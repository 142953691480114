import React, { useState } from 'react';
import styled from 'styled-components';

import withTheme from '@material-ui/core/styles/withTheme';

import OutlinedPurpleButton from 'design-system/molecules/OutlinedPurpleButton/index';

import AddProjectButton from 'components/AddProjectButton/AddProjectButton';
import BookDemoLightbox from 'components/BookDemoLightbox';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import useLightboxesControlContext from 'hooks/lightboxes/useLightboxesControl';
import AIAnswerBotButton from 'features/AIAnswerBot/components/AIAnswerBotButton';

const HeaderOptions = props => {
  const { canView } = usePermissions();
  const [showBookDemo, setShowBookDemo] = useState(false);

  const { openAIAnswerBotDialog } = useLightboxesControlContext();

  const shouldRenderAiBotButton = canView(PERMISSION_FEATURES.aiAnswerBot);

  const shouldRenderDemoButton = canView(PERMISSION_FEATURES.missionControlBookADemo);

  return (
    <>
      {shouldRenderAiBotButton ? <AIAnswerBotButton onClick={openAIAnswerBotDialog} /> : null}
      {shouldRenderDemoButton ? <BookDemoButton buttonText="Book a Demo" onClick={() => setShowBookDemo(true)} /> : null}
      <StyledAddProjectButton {...props} />
      <BookDemoLightbox open={showBookDemo} onClose={() => setShowBookDemo(false)} />
    </>
  );
};

const StyledAddProjectButton = styled(AddProjectButton)`
  &&&& {
    padding-right: 20px;
  }
`;

const BookDemoButton = styled(OutlinedPurpleButton)``;

export default withTheme()(HeaderOptions);
