import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  removeProjectIntegration,
  isLoadingRemoveProjectIntegration,
  getRemoveProjectIntegrationError,
} from 'store/integrations';

export default (projectId, projIntegrationId, integrationId, updateProject) => {
  const dispatch = useDispatch();

  const unlinking = useSelector(isLoadingRemoveProjectIntegration);
  const error = useSelector(getRemoveProjectIntegrationError);

  const _unlinkProjectIntegration = useCallback(
    async data => {
      if (unlinking) {
        return;
      }

      if (projIntegrationId && projIntegrationId instanceof Array) {
        projIntegrationId.map(projIntegrationId =>
          dispatch(
            removeProjectIntegration(
              projectId,
              projIntegrationId,
              {
                org_integration_id: integrationId,
                ...data,
              },
              updateProject,
            ),
          ),
        );
      } else if (projIntegrationId) {
        dispatch(
          removeProjectIntegration(
            projectId,
            projIntegrationId,
            {
              org_integration_id: integrationId,
              ...data,
            },
            updateProject,
          ),
        );
      }
    },
    [projectId, projIntegrationId, integrationId, updateProject],
  );

  return [_unlinkProjectIntegration, unlinking, error];
};
