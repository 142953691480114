export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_FULFILLED = 'FETCH_USERS_FULFILLED';
export const FETCH_USERS_PENDING = 'FETCH_USERS_PENDING';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_FULFILLED = 'CREATE_USER_FULFILLED';
export const CREATE_USER_PENDING = 'CREATE_USER_PENDING';
export const CREATE_USER_REJECTED = 'CREATE_USER_REJECTED';

export const IMPORT_USERS = 'IMPORT_USERS';
export const IMPORT_USERS_FULFILLED = 'IMPORT_USERS_FULFILLED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED';
export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING';
export const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED';

export const DELETE_USERS = 'DELETE_USERS';
export const DELETE_USERS_FULFILLED = 'DELETE_USERS_FULFILLED';

export const DELETE_ALL_USERS = 'DELETE_ALL_USERS';

export const DELETE_USER_BY_ID = 'DELETE_USER_BY_ID';
export const DELETE_USER_BY_ID_FULFILLED = 'DELETE_USER_BY_ID_FULFILLED';

export const ADD_USER_WITHOUT_SAVE = 'ADD_USER_WITHOUT_SAVE';
export const REMOVE_UNSAVED_USERS = 'REMOVE_UNSAVED_USERS';

export const UPDATE_USER_BY_ID = 'UPDATE_USER_BY_ID';
export const UPDATE_USER_BY_ID_FULFILLED = 'UPDATE_USER_BY_ID_FULFILLED';

export const MERGE_USERS = 'MERGE_USERS';
export const MERGE_USERS_FULFILLED = 'MERGE_USERS_FULFILLED';

export const UPDATE_USERS_SEARCH = 'UPDATE_USERS_SEARCH';
export const UPDATE_USERS_FILTERS = 'UPDATE_USERS_FILTERS';

export const CREATE_USERS = 'CREATE_USERS';
export const CREATE_USERS_FULFILLED = 'CREATE_USERS_FULFILLED';

export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_USERS_FULFILLED = 'UPDATE_USERS_FULFILLED';
export const BULK_DELETE_USERS = 'BULK_DELETE_USERS';
export const BULK_DELETE_USERS_FULFILLED = 'BULK_DELETE_USERS_FULFILLED';

export const UNDO_CREATE_USERS = 'UNDO_CREATE_USERS';
export const UNDO_CREATE_USERS_FULFILLED = 'UNDO_CREATE_USERS_FULFILLED';
export const UNDO_UPDATE_USERS = 'UNDO_UPDATE_USERS';
export const UNDO_UPDATE_USERS_FULFILLED = 'UNDO_UPDATE_USERS_FULFILLED';
export const UNDO_BULK_DELETE_USERS = 'UNDO_BULK_DELETE_USERS';
export const UNDO_BULK_DELETE_USERS_FULFILLED = 'UNDO_BULK_DELETE_USERS_FULFILLED';

export const INVITE_USERS = 'INVITE_USERS';
export const INVITE_USERS_FULFILLED = 'INVITE_USERS_FULFILLED';
