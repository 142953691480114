import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { Draggable } from 'react-beautiful-dnd';

import MicroChip from 'design-system/atoms/MicroChip';

const MultiValue = props => {
  return (
    <StyledMicroChip
      theme={props.theme}
      tabIndex={-1}
      label={props.children}
      fullWidth
      onDelete={props.selectProps.hideControlsOnBlur && !props.selectProps.isFocused ? null : props.removeProps.onClick}
      className="multiselect-chip"
      deleteIcon={<CloseIcon {...props.removeProps} fontSize="small" />}
    />
  );
};

const OrderableMultiValue = props => {
  const { isDragging, styleOnDrag } = props;
  const { index, value } = props.data;
  const ref = React.useRef(null);

  return (
    <div ref={ref} style={isDragging ? styleOnDrag : null}>
      <Draggable draggableId={value} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
            }}
          >
            <MultiValue isDragging={isDragging} {...props} />
            {provided.placeholder}
          </div>
        )}
      </Draggable>
    </div>
  );
};

/**
 * Select's Control component prevents default on a MouseDown event
 * this component stops propagation of that event for the default - begginDrag - actually happen.
 */
export default props => {
  if (!props.selectProps.draggable) {
    return <MultiValue {...props} />;
  }

  return (
    <Wrapper
      onMouseDown={e => {
        e.stopPropagation();
      }}
    >
      <OrderableMultiValue {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0;
`;

const StyledMicroChip = styled(MicroChip)`
  &&&& {
    background-color: ${({ theme }) => theme.palette.background.white};
    border: 1px solid ${({ theme }) => theme.palette.background.gainsboro};
    border-radius: 20px;
    height: auto;
    padding: 2px 0 2px 8px;

    span {
      padding: 0;
      color: ${({ theme }) => theme.palette.text.black};
      font-size: ${({ theme }) => theme.typography.fontSizeSmall}px;
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    }

    svg {
      font-size: 15px;
      margin-left: -4px;
      margin: 0 8px;
    }
  }
`;
