import { useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';

import usePrevious from 'hooks/usePrevious';

import { dataFormatter, getTimelineItemsFromData, getTimelineOrders } from '../helpers';
import head from 'lodash/head';
import remove from 'lodash/remove';

const MIN_BAR_WIDTH = 22;

const useTimelineLocalState = (data, orders, isMultiGroup, isLoading, shouldResetData) => {
  const [internalData, setInternalData] = useState([...data]);
  const [internalOrders, setInternalOrders] = useState(orders);

  const externalDataFormatted = useMemo(() => dataFormatter(isMultiGroup, data), [data]);
  const internalDataFormatted = useMemo(() => dataFormatter(isMultiGroup, internalData), [internalData]);

  const previousExternalDataFormatted = usePrevious(externalDataFormatted);

  const refreshInternalState = newData => {
    setInternalData(newData);
    setInternalOrders(getTimelineOrders(newData));
  };

  const refreshInternalStateAfterDrag = ({
    task,
    laneIndex,
    groupIndex,
    rowIndex,
    delta,
    skipVertical,
    dateDiff,
    oldOrderIndex,
    oldOrder,
    newOrder,
    zoomMode,
    slotWidth,
    fromDate,
    snapToGridOn,
    minBarWidth = MIN_BAR_WIDTH,
  }) => {
    // update internal state
    const internalTask = isMultiGroup
      ? internalData?.[laneIndex]?.groups?.[groupIndex]?.rows?.[rowIndex]?.find(({ id }) => id === task.id)
      : internalData?.[laneIndex]?.rows?.[rowIndex]?.find(({ id }) => id === task.id);

    if (internalTask) {
      if (dateDiff) {
        internalTask.startDate.add(dateDiff, 'days');
        internalTask.endDate.add(dateDiff, 'days');
      }
    }

    const firstGroupChanged = head(oldOrder) !== head(newOrder);
    const secondGroupChanged = oldOrder?.[1] !== newOrder?.[1];

    const groupHasChanged = oldOrderIndex !== -1 && isMultiGroup ? firstGroupChanged || secondGroupChanged : firstGroupChanged;

    if (!skipVertical && groupHasChanged) {
      const newLaneId = ['null', 'undefined'].includes(head(newOrder)) ? null : parseInt(head(newOrder), 10);

      if (isMultiGroup) {
        remove(internalData?.[laneIndex]?.groups?.[groupIndex]?.items, ({ id }) => id === task.id);

        const newLaneIndex = internalData?.findIndex(lane => lane.id === newLaneId);
        const newGroupIndex = internalData?.[newLaneIndex]?.groups?.findIndex(
          group => group.id === (['null', 'undefined'].includes(newOrder?.[1]) ? null : parseInt(newOrder?.[1], 10)),
        );

        if (newLaneIndex !== -1 && newGroupIndex !== -1) {
          // add the task to the new lane
          internalData[newLaneIndex].groups[newGroupIndex].items = [
            ...(internalData[newLaneIndex].groups[newGroupIndex].items ?? []),
            internalTask,
          ];
        }
      } else {
        remove(internalData?.[laneIndex]?.items, ({ id }) => id === task.id);

        const newLaneIndex = internalData?.findIndex(lane => lane.id === newLaneId);

        if (newLaneIndex !== -1) {
          // add the task to the new lane
          internalData[newLaneIndex].items = [...(internalData[newLaneIndex]?.items ?? []), internalTask];
        }
      }
    }

    if (dateDiff || groupHasChanged) {
      const refreshedData = getTimelineItemsFromData(internalData, zoomMode, slotWidth, fromDate, minBarWidth, snapToGridOn);

      refreshInternalState(refreshedData);
    }
  };

  useEffect(() => {
    if (
      shouldResetData ||
      (!isLoading &&
        !isEqual(externalDataFormatted, previousExternalDataFormatted) &&
        !isEqual(externalDataFormatted, internalDataFormatted))
    ) {
      setInternalData(data);
      setInternalOrders(orders);
    }
  }, [
    data,
    orders,
    externalDataFormatted,
    previousExternalDataFormatted,
    internalDataFormatted,
    setInternalData,
    isLoading,
    shouldResetData,
  ]);

  return {
    internalData,
    setInternalData,
    internalOrders,
    setInternalOrders,
    refreshInternalState,
    refreshInternalStateAfterDrag,
  };
};

export default useTimelineLocalState;
