import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { not } from 'ramda';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import useIntegrations from 'hooks/useIntegrations';

const MultiIntegrationDropdown = props => {
  const { integrationType, selectedIntegration, onChange, disabled, enableMultipleIntegrations } = props;

  const [labelRef, setLabelRef] = useState(null);

  const { organizationIntegrations } = useIntegrations(integrationType);

  const isSelectNotDisabled = not(disabled);
  const allowToSelectIntegration = isSelectNotDisabled || enableMultipleIntegrations;

  const onLabelClick = useCallback(
    ({ currentTarget }) => {
      if (!allowToSelectIntegration) return;

      setLabelRef(currentTarget);
    },
    [allowToSelectIntegration],
  );

  const onResetLabelRef = useCallback(() => setLabelRef(null), []);

  const onMenuItemClickHandler = useCallback(integration => {
    onChange(integration.id);
    setLabelRef(null);
  }, []);

  return (
    <>
      <Label onClick={onLabelClick}>
        {selectedIntegration && `(${selectedIntegration.code})`}
        {allowToSelectIntegration && (
          <ExpandIcon>
            <ExpandMoreIcon />
          </ExpandIcon>
        )}
      </Label>
      <Popper placement="bottom-start" anchorEl={labelRef} open={!!labelRef} onClose={onResetLabelRef} style={{ zIndex: 100000 }}>
        <Paper>
          <ClickAwayListener onClickAway={onResetLabelRef}>
            <MenuList>
              {organizationIntegrations.map(integration => (
                <MenuItem key={integration.id} onClick={() => onMenuItemClickHandler(integration)}>
                  {integration.code}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

MultiIntegrationDropdown.propTypes = {
  integrationType: PropTypes.string.isRequired,
  selectedIntegration: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  enableMultipleIntegrations: PropTypes.bool,
};

const Label = styled.span`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const ExpandIcon = styled.span`
  display: flex;
  margin-left: 2px;
`;

export default MultiIntegrationDropdown;
