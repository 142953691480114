import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import Logout from 'design-system/atoms/DragonIcons/Logout';
import Cog from 'design-system/atoms/DragonIcons/Cog';
import theme from 'design-system/theme';

import { clearAuthTokenAndStore } from 'store/login';
import { getCurrentUser } from 'store/login/selectors';
import history from 'store/utils/history';

const useDragonAvatarDropdown = () => {
  const dispatch = useDispatch();

  const user = useSelector(getCurrentUser);
  const onClickLogout = () => {
    const { pathname } = window.location;

    const parameters = window.location.search;

    dispatch(clearAuthTokenAndStore());

    const path = pathname ? `login?next=${pathname + parameters}` : 'login';

    history.push(`/${path}`);
  };

  const handleOptionClick = item => {
    if (item.onClick) return item.onClick();
    history.push(item.path);
  };

  const dropdownOptions = [
    {
      key: 'personalSettings',
      label: 'Personal settings',
      icon: (
        <IconContainer>
          <StyledCogIcon />
        </IconContainer>
      ),
      onClick: () => history.push('/user/preferences'),
      testId: 'avatar-personal-settings',
    },
    {
      key: 'logout',
      label: 'Logout',
      icon: (
        <IconContainer>
          <StyledLogoutIcon />
        </IconContainer>
      ),
      path: null,
      onClick: onClickLogout,
      testId: 'avatar-logout',
    },
  ];

  return {
    dropdownOptions,
    handleOptionClick,
    user,
  };
};

const IconContainer = styled.div`
  position: relative;
  width: 22px;
`;

const iconStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: ${theme.sizing.icons.regular}rem;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.palette.newLayout.background.disable};
`;

const StyledLogoutIcon = styled(Logout)`
  ${iconStyles};

  path {
    fill: ${({ theme }) => theme.palette.newLayout.background.disable};
  }
`;

const StyledCogIcon = styled(Cog)`
  ${iconStyles};

  path {
    fill: ${({ theme }) => theme.palette.newLayout.background.disable};
  }
`;

export default useDragonAvatarDropdown;
