import React from 'react';

import Swimlane from 'routes/Swimlane';

import SwimlaneVerticalBar from './components/SwimlaneVerticalBar';

import { MIN_BAR_WIDTH } from './components/constants';

const ScenarioSwimlane = props => {
  return <Swimlane {...props} barRenderer={SwimlaneVerticalBar} withCustomersGrouping minBarWidth={MIN_BAR_WIDTH} />;
};

export default ScenarioSwimlane;
