import React from 'react';

import Button from '@material-ui/core/Button';

// TODO - check if this color is correct according to the latest design system rules
//      - confirm with product
import { materialColorsAlt } from 'design-system/themes/default';
import SmallText from 'design-system/atoms/SmallText/index';


import { INTEGRATIONS_NAMES } from 'constants/integrations';

import ConfirmDialog from 'components/ConfirmDialog';

import useSystemFields from 'hooks/useSystemFields';

import { useLastSyncDay, useUpdateProjectsMappedFields } from './hooks';

const UpdateProjectsMappedFields = props => {
  const { integrationType, orgIntegrationId, disabled } = props;

  const [getSystemFieldName] = useSystemFields();

  const {
    isLoading,
    isConfirmDialogOpen,
    isUpdateInProgressDialogOpen,
    onOpenConfirmDialog,
    onCloseConfirmDialog,
    onCloseUpdateInProgressDialog,
    onConfirmHandler,
  } = useUpdateProjectsMappedFields(integrationType, orgIntegrationId);

  const { lastSyncDay } = useLastSyncDay(orgIntegrationId);

  return (
    <>
      <Button id="update-mapped-fields" color="primary" disabled={disabled || isLoading} onClick={onOpenConfirmDialog}>
        Update mapped fields with {INTEGRATIONS_NAMES[integrationType]} values now
      </Button>
      {lastSyncDay && <SmallText>(Last Sync: {lastSyncDay})</SmallText>}
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        onCancel={onCloseConfirmDialog}
        onConfirm={onConfirmHandler}
        title="Are you sure you want to continue?"
        text={
          <div>
            <p style={{ color: materialColorsAlt.red, marginBottom: '1rem' }}>
              This action will update every {getSystemFieldName('idea', true)} and overwrite other users&apos; changes. Are you
              sure you want to continue?
            </p>
          </div>
        }
      />
      <ConfirmDialog
        isOpen={isUpdateInProgressDialogOpen}
        onConfirm={onCloseUpdateInProgressDialog}
        text="Update in progress. Please check back in a few minutes."
        confirmText="Close"
        showCancel={false}
        showTitle={false}
      />
    </>
  );
};

export default UpdateProjectsMappedFields;
