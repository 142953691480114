import { useSelector } from 'react-redux';

import { getUserViews, getUserDashboards, getUserViewsWithoutDashboardViews } from 'store/userViews/selectors';

/**
 * @function useUserViews
 *
 * use all user views related data
 *
 * @return {Object}
 */
const useUserViews = () => {
  const userViews = useSelector(getUserViews);
  const userDashboards = useSelector(getUserDashboards);
  const userViewsWithoutDashboardViews = useSelector(getUserViewsWithoutDashboardViews);

  return {
    userViews,
    userDashboards,
    userViewsWithoutDashboardViews,
  };
};

export default useUserViews;
