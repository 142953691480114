/**
 * @function promiseTimeout
 *
 * Creates a promise that rejects in <ms> milliseconds
 *
 * @param  {Number} ms        Number of milliseconds of timeout
 * @param  {Promise} promise  The promise racing with timeout
 * @return {Promise}          Whichever resolves or rejects first
 */
function promiseTimeout(ms, promise) {
  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject(new Error('Promise timed out'));
    }, ms);
  });

  return Promise.race([promise, timeout]);
}

export default promiseTimeout;
