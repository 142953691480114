/**
 * @function formatUserViews
 *
 * Returns the array with all the user's views plus the user who created each
 *
 * @return {Array} userViews - Array with user's views
 * @return {Array} users - Array with users
 */

export default (userViews, users) =>
  userViews.reduce((views, view) => {
    if (view.path === 'dashboard/home' || view.default_view || view.is_static || !view.path) {
      return views;
    }

    const user = users.find(user => user.id === view.user_id);

    views.push({
      ...view,
      user,
    });

    return views;
  }, []);
