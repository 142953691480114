import { useMemo, useState } from 'react';
import { head } from 'ramda';
import usePermissions from 'hooks/permissions/usePermissions';
import useSystemFields from 'hooks/useSystemFields';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { BET, IDEA, INITIATIVE } from 'constants/projects';
import { KEY_RESULT, KEY_RESULT_2, OBJECTIVE, METRIC } from 'constants/common';

const initialDrawerProps = {
  isOpen: false,
  objectTitle: null,
  textLabelId: null,
};

const useCreateDrawer = () => {
  const { canCreate } = usePermissions();
  const [getSystemFieldName] = useSystemFields();
  const [drawerProps, setDrawerProps] = useState(initialDrawerProps);

  const canCreateIdea = canCreate(PERMISSION_RESOURCES.project, { project: { layer: IDEA_LAYER } });
  const canCreateInitiative = canCreate(PERMISSION_RESOURCES.project, { project: { layer: INITIATIVE_LAYER } });
  const canCreateBet = canCreate(PERMISSION_RESOURCES.project, { project: { layer: BET_LAYER } });
  const canCreateObjective = canCreate(PERMISSION_RESOURCES.objective);
  const canCreateKeyResult = canCreate(PERMISSION_RESOURCES.keyResult);
  const canCreateKeyResult2 = canCreate(PERMISSION_RESOURCES.keyResult2);
  const canCreateMetric = canCreate(PERMISSION_RESOURCES.metric);

  const availableTypes = useMemo(
    () => ({
      ...(canCreateIdea ? { [IDEA]: getSystemFieldName('idea') } : {}),
      ...(canCreateInitiative ? { [INITIATIVE]: getSystemFieldName('initiative') } : {}),
      ...(canCreateBet ? { [BET]: getSystemFieldName('bet') } : {}),
      ...(canCreateObjective ? { [OBJECTIVE]: getSystemFieldName('objective') } : {}),
      ...(canCreateKeyResult ? { [KEY_RESULT]: getSystemFieldName('keyResult1') } : {}),
      ...(canCreateKeyResult2 ? { [KEY_RESULT_2]: getSystemFieldName('keyResult2') } : {}),
      ...(canCreateMetric ? { [METRIC]: getSystemFieldName('metric') } : {}),
    }),
    [canCreateIdea, canCreateInitiative, canCreateBet, canCreateObjective, canCreateKeyResult, canCreateKeyResult2],
  );

  const [type, setType] = useState(head(Object.keys(availableTypes)));

  const handleTypeChange = val => {
    const typeId = Object.keys(availableTypes).find(key => availableTypes[key] === val);

    setType(typeId);
  };

  const selectedTypeIsOkr = [OBJECTIVE, KEY_RESULT, KEY_RESULT_2].includes(type);
  const selectedTypeIsProject = [BET, INITIATIVE, IDEA].includes(type);
  const selectedTypeIsMetric = type === METRIC;

  const openCreateDrawer = (textLabelId, objectTitle) =>
    setDrawerProps({
      isOpen: true,
      objectTitle,
      textLabelId,
    });

  const closeCreateDrawer = onCloseCallback => {
    setDrawerProps(initialDrawerProps);
    setType(head(Object.keys(availableTypes)));

    onCloseCallback && onCloseCallback();
  };

  return {
    ...drawerProps,
    type,
    typeOptions: availableTypes,
    selectedTypeIsOkr,
    selectedTypeIsProject,
    selectedTypeIsMetric,
    handleTypeChange,
    openCreateDrawer,
    onClose: closeCreateDrawer,
  };
};

export default useCreateDrawer;
