import axios from 'axios';
import head from 'lodash/head';
import isEqual from 'lodash/isEqual';
import { both, defaultTo, isNil, isEmpty, pipe, omit, pick, not, pluck } from 'ramda';

import { METADATA_LEVELS } from 'constants/common';

import multioptionsFieldPassesFilter from 'utils/multioptionsFieldPassesFilter';
import {
  CREATE,
  BULK_CREATE,
  UPDATE,
  BULK_UPDATE,
  DELETE,
  BULK_DELETE,
  BULK_UPDATE_PROGRESS,
  ASYNC_IMPORT_INTEGRATION_PROJECTS,
} from 'store/constants/realtimeUpdateTypes';
import getStore from 'store/store';
import { createRoadmap, createProduct } from 'store/roadmaps/actions';
import { createCategory } from 'store/categories/actions';
import { createObjective, createKeyResult } from 'store/objectives/actions';
import { createTheme } from 'store/themes/actions';
import { createTimeframe } from 'store/timeframes/actions';
import { createPhase } from 'store/phases/actions';
import { getRoadmaps, getProducts } from 'store/roadmaps/selectors';
import { getObjectives, selectKeyResults1 } from 'store/objectives/selectors';
import { getCompareVersionEditScenario, getSelectedRoadmapVersion } from 'store/roadmapVersions/selectors';
import { getCategories } from 'store/categories/selectors';
import { getPriorities } from 'store/priorities/selectors';
import { getPhases } from 'store/phases/selectors';
import { getThemes } from 'store/themes/selectors';
import { getTimeframes } from 'store/timeframes/selectors';
import { getSelectedProject, getIdeas } from 'store/projects/selectors';
import { selectScenarioSelected, getSelectedProjectOnLigthbox } from 'store/projectLightbox/selectors';
import { loadMetadataForProjectIds, loadMetadataForProjectFilters } from 'features/MetadataOnDemand/store/network';
import bulkUpdateAction from 'store/utils/factory/bulkUpdateAction';
import transformObjectWithNestedKeys from 'utils/transformObjectWithNestedKeys';
import getFiltersToFetchProjects from 'utils/getFiltersToFetchProjects';
import undoAction from '../utils/factory/undoAction';

import {
  createProjectEstimate,
  updateProjectEstimate,
  deleteProjectEstimate,
  gotEstimatesRealtimeUpdate,
} from 'store/estimates/thunks';
import { gotTasksRealtimeUpdate } from 'store/tasks/thunks';
import { createPriority } from 'store/priorities/actions';
import {
  syncProjectFromJira,
  createAllProjectsJiras,
  getProjectStories,
  addProjectIntegration,
  removeProjectIntegration,
  updateIntegrationProjectFromProject,
} from 'store/integrations/thunks';

import { switchProjectRowOrder, updateProjects } from './thunks';

import { fetchFromProjectsApi } from './helpers/fetchProjects';

import {
  APPLY_FILTERS,
  FETCH_PROJECTS_CHILDREN,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  UPDATE_PROJECT_FULFILLED,
  DELETE_PROJECTS,
  DELETE_PROJECTS_FULFILLED,
  BULK_CREATE_PROJECTS,
  BULK_UPDATE_PROJECTS,
  BULK_UPDATE_PROJECTS_FULFILLED,
  CREATE_PROJECT_FULFILLED,
  BULK_CREATE_PROJECTS_FULFILLED,
  SET_TASKS,
  FETCH_PROJECT_CUSTOMER_REQUESTS,
  REALTIME_BULK_UPDATE_PROGRESS,
  GET_PROJECT_COUNTERS_BY_PHASE,
  WATCH_PROJECT,
  UNWATCH_PROJECT,
  MERGE_PROJECTS,
  CLONE_PROJECT,
  CREATE_UNSAVED_PROJECT,
  REMOVE_UNSAVED_PROJECT,
  CREATE_PROJECT_RESET,
  UPDATE_PROJECT_RESET,
  UPDATE_PROJECT_ROW_ORDER_RESET,
  ADD_SELECTED_PARENT_TO_STORE,
} from './types';
import serializeProject from './helpers/serializeProject';
import buildTasksChainAndAssignToProjects from './helpers/buildTasksChainAndAssignToProjects';
import assignIntegrationsToProjects from 'store/projects/helpers/assignIntegrationsToProjects';

import { fetchVotesPerProjectApiCall } from 'store/votes/actions';
import { updateJiraIssueFromProject } from 'store/integrations';
import { GLOBAL_FILTER } from 'constants/filters';
import { INTEGRATIONS_KEYS } from 'constants/integrations';
import { IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC_RESULT } from '../organization';
import { getEstimatesForRoadmapVersion, updateScenarioProject } from '../roadmapVersions';
import assignIntegrationProgressToProjects from 'store/projects/helpers/assignIntegrationProgressToProjects';
import assignEstimatesToProjects from 'store/projects/helpers/assignEstimatesToProjects';
import assignJirasToProjects from 'store/projects/helpers/assignJirasToProjects';

import { addProjectToCustomerRequest as addProjectToCustomerRequestAction } from 'store/customerRequests';
import { SUPPORTED_FIELDS } from 'utils/roadmapVersions/supportedFieldsUtils';
import { getDropdownCustomFields } from 'store/customFields/selectors';
import { selectIsScenarioRoute } from 'store/app';

const defaultToEmptyObject = defaultTo({});
const isNotNil = pipe(isNil, not);
const isNotEmpty = pipe(isEmpty, not);
const isNotNilOrEmpty = both(isNotNil, isNotEmpty);
const isNilOrEmpty = value => isNil(value) || isEmpty(value);
const pickIdAndTitle = pick(['id', 'title']);

const getProjectDiff = (updateData, project1) => {
  return Object.keys(updateData).reduce((newObj, key) => {
    if (!isEqual(project1[key], updateData[key])) {
      return { ...newObj, [key]: updateData[key] };
    }

    return newObj;
  }, {});
};

const searchTasksForProjectsAndParents = params =>
  axios.post('/api/projects/search/tasks', {
    ...params,
    withParents: true,
  });
const searchEstimatesForProjectsAndParents = params =>
  axios.post('/api/projects/search/estimates', { ...params, withParents: true });
const searchIntegrationsForProjectsAndParents = params =>
  axios.post('/api/projects/search/integrations', { ...params, withParents: true });
const searchDependenciesForProjects = params => axios.post('/api/projects/search/dependencies', params);
const searchJirasForProjectsAndParents = params =>
  axios.post('/api/projects/search/jiras', {
    ...params,
    withParents: true,
  });
const searchIntegrationProgressForProjectsAndParents = params =>
  axios.post('/api/projects/search/integration-progress', { ...params, withParents: true });

export const searchParentsForProjects = params =>
  axios.post('/api/projects/search/parents', {
    ...params,
    withParents: true,
  });

export const searchProjectsByMetric = (metricId, params) => axios.post(`/api/projects/search/metric/${metricId}`, params);

const getKeys = pluck('key');
const getFields = pluck('field');
const fieldKeysFromVersion = getKeys(SUPPORTED_FIELDS);
const fieldsFromVersion = getFields(SUPPORTED_FIELDS);
const PRIORITY_KEY = 'priority';
const OWNER_KEY = 'owner';
const TIMEFRAME_KEY = 'timeframe';
const TIMEFRAME_2_KEY = 'timeframe2';

const cleanUpdateDataAfterVersionUpdate = updateData => {
  return omit(
    [...fieldKeysFromVersion, ...fieldsFromVersion, PRIORITY_KEY, OWNER_KEY, TIMEFRAME_KEY, TIMEFRAME_2_KEY],
    updateData,
  );
};

/**
 * Dispatch create action for metadata that dosent exists
 *
 * @param {Function} dispatch
 * @param {Object} project
 * @param {Integer} id
 * @return {Promise<Array>}
 */
const _createMetadataIfDoesntExist = async (dispatch, project, id) => {
  if (!project) return;

  const state = getStore().getState();

  let existingProject;

  if (id) existingProject = getSelectedProject(state, { id });

  const roadmapsTitles = getRoadmaps(state, false, METADATA_LEVELS.LEVEL_1).map(({ title }) => title);
  const productsTitles = getProducts(state).map(({ title }) => title);
  const objectivesTitles = getObjectives(state).map(({ title }) => title);
  const keyResultsTitles = selectKeyResults1(state).map(({ title }) => title);
  const categoriesTitles = getCategories(state).map(({ title }) => title);
  const prioritiesTitles = getPriorities(state).map(({ title }) => title);
  const phasesTitles = getPhases(state).map(({ title }) => title);
  const themesTitles = getThemes(state).map(({ title }) => title);
  const timeframesTitles = getTimeframes(state).map(({ title }) => title);

  const objectivesCorpTitles = getObjectives(state, true, METADATA_LEVELS.LEVEL_CORP).map(pickIdAndTitle);
  const timeframesCorpTitles = getTimeframes(state, true, METADATA_LEVELS.LEVEL_CORP).map(pickIdAndTitle);

  const map = {
    roadmapTitle: { metadata: roadmapsTitles, action: createRoadmap, attribute: 'title' },
    objectiveTitle: { metadata: objectivesTitles, action: createObjective, attribute: 'title' },
    categoryTitle: { metadata: categoriesTitles, action: createCategory, attribute: 'title' },
    priorityTitle: { metadata: prioritiesTitles, action: createPriority, attribute: 'title' },
    timeframeTitle: { metadata: timeframesTitles, action: createTimeframe, attribute: 'title' },
    phaseTitle: { metadata: phasesTitles, action: createPhase, attribute: 'title' },
    themeTitle: { metadata: themesTitles, action: createTheme, attribute: 'title' },
  };

  const corpFieldsMap = {
    objectiveCorpTitle: {
      metadata: objectivesCorpTitles,
      action: createObjective,
      attribute: 'title',
      corpIdField: 'objective_corp_id',
    },
    timeframeCorpTitle: {
      metadata: timeframesCorpTitles,
      action: createTimeframe,
      attribute: 'title',
      corpIdField: 'timeframe_corp_id',
    },
  };

  let promises = [];
  const keys = [];

  const corpPromises = [];
  const corpKeys = [];

  const dealWithError = error => {
    if (
      error.response &&
      ((error.response.data && error.response.data.error_code === 'DUPLICATED_RECORD_WITH_SAME_TITLE') ||
        error.response.status === 401)
    )
      return;

    console.warn(error);
  };

  Object.keys(map).forEach(key => {
    if (!map[key] || !project || !project[key]) return;
    if (!map[key].metadata.includes(project[key])) {
      promises.push(dispatch(map[key].action({ [map[key].attribute]: project[key] })).catch(dealWithError));
      keys.push(key);
    }
  });

  /**
   * Handle updates of OKRs and Timeframes Corp using the Id instead of title since some of the row in the
   * database have a template as title.
   *
   * This implementation try to follow the existing one for the other metadata levels.
   */
  Object.keys(corpFieldsMap).forEach(key => {
    const corpMapEntry = corpFieldsMap[key];

    if (isNil(corpMapEntry) || isNil(project) || isNilOrEmpty(project[key])) {
      return;
    }

    const selectedTitle = project[key];
    const selectedOption = corpMapEntry.metadata.find(({ title }) => title === selectedTitle);

    delete project[key];

    if (isNil(selectedOption)) {
      corpPromises.push(
        dispatch(corpMapEntry.action({ title: selectedTitle, level: METADATA_LEVELS.LEVEL_CORP })).catch(dealWithError),
      );
      corpKeys.push(corpMapEntry.corpIdField);

      return;
    }

    project[corpMapEntry.corpIdField] = selectedOption.id;
  });

  // if (project.ownerName && !usersNames.includes(project.ownerName)) {
  //   const names = project.ownerName.split(' ');
  //   const lastName = names.length > 1 ? names[names.length - 1] : '';
  //   const firstName = names.length === 1 || names.length === 2 ? names[0] : names.slice(0, names.length - 1).join(' ');

  //   promises.push(dispatch(createUser({ first_name: firstName, last_name: lastName })).catch(dealWithError));
  // }

  const results = await Promise.all(promises);
  const corpResults = await Promise.all(corpPromises);

  // Add the corp metadata ids to the project to be updated.
  corpKeys.forEach((fieldKey, index) => {
    project[fieldKey] = corpResults[index].id;
  });

  promises = [];

  if (project.product1Title && !productsTitles.includes(project.product1Title)) {
    let roadmapId;

    if (project.roadmapTitle) {
      let roadmap;

      if (keys.includes('roadmapTitle')) {
        roadmap = results[keys.indexOf('roadmapTitle')];
      } else {
        roadmap = getRoadmaps(state).find(({ title }) => title === project.roadmapTitle);
      }

      roadmapId = roadmap ? roadmap.id : null;
    } else if (existingProject) {
      roadmapId = existingProject.roadmap_id;
    }
    promises.push(
      dispatch(
        createProduct({
          title: project.product1Title,
          roadmap_id: roadmapId,
        }),
      ).catch(dealWithError),
    );
  }

  if (project.keyResult1Title && !keyResultsTitles.includes(project.keyResult1Title)) {
    let objectiveId;

    if (project.objectiveTitle) {
      let objective;

      if (keys.includes('objectiveTitle')) {
        objective = results[keys.indexOf('objectiveTitle')];
      } else {
        objective = getObjectives(state).find(({ title }) => title === project.objectiveTitle);
      }

      objectiveId = objective ? objective.id : null;
    } else if (existingProject) {
      objectiveId = existingProject.objective_id;
    }

    promises.push(
      dispatch(
        createKeyResult({
          title: project.keyResult1Title,
          objective_id: objectiveId,
          level: project.level,
        }),
      ).catch(dealWithError),
    );
  }

  if (project.parent && project.parent.id) {
    const { parent } = project;
    const allParents = getIdeas(state, null, parent.layer, false);
    const parentExists = allParents.some(p => {
      return p.id === parent.id;
    });

    if (!parentExists) {
      dispatch({
        payload: parent,
        type: ADD_SELECTED_PARENT_TO_STORE,
      });
    }
  }

  return Promise.all(promises);
};

/**
 * @function _updateProjectAndDispatchUpdateProjectIntegration
 *
 * Executes the update project HTTP request and, if required, dispatch the action to
 * update also the linked issue/work item on the integrations side.
 *
 * Only wait for the update project, the project integration update will be executed in background.
 *
 * @param {Function} dispatch
 * @param {Number} projectId
 * @param {Object} existingProject
 * @param {Object} dataToSave
 * @param {Boolean} jiraSync
 * @returns {Promise<Object>}
 */
const _updateProjectAndDispatchUpdateProjectIntegration = async (dispatch, existingProject, dataToSave, jiraSync) => {
  const url = `/api/projects/update/${existingProject.id}${jiraSync ? '?jiraSync=true' : ''}`;

  const payload = await axios.post(url, dataToSave).then(res => res.data);

  // Determine whether integration type should use separate endpoint to update project integration after DB update
  const shouldUpdateIntegrationProject = type => [INTEGRATIONS_KEYS.azuredevops, INTEGRATIONS_KEYS.rally].includes(type);

  if (jiraSync) {
    const { jira, integration } = defaultToEmptyObject(existingProject);

    if (isNotNilOrEmpty(jira)) {
      const { orgIntegration_id: jiraIssueOrgIntegrationId } = jira;

      if (isNotNil(jiraIssueOrgIntegrationId)) {
        dispatch(updateJiraIssueFromProject(jira.orgIntegration_id, existingProject.id, dataToSave));
      }
    }

    if (isNotNilOrEmpty(integration) && shouldUpdateIntegrationProject(integration.type)) {
      const { type, orgIntegrationId } = integration;

      if (isNotNil(orgIntegrationId)) {
        dispatch(updateIntegrationProjectFromProject(type, orgIntegrationId, existingProject.id, dataToSave));
      }
    }
  }

  return payload;
};

const nonBlockingError = error => {
  if (window.Rollbar) {
    window.Rollbar.error(error); // Send it to Rollbar!
  }

  return {};
};

const handleUpdateScenarioProject = async (
  state,
  dispatch,
  projectId,
  selectedRoadmapVersion,
  responseProjectData,
  serializedScenarioUpdateData,
  hasScenarioFieldsToUpdate,
  overridePoRProjectWithVersionUpdates = true,
) => {
  responseProjectData = omit(fieldsFromVersion, responseProjectData);

  if (hasScenarioFieldsToUpdate) {
    // Updates scenario versionable fields
    const updatedVersionProject = await updateScenarioProject(
      state,
      dispatch,
      projectId,
      serializedScenarioUpdateData,
      selectedRoadmapVersion,
    );

    responseProjectData = overridePoRProjectWithVersionUpdates ? updatedVersionProject : responseProjectData;
  }

  return responseProjectData;
};

/**
 * @function baseUpdateProject
 *
 * Base function to invoke projects update endpoint and to create metadata if required.
 * If the project is integrated, then invoke also the update project on integration.
 *
 * @param {Object} project contains the changes made by the user and the id of the project (example: id and title)
 * @param {Object} existingProject current version of the project (what exists in the store/database)
 * @param {Object} options
 * @param {Boolean} options.skipJiraIntegrationUpdate
 * @returns {Promise}
 */
export const getUpdateProjectPromise = async (
  dispatch,
  updateData,
  project,
  {
    skipIntegrationsUpdate,
    overridePoRProjectWithVersionUpdates = true,
    selectedScenarioSelector = getSelectedRoadmapVersion,
  } = {},
) => {
  await _createMetadataIfDoesntExist(dispatch, updateData, project.id);

  const state = getStore().getState();

  const selectedRoadmapVersion = selectedScenarioSelector(state);

  const isScenarioRoute = selectIsScenarioRoute(state);

  const projectUpdateData =
    isScenarioRoute || selectedRoadmapVersion ? cleanUpdateDataAfterVersionUpdate(updateData) : updateData;

  const scenarioProjectUpdateData = omit(Object.keys(projectUpdateData), updateData);
  const serializedProjectUpdateData = serializeProject(projectUpdateData);
  const serializedScenarioUpdateData = serializeProject(scenarioProjectUpdateData);

  const hasFieldsToUpdate = Object.keys(serializedProjectUpdateData).length;
  const hasScenarioFieldsToUpdate = Object.keys(serializedScenarioUpdateData).length;

  // If the payload is empty cancel update
  if (!hasFieldsToUpdate && !hasScenarioFieldsToUpdate) return;

  const payload = (async () => {
    let parentData;
    let responseProjectData;

    if (hasFieldsToUpdate) {
      const shouldUpdateIntegrations = not(skipIntegrationsUpdate);

      responseProjectData = await _updateProjectAndDispatchUpdateProjectIntegration(
        dispatch,
        project,
        serializedProjectUpdateData,
        shouldUpdateIntegrations,
      );

      const hasParent = responseProjectData?.parent_id != null;
      const shouldFetchParent = project?.parent_id !== responseProjectData?.parent_id;

      if (shouldFetchParent) {
        parentData = hasParent ? await axios.get(`/api/projects/${responseProjectData.parent_id}`).then(res => res.data) : null;
      }
    }

    if (selectedRoadmapVersion) {
      responseProjectData = await handleUpdateScenarioProject(
        state,
        dispatch,
        project?.id,
        selectedRoadmapVersion,
        responseProjectData,
        serializedScenarioUpdateData,
        hasScenarioFieldsToUpdate,
        overridePoRProjectWithVersionUpdates,
      );
    }

    const metadataOnDemand = await loadMetadataForProjectIds([project.id]);

    return { projectData: responseProjectData, parentData, metadataOnDemand };
  })();

  return payload;
};

/**
 * Method to fetch project associations like tasks, estimates, jiras, integrations, parents and others
 *
 * @param {Array} projectsResult
 * @param {Object} params
 * @param {Object} associations
 * @param {Object} options
 * @param {Boolean} forceAssociations
 * @param {Number} selectedRoadmapVersionId
 * @param {Object} paginationAttributes - Limit and offset to use
 * @param {Object} searchMetadata - Projects search metadata
 * @return {Object}
 */
export const fetchProjectAssociations = async (
  projectsResult,
  params,
  associations,
  { customers, tags },
  forceAssociations,
  selectedRoadmapVersionId,
  paginationAttributes = {},
  searchMetadata = {},
) => {
  const { withTasks, withEstimates, withIntegrations, withParents, withDependencies } = associations || {};
  const { withIntegrations: forceWithIntegrations } = forceAssociations || {};

  const finalWithIntegrations = withIntegrations || forceWithIntegrations;

  let projects = (projectsResult || []).map(project => {
    const result = { ...project };

    if (withTasks) result.tasks = [];
    if (withEstimates) result.estimates = [];
    if (finalWithIntegrations) result.integrations = [];
    result.Jiras = [];
    return result;
  });

  // TECH DEBT: The filtering by customers and tags should be done in backend
  if (params && params.tags && tags) {
    const paramsTags = params.tags.split(',').map(id => +id);
    const tagsOptions = tags.map(tag => ({
      id: tag.id,
      selected: paramsTags.includes(tag.id),
    }));

    // Unassigned option
    tagsOptions.push({
      id: null,
      selected: paramsTags.includes(NaN),
    });

    projects = projects.filter(p => multioptionsFieldPassesFilter(p.tags, tagsOptions));
  } else if (params && 'tags' in params && !params.tags) {
    // projects = [];
  }

  if (params && params.customers && customers) {
    const paramsCustomers = params.customers.split(',').map(id => +id);
    const customersOptions = customers.map(customer => ({
      id: customer.id,
      selected: paramsCustomers.includes(customer.id),
    }));

    // Unassigned option
    customersOptions.push({
      id: null,
      selected: paramsCustomers.includes(NaN),
    });

    projects = projects.filter(p => multioptionsFieldPassesFilter(p.customers, customersOptions));
  } else if (params && 'customers' in params && !params.customers) {
    // projects = [];
  }

  const requests = [];
  let tasksResPos;
  let estimatesResPos = null;
  let integrationsResPos = null;
  let parentsResPos = null;
  let dependenciesResPos = null;
  let tasks = [];
  let parents = [];
  let dependencies = [];

  if (withTasks) {
    requests.push(
      searchTasksForProjectsAndParents({
        ...params,
        ...paginationAttributes,
        searchUuid: searchMetadata.searchUuid,
      }).catch(nonBlockingError),
    );
    tasksResPos = requests.length - 1;
  }
  if (withEstimates) {
    if (selectedRoadmapVersionId) {
      requests.push(getEstimatesForRoadmapVersion(selectedRoadmapVersionId));
    } else {
      requests.push(searchEstimatesForProjectsAndParents({ ...params, searchUuid: searchMetadata.searchUuid }));
    }
    estimatesResPos = requests.length - 1;
  }
  if (finalWithIntegrations) {
    requests.push(
      searchIntegrationsForProjectsAndParents({
        ...params,
        ...paginationAttributes,
        searchUuid: searchMetadata.searchUuid,
      }),
    );
    integrationsResPos = requests.length - 1;
  }
  if (withParents) {
    // force withParent to get the full hierarchy above, not just direct parents
    requests.push(
      searchParentsForProjects({
        ...params,
        searchUuid: searchMetadata.searchUuid,
      }).catch(nonBlockingError),
    );
    parentsResPos = requests.length - 1;
  }
  if (withDependencies) {
    requests.push(
      searchDependenciesForProjects({
        ...params,
        searchUuid: searchMetadata.searchUuid,
      }).catch(nonBlockingError),
    );
    dependenciesResPos = requests.length - 1;
  }

  // Todo: this request could be avoided if the organization does not have jira integration
  // Force withParent to be able to populate the Jira integration data also on parents projects
  requests.push(
    searchJirasForProjectsAndParents({
      ...params,
      ...paginationAttributes,
      searchUuid: searchMetadata.searchUuid,
    }),
  );
  const jirasResPos = requests.length - 1;

  // Todo: this request could be avoided if the organization does not have any integration
  requests.push(
    searchIntegrationProgressForProjectsAndParents({
      ...params,
      ...paginationAttributes,
      searchUuid: searchMetadata.searchUuid,
    }).catch(nonBlockingError),
  );
  const intProgressResPos = requests.length - 1;

  // Make all requests to get associations
  const res = await Promise.all(requests);

  if (withParents) {
    parents = res[parentsResPos].data?.data;
  }

  if (withTasks) {
    tasks = res[tasksResPos].data || [];

    // -> project 1
    //    - tasks: [
    //      subtasks: [ task1, task2, task3: { subtasks: [] }]
    //    ]
    projects = buildTasksChainAndAssignToProjects(projects, tasks);
    parents = buildTasksChainAndAssignToProjects(parents, tasks);
  }

  if (withEstimates) {
    const estimates = res[estimatesResPos].data || [];

    projects = assignEstimatesToProjects(projects, estimates);
    parents = assignEstimatesToProjects(parents, estimates);
  }

  if (finalWithIntegrations) {
    const integrations = res[integrationsResPos].data || [];

    projects = assignIntegrationsToProjects(projects, integrations);
    parents = assignIntegrationsToProjects(parents, integrations);
  }

  if (withDependencies) {
    dependencies = res[dependenciesResPos].data;
  }

  const jiras = res[jirasResPos].data;

  projects = assignJirasToProjects(projects, jiras);
  parents = assignJirasToProjects(parents, jiras);

  const intProgressByProj = {};

  res[intProgressResPos].data.forEach(intProgress => {
    intProgressByProj[intProgress.project_id] = [
      ...(intProgressByProj[intProgress.project_id] || []),
      intProgress.current_progress,
    ];
  });

  projects = assignIntegrationProgressToProjects(projects, intProgressByProj);
  parents = assignIntegrationProgressToProjects(parents, intProgressByProj);

  return { projects, tasks, parents, dependencies };
};

// TODO: [REFACTOR]
export const fetchProjects = (
  params,
  associations = {},
  { customers, tags } = {},
  storeAsFilters = false,
  addToIds = true,
  forceAssociations = {},
  extraFilters = {},
  paginationAttributes = {},
) => {
  return async dispatch => {
    let layers = params ? params.layer : null;

    if (layers && !Array.isArray(layers)) layers = [layers];

    const state = getStore().getState();

    const associationsAsArray = Object.entries(associations)
      .filter(([_, val]) => val)
      .map(([key]) => key);

    let votes;
    let projects;
    let projectsResult;
    let projectsSearchMetadataResult;
    let tasks;
    let parents;
    let dependencies;

    const result = new Promise(async (resolve, reject) => {
      const selectedRoadmapVersion = getSelectedRoadmapVersion(state);

      try {
        ({ projectsResult, projectsSearchMetadataResult } = await fetchFromProjectsApi({
          getStore,
          layers,
          storeAsFilters,
          params,
          paginationAttributes,
          extraFilters,
          selectedRoadmapVersion,
        }));

        ({ projects, tasks, parents, dependencies } = await fetchProjectAssociations(
          projectsResult,
          params,
          associationsAsArray.reduce((obj, association) => ({ ...obj, [association]: true }), {}),
          {
            customers,
            tags,
          },
          forceAssociations,
          selectedRoadmapVersion?.id,
          paginationAttributes,
          projectsSearchMetadataResult,
        ));

        const metadataOnDemand = await loadMetadataForProjectFilters({
          ...params,
          searchUuid: projectsSearchMetadataResult.searchUuid,
        });

        if (associationsAsArray.includes('withVotes')) {
          try {
            votes = await fetchVotesPerProjectApiCall({
              ...params,
              searchUuid: projectsSearchMetadataResult.searchUuid,
            });
          } catch (error) {
            nonBlockingError(error);
          }
        }

        resolve({
          projects,
          projectsSearchMetadataResult,
          tasks,
          parents,
          dependencies,
          votes,
          metadataOnDemand,
        });
      } catch (e) {
        reject();
      }
    });

    dispatch({
      type: APPLY_FILTERS,
      payload: result.then(data => data),
      meta: {
        storeAsFilters,
        layers: ['0', '1', '2'],
        params: omit(['layer'], params),
        addToIds,
        associations: associationsAsArray,
        projectsSearchMetadataResult,
      },
    });

    return result;
  };
};

export const fetchProjectsChildren = (parents, layer, filters, addToIds = false, otherFilterParams = {}) => {
  const _excludeArchivedFromChildrenIfExcludedFromParent = filters => {
    if (
      ((filters?.op?.planningStages === 'in' || !filters?.op?.planningSages) &&
        !(filters?.planningStages || []).includes('Archived')) ||
      (filters?.op?.planningStages === 'notIn' && (filters?.planningStages || []).includes('Archived'))
    ) {
      return {
        planningStages: ['Archived'],
        op: { planningStages: 'notIn' },
      };
    }
    return {};
  };

  return dispatch => {
    const result = new Promise(async resolve => {
      const params = {
        ...otherFilterParams,
        fields: [
          {
            parent_id: (parents || '')
              .toString()
              .split(',')
              .filter(id => id !== 'null')
              .join(','),
            ...(filters
              ? { ...getFiltersToFetchProjects(_excludeArchivedFromChildrenIfExcludedFromParent(filters), GLOBAL_FILTER) }
              : {}),
            ...(layer ? { layer: Array.isArray(layer) ? layer.join(',') : layer } : {}),
          },
        ],
      };

      let childrenProjectsResult;

      if (head(params?.fields)?.parent_id) {
        childrenProjectsResult = (await axios.post('/api/projects/search', params)).data.data;
      }

      const { projects, tasks } = await fetchProjectAssociations(
        childrenProjectsResult || [],
        params,
        { withTasks: true, withEstimates: true, withIntegrations: true, withVotes: true },
        {},
      );

      dispatch({
        type: SET_TASKS,
        payload: [...(tasks || [])],
        merge: true,
      });

      resolve([...(projects || [])]);
    });

    dispatch({
      payload: result,
      meta: { parentsIds: parents ? String(parents).split(',') : [], layer, addToIds },
      type: FETCH_PROJECTS_CHILDREN,
    });

    return result;
  };
};

export const getCreateProjectPromise = async (dispatch, project) => {
  await _createMetadataIfDoesntExist(dispatch, project);
  const { lifecycles, personas, ...projectFields } = project;

  const payload = (async () => {
    let createdPersonas = [];
    let createdLifecycles = [];
    let metadataOnDemand = {};

    const createdProject = await axios.post('/api/projects', serializeProject(projectFields)).then(res => {
      return res.data;
    });

    if (personas?.length && createdProject?.id) {
      const personaIds = personas?.map(p => p?.id);

      createdPersonas = await axios
        .post(`/api/projects/${createdProject?.id}/personas`, { personaIds })
        .then(res => res.data?.personas);
    }

    if (lifecycles?.length && createdProject?.id) {
      const lifecycleIds = lifecycles?.map(l => l?.id);

      createdLifecycles = await axios
        .post(`/api/projects/${createdProject?.id}/lifecycles`, { lifecycleIds })
        .then(res => res.data?.lifecycles);
    }

    if (createdProject?.id) {
      metadataOnDemand = await loadMetadataForProjectIds([createdProject.id]);
    }

    return { ...createdProject, personas: createdPersonas, lifecycles: createdLifecycles, metadataOnDemand };
  })();

  return payload;
};

export const createProject = project => {
  delete project.id;
  project.isNew = true;
  return async dispatch => {
    const payload = getCreateProjectPromise(dispatch, project);

    dispatch({
      payload: {
        data: { ...project },
        promise: payload,
      },
      type: CREATE_PROJECT,
    });

    return payload;
  };
};

// Creates a project and links it to the request that started it
export const createProjectFromRequest = (project, requestId) => {
  return async dispatch => {
    const payload = dispatch(createProject(project));

    payload.then(res => {
      if (res?.id && requestId) dispatch(addProjectToCustomerRequestAction(requestId, res?.id));
    });

    return payload;
  };
};

// TODO: /update/id is not RESTful
export const updateProject =
  (project, jiraSync = true, useWholeObject = false) =>
  async dispatch => {
    const shouldDiff = not(useWholeObject);

    const state = getStore().getState();

    const existingProject = getSelectedProject(state, { id: project.id });

    let updateData = project;

    if (shouldDiff) {
      updateData = getProjectDiff(project, existingProject);
    }

    const promise = getUpdateProjectPromise(dispatch, updateData, existingProject, { skipIntegrationsUpdate: !jiraSync });

    dispatch({
      payload: {
        data: { ...project },
        promise,
      },
      type: UPDATE_PROJECT,
    });

    return promise;
  };

export const updateProjectOnLigthbox =
  (project, jiraSync = true, useWholeObject = false) =>
  async dispatch => {
    const shouldDiff = not(useWholeObject);

    const state = getStore().getState();
    const existingProject = getSelectedProjectOnLigthbox(state);

    const customFields = getDropdownCustomFields(state, true); // get dropdown CFs including archived

    let updateData = project;

    if (shouldDiff) {
      updateData = getProjectDiff(project, existingProject);
    }

    const promise = getUpdateProjectPromise(dispatch, updateData, existingProject, {
      skipIntegrationsUpdate: !jiraSync,
      selectedScenarioSelector: selectScenarioSelected,
    });

    dispatch({
      payload: {
        data: { ...project },
        promise,
      },
      type: UPDATE_PROJECT,
      meta: {
        customFields,
      },
    });

    return promise;
  };

export const updateProjectOnGrids = (id, update) => async dispatch => {
  if (!update || !id) return;
  const state = getStore().getState();

  const existingProject = getSelectedProject(state, { id });

  update.id = id;

  const promise = getUpdateProjectPromise(dispatch, update, existingProject);

  dispatch({
    payload: {
      data: { ...update },
      promise,
    },
    meta: {
      batch: true,
    },
    type: UPDATE_PROJECT,
  });

  return promise;
};

export const updateProjectOnCompareScenario = update => async dispatch => {
  const state = getStore().getState();
  const existingProject = getSelectedProject(state, { id: update.id });

  const promise = getUpdateProjectPromise(dispatch, update, existingProject, {
    selectedScenarioSelector: getCompareVersionEditScenario,
    overridePoRProjectWithVersionUpdates: false,
  });

  dispatch({
    payload: {
      data: update,
      promise,
    },
    type: UPDATE_PROJECT,
  });

  return promise;
};

export const updateProjectById = (id, update) => {
  if (!update || !id) return;

  update.id = id;

  return updateProject(update);
};

export const updateLocalProjectById = (id, update) => {
  return dispatch => {
    const state = getStore().getState();
    const existingProject = getSelectedProject(state, { id });

    let estimates = [];
    let tasks = [];

    if (update.estimates.length > 0) {
      estimates = existingProject.estimates.map(estimate => {
        return Object.assign(
          {},
          estimate,
          update.estimates.find(e => e.id === estimate.id),
        );
      });
    }

    if (update.tasks.length > 0) {
      tasks = existingProject.tasks.map(task => {
        return Object.assign(
          {},
          task,
          update.tasks.find(e => e.id === task.id),
        );
      });
    }

    const projectToUpdate = Object.assign({}, existingProject, update, { tasks, estimates });

    dispatch({
      payload: projectToUpdate,
      type: UPDATE_PROJECT_FULFILLED,
    });

    return projectToUpdate;
  };
};

export const deleteProjects = ids => {
  return dispatch => {
    const payload = axios
      .delete('/api/projects', {
        data: {
          ids,
        },
      })
      .then(res => res.data);

    dispatch({
      type: DELETE_PROJECTS,
      payload,
    });

    return payload;
  };
};

export function mergeProjects(id, idsToMerge) {
  return dispatch => {
    const payload = axios.post(`/api/projects/${id}/merge`, { idsToMerge }).then(res => res.data);

    dispatch({
      type: MERGE_PROJECTS,
      payload,
    });

    return payload;
  };
}

export function bulkCreateProjects(projects, socketRoom) {
  return dispatch => {
    const payload = axios
      .post(
        `/api/projects${socketRoom ? `?socketRoom=${socketRoom}` : ''}`,
        projects.map(project => serializeProject(project)),
      )
      .then(res => res.data);

    dispatch({
      type: BULK_CREATE_PROJECTS,
      payload,
    });

    return payload;
  };
}

export const fetchProjectCustomerRequests = projectId => {
  return {
    type: FETCH_PROJECT_CUSTOMER_REQUESTS,
    payload: axios.get(`/api/projects/${projectId}/customer-requests`).then(({ data }) => data),
    meta: {
      projectId,
    },
  };
};

export function bulkUpdateProjects(projects) {
  return bulkUpdateAction(
    BULK_UPDATE_PROJECTS,
    '/api/projects',
    null,
    transformObjectWithNestedKeys,
  )(projects instanceof Array ? projects.map(project => serializeProject(project)) : serializeProject(projects));
  // trigger update fields in integration
}

// if we are on a scenario creation/edition we should discard the updates of versionable fields
const handleUpdatePayloadFields = (data, selectedRoadmapVersion) => {
  if (not(selectedRoadmapVersion)) {
    return data;
  }

  const project = data.projectData ?? data;
  const projectData = selectedRoadmapVersion ? omit(fieldsFromVersion, project) : project;

  if (data.projectData) {
    return { ...data, projectData };
  }

  return projectData;
};

export function gotProjectRealtimeUpdate(type, data, currentUserId, userId) {
  return (dispatch, getState) => {
    switch (type) {
      case CREATE:
        return dispatch({
          type: CREATE_PROJECT_FULFILLED,
          payload: data,
          realtime: true,
        });
      case BULK_CREATE:
        if (!Array.isArray(data)) {
          data = [data];
        }
        return dispatch({
          type: BULK_CREATE_PROJECTS_FULFILLED,
          payload: data,
        });
      case UPDATE:
        const state = getState();
        // realtime updates cannot override versionable fields while on scenario mode
        const selectedRoadmapVersion = getSelectedRoadmapVersion(state);

        return dispatch({
          type: UPDATE_PROJECT_FULFILLED,
          payload: handleUpdatePayloadFields(data, selectedRoadmapVersion),
          realtime: true,
          meta: { batch: true },
        });
      case BULK_UPDATE:
        return dispatch({
          type: BULK_UPDATE_PROJECTS_FULFILLED,
          payload: data,
        });
      case DELETE:
        return dispatch({
          type: DELETE_PROJECTS_FULFILLED,
          payload: data,
        });
      case BULK_DELETE:
        return dispatch({
          type: DELETE_PROJECTS_FULFILLED,
          payload: data,
        });
      case BULK_UPDATE_PROGRESS:
        return dispatch({
          type: REALTIME_BULK_UPDATE_PROGRESS,
          payload: data,
        });
      case ASYNC_IMPORT_INTEGRATION_PROJECTS:
        return dispatch({
          type: IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC_RESULT,
          payload: data.payload,
          meta: data.meta,
        });
      default:
    }
  };
}

export function setProjectData(project) {
  return {
    type: UPDATE_PROJECT_FULFILLED,
    payload: project,
  };
}

export const getProjectsCountersPyPhase =
  (groupType = 'roadmap', countLayer = '1') =>
  async dispatch => {
    return dispatch({
      type: GET_PROJECT_COUNTERS_BY_PHASE,
      payload: axios
        .get(`/api/projects/counters-by-phase?group_type=${groupType}&count_layer=${countLayer}`)
        .then(({ data }) => data),
    });
  };

export const addFileToProject = (project, file) => async dispatch => {
  if (project.files.length >= 3) {
    project.files.shift();
    project.files.push(file);
  } else if (project.files.length > 0) {
    project.files.push(file);
  } else {
    project.files = [file];
  }

  return dispatch({
    type: UPDATE_PROJECT_FULFILLED,
    payload: {
      ...project,
    },
  });
};

export const removeFileFromProject = (file, parent) => async dispatch => {
  if (!file.id || !parent.id) return;

  const fileDeleted = await axios
    .delete(`/api/projects/${parent.id}/files/${file.id}`)
    .then(res => {
      const { data } = res;

      dispatch(
        undoAction(
          `Image ${file.name} was deleted from ${parent.title}`,
          UPDATE_PROJECT_FULFILLED,
          'projects',
          `/api/projects/${parent.id}/files/${file.id}/versions/last`,
          restoredFile => {
            parent.files = [restoredFile];

            return dispatch({
              type: UPDATE_PROJECT_FULFILLED,
              payload: {
                ...parent,
              },
            });
          },
        ),
      );

      return data;
    })
    .catch(err => {
      console.error(err.name, err.message);
    });

  parent.files = parent.files.filter(f => +f.id !== fileDeleted);

  return dispatch({
    type: UPDATE_PROJECT_FULFILLED,
    payload: {
      ...parent,
    },
  });
};

export const watchProject = project => {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.login.currentUser.id;

    if (project) {
      return dispatch({
        type: WATCH_PROJECT,
        payload: axios.post(`/api/projects/${project.id}/watch`).then(res => res.data),
        meta: {
          user_id: userId,
          project_id: project.id,
          layer: project.layer,
        },
      });
    }
  };
};

export const unwatchProject = project => {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.login.currentUser.id;

    return dispatch({
      type: UNWATCH_PROJECT,
      payload: axios.delete(`/api/projects/${project.id}/watch`).then(res => res.data),
      meta: {
        user_id: userId,
        project_id: project.id,
        layer: project.layer,
      },
    });
  };
};

export const cloneProject = (projectId, includes = {}) => {
  return dispatch => {
    return dispatch({
      type: CLONE_PROJECT,
      payload: axios.post(`/api/projects/${projectId}/clone`, { include: includes }).then(res => res.data),
      meta: {
        projectId,
      },
    });
  };
};

export const addChildrenToProjectOnLightbox = update => async dispatch => {
  const state = getStore().getState();
  const existingProject = getSelectedProject(state, { id: update.id });
  const projectToUpdate = existingProject || { id: update.id };

  const promise = getUpdateProjectPromise(dispatch, update, projectToUpdate, { skipIntegrationsUpdate: false });

  dispatch({
    payload: {
      data: { ...update },
      promise,
    },
    type: UPDATE_PROJECT,
  });

  return promise;
};

export const createUnsavedProject = project => ({
  payload: project,
  type: CREATE_UNSAVED_PROJECT,
});

export const removeUnsavedProject = () => ({
  type: REMOVE_UNSAVED_PROJECT,
});

export const resetProjectCreation = () => ({
  type: CREATE_PROJECT_RESET,
});

export const resetProjectUpdate = () => ({
  type: UPDATE_PROJECT_RESET,
});

export const resetProjectRowOrderUpdate = () => ({
  type: UPDATE_PROJECT_ROW_ORDER_RESET,
});

// TODO: We should remove this from all places in the project
export const projectActions = {
  fetchProjects,
  fetchProjectsChildren,
  updateProjects,
  createProject,
  updateProject,
  deleteProjects,
  switchProjectRowOrder,
  bulkCreateProjects,
  bulkUpdateProjects,
  gotProjectRealtimeUpdate,
  createProjectEstimate,
  updateProjectEstimate,
  deleteProjectEstimate,
  gotEstimatesRealtimeUpdate,
  gotTasksRealtimeUpdate,
  mergeProjects,
  cloneProject,
  addChildrenToProjectOnLightbox,
  syncProjectFromJira,
  createAllProjectsJiras,
  getProjectStories,
  addProjectIntegration,
  removeProjectIntegration,
  resetProjectCreation,
  resetProjectUpdate,
  resetProjectRowOrderUpdate,
};
