import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import PageTemplate from 'design-system/templates/PageTemplate';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getOrganizationIntegrations } from 'store/organization/selectors';
import { getCurrentUser } from 'store/login/selectors';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

const Wrapper = styled(PageTemplate)`
  &&&& {
  }
`;

const IntegrationWrapper = styled(Grid)`
  &&&& {
    padding: 16px 8px 8px 8px;
  }
`;

const Container = styled.div`
  &&&& {
    min-height: calc(100vh - 68px);
    overflow: auto;
    height: calc(100vh - 68px);
    padding-bottom: 60px;

    display: flex;
    justify-content: center;
  }
`;

const Component = props => {
  const [loaded, setLoaded] = React.useState(false);
  const { visibleIntegrations, userIntegrations = [] } = props;

  const permissions = usePermissions();

  const loadOrgAndUserIntegrations = () => Promise.all([props.fetchOrgIntegrations(), props.fetchUserIntegrations()]);
  const _removeIntegration = async (integrationType, orgIntegrationId) => {
    await props.removeOrgIntegration(integrationType, orgIntegrationId);

    await loadOrgAndUserIntegrations();

    return Promise.resolve();
  };

  React.useEffect(() => {
    loadOrgAndUserIntegrations().then(() => setLoaded(true));
  }, [userIntegrations.length]);

  const routes = useMemo(
    () =>
      visibleIntegrations.map(integration => {
        return (
          <Route
            exact
            path={[`/settings/integrations/${integration.name}`, `/settings/integrations/${integration.name}/:id`]}
            component={() => {
              const orgIntegrations = useSelector(getOrganizationIntegrations);
              const currentUser = useSelector(getCurrentUser);

              const userIntegration = userIntegrations.find(ui => ui.integrationType === integration.userIntegrationType);
              const orgIntegration = orgIntegrations.find(oi => oi.integrationType === integration.integrationType);

              const canUpdateOrgIntegration = permissions.canUpdate(PERMISSION_RESOURCES.orgIntegration, {
                integrationType: integration.integrationType,
              });

              const canViewOrgIntegration = permissions.canView(PERMISSION_RESOURCES.orgIntegration, {
                integrationType: integration.integrationType,
              });

              const Component = integration.component;

              return (
                <IntegrationWrapper item xs={11}>
                  <Component
                    integration={integration}
                    addUserIntegration={token => {
                      return props.addUserIntegration(integration.integrationType, orgIntegration?.id, token);
                    }}
                    addUserIntegrationError={props.addUserIntegrationError}
                    addOrgIntegration={opts => props.addOrgIntegration(integration.integrationType, opts)}
                    removeUserIntegration={() => props.removeUserIntegration(integration.integrationType, orgIntegration?.id)}
                    removeIntegration={() => _removeIntegration(orgIntegration.integrationType, orgIntegration.id)}
                    userCanUpdateOrgIntegration={canUpdateOrgIntegration}
                    userCanViewOrgIntegration={canViewOrgIntegration}
                    userIntegration={userIntegration}
                    orgIntegration={orgIntegration}
                    loadOrgAndUserIntegrations={loadOrgAndUserIntegrations}
                    currentUser={currentUser}
                    handleTestConnection={props.handleTestConnection}
                  />
                </IntegrationWrapper>
              );
            }}
            key={integration.label}
          />
        );
      }),
    [userIntegrations, props.addUserIntegrationError],
  );

  return (
    <Wrapper>
      <Container>{!!visibleIntegrations && visibleIntegrations.length > 0 && loaded && routes}</Container>
    </Wrapper>
  );
};

export default Component;
