import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 26px 5px 0;
`;

const ContainerStyles = css`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const LeftContainer = styled.div`
  ${ContainerStyles};
`;

const RightContainer = styled.div`
  ${ContainerStyles};
  align-items: center;
`;

const Strong = styled.span`
  font-weight: bold;
`;

export { LeftContainer, RightContainer, Strong, Wrapper };
