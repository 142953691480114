import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import PageHeaderFilters from 'containers/PageHeaderFilters';
import DashboardTitle from './DashboardTitle';

import { getTotalProjectResults } from 'store/projects';

import { DASHBOARDS_PAGE } from 'constants/filters/pages';
import isMobileScreen from 'utils/isMobileScreen';

export default props => {
  const totalResults = useSelector(getTotalProjectResults);

  return (
    <Wrapper>
      <DashboardTitle />
      {isMobileScreen() ? null : (
        <PageHeaderFilters
          page={DASHBOARDS_PAGE}
          showQuickFilter={false}
          showDisplayLayer={false}
          showPageIcon={false}
          customNumberOfResults={totalResults}
          showFilters={props.showFilters}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;
