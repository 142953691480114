import axios from 'axios';
import React, { useState } from 'react';
import formatDate from 'utils/dates/formatDate';
import Grid from '@material-ui/core/Grid';
import ConfirmDialog from 'components/ConfirmDialog';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import copyToClipboard from 'utils/copyToClipboard';

function TokenRow(props) {
  const [isVisible, setVisible] = useState(true);
  const [showConfirmationDialog, changeConfirmationDialogVisibility] = useState(false);

  const revokeApiKey = id => event => {
    event.preventDefault();

    closeDialog();

    return axios.delete(`/api/api-tokens/${id}`).then(response => {
      if (response.status === 204) setVisible(false);
    });
  };

  const copyKeyToClipboard = async apiKey => {
    await copyToClipboard(apiKey);
  };

  const openDialog = () => {
    changeConfirmationDialogVisibility(true);
  };

  const closeDialog = () => {
    changeConfirmationDialogVisibility(false);
  };

  const getFullName = user => user.first_name.concat(' ').concat(user.last_name);

  return (
    <>
      {isVisible && (
        <>
          <Grid container style={{ marginBottom: '6px' }}>
            <TextCol item xs={4}>
              <TextDeprecated>{props.label}</TextDeprecated>
            </TextCol>
            <TextCol item xs={4}>
              <TextDeprecated>{getFullName(props.owner)}</TextDeprecated>
            </TextCol>
            <TextCol item xs={3}>
              <TextDeprecated>Created at {formatDate(props.created_at)}</TextDeprecated>
            </TextCol>
            <RemoveGridCol item md={1}>
              {props.jwtToken && (
                <ButtonWithIcon onClick={() => copyKeyToClipboard(props.jwtToken)} title="Copy key to clipboard">
                  <CopyIcon />
                </ButtonWithIcon>
              )}
              <ButtonWithIcon onClick={openDialog} data-testid="delete-link-button">
                <DeleteIcon />
              </ButtonWithIcon>
            </RemoveGridCol>
          </Grid>
        </>
      )}

      <ConfirmDialog
        isOpen={showConfirmationDialog}
        onConfirm={revokeApiKey(props.id)}
        onCancel={closeDialog}
        title="Delete selected API key?"
        text={`Are you sure you want to revoke the ${props.label} API key? This action cannot be reverted.`}
      />
    </>
  );
}
const ButtonWithIcon = styled(Button)`
  && {
    min-width: unset;
  }
`;

const RemoveGridCol = styled(Grid)`
  &&&& {
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }
`;

const TextCol = styled(Grid)`
  &&&& {
    display: flex;
    align-items: center;
  }
`;

export default TokenRow;
