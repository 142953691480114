import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

export default styled(props => {
  return <Paper {...props} elevation={0} />;
})`
  &&&& {
    position: relative;
    padding: 6px 8px;
    box-sizing: border-box;
    min-height: 32px;
    background: transparent;
    outline: none;
    max-width: 100%;
    ${({ width = '100%' }) => width && `width: ${width}`}
    > * {
      width: 100%;
    }
  }
`;
