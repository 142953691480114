import { updateProjectOnCollectionsUsingMethod } from '../helpers/collections';

const setProgress = (state, payload) => {
  const newCollections = updateProjectOnCollectionsUsingMethod(state, collection => {
    return payload.reduce((newCollection, p) => {
      const index = newCollection.findIndex(obj => +obj.get('id') === +p.id);

      if (index >= 0) {
        const project = newCollection
          .get(index)
          .set('progress_calculated', p.progress_calculated)
          .set('progress', p.progress)
          .set('progress_type', p.progress_type)
          .set('jira_progress', p.jira_progress)
          .set('start_date_calculated', p.start_date_calculated)
          .set('end_date_calculated', p.end_date_calculated);
        // .set('predicted_start_date_calculated', p.predicted_start_date_calculated)
        // .set('predicted_end_date_calculated', p.predicted_end_date_calculated);

        return newCollection.set(index, project);
      }

      return newCollection;
    }, collection);
  });

  return {
    ...state,
    ...newCollections,
  };
};

export { setProgress };
