import React from 'react';
import { useSelector } from 'react-redux';

import AddRecurringButton from './components/AddRecurringButton/AddRecurringButton';
import { isSharingViewOnIntegration } from 'store/userViews/selectors';

const ShareUserViewViaIntegrationButton = ({ integrationType, onClick, active }) => {
  const isSharingOperationInProgress = useSelector(isSharingViewOnIntegration);

  return (
    <AddRecurringButton
      integrationType={integrationType}
      onClick={onClick}
      active={active}
      loading={isSharingOperationInProgress}
    />
  );
};

export default ShareUserViewViaIntegrationButton;
