import { useMemo } from 'react';
import createMentionPlugin from 'draft-js-mention-plugin';

const DEFAULT_OPTIONS = {
  entityMutability: 'IMMUTABLE',
};

const useCreateMentionPlugin = () => {
  const { plugins, MentionSuggestions } = useMemo(() => {
    const mentionPlugin = createMentionPlugin(DEFAULT_OPTIONS);

    const { MentionSuggestions } = mentionPlugin;

    return { plugins: [mentionPlugin], MentionSuggestions };
  }, []);

  return { plugins, MentionSuggestions };
};

export default useCreateMentionPlugin;
