import { BELOW_NOT_INCLUDED_CLASS } from 'containers/IdeasForecastList/styled';

import { EXCLUDE_AND_DISPLAY_UNCOMMITTED_OPTION, EXCLUDE_UNCOMMITTED_OPTION, INCLUDE_ALL_OPTION } from 'constants/projects';

/**
 * Forecast have two different ways to dealt with Above and Below the line projects and we need to account them both.
 * Forecast by headcount allows to have all combinations of including above or below the line projects.
 * Forecast by Time or Time and Capacity ALWAYS include above the line projects and below projects are toggle on/off.
 *
 * Depending on each selection we're at we need to check different conditions to grey it out or not
 * @type {{"[BELOW_NOT_INCLUDED_CLASS]": (function(*))}}
 */
const rowClassRules = {
  [BELOW_NOT_INCLUDED_CLASS]: params => {
    const {
      data,
      context: { showUncommittedProjects = INCLUDE_ALL_OPTION, isForecastByHeadcountSelected = false },
    } = params;

    const timeAndCapacityProjectNotIncluded =
      [EXCLUDE_AND_DISPLAY_UNCOMMITTED_OPTION, EXCLUDE_UNCOMMITTED_OPTION].includes(showUncommittedProjects) && !data?.committed;
    const headcountProjectNotIncluded = !data?.includeInSums;
    const isNotIncluded = isForecastByHeadcountSelected ? headcountProjectNotIncluded : timeAndCapacityProjectNotIncluded;

    return !data?.group && isNotIncluded;
  },
};

export default rowClassRules;
