import { useMemo } from 'react';

import { useSettingsGridProps } from 'containers/Grids/SettingsGrid/hooks';

import { isParentItem, getDataPath, getRowId } from '../helpers';

const useTeamsGridProps = ({ createTeams, updateTeams }) => {
  const settingsGridProps = useSettingsGridProps({
    bulkCreate: createTeams,
    bulkUpdate: updateTeams,
    treeData: true,
  });

  return useMemo(
    () => ({
      ...settingsGridProps,
      rowClassRules: {
        'metadata-parent-row': params => {
          if (!params.data) {
            return false;
          }

          return isParentItem(params.data);
        },
      },
      groupDisplayType: 'custom',
      suppressRowClickSelection: true,
      getDataPath,
      getRowId,
    }),
    [settingsGridProps],
  );
};

export default useTeamsGridProps;
