/* eslint jsx-a11y/anchor-is-valid: 0 */
// External dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';

// dragonboat dependencies
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import LightBox from 'components/LightBox';
import CloseButton from 'components/DialogTitle/CloseButton';
import _getSystemFieldName from 'utils/getSystemFieldName';

import { IMPORT_WIZARD_OPTION_IDS, getDefaultSelectedOption } from './utils';

class ImportWizardLightbox extends Component {
  constructor(props) {
    super(props);

    const { showJiraOption, dragonboatOnDragonboatIntegrated } = props;

    this.state = {
      importType: getDefaultSelectedOption({ showJiraOption, dragonboatOnDragonboatIntegrated }),
    };
  }

  showCopyPasteLightbox = () => {
    const { setCopyPasteData, showCopyPasteLightbox, onClose, onSelectCopyPaste } = this.props;
    const newArray = [];

    if (onSelectCopyPaste) {
      return onSelectCopyPaste();
    }

    for (let i = 0; i < 100; i++) newArray.push({});

    setCopyPasteData(newArray);
    onClose();
    showCopyPasteLightbox();
  };

  importClick = () => {
    const { onClose, importClick } = this.props;

    onClose();
    importClick();
  };

  onChange = e => {
    this.setState({
      importType: e.target.value,
    });
  };

  goToNextStep = onClick => {
    const {
      jiraIntegrated,
      azureDevopsIntegrated,
      rallyIntegrated,
      onClose,
      showImportJqlLightbox,
      onUserWithoutJira,
      showImportADOQueryLightbox,
      onUserWithoutAzureDevops,
      showImportRallyViewLightbox,
      onUserWithoutRally,
      showImportDragonboatFilterLightbox,
      openProjectLightbox,
    } = this.props;
    const { importType } = this.state;

    switch (importType) {
      case IMPORT_WIZARD_OPTION_IDS.COPY_AND_PASTE:
        this.showCopyPasteLightbox();
        break;
      case IMPORT_WIZARD_OPTION_IDS.IMPORT_JQL:
        onClose();

        if (jiraIntegrated) {
          showImportJqlLightbox();
        } else {
          onUserWithoutJira();
        }
        break;
      case IMPORT_WIZARD_OPTION_IDS.IMPORT_ADO_QUERY:
        onClose();

        if (azureDevopsIntegrated) {
          showImportADOQueryLightbox();
        } else {
          onUserWithoutAzureDevops();
        }
        break;
      case IMPORT_WIZARD_OPTION_IDS.IMPORT_RALLY_VIEW:
        onClose();

        if (rallyIntegrated) {
          showImportRallyViewLightbox();
        } else {
          onUserWithoutRally();
        }
        break;
      case IMPORT_WIZARD_OPTION_IDS.IMPORT_DRAGONBOAT_USER_FILTER:
        onClose();

        showImportDragonboatFilterLightbox();
        break;
      case IMPORT_WIZARD_OPTION_IDS.START_FRESH:
        onClose();

        openProjectLightbox();
        break;
      default:
    }
  };

  _renderRadioOption(value, text) {
    return (
      <RadioControlLabel
        value={value}
        control={<Radio />}
        label={
          <TextDeprecated breakwords size="medium">
            {text}
          </TextDeprecated>
        }
      />
    );
  }

  _renderImportCsvContainer() {
    return (
      <ImportCsvContainer>
        <InputFileContainer onClick={this.importClick}>
          <BrowseButton size="small" variant="contained">
            Browse
          </BrowseButton>
        </InputFileContainer>
        <ImportInstructions>
          <TextDeprecated breakwords size="small">
            Map the reference ID (or Jira issue key) to the Link field for future reference.
          </TextDeprecated>
        </ImportInstructions>
      </ImportCsvContainer>
    );
  }

  render() {
    const {
      isOpen,
      onClose,
      systemFields,
      jiraIntegrated,
      showJiraOption,
      azureDevopsIntegrated,
      showAzureDevopsOption,
      rallyIntegrated,
      showRallyOption,
      dragonboatOnDragonboatIntegrated,
    } = this.props;
    const { importType } = this.state;

    const getSystemFieldName = (name, plural) => _getSystemFieldName(name, systemFields, plural);

    const isImportTypeCsv = importType === IMPORT_WIZARD_OPTION_IDS.IMPORT_CSV;
    const shouldRenderImportEpicsFromJira = showJiraOption && !dragonboatOnDragonboatIntegrated;
    const shouldRenderImportWorkItemsFromADO = showAzureDevopsOption && !dragonboatOnDragonboatIntegrated;
    const shouldRenderImportWorkitemsFromRally = showRallyOption && !dragonboatOnDragonboatIntegrated;

    return (
      <LightBox
        isOpen={isOpen}
        onClose={onClose}
        maxWidth="lg"
        dataCy="import-ideas-dialog"
        content={
          <Wrapper>
            <CloseButton onClick={onClose} />
            <DialogContent>
              <DialogTitle>Start building your {getSystemFieldName('roadmap')}</DialogTitle>
              <RadioGroup value={importType} onChange={this.onChange}>
                {shouldRenderImportEpicsFromJira &&
                  this._renderRadioOption(IMPORT_WIZARD_OPTION_IDS.IMPORT_JQL, 'Import Epics from Jira')}
                {shouldRenderImportEpicsFromJira && !jiraIntegrated && (
                  <SmallIntegrationText>You need Jira System Admin Access to integrate with Jira</SmallIntegrationText>
                )}
                {dragonboatOnDragonboatIntegrated &&
                  this._renderRadioOption(
                    IMPORT_WIZARD_OPTION_IDS.IMPORT_DRAGONBOAT_USER_FILTER,
                    'Import from Dragonboat Portfolio account',
                  )}
                {shouldRenderImportWorkItemsFromADO &&
                  this._renderRadioOption(IMPORT_WIZARD_OPTION_IDS.IMPORT_ADO_QUERY, 'Import Work Items from Azure Devops')}
                {shouldRenderImportWorkItemsFromADO && !azureDevopsIntegrated && (
                  <SmallIntegrationText>
                    You need Azure Devops System Admin Access to integrate with Azure Devops
                  </SmallIntegrationText>
                )}
                {shouldRenderImportWorkitemsFromRally &&
                  this._renderRadioOption(IMPORT_WIZARD_OPTION_IDS.IMPORT_RALLY_VIEW, 'Import Work Items from Rally')}
                {shouldRenderImportWorkitemsFromRally && !rallyIntegrated && (
                  <SmallIntegrationText>You need Rally System Admin Access to integrate with Rally</SmallIntegrationText>
                )}
                {this._renderRadioOption(
                  IMPORT_WIZARD_OPTION_IDS.COPY_AND_PASTE,
                  `Copy and paste ${getSystemFieldName('roadmap')} Items from spreadsheets`,
                )}
                {this._renderRadioOption(IMPORT_WIZARD_OPTION_IDS.IMPORT_CSV, 'Import from a csv file')}
                {this._renderImportCsvContainer()}
                {this._renderRadioOption(
                  IMPORT_WIZARD_OPTION_IDS.START_FRESH,
                  `Start fresh? Add your ${getSystemFieldName('idea', true)} directly in dragonboat`,
                )}
                <ActionsContainer>
                  <NextButton color="primary" onClick={() => this.goToNextStep(true)} disabled={isImportTypeCsv}>
                    Next
                  </NextButton>
                </ActionsContainer>
              </RadioGroup>
            </DialogContent>
          </Wrapper>
        }
      />
    );
  }
}

const Wrapper = styled.div`
  max-width: 700px;
`;

const ImportCsvContainer = styled.div`
  margin-left: 34px;
  padding-top: 5px;
  padding-bottom: 5px;

  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`;

const InputFileContainer = styled.div`
  text-align: right;
  width: 300px;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  cursor: pointer;

  &&&:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
`;

const ImportInstructions = styled.p`
  margin-top: 15px;
  font-size: 0.875rem;
`;

const DialogContent = styled.div``;

const DialogTitle = styled.h4`
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 1rem;
`;

const SmallIntegrationText = styled.p`
  margin-left: 46px;
  font-size: 12px;
  margin-top: -15px;
  margin-bottom: 10px;
`;

const ActionsContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row-reverse;
`;

const NextButton = styled(Button)`
  &&&& {
  }
`;

const BrowseButton = styled(Button)`
  &&& {
    text-transform: none;
    box-shadow: none;
    border-radius: 0px;
  }
`;

const RadioControlLabel = styled(FormControlLabel)`
  &&&& {
    margin: 8px 0;
  }
`;

ImportWizardLightbox.propTypes = {
  setCopyPasteData: PropTypes.func.isRequired,
  showCopyPasteLightbox: PropTypes.func.isRequired,
  importClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  jiraIntegrated: PropTypes.bool.isRequired,
  azureDevopsIntegrated: PropTypes.bool.isRequired,
  rallyIntegrated: PropTypes.bool.isRequired,
  showJiraOption: PropTypes.bool.isRequired,
  showAzureDevopsOption: PropTypes.bool.isRequired,
  showRallyOption: PropTypes.bool.isRequired,
  // onStartFresh: PropTypes.func.isRequired,
  onUserWithoutAzureDevops: PropTypes.func.isRequired,
  onUserWithoutRally: PropTypes.func.isRequired,
  onSelectCopyPaste: PropTypes.func,
};

export default ImportWizardLightbox;
