import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const TableHeaderTitle = styled(Typography)`
  &&&& {
    max-width: calc(100% - 40px);
    font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
    text-align: left;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    color: ${({ theme }) => theme.palette.text.secondary};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    padding-left: 20px;
    text-transform: uppercase;
  }
`;

TableHeaderTitle.displayName = 'TableHeaderTitle';

export default TableHeaderTitle;
