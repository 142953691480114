import {
  UPDATE_OBJECTIVE_FROM_DRAWER_FULFILLED,
  UPDATE_OBJECTIVE_FULFILLED,
  CREATE_OBJECTIVE_FROM_DRAWER_FULFILLED,
  CREATE_OBJECTIVE_FULFILLED,
  REMOVE_OBJECTIVE_BY_ID,
  BULK_DELETE_OBJECTIVES_FULFILLED,
  ADD_OBJECTIVE_METRIC_FULFILLED,
  REMOVE_OBJECTIVE_METRIC_FULFILLED,
} from 'store/objectives';

import { CREATE_OBJECTIVE_FROM_CANVAS_FULFILLED } from 'features/Canvas/store/types';

import { initialState } from '../reducer';
import { objectivesAdapter } from '../helpers/adapters';
import { APPLY_FILTERS_WHITEBOARD_FULFILLED, UPDATE_METADATA_ENTITIES_WHITEBOARD } from '../types';
import { pickOkrFields } from 'features/Canvas/helpers/fieldPickers';
import { filterProjectsObjectives } from 'features/whiteboard/store/helpers/entitiesFilters';
import { projectsSelector } from 'features/whiteboard/store/selectors';

const processObjectives = (objectives, projects) => filterProjectsObjectives(objectives, projects).map(pickOkrFields);

const objectivesReducer = (state = initialState, action) => {
  const { payload } = action;
  const storeProjects = projectsSelector.selectAll(state);

  switch (action.type) {
    case APPLY_FILTERS_WHITEBOARD_FULFILLED: {
      const { objectives, projects } = payload;

      if (!objectives?.length) return state;

      return {
        ...state,
        objectives: objectivesAdapter.setAll(state?.objectives, processObjectives(objectives, projects)),
      };
    }
    case UPDATE_METADATA_ENTITIES_WHITEBOARD: {
      const { objectives } = payload;

      return {
        ...state,
        objectives: objectivesAdapter.setAll(state?.objectives, processObjectives(objectives, storeProjects)),
      };
    }

    case CREATE_OBJECTIVE_FULFILLED:
    case CREATE_OBJECTIVE_FROM_DRAWER_FULFILLED:
    case UPDATE_OBJECTIVE_FROM_DRAWER_FULFILLED:
    case ADD_OBJECTIVE_METRIC_FULFILLED:
    case REMOVE_OBJECTIVE_METRIC_FULFILLED:
      if (!payload?.id) return state;

      const shouldAddToStore = storeProjects.some(project => project?.objectiveId === payload?.id);

      if (!shouldAddToStore) {
        return state;
      }
      return {
        ...state,
        objectives: objectivesAdapter.upsertOne(state?.objectives, pickOkrFields(payload)),
      };
    case CREATE_OBJECTIVE_FROM_CANVAS_FULFILLED:
      const { meta: { objectiveCreatedFrom } = {} } = action;

      if (!payload?.id) return state;

      return {
        ...state,
        objectives: objectivesAdapter.upsertOne(state?.objectives, pickOkrFields(payload)),
        userActionsMeta: objectiveCreatedFrom
          ? {
              entityCreatedFrom: objectiveCreatedFrom,
              entityId: payload?.id,
              type: 'objective',
            }
          : {},
      };
    case UPDATE_OBJECTIVE_FULFILLED:
      const updatedObjective = payload.id ? payload : payload.data;

      return {
        ...state,
        objectives: objectivesAdapter.upsertOne(state?.objectives, pickOkrFields(updatedObjective)),
      };
    case REMOVE_OBJECTIVE_BY_ID:
      return {
        ...state,
        objectives: objectivesAdapter.removeOne(state?.objectives, payload),
      };
    case BULK_DELETE_OBJECTIVES_FULFILLED:
      return {
        ...state,
        objectives: objectivesAdapter.removeMany(state?.objectives, payload.map(Number)),
      };
    default: {
      return state;
    }
  }
};

export { objectivesReducer };
