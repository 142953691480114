// External dependencies
import moment from 'moment-timezone';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

// Dragonboat dependencies
import { materialColors } from 'design-system/themes/default';
import { getUserName } from 'utils';

export const dafaultFormData = {
  id: null,
  project_id: null,
  parent_task_id: null,
  startDate: moment(),
  endDate: moment().add(6, 'days'),
  type: 'task',
  createAnother: false,
  title: 'New assignment',
  customColor: materialColors.gray,
  details: '',
  owner: '',
  ownerAllocation: 100,
  priority: '',
  status: 'Open',
  statusColor: '',
  progress: 0,
  duration: 14,
  timeline: 'duration',
  taskTitles: '',
};

export const parseTaskToForm = task => {
  return {
    ...dafaultFormData,
    ...pick(task, Object.keys(dafaultFormData)),
    startDate: task.start_date,
    endDate: task.end_date,
    statusColor: task.status_color,
    owner: getUserName(task.owner),
  };
};

export const parseForm = formData => {
  return {
    ...omit(formData, ['owner', 'startDate', 'endDate', 'statusColor', 'createAnother', 'taskTitles']),
    start_date: formData.startDate,
    end_date: formData.endDate,
    status_color: formData.statusColor,
    owner_id: formData.owner_id,
    newTasks: formData.taskTitles
      .split('\n')
      .filter(t => !!t)
      .map(title => ({ title })),
  };
};

export const startDateChange = (formData, setFormData) => value => {
  const startDate = moment(value);
  const endDate = formData.endDate && moment(formData.endDate).isValid() ? moment(formData.endDate) : null;

  if (!startDate.isValid() || (endDate && formData.timeline === 'endDate' && endDate.isBefore(startDate))) {
    return;
  }

  if (formData.timeline === 'duration' && formData.duration) {
    setFormData({
      ...formData,
      startDate,
      endDate: moment(startDate, 'YYYY/MM/DD').addDuration(formData.duration, 'days'),
    });
  } else if (formData.timeline === 'endDate' && endDate) {
    setFormData({
      ...formData,
      startDate,
      duration: endDate.diffDuration(startDate, 'days'),
    });
  } else {
    setFormData({ ...formData, startDate });
  }
};

export const endDateChange = (formData, setFormData) => value => {
  const endDate = moment(value);
  const startDate = formData.startDate && moment(formData.startDate).isValid() ? moment(formData.startDate) : null;

  if (!endDate.isValid() || (startDate && formData.timeline === 'endDate' && startDate.isAfter(endDate))) {
    return;
  }

  if (formData.timeline === 'endDate' && startDate) {
    setFormData({
      ...formData,
      endDate,
      duration: endDate.diffDuration(startDate, 'days'),
    });
  } else {
    setFormData({ ...formData, endDate });
  }
};
