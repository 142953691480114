// import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import 'utils/moment-dragonboat';
import momentTimezone from 'moment-timezone';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import 'react-toastify/dist/ReactToastify.css';
import 'typeface-roboto';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';

import './styles/animate.min.css';
import './styles/main.css';
import './styles/fileUpload.css';
import App from './App';
import theme from './design-system/theme';
import configureStore from './store';
import addRollbar from './utils/addRollbar';
import { ProjectsDropdownProvider } from './hooks/useProjectsDropdownContext';
import { DeepLinkProvider } from './deep-link/useDeepLinkContext';
import { GridApiContextProvider } from './hooks/grid/useGridApiContext';
import { UserRoutesContextProvider } from './hooks/useApplicationRoutes/useUserRoutesContext';
import 'styles/styles.css';
import history from 'store/utils/history';

const errorsToWarn = ['Warning:'];
const oldConsError = console.error;

// log react Warnings as warning
console.error = (...args) => {
  let toWarn = false;

  if (typeof args[0] === 'string') {
    errorsToWarn.forEach(_s => {
      if (args[0].startsWith(_s)) {
        toWarn = true;
      }
    });
  }

  if (toWarn) console.warn(...args);
  else oldConsError(...args);
};

if (process.env.REACT_APP_ROLLBAR_FRONTEND_TOKEN && process.env.REACT_APP_ROLLBAR_ENVIRONMENT) {
  addRollbar();
}

momentTimezone.tz.setDefault('UTC');

// Version info for debugging
const REACT_APP_VERSION = process.env.REACT_APP_VERSION && process.env.REACT_APP_VERSION.replace(/"/g, '');
const { REACT_APP_BUILD_DATE } = process.env;

console.log(`%cCurrent Version: ${REACT_APP_VERSION} built in ${REACT_APP_BUILD_DATE}`, 'color:green');

// Configure redux store
export const { store, persistor } = configureStore();

const MOUNT_NODE = document.getElementById('root');

const render = () => {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <DndProvider backend={HTML5Backend} key={Math.random()}>
              <UserRoutesContextProvider>
                <GridApiContextProvider>
                  <ProjectsDropdownProvider>
                    <DeepLinkProvider>
                      <App />
                    </DeepLinkProvider>
                  </ProjectsDropdownProvider>
                </GridApiContextProvider>
              </UserRoutesContextProvider>
            </DndProvider>
          </PersistGate>
        </Provider>
      </StyledThemeProvider>
    </MuiThemeProvider>,
    MOUNT_NODE,
  );
};

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    environment: process.env.REACT_APP_DEPLOY_ENVIRONMENT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      // Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
      }),
    ],
    // Profiling
    // profilesSampleRate: 0.5, // Profile 50% of transactions
    // Tracing
    tracesSampleRate: 0.5, //  Capture 100% of the transactions
    // Session Replay
    // This sets the sample rate at 0%.
    // This needs to be updated in order for sessions to be recorded
    replaysSessionSampleRate: 0,
    // If you're not already sampling the entire session,
    // change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 0.5,
  });
}

// if (module.hot) {
//   // Hot reloadable React components and translation json files
//   // modules.hot.accept does not accept dynamic dependencies,
//   // have to be constants at compile-time
//   module.hot.accept(['App'], () => {
//     ReactDOM.unmountComponentAtNode(MOUNT_NODE);
//     render();
//   });
// }

render();

if (window.Cypress) {
  window.store = store;
}
