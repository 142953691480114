import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import isFunction from 'lodash/isFunction';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/EditOutlined';

import useSystemFields from 'hooks/useSystemFields';
import { DASHBOARDS_PAGE } from 'constants/filters/pages';
import { DASHBOARD_CHART_MIN_HEIGHT, DASHBOARD_CHART_MAX_HEIGHT } from 'constants/charts';
import { getWidgetId } from './helpers/widgets';

export default ({
  widget,
  inEditMode,
  handleDelete,
  updateWidgetHeight,
  height,
  dashboardState,
  updateDateRange,
  handleEdit,
  checkAllowEdit,
  disableClick = false,
}) => {
  const widgetRef = useRef(null);
  const [getSystemFieldName] = useSystemFields();

  const { component: Chart, props = {}, title, subtitle } = widget;
  const widgetTitle = isFunction(title) ? title(getSystemFieldName) : title;
  const widgetSubtitle = isFunction(subtitle) ? subtitle(getSystemFieldName) : subtitle;

  useEffect(() => {
    let resizeObserver = null;

    if (widgetRef.current && updateWidgetHeight) {
      resizeObserver = new ResizeObserver(entries => {
        if (entries.length > 0) {
          // since we are observing only a single element, we access the first element in entries array
          const rect = entries[0].contentRect;
          const { height } = rect;

          updateWidgetHeight(getWidgetId(widget), height);
        }
      });
      resizeObserver.observe(widgetRef.current);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [widgetRef.current]);

  const actionButtons = inEditMode && (
    <ButtonsWrapper>
      {checkAllowEdit(widget) && (
        <StyledIconButton onClick={() => handleEdit(getWidgetId(widget))}>
          <EditIcon />
        </StyledIconButton>
      )}
      <StyledIconButton onClick={() => handleDelete(getWidgetId(widget))}>
        <CloseIcon />
      </StyledIconButton>
    </ButtonsWrapper>
  );

  return (
    <WidgetWrapper key={getWidgetId(widget)} inEditMode={inEditMode} ref={widgetRef}>
      <Chart
        pageId={DASHBOARDS_PAGE}
        title={widgetTitle}
        subtitle={widgetSubtitle}
        wrapper={ChartWrapper}
        disableClick={disableClick}
        minHeight={height || DASHBOARD_CHART_MIN_HEIGHT}
        maxHeight={height || DASHBOARD_CHART_MAX_HEIGHT}
        dashboardState={dashboardState}
        updateDateRange={updateDateRange}
        actionButtons={actionButtons}
        {...props}
      />
    </WidgetWrapper>
  );
};

const WidgetWrapper = styled.div`
  position: relative;
  text-align: left;
  background: ${({ theme }) => theme.palette.white};

  border: ${({ theme }) => `1px solid ${theme.palette.border.primary}`};
  border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;

  ${({ inEditMode }) =>
    inEditMode &&
    `
      border-style: dashed;
      cursor: grab;
    `}
`;

const StyledIconButton = styled(IconButton)`
  && {
    svg {
      font-size: ${({ theme }) => theme.typography.fontSizeMedium}px;
    }
  }
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  top: 7px;
  right: 0;
`;

const ChartWrapper = styled.div``;
