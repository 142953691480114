import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { getGridConfigValue } from 'store/grids/selectors';
import { getFilteredProjectsWithForecastHeadcountFilter } from 'store/forecastHeadcount/selectors';
import useProjectsLocalSearch from 'hooks/projects/useProjectsLocalSearch';
import useProjectsFrontendFilters from 'hooks/filters/useProjectsFontendFilters';
import useProjectGroups from 'hooks/useProjectGroups';
import { makeItSelectable } from 'utils/grid';
import setIdeasHierarchyPath from 'utils/setIdeasHierarchyPath';

import useProjectsLocalFilterByCommited from './useProjectsLocalFilterByCommited';
import { getGroupingObjectsForLoadedOnDemandMetadata } from 'store/projects/groupSelectors';

const useEstimatesListData = ({
  viewType,
  displayLayer,
  isSingleLayerGroupedBy,
  getSystemFieldName,
  selectedGroup1,
  selectedGroup2,
  pageId,
}) => {
  const searchText = useSelector(state => getGridConfigValue(state, viewType, 'searchText'));
  const bulkDelete = useSelector(state => getGridConfigValue(state, viewType, 'bulkDelete'));

  const filteredProjectsStore = useSelector(state =>
    getFilteredProjectsWithForecastHeadcountFilter(state, pageId, true, displayLayer),
  );

  const [filterBySearchString] = useProjectsLocalSearch(filteredProjectsStore, searchText);
  const [filterByCommited] = useProjectsLocalFilterByCommited(filteredProjectsStore, viewType);

  const customAllProjectsByLayerForGroups = useMemo(
    () => ({ [displayLayer]: filteredProjectsStore }),
    [displayLayer, filteredProjectsStore],
  );

  const { applyFrontendFiltersOnProject, applyFrontendFilters } = useProjectsFrontendFilters(
    filterByCommited,
    filterBySearchString,
  );

  const projectsFilters = useMemo(() => [applyFrontendFiltersOnProject], [applyFrontendFiltersOnProject]);

  const projectGroups = useProjectGroups({
    selectedGroup1,
    selectedGroup2,
    page: pageId,
    projectsFilters,
    customAllProjectsByLayer: customAllProjectsByLayerForGroups,
    id: pageId,
    defaultGroupingObjectsSelector: getGroupingObjectsForLoadedOnDemandMetadata,
  });

  const data = useMemo(() => {
    let data;

    if (isEmpty(filteredProjectsStore)) {
      return [];
    }

    data = applyFrontendFilters(filteredProjectsStore);

    if (isSingleLayerGroupedBy) {
      data = setIdeasHierarchyPath(
        {
          [displayLayer]: data,
        },
        displayLayer,
        getSystemFieldName('initiative', false),
        getSystemFieldName('bet', false),
        isSingleLayerGroupedBy,
        projectGroups,
      );
    }

    if (bulkDelete) data = makeItSelectable(data);

    return data;
  }, [filteredProjectsStore, displayLayer, isSingleLayerGroupedBy, projectGroups, bulkDelete, applyFrontendFilters]);

  return data;
};

export default useEstimatesListData;
