import React, { useCallback, useMemo } from 'react';

const container = Component => {
  return props => {
    const { availableJiraProjects, onChange } = props;

    const suggestions = useMemo(
      () => availableJiraProjects.map(jiraProject => ({ label: jiraProject.name, value: jiraProject.key })),
      [availableJiraProjects],
    );

    const onCancel = useCallback(
      ({ target }) => {
        if (!target.value) {
          setTimeout(() => onChange(null), 300);
        }
      },
      [onChange],
    );

    return <Component suggestions={suggestions} onChange={onChange} onCancel={onCancel} />;
  };
};

export default container;
