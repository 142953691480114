import React from 'react';
import transform from 'lodash/transform';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isFinite from 'lodash/isFinite';
import moment from 'moment-timezone';
import { when, pipe, concat, trim, reject, split, isEmpty, head, isNil } from 'ramda';
import isString from 'lodash/isString';

import {
  ADMIN_USER,
  EDITOR_USER,
  LEADER_USER,
  MANAGER_USER,
  OWNER_USER,
  READ_ONLY_USER,
  REQUESTOR_USER,
  COLLABORATOR_USER,
  PLANNER_USER,
} from '@dragonboat/permissions';
import { ISSUE_COUNT_PROGRESS } from 'constants/integrations';
import { textColor, materialColorsAlt } from 'design-system/themes/default';
import ProjectLink from 'design-system/atoms/ProjectLink';

import sortOrder from './sortOrder';
import roundDecimalPlaces from './roundDecimalPlaces';
import linkifyHtml from 'linkify-html';

const HTTP_PROTOCOL = 'https://';

const splitByProtocol = split(HTTP_PROTOCOL);
const splitByPath = split('/');
const concatHttp = concat(HTTP_PROTOCOL);
const compact = reject(isEmpty);

export const removePathFromUrl = pipe(splitByProtocol, compact, head, splitByPath, head, concatHttp);

/**
 * Function to log messages on console
 *
 * @param {*} message
 * @param {*} color
 */
export const log = (message, color = '#CCC') => {
  if (process.env.REACT_APP_ROLLBAR_ENVIRONMENT === undefined) {
    console.log(`%c${message}`, `color:${color}`);
  }
};

/**
 *
 * @param {*} functions
 */
export const loadAll = functions => {
  return Promise.all(functions);
};

/**
 *
 * @param {*} searchString
 */
export const idSearchIdeaString = searchString => {
  return searchString.toLowerCase().replace(/db-/g, '');
};

/**
 *
 * @param {*} input
 */
export const isURL = input => {
  const pattern =
    '^(https?:\\/\\/)?' + // protocol
    '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+' + // sub-domain + domain name
    '[a-zA-Z]{2,13})' + // extension
    '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
    '|localhost)' + // OR localhost
    '(\\:\\d{1,5})?' + // port
    '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*' + // path
    '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?' + // query string
    '(\\#[-a-zA-Z&\\d_]*)?$'; // fragment locator
  const regex = new RegExp(pattern);

  return regex.test(input);
};

/**
 * @function trimAndCleanUrl
 * @return {Function}         The function we composed to be used elsewhere
 */
const cleanIfValidUrl = when(isURL, removePathFromUrl);

export const trimAndCleanUrl = pipe(trim, cleanIfValidUrl);

/**
 *
 * @param {*} inProgress
 * @param {*} closed
 * @param {*} total
 */
export const calcProgress = (log, trackProgressBy) => {
  if (!trackProgressBy) trackProgressBy = ISSUE_COUNT_PROGRESS;

  const mapCalcProgress = {
    // eslint-disable-next-line no-mixed-operators
    issueCount: l => (l.issuesClosed + l.issuesInProgress * 0.5) / l.issuesTotal,
    storyPoints: l => l.pointsClosed / l.pointsTotal,
    timeEstimates: l => l.timeInProgress / l.timeTotal,
  };

  return mapCalcProgress[trackProgressBy](log);
};

const dashboardHomeRoute = '/dashboard/home';
const requestsRoute = '/requests';
const defaultRoute = '/home';

const defaultUserRoutes = {
  [ADMIN_USER]: defaultRoute,
  [MANAGER_USER]: defaultRoute,
  [EDITOR_USER]: defaultRoute,
  [LEADER_USER]: defaultRoute,
  [OWNER_USER]: defaultRoute,
  [READ_ONLY_USER]: defaultRoute,
  [COLLABORATOR_USER]: dashboardHomeRoute,
  [PLANNER_USER]: dashboardHomeRoute,
  [REQUESTOR_USER]: requestsRoute,
};

export const getWelcomeRoute = user => {
  return user && user.role_id ? defaultUserRoutes[user.role_id] || defaultRoute : defaultRoute;
};

export const getDefaultRoute = user => {
  if (user && user.login_count > 1) {
    return [READ_ONLY_USER, REQUESTOR_USER, COLLABORATOR_USER, PLANNER_USER].includes(user.role_id)
      ? defaultUserRoutes[user.role_id]
      : defaultRoute;
  }
  return getWelcomeRoute(user);
};

export const planningStageColors = {
  Backlog: textColor,
  Planning: materialColorsAlt.blue,
  Confirmed: materialColorsAlt.green,
  Completed: textColor,
  Archived: materialColorsAlt.darkGray,
};

export const planningStageChartColors = {
  Backlog: materialColorsAlt.gray,
  Planning: materialColorsAlt.lightBlue,
  Confirmed: materialColorsAlt.green,
  Completed: materialColorsAlt.lightGreen,
  Archived: materialColorsAlt.darkGray,
};

export const planningStages = Object.keys(planningStageColors);

export const planningStageOptions = planningStages.map(stage => ({ id: stage, title: stage }));

export const orderedPlanningStages = planningStages.map((stage, index) => ({ id: stage, title: stage, row_order: index + 1 }));

export const reversedPlanningStages = [...planningStages].reverse().map((stage, index) => ({
  id: stage,
  title: stage,
  row_order: index + 1,
}));

export function parseQuery(queryString) {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');

    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export function removeQueryParam(url, parameter) {
  const urlparts = url.split('?');

  if (urlparts.length >= 2) {
    const prefix = `${encodeURIComponent(parameter)}=`;
    const pars = urlparts[1].split(/[&;]/g);

    for (let i = pars.length; i-- > 0; ) {
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return `${urlparts[0]}${pars.length > 0 ? `?${pars.join('&')}` : ''}`;
  }
  return url;
}

export function deepDifference(object, base) {
  function changes(object, base) {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

export function toLower(str) {
  if (!str || typeof str !== 'string') {
    return str;
  }
  return str.toLowerCase();
}

export function ucFirst(string) {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
}

export function getLargestId(entities) {
  return entities.length ? Math.max(...entities.map(ent => +ent.id || 0)) : 0;
}

export function computeEndDate(startDate, duration = 0) {
  return moment(startDate).addDuration(duration, 'days');
  // return new Date(new Date(startDate).getTime() + (duration * 24 * 60 * 60 * 1000));
}

export function filterByDate(project, startDate, endDate, includeEmptyProjects = true) {
  const passesDate = entity => {
    let parsedStartDate = entity.estimated_start_date || entity.start_date;

    if (typeof parsedStartDate === 'string') {
      const format = parsedStartDate.match(/....-..-/) ? 'YYYY-MM-DD' : 'DD-MM-YYYY';

      parsedStartDate = moment(parsedStartDate, format);
    }

    const entityEndDate = parsedStartDate.clone().addDuration(+entity.duration, 'days');
    const entityStartDate = parsedStartDate;

    return entityStartDate.isBefore(moment(endDate)) && entityEndDate.isAfter(moment(startDate));
  };
  const bottomTasks = getBottomTasks(project);

  if (project.planningStage === 'Planning') {
    if (project.estimates && project.estimates.length) {
      return project.estimates.some(passesDate);
    } else if (bottomTasks.length) {
      return bottomTasks.some(passesDate);
    }
    return includeEmptyProjects ? passesDate(project) : false;
  } else if (['Confirmed', 'Completed'].includes(project.planningStage)) {
    if (bottomTasks.length) {
      return bottomTasks.some(passesDate);
    } else if (project.estimates && project.estimates.length) {
      return project.estimates.some(passesDate);
    }
    return includeEmptyProjects ? passesDate(project) : false;
  }
}

export function computeDate(date) {
  return new Date(new Date(date).getTime());
}

export function getProgressNumber(progress) {
  return parseInt((progress || 0) * 100);
}

const checkIsNumber = num => typeof num === 'number' && !window.isNaN(num);

export function formatPercentOnly(num, decimals = 0) {
  const isNumber = checkIsNumber(num);

  return isNumber ? `${num.toFixed(decimals)}%` : '0%';
}

export const formatPercent = (num, decimals = 0) => {
  const isNumber = checkIsNumber(num) && checkIsNumber(decimals);

  return isNumber ? `${(num * 100).toFixed(decimals)}%` : null;
};

export const formatNumber = (num, decimals = 0) => {
  const isNumber = checkIsNumber(num);

  return isNumber ? parseFloat(num.toFixed(decimals)).toLocaleString('en-US') : null;
};

export const roundToDecimals = (num, decimals) => {
  const isNumber = checkIsNumber(num) && checkIsNumber(decimals);

  if (!isNumber) {
    return null;
  }

  const factor = Math.pow(10, decimals);

  return Math.round(num * factor) / factor;
};

export function calculateGroupProgress(groupProjs, entity) {
  if ((entity || {}).progress_calculated || (entity || {}).progress_calculated === 0) return entity.progress_calculated;

  let totalDuration = 0;
  let totalProgressDays = 0;

  groupProjs.forEach(p => {
    const calculatedDuration = isFinite(p.duration) ? p.duration : 14;

    totalDuration += calculatedDuration;
    totalProgressDays += (p.progress || 0) * calculatedDuration;
  });

  return totalDuration === 0 ? 0 : totalProgressDays / totalDuration;
}

export function convertDate(ts, addDays = 0) {
  const date = computeEndDate(ts, addDays);

  return moment(date).format('DD-MM-YYYY');
}

export function convertedDate(ts, addDays = 0) {
  // eslint-disable-next-line no-mixed-operators
  const date = new Date(new Date(ts).getTime() + addDays * 24 * 60 * 60 * 1000);
  const month = date.getMonth() + 1;
  const paddedMonth = month < 10 ? `0${month}` : month;

  return `${date.getFullYear()}-${paddedMonth}-${date.getDate()}`;
}

export function convertedDateTime(ts, addDays = 0) {
  // eslint-disable-next-line no-mixed-operators
  const date = new Date(new Date(ts).getTime() + addDays * 24 * 60 * 60 * 1000);

  return moment(date).format('YYYY-MM-DD HH:mm');
}

export function calculateRiceScore(reachScore, impactScore, confidenceScore, effortScore) {
  return effortScore > 0 ? roundDecimalPlaces((reachScore * impactScore * (confidenceScore / 100)) / effortScore) : 0;
}

export const caculateTotalCost = (estimates, teams) => {
  return (
    estimates &&
    estimates.reduce((acc, est) => {
      const team = teams.find(t => (est.team ? est.team.title === t.title : est.team_id === t.id));

      acc += team ? Math.round((est.numStaff || 0) * (team.cost || 0) * (est.duration / 7)) : 0;
      return acc;
    }, 0)
  );
};

export function setHttp(link) {
  if (link.search(/^http[s]?:\/\//) === -1) {
    link = `http://${link}`;
  }
  return link;
}

export function removeDuplicates(arr) {
  return Array.from(new Set(arr));
}

export function removeDuplicatesByKey(myArr, prop) {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

export function sortAlphaByKey(arr, key) {
  const newArr = arr.sort((a, b) => {
    const nameA = a[key].toLowerCase();
    const nameB = b[key].toLowerCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return newArr;
}

export function getUserName(user) {
  if (!user) return '';

  if (user.first_name && user.last_name) {
    return `${user.first_name}${user.last_name ? ` ${user.last_name}` : ''}`;
  }

  const userIdLabel = user.id ? `user ${user.id}` : '';

  return user.first_name || userIdLabel || '';
}

export const getUpdatedByDisplayName = user => {
  if (isNil(user)) return 'System';

  return getUserName(user);
};

export const getUserShortName = user => {
  if (!user) return '';

  if (user.first_name && user.last_name) {
    return `${user.first_name.charAt(0).toUpperCase()}${user.last_name ? `${user.last_name.charAt(0).toUpperCase()}` : ''}`;
  }
  return (user.first_name || '').charAt(0).toUpperCase();
};

export const statusColors = [
  { label: '', value: '' },
  { label: 'Red', value: 'Red', rank: 'd' },
  { label: 'Yellow', value: 'Yellow', rank: 'c' },
  { label: 'Green', value: 'Green', rank: 'b' },
  { label: 'Blue', value: 'Blue', rank: 'a' },
];

export const taskStatuses = [
  { label: 'Open', value: 'Open' },
  { label: 'In Progress', value: 'In Progress' },
  { label: 'Completed', value: 'Completed' },
];

export function getAllTasks(entities) {
  if (!entities) return [];

  entities = Array.isArray(entities) ? entities : [entities];

  return entities.reduce((tasks, entity) => {
    if (!entity) return null;
    const subtasks = entity.tasks || entity.subtasks;

    if (subtasks && subtasks.length) {
      const clonedTasks = tasks.concat([...subtasks].sort(sortOrder));

      return [...clonedTasks].concat(getAllTasks(subtasks));
    }
    return tasks;
  }, []);
}

export function getBottomTasks(project) {
  return getAllTasks(project).filter(t => t.type !== 'milestone' && t.subtasks && !t.subtasks.length);
}

export function flattenTasks(parent) {
  let tasks = parent.tasks || parent.subtasks;

  if (!tasks) {
    return [];
  }

  for (let i = 0; i < tasks.length; i++) {
    tasks = tasks.concat(flattenTasks(tasks[i]));
  }
  return tasks;
}

export function getAllEsts(projects = []) {
  if (!projects) return [];

  return projects
    .reduce((acc, cur) => {
      acc = acc.concat(cur.estimates);
      return acc;
    }, [])
    .filter(Boolean);
}

// entity can be task, subtask, estimate or project
export function getParentProject(entity, projects = []) {
  if (projects.includes(entity)) {
    return entity;
  }
  for (let i = 0; i < projects.length; i++) {
    const project = projects[i];
    const tasks = project.tasks ? project.tasks.concat(getAllTasks(project.tasks)) : [];

    if ((tasks && tasks.includes(entity)) || (project && project.estimates && project.estimates.includes(entity))) {
      return project;
    }
  }
}

export function getParentTaskOnGantt(task, allTasks) {
  if (task.parent_task_id) {
    return { id: `task-${task.parent_task_id}` };
  }

  for (let i = 0; i < allTasks.length; i++) {
    const t = allTasks[i];

    if (t.subtasks && t.subtasks.includes(task)) {
      return { ...t, id: `task-${t.id}` };
    }
  }
}

export function getProjectEndDate(project) {
  const findOldestEndDate = entities => {
    let oldestEndDate = computeEndDate(entities[0].start_date, entities[0].duration);

    entities.forEach(entity => {
      const entityEndDate = computeEndDate(entity.start_date, entity.duration);

      if (moment(entityEndDate).isAfter(oldestEndDate)) {
        oldestEndDate = entityEndDate;
      }
    });
    return oldestEndDate;
  };
  const bottomTasks = getBottomTasks(project);

  if (!['Confirmed', 'Completed'].includes(project.planningStage)) {
    if (project.estimates && project.estimates.length) {
      return findOldestEndDate(project.estimates);
    } else if (bottomTasks && bottomTasks.length) {
      return findOldestEndDate(bottomTasks);
    }
    return computeEndDate(project.start_date, project.duration);
  }

  if (bottomTasks && bottomTasks.length) {
    return findOldestEndDate(bottomTasks);
  } else if (project.estimates && project.estimates.length) {
    return findOldestEndDate(project.estimates);
  }
  return computeEndDate(project.start_date, project.duration);
}

export function getRandom4DigitNum() {
  return Math.floor(Math.random() * 9000) + 1000;
}

export function getRandom5DigitNum() {
  return Math.floor(Math.random() * 90000) + 10000;
}

export function validatePassword(string) {
  if (string.length < 8 || string.length > 24) {
    return false;
  }

  return (/[a-z]/.test(string) || /[A-Z]/.test(string)) && /[0-9]/.test(string);
}

export const filterActiveItens = i => i && i.status !== 'Archived';
export const filterActiveItems = filterActiveItens;
export const filterActiveUsers = i => i && i.status !== 'Inactive';

export const getMetadataFilteredByActiveItems = (metadata = [], hideArchivedItems = false) => {
  if (hideArchivedItems) {
    return metadata.filter(filterActiveItens);
  }

  return metadata;
};

/**
 * @function makeFilterByLevel
 *
 * Gets a callback function to filter items by level.
 * Works for any entity that have level property in the root.
 *
 * @param {Number} level
 * @returns {Function} filter by level callback
 */
export const makeFilterByLevel = level => item => item?.level === level;

export const isCapacityUser = user => !!user.capacity || user.capacity === null;

export const rgbToHex = color => {
  const a = (color || 'rba(255,255,255)').split('(')[1].split(')')[0];
  const code = a
    .split(',')
    .map(x => {
      const hex = parseInt(x).toString(16);

      return hex.length === 1 ? `0${hex}` : hex;
    })
    .join('')
    .replace(/\s/g, '');

  return `#${code}`;
};

export const getRandomColor = () => {
  return `#${((Math.random() * 0xffffff) << 0).toString(16)}`;
};

export const cleanUrl = value => {
  let url = value;

  if (isURL(url)) {
    let protocol = '';
    const splitedProtocol = url.split('//');

    if (splitedProtocol.length > 1) {
      protocol = `${splitedProtocol[0]}//`;
      [, url] = splitedProtocol;
    } else {
      [url] = splitedProtocol;
    }
    const splitedOrigin = url.split('/');

    url = `${protocol}${splitedOrigin[0]}`;
  }

  return url;
};

/**
 * Extract the organization slug from some url (http:\\slug.dragonboat.io/other/path)
 *
 * @function getOrgSlugFromUrl
 * @param  {String} link         url of the idea
 * @return {String}             org slug
 */
export const getOrgSlugFromUrl = link => {
  const invalidUrl = !isString(link);

  if (invalidUrl) return;

  const url = new URL(link);
  const host = url.hostname;

  if (!host) return;

  const [slug] = host.split('.');

  return slug;
};

export const getDuplicatedToastErrorMessage = (fieldName, textValue, roadmap, subRoadmap, url) => {
  return (
    <span>
      There is already another item named {textValue}. This item may be hidden from the view, archived or used by another{' '}
      {roadmap}/{subRoadmap}. Please choose another title or go to
      <ProjectLink href={url}> settings </ProjectLink> to view details.
    </span>
  );
};

/**
 * @function linkifyHtml
 *
 * Finds and replaces links in HTML text and converts them to <a> tags
 * @param {String} htmlString - The string with html content to parse
 * @param {Boolean} forceLinksTargetBlank - New and existing links will have target blank when no target is set
 * @returns {String} - The input htmlString with transformed links to <a> tags
 */
export const linkifyHtmlString = (htmlString, forceLinksTargetBlank = false) => {
  const linkifyOptions = forceLinksTargetBlank ? { target: '_blank' } : {};

  const linkifiedHtml = linkifyHtml(htmlString, linkifyOptions);

  if (!forceLinksTargetBlank) {
    return linkifiedHtml;
  }

  const tmpWrapper = document.createElement('div');

  tmpWrapper.innerHTML = linkifiedHtml;

  tmpWrapper.querySelectorAll('a').forEach(element => {
    if (!element.getAttribute('target')) {
      element.setAttribute('target', '_blank');
    }
  });

  return tmpWrapper.innerHTML;
};

/**
 * @function getPathWithoutLeadingSlash
 *
 * Removes the leading slash from a string (path)
 * @param {String} path
 * @returns {String} - provided path without the leading slash
 */
export const getPathWithoutLeadingSlash = path => {
  return path.startsWith('/') ? path.slice(1) : path;
};

export const removeHtmlTags = str => {
  return str.replace(/<\/?[^>]+(>|$)/g, '');
};

/**
 * Add alpha channel to hex color
 * @param {String} hexColor
 * @param {Number} opacity
 * @returns {String} hex color with extra appended alpha channel hex
 */
export const addAlphaToHexColor = (hexColor, opacity = 1) => {
  const opacityToApply = opacity < 0 || opacity > 1 ? 1 : opacity;

  const alphaChannelHex = Number((255 * opacityToApply).toFixed(0)).toString(16);

  return `${hexColor}${alphaChannelHex}`;
};

export const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength - 3)}...`;
  }

  return text;
};
