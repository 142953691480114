import React, { Component, Fragment } from 'react';
import { EditorState, RichUtils } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import styled from 'styled-components';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import isEqual from 'lodash/isEqual';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from 'draft-js-buttons';
import HeadlinesButton from './HeadlinesButton';
import TextAreaEditorRender from '../TextAreaEditorRender';

import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'draft-js-linkify-plugin/lib/plugin.css';

const toolbarPlugin = createToolbarPlugin();
const linkifyPlugin = createLinkifyPlugin({
  component: props => (
    // eslint-disable-next-line no-alert, jsx-a11y/anchor-has-content, jsx-a11y/no-static-element-interactions
    (<a {...props} onClick={() => window.open(props.href, '_blank').focus()} />)
  ),
});

const fromHtml = convertFromHTML({
  htmlToStyle: (nodeName, node, currentStyle) => {
    if (nodeName === 'a') {
      return currentStyle.add('BLUE');
    }
    return currentStyle;
  },
  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName === 'a') {
      return createEntity('LINK', 'MUTABLE', { url: node.href });
    }
  },
});

export default class TextAreaEditor extends Component {
  state = {
    active: false,
  };

  constructor(props) {
    super(props);

    let editorState;

    if (props.value && typeof props.value === 'string' && props.value.trim() !== '') {
      editorState = EditorState.createWithContent(fromHtml(props.value));
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {
      editorState,
      html: props.value,
      active: props.defaultActive,
    };
  }

  componentWillReceiveProps(newProps) {
    if (!isEqual(newProps.value, this.state.html)) {
      const editorState =
        newProps.value && typeof newProps.value === 'string' && newProps.value.trim() !== ''
          ? EditorState.createWithContent(fromHtml(newProps.value))
          : EditorState.createEmpty();

      this.setState({ editorState, html: newProps.value });
    }
  }

  onChange = editorState => {
    const html = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return <a href={entity.data.url}>{originalText}</a>;
        }
        return originalText;
      },
    })(editorState.getCurrentContent());

    this.setState({ editorState, html }, () => {
      if (this.props.onChange && !isEqual(html, this.props.value)) {
        this.props.onChange(html);
      }
    });
  };

  focus = () => {
    setTimeout(() => {
      if (this.editor) {
        this.setState({ isFocused: true });
        this.editor.focus();
      }
    }, 100);
  };

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  render() {
    const {
      label,
      ref,
      className,
      disableToolbox,
      small,
      big,
      style,
      autofocus,
      onFocus,
      onBlur,
      borderLessToolbox,
      toolboxPosition,
      hideBottomBorder,
    } = this.props;
    const { Toolbar } = toolbarPlugin;
    const plugins = [linkifyPlugin];
    const { editorState, active, html } = this.state;

    if (!disableToolbox) {
      plugins.push(toolbarPlugin);
    }

    let height;

    if (big) {
      height = 350;
    } else if (small) {
      height = 30;
    } else {
      height = 120;
      // height = 250;
    }

    if (!active) {
      return (
        <div>
          <EditorContainer
            height={this.props.height || height}
            read
            focused={active}
            className={`${className} textAreaEditorContainer ${active && 'focused'}`}
            onClick={() => {
              if (!this.props.disabled) this.setState({ active: true }, () => this.focus());
            }}
            style={style}
            disabled={this.props.disabled}
            borderLessToolbox={borderLessToolbox}
            toolboxPosition={toolboxPosition}
            hideBottomBorder={hideBottomBorder}
          >
            {label && (
              <Label disabled={this.props.disabled} html>
                {label}
              </Label>
            )}
            <TextAreaEditorRender value={html} />
          </EditorContainer>
        </div>
      );
    }

    const toolbox = !disableToolbox && (
      <Toolbar>
        {externalProps => (
          <Fragment>
            <BoldButton {...externalProps} />
            <ItalicButton {...externalProps} />
            <UnderlineButton {...externalProps} />
            <CodeButton {...externalProps} />
            <HeadlinesButton {...externalProps} />
            <UnorderedListButton {...externalProps} />
            <OrderedListButton {...externalProps} />
            <BlockquoteButton {...externalProps} />
          </Fragment>
        )}
      </Toolbar>
    );

    return (
      <EditorContainer
        height={this.props.height || height}
        className={`${className} textAreaEditorContainer ${active && 'focused'}`}
        focused={active}
        style={style}
        disabled={this.props.disabled}
        borderLessToolbox={borderLessToolbox}
        toolboxPosition={toolboxPosition}
        hideBottomBorder={hideBottomBorder}
      >
        {label && <Label>{label}</Label>}
        {toolboxPosition !== 'bottom' && toolbox}
        <Editor
          editorState={editorState}
          onChange={this.onChange}
          readOnly={this.props.disabled}
          onFocus={() => onFocus && onFocus()}
          onBlur={() => {
            this.setState({ active: false });

            if (onBlur) onBlur();
          }}
          handleKeyCommand={this.handleKeyCommand}
          plugins={plugins}
          ref={element => {
            this.editor = element;
            if (ref) ref(element);

            if (element && autofocus) element.focus();
          }}
          spellCheck
        />
        {toolboxPosition === 'bottom' && toolbox}
      </EditorContainer>
    );
  }
}

const EditorContainer = styled.div`
  cursor: text;
  border-radius: 2px;
  margin-bottom: 2em;
  min-height: ${({ height }) => `${height + 9}px`};
  position: relative;
  font-weight: 400;
  padding: 6px 0 7px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 24px;
  background: transparent;
  line-height: 22px;
  box-sizing: border-box;
  overflow: hidden;

  ${({ disabled }) => disabled && `opacity: .5;`}

  /* force text to break on overflow */
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  &:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    pointer-events: none;
  }

  &:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    pointer-events: none;
  }

  &.focused:after {
    transform: scaleX(1);
  }

  &&&:global(.public-DraftEditor-content) {
    min-height: 100px;
  }

  > div:not(.DraftEditor-root) {
    background: #fbfbfb;
  }

  .DraftEditor-root {
    overflow-y: auto;
    max-height: 'auto';
    min-height: ${({ read, height }) => (read ? `${height}px` : `${height - 36}px`)};
  }

  ul {
    list-style: disc;
    padding-left: 30px;
    line-height: 22px;
  }
  ol {
    list-style: decimal;
    padding-left: 30px;
    line-height: 22px;
  }
  h1 {
    font-size: 40px;
    line-height: 40px;
    margin: 30px 0 20px;
  }
  h2 {
    font-size: 32px;
    line-height: 32px;
    margin: 20px 0 10px;
  }
  h3 {
    font-size: 26px;
    line-height: 26px;
    margin: 20px 0 10px;
  }
  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
  }
  blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  blockquote p {
    display: inline;
  }
  pre code {
    background-color: #eee;
    border: 1px solid #999;
    display: block;
    padding: 20px;
  }
  a {
    color: #009dff;
    text-decoration: underline;
    cursor: pointer;
  }
  span {
    line-height: 20px;
  }
  strong {
    font-weight: bold;
  }
  p {
    min-height: 22px;
  }

  ${props =>
    !props.disableToolbox &&
    props.borderLessToolbox &&
    `
    &&&& > div:${props.toolboxPosition !== 'bottom' ? 'first' : 'last'}-child {
      background: transparent;
      border: none;
      box-shadow: none;

      > div > button {
        border-radius: 2px;
        background: #fff;

        :hover {
          background: #eee;
        }
      }
    }
  `}
`;

const Label = styled.label`
  ${({ html }) => (!html ? 'top: -24px' : 'top: 0')};
  ${({ disabled }) => disabled && `opacity: .5;`}
  left: 0;
  position: absolute;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
`;
