import { not, pipe, isNil } from 'ramda';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { GLOBAL_FILTER, CHILDREN_FILTERS } from 'constants/filters';
import useOrganizations from 'hooks/useOrganizations';
import useApplicationRoutes from 'hooks/useApplicationRoutes';
import useHeaderSearchString from 'hooks/useHeaderSearchString';
import usePageFilters from 'hooks/filters/usePageFilters';

import getLayersFromFilters from 'utils/filters/getLayersFromFilters';

const ENTER_KEY = 13;
const isNotNil = pipe(isNil, not);

/**
 * @function useInputEventsHandlers
 *
 * use all events handlers to handle with global search input
 *
 * @param  {Object}
 * @return {Object}
 */
const useInputEventsHandlers = ({
  debouncedLoadData,
  setValue,
  value,
  treeIsOpen,
  setTreeIsOpen,
  isLoading,
  filters,
  dropDownRef,
}) => {
  const dispatch = useDispatch();
  const { hasBet } = useOrganizations();
  const [, , , routesWithPortfolioMode] = useApplicationRoutes();
  const [localSearch, setLocalSearch] = useHeaderSearchString(window.location.pathname);
  const { displayLayer, applyFilters, pageFilters: currentPageFilters, searchString } = usePageFilters(GLOBAL_FILTER);

  const closeTree = useCallback(() => {
    setTreeIsOpen(false);
  }, [setTreeIsOpen]);

  const applySearchOnPage = useCallback(
    search => {
      const currentRouteIsPortfolioModeRoute = routesWithPortfolioMode.find(r => window.location.pathname.includes(r.path));

      /*
       * if current route has portolio mode option will change
       * the display layer and apply the portolio mode option
       */
      const portfolioFilters = {
        ...currentPageFilters,
        ...(currentRouteIsPortfolioModeRoute
          ? getLayersFromFilters({ layer: 'TOP_LAYER', children: CHILDREN_FILTERS.allChildren, secondLayer: 'ALL_OTHERS' })
          : { children: CHILDREN_FILTERS.noChildren, secondLayer: [] }),
        fields: {
          planningStages: ['Backlog', 'Planning', 'Confirmed'],
          ...filters.fields,
          ...(isNotNil(search) ? { search } : {}),
        },
        fuzzySearchEnabled: true,
      };

      applyFilters(portfolioFilters);

      // clear local search
      if (localSearch) {
        dispatch(setLocalSearch(''));
      }
    },
    [
      window.location.pathname,
      filters,
      hasBet,
      isLoading,
      dispatch,
      applyFilters,
      routesWithPortfolioMode,
      localSearch,
      setLocalSearch,
      displayLayer,
      currentPageFilters,
    ],
  );

  const handleOnChange = useCallback(
    v => {
      setValue(v);

      debouncedLoadData(v);
    },
    [setValue, debouncedLoadData],
  );

  const handleOnFocus = useCallback(() => {
    if (not(treeIsOpen)) {
      setTreeIsOpen(true);

      debouncedLoadData(value);
    }
  }, [treeIsOpen, setTreeIsOpen, debouncedLoadData, value]);

  const handleOnKeyDown = useCallback(
    e => {
      e.stopPropagation();

      if (e.which === ENTER_KEY && not(isLoading)) {
        applySearchOnPage();

        e.target.blur();
      }
    },
    [isLoading, applySearchOnPage],
  );

  const handleOnBlur = useCallback(
    e => {
      if (dropDownRef.current && dropDownRef.current.contains(e.relatedTarget)) {
        return;
      }

      /*
       * On blur with empty input search will reset the global search
       * and should have current global search active
       */
      if (!value && searchString) {
        applySearchOnPage('');
      } else if (searchString) {
        // replace the current input value bue the current global search
        setValue(searchString);
      }

      closeTree();
    },
    [closeTree, dropDownRef, value, searchString, applySearchOnPage],
  );

  return {
    handleOnChange,
    handleOnFocus,
    handleOnKeyDown,
    handleOnBlur,
  };
};

export default useInputEventsHandlers;
