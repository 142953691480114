import { allPass, isEmpty, isNil, not, pipe, prop } from 'ramda';
import { materialColors } from 'design-system/themes/default';

const isNotNil = pipe(isNil, not);
const isNotEmpty = pipe(isEmpty, not);
const getStatusColor = prop('status_color');
const isValidStatusColor = pipe(getStatusColor, allPass([isNotNil, isNotEmpty]));

const getStatusColorInHex = p => (isValidStatusColor(p) ? materialColors[getStatusColor(p).toLowerCase()] : undefined);

export default getStatusColorInHex;
