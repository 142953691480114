import { UPDATE_PORTFOLIO_REPORT_STATE } from './types';
import { groupByOptions } from './consts';

const initialState = {
  group1: groupByOptions().find(o => o.key === 'objective'),
};

export default function portfolioReportReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PORTFOLIO_REPORT_STATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
