import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import queryString from 'query-string';

import { getCurrentUser } from 'store/login/selectors';
import { setAppGlobalConfig } from 'store/app/actions';
import { getOrganization } from 'store/organization/selectors';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const showTemplatesLightbox = useSelector(state => state.app.showTemplatesLightbox);
    const currentUser = useSelector(state => getCurrentUser(state));
    const organization = useSelector(getOrganization);
    const currentUserIsOwnerOfOrg = organization.owner_id === currentUser.id;
    const disabled = !currentUserIsOwnerOfOrg;

    const { template, showTemplates } = queryString.parse(props.location.search);

    const [templates, setTemplates] = useState([]);
    const [open, setOpen] = useState(showTemplates && !template && !disabled);
    const showLightbox = open || showTemplatesLightbox;
    const activeTemplate = organization.selected_template;

    const _loadTemplates = () =>
      axios.get(`/api/organizations/${organization.id}/templates`).then(res => {
        setTemplates(res.data);
      });

    useEffect(() => {
      if (template) {
        _loadTemplates();
      }
    }, []);

    useEffect(() => {
      if (templates.length && template !== null) {
        _applyTemplate(template);
      }
    }, [templates]);

    useEffect(() => {
      if (!showLightbox) return;
      if (!templates.length) _loadTemplates();
    }, [showLightbox]);

    const _applyTemplate = id => {
      if (disabled || !templates.find(t => +t.id === +id)) return;

      setOpen(false);
      dispatch(
        setAppGlobalConfig({
          showTemplatesLightbox: false,
          selectedTemplate: templates.find(t => +t.id === +id),
          showPreviewTemplate: true,
        }),
      );
    };
    const _onClose = () => {
      setOpen(false);
      dispatch(setAppGlobalConfig({ showTemplatesLightbox: false }));
    };

    return (
      <Component
        {...props}
        open={showLightbox}
        onClose={_onClose}
        templates={templates}
        applyTemplate={_applyTemplate}
        selectedTemplate={activeTemplate}
        disabled={disabled}
      />
    );
  };
};

export default componentHOC;
