import React, { useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';

import ConfirmDialog from 'components/ConfirmDialog';

export default ({ integrationName, disconnect, disabled }) => {
  const [confirmDialogDisplay, setConfirmDialogDisplay] = useState(false);

  return (
    <Fragment>
      <Button id="disconnect-button" color="secondary" onClick={() => setConfirmDialogDisplay(true)} disabled={disabled}>
        Disconnect From {integrationName}
      </Button>
      <ConfirmDialog
        title={`Disconnect From ${integrationName}`}
        text={
          <div>
            <p>
              Disconnecting {integrationName} will disable {integrationName} linking and update for your entire Workspace. Do you
              want to continue?
            </p>
            <br />
            <p>You may reconnect following the {integrationName} integration step in the future.</p>
          </div>
        }
        contentComponent="div"
        isOpen={confirmDialogDisplay}
        onCancel={() => setConfirmDialogDisplay(false)}
        onConfirm={() =>
          disconnect()
            .then(() => setConfirmDialogDisplay(false))
            .catch(() => setConfirmDialogDisplay(false))
        }
      />
    </Fragment>
  );
};
