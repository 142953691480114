import React from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';

const CustomFormControl = styled(FormControl)`
  &&&& {
    ${props => props.labelPosition === 'left' && `
        display: grid;
        align-items: center;
        grid-template-columns: 150px auto;

        * {
          grid-column: 2/2;
        }

        label {
          position: relative;
          grid-column: 1/1;
        }

        div {
          margin-top: 0;
        }
    `}
  }
`;

export default props => {
  return <CustomFormControl {...props} />;
};
