import React from 'react';

import './dhtmlx-gantt.css';
import './Gantt';

// const GanttWrapper = Component => {
//   return Loadable({
//     loading: PageLoading,
//     loader: () => import('./Gantt'),
//     render: (loaded, props) => {
//       return <Component {...props} />;
//     },
//   });
// };

export default Component => props => <Component {...props} />;
