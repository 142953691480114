import styled from 'styled-components';

const TextWrapper = styled.div`
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ $nOfTextRowsToDisplay = 0 }) => $nOfTextRowsToDisplay};
  -webkit-box-orient: vertical;
  flex: 1;
`;

export default TextWrapper;
