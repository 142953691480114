import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { defaultTo, isNil, pipe, prop } from 'ramda';

import { getUserName } from 'utils';

const defaultToEmptyArray = defaultTo([]);
const hasNotParentId = pipe(prop('parent_id'), isNil);

const buildPathFromAncestry = (list, parentId) => {
  const parent = list.find(parentItem => parentItem.id === parentId);

  let previousPath = [];

  if (!parent) return previousPath;

  if (parent?.parent_id) {
    previousPath = buildPathFromAncestry(list, parent?.parent_id).flat();
  }
  return [...previousPath, parent?.title];
};

/**
 * Build a grid row item based on the portfolio data entity.
 *
 * @param {Object} item
 * @param {Object} options
 * @param {String} options.parentTitle
 * @param {String} options.settingKey
 * @param {Boolean} options.selectionMode
 * @returns {Object} row item with the properties required by the grid.
 */
const buildGridRowItem = (item, options = {}) => {
  const { settingKey = '', selectionMode = false, parentPath = null } = options;
  const path = parentPath ? [...parentPath, item.title] : [item.title];

  return {
    ...item,
    title: item.title,
    ownerName: getUserName(item.owner),
    updatedBy: getUserName(item.updatedBy),
    path,
    selectable: selectionMode,
    uniqueId: `${settingKey.toUpperCase()}${item.id || '-PENDING'}`,
  };
};

const useGridRowsFormat = (items, { settingKey, gridStorePath, multiLevelEnabled = false } = {}) => {
  const selectionMode = useSelector(state => state.grids.getIn([gridStorePath, 'selectionMode']));

  const gridRows = useMemo(() => {
    const list = defaultToEmptyArray(items);
    const filteredItems = multiLevelEnabled ? list : list.filter(hasNotParentId);
    const options = { settingKey, selectionMode };

    return filteredItems.map(item => {
      if (item.parent_id) {
        const parentPath = buildPathFromAncestry(list, item.parent_id);

        return buildGridRowItem(item, { ...options, parentPath });
      }

      return buildGridRowItem(item, options);
    });
  }, [settingKey, multiLevelEnabled, items, selectionMode]);

  return { gridRows };
};

export default useGridRowsFormat;
