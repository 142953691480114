export default (gridApi, method = () => {}) => {
  if (!gridApi) return;

  setTimeout(() => {
    try {
      method(gridApi);
    } catch (e) {
      // pass
    }
  }, 0);
};
