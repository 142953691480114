import React from 'react';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import ZoomIcon from 'design-system/atoms/ZoomIcon/index';

import { zoomModes } from 'design-system/organisms/Timeline/New';

const zoomSizeMap = {
  [zoomModes.DAY]: 'small',
  [zoomModes.WEEK]: 'medium',
  [zoomModes.QUARTER]: 'big',
};

const ZoomButton = ({ selectedZoom, changeSelectedZoom }) => {
  const size = zoomSizeMap[selectedZoom?.id ?? zoomModes.WEEK];

  return (
    <ButtonIcon title={selectedZoom && selectedZoom.title} onClick={changeSelectedZoom}>
      <ZoomIcon size={size}>
        <span />
      </ZoomIcon>
    </ButtonIcon>
  );
};

export default ZoomButton;
