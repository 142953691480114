import { useCallback } from 'react';

import { GROUP_EXCLUDE_DRAG } from '../helpers/constants';
import useSwimlaneActions from './useSwimlaneActions';

const MILESTONE_TOP_LANE = 'milestoneTopLane';
const RIGHT_DIRECTION = 'right';

const useSwimlaneHandlers = ({
  treeData,
  firstGroupData,
  secondGroupData,
  filterData,
  filter,
  groupTypeTitle,
  dataTypeTitle,
  slotWidth,
  zoomMode,
  updateState,
}) => {
  // Get actions to create or update the projects/milestones
  const { createProject, createMilestone, updateProjectTimeline } = useSwimlaneActions({
    firstGroupData,
    secondGroupData,
    filterData,
    filter,
  });

  // Drag handler
  const handleDrag = useCallback(
    (delta, task, dateDiff, skipVertical, oldOrder, newOrder) => {
      if ([groupTypeTitle, dataTypeTitle].includes(GROUP_EXCLUDE_DRAG)) {
        updateState({ isDialogVisible: true });
        return;
      }

      const isMultipleGrouping = !!dataTypeTitle;

      if (isMultipleGrouping) {
        if ((oldOrder[0] === newOrder[0] && oldOrder[1] === newOrder[1]) || skipVertical) {
          updateProjectTimeline(task.id, dateDiff, dateDiff, 0, newOrder[0], newOrder[1]);
        } else {
          updateProjectTimeline(task.id, dateDiff, dateDiff, 1, newOrder[0], newOrder[1]);
        }
      } else if ((oldOrder[0] !== undefined && newOrder[0] !== undefined && oldOrder[0] === newOrder[0]) || skipVertical) {
        updateProjectTimeline(task.id, dateDiff, dateDiff, 0, oldOrder[0]);
      } else if (newOrder && newOrder[0]) {
        updateProjectTimeline(task.id, dateDiff, dateDiff, 1, newOrder[0]);
      }
    },
    [treeData, groupTypeTitle, dataTypeTitle, slotWidth, zoomMode],
  );

  // Resize handler
  const handleResize = useCallback(
    (direction, _1, _2, task, laneIndex, groupIndex, dateDiff) => {
      const isMultipleGrouping = !!dataTypeTitle;

      if (direction === RIGHT_DIRECTION) {
        if (isMultipleGrouping) {
          updateProjectTimeline(task.id, 0, dateDiff, 0, treeData[laneIndex].groups[groupIndex].id);
        } else {
          updateProjectTimeline(task.id, 0, dateDiff, 0, treeData[laneIndex].id);
        }
      } else if (isMultipleGrouping) {
        updateProjectTimeline(task.id, dateDiff, 0, 0, treeData[laneIndex].groups[groupIndex].id);
      } else {
        updateProjectTimeline(task.id, dateDiff, 0, 0, treeData[laneIndex].id);
      }
    },
    [treeData, dataTypeTitle, slotWidth, zoomMode, updateProjectTimeline],
  );

  // Row click handler
  const handleClickRow = useCallback(
    (laneKey, laneId = null, groupId = null) => {
      if (laneKey === MILESTONE_TOP_LANE) {
        createMilestone();
      } else {
        createProject(laneId, groupId);
      }
    },
    [createProject, createMilestone],
  );

  return {
    handleDrag,
    handleResize,
    handleClickRow,
  };
};

export default useSwimlaneHandlers;
