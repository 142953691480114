import React from 'react';
import { not } from 'ramda';
import styled from 'styled-components';

import { FormGroup, FormLabel } from '@material-ui/core';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import { ISSUE_COUNT_PROGRESS, STORY_POINTS_PROGRESS, TIME_ESTIMATES_PROGRESS } from 'constants/integrations';

import CollapsePanel from 'components/CollapsePanel';

import IssueCountDetails from './components/IssueCountDetails';
import StoryPointsDetails from './components/StoryPointsDetails';
import TimeEstimatesDetails from './components/TimeEstimatesDetails';
import ProgressTrackingTypeField from './components/ProgressTrackingTypeField';
import ProjectsVerticalTabs from './components/ProjectsVerticalTabs';

import useProgressTrackingConfigPage from './hooks/useProgressTrackingConfigPage';

import { PreferenceGroup } from './ProgressTrackingConfig.styles';

// Temp flag to hide progress tracking by time estimate
const ENABLE_TIME_ESTIMATES_PROGRESS = false;

const ProgressTrackingConfig = props => {
  const { integrationType, orgIntegrationId, userCanUpdateOrgIntegration } = props;

  const {
    progressTracking,
    projects,
    selectedProjectKey,
    onChangeSelectedProjectKey,
    onChangeTypeHandler,
    onChangeDaysPerIssueHandler,
    onChangeStoryPointsHandler,
    onChangeStoryPointPerDayHandler,
    onChangeTimeEstimateHandler,
    onAddNewProjectKey,
    onDeleteProjectKey,
  } = useProgressTrackingConfigPage(integrationType, orgIntegrationId);

  const isIssueCountSelected = progressTracking.track_progress_by === ISSUE_COUNT_PROGRESS || !progressTracking.track_progress_by;
  const isStoryPointsSelected = progressTracking.track_progress_by === STORY_POINTS_PROGRESS;
  const isTimeEstimatesSelected =
    ENABLE_TIME_ESTIMATES_PROGRESS && progressTracking.track_progress_by === TIME_ESTIMATES_PROGRESS;

  return (
    <Wrapper>
      <CollapsePanel title={<TextDeprecated size="medium">Progress tracking setting</TextDeprecated>}>
        <PanelContent>
          <ProjectsVerticalTabs
            projects={projects}
            selectedProjectKey={selectedProjectKey}
            onChangeSelectedProjectKey={onChangeSelectedProjectKey}
            onAddNewProjectKey={onAddNewProjectKey}
            onDeleteProjectKey={onDeleteProjectKey}
            userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
          />
          <ConfigsContainer>
            <PreferenceGroup>
              <FormLabel control="legend">Track progress by</FormLabel>
              <Preference row>
                <ProgressTrackingTypeField
                  value={isIssueCountSelected}
                  onChange={onChangeTypeHandler}
                  type={ISSUE_COUNT_PROGRESS}
                  label="Issue count"
                  disabled={not(userCanUpdateOrgIntegration)}
                />
                <ProgressTrackingTypeField
                  value={isStoryPointsSelected}
                  onChange={onChangeTypeHandler}
                  type={STORY_POINTS_PROGRESS}
                  label="Story Points"
                  disabled={not(userCanUpdateOrgIntegration)}
                />
                {ENABLE_TIME_ESTIMATES_PROGRESS ? (
                  <ProgressTrackingTypeField
                    value={isTimeEstimatesSelected}
                    onChange={onChangeTypeHandler}
                    type={TIME_ESTIMATES_PROGRESS}
                    label="Time Tracking"
                    disabled={not(userCanUpdateOrgIntegration)}
                  />
                ) : null}
              </Preference>
            </PreferenceGroup>
            {isIssueCountSelected ? (
              <IssueCountDetails
                value={progressTracking.display_days_per_issue}
                onChange={onChangeDaysPerIssueHandler}
                disabled={not(userCanUpdateOrgIntegration)}
              />
            ) : null}
            {isStoryPointsSelected ? (
              <StoryPointsDetails
                storyPoints={progressTracking.default_story_points}
                storyPointPerDay={progressTracking.display_storypoint_per_day}
                onChangeStoryPoints={onChangeStoryPointsHandler}
                onChangeStoryPointPerDay={onChangeStoryPointPerDayHandler}
                disabled={not(userCanUpdateOrgIntegration)}
              />
            ) : null}
            {isTimeEstimatesSelected ? (
              <TimeEstimatesDetails
                value={progressTracking.default_time_estimate}
                onChange={onChangeTimeEstimateHandler}
                disabled={not(userCanUpdateOrgIntegration)}
              />
            ) : null}
          </ConfigsContainer>
        </PanelContent>
      </CollapsePanel>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const PanelContent = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.palette.text.lightGrey};
  padding-bottom: 16px;
`;

const ConfigsContainer = styled.div``;

const Preference = styled(FormGroup)`
  &&&& {
    margin: 0 6px;
  }
`;

export default ProgressTrackingConfig;
