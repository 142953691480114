import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_FIELDS_LAYOUT'),
  ...getThunkActionTypes('UPSERT_FIELDS_LAYOUT'),
};

const SET_CURRENT_FORM_LAYOUT_DIRTY = 'SET_CURRENT_FORM_LAYOUT_DIRTY';
const OPEN_FORM_LAYOUT_DIRTY_WARNING = 'OPEN_FORM_LAYOUT_DIRTY_WARNING';
const CLOSE_FORM_LAYOUT_DIRTY_WARNING = 'CLOSE_FORM_LAYOUT_DIRTY_WARNING';
const CLEAN_FORM_LAYOUT_STATE = 'CLEAN_FORM_LAYOUT_STATE';

export const { FETCH_FIELDS_LAYOUT, FETCH_FIELDS_LAYOUT_FULFILLED, UPSERT_FIELDS_LAYOUT, UPSERT_FIELDS_LAYOUT_FULFILLED } =
  actionTypes;

export {
  SET_CURRENT_FORM_LAYOUT_DIRTY,
  OPEN_FORM_LAYOUT_DIRTY_WARNING,
  CLOSE_FORM_LAYOUT_DIRTY_WARNING,
  CLEAN_FORM_LAYOUT_STATE,
};
