/**
 * Autocomplete Molecule Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { PureComponent, Fragment } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import Autosuggest from 'react-autosuggest';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import isFunction from 'lodash/isFunction';

function renderInput(inputProps) {
  const { ref, autoFocus, value, setRefInput, onIconClick, placeholder, ...other } = inputProps;

  const Component = this.props.inputComponent ? this.props.inputComponent : AutocompleteTextField;

  return (
    <Fragment>
      <Component
        labelPosition={this.props.labelPosition}
        value={value}
        label={this.props.label}
        disabled={this.props.disabled}
        required={this.props.required}
        margin={this.props.margin || 'none'}
        onKeyPress={this.props.onKeyPress}
        onKeyDown={this.props.onKeyDown}
        onBlur={this.props.onBlur}
        placeholder={placeholder}
        variant={this.props.variant}
        inputRef={input => {
          ref(input);
          setRefInput(input);
        }}
        fullWidth={!!this.props.fullWidth}
        style={this.props.style || {}}
        isOnGrid={this.props.isOnGrid}
        hideBottomBorder={this.props.hideBottomBorder}
        InputProps={{
          autoFocus,
          readOnly: this.props.readonly,
          ...other,
        }}
        inputProps={{
          autoComplete: 'off',
        }}
        InputLabelProps={{
          shrink: other.shrink,
        }}
        {...other}
      />
      <InputIcon hasLabel={!!this.props.label} onClick={onIconClick} style={{ display: this.props.hideIcon && 'none' }}>
        <ExpandMoreIcon />
      </InputIcon>
    </Fragment>
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const label = getSuggestionValue(suggestion, true);
  const matches = match(label, query);
  const parts = parse(label, matches);

  return (
    <MenuItem
      selected={isHighlighted}
      component="div"
      style={{
        whiteSpace: 'normal',
        minHeight: '24px',
        height: 'initial',
        fontSize: this.props.isOnGrid && '0.8125rem',
      }}
    >
      {this.props.prependSuggestion !== undefined && this.props.prependSuggestion(suggestion)}
      <div>
        {parts.map((part, index) => {
          return part.highlight || (suggestion && suggestion.new) ? (
            <strong key={part.text} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          ) : (
            <span key={part.text} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          );
        })}
      </div>
    </MenuItem>
  );
}

function getSuggestionValue(suggestion, onRender) {
  const _getResult = () => {
    if (suggestion && suggestion.new && !onRender) {
      return suggestion.value || '';
    }

    if (typeof suggestion === 'string') {
      return suggestion;
    }

    if (!suggestion || typeof suggestion !== 'object') {
      return '';
    }

    return suggestion.label || suggestion.title || '';
  };
  const result = _getResult();

  return result || '';
}

export default class Autocomplete extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      selected: false,
      value: props.value || '',
    };
    this.renderInput = renderInput.bind(this);
    this.renderSuggestion = renderSuggestion.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.setRefInput = this.setRefInput.bind(this);
    this.focusInput = this.focusInput.bind(this);
  }

  componentDidUpdate = prevProps => {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    } else if (!isEqual(prevProps.suggestions, this.props.suggestions)) {
      this.setState({
        suggestions: this.getSuggestions(),
      });
    }
  };

  focusInput() {
    if (this.input) {
      this.input.focus();
    }
  }

  setRefInput(input) {
    const { autoFocus, onBlur, charPress } = this.props;

    this.input = input;

    if (input && autoFocus) {
      setTimeout(() => {
        input.focus();
        input.select();

        if (charPress) {
          this.setState({ value: charPress });
        }
      }, 0);
    }

    if (onBlur && input) {
      input.addEventListener('blur', this.props.onBlur);
    }
  }

  getSuggestions = wholeValue => {
    const cleanedValue = wholeValue || '';

    const allSuggestions = isFunction(this.props.suggestions) ? this.props.suggestions() : this.props.suggestions;

    // allSuggestions.forEach(s => {
    //   cleanedValue = cleanedValue.replace(s, '');
    // });

    const matchSuggestionsWithSubstring = value => {
      const inputValue = value ? value.toLowerCase() : '';
      const inputLength = inputValue.length;

      let count = 0;

      if (this.props.readonly) {
        return [];
      }

      if (inputLength === 0) {
        return allSuggestions;
      }

      const result =
        inputLength === 0 || !allSuggestions
          ? []
          : allSuggestions.filter(suggestion => {
              const label = getSuggestionValue(suggestion);

              const keep =
                count < 5 && inputLength >= 3
                  ? label.toLowerCase().includes(inputValue.replace(/ +/gi, ' '))
                  : count < 5 && label.toLowerCase().slice(0, inputLength) === inputValue.replace(/ +/gi, ' ');

              if (keep) {
                count += 1;
              }

              return keep;
            });

      return !result.length ? [] : result;
    };

    let currentCursor = this.currentSelectionStart;

    let confirmedSuggestion = [];
    let suggestions;

    while (currentCursor >= this.selectionStart) {
      const substring = cleanedValue?.substring(currentCursor, this.currentSelectionStart || 0);

      suggestions = matchSuggestionsWithSubstring(substring !== ' ' ? substring : '');

      if (suggestions.length) {
        confirmedSuggestion = suggestions;
        this.matchedCursor = currentCursor;
      }
      currentCursor -= 1;
    }

    return ((confirmedSuggestion || []).length ? confirmedSuggestion : allSuggestions).sort((a, b) => (a > b ? 1 : -1));
  };

  handleSuggestionsFetchRequested = ({ value, ...rest }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    // this.setState({
    //   suggestions: [],
    // });
  };

  handleChange = (event, { newValue, method }) => {
    if (method === 'type') {
      const prevCurrentSelectionStart = this.currentSelectionStart;

      this.currentSelectionStart = this.input.selectionStart;

      if (
        (this.currentSelectionStart === undefined ||
          this.selectionStart === undefined ||
          this.input.selectionStart !== prevCurrentSelectionStart + 1) &&
        (newValue?.length || 0) > (this.state.value?.length || 0)
      ) {
        this.selectionStart = this.input.selectionStart > 0 ? this.input.selectionStart - 1 : 0;
      } else if ((newValue?.length || 0) <= (this.state.value?.length || 0)) {
        this.selectionStart = this.input.selectionStart;
        this.currentSelectionStart = this.input.selectionStart;
      }
    }

    this.allowedMatch = newValue.substring(this.selectionStart, this.input.selectionStart);

    if (method === 'up' || method === 'down') return;
    this.props.handleChange && this.props.handleChange(newValue);
    this.setState({ value: newValue });

    if (!newValue) {
      this.props.onValueChange && this.props.onValueChange(null);
    } else {
      this.props.onValueChange && this.props.onValueChange(newValue);
    }
  };

  shouldRenderSuggestions = () => {
    return true;
  };

  onSuggestionSelected(event, { suggestion }) {
    event.stopPropagation();
    this.input.focus();
    const { onSuggestionSelected, onValueChange } = this.props;

    this.setState({ selected: true });

    if (onSuggestionSelected && typeof onSuggestionSelected === 'function') {
      onSuggestionSelected(suggestion);
    }

    let cursor = this.matchedCursor;
    let cursorEnd = this.currentSelectionStart;

    if (cursor === undefined || cursor === null) {
      cursor = this.input?.selectionStart || 0;
      cursorEnd = this.input?.selectionStart || 0;
    }

    onValueChange &&
      onValueChange((this.state.value?.substring(0, cursor) || '') + suggestion + (this.state.value?.substring(cursorEnd) || ''));

    const storedSelectionStart = this.matchedCursor !== undefined ? this.matchedCursor : this.input?.selectionStart;

    setTimeout(() => {
      this.input.selectionStart = storedSelectionStart + suggestion.length;
      this.input.selectionEnd = storedSelectionStart + suggestion.length;
      this.selectionStart = this.input.selectionStart;
      this.matchedCursor = null;
    });
  }

  onClickInput = event => {
    this.matchedCursor = null;
  };

  onFocus = event => {
    setTimeout(() => {
      this.setState({ suggestions: this.getSuggestions(), matchValue: '' });
    });

    if (this.props.onFocus) this.props.onFocus();
  };

  onBlur = event => {
    setTimeout(() => {
      const value = this.state.selected ? this.state.value : this.props.value;

      this.setState({ value, suggestions: this.getSuggestions(value), selected: false, matchValue: '' });
    });
  };

  renderSuggestionsContainer = ({ containerProps, children }) => {
    let toRender = null;

    if (this.input) {
      // this.input is the input ref as received from Autosuggest
      const inputCoords = this.input.getBoundingClientRect();
      const style = {
        position: 'absolute',
        left: inputCoords.left + window.scrollX, // adding scrollX and scrollY to get the coords wrt document instead of viewport
        top: inputCoords.top + 52 + window.scrollY,
        maxHeight: '264px',
        overflow: 'auto',
        zIndex: 1000000,
        backgroundColor: '#ffffff',
        borderRadius: '4px',
        boxShadow: '0 4px 16px 0 rgba(0,0,0,.15), 0 1px 2px 0 rgba(0,0,0,.07), 0 0 1px 0 rgba(0,0,0,.2)',
        width: inputCoords.width,
      };

      toRender = (
        <div {...containerProps} className={`${containerProps.className} autocomplete-portal`} style={style}>
          {children}
        </div>
      );
      return ReactDOM.createPortal(toRender, document.body);
    }

    return null;
  };

  render() {
    const { classes, placeholder, endAdornment, shrink, isOnGrid, addNew } = this.props;
    const { value, suggestions } = this.state;

    const theme = {
      container: {
        flexGrow: 1,
        position: 'relative',
      },
      suggestionsContainerOpen: {
        position: 'absolute',
        marginBottom: '15px',
        left: 0,
        right: 0,
        maxHeight: '200px',
        zIndex: 10000,
        overflow: 'auto',
        backgroundColor: 'white',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        width: isOnGrid ? 240 : 'auto',
      },
      suggestion: {
        display: 'block',
      },
      suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
      },
    };

    const inputVal = value || '';
    const inputSuggestions = [...suggestions];
    const newSuggestion = { title: `Create "${inputVal}"`, inputVal, new: true };
    const showNew =
      typeof inputVal === 'string' &&
      addNew &&
      !inputSuggestions.some(
        s => getSuggestionValue(s).replace(/ +/gi, ' ').toLowerCase() === inputVal.replace(/ +/gi, ' ').toLowerCase(),
      );

    if (inputVal && addNew && showNew) inputSuggestions.push(newSuggestion);

    return (
      <span ref={this.props.associateContainerRef}>
        <Autosuggest
          theme={theme}
          renderInputComponent={this.renderInput}
          suggestions={inputSuggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionHighlighted={() => {
            return this.state.value;
          }}
          getSuggestionValue={getSuggestionValue}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          renderSuggestion={this.renderSuggestion}
          onSuggestionSelected={this.onSuggestionSelected}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          inputProps={{
            onClick: this.onClickInput,

            onBlur: this.onBlur,
            onFocus: this.onFocus,
            classes,
            value: inputVal,
            onChange: this.handleChange,
            setRefInput: this.setRefInput,
            name: this.props.name,
            onIconClick: () => this.input.focus(),
            placeholder,
            endAdornment,
            shrink,
            autoComplete: 'off',
            ...this.props.inputProps,
          }}
        />
      </span>
    );
  }
}

const InputIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding-top: ${({ hasLabel }) => (hasLabel ? '20px' : '4px')};
  padding-bottom: 2px;
  background: transparent;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: hsl(0, 0%, 40%);

  &:hover {
    cursor: text;
  }
`;

const AutocompleteTextField = styled(TextField)`
  width: 100%;

  ${props =>
    props.hideBottomBorder &&
    `
    > div::before{
      border-bottom: 0 !important;
    }
    > div > div::focus{
      background-color: transparent;
    }
  `}

  &&& input {
    font-size: ${props => props.isOnGrid && '0.8125rem'};
    padding: ${props => props.isOnGrid && '0.8125rem 0.4rem'};
  }
`;
