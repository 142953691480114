import React from 'react';

import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { spacing } from 'design-system/theme';

// todo: how should we address this? we want to keep same component usage on this dialog
import FieldsGroup from 'containers/IdeasList/IdeasListControlsBar/GridShowColumns/components/FieldsGroup';

import useShowColumnsRoadmapHistory from './hooks/useShowColumnsRoadmapHistory';
import Text from 'design-system/atoms/Text';

const ShowColumnsDialogRoadmapHistory = ({ isOpen, gridColumns, onClose, changeColumnVisible, visibleColumnsOnGrid }) => {
  const {
    visibleColumns,
    columnsToCompare,
    genericMultiselectGroup,
    compareMultiselectGroup,

    handleChangeColumnVisible,
    hideColumns,
    handleChangeComparableColumnVisible,
    hideComparableColumns,
    changeCompareColumns,
  } = useShowColumnsRoadmapHistory({ visibleColumnsOnGrid, isOpen, gridColumns });

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth>
      <DialogTitle>Show Fields</DialogTitle>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <DialogContent>
        {/* Generic Multi select at top */}
        <FieldsGroup
          key="generic"
          group={genericMultiselectGroup}
          visibleColumns={visibleColumns}
          changeColumnVisible={handleChangeColumnVisible}
          hideColumns={hideColumns}
        />
        <StyledFieldsGroupHighlight
          key="compare"
          group={compareMultiselectGroup}
          visibleColumns={columnsToCompare}
          changeColumnVisible={handleChangeComparableColumnVisible}
          hideColumns={hideComparableColumns}
        />
        <Text variant="p">
          Fields selected for comparison cannot be used for <ItalicText>Group By</ItalicText> of{' '}
          <ItalicText>Display Fields</ItalicText>
        </Text>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            changeColumnVisible(visibleColumns);
            changeCompareColumns();
          }}
          color="primary"
        >
          Apply
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowColumnsDialogRoadmapHistory;

ShowColumnsDialogRoadmapHistory.propTypes = {
  isOpen: PropTypes.bool,
  gridColumns: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  changeColumnVisible: PropTypes.func.isRequired,
};

ShowColumnsDialogRoadmapHistory.defaultProps = {
  isOpen: false,
};

const CloseButton = styled(IconButton)`
  &&&& {
    position: absolute;
    top: ${spacing()}px;
    right: ${spacing(4)}px;
  }
`;

const StyledFieldsGroupHighlight = styled(FieldsGroup)`
  margin-bottom: ${spacing()}px;
`;

const ItalicText = styled.span`
  font-style: italic;
`;
