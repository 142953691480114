/**
 * MicroChip Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import styled, { css } from 'styled-components';
import omit from 'lodash/omit';
import Chip from '@material-ui/core/Chip';

import invertedTextColor from 'design-system/utils/invertedTextColor';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow';
import { spacing } from 'design-system/theme';

const BOLD_VARIANT = 'bold';
const OUTLINED_VARIANT = 'outlined';

export default styled(props => {
  return (
    <TooltipOnOverflow text={props?.label} elementWithOverflow="span > span">
      <Chip
        {...omit(props, ['fullWidth', 'color', 'children'])}
        label={<span>{props.label || ''}</span>}
        onClick={!props.disabled && props.onClick}
      />
    </TooltipOnOverflow>
  );
})`
  &&&& {
    height: 21px;
    margin: 2px;
    background-color: ${({ color }) => color};
    border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;
    ${props => (!props.fullWidth ? 'max-width: 100px;' : 'max-width: 100%;')}

    span {
      color: ${({ color, textColor }) => textColor || invertedTextColor(color, true)};
      padding: ${spacing(0.75)}px;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
      line-height: ${({ theme }) => theme.typography.lineHeightSmallRem}rem;
      font-weight: normal;
    }

    ${({ variant, theme }) =>
      [BOLD_VARIANT, OUTLINED_VARIANT].includes(variant) &&
      css`
        text-transform: uppercase;

        span {
          font-size: ${theme.typography.fontSizeExtraSmallRem}rem;
          font-weight: ${theme.typography.fontWeightBold};
        }
      `}

    ${({ variant, color, borderColor }) =>
      variant === OUTLINED_VARIANT &&
      css`
        border-color: ${borderColor || color};
      `}
  }
`;
