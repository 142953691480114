import React, { useCallback, useImperativeHandle, useState } from 'react';
import { string } from 'prop-types';
import Popover from '@material-ui/core/Popover';

import SecondaryColorPicker from 'components/SecondaryColorPicker';

import cellEditorHoc from '../helpers/cellEditorHoc';

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const ColorCellEditor = cellEditorHoc(({ value: originalValue = '', forwardedRef, eGridCell, stopEditing, ...rest }) => {
  const [internalValue, setInternalValue] = useState(originalValue);

  useImperativeHandle(forwardedRef, () => ({
    getValue() {
      return internalValue;
    },
  }));

  const handleChange = value => {
    setInternalValue(value.hex);
  };

  const handleCloseOptionsEditor = useCallback(() => {
    stopEditing();
  });

  /*  Since we use settings page on other popups/dialogs the popover
    mechanism of the AgGrid was not enough to render the color picker correctly and with full visibility.
   */
  return (
    <Popover
      open
      anchorEl={eGridCell}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={handleCloseOptionsEditor}
    >
      <SecondaryColorPicker color={internalValue} onChange={handleChange} />
    </Popover>
  );
});

ColorCellEditor.propTypes = {
  value: string,
};

export default ColorCellEditor;
