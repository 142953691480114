import { not } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { basicValidationField } from '../helpers';
import { selectFormLayoutIsDirty } from '../store';
import { setCurrentFormLayoutDirty } from '../store/actions';

const defaultGetFormFieldKey = field => field;

const useLayoutRequiredFields = ({
  type,
  formData,
  onSave,
  requiredFieldsKeys = [],
  validationByField = {},
  getFormFieldKey = defaultGetFormFieldKey,
}) => {
  const dispatch = useDispatch();
  const formIsDirty = useSelector(state => selectFormLayoutIsDirty(state, type));

  const setFormIsDirty = isDirty => {
    dispatch(setCurrentFormLayoutDirty(isDirty, type));
  };

  const validateLayoutField = (fieldKey, value) => {
    const validationFn = validationByField[fieldKey] || basicValidationField;
    const valid = validationFn(value);

    return valid;
  };

  const getInvalidRequiredFields = (formData, requiredFieldsKeys) => {
    return requiredFieldsKeys.filter(fieldKey => {
      const formField = getFormFieldKey(fieldKey);
      const value = formData && formData[formField];

      const valid = validateLayoutField(fieldKey, value);

      return not(valid);
    });
  };

  const invalidFieldsOnForm = getInvalidRequiredFields(formData, requiredFieldsKeys);

  const isFieldValid = name => {
    const ingoreValidation = not(formIsDirty);

    if (ingoreValidation) {
      return true;
    }

    return not(invalidFieldsOnForm.includes(name));
  };

  const isFieldRequired = name => requiredFieldsKeys.includes(name);

  const fieldValidation = {
    isFieldValid,
    isFieldRequired,
  };

  const onSaveWithValidation = (formFieldKey, value) => {
    const invalidFieldsOnFormWithCurrentField = getInvalidRequiredFields(
      { ...formData, [formFieldKey]: value },
      requiredFieldsKeys,
    );

    const currentValidationIsDirty = invalidFieldsOnFormWithCurrentField.length > 0;

    // update form dirty status
    setFormIsDirty(currentValidationIsDirty);

    return onSave(formFieldKey, value);
  };

  return {
    fieldValidation,

    onSaveWithValidation,
  };
};

export default useLayoutRequiredFields;
