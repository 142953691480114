import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';

import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import BaseLayout from 'design-system/organisms/BaseLayout/index';
import MultiSelectDropdown2ColumnsPopover from 'design-system/organisms/MultiSelectDropdown2ColumnsPopover/index';
import ShowFieldsIcon from 'design-system/atoms/ShowFieldsIcon/index';

import { useCommonGridProps } from 'design-system/molecules/AgGridReact-New/hooks';
import { getRowId } from 'design-system/molecules/AgGridReact-New/helpers';

import { getUsers } from 'store/users/selectors';
import { getCurrentUser } from 'store/login/selectors';
import { getHasAdvancedMetricReporting, getOrganization } from 'store/organization/selectors';

import { BaseGrid } from 'containers/Grids';
import ActionsToolbar from './components/ActionsToolbar';

import { BASE_ROW_HEIGHT, DEFAULT_TABLE_HEIGHT } from 'constants/grid';
import { METRICS_GRID_PAGE } from 'constants/filters';
import { METRICS } from 'store/grids/constants';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import useDeepEffect from 'hooks/useDeepEffect';
import useFilteredMetrics from 'hooks/useFilteredMetrics';
import useMetricsGridColumns from 'hooks/grid/metrics/useMetricsGridColumns';
import useMetricsGrid from 'hooks/grid/metrics/useMetricsGrid';
import useMetrics from 'hooks/grid/metrics/useMetrics';
import useOpenMetricFromEntityWithMetrics from 'hooks/useOpenMetricFromEntityWithMetrics';
import useUpdateGridStateFromViewData from 'hooks/grid/useUpdateGridStateFromViewData';
import useProjectsListLightboxContext from 'hooks/useProjectsListLightbox';
import useMetricsGridEventHandlers from '../hooks/useMetricsGridEventHandlers';
import useMetricModeMetrics from '../hooks/useMetricModeMetrics';
import useExportCsvFromGrid from 'features/GridExport/hooks/useExportCsvFromGrid';
import useGridApiContext from 'hooks/grid/useGridApiContext';

export default function MetricsGrid() {
  const currentUser = useSelector(getCurrentUser);
  const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);
  const organization = useSelector(getOrganization);
  const users = useSelector(getUsers);

  const isAnonymousUser = currentUser.is_anonymous;

  const gridApi = useRef();
  const gridColumnApi = useRef();
  const moreButtonRef = useRef();

  const hideMetadataRoadmaps = useMemo(() => !organization.enable_metadata_roadmaps, [organization]);

  const { canUpdate, canCreate, canView } = usePermissions();
  const allowCreate = canCreate(PERMISSION_RESOURCES.metric) && !canView(PERMISSION_FEATURES.metricLightboxDetails);
  const allowUpdate = canUpdate(PERMISSION_RESOURCES.metric);

  const canViewProjectsListLightbox = canView(PERMISSION_FEATURES.metricProjectsLightbox);

  const canExport = canView(PERMISSION_FEATURES.exportMetricOkrsFromGrid);

  const { openProjectsListLightbox: openProjectsListLightboxAction } = useProjectsListLightboxContext();

  const openProjectsListLightbox = metric => openProjectsListLightboxAction(metric, 'metric');

  const gridProps = useCommonGridProps({ isTreeView: false });

  const { handleOpenMetricView } = useOpenMetricFromEntityWithMetrics();

  const { registerGridApi } = useGridApiContext();

  const { handleExportToCsv } = useExportCsvFromGrid(METRICS, 'metrics');

  const {
    metrics,
    addMetricWithoutSave,
    createMetric,
    updateMetricById,
    removeUnsavedMetrics,
    switchMetricsRowOrder,
    createMetricRoadmap,
    deleteMetricRoadmap,
    bulkDeleteMetricRoadmaps,
    searchText,
    updateSearchText,
    addMetricValue,
    deleteMetricValue,
    updateMetricValue,
    tableShowFieldsEl,
    setTableShowFieldsEl,
    tableVisibleFields,
    handleChangeTableFields,
    handleShowAllTableFields,
    handleHideAllTableFields,
    leftTableFields,
    rightTableFields,
    canViewMetricIntegration,
    canUpdateMetricIntegration,
    metricOrgIntegrations,
    updateMetricIntegration,
  } = useMetrics();

  const {
    columnDefs: metricsGridColumnDefs,
    defaultColumnDef,
    beforeColumnDefs,
  } = useMetricsGridColumns({
    allowActions: allowUpdate,
    isMetricMode: true,
    roadmapColumn: {
      createMetricRoadmap,
      deleteMetricRoadmap,
      bulkDeleteMetricRoadmaps,
      metrics,
      hideMetadataRoadmaps,
    },
    users,
    openMetricView: hasAdvancedMetricReporting && handleOpenMetricView,
    openProjectsListLightbox: canViewProjectsListLightbox && openProjectsListLightbox,
    canDragRows: allowUpdate,
    canViewMetricIntegration,
    canUpdateMetricIntegration,
    updateMetricIntegration,
  });

  const { gridState, saveGridState, handleRowDrag, handleUpdateMetricById } = useMetricsGrid({
    addMetricValue,
    deleteMetricValue,
    switchMetricsRowOrder,
    updateMetricById,
    updateMetricValue,
    metricOrgIntegrations,
  });

  const quickFilteredMetrics = useMetricModeMetrics(metrics, METRICS_GRID_PAGE);

  const filteredMetrics = useFilteredMetrics(quickFilteredMetrics, { searchText });

  const gridEventHandlers = useMetricsGridEventHandlers({
    drag: handleRowDrag,
    items: filteredMetrics,
    remove: removeUnsavedMetrics,
    save: createMetric,
    update: handleUpdateMetricById,
  });

  const { onGridReadySetData, updateDataOnStateGridChange } = useUpdateGridStateFromViewData(gridState, saveGridState);

  const handleTableShowFieldsClicked = () => {
    if (moreButtonRef.current) setTableShowFieldsEl(moreButtonRef.current);
  };

  const columnDefs = useMemo(
    () => [
      ...beforeColumnDefs,
      ...(tableVisibleFields
        ? metricsGridColumnDefs.filter(col => tableVisibleFields.includes(col.headerName))
        : metricsGridColumnDefs),
    ],
    [tableVisibleFields, metricsGridColumnDefs, beforeColumnDefs],
  );

  const onGridReady = params => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;

    registerGridApi(METRICS, params.api, params.columnApi);

    params.api.sizeColumnsToFit();
    onGridReadySetData(gridApi, gridColumnApi);
  };

  const dropdownOptions = [
    {
      id: 'selectTableFields',
      key: 'selectTableFields',
      onClick: () => handleTableShowFieldsClicked(),
      title: (
        <span>
          <ShowFieldsIcon style={{ fontSize: '24px', verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Select table fields</span>
        </span>
      ),
    },
    ...(canExport
      ? [
          {
            id: 'export',
            key: 'export',
            onClick: handleExportToCsv,
            title: (
              <span>
                <DownloadIcon style={{ fontSize: '24px', verticalAlign: 'bottom' }} />
                <span style={{ paddingLeft: 13 }}>Export</span>
              </span>
            ),
          },
        ]
      : []), // TODO: FIGURE OUT STYLE
  ];

  useDeepEffect(() => {
    updateDataOnStateGridChange(gridApi, gridColumnApi);
  }, [gridState]);

  return (
    <BaseLayout>
      {isAnonymousUser ? null : (
        <ActionsToolbar
          allowActions={allowCreate}
          addMetricWithoutSave={addMetricWithoutSave}
          searchText={searchText}
          updateSearchText={updateSearchText}
          moreButtonRef={moreButtonRef}
          handleTableShowFieldsClicked={handleTableShowFieldsClicked}
          showArchivedToggle={false}
          pageId={METRICS_GRID_PAGE}
          dropdownOptions={dropdownOptions}
        />
      )}
      <GridWrapper>
        <BaseGrid
          {...gridProps}
          {...gridEventHandlers}
          getRowId={getRowId}
          rowData={filteredMetrics}
          onGridReady={onGridReady}
          columnDefs={columnDefs}
          defaultColDef={defaultColumnDef}
          headerHeight={BASE_ROW_HEIGHT}
          height={DEFAULT_TABLE_HEIGHT}
        />
      </GridWrapper>
      <MultiSelectDropdown2ColumnsPopover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!tableShowFieldsEl}
        anchorEl={tableShowFieldsEl}
        onClose={() => {
          setTableShowFieldsEl(null);
        }}
        leftItems={leftTableFields}
        rightItems={rightTableFields}
        selected={tableVisibleFields}
        handleShowAll={handleShowAllTableFields}
        handleHideAll={handleHideAllTableFields}
        onChange={handleChangeTableFields}
      />
    </BaseLayout>
  );
}

const GridWrapper = styled.div`
  && {
    .ag-root {
      margin: 0;
    }

    .align-center > div {
      display: inline-flex;
      align-items: center;
    }
  }
`;
