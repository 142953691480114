import { indexOf, keys, pluck, values, not, pipe, prop, equals } from 'ramda';

const LEFT_AREA = 'leftFields';
const RIGHT_AREA = 'rightFields';
const BOTTOM_AREA = 'hiddenFields';

const LEFT_POSITION = 'left';
const RIGHT_POSITION = 'right';

const extractIds = pluck('id');

const sortByIndex = (a, b) => a.index - b.index;

const getRequiredFields = fields => {
  const allFields = values(fields);

  return allFields.filter(f => not(f.hidden) && f.required === true);
};

const getFieldsByGroupArea = fields => {
  const allFields = values(fields);

  const leftFields = allFields.filter(f => not(f.hidden) && f.position === LEFT_POSITION).sort(sortByIndex);
  const rightFields = allFields.filter(f => not(f.hidden) && f.position === RIGHT_POSITION).sort(sortByIndex);
  const hiddenFields = allFields.filter(f => f.hidden === true).sort(sortByIndex);

  return {
    [LEFT_AREA]: leftFields,
    [RIGHT_AREA]: rightFields,
    [BOTTOM_AREA]: hiddenFields,
  };
};

const moveFieldOnArray = (from, to, arr) => {
  const newArr = [...arr];
  const item = newArr.splice(from, 1)[0];

  newArr.splice(to, 0, item);

  return newArr;
};

const getFieldUpdateOnDragElement = (fields, destination, source, draggableId) => {
  const sourceId = source.droppableId.replace('droppable-', '');
  const destinationId = destination.droppableId.replace('droppable-', '');
  const fieldId = draggableId.replace(`draggable-${sourceId}-`, '');

  const fieldsWithFieldUpdated = values(fields).reduce((acc, field) => {
    if (field.id === fieldId) {
      return {
        ...acc,
        [field.id]: {
          ...field,
          ...([LEFT_AREA, RIGHT_AREA].includes(destinationId)
            ? { position: destinationId === LEFT_AREA ? LEFT_POSITION : RIGHT_POSITION }
            : {}),
          hidden: destinationId === BOTTOM_AREA,
        },
      };
    }

    return {
      ...acc,
      [field.id]: field,
    };
  }, {});

  const fieldsByArea = getFieldsByGroupArea(fieldsWithFieldUpdated);

  fieldsByArea[destinationId] = moveFieldOnArray(
    indexOf(fieldId, extractIds(fieldsByArea[destinationId])),
    destination.index,
    fieldsByArea[destinationId],
  );

  const _getFieldIndex = field => {
    const isSameAsCurrentField = pipe(prop('id'), equals(field.id));
    const findCurrentFieldGroup = key => fieldsByArea[key].some(isSameAsCurrentField);
    const areasKeys = keys(fieldsByArea);
    const fieldAreaKey = areasKeys.find(findCurrentFieldGroup);
    const fieldsGroup = fieldsByArea[fieldAreaKey];

    if (not(fieldsGroup)) {
      return -1;
    }

    return indexOf(field.id, extractIds(fieldsGroup));
  };

  const fieldsReordered = values(fieldsWithFieldUpdated).reduce((acc, field) => {
    return {
      ...acc,
      [field.id]: {
        ...field,
        index: _getFieldIndex(field),
      },
    };
  }, {});

  return fieldsReordered;
};

export {
  getRequiredFields,
  getFieldsByGroupArea,
  getFieldUpdateOnDragElement,
  LEFT_AREA,
  RIGHT_AREA,
  BOTTOM_AREA,
  LEFT_POSITION,
  RIGHT_POSITION,
};
