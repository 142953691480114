import isNil from 'lodash/isNil';
import { equals, pipe, prop, not, propEq, isEmpty, head } from 'ramda';

import { NEW_ROW_ID } from 'design-system/molecules/AgGridReact-New/helpers';
import { ACTIVE_STATUS } from 'constants/common';
import getSystemFieldName from 'utils/getSystemFieldName';

import getAllRoadmaps from './getAllRoadmaps';
import {
  ROADMAP_CORP_KEY,
  ROADMAP_KEY,
  PRODUCT_1_KEY,
  PRODUCT_2_KEY,
  OBJECT_ROADMAP,
  OBJECT_PRODUCT,
  ROOT_LEVEL,
} from './constants';
import { PRODUCT_1_LEVEL, PRODUCT_2_LEVEL, ROADMAP_CORP_LEVEL, ROADMAP_LEVEL } from 'constants/roadmaps';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

const ROADMAP_TYPE = 0;
const PRODUCT_TYPE = 1;

const isOfObjectRoadmapType = pipe(prop('type'), equals(OBJECT_ROADMAP));
const isOfObjectProductType = pipe(prop('type'), equals(OBJECT_PRODUCT));
const isNotActive = pipe(prop('status'), equals(ACTIVE_STATUS), not);
const isActive = propEq('status', ACTIVE_STATUS);

const getRowId = ({ data }) => (!data.uniqueId ? NEW_ROW_ID : data.uniqueId);

const getDataPath = data => data.path;

const isParentItem = data => isNil(data?.parent_id);

const checkAddVisibility = (allowActions, hasProducts, hasProducts2, selectionMode, canCreate) => {
  return ({ data }) => {
    const notAllowActionsForProducts = not(
      allowActions && hasProducts2 && !selectionMode && isParentItem(data) && isActive(data),
    );
    const notAllowActions = not(allowActions && hasProducts && !selectionMode && isActive(data));

    if (data && isOfObjectProductType(data) && notAllowActionsForProducts) {
      return false;
    }

    if (notAllowActions) {
      return false;
    }

    switch (data?.level) {
      case ROADMAP_CORP_LEVEL:
        return canCreate(PERMISSION_RESOURCES.roadmapCorp);
      case ROADMAP_LEVEL:
        return canCreate(PERMISSION_RESOURCES.roadmap);
      case PRODUCT_1_LEVEL:
        return canCreate(PERMISSION_RESOURCES.product1);
      case PRODUCT_2_LEVEL:
        return canCreate(PERMISSION_RESOURCES.product2);
      default:
        return false;
    }
  };
};

/**
 * @function hasNotParentArchived
 *
 * checks if the row item has a parent that is archived
 *
 * @param {Array} items - array with all roadmaps and products at same level
 * @returns {Boolean}
 */
const hasNotParentArchived = (items, data) => {
  const type = prop('type', data);

  // if the row is a roadmap it has not parent -> hasNotParentArchived = true
  if (type === ROADMAP_TYPE) return true;

  const parentId = prop('parent_id', data);
  const parentRoadmapId = prop('roadmap_id', data);
  let parent = {};

  // if the product has not parent id it means that the parent is a roadmap
  if (!parentId) {
    parent = items.find(item => item.id === parentRoadmapId && item.type === ROADMAP_TYPE);
  } else {
    // if there is parent id, it means that the row is a roadmap level 2
    parent = items.find(item => item.id === parentId && item.type === PRODUCT_TYPE);
  }

  return isActive(parent);
};

/**
 * @function getChangeParentErrorMessageByType
 *
 * Gets the drag and drop to change parent error message by level type.
 *
 * @param {String} type
 * @param {Object} systemFields
 * @returns {String}
 */
export const getChangeParentErrorMessageByType = (type, systemFields) => {
  const level0Label = getSystemFieldName(ROADMAP_CORP_KEY, systemFields);
  const level1Label = getSystemFieldName(ROADMAP_KEY, systemFields);
  const level2Label = getSystemFieldName(PRODUCT_1_KEY, systemFields);
  const level3Label = getSystemFieldName(PRODUCT_2_KEY, systemFields);

  switch (type) {
    case ROADMAP_KEY: {
      return `This ${level1Label} can only be moved within it's current ${level0Label}.`;
    }
    case PRODUCT_1_KEY:
      return `This ${level2Label} can only be moved within it's current ${level1Label}.`;
    case PRODUCT_2_KEY:
      return `This ${level3Label} can only be moved within it's current ${level2Label}.`;
    default:
      return '';
  }
};

const checkCheckboxSelection = ({ data, context, api }) => {
  if (data.organization_id !== context.organizationId) {
    return false;
  }

  const isCorpRoadmap = context.organizationAccessControlData.some(access => access.portfolio_roadmap_id === data.id);

  if (isCorpRoadmap) {
    return false;
  }

  const currentSelectedItems = api.getSelectedRows();

  return (
    isEmpty(currentSelectedItems) ||
    (head(currentSelectedItems).type === data.type && head(currentSelectedItems).level === data.level)
  );
};

const checkDefaultColEditable = (params, canUpdate, allowActions) => {
  const { data } = params;

  if (not(allowActions)) {
    return false;
  }

  switch (data?.level) {
    case ROADMAP_LEVEL:
      return canUpdate(PERMISSION_RESOURCES.roadmap);
    case PRODUCT_1_LEVEL:
      return canUpdate(PERMISSION_RESOURCES.product1);
    case PRODUCT_2_LEVEL:
      return canUpdate(PERMISSION_RESOURCES.product2);
    default:
      return allowActions;
  }
};

const checkDeleteDisabled = (row, canDelete) => {
  switch (row?.level) {
    case ROADMAP_LEVEL:
      return not(canDelete(PERMISSION_RESOURCES.roadmap));
    case PRODUCT_1_LEVEL:
      return not(canDelete(PERMISSION_RESOURCES.product1));
    case PRODUCT_2_LEVEL:
      return not(canDelete(PERMISSION_RESOURCES.product2));
    default:
      return false;
  }
};

const checkRowDrag = (params, canUpdate) => {
  const { data } = params;

  switch (data?.level) {
    case ROADMAP_CORP_LEVEL:
      return canUpdate(PERMISSION_RESOURCES.roadmapCorp);
    case ROADMAP_LEVEL:
      return canUpdate(PERMISSION_RESOURCES.roadmap);
    case PRODUCT_1_LEVEL:
      return canUpdate(PERMISSION_RESOURCES.product1);
    case PRODUCT_2_LEVEL:
      return canUpdate(PERMISSION_RESOURCES.product2);
    default:
      return false;
  }
};

export {
  ROADMAP_CORP_KEY,
  ROADMAP_KEY,
  PRODUCT_1_KEY,
  PRODUCT_2_KEY,
  OBJECT_ROADMAP,
  OBJECT_PRODUCT,
  ROOT_LEVEL,
  isOfObjectRoadmapType,
  isOfObjectProductType,
  isNotActive,
  getRowId,
  getDataPath,
  isParentItem,
  checkAddVisibility,
  getAllRoadmaps,
  checkCheckboxSelection,
  hasNotParentArchived,
  checkDefaultColEditable,
  checkRowDrag,
  checkDeleteDisabled,
};
