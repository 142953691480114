import { useState } from 'react';

const INITIAL_DIALOG_PROPS = { open: false };

const useSaveViewDialog = () => {
  const [saveDialogProps, setDialogProps] = useState(INITIAL_DIALOG_PROPS);

  const openSaveDialog = () => setDialogProps({ open: true });

  const closeSaveDialog = () => setDialogProps({ open: false });

  return { saveDialogProps, openSaveDialog, closeSaveDialog };
};

export default useSaveViewDialog;
