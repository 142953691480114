/**
 * DragDrop Organism Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */

import React from 'react';

import { DragDropContext } from 'react-beautiful-dnd';

export default (props) => {
  const { children, onDragStart, onDragEnd } = props;

  const handleDragStart = (result) => {
    onDragStart && onDragStart(result);
  };

  const handleDragEnd = (result) => {
    onDragEnd && onDragEnd(result);
  };

  return (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      {children}
    </DragDropContext>
  );
};
