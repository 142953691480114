import React from 'react';

import formatPercent from 'design-system/utils/formatPercent';
import { PROGRESS_PERCENTAGE_DECIMAL_PLACES } from 'constants/common';

// Duplicated from /legacy/OkrProgressDisplay.js

export default props => {
  const { value } = props;

  if (!value) return '';

  const valueAsPercentage = formatPercent(value, PROGRESS_PERCENTAGE_DECIMAL_PLACES);

  return <span>{valueAsPercentage}</span>;
};
