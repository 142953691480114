import React from 'react';
import FormControl from '@material-ui/core/FormControl';

import LoadableAutocomplete from 'design-system/molecules/LoadableAutocomplete/index';

import { AUTOCOMPLETE_FROM_SERVER_DELAY } from 'constants';
import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';
import useLoadProjectCustomerRequestsOnAutocomplete from 'hooks/useLoadProjectCustomerRequestsOnAutocomplete';

const LinkRequestCellEditor = ({ onSave, ...props }) => {
  const [_loadAutocompleteCustomerRequests] = useLoadProjectCustomerRequestsOnAutocomplete({}, true);

  const _onSuggestionSelected = val => {
    onSave(val);
  };

  return (
    <FormControl fullWidth>
      <LoadableAutocomplete
        style={{ width: '100%' }}
        name="idea-parent"
        placeholder={TYPE_TO_SEARCH_PLACEHOLDER}
        loadData={_loadAutocompleteCustomerRequests}
        onSuggestionSelected={_onSuggestionSelected}
        loadDelay={AUTOCOMPLETE_FROM_SERVER_DELAY}
        {...props}
      />
    </FormControl>
  );
};

export default LinkRequestCellEditor;
