import storageLocal from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

const getPublicShareHash = () => {
  const currentUrl = new URL(window.location.href);
  const isPublicShare = currentUrl.pathname.startsWith('/share/');

  if (isPublicShare) {
    return currentUrl.pathname.split('/').pop();
  }

  return '';
};

/**
 * Get storage driver for the redux persistence
 * When rendering dragonboat app in normal circumstances we are using localStorage as default storage engine
 * This will persist on the user browser until deleted
 * 
 * When rendering public shared views or embedded views we don't want localStorage to be used to we are going
 * to use sessionStorage which persists until the browser tab is closed
 * For the session storage we also will use a prefix to ensure every sharedView key renders from a fresh store
 */
export default () => {
  const isEmbedded = window.self !== window.top;
  const publicShareHash = getPublicShareHash();
  const saltedPrefix = parseInt(Math.random() * 10000).toString();

  if (publicShareHash) {
    sessionStorage.clear();
    return {
      login: storageSession,
      metadata: storageSession,
      prefix: `${publicShareHash}_${saltedPrefix}_`,
    };
  }

  if (isEmbedded) {
    return {
      login: storageLocal,
      metadata: storageSession,
      prefix: parseInt(Math.random() * 10000).toString(),
    };
  }

  return {
    login: storageLocal,
    metadata: storageLocal,
    prefix: '',
  };
};
