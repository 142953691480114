import { useSelector } from 'react-redux';

import useDeepMemo from 'hooks/useDeepMemo'; 

import { CUSTOM_FIELD_TYPES } from 'store/customFields/constants';
import getCustomFormulaOptions from 'store/customFields/helpers/getCustomFormulaOptions';
import { getProjectsCustomFields, getCustomerRequestsCustomFields } from 'store/customFields/selectors';


export default (customFieldsType = CUSTOM_FIELD_TYPES.PROJECTS) => {
  const customFields = customFieldsType === CUSTOM_FIELD_TYPES.PROJECTS ? 
    useSelector(getProjectsCustomFields) : 
    useSelector(getCustomerRequestsCustomFields);

  const formulaOptions = useDeepMemo(() => {
    return getCustomFormulaOptions(customFields, customFieldsType);
  }, [customFields, customFieldsType]);
  
  return formulaOptions;
};
