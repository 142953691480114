import { useEffect } from 'react';
import { toast } from 'react-toastify';

let sortAgg = 0;
const sortEvents = {};

/**
 * Custom hook to set the sort on the gantt pages
 *
 * @param {*} gantt
 * @param {*} updateState
 * @param {*} ganttData
 */
export default function (gantt, updateState, ganttData, sort) {
  if (gantt && sortAgg === 3) {
    updateState({ sort: null });
    sortAgg = 0;

    gantt.config.sort = false;
    gantt.clearAll();
    gantt.render();

    gantt.config.sort = true;
    gantt.parse(ganttData);
    gantt.render();
  }

  useEffect(() => {
    if (gantt) {
      if (sortEvents.onBeforeTaskMove) {
        gantt.detachEvent(sortEvents.onBeforeTaskMove);
      }

      sortEvents.onBeforeTaskMove = gantt.attachEvent('onBeforeTaskMove', () => {
        const tastVisible = document.getElementsByClassName('Toastify__toast');

        if (sort && !tastVisible.length) {
          toast('Disable sort to re-order', { autoClose: 5000, toastId: 'DISABLE_SORT_TOAST' });
        }
        return !sort;
      });
    }
  }, [!!gantt, sort]);

  const onLoadGantt = ganttInstance => {
    ganttInstance.attachEvent('onGridHeaderClick', (field, e) => {
      if (field === 'add') {
        return true;
      }

      const children = e.target.childNodes;
      let direction = true;

      if (children.length > 1) {
        direction = children[1].className.includes('gantt_asc');
      }
      sortAgg++;
      ganttInstance.sort(field, direction);
      updateState({ sort: { field, direction } });
    });
  };
  const sortGantt = lsState => {
    if (lsState.sort && lsState.sort.field) {
      if (lsState.sort.direction === true) sortAgg = 1;
      if (lsState.sort.direction === false) sortAgg = 2;
      gantt.sort(lsState.sort.field, lsState.sort.direction, null, false);
    }
  };

  return [onLoadGantt, sortGantt];
}
