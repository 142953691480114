import React, { useMemo, useCallback } from 'react';
import { PERMISSION_RESOURCES, SYSTEM_FIELD } from '@dragonboat/permissions';

import { CATEGORIES } from 'store/grids/constants';
import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SettingsDialogs from 'components/SettingsDialogs';
import { BaseGrid } from 'containers/Grids';
import {
  useSettingsActions,
  useSettingsGridSelection,
  useSettingsGridProps,
  useSettingsGridEventHandlers,
  useSettingsGridColumns,
} from 'containers/Grids/SettingsGrid/hooks';
import useCategories from './hooks/useCategories';
import useCategoriesGridColumns from './hooks/useCategoriesGridColumns';
import useForceVisibleColumns from 'hooks/useForceVisibleColumns';
import usePermissions from 'hooks/permissions/usePermissions';

const CATEGORY_KEY = 'category';
const TITLE_FIELD = 'title';

export default ({
  showActionBar = true,
  systemFields,
  updateSystemFieldsName,
  hideMetadataRoadmaps,
  forceVisibleFields,
  customHeight,
  hideArchivedItems,
}) => {
  const {
    categories,
    createCategoryRoadmap,
    deleteCategoryRoadmap,
    bulkDeleteCategoryRoadmaps,
    bulkDeleteCategories,
    deleteCategory,
    createCategories,
    updateCategories,
    switchCategoriesRowOrder,
    updateCategoryById,
    createCategory,
    removeUnsavedCategories,
    mergeCategories,
    addCategoryWithoutSave,
  } = useCategories(hideArchivedItems);

  // Specific column definition for categories
  const categoriesGridColumnDefs = useCategoriesGridColumns({
    roadmapColumn: {
      createCategoryRoadmap,
      deleteCategoryRoadmap,
      bulkDeleteCategoryRoadmaps,
      categories,
      hideMetadataRoadmaps,
    },
  });

  const handleRowDrag = useCallback(
    (currentNode, lastOverNode, { position }) =>
      switchCategoriesRowOrder(currentNode.data.id, lastOverNode.data.id, null, position),
    [switchCategoriesRowOrder],
  );

  // Settings common agGrid configuration

  const {
    selectionMode,
    selectedItems,
    enableSelectionMode,
    disableSelectionMode,
    updateSelectedOnAgGridInit,
  } = useSettingsGridSelection(CATEGORIES, { actions: { remove: removeUnsavedCategories } });

  const {
    itemToDelete,
    setItemToDelete,
    deleteItem,
    showBulkDeleteDialog,
    setShowBulkDeleteDialog,
    deleteProgress,
    bulkDeleteItems,
    mergeLightboxVisible,
    showMergeLightbox,
    hideMergeLightbox,
  } = useSettingsActions({
    settingType: CATEGORIES,
    selectedItems,
    disableSelectionMode,
    deleteById: deleteCategory,
    bulkDelete: bulkDeleteCategories,
  });

  const settingsGridProps = useSettingsGridProps({
    bulkCreate: createCategories,
    bulkUpdate: updateCategories,
  });

  const { canUpdate, canCreate, canMerge, canView } = usePermissions();
  const canUpdateOrCreate = canUpdate(PERMISSION_RESOURCES.category) || canCreate(PERMISSION_RESOURCES.category);
  const permissions = {
    allowActions: canUpdateOrCreate,
    canAdd: canCreate(PERMISSION_RESOURCES.category),
    rename: {
      canUpdate: showActionBar && canUpdate(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.category }),
      canView: showActionBar && canView(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.category }),
    },
    canMerge: showActionBar && canMerge(PERMISSION_RESOURCES.category),
    canBulkDelete: showActionBar && canUpdateOrCreate,
    canDragRows: showActionBar && canUpdateOrCreate,

  };

  const settingsGridColumns = useSettingsGridColumns({
    allowActions: permissions.allowActions,
    selectionMode,
    setItemToDelete,
    items: categories,
    canDragRows: permissions.canDragRows,
  });

  const settingsGridEventHandlers = useSettingsGridEventHandlers({
    settingType: CATEGORIES,
    items: categories,
    focusField: TITLE_FIELD,
    actions: {
      update: updateCategoryById,
      save: createCategory,
      remove: removeUnsavedCategories,
      drag: handleRowDrag,
    },
  });

  const visibleColumns = useForceVisibleColumns(categoriesGridColumnDefs, forceVisibleFields);

  // Join settings and tags column definitions
  const columnDefs = useMemo(
    () => [...settingsGridColumns.beforeColumnDefs, ...visibleColumns, ...settingsGridColumns.afterColumnDefs],
    [settingsGridColumns.beforeColumnDefs, settingsGridColumns.afterColumnDefs, visibleColumns],
  );

  return (
    <>
      {permissions.allowActions && (
        <SettingsActionsToolbar
          settingKey={CATEGORY_KEY}
          settingType={CATEGORIES}
          systemFields={systemFields}
          updateSystemFieldsName={updateSystemFieldsName}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          onEnableSelection={enableSelectionMode}
          onCancelSelection={disableSelectionMode}
          setShowBulkDeleteDialog={setShowBulkDeleteDialog}
          showMergeLightbox={showMergeLightbox}
          add={addCategoryWithoutSave}
          renamePermissions={permissions.rename}
          canMerge={permissions.canMerge}
          canBulkDelete={permissions.canBulkDelete}
          hideAddButton={!permissions.canAdd}
        />
      )}
      <BaseGrid
        onGridReady={updateSelectedOnAgGridInit}
        rowData={categories}
        columnDefs={columnDefs}
        defaultColDef={settingsGridColumns.defaultColDef}
        {...settingsGridProps}
        {...settingsGridEventHandlers}
        height={customHeight ?? settingsGridProps.height}
      />
      <SettingsDialogs
        settingKey={CATEGORY_KEY}
        settingType={CATEGORIES}
        showBulkDeleteDialog={showBulkDeleteDialog}
        setShowBulkDeleteDialog={setShowBulkDeleteDialog}
        deleteProgress={deleteProgress}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        deleteItem={deleteItem}
        bulkDelete={bulkDeleteItems}
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
        mergeLightboxVisible={mergeLightboxVisible}
        hideMergeLightbox={hideMergeLightbox}
        merge={mergeCategories}
      />
    </>
  );
};
