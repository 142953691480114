import { useState, useEffect } from 'react';

export const DESKTOP_BREAKPOINT = 1024;

const useDeviceType = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const updateDeviceType = () => {
    const width = window?.innerWidth;

    setIsDesktop(width >= DESKTOP_BREAKPOINT);
    setIsMobile(width < DESKTOP_BREAKPOINT);
  };

  useEffect(() => {
    updateDeviceType();

    const handleResize = () => {
      updateDeviceType();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isDesktop, isMobile };
};

export default useDeviceType;
