import { ifElse, isEmpty, head, prop, pipe, defaultTo, reject, isNil } from 'ramda';

const getMetrics = prop('metrics');
const getValueFromHeadItem = value => pipe(head, prop(value));
const defaultToEmptyArray = defaultTo([]);
const returnNil = () => null;

const GROOMED_VALUES = ['name', 'actual_value', 'actual_date', 'target_value', 'target_date'];

/**
 * If objective/key result has matrics return metrics `value`. otherwise return null
 * @param  {} obj
 * @param  {} value
 */
export default (obj, metrics, value) => {
  if (!obj) {
    return null;
  }

  if (GROOMED_VALUES.includes(value) && !!obj.metrics?.length) {
    const metricId = obj.metrics[0]?.id;
    const metric = metrics.find(m => m.id === metricId);

    return metric && metric[value] ? metric[value] : null;
  }

  return pipe(getMetrics, defaultToEmptyArray, reject(isNil), ifElse(isEmpty, returnNil, getValueFromHeadItem(value)))(obj);
};
