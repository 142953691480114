import styled from 'styled-components';

import Text from 'design-system/atoms/Text';
import theme, { spacing } from 'design-system/theme';
import Loading from 'design-system/atoms/Loading/Loading';

const Container = styled.div`
  position: fixed;
  height: ${({ containerHeight, $height }) => $height || containerHeight}px;
  z-index: ${theme.zIndex.gridPopover};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  top: ${({ $top }) => $top != null && `${$top}px`};
  left: ${({ $left }) => $left != null && `${$left}px`};
  width: ${({ $width }) => $width != null && `${$width}px`};
  background-color: white;
`;

const StyledLoading = styled(Loading)`
  &&&& {
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 12px);
    z-index: 1;
  }
`;

const FooterContainer = styled.div`
  bottom: 0;
  position: absolute;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.palette.background.gallery};
`;

const DataList = styled.div`
  height: ${({ $height }) => $height}px;
  overflow: auto;
`;

const ListRowWrapper = styled.div`
  width: calc(500px - ${spacing(4)}px);
`;

const RowText = styled(Text)`
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem !important;
`;

export { Container, StyledLoading, FooterContainer, DataList, ListRowWrapper, RowText };
