import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import CommentsLightbox from 'containers/CommentLightbox/CustomerRequests/CommentsLightbox';

import {
  startEditingComment as startEditingCommentAction,
  stopEditingComment as stopEditingCommentAction,
} from 'store/customerRequests/actions';
import {
  getCustomerRequests,
  getIsCommmentingCustomerRequest,
  getCustomerRequestFormData,
  getIsLoadingCustomerRequests,
} from 'store/customerRequests/selectors';
import useLoadCustomerRequests from 'hooks/customerRequests/useLoadCustomerRequests';

const componentHOC = Component => {
  return props => {
    useLoadCustomerRequests();

    const dispatch = useDispatch();
    const customerRequests = useSelector(getCustomerRequests);
    const isCommenting = useSelector(getIsCommmentingCustomerRequest);
    const isLoading = useSelector(getIsLoadingCustomerRequests);
    const formData = useSelector(getCustomerRequestFormData);

    const startEditingComment = item => dispatch(startEditingCommentAction(item.id));
    const stopEditingComment = item => dispatch(stopEditingCommentAction(item.id));

    return (
      <>
        <Component
          customerRequests={customerRequests}
          startEditingComment={startEditingComment}
          isLoading={isLoading}
          {...props}
        />
        <CommentsLightbox item={formData} isOpen={!!isCommenting} onClose={stopEditingComment} />
      </>
    );
  };
};

export default compose(withRouter, componentHOC);
