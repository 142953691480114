import { useMemo, useRef } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { prop, propEq, pipe, not, isEmpty, propOr, pick, defaultTo } from 'ramda';

import { TIMEFRAMES } from 'store/grids/constants';
import {
  addTimeframeWithoutSave,
  bulkDeleteTimeframeRoadmaps,
  bulkDeleteTimeframes,
  createTimeframe,
  createTimeframeRoadmap,
  createTimeframes,
  deleteTimeframeById,
  deleteTimeframeRoadmap,
  fetchTimeframes,
  mergeTimeframes,
  removeUnsavedTimeframes,
  switchTimeframesRowOrder,
  updateTimeframeById,
  updateTimeframes,
} from 'store/timeframes';
import { getAllTimeframes } from 'store/timeframes/selectors';
import useGridRowsFormat from 'design-system/molecules/AgGridReact-New/hooks/useGridRowsFormat';

import { PERMISSION_RESOURCES, REFERER_SETTINGS } from '@dragonboat/permissions';

import useMetadataTopLevel from 'hooks/metadata/useMetadataTopLevel';
import usePermissions from 'hooks/permissions/usePermissions';

const TIMEFRAME_KEY = 'timeframe';
const TIMEFRAME_KEY_LEVEL_2 = 'timeframe2';

const PARENT_LEVEL = '0';

const defaultToArray = defaultTo([]);

const useTimeframes = hasMultiLevelPortfolioMetadata => {
  const dispatch = useDispatch();
  const storedTimeframes = useSelector(getAllTimeframes);

  const { timeframes: timeframesTopLevel } = useMetadataTopLevel();
  const { canView } = usePermissions();

  const { gridRows: timeframes } = useGridRowsFormat(storedTimeframes, {
    settingKey: timeframesTopLevel,
    gridStorePath: TIMEFRAMES,
    multiLevelEnabled:
      canView(PERMISSION_RESOURCES.timeframeCorp, { referer: REFERER_SETTINGS }) || hasMultiLevelPortfolioMetadata,
  });

  const timeframesRef = useRef(null);

  timeframesRef.current = timeframes;

  const childrenSettingKeys = useMemo(
    () => [
      ...(canView(PERMISSION_RESOURCES.timeframeCorp, { referer: REFERER_SETTINGS }) ? [TIMEFRAME_KEY] : []),
      ...(hasMultiLevelPortfolioMetadata ? [TIMEFRAME_KEY_LEVEL_2] : []),
    ],
    [hasMultiLevelPortfolioMetadata],
  );

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          fetchTimeframes,
          createTimeframe,
          createTimeframes,
          updateTimeframes,
          bulkDeleteTimeframes,
          addTimeframeWithoutSave,
          removeUnsavedTimeframes,
          updateTimeframeById,
          deleteTimeframeById,
          mergeTimeframes,
          switchTimeframesRowOrder,
        },
        dispatch,
      ),
    [dispatch],
  );

  const { updateTimeframeById: updateSingleTimeframe, updateTimeframes: updateMultipleTimeframes } = boundActionCreators;

  const update = (id, updateObj) => {
    const itemBeingUpdated = (timeframesRef.current || []).find(t => t.id === id);
    const children = pipe(prop('children'), defaultToArray)(itemBeingUpdated);

    // before update we need to check:
    // 1 - if the prop being updated is the Status
    // 2 - if 1, if the item being updated has sub items
    // if 1 and 2 all items should be updated with same Status. otherwise update just the item

    // checking 1
    const isUpdatingStatus = propOr(false, 'status')(updateObj);

    if (!isUpdatingStatus) return updateSingleTimeframe(id, updateObj);

    // checking 2

    const isNotParentLevel = pipe(propEq('level', PARENT_LEVEL), not);

    if (isNotParentLevel(itemBeingUpdated)) return updateSingleTimeframe(id, updateObj);

    const isParentWithChildren = pipe(isEmpty, not)(children);

    if (!isParentWithChildren) return updateSingleTimeframe(id, updateObj);

    // updating all items Status

    const update = pick(['status'], updateObj);
    const itemsToUpdate = [{ id, ...update }];

    children.forEach(child => {
      itemsToUpdate.push({ id: child.id, ...update });
    });

    return updateMultipleTimeframes(itemsToUpdate);
  };

  return {
    timeframes,
    childrenSettingKeys,
    createTimeframeRoadmap,
    deleteTimeframeRoadmap,
    bulkDeleteTimeframeRoadmaps,
    update,
    ...boundActionCreators,
    deleteTimeframeById: async (id, itemToDelete) => {
      await boundActionCreators.deleteTimeframeById(id, itemToDelete);

      boundActionCreators.fetchTimeframes();
    },
    bulkDeleteTimeframes: async (ids, socketRoom) => {
      await boundActionCreators.bulkDeleteTimeframes(ids, socketRoom);

      boundActionCreators.fetchTimeframes();
    },
  };
};

export default useTimeframes;
