class DependencyLinesError extends Error {}

const isDependencyLinesError = (e) => e instanceof DependencyLinesError;

/**
 *
 * @param source {'left'|'right'}
 * @param target {'left'|'right'}
 * @return {0|1|2|3} One of:
 *  - `0`: `source` finishes before `target` starts; A.k.a. "`source` blocks `target`"
 *    or "`target` is blocked by `source`".
 *    Happens if `source === 'right' && target === 'left'`.
 *  - `1`: `source` starts before `target` starts;
 *    Happens if `source === 'left' && target === 'left'`.
 *  - `2`: `source` finishes before `target` finishes;
 *    Happens if `source === 'right' && target === 'right'`.
 *  - `3`: `source` starts before `target` finishes;
 *    Happens if `source === 'left' && target === 'right'`
 */
const computeDependencyType = (source, target) => {
  if (source === 'right' && target === 'left') {
    return 0;
  }

  if (source === 'left' && target === 'left') {
    return 1;
  }

  if (source === 'right' && target === 'right') {
    return 2;
  }

  if (source === 'left' && target === 'right') {
    return 3;
  }

  throw new DependencyLinesError(
    `Invalid dependency link: source >> ${source}; target >> ${target}.`,
  );
};

/**
 * @typedef {Object} ConnectionsPoints
 * @property {'left'|'right'} source
 * @property {'left'|'right'} target
 */
/**
 *
 * @param dependencyType {null|0|1|2|3}
 * @return {ConnectionsPoints}
 */
const computeConnectionPoints = (dependencyType) => {
  if (!dependencyType) {
    return {
      source: 'right',
      target: 'left',
    };
  }

  if (dependencyType === 1) {
    return {
      source: 'left',
      target: 'left',
    };
  }

  if (dependencyType === 2) {
    return {
      source: 'right',
      target: 'right',
    };
  }

  if (dependencyType === 3) {
    return {
      source: 'left',
      target: 'right',
    };
  }

  throw new DependencyLinesError(`Invalid dependency type: ${dependencyType}.`);
};

export { computeDependencyType, computeConnectionPoints, isDependencyLinesError };