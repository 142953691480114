import moment from 'moment-timezone';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import pick from 'lodash/pick';
import isFunction from 'lodash/isFunction';

import { bulkUpdateTasks, createTask } from 'store/tasks';
import { removeGanttTolltip } from 'components/ganttCommon';
import { dafaultFormData } from 'containers/ProjectTaskDialog/utils';

const events = [];

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const { addOnlyTasks, addBasedOnPlanningStage, localMode, showPhaseWarningDialog, hasPlanningStageGroup } = props;

    const [openTaskFn, setOpenTaskFn] = useState(null);

    let tasksToUpate = [];
    const [debouncedUpdateProjectTasks] = useDebouncedCallback(
      (tasks, onUpdate = null) => dispatch(bulkUpdateTasks(tasks)).then(onUpdate),
      250,
    );
    const addOnlyTasksRef = useRef(addOnlyTasks);
    const addBasedOnPlanningStageRef = useRef(addBasedOnPlanningStage);

    addOnlyTasksRef.current = addOnlyTasks;
    addBasedOnPlanningStageRef.current = addBasedOnPlanningStage;
    const { gantt } = props;
    const onBeforeLightbox = id => {
      const task = gantt.isTaskExists(id) ? gantt.getTask(id) : null;
      let parent;

      if (task && !task.$new && task.dbType === 'task') {
        removeGanttTolltip();
        if (openTaskFn) {
          openTaskFn({
            ...task,
            id: task.entityId,
            start_date: moment(task.start_date).parseFromGantt(),
            end_date: moment(task.end_date).parseFromGantt(true),
          });
        }
      } else if (task && task.$new && task.parent) {
        if (task.parent) {
          parent = gantt.getTask(task.parent);
        }

        const basedOnPlanningStage =
          parent && parent.dbType === 'project' && parent.planningStage === 'Confirmed' && addBasedOnPlanningStageRef.current;
        const parentIsTask = parent && parent.dbType === 'task';

        const souldForceAddTasks = isFunction(addOnlyTasksRef.current)
          ? addOnlyTasksRef.current(task, parent)
          : addOnlyTasksRef.current;

        if (basedOnPlanningStage || parentIsTask || souldForceAddTasks) {
          const newTask = {
            id: null,
            start_date: moment(parent.start_date).parseFromGantt(),
            parent_task_id: parent.dbType === 'task' ? parent.entityId : null,
          };
          const parentId = parent.dbType === 'project' ? parent.id : parent.project_id;

          const data = {
            ...dafaultFormData,
            ...newTask,
            project_id: parentId,
            sort_order: gantt.getTaskIndex(id),
          };

          dispatch(createTask(data)).then(({ value }) => {
            const { data } = value;

            // wait for gantt render and set the new task editable
            setTimeout(() => {
              const taskId = `task-${data.id}`;
              const parentId = gantt.getParent(taskId);
              const parent = gantt.getTask(parentId);

              // eslint-disable-next-line security/detect-non-literal-fs-filename
              if (parent && !parent.$open) gantt.open(parent.id);
              gantt.ext.inlineEditors.startEdit(taskId, 'text');
            }, 1000);
          });
          if (!parent.$open) {
            gantt.showTask(parent.id);
          }
          gantt.deleteTask(task.id);
        }
      }

      return false;
    };
    const onAfterTaskUpdate = (id, task) => {
      if (localMode) return true;
      if (!gantt.isTaskExists(id)) return true;

      const ganttTask = gantt.getTask(id);

      if (ganttTask.dbType === 'task') {
        tasksToUpate.push({
          id: ganttTask.entityId,
          progress: +ganttTask.progress,
          start_date: moment(ganttTask.start_date).parseFromGantt(),
          end_date: moment(ganttTask.end_date).parseFromGantt(),
          duration: ganttTask.duration,
          sort_order: gantt.getTaskIndex(id),
          ...pick(ganttTask, ['title', 'ownerName', 'status_color']),
        });
        debouncedUpdateProjectTasks(tasksToUpate, () => {
          tasksToUpate = [];
        });
      }
      return true;
    };
    const onBeforeRowDragEnd = (id, lastParent, fromIndex) => {
      if (localMode) return true;

      if (hasPlanningStageGroup && !!showPhaseWarningDialog) {
        showPhaseWarningDialog();
        return true;
      }

      const task = gantt.getTask(id);
      const newParentId = task.parent;

      if (task.dbType === 'task') {
        const parentProject = gantt.getTask(task.parent);
        const getTasksWithNewSortOrder = tasks =>
          tasks.map(({ id }) => ({
            id,
            sort_order: gantt.getTaskIndex(`task-${id}`),
          }));

        if (newParentId === lastParent) {
          let newTasks;

          // if this condition is true the task parent is a project
          if (parentProject && parentProject.tasks) {
            newTasks = getTasksWithNewSortOrder(parentProject.tasks);
          } else if (parentProject && parentProject.subtasks) {
            newTasks = getTasksWithNewSortOrder(parentProject.subtasks);
          }

          if (newTasks) {
            debouncedUpdateProjectTasks(newTasks);
          }
        } else if (parentProject.dbType === 'project') {
          debouncedUpdateProjectTasks([
            {
              id: task.entityId,
              project_id: parentProject.id,
              parent_task_id: null,
            },
          ]);
        } else if (parentProject.dbType === 'task') {
          debouncedUpdateProjectTasks([
            {
              id: task.entityId,
              parent_task_id: parentProject ? parentProject.entityId : null,
              project_id: parentProject ? parentProject.project_id : null,
            },
          ]);
        }
      }

      return true;
    };

    useEffect(() => {
      if (gantt) {
        if (events.length) {
          while (events.length) {
            gantt.detachEvent(events.pop());
          }
        }
        events.push(gantt.attachEvent('onBeforeLightbox', onBeforeLightbox));
        events.push(gantt.attachEvent('onAfterTaskUpdate', onAfterTaskUpdate));
        events.push(gantt.attachEvent('onBeforeRowDragEnd', onBeforeRowDragEnd));
      }

      return () => {
        while (events.length) {
          gantt.detachEvent(events.pop());
        }
      };
    }, [!!gantt, localMode, hasPlanningStageGroup]);

    return <Component setOpenTaskFn={setOpenTaskFn} />;
  };
};

export default componentHOC;
