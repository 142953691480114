import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrganizationSystemFieldsNames as getOrganizationSystemFieldsNamesSelector } from 'store/organization/selectors';

import { fetchJiraFields as fetchJiraFieldsAction, isLoadingJiraFields as isLoadingJiraFieldsSelector } from 'store/integrations';

import getSystemFieldNameHelper from 'utils/getSystemFieldName';

const container = Component => {
  return props => {
    const { orgIntegrationId, mapping, onClose, onSave } = props;
    const dispatch = useDispatch();

    const [jiraFields, setJiraFields] = useState([]);
    const [currentMapping, setCurrentMapping] = useState(mapping);

    const systemFields = useSelector(state => getOrganizationSystemFieldsNamesSelector(state));
    const isLoadingJiraFields = useSelector(isLoadingJiraFieldsSelector);

    const getSystemFieldName = useCallback(name => getSystemFieldNameHelper(name, systemFields), []);

    const fetchJiraFields = useCallback(async () => {
      const fields = await dispatch(fetchJiraFieldsAction(orgIntegrationId));

      setJiraFields(fields?.data || []);
    }, [orgIntegrationId, dispatch]);

    const onChange = useCallback(data => setCurrentMapping(data), [setCurrentMapping]);

    const onSaveHandler = useCallback(() => {
      const filterInvalidFields = field => !!field.col;
      const filterFieldsNotFoundInJiraFields = field => !!jiraFields.find(f => f.name === field.col);

      const updatedMapping = currentMapping
        .filter(filterInvalidFields)
        .filter(filterFieldsNotFoundInJiraFields)
        .map(d => ({
          jiraKey: jiraFields.find(f => f.name === d.col).key,
          jiraField: jiraFields.find(f => f.name === d.col),
          field: d.field,
          dbAutoSync: d.dbAutoSync,
          jiraAutoSync: d.jiraAutoSync,
        }));

      onSave(updatedMapping);
    }, [currentMapping, jiraFields, onSave]);

    useEffect(() => {
      fetchJiraFields();
    }, []);

    return (
      <Component
        getSystemFieldName={getSystemFieldName}
        jiraFields={jiraFields}
        currentMapping={currentMapping}
        onClose={onClose}
        onSave={onSaveHandler}
        onChange={onChange}
        isLoadingJiraFields={isLoadingJiraFields}
      />
    );
  };
};

export default container;
