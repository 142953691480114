import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

import CloseIcon from '@material-ui/icons/Close';
import Minimize from '@material-ui/icons/Minimize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Drawer from '@material-ui/core/Drawer';

import Text from 'design-system/atoms/Text/index';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import { spacing } from 'design-system/theme';
import { removeElemProps } from 'design-system/utils';

const HEADER_MIN_HEIGHT = 57;

const boxShadowCss = css`
  box-shadow: 0px -2px 12px 0px rgba(204, 204, 204, 0.3);
`;

export default class ModalDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.hiddenFileInput = React.createRef();
  }

  render() {
    const {
      type,
      actions,
      header,
      children,
      title,
      closeButton,
      closeButtonColor,
      customHeaderBackgroundColor,
      customHeaderBackground,
      footer,
      headerComponent,
      withActionsShadow = false,
      withMinimizeCloseButton = false,
      headerStyles,
      subHeader = null,
      ...restProps
    } = this.props;
    const HeaderComponent = headerComponent;

    const _handleClose = () => {
      if (this.props.open && this.props.onClose) this.props.onClose();
    };

    const _handleHeaderClose = () => {
      if (this.props.headerProps?.onClose) {
        this.props.headerProps.onClose();
      } else {
        _handleClose();
      }
    };

    const body = (
      <React.Fragment>
        {title && !header && !headerComponent && (
          <Header backgroundColor={customHeaderBackgroundColor} background={customHeaderBackground}>
            {title && (
              <Text inline as="h3">
                {title}
              </Text>
            )}
          </Header>
        )}
        {closeButton && (
          <HeaderIconButton color={closeButtonColor} onClick={_handleClose} data-cy="close-button" data-test="close-button">
            {withMinimizeCloseButton ? <Minimize fontSize="small" /> : <CloseIcon fontSize="small" />}
          </HeaderIconButton>
        )}
        {header && (
          <Header backgroundColor={customHeaderBackgroundColor} headerStyles={headerStyles}>
            {header}
          </Header>
        )}
        {HeaderComponent && <HeaderComponent {...(this.props.headerProps || {})} onClose={_handleHeaderClose} />}
        {subHeader}
        <Content {...restProps} {...(this.props.contentProps || {})}>
          <div>{children}</div>
        </Content>
        {actions && <Actions withActionsShadow={withActionsShadow}>{actions}</Actions>}
      </React.Fragment>
    );

    if (!type || type !== 'DRAWER') {
      return (
        <DialogWrapper {...restProps}>
          {body}
          {footer}
        </DialogWrapper>
      );
    }

    return (
      <DrawerWrapper anchor="right" {...restProps}>
        {body}
        {footer}
      </DrawerWrapper>
    );
  }
}

const DialogWrapper = styled(removeElemProps(Dialog, ['fullHeight']))`
  &&&& {
    > div[role='document'] > div {
      ${({ fullHeight }) =>
        fullHeight &&
        `
        min-height: calc(100% - 96px);
      `}
      ${({ width }) =>
        width &&
        `
        max-width: ${width};
        width: ${width};
      `}
    }

    ${({ withoutBackdrop }) =>
      withoutBackdrop &&
      `
      > div:nth-child(1) {
        background-color: transparent;
      }
    `}
  }
`;

const Header = styled.div`
  padding: ${spacing(1.5)}px ${spacing(3)}px;
  display: flex;
  align-items: center;
  min-height: ${HEADER_MIN_HEIGHT}px;
  background-color: ${props => props.backgroundColor};
  background: ${props => props.background};
  box-shadow: 0px 2px 12px 0px rgba(204, 204, 204, 0.2);
  box-sizing: border-box;
  z-index: 1;

  ${({ headerStyles }) => headerStyles && headerStyles};
`;

const DrawerWrapper = styled(removeElemProps(Drawer, ['fullHeight', 'fullWidth']))`
  &&&& {
    ${({ withoutBackdrop }) =>
      withoutBackdrop &&
      `
      width:0 !important;
    `};

    > div[role='document'] {
      ${({ fullWidth }) =>
        fullWidth &&
        `
        width: 60%;
      `}
      height: ${({ headerHeight }) => (headerHeight ? `calc(100% - ${headerHeight})` : 'calc(100% - 48px)')};
      margin-top: ${({ headerHeight }) => headerHeight || '48px'};

      ${({ fullHeight }) =>
        fullHeight &&
        css`
          height: 100%;
          margin-top: 0;
        `};
    }

    > div:nth-child(1) {
      ${({ withoutBackdrop }) =>
        withoutBackdrop &&
        `
      background-color: transparent;
      width: 0 !important;
      position: absolute;
      display: none;
    `}
      height: ${({ headerHeight }) => (headerHeight ? `calc(100% - ${headerHeight})` : 'calc(100% - 48px)')};
      margin-top: ${({ headerHeight }) => headerHeight || '48px'};

      ${({ fullHeight }) =>
        fullHeight &&
        css`
          height: 100%;
          margin-top: 0;
        `};
    }

    > div:nth-child(2) {
      width: 100%;

      @media only screen and (min-width: calc(${props => props.width || '900px'} + 200px)) {
        width: ${props => props.width || '900px'};
      }
    }

    ${Header} {
      padding: ${spacing(2.5)}px ${spacing(3)}px ${spacing()}px;
    }
  }
`;

const Content = styled(DialogContent)`
  &&&& {
    padding: ${props => {
      return props.paddingOverride || '24px 24px 24px';
    }};

    ${({ contentBackgroundColor }) => contentBackgroundColor && `background-color: ${contentBackgroundColor};`}
    > div {
      height: 100%;
    }
  }
`;

const Actions = styled(DialogActions)`
  &&&& {
    margin: 0;
    padding: ${spacing(1.25)}px ${spacing(2)}px ${spacing(1.25)}px;
    border-top: 1px solid #fafafa;

    ${({ withActionsShadow }) => (withActionsShadow ? boxShadowCss : '')}
  }
`;

export const HeaderIconButton = styled(ButtonIcon)`
  &&&& {
    position: absolute;
    top: 10px;
    right: ${spacing(2)}px;
    padding: ${spacing()}px;
    z-index: 10000;

    ${({ color }) =>
      color &&
      `
      svg {
        color: ${color};
      }
    `}
  }
`;
