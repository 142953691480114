import { useState, useLayoutEffect } from 'react';
import { not } from 'ramda';

/**
 * @function useGridRowDataWithUpdateDataLoading
 *
 * hook to get grid data and dont update row data if
 *    1 - some update loading is ocurring
 *    2 - the grid is rendering and will avoid crash if not update row date
 *
 * @param  {Array}    data
 * @param  {Boolean}  isUpdateLoading
 * @param  {Object}   options
 * @param  {Function} options.getGridApi
 * @return {Object}
 */
const useGridRowDataWithUpdateDataLoading = (data, isUpdateLoading, { getGridApi }) => {
  const [rowData, setRowData] = useState(data ?? []);

  const gridApi = getGridApi();
  /*
   * isAnimationFrameQueueEmpty is checking is the grid is in the middle of some
   * rendering and on that case will push the data update to next iteration
   * to avoid ag grid crash
   *
   * Documentation link:
   * https://www.ag-grid.com/javascript-data-grid/grid-api/#reference-miscellaneous-isAnimationFrameQueueEmpty
   */
  const isSomeRenderOnGridOcurring = gridApi && not(gridApi.isAnimationFrameQueueEmpty());

  /*
   * On redux actions is dispatching the PENDING and FULLFILED actions on
   * the (update / row order / create) change and it causes grid flickering
   * based on:
   *
   * 1. the PENDING sends the previous data (without the update) to the grid
   * 2. and FULLFILED sends the data with update to the grid
   * 3. this data change on grid causes the cell flickering
   *
   */
  useLayoutEffect(() => {
    /*
     * Will ignore any data change if some update is ocurring
     */
    if (isUpdateLoading) {
      return;
    }

    if (isSomeRenderOnGridOcurring) {
      return;
    }

    setRowData(data);
  }, [isUpdateLoading, data, isSomeRenderOnGridOcurring]);

  return {
    rowData,
  };
};

export default useGridRowDataWithUpdateDataLoading;
