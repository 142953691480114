import { getThunkActionTypes } from 'utils/store/thunk';

const { FETCH_GOALS_ALLOCATION_DATA, FETCH_GOALS_ALLOCATION_DATA_FULFILLED } = getThunkActionTypes('FETCH_GOALS_ALLOCATION_DATA');
const { RECALCULATE_COMPLETED_ALLOCATION_DATA } = getThunkActionTypes('RECALCULATE_COMPLETED_ALLOCATION_DATA');

export { FETCH_GOALS_ALLOCATION_DATA, FETCH_GOALS_ALLOCATION_DATA_FULFILLED, RECALCULATE_COMPLETED_ALLOCATION_DATA };

export const UPDATE_GOALS_ALLOCATION_TIME_PERIOD = 'UPDATE_GOALS_ALLOCATION_TIME_PERIOD';

export const UPDATE_GOALS_ALLOCATION_TIME_UNIT = 'UPDATE_GOALS_ALLOCATION_TIME_UNIT';

export const UPDATE_GOALS_ALLOCATION_GRID_PREFERENCES = 'UPDATE_GOALS_ALLOCATION_GRID_PREFERENCES';
