import moment from 'moment-timezone';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import isFunction from 'lodash/isFunction';

import { updateEstimates } from 'store/estimates';
import { removeGanttTolltip } from 'components/ganttCommon';

const events = [];

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const { localMode, addOnlyEstimates, addBasedOnPlanningStage } = props;
    const [openEstimateFn, setOpenEstimateFn] = useState(null);
    let { current: estimatesToUpdate } = useRef([]);
    const [debouncedUpdateProjectEstimate] = useDebouncedCallback(
      (estimates, onUpdate = null) => dispatch(updateEstimates(estimates)).then(onUpdate),
      250,
    );
    const addOnlyEstimatesRef = useRef(addOnlyEstimates);
    const addBasedOnPlanningStageRef = useRef(addBasedOnPlanningStage);

    addOnlyEstimatesRef.current = addOnlyEstimates;
    addBasedOnPlanningStageRef.current = addBasedOnPlanningStage;

    const { gantt } = props;
    const onBeforeLightbox = id => {
      const task = gantt.isTaskExists(id) ? gantt.getTask(id) : null;
      let parent;

      if (task && !task.$new && task.dbType === 'estimate') {
        removeGanttTolltip();
        if (task.parent) {
          parent = gantt.getTask(task.parent);
        }
        if (openEstimateFn) {
          openEstimateFn(
            {
              ...task,
              id: task.entityId,
              start_date: moment(task.start_date).parseFromGantt(),
              end_date: moment(task.end_date).parseFromGantt(),
            },
            parent,
          );
        }
      } else if (task && task.$new && task.parent) {
        if (task.parent) {
          parent = gantt.getTask(task.parent);
        }

        const addEstimatesBasedOnPlanningStage =
          parent &&
          parent.dbType === 'project' &&
          ['Planning', 'Backlog'].includes(parent.planningStage) &&
          addBasedOnPlanningStageRef.current;

        const souldForceAddEstimates = isFunction(addOnlyEstimatesRef.current)
          ? addOnlyEstimatesRef.current(task, parent)
          : addOnlyEstimatesRef.current;

        if ((addEstimatesBasedOnPlanningStage || souldForceAddEstimates) && openEstimateFn) {
          openEstimateFn(
            {
              id: null,
              start_date: moment(parent.start_date || moment()).parseFromGantt(),
            },
            parent,
          );
          gantt.deleteTask(task.id);
        }
      }

      return false;
    };
    const onAfterTaskUpdate = (id, task) => {
      if (localMode) return true;
      if (!gantt.isTaskExists(id)) return true;

      const ganttTask = gantt.getTask(id);

      if (ganttTask.dbType === 'estimate') {
        estimatesToUpdate.push({
          id: ganttTask.entityId,
          start_date: moment(ganttTask.start_date).parseFromGantt(),
          end_date: moment(ganttTask.end_date).parseFromGantt(),
          duration: ganttTask.duration,
          sort_order: gantt.getTaskIndex(ganttTask.id),
          skill_id: ganttTask.skill_id,
          team_id: ganttTask.team_id,
          estimate_id: ganttTask.estimate_id,
        });
        debouncedUpdateProjectEstimate(estimatesToUpdate, () => {
          estimatesToUpdate = [];
        });
      }
      return true;
    };
    const onBeforeRowDragEnd = (id, lastParent, fromIndex) => {
      if (localMode) return true;

      const task = gantt.getTask(id);

      if (task.dbType === 'estimate') {
        const parentProject = gantt.getTask(+task.parent);

        const newEstimates = parentProject.estimates.map(estimate => {
          return {
            id: estimate.id,
            sort_order: gantt.getTaskIndex(`estimate-${estimate.id}`),
          };
        });

        debouncedUpdateProjectEstimate(newEstimates);
      }

      return true;
    };

    useEffect(() => {
      if (gantt) {
        if (events.length) {
          while (events.length) {
            gantt.detachEvent(events.pop());
          }
        }

        events.push(gantt.attachEvent('onBeforeLightbox', onBeforeLightbox));
        events.push(gantt.attachEvent('onAfterTaskUpdate', onAfterTaskUpdate));
        events.push(gantt.attachEvent('onBeforeRowDragEnd', onBeforeRowDragEnd));
      }

      return () => {
        while (events.length) {
          gantt.detachEvent(events.pop());
        }
      };
    }, [!!gantt, localMode]);

    return <Component setOpenEstimateFn={setOpenEstimateFn} />;
  };
};

export default componentHOC;
