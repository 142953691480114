import React, { useMemo } from 'react';
import Highlighter from 'react-highlight-words';

import BaseTitle from './BaseTitle';

/**
 * Base renderer, to ensure presentation consistency between
 * different types of headers.
 */
const BaseTitleRenderer = ({
  node,
  value,
  icon = '',
  bold = false,
  fontSize = null,
  textColor = null,
  displayChildrenCount = false,
  getNumberOfTextRowsToDisplayOnGridCell,
  titleSearchString,
  addButtonProps,
  openButtonProps,
}) => {
  const nodeIsNotTopLevel = !!node.level;

  const nodeDoesNotHaveChildren = !node.allChildrenCount;

  const childrenCountString = useMemo(() => {
    return displayChildrenCount ? ` (${node.allChildrenCount || 0})` : '';
  }, [node.allChildrenCount, displayChildrenCount]);

  const textValue = titleSearchString ? (
    <Highlighter searchWords={[titleSearchString]} autoEscape textToHighlight={value} />
  ) : (
    value
  );

  const leafShouldHaveIdent = !displayChildrenCount && nodeIsNotTopLevel;
  const compensateNoCollapse = displayChildrenCount && nodeDoesNotHaveChildren;
  const needsIndentation = compensateNoCollapse || leafShouldHaveIdent;

  return (
    <BaseTitle
      icon={icon}
      bold={bold}
      fontSize={fontSize}
      textColor={textColor}
      indent={needsIndentation}
      getNumberOfTextRowsToDisplayOnGridCell={getNumberOfTextRowsToDisplayOnGridCell}
      addButtonProps={addButtonProps}
      openButtonProps={openButtonProps}
    >
      {textValue}
      {childrenCountString}
    </BaseTitle>
  );
};

export default BaseTitleRenderer;
