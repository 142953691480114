import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { equals } from 'ramda';

import { getDidOneClickPlanModeRun, enableOneClickPlanMode, disableOneClickPlanMode } from 'store/app';
import { runOneClickPlan, updateRoadmapVersionDialogProperties } from 'store/roadmapVersions';
import { isRunOneClickPlanLoading } from 'store/roadmapVersions/selectors';

import useFeatureFlags from './useFeatureFlags';
import useNavigationToPathWithGoBackUrl from 'hooks/useNavigationToPathWithGoBackUrl';
import useSinglePurposeSocket from './useSinglePurposeSocket';
import useScenariosNavigation from './useScenariosNavigation';

import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/userViews';

import { SCENARIO_AI_PLANNER_PAGE } from 'constants/filters';
import { FeatureFlags } from '@dragonboat/config';
import { DIALOG } from 'constants/queryParams';
import { RUN_AI_PLANNER_DIALOG } from 'constants/dialogs';
import { SolverType } from 'constants/aiPlanner';

const openRunDialogQueryParam = `${DIALOG}=${RUN_AI_PLANNER_DIALOG}`;

const getQueryParams = ({ openPopup }) => (openPopup ? openRunDialogQueryParam : '');

const useOneClickPlan = () => {
  const dispatch = useDispatch();
  const socket = useSinglePurposeSocket();
  const { navigateToScenarioModule } = useScenariosNavigation();

  const path = getCurrentPath();
  const pageId = getPageIdFromPath(path);

  const isOneClickPlanModeActive = equals(pageId, SCENARIO_AI_PLANNER_PAGE);
  const didOneClickPlanModeRun = useSelector(getDidOneClickPlanModeRun);
  const isOneClickPlanLoading = useSelector(isRunOneClickPlanLoading);
  const { navigateBack } = useNavigationToPathWithGoBackUrl();

  const useOptimizer = useFeatureFlags([FeatureFlags.USE_OPTIMIZER]);

  const saveOneClickPlanScenario = useCallback(
    (isToNavigateBack = true) => {
      dispatch(updateRoadmapVersionDialogProperties({ showSaveDialog: true }));

      if (isToNavigateBack) {
        navigateBack();
      }
    },
    [navigateBack],
  );

  const toggleOneClickPlanMode = useCallback(
    (openPopup = false) => {
      navigateToScenarioModule(SCENARIO_AI_PLANNER_PAGE, getQueryParams({ openPopup }));
      if (isOneClickPlanModeActive) {
        dispatch(disableOneClickPlanMode());
      } else {
        dispatch(enableOneClickPlanMode());
      }
    },
    [isOneClickPlanModeActive],
  );

  const runOneClickPlanClicked = useCallback(
    ({ target = 0, orderBy }) => {
      dispatch(runOneClickPlan(socket, useOptimizer ? SolverType.ADVANCED : SolverType.DETERMINISTIC, +target, orderBy));
    },
    [socket, useOptimizer],
  );

  const exitOneClickPlanMode = useCallback(() => {
    dispatch(disableOneClickPlanMode());

    navigateBack();
  }, [navigateBack]);

  return {
    isOneClickPlanModeActive,
    toggleOneClickPlanMode,
    runOneClickPlanClicked,
    exitOneClickPlanMode,
    saveOneClickPlanScenario,
    canSaveOneClickPlan: didOneClickPlanModeRun,
    isOneClickPlanLoading,
    useOptimizer,
  };
};

export default useOneClickPlan;
