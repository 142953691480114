import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';

const hasRoadmapQuickFilter = filters => !isEmpty(filters?.quickFilters?.roadmaps);

const _isNull = v => v === 'null' || v === null;
const _isEquals = (v, v2) => (_isNull(v) && _isNull(v2)) || v === v2;

const getFilterByRoadmap = filters => project => _isEquals(project?.roadmap_id, head(filters?.quickFilters?.roadmaps));

export { hasRoadmapQuickFilter, getFilterByRoadmap };
