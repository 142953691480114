import { defaultTo } from 'ramda';

import { REVALIDATE_USER_INTEGRATION_AUTH_PENDING, REVALIDATE_USER_INTEGRATION_AUTH_FULFILLED } from 'store/integrations/types';

import {
  SET_AUTH_TOKEN,
  CURRENT_USER,
  CHANGE_PASSWORD_FULFILLED,
  LOGIN_FAILED,
  GOOGLE_LOGIN_FAILED,
  RESET_PASSWORD_FAILED,
  SENT_RESET_PASSWORD_INSTRUCTIONS,
  CLEAR_RESET_PASSWORD,
  NEW_PASSWORD_SET,
  SET_NEW_PASSWORD_FAILED,
  GOOGLE_LOGIN_NO_ACCOUNT,
  GOOGLE_SIGNUP_APPROVED,
  GOOGLE_SIGNUP_DECLINED,
  GOOGLE_SIGNUP_APPROVAL_FAILED,
  ACCOUNT_SIGNUP_SUCCESS,
  ACCOUNT_SIGNUP_ERROR,
  SET_ORGANIZATION,
  SET_ALLOW_RECEIVE_NOTIFICATIONS_EMAILS_FULFILLED,
  FETCH_USER_INTEGRATIONS_FULFILLED,
  ADD_USER_INTEGRATION_FULFILLED,
  ADD_USER_INTEGRATION_REJECTED,
  REMOVE_USER_INTEGRATION_FULFILLED,
  UPDATE_CURRENT_USER_FULFILLED,
  REQUEST_ACCESS_FULFILLED,
  REQUEST_ACCESS_REJECTED,
  CLEAR_LOADED_USER_INTEGRATIONS_FOR_ORG_INTEGRATION,
} from './types';
import { REMOVE_ONE_STEP_ORG_INTEGRATION_FULFILLED } from 'features/OneStepIntegration/store';

export const initialState = {
  jwtToken: null,
  isAuthenticated: null,
  changePasswordSuccess: false,
  currentUser: {},
  errorMessage: null,
  googleErrorMessage: null,
  resetPasswordEmail: null,
  resetPasswordErrorMessage: null,
  newPasswordSuccess: null,
  newPasswordErrorMessage: null,
  requestAccessSuccess: null,
  requestAccessError: null,
  requestAccessUserCreated: null,
};

const defaultToEmptyArray = defaultTo([]);

export default function login(state = initialState, action) {
  switch (action.type) {
    case CLEAR_LOADED_USER_INTEGRATIONS_FOR_ORG_INTEGRATION:
      return {
        ...state,
        integrations: state.integrations.filter(i => i.org_integration_id !== action.meta.orgIntegrationId),
      };
    case SET_AUTH_TOKEN:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        currentUser: action.payload.loginUser,
        errorMessage: null,
        googleErrorMessage: null,
        organization: state.organization,
      };
    case LOGIN_FAILED:
      return {
        isAuthenticated: false,
        errorMessage: action.payload.errorMessage,
        organization: state.organization,
      };
    case GOOGLE_LOGIN_NO_ACCOUNT:
      return {
        isAuthenticated: false,
        googleToken: action.payload.googleToken,
        organization: state.organization,
      };
    case GOOGLE_LOGIN_FAILED:
      return {
        isAuthenticated: false,
        googleErrorMessage: action.payload.errorMessage,
        organization: state.organization,
      };
    case RESET_PASSWORD_FAILED:
      return {
        isAuthenticated: false,
        resetPasswordErrorMessage: action.payload.errorMessage,
        organization: state.organization,
      };
    case SENT_RESET_PASSWORD_INSTRUCTIONS:
      return {
        isAuthenticated: false,
        resetPasswordErrorMessage: null,
        resetPasswordEmail: action.payload.email,
      };
    case CLEAR_RESET_PASSWORD:
      return {
        ...state,
        resetPasswordErrorMessage: null,
        resetPasswordEmail: null,
      };
    case NEW_PASSWORD_SET:
      return {
        ...state,
        newPasswordSuccess: true,
        newPasswordErrorMessage: null,
      };
    case SET_NEW_PASSWORD_FAILED:
      return {
        ...state,
        newPasswordSuccess: false,
        newPasswordErrorMessage: action.payload.errorMessage,
      };
    case GOOGLE_SIGNUP_APPROVED:
      return {
        ...state,
        approvedUserName: `${action.payload.firstName} ${action.payload.lastName ? action.payload.lastName : ''}`,
        approvedUserEmail: action.payload.email,
        approvalError: false,
      };
    case GOOGLE_SIGNUP_DECLINED:
      return {
        ...state,
        approvedUserName: `${action.payload.firstName} ${action.payload.lastName ? action.payload.lastName : ''}`,
        approvedUserEmail: action.payload.email,
        approvalError: false,
      };
    case GOOGLE_SIGNUP_APPROVAL_FAILED:
      return {
        ...state,
        approvalError: true,
      };
    case ACCOUNT_SIGNUP_SUCCESS:
      return {
        ...state,
        signupSuccess: true,
        signupError: null,
        organization: action.payload.data,
      };
    case ACCOUNT_SIGNUP_ERROR:
      return {
        ...state,
        signupError: action.payload,
      };
    case CURRENT_USER:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated !== null ? action.payload.isAuthenticated : state.isAuthenticated,
        currentUser: action.payload.loginUser,
      };
    case UPDATE_CURRENT_USER_FULFILLED:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    case CHANGE_PASSWORD_FULFILLED:
      return Object.assign({}, state, { changePasswordSuccess: true });
    case SET_ORGANIZATION:
      return {
        ...state,
        organization: {
          ...action.payload.data,
        },
      };
    case SET_ALLOW_RECEIVE_NOTIFICATIONS_EMAILS_FULFILLED:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    case FETCH_USER_INTEGRATIONS_FULFILLED:
      return {
        ...state,
        integrations: action.payload,
      };
    case REVALIDATE_USER_INTEGRATION_AUTH_FULFILLED: {
      const { payload = {} } = action;

      return {
        ...state,
        integrations: defaultToEmptyArray(payload.userIntegrations),
      };
    }
    case ADD_USER_INTEGRATION_FULFILLED:
      return {
        ...state,
        integrations: [...(state.integrations || []), action.payload],
      };
    case ADD_USER_INTEGRATION_REJECTED:
      return {
        ...state,
        addUserIntegrationError: action.payload,
      };
    case REMOVE_ONE_STEP_ORG_INTEGRATION_FULFILLED:
    case REVALIDATE_USER_INTEGRATION_AUTH_PENDING:
    case REMOVE_USER_INTEGRATION_FULFILLED:
      return {
        ...state,
        integrations: state.integrations.filter(i => i.org_integration_id !== action.meta.orgIntegrationId),
      };
    case REQUEST_ACCESS_FULFILLED:
      const userHasBeenCreated = action.payload.status === 201;

      return {
        ...state,
        requestAccessSuccess: true,
        requestAccessUserCreated: userHasBeenCreated,
        requestAccessError: null,
      };
    case REQUEST_ACCESS_REJECTED:
      return {
        ...state,
        requestAccessSuccess: false,
        requestAccessUserCreated: false,
        requestAccessError: action.payload.response.data,
      };
    default:
      return state;
  }
}
