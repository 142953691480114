export const FETCH_FUNCTIONROLES = 'FETCH_FUNCTIONROLES';
export const FETCH_FUNCTIONROLES_FULFILLED = 'FETCH_FUNCTIONROLES_FULFILLED';
export const FETCH_FUNCTIONROLES_REJECTED = 'FETCH_FUNCTIONROLES_REJECTED';

export const FETCH_USER_FUNCTIONROLES = 'FETCH_USER_FUNCTIONROLES';
export const FETCH_USER_FUNCTIONROLES_FULFILLED = 'FETCH_USER_FUNCTIONROLES_FULFILLED';
export const FETCH_USER_FUNCTIONROLES_REJECTED = 'FETCH_USER_FUNCTIONROLES_REJECTED';

export const UPDATE_USER_FUNCTIONROLES = 'UPDATE_USER_FUNCTIONROLES';
export const UPDATE_USER_FUNCTIONROLES_FULFILLED = 'UPDATE_USER_FUNCTIONROLES_FULFILLED';
export const UPDATE_USER_FUNCTIONROLES_REJECTED = 'UPDATE_USER_FUNCTIONROLES_REJECTED';
