import React from 'react';
import { useSelector } from 'react-redux';

import PageHeaderFilters from 'containers/PageHeaderFilters';

import { selectTotalProjects, selectTotalProjectsByLayer } from 'features/whiteboard/store/selectors';

const WhiteboardPageHeaderFilters = props => {
  const totalProjects = useSelector(selectTotalProjects);
  const totalByLayer = useSelector(selectTotalProjectsByLayer);

  return <PageHeaderFilters {...props} customNumberOfResults={totalProjects} customTotalByLayer={totalByLayer} />;
};

export default WhiteboardPageHeaderFilters;
