import { useCallback } from 'react';
import { not } from 'ramda';

import { getHasSort } from 'utils/grids/helpers';

/**
 * @function useInitGridWithColumnsState
 *
 * Hook to init grid with event handlers for column state
 * - apply by default if any exists
 * - apply colum state on columns changed
 *
 *
 * @param  {Array} columnsState
 * @param  {Function} getGridIsReady
 * @param  {Function} saveColumnsState
 * @return {Object}
 */
const useInitGridWithColumnsState = ({ columnsState, getGridIsReady, saveColumnsState }) => {
  const handleGridReady = useCallback(
    params => {
      const columnState = params.columnApi.getColumnState();

      /*
       * Should Apply initial columnsState on grid ready if column state does not exists
       */
      if (!columnsState) {
        saveColumnsState(columnState);
      }

      if (getHasSort(columnState)) {
        // if view has sort, disable row drag
        params.api.setSuppressRowDrag(true);
      }
    },
    [columnsState, saveColumnsState],
  );

  const handleDisplayedColumnsChanged = useCallback(
    ({ columnApi }) => {
      /*
       * Ingore change if the grid is not ready
       */
      const gridIsNotReady = not(getGridIsReady());

      if (gridIsNotReady) {
        return;
      }

      const newColumnsState = columnApi.getColumnState();

      return saveColumnsState(newColumnsState);
    },
    [saveColumnsState, getGridIsReady],
  );

  const handleColumnResized = useCallback(
    ({ finished, ...props }) => {
      if (finished) handleDisplayedColumnsChanged(props);
    },
    [handleDisplayedColumnsChanged],
  );

  return {
    handleGridReady,
    handleDisplayedColumnsChanged,
    handleColumnResized,
  };
};

export default useInitGridWithColumnsState;
