import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import theme from 'design-system/theme';
import SubNavigationTabs from 'design-system/organisms/SubNavigationTabs';

import FilterButtonComponent from 'containers/PageHeaderFilters/components/FilterButtonComponent';
import TotalCountComponent from 'containers/PageHeaderFilters/components/TotalCountComponent';
import AdvancedSearchPopover from 'containers/AdvancedSearchPopover';

import ModulesMenu from 'components/ModulesMenu';

import { isLoadingProjects } from 'store/projects';
import { setAppGlobalConfig } from 'store/app';
import history from 'store/utils/history';
import { getUserById } from 'store/users/selectors';

import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useScenarioFilter from './hooks/useScenarioFilters';
import useApplicationRoutes from 'hooks/useApplicationRoutes';
import useLoadScenario from './hooks/useLoadScenario';

import getFiltersCounter from 'utils/filters/getFiltersCounter';
import { getPathWithoutLeadingSlash } from 'utils/index';

import { SCENARIO_FILTER } from 'constants/filters/pages';
import { FILTERS_FIELDS } from 'constants/filters/fields';

const SCENARIO_VIEWS = [
  {
    path: 'scenario/list',
    label: 'List',
  },
  {
    path: 'scenario/swimlane',
    label: 'Swimlane',
  },
  {
    path: 'scenario/gantt',
    label: 'Gantt',
  },
  {
    path: 'scenario/summary',
    label: '3D Dynamic',
  },
  {
    path: 'scenario/chart',
    label: 'Chart',
  },
];

const ICON_MODULE_PURPLE = theme.palette.text.purple;
const ICON_MODULE_BLUE = theme.palette.newLayout.background.lightPrimary;
const ICON_MODULE_BACKGROUND = `linear-gradient(to bottom right, ${ICON_MODULE_PURPLE}, ${ICON_MODULE_BLUE});`;

const ScenarioHeader = () => {
  useLoadScenario();

  const dispatch = useDispatch();
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const [pages] = useApplicationRoutes();
  const { pageFilters, fields, total, shouldDisplayTotalCount, totalCountForDisplayLayer } = useScenarioFilter();
  const { isRoadmapVersionsLoading, selectedRoadmapVersion, isRoadmapVersionCommittedWithGanttFlow } = useRoadmapVersions();

  const selectedRoadmapVersionOwner = useSelector(state => getUserById(state, selectedRoadmapVersion?.created_by_id));
  const isLoadingProjectsStore = useSelector(isLoadingProjects);

  const selectedVersionForHeader = useMemo(() => {
    if (!selectedRoadmapVersion) {
      return null;
    }

    return {
      ...selectedRoadmapVersion,
      createdByName: selectedRoadmapVersionOwner?.name,
    };
  }, [selectedRoadmapVersion, selectedRoadmapVersionOwner]);

  const isLoading = isRoadmapVersionsLoading || isLoadingProjectsStore;

  const subNavigationTabs = useMemo(() => SCENARIO_VIEWS.filter(o => !!pages.find(p => p.path === o.path)), [pages]);

  const path = window.location.pathname;
  const lastView = useSelector(state => state.app?.lastPagesView?.scenario);
  const myLastView = path === '/scenario' && lastView ? `/scenario/${lastView}` : path;
  const selectedSubNavigationTab = useMemo(() => getPathWithoutLeadingSlash(myLastView), [myLastView]);

  const setSelectedPage = useCallback(path => {
    const params = new URLSearchParams(window.location.search);

    history.push(`/${path}?${params.toString()}`);
    const pathParts = path.split('/');

    const selectedPage = pathParts.lenght > 1 ? pathParts[1] : '';

    dispatch(setAppGlobalConfig({ lastPagesView: { scenario: selectedPage } }));
  }, []);

  const _renderFilterButton = () => (
    <SpaceContainer>
      <FilterButtonComponent
        badgeContent={getFiltersCounter(pageFilters, fields)}
        onClick={event => {
          if (filterAnchorEl === null) setFilterAnchorEl(event.currentTarget);
        }}
      />
      <TotalCountComponent
        total={total}
        hasLoaded={!isLoading}
        shouldDisplayTotalCount={shouldDisplayTotalCount}
        totalCountForDisplayLayer={totalCountForDisplayLayer}
      />
    </SpaceContainer>
  );

  const _renderAdvancedSearchPopover = () => (
    <AdvancedSearchPopover
      anchorEl={filterAnchorEl}
      setAnchorEl={el => setFilterAnchorEl(el)}
      page={SCENARIO_FILTER}
      availableFields={FILTERS_FIELDS}
      includeCustomFields
    />
  );

  const _renderScenarioTitle = () => (
    <SelectedVersion>
      <SelectedVersionName>{selectedVersionForHeader.name}</SelectedVersionName>
      <SelectedVersionCreatedBy>(created by {selectedVersionForHeader.createdByName})</SelectedVersionCreatedBy>
    </SelectedVersion>
  );

  const _renderSubNavigation = () => (
    <>
      {subNavigationTabs && (
        <StyledSubNavigationTabs
          items={subNavigationTabs}
          selected={selectedSubNavigationTab}
          handleNavigationChange={setSelectedPage}
        />
      )}
    </>
  );

  return (
    <Wrapper>
      <ModulesMenu pageIconBackground={ICON_MODULE_BACKGROUND} moduleTitle="Scenario" />
      <FlexColumnContainer>
        <Header>
          {_renderFilterButton()}
          {_renderAdvancedSearchPopover()}
          {selectedRoadmapVersion && isRoadmapVersionCommittedWithGanttFlow && _renderScenarioTitle()}
        </Header>
        {_renderSubNavigation()}
      </FlexColumnContainer>
    </Wrapper>
  );
};

export default ScenarioHeader;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 20px;
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
  height: 100%;
`;

const Header = styled.div`
  &&&& {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: ${({ theme }) => theme.typography.fontSize}px;
  }
`;

const StyledSubNavigationTabs = styled(SubNavigationTabs)`
  &&&& {
    min-height: unset;
  }
`;

const SpaceContainer = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: 0px !important;
    left: -12px;
  }
`;

const SelectedVersion = styled.div`
  display: flex;
`;

const SelectedVersionName = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizeSmallLarge}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SelectedVersionCreatedBy = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSizeSmall}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  color: ${({ theme }) => theme.palette.newLayout.text.grey};
  padding: 0;
  margin: 0 8px;
  align-self: center;
  white-space: nowrap;
`;
