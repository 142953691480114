import styled from 'styled-components';
import axios from 'axios';
import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import { linkColor } from 'design-system/themes/default';

export default header => {
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [message, setMessage] = useState('');

  const sendMessage = async () => {
    await axios.post('/api/billing/message', {
      message,
      header,
    });

    setMessageSuccess(true);
    setMessage('');
    setTimeout(() => {
      setMessageOpen(false);
      setMessageSuccess(false);
    }, 3000);
  };

  const toggleMessage = () => {
    setMessageOpen(!messageOpen);
  };

  const Component = (
    <MessageWrapper closed={!messageOpen && !messageSuccess}>
      {messageOpen && (
        <React.Fragment>
          <TextField
            multiline
            rows={5}
            placeholder="Message"
            onChange={e => setMessage(e.target.value)}
            defaultValue={message}
            style={{ width: '100%', marginBottom: '20px' }}
          />
          <SendButton disabled={messageSuccess} onClick={sendMessage}>
            Send
          </SendButton>
        </React.Fragment>
      )}
      {messageSuccess && (
        <TextDeprecated style={{ width: '100%', color: linkColor, textAlign: 'center' }}>
          Message sent with success
        </TextDeprecated>
      )}
    </MessageWrapper>
  );

  return [Component, toggleMessage];
};

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: max-height 0.1s ease-out;
  padding: 20px 20px 0 20px;
  max-height: ${props => (props.closed ? 0 : '400px')};
  // display: ${props => (props.closed ? 'none' : 'auto')};
  overflow: hidden;
`;

const SendButton = styled(Button)`
  &&&& {
    width: 70px;
    margin-top: 10px;
    color: ${linkColor};
  }
`;
