import parseArrayIntoObject from 'components/DataGrid/processDataFromClipboard/parseArrayIntoObject';
import mapEditableColumns from 'components/DataGrid/processDataFromClipboard/mapEditableColumns';
import parseClipboardDataToArray from 'design-system/utils/parseClipboardDataToArray';

const processRowDataFromClipboard = async ({ gridColumnApi }, columnDefs) => {
  const clipboardData = await navigator.clipboard.readText();

  if (!clipboardData) {
    return;
  }

  const clipboardDataArrays = parseClipboardDataToArray(clipboardData);

  const columnsIds = gridColumnApi
    .getColumnState()
    .filter(column => !column.hide)
    .map(column => column.colId);

  const columns = mapEditableColumns(columnsIds, columnDefs);

  return clipboardDataArrays.map(row => {
    return parseArrayIntoObject(row, columns, 'title');
  });
};

export default processRowDataFromClipboard;
