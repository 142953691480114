import React from 'react';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/DeleteOutline';

import Autocomplete from 'design-system/atoms/Autocomplete/index';
import Checkbox from 'design-system/atoms/Checkbox/index';


import { DISABLE_DEFAULT_FIELDS } from '../../utils';
import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';

const FieldRow = props => {
  const {
    fieldData,
    suggestions,
    integrationSuggestions,
    onChangeDragonboatFieldHandler,
    onChangeIntegrationFieldHandler,
    onChangeUpdateFromIntegrationToDragonboatHandler,
    onChangeUpdateFromDragonboatToIntegrationHandler,
    onDeleteHandler,
  } = props;

  const isFieldRowReadOnly = DISABLE_DEFAULT_FIELDS.includes(fieldData.key);
  const hasDragonboatFieldSelected = !!fieldData.key;
  const hasIntegrationFieldSelected = !!fieldData.integration?.key;
  const isIntegrationFieldReadOnly = !fieldData.integration?.syncable;

  return (
    <Grid container spacing={8} key={fieldData.key} style={{ alignItems: 'center' }}>
      <Grid item xs={3} style={{ textAlign: 'right', paddingRight: 20 }}>
        <div style={{ float: 'right', width: '90%' }}>
          <Autocomplete
            placeholder={TYPE_TO_SEARCH_PLACEHOLDER}
            suggestions={suggestions}
            value={fieldData.title}
            disabled={isFieldRowReadOnly}
            onValueChange={onChangeDragonboatFieldHandler}
          />
        </div>
      </Grid>
      <Grid item xs={3}>
        <Autocomplete
          placeholder={TYPE_TO_SEARCH_PLACEHOLDER}
          suggestions={integrationSuggestions}
          value={fieldData?.integration?.title}
          onValueChange={onChangeIntegrationFieldHandler}
          disabled={isFieldRowReadOnly || !hasDragonboatFieldSelected}
        />
      </Grid>
      <GridCheckbox item xs={2}>
        <SyncCheckbox
          color="primary"
          checked={(hasIntegrationFieldSelected && fieldData.updateFromIntegrationToDragonboat) || false}
          disabled={isFieldRowReadOnly || !hasIntegrationFieldSelected}
          onChange={onChangeUpdateFromIntegrationToDragonboatHandler}
        />
      </GridCheckbox>
      <GridCheckbox item xs={2}>
        <SyncCheckbox
          color="primary"
          checked={(hasIntegrationFieldSelected && fieldData.updateFromDragonboatToIntegration) || false}
          disabled={isFieldRowReadOnly || !hasIntegrationFieldSelected || isIntegrationFieldReadOnly}
          onChange={onChangeUpdateFromDragonboatToIntegrationHandler}
        />
      </GridCheckbox>
      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <DeleteButton title="Delete" disabled={isFieldRowReadOnly}>
          <DeleteIcon onClick={onDeleteHandler} />
        </DeleteButton>
      </Grid>
    </Grid>
  );
};

const GridCheckbox = styled(Grid)`
  &&&& {
    display: flex;
    justify-content: center;
  }
`;

const SyncCheckbox = styled(Checkbox)`
  &&&& {
    padding: 0;

    ${({ disabled }) =>
      disabled &&
      `
      span {
        color: rgba(0, 0, 0, 0.15)!important;
      }
    `}
  }
`;

const DeleteButton = styled(IconButton)`
  &&&& {
    padding: 4px;
  }
`;

export default FieldRow;
