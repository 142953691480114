import React from 'react';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import PublicPageTemplateComponent from 'src/design-system/templates/PublicPageTemplate/PublicPageTemplateComponent';

export default ({ email }) => {
  return (
    <PublicPageTemplateComponent>
      <Wrapper>
        <Grid container direction="column" alignItems="center" spacing={0}>
          <Grid item xs={12}>
            <StyledTitle>Workspaces sent!</StyledTitle>
          </Grid>
          <Grid item xs={12}>
            <StyledSubTitle>A list of workspaces have been sent to {email}.</StyledSubTitle>
          </Grid>
          <Grid item xs={12}>
            <StyledSubTitle>
              You&apos;ll receive this email within 5 minutes. Be sure to check your spam folder too.
            </StyledSubTitle>
          </Grid>
        </Grid>
      </Wrapper>
    </PublicPageTemplateComponent>
  );
};

const Wrapper = styled.div`
  max-width: 360px;
  @media screen and (min-height: 700px) {
    margin-top: -5rem;
  }
`;

const StyledTitle = styled.h1`
  color: #3461ac;
  font-size: 2.25rem; // -> 36px -> with :root font-size of 16px;
  font-weight: bold;
  margin: 0 0 1.813rem 0;
`;

const StyledSubTitle = styled.h5`
  color: #131c23;
  font-size: 1rem;
  font-weight: 400;
  margin: 0 0 1.813rem 0;
  text-align: center;
`;
