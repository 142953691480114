export default obj => {
  const ids = [];

  const traverse = obj => {
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'boolean' && obj[key]) {
        const formattedKey = key === 'null' ? null : key;

        ids.push(formattedKey);
      } else if (typeof obj[key] === 'object') {
        traverse(obj[key]);
      }
    });
  };

  traverse(obj);

  return ids;
};
