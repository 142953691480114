import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { withTheme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';

// TODO: replace AccordionGroupDeprecated component for this one when we can
const AccordionGroup = ({
  title,
  color,
  children,
  showGroupColor,
  isExpandedDefault,
  hideAddButton,
  onAddNewProject,
  onToggle,
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedDefault);

  const handleChange = (_, expanded) => {
    setIsExpanded(!isExpanded);
    onToggle && onToggle(expanded);
  };

  const handleAddNew = e => {
    e.stopPropagation();

    onAddNewProject();
  };

  useEffect(() => {
    setIsExpanded(isExpandedDefault);
  }, [isExpandedDefault]);

  return (
    <Wrapper color={color} onChange={handleChange} expanded={isExpanded}>
      <GroupHeader
        showGroupColor={showGroupColor}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
        groupColor={color}
        {...(color ? { component: Paper, elevation: 1 } : {})}
      >
        <StyledText>{title}</StyledText>
        {!hideAddButton && (
          <AddButton onClick={handleAddNew}>
            <AddIcon />
          </AddButton>
        )}
      </GroupHeader>
      {isExpanded && <GroupDetails>{children}</GroupDetails>}
    </Wrapper>
  );
};

const Wrapper = styled(ExpansionPanel)`
  &&&& {
    margin-bottom: ${spacing(2)}px;
    box-shadow: none;

    :before {
      opacity: 0;
    }
  }
`;

const AddButton = withTheme()(styled(IconButton)`
  &&&& {
    padding: ${spacing(0.5)}px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: none;
  }
`);

const GroupHeader = withTheme()(styled(ExpansionPanelSummary)`
  &&&& {
    position: relative;
    display: flex;
    margin-bottom: ${spacing(0.25)}px;
    min-height: 35px;
    align-items: center;
    cursor: pointer;

    border: 1px solid ${({ theme }) => theme.palette.border.secondary};
    border-top: 10px solid ${props => (props.showGroupColor ? props.groupColor : props.theme.palette.border.secondary)};
    border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;
    z-index: 100;

    & > div {
      align-items: center;
      margin: 0;
    }

    [role='button'] {
      left: 10px;
      width: 0;
      color: ${props => props.theme.palette.text.primary};
    }

    &:hover {
      ${AddButton} {
        display: block;
      }
    }
  }
`);

const GroupDetails = styled(ExpansionPanelDetails)`
  &&&& {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
`;

const StyledText = styled(Text)`
  margin: ${spacing()}px 0 ${spacing()}px ${spacing(2.5)}px;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export default withTheme()(AccordionGroup);
