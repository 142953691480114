import { useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { updateSelectedItems as updateSelectedItemsHelper } from 'design-system/molecules/AgGridReact-New/helpers';
import useInitAgGrid from 'design-system/molecules/AgGridReact-New/hooks/useInitAgGrid';
import { enableSelectionMode, disableSelectionMode } from 'store/grids/actions';
import { getSelectedItems, getSelectionMode } from 'store/grids/selectors';

const useSettingsGridSelection = (settingType, options = {}) => {
  const { extraMethods = {}, actions = {} } = options;
  const { remove } = actions;

  const methodsToDecorate = useMemo(
    () => ({
      updateSelectedItems: updateSelectedItemsHelper,
      ...extraMethods,
    }),
    [extraMethods],
  );

  const {
    isGridReady,
    getGridApi,
    initAgGrid,
    methods: { updateSelectedItems, ...methods },
  } = useInitAgGrid(methodsToDecorate);

  const dispatch = useDispatch();

  // Selection
  const selectionMode = useSelector(state => getSelectionMode(state, settingType));
  const selectedItems = useSelector(state => getSelectedItems(state, settingType));

  const selectionActions = useMemo(
    () =>
      bindActionCreators(
        {
          enableSelectionMode,
          disableSelectionMode,
        },
        dispatch,
      ),
    [dispatch],
  );

  const updateSelectedOnAgGridInit = useCallback(
    ({ api, columnApi }) => {
      initAgGrid(api, columnApi);
      updateSelectedItems(selectedItems);
    },
    [selectedItems],
  );

  const replaceCellContent = useCallback((rowId, field, data) => {
    if (isGridReady()) {
      // eslint-disable-next-line no-unused-expressions
      getGridApi().getRowNode(rowId)?.setDataValue(field, data);
    }
  }, []);

  useEffect(() => {
    if (isGridReady()) {
      updateSelectedItems(selectedItems);
    }
  }, [selectionMode, selectedItems]);

  return {
    selectionMode,
    selectedItems,
    enableSelectionMode: () => {
      if (remove) {
        remove();
      }

      selectionActions.enableSelectionMode(settingType);
    },
    disableSelectionMode: () => selectionActions.disableSelectionMode(settingType),
    updateSelectedOnAgGridInit,

    replaceCellContent,
    methods,
  };
};

export default useSettingsGridSelection;
