import { isNil, propOr, pluck } from 'ramda';

import { getBottomTasks } from 'utils';
import { convertPlannedAllocationToDuration, DURATION_DAY } from 'utils/allocation/unitConversion';
import calcProjectDuration from 'utils/allocation/calcProjectDuration';
import { DEFAULT_PIE_CHART_DATALABEL_OPTIONS } from 'constants/charts';
import { ENTITY_TO_COLLECTION, PRODUCT, KEY_RESULT, METRIC, METRICS } from 'constants/common';

import { sortChartDataDescending } from '../Dashboards/helpers';
import calcDurationBasedOnTimeline from 'utils/allocation/calcDurationBasedOnTimeline';

const DEFAULT_LABEL_MAX_WIDTH = 65;
const UNDEFINED = 'undefined';

const UNDEFINED_ENTITY = {
  id: null,
  color: '#eee',
  title: 'Undefined',
};

const extractColors = pluck('color');
const extractLabels = pluck('label');
const extractIds = pluck('id');
const extractValues = pluck('value');

/**
 *
 * @param {*} projects
 * @param {*} timeframes
 */
const filterProjectsByTimeframes = (projects, timeframes) =>
  projects.filter(project => {
    const hasMatchingTimeframe = timeframes.includes(project.timeframe?.id.toString());
    const hasMatchingL2Timeframe = timeframes.includes(project.timeframe2?.id.toString());
    const hasMatchingCorpTimeframe = timeframes.includes(project.timeframeCorp?.id.toString());

    const hasUndefinedTimeframe =
      timeframes.includes(UNDEFINED) && isNil(project.timeframe) && isNil(project.timeframe2) && isNil(project.timeframeCorp);

    return hasMatchingTimeframe || hasMatchingL2Timeframe || hasMatchingCorpTimeframe || hasUndefinedTimeframe;
  });

/**
 *
 * @param {*} props
 */
export const generatePieChartData = props => {
  const { projects, lsState, hasDatalabels, useTimeframes, labelMaxWidth = DEFAULT_LABEL_MAX_WIDTH } = props;

  const { dataType, sumBy, duration, selectedTimeframes } = lsState;

  const validProjects = useTimeframes ? filterProjectsByTimeframes(projects, selectedTimeframes) : projects;

  const typeKey = dataType ? dataType.key : 'roadmap';

  const normalizeTypeKey = typeKey => {
    switch (typeKey) {
      case 'product1':
        return PRODUCT;
      case 'keyResult1':
        return KEY_RESULT;
      case METRICS:
        return METRIC;
      default:
        return typeKey;
    }
  };

  const normalizedTypeKey = normalizeTypeKey(typeKey);
  const collectionName = ENTITY_TO_COLLECTION[normalizedTypeKey];
  const entities = props[collectionName] || [];

  const _sumEntityValue = (sum, p) => {
    let value;

    if (sumBy && sumBy.key === 'duration') {
      value = duration.key === DURATION_DAY.key ? p.planned_scope_in_days : p.planned_scope_in_weeks;
    } else {
      value = 1;
    }

    sum += value || 0;
    return sum;
  };
  const _filterOutEntitiesWithZeroValue = e => e.value && e.value > 0;

  const _defaultParser = e => {
    const projects = validProjects.filter(p => p[typeKey] && p[typeKey].id === e.id);
    const value = projects.reduce(_sumEntityValue, 0);

    return {
      value,
      label: e.title,
      id: e.id,
      color: e.color,
    };
  };
  const _metricParser = e => {
    const projects = validProjects.filter(p => Array.isArray(p[typeKey]) && p[typeKey].some(i => i.id === e.id));
    const undefinedProjects = validProjects.filter(p => Array.isArray(p[typeKey]) && p[typeKey].length === 0);
    const projectsToUse = e.id === UNDEFINED_ENTITY.id ? undefinedProjects : projects;
    const label = e.id === UNDEFINED_ENTITY.id ? UNDEFINED_ENTITY.title : e.name;

    const value = projectsToUse.reduce(_sumEntityValue, 0);

    return {
      value,
      label,
      id: e.id,
      color: e.color,
    };
  };

  const parserByType = {
    [METRICS]: _metricParser,
  };
  const entitiesParser = propOr(_defaultParser, typeKey)(parserByType);
  const formattedEntities = [...entities, UNDEFINED_ENTITY].map(entitiesParser).filter(_filterOutEntitiesWithZeroValue);

  const sortedFormattedEntities = sortChartDataDescending(formattedEntities);
  const colors = extractColors(formattedEntities);

  return {
    labels: extractLabels(sortedFormattedEntities),
    ids: extractIds(sortedFormattedEntities),
    datasets: [
      {
        data: extractValues(sortedFormattedEntities),
        backgroundColor: colors,
        hoverBackgroundColor: colors,
        labelMaxWidth,
        ...(hasDatalabels && { datalabels: { ...DEFAULT_PIE_CHART_DATALABEL_OPTIONS } }),
      },
    ],
  };
};

/**
 *
 * @param {*} props
 */
export const generateBarChartData = (props, type = 'team') => {
  const { projects, lsState, teams, skills } = props;
  const { dataType, sumBy, duration } = lsState;
  const typeKey = dataType ? dataType.key : 'roadmap';
  const sortFn = (a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1);
  const sortedEntities = [{ id: null, title: 'Undefined', backgroundColor: '#eee' }, ...props[`${typeKey}s`]].sort(sortFn);
  const teamsOrSkills = type === 'team' ? teams : skills;
  const _existTeamOrSkillOnProject = (p, teamOrSkillId) => {
    if (p.estimates && p.estimates.length) {
      return !!p.estimates.filter(e => e[`${type}_id`] === teamOrSkillId).length;
    } else if (p.tasks && p.tasks.length) {
      return !!getBottomTasks(p).filter(t => t.owner && t.owner[`${type}_id`] === teamOrSkillId).length;
    } else if (p.owner) {
      return p.owner[`${type}_id`] === teamOrSkillId;
    }

    return false;
  };

  const data = sortedEntities
    .reduce((datasets, entity) => {
      const entityProjects = projects.filter(p => p[`${typeKey}_id`] === entity.id);
      const [projectsWithTeamSkill, projectsWithoutTeamSkill] = entityProjects.reduce(
        (acc, p) => {
          if (p.estimates && p.estimates.length && p.estimates.filter(e => e[`${type}_id`] !== null).length) {
            acc[0].push(p);
          } else if (p.tasks && p.tasks.length) {
            const bottomTasks = getBottomTasks(p);
            const tasks = bottomTasks.filter(t => t.owner && t.owner[`${type}_id`] !== null);

            if (tasks.length) acc[0].push(p);
          } else if (p.owner && p.owner[`${type}_id`] !== null) {
            acc[0].push(p);
          } else {
            acc[1].push(p);
          }

          return acc;
        },
        [[], []],
      );

      const undefinedTotal = projectsWithoutTeamSkill.reduce((totalDuration, p) => {
        const value = sumBy && sumBy.key === 'duration' ? calcDurationBasedOnTimeline(p) : 1;

        totalDuration += convertPlannedAllocationToDuration(value, duration) || 0;

        return totalDuration;
      }, 0);

      const dataset = teamsOrSkills.reduce((acc, teamSkill) => {
        acc.push(
          projectsWithTeamSkill.reduce((sum, p) => {
            let value = sumBy && sumBy.key === 'duration' ? calcProjectDuration(p, teamSkill.id, type) : 0;

            if (sumBy && sumBy.key === 'count' && _existTeamOrSkillOnProject(p, teamSkill.id)) value = 1;

            sum += convertPlannedAllocationToDuration(value, duration) || 0;
            return sum;
          }, 0),
        );
        return acc;
      }, []);

      datasets.push({
        label: entity.title,
        backgroundColor: entity.color,
        data: [undefinedTotal, ...dataset],
      });
      return datasets;
    }, [])
    .sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1));

  const labels = ['Undefined', ...teamsOrSkills.map(({ title }) => title)];
  const ids = [null, ...teamsOrSkills.map(({ id }) => id)];

  const result = {
    labels,
    ids,
    datasets: data,
  };

  return result;
};

/**
 *
 */
export const getSummaryDataFromPieData = (pieData, duration, metadata, selectedGroupBy) => {
  const total = pieData.datasets[0].data.reduce((sum, d) => sum + d, 0);

  let tableData = pieData.labels.map(label => ({ label }));
  const entities = selectedGroupBy && metadata[`${selectedGroupBy.key}s`];

  for (let i = 0; i < tableData.length; i++) {
    const count = pieData.datasets[0].data[i];

    tableData[i].id = pieData.ids[i];
    tableData[i].count = count;
    tableData[i].color = pieData.datasets[0].backgroundColor[i];
    tableData[i].entity = entities && entities.find(e => e.id === pieData.ids[i]);
    tableData[i].targetAllocationPercentage = tableData[i].entity?.target_allocation_percentage || 0;
  }

  tableData = tableData
    .filter(t => t.count > 0)
    .sort((a, b) => {
      const { entity: entityA } = a || {};
      const { entity: entityB } = b || {};

      if (!entityB) {
        return -1;
      }

      if (entityA?.row_order) return entityA?.row_order > entityB?.row_order ? -1 : 1;

      return entityA?.title < entityB?.title ? -1 : 1;
    });

  return {
    total,
    tableData,
  };
};
