import React from 'react';
import PropTypes from 'prop-types';

import ObjectiveIcon from 'design-system/atoms/ObjectiveIcon/index';
import KeyResultIcon from 'design-system/atoms/KeyResultIcon/index';
import KeyResultLevel2Icon from 'design-system/atoms/KeyResultLevel2Icon/index';
import ObjectiveCorpIcon from 'design-system/atoms/ObjectiveCorpIcon/index';


import { materialColors } from 'design-system/themes/default';

// Temp protection
const ObjectiveType = ({ type = 'objective', active, style, className }) => {
  const mapTypeToComp = {
    objectiveCorp: ObjectiveCorpIcon,
    objective: ObjectiveIcon,
    keyResult: KeyResultIcon,
    keyResult2: KeyResultLevel2Icon,
  };

  const Icon = mapTypeToComp[type];

  // return (<img
  //   alt={type}
  //   height={16}
  //   src={mapTypeToComp[type][`${active}`] || objective}
  // />);

  return <Icon style={{ color: active ? materialColors.mediumPurple : materialColors.silver, ...style }} className={className} />;
};

ObjectiveType.propTypes = {
  type: PropTypes.oneOf(['objective', 'keyResult', 'keyResult2', 'objectiveCorp']).isRequired,
};

export default ObjectiveType;
