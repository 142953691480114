import axios from 'axios';

import moveRowToPosition from 'utils/moveRowToPosition';
import { removeQueryParam } from 'utils';

import {
  FETCH_CUSTOMER_REQUESTS,
  FETCH_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_REQUEST,
  START_EDITING_CUSTOMER_REQUEST,
  STOP_EDITING_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_REQUEST,
  START_CREATING_CUSTOMER_REQUEST,
  STOP_CREATING_CUSTOMER_REQUEST,
  START_EDITING_COMMENT,
  STOP_EDITING_COMMENT,
  ADD_CUSTOMER_TO_CUSTOMER_REQUEST,
  REMOVE_CUSTOMER_FROM_CUSTOMER_REQUEST,
  ADD_TAG_TO_CUSTOMER_REQUEST,
  REMOVE_TAG_FROM_CUSTOMER_REQUEST,
  CREATE_PROJECT_FROM_CUSTOMER_REQUEST,
  REMOVE_PROJECT_FROM_CUSTOMER_REQUEST,
  ADD_CUSTOMER_REQUESTS_FILTER,
  REMOVE_CUSTOMER_REQUESTS_FILTER,
  SET_CUSTOMER_REQUESTS_FILTER,
  SET_CUSTOMER_REQUESTS_SEARCH,
  IMPORT_CUSTOMER_REQUESTS,
  DELETE_CUSTOMER_REQUEST,
  SET_SELECTED_VIEW,
  SWITCH_CUSTOMER_REQUEST_ROW_ORDER,
  UPDATE_CUSTOMER_REQUEST_FULFILLED,
  SAVE_CUSTOMER_REQUESTS_MULTI_FILTERS,
  UPDATE_CUSTOMER_REQUESTS_MULTI_FILTERS,
  LOAD_CUSTOMER_REQUESTS_MULTI_FILTERS,
  DELETE_CUSTOMER_REQUESTS_MULTI_FILTERS,
  TOGGLE_CUSTOMER_REQUEST_CUSTOM_FIELDS_VIEW,
  TOGGLE_CUSTOMER_REQUEST_PORTAL_SETTINGS_VIEW,
  TOGGLE_CUSTOMER_REQUEST_FORWARD_EMAILS_VIEW,
  SET_CUSTOMER_REQUESTS_GRID_VISIBLE_FIELDS,
  SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_PIE_CHART_GROUP_BY,
  SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_GROUP_BY,
  SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_STACKED_BY,
  UPDATE_CUSTOMER_REQUEST_FORM_DATA,
  BULK_UPDATE_CUSTOMER_REQUESTS,
  TOGGLE_BULK_UPDATE_CUSTOMER_REQUESTS,
  BULK_DELETE_CUSTOMER_REQUESTS,
  WATCH_CUSTOMER_REQUESTS,
  UNWATCH_CUSTOMER_REQUESTS,
  NOTIFY_CUSTOMER_REQUEST_WATCHERS,
  ADD_USER_TO_CUSTOMER_REQUESTS_WATCHERS,
  SET_CUSTOMER_REQUEST_LIST_PAGE,
  SET_CUSTOMER_REQUEST_RESULT_COUNT,
  SET_CUSTOMER_REQUEST_PAGE_LIMIT,
  REALTIME_PARTIAL_BULK_UPDATE_CUSTOMER_REQUESTS,
  SET_MULTI_FILTERS_LOADED,
} from './types';
import { getCustomerRequests, getLoadedCustomerRequestsUserFilters } from './selectors';
import undoAction from '../utils/factory/undoAction';
import { REQUESTS_FILTERS } from 'src/constants/filters';
import { fetchVotesPerCustomerRequest } from 'store/votes/actions';
import bulkUpdateAction from 'store/utils/factory/bulkUpdateAction';
import bulkDeleteAction from 'store/utils/factory/bulkDeleteAction';
import transformObjectWithNestedKeys from 'utils/transformObjectWithNestedKeys';
import compileFilters from './helpers/compileFilters';
import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';
import getPage from './helpers/getPage';
import { updateRequestLifecyclesApiCall, updateRequestPersonasApiCall } from 'store/customerRequests/api';
import { fetchCustomerRequestByIdOrKey } from 'store/customerRequests/network';
import { addQueryParamToUrl, getQueryParamFromUrl } from 'utils/queryParamsUtils';
import { OPEN_CUSTOMER_REQUEST_QUERY_PARAM } from 'constants/customerRequests';

export const fetchCustomerRequests = (options = {}, withChildren = false) => {
  return (dispatch, getState) => {
    const { customerRequests: customerRequestsState } = getState();

    const { multiFilters, pageLimit: limit, paginationInformation, search } = customerRequestsState;
    const { current, next } = paginationInformation;

    const filters = compileFilters(search, multiFilters);

    dispatch(fetchVotesPerCustomerRequest());

    const page = getPage(options, next, current);

    const params = { limit, ...filters, page };

    const promise = axios.get('/api/customer-requests', { params }).then(({ data: responseData }) => {
      const { data, _meta: paginationData } = responseData;

      const multiFilterWithTotalLimit = multiFilters.find(f => !!f.limit);

      const { count, next, previous } = paginationData;

      let displayCount = count;

      if (multiFilterWithTotalLimit) {
        displayCount = Math.min(displayCount, multiFilterWithTotalLimit.limit);
      }

      dispatch(setCustomerRequestListPage({ next, previous, current: page }));

      if (Number.isInteger(displayCount)) {
        dispatch(setCustomerRequestResultCount(displayCount));
      }

      return data;
    });

    return dispatch({
      type: FETCH_CUSTOMER_REQUESTS,
      payload: promise,
    });
  };
};

export const fetchCustomerRequest = (id, key) => {
  return dispatch => {
    const promise = axios.get(`/api/customer-requests/${id || key}${!id && key ? '?by_key=true' : ''}`).then(({ data }) => data);

    return dispatch({
      type: FETCH_CUSTOMER_REQUEST,
      payload: promise,
    });
  };
};

const getCreateCustomRequestPromise = async data => {
  const { lifecycles, personas, ...requestFields } = data;

  const payload = (async () => {
    let createdRequestPersonas = [];
    let createdRequestLifecycles = [];
    const createdRequest = await axios.post('/api/customer-requests', requestFields).then(({ data }) => data);

    if (personas?.length && createdRequest?.id) {
      const updatedRequest = await updateRequestPersonasApiCall(createdRequest?.id, personas);

      createdRequestPersonas = updatedRequest?.personas || [];
    }

    if (lifecycles?.length && createdRequest?.id) {
      const updatedRequest = await updateRequestLifecyclesApiCall(createdRequest?.id, lifecycles);

      createdRequestLifecycles = updatedRequest?.lifecycles || [];
    }

    return { ...createdRequest, personas: createdRequestPersonas, lifecycles: createdRequestLifecycles };
  })();

  return payload;
};

export const createCustomerRequest = data => {
  return dispatch => {
    const promise = getCreateCustomRequestPromise(data);

    return dispatch({
      type: CREATE_CUSTOMER_REQUEST,
      payload: promise,
      meta: data,
    });
  };
};

export const deleteCustomerRequest = id => {
  return dispatch => {
    const promise = axios.delete(`/api/customer-requests/${id}`).then(({ data }) => data);

    return dispatch({
      type: DELETE_CUSTOMER_REQUEST,
      payload: promise,
      meta: { id },
    });
  };
};

export const bulkDeleteCustomerRequests = ids => {
  return bulkDeleteAction(BULK_DELETE_CUSTOMER_REQUESTS, '/api/customer-requests', null)(ids);
};

export const importCustomerRequests = data => {
  return dispatch => {
    const cleanData = data.map(obj => {
      return Object.entries(obj).reduce((cleanObj, [key, value]) => {
        if (typeof value !== 'undefined') cleanObj[key] = value;

        return cleanObj;
      }, {});
    });

    axios.post('/api/customer-requests/import', cleanData).then(({ data }) => data);

    return dispatch({
      type: IMPORT_CUSTOMER_REQUESTS,
      meta: data,
    });
  };
};

export const updateCustomerRequest = (id, data) => {
  return dispatch => {
    const promise = axios.patch(`/api/customer-requests/${id}`, data).then(({ data }) => data);

    return dispatch({
      type: UPDATE_CUSTOMER_REQUEST,
      payload: promise,
    });
  };
};

/**
 * Action creator to use when updating requests on grid.
 * The meta.batch true indicated that multiple updates on requests will be processed debounced to avoid having wrong
 * data on the grid after updates
 * @param id
 * @param data
 * @return {function(*): *}
 */
export const updateCustomerRequestOnGrid = (id, data) => {
  return dispatch => {
    const promise = axios.patch(`/api/customer-requests/${id}`, data).then(({ data }) => data);

    return dispatch({
      type: UPDATE_CUSTOMER_REQUEST,
      payload: promise,
      meta: {
        batch: true,
      },
    });
  };
};

export const bulkUpdateCustomerRequests = customerRequests => {
  return bulkUpdateAction(
    BULK_UPDATE_CUSTOMER_REQUESTS,
    '/api/customer-requests',
    null,
    transformObjectWithNestedKeys,
  )(customerRequests);
};

export const updateCustomerRequestFormData = data => {
  return dispatch => {
    return dispatch({
      type: UPDATE_CUSTOMER_REQUEST_FORM_DATA,
      payload: data,
    });
  };
};

export const addCustomerToCustomerRequest = (id, customerId) => {
  return dispatch => {
    const promise = axios.post(`/api/customer-requests/${id}/customers`, { customer_id: customerId }).then(({ data }) => data);

    return dispatch({
      type: ADD_CUSTOMER_TO_CUSTOMER_REQUEST,
      payload: promise,
      meta: {
        id,
        customerId,
      },
    });
  };
};

export const removeCustomerFromCustomerRequest = (id, customerId) => {
  return dispatch => {
    const promise = axios.delete(`/api/customer-requests/${id}/customers/${customerId}`).then(({ data }) => data);

    return dispatch({
      type: REMOVE_CUSTOMER_FROM_CUSTOMER_REQUEST,
      payload: promise,
      meta: {
        id,
        customerId,
      },
    });
  };
};

export const addTagToCustomerRequest = (id, tagId) => {
  return dispatch => {
    const promise = axios.post(`/api/customer-requests/${id}/tags`, { tag_id: tagId }).then(({ data }) => data);

    return dispatch({
      type: ADD_TAG_TO_CUSTOMER_REQUEST,
      payload: promise,
      meta: {
        id,
        tagId,
      },
    });
  };
};

export const removeTagFromCustomerRequest = (id, tagId) => {
  return dispatch => {
    const promise = axios.delete(`/api/customer-requests/${id}/tags/${tagId}`).then(({ data }) => data);

    return dispatch({
      type: REMOVE_TAG_FROM_CUSTOMER_REQUEST,
      payload: promise,
      meta: {
        id,
        tagId,
      },
    });
  };
};

export const createProjectFromCustomerRequest = (id, projectData) => {
  return dispatch => {
    const promise = axios.post(`/api/customer-requests/${id}/projects`, projectData).then(({ data }) => data);

    return dispatch({
      type: CREATE_PROJECT_FROM_CUSTOMER_REQUEST,
      payload: promise,
      meta: {
        id,
      },
    });
  };
};

export const addProjectToCustomerRequest = (id, projectId) => {
  return dispatch => {
    const promise = axios.post(`/api/customer-requests/${id}/projects`, { id: projectId }).then(({ data }) => data);

    return dispatch({
      type: CREATE_PROJECT_FROM_CUSTOMER_REQUEST,
      payload: promise,
      meta: {
        id,
      },
    });
  };
};

export const removeProjectFromCustomerRequest = (id, projectId) => {
  return dispatch => {
    const promise = axios.delete(`/api/customer-requests/${id}/projects/${projectId}`);

    return dispatch({
      type: REMOVE_PROJECT_FROM_CUSTOMER_REQUEST,
      payload: promise,
      meta: {
        id,
        projectId,
      },
    });
  };
};

export const loadAndOpenCustomerRequest = key => {
  return async dispatch => {
    const requestData = await fetchCustomerRequestByIdOrKey(null, key);

    if (requestData) {
      dispatch(startEditingCustomerRequest(requestData.id, requestData));
    }
  };
};

export const startEditingCustomerRequest = (id, loadedRequestData) => {
  return dispatch => {
    if (!id) {
      return {};
    }

    const { key } = loadedRequestData || {};

    const currentQueryKey = getQueryParamFromUrl(OPEN_CUSTOMER_REQUEST_QUERY_PARAM);

    if (String(key) !== currentQueryKey) {
      addQueryParamToUrl(OPEN_CUSTOMER_REQUEST_QUERY_PARAM, key);
    }

    return dispatch({
      type: START_EDITING_CUSTOMER_REQUEST,
      payload: {
        id,
        loadedRequestData,
      },
    });
  };
};

export const stopEditingCustomerRequest = () => {
  window.history.pushState('', '', removeQueryParam(window.location.href, OPEN_CUSTOMER_REQUEST_QUERY_PARAM));
  return {
    type: STOP_EDITING_CUSTOMER_REQUEST,
  };
};

export const startEditingComment = id => {
  return (dispatch, getState) => {
    if (!id) {
      return {};
    }

    return dispatch({
      type: START_EDITING_COMMENT,
      payload: {
        id,
      },
    });
  };
};

export const stopEditingComment = () => {
  window.history.pushState('', '', removeQueryParam(window.location.href, OPEN_CUSTOMER_REQUEST_QUERY_PARAM));
  return {
    type: STOP_EDITING_COMMENT,
  };
};

export const startCreatingCustomerRequest = () => ({
  type: START_CREATING_CUSTOMER_REQUEST,
});

export const stopCreatingCustomerRequest = () => ({
  type: STOP_CREATING_CUSTOMER_REQUEST,
});

export const setCustomerRequestsFilter = (field, values) => {
  return {
    type: SET_CUSTOMER_REQUESTS_FILTER,
    payload: {
      field,
      values,
    },
  };
};

export const addCustomerRequestsFilter = (field, value) => {
  return {
    type: ADD_CUSTOMER_REQUESTS_FILTER,
    payload: {
      field,
      value,
    },
  };
};

export const setMultiFiltersLoaded = value => {
  return {
    type: SET_MULTI_FILTERS_LOADED,
    payload: value,
  };
};

export const loadCustomerRequestsMultiFilters = () => (dispatch, getState) => {
  const loadedCustomerRequestsUserFilters = getLoadedCustomerRequestsUserFilters(getState());

  return dispatch({
    type: LOAD_CUSTOMER_REQUESTS_MULTI_FILTERS,
    payload: loadedCustomerRequestsUserFilters,
  });
};

export const saveCustomerRequestsMultiFilters = (name, multiFilters, defaultFilter = false) => {
  return (dispatch, getState) => {
    const currentUser = getState().login.currentUser || {};

    const data = {
      name,
      page: REQUESTS_FILTERS,
      state: multiFilters,
      user_id: currentUser.id,
      default_filter: defaultFilter,
    };

    const payload = axios
      .post('/api/userFilters/', data)
      .then(response => response)
      .catch(err => {
        console.error(err);
      });

    dispatch({
      payload,
      type: SAVE_CUSTOMER_REQUESTS_MULTI_FILTERS,
    });

    return payload.data;
  };
};

export const updateCustomerRequestsMultiFilters = (id, multiFilters, name) => {
  return dispatch => {
    const data = {
      state: multiFilters,
    };

    if (name) {
      data.name = name;
    }

    const payload = axios.put(`/api/userFilters/${id}`, data);

    return dispatch({
      payload,
      type: UPDATE_CUSTOMER_REQUESTS_MULTI_FILTERS,
    });
  };
};

export const deleteCustomerRequestsMultiFilters = id => {
  return {
    payload: axios.delete(`/api/userFilters/${id}`),
    type: DELETE_CUSTOMER_REQUESTS_MULTI_FILTERS,
  };
};

export const removeCustomerRequestsFilter = (field, value) => {
  return {
    type: REMOVE_CUSTOMER_REQUESTS_FILTER,
    payload: {
      field,
      value,
    },
  };
};

export const setSearch = value => {
  return {
    type: SET_CUSTOMER_REQUESTS_SEARCH,
    payload: {
      value,
    },
  };
};

export const setSelectedView = view => {
  return {
    type: SET_SELECTED_VIEW,
    view,
  };
};

export const switchCustomerRequestRowOrder = (id1, id2, position) => {
  return (dispatch, getState) => {
    if (!id1 || !id2) return;

    const state = getCustomerRequests(getState());
    const prevData = state.find(({ id }) => id === +id1);
    const movedRow = moveRowToPosition(state, id1, id2, position);

    const promise = axios.put(`/api/customer-requests/rowOrder/${id1}/${id2}`, { position }).then(({ data }) => data);

    return dispatch({
      type: SWITCH_CUSTOMER_REQUEST_ROW_ORDER,
      payload: {
        promise,
        data: movedRow,
      },
      meta: { prev: prevData },
    });
  };
};

export const addFileToCustomerRequest = (customerRequest, file) => async dispatch => {
  customerRequest.files = [file];

  return dispatch({
    type: UPDATE_CUSTOMER_REQUEST_FULFILLED,
    payload: {
      ...customerRequest,
    },
  });
};

export const removeFileFromCustomerRequest = (file, customerRequest) => async dispatch => {
  if (!file.id || !customerRequest.id) return;

  const fileDeleted = await axios
    .delete(`/api/customer-requests/${customerRequest.id}/files/${file.id}`)
    .then(res => {
      const { data } = res;

      dispatch(
        undoAction(
          `Image ${file.name} was deleted from ${customerRequest.title}`,
          false,
          'projects',
          `/api/customer-requests/${customerRequest.id}/files/${file.id}/versions/last`,
          restoredFile => {
            customerRequest.files = [restoredFile];

            return dispatch({
              type: UPDATE_CUSTOMER_REQUEST_FULFILLED,
              payload: {
                ...customerRequest,
              },
            });
          },
        ),
      );

      return data;
    })
    .catch(err => {
      console.error(err.name, err.message);
    });

  customerRequest.files = customerRequest.files.filter(f => +f.id !== fileDeleted);

  return dispatch({
    type: UPDATE_CUSTOMER_REQUEST_FULFILLED,
    payload: {
      ...customerRequest,
    },
  });
};

export const toggleCustomFieldsForCustomerRequest = () => ({ type: TOGGLE_CUSTOMER_REQUEST_CUSTOM_FIELDS_VIEW });
export const togglePortalSettingsForCustomerRequest = () => ({ type: TOGGLE_CUSTOMER_REQUEST_PORTAL_SETTINGS_VIEW });

export const toggleForwardEmailsForCustomerRequest = () => ({ type: TOGGLE_CUSTOMER_REQUEST_FORWARD_EMAILS_VIEW });

export const setCustomerRequestsGridVisibleFields = visibleFields => {
  return {
    type: SET_CUSTOMER_REQUESTS_GRID_VISIBLE_FIELDS,
    visibleFields,
    meta: { makesActiveViewDirty: true },
  };
};

export const toggleBulkUpdateCustomerRequests = () => ({ type: TOGGLE_BULK_UPDATE_CUSTOMER_REQUESTS });

export const watchCustomerRequest = customerRequest => {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.login.currentUser.id;

    if (customerRequest) {
      return dispatch({
        type: WATCH_CUSTOMER_REQUESTS,
        payload: axios.post(`/api/customer-requests/${customerRequest.id}/watch`).then(res => res.data),
        meta: {
          id: customerRequest.id,
          userId,
        },
      });
    }
  };
};

export const unwatchCustomerRequest = customerRequest => {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.login.currentUser.id;

    return dispatch({
      type: UNWATCH_CUSTOMER_REQUESTS,
      payload: axios.delete(`/api/customer-requests/${customerRequest.id}/watch`).then(res => res.data),
      meta: {
        id: customerRequest.id,
        userId,
      },
    });
  };
};

export const notifyCustomerRequestWatchers = (customerRequestId, data) => {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.login.currentUser.id;

    return dispatch({
      type: NOTIFY_CUSTOMER_REQUEST_WATCHERS,
      payload: axios.post(`/api/customer-requests/${customerRequestId}/notifications`, data).then(res => res.data),
      meta: {
        id: customerRequestId,
        userId,
      },
    });
  };
};

export const addUserToCustomerRequestWatchers = (customerRequestId, user) => {
  return {
    type: ADD_USER_TO_CUSTOMER_REQUESTS_WATCHERS,
    payload: user,
    meta: { id: customerRequestId },
  };
};

export const setCustomerRequestListPage = page => {
  return {
    type: SET_CUSTOMER_REQUEST_LIST_PAGE,
    payload: page,
  };
};

export const setCustomerRequestResultCount = count => {
  return {
    type: SET_CUSTOMER_REQUEST_RESULT_COUNT,
    payload: count,
  };
};

export const setCustomerRequestPageLimit = limit => {
  return {
    type: SET_CUSTOMER_REQUEST_PAGE_LIMIT,
    payload: limit,
  };
};

export const setSelectedCounterPieChartGroupBy = selectedCounterPieChartGroupBy => {
  return {
    type: SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_PIE_CHART_GROUP_BY,
    selectedCounterPieChartGroupBy,
    meta: { makesActiveViewDirty: true },
  };
};

export const setSelectedCounterStackedChartGroupBy = selectedCounterStackedChartGroupBy => {
  return {
    type: SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_GROUP_BY,
    selectedCounterStackedChartGroupBy,
    meta: { makesActiveViewDirty: true },
  };
};

export const setSelectedCounterStackedChartStackedBy = selectedCounterStackedChartStackedBy => {
  return {
    type: SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_STACKED_BY,
    selectedCounterStackedChartStackedBy,
    meta: { makesActiveViewDirty: true },
  };
};

export const gotCustomerRequestsRealtimeUpdate = generateRealtimeUpdateAction(
  null,
  null,
  null,
  null,
  null,
  REALTIME_PARTIAL_BULK_UPDATE_CUSTOMER_REQUESTS,
);
