/* eslint-disable */
import React from 'react';
import { withTheme } from 'styled-components';

export default withTheme(({ fill, width = 24, height = 24, theme, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={fill} viewBox="0 -960 960 960" {...props}>
      <path d="M160-160v-440h160v440H160Zm0-480v-160h160v160H160Zm240 480v-320h160v320H400Zm0-360v-160h160v160H400Zm240 360v-200h160v200H640Zm0-240v-160h160v160H640Z" />
    </svg>
  );
});
