import getLegendBoxConfig from './getLegendBoxConfig';

const getLegendConfigForStackedChart = (labelsLimit = 10) => ({
  onClick: () => {},
  labels: {
    filter: label => {
      if (label.index > labelsLimit) return false;

      return true;
    },
    generateLabels: chart => {
      const { data } = chart;

      if (data.datasets.length) {
        return data.datasets.map((dataset, i) => {
          if (i === labelsLimit) {
            return {
              text: '...',
              fillStyle: 'transparent',
              lineWidth: 0,
            };
          }

          return {
            text: dataset.label,
            fillStyle: dataset.backgroundColor,
            lineWidth: 0,
            index: i,
          };
        });
      }

      return [];
    },
    ...getLegendBoxConfig(),
  },
});

export default getLegendConfigForStackedChart;
