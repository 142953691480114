import { COMPARE_SCENARIOS_PLAN_OF_RECORD_COMMITTED_VERSION, COMPARE_SCENARIOS_PLAN_OF_RECORD_VERSION } from 'constants/common';
import {
  COMPARE_SCENARIOS_LIST_PAGE,
  COMPARE_SCENARIOS_CHARTS_PAGE,
  COMPARE_SCENARIOS_SUMMARY_PAGE,
  COMPARE_SCENARIOS_SWIMLANE_PAGE,
} from 'constants/filters';

const COMPARE_SCENARIOS_PAGE_OPTIONS_MAPPER = {
  [COMPARE_SCENARIOS_LIST_PAGE]: [COMPARE_SCENARIOS_PLAN_OF_RECORD_VERSION],
  [COMPARE_SCENARIOS_CHARTS_PAGE]: [COMPARE_SCENARIOS_PLAN_OF_RECORD_VERSION, COMPARE_SCENARIOS_PLAN_OF_RECORD_COMMITTED_VERSION],
  [COMPARE_SCENARIOS_SWIMLANE_PAGE]: [
    COMPARE_SCENARIOS_PLAN_OF_RECORD_VERSION,
    COMPARE_SCENARIOS_PLAN_OF_RECORD_COMMITTED_VERSION,
  ],
  [COMPARE_SCENARIOS_SUMMARY_PAGE]: [
    COMPARE_SCENARIOS_PLAN_OF_RECORD_VERSION,
    COMPARE_SCENARIOS_PLAN_OF_RECORD_COMMITTED_VERSION,
  ],
};

const getPoROptionsByPage = pageId => {
  return COMPARE_SCENARIOS_PAGE_OPTIONS_MAPPER[pageId] || [];
};

export default getPoROptionsByPage;
