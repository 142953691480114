import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import IconButton from '@material-ui/core/IconButton';
import isNumber from 'lodash/isNumber';

import renderFormattedNumberText from 'design-system/utils/renderFormattedNumberText';

const BubbleNumericRenderer = ({ value, data, colDef, getValue }) => {
  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();

    if (data?.id && isNumber(data.id)) {
      colDef.onLinkClick(data.id);
    }
  };

  return (
    <Wrapper className={+value < 0 ? 'red' : ''} hasLink={!!colDef.onLinkClick}>
      {value && parseFloat(value) ? (
        <Bubble>
          <NumberFormat
            value={parseFloat(getValue())}
            renderText={renderFormattedNumberText}
            displayType="text"
            thousandSeparator
            decimalScale={2}
          />
          {colDef.suffix && colDef.suffix}
        </Bubble>
      ) : (
        <span />
      )}
      {data?.id && isNumber(data.id) && colDef.onLinkClick && <OpenLink onClick={onClick}>{colDef.linkIcon}</OpenLink>}
    </Wrapper>
  );
};

export default BubbleNumericRenderer;

const Wrapper = styled.div`
  height: auto !important;

  ${({ hasLink }) =>
    hasLink &&
    `
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  `}
`;

const OpenLink = styled(props => <IconButton {...props}>{props.children}</IconButton>)`
  &&&& {
    position: relative;
    visibility: hidden;
    padding: 3px;
    font-size: 1.125rem;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

const Bubble = styled.span`
  color: white;
  font-weight: bold;
  font-size: 11px;
  line-height: 22px;
  border-radius: 6px;
  border: 1px solid #00c8dd;
  width: auto !important;
  padding: 0 8px;
`;
