import { useMemo } from 'react';
import isFunction from 'lodash/isFunction';
import flatten from 'lodash/flatten';
import { useSelector } from 'react-redux';

import { getLoadedPublicSharedViewData } from 'store/userViews/selectors';
import { getCurrentUser } from 'store/login/selectors';
import { getOrganization, getOrgHasJiraIntegrated, getOrganizationIntegrations } from 'store/organization/selectors';

import usePermissions from 'hooks/permissions/usePermissions';
import usePageMode from 'hooks/app/usePageMode';

import { ALL_VIEWS } from 'constants/routes';

import generateRoutes from './generateRoutes';
import getRoutesAndComponents from './getRoutesAndComponents';

export default () => {
  const user = useSelector(getCurrentUser);
  const organization = useSelector(getOrganization);
  const systemFields = organization.system_fields_name;
  const jiraIntegrated = useSelector(getOrgHasJiraIntegrated);
  const orgIntegrations = useSelector(getOrganizationIntegrations);
  const publicSharedViewData = useSelector(state => getLoadedPublicSharedViewData(state));

  const { canView } = usePermissions();

  const menu = generateRoutes({ user, systemFields, organization, publicSharedViewData, canView }, getRoutesAndComponents);

  const filterTabs = t =>
    isFunction(t.hideMenu) ? !t.hideMenu({ jiraIntegrated, orgIntegrations, organization, userType: user.role_id }) : !t.hideMenu;

  let routes = menu;
  const tabs = menu.filter(filterTabs).map(t => ({ ...t, ...{ subMenu: t.subMenu ? t.subMenu.filter(filterTabs) : null } }));

  const validateRouteExists = location => {
    const [page, path] = location.pathname.split('/').filter(Boolean);

    const route = ALL_VIEWS[page]?.find(p => p.includes(path));

    if (route) {
      return route;
    }

    return false;
  };

  if (routes.length) {
    let subMenus = routes.filter(tab => !!tab.subMenu).map(tab => tab.subMenu);

    subMenus = flatten(subMenus);
    routes = [...routes, ...subMenus];
  }

  const routesWithPortfolioMode = useMemo(() => routes.filter(r => r?.props?.hasPortfolioOption === true), [routes]);

  usePageMode(routes);

  return [routes, tabs, validateRouteExists, routesWithPortfolioMode];
};
