const calculateLinesNumber = rowHeight => {
  switch (rowHeight) {
    case 40:
      return 1;
    case 80:
      return 3;
    case 120:
      return 6;
    case 160:
      return 8;
    default:
      return 1;
  }
};

export default calculateLinesNumber;
