import React from 'react';

import { useSelector } from 'react-redux';

import { getTotalCustomerRequests } from 'store/customerRequests/selectors';

export default Component => {
  return props => {
    const total = useSelector(state => getTotalCustomerRequests(state));

    return <Component total={total} {...props} />;
  };
};
