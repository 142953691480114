import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectObjectiveLinkedItems,
  isFetchObjectiveLinkedItemsLoading,
  isLinkProjectToOkrLoading,
  isUnlinkProjectToOkrLoading,
  isCreateProjectFromOkrLoading,
  fetchLinkedItems,
  linkProjectToOkr as linkProjectToOkrAction,
  unlinkProjectFromOkr as unlinkProjectFromOkrAction,
  addNewLinkedItemRow as addNewLinkedItemRowAction,
  removeUnsavedLinkedItemsRows as removeUnsavedLinkedItemsRowsAction,
} from '../store';
import { openProjectLightboxToCreateFromOkr } from 'store/projectLightbox/actions';
import { getNormalizedObjectives, getNormalizedKeyResults } from 'store/objectives/selectors';
import { getObjectiveKeyByLevel } from 'utils/objectives';
import { getInitialOkrDataForLightbox } from '../helpers';

const useOkrLinkedPortfolioItems = entity => {
  const dispatch = useDispatch();

  const linkedItems = useSelector(selectObjectiveLinkedItems);
  const isLoadingLinkedItems = useSelector(isFetchObjectiveLinkedItemsLoading);
  const isLoadingLinkProjectToOkr = useSelector(isLinkProjectToOkrLoading);
  const isLoadingUnLinkProjectToOkr = useSelector(isUnlinkProjectToOkrLoading);
  const isLoadingCreateProjectFromOkr = useSelector(isCreateProjectFromOkrLoading);

  const objectives = useSelector(getNormalizedObjectives);
  const keyResults = useSelector(getNormalizedKeyResults);

  const searchProjects = useCallback(() => dispatch(fetchLinkedItems(entity, {}, true)), [entity]);

  const createProjectFromOkr = useCallback(() => {
    const okrKey = getObjectiveKeyByLevel(entity);
    const initialData = getInitialOkrDataForLightbox(entity, objectives, keyResults);

    dispatch(openProjectLightboxToCreateFromOkr(entity?.id, okrKey, initialData));
  }, [entity]);

  const linkProjectToOkr = useCallback(project => dispatch(linkProjectToOkrAction(project, entity)), [entity]);

  const unlinkProjectFromOkr = useCallback(project => dispatch(unlinkProjectFromOkrAction(project, entity)), [entity]);

  const addNewLinkedItemRow = useCallback(() => dispatch(addNewLinkedItemRowAction()), []);

  const removeUnsavedLinkedItemsRows = useCallback(() => dispatch(removeUnsavedLinkedItemsRowsAction()), []);

  useEffect(() => {
    if (entity?.id) searchProjects();
  }, [entity?.id]);

  return {
    linkedItems,
    isLoadingLinkedItems,
    isLoadingLinkProjectToOkr,
    isLoadingUnLinkProjectToOkr,
    isLoadingCreateProjectFromOkr,
    createProjectFromOkr,
    linkProjectToOkr,
    unlinkProjectFromOkr,
    addNewLinkedItemRow,
    removeUnsavedLinkedItemsRows,
  };
};

export default useOkrLinkedPortfolioItems;
