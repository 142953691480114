import uniqWith from 'lodash/uniqWith';

import {
  UPDATE_PROJECT_FULFILLED,
  CREATE_PROJECT_FULFILLED,
  BULK_UPDATE_PROJECTS_FULFILLED,
  BULK_CREATE_PROJECTS_FULFILLED,
  IMPORT_PROJECTS_FULFILLED,
} from 'store/projects';
import { CREATE_PROJECT_ESTIMATE_FULFILLED } from 'store/estimates';
import { CREATE_ROADMAP_FULFILLED, UPDATE_ROADMAPS_FULFILLED } from 'store/roadmaps';
import { CREATE_THEME_FULFILLED } from 'store/themes/types';
import { CREATE_CATEGORY_FULFILLED } from 'store/categories/types';
import { CREATE_OBJECTIVE_FULFILLED, UPDATE_OBJECTIVES_FULFILLED } from 'store/objectives';
import { CREATE_PHASE_FULFILLED } from 'store/phases/types';
import { UPDATE_USER_BY_ID_FULFILLED, CREATE_USERS_FULFILLED } from 'store/users/types';
import { CREATE_TIMEFRAME_FULFILLED } from 'store/timeframes/types';
import { getEnrichedProject } from 'store/projects/selectors';

import checkItemExistsInArray from './checkItemExistsInArray';
import { CREATE_TEAM_FULFILLED } from 'store/teams/types';
import { CREATE_SKILL_FULFILLED } from 'store/skills/types';

export default ({ dispatch, getState }) =>
  next =>
  action => {
    const projectsActions = [
      UPDATE_PROJECT_FULFILLED,
      CREATE_PROJECT_FULFILLED,
      BULK_UPDATE_PROJECTS_FULFILLED,
      BULK_CREATE_PROJECTS_FULFILLED,
      IMPORT_PROJECTS_FULFILLED,
    ];
    const areEqualFn = (r1, r2) => r1 && r2 && r1.id === r2.id;

    const addNewEntries = data => {
      return options => {
        let items = data.reduce((acc, item) => {
          const project = getEnrichedProject(getState(), item);

          if (!project || !project[options.projectKey]) return acc;
          return [...acc, { ...project[options.projectKey], _project: project }];
        }, []);

        items = uniqWith(items, options.areEqualFn);

        items.forEach(item => {
          item = 'serialize' in options && typeof options.serialize === 'function' ? options.serialize(item) : item;

          if (item && !checkItemExistsInArray(options.items, item)) {
            dispatch({
              type: options.actionType,
              payload: options.processItemForDispatch ? options.processItemForDispatch(item) : item,
            });
          }
        });
      };
    };

    if (projectsActions.includes(action.type) && action.payload) {
      const data = action.payload.data ? action.payload.data : action.payload;

      const {
        roadmaps: { roadmaps },
        themes: { themes },
        objectives: { objectives },
        // users,
        phases: { phases },
        timeframes: timeframeState,
        categories: { categories },
      } = getState();

      // const owners = users.get('rows').toJS();
      const timeframes = timeframeState.get('rows').toJS();

      let keyResults = [];
      let products = [];

      roadmaps.forEach(roadmap => {
        if (roadmap.products) products = [...products, ...roadmap.products];
      });
      objectives.forEach(objective => {
        if (objective.keyResults) keyResults = [...keyResults, ...objective.keyResults];
      });

      if (data) {
        const checkNewItemExistOptions = [
          {
            items: roadmaps,
            projectKey: 'roadmap',
            actionType: CREATE_ROADMAP_FULFILLED,
            areEqualFn,
          },
          {
            items: themes,
            projectKey: 'theme',
            actionType: CREATE_THEME_FULFILLED,
            areEqualFn,
          },
          {
            items: categories,
            projectKey: 'category',
            actionType: CREATE_CATEGORY_FULFILLED,
            areEqualFn,
          },
          {
            items: objectives,
            projectKey: 'objective',
            actionType: CREATE_OBJECTIVE_FULFILLED,
            areEqualFn,
          },
          {
            items: phases,
            projectKey: 'phase',
            actionType: CREATE_PHASE_FULFILLED,
            areEqualFn,
          },
          // {
          //   items: owners,
          //   projectKey: 'owner',
          //   actionType: CREATE_USER_FULFILLED,
          //   areEqualFn,
          // },
          {
            items: timeframes,
            projectKey: 'timeframe',
            actionType: CREATE_TIMEFRAME_FULFILLED,
            areEqualFn,
          },
          {
            items: keyResults,
            projectKey: 'keyResult1',
            actionType: UPDATE_OBJECTIVES_FULFILLED,
            processItemForDispatch: item => [item._project.objective].filter(val => val),
            areEqualFn,
          },
          {
            items: products,
            projectKey: 'product1',
            actionType: UPDATE_ROADMAPS_FULFILLED,
            processItemForDispatch: item => [item._project.roadmap].filter(val => val),
            areEqualFn,
          },
        ];

        if (Array.isArray(data)) {
          checkNewItemExistOptions.forEach(addNewEntries(data));
        } else {
          checkNewItemExistOptions.forEach(addNewEntries([data]));
        }
      }
    }

    if (
      [
        UPDATE_PROJECT_FULFILLED,
        CREATE_PROJECT_FULFILLED,
        CREATE_PROJECT_ESTIMATE_FULFILLED,
        UPDATE_USER_BY_ID_FULFILLED,
        CREATE_USERS_FULFILLED,
      ].includes(action.type) &&
      action.payload
    ) {
      const projectEstimatesTypes = [UPDATE_PROJECT_FULFILLED, CREATE_PROJECT_FULFILLED];
      let data = action.payload;

      data = projectEstimatesTypes.includes(action.type) ? data.estimates : data;
      const {
        teams: { teams },
        skills: { skills },
      } = getState();

      if (data) {
        const checkNewItemExistOptions = [
          {
            items: teams,
            projectKey: 'team',
            actionType: CREATE_TEAM_FULFILLED,
            areEqualFn,
          },
          {
            items: skills,
            projectKey: 'skill',
            actionType: CREATE_SKILL_FULFILLED,
            areEqualFn,
          },
        ];

        if (Array.isArray(data)) {
          checkNewItemExistOptions.forEach(addNewEntries(data));
        } else {
          checkNewItemExistOptions.forEach(addNewEntries([data]));
        }
      }
    }

    next(action);
  };
