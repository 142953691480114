import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { isEmpty } from 'ramda';
import { debounce } from 'lodash';

import UserViewsList from 'containers/UserViewsList';
import Dialog from 'design-system/molecules/Dialog/index';
import DialogHeaderWithSearch from 'design-system/molecules/DialogHeaderWithSearch/index';
import Tabs from 'design-system/organisms/Tabs/index';
import { spacing } from 'design-system/theme';

import useViewsDialog from 'hooks/userViews/useViewsDialog';
import useViewsFilters from 'hooks/userViews/useViewsFilters';
import useViewsTabs, { ALL_VIEWS, FAVORITE_VIEWS } from 'hooks/userViews/useViewsTabs';
import useUserViewsList from 'hooks/userViews/useUserViewsList';
import { VIEW_ITEM_VARIANTS } from 'constants/userViews';
import Actions from './components/Actions';
import { FAVORITE_VIEWS_EMPTY_TEXT, FAVORITE_VIEWS_EMPTY_BUTTON_TEXT } from 'containers/ViewsListWithTabs/consts';

const dialogContentStyles = css`
  display: flex;
  padding: 24px 24px 0;
`;

export default () => {
  const { isOpen, closeDialog } = useViewsDialog();
  const { searchQuery, setSearchQuery, applyFiltersOnViews } = useViewsFilters();
  const { selectedTab, handleTabChange, viewsForSelectedTab, tabs } = useViewsTabs(true, closeDialog);

  const filteredViews = useMemo(() => applyFiltersOnViews(viewsForSelectedTab), [applyFiltersOnViews, viewsForSelectedTab]);

  const { handleSelectView, ...userViewsListProps } = useUserViewsList(filteredViews, !isEmpty(searchQuery));

  const debouncedSetQuery = useMemo(() => debounce(setSearchQuery, 200), []);

  const _handleSearchViews = useCallback(
    search => {
      if (search && selectedTab !== ALL_VIEWS) {
        handleTabChange(null, ALL_VIEWS);
      }
      debouncedSetQuery(search);
    },
    [selectedTab],
  );

  const _handleSelectView = view => {
    handleSelectView(view);
    closeDialog();
  };

  const isFavoriteViewsTab = selectedTab === FAVORITE_VIEWS;

  const handleFavoriteViewsEmptyButtonClick = () => handleTabChange(null, ALL_VIEWS);

  const viewsListProps = useMemo(
    () => ({
      ...userViewsListProps,
      ...(isFavoriteViewsTab
        ? {
            emptyText: FAVORITE_VIEWS_EMPTY_TEXT,
            emptyButtonText: FAVORITE_VIEWS_EMPTY_BUTTON_TEXT,
            onClickEmptyButton: handleFavoriteViewsEmptyButtonClick,
          }
        : {}),
    }),
    [selectedTab, userViewsListProps],
  );

  return (
    <Dialog
      id="views-dialog"
      open={isOpen}
      onClose={closeDialog}
      maxWidth="lg"
      scroll="paper"
      fullWidth
      header={
        <DialogHeaderWithSearch
          searchQuery={searchQuery}
          searchPlaceholder="Search for a view..."
          handleSearch={_handleSearchViews}
          closeDialog={closeDialog}
        />
      }
      actions={<Actions onClose={closeDialog} />}
      contentCustomStyles={dialogContentStyles}
      PaperComponent={StyledPaper}
    >
      <Wrapper>
        <StyledTabs tabs={tabs} selectedTab={selectedTab} handleTabChange={handleTabChange} />
        <ViewsContainer>
          <UserViewsList
            {...viewsListProps}
            handleSelectView={_handleSelectView}
            variant={VIEW_ITEM_VARIANTS.homepage}
            closeViewsDialog={closeDialog}
          />
        </ViewsContainer>
      </Wrapper>
    </Dialog>
  );
};

const StyledPaper = styled(Paper)`
  height: 900px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const StyledTabs = styled(Tabs)`
  margin-left: ${spacing(3)}px;
`;

const ViewsContainer = styled.div`
  overflow-y: auto;
  padding: ${spacing(1.5)}px ${spacing()}px;
`;
