import { fromJS, List, Map } from 'immutable';

import { IN_PROGRESS, DONE } from 'store/integrations/constants/statusCategories';
import { JIRA_EPIC_DELETED } from 'store/integrations/constants/errorCodes';

import deserializeProject from 'store/projects/helpers/deserializeProject';
import { updateProjectOnCollectionsUsingMethod } from 'store/projects/helpers/collections';

import {
  CREATE_PROJECT_INTEGRATION_FULFILLED,
  ADD_PROJECT_INTEGRATION,
  ADD_PROJECT_INTEGRATION_FULFILLED,
  REMOVE_PROJECT_INTEGRATION_FULFILLED,
  SET_PROJECT_STORIES,
  SET_PROJECT_INTEGRATIONS,
  CREATE_PROJECTS_JIRAS_FULFILLED,
  GET_PROJECT_STORIES_PENDING,
  GET_PROJECT_STORIES_FULFILLED,
  JIRA_TICKET_DETAIL_FULFILLED,
  JIRA_TICKET_DETAIL_REJECTED,
  JIRA_UNLINK_FULFILLED,
} from '../types';

const noPayload = action => {
  return !action.payload || !action.payload.projectId;
};
const noProjectIntegrationPayload = action => {
  return noPayload(action) || !action.payload.projectIntegration;
};
const noProjectIntegrationIdPayload = action => {
  return noPayload(action) || !action.payload.projectIntegrationId;
};
const noProjectIntegrationsPayload = action => {
  return noPayload(action) || !action.payload.projectIntegrations;
};

const projectIntegrationsReducer = (state, action) => {
  switch (action.type) {
    case ADD_PROJECT_INTEGRATION:
    case ADD_PROJECT_INTEGRATION_FULFILLED:
    case CREATE_PROJECT_INTEGRATION_FULFILLED: {
      if (noProjectIntegrationPayload(action)) {
        return state;
      }
      const { projectId, projectIntegration } = action.payload;

      const _updateCollectionWithAddedIntegration = (collection, collectionName) => {
        const index = collection.findIndex(obj => +obj.get('id') === +projectId);

        if (index === -1) return collection;

        return collection.update(index, project => {
          let integrations = project.get('integrations') || new List();

          if (projectIntegration instanceof Array) {
            projectIntegration.forEach(projectIntegration => {
              integrations = integrations.push(fromJS(projectIntegration));
            });
          } else if (projectIntegration) {
            integrations = integrations.push(fromJS(projectIntegration));
          }

          return project.set('integrations', integrations);
        });
      };
      const updatedCollections = updateProjectOnCollectionsUsingMethod(state, _updateCollectionWithAddedIntegration);

      return {
        ...state,
        ...updatedCollections,
      };
    }
    case REMOVE_PROJECT_INTEGRATION_FULFILLED: {
      if (noProjectIntegrationIdPayload(action)) {
        return state;
      }

      const { projectId, projectIntegrationId } = action.payload;
      const _updateCollectionWithRemovedIntegration = (collection, collectionName) => {
        const index = collection.findIndex(obj => +obj.get('id') === +projectId);

        if (index === -1) return collection;

        return collection.update(index, project => {
          let integrations = project.get('integrations') || new List();

          integrations = integrations.filter(integration => integration.get('id') !== projectIntegrationId);
          return project.set('integrations', integrations);
        });
      };
      const updatedCollections = updateProjectOnCollectionsUsingMethod(state, _updateCollectionWithRemovedIntegration);

      return {
        ...state,
        ...updatedCollections,
      };
    }
    case SET_PROJECT_INTEGRATIONS: {
      if (noProjectIntegrationsPayload(action)) {
        return state;
      }
      const { projectId, projectIntegrations } = action.payload;
      const _updateCollectionWithIntegrations = (collection, collectionName) => {
        const index = collection.findIndex(obj => +obj.get('id') === +projectId);

        if (index === -1) return collection;

        return collection.update(index, project => {
          const integrations = fromJS(projectIntegrations);

          return project.set('integrations', integrations);
        });
      };

      const updatedCollections = updateProjectOnCollectionsUsingMethod(state, _updateCollectionWithIntegrations);

      return {
        ...state,
        ...updatedCollections,
      };
    }
    case CREATE_PROJECTS_JIRAS_FULFILLED: {
      let rowsJs = state.rows.toJS();

      const {
        data: { data },
      } = action.payload;

      if (!data || !(data instanceof Array)) {
        return state;
      }

      data.forEach(row => {
        const project = {
          ...rowsJs.find(p => p.id === row.project_id),
          ...{
            Jiras: [row],
          },
        };

        rowsJs = rowsJs.filter(p => p.id !== row.project_id);
        rowsJs.unshift(deserializeProject(project));
      });
      return {
        ...state,
        rows: fromJS(rowsJs),
      };
    }
    case JIRA_UNLINK_FULFILLED: {
      const _jiraUnlink = collection => {
        const projectIndex = collection.findIndex(project => +project.get('id') === +action.payload.data.project_id);

        if (projectIndex === -1) return collection;

        const Jiras = (collection.getIn([projectIndex, 'Jiras']) || new List()).toJS();

        collection = collection.updateIn([projectIndex, 'Jiras'], () =>
          fromJS(Jiras.filter(j => j.key !== action.meta.issueKey)),
        );

        return collection.updateIn([projectIndex, 'integrationProgress'], () => null);
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _jiraUnlink),
      };
    }
    case JIRA_TICKET_DETAIL_FULFILLED: {
      const jiraData = action.payload.data;

      let found = false;

      const _updateProjectJiras = collection => {
        if (!collection) return new List();
        const index = collection.findIndex(obj => +obj.get('id') === +jiraData.project_id);

        if (index > -1) {
          found = true;
          const Jiras = collection.getIn([index, 'Jiras']) || [];

          return collection.updateIn([index, 'Jiras'], () => fromJS([...Jiras, jiraData]));
        }

        return collection;
      };

      const newCollections = updateProjectOnCollectionsUsingMethod(state, _updateProjectJiras);

      if (!found) {
        if (process.env.NODE_ENV && jiraData) {
          console.warn(`JIRA_TICKET_DETAIL_FULFILLED::Project with id ${jiraData.project_id} not found`);
        }

        return state;
      }

      return {
        ...state,
        ...newCollections,
        isLoaded: true,
      };
    }
    case JIRA_TICKET_DETAIL_REJECTED: {
      const errorData = action.payload?.response?.data;

      if (errorData?.error_code === JIRA_EPIC_DELETED && errorData._data && errorData._data.epicKey) {
        const rowsJs = state.rows.toJS();
        const project = rowsJs.find(r => {
          return r.jira && r.jira.key === errorData._data.epicKey;
        });

        if (!project) {
          return state;
        }

        project.Jiras = [];
        project.jira = null;
        return {
          ...state,
          rows: fromJS(rowsJs),
        };
      }

      return state;
    }
    case GET_PROJECT_STORIES_PENDING: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GET_PROJECT_STORIES_FULFILLED:
    case SET_PROJECT_STORIES: {
      if (!action.payload || !action.payload.projects || !action.payload.stories) {
        return state;
      }
      const { projects, stories } = action.payload;
      const allProjects = projects.split(',');

      const projectsStories = {};

      const _setProjectStories = collection => {
        return allProjects.reduce((currentCollection, projectId) => {
          const projectIndex = currentCollection.findIndex(project => project.get('id') === parseInt(projectId));
          const projectStories = stories.filter(
            s => s.parent_project_id === parseInt(projectId) || s.project_id === parseInt(projectId),
          );

          if (projectIndex === -1) return currentCollection;

          return currentCollection.update(projectIndex, project => {
            const projectUpdated = project;

            projectsStories[project.get('id')] = projectStories;

            const newProgress = projectStories.reduce(
              (progress, story) => {
                progress.issuesTotal++;

                if (story.status_category === IN_PROGRESS) {
                  progress.issuesInProgress++;
                } else if (story.status_category === DONE) {
                  progress.issuesClosed++;
                }

                progress.pointsTotal += story?.points_estimated ?? story?.data?.storyPoints ?? 0;

                return progress;
              },
              {
                issuesTotal: 0,
                issuesInProgress: 0,
                issuesClosed: 0,
                pointsTotal: 0,
              },
            );

            if (project.get('integrationProgress')) {
              const integrationProgress = project.get('integrationProgress').merge(new Map(newProgress));

              return projectUpdated.set('integrationProgress', integrationProgress);
            }

            return projectUpdated.set('integrationProgress', new Map(newProgress));
          });
        }, collection);
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _setProjectStories),
        stories: {
          ...state.stories,
          ...projectsStories,
        },
        isFetching: false,
      };
    }
    default: {
      return state;
    }
  }
};

export { projectIntegrationsReducer };
