import moment from 'moment-timezone';

import { ConditionBuilder } from '@dragonboat/dbql';
import { BETWEEN, LESS_THAN_OR_EQUAL, GREATER_THAN_OR_EQUAL } from '@dragonboat/dbql/src/operators';

import { FILTERS_FUNCTIONS } from 'design-system/constants';

const calculateNextDates = (unit, amount = 1) => ({
  start: moment().add(amount, unit).startOf(unit).format(),
  end: moment().add(amount, unit).endOf(unit).format(),
});

const calculateThisDates = unit => ({
  start: moment().startOf(unit).format(),
  end: moment().endOf(unit).format(),
});

const calculateLastDates = (unit, amount = 1) => ({
  start: moment().subtract(amount, unit).startOf(unit).format(),
  end: moment().subtract(amount, unit).endOf(unit).format(),
});

const WEEK = 'week';
const MONTH = 'month';
const QUARTER = 'quarter';
const YEAR = 'year';
const DAY = 'day';

const datesIntervalByFilter = {
  [FILTERS_FUNCTIONS.lastWeek.key]: calculateLastDates(WEEK),
  [FILTERS_FUNCTIONS.lastMonth.key]: calculateLastDates(MONTH),
  [FILTERS_FUNCTIONS.lastQuarter.key]: calculateLastDates(QUARTER),
  [FILTERS_FUNCTIONS.lastYear.key]: calculateLastDates(YEAR),
  [FILTERS_FUNCTIONS.thisWeek.key]: calculateThisDates(WEEK),
  [FILTERS_FUNCTIONS.thisMonth.key]: calculateThisDates(MONTH),
  [FILTERS_FUNCTIONS.thisQuarter.key]: calculateThisDates(QUARTER),
  [FILTERS_FUNCTIONS.thisYear.key]: calculateThisDates(YEAR),
  [FILTERS_FUNCTIONS.nextYear.key]: calculateNextDates(YEAR),
  [FILTERS_FUNCTIONS.nextQuarter.key]: calculateNextDates(QUARTER),
  [FILTERS_FUNCTIONS.nextMonth.key]: calculateNextDates(MONTH),
  [FILTERS_FUNCTIONS.nextWeek.key]: calculateNextDates(WEEK),
  [FILTERS_FUNCTIONS.today.key]: calculateThisDates(DAY),
};

const opThatNeedValueValidation = [FILTERS_FUNCTIONS.between.key, FILTERS_FUNCTIONS.after.key, FILTERS_FUNCTIONS.before.key];

const CreateConditionFromDateFilter = filterCondition => {
  const { field, op, value } = filterCondition;
  const selectedStart = value?.gte;
  const selectedEnd = value?.lte;

  if (opThatNeedValueValidation.includes(op) && !selectedStart && !selectedEnd) {
    console.warn('DBQL: Failed creating Condition for the field:', field?.id);
    return;
  }

  switch (op) {
    case FILTERS_FUNCTIONS.between.key:
      return ConditionBuilder.createCondition(field.id, BETWEEN, [selectedStart, selectedEnd]);
    case FILTERS_FUNCTIONS.after.key:
      return ConditionBuilder.createCondition(field.id, GREATER_THAN_OR_EQUAL, selectedStart);
    case FILTERS_FUNCTIONS.before.key:
      return ConditionBuilder.createCondition(field.id, LESS_THAN_OR_EQUAL, selectedEnd);
    default:
      const { start, end } = datesIntervalByFilter[op];

      return ConditionBuilder.createCondition(field.id, BETWEEN, [start, end]);
  }
};

export default CreateConditionFromDateFilter;
