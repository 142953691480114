import React from 'react';
import withTheme from '@material-ui/core/styles/withTheme';
import styled from 'styled-components';
import { InlineDatePicker } from 'material-ui-pickers';

function InlineDatePickerNoBorder(props) {
  return <StyledInlineDatePicker {...props} style={{ zIndex: 100000 }} customStyles={props.customStyles} />;
}

export const StyledInlineDatePicker = styled(InlineDatePicker)`
  > div:before {
    border-bottom: 0 !important;
  }

  > div:after {
    border-bottom: 0 !important;
  }

  button {
    transition: opacity 150ms ease-in-out;
    opacity: 0;

    svg {
      font-size: ${({ theme }) => theme.typography.fontSizeSmallLargeRem}rem;
    }
  }

  :hover {
    button {
      opacity: 1;
    }
  }

  && {
    ${({ customStyles }) => customStyles}
  }
`;

export default withTheme()(InlineDatePickerNoBorder);
