import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';
import {
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  RESOURCE_TEAM,
  TEAM,
  TEAM_2,
} from 'constants/common';
import { customUserFieldIds } from 'utils/customUserFields/customUserFields';

const MULTI_SELECT_METADATA_FIELDS = [
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
];

const MULTI_SELECT_TEAM_FIELDS = [ADDITIONAL_TEAMS, ADDITIONAL_TEAMS_2];

const TEAM_RESOURCE_FIELDS = [TEAM, TEAM_2];

const mapCustomFieldTypeToFieldType = {
  [TYPES_OF_CUSTOM_FIELDS.SHORT_TEXT]: 'text',
  [TYPES_OF_CUSTOM_FIELDS.RICH_TEXT]: 'text',
  [TYPES_OF_CUSTOM_FIELDS.NUMBER]: 'number',
  [TYPES_OF_CUSTOM_FIELDS.DATE]: 'date',
  [TYPES_OF_CUSTOM_FIELDS.YES_NO]: 'checkbox',
  [TYPES_OF_CUSTOM_FIELDS.DROPDOWN]: 'option',
  [TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN]: 'option',
};

const getDefaultInitialValue = (fieldType = '') => {
  switch (fieldType) {
    case TYPES_OF_CUSTOM_FIELDS.YES_NO:
      return true;
    default:
      return null;
  }
};

const parseCustomField = field => ({
  id: field.key,
  type: mapCustomFieldTypeToFieldType[field.field_type],
  label: field.title,
  archivedOptions: true,
  defaultInitialValue: getDefaultInitialValue(field?.field_type),
});

const getLabelByFieldKey = (field, getSystemFieldName) => {
  if (MULTI_SELECT_TEAM_FIELDS.includes(field.labelKey)) {
    return `All ${getSystemFieldName(field.systemKey, true)}`;
  }

  if (MULTI_SELECT_METADATA_FIELDS.includes(field.labelKey)) {
    return `Additional ${getSystemFieldName(field.systemKey, true)}`;
  }

  if (TEAM_RESOURCE_FIELDS.includes(field.labelKey)) {
    return `Estimate ${getSystemFieldName(field.labelKey)}`;
  }

  if (RESOURCE_TEAM === field.labelKey) {
    return getSystemFieldName('team');
  }

  return getSystemFieldName(field.labelKey);
};

export default (
  getSystemFieldName,
  availableFields = {},
  includeCustomFields = false,
  customFields = [],
  hasKeyResults = false,
  hasKeyResults2 = false,
  hasProducts = false,
  hasMultiLevelPortfolioMetadata = false,
  hasTeams2 = false,
  hasProjectMetrics = false,
  options = {},
) => {
  let fields = Object.keys(availableFields).reduce((result, key) => {
    const field = availableFields[key];

    let { label } = field;

    if (!label && getSystemFieldName) {
      label = getLabelByFieldKey(field, getSystemFieldName);
    }

    result.push({
      ...field,
      id: key,
      label,
    });
    return result;
  }, []);

  if (!hasKeyResults) fields = fields.filter(f => f.id !== 'keyResults' && f.id !== ADDITIONAL_KEY_RESULTS);
  if (!hasKeyResults2) fields = fields.filter(f => f.id !== 'keyResults2' && f.id !== ADDITIONAL_KEY_RESULTS_2);
  if (!hasProducts) fields = fields.filter(f => f.id !== 'products' && f.id !== ADDITIONAL_PRODUCTS);
  if (!hasTeams2) fields = fields.filter(f => !['teams2', ADDITIONAL_TEAMS_2].includes(f.id));
  if (!hasMultiLevelPortfolioMetadata) fields = fields.filter(f => !['timeframes2', ADDITIONAL_TIMEFRAMES_2].includes(f.id));
  if (!hasProjectMetrics) fields = fields.filter(f => !['metrics'].includes(f.id));

  if (options.canView && !options.canView(PERMISSION_RESOURCES.objectiveCorp)) {
    fields = fields.filter(f => !['objectivesCorp'].includes(f.id));
  }
  if (options.canView && !options.canView(PERMISSION_RESOURCES.roadmapCorp)) {
    fields = fields.filter(f => !['roadmapsCorp'].includes(f.id));
  }

  if (options.canView && !options.canView(PERMISSION_RESOURCES.product2)) {
    fields = fields.filter(f => !['products2'].includes(f.id));
  }

  customUserFieldIds.forEach(customUserField => {
    if (!options.getCustomUserFieldByFieldKey || !options.getCustomUserFieldByFieldKey(customUserField)) {
      fields = fields.filter(f => ![customUserField].includes(f.id));
    }
  });

  if (includeCustomFields && customFields && customFields.length) {
    return [...fields, ...customFields.map(parseCustomField)];
  }

  return fields;
};
