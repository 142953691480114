import { useCallback } from 'react';
import { pluck } from 'ramda';
import { useDispatch } from 'react-redux';

import { updateProjectLifecycles as updateProjectLifecyclesAction } from 'store/projects/thunks';

const ID_KEY = 'id';
const mapObjectsToIds = pluck(ID_KEY);

/**
 * @function useUpdateProjectLifecycles
 *
 * hook that returns actions to update project Lifecycles
 *
 * @return {Object}
 */
const useUpdateProjectLifecycles = () => {
  const dispatch = useDispatch();

  const updateProjectLifecycles = useCallback(
    (id, newValue, oldValue) => {
      const oldValueIds = mapObjectsToIds(oldValue);
      const newValueIds = mapObjectsToIds(newValue);

      dispatch(updateProjectLifecyclesAction(id, newValueIds, oldValueIds));
    },
    [dispatch, updateProjectLifecyclesAction],
  );

  return { updateProjectLifecycles };
};

export default useUpdateProjectLifecycles;
