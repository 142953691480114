import React from 'react';
import styled from 'styled-components';

import NewDialog from 'design-system/organisms/NewDialog/index';

import theme from 'design-system/theme';
import Header from './components/Header';
import Chat from './components/Chat';

const AIAnswerBotDialog = ({ isOpen, closeDialog }) => {
  return (
    <StyledDialog
      open={isOpen}
      fullWidth
      fullHeight
      width="100%"
      closeButton
      closeButtonColor={theme.palette.white}
      paddingOverride="0px"
      onClose={closeDialog}
      contentBackgroundColor={theme.palette.background.aiAnswerBotDialog}
      disableBackdropClick
    >
      <Header />
      <Chat />
    </StyledDialog>
  );
};

export default AIAnswerBotDialog;

const StyledDialog = styled(NewDialog)`
  &&&& {
    div[role='document'] > div {
      height: 100%;
      max-height: 100%;
      margin: 0;
      border-radius: 0;
    }
  }
`;
