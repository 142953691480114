import axios from 'axios';
import throwRequestError from '../utils/throwRequestError';

import {
  FETCH_TAGS,
  CREATE_TAG,
  MERGE_TAGS,
  DELETE_TAG,
  CREATE_TAGS,
  UNDO_CREATE_TAGS,
  UPDATE_TAGS,
  UNDO_UPDATE_TAGS,
  BULK_DELETE_TAGS,
  UNDO_BULK_DELETE_TAGS,
  UPDATE_TAG_BY_ID,
} from './types';
import bulkCreateAction from 'store/utils/factory/bulkCreateAction';
import bulkUpdateAction from 'store/utils/factory/bulkUpdateAction';
import bulkDeleteAction from 'store/utils/factory/bulkDeleteAction';
import fetchMetadataAction from 'store/utils/factory/fetchMetadataAction';


export function mergeTags(tagsIdsToMerge, tagId) {
  return dispatch => {
    const payload = axios.post(`/api/tags/merge/${tagId}`, {
      tagsIdsToMerge,
    })
      .then(response => {
        return response.data;
      })
      .catch(throwRequestError);

    dispatch({
      type: MERGE_TAGS,
      payload,
    });

    return payload;
  };
}

export const deleteTag = tagId => {
  return dispatch => {
    const payload = axios.delete(`/api/tags/${tagId}`).then(res => res.data).catch(throwRequestError);

    dispatch({
      payload,
      type: DELETE_TAG,
    });

    return payload;
  };
};

export const fetchTags = fetchMetadataAction(
  FETCH_TAGS,
  '/api/tags',
  (state) => state.tags.toJS().rows,
  (state) => state.tags.toJS().lastCallsDate,
);

export const createTag = (tag) => {
  return dispatch => {
    const payload = axios.post('/api/tags', tag).then(res => res.data);

    dispatch({
      payload,
      type: CREATE_TAG,
    });

    return payload;
  };
};

export const updateTagById = (tagId, update) => {
  return {
    payload: axios.put(`/api/tags/${tagId}`, update).then(res => res.data).catch(throwRequestError),
    type: UPDATE_TAG_BY_ID,
  };
};

export const createTags = bulkCreateAction(CREATE_TAGS, '/api/tags', {
  toastText: 'Tags have been created',
  ACTION_TYPE: UNDO_CREATE_TAGS,
  endpoint: '/api/tags/versions/last',
  store: 'tags',
});
export const updateTags = bulkUpdateAction(UPDATE_TAGS, '/api/tags', {
  toastText: 'Tags have been changed',
  ACTION_TYPE: UNDO_UPDATE_TAGS,
  endpoint: '/api/tags/versions/last',
  store: 'tags',
});
export const bulkDeleteTags = bulkDeleteAction(BULK_DELETE_TAGS, '/api/tags/', {
  toastText: 'Tags have been deleted',
  ACTION_TYPE: UNDO_BULK_DELETE_TAGS,
  endpoint: '/api/tags/versions/last',
  store: 'tags',
});
