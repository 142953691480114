import { getThunkActionTypes } from 'utils/store/thunk';

const { CREATE_PROJECT_FROM_CANVAS, CREATE_PROJECT_FROM_CANVAS_FULFILLED } = getThunkActionTypes('CREATE_PROJECT_FROM_CANVAS');

const { CREATE_OBJECTIVE_FROM_CANVAS, CREATE_OBJECTIVE_FROM_CANVAS_FULFILLED } =
  getThunkActionTypes('CREATE_OBJECTIVE_FROM_CANVAS');
const { CREATE_KEY_RESULT_FROM_CANVAS, CREATE_KEY_RESULT_FROM_CANVAS_FULFILLED } =
  getThunkActionTypes('CREATE_KEY_RESULT_FROM_CANVAS');
const { CREATE_METRIC_FROM_CANVAS, CREATE_METRIC_FROM_CANVAS_FULFILLED } = getThunkActionTypes('CREATE_METRIC_FROM_CANVAS');

export {
  CREATE_PROJECT_FROM_CANVAS,
  CREATE_PROJECT_FROM_CANVAS_FULFILLED,
  CREATE_METRIC_FROM_CANVAS,
  CREATE_METRIC_FROM_CANVAS_FULFILLED,
  CREATE_OBJECTIVE_FROM_CANVAS,
  CREATE_OBJECTIVE_FROM_CANVAS_FULFILLED,
  CREATE_KEY_RESULT_FROM_CANVAS,
  CREATE_KEY_RESULT_FROM_CANVAS_FULFILLED,
};
