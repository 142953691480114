import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ADMIN_USER, EDITOR_USER, MANAGER_USER, LEADER_USER, OWNER_USER } from '@dragonboat/permissions';

import { getCurrentUser } from 'store/login/selectors';
import { getHasAdvancedMetricReporting, getHasProjectMetrics } from 'store/organization';
import {
  getAreMetricClustersVisible,
  getMetricViewModeTimePeriod,
  selectChartVisibleLayers,
  selectIsMetricMoarVisible,
} from 'store/metrics/selectors';
import {
  updateClustersVisibility,
  updateMetricViewTimePeriod,
  updateChartVisibleLayers as updateChartVisibleLayersAction,
} from 'store/metrics/actions';

import onlyVisibleForUsers from 'utils/onlyVisibleForUsers';
import makeChangeHandlerForDateRange from 'utils/makeChangeHandlerForDateRange';

const JANUARY = 0;
const DECEMBER = 11;
const FIRST_DAY = 1;
const LAST_DAY = 31;
const currentYear = new Date().getFullYear();
const defaultStartDate = new Date(currentYear, JANUARY, FIRST_DAY).setUTCHours(0, 0, 0, 0);
const defaultEndDate = new Date(currentYear, DECEMBER, LAST_DAY).setUTCHours(23, 59, 59);

const useMetricsChartView = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);
  const hasProjectMetrics = useSelector(getHasProjectMetrics);

  const areMetricClustersVisible = useSelector(getAreMetricClustersVisible);
  const isMetricMoarVisible = useSelector(selectIsMetricMoarVisible);
  const chartViewTimePeriod = useSelector(getMetricViewModeTimePeriod);
  const { startDate = defaultStartDate, endDate = defaultEndDate } = chartViewTimePeriod || {};

  // TODO: PERMISSION
  const userCanEditChartView = useMemo(
    () => onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER)(currentUser?.role_id),
    [currentUser?.role_id],
  );

  const userCanViewChartToggle = useMemo(() => hasAdvancedMetricReporting, [hasAdvancedMetricReporting]);

  const handleChartClustersToggle = useCallback(() => {
    dispatch(updateClustersVisibility(!areMetricClustersVisible));
  }, [updateClustersVisibility, areMetricClustersVisible]);

  const history = useHistory();
  const { search } = useLocation();

  const toggleShowMoar = useCallback(() => {
    const urlParams = new URLSearchParams(search);

    urlParams.set('showMoar', !isMetricMoarVisible);

    history.replace({ search: urlParams.toString() });
  }, [isMetricMoarVisible, search]);

  const setStartDate = useCallback(
    date => {
      dispatch(
        updateMetricViewTimePeriod({
          startDate: date,
          endDate,
        }),
      );
    },
    [dispatch, updateMetricViewTimePeriod, startDate, endDate],
  );

  const setEndDate = useCallback(
    date => {
      dispatch(
        updateMetricViewTimePeriod({
          endDate: date,
          startDate,
        }),
      );
    },
    [dispatch, updateMetricViewTimePeriod, startDate, endDate],
  );

  const handleDateRangeChange = useMemo(
    () => makeChangeHandlerForDateRange(setStartDate, setEndDate),
    [setStartDate, setEndDate],
  );

  const chartVisibleLayers = useSelector(selectChartVisibleLayers);

  const updateChartVisibleLayers = useCallback(
    (visibleLayers = []) => {
      if (!Array.isArray(visibleLayers)) return;

      dispatch(updateChartVisibleLayersAction(visibleLayers));
    },
    [updateChartVisibleLayersAction],
  );

  return {
    areMetricClustersVisible,
    hasProjectMetrics,
    startDate,
    endDate,
    userCanEditChartView,
    userCanViewChartToggle,
    handleChartClustersToggle,
    handleDateRangeChange,
    isMetricMoarVisible,
    toggleShowMoar,
    chartVisibleLayers,
    updateChartVisibleLayers,
  };
};

export default useMetricsChartView;
