import { useSelector } from 'react-redux';

import { getOrganization } from 'store/organization';
import { Configuration } from 'utils/configuration';

export const checkOrganizationFlags = (organization, flags) => {
  return Configuration.isEnabled(flags, { organization });
};

export default flags => {
  const organization = useSelector(getOrganization);

  return checkOrganizationFlags(organization, flags);
};
