import { defaultTo, isNil, not } from 'ramda';

import {
  ADMIN_USER,
  CSM_USER,
  EDITOR_USER,
  LEADER_USER,
  MANAGER_USER,
  OWNER_USER,
  COLLABORATOR_USER,
  PERMISSION_ACTIONS,
  PLANNER_USER,
  READ_ONLY_USER,
  REQUESTOR_USER,
  RESOURCE_USER,
  SUPER_ADMIN_USER,
  PERMISSION_RESOURCES,
} from '@dragonboat/permissions';
import { FeatureFlags } from '@dragonboat/config';

import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { checkOrganizationFlags } from 'hooks/useFeatureFlags';

import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';

import userViewDialogPermissions from 'containers/ViewDialog/permissions';
import { ESTIMATE_BY_POINTS } from 'constants/teams';

const generateFeaturesPermissions = ({ checks, isChildDragon, organization }) => {
  const hasCrossBabyRoadmapSupport = organization[FeatureFlags.HAS_DOD_CROSS_BABY_ROADMAP_SUPPORT];
  const isCrossBabyRoadmapSupportEnabled = () => hasCrossBabyRoadmapSupport;

  const canViewProjectProgress = ({ project }) => {
    const hasProgressReport = checkOrganizationFlags(organization, [FeatureFlags.HAS_PROGRESS_REPORT]);

    if (!isNil(project?.layer)) {
      return hasProgressReport && [BET_LAYER, INITIATIVE_LAYER].includes(project?.layer);
    }

    return hasProgressReport;
  };

  const canViewControlsBar = () => {
    // anonymous and requestors should not see controls bar
    if (checks.userIsRequestorOrAnonymous()) return false;

    // readonly users should view controls bar only if the flag is enabled
    if (checks.userIsReadOnly()) return checks.showConfigForReadOnly();

    return true;
  };

  const canViewHeadlessShare = () => {
    return checks?.hasHeadlessShareEnabled();
  };

  const canInviteUsers = () => {
    const { read_only_users_access: readOnlyUsersAccessObject } = organization;

    const readOnlyUsersAccess = defaultTo({}, readOnlyUsersAccessObject);

    const isDisallowedForReadOnly = readOnlyUsersAccess['users/invite'] === false;

    if (checks.userIsReadOnly() && isDisallowedForReadOnly) {
      return false;
    }

    return true;
  };

  const canViewCustomersCustomFieldsCustomizer = () => checks?.userIsOneOf(SUPER_ADMIN_USER, OWNER_USER, LEADER_USER);

  // Builds check for `flags`
  const checkFeatureFlagsEnabled = flags => {
    return () => checkOrganizationFlags(organization, flags);
  };

  const canViewRequestsModule = () => {
    return (
      checkOrganizationFlags(organization, FeatureFlags.HAS_CUSTOMER_REQUESTS) &&
      checks.userIsOneOf(
        ADMIN_USER,
        SUPER_ADMIN_USER,
        CSM_USER,
        OWNER_USER,
        READ_ONLY_USER,
        RESOURCE_USER,
        REQUESTOR_USER,
        PLANNER_USER,
        COLLABORATOR_USER,
        LEADER_USER,
        MANAGER_USER,
        EDITOR_USER,
      )
    );
  };

  const canViewOutcomeModule = () => {
    return (
      checks.isNotSelfTrialAccount() &&
      checks.userIsOneOf(ADMIN_USER, SUPER_ADMIN_USER, CSM_USER, OWNER_USER, LEADER_USER, PLANNER_USER, MANAGER_USER, EDITOR_USER)
    );
  };

  const canUpdateAiReleaseNotes = () => checks?.userIsOneOf(ADMIN_USER, OWNER_USER, LEADER_USER);

  const canViewBulkUpdateRequests = () =>
    !checks.userIsAnonymous() &&
    checks.userIsOneOf(ADMIN_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER, READ_ONLY_USER, REQUESTOR_USER);

  const canBulkUpdateRequest = (request, permissions) =>
    permissions.canUpdate(PERMISSION_RESOURCES.request, { data: request, isBulk: true });

  const canViewMergeRequests = () =>
    !checks.userIsAnonymous() &&
    checks.userIsOneOf(ADMIN_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER, READ_ONLY_USER, REQUESTOR_USER);

  const canMergeRequest = (request, permissions) =>
    permissions.canUpdate(PERMISSION_RESOURCES.request, { data: request, isBulk: true });

  const canViewBulkDeleteRequests = () =>
    !checks.userIsAnonymous() &&
    checks.userIsOneOf(ADMIN_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER, READ_ONLY_USER, REQUESTOR_USER);

  const canBulkDeleteRequest = (request, permissions) =>
    permissions.canDelete(PERMISSION_RESOURCES.request, { data: request, isBulk: true });

  const canViewCompareScenariosModule = () => {
    return (
      checkOrganizationFlags(organization, FeatureFlags.HAS_ROADMAP_VERSIONS) ||
      checkOrganizationFlags(organization, FeatureFlags.HAS_ONE_CLICK_PLAN)
    );
  };

  const canViewPortfolioModule = () =>
    checks.userIsOneOf(ADMIN_USER, SUPER_ADMIN_USER, CSM_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER);

  const canViewForecastModule = () => checks.userIsOneOf(ADMIN_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER);

  const canViewSettingsModule = () => checks.userIsOneOf(ADMIN_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER);

  const canViewPDLCModule = () => checks.userIsOneOf(COLLABORATOR_USER);

  const canViewScenariosModule = () =>
    checks.userIsOneOf(ADMIN_USER, SUPER_ADMIN_USER, CSM_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER);

  const canViewDashboardModule = () =>
    checks.userIsOneOf(ADMIN_USER, SUPER_ADMIN_USER, CSM_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER);

  const canViewMetricsModule = () => {
    return (
      checks.isNotSelfTrialAccount() &&
      checks.userIsOneOf(ADMIN_USER, SUPER_ADMIN_USER, CSM_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER)
    );
  };

  const allowToCreateUpdateDahsboard = () => {
    return checks.userIsOneOf(ADMIN_USER, SUPER_ADMIN_USER, OWNER_USER, CSM_USER, LEADER_USER, MANAGER_USER, EDITOR_USER);
  };

  const canViewPageHeaderFiltersAndOptions = context => {
    return checks.userIsOneOf(ADMIN_USER, SUPER_ADMIN_USER, CSM_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER);
  };

  return {
    [PERMISSION_FEATURES.inviteUsers]: {
      [PERMISSION_ACTIONS.view]: [checks.isPortfolioAccount, canInviteUsers],
    },
    [PERMISSION_FEATURES.childOrganizationReaders]: {
      [PERMISSION_ACTIONS.create]: [checks.isCorpAccount],
    },
    [PERMISSION_FEATURES.rollupDates]: {
      [PERMISSION_ACTIONS.view]: [checks.isCorpAccount],
    },
    [PERMISSION_FEATURES.crossBabyRoadmapSupport]: {
      [PERMISSION_ACTIONS.view]: [() => isChildDragon, isCrossBabyRoadmapSupportEnabled],
      [PERMISSION_ACTIONS.update]: [() => isChildDragon, isCrossBabyRoadmapSupportEnabled],
    },
    ...userViewDialogPermissions({ checks }),
    [PERMISSION_FEATURES.projectProgressReport]: {
      [PERMISSION_ACTIONS.view]: [canViewProjectProgress],
    },
    [PERMISSION_FEATURES.controlsBar]: {
      [PERMISSION_ACTIONS.view]: [canViewControlsBar],
    },
    [PERMISSION_FEATURES.headlessShare]: {
      [PERMISSION_ACTIONS.view]: [canViewHeadlessShare],
    },
    [PERMISSION_FEATURES.personas]: {
      [PERMISSION_ACTIONS.view]: [
        canViewRequestsModule,
        checkFeatureFlagsEnabled(FeatureFlags.HAS_PERSONAS),
        checks.isPortfolioAccount,
      ],
    },
    [PERMISSION_FEATURES.lifecycles]: {
      [PERMISSION_ACTIONS.view]: [
        canViewRequestsModule,
        checkFeatureFlagsEnabled(FeatureFlags.HAS_LIFECYCLES),
        checks.isPortfolioAccount,
      ],
    },
    [PERMISSION_FEATURES.updateOrgFlagPersonas]: {
      [PERMISSION_ACTIONS.view]: [checks.userIsOwnerOrLeader, canViewRequestsModule, checks.isPortfolioAccount],
    },
    [PERMISSION_FEATURES.updateOrgFlagLifecycles]: {
      [PERMISSION_ACTIONS.view]: [checks.userIsOwnerOrLeader, canViewRequestsModule, checks.isPortfolioAccount],
    },
    [PERMISSION_FEATURES.importRequestsWithExternalKey]: {
      [PERMISSION_ACTIONS.view]: [
        checkFeatureFlagsEnabled([FeatureFlags.HAS_EXTERNAL_KEY_ON_PROJECTS_REQUESTS]),
        canViewRequestsModule,
        checks.isPortfolioAccount,
      ],
    },
    [PERMISSION_FEATURES.importProjectsWithExternalKey]: {
      [PERMISSION_ACTIONS.view]: [
        checkFeatureFlagsEnabled([FeatureFlags.HAS_EXTERNAL_KEY_ON_PROJECTS_REQUESTS]),
        checks.isPortfolioAccount,
      ],
    },
    [PERMISSION_FEATURES.missionControlBookADemo]: {
      [PERMISSION_ACTIONS.view]: [checks.isTrialOrSelfTrialAccount, checks.userIsOwnerOrLeader],
    },
    [PERMISSION_FEATURES.missionControlRequests]: {
      [PERMISSION_ACTIONS.view]: [canViewRequestsModule],
    },
    [PERMISSION_FEATURES.missionControlMetrics]: {
      [PERMISSION_ACTIONS.view]: [canViewOutcomeModule, checkFeatureFlagsEnabled(FeatureFlags.HAS_ADVANCED_METRIC_REPORTING)],
    },
    [PERMISSION_FEATURES.missionControlOkrs]: {
      [PERMISSION_ACTIONS.view]: [canViewOutcomeModule],
    },
    [PERMISSION_FEATURES.outcomeModule]: {
      [PERMISSION_ACTIONS.view]: [canViewOutcomeModule],
    },
    [PERMISSION_FEATURES.metricLevels]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_METRIC_LEVELS)],
      [PERMISSION_ACTIONS.update]: [
        checkFeatureFlagsEnabled(FeatureFlags.HAS_METRIC_LEVELS),
        (context, permissions) => permissions.canUpdate(PERMISSION_RESOURCES.metricLevel, context),
      ],
    },
    [PERMISSION_FEATURES.metricLightboxDetails]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_METRIC_LIGHTBOX_DETAILS)],
    },
    [PERMISSION_FEATURES.metricProjectsLightbox]: {
      [PERMISSION_ACTIONS.view]: [() => not(checks.userIsAnonymous())],
    },
    [PERMISSION_FEATURES.goalProjectsLightbox]: {
      [PERMISSION_ACTIONS.view]: [() => not(checks.userIsAnonymous())],
    },
    [PERMISSION_FEATURES.metadataRoadmaps]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.ENABLE_METADATA_ROADMAPS)],
    },
    [PERMISSION_FEATURES.customersCustomFieldsCustomizer]: {
      [PERMISSION_ACTIONS.view]: [
        checkFeatureFlagsEnabled(FeatureFlags.HAS_CUSTOMER_SEGMENT_TYPE_CUSTOMIZATION),
        canViewCustomersCustomFieldsCustomizer,
      ],
    },
    [PERMISSION_FEATURES.metricSummary]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_METRIC_SUMMARY)],
    },
    [PERMISSION_FEATURES.roadmapHistory]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_ROADMAP_HISTORY), checks.isPortfolioAccount],
    },
    [PERMISSION_FEATURES.requestDetailsFieldsLayout]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_CONFIGURABLE_REQUEST_DRAWER)],
    },
    [PERMISSION_FEATURES.projectMetrics]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_PROJECT_METRICS)],
    },
    [PERMISSION_FEATURES.aiReleaseNotes]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_AI_RELEASE_NOTES)],
      [PERMISSION_ACTIONS.update]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_AI_RELEASE_NOTES), canUpdateAiReleaseNotes],
    },
    [PERMISSION_FEATURES.aiAnswerBot]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_DRAGONBOT_ENABLED)],
    },
    [PERMISSION_FEATURES.metricMoar]: {
      [PERMISSION_ACTIONS.view]: [
        (context, permissions) => permissions.canView(PERMISSION_FEATURES.outcomeModule, context),
        () => checks.isFeatureFlagEnabled(FeatureFlags.HAS_MOAR_CHART),
      ],
    },
    [PERMISSION_FEATURES.requestsListBulkUpdate]: {
      [PERMISSION_ACTIONS.view]: [canViewBulkUpdateRequests],
      [PERMISSION_ACTIONS.update]: [canBulkUpdateRequest],
    },
    [PERMISSION_FEATURES.requestsMerge]: {
      [PERMISSION_ACTIONS.view]: [canViewMergeRequests],
      [PERMISSION_ACTIONS.update]: [canMergeRequest],
    },
    [PERMISSION_FEATURES.requestsBulkDelete]: {
      [PERMISSION_ACTIONS.view]: [canViewBulkDeleteRequests],
      [PERMISSION_ACTIONS.delete]: [canBulkDeleteRequest],
    },
    [PERMISSION_FEATURES.completedAllocation]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_COMPLETED_ALLOCATION)],
    },
    [PERMISSION_FEATURES.summaryLazyLoading]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_SUMMARY_LAZY_LOADING)],
    },
    [PERMISSION_FEATURES.actualAllocationByTime]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_ACTUAL_ALLOCATION)],
    },
    [PERMISSION_FEATURES.defaultsForAutoHealth]: {
      [PERMISSION_ACTIONS.view]: [
        checkFeatureFlagsEnabled(FeatureFlags.HAS_DEFAULTS_FOR_AUTO_HEALTH),
        checks.userIsOwnerOrLeader,
      ],
    },
    [PERMISSION_FEATURES.newRoadmapMetadataAutocomplete]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_NEW_ROADMAP_METADATA_AUTOCOMPLETE)],
    },
    [PERMISSION_FEATURES.newRoadmapMetadataAutocompleteOnGrids]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_NEW_METADATA_AUTOCOMPLETE_ON_GRIDS)],
    },
    [PERMISSION_FEATURES.newRoadmapMetadataAutocompleteOnMetricsForm]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_NEW_METADATA_AUTOCOMPLETE_ON_METRICS_FORM)],
    },
    [PERMISSION_FEATURES.exportMetricOkrsFromGrid]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_EXPORT_METRICS_OKR_FROM_GRID)],
    },
    [PERMISSION_FEATURES.usePointsToEstimate]: {
      [PERMISSION_ACTIONS.view]: [
        checkFeatureFlagsEnabled(FeatureFlags.USE_POINTS_TO_ESTIMATE),
        ({ layer }) => !layer || layer === IDEA_LAYER,
        ({ team }) => !team || team?.estimate_by === ESTIMATE_BY_POINTS,
      ],
      [PERMISSION_ACTIONS.update]: [
        checkFeatureFlagsEnabled(FeatureFlags.USE_POINTS_TO_ESTIMATE),
        (context, permissions) => permissions.canUpdate(PERMISSION_RESOURCES.team, context),
      ],
    },
    [PERMISSION_FEATURES.projectEstimateByPoints]: {
      [PERMISSION_ACTIONS.update]: [
        checkFeatureFlagsEnabled(FeatureFlags.USE_POINTS_TO_ESTIMATE),
        ({ project }) => project?.layer === IDEA_LAYER,
        ({ team }) => team?.estimate_by === ESTIMATE_BY_POINTS,
      ],
    },
    [PERMISSION_FEATURES.pdlcGovernance]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_ADVANCED_PDLC)],
    },
    [PERMISSION_FEATURES.scenariosModule]: {
      [PERMISSION_ACTIONS.view]: [checkFeatureFlagsEnabled(FeatureFlags.HAS_ROADMAP_VERSIONS), canViewScenariosModule],
    },
    [PERMISSION_FEATURES.compareScenariosModule]: {
      [PERMISSION_ACTIONS.view]: [canViewCompareScenariosModule],
    },
    [PERMISSION_FEATURES.portfolioModule]: {
      [PERMISSION_ACTIONS.view]: [canViewPortfolioModule],
    },
    [PERMISSION_FEATURES.forecastModule]: {
      [PERMISSION_ACTIONS.view]: [canViewForecastModule],
    },
    [PERMISSION_FEATURES.settingsModule]: {
      [PERMISSION_ACTIONS.view]: [canViewSettingsModule],
    },
    [PERMISSION_FEATURES.pdlcModule]: {
      [PERMISSION_ACTIONS.view]: [canViewPDLCModule],
    },
    [PERMISSION_FEATURES.requestsModule]: {
      [PERMISSION_ACTIONS.view]: [canViewRequestsModule],
    },
    [PERMISSION_FEATURES.metricsModule]: {
      [PERMISSION_ACTIONS.view]: [canViewMetricsModule],
    },
    [PERMISSION_FEATURES.dashboardModule]: {
      [PERMISSION_ACTIONS.view]: [canViewDashboardModule],
    },
    [PERMISSION_FEATURES.userDashbboard]: {
      [PERMISSION_ACTIONS.update]: [allowToCreateUpdateDahsboard],
      [PERMISSION_ACTIONS.create]: [allowToCreateUpdateDahsboard],
    },
    [PERMISSION_FEATURES.pageHeaderFilters]: {
      [PERMISSION_ACTIONS.view]: [canViewPageHeaderFiltersAndOptions],
    },
    [PERMISSION_FEATURES.pageHeaderOptions]: {
      [PERMISSION_ACTIONS.view]: [canViewPageHeaderFiltersAndOptions],
    },
    [PERMISSION_FEATURES.userViewCounts]: {
      [PERMISSION_ACTIONS.view]: [checks.isPaidAccount, () => checks.isFeatureFlagEnabled(FeatureFlags.HAS_USER_VIEWS_COUNTER)],
    },
    [PERMISSION_FEATURES.ideasGridTreeView]: {
      [PERMISSION_ACTIONS.view]: [
        checkFeatureFlagsEnabled(FeatureFlags.HAS_IDEAS_GRID_TREE_VIEW),
        ({ displayLayer, topLayer }) => displayLayer < topLayer,
      ],
    },
    [PERMISSION_FEATURES.customerRequestsDrawer]: {
      [PERMISSION_ACTIONS.view]: [() => !checks.userIsAnonymous()],
    },
    [PERMISSION_FEATURES.dbql]: {
      [PERMISSION_ACTIONS.view]: [() => checks.isFeatureFlagEnabled(FeatureFlags.HAS_DBQL_SEARCH)],
    },
    [PERMISSION_FEATURES.customFieldAppliesTo]: {
      [PERMISSION_ACTIONS.view]: [checks.isDoDActive],
    },
    [PERMISSION_FEATURES.customFieldColor]: {
      [PERMISSION_ACTIONS.view]: [checks.isDoDActive],
    },
  };
};

export default generateFeaturesPermissions;
