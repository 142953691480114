import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import CommentIcon from 'design-system/atoms/CommentIcon/index';
import PostListItem from 'design-system/organisms/PostListItem/index';
import MicroChip from 'design-system/atoms/MicroChip/index';
import SmallText from 'design-system/atoms/SmallText/index';

import IconButton from '@material-ui/core/IconButton';
import invertedTextColor from 'design-system/utils/invertedTextColor';
import Highlighter from 'react-highlight-words';
import { materialBackground, linkColor } from 'design-system/themes/default';
import DOMPurify from 'dompurify';

import VoteIconOutlined from '@material-ui/icons/ThumbUpOutlined';

import priorities from 'store/customerRequests/constants/priority';
import statuses from 'store/customerRequests/constants/status';

const PostChip = React.memo(styled(MicroChip)`
  &&&& {
    max-width: ${props => (props.fullWidth ? '100%' : '100px')};
    overflow: hidden;
    margin-left: 0;
  }
`);

const onClickFactory = (addCustomerRequestsFilter, field, value) => {
  return () => {
    addCustomerRequestsFilter(field, value);
  };
};

const CustomersComponent = React.memo(props => {
  const { checkFieldDisplay } = props;
  const _onClickComment = e => {
    e.preventDefault();
    e.stopPropagation();

    props.startEditingComment();
  };

  return (
    <>
      <CustomersWrapper>
        {checkFieldDisplay('comments') && (
          <ListItemButtonContainer>
            <ListItemButton onClick={_onClickComment} color="primary">
              <CommentIcon color={props.iconColor} height="12" width="14" />
            </ListItemButton>
            <ListItemCounter hidden={!props.item?.comments_count}>{props.item?.comments_count}</ListItemCounter>
          </ListItemButtonContainer>
        )}
        <PostChipWrapper>
          {((checkFieldDisplay('customers') && props.item.customers) || []).map(customer => {
            return (
              <PostChip
                key={customer.id}
                onClick={onClickFactory(props.addCustomerRequestsFilter, 'customer_ids', customer.id)}
                label={customer.name}
                color={customer.color}
                textColor={invertedTextColor(customer.color, true)}
              />
            );
          })}
        </PostChipWrapper>
      </CustomersWrapper>
    </>
  );
});

const RightSideComponent = React.memo(props => <CustomersComponent {...props} />);

export default React.memo(props => {
  const { checkFieldDisplay } = props;
  const _onClickVote = e => {
    e.preventDefault();
    e.stopPropagation();

    if (props.item?.votes?.had_vote) props.unvoteOnCustomerRequest(props.item.id);
    else props.voteOnCustomerRequest(props.item.id);
  };

  const _onClickTitle = () => {
    props.startEditingCustomerRequest();
  };

  const _renderTitle = () => {
    const key = `CR-${props.item.key}`;

    if (!props.search) return `${key} ${props.item.title}`;

    return (
      <>
        {key}{' '}
        <Highlighter
          highlightClassName="hightlightText"
          searchWords={[props.search]}
          autoEscape
          textToHighlight={props.item.title}
        />
      </>
    );
  };

  const _getIconColor = iconButton => {
    switch (iconButton) {
      case 'votes':
        return props.item?.votes?.count > 0 ? linkColor : materialBackground.hawkesBlue;
      case 'comments':
        return props.item?.comments_count > 0 ? linkColor : materialBackground.hawkesBlue;
      default:
        throw new Error('Invalid iconButton name.');
    }
  };

  const detailsField = checkFieldDisplay('details')
    ? ReactHtmlParser(DOMPurify.sanitize(props.item.details), { decodeEntities: true })
    : '';

  return (
    <ListItemComponentWrapper>
      {checkFieldDisplay('votes') && (
        <ListItemButtonGroupWrapper>
          <ListItemButtonGroup>
            <ListItemButtonContainer>
              <ListItemButton onClick={_onClickVote} color={_getIconColor('votes')}>
                <VoteIconOutlined fontSize="small" />
              </ListItemButton>
              <ListItemCounter hidden={props.item?.votes?.count === 0}>{(props.item.votes || {}).count}</ListItemCounter>
            </ListItemButtonContainer>
          </ListItemButtonGroup>
        </ListItemButtonGroupWrapper>
      )}
      <PostListItem
        title={_renderTitle()}
        details={detailsField}
        onClickTitle={_onClickTitle}
        rightSideComponent={() => <RightSideComponent iconColor={_getIconColor('comments')} {...props} />}
        item={props.item}
        addCustomerRequestsFilter={props.addCustomerRequestsFilter}
      >
        {checkFieldDisplay('status') && props.item.status && (
          <PostChip
            label={statuses[props.item.status].label}
            color={statuses[props.item.status].color}
            textColor={invertedTextColor(statuses[props.item.status].color, true)}
            onClick={onClickFactory(props.addCustomerRequestsFilter, 'status', props.item.status)}
          />
        )}
        {checkFieldDisplay('priority') && props.item.priority && (
          <PostChip
            label={priorities[props.item.priority].label}
            color={priorities[props.item.priority].color}
            variant="outlined"
            textColor={invertedTextColor(priorities[props.item.priority].color, true)}
            onClick={onClickFactory(props.addCustomerRequestsFilter, 'priority', props.item.priority)}
          />
        )}
        {checkFieldDisplay('created_by') && props.item.created_by && (
          <PostChip
            label={`Created by ${props.item.created_by.name}`}
            color="#fff"
            textColor={invertedTextColor(null, true)}
            fullWidth
            onClick={onClickFactory(props.addCustomerRequestsFilter, 'created_by_id', props.item.created_by.id)}
          />
        )}
        {checkFieldDisplay('roadmap') && props.item.roadmap && (
          <PostChip
            label={props.item.roadmap.title}
            color={props.item.roadmap.color}
            textColor={invertedTextColor(props.item.roadmap.color, true)}
            onClick={onClickFactory(props.addCustomerRequestsFilter, 'roadmap_id', props.item.roadmap.id)}
          />
        )}
        {checkFieldDisplay('owner') && props.item.owner && (
          <PostChip
            label={props.item.owner.name}
            textColor={invertedTextColor(null, true)}
            onClick={onClickFactory(props.addCustomerRequestsFilter, 'owner_id', props.item.owner.id)}
          />
        )}
        {((checkFieldDisplay('tags') && props.item.tags) || []).map(tag => {
          return (
            <PostChip
              key={tag.id}
              onClick={onClickFactory(props.addCustomerRequestsFilter, 'tag_ids', tag.id)}
              label={tag.title}
              color={tag.color}
              textColor={invertedTextColor(tag.color, true)}
            />
          );
        })}
      </PostListItem>
    </ListItemComponentWrapper>
  );
});

const ListItemComponentWrapper = styled.div`
  &&&& {
    display: flex;
  }
`;

const CustomersWrapper = styled.div`
  &&&& {
    align-items: flex-start;
    flex-direction: column;
    flex-basis: 250px;
    flex-shrink: 0;
    width: 250px;
  }
`;

const PostChipWrapper = styled.div`
  &&&& {
    display: flex;
    flex: 1;
    min-height: 25px;
  }
`;

const ListItemButtonGroupWrapper = styled.div`
  &&&& {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    min-width: 72px;
    max-width: 72px;
  }
`;

const ListItemButtonGroup = styled.div`
  &&&& {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ListItemButtonContainer = styled.div`
  &&&& {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 14px;
    margin-bottom: 4px;
  }
`;

const ListItemButton = styled(({ color, ...rest }) => <IconButton {...rest} />)`
  &&&& {
    min-width: 14px;
    min-height: 14px;
    margin-right: 4px;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    > span {
      display: flex;
      align-items: center;

      svg {
        font-size: 14px;
        color: ${props => props.color};
      }
    }

    &:hover {
      color: ${props => props.color};
    }
  }
`;

const ListItemCounter = styled(SmallText)`
  &&&& {
    display: ${props => (props.hidden ? 'none' : 'flex')};
    align-items: flex-start;
    height: 14px;
  }
`;
