import { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { getEnrichedCustomerRequest, getMetadata, getAllCustomerRequests } from 'store/customerRequests/selectors';

import { CUSTOMER_REQUESTS_AUTOCOMPLETE_PAGINATION_OPTIONS } from 'constants/customerRequests';

export default (filters, displayResultsIfNoQuery = false) => {
  const [customerRequests, setCustomerRequests] = useState([]);
  const metadata = useSelector(state => getMetadata(state));
  const byIdCustomerRequests = useSelector(getAllCustomerRequests);

  const _enrichedCustomerRequests = useSelector(state =>
    customerRequests.map(cr => getEnrichedCustomerRequest(cr.id, metadata, byIdCustomerRequests)),
  );

  const _loadCustomerRequests = value => {
    return new Promise(resolve => {
      if (!value && !displayResultsIfNoQuery) resolve([]);

      const params = {
        search: value,
        ...CUSTOMER_REQUESTS_AUTOCOMPLETE_PAGINATION_OPTIONS,
        ...filters,
      };

      axios.get('/api/customer-requests', { params }).then(({ data: responseData }) => {
        const { data } = responseData;

        setCustomerRequests(data);
        const result = data.map(p => ({
          entity: p,
          label: p.title,
          value: p.title,
        }));

        resolve(result);
      });
    });
  };

  return [_loadCustomerRequests, _enrichedCustomerRequests];
};
