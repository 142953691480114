import React, { useCallback } from 'react';

const container = Component => {
  return props => {
    const { orgIntegrationId, selectedType, onChange } = props;

    const onChangeHandler = useCallback(
      ({ target }) => {
        onChange(target.value);
      },
      [onChange],
    );

    return (
      <Component
        mappingLink={`/settings/integrations/jira/${orgIntegrationId}`}
        selectedType={selectedType}
        onChangeHandler={onChangeHandler}
      />
    );
  };
};

export default container;
