import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const MultiSelectDropdown2Columns = ({
  rightItems = [],
  leftItems = [],
  selected = [],
  onClose,
  onChange,
  handleShowAll,
  handleHideAll,
  renderLabel,
}) => {
  const handleCheckboxChange = (item, checked) => {
    if (checked) {
      onChange([...selected, item]);
    } else {
      const newSelected = selected.filter(it => it !== item);

      onChange(newSelected);
    }
  };

  return (
    <>
      <Header>
        <SelectionButtons>
          <Button onClick={handleShowAll}>Check all</Button>
          <Button onClick={handleHideAll}>Uncheck all</Button>
        </SelectionButtons>

        <IconButton>
          <CloseIcon onClick={onClose} />
        </IconButton>
      </Header>
      <Wrapper>
        {leftItems.map((item, i) => (
          <StyledFormControl left index={i}>
            <FormGroup>
              <FormControlLabel
                control={<StyledCheckbox color="primary" checked={selected.indexOf(item) > -1} />}
                label={renderLabel ? renderLabel(item) : item}
                value={item}
                onChange={(event, checked) => handleCheckboxChange(event.target.value, checked)}
              />
            </FormGroup>
          </StyledFormControl>
        ))}
        {rightItems.map((item, i) => (
          <StyledFormControl index={i}>
            <FormGroup>
              <FormControlLabel
                control={<StyledCheckbox color="primary" checked={selected.indexOf(item) > -1} />}
                label={renderLabel ? renderLabel(item) : item}
                value={item}
                onChange={(event, checked) => handleCheckboxChange(event.target.value, checked)}
              />
            </FormGroup>
          </StyledFormControl>
        ))}
      </Wrapper>
    </>
  );
};

MultiSelectDropdown2Columns.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default MultiSelectDropdown2Columns;

const Header = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Wrapper = styled.div`
  &&&& {
    display: grid;
    background-color: white;
    margin: 1rem;
    margin-top: 0;
  }
`;

const SelectionButtons = styled.div`
  &&&& {
    padding-left: 20px;
    grid-column: 1 / span 2;
    grid-row: 1 / 1;
    display: grid;
    grid-template-columns: auto auto;

    button {
      font-size: 0.875rem;
      color: #6b6b6b;
      text-transform: capitalize;
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  &&&& {
    width: 170px;
    grid-column: ${props => (props.left ? 1 : 2)};
    grid-row: ${props => props.index + 2};

    div {
      label {
        margin: 0;
      }
    }
  }
`;

const StyledCheckbox = styled(Checkbox)`
  &&&& {
    padding: 6px;
    svg {
      width: 0.8em;
    }
  }
`;
