import { getProjectIntegration } from './common';
import { INTEGRATIONS_KEYS } from 'constants/integrations';

export default (selectedProject, orgIntegrations, projectIntegrations) => {
  return !!orgIntegrations.find(orgIntegration => {
    const projectIntegration = getProjectIntegration(projectIntegrations, orgIntegration);

    if (orgIntegration.integrationType !== 'JIRA' && !projectIntegration) {
      return false;
    }

    switch (orgIntegration.integrationType) {
      case 'clubhouse':
        return true;
      case 'JIRA':
        const jiraEpic = selectedProject ? selectedProject.jira : null;

        if (jiraEpic) return true;
        break;
      case 'github':
        if (projectIntegration && projectIntegration.data.projects) {
          return true;
        }
        break;
      case 'azuredevops':
      case INTEGRATIONS_KEYS.rally:
        return true;
      default:
        break;
    }

    return false;
  });
};
