import { useDispatch, useSelector } from 'react-redux';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import usePermissions from 'hooks/permissions/usePermissions';
import { getMetricOrgIntegrations } from 'store/organization/selectors';
import { importMetricFromDataSource, unlinkMetricFromDataSource } from 'store/metrics/thunks';
import { getAvailableIntegrations } from 'features/OneStepIntegration/store';
import { METRIC_INTEGRATION_TYPES } from 'constants/integrations';

const useMetricsIntegrations = () => {
  const dispatch = useDispatch();

  const { canUpdate, canView } = usePermissions();

  const availableIntegrations = useSelector(getAvailableIntegrations);
  const metricOrgIntegrations = useSelector(getMetricOrgIntegrations);

  const canViewMetricIntegration = METRIC_INTEGRATION_TYPES.some(integrationType =>
    canView(PERMISSION_RESOURCES.metricIntegration, { integrationConfig: availableIntegrations[integrationType] }),
  );

  const canUpdateMetricIntegration = metricOrgIntegrations.some(orgIntegration => {
    return canUpdate(PERMISSION_RESOURCES.metricIntegration, {
      integrationConfig: availableIntegrations[orgIntegration.integrationType],
    });
  });

  const updateMetricIntegration =
    metricId =>
    ({ integrationType, orgIntegrationId, dataSource, entity, metricType, timePeriod, segment }) => {
      if (dataSource) {
        return dispatch(
          importMetricFromDataSource(
            integrationType,
            orgIntegrationId,
            metricId,
            dataSource,
            entity,
            metricType,
            timePeriod,
            segment,
          ),
        );
      }

      dispatch(unlinkMetricFromDataSource(integrationType, orgIntegrationId, metricId));
    };

  return {
    metricOrgIntegrations,
    canViewMetricIntegration,
    canUpdateMetricIntegration,
    updateMetricIntegration,
  };
};

export default useMetricsIntegrations;
