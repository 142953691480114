import React from 'react';
import styled from 'styled-components';
import isNumber from 'lodash/isNumber';

function countStoriesStatus(stories) {
  if (!stories) {
    return;
  }

  const total = stories.length;
  let inProgress = 0;
  let closed = 0;

  stories.forEach(story => {
    switch (story.status_category) {
      case '1': inProgress++; break;
      case '2': closed++; break;
      default: break;
    }
  });

  return {
    total,
    inProgress,
    closed,
  };
}

export default ({ stories }) => {
  if (!stories) {
    return <span />;
  }

  const {
    total,
    inProgress,
    closed,
  } = countStoriesStatus(stories);

  if (!isNumber(total) || !isNumber(inProgress) || !isNumber(closed)) return '';

  return (
    <Lower>
      Total: <span>{total}</span>,&nbsp;
      In Progress: <span className="material--blue">{inProgress}</span>,&nbsp;
      Closed: <span className="material--green">{closed}</span>
    </Lower>
  );
};

const Lower = styled.div`
  font-size: 11px;

  span{
    font-size: 12px;
  }
`;
