import React from 'react';
import styled from 'styled-components';
import { Rnd } from 'react-rnd';

import { materialColors } from 'design-system/themes/default';

import TooltipContent from './TooltipContent';
import { HtmlTooltip } from './styled';
import { snapToGridOptions } from '../../helpers';

const TimelineMilestone = React.memo(
  ({
    id,
    title,
    name,
    textColor,
    startDate,
    endDate,
    startDateEstimates,
    endDateEstimates,
    top,
    left,
    width,
    height,
    boundsname,
    openLightBox,
    onDoubleClick,
    onDragStop,
    onResizeStop,
    isLoading,
    showTooltip,
    milestoneDiamondColor,
    showTitle = true,
    snapToGridOn,
    tooltipLabels,
  }) => {
    const [open, setOpen] = React.useState(false);

    const showEstimatesDates = [snapToGridOptions.START_DATE_ESTIMATES, snapToGridOptions.END_DATE_ESTIMATES].includes(
      snapToGridOn,
    );

    const onOpenTooltip = () => {
      setOpen(true);
    };

    const onCloseTooltip = () => {
      setOpen(false);
    };

    const handleDragStop = (_, delta) => {
      // should not trigger the drag event on double click
      // prevent the project update when we only want to open the lightbox
      if (delta?.x === left && delta?.y === top) {
        return;
      }

      if (onDragStop) {
        onDragStop(delta);
      }
    };

    const handleResizeStop = (_, direction, __, delta, position) => {
      if (onResizeStop) {
        onResizeStop(direction, delta, position);
      }
    };

    const handleClick = event => {
      event.stopPropagation();
    };

    const handleDoubleClick = event => {
      if (openLightBox) {
        openLightBox(id);
      }

      if (onDoubleClick) {
        onDoubleClick(id);
      }

      event.stopPropagation();
    };

    return (
      <StyledRnd
        default={{
          x: left,
          y: top,
          width,
          height,
        }}
        position={{
          x: left,
          y: top,
        }}
        size={{
          width: 34,
          height: 34,
        }}
        enableResizing={{
          left: false,
          right: false,
        }}
        disableDragging={isLoading}
        dragGrid={[1, 35]}
        bounds={`.${boundsname}`}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        onDragStart={onCloseTooltip}
        onDragStop={handleDragStop}
        onResizeStart={onCloseTooltip}
        onResizeStop={handleResizeStop}
      >
        <HtmlTooltip
          open={showTooltip && open}
          onOpen={onOpenTooltip}
          onClose={onCloseTooltip}
          title={
            <TooltipContent
              name={name}
              title={title}
              startDate={showEstimatesDates ? startDateEstimates : startDate}
              endDate={showEstimatesDates ? endDateEstimates : endDate}
              tooltipLabels={tooltipLabels}
            />
          }
        >
          <DiamondWrapper style={{ backgroundColor: milestoneDiamondColor ?? materialColors.mediumPurple, color: textColor }}>
            {showTitle ? <BarContent showTextOverflow>{title}</BarContent> : null}
          </DiamondWrapper>
        </HtmlTooltip>
      </StyledRnd>
    );
  },
);

TimelineMilestone.displayName = 'TableMilestone';

export default TimelineMilestone;

const StyledRnd = styled(Rnd)`
  z-index: 50;
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

const DiamondWrapper = styled.div`
  &&&& {
    height: 20px;
    width: 20px;
    margin-top: 7px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    transform: rotateZ(45deg);
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);

    background-color: ${materialColors.mediumPurple};
  }
`;

const BarContent = styled.p`
  &&&& {
    max-width: 100%;
    height: 26px;
    padding-left: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: none;
    font-size: 12px;
    font-stretch: 100%;
    font-weight: normal;
    transform: rotateZ(-45deg);

    overflow: ${({ showTextOverflow }) => (showTextOverflow ? 'unset' : 'hidden')};
  }
`;
