import cloneDeep from 'lodash/cloneDeep';

export const getTaskText = (task, parentProject, showAllocation, hideTasksName) => {
  if (!task || !parentProject) return '';

  let text = `${parentProject.title}${!hideTasksName ? ` : ${task.title}` : ''}`;

  if (showAllocation) text = `[${task.ownerAllocation}%] ${text}`;

  return text;
};

export const getStaffingFiltersForApi = filters => {
  const _mergeFiltersWithQuickFilters = _pageFilters => {
    const result = cloneDeep(_pageFilters);

    if (_pageFilters && _pageFilters.quickFilters) {
      Object.keys(_pageFilters.quickFilters).forEach(filterKey => {
        const value = _pageFilters.quickFilters[filterKey];

        if (value && value.length) result[filterKey] = value;
      });
    }

    return result;
  };
  const pageFilters = _mergeFiltersWithQuickFilters(filters);

  const _getFiltersForApi = mapper =>
    Object.keys(pageFilters).reduce((acc, key) => {
      if (!Object.keys(mapper).includes(key)) return acc;
      acc[mapper[key]] = pageFilters[key].join();
      return acc;
    }, {});

  const usersFilters = _getFiltersForApi({
    teams: 'team_id',
    skills: 'skill_id',
    users: 'id',
  });
  const taksFilters = _getFiltersForApi({
    teams: 'owner__team_id',
    skills: 'owner__skill_id',
    users: 'owner_id',
    displayLayer: 'project__layer',
  });

  return [usersFilters, taksFilters];
};
