import React, { useState } from 'react';
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ViewName from 'design-system/molecules/ViewName/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';
import theme, { spacing } from 'design-system/theme';

import parseHtml from 'utils/parseHtml';
import { formatNumber } from 'utils/index';
import getUserViewsVisits from 'utils/userViews/getUserViewsVisits';

import ViewIconWithAvatar from './ViewIconWithAvatar';
import UpdateDate from './UpdateDate';
import ActionMenu from './ActionMenu';

export default ({
  item,
  handleFavoriteUserView,
  handleActionClick,
  getActionIcon,
  isFavorite,
  onSelectView,
  showUserViewCounts,
}) => {
  const [showUpdateDate, setShowUpdateDate] = useState(false);

  const { allTimeViews } = getUserViewsVisits(item);

  const viewsCounterValue = allTimeViews;

  return (
    <StyledListItem onMouseOver={() => setShowUpdateDate(true)} onMouseOut={() => setShowUpdateDate(false)}>
      <ListItemContainer onClick={() => onSelectView(item)}>
        <ViewIconWithAvatar item={item} />
        <TextWrapper>
          <ListItemText
            primary={<ViewName selectedView={item} viewOwner={item?.user} />}
            primaryTypographyProps={{
              style: {
                color: theme.palette.text.primary,
                cursor: 'pointer',
                fontSize: theme.typography.fontSizeSmallLarge,
                fontWeight: theme.typography.fontWeightMedium,
              },
            }}
            truncateText
          />
          <TooltipOnOverflow text={parseHtml(item.description)} tooltipPlacement="bottom-start" vertical>
            <StyledText>{parseHtml(item.description)}</StyledText>
          </TooltipOnOverflow>
        </TextWrapper>

        <StyledUpdateDate item={item} isVisible={showUpdateDate} />

        {showUserViewCounts && !!viewsCounterValue && (
          <ViewsCounter>
            <p>{formatNumber(viewsCounterValue)}</p>
          </ViewsCounter>
        )}
      </ListItemContainer>
      {!item.is_static && (
        <ActionMenu
          item={item}
          handleFavoriteUserView={handleFavoriteUserView}
          handleActionClick={handleActionClick}
          getActionIcon={getActionIcon}
          isFavorite={isFavorite}
          shareButtonId="homepage-share-view-button"
        />
      )}
    </StyledListItem>
  );
};

const StyledListItem = styled(ListItem)`
  border-radius: ${theme.shape.borderRadiusExtraLarge}px;
  transition: background-color 0.2s;

  && {
    justify-content: space-between;
  }

  &:hover {
    background-color: ${theme.palette.newLayout.background.transparentBlack};
  }
`;

const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const TextWrapper = styled.div`
  display: grid;
  margin-left: ${spacing(2)}px;
  max-width: 580px;
`;

const StyledText = styled(TextDeprecated)`
  &&&& {
    color: ${({ theme }) => theme.palette.newLayout.text.siroccoGrey};
    max-width: 90%;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const StyledUpdateDate = styled(UpdateDate)`
  &&&& {
    min-width: 120px;
  }
`;

const ViewsCounter = styled.div`
  margin-left: auto;

  p {
    min-width: ${spacing(11.25)}px;
    margin-left: ${spacing(2)}px;
    margin-right: ${spacing(1)}px;

    text-align: right;
    color: ${({ theme }) => theme.palette.newLayout.text.siroccoGrey};
    font-size: ${theme.typography.fontSize}px;
  }
`;
