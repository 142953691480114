import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

import Dialog from 'design-system/molecules/Dialog/index';
import Text from 'design-system/atoms/Text/index';

import theme from 'design-system/theme';

const RequiredFieldsWarningPopup = ({ isOpen, onClose, continueAndSave }) => {
  const handleIgnoreAndContinue = () => {
    onClose();
    continueAndSave();
  };

  return (
    <Dialog
      id="required-fields-warning-popup"
      data-test="required-fields-warning-popup"
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      scroll="paper"
      closeButton
      actions={
        <>
          <Button onClick={onClose} color="primary">
            Edit Now
          </Button>
          <Button onClick={handleIgnoreAndContinue} color="primary">
            Save and Close
          </Button>
        </>
      }
    >
      <DialogWrapper>
        <Grid container style={{ marginTop: 15 }}>
          <Grid item xs={9}>
            <StyledText>Some fields required by your admin have no value.</StyledText>
          </Grid>
        </Grid>
      </DialogWrapper>
    </Dialog>
  );
};

const DialogWrapper = styled.div`
  min-width: 600px;
`;

const StyledText = styled(Text)`
  color: ${theme.palette.text.error};
`;

export default RequiredFieldsWarningPopup;
