import styled from 'styled-components';

const TableCellContainer = styled.div`
  &&&& {
    width: 100%;
    height: 100%;

    border-bottom: 1px solid ${({ theme }) => theme.palette.border.mercury};

    ${({ color }) =>
      color
        ? `
      border-left: 2px solid ${color};
    `
        : `
      padding-left: 2px;
    `}

    ${({ gray, theme }) =>
      gray
        ? `
      background: ${theme.palette.background.lightestGray};
    `
        : `
      background: ${theme.palette.background.white};
    `}

    ${({ center }) =>
      center &&
      `
      display: flex;
      align-items: center;
    `}

    ${({ bordered, theme }) =>
      bordered &&
      `
    border-right: 1px solid ${theme.palette.border.mercury};
  `}
  }
`;

TableCellContainer.displayName = 'TableCellContainer';

export default TableCellContainer;
