import { REQUEST_FORM_FIELDS } from '../constants';

const getFieldsAvailableToBePromoted = ({ customFields = [] }) => {
  const customFieldsKeys = customFields.map(field => field.key);

  return [
    REQUEST_FORM_FIELDS.details,
    REQUEST_FORM_FIELDS.tags,
    REQUEST_FORM_FIELDS.customers,
    REQUEST_FORM_FIELDS.owner,
    REQUEST_FORM_FIELDS.roadmap,
    REQUEST_FORM_FIELDS.product1,
    REQUEST_FORM_FIELDS.product2,
    REQUEST_FORM_FIELDS.lifecycles,
    REQUEST_FORM_FIELDS.personas,
    REQUEST_FORM_FIELDS.links,
    ...customFieldsKeys,
  ];
};

export default getFieldsAvailableToBePromoted;
