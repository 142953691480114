import React from 'react';
import styled from 'styled-components';
import { pluck } from 'ramda';

import theme from 'design-system/theme';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import SelectableItem from 'design-system/molecules/SelectableItem/index';
import SelectableItemWithSelect from 'design-system/molecules/SelectableItemWithSelect/index';

import Stars from 'design-system/atoms/DragonIcons/Stars';
import { StyledButton } from '../styled';
import panelBackground from './background.svg';

const getLabels = pluck('label');

const FormScreen = ({
  recommendedPlanningItems,
  otherPlanningItems,
  selectedPlanOrderBy,
  selectPlanOrderBy,
  selectedOptions,
  handleSelectOption,
  generatePlan,
}) => {
  const renderRecommendedPlanningItems = () =>
    recommendedPlanningItems.map(item => (
      <StyledSelectableItem
        label={item.label}
        selected={selectedPlanOrderBy === item.key}
        onChange={() => selectPlanOrderBy(item.key)}
      />
    ));

  const renderOtherPlanningItems = () =>
    otherPlanningItems.map(item => {
      const selectedOption = item?.options?.find(option => option?.key === selectedOptions[item?.key]);

      return (
        <StyledSelectableItemWithSelect
          label={item.label}
          selected={selectedPlanOrderBy === item.key}
          onChange={() => selectPlanOrderBy(item.key)}
          selectedOption={selectedOption?.label}
          options={getLabels(item?.options)}
          onSelectChange={selectedOption => handleSelectOption(item, selectedOption)}
        />
      );
    });

  return (
    <Flex>
      <Left>
        <Background src={panelBackground} />
      </Left>
      <Right>
        <Content>
          <Stars />
          <Title component="h2" color={theme.palette.text.black}>
            Generate a Resource Scenario Plan
          </Title>
          <StyledText variant="body1" color={theme.palette.text.black} breakwords>
            Use your data, settings, and our expert AI to create optimized secure scenarios in minutes. Compare and customize
            plans to meet your goals and team needs with our tools.
          </StyledText>
          <FormContainer>
            <FormGroup>
              <TextDeprecated variant="bold" size="small" color={theme.palette.text.black}>
                Recommended Planning Strategies
              </TextDeprecated>
              {renderRecommendedPlanningItems()}
            </FormGroup>
            <FormGroup>
              <TextDeprecated variant="bold" size="small" color={theme.palette.text.black}>
                Maximize based on other strategies
              </TextDeprecated>
              {renderOtherPlanningItems()}
            </FormGroup>
            <StyledButton
              background={theme.palette.newLayout.background.duskBlue}
              color={theme.palette.newLayout.background.white}
              hover={{ background: theme.palette.newLayout.background.dodgerBlue }}
              fontSize={theme.typography.fontSize}
              onClick={generatePlan}
              width="200px"
            >
              Generate Plan
            </StyledButton>
          </FormContainer>
        </Content>
      </Right>
    </Flex>
  );
};

export default FormScreen;

const Flex = styled.div`
  display: flex;
  height: 100%;
`;

const Left = styled.div`
  width: 45%;
  background-color: ${({ theme }) => theme.palette.newLayout.background.concrete};
  position: relative;
  overflow: hidden;
`;

const Background = styled.img`
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

const Right = styled.div`
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing.unit * 10}px;
`;

const Content = styled.div`
  max-width: 450px;
`;

const Title = styled(TextDeprecated)`
  && {
    font-size: ${({ theme }) => theme.typography.fontSizeH4}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    margin-top: ${props => props.theme.spacing.unit * 3}px;
  }
`;

const StyledText = styled(TextDeprecated)`
  && {
    font-size: ${({ theme }) => theme.typography.fontSize}px;
    margin-top: ${props => props.theme.spacing.unit}px;
  }
`;

const FormContainer = styled.div``;

const FormGroup = styled.div`
  margin-top: ${props => props.theme.spacing.unit * 4}px;
`;

const StyledSelectableItem = styled(SelectableItem)`
  max-width: 350px;
  margin-top: ${props => props.theme.spacing.unit}px;
`;

const StyledSelectableItemWithSelect = styled(SelectableItemWithSelect)`
  max-width: 350px;
  margin-top: ${props => props.theme.spacing.unit}px;
`;
