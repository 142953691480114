import axios from 'axios';
import { toast } from 'react-toastify';

import { fetchUserIntegrations } from 'store/login/actions';
import { fetchOrgIntegrations } from 'store/organization';
import { fetchCustomers, resetCustomerCount } from 'store/customers';

import { SALESFORCE_AUTH, SALESFORCE_CUSTOMER_COUNT, SALESFORCE_IMPORT_CUSTOMERS } from '../types';

const authenticateOnSalesforce = id => {
  return dispatch => {
    const payload = axios
      .get(`/api/salesforce/${id}/auth`)
      .then(async res => {
        window.open(res.data, 'Salesforce Authentication', 'height=700,width=1100');

        await window.addEventListener(
          'message',
          async ({ data }) => {
            // get code returned from auth callback
            if (data && data.code) {
              const response = await axios.post(`/api/salesforce/auth/callback`, {
                code: data.code,
                integrationId: id,
              });

              const { data: responseData } = response;

              dispatch(fetchOrgIntegrations());

              if (responseData.success) {
                dispatch(fetchUserIntegrations());
                toast(responseData?.message, { type: 'success' });
              } else {
                toast(responseData?.message, { type: 'error' });
              }
            }
          },
          false,
        );
      })
      .catch(err => {
        console.error('Salesforce Auth Failed', err);
        toast('Authentication Failed', { type: 'error' });
      });

    return { type: SALESFORCE_AUTH, payload };
  };
};

// TODO: MAKE GENERIC AND MOVE TO ONE STEP FEATURE
const fetchCustomersFromSalesforce = (integrationId, queryId) => {
  const payload = axios
    .post(`/api/integrations/salesforce/${integrationId}/customers/import-count`, { queryId })
    .then(res => res.data)
    .catch(err => err.response.data);

  return {
    type: SALESFORCE_CUSTOMER_COUNT,
    payload,
  };
};

const importCustomersFromSalesforce = (integrationId, queryId) => {
  return dispatch => {
    const payload = axios
      .post(`/api/integrations/salesforce/${integrationId}/customers/import`, { queryId })
      .then(res => {
        toast(res.data, { success: true });
        dispatch(fetchCustomers());
        return res.data;
      })
      .catch(err => {
        dispatch(resetCustomerCount());
        return err.message;
      });

    return {
      type: SALESFORCE_IMPORT_CUSTOMERS,
      payload,
    };
  };
};

export { authenticateOnSalesforce, fetchCustomersFromSalesforce, importCustomersFromSalesforce };
