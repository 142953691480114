import React from 'react';
import styled from 'styled-components';

import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete';
import { spacing } from 'design-system/theme';

const DisplayTimeline = ({ selectedSnapToGridOn, changeSelectedSnapToGridOn, snapToGridOnOptions }) => {
  return (
    <StyledGroupByAutocomplete
      name="display-timeline"
      suggestions={snapToGridOnOptions}
      value={selectedSnapToGridOn}
      onChange={changeSelectedSnapToGridOn}
      label="Display"
    />
  );
};

export default DisplayTimeline;

const StyledGroupByAutocomplete = styled(GroupByAutocomplete)`
  &&&&& {
    margin-left: ${spacing(1.5)}px;
  }
`;
