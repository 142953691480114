import { css } from 'styled-components';

import { materialColors, linkColor } from 'design-system/themes/default';

const agGridStylesOverride = css`
  .ag-layout-normal.ag-root {
    border: none;
    margin: 0;
  }

  .ag-cell > div > span {
    width: 100%;
    display: inline-block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .ag-cell:not(.ag-react-editable-container--expandable) {
    white-space: normal;
  }

  .ag-cell > div {
    height: 100%;
  }

  .first-field .ag-cell-label-container {
    padding: 0 10px 0 10px;
  }

  .centered-header .ag-cell-label-container {
    display: flex !important;
    justify-content: center;
  }

  .ag-header-cell-text-centered {
    .ag-header-cell-text {
      flex: 1;
      text-align: center;
    }
  }

  .centered-header .ag-header-cell-label {
    position: absolute;
    justify-content: center;
    width: auto;
  }

  .centered-header .ag-header-icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .ag-group-value .ag-react-container {
    display: inline !important;
  }

  .ag-row-group-leaf-indent {
    margin-left: 10px !important;
  }

  .ag-row-hover {
    background-color: ${materialColors.lightGray} !important;
  }

  .ag-cell-focus {
    border: 1px solid ${linkColor} !important;
  }

  .ag-row-level-0 .ag-group-expanded.ag-hidden + .ag-group-contracted.ag-hidden ~ .ag-group-checkbox:not(.ag-hidden) {
    padding-left: 26px;
  }

  .ag-row-checkbox-disabled .ag-selection-checkbox .ag-icon-checkbox-unchecked {
    pointer-events: none;
    opacity: 0.2;
  }

  .ag-body-container .ag-row-drag {
    width: 20px;
    margin-left: -2px !important;
    cursor: grab;
    transition: opacity 150ms;
    opacity: 0;
  }

  .ag-body-container .ag-row.ag-row-hover .ag-row-drag {
    opacity: 1;
  }

  .ag-cell-inline-editing .ag-cell-editor .ag-wrapper {
    padding: 6px;
  }

  .ag-input-wrapper {
    align-items: flex-start;
  }

  div.ag-cell,
  .ag-cell p,
  .ag-cell span,
  .ag-cell div {
    color: rgb(107, 107, 107);
    font-size: 12px;
  }

  .ag-cell div.red span,
  .ag-cell div.red p {
    color: rgb(255, 0, 0) !important;
  }

  .ag-header-cell-filtered {
    background-color: #cae1fa !important;
  }

  .ag-cell-inline-editing.padding-0 {
    padding: 0 !important;
  }

  .ag-cell-inline-editing.border-0 {
    border: 0 !important;
  }

  .ag-cell {
    display: flex;
    align-items: center;
  }

  // DASHBOARDS SPECIFIC

  .ag-header-row {
    background-color: #f8fafb;
    height: 60px !important;
  }

  .ag-header-row .ag-header-cell {
    color: #161616;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px !important;
    padding: 21px 0 21px 12px !important;
  }

  .ag-row .ag-cell > div p,
  .ag-row .ag-cell > p {
    font-size: 14px !important;
  }

  // METRIC PROJECTS SPECIFIC

  .ag-title-wrapper .ag-group-value {
    display: flex;
  }
`;

export default agGridStylesOverride;
