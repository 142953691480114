import React from 'react';
import 'containers/Dragonbot/chatbot.css';
import styled from 'styled-components';
import chatbotTheme from 'containers/Dragonbot/dragonbot.theme';

const Header = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.headerBgColor};
  color: ${({ theme }) => theme.headerFontColor};
  display: flex;
  fill: ${({ theme }) => theme.headerFontColor};
  height: 56px;
  justify-content: space-between;
  padding: 0 10px;
`;

Header.defaultProps = {
  theme: chatbotTheme,
};

const HeaderTitle = styled.h2`
  margin: 0;
  font-size: ${({ theme }) => theme.headerFontSize};
`;

HeaderTitle.defaultProps = {
  theme: chatbotTheme,
};

const ChatHeaderComponent = () => {
  return (
    <BotHeader className="react-chatbot-kit-chat-header-container">
      <HeaderBlock style={{ flex: 1, flexGrow: 5 }}>
        <HeaderTitle className="rsc-header-title">Discovery Bot</HeaderTitle>
      </HeaderBlock>
    </BotHeader>
  );
};

const HeaderBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BotHeader = styled(Header)`
  background: transparent linear-gradient(117deg, rgb(107, 226, 220) 0%, rgb(67, 190, 223) 100%) 0% 0% no-repeat padding-box;
  height: 50px;
`;

export default ChatHeaderComponent;
