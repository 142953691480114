import axios from 'axios';

import {
  FETCH_PRIORITIES,
  UPDATE_PRIORITY,
  UPDATE_PRIORITY_BY_ID,
  CREATE_PRIORITY,
  ADD_PRIORITY_WITHOUT_SAVE,
  REMOVE_UNSAVED_PRIORITIES,
  CREATE_PRIORITY_FULFILLED,
  DELETE_PRIORITY_FULFILLED,
  UPDATE_PRIORITY_FULFILLED,
  BULK_DELETE_PRIORITY_ROADMAP,
  CREATE_PRIORITY_ROADMAP,
  DELETE_PRIORITY_ROADMAP,
} from './types';

import fetchMetadataAction from 'store/utils/factory/fetchMetadataAction';
import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';

export const fetchPriorities = fetchMetadataAction(
  FETCH_PRIORITIES,
  '/api/priorities',
  state => state.priorities,
  state => state.priorities.lastCallsDate,
);

export const updatePriority = priority => {
  return {
    payload: axios.post(`/api/priorities/update/${priority.id}`, priority).then(res => res.data),
    type: UPDATE_PRIORITY,
  };
};

export const updatePriorityById = (id, priority) => {
  return {
    payload: axios.put(`/api/priorities/${id}`, priority).then(res => res.data),
    type: UPDATE_PRIORITY_BY_ID,
  };
};

export const createPriority = priority => {
  return {
    payload: axios.post('/api/priorities/', priority).then(res => res.data),
    type: CREATE_PRIORITY,
  };
};

export const createPriorityRoadmap = (priorityId, priorityRoadmap) => {
  return {
    payload: axios.post(`/api/priorities/${priorityId}/priorityRoadmaps/`, priorityRoadmap).then(response => response.data),
    type: CREATE_PRIORITY_ROADMAP,
  };
};

export const deletePriorityRoadmap = ({ id, roadmapId, subRoadmapId = null, product2Id = null }) => {
  let route = `/api/priorities/${id}/priorityRoadmaps/${roadmapId}`;

  route += subRoadmapId ? `/${subRoadmapId}` : '';
  route += subRoadmapId && product2Id ? `/${product2Id}` : '';

  return {
    payload: axios.delete(route).then(response => response.data),
    type: DELETE_PRIORITY_ROADMAP,
  };
};

export const bulkDeletePriorityRoadmaps = priorityId => {
  return {
    payload: axios.delete(`/api/priorities/${priorityId}/priorityRoadmaps/`).then(response => response.data),
    type: BULK_DELETE_PRIORITY_ROADMAP,
  };
};

export const addPriorityWithoutSave = priority => ({
  type: ADD_PRIORITY_WITHOUT_SAVE,
  priority,
});

export const removeUnsavedPriorities = _ => ({
  type: REMOVE_UNSAVED_PRIORITIES,
});

export const prioritiesActions = {
  fetchPriorities,
};

export const gotPrioritiesRealtimeUpdate = generateRealtimeUpdateAction(
  CREATE_PRIORITY_FULFILLED,
  DELETE_PRIORITY_FULFILLED,
  UPDATE_PRIORITY_FULFILLED,
);
