import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import theme, { spacing } from 'design-system/theme';

export default function FieldMappingHeader({ integrationNameLabel }) {
  return (
    <Wrapper container style={{ alignItems: 'center' }}>
      <Grid item xs={5} style={{ textAlign: 'right' }}>
        <HeaderText>Dragonboat Field</HeaderText>
      </Grid>
      <Grid item xs={1} style={{ textAlign: 'center' }}>
        <span>←</span>
      </Grid>
      <Grid item xs={6}>
        <HeaderText>{integrationNameLabel} Field</HeaderText>
      </Grid>
    </Wrapper>
  );
}

const Wrapper = styled(Grid)`
  &&&& {
    position: sticky;
    top: 0;
    z-index: 1000;
    margin-bottom: ${spacing(2)}px;
    background: #ffff;
    align-items: center;
    width: 100%;
  }
`;

const HeaderText = styled.span`
  color: ${theme.palette.text.secondary};
  font-size: ${theme.typography.fontSizeSmallRem}rem;
  font-weight: ${theme.typography.fontWeightBold};
  text-transform: uppercase;
`;
