import React from 'react';

import FieldsLayoutConfigurator from 'design-system/organisms/FieldsLayoutConfigurator/index';
import Text from 'design-system/atoms/Text/index';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { ButtonsContainer, Header, Wrapper } from './styles';

const SAVE_BUTTON_ID = 'save-fields-layout-';
const CLOSE_BUTTON_ID = 'close-fields-layout-';

export default ({
  type,
  onClose,
  fieldslayout,
  editable,
  onChange,
  onSave,
  enableRequiredFields,
  canFieldBeRequired,
  itemRightActions,
}) => {
  return (
    <Wrapper>
      <Header>
        <Text variant="body2" color="textSecondary">
          {editable && 'Drag and drop to organize/hide fields'}
        </Text>
        <IconButton>
          <CloseIcon onClick={onClose} fontSize="small" />
        </IconButton>
      </Header>
      <FieldsLayoutConfigurator
        onChange={onChange}
        fields={fieldslayout}
        editable={editable}
        enableRequiredFields={enableRequiredFields}
        canFieldBeRequired={canFieldBeRequired}
        itemRightActions={itemRightActions}
      />
      <ButtonsContainer>
        {editable && (
          <Button id={`${SAVE_BUTTON_ID}${type}`} onClick={onSave} color="primary">
            save
          </Button>
        )}
        {!editable && (
          <Button id={`${CLOSE_BUTTON_ID}${type}`} onClick={onClose} color="primary">
            close
          </Button>
        )}
      </ButtonsContainer>
    </Wrapper>
  );
};
