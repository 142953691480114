import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';

import MultiSelectColumns from './MultiSelectColumns';
import GroupHead from './GroupHead';
import { GroupContainer } from './styled';

const GroupWithMultiSelect = ({ group, changeColumnVisible, visibleColumns, checkFieldVisible, hideColumns, className }) => {
  const _renderMultiSelect = () => (
    <MultiSelectColumns
      visibleColumns={visibleColumns}
      groupFields={group.fields}
      checkFieldVisible={checkFieldVisible}
      changeColumnVisible={changeColumnVisible}
      hideColumns={hideColumns}
    />
  );

  return (
    <GroupContainer className={className}>
      {group.title && <GroupHead group={group} />}
      <FormGroup row>{_renderMultiSelect()}</FormGroup>
    </GroupContainer>
  );
};

export default GroupWithMultiSelect;
