import React from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { getDisplayLayer, makeSelectFilteredProjectsByRoadmapForPage } from 'store/filters/selectors';
import { PORTFOLIO_REPORT_PAGE } from 'constants/filters';
import useLoadProjects from 'hooks/projects/useLoadProjects';

import useReportTableData from './hooks/useReportTableData';
import useCleanSelectedGroups from './hooks/useCleanSelectedGroups';

const getFilteredProjectsForPage = makeSelectFilteredProjectsByRoadmapForPage();

const componentHOC = Component => {
  return props => {
    const displayLayer = useSelector(state => getDisplayLayer(state));
    const projects = useSelector(state => getFilteredProjectsForPage(state, PORTFOLIO_REPORT_PAGE, true, displayLayer), isEqual);

    useLoadProjects(PORTFOLIO_REPORT_PAGE);
    useCleanSelectedGroups();

    const [tableData] = useReportTableData(projects);

    return <Component {...props} tableData={tableData} />;
  };
};

export default componentHOC;
