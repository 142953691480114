import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import IdeasList from 'containers/IdeasList/IdeasList';
import Pagination from 'design-system/organisms/Pagination/index';

import { topics } from 'constants/topics';
import { getGridConfigValue } from 'store/grids/selectors';
import useSubscribeNewData from 'hooks/useSubscribeNewData';
import useIdeasGridFlatData from './hooks/useIdeasGridFlatData';
import { IDEAS } from 'store/grids/constants';
import IdeasListControlsBar from 'containers/IdeasList/IdeasListControlsBar';
import { IDEAS_GRID_VIEWS } from 'constants/ideas';
import { IDEAS_FLAT_GRID_PAGE } from 'constants/filters';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { PAGE_HEADER_HEIGHT, PAGE_SIDE_MARGIN } from 'constants/common';
import { TOOLBAR_HEIGHT } from 'constants/grid';
import { PAGE_LIMIT_OPTIONS, DEFAULT_PAGINATION_LIMIT } from 'constants/projects';

import useIdeasGridFlatPagination from './hooks/useIdeasGridFlatPagination';

const viewType = IDEAS;
const pageId = IDEAS_FLAT_GRID_PAGE;

const PAGINATION_OFFSET = 56;
const OFFSET = PAGE_HEADER_HEIGHT + TOOLBAR_HEIGHT + PAGINATION_OFFSET;
const DEFAULT_TABLE_HEIGHT = `calc(100vh - ${OFFSET}px )`;

const IdeasGridFlat = ({ viewsDropdownTabs }) => {
  const [grid, setGrid] = useState(null);
  const bulkDelete = useSelector(state => getGridConfigValue(state, viewType, 'bulkDelete'));
  const searchText = useSelector(state => getGridConfigValue(state, viewType, 'searchText'));
  const showMyItemsOnly = useSelector(state => getGridConfigValue(state, viewType, 'showMyItemsOnly'));

  const { canView } = usePermissions();

  const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

  useSubscribeNewData(
    [
      'app',
      'projects',
      'tasks',
      'estimates',
      'phases',
      'roadmaps',
      topics.OBJECTIVES,
      topics.KEY_RESULTS,
      topics.METRICS,
      'users',
      'customFields',
      'timeframes',
      'priorities',
    ],
    false,
  );

  const data = useIdeasGridFlatData({ bulkDelete, searchText, showMyItemsOnly });

  const {
    handleFetchData,
    handleChangePage,
    handleChangePageLimit,
    isFetchingProjects,
    totalProjects,
    currentPage,
    pageLimit,
    lastPage,
  } = useIdeasGridFlatPagination({ pageId });

  const _onGridReady = useCallback(params => setGrid({ gridApi: params.api, columnApi: params.columnApi }), []);
  const _getGridApi = useCallback(() => grid, [grid]);

  useEffect(() => {
    handleFetchData({ page: currentPage });
  }, []);

  return (
    <>
      {shouldDisplayControlsBar ? (
        <IdeasListControlsBar
          viewType={viewType}
          availableViews={IDEAS_GRID_VIEWS}
          pageId={pageId}
          numberOfRows={data.length}
          getGridApi={_getGridApi}
          usePortolioMode
          showPdlcFields
          showEstimatesFields={false}
          showAdditionalFields // to remove once new grid has additional fields implemented
          showCustomFields
          hasImport
          hasBulkUpdate
          hasBulkDelete
          hasViews
          hasGroupBy
          showMyItemsOnly={showMyItemsOnly}
          viewsDropdownTabs={viewsDropdownTabs}
          hasShowMyItemFilter
          hasRoadmapHistory
        />
      ) : (
        <EmptyHeight />
      )}
      <IdeasList data={data} viewType={viewType} treeData={false} onGridReady={_onGridReady} gridHeight={DEFAULT_TABLE_HEIGHT} />
      <PaginationWrapper>
        <Pagination
          currentPage={currentPage - 1}
          handleChangePage={handleChangePage}
          handleChangePageLimit={handleChangePageLimit}
          includePagination={!isFetchingProjects && totalProjects > pageLimit}
          lastPage={lastPage}
          pageLimit={pageLimit}
          resultsCount={totalProjects}
          rowsPerPageOptions={[DEFAULT_PAGINATION_LIMIT]}
          pageLimitOptions={PAGE_LIMIT_OPTIONS}
        />
      </PaginationWrapper>
    </>
  );
};

const EmptyHeight = styled.div`
  height: 48px;
`;

const PaginationWrapper = styled.div`
  margin: 0 64px 0 ${PAGE_SIDE_MARGIN}px;
`;

export default IdeasGridFlat;
