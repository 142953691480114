import React from 'react';

import TimelineLightbox from 'containers/TimelineLightbox';
import { ALLOCATION_REPORT_PAGE } from 'constants/filters';

export default ({ projects, timelineEntity, cleanTimelineEntity, dataType }) => {
  const [openTimelineLightbox, setOpenTimelineLightbox] = React.useState();

  const timelineIdeas = React.useMemo(() => {
    if (!timelineEntity) return [];

    const _filterProjects = p => {
      if (timelineEntity?.id === null) return p[dataType.key] === null;

      return p[dataType.key]?.id === timelineEntity?.id;
    };

    return projects.filter(_filterProjects);
  }, [projects, dataType.key, timelineEntity]);

  React.useEffect(() => {
    if (timelineEntity) openTimelineLightbox();
  }, [timelineEntity, openTimelineLightbox]);

  return (
    <TimelineLightbox
      onOpen={f => setOpenTimelineLightbox(() => f)}
      projects={projects}
      entityGroup={dataType}
      groupedBy={{ ...timelineEntity, ideas: timelineIdeas }}
      pageId={ALLOCATION_REPORT_PAGE}
      onClose={cleanTimelineEntity}
      hideMaxItemDisplayMessage
    />
  );
};
