import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import { FeatureFlags } from '@dragonboat/config';

import useFeatureFlags from 'hooks/useFeatureFlags';

import {
  generateAutoTags,
  selectGeneratedAutoTags,
  selectIsGeneratingAutoTags,
  saveAutoTags,
  selectIsGeneratedAutoTagsUnintialized,
  setAutoTagsAsTags,
  getAIAnalyzerCustomerRequests,
  selectWizardStep,
} from '../store';
import { discardGeneratedAutoTags } from '../store/actions';
import { WIZARD_STEPS } from '../constants';

const useAIAnalyzer = () => {
  const dispatch = useDispatch();
  const [showAIAnalyzer, setShowAIAnalyzer] = useState(false);
  const [selectedRequests, setSelectedRequests] = useState([]);

  const generatedAutoTags = useSelector(selectGeneratedAutoTags);
  const isGeneratingAutoTags = useSelector(selectIsGeneratingAutoTags);
  const areAutoTagsUnitialized = useSelector(selectIsGeneratedAutoTagsUnintialized);
  const customerRequests = useSelector(getAIAnalyzerCustomerRequests);
  const currentWizardStep = useSelector(selectWizardStep);

  const hasEnabledAutoTag = useFeatureFlags([FeatureFlags.HAS_CUSTOMER_REQUESTS_AUTO_TAG]);

  const customerRequestsWithAutoTags = useMemo(() => {
    return customerRequests.map(cr => {
      const generatedAutoTagsForRequest = generatedAutoTags.find(autoTag => cr.id === autoTag.customerRequestId);

      if (!generatedAutoTagsForRequest || isEmpty(generatedAutoTagsForRequest.tags)) {
        return cr;
      }

      return {
        ...cr,
        autoTags: generatedAutoTagsForRequest.tags || [],
      };
    });
  }, [customerRequests, generatedAutoTags]);

  const handleResetAutoTags = () => {
    dispatch(discardGeneratedAutoTags());
  };

  const handleCloseAIAnalyzer = () => {
    setShowAIAnalyzer(false);
  };
  const handleOpenAIAnalyzer = () => {
    setShowAIAnalyzer(true);
  };
  const handleGenerateAutoTag = similarityThreshold => {
    dispatch(generateAutoTags(customerRequests, similarityThreshold));
  };
  const handleUpdateWithAutoTags = () => {
    dispatch(
      saveAutoTags(
        customerRequestsWithAutoTags.map(customerRequest => ({
          customerRequestId: customerRequest.id,
          tags: customerRequest.autoTags,
        })),
      ),
    );
  };

  const handleSetAutoTagsAsRequestTags = () => {
    const saveAutoTagsData = customerRequestsWithAutoTags.map(customerRequest => ({
      customerRequestId: customerRequest.id,
      tags: customerRequest.autoTags,
    }));

    const autoTagsToSave = saveAutoTagsData.filter(autoTag => selectedRequests.includes(autoTag.customerRequestId));

    dispatch(setAutoTagsAsTags(autoTagsToSave));
  };

  return {
    showAIAnalyzer,
    selectedRequests: customerRequestsWithAutoTags.filter(cr => selectedRequests.includes(cr.id)),
    customerRequests: customerRequestsWithAutoTags,
    previewAutoTags: !areAutoTagsUnitialized && !isGeneratingAutoTags && currentWizardStep === WIZARD_STEPS.preview,
    canSaveAutoTags: !areAutoTagsUnitialized && !isGeneratingAutoTags && selectedRequests.length > 0,
    hasEnabledAutoTag,
    isGeneratingAutoTags,

    handleGenerateAutoTag,
    handleCloseAIAnalyzer,
    handleResetAutoTags,
    handleOpenAIAnalyzer,
    handleUpdateWithAutoTags,
    handleSetAutoTagsAsRequestTags,
    setSelectedRequests,
  };
};

export default useAIAnalyzer;
