import React, { useMemo } from 'react';
import { VariableSizeList } from 'react-window';

import { Droppable } from 'react-beautiful-dnd';

import DndAtoms from 'design-system/atoms/DndAtoms/index';

import { getVirtualizedListHeight, makeGetItemSize } from './helpers/sizes';
import VirtualizedItemRenderer from './components/VirtualizedItemRenderer';

const { MenuContainer } = DndAtoms;

const getItemKey = (index, data) => {
  const item = data?.items?.[index];

  return item.id;
};

export default ({
  id,
  draggable,
  rootList,
  children,
  type,
  isDragging,
  onDraggingStyle,
  renderOnOverOverlay,
  items,
  renderItem,
  renderItemByIndex,
  path = [],
  openItems = {},
  listRef,
  itemHeight,
  rootHeight,
  avoidVirtualization = false,
  focusedRef,
}) => {
  const listHeight = useMemo(
    () => getVirtualizedListHeight(rootList, path, openItems, items?.length, itemHeight, rootHeight),
    [rootList, path, openItems, items?.length, itemHeight, rootHeight],
  );
  const getItemSize = makeGetItemSize(path, items, openItems, itemHeight);

  const style = {
    ...(isDragging ? onDraggingStyle : {}),
  };

  if (draggable) {
    return (
      <Droppable droppableId={`droppable-${id}`} type={type}>
        {(provided, snapshot) => (
          <MenuContainer
            {...provided.droppableProps}
            ref={provided.innerRef}
            rootList={rootList}
            isDraggingOver={snapshot.isDraggingOver}
            style={style}
          >
            {items?.map((item, index) => renderItem(item, index))}
            {children}
            {provided.placeholder}
            {isDragging && renderOnOverOverlay ? renderOnOverOverlay() : null}
          </MenuContainer>
        )}
      </Droppable>
    );
  }

  if (avoidVirtualization) {
    return <MenuContainer rootList={rootList}>{items?.map((item, index) => renderItem(item, index))}</MenuContainer>;
  }

  return (
    <MenuContainer rootList={rootList}>
      <VariableSizeList
        ref={listRef}
        height={listHeight}
        itemSize={getItemSize}
        itemCount={items.length}
        itemKey={getItemKey}
        estimatedItemSize={itemHeight}
        // passing the custom renderItem on itemData so we can easily access it in the renderer
        itemData={{ renderItemByIndex, items, focusedRef }}
      >
        {VirtualizedItemRenderer}
      </VariableSizeList>
      {children}
    </MenuContainer>
  );
};
