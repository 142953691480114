import React from 'react';
import styled from 'styled-components';

import InputTextField from 'design-system/atoms/InputTextField/index';

import Search from 'design-system/atoms/DragonIcons/Search';

export default ({ query, placeholder, handleChange, ...props }) => {
  return (
    <Wrapper {...props}>
      <SearchIcon>
        <Search />
      </SearchIcon>
      <StyledInputTextField placeholder={placeholder} size="medium" value={query} handleValue={handleChange} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SearchIcon = styled.div`
  display: flex;

  svg {
    width: 18px;
    height: 18px;
  }
`;

const StyledInputTextField = styled(InputTextField)`
  fieldset {
    border: none;
  }

  &&&& {
    > div {
      background: transparent;
    }

    input::placeholder {
      font-size: ${({ theme }) => theme.typography.fontSizeSmallLarge}px;
      font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    }
  }
`;
