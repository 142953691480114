import React from 'react';
import { isEmpty } from 'ramda';
import styled, { createGlobalStyle } from 'styled-components';
import { spacing } from 'design-system/theme';

import PrevIcon from '@material-ui/icons/KeyboardArrowLeft';
import PrimaryButton from 'design-system/atoms/PrimaryButton/index';
import Text from 'design-system/atoms/Text/index';
import HomeBackground from 'design-system/atoms/HomeBackground/index';

import bg from 'src/design-system/atoms/HomeBackground/images/home_background.png';
import OptionsListContainer from './components/OptionsListContainer';
import SidebarContentContainer from './components/SidebarContentContainer';
import ContactSalesButton from './components/ContactSalesButton';
import GuideContainer from './components/GuideContainer';
import DiscoveryBot from 'containers/DiscoveryBot';

import { PAGE_HEADER_HEIGHT } from 'constants/common';

// TODO Using the new blue here. Update to theme later
const MAIN_BLUE = '#2E7CF6';
const NAV_WIDTH = 380;

const GlobalPendoStyle = createGlobalStyle`
  body {
    ._pendo-step-container-size {
      left: ${NAV_WIDTH}px !important;
      top: 0 !important;
      height: 100% !important;
    }

    ._pendo-step-container-styles {
      height: 100% !important;
      max-height: 100% !important;
      width: 100% !important;
    }
  }
`;

const DemoDialogContent = ({
  hasDiscoveryBot,
  isBookADemoOpen,
  shouldShowHeader,
  removeNavOptionsParams,
  handleSelectGuide,
  selectedSubMenuOption,
  selectedMenuOption,
  navigationOptions,
  setIsBookADemoOpen,
}) => {
  const renderLeftColumnContent = () => {
    if (isEmpty(navigationOptions)) {
      return null;
    }

    if (hasDiscoveryBot) {
      return selectedMenuOption?.options && selectedSubMenuOption?.pendo_guide_id ? (
        <OptionsListContainer
          pageOptions={selectedMenuOption?.options}
          selectedGuideId={selectedSubMenuOption?.pendo_guide_id}
          handleSelectPendoGuide={handleSelectGuide}
        />
      ) : (
        <DiscoveryBot selfGuidedDemoData={navigationOptions} />
      );
    }

    return selectedMenuOption?.options ? (
      <OptionsListContainer
        pageOptions={selectedMenuOption?.options}
        selectedGuideId={selectedSubMenuOption?.pendo_guide_id}
        handleSelectPendoGuide={handleSelectGuide}
      />
    ) : (
      <SidebarContentContainer navOptions={navigationOptions} />
    );
  };

  const renderRightColumnContent = () => {
    if (hasDiscoveryBot) {
      return selectedMenuOption?.options && !selectedSubMenuOption?.pendo_guide_id ? (
        <OptionsListContainer
          pageOptions={selectedMenuOption?.options}
          selectedGuideId={selectedSubMenuOption?.pendo_guide_id}
          handleSelectPendoGuide={handleSelectGuide}
        />
      ) : (
        <GuideContainer selectedNavOption={selectedMenuOption} selectedOption={selectedMenuOption?.options} />
      );
    }

    return <GuideContainer selectedNavOption={selectedMenuOption} selectedOption={selectedMenuOption?.options} />;
  };

  const handleClickBack = () => {
    window.pendo.onGuideDismissed();

    removeNavOptionsParams();
  };

  return (
    <>
      <GlobalPendoStyle />
      <HomeBackground>
        <Header>
          {shouldShowHeader && (
            <>
              <BackButton iconpos="left" onClick={handleClickBack}>
                <PrevIcon /> Back
              </BackButton>
              <ContactSalesButton
                selectedOption={selectedMenuOption?.options}
                isBookADemoOpen={isBookADemoOpen}
                setIsBookADemoOpen={setIsBookADemoOpen}
              />
            </>
          )}
        </Header>
        <Container tabIndex={-1} hasDiscoveryBot={hasDiscoveryBot}>
          <LeftContent hasDiscoveryBot={hasDiscoveryBot}>{renderLeftColumnContent()}</LeftContent>
          <RightContent hideBackground={isBookADemoOpen}>
            {isBookADemoOpen ? (
              <>
                <SalesTitle variant="h2">Book a demo</SalesTitle>
                <SalesContent id="calendlyDemoWidget" />
              </>
            ) : (
              renderRightColumnContent()
            )}
          </RightContent>
        </Container>
      </HomeBackground>
    </>
  );
};

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${PAGE_HEADER_HEIGHT}px;
  z-index: ${({ theme }) => theme.zIndex.highest};

  background-color: ${({ theme }) => theme.palette.newLayout.background.header};
  background-image: url(${bg});
  background-size: 100%;
  background-position: center top;
  background-repeat: no-repeat;
`;

const Container = styled.div`
  display: flex;
  align-items: stretch;
  padding-top: ${PAGE_HEADER_HEIGHT}px;
`;

const LeftContent = styled.div`
  padding: ${({ hasDiscoveryBot }) =>
    hasDiscoveryBot ? `${spacing()}px ${spacing(3)}px 0px` : `${spacing()}px ${spacing(3)}px;`};
  width: ${NAV_WIDTH}px;
  min-width: ${NAV_WIDTH}px;
  overflow: hidden;
  height: 100%;
`;

const RightContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
  padding: ${spacing()}px ${spacing(3)}px;
`;

const SalesTitle = styled(Text)`
  text-align: center;
`;

const SalesContent = styled.div`
  width: 100%;
  height: 800px;
  padding-top: ${spacing(5.5)}px;
`;

const BackButton = styled(PrimaryButton)`
  &&&& {
    position: absolute;
    left: 3px;

    span {
      color: ${MAIN_BLUE};
    }
  }
`;

export default DemoDialogContent;
