import pick from 'lodash/pick';

export default state =>
  pick(state, [
    'projects',
    'teams',
    'users',
    'roadmaps',
    'phases',
    'themes',
    'priorities',
    'categories',
    'timeframes',
    'skills',
    'tasks',
    'objectives',
    'organization',
    'login',
    'comments',
    'votes',
    'customerRequests',
    'customFields',
    'customers',
    'tags',
    'accessControl',
    'metrics',
    'personasData',
    'lifecyclesData',
  ]);
