import React, { Component } from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';

import MetricsGrid from './MetricsGrid';

import { getOrganization } from 'store/organization/selectors';

import { setLastIdeasView } from 'store/app';

class Metrics extends Component {
  static propTypes = {
    displayView: string,
  };

  static defaultProps = {
    displayView: 'grid',
  };

  availableViews = {
    grid: MetricsGrid,
  };

  componentDidMount() {
    this.props.setLastIdeasView(this.getViewKey());
  }

  getViewKey() {
    const { location } = this.props;

    const page = location.pathname.split('/')[2];
    const queryString = location.search || '';

    if (!page) {
      this.props.history.push(`/metrics/grid${queryString}`);
    }

    return 'grid';
  }

  render() {
    const { props } = this;

    const View = this.availableViews.grid;

    return <View {...props} />;
  }
}

export default connect(
  state => {
    return {
      organization: getOrganization(state),
    };
  },
  {
    setLastIdeasView,
  },
)(Metrics);
