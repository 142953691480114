import { SET_DEMO_SPLASH_PAGE_SHOWN, TOGGLE_DEMO_SPLASH_PAGE_VISIBILIY } from "./types";

export const setDemoSplashPageShown = () => ({
  type: SET_DEMO_SPLASH_PAGE_SHOWN,
});

export const openDemoSplashPage = () => ({
  type: TOGGLE_DEMO_SPLASH_PAGE_VISIBILIY,
  payload: true,
});

export const closeDemoSplashPage = () => ({
  type: TOGGLE_DEMO_SPLASH_PAGE_VISIBILIY,
  payload: false,
});
