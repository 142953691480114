import React from 'react';
import styled from 'styled-components';

import theme from 'design-system/theme';
import ButtonV2 from 'design-system/atoms/ButtonV2/index';


const DEFAULT_BUTTON_TEXT = 'Back';

const DragonBackButton = ({ icon, onClick }) => {
  return (
    <ButtonV2 background="transparent" onClick={onClick} size="small">
      <InnerButton>
        {icon} <ButtonText>{DEFAULT_BUTTON_TEXT}</ButtonText>
      </InnerButton>
    </ButtonV2>
  );
};

const InnerButton = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonText = styled.span`
  font-weight: bold;
  color: ${theme.palette.primary.main};
`;

export default DragonBackButton;
