import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';

/**
 * @function checkIfFieldIsCustomField
 *
 * Will check if some field is a custom field
 *
 * @param  {String} field
 * @return {Boolean}
 */
const checkIfFieldIsCustomField = field => field.startsWith('custom_fields.');

/**
 * @function getCustomFieldKey
 *
 * Returns the key from some custom field
 *
 * 'custom_fields.field_key' returns 'field_key'
 *
 * @param  {String} field
 * @return {String}
 */
const getCustomFieldKey = field => field.replace('custom_fields.', '');

const checkIfCustomFieldIsDropdown = fieldKey =>
  [TYPES_OF_CUSTOM_FIELDS.DROPDOWN, TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN].includes(fieldKey);

export { checkIfCustomFieldIsDropdown, checkIfFieldIsCustomField, getCustomFieldKey };
