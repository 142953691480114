import isFunction from 'lodash/isFunction';
import isEqual from 'lodash/isEqual';
import { propEq, pluck, prop, defaultTo, not, or, pickAll, omit, has } from 'ramda';
import isPlainObject from 'utils/isPlainObject';
import { PAGES_STATE_TO_SAVE, PAGES_STATE_TO_STORE } from './consts';
import { READ_ONLY_USER } from '@dragonboat/permissions';

const HIDE_PROPERTY = 'hide';
const COL_ID_PROPERTY = 'colId';
const OPERATIONS_KEY = 'operations';
const SNAPSHOT_KEY = 'snapshot';
const SNAPSHOT_DATA_KEY = 'snapshotData';
const defaultEmptyObject = defaultTo({});
const defaultEmptyArray = defaultTo([]);
const hasPath = has('path');

// the changes in height will affect the y position by a small amount.
// If there are any changes in order the Y position will change more than 1 unit.
// we should normalize so state is not sensible to smaller change but big positional changes
const widgetNormalizeHeight = ({ id, type, x, y }) => ({ id, type, x, y: Math.round(y) });

// Temporary workaround. It is being store in the store properties like 'component' and 'label'
// Also height must be ignored when comparing states since it needs to re-calc the widget height after render
const dashboardWidgetsFieldsToPick = ['id', 'type', 'x', 'y'];
const pickFieldsFromDashboardWidget = widget => {
  return widgetNormalizeHeight(pickAll(dashboardWidgetsFieldsToPick, widget));
};

export const checkCurrentStateIsSameAsActiveView = (state, page, activeView) => {
  let currentState = PAGES_STATE_TO_SAVE[page];
  let hasSameState = true;
  const isReadOnlyUser = state.login?.currentUser.role_id === READ_ONLY_USER;
  const isGoalsGridView = ['goals/grid'].includes(activeView.path);

  currentState = isFunction(currentState) ? { ...currentState(state) } : { [currentState]: state[currentState] };

  const _compareStateObject = (obj, objToCompare, level = 0, parentKey = '') => {
    Object.entries(obj).forEach(([entryKey, entryVal]) => {
      if (hasSameState) {
        if (isPlainObject(entryVal) && level < 2) {
          _compareStateObject(entryVal, prop(entryKey, defaultEmptyObject(objToCompare)), level + 1, entryKey);
        } else {
          let entryToCompare1 = entryVal;
          let entryToCompare2 = objToCompare ? objToCompare[entryKey] : null;

          // displayLayer and portfolioMode are keys on old saved views, should be ignored
          if (!parentKey && ['displayLayer', 'portfolioMode'].includes(entryKey)) {
            entryToCompare2 = entryToCompare1;
          }

          // ignore aplliedAt and activeFilter on filters
          if (!isGoalsGridView && ['filter', 'filters'].includes(parentKey) && ['aplliedAt', 'activeFilter'].includes(entryKey))
            entryToCompare2 = entryToCompare1;

          // Custom comparisions
          // IDEAS GRID - view specific cases to ignore or make custom comparisions
          if (['ideas/grid', 'forecast/list'].includes(activeView.path)) {
            if (entryKey === 'portfolioMode' && parentKey === 'grids') {
              entryToCompare2 = entryToCompare1;
            }
            if (entryKey === 'columnState') {
              const filterOnlyVisibleColumns = propEq(HIDE_PROPERTY, false);
              const mapToColId = pluck(COL_ID_PROPERTY);
              const currentFieldsOnTable = mapToColId(defaultEmptyArray(entryToCompare2));
              const filterFieldsDontExistsOnCurrentTable = c => currentFieldsOnTable.includes(c.colId);

              /*
               * We could have old saved state with fields that does not exists on
               * table anymore (for example custom fields removed on the organization)
               *
               * Should filter those fields to not be compared with current state
               */
              entryToCompare1 = defaultEmptyArray(entryToCompare1)
                .filter(filterFieldsDontExistsOnCurrentTable)
                .filter(filterOnlyVisibleColumns);
              entryToCompare2 = defaultEmptyArray(entryToCompare2).filter(filterOnlyVisibleColumns);

              if (isReadOnlyUser) {
                entryToCompare2 = entryToCompare1;
              } else {
                const _ignoreCols = c => !['drag', 'select', 'row_order', 'rank'].includes(c.colId);

                entryToCompare1 = entryToCompare1?.filter(_ignoreCols);
                entryToCompare2 = entryToCompare2?.filter(_ignoreCols);
              }

              /*
               * In case current columns or user view columns are empty (it means
               * something went wrong with the view) should equalize both column states
               */
              const isCurrentStateOrViewStateEmpty = or(not(entryToCompare1.length), not(entryToCompare2.length));

              if (isCurrentStateOrViewStateEmpty) {
                entryToCompare2 = entryToCompare1;
              }
            } else if (entryKey === 'expandedGroups') {
              entryToCompare2 = entryToCompare1;
            }
          }
          if (isGoalsGridView) {
            if (entryKey === 'columnState') {
              if (isReadOnlyUser) {
                entryToCompare2 = entryToCompare1;
              } else {
                const omitWidthVariations = omit(['width']);

                entryToCompare1 = entryToCompare1.map(omitWidthVariations);
                entryToCompare2 = entryToCompare2.map(omitWidthVariations);
              }
            }
          }
          if (hasPath(activeView) && activeView.path?.includes('dashboard/dashboards')) {
            if (entryKey === 'widgets') {
              // normalize heights since they get a sligly shift with the re-calc but order maintain
              entryToCompare1 = entryToCompare1?.map(pickFieldsFromDashboardWidget);
              entryToCompare2 = entryToCompare2?.map(pickFieldsFromDashboardWidget);
            }
            // this was wrongly being saved in the view state. some views might still have this property
            if (entryKey === 'inEditMode') {
              entryToCompare2 = entryToCompare1;
            }
          }

          // Fix for old Snapshot views that still have the operations and snapshotData on the state
          const hasOperationsKey = parentKey === OPERATIONS_KEY;
          const hasSnapshotDataKey = parentKey === SNAPSHOT_KEY && entryKey === SNAPSHOT_DATA_KEY;
          const shouldIgnoreKeys = hasOperationsKey || hasSnapshotDataKey;

          if (hasPath(activeView) && activeView.path === 'dashboard/snapshot' && shouldIgnoreKeys) {
            entryToCompare2 = entryToCompare1;
          }

          try {
            hasSameState = hasSameState && isEqual(entryToCompare1, entryToCompare2);
          } catch (err) {
            hasSameState = false;
          }
        }
      }
    });
  };

  _compareStateObject(activeView.state, currentState);

  return hasSameState;
};

/**
 * Check if created or updated UserView needs to update a loaded active view
 * Examples are: Public links or favorites are changed on userView active view needs to be updated as well
 * at least for now because all of those places are attached to the userView not the active view
 *
 * @param {Object} activeViewsByPage state object for active views by page
 * @param {Object} updatedView UserView created or updated
 * @param {Boolean} clearUnsavedChangesState
 * @returns {Object} The state active views by page updated
 */
export const updateActiveViewFromViewUpdate = (activeViewsByPage, updatedView, clearUnsavedChangesState = false) => {
  const pageActiveView = activeViewsByPage[updatedView.page];

  if (!pageActiveView || pageActiveView.id !== updatedView.id) {
    return activeViewsByPage;
  }

  activeViewsByPage[updatedView.page] = {
    ...pageActiveView,
    name: updatedView.name,
    publicLinks: updatedView.publicLinks || [],
    favorites: updatedView.favorites || [],
    hasUnsavedChanges: clearUnsavedChangesState ? false : pageActiveView.hasUnsavedChanges,
  };

  return activeViewsByPage;
};

export const getUpdatedStoreStateFromActiveViewChange = (state, page, view) => {
  const viewToStoreHandler = PAGES_STATE_TO_STORE[page];

  if (!viewToStoreHandler) {
    return null;
  }

  return {
    ...state,
    userViews: {
      ...state.userViews,
      activeViewsByPage: {
        ...state.userViews.activeViewsByPage,
        [page]: view,
      },
    },
    ...omit(['_filters', 'app'], viewToStoreHandler(view, state)),
  };
};

export const isViewADefaultViewFromUser = view => {
  return view.isOwner && !!view.default_view;
};
