import React from 'react';
import styled from 'styled-components';

import NewDialog from 'design-system/organisms/NewDialog/index';
import DialogActions from 'design-system/molecules/DialogActions/index';

import useOptionsForm from 'hooks/useOptionsForm';

const DIALOG_TITLE = 'Customize options for';
const LIST_TITLE = 'Options';
const ADD_BUTTON_LABEL = '+ Add new option';

const CustomFieldOptionsCustomizer = ({ isOpen, customField, handleOptionsChange, handleClose, canEdit }) => {
  if (!customField) return null;

  const onCustomFieldOptionsChange = newOptions => {
    if (customField.id) {
      handleOptionsChange(customField.id, newOptions);
    }
  };

  const OptionsComponent = useOptionsForm({
    options: customField.data_enhanced,
    handleUpdateOptions: onCustomFieldOptionsChange,
    titleLabel: LIST_TITLE,
    addOptionLabel: ADD_BUTTON_LABEL,
    optionIdPrefix: '',
    disableEdit: !canEdit,
    showStatusButton: false,
    wrapperDndListStyle: {
      boxShadow: 'none',
    },
  });

  return (
    <NewDialog
      title={`${DIALOG_TITLE} ${customField.title}`}
      actions={<DialogActions onCancel={handleClose} cancelLabel="Close" cancelProps={{ color: 'primary' }} />}
      onClose={handleClose}
      open={isOpen}
      width="750px"
      withActionsShadow
      closeButton
    >
      <Container>{OptionsComponent}</Container>
    </NewDialog>
  );
};

export default CustomFieldOptionsCustomizer;

const Container = styled.div`
  width: 100%;
`;
