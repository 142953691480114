import React, { useMemo, useState, useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import NewCard from 'design-system/organisms/NewCard/index';
import Droppable from 'design-system/molecules/Droppable/index';

import { NewProjectFields } from 'constants/projects';
import useSummaryDependencyLinesManagement from 'hooks/useSummaryDependencyLinesManagement';
import usePageFilters from 'hooks/filters/usePageFilters';

import CellGroup from './components/CellGroup';
import CellProject from './components/CellProject';

import useSummaryState from 'routes/Dashboard/Summary/hooks/useSummaryState';
import { useSummaryTableData } from 'src/routes/Dashboard/Summary/hooks/useSummaryTableData';
import { makeProjectId, getCellProjectsByGroup, computeGroupId } from 'routes/Dashboard/Summary/helpers';
import { getCurrentUser } from 'store/login/selectors';

const Cell = memo(
  ({
    col,
    row,
    cellProjects,
    onCreateProject,
    onToggleGroup,
    disableAdd,
    onProjectClick,
    allProjectsInfo,
    slice,
    getInlineGroupTitle,
  }) => {
    const { isGoalMode } = useSummaryTableData();
    const {
      isReadOnly,
      isGroupExpanded,
      selectedColOption,
      selectedRowOption,
      selectedGroupByOption,
      displayPreferences,
      searchStr: localSearchString,
      expandedGroups,
    } = useSummaryState(isGoalMode, slice);

    const { searchString: globalSearchString } = usePageFilters();
    const searchStr = localSearchString || globalSearchString;

    const { showGroupColor, displayFields } = displayPreferences;

    const [draggingProjectId, setDraggingProjectId] = useState(null);

    const [newInput, setNewInput] = useState({
      rowId: undefined,
      colId: undefined,
      groupId: undefined,
      placement: undefined,
    });

    const currentUser = useSelector(getCurrentUser);
    const isAnonymousUser = currentUser.is_anonymous;

    const enableAddNewButton = !isReadOnly && !isAnonymousUser;

    const hasGroups = selectedGroupByOption && row.groups && cellProjects.length;

    const isGrouping = !!selectedGroupByOption.key;

    const projectsByGroup = useMemo(() => {
      if (!hasGroups) {
        return {};
      }

      return getCellProjectsByGroup(cellProjects);
    }, [hasGroups, cellProjects]);

    const { hideItemsWithoutDependencies } = useSummaryDependencyLinesManagement();

    const showNewProjectCard = useCallback(
      (rowId, colId, placement, groupId = undefined) => {
        const nonGroupMatch = newInput.placement === placement && newInput.rowId === rowId && newInput.colId === colId;

        return nonGroupMatch && newInput.groupId === groupId;
      },
      [newInput],
    );

    const _onProjectClick = useCallback(
      projectId => {
        onProjectClick(projectId, row?.id);
      },
      [onProjectClick, row?.id],
    );

    const _handleAddNewProject = useCallback(
      ({ rowId, colId, groupId = undefined, placement = 'bottom' }) =>
        () => {
          setNewInput({
            rowId,
            colId,
            groupId,
            placement,
          });
        },
      [],
    );

    const _handleSaveNewProject = useCallback(
      newProject => {
        onCreateProject(newInput, newProject);
        setNewInput({});
      },
      [newInput],
    );

    const _handleCancelNewProject = useCallback(() => {
      setNewInput({});
    }, []);

    const _handleClickAway = useCallback(
      values => {
        const { title } = values;

        if (!title) {
          _handleCancelNewProject();
        } else {
          _handleSaveNewProject(values);
        }
      },
      [_handleCancelNewProject, _handleSaveNewProject],
    );

    const _renderNewCard = () => {
      return (
        <NewCardContainer id="port-3d-add-new-item-button" marginBottom={hasGroups}>
          <NewCard
            elevation={2}
            fields={NewProjectFields}
            handleOutsideClick={_handleClickAway}
            onAdd={_handleSaveNewProject}
            onCancel={_handleCancelNewProject}
          />
        </NewCardContainer>
      );
    };

    const hasConnections = project => {
      return (
        project?.projectDependencies?.length ||
        allProjectsInfo.some(pi => pi.data?.projectDependencies?.some(pd => pd.id === project.id))
      );
    };

    const _renderGroups = () => (
      <>
        {row.groups.map((group, index) => {
          const groupProjects = projectsByGroup[group.id] ?? [];

          const filteredProjects = hideItemsWithoutDependencies
            ? groupProjects.filter(gp => hasConnections(gp.project))
            : groupProjects;

          return filteredProjects.length ? (
            <CellGroup
              rowId={row.id}
              colId={col.id}
              group={group}
              groupProjects={filteredProjects}
              isReadOnly={isReadOnly}
              displayFields={displayFields}
              selectedColData={selectedColOption}
              selectedRowData={selectedRowOption}
              localSearchStr={searchStr}
              showGroupColor={showGroupColor}
              disableAdd={disableAdd}
              onToggleGroup={onToggleGroup}
              handleAddNewProject={_handleAddNewProject}
              newInput={newInput}
              showNewCard={showNewProjectCard}
              renderNewCard={_renderNewCard}
              isExpanded={isGroupExpanded(row, col, group)}
              onProjectClick={_onProjectClick}
              isLast={index === row.groups.length - 1}
              draggingProjectId={draggingProjectId}
              setDraggingProjectId={setDraggingProjectId}
              allProjectsInfo={allProjectsInfo}
              expandedGroups={expandedGroups}
              isGrouping={isGrouping}
              isGoalMode={isGoalMode}
              isAnonymousUser={isAnonymousUser}
              getInlineGroupTitle={getInlineGroupTitle}
            />
          ) : null;
        })}
        {!isReadOnly && (
          <BottomDroppableRegion
            flexHeight
            key={`col*${computeGroupId(row.id, col.id, 'null')}`}
            id={`col*${computeGroupId(row.id, col.id, 'null')}`}
            allowFlexGrow
            withHover
          />
        )}
      </>
    );

    const _renderProjects = () => {
      return (
        <DroppableRegion
          flexHeight
          key={`col*${computeGroupId(row.id, col.id, 'null')}`}
          id={`col*${computeGroupId(row.id, col.id, 'null')}`}
          height={cellProjects.length === 0 ? 5 : null}
          allowFlexGrow
        >
          {cellProjects.reduce((renderedProjects, { project }, index) => {
            if (hideItemsWithoutDependencies && !hasConnections(project)) {
              return renderedProjects;
            }

            const dragId = makeProjectId(row?.id, col?.id, undefined, project.id);

            const projectComponentInstance = (
              <CellProject
                dragId={dragId}
                project={project}
                isReadOnly={isReadOnly}
                displayFields={displayFields}
                selectedColData={selectedColOption}
                selectedRowData={selectedRowOption}
                localSearchStr={searchStr}
                index={index}
                onProjectClick={_onProjectClick}
                col={col}
                draggingProjectId={draggingProjectId}
                setDraggingProjectId={setDraggingProjectId}
                allProjectsInfo={allProjectsInfo}
                expandedGroups={expandedGroups}
                isGrouping={isGrouping}
                isGoalMode={isGoalMode}
                isAnonymousUser={isAnonymousUser}
              />
            );

            return [...renderedProjects, projectComponentInstance];
          }, [])}
        </DroppableRegion>
      );
    };

    return (
      <ColWrapper>
        <ColInfo
          style={
            {
              // width: colsWidth[col.id] || `calc(100vw / ${cols.length > 4 ? 4 : cols.length + 1} + 35px)`,
            }
          }
          marginBottom={hasGroups || isReadOnly}
          // ref={elem => {
          //   // this.rowsHeights[row.id] = [
          //   //   ...(this.rowsHeights[row.id] ? this.rowsHeights[row.id] : []),
          //   //   elem ? elem.clientHeight : 0,
          //   // ];
          // }}
        >
          {hasGroups ? _renderGroups() : _renderProjects()}
          {showNewProjectCard(row.id, col.id, 'bottom') && _renderNewCard()}
        </ColInfo>
        {enableAddNewButton && (
          <NewGroupProjectBox onClick={_handleAddNewProject({ rowId: row.id, colId: col.id, placement: 'bottom' })}>
            <NewGroupProjectTitle>+ NEW</NewGroupProjectTitle>
          </NewGroupProjectBox>
        )}
      </ColWrapper>
    );
  },
);

export default Cell;

const ColWrapper = styled.div`
  &&&& {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
  }
`;

const ColInfo = styled.div`
  line-height: 24px;
  width: 100%;
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? '0' : '25px')};
`;

const DroppableRegion = styled(Droppable)`
  order: ${({ asLast }) => (asLast ? 1 : 0)};
`;

DroppableRegion.displayName = 'CellDroppableRegion';

const BottomDroppableRegion = styled(DroppableRegion)``;

BottomDroppableRegion.displayName = 'CellBottomDroppableRegion';

const NewGroupProjectBox = styled.div`
  &&&& {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 25px;

    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    border: 1px dashed #dcdbdb;
    opacity: 0;

    &:hover {
      background: #f6f6f6;
      opacity: 1;
    }
  }
`;

const NewGroupProjectTitle = styled.p`
  &&&& {
    font-size: 14px;
    color: #131c23;
    margin: 0;
  }
`;

const NewCardContainer = styled.div`
  padding-top: 10px;
  padding-bottom: ${({ marginBottom }) => (marginBottom ? '25px' : '0')};
  display: inline-block;
`;
