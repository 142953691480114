import { css } from 'styled-components';

import { genericStyles } from '../IdeasList/IdeasList/New/styled';

const BELOW_NOT_INCLUDED_CLASS = 'below-not-included';

const forecastStyles = css`
  ${genericStyles}

  &&&& {
    .ag-root-wrapper.ag-layout-normal {
      margin: 0 44px;
    }

    .ag-layout-normal.ag-root {
      margin: 0;
    }

    .below-not-included > * {
      opacity: 0.6;
    }

    .ag-header-cell {
      border-top: none;
    }
  }
`;

export { forecastStyles, BELOW_NOT_INCLUDED_CLASS };
