import { useSelector } from 'react-redux';

import { getCurrentUser } from 'store/login/selectors';

/**
 * Hook to abstract accessing and actions on the Login slice.
 */
const useLoginInfo = () => {
  const currentUser = useSelector(getCurrentUser);

  return { currentUser };
};

export default useLoginInfo;
