import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isNil } from 'ramda';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import { getPageUserViews, getActiveViewForPage, getClonedUserViewOperationResult } from 'store/userViews/selectors';
import { getUsers, getUserById } from 'store/users/selectors';

import { deleteUserView, setPageActiveView, setDefaultPageActiveView } from 'store/userViews';
import { cloneView, updateSharedView, updateSharedViewWithoutModifyingState } from 'store/userViews/sharedViews.thunks';

import history from 'store/utils/history';

import copyToClipboard from 'utils/copyToClipboard';
import sortUserViews from 'utils/userViews/sortUserViews';
import formatUserViews from 'utils/userViews/formatUserViews';

import usePermissions from 'hooks/permissions/usePermissions';

import { getPathFromPageId } from 'utils/pages';

import useUpdateViewDialogUI from 'hooks/userViews/useUpdateViewDialogUI';
import useSaveViewDialogUI from 'hooks/userViews/useSaveViewDialogUI';

const SHARED_VIEW = 'sharedView';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();

    const copyLinkRefInput = useRef();

    const users = useSelector(getUsers);
    const userViews = useSelector(state => getPageUserViews(state, props.pageId));
    const pageActiveView = useSelector(state => getActiveViewForPage(state, props.pageId));
    const viewOwner = useSelector(state => getUserById(state, pageActiveView?.user_id));
    const createdUserView = useSelector(state => getClonedUserViewOperationResult(state));

    const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] = useState(false);
    const [viewToChangeId, setViewToChangeId] = useState(false);

    const viewToChange = useMemo(() => userViews.find(v => v.id === viewToChangeId), [userViews, viewToChangeId]);

    const permissions = usePermissions();

    const {
      updateDialogProps,
      openUpdateDialog: openUpdateDialogUI,
      openShareDialog: openShareDialogUI,
      closeUpdateDialog: closeUpdateDialogUI,
    } = useUpdateViewDialogUI(viewToChange, permissions);

    const { saveDialogProps, openSaveDialog: openSaveDialogUI, closeSaveDialog: closeSaveDialogUI } = useSaveViewDialogUI();

    const editableUserViews = useMemo(
      () => userViews.filter(view => permissions.canUpdate(PERMISSION_RESOURCES.userView, { view })),
      [userViews, permissions],
    );

    const shouldRenderToggleButton = isNil(props.isShareViewMenuOpen) || isNil(props.closeShareViewMenuPopover);

    const sortedAndFormattedUserViews = useMemo(() => {
      const formattedViews = formatUserViews(userViews, users).map(view => ({
        ...view,
        active: pageActiveView && pageActiveView.id === view.id,
      }));

      return sortUserViews(formattedViews);
    }, [userViews, pageActiveView, users]);

    const hasAnyActiveView = useMemo(() => userViews.some(view => view.id === pageActiveView?.id), [userViews, pageActiveView]);

    useEffect(() => {
      if (updateDialogProps.open && createdUserView && !viewToChangeId) {
        setViewToChangeId(createdUserView.id);
      }
    }, [updateDialogProps, createdUserView, viewToChangeId]);

    const shareUrl = viewToChange
      ? `${window.location.origin}/${getPathFromPageId(props.pageId)}?${SHARED_VIEW}=${viewToChange.id}` // &public=true`
      : '';

    const closeSaveDialog = (resetView = true) => {
      if (resetView) setViewToChangeId(null);
      closeSaveDialogUI();
    };

    const closeDialog = () => {
      setViewToChangeId(null);
      closeSaveDialogUI();
      closeUpdateDialogUI();
    };

    const openShareDialog = () => {
      openShareDialogUI();
      closeSaveDialog(false);
    };
    const closeShareDialog = () => {
      setViewToChangeId(null);
      closeUpdateDialogUI();
    };
    const onEditUserView = view => {
      setViewToChangeId(view.id);
      openUpdateDialogUI();
    };
    const onShareUserView = view => {
      setViewToChangeId(view.id);
      openShareDialog();
    };

    const onSelectUserView = id => {
      const view = userViews.find(v => v.id === id);

      if (props.onSetPageUserView) {
        props.onSetPageUserView(view);
      }

      dispatch(setPageActiveView(props.pageId, view));

      // View may not have path (like static views so we use the current location)
      const viewPath = view.path || window.location.pathname.slice(1); // Remove leading slash

      history.push(`/${viewPath}?${SHARED_VIEW}=${view.id}`);
    };

    const onSaveUserViewForCreate = share => async (name, description) => {
      dispatch(cloneView(pageActiveView, name, description, false));

      if (share) {
        openShareDialog();
      }
    };

    const onSaveUserViewForUpdate = share => async (name, description) => {
      if (!viewToChange) return;

      dispatch(
        updateSharedViewWithoutModifyingState({
          ...viewToChange,
          name,
          description,
        }),
      );

      if (share) {
        openShareDialog();
      }
    };

    const onUpdateUserView = async current => {
      dispatch(
        updateSharedView({
          ...current,
          state: pageActiveView.state,
        }),
      );

      closeSaveDialog();
    };

    const onDeleteUserView = id => {
      dispatch(deleteUserView(id));

      if (pageActiveView?.id === id) {
        // If we delete the view that's currently active we need to go back to the default one
        dispatch(setDefaultPageActiveView(props.pageId));
      }
      // TODO remove closeSaveDialog closeShareDialog
      closeSaveDialog();
      closeShareDialog();
      closeDialog();
      setIsDeleteConfirmationVisible(false);
    };

    const onDropdownChange = (e, { key }) => {
      const mapKeysToFunc = {
        save: () => openSaveDialogUI(),
        copyLink: async () => {
          const newSharedViewUrl = pageActiveView
            ? `${window.location.origin}/${getPathFromPageId(props.pageId)}?sharedView=${pageActiveView.key || pageActiveView.id}`
            : '';

          copyLinkRefInput.current.select();
          await copyToClipboard(newSharedViewUrl);
          copyLinkRefInput.current.select();

          toast('Link was copied to clipboard.');
        },
      };

      const func = mapKeysToFunc[key];

      if (func) func();
    };

    return (
      <Component
        {...props}
        openUpdateDialog={openUpdateDialogUI}
        openSaveDialog={openSaveDialogUI}
        closeSaveDialog={closeSaveDialog}
        showShareDialog={openShareDialogUI}
        openShareDialog={openShareDialog}
        closeShareDialog={closeShareDialog}
        userViews={sortedAndFormattedUserViews}
        hasAnyActiveView={hasAnyActiveView}
        editableUserViews={editableUserViews}
        onDropdownChange={onDropdownChange}
        onSelectUserView={onSelectUserView}
        onSaveUserViewForCreate={onSaveUserViewForCreate(false)}
        onSaveUserViewForUpdate={onSaveUserViewForUpdate(false)}
        onUpdateUserView={onUpdateUserView}
        onSaveUserViewAndShareForCreate={onSaveUserViewForCreate(true)}
        onSaveUserViewAndShareForUpdate={onSaveUserViewForUpdate(true)}
        onEditUserView={onEditUserView}
        onShareUserView={onShareUserView}
        onDeleteUserView={onDeleteUserView}
        viewToChange={viewToChange}
        activeView={pageActiveView}
        shareUrl={shareUrl}
        copyLinkRefInput={copyLinkRefInput}
        pageId={props.pageId}
        isDeleteConfirmationVisible={isDeleteConfirmationVisible}
        setIsDeleteConfirmationVisible={setIsDeleteConfirmationVisible}
        viewOwner={viewOwner}
        shouldRenderToggleButton={shouldRenderToggleButton}
        saveDialogProps={saveDialogProps}
        updateDialogProps={updateDialogProps}
        closeDialog={closeDialog}
      />
    );
  };
};

export default componentHOC;
