import { useMemo } from 'react';
import { propEq, uniq, defaultTo } from 'ramda';

import normalizeArray from 'utils/normalizeArray';

const defaultToZero = defaultTo(0);

export default (planned, reported, completed, scopeVariance, totalScopeVariance, overInvestedGoals, entities) => {
  const normalizedEntities = useMemo(() => normalizeArray(entities, 'id'), [entities]);

  const totalPlanned = useMemo(() => Object.values(planned).reduce((sum, d) => sum + d, 0), [planned]);
  const totalReported = useMemo(() => Object.values(reported).reduce((sum, d) => sum + d, 0), [reported]);
  const totalCompleted = useMemo(() => Object.values(completed).reduce((sum, d) => sum + d, 0), [completed]);

  const allIds = useMemo(
    () => uniq([...Object.keys(planned), ...Object.keys(reported), ...Object.keys(completed)]),
    [planned, reported, completed],
  );

  const tableData = useMemo(() => {
    return allIds.map(id => {
      const entity = normalizedEntities[id];

      return {
        id,
        label: entity?.title,
        count: planned[id],
        countReported: reported[id],
        countCompleted: completed[id],
        scopeVariance: scopeVariance[id],
        color: entity?.color,
        targetAllocationPercentage: defaultToZero(entity?.target_allocation_percentage),
        entity,
        isOverInvested: overInvestedGoals.some(propEq('id', Number(id))),
      };
    });
  }, [normalizedEntities, allIds, planned, reported, completed]);

  return {
    total: totalPlanned,
    totalReported,
    totalScopeVariance,
    totalCompleted,
    tableData,
  };
};
