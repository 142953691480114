import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isNumeric from 'utils/isNumeric';

import { fetchCustomerRequestsInsightsForCounterStackedChart } from 'store/customerRequests/thunks';
import normalizeArray from 'utils/normalizeArray';
import { UNDEFINED_LABEL } from 'constants/common';
import {
  getFiltersCompiled,
  getInsightsDataForCounterStackedChart,
  isUninitializedInsightsDataForCounterStackedChart,
  getSelectedCounterStackedChartGroupBy,
  getSelectedCounterStackedChartStackedBy,
} from 'store/customerRequests/selectors';

import useGroupsData from '../hooks/useGroupsData';
import { setSelectedCounterStackedChartGroupBy, setSelectedCounterStackedChartStackedBy } from 'store/customerRequests';
import { onCounterStackChartEntityClick } from '../store';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const selectedGroupBy = useSelector(getSelectedCounterStackedChartGroupBy);
    const selectedStackedBy = useSelector(getSelectedCounterStackedChartStackedBy);
    const getGroupData = useGroupsData();
    const filters = useSelector(getFiltersCompiled);
    const insightsData = useSelector(getInsightsDataForCounterStackedChart);
    const isInsightsDataUninitialized = useSelector(isUninitializedInsightsDataForCounterStackedChart);

    const _handleGroupedByChange = React.useCallback(
      value => {
        if (value?.key === selectedStackedBy?.key) {
          return;
        }

        dispatch(setSelectedCounterStackedChartGroupBy(value));
      },
      [selectedStackedBy?.key],
    );
    const _handleStackedByChange = React.useCallback(
      value => {
        if (value?.key === selectedGroupBy?.key) {
          return;
        }

        dispatch(setSelectedCounterStackedChartStackedBy(value));
      },
      [selectedGroupBy?.key],
    );

    React.useEffect(() => {
      dispatch(fetchCustomerRequestsInsightsForCounterStackedChart(selectedGroupBy.key, selectedStackedBy.key, filters));
    }, [selectedGroupBy, selectedStackedBy, filters]);

    const chartData = React.useMemo(() => {
      if (isInsightsDataUninitialized || !insightsData) return {};
      const normalizedDataByGroup = normalizeArray(insightsData, selectedGroupBy.key);
      const normalizedDataByStack = normalizeArray(insightsData, selectedStackedBy.key);
      const groupEntities = getGroupData(selectedGroupBy.key);
      const stackEntities = getGroupData(selectedStackedBy.key);
      const allGroupByEntities = Object.keys(normalizedDataByGroup)
        .map(i => groupEntities[i])
        .filter(i => !!i);
      const allStackEntities = Object.keys(normalizedDataByStack)
        .map(i => stackEntities[i])
        .filter(i => !!i);
      const _getItemId = item => {
        if (item?.key || isNumeric(item?.key)) return item.key;

        return item?.id || null;
      };
      const datasets = allStackEntities.reduce((acc, item) => {
        const groupItemsOnStack = normalizeArray(
          insightsData.filter(r => r[selectedStackedBy.key] === _getItemId(item)),
          selectedGroupBy.key,
        );

        return [
          ...acc,
          {
            label: item?.title || item?.name || UNDEFINED_LABEL,
            data: allGroupByEntities.map(entity => groupItemsOnStack[_getItemId(entity)]?.count || 0),
            backgroundColor: item?.color,
            hoverBackgroundColor: item?.color,
          },
        ];
      }, []);
      const labels = allGroupByEntities.map(entity => entity?.title || entity?.name || UNDEFINED_LABEL);

      return { labels, datasets };
    }, [insightsData]);

    const handleChartItemClick = (groupItemLabel, stackItemLabel) => {
      const groupEntities = getGroupData(selectedGroupBy.key);
      const stackEntities = getGroupData(selectedStackedBy.key);

      const selectedGroupEntity = Object.values(groupEntities).find(e => (e.title || e.name) === groupItemLabel);
      const selectedStackEntity = Object.values(stackEntities).find(e => (e.title || e.name) === stackItemLabel);

      dispatch(onCounterStackChartEntityClick(selectedGroupEntity, selectedStackEntity));
    };

    return (
      <Component
        {...props}
        groupBy={selectedGroupBy}
        stackedBy={selectedStackedBy}
        handleGroupedByChange={_handleGroupedByChange}
        handleStackedByChange={_handleStackedByChange}
        chartData={chartData}
        handleChartItemClick={handleChartItemClick}
      />
    );
  };
};

export default componentHOC;
