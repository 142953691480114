import { CUSTOM_FIELD_TYPES } from 'store/customFields/constants';

export default (customFields, type = CUSTOM_FIELD_TYPES.PROJECTS) => {
  const mappedCustomFields = customFields
    .filter(cf => cf.field_type === 'Date' && cf.title)
    .reduce((acc, cf) => {
      return { ...acc, [cf.title.toUpperCase()]: cf.key };
    }, {});

  if (type === CUSTOM_FIELD_TYPES.CUSTOMER_REQUESTS) {
    return {
      'DESIRED DATE': 'desired_date',
      ...mappedCustomFields,
    };
  }

  return {
    'TARGET START DATE': 'estimated_start_date',
    'PREDICTED END DATE': 'predictedEndDate',
    'TARGET END DATE': 'deadline',
    ...mappedCustomFields,
  };
};
