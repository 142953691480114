import axios from 'axios';

import { pathOr } from 'ramda';

import { createThunk } from '../../utils/store/thunk';

import { FETCH_PROJECT_HISTORY_NEXT_PAGE } from './types';

import { PROJECT } from './constants';

export const fetchProjectHistoryNextPage = (params = {}) => {
  return (dispatch, getState) => {
    const nextPage = pathOr(null, [PROJECT, '_meta', 'next'])(getState().history);

    if (!nextPage) return;

    dispatch(createThunk(FETCH_PROJECT_HISTORY_NEXT_PAGE, axios.get(nextPage, { params })));
  };
};
