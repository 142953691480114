import React, { useEffect } from 'react';
import queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';

const MIRO_SETTINGS_URL = '/settings/integrations/miro';

const MiroRedirectAuth = props => {
  useEffect(() => {
    const params = queryString.parse(props.location.search);

    if (!params) return;

    const obj = { integrationType: 'miro', ...params };

    if (window.opener) {
      window.opener.postMessage(JSON.stringify(obj), '*');
      window.close();
    } else if (params.authorize) {
      window.location = queryString.stringifyUrl({ url: MIRO_SETTINGS_URL, query: params });
    }
  }, [props.location.search]);

  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        marginTop: '-1em' /* set to a negative number 1/2 of your height */,
        marginLeft: '-2em' /* set to a negative number 1/2 of your width */,
      }}
    >
      <CircularProgress size={50} />
    </div>
  );
};

export default MiroRedirectAuth;
