import { path as ramdaPath, defaultTo } from 'ramda';

const DEFAULT_ITEM_HEIGHT = 30;
const MAX_ROOT_LIST_HEIGHT = 326; // this was being done by css

const defaultToOne = defaultTo(1);

// root list does not have counters, we need to sum up all counts from all items
export const getAllCountForRoot = (openItems, childrenLength, rootList) => {
  if (!rootList || !Object.values(openItems)?.length) {
    return childrenLength;
  }
  let totalCount = 0;

  Object.values(openItems).forEach(item => {
    totalCount += defaultToOne(item?.count);
  });

  return totalCount;
};

// size of the children list should not include the parent itself
const getCountForChildList = (path, openItems) => defaultToOne(ramdaPath(path, openItems)?.count) - 1;

export const getVirtualizedListHeight = (
  rootList,
  path,
  openItems,
  totalItems,
  itemHeight = DEFAULT_ITEM_HEIGHT,
  rootHeight = MAX_ROOT_LIST_HEIGHT,
) => {
  const count = path?.length ? getCountForChildList(path, openItems) : getAllCountForRoot(openItems, totalItems, rootList);

  const calculatedListHeight = defaultToOne(count) * itemHeight;

  return rootList ? Math.min(rootHeight, calculatedListHeight) : calculatedListHeight;
};

// get size of each item including the visible children
export const makeGetItemSize =
  (path, items, openItems, itemHeight = DEFAULT_ITEM_HEIGHT) =>
  index => {
    const item = items[index];
    const key = item?.id;
    const count = defaultToOne(ramdaPath([...path, key], openItems)?.count);

    return itemHeight * count;
  };
