import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProjectComments, getProjectCommentsMeta } from 'store/comments/selectors';
import {
  fetchProjectComments,
  saveProjectComment,
  fetchNextProjectComments,
  deleteProjectComment,
  editProjectComment,
} from 'store/comments';

const containerHOC = Component => {
  return props => {
    const { projectId, field, isOpen } = props;
    const dispatch = useDispatch();

    const comments = useSelector(state => getProjectComments(state, projectId));
    const commentsMeta = useSelector(state => getProjectCommentsMeta(state, projectId));

    React.useEffect(() => {
      if (!projectId || !isOpen) return;

      dispatch(fetchProjectComments(projectId, field));
    }, [projectId]);

    const _handleAddComment = ({ text: plainText, mentions, editorState, integrationType, channel }) => {
      let { blocks } = editorState;
      const firstRealIndex = blocks.findIndex(s => s.text.length);

      blocks = blocks.slice(firstRealIndex);

      const lastRealIndex =
        blocks.length -
        blocks
          .slice()
          .reverse()
          .findIndex(s => s.text.length);

      blocks = blocks.slice(0, lastRealIndex);
      editorState.blocks = blocks;

      dispatch(
        saveProjectComment(projectId, { field, plainText: plainText.trim(), mentions, editorState, integrationType, channel }),
      );
    };

    const _fetchNext = () => {
      dispatch(fetchNextProjectComments(projectId, field));
    };

    const deleteComment = useCallback(
      commentId => {
        dispatch(deleteProjectComment(projectId, commentId));
      },
      [projectId],
    );

    const editComment = useCallback(
      (commentId, data) => {
        return dispatch(editProjectComment(projectId, commentId, data));
      },
      [projectId],
    );

    const hasMoreComments = commentsMeta && !!commentsMeta.next;

    return (
      <Component
        {...props}
        addComment={_handleAddComment}
        comments={comments}
        hasMoreComments={hasMoreComments}
        fetchNext={_fetchNext}
        onDeleteComment={deleteComment}
        onEditComment={editComment}
      />
    );
  };
};

export default containerHOC;
