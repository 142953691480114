import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import flatten from 'lodash/flatten';

import { getAllProjectsFiltered } from 'store/projects/groupSelectors';

import useProjectsLocalSearch from 'hooks/projects/useProjectsLocalSearch';
import useProjectsFrontendFilters from 'hooks/filters/useProjectsFontendFilters';
import useProjectsLocalFilterByOwner from 'hooks/projects/useProjectsLocalFilterByOwner';

import sortByRank from 'utils/sortByRank';
import { makeItSelectable } from 'utils/grid';

import { IDEAS_FLAT_GRID_PAGE, MULTI_LAYER_FILTER } from 'constants/filters';
import { IDEAS } from 'store/grids';
import useEnrichedSelectedGroupsFromState from 'hooks/grid/useGridSelectedGroups';
import useProjectGroups from 'hooks/useProjectGroups';
import { getOrgHasMetadataRoadmaps as getOrgHasMetadataRoadmapsSelector } from 'store/organization';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useSystemFields from 'hooks/useSystemFields';
import setIdeasHierarchyPath from 'utils/setIdeasHierarchyPath';

const avoidHierarchyOnFilter = true;
const viewType = IDEAS;
const pageId = IDEAS_FLAT_GRID_PAGE;

const CUSTOM_GROUPING_LAYER = 'customLayer';

/**
 * Custom hook that retrieves data for the Ideas Grid Flat component.
 *
 * @param {Object} options - The options for the hook.
 * @param {boolean} options.bulkDelete - Flag indicating whether bulk delete is enabled.
 * @param {string} options.searchText - The search text to filter the data.
 * @param {boolean} [options.showMyItemsOnly=false] - Flag indicating whether to show only the user's items.
 * @returns {Array} The data for the Ideas Grid Flat component.
 */
const useIdeasGridFlatData = ({ bulkDelete, searchText, showMyItemsOnly = false }) => {
  const allData = useSelector(state => getAllProjectsFiltered(state, MULTI_LAYER_FILTER, true, true));
  const projectsInAllLayers = useMemo(() => flatten(Object.values(allData)).sort(sortByRank), [allData]);

  const filterMyItems = useProjectsLocalFilterByOwner(projectsInAllLayers, showMyItemsOnly, avoidHierarchyOnFilter);
  const [filterBySearchString] = useProjectsLocalSearch(projectsInAllLayers, searchText, avoidHierarchyOnFilter);

  const { applyFrontendFiltersOnProject, applyFrontendFilters } = useProjectsFrontendFilters(filterMyItems, filterBySearchString);

  const projectsFilters = useMemo(() => [applyFrontendFiltersOnProject], [applyFrontendFiltersOnProject]);

  const { selectedGroup1, selectedGroup2, selectedGroup3 } = useEnrichedSelectedGroupsFromState(viewType);

  const projectGroups = useProjectGroups({
    projectsFilters,
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    withHierarchy: false,
    customAllProjectsByLayer: {
      [CUSTOM_GROUPING_LAYER]: projectsInAllLayers,
    },
    page: pageId,
    layer: CUSTOM_GROUPING_LAYER,
  });
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmapsSelector);
  const { getDefaultRoadmapTitleForMetadataItem } = useOrganizationsAccessControl();
  const [getSystemFieldName] = useSystemFields();

  const data = useMemo(() => {
    let flatData = projectsInAllLayers;

    if (bulkDelete) flatData = makeItSelectable(flatData);

    return applyFrontendFilters(flatData);
  }, [projectsInAllLayers, bulkDelete, applyFrontendFilters]);

  const dataWithGroups = useMemo(
    () =>
      selectedGroup1?.key
        ? setIdeasHierarchyPath(
            {
              [CUSTOM_GROUPING_LAYER]: data,
            },
            CUSTOM_GROUPING_LAYER,
            getSystemFieldName('initiative', false),
            getSystemFieldName('bet', false),
            true,
            projectGroups,
            hasMetadataRoadmaps,
            getDefaultRoadmapTitleForMetadataItem,
            false,
          )
        : data,
    [selectedGroup1?.key, data, projectGroups, hasMetadataRoadmaps, getDefaultRoadmapTitleForMetadataItem],
  );

  return dataWithGroups;
};

export default useIdeasGridFlatData;
