import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

function BookADemoPageTemplate({ image, text }) {
  return (
    <Wrapper>
      <StyledTypography>{text}</StyledTypography>
      {image && (
        <Image>
          <img src={image} alt="Advanced feature" />
        </Image>
      )}
    </Wrapper>
  );
}

BookADemoPageTemplate.propTypes = {
  image: PropTypes.string,
};

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 68px);

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${props => props.theme.palette.background.alabaster};
`;

const StyledTypography = styled(props => <Typography variant="h6" {...props} />)`
  &&&& {
    line-height: 3em;

    a {
      color: ${props => props.theme.palette.text.purple};
    }
  }
`;

const Image = styled(Paper)`
  width: 80%;
  padding: 5px;

  > img {
    width: 100%;
    height: auto;
    opacity: 80%;
  }
`;

export default BookADemoPageTemplate;
