import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { not } from 'ramda';
import { useDebouncedCallback } from 'use-debounce';

import NewDragonInput from 'design-system/molecules/NewDragonInput/index';

import ListContainer from './components/ListContainer';

const GLOBAL_SEARCH_DEBOUNCE_DELAY = 100;

const GlobalSearchInput = ({ fetchData, data, isLoadingData, onClickItem, onCloseList }) => {
  const [value, setValue] = useState('');
  const [listIsOpen, setListIsOpen] = useState(false);

  const inputRef = useRef();
  const listRef = useRef();

  const [debouncedLoadData] = useDebouncedCallback(fetchData, GLOBAL_SEARCH_DEBOUNCE_DELAY);

  const handleOnFocus = () => {
    if (not(listIsOpen)) {
      setListIsOpen(true);

      debouncedLoadData(value);
    }
  };

  const handleCloseList = () => {
    setListIsOpen(false);

    if (onCloseList) {
      onCloseList();
    }
  };

  const handleOnChange = v => {
    setValue(v);

    debouncedLoadData(v);
  };

  const handleOnBlur = e => {
    if (listRef.current && listRef.current.contains(e.relatedTarget)) {
      return;
    }

    handleCloseList();
  };

  const handleClickItem = item => {
    onClickItem(item);

    handleCloseList();
  };

  return (
    <Wrapper>
      <NewDragonInput
        inputRef={inputRef}
        id="global_search"
        type="search"
        value={value}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
      <ListContainer
        listRef={listRef}
        inputRef={inputRef}
        isOpen={listIsOpen}
        data={data}
        isLoading={isLoadingData}
        onClickItem={handleClickItem}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default GlobalSearchInput;
