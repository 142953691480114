import { stringComparator } from '../comparators';

// TODO: readonly version for now.

const planningStageColumnDef = {
  cellClassRules: {
    'material--blue': params => params.value === 'Planning',
    'material--green': params => params.value === 'Confirmed',
    'material--darkGray': params => params.value === 'Archived',
    'material--darkerGray': params => params.value === 'Completed',
  },
  field: 'planningStage',
  headerName: 'Planning Stage',
  width: 150,
  comparator: stringComparator,
};

export default planningStageColumnDef;
