/**
 * Draggable Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */

import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Draggable } from 'react-beautiful-dnd';

export default props => {
  // datasets
  const { id, index, children, draggingFlagHandler, metadata } = props;
  // styles
  const { padding, border, shadow, margin, disabled } = props;

  return (
    <Wrapper draggableId={`${id}`} index={index} isDragDisabled={disabled}>
      {(provided, snapshot) => {
        useEffect(() => {
          if (draggingFlagHandler) {
            draggingFlagHandler(snapshot.isDragging, metadata);
          }
        }, [snapshot.isDragging]);

        return (
          <InnerWrapper
            ref={provided.innerRef}
            {...provided.draggableProps}
            padding={padding}
            border={border}
            shadow={shadow}
            margin={margin}
            disabled={disabled}
          >
            <Box {...provided.dragHandleProps}>{children}</Box>
          </InnerWrapper>

        );
      }}
    </Wrapper>
  );
};

const Wrapper = styled(Draggable)`
  &&&& {
  }
`;

const InnerWrapper = styled.div`
  &&&& {
    ${({ disabled }) =>
      !disabled &&
      `
      cursor: pointer;
    `}
    ${({ padding }) =>
      padding &&
      `
      padding: ${padding};
    `}
    ${({ border }) =>
      border &&
      `
      border: ${border};
    `}
    ${({ shadow }) =>
      shadow &&
      `
      box-shadow: 0px 2px 3px #6F77821A;
    `}
    ${({ margin }) =>
      margin &&
      `
      margin: ${margin};
    `}
  }
`;

const Box = styled.div``;
