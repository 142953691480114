import React, { useMemo } from 'react';
import styled from 'styled-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Card from 'design-system/molecules/Card/index';

import WidgetGrid from 'design-system/organisms/WidgetGrid';
import GroupHeaderInnerRenderer from 'containers/Grids/OkrsGrid/components/GroupHeaderInnerRenderer';
import CenteredLoading from 'features/MissionControlHome/components/CenteredLoading';
import EmptyMessage from 'features/MissionControlHome/components/EmptyMessage';
import { getWidgetHeight } from 'features/PortfolioOverview/widgets/helpers';
import useNumberOfTextRowsToDisplayOnGridCell from 'hooks/useNumberOfTextRowsToDisplayOnGridCell';
import useUpdateGridWidgetHeight from 'features/MissionControlHome/widgets/useUpdateGridWidgetHeight';
import okrHealthColumnDef from 'design-system/molecules/AgGridReact-New/columns/okrHealthColumnDef';
import { ProgressCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { ProgressCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import useMissionControlMyOkrs from './useMissionControlMyOkrs';

const GRID_MAX_ROWS = 5;
const GRID_MIN_HEIGHT = 100;

const outcomeProgressColumnDef = {
  width: 135,
  minWidth: 100,
  editable: false,
  field: 'progress',
  headerName: 'Goal Progress',
  cellRenderer: ProgressCellRenderer,
  cellEditor: ProgressCellEditor,
  resizable: true,
};

const healthColumnDef = {
  ...okrHealthColumnDef,
  resizable: true,
};

const OkrsList = ({ id, updateHeightOnLayout = () => {} }) => {
  const {
    objectivesAndKeyResultsWithHierarchy,
    getRowId,
    dataLength,
    title,
    headerName,
    entitySystemFieldName,
    openObjectiveDrawer,
    isLoading,
    goToOkrsPage,
  } = useMissionControlMyOkrs();

  const hasPagination = dataLength > GRID_MAX_ROWS;

  const { getNumberOfTextRowsToDisplayOnGridCell } = useNumberOfTextRowsToDisplayOnGridCell();

  const { updateWidgetHeight, gridHeight } = useUpdateGridWidgetHeight({
    widgetId: id,
    getWidgetHeight,
    updateHeightOnLayout,
    hasPagination,
    maxItems: GRID_MAX_ROWS,
  });

  const groupColumnDef = useMemo(
    () => ({
      headerName,
      field: 'title',
      suppressMovable: true,
      suppressMenu: true,
      cellClass: 'ag-react-editable-container--expandable ag-title-cell',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: GroupHeaderInnerRenderer,
        suppressCount: true,
        suppressDoubleClickExpand: true,
        openObjectiveDrawer,
        getNumberOfTextRowsToDisplayOnGridCell,
      },
      rowDrag: false,
      editable: false,
      flex: 1,
      resizable: true,
    }),
    [openObjectiveDrawer, getNumberOfTextRowsToDisplayOnGridCell],
  );

  return (
    <StyledCard
      title={title}
      actions={[{ icon: <OpenInNewIcon />, onClick: goToOkrsPage, testId: 'open-my-okrs' }]}
      onClickTitle={goToOkrsPage}
    >
      {isLoading ? <CenteredLoading /> : null}
      {!isLoading && !dataLength ? <EmptyMessage entity={entitySystemFieldName} /> : null}
      {!isLoading && !!dataLength ? (
        <WidgetGrid
          hasPagination={hasPagination}
          getRowId={getRowId}
          data={objectivesAndKeyResultsWithHierarchy}
          isTreeView
          columnDefs={[groupColumnDef, healthColumnDef, outcomeProgressColumnDef]}
          pageSize={GRID_MAX_ROWS}
          numberOfRecords={dataLength}
          setVisibleItems={i => updateWidgetHeight(i)}
          gridHeight={gridHeight || GRID_MIN_HEIGHT}
        />
      ) : null}
    </StyledCard>
  );
};

export default OkrsList;

const StyledCard = styled(Card)`
  overflow: hidden;
`;
