import { NumericCellRenderer } from '../cellRenderers';
import { NumericCellEditor } from '../cellEditors';
import { numberComparator } from '../comparators';

import textCenterAlignedColumnDef from './textCenterAlignedColumnDef';

const numericColumnDef = {
  ...textCenterAlignedColumnDef,
  cellRenderer: NumericCellRenderer,
  cellEditor: NumericCellEditor,
  comparator: numberComparator,
  width: 180,
  minWidth: 50,
};

export default numericColumnDef;
