import { getThunkActionTypes } from 'utils/store/thunk';

export const FETCH_METRICS = 'FETCH_METRICS';
export const FETCH_METRICS_FULFILLED = 'FETCH_METRICS_FULFILLED';
export const FETCH_METRICS_REJECTED = 'FETCH_METRICS_REJECTED';

export const CREATE_METRIC = 'CREATE_METRIC';
export const CREATE_METRIC_VALUE = 'CREATE_METRIC_VALUE';
export const CREATE_METRIC_FULFILLED = 'CREATE_METRIC_FULFILLED';
export const CREATE_METRIC_VALUE_FULFILLED = 'CREATE_METRIC_VALUE_FULFILLED';

export const DELETE_METRIC_VALUE = 'DELETE_METRIC_VALUE';
export const DELETE_METRIC_VALUE_FULFILLED = 'DELETE_METRIC_VALUE_FULFILLED';

export const UPDATE_METRIC_BY_ID = 'UPDATE_METRIC_BY_ID';
export const UPDATE_METRIC_BY_ID_FULFILLED = 'UPDATE_METRIC_BY_ID_FULFILLED';

export const UPDATE_METRIC_VALUE = 'UPDATE_METRIC_VALUE';
export const UPDATE_METRIC_VALUE_FULFILLED = 'UPDATE_METRIC_VALUE_FULFILLED';

export const ADD_METRIC_WITHOUT_SAVE = 'ADD_METRIC_WITHOUT_SAVE';
export const REMOVE_UNSAVED_METRICS = 'REMOVE_UNSAVED_METRICS';

export const ADD_METRIC_VALUE_WITHOUT_SAVE = 'ADD_METRIC_VALUE_WITHOUT_SAVE';
export const REMOVE_UNSAVED_METRIC_VALUES = 'REMOVE_UNSAVED_METRIC_VALUES';

export const UPDATE_METRICS_SEARCH_TEXT = 'UPDATE_METRICS_SEARCH_TEXT';

export const UPDATE_IS_HIDING_ARCHIVED_METRICS = 'UPDATE_IS_HIDING_ARCHIVED_METRICS';

export const UPDATE_TABLE_VISIBLE_FIELDS = 'UPDATE_TABLE_VISIBLE_FIELDS';

export const UPDATE_METRIC_CLUSTERS_VISIBILITY = 'UPDATE_METRIC_CLUSTERS_VISIBILITY';
export const UPDATE_METRIC_VIEW_MODE_TIME_PERIOD = 'UPDATE_METRIC_VIEW_MODE_TIME_PERIOD';
export const UPDATE_METRIC_MOAR_VISIBILITY = 'UPDATE_METRIC_MOAR_VISIBILITY';
export const UPDATE_CHART_VISIBLE_LAYERS = 'UPDATE_CHART_VISIBLE_LAYERS';

export const UPDATE_METRIC_CHARTS_LAYOUT = 'UPDATE_METRIC_CHARTS_LAYOUT';
export const UPDATE_METRIC_CHARTS_LAYOUT_EDIT_MODE = 'UPDATE_METRIC_CHARTS_LAYOUT_EDIT_MODE';

export const IMPORT_METRIC_FROM_DATA_SOURCE = 'IMPORT_METRIC_FROM_DATA_SOURCE';
export const UNLINK_METRIC_FROM_DATA_SOURCE = 'UNLINK_METRIC_FROM_DATA_SOURCE';

const switchRowOrderactionTypes = {
  ...getThunkActionTypes('SWITCH_METRICS_ROW_ORDER'),
};

const createMetricRoadmapTypes = {
  ...getThunkActionTypes('CREATE_METRIC_ROADMAP'),
};
const deleteMetricRoadmapTypes = {
  ...getThunkActionTypes('DELETE_METRIC_ROADMAP'),
};
const bulkDeleteMetricRoadmapTypes = {
  ...getThunkActionTypes('BULK_DELETE_METRIC_ROADMAP'),
};
const fetchMetricFilterOptionsTypes = {
  ...getThunkActionTypes('FETCH_METRIC_INTEGRATION_FILTER_OPTIONS'),
};

export const {
  FETCH_METRIC_INTEGRATION_FILTER_OPTIONS,
  FETCH_METRIC_INTEGRATION_FILTER_OPTIONS_FULFILLED,
  FETCH_METRIC_INTEGRATION_FILTER_OPTIONS_PENDING,
  FETCH_METRIC_INTEGRATION_FILTER_OPTIONS_REJECTED,
} = fetchMetricFilterOptionsTypes;

export const {
  CREATE_METRIC_ROADMAP,
  CREATE_METRIC_ROADMAP_FULFILLED,
  CREATE_METRIC_ROADMAP_PENDING,
  CREATE_METRIC_ROADMAP_REJECTED,
} = createMetricRoadmapTypes;

export const {
  DELETE_METRIC_ROADMAP,
  DELETE_METRIC_ROADMAP_FULFILLED,
  DELETE_METRIC_ROADMAP_PENDING,
  DELETE_METRIC_ROADMAP_REJECTED,
} = deleteMetricRoadmapTypes;

export const {
  BULK_DELETE_METRIC_ROADMAP,
  BULK_DELETE_METRIC_ROADMAP_FULFILLED,
  BULK_DELETE_METRIC_ROADMAP_PENDING,
  BULK_DELETE_METRIC_ROADMAP_REJECTED,
} = bulkDeleteMetricRoadmapTypes;

export const {
  SWITCH_METRICS_ROW_ORDER,
  SWITCH_METRICS_ROW_ORDER_FULFILLED,
  SWITCH_METRICS_ROW_ORDER_PENDING,
  SWITCH_METRICS_ROW_ORDER_REJECTED,
} = switchRowOrderactionTypes;
