import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  ADMIN_USER,
  LEADER_USER,
  OWNER_USER,
  SUPER_ADMIN_USER,
  READ_ONLY_USER,
  EDITOR_USER,
  MANAGER_USER,
} from '@dragonboat/permissions';
import { getCurrentUser } from 'store/login/selectors';

const useUserPermissions = () => {
  const currentUser = useSelector(getCurrentUser);

  const roleId = useMemo(() => currentUser.role_id, [currentUser.role_id]);

  const isAdmin = useMemo(() => roleId === ADMIN_USER, [roleId]);

  const isOwner = useMemo(() => roleId === OWNER_USER, [roleId]);

  const isSuperAdmin = useMemo(() => roleId === SUPER_ADMIN_USER, [roleId]);

  const isLeaderUser = useMemo(() => roleId === LEADER_USER, [roleId]);

  const isReadOnly = useMemo(() => roleId === READ_ONLY_USER, [roleId]);

  const isManager = useMemo(() => roleId === MANAGER_USER, [roleId]);

  const isEditor = useMemo(() => roleId === EDITOR_USER, [roleId]);

  return {
    roleId,
    isAdmin,
    isOwner,
    isSuperAdmin,
    isLeaderUser,
    isReadOnly,
    isManager,
    isEditor,
  };
};

export default useUserPermissions;
