import { getThunkActionTypes } from 'utils/store/thunk';

export const FETCH_CUSTOMER_REQUESTS = 'FETCH_CUSTOMER_REQUESTS';
export const FETCH_CUSTOMER_REQUESTS_PENDING = 'FETCH_CUSTOMER_REQUESTS_PENDING';
export const FETCH_CUSTOMER_REQUESTS_FULFILLED = 'FETCH_CUSTOMER_REQUESTS_FULFILLED';
export const FETCH_CUSTOMER_REQUESTS_REJECTED = 'FETCH_CUSTOMER_REQUESTS_REJECTED';

export const FETCH_NEXT_CUSTOMER_REQUESTS = 'FETCH_NEXT_CUSTOMER_REQUESTS';
export const FETCH_NEXT_CUSTOMER_REQUESTS_PENDING = 'FETCH_NEXT_CUSTOMER_REQUESTS_PENDING';
export const FETCH_NEXT_CUSTOMER_REQUESTS_FULFILLED = 'FETCH_NEXT_CUSTOMER_REQUESTS_FULFILLED';
export const FETCH_NEXT_CUSTOMER_REQUESTS_REJECTED = 'FETCH_NEXT_CUSTOMER_REQUESTS_REJECTED';

export const FETCH_VOTES_PER_CUSTOMER_REQUEST_PENDING = 'FETCH_VOTES_PER_CUSTOMER_REQUEST_PENDING';
export const FETCH_VOTES_PER_CUSTOMER_REQUEST_FULFILLED = 'FETCH_VOTES_PER_CUSTOMER_REQUEST_FULFILLED';

export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_REQUEST_PENDING = 'FETCH_CUSTOMER_REQUEST_PENDING';
export const FETCH_CUSTOMER_REQUEST_FULFILLED = 'FETCH_CUSTOMER_REQUEST_FULFILLED';
export const FETCH_CUSTOMER_REQUEST_REJECTED = 'FETCH_CUSTOMER_REQUEST_REJECTED';

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_REQUEST_PENDING = 'CREATE_CUSTOMER_REQUEST_PENDING';
export const CREATE_CUSTOMER_REQUEST_FULFILLED = 'CREATE_CUSTOMER_REQUEST_FULFILLED';
export const CREATE_CUSTOMER_REQUEST_REJECTED = 'CREATE_CUSTOMER_REQUEST_REJECTED';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_REQUEST_PENDING = 'UPDATE_CUSTOMER_REQUEST_PENDING';
export const UPDATE_CUSTOMER_REQUEST_FULFILLED = 'UPDATE_CUSTOMER_REQUEST_FULFILLED';
export const UPDATE_CUSTOMER_REQUEST_REJECTED = 'UPDATE_CUSTOMER_REQUEST_REJECTED';
export const UPDATE_CUSTOMER_REQUEST_DEBOUNCED_FULFILLED = 'UPDATE_CUSTOMER_REQUEST_DEBOUNCED_FULFILLED';

export const BULK_UPDATE_CUSTOMER_REQUESTS = 'BULK_UPDATE_CUSTOMER_REQUESTS';
export const BULK_UPDATE_CUSTOMER_REQUESTS_PENDING = 'BULK_UPDATE_CUSTOMER_REQUESTS_PENDING';
export const BULK_UPDATE_CUSTOMER_REQUESTS_FULFILLED = 'BULK_UPDATE_CUSTOMER_REQUESTS_FULFILLED';
export const BULK_UPDATE_CUSTOMER_REQUESTS_REJECTED = 'BULK_UPDATE_CUSTOMER_REQUESTS_REJECTED';

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_REQUEST_PENDING = 'DELETE_CUSTOMER_REQUEST_PENDING';
export const DELETE_CUSTOMER_REQUEST_FULFILLED = 'DELETE_CUSTOMER_REQUEST_FULFILLED';
export const DELETE_CUSTOMER_REQUEST_REJECTED = 'DELETE_CUSTOMER_REQUEST_REJECTED';

export const ADD_CUSTOMER_TO_CUSTOMER_REQUEST = 'ADD_CUSTOMER_TO_CUSTOMER_REQUEST';
export const ADD_CUSTOMER_TO_CUSTOMER_REQUEST_PENDING = 'ADD_CUSTOMER_TO_CUSTOMER_REQUEST_PENDING';
export const ADD_CUSTOMER_TO_CUSTOMER_REQUEST_FULFILLED = 'ADD_CUSTOMER_TO_CUSTOMER_REQUEST_FULFILLED';
export const ADD_CUSTOMER_TO_CUSTOMER_REQUEST_REJECTED = 'ADD_CUSTOMER_TO_CUSTOMER_REQUEST_REJECTED';

export const REMOVE_CUSTOMER_FROM_CUSTOMER_REQUEST = 'REMOVE_CUSTOMER_FROM_CUSTOMER_REQUEST';
export const REMOVE_CUSTOMER_FROM_CUSTOMER_REQUEST_PENDING = 'REMOVE_CUSTOMER_FROM_CUSTOMER_REQUEST_PENDING';
export const REMOVE_CUSTOMER_FROM_CUSTOMER_REQUEST_FULFILLED = 'REMOVE_CUSTOMER_FROM_CUSTOMER_REQUEST_FULFILLED';
export const REMOVE_CUSTOMER_FROM_CUSTOMER_REQUEST_REJECTED = 'REMOVE_CUSTOMER_FROM_CUSTOMER_REQUEST_REJECTED';

export const ADD_TAG_TO_CUSTOMER_REQUEST = 'ADD_TAG_TO_CUSTOMER_REQUEST';
export const ADD_TAG_TO_CUSTOMER_REQUEST_PENDING = 'ADD_TAG_TO_CUSTOMER_REQUEST_PENDING';
export const ADD_TAG_TO_CUSTOMER_REQUEST_FULFILLED = 'ADD_TAG_TO_CUSTOMER_REQUEST_FULFILLED';
export const ADD_TAG_TO_CUSTOMER_REQUEST_REJECTED = 'ADD_TAG_TO_CUSTOMER_REQUEST_REJECTED';

export const REMOVE_TAG_FROM_CUSTOMER_REQUEST = 'REMOVE_TAG_FROM_CUSTOMER_REQUEST';
export const REMOVE_TAG_FROM_CUSTOMER_REQUEST_PENDING = 'REMOVE_TAG_FROM_CUSTOMER_REQUEST_PENDING';
export const REMOVE_TAG_FROM_CUSTOMER_REQUEST_FULFILLED = 'REMOVE_TAG_FROM_CUSTOMER_REQUEST_FULFILLED';
export const REMOVE_TAG_FROM_CUSTOMER_REQUEST_REJECTED = 'REMOVE_TAG_FROM_CUSTOMER_REQUEST_REJECTED';

export const START_EDITING_COMMENT = 'START_EDITING_COMMENT';
export const STOP_EDITING_COMMENT = 'STOP_EDITING_COMMENT';

export const CREATE_PROJECT_FROM_CUSTOMER_REQUEST = 'CREATE_PROJECT_FROM_CUSTOMER_REQUEST';
export const CREATE_PROJECT_FROM_CUSTOMER_REQUEST_PENDING = 'CREATE_PROJECT_FROM_CUSTOMER_REQUEST_PENDING';
export const CREATE_PROJECT_FROM_CUSTOMER_REQUEST_FULFILLED = 'CREATE_PROJECT_FROM_CUSTOMER_REQUEST_FULFILLED';
export const CREATE_PROJECT_FROM_CUSTOMER_REQUEST_REJECTED = 'CREATE_PROJECT_FROM_CUSTOMER_REQUEST_REJECTED';

export const REMOVE_PROJECT_FROM_CUSTOMER_REQUEST = 'REMOVE_PROJECT_FROM_CUSTOMER_REQUEST';
export const REMOVE_PROJECT_FROM_CUSTOMER_REQUEST_PENDING = 'REMOVE_PROJECT_FROM_CUSTOMER_REQUEST_PENDING';
export const REMOVE_PROJECT_FROM_CUSTOMER_REQUEST_FULFILLED = 'REMOVE_PROJECT_FROM_CUSTOMER_REQUEST_FULFILLED';
export const REMOVE_PROJECT_FROM_CUSTOMER_REQUEST_REJECTED = 'REMOVE_PROJECT_FROM_CUSTOMER_REQUEST_REJECTED';

export const START_EDITING_CUSTOMER_REQUEST = 'START_EDITING_CUSTOMER_REQUEST';
export const STOP_EDITING_CUSTOMER_REQUEST = 'STOP_EDITING_CUSTOMER_REQUEST';
export const START_CREATING_CUSTOMER_REQUEST = 'START_CREATING_CUSTOMER_REQUEST';
export const STOP_CREATING_CUSTOMER_REQUEST = 'STOP_CREATING_CUSTOMER_REQUEST';

export const ADD_CUSTOMER_REQUESTS_FILTER = 'ADD_CUSTOMER_REQUESTS_FILTER';
export const REMOVE_CUSTOMER_REQUESTS_FILTER = 'REMOVE_CUSTOMER_REQUESTS_FILTER';
export const SET_CUSTOMER_REQUESTS_FILTER = 'SET_CUSTOMER_REQUESTS_FILTER';

export const SET_CUSTOMER_REQUESTS_MULTI_FILTERS_ACTIVE = 'SET_CUSTOMER_REQUESTS_MULTI_FILTERS_ACTIVE';

export const SAVE_CUSTOMER_REQUESTS_MULTI_FILTERS = 'SAVE_CUSTOMER_REQUESTS_MULTI_FILTERS';
export const SAVE_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED = 'SAVE_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED';

export const UPDATE_CUSTOMER_REQUESTS_MULTI_FILTERS = 'UPDATE_CUSTOMER_REQUESTS_MULTI_FILTERS';
export const UPDATE_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED = 'UPDATE_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED';

export const LOAD_CUSTOMER_REQUESTS_MULTI_FILTERS = 'LOAD_CUSTOMER_REQUESTS_MULTI_FILTERS';

export const DELETE_CUSTOMER_REQUESTS_MULTI_FILTERS = 'DELETE_CUSTOMER_REQUESTS_MULTI_FILTERS';
export const DELETE_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED = 'DELETE_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED';

export const SET_CUSTOMER_REQUESTS_SEARCH = 'SET_CUSTOMER_REQUESTS_SEARCH';

export const IMPORT_CUSTOMER_REQUESTS = 'IMPORT_CUSTOMER_REQUESTS';
export const IMPORT_CUSTOMER_REQUESTS_PENDING = 'IMPORT_CUSTOMER_REQUESTS_PENDING';
export const IMPORT_CUSTOMER_REQUESTS_REJECTED = 'IMPORT_CUSTOMER_REQUESTS_REJECTED';
export const IMPORT_CUSTOMER_REQUESTS_FULFILLED = 'IMPORT_CUSTOMER_REQUESTS_FULFILLED';

export const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW';

export const SWITCH_CUSTOMER_REQUEST_ROW_ORDER = 'SWITCH_CUSTOMER_REQUEST_ROW_ORDER';
export const SWITCH_CUSTOMER_REQUEST_ROW_ORDER_PENDING = 'SWITCH_CUSTOMER_REQUEST_ROW_ORDER_PENDING';
export const SWITCH_CUSTOMER_REQUEST_ROW_ORDER_FULFILLED = 'SWITCH_CUSTOMER_REQUEST_ROW_ORDER_FULFILLED';
export const SWITCH_CUSTOMER_REQUEST_ROW_ORDER_REJECTED = 'SWITCH_CUSTOMER_REQUEST_ROW_ORDER_REJECTED';

export const TOGGLE_CUSTOMER_REQUEST_CUSTOM_FIELDS_VIEW = 'TOGGLE_CUSTOMER_REQUEST_CUSTOM_FIELDS_VIEW';
export const TOGGLE_CUSTOMER_REQUEST_FORWARD_EMAILS_VIEW = 'TOGGLE_CUSTOMER_REQUEST_FORWARD_EMAILS_VIEW';

export const TOGGLE_CUSTOMER_REQUEST_PORTAL_SETTINGS_VIEW = 'TOGGLE_CUSTOMER_REQUEST_PORTAL_SETTINGS_VIEW';

export const SET_CUSTOMER_REQUESTS_GRID_VISIBLE_FIELDS = 'SET_CUSTOMER_REQUESTS_GRID_VISIBLE_FIELDS';
export const SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_PIE_CHART_GROUP_BY =
  'SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_PIE_CHART_GROUP_BY';
export const SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_GROUP_BY =
  'SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_GROUP_BY';
export const SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_STACKED_BY =
  'SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_STACKED_BY';
export const UPDATE_CUSTOMER_REQUEST_FORM_DATA = 'UPDATE_CUSTOMER_REQUEST_FORM_DATA';

export const TOGGLE_BULK_UPDATE_CUSTOMER_REQUESTS = 'TOGGLE_BULK_UPDATE_CUSTOMER_REQUESTS';

export const BULK_DELETE_CUSTOMER_REQUESTS = 'BULK_DELETE_CUSTOMER_REQUESTS';
export const BULK_DELETE_CUSTOMER_REQUESTS_PENDING = 'BULK_DELETE_CUSTOMER_REQUESTS_PENDING';
export const BULK_DELETE_CUSTOMER_REQUESTS_FULFILLED = 'BULK_DELETE_CUSTOMER_REQUESTS_FULFILLED';
export const BULK_DELETE_CUSTOMER_REQUESTS_REJECTED = 'BULK_DELETE_CUSTOMER_REQUESTS_REJECTED';

export const WATCH_CUSTOMER_REQUESTS = 'WATCH_CUSTOMER_REQUESTS';
export const WATCH_CUSTOMER_REQUESTS_FULFILLED = 'WATCH_CUSTOMER_REQUESTS_FULFILLED';
export const UNWATCH_CUSTOMER_REQUESTS = 'UNWATCH_CUSTOMER_REQUESTS';
export const UNWATCH_CUSTOMER_REQUESTS_FULFILLED = 'UNWATCH_CUSTOMER_REQUESTS_FULFILLED';

export const NOTIFY_CUSTOMER_REQUEST_WATCHERS = 'NOTIFY_CUSTOMER_REQUEST_WATCHERS';
export const NOTIFY_CUSTOMER_REQUEST_WATCHERS_PENDING = 'NOTIFY_CUSTOMER_REQUEST_WATCHERS_PENDING';
export const NOTIFY_CUSTOMER_REQUEST_WATCHERS_FULFILLED = 'NOTIFY_CUSTOMER_REQUEST_WATCHERS_FULFILLED';
export const NOTIFY_CUSTOMER_REQUEST_WATCHERS_REJECTED = 'NOTIFY_CUSTOMER_REQUEST_WATCHERS_REJECTED';

export const ADD_USER_TO_CUSTOMER_REQUESTS_WATCHERS = 'ADD_USER_TO_CUSTOMER_REQUESTS_WATCHERS';

export const SET_CUSTOMER_REQUEST_LIST_PAGE = 'SET_CUSTOMER_REQUEST_LIST_PAGE';
export const SET_CUSTOMER_REQUEST_RESULT_COUNT = 'SET_CUSTOMER_REQUEST_RESULT_COUNT';
export const SET_CUSTOMER_REQUEST_PAGE_LIMIT = 'SET_CUSTOMER_REQUEST_PAGE_LIMIT';

export const SET_MULTI_FILTERS_LOADED = 'SET_MULTI_FILTERS_LOADED';

const actionTypes = {
  ...getThunkActionTypes('CREATE_CUSTOMER_REQUEST_FROM_PROJECT'),
  ...getThunkActionTypes('ADD_CUSTOMER_REQUEST_FROM_PROJECT'),
  ...getThunkActionTypes('FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_PIE_CHART'),
  ...getThunkActionTypes('FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_STACKED_CHART'),
  ...getThunkActionTypes('SET_CUSTOMER_REQUESTS_MULTI_FILTERS'),
  ...getThunkActionTypes('UPDATE_CUSTOMER_REQUESTS_PERSONAS'),
  ...getThunkActionTypes('UPDATE_CUSTOMER_REQUESTS_LIFECYCLES'),
  ...getThunkActionTypes('MERGE_CUSTOMER_REQUESTS'),
};

export const {
  CREATE_CUSTOMER_REQUEST_FROM_PROJECT,
  CREATE_CUSTOMER_REQUEST_FROM_PROJECT_PENDING,
  CREATE_CUSTOMER_REQUEST_FROM_PROJECT_FULFILLED,
  CREATE_CUSTOMER_REQUEST_FROM_PROJECT_REJECTED,

  ADD_CUSTOMER_REQUEST_FROM_PROJECT,
  ADD_CUSTOMER_REQUEST_FROM_PROJECT_PENDING,
  ADD_CUSTOMER_REQUEST_FROM_PROJECT_FULFILLED,
  ADD_CUSTOMER_REQUEST_FROM_PROJECT_REJECTED,

  FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_PIE_CHART,

  FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_STACKED_CHART,

  SET_CUSTOMER_REQUESTS_MULTI_FILTERS,
  SET_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED,
  SET_CUSTOMER_REQUESTS_MULTI_FILTERS_PENDING,

  UPDATE_CUSTOMER_REQUESTS_PERSONAS,
  UPDATE_CUSTOMER_REQUESTS_PERSONAS_FULFILLED,

  UPDATE_CUSTOMER_REQUESTS_LIFECYCLES,
  UPDATE_CUSTOMER_REQUESTS_LIFECYCLES_FULFILLED,

  MERGE_CUSTOMER_REQUESTS,
  MERGE_CUSTOMER_REQUESTS_FULFILLED,
} = actionTypes;

export const REALTIME_PARTIAL_BULK_UPDATE_CUSTOMER_REQUESTS = 'REALTIME_PARTIAL_BULK_UPDATE_CUSTOMER_REQUESTS';

export default actionTypes;
