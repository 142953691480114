import { useDispatch } from 'react-redux';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { fetchTeams } from 'store/teams';
import { fetchSkills } from 'store/skills';
import { fetchRoadmaps } from 'store/roadmaps';
import { fetchThemes } from 'store/themes';
import { fetchObjectives } from 'store/objectives';
import { fetchTimeframes } from 'store/timeframes';
import { fetchUsers } from 'store/users';
import { fetchTags } from 'store/tags';
import { fetchCustomers } from 'store/customers';
import { fetchPhases } from 'store/phases';
import { fetchMetrics } from 'store/metrics';
import { fetchCustomFields } from 'store/customFields';
import { fetchCategories } from 'store/categories';
import { fetchMetricLevels } from 'features/MetricLevels/store';
import { ALL_METADATA } from 'constants/common';
import { fetchPriorities } from 'store/priorities';
import { fetchPersonasData } from 'features/Personas/store/thunks';
import { fetchLifecyclesData } from 'features/Lifecycles/store/thunks';

const DEFAULT_METADATA_TO_FETCH = ALL_METADATA.filter(k => k !== 'users');
/**
 * Custom Hook to load metadata on page load
 *
 * @returns isLoaded
 */
const useLoadPageMetadata = (metadataToFetch = DEFAULT_METADATA_TO_FETCH) => {
  const dispatch = useDispatch();

  const { canView } = usePermissions();

  const canViewMetricLevels = canView(PERMISSION_FEATURES.metricLevels);
  const canViewPersonas = canView(PERMISSION_FEATURES.personas);
  const canViewLifecycles = canView(PERMISSION_FEATURES.lifecycles);

  const initLoad = async (ignoreCache = false) => {
    const metadata = [
      ...(metadataToFetch.includes('priorities') ? [dispatch(fetchPriorities())] : []),
      ...(metadataToFetch.includes('teams') ? [dispatch(fetchTeams({}, ignoreCache))] : []),
      ...(metadataToFetch.includes('skills') ? [dispatch(fetchSkills({}, ignoreCache))] : []),
      ...(metadataToFetch.includes('roadmaps') ? [dispatch(fetchRoadmaps({}, ignoreCache))] : []),
      ...(metadataToFetch.includes('themes') ? [dispatch(fetchThemes({}, ignoreCache))] : []),
      ...(metadataToFetch.includes('objectives') ? [dispatch(fetchObjectives({}, ignoreCache))] : []),
      ...(metadataToFetch.includes('timeframes') ? [dispatch(fetchTimeframes({}, ignoreCache))] : []),
      ...(metadataToFetch.includes('users') ? [dispatch(fetchUsers({}, ignoreCache))] : []),
      ...(metadataToFetch.includes('tags') ? [dispatch(fetchTags({}, ignoreCache))] : []),
      ...(metadataToFetch.includes('customers') ? [dispatch(fetchCustomers({}, ignoreCache))] : []),
      ...(metadataToFetch.includes('phases') ? [dispatch(fetchPhases({}, ignoreCache))] : []),
      ...(metadataToFetch.includes('categories') ? [dispatch(fetchCategories({}, ignoreCache))] : []),
      ...(canViewMetricLevels && metadataToFetch.includes('metricLevels') ? [dispatch(fetchMetricLevels())] : []),
      ...(canViewPersonas ? [dispatch(fetchPersonasData())] : []),
      ...(canViewLifecycles ? [dispatch(fetchLifecyclesData())] : []),
    ];
    const alwaysLoaded = [dispatch(fetchMetrics({}, ignoreCache)), dispatch(fetchCustomFields({}, ignoreCache))];

    return Promise.all([...metadata, ...alwaysLoaded]);
  };

  return initLoad;
};

export default useLoadPageMetadata;
