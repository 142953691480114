function desc(a, b, orderBy, dataMapper) {
  const aValue = dataMapper(a);
  const bValue = dataMapper(b);

  if (bValue < aValue) return -1;
  if (bValue > aValue) return 1;
  return 0;
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getSorting(order, orderBy, dataMapper) {
  return order === SortDirection.DESC ? (a, b) => desc(a, b, orderBy, dataMapper) : (a, b) => -desc(a, b, orderBy, dataMapper);
}

export const SortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
};
