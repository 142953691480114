import React, { forwardRef } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { InputWrapper, Input, InputIcon, SearchRow, SearchIcon } from './styled';

const getIcon = styleAsAutocomplete => {
  return styleAsAutocomplete ? (
    <InputIcon>
      <ExpandMoreIcon />
    </InputIcon>
  ) : (
    <InputAdornment position="start">
      <SearchIcon />
    </InputAdornment>
  );
};

const MultiFilterSearchRow = forwardRef(({ handleChange, hideSearch = false, search, styleAsAutocomplete, placeholder }, ref) => {
  return hideSearch ? null : (
    <SearchRow $styleAsAutocomplete={styleAsAutocomplete}>
      <InputWrapper $styleAsAutocomplete={styleAsAutocomplete}>
        <Input
          fullWidth
          disableUnderline
          value={search}
          onChange={handleChange}
          startAdornment={getIcon(styleAsAutocomplete)}
          inputRef={ref}
          placeholder={placeholder}
        />
      </InputWrapper>
    </SearchRow>
  );
});

MultiFilterSearchRow.name = 'MultiFilterSearchRow';

export default MultiFilterSearchRow;
