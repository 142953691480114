import React, { useMemo } from 'react';
import { NumericCellRenderer, BubbleNumericCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { NumericCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import CommentIcon from 'design-system/atoms/CommentIcon';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { numericColumnDef, sumNumericFieldAggColumnDef } from 'design-system/molecules/AgGridReact-New/columns';

import { EmptyValueIfNotProjectRenderer } from './renderers';
import useCommentsLightboxContext from '../useCommentsLightbox';

/**
 * @function useScoringFieldsColumnsDefinitions
 *
 * Scoring fields columns to be used on ideas grid
 *
 * @return {Object}
 */
const useScoringFieldsColumnsDefinitions = ({ getSystemFieldName }) => {
  const { openCommentsLightbox } = useCommentsLightboxContext();

  const benefitColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      ...sumNumericFieldAggColumnDef,
      field: 'business_value',
      headerName: 'Benefit',
      onLinkClick: id => openCommentsLightbox({ projectId: id, field: 'business_value' }),
      linkIcon: <CommentIcon />,
      cellEditor: NumericCellEditor,
      cellRenderer: NumericCellRenderer,
      cellRendererParams: {
        withoutZeros: true,
      },
      width: 120,
    };
  }, []);

  const monthToValueColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      field: 'value_offset_in_months',
      headerName: getSystemFieldName('value_offset_in_months'),
      cellEditor: NumericCellEditor,
      cellRenderer: NumericCellRenderer,
      cellEditorParams: {
        allowNegative: false,
      },
      decimalScale: 0,
      width: 120,
    };
  }, [getSystemFieldName]);

  const effortColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      ...sumNumericFieldAggColumnDef,
      field: 'effort_score',
      headerName: 'Effort',
      onLinkClick: id => openCommentsLightbox({ projectId: id, field: 'effort_score' }),
      linkIcon: <CommentIcon />,
      cellEditor: NumericCellEditor,
      cellRenderer: NumericCellRenderer,
      cellRendererParams: {
        withoutZeros: true,
      },
      width: 120,
    };
  }, []);

  const plannedMoarColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      editable: false,
      field: 'planned_moar',
      headerName: 'Planned MoAR',
      suffix: '%',
      cellRenderer: EmptyValueIfNotProjectRenderer(BubbleNumericCellRenderer),
      cellRendererParams: {
        withoutZeros: true,
      },
      width: 100,
    };
  }, []);

  const strategicBenefitColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      field: 'benefit1',
      headerName: 'Strategic Benefit (0-5)',
      cellRenderer: EmptyValueIfNotProjectRenderer(NumericCellRenderer),
      comparator: stringComparator,
      width: 100,
    };
  }, []);

  const urgencyColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      field: 'benefit2',
      headerName: 'Urgency (0-5)',
      cellRenderer: EmptyValueIfNotProjectRenderer(NumericCellRenderer),
      comparator: stringComparator,
      width: 100,
    };
  }, []);

  const frequencyColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      field: 'benefit3',
      headerName: 'Frequency (0-5)',
      cellRenderer: EmptyValueIfNotProjectRenderer(NumericCellRenderer),
      comparator: stringComparator,
      width: 100,
    };
  }, []);

  const costColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      field: 'cost1',
      headerName: 'Cost (0-5)',
      cellRenderer: EmptyValueIfNotProjectRenderer(NumericCellRenderer),
      comparator: stringComparator,
      width: 100,
    };
  }, []);

  const totalScoreColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      field: 'priorityScore',
      headerName: 'Total Score',
      cellEditor: NumericCellEditor,
      cellRenderer: EmptyValueIfNotProjectRenderer(NumericCellRenderer),
      cellRendererParams: {
        withoutZeros: true,
      },
      width: 100,
    };
  }, []);

  const reachScoreColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      field: 'reach_score',
      headerName: 'Reach Score',
      cellEditor: NumericCellEditor,
      cellRenderer: EmptyValueIfNotProjectRenderer(NumericCellRenderer),
      cellRendererParams: {
        withoutZeros: true,
      },
      width: 100,
      onLinkClick: id => openCommentsLightbox({ projectId: id, field: 'reach_score' }),
      linkIcon: <CommentIcon />,
    };
  }, []);

  const impactScoreColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      field: 'impact_score',
      headerName: 'Impact Score',
      cellEditor: NumericCellEditor,
      cellRenderer: EmptyValueIfNotProjectRenderer(NumericCellRenderer),
      cellRendererParams: {
        withoutZeros: true,
      },
      width: 100,
      onLinkClick: id => openCommentsLightbox({ projectId: id, field: 'impact_score' }),
      linkIcon: <CommentIcon />,
    };
  }, []);

  const confidenceScoreColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      field: 'confidence_score',
      headerName: 'Confidence Score',
      cellEditor: NumericCellEditor,
      cellRenderer: EmptyValueIfNotProjectRenderer(NumericCellRenderer),
      cellRendererParams: {
        withoutZeros: true,
      },
      width: 100,
      onLinkClick: id => openCommentsLightbox({ projectId: id, field: 'confidence_score' }),
      linkIcon: <CommentIcon />,
    };
  }, []);

  const riceScoreColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      editable: false,
      field: 'riceScore',
      headerName: 'RICE Score',
      cellEditor: NumericCellEditor,
      cellRenderer: EmptyValueIfNotProjectRenderer(BubbleNumericCellRenderer),
      cellRendererParams: {
        withoutZeros: true,
      },
      width: 100,
      onLinkClick: id => openCommentsLightbox({ projectId: id, field: 'riceScore' }),
      linkIcon: <CommentIcon />,
    };
  }, []);

  return useMemo(
    () => [
      benefitColumnDef,
      monthToValueColumnDef,
      effortColumnDef,
      plannedMoarColumnDef,
      strategicBenefitColumnDef,
      urgencyColumnDef,
      frequencyColumnDef,
      costColumnDef,
      totalScoreColumnDef,
      reachScoreColumnDef,
      impactScoreColumnDef,
      confidenceScoreColumnDef,
      riceScoreColumnDef,
    ],
    [
      benefitColumnDef,
      monthToValueColumnDef,
      effortColumnDef,
      plannedMoarColumnDef,
      strategicBenefitColumnDef,
      urgencyColumnDef,
      frequencyColumnDef,
      costColumnDef,
      totalScoreColumnDef,
      reachScoreColumnDef,
      impactScoreColumnDef,
      confidenceScoreColumnDef,
      riceScoreColumnDef,
    ],
  );
};

export default useScoringFieldsColumnsDefinitions;
