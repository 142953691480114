import { path, pipe, prop, defaultTo } from 'ramda';

const exist = Boolean;

export const getObjectiveCorpData = pipe(path(['groupData', 'objectiveCorp']), defaultTo({}));
export const getObjectiveData = pipe(path(['groupData', 'objective']), defaultTo({}));
export const getKeyResult1Data = pipe(path(['groupData', 'keyResult1']), defaultTo({}));
export const getKeyResult2Data = pipe(path(['groupData', 'keyResult2']), defaultTo({}));

const isObjectiveCorp = pipe(getObjectiveCorpData, prop('id'), exist);
const isObjective = pipe(getObjectiveData, prop('id'), exist);
const isKeyResult1 = pipe(getKeyResult1Data, prop('id'), exist);
const isKeyResult2 = pipe(getKeyResult2Data, prop('id'), exist);

export const isOkr = data => isObjectiveCorp(data) || isObjective(data) || isKeyResult1(data) || isKeyResult2(data);
