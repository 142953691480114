import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { not } from 'ramda';

import FluidField from 'design-system/molecules/FluidField/index';
import TextFieldNoBorder from 'design-system/atoms/TextFieldNoBorder/index';

import ProjectType from 'containers/ProjectType';
import ProjectsDropdown from 'containers/ProjectsDropdown';
import useOrganizations from 'hooks/useOrganizations';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import IncludeAllParentsAcrossCorpSwitch from './IncludeAllParentsAcrossCorpSwitch';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow';
import { NONE_PLACEHOLDER } from 'constants/common';

const FluidIdeaParentField = props => {
  const { canView } = usePermissions();
  const { selectedProject, layer } = props;

  const {
    filters: { topLayer, availableLayers },
  } = useOrganizations();

  const searchLayers = useMemo(
    () => Object.values(availableLayers).filter(availableLayer => +availableLayer > +layer),
    [layer, availableLayers],
  );

  const [searchFilterOptions, setSearchFilterOptions] = useState({});

  const dropdownFooterComponent = useMemo(() => {
    if (not(canView(PERMISSION_FEATURES.crossBabyRoadmapSupport))) {
      return null;
    }

    return props => (
      <IncludeAllParentsAcrossCorpSwitch
        {...props}
        onChange={checked => setSearchFilterOptions({ includeAllCorpItems: checked })}
        checked={!!searchFilterOptions.includeAllCorpItems}
      />
    );
  }, [canView, searchFilterOptions, setSearchFilterOptions]);

  return (
    <FluidField
      {...props}
      renderer={(_, { placeholder } = {}) =>
        props.value && props.parent ? (
          <RenderContainer>
            <TooltipOnOverflow text={props.parent.title} elementWithOverflow="textarea:not([aria-hidden=true])" vertical>
              <TextFieldNoBorder
                value={props.parent.title}
                InputProps={{
                  startAdornment: props.parent?.layer && <StyledProjectType layer={props.parent.layer} />,
                }}
                fullWidth
                multiline
                rowsMax={2}
              />
            </TooltipOnOverflow>
          </RenderContainer>
        ) : (
          <RenderContainer>
            <TextFieldNoBorder placeholder={NONE_PLACEHOLDER} fullWidth multiline rowsMax={2} />
          </RenderContainer>
        )
      }
      editorRenderer={({ save, cancel }) => {
        const saveNewParent = newParent => {
          if (!newParent) {
            return save({
              parent_id: null,
            });
          }

          const dataToSave = {
            parent_id: newParent.id,
            parent: newParent,
          };

          const shouldAddParentValueField = fieldName => {
            return newParent[fieldName] && (!selectedProject || !selectedProject[fieldName]);
          };

          const selectedProjectHasParentRoadmap = !selectedProject || selectedProject.roadmap_id === newParent.roadmap_id;

          const selectedProjectHasParentObjective = !selectedProject || selectedProject.objective_id === newParent.objective_id;

          if (shouldAddParentValueField('roadmap_id')) {
            dataToSave.roadmap_id = newParent.roadmap_id;
          }

          if (shouldAddParentValueField('product_1_id') && selectedProjectHasParentRoadmap) {
            dataToSave.product_1_id = newParent.product_1_id;
          }

          if (shouldAddParentValueField('objective_id')) {
            dataToSave.objective_id = newParent.objective_id;
          }

          if (shouldAddParentValueField('key_result_1_id') && selectedProjectHasParentObjective) {
            dataToSave.key_result_1_id = newParent.key_result_1_id;
          }

          return save(dataToSave);
        };

        const handleProjectSelection = (newParent, { closeTree }) => {
          saveNewParent(newParent);

          closeTree();
        };

        return (
          <ProjectsDropdown
            initialValue={props?.parent?.title}
            componentId="lightbox-parent-field"
            displayLayer={topLayer}
            searchLayers={searchLayers}
            onProjectSelect={handleProjectSelection}
            onClickOutside={() => cancel()}
            nullOption
            searchFilterOptions={searchFilterOptions}
            dropdownFooterComponent={dropdownFooterComponent}
          />
        );
      }}
      ignoreBlurOn={() => true}
    />
  );
};

FluidIdeaParentField.propTypes = {
  ...FluidField.propTypes,
  hasHierarchy: PropTypes.bool.isRequired,
  layer: PropTypes.string.isRequired,
  initiatives: PropTypes.array.isRequired,
  bets: PropTypes.array.isRequired,
};

export default FluidIdeaParentField;

const RenderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 32px;
`;

const StyledProjectType = styled(ProjectType)`
  &&&& {
    display: flex;
    margin-right: 8px;

    svg {
      width: 14px;
      margin-right: 4px;
    }
  }
`;
