import moment from 'moment-timezone';

import { convertedDateTime } from 'utils';

import {
  FETCH_USERS_FULFILLED,
  CREATE_USER_FULFILLED,
  UPDATE_USER_FULFILLED,
  DELETE_USERS_FULFILLED,
  DELETE_USER_BY_ID_FULFILLED,
  ADD_USER_WITHOUT_SAVE,
  REMOVE_UNSAVED_USERS,
  UPDATE_USER_BY_ID_FULFILLED,
  MERGE_USERS_FULFILLED,
  UPDATE_USERS_SEARCH,
  FETCH_USERS_PENDING,
  CREATE_USERS_FULFILLED,
  UPDATE_USERS_FULFILLED,
  UNDO_CREATE_USERS_FULFILLED,
  UNDO_UPDATE_USERS_FULFILLED,
  BULK_DELETE_USERS_FULFILLED,
  UNDO_BULK_DELETE_USERS_FULFILLED,
  UPDATE_USERS_FILTERS,
  INVITE_USERS_FULFILLED,
} from './types';
import { CREATE_USER_ON_SETTINGS_FULFILLED } from '../users-settings/types';
import { IMPORT_CUSTOMER_REQUESTS_FULFILLED } from 'store/customerRequests/types';

import { pluck, isNil, isEmpty, either } from 'ramda';
import updateRows from 'store/utils/updateRows';

const isNilOrEmpty = either(isNil, isEmpty);

const initialState = {
  rows: [],
  isLoaded: false,
  searchText: '',
  filters: {},
  lastActionIds: [],
};

export function serializeUser(user) {
  // team and skill have to be an object to be editable on ag-grid
  // title has to have a character, so users without skill or team appears under an empty group on ag-grid group mode
  user.team = user.team ? user.team : { title: ' ' };
  user.skill = user.skill ? user.skill : { title: ' ' };
  if ('updated_at' in user) {
    user.updated_at = user.updated_at ? (user.updated_at = convertedDateTime(user.updated_at)) : '';
  }
  return user;
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS_PENDING:
      return {
        ...state,
        isLoaded: false,
      };
    case FETCH_USERS_FULFILLED:
      const { cached } = action.payload;
      const { ignoreCache } = action.meta || {};

      // on cached returns current state
      if (cached) return state;

      const { data } = action.payload.data;

      return {
        ...state,
        rows: data,
        isLoaded: true,

        lastCallsDate: ignoreCache ? null : moment().valueOf(),
      };
    case INVITE_USERS_FULFILLED: {
      const { data } = action.payload;

      if (isNilOrEmpty(data)) {
        return state;
      }

      const rows = updateRows(state.rows, data);

      return {
        ...state,
        rows,
      };
    }
    case CREATE_USER_ON_SETTINGS_FULFILLED:
    case CREATE_USER_FULFILLED: {
      const { payload } = action;

      // remove row used in ag-grid to show the empty row
      const rows = [...state.rows.filter(row => row.id)];

      if (!payload) {
        return {
          ...state,
          rows,
          isLoaded: true,
        };
      }

      return {
        ...state,
        rows: updateRows(rows, payload),
        isLoaded: true,
      };
    }
    case UPDATE_USER_BY_ID_FULFILLED:
    case UPDATE_USER_FULFILLED: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      const rows = updateRows(state.rows, payload);

      return {
        ...state,
        rows,
        isLoaded: true,
      };
    }
    case DELETE_USERS_FULFILLED: {
      const listWithoutDeletedUsers = state.rows.filter(user => action.payload.data.includes(user.id));

      return {
        ...state,
        rows: listWithoutDeletedUsers,
        isLoaded: true,
      };
    }
    case DELETE_USER_BY_ID_FULFILLED:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        rows: state.rows.filter(row => row.id !== action.payload),
      };
    // should be settings slice
    case ADD_USER_WITHOUT_SAVE:
      const rowsWithId = state.rows.filter(user => user.id);

      return {
        ...state,
        rows: [action.user || {}, ...rowsWithId],
      };
    // should be settings slice
    case REMOVE_UNSAVED_USERS:
      const savedUsers = state.rows.filter(user => user.id);

      return {
        ...state,
        rows: savedUsers,
      };
    // should be settings slice
    case MERGE_USERS_FULFILLED:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        rows: state.rows.filter(row => !action.payload.includes(row.id)),
      };
    // should be settings slice
    case UPDATE_USERS_SEARCH:
      return {
        ...state,
        searchText: action.payload,
      };
    // should be settings slice
    case UPDATE_USERS_FILTERS:
      return {
        ...state,
        filters: {
          ...(state.filters || {}),
          ...(action.payload || {}),
        },
      };
    case CREATE_USERS_FULFILLED: {
      const rowsWithNewAddedUsers = [...state.rows];
      const lastActionIds = [];

      action.payload.forEach(newUser => {
        if (!rowsWithNewAddedUsers.find(user => user.id === newUser.id)) {
          rowsWithNewAddedUsers.push(newUser);
        }
        lastActionIds.push(newUser.id);
      });

      return {
        ...state,
        lastActionIds,
        rows: rowsWithNewAddedUsers,
      };
    }
    case UPDATE_USERS_FULFILLED: {
      const rows = updateRows(state.rows, action.payload);

      return {
        ...state,
        rows,
        lastActionIds: pluck('id', action.payload),
      };
    }
    case BULK_DELETE_USERS_FULFILLED: {
      const rows = state.rows.filter(r => !action.payload.includes(String(r.id)));

      return {
        ...state,
        rows,
        lastActionIds: action.payload,
      };
    }
    case UNDO_UPDATE_USERS_FULFILLED: {
      const rows = updateRows(state.rows, action.payload);

      return {
        ...state,
        rows,
        lastActionIds: null,
      };
    }
    case UNDO_CREATE_USERS_FULFILLED: {
      const newRowsState = state.rows.filter(c => (state.lastActionIds || []).indexOf(c.id) === -1);

      return {
        ...state,
        lastActionIds: null,
        rows: newRowsState,
      };
    }
    case UNDO_BULK_DELETE_USERS_FULFILLED: {
      const rows = updateRows(state.rows, action.payload);

      return {
        ...state,
        rows,
        lastActionIds: null,
      };
    }
    case IMPORT_CUSTOMER_REQUESTS_FULFILLED: {
      const rows = updateRows(
        state.rows,
        action.payload
          .filter(cr => cr.owner)
          .map(cr => {
            return cr.owner;
          }),
      );

      return {
        ...state,
        rows,
      };
    }
    default:
      return state;
  }
}
