import Component from './Component';
import container from './container';
import HeaderFilters from './HeaderFilters';
import HeaderOptions from './HeaderOptions';

export default {
  HeaderFilters,
  HeaderOptions,
  Route: container(Component),
};
