import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_ADO_PROJECTS'),
};

export const { 
  FETCH_ADO_PROJECTS,
  FETCH_ADO_PROJECTS_RESET,
} = actionTypes;
