import { keys, path, prop, propOr, values } from 'ramda';

import generateColorForMetadata from './generateColorForMetadata';
import { UNDEFINED } from 'constants/common';
import clampLabelSize from 'utils/charts/clampLabelSize';
import { BAR_CHART_LABEL_MAX_NUM_CHARS } from 'constants/charts';
import invertedTextColor from 'design-system/utils/invertedTextColor';

const parseLabel = label => clampLabelSize(label, BAR_CHART_LABEL_MAX_NUM_CHARS);

const sliceIfNeeded = (arr, max) => (max ? arr.slice(0, max) : arr);

/**
 * Generates data for a stacked chart based on the provided chart data, groups metadata, and stacks metadata.
 *
 * @param {Array} chartData - The data for the stacked chart.
 * @param {Object} groupsMetadataById - Metadata for groups, organized by group ID.
 * @param {Object} stacksMetadataById - Metadata for stacks, organized by stack ID.
 * @param {number} maxItems - max items visible on dataset.
 * @returns {Object} An object containing datasets and labels for the stacked chart.
 */
const generateDataForStackedChart = (chartData, groupsMetadataById, stacksMetadataById, maxItems, bucketInformation) => {
  const data = sliceIfNeeded(chartData, maxItems);

  const allStacksSet = data.reduce((set, group) => {
    return keys(group.stacks).reduce((set, stack) => {
      return {
        ...set,
        [stack]: stack,
      };
    }, set);
  }, {});
  const allStacks = keys(allStacksSet);

  const datasetsByStack = allStacks.reduce((datasets, stack) => {
    const backgroundColor = generateColorForMetadata(stacksMetadataById[stack]);

    const emptyDataset = {
      label: parseLabel(propOr(UNDEFINED, 'title')(stacksMetadataById[stack])),
      data: Array(data.length).fill(0),
      rawData: Array(data.length).fill(0),
      backgroundColor,
      datalabels: {
        color: invertedTextColor(backgroundColor, true),
        formatter: (_, dataset) => path([dataset.dataIndex, 'stacks', stack], data) || '',
      },
      dataBucketIds: {},
    };

    return {
      ...datasets,
      [stack]: emptyDataset,
    };
  }, {});

  const clampedLabels = [];
  const unclampedLabels = [];

  data.forEach((group, groupIndex) => {
    clampedLabels.push(parseLabel(propOr(UNDEFINED, 'title')(groupsMetadataById[group.t])));
    unclampedLabels.push(propOr(UNDEFINED, 'title')(groupsMetadataById[group.t]));

    const totalForGroup = keys(group.stacks).reduce((acc, stackKey) => {
      return acc + +group.stacks[stackKey];
    }, 0);

    keys(group.stacks).forEach(stack => {
      if (datasetsByStack[stack]) {
        datasetsByStack[stack].rawData[groupIndex] = group.stacks[stack];
        datasetsByStack[stack].data[groupIndex] = (group.stacks[stack] * 100) / totalForGroup;
      }

      const dataBucketId = path([group.t, stack], bucketInformation);

      if (dataBucketId) {
        datasetsByStack[stack].dataBucketIds[group.t] = path([group.t, stack], bucketInformation);
      }
    });
  });

  const ids = data.map(entry => prop('id', groupsMetadataById[entry.t]));

  const datasets = values(datasetsByStack);

  return { datasets, labels: clampedLabels, unclampedLabels, ids };
};

export default generateDataForStackedChart;
