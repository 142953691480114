import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { spacing } from 'design-system/theme';

import useIntegrations from 'hooks/useIntegrations';
import { useCommonGridEventHandlers, useCommonGridProps } from 'design-system/molecules/AgGridReact-New/hooks';
import useGridTableApi from 'routes/Settings/hooks/useGridTableApi';
import useMultiIntegrationsListGridColumns from './hooks/useMultiIntegrationsListGridColumns';
import useMultiIntegrationsList from './hooks/useMultiIntegrationsList';

import { BaseGrid } from 'containers/Grids';
import { BASE_ROW_HEIGHT } from 'constants/grid';
import NewIntegrationButton from '../NewIntegrationButton';
import { ID_KEY, TITLE_HEIGHT } from './helpers/constants';

const getRowId = ({ data }) => data?.id || null;

const MultiIntegrationsList = ({
  integrationType,
  onCreateNewIntegration,
  userCanViewOrgIntegration,
  userCanUpdateOrgIntegration,
  onSelectIntegration,
  updateConfigDetails,
}) => {
  const { organizationIntegrations } = useIntegrations(integrationType);
  const { rows, openRowBaseUrlById, handleUpdateConfigDetails } = useMultiIntegrationsList(
    integrationType,
    organizationIntegrations,
    updateConfigDetails,
  );
  const { tableHeightInPx } = useGridTableApi(rows);

  const commonGridProps = useCommonGridProps({});
  const commonGridEventHandlers = useCommonGridEventHandlers({
    items: rows,
    idKey: ID_KEY,
    actions: {
      update: handleUpdateConfigDetails,
    },
  });

  const { columnDefs, defaultColDef } = useMultiIntegrationsListGridColumns(
    openRowBaseUrlById,
    onSelectIntegration,
    userCanViewOrgIntegration,
    userCanUpdateOrgIntegration,
  );

  return (
    <Wrapper>
      {rows.length > 0 && (
        <BaseGrid
          {...commonGridProps}
          {...commonGridEventHandlers}
          rowData={rows}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          headerHeight={BASE_ROW_HEIGHT}
          height={tableHeightInPx}
          getRowId={getRowId}
          enableSorting
        />
      )}
      {userCanUpdateOrgIntegration ? (
        <NewIntegrationButton integrationType={integrationType} onClick={onCreateNewIntegration} />
      ) : null}
    </Wrapper>
  );
};

MultiIntegrationsList.propTypes = {
  integrationType: PropTypes.string.isRequired,
  userCanUpdateOrgIntegration: PropTypes.bool.isRequired,
  userCanViewOrgIntegration: PropTypes.bool.isRequired,
  onCreateNewIntegration: PropTypes.func,
  onSelectIntegration: PropTypes.func,
  config: PropTypes.object,
};

const Wrapper = styled.div`
  margin-top: ${spacing(2)}px;
  height: calc(100% - ${TITLE_HEIGHT}px);

  .ag-layout-normal.ag-root {
    margin: 0;
  }
`;

export default MultiIntegrationsList;
