import React from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';

import NewDialog from 'design-system/organisms/NewDialog/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import { INTEGRATIONS_NAMES } from 'constants/integrations';

const DisableSyncWarningDialog = props => {
  const { integrationType, isOpen, onClose } = props;

  const integrationNameLabel = INTEGRATIONS_NAMES[integrationType];

  return (
    <NewDialog
      id="disable-sync-warning-dialog"
      title="Warning"
      open={isOpen}
      closeButton
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      actions={
        <React.Fragment>
          <Button onClick={onClose}>Close</Button>
        </React.Fragment>
      }
    >
      <TextWithMargin breakwords>
        Disabling update from dragonboat to {integrationNameLabel} will cause loss of data entered by your user in dragonboat.
      </TextWithMargin>
      <TextDeprecated breakwords variant="bold">
        Applying this setting only if no change of this field in dragonboat is allowed.
      </TextDeprecated>
    </NewDialog>
  );
};

const TextWithMargin = styled(TextDeprecated)`
  &&&& {
    margin-bottom: 30px;
  }
`;

export default DisableSyncWarningDialog;
