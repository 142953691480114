import reduceReducers from 'reduce-reducers';
import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';
import {
  CREATE_METRIC_FROM_CANVAS,
  CREATE_PROJECT_FROM_CANVAS,
  CREATE_METRIC_FROM_CANVAS_FULFILLED,
  CREATE_PROJECT_FROM_CANVAS_FULFILLED,
  CREATE_OBJECTIVE_FROM_CANVAS,
  CREATE_OBJECTIVE_FROM_CANVAS_FULFILLED,
  CREATE_KEY_RESULT_FROM_CANVAS,
  CREATE_KEY_RESULT_FROM_CANVAS_FULFILLED,
} from 'features/Canvas/store/types';

import {
  SET_GOALS_CANVAS_DATA,
  UPDATE_GOALS_CANVAS_EDGES_LAYOUT,
  UPDATE_GOALS_CANVAS_LAYOUT_CONFIG,
  UPDATE_GOALS_CANVAS_NODES_LAYOUT,
} from './types';
import {
  keyResultsAdapter,
  metricsAdapter,
  objectivesAdapter,
  projectsAdapter,
  timeframesAdapter,
} from 'features/whiteboard/store/helpers/adapters';
import { pickMetricFields, pickOkrFields, pickProjectFields, pickTimeframeFields } from 'features/Canvas/helpers/fieldPickers';

export const initialState = {
  nodesLayout: {
    nodes: [],
    labelsValue: {},
  },
  edgesLayout: [],
  layoutConfig: {
    displayNodes: ['objective', 'metric'],
    relationsConfig: {
      okrs: false,
      metrics: false,
    },
  },
  projects: projectsAdapter.getInitialState(),
  objectives: objectivesAdapter.getInitialState(),
  keyResults: keyResultsAdapter.getInitialState(),
  timeframes: timeframesAdapter.getInitialState(),
  metrics: metricsAdapter.getInitialState(),
  operations: bulkThunkInitialState([
    CREATE_PROJECT_FROM_CANVAS,
    CREATE_OBJECTIVE_FROM_CANVAS,
    CREATE_KEY_RESULT_FROM_CANVAS,
    CREATE_METRIC_FROM_CANVAS,
  ]),
};

const goalsCanvasReducer = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case SET_GOALS_CANVAS_DATA:
      return {
        ...state,
        objectives: objectivesAdapter.setAll(state?.objectives, payload?.objectives?.map(pickOkrFields) || []),
        keyResults: keyResultsAdapter.setAll(state?.keyResults, payload?.keyResults?.map(pickOkrFields) || []),
        projects: projectsAdapter.setAll(state?.projects, payload?.projects?.map(pickProjectFields) || []),
        metrics: metricsAdapter.setAll(state?.metrics, payload?.metrics?.map(pickMetricFields) || []),
        timeframes: timeframesAdapter.setAll(state?.timeframes, payload?.timeframes?.map(pickTimeframeFields) || []),
        userActionsMeta: {},
      };
    case UPDATE_GOALS_CANVAS_LAYOUT_CONFIG:
      return {
        ...state,
        layoutConfig: {
          ...action.payload,
        },
      };
    case UPDATE_GOALS_CANVAS_NODES_LAYOUT:
      return {
        ...state,
        nodesLayout: {
          ...state.nodesLayout,
          ...payload,
        },
      };
    case UPDATE_GOALS_CANVAS_EDGES_LAYOUT:
      return {
        ...state,
        edgesLayout: action.payload,
      };
    case CREATE_OBJECTIVE_FROM_CANVAS_FULFILLED:
      const { meta: { objectiveCreatedFrom } = {} } = action;

      if (!payload?.id) return state;

      return {
        ...state,
        objectives: objectivesAdapter.upsertOne(state?.objectives, pickOkrFields(payload)),
        userActionsMeta: objectiveCreatedFrom
          ? {
              entityCreatedFrom: objectiveCreatedFrom,
              entityId: payload?.id,
              type: 'objective',
            }
          : {},
      };
    case CREATE_KEY_RESULT_FROM_CANVAS_FULFILLED:
      const { meta: { keyResultCreatedFrom } = {} } = action;

      if (!payload?.id) return state;

      return {
        ...state,
        keyResults: keyResultsAdapter.upsertOne(state?.keyResults, pickOkrFields(payload)),
        userActionsMeta: keyResultCreatedFrom
          ? {
              entityCreatedFrom: keyResultCreatedFrom,
              entityId: payload?.id,
              type: 'keyResult',
            }
          : {},
      };
    case CREATE_METRIC_FROM_CANVAS_FULFILLED:
      const { meta: { metricCreatedFrom } = {} } = action;

      if (!payload?.id) return state;

      return {
        ...state,
        metrics: metricsAdapter.upsertOne(state?.metrics, pickMetricFields(payload)),
        userActionsMeta: metricCreatedFrom
          ? {
              entityCreatedFrom: metricCreatedFrom,
              entityId: payload?.id,
              type: 'metric',
            }
          : {},
      };
    case CREATE_PROJECT_FROM_CANVAS_FULFILLED:
      const { meta } = action;

      return {
        ...state,
        projects: projectsAdapter.upsertOne(state?.projects, pickProjectFields(payload)),
        userActionsMeta: meta?.projectCreatedFrom
          ? {
              entityCreatedFrom: meta.projectCreatedFrom,
              entityId: payload?.id,
              type: 'project',
            }
          : {},
      };
    default: {
      return state;
    }
  }
};

const operationsReducer = getThunksReducers([
  CREATE_PROJECT_FROM_CANVAS,
  CREATE_OBJECTIVE_FROM_CANVAS,
  CREATE_KEY_RESULT_FROM_CANVAS,
  CREATE_METRIC_FROM_CANVAS,
]);

const reducer = reduceReducers(initialState, goalsCanvasReducer, ...operationsReducer);

export default reducer;
