import { LOAD_PROGRESS_REPORT_DATA, UPDATE_PROGRESS_PAGE_STATE } from './types';
import { initStartDate, initEndDate, groupByLvl1Options, groupByLvl2Options, TABLE_SELECTED_LEVELS } from './consts';
import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import reduceReducers from 'reduce-reducers';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  LOAD_PROGRESS_REPORT_DATA,
]);

const initialState = {
  displayPreferences: {
    chart: true,
  },
  startDate: initStartDate(),
  endDate: initEndDate(),
  isPreferencing: false,
  jiras: [],
  activeProject: null,
  searchStr: '',
  progressChoice: 'objective',
  activeSearchItem: null,
  activeGroupItem: null,
  searchField: 'objective',
  groupField: 'phase',
  zoom: 90,
  isLoaded: false,
  isSyncing: false,
  firstTime: true,
  selectedGroupByLvl1: groupByLvl1Options().find(opt => opt.key === 'timeframe'),
  selectedGroupByLvl2: groupByLvl2Options().find(opt => opt.key === 'roadmap'),
  tableSelectedLevel: TABLE_SELECTED_LEVELS.first,
  level1ClickedId: null,
  level2ClickedId: null,
  projectActiveId: null,
  showTargetAndPredictedLines: true,
  ...thunksInitialState,
};

const progressStoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROGRESS_PAGE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const reducer = reduceReducers(initialState, progressStoreReducer, ...thunksReducers);

export default reducer;
