import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Autocomplete from 'design-system/atoms/Autocomplete/index';


const AddNewMappingInput = props => {
  const { suggestions, onChange, onCancel } = props;

  return (
    <Wrapper>
      <LeftIcon color="default" />
      <Autocomplete
        placeholder="Type your project name and press enter"
        suggestions={suggestions}
        onBlur={onCancel}
        onValueChange={onChange}
        hideBottomBorder
        hideIcon
      />
    </Wrapper>
  );
};

AddNewMappingInput.propTypes = {
  suggestions: PropTypes.array,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid #3333331a;

  div {
    flex: 1;
  }
`;

const LeftIcon = styled(ExpandMoreIcon)`
  margin-right: 12px;
`;

export default AddNewMappingInput;
