import React from 'react';
import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

export default ({ title, getLink, handleDeleteLink }) => {
  const _handlePreviewClick = async () => {
    const url = await getLink();

    if (url) window.open(url, '_blank').focus();
  };

  const _handleDownloadClick = async () => {
    const url = await getLink();

    if (url) {
      const link = document.createElement('a');

      link.href = url;
      link.download = title;

      link.click();
      link.remove();
    }
  };

  return (
    <Wrapper>
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <a onClick={_handlePreviewClick} role="button">
        {title}
      </a>
      <div>
        <IconButton onClick={_handleDownloadClick}>
          <GetAppIcon fontSize="small" />
        </IconButton>

        <IconButton>
          <DeleteOutlinedIcon fontSize="small" onClick={handleDeleteLink} />
        </IconButton>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 8px;
    font-size: 14px;
  }
`;
