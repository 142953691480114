import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentUser } from 'store/login/selectors';
import { getCustomerRequestFormData } from 'store/customerRequests/selectors';

import usePortfolioItems from './hooks/usePortfolioItems';
import useSystemFields from 'hooks/useSystemFields';
import {
  READ_ONLY_USER,
  ADMIN_USER,
  LEADER_USER,
  OWNER_USER,
  MANAGER_USER,
  EDITOR_USER,
  PERMISSION_RESOURCES,
} from '@dragonboat/permissions';
import usePermissions from 'hooks/permissions/usePermissions';

export default Component => {
  return props => {
    const formData = useSelector(getCustomerRequestFormData);
    const [getSystemFieldName] = useSystemFields();
    const currentUser = useSelector(getCurrentUser);

    const { promoteToProject, addProjectToCustomerRequest, removeProjectFromCustomerRequest } = usePortfolioItems();

    const { canUpdate } = usePermissions();
    const canUpdateRequest = canUpdate(PERMISSION_RESOURCES.request, { data: formData });
    const canLinkProjects = canUpdateRequest;

    // TODO: PERMISSION
    const canViewProjects = [ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER].includes(
      currentUser.role_id,
    );

    return (
      <Component
        {...props}
        ideas={formData.projects}
        formData={formData}
        addIdea={addProjectToCustomerRequest}
        removeIdea={removeProjectFromCustomerRequest}
        getSystemFieldName={getSystemFieldName}
        canLinkProjects={canLinkProjects}
        canViewProjects={canViewProjects}
        promoteToProject={promoteToProject}
      />
    );
  };
};
