import React from 'react';
import NumberFormat from 'react-number-format';

import { TOTAL_ROW_ID, ALLOCATION_PERCENTAGE_COLS } from './constants';

export const isTotalRow = row => row?.id === TOTAL_ROW_ID;

export const isAllocationPercentageCol = columnKey => ALLOCATION_PERCENTAGE_COLS.includes(columnKey);

const _isFloat = value => parseFloat(value) % 1 !== 0;

const _formatNumber = (value, decimals) => {
  if (_isFloat(value)) return parseFloat(value).toFixed(decimals);

  return Number(value);
};

export const formatNumber = (value, suffix = '', decimals = 2) => (
  <NumberFormat
    value={_formatNumber(value, decimals)}
    displayType="text"
    thousandSeparator
    decimalScale={decimals}
    suffix={suffix}
  />
);

export const makeIsColumnVisible = (displayOptions, columnsOptions) => columnKey =>
  displayOptions.includes(columnKey) && columnsOptions.some(col => col.key === columnKey);
