/* eslint-disable camelcase */
// External dependencies
import React, { Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

// Dragonboat dependencies
import Dialog from 'design-system/molecules/Dialog/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


export default ({ showDialog, onClose, onSave, filterName = '' }) => {
  const [name, setName] = useState(filterName);

  // if filterName changed on store update filters on state
  useEffect(() => setName(filterName), [showDialog, filterName]);

  const _onSave = () => {
    onSave(name);
    onClose();
  };

  return (
    <Dialog
      id="save-filter-dialog"
      data-test="save-filter-dialog"
      open={showDialog}
      onClose={onClose}
      maxWidth="md"
      scroll="paper"
      closeButton
      actions={
        <Fragment>
          <Button data-test="save-filter" onClick={_onSave} color="primary">
            Save
          </Button>
          <Button
            data-test="save-filter"
            onClick={() => {
              onClose();
              setName('');
            }}
          >
            Cancel
          </Button>
        </Fragment>
      }
    >
      <DialogWrapper>
        <TextDeprecated>Save this filter as</TextDeprecated>
        <TextField
          data-test="filter-name-input"
          placeholder="Filter name"
          fullWidth
          margin="normal"
          value={name}
          onChange={({ target: { value } }) => setName(value)}
        />
      </DialogWrapper>
    </Dialog>
  );
};

const DialogWrapper = styled.div`
  min-width: 500px;
`;
