import React from 'react';

import ProjectLink from 'design-system/atoms/ProjectLink/index';


export const getDefaultErrorMessage = type => <span>There has been a problem creating the new {type}.</span>;

export const getDuplicatedErrorMessage = (entityType, title, settingsUrl) => (
  <span>
    There is already a {entityType} named {title}. Please choose another title or go to
    <ProjectLink href={settingsUrl}> settings </ProjectLink> to view details.
  </span>
);
