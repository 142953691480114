import { useMemo } from 'react';
import { identity, pipe } from 'ramda';

import theme from 'design-system/theme';
import getMaxValueOnBarStackedChart from 'utils/charts/getMaxValueOnBarStackedChart';
import { DEFAULT_LIMIT_TO_HIDE_DATALABEL } from 'constants/charts';

const USE_DATA_VALUE_DIRECTLY = identity();

/**
 * @function useBarStackedCharDatalabelsConfig
 *
 * use datalabels configuration for Bar Stacked Chart
 *
 * @param  {Array} datasets
 * @return {Object}
 */
const useBarStackedCharDatalabelsConfig = (
  datasets,
  limitPercentToHide = DEFAULT_LIMIT_TO_HIDE_DATALABEL,
  dataValueExtractor = USE_DATA_VALUE_DIRECTLY,
  formatter = Math.round,
  displayCallback = () => true,
) => {
  const maxValueOnChartData = useMemo(() => getMaxValueOnBarStackedChart(datasets), [datasets]);

  return {
    color: theme.palette.black,
    display: context => {
      const maxValueToHide = maxValueOnChartData * limitPercentToHide;
      const value = dataValueExtractor(context.dataset.data[context.dataIndex]);

      return value > maxValueToHide && displayCallback(value, context);
    },
    labels: {
      value: {
        align: 'center',
        anchor: 'center',
        clamp: true,
        offset: 20,
        font: {
          size: theme.typography.fontSizeSmall,
        },
      },
    },
    formatter: pipe(dataValueExtractor, formatter),
  };
};

export default useBarStackedCharDatalabelsConfig;
