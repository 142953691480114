import React, { useState, useRef, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import styled from 'styled-components';

import Autocomplete from 'design-system/atoms/Autocomplete/index';


const LOAD_DELAY = 1000;

export default props => {
  const { loadData, loadDelay } = props;
  const options = props.options || [];
  const [suggestions, setSuggestions] = useState(options);
  const [value, setValue] = useState(props.value);
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(loading);
  const valueRef = useRef(value);
  const suggestionsRef = useRef(suggestions);

  suggestionsRef.current = suggestions;
  loadingRef.current = loading;
  valueRef.current = value;

  const _loadData = search => {
    if (!loadData) return;

    setLoading(true);
    loadData(search).then(data => {
      let _results = data;

      /**
       * if the autocomplete has some value should put on first index the current value object
       * and then all suggestions already on cache
       */
      if (valueRef.current && valueRef.current === search && options.length > 0) {
        _results = [
          ..._results.filter(o => o && o.label === valueRef.current),
          ...options.filter(s => s && s.label !== valueRef.current),
        ];
      }

      setSuggestions(_results);
      setLoading(false);
    });
  };
  const _onChange = search => {
    if (suggestionsRef.current.some(s => s.label === search)) {
      setLoading(false);
      return;
    }

    _loadData(search);
  };
  const [_debouncedOnChange] = useDebouncedCallback(_onChange, loadDelay ?? LOAD_DELAY);
  const _onBlur = e => {
    setTimeout(() => {
      if (!valueRef.current) {
        setValue(null);
        // if (props.onValueChange) props.onValueChange(null);
      }
      if (props.onBlur) props.onBlur(e);
    }, 500);
  };
  const _valueSelected = suggestion => {
    setValue(suggestion.label);

    if (props.onSuggestionSelected) props.onSuggestionSelected(suggestion);
  };
  const _valueChanged = (val, selectedOption) => {
    valueRef.current = val;
    if (!val) setValue(null);

    let text = selectedOption ? selectedOption.label : '';

    if (selectedOption && selectedOption.new) text = val;
    if (props.onValueChange) props.onValueChange(val, text, selectedOption);
  };
  const _onFocus = () => {
    setLoading(true);
    _debouncedOnChange(valueRef.current);
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Autocomplete
      {...props}
      addNew={loading ? false : props.addNew}
      value={value}
      handleChange={_debouncedOnChange}
      onFocus={_onFocus}
      suggestions={loading ? [] : suggestions}
      onBlur={_onBlur}
      onSuggestionSelected={_valueSelected}
      onValueChange={_valueChanged}
      // endAdornment={
      //   <InputAdornment position="end" style={{ minWidth: 20, maxWidth: 20 }}>
      //     {loading && (
      //       <LoadingContainer>
      //         <CircularProgress size={20} variant="indeterminate" thickness={4} disableShrink />
      //       </LoadingContainer>
      //     )}
      //   </InputAdornment>
      // }
    />
  );
};

// const LoadingContainer = styled.div`
//   position: absolute;
//   z-index: 100000;
//   right: 36px;
//   top: 6px;
// `;
