import React from 'react';
import styled from 'styled-components';

import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';

import theme, { spacing } from 'design-system/theme';

const CUSTOM_EMPTY_MESSAGE = 'No results';

const EmptyOption = ({ showCreateOption, label, onClick }) => {
  return showCreateOption ? (
    <StyledMenuItemDropdown onClick={onClick}>{`Create "${label}"`}</StyledMenuItemDropdown>
  ) : (
    <EmptyMessage>{CUSTOM_EMPTY_MESSAGE}</EmptyMessage>
  );
};

export default EmptyOption;

const EmptyMessage = styled.div`
  margin: ${spacing(2)}px ${spacing(5)}px;
  font-size: ${theme.typography.fontSize}px;
  font-style: italic;
  text-align: center;
`;
