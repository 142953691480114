import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import Timeline, { snapToGridOptions } from 'design-system/organisms/Timeline/New';
import ControlsBar from 'routes/RoadmapVersions/components/CompareScenariosSwimlane/components/ControlsBar/ControlsBar';
import { tooltipLabels } from 'routes/RoadmapVersions/components/CompareScenariosSwimlane/helpers';
// eslint-disable-next-line max-len
import useTimelinesForSwimlaneCompare from 'routes/RoadmapVersions/components/CompareScenariosSwimlane/hooks/useTimelinesForSwimlaneCompare';
// eslint-disable-next-line max-len
import useBaseProjectsForSwimlaneComparison from 'routes/RoadmapVersions/components/CompareScenariosSwimlane/hooks/useBaseProjectsForSwimlaneComparison';
import useScenarioVersionColors from 'routes/RoadmapVersions/hooks/useScenarioVersionColors';
import useSwimlaneProjects from 'routes/Swimlane/New/hooks/useSwimlaneProjects';
import useRoadmapVersions from 'hooks/useRoadmapVersions';
import usePageFilters from 'hooks/filters/usePageFilters';
import { makeCustomGroupTitleRenderer } from 'utils/swimlane/new';
import { PLAN_OF_RECORD_ID } from 'constants/common';
import { COMPARE_SCENARIOS_FILTER } from 'constants/filters/pages';

const Swimlane = ({ oneClickRoadmapVersion }) => {
  const dispatch = useDispatch();

  const { displayLayer } = useSwimlaneProjects();

  const { filtersForApiByLevels } = usePageFilters(COMPARE_SCENARIOS_FILTER);

  const { fetchMultipleRoadmapVersionsProjects } = useRoadmapVersions();

  const { scenarioColors } = useScenarioVersionColors();

  const timelineConfig = useTimelinesForSwimlaneCompare();

  const {
    lsState: { slotWidth, showLegend, showTooltip, hideEmptyLane },
    groupWidths,
    groupTitles,
    zoomMode,
  } = timelineConfig;

  const { groupedByProjectData, handleGroupHeaderResize } = useBaseProjectsForSwimlaneComparison(
    timelineConfig,
    displayLayer,
    scenarioColors,
    oneClickRoadmapVersion,
  );

  const groupTitleRenderer = makeCustomGroupTitleRenderer(dispatch);

  useEffect(() => {
    if (oneClickRoadmapVersion) {
      fetchMultipleRoadmapVersionsProjects(PLAN_OF_RECORD_ID, [oneClickRoadmapVersion?.id], filtersForApiByLevels);
    }
  }, []);

  return (
    <Container>
      <ControlsBar displayRoadmapChips={false} />
      <StyledTimeline
        // for style purposes
        showLegend={showLegend}
        // data
        data={groupedByProjectData}
        groupTitles={groupTitles}
        isMilestoneItemChecker={() => true}
        // options
        groupWidths={groupWidths}
        zoomMode={zoomMode}
        slotWidth={slotWidth}
        displayMilestone
        displayMilestoneOn
        showTooltip={showTooltip}
        snapToGridOn={snapToGridOptions.END_DATE_ESTIMATES}
        resizable={false}
        draggable={false}
        hideEmptyLane={hideEmptyLane}
        groupTitleRenderer={groupTitleRenderer}
        tooltipLabels={tooltipLabels}
        // handlers
        onResizeGroupHeader={handleGroupHeaderResize}
      />
    </Container>
  );
};

export default Swimlane;

const Container = styled.div`
  &&& > div {
    padding-top: 0;
  }
`;

const StyledTimeline = styled(Timeline)`
  &&&& {
    width: 100%;
  }
`;
