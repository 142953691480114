// Define constants for each operator
export const EQUAL = '=';
export const NOT_EQUAL = '!=';
export const GREATER_THAN = '>';
export const LESS_THAN = '<';
export const GREATER_THAN_OR_EQUAL = '>=';
export const LESS_THAN_OR_EQUAL = '<=';
export const CONTAINS = 'contains';
export const NOT_CONTAINS = 'not contains';
export const IS_EMPTY = 'is empty';
export const IS_NOT_EMPTY = 'is not empty';
export const IN = 'in';
export const NOT_IN = 'not in';
export const BETWEEN = 'between';
export const AND = 'and';
export const OR = 'or';

// Use the constants in the operators array
export const allOperators = [
  EQUAL,
  NOT_EQUAL,
  GREATER_THAN,
  LESS_THAN,
  GREATER_THAN_OR_EQUAL,
  LESS_THAN_OR_EQUAL,
  CONTAINS,
  NOT_CONTAINS,
  IS_EMPTY,
  IS_NOT_EMPTY,
  IN,
  NOT_IN,
  BETWEEN,
  AND,
  OR,
];

// Define template strings for the existing operators
export const conditionTemplates = {
  comparison: {
    operators: [EQUAL, NOT_EQUAL, GREATER_THAN, GREATER_THAN_OR_EQUAL, LESS_THAN, LESS_THAN_OR_EQUAL, CONTAINS, NOT_CONTAINS],
    template: condition => `${condition.field} ${condition.operator} ${condition.value[0]}`,
  },
  between: {
    operators: [BETWEEN],
    template: condition => `${condition.field} ${condition.operator} ${condition.value[0]} and ${condition.value[1]}`,
  },
  empty: {
    operators: [IS_EMPTY, IS_NOT_EMPTY],
    template: condition => `${condition.field} ${condition.operator}`,
  },
  array: {
    operators: [IN, NOT_IN],
    template: condition => `${condition.field} ${condition.operator} (${condition.value.join(', ')})`,
  },
};
