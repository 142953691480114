import React from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/HelpOutline';

import { FeatureFlags } from '@dragonboat/config';

import HelpLink from 'design-system/atoms/HelpLink/index';
import InlineIcon from 'design-system/atoms/InlineIcon/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import CollapsePanel from 'components/CollapsePanel';
import FieldsMappingDisplay from 'components/FieldsMappingDisplay';

import SetupFieldsMappingDialog from 'containers/SetupFieldsMappingDialog';

import useFeatureFlags from 'hooks/useFeatureFlags';

import { INTEGRATIONS_NAMES, INTEGRATIONS_DOCS_LINKS } from 'constants/integrations';

import getSystemFieldNameUtil from 'utils/getSystemFieldName';

import UpdateProjectsMappedFields from 'features/UpdateProjectsMappedFields';
import { getTypeNameBasedOnLayer } from 'constants/projectLightbox';
import { integrationLayerNameOrDefault } from '../../IntegrationLayerNames/helpers';

const FieldMapping = props => {
  const {
    integrationType,
    orgIntegrationId,
    mapping,
    layer,
    systemFields,
    integrationLayerNames,
    isFieldMappingDialogOpen,
    onOpenFieldMappingDialog,
    onCloseFieldMappingDialog,
    userCanUpdateOrgIntegration,
  } = props;

  const shouldRenderFieldsMapping = mapping && !!mapping.length;
  const integrationNameLabel = INTEGRATIONS_NAMES[integrationType];
  const mappingFieldsDocsLink = INTEGRATIONS_DOCS_LINKS[integrationType].MAPPING_FIELDS_FIELDS_SYNC;

  const getSystemFieldName = name => getSystemFieldNameUtil(name, systemFields);

  const hasSyncFieldsEnabled = useFeatureFlags([FeatureFlags.HAS_INTEGRATION_GATEWAY_SYNC_FIELDS]);

  const integrationLayerName = integrationLayerNameOrDefault(layer, integrationLayerNames);

  return (
    <Wrapper>
      <CollapsePanel title={<TextDeprecated size="medium">Field mapping setting</TextDeprecated>}>
        <PanelContent>
          {shouldRenderFieldsMapping && (
            <React.Fragment>
              <TextDeprecated size="medium">
                Mapping between fields in {integrationNameLabel} {integrationLayerName} and dragonboat{' '}
                {getSystemFieldName(getTypeNameBasedOnLayer(layer))}:
                <HelpLink href={mappingFieldsDocsLink} target="_blank" rel="noopener noreferrer">
                  <InlineIcon>
                    <HelpIcon />
                  </InlineIcon>
                </HelpLink>
              </TextDeprecated>

              <FieldsMappingDisplay integrationType={integrationType} mapping={mapping} />
            </React.Fragment>
          )}

          <ActionsContainer>
            <ActionItem>
              <Button color="primary" onClick={onOpenFieldMappingDialog} disabled={!userCanUpdateOrgIntegration}>
                {shouldRenderFieldsMapping ? 'Edit field mapping' : 'Add field mapping'}
              </Button>
            </ActionItem>
            {hasSyncFieldsEnabled && (
              <ActionItem>
                <UpdateProjectsMappedFields
                  integrationType={integrationType}
                  orgIntegrationId={orgIntegrationId}
                  disabled={!userCanUpdateOrgIntegration || !shouldRenderFieldsMapping}
                />
              </ActionItem>
            )}
          </ActionsContainer>
        </PanelContent>
      </CollapsePanel>
      {isFieldMappingDialogOpen && (
        <SetupFieldsMappingDialog
          onClose={onCloseFieldMappingDialog}
          integrationType={integrationType}
          orgIntegrationId={orgIntegrationId}
          mapping={mapping}
          layer={layer}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const PanelContent = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.text.lightGrey};
  padding-bottom: 16px;
`;

const ActionsContainer = styled.div`
  margin-top: 16px;
`;

const ActionItem = styled.div`
  margin-left: -8px;
`;

export default FieldMapping;
