import React from 'react';

import { PieChart, BarChart } from './charts';

const PIE_CHART = 'pie';
const BAR_CHART = 'bar';

const mapChartTypeToChart = {
  [PIE_CHART]: PieChart,
  [BAR_CHART]: BarChart,
};

const ConfigurableChart = ({ wrapper: Wrapper, chartType, minHeight, ...props }) => {
  const ChartComponent = mapChartTypeToChart[chartType];

  return <Wrapper>{ChartComponent && <ChartComponent minHeight={minHeight} {...props} />}</Wrapper>;
};

export default ConfigurableChart;
