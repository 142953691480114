import { isNil, propOr } from 'ramda';
import { formatNumber, formatPercent, roundToDecimals } from 'utils/index';

const getMetadata = propOr([], 'metadata');
const getDatesFormatted = propOr([], 'dates_formatted');
const getCompletedAllocation = propOr({}, 'completed_allocation');
const getDataInDays = propOr([], 'data');
const getDataInWeeks = propOr([], 'dataWeeks');
const getDataPercentage = propOr([], 'dataPercentage');

const incrementColumnTotal = (columnName, row, totals) => {
  if (isNil(row[columnName])) {
    return totals[columnName];
  }

  const columnValueOrZero = propOr(0, columnName);

  const currentValue = columnValueOrZero(totals);

  return currentValue + columnValueOrZero(row);
};

export const getNumber = (value, showTwoDecimals) => {
  return formatNumber(value, showTwoDecimals ? 2 : 0);
};

export const roundNumber = (value, showTwoDecimals) => {
  return roundToDecimals(value, showTwoDecimals ? 2 : 0);
};

export const getPercentage = (value, showTwoDecimals) => {
  return formatPercent(value, showTwoDecimals ? 2 : 0);
};

const getAllocationDataFormatted = (
  allocationData,
  showAllocationAsPercentage = false,
  showAllocationInWeeks = false,
  showTwoDecimals = false,
) => {
  const entities = getMetadata(allocationData);
  const datesFormatted = getDatesFormatted(allocationData);
  const completedAllocation = getCompletedAllocation(allocationData);

  const allocationDataFormatted = entities.map(entity => {
    const { id, title, level, status, progress } = entity;

    const entityCompletedAllocation = completedAllocation[id];

    const completedAllocationDataInDays = getDataInDays(entityCompletedAllocation);
    const completedAllocationDataInWeeks = getDataInWeeks(entityCompletedAllocation);
    const completedAllocationDataPercentage = getDataPercentage(entityCompletedAllocation);

    const dynamicFields = datesFormatted.reduce((acc, date, i) => {
      const completed = showAllocationInWeeks ? completedAllocationDataInWeeks[i] : completedAllocationDataInDays[i];
      const completedPercentage = completedAllocationDataPercentage[i];

      return {
        ...acc,
        [date]: showAllocationAsPercentage
          ? roundToDecimals(completedPercentage, showTwoDecimals ? 4 : 2)
          : roundNumber(completed, showTwoDecimals),
      };
    }, {});

    const enrichedEntity = {
      id,
      title,
      level,
      status,
      progress,
      target_allocation_amount: roundNumber(entity.target_allocation_amount, showTwoDecimals),
      target_allocation_percentage: roundNumber(entity.target_allocation_percentage, showTwoDecimals),
      status_color: entity.status_color,
      total: roundNumber(
        showAllocationInWeeks ? entityCompletedAllocation?.totalWeeks : entityCompletedAllocation?.total,
        showTwoDecimals,
      ),
      change: roundToDecimals(entityCompletedAllocation?.change, showTwoDecimals ? 4 : 2),
      ...dynamicFields,
    };

    return enrichedEntity;
  });

  const formatRow = row => {
    const dynamicFieldsFormatted = datesFormatted.reduce((acc, date, i) => {
      return {
        ...acc,
        [date]: showAllocationAsPercentage ? getPercentage(row[date], showTwoDecimals) : getNumber(row[date], showTwoDecimals),
      };
    }, {});

    return {
      ...row,
      target_allocation_amount: getNumber(row.target_allocation_amount, showTwoDecimals),
      total: getNumber(row.total, showTwoDecimals),
      change: getPercentage(row.change, showTwoDecimals),
      ...dynamicFieldsFormatted,
    };
  };

  const totalsRow = allocationDataFormatted.reduce(
    (totalsRow, dataRow) => {
      return {
        ...totalsRow,
        target_allocation_amount: incrementColumnTotal('target_allocation_amount', dataRow, totalsRow),
        target_allocation_percentage: incrementColumnTotal('target_allocation_percentage', dataRow, totalsRow),
        change: incrementColumnTotal('change', dataRow, totalsRow),
        total: incrementColumnTotal('total', dataRow, totalsRow),
        ...datesFormatted.reduce((fields, date) => {
          return {
            ...fields,
            [date]: incrementColumnTotal(date, dataRow, totalsRow),
          };
        }, {}),
      };
    },
    { title: 'Totals', status: 'Active' },
  );

  const rows = [...allocationDataFormatted, totalsRow];

  return rows.map(formatRow);
};

export default getAllocationDataFormatted;
