import { useState, useRef } from 'react';

export default function useStateRef(defaultValue) {
  const valueRef = useRef(defaultValue);
  const [, _setValue] = useState(defaultValue);

  function setValue(v) {
    valueRef.current = v;
    _setValue(v);
  }

  return [valueRef.current, setValue];
}
