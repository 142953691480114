import React from 'react';
import styled from 'styled-components';

import AddProjectButton from 'components/AddProjectButton';

export default function HeaderOptions() {
  return (
    <HeaderContainer>
      <AddProjectButton />
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  margin: 0 16px;
`;