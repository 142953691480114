import React, { useState } from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';

import WidgetSegmentTitle from 'design-system/atoms/WidgetSegmentTitle';

export default ({
  canAddAnnouncement, // TODO: WE SHOULD SEPARATE THESE COMPONENTS :)
  contentMaxHeight,
  contentMinHeight,
  customBorderColor,
  expandedContent,
  handleAddAnnouncement,
  initialContent,
  overflowContent,
  showExpandButton,
  title,
  showMore,
  handleShowMore,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => setIsExpanded(!isExpanded);

  const expandButtonLabel = isExpanded ? 'See less' : 'See more';

  return (
    <WidgetWrapper $borderColor={customBorderColor} {...props}>
      <WidgetContainer>
        <WidgetTopBar>
          <WidgetSegmentTitle>{title}</WidgetSegmentTitle>
          {showExpandButton && <Button onClick={handleExpand}>{expandButtonLabel}</Button>}
        </WidgetTopBar>
        <Container>
          <WidgetContent
            maxHeight={contentMaxHeight}
            minHeight={contentMinHeight}
            isExpanded={isExpanded}
            overflowContent={overflowContent}
          >
            {isExpanded ? expandedContent : initialContent}
          </WidgetContent>
          {canAddAnnouncement && (
            <WidgetFooter>
              <Button onClick={handleAddAnnouncement}>+ Add New Announcement</Button>
            </WidgetFooter>
          )}
          {showMore && (
            <WidgetFooter>
              <Button onClick={handleShowMore}>Show more</Button>
            </WidgetFooter>
          )}
        </Container>
      </WidgetContainer>
    </WidgetWrapper>
  );
};

const WidgetWrapper = styled.div`
  padding: 25px;
  background-color: ${({ theme }) => theme.palette.newLayout.background.white};
  border: 1px solid ${({ $borderColor, theme }) => $borderColor || theme.palette.newLayout.background.header};
  border-radius: ${({ theme }) => theme.shape.borderRadiusExtraLarge}px;
`;

const WidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const WidgetTopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(MuiButton)`
  && {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    text-transform: none;
  }
`;

const WidgetContent = styled.div`
  margin-top: 16px;
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}px;`}
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
  overflow-y: ${({ isExpanded }) => (isExpanded ? 'scroll' : 'unset')};

  ${({ overflowContent }) =>
    overflowContent &&
    `
      width: calc(100% + 50px);
      margin-left: -25px;
      margin-right: -25px;
    `}

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.newLayout.background.seashellGrey};
    border-radius: ${({ theme }) => theme.shape.borderRadiusExtraLarge}px;
  }
`;

const WidgetFooter = styled.div`
  margin-top: 16px;
`;
