import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';

import ProgressHeader from './ProgressHeader';
import ProgressContent from './ProgressContent';

import { initEndDate, initStartDate } from 'store/progress';
import useProgressBurnChartData from './hooks/useProgressBurnChartData';

const Progress = ({ parentProjectId }) => {
  const [dates, setDates] = useState({
    startDate: initStartDate(),
    endDate: initEndDate(),
  });
  const [showTargetAndPredictedLines, setShowTargetAndPredictedLines] = useState(true);
  const { data, titleType, maxEndDate, maxPredictedEndDate, formattedEndDate, formattedPredictedDate, isLoading } =
    useProgressBurnChartData({
      startDate: dates.startDate,
      endDate: dates.endDate,
      parentProjectId,
    });

  const onDateChange = ({ start_date: changedStartDate, end_date: changedEndDate }) => {
    setDates(state => ({
      startDate: changedStartDate ? changedStartDate.format() : state.startDate,
      endDate: changedEndDate ? changedEndDate.format() : state.endDate,
    }));
  };

  return (
    <Grid container direction="column" justify="center">
      <Grid item>
        <ProgressHeader
          startDate={dates.startDate}
          endDate={dates.endDate}
          onDateChange={onDateChange}
          targetAndPredictedLinesOptions={{
            checked: showTargetAndPredictedLines,
            formattedEndDate,
            formattedPredictedDate,
          }}
          onShowTargetAndPredictedLinesChange={() => setShowTargetAndPredictedLines(state => !state)}
          showShareView={false}
        />
      </Grid>
      <Grid item>
        <ProgressContent
          data={data}
          isLoading={isLoading}
          startDate={dates.startDate}
          endDate={dates.endDate}
          targetAndPredictedLinesOptions={{
            checked: showTargetAndPredictedLines,
            maxEndDate,
            maxPredictedEndDate,
          }}
          titleType={titleType}
          parentProjectId={parentProjectId}
        />
      </Grid>
    </Grid>
  );
};

export default Progress;
