import React from 'react';
import isString from 'lodash/isString';
import DragonTableCell from '../DragonTableCell';
import OpenProjectIconButton from '../OpenProjectIconButton';


export default ({ map, row, ...props }) => {
  const result = row ? map(row) : '';
  const { label, url } = isString(result)
    ? { url: result }
    : result;

  return (
    <DragonTableCell {...props} style={{ height: props.cellProps.rowHeight, width: props.width }}>
      {!row || !url
        ? null
        : (
          <a href={url} target="_blank" rel="noreferrer noopener">{
            label || <OpenProjectIconButton />
          }
          </a>
        )
      }
    </DragonTableCell>);
};
