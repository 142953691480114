import flatten from 'lodash/flatten';
import { useState, useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { not } from 'ramda';

import normalizeArray from 'utils/normalizeArray';

/**
 * @function useProjectsLocalSearch
 *
 * This is a custom Hook that is used for filtering an array of projects
 * based on a search string.
 *
 * When the search string changes, the Hook debounces the function that
 * filters the projects and sets the state of the projectsIdsToShow array
 * to the filtered projects.
 *
 * @param  {Array} projects    - all projects to make the local search
 * @param  {String} searchStr  - search string to execute the search
 * @param  {String} avoidHierarchy  - if it should consider projects hierarchy
 * @return {Array}
 */
const useProjectsLocalSearch = (projects, searchStr = '', avoidHierarchy = false) => {
  const [projectsIdsToShow, setProjectsIdsToShow] = useState([]);

  const projectsById = useMemo(() => normalizeArray(projects, 'id'), [projects]);

  const _filterProjectsBySearchStr = () => {
    // if any search term will got out
    if (!searchStr) {
      return;
    }

    let projectsToShow = [];

    // recursive function to get all project parents on the tree
    const _getProjectParents = project => {
      const parent = projectsById[project.parent_id];

      if (!parent) {
        return [];
      }

      return [parent.id, ..._getProjectParents(parent)];
    };

    // recursive function to get all project children on the current project tree
    const _getProjectChildren = project => {
      const children = projects.filter(p => p.parent_id === project.id);

      return flatten([project.id, ...children.map(c => _getProjectChildren(c))]);
    };

    // iterate over all projects to find the ones that matches with current search
    projects.forEach(project => {
      const { title, id } = project;

      const isNotMatchedAlready = not(projectsToShow.includes(id));

      if (isNotMatchedAlready) {
        const projectTitleMatch = title.toLowerCase().includes(searchStr.toLowerCase());

        if (projectTitleMatch) {
          const parents = not(avoidHierarchy) ? _getProjectParents(project) : [];
          const children = not(avoidHierarchy) ? _getProjectChildren(project) : [];

          projectsToShow = flatten([...projectsToShow, ...parents, ...children, id]);
        }
      }
    });

    // save on the state the visible projects for the current search term
    setProjectsIdsToShow(projectsToShow);
  };

  const [_debouncedFilterProjectsBySearchStr] = useDebouncedCallback(() => {
    _filterProjectsBySearchStr();
  }, 250);

  useEffect(() => {
    _debouncedFilterProjectsBySearchStr();
  }, [searchStr]);

  const filterFunction = useMemo(
    () => (searchStr ? p => projectsIdsToShow.includes(p?.id) : null),
    [searchStr, projectsIdsToShow],
  );

  return [filterFunction];
};

export default useProjectsLocalSearch;
