import React from 'react';
import styled, { css } from 'styled-components';

import Dialog from 'design-system/molecules/Dialog/index';

import { spacing } from 'design-system/theme';
import Comments from 'components/Comments';
import { CommentsContainer, ButtonsGrid } from 'components/Comments/Comments';

const CommentsLightbox = ({
  isOpen,
  addComment,
  comments,
  hasMoreComments = false,
  onClose,
  fetchNext,
  onDeleteComment,
  onEditComment,
  fullScreenOnMobile,
}) => {
  return (
    <Dialog open={isOpen} closeButton title="Comments" onClose={onClose} fullWidth fullScreenOnMobile={fullScreenOnMobile}>
      <Content fullScreenOnMobile={fullScreenOnMobile}>
        <Comments
          isDialog
          onSave={addComment}
          comments={comments}
          hasMoreComments={hasMoreComments}
          showMore={fetchNext}
          onDelete={onDeleteComment}
          onEdit={onEditComment}
        />
      </Content>
    </Dialog>
  );
};

export default CommentsLightbox;

const Content = styled.div`
  ${({ fullScreenOnMobile }) =>
    fullScreenOnMobile &&
    css`
      @media screen and (max-width: 1024px) {
        ${CommentsContainer} {
          margin: ${spacing(5)}px 0 0;
        }
      }

      @media screen and (max-width: 550px) {
        ${ButtonsGrid} {
          > div {
            width: 100%;
            order: 2;
            justify-content: flex-end;
          }
        }
      }
    `}
`;
