import { getCustomFieldColumnDef } from 'design-system/molecules/AgGridReact-New/columns';

const customFieldColumnFactory = defaultOptions => ({
  key,
  title,
  field_type: fieldType,
  data,
  enableRowGroup,
  data_enhanced: dataEnhanced,
  sortedValidOptions,
  ...rest
}) => {
  return getCustomFieldColumnDef({
    ...defaultOptions,
    key,
    fieldType,
    title,
    dataEnhanced,
    data,
    enableRowGroup,
    sortedValidOptions,
  });
};

export default customFieldColumnFactory;
