import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import isObject from 'lodash/isObject';
import { fromJS, List } from 'immutable';
import { head } from 'ramda';

import searchTaskRecursively from 'store/utils/searchTaskRecursively';

import { updateProjectOnCollectionsUsingMethod } from 'store/projects/helpers/collections';
import buildTasksChainAndAssignToProjects from 'store/projects/helpers/buildTasksChainAndAssignToProjects';
import replaceTaskInProjects from 'store/projects/helpers/replaceTaskInProjects';

import {
  ADD_PROJECT_TASKS_FULFILLED,
  UPDATE_PROJECT_TASKS_FULFILLED,
  CREATE_TASK_FULFILLED,
  UPDATE_TASK_FULFILLED,
  DELETE_TASK_FULFILLED,
  BULK_UPDATE_TASKS_FULFILLED,
} from '../types';

const _insertNewTaskOnProject = (collection, task, projectIndex) => {
  if (task.parent_task_id) {
    const parentTaskPath = searchTaskRecursively(collection.get(projectIndex).toJS(), projectIndex, [], task.parent_task_id);

    return collection.updateIn(parentTaskPath, parentTask => {
      const parentTaskJS = parentTask.toJS();

      return fromJS({
        ...parentTaskJS,
        subtasks: [...(parentTaskJS.subtasks || []), task],
      });
    });
  }

  return collection.updateIn([projectIndex, 'tasks'], tasks => fromJS([...(tasks || new List()).toJS(), task]));
};

const _updateTasksState = (stateTasks, data = []) => {
  const tasks = stateTasks || [];

  if (!data) return tasks;

  const tasksIds = data.map(task => task.id);
  const newTasks = data.filter(updateTask => !tasks.some(t => t.id === updateTask.id));

  return [...tasks, ...newTasks].map(task => {
    const index = tasksIds.indexOf(task.id);
    let tmpTask = { ...task };

    if (index >= 0 && task.id) {
      tmpTask = { ...data[index] };
    }

    tmpTask.projectDependencies = tmpTask.projectDependencies
      ? tmpTask.projectDependencies.map(p => (isObject(p) ? p.id : p))
      : [];
    tmpTask.taskDependencies = tmpTask.taskDependencies ? tmpTask.taskDependencies.map(t => (isObject(t) ? t.id : t)) : [];

    return tmpTask;
  });
};

const projectTasksReducer = (state, action) => {
  switch (action.type) {
    case CREATE_TASK_FULFILLED: {
      const task = omit(action.payload.data, 'project');
      let stateTasks = state.tasks;
      const _addNewTask = collection => {
        const projectIndex = collection.findIndex(project => +project.get('id') === +task.project_id);

        if (projectIndex === -1) return collection;

        stateTasks = [...stateTasks, task];
        return _insertNewTaskOnProject(collection, task, projectIndex);
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _addNewTask),
        tasks: stateTasks,
      };
    }
    case UPDATE_TASK_FULFILLED: {
      const { updatedTask } = action.meta;
      const actualTask = action.payload || updatedTask;
      let stateTasks = state.tasks;
      const _updateTask = collection => {
        const projectIndex = collection.findIndex(project => +project.get('id') === +actualTask.project_id);

        if (projectIndex === -1) return collection;

        stateTasks = _updateTasksState(stateTasks, [actualTask]);
        return replaceTaskInProjects(actualTask, collection);
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateTask),
        tasks: stateTasks,
      };
    }
    case DELETE_TASK_FULFILLED: {
      if (Number.isNaN(action.payload)) {
        if (process.env.NODE_ENV === 'development') {
          console.trace('payload wrong format');
        }
        return state;
      }

      const taskId = +action.payload;
      let stateTasks = state.tasks;
      const _deleteTask = collection => {
        let taskPath;
        const projectIndex = collection.findIndex((project, index) => {
          taskPath = searchTaskRecursively(project.toJS(), index, [], taskId);
          return !!taskPath;
        });

        if (projectIndex === -1 || !taskPath) return collection;

        stateTasks = stateTasks.filter(t => t.id !== taskId);
        return collection.deleteIn(taskPath);
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _deleteTask),
        tasks: stateTasks,
      };
    }
    case UPDATE_PROJECT_TASKS_FULFILLED: {
      const { projectId } = action.meta;
      const { data } = action.payload;

      if (!projectId || !data) {
        if (process.env.NODE_ENV === 'development') {
          console.trace('Action must have projectId and payload fields.');
        }
        return state;
      }

      let stateTasks = state.tasks;
      const _updateProjectTasks = collection => {
        const projectIndex = collection.findIndex(project => project.get('id') === projectId);

        if (projectIndex === -1) return collection;

        let project = collection.get(projectIndex).toJS();

        stateTasks = _updateTasksState(stateTasks, data);
        project = head(buildTasksChainAndAssignToProjects([project], stateTasks));

        return collection.updateIn([projectIndex, 'tasks'], () => fromJS(project.tasks));
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateProjectTasks),
        tasks: stateTasks,
      };
    }
    case ADD_PROJECT_TASKS_FULFILLED: {
      if (!action.projectId || !action.payload) {
        if (process.env.NODE_ENV === 'development') {
          console.trace('Action must have a projectId and a payload fields.');
        }
        return state;
      }

      const { projectId } = action;
      const data = action.payload;
      let stateTasks = state.tasks;
      const _addTasks = collection => {
        const projectIndex = collection.findIndex(project => project.get('id') === projectId);

        if (projectIndex === -1) return collection;

        if (data instanceof Array) {
          stateTasks = _updateTasksState(stateTasks, data);
          return data.reduce(
            (currentCollection, task) => _insertNewTaskOnProject(currentCollection, task, projectIndex),
            collection,
          );
        }

        stateTasks = _updateTasksState(stateTasks, [data]);
        return _insertNewTaskOnProject(collection, data, projectIndex);
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _addTasks),
        tasks: stateTasks,
      };
    }
    case BULK_UPDATE_TASKS_FULFILLED: {
      if (!action.payload || !action.payload || !(action.payload instanceof Array)) {
        if (process.env.NODE_ENV) {
          console.error('Wrong payload format', { ...action });
        }
        return state;
      }

      const changedTasks = action.payload;
      let stateTasks = state.tasks;

      const _bulkUpdateTasks = collection => {
        const projectIds = uniq(changedTasks.filter(Boolean).map(t => t.project_id));

        return projectIds.reduce((currentCollection, projectId) => {
          const projectIndex = currentCollection.findIndex(project => project.get('id') === +projectId);

          if (projectIndex === -1) return currentCollection;

          for (let i = 0; i < changedTasks.length; i++) {
            const actualTask = { ...changedTasks[i] };

            currentCollection = replaceTaskInProjects(actualTask, currentCollection);
          }

          let project = currentCollection.get(projectIndex).toJS();

          stateTasks = _updateTasksState(stateTasks, changedTasks);
          project = head(buildTasksChainAndAssignToProjects([project], stateTasks));

          return currentCollection.updateIn([projectIndex, 'tasks'], () => fromJS(project.tasks));
        }, collection);
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _bulkUpdateTasks),
        tasks: stateTasks,
      };
    }
    default: {
      return state;
    }
  }
};

export { projectTasksReducer };
