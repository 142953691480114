import { DEFAULT_SIDEBAR_WIDTH, VIEW_MODE_RELATIONS } from './constants';
import { getTargetDateForSnap, isSnapToEnd } from './snapToGrid';
import { distanceBetweenDates } from './dates';

const THRESHOLD = 200;
const GROUP_CELL_BORDER_WIDTH = 3;
const MIN_BAR_WIDTH = 22;
const SNAP_BAR_MARGIN = 1;
const SNAP_BAR_WIDTH = 24;

const generateSidebarDifference = (previousSidebarWidth, currentSidebarWidth) => {
  let delta = 0;

  if (previousSidebarWidth !== currentSidebarWidth) {
    delta = currentSidebarWidth === DEFAULT_SIDEBAR_WIDTH ? 0 : currentSidebarWidth - previousSidebarWidth;
  }

  if (delta === 0) {
    return delta;
  }

  return delta;
};

const generateTodayLeft = left => {
  if (left > THRESHOLD) {
    return left - THRESHOLD;
  }

  return 0;
};

const configureDistance = (item, zoomMode, slotWidth, fromDate, minBarWidth = MIN_BAR_WIDTH, snapToGridOn) => {
  const targetDate = snapToGridOn ? getTargetDateForSnap(item, zoomMode, snapToGridOn) : item.startDate;

  if (!targetDate) {
    return {};
  }

  const distanceToTargetDate = distanceBetweenDates(fromDate, targetDate, zoomMode);
  const distanceToTargetDateRelation = Math.floor(distanceBetweenDates(fromDate, targetDate, VIEW_MODE_RELATIONS[zoomMode]));

  if (snapToGridOn) {
    const leftDistance = Math.round(distanceToTargetDate) * slotWidth;

    // add 1px for each relation slot before this item
    const leftDistanceWithBorders = leftDistance + distanceToTargetDateRelation;
    const snapForTargetDate = isSnapToEnd(snapToGridOn) ? leftDistanceWithBorders - slotWidth : leftDistanceWithBorders;

    const left = snapForTargetDate - SNAP_BAR_MARGIN - GROUP_CELL_BORDER_WIDTH;

    return {
      left,
      width: SNAP_BAR_WIDTH,
      right: left + SNAP_BAR_WIDTH,
    };
  }

  const leftDistance = distanceToTargetDate * slotWidth;

  // add 1px for each relation slot before this item
  const leftDistanceWithBorders = leftDistance + distanceToTargetDateRelation;
  const left = leftDistanceWithBorders - GROUP_CELL_BORDER_WIDTH;

  const widthDistance = Math.max(distanceBetweenDates(item.startDate, item.endDate, zoomMode) * slotWidth, minBarWidth);

  return {
    left,
    width: Math.max(widthDistance, minBarWidth),
    right: left + widthDistance,
  };
};

export { generateSidebarDifference, generateTodayLeft, configureDistance };
