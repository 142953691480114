import { READ_ONLY_ROUTES } from 'config';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

/**
 * @function parseViewPathForCurrentUser
 *
 * parse the user view path based on the current user
 *
 * @param  {String} path
 * @param  {Function} canView
 * @param  {Array} userRoutes
 * @return {String}
 */
const parseViewPathForCurrentUser = (path, canView, userRoutes) => {
  let formatted;

  if (canView(PERMISSION_FEATURES.portfolioModule)) {
    return path;
  }

  const originalPath = path;

  READ_ONLY_ROUTES.forEach(route => {
    if (path.includes(route)) {
      if (route === 'forecast' && path === 'forecast/timeline') {
        path = path.replace('timeline', 'forecast-timeline');
      } else if (route === 'ideas' && path === 'ideas/pdlc') {
        path = path.replace('pdlc', 'ideas-pdlc');
      }

      formatted = path.replace(route, 'dashboard');
    }
  });

  const userRoutesIncludesRoute = userRoutes.some(page => {
    return page.path === formatted;
  });

  return userRoutesIncludesRoute ? formatted : originalPath;
};

export { parseViewPathForCurrentUser };
