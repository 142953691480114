import React from 'react';
import PublicPageTemplateComponent from 'src/design-system/templates/PublicPageTemplate/PublicPageTemplateComponent';
import WorkspaceFormComponent from './WorkspaceForm/WorkspaceFormComponent';

export default ({ handleWorkspaceValue, handleSubmit, value }) => {
  return (
    <PublicPageTemplateComponent>
      <WorkspaceFormComponent value={value} handleWorkspaceValue={handleWorkspaceValue} handleSubmit={handleSubmit} />
    </PublicPageTemplateComponent>
  );
};
