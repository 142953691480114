import { materialColorsAlt } from 'design-system/themes/default';
import { pick } from 'ramda';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getRoadmaps } from 'store/roadmaps/selectors';
import { getNormalizedSkills } from 'store/skills/selectors';
import { getNormalizedTeams } from 'store/teams/selectors';

const DEFAULT_DATA = { title: 'No group', color: materialColorsAlt.blue };

// TODO - create selector to have this logic
export default stackedBy => {
  const roadmaps = useSelector(getRoadmaps);
  const normalizedSkills = useSelector(getNormalizedSkills);
  const normalizedTeams = useSelector(getNormalizedTeams);

  const _getRoadmapData = useCallback(
    roadmapId => {
      const roadmap = roadmaps.find(r => r.id === Number(roadmapId));

      if (!roadmap) return null;

      return pick(['title', 'color'], roadmap);
    },
    [roadmaps],
  );

  const _getSkillData = useCallback(
    id => {
      if (!(id in normalizedSkills)) {
        return null;
      }
      return pick(['title', 'color'], normalizedSkills[id]);
    },
    [normalizedSkills],
  );

  const _getTeamData = useCallback(
    id => {
      if (!(id in normalizedTeams)) {
        return null;
      }
      return pick(['title', 'color'], normalizedTeams[id]);
    },
    [normalizedTeams],
  );

  const _getStackedByData = {
    roadmap_id: _getRoadmapData,
    skill_id: _getSkillData,
    team_id: _getTeamData,
  };

  let result = () => DEFAULT_DATA;

  if (stackedBy && !_getStackedByData[stackedBy]) throw new Error('Stacked By not supported by getStackedByData');

  if (_getStackedByData[stackedBy]) result = _getStackedByData[stackedBy];

  return result;
};
