import React, { useMemo } from 'react';

import customerRequestStatus from 'store/customerRequests/constants/status';

const container = Component => {
  return ({ mapping, phases, ...rest }) => {
    const mappingToDisplay = useMemo(
      () =>
        mapping
          ? Object.entries(mapping).reduce((acc, entry) => {
              const key = entry[0];
              const value = entry[1];
              const curPhase = phases.find(phase => phase.id === +key);

              return [
                ...acc,
                {
                  key,
                  projectStatusLabel: curPhase?.title ?? '',
                  customerRequestStatusLabel: customerRequestStatus[value.target]?.label,
                  sendEmail: value.sendEmail,
                  rowOrder: phases.indexOf(curPhase), // Actual row_order field is not being updated
                },
              ];
            }, [])
          : [],
      [mapping, phases],
    );

    const availableProjectPhasesSuggestions = useMemo(
      () => phases.filter(phase => !mapping?.[phase.id]).map(phase => phase.title),
      [phases, mapping],
    );
    const availableCRStatusSuggestions = useMemo(
      () => Object.values(customerRequestStatus).map(status => status.label),
      [mapping],
    );

    return (
      <Component
        mappingToDisplay={mappingToDisplay}
        availableCRStatusSuggestions={availableCRStatusSuggestions}
        availableProjectPhasesSuggestions={availableProjectPhasesSuggestions}
        {...rest}
      />
    );
  };
};

export default container;
