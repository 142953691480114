import React from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';
import { linkifyHtmlString } from 'utils';

export default ({ value }) => {
  if (!value || !(typeof value === 'string')) {
    return '';
  }

  const linkifiedHtml = linkifyHtmlString(value, true);
  const sanitized = DOMPurify.sanitize(linkifiedHtml, { ADD_ATTR: ['target'] });

  return (
    <Container
      dangerouslySetInnerHTML={{ __html: sanitized }}
      onClick={e => {
        if (e.nativeEvent.target.tagName === 'a') {
          e.stopPropagation();
        }
      }}
    />
  );
};

const Container = styled.div`
  /* force text to break on overflow */
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  strong {
    font-weight: bold;
  }
  p {
    display: block;
    min-height: 16px;
  }
  ul {
    list-style: disc;
    padding-left: 30px;
  }
  ol {
    list-style: decimal;
    padding-left: 30px;
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 26px;
  }
  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
  }
  blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  blockquote p {
    display: inline;
  }
  pre code {
    background-color: #eee;
    border: 1px solid #999;
    display: block;
    padding: 20px;
  }
  a {
    color: #009dff;
    text-decoration: underline;
  }
`;
