import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { spacing } from 'design-system/theme';
import Text from 'design-system/atoms/Text/index';

import Stars from 'design-system/atoms/DragonIcons/Stars';

const Header = ({ onClose }) => (
  <Container>
    <Flex>
      <StyledStarsIcon />
      <StyledText>Preview/Edit Summary</StyledText>
    </Flex>
    <Flex>
      <IconButton onClick={onClose}>
        <StyledCloseIcon />
      </IconButton>
    </Flex>
  </Container>
);

export default Header;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${spacing(10)}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${spacing(6)}px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2)}px;
`;

const StyledStarsIcon = styled(Stars)`
  width: 27px;
  height: 27px;

  path {
    fill: ${({ theme }) => theme.palette.white};
  }
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.palette.white};
  font-size: ${({ theme }) => theme.typography.fontSizeLargeRem}rem;
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.white};
`;
