import React, { useMemo } from 'react';
import isNumber from 'lodash/isNumber';

import useSystemFields from 'hooks/useSystemFields';
import { materialColors } from 'design-system/themes/default';
import { useCommonGridProps } from 'design-system/molecules/AgGridReact-New/hooks';
import { defaultColumnDefCommonProps, textCenterAlignedColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import { BASE_ROW_HEIGHT } from 'constants/grid';
import { ChipCellRenderer } from 'containers/IdeasList/IdeasList/New/hooks/useIdeasGridColumnsDefinitions/renderers';
import { Circle, gridStyles, getCellStyle } from './styles';
import { formatNumber, isTotalRow, makeIsColumnVisible } from './helpers';
import {
  TOTAL_ROW_ID,
  TOTAL_ROW_LABEL,
  GRID_HEIGHT_OFFSET,
  DEFAULT_COL_WIDTH,
  TITLE_COL_WIDTH,
  HEALTH_COL_WIDTH,
  DEFAULT_DRAWER_COL_WIDTH,
  TITLE_DRAWER_COL_WIDTH,
} from './constants';

const useAllocationReportSummaryGrid = ({
  data,
  total,
  totalReported,
  totalCompleted,
  totalScopeVariance,
  lsState,
  displayOptions,
  columnsOptions,
  selectedGroupBy,
  currentAllocationAmountTitle,
  clickableMetadata,
  isDrawerView,
  onTitleClick,
  handleTitleClick,
  handleColorPickStart,
  displayCurrentAllocationPercentage,
  displayCompletedAllocationPercentage,
  displayReportedAllocationPercentage,
  displayReportedVsPlannedDifference,
  displayScopeVariance,
}) => {
  const [getSystemFieldName] = useSystemFields();

  const commonGridProps = useCommonGridProps({});

  const isColumnVisible = makeIsColumnVisible(displayOptions, columnsOptions);

  const defaultColDef = {
    ...defaultColumnDefCommonProps,
    editable: false,
    sortable: true,
    resizable: true,
    filter: true,
    width: isDrawerView ? DEFAULT_DRAWER_COL_WIDTH : DEFAULT_COL_WIDTH,
    cellStyle: params => getCellStyle(params?.data, params?.colDef?.field, !!lsState.highlightOverInvestedGoals),
  };

  const healthColumnDef = useMemo(
    () => ({
      field: 'showHealth',
      headerName: '',
      hide: !displayOptions.includes('showHealth'),
      pinned: 'left',
      lockPinned: true,
      suppressMovable: true,
      width: HEALTH_COL_WIDTH,
      cellRenderer: ({ data }) => {
        if (isTotalRow(data)) return null;

        return (
          <Circle
            style={{
              background:
                data?.entity && data?.entity.status_color ? materialColors[data.entity.status_color.toLowerCase()] : null,
            }}
          />
        );
      },
    }),
    [displayOptions],
  );

  const titleColumnDef = useMemo(
    () => ({
      field: 'title',
      headerName: getSystemFieldName(selectedGroupBy.key),
      cellRenderer: params => {
        if (isTotalRow(params?.data)) return <strong>{TOTAL_ROW_LABEL}</strong>;

        return <ChipCellRenderer {...params} />;
      },
      cellRendererParams: params => {
        const shouldNotHaveOnClick = !handleTitleClick && !handleColorPickStart && !(params?.data?.id && clickableMetadata);

        return {
          color: params?.data?.color,
          onClick: shouldNotHaveOnClick ? null : onTitleClick(params?.data),
        };
      },
      editable: false,
      minWidth: isDrawerView ? TITLE_DRAWER_COL_WIDTH : TITLE_COL_WIDTH,
      pinned: 'left',
      lockPinned: true,
      suppressMovable: true,
      rowDrag: false,
    }),
    [getSystemFieldName, selectedGroupBy, isDrawerView],
  );

  const plannedPercentageColumnDef = useMemo(
    () => ({
      ...textCenterAlignedColumnDef,
      headerName: 'Planned (%)',
      hide: !isColumnVisible('showCurrentAllocationPercentage'),
      field: 'showCurrentAllocationPercentage',
      valueGetter: ({ data }) => data?.count,
      cellRenderer: ({ data }) => {
        if (isTotalRow(data)) return <strong>{total ? '100%' : '0%'}</strong>;

        return displayCurrentAllocationPercentage(data);
      },
    }),
    [isColumnVisible, total, displayCurrentAllocationPercentage],
  );

  const plannedDaysColumnDef = useMemo(
    () => ({
      ...textCenterAlignedColumnDef,
      headerName: currentAllocationAmountTitle,
      hide: !isColumnVisible('currentAllocationAmount'),
      field: 'currentAllocationAmount',
      valueGetter: ({ data }) => data?.count,
      cellRenderer: ({ data }) => {
        if (isTotalRow(data)) return <strong>{formatNumber(total)}</strong>;

        return data?.count ? formatNumber(data?.count) : 0;
      },
    }),
    [isColumnVisible, total, currentAllocationAmountTitle],
  );

  const completedPercentageColumnDef = useMemo(
    () => ({
      ...textCenterAlignedColumnDef,
      headerName: 'Completed (%)',
      hide: !isColumnVisible('showCompletedAllocationPercentage'),
      field: 'showCompletedAllocationPercentage',
      cellRenderer: ({ data }) => {
        if (isTotalRow(data)) return <strong>{totalCompleted ? '100%' : '0%'}</strong>;

        return displayCompletedAllocationPercentage(data);
      },
    }),
    [isColumnVisible, total, displayCompletedAllocationPercentage],
  );

  const completedDaysColumnDef = useMemo(
    () => ({
      ...textCenterAlignedColumnDef,
      headerName: `Completed (${lsState.duration.key}s)`,
      hide: !isColumnVisible('reportedAllocationAmount'),
      field: 'completedAllocationAmount',
      cellRenderer: ({ data }) => {
        if (isTotalRow(data)) return <strong>{formatNumber(totalCompleted)}</strong>;

        return data?.countCompleted ? formatNumber(data.countCompleted) : 0;
      },
    }),
    [isColumnVisible, totalCompleted],
  );

  const reportedPercentageColumnDef = useMemo(
    () => ({
      ...textCenterAlignedColumnDef,
      headerName: 'Reported (%)',
      hide: !isColumnVisible('showReportedAllocationPercentage'),
      field: 'showReportedAllocationPercentage',
      cellRenderer: ({ data }) => {
        if (isTotalRow(data)) return <strong>{totalReported ? '100%' : '0%'}</strong>;

        return displayReportedAllocationPercentage(data);
      },
    }),
    [isColumnVisible, totalReported, displayReportedAllocationPercentage],
  );

  const reportedDaysColumnDef = useMemo(
    () => ({
      ...textCenterAlignedColumnDef,
      headerName: `Reported (${lsState.duration.key}s)`,
      hide: !isColumnVisible('reportedAllocationAmount'),
      field: 'reportedAllocationAmount',
      cellRenderer: ({ data }) => {
        if (isTotalRow(data)) return <strong>{formatNumber(totalReported)}</strong>;

        return data?.countReported ? formatNumber(data.countReported) : 0;
      },
    }),
    [isColumnVisible, totalReported],
  );

  const differenceColumnDef = useMemo(
    () => ({
      ...textCenterAlignedColumnDef,
      headerName: 'Difference',
      hide: !isColumnVisible('showDifferencePlannedReported'),
      field: 'showDifferencePlannedReported',
      cellRenderer: ({ data }) => {
        if (isTotalRow(data)) return <strong>{formatNumber(totalReported - total)}</strong>;

        return displayReportedVsPlannedDifference(data);
      },
    }),
    [isColumnVisible, total, totalReported, displayReportedVsPlannedDifference],
  );

  const scopeVarianceColumnDef = useMemo(
    () => ({
      ...textCenterAlignedColumnDef,
      headerName: 'Scope Variance',
      hide: !isColumnVisible('showScopeVariance'),
      field: 'showScopeVariance',
      cellRenderer: ({ data }) => {
        if (isTotalRow(data)) return <strong>{displayScopeVariance(totalScopeVariance)}</strong>;

        return displayScopeVariance(data?.scopeVariance);
      },
    }),
    [isColumnVisible, totalScopeVariance, displayScopeVariance],
  );

  const targetPercentageColumnDef = useMemo(
    () => ({
      ...textCenterAlignedColumnDef,
      headerName: 'Target (%)',
      hide: !isColumnVisible('showTargetAllocationPercentage'),
      field: 'showTargetAllocationPercentage',
      cellRenderer: ({ data: row }) => {
        if (isTotalRow(row))
          return (
            <strong>
              {formatNumber(
                data.reduce((sum, d) => sum + (isNumber(d?.targetAllocationPercentage) ? d?.targetAllocationPercentage : 0), 0),
                '%',
              )}
            </strong>
          );

        if (row?.targetAllocationPercentage) {
          return formatNumber(row.targetAllocationPercentage, '%');
        }

        return '0%';
      },
    }),
    [isColumnVisible],
  );

  const targetAmountColumnDef = useMemo(
    () => ({
      ...textCenterAlignedColumnDef,
      headerName: 'Target Amount',
      hide: !isColumnVisible('showTargetAllocationAmount'),
      field: 'showTargetAllocationAmount',
      cellRenderer: ({ data: row }) => {
        if (isTotalRow(row))
          return (
            <strong>
              {formatNumber(
                data.reduce(
                  (sum, d) =>
                    sum + (d.entity && isNumber(d.entity.target_allocation_amount) ? d.entity.target_allocation_amount : 0),
                  0,
                ),
              )}
            </strong>
          );

        return row?.entity && row?.entity?.target_allocation_amount ? formatNumber(row.entity.target_allocation_amount) : 0;
      },
    }),
    [isColumnVisible],
  );

  const columnDefs = [
    healthColumnDef,
    titleColumnDef,
    plannedPercentageColumnDef,
    plannedDaysColumnDef,
    reportedPercentageColumnDef,
    reportedDaysColumnDef,
    differenceColumnDef,
    scopeVarianceColumnDef,
    targetPercentageColumnDef,
    targetAmountColumnDef,
    completedPercentageColumnDef,
    completedDaysColumnDef,
  ];

  const gridData = [...data.map(i => ({ ...i, title: i.label })), { id: TOTAL_ROW_ID }];

  const baseGridHeight = gridData?.length * BASE_ROW_HEIGHT;

  return {
    gridData,
    defaultColDef,
    columnDefs,
    rowHeight: BASE_ROW_HEIGHT,
    gridHeight: baseGridHeight + GRID_HEIGHT_OFFSET,
    gridStyles,
    commonGridProps,
  };
};

export default useAllocationReportSummaryGrid;
