export const LIST_HEADERS = {
  PAGE: {
    key: 'page',
    label: 'Page',
  },
  NAME: {
    key: 'name',
    label: 'View Name',
  },
  VISITS_COUNT: {
    key: 'visits-count',
    label: 'Total Visits',
  },
  OWNER: {
    key: 'owner',
    label: 'Owner',
  },
};
