import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import AddIcon from '@material-ui/icons/Add';

import { BaseGrid } from 'containers/Grids';
import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';
import { getRowId } from 'design-system/molecules/AgGridReact-New/helpers';
import { BASE_ROW_HEIGHT } from 'constants/grid';
import useSystemFields from 'hooks/useSystemFields';
import useProjectLightboxRequestsGrid from './useProjectLightboxRequestsGrid';

export default () => {
  const [getSystemFieldName] = useSystemFields();

  const { rows, addNewRow, columnDefs, defaultColDef, commonGridProps, commonGridEvents, gridContext, gridStyles, tableHeight } =
    useProjectLightboxRequestsGrid();

  const hasEmptyRow = rows.some(row => !row.id);

  return (
    <>
      <TopBar>
        <Text variant="h3">Customer {getSystemFieldName('request', true)}</Text>
        <FlexWrapper>
          <StyledButton onClick={() => addNewRow()} disabled={hasEmptyRow} size="small">
            <AddIcon />
            Create {getSystemFieldName('request')}
          </StyledButton>

          <StyledButton onClick={() => addNewRow(true)} disabled={hasEmptyRow} size="small">
            <LinkIcon />
            Link {getSystemFieldName('request')}
          </StyledButton>
        </FlexWrapper>
      </TopBar>

      <BaseGrid
        {...commonGridProps}
        {...commonGridEvents}
        getRowId={getRowId}
        rowData={rows}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        headerHeight={BASE_ROW_HEIGHT}
        context={gridContext}
        height={tableHeight}
        cssStyles={gridStyles}
      />
    </>
  );
};

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing()}px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${spacing(2)}px;
`;

const StyledButton = styled(Button)`
  span {
    gap: ${spacing()}px;
  }
`;
