import React, { useState } from 'react';
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';

import theme from 'design-system/theme';
import { getUserName } from 'utils';
import { getViewName } from 'utils/userViews';
import parseHtml from 'utils/parseHtml';
import ViewIconWithAvatar from './ViewIconWithAvatar';

export default ({ item, onSelectView }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <StyledListItem
      onClick={() => onSelectView(item)}
      onMouseOver={e => setAnchorEl(e.currentTarget)}
      onMouseLeave={e => setAnchorEl(null)}
    >
      <ListItemContainer>
        <ViewIconWithAvatar item={item} />
        <StyledListItemText
          primary={getViewName(item)}
          primaryTypographyProps={{
            style: {
              color: theme.palette.text.primary,
              cursor: 'pointer',
              lineHeight: `${theme.typography.lineHeightRegularLarge}px`,
            },
          }}
          secondary={getUserName(item.user)}
          secondaryTypographyProps={{
            style: {
              color: theme.palette.newLayout.text.grey,
              cursor: 'pointer',
              fontSize: theme.typography.fontSizeSmall,
            },
          }}
        />
      </ListItemContainer>
      {item.description && (
        <StyledPopper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          modifiers={{
            flip: {
              enabled: false,
            },
            offset: {
              enabled: true,
              offset: '45,-45',
            },
          }}
        >
          <StyledPaper>{parseHtml(item.description)}</StyledPaper>
        </StyledPopper>
      )}
    </StyledListItem>
  );
};

const StyledListItem = styled(ListItem)`
  && {
    padding: 9px 0;
  }
`;

const StyledListItemText = styled(ListItemText)`
  & :hover {
    color: ${theme.palette.newLayout.background.primary} !important;
  }
`;

const ListItemContainer = styled.div`
  display: flex;
`;

const StyledPopper = styled(Popper)`
  z-index: ${theme.zIndex.high};
`;

const StyledPaper = styled(Paper)`
  width: 215px;
  padding: 12px;
  font-size: ${theme.typography.fontSize}px;
  line-height: ${theme.typography.lineHeightRegular}px;
`;
