import { createStore } from 'redux';
import { persistStore } from 'redux-persist';
import axios from 'axios';

import { API_BASE_URL } from 'config';
import rootReducer from './rootReducer';
import setOrganizationHeader from '../utils/setOrganizationHeader';
import rootMiddleware from './middlewares';
import { setAppError } from 'store/app';
import { registerStore } from './store';

export default function initStore() {
  const store = createStore(rootReducer, {}, rootMiddleware);
  const persistor = persistStore(store);

  // axios interceptors
  axios.interceptors.request.use(
    config => {
      // Do something before request is sent
      if (API_BASE_URL) {
        config.url = `${API_BASE_URL}${config.url}`;
      }
      return config;
    },
    error => Promise.reject(error),
  );

  axios.interceptors.response.use(
    response => response,
    error => {
      const { response } = error;

      if (response && response.status === 500) {
        const { data } = response;

        store.dispatch(setAppError(data.message));
      }

      return Promise.reject(error);
    },
  );

  setOrganizationHeader();

  registerStore(store);

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return { store, persistor };
}
