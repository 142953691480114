import React, { useEffect } from 'react';

import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SettingsDialogs from 'components/SettingsDialogs';

import { BaseGrid } from 'containers/Grids';
import { useSettingsActions, useSettingsGridProps, useSettingsGridSelection } from 'containers/Grids/SettingsGrid/hooks';

import useErrorLogs from 'src/hooks/errorLogs/useErrorLogs';

import { ERROR_LOGS } from 'store/grids/constants';

import useErrorLogsGridColumns from './hooks/useErrorLogsGridColumns';
import useErrorLogsGridEventHandlers from './hooks/useErrorLogsGridEventHandlers';

const ERROR_LOG_KEY = 'Integration Alerts';

const ErrorLogs = () => {
  const { errorLogs, bulkDeleteErrorLogs, fetchErrorLogs } = useErrorLogs();

  const settingsGridProps = useSettingsGridProps();

  const { selectionMode, selectedItems, enableSelectionMode, disableSelectionMode, updateSelectedOnAgGridInit } =
    useSettingsGridSelection(ERROR_LOGS, {});

  const {
    itemToDelete,
    setItemToDelete,
    deleteItem,
    showBulkDeleteDialog,
    setShowBulkDeleteDialog,
    deleteProgress,
    bulkDeleteItems,
  } = useSettingsActions({
    settingType: ERROR_LOGS,
    selectedItems,
    disableSelectionMode,
    bulkDelete: bulkDeleteErrorLogs,
  });

  const errorLogsGridEventHandlers = useErrorLogsGridEventHandlers({ errorLogs });

  const { defaultColDef, columnDefs } = useErrorLogsGridColumns({ allowActions: true, selectionMode });

  useEffect(() => {
    fetchErrorLogs();
  }, []);

  return (
    <>
      <SettingsActionsToolbar
        hideAddButton
        canBulkDelete
        selectionMode={selectionMode}
        selectedItems={selectedItems}
        onEnableSelection={enableSelectionMode}
        onCancelSelection={disableSelectionMode}
        setShowBulkDeleteDialog={setShowBulkDeleteDialog}
      />
      <BaseGrid
        onGridReady={updateSelectedOnAgGridInit}
        rowData={errorLogs}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        {...settingsGridProps}
        {...errorLogsGridEventHandlers}
      />
      <SettingsDialogs
        settingKey={ERROR_LOG_KEY}
        settingType={ERROR_LOGS}
        showBulkDeleteDialog={showBulkDeleteDialog}
        setShowBulkDeleteDialog={setShowBulkDeleteDialog}
        deleteProgress={deleteProgress}
        deleteItem={deleteItem}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        bulkDelete={bulkDeleteItems}
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
      />
    </>
  );
};

export default ErrorLogs;
