import reduceReducers from 'reduce-reducers';
import { pathOr } from 'ramda';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import {
  FETCH_REQUEST_HISTORY,
  FETCH_REQUEST_HISTORY_FULFILLED,
  FETCH_REQUEST_HISTORY_NEXT_FULFILLED,
  RESET_REQUEST_HISTORY,
} from './types';
import { START_CREATING_CUSTOMER_REQUEST, START_EDITING_CUSTOMER_REQUEST } from 'store/customerRequests/types';

const getMeta = pathOr({}, ['payload', '_meta']);
const getHistoryData = pathOr([], ['payload', 'data']);

const { initialState: operationsInitialState, reducers: operationsReducers } = getThunksInitialStateAndReducers([
  FETCH_REQUEST_HISTORY,
]);

const initialState = {
  ...operationsInitialState,
  history: [],
  meta: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REQUEST_HISTORY_FULFILLED:
      return {
        ...state,
        history: getHistoryData(action),
        meta: getMeta(action),
      };
    case FETCH_REQUEST_HISTORY_NEXT_FULFILLED:
      return {
        ...state,
        history: [...state.history, ...getHistoryData(action)],
        meta: getMeta(action),
      };
    case RESET_REQUEST_HISTORY:
    case START_CREATING_CUSTOMER_REQUEST:
    case START_EDITING_CUSTOMER_REQUEST:
      return {
        ...state,
        history: [],
        meta: {},
      };
    default:
      return state;
  }
};

export default reduceReducers(initialState, reducer, ...operationsReducers);
