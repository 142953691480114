import React, { useEffect, useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import { ucFirst, getDuplicatedToastErrorMessage } from 'utils';
import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { getProjectFieldToUpdate } from 'store/projects/helpers/groupMetadata';
import useProjectAutofillBasedOnQuickfilter from 'hooks/projects/useProjectAutofillBasedOnQuickfilter';
import { DISABLE_ADD_GROUPS } from 'constants/common';
import theme from 'design-system/theme';

import Table from './components/Table/Table';

import useSummaryState from '../hooks/useSummaryState';
import { useSummaryTableData } from '../hooks/useSummaryTableData';
import useSystemFields from 'hooks/useSystemFields';
import {
  computeGroupId,
  createNewProjectToast,
  setDataId,
  updateProjectWithGroupData,
  getSortedProjectByRowAndColAndGroup,
} from '../helpers';
import { getSettingsUrl } from 'routes/Ideas/IdeasBoard/utils';
import { ROADMAP, SUB_ROADMAP, DUPLICATED_ERROR_CODE } from 'config';
import { METADATA_KEY_MAPPER } from '../helpers/summaryMetadataKeyMapper';

const direction = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

const GROUP_TYPE = 'group';
const GROUP_SEPARATOR = '*';
const STRINGIFIED_NULL = 'null';
const PLANNING_STAGE_KEY = 'planningStage';
const GROUP_KEYS_TO_DISABLE_AUTO_OPEN_ON_CREATE = ['phase', 'planningStage'];

const SummaryTable = ({
  isLoading,
  displayLayer,
  showActionsBar,
  isTrial,
  hasEditPermissions,
  metadata,
  pageFilters,
  projects,
  normalizedProjects,
  onChangingPlanningStage,
  showImportIdeasLightbox,
  openProjectLightbox,
  createProject,
  updateProject,
  switchProjectRowOrder,
  createTag,
  createCustomer,
  emptyTableMessage,
  isGoalMode,
  slice,
  getInlineGroupTitle,
  isValidDropUpdate,
  onDragStartCallback,
  allowAddNewProject = true,
  fieldsToIgnoreAddColumnRow,
  ...remainingActions
}) => {
  const { tableData, localAddProject, localUpdateProject, localUpdateRowOrderProject } = useSummaryTableData();

  const {
    isReadOnly,
    hideEmptyLanes,
    selectedColOption,
    selectedRowOption,
    selectedGroupByOption,
    isGroupIdExpanded,
    updateState,
    updateColumnWidth,
    updateExpandedGroups,
    addNewGrouping,
    userCanToggleLanesVisibility,
  } = useSummaryState(isGoalMode, slice);

  const [getSystemFieldName] = useSystemFields();

  const autoFillData = useProjectAutofillBasedOnQuickfilter();

  const disableAddNewProject = useMemo(
    () => !allowAddNewProject || DISABLE_ADD_GROUPS.includes(selectedGroupByOption?.key),
    [selectedGroupByOption, allowAddNewProject],
  );

  const showMessageWhenEmpty = useMemo(() => isGoalMode && emptyTableMessage, [isGoalMode, emptyTableMessage]);
  const tableIsEmpty = useMemo(() => isEmpty(tableData?.cols) && isEmpty(tableData?.rows), [tableData]);

  const handleAddMetadata = async (form, type) => {
    const keyField = type === 'col' ? selectedColOption.key : selectedRowOption.key;

    let createMethod = remainingActions[`create${ucFirst(keyField)}`];

    switch (keyField) {
      case 'tags':
        createMethod = createTag;
        break;
      case 'customers':
        createMethod = data => createCustomer({ name: data.title });
        break;
      case 'bet':
        createMethod = data => createProject({ layer: BET_LAYER, ...data });
        break;
      case 'initiative':
        createMethod = data => createProject({ layer: INITIATIVE_LAYER, ...data });
        break;
      default:
        break;
    }

    if (typeof createMethod === 'function') {
      await createMethod(form)
        .then(res => {
          const resultId = res?.id || res?.value?.id;
          const resultMetadataKey = METADATA_KEY_MAPPER[keyField];
          const shouldAddToGrouping = resultId && resultMetadataKey && userCanToggleLanesVisibility;

          if (shouldAddToGrouping) {
            addNewGrouping(res?.value || res, resultMetadataKey);
          }
        })
        .catch(err => {
          const { data } = err.response;

          if (data && data.error_code === DUPLICATED_ERROR_CODE) {
            toast(
              getDuplicatedToastErrorMessage(
                getSystemFieldName(selectedRowOption.key),
                form.title,
                getSystemFieldName(ROADMAP),
                getSystemFieldName(SUB_ROADMAP),
                getSettingsUrl(selectedRowOption.key),
              ),
            );
          }
        });

      if (hideEmptyLanes) {
        toast('Please click the hide empty lane icon to see the newly created lane.');
      }
    }
  };

  const handleResizeColumn = (colId, newWidth) => {
    updateColumnWidth({
      [colId]: newWidth,
    });
  };

  const handleChangeColumnOrder = orders => {
    updateState({
      colsOrder: [...orders],
    });
  };

  const handleChangeRowOrder = newOrders => {
    updateState({
      rowsOrder: newOrders.map(order => order.id),
    });
  };

  const handleToggleGroup = (groupId, expanded) => {
    updateExpandedGroups({ groupId, expanded });
  };

  const handleAddProject = async (newInput, newProject) => {
    if (!newProject.title) return;

    newProject.layer = displayLayer;

    updateProjectWithGroupData(newProject, selectedColOption, metadata, newInput.colId);
    updateProjectWithGroupData(newProject, selectedRowOption, metadata, newInput.rowId);

    if (
      newInput.groupId !== undefined &&
      selectedGroupByOption &&
      selectedGroupByOption.key !== selectedColOption.key &&
      selectedGroupByOption.key !== selectedRowOption.key
    ) {
      updateProjectWithGroupData(newProject, selectedGroupByOption, metadata, newInput.groupId);
    }

    // Add the new project to the local state to avoid the back and forth issue
    localAddProject(newProject);

    // Check if the group of the new project is closed, if so open it
    if (selectedGroupByOption && !GROUP_KEYS_TO_DISABLE_AUTO_OPEN_ON_CREATE.includes(selectedGroupByOption.key)) {
      const groupId = computeGroupId(newInput.rowId, newInput.colId, newInput?.groupId ?? null);

      if (!isGroupIdExpanded(groupId)) {
        updateExpandedGroups({ groupId, expanded: true });
      }
    }

    // Add the new project in the backend
    const createdProject = await createProject({
      ...autoFillData,
      ...newProject,
    });

    if (createdProject) {
      createNewProjectToast(createdProject, openProjectLightbox);
    }
  };

  const _changeProjectRowOrder = useCallback(
    async (_project, destRowId, destColId, sourceIndex, destIndex, newGroupKey, newGroupValue) => {
      // Scoped projects can be projects in the cell when there is no groupBy option selected or the group projects otherwise
      const scopedProjects = getSortedProjectByRowAndColAndGroup(
        tableData.rows,
        destRowId,
        destColId,
        newGroupKey,
        newGroupValue,
      );

      const positionLabel = sourceIndex > destIndex ? direction.TOP : direction.BOTTOM;
      const otherProject = scopedProjects[destIndex];
      const otherProject1 = scopedProjects[positionLabel === direction.BOTTOM ? destIndex + 1 : destIndex - 1];
      const prev = positionLabel === direction.BOTTOM ? otherProject1 : otherProject;
      const next = positionLabel === direction.BOTTOM ? otherProject : otherProject1;

      // Update the project row order in the local state to avoid the back and forth issue
      localUpdateRowOrderProject(_project, prev, next);

      await switchProjectRowOrder(_project?.id, otherProject?.id, null, positionLabel);
    },
    [tableData.rows],
  );

  const handleUpdateProject = async (dragInfo, destInfo, sourceIndex, destIndex) => {
    const [destType, newRowId, newColId, ...restGroupId] = destInfo;
    const [, originalRowId, originalColId, originalGroupId, projId] = dragInfo;

    const newGroupId = destType === GROUP_TYPE ? restGroupId.join(GROUP_SEPARATOR) : originalGroupId;

    const projectId = projId === STRINGIFIED_NULL ? null : parseInt(projId, 10);
    const destRowId = setDataId(selectedRowOption.key, newRowId);
    const destColId = setDataId(selectedColOption.key, newColId);

    const project = normalizedProjects[projectId];

    if (!project?.id) {
      return;
    }

    const projectUpdate = { id: project.id, layer: displayLayer };

    updateProjectWithGroupData(projectUpdate, selectedColOption, metadata, destColId);
    updateProjectWithGroupData(projectUpdate, selectedRowOption, metadata, destRowId);

    let gId = computeGroupId(destRowId, destColId, STRINGIFIED_NULL);

    const rowFieldId = getProjectFieldToUpdate(selectedRowOption.field);
    const colFieldId = getProjectFieldToUpdate(selectedColOption.field);

    const isSameRow = projectUpdate[rowFieldId] === project[rowFieldId];
    const isSameCol = projectUpdate[colFieldId] === project[colFieldId];

    const isGroupBySelected = selectedGroupByOption && !!selectedGroupByOption.key;

    if (!isGroupBySelected && isSameRow && isSameCol) {
      await _changeProjectRowOrder(project, destRowId, destColId, sourceIndex, destIndex);

      return;
    }

    if (isGroupBySelected) {
      const destGroupId = setDataId(selectedGroupByOption.key, newGroupId);

      updateProjectWithGroupData(projectUpdate, selectedGroupByOption, metadata, destGroupId);

      const selectedGroupByOptionField = getProjectFieldToUpdate(selectedGroupByOption.field);
      const originalProjectValueStringified = project[selectedGroupByOptionField]
        ? String(project[selectedGroupByOptionField])
        : STRINGIFIED_NULL;

      const isSameRow = newRowId === originalRowId;
      const isSameCol = newColId === originalColId;
      const isSameGroup = newGroupId === originalProjectValueStringified;

      if (isSameRow && isSameCol && isSameGroup) {
        gId = computeGroupId(destRowId, destColId, destGroupId);

        // Change project row order
        if (project[selectedGroupByOption.field] === projectUpdate[selectedGroupByOption.field]) {
          await _changeProjectRowOrder(
            project,
            destRowId,
            destColId,
            sourceIndex,
            destIndex,
            selectedGroupByOption.field,
            project[selectedGroupByOption.field],
          );

          return;
        }
      }
    }

    // Is not allowed to change the planning stage of the project with drag and drop
    const projectIndex = projects.findIndex(p => p.id === projectId);

    if ([selectedColOption.key, selectedRowOption.key].includes(PLANNING_STAGE_KEY)) {
      const project = projects[projectIndex];

      if (project.planningStage !== projectUpdate.planningStage) {
        onChangingPlanningStage(true);

        delete projectUpdate.planningStage;
      }
    }

    // Update the project in the local state to avoid the back and forth issue
    localUpdateProject(projectUpdate);

    // Update the project in the backend
    await updateProject(projectUpdate);

    const updatedProjectGroupBy = String(projectUpdate[getProjectFieldToUpdate(selectedGroupByOption.field)]);
    const targetGroupId = updatedProjectGroupBy ? computeGroupId(destRowId, destColId, updatedProjectGroupBy) : gId;

    if (!isGroupIdExpanded(targetGroupId)) {
      updateExpandedGroups({ groupId: targetGroupId, expanded: true });
    }
  };

  const handleDragStart = result => {
    onDragStartCallback && onDragStartCallback(result);
  };

  const handleDragEnd = result => {
    const { destination, source, draggableId } = result;

    // No Destination
    if (!destination) return;
    // Same Destination
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    // extra validations
    if (isValidDropUpdate && !isValidDropUpdate(result)) {
      return;
    }

    const destInfo = destination.droppableId.split(GROUP_SEPARATOR);
    const dragInfo = draggableId.split(GROUP_SEPARATOR);

    if (dragInfo[0] !== GROUP_TYPE) {
      handleUpdateProject(dragInfo, destInfo, source.index, destination.index);
    }
  };

  useEffect(() => {
    const updatedState = {
      isReadOnly: isReadOnly === undefined ? !hasEditPermissions : isReadOnly,
    };

    updateState(updatedState, false);
  }, []);

  if (tableIsEmpty && showMessageWhenEmpty) {
    return <StyledText color={theme.palette.text.error}>{emptyTableMessage}</StyledText>;
  }

  return (
    <Table
      rows={tableData.rows}
      cols={tableData.cols}
      addImportHeader={isTrial}
      onOpenImport={showImportIdeasLightbox}
      showActionsBar={showActionsBar}
      onProjectClick={openProjectLightbox}
      disableAddNewProject={disableAddNewProject}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onCreateProject={handleAddProject}
      onAddColumnOrRow={handleAddMetadata}
      onResizeColumn={handleResizeColumn}
      onChangeColumnOrder={handleChangeColumnOrder}
      onChangeRowOrder={handleChangeRowOrder}
      onToggleGroup={handleToggleGroup}
      slice={slice}
      getInlineGroupTitle={getInlineGroupTitle}
      fieldsToIgnoreAddColumnRow={fieldsToIgnoreAddColumnRow}
      metadata={metadata}
    />
  );
};

export default SummaryTable;

const StyledText = styled(TextDeprecated)`
  && {
    margin: 25px;
  }
`;
