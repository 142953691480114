import React from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { pluck } from 'ramda';

import Summary from 'routes/Dashboard/Summary/Summary';

import { getCurrentUser } from 'store/login/selectors';
import { selectOrganization } from 'store/organization';
import useMetadata from 'routes/Dashboard/Summary/hooks/useMetadata';

import { PAGE_HEADER_HEIGHT } from 'constants/common';
import { ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PAGE } from 'constants/filters/pages';
import { COMPARE_ROADMAP_HISTORY_SUMMARY } from 'features/RoadmapHistory/constants';

import useLoadProjectsForSnapComparison from '../../hooks/useLoadProjectsForSnapComparison';
import useSnapsGroups from './hooks/useSnapsGroups';

import ActionBarComponent from './components/ActionsBarComponent';

import { getGroupOptions, getGroupOptionByKey, getDateOptions } from './helpers/groupOptions';
import { getGroupOptionsSelector } from './helpers/groupSelectors';

const emptyTableMessage = `0 projects matching the search criteria. Please update filter or display option.`;
const getKeys = pluck('key');

const fieldsToIgnoreAddColumnRow = ['scenarioVersions', ...getKeys(getDateOptions())];

const innerGroupTitle = (group, groupProjects) => {
  const totalBenefit = groupProjects.reduce((acc, element) => {
    return acc + (element?.project?.business_value ?? 0);
  }, 0);

  return `${group.title} (${groupProjects.length} | ${totalBenefit})`;
};

const CompareRoadmapHistorySnapsSummary = () => {
  useLoadProjectsForSnapComparison();
  const currentUser = useSelector(getCurrentUser);
  const organization = useSelector(selectOrganization);
  const metadata = useMetadata(organization, []);

  return (
    <StyledSummary
      isLoading={false}
      projects={[]}
      normalizedProjects={[]}
      metadata={metadata}
      isTrial={false}
      currentUser={currentUser}
      hasEditPermissions={false}
      pageFilters={{}}
      showControlsForUser
      page={ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PAGE}
      emptyTableMessage={emptyTableMessage}
      useGroupsHook={useSnapsGroups}
      getGroupOptionsSelector={getGroupOptionsSelector}
      getGroupOptions={getGroupOptions}
      getGroupOptionByKey={getGroupOptionByKey}
      slice={COMPARE_ROADMAP_HISTORY_SUMMARY}
      ActionbarComponent={ActionBarComponent}
      openProjectLightbox={() => {}}
      getInlineGroupTitle={innerGroupTitle}
      allowAddNewProject={false}
      fieldsToIgnoreAddColumnRow={fieldsToIgnoreAddColumnRow}
    />
  );
};

const StyledSummary = styled(Summary)`
  height: calc(100vh - ${PAGE_HEADER_HEIGHT} - 4px);
`;

export default CompareRoadmapHistorySnapsSummary;
