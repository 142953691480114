import { has, propOr } from 'ramda';

import { materialColors } from 'design-system/themes/default';

const DISPLAY_DATA_CONSISTENT_RANDOM_PLACEHOLDER_COLOR = '<consistent-random>';

// source: https://www.materialui.co/colors
const colors = [
  '#673AB7',
  '#03DEA3',
  '#FFBA00',
  '#F4CEFF',
  '#E1E5B0',
  '#D1EEF8',
  '#FFCCCC',
  '#E2E2E2',
  '#E9F098',
  '#C0E9F5',
  '#A4F2A1',
  '#93E3FF',
  '#93E3FF',
  '#93E3FF',
  '#F0F4D3',
];

const hashCode = s => {
  let h;

  for (let i = 0; i < s.length; i++) {
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  }

  return h;
};

/**
 * Returns one of `colors` based on the hash of `contentToHash`. Same input will always generate same output.
 * @param {String} contentToHash
 * @param {String[]} colors - hex colors
 * @returns {String} hex color
 */
const consistentColorFromHash = (contentToHash, colors) => {
  const code = hashCode(contentToHash);

  return colors[Math.abs(code) % colors.length];
};

const generateColorForMetadata = metadata => {
  const defaultColor = materialColors.darkGray;
  const color = propOr(defaultColor, 'color')(metadata);

  if (color === DISPLAY_DATA_CONSISTENT_RANDOM_PLACEHOLDER_COLOR) {
    if (has('title')(metadata)) {
      return consistentColorFromHash(metadata.title, colors);
    }
    return defaultColor;
  }

  return color;
};

export default generateColorForMetadata;
