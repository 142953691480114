import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import ImportIcon from '@material-ui/icons/CloudUploadOutlined';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import DividedList from 'design-system/organisms/DividedList/index';
import OutlinedPurpleButton from 'design-system/molecules/OutlinedPurpleButton/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import OverviewVideoLightbox from 'components/OverviewVideoLightbox';
import BookDemoLightbox from 'components/BookDemoLightbox';
import ImportIdeasDialog from 'containers/ImportIdeasDialog';
import { checkRolePermission } from 'containers/UserPermission/utils';

import history from 'store/utils/history';

import AppFeatures from './AppFeatures';

export default ({ currentUser, currentUserIsOwnerOfOrg, getSystemFieldName, showImportIdeasLightbox }) => {
  const [showOverviewVideo, setShowOverviewVideo] = useState(false);
  const [showBookDemo, setShowBookDemo] = useState(false);

  const options = useMemo(() => {
    return [
      { label: 'Home', id: 'home_link', onClick: () => history.push('/home') },
      { label: 'Product Tour', id: 'welcome_link', active: true },
      { label: 'Overview Video', onClick: () => setShowOverviewVideo(true) },
      {
        label: 'Quick Start Guide',
        href: 'https://dragonboat.zendesk.com/hc/en-us/articles/360013275474-dragonboat-Quick-Start',
      },
      { label: 'Select a Template', onClick: () => history.push('/dashboard/templates') },
    ];
  }, [setShowOverviewVideo]);

  const linkOptions = useMemo(() => {
    return [
      {
        label: 'Join Slack Community',
        href: 'https://join.slack.com/t/ourdragonboat/shared_invite/zt-7dwylil3-j9hp8BXfl8rBQ0bD8k160Q"',
      },
      { label: 'Webinars', href: 'https://dragonboat.io/category/webinar/' },
      { label: 'Responsive PPM', href: 'http://responsiveppm.org/' },
    ];
  }, []);

  return (
    <PageContent>
      <ImportIdeasBlock>
        {checkRolePermission('IMPORT_IDEAS', currentUser) && (
          <ButtonIcon data-cy="import-ideas" onClick={showImportIdeasLightbox} title="Import">
            <ImportIcon />
          </ButtonIcon>
        )}
        <ImportIdeasDialog />
      </ImportIdeasBlock>
      <ContentSection>
        <Column style={{ flex: 0.15 }}>
          <BookDemoButton
            buttonText="Book a Demo"
            onClick={() => setShowBookDemo(true)}
            extraStyles="position: absolute; top: 48px; width: 135px; margin-left: 10px;"
          />
          <SidebarOptions bottomOptions={linkOptions} topOptions={options} />
        </Column>
        <ColumnRight style={{ flex: 0.85 }}>
          <TitleSection>
            <TextDeprecated variant="h4" component="h1">
              Ok, {currentUser.first_name}, let&apos;s get rowing!
            </TextDeprecated>
          </TitleSection>
          <AppFeatures getSystemFieldName={getSystemFieldName} />
        </ColumnRight>
      </ContentSection>
      <OverviewVideoLightbox open={showOverviewVideo} onClose={() => setShowOverviewVideo(false)} />
      <BookDemoLightbox open={showBookDemo} onClose={() => setShowBookDemo(false)} />
    </PageContent>
  );
};

const PageContent = styled.div`
  height: calc(100vh - 68px);
  padding: 0 15px;
  overflow: auto;
  position: relative;
`;

const TitleSection = styled.div`
  min-height: 100px;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 45px;

  h1 {
    text-align: left;
    font: normal normal 300 40px/53px Inter;
    letter-spacing: 0px;
    color: #131c23;
  }
`;

const ContentSection = styled.div`
  display: flex;
`;

const Column = styled.div``;

const ColumnRight = styled.div`
  border-left: 1px solid #e6e6e6;
  height: calc(100vh - 68px);
`;

const BookDemoButton = styled(OutlinedPurpleButton)``;

const SidebarOptions = styled(DividedList)`
  margin-top: 112px;
  padding: 0 15px 28px 11px;
`;

const ImportIdeasBlock = styled.div`
  position: absolute;
  right: 24px;
  top: 6px;
`;
