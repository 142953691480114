import React from 'react';

import { planningStageColors } from 'utils';

const EDITABLE_FIELDS = ['title', 'color'];

const checkIsEditable = ({ colDef, data }) => {
  const { field } = colDef;

  if (EDITABLE_FIELDS.includes(field) && !data.isGroupRow) {
    return true;
  }
  
  return !data.isGroupRow && !data.system;
};

const checkShouldDisableDelete = data => !data?.isGroupRow && data?.system;

const checkDeleteVisibility = ({ data }) => !data?.isGroupRow;

const checkCheckboxSelection = ({ data }) => !data?.isGroupRow;

const checkAddVisibility = ({ data }) => data?.isGroupRow;

const checkShouldDisableBulkDelete = selectedItems => selectedItems.some(checkShouldDisableDelete);

const checkShouldDisableMergeOption = (item, selectedItems) => {
  const systemItems = selectedItems.filter(item => item.system);

  if (item.system && systemItems.length === 1) {
    return false;
  }

  return systemItems.length;
};

const checkRowDrag = ({ data }) => !data?.isGroupRow && !data.system;

const getDataPath = data => data.path || [data.title];

const createMetadataRoadmapColumnCellRenderer = DefaultCellRenderer => {
  return params => (params.data.isGroupRow ? '' : <DefaultCellRenderer {...params} />);
};

const retrieveColor = data => planningStageColors[data.id];

export {
  checkIsEditable,
  checkShouldDisableDelete,
  checkDeleteVisibility,
  checkCheckboxSelection,
  checkShouldDisableBulkDelete,
  checkShouldDisableMergeOption,
  checkAddVisibility,
  checkRowDrag,
  getDataPath,
  createMetadataRoadmapColumnCellRenderer,
  retrieveColor,
};
