import React from 'react';
import MuiListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import RightArrowIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import styled from 'styled-components';

import theme from 'design-system/theme';

export default ({ label, onClick, isActive, showArrow = true }) => (
  <ListItem button key={label} onClick={onClick} style={{ paddingLeft: 26 }}>
    <ListItemText
      primary={label}
      primaryTypographyProps={{ style: { color: isActive && theme.palette.primary.main } }}
      style={{ paddingRight: 4 }}
    />
    {showArrow && (
      <ListItemSecondaryAction style={{ right: 10, pointerEvents: 'none' }}>
        <RightArrowIcon style={{ color: theme.palette.primary.main }} />
      </ListItemSecondaryAction>
    )}
  </ListItem>
);

const ListItemText = styled(MuiListItemText)`
  &&&& {
    &:hover {
      color: ${({ theme }) => theme.palette.newLayout.background.primary};
    }
  }
`;
