import React, { memo } from 'react';
import invertedTextColor from 'design-system/utils/invertedTextColor';

import {
  DATE_FORMATTER,
  DEFAULT_SNAP,
  DEFAULT_Y_SNAP,
  DND_BOUNDS_NAME,
  MILESTONES_DND_BOUNDS_NAME,
  OVERFLOW_TEXT_COLOR,
  ROW_HEIGHT,
  snapToGridOptions,
  zoomModes,
} from '../../helpers';

import TimelineMilestone from './TimelineMilestone';
import TimelineBar from './TimelineBar';

const TimelineItem = memo(
  ({
    id,
    title,
    color,
    borderColor,
    type,
    ownerName,
    startDate,
    endDate,
    predictedEndDate,
    progress,
    info,
    isMilestoneGroupTopLane,
    openBody,
    onDoubleClick,
    onDragStop,
    onResizeStop,
    isLoading,
    showTooltip,
    showTextOverflow,
    resizable,
    draggable,
    mode,
    slotWidth,
    snapToGridOn,
    top = 0,
    isMilestoneItemChecker,
    milestoneDiamondColor,
    showTitle = true,
    startDateEstimates,
    endDateEstimates,
    tooltipLabels,
  }) => {
    const props = {
      id,
      name: ownerName,
      title,
      color,
      borderColor,
      startDate: startDate.format(DATE_FORMATTER),
      endDate: endDate.format(DATE_FORMATTER),
      startDateEstimates: startDateEstimates.format(DATE_FORMATTER),
      endDateEstimates: endDateEstimates.format(DATE_FORMATTER),
      predictedEndDate:
        snapToGridOn === snapToGridOptions.PREDICTED_END_DATE && predictedEndDate ? predictedEndDate.format('YYYY-MM-DD') : null,
      progress,
      textColor: showTextOverflow ? OVERFLOW_TEXT_COLOR : invertedTextColor(color, true),
      top,
      left: info.left,
      width: info.width,
      height: ROW_HEIGHT,
      boundsname: isMilestoneGroupTopLane ? MILESTONES_DND_BOUNDS_NAME : DND_BOUNDS_NAME,
      openBody,
      onDoubleClick,
      onDragStop,
      onResizeStop,
      isLoading,
      showTooltip,
      showTextOverflow,
      resizable,
      disableDragX: !draggable,
      disableDragY: !draggable,
      resizeGrid: mode === zoomModes.DAY ? [slotWidth, DEFAULT_SNAP] : [DEFAULT_SNAP, DEFAULT_SNAP],
      dragGrid: snapToGridOn || mode === zoomModes.DAY ? [slotWidth, DEFAULT_Y_SNAP] : [DEFAULT_SNAP, DEFAULT_Y_SNAP],
      milestoneDiamondColor,
      showTitle,
      snapToGridOn,
      tooltipLabels,
    };

    return isMilestoneItemChecker({ type }) ? <TimelineMilestone {...props} /> : <TimelineBar {...props} />;
  },
);

TimelineItem.displayName = 'TimelineItem';

export default TimelineItem;
