import React from 'react';
import styled from 'styled-components';

import DragonViewHeader from '../DragonViewHeader';

export default ({ currentPath, selectedView, viewOwner, isFavoriteView, handleFavoriteUserView }) => {
  return (
    <Wrapper>
      <DragonViewHeader
        currentPath={currentPath}
        selectedView={selectedView}
        viewOwner={viewOwner}
        isFavoriteView={isFavoriteView}
        handleFavoriteUserView={handleFavoriteUserView}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &&&& {
    display: flex;
    align-items: center;
  }
`;
