import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import callAgGridApiMethod from 'utils/callAgGridApiMethod';

import useUpdateGridStateFromViewData from 'hooks/grid/useUpdateGridStateFromViewData';
import useGridExpandedItems from 'hooks/grid/useGridExpandedItems';
import useActiveViewChanged from 'hooks/userViews/useActiveViewChanged';
import { ProjectsListLightboxProvider } from 'hooks/useProjectsListLightbox';
import useGridApiContext from 'hooks/grid/useGridApiContext';
import { getUsers } from 'store/users/selectors';
import { getActiveMetrics } from 'store/metrics/selectors';

import { ROW_HEIGHT_DEFAULT, FOOTER_HEIGHT } from '../../helpers/constants';
import OkrTable from '../../../OkrTable/OkrTable';

const OKRTableOnSnapshot = ({
  rows,
  title,
  selectedSnapshotField,
  showSnapshotTreeView,
  openMetricView,
  onUpdateMetadataFieldById,
  displayLayer,
  snapshotTableVisibleFields,
  rowHeight = ROW_HEIGHT_DEFAULT,
  pageSize,
  expandTableItems,
  hasAdvancedMetricReporting,
  hasKeyResults,
  hasKeyResults2,
  isGoalMode = false,
  withPagination = true,
  overrideTableHeight,
  saveGridState,
  gridState,
  createMetricOption,
  gridId,
  tableHeight,
  onGridReady,
  searchString,
}) => {
  const gridApi = useRef();
  const gridColumnApi = useRef();
  const expandAllCollapseAllTableItems = useRef(expandTableItems);

  const users = useSelector(getUsers);
  const metrics = useSelector(getActiveMetrics);

  const { activeViewChanged, switchedToDefaultView } = useActiveViewChanged();

  const { registerGridApi } = useGridApiContext();

  const { isItemExpandedByDefault, hasInvidualExpandedItems, clearExpandedItems, setAllItemsExpanded } = useGridExpandedItems(
    gridId,
    gridApi.current,
  );

  const { onGridReadySetData, updateDataOnStateGridChange } = useUpdateGridStateFromViewData(gridState, saveGridState);

  const _redoRowHeight = () => {
    if (!gridApi.current) return;

    try {
      gridApi.current.resetRowHeights();
      gridApi.current.sizeColumnsToFit();
    } catch (e) {
      // pass
    }
  };

  const onOkrTableGridReady = useCallback(params => {
    gridApi.current = params.api;
    gridColumnApi.current = params.columnApi;

    registerGridApi(gridId, params.api, params.columnApi);

    params.api.sizeColumnsToFit();
    onGridReadySetData(gridApi, gridColumnApi);
    onGridReady(params);
  }, []);

  useEffect(() => {
    if (gridApi.current) {
      gridApi.current.sizeColumnsToFit();
    }
  }, [title, displayLayer, snapshotTableVisibleFields]);

  useEffect(() => {
    const expandCollapseToggleChanged = expandAllCollapseAllTableItems.current !== expandTableItems;

    if (!gridApi.current || !expandCollapseToggleChanged) return;

    expandAllCollapseAllTableItems.current = expandTableItems;

    if (expandCollapseToggleChanged && (!activeViewChanged || switchedToDefaultView)) {
      if (!expandTableItems) {
        callAgGridApiMethod(gridApi.current, _api => _api.collapseAll());
        clearExpandedItems();
      } else {
        callAgGridApiMethod(gridApi.current, _api => _api.expandAll());
        setAllItemsExpanded();
      }

      return;
    }

    if (hasInvidualExpandedItems) {
      // We are keeping track of individual rows opened, not doing anything on this effect execution
      return;
    }

    callAgGridApiMethod(gridApi.current, _api => (expandTableItems ? _api.expandAll() : _api.collapseAll()));
  }, [
    expandAllCollapseAllTableItems.current,
    hasInvidualExpandedItems,
    expandTableItems,
    gridApi,
    activeViewChanged,
    switchedToDefaultView,
  ]);

  useEffect(() => {
    updateDataOnStateGridChange(gridApi, gridColumnApi);
  }, [gridState]);

  useEffect(_redoRowHeight, [rowHeight]);

  return (
    <ProjectsListLightboxProvider>
      <OkrTable
        items={rows}
        hasKeyResults={hasKeyResults}
        hasKeyResults2={hasKeyResults2}
        onGridReady={onOkrTableGridReady}
        isTreeView={showSnapshotTreeView}
        selectedField={selectedSnapshotField}
        tableHeight={overrideTableHeight || `${tableHeight + FOOTER_HEIGHT}px`}
        rowHeight={rowHeight}
        pageSize={pageSize}
        visibleColumns={snapshotTableVisibleFields}
        metadataTitle={title}
        displayLayerLabel={displayLayer}
        users={users}
        metrics={metrics}
        hasAdvancedMetricReporting={hasAdvancedMetricReporting}
        openMetricView={openMetricView}
        updateById={onUpdateMetadataFieldById}
        isGoalMode={isGoalMode}
        withPagination={withPagination}
        createMetricOption={createMetricOption}
        isGroupOpenByDefault={isItemExpandedByDefault}
        searchString={searchString}
      />
    </ProjectsListLightboxProvider>
  );
};

export default OKRTableOnSnapshot;
