import { useCallback, useMemo } from 'react';
import { equals, pipe, prop, defaultTo } from 'ramda';

import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { applyCheckIsEditable, isRowDraggable } from 'design-system/molecules/AgGridReact-New/helpers';
import { getCustomFieldColumnDef, defaultColumnDefCommonProps } from 'design-system/molecules/AgGridReact-New/columns';

import { processColumnsGroup as processColumnsGroupUtil, processGroomedColumnDefs } from 'utils/grids/helpers';
import { PERMISSION_RESOURCES, READ_ONLY_USER } from '@dragonboat/permissions';

import { checkOpenItemColumnVisibility } from '../../helpers';

import {
  applyCheckIsCreatingOrViewingScenario,
  applyCheckUserCanUpdateRow,
  checkAddColumnVisibility,
  checkIsEditable,
  makeCellClassForCreatingOrViewingScenario,
  makeCellStyleForCreatingOrViewingScenario,
} from './helpers';

import useNumberOfTextRowsToDisplayOnGridCell from 'hooks/useNumberOfTextRowsToDisplayOnGridCell';
import usePermissions from 'hooks/permissions/usePermissions';
import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import { GROUP_COLUMN_INITIAL_WIDTH } from 'constants/grid';

import { TitleCellInnerRenderer } from './renderers';
import useCommonFieldsColumnsDefinitions from './useCommonFieldsColumnsDefinitions';
import useScoringFieldsColumnsDefinitions from './useScoringFieldsColumnsDefinitions';
import useAdditionalFieldsColumnsDefinitions from './useAdditionalFieldsColumnsDefinitions';
import useEstimationFieldsColumnsDefinitions from './useEstimationFieldsColumnsDefinitions';
import useAllocationFieldsColumnsDefinitions from './useAllocationFieldsColumnsDefinitions';
import usePreCalculatedFieldsColumnsDefinitions from './usePreCalculatedFieldsColumnsDefinitions';

const defaultToEmptyObject = defaultTo({});
const defaultToEmptyArray = defaultTo([]);

const GROUP_COLUMN_ID = 'ag-Grid-AutoColumn';

const isReadOnlyUser = pipe(defaultToEmptyObject, prop('role_id'), equals(READ_ONLY_USER));

const getChildLayer = parentLayer => (parentLayer === BET_LAYER ? INITIATIVE_LAYER : IDEA_LAYER);

const getCellClass = params => {
  /* When we have projects grouped under a Without <layer> there is no data on the node since they are dynamic.
   * As they are also a group the drag icon is not visible which dis-aligns the titles compared to other initiative/bets grouped
   * We need to add a spacing to compensate the lack of the drag icon
   */
  const isWithoutDynamicGroup = !params?.data && params?.node?.group;

  return `ag-react-editable-container--expandable ag-title-cell ${isWithoutDynamicGroup ? 'allocate-drag-icon-space' : ''}`;
};
/**
 * Hook to hold the columns definitions to be passed to the Grid component.
 */
const useIdeasGridColumnsDefinitions = ({
  portfolioMode,
  hasHierarchy,
  hasKeyResults,
  hasKeyResults2,
  hasProducts,
  hasProducts2,
  hasMultiLevelMetadata,
  hasTeams2,
  topLayer,
  displayLayer,
  rowHeight,
  visibleColumnsIds,
  titleSearchString,
  customUserFields,
  currentUser,
  integrations,
  customFields,
  columnsState,
  hasMetadataMultiSelect,
  isBulkDeleting,

  getSystemFieldName,
  addNewInlineProject,
  openProjectInfo,
  openRowInfo,
  loadParentProjectsOptions,
  checkUserCanCreateNewMetadata,
  updateProjectById,
  handleMetadataCellDoubleClick,
  createMetadataMultiSelectOption,
  removeGridRow,
  checkAddChildrenButtonVisibility = checkAddColumnVisibility,
}) => {
  const { canView, canDelete, canUpdate, canCreate } = usePermissions();
  const isReadOnly = isReadOnlyUser(currentUser);

  const { getNumberOfTextRowsToDisplayOnGridCell } = useNumberOfTextRowsToDisplayOnGridCell(rowHeight);

  const processColumnsGroup = useCallback(
    columns => processColumnsGroupUtil(columns, visibleColumnsIds, columnsState),
    [visibleColumnsIds, columnsState],
  );

  const groupColumnState = useMemo(() => columnsState && columnsState.find(c => c.colId === GROUP_COLUMN_ID), [columnsState]);

  const groupColumnDef = useMemo(
    () => ({
      field: 'title',
      headerName: 'Title',
      suppressMovable: true,
      comparator: stringComparator,
      headerClass: 'first-field',
      cellClass: getCellClass,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: params => ({
        innerRenderer: TitleCellInnerRenderer,
        suppressCount: true,
        suppressDoubleClickExpand: true,
        portfolioMode,
        titleSearchString,
        getNumberOfTextRowsToDisplayOnGridCell,
        addButtonProps: {
          addChildVisible:
            canCreate(PERMISSION_RESOURCES.project, { project: { layer: getChildLayer(params?.data?.layer) } }) &&
            checkAddChildrenButtonVisibility(params),
          onClick: () => addNewInlineProject(params),
        },
        openButtonProps: {
          hasOpenButton: checkOpenItemColumnVisibility(params),
          onClick: () => openRowInfo(params),
        },
      }),
      rowDrag: isRowDraggable,
      initialHide: false,
      pinned: 'left',
      lockPinned: true,
      lockPosition: 'left',
      width: groupColumnState?.width || GROUP_COLUMN_INITIAL_WIDTH,
      headerCheckboxSelection: () => isBulkDeleting,
      checkboxSelection: () => isBulkDeleting,
    }),
    [
      portfolioMode,
      titleSearchString,
      getNumberOfTextRowsToDisplayOnGridCell,
      addNewInlineProject,
      openRowInfo,
      groupColumnState,
      isBulkDeleting,
    ],
  );

  const customColumnDefs = useMemo(
    () =>
      defaultToEmptyArray(customFields).map(cf =>
        getCustomFieldColumnDef({
          ...cf,
          fieldType: cf.field_type,
          dataEnhanced: cf.data_enhanced,
          getNumberOfTextRowsToDisplayOnGridCell,
          handleMetadataCellDoubleClick,
        }),
      ),
    [customFields],
  );

  const commonFieldsColumnDefs = useCommonFieldsColumnsDefinitions({
    hasHierarchy,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    topLayer,
    customUserFields,
    hasMetadataMultiSelect,

    canView,
    canUpdate,
    getSystemFieldName,
    openProjectInfo,
    loadParentProjectsOptions,
    checkUserCanCreateNewMetadata,
    updateProjectById,
    handleMetadataCellDoubleClick,
    getNumberOfTextRowsToDisplayOnGridCell,
  });

  const scoringFieldsDefs = useScoringFieldsColumnsDefinitions({ getSystemFieldName });

  const additionalFieldsColumnDefs = useAdditionalFieldsColumnsDefinitions({
    getSystemFieldName,
    integrations,
    isReadOnly,
    checkUserCanCreateNewMetadata,
    handleMetadataCellDoubleClick,
    createMetadataMultiSelectOption,
    canView,
    canUpdate,
    canDelete,
    removeGridRow,
    rowHeight,
    updateProjectById,
  });

  const estimationFieldsDefs = useEstimationFieldsColumnsDefinitions({ displayLayer });

  const allocationFieldsDefs = useAllocationFieldsColumnsDefinitions({ displayLayer });

  const preCalculatedFieldsColumnsDefs = usePreCalculatedFieldsColumnsDefinitions();

  const columnDefs = useMemo(
    () =>
      processGroomedColumnDefs(
        [
          // Other columns
          ...processColumnsGroup(commonFieldsColumnDefs),
          ...processColumnsGroup(scoringFieldsDefs),
          ...processColumnsGroup(additionalFieldsColumnDefs),
          ...processColumnsGroup(customColumnDefs),
          ...processColumnsGroup(estimationFieldsDefs),
          ...processColumnsGroup(allocationFieldsDefs),
          ...processColumnsGroup(preCalculatedFieldsColumnsDefs),
        ],
        columnsState,
      ),
    [
      processColumnsGroup,
      commonFieldsColumnDefs,
      scoringFieldsDefs,
      additionalFieldsColumnDefs,
      customColumnDefs,
      estimationFieldsDefs,
      allocationFieldsDefs,
      columnsState,
    ],
  );

  const defaultColDef = useMemo(
    () => ({
      ...defaultColumnDefCommonProps,
      editable: applyCheckUserCanUpdateRow(
        canUpdate,
        applyCheckIsCreatingOrViewingScenario(applyCheckIsEditable(checkIsEditable)),
      ),
      cellStyle: makeCellStyleForCreatingOrViewingScenario(),
      headerClass: makeCellClassForCreatingOrViewingScenario(),
      cellClass: makeCellClassForCreatingOrViewingScenario(),
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: false,
      initialHide: true,
    }),
    [],
  );

  return {
    columnDefs,
    defaultColDef,
    groupColumnDef,
  };
};

export default useIdeasGridColumnsDefinitions;
