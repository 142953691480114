import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';

import history from 'store/utils/history';
import LabelledButton from 'design-system/atoms/LabelledButton/index';
import PageLink from 'design-system/atoms/PageLink/index';

import { getOrgJiraIntegrations } from 'store/organization/selectors';

import { TAB_DETAILS, TAB_DEPENDENCIES, TAB_COMMENTS, TAB_SHARE, TAB_HISTORY } from 'constants/projectLightbox';
import DeleteAndConfirm from './DeleteAndConfirm';

import { getMappingByJiraTicketId } from 'utils/mapping';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

const JiraButton = ({ showJiraSaveButton, jiraIntegration, hasJiraMapping, hasdbMapping, onSave, onClose }) => {
  return (
    <>
      {showJiraSaveButton && (
        <LabelledButton
          name="save"
          color="primary"
          // disabled={this.state.waitForApi === true}
          label={
            <PageLink href={`/settings/integrations/jira/${jiraIntegration.id}`} target="_blank">
              see mapping
            </PageLink>
          }
          onClick={() => onSave(true)}
        >
          save and update jira
        </LabelledButton>
      )}
      {!hasJiraMapping && !hasdbMapping && (
        <Button
          name="save"
          color="primary"
          onClick={() => {
            onClose();
            history.push(`/settings/integrations/jira/${jiraIntegration.id}`);
          }}
        >
          Set up Jira field sync here
        </Button>
      )}
    </>
  );
};

const SaveButton = ({
  showJiraActions,
  showJiraSaveButton,
  hasJiraMapping,
  hasdbMapping,
  jiraIntegration,
  webhookEnabled,
  userCanEdit,
  isNew,
  onSave,
  onClose,
}) => {
  return (
    <>
      {showJiraActions && userCanEdit && (
        <JiraButton
          showJiraSaveButton={showJiraSaveButton}
          jiraIntegration={jiraIntegration}
          hasJiraMapping={hasJiraMapping}
          hasdbMapping={hasdbMapping}
          onSave={onSave}
          onClose={onClose}
        />
      )}
      {!(showJiraActions && !webhookEnabled) && (
        <StyledButton name="save" color="primary" onClick={() => onSave()}>
          {isNew && userCanEdit ? 'SAVE' : 'CLOSE'}
        </StyledButton>
      )}
    </>
  );
};

export default ({
  onSave,
  currentTab,
  isNew,
  selectedProject,
  userCanEdit,
  onClose,
  visible,
  onDeleteProject,
  onArchiveProject,
  selectedRoadmapVersion,
}) => {
  const permissions = usePermissions();
  const jiraIntegrations = useSelector(getOrgJiraIntegrations);

  if (!visible) return '';

  const jiraIntegration =
    selectedProject && !!selectedProject.jira && jiraIntegrations.find(i => i.id === selectedProject.jira.orgIntegration_id);
  const hasJiraIntegration = !!jiraIntegration;
  const jiraConfig = jiraIntegration && jiraIntegration.data;

  const mapping = getMappingByJiraTicketId(jiraConfig, selectedProject?.jira?.key, selectedProject?.layer);
  const hasJiraMapping = !!mapping.length && mapping.some(c => !!c.jiraAutoSync);
  const hasdbMapping = !!mapping.length && mapping.some(c => !!c.dbAutoSync);

  const hasSingleWebhookTurnedOn = !!jiraConfig?.webhook;
  const hasMultipleWebhookTurnedOn = !!(Array.isArray(jiraConfig?.webhooks) && jiraConfig?.webhooks.length);

  const webhookEnabled = hasSingleWebhookTurnedOn || hasMultipleWebhookTurnedOn;

  const showJiraActions = hasJiraIntegration && [TAB_DETAILS].includes(currentTab);
  const showJiraSaveButton = (hasdbMapping && !webhookEnabled) || (webhookEnabled && [TAB_DEPENDENCIES].includes(currentTab));
  const showDeleteAction =
    ![TAB_COMMENTS, TAB_SHARE, TAB_HISTORY, TAB_DEPENDENCIES].includes(currentTab) &&
    permissions.canDelete(PERMISSION_RESOURCES.project, { project: selectedProject });

  return (
    <Grid container>
      <Grid item xs={3}>
        {!isNew && !selectedRoadmapVersion && showDeleteAction && (
          <DeleteAndConfirm selectedProject={selectedProject} onDelete={onDeleteProject} onArchive={onArchiveProject} />
        )}
      </Grid>
      <Grid item xs={9}>
        <SaveCancelActions row>
          <SaveButton
            showJiraActions={showJiraActions}
            showJiraSaveButton={showJiraSaveButton}
            hasJiraMapping={hasJiraMapping}
            hasdbMapping={hasdbMapping}
            jiraIntegration={jiraIntegration}
            webhookEnabled={webhookEnabled}
            userCanEdit={userCanEdit}
            isNew={isNew}
            onSave={onSave}
            onClose={onClose}
          />
        </SaveCancelActions>
      </Grid>
    </Grid>
  );
};

const SaveCancelActions = styled(FormGroup)`
  &&&& {
    justify-content: flex-end;
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    margin-right: 60px;
  }
`;
