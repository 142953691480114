import { useCallback } from 'react';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { defaultTo, either, equals, isNil } from 'ramda';

import { focusOnCell, isDraggable } from '../helpers';
import useRowDrag from './useRowDrag';

const DEFAULT_FOCUS_FIELD = 'title';
const defaultToEmptyArray = defaultTo([]);
// A null id or key on the grid is a string 'null'
const isNull = either(isNil, equals('null'));

const useCommonGridEventHandlers = ({
  items,
  focusField = DEFAULT_FOCUS_FIELD,
  idKey,
  actions: { update, save, remove, drag, sortChanged },
  forceUpdate = false,
  onCreateNewItemError,
  onUpdateItemError,
}) => {
  // Cell

  const onCellEditingStopped = useCallback(event => {
    if (isNull(event.data.id) && !event.data[focusField]) {
      return remove();
    }
  }, []);

  const onCellValueChanged = useCallback(
    async cell => {
      const {
        data,
        node,
        colDef: { field, overrideCellValueChanged, valueSetter },
        newValue: cellNewValue,
        oldValue,
        api,
      } = cell;

      let newValue = cellNewValue;

      /*
       * IMPORTANT:
       * If update row is a group will look into the row data to get the newValue
       *
       * Its because we have aggValue on groups and the newValue and oldValue that
       * comes from aggrid cell agg value and not the updated value
       *
       * On this situation the verification above of nothing changed should be done on
       * valueSetter of corresponding column def otherwise will use the default
       * verification
       *
       * If allLeafChildren is more than zero it means the row is parent project
       */
      const currentNodeHasChildren = defaultToEmptyArray(node?.allLeafChildren).length > 0;
      const isGroupOrIsParentProjectRow = data.group || currentNodeHasChildren;

      if (isGroupOrIsParentProjectRow && isFunction(valueSetter)) {
        newValue = data[field];
      }

      if (overrideCellValueChanged || oldValue === newValue) {
        return;
      }

      const { id } = data;
      const idIsNull = isNull(id);

      // create new item if it has a value on focus field and does not have an id
      if (idIsNull && field === focusField && data[focusField]) {
        return save(cell.data).catch(error => {
          if (onCreateNewItemError) {
            onCreateNewItemError({ error, newValue });
          }

          remove();
        });
      }

      if (idIsNull && field === focusField) {
        // if user did not type a value on focus field then remove row from grid
        return remove();
      }

      if (oldValue === get(data, field) || (idIsNull && field !== focusField)) {
        // if cell value did not change or a cell different from value of
        // focus field in a new row is being updated then do nothing
        return;
      }

      const result = update(
        id,
        {
          [field]: data[field] !== undefined && data[field] !== null ? data[field] : newValue,
          type: data.type,
        },
        oldValue,
        cell,
      );

      if (result) {
        result
          // applyTransaction forces the group change when the cell value was changed
          .then(() => forceUpdate && api.applyTransaction({ update: [data] }))
          .catch(error => {
            try {
              cell.node.setData({
                ...cell.node.data,
                [field]: oldValue,
              });

              if (onUpdateItemError) {
                onUpdateItemError({ error, newValue });
              }
            } catch {
              // pass
            }
          });
      }
    },
    [update],
  );

  // Row

  const onRowDataUpdated = useCallback(
    ({ api, columnApi }) => {
      if (focusField) {
        focusOnCell({ api, columnApi }, focusField);
      }
    },
    [focusField],
  );

  // Drag

  const updateDragVisibility = useCallback(({ api, columnApi }) => {
    const shouldBeVisible = isDraggable(api, columnApi, drag);

    api.setSuppressRowDrag(!shouldBeVisible);
  }, []);

  const onSortChanged = useCallback(
    params => {
      if (sortChanged) {
        sortChanged(params);
      }

      updateDragVisibility(params);
    },
    [updateDragVisibility, sortChanged],
  );

  const { onRowDragEnter, onRowDragMove, onRowDragEnd, onRowDragLeave } = useRowDrag({ items, dragAction: drag, idKey });

  return {
    onCellEditingStopped,
    onCellValueChanged,
    onRowDataUpdated,
    onColumnRowGroupChanged: updateDragVisibility,
    onSortChanged,
    onFilterChanged: updateDragVisibility,
    onRowDragEnter,
    onRowDragMove,
    onRowDragEnd,
    onRowDragLeave,
  };
};

export default useCommonGridEventHandlers;
