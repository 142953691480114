import React from 'react';
import styled from 'styled-components';
import Input from '@material-ui/core/Input';

import PageLink from 'design-system/atoms/PageLink/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';

import { isEscape } from 'design-system/utils/keyboard';
import theme, { spacing } from 'design-system/theme';

export default function DataSourceUrlInput({ cancel, disableEdit, handleInputChange, integrationData, onChange, value }) {
  return (
    <TooltipOnOverflow
      text={<BreakWord>{value}</BreakWord>}
      elementWithOverflow={integrationData?.id ? 'a' : 'input'}
      containerWidth={value.length}
      disableFocusListener
    >
      {integrationData?.id ? (
        <StyledPageLink href={value} ellipsis target="_blank" rel="noopener noreferrer">
          {value}
        </StyledPageLink>
      ) : (
        <StyledInput
          disableUnderline
          type="text"
          value={value}
          onChange={e => handleInputChange(e.target.value, onChange)}
          readOnly={disableEdit || integrationData?.id}
          placeholder="URL"
          onKeyDown={e => {
            e.stopPropagation();
            if (isEscape(e)) cancel();
          }}
        />
      )}
    </TooltipOnOverflow>
  );
}

const StyledPageLink = styled(PageLink)`
  padding: ${spacing()}px 0;
  font-size: ${theme.typography.fontSizeSmallLarge}px;
  max-width: 100%;
`;

const StyledInput = styled(Input)`
  flex: 1;

  input {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const BreakWord = styled.div`
  word-break: break-word;
`;
