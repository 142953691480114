import styled from 'styled-components';

const TableHead = styled.div`
  &&&& {
    border-bottom: 3px solid;
    border-image: linear-gradient(0deg, #0e275200 0%, #0e27522c 100%) 30;
    ${({ sticky }) =>
      sticky &&
      `
      position: sticky;
      z-index: 101;
    `}

    ${({ left }) =>
      left &&
      `
      left: 0;
    `}

        ${({ top }) =>
      top &&
      `
      top: 0;
    `}
  }
`;

TableHead.displayName = 'TableHead';

export default TableHead;
