import React from 'react';
import MuiIconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';

/**
 * Material design search bar
 * @see [Search patterns](https://material.io/archive/guidelines/patterns/search.html)
 */
export default React.forwardRef(
  (
    { className, cancelOnEscape, closeIcon, disabled, onCancelSearch, onRequestSearch, searchIcon, style, ...inputProps },
    ref,
  ) => {
    const inputRef = React.useRef();
    const [value, setValue] = React.useState(inputProps.value);

    React.useEffect(() => {
      setValue(inputProps.value);
    }, [inputProps.value]);

    const handleFocus = React.useCallback(
      e => {
        if (inputProps.onFocus) {
          inputProps.onFocus(e);
        }
      },
      [inputProps.onFocus],
    );

    const handleBlur = React.useCallback(
      e => {
        setValue(v => v.trim());
        if (inputProps.onBlur) {
          inputProps.onBlur(e);
        }
      },
      [inputProps.onBlur],
    );

    const handleInput = React.useCallback(
      e => {
        setValue(e.target.value);
        if (inputProps.onChange) {
          inputProps.onChange(e.target.value);
        }
      },
      [inputProps.onChange],
    );

    const handleCancel = React.useCallback(() => {
      setValue('');
      if (onCancelSearch) {
        onCancelSearch();
      }
    }, [onCancelSearch]);

    const handleRequestSearch = React.useCallback(() => {
      if (onRequestSearch) {
        onRequestSearch(value);
      }
    }, [onRequestSearch, value]);

    const handleKeyUp = React.useCallback(
      e => {
        if (e.charCode === 13 || e.key === 'Enter') {
          handleRequestSearch();
        } else if (cancelOnEscape && (e.charCode === 27 || e.key === 'Escape')) {
          handleCancel();
        }
        if (inputProps.onKeyUp) {
          inputProps.onKeyUp(e);
        }
      },
      [handleRequestSearch, cancelOnEscape, handleCancel, inputProps.onKeyUp],
    );

    React.useEffect(() => {
      inputRef.current.focus();
    }, [!ref]);

    React.useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current.focus();
      },
      blur: () => {
        inputRef.current.blur();
      },
    }));

    return (
      <Wrapper style={style} className={className}>
        <InputContainer>
          <Input
            {...inputProps}
            inputRef={inputRef}
            onBlur={handleBlur}
            value={value}
            onChange={handleInput}
            onKeyUp={handleKeyUp}
            onFocus={handleFocus}
            fullWidth
            disableUnderline
            disabled={disabled}
          />
        </InputContainer>
        <IconContainer>
          <IconButton hidden={value !== ''} onClick={handleRequestSearch} disabled={disabled}>
            <SearchIcon />
          </IconButton>
          <IconButton hidden={value === ''} onClick={handleCancel} disabled={disabled}>
            <ClearIcon />
          </IconButton>
        </IconContainer>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  &&&& {
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    border-radius: 25px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  margin: auto 16px;

  input {
    font-size: 14px;
    padding: 0;
  }
`;

const IconContainer = styled.div`
  button {
    opacity: 0.54;
    transform: scale(1, 1);
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
    padding: 6px;

    svg {
      transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
      width: 18px;
      height: 18px;
    }
  }
`;

const IconButton = styled(MuiIconButton)`
  &&&& {
    ${({ hidden }) =>
      hidden &&
      `
      transform: scale(0, 0);
      display: none;
    `}
  }
`;
