import { useMemo } from 'react';

import { generateTimelineData, getTimelineOrders } from '../helpers';

import useTimelineLocalState from './useTimelineLocalState';

/**
 * Custom hook responsible to enrich the received tree data with position and other visual information of the items.
 *
 * It also stores the resulting structure in the internal state to avoid the back and forth issue.
 *
 * */
const useTimelineConverter = options => {
  // Create the structure with the visual information and the orders used in the drag and drop operation
  const { timelineData, timelineOrders } = useMemo(() => {
    const timelineData = generateTimelineData(options);

    const { isGroupOpen, hideEmptyLane, groupsCount } = options;

    const timelineOrders = getTimelineOrders(timelineData, isGroupOpen, hideEmptyLane, groupsCount);

    return {
      timelineData,
      timelineOrders,
    };
  }, [options]);

  // Internal state hook manager
  const { internalData, internalOrders, updateItemAfterDrag, updateItemAfterResize } = useTimelineLocalState({
    timelineData,
    timelineOrders,
    ...options,
  });

  return {
    internalData,
    internalOrders,
    updateItemAfterDrag,
    updateItemAfterResize,
  };
};

export default useTimelineConverter;
