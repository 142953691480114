import isEmpty from 'lodash/isEmpty';

import { IDEA_LAYER } from 'store/projects/constants';

import { MILESTONE_TYPE } from 'utils/swimlane/new/constants';
import { updateProjectWithGroupData } from './updateProjectWithGroupData';

/**
 * Creates the open light box for a new item handler.
 *
 * @param {String} displayLayer The display layer selected
 * @param {Array} groupsData The list of metadata objects for the selected groups
 *  @param {Array} selectedGroupOptions The selected group options
 * @param {Function} openProjectLightbox The open lightbox action
 * */
const makeOpenNewProjectLightbox =
  ({ displayLayer, groupsData, selectedGroupOptions, openProjectLightbox }) =>
  (groupIds = null, isMilestone = false) => {
    const payload = {
      layer: isMilestone ? IDEA_LAYER : displayLayer,
    };

    if (isMilestone) {
      payload.type = MILESTONE_TYPE;
    }

    if (!isEmpty(groupIds)) {
      groupIds.forEach((groupId, index) => {
        if (groupId !== null) {
          updateProjectWithGroupData(payload, selectedGroupOptions[index], groupsData[index], groupId);
        }
      });
    }

    openProjectLightbox(null, null, payload);
  };

export { makeOpenNewProjectLightbox };
