import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import TextDeprecated from 'design-system/atoms/TextDeprecated';
import calculateLinesNumber from 'design-system/utils/calculateAgGridLineNumbers';

import TextContent from './TextContent';

const Title = ({ params, wrapText, rowHeight, textStyles, icon, showChildrenCount, showTooltip }) => {
  const { node, value } = params;

  const isLeaf = isEmpty(node?.childrenAfterGroup);

  return (
    <TextWithIconWrapper level={node.level} isLeaf={isLeaf}>
      {icon}
      <TextWrapper linesNumber={calculateLinesNumber(rowHeight)} wrapText={wrapText}>
        <TextDeprecated textStyles={textStyles}>
          <TextContent showTooltip={showTooltip} text={value} />
          {showChildrenCount && <ChildrenCount>({node.allChildrenCount || 0})</ChildrenCount>}
        </TextDeprecated>
      </TextWrapper>
    </TextWithIconWrapper>
  );
};

export default Title;

const TextWithIconWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  ${({ isLeaf, level }) => (isLeaf && level !== 0 ? `padding-left: 25px` : '')};
`;

const TextWrapper = styled.div`
  width: 100%;
  height: calc(1.5em * ${({ linesNumber }) => linesNumber});
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: ${({ linesNumber }) => linesNumber}; // line height * number of lines
  -webkit-box-orient: vertical;

  p {
    height: 100%;
    white-space: break-spaces !important;
    overflow: visible !important;
    /* width: calc(100% - 14px) !important; */
  }
`;

const ChildrenCount = styled.span`
  margin-left: 5px;
  font-size: 12px;
  color: #b1b1b1;
`;
