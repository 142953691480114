// External dependencies
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { EditorState, RichUtils } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import axios from 'axios';

import { getUserName } from 'utils';
import createdByText from 'utils/userViews/createdByText';
import 'draft-js-mention-plugin/lib/plugin.css';
// Dragonboat dependencies
import { getUserById } from 'store/users/selectors';

import Text from 'design-system/atoms/Text/index';
import RadioButton from 'design-system/atoms/RadioButton/index';

import theme, { spacing } from 'design-system/theme';
import useCreateMentionPlugin from 'hooks/useCreateMentionPlugin';
import useHasIntegrationsOfType from 'hooks/integrations/useHasIntegrationsOfType';
import ShareViaIntegrationButton from 'features/Integrations/ShareViaIntegrationButton/ShareViaIntegrationButton';

import ShareUrlText from '../ShareUrlText';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { INTEGRATIONS_KEYS } from 'constants/integrations';
import SendViewToIntegrationChannel from './SendViewToIntegrationChannel';

const SHARE = 'Share';

const _MentionComponent = props => {
  const { mention, theme, ...parentProps } = props;

  return (
    <div {...parentProps}>
      <div className={theme.mentionSuggestionsEntryContainer}>
        <div className={theme.mentionSuggestionsEntryContainerRight}>
          <div className={theme.mentionSuggestionsEntryText}>{mention.name}</div>
        </div>
      </div>
    </div>
  );
};

const ShareWithUsers = ({ show, onClose, shareUrl, view, shareOption, setShareOption, editorState, setEditorState }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [editorRef, setEditorRef] = useState(null);
  const [showTooltip, setShowTooltip] = useState(true);
  const [isSlackExpanded, setIsSlackExpanded] = useState(false);
  const [isMicrosoftTeamsExpanded, setIsMicrosoftTeamsExpanded] = useState(false);

  const viewOwner = useSelector(state => getUserById(state, view?.user_id));

  const { plugins, MentionSuggestions } = useCreateMentionPlugin();

  const hasSlackIntegration = useHasIntegrationsOfType(INTEGRATIONS_KEYS.slack);
  const hasMicrosoftTeamsIntegration = useHasIntegrationsOfType(INTEGRATIONS_KEYS.microsoftTeams);

  const dialogRef = React.useRef(null);

  useEffect(() => {
    setEditorState(EditorState.createEmpty());
  }, [editorRef, show]);

  const _closeDialog = () => {
    if (isSlackExpanded) setIsSlackExpanded(false);
    if (isMicrosoftTeamsExpanded) setIsMicrosoftTeamsExpanded(false);
    onClose();
  };

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const _toggleSlackExpand = () => {
    if (isMicrosoftTeamsExpanded) setIsMicrosoftTeamsExpanded(false);
    setIsSlackExpanded(prevValue => !prevValue);
  };

  const _toggleMicrosoftTeamsExpand = () => {
    if (isSlackExpanded) setIsSlackExpanded(false);
    setIsMicrosoftTeamsExpanded(prevValue => !prevValue);
  };

  const _renderEditor = () => (
    <>
      <EditorContainer
        onClick={() => editorRef.focus()}
        top={dialogRef.current && dialogRef.current.offsetParent.offsetTop}
        left={dialogRef.current && dialogRef.current.offsetParent.offsetLeft}
      >
        <Editor
          editorState={editorState}
          onChange={state => {
            setEditorState(state);
            const plainText = editorState.getCurrentContent().getPlainText();

            setShowTooltip(!plainText);
          }}
          handleKeyCommand={handleKeyCommand}
          plugins={plugins}
          ref={r => setEditorRef(r)}
        />
        <MentionSuggestions
          onSearchChange={({ value }) => {
            const params = {
              search: value,
              from: 0,
              to: 10,
            };

            axios.get('/api/users', { params }).then(({ data: resData }) => {
              const usersData = resData.data.map(user => ({
                ...user,
                name: getUserName(user),
              }));

              setSuggestions(usersData);
            });
          }}
          suggestions={suggestions}
          entryComponent={_MentionComponent}
        />
        {showTooltip && (
          <HelpText onClick={() => editorRef.focus()}>
            @mention users. This view will be automatically add to their view list
          </HelpText>
        )}
      </EditorContainer>
    </>
  );

  return (
    <DialogWrapper ref={dialogRef}>
      <Container>
        <Text>{`${SHARE} `}</Text>
        <ViewText maxWidth={300} withEllipsis>
          {view?.name}
        </ViewText>
        <StyledCreator>&nbsp;{createdByText(viewOwner?.name)}</StyledCreator>
      </Container>
      <StyledFormControl component="fieldset">
        <RadioGroup aria-label="Gender" name="gender1" value={shareOption} onChange={event => setShareOption(event.target.value)}>
          <FormControlLabel
            value="organization"
            control={<RadioButton color={theme.palette.primary.main} />}
            label="My organization"
          />
          <FormControlLabel
            value="mentioned"
            control={<RadioButton color={theme.palette.primary.main} />}
            label="Only these mentioned"
          />
        </RadioGroup>
      </StyledFormControl>

      {shareOption === 'mentioned' && _renderEditor()}

      <FlexContainer>
        <FlexWrapper>
          <ShareContainer>
            <ShareUrlText shareUrl={shareUrl} />
            {hasSlackIntegration ? (
              <ShareViaIntegrationButton
                integrationType={INTEGRATIONS_KEYS.slack}
                onClick={_toggleSlackExpand}
                active={isSlackExpanded}
              />
            ) : null}
            {hasMicrosoftTeamsIntegration ? (
              <ShareViaIntegrationButton
                integrationType={INTEGRATIONS_KEYS.microsoftTeams}
                onClick={_toggleMicrosoftTeamsExpand}
                active={isMicrosoftTeamsExpanded}
              />
            ) : null}
          </ShareContainer>
        </FlexWrapper>
        {isSlackExpanded ? (
          <SendViewToIntegrationChannel
            integrationType={INTEGRATIONS_KEYS.slack}
            handleCloseDialog={_closeDialog}
            viewUrl={shareUrl}
            view={view}
          />
        ) : null}
        {isMicrosoftTeamsExpanded ? (
          <SendViewToIntegrationChannel
            integrationType={INTEGRATIONS_KEYS.microsoftTeams}
            handleCloseDialog={_closeDialog}
            viewUrl={shareUrl}
            view={view}
          />
        ) : null}
      </FlexContainer>
    </DialogWrapper>
  );
};

const DialogWrapper = styled.div``;

const StyledFormControl = styled(FormControl)`
  && {
    margin-top: ${spacing(0.5)}px;
  }

  > div {
    flex-direction: row;
    gap: ${spacing(4)}px;
    // Transform needed to offset the padding on the radio buttons
    transform: translateX(-12px);
  }

  label {
    margin: 0;
  }

  span {
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  }
`;

const ShareContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: ${spacing(0.5)}px;
  gap: ${spacing(9.75)}px;
`;

const FlexContainer = styled.div``;

const EditorContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  border: 1px solid #eee;
  cursor: text;
  padding: ${spacing(2)}px;
  border-radius: 2px;
  background: #fefefe;
  min-height: 100px;
  margin-top: ${spacing(2)}px;

  &&& :global(.public-DraftEditor-content) {
    min-height: 100px;
  }

  div[role='listbox'] {
    position: fixed;
    transform: translate(${props => props.left}px, ${props => props.top}px) !important;
  }
`;

const HelpText = styled(Text)`
  &&&& {
    font-size: ${theme.typography.fontSizeSmallRem}rem;
    color: #ccc;
    position: absolute;
    top: 18px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${spacing(0.5)}px;
  // Transform needed to offset the padding on the buttons
  transform: translateX(-${spacing()}px);
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ViewText = styled(Text)`
  padding-left: ${spacing(0.5)}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

const StyledCreator = styled(Text)`
  color: ${({ theme }) => theme.palette.newLayout.text.grey};
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
  line-height: ${({ theme }) => theme.typography.lineHeightSmallLargeRem}rem;
`;

export default ShareWithUsers;
