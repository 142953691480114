import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Editor from 'draft-js-plugins-editor';
import styled from 'styled-components';
import { getUserName } from 'utils';
import { spacing } from 'design-system/theme';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import UserMention from 'design-system/atoms/UserMention/index';


import 'draft-js-mention-plugin/lib/plugin.css';

import useCreateMentionPlugin from 'hooks/useCreateMentionPlugin';
import useMentionEditorState from './hooks/useMentionEditorState';
import useSystemFields from 'hooks/useSystemFields';

const UserMentionTextArea = ({ storedUsers, onUsersToShareChanged, smallInput = false, ...props }) => {
  const editor = useRef(null);
  const editorContainer = useRef(null);
  const [users, setUsers] = useState([]);
  const [showHelpText, setShowHelpText] = useState(false);

  const [editorState, onEditorContentChange, handleKeyCommand] = useMentionEditorState(storedUsers, onUsersToShareChanged);
  const { plugins, MentionSuggestions } = useCreateMentionPlugin();

  const [getSystemFieldName] = useSystemFields();

  useEffect(() => {
    const hasAnyText = editorState.getCurrentContent().hasText();

    setShowHelpText(!hasAnyText);
  }, [editorState]);

  const onSearchUsers = ({ value }) => {
    const params = {
      search: value,
      from: 0,
      to: 10,
    };

    // TODO this should be dispatched / fetched from store
    axios.get('/api/users', { params }).then(({ data: resData }) => {
      const usersData = resData.data.map(user => ({
        ...user,
        name: getUserName(user),
      }));

      setUsers(usersData);
    });
  };

  const focus = () => {
    editor.current.focus();
  };

  return (
    <div>
      <EditorContainer
        ref={el => {
          editorContainer.current = el;
        }}
        onClick={focus}
        top={editorContainer.current && editorContainer.current.getBoundingClientRect().top}
        left={editorContainer.current && editorContainer.current.getBoundingClientRect().left}
        smallInput={smallInput}
        {...props}
      >
        <Editor
          editorState={editorState}
          onChange={onEditorContentChange}
          handleKeyCommand={handleKeyCommand}
          plugins={plugins}
          ref={element => {
            editor.current = element;
          }}
        />
        <MentionSuggestions onSearchChange={onSearchUsers} suggestions={users} entryComponent={UserMention} />
        {showHelpText && (
          <HelpText onClick={() => editor.current.focus()}>
            {`@mention users to share this ${getSystemFieldName('scenario')} with`}
          </HelpText>
        )}
      </EditorContainer>
    </div>
  );
};

export default UserMentionTextArea;

const EditorContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  cursor: text;
  padding: ${spacing(3)}px;
  background: #fefefe;
  min-height: ${({ smallInput }) => (smallInput ? '55px' : '100px')};
  margin-top: ${spacing(2)}px;
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  max-height: 110px;
  overflow-y: auto;
  border: ${({ theme }) => `1px solid ${theme.palette.border.primary}`};
  border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;

  &&& :global(.public-DraftEditor-content) {
    min-height: 100px;
  }

  div[role='listbox'] {
    position: fixed;
    transform: translate(${props => props.left + 5}px, ${props => props.top + 5}px) !important;
  }
`;

const HelpText = styled(TextDeprecated)`
  &&&& {
    position: absolute;
    top: ${spacing(3)}px;
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    color: ${({ theme }) => theme.palette.text.disabled};
  }
`;
