import { NEW_ROW_ID } from 'design-system/molecules/AgGridReact-New/helpers';

const UNDEFINED_GROUP = {
  id: 'undefined',
  name: 'Undefined',
  path: ['undefined'],
};

const NO_GROUP_OPTION = { key: null, title: 'No group' };

const GROUP_BY_OPTIONS = [
  NO_GROUP_OPTION,
  { key: 'status', title: 'Status' },
  { key: 'segment', title: 'Segment', meta: { isCustomField: true } },
];

const getDataPath = data => data.path || [data.id];

const checkIsGroupRow =
  isGrouping =>
  ({ data }) => {
    const isGroupRow = isGrouping && data.path.length === 1;

    return !isGroupRow;
  };

const makeCheckIsEditable = checkIsGroupRow;

const makeCheckDeleteVisibility = checkIsGroupRow;

const makeCheckCheckboxSelection = checkIsGroupRow;

const buildHierarchyByFieldValue = (customers, accessor) => {
  const groupOptions = {};

  let hasUndefined = false;

  const mappedCustomers = customers.map(customer => {
    const customerFieldValue = accessor(customer);

    if (!customerFieldValue) {
      hasUndefined = true;
    } else {
      groupOptions[customerFieldValue] = {
        id: customerFieldValue,
        name: customerFieldValue,
        path: [customerFieldValue],
      };
    }

    return {
      ...customer,
      path: [customerFieldValue || UNDEFINED_GROUP.id, customer.id || NEW_ROW_ID],
    };
  });

  return [...mappedCustomers, ...Object.values(groupOptions), ...(hasUndefined ? [UNDEFINED_GROUP] : [])];
};

export {
  UNDEFINED_GROUP,
  NO_GROUP_OPTION,
  GROUP_BY_OPTIONS,
  getDataPath,
  makeCheckIsEditable,
  makeCheckDeleteVisibility,
  makeCheckCheckboxSelection,
  buildHierarchyByFieldValue,
};
