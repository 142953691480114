import { useDispatch } from 'react-redux';

import { updateLayoutConfig } from 'features/MetricsCanvas/store';
import { BOARD, HIGHLIGHT_HEALTH, METRICS, OKRS, OKRS_METRICS, OWNER_AVATAR, PROGRESS, STATUS } from 'features/Canvas/constants';

const useGoalsCanvasHandleConfigChanges = ({ canvasLayoutConfig }) => {
  const dispatch = useDispatch();
  const { relationsConfig, displayPreferences } = canvasLayoutConfig;

  const makeToggleRelations = key => () =>
    dispatch(
      updateLayoutConfig({
        ...canvasLayoutConfig,
        relationsConfig: {
          ...relationsConfig,
          [key]: !relationsConfig?.[key],
        },
      }),
    );

  const makeTogglePreferences = key => () =>
    dispatch(
      updateLayoutConfig({
        ...canvasLayoutConfig,
        displayPreferences: {
          ...displayPreferences,
          [key]: !displayPreferences?.[key],
        },
      }),
    );

  const handleDisplayNodesChange = values =>
    dispatch(
      updateLayoutConfig({
        ...canvasLayoutConfig,
        displayNodes: values,
      }),
    );

  return {
    onToggleOkrRelations: makeToggleRelations(OKRS),
    onToggleBoardRelations: makeToggleRelations(BOARD),
    onToggleMetricRelations: makeToggleRelations(METRICS),
    onToggleOkrMetricsRelations: makeToggleRelations(OKRS_METRICS),
    onToggleHighlightHealth: makeTogglePreferences(HIGHLIGHT_HEALTH),
    onToggleOwnerAvatar: makeTogglePreferences(OWNER_AVATAR),
    onToggleProgress: makeTogglePreferences(PROGRESS),
    onToggleStatus: makeTogglePreferences(STATUS),
    handleDisplayNodesChange,
  };
};

export default useGoalsCanvasHandleConfigChanges;
