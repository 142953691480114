import JiraIcon from 'design-system/atoms/JiraIcon';
import ClubHouseIcon from 'design-system/atoms/ClubHouseIcon';
import GithubIcon from 'design-system/atoms/GithubIcon';
import AsanaIcon from 'design-system/atoms/AsanaIcon';
import AzureDevopsIcon from 'design-system/atoms/AzureDevopsIcon';
import ZendeskIcon from 'design-system/atoms/ZendeskIcon';
import MiroIcon from 'src/design-system/atoms/MiroIcon';
import RallyIcon from 'src/design-system/atoms/RallyIcon';
import MicrosoftTeamsIcon from 'design-system/atoms/MicrosoftTeamsIcon';
import SlackIcon from 'design-system/atoms/SlackIcon';

const ISSUE_COUNT_PROGRESS = 'issueCount';
const STORY_POINTS_PROGRESS = 'storyPoints';
const TIME_ESTIMATES_PROGRESS = 'timeEstimates';

const PROJECT_INTEGRATION_STATUSES = {
  OPEN: '0',
  IN_PROGRESS: '1',
  DONE: '2',
};

const INTEGRATIONS_NAMES = {
  JIRA: 'Jira',
  clubhouse: 'Shortcut',
  github: 'Github',
  azuredevops: 'Azure Devops',
  asana: 'Asana',
  dragonboat: 'Dragonboat',
  salesforce: 'Salesforce',
  zendesk: 'Zendesk',
  rally: 'Rally',
  microsoftTeams: 'Microsoft Teams',
  slack: 'Slack',
};

const INTEGRATIONS_KEYS = {
  JIRA: 'JIRA',
  clubhouse: 'clubhouse',
  github: 'github',
  azuredevops: 'azuredevops',
  asana: 'asana',
  miro: 'miro',
  dragonboat: 'dragonboat',
  salesforce: 'salesforce',
  dodV2: 'dodV2',
  zendesk: 'zendesk',
  rally: 'rally',
  microsoftTeams: 'microsoftTeams',
  slack: 'slack',
  tableau: 'tableau',
  userVoice: 'userVoice',
  pendo: 'pendo',
  amplitude: 'amplitude',
};

const METRIC_INTEGRATION_TYPES = [INTEGRATIONS_KEYS.tableau, INTEGRATIONS_KEYS.pendo, INTEGRATIONS_KEYS.amplitude];

/**
 * Integration types that are already under Integration Gateway and use
 * generic endpoints to manage OrgIntegrations and UserIntegrations.
 */
const INTEGRATION_GATEWAY_INTEGRATION_TYPES = [
  INTEGRATIONS_KEYS.asana,
  INTEGRATIONS_KEYS.azuredevops,
  INTEGRATIONS_KEYS.miro,
  INTEGRATIONS_KEYS.salesforce,
  INTEGRATIONS_KEYS.zendesk,
  INTEGRATIONS_KEYS.rally,
  INTEGRATIONS_KEYS.microsoftTeams,
];

const INTEGRATIONS_ICON_MAPPER = {
  JIRA: JiraIcon,
  github: GithubIcon,
  clubhouse: ClubHouseIcon,
  asana: AsanaIcon,
  azuredevops: AzureDevopsIcon,
  miro: MiroIcon,
  zendesk: ZendeskIcon,
  [INTEGRATIONS_KEYS.rally]: RallyIcon,
  [INTEGRATIONS_KEYS.microsoftTeams]: MicrosoftTeamsIcon,
  [INTEGRATIONS_KEYS.slack]: SlackIcon,
};

const MAPPING_TYPES = {
  PROJECTS: 'projects',
  REQUESTS: 'requests',
};

const DEFAULT_MAPPING_KEY = 'DEFAULT';

const FEATURE_FLAGS_BY_INTEGRATION = {
  miro: 'has_miro',
};

const NON_AGILE_INTEGRATIONS = [
  'azure',
  'okta',
  'slack',
  'forwardEmails',
  'miro',
  'microsoftTeams',
  INTEGRATIONS_KEYS.salesforce,
  INTEGRATIONS_KEYS.zendesk,
];

const COLLABORATION_TOOL_TYPE_INTEGRATIONS = [INTEGRATIONS_KEYS.slack, INTEGRATIONS_KEYS.microsoftTeams];

const CUSTOMER_REQUEST_INTEGRATIONS = [INTEGRATIONS_KEYS.zendesk];

const INTEGRATIONS_DOCS_LINKS = {
  [INTEGRATIONS_KEYS.azuredevops]: {
    MAPPING_FIELDS_FIELDS_SYNC: 'https://dragonboat.zendesk.com/hc/en-us/articles/360025615233-Jira-Mapping-Fields-Field-Sync',
    DATA_STRUCTURE: 'https://dragonboat.zendesk.com/hc/en-us/articles/360013269194-Dragonboat-Overview-Data-Structure',
  },
  [INTEGRATIONS_KEYS.rally]: {
    MAPPING_FIELDS_FIELDS_SYNC: 'https://dragonboat.zendesk.com/hc/en-us/articles/360025615233-Jira-Mapping-Fields-Field-Sync',
    DATA_STRUCTURE: 'https://dragonboat.zendesk.com/hc/en-us/articles/360013269194-Dragonboat-Overview-Data-Structure',
    CREATE_OR_LINK_EPICS: 'https://dragonboat.zendesk.com/hc/en-us/articles/15684176368151-Rally-Software-Integration',
  },
  [INTEGRATIONS_KEYS.JIRA]: {
    CREATE_OR_LINK_EPICS: 'https://dragonboat.zendesk.com/hc/en-us/articles/360041222674',
  },
};

const FIELDS_MAPPING_TYPES_FOR_IMPORT = {
  SAVED_MAPPING: 'saved-mapping',
  CUSTOM_MAPPING: 'custom-mapping',
};

const JIRA_PROJECT_RENAMED_WARNING = 'JIRA_PROJECT_RENAMED_WARNING';

const USER_INTEGRATIONS_TYPES = {
  [INTEGRATIONS_KEYS.rally]: 14,
  [INTEGRATIONS_KEYS.microsoftTeams]: 15,
};

const USER_INTEGRATIONS_ICON_MAPPER = {};

const DOD_LOGIN_POPOP_WINDOW_NAME = 'Dragonboat_Authentication';

// Removing 'asana', 'azuredevops' since they have webhook updates
const INTEGRATIONS_UPDATE_IDEA_FROM_INTEGRATION_OBJECT = ['clubhouse', 'miro'];

const IMPORT_LIGHTBOX_VARIANTS = {
  import: 'import',
  recurringImport: 'recurringImport',
};

const WEBHOOKS_PROJECT_RESTRICTIONS_TYPES = {
  ALL: 'All',
  INCLUDE: 'Include',
};

const INTEGRATION_AUTH_TYPES = {
  token: 'token',
  pat: 'pat',
  oauth2: 'oauth2',
  oauth: 'oauth',
};

const FIELD_MAPPING_DIRECTION_OPTIONS = {
  inbound: 'inbound',
  outbound: 'outbound',
};

const IMPORT_JQL_PREVIEW_MESSAGE = 'Preview includes filter results. Only new items will be imported.';

export {
  IMPORT_LIGHTBOX_VARIANTS,
  INTEGRATIONS_UPDATE_IDEA_FROM_INTEGRATION_OBJECT,
  DOD_LOGIN_POPOP_WINDOW_NAME,
  USER_INTEGRATIONS_ICON_MAPPER,
  USER_INTEGRATIONS_TYPES,
  NON_AGILE_INTEGRATIONS,
  FEATURE_FLAGS_BY_INTEGRATION,
  INTEGRATIONS_DOCS_LINKS,
  INTEGRATIONS_ICON_MAPPER,
  INTEGRATIONS_KEYS,
  INTEGRATIONS_NAMES,
  COLLABORATION_TOOL_TYPE_INTEGRATIONS,
  PROJECT_INTEGRATION_STATUSES,
  MAPPING_TYPES,
  DEFAULT_MAPPING_KEY,
  INTEGRATION_GATEWAY_INTEGRATION_TYPES,
  ISSUE_COUNT_PROGRESS,
  STORY_POINTS_PROGRESS,
  TIME_ESTIMATES_PROGRESS,
  JIRA_PROJECT_RENAMED_WARNING,
  FIELDS_MAPPING_TYPES_FOR_IMPORT,
  WEBHOOKS_PROJECT_RESTRICTIONS_TYPES,
  CUSTOMER_REQUEST_INTEGRATIONS,
  INTEGRATION_AUTH_TYPES,
  METRIC_INTEGRATION_TYPES,
  FIELD_MAPPING_DIRECTION_OPTIONS,
  IMPORT_JQL_PREVIEW_MESSAGE,
};
