import moment from 'moment-timezone';
import { defaultTo } from 'ramda';

import UserTypeMapper from 'utils/UserTypeMapper';
import { getJiraAccountId } from './getJiraAccountId';

const defaultToEmptyObject = defaultTo({});

const exportToCsv = ({ gridApi }) => {
  gridApi.exportDataAsCsv({
    fileName: `users-${moment().format()}`,
    columnKeys: [
      'first_name',
      'last_name',
      'email',
      'team.title',
      'skill.title',
      'capacity',
      'role_id',
      'status',
      'jira_reference',
      'updated_at',
    ],

    processCellCallback: params => {
      if (params?.column?.colId) {
        switch (params.column.colId) {
          case 'role_id': {
            const role = UserTypeMapper.find(role => role.value === params.value);

            if (role) {
              return role.label;
            }

            return null;
          }
          case 'jira_reference': {
            const user = defaultToEmptyObject(params.node.data);
            const jiraReference = getJiraAccountId(user);

            return jiraReference;
          }
          default:
            return params.value;
        }
      }

      return params.value;
    },
  });
};

export default exportToCsv;
