import { useState } from 'react';
import pick from 'lodash/pick';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { selectMetricLevels, updateMetricLevels as updateMetricLevelsAction } from 'features/MetricLevels/store';

const FIELDS = ['title', 'rank', 'color'];

const useMetricLevelsLightbox = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const metricLevels = useSelector(selectMetricLevels);

  const { canUpdate } = usePermissions();

  const canUpdateMetricLevels = canUpdate(PERMISSION_FEATURES.metricLevels);

  const updateMetricLevels = levels => dispatch(updateMetricLevelsAction(levels));

  const [deboundedUdateMetricLevels] = useDebouncedCallback(updateMetricLevels, 500);

  const handleMetricLevelsChange = value => {
    const updatedMetricLevels = Object.entries(value).reduce((acc, [key, val]) => {
      return {
        ...acc,
        [key]: pick(val, FIELDS),
      };
    }, {});

    if (!isEqual(metricLevels, updatedMetricLevels)) {
      deboundedUdateMetricLevels(updatedMetricLevels);
    }
  };

  return {
    isOpen,
    openMetricLevelsLightbox: () => setIsOpen(true),
    closeMetricLevelsLightbox: () => setIsOpen(false),
    metricLevels,
    canUpdateMetricLevels,
    handleMetricLevelsChange,
  };
};

export default useMetricLevelsLightbox;
