import {
  PLAN_OF_RECORD_COMMITTED_ID,
  PLAN_OF_RECORD_ID,
  PLAN_OF_RECORD_TITLE,
  PLAN_OF_RECORD_TITLE_COMMITTED,
} from 'constants/common';

const createLabelsMap = roadmapVersions => {
  const labelsMap = {};

  roadmapVersions.forEach(version => {
    if (version) {
      labelsMap[version.id] = version.name;
    }
  });

  labelsMap[PLAN_OF_RECORD_ID] = PLAN_OF_RECORD_TITLE;
  labelsMap[PLAN_OF_RECORD_COMMITTED_ID] = PLAN_OF_RECORD_TITLE_COMMITTED;

  return labelsMap;
};

export default createLabelsMap;
