import { useEffect, useRef } from 'react';

import useRoadmapVersions from 'hooks/useRoadmapVersions';
// todo: probablu move this to a move generic place
// eslint-disable-next-line max-len
import useSelectCompareScenariosBaseVersion from '../components/HeaderVersionsSelection/hooks/useSelectCompareScenariosBaseVersion';
import { selectedScenarioVersion } from 'constants/queryParams';
import { COMPARE_SCENARIOS_PLAN_OF_RECORD_LIST } from 'constants/common';

const usePreSelectedVersions = () => {
  const preSelectedVersion = useRef();
  const { roadmapVersions } = useRoadmapVersions();
  const { setBaseVersion } = useSelectCompareScenariosBaseVersion();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const selectedVersion = params.get(selectedScenarioVersion) ?? undefined;

    const isSameVersion = preSelectedVersion.current === selectedVersion;

    if (!roadmapVersions || preSelectedVersion.current || isSameVersion) {
      return;
    }

    const validPreSelection = [...COMPARE_SCENARIOS_PLAN_OF_RECORD_LIST, ...roadmapVersions].find(
      version => String(version.id) === selectedVersion,
    );

    preSelectedVersion.current = validPreSelection?.id;

    setBaseVersion(validPreSelection);
  }, [roadmapVersions, preSelectedVersion.current]);
};

export default usePreSelectedVersions;
