import { css } from 'styled-components';
import formatDateTime from 'utils/dates/formatDateTime';
import { TextCellRenderer } from '../cellRenderers';

const updatedColumnDef = {
  field: 'updated_at',
  headerName: 'Updated',
  suppressMovable: true,
  editable: false,
  valueFormatter: ({ updated_at: updatedAt } = {}) => formatDateTime(updatedAt),
  cellRenderer: TextCellRenderer,
  cellRendererParams: {
    textStyles: css`
      color: ${({ theme }) => theme.palette.text.disabled};
      font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    `,
  },
};

export default updatedColumnDef;
