import React from 'react';
import styled, { css } from 'styled-components';

import { BaseTitle } from '../TitleCellRenderer';

const TitleLinkCellRenderer = ({
  node,
  value,
  icon = '',
  bold = false,
  fontSize = null,
  textColor = null,
  getNumberOfTextRowsToDisplayOnGridCell,
  addButtonProps,
  openButtonProps,
  titleIsClickable,
  onTitleClick,
}) => {
  const nodeIsNotTopLevel = !!node.level;

  const nodeDoesNotHaveChildren = !node.allChildrenCount;

  const textValue = (
    <Link onClick={() => onTitleClick(node.data)} isClickable={titleIsClickable}>
      {value}
    </Link>
  );

  const leafShouldHaveIndent = nodeIsNotTopLevel;
  const compensateNoCollapse = nodeDoesNotHaveChildren;
  const needsIndentation = compensateNoCollapse || leafShouldHaveIndent;

  return (
    <BaseTitle
      icon={icon}
      bold={bold}
      fontSize={fontSize}
      textColor={textColor}
      indent={needsIndentation}
      getNumberOfTextRowsToDisplayOnGridCell={getNumberOfTextRowsToDisplayOnGridCell}
      addButtonProps={addButtonProps}
      openButtonProps={openButtonProps}
    >
      {textValue}
    </BaseTitle>
  );
};

const Link = styled.span`
  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    `}
`;

export default TitleLinkCellRenderer;
