import { css } from 'styled-components';
import { TextCellRenderer } from '../cellRenderers';

const updatedByColumnDef = {
  field: 'updatedBy',
  headerName: 'Updated By',
  width: 100,
  minWidth: 100,
  cellRenderer: TextCellRenderer,
  cellRendererParams: {
    textStyles: css`
      color: ${({ theme }) => theme.palette.text.disabled};
      font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    `,
  },
};

export default updatedByColumnDef;
