import statusValues from '../constants/status';

const getStatuses = () => {
  const statuses = Object.entries(statusValues)
    .map(([key, { label, color, order }]) => ({ value: key, label, color, order }))
    .sort((a, b) => a.order - b.order);

  return statuses;
};

export default getStatuses;
