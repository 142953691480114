import React from 'react';
import styled from 'styled-components';
import theme, { spacing } from 'design-system/theme';
import OutlinedPurpleButton from 'design-system/molecules/OutlinedPurpleButton/index';

import { SALES_BUTTON } from 'constants/discoveryBot';

const SALES_BUTTON_STYLES = { width: '140px', backgroundColor: `${theme.palette.white}` };

const ContactSalesButton = ({ selectedOption, isBookADemoOpen, setIsBookADemoOpen }) => {
  const handleClickSalesButton = () => {
    if (isBookADemoOpen) {
      return;
    }
    
    window.pendo.onGuideDismissed();
    setIsBookADemoOpen(true);

    /* global Calendly */
    setTimeout(() =>
      Calendly.initInlineWidget({
        url: SALES_BUTTON?.href,
        parentElement: document.getElementById('calendlyDemoWidget'),
        prefill: {},
        utm: {},
      }),
    );
  };

  return (
    <ButtonContainer selectedOption={selectedOption}>
      <OutlinedPurpleButton
        id="talk_to_sales"
        buttonText={SALES_BUTTON.label}
        extraStyles={SALES_BUTTON_STYLES}
        onClick={handleClickSalesButton}
      />
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  position: ${({ selectedOption }) => selectedOption && 'absolute'};
  left: ${({ selectedOption }) => selectedOption && '215px'};
  margin-top: ${({ selectedOption }) => (selectedOption ? '0' : `${spacing(4)}px`)};
`;

export default ContactSalesButton;
