import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { both, pipe, isEmpty, not, path, equals } from 'ramda';
import { FeatureFlags } from '@dragonboat/config';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

import { enableADOWebhooks, restoreOrgIntegrationData } from 'store/organization';
import { getOrganization } from 'store/organization/selectors';
import { getCurrentUser } from 'store/login/selectors';
import { authenticateWithOAuth2 } from 'store/integrations';

import useIntegrations from 'hooks/useIntegrations';
import useUserIntegrations from 'hooks/useUserIntegrations';
import useAppNotifications from 'hooks/useAppNotifications';
import useFeatureFlags from 'hooks/useFeatureFlags';

const AUTH_TYPE_OAUTH2 = 'oauth2';
const AUTH_TYPE_PAT = 'pat';
const DATA = 'data';
const TYPE = 'type';

import CreateOrgIntegration from './CreateOrgIntegration';
import CreateUserIntegration from './CreateUserIntegration';

const hasNotWebhooksCreated = pipe(path(['data', 'webhooks']), both(Array.isArray, pipe(isEmpty, not)), not);
const isNotOauth2Type = pipe(path([DATA, TYPE]), equals(AUTH_TYPE_OAUTH2), not);

const IntegrationSteps = props => {
  const { orgIntegration, userIntegration, addingOrgIntegration, onAddOrgIntegration } = props;

  const dispatch = useDispatch();

  const organization = useSelector(getOrganization);
  const currentUser = useSelector(state => getCurrentUser(state));

  const { addOrgIntegration } = useIntegrations(INTEGRATIONS_KEYS.azuredevops);
  const { addUserIntegrationError, addUserIntegration, fetchUserIntegrations } = useUserIntegrations(
    INTEGRATIONS_KEYS.azuredevops,
    orgIntegration?.id,
  );
  const { addNotification } = useAppNotifications();
  const hasAzureDevopsOAuth2 = useFeatureFlags([FeatureFlags.HAS_AZURE_DEVOPS_OAUTH2]);

  const shouldShowOrganizationIntegrationStep = useMemo(
    () => !orgIntegration && addingOrgIntegration,
    [orgIntegration, addingOrgIntegration],
  );

  const shouldShowUserIntegrationStep = useMemo(
    () => orgIntegration && !userIntegration && isNotOauth2Type(orgIntegration),
    [orgIntegration, userIntegration],
  );

  useEffect(() => {
    // TODO: Extract this to useUserIntegrations, can make success steps a separate method for both user integration types
    const handleOauth2UserIntegration = async () => {
      if (!orgIntegration) return;
      await authenticateWithOAuth2('azuredevops', orgIntegration.id).then(() => {
        fetchUserIntegrations();
        addNotification({ id: 'ado-successfully-integrated', message: 'Successfully integrated' });
      });

      if (hasNotWebhooksCreated(orgIntegration)) {
        await dispatch(enableADOWebhooks(organization.id, currentUser.id, orgIntegration?.id)).then(() => {
          addNotification({ id: 'ado-webhooks-enabled-successfully', message: 'Webhooks enabled successfully' });
        });
      }

      if (orgIntegration.id && orgIntegration.needs_restore) {
        dispatch(restoreOrgIntegrationData(orgIntegration.id, { type: AUTH_TYPE_OAUTH2 }));
      }
    };

    if (shouldShowUserIntegrationStep) return;

    handleOauth2UserIntegration();
  }, [shouldShowUserIntegrationStep, orgIntegration?.id, organization, currentUser.id]);

  const handleCreateOrganization = useCallback(
    async uri => {
      const authType = hasAzureDevopsOAuth2 ? AUTH_TYPE_OAUTH2 : AUTH_TYPE_PAT;

      const result = await addOrgIntegration({ uri, type: authType });

      onAddOrgIntegration(result.id);
    },
    [addOrgIntegration],
  );

  const handleAddUserIntegration = useCallback(
    async token => {
      await addUserIntegration(token).then(() => {
        addNotification({ id: 'ado-successfully-integrated', message: 'Successfully integrated' });
      });

      if (hasNotWebhooksCreated(orgIntegration)) {
        await dispatch(enableADOWebhooks(organization.id, currentUser.id, orgIntegration?.id)).then(() => {
          addNotification({ id: 'ado-webhooks-enabled-successfully', message: 'Webhooks enabled successfully' });
        });
      }

      if (orgIntegration.id && orgIntegration.needs_restore) {
        dispatch(restoreOrgIntegrationData(orgIntegration.id, { type: AUTH_TYPE_PAT }));
      }
    },
    [addUserIntegration, organization.id, currentUser.id, orgIntegration],
  );

  return (
    <React.Fragment>
      {shouldShowOrganizationIntegrationStep && <CreateOrgIntegration onClickNext={handleCreateOrganization} />}
      {shouldShowUserIntegrationStep && (
        <CreateUserIntegration
          addUserIntegrationError={addUserIntegrationError}
          uri={orgIntegration.data.uri}
          onClickConnect={handleAddUserIntegration}
        />
      )}
    </React.Fragment>
  );
};

export default IntegrationSteps;
