import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentUser } from 'store/login/selectors';

import CycleDeliverableLightbox from '../components/CycleDeliverableLightbox';

const CycleDeliverableLightboxContext = createContext({});

const CycleDeliverableLightboxProvider = ({ children }) => {
  const [selectedCycleDeliverableId, setSelectedCycleDeliverableId] = useState();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (selectedCycleDeliverableId) setIsOpen(true);
  }, [setIsOpen, selectedCycleDeliverableId]);

  const currentUser = useSelector(getCurrentUser);

  const openCycleDeliverableLightbox = useCallback(
    data => {
      setSelectedCycleDeliverableId(data);
      setIsOpen(true);
    },
    [setIsOpen, setSelectedCycleDeliverableId],
  );
  const closeCycleDeliverableLightbox = useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <CycleDeliverableLightboxContext.Provider value={{ openCycleDeliverableLightbox }}>
      {children}
      <CycleDeliverableLightbox
        currentUser={currentUser}
        isOpen={isOpen}
        onClose={closeCycleDeliverableLightbox}
        selectedCycleDeliverableId={selectedCycleDeliverableId}
      />
    </CycleDeliverableLightboxContext.Provider>
  );
};

/**
 * @function useCycleDeliverableLightboxContext
 *
 * hook to use the cycle deliverable lightbox context
 * needs to be wrapped on CycleDeliverableLightboxProvider
 *
 * @return {Object}
 */
const useCycleDeliverableLightboxContext = () => useContext(CycleDeliverableLightboxContext);

export { CycleDeliverableLightboxProvider };

export default useCycleDeliverableLightboxContext;
