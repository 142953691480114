import React from 'react';

import HomepageViewItem from './components/HomepageViewItem';
import DashboardViewItem from './components/DashboardViewItem';
import WidgetListViewItem from './components/WidgetListViewItem';
import WidgetTileViewItem from './components/WidgetTileViewItem';
import { VIEW_ITEM_VARIANTS } from 'constants/userViews';

export default ({
  handleFavoriteUserView,
  handleActionClick,
  getActionIcon,
  isFavorite,
  isLast,
  item,
  onSelectView,
  variant,
  showUserViewCounts,
  totalViewsUnit,
}) => {
  const isHomepageVariant = variant === VIEW_ITEM_VARIANTS.homepage;
  const isWidgetListVariant = variant === VIEW_ITEM_VARIANTS.widgetList;
  const isWidgetTileVariant = variant === VIEW_ITEM_VARIANTS.widgetTile;

  if (isHomepageVariant) {
    return (
      <HomepageViewItem
        item={item}
        handleFavoriteUserView={handleFavoriteUserView}
        handleActionClick={handleActionClick}
        getActionIcon={getActionIcon}
        onSelectView={onSelectView}
        isFavorite={isFavorite}
        showUserViewCounts={showUserViewCounts}
        totalViewsUnit={totalViewsUnit}
      />
    );
  }

  if (isWidgetListVariant) {
    return (
      <WidgetListViewItem
        item={item}
        handleFavoriteUserView={handleFavoriteUserView}
        handleActionClick={handleActionClick}
        getActionIcon={getActionIcon}
        onSelectView={onSelectView}
        isFavorite={isFavorite}
        isLast={isLast}
      />
    );
  }

  if (isWidgetTileVariant) {
    return <WidgetTileViewItem item={item} onSelectView={onSelectView} />;
  }

  return (
    <DashboardViewItem
      item={item}
      handleFavoriteUserView={handleFavoriteUserView}
      handleActionClick={handleActionClick}
      getActionIcon={getActionIcon}
      onSelectView={onSelectView}
      isFavorite={isFavorite}
      isLast={isLast}
    />
  );
};
