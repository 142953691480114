import React from 'react';
import { prop, equals, pipe, defaultTo } from 'ramda';

import Actions from './Actions';
import { TextWrapper, Wrapper } from './styled';
import { CYCLE_DELIVERABLE_LEVEL } from 'constants/common';

const LEVEL = 'level';

const defaultToEmptyObject = defaultTo({});

const isLevel2 = pipe(prop(LEVEL), equals(CYCLE_DELIVERABLE_LEVEL.deliverable));
const isChild = pipe(defaultToEmptyObject, isLevel2);

const GroupHeaderInnerRenderer = params => {
  const { data, value, onTitleClick } = params;

  if (!value) {
    return null;
  }
  // todo: use TitleWithActions once PROD-8433 is merged. changes there are needed
  return (
    <Wrapper $indent={isChild(data)}>
      <TextWrapper onClick={onTitleClick}>{params.value}</TextWrapper>
      <Actions {...params} />
    </Wrapper>
  );
};

export default GroupHeaderInnerRenderer;
