import React from 'react';

import MicroChip from 'design-system/atoms/MicroChip/index';


const CapacityCellRenderer = props => {
  const { value: capacity, node } = props;

  if (node && node.group) {
    return null;
  }

  if (!capacity || capacity === 'false') {
    return (
      <MicroChip
        label="No"
        color="#616161"
        style={{
          width: '40px',
          margin: 0,
          height: '80%',
        }}
      />
    );
  }

  return (
    <MicroChip
      label="Yes"
      color="#1291bd"
      fullWidth
      style={{
        margin: 0,
        height: '80%',
      }}
    />
  );
};

export default CapacityCellRenderer;
