import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  text: {
    margin: theme.spacing.unit,
    lineHeight: 1.5,
  },
});

export default withStyles(styles)(({ classes, children }) => (
  <div>
    <h3 className={classes.text}>{ children }</h3>
  </div>
));
