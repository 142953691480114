const { useDispatch } = require('react-redux');
const { loadEstimatesTab: loadEstimatesTabActionCreator } = require('store/projectLightbox/thunks');

const useLoadEstimatesTab = () => {
  const dispatch = useDispatch();

  const loadEstimatesTab = () => dispatch(loadEstimatesTabActionCreator());

  return { loadEstimatesTab };
};

export default useLoadEstimatesTab;
