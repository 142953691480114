import parseArrayIntoObject from './parseArrayIntoObject';

const transformClipboardData = (data, selectedColumn, selectedRowData, columns, rowData) => {
  if (!data || !(data instanceof Array) || !data.length || !selectedColumn || !selectedRowData || !columns) {
    return;
  }

  if (!selectedRowData.id) {
    const transformedData = data.map(row => parseArrayIntoObject(row, columns, selectedColumn));

    return {
      create: transformedData,
      rawData: data,
    };
  }

  const selectedRowIndex = rowData.map(row => row.id).indexOf(selectedRowData.id);

  if (selectedRowIndex >= 0) {
    const transformedData = data
      .map((row, index) => {
        const resource = rowData[selectedRowIndex + index];

        const update = parseArrayIntoObject(row, columns, selectedColumn);

        if (!update || !Object.keys(update).length) {
          // if there is nothing to update or create
          return null;
        }

        if (resource && resource.id) {
          return {
            id: resource.id,
            ...update,
          };
        }

        return update;
      })
      .filter(row => row !== null);

    return transformedData.reduce(
      (final, row) => {
        if (!row.id) {
          final.create.push(row);
        } else {
          final.update.push(row);
        }

        final.rawData = data;

        return final;
      },
      { update: [], create: [], rawData: data },
    );
  }
};

export default transformClipboardData;
