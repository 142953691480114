import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil, not } from 'ramda';
import { getDiscoveryBotThreadId, getIsCreateBotThreadLoading } from 'store/discoveryBot/selectors';
import { createDiscoveryBotThread, processUserChatMessage, storeDiscoveryBotConversationHistory } from 'store/discoveryBot';
import { DISCOVERY_BOT_HISTORY_CACHE_STORAGE_KEY } from 'store/discoveryBot/constants';

const useDiscoveryBot = () => {
  const dispatch = useDispatch();

  const threadId = useSelector(state => getDiscoveryBotThreadId(state));
  const isLoadingCreateThread = useSelector(state => getIsCreateBotThreadLoading(state));


  const threadNotInitialized = useMemo(() => isNil(threadId) && not(isLoadingCreateThread), [threadId, isLoadingCreateThread]);

  const createNewDiscoveryBotThread = useCallback(() => {
    dispatch(createDiscoveryBotThread());
  }, [dispatch]);

  const storeDiscoveryBotMessageHistory = useCallback(
    messages => {
      dispatch(storeDiscoveryBotConversationHistory(messages));
    },
  );

  const processUserMessage = useCallback(
    async message => {
      if (threadNotInitialized) {
        return;
      }

      const { value } = await dispatch(
        processUserChatMessage({
          threadId,
          text: message,
        }),
      );

      const promptResponse = {
        message: value.content[0].text.value,
        options: {
          withAvatar: false,
          payload: value,
        },
      };

      return promptResponse;
    },
    [dispatch, threadId, threadNotInitialized],
  );

  const loadDiscoveryBotMessageHistory = () => {
    const messages = localStorage.getItem(DISCOVERY_BOT_HISTORY_CACHE_STORAGE_KEY);

    return JSON.parse(messages);
  };

  return {
    threadId,
    threadNotInitialized,
    createNewDiscoveryBotThread,
    processUserMessage,
    loadDiscoveryBotMessageHistory,
    storeDiscoveryBotMessageHistory,
  };
};

export default useDiscoveryBot;
