export default (dependencyId, dependencyKey, dependencyIdKey = 'id') => {
  return project => {
    const dependencyIndex = project
      .get(dependencyKey, [])
      .findIndex(dependency => dependency[dependencyIdKey] === dependencyId || dependency.get(dependencyIdKey) === dependencyId);

    if (dependencyIndex >= 0) {
      return project.set(dependencyKey, project.get(dependencyKey).delete(dependencyIndex));
    }

    return project;
  };
};
