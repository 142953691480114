/**
 * BurnDownChart Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

import LineChart from 'components/ReactChart/LineChart';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import Segment from 'design-system/atoms/Segment/index';

import TimelineLightbox from 'features/TimelineLightbox';
import { DASHBOARDS_PAGE } from 'constants/filters';

export default ({
  data,
  title,
  loading,
  onChartClick,
  registerOpenTimelineCallback,
  timelineLightboxGroupBy,
  timelineLightboxFilters,
  timelineLightboxPortfolioMode,
  timelineLightboxDisplayLayer,
}) => {
  const options = {
    onClick: onChartClick,
    plugins: {
      title: {
        display: true,
        fontSize: 14,
      },
      legend: {
        onClick: (event, legendItem) => {},
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: event => {
            const { dataset, raw } = event;

            return `${dataset.label}: ${raw}${dataset.format}`;
          },
        },
      },
      annotation: {
        annotations: data.annotations || {},
      },
    },
    scales: {
      y: {
        suggestedMax: 101,
        ticks: {
          stepSize: 10,
          callback: value => {
            if (value > 100) {
              // Hide labels after allowed max
              return '';
            }

            return `${value}%`;
          },
        },
      },
      x: {
        type: 'time',
        time: {
          unit: 'week',
          displayFormats: {
            day: 'MMM D',
          },
          // round: 'day',
          tooltipFormat: 'll',
        },
        scaleLabel: {
          display: true,
          labelString: 'Date',
        },
      },
    },
  };

  return (
    <WidgetWrapper>
      {loading && (
        <LoadingOverlay>
          <Progress />
        </LoadingOverlay>
      )}
      <Chart bordered label={title}>
        {!data.datasets.length ? (
          <TextDeprecated style={{ textAlign: 'center' }}>
            {!loading && 'No data available for the selected period'}
          </TextDeprecated>
        ) : (
          <LineChart data={data} options={options} />
        )}
        <TimelineLightbox
          onOpen={registerOpenTimelineCallback}
          groupedBy={{}}
          lazyLoadProjects
          pageId={DASHBOARDS_PAGE}
          groupBySelection={timelineLightboxGroupBy}
          filtersForApi={timelineLightboxFilters}
          portfolioMode={timelineLightboxPortfolioMode}
          displayLayer={timelineLightboxDisplayLayer}
        />
      </Chart>
    </WidgetWrapper>
  );
};

const WidgetWrapper = styled.div`
  height: 100%;
`;

const Chart = styled(Segment)`
  position: relative;
  background: ${({ theme }) => theme.palette.white};
`;

const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #ffffffb5;
`;

const Progress = styled(CircularProgress)`
  &&&& {
    position: absolute;
    left: 50%;
    top: 50%;
  }
`;
