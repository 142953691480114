import theme, { spacing } from 'design-system/theme';

export default (getTooltipTitle, getTooltipLabel) => ({
  callbacks: {
    title: getTooltipTitle,
    label: getTooltipLabel,
  },
  padding: spacing(),
  backgroundColor: theme.palette.text.primary,
  titleColor: theme.palette.white,
  bodyColor: theme.palette.white,
  titleFont: {
    size: theme.typography.fontSizeH4,
  },
  bodyFont: {
    size: theme.typography.fontSizeSmall,
  },
  titleMarginBottom: 0,
  displayColors: false,
});
