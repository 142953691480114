import { defaultTo, isNil, not, pipe, prop } from 'ramda';

const defaultToEmptyObject = defaultTo({});
const hasId = pipe(prop('id'), isNil, not);
const hasParentId = pipe(prop('parent_id'), isNil, not);

/**
 * Transforms a given item in a dropdown item.
 *
 * @param {Object} item
 * @param {Number} item.id
 * @param {String} item.label
 * @param {String} item.title
 * @returns {Object}
 */
export const buildDropdownItemBody = item => ({ id: item.id, label: item.label || item.title });

/**
 * Excludes all the items with parent_id not equals to null/undefined.
 * Only root items will be returned.
 *
 * @param {Array} items
 * @returns {Array}
 */
export const excludeChildren = items => items.filter(item => item.parent_id == null).map(buildDropdownItemBody);

/**
 * Unflatten an array of items based on parent_id property.
 * The children will be added to the property children of type array.
 *
 * @param {Array} items
 * @param {Number} id
 * @returns {Array}
 */
export const unflattenItems = (items, id = null) =>
  items
    // Using 'hasId' as safeguard to prevent the 'Maximum call stack' error that was happening on some edge cases
    .filter(item => hasId(item) && item.parent_id === id)
    .map(item => ({ ...buildDropdownItemBody(item), children: unflattenItems(items, item.id) }));

/**
 * Parse an array of selected items to key-value format in order to have compatibility with DndList component.
 *
 * @param {Array} selectedItems
 * @returns {Object}
 */
export const parseMultiSelectionSelectedItems = selectedItems => {
  /**
   * The DndList component (design-system) is expecting the following structure when
   * multi selection is enabled and exists multi-level hierarchy:
   *
   * {
   *    4: true
   *    10: { 120: true }
   * }
   *
   * Where the numbers are the ID's.
   * When nested (for example 120) means that L2 team ID equals to 120 is selected.
   */
  return selectedItems.reduce((result, item) => {
    if (hasParentId(item)) {
      return { ...result, [item.parent_id]: { ...defaultToEmptyObject(result[item.parent_id]), [item.id]: true } };
    }

    return { ...result, [item.id]: true };
  }, {});
};
