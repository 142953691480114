/* eslint-disable prefer-destructuring */
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { spacing } from 'design-system/theme';
import TextDeprecated from 'design-system/atoms/TextDeprecated';
import {
  getOrgHasJiraTokenOAuth,
  getOrgHasMultipleIntegrations,
  getOrgHasJiraIntegrated,
  getOrgJiraIntegrations,
  getOrgHasJiraOAuth2,
} from 'store/organization/selectors';
import { getUserIntegrations } from 'store/login/selectors';
import { updateJiraConfigDetails } from 'store/integrations';

import SelectAuthType from './SelectAuthType';
import ConfigDetails from './ConfigDetails';
import Title from '../components/Title';
import OAuthForm from './OAuthForm';
import JiraIcon from 'design-system/atoms/JiraIcon/index';

import MultiIntegrationsList from '../components/MultiIntegrationsList';
import Container from '../components/Container';
import { INTEGRATIONS_KEYS } from 'constants/integrations';

class Jira extends React.Component {
  constructor(props) {
    super(props);

    const { orgIntegration, match, orgHasMultipleIntegrations } = props;
    const { id } = match.params;
    let defaultOrgIntegrationId = null;

    if (id && orgHasMultipleIntegrations) defaultOrgIntegrationId = +id;

    this.state = {
      // eslint-disable-next-line no-nested-ternary
      selectedIntegrationId: orgHasMultipleIntegrations ? defaultOrgIntegrationId : orgIntegration ? orgIntegration.id : -1,
    };
  }

  onCreateNewIntegration = () => {
    this.setState({
      selectedIntegrationId: -1,
    });
  };

  onSelectIntegration = integration => {
    this.setState({
      selectedIntegrationId: integration.id,
    });
  };

  render() {
    const { selectedIntegrationId } = this.state;
    const {
      orgIntegrations,
      orgHasMultipleIntegrations,
      orgHasJiraTokenOAuth,
      orgHasJiraOAuth2,
      userCanUpdateOrgIntegration,
      userCanViewOrgIntegration,
    } = this.props;
    const selectedIntegration = selectedIntegrationId === -1 ? {} : orgIntegrations.find(i => i.id === selectedIntegrationId);
    const _setSelectedIntegrationId = id => this.setState({ selectedIntegrationId: id });
    const _goBack = orgHasMultipleIntegrations ? () => _setSelectedIntegrationId(null) : null;

    const renderJiraIntegrationsList = () => (
      <MultiIntegrationsList
        integrationType={INTEGRATIONS_KEYS.JIRA}
        onCreateNewIntegration={this.onCreateNewIntegration}
        onSelectIntegration={this.onSelectIntegration}
        userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
        userCanViewOrgIntegration={userCanViewOrgIntegration}
        updateConfigDetails={updateJiraConfigDetails}
      />
    );

    return (
      <Container>
        <Title
          title="Jira Integration"
          logo={<JiraIcon size={30} marginRight={20} />}
          helpLink="https://dragonboat.zendesk.com/hc/en-us/articles/360017713873"
        />
        {selectedIntegration && selectedIntegration.data && selectedIntegration.data.jira_tested && (
          <IntegrationLink href={selectedIntegration.data.base_url} target="_blank" rel="noreferrer">
            {selectedIntegration.data.base_url}
          </IntegrationLink>
        )}
        {!userCanUpdateOrgIntegration && !userCanViewOrgIntegration && (
          <TextDeprecated size="medium" style={{ textAlign: 'center', marginBottom: '20px' }}>
            To change integration settings, please contact your dragonboat admin
          </TextDeprecated>
        )}
        {!selectedIntegration && orgHasMultipleIntegrations && renderJiraIntegrationsList()}
        {selectedIntegration && (userCanViewOrgIntegration || userCanUpdateOrgIntegration) && (
          <>
            {/* eslint-disable no-nested-ternary */}
            {selectedIntegration.data && selectedIntegration.data.jira_tested ? (
              <ConfigDetails
                jiraConfig={selectedIntegration && selectedIntegration.data}
                orgIntegration={selectedIntegration}
                goBack={_goBack}
                userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
              />
            ) : !orgHasJiraTokenOAuth && !orgHasJiraOAuth2 && userCanUpdateOrgIntegration ? (
              <OAuthForm
                orgIntegration={selectedIntegration}
                setSelectedIntegrationId={_setSelectedIntegrationId}
                jiraConfig={selectedIntegration && selectedIntegration.data}
                goBack={_goBack}
              />
            ) : userCanUpdateOrgIntegration ? (
              <SelectAuthType
                orgIntegration={selectedIntegration}
                onCancel={_goBack}
                jiraConfig={selectedIntegration && selectedIntegration.data}
                setSelectedIntegrationId={_setSelectedIntegrationId}
              />
            ) : (
              <></>
            )}
            {/* eslint-enable no-nested-ternary */}
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    orgHasMultipleIntegrations: getOrgHasMultipleIntegrations(state),
    orgHasJiraTokenOAuth: getOrgHasJiraTokenOAuth(state),
    orgHasJiraOAuth2: getOrgHasJiraOAuth2(state),
    jiraIntegrated: getOrgHasJiraIntegrated(state),
    currentUser: state.login.currentUser,
    orgIntegrations: getOrgJiraIntegrations(state),
    userIntegrations: getUserIntegrations(state),
  };
};

export default compose(connect(mapStateToProps, {}), withRouter)(Jira);

const IntegrationLink = styled.a`
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: ${spacing(2)}px;
`;
