import { useCallback, useMemo, useState } from 'react';
import { defaultTo, equals, keys, not, pipe } from 'ramda';

import { ISSUE_COUNT_PROGRESS, STORY_POINTS_PROGRESS, TIME_ESTIMATES_PROGRESS } from 'constants/integrations';

import useProgressTrackingConfig from 'hooks/integrations/useProgressTrackingConfig';

const DEFAULT = 'DEFAULT';

const defaultToEmptyObject = defaultTo({});

const isNotDefault = pipe(equals(DEFAULT), not);

const useProgressTrackingConfigPage = (integrationType, orgIntegrationId) => {
  const { progressTracking, updateProgressTracking } = useProgressTrackingConfig(integrationType, orgIntegrationId);

  const [selectedProjectKey, setSelectedProjectKey] = useState(DEFAULT);

  const currentProgressTracking = useMemo(() => {
    return defaultToEmptyObject(progressTracking[selectedProjectKey]);
  }, [progressTracking, selectedProjectKey]);

  const projects = useMemo(() => {
    return keys(progressTracking).filter(isNotDefault);
  }, [progressTracking]);

  const onChangeTypeHandler = useCallback(
    selectedType => updateProgressTracking({ ...progressTracking, [selectedProjectKey]: { track_progress_by: selectedType } }),
    [progressTracking, selectedProjectKey, updateProgressTracking],
  );

  const onChangeDaysPerIssueHandler = useCallback(
    ({ target }) => {
      const payload = {
        ...progressTracking,
        [selectedProjectKey]: {
          ...currentProgressTracking,
          track_progress_by: ISSUE_COUNT_PROGRESS,
          display_days_per_issue: target.value,
        },
      };

      updateProgressTracking(payload);
    },
    [progressTracking, currentProgressTracking, selectedProjectKey, updateProgressTracking],
  );

  const onChangeStoryPointsHandler = useCallback(
    ({ target }) => {
      const payload = {
        ...progressTracking,
        [selectedProjectKey]: {
          ...currentProgressTracking,
          track_progress_by: STORY_POINTS_PROGRESS,
          default_story_points: target.value,
        },
      };

      updateProgressTracking(payload);
    },
    [progressTracking, currentProgressTracking, selectedProjectKey, updateProgressTracking],
  );

  const onChangeStoryPointPerDayHandler = useCallback(
    ({ target }) => {
      const payload = {
        ...progressTracking,
        [selectedProjectKey]: {
          ...currentProgressTracking,
          track_progress_by: STORY_POINTS_PROGRESS,
          display_storypoint_per_day: target.value,
        },
      };

      updateProgressTracking(payload);
    },
    [progressTracking, currentProgressTracking, selectedProjectKey, updateProgressTracking],
  );

  const onChangeTimeEstimateHandler = useCallback(
    value => {
      const payload = {
        ...progressTracking,
        [selectedProjectKey]: {
          ...currentProgressTracking,
          track_progress_by: TIME_ESTIMATES_PROGRESS,
          default_time_estimate: value,
        },
      };

      updateProgressTracking(payload);
    },
    [progressTracking, currentProgressTracking, selectedProjectKey, updateProgressTracking],
  );

  const onAddNewProjectKey = useCallback(
    projectKey => {
      const payload = {
        ...progressTracking,
        [projectKey]: { track_progress_by: ISSUE_COUNT_PROGRESS },
      };

      updateProgressTracking(payload);
    },
    [progressTracking, updateProgressTracking],
  );

  const onDeleteProjectKey = useCallback(
    projectKey => {
      if (projectKey === DEFAULT) {
        return;
      }

      const { [projectKey]: toBeDeleted, ...payload } = progressTracking;

      updateProgressTracking(payload);
      setSelectedProjectKey(DEFAULT);
    },
    [progressTracking, updateProgressTracking],
  );

  return {
    progressTracking: currentProgressTracking,
    projects,
    selectedProjectKey,
    onChangeSelectedProjectKey: setSelectedProjectKey,
    onChangeTypeHandler,
    onChangeDaysPerIssueHandler,
    onChangeStoryPointsHandler,
    onChangeStoryPointPerDayHandler,
    onChangeTimeEstimateHandler,
    onAddNewProjectKey,
    onDeleteProjectKey,
  };
};

export default useProgressTrackingConfigPage;
