import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTo, head, pipe } from 'ramda';

import { getIntegrationById as getIntegrationByIdSelector } from 'store/organization';

import { importMetricValues as importMetricValuesAction, selectIsImportingMetricValues } from '../store';

const getFirstMetricIntegration = pipe(defaultTo([]), head);

const useImportMetricValues = (metricId, metricIntegrations) => {
  const dispatch = useDispatch();

  const metricIntegration = getFirstMetricIntegration(metricIntegrations);

  const orgIntegration = useSelector(getIntegrationByIdSelector(metricIntegration?.org_integration_id));

  const isImportingMetricValues = useSelector(selectIsImportingMetricValues);

  const onImportMetricValues = useCallback(() => {
    return dispatch(importMetricValuesAction(orgIntegration?.integrationType, orgIntegration?.id, metricId));
  }, [orgIntegration?.integrationType, orgIntegration?.id, metricId]);

  return {
    isImportingMetricValues,
    onImportMetricValues,
  };
};

export default useImportMetricValues;
