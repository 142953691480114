import { css } from 'styled-components';

import theme from 'design-system/theme';
import { materialColors, linkColor, materialBackground } from 'design-system/themes/default';

const agGridStylesOverride = css`
  .ag-layout-normal.ag-root {
    margin: 0 44px;
  }
  .ag-cell > div > span {
    width: 100%;
    display: inline-block !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
  .ag-cell .ag-react-editable-container--expandable {
    position: relative;
  }
  .ag-cell:not(.ag-react-editable-container--expandable) {
    white-space: normal;
  }
  .ag-cell.no-vertical-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
  .ag-cell > div {
    height: 100%;
  }
  .first-field .ag-cell-label-container {
    padding: 0 10px 0 10px;
  }
  .centered-header .ag-cell-label-container {
    display: flex !important;
    justify-content: center;
  }
  .ag-header-cell {
    .ag-header-cell-comp-wrapper {
      height: auto !important;
    }
  }

  .ag-header-cell-text-centered {
    justify-content: center;

    .ag-header-cell-text {
      flex: 1;
      text-align: center;
    }

    .ag-header-cell-label {
      /*
      * This header label be centered with cell content
      * for centered content we have the header cell menu (width: 18px)
      */
      margin-left: 18px;
    }

    .ag-header-group-cell-label {
      justify-content: center;
    }
  }
  .centered-header .ag-header-cell-label {
    position: absolute;
    justify-content: center;
    width: auto;
  }
  .centered-header .ag-header-icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .ag-group-value .ag-react-container {
    display: inline !important;
  }
  .ag-row-group-leaf-indent {
    margin-left: 0 !important;
  }
  .ag-row-hover {
    background-color: ${materialColors.lightGray} !important;
  }
  .ag-cell-focus {
    border: 1px solid ${linkColor} !important;
  }
  .ag-row-level-0 .ag-group-expanded.ag-hidden + .ag-group-contracted.ag-hidden ~ .ag-group-checkbox:not(.ag-hidden) {
    padding-left: 26px;
  }
  .ag-row-checkbox-disabled .ag-selection-checkbox .ag-icon-checkbox-unchecked {
    pointer-events: none;
    opacity: 0.2;
  }
  .ag-body-container .ag-row-drag {
    width: 20px;
    margin-left: -2px !important;
    cursor: grab;
    transition: opacity 150ms;
    opacity: 0;
  }
  .ag-body-container .ag-row.ag-row-hover .ag-row-drag {
    opacity: 1;
  }

  .ag-cell-inline-editing .ag-cell-editor .ag-wrapper {
    padding: 6px;
  }

  .ag-input-wrapper {
    align-items: flex-start;
  }

  div.ag-cell,
  .ag-cell p,
  .ag-cell span,
  .ag-cell div {
    color: ${theme.palette.text.primary};
    font-size: ${theme.typography.fontSizeSmallRem}rem;
    line-height: ${theme.typography.lineHeightSmallRem}rem;
  }

  .ag-cell div.red span,
  .ag-cell div.red p {
    color: rgb(255, 0, 0) !important;
  }

  .ag-header-cell-filtered {
    background-color: #cae1fa !important;
  }

  .ag-cell-inline-editing.padding-0 {
    padding: 0 !important;
  }

  .ag-cell-inline-editing.border-0 {
    border: 0 !important;
  }

  .ag-cell {
    display: flex;
    align-items: center;

    &.align-items-center,
    &.align-items-center > div {
      align-items: center !important;
      justify-content: center;
    }
  }

  .ag-cell-edit-input {
    width: 100%;
  }

  .ag-header-group-cell.ag-header-cell-small-padding,
  .ag-header-cell.ag-header-cell-small-padding {
    padding: 3px 0 !important;

    &:first-child {
      padding-left: 0 !important;
    }
    &:last-child {
      padding-right: 0 !important;
    }
  }

  /*
  * force to hide the wrapper when drag and select is
  * invisible. Only when  is included with title column
  */
  .ag-group-expanded.ag-invisible,
  .ag-row-drag.ag-invisible,
  .ag-group-checkbox.ag-invisible,
  .ag-selection-checkbox.ag-invisible {
    display: none !important;
  }

  .roadmap-version-with-background-color {
    background-color: ${materialBackground.roadmapVersionCellBackground};
  }

  .ag-root .ag-header .ag-pinned-left-header,
  .ag-root .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
    border: none;
  }

  .ag-header-cell-text {
    color: ${theme.palette.text.secondary};
    font-size: ${theme.typography.fontSizeSmallRem}rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .ag-row-group .ag-cell-value span,
  .ag-row-group .ag-cell-value p {
    font-weight: ${theme.typography.fontWeightMedium};
  }
`;

export default agGridStylesOverride;
