import React, { useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch } from 'react-redux';

import NewDragonInput from 'design-system/molecules/NewDragonInput/index';

import theme, { spacing } from 'design-system/theme';
import useProjectsDropdown from 'containers/ProjectsDropdown/useProjectsDropdown';
import useOrganizations from 'hooks/useOrganizations';
import { openProjectLightbox } from 'store/projectLightbox';
import useProjectsTitleSearch from 'hooks/useProjectsTitleSearch';
import { getPageIdFromPath } from 'utils/userViews';
import { IDEAS_OVERVIEW_PAGE } from 'constants/filters';

import GlobalSearchPopoverFooter from './components/GlobalSearchPopoverFooter';
import useInputEventsHandlers from './hooks/useInputEventsHandlers';
import useInputSearchValue from './hooks/useInputSearchValue';

const GLOBAL_SEARCH_POPOVER_WIDTH = 500;
const GLOBAL_SEARCH_SERVER_DELAY = 100;
const DELAY_TIME_TO_OPEN_PROJECT_LIGHTBOX = 100;
const GLOBAL_SEARCH_TOP_OFFSET = 12;
const LIMIT_PROJECTS_TO_SHOW = 5;
const LIMIT_PROJECTS_TO_SHOW_WHEN_SEARCH = 20;
const GLOBAL_FOOTER_ROW_HEIGHT = 36;
const PROJECT_ROW_HEIGHT = 33;
const CONTENT_HEIGHT = LIMIT_PROJECTS_TO_SHOW * PROJECT_ROW_HEIGHT;

/*
 * This component have the input search to use in the app header
 */
const GlobalSearchInput = () => {
  const dispatch = useDispatch();

  const {
    filters: { availableLayers },
  } = useOrganizations();

  const { value, setValue, searchValue } = useInputSearchValue();

  const searchLayers = useMemo(() => Object.values(availableLayers), [availableLayers]);

  const path = window.location.pathname.replace(/^\/+/, '');
  const pageId = getPageIdFromPath(path);
  const pagesToHideFooter = [IDEAS_OVERVIEW_PAGE];

  const footerComponent = !pagesToHideFooter.includes(pageId) ? GlobalSearchPopoverFooter : null;

  const getContainerPosition = useCallback((defaultPos, inputBoundingClientRect) => {
    return {
      ...defaultPos,
      $height: CONTENT_HEIGHT + (footerComponent ? GLOBAL_FOOTER_ROW_HEIGHT : 0),
      $top: defaultPos.$top + GLOBAL_SEARCH_TOP_OFFSET,
      $left: inputBoundingClientRect.right - GLOBAL_SEARCH_POPOVER_WIDTH,
      $width: GLOBAL_SEARCH_POPOVER_WIDTH,
    };
  }, []);

  const renderTitle = data => {
    return (
      <ProjectTitle>
        {`DB-${data.id}`} {data.title}{' '}
        <span>
          {data.roadmapTitle ? `(${data.roadmapTitle}) ` : ''}
          {data.ownerName}
        </span>
      </ProjectTitle>
    );
  };

  const onProjectSelect = useCallback((project, { closeTree }) => {
    closeTree();

    /*
     * this setTimeout is to not execute the dispatch at same time is closing the tree
     * because the tree remains for some seconds above the lightbox background
     */
    setTimeout(() => dispatch(openProjectLightbox(project.id)), DELAY_TIME_TO_OPEN_PROJECT_LIGHTBOX);
  }, []);

  const { setData, isLoading, setIsLoading, treeIsOpen, setTreeIsOpen, inputRef, dropDownRef } = useProjectsDropdown(
    'global-search',
    {
      onProjectSelect,
      showShouldShowCompletedSwitcher: false,
      footerComponent,
      getContainerPosition,
      treeComponentProps: {
        renderTitle,
        groups: {
          selectedGroup1Key: null,
          selectedGroup2Key: null,
        },
        treeView: false,
      },
      projectRowHeight: PROJECT_ROW_HEIGHT,
    },
  );

  const { searchProjects, filters } = useProjectsTitleSearch(searchLayers);

  const dataLoader = useCallback(
    async search => {
      const from = 0;
      const limit = !search ? LIMIT_PROJECTS_TO_SHOW : LIMIT_PROJECTS_TO_SHOW_WHEN_SEARCH;

      return searchProjects(search, { showCompleted: true, from, limit });
    },
    [searchProjects],
  );

  const loadData = useCallback(
    async (search = '') => {
      setIsLoading(true);

      const searchResults = await dataLoader(search);

      setData(searchResults);

      setIsLoading(false);
    },
    [dataLoader, setData, setIsLoading],
  );

  const [debouncedLoadData] = useDebouncedCallback(loadData, GLOBAL_SEARCH_SERVER_DELAY);

  const { handleOnChange, handleOnFocus, handleOnKeyDown, handleOnBlur } = useInputEventsHandlers({
    debouncedLoadData,
    setValue,
    value,
    treeIsOpen,
    setTreeIsOpen,
    isLoading,
    filters,
    dropDownRef,
  });

  useEffect(() => {
    if (!treeIsOpen) {
      setData([]);
    }
  }, [treeIsOpen, searchValue]);

  return (
    <Wrapper>
      <NewDragonInput
        inputRef={inputRef}
        id="global_search"
        type="search"
        value={value}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onKeyDown={handleOnKeyDown}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ProjectTitle = styled.label`
  &&&& {
    display: block;
    margin-left: ${spacing(0.5)}px;
    font-size: ${theme.typography.fontSize}px;
    white-space: break-spaces;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    font-weight: ${theme.typography.fontWeightBold};

    span {
      font-size: ${theme.typography.fontSizeSmall}px;
      line-height: 14px;
      color: ${theme.palette.text.lightestGrey};
      font-weight: ${theme.typography.fontWeight};
      word-break: break-word;
    }
  }
`;

GlobalSearchInput.propTypes = {};

export default GlobalSearchInput;
