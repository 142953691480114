const handleProductSelection = (options, selectedValue, save) => {
  const roadmap = options.find(opt => opt.id === selectedValue.roadmap_id);

  if (selectedValue.level === 0) {
    save({ roadmapTitle: roadmap.title, product1Title: selectedValue.title, product2Title: null });
  }

  if (selectedValue.level === 1) {
    const product1 = roadmap.products.find(opt => opt.id === selectedValue.parent_id);

    save({ roadmapTitle: roadmap.title, product1Title: product1.title, product2Title: selectedValue.title });
  }
};

export { handleProductSelection };
