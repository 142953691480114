import React from 'react';
import styled from 'styled-components';

import { draftBackgroundColor } from 'design-system/constants/draft';

import legacyVersion from './Legacy';
import newVersion from './New';
import useRoadmapVersions from 'hooks/useRoadmapVersions';

const shouldUseNewVersion = localStorage.getItem('threeGroupingLevelsSwimlane') === 'true';

const component = shouldUseNewVersion ? newVersion : legacyVersion;

const SwimlaneHoc = Component => {
  return props => {
    const { isRoadmapVersionSelected } = useRoadmapVersions();

    return <Component {...props} useDraftStyle={isRoadmapVersionSelected} />;
  };
};

const StyledComponent = styled(component)`
  ${({ useDraftStyle }) => (useDraftStyle ? `background-color: ${draftBackgroundColor}` : '')};
`;

export default SwimlaneHoc(StyledComponent);

// export default newVersion;
// export default legacyVersion;
