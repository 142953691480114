import { useState, useCallback, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import {
  getMetrics,
  getSearchText,
  getIsHidingArchivedMetrics,
  getTableVisibleFields,
  getActiveMetrics,
} from 'store/metrics/selectors';
import {
  fetchMetrics,
  addMetricWithoutSave,
  createMetric,
  updateMetricById,
  removeUnsavedMetrics,
  updateSearchText,
  updateIsHidingArchivedMetrics,
  metricActions,
  updateTableVisibleFields,
} from 'store/metrics/actions';
import { switchMetricsRowOrder, createMetricRoadmap, deleteMetricRoadmap, bulkDeleteMetricRoadmaps } from 'store/metrics/thunks';
import { getOrgHasMetadataRoadmaps } from 'store/organization/selectors';
import useMetricsIntegrations from 'hooks/useMetricsIntegrations';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import {
  NAME_COLUMN_TITLE,
  APPLIES_TO_COLUMN_TITLE,
  STATUS_COLUMN_TITLE,
  LEVEL_COLUMN_TITLE,
  DETAILS_COLUMN_TITLE,
  OWNER_COLUMN_TITLE,
  HEALTH_COLUMN_TITLE,
  ACTUAL_VALUE_COLUMN_TITLE,
  TARGET_VALUE_COLUMN_TITLE,
  BASELINE_VALUE_COLUMN_TITLE,
  DATA_SOURCE_COLUMN_TITLE,
  SUMMARY_COLUMN_TITLE,
  COLOR_COLUMN_TITLE,
} from './constants';

const LEFT_TABLE_FIELDS_ITEMS = [
  NAME_COLUMN_TITLE,
  APPLIES_TO_COLUMN_TITLE,
  STATUS_COLUMN_TITLE,
  LEVEL_COLUMN_TITLE,
  DATA_SOURCE_COLUMN_TITLE,
  SUMMARY_COLUMN_TITLE,
  COLOR_COLUMN_TITLE,
];

const RIGHT_TABLE_FIELDS_ITEMS = [
  DETAILS_COLUMN_TITLE,
  OWNER_COLUMN_TITLE,
  HEALTH_COLUMN_TITLE,
  ACTUAL_VALUE_COLUMN_TITLE,
  TARGET_VALUE_COLUMN_TITLE,
  BASELINE_VALUE_COLUMN_TITLE,
];

const METADATA_ROADMAPS_ONLY_FIELDS = [APPLIES_TO_COLUMN_TITLE];

const useMetrics = () => {
  const dispatch = useDispatch();

  const [tableShowFieldsEl, setTableShowFieldsEl] = useState(null);

  const metrics = useSelector(getMetrics);
  const activeMetrics = useSelector(getActiveMetrics);
  const searchText = useSelector(getSearchText);
  const isHidingArchivedMetrics = useSelector(getIsHidingArchivedMetrics);
  const tableVisibleFields = useSelector(getTableVisibleFields);
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);

  const { metricOrgIntegrations, canViewMetricIntegration, canUpdateMetricIntegration, updateMetricIntegration } =
    useMetricsIntegrations();

  const { canView } = usePermissions();

  const canViewMetricLevels = canView(PERMISSION_FEATURES.metricLevels);
  const canViewMetricSummary = canView(PERMISSION_FEATURES.metricSummary);

  const toggleIsHidingArchivedMetrics = useCallback(() => {
    dispatch(updateIsHidingArchivedMetrics(!isHidingArchivedMetrics));
  }, [dispatch, updateIsHidingArchivedMetrics, isHidingArchivedMetrics]);

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          fetchMetrics,
          addMetricWithoutSave,
          createMetric,
          updateMetricById,
          removeUnsavedMetrics,
          switchMetricsRowOrder,
          updateSearchText,
        },
        dispatch,
      ),
    [dispatch],
  );

  const [debouncedUpdateSearchText] = useDebouncedCallback(boundActionCreators.updateSearchText, 500);

  const handleChangeTableFields = fields => dispatch(updateTableVisibleFields(fields));
  const handleHideAllTableFields = () => dispatch(updateTableVisibleFields([]));
  const handleShowAllTableFields = () =>
    dispatch(updateTableVisibleFields([...LEFT_TABLE_FIELDS_ITEMS, ...RIGHT_TABLE_FIELDS_ITEMS]));

  const filterFieldsByFlags = useCallback(
    fields =>
      (fields || []).filter(field => {
        if (!hasMetadataRoadmaps) return !METADATA_ROADMAPS_ONLY_FIELDS.includes(field);
        if (!canViewMetricLevels) return field !== LEVEL_COLUMN_TITLE;
        if (!canViewMetricSummary) return field !== SUMMARY_COLUMN_TITLE;
        if (!canViewMetricIntegration) return field !== DATA_SOURCE_COLUMN_TITLE;

        return true;
      }),
    [hasMetadataRoadmaps, canViewMetricLevels],
  );

  const visibleFields = useMemo(() => filterFieldsByFlags(tableVisibleFields), [tableVisibleFields, filterFieldsByFlags]);

  const leftTableFields = useMemo(
    () => filterFieldsByFlags(LEFT_TABLE_FIELDS_ITEMS),
    [LEFT_TABLE_FIELDS_ITEMS, filterFieldsByFlags],
  );

  const rightTableFields = useMemo(
    () => filterFieldsByFlags(RIGHT_TABLE_FIELDS_ITEMS),
    [RIGHT_TABLE_FIELDS_ITEMS, filterFieldsByFlags],
  );

  return {
    metrics,
    activeMetrics,
    searchText,
    isHidingArchivedMetrics,
    toggleIsHidingArchivedMetrics,
    ...boundActionCreators,
    createMetricRoadmap,
    deleteMetricRoadmap,
    bulkDeleteMetricRoadmaps,
    updateSearchText: debouncedUpdateSearchText,
    addMetricValue: metricActions.addMetricValue,
    deleteMetricValue: metricActions.deleteMetricValue,
    updateMetricValue: metricActions.updateMetricValue,
    tableShowFieldsEl,
    setTableShowFieldsEl,
    tableVisibleFields: visibleFields,
    handleChangeTableFields,
    handleShowAllTableFields,
    handleHideAllTableFields,
    leftTableFields,
    rightTableFields,
    canViewMetricIntegration,
    canUpdateMetricIntegration,
    metricOrgIntegrations,
    updateMetricIntegration,
  };
};

export default useMetrics;
