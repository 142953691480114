import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SettingsIcon from '@material-ui/icons/Settings';
import FormControl from '@material-ui/core/FormControl';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import MultiSelect from 'design-system/atoms/MultiSelect/index';

import normalizeArray from 'utils/normalizeArray';

export default ({ onApply, preferences, displayFieldsOptions }) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [tempState, setTempState] = React.useState({});
  const _onCancel = () => setShowDialog(false);
  const _onOpen = () => setShowDialog(true);
  const _onApply = () => {
    onApply(tempState);
    _onCancel();
  };
  const displayFieldsOptionsByKey = normalizeArray(displayFieldsOptions, 'key');
  const displayFieldsOptionsByLabel = normalizeArray(displayFieldsOptions, 'label');
  const displayFieldsValue =
    tempState.displayFields &&
    tempState.displayFields.map(k => displayFieldsOptionsByKey[k] && displayFieldsOptionsByKey[k].label);

  React.useEffect(() => {
    if (!showDialog) return;
    setTempState({
      ...(preferences || {}),
      displayFields: preferences.displayFields || ['title', 'timeframe', 'key'],
    });
  }, [showDialog]);

  return (
    <>
      <ButtonIcon size="small" style={{ float: 'right' }} onClick={_onOpen} title="Preferences">
        <SettingsIcon />
      </ButtonIcon>
      <Dialog open={showDialog} maxWidth="md" fullWidth>
        <DialogTitle>Display Preferences</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <MultiSelect
              options={displayFieldsOptions.filter(o => tempState.displayFields && !tempState.displayFields.includes(o.key))}
              optionsMapper={{ label: 'label', value: 'key' }}
              label="Display these values on card"
              placeholder="Select fields to display"
              onChange={newOptions => {
                setTempState({
                  displayFields: newOptions.map(k => (displayFieldsOptionsByLabel[k] ? displayFieldsOptionsByLabel[k].key : k)),
                });
              }}
              value={displayFieldsValue}
              hideCreateOption
              hideIcon
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={_onApply} color="primary">
            Apply
          </Button>
          <Button onClick={_onCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
