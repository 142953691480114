import withStyles from '@material-ui/core/styles/withStyles';

import Users from './Users';

const styles = {
  searchBarContainer: {
    marginRight: 40,
    display: 'inline-block',
  },
  fileInputContainer: {
    marginRight: 6,
    display: 'inline-block',
  },
};

export default withStyles(styles)(Users);
