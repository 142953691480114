import React, { useState, useMemo } from 'react';
import { pipe, isNil, not, isEmpty, defaultTo } from 'ramda';

import Title from '../components/Title';
import Body from '../components/Body';
import Container from '../components/Container';
import ConnectionSteps from './components/ConnectionSteps';
import ChildDragonDetails from './components/ChildDragonDetails';
import MultiChildDragonsList from './components/MultiChildDragonsList';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useLoadPageMetadata from 'hooks/useLoadPageMetadata';

import DragonLogo from 'design-system/atoms/DragonLogo/index';


const isNotNil = pipe(isNil, not);

const defaultToEmptyArray = defaultTo([]);
const isNotEmpty = pipe(isEmpty, not);
const haveAccessToChildren = pipe(defaultToEmptyArray, isNotEmpty);
// TODO: we need the final link. Used a generic so it won't redirect to ADO integration page
const HELP_LINK = 'https://dragonboat.zendesk.com/hc/en-us/sections/360001489073-Getting-Started';

const DragonboatComponent = ({ isAdmin }) => {
  const [selectedChildDragonOrgId, setSelectedChildDragonOrgId] = useState(null);
  const [addingChildDragonAccess, setAddingChildDragonAccess] = useState(false);
  const loadMetadata = useLoadPageMetadata();

  const {
    organizationAccessControlData,
    addChildDragonAccess,
    removeChildDragonAccess,
    childrenDragons,
    getAccountNameAndUrlForOrganizationId,
  } = useOrganizationsAccessControl();

  const hasChildDragons = haveAccessToChildren(childrenDragons);

  const selectedChildDragon = useMemo(() => {
    if (isNil(selectedChildDragonOrgId)) {
      return null;
    }
    const account = organizationAccessControlData.find(
      childAccess => childAccess.child_organization_id === selectedChildDragonOrgId,
    );

    const { accountName } = getAccountNameAndUrlForOrganizationId(selectedChildDragonOrgId);

    return {
      ...account,
      accountName,
    };
  }, [organizationAccessControlData, selectedChildDragonOrgId, getAccountNameAndUrlForOrganizationId]);

  const shouldShowAddChildDragonAccessSteps = !hasChildDragons || addingChildDragonAccess;
  const shouldShowChildDragonsList = hasChildDragons && !selectedChildDragonOrgId && !addingChildDragonAccess;
  const shouldShowChildDragonDetails = isNotNil(selectedChildDragon);

  const onCreateNewChildDragonAccessHandler = () => {
    setAddingChildDragonAccess(true);
  };

  const onClickBackDetails = () => {
    setSelectedChildDragonOrgId(null);
  };

  const onClickBackCreate = () => {
    setAddingChildDragonAccess(false);
  };

  const onAddOrganizationAccess = () => {
    setAddingChildDragonAccess(false);
    loadMetadata(true);
  };
  const onRemoveOrganizationIntegration = () => {
    setSelectedChildDragonOrgId(undefined);
    loadMetadata(true);
  };
  const onSelectChildDragonHandler = ({ child_organization_id: childOrganizationId }) => {
    if (childOrganizationId) {
      setSelectedChildDragonOrgId(childOrganizationId);
    }
  };

  return (
    <Container>
      <Title
        logo={<DragonLogo style={{ width: 40, height: 40, marginRight: 16 }} />}
        title="Dragonboat Integration"
        helpLink={HELP_LINK}
      />
      {shouldShowAddChildDragonAccessSteps && (
        <Body>
          {isAdmin && (
            <>
              <ConnectionSteps
                addChildDragonAccess={addChildDragonAccess}
                addingChildDragonAccess={addingChildDragonAccess}
                onAddChildAccess={onAddOrganizationAccess}
                onClickBack={hasChildDragons && onClickBackCreate}
              />
            </>
          )}
          {!selectedChildDragon && !isAdmin && (
            <p>Please contact your organization Admin to activate Dragonboat on Dragonboat.</p>
          )}
        </Body>
      )}
      {shouldShowChildDragonsList && (
        <MultiChildDragonsList
          childrenDragons={childrenDragons}
          onCreateChildAccess={onCreateNewChildDragonAccessHandler}
          onSelectChildDragon={onSelectChildDragonHandler}
          readOnly={!isAdmin}
          shouldRenderNewButton={isAdmin}
        />
      )}
      {shouldShowChildDragonDetails && (
        <ChildDragonDetails
          selectedChildDragon={selectedChildDragon}
          handleRemoveChildDragonAccess={removeChildDragonAccess}
          onRemoveOrgIntegration={onRemoveOrganizationIntegration}
          isAdmin={isAdmin}
          onClickBack={onClickBackDetails}
        />
      )}
    </Container>
  );
};

export default DragonboatComponent;
