/**
 * PageTemplate Template Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PageTemplate = ({ header, children, ...props }) => {
  return (
    <Wrapper {...props}>
      <Header>{header}</Header>
      <Content>{children}</Content>
    </Wrapper>
  );
};

PageTemplate.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.any.isRequired,
};

export default PageTemplate;

const Wrapper = styled.div`
  min-height: calc(100vh - 68px);
  max-height: 'calc(100vh - 68px)';
  overflow: hidden;
`;

const Header = styled.div``;

const Content = styled.section`
  width: 100%;
  box-sizing: border-box;
  margin: 0;
`;
