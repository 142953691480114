import React, { Component } from 'react';
import Input from '@material-ui/core/Input';
import RootRef from '@material-ui/core/RootRef';

import agGridOnPaste from 'design-system/utils/agGridOnPaste';
import { isEmail } from 'design-system/utils';

export default class AgGridInput extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
    };

    this.onChange = this.onChange.bind(this);
    this.getValue = this.getValue.bind(this);
    this.onPaste = agGridOnPaste.bind(this);

    this.domRef = React.createRef();
  }

  componentDidMount() {
    const { charPress } = this.props;

    // setTimeout ensures that the focus is done after render material ui select component
    setTimeout(() => {
      this.domRef.current.childNodes[0].select();
      this.domRef.current.childNodes[0].focus();
      if (charPress) {
        // changing dom directly is quicker that waiting for the state updating
        this.domRef.current.childNodes[0].value = charPress;
        // setting the state is still required for saving one character strings
        this.setState({
          value: charPress,
        });
      }
    }, 0);
  }

  onChange(event) {
    this.setState({
      value: event.target.value,
    });
  }

  getValue() {
    const { value } = this.state;

    if (isEmail(value)) {
      return value;
    }

    return '';
  }

  render() {
    const { value } = this.state;

    return (
      <RootRef rootRef={this.domRef}>
        <Input type="email" fullWidth value={value} onChange={this.onChange} onPaste={this.onPaste} />
      </RootRef>
    );
  }
}
