import React, { useCallback, useEffect, useReducer, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { move } from 'ramda';

import RemoveIcon from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';

import DndList from 'design-system/organisms/DndList/index';
import TextField from 'design-system/atoms/TextField/index';
import DndAtoms from 'design-system/atoms/DndAtoms/index';


import ConfirmDialog from 'components/ConfirmDialog';

const { ActionWrapper, MenuText } = DndAtoms;

import theme from 'design-system/theme';

export const OPTIONS_FORM_WIDTH = 400;

const DEFAULT_ADD_OPTION_LABEL = '+ Add Option';
const DEFAULT_TITLE_LABEL = 'Options';
const DEFAULT_OPTION_ID_PREFIX = 'option#';

const exist = Boolean;

const convertOptionsToObject = options => {
  const optionsObject = (options || [])
    .filter(o => exist(o.label))
    .reduce((acc, current) => {
      return {
        ...acc,
        [current.id]: {
          title: current.label,
          color: current.color || null,
          rank: current.rank,
          archived: current.archived || false,
        },
      };
    }, {});

  return optionsObject;
};

const ItemTemplateComponent = ({ item, onChangeCallback, disabled }) => {
  const focused = !item.label;

  const onKeyPress = e => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  const onChange = e => onChangeCallback(item.id, e.target.value);

  return (
    <ItemWrapper>
      <Field
        value={item.label}
        inputRef={input => {
          if (input != null && focused) {
            input.focus();
          }
        }}
        onKeyPress={onKeyPress}
        onChange={onChange}
        disabled={disabled}
      />
    </ItemWrapper>
  );
};

const getEnrichedOptions = (opt, disabled) => {
  const enrichedOptions = Object.entries(opt)
    .map(([key, val], idx) => {
      return {
        id: key,
        label: val.title,
        color: val.color,
        rank: val.rank ? parseInt(val.rank) : idx + 1,
        archived: val.archived || false,
        disabled,
      };
    })
    .sort((val, val2) => {
      return val.rank - val2.rank;
    });

  return enrichedOptions;
};

const emptyObject = {};

const useOptionsForm = ({
  options = emptyObject,
  handleUpdateOptions,
  titleLabel = DEFAULT_TITLE_LABEL,
  addOptionLabel = DEFAULT_ADD_OPTION_LABEL,
  optionIdPrefix = DEFAULT_OPTION_ID_PREFIX,
  showStatusButton = true,
  wrapperDndListStyle = {},
  disableEdit,
  withRemovalConfirmation = true,
  popoverActionRef = null,
  onOptionRemove,
}) => {
  const initialState = exist(options) ? getEnrichedOptions(options, disableEdit) : [];

  const [selectedOptionToRemove, setSelectedOptionToRemove] = useState(null);

  const triggerOptionsUpdate = state => {
    if (handleUpdateOptions) {
      // we need to convert the state to the input options format before update
      const optionsObject = convertOptionsToObject(state);

      handleUpdateOptions(optionsObject);

      // adjusts the options popover if getting too close to edge of window
      if (popoverActionRef && popoverActionRef.updatePosition) {
        popoverActionRef.updatePosition();
      }
    }
  };

  const reducer = (state = [], action) => {
    switch (action.type) {
      case 'ADD_OPTION': {
        return [...state, { id: `${optionIdPrefix}${uuidv4()}`, rank: state.length + 1 }];
      }
      case 'REMOVE_OPTION': {
        const newState = [...state.filter(o => o.id !== action.key)];

        triggerOptionsUpdate(newState);

        return newState;
      }
      case 'CHANGE_OPTION_LABEL': {
        const newState = [
          ...state.map(o => ({
            ...o,
            label: o.id === action.key ? action.text : o.label,
          })),
        ];

        triggerOptionsUpdate(newState);

        return newState;
      }
      case 'CHANGE_OPTION_COLOR': {
        const newState = [
          ...state.map(o => ({
            ...o,
            color: o.id === action.key ? action.color : o.color,
          })),
        ];

        triggerOptionsUpdate(newState);

        return newState;
      }
      case 'CHANGE_OPTION_RANK': {
        const { sourceIndex, destinationIndex } = action;

        let newState = move(sourceIndex, destinationIndex, state);

        newState = (newState || []).map((o, idx) => ({ ...o, rank: idx + 1 }));

        triggerOptionsUpdate(newState);

        return newState;
      }
      case 'CHANGE_OPTION_STATUS': {
        const { key, currentStatus } = action;

        const newState = [
          ...state.map(o => ({
            ...o,
            archived: o.id === key ? !currentStatus : o.archived,
          })),
        ];

        triggerOptionsUpdate(newState);

        return newState;
      }
      case 'RESET_STATE':
        return [...initialState];
      case 'CLEAN_EMPTY': {
        return [...state.filter(o => exist(o.label))];
      }
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'RESET_STATE' });
  }, [options]);

  const _addOption = () => dispatch({ type: 'ADD_OPTION' });

  const _changeOptionLabel = (key, value) => dispatch({ type: 'CHANGE_OPTION_LABEL', key, text: value });

  const _updateColor = (itemsChangedKeys = [], color) => {
    itemsChangedKeys.forEach(key => {
      dispatch({ type: 'CHANGE_OPTION_COLOR', key, color });
    });
  };

  const _onChangeOrder = (_, sourceIndex, destinationIndex) => {
    dispatch({ type: 'CHANGE_OPTION_RANK', sourceIndex, destinationIndex });
  };

  const _renderAddOption = () => (
    <ActionWrapper>
      <MenuText disabled={disableEdit} onClick={_addOption}>
        <Typography variant="body1">{addOptionLabel}</Typography>
      </MenuText>
    </ActionWrapper>
  );

  const removalAction = item => {
    if (withRemovalConfirmation) {
      return setSelectedOptionToRemove(item.id);
    }

    dispatch({ type: 'REMOVE_OPTION', key: item.id });

    if (onOptionRemove && typeof onOptionRemove === 'function') {
      onOptionRemove(item.id);
    }
  };

  const onCancelRemoval = () => setSelectedOptionToRemove(null);
  const onConfirmRemoveOption = () => {
    dispatch({ type: 'REMOVE_OPTION', key: selectedOptionToRemove });

    if (onOptionRemove && typeof onOptionRemove === 'function') {
      onOptionRemove(selectedOptionToRemove);
    }

    onCancelRemoval();
  };

  const _itemRightActions = [
    ...(showStatusButton
      ? [
          {
            renderIcon: item => (exist(item.archived) ? <VisibilityOffIcon /> : <Visibility />),
            onClick: item => dispatch({ type: 'CHANGE_OPTION_STATUS', key: item.id, currentStatus: item.archived }),
          },
        ]
      : []),
    {
      renderIcon: () => <RemoveIcon />,
      onClick: removalAction,
      testId: 'remove-option',
    },
  ];

  const _renderItemTemplate = useCallback(
    item => {
      return <ItemTemplateComponent disabled={disableEdit} item={item} onChangeCallback={_changeOptionLabel} />;
    },
    [_changeOptionLabel, disableEdit],
  );

  const renderRemovalConfirmDialog = () => {
    const isRemovalOptionDialogOpen = withRemovalConfirmation && selectedOptionToRemove;
    const removalOptionDialogText = `Are you sure you want to delete ${
      options?.[selectedOptionToRemove]?.title ?? 'selected option'
    }?`;

    return (
      <ConfirmDialog
        isOpen={isRemovalOptionDialogOpen}
        onCancel={onCancelRemoval}
        onConfirm={onConfirmRemoveOption}
        title="Delete selected Option"
        text={removalOptionDialogText}
      />
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        <DndList
          titleLabel={titleLabel}
          items={state}
          colorable
          onChangeColor={_updateColor}
          renderAddOption={!disableEdit ? _renderAddOption : null}
          itemRightActions={!disableEdit ? _itemRightActions : []}
          renderCustomItem={_renderItemTemplate}
          draggable={!disableEdit}
          onChangeOrder={_onChangeOrder}
          width={OPTIONS_FORM_WIDTH}
          wrapperStyle={wrapperDndListStyle}
        />
        {renderRemovalConfirmDialog()}
      </>
    </ThemeProvider>
  );
};

export default useOptionsForm;

useOptionsForm.propTypes = {
  options: PropTypes.object,
  handleUpdateOptions: PropTypes.func,
};

const ItemWrapper = styled.div`
  &&&& {
    height: 20px;
  }
`;

const Field = styled(TextField)`
  &&&& {
    flex-grow: 1;
    height: 100%;
    font-size: ${props => props.theme.typography.fontSize}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    line-height: ${props => props.theme.typography.lineHeightRegular}px;
    color: ${props => props.theme.palette.text.lightGrey};

    label {
      display: none;
    }

    label + div {
      margin-top: 0;
      height: 100%;
    }

    input {
      padding: 0;
    }
  }
`;
