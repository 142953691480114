/**
 *
 * @param {*} event
 */

import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';

import isNumeric from 'utils/isNumeric';
import isPercentage from 'utils/isPercentage';
import isTruthyOrZero from 'utils/isTruthyOrZero';

export const returnsTrueIfKeyIsNotEnterOrTab = event => {
  const key = event.event.which || event.event.keyCode;

  // prevent navigation actions while editing
  if (
    event.editing &&
    key !== 9 && // tab
    key !== 13 && // enter
    key !== 27 // Escape
  ) {
    return true;
  }
};

/**
 *
 * @param {*} event
 */
export const returnsTrueIfKeyIsNotEscOrTab = event => {
  const key = event.event.which || event.event.keyCode;

  // prevent navigation actions while editing
  if (
    event.editing &&
    key !== 9 && // tab
    key !== 27 // Escape
  ) {
    return true;
  }
};

export const returnsTrueIfKeyIsNotEscOrTabOrEnter = event => {
  const key = event.event.which || event.event.keyCode;

  // prevent navigation actions while editing
  if (
    event.editing &&
    key !== 9 && // tab
    key !== 27 && // Escape
    key !== 13 // Enter
  ) {
    return true;
  }
};

export const wrapTextOptionsFactory = rowsAutoheight => ({
  ...(rowsAutoheight
    ? {
        autoHeight: true,
        cellClass: 'ag-react-container--expandable',
      }
    : {
        cellClass: 'ag-react-editable-container--expandable',
      }),
  suppressKeyboardEvent: returnsTrueIfKeyIsNotEnterOrTab,
});

export const editOptions = {
  editable: true,
};

export const mapDataToAgGrid = row => {
  const newRow = {};

  Object.keys(row).forEach(field => {
    newRow[field] = cloneDeep(row[field]) || '';
  });
  return newRow;
};

/**
 * Returns true if the cell value is neither a number or a percentage
 *
 * @param {*} params
 */
export const isInvalidNumberCell = params =>
  Object.hasOwn(params, 'value') && isTruthyOrZero(params.value) && !isNumeric(params.value) && !isPercentage(params.value);

/**
 * @function cleanColumnStateToApplyOnGrid
 *
 * Cleans a stored column state to be applied on the grid
 *
 * The are some fields that are not required to be applied on column state and
 * if they they are applied will cause inconsistencies on the grid
 *
 * - aggFunc is applied by default by the grid based on columns defs, if applied by column
 *   has the risk of wrong calculations when column state is outtage with column defs
 *
 * @param  {Array} columnState
 * @return {Array}
 */
const cleanColumnStateToApplyOnGrid = columnState => columnState.map(c => omit(c, ['aggFunc']));

export { cleanColumnStateToApplyOnGrid };
