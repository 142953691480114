import React from 'react';
import styled from 'styled-components';

import { DESKTOP_BREAKPOINT } from 'design-system/hooks/useDeviceType';

export default ({ children }) => <Wrapper>{children}</Wrapper>;

const Wrapper = styled.div`
  @media screen and (max-width: ${DESKTOP_BREAKPOINT}px) {
    display: none;
  }
`;
