/**
 * PageLink Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import styled from 'styled-components';

// eslint-disable-next-line jsx-a11y/anchor-has-content
export default styled(props => <a onClick={e => e.stopPropagation()} {...props} />)`
&&&& {
  ${({ ellipsis }) =>
    !!ellipsis &&
    `
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}}


  color: ${({ color, theme }) => color || theme.palette.primary};
  text-decoration: none;
  cursor: pointer;

    ${({ disabled }) =>
      disabled &&
      `
      color: #ccc;
      cursor: inital;
    `}

    ${({ size }) =>
      size === 'medium' &&
      `
      font-size: 16px;
    `}

    ${({ size }) =>
      size === 'small' &&
      `
      font-size: 12px;
    `}
  }

}
`;
