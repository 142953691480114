import { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'ramda';

import { getUsers } from 'store/users/selectors';
import { updateSharedViewWithoutModifyingState } from 'store/userViews/sharedViews.thunks';
import { deleteUserView } from 'store/userViews/actions';
import formatUserViews from 'utils/userViews/formatUserViews';
import makeShareUrl from 'utils/makeShareUrl';
import useSelectView from 'hooks/userViews/useSelectView';

import { EDIT_LABEL, SHARE_LABEL } from 'constants/userViews';

import useUpdateViewDialogUI from 'hooks/userViews/useUpdateViewDialogUI';
import usePermissions from 'hooks/permissions/usePermissions';

/**
 * @function useUserViewsList
 *
 * Returns all the necessary logic to render a Views list.
 *
 * @return {array} views - Array with all the views to be displayed
 * @return {Boolean} hasSearch - Flag based on wether this listing has search or not
 */

const useUserViewsList = (views, hasSearch) => {
  const dispatch = useDispatch();

  const [shareUrl, setShareUrl] = useState('');
  const [viewOptionsAnchorEl, setViewOptionsAnchorEl] = useState(null);

  const permissions = usePermissions();

  const users = useSelector(getUsers);

  const { selectedView, setSelectedView, handleFavoriteView, handleSelectView, handleViewOptions } =
    useSelectView(setViewOptionsAnchorEl);

  const {
    updateDialogProps,
    openUpdateDialog: openUpdateDialogUI,
    closeUpdateDialog: closeUpdateDialogUI,
    openShareDialog: openShareDialogUI,
  } = useUpdateViewDialogUI(selectedView, permissions);

  const formattedViews = useMemo(() => formatUserViews(views, users), [views, users]);
  const saveDialogViews = useMemo(() => views.filter(v => v.isOwner), [views]);

  const hasNoViews = !hasSearch && isEmpty(formattedViews);
  const searchHasNoResults = hasSearch && isEmpty(formattedViews);

  const closeUpdateDialog = () => {
    setSelectedView(null);
    closeUpdateDialogUI();
  };

  const handleShareView = view => {
    const url = makeShareUrl(window.location.origin, view);

    setShareUrl(url);
    setSelectedView(view.id);
    openShareDialogUI();
  };

  const handleEditView = view => {
    const url = makeShareUrl(window.location.origin, view);

    setShareUrl(url);
    setSelectedView(view.id);
    openUpdateDialogUI();
  };

  const handleSaveView = share => (name, description) => {
    dispatch(updateSharedViewWithoutModifyingState({ ...selectedView, name, description }));

    if (share) {
      openShareDialogUI();
    }
  };

  const handleDeleteView = viewId => {
    dispatch(deleteUserView(viewId));
    closeUpdateDialog();
  };

  const onSelectViewOption = option => {
    const { label } = option;

    const optionFnMapper = {
      [EDIT_LABEL]: handleEditView,
      [SHARE_LABEL]: handleShareView,
    };

    const optionFn = optionFnMapper[label];

    if (!optionFn) return;

    optionFn(selectedView);
  };

  return {
    viewsList: formattedViews,
    hasNoViews,
    searchHasNoResults,
    handleFavoriteView,
    handleShareView,
    handleSaveView: handleSaveView(false),
    handleSaveViewAndShare: handleSaveView(true),
    handleViewOptions,
    handleSelectView,
    handleDeleteView,
    viewOptionsAnchorEl,
    setViewOptionsAnchorEl,
    selectedView,
    onSelectViewOption,
    shareUrl,
    saveDialogViews,
    dialogProps: updateDialogProps,
    closeDialog: closeUpdateDialog,
  };
};

export default useUserViewsList;
