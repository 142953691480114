import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';

import {
  FETCH_CUSTOMERS_FULFILLED,
  CREATE_CUSTOMER_FULFILLED,
  DELETE_CUSTOMER_FULFILLED,
  ADD_CUSTOMER_WITHOUT_SAVE,
  REMOVE_UNSAVED_CUSTOMERS,
  MERGE_CUSTOMERS_FULFILLED,
  UNDO_BULK_DELETE_CUSTOMERS_FULFILLED,
  UNDO_CREATE_CUSTOMERS_FULFILLED,
  UNDO_UPDATE_CUSTOMERS_FULFILLED,
  BULK_DELETE_CUSTOMERS_FULFILLED,
  UPDATE_CUSTOMERS_FULFILLED,
  CREATE_CUSTOMERS_FULFILLED,
  BULK_DELETE_CUSTOMER_ROADMAP_FULFILLED,
  CREATE_CUSTOMER_ROADMAP_FULFILLED,
  DELETE_CUSTOMER_ROADMAP_FULFILLED,
  UPDATE_CUSTOMER_BY_ID_FULFILLED,
  IMPORT_CUSTOMERS_FULFILLED,
  UPDATE_CUSTOMERS_SEARCH_TEXT,
  UPDATE_SETTINGS_GROUP_BY_OPTION,
} from './types';

import {
  SALESFORCE_CUSTOMER_COUNT_FULFILLED,
  SALESFORCE_CUSTOMER_COUNT_RESET,
  SALESFORCE_IMPORT_CUSTOMERS_FULFILLED,
  SALESFORCE_IMPORT_CUSTOMERS_REJECTED,
  SALESFORCE_IMPORT_CUSTOMERS_ERROR_RESET,
} from 'store/integrations/types';

import { Map, fromJS } from 'immutable';
import updateRows from 'store/utils/updateRows';
import addRows from 'store/utils/addRows';
import upsertListItem from 'store/utils/upsertListItem';

export default (state = new Map(), action) => {
  switch (action.type) {
    case FETCH_CUSTOMERS_FULFILLED: {
      const byId = state.get('byId') || {};

      action.payload.data.forEach(customer => {
        byId[customer.id] = customer;
      });

      return state.merge({
        byId,
        rows: fromJS(action.payload.data),
        lastCallsDate: moment().valueOf(),
      });
    }
    case CREATE_CUSTOMER_FULFILLED: {
      return state.set(
        'rows',
        state
          .get('rows')
          .filter(customer => customer.get('id') && customer.get('id') !== action.payload.id)
          .unshift(fromJS(action.payload)),
      );
    }
    case UPDATE_CUSTOMER_BY_ID_FULFILLED: {
      const byId = state.get('byId') || new Map();

      const updatedRows = updateRows(state.get('rows'), action.payload);

      return state.set('byId', byId.set(action.payload.id, action.payload)).set('rows', updatedRows);
    }
    case DELETE_CUSTOMER_FULFILLED: {
      return state.set(
        'rows',
        state.get('rows').filter(customer => customer.get('id') !== action.payload),
      );
    }
    case ADD_CUSTOMER_WITHOUT_SAVE: {
      return state.set(
        'rows',
        state
          .get('rows')
          .filter(tag => tag.get('id'))
          .unshift(fromJS(action.customer || {})),
      );
    }
    case REMOVE_UNSAVED_CUSTOMERS: {
      return state.set(
        'rows',
        state.get('rows').filter(customer => {
          return customer.get('id');
        }),
      );
    }
    case MERGE_CUSTOMERS_FULFILLED: {
      if (!action.payload) {
        return state;
      }

      return state.set(
        'rows',
        state.get('rows').filter(customer => !action.payload.includes(customer.get('id'))),
      );
    }
    case CREATE_CUSTOMERS_FULFILLED: {
      const customers = addRows(state.get('rows'), action.payload);

      state = state.set('rows', customers);
      state = state.set(
        'lastActionIds',
        action.payload.map(customer => customer.id),
      );

      return state;
    }
    case UPDATE_CUSTOMERS_FULFILLED: {
      const customers = updateRows(state.get('rows'), action.payload);

      state = state.set('rows', customers);
      state = state.set(
        'lastActionIds',
        action.payload.map(customer => customer.id),
      );

      return state;
    }
    case BULK_DELETE_CUSTOMERS_FULFILLED: {
      const customers = state.get('rows').filter(customer => !action.payload.includes(String(customer.get('id'))));

      state = state.set('rows', customers);
      state = state.set('lastActionIds', action.payload);

      return state;
    }
    case UNDO_UPDATE_CUSTOMERS_FULFILLED: {
      const customers = updateRows(state.get('rows'), action.payload);

      state = state.set('rows', customers);
      state = state.set(
        'lastActionIds',
        action.payload.map(customer => customer.id),
      );

      return state;
    }
    case UNDO_CREATE_CUSTOMERS_FULFILLED: {
      const customers = state.get('rows').filter(customer => state.get('lastActionIds').indexOf(customer.get('id')) === -1);

      state = state.set('rows', customers);
      state = state.set('lastActionIds', null);

      return state;
    }
    case UNDO_BULK_DELETE_CUSTOMERS_FULFILLED: {
      const customers = updateRows(state.get('rows'), action.payload);

      state = state.set('rows', customers);
      state = state.set('lastActionIds', null);

      return state;
    }
    case IMPORT_CUSTOMERS_FULFILLED: {
      const customers = updateRows(
        state.get('rows'),
        action.payload.reduce((customers, cr) => {
          return [...customers, ...(cr.customers || [])];
        }, []),
      );

      state = state.set('rows', customers);

      return state;
    }
    case CREATE_CUSTOMER_ROADMAP_FULFILLED:
      const customers = cloneDeep(state.get('rows').toJS() || []);
      const customer = customers.find(obj => obj.id === action.payload.customer_id);

      customer.customer_roadmaps = [...(customer.customer_roadmaps || []), action.payload];
      customer.updated_at = action.payload.updated_at;

      const updatedCustomers = upsertListItem(customer, customers);

      return state.set('rows', fromJS(updatedCustomers));
    case DELETE_CUSTOMER_ROADMAP_FULFILLED:
      const existing = cloneDeep(state.get('rows').toJS() || []);
      const { id: customerId, customerRoadmaps } = action.payload;
      const parent = existing.find(obj => obj.id === parseInt(customerId));

      parent.customer_roadmaps = customerRoadmaps || [];

      const updated = upsertListItem(parent, existing);

      return state.set('rows', fromJS(updated));
    case SALESFORCE_CUSTOMER_COUNT_FULFILLED:
      return state.set('totalSalesforceCustomers', action.payload);
    case SALESFORCE_CUSTOMER_COUNT_RESET:
    case SALESFORCE_IMPORT_CUSTOMERS_FULFILLED:
      return state.set('totalSalesforceCustomers', 0);
    case SALESFORCE_IMPORT_CUSTOMERS_ERROR_RESET:
    case SALESFORCE_IMPORT_CUSTOMERS_REJECTED:
      state.set('totalSalesforceCustomers', 0);
      return state.set('importSalesforceCustomersError', action.payload);
    case BULK_DELETE_CUSTOMER_ROADMAP_FULFILLED:
      const existingCustomers = cloneDeep(state.get('rows').toJS() || []);
      const updatedObjs = existingCustomers.map(customer => {
        if (customer.id === action.payload) {
          return {
            ...customer,
            customer_roadmaps: [],
          };
        }

        return customer;
      });

      return state.set('rows', fromJS(updatedObjs));
    // case FETCH_CUSTOMER_REQUESTS_FULFILLED: {
    //   const newCustomersById = action.payload.reduce((customersById, { customers }) => {
    //     if (!customers) return customersById;

    //     return customers.reduce((customersById, customer) => {
    //       if (customersById[customer.id]) return customersById;

    //       return { ...customersById, [customer.id]: customer };
    //     }, customersById);
    //   }, state.get('byId') || {});

    //   state = state.set('byId', newCustomersById);

    //   return state;
    // }

    case UPDATE_CUSTOMERS_SEARCH_TEXT: {
      return state.set('searchText', action.payload);
    }
    case UPDATE_SETTINGS_GROUP_BY_OPTION: {
      return state.set('settingsGroupByOption', action.payload);
    }
    default:
      return state;
  }
};
