import axios from 'axios';

import { createThunk } from 'utils/store/thunk';
import { TRANSACTION_PAGES_PROJECTS_LIMIT } from 'constants';
import {
  CREATE_ROADMAP_HISTORY_SNAPSHOT,
  DELETE_ROADMAP_HISTORY_SNAPSHOT,
  FETCH_ROADMAP_HISTORY_PROJECTS,
  FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW,
  SELECT_VIEW_SNAP_TO_COMPARE,
  REMOVE_SELECTED_SNAP_TO_COMPARE,
  UPDATE_ROADMAP_HISTORY_SNAPSHOT,
  FETCH_ROADMAP_HISTORY_COMPARE_DATA,
} from './types';
import {
  selectCompareUuid,
  selectCurrentViewId,
  selectSelectedSnapshotsIdsToCompare,
} from 'features/RoadmapHistory/store/selectors';

const paginationAttributes = {
  from: 0,
  to: TRANSACTION_PAGES_PROJECTS_LIMIT,
};

const createRoadmapHistorySnapshot = snapshotHistoryData =>
  createThunk(
    CREATE_ROADMAP_HISTORY_SNAPSHOT,
    axios.post(`/api/v1/roadmap-history/snapshots`, snapshotHistoryData).then(res => res.data),
    snapshotHistoryData,
  );

const fetchViewProjectsForSnapComparison = userViewId =>
  createThunk(
    FETCH_ROADMAP_HISTORY_PROJECTS,
    axios.get(`/api/userViews/${userViewId}/projects`).then(res => res.data),
    { userViewId },
  );

const selectViewSnapToCompare = snapId => (dispatch, getState) => {
  const state = getState();
  const compareUuid = selectCompareUuid(state);
  const userViewId = selectCurrentViewId(state);
  const selectedSnapIds = selectSelectedSnapshotsIdsToCompare(state);
  const snapIds = [...selectedSnapIds, snapId];

  dispatch(
    createThunk(SELECT_VIEW_SNAP_TO_COMPARE, loadCompareRoadmapHistoryDataApiCall(snapIds, compareUuid), { userViewId, snapId }),
  );
};

const removeSelectedSnapToCompare = snapId => (dispatch, getState) => {
  const state = getState();
  const compareUuid = selectCompareUuid(state);
  const userViewId = selectCurrentViewId(state);
  const selectedSnapIds = selectSelectedSnapshotsIdsToCompare(state);
  const snapIds = selectedSnapIds.filter(id => id !== snapId);

  dispatch(
    createThunk(REMOVE_SELECTED_SNAP_TO_COMPARE, loadCompareRoadmapHistoryDataApiCall(snapIds, compareUuid), {
      userViewId,
      snapId,
    }),
  );
};

const updateRoadmapHistorySnapshot = (historySnapshotId, data) =>
  createThunk(
    UPDATE_ROADMAP_HISTORY_SNAPSHOT,
    axios.put(`/api/v1/roadmap-history/snapshots/${historySnapshotId}`, data).then(res => res.data),
    data,
  );

const fetchRoadmapHistorySnapshotsForUserView = userViewId =>
  createThunk(
    FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW,
    axios.get(`/api/v1/roadmap-history/snapshots/userView/${userViewId}`).then(res => res.data),
    { userViewId },
  );

const deleteRoadmapHistorySnap = (historySnapId, meta = {}) =>
  createThunk(
    DELETE_ROADMAP_HISTORY_SNAPSHOT,
    axios.delete(`/api/v1/roadmap-history/snapshots/${historySnapId}`).then(res => res.data),
    meta,
  );

const loadCompareRoadmapHistoryData = (userViewId, selectedSnapIds = []) =>
  createThunk(FETCH_ROADMAP_HISTORY_COMPARE_DATA, loadCompareRoadmapHistoryDataApiCall(selectedSnapIds), { userViewId });

const loadCompareRoadmapHistoryDataApiCall = (selectedSnapIds, compareUuid) =>
  axios
    .get(`/api/v1/roadmap-history/snapshots/diff/compare`, {
      params: {
        selectedSnaps: selectedSnapIds.join(','),
        compareUuid,
        ...paginationAttributes,
      },
    })
    .then(res => res.data);

export {
  createRoadmapHistorySnapshot,
  fetchViewProjectsForSnapComparison,
  selectViewSnapToCompare,
  removeSelectedSnapToCompare,
  updateRoadmapHistorySnapshot,
  fetchRoadmapHistorySnapshotsForUserView,
  deleteRoadmapHistorySnap,
  loadCompareRoadmapHistoryData,
};
