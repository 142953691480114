import { toast } from 'react-toastify';

import getSystemFieldName from 'utils/getSystemFieldName';
import { DUPLICATED_ERROR_CODE } from 'config';
import { METRIC, METRICS } from 'constants/common';
import { getDefaultErrorMessage, getDuplicatedErrorMessage } from './errorMessages';

const METRICS_SETTINGS_URL = `/settings/${METRICS}`;

export default (err, title, systemFieldsNames) => {
  const { data } = err?.response;
  const isDuplicatedRecordError = data && data.error_code === DUPLICATED_ERROR_CODE;

  const typeSystemFieldName = getSystemFieldName(METRIC, systemFieldsNames, false);

  const errorMessage = isDuplicatedRecordError
    ? getDuplicatedErrorMessage(typeSystemFieldName, title, METRICS_SETTINGS_URL)
    : getDefaultErrorMessage(typeSystemFieldName);

  toast(errorMessage);

  throw err;
};
