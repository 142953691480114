import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import CollapsePanel from 'components/CollapsePanel';

// We can leverage the same component even if it's not an integration
import DisconnectIntegration from '../../components/DisconnectIntegration/DisconnectIntegration';

const DRAGONBOAT = 'Dragonboat';

const ChildDragonDetails = ({
  selectedChildDragon,
  isAdmin,
  handleRemoveChildDragonAccess,
  onRemoveOrgIntegration,
  onClickBack,
}) => {
  const onDisconnectClick = async () =>
    handleRemoveChildDragonAccess(selectedChildDragon.child_organization_id).then(() => onRemoveOrgIntegration());

  return (
    <Wrapper>
      {(selectedChildDragon?.child_organization_url || selectedChildDragon?.dragonAccountName) && (
        <IntegrationLink href={selectedChildDragon?.child_organization_url} target="_blank" rel="noreferrer">
          {selectedChildDragon?.accountName || selectedChildDragon?.child_organization_slug}
        </IntegrationLink>
      )}
      <CollapsePanel title={<TextDeprecated size="medium">Dragonboat Connection</TextDeprecated>}>
        <PanelContent>
          {isAdmin && <DisconnectIntegration integrationName={DRAGONBOAT} disconnect={onDisconnectClick} />}
        </PanelContent>
      </CollapsePanel>
      <ActionsWrapper>
        {onClickBack && (
          <Button color="primary" onClick={onClickBack}>
            Back
          </Button>
        )}
      </ActionsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const IntegrationLink = styled.a`
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: 16px;
`;

const PanelContent = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.text.lightGrey};
  padding-bottom: 16px;
`;

const ActionsWrapper = styled.div`
  &&&& {
    padding: 38px 0;
  }
`;

export default ChildDragonDetails;
