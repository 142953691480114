import axios from 'axios';

import throwRequestError from 'store/utils/throwRequestError';

export const apiUpdateCustomFieldById = async (customFieldId, update, enhanced = false) => {
  if (enhanced) {
    update = {
      ...update,
      data_enhanced: update.data,
      data: Object.entries(update.data || {}).reduce((acc, [key, value]) => ({ ...acc, [key]: value.title }), {}),
    };
  }

  const { data } = await axios
    .put(`/api/customFields/${customFieldId}`, update)
    .then(res => res.data)
    .catch(throwRequestError);

  return data;
};
