import React from 'react';
import styled, { css } from 'styled-components';
import { max } from 'lodash';
import useProgressBarValues from '../../hooks/useProgressBarValues';
import { formatNumberWithCommas } from 'src/design-system/utils/numbers';

const ProgressContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 24px;
  border-radius: 4px;

  background: ${props => props.theme.palette.newLayout.progressBarSquared.background.lightGray};

  height: 16px;
  border-radius: 2px;
`;
const ProgressBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 3px;
  height: 24px;
  width: ${props => props.value}%;
  max-width: 100%;

  justify-content: right;

  background: ${props =>
    props.theme.palette.newLayout.progressBarSquared.background[props.color] ||
    props.theme.palette.newLayout.progressBarSquared.background.gray};

  border-radius: 3px;
  height: 16px;
`;

const LabelBeforeSmallVersion = styled.span`
  ${({ value }) =>
    value <= 20 &&
    css`
      display: none;
    `}
  margin-right: 4px;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  font-size: ${props => props.theme.typography.fontSizeExtraSmall}px;
  color: ${props => props.color && ['undefined', 'red', 'green'].includes(props.color) && 'white'};
`;

const LabelAfterSmallVersion = styled.span`
  ${({ value }) =>
    value > 20 &&
    css`
      display: none;
    `}
  margin-left: 4px;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${props => props.theme.typography.fontSizeExtraSmall}px;
  color: ${props => props.color && ['undefined', 'red', 'green'].includes(props.color) && 'white'};
`;

const LabelPercentage = styled.span`
  display: none; // temp removal. how we present this info must be re-evaluated by product
  ${({ value }) =>
    value >= 75 &&
    css`
      display: none;
    `}
  margin-right: 4px;
  margin-left: 10px;
  position: absolute;
  right: 0;
  font-size: ${props => props.theme.typography.fontSizeExtraSmall}px;
`;

export default ({ actualValue, actualValueLabel, minValue, minValueLabel, maxValue, maxValueLabel, formatValues, ...props }) => {
  const { percentageValue, formattedActualValue, color } = useProgressBarValues({
    actualValue,
    actualValueLabel,
    minValue,
    minValueLabel,
    maxValue,
    maxValueLabel,
    formatValues,
    color: props.color,
  });

  const rightValue = Number.parseFloat(max([percentageValue, 0]) || 0).toFixed(0) || 0;

  return (
    <ProgressContainer className={props.className}>
      <ProgressBarContainer>
        {actualValue > 0 && (
          <ProgressBar value={percentageValue} color={color}>
            <LabelBeforeSmallVersion value={percentageValue} color={color}>
              {formattedActualValue}
            </LabelBeforeSmallVersion>
          </ProgressBar>
        )}
        <LabelAfterSmallVersion value={percentageValue}>{formattedActualValue}</LabelAfterSmallVersion>
        {!!rightValue && (
          <LabelPercentage value={percentageValue}>
            {formatNumberWithCommas(rightValue) || formatNumberWithCommas(rightValue)}%
          </LabelPercentage>
        )}
      </ProgressBarContainer>
    </ProgressContainer>
  );
};
