import { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getChartsLayout, isInEditModeChartsLayout } from 'store/metrics/selectors';
import { updateChartsLayout, updateChartsLayoutEditToggle } from 'store/metrics';

import { mapEntitiesToLayout } from 'utils/reactGridLayout';

/**
 * Hook that will handle generate default layout, layout changes and filtering by local search
 * @param filteredMetrics
 * @param searchText
 * @return Object
 */
const useMetricsChartLayout = ({ filteredMetrics, searchText }) => {
  const dispatch = useDispatch();

  const filterByLocalSearch = Boolean(searchText);
  const chartsLayout = useSelector(getChartsLayout);
  const inEditMode = useSelector(isInEditModeChartsLayout);
  const layout = useMemo(() => chartsLayout || mapEntitiesToLayout(filteredMetrics), [chartsLayout, filteredMetrics]);

  const [layoutBasedOnLocalSearch, setLayoutBasedOnLocalSearch] = useState(layout);

  useEffect(() => {
    const newSearchLayout = layout.filter(chart => filteredMetrics.some(metric => chart.i === metric.id.toString()));

    setLayoutBasedOnLocalSearch(newSearchLayout);
  }, [layout, filterByLocalSearch, filteredMetrics]);

  const layoutToUse = useMemo(
    () => (filterByLocalSearch ? layoutBasedOnLocalSearch : layout),
    [filterByLocalSearch, layoutBasedOnLocalSearch, layout],
  );

  const handleLayoutChange = newLayout => {
    if (filterByLocalSearch) {
      setLayoutBasedOnLocalSearch(newLayout);
    } else if (inEditMode) {
      dispatch(updateChartsLayout(newLayout));
    }
  };

  const handleDelete = metricId => {
    const newLayout = layout.filter(chart => chart.i !== metricId.toString());

    dispatch(updateChartsLayout(newLayout));
  };

  const toggleEditMode = useCallback(
    () => dispatch(updateChartsLayoutEditToggle(!inEditMode)),
    [updateChartsLayoutEditToggle, inEditMode],
  );

  return {
    layout: layoutToUse,
    handleLayoutChange,
    handleDelete,
    toggleEditMode,
    inEditMode,
  };
};

export default useMetricsChartLayout;
