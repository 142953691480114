/* eslint-disable */
/**
 * UnfilterIcon Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function jiraIcon(props) {
  const height = Math.ceil((props?.style?.height || 16) * 0.9375);
  return (
    <SvgIcon {...props} style={{ ...props.style, height }} width="256px" height="256px" viewBox="0 0 23 23">
      <circle className="a" cx="5.5" cy="5.5" r="5.5" />
      <circle className="a" cx="5.5" cy="5.5" r="5.5" transform="translate(12)" />
      <circle className="a" cx="5.5" cy="5.5" r="5.5" transform="translate(0 12)" />
      <circle className="a" cx="5.5" cy="5.5" r="5.5" transform="translate(12 12)" />
    </SvgIcon>
  );
}
