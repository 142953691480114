import { identity } from 'ramda';

export class RoundMode {
  /** Ceils to the nearest integer */
  static CEIL = new RoundMode(Math.ceil);
  /** Rounds to the nearest integer */
  static ROUND = new RoundMode(Math.round);
  /** Do not round */
  static NONE = new RoundMode(identity);

  /**
   * Creates RoundMode with `rounder` function.
   * @param {Function} rounder
   */
  constructor(rounder) {
    /** @type Function */
    this.rounder = rounder;
  }
}
