/* eslint-disable */
/**
 * FilterIcon Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function filterIcon(props) {
  return (
    <SvgIcon {...props} style={{ width: props.width || 20, height: props.height || 20 }} viewBox="0 0 30 30">
      <path
        d="M27.4061994,2.7338695 C27.2407694,2.3772283 26.8832981,2.14905411 26.4901335,2.14905411 L3.50987209,2.14905411 C3.11675623,2.14905411 2.75928495,2.37717947 2.59380617,2.7338695 C2.42832739,3.09055952 2.48491928,3.51077504 2.73877711,3.81101966 L11.6921117,14.3983413 L11.6921117,24.5430793 C11.6921117,24.9255506 11.9082254,25.2752582 12.2503646,25.4463522 L16.8464169,27.7443539 C16.9889464,27.8156431 17.143683,27.8509459 17.2980289,27.8509459 C17.4826972,27.8509459 17.6667795,27.8003599 17.8289868,27.7001156 C18.1266923,27.5160821 18.3079426,27.1910327 18.3079426,26.8410322 L18.3079426,14.3983413 L27.2612773,3.81101966 C27.5151351,3.51077504 27.5716293,3.09051069 27.4061994,2.7338695 Z M16.5268851,13.3764646 C16.3727345,13.5587892 16.288164,13.7898442 16.288164,14.0285653 L16.288164,25.207094 L13.7118415,23.9189572 L13.7118415,14.0286141 C13.7118415,13.7898442 13.6272223,13.558838 13.4730716,13.3765135 L5.6864869,4.1687839 L24.3135675,4.1687839 L16.5268851,13.3764646 Z"
        id="Combined-Shape"
      ></path>
    </SvgIcon>
  );
}
