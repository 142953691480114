import { UPDATE_PROJECT_DEBOUNCED_FULFILLED, UPDATE_PROJECT_FULFILLED, UPDATE_PROJECT_PENDING } from 'store/projects';

let timer;
let buffer = [];

const BATCH_TIMEOUT = 1000; // 1 second

const debounce = callback => {
  if (timer) clearTimeout(timer);

  timer = setTimeout(() => {
    callback();
    clearTimeout(timer);
    buffer = [];
  }, BATCH_TIMEOUT);
};

// if a PENDING action is triggered debounce the dispatch of FULFILLED of the buffered updates to wait for completion
const debouncePendingAction = (action, dispatch, next) => {
  debounce(() => {
    if (buffer?.length)
      dispatch({ type: UPDATE_PROJECT_DEBOUNCED_FULFILLED, payload: buffer.map(eachAction => eachAction.payload) });
  });

  return next(action);
};

// if a FULFILLED action is triggered debounce the dispatch of itself and other buffered updates to wait for any new update
const debounceFulfilledAction = (action, dispatch, next) => {
  buffer.push(action);

  debounce(() => {
    dispatch({ type: UPDATE_PROJECT_DEBOUNCED_FULFILLED, payload: buffer.map(eachAction => eachAction.payload) });
  });

  next({ ...action, meta: { batch: true } });
};

export default ({ dispatch }) =>
  next =>
  action => {
    if (action.type === UPDATE_PROJECT_PENDING) {
      if (!action.meta?.batch) {
        return next(action);
      }

      return debouncePendingAction(action, dispatch, next);
    }

    if (action.type === UPDATE_PROJECT_FULFILLED) {
      if (!action.meta?.batch) {
        return next(action);
      }

      return debounceFulfilledAction(action, dispatch, next);
    }

    next(action);
  };
