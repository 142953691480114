import { GET_PROJECT_FIELDS_CONFIGURATION_FULFILLED, UPDATE_PROJECT_FIELDS_CONFIGURATION_FULFILLED } from '../types';

const initialState = {};

const projectFieldsInheritReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_FIELDS_CONFIGURATION_FULFILLED: {
      return {
        ...state,
        [action?.meta?.layer]: action.payload?.data?.inherit || {},
      };
    }

    case UPDATE_PROJECT_FIELDS_CONFIGURATION_FULFILLED: {
      return {
        ...state,
        [action?.meta?.layer]: action.payload?.data?.inherit,
      };
    }

    default: {
      return state;
    }
  }
};

export { projectFieldsInheritReducer, initialState };

export default projectFieldsInheritReducer;
