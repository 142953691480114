import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SettingsIcon from '@material-ui/icons/Settings';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import Checkbox from 'design-system/atoms/Checkbox/index';

import AllocattionReportPreferences from 'containers/AllocattionReportPreferences';
import getSystemFieldName from 'utils/getSystemFieldName';

export default ({
  isOpen,
  onCancel,
  onOpen,
  onApply,
  groupByOptions,
  lsState,
  updateLsState,
  includeEmptyProjects,
  onChangeIncludeEmptyProjects,
  systemFields,
  hideIcon,
  columnsOptions,
  hideArchivedData,
  onChangeHideArchivedData,
}) => {
  const hideArchivedLabel = useMemo(
    () => `Hide Archived ${lsState?.dataType ? getSystemFieldName(lsState.dataType?.title, systemFields, true) : 'Metadata'}`,
    [lsState, systemFields],
  );

  return (
    <Fragment>
      {!hideIcon && (
        <ButtonIcon title="Display preferences" onClick={onOpen}>
          <SettingsIcon />
        </ButtonIcon>
      )}
      <Dialog open={isOpen} maxWidth="sm" fullWidth>
        <DialogTitle>Display Preferences</DialogTitle>
        <DialogContent>
          <AllocattionReportPreferences
            lsState={lsState}
            updateLsState={updateLsState}
            groupOptions={groupByOptions}
            columnsOptions={columnsOptions}
            showDuration
            showSumBy
          />
          <PreferenceGroup>
            <Preference row>
              <RadioLabel
                control={
                  <Checkbox
                    checked={!!includeEmptyProjects}
                    color="primary"
                    onChange={e => onChangeIncludeEmptyProjects(e.target.checked)}
                  />
                }
                label={`Include ${getSystemFieldName('idea', systemFields, true)} without estimates or tasks`}
              />
            </Preference>
            <Preference row>
              <RadioLabel
                control={
                  <Checkbox
                    checked={!!hideArchivedData}
                    color="primary"
                    onChange={e => onChangeHideArchivedData(e.target.checked)}
                  />
                }
                label={hideArchivedLabel}
              />
            </Preference>
          </PreferenceGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onApply} color="primary">
            Apply
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const PreferenceGroup = styled.div`
  margin: 12px 0;
`;

const Preference = styled(FormGroup)`
  &&&& {
    margin: 0 6px;
  }
`;

const RadioLabel = styled(FormControlLabel)`
  &&&& {
    flex: 1 1 auto;
  }
`;
