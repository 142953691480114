import React from 'react';
import styled from 'styled-components';

import theme from 'design-system/theme';
import ButtonV2 from 'design-system/atoms/ButtonV2/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


const DEFAULT_BACKGROUND_COLOR = '#b5b5e4';

const setButtonHref = (origin, template) => `${origin}/${template.path}?template=${template.id}`;

export default ({ template, handleSelectTemplate }) => {
  return (
    <Wrapper>
      <ImageContainer backgroundColor={template.background_color || DEFAULT_BACKGROUND_COLOR}>
        <Image src={template.image_url} alt={template.name} />
      </ImageContainer>
      <Content>
        <Title color={theme.palette.text.primary}>{template.name}</Title>
        <StyledText size="small" color={theme.palette.newLayout.text.siroccoGrey}>
          {template.description}
        </StyledText>
        <TemplateButtons>
          <LearnLabel
            fontSize={theme.typography.fontSize}
            color={theme.palette.newLayout.background.disable}
            background="transparent"
            id={`learn-more-${template.url_identifier}`}
          >
            Learn more
          </LearnLabel>

          <TryButton
            fontSize={theme.typography.fontSize}
            color={theme.palette.background.primary}
            href={setButtonHref(window.location.origin, template)}
            background="transparent"
            onClick={e => {
              e.preventDefault();
              handleSelectTemplate(template);
            }}
          >
            Try it
          </TryButton>
        </TemplateButtons>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 275px;
  background: ${({ theme }) => theme.palette.white};
  border: ${({ theme }) => `1px solid ${theme.palette.border.mercury}`};
  border-radius: ${({ theme }) => `${theme.shape.borderRadiusRegular}px`};
  box-shadow: 0px 12px 16px rgba(60, 128, 209, 0.09);
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 130px;
  background: ${({ backgroundColor }) => backgroundColor};
`;

const Image = styled.img`
  width: 185px;
`;

const Content = styled.div`
  padding: 16px 16px 5px;
`;

const Title = styled(TextDeprecated)`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

const StyledText = styled(TextDeprecated)`
  &&&& {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: inherit;
    margin-top: 3px;
  }
`;

const TemplateButtons = styled.div`
  margin-top: 16px;
  margin-right: -15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LearnLabel = styled.span`
  &&&&& {
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    font-size: ${({ theme }) => theme.typography.fontSize};
    color: ${({ theme }) => theme.palette.newLayout.background.disable};
  }
`;

const TryButton = styled(ButtonV2)`
  &&&&& {
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  }
`;
