import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REQUESTOR_USER, EXTERNAL_REQUESTOR } from '@dragonboat/permissions';
import { FeatureFlags } from '@dragonboat/config';

import useApplicationRoutes from 'hooks/useApplicationRoutes';
import useSystemFields from 'hooks/useSystemFields';
import { getCurrentUser } from 'store/login/selectors';
import { getOrganization, getHasAdvancedMetricReporting } from 'store/organization/selectors';
import hideDashboardHome from 'utils/hideDashboardHome';
import { organizationHasDashboardsEnabled } from 'utils/organizations/dashboards';
import { getPathWithoutLeadingSlash } from 'utils/index';

import { getModulePages } from './modulesPages';
import useFeatureFlags from 'hooks/useFeatureFlags';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES, PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';
import { PORTFOLIO_CANVAS_PAGE, GOALS_CANVAS_PAGE, METRICS_CANVAS_PAGE } from 'constants/filters';
import { isProjectsFlatSearchActive } from 'utils/grid';

export default (page, history, lastPageViewProp, setLastPageView) => {
  const dispatch = useDispatch();

  const [pages] = useApplicationRoutes();
  const [getSystemFieldName] = useSystemFields();
  const { canView } = usePermissions();

  const currentUser = useSelector(getCurrentUser);
  const organization = useSelector(getOrganization);

  const hideDashboardHomePage = hideDashboardHome(organization);
  const isNotRequestor = ![REQUESTOR_USER, EXTERNAL_REQUESTOR].some(role => String(role) === String(currentUser.role_id));
  const isDashboardsEnabled = organizationHasDashboardsEnabled(organization);
  const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);
  const hasProgressReport = useFeatureFlags([FeatureFlags.HAS_PROGRESS_REPORT]);
  const hasRequestsAnalyzePage = useFeatureFlags([FeatureFlags.HAS_REQUESTS_ANALYZE_PAGE]);
  const hasPortfolioCanvas = canView(PERMISSION_PAGES[PORTFOLIO_CANVAS_PAGE]);
  const hasGoalsCanvas = canView(PERMISSION_PAGES[GOALS_CANVAS_PAGE]);
  const hasMetricsCanvas = canView(PERMISSION_PAGES[METRICS_CANVAS_PAGE]);
  const hasMetricsAllocation = canView(PERMISSION_FEATURES.metricMoar);

  const lastView = useSelector(state => state.app[lastPageViewProp]);
  const path = window.location.pathname;
  const myLastView = path === page && lastView ? `${page}/${lastView}` : path;

  const setSelectedPage = path => {
    history.push(`/${path}`);
    const selectedPage = path.split('/')[1];

    if (setLastPageView) {
      dispatch(setLastPageView(selectedPage || ''));
    }
  };

  const isFlatGridActive = isProjectsFlatSearchActive();

  const modulePages = getModulePages(
    page,
    getSystemFieldName,
    isNotRequestor,
    hideDashboardHomePage,
    isDashboardsEnabled,
    hasAdvancedMetricReporting,
    hasProgressReport,
    hasRequestsAnalyzePage,
    hasPortfolioCanvas,
    hasGoalsCanvas,
    hasMetricsCanvas,
    hasMetricsAllocation,
    canView,
    isFlatGridActive,
  ).filter(o => !!pages.find(p => p.path === o.path));

  const selectedPage = useMemo(() => getPathWithoutLeadingSlash(myLastView) || modulePages[0], [myLastView]);

  return {
    pages: modulePages,
    selectedPage,
    setSelectedPage,
  };
};
