import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { equals, filter, forEach, isEmpty, keys, not, pluck } from 'ramda';
import isFunction from 'lodash/isFunction';
import flatten from 'lodash/flatten';

import useProjectsLocalSearch from 'hooks/projects/useProjectsLocalSearch';
import useBaseProjectsForComparison from 'routes/RoadmapVersions/hooks/useBaseProjectsForComparison';

import { formatGroupedDate, selectScenarioProjectGroups } from 'store/roadmapVersions/groupSelectors';
import { getDisplayLayer } from 'store/filters/selectors';
import {
  getBaseCompareVersionSelected,
  getHideRowsWithSameData,
  getRoadmapVersionProjectsState,
  getSelectedRoadmapVersionsIdsToCompare,
} from 'store/roadmapVersions/selectors';
import { getSliceProp as getSummarySliceProp } from 'store/summary/selectors';

import { COMPARE_SCENARIOS_SUMMARY } from 'constants/summary';
import filterDuplicateProjectValuesByFieldIds from 'routes/RoadmapVersions/utils/filterDuplicateProjectValuesByFieldIds';

const exist = Boolean;

const isCommitted = p => p.committed;
const getIds = pluck('id');

const DATES_GROUP_TYPE = 'dates';

const filterProjectsForGrouping = (projects, projectFilter) => {
  if (!isEmpty(projects) && isFunction(projectFilter)) {
    return Object.entries(projects).reduce((acc, [key, innerProjects]) => {
      return {
        ...acc,
        [key]: innerProjects.filter(projectFilter),
      };
    }, {});
  }

  return projects;
};

const getProjectIdsByLayer = projectsByLayer => {
  return keys(projectsByLayer).reduce((idsByLayer, layer) => {
    return {
      ...idsByLayer,
      [layer]: getIds(projectsByLayer[layer]),
    };
  }, {});
};

const getFilteredProjectsToConsider = (
  projects,
  roadmapVersionProjects,
  config,
  selectedRoadmapVersionIds,
  considerOnlyCommitted,
) => {
  const { selectedGroup2 } = config;

  let fieldIds = considerOnlyCommitted ? ['committed'] : [];
  let fieldComparison = {};

  if (selectedGroup2) {
    const { field, type } = selectedGroup2;

    fieldIds = [...fieldIds, field];

    if (type === DATES_GROUP_TYPE) {
      fieldComparison = {
        [field]: (date1, date2) => {
          const formatDateValue = date => formatGroupedDate(date, selectedGroup2)?.id || date;

          return equals(formatDateValue(date1), formatDateValue(date2));
        },
      };
    }
  }

  const filteredByLayer = {};

  keys(projects).forEach(key => {
    filteredByLayer[key] = filterDuplicateProjectValuesByFieldIds(
      projects[key],
      selectedRoadmapVersionIds,
      roadmapVersionProjects,
      fieldIds,
      fieldComparison,
    );
  });

  return filteredByLayer;
};

const useScenarioVersionGroups = config => {
  const displayLayer = useSelector(getDisplayLayer);
  const baseCompareVersionSelected = useSelector(getBaseCompareVersionSelected);
  const { projects } = useBaseProjectsForComparison(displayLayer);
  const roadmapVersionsProjects = useSelector(getRoadmapVersionProjectsState);
  const hideRowsWithSameData = useSelector(getHideRowsWithSameData);
  const searchStr = useSelector(state => getSummarySliceProp('searchStr')(state, COMPARE_SCENARIOS_SUMMARY));
  const selectedRoadmapVersionsIds = useSelector(getSelectedRoadmapVersionsIdsToCompare);

  const isBasedOnPor = exist(baseCompareVersionSelected?.isPoRBase);

  // if we use plan of record (above the line), only committed is considered;
  const considerOnlyCommitted = isBasedOnPor && not(baseCompareVersionSelected?.includeBelowTheLine);

  const getProjectsToConsider = ({
    config,
    hideRowsWithSameData,
    considerOnlyCommitted,
    projects,
    roadmapVersionsProjects,
    selectedRoadmapVersionsIds,
  }) => {
    const filteredProjectsToConsider = hideRowsWithSameData
      ? getFilteredProjectsToConsider(
          projects,
          roadmapVersionsProjects,
          config,
          selectedRoadmapVersionsIds,
          considerOnlyCommitted,
        )
      : projects;

    const filteredProjectIdsToConsider = getProjectIdsByLayer(filteredProjectsToConsider);

    if (!considerOnlyCommitted) return { projectsToConsider: filteredProjectsToConsider, filteredProjectIdsToConsider };

    const result = {};

    const projectKeys = keys(filteredProjectsToConsider);

    forEach(k => {
      const layerProjects = filteredProjectsToConsider[k];

      result[k] = filter(isCommitted, layerProjects);
    }, projectKeys);

    return { projectsToConsider: result, filteredProjectIdsToConsider };
  };

  const allProjects = useMemo(() => {
    const { projectsToConsider, filteredProjectIdsToConsider } = getProjectsToConsider({
      config,
      hideRowsWithSameData,
      considerOnlyCommitted,
      projects,
      roadmapVersionsProjects,
      selectedRoadmapVersionsIds,
    });

    return selectedRoadmapVersionsIds.reduce(
      (acc, selectedVersionId) => {
        const versionProjects = roadmapVersionsProjects[selectedVersionId];
        const idsToConsider = filteredProjectIdsToConsider[displayLayer];

        if (versionProjects?.length) {
          const projectsToUse = versionProjects.filter(p => idsToConsider.includes(p.id));

          return { ...acc, [displayLayer]: [...acc[displayLayer], ...projectsToUse] };
        }
        return acc;
      },
      { ...projectsToConsider },
    );
  }, [
    config,
    displayLayer,
    hideRowsWithSameData,
    considerOnlyCommitted,
    projects,
    roadmapVersionsProjects,
    selectedRoadmapVersionsIds,
  ]);

  const projectsInAllLayers = flatten(Object.values(allProjects));
  const [filterBySearchString] = useProjectsLocalSearch(projectsInAllLayers, searchStr);

  const filteredProjects = useMemo(
    () => filterProjectsForGrouping(allProjects, filterBySearchString),
    [allProjects, filterBySearchString],
  );

  const projectGroups = useSelector(state =>
    selectScenarioProjectGroups(state, { ...config, customAllProjectsByLayer: filteredProjects }),
  );

  return projectGroups;
};

export default useScenarioVersionGroups;
