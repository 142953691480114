import React from 'react';
import styled from 'styled-components';

import InlineDateRange from 'design-system/organisms/InlineDateRange/InlineDateRange';


const MetricChartViewTimePeriodInput = ({ startDate, endDate, handleDateRangeChange, disabled = false }) => {
  return (
    <InputWrapper>
      <InlineDateRange
        label="Time Period:"
        startDate={startDate}
        endDate={endDate}
        onChange={handleDateRangeChange}
        disableEdit={disabled}
      />
    </InputWrapper>
  );
};

const InputWrapper = styled.div``;

export default MetricChartViewTimePeriodInput;
