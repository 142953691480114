import React, { useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import DndList from 'design-system/organisms/DndList/index';
import MultiFilterClearRow from 'design-system/atoms/MultiFilterClearRow/index';
import MultiFilterSearchRow from 'design-system/atoms/MultiFilterSearchRow/index';

import { searchMenuItems } from 'design-system/utils';
import theme from 'design-system/theme';

import cellEditorHoc from '../helpers/cellEditorHoc';

const handleOnClickAway = (clickEvent, { stopEditing }) => {
  const clickedEventTargetIsRenderedAsPortal = clickEvent.target.closest('[role="tooltip"]');

  if (!clickedEventTargetIsRenderedAsPortal) {
    stopEditing();
  }
};

const MultiLevelSelectCellEditor = cellEditorHoc(
  ({
    options,
    value,
    selectedItems,
    getSelectedItems,
    setSelectedItems,
    data,
    handleClear,
    anchorEl,
    width,
    api,
    stopEditing,
    forwardedRef,
    showTooltip,
    displaySearch = true,
    displayClearAll = true,
  }) => {
    const [items, setItems] = useState(options || []);
    const [search, setSearch] = useState('');

    useImperativeHandle(forwardedRef, () => ({
      getValue() {
        return value;
      },
    }));

    const handleSelectItems = (...params) => {
      setSelectedItems([...params], data);

      api.stopEditing();
    };

    const handleSearchChange = e => {
      const searchString = e.target.value;

      setSearch(searchString);
      setItems(searchString ? searchMenuItems(searchString, options) : options);
    };

    const handleClearItems = item => {
      handleClear(item);

      api.stopEditing();
    };

    const renderClear = () => {
      return (
        <ThemeProvider theme={theme}>
          <MultiFilterClearRow handleClear={handleClearItems} item={data} text="Clear All" />
        </ThemeProvider>
      );
    };

    const renderSearch = () => {
      return <MultiFilterSearchRow handleChange={handleSearchChange} search={search} />;
    };

    return (
      <MultiLevelSelectContainer>
        <StyledPopper open={!!anchorEl} anchorEl={anchorEl} placement="bottom-start">
          <Paper>
            <DndList
              checkable
              items={items}
              onSelectItem={handleSelectItems}
              renderClear={displayClearAll ? renderClear : null}
              renderSearch={displaySearch ? renderSearch : null}
              selectedItems={getSelectedItems ? getSelectedItems() : selectedItems}
              showIndeterminate
              width={width}
              showTooltip={showTooltip}
            />
          </Paper>
        </StyledPopper>
      </MultiLevelSelectContainer>
    );
  },
  handleOnClickAway,
);

export default MultiLevelSelectCellEditor;

const MultiLevelSelectContainer = styled.div`
  min-width: 200px;
`;

const StyledPopper = styled(Popper)`
  &&&& {
    z-index: ${theme.zIndex.gridPopover};
  }
`;

MultiLevelSelectCellEditor.propTypes = {
  anchorEl: PropTypes.any,
  data: PropTypes.object,
  options: PropTypes.array,
  selectedItems: PropTypes.object,
  setSelectedItems: PropTypes.func,
  value: PropTypes.array.isRequired,
  width: PropTypes.number,
};
