import { useSelector } from 'react-redux';

import { selectPortfolioOverviewSubRoadmapsData, selectIsloadingFetchPortfolioOverviewSubRoadmaps } from '../../store';

const usePortfolioOverviewSubRoadmaps = () => {
  const data = useSelector(selectPortfolioOverviewSubRoadmapsData);
  const isLoading = useSelector(selectIsloadingFetchPortfolioOverviewSubRoadmaps);

  return {
    data,
    isLoading,
  };
};

export default usePortfolioOverviewSubRoadmaps;
