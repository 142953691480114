const GRID_HEIGHT = 'calc(100vh - 160px)';
const PAGE_SIZE = 100;

const VISIBLE_COLUMNS_IDS = [
  'dash',
  'key',
  'title',
  'status',
  'priority',
  'roadmap_title',
  'customers',
  'tags',
  'owner_name',
  'created_by_name',
];

export { GRID_HEIGHT, PAGE_SIZE, VISIBLE_COLUMNS_IDS };
