import reduceReducers from 'reduce-reducers';

import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';
import { UPDATE_PROJECT_FULFILLED } from 'store/projects';

import {
  FETCH_TASKS,
  CREATE_TASK,
  UPDATE_TASK,
  BULK_UPDATE_TASKS,
  DELETE_TASK,
  CREATE_TASK_FULFILLED,
  FETCH_TASKS_FULFILLED,
  DELETE_TASK_FULFILLED,
  UPDATE_TASK_FULFILLED,
} from '../types';

const initialState = {
  tasks: [],
  isLoaded: false,
  justCreated: false,
  operations: bulkThunkInitialState([FETCH_TASKS, CREATE_TASK, UPDATE_TASK, BULK_UPDATE_TASKS, DELETE_TASK]),
};

const tasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TASK_FULFILLED:
      const task = action.payload.data;

      return {
        ...state,
        justCreated: true,
        tasks: [...state.tasks, task],
      };
    case DELETE_TASK_FULFILLED: {
      const taskId = +action.payload;

      return {
        ...state,
        tasks: state.tasks.filter(t => t.id !== taskId),
      };
    }
    case UPDATE_TASK_FULFILLED: {
      const { updatedTask } = action.meta;
      const actualTask = action.payload || updatedTask;
      let stateTasks = state.tasks;

      stateTasks = stateTasks.reduce((acc, task) => {
        if (task.id !== actualTask.id) return [...acc, task];

        return [...acc, actualTask];
      }, []);

      return {
        ...state,
        tasks: stateTasks,
      };
    }
    case FETCH_TASKS_FULFILLED:
      const { data } = action.payload;

      return {
        ...state,
        tasks: data,
      };
    case UPDATE_PROJECT_FULFILLED: {
      const project = action.payload.projectData || action.payload;
      const { tasks } = project;

      if (tasks) {
        const newTasks = tasks.filter(pt => !state.tasks.some(t => t.id === pt.id));

        if (newTasks.length) {
          return {
            ...state,
            tasks: [...state.tasks, ...newTasks],
          };
        }
      }

      return state;
    }
    default:
      return state;
  }
};

const operationsReducer = getThunksReducers([FETCH_TASKS, CREATE_TASK, UPDATE_TASK, BULK_UPDATE_TASKS, DELETE_TASK]);

const reducer = reduceReducers(initialState, tasksReducer, ...operationsReducer);

export default reducer;
