import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useSystemFields from 'hooks/useSystemFields';
import { INITIATIVE, BET } from 'constants/projects';
import setIdeasHierarchyPath from 'utils/setIdeasHierarchyPath';
import {
  getReleaseDashboardsProjects,
  isDashboardProjectsLoading,
  isDashboardProjectsUninitialized,
} from 'store/dashboards/selectors';
import useProjectGroups from 'hooks/useProjectGroups';
import useOrganizations from 'hooks/useOrganizations';
import useFetchProjectsForReleaseDashboard from 'hooks/useFetchProjectsForReleaseDashboard';
import { DEFAULT_GROUP_OPTION } from 'store/projects/helpers/groupOptions';
import { hideAllEmpty } from 'utils/projects/grouping';

const useDashboards = ({ projectsSubFilters, gridProps = {} }) => {
  const [getSystemFieldName] = useSystemFields();

  const defaultVisibleColumns = [
    {
      key: 'status_color',
      label: getSystemFieldName('status_color'),
    },
    {
      key: 'roadmapTitle',
      label: getSystemFieldName('roadmap'),
    },
    {
      key: 'progress',
      label: getSystemFieldName('progress'),
    },
    {
      key: 'ownerName',
      label: getSystemFieldName('owner'),
    },
  ];

  const {
    filters: { topLayer },
  } = useOrganizations();

  const dashboardProjects = useSelector(getReleaseDashboardsProjects);

  const isDashboardLoading = useSelector(isDashboardProjectsLoading);
  const isDashboardUninitialized = useSelector(isDashboardProjectsUninitialized);

  const selectedGroup1 = gridProps.selectedGroup1 || DEFAULT_GROUP_OPTION;
  const selectedGroup2 = gridProps.selectedGroup2 || DEFAULT_GROUP_OPTION;
  const selectedGroup3 = gridProps.selectedGroup3 || DEFAULT_GROUP_OPTION;

  const projectGroups = useProjectGroups({
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    withHierarchy: true,
    customAllProjectsByLayer: dashboardProjects,
    overrideShowArchived: true,
    hideEmptyHandler: hideAllEmpty,
  });

  const displayLayer = gridProps.displayLayer || topLayer;
  const allLevels = gridProps.allLevels === undefined ? true : gridProps.allLevels;

  const hierarchicalData = useMemo(() => {
    const projectsHierarchyPath = setIdeasHierarchyPath(
      dashboardProjects,
      displayLayer,
      getSystemFieldName(INITIATIVE, false),
      getSystemFieldName(BET, false),
      !allLevels,
      projectGroups,
    );

    return projectsHierarchyPath.filter(item => item.group || (!item.group && item.layer === displayLayer));
  }, [dashboardProjects, projectGroups, displayLayer, allLevels, selectedGroup1, selectedGroup2, selectedGroup3]);

  // hook responsible for fetching projects for release dashboard
  useFetchProjectsForReleaseDashboard({ projectsSubFilters, displayLayer, allLevels });

  return {
    defaultVisibleColumns,
    dashboardProjects,
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    hierarchicalData,
    isDashboardLoading,
    isDashboardUninitialized,
  };
};

export default useDashboards;
