import { isNil, keys } from 'ramda';
import { useMemo } from 'react';

import useConfigurableChartData from './useConfigurableChartData';
import { generateDataForChart, generateDataForStackedChart } from '../helpers';

const checkHasChartData = (chartData = {}) => Boolean(keys(chartData.datasets).length);

const DEFAULT_CHART_DATA = {
  datasets: [],
  labels: [],
};

/**
 * A custom hook for fetching and preparing configurable chart data for a bar chart.
 *
 * @param {Object} options - Configuration options for fetching chart data.
 * @param {string} options.groupBy - The grouping parameter for the chart data.
 * @param {string} options.stackBy - The stacking parameter for the chart data.
 * @param {number} options.maxItems - The max items to limit the dataset view to not grow to inifint.
 * @returns {Object} An object containing prepared chart data and a flag indicating data presence.
 */
const useConfigurableChartDataForBarChart = ({ groupBy, stackBy, maxItems, label }) => {
  const { chartData, groupsMetadata, bucketInformation, stacksMetadata, title, subtitle } = useConfigurableChartData({
    groupBy,
    stackBy,
    label,
  });

  const data = useMemo(() => {
    if (isNil(chartData)) {
      return DEFAULT_CHART_DATA;
    }

    if (stackBy) {
      return generateDataForStackedChart(chartData, groupsMetadata, stacksMetadata, maxItems, bucketInformation);
    }

    return generateDataForChart(chartData, groupsMetadata, maxItems, bucketInformation);
  }, [chartData, groupsMetadata, stacksMetadata, maxItems]);

  const hasData = useMemo(() => checkHasChartData(data), [data]);

  return {
    data,
    hasData,
    title,
    subtitle,
  };
};

export default useConfigurableChartDataForBarChart;
