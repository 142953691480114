import theme from 'design-system/theme';

const DROPDOWN_BOX_SHADOW = '0 4px 16px 0 rgba(0,0,0,.15), 0 1px 2px 0 rgba(0,0,0,.07), 0 0 1px 0 rgba(0,0,0,.2)';

/**
 * @function useDropdownStyles
 *
 * Get the dropdown styles to be used on dropdown selects
 *
 * @return {Object}
 */
const useDropdownStyles = () => {
  return {
    position: 'absolute',
    overflow: 'auto',
    zIndex: theme.zIndex.highest2,
    backgroundColor: theme.palette.background.white,
    borderRadius: theme.shape.borderRadiusRegular,
    boxShadow: DROPDOWN_BOX_SHADOW,
    minHeight: 0,
  };
};

export default useDropdownStyles;
