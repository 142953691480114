const mapEditableColumns = (columns, columnDefs) => {
  if (!columns || !columnDefs || !(columns instanceof Array) || !(columnDefs instanceof Array)) {
    return;
  }

  return columns.map(column => {
    const colDef = columnDefs.find(col => col.field === column);

    return {
      id: column,
      editable: colDef && colDef.editable === true,
    };
  });
};

export default mapEditableColumns;
