import { css } from 'styled-components';

import { spacing } from 'design-system/theme';

export default css`
  .react-chatbot-kit-chat-container {
    position: relative;
    max-width: 80%;
    margin: 0 auto;
    box-shadow: unset;
    border-radius: unset;
  }

  .react-chatbot-kit-chat-inner-container {
    background: transparent;
    border-radius: unset;
  }

  .react-chatbot-kit-chat-message-container {
    overflow: auto;
    padding-left: ${spacing(3)}px;
    padding-right: ${spacing(3)}px;

    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.palette.background.botMessage};
    }
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.palette.text.actionLabel.primary};
    }
  }

  // Hide the Header if there are messages
  :has(.react-chatbot-kit-user-chat-message, .react-chatbot-kit-chat-bot-message) {
    .react-chatbot-kit-header {
      display: none;
    }
  }

  .react-chatbot-kit-chat-bot-message-container {
    width: 100%;
  }

  .react-chatbot-kit-user-chat-message,
  .react-chatbot-kit-chat-bot-message {
    width: auto;
    max-width: 70%;
    padding: ${spacing(2)}px;
    font-size: ${({ theme }) => theme.typography.fontSizeSmallLargeRem}rem;
    line-height: ${({ theme }) => theme.typography.lineHeightRegularSmallRem}rem;
    border-radius: ${({ theme }) => theme.shape.borderRadiusExtraLarge}px;

    // Custom styling for the tags inside the messages
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ul,
    p {
      margin: ${spacing()}px 0;
    }

    ul {
      list-style-type: disc;
      padding-left: ${spacing(2.5)}px;
    }

    li {
      margin: ${spacing(0.5)}px 0;
    }

    a {
      color: ${({ theme }) => theme.palette.text.brand};
    }

    b,
    strong {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    }

    h2 {
      font-size: ${({ theme }) => theme.typography.fontSizeMediumRem}rem;
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      line-height: ${({ theme }) => theme.typography.lineHeight.bold};
    }

    h3 {
      font-size: ${({ theme }) => theme.typography.fontSizeMediumSmallRem}rem;
      line-height: ${({ theme }) => theme.typography.lineHeightRegularSmallRem}rem;
    }

    h4 {
      font-size: ${({ theme }) => theme.typography.fontSizeSmallLargeRem}rem;
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      line-height: ${({ theme }) => theme.typography.lineHeight.heading}rem;
    }

    h5 {
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      line-height: ${({ theme }) => theme.typography.lineHeight.heading}rem;
    }

    h6 {
      color: ${({ theme }) => theme.palette.text.secondary};
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      line-height: ${({ theme }) => theme.typography.lineHeight.heading}rem;
    }
  }

  .react-chatbot-kit-user-chat-message {
    background-color: ${({ theme }) => theme.palette.text.brand};
    color: ${({ theme }) => theme.palette.white};
    border-top-right-radius: 0;
  }

  .react-chatbot-kit-chat-bot-message {
    background: ${({ theme }) => theme.palette.background.botMessage};
    color: ${({ theme }) => theme.palette.white};
    border-top-left-radius: 0;
  }

  .react-chatbot-kit-user-chat-message-arrow,
  .react-chatbot-kit-chat-bot-message-arrow {
    display: none;
  }

  .react-chatbot-kit-chat-input-form {
    position: relative;
    height: 75px;
    margin: 0 ${spacing(3)}px;

    input {
      font-family: ${({ theme }) => theme.typography.fontFamily};
      font-size: ${({ theme }) => theme.typography.fontSizeMediumSmallRem}rem;
      color: ${({ theme }) => theme.palette.white};
      background: transparent;
      border: none;
      outline: none;
      border: ${({ theme }) => `1px solid ${theme.palette.white}`};
      border-radius: ${({ theme }) => theme.shape.borderRadiusExtraLarge}px;
      // Padding to not have the input behind the the send button
      padding-right: 75px;
      padding-left: ${spacing(2)}px;

      &::placeholder {
        color: ${({ theme }) => theme.palette.text.secondary};
      }
    }

    button {
      position: absolute;
      width: 45px;
      height: 45px;
      top: 50%;
      right: ${spacing(3)}px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${({ theme }) => theme.palette.text.brand};
      border-radius: 50%;
      transition: background-color 0.25s;
      cursor: pointer;

      &:hover,
      &:active {
        background-color: ${({ theme }) => theme.palette.newLayout.background.duskBlue};
      }

      svg {
        transform: rotate(45deg);
      }
    }
  }

  @media screen and (max-height: 800px) {
    .react-chatbot-kit-chat-container {
      height: 80vh;
    }

    .react-chatbot-kit-chat-inner-container {
      height: 90%;
    }
  }
`;
