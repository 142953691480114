import { useMemo, useRef, useCallback } from 'react';

import { updatedColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import useRoleAvailability from 'hooks/users/useRoleAvailability';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import { getTeamTitle } from '../helpers/getTeamTitle';

import {
  getEmailColumnDef,
  getFirstNameColumnDef,
  getLastNameColumnDef,
  getTeamsColumnDef,
  getSkillColumnDef,
  getCapacityColumnDef,
  getRoleColumDef,
  getStatusColumDef,
  getJiraColumnDef,
  getIntegrationColumnDef,
} from '../helpers/columnDefs';
import { getTeamsUpdatePayload } from '../utils';

const useUsersGridColumns = ({
  systemFields,
  allTeams,
  teamsTitles,
  hasTeams2,
  updateUserById,
  skillsTitles,
  isPortalSettingsEnabled,
  hasJiraIntegration,
  hideUserIntegrationColumn,
  orgIntegrations,
}) => {
  const teams2LevelsDropdownAnchor = useRef(null);

  const { canUpdate } = usePermissions();

  const canUserEdit = useCallback(
    ({ node }) => {
      return canUpdate(PERMISSION_RESOURCES.user, { data: node.data });
    },
    [canUpdate],
  );

  const { isRoleAvailable } = useRoleAvailability();

  const onTeamChangeHandler = useCallback(
    params => async selectedTeam => {
      const { data, api } = params;

      const oldValue = getTeamTitle(data);

      /**
       * Since it is required to deal with both team and team2 is required to use the flag overrideCellValueChanged.
       * By using overrideCellValueChanged, the updateUser needs to be tiggered manually.
       */
      await updateUserById(data.id, getTeamsUpdatePayload(selectedTeam), oldValue);

      teams2LevelsDropdownAnchor.current = null;
      api.stopEditing();
    },
    [updateUserById],
  );

  return useMemo(
    () => [
      getFirstNameColumnDef(canUserEdit),
      getLastNameColumnDef(canUserEdit),
      getEmailColumnDef(canUserEdit),
      getTeamsColumnDef({
        editable: canUserEdit,
        systemFields,
        allTeams,
        teamsTitles,
        hasTeams2,
        teams2LevelsDropdownAnchor,
        onTeamChangeHandler,
      }),
      getSkillColumnDef({ editable: canUserEdit, systemFields, skillsTitles }),
      getCapacityColumnDef(canUserEdit),
      getRoleColumDef({ editable: canUserEdit, isPortalSettingsEnabled, isRoleAvailable }),
      getStatusColumDef({ editable: canUserEdit }),
      getJiraColumnDef({ hide: !hasJiraIntegration }),
      getIntegrationColumnDef({ hideUserIntegrationColumn, orgIntegrations }),
      updatedColumnDef,
    ],
    [
      systemFields,
      allTeams,
      teamsTitles,
      hasTeams2,
      skillsTitles,
      onTeamChangeHandler,
      teams2LevelsDropdownAnchor.current,
      canUserEdit,
      isPortalSettingsEnabled,
      hasJiraIntegration,
      canUserEdit,
    ],
  );
};

export default useUsersGridColumns;
