export const DETAILS_TAB = {
  id: 'details',
  label: 'Details',
};

export const LINKED_ITEMS_TAB = {
  id: 'linked_items',
  label: 'See Roadmap Items',
};

export const HISTORY_TAB = {
  id: 'history',
  label: 'History',
};

export const OBJECTIVES_DRAWER_TABS = {
  DETAILS: DETAILS_TAB.id,
  LINKED_ITEMS: LINKED_ITEMS_TAB.id,
  HISTORY: HISTORY_TAB.id,
};

export const isValidTab = tabId => Object.values(OBJECTIVES_DRAWER_TABS).includes(tabId);
