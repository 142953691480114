import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentUser } from 'store/login';
import { getHasAdvancedMetricReporting } from 'store/organization';
import { getOutcomeSnapshotState, selectLoadedOutcomeKeyResults, selectLoadedOutcomeObjectives } from 'store/goalMode/selectors';
import { updateSnapshotState } from 'store/goalMode';

import { CORP_OBJECTIVE_KEY, OBJECTIVE_KEY } from 'constants/objectives';
import { METADATA_LEVELS } from 'constants/common';

import useSystemFields from 'hooks/useSystemFields';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { selectOutcomeModuleFilter } from 'store/filters/selectors';
import getGoalsSnapshotTopLevelObjectiveKey from '../helpers/getGoalsSnapshotTopLevelObjectiveKey';
import { equals, filter, pipe, prop } from 'ramda';
import { loadOutcomeGoalsFromUserViewApplied } from 'store/goalMode/thunks';

const castToString = v => String(v);
const isCorpLevel = pipe(prop('level'), castToString, equals(METADATA_LEVELS.LEVEL_CORP));
const isLevel1 = pipe(prop('level'), castToString, equals(METADATA_LEVELS.LEVEL_1));
const isLevel2 = pipe(prop('level'), castToString, equals(METADATA_LEVELS.LEVEL_2));

export default function useGoalModeSnapshot() {
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);
  const menuIsClosed = useSelector(state => state.app.leftMenuClosed);

  const loadedObjectives = useSelector(selectLoadedOutcomeObjectives);
  const loadedKeyResults = useSelector(selectLoadedOutcomeKeyResults);

  const usableMetadata = useMemo(
    () => ({
      objectivesCorp: filter(isCorpLevel, loadedObjectives),
      objectives: filter(isLevel1, loadedObjectives),
      keyResult1s: filter(isLevel1, loadedKeyResults),
      keyResult2s: filter(isLevel2, loadedKeyResults),
    }),
    [loadedObjectives, loadedKeyResults],
  );

  const outcomeFilters = useSelector(selectOutcomeModuleFilter);

  const [getSystemFieldName] = useSystemFields();

  const { hideEmptyCards, metricViewModeOn, metricViewModeTimePeriod, selectedGroup, cardsPerRow, visibleCardElements } =
    useSelector(getOutcomeSnapshotState) || {};

  const { canView } = usePermissions();

  const hasOkrCorpLevel = canView(PERMISSION_RESOURCES.objectiveCorp);

  const accountMaxLevel = useMemo(() => (hasOkrCorpLevel ? CORP_OBJECTIVE_KEY : OBJECTIVE_KEY), [hasOkrCorpLevel]);

  const objectiveTopLevelKey = getGoalsSnapshotTopLevelObjectiveKey(accountMaxLevel, outcomeFilters);

  const filteredOkrs = useMemo(
    () => [...loadedObjectives, ...loadedKeyResults],
    [loadedObjectives, loadedKeyResults],
  );

  const updateState = useCallback(update => dispatch(updateSnapshotState(update)), [dispatch]);

  useEffect(() => {
    const group = { key: objectiveTopLevelKey, field: 'objective_id', title: getSystemFieldName(objectiveTopLevelKey) };

    if (group.key !== selectedGroup?.key) {
      updateState({ selectedGroup: group });
    }
  }, [objectiveTopLevelKey]);

  const onSetPageUserView = useCallback(view => {
    dispatch(loadOutcomeGoalsFromUserViewApplied(view));
  });

  return {
    currentUser,
    hasAdvancedMetricReporting,
    hideEmptyCards,
    menuIsClosed,
    metricViewModeOn,
    metricViewModeTimePeriod,
    objectiveTopLevelKey,
    filteredOkrs,
    selectedGroup,
    updateState,
    usableMetadata,
    cardsPerRow,
    visibleCardElements,
    onSetPageUserView,
  };
}
