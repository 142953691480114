import {
  UPDATE_PROJECT_FULFILLED,
  DELETE_PROJECTS_FULFILLED,
  CREATE_PROJECT_FULFILLED,
  BULK_UPDATE_PROJECTS_FULFILLED,
  REMOVE_PROJECT_METRIC_FULFILLED,
  ADD_PROJECT_METRIC_FULFILLED,
  BULK_REMOVE_PROJECT_METRICS_FULFILLED,
  BULK_ADD_PROJECT_METRICS_FULFILLED,
  ADD_SELECTED_PARENT_TO_STORE,
} from 'store/projects';

import { pickProjectFields } from 'features/Canvas/helpers/fieldPickers';
import { CREATE_PROJECT_FROM_CANVAS_FULFILLED } from 'features/Canvas/store/types';
import { projectsSelector } from 'features/whiteboard/store/selectors';

import { initialState } from '../reducer';
import { projectsAdapter } from '../helpers/adapters';
import { APPLY_FILTERS_WHITEBOARD_FULFILLED } from '../types';
import { ADD_PROJECT_INTEGRATION_FULFILLED } from 'store/integrations';

const projectsReducer = (state = initialState, action) => {
  const storeProjects = projectsSelector.selectAll(state);
  const { payload } = action;

  switch (action.type) {
    case APPLY_FILTERS_WHITEBOARD_FULFILLED: {
      const { projects, projectsSearchMetadataResult } = action.payload;

      return {
        ...state,
        totalAllLayers: projects?.length,
        totalByLayer: projectsSearchMetadataResult?.totalByLayer,
        projects: projectsAdapter.setAll(state?.projects, projects.map(pickProjectFields)),
      };
    }

    case CREATE_PROJECT_FULFILLED:
      const { realtime } = action;
      // todo: should we show or not if the item does not match filters? For example creating initiative when viewing ideas only

      if (realtime) return state;

      return {
        ...state,
        projects: projectsAdapter.addOne(state?.projects, pickProjectFields(payload)),
      };
    case CREATE_PROJECT_FROM_CANVAS_FULFILLED:
      const { meta } = action;

      const project = payload.projectData || payload;

      return {
        ...state,
        projects: projectsAdapter.upsertOne(state?.projects, pickProjectFields(project)),
        userActionsMeta: meta?.projectCreatedFrom
          ? {
              entityCreatedFrom: meta.projectCreatedFrom,
              entityId: payload?.id,
              type: 'project',
            }
          : {},
      };
    case ADD_PROJECT_METRIC_FULFILLED:
    case ADD_PROJECT_INTEGRATION_FULFILLED:
    case UPDATE_PROJECT_FULFILLED:
    case BULK_ADD_PROJECT_METRICS_FULFILLED:
    case REMOVE_PROJECT_METRIC_FULFILLED:
    case BULK_REMOVE_PROJECT_METRICS_FULFILLED: {
      const project = payload.projectData || payload;

      return {
        ...state,
        projects: projectsAdapter.upsertOne(state?.projects, pickProjectFields(project)),
      };
    }
    case BULK_UPDATE_PROJECTS_FULFILLED: {
      return {
        ...state,
        projects: projectsAdapter.upsertMany(state?.projects, payload.map(pickProjectFields)),
      };
    }
    case DELETE_PROJECTS_FULFILLED: {
      return {
        ...state,
        projects: projectsAdapter.removeMany(state?.projects, payload),
      };
    }

    case ADD_SELECTED_PARENT_TO_STORE: {
      const showingProjectsOfSameLayer = storeProjects?.some(project => project?.layer === payload?.layer);

      if (!showingProjectsOfSameLayer) {
        return state;
      }
      return {
        ...state,
        projects: projectsAdapter.upsertOne(state?.projects, pickProjectFields(payload)),
      };
    }
    default: {
      return state;
    }
  }
};

export { projectsReducer };
