import { isNil } from 'ramda';

const PROJECTS_KEY_PREFIX = 'DB-';

/**
 * @function isProjectKey
 *
 * Checks if a provided value is a Project Key or not.
 *
 * @param {String|Number} value
 * @returns {Boolean}
 */
const isProjectKey = value => {
  if (isNil(value)) {
    return false;
  }

  return String(value).startsWith(PROJECTS_KEY_PREFIX);
};

export default isProjectKey;
