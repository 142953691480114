import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { has, isEmpty, isNil, not, path } from 'ramda';

import { getMetricValueFromMetricObject } from 'utils/metricValues';
import { getGridState } from 'store/grids/selectors';
import { saveGridState as saveGridStateAction } from 'store/grids/actions';
import { METRICS } from 'store/grids/constants';
import { importMetricFromDataSource, unlinkMetricFromDataSource } from 'store/metrics';
import getOrgIntegrationForDataSource from 'utils/metrics/getOrgIntegrationForDataSource';

const ACTUAL_VALUE = 'actual_value';
const TARGET_VALUE = 'target_value';
const ACTUAL_TYPE = 'actual';
const TARGET_TYPE = 'target';
const DATA_SOURCE = 'data_source';

const hasActual = has(ACTUAL_VALUE);

const getOrgIntegrationFromMetric = (metric, orgIntegrations) => {
  const orgIntegrationId = path(['metricIntegrations', 0, 'org_integration_id'], metric);

  return orgIntegrations.find(integration => integration.id === orgIntegrationId);
};

export default function useMetricsGrid({
  addMetricValue,
  deleteMetricValue,
  switchMetricsRowOrder,
  updateMetricById,
  updateMetricValue,
  metricOrgIntegrations,
}) {
  const dispatch = useDispatch();

  const gridState = useSelector(state => getGridState(state, METRICS));

  const saveGridState = useCallback(
    (state, makesActiveViewDirty = true) => dispatch(saveGridStateAction(METRICS, state, makesActiveViewDirty)),
    [dispatch],
  );

  const handleRowDrag = useCallback(
    (currentNode, lastOverNode, { position }) => {
      return switchMetricsRowOrder(currentNode.data.id, lastOverNode.data.id, position);
    },
    [switchMetricsRowOrder],
  );

  const handleUpdateMetricValueForMetric = useCallback(
    (metric, data, originalValue) => {
      const { id: metricId } = metric;
      const type = hasActual(data) ? ACTUAL_TYPE : TARGET_TYPE;
      const value = type === ACTUAL_TYPE ? data[ACTUAL_VALUE] : data[TARGET_VALUE];
      const isNewMetricValue = not(originalValue);
      const emptyValue = not(value);

      if (isNewMetricValue) {
        const date = new Date();

        return dispatch(addMetricValue({ metric_id: metricId, type, value, date }));
      }

      const existingMetricValue = getMetricValueFromMetricObject(metric, type);

      const metricValueNotFound = not(existingMetricValue);

      if (metricValueNotFound) {
        return;
      }

      if (emptyValue) {
        return dispatch(deleteMetricValue(existingMetricValue.id));
      }

      return dispatch(updateMetricValue(existingMetricValue.id, { value }));
    },
    [addMetricValue, deleteMetricValue, updateMetricValue],
  );

  const handleUpdateMetricById = useCallback(
    (metricId, updateData, oldValue, nodeData) => {
      const keys = Object.keys(updateData);

      if (keys.includes(DATA_SOURCE)) {
        if (isEmpty(updateData[DATA_SOURCE]) || isNil(updateData[DATA_SOURCE])) {
          const { data } = nodeData;
          const orgIntegration = getOrgIntegrationFromMetric(data, metricOrgIntegrations);

          if (orgIntegration) {
            return dispatch(unlinkMetricFromDataSource(orgIntegration.integrationType, orgIntegration.id, metricId));
          }
        } else {
          const orgIntegration = getOrgIntegrationForDataSource(updateData[DATA_SOURCE], metricOrgIntegrations);

          if (orgIntegration) {
            const { id, integrationType } = orgIntegration;

            return dispatch(importMetricFromDataSource(integrationType, id, metricId, updateData[DATA_SOURCE]));
          }
        }

        return;
      }

      if (keys.some(key => [ACTUAL_VALUE, TARGET_VALUE].includes(key))) {
        const { data: metric } = nodeData;

        return handleUpdateMetricValueForMetric(metric, updateData, oldValue);
      }

      return updateMetricById(metricId, updateData);
    },
    [addMetricValue, deleteMetricValue, updateMetricById, updateMetricValue, metricOrgIntegrations],
  );

  return { gridState, saveGridState, handleRowDrag, handleUpdateMetricById };
}
