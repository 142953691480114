export const FETCH_SKILLS = 'FETCH_SKILLS';
export const FETCH_SKILLS_FULFILLED = 'FETCH_SKILLS_FULFILLED';
export const FETCH_SKILLS_PENDING = 'FETCH_SKILLS_PENDING';

export const UPDATE_SKILL = 'UPDATE_SKILL';
export const UPDATE_SKILL_FULFILLED = 'UPDATE_SKILL_FULFILLED';

export const CREATE_SKILL = 'CREATE_SKILL';
export const CREATE_SKILL_FULFILLED = 'CREATE_SKILL_FULFILLED';

export const UPDATE_SKILL_BY_ID = 'UPDATE_SKILL_BY_ID';
export const UPDATE_SKILL_BY_ID_FULFILLED = 'UPDATE_SKILL_BY_ID_FULFILLED';

export const ADD_SKILL_WITHOUT_SAVE = 'ADD_SKILL_WITHOUT_SAVE';
export const REMOVE_UNSAVED_SKILLS = 'REMOVE_UNSAVED_SKILLS';

export const DELETE_SKILL = 'DELETE_SKILL';
export const DELETE_SKILL_FULFILLED = 'DELETE_SKILL_FULFILLED';

export const MERGE_SKILLS = 'MERGE_SKILLS';
export const MERGE_SKILLS_FULFILLED = 'MERGE_SKILLS_FULFILLED';

export const CREATE_SKILLS = 'CREATE_SKILLS';
export const CREATE_SKILLS_FULFILLED = 'CREATE_SKILLS_FULFILLED';
export const UPDATE_SKILLS = 'UPDATE_SKILLS';
export const UPDATE_SKILLS_FULFILLED = 'UPDATE_SKILLS_FULFILLED';
export const BULK_DELETE_SKILLS = 'BULK_DELETE_SKILLS';
export const BULK_DELETE_SKILLS_FULFILLED = 'BULK_DELETE_SKILLS_FULFILLED';

export const UNDO_CREATE_SKILLS = 'UNDO_CREATE_SKILLS';
export const UNDO_CREATE_SKILLS_FULFILLED = 'UNDO_CREATE_SKILLS_FULFILLED';
export const UNDO_UPDATE_SKILLS = 'UNDO_UPDATE_SKILLS';
export const UNDO_UPDATE_SKILLS_FULFILLED = 'UNDO_UPDATE_SKILLS_FULFILLED';
export const UNDO_BULK_DELETE_SKILLS = 'UNDO_BULK_DELETE_SKILLS';
export const UNDO_BULK_DELETE_SKILLS_FULFILLED = 'UNDO_BULK_DELETE_SKILLS_FULFILLED';

export const SWITCH_SKILLS_ROW_ORDER = 'SWITCH_SKILLS_ROW_ORDER';
export const SWITCH_SKILLS_ROW_ORDER_FULFILLED = 'SWITCH_SKILLS_ROW_ORDER_FULFILLED';
export const SWITCH_SKILLS_ROW_ORDER_PENDING = 'SWITCH_SKILLS_ROW_ORDER_PENDING';
export const SWITCH_SKILLS_ROW_ORDER_REJECTED = 'SWITCH_SKILLS_ROW_ORDER_REJECTED';
