export const SAVE_PROJECT_COMMENT = 'SAVE_PROJECT_COMMENT';
export const SAVE_PROJECT_COMMENT_FULFILLED = 'SAVE_PROJECT_COMMENT_FULFILLED';

export const EDIT_PROJECT_COMMENT = 'EDIT_PROJECT_COMMENT';
export const EDIT_PROJECT_COMMENT_FULFILLED = 'EDIT_PROJECT_COMMENT_FULFILLED';
export const EDIT_PROJECT_COMMENT_PENDING = 'EDIT_PROJECT_COMMENT_PENDING';

export const FETCH_PROJECT_COMMENTS = 'FETCH_PROJECT_COMMENTS';
export const FETCH_PROJECT_COMMENTS_FULFILLED = 'FETCH_PROJECT_COMMENTS_FULFILLED';
export const FETCH_PROJECT_COMMENTS_PENDING = 'FETCH_PROJECT_COMMENTS_PENDING';

export const FETCH_NEXT_PROJECT_COMMENTS = 'FETCH_NEXT_PROJECT_COMMENTS';
export const FETCH_NEXT_PROJECT_COMMENTS_FULFILLED = 'FETCH_NEXT_PROJECT_COMMENTS_FULFILLED';

export const SAVE_CUSTOMER_REQUEST_COMMENT = 'SAVE_CUSTOMER_REQUEST_COMMENT';
export const SAVE_CUSTOMER_REQUEST_COMMENT_FULFILLED = 'SAVE_CUSTOMER_REQUEST_COMMENT_FULFILLED';

export const FETCH_CUSTOMER_REQUEST_COMMENTS = 'FETCH_CUSTOMER_REQUEST_COMMENTS';
export const FETCH_CUSTOMER_REQUEST_COMMENTS_FULFILLED = 'FETCH_CUSTOMER_REQUEST_COMMENTS_FULFILLED';
export const FETCH_CUSTOMER_REQUEST_COMMENTS_PENDING = 'FETCH_CUSTOMER_REQUEST_COMMENTS_PENDING';

export const FETCH_NEXT_CUSTOMER_REQUEST_COMMENTS = 'FETCH_NEXT_CUSTOMER_REQUEST_COMMENTS';
export const FETCH_NEXT_CUSTOMER_REQUEST_COMMENTS_FULFILLED = 'FETCH_NEXT_CUSTOMER_REQUEST_COMMENTS_FULFILLED';

export const DELETE_CUSTOMER_REQUEST_COMMENT = 'DELETE_CUSTOMER_REQUEST_COMMENT';
export const DELETE_CUSTOMER_REQUEST_COMMENT_FULFILLED = 'DELETE_CUSTOMER_REQUEST_COMMENT_FULFILLED';
export const DELETE_CUSTOMER_REQUEST_COMMENT_PENDING = 'DELETE_CUSTOMER_REQUEST_COMMENT_PENDING';

export const EDIT_CUSTOMER_REQUEST_COMMENT = 'EDIT_CUSTOMER_REQUEST_COMMENT';
export const EDIT_CUSTOMER_REQUEST_COMMENT_FULFILLED = 'EDIT_CUSTOMER_REQUEST_COMMENT_FULFILLED';
export const EDIT_CUSTOMER_REQUEST_COMMENT_PENDING = 'EDIT_CUSTOMER_REQUEST_COMMENT_PENDING';

export const SAVE_OBJECTIVE_COMMENT = 'SAVE_OBJECTIVE_COMMENT';
export const SAVE_OBJECTIVE_COMMENT_FULFILLED = 'SAVE_OBJECTIVE_COMMENT_FULFILLED';

export const FETCH_OBJECTIVE_COMMENTS = 'FETCH_OBJECTIVE_COMMENTS';
export const FETCH_OBJECTIVE_COMMENTS_FULFILLED = 'FETCH_OBJECTIVE_COMMENTS_FULFILLED';
export const FETCH_OBJECTIVE_COMMENTS_PENDING = 'FETCH_OBJECTIVE_COMMENTS_PENDING';

export const FETCH_NEXT_OBJECTIVE_COMMENTS = 'FETCH_NEXT_OBJECTIVE_COMMENTS';
export const FETCH_NEXT_OBJECTIVE_COMMENTS_FULFILLED = 'FETCH_NEXT_OBJECTIVE_COMMENTS_FULFILLED';

export const DELETE_OBJECTIVE_COMMENT = 'DELETE_OBJECTIVE_COMMENT';
export const DELETE_OBJECTIVE_COMMENT_FULFILLED = 'DELETE_OBJECTIVE_COMMENT_FULFILLED';
export const DELETE_OBJECTIVE_COMMENT_PENDING = 'DELETE_OBJECTIVE_COMMENT_PENDING';

export const EDIT_OBJECTIVE_COMMENT = 'EDIT_OBJECTIVE_COMMENT';
export const EDIT_OBJECTIVE_COMMENT_FULFILLED = 'EDIT_OBJECTIVE_COMMENT_FULFILLED';
export const EDIT_OBJECTIVE_COMMENT_PENDING = 'EDIT_OBJECTIVE_COMMENT_PENDING';

export const SAVE_KEY_RESULT_COMMENT = 'SAVE_KEY_RESULT_COMMENT';
export const SAVE_KEY_RESULT_COMMENT_FULFILLED = 'SAVE_KEY_RESULT_COMMENT_FULFILLED';

export const FETCH_KEY_RESULT_COMMENTS = 'FETCH_KEY_RESULT_COMMENTS';
export const FETCH_KEY_RESULT_COMMENTS_FULFILLED = 'FETCH_KEY_RESULT_COMMENTS_FULFILLED';
export const FETCH_KEY_RESULT_COMMENTS_PENDING = 'FETCH_KEY_RESULT_COMMENTS_PENDING';

export const FETCH_NEXT_KEY_RESULT_COMMENTS = 'FETCH_NEXT_KEY_RESULT_COMMENTS';
export const FETCH_NEXT_KEY_RESULT_COMMENTS_FULFILLED = 'FETCH_NEXT_KEY_RESULT_COMMENTS_FULFILLED';

export const DELETE_KEY_RESULT_COMMENT = 'DELETE_KEY_RESULT_COMMENT';
export const DELETE_KEY_RESULT_COMMENT_FULFILLED = 'DELETE_KEY_RESULT_COMMENT_FULFILLED';
export const DELETE_KEY_RESULT_COMMENT_PENDING = 'DELETE_KEY_RESULT_COMMENT_PENDING';

export const EDIT_KEY_RESULT_COMMENT = 'EDIT_KEY_RESULT_COMMENT';
export const EDIT_KEY_RESULT_COMMENT_FULFILLED = 'EDIT_KEY_RESULT_COMMENT_FULFILLED';
export const EDIT_KEY_RESULT_COMMENT_PENDING = 'EDIT_KEY_RESULT_COMMENT_PENDING';

export const DELETE_PROJECT_COMMENT = 'DELETE_PROJECT_COMMENT';
export const DELETE_PROJECT_COMMENT_FULFILLED = 'DELETE_PROJECT_COMMENT_FULFILLED';
export const DELETE_PROJECT_COMMENT_PENDING = 'DELETE_PROJECT_COMMENT_PENDING';
