import React from 'react';
import { withTheme } from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

function SvgComponent(props) {
  const fill = props.fill || props.theme.palette.text.primary;

  return (
    <SvgIcon fontSize="inherit" width="25.02" height="14.018" viewBox="0 0 25.02 14.018" {...props}>
      <g id="icon-swimlane" transform="translate(-82.331 -768.817)" opacity="0.6">
        <path 
          id="Path_15582"
          data-name="Path 15582"
          d="M106.351,770.836H97.169a1,1,0,0,1,0-2h9.182a1,1,0,0,1,0,2Z"
          fill={fill}
        />
        <path 
          id="Path_15583"
          data-name="Path 15583"
          d="M106.351,776.836H93.263a1,1,0,0,1,0-2h13.088a1,1,0,0,1,0,2Z"
          fill={fill}
        />
        <path 
          id="Path_15584"
          data-name="Path 15584"
          d="M106.351,782.835h-6.113a1,1,0,1,1,0-2h6.113a1,1,0,0,1,0,2Z"
          fill={fill}
        />
        <path 
          id="Path_15585"
          data-name="Path 15585"
          d="M93.751,770.817H83.331a1,1,0,0,1,0-2h10.42a1,1,0,0,1,0,2Z"
          fill={fill}
        />
        <path 
          id="Path_15586"
          data-name="Path 15586"
          d="M89.094,776.842H83.331a1,1,0,0,1,0-2h5.763a1,1,0,0,1,0,2Z"
          fill={fill}
        />
        <path 
          id="Path_15587"
          data-name="Path 15587"
          d="M87.611,782.835h-4.28a1,1,0,0,1,0-2h4.28a1,1,0,0,1,0,2Z"
          fill={fill}
        />
        <path 
          id="Path_15588"
          data-name="Path 15588"
          d="M97.439,782.835H91.327a1,1,0,0,1,0-2h6.112a1,1,0,0,1,0,2Z"
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
}

export default withTheme(SvgComponent);
