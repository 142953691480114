// External dependencies
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';

// Dragonboat dependencies
import NewDialog from 'design-system/organisms/NewDialog/index';
import SwitchWithLabel from 'design-system/molecules/SwitchWithLabel/SwitchWithLabel';
import DialogActions from 'design-system/molecules/DialogActions/index';
import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';

import useTeamLightbox from './hooks/useTeamLightbox';

const TeamDialog = ({ open, onClose, team }) => {
  const {
    estimatingByPoints,
    workdaysPerPoint,
    onToggleEstimateByPoints,
    onWorkdaysPerPointChange,
    onSave,
    canEdit,
    canSaveChanges,
  } = useTeamLightbox({
    team,
    onClose,
  });

  return (
    <NewDialog
      data-test="team-dialog"
      open={open}
      onClose={onClose}
      maxWidth="sm"
      scroll="paper"
      header={
        <div>
          <StyledSwitchWithLabel
            id={estimatingByPoints ? 'team_estimate_points_off' : 'team_estimate_points_on'}
            switchProps={{
              color: 'primary',
              checked: estimatingByPoints,
              onChange: onToggleEstimateByPoints,
            }}
            labelProps={{
              color: 'textPrimary',
            }}
            label="Use Story Points to Estimate"
          />
        </div>
      }
      actions={<DialogActions onSave={onSave} onCancel={onClose} saveProps={{ disabled: !canSaveChanges }} />}
      fullWidth
      closeButton
    >
      <Wrapper>
        <Text inline>1 story point =</Text>
        <StyledNumberFormat
          data-testid="story_point_workday"
          type="text"
          thousandSeparator
          decimalScale={2}
          customInput={TextField}
          onValueChange={onWorkdaysPerPointChange}
          defaultValue={workdaysPerPoint}
          allowNegative={false}
          disabled={!canEdit}
        />
        <Text inline>business day</Text>
      </Wrapper>
    </NewDialog>
  );
};

TeamDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  team: PropTypes.object,
};

TeamDialog.defaultProps = {
  team: null,
  open: false,
  onClose: null,
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing()}px;
`;

const StyledNumberFormat = styled(NumberFormat)`
  width: 50px;
  input {
    text-align: center;
    padding: ${spacing(0.5)}px;
  }
`;

const StyledSwitchWithLabel = styled(SwitchWithLabel)`
  &&&& > span:first-child > span:first-child {
    height: auto;
  }
`;

export default TeamDialog;
