import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { mergeCustomerRequests } from 'store/customerRequests';
import { stopEditingCustomerRequest as stopEditingCustomerRequestAction } from 'store/customerRequests/actions';
import { getIsMergingRequests } from 'store/customerRequests/selectors';

/**
 * Custom hook for merging potential duplicates in the customer requests detailed view.
 *
 * @param {Object} options - The options for the hook.
 * @param {Object} options.customerRequest - The customer request to merge.
 * @param {Array} options.requestsToMerge - The list of requests to merge.
 * @returns {Object} - An object containing the selected request to merge, suggestions, and helper functions.
 */
const useMergePotentialDuplicates = ({ customerRequest, requestsToMerge }) => {
  const [selectedRequestToMerge, setSelectedRequestToMerge] = useState(customerRequest);
  const dispatch = useDispatch();

  const isMerging = useSelector(getIsMergingRequests);

  const requestsListIsEmpty = !requestsToMerge.filter(r => !!r.id).length;
  const anyRequestToMergeSelected = !selectedRequestToMerge?.id;
  const suggestions = [customerRequest, ...requestsToMerge.filter(r => r.id && r.id !== customerRequest.id)];

  const handleMergeRequests = async () => {
    try {
      /*
       * Use suggestions to get the items to merge because already contains the
       * opened request.
       */
      const itemToKeep = selectedRequestToMerge?.id;
      const itemsToMerge = suggestions.filter(r => r.id !== selectedRequestToMerge.id).map(r => r.id);

      await dispatch(mergeCustomerRequests(itemToKeep, itemsToMerge));

      // Will close the request drawer at the end
      dispatch(stopEditingCustomerRequestAction());

      toast('The items have been merged successfully.');
    } catch (error) {
      toast('An error occurred while merging the items.');
    }
  };

  return {
    selectedRequestToMerge,
    suggestions,
    requestsListIsEmpty,
    anyRequestToMergeSelected,
    isMerging,

    setSelectedRequestToMerge,
    handleMergeRequests,
  };
};

export default useMergePotentialDuplicates;
