/* eslint-disable */
/**
 * UnfilterIcon Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function jiraIcon(props) {
  const height = Math.ceil((props?.style?.height || 16) * 0.75);
  return (
    <SvgIcon {...props} style={{ ...props.style, height }} viewBox="0 0 17 17">
      <circle className="a" cx="8.5" cy="8.5" r="8.5" />
    </SvgIcon>
  );
}
