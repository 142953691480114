import { isNil, not, path, pipe } from 'ramda';
import { useSelector } from 'react-redux';

import formatDateTime from 'utils/dates/formatDateTime';

import { getIntegrationById } from 'store/organization';

const isNotNil = pipe(isNil, not);

const useLastSyncDay = orgIntegrationId => {
  const orgIntegration = useSelector(getIntegrationById(orgIntegrationId));

  const lastSyncDay = path(['data', 'last_sync_day'], orgIntegration);

  return {
    lastSyncDay: isNotNil(lastSyncDay) ? formatDateTime(lastSyncDay) : null,
  };
};

export default useLastSyncDay;
