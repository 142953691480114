import { useMemo } from 'react';
import LinkOffOutlined from '@material-ui/icons/LinkOffOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import theme from 'design-system/theme';
import AgGridInput from 'design-system/atoms/AgGridInput';
import { ActionsCellRenderer, TitleCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { useCustomerRequestsColumnsDefs } from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsColumnsDefs';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { isRowDraggable, isNewRow } from 'design-system/molecules/AgGridReact-New/helpers';
import LinkRequestCellEditor from './LinkRequestCellEditor';

const VISIBLE_COLUMNS = ['priority', 'status', 'customers', 'roadmap_title', 'owner_name', 'created_by_name'];

const useProjectLightboxRequestsColumns = ({
  onOpenCustomerRequestInfo,
  createMultiSelectOption,
  updateCustomerRequestByIdOnCellValueChange,
  addCustomerRequest,
  unlinkCustomerRequest,
}) => {
  const { columnDefs, defaultColDef } = useCustomerRequestsColumnsDefs({
    searchString: '',
    isBulkUpdate: false,
    visibleColumnsIds: VISIBLE_COLUMNS,

    updateCustomerRequestById: updateCustomerRequestByIdOnCellValueChange,
    onOpenCustomerRequestInfo,
    createMultiSelectOption,
  });

  const titleColumnDef = useMemo(
    () => ({
      field: 'title',
      headerName: 'Title',
      suppressMovable: true,
      comparator: stringComparator,
      headerClass: 'first-field',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: params => ({
        innerRenderer: TitleCellRenderer,
        suppressCount: true,
        suppressDoubleClickExpand: true,
        icon: OpenInNewIcon,
        openButtonProps: {
          hasOpenButton: true,
          onClick: () => onOpenCustomerRequestInfo(params.data),
        },
      }),
      cellEditorSelector: params => {
        const isLinkRow = params.data.isLink;

        if (!isLinkRow) {
          return {
            component: AgGridInput,
          };
        }

        return {
          component: LinkRequestCellEditor,
          params: {
            onSave: data => addCustomerRequest(data?.entity?.id),
            autoFocus: true,
            isSelected: true,
          },
        };
      },
      initialHide: false,
      pinned: 'left',
      lockPinned: true,
      editable: isNewRow,
      rowDrag: isRowDraggable,
      width: 400,
    }),
    [onOpenCustomerRequestInfo],
  );

  const unlinkColumnDef = useMemo(
    () => ({
      headerName: '',
      field: 'unlink',
      editable: false,
      suppressMovable: true,
      suppressMenu: true,
      filter: false,
      resizable: false,
      cellRenderer: ActionsCellRenderer,
      headerClass: 'centered-header',
      cellRendererParams: {
        actions: [
          {
            icon: LinkOffOutlined,
            color: theme.palette.icons.primary,
            onClick: unlinkCustomerRequest,
            getVisibility: () => true,
          },
        ],
      },
      width: 38,
      initialHide: false,
    }),
    [unlinkCustomerRequest],
  );

  return useMemo(
    () => ({
      columnDefs: [titleColumnDef, ...columnDefs.filter(col => col.field !== 'title'), unlinkColumnDef],
      defaultColDef,
    }),
    [columnDefs, defaultColDef, titleColumnDef, unlinkColumnDef],
  );
};

export default useProjectLightboxRequestsColumns;
