import React, { useMemo } from 'react';
import styled from 'styled-components';

import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete';

import { getSuggestionsForGroupByDropdown } from 'utils/swimlane/new/groupingBy';

const firstGroupIndex = 0;
const secondGroupIndex = 1;
const GroupByList = ({ selectedGroupOptions, groupByOptionsList, changeSelectedGroupOption }) => {
  const [firstGroupBy, secondGroupBy] = selectedGroupOptions;

  const firstGroupSuggestions = useMemo(
    () => getSuggestionsForGroupByDropdown(selectedGroupOptions, firstGroupIndex, groupByOptionsList[firstGroupIndex] ?? []),
    [selectedGroupOptions, groupByOptionsList],
  );

  const secondGroupSuggestions = useMemo(
    () => getSuggestionsForGroupByDropdown(selectedGroupOptions, secondGroupIndex, groupByOptionsList[secondGroupIndex] ?? []),
    [selectedGroupOptions, groupByOptionsList],
  );

  return (
    <>
      <DropdownWrapper key={`${firstGroupBy.key}${firstGroupIndex}`}>
        <GroupByAutocomplete
          name={`groupByData-${firstGroupIndex}`}
          suggestions={firstGroupSuggestions}
          value={firstGroupBy}
          onChange={changeSelectedGroupOption(firstGroupIndex)}
        />
      </DropdownWrapper>
      <DropdownWrapper key={`${secondGroupBy.key}${secondGroupIndex}`}>
        <StyledGroupByAutocomplete
          name={`groupByData-${secondGroupIndex}`}
          suggestions={secondGroupSuggestions}
          value={secondGroupBy}
          onChange={changeSelectedGroupOption(secondGroupIndex)}
          label="then by"
          disabled
        />
      </DropdownWrapper>
    </>
  );
};

export default GroupByList;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledGroupByAutocomplete = styled(GroupByAutocomplete)`
  &&&&& {
    margin-left: 12px;
  }
`;
