import React, { Component } from 'react';
import { bool, func, number, object, oneOfType, string } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MomentUtils from '@date-io/moment';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import moment from 'moment-timezone';
import styled from 'styled-components';

import getDateFormat from 'utils/dates/getDateFormat';

class DateRange extends Component {
  static propTypes = {
    startDate: oneOfType([object, string]),
    endDate: oneOfType([object, string]),
    duration: oneOfType([number, string]),
    disableDuration: bool,
    disableEndDate: bool,
    disableStartDate: bool,
    showDuration: bool,
    showEndDate: bool,
    onStartDateChange: func,
    onDurationChange: func,
    onEndDateChange: func,
    disabled: bool,
  };

  static defaultProps = {
    startDate: null,
    endDate: null,
    duration: null,
    showDuration: false,
    showEndDate: false,
    disableStartDate: false,
    disableDuration: false,
    disableEndDate: false,
    onDurationChange: () => {},
    onEndDateChange: () => {},
    onStartDateChange: () => {},
    disabled: false,
  };

  constructor(props) {
    super(props);

    const state = {};

    state.startDateDisplay = this.props.startDate ? moment(this.props.startDate).toDate() : null;
    state.endDateDisplay = this.props.endDate ? moment(this.props.endDate).toDate() : null;

    this.state = state;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.startDate !== this.props.startDate && this.props.startDate) {
      this.setState({ startDateDisplay: moment(this.props.startDate).toDate() });
    } else if (prevProps.startDate !== this.props.startDate && this.props.startDate === null) {
      this.setState({ startDateDisplay: null });
    }

    if (prevProps.endDate !== this.props.endDate && this.props.endDate) {
      this.setState({ endDateDisplay: moment(this.props.endDate).toDate() });
    }
  }

  render() {
    const {
      startDate,
      endDate,
      startLabel,
      endLabel,
      duration,
      disableDuration,
      disableEndDate,
      disableStartDate,
      showDuration,
      showEndDate,
      onStartDateChange,
      onEndDateChange,
      onDurationChange,
      durationMode,
      startDateProps,
      endDateProps,
      disabled,
      inputProps = {},
      calendarIcon,
    } = this.props;

    const start = startDate ? moment(startDate).toDate() : null;
    const end = endDate ? moment(endDate).toDate() : null;

    let maxDate = null;

    if (disableDuration && end) maxDate = end;

    let minDate = null;

    if (disableDuration && start) minDate = start;

    const maxDateDict = maxDate ? { maxDate } : {};
    const minDateDict = minDate ? { minDate } : {};

    const $startDate = (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <InlineDatePicker
          keyboard
          clearable
          value={this.state.startDateDisplay}
          onChange={onStartDateChange}
          format={getDateFormat()}
          {...maxDateDict}
          label={startLabel || 'Start Date'}
          style={{ width: '100%' }}
          disabled={disableStartDate || disabled}
          {...(startDateProps || {})}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={inputProps}
          keyboardIcon={calendarIcon}
        />
      </MuiPickersUtilsProvider>
    );

    const $endDate = (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <InlineDatePicker
          keyboard
          clearable
          value={this.state.endDateDisplay}
          onChange={onEndDateChange}
          format={getDateFormat()}
          {...minDateDict}
          label={endLabel || 'End Date'}
          disabled={disableEndDate || disabled}
          {...(endDateProps || {})}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={inputProps}
          keyboardIcon={calendarIcon}
        />
      </MuiPickersUtilsProvider>
    );

    return (
      <Grid
        container
        spacing={8}
        style={{ paddingTop: this.props.withPadding ? 15 : 0, justifyContent: 'space-between', fontSize: '12px', color: 'red' }}
      >
        <Grid item style={{ flexBasis: showDuration ? '38%' : '50%' }}>
          {$startDate}
        </Grid>

        {showDuration && (
          <Grid item style={{ flexBasis: '20%' }}>
            <DurationInput
              name="duration"
              label={durationMode === 'weeks' ? 'Duration (weeks)' : 'Duration (days)'}
              type="number"
              InputProps={{ ...inputProps, min: 1 }}
              InputLabelProps={{
                shrink: true,
              }}
              value={duration}
              onChange={onDurationChange}
              disabled={disableDuration || disabled}
            />
          </Grid>
        )}

        {showEndDate && (
          <Grid item style={{ flexBasis: showDuration ? '38%' : '50%' }}>
            {$endDate}
          </Grid>
        )}
      </Grid>
    );
  }
}

export default DateRange;

const DurationInput = styled(TextField)`
  &&&& {
    label {
      width: 140px;
    }
  }
`;
