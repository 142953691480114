import React from 'react';

import { INITIATIVE_LAYER } from 'store/projects/constants';

import ShowJiraEpic from './components/ShowJiraEpic';
import ShowJiraInitiative from './components/ShowJiraInitiative';


export default (props) => {
  const {
    formData,
  } = props;

  const Component = formData.layer === INITIATIVE_LAYER ? ShowJiraInitiative : ShowJiraEpic;

  return <Component {...props} />;
};
