import { defaultTo, has, isEmpty, propEq } from 'ramda';
import compact from 'lodash/compact';

import theme from 'design-system/theme';

import { sortByRankAndTitle } from 'store/projects/helpers/groupSelectors';

import { GROUP_KEY_SEPARATOR, UNDEFINED_GROUP_ID, UNDEFINED_GROUP_TITLE } from 'utils/swimlane/new/constants';

const isUndefinedGroup = propEq('title', UNDEFINED_GROUP_TITLE);
const defaultToMercuyColor = defaultTo(theme.palette.border.mercury);
const isLeafItem = has('type');

/**
 * Builds the group unique identifier.
 *
 * @param {String} groupType The type of the group, for example, roadmap, objective etc
 * @param {Number | null} id The id The id of the group
 * @param {String} lastKey The accumulated previous key in order to be used in recursive funcions
 *
 * @return {String} the group key
 * */
const buildGroupKey = (groupType, id, lastKey = null) =>
  compact([lastKey, groupType, id ?? UNDEFINED_GROUP_ID]).join(GROUP_KEY_SEPARATOR);

/**
 * Converts a single external project group into a timeline group with the expected format/structure to Timeline component.
 *
 * @param group The projects group to be converted
 * @param {Array} selectedGroupKeys The list of selected groups options type, for example, roadmap, objective etc
 * @param {Number} level the current level of grouping, 1, 2 or 3
 * @param {String} lastKey The accumulated previous key in order to be used in recursive funcions
 *
 * @return {Object} the resulting timeline group
 * */
const convertProjectGroupToTimeline = (group, selectedGroupKeys, level = 1, lastKey = '') => {
  const currentLevel = level - 1;
  const isUndefined = isUndefinedGroup(group);

  // Get the group object data
  const groupType = selectedGroupKeys[currentLevel];
  const groupData = group?.groupData?.[groupType];

  const id = isUndefined ? null : groupData.id;
  const title = isUndefined ? UNDEFINED_GROUP_TITLE : group.title;
  const key = buildGroupKey(groupType, id, lastKey);

  const timelineGroup = {
    id,
    key,
    title,
    rank: group?.rank,
    level: group?.level,
    color: defaultToMercuyColor(groupData?.color),
    // everything that is necessary to the timeline handlers passed to the timeline, but it won't affect the timeline internals
    meta: {
      type: groupType,
      groupData,
    },
  };

  const groupElements = group?.elements ?? [];

  if (isEmpty(groupElements)) {
    return timelineGroup;
  }

  const isLastLevel = groupElements.some(isLeafItem);

  if (isLastLevel) {
    // change the nomenclature from elements to items for the last level items (projects/milestones)
    return { ...timelineGroup, items: groupElements };
  }

  // recursively convert the next group level if is not the last one
  return {
    ...timelineGroup,
    groups: groupElements.reduce(
      (acc, groupElement) => [...acc, convertProjectGroupToTimeline(groupElement, selectedGroupKeys, groupElement.level, key)],
      [],
    ),
  };
};

/**
 * Converts all the project groups returned by the grouping logic to timeline groups
 * (with the expected structure for the Timeline component).
 *
 * @param {Array} projectGroups The list of projectGroups (may includes milestones)
 * @param {Array} selectedGroupKeys The list of selected groups options type, for example, roadmap, objective etc
 *
 * @return {Array} the timeline groups to be used by the Timeline component
 * */
const convertProjectGroupsToTimelineGroups = (projectGroups, selectedGroupKeys) => {
  if (isEmpty(projectGroups)) {
    return [];
  }

  return projectGroups.map(groupLevel1 => convertProjectGroupToTimeline(groupLevel1, selectedGroupKeys)).sort(sortByRankAndTitle);
};

export { convertProjectGroupsToTimelineGroups };
