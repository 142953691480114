import { ADD_CYCLE_WITHOUT_SAVE, REMOVE_UNSAVED_CYCLE_DELIVERABLE, ADD_DELIVERABLE_WITHOUT_SAVE } from './types';

export const addCycleWithoutSave = () => ({
  type: ADD_CYCLE_WITHOUT_SAVE,
});

export const addDeliverableWithoutSave = data => ({
  type: ADD_DELIVERABLE_WITHOUT_SAVE,
  payload: data,
});

export const removeUnsavedCycleDeliverable = () => ({ type: REMOVE_UNSAVED_CYCLE_DELIVERABLE });
