import { useCallback } from 'react';
import { difference, without } from 'ramda';

import { ESTIMATES, IDEAS, PDLC, CUSTOMER_REQUESTS } from 'store/grids';
import { ADD_COLUMN_ID, OPEN_ITEM_COLUMN_ID, EDIT_COLUMN_ID } from 'design-system/molecules/AgGridReact-New/columns';

const COLUMN_IDS_TO_IGNORE = [ADD_COLUMN_ID, OPEN_ITEM_COLUMN_ID, EDIT_COLUMN_ID];
const GRIDS_TO_MOVE_COLUMNS_TO_END = [IDEAS, ESTIMATES, PDLC, CUSTOMER_REQUESTS];

const getAllVisibleCols = columnApi =>
  columnApi
    .getColumnState()
    .filter(c => c.hide === false)
    .map(c => c.colId)
    .filter(id => !COLUMN_IDS_TO_IGNORE.includes(id));

/**
 * Custom hook for managing column visibility in a grid using ag-Grid.
 *
 * @param {String} viewId - The id of the view
 * @param {Object} columnApi - The ag-Grid column API.
 * @param {Function} enhanceOnChangeVisibleColumns - Function to enhance visible columns on update visibility
 * @returns {Object} An object with the `handleChangeColumnsVisibility` function.
 */
const useGridShowFields = (viewId, columnApi, enhanceOnChangeVisibleColumns) => {
  /**
   * Handle change new visible columns to the end of the grid
   *
   * @param {Array} allColsVisible
   * @param {Array} columnsToShow
   */
  const moveNewColumnsToTheEnd = useCallback(
    columnsToShow => {
      const allColsVisible = getAllVisibleCols(columnApi);

      /*
       * Get all columns that have a parent column
       *
       * All new added columns should be added before this columns (when exists)
       */
      const allChildrenColsIds = columnApi
        .getColumns()
        .filter(c => !!c.colDef.parent)
        .map(c => c.colId);

      // Get from allColsVisible to mantain the order from column state
      const allChildrenCols = allColsVisible.filter(cId => allChildrenColsIds.includes(cId));
      const allColsVisibleWithoutChildrenCols = difference(allColsVisible, allChildrenCols);

      /*
       * Children columns should not be added at the end because those will be only
       * hidden or visible on the same position
       */
      const newAddedColumns = columnsToShow.filter(c => !allChildrenCols.includes(c));
      const newCols = [...allColsVisibleWithoutChildrenCols, ...newAddedColumns, ...allChildrenCols];

      /*
       * Move columns from index 0 to have the exact position on the grid as defined
       * on the new columns array
       */
      columnApi.moveColumns(newCols, 0);
    },
    [columnApi],
  );

  /**
   * Handle changes in column visibility.
   *
   * @param {string[]} visibleFields - An array of column IDs representing the visible columns.
   */
  const handleChangeColumnsVisibility = useCallback(
    visibleFields => {
      const allColsVisible = getAllVisibleCols(columnApi);

      const enchancedVisibleFields = enhanceOnChangeVisibleColumns ? enhanceOnChangeVisibleColumns(visibleFields) : visibleFields;
      const toHide = difference(allColsVisible, enchancedVisibleFields);
      const columnsToShow = without(allColsVisible, enchancedVisibleFields);

      // Show and move columns to the end of the list if needed.
      if (columnsToShow.length) {
        if (GRIDS_TO_MOVE_COLUMNS_TO_END.includes(viewId)) {
          moveNewColumnsToTheEnd(columnsToShow);
        }

        columnApi.setColumnsVisible(columnsToShow, true);
      }

      // Hide columns that are not in the visibleFields array.
      if (toHide.length) {
        columnApi.setColumnsVisible(toHide, false);
      }
    },
    [columnApi, enhanceOnChangeVisibleColumns, moveNewColumnsToTheEnd],
  );

  return {
    handleChangeColumnsVisibility,
  };
};

export default useGridShowFields;
