import React, { useState, useEffect, useMemo, useRef } from 'react';
import useResizeObserver from 'use-resize-observer';
import { isFunction } from 'lodash';
import styled from 'styled-components';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';

import DndList from 'design-system/organisms/DndList/index';
import SavedFiltersRow from 'design-system/atoms/SavedFiltersRow/index';

import { labelMatchesQuery, optionMatchesQuery } from './helpers';

const DEFAULT_LIST_WIDTH = 300;

export default ({
  options,
  onSelectOption,
  onCloseList,
  onShowAllClick,
  placeholder = 'Type to search',
  showAllText = 'Show All',
  getOptionWhenSearchHasNoResults,
  disabled = false,
  focusOnInit = false,
  usePopper = false,
  disablePortal = true,
  value: originalValue = '',
  ...props
}) => {
  const [value, setValue] = useState(originalValue);
  const [showList, setShowList] = useState(false);

  const [expandAllCallback, setExpandAllCallback] = useState();
  const [collapseAllCallback, setCollapseAllCallback] = useState();

  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const popperRef = useRef(null);

  const { height: listHeight } = useResizeObserver({ ref: popperRef?.current?.popper?.popper });

  const shouldDisplayShowAll = useMemo(() => !!onShowAllClick, [onShowAllClick]);

  const filteredOptions = useMemo(() => {
    const filtered = value
      ? options
          .filter(option => optionMatchesQuery(option, value))
          .map(option => {
            return {
              ...option,
              children: option?.children?.filter(
                child => optionMatchesQuery(child, value) || labelMatchesQuery(option.label, value),
              ),
            };
          })
      : options;

    if (!filtered.length && value && !!getOptionWhenSearchHasNoResults) {
      return [getOptionWhenSearchHasNoResults(value)];
    }

    return filtered;
  }, [options, value]);

  const closeList = () => {
    setShowList(false);
    onCloseList && onCloseList();
  };

  useEffect(() => {
    if (isFunction(popperRef?.current?.popper?.scheduleUpdate)) {
      popperRef?.current?.popper?.scheduleUpdate();
    }
  }, [listHeight]);

  const registerExpandAllCallback = callback => setExpandAllCallback(() => callback);
  const registerCollapseAllCallback = callback => setCollapseAllCallback(() => callback);

  const handleChange = event => {
    // Clean the value and select undefined
    if (originalValue && !event.target.value) {
      onSelectOption(undefined);
    }

    if (event.target.value) {
      expandAllCallback && expandAllCallback();
    } else {
      collapseAllCallback && collapseAllCallback();
    }

    setValue(event.target.value);
  };

  const handleFocus = () => setShowList(true);

  const handleItemClick = item => {
    setValue(item.label);
    onSelectOption(item);
    closeList();
  };

  const handleGroupClick = event => event.stopPropagation();
  const handleClickOutside = event => {
    const clickOutsiseOfContainer = !containerRef.current?.contains(event.target);
    const clickOutsideOfPopper = !usePopper || !popperRef.current?.popper?.popper?.contains(event.target);

    if (clickOutsiseOfContainer && clickOutsideOfPopper) {
      closeList();
    }
  };

  const renderShowAll = () => <SavedFiltersRow handleClick={onShowAllClick} text={showAllText} />;

  const renderList = () => (
    <DndList
      items={filteredOptions}
      onClickItem={handleItemClick}
      onGroupOpenClose={handleGroupClick}
      width={inputRef.current?.offsetWidth || DEFAULT_LIST_WIDTH}
      renderClear={() => (shouldDisplayShowAll ? renderShowAll() : null)}
      registerExpandAllCallback={registerExpandAllCallback}
      registerCollapseAllCallback={registerCollapseAllCallback}
      openByDefault
      showTooltip
    />
  );

  useEffect(() => {
    if (focusOnInit) {
      inputRef.current.focus();
      inputRef.current.select();
    }
    // We need to use 'window' instead of 'document' because otherwise the 'event.stopPropagation'
    // on the group click won't work. See: https://github.com/facebook/react/issues/4335
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Container ref={containerRef} {...props}>
      <StyledTextField
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder={placeholder}
        inputRef={inputRef}
        disabled={disabled}
      />
      {usePopper ? (
        <StyledPopper
          open={!!showList}
          anchorEl={containerRef?.current}
          ref={popperRef}
          placement="bottom-start"
          disablePortal={disablePortal}
        >
          {renderList()}
        </StyledPopper>
      ) : showList ? (
        <ListContainer>{renderList()}</ListContainer>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  transform: translate(0);
  z-index: 1;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const StyledPopper = styled(Popper)`
  z-index: ${({ theme }) => theme.zIndex.highest};
`;

const ListContainer = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.highest};
`;
