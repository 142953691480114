import React, { useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'ramda';

import { discardRoadmapVersionUpdatedProjects, updateRoadmapVersionDialogProperties } from 'store/roadmapVersions';

import { getRoadmapVersionDialogProperties, getCurrentSelectedScenarioForUpdate } from 'store/roadmapVersions/selectors';

import SaveVersionDialog from './components/SaveVersionDialog';

const SaveScenarioDialog = props => {
  const { history } = props;
  const [roadmapVersionToBeEdited, setRoadmapVersionToBeEdited] = useState(null);

  const dispatch = useDispatch();
  const selectedRoadmapVersion = useSelector(state => getCurrentSelectedScenarioForUpdate(state));

  const { showSaveDialog, nextRoute, editRoadmapVersion } = useSelector(state => getRoadmapVersionDialogProperties(state));

  const roadmapVersion = selectedRoadmapVersion || editRoadmapVersion;

  const navigateAfterDiscardOrSave = useCallback(() => {
    if (nextRoute) {
      history.push(nextRoute);
      dispatch(updateRoadmapVersionDialogProperties({ nextRoute: null }));
    }
  }, [nextRoute, history]);

  const onSaveDialogClose = useCallback(() => {
    setRoadmapVersionToBeEdited(null);
    dispatch(updateRoadmapVersionDialogProperties({ showSaveDialog: false, editRoadmapVersion: null }));
  }, [setRoadmapVersionToBeEdited]);

  const onSaveSelectedRoadmapVersion = useCallback(() => {
    if (isNil(roadmapVersionToBeEdited)) {
      dispatch(discardRoadmapVersionUpdatedProjects());
    }

    navigateAfterDiscardOrSave();

    setRoadmapVersionToBeEdited(null);
  }, [roadmapVersionToBeEdited, setRoadmapVersionToBeEdited, navigateAfterDiscardOrSave]);

  const roadmapVersionForSaveVersionDialog = useMemo(() => {
    return roadmapVersionToBeEdited || roadmapVersion;
  }, [roadmapVersionToBeEdited, roadmapVersion]);

  return (
    <SaveVersionDialog
      onClose={onSaveDialogClose}
      onSave={onSaveSelectedRoadmapVersion}
      show={showSaveDialog}
      selectedRoadmapVersion={roadmapVersionForSaveVersionDialog}
    />
  );
};

export default React.memo(SaveScenarioDialog);
