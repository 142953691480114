import React from 'react';

import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SearchBar from 'components/SearchBar';

import SettingsToggle from 'design-system/atoms/SettingsToggle';

import { LeftContainer, RightContainer, Wrapper } from './styled';

const METRIC_KEY = 'metric';

const SEARCH_BAR_STYLES = { width: 160 };

const MetricsSettingsActionsToolbar = ({
  allowActions,

  addMetricWithoutSave,

  searchText,
  updateSearchText,

  isHidingArchivedMetrics,
  toggleIsHidingArchivedMetrics,
  showActionBar,
  showSearchBar,
  showSettingsToggle,
}) => {
  return (
    <Wrapper>
      <LeftContainer>
        {allowActions && (
          <SettingsActionsToolbar
            settingKey={METRIC_KEY}
            renamePermissions={{ canView: false, canUpdate: false }}
            add={addMetricWithoutSave}
          />
        )}
      </LeftContainer>
      {showActionBar && (
        <RightContainer>
          {showSearchBar && (
            <SearchBar searchString={searchText} updateSearchString={updateSearchText} style={SEARCH_BAR_STYLES} />
          )}

          {showSettingsToggle && (
            <SettingsToggle isChecked={isHidingArchivedMetrics} onToggle={toggleIsHidingArchivedMetrics} label="Hide archived" />
          )}
        </RightContainer>
      )}
    </Wrapper>
  );
};

export default MetricsSettingsActionsToolbar;
