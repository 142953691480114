import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import getOrCreateSocket from 'utils/getOrCreateSocket';
import { topics as Topics } from 'constants/topics';

import { gotAppRealtimeUpdate } from 'store/app';
import { gotThemesRealtimeUpdate } from 'store/themes';
import { gotProjectRealtimeUpdate } from 'store/projects';
import { gotTasksRealtimeUpdate } from 'store/tasks';
import { gotEstimatesRealtimeUpdate } from 'store/estimates';
import { gotCategoriesRealtimeUpdate } from 'store/categories';
import { gotPrioritiesRealtimeUpdate } from 'store/priorities';
import { gotPhasesRealtimeUpdate } from 'store/phases';
import { gotObjectivesRealtimeUpdate, gotKeyResultsRealtimeUpdate } from 'store/objectives';
import { gotRoadmapsRealtimeUpdate, gotProductsRealtimeUpdate } from 'store/roadmaps';
import { gotMetricsRealtimeUpdate } from 'store/metrics';
import { gotTimeframesRealtimeUpdate } from 'store/timeframes';
import { gotTeamsRealtimeUpdate } from 'store/teams';
import { gotSkillsRealtimeUpdate } from 'store/skills';
import { gotTagsRealtimeUpdate } from 'store/tags';
import { gotCustomersRealtimeUpdate } from 'store/customers';
import { gotUsersRealtimeUpdate } from 'store/users';
import { receiveRealtimeNotification } from 'store/notifications/thunks';
import { getCurrentUser } from 'store/login/selectors';
import { gotUserFiltersRealtimeUpdate } from 'store/filters';
import { gotUserViewsRealtimeUpdate } from 'store/userViews';
import { gotCustomFieldsRealtimeUpdate } from 'store/customFields';
import { gotOrgIntegrationsRealtimeUpdate } from 'store/organization';
import { gotCustomerRequestsRealtimeUpdate } from 'store/customerRequests';
import { handleRoadmapHistoryRealtimeUpdate } from 'features/RoadmapHistory/store/actions';
import { handleStreamReleaseNotes } from 'features/AIReleaseNotes/store';

export default (topics, allowOwnChanges = false) => {
  if (window.location !== window.parent.location) {
    return;
  }
  const currentUser = useSelector(getCurrentUser);

  const socket = useRef(getOrCreateSocket());

  const _joinSocketio = () => {
    topics.forEach(group => socket.current.joinRealtimeChangesTopic(group));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    socket.current.onReconnect(_joinSocketio);
    socket.current.onConnect(_joinSocketio);

    socket.current.subscribe(
      ({ type, data, user: userId, topic }) => {
        const currentUserId = currentUser.id;

        // if (!allowOwnChanges && userId === currentUserId) {
        //   return;
        // }
        switch (topic) {
          case 'app':
            dispatch(gotAppRealtimeUpdate(type, data));
            break;
          case 'projects':
          case 'asyncImportIntegrationProjects':
            dispatch(gotProjectRealtimeUpdate(type, data, currentUserId, userId));
            break;
          case 'estimates':
            dispatch(gotEstimatesRealtimeUpdate(type, data));
            break;
          case 'tasks':
            dispatch(gotTasksRealtimeUpdate(type, data));
            break;
          case 'themes':
            dispatch(gotThemesRealtimeUpdate(type, data));
            break;
          case 'categories':
            dispatch(gotCategoriesRealtimeUpdate(type, data));
            break;
          case 'priorities':
            dispatch(gotPrioritiesRealtimeUpdate(type, data));
            break;
          case 'phases':
            dispatch(gotPhasesRealtimeUpdate(type, data));
            break;
          case 'objectives':
            dispatch(gotObjectivesRealtimeUpdate(type, data));
            break;
          case 'roadmaps':
            dispatch(gotRoadmapsRealtimeUpdate(type, data));
            break;
          case 'timeframes':
            dispatch(gotTimeframesRealtimeUpdate(type, data));
            break;
          case 'teams':
            dispatch(gotTeamsRealtimeUpdate(type, data));
            break;
          case 'skills':
            dispatch(gotSkillsRealtimeUpdate(type, data));
            break;
          case 'tags':
            dispatch(gotTagsRealtimeUpdate(type, data));
            break;
          case 'customers':
            dispatch(gotCustomersRealtimeUpdate(type, data));
            break;
          case Topics.CUSTOMER_REQUESTS:
            dispatch(gotCustomerRequestsRealtimeUpdate(type, data));
            break;
          case 'users':
            dispatch(gotUsersRealtimeUpdate(type, data));
            break;
          case 'products':
            dispatch(gotProductsRealtimeUpdate(type, data));
            break;
          case 'keyResults':
            dispatch(gotKeyResultsRealtimeUpdate(type, data));
            break;
          case 'metrics':
            dispatch(gotMetricsRealtimeUpdate(type, data));
            break;
          case 'notifications':
            dispatch(receiveRealtimeNotification(data));
            break;
          case 'userFilters':
            dispatch(gotUserFiltersRealtimeUpdate(type, data));
            break;
          case 'userViews':
            dispatch(gotUserViewsRealtimeUpdate(type, data));
            break;
          case 'customFields':
            dispatch(gotCustomFieldsRealtimeUpdate(type, data));
            break;
          case 'orgIntegrations':
            dispatch(gotOrgIntegrationsRealtimeUpdate(type, data));
            break;
          case 'roadmapHistory':
            dispatch(handleRoadmapHistoryRealtimeUpdate(type, data));
            break;
          case Topics.STREAM_RELEASE_NOTES:
            dispatch(handleStreamReleaseNotes(type, data));
            break;
          default:
            break;
        }
      },
      { isRealtimeChangesTopicHandler: true },
    );

    return () => {
      topics.forEach(group => {
        socket.current.leaveRealtimeChangesTopic(group);
      });
      socket.current.destructor();
    };
  }, []);
};
