import React from 'react';

import RoadmapTable from 'design-system/organisms/RoadmapTable/index';


import { BASE_Z_INDEX, DEFAULT_SIDEBAR_WIDTH, MAX_HEIGHT } from '../helpers';

import TimeBar from './TimeBar';

const TimelineHeader = ({
  slots,
  onResizeFirstGroupHeader,
  onResizeSecondGroupHeader,
  groupbarWidth,
  sidebarWidth,
  fromDate,
  toDate,
  zoomMode,
  slotWidth,
  firstGroupTitle,
  secondGroupTitle,
}) => (
  <RoadmapTable.Head sticky top>
    <RoadmapTable.Row>
      <RoadmapTable.Header
        sticky
        left={1}
        top
        width={groupbarWidth || DEFAULT_SIDEBAR_WIDTH}
        maxHeight={MAX_HEIGHT}
        zIndex={BASE_Z_INDEX + 1}
        split={!secondGroupTitle}
      >
        <RoadmapTable.HeaderTitleWrapper bordered>
          <RoadmapTable.HeaderCell
            colId="group-auto"
            width={groupbarWidth || DEFAULT_SIDEBAR_WIDTH}
            onResizeColumnStop={onResizeFirstGroupHeader}
          >
            <RoadmapTable.HeaderTitle>{firstGroupTitle}</RoadmapTable.HeaderTitle>
          </RoadmapTable.HeaderCell>
        </RoadmapTable.HeaderTitleWrapper>
      </RoadmapTable.Header>
      {secondGroupTitle && (
        <RoadmapTable.Header
          sticky
          left={(groupbarWidth || DEFAULT_SIDEBAR_WIDTH) + 1}
          top
          width={sidebarWidth}
          maxHeight={MAX_HEIGHT}
          zIndex={BASE_Z_INDEX + 1}
          split
        >
          <RoadmapTable.HeaderTitleWrapper bordered>
            <RoadmapTable.HeaderCell colId="auto" width={sidebarWidth} onResizeColumnStop={onResizeSecondGroupHeader} bordered>
              <RoadmapTable.HeaderTitle>{secondGroupTitle}</RoadmapTable.HeaderTitle>
            </RoadmapTable.HeaderCell>
          </RoadmapTable.HeaderTitleWrapper>
        </RoadmapTable.Header>
      )}
      <TimeBar slots={slots} slotWidth={slotWidth} />
    </RoadmapTable.Row>
  </RoadmapTable.Head>
);

export default TimelineHeader;
