import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';
import { getObjectiveProjectFieldByType } from 'utils/objectives';
import {
  FETCH_OBJECTIVE_LINKED_ITEMS,
  FETCH_OBJECTIVE_LINKED_ITEMS_FULFILLED,
  ADD_NEW_LINKED_ITEM_ROW,
  REMOVE_UNSAVED_LINKED_ITEMS_ROWS,
  LINK_PROJECT_TO_OKR,
  UNLINK_PROJECT_FROM_OKR,
  LINK_PROJECT_TO_OKR_FULFILLED,
  UNLINK_PROJECT_FROM_OKR_FULFILLED,
  CREATE_PROJECT_FROM_OKR,
  CREATE_PROJECT_FROM_OKR_FULFILLED,
  FETCH_OBJECTIVE_LINKED_ITEMS_PENDING,
} from './types';
import { OPEN_OBJECTIVE_DRAWER } from 'store/objectives';

const EMPTY_ARRAY = [];

export const initialState = {
  linkedItems: [],
  operations: bulkThunkInitialState([
    FETCH_OBJECTIVE_LINKED_ITEMS,
    LINK_PROJECT_TO_OKR,
    UNLINK_PROJECT_FROM_OKR,
    CREATE_PROJECT_FROM_OKR,
  ]),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_OBJECTIVE_DRAWER:
    case FETCH_OBJECTIVE_LINKED_ITEMS_PENDING:
      return {
        ...state,
        linkedItems: EMPTY_ARRAY,
      };
    case FETCH_OBJECTIVE_LINKED_ITEMS_FULFILLED:
      return {
        ...state,
        linkedItems: action.payload || EMPTY_ARRAY,
      };
    case ADD_NEW_LINKED_ITEM_ROW:
      return {
        ...state,
        linkedItems: [{ isLink: action.payload }, ...(state?.linkedItems || EMPTY_ARRAY)],
      };
    case REMOVE_UNSAVED_LINKED_ITEMS_ROWS:
      return {
        ...state,
        linkedItems: (state?.linkedItems || EMPTY_ARRAY).filter(r => r.id),
      };
    case LINK_PROJECT_TO_OKR_FULFILLED:
    case CREATE_PROJECT_FROM_OKR_FULFILLED:
      const project = action?.payload?.projectData;
      const { okrId, okrType } = action?.meta || {};

      const isDuplicated = (state?.linkedItems || EMPTY_ARRAY).some(r => r.id === project?.id);
      const projectHasMatchingOkr = project?.[getObjectiveProjectFieldByType(okrType)] === okrId;

      if (!project?.id || !projectHasMatchingOkr) return state;

      if (isDuplicated) {
        return {
          ...state,
          linkedItems: (state?.linkedItems || EMPTY_ARRAY).filter(r => !r.isLink),
        };
      }

      return {
        ...state,
        linkedItems: [project, ...(state?.linkedItems || EMPTY_ARRAY)],
      };
    case UNLINK_PROJECT_FROM_OKR_FULFILLED: {
      const project = action?.payload?.projectData;

      if (!project?.id) return state;

      return {
        ...state,
        linkedItems: (state?.linkedItems || EMPTY_ARRAY).filter(r => r.id !== project?.id),
      };
    }

    default:
      return state;
  }
};

export const operationsReducer = getThunksReducers([
  FETCH_OBJECTIVE_LINKED_ITEMS,
  LINK_PROJECT_TO_OKR,
  UNLINK_PROJECT_FROM_OKR,
  CREATE_PROJECT_FROM_OKR,
]);

export default reducer;
