import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import OpenInNew from '@material-ui/icons/OpenInNew';

import Text from 'design-system/atoms/Text/index';

import theme, { spacing } from 'design-system/theme';

import { OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2, OBJECT_OBJECTIVE, OBJECT_OBJECTIVE_CORP_STRING } from 'store/objectives';
import { openObjectiveDrawer } from 'store/objectives/actions';

import EditableHeader from './EditableHeader';
import VisibilityButtonIcon from './VisibilityButtonIcon';

const OKR_KEYS = ['objectiveCorp', 'objective', 'keyResult1'];
const MAP_OKR_KEY_TO_LEVEL = {
  objectiveCorp: OBJECT_OBJECTIVE_CORP_STRING,
  objective: OBJECT_OBJECTIVE,
  keyResult1: OBJECT_KEY_RESULT,
  keyResult2: OBJECT_KEY_RESULT_2,
};

const LINE_HEIGHT = theme.typography.lineHeightSmallLarge;

const PADDING_BOTTOM = spacing();

const calcMaxHeight = suggestedMaxHeight => {
  // eslint-disable-next-line no-mixed-operators
  return suggestedMaxHeight - (suggestedMaxHeight % LINE_HEIGHT) + PADDING_BOTTOM;
};

const LinkIconButton = styled(props => <IconButton {...props}>{props.children}</IconButton>)`
  position: relative;
  visibility: hidden;
  font-size: 1.125rem;

  && {
    color: ${({ theme }) => theme.palette.text.secondary};
    padding: ${spacing(0.5)}px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
  width: 100%;
  max-height: ${({ $maxHeight }) => ($maxHeight ? `${$maxHeight}px` : '100%')};

  ${LinkIconButton} {
    visibility: ${({ $displayOkrButton }) => ($displayOkrButton ? 'visible' : 'hidden')};
  }
`;

const verticalTitleStyles = css`
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  text-align: right;
  max-width: calc(100% - 42px);
  overflow-wrap: normal;
  overflow: hidden;
`;

const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  line-height: ${LINE_HEIGHT}px;

  max-height: ${({ $maxHeight }) => ($maxHeight ? `${$maxHeight}px` : '100%')};

  > * {
    max-height: ${({ $maxHeight }) => ($maxHeight ? `${calcMaxHeight($maxHeight)}px` : '100%')};
  }

  * {
    line-height: ${LINE_HEIGHT}px;
  }

  ${({ $vertical }) => $vertical && verticalTitleStyles}
`;

export const IconsWrapper = styled.div`
  position: absolute;
  top: ${spacing(0.5)}px;
  right: 0;
  height: 90%;
  padding: ${spacing(2.5)}px ${spacing()}px;
  background-color: ${({ theme }) => theme.palette.white};
  opacity: 0;
  transition: opacity 0.25s ease;

  ${({ showIcons }) =>
    showIcons &&
    css`
      opacity: 1;
    `}
`;

const rawHeaderNotVerticalStyles = css`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ $maxLines }) => $maxLines};
  -webkit-box-orient: vertical;
`;

const StyledRawHeaderText = styled(Text)`
  padding: ${spacing(0.75)}px ${spacing(0.5)}px;
  ${({ $vertical }) => !$vertical && rawHeaderNotVerticalStyles}
`;

const PostTitle = styled.div`
  padding: 0 4px;
`;

const HeaderTitle = ({
  title,
  postTitle,
  dataId,
  dataKey,
  editable,
  verticalTitle,
  maxHeight,
  id,
  isColumnHeader,
  onVisilityIconClick,
  showIcons,
}) => {
  const dispatch = useDispatch();
  const [inputIsFocused, setInputIsFocused] = useState(false);

  const isOkr = useMemo(() => OKR_KEYS.includes(dataKey), [dataKey]);

  const displayOkrButton = useMemo(() => dataId != null && isOkr, [dataId, isOkr]);

  const okrDrawerOpener = useCallback(
    () => displayOkrButton && dispatch(openObjectiveDrawer(dataId, MAP_OKR_KEY_TO_LEVEL[dataKey])),
    [dataId, displayOkrButton, dataKey],
  );

  const maxLines = useMemo(() => Math.floor(maxHeight / LINE_HEIGHT), [maxHeight]);

  return (
    <Wrapper id={id} $displayOkrButton={displayOkrButton} data-testid={id}>
      <TitlesContainer $vertical={verticalTitle} $maxHeight={maxHeight}>
        {editable && !verticalTitle ? (
          <EditableHeader
            title={title}
            dataId={dataId}
            dataKey={dataKey}
            editingHeight={maxHeight}
            isColumnHeader={isColumnHeader}
            handleFocus={() => setInputIsFocused(true)}
            handleBlur={() => setInputIsFocused(false)}
          />
        ) : (
          <StyledRawHeaderText variant={isColumnHeader ? 'h6' : 'h5'} $maxLines={maxLines} $vertical={verticalTitle}>
            {title}
          </StyledRawHeaderText>
        )}
        {postTitle && <PostTitle>{postTitle}</PostTitle>}
      </TitlesContainer>
      <IconsWrapper showIcons={showIcons && !inputIsFocused}>
        {displayOkrButton && (
          <LinkIconButton onClick={okrDrawerOpener}>
            <OpenInNew style={{ fontSize: 14 }} />
          </LinkIconButton>
        )}
        {onVisilityIconClick ? <VisibilityButtonIcon onClick={onVisilityIconClick} /> : null}
      </IconsWrapper>
    </Wrapper>
  );
};

export default HeaderTitle;
