import React, { useCallback } from 'react';
import isFunction from 'lodash/isFunction';

import { LEAD_LINES_PLUGIN } from 'components/ReactChart/utils/plugins';
import { HIDE_LEGEND_OPTION } from 'constants/charts';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';

import DoughnutChart from 'components/Charts/DoughnutChart';

import useEpicGoalDoughnutChartData from './useEpicGoalDoughnutChartData';
import { DASHBOARDS_PAGE } from 'constants/filters';
import useChartTimelineLightbox from '../../hooks/useChartTimelineLightbox';

const mappedLayers = {
  [IDEA_LAYER]: 'idea',
  [INITIATIVE_LAYER]: 'initiative',
  [BET_LAYER]: 'bet',
};

const componentHOC = Component => {
  return props => {
    const { chartData, displayLayer } = useEpicGoalDoughnutChartData();

    const generateData = useCallback(() => chartData, [chartData]);

    const {
      handleOnChartClick,
      registerOpenTimelineCallback,
      timelineProjectsGrouping,
      groupByEntity,
      timelineLightboxOpenItems,
      timelineDisplayLayer,
    } = useChartTimelineLightbox(chartData, 'objective');

    const chart = DoughnutChart({
      generateData,
      options: {
        layout: {
          padding: 10,
        },
        plugins: {
          legend: { ...HIDE_LEGEND_OPTION },
        },
        radius: '80%',
        onClick: props.disableClick ? null : handleOnChartClick,
      },
      plugins: [LEAD_LINES_PLUGIN],
      minHeight: props.minHeight,
    });

    const { title } = props;
    const chartTitle = isFunction(title) && title(mappedLayers[displayLayer] || mappedLayers.IDEA_LAYER);

    return (
      <Component
        {...props}
        chart={chart}
        title={chartTitle}
        timelineLightboxProps={{
          onOpen: registerOpenTimelineCallback,
          groupedBy: timelineProjectsGrouping,
          entityGroup: groupByEntity,
          groupBySelection: groupByEntity,
          pageId: DASHBOARDS_PAGE,
          openItems: timelineLightboxOpenItems,
          lazyLoadProjects: true,
          displayLayer: timelineDisplayLayer,
        }}
      />
    );
  };
};

export default componentHOC;
