import React from 'react';
import { prop, equals, pipe, defaultTo, isEmpty, allPass } from 'ramda';

import { METADATA_LEVELS } from 'constants/common';
import { isOfObjectProductType, OBJECT_ROADMAP, OBJECT_PRODUCT } from '../../helpers';
import { Wrapper, TextWrapper } from './styled';
import Actions from './Actions';

const LEVEL = 'level';
const PRODUCTS = 'products';
const ROADMAPS = 'children';

const defaultToEmptyObject = defaultTo({});
const defaultToEmptyArray = defaultTo([]);
const isMetadataLevel1 = equals(METADATA_LEVELS.LEVEL_1);
const isMetadataLevelCorp = equals(METADATA_LEVELS.LEVEL_CORP);
const getLevelProp = prop(LEVEL);
const isLevelMetadataLevel1 = pipe(getLevelProp, isMetadataLevel1);
const isLevelMetadataLevelCorp = pipe(getLevelProp, isMetadataLevelCorp);
const getProducts = prop(PRODUCTS);
const getRoadmaps = prop(ROADMAPS);
const hasNoProducts = pipe(getProducts, defaultToEmptyArray, isEmpty);
const hasNoRoadmaps = pipe(getRoadmaps, defaultToEmptyArray, isEmpty);
const checkIfIsParentL1WithoutChildren = pipe(defaultToEmptyObject, allPass([isLevelMetadataLevel1, hasNoProducts]));
const checkIfIsParentLCorpWithoutChildren = pipe(defaultToEmptyObject, allPass([isLevelMetadataLevelCorp, hasNoRoadmaps]));

const GroupHeaderInnerRenderer = () => params => {
  const { node, data, value, context: { treeData } = {} } = params;

  if (!value) {
    return null;
  }

  const indentL1 = treeData && (checkIfIsParentLCorpWithoutChildren(data) || checkIfIsParentL1WithoutChildren(data));
  const indentProducts =
    isOfObjectProductType(data) && [OBJECT_ROADMAP, OBJECT_PRODUCT].includes(data?.level) && !node?.allChildrenCount;

  return (
    <Wrapper $indent={indentL1 || indentProducts}>
      <TextWrapper>{params.value}</TextWrapper>
      <Actions {...params} />
    </Wrapper>
  );
};

export default GroupHeaderInnerRenderer;
