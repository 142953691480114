import { defaultTo, path, prop } from 'ramda';
import { PAGEID_TO_PATH, PROGRESS_PAGE } from 'constants/filters/pages';

/**
 * @function shouldDisableSelectLayer
 *
 * Check if should disable the selct layer input on filters Popover
 *
 * @param {String} path
 * @return {Boolean}
 */
const shouldDisableSelectLayer = path => {
  const isDashboardsPage = path?.match(/^\/dashboard\/dashboards/m);

  const disableSelectLayer = isDashboardsPage;

  return disableSelectLayer;
};

const getSelectedQuickFilterFromDefaultFilters = (defaultFilters, currentFilters) => {
  const filters = defaultTo({}, currentFilters);

  if (!Array.isArray(defaultFilters)) {
    return '';
  }

  const defaultFilterToSelect = defaultFilters.find(({ key, metaType }) => {
    const metaField = path(['fields', metaType], filters);

    return Array.isArray(metaField) ? metaField.includes(key) : false;
  });

  return defaultFilterToSelect?.label || '';
};

const checkPathIsDashboardPath = urlPath => {
  return urlPath?.includes('dashboard') || false;
};

/**
 * Whether the following urlPath is allowed to show View name.
 * @param {string} urlPath
 * @returns {boolean}
 */
const shouldDisplayDashboardViewName = urlPath => {
  const isDashboardPath = checkPathIsDashboardPath(urlPath);

  if (!isDashboardPath) {
    return false;
  }

  const progressPath = prop(PROGRESS_PAGE)(PAGEID_TO_PATH);
  const allowedPaths = [progressPath];

  return allowedPaths.some(pagePath => {
    return urlPath?.includes(pagePath?.main);
  });
};

export {
  shouldDisableSelectLayer,
  getSelectedQuickFilterFromDefaultFilters,
  checkPathIsDashboardPath,
  shouldDisplayDashboardViewName,
};
