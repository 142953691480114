import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SvgComponent(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="115" height="105" viewBox="0 0 115 105" {...props}>
      <defs>
        <linearGradient id="linear-gradient" y1="0.249" x2="0.957" y2="0.816" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#3a9ab3" />
          <stop offset="1" stopColor="#66bbd1" />
        </linearGradient>
        <filter id="Path_16113" x="0" y="0" width="115" height="105" filterUnits="userSpaceOnUse">
          <feOffset dy="4" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="7.5" result="blur" />
          <feFlood floodOpacity="0.263" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_9684" data-name="Group 9684" transform="translate(-683.662 -378.952)">
        <g transform="matrix(1, 0, 0, 1, 683.66, 378.95)" filter="url(#Path_16113)">
          <path
            id="Path_16113-2"
            data-name="Path 16113"
            d="M775.548,431.394c-.967-.932-2.546-.884-3.785-1.111,4.047-4.6,4.673-7.712,
            3.9-9.054s-2.746-.959-6.36-.144c-.114-.827-.213-1.626-.3-2.423a17.226,17.226,0,0,0,
            1.125-6.377c-.377-8.941-8.811-18.714-9.305-13.258-.17,
            1.883-.046,3.748-.879,5.5a8.554,8.554,0,0,1-3.292,3.591,9.592,9.592,0,0,1-1.651.79,15.675,15.675,0,0,1-5.95.73,
            136.709,136.709,0,0,0-15.778,0,15.144,15.144,0,0,1-5.95-.731,9.452,9.452,0,0,1-1.649-.789,8.546,8.546,0,0,
            1-3.294-3.589c-.832-1.755-.707-3.617-.879-5.5-.494-5.455-8.927,4.317-9.305,13.258a17.213,17.213,0,0,0,1.125,
            6.375c-.088.8-.189,1.6-.3,2.425-3.613-.815-5.589-1.2-6.359.144s-.145,4.456,3.9,9.054c-1.239.228-2.817.177-3.783,
            1.111a2.189,2.189,0,0,0-.616,1.588,6.146,6.146,0,0,0,2.211,4.06,9.016,9.016,0,0,0,2.574,1.7,17.012,17.012,0,0,0,
            3.7,1.334V442.4a6.6,6.6,0,0,0,6.624,6.493h7.563l0,0h2.369v7.321a1.25,1.25,0,0,0,1.979,1l12.924-8.319H761.05a6.6,
            6.6,0,0,0,6.622-6.494v-2.324a16.94,16.94,0,0,0,3.706-1.334,9.028,9.028,0,0,0,2.574-1.7,6.151,6.151,0,0,0,
            2.21-4.06A2.193,2.193,0,0,0,775.548,431.394Z"
            transform="translate(-683.66 -378.95)"
            fill="url(#linear-gradient)"
          />
        </g>
        <path
          id="Path_16114"
          data-name="Path 16114"
          d="M725.376,407.741l3.7-5.251a1.824,1.824,0,0,1,2.568,0l3.7,5.251s.333.081-1.283.081h-7.4Z"
          transform="translate(10.801 2.567)"
          fill="#4aa6be"
        />
        <rect
          id="Rectangle_5424"
          data-name="Rectangle 5424"
          width="43.155"
          height="22.633"
          rx="9"
          transform="translate(719.585 419.374)"
          fill="#fff"
        />
        <g id="Group_9684-2" data-name="Group 9684" transform="translate(723.796 424.676)">
          <path
            id="Path_16115"
            data-name="Path 16115"
            d="M723.81,415.6a5.18,5.18,0,0,0-5.877,2.558c-2.236,4.322,2.26,8.673,5.978,10.152,1.8.714,4.858,1.532,
            6.051-.6.944-1.687.529-4.28-.019-6.028A8.993,8.993,0,0,0,723.81,415.6Z"
            transform="translate(-717.335 -415.436)"
            fill="#4ba7bf"
          />
          <path
            id="Path_16116"
            data-name="Path 16116"
            d="M737.7,415.6a5.179,5.179,0,0,1,5.877,2.558c2.236,4.322-2.26,8.673-5.979,10.152-1.8.714-4.858,
            1.532-6.051-.6-.944-1.687-.527-4.28.019-6.028A9,9,0,0,1,737.7,415.6Z"
            transform="translate(-709.442 -415.436)"
            fill="#4ba7bf"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
