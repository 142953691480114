import reduceReducers from 'reduce-reducers';

import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';

import {
  FETCH_TIMELINE_LIGHTBOX_PROJECTS,
  FETCH_TIMELINE_LIGHTBOX_PROJECTS_FULFILLED,
  FETCH_TIMELINE_LIGHTBOX_PROJECTS_PENDING,
  CLEAR_TIMELINE_LIGHTBOX_PROJECTS,
} from './types';

import { parentsAdapter, projectsAdapter } from './helpers/adapters';

export const initialState = {
  projects: projectsAdapter.getInitialState(),
  parents: parentsAdapter.getInitialState(),
  totalAllLayers: 0,
  totalByLayer: {},
  operations: bulkThunkInitialState([FETCH_TIMELINE_LIGHTBOX_PROJECTS]),
};

const timelineLightboxReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TIMELINE_LIGHTBOX_PROJECTS_PENDING:
      return {
        ...state,
        totalAllLayers: 0,
        totalByLayer: {},
        projects: projectsAdapter.getInitialState(),
        parents: parentsAdapter.getInitialState(),
      };

    case FETCH_TIMELINE_LIGHTBOX_PROJECTS_FULFILLED:
      const { projects, parents, projectsSearchMetadataResult } = action.payload;

      return {
        ...state,
        totalAllLayers: projects?.length,
        totalByLayer: projectsSearchMetadataResult?.totalByLayer,
        projects: projectsAdapter.setAll(state?.projects, projects),
        parents: parentsAdapter.setAll(state?.parents, parents),
      };
    case CLEAR_TIMELINE_LIGHTBOX_PROJECTS:
      return initialState;
    default: {
      return state;
    }
  }
};

const operationsReducer = getThunksReducers([FETCH_TIMELINE_LIGHTBOX_PROJECTS]);

const reducer = reduceReducers(initialState, timelineLightboxReducer, ...operationsReducer);

export default reducer;
