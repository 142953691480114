import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import { updateState } from 'store/staffing';
import useSystemFields from 'hooks/useSystemFields';
import { getDisplayLayer } from 'store/filters/selectors';
import { getOrgHasBet } from 'store/organization/selectors';
import { openProjectLightbox } from 'store/projectLightbox';

import useUpdateTask from '../hooks/useUpdateTask';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const lsState = useSelector(state => state.staffing);
    const { selectedTask, hideTasksName } = lsState;
    const _updateLsState = state => dispatch(updateState(state));
    const [handleTaskUpdate] = useUpdateTask();
    const [getSystemFieldName] = useSystemFields();
    const displayLayer = useSelector(state => getDisplayLayer(state));
    const orgHasBet = useSelector(state => getOrgHasBet(state));

    const _showLightbox = selectedTask => _updateLsState({ selectedTask });
    const _hideLightbox = () => _updateLsState({ selectedTask: null });
    const _onSaveTaskDetails = (task, createAnother, ignoreDraftMode = false) => {
      const method = task.$new ? 'inserted' : 'updated';

      task.$new = false;
      handleTaskUpdate(task.id, method, cloneDeep(task), ignoreDraftMode);
      _hideLightbox();

      if (createAnother) {
        setTimeout(() => _showLightbox({ ...task, $new: true }), 0);
      }
    };
    const _onCancelTask = id => {
      if (selectedTask.$new && props.scheduler) props.scheduler.deleteEvent(selectedTask.id);
      _hideLightbox();
    };
    const _onDeleteTask = task => {
      handleTaskUpdate(task.id, 'deleted', task);
      _hideLightbox();
    };
    const _onCloneTask = (task, cloneAnother) => {
      const method = cloneAnother ? 'inserted' : 'updated';
      const title = cloneAnother ? task.title : `${task.title} [CLONE]`;

      handleTaskUpdate(task.id, method, task);
      _hideLightbox('task');

      const cloneTask = {
        ...task,
        ...{ title, $new: true },
      };

      setTimeout(() => _showLightbox(cloneTask), 0);
    };
    const _openProjectLightbox = id => dispatch(openProjectLightbox(id));

    return (
      <Component
        {...props}
        selectedTask={selectedTask}
        onSaveTaskDetails={_onSaveTaskDetails}
        onCancelTask={_onCancelTask}
        onDeleteTask={_onDeleteTask}
        onCloneTask={_onCloneTask}
        getSystemFieldName={getSystemFieldName}
        displayLayer={displayLayer}
        hideTasksName={hideTasksName}
        orgHasBet={orgHasBet}
        openProjectLightbox={_openProjectLightbox}
      />
    );
  };
};

export default componentHOC;
