import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import groupBy from 'lodash/groupBy';

import { ROADMAP_PAGE } from 'constants/filters';
import { getState } from 'store/timelines/selectors';
import useProjectGroups from 'hooks/useProjectGroups';
import { helpers as timelineHelpers } from 'design-system/organisms/Timeline';
import { idSearchIdeaString } from 'utils';
import { shouldHideEmptyBasedOnUserPref } from 'utils/projects/grouping';

import { getIntegrationTimeSpan, getProjectMapper, planningStages } from '../helpers/getProjectMapper';

const isSnapToPredictedEndDate = option => timelineHelpers.snapToGridOptions.PREDICTED_END_DATE === option;

const hasTimeline = project => {
  if (!project.estimated_start_date || !project.deadline) {
    return false;
  }

  return (project.integrationProgress && project.integrationProgress.endDate) || project.deadline;
};

const useSwimlaneGroups = (filteredMetadata, projects) => {
  const lsState = useSelector(getState(ROADMAP_PAGE));

  const {
    searchString, // Project title search string
    selectedColorBy, // Field name to set bar color
    swimlaneType, // lane type
    grouplaneType, // group lane type
    displayMilestone, // show milestone on top lane
    displayMilestoneOn, // show milestone on lane
    displayTimelineBy, // row field name
    hideEmptyLane,
    snapToGridOn,
    showItemsWithoutTimeline,
  } = {
    hideEmptyLane: true,
    ...lsState,
  };

  // Filter project title by search string
  const _passesSearchFilter = useCallback((p, search = '') => {
    const { id, title } = p;
    const titleFilter = !title || title.toLowerCase().includes(search.toLowerCase());
    const idFilter = !id || id.toString().toLowerCase().includes(idSearchIdeaString(search));
    const taskTitleFilter = p.tasks ? p.tasks.some(t => t.title?.toLowerCase().includes(search?.toLowerCase())) : false;

    return !search || titleFilter || idFilter || taskTitleFilter;
  }, []);

  const projectsFilter = useCallback(
    project => {
      const integrationTimespan = project?.integrationProgress ? getIntegrationTimeSpan(project.integrationProgress) : null;

      const shouldShowItemsWithoutTimeline = showItemsWithoutTimeline || hasTimeline(project);

      return (
        (project.type !== 'milestone' ||
          (project.type === 'milestone' && displayMilestone && displayMilestoneOn !== 'topLane')) &&
        planningStages[project.planningStage] &&
        (isSnapToPredictedEndDate(snapToGridOn) ? integrationTimespan?.endDate : true) &&
        shouldShowItemsWithoutTimeline
      );
    },
    [displayMilestone, displayMilestoneOn, searchString, planningStages, snapToGridOn, showItemsWithoutTimeline],
  );

  const milestoneFilter = useCallback(
    project =>
      project.type === 'milestone' &&
      displayMilestone &&
      displayMilestoneOn === 'topLane' &&
      (showItemsWithoutTimeline || project?.estimated_start_date),
    [displayMilestone, displayMilestoneOn, searchString, showItemsWithoutTimeline],
  );

  const projectMapper = useMemo(
    () => getProjectMapper(selectedColorBy, displayTimelineBy, grouplaneType, swimlaneType),
    [selectedColorBy, displayTimelineBy, grouplaneType, swimlaneType],
  );

  const projectsByLayer = groupBy(
    projects.filter(project => _passesSearchFilter(project, searchString)),
    project => project?.layer,
  );

  const projectGroups = useProjectGroups({
    selectedGroup1: grouplaneType,
    selectedGroup2: swimlaneType,
    page: ROADMAP_PAGE,
    onlyProjects: false,
    hideEmptyHandler: shouldHideEmptyBasedOnUserPref,
    hideEmptyUserPref: hideEmptyLane,
    projectsFilters: [projectsFilter, milestoneFilter],
    projectMapper,
    customAllProjectsByLayer: projectsByLayer,
  });

  const allGroupsProjects = useMemo(
    () =>
      projectGroups.reduce((acc, group) => {
        if (group.level === 1) {
          return swimlaneType?.key
            ? [...acc, ...group.elements.reduce((acc, group) => [...acc, ...(group?.elements ?? [])], [])]
            : [...acc, ...group.elements];
        }

        if (group.level === 2) {
          return [...acc, ...group.elements];
        }

        return acc;
      }, []),
    [projectGroups],
  );

  return { projectGroups, allGroupsProjects };
};

export default useSwimlaneGroups;
