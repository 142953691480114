import React from 'react';
import styled from 'styled-components';

import { spacing } from 'design-system/theme';
import SendToIntegrationChannelButton from 'design-system/molecules/SendToIntegrationChannelButton';
import useHasIntegrationsOfType from 'hooks/integrations/useHasIntegrationsOfType';
import useSendToIntegrationChannel from 'hooks/integrations/useSendToIntegrationChannel';
import { INTEGRATIONS_KEYS } from 'constants/integrations';
import { removeHtmlTags } from 'utils';

const ShareOnSlack = ({ announcement, onSend }) => {
  const hasSlackIntegration = useHasIntegrationsOfType(INTEGRATIONS_KEYS.slack);
  const { channels, selectedChannel, setSelectedChannel } = useSendToIntegrationChannel(INTEGRATIONS_KEYS.slack);

  const sendButtonIsDisabled = !selectedChannel || !removeHtmlTags(announcement);

  const handleOnSend = () => {
    onSend(selectedChannel, INTEGRATIONS_KEYS.slack);
    setSelectedChannel(null);
  };

  return hasSlackIntegration ? (
    <Flex>
      <SendToIntegrationChannelButton
        integrationType={INTEGRATIONS_KEYS.slack}
        onSend={handleOnSend}
        channels={channels}
        selectedChannel={selectedChannel}
        setSelectedChannel={setSelectedChannel}
        isDisabled={sendButtonIsDisabled}
      />
    </Flex>
  ) : null;
};

export default ShareOnSlack;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${spacing()}px;
`;
