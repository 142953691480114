import { useSelector } from 'react-redux';

import { getAllInitiatives, getAllBets } from 'store/projects/selectors';

export default () => {
  const initiatives = useSelector((state) => getAllInitiatives(state));
  const bets = useSelector((state) => getAllBets(state));
  const hasHierarchy = useSelector((state) => state.organization.organization.has_hierarchy);
  const hasBet = useSelector((state) => state.organization.organization.has_bet);

  return [initiatives, bets, hasHierarchy, hasBet];
};
