import { numericColumnDef } from 'design-system/molecules/AgGridReact-New/columns';

import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';

import getSystemFieldName from 'utils/getSystemFieldName';
import {
  getIntegrationTimeEstimate,
  getIntegrationStoryPoints,
  getIntegrationIssuesCount,
} from 'containers/IdeasList/IdeasList/New/hooks/useIdeasGridColumnsDefinitions/helpers';
import theme from 'design-system/theme';
import shortTextColumnDef from 'design-system/molecules/AgGridReact-New/columns/shortTextColumnDef';
import { EmptyValueIfNotProjectRenderer } from
'containers/IdeasList/IdeasList/New/hooks/useIdeasGridColumnsDefinitions/renderers';
import { TextCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';

const POSSIBLE_COLUMNS_KEYS = [
  'status_color',
  'roadmapTitle',
  'progress',
  'integration',
  'integrationIssueType',
  'ownerName',
  'parent',
  'categoryTitle',
  'themeTitle',
  'timeframeTitle',
  'objectiveTitle',
  'priorityTitle',
  'product1Title',
  'product2Title',
  'keyResult1Title',
  'keyResult2Title',
  'timeEstimate',
  'storyPoint',
  'issueCounts',
  'updatedByName',
  'tags',
  'customers',
  'planningStage',
  'phaseTitle',
  'status_summary',
  'predictedEndDate',
  'deadline',
  'estimated_start_date',
  'links',
  'details',
  'planned_moar',
  'benefit1',
  'benefit2',
  'benefit3',
  'cost1',
  'priorityScore',
  'riceScore',
  'reach_score',
  'impact_score',
  'confidence_score',
  'effort_score',
  'sum',
];

export const setColumnDefsVisibility = (originalColumnDefs, visibleFields, customFields) => {
  const possibleColumnsKeys = POSSIBLE_COLUMNS_KEYS;

  if (!isEmpty(customFields)) {
    customFields.forEach(field => {
      possibleColumnsKeys.push(field.key);
    });
  }

  const columnDefs = originalColumnDefs.map(col => col.colDef);

  columnDefs.sort((first, second) => {
    const a = visibleFields.findIndex(item => item.key === first.field);
    const b = visibleFields.findIndex(item => item.key === second.field);

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  columnDefs.forEach((colDef, index) => {
    possibleColumnsKeys.forEach(key => {
      if (colDef.field === key) colDef.hide = !visibleFields.some(field => field.key === key);
    });
  });

  return columnDefs;
};

const AUTOCOMPLETE_FIELDS = {
  categoryTitle: systemFields => getSystemFieldName('category', systemFields),
  themeTitle: systemFields => getSystemFieldName('theme', systemFields),
  timeframeTitle: systemFields => getSystemFieldName('timeframe', systemFields),
  priorityTitle: systemFields => getSystemFieldName('priority', systemFields),
  phaseTitle: systemFields => getSystemFieldName('phase', systemFields),
};

export const generateAutocompleteFields = systemFields => {
  return Object.entries(AUTOCOMPLETE_FIELDS).map(([key, value]) => {
    return {
      field: key,
      headerName: isString(value) ? value : value(systemFields),
      cellRenderer: EmptyValueIfNotProjectRenderer(TextCellRenderer),
      comparator: stringComparator,
      width: 120,
    };
  });
};

const NUMERIC_FIELDS = {
  storyPoint: {
    label: 'Reported Points',
    valueGetter: getIntegrationStoryPoints,
    width: 80,
  },
  issueCounts: {
    label: 'Issue Count',
    valueGetter: getIntegrationIssuesCount,
    width: 80,
  },
  priorityScore: {
    label: 'Total Score',
    width: 100,
  },
  reach_score: {
    label: 'Reach Score',
    width: 100,
  },
  impact_score: {
    label: 'Impact Score',
    width: 100,
  },
  confidence_score: {
    label: 'Confidence Score',
    width: 100,
  },
  effort_score: {
    label: 'Effort',
    width: 100,
    aggFunc: 'sumNumFieldsValue',
  },
};

export const generateNumericFields = () => {
  return Object.entries(NUMERIC_FIELDS).map(([key, value]) => ({
    ...numericColumnDef,
    editable: false,
    field: key,
    headerName: value.label,
    width: value.width,
    valueGetter: value.valueGetter,
    aggFunc: value.aggFunc,
  }));
};

const TEXT_FIELDS = {
  timeEstimate: {
    label: 'Time Estimate',
    valueGetter: getIntegrationTimeEstimate,
    width: 80,
  },
  updatedByName: {
    label: 'Updated By',
    width: 100,
  },
  benefit1: {
    label: 'Strategic Benefit (0-5)',
    width: 100,
  },
  benefit2: {
    label: 'Urgency (0-5)',
    width: 100,
  },
  benefit3: {
    label: 'Frequency (0-5)',
    width: 100,
  },
  cost1: {
    label: 'Cost (0-5)',
    width: 100,
  },
};

export const generateTextFields = () => {
  return Object.entries(TEXT_FIELDS).map(([key, value]) => ({
    ...shortTextColumnDef,
    editable: false,
    field: key,
    headerName: value.label,
    width: value.width,
    valueGetter: value.valueGetter,
  }));
};

const getListGroupColor = groupLevel => {
  if (groupLevel === 1) {
    return theme?.palette?.background?.gallery;
  }
  if (groupLevel === 2) {
    return theme?.palette?.background?.lightGallery;
  }

  return theme?.palette?.background?.white;
};

export const getRowStyle = params => {
  if (params.data?.group) {
    const levelColor = getListGroupColor(params.node.level + 1);

    return { backgroundColor: levelColor };
  }

  return { backgroundColor: theme?.palette?.background?.white };
};
