import moment from 'moment-timezone';
import isString from 'lodash/isString';

const dateComparator = (date1, date2, _1, _2, isInverted) => {
  if (!date1 && !date2) {
    return 0;
  }

  const momentDate1 = isString(date1) ? moment(date1) : date1;
  const momentDate2 = isString(date2) ? moment(date2) : date2;

  if ((!momentDate1 || !momentDate1?.isValid()) && momentDate2 && momentDate2.isValid()) {
    return isInverted ? -1 : 1;
  }

  if (momentDate1?.isValid() && (!momentDate2 || !momentDate2?.isValid())) {
    return isInverted ? 1 : -1;
  }

  if (momentDate1?.isSame(momentDate2)) {
    return 0;
  }

  return momentDate1?.isAfter(momentDate2) ? 1 : -1;
};

export default dateComparator;
