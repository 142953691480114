import React from 'react';
import styled from 'styled-components';

import ActionsBar from './ActionsBar';
import PivotTable from './PivotTable';

export default ({ tableData }) => {
  return (
    <Wrapper>
      <ActionsBar />
      <TableWrapper>
        <PivotTable data={tableData} />
      </TableWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: calc(100vh - 68px);
  overflow: auto;
  padding: 0 22px;
`;

const TableWrapper = styled.div`
  overflow: auto;
  max-height: calc(100vh - 128px);
  height: 100vh;
  width: 100%;
  position: relative;
`;
