import React from 'react';
import styled from 'styled-components';
import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';

export default function ({ handleClick, iconFontSize = 16 }) {
  return (
    <StyledButton color="primary" onClick={handleClick} size="small" disableRipple>
      <StyledIcon fontSize="inherit" iconFontSize={iconFontSize} />
    </StyledButton>
  );
}

const StyledButton = styled(IconButton)`
  &&&&& {
    align-self: flex-end;
    padding: 3px;
  }
`;

const StyledIcon = styled(LaunchIcon)`
  &&&&& {
    font-size: ${({ iconFontSize }) => iconFontSize}px !important;
  }
`;
