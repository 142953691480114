import React from 'react';
import PublicPageTemplateComponent from 'design-system/templates/PublicPageTemplate/PublicPageTemplateComponent';
import ForgotWorkspaceFormComponent from './ForgotWorkspaceForm/ForgotWorkspaceFormComponent';

export default ({ email, handleEmailValue, handleSubmit }) => {
  return (
    <PublicPageTemplateComponent>
      <ForgotWorkspaceFormComponent email={email} handleEmailValue={handleEmailValue} handleSubmit={handleSubmit} />
    </PublicPageTemplateComponent>
  );
};
