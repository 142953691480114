import React from 'react';
import styled, { css } from 'styled-components';

const TabButton = ({ children, onClick, active, ...props }) => {
  return (
    <Button onClick={onClick} active={active} {...props}>
      {children}
    </Button>
  );
};

export default TabButton;

const Button = styled.button`
  position: relative;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  line-height: ${({ theme }) => theme.typography.lineHeightRegularSmallRem}rem;
  color: ${({ theme }) => theme.palette.text.actionLabel.primary};
  border: none;
  background: none;
  padding: 0;
  outline: none;
  white-space: nowrap;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.palette.text.primary};
    transform-origin: left;
    transform: scale(0);
    transition: transform 0.2s ease-in-out;
  }

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.palette.text.primary};

      &:after {
        transform: scale(1);
      }
    `}
`;
