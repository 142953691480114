import axios from 'axios';
import { pick } from 'ramda';

import throwRequestError from '../utils/throwRequestError';

import {
  MERGE_TEAMS,
  CREATE_TEAM,
  UPDATE_TEAM,
  DELETE_TEAM,
  CREATE_TEAMS,
  UPDATE_TEAMS,
  UNDO_CREATE_TEAMS,
  UNDO_UPDATE_TEAMS,
  BULK_DELETE_TEAMS,
  UNDO_BULK_DELETE_TEAMS,
  SWITCH_TEAMS_ROW_ORDER,
} from './types';
import bulkCreateAction from 'store/utils/factory/bulkCreateAction';
import bulkUpdateAction from 'store/utils/factory/bulkUpdateAction';
import bulkDeleteAction from 'store/utils/factory/bulkDeleteAction';
import moveRowToPosition from 'utils/moveRowToPosition';

export function switchTeamsRowOrder(id1, id2, _, position) {
  return async (dispatch, getState) => {
    const state = getState().teams.teams || [];
    const selectedTeam = state.find(({ id }) => id === +id1);
    const moveToCloseTeam = state.find(({ id }) => id === +id2);
    const movedRow = moveRowToPosition(state, id1, id2, position);

    const movedToParentWithoutChildren = selectedTeam.parent_id && !moveToCloseTeam.parent_id;
    const movedToDifferentParent = selectedTeam.parent_id !== moveToCloseTeam.parent_id;

    if (movedToParentWithoutChildren) {
      await dispatch(updateTeamById(selectedTeam.id, { parent_id: moveToCloseTeam.id }));
    } else if (movedToDifferentParent) {
      await dispatch(updateTeamById(selectedTeam.id, { parent_id: moveToCloseTeam.parent_id }));
    }

    const promise = axios.put(`/api/teams/rowOrder/${id1}/${id2}`, { position }).then(res => res.data);

    return dispatch({
      type: SWITCH_TEAMS_ROW_ORDER,
      payload: {
        promise,
        data: movedRow,
      },
      meta: { prev: selectedTeam },
    });
  };
}

export const createTeam = team => {
  return dispatch => {
    const payload = axios
      .post('/api/teams', team)
      .then(response => response.data)
      .catch(throwRequestError);

    dispatch({
      type: CREATE_TEAM,
      payload,
    });

    return payload;
  };
};

export const updateTeamById = (id, update) => {
  return dispatch => {
    if (update.cost === '') delete update.cost;

    const payload = axios
      .put(`/api/teams/${id}`, update)
      .then(response => response.data)
      .catch(throwRequestError);

    dispatch({
      type: UPDATE_TEAM,
      payload,
    });

    return payload;
  };
};

export const deleteTeamById = id => {
  return dispatch => {
    const payload = axios
      .delete(`/api/teams/${id}`)
      .then(response => response.data)
      .catch(throwRequestError);

    dispatch({
      type: DELETE_TEAM,
      payload,
    });

    return payload;
  };
};

export function mergeTeams(teamsIdsToMerge, teamId) {
  return dispatch => {
    const payload = axios
      .post(`/api/teams/merge/${teamId}`, {
        teamsIdsToMerge,
      })
      .then(response => {
        return response.data;
      })
      .catch(throwRequestError);

    dispatch({
      type: MERGE_TEAMS,
      payload,
    });

    return payload;
  };
}

export const bulkCreateTeams = bulkCreateAction(CREATE_TEAMS, '/api/teams', {
  toastText: 'Teams have been created',
  ACTION_TYPE: UNDO_CREATE_TEAMS,
  endpoint: '/api/teams/versions/last',
  store: 'teams',
});
export const bulkUpdateTeams = bulkUpdateAction(UPDATE_TEAMS, '/api/teams', {
  toastText: 'Teams have been changed',
  ACTION_TYPE: UNDO_UPDATE_TEAMS,
  endpoint: '/api/teams/versions/last',
  store: 'teams',
});
export const bulkDeleteTeams = bulkDeleteAction(BULK_DELETE_TEAMS, '/api/teams/', {
  toastText: 'Teams have been deleted',
  ACTION_TYPE: UNDO_BULK_DELETE_TEAMS,
  endpoint: '/api/teams/versions/last',
  store: 'teams',
});

export const updateTeamEstimationSettingsById = (id, update) => {
  return dispatch => {
    const estimationSettingsFields = pick(['estimate_by', 'workdays_per_point'], update);

    const payload = axios
      .put(`/api/teams/${id}`, estimationSettingsFields)
      .then(response => response.data)
      .catch(throwRequestError);

    dispatch({
      type: UPDATE_TEAM,
      payload,
    });

    return payload;
  };
};
