import React, { useMemo } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { PAGE_HEADER_HEIGHT } from 'constants/common';
import { spacing } from 'design-system/theme';
import ChartWidget from './ChartWidget';
import DescriptionEditor from './DescriptionEditor/DescriptionEditor';
import { sortWidgetsByCoordinates } from './helpers/widgets';

const MobileDashboardsComponent = ({ dashboardView, dashboardState, dashboardWidgets }) => {
  const sortedWidgets = useMemo(() => sortWidgetsByCoordinates(dashboardWidgets), [dashboardWidgets]);

  return (
    <Wrapper>
      <DescriptionEditor description={dashboardView?.description || ''} inEditMode={false} />
      <ChartsWrapper>
        {dashboardWidgets &&
          sortedWidgets
            .filter(widget => !!widget.component)
            .map(widget => <ChartWidget widget={widget} dashboardState={dashboardState} disableClick />)}
      </ChartsWrapper>
    </Wrapper>
  );
};

export default withRouter(MobileDashboardsComponent);

const Wrapper = styled.div`
  position: relative;
  padding: ${spacing(2)}px;
  height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
  overflow: auto;
`;

const ChartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing(2)}px;
  margin-top: ${spacing(2)}px;
`;
