import React, { useState, useRef } from 'react';
import Popover from '@material-ui/core/Popover';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import styled from 'styled-components';

import { spacing } from 'design-system/theme';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import SwitchWithLabel from 'design-system/molecules/SwitchWithLabel/SwitchWithLabel';

import useSystemFields from 'hooks/useSystemFields';

import {
  defaultAvailableNodes,
  BET_NODE,
  IDEA_NODE,
  INITIATIVE_NODE,
  KEYRESULT1_NODE,
  KEYRESULT2_NODE,
  METRIC_NODE,
  OBJECTIVE_NODE,
} from 'features/Canvas/constants';

const RelationsConfigDropdown = ({
  relationsConfig = {},
  displayPreferences = {},
  onToggleOkrRelations,
  onToggleOkrMetricsRelations,
  onToggleOkrProjectsRelations,
  onTogglePortfolioItemsRelations,
  onToggleBoardRelations,
  onToggleMetricRelations,
  onToggleHighlightHealth,
  onToggleOwnerAvatar,
  onToggleProgress,
  onToggleStatus,
  availableNodes = defaultAvailableNodes,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const wrapperRef = useRef();
  const [getSystemFieldName] = useSystemFields();

  const hasProjectNodes = availableNodes[BET_NODE] || availableNodes[INITIATIVE_NODE] || availableNodes[IDEA_NODE];
  const hasOkrNodes = availableNodes[OBJECTIVE_NODE] || availableNodes[KEYRESULT1_NODE] || availableNodes[KEYRESULT2_NODE];
  const hasMetricNodes = availableNodes[METRIC_NODE];

  return (
    <div ref={wrapperRef}>
      <ButtonIcon title="Show/hide link" onClick={() => setDialogOpen(true)}>
        <MoreHorizIcon />
      </ButtonIcon>
      <Popover
        anchorEl={wrapperRef.current}
        open={dialogOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ClickAwayListener onClickAway={() => setDialogOpen(false)}>
          <Options>
            <StyledSwitchWithLabel
              label="Add/edit connectors"
              switchProps={{
                color: 'primary',
                checked: relationsConfig.board,
                onChange: onToggleBoardRelations,
              }}
            />
            {hasOkrNodes && (
              <StyledSwitchWithLabel
                label={`Link between ${getSystemFieldName('objective', true)}`}
                switchProps={{
                  color: 'primary',
                  checked: relationsConfig.okrs,
                  onChange: onToggleOkrRelations,
                }}
              />
            )}
            {hasOkrNodes && hasMetricNodes && (
              <StyledSwitchWithLabel
                label={`Link between ${getSystemFieldName('objective', true)} / ${getSystemFieldName('metric', true)}`}
                switchProps={{
                  color: 'primary',
                  checked: relationsConfig.okrsMetrics,
                  onChange: onToggleOkrMetricsRelations,
                }}
              />
            )}
            {hasOkrNodes && hasProjectNodes && (
              <StyledSwitchWithLabel
                label={`Link between ${getSystemFieldName('objective')} & ${getSystemFieldName('roadmap')}`}
                switchProps={{
                  color: 'primary',
                  checked: relationsConfig.okrsProjects,
                  onChange: onToggleOkrProjectsRelations,
                }}
              />
            )}
            {hasMetricNodes && hasProjectNodes && (
              <StyledSwitchWithLabel
                label={`Link between ${getSystemFieldName('metric')} & ${getSystemFieldName('roadmap')}`}
                switchProps={{
                  color: 'primary',
                  checked: relationsConfig.metrics,
                  onChange: onToggleMetricRelations,
                }}
              />
            )}
            {hasProjectNodes && (
              <StyledSwitchWithLabel
                label={`Link between ${getSystemFieldName('roadmap')} hierarchy`}
                switchProps={{
                  color: 'primary',
                  checked: relationsConfig.projects,
                  onChange: onTogglePortfolioItemsRelations,
                }}
              />
            )}
            <StyledSwitchWithLabel
              label="Highlight Health"
              switchProps={{
                color: 'primary',
                checked: displayPreferences.highlightHealth,
                onChange: onToggleHighlightHealth,
              }}
            />
            <StyledSwitchWithLabel
              label="Owner Avatar"
              switchProps={{
                color: 'primary',
                checked: displayPreferences.ownerAvatar,
                onChange: onToggleOwnerAvatar,
              }}
            />
            <StyledSwitchWithLabel
              label="Progress"
              switchProps={{
                color: 'primary',
                checked: displayPreferences.progress,
                onChange: onToggleProgress,
              }}
            />
            <StyledSwitchWithLabel
              label="Status"
              switchProps={{
                color: 'primary',
                checked: displayPreferences.status,
                onChange: onToggleStatus,
              }}
            />
          </Options>
        </ClickAwayListener>
      </Popover>
    </div>
  );
};

const Options = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing()}px ${spacing(2)}px;
`;

const StyledSwitchWithLabel = styled(SwitchWithLabel)`
  height: 32px;
`;

export default RelationsConfigDropdown;
