/**
 * Enum for title types.
 * @readonly
 * @enum {number}
 */
const TitleType = {
  PORTFOLIO: 1,
  INITIATIVE: 2,
  BET: 3,
};

export default TitleType;
