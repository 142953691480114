import React from 'react';

import { planningStages } from 'utils';
import useLoadProjectsOnAutocomplete from 'hooks/useLoadProjectsOnAutocomplete';

const componentHOC = Component => {
  return props => {
    const [loadProjects, projects] = useLoadProjectsOnAutocomplete({
      planningStage: planningStages.filter(s => s !== 'Archived').join(','),
      ...(props.filters || {}),
    });
    const _onValueChange = (value, text, selectedOption) => {
      let projectSelected;

      if (selectedOption && selectedOption.entity) {
        projectSelected = selectedOption.entity;
      } else {
        projectSelected = projects.find(p => p.id === value || p.title.includes(value));
      }

      props.onValueChange(projectSelected);
    };

    return <Component {...props} loadProjects={loadProjects} options={projects} onValueChange={_onValueChange} />;
  };
};

export default componentHOC;
