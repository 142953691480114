import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import ToastMessage from 'design-system/atoms/ToastMessage/index';

import { not, isNil, pipe } from 'ramda';

// Dragonboat imports
import { getCurrentUser } from 'store/login/selectors';
import { getGridConfigValue, getGridState } from 'store/grids/selectors';
import { removeSelectedItem, saveGridConfig, saveGridSelectedView, saveUserGridView } from 'store/grids';
import { showImportIdeasLightbox } from 'store/ideas';
import { DEFAULT_GROUP_OPTION } from 'store/projects/helpers/groupOptions';
import { getGroupOptionsSelector } from 'store/projects/groupSelectors';
import { getDisplayLayer, usePortfolioMode } from 'store/filters/selectors';
import { getOrganization, getOrgJiraIntegrations } from 'store/organization/selectors';
import { getDefaultPhaseByPlanningStage } from 'store/phases/selectors';
import { setAppGlobalConfig } from 'store/app';
import { bulkUpdateProjects, deleteProjects, mergeProjects } from 'store/projects';

import useSystemFields from 'hooks/useSystemFields';
import useActiveViewChanged from 'hooks/userViews/useActiveViewChanged';
import useEnrichedSelectedGroupsFromState from 'hooks/grid/useGridSelectedGroups';

import { TOAST_MESSAGE_TYPES } from 'design-system/constants';

import { processCellOnExport } from './helpers';
import downloadPNG from 'utils/downloadPNG';
import getJiraIntegrationWarnings from 'utils/getJiraIntegrationWarnings';
import { isForecastPage } from 'utils/pages';

import { ARCHIVED_PLANNING_STAGE } from 'constants/projectLightbox';
import { BASE_ROW_HEIGHT, SHOW_UNCOMMITTED_PROJECTS } from 'constants/grid';
import { DEFAULT_SELECTED_VIEW, isEstimatesView } from 'constants/ideas';
import { IDEAS_ESTIMATES_PAGE, SCENARIO_LIST_PAGE } from 'constants/filters';
import { JIRA_PROJECT_RENAMED_WARNING } from 'constants/integrations';
import { FORECAST_LIST_FORECAST_BY_OPTION_KEY, FORECAST_BY_TIME } from 'constants/forecast';
import useScenariosListDialog from 'hooks/useScenariosListDialog/useScenariosListDialog';
import useScenarioToolbarControls from 'containers/ScenarioToolbarControls/hooks/useScenarioToolbarControls';
import useScenariosNavigation from 'hooks/useScenariosNavigation';
import useMetadataGroupByOptions from 'hooks/useMetadataGroupByOptions';

import { INCLUDE_ALL_OPTION } from 'constants/projects';

const isNotNil = pipe(isNil, not);
const GROUP_COLUMN_ID = 'ag-Grid-AutoColumn';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [getSystemFieldName] = useSystemFields();
    const { customGroupByOptions, customGroupBy2Options, customGroupBy3Options, viewType, getGridApi } = props;
    const [viewWasApplied, setViewWasApplied] = useState(false);
    const [showColumnsDialogVisible, setShowColumnsDialogVisible] = useState(false);
    const { activeViewChanged, switchedToDefaultView } = useActiveViewChanged();
    const { selectedGroup1, selectedGroup2, selectedGroup3 } = useEnrichedSelectedGroupsFromState(viewType);

    const lsState = useSelector(state => ({
      selectedView: getGridConfigValue(state, viewType, 'selectedView', DEFAULT_SELECTED_VIEW(viewType)),
      gridState: getGridState(state, viewType),
      bulkDelete: getGridConfigValue(state, viewType, 'bulkDelete'),
      showAllocationReport: getGridConfigValue(state, viewType, 'showAllocationReport'),
      selectedItems: getGridConfigValue(state, viewType, 'selectedItems'),
      rowsAutoheight: false, // getGridConfigValue(state, viewType, 'rowsAutoheight'),
      rowHeight: getGridConfigValue(state, viewType, 'rowHeight', BASE_ROW_HEIGHT),
      selectedGroup1,
      selectedGroup2,
      selectedGroup3,
      searchText: getGridConfigValue(state, viewType, 'searchText'),
      forecastBy: getGridConfigValue(state, viewType, FORECAST_LIST_FORECAST_BY_OPTION_KEY, FORECAST_BY_TIME),
      showUncommittedProjects: getGridConfigValue(state, viewType, SHOW_UNCOMMITTED_PROJECTS, INCLUDE_ALL_OPTION),
    }));
    const currentUser = useSelector(state => getCurrentUser(state));
    const globalSearch = useSelector(state => state.app.globalSearch);
    const displayLayer = useSelector(getDisplayLayer);
    const organization = useSelector(state => getOrganization(state));
    const hasBet = organization.has_bet;
    const portfolioMode = props.pageId === IDEAS_ESTIMATES_PAGE ? false : useSelector(state => usePortfolioMode(state));
    const orgJiraIntegrations = useSelector(getOrgJiraIntegrations);
    const defaultArchivedPhase = useSelector(state => getDefaultPhaseByPlanningStage(state, ARCHIVED_PLANNING_STAGE));

    const { openScenariosDialog } = useScenariosListDialog();

    const { isCreatingOrViewingScenario, onClickExitDraftMode } = useScenarioToolbarControls();

    const _onUpdateGridConfig = (key, value) => dispatch(saveGridConfig(viewType, key, value));
    const handleToggleUncommittedProjects = value => {
      _onUpdateGridConfig(SHOW_UNCOMMITTED_PROJECTS, value);
    };

    const { navigateToScenarioModule } = useScenariosNavigation();

    const toggleLocalMode = () => {
      const localMode = isCreatingOrViewingScenario;

      if (!localMode) {
        navigateToScenarioModule(SCENARIO_LIST_PAGE);
      } else {
        onClickExitDraftMode();
      }
    };

    const { has_rename_jira_project_enabled: hasRenameJiraProjectEnabled } = organization;
    const warning = hasRenameJiraProjectEnabled && getJiraIntegrationWarnings(orgJiraIntegrations, JIRA_PROJECT_RENAMED_WARNING);

    const _onExportToCsv = () => {
      if (!getGridApi) return;

      const { gridApi, columnApi } = getGridApi() || {};

      if (!gridApi || !columnApi) return;

      const columnState = columnApi.getColumnState();
      const fixedGroupColumn = columnState.find(column => column.colId === GROUP_COLUMN_ID);
      const columnsOrderKeys = Object.values(columnState).map(column => column.colId);

      let visibleColumns = columnApi
        .getColumns()
        .map(col => col.colDef.field)
        .filter(col => columnState.some(colState => colState.colId === col && !colState.hide))
        .sort((colA, colB) => columnsOrderKeys.indexOf(colA) - columnsOrderKeys.indexOf(colB));

      // ensure the title is always present
      if (isNotNil(fixedGroupColumn) && !visibleColumns.includes(fixedGroupColumn.colId)) {
        visibleColumns = [fixedGroupColumn.colId, ...visibleColumns];
      }

      gridApi.exportDataAsCsv({
        fileName: `ideas-${moment().format()}`,
        columnGroups: true,
        columnKeys: visibleColumns,
        processCellCallback: params => processCellOnExport(params),
      });
    };
    const _onUpdateView = value => dispatch(saveGridSelectedView(viewType, value));
    const _onMoreDropdownClick = (e, { id }) => {
      const mapIdToAction = {
        'show-columns': () => setShowColumnsDialogVisible(true),
        'bulk-update': () => _onUpdateGridConfig('bulkDelete', true),
        'save-my-view': () =>
          saveUserGridView(dispatch, viewType)
            .then(() => {
              toast(isEstimatesView(lsState.selectedView) ? 'Current view was saved' : 'Current view was saved as My view');
            })
            .catch(() => {
              toast(
                <ToastMessage
                  title="Saving view failed."
                  description="Please try again, or click the ? to contact support."
                  type={TOAST_MESSAGE_TYPES.ERROR}
                />,
              );
            }),
        'allocation-report': () => _onUpdateGridConfig('showAllocationReport', !lsState.showAllocationReport),
        'download-png': downloadPNG,
        import: () => dispatch(showImportIdeasLightbox()),
        export: () => _onExportToCsv(),
      };
      const action = mapIdToAction[id];

      if (action) action();
    };
    const _clearGridSelection = () => {
      if (!getGridApi) return;

      const { gridApi } = getGridApi() || {};

      if (!gridApi) return;

      dispatch(removeSelectedItem(viewType, lsState.selectedItems));
      gridApi.deselectAll();
    };
    const _onCancelBulkDelete = () => {
      dispatch(saveGridConfig(viewType, 'bulkDelete', false));
      _clearGridSelection();
    };
    const _onConfirmBulkDelete = () => {
      dispatch(deleteProjects(lsState.selectedItems.map(i => i.id)));
      _onCancelBulkDelete();
    };
    const _onConfirmBulkArchive = async () => {
      const projects = lsState.selectedItems.map(p =>
        defaultArchivedPhase
          ? {
              id: p.id,
              phase: defaultArchivedPhase,
              phase_id: defaultArchivedPhase.id,
            }
          : { id: p.id, phaseTitle: 'Archived' },
      );

      await dispatch(bulkUpdateProjects(projects));
      _onCancelBulkDelete();
      _clearGridSelection();
    };
    const _onBulkUpdate = async projects => {
      await dispatch(bulkUpdateProjects(projects));
      _onCancelBulkDelete();
      _clearGridSelection();
    };
    const _onMerge = async (id, idsToMerge) => {
      await dispatch(mergeProjects(id, idsToMerge));
      _onCancelBulkDelete();
      _clearGridSelection();

      return Promise.resolve({});
    };

    useEffect(() => {
      if (viewWasApplied) {
        const { gridApi, columnApi } = getGridApi();

        // will avoid call api methods when it was destroyed
        if (gridApi?.destroyCalled) {
          return;
        }

        if (gridApi && columnApi) {
          const { gridState } = lsState;

          if (gridState.filterState) {
            gridApi.setFilterModel(gridState.filterState);
          }

          if (gridState.sortState && gridApi.setSortModel) {
            gridApi.setSortModel(gridState.sortState);
          }
        }

        setViewWasApplied(false);
      }
    }, [lsState.gridState, viewWasApplied]);

    useEffect(() => {
      const gridApi = getGridApi();

      if (viewWasApplied || !gridApi || !lsState.gridState) {
        return;
      }

      if (activeViewChanged && !switchedToDefaultView) {
        setViewWasApplied(true);
      }
    }, [viewWasApplied, switchedToDefaultView, activeViewChanged, getGridApi, lsState?.gridState]);

    const groupingOptions = useMemo(() => ({ withNullOption: not(isForecastPage(viewType)) }), []);
    const groupByOptionsFromStore = useSelector(state => getGroupOptionsSelector(state, groupingOptions));
    const groupingOptionsWithNullOption = useMemo(() => ({ withNullOption: true }), []);
    const groupByOptionsWithNullOptionFromStore = useSelector(state =>
      getGroupOptionsSelector(state, groupingOptionsWithNullOption),
    );

    const { groupByOptions } = useMetadataGroupByOptions(groupByOptionsFromStore);
    const { groupByOptions: groupByOptionsWithNullOption } = useMetadataGroupByOptions(groupByOptionsWithNullOptionFromStore);

    const groupByGroup2Options = useMemo(
      () =>
        (customGroupBy2Options || groupByOptionsWithNullOption).filter(o => {
          return lsState.selectedGroup1 && lsState.selectedGroup1.key === 'initiative' ? o.key !== 'bet' : true;
        }),
      [customGroupBy2Options, groupByOptionsWithNullOption, lsState],
    );

    const groupByGroup3Options = useMemo(
      () =>
        (customGroupBy3Options || groupByOptionsWithNullOption).filter(o =>
          (lsState.selectedGroup1 && lsState.selectedGroup1.key === 'initiative') ||
          (lsState.selectedGroup2 && lsState.selectedGroup2.key) === 'initiative'
            ? o.key !== 'bet'
            : true,
        ),
      [customGroupBy3Options, groupByOptionsWithNullOption, lsState],
    );

    const onGroupLevelChange = level => option => {
      const nullOption = groupByOptionsWithNullOption.find(o => o.key === null);
      let selectedGroup1 = level === 'selectedGroup1' ? option : lsState.selectedGroup1;
      let selectedGroup2 = level === 'selectedGroup2' ? option : lsState.selectedGroup2;
      let selectedGroup3 = level === 'selectedGroup3' ? option : lsState.selectedGroup3;

      if (!selectedGroup1 || selectedGroup1?.key === null) {
        selectedGroup1 = nullOption;
        selectedGroup2 = nullOption;
        selectedGroup3 = nullOption;
      }
      if (!selectedGroup2 || selectedGroup2?.key === null || selectedGroup1?.key === selectedGroup2?.key) {
        selectedGroup2 = nullOption;
        selectedGroup3 = nullOption;
      }
      if (
        !selectedGroup3 ||
        selectedGroup3?.key === null ||
        selectedGroup1.key === selectedGroup3.key ||
        selectedGroup2.key === selectedGroup3.key
      ) {
        selectedGroup3 = nullOption;
      }

      if (selectedGroup1 !== lsState.selectedGroup1) _onUpdateGridConfig('selectedGroup1', selectedGroup1);
      if (selectedGroup2 !== lsState.selectedGroup2) _onUpdateGridConfig('selectedGroup2', selectedGroup2);
      if (selectedGroup3 !== lsState.selectedGroup3) _onUpdateGridConfig('selectedGroup3', selectedGroup3);
    };

    const _onWarningClick = () => {
      dispatch(setAppGlobalConfig({ showUpdateJiraKeyDialog: true }));
    };

    useEffect(() => {
      if (lsState.selectedGroup1?.key && !groupByOptions.some(option => option.key === lsState.selectedGroup1.key)) {
        dispatch(saveGridConfig(viewType, 'selectedGroup1', DEFAULT_GROUP_OPTION, false));
        dispatch(saveGridConfig(viewType, 'selectedGroup2', DEFAULT_GROUP_OPTION, false));
        dispatch(saveGridConfig(viewType, 'selectedGroup3', DEFAULT_GROUP_OPTION, false));
      }

      if (lsState.selectedGroup2?.key && !groupByOptions.some(option => option.key === lsState.selectedGroup2.key)) {
        dispatch(saveGridConfig(viewType, 'selectedGroup2', DEFAULT_GROUP_OPTION, false));
        dispatch(saveGridConfig(viewType, 'selectedGroup3', DEFAULT_GROUP_OPTION, false));
      }

      if (lsState.selectedGroup3?.key && !groupByOptions.some(option => option.key === lsState.selectedGroup3.key)) {
        dispatch(saveGridConfig(viewType, 'selectedGroup3', DEFAULT_GROUP_OPTION, false));
      }
    }, [groupByOptions, lsState, saveGridConfig, viewType]);

    const _onCloseShowColumnsDialog = () => setShowColumnsDialogVisible(false);

    return (
      <Component
        {...props}
        currentUser={currentUser}
        globalSearch={globalSearch}
        lsState={lsState}
        onMoreDropdownClick={_onMoreDropdownClick}
        onUpdateView={_onUpdateView}
        onUpdateGridConfig={_onUpdateGridConfig}
        displayLayer={displayLayer}
        onConfirmBulkDelete={_onConfirmBulkDelete}
        onConfirmBulkArchive={_onConfirmBulkArchive}
        onBulkUpdate={_onBulkUpdate}
        onCancelBulkDelete={_onCancelBulkDelete}
        getSystemFieldName={getSystemFieldName}
        hasBet={hasBet}
        groupByOptions={customGroupByOptions || groupByOptions}
        groupByGroup2Options={groupByGroup2Options}
        groupByGroup3Options={groupByGroup3Options}
        onGroupLevelChange={onGroupLevelChange}
        portfolioMode={portfolioMode}
        onMerge={_onMerge}
        warning={warning}
        onWarningClick={_onWarningClick}
        showColumnsDialogVisible={showColumnsDialogVisible}
        onCloseShowColumnsDialog={_onCloseShowColumnsDialog}
        toggleLocalMode={toggleLocalMode}
        isCreatingOrViewingScenario={isCreatingOrViewingScenario}
        openScenariosDialog={openScenariosDialog}
        handleToggleUncommittedProjects={handleToggleUncommittedProjects}
      />
    );
  };
};

export default componentHOC;
