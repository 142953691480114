import React, { memo } from 'react';
import styled from 'styled-components';

import { PROGRESS_PERCENTAGE_DECIMAL_PLACES } from 'constants/common';
import formatPercent from 'design-system/utils/formatPercent';

const TITLE_LABEL = 'Title';
const OWNER_LABEL = 'Owner Name';
const START_DATE_LABEL = 'StartDate';
const END_DATE_LABEL = 'EndDate';
const PREDICTED_START_DATE_LABEL = 'PredictedEndDate';
const PROGRESS_LABEL = 'Progress';

const TooltipContent = memo(
  ({
    name,
    title,
    startDate,
    endDate,
    predictedEndDate,
    progress,
    tooltipLabels: {
      titleLabel = TITLE_LABEL,
      ownerLabel = OWNER_LABEL,
      startDateLabel = START_DATE_LABEL,
      endDateLabel = END_DATE_LABEL,
      predictedEndDateLabel = PREDICTED_START_DATE_LABEL,
      progressLabel = PROGRESS_LABEL,
    } = {},
  }) => {
    return (
      <>
        <TooltipLabel>
          <TooltipStrongLabel>{titleLabel}:</TooltipStrongLabel> {title}
        </TooltipLabel>
        {name && (
          <TooltipLabel>
            <TooltipStrongLabel>{ownerLabel}:</TooltipStrongLabel> {name}
          </TooltipLabel>
        )}
        <TooltipLabel>
          <TooltipStrongLabel>{startDateLabel}:</TooltipStrongLabel> {startDate}
        </TooltipLabel>
        <TooltipLabel>
          <TooltipStrongLabel>{endDateLabel}:</TooltipStrongLabel> {endDate}
        </TooltipLabel>
        {predictedEndDate && (
          <TooltipLabel>
            <TooltipStrongLabel>{predictedEndDateLabel}:</TooltipStrongLabel> {predictedEndDate}
          </TooltipLabel>
        )}
        {progress && (
          <TooltipLabel>
            <TooltipStrongLabel>{progressLabel}:</TooltipStrongLabel>{' '}
            {formatPercent(progress, PROGRESS_PERCENTAGE_DECIMAL_PLACES)}
          </TooltipLabel>
        )}
      </>
    );
  },
);

export default TooltipContent;

const TooltipLabel = styled.p`
  &&&& {
    padding: 0;
    margin-bottom: 0.5rem;

    font-size: ${({ theme }) => `${theme.typography.fontSize}px`};
    color: ${({ theme }) => theme.palette.text.primary};

    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const TooltipStrongLabel = styled.span`
  &&&& {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }
`;
