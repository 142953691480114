import { useMemo } from 'react';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import { filterChildrenObjectives } from 'utils/okrs';

import useGoalModeOKRs from './useGoalModeOKRs';

/**
 * @function usePortfolioOKRs
 * @param  {Boolean} isGoalMode If it is being rendering in goal mode context/pages
 * @param  {Array} okrs       List of OKRs to be processed
 * @return {Array}          List of preocessed OKRs
 */
const usePortfolioOKRs = (isGoalMode = false, okrs = [], considerChildrenOnFilter = true) => {
  const { isParentDragon, childrenDragonsCorpObjectives } = useOrganizationsAccessControl();

  const processedOkrs = useMemo(() => {
    let filteredOkrs = okrs;

    if (isParentDragon) {
      filteredOkrs = filterChildrenObjectives(filteredOkrs, childrenDragonsCorpObjectives);
    }

    return filteredOkrs;
  }, [okrs, childrenDragonsCorpObjectives, isParentDragon]);

  const processedOkrsWithGoalModeFilters = useGoalModeOKRs(isGoalMode, processedOkrs, considerChildrenOnFilter);

  return processedOkrsWithGoalModeFilters;
};

export default usePortfolioOKRs;
