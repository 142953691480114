import { anyPass, defaultTo, isNil, pipe, prop, propEq } from 'ramda';

import { NEW_ROW_ID } from 'design-system/molecules/AgGridReact-New/helpers';
import { OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2, OBJECT_OBJECTIVE } from 'store/objectives/types';
import { METADATA_LEVELS } from 'constants/common';
import { getPermissionResourceByObjectiveType } from 'utils/okrs';

const defaultToEmptyObject = defaultTo({});
const isNumberObjective = propEq('type', 0);
const isStringObjective = propEq('type', OBJECT_OBJECTIVE);
const isTypeObjective = anyPass([isNumberObjective, isStringObjective]);
const isLevelCorp = pipe(defaultToEmptyObject, propEq('level', METADATA_LEVELS.LEVEL_CORP));
const isLevelNil = pipe(prop('level'), isNil);

const getRowId = ({ data }) => data.uniqueId || NEW_ROW_ID;

const getDataPath = data => data.path || ['undefined'];

const OBJECTIVE_CHILDREN = {
  [OBJECT_OBJECTIVE]: OBJECT_KEY_RESULT,
  [OBJECT_KEY_RESULT]: OBJECT_KEY_RESULT_2,
};

const makeCheckAddVisibility = ({ allowedLevel, isTreeView, hasKeyResults, isParentDragon, canCreate }) => ({ data }) => {
  // TODO: move this condition to permissions module
  if (isParentDragon) {
    // Don't allow parent organizations to add inner levels
    return false;
  }

  if (!isTreeView) {
    return false;
  }

  const childrenObjectiveType = OBJECTIVE_CHILDREN[data.type];
  const permissionResource = getPermissionResourceByObjectiveType(childrenObjectiveType);
  const allowToCreate = canCreate(permissionResource);

  if (!allowToCreate) {
    return false;
  }

  if (isTypeObjective(data)) {
    return isLevelCorp(data) || hasKeyResults;
  }

  return isLevelNil(data) || +data.level < allowedLevel;
};

const checkRowDrag = ({ data }) => {
  return !!data?.id;
};

export { getRowId, getDataPath, makeCheckAddVisibility, checkRowDrag };
