import { useMemo, useCallback } from 'react';
import { keys } from 'ramda';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import { KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL } from 'constants/objectives';
import { PRODUCT_1_LEVEL, PRODUCT_2_LEVEL } from 'constants/roadmaps';
import { TIMEFRAME_2_LEVEL } from 'constants/timeframes';
import { ALL_CUSTOM_USER_FIELDS_AVAILABLE } from 'constants/projects';

import AgGridAutocomplete from 'components/AgGridAutocomplete/new';

import LoadableUsersAutocomplete from 'containers/LoadableUsersAutocomplete';

import LoadableAutocomplete from 'design-system/molecules/LoadableAutocomplete';
import AgGridSearchableDndListField from 'design-system/molecules/AgGridSearchableDndListField';
import { TextLinkCellRenderer, TextWithOpenLinkCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { applyCheckIsEditable, returnsTrueIfKeyIsNotEscOrTab } from 'design-system/molecules/AgGridReact-New/helpers';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

import { getUserName } from 'utils';

import { checkDataIsProject, checkDataIsLayer, checkDataIsOkr, mapRoadmapsForCellEditor } from '../../helpers';

import makeMetadataRoadmapsOptionsFormatter from 'utils/makeMetadataRoadmapsOptionsFormatter';
import { getProjectKey } from 'utils/projects/getProjectKey';
import { getProductColumnDef } from 'utils/grids/columns';
import {
  getMetadataTreeEditorColumnDef,
  numericColumnDef,
  textCenterAlignedColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';
import { getKeyResultColumnDef } from './columnsDefinitionsGenerators';
import {
  checkIsEditable,
  makeGetMetadataChipCellRendererParams,
  getMultiChipCellRendererParams,
  parentProjectOptionIconRenderer,
  getAdditionalMetadataColumnsCommonProps,
} from './helpers';
import {
  ChipCellRenderer,
  EmptyValueIfNotProjectRenderer,
  ParentCellRenderer,
  RoadmapCellRenderer,
  TextCellRenderer,
} from './renderers';
// eslint-disable-next-line max-len
import getMetadataAutocompleteEditorColumnDef from 'shared/components/inputs/Autocomplete/helpers/getMetadataAutocompleteEditorColumnDef';
import getFormattedMetadataFieldOptions from 'shared/components/inputs/Autocomplete/helpers/getFormattedMetadataFieldOptions';
import GroupAutocompleteCellEditor from 'shared/components/inputs/Autocomplete/gridColumns/GroupAutocompleteCellEditor';

import {
  PRIORITY,
  PRIORITIES,
  THEME,
  THEMES,
  CATEGORY,
  CATEGORIES,
  TIMEFRAME_CORP,
  TIMEFRAME,
  TIMEFRAMES,
  TIMEFRAME_2,
  TIMEFRAMES_2,
  OBJECTIVE,
  OBJECTIVES,
  KEY_RESULT,
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_PRODUCTS_2,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
  TEAMS,
} from 'constants/common';
import { useSelector } from 'react-redux';
import { getOrganization, getOrgHasMetadataRoadmaps } from 'store/organization';
import { getCrossAccountRoadmaps } from 'store/roadmaps/selectors';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { TAB_DEPENDENCIES } from 'constants/projectLightbox';
import { cleanTimeframesL1ParentIdHierarchy } from 'utils/timeframes';

const filterOutOptionById = (options = [], idToRemove) => options.filter(opt => opt?.id !== idToRemove);

const getAdditionalMetadataOptionsFromContext = ({ context: { additionalMetadataOptions = {} } = {} } = {}, field) =>
  additionalMetadataOptions[field];

const useCommonFieldsColumnsDefinitions = ({
  hasHierarchy,
  hasKeyResults,
  hasKeyResults2,
  hasProducts,
  hasProducts2,
  hasMultiLevelMetadata,
  hasTeams2,
  topLayer,
  customUserFields,
  hasMetadataMultiSelect = true,

  canView,
  canUpdate,
  getSystemFieldName,
  openProjectInfo,
  loadParentProjectsOptions,
  checkUserCanCreateNewMetadata,
  updateProjectById,
  handleMetadataCellDoubleClick,
  getNumberOfTextRowsToDisplayOnGridCell,
}) => {
  const organization = useSelector(state => getOrganization(state));
  const crossAccountRoadmaps = useSelector(state => getCrossAccountRoadmaps(state));
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);

  const organizationsAccessControl = useOrganizationsAccessControl();
  const { isDodActive, isChildDragon, getCurrentOrgCorpRoadmap } = organizationsAccessControl;

  const hasNewMetadataAutocompleteOnGrids = canView(PERMISSION_FEATURES.newRoadmapMetadataAutocompleteOnGrids);
  const hasExternalKey = canView(PERMISSION_FEATURES.importProjectsWithExternalKey);

  const includeCorpRoadmapsForObjectivesAndTimeframes = isChildDragon && hasNewMetadataAutocompleteOnGrids;

  const additionalMetadataColumnsCommonProps = useMemo(
    () =>
      getAdditionalMetadataColumnsCommonProps({
        getNumberOfTextRowsToDisplayOnGridCell,
        handleMetadataCellDoubleClick,
      }),
    [getNumberOfTextRowsToDisplayOnGridCell, handleMetadataCellDoubleClick],
  );

  /**
   * Id column is using valueGetter to return the Project Key in order
   * to avoid backwards compatibility issues in the existing views
   * displaying the Id column.
   */
  const idColumnDef = useMemo(() => {
    return {
      field: 'id',
      headerName: 'ID',
      cellClassRules: {
        'cell-link': ({ data }) => checkDataIsProject(data),
      },
      editable: false,
      suppressMovable: true,
      cellRenderer: EmptyValueIfNotProjectRenderer(TextLinkCellRenderer),
      cellRendererParams: {
        handleClick: openProjectInfo,
      },
      width: 60,
      valueGetter: ({ data }) => getProjectKey(data),
    };
  }, [openProjectInfo]);

  const parentColumnDef = useMemo(() => {
    return {
      field: 'parent',
      headerName: 'Parent',
      editable: params => {
        if (!canUpdate(PERMISSION_RESOURCES.project, { project: params.data })) {
          return false;
        }

        return !checkDataIsLayer(params.data, topLayer) && applyCheckIsEditable(checkIsEditable)(params);
      },
      cellRenderer: EmptyValueIfNotProjectRenderer(ParentCellRenderer),
      cellRendererParams: {
        getNumberOfTextRowsToDisplayOnGridCell,
      },
      cellEditor: AgGridAutocomplete,
      cellEditorParams: params => ({
        autoFocus: true,
        size: '2x',
        loadData: valueToSearch => loadParentProjectsOptions(valueToSearch, params),
        autocompleteComponent: LoadableAutocomplete,
        prependProjectIcon: parentProjectOptionIconRenderer,
      }),
      cellEditorPopup: true,
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      width: 240,
    };
  }, [loadParentProjectsOptions, topLayer, getNumberOfTextRowsToDisplayOnGridCell]);

  const ownerColumnDef = useMemo(() => {
    return {
      field: 'ownerName',
      headerName: 'Owner',
      cellRenderer: TextCellRenderer,
      cellRendererParams: {
        getNumberOfTextRowsToDisplayOnGridCell,
      },
      ...(hasNewMetadataAutocompleteOnGrids
        ? {
            cellEditor: GroupAutocompleteCellEditor,
            cellEditorParams: params => ({
              focusOnInit: true,
              withNullOption: true,
              getSystemFieldName,
              options: params.context.metadata.usersGroupedByTeam,
            }),
          }
        : {
            cellEditor: AgGridAutocomplete,
            cellEditorParams: {
              autoFocus: true,
              autocompleteComponent: LoadableUsersAutocomplete,
              dataMapper: u => ({
                value: getUserName(u),
                label: getUserName(u),
              }),
            },
          }),

      cellEditorPopup: true,
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      width: 120,
    };
  }, [getNumberOfTextRowsToDisplayOnGridCell, hasNewMetadataAutocompleteOnGrids]);

  const hasCustomUserField = useCallback(customUser => customUserFields[customUser], [customUserFields]);

  const customUserFieldsColumnDefs = useMemo(() => {
    return keys(ALL_CUSTOM_USER_FIELDS_AVAILABLE)
      .filter(hasCustomUserField)
      .map(fieldKey => {
        const fieldObj = ALL_CUSTOM_USER_FIELDS_AVAILABLE[fieldKey];

        return {
          field: fieldObj.field,
          headerName: getSystemFieldName(fieldObj.systemFieldKey),
          cellRenderer: TextCellRenderer,
          cellRendererParams: {
            getNumberOfTextRowsToDisplayOnGridCell,
          },
          cellEditor: AgGridAutocomplete,
          cellEditorParams: {
            autoFocus: true,
            autocompleteComponent: LoadableUsersAutocomplete,
            dataMapper: u => ({
              value: getUserName(u),
              label: getUserName(u),
            }),
          },
          cellEditorPopup: true,
          comparator: stringComparator,
          suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
          width: 120,
        };
      });
  }, [hasCustomUserField, getSystemFieldName]);

  const categoryColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params =>
        getFormattedMetadataFieldOptions(
          params,
          CATEGORIES,
          CATEGORY,
          organization,
          organizationsAccessControl,
          canView(PERMISSION_RESOURCES.roadmapCorp),
          hasMetadataRoadmaps,
        ),
      handleUpdate: updateProjectById,
      autocompleteParams: {
        name: CATEGORIES,
        roadmapsKey: CATEGORY,
        levelOneFieldName: CATEGORY,
        withNullOption: true,
        getSystemFieldName,
      },
    });

    return {
      field: 'categoryTitle',
      headerName: getSystemFieldName('category'),
      cellRenderer: EmptyValueIfNotProjectRenderer(ChipCellRenderer),
      cellRendererParams: params => makeGetMetadataChipCellRendererParams(params.context.metadata.categories)(params),
      ...(hasNewMetadataAutocompleteOnGrids
        ? metadataAutocompleteEditorColumnDef
        : {
            cellEditor: AgGridAutocomplete,
            cellEditorParams: params => ({
              autoFocus: true,
              size: '2x',
              addNew: checkUserCanCreateNewMetadata('categories'),
              options: params.context.filteredMetadata?.categories.map(c => c.title) || [],
            }),
          }),
      cellEditorPopup: true,
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      width: 120,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    };
  }, [getSystemFieldName, checkUserCanCreateNewMetadata, handleMetadataCellDoubleClick]);

  const additionalCategoriesColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params => {
        const roadmapId = params?.data?.roadmap_id;
        const { roadmaps = [], roadmapsCorp = [] } = params?.context?.filteredMetadata;

        const filteredOptions = filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalCategoriesOptions'),
          params?.data?.category_id,
        );

        if (!hasMetadataRoadmaps) return filteredOptions;

        const formatMetadataOptions = makeMetadataRoadmapsOptionsFormatter(
          organization,
          roadmapId,
          organizationsAccessControl,
          roadmaps,
          roadmapsCorp,
        );

        return formatMetadataOptions(`category_roadmaps`, filteredOptions, {
          needsObject: true,
          includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp),
        });
      },
      autocompleteParams: {
        name: ADDITIONAL_CATEGORIES,
        roadmapsKey: ADDITIONAL_CATEGORIES,
        getSystemFieldName,
      },
      isMultiSelect: true,
    });

    return {
      field: ADDITIONAL_CATEGORIES,
      headerName: `Additional ${getSystemFieldName('category', true)}`,
      cellEditorParams: params => ({
        options: filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalCategoriesOptions'),
          params?.data?.category_id,
        ),
        optionsMapper: { label: 'title', value: 'id' },
        hideCreateOption: true,
      }),
      ...additionalMetadataColumnsCommonProps,
      ...(hasNewMetadataAutocompleteOnGrids ? metadataAutocompleteEditorColumnDef : {}),
    };
  }, [getSystemFieldName]);

  const themeColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params =>
        getFormattedMetadataFieldOptions(
          params,
          THEMES,
          THEME,
          organization,
          organizationsAccessControl,
          canView(PERMISSION_RESOURCES.roadmapCorp),
          hasMetadataRoadmaps,
        ),
      handleUpdate: updateProjectById,
      autocompleteParams: {
        name: THEMES,
        roadmapsKey: THEME,
        levelOneFieldName: THEME,
        withNullOption: true,
        getSystemFieldName,
      },
    });

    return {
      field: 'themeTitle',
      headerName: getSystemFieldName('theme'),
      cellRenderer: EmptyValueIfNotProjectRenderer(ChipCellRenderer),
      cellRendererParams: params => makeGetMetadataChipCellRendererParams(params.context.metadata.themes)(params),
      ...(hasNewMetadataAutocompleteOnGrids
        ? metadataAutocompleteEditorColumnDef
        : {
            cellEditor: AgGridAutocomplete,
            cellEditorParams: params => ({
              autoFocus: true,
              size: '2x',
              addNew: checkUserCanCreateNewMetadata('themes'),
              options: params.context.filteredMetadata.themes.map(t => t.title),
            }),
          }),
      cellEditorPopup: true,
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      width: 120,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    };
  }, [getSystemFieldName, checkUserCanCreateNewMetadata, handleMetadataCellDoubleClick]);

  const additionalThemesColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params => {
        const roadmapId = params?.data?.roadmap_id;
        const { roadmaps = [], roadmapsCorp = [] } = params?.context?.filteredMetadata;

        const filteredOptions = filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalThemesOptions'),
          params?.data?.theme_id,
        );

        if (!hasMetadataRoadmaps) return filteredOptions;

        const formatMetadataOptions = makeMetadataRoadmapsOptionsFormatter(
          organization,
          roadmapId,
          organizationsAccessControl,
          roadmaps,
          roadmapsCorp,
        );

        return formatMetadataOptions(`theme_roadmaps`, filteredOptions, {
          needsObject: true,
          includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp),
        });
      },
      autocompleteParams: {
        name: ADDITIONAL_THEMES,
        roadmapsKey: ADDITIONAL_THEMES,
        getSystemFieldName,
      },
      isMultiSelect: true,
    });

    return {
      field: ADDITIONAL_THEMES,
      headerName: `Additional ${getSystemFieldName('theme', true)}`,
      cellEditorParams: params => ({
        options: filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalThemesOptions'),
          params?.data?.theme_id,
        ),
        optionsMapper: { label: 'title', value: 'id' },
        hideCreateOption: true,
      }),
      ...additionalMetadataColumnsCommonProps,
      ...(hasNewMetadataAutocompleteOnGrids ? metadataAutocompleteEditorColumnDef : {}),
    };
  }, [getSystemFieldName]);

  const priorityColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params =>
        getFormattedMetadataFieldOptions(
          params,
          PRIORITIES,
          PRIORITY,
          organization,
          organizationsAccessControl,
          canView(PERMISSION_RESOURCES.roadmapCorp),
          hasMetadataRoadmaps,
        ),
      handleUpdate: updateProjectById,
      autocompleteParams: {
        name: PRIORITIES,
        roadmapsKey: PRIORITY,
        levelOneFieldName: PRIORITY,
        withNullOption: true,
        getSystemFieldName,
      },
    });

    return {
      field: 'priorityTitle',
      headerName: getSystemFieldName('priority'),
      cellRenderer: EmptyValueIfNotProjectRenderer(ChipCellRenderer),
      cellRendererParams: params => makeGetMetadataChipCellRendererParams(params.context.metadata.priorities)(params),
      ...(hasNewMetadataAutocompleteOnGrids
        ? metadataAutocompleteEditorColumnDef
        : {
            cellEditor: AgGridAutocomplete,
            cellEditorParams: params => ({
              autoFocus: true,
              addNew: checkUserCanCreateNewMetadata('priorities'),
              options: params.context.filteredMetadata.priorities.map(p => p.title),
            }),
          }),
      cellEditorPopup: true,
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      width: 120,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    };
  }, [getSystemFieldName, checkUserCanCreateNewMetadata, handleMetadataCellDoubleClick]);

  const timeframeColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params => {
        const options = getFormattedMetadataFieldOptions(
          params,
          TIMEFRAMES,
          TIMEFRAME,
          organization,
          organizationsAccessControl,
          canView(PERMISSION_RESOURCES.roadmapCorp) || includeCorpRoadmapsForObjectivesAndTimeframes,
          hasMetadataRoadmaps,
        );

        return cleanTimeframesL1ParentIdHierarchy(options);
      },
      handleUpdate: updateProjectById,
      autocompleteParams: {
        name: TIMEFRAMES,
        roadmapsKey: TIMEFRAME,
        levelZeroFieldName: canView(PERMISSION_RESOURCES.timeframeCorp) ? TIMEFRAME_CORP : null,
        levelOneFieldName: TIMEFRAME,
        levelTwoFieldName: TIMEFRAME_2,
        withNullOption: true,
        getSystemFieldName,
      },
    });

    return {
      field: 'timeframeTitle',
      headerName: getSystemFieldName('timeframe'),
      cellRenderer: EmptyValueIfNotProjectRenderer(TextCellRenderer),
      cellRendererParams: {
        getNumberOfTextRowsToDisplayOnGridCell,
      },
      ...(hasNewMetadataAutocompleteOnGrids
        ? metadataAutocompleteEditorColumnDef
        : {
            cellEditor: AgGridAutocomplete,
            cellEditorParams: params => ({
              autoFocus: true,
              addNew: checkUserCanCreateNewMetadata('timeframes'),
              options: params.context.filteredMetadata.timeframes.map(t => t.title),
            }),
          }),
      cellEditorPopup: true,
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      width: 120,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    };
  }, [getSystemFieldName, checkUserCanCreateNewMetadata, getNumberOfTextRowsToDisplayOnGridCell, handleMetadataCellDoubleClick]);

  const additionalTimeframesColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params => {
        const roadmapId = params?.data?.roadmap_id;
        const { roadmaps = [], roadmapsCorp = [] } = params?.context?.filteredMetadata;

        const filteredOptions = filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalTimeframesOptions'),
          params?.data?.timeframe_id,
        );

        if (!hasMetadataRoadmaps) return filteredOptions;

        const formatMetadataOptions = makeMetadataRoadmapsOptionsFormatter(
          organization,
          roadmapId,
          organizationsAccessControl,
          roadmaps,
          roadmapsCorp,
        );

        return formatMetadataOptions(`timeframe_roadmaps`, filteredOptions, {
          needsObject: true,
          includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp) || includeCorpRoadmapsForObjectivesAndTimeframes,
        });
      },
      autocompleteParams: {
        name: ADDITIONAL_TIMEFRAMES,
        roadmapsKey: ADDITIONAL_TIMEFRAMES,
        getSystemFieldName,
      },
      isMultiSelect: true,
    });

    return {
      field: ADDITIONAL_TIMEFRAMES,
      headerName: `Additional ${getSystemFieldName('timeframe', true)}`,
      cellEditorParams: params => ({
        options: filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalTimeframesOptions'),
          params?.data?.timeframe_id,
        ),
        optionsMapper: { label: 'title', value: 'id' },
        hideCreateOption: true,
      }),
      ...additionalMetadataColumnsCommonProps,
      ...(hasNewMetadataAutocompleteOnGrids ? metadataAutocompleteEditorColumnDef : {}),
    };
  }, [getSystemFieldName]);

  const additionalTimeframes2ColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params => {
        const roadmapId = params?.data?.roadmap_id;
        const { roadmaps = [], roadmapsCorp = [] } = params?.context?.filteredMetadata;

        const filteredOptions = filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalTimeframes2Options'),
          params?.data?.timeframe_2_id,
        );

        if (!hasMetadataRoadmaps) return filteredOptions;

        const formatMetadataOptions = makeMetadataRoadmapsOptionsFormatter(
          organization,
          roadmapId,
          organizationsAccessControl,
          roadmaps,
          roadmapsCorp,
        );

        return formatMetadataOptions(`timeframe_roadmaps`, filteredOptions, {
          needsObject: true,
          includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp) || includeCorpRoadmapsForObjectivesAndTimeframes,
        });
      },
      autocompleteParams: {
        name: ADDITIONAL_TIMEFRAMES_2,
        roadmapsKey: ADDITIONAL_TIMEFRAMES_2,
        getSystemFieldName,
      },
      isMultiSelect: true,
    });

    return {
      field: ADDITIONAL_TIMEFRAMES_2,
      headerName: `Additional ${getSystemFieldName('timeframe2', true)}`,
      cellEditorParams: params => ({
        options: filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalTimeframes2Options'),
          params?.data?.timeframe_2_id,
        ),
        optionsMapper: { label: 'title', value: 'id' },
        hideCreateOption: true,
      }),
      ...additionalMetadataColumnsCommonProps,
      ...(hasNewMetadataAutocompleteOnGrids ? metadataAutocompleteEditorColumnDef : {}),
    };
  }, [getSystemFieldName]);

  const timeframe2ColumnDef = useMemo(() => {
    const field = 'timeframe2Title';

    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params => {
        const roadmapId = params?.data?.roadmap_id;
        const { roadmaps = [], roadmapsCorp = [] } = params?.context?.filteredMetadata;

        const timesframes = params?.context?.filteredMetadata?.timeframes;

        const dataTimeframeId = params.data.timeframe_id;

        const dataTimeframe = dataTimeframeId ? timesframes.find(timeframe => timeframe.id === dataTimeframeId) : null;

        const filteredOptions = timesframes.filter(timeframe => {
          const hasLowerOrEqualLevel = timeframe.level <= TIMEFRAME_2_LEVEL;

          if (!hasLowerOrEqualLevel || !timeframe?.children?.length > 0) {
            return false;
          }

          // Works for 2 levels only.
          return !dataTimeframe || [timeframe.id, timeframe.parent_id].includes(dataTimeframe.id);
        });

        if (!hasMetadataRoadmaps) return filteredOptions;

        const formatMetadataOptions = makeMetadataRoadmapsOptionsFormatter(
          organization,
          roadmapId,
          organizationsAccessControl,
          roadmaps,
          roadmapsCorp,
        );

        return formatMetadataOptions(`timeframe_roadmaps`, filteredOptions, {
          needsObject: true,
          includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp) || includeCorpRoadmapsForObjectivesAndTimeframes,
        });
      },
      handleUpdate: updateProjectById,
      autocompleteParams: {
        name: TIMEFRAMES_2,
        roadmapsKey: TIMEFRAME_2,
        levelOneFieldName: TIMEFRAME,
        levelTwoFieldName: TIMEFRAME_2,
        withNullOption: true,
        getSystemFieldName,
      },
    });

    const metadataTreeEditorColumnDef = getMetadataTreeEditorColumnDef({
      shouldAcceptSelection: selectedOption => !selectedOption || Number(selectedOption.level) === TIMEFRAME_2_LEVEL,
      getItems: params => {
        const allLevelsActiveTimeframes = [
          ...params.context.filteredMetadata.timeframes,
          ...params.context.filteredMetadata.timeframes2,
        ];

        const dataTimeframeId = params.data.timeframe_id;

        const dataTimeframe = dataTimeframeId
          ? allLevelsActiveTimeframes.find(timeframe => timeframe.id === dataTimeframeId)
          : null;

        return allLevelsActiveTimeframes.filter(timeframe => {
          const hasLowerOrEqualLevel = timeframe.level <= TIMEFRAME_2_LEVEL;

          if (!hasLowerOrEqualLevel) {
            return false;
          }

          // Works for 2 levels only.
          return !dataTimeframe || [timeframe.id, timeframe.parent_id].includes(dataTimeframe.id);
        });
      },
      handleUpdate: (dataId, selectedOption) => {
        const updatedData = {
          [field]: selectedOption?.title ?? null,
        };

        return updateProjectById(dataId, updatedData);
      },
    });

    return {
      field,
      headerName: getSystemFieldName('timeframe2'),
      cellRenderer: EmptyValueIfNotProjectRenderer(TextCellRenderer),
      cellRendererParams: {
        getNumberOfTextRowsToDisplayOnGridCell,
      },
      ...(hasNewMetadataAutocompleteOnGrids ? metadataAutocompleteEditorColumnDef : metadataTreeEditorColumnDef),
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      width: 120,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    };
  }, [getSystemFieldName, checkUserCanCreateNewMetadata, getNumberOfTextRowsToDisplayOnGridCell, handleMetadataCellDoubleClick]);

  const timeframeCorpColumnDef = useMemo(() => {
    return {
      field: 'timeframeCorpTitle',
      headerName: getSystemFieldName('timeframeCorp'),
      cellRenderer: EmptyValueIfNotProjectRenderer(ChipCellRenderer),
      cellRendererParams: params => makeGetMetadataChipCellRendererParams(params.context.metadata.timeframesCorp)(params),
      cellEditor: AgGridAutocomplete,
      cellEditorParams: params => ({
        autoFocus: true,
        options: params.context.filteredMetadata.timeframesCorp.map(tc => tc.title),
      }),
      cellEditorPopup: true,
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      width: 120,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    };
  }, [getSystemFieldName, handleMetadataCellDoubleClick]);

  const roadmapColumnDef = useMemo(() => {
    return {
      field: 'roadmapTitle',
      headerName: getSystemFieldName('roadmap'),
      cellRenderer: RoadmapCellRenderer,
      cellRendererParams: params => {
        if (checkDataIsOkr(params.data)) {
          return getMultiChipCellRendererParams(params);
        }

        return makeGetMetadataChipCellRendererParams(params.context.metadata.roadmaps)(params);
      },
      cellEditor: AgGridSearchableDndListField,
      cellEditorParams: params => {
        const hasCrossAccountRoadmapFeature = canView(PERMISSION_FEATURES.crossBabyRoadmapSupport);
        const corpRoadmap = hasCrossAccountRoadmapFeature ? getCurrentOrgCorpRoadmap() : {};

        const options = params.context.filteredMetadata.roadmaps;

        const allOptions = hasCrossAccountRoadmapFeature ? crossAccountRoadmaps : null;

        return {
          autoFocus: true,
          addNew: checkUserCanCreateNewMetadata('roadmaps'),
          options: mapRoadmapsForCellEditor(options),
          allOptions: mapRoadmapsForCellEditor(allOptions, true),
          disabledSelections: params.context.metadata.roadmapsCorp.map(roadmap => roadmap.title),
          showOptionsSubsetText: `Show ${corpRoadmap?.title} roadmaps`,
        };
      },
      cellEditorPopup: true,
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      width: 120,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    };
  }, [getSystemFieldName, checkUserCanCreateNewMetadata, isDodActive, getCurrentOrgCorpRoadmap, handleMetadataCellDoubleClick]);
  // NOTE: `filteredMetadata.roadmaps` should belong to the array of dependencies,
  //   but it is not properly memoized. It would cause flickering. Not being on the
  //   dependencies array should not cause major issues. It should be properly memoized.

  const product1ColumnDef = useMemo(() => {
    return getProductColumnDef({
      field: 'product1Title',
      headerName: getSystemFieldName('product1'),
      selectedLevel: PRODUCT_1_LEVEL,

      getFilteredRoadmaps: params => params.context.filteredMetadata.roadmaps,

      update: updateProjectById,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
      cellRenderer: RoadmapCellRenderer,
      cellRendererParams: params => {
        if (checkDataIsOkr(params.data)) {
          return getMultiChipCellRendererParams(params);
        }

        return makeGetMetadataChipCellRendererParams(params.context.metadata.products1)(params);
      },
    });
  }, [getSystemFieldName, updateProjectById, handleMetadataCellDoubleClick]);
  // NOTE: `filteredMetadata.roadmaps` should belong to the array of dependencies,
  //   but it is not properly memoized. It would cause flickering. Not being on the
  //   dependencies array should not cause major issues. It should be properly memoized.

  const product2ColumnDef = useMemo(() => {
    return getProductColumnDef({
      field: 'product2Title',
      headerName: getSystemFieldName('product2'),
      selectedLevel: PRODUCT_2_LEVEL,

      getFilteredRoadmaps: params => params.context.filteredMetadata.roadmaps,

      update: updateProjectById,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
      cellRenderer: RoadmapCellRenderer,
      cellRendererParams: params => {
        if (checkDataIsOkr(params.data)) {
          return getMultiChipCellRendererParams(params);
        }

        return makeGetMetadataChipCellRendererParams(params.context.metadata.products1)(params);
      },
    });
  }, [getSystemFieldName, updateProjectById, handleMetadataCellDoubleClick]);
  // NOTE: `filteredMetadata.roadmaps` should belong to the array of dependencies,
  //   but it is not properly memoized. It would cause flickering. Not being on the
  //   dependencies array should not cause major issues. It should be properly memoized.

  const roadmapCorpColumnDef = useMemo(() => {
    return {
      field: 'roadmapCorpTitle',
      headerName: getSystemFieldName('roadmapCorp'),
      cellRenderer: EmptyValueIfNotProjectRenderer(ChipCellRenderer),
      cellRendererParams: params => makeGetMetadataChipCellRendererParams(params.context.metadata.roadmapsCorp)(params),
      cellEditor: AgGridAutocomplete,
      cellEditorParams: params => ({
        autoFocus: true,
        options: params.context.filteredMetadata.roadmapsCorp.map(rc => rc.title),
      }),
      cellEditorPopup: true,
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      width: 120,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    };
  }, [getSystemFieldName, handleMetadataCellDoubleClick]);

  const additionalRoadmapsColumnDef = useMemo(() => {
    return {
      field: ADDITIONAL_ROADMAPS,
      headerName: `Additional ${getSystemFieldName('roadmap', true)}`,
      cellEditorParams: params => ({
        options: filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalRoadmapOptions'),
          params?.data?.roadmap_id,
        ),
        optionsMapper: { label: 'title', value: 'id' },
        hideCreateOption: true,
      }),
      ...additionalMetadataColumnsCommonProps,
    };
  }, [getSystemFieldName]);

  const additionalProducts1ColumnDef = useMemo(() => {
    return {
      field: ADDITIONAL_PRODUCTS,
      headerName: `Additional ${getSystemFieldName('product1', true)}`,
      cellEditorParams: params => ({
        options: filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalProducts1Options'),
          params?.data?.product_1_id,
        ),
        optionsMapper: { label: 'title', value: 'id' },
        hideCreateOption: true,
      }),
      ...additionalMetadataColumnsCommonProps,
    };
  }, [getSystemFieldName]);

  const additionalProducts2ColumnDef = useMemo(() => {
    return {
      field: ADDITIONAL_PRODUCTS_2,
      headerName: `Additional ${getSystemFieldName('product2', true)}`,
      cellEditorParams: params => ({
        options: filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalProducts2Options'),
          params?.data?.product_2_id,
        ),
        optionsMapper: { label: 'title', value: 'id' },
        hideCreateOption: true,
      }),
      ...additionalMetadataColumnsCommonProps,
    };
  }, [getSystemFieldName, additionalMetadataColumnsCommonProps]);

  const objectiveColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params =>
        getFormattedMetadataFieldOptions(
          params,
          OBJECTIVES,
          OBJECTIVE,
          organization,
          organizationsAccessControl,
          canView(PERMISSION_RESOURCES.roadmapCorp) || includeCorpRoadmapsForObjectivesAndTimeframes,
          hasMetadataRoadmaps,
        ),
      handleUpdate: updateProjectById,
      autocompleteParams: {
        name: OBJECTIVES,
        roadmapsKey: OBJECTIVE,
        levelOneFieldName: OBJECTIVE,
        levelTwoFieldName: KEY_RESULT,
        getSystemFieldName,
        withNullOption: true,
      },
    });

    return {
      field: 'objectiveTitle',
      headerName: getSystemFieldName('objective'),
      cellRenderer: EmptyValueIfNotProjectRenderer(ChipCellRenderer),
      cellRendererParams: params => makeGetMetadataChipCellRendererParams(params.context.metadata.objectives)(params),
      ...(hasNewMetadataAutocompleteOnGrids
        ? metadataAutocompleteEditorColumnDef
        : {
            cellEditor: AgGridAutocomplete,
            cellEditorParams: params => ({
              autoFocus: true,
              size: '2x',
              addNew: checkUserCanCreateNewMetadata('objectives'),
              options: params.context.filteredMetadata.objectives.map(o => o.title),
            }),
          }),
      cellEditorPopup: true,
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      width: 120,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    };
  }, [
    getSystemFieldName,
    checkUserCanCreateNewMetadata,
    // filteredMetadata.objectives,
    handleMetadataCellDoubleClick,
  ]);
  // NOTE: `filteredMetadata.objectives` should belong to the array of dependencies,
  //   but it is not properly memoized. It would cause flickering. Not being on the
  //   dependencies array should not cause major issues. It should be properly memoized.

  const keyResult1ColumnDef = useMemo(() => {
    return getKeyResultColumnDef({
      field: 'keyResult1Title',
      headerName: getSystemFieldName('keyResult1'),
      selectedLevel: KEY_RESULT_1_LEVEL,

      getFilteredObjectives: params => params.context.filteredMetadata.objectives,
      getKeyResults: params => params.context.metadata.keyResults1,

      update: updateProjectById,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    });
  }, [getSystemFieldName, updateProjectById, handleMetadataCellDoubleClick]);
  // NOTE: `filteredMetadata.objectives` should belong to the array of dependencies,
  //   but it is not properly memoized. It would cause flickering. Not being on the
  //   dependencies array should not cause major issues. It should be properly memoized.

  const keyResult2ColumnDef = useMemo(() => {
    return getKeyResultColumnDef({
      field: 'keyResult2Title',
      headerName: getSystemFieldName('keyResult2'),
      selectedLevel: KEY_RESULT_2_LEVEL,

      getFilteredObjectives: params => params.context.filteredMetadata.objectives,
      getKeyResults: params => params.context.metadata.keyResults2,

      update: updateProjectById,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    });
  }, [getSystemFieldName, updateProjectById, handleMetadataCellDoubleClick]);

  const objectiveCorpColumnDef = useMemo(() => {
    return {
      field: 'objectiveCorpTitle',
      headerName: getSystemFieldName('objectiveCorp'),
      cellRenderer: EmptyValueIfNotProjectRenderer(ChipCellRenderer),
      cellRendererParams: params => makeGetMetadataChipCellRendererParams(params.context.metadata.objectivesCorp)(params),
      cellEditor: AgGridAutocomplete,
      cellEditorParams: params => ({
        autoFocus: true,
        options: params.context.filteredMetadata.objectivesCorp.map(tc => tc.title),
      }),
      cellEditorPopup: true,
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      width: 120,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    };
  }, [getSystemFieldName, handleMetadataCellDoubleClick]);

  const additionalObjectivesColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params => {
        const roadmapId = params?.data?.roadmap_id;
        const { roadmaps = [], roadmapsCorp = [] } = params?.context?.filteredMetadata;

        const filteredOptions = filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalObjectivesOptions'),
          params?.data?.objective_id,
        );

        if (!hasMetadataRoadmaps) return filteredOptions;

        const formatMetadataOptions = makeMetadataRoadmapsOptionsFormatter(
          organization,
          roadmapId,
          organizationsAccessControl,
          roadmaps,
          roadmapsCorp,
        );

        return formatMetadataOptions(`objective_roadmaps`, filteredOptions, {
          needsObject: true,
          includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp) || includeCorpRoadmapsForObjectivesAndTimeframes,
          childRoadmapKey: 'key_result_roadmaps',
        });
      },
      autocompleteParams: {
        name: ADDITIONAL_OBJECTIVES,
        roadmapsKey: ADDITIONAL_OBJECTIVES,
        getSystemFieldName,
      },
      isMultiSelect: true,
    });

    return {
      field: ADDITIONAL_OBJECTIVES,
      headerName: `Additional ${getSystemFieldName('objective', true)}`,
      cellEditorParams: params => ({
        options: filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalObjectivesOptions'),
          params?.data?.objective_id,
        ),
        optionsMapper: { label: 'title', value: 'id' },
        hideCreateOption: true,
      }),
      ...additionalMetadataColumnsCommonProps,
      ...(hasNewMetadataAutocompleteOnGrids ? metadataAutocompleteEditorColumnDef : {}),
    };
  }, [getSystemFieldName]);

  const additionalKeyResults1ColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params => {
        const roadmapId = params?.data?.roadmap_id;
        const { roadmaps = [], roadmapsCorp = [] } = params?.context?.filteredMetadata;

        const filteredOptions = filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalKeyResults1Options'),
          params?.data?.key_result_1_id,
        );

        if (!hasMetadataRoadmaps) return filteredOptions;

        const formatMetadataOptions = makeMetadataRoadmapsOptionsFormatter(
          organization,
          roadmapId,
          organizationsAccessControl,
          roadmaps,
          roadmapsCorp,
        );

        return formatMetadataOptions(`key_result_roadmaps`, filteredOptions, {
          needsObject: true,
          includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp) || includeCorpRoadmapsForObjectivesAndTimeframes,
        });
      },
      autocompleteParams: {
        name: ADDITIONAL_KEY_RESULTS,
        roadmapsKey: ADDITIONAL_KEY_RESULTS,
        getSystemFieldName,
      },
      isMultiSelect: true,
    });

    return {
      field: ADDITIONAL_KEY_RESULTS,
      headerName: `Additional ${getSystemFieldName('keyResult1', true)}`,
      cellEditorParams: params => ({
        options: filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalKeyResults1Options'),
          params?.data?.key_result_1_id,
        ),
        optionsMapper: { label: 'title', value: 'id' },
        hideCreateOption: true,
      }),
      ...additionalMetadataColumnsCommonProps,
      ...(hasNewMetadataAutocompleteOnGrids ? metadataAutocompleteEditorColumnDef : {}),
    };
  }, [getSystemFieldName]);

  const additionalKeyResults2ColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params => {
        const roadmapId = params?.data?.roadmap_id;
        const { roadmaps = [], roadmapsCorp = [] } = params?.context?.filteredMetadata;

        const filteredOptions = filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalKeyResults2Options'),
          params?.data?.key_result_2_id,
        );

        if (!hasMetadataRoadmaps) return filteredOptions;

        const formatMetadataOptions = makeMetadataRoadmapsOptionsFormatter(
          organization,
          roadmapId,
          organizationsAccessControl,
          roadmaps,
          roadmapsCorp,
        );

        return formatMetadataOptions(`key_result_roadmaps`, filteredOptions, {
          needsObject: true,
          includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp) || includeCorpRoadmapsForObjectivesAndTimeframes,
        });
      },
      autocompleteParams: {
        name: ADDITIONAL_KEY_RESULTS_2,
        roadmapsKey: ADDITIONAL_KEY_RESULTS_2,
        getSystemFieldName,
      },
      isMultiSelect: true,
    });

    return {
      field: ADDITIONAL_KEY_RESULTS_2,
      headerName: `Additional ${getSystemFieldName('keyResult2', true)}`,
      cellEditorParams: params => ({
        options: filterOutOptionById(
          getAdditionalMetadataOptionsFromContext(params, 'additionalKeyResults2Options'),
          params?.data?.key_result_2_id,
        ),
        optionsMapper: { label: 'title', value: 'id' },
        hideCreateOption: true,
      }),
      ...additionalMetadataColumnsCommonProps,
      ...(hasNewMetadataAutocompleteOnGrids ? metadataAutocompleteEditorColumnDef : {}),
    };
  }, [getSystemFieldName, additionalMetadataColumnsCommonProps]);

  const teamsColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params => {
        const filteredOptions = getAdditionalMetadataOptionsFromContext(params, 'additionalTeamsOptions');

        return filteredOptions;
      },
      autocompleteParams: {
        name: ADDITIONAL_TEAMS,
        roadmapsKey: ADDITIONAL_TEAMS,
        getSystemFieldName,
        formatByRoadmap: false,
      },
      isMultiSelect: true,
    });

    return {
      field: ADDITIONAL_TEAMS,
      headerName: `All ${getSystemFieldName('team', true)}`,
      cellEditorParams: params => ({
        options: getAdditionalMetadataOptionsFromContext(params, 'additionalTeamsOptions'),

        optionsMapper: { label: 'title', value: 'id' },
        hideCreateOption: true,
      }),
      ...additionalMetadataColumnsCommonProps,
      ...(hasNewMetadataAutocompleteOnGrids ? metadataAutocompleteEditorColumnDef : {}),
    };
  }, [getSystemFieldName]);

  const resourceTeamColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params => params?.context?.filteredMetadata?.[TEAMS],
      handleUpdate: (dataId, updatedData, params) => {
        const { teams } = params.context.filteredMetadata;

        const selectedTeamTitle = updatedData.resourceTeamLv2Title || updatedData.resourceTeamLv1Title;
        const teamsArr = updatedData.resourceTeamLv2Title ? teams.reduce((acc, team) => [...acc, ...team.children], []) : teams;
        const selectedTeam = teamsArr.find(team => team.title === selectedTeamTitle);

        return updateProjectById(dataId, { resource_team_id: selectedTeam?.id ?? null });
      },
      autocompleteParams: {
        name: 'resourceTeamTitle',
        levelOneFieldName: 'resourceTeamLv1',
        levelTwoFieldName: 'resourceTeamLv2',
        withNullOption: true,
        getSystemFieldName,
        formatByRoadmap: false,
      },
    });

    return {
      field: 'resourceTeamTitle',
      headerName: getSystemFieldName('team'),
      cellRenderer: EmptyValueIfNotProjectRenderer(ChipCellRenderer),
      cellRendererParams: params => makeGetMetadataChipCellRendererParams(params.context.metadata.teams)(params),
      cellEditorPopup: true,
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      width: 120,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
      ...metadataAutocompleteEditorColumnDef,
    };
  }, [getSystemFieldName]);

  const teams2ColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params => {
        const filteredOptions = getAdditionalMetadataOptionsFromContext(params, 'additionalTeams2Options');

        return filteredOptions;
      },
      autocompleteParams: {
        name: ADDITIONAL_TEAMS_2,
        roadmapsKey: ADDITIONAL_TEAMS_2,
        getSystemFieldName,
        formatByRoadmap: false,
      },
      isMultiSelect: true,
    });

    return {
      field: ADDITIONAL_TEAMS_2,
      headerName: getSystemFieldName('team2', true),
      cellEditorParams: params => ({
        options: getAdditionalMetadataOptionsFromContext(params, 'additionalTeams2Options'),

        optionsMapper: { label: 'title', value: 'id' },
        hideCreateOption: true,
      }),
      ...additionalMetadataColumnsCommonProps,
      ...(hasNewMetadataAutocompleteOnGrids ? metadataAutocompleteEditorColumnDef : {}),
    };
  }, [getSystemFieldName]);

  const blocksTotalColumnDef = useMemo(
    () => ({
      ...numericColumnDef,
      width: 150,
      headerName: 'Blocking',
      field: 'blocksTotal',
      editable: false,
      cellRenderer: TextWithOpenLinkCellRenderer,
      cellEditorParams: {
        onClickFactory: data => () => openProjectInfo(data, TAB_DEPENDENCIES),
      },
      valueFormatter: ({ value }) => value,
    }),
    [openProjectInfo],
  );

  const blockedByTotalColumnDef = useMemo(
    () => ({
      ...numericColumnDef,
      width: 150,
      headerName: 'Blocked By',
      field: 'blockedByTotal',
      editable: false,
      cellRenderer: TextWithOpenLinkCellRenderer,
      cellEditorParams: {
        onClickFactory: data => () => openProjectInfo(data, TAB_DEPENDENCIES),
      },
      valueFormatter: ({ value }) => value,
    }),
    [openProjectInfo],
  );

  const externalKeyColumnDef = useMemo(
    () =>
      hasExternalKey && {
        ...textCenterAlignedColumnDef,
        width: 150,
        headerName: 'External Key',
        field: 'external_key',
        editable: false,
      },
    [hasExternalKey],
  );

  const externalParentKeyColumnDef = useMemo(
    () =>
      hasExternalKey && {
        ...textCenterAlignedColumnDef,
        width: 150,
        headerName: 'External Parent Key',
        field: 'external_parent_key',
        editable: false,
      },
    [hasExternalKey],
  );

  return useMemo(
    () => [
      // Static columns:
      idColumnDef,

      // Movable columns:
      hasHierarchy && parentColumnDef,
      canView(PERMISSION_RESOURCES.roadmapCorp) && roadmapCorpColumnDef,
      roadmapColumnDef,
      hasProducts && product1ColumnDef,
      hasProducts2 && product2ColumnDef,
      hasMetadataMultiSelect && additionalRoadmapsColumnDef,
      hasMetadataMultiSelect && hasProducts && additionalProducts1ColumnDef,
      hasMetadataMultiSelect && hasProducts2 && additionalProducts2ColumnDef,
      timeframeColumnDef,
      hasMultiLevelMetadata && timeframe2ColumnDef,
      hasMetadataMultiSelect && additionalTimeframesColumnDef,
      hasMetadataMultiSelect && hasMultiLevelMetadata && additionalTimeframes2ColumnDef,
      canView(PERMISSION_RESOURCES.timeframeCorp) && timeframeCorpColumnDef,
      ownerColumnDef,
      categoryColumnDef,
      hasMetadataMultiSelect && additionalCategoriesColumnDef,
      themeColumnDef,
      hasMetadataMultiSelect && additionalThemesColumnDef,
      priorityColumnDef,
      objectiveColumnDef,
      hasKeyResults && keyResult1ColumnDef,
      hasKeyResults2 && keyResult2ColumnDef,
      hasMetadataMultiSelect && additionalObjectivesColumnDef,
      hasMetadataMultiSelect && hasKeyResults && additionalKeyResults1ColumnDef,
      hasMetadataMultiSelect && hasKeyResults2 && additionalKeyResults2ColumnDef,
      canView(PERMISSION_RESOURCES.objectiveCorp) && objectiveCorpColumnDef,
      ...customUserFieldsColumnDefs,
      hasMetadataMultiSelect && teamsColumnDef,
      hasMetadataMultiSelect && hasTeams2 && teams2ColumnDef,
      resourceTeamColumnDef,
      blocksTotalColumnDef,
      blockedByTotalColumnDef,
      externalKeyColumnDef,
      externalParentKeyColumnDef,
    ],
    [
      hasHierarchy,
      hasKeyResults,
      hasKeyResults2,
      hasProducts,
      hasProducts2,
      hasMultiLevelMetadata,
      idColumnDef,
      parentColumnDef,
      ownerColumnDef,
      categoryColumnDef,
      themeColumnDef,
      priorityColumnDef,
      timeframeColumnDef,
      timeframe2ColumnDef,
      timeframeCorpColumnDef,
      roadmapColumnDef,
      product1ColumnDef,
      additionalProducts1ColumnDef,
      product2ColumnDef,
      additionalProducts2ColumnDef,
      roadmapCorpColumnDef,
      additionalRoadmapsColumnDef,
      objectiveColumnDef,
      keyResult1ColumnDef,
      keyResult2ColumnDef,
      objectiveCorpColumnDef,
      additionalObjectivesColumnDef,
      additionalKeyResults1ColumnDef,
      additionalKeyResults2ColumnDef,

      customUserFieldsColumnDefs,
      canView,
      hasMetadataMultiSelect,
      additionalTimeframesColumnDef,
      additionalTimeframes2ColumnDef,
      additionalCategoriesColumnDef,
      additionalThemesColumnDef,
      teamsColumnDef,
      teams2ColumnDef,
      resourceTeamColumnDef,
      blocksTotalColumnDef,
      blockedByTotalColumnDef,
      externalKeyColumnDef,
      externalParentKeyColumnDef,
    ],
  );
};

export default useCommonFieldsColumnsDefinitions;
