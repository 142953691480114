import React, { useCallback, useMemo } from 'react';

import formatDate from 'utils/dates/formatDate';
import { TIMEFRAMES } from 'store/grids/constants';
import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SettingsDialogs from 'components/SettingsDialogs';
import { BaseGrid } from 'containers/Grids';
import { useSettingsActions, useSettingsGridSelection } from 'containers/Grids/SettingsGrid/hooks';
import { buildGridCheckboxDisabledComparator } from 'design-system/molecules/AgGridReact-New/helpers';

import { PERMISSION_RESOURCES, REFERER_SETTINGS } from '@dragonboat/permissions';

import usePermissions from 'hooks/permissions/usePermissions';
import useMetadataTopLevel from 'hooks/metadata/useMetadataTopLevel';

import useTimeframes from './hooks/useTimeframes';
import useTimeframesGridProps from './hooks/useTimeframesGridProps';
import useTimeframesGridColumns from './hooks/useTimeframesGridColumns';
import useTimeframesGridEventHandlers from './hooks/useTimeframesGridEventHandlers';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useForceVisibleColumns from 'hooks/useForceVisibleColumns';
import { getMetadataFilteredByActiveItems } from 'utils';

const TIMEFRAME_KEY = 'timeframe';

const Timeframes = ({
  showActionBar = true,
  updateSystemFieldsName,
  systemFields,
  hideMetadataRoadmaps,
  hasMultiLevelPortfolioMetadata,
  forceVisibleFields,
  customHeight,
  hideArchivedItems,
  organization,
}) => {
  const { organizationAccessControlData } = useOrganizationsAccessControl();

  const { canCreate, canView, canUpdate, canMerge } = usePermissions();
  const { timeframes: timeframesTopLevel } = useMetadataTopLevel();
  const canUpdateOrCreate = canUpdate(timeframesTopLevel) || canCreate(timeframesTopLevel);

  const permissions = {
    allowActions: canUpdateOrCreate,
    canAdd: canCreate(timeframesTopLevel),
    rename: {
      canUpdate: showActionBar && canUpdate(PERMISSION_RESOURCES.systemFieldName, { field: timeframesTopLevel }),
      canView: showActionBar && canView(PERMISSION_RESOURCES.systemFieldName, { field: timeframesTopLevel }),
    },
    canMerge: showActionBar && canMerge(timeframesTopLevel),
    canBulkDelete: showActionBar && canUpdateOrCreate,
  };

  const {
    timeframes,
    createTimeframe,
    createTimeframes,
    updateTimeframes,
    bulkDeleteTimeframes,
    addTimeframeWithoutSave,
    removeUnsavedTimeframes,
    update,
    deleteTimeframeById,
    mergeTimeframes,
    switchTimeframesRowOrder,
    createTimeframeRoadmap,
    deleteTimeframeRoadmap,
    bulkDeleteTimeframeRoadmaps,
    childrenSettingKeys,
  } = useTimeframes(hasMultiLevelPortfolioMetadata);

  const hasCorpLevel = canView(PERMISSION_RESOURCES.timeframeCorp, { referer: REFERER_SETTINGS });

  const addTimeframeWithoutSaveAction = useCallback(
    timeframe =>
      addTimeframeWithoutSave({
        ...timeframe,
        hasCorpLevel,
      }),
    [hasCorpLevel],
  );

  // Settings common agGrid configuration

  const { selectionMode, selectedItems, enableSelectionMode, disableSelectionMode, updateSelectedOnAgGridInit } =
    useSettingsGridSelection(TIMEFRAMES, {
      actions: {
        remove: removeUnsavedTimeframes,
      },
    });

  const {
    itemToDelete,
    setItemToDelete,
    deleteItem,
    showBulkDeleteDialog,
    setShowBulkDeleteDialog,
    deleteProgress,
    bulkDeleteItems,
    mergeLightboxVisible,
    showMergeLightbox,
    hideMergeLightbox,
  } = useSettingsActions({
    settingType: TIMEFRAMES,
    selectedItems,
    disableSelectionMode,
    deleteById: deleteTimeframeById,
    bulkDelete: bulkDeleteTimeframes,
  });

  // Specific Timeframes agGrid configuration

  const timeframesGridProps = useTimeframesGridProps({
    createTimeframes,
    updateTimeframes,
    hasMultiLevelPortfolioMetadata,
    hasCorpLevel,
  });

  const timeframesGridColumnDefs = useTimeframesGridColumns({
    allowActions: permissions.allowActions,
    selectionMode,
    setItemToDelete,
    addTimeframeWithoutSave,
    groupColumn: {
      hasMultiLevelPortfolioMetadata,
    },
    roadmapColumn: {
      createTimeframeRoadmap,
      deleteTimeframeRoadmap,
      timeframes,
      bulkDeleteTimeframeRoadmaps,
      hideMetadataRoadmaps,
    },
  });

  timeframesGridColumnDefs.columnDefs = useForceVisibleColumns(timeframesGridColumnDefs.columnDefs, forceVisibleFields);

  const timeframesGridEventHandlers = useTimeframesGridEventHandlers({
    timeframes,
    update,
    createTimeframe,
    removeUnsavedTimeframes,
    switchTimeframesRowOrder,
  });

  // Add the isSelectable flag to timeframes to update the selection state in the agGrid

  const timeframesWithSelectable = useMemo(
    () =>
      (getMetadataFilteredByActiveItems(timeframes, hideArchivedItems) || []).map(timeframe => {
        const checkboxDisabledComparator = buildGridCheckboxDisabledComparator(timeframe);
        const isSelectableBasedOnOtherItems = selectedItems.filter(checkboxDisabledComparator).length === 0;

        const isSameOrg = timeframe.organization_id === organization?.id;

        const isCorpTimeframe = organizationAccessControlData.some(
          access => access.portfolio_placeholder_timeframe_id === timeframe.id,
        );

        return {
          ...timeframe,
          isSelectable: isSameOrg && !isCorpTimeframe && isSelectableBasedOnOtherItems,
        };
      }),
    [timeframes, selectedItems, hideArchivedItems],
  );

  const transformLabel = useCallback(timeframe => `${timeframe.title} (${formatDate(timeframe.date)})`, []);

  return (
    <>
      {permissions.allowActions && (
        <SettingsActionsToolbar
          settingKey={timeframesTopLevel}
          childrenSettingKeys={childrenSettingKeys}
          settingType={TIMEFRAMES}
          systemFields={systemFields}
          updateSystemFieldsName={updateSystemFieldsName}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          onEnableSelection={enableSelectionMode}
          onCancelSelection={disableSelectionMode}
          setShowBulkDeleteDialog={setShowBulkDeleteDialog}
          showMergeLightbox={showMergeLightbox}
          add={addTimeframeWithoutSaveAction}
          renamePermissions={permissions.rename}
          canMerge={permissions.canMerge}
          canBulkDelete={permissions.canBulkDelete}
          hideAddButton={!permissions.canAdd}
        />
      )}
      <BaseGrid
        onGridReady={updateSelectedOnAgGridInit}
        rowData={timeframesWithSelectable}
        {...timeframesGridProps}
        {...timeframesGridColumnDefs}
        {...timeframesGridEventHandlers}
        height={customHeight ?? timeframesGridProps.height}
      />
      <SettingsDialogs
        settingKey={TIMEFRAME_KEY}
        settingType={TIMEFRAMES}
        showBulkDeleteDialog={showBulkDeleteDialog}
        setShowBulkDeleteDialog={setShowBulkDeleteDialog}
        deleteProgress={deleteProgress}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        deleteItem={deleteItem}
        bulkDelete={bulkDeleteItems}
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
        mergeLightboxVisible={mergeLightboxVisible}
        hideMergeLightbox={hideMergeLightbox}
        merge={mergeTimeframes}
        transformLabel={transformLabel}
      />
    </>
  );
};

export default Timeframes;
