import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SvgComponent(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" height={24} width={24} {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M6 10h3v10H6V10zm0-5h3v4H6V5zm10 11h3v4h-3v-4zm0-3h3v2h-3v-2zm-5 0h3v7h-3v-7zm0-4h3v3h-3V9z" />
    </SvgIcon>
  );
}
