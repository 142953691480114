import React from 'react';
import styled from 'styled-components';

const TableHeader = React.memo(({ children, ...otherProps }) => {
  return <Wrapper {...otherProps}>{children}</Wrapper>;
});

TableHeader.displayName = 'TableHeader';

export default TableHeader;

const Wrapper = styled.div`
  &&&& {
    justify-content: center;
    align-items: center;
    line-height: 24px;
    background: ${({ theme }) => theme.palette.background.white};

    ${({ sticky }) =>
      sticky &&
      `
      position: sticky;
    `}

    ${({ top }) =>
      top &&
      `
      top: 0;
    `}

    ${({ left }) =>
      left &&
      `
      left: ${left - 1}px;
    `}

    ${({ zIndex }) =>
      zIndex &&
      `
      z-index: ${zIndex};
    `}

    ${({ width }) =>
      width &&
      `
      width: ${width}px;
    `}

    ${({ maxHeight }) =>
      maxHeight &&
      `
      height: ${maxHeight}px;
      max-height: ${maxHeight}px;
    `}

    ${({ split }) =>
      split &&
      `
      border-image: linear-gradient(270deg, #0E275200 0%, #0E27522C 100%) 30;
      border-right: 3px solid;
    `}
  }
`;
