import { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { keys, isNil } from 'ramda';

import { DASHBOARDS_PAGE } from 'constants/filters/pages';
import { getAllObjectives } from 'store/objectives/selectors';
import { getUserFilters } from 'store/filters/selectors';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import { formatDataByMetadataForPieChart } from '../../helpers';
import useSystemFields from 'hooks/useSystemFields';
import { getGroupOptions } from 'store/projects/helpers/groupOptions';
import usePageFilters from 'hooks/filters/usePageFilters';
import { getOrgCustomUserProjectFields, getOrgHasCustomUserProjectFields } from 'store/organization';

const DEFAULT_CHART_DATA = {
  labels: [],
  datasets: [],
};
const CHART_API_URL = '/api/v1/reports/dashboard/ideas-by-goal';
const OBJECTIVE_KEY = 'objective';

const checkHasChartData = (chartData = {}) => Boolean(keys(chartData.datasets).length);

const requestChartData = (filters = {}, userFilters = {}, hasBet, displayLayer) => {
  const projectsFilter = compileFiltersBody(filters, userFilters, hasBet, DASHBOARDS_PAGE, displayLayer, null);

  return axios.post(CHART_API_URL, projectsFilter);
};

/**
 * @function useEpicGoalDoughnutChartData
 *
 * Use chart data for epics by goal
 *
 * @return {Object}
 */
const useEpicGoalDoughnutChartData = () => {
  const [state, setState] = useState({
    countersByRoadmap: null,
    loading: false,
  });

  const objectives = useSelector(getAllObjectives);
  const metadataMap = useMemo(() => {
    return (objectives || []).reduce((objs, obj) => {
      if (!obj.id) return objs;
      objs[obj.id] = {
        title: obj.title,
        color: obj.color,
      };
      return objs;
    }, {});
  }, [objectives]);

  const { pageFilters: filters, displayLayer } = usePageFilters(DASHBOARDS_PAGE);
  const userFilters = useSelector(state => getUserFilters(state));
  const hasBet = useSelector(state => state.organization.organization.has_bet);

  const chartData = useMemo(() => {
    if (isNil(state.countersByRoadmap)) {
      return DEFAULT_CHART_DATA;
    }

    const { ids, values, colors, labels } = formatDataByMetadataForPieChart(state.countersByRoadmap, metadataMap);

    const chartData = {
      labels,
      ids,
      datasets: [
        {
          label: '',
          data: values,
          backgroundColor: colors,
          hoverOffset: 4,
        },
      ],
    };

    return chartData;
  }, [state.countersByRoadmap, metadataMap]);

  const makeChartData = useCallback(async () => {
    setState(state => ({ ...state, loading: true }));

    try {
      const { data } = await requestChartData(filters, userFilters, hasBet, displayLayer);

      setState({ countersByRoadmap: data, loading: false });
    } catch {
      setState({ countersByRoadmap: {}, loading: false });
    }
  }, [filters, userFilters, hasBet, displayLayer]);

  useEffect(() => {
    makeChartData();
  }, [makeChartData]);

  const hasData = useMemo(() => checkHasChartData(chartData), [chartData]);

  const [getSystemFieldName] = useSystemFields();

  const hasCustomUserFields = useSelector(getOrgHasCustomUserProjectFields);
  const customUserFields = useSelector(getOrgCustomUserProjectFields);

  const groupOptions = useMemo(
    () =>
      getGroupOptions({
        getSystemFieldName,
        hasKeyResults: true,
        withNullOption: true,
        customUserFields: hasCustomUserFields ? customUserFields : {},
      }),
    [getSystemFieldName],
  );

  const selectedGroup1 = useMemo(() => groupOptions.find(go => go.key === OBJECTIVE_KEY), [groupOptions]);

  return {
    chartData,
    hasData,
    loading: state.loading,
    displayLayer,
    selectedGroup1,
  };
};

export default useEpicGoalDoughnutChartData;
