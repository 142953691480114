import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_ROADMAP_VERSIONS'),
  ...getThunkActionTypes('FETCH_ROADMAP_VERSION_PROJECTS'),
  ...getThunkActionTypes('CREATE_NEW_ROADMAP_VERSION'),
  ...getThunkActionTypes('UPDATE_ROADMAP_VERSION'),
  ...getThunkActionTypes('RUN_ONE_CLICK_PLAN'),
  ...getThunkActionTypes('FETCH_MULTIPLE_ROADMAP_VERSION_PROJECTS'),
  ...getThunkActionTypes('SET_VERSION_AS_PLAN_OF_RECORD'),
  ...getThunkActionTypes('DELETE_ROADMAP_VERSION'),
  ...getThunkActionTypes('SET_ROADMAP_VERSIONS_PROJECTS'),
  ...getThunkActionTypes('LOAD_SCENARIO_FROM_QUERY_PARAM'),
  ...getThunkActionTypes('CREATE_OR_LOAD_NEW_ROADMAP_VERSION_AFTER_DELETE'),

  SET_ROADMAP_VERSION_SELECTED: 'SET_ROADMAP_VERSION_SELECTED',
  STORE_ROADMAP_VERSION_UPDATED_PROJECT: 'STORE_ROADMAP_VERSION_UPDATED_PROJECT',
  UPDATE_ROADMAP_VERSION_DIALOG_PROPERTIES: 'UPDATE_ROADMAP_VERSION_DIALOG_PROPERTIES',
  DISCARD_ROADMAP_VERSION_UPDATED_PROJECTS: 'DISCARD_ROADMAP_VERSION_UPDATED_PROJECTS',
  SET_SELECTED_ROADMAP_VERSIONS_TO_COMPARE: 'SET_SELECTED_ROADMAP_VERSIONS_TO_COMPARE',
  SET_SELECTED_ROADMAP_VERSIONS_FIELD: 'SET_SELECTED_ROADMAP_VERSIONS_FIELD',
  SET_STACKED_BY_CHART_OPTION_SELECTED: 'SET_STACKED_BY_CHART_OPTION_SELECTED',
  SET_PROJECTED_CHART_OPTION_SELECTED: 'SET_PROJECTED_CHART_OPTION_SELECTED',
  SET_TIME_BUCKET_CHART_OPTION_SELECTED: 'SET_TIME_BUCKET_CHART_OPTION_SELECTED',
  SET_BASE_COMPARE_VERSION_SELECTED: 'SET_BASE_COMPARE_VERSION_SELECTED',
  SET_COMPARE_SCENARIOS_VERSIONS_COLORS: 'SET_COMPARE_SCENARIOS_VERSIONS_COLORS',
  SET_CHARTS_DATE_INTERVAL: 'SET_CHARTS_DATE_INTERVAL',
  SET_PENDING_CHANGES: 'SET_PENDING_CHANGES',
  CLONE_ROADMAP_VERSION_FULFILLED: 'CLONE_ROADMAP_VERSION_FULFILLED',
  TOGGLE_HIDE_ROWS_WITH_SAME_DATA: 'TOGGLE_HIDE_ROWS_WITH_SAME_DATA',
  UPDATE_SCENARIO_PROJECT: 'UPDATE_SCENARIO_PROJECT',
  SET_COMPARE_SCENARIOS_EDIT_SCENARIO: 'SET_COMPARE_SCENARIOS_EDIT_SCENARIO',
  UNSET_SELECTED_SCENARIO: 'UNSET_SELECTED_SCENARIO',
  OPEN_DELETE_SCENARIO_DIALOG: 'OPEN_DELETE_SCENARIO_DIALOG',
  CLOSE_DELETE_SCENARIO_DIALOG: 'CLOSE_DELETE_SCENARIO_DIALOG',
  SET_SCENARIOS_DIALOG_OPEN: 'SET_SCENARIOS_DIALOG_OPEN',
};

export const {
  FETCH_ROADMAP_VERSIONS,
  FETCH_ROADMAP_VERSIONS_PENDING,
  FETCH_ROADMAP_VERSIONS_FULFILLED,
  FETCH_ROADMAP_VERSIONS_REJECTED,

  FETCH_ROADMAP_VERSION_PROJECTS,
  FETCH_ROADMAP_VERSION_PROJECTS_PENDING,
  FETCH_ROADMAP_VERSION_PROJECTS_FULFILLED,
  FETCH_ROADMAP_VERSION_PROJECTS_REJECTED,

  RUN_ONE_CLICK_PLAN,
  RUN_ONE_CLICK_PLAN_PENDING,
  RUN_ONE_CLICK_PLAN_FULFILLED,
  RUN_ONE_CLICK_PLAN_REJECTED,

  SET_VERSION_AS_PLAN_OF_RECORD,
  SET_VERSION_AS_PLAN_OF_RECORD_PENDING,
  SET_VERSION_AS_PLAN_OF_RECORD_FULFILLED,
  SET_VERSION_AS_PLAN_OF_RECORD_REJECTED,

  CREATE_NEW_ROADMAP_VERSION,
  CREATE_NEW_ROADMAP_VERSION_PENDING,
  CREATE_NEW_ROADMAP_VERSION_FULFILLED,
  CREATE_NEW_ROADMAP_VERSION_REJECTED,

  UPDATE_ROADMAP_VERSION,
  UPDATE_ROADMAP_VERSION_PENDING,
  UPDATE_ROADMAP_VERSION_FULFILLED,
  UPDATE_ROADMAP_VERSION_REJECTED,

  STORE_ROADMAP_VERSION_UPDATED_PROJECT,
  DISCARD_ROADMAP_VERSION_UPDATED_PROJECTS,
  SET_ROADMAP_VERSION_SELECTED,
  UPDATE_ROADMAP_VERSION_DIALOG_PROPERTIES,
  SET_SELECTED_ROADMAP_VERSIONS_TO_COMPARE,
  SET_SELECTED_ROADMAP_VERSIONS_FIELD,

  FETCH_MULTIPLE_ROADMAP_VERSION_PROJECTS,
  FETCH_MULTIPLE_ROADMAP_VERSION_PROJECTS_PENDING,
  FETCH_MULTIPLE_ROADMAP_VERSION_PROJECTS_FULFILLED,
  FETCH_MULTIPLE_ROADMAP_VERSION_PROJECTS_REJECTED,

  DELETE_ROADMAP_VERSION,
  DELETE_ROADMAP_VERSION_FULFILLED,

  SET_STACKED_BY_CHART_OPTION_SELECTED,
  SET_PROJECTED_CHART_OPTION_SELECTED,
  SET_TIME_BUCKET_CHART_OPTION_SELECTED,

  SET_BASE_COMPARE_VERSION_SELECTED,
  SET_COMPARE_SCENARIOS_VERSIONS_COLORS,

  SET_CHARTS_DATE_INTERVAL,
  SET_PENDING_CHANGES,
  CLONE_ROADMAP_VERSION_FULFILLED,

  SET_ROADMAP_VERSIONS_PROJECTS,
  SET_ROADMAP_VERSIONS_PROJECTS_PENDING,
  SET_ROADMAP_VERSIONS_PROJECTS_FULFILLED,
  SET_ROADMAP_VERSIONS_PROJECTS_REJECTED,

  TOGGLE_HIDE_ROWS_WITH_SAME_DATA,

  UPDATE_SCENARIO_PROJECT,
  SET_COMPARE_SCENARIOS_EDIT_SCENARIO,

  UNSET_SELECTED_SCENARIO,

  OPEN_DELETE_SCENARIO_DIALOG,
  CLOSE_DELETE_SCENARIO_DIALOG,

  SET_SCENARIOS_DIALOG_OPEN,

  LOAD_SCENARIO_FROM_QUERY_PARAM,
  LOAD_SCENARIO_FROM_QUERY_PARAM_PENDING,
  LOAD_SCENARIO_FROM_QUERY_PARAM_FULFILLED,

  CREATE_OR_LOAD_NEW_ROADMAP_VERSION_AFTER_DELETE,
  CREATE_OR_LOAD_NEW_ROADMAP_VERSION_AFTER_DELETE_PENDING,
  CREATE_OR_LOAD_NEW_ROADMAP_VERSION_AFTER_DELETE_FULFILLED,
} = actionTypes;

export default actionTypes;
