import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isNil, keys, path, prop, propOr } from 'ramda';
import axios from 'axios';

import usePageFilters from 'hooks/filters/usePageFilters';
import { COMPARE_SCENARIOS_FILTER } from 'constants/filters/pages';
import { getBaseCompareVersionSelected } from 'store/roadmapVersions/selectors';

import { PLAN_OF_RECORD_ID } from 'constants/common';
import { SCALE_TYPE_LABELS, SCALE_TYPE_TIME } from '../utils/scales';
import {
  TIME_BUCKET_TIMEFRAME_KEY,
  PROJECTED_OPTION_BUSINESS_VALUE_KEY,
  PROJECTED_OPTION_CUSTOMER_VALUE_KEY,
} from 'store/roadmapVersions/constants/defaultChartOptions';

const DEFAULT_CHART_DATA = {
  datasets: [],
};

const checkHasChartData = (chartData = {}) => Boolean(keys(chartData.datasets).length);

const CHART_API_URL = '/api/v1/reports/scenarios/compare';

const requestChartData = async requestPayload => {
  return axios.post(CHART_API_URL, requestPayload);
};

const getChartLabel = projectedField => {
  if (projectedField === PROJECTED_OPTION_CUSTOMER_VALUE_KEY) {
    return 'Customer Value per scenario';
  }
  if (projectedField === PROJECTED_OPTION_BUSINESS_VALUE_KEY) {
    return 'Benefits per scenario';
  }
  throw new Error(`Label not available for '${projectedField}'`);
};

/**
 * @function useScenariosBenefitsComparisonChartData
 *
 * Use chart data for benefit comparison.
 *
 * @return {Object}
 */
const useScenariosBenefitsComparisonChartData = ({
  roadmapVersionIds,
  projectedField,
  timeBucketMode,
  dateInterval,
  scenarioLabels,
  scenarioColors,
}) => {
  const [state, setState] = useState({
    dataKeyedByScenarioId: null,
    loading: false,
  });

  const chartData = useMemo(() => {
    if (isNil(state.dataKeyedByScenarioId)) {
      return DEFAULT_CHART_DATA;
    }

    const scenarioIds = keys(state.dataKeyedByScenarioId);

    const datasets = scenarioIds.map(scenarioId => {
      const scenarioData = state.dataKeyedByScenarioId[scenarioId].map(entry => ({
        x: entry.t,
        y: path(['stacks', 'no_group'])(entry),
      }));

      const datasetColor = prop(scenarioId)(scenarioColors);
      const datasetLabel = propOr('N/A', scenarioId)(scenarioLabels);

      return {
        label: datasetLabel,
        yAxisID: 'y',
        data: scenarioData,
        borderColor: datasetColor,
        backgroundColor: datasetColor,
      };
    }, []);

    return {
      datasets,
    };
  }, [state.dataKeyedByScenarioId, scenarioLabels, scenarioColors]);

  const { filtersForApiByLevels } = usePageFilters(COMPARE_SCENARIOS_FILTER);
  const baseCompareVersionSelected = useSelector(getBaseCompareVersionSelected);

  const makeChartData = useCallback(async () => {
    if (isNil(projectedField) || isNil(dateInterval)) {
      return;
    }

    setState(state => ({ ...state, loading: true }));

    try {
      const requestPayload = {
        scenarioIds: roadmapVersionIds,
        interval: dateInterval,
        projectedField,
        timeBucketMode,
        stackByField: null,
        filters: filtersForApiByLevels,
        baseCompareVersionId: baseCompareVersionSelected?.id || PLAN_OF_RECORD_ID,
      };
      const { data } = await requestChartData(requestPayload);

      setState({ dataKeyedByScenarioId: data, loading: false });
    } catch {
      setState({ dataKeyedByScenarioId: {}, loading: false });
    }
  }, [roadmapVersionIds, projectedField, timeBucketMode, dateInterval, filtersForApiByLevels, baseCompareVersionSelected?.id]);

  useEffect(() => {
    makeChartData();
  }, [makeChartData]);

  const hasData = useMemo(() => checkHasChartData(chartData), [chartData]);
  const chartScaleType = timeBucketMode === TIME_BUCKET_TIMEFRAME_KEY ? SCALE_TYPE_LABELS : SCALE_TYPE_TIME;

  return {
    chartData,
    chartScaleType,
    chartTitle: getChartLabel(projectedField),
    hasData,
    loading: state.loading,
  };
};

export default useScenariosBenefitsComparisonChartData;
