import { useDispatch, useSelector } from 'react-redux';
import {
  updateProjectIntegration,
  isUpdateProjectIntegrationLoading,
  getUpdateProjectIntegrationError,
} from 'store/integrations';

export default (projectId, updateProject, integration) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isUpdateProjectIntegrationLoading);
  const error = useSelector(getUpdateProjectIntegrationError);

  const unlinkMilestone = async () => {
    dispatch(updateProjectIntegration(projectId, { epic_id: null, org_integration_id: integration.id }));
    await updateProject();
    return true;
  };

  return [unlinkMilestone, isLoading, error];
};
