import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateState as updateStateIdeasBoard } from 'store/ideasBoard';
import { updateState as updateStateRoadmap } from 'store/roadmap';
import { updateState as updateStateManage } from 'store/manage';
import { updateState as updateStateForecast } from 'store/forecast';
import { updateState as updateStateSummary } from 'store/summary';
import { COMPARE_ROADMAP_VERSIONS, saveGridConfig } from 'store/grids';

import { getGridConfigValue } from 'store/grids/selectors';

import { IDEAS, ESTIMATES, PDLC } from 'store/grids/constants';
import { getSliceProp as getSummarySliceProp } from 'store/summary/selectors';
import { COMPARE_SCENARIOS_SUMMARY } from 'constants/summary';
import { ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PATH, ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PATH } from 'constants/filters/pages';
import { COMPARE_ROADMAP_HISTORY, COMPARE_ROADMAP_HISTORY_SUMMARY } from 'features/RoadmapHistory/constants';

/**
 * @function getSelectorByPath
 *
 * The goal of this function is to avoid the declaration of multiple selector before the main function
 * switch case and also to avoid conditional hooks inside the main function switch case.
 *
 * Conditional hooks are not allowed in react -> https://reactjs.org/docs/hooks-rules.html
 *
 * @param {String} path
 * @returns {Function} the selector function to get search string
 */
const getSelectorByPath = path => state => {
  switch (path) {
    case '/ideas':
    case '/ideas/board':
    case '/dashboard/board':
      return state.ideasBoard.searchString;
    case '/ideas/grid':
    case '/dashboard/grid':
      return getGridConfigValue(state, IDEAS, 'searchText');
    case '/ideas/pdlc':
      return getGridConfigValue(state, PDLC, 'searchText');
    case '/compare-scenarios/list':
      return getGridConfigValue(state, COMPARE_ROADMAP_VERSIONS, 'searchText');
    case '/compare-scenarios/summary':
      return getSummarySliceProp('searchStr')(state, COMPARE_SCENARIOS_SUMMARY);
    case '/ideas/roadmap':
    case '/dashboard/roadmap':
    case '/scenario/swimlane':
      return state.roadmap.searchString;
    case '/forecast':
    case '/forecast/timeline':
    case '/dashboard/forecast-timeline':
    case '/scenario/gantt':
    case '/scenario/aiplanner':
      return state.forecast.searchStr;
    case '/forecast/list':
    case '/dashboard/list':
    case '/scenario/list':
      return getGridConfigValue(state, ESTIMATES, 'searchText');
    case '/dashboard/timeline':
    case '/ideas/timeline':
      return state.manage.manageState.searchStr;
    case '/dashboard/summary':
    case '/ideas/summary':
    case '/scenario/summary':
      return getSummarySliceProp('searchStr')(state);
    case `/${ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PATH}`:
      return getGridConfigValue(state, COMPARE_ROADMAP_HISTORY, 'searchText');
    case `/${ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PATH}`:
      return getSummarySliceProp('searchStr')(state, COMPARE_ROADMAP_HISTORY_SUMMARY);
    default:
  }
};

export default path => {
  const dispatch = useDispatch();

  let search = useSelector(getSelectorByPath(path));
  let setSearch = () => {};

  switch (path) {
    case '/ideas':
    case '/ideas/board':
    case '/dashboard/board':
      setSearch = value => updateStateIdeasBoard({ searchString: value });
      break;
    case '/ideas/grid':
    case '/dashboard/grid':
      setSearch = value => saveGridConfig(IDEAS, 'searchText', value);
      break;
    case '/ideas/pdlc':
      setSearch = value => saveGridConfig(PDLC, 'searchText', value);
      break;
    case '/compare-scenarios/list':
      setSearch = value => saveGridConfig(COMPARE_ROADMAP_VERSIONS, 'searchText', value);
      break;
    case '/compare-scenarios/summary':
      setSearch = value => updateStateSummary({ searchStr: value }, COMPARE_SCENARIOS_SUMMARY);
      break;
    case '/ideas/roadmap':
    case '/dashboard/roadmap':
    case '/scenario/swimlane':
      setSearch = value => updateStateRoadmap({ searchString: value });
      break;
    case '/forecast':
    case '/forecast/timeline':
    case '/dashboard/forecast-timeline':
    case '/scenario/gantt':
    case '/scenario/aiplanner':
      setSearch = value => updateStateForecast({ searchStr: value });
      break;
    case '/forecast/list':
    case '/dashboard/list':
    case '/scenario/list':
      setSearch = value => saveGridConfig(ESTIMATES, 'searchText', value);
      break;
    case '/dashboard/timeline':
    case '/ideas/timeline':
      setSearch = value => updateStateManage('manageState', { searchStr: value });
      break;
    case '/dashboard/summary':
    case '/ideas/summary':
    case '/scenario/summary':
      setSearch = value => updateStateSummary({ searchStr: value });
      break;
    case `/${ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PATH}`:
      setSearch = value => saveGridConfig(COMPARE_ROADMAP_HISTORY, 'searchText', value);
      break;
    case `/${ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PATH}`:
      setSearch = value => updateStateSummary({ searchStr: value }, COMPARE_ROADMAP_HISTORY_SUMMARY);
      break;
    default:
      search = '';
      break;
  }

  React.useEffect(() => {
    if (setSearch && !!search) dispatch(setSearch(''));
  }, [path, !setSearch]);

  if (search == null) search = '';

  return [search, setSearch];
};
