import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import useMetricsIntegrations from 'hooks/useMetricsIntegrations';
import usePermissions from 'hooks/permissions/usePermissions';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { getUsers } from 'store/users/selectors';
import { getMetrics } from 'store/metrics/selectors';
import { getRoadmaps, getCorpRoadmaps } from 'store/roadmaps/selectors';
import { selectMetricLevels } from 'features/MetricLevels/store/selectors';
import { createMetricRoadmap, deleteMetricRoadmap, bulkDeleteMetricRoadmaps } from 'features/MetricsDialog/store/thunks';
import { METRIC } from 'constants/common';
import useSystemFields from 'hooks/useSystemFields';
import { groupByTeamHierarchy } from 'shared/helpers/entities/users';
import { getOrganization } from 'store/organization/selectors';

const useMetricDetails = ({ selectedMetric }) => {
  const { isDodActive } = useOrganizationsAccessControl();

  const { canView } = usePermissions();

  const hasTeams2 = useSelector(state => getOrganization(state).has_teams_2);
  const { canViewMetricIntegration, canUpdateMetricIntegration, updateMetricIntegration } = useMetricsIntegrations();
  const [getSystemFieldName] = useSystemFields();

  const canViewMetricLevels = canView(PERMISSION_FEATURES.metricLevels);
  const canViewMetricSummary = canView(PERMISSION_FEATURES.metricSummary);
  const canViewMetadataRoadmaps = canView(PERMISSION_FEATURES.metadataRoadmaps);
  const hasNewMetadataAutocomplete = canView(PERMISSION_FEATURES.newRoadmapMetadataAutocomplete);

  const users = useSelector(getUsers);
  const metrics = useSelector(getMetrics);
  const metricLevels = useSelector(selectMetricLevels);

  const roadmaps = useSelector(getRoadmaps);
  const corpRoadmaps = useSelector(state => getCorpRoadmaps(state, false));

  const usersOptions = useMemo(
    () => groupByTeamHierarchy(users, { hasTeams2, getSystemFieldName }),
    [users, hasTeams2, getSystemFieldName],
  );

  const updateMetricDataSource = updateMetricIntegration(selectedMetric?.id);

  const [
    ,
    formatMetadataRoadmaps,
    formatRoadmaps,
    handleClearAllItems,
    ,
    ,
    updateSelectedItems,
    addDefaultMetadataRoadmapName,
    removeMetadataRoadmap,
    getSelectedItemsRef,
  ] = useRoadmapsMetadata(createMetricRoadmap, deleteMetricRoadmap, metrics, METRIC, bulkDeleteMetricRoadmaps);

  return {
    metricLevels,
    users,
    usersOptions,
    availableRoadmaps: isDodActive ? corpRoadmaps : roadmaps,
    getSelectedRoadmapItems: getSelectedItemsRef.current,
    updateSelectedRoadmapItems: updateSelectedItems,
    formatMetadataRoadmaps,
    formatRoadmaps,
    handleClearAllRoadmapItems: handleClearAllItems,
    addDefaultMetadataRoadmapName,
    removeMetadataRoadmap,
    canViewMetricLevels,
    canViewMetricSummary,
    canViewMetadataRoadmaps,
    canViewMetricIntegration,
    canUpdateMetricIntegration,
    showMetricComments: Boolean(selectedMetric?.id),
    updateMetricDataSource,
    getSystemFieldName,
    hasNewMetadataAutocomplete,
  };
};

export default useMetricDetails;
