import React from 'react';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';

import Checkbox from 'design-system/atoms/Checkbox/index';


import GroupHead from './GroupHead';
import { GroupContainer } from './styled';

const GroupWithCheckboxes = ({ group, changeColumnVisible, checkFieldVisible, className }) => {
  const _renderCheckboxGroup = () =>
    group.fields.map(({ field, headerName, children, disabled }) => (
      <Column key={field}>
        <FormControlLabel
          data-cy={`show-fields-${field}`}
          control={
            <Checkbox
              onChange={() => {
                if (!children) {
                  changeColumnVisible(field, !checkFieldVisible(field, children));
                } else {
                  changeColumnVisible(
                    children.map(c => c.field),
                    !checkFieldVisible(field),
                  );
                }
              }}
              checked={checkFieldVisible(field, children)}
              disabled={disabled}
              color="primary"
              fontSize="small"
            />
          }
          label={headerName}
        />
        {children &&
          children.map(({ field, headerName }) => (
            <ChildrenColumn key={field}>
              <FormControlLabel
                data-cy={`show-fields-${field}`}
                control={
                  <Checkbox
                    onChange={() => changeColumnVisible(field, !checkFieldVisible(field))}
                    checked={checkFieldVisible(field)}
                    color="primary"
                    fontSize="small"
                  />
                }
                label={headerName}
              />
            </ChildrenColumn>
          ))}
      </Column>
    ));

  return (
    <GroupContainer className={className}>
      {group.title && <GroupHead group={group} />}
      <FormGroup row>{_renderCheckboxGroup()}</FormGroup>
    </GroupContainer>
  );
};

export default GroupWithCheckboxes;

const Column = styled.div`
  display: inline-block;
  width: calc(100% / 3);

  > label {
    margin-left: -4px;

    > span {
      padding: 4px;
    }
  }
`;

const ChildrenColumn = styled.div`
  display: inline-block;
  width: 100%;
  padding-left: 26px;

  > label {
    margin-left: -4px;

    > span {
      padding: 4px;
    }
  }
`;
