import { createSelector } from 'reselect';
import { defaultTo, isNil, not } from 'ramda';

import { getEnrichedProject } from 'store/projects/selectors';
import { getOrganizationSystemFieldsNames } from 'store/organization';

import { isLoading } from 'utils/store/thunk';
import {
  FETCH_MULTIPLE_ROADMAP_VERSION_PROJECTS,
  SET_VERSION_AS_PLAN_OF_RECORD,
  RUN_ONE_CLICK_PLAN,
  FETCH_ROADMAP_VERSIONS,
} from './types';
import {
  getDefaultBucketByOption,
  DEFAULT_DATE_INTERVAL,
  DEFAULT_PROJECTED_OPTION,
  getDefaultStackedByOption,
  STACKED_BY_ROADMAP_KEY,
  TIME_BUCKET_PROJECT_ESTIMATED_END_DATE_KEY,
  TIME_BUCKET_PROJECT_TARGET_END_DATE_KEY,
  TIME_BUCKET_TIMEFRAME_KEY,
} from './constants/defaultChartOptions';
import getSystemFieldName from 'utils/getSystemFieldName';
import { CONFIRM_DELETE } from './constants/deleteScenarioDialogSteps';

const emptyArray = [];
const emptyObject = {};

const exist = Boolean;

const defaultToEmptyArray = defaultTo(emptyArray);
const defaultToEmptyObject = defaultTo(emptyObject);

export const getState = state => {
  return state.roadmapVersions;
};

export const getRoadmapVersionProjectsState = state => {
  return state.roadmapVersions.roadmapVersionsProjectsById;
};

export const getOperations = createSelector(getState, state => {
  return defaultToEmptyObject(state?.operations);
});

export const getAllRoadmapVersions = createSelector(
  state => state.roadmapVersions,
  state => defaultToEmptyArray(state?.availableRoadmapVersions),
);

export const getAvailableRoadmapVersions = createSelector(getAllRoadmapVersions, state =>
  state?.filter(version => exist(version.committed)),
);

export const getSelectedRoadmapVersion = createSelector(
  state => state,
  state => state?.roadmapVersions?.selectedRoadmapVersion,
);

export const getNextRoadmapVersionName = createSelector(
  state => state.roadmapVersions,
  state => {
    const availableRoadmapVersions = state?.availableRoadmapVersions || emptyArray;
    const lastVersion = availableRoadmapVersions[availableRoadmapVersions.length - 1] || { name: '' };

    if (!lastVersion) {
      return 'v1';
    }

    const lastVersionNumber = Number(lastVersion.name.split('v')[1]);

    return `v${(Number.isNaN(lastVersionNumber) ? 0 : lastVersionNumber) + 1}`;
  },
);

export const getStoredProjectUpdates = createSelector(
  state => state.roadmapVersions,
  state => state?.projectUpdates || {},
);

export const getRoadmapVersionDialogProperties = createSelector(
  state => state.roadmapVersions,
  state =>
    state.saveDialog ?? {
      showSavePopup: false,
      showSaveDialog: false,
      nextRoute: null,
    },
);

export const selectIsRoadmapVersionsLoaded = createSelector(getOperations, (operations = {}) => {
  const { fetchRoadmapVersions = {} } = operations;

  return not(fetchRoadmapVersions.isLoading) && not(fetchRoadmapVersions.isUninitialized) && isNil(fetchRoadmapVersions.error);
});

export const isRoadmapVersionsInitialized = createSelector(getOperations, (operations = {}) => {
  const { fetchRoadmapVersions = {} } = operations;

  return not(fetchRoadmapVersions.isUninitialized);
});

export const isRoadmapVersionsLoading = createSelector(getOperations, state => isLoading(state, FETCH_ROADMAP_VERSIONS));

export const getAllRoadmapVersionsProjects = createSelector(
  state => state,
  getState,
  (state, roadmapVersions) => {
    const { roadmapVersionsProjectsById } = roadmapVersions || {};

    return Object.keys(defaultToEmptyObject(roadmapVersionsProjectsById)).reduce((result, key) => {
      return {
        ...result,
        [key]: defaultToEmptyArray(roadmapVersionsProjectsById[key]).map(project => getEnrichedProject(state, project)),
      };
    }, {});
  },
);

export const getRoadmapVersionProjects = createSelector(
  state => state,
  getAllRoadmapVersionsProjects,
  (state, roadmapVersionsProjects, roadmapVersionId) => {
    return defaultToEmptyArray(roadmapVersionsProjects[roadmapVersionId]).map(project => getEnrichedProject(state, project));
  },
);

export const getSelectedRoadmapVersionsIdsToCompare = createSelector(getState, state => {
  return defaultToEmptyArray(state?.compareVersions?.selectedRoadmapVersions);
});

export const getSelectedRoadmapVersionsToCompare = createSelector(
  getSelectedRoadmapVersionsIdsToCompare,
  getAvailableRoadmapVersions,
  (selectedIds, availableScenarios) => {
    return (availableScenarios || emptyArray).filter(scenario => selectedIds.includes(String(scenario.id))) || emptyArray;
  },
);

export const getFetchMultipleRoadmapVersionsProjectIsLoading = createSelector(getOperations, state =>
  isLoading(state, FETCH_MULTIPLE_ROADMAP_VERSION_PROJECTS),
);

export const isSettingVersionAsPlanOfRecordLoading = createSelector(getOperations, state =>
  isLoading(state, SET_VERSION_AS_PLAN_OF_RECORD),
);

export const isRunOneClickPlanLoading = createSelector(getOperations, state => isLoading(state, RUN_ONE_CLICK_PLAN));

export const getStackedByChartOptions = createSelector(getOrganizationSystemFieldsNames, systemFieldsNames => [
  { key: null, title: 'No group' },
  { key: STACKED_BY_ROADMAP_KEY, title: getSystemFieldName('roadmap', systemFieldsNames) },
]);

export const getStackedByChartOptionSelected = createSelector(
  getState,
  getStackedByChartOptions,
  (state, stackedByOptions) => state.compareVersions?.stackedByChartOptionSelected || getDefaultStackedByOption(stackedByOptions),
);

export const getProjectedChartOptionSelected = createSelector(
  getState,
  state => state.compareVersions?.projectedChartOptionSelected || DEFAULT_PROJECTED_OPTION,
);

export const getTimeBucketChartOptions = createSelector(getOrganizationSystemFieldsNames, systemFieldsNames => [
  { key: TIME_BUCKET_PROJECT_TARGET_END_DATE_KEY, title: 'Target end month' },
  { key: TIME_BUCKET_PROJECT_ESTIMATED_END_DATE_KEY, title: 'Estimated end month' },
  { key: TIME_BUCKET_TIMEFRAME_KEY, title: getSystemFieldName('timeframe', systemFieldsNames) },
]);

export const getTimeBucketChartOptionSelected = createSelector(
  getState,
  getTimeBucketChartOptions,
  (state, timeBucketOptions) =>
    state.compareVersions?.timeBucketChartOptionSelected || getDefaultBucketByOption(timeBucketOptions),
);

export const getBaseCompareVersionSelected = createSelector(getState, state => state.compareVersions.baseCompareVersionSelected);

export const getScenarioVersionsColors = createSelector(getState, state =>
  defaultToEmptyObject(state.compareVersions.versionsColor),
);

export const getChartsDateInterval = createSelector(
  getState,
  state => state.compareVersions?.chartsDateInterval || DEFAULT_DATE_INTERVAL,
);

export const getSelectedRoadmapVersionsWithColors = createSelector(
  getAllRoadmapVersions,
  getSelectedRoadmapVersionsIdsToCompare,
  getScenarioVersionsColors,
  (roadmapVersions, selectedRoadmapVersionIds, versionColors) => {
    return roadmapVersions
      .filter(version => selectedRoadmapVersionIds.includes(String(version?.id)))
      .map(version => ({ ...version, color: versionColors[version?.id] }));
  },
);

export const getHasPendingChanges = createSelector(getState, state => {
  return state?.hasPendingChanges;
});

export const isRecentlyCloned = createSelector(getState, state => {
  return state?.recentlyCloned;
});

export const getClonedFrom = createSelector(getState, state => {
  return state?.clonedFrom;
});

export const getHideRowsWithSameData = createSelector(getState, state => state.compareVersions?.hideRowsWithSameData);

export const getCompareVersionEditScenario = createSelector(getState, state => {
  return state?.compareVersions?.compareVersionEditScenario;
});

export const getCurrentSelectedScenarioForUpdate = createSelector(
  getSelectedRoadmapVersion,
  getCompareVersionEditScenario,
  (selectedRoadmapVersion, CompareVersionEditScenario) => selectedRoadmapVersion || CompareVersionEditScenario,
);

export const selectDeleteScenarioDialogVisible = state => getState(state)?.deleteScenarioDialog?.visible || false;

export const selectDeleteScenarioDialogStep = state => getState(state)?.deleteScenarioDialog?.step || CONFIRM_DELETE;

export const selectScenarioToDelete = createSelector(getState, state => state?.deleteScenarioDialog?.scenarioToDelete);

export const selectIsScenariosDialogOpen = state => getState(state)?.scenariosDialogOpen || false;
