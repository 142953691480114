import moment from 'moment-timezone';
import { fromJS, List } from 'immutable';
import reduceReducers from 'reduce-reducers';
import cloneDeep from 'lodash/cloneDeep';
import { pipe, mergeAll, omit, prop, uniqBy } from 'ramda';

import upsertImmutableListItem from 'store/utils/upsertImmutableListItem';
import updateRows from 'store/utils/updateRows';
import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import { projectEstimatesReducer } from 'store/estimates';
import { projectTasksReducer } from 'store/tasks';
import { projectIntegrationsReducer } from 'store/integrations/reducers';
import { dependenciesProjectsReducer } from 'store/dependencies';

import deserializeProject from '../helpers/deserializeProject';
import { SET_PAGE_FILTERS } from 'store/filters/types';

import { GET_PROJECT_INTEGRATION_PROGRESS_FULFILLED } from 'src/store/projectLightbox/types';
import { APPLY_FILTERS_FULFILLED, APPLY_FILTERS_PENDING, APPLY_FILTERS_REJECTED, UPDATE_PROJECT_PERSONAS } from 'store/projects';

import {
  FETCH_PROJECTS_CHILDREN_PENDING,
  FETCH_PROJECTS_CHILDREN_FULFILLED,
  CREATE_PROJECT_PENDING,
  UPDATE_PROJECT_PENDING,
  UPDATE_PROJECTS_FULFILLED,
  UPDATE_PROJECT_FULFILLED,
  UPDATE_PROJECT_DEBOUNCED_FULFILLED,
  CREATE_PROJECT_FULFILLED,
  DELETE_PROJECTS_FULFILLED,
  UPDATE_PROJECTS_REJECTED,
  DELETE_PROJECTS_PENDING,
  DELETE_PROJECTS_REJECTED,
  CREATE_PROJECT_REJECTED,
  UPDATE_PROJECT_REJECTED,
  UPDATE_PROJECT_ROW_ORDER_FULFILLED,
  UPDATE_PROJECT_ROW_ORDER_PENDING,
  BULK_CREATE_PROJECTS_FULFILLED,
  BULK_UPDATE_PROJECTS_REJECTED,
  BULK_UPDATE_PROJECTS_PENDING,
  BULK_UPDATE_PROJECTS_FULFILLED,
  UNDO_BULK_UPDATE_PROJECTS_FULFILLED,
  UNDO_DELETE_PROJECTS_FULFILLED,
  SET_TASKS,
  SET_PROJECTS_PARENTS,
  FETCH_PROJECT_CUSTOMER_REQUESTS_FULFILLED,
  REALTIME_BULK_UPDATE_PROGRESS,
  GET_PROJECT_COUNTERS_BY_PHASE_FULFILLED,
  MERGE_PROJECTS_FULFILLED,
  CLONE_PROJECT_FULFILLED,
  CREATE_UNSAVED_PROJECT,
  REMOVE_UNSAVED_PROJECT,
  ADD_PROJECT_METRIC,
  ADD_PROJECT_METRIC_FULFILLED,
  BULK_ADD_PROJECT_METRICS,
  BULK_ADD_PROJECT_METRICS_FULFILLED,
  BULK_REMOVE_PROJECT_METRICS,
  BULK_REMOVE_PROJECT_METRICS_FULFILLED,
  REMOVE_PROJECT_METRIC,
  REMOVE_PROJECT_METRIC_FULFILLED,
  CREATE_AND_ADD_METRIC_TO_PROJECT,
  CREATE_AND_ADD_METRIC_TO_PROJECT_FULFILLED,
  ADD_SELECTED_PARENT_TO_STORE,
  TRANSFER_PROJECT_TO_OTHER_ACCOUNT,
  TRANSFER_PROJECT_TO_OTHER_ACCOUNT_FULFILLED,
  UPDATE_PROJECT_PERSONAS_FULFILLED,
  UPDATE_PROJECT_LIFECYCLES,
  UPDATE_PROJECT_LIFECYCLES_FULFILLED,
} from '../types';
import { ADD_PROJECT_INTEGRATION_FULFILLED } from 'store/integrations';

import {
  processPayloadToStore,
  getCollectionNameFromProject,
  updateProjectOnCollectionsUsingMethod,
  processTasksToStore,
} from '../helpers/collections';

import addResultsToIds from '../helpers/addResultsToIds';

import { setProgress } from './utils';

import { bulkThunkInitialState, getThunksReducers, thunkInitialState } from 'utils/store/thunk';
import { LOCATION_CHANGE } from 'store/app/types';

const defaultSorting = [];
const ATTRIBUTES_TO_SKIP_OVERRIDE = [
  'tasks',
  'estimates',
  'integrations',
  'children',
  'files',
  'projectDependencies',
  'estimateDependencies',
  'watchers',
  'taskDependencies',
  'dependencies',
  'Jiras',
];

const initialState = {
  rows: new List(),
  initiatives: new List(),
  bets: new List(),
  rowsFilters: new List(),
  initiativesFilters: new List(),
  betsFilters: new List(),
  isLoaded: false,
  isFetching: true,
  isUpdating: false,
  lastCallsMeta: {},
  stories: {},
  unsaved: null,
  totalAllLayers: null,
  totalByLayer: {},
  operations: bulkThunkInitialState([
    ADD_PROJECT_METRIC,
    BULK_ADD_PROJECT_METRICS,
    BULK_REMOVE_PROJECT_METRICS,
    CREATE_AND_ADD_METRIC_TO_PROJECT,
    REMOVE_PROJECT_METRIC,
    UPDATE_PROJECT_PERSONAS,
    UPDATE_PROJECT_LIFECYCLES,
    'summarize_projects',
  ]),
};

function removeUnsavedProjects(list) {
  return list.filter(project => project.id);
}

const processProjectsAction = (state, projects, meta = {}) => {
  if (!projects) {
    return {
      ...state,
      isLoaded: true,
      isFetching: false,
    };
  }

  const newState = processPayloadToStore(projects, state, meta.storeAsFilters, meta.layers);

  let lastCallsMeta = cloneDeep(state.lastCallsMeta);

  if (meta.addToIds) {
    const layers = meta.layers || [];

    lastCallsMeta = [IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER].reduce((obj, layer) => {
      return {
        ...obj,
        [layer]: {
          ...obj[layer],
          [meta.storeAsFilters ? 'filters' : 'notFilters']: {
            ...(layers.includes(layer) ? { params: meta.params } : {}),
            ...(layers.includes(layer) ? { date: moment().valueOf() } : {}),
            ...(layers.includes(layer) ? { associations: meta.associations } : {}),
            ids: projects.filter(({ layer: entryLayer }) => +entryLayer === +layer).map(({ id }) => id),
          },
        },
      };
    }, state.lastCallsMeta);
  }

  return {
    ...state,
    ...newState,
    lastCallsMeta,
    isLoaded: true,
    isFetching: false,
    totalAllLayers: meta.totalAllLayers || null,
    totalByLayer: meta.totalByLayer || {},
  };
};

const updateProject = (state, payload) => {
  const project = payload.projectData || payload;
  const parentProject = payload.parentData;
  let currentState = state;

  /*
   * This is a temporary update to simplify the logic
   * Maybe we could create some util for this in the future to be usable in other places
   */
  const _includeProjectOnCollection = (_project, _state) => {
    const newCollectionNameForProject = getCollectionNameFromProject(_project, false);
    const newFiltersCollectionNameForProject = getCollectionNameFromProject(_project, true);

    const newCollectionsForState = updateProjectOnCollectionsUsingMethod(_state, (collection, collectionName) => {
      const index = collection.findIndex(obj => +obj.get('id') === +_project.id);

      if (index >= 0 && [newCollectionNameForProject, newFiltersCollectionNameForProject].includes(collectionName)) {
        return collection.update(index, p => p.merge(deserializeProject(_project)));
      } else if (index >= 0) {
        return collection.remove(index);
      } else if ([newCollectionNameForProject, newFiltersCollectionNameForProject].includes(collectionName)) {
        return collection.unshift(fromJS(deserializeProject(_project)));
      }

      return collection;
    });

    return {
      ..._state,
      ...newCollectionsForState,
    };
  };

  /*
   * Include the parent project on the store collection
   *
   * On update project parent sometimes the parent project does not exist on the store
   * to enrich the project with parent data
   */
  if (parentProject) {
    currentState = _includeProjectOnCollection(parentProject, currentState);
  }

  // update project on the correspondent collection
  currentState = _includeProjectOnCollection(project, currentState);

  // check if dependencies exists and on that case should update project data on dependencies
  if (currentState.dependencies && currentState.dependencies[+project.id]) {
    const newDependencies = { ...currentState.dependencies };

    newDependencies[+project.id] = project;
    return {
      ...currentState,
      dependencies: newDependencies,
      isUpdating: false,
    };
  }

  return {
    ...currentState,
    isUpdating: false,
  };
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_FILTERS: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case APPLY_FILTERS_REJECTED:
      return {
        ...state,
        isLoaded: true,
        isFetching: false,
      };
    case APPLY_FILTERS_PENDING:
      let { lastCallsMeta } = state;

      if ((action.meta || {}).addToIds) {
        lastCallsMeta = [IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER].reduce((obj, layer) => {
          return {
            [layer]: {
              [action.meta.storeAsFilters ? 'filters' : 'notFilters']: {
                ids: [],
              },
            },
          };
        }, state.lastCallsMeta);
      }
      return {
        ...state,
        lastCallsMeta,
        sorting: defaultSorting,
        isFetching: true,
        isLoaded: false,
        rows: new List(),
        initiatives: new List(),
        bets: new List(),
      };
    case FETCH_PROJECTS_CHILDREN_PENDING:
      return {
        ...state,
        sorting: defaultSorting,
        isFetching: true,
      };
    case FETCH_PROJECTS_CHILDREN_FULFILLED: {
      if (!action.payload) return { ...state, isFetching: false };

      // const newState = removeParentsChildren(state, action.meta.parentsIds, action.meta.layer || undefined);
      let lastCallsMeta = cloneDeep(state.lastCallsMeta);

      if ((action.meta || {}).addToIds) {
        lastCallsMeta = addResultsToIds(state, action.meta.storeAsFilters, action.payload);
      }

      return {
        ...state,
        lastCallsMeta,
        ...processPayloadToStore(action.payload, state),
        isFetching: false,
      };
    }
    case APPLY_FILTERS_FULFILLED: {
      const { payload: { tasks, parents, projects, projectsSearchMetadataResult } = {}, meta = {} } = action;

      const processedTasks = tasks ? { tasks: processTasksToStore(state.tasks, tasks) } : {};
      const processedTParents = parents ? processPayloadToStore(parents, state, false, [INITIATIVE_LAYER, BET_LAYER]) : {};

      const tempState = {
        ...state,
        ...processedTasks,
        ...processedTParents,
      };

      return {
        ...tempState,
        ...processProjectsAction(tempState, projects, {
          ...meta,
          totalAllLayers: projectsSearchMetadataResult?.total,
          totalByLayer: projectsSearchMetadataResult?.totalByLayer,
        }),
        /*
         * On apply filters if there is some unsaved project will clean it
         *
         * This is a workaround, because currently on the grid the option stopEditingWhenCellsLoseFocus
         * is breaking the autocomplete editors and this cause the pesistence of the unsaved project
         * when user moves to another page without save and could cause multiple issues in other pages
         */
        unsaved: null,
      };
    }
    case SET_PROJECTS_PARENTS: {
      if (!action.payload) return state;

      return {
        ...state,
        ...processPayloadToStore(action.payload, state, false, [INITIATIVE_LAYER, BET_LAYER]),
      };
    }
    case UPDATE_PROJECTS_FULFILLED:
      return {
        ...state,
        ...processPayloadToStore(action.payload.data, state),
      };
    case CREATE_PROJECT_PENDING:
    case UPDATE_PROJECT_PENDING: {
      if (!action.payload) return state;
      return {
        ...state,
        recentlyUsedData: { ...action.payload },
        isUpdating: action.type === UPDATE_PROJECT_PENDING,
      };
    }
    case ADD_PROJECT_METRIC_FULFILLED:
    case ADD_PROJECT_INTEGRATION_FULFILLED:
    case BULK_ADD_PROJECT_METRICS_FULFILLED:
    case BULK_REMOVE_PROJECT_METRICS_FULFILLED:
    case CREATE_AND_ADD_METRIC_TO_PROJECT_FULFILLED:
    case REMOVE_PROJECT_METRIC_FULFILLED:
    case UPDATE_PROJECT_LIFECYCLES_FULFILLED:
    case UPDATE_PROJECT_PERSONAS_FULFILLED:
    case UPDATE_PROJECT_FULFILLED: {
      if (action.meta?.batch) return state;
      return updateProject(state, action.payload);
    }
    case UPDATE_PROJECT_DEBOUNCED_FULFILLED: {
      if (Array.isArray(action.payload)) {
        return action.payload.reduce((currState, eachPayload) => {
          return updateProject(currState, eachPayload);
        }, state);
      }

      return updateProject(state, action);
    }
    case CLONE_PROJECT_FULFILLED:
    case ADD_SELECTED_PARENT_TO_STORE:
    case CREATE_PROJECT_FULFILLED: {
      const collectionName = getCollectionNameFromProject(action.payload);
      const collectionNameAsFilters = getCollectionNameFromProject(action.payload, true);
      let collection = state[collectionName].toJS();
      let collectionAsFilters = state[collectionNameAsFilters] ? state[collectionNameAsFilters].toJS() : null;

      collection = removeUnsavedProjects(collection);

      collection = collection.filter(proj => proj.id !== action.payload.id);

      collection.unshift(deserializeProject(action.payload));

      const projects = {
        [collectionName]: fromJS(collection),
      };

      if (collectionAsFilters) {
        collectionAsFilters = collectionAsFilters.filter(proj => proj.id !== action.payload.id);
        collectionAsFilters.unshift(deserializeProject(action.payload));
        projects[collectionNameAsFilters] = fromJS(collectionAsFilters);
      }

      const lastCallsMeta = addResultsToIds(state, false, [action.payload]);

      return {
        ...state,
        ...projects,
        lastCallsMeta,
        unsaved: null,
      };
    }
    case BULK_CREATE_PROJECTS_FULFILLED: {
      if (!action.payload || !(action.payload instanceof Array)) {
        return state;
      }

      const layers = {
        ...processPayloadToStore(action.payload, state, false),
        ...processPayloadToStore(action.payload, state, true),
      };

      const lastCallsMeta = addResultsToIds(state, false, action.payload);

      return {
        ...state,
        ...layers,
        lastCallsMeta,
      };
    }
    case BULK_UPDATE_PROJECTS_PENDING:
      return {
        ...state,
        bulkUpdatePending: true,
      };
    case BULK_UPDATE_PROJECTS_FULFILLED: {
      const propParent = prop('parent');

      const parents = uniqBy(prop('id'), action.payload.filter(propParent).map(propParent));

      const _bulkUpdateOnCollection = (collection, name) => {
        return action.payload.reduce((aggregator, currentProject) => {
          const index = aggregator.findIndex(obj => obj.get('id') === currentProject.id);

          if (index >= 0) {
            /**
             * This `omit` should have been done on the backend, I think, but we have no guarantee that the endpoint
             * is not being used for other purposes (it supports an Array AND an Object, so we can only assume so)
             * For this reason, it is safer to do this here.
             */
            const deserializedProject = pipe(deserializeProject, omit(ATTRIBUTES_TO_SKIP_OVERRIDE))(currentProject);
            const jsAggregator = aggregator.get(index).toJS();
            // using `mergeAll` to merge the updated project data with the existing project data in store
            const mergedProjectData = mergeAll([jsAggregator, deserializedProject]);

            return aggregator.set(index, fromJS(mergedProjectData));
          }

          return aggregator;
        }, collection);
      };

      const newCollections = updateProjectOnCollectionsUsingMethod(state, _bulkUpdateOnCollection);

      const stateWithUpdatedProjects = {
        ...state,
        ...processPayloadToStore(parents, newCollections),
        lastActionIds: action.payload.map(obj => obj.id),
        bulkUpdatePending: false,
      };

      return stateWithUpdatedProjects;
    }
    case UNDO_BULK_UPDATE_PROJECTS_FULFILLED: {
      const _undoBulkUpdateProjects = collection => {
        return updateRows(collection, action.payload, deserializeProject);
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _undoBulkUpdateProjects),
        lastActionIds: null,
      };
    }
    case DELETE_PROJECTS_PENDING: {
      return {
        ...state,
        bulkDeletePending: true,
      };
    }
    case DELETE_PROJECTS_FULFILLED: {
      const _deleteFromCollection = collection => {
        return action.payload.reduce((curr, id) => {
          const index = curr.findIndex(obj => +obj.get('id') === +id);

          if (index > -1) return curr.delete(index);

          return curr;
        }, collection);
      };

      const newCollections = updateProjectOnCollectionsUsingMethod(state, _deleteFromCollection);

      return {
        ...state,
        ...newCollections,
        lastActionIds: action.payload,
        bulkDeletePending: false,
      };
    }
    case UNDO_DELETE_PROJECTS_FULFILLED: {
      const rows = updateRows(state.rows, action.payload, deserializeProject);

      return {
        ...state,
        rows,
        lastActionIds: null,
      };
    }
    case UPDATE_PROJECTS_REJECTED:
    case CREATE_PROJECT_REJECTED:
    case UPDATE_PROJECT_REJECTED:
    case DELETE_PROJECTS_REJECTED:
    case BULK_UPDATE_PROJECTS_REJECTED:
      return {
        ...state,
        bulkDeletePending: false,
        bulkUpdatePending: false,
        isUpdating: false,
      };
    case UPDATE_PROJECT_ROW_ORDER_PENDING:
      return {
        ...state,
        isUpdating: true,
      };
    case UPDATE_PROJECT_ROW_ORDER_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        console.error('UPDATE_PROJECT_ROW_ORDER_FULFILLED::Project passed does not have id');
        return state;
      }

      const _updateProjectRowOrderOnCollection = (_action, collection) => {
        if (!collection) return null;
        const projectIndex = collection.findIndex(row => row.get('id') === _action.payload.id);

        const project = fromJS(deserializeProject(_action.payload));

        if (projectIndex >= 0) {
          if (action.mustUpdateEntireProject) {
            collection = upsertImmutableListItem(project, collection);
          } else {
            collection = collection.setIn([projectIndex, 'rank'], _action.payload.rank);
          }
        } else {
          collection = collection.unshift(project);
        }

        return collection;
      };

      const collectionName = getCollectionNameFromProject(action.payload);
      const collectionNameForFilters = getCollectionNameFromProject(action.payload, true);
      const collection = state[collectionName];
      const collectionForFilters = state[collectionNameForFilters];

      const newState = {
        ...state,
        isUpdating: false,
      };

      if (collection) {
        newState[collectionName] = _updateProjectRowOrderOnCollection(action, collection);
      }

      if (collectionForFilters) {
        newState[collectionNameForFilters] = _updateProjectRowOrderOnCollection(action, collectionForFilters);
      }

      return newState;
    }
    case FETCH_PROJECT_CUSTOMER_REQUESTS_FULFILLED: {
      if (!action.payload) return state;
      const ids = action.payload.map(({ id }) => id);

      const { projectId } = action.meta;

      const newCollections = updateProjectOnCollectionsUsingMethod(state, collection => {
        const index = collection.findIndex(obj => +obj.get('id') === +projectId);

        if (index >= 0) {
          const project = collection.get(index).set('customer_requests_ids', ids);

          return collection.set(index, project);
        }

        return collection;
      });

      return {
        ...state,
        ...newCollections,
      };
    }
    case SET_TASKS: {
      return {
        ...state,
        tasks: [...(state.tasks || []).filter(t => !action.payload.some(({ id }) => id === t.id)), ...action.payload],
      };
    }
    case GET_PROJECT_INTEGRATION_PROGRESS_FULFILLED: {
      if (!action.payload || !action.payload.data) return state;

      const { data } = action.payload;

      const mappedPayload = [
        {
          end_date_calculated: data.endDate,
          start_date_calculated: data.startDate,
          progress_calculated: data.progress,
          id: data.projectId,
          progress_type: 'auto',
          jira_progress: true,
        },
      ];

      return setProgress(state, mappedPayload);
    }
    case REALTIME_BULK_UPDATE_PROGRESS: {
      if (!action.payload) return state;

      return setProgress(state, action.payload);
    }
    case GET_PROJECT_COUNTERS_BY_PHASE_FULFILLED: {
      return {
        ...state,
        projectCountersByPhase: action.payload,
      };
    }
    case MERGE_PROJECTS_FULFILLED: {
      const _deleteFromCollection = collection => {
        return action.payload.reduce((curr, id) => {
          const index = curr.findIndex(obj => +obj.get('id') === +id);

          if (index > -1) return curr.delete(index);

          return curr;
        }, collection);
      };

      const newCollections = updateProjectOnCollectionsUsingMethod(state, _deleteFromCollection);

      return {
        ...state,
        ...newCollections,
      };
    }

    case CREATE_UNSAVED_PROJECT: {
      return {
        ...state,
        unsaved: deserializeProject(action.payload),
      };
    }

    case REMOVE_UNSAVED_PROJECT: {
      return {
        ...state,
        unsaved: null,
      };
    }

    case TRANSFER_PROJECT_TO_OTHER_ACCOUNT_FULFILLED: {
      // Remove transfered project from stored lists
      const _deleteFromCollection = collection => {
        const index = collection.findIndex(obj => +obj.get('id') === +action.payload.id);

        if (index > -1) return collection.delete(index);

        return collection;
      };

      const newCollections = updateProjectOnCollectionsUsingMethod(state, _deleteFromCollection);

      return {
        ...state,
        ...newCollections,
      };
    }
    case LOCATION_CHANGE:
      return {
        ...state,
        operations: {
          ...(state.operations || {}),
          applyFilters: thunkInitialState,
        },
      };
    default: {
      return state;
    }
  }
};

export { initialState };

const operationsReducer = getThunksReducers([
  ADD_PROJECT_METRIC,
  BULK_ADD_PROJECT_METRICS,
  BULK_REMOVE_PROJECT_METRICS,
  CREATE_AND_ADD_METRIC_TO_PROJECT,
  REMOVE_PROJECT_METRIC,
  TRANSFER_PROJECT_TO_OTHER_ACCOUNT,
  UPDATE_PROJECT_PERSONAS,
  UPDATE_PROJECT_LIFECYCLES,
]);

const reducer = reduceReducers(
  initialState,
  projectsReducer,
  projectTasksReducer,
  projectEstimatesReducer,
  projectIntegrationsReducer,
  dependenciesProjectsReducer,
  ...operationsReducer,
);

export default reducer;
