import axios from 'axios';

import {
  FETCH_PHASES,
  UPDATE_PHASE,
  CREATE_PHASE,
  REMOVE_UNSAVED_PHASES,
  ADD_PHASE_WITHOUT_SAVE,
  CREATE_PHASE_FULFILLED,
  UPDATE_PHASE_FULFILLED,
  DELETE_PHASE_FULFILLED,
  BULK_DELETE_PHASES_FULFILLED,
  BULK_DELETE_PHASE_ROADMAP,
  CREATE_PHASE_ROADMAP,
  DELETE_PHASE_ROADMAP,
} from './types';
import fetchMetadataAction from '../utils/factory/fetchMetadataAction';

import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';

export const fetchPhases = fetchMetadataAction(
  FETCH_PHASES,
  '/api/phases',
  state => state.phases.phases,
  state => state.phases.lastCallsDate,
);

export const updatePhase = phase => {
  return {
    payload: axios.post(`/api/phases/update/${phase.id}`, phase),
    type: UPDATE_PHASE,
  };
};

export const createPhase = phase => {
  return {
    payload: axios.post('/api/phases/', phase).then(response => response.data),
    type: CREATE_PHASE,
  };
};

export const removeUnsavedPhases = () => ({
  type: REMOVE_UNSAVED_PHASES,
});

export const addPhaseWithoutSave = phase => ({
  type: ADD_PHASE_WITHOUT_SAVE,
  phase,
});

export const createPhaseRoadmap = (phaseId, phaseRoadmap) => {
  return {
    payload: axios.post(`/api/phases/${phaseId}/phaseRoadmaps/`, phaseRoadmap).then(response => response.data),
    type: CREATE_PHASE_ROADMAP,
  };
};

export const deletePhaseRoadmap = ({ id, roadmapId, subRoadmapId = null, product2Id = null }) => {
  let route = `/api/phases/${id}/phaseRoadmaps/${roadmapId}`;

  route += subRoadmapId ? `/${subRoadmapId}` : '';
  route += subRoadmapId && product2Id ? `/${product2Id}` : '';

  return {
    payload: axios.delete(route).then(response => response.data),
    type: DELETE_PHASE_ROADMAP,
  };
};

export const bulkDeletePhaseRoadmaps = phaseId => {
  return {
    payload: axios.delete(`/api/phases/${phaseId}/phaseRoadmaps/`).then(response => response.data),
    type: BULK_DELETE_PHASE_ROADMAP,
  };
};

export const gotPhasesRealtimeUpdate = generateRealtimeUpdateAction(
  CREATE_PHASE_FULFILLED,
  DELETE_PHASE_FULFILLED,
  UPDATE_PHASE_FULFILLED,
  BULK_DELETE_PHASES_FULFILLED,
);

export const phaseActions = {
  fetchPhases,
  updatePhase,
  createPhase,
};
