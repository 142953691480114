import { propOr, __ } from 'ramda';

const propOrFalse = propOr(false, __);

const getLoginOptionsVisibility = organization => {
  const hasAzureADConfigured = propOrFalse('has_azure_ad_configured', organization);
  const hasAzureAd = propOrFalse('has_azure_ad', organization);
  const useAzure = hasAzureADConfigured && hasAzureAd;

  const hasOktaConfigured = propOrFalse('has_okta_configured', organization);
  const hasOkta = propOrFalse('has_okta', organization);
  const useOkta = hasOktaConfigured && hasOkta;

  const usingSSO = useOkta || useAzure;
  
  const useEmailPassword = !usingSSO;

  const hasGoogleLoginIfSSOIntegrations = propOrFalse('has_google_login_if_sso_integrations', organization);
  const useGoogle = hasGoogleLoginIfSSOIntegrations || !usingSSO;

  return {
    useAzure,
    useEmailPassword,
    useGoogle,
    useOkta,
  };
};

export default getLoginOptionsVisibility;
