import { pickUserFields } from 'features/Canvas/helpers/fieldPickers';

// Returns the users that are owners of any entity of the canvas. Only sends to canvas id, first and las name
const getOwnerUsersById = (users = [], entities = []) => {
  return users.reduce((acc, user) => {
    const entityHasUser = entities.some(entity => entity?.ownerId === user?.id || entity?.owner_id === user?.id);

    if (entityHasUser) {
      return {
        ...acc,
        [user.id]: pickUserFields(user),
      };
    }
    return acc;
  }, {});
};

export default getOwnerUsersById;
