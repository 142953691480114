import { UPDATE_PROGRESS_PAGE_STATE } from './types';

export const updateState = state => {
  return {
    payload: state,
    type: UPDATE_PROGRESS_PAGE_STATE,
  };
};

export const setGroupByLevel1 = value => {
  return {
    payload: { selectedGroupByLvl1: value },
    type: UPDATE_PROGRESS_PAGE_STATE,
  };
};

export const setGroupByLevel2 = value => {
  return {
    payload: { selectedGroupByLvl2: value },
    type: UPDATE_PROGRESS_PAGE_STATE,
  };
};

export const setTableLevel = value => {
  return {
    payload: { tableSelectedLevel: value },
    type: UPDATE_PROGRESS_PAGE_STATE,
  };
};

export const setLevel1ClickedId = id => {
  return {
    payload: { level1ClickedId: id },
    type: UPDATE_PROGRESS_PAGE_STATE,
  };
};

export const setLevel2ClickedId = id => {
  return {
    payload: { level2ClickedId: id },
    type: UPDATE_PROGRESS_PAGE_STATE,
  };
};

export const setProjectActiveId = id => {
  return {
    payload: { projectActiveId: id },
    type: UPDATE_PROGRESS_PAGE_STATE,
  };
};

export const setStartDate = date => {
  return {
    payload: { startDate: date },
    type: UPDATE_PROGRESS_PAGE_STATE,
    meta: { makesActiveViewDirty: true },
  };
};

export const setEndDate = date => {
  return {
    payload: { endDate: date },
    type: UPDATE_PROGRESS_PAGE_STATE,
    meta: { makesActiveViewDirty: true },
  };
};

export const setShowTargetAndPredictedLines = value => {
  return {
    payload: { showTargetAndPredictedLines: value },
    type: UPDATE_PROGRESS_PAGE_STATE,
    meta: { makesActiveViewDirty: true },
  };
};
