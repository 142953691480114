import React, { Component } from 'react';

export default (LABELS, mappers = {}) =>
  (class AgGridGroupRowInnerRenderer extends Component {
    constructor(props) {
      super(props);

      this.props.reactContainer.style.display = 'inline-block';

      this.initializeDefaultObject();

      this.add = this.add.bind(this);
    }

    /**
     * Search groups above current group and set default object with properties to have a new row inserted into that group
     */
    initializeDefaultObject() {
      this.defaultObject = {};

      let actualNode = this.props.node;

      while (actualNode) {
        this.defaultObject[actualNode.field] = actualNode.key;

        actualNode = actualNode.parent;

        if (actualNode.level < 0) {
          break;
        }
      }
    }

    add() {
      try {
        this.props.node.setExpanded(true);
      } catch {
        // pass
      }
      this.props.add(this.defaultObject);
    }

    render() {
      const { node } = this.props;

      const value = mappers[node.field] ? mappers[node.field](node.key) : node.key;

      return (
        <span className="group-row-inner-renderer">
          {/* <Button color="secondary" onClick={this.add}><Add /></Button>  */}
          <span className="label">
            {LABELS && LABELS[node.field] ? `${LABELS[node.field]}: ` : ''}
            {value ? value.trim() : 'Undefined'}
          </span>
        </span>
      );
    }
  });
