import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useSystemFields from 'hooks/useSystemFields';

import {
  selectGoalsCanvasEdgesLayout,
  selectGoalsCanvasLayoutConfig,
  selectGoalsCanvasNodesLayout,
  selectGoalsCanvasUserActionsMeta,
} from '../store/selectors';
import { updateEdgesLayout, updateNodesLayout } from '../store';
import useGoalsCanvasHandleConfigChanges from './useGoalsCanvasHandleConfigChanges';
import useGoalsCanvasData from 'features/GoalsCanvas/hooks/useGoalsCanvasData';

const useGoalsCanvas = () => {
  const dispatch = useDispatch();

  const [getSystemFieldName] = useSystemFields();

  const canvasLayoutConfig = useSelector(selectGoalsCanvasLayoutConfig);
  const canvasNodesLayout = useSelector(selectGoalsCanvasNodesLayout);
  const canvasEdgesLayout = useSelector(selectGoalsCanvasEdgesLayout);
  const userActionsMeta = useSelector(selectGoalsCanvasUserActionsMeta);
  const nodeTypeLabels = useMemo(
    () => ({
      objective: getSystemFieldName('objective'),
      keyResult1: getSystemFieldName('keyResult1'),
      keyResult2: getSystemFieldName('keyResult2'),
      metric: getSystemFieldName('metric'),
    }),
    [],
  );

  const {
    onToggleOkrRelations,
    onToggleOkrMetricsRelations,
    onToggleBoardRelations,
    onToggleMetricRelations,
    onToggleHighlightHealth,
    onToggleOwnerAvatar,
    onToggleProgress,
    onToggleStatus,
    handleDisplayNodesChange,
  } = useGoalsCanvasHandleConfigChanges({ canvasLayoutConfig });

  const { dataForCanvas } = useGoalsCanvasData();

  const onNodesLayoutUpdate = nodesLayout => dispatch(updateNodesLayout(nodesLayout));
  const onEdgesLayoutUpdate = edgesLayout => dispatch(updateEdgesLayout(edgesLayout));

  return {
    dataForCanvas,
    canvasLayoutConfig,
    canvasNodesLayout,
    canvasEdgesLayout,
    userActionsMeta,
    nodeTypeLabels,
    onNodesLayoutUpdate,
    onEdgesLayoutUpdate,
    onToggleOkrRelations,
    onToggleOkrMetricsRelations,
    onToggleBoardRelations,
    onToggleMetricRelations,
    onToggleHighlightHealth,
    onToggleOwnerAvatar,
    onToggleProgress,
    onToggleStatus,
    handleDisplayNodesChange,
  };
};

export default useGoalsCanvas;
