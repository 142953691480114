import { useCallback, useState, useRef, useEffect } from 'react';
import {
  ADMIN_USER,
  EDITOR_USER,
  LEADER_USER,
  MANAGER_USER,
  OWNER_USER,
  READ_ONLY_USER,
  REQUESTOR_USER,
  RESOURCE_USER,
  COLLABORATOR_USER,
} from '@dragonboat/permissions';

// TODO: FEATURE PERMISSION
const disallowedRolesForBulkDelete = [ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER];

const rolesTosCheckAccessRequest = [
  +READ_ONLY_USER,
  +ADMIN_USER,
  +MANAGER_USER,
  +EDITOR_USER,
  +LEADER_USER,
  +REQUESTOR_USER,
  +COLLABORATOR_USER,
];

const newRoleNeedsToRequestAccess = (newRoleId, oldRoleId) => {
  return rolesTosCheckAccessRequest.includes(+newRoleId) && +oldRoleId === +RESOURCE_USER;
};

const useUsersSettings = ({
  users,
  selectedItems,
  selectionMode,
  enableSelectionMode,
  disableSelectionMode,

  showMergeLightbox,
  setUsersBulkDeleteDialogIsOpen,

  addUserWithoutSave,
  updateUserById,
  updateUsers,
  setItemToDelete,
  deleteItem,
  bulkDeleteItems,

  replaceCellContent,
}) => {
  const [usersBulkUpdateDialogIsOpen, setUsersBulkUpdateDialogIsOpen] = useState(false);

  const [usersImportDialogIsOpen, setUsersImportDialogIsOpen] = useState(false);

  const [userDeleteDialogIsOpen, setUserDeleteDialogIsOpen] = useState(false);

  const [userAccessEmailDialogIsVisible, setUserAccessEmailDialogIsVisible] = useState(false);

  const [userAccessData, setUserAccessData] = useState(null);

  const usersRef = useRef(users);

  useEffect(() => {
    usersRef.current = users;
  }, [users]);

  const handleBulkDeleteUsersClick = useCallback(
    () => (!selectionMode ? enableSelectionMode() : setUsersBulkDeleteDialogIsOpen(true)),
    [selectionMode, enableSelectionMode, setUsersBulkDeleteDialogIsOpen],
  );

  const handleBulkUpdateUsersClick = useCallback(() => {
    setUsersBulkUpdateDialogIsOpen(true);
  }, []);

  const handleMergeUsersClick = useCallback(
    () => (!selectionMode ? enableSelectionMode() : showMergeLightbox()),
    [selectionMode, enableSelectionMode, showMergeLightbox],
  );

  const handleAddNewUserClick = useCallback(() => {
    addUserWithoutSave({ first_name: '' });
  }, [addUserWithoutSave]);

  const openImportLightbox = useCallback(() => {
    setUsersImportDialogIsOpen(true);
  }, []);

  const handleSaveUserAccessEmail = useCallback(
    email => {
      return updateUserById(userAccessData.id, {
        email,
        role_id: userAccessData.roleId,
      });
    },
    [updateUserById, userAccessData],
  );

  const handleCancelUserAccessEmail = useCallback(() => {
    setUserAccessEmailDialogIsVisible(false);

    replaceCellContent(userAccessData.id, 'role_id', userAccessData.prevRoleId);
  }, [replaceCellContent, userAccessData]);

  const showUserAccessEmailLightbox = useCallback((user, roleId) => {
    setUserAccessEmailDialogIsVisible(true);

    setUserAccessData({
      id: user.id,
      name: user.first_name,
      email: user.email,
      roleId,
      prevRoleId: user.role_id,
    });
  }, []);

  const hideUserAccessEmailDialog = useCallback(() => {
    return setUserAccessEmailDialogIsVisible(false);
  }, []);

  const handleUserDeleteClick = useCallback(data => {
    setItemToDelete(data);

    setUserDeleteDialogIsOpen(data);
  }, []);

  const handleUserDeleteConfirm = useCallback(() => {
    setUserDeleteDialogIsOpen(false);

    deleteItem();
  }, [deleteItem]);

  const handleUserDeleteCancel = useCallback(() => {
    setUserDeleteDialogIsOpen(false);
  }, []);

  const handleUsersBulkDeleteCancel = useCallback(() => {
    setUsersBulkDeleteDialogIsOpen(false);
  }, [setUsersBulkDeleteDialogIsOpen]);

  const handleUsersImportCancel = useCallback(() => {
    setUsersImportDialogIsOpen(false);
  }, []);

  const handleUsersBulkUpdateCancel = useCallback(() => {
    setUsersBulkUpdateDialogIsOpen(false);
  }, []);

  const handleUsersBulkUpdateConfirm = useCallback(
    async users => {
      await updateUsers(users);

      setUsersBulkUpdateDialogIsOpen(false);

      disableSelectionMode();
    },
    [updateUsers, disableSelectionMode],
  );

  const updateUser = useCallback(
    async (id, update, oldValue) => {
      const user = usersRef.current.find(user => user.id === id);

      if (!user) {
        return;
      }

      if (newRoleNeedsToRequestAccess(+update.role_id, oldValue)) {
        return showUserAccessEmailLightbox(
          {
            ...user,
            role_id: oldValue,
          },
          update.role_id,
        );
      }

      return updateUserById(id, update);
    },
    [usersRef.current, showUserAccessEmailLightbox, updateUserById],
  );

  const bulkDelete = useCallback(() => {
    // The user only has the ability to delete No Access users
    const onlyNoAccessUsers = selectedItems.filter(u => !disallowedRolesForBulkDelete.includes(u.role_id));

    return bulkDeleteItems(onlyNoAccessUsers.map(i => i.id));
  }, [selectedItems, bulkDeleteItems]);

  return {
    usersBulkUpdateDialogIsOpen,
    usersImportDialogIsOpen,
    userDeleteDialogIsOpen,
    userAccessEmailDialogIsVisible,

    userAccessData,

    handleBulkDeleteUsersClick,
    handleBulkUpdateUsersClick,
    handleMergeUsersClick,
    handleAddNewUserClick,
    handleUserDeleteClick,
    openImportLightbox,
    handleSaveUserAccessEmail,
    handleCancelUserAccessEmail,
    hideUserAccessEmailDialog,
    handleUserDeleteConfirm,
    handleUserDeleteCancel,
    handleUsersBulkDeleteCancel,
    handleUsersImportCancel,
    handleUsersBulkUpdateCancel,
    handleUsersBulkUpdateConfirm,

    updateUser,
    bulkDelete,
  };
};

export default useUsersSettings;
