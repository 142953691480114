import { defaultTo, equals, not, pick, values } from 'ramda';

const defaultToEmptyObject = defaultTo({});

export default function filterDuplicateProjectValuesByFieldIds(
  projectsData = [],
  selectedRoadmapVersionIds = [],
  roadmapVersionsProjects = {},
  fieldIds = [],
  comparisonByField = {},
) {
  const versionData = values(pick(selectedRoadmapVersionIds, roadmapVersionsProjects));

  if (!versionData.length) {
    return projectsData;
  }

  return projectsData.filter(project => {
    const projectValuesToCompare = pick(['id', ...fieldIds], project);

    const atLeastOneVersionValueHasDifference = versionData.some(values => {
      const versionProject = defaultToEmptyObject(values.find(v => v.id === project.id));

      const versionValuesToCompare = pick(['id', ...fieldIds], versionProject);

      const fieldsWithDifferentValues = fieldIds.filter(field => {
        const fieldEval = comparisonByField[field] || equals;

        return not(fieldEval(projectValuesToCompare[field], versionValuesToCompare[field]));
      });

      return Boolean(fieldsWithDifferentValues.length);
    });

    return atLeastOneVersionValueHasDifference;
  });
}
