import { defaultTo } from 'ramda';
import { matchPath } from 'react-router-dom';
import { READ_ONLY_USER } from '@dragonboat/permissions';
import isFunction from 'lodash/isFunction';

import { getReadOnlyRoutes } from 'config';
import { IDEAS_OVERVIEW_PAGE } from 'constants/filters';
import { PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';

const defaultToTrue = defaultTo(true);
const defaultToFalse = defaultTo(false);

export default ({ user, systemFields, organization, publicSharedViewData, canView }, ROUTES) => {
  const canViewMissionControlHome = canView?.(PERMISSION_PAGES.missionControlHome);

  const filterRoutes = routes => {
    const hiddenRoutes = routes.filter(r => isFunction(r.visible) && !r.visible(user.role_id, organization)).map(r => r.path);

    return routes.filter(r => !hiddenRoutes.includes(r.path));
  };

  const routes = ROUTES(systemFields, user, canView);

  const readOnlyRoutes = getReadOnlyRoutes(canViewMissionControlHome);

  // block read only users access based on organization configuration
  if (user.role_id === READ_ONLY_USER && organization.read_only_users_access && !user.is_anonymous) {
    const hasRequestsAccess = !!organization.read_only_users_access.requests;
    // Sérgio - I am inserting by hard code these two paths to read only users permitions to avoid a migration
    // Ideally we should create a migration and add this to read_only_users_access field in organizations
    const readOnlyUsersAccess = {
      ...organization.read_only_users_access,
      'ideas/summary': !!organization.read_only_users_access['dashboard/summary'],
      'ideas/timeline': !!organization.read_only_users_access['dashboard/timeline'],
      'ideas/personas': !!organization.read_only_users_access['dashboard/personas'],
      'dashboard/forecast-timeline': !!organization.read_only_users_access['forecast/timeline'],
      home: true,
      'dashboard/list': !!organization.read_only_users_access['forecast/list'],
      'dashboard/home': true,
      'user/preferences': true,
      'dashboard/dashboards/:dashboardId': true,
      'requests/detail': !!organization.read_only_users_access['requests/detail'] || hasRequestsAccess,
      'requests/list': !!organization.read_only_users_access['requests/list'] || hasRequestsAccess,
      'requests/insights': !!organization.read_only_users_access['requests/insights'] || hasRequestsAccess,
      'dashboard/goals-grid': true,
      'dashboard/ideas-pdlc': true,
      'dashboard/grid-flat': true,
      'dashboard/goals-summary': defaultToTrue(organization.read_only_users_access['dashboard/summary']),
      'dashboard/goals-snapshot': defaultToTrue(organization.read_only_users_access['dashboard/snapshot']),
      'dashboard/goals-allocation': defaultToFalse(organization.read_only_users_access['dashboard/allocation-report']),
      'dashboard/overview': canView(PERMISSION_PAGES[IDEAS_OVERVIEW_PAGE]),
      'dashboard/canvas': defaultToTrue(organization.read_only_users_access['dashboard/canvas']),
      'dashboard/goals-canvas': defaultToTrue(organization.read_only_users_access['dashboard/canvas']),
      'dashboard/metrics-canvas': defaultToTrue(organization.read_only_users_access['dashboard/canvas']),
      'dashboard/metrics-chart': defaultToTrue(organization.read_only_users_access['metrics/chart']),
      'dashboard/metrics-grid': defaultToTrue(organization.read_only_users_access['metrics/grid']),
      'not-found': true,
    };

    const readOnlyKeys = Object.keys(readOnlyUsersAccess);

    readOnlyKeys.forEach(key => {
      const [parent, child] = key.split('/');

      const match = readOnlyRoutes.find(route => parent.includes(route));

      if (match) {
        const newKey = `dashboard/${child}`;

        if (!readOnlyKeys.includes(newKey)) {
          readOnlyKeys.push(newKey);
          readOnlyUsersAccess[newKey] = readOnlyUsersAccess[key];
        }

        readOnlyUsersAccess[key] = false;
      }
    });

    routes.forEach(menuEntry => {
      const originalVisibleFilter = menuEntry.visible;

      menuEntry.visible = (userType, org) => {
        if (typeof originalVisibleFilter !== 'function') {
          return readOnlyUsersAccess[menuEntry.path];
        }

        return originalVisibleFilter(userType, org) && readOnlyUsersAccess[menuEntry.path];
      };

      menuEntry.hideMenu = menuEntry.hideMenu || !readOnlyUsersAccess[menuEntry.path];
      const { path } = menuEntry;

      if (menuEntry.subMenu) {
        menuEntry.subMenu.forEach(subMenuEntry => {
          subMenuEntry.visible = () => readOnlyUsersAccess[subMenuEntry.path];
          subMenuEntry.hideMenu = !readOnlyUsersAccess[subMenuEntry.path];
          subMenuEntry.path = readOnlyRoutes.includes(path) ? subMenuEntry.path.replace(path, 'dashboard') : subMenuEntry.path;
        });
      }
    });
  } else if (user.is_anonymous && publicSharedViewData.loadedView.id) {
    const anonymousUserView = publicSharedViewData.loadedView;

    const viewPathMatchesRoute = routeEntry => matchPath(anonymousUserView.path, routeEntry)?.isExact;

    routes.forEach(menuEntry => {
      menuEntry.visible = () => {
        const subMenu = menuEntry.subMenu || [];

        return viewPathMatchesRoute(menuEntry) || subMenu.find(entry => viewPathMatchesRoute(entry));
      };
    });
  }

  const pages = filterRoutes(routes).map(p => ({
    ...p,
    subMenu: p.subMenu && filterRoutes(p.subMenu),
  }));

  // sort read_only user dashboard menu entries
  if (user.role_id === READ_ONLY_USER && !user.is_anonymous) {
    const dashboardEntry = pages.find(page => page.path === 'dashboard');

    if (dashboardEntry) {
      const order = ['dashboard', 'dashboard/home'];

      dashboardEntry.subMenu = dashboardEntry.subMenu.sort((a, b) => order.indexOf(a.path) - order.indexOf(b.path));
    }
  }

  return pages;
};
