import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('IMPORT_METRIC_VALUES'),
};

export const {
  IMPORT_METRIC_VALUES,
  IMPORT_METRIC_VALUES_PENDING,
  IMPORT_METRIC_VALUES_FULFILLED,
  IMPORT_METRIC_VALUES_REJECTED,
} = actionTypes;
