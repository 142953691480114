import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { not } from 'ramda';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InfoOutlinedIcon from '@material-ui/icons/HelpOutline';

import Dialog from 'design-system/molecules/Dialog/index';
import InlineIcon from 'design-system/atoms/InlineIcon/index';


import {
  getJiraPermissionsData as getJiraPermissionsDataSelector,
  isLoadingGetJiraPermissions as isLoadingGetJiraPermissionsSelector,
} from 'store/integrations/selectors';

import {
  getJiraPermissions as getJiraPermissionsAction,
  resetGetJiraPermissions as resetGetJiraPermissionsAction,
} from 'store/integrations';

const IntegrationPermissionsDialog = ({
  isDialogVisible,
  integrationType,
  messageActionName,
  onCancel,
  onConfirm,
  orgIntegrationId,
  onClose,
  confirmationMessage = null,
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingGetJiraPermissionsSelector);
  const currentPermissions = useSelector(getJiraPermissionsDataSelector);

  const handleOnConfirm = useCallback(() => {
    onConfirm();
    onClose();
  }, [onConfirm, onClose]);

  useEffect(() => {
    if (isDialogVisible) {
      dispatch(getJiraPermissionsAction(orgIntegrationId));
    } else {
      dispatch(resetGetJiraPermissionsAction());
    }
  }, [isDialogVisible]);

  const isAdmin = !!currentPermissions?.data?.isAdmin;
  const adminConfirmationMessage = confirmationMessage || `Are you sure you want to ${messageActionName}?`;
  const regularUserMessage = `You do not have the required permissions in ${integrationType}
    to ${messageActionName}. Please contact your ${integrationType} admin to make the change.`;

  return (
    <Dialog open={isDialogVisible}>
      {isLoading && <div>{`Checking your permission with ${integrationType}...`}</div>}
      {not(isLoading) && (
        <>
          <div>
            {isAdmin ? adminConfirmationMessage : regularUserMessage}
            <InlineIcon>
              <InfoIcon id="help_webhook" />
            </InlineIcon>
          </div>
          <Grid container justify="flex-end" direction="row" style={{ marginTop: '15px' }}>
            {isAdmin && (
              <Grid item>
                <Button color="primary" className="final-confirm-button" onClick={handleOnConfirm}>
                  Confirm
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button className="close-button" onClick={onCancel}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Dialog>
  );
};

const InfoIcon = styled(InfoOutlinedIcon)`
  font-size: 16px !important;
  margin-left: 5px;
`;

export default IntegrationPermissionsDialog;
