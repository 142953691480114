/* eslint-disable no-nested-ternary */
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { not } from 'ramda';

import UserViewListItem from 'containers/UserViewListItem';
import UpdateViewDialog from 'containers/ViewDialog/UpdateViewDialog';
import EmptyViewsList from 'components/EmptyViewsList';
import DragonMenu from 'design-system/atoms/DragonMenu/index';

import TextDeprecated from 'design-system/atoms/TextDeprecated';
import { TOTAL_VIEWS_UNIT, VIEW_ITEM_VARIANTS } from 'constants/userViews';
import { UPDATE_VIEW_OPTIONS, sortViewsByField } from './helpers';
import history from 'store/utils/history';
import UserViewListHeader from 'containers/UserViewListItem/components/UserViewListHeader';

import useTableSort, { DESCENDING } from 'hooks/useTableSort';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { DASHBOARDS_HOME_PATH } from 'constants/filters/pages';

import { LIST_HEADERS } from './constants';

export default ({
  variant,
  viewsList,
  hasNoViews,
  searchHasNoResults,
  handleFavoriteView,
  handleShareView,
  handleSaveView,
  handleSaveViewAndShare,
  handleViewOptions,
  handleSelectView,
  handleDeleteView,
  viewOptionsAnchorEl,
  setViewOptionsAnchorEl,
  selectedView,
  onSelectViewOption,
  shareUrl,
  saveDialogViews,
  emptyText,
  emptyButtonText,
  onClickEmptyButton,
  closeViewsDialog,
  dialogProps,
  closeDialog,
}) => {
  const [totalViewsUnit, setTotalViewsUnit] = useState(TOTAL_VIEWS_UNIT.MONTH);

  const { sortBy, sortDirection, handleSort } = useTableSort(LIST_HEADERS.VISITS_COUNT.key, DESCENDING);

  const { canView } = usePermissions();

  const canViewUserViewCounts = canView(PERMISSION_FEATURES.userViewCounts);
  const shouldShowUserViewCounts = canViewUserViewCounts && variant !== VIEW_ITEM_VARIANTS.widgetList;
  const shouldShowHeader = variant !== VIEW_ITEM_VARIANTS.widgetList;

  const checkViewIsFavorite = view => {
    if (not(view)) {
      return false;
    }

    return view.favorites?.length > 0;
  };

  const navigateToDashboardHome = () => {
    history.push(DASHBOARDS_HOME_PATH);
    closeViewsDialog && closeViewsDialog();
  };

  const sortedViewsList = useMemo(() => {
    if (!sortBy || !sortDirection) return viewsList;

    return sortViewsByField(viewsList, sortBy, sortDirection);
  }, [viewsList, sortBy, sortDirection]);

  return (
    <>
      {hasNoViews ? (
        <StyledEmptyViewsList
          variant={variant}
          text={emptyText}
          buttonText={emptyButtonText}
          onClick={onClickEmptyButton || navigateToDashboardHome}
        />
      ) : searchHasNoResults ? (
        <NoResultsText>No Views</NoResultsText>
      ) : (
        <ViewsListContainer variant={variant}>
          {shouldShowHeader && (
            <UserViewListHeader
              showUserViewCounts={canViewUserViewCounts}
              totalViewsUnit={totalViewsUnit}
              setTotalViewsUnit={setTotalViewsUnit}
              sortBy={sortBy}
              sortDirection={sortDirection}
              handleSort={handleSort}
            />
          )}

          {sortedViewsList.map((view, index) => (
            <UserViewListItem
              handleFavoriteUserView={handleFavoriteView}
              handleShareView={handleShareView}
              handleViewOptions={handleViewOptions}
              isLast={index === sortedViewsList.length - 1}
              isFavorite={checkViewIsFavorite(view)}
              item={view}
              onSelectView={handleSelectView}
              variant={variant}
              key={`${view.id}-${view.name}`}
              showUserViewCounts={shouldShowUserViewCounts}
              totalViewsUnit={totalViewsUnit}
            />
          ))}
        </ViewsListContainer>
      )}
      <DragonMenu
        items={UPDATE_VIEW_OPTIONS}
        anchorEl={viewOptionsAnchorEl}
        placement="bottom-end"
        onSetAnchorEl={setViewOptionsAnchorEl}
        onSelectItem={onSelectViewOption}
      />
      <UpdateViewDialog
        key={dialogProps?.selectedTab}
        show={dialogProps?.open}
        tabs={dialogProps?.visibleTabs}
        userCanEditView={dialogProps?.userCanEditView}
        onClose={closeDialog}
        initialViewTab={dialogProps?.selectedTab}
        onSave={handleSaveView}
        onSaveAndShare={handleSaveViewAndShare}
        onDelete={handleDeleteView}
        view={selectedView}
        shareUrl={shareUrl}
        userViews={saveDialogViews}
      />
    </>
  );
};

const ViewsListContainer = styled.div`
  ${({ variant }) =>
    variant === VIEW_ITEM_VARIANTS.widgetTile &&
    `
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 25px;
  `}
`;

const StyledEmptyViewsList = styled(EmptyViewsList)`
  ${({ variant }) => variant === VIEW_ITEM_VARIANTS.homepage && `margin-top: 100px;`}
  ${({ variant }) =>
    (variant === VIEW_ITEM_VARIANTS.widgetList || variant === VIEW_ITEM_VARIANTS.widgetTile) && `margin-top: 40px;`}
`;

const NoResultsText = styled(TextDeprecated)`
  padding: 25px;
`;
