import {
  CLEAN_FORM_LAYOUT_STATE,
  CLOSE_FORM_LAYOUT_DIRTY_WARNING,
  OPEN_FORM_LAYOUT_DIRTY_WARNING,
  SET_CURRENT_FORM_LAYOUT_DIRTY,
} from './types';

export const setCurrentFormLayoutDirty = (isDirty, type) => ({
  type: SET_CURRENT_FORM_LAYOUT_DIRTY,
  payload: { isDirty, type },
});

export const openFormLayoutDirtyWarning = type => ({
  type: OPEN_FORM_LAYOUT_DIRTY_WARNING,
  payload: { type },
});

export const closeFormLayoutDirtyWarning = type => ({
  type: CLOSE_FORM_LAYOUT_DIRTY_WARNING,
  payload: { type },
});

export const cleanFormLayoutState = type => ({
  type: CLEAN_FORM_LAYOUT_STATE,
  payload: { type },
});
