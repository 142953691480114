import React from 'react';

import useAllocationPieChart from 'hooks/allocation/useAllocationPieChart';
// eslint-disable-next-line max-len
import { ChartContainer } from 'routes/Dashboard/AllocationReport/AllocationV2/AllocationReportComponent/AllocationReportComponent';
import ChartContent from 'routes/Dashboard/Dashboards/Charts/ChartContent';

import mapAllocationDataToChart from './mapAllocationData';

const ActualAllocationPieChart = ({ allocationData, displayLegendInline, showAllocationInWeeks }) => {
  const { data, entities } = mapAllocationDataToChart(allocationData, showAllocationInWeeks);

  const [chart] = useAllocationPieChart(data, entities, { displayLegendInline }, 'actual-by-time-pie-chart', true);

  return (
    <ChartContainer bordered>
      <ChartContent title="Total Actual Allocation">{chart}</ChartContent>
    </ChartContainer>
  );
};

export default ActualAllocationPieChart;
