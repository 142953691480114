export const reorderDraggedElement = (updatedConfiguration, destination, source, draggableId) => {
  if (!destination) {
    return updatedConfiguration;
  }
  if (destination?.droppableId === source.droppableId && destination.index === source.index) {
    return updatedConfiguration;
  }

  const sourceId = source.droppableId.replace('droppable-', '');
  const destinationId = destination.droppableId.replace('droppable-', '');
  const fromList = updatedConfiguration[sourceId];
  const toList = updatedConfiguration[destinationId];
  const currentItem = fromList.items.find(item => item.id === draggableId.replace(`draggable-${sourceId}-`, ''));
  const fromItems = fromList.items;

  fromItems.splice(source.index, 1);
  if (fromList === toList) {
    fromItems.splice(destination.index, 0, currentItem);

    return updatedConfiguration;
  }

  const toItems = toList.items;

  toItems.splice(destination.index, 0, currentItem);

  return updatedConfiguration;
};
