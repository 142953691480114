const HIDDEN_SUFFIX = 'hidden#';

export default (customFields, query) => {
  const processedQuery = query?.toLowerCase();

  return customFields.filter(cf => {
    const isHiddenKey = (cf?.key || '')?.includes(HIDDEN_SUFFIX);
    const matchesSearch = !processedQuery || cf?.title?.toLowerCase().includes(processedQuery);

    return !isHiddenKey && matchesSearch;
  });
};
