import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { LEADER_USER, OWNER_USER } from '@dragonboat/permissions';
import { getCurrentUser } from 'store/login/selectors';
import { getOrganization, getOrgHasJiraIntegrated, getOrgHasTeamsLevelTwo } from 'store/organization/selectors';
import { getTeams, getTeamsLevel2, getAllTeams } from 'store/teams/selectors';
import { getSkills } from 'store/skills/selectors';
import { getState, getSearchText } from 'store/users/selectors';
import {
  fetchUsers,
  importUsers,
  updateUsersSearch,
  updateUsers,
  createUsers,
  bulkDeleteUsers,
  updateFilters,
  addUserWithoutSave,
  removeUnsavedUsers,
} from 'store/users';
import { createUserOnSettings } from 'store/users-settings/thunks';
import { updateUserById, mergeUsers, deleteUserById } from 'store/users/thunks';
import { fetchTeams } from 'store/teams/actions';
import { fetchSkills } from 'store/skills/actions';

import { transformMergeItemLabel } from '../helpers/transformMergeItemLabel';
import useUsersFilters from './useUsersFilters';

const useUsers = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const organization = useSelector(getOrganization);

  // TODO: PERMISSION
  const allowAddNewSettings = useMemo(() => [OWNER_USER, LEADER_USER].includes(currentUser.role_id), [currentUser.role_id]);

  const users = useSelector(getState);
  const teams = useSelector(state => getTeams(state, true));
  const teams2 = useSelector(state => getTeamsLevel2(state, true));
  const allTeams = useSelector(getAllTeams);
  const skills = useSelector(state => getSkills(state, false));

  const searchText = useSelector(getSearchText);

  const { filters } = useUsersFilters();

  const hasJiraIntegration = useSelector(getOrgHasJiraIntegrated);
  const hasTeamsLevelTwo = useSelector(getOrgHasTeamsLevelTwo);

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          fetchUsers,
          createUser: createUserOnSettings,
          addUserWithoutSave,
          removeUnsavedUsers,
          importUsers,
          updateUsers,
          createUsers,
          bulkDeleteUsers,
          updateUserById,
          mergeUsers,
          deleteUserById,
          updateUsersSearch,
          updateFilters,
          fetchTeams,
          fetchSkills,
        },
        dispatch,
      ),
    [dispatch],
  );

  const [debouncedUpdateUsersSearch] = useDebouncedCallback(boundActionCreators.updateUsersSearch, 500);

  return {
    users,

    // Organization
    orgHasOkta: organization.has_okta,
    systemFields: organization.system_fields_name,
    isPortalSettingsEnabled: organization.enable_portal_settings,
    orgIntegrations: organization?.integrations || [],
    hideUserIntegrationColumn: true,
    hasJiraIntegration,

    // Teams
    hasTeams2: hasTeamsLevelTwo,
    teams,
    teams2,
    allTeams,
    teamsTitles: teams.map(({ title }) => title),

    // Skills
    skills,
    skillsTitles: skills.map(({ title }) => title),

    // Misc
    allowAddNewSettings,
    currentUser,
    searchText,
    filters,
    transformLabel: transformMergeItemLabel,

    // Actions
    ...boundActionCreators,
    updateUsersSearch: debouncedUpdateUsersSearch,
  };
};

export default useUsers;
