import styled from 'styled-components';

const TableRow = styled.div`
  &&&& {
    display: flex;
    width: 100%;

    ${({ theme }) => `
      background: ${theme.palette.background.alabaster};
    `}
    &:nth-child(2n) {
      ${({ theme }) => `
        background: ${theme.palette.background.white};
      `}
    }
  }
`;

TableRow.displayName = 'TableRow';

export default TableRow;
