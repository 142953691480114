import reduceReducers from 'reduce-reducers';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import {
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_FULFILLED,
  FETCH_TEMPLATES_CATEGORIES,
  FETCH_TEMPLATES_CATEGORIES_FULFILLED,
  SET_ACTIVE_TEMPLATE,
  SET_ACTIVE_TEMPLATE_CATEGORY,
} from './types';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_CATEGORIES,
]);

const initialState = {
  templates: [],
  templatesCategories: [],
  activeTemplate: null,
  activeTemplateCategory: null,
  ...thunksInitialState,
};

function templatesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TEMPLATES_FULFILLED: {
      return {
        ...state,
        templates: action.payload.data,
      };
    }
    case FETCH_TEMPLATES_CATEGORIES_FULFILLED: {
      return {
        ...state,
        templatesCategories: action.payload.data,
      };
    }
    case SET_ACTIVE_TEMPLATE: {
      return {
        ...state,
        activeTemplate: action.payload,
      };
    }
    case SET_ACTIVE_TEMPLATE_CATEGORY: {
      return {
        ...state,
        activeTemplateCategory: action.payload,
      };
    }
    default:
      return state;
  }
}

const reducer = reduceReducers(initialState, templatesReducer, ...thunksReducers);

export default reducer;
