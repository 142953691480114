import { PLAN_OF_RECORD_COMMITTED_ID, PLAN_OF_RECORD_ID } from 'constants/common';

import {
  FETCH_ROADMAP_VERSIONS_FULFILLED,
  CREATE_NEW_ROADMAP_VERSION_FULFILLED,
  UPDATE_ROADMAP_VERSION_FULFILLED,
  RUN_ONE_CLICK_PLAN_FULFILLED,
  SET_VERSION_AS_PLAN_OF_RECORD_FULFILLED,
  DELETE_ROADMAP_VERSION_FULFILLED,
  CLONE_ROADMAP_VERSION_FULFILLED,
} from './types';
import { POR_DEFAULT_COLOR } from './constants/colors';
import { getScenariosColors } from './helpers/colors';

const actionTypesWithRoadmapVersionChanges = [
  FETCH_ROADMAP_VERSIONS_FULFILLED,
  CLONE_ROADMAP_VERSION_FULFILLED,
  CREATE_NEW_ROADMAP_VERSION_FULFILLED,
  UPDATE_ROADMAP_VERSION_FULFILLED,
  RUN_ONE_CLICK_PLAN_FULFILLED,
  SET_VERSION_AS_PLAN_OF_RECORD_FULFILLED,
  DELETE_ROADMAP_VERSION_FULFILLED,
];

const initialState = {
  versionsColor: { [PLAN_OF_RECORD_ID]: POR_DEFAULT_COLOR, [PLAN_OF_RECORD_COMMITTED_ID]: POR_DEFAULT_COLOR },
};

const colorsReducer = (state, { type }) => {
  if (actionTypesWithRoadmapVersionChanges.includes(type)) {
    return {
      ...state,
      compareVersions: {
        ...state.compareVersions,
        versionsColor: getScenariosColors(state.availableRoadmapVersions),
      },
    };
  }
  return state;
};

export { initialState, colorsReducer };
