import { DEFAULT_TABLE_HEIGHT } from 'constants/grid';
import { ADDITIONAL_METADATA_FIELDS } from 'constants/projectLightbox';

const ID_KEY = 'uniqueId';

const GRID_HEIGHT = DEFAULT_TABLE_HEIGHT;

const EDITABLE_METADATA_FIELDS = [
  'tags',
  'customers',
  'roadmapCorpTitle',
  'roadmapTitle',
  'product1Title',
  'product2Title',
  'themeTitle',
  'objectiveCorpTitle',
  'objectiveTitle',
  'keyResult2Title',
  'categoryTitle',
  'timeframeCorpTitle',
  'timeframeTitle',
  'timeframe2Title',
  'phaseTitle',
  'priorityTitle',
  'resourceTeamTitle',
  ...ADDITIONAL_METADATA_FIELDS,
];

const BET_PLACEHOLDER_ID = 'BET_PLACEHOLDER';
const INITIATIVE_PLACEHOLDER_ID = 'INITIATIVE_PLACEHOLDER';

export { ID_KEY, GRID_HEIGHT, EDITABLE_METADATA_FIELDS, BET_PLACEHOLDER_ID, INITIATIVE_PLACEHOLDER_ID };
