import React from 'react';

import useGetOrSaveLastPageView from 'hooks/useGetOrSaveLastPageView';

const GetParentRouteProxyFactory = props => {
  const { history, location } = props;

  const queryString = location.search || '';

  const View = useGetOrSaveLastPageView(location.pathname, queryString, history);

  if (!View) return '';

  return <View {...props} />;
};

export default GetParentRouteProxyFactory;
