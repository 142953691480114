import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { spacing } from 'design-system/theme';
import Button from '@material-ui/core/Button';
import { INTEGRATIONS_ICON_MAPPER, INTEGRATIONS_NAMES } from 'constants/integrations';
import Autocomplete from 'containers/Autocomplete';
import FluidAutocompleteField from 'design-system/organisms/FluidAutocompleteField';

import { RECURRING_OPTIONS_LIST, SEND_ONCE } from 'features/EstimateByPoints/constants';

const getButtonLabel = (periodicity, integrationTypeName, canCreateRecurringNotifications = true) => {
  if (canCreateRecurringNotifications && periodicity && periodicity?.value !== SEND_ONCE) {
    return 'Schedule update';
  }
  return `Send to ${integrationTypeName}`;
};

const getButtonId = (periodicity, integrationTypeName, canCreateRecurringNotifications = true) => {
  if (canCreateRecurringNotifications && periodicity && periodicity?.value !== SEND_ONCE) {
    return `${integrationTypeName}_comment_recurring`;
  }
  return `${integrationTypeName}_comment`;
};

/**
 * Renders a button that sends content to a selected collaboration integration channel.
 * ex. Slack, Microsoft Teams
 *
 * @param {Object} props - The component props.
 * @param {string} props.integrationType - The type of integration to send content to.
 * @param {Function} props.onSend - The function to call when the content is sent to the integration.
 * @param {Object[]} props.channels - The channels available for the integration.
 * @param {string} props.selectedChannel - The currently selected channel.
 * @param {Function} props.setSelectedChannel - The function to call when a channel is selected.
 * @param {boolean} props.isDisabled - Whether the button should be disabled.
 * @returns {JSX.Element} - The SendToIntegrationChannelButton component.
 */
const SendToIntegrationChannelButton = ({
  integrationType,
  onSend,
  channels,
  setSelectedChannel,
  selectedChannel,
  isDisabled,
  periodicity,
  setSelectedPeriodicity,
  canCreateRecurringNotifications = true,
}) => {
  const INTEGRATION_TYPE_NAME = INTEGRATIONS_NAMES[integrationType];

  const IntegrationTypeIcon = INTEGRATIONS_ICON_MAPPER[integrationType];

  const selectedChannelName = channels.find(c => c.id === selectedChannel)?.name;

  return (
    <>
      <Flex>
        <IntegrationTypeIcon marginRight={`${spacing()}px`} />
        <FluidAutocompleteField
          options={channels.map(c => ({ value: c.id, title: c.name }))}
          value={selectedChannelName}
          format="column"
          key="channel"
          onChange={setSelectedChannel}
          autocompleteComponent={Autocomplete}
          placeholder="Type to search channel"
          readingPlaceholder="Select channel"
          hideBottomBorder={false}
          hideIcon={false}
          addNew={false}
          useEllipsis
        />
      </Flex>
      <RightSection>
        {/* We should be using new Autocomplete (design-system/atoms/Autocomplete/v2 ) but there is still some missing
           functionalities. Going to proceed with this one and to the switch later on
        */}
        {canCreateRecurringNotifications && periodicity ? (
          <StyledPrioridicityAutocomplete
            suggestions={RECURRING_OPTIONS_LIST}
            value={periodicity?.label}
            key="periodicity"
            onValueChange={setSelectedPeriodicity}
            placeholder="Type to search periodicity"
            readingPlaceholder="Select periodicity"
            useEllipsis
          />
        ) : null}
        <Button
          id={getButtonId(periodicity, INTEGRATION_TYPE_NAME, canCreateRecurringNotifications)}
          disabled={isDisabled}
          color="primary"
          onClick={onSend}
        >
          {getButtonLabel(periodicity, INTEGRATION_TYPE_NAME, canCreateRecurringNotifications)}
        </Button>
      </RightSection>
    </>
  );
};

SendToIntegrationChannelButton.propTypes = {
  integrationType: PropTypes.string.isRequired,
  onSend: PropTypes.func.isRequired,
  setSelectedChannel: PropTypes.func.isRequired,
  channels: PropTypes.array,
  selectedChannel: PropTypes.string,
  isDisabled: PropTypes.bool,
};

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 62%;
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing()}px;
`;

const StyledPrioridicityAutocomplete = styled(Autocomplete)`
  min-width: 200px;
`;

export default SendToIntegrationChannelButton;
