import React from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { getOrganization } from 'store/organization/selectors';
import { fetchOrganization } from 'store/organization/actions';

export default function (selectedTemplateId) {
  const dispatch = useDispatch();
  const organization = useSelector(getOrganization);

  const applyTemplate = async () => {
    return axios
      .put(`/api/organizations/${organization.id}/template`, { templateId: selectedTemplateId })
      .then(res => {
        return dispatch(fetchOrganization());
      })
      .catch(({ response }) => {
        if (response && response.status === 400 && response.data) {
          const { error_code: code } = response.data;

          if (code === 'TEMPLATES_ORG_NOT_ALLOWED_TO_CHANGE') {
            toast(
              <span>
                {'Please '}
                <a
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                  rel="noreferrer"
                  href="https://dragonboat.zendesk.com/hc/en-us/articles/360048304994"
                >
                  bulk delete
                </a>{' '}
                current data before applying a new template.
              </span>,
            );
          }
        } else {
          toast('Something went wrong apllying the template data');
        }
      });
  };

  return applyTemplate;
}
