import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { selectKeyResults1, getObjectives, selectKeyResults2 } from 'store/objectives/selectors';
import { getCategories } from 'store/categories/selectors';
import { getThemes } from 'store/themes/selectors';
import { updateProductById, updateRoadmap } from 'store/roadmaps';
import { updateKeyResultById, updateObjective } from 'store/objectives';
import { updateCategory } from 'store/categories';
import { updateTheme } from 'store/themes';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import { getTimeframes } from 'store/timeframes/selectors';
import { getProducts, getRoadmaps } from 'store/roadmaps/selectors';
import { getTags } from 'store/tags/selectors';
import { getCustomersFilteredByStatus } from 'store/customers/selectors';
import { getPhases } from 'store/phases/selectors';
import { getPriorities } from 'store/priorities/selectors';
import { getStatusColors } from 'store/projects/helpers/groupOptions';

/**
 * Custom hook that use color legend and update the group color methods
 *
 * @param {*} projects
 * @param {*} colorBy
 * @returns [legendGroups, updateGroupColor]
 */
const useColorLegend = () => {
  const dispatch = useDispatch();

  const metadata = {
    roadmaps: useSelector(state => getStateDataForPage(state, getRoadmaps, 'roadmaps')),
    timeframes: useSelector(state => getStateDataForPage(state, getTimeframes, 'timeframes')),
    keyResult1s: useSelector(state => getStateDataForPage(state, selectKeyResults1, 'keyResults')),
    keyResult2s: useSelector(state =>
      getStateDataForPage(state, (state, showArchived) => selectKeyResults2(state, showArchived), 'keyResult2'),
    ),
    product1s: useSelector(state => getStateDataForPage(state, getProducts, 'products')),
    objectives: useSelector(state => getStateDataForPage(state, getObjectives, 'objectives')),
    themes: useSelector(state => getStateDataForPage(state, getThemes, 'themes')),
    categorys: useSelector(state => getStateDataForPage(state, getCategories, 'categories')),
    customers: useSelector(state => getStateDataForPage(state, getCustomersFilteredByStatus, 'customers')),
    phases: useSelector(state => getStateDataForPage(state, getPhases, 'phases')),
    tags: useSelector(state => getStateDataForPage(state, getTags, 'tags')),
    priorities: useSelector(state => getStateDataForPage(state, getPriorities, 'priorities')),
    healths: getStatusColors(),
  };

  const legendGroups = (colorBy = { key: 'roadmap' }) => metadata[`${colorBy.key}s`] || [];
  const debouncedUpdateData = update => {
    const [debounce] = useDebouncedCallback((data, more) => dispatch(update(data, more)), 500);

    return debounce;
  };
  const debouncedUpdateRoadmap = debouncedUpdateData(updateRoadmap);
  const debouncedUpdateProduct = debouncedUpdateData(updateProductById);
  const debouncedUpdateObjective = debouncedUpdateData(updateObjective);
  const debouncedUpdateKeyResult = debouncedUpdateData(updateKeyResultById);
  const debouncedUpdateCategory = debouncedUpdateData(updateCategory);
  const debouncedUpdateTheme = debouncedUpdateData(updateTheme);

  const updateGroupColor = colorBy => (group, color) => {
    const colorById = colorBy.id || colorBy.key;
    const data = Object.assign({}, group, { color });
    const mapIdToFn = {
      roadmap: () => debouncedUpdateRoadmap(data),
      objective: () => debouncedUpdateObjective(data),
      category: () => debouncedUpdateCategory(data),
      theme: () => debouncedUpdateTheme(data),
      keyResult1: () => debouncedUpdateKeyResult(data.id, data),
      keyResult2: () => debouncedUpdateKeyResult(data.id, data),
      product1: () => debouncedUpdateProduct(data.id, data),
    };

    mapIdToFn[colorById]();
  };

  return [legendGroups, updateGroupColor];
};

export default useColorLegend;
