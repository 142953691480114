import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { isEmpty } from 'ramda';

import { DEFAULT_COLOR_BY_OPTION, DEFAULT_GROUPLANE_TYPE } from 'constants/roadmap';
import { getState, updateState as updateStateAction } from 'store/timelines';
import { getGroupOptionsSelector } from 'store/projects/groupSelectors';
import { DEFAULT_GROUP_OPTION, getGroupOptionByKey, getObjectiveGroupOption } from 'store/projects/helpers/groupOptions';
import useSystemFields from 'hooks/useSystemFields';
import useOrganizations from 'hooks/useOrganizations';

import {
  DEFAULT_SIDEBAR_WIDTH,
  DEFAULT_VIEW_MODE,
  DEFAULT_ZOOM_OPTION,
  SLOT_WIDTH,
  ZOOM_OPTIONS,
} from '../../../design-system/organisms/Timeline/Legacy/helpers/constants';

const DEFAULT_GROUP_TYPE = 'objective';

const includesOption = (groupByOptions, optionToCheck) => groupByOptions.some(option => option.key === optionToCheck.key);

const useTimelines = (key, groupingOptions = {}) => {
  const dispatch = useDispatch();

  const [getSystemFieldName] = useSystemFields();

  const { systemFields } = useOrganizations();

  const lsState = useSelector(getState(key));

  const { id: zoomMode } = lsState?.selectedZoom ?? DEFAULT_VIEW_MODE;

  // Grouping configuration

  const processedGroupingOptions = useMemo(() => ({ portfolioMode: false, ...groupingOptions }), []);
  const groupByOptions = useSelector(state => getGroupOptionsSelector(state, processedGroupingOptions));

  const groupType = lsState?.grouplaneType ? lsState.grouplaneType.key : DEFAULT_GROUP_TYPE;
  const dataType = lsState?.swimlaneType ? lsState.swimlaneType.key : null;

  const selectedGrouplaneType = getGroupOptionByKey(
    lsState?.grouplaneType?.key ? lsState?.grouplaneType.key : null,
    groupByOptions,
  );
  const selectedSwimlaneType = getGroupOptionByKey(lsState?.swimlaneType?.key ? lsState?.swimlaneType.key : null, groupByOptions);

  // Actions to update state

  const updateState = options => {
    dispatch(updateStateAction(key, options));
  };

  const resizeFirstGroupHeader = useCallback(
    width =>
      updateState({
        groupbarWidth: +width,
      }),
    [],
  );
  const resizeSecondGroupHeader = useCallback(width => updateState({ sidebarWidth: +width }), []);

  const changeSelectedZoom = useCallback(() => {
    if (lsState.selectedZoom.id === 'week') {
      updateState({ selectedZoom: ZOOM_OPTIONS.find(g => g.id === 'month') });
    } else if (lsState.selectedZoom.id === 'month') {
      updateState({ selectedZoom: ZOOM_OPTIONS.find(g => g.id === 'quarter') });
    } else if (lsState.selectedZoom.id === 'day') {
      updateState({ selectedZoom: ZOOM_OPTIONS.find(g => g.id === 'week') });
    } else if (lsState.selectedZoom.id === 'quarter') {
      updateState({ selectedZoom: ZOOM_OPTIONS.find(g => g.id === 'day') });
    }
  }, [lsState]);

  const updateSearchString = searchString => updateState({ searchString });

  const changeGroupLane = option => updateState({ grouplaneType: option });

  const changeDataLane = option =>
    updateState({
      swimlaneType: option,
      sidebarWidth: DEFAULT_SIDEBAR_WIDTH,
      groupbarWidth: DEFAULT_SIDEBAR_WIDTH,
    });

  const toggleShowItemsWithoutTimeline = useCallback(
    () => updateState({ showItemsWithoutTimeline: !lsState?.showItemsWithoutTimeline }),
    [lsState?.showItemsWithoutTimeline],
  );

  // Set the defaults

  useEffect(() => {
    const defaultState = {};

    if (!lsState.slotWidth) defaultState.slotWidth = SLOT_WIDTH;
    if (!lsState.sidebarWidth) defaultState.sidebarWidth = DEFAULT_SIDEBAR_WIDTH;
    if (!lsState.groupbarWidth) defaultState.groupbarWidth = DEFAULT_SIDEBAR_WIDTH;
    if (!lsState.grouplaneType) defaultState.grouplaneType = DEFAULT_GROUPLANE_TYPE(systemFields);
    if (!lsState.selectedColorBy) defaultState.selectedColorBy = DEFAULT_COLOR_BY_OPTION(systemFields);
    if (!lsState.timeWindowStart) defaultState.timeWindowStart = moment().subtract(1, 'month');
    if (!lsState.timeWindowEnd) defaultState.timeWindowEnd = moment().add(1, 'month');
    if (!lsState.selectedZoom) defaultState.selectedZoom = DEFAULT_ZOOM_OPTION;
    if (lsState.showTooltip === undefined) defaultState.showTooltip = true;
    if (lsState.singleTimeScale === undefined) defaultState.singleTimeScale = true;
    if (lsState.isDialogVisible === undefined) defaultState.isDialogVisible = false;
    if (lsState.showItemsWithoutTimeline === undefined) defaultState.showItemsWithoutTimeline = true;
    if (!lsState.showMyItemsOnly) defaultState.showMyItemsOnly = false;

    if (
      !selectedGrouplaneType ||
      (selectedGrouplaneType?.key && !includesOption(groupByOptions, selectedGrouplaneType)) ||
      (selectedSwimlaneType?.key && !includesOption(groupByOptions, selectedSwimlaneType))
    ) {
      defaultState.grouplaneType = getObjectiveGroupOption(getSystemFieldName);
      defaultState.swimlaneType = DEFAULT_GROUP_OPTION;
    }

    if (selectedSwimlaneType?.key === selectedGrouplaneType?.key && lsState?.swimlaneType?.key !== DEFAULT_GROUP_OPTION?.key) {
      defaultState.swimlaneType = DEFAULT_GROUP_OPTION;
    }

    if (!isEmpty(defaultState)) {
      updateState({
        ...lsState,
        ...defaultState,
      });
    }
  }, []);

  return {
    lsState: { ...(lsState || {}) },
    zoomMode,
    groupType, // key for group level 1
    dataType, // key for group level 2
    selectedGrouplaneType,
    selectedSwimlaneType,
    groupByOptions,
    updateState,
    resizeFirstGroupHeader,
    resizeSecondGroupHeader,
    changeSelectedZoom,
    updateSearchString,
    changeGroupLane,
    changeDataLane,
    toggleShowItemsWithoutTimeline,
  };
};

export default useTimelines;
