import axios from 'axios';

import { createThunk } from 'utils/store/thunk';

import { BULK_DELETE_ERROR_LOGS, FETCH_ERROR_LOGS } from './types';

export const bulkDeleteErrorLogs = ids => {
  return createThunk(BULK_DELETE_ERROR_LOGS, axios.delete(`/api/error-logs`, { data: { ids } }), { ids });
};

export const fetchErrorLogs = () => createThunk(FETCH_ERROR_LOGS, axios.get(`/api/error-logs`), {});
