import { keys, pluck, propOr } from 'ramda';

import { getUserName } from 'utils';
import formatDate from 'utils/dates/formatDate';

const extractNames = pluck('name');

/**
 * Transforms a merge request object into a formatted label string.
 *
 * @param {Object} request - The merge request object.
 * @param {string} request.title - The title of the merge request.
 * @param {Array} request.customers - The customers associated with the merge request.
 * @param {Object} request.roadmap - The roadmap associated with the merge request.
 * @param {Object} request.owner - The owner of the merge request.
 * @param {Object} request.created_by - The creator of the merge request.
 * @param {string} request.created_at - The creation date of the merge request.
 * @returns {string} The formatted label string.
 */
const transformMergeRequestLabel = request => {
  const { title, customers, roadmap, owner, created_by: createdBy, created_at: createdAt } = request;

  const fieldMapper = {
    customers: customers.length > 0 && extractNames(customers).join(', '),
    roadmap: roadmap && roadmap.title,
    owner: owner && getUserName(owner),
    createdBy: getUserName(createdBy),
    createdAt: formatDate(createdAt),
  };
  const additionalFields = keys(fieldMapper);

  const label = additionalFields.reduce((acc, field) => {
    const fieldValue = propOr('', field)(fieldMapper);

    if (!fieldValue) {
      return acc;
    }

    return acc === '' ? fieldValue : `${acc}, ${fieldValue}`;
  }, '');

  return `${title} (${label})`;
};

export default transformMergeRequestLabel;
