import { Tooltip } from '@material-ui/core';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


const DEFAULT_BAR_HEIGHT = 32;

export default function IssuesProgressBar({ progressValues = [], onProgressBarClick, ...props }) {
  const filteredProgressValues = useMemo(() => progressValues.filter(value => value?.percentage), [progressValues]);

  return (
    <ProgressContainer className={props.className}>
      <ProgressBarContainer>
        {filteredProgressValues.map((value, i) => (
          <Tooltip title={`${value.label}: ${value.value}`}>
            <ProgressBar
              onClick={() => {
                if (onProgressBarClick) {
                  onProgressBarClick(value);
                }
              }}
              previousPercentage={i > 0 ? filteredProgressValues[i - 1].percentage : 0}
              value={value.percentage}
              totalValues={filteredProgressValues.length}
              index={i}
              color={value.color}
            >
              <ProgressValue>{value.value}</ProgressValue>
            </ProgressBar>
          </Tooltip>
        ))}
      </ProgressBarContainer>
    </ProgressContainer>
  );
}

const ProgressContainer = styled.div`
  width: 100%;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: ${DEFAULT_BAR_HEIGHT}px;

  background: ${props => props.theme.palette.newLayout.progressBarSquared.background.lightGray};

  border-radius: 5px;

  margin-top: ${({ theme }) => 2 * theme.spacing.unit}px;
`;
const ProgressBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: ${DEFAULT_BAR_HEIGHT}px;
  width: ${props => props.value}%;
  max-width: 100%;
  background: ${props => props.color};
  border-radius: 5px;

  box-sizing: content-box;
  z-index: ${({ totalValues, index }) => totalValues - index};

  ${({ index, previousPercentage }) => {
    if (index > 0) {
      return `
        margin-left: -${previousPercentage}%;
        padding-left: ${previousPercentage}%;
      `;
    }
  }}
`;

const ProgressValue = styled(TextDeprecated).attrs({ color: 'primary' })`
  &&&& {
    margin: 0 10px;

    text-overflow: ellipsis;
    overflow-x: hidden;
  }
`;
