/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Component, Fragment } from 'react';
import { func, string, number, bool, array } from 'prop-types';
import styled from 'styled-components';
import { not } from 'ramda';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

import FieldsMappingTypeSelection from 'containers/FieldsMappingTypeSelection';

export default class ImportJQLStepOne extends Component {
  static propTypes = {
    onSearch: func.isRequired,
    totalTasks: number,
    jiraUrl: string,
    loading: bool,
    searched: bool,
    jiraErrors: array,
    recurringWarningMessage: bool,
    orgIntegrationId: number,
    hasSavedMappingForJiraProjects: bool,
    selectedFieldsMappingType: string,
    onChangeFieldsMappingType: func.isRequired,
  };

  static defaultProps = {
    totalTasks: 0,
    loading: false,
    jiraErrors: [],
  };

  state = {
    url: string,
  };

  constructor(props) {
    super(props);

    this.state.url = props.jiraUrl;
  }

  render() {
    const {
      onSearch,
      totalTasks,
      loading,
      searched,
      jiraErrors,
      recurringWarningMessage,
      getSystemFieldName,
      orgIntegrationId,
      hasSavedMappingForJiraProjects,
      selectedFieldsMappingType,
      onChangeFieldsMappingType,
      hasJiraFieldMappingByProject,
    } = this.props;

    const { url } = this.state;

    const shouldRenderFieldMappingTypeSelector = not(hasJiraFieldMappingByProject) && hasSavedMappingForJiraProjects;

    return (
      <Wrapper>
        <ImportInstructions>
          Search epics on your jira instance, then <Bold>paste the URL</Bold> below
        </ImportInstructions>
        <InputContainer>
          <UrlInput value={url} placeholder="Paste your Jira URL here" onChange={e => this.setState({ url: e.target.value })} />
          <Button onClick={() => onSearch(url)} disabled={!url}>
            Search
          </Button>
        </InputContainer>
        {recurringWarningMessage && (
          <ImportInstructions style={{ color: 'red', marginTop: 30 }}>
            {'To avoid Jira timeout, please add "updated > -2d" to your recurring import query.'}
          </ImportInstructions>
        )}
        {recurringWarningMessage && (
          <ImportInstructions style={{ marginTop: 20 }}>
            {`EPICs already linked to dragonboat ${getSystemFieldName('idea', true)} will not be imported. `}
            {'To update these EPICs, please use the field mapping setting section.'}
          </ImportInstructions>
        )}
        {loading && <CircularProgress variant="indeterminate" disableShrink size={24} thickness={4} />}
        {!loading && !!totalTasks && (
          <ImportInstructions>
            <Bold>{totalTasks}</Bold> EPIC{totalTasks !== 1 ? 's' : ''} found.
          </ImportInstructions>
        )}
        {!loading && !totalTasks && searched && !jiraErrors && (
          <ImportInstructions>There are no epics in the search result.</ImportInstructions>
        )}
        {!!jiraErrors && (
          <JiraError>
            <Grid container>
              <Grid xs={12} item>
                {!Array.isArray(jiraErrors) && <Error>{jiraErrors}</Error>}
                {Array.isArray(jiraErrors) && (
                  <Fragment>
                    <Error>Jira has returned the following error(s) on your JQL. Please update your JQL and try again.</Error>
                    {jiraErrors.map(err => (
                      <Error padded>{err}</Error>
                    ))}
                  </Fragment>
                )}
              </Grid>
              <Grid xs={12} item style={{ marginTop: 22 }}>
                <Typography style={{ lineHeight: 1 }}>
                  <a
                    href="https://dragonboat.zendesk.com/hc/en-us/articles/360015879253-After-integrated-with-Jira-Import-EPICs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Check here
                  </a>{' '}
                  to troubleshoot or contact us at <a href="mailto:help@dragonboat.io">help@dragonboat.io</a>
                </Typography>
              </Grid>
            </Grid>
          </JiraError>
        )}
        {shouldRenderFieldMappingTypeSelector && (
          <FieldsMappingTypeSelection
            orgIntegrationId={orgIntegrationId}
            selectedType={selectedFieldsMappingType}
            onChange={onChangeFieldsMappingType}
          />
        )}
        {/* <ImportInstructions style={{ marginTop: 22, fontSize: 14 }}>
          We will import the first 100 EPICs.<br />
          To import more EPICs, please revise your Jira search to reduce total issue count.
        </ImportInstructions> */}
      </Wrapper>
    );
  }
}

const InputContainer = styled.div`
  margin: 36px 0 11px 0;
  text-align: right;
  width: 550px;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;

  button {
    background-color: rgba(0, 0, 0, 0.08);
    text-transform: initial;
    border-radius: 0;
    font-weight: 400;
    height: 42px;
    width: 90px;
  }
`;

const ImportInstructions = styled.p`
  font-size: 16px;
  line-height: 23px;
  width: 640px;
`;

const Wrapper = styled.div`
  margin: 20px 32px;
  min-height: 200px;
`;

const UrlInput = styled(Input)`
  &&&& {
    width: calc(100% - 42px);

    input {
      width: 100%;
      height: 100%;
      padding: 10px;
      color: #4689f0;
      font-size: 14px;
    }
  }
`;

const Bold = styled.b`
  font-weight: bold;
`;

const JiraError = styled.div`
  font-size: 14px;
`;

const Error = styled.span`
  color: red;

  ${({ padded }) =>
    padded &&
    `
    margin: 10px 30px 10px 30px;
    list-style: square;
    display: list-item;
  `}
`;
