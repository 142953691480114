import moment from 'moment-timezone';
import { UPDATE_FORECAST_STATE } from './types';
import { groupByOptions, sumByOptions, durationOptions } from 'store/allocationReport/consts';
import { UPDATE_ROADMAP_VERSION_FULFILLED } from 'store/roadmapVersions';
import { INCLUDE_ALL_OPTION } from 'constants/projects';

const initStartDate = () => moment().subtract(12, 'weeks');
const initEndDate = () => moment().add(12, 'weeks');

const initialState = {
  showBusinessValue: false,
  assignNoCapacity: true,
  editConfirmedTasks: true,
  searchString: '',
  selectedDisplayColumns: null,
  showLegend: false,
  showTooltip: true,
  reportState: {
    selectedGroupBy: groupByOptions().find(o => o.key === 'roadmap'),
    selectedSumBy: sumByOptions()[0],
    selectedDuration: durationOptions[0],
    startDate: initStartDate(),
    endDate: initEndDate(),
  },
  showUncommittedProjects: INCLUDE_ALL_OPTION,
};

export default function allocationReportReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_FORECAST_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_ROADMAP_VERSION_FULFILLED:
      return {
        ...state,
        localMode: false,
      };
    default:
      return state;
  }
}
