import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import StepButton from '@material-ui/core/StepButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { path } from 'ramda';
import {
  testJira,
  disconnectJira,
  updateJiraConfigMisc,
  createJiraConfigDetails,
  updateJiraConfigDetails,
} from 'store/integrations';

import ConfigDetails from '../ConfigDetails';
import ConfigStepOne from './components/ConfigStepOne';
import ConfigStepTwo from './components/ConfigStepTwo';
import { JiraConfig } from '../OAuthForm';

class JiraOauth2Config extends JiraConfig {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      formData: {
        client_id: path(['orgIntegration', 'data', 'client_id'], props) || '',
        client_secret: path(['orgIntegration', 'data', 'client_secret'], props) || '',
        type: 'oauth2',
      },
    };
  }

  getSteps() {
    return [<p>Configure an incoming link in Jira</p>, <p>Copy Client ID and Secret from Jira to Dragonboat</p>];
  }

  async cancelSetup() {
    const orgIntegration = this.props.orgIntegration || {};

    if (orgIntegration.id) {
      this.props.disconnectJira(orgIntegration.id);
    }

    this.props.goBack();
  }

  async setupJira() {
    await this.setJiraConfiguration(false);

    this.makeJiraConnection();
  }

  render() {
    const { currentStep, completed, baseUrl, waitForAPI, formData, orgIntegration } = this.state;

    const { jiraConfig, goBack } = this.props;

    const steps = this.getSteps();

    const changeForm = field => e => {
      this.setState({ formData: { ...this.state.formData, [field]: e.target.value } });
    };

    if (currentStep === 2) {
      return (
        <ConfigDetails
          justIntegrated
          jiraConfig={jiraConfig}
          orgIntegration={orgIntegration}
          disconnet={id => {
            this.props.disconnectJira(id);
            this.setState({ currentStep: 0, completed: {} });
          }}
        />
      );
    }

    return (
      <Card style={{ minHeight: '400px' }}>
        <CardContent>
          <ConfigStepper alternativeLabel activeStep={currentStep}>
            {steps.map((label, index) => (
              <JiraStep key={label}>
                <StepButton completed={completed[index]}>{label}</StepButton>
              </JiraStep>
            ))}
          </ConfigStepper>
          <ConfigContent>
            <Progress>{waitForAPI && <LinearProgress />}</Progress>
            {currentStep === 0 && (
              <ConfigStepOne goBack={goBack} onNextClick={() => this.setState({ currentStep: this.state.currentStep + 1 })} />
            )}
            {currentStep === 1 && (
              <ConfigStepTwo
                jiraConfig={jiraConfig}
                baseUrl={baseUrl}
                goBack={() => this.setState({ currentStep: 0, completed: {} })}
                baseUrlChange={e => this.setState({ baseUrl: this.cleanInsertedUrl(e) })}
                changeForm={changeForm}
                formData={formData}
                onNext={() => this.setupJira()}
                onCancel={() => this.cancelSetup()}
              />
            )}
          </ConfigContent>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = {
  updateJiraConfigDetails,
  createJiraConfigDetails,
  disconnectJira,
  updateJiraConfigMisc,
  testJira,
};

export default connect(mapStateToProps, mapDispatchToProps)(JiraOauth2Config);

const ConfigStepper = styled(Stepper)`
  &&&& {
    justify-content: center;
  }
`;

const JiraStep = styled(Step)`
  &&&& {
    max-width: 270px;

    span {
      font-weight: 400;

      b {
        font-weight: 600;
      }
    }
  }
`;

const ConfigContent = styled.div`
  min-height: 200px;
  padding: 0 16px 16px;
`;

const Progress = styled.div`
  height: 6px;
  width: 100%;
`;
