import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('REQUEST_DRAGONBOT_PROMPT_RESULT'),
  ...getThunkActionTypes('GET_DRAGONBOT_CORPUS_VERSIONS'),
  ...getThunkActionTypes('UPDATE_DRAGONBOT_CORPUS_VERSION'),
  ...getThunkActionTypes('MAKE_DRAGONBOT_CORPUS_VERSION_CURRENT'),

  SET_DRAGONBOT_LAST_PROMPT_RESULT: 'SET_DRAGONBOT_LAST_PROMPT_RESULT',
  SET_CORPUS_VERSION_BEING_EDITED: 'SET_CORPUS_VERSION_BEING_EDITED',
  TOGGLE_DRAGONBOT_VISIBILITY: 'TOGGLE_DRAGONBOT_VISIBILITY',
  SET_DRAGONBOT_HISTORY_CACHE_EXPIRY: 'SET_DRAGONBOT_HISTORY_CACHE_EXPIRY',
};

export const {
  REQUEST_DRAGONBOT_PROMPT_RESULT,
  REQUEST_DRAGONBOT_PROMPT_RESULT_PENDING,
  REQUEST_DRAGONBOT_PROMPT_RESULT_FULFILLED,
  REQUEST_DRAGONBOT_PROMPT_RESULT_REJECTED,
  GET_DRAGONBOT_CORPUS_VERSIONS,
  GET_DRAGONBOT_CORPUS_VERSIONS_PENDING,
  GET_DRAGONBOT_CORPUS_VERSIONS_FULFILLED,
  GET_DRAGONBOT_CORPUS_VERSIONS_REJECTED,
  UPDATE_DRAGONBOT_CORPUS_VERSION,
  UPDATE_DRAGONBOT_CORPUS_VERSION_PENDING,
  UPDATE_DRAGONBOT_CORPUS_VERSION_FULFILLED,
  UPDATE_DRAGONBOT_CORPUS_VERSION_REJECTED,
  MAKE_DRAGONBOT_CORPUS_VERSION_CURRENT,
  MAKE_DRAGONBOT_CORPUS_VERSION_CURRENT_PENDING,
  MAKE_DRAGONBOT_CORPUS_VERSION_CURRENT_FULFILLED,
  MAKE_DRAGONBOT_CORPUS_VERSION_CURRENT_REJECTED,
  SET_DRAGONBOT_LAST_PROMPT_RESULT,
  SET_CORPUS_VERSION_BEING_EDITED,
  TOGGLE_DRAGONBOT_VISIBILITY,
  SET_DRAGONBOT_HISTORY_CACHE_EXPIRY,
} = actionTypes;

export default actionTypes;
