import reduceReducers from 'reduce-reducers';
import cloneDeep from 'lodash/cloneDeep';
import { isEmpty, isNil, omit, prop, uniqBy } from 'ramda';

import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';

import {
  FETCH_USER_VIEWS,
  FETCH_USER_VIEWS_FULFILLED,
  FETCH_USER_TEAM_VIEWS_FULFILLED,
  SAVE_USER_VIEW_FULFILLED,
  DELETE_USER_VIEW_FULFILLED,
  SET_ACTIVE_USER_VIEW,
  SET_ACTIVE_USER_VIEW_ONLOAD,
  SUBSCRIBE_USER_TO_USER_VIEW_FULFILLED,
  UPDATE_USER_VIEW_BY_REALTIME,
  ADD_USER_VIEW_FAVORITE_FULFILLED,
  DELETE_USER_VIEW_FAVORITE_FULFILLED,
  CREATE_USER_VIEW_PUBLIC_LINK_FULFILLED,
  UPDATE_USER_VIEW_PUBLIC_LINK_FULFILLED,
  UPDATE_USER_VIEW_DIALOG_PROPERTIES,
  DELETE_USER_VIEW_PUBLIC_LINK_FULFILLED,
  FETCH_PUBLIC_USER_VIEW_FULFILLED,
  FETCH_PUBLIC_USER_VIEW_REJECTED,
  RESET_USER_VIEW_DIALOG_PROPERTIES,
  SET_PAGE_ACTIVE_VIEW,
  UPDATE_PAGE_ACTIVE_VIEW_DATA,
  CLEAR_PAGE_ACTIVE_VIEW,
  UPDATE_USER_VIEW_FULFILLED,
  CREATE_USER_VIEW_FULFILLED,
  CLONE_USER_VIEW_FULFILLED,
  CLONE_USER_VIEW,
  TOGGLE_VIEWS_DIALOG,
  SUBSCRIBE_USER_TO_USER_VIEW,
  UPDATE_USER_VIEW_OWNER_FULFILLED,
  SHARE_VIEW_ON_INTEGRATION,
} from './types';
import updateRows from 'store/utils/updateRows';
import { updateActiveViewFromViewUpdate } from './utils';
import { SET_PAGE_FILTERS } from 'store/filters/types';
import { SET_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED } from 'store/customerRequests/types';
import { getPageIdFromPath } from 'utils/userViews';
import { SHARED_FILTERS_BY_PAGE } from './consts';
import { getSharedFilterIdFromPageId } from 'store/filters/selectors/getPageFilters';
import { UPDATE_OUTCOME_MODULE_FILTERS_FULFILLED, UPDATE_OUTCOME_MODULE_FILTERS_PENDING } from 'store/goalMode/types';
import { OBJECTIVES_FILTER } from 'constants/filters';
import { CREATE_USER_VIEW_RECURRING_NOTIFICATION_FULFILLED } from 'features/UserViewRecurringNotification/store/types';

export const initSaveDialogState = {
  showSaveDialog: false,
  activeViewId: null,
  viewName: null,
  nextRoute: null,
  nextView: null,
  viewIdActivated: null,
};

const initialState = {
  views: [],
  activeViewsByPage: {},
  activeViews: {},
  publicSharedView: {
    error: false,
    loadedView: {},
    user: null,
    token: '',
  },
  userTeamViews: [],
  operations: bulkThunkInitialState([FETCH_USER_VIEWS, SHARE_VIEW_ON_INTEGRATION]),
  saveDialog: initSaveDialogState,
  viewsDialogOpen: false,
};

const userViewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_VIEWS_FULFILLED: {
      const { data } = action.payload;

      return {
        ...state,
        views: data,
      };
    }
    case CREATE_USER_VIEW_RECURRING_NOTIFICATION_FULFILLED: {
      return {
        ...state,
        views: state.views.map(view => {
          if (view.id === action.meta.userViewId) {
            const recurringNotifications = view.userViewRecurringNotifications || [];

            recurringNotifications.push({ id: action.payload.id });

            return {
              ...view,
              userViewRecurringNotifications: uniqBy(prop('id'))(recurringNotifications),
            };
          }

          return view;
        }),
      };
    }
    case FETCH_USER_TEAM_VIEWS_FULFILLED: {
      const { data } = action.payload;

      return {
        ...state,
        userTeamViews: data,
      };
    }
    case SAVE_USER_VIEW_FULFILLED:
    case UPDATE_USER_VIEW_OWNER_FULFILLED:
    case SUBSCRIBE_USER_TO_USER_VIEW_FULFILLED: {
      const { id } = action.meta;
      const { data } = action.payload;
      let { views } = state;

      const index = views.findIndex(f => f.id === data.id);

      if (id || index) {
        views = views.filter(f => f.id !== data.id);
        views.splice(index, 0, data);
      } else {
        views = [...state.views, data];
      }

      // if we create a new based on an existing view it is not set as active but
      return {
        ...state,
        views,
        saveDialog: !data?.default_view ? initSaveDialogState : state.saveDialog,
      };
    }
    case UPDATE_USER_VIEW_BY_REALTIME:
      const userViews = updateRows(state.views, action.payload);

      return {
        ...state,
        views: userViews,
      };

    case DELETE_USER_VIEW_FULFILLED: {
      const { data: id } = action.payload;

      return {
        ...state,
        views: state.views.filter(f => f.id !== id),
      };
    }
    case ADD_USER_VIEW_FAVORITE_FULFILLED: {
      const { id } = action.meta;
      const { data } = action.payload;

      const cloned = cloneDeep(state.views);

      const userView = cloned.find(view => view.id === id);
      const favorites = userView.favorites && Array.isArray(userView.favorites) ? [...userView.favorites, data] : [data];

      userView.favorites = favorites;

      const views = updateRows(state.views, userView);

      return {
        ...state,
        views,
        activeViewsByPage: updateActiveViewFromViewUpdate(state.activeViewsByPage, userView),
      };
    }
    case DELETE_USER_VIEW_FAVORITE_FULFILLED: {
      const { id } = action.meta;

      const clonedViews = cloneDeep(state.views);

      const userViewUpdate = clonedViews.find(view => view.id === id);

      userViewUpdate.favorites = [];

      const updated = updateRows(clonedViews, userViewUpdate);

      return {
        ...state,
        views: updated,
        activeViewsByPage: updateActiveViewFromViewUpdate(state.activeViewsByPage, userViewUpdate),
      };
    }
    case CREATE_USER_VIEW_PUBLIC_LINK_FULFILLED: {
      const { userViewId } = action.meta;
      const publicLink = action.payload.data;

      const affectedView = state.views.find(view => view.id === userViewId);

      if (affectedView) {
        affectedView.publicLinks = affectedView.publicLinks || [];
        affectedView.publicLinks.push(publicLink);
      }

      return {
        ...state,
        views: [...state.views],
        activeViewsByPage: updateActiveViewFromViewUpdate(state.activeViewsByPage, affectedView),
      };
    }
    case UPDATE_USER_VIEW_PUBLIC_LINK_FULFILLED: {
      const { userViewId, linkId } = action.meta;

      const affectedView = state.views.find(view => view.id === userViewId);

      if (affectedView) {
        const linkToUpdateIndex = affectedView.publicLinks.findIndex(link => link.id === linkId);

        if (linkToUpdateIndex >= 0) {
          affectedView.publicLinks.splice(linkToUpdateIndex, 1, action.payload.data);
        }
      }

      return {
        ...state,
        views: [...state.views],
        activeViewsByPage: updateActiveViewFromViewUpdate(state.activeViewsByPage, affectedView),
      };
    }
    case UPDATE_USER_VIEW_DIALOG_PROPERTIES: {
      return {
        ...state,
        saveDialog: {
          ...state.saveDialog,
          ...action.payload,
        },
      };
    }
    case DELETE_USER_VIEW_PUBLIC_LINK_FULFILLED: {
      const { userViewId, linkId } = action.meta;

      const affectedView = state.views.find(view => view.id === userViewId);

      if (affectedView) {
        const linkToUpdateIndex = affectedView.publicLinks.findIndex(link => link.id === linkId);

        if (linkToUpdateIndex >= 0) {
          affectedView.publicLinks.splice(linkToUpdateIndex, 1);
        }
      }

      return {
        ...state,
        views: [...state.views],
        activeViewsByPage: updateActiveViewFromViewUpdate(state.activeViewsByPage, affectedView),
      };
    }
    case SET_ACTIVE_USER_VIEW:
    case SET_ACTIVE_USER_VIEW_ONLOAD: {
      const { view, page } = action.payload;
      const viewIdActivated = action?.meta?.viewIdActivated ?? null;

      return {
        ...state,
        ...{
          activeViews: {
            ...state.activeViews,
            [page]: view?.id || null,
          },
          activeViewsByPage: {
            ...state.activeViewsByPage,
            [page]: view,
          },
          saveDialog: {
            ...state.saveDialog,
            viewIdActivated,
          },
        },
      };
    }
    case FETCH_PUBLIC_USER_VIEW_FULFILLED: {
      const { user, token, userView } = action.payload.data;

      return {
        ...state,
        publicSharedView: {
          error: false,
          user,
          token,
          loadedView: userView,
        },
      };
    }
    case FETCH_PUBLIC_USER_VIEW_REJECTED: {
      return {
        ...state,
        publicSharedView: {
          loadedView: {},
          user: null,
          token: '',
          error: true,
        },
      };
    }
    case RESET_USER_VIEW_DIALOG_PROPERTIES: {
      return {
        ...state,
        saveDialog: initSaveDialogState,
      };
    }
    case SET_PAGE_ACTIVE_VIEW: {
      const { view, page } = action.payload;

      return {
        ...state,
        activeViewsByPage: {
          ...state.activeViewsByPage,
          [page]: view,
        },
      };
    }
    case UPDATE_PAGE_ACTIVE_VIEW_DATA: {
      const { viewData, page, updatedDefaultView } = action.payload;
      const { markViewAsHavingChanges } = action.meta || {};

      const stateUpdates = {};

      if (state.activeViewsByPage && state.activeViewsByPage[page]) {
        const activeViewData = {
          ...state.activeViewsByPage[page],
          ...omit(['id'], viewData),
        };

        if (markViewAsHavingChanges) {
          activeViewData.hasUnsavedChanges = true;
        }

        stateUpdates.activeViewsByPage = {
          ...state.activeViewsByPage,
          [page]: activeViewData,
        };
      }

      if (!isNil(updatedDefaultView) && !isEmpty(updatedDefaultView)) {
        stateUpdates.views = updateRows(state.views, updatedDefaultView);
      }

      return {
        ...state,
        ...stateUpdates,
      };
    }
    case CLEAR_PAGE_ACTIVE_VIEW: {
      const { page } = action.payload;

      if (!state.activeViewsByPage[page]) {
        return state;
      }

      const { [page]: activeViewCleared, ...remainingViews } = state.activeViewsByPage;

      return {
        ...state,
        activeViewsByPage: {
          ...remainingViews,
        },
      };
    }
    case UPDATE_USER_VIEW_FULFILLED: {
      const { data: updatedUserView } = action.payload;

      return {
        ...state,
        views: updateRows(state.views, updatedUserView),
        activeViewsByPage: updateActiveViewFromViewUpdate(state.activeViewsByPage, updatedUserView, true),
      };
    }
    case CREATE_USER_VIEW_FULFILLED: {
      const { activeViewForPage } = action.meta;
      const { data: createdUserView } = action.payload;

      const newState = {
        ...state,
        views: [...state.views, createdUserView],
      };

      if (activeViewForPage) {
        newState.activeViewsByPage = {
          ...state.activeViewsByPage,
          [createdUserView.page]: {
            ...createdUserView,
          },
        };
      }

      return newState;
    }
    case CLONE_USER_VIEW_FULFILLED: {
      const { setAsActiveOnCloneSuccess } = action.meta;
      const { data: clonedView } = action.payload;

      const newState = {
        ...state,
        views: [...state.views, clonedView],
      };

      if (setAsActiveOnCloneSuccess) {
        newState.activeViewsByPage = {
          ...state.activeViewsByPage,
          [clonedView.page]: clonedView,
        };
      }

      return newState;
    }
    case SET_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED:
    case SET_PAGE_FILTERS: {
      const currentPageId = getPageIdFromPath(window.location.pathname);

      const sharedPageFilter = getSharedFilterIdFromPageId(currentPageId);

      if (!sharedPageFilter) {
        return state;
      }

      // When filters change in portfolio pages we need to invalidate active views from the other portfolio pages
      const activeViewsThatNeedReset = SHARED_FILTERS_BY_PAGE[sharedPageFilter];

      const currentActiveViews = state.activeViewsByPage || {};

      return {
        ...state,
        activeViewsByPage: omit(activeViewsThatNeedReset, currentActiveViews),
      };
    }
    case TOGGLE_VIEWS_DIALOG: {
      return {
        ...state,
        viewsDialogOpen: action.payload,
      };
    }
    case UPDATE_OUTCOME_MODULE_FILTERS_PENDING: {
      // Reset to default view on filter update
      // Invalidates other active views that use objectives filter

      const currentPageId = getPageIdFromPath(window.location.pathname);
      const defaultView = state.views.find(v => v.page === currentPageId && v.default_view);
      const currentActiveViews = state.activeViewsByPage || {};

      const activeViewsThatNeedReset = SHARED_FILTERS_BY_PAGE[OBJECTIVES_FILTER];

      if (!defaultView) {
        return state;
      }

      return {
        ...state,
        activeViewsByPage: {
          ...omit(activeViewsThatNeedReset, currentActiveViews),
          [currentPageId]: defaultView,
        },
      };
    }
    case UPDATE_OUTCOME_MODULE_FILTERS_FULFILLED: {
      const { updatedUserView } = action.payload;

      if (isNil(updatedUserView) || isEmpty(updatedUserView)) {
        return state;
      }

      const currentActiveViews = state.activeViewsByPage || {};

      const updatedActiveViews = {
        ...currentActiveViews,
        ...updateActiveViewFromViewUpdate(currentActiveViews, updatedUserView, true),
      };

      return {
        ...state,
        views: updateRows(state.views, updatedUserView),
        activeViewsByPage: updatedActiveViews,
      };
    }
    default:
      return state;
  }
};

const operationsReducer = getThunksReducers([
  FETCH_USER_VIEWS,
  CLONE_USER_VIEW,
  SUBSCRIBE_USER_TO_USER_VIEW,
  SHARE_VIEW_ON_INTEGRATION,
]);

const reducer = reduceReducers(initialState, userViewsReducer, ...operationsReducer);

export default reducer;
