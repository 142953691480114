import React from 'react';
import PublicPageTemplateComponent from 'src/design-system/templates/PublicPageTemplate/PublicPageTemplateComponent';
import RequestAccessFormComponent from './RequestAccessForm/RequestAccessFormComponent';

export default ({ organization, error, email, handleEmailValue, handleRequest }) => {
  return (
    <PublicPageTemplateComponent>
      <RequestAccessFormComponent
        organization={organization}
        error={error}
        email={email}
        handleEmailValue={handleEmailValue}
        handleRequest={handleRequest}
      />
    </PublicPageTemplateComponent>
  );
};
