import React from 'react';
import styled from 'styled-components';

import FluidField from 'design-system/molecules/FluidField/index';

import Integrations from 'containers/ProjectLightBox/Form/Integrations';

/*
  this component is atypical because it has its own state and handles its
  own updates. so, we will bypass the FluidField state handling at least for now
*/
const FluidIntegrationField = props => (
  <FluidField
    {...props}
    ignoreMouseEvents
    editorRenderer={({ value, onChange, save, cancel, disableEdit }) => {
      return (
        <IntegrationComponentContainer>
          <Integrations
            selectedProject={props.selectedProject}
            formData={props.value}
            disableEdit={disableEdit}
            // handleUseJiraProgress={props.handleUseJiraProgress}
            updateProject={props.updateProject}
            setProjectIntegrations={props.setProjectIntegrations}
            onLink={() => {}}
          />
        </IntegrationComponentContainer>
      );
    }}
  />
);

const IntegrationComponentContainer = styled.div`
  &&&& {
    position: relative;
    padding-top: 20px;

    > div {
      background: none;
    }
  }
`;

FluidIntegrationField.propTypes = { ...FluidField.propTypes };

export default FluidIntegrationField;
