import React from 'react';
import styled from 'styled-components';

import MultiSelect from 'design-system/atoms/MultiSelect/index';

import { FILTERS_FUNCTIONS } from 'design-system/constants';
import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';

import FieldWrapper from './FieldWrapper';

export default ({ options, value, onChange, fieldFunction, inputComponent, labelOverride, hasGroups, ...props }) => {
  const isEmptyFunction = () => fieldFunction && fieldFunction.key === FILTERS_FUNCTIONS.isEmpty.key;
  const isNotEmptyFunction = () => fieldFunction && fieldFunction.key === FILTERS_FUNCTIONS.isNotEmpty.key;

  if (isEmptyFunction() || isNotEmptyFunction()) return '';

  const InputComponent = inputComponent || MultiSelect;

  return (
    <FieldWrapper input multiselect style={{ flexBasis: 'auto', flexGrow: 1 }}>
      <Wrapper data-testid="filter-multiselect">
        <InputComponent
          showUndefined={props.showUndefined}
          data-test="filter-multiselect"
          options={options}
          hideCreateOption
          optionsMapper={{
            label: labelOverride || 'title',
            value: 'id',
          }}
          value={value}
          onChange={onChange}
          placeholder={TYPE_TO_SEARCH_PLACEHOLDER}
          hasGroups={hasGroups}
          groupOptions={hasGroups ? options : null}
        />
      </Wrapper>
    </FieldWrapper>
  );
};

const Wrapper = styled.div`
  div[type='text'] {
    padding: 0;
  }
`;
