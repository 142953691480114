import moment from 'moment-timezone';

import isNilOrEmpty from 'utils/isNilOrEmpty';

const getDatesFromPastSixMonths = () => ({
  startDate: moment().subtract(6, 'months'),
  endDate: moment(),
});

const getFirstAndLastTargetValuesDates = targetValues => {
  const sortedValuesByDate = targetValues.sort((a, b) => new Date(a.date) - new Date(b.date));
  const firstTargetValue = sortedValuesByDate[0];
  const lastTargetValue = sortedValuesByDate[sortedValuesByDate.length - 1];

  return {
    startDate: firstTargetValue.date,
    endDate: lastTargetValue.date,
  };
};

const getDatesFromTargetValues = targetValues => {
  if (targetValues.length === 1) {
    const target = targetValues[0];

    return {
      startDate: moment(target.date).subtract(30, 'days'),
      endDate: moment(target.date).add(30, 'days'),
    };
  }

  return getFirstAndLastTargetValuesDates(targetValues);
};

const getChartDates = (startDate, endDate, targetValues) => {
  if (startDate && endDate) {
    return { startDate, endDate };
  }

  if (isNilOrEmpty(targetValues)) {
    return getDatesFromPastSixMonths();
  }

  return getDatesFromTargetValues(targetValues);
};

const getClampChartDates = (startDate, endDate, targetValues) => {
  const { startDate: chartStartDate, endDate: chartEndDate } = getChartDates(startDate, endDate, targetValues);

  return {
    startDate: moment(chartStartDate).startOf('day'),
    endDate: moment(chartEndDate).endOf('day'),
  };
};

export default getClampChartDates;
