import React, { createContext, useCallback, useContext, useState } from 'react';

import NewDialog from 'design-system/organisms/NewDialog/index';
import Text from 'design-system/atoms/Text/index';
import DialogHeaderBorderless from 'design-system/molecules/DialogHeaderBorderless/index';


const GenericErrorDialogContext = createContext({});

const GenericErrorDialogProvider = ({ children }) => {
  const [selectedGenericErrorData, setSelectedGenericErrorData] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const openGenericErrorDialog = useCallback(
    data => {
      setSelectedGenericErrorData(data);
      setIsOpen(true);
    },
    [setIsOpen, setSelectedGenericErrorData],
  );

  const closeGenericErrorDialog = useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <GenericErrorDialogContext.Provider value={{ openGenericErrorDialog }}>
      {children}
      <NewDialog open={isOpen} onClose={closeGenericErrorDialog} headerComponent={DialogHeaderBorderless} closeButton>
        <Text>{selectedGenericErrorData?.content}</Text>
      </NewDialog>
    </GenericErrorDialogContext.Provider>
  );
};

/**
 * @function useGenericErrorDialogContext
 *app.
 * hook to use the generic error dialog context
 * needs to be wrapped on GenericErrorDialogProvider
 *
 * @return {Object}
 */
const useGenericErrorDialogContext = () => useContext(GenericErrorDialogContext);

export { GenericErrorDialogProvider };

export default useGenericErrorDialogContext;
