import React from 'react';
import styled from 'styled-components';

import TimelineScheduler from 'containers/TimelineScheduler';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';

import { draftBackgroundColor } from 'design-system/constants/draft';

import ControlsBar from './ControlsBar';
import TaskLightBox from './TaskLightBox';

export default ({
  dataTree,
  tasks,
  localMode,
  toggleLocalMode,
  exportToExcel,
  allCollapsed,
  expandAll,
  collapseAll,
  onInitScheduler,
  loaded,
  lsState,
  dataTypeField,
  renderTooltipText,
  nextPage,
  loadingData,
  scheduler,
}) => {
  if (!loaded) return <PageLoading />;

  return (
    <Wrapper useDraftStyle={localMode}>
      <ControlsBar
        localMode={localMode}
        toggleLocalMode={toggleLocalMode}
        exportToExcel={exportToExcel}
        allCollapsed={allCollapsed}
        expandAll={expandAll}
        collapseAll={collapseAll}
      />
      <TimelineScheduler
        localMode={localMode}
        disableDrag={lsState.displayTimelineBy === 'predicted'}
        renderTooltipText={renderTooltipText}
        showTooltip={lsState.showTooltip}
        displayTimeBy={lsState.displayTimeBy}
        selectedZoom={lsState.selectedZoom}
        singleTimeScale={lsState.singleTimeScale}
        timeWindowStart={lsState.selectedTimeWindow && lsState.timeWindowStart}
        timeWindowEnd={lsState.selectedTimeWindow && lsState.timeWindowEnd}
        dataTypeField={dataTypeField}
        tasks={tasks}
        dataTree={dataTree}
        onInitScheduler={onInitScheduler}
        nextPage={nextPage}
        height={`calc(100vh - (140px + ${lsState.showLegend ? '54px' : '0px'}))`}
      />
      <TaskLightBox scheduler={scheduler} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ useDraftStyle }) => (useDraftStyle ? `background-color: ${draftBackgroundColor}` : '')};
`;
