import React from 'react';
import { BASE_Z_INDEX, DEFAULT_SIDEBAR_WIDTH, MAX_HEIGHT } from '../helpers';

import { TimelineHead, TimelineHeaderContainer, TimelineHeaderTitle, TimelineRow } from './styled';
import TimelineResizableCell from './TimelineResizableCell';
import TimeBar from './TimeBar';

const TimelineHeader = ({ slots, groupTitles, groupsWidths, slotWidth, onResizeGroupHeader }) => (
  <TimelineHead sticky top zIndex={BASE_Z_INDEX + 1}>
    <TimelineRow>
      <TimelineHeaderContainer
        sticky
        left={1}
        top
        width={groupsWidths[0] || DEFAULT_SIDEBAR_WIDTH}
        maxHeight={MAX_HEIGHT}
        zIndex={BASE_Z_INDEX + 1}
        split={!groupTitles?.[1]}
        bordered
      >
        <TimelineResizableCell
          colId="group-auto"
          width={groupsWidths[0] || DEFAULT_SIDEBAR_WIDTH}
          onResize={onResizeGroupHeader(0)}
        >
          <TimelineHeaderTitle>{groupTitles?.[0] ?? ''}</TimelineHeaderTitle>
        </TimelineResizableCell>
      </TimelineHeaderContainer>
      {groupTitles?.[1] && (
        <TimelineHeaderContainer
          sticky
          left={(groupsWidths[0] || DEFAULT_SIDEBAR_WIDTH) + 1}
          top
          width={groupsWidths[1]}
          maxHeight={MAX_HEIGHT}
          zIndex={BASE_Z_INDEX + 1}
          split
          bordered
        >
          <TimelineResizableCell colId="auto" width={groupsWidths[1]} onResize={onResizeGroupHeader(1)}>
            <TimelineHeaderTitle>{groupTitles[1]}</TimelineHeaderTitle>
          </TimelineResizableCell>
        </TimelineHeaderContainer>
      )}
      <TimeBar slots={slots} slotWidth={slotWidth} />
    </TimelineRow>
  </TimelineHead>
);

export default TimelineHeader;
