import React from 'react';
import { Field } from 'redux-form';
import TextField from '@material-ui/core/TextField';

const renderTextField = ({
  input,
  helperText,
  errorLabel,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    helperText={helperText}
    error={(touched && error) || errorLabel}
    errorText={touched && error}
    label={touched && error ? error : errorLabel}
    style={touched && error ? { marginTop: -15 } : {}}
    {...input}
    {...custom}
  />);

export default (props) => {
  return (
    <Field
      component={renderTextField}
      {...props}
    />
  );
};
