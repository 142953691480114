import { last } from 'ramda';

import {
  PENDO_ENTITY_TYPES,
  PAGE_TYPE_OPTIONS,
  TIME_PERIOD_OPTIONS,
  TIME_PERIOD_MAPPER,
  OPTIONS_BY_ENTITY_TYPE,
  ACCOUNTS_KEY,
  ALL_ACCOUNTS_KEY,
} from './constants';

export const getIntegrationEntityFromUrl = url => {
  if (url?.includes(ACCOUNTS_KEY)) {
    const urlElements = url.split('/');

    return last(urlElements) === 'accounts' ? ALL_ACCOUNTS_KEY : ACCOUNTS_KEY;
  }

  return PENDO_ENTITY_TYPES.find(type => url?.includes(type));
};

export const getInitialTimePeriod = originalTimePeriod =>
  TIME_PERIOD_OPTIONS.find(opt => opt.key === TIME_PERIOD_MAPPER[originalTimePeriod]) || TIME_PERIOD_OPTIONS[0];

export const getInitialMetricType = (originalMetricType, url) => {
  const integrationEntity = getIntegrationEntityFromUrl(url);

  const options = OPTIONS_BY_ENTITY_TYPE[integrationEntity] || PAGE_TYPE_OPTIONS;

  return options.find(opt => opt.key === originalMetricType) || options[0];
};
