import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FiltersFooter = ({ leftContent, rightContent }) => {
  return (
    <ButtonsContainer>
      {leftContent}
      <ActionsButtonsContainer>{rightContent}</ActionsButtonsContainer>
    </ButtonsContainer>
  );
};

FiltersFooter.propTypes = {
  leftContent: PropTypes.object,
  rightContent: PropTypes.object,
};

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px 10px;
  border-top: 1px solid ${({ theme }) => theme.palette.white};
  box-shadow: 0px -2px 12px 0px ${({ theme }) => theme.palette.background.lightGrey};
`;

const ActionsButtonsContainer = styled.div`
  margin-left: auto;
  padding-left: 50px;
`;

export default FiltersFooter;
