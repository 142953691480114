import axios from 'axios';

import { createThunk } from 'utils/store/thunk';
import { LOAD_ESTIMATES_TAB } from './types';

const loadEstimatesTab = () => async (dispatch, getState) => {
  return dispatch(
    createThunk(LOAD_ESTIMATES_TAB, async () => {
      const projectId = getState()?.projectLightbox?.projectData?.id;

      if (!projectId) {
        throw Error(`Couldn't fetch estimates on lightbox with no project id`);
      }

      const estimates = await axios.get(`/api/projects/${projectId}/estimates`).then(response => response.data);

      return { estimates };
    }),
  );
};

export { loadEstimatesTab };
