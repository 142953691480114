import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

const DragonIconButton = ({ variant, type, icon, secondaryIcon, onClick, ...otherProps }) => {
  const props = {
    fill: type === 'fill',
    transparent: type === 'transparent',
    variant,
    ...otherProps,
  };

  return (
    <ButtonWrapper {...props}>
      <Button onClick={onClick} data-testid="dragon-icon-button">
        {icon}
        {secondaryIcon && secondaryIcon}
      </Button>
    </ButtonWrapper>
  );
};

const Button = styled.button`
  &&&& {
    width: 100%;
    height: 100%;

    border: none;
    border-radius: 50%;

    padding: 7px;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

const ButtonWrapper = styled.div`
  &&&& {
    width: 38px;
    height: 38px;

    border: none;
    border-radius: 50%;

    padding: 5px;
    box-sizing: border-box;

    &:hover {
      background: ${({ theme }) => theme.palette.newLayout.background.lightestDisable};
    }

    ${({ fill, theme, variant }) =>
      fill &&
      `
      ${Button} {
        background: ${variant ? theme.palette.newLayout.background[variant] : theme.palette.primary.main};
      }

      svg path {
        fill: ${theme.palette.newLayout.background.white};
      }

      &:hover {
        background: ${
          variant ? theme.palette.newLayout.background[`lightest${capitalize(variant)}`] : theme.palette.primary.lightestMain
        };
      }
    `}

    ${({ transparent, theme, variant }) =>
      transparent &&
      `
      width: 34px;
      height: 34px;
      background: transparent;

      padding: 0;

      ${Button} {
        background: transparent;
      }

      ${
        variant &&
        `
        svg path {
          fill: ${theme.palette.newLayout.background[variant]};
        }

        &:hover {
          ${Button} {
            background: ${theme.palette.newLayout.background[`lightest${capitalize(variant)}`]};
          }
        }
      `
      }
    `}
  }
`;

DragonIconButton.propTypes = {
  variant: PropTypes.oneOf(['primary', 'disable', 'secondary']),
  type: PropTypes.oneOf(['fill', 'transparent', 'default']).isRequired,
  icon: PropTypes.any.isRequired,
  onClick: PropTypes.func,
};

export default DragonIconButton;
