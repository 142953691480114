import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BaseLayout from 'design-system/organisms/BaseLayout/index';

import theme from 'design-system/theme';

import { openProjectLightbox } from 'store/projectLightbox';
import { openObjectiveDrawer } from 'store/objectives';

import { updateNodesLayout, updateEdgesLayout } from 'features/whiteboard/store/actions';
import {
  isApplyingFiltersOnWhiteboardLoading,
  selectProjectAndMetadataForIdeasWhiteboard,
  selectWhiteboardEdgesLayout,
  selectWhiteboardLayoutConfig,
  selectWhiteboardNodesLayout,
  selectWhiteboardUserActionsMeta,
} from 'features/whiteboard/store/selectors';

import { FeatureFlags } from '@dragonboat/config';

import { PAGE_HEADER_HEIGHT } from 'constants/common';
import { PORTFOLIO_CANVAS_PAGE } from 'constants/filters';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useLightboxesControlContext from 'hooks/lightboxes/useLightboxesControl';
import useSystemFields from 'hooks/useSystemFields';
import CreateDrawer from 'features/Canvas/components/CreateDrawer';
import useCreateDrawer from 'features/Canvas/components/CreateDrawer/useCreateDrawer';

import useLoadProjectsForWhiteboard from './hooks/useLoadProjectsForWhiteboard';
import useHandleConfigChanges from './hooks/useHandleConfigChanges';
import CanvasControlsBar from 'features/Canvas/components/CanvasControlsBar';
import useUpdateProjectOnWhiteboard from 'routes/Ideas/IdeasWhiteboard/hooks/useUpdateProjectOnWhiteboard';

const pageId = PORTFOLIO_CANVAS_PAGE;

const PAGE_TOOLBAR_HEIGHT = 68;

const IdeasWhiteboard = ({ isReadOnlyView = false, viewsDropdownTabs }) => {
  const ref = useRef();
  const [whiteboardInitialized, setWhiteboardInitialized] = useState(false);
  const dispatch = useDispatch();

  useLoadProjectsForWhiteboard();
  useUpdateProjectOnWhiteboard();

  const hasBet = useFeatureFlags([FeatureFlags.HAS_BET]);
  const dataForWhiteboard = useSelector(selectProjectAndMetadataForIdeasWhiteboard);
  const isLoadingData = useSelector(isApplyingFiltersOnWhiteboardLoading);
  const whiteboardLayoutConfig = useSelector(selectWhiteboardLayoutConfig);
  const whiteboardNodesLayout = useSelector(selectWhiteboardNodesLayout);
  const whiteboardEdgesLayout = useSelector(selectWhiteboardEdgesLayout);
  const userActionsMeta = useSelector(selectWhiteboardUserActionsMeta);

  const { displayNodes, displayPreferences, relationsConfig } = whiteboardLayoutConfig;

  const [getSystemFieldName] = useSystemFields();

  const { canView } = usePermissions();

  const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

  const { openMetricLightbox } = useLightboxesControlContext();

  const { openCreateDrawer, ...createDrawerProps } = useCreateDrawer();

  const {
    onToggleOkrRelations,
    onToggleOkrMetricsRelations,
    onToggleOkrProjectsRelations,
    onTogglePortfolioItemsRelations,
    onToggleTimeframeRelations,
    onToggleBoardRelations,
    onToggleMetricRelations,
    handleDisplayNodesChange,
    onToggleHighlightHealth,
    onToggleOwnerAvatar,
    onToggleProgress,
    onToggleStatus,
  } = useHandleConfigChanges();

  const nodeTypeLabels = useMemo(
    () => ({
      idea: getSystemFieldName('idea'),
      initiative: getSystemFieldName('initiative'),
      bet: getSystemFieldName('bet'),
      objective: getSystemFieldName('objective'),
      keyResult1: getSystemFieldName('keyResult1'),
      keyResult2: getSystemFieldName('keyResult2'),
      timeframe: getSystemFieldName('timeframe'),
      metric: getSystemFieldName('metric'),
    }),
    [],
  );

  useEffect(() => {
    if (whiteboardInitialized && !isLoadingData) {
      ref.current.contentWindow.postMessage(
        {
          type: 'layout',
          displayNodes,
        },
        '*',
      );
    }
  }, [displayNodes, whiteboardInitialized, isLoadingData]);

  useEffect(() => {
    if (whiteboardInitialized && !isLoadingData) {
      ref.current.contentWindow.postMessage(
        {
          type: 'layout',
          displayPreferences,
        },
        '*',
      );
    }
  }, [displayPreferences, whiteboardInitialized, isLoadingData]);
  useEffect(() => {
    if (whiteboardInitialized && !isLoadingData) {
      ref.current.contentWindow.postMessage(
        {
          type: 'layout',
          relationsConfig,
        },
        '*',
      );
    }
  }, [relationsConfig, whiteboardInitialized, isLoadingData]);

  useEffect(() => {
    if (whiteboardInitialized) {
      ref.current.contentWindow.postMessage(
        {
          type: 'layout',
          nodesLayout: whiteboardNodesLayout,
        },
        '*',
      );
    }
  }, [whiteboardNodesLayout, whiteboardInitialized]);

  useEffect(() => {
    if (whiteboardInitialized) {
      ref.current.contentWindow.postMessage(
        {
          type: 'layout',
          edgesLayout: whiteboardEdgesLayout,
        },
        '*',
      );
    }
  }, [whiteboardEdgesLayout, whiteboardInitialized]);

  useEffect(() => {
    if (whiteboardInitialized) {
      ref.current.contentWindow.postMessage(
        {
          type: 'nodeTypeLabels',
          nodeTypeLabels,
        },
        '*',
      );
    }
  }, [nodeTypeLabels, whiteboardInitialized]);

  useEffect(() => {
    if (whiteboardInitialized && !isLoadingData) {
      ref.current.contentWindow.postMessage(
        {
          type: 'data',
          ...dataForWhiteboard,
        },
        '*',
      );
    }
  }, [dataForWhiteboard, whiteboardInitialized, isLoadingData]);

  useEffect(() => {
    if (whiteboardInitialized) {
      ref.current.contentWindow.postMessage(
        {
          type: 'userActionsMeta',
          userActionsMeta,
        },
        '*',
      );
    }
  }, [userActionsMeta, whiteboardInitialized]);

  useEffect(() => {
    const handleMessage = event => {
      const data = event.data || {};

      switch (data.type) {
        case 'whiteboardInitialized':
          setWhiteboardInitialized(true);
          break;
        case 'openProject':
          dispatch(openProjectLightbox(data?.projectId));
          break;
        case 'openOkr':
          dispatch(openObjectiveDrawer(data?.okrId, data?.okrType));
          break;
        case 'openMetric':
          openMetricLightbox(data?.metricId);
          break;
        case 'createDragonboatObject':
          openCreateDrawer(data?.id, data?.title);
          break;
        case 'nodesLayout':
          dispatch(updateNodesLayout(data.nodesLayout));
          break;
        case 'edgesLayout':
          dispatch(updateEdgesLayout(data.edgesLayout));
          break;
        default:
          break;
      }
    };

    window.addEventListener('message', handleMessage, false);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const onAddTextBoxClick = () => {
    if (whiteboardInitialized) {
      ref.current.contentWindow.postMessage(
        {
          type: 'addTextBox',
        },
        '*',
      );
    }
  };

  return (
    <BaseLayout
      displayBorderOnToolbar
      withoutContentMargin
      background={theme.palette.background.secondary}
      toolbarComponent={
        shouldDisplayControlsBar ? (
          <CanvasControlsBar
            pageId={pageId}
            onAddTextBoxClick={onAddTextBoxClick}
            relationsConfig={relationsConfig}
            displayPreferences={displayPreferences}
            displayNodes={displayNodes}
            onToggleOkrRelations={onToggleOkrRelations}
            onToggleOkrMetricsRelations={onToggleOkrMetricsRelations}
            onToggleOkrProjectsRelations={onToggleOkrProjectsRelations}
            onTogglePortfolioItemsRelations={onTogglePortfolioItemsRelations}
            onToggleTimeframeRelations={onToggleTimeframeRelations}
            onToggleBoardRelations={onToggleBoardRelations}
            onToggleMetricRelations={onToggleMetricRelations}
            handleDisplayNodesChange={handleDisplayNodesChange}
            onToggleHighlightHealth={onToggleHighlightHealth}
            onToggleOwnerAvatar={onToggleOwnerAvatar}
            onToggleProgress={onToggleProgress}
            onToggleStatus={onToggleStatus}
            hasBet={hasBet}
            viewsDropdownTabs={viewsDropdownTabs}
          />
        ) : null
      }
    >
      <iframe
        id="ideas-whiteboard"
        ref={ref}
        title="ideas whiteboard"
        style={{ width: '100%', height: `calc(100vh - ${PAGE_HEADER_HEIGHT}px - ${isReadOnlyView ? 0 : PAGE_TOOLBAR_HEIGHT}px)` }}
        src="/render-whiteboard"
      />

      <CreateDrawer {...createDrawerProps} />
    </BaseLayout>
  );
};

export default IdeasWhiteboard;
