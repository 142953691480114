/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useRef } from 'react';
import isEmpty from 'lodash/isEmpty';

import DndDropLayout from 'design-system/molecules/DndDropLayout/index';
import DndMenuItem from 'design-system/molecules/DndMenuItem/index';

import { checkedObject } from 'design-system/utils';
import {
  eventKeyIsEnter,
  eventKeyIsUpArrow,
  eventKeyIsDownArrow,
  eventKeyIsRightArrow,
  eventKeyIsLeftArrow,
  findItem,
  parseItemIdAndPath,
  getNextElementToBeFocused,
  getPreviousElementToBeFocused,
} from './helpers';

export default ({
  rootList,
  items,
  parentId,
  selectedItems = {},
  openItems = {},
  draggable,
  checkable,
  colorable,
  isSubTree,
  onChangeColor,
  onGroupOpenClose,
  onSelectItem,
  onClickItem,
  showIndeterminate,
  type,
  isDragging,
  onDraggingStyle,
  renderOnOverOverlay,
  checkedByDefault,
  showTooltip,
  itemRightActions = [],
  renderCustomItem,
  renderCustomEmptyOptions,
  useHierarchy,
  showActionsOnHover,
  onOpen,
  path = [],
  itemHeight,
  rootHeight,
  avoidVirtualization,
  focusedRef,
  onSelectItemWithKeyboard,
  parentListRef,
}) => {
  const listRef = useRef();
  const handleExpandCollapse = (...args) => {
    onOpen(...args);
    listRef?.current?.resetAfterIndex(0);
  };
  const _getSelectedItems = itemId => {
    if (typeof selectedItems === 'object' && checkedObject(items, selectedItems)) return true;
    if (typeof selectedItems === 'boolean') return selectedItems;

    return selectedItems[itemId];
  };
  const showCustomEmptyMessage = isEmpty(items) && renderCustomEmptyOptions;

  const onItemKeyDown = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    if (!onSelectItemWithKeyboard) return;

    const { itemId, itemPath } = parseItemIdAndPath(id);

    const item = findItem(items, itemId);

    // Move focus to the next element
    if (eventKeyIsDownArrow(e)) {
      const elementToBeFocused = getNextElementToBeFocused(e, itemPath);

      elementToBeFocused?.focus();
    }

    // Move focus to the previous element
    if (eventKeyIsUpArrow(e)) {
      const elementToBeFocused = getPreviousElementToBeFocused(e);

      elementToBeFocused?.focus();
    }

    // Open group
    if (eventKeyIsRightArrow(e)) {
      if (item?.children?.length > 0 || item?.menuIconComponent) {
        const elementId = e?.currentTarget?.id;

        // For items with menuIconComponent we need to manually click the icon since it handles its own expand/collapse
        if (item?.menuIconComponent) {
          e?.currentTarget?.querySelector('[data-groupicon="true"]')?.click();
        }

        handleExpandCollapse(itemPath, true);

        if (!elementId) return;

        // Use requestAnimationFrame to re-focus the active element after the DOM updates (due to the list being re-rendered)
        requestAnimationFrame(() => {
          const element = document.getElementById(elementId);

          if (element) {
            element.focus();
          }
        });
      }
    }

    // Close group
    if (eventKeyIsLeftArrow(e)) {
      if (item?.children?.length > 0 || item?.menuIconComponent) {
        const elementId = e?.currentTarget?.id;

        // For items with menuIconComponent we need to manually click the icon since it handles its own expand/collapse
        if (item?.menuIconComponent) {
          e?.currentTarget?.querySelector('[data-groupicon="true"]')?.click();
        }

        handleExpandCollapse(itemPath, false);

        if (!elementId) return;

        // Use requestAnimationFrame to re-focus the active element after the DOM updates (due to the list being re-rendered)
        requestAnimationFrame(() => {
          const element = document.getElementById(elementId);

          if (element) {
            element.focus();
          }
        });
      }
    }

    // Select item
    if (eventKeyIsEnter(e)) {
      onSelectItemWithKeyboard(item);
    }
  };

  const renderItemByIndex = index => {
    const item = items[index];

    if (!item) {
      return null;
    }
    return renderItem(item, index);
  };

  const renderItem = (item, index) => {
    return (
      <DndMenuItem
        focusedRef={index === 0 ? focusedRef : null}
        key={item.id}
        item={item}
        index={index}
        rootList={rootList}
        parentId={parentId}
        selectedItems={_getSelectedItems(item.id)}
        draggable={draggable}
        checkable={checkable}
        colorable={colorable}
        isSubTree={isSubTree}
        onSelectItem={onSelectItem}
        onClickItem={onClickItem}
        onChangeColor={onChangeColor}
        onGroupOpenClose={onGroupOpenClose}
        showIndeterminate={showIndeterminate}
        checkedByDefault={checkedByDefault}
        showTooltip={showTooltip}
        itemRightActions={itemRightActions}
        renderCustomItem={renderCustomItem}
        useHierarchy={useHierarchy}
        showActionsOnHover={showActionsOnHover}
        onOpen={handleExpandCollapse}
        path={path}
        openItems={openItems}
        itemHeight={itemHeight}
        avoidVirtualization={avoidVirtualization}
        onItemKeyDown={onItemKeyDown}
        onSelectItemWithKeyboard={onSelectItemWithKeyboard}
        parentListRef={listRef}
      />
    );
  };

  return (
    <DndDropLayout
      id={parentId}
      rootList={rootList}
      draggable={draggable}
      type={type || parentId}
      isDragging={isDragging}
      onDraggingStyle={onDraggingStyle}
      renderOnOverOverlay={renderOnOverOverlay}
      renderItem={renderItem}
      renderItemByIndex={renderItemByIndex}
      items={items}
      openItems={openItems}
      path={path}
      listRef={rootList ? parentListRef : listRef}
      itemHeight={itemHeight}
      rootHeight={rootHeight}
      avoidVirtualization={avoidVirtualization}
      focusedRef={focusedRef}
    >
      {showCustomEmptyMessage && renderCustomEmptyOptions()}
    </DndDropLayout>
  );
};
