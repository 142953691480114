import { useState } from 'react';
import { useDispatch } from 'react-redux';

import useMetadataForOptions from 'hooks/metadata/useMetadataForOptions';
import { USERS } from 'constants/common';
import { createMetricFromCanvas } from 'features/Canvas/store/thunks';

const METADATA_KEYS = [USERS];

const initialFormValues = {
  name: null,
  owner: '',
  details: '',
};

const processDataForCreateMetric = formValues => {
  const { name, owner, details, objectTitle } = formValues;

  return {
    name: name || objectTitle,
    ...(details ? { details } : {}),
    ...(owner ? { owner_id: owner } : {}),
  };
};

const useCreateMetricForm = (type, objectTitle, textLabelId, onClose) => {
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState(initialFormValues);

  const {
    filteredOptions: { users },
  } = useMetadataForOptions({
    metadataKeys: METADATA_KEYS,
  });

  const handleFormValueChange = (property, value) => setFormValues(prevFormValues => ({ ...prevFormValues, [property]: value }));

  const onCloseCallback = () => setFormValues(initialFormValues);

  const handleSave = () => {
    dispatch(createMetricFromCanvas(processDataForCreateMetric({ ...formValues, objectTitle }), textLabelId));

    onClose(onCloseCallback);
  };

  return {
    objectTitle,
    formValues,
    ownerOptions: users,
    handleFormValueChange,
    handleSave,
    onCloseCallback,
  };
};

export default useCreateMetricForm;
