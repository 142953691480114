import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { sum } from 'ramda';

import { getProjectStories as getProjectStoriesAction } from 'store/integrations';
import useProjectsForGantt from 'hooks/timeline/useProjectsForGantt';
import useCollapsedTasks from 'hooks/timeline/useCollapsedTasks';
import useTodayMarker from 'hooks/timeline/useTodayMarker';
import useInitTimelineGantt from 'hooks/timeline/useInitTimelineGantt';
import useLazyLoadStories from 'hooks/timeline/useLazyLoadStories';
import useProjectsLocalSearch from 'hooks/projects/useProjectsLocalSearch';

import useTimelineLightboxState from '../../../hooks/useTimelineLightboxState';
import useTimelineLightboxData from '../../../hooks/useTimelineLightboxData';
import ganttConfigs from '../../../helpers/ganttConfigs';

const useTimeline = ({ pageId, openItems = [], portfolioMode = false, displayLayer }) => {
  const dispatch = useDispatch();

  const {
    lsState,
    orgIntegrations,
    search,
    zoom,
    selectedGroup,
    groupByOptions,

    getColumns,
    setZoom,
    setSearch,
    updateState,
    setSelectedGroup,
  } = useTimelineLightboxState({ portfolioMode });

  const { projects, ideas, initiatives, bets, isLoaded, totalByLayer } = useTimelineLightboxData({ displayLayer });

  const updateTodayMaker = useTodayMarker();

  const [filterProjects] = useProjectsLocalSearch(projects, search);

  const [tasks] = useProjectsForGantt(pageId, {
    groupBy: [selectedGroup],

    projects: filterProjects ? projects.filter(filterProjects) : projects,
    ideas: filterProjects ? ideas.filter(filterProjects) : ideas,
    initiatives: filterProjects ? initiatives.filter(filterProjects) : initiatives,
    bets: filterProjects ? bets.filter(filterProjects) : bets,

    portfolioMode,
    showEstimates: !portfolioMode,
    showTasks: !portfolioMode,
    groupingOptions: {
      withHierarchy: true,
    },
    layer: displayLayer,

    // always show stories if the integration exists
    showStories: {
      jira: orgIntegrations.some(i => i.integrationType === 'JIRA'),
      clubhouse: orgIntegrations.some(i => i.integrationType === 'clubhouse'),
      github: orgIntegrations.some(i => i.integrationType === 'github'),
      azuredevops: orgIntegrations.some(i => i.integrationType === 'azuredevops'),
    },
    showId: false,
    ignoreProjectsWithNoParent: true,
    showJiraKey: false,
    coloredGroups: true,
    searchStr: search,
  });

  const onInitGantt = ganttInstance => {
    const columns = getColumns();

    ganttConfigs(ganttInstance, zoom, columns);

    ganttInstance.attachEvent('onTaskOpened', id => {
      const task = ganttInstance.getTask(id);

      if (task.dbType === 'project') {
        loadProjectStories(id);
      }
    });
  };
  const onRefreshGantt = (ganttInstance, data) => {
    const columns = getColumns();

    ganttConfigs(ganttInstance, zoom, columns);
    updateTodayMaker(gantt);
  };

  const { gantt, refreshGantt, ganttRef, reloadGantt } = useInitTimelineGantt({
    lsState,
    onRefresh: onRefreshGantt,
    data: tasks,
    ganttInitFuncs: [onInitGantt],
  });

  const _mapGanttData = opens => task => {
    const isIdOpen = opens && opens.includes(String(task.id));
    const isEntityIdOpen = openItems.includes(String(task.entityId));

    const open = isIdOpen || isEntityIdOpen;

    return {
      ...task,
      open,
      $open: open,
    };
  };

  const ganttTasks = useMemo(
    () => ({
      data: tasks.data.map(_mapGanttData(lsState.openTasks)),
      links: tasks.links,
    }),
    [tasks, lsState.openTasks],
  );

  const loadProjectStories = useLazyLoadStories(gantt, ganttTasks, lsState);

  useCollapsedTasks(gantt, updateState, lsState);

  const toggleZoom = () => setZoom(!zoom);

  const getProjectStories = taskId => dispatch(getProjectStoriesAction(taskId));

  const totalProjectsByLayer = useMemo(() => sum(Object.values(totalByLayer)), [totalByLayer]);

  const onGanttContainerMounted = ganttContainerDiv => {
    if (ganttContainerDiv) {
      reloadGantt();
    }
  };

  return {
    ganttTasks,
    totalProjectsByLayer,
    ganttRef,
    search,
    isLoaded,
    zoom,
    lsState,
    gantt,
    selectedGroup,
    groupByOptions,

    refreshGantt,
    setSelectedGroup,
    setSearch,
    onGanttContainerMounted,
    toggleZoom,
    getProjectStories,
  };
};

export default useTimeline;
