import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import { DEFAULT_SETTINGS_NAME } from 'config';
import { LEADER_USER, OWNER_USER } from '@dragonboat/permissions';

import DragonTable from 'design-system/organisms/DragonTable/index';
import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';
import getSystemFieldName from 'utils/getSystemFieldName';
import onlyVisibleForUsers from 'utils/onlyVisibleForUsers';

import useRename from './hooks/useRename';

const Rename = ({ rows }) => {
  const { systemFields, user, updateSystemFieldsName } = useRename();
  const maxRows = rows.length;

  return (
    <>
      <Grid item>
        <Text variant="h6">Rename</Text>
      </Grid>
      <Grid item xs={12}>
        <Container maxRows={maxRows}>
          <DragonTable rows={rows} disableSorting>
            <DragonTable.TextColumn header="Default name" map={k => DEFAULT_SETTINGS_NAME[k]} width={100} flexGrow={1.0} />
            <DragonTable.TextColumn
              header="Rename to"
              map={k => getSystemFieldName(k, systemFields)}
              onChange={(key, title) => updateSystemFieldsName({ key, defaultTitle: DEFAULT_SETTINGS_NAME[key], title })}
              editable={onlyVisibleForUsers(OWNER_USER, LEADER_USER)(user.role_id)}
              width={100}
              flexGrow={1.0}
            />
          </DragonTable>
        </Container>
      </Grid>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  height: ${({ maxRows }) => `calc(50px + (40px * ${maxRows}))`};
  margin-bottom: ${spacing(3)}px;
  overflow: hidden;
`;

export default Rename;
