import React, { useState } from 'react';

import NewDialog from 'design-system/organisms/NewDialog/index';


import useSystemFields from 'hooks/useSystemFields';

import HeatmapLightboxChart from './HeatmapLightboxChart';
import HeatmapLightboxHeader from 'routes/Ideas/IdeasPDLCView/components/HeatmapLightboxHeader';

const HeatmapLightbox = ({ open, onClose }) => {
  const [getSystemFieldName] = useSystemFields();
  const [isChart100, setIsChart100] = useState(false);

  return (
    <NewDialog
      header={
        <HeatmapLightboxHeader
          onClose={onClose}
          title={`${getSystemFieldName('pdlc')} Heatmap`}
          isChart100={isChart100}
          onChartOptionClick={() => setIsChart100(prevState => !prevState)}
        />
      }
      open={open}
      onClose={onClose}
      width="50vw"
      closeButton
    >
      <HeatmapLightboxChart isChart100={isChart100} />
    </NewDialog>
  );
};

export default HeatmapLightbox;
