import React, { useEffect } from 'react';
import styled from 'styled-components';
import Input from '@material-ui/core/Input';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';
import PropTypes from 'prop-types';

import ListPopper from './components/ListPopper';
import EmptyOption from './components/EmptyOption';
import useAutocomplete from './hooks/useAutocomplete';

const DOWN_ARROW_KEY_CODE = 40;

const Autocomplete = ({
  name,
  searchValue,
  options,
  onSelectOption,
  onCloseList,
  placeholder = TYPE_TO_SEARCH_PLACEHOLDER,
  disabled = false,
  focusOnInit = false,
  onGroupClickCallback,
  onValueChange,
  registerExpandAllCallback,
  registerCollapseAllCallback,
  canAddNew,
  renderPopperOnPortal,
  ...props
}) => {
  const {
    showList,
    inputRef,
    containerRef,
    popperRef,
    focusedRef,
    handleChange,
    handleFocus,
    handleItemClick,
    handleClickOutside,
    handleGroupClick,
  } = useAutocomplete({ onValueChange, onSelectOption, onGroupClickCallback, onCloseList });

  const handleKeyDown = event => {
    const key = event?.keyCode || event?.charCode;

    const isDownArrowKey = key === DOWN_ARROW_KEY_CODE;

    // Focus first item on list when pressing Down arrow key
    if (isDownArrowKey) {
      if (focusedRef?.current) {
        focusedRef?.current.focus();
      }
    }
  };

  useEffect(() => {
    if (focusOnInit) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickOutside}>
      <Container ref={containerRef} {...props}>
        <StyledInput
          type="text"
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          inputRef={inputRef}
          disabled={disabled}
          data-testid={`${name}-autocomplete`}
        />
        <ListPopper
          open={!!showList}
          anchorEl={containerRef?.current}
          popperRef={popperRef}
          items={options}
          onClickItem={handleItemClick}
          onGroupOpenClose={handleGroupClick}
          width={inputRef.current?.offsetWidth}
          registerExpandAllCallback={registerExpandAllCallback}
          registerCollapseAllCallback={registerCollapseAllCallback}
          focusedRef={focusedRef}
          onSelectItemWithKeyboard={handleItemClick}
          disablePortal={!renderPopperOnPortal}
          renderCustomEmptyOptions={() => (
            <EmptyOption
              showCreateOption={canAddNew}
              label={searchValue}
              onClick={() => onSelectOption({ isNew: true, title: searchValue })}
            />
          )}
        />
      </Container>
    </ClickAwayListener>
  );
};

const Container = styled.div`
  position: relative;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

Autocomplete.propTypes = {
  onValueChange: PropTypes.func.isRequired,
};

export default Autocomplete;
