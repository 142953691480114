import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import TextFieldNoBorder from 'design-system/atoms/TextFieldNoBorder/index';


import { isEnter, isTab } from 'design-system/utils/keyboard';
import formatPercent from 'design-system/utils/formatPercent';
import isNumeric from 'utils/isNumeric';
import theme from 'design-system/theme';

import { PROGRESS_PERCENTAGE_DECIMAL_PLACES } from 'constants/common';

const MANUAL_INPUT_WIDTH = 45;

export default function ProgressValueField({
  canEditProgressText,
  disabledEditableText,
  onChange,
  progressValue,
  updateIsEditing,
}) {
  const [rawProgress, setRawProgress] = useState(progressValue);
  const [inputValue, setInputValue] = useState(formatPercent(rawProgress, PROGRESS_PERCENTAGE_DECIMAL_PLACES));

  const innerValue = useMemo(() => {
    const formatted = formatPercent(progressValue, PROGRESS_PERCENTAGE_DECIMAL_PLACES);

    if (progressValue !== rawProgress) {
      setRawProgress(progressValue);
      setInputValue(formatted);
    }

    return formatted;
  }, [progressValue]);

  const handleChangeProgressText = e => {
    const progress = e.target.value.replace('%', '');
    const updatedPercentage = parseFloat(progress) / 100;

    if (updateIsEditing) {
      updateIsEditing(true);
    }

    setInputValue(progress);

    if (isNumeric(updatedPercentage)) {
      setRawProgress(updatedPercentage);
    }
  };

  const handleUpdateProgress = updateValue => {
    if (progressValue === updateValue) {
      return;
    }

    setInputValue(formatPercent(updateValue, PROGRESS_PERCENTAGE_DECIMAL_PLACES));
    onChange(updateValue);
  };

  return canEditProgressText ? (
    <TextFieldNoBorder
      $width={MANUAL_INPUT_WIDTH}
      disabled={disabledEditableText}
      InputProps={{
        style: { fontSize: `${theme.typography.fontSize}px` },
      }}
      key="progressValue"
      onChange={handleChangeProgressText}
      onKeyDown={e => {
        e.stopPropagation();

        if (isTab(e) || isEnter(e)) {
          handleUpdateProgress(rawProgress);
        }
      }}
      onBlur={() => handleUpdateProgress(rawProgress)}
      rowsMax={1}
      value={inputValue}
    />
  ) : (
    <ProgressLabel>{innerValue}</ProgressLabel>
  );
}

const ProgressLabel = styled(Typography)`
  width: 38px;
  text-align: right;
`;
