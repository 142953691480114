import reduceReducers from 'reduce-reducers';
import { append, pick } from 'ramda';

import {
  UPDATE_GRID_LIFECYCLE,
  FETCH_LIFECYCLES_GRID_DATA,
  FETCH_LIFECYCLES_GRID_DATA_FULFILLED,
  CREATE_GRID_LIFECYCLE,
  SAVE_GRID_NEW_LIFECYCLE,
  SAVE_GRID_NEW_LIFECYCLE_FULFILLED,
  REMOVE_GRID_UNSAVED_LIFECYCLE,
  DELETE_GRID_LIFECYCLE,
  DELETE_GRID_LIFECYCLE_FULFILLED,
  CREATE_LIFECYCLE_ROADMAP_FULFILLED,
  DELETE_LIFECYCLE_ROADMAP_FULFILLED,
  BULK_DELETE_LIFECYCLE_ROADMAP_FULFILLED,
  SWITCH_LIFECYCLES_GRID_ROW_ORDER_PENDING,
  SWITCH_LIFECYCLES_GRID_ROW_ORDER_REJECTED,
  SWITCH_LIFECYCLES_GRID_ROW_ORDER_FULFILLED,
  CHANGE_ROW_HEIGHT_LIFECYCLE_GRID,
  CHANGE_HIDE_ARCHIVED_LIFECYCLE_GRID,
  UPDATE_GRID_LIFECYCLE_PENDING,
} from './types';
import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';
import sortByRank from 'utils/sortByRank';

import { BASE_ROW_HEIGHT, LIFECYCLE_ROADMAP_FIELD } from '../helpers/constants';

const initialState = {
  data: [],
  rowHeight: BASE_ROW_HEIGHT,
  hideArchived: false,
  operations: bulkThunkInitialState([UPDATE_GRID_LIFECYCLE, FETCH_LIFECYCLES_GRID_DATA]),
};

const lifecyclesDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIFECYCLES_GRID_DATA_FULFILLED:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_GRID_LIFECYCLE_PENDING:
      const updatedLifecycle = action.payload;

      return {
        ...state,
        data: state.data?.map(lifecycle => {
          if (updatedLifecycle?.id === lifecycle.id) {
            return { ...lifecycle, ...updatedLifecycle };
          }
          return lifecycle;
        }),
      };
    case CREATE_GRID_LIFECYCLE:
      const { hasUnsavedItem, data } = state;

      if (hasUnsavedItem) return state;

      return {
        ...state,
        data: [{ isNew: true }, ...data],
        hasUnsavedItem: true,
      };
    case SAVE_GRID_NEW_LIFECYCLE_FULFILLED:
      return {
        ...state,
        data: state.data.map(lifecycle => {
          if (lifecycle?.isNew) {
            const createdLifecycle = {
              ...action.payload,
              [LIFECYCLE_ROADMAP_FIELD]: [],
            };

            return createdLifecycle;
          }
          return lifecycle;
        }),
        hasUnsavedItem: false,
      };
    case REMOVE_GRID_UNSAVED_LIFECYCLE:
      return {
        ...state,
        data: state.data.filter(lifecycle => !lifecycle.isNew),
        hasUnsavedItem: false,
      };
    case DELETE_GRID_LIFECYCLE_FULFILLED:
      return {
        ...state,
        data: state.data.filter(lifecycle => lifecycle?.id !== action.payload),
      };
    case CREATE_LIFECYCLE_ROADMAP_FULFILLED: {
      const newLifecycleRoadmap = action.payload;
      const updatedData = state.data.map(item => {
        if (item.id === newLifecycleRoadmap.lifecycle_id) {
          const existingRoadmaps = item[LIFECYCLE_ROADMAP_FIELD] || [];

          return {
            ...item,
            [LIFECYCLE_ROADMAP_FIELD]: append(newLifecycleRoadmap, existingRoadmaps),
            updated_at: newLifecycleRoadmap.updated_at,
          };
        }
        return item;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case DELETE_LIFECYCLE_ROADMAP_FULFILLED: {
      const { id, lifecycleRoadmaps } = action.payload;
      const lifecycleId = parseInt(id);
      const updatedData = state.data.map(item => {
        if (item.id === lifecycleId) {
          return {
            ...item,
            [LIFECYCLE_ROADMAP_FIELD]: lifecycleRoadmaps,
          };
        }
        return item;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case BULK_DELETE_LIFECYCLE_ROADMAP_FULFILLED: {
      const lifecycleId = action.payload;
      const updatedData = state.data.map(item => {
        if (item.id === lifecycleId) {
          return {
            ...item,
            [LIFECYCLE_ROADMAP_FIELD]: [],
          };
        }
        return item;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case SWITCH_LIFECYCLES_GRID_ROW_ORDER_REJECTED: {
      if (!action.meta || !action.meta.prev) return state;

      const { id, rank } = action.meta.prev;
      const updatedData = state.data.map(item => {
        if (item.id === id) {
          return { ...item, rank };
        }
        return item;
      });

      return {
        ...state,
        data: updatedData.sort(sortByRank),
      };
    }
    case SWITCH_LIFECYCLES_GRID_ROW_ORDER_PENDING: {
      if (!action.meta || !action.meta.row) {
        return state;
      }

      const { id, rank } = action.meta.row;
      const updatedData = state.data.map(item => {
        if (item.id === id) {
          return { ...item, rank };
        }
        return item;
      });

      return {
        ...state,
        data: updatedData.sort(sortByRank),
      };
    }
    case SWITCH_LIFECYCLES_GRID_ROW_ORDER_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        return state;
      }
      const updatedItem = action.payload;
      const updatedData = state.data.map(item => {
        if (item.id === updatedItem?.id) {
          return {
            ...item,
            ...pick(['rank', 'updated_at', 'updated_by_id'])(updatedItem),
          };
        }
        return item;
      });

      return {
        ...state,
        data: updatedData.sort(sortByRank),
      };
    }
    case CHANGE_ROW_HEIGHT_LIFECYCLE_GRID:
      return {
        ...state,
        rowHeight: action?.payload,
      };
    case CHANGE_HIDE_ARCHIVED_LIFECYCLE_GRID:
      return {
        ...state,
        hideArchived: action?.payload,
      };
    default: {
      return state;
    }
  }
};

const operationsReducer = getThunksReducers([UPDATE_GRID_LIFECYCLE, SAVE_GRID_NEW_LIFECYCLE, DELETE_GRID_LIFECYCLE]);

const reducer = reduceReducers(initialState, lifecyclesDataReducer, ...operationsReducer);

export default reducer;
