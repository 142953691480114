import { useState } from 'react';

const useTempConfigurationOnFieldsLayout = () => {
  const [tempFields, setTempFields] = useState({});

  const onChange = updatedFields => {
    setTempFields(updatedFields);
  };

  return {
    fieldslayout: tempFields,

    setTemporaryFieldsLayout: setTempFields,
    onChange,
  };
};

export default useTempConfigurationOnFieldsLayout;
