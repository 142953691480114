import { either, isEmpty, isNil, pipe, propEq, keys, defaultTo, filter, equals } from 'ramda';

import {
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_THEMES,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
} from 'constants/common';
import { ALL_CUSTOM_USER_FIELDS_AVAILABLE } from 'constants/projects';

import { FeatureFlags } from '@dragonboat/config';
import { checkOrganizationFlags } from 'hooks/useFeatureFlags';
import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';

import getSystemFieldName from 'utils/getSystemFieldName';

const fieldTypes = {
  STRING: 'string',
  VERSION: 'version',
  COMPONENT: 'component',
  STATUS: 'status',
  OPTION: 'option',
  ARRAY: 'array',
  PRIORITY: 'priority',
  DATE: 'date',
  NUMBER: 'number',
  USER: 'user',
};

const allFields = ['string', 'status', 'any', 'version', 'component', 'user', 'priority', 'project', 'option', 'number'];

const isNilOrEmpty = either(isNil, isEmpty);

const isCustomFieldTypeDate = propEq('field_type', TYPES_OF_CUSTOM_FIELDS.DATE);
const isCustomFieldTypeMultiSelectDropdown = propEq('field_type', TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN);

const isCustomFieldAllowedToIntegrate = (customField, organization) => {
  if (isCustomFieldTypeMultiSelectDropdown(customField)) {
    const hasJiraMultiselectMappingEnabled = checkOrganizationFlags(organization, [
      FeatureFlags.HAS_JIRA_MULTISELECT_MAPPING_ENABLED,
    ]);

    return hasJiraMultiselectMappingEnabled;
  }

  return [
    TYPES_OF_CUSTOM_FIELDS.DATE,
    TYPES_OF_CUSTOM_FIELDS.DROPDOWN,
    TYPES_OF_CUSTOM_FIELDS.NUMBER,
    TYPES_OF_CUSTOM_FIELDS.SHORT_TEXT,
    TYPES_OF_CUSTOM_FIELDS.YES_NO,
  ].includes(customField.field_type);
};

/**
 * @function getAdditionalMetadataFieldDef
 *
 * Generates a field mapping entry for an additional field based on a provided
 * field name and system name label.
 *
 * @param {String} field
 * @param {String} systemNameLabel
 * @returns {Object}
 */
const getAdditionalMetadataFieldDef = (field, systemNameLabel) => {
  return {
    field,
    displayTitle: `Additional ${systemNameLabel}`,
    col: null,
    datatypeIn: [fieldTypes.OPTION, fieldTypes.ARRAY, fieldTypes.VERSION, fieldTypes.COMPONENT, fieldTypes.STRING],
    datatypeOut: [fieldTypes.OPTION, fieldTypes.ARRAY, fieldTypes.VERSION, fieldTypes.COMPONENT, fieldTypes.STRING],
    type: fieldTypes.OPTION,
  };
};

/**
 * @function getAllAdditionalMetadataFieldsDef
 *
 * Gets all the available additional fields based on the organization configuration.
 *
 * @param {Object} organization
 * @param {Object} systemFields
 * @returns {Array}
 */
const getAllAdditionalMetadataFieldsDef = (organization, systemFields) => {
  const hasProduct = checkOrganizationFlags(organization, [FeatureFlags.HAS_PRODUCTS]);
  const hasKeyResults = checkOrganizationFlags(organization, [FeatureFlags.HAS_KEY_RESULTS]);
  const hasMultiLevelPortfolioMetadata = checkOrganizationFlags(organization, [FeatureFlags.HAS_MULTI_LEVEL_PORTFOLIO_METADATA]);

  return [
    getAdditionalMetadataFieldDef(ADDITIONAL_ROADMAPS, getSystemFieldName('roadmap', systemFields)),
    ...(hasProduct ? [getAdditionalMetadataFieldDef(ADDITIONAL_PRODUCTS, getSystemFieldName('product1', systemFields))] : []),
    getAdditionalMetadataFieldDef(ADDITIONAL_TIMEFRAMES, getSystemFieldName('timeframe', systemFields)),
    ...(hasMultiLevelPortfolioMetadata
      ? [getAdditionalMetadataFieldDef(ADDITIONAL_TIMEFRAMES_2, getSystemFieldName('timeframe2', systemFields))]
      : []),
    getAdditionalMetadataFieldDef(ADDITIONAL_OBJECTIVES, getSystemFieldName('objective', systemFields)),
    ...(hasKeyResults
      ? [getAdditionalMetadataFieldDef(ADDITIONAL_KEY_RESULTS, getSystemFieldName('keyResult1', systemFields))]
      : []),
    getAdditionalMetadataFieldDef(ADDITIONAL_THEMES, getSystemFieldName('theme', systemFields)),
    getAdditionalMetadataFieldDef(ADDITIONAL_CATEGORIES, getSystemFieldName('category', systemFields)),
  ];
};

/**
 * @function getAllCustomFieldsDef
 *
 * Gets all the available custom fields for field mapping
 *
 * @param {Object} organization
 * @param {Array} customFields
 * @returns {Array}
 */
const getAllCustomFieldsDef = (organization, customFields = []) => {
  if (isNilOrEmpty(customFields)) {
    return [];
  }

  const getDataTypeIn = customField => {
    switch (true) {
      case isCustomFieldTypeDate(customField):
        return fieldTypes.DATE;
      case isCustomFieldTypeMultiSelectDropdown(customField):
        return [fieldTypes.OPTION, fieldTypes.ARRAY, fieldTypes.VERSION, fieldTypes.COMPONENT, fieldTypes.STRING];
      default:
        return allFields;
    }
  };

  const getDataTypeOut = customField => {
    switch (true) {
      case isCustomFieldTypeDate(customField):
        return fieldTypes.DATE;
      case isCustomFieldTypeMultiSelectDropdown(customField):
        return [fieldTypes.OPTION, fieldTypes.ARRAY, fieldTypes.VERSION, fieldTypes.COMPONENT, fieldTypes.STRING];
      default:
        return [fieldTypes.STRING, fieldTypes.OPTION, fieldTypes.NUMBER];
    }
  };

  const getDataType = customField => {
    switch (true) {
      case isCustomFieldTypeDate(customField):
        return fieldTypes.DATE;
      case isCustomFieldTypeMultiSelectDropdown(customField):
        return fieldTypes.OPTION;
      default:
        return fieldTypes.STRING;
    }
  };

  return customFields
    .filter(customField => isCustomFieldAllowedToIntegrate(customField, organization))
    .map(customField => ({
      field: customField.key,
      displayTitle: `${customField.title} (cf)`,
      displayHelpText: customField.field_type,
      col: null,
      datatypeIn: getDataTypeIn(customField),
      datatypeOut: getDataTypeOut(customField),
      type: getDataType(customField),
    }));
};

const getAllCustomUserFieldsDef = (organization, systemFields) => {
  const hasCustomUserFieldsEnabled = checkOrganizationFlags(organization, [FeatureFlags.HAS_CUSTOM_USER_PROJECT_FIELDS]);

  const { custom_user_project_fields: customUserFields } = organization;

  const customUserFieldIds = pipe(defaultTo({}), filter(equals(true)), keys)(customUserFields);

  if (!hasCustomUserFieldsEnabled || isNilOrEmpty(customUserFieldIds)) return [];

  const mapping = customUserFieldIds.map(fieldKey => {
    const customUserField = ALL_CUSTOM_USER_FIELDS_AVAILABLE[fieldKey];
    const title = getSystemFieldName(customUserField.systemFieldKey, systemFields);

    return {
      field: customUserField.field,
      displayTitle: title,
      col: null,
      datatypeIn: [fieldTypes.USER, fieldTypes.STRING],
      datatypeOut: [fieldTypes.USER],
      type: 'user',
    };
  });

  return mapping;
};

export const IMPORT_COLUMNS = (organization, customFields, systemFields) => {
  const hasJiraMultiselectMappingEnabled = checkOrganizationFlags(organization, [
    FeatureFlags.HAS_JIRA_MULTISELECT_MAPPING_ENABLED,
  ]);

  const getTimeframeLevel2FieldDef = () => {
    const timeframeLevel2 = [];

    if (organization && organization.has_multi_level_portfolio_metadata) {
      timeframeLevel2.push({
        field: 'timeframe2Title',
        displayTitle: getSystemFieldName('timeframe2', systemFields),
        col: null,
        datatypeIn: allFields,
        datatypeOut: ['string', 'version', 'status', 'option'],
        type: fieldTypes.OPTION,
      });
    }

    return timeframeLevel2;
  };

  return [
    {
      field: 'title',
      displayTitle: 'Title',
      displayHelpText: 'required',
      required: true,
      col: null,
      datatypeIn: ['string'],
      datatypeOut: ['string'],
      index: 0,
      type: fieldTypes.STRING,
    },
    ...(organization && organization.has_hierarchy
      ? [
          {
            field: 'initiativeTitle',
            displayTitle: getSystemFieldName('initiative', systemFields),
            col: null,
            datatypeIn: [...allFields, 'issuelinks'],
            datatypeOut: ['string', 'version', 'status', 'option', 'issuelinks', 'any'],
            type: fieldTypes.OPTION,
          },
        ]
      : []),
    {
      field: 'ownerName',
      displayTitle: 'Owner',
      col: null,
      datatypeIn: ['user', 'string', 'any'],
      datatypeOut: ['user'],
      index: 1,
      type: fieldTypes.USER,
    },
    {
      field: 'phaseTitle',
      displayTitle: getSystemFieldName('phase', systemFields),
      col: null,
      datatypeIn: allFields,
      datatypeOut: ['string', 'version', 'status', 'option'],
      type: fieldTypes.OPTION,
    },
    {
      field: 'roadmapTitle',
      displayTitle: getSystemFieldName('roadmap', systemFields),
      col: null,
      datatypeIn: allFields,
      datatypeOut: ['string', 'version', 'status', 'option'],
      type: fieldTypes.OPTION,
    },
    ...(organization && organization.has_products
      ? [
          {
            field: 'product1Title',
            displayTitle: getSystemFieldName('product1', systemFields),
            // title:
            //   dataMapping =>
            //     !dataMapping.some(col => col.field === 'roadmapTitle' && col.col) ? 'Must map roadmap first' : '',
            // disabled:
            //   dataMapping =>
            //     !dataMapping.some(col => col.field === 'roadmapTitle' && col.col),
            col: null,
            datatypeIn: allFields,
            datatypeOut: ['string', 'version', 'status', 'option'],
            type: fieldTypes.OPTION,
          },
        ]
      : []),
    {
      field: 'timeframeTitle',
      displayTitle: getSystemFieldName('timeframe', systemFields),
      col: null,
      datatypeIn: allFields,
      datatypeOut: ['string', 'version', 'status', 'option'],
      type: fieldTypes.OPTION,
    },
    ...getTimeframeLevel2FieldDef(),
    {
      field: 'objectiveTitle',
      displayTitle: getSystemFieldName('objective', systemFields),
      col: null,
      datatypeIn: allFields,
      datatypeOut: ['string', 'version', 'status', 'option'],
      type: fieldTypes.OPTION,
    },
    ...(organization && organization.has_key_results
      ? [
          {
            field: 'keyResult1Title',
            displayTitle: getSystemFieldName('keyResult1', systemFields),
            // title:
            //   dataMapping =>
            //     !dataMapping.some(col => col.field === 'objectiveTitle' && col.col) ? 'Must map objective first' : '',
            // disabled:
            //   dataMapping =>
            //     !dataMapping.some(col => col.field === 'objectiveTitle' && col.col),
            col: null,
            datatypeIn: allFields,
            datatypeOut: ['string', 'option'],
            type: fieldTypes.OPTION,
          },
        ]
      : []),
    {
      field: 'themeTitle',
      displayTitle: getSystemFieldName('theme', systemFields),
      col: null,
      datatypeIn: allFields,
      datatypeOut: ['string', 'version', 'status', 'option'],
      type: fieldTypes.OPTION,
    },
    {
      field: 'categoryTitle',
      displayTitle: getSystemFieldName('category', systemFields),
      col: null,
      datatypeIn: allFields,
      datatypeOut: ['string', 'version', 'status', 'option'],
      type: fieldTypes.OPTION,
    },
    {
      field: 'priorityTitle',
      displayTitle: getSystemFieldName('priority', systemFields),
      col: null,
      datatypeIn: allFields,
      datatypeOut: [fieldTypes.STRING, fieldTypes.VERSION, fieldTypes.STATUS, fieldTypes.OPTION, fieldTypes.PRIORITY],
      type: fieldTypes.OPTION,
    },
    ...(hasJiraMultiselectMappingEnabled ? getAllAdditionalMetadataFieldsDef(organization, systemFields) : []),
    {
      field: 'details',
      displayTitle: 'Details',
      displayHelpText: 'multi-line text',
      col: null,
      datatypeIn: allFields,
      datatypeOut: ['string'],
      index: 2,
      type: fieldTypes.STRING,
    },
    {
      field: 'links',
      displayTitle: 'Links',
      displayHelpText: 'multi-line text',
      col: null,
      datatypeIn: allFields,
      datatypeOut: ['string'],
      type: fieldTypes.STRING,
    },
    {
      field: 'status_summary',
      displayTitle: 'Status Summary',
      displayHelpText: 'multi-line text',
      col: null,
      datatypeIn: allFields,
      datatypeOut: ['string'],
      type: fieldTypes.STRING,
    },
    {
      field: 'deadline',
      displayTitle: 'Target end date',
      displayHelpText: 'date',
      col: null,
      datatype: 'date',
      type: fieldTypes.DATE,
    },
    {
      field: 'status_color',
      displayTitle: 'Health',
      displayHelpText: 'multi-line text',
      col: null,
      datatypeIn: allFields,
      datatypeOut: ['string', 'option'],
      type: fieldTypes.OPTION,
    },
    {
      field: 'business_value',
      displayTitle: 'Benefit',
      displayHelpText: 'multi-line text',
      col: null,
      datatypeIn: allFields,
      datatypeOut: ['string'],
      type: fieldTypes.STRING,
    },
    {
      field: 'estimated_start_date',
      displayTitle: 'Target start date',
      displayHelpText: 'date',
      col: null,
      datatype: 'date',
      type: fieldTypes.DATE,
    },
    {
      field: 'issuelinks',
      displayTitle: 'Dependencies',
      col: null,
      datatypeIn: 'array',
      datatypeOut: 'array',
      type: fieldTypes.ARRAY,
    },
    {
      field: 'effort_score',
      displayTitle: 'Effort',
      displayHelpText: 'multi-line text',
      col: null,
      datatypeIn: allFields,
      datatypeOut: ['string', 'number'],
      type: fieldTypes.NUMBER,
    },
    ...getAllCustomFieldsDef(organization, customFields),
    ...getAllCustomUserFieldsDef(organization, systemFields),
  ];
};

export const sortByTitle = (a, b) => {
  if ('index' in a && 'index' in b) {
    return a > b ? -1 : 1;
  } else if ('index' in a && !('index' in b)) {
    return -1;
  } else if ('index' in b && !('index' in a)) {
    return 1;
  }

  if (!a || !a.displayTitle || !b || !b.displayTitle || a.isNew) {
    return 1;
  }

  return a.displayTitle.toLowerCase() < b.displayTitle.toLowerCase() ? -1 : 1;
};
