import React from 'react';
import styled from 'styled-components';
import { defaultTo } from 'ramda';
import Switch from '@material-ui/core/Switch';
import NotesIcon from '@material-ui/icons/Assignment';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DrawerHeader from 'design-system/molecules/DrawerHeader/index';
import HeaderBreadcrumbTitles from 'design-system/organisms/HeaderBreadcrumbTitles/index';
import ToggleButton from 'design-system/molecules/ToggleButton/index';

import theme, { spacing } from 'design-system/theme';
import InsightsIcon from 'design-system/atoms/InsightsIcon';
import TableIcon from 'design-system/atoms/TableIcon';
import Roadmap from 'design-system/atoms/DragonIcons/Roadmap';
import IntegrationOpenExternalUrlButton from 'features/Integrations/IntegrationOpenExternalUrlButton';
import useMetricsDialogHeader from '../../hooks/useMetricsDialogHeader';

const defaultToEmptyArray = defaultTo([]);

const MetricsDialogHeader = ({
  metric,
  createMetric,
  updateMetric,
  isCreating,
  canEditMetric,
  canCreateMetric,
  showTable,
  isAnonymousUser,
  setShowTable,
  onClose,
}) => {
  const {
    showTitleError,
    titleInputRef,
    metricLabel,
    displayProjectsLightboxIcon,
    activeTab,
    detailsTab,
    linkedItemsTab,
    chartTab,
    isMetricActive,
    onTabClick,
    onToggleActive,
    onChangeTitle,
    onBlurTitle,
    onKeyDownTitle,
  } = useMetricsDialogHeader(metric, createMetric, updateMetric, isCreating, isAnonymousUser);

  const openMetricIntegrationActions = defaultToEmptyArray(metric?.metricIntegrations).map(metricIntegration => {
    return (
      <StyledIntegrationOpenExternalUrlButton
        orgIntegrationId={metricIntegration.org_integration_id}
        externalUrl={metricIntegration.external_url}
      />
    );
  });

  const renderActiveToggle = () => (
    <ActiveToggle
      value="start"
      control={
        <Switch
          color="secondary"
          disabled={!canEditMetric || (isCreating && !canCreateMetric)}
          checked={isMetricActive}
          onChange={onToggleActive}
        />
      }
      label="Active"
      labelPlacement="start"
    />
  );

  const renderDetailsTabButton = () => (
    <StyledToggleButton
      icon={<NotesIcon fontSize="inherit" />}
      on={activeTab === detailsTab}
      onChange={() => onTabClick(detailsTab)}
      title="Details"
    />
  );

  const renderLinkedItemsTabButton = () =>
    displayProjectsLightboxIcon ? (
      <StyledToggleButton
        icon={<StyledRoadmap />}
        on={activeTab === linkedItemsTab}
        onChange={() => onTabClick(linkedItemsTab)}
        title="Show projects"
        disabled={isCreating}
      />
    ) : null;

  const renderChartTabButton = () => (
    <StyledToggleButton
      icon={<InsightsIcon fontSize="inherit" />}
      on={activeTab === chartTab}
      onChange={() => onTabClick(chartTab)}
      title="Chart"
      disabled={isCreating}
    />
  );

  const renderTableButton = () =>
    activeTab === chartTab ? (
      <StyledToggleButton
        icon={<TableIcon fontSize="inherit" />}
        on={showTable}
        onChange={() => setShowTable(!showTable)}
        title="Table"
        disabled={isCreating}
      />
    ) : null;

  const headerActions = [
    renderActiveToggle(),
    renderDetailsTabButton(),
    renderLinkedItemsTabButton(),
    ...openMetricIntegrationActions,
    renderChartTabButton(),
    renderTableButton(),
  ];

  const placeholder = `New ${metricLabel}`;

  // we can leverage breadcrumbTitles to have same behaviour as project and okr drawers
  // also might be handy if we have metric hierarchy
  const titleItems = [
    {
      title: metric?.name,
      placeholder: isCreating ? placeholder : '',
      readingPlaceholder: isCreating ? placeholder : null,
      isEditable: canEditMetric || (isCreating && canCreateMetric),
      onChange: onChangeTitle,
      onBlur: onBlurTitle,
      onKeyDown: onKeyDownTitle,
      error: showTitleError,
      inputRef: titleInputRef,
      icon: <StyledInsightsIcon />,
      useTextFieldWhenNonEditable: true,
      fontSize: theme.typography.fontSizeSmallLarge,
    },
  ];

  return (
    <StyledDrawerHeader
      onClose={onClose}
      title={<StyledHeaderBreadcrumbTitles items={titleItems} />}
      tooltipTitle={metric?.name}
      actions={headerActions}
      headerHeight="71px"
      showBorderBottom={false}
    />
  );
};

export default MetricsDialogHeader;

const StyledDrawerHeader = styled(DrawerHeader)`
  padding: ${spacing()}px ${spacing(3)}px;
`;

const ActiveToggle = styled(FormControlLabel)`
  && {
    margin-right: ${spacing(0.5)}px;
  }
`;

const StyledToggleButton = styled(ToggleButton)`
  &&&&& {
    margin: ${spacing(1)}px ${spacing(0.5)}px;
    font-size: ${({ theme }) => theme.typography.fontSizeMediumSmallRem}rem;
    max-height: 35px;
    max-width: 35px;
  }
`;

const StyledIntegrationOpenExternalUrlButton = styled(IntegrationOpenExternalUrlButton)`
  &&&&& {
    margin: ${spacing(1)}px ${spacing(0.5)}px;
    max-height: 35px;
    max-width: 35px;

    img {
      width: 18px;
    }
  }
`;

const StyledRoadmap = styled(Roadmap)`
  fill: currentColor;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
`;

const StyledHeaderBreadcrumbTitles = styled(HeaderBreadcrumbTitles)`
  textarea {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const StyledInsightsIcon = styled(InsightsIcon)`
  color: ${({ theme }) => theme.palette.text.accent};
`;
