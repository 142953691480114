import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import useSubscribeNewData from 'hooks/useSubscribeNewData';
import { INTEGRATIONS_KEYS, IMPORT_LIGHTBOX_VARIANTS } from 'constants/integrations';
import useImportConfiguration from 'hooks/integrations/useImportConfiguration';

import IntegrationFromIntegrationWizard from './components/ImportFromIntegrationWizard/ImportFromIntegrationWizard';
import ADOSearchStep from './components/ADOSearchStep/ADOSearchStep';
import RallySearchStep from './components/RallySearchStep/RallySearchStep';
import ResultStep from './components/ResultStep/ResultStep';

const asyncImportIntegrationTypes = [INTEGRATIONS_KEYS.azuredevops, INTEGRATIONS_KEYS.rally];

const shouldDoAsyncImportByIntegrationType = integrationType => asyncImportIntegrationTypes.includes(integrationType);

const ImportFromIntegration = ({
  integrationType,
  history,
  isOpen,
  onClose,
  orgIntegrationId,
  variant = IMPORT_LIGHTBOX_VARIANTS.import,
  showWorkspaceList,
}) => {
  const [parsedImportUrl, setParsedImportUrl] = useState('');
  const [selectedOrgIntegrationId, setSelectedOrgIntegrationId] = useState(orgIntegrationId);

  const [importIntegrationData, updateImportConfig] = useImportConfiguration(integrationType, selectedOrgIntegrationId);

  const isRecurringImportVariant = variant === IMPORT_LIGHTBOX_VARIANTS.recurringImport;

  const useAsyncImport = useMemo(() => shouldDoAsyncImportByIntegrationType(integrationType), [integrationType]);

  useSubscribeNewData(['projects', 'asyncImportIntegrationProjects'], false);

  const handleFinish = useCallback(() => {
    if (isRecurringImportVariant && selectedOrgIntegrationId) {
      updateImportConfig({
        recurring_import_link: parsedImportUrl,
      });
    }
    onClose();
  }, [onClose, selectedOrgIntegrationId, parsedImportUrl]);

  const handleOrgIntegrationChange = useCallback(id => setSelectedOrgIntegrationId(id));

  useEffect(() => {
    if (!orgIntegrationId) return;

    setSelectedOrgIntegrationId(orgIntegrationId);
  }, [orgIntegrationId]);

  const steps = useMemo(() => {
    switch (integrationType) {
      case INTEGRATIONS_KEYS.azuredevops:
        return [
          {
            title: 'Search Work Items',
            component: (
              <ADOSearchStep
                parsedImportUrl={parsedImportUrl}
                orgIntegrationId={selectedOrgIntegrationId}
                onOrgIntegrationIdChange={handleOrgIntegrationChange}
                onSelectedValueChange={value => setParsedImportUrl(value)}
              />
            ),
          },
          {
            title: 'Complete Import',
            component: (
              <ResultStep integrationType={integrationType} orgIntegrationId={selectedOrgIntegrationId} onFinish={handleFinish} />
            ),
          },
        ];
      case INTEGRATIONS_KEYS.rally:
        return [
          {
            title: 'Search Work Items',
            component: (
              <RallySearchStep
                parsedImportUrl={parsedImportUrl}
                orgIntegrationId={selectedOrgIntegrationId}
                onOrgIntegrationIdChange={handleOrgIntegrationChange}
                onSelectedValueChange={value => setParsedImportUrl(value)}
                recurringImportConfig={importIntegrationData}
                isRecurringImportVariant={isRecurringImportVariant}
                showWorkspaceList={showWorkspaceList}
              />
            ),
          },
          {
            title: 'Complete Import',
            component: (
              <ResultStep integrationType={integrationType} orgIntegrationId={selectedOrgIntegrationId} onFinish={handleFinish} />
            ),
          },
        ];
      default:
        return [];
    }
  }, [parsedImportUrl, selectedOrgIntegrationId, setParsedImportUrl, integrationType, isRecurringImportVariant]);

  return (
    <IntegrationFromIntegrationWizard
      integrationType={integrationType}
      steps={steps}
      isOpen={isOpen}
      parsedImportUrl={parsedImportUrl}
      orgIntegrationId={selectedOrgIntegrationId}
      onClose={onClose}
      onFinish={handleFinish}
      variant={variant}
      useAsyncImport={useAsyncImport}
    />
  );
};

ImportFromIntegration.propTypes = {
  integrationType: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

export default withRouter(ImportFromIntegration);
