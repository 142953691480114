import React, { useEffect, useMemo } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { DASHBOARDS_PAGE } from 'constants/filters/pages';
import useDashboardInformation from './hooks/useDashboardInformation';

import NewDashboard from './NewDashboard';
import AccessDenied from 'routes/AccessDenied';
import { getGridLayoutFromWidgets } from './helpers/widgets';
import useLoadProjectsWithFilters from 'hooks/projects/useLoadProjectsWithFilters';
import useApplyActiveDashboardUrl from './helpers/useApplyActiveDashboardUrl';
import { hidePermissionsDialog, showPermissionsDialog } from 'store/app';
import { getIsPermissionsDialogVisible } from 'store/app/selectors';
import useDashboardWidgets from './hooks/useDashboardWidgets';
import { TRANSACTION_PAGES_PROJECTS_LIMIT } from 'constants/index';
import { updateActiveDashboard } from 'store/dashboards/actions';

const emptyObject = {};

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const isPermissionDialogVisible = useSelector(state => getIsPermissionsDialogVisible(state));
    const { isInEditMode, dashboardId, activeDashboardView, hasAccessToDashboard } = useDashboardInformation(props.match);

    const { dashboard = emptyObject, filter = emptyObject } = activeDashboardView?.state || emptyObject;

    useEffect(() => {
      if (!isPermissionDialogVisible && !hasAccessToDashboard) {
        dispatch(showPermissionsDialog());
      }
      if (isPermissionDialogVisible && hasAccessToDashboard) {
        dispatch(hidePermissionsDialog(false));
      }
    }, [isPermissionDialogVisible, hasAccessToDashboard]);

    useApplyActiveDashboardUrl(activeDashboardView, props.history);
    useLoadProjectsWithFilters(DASHBOARDS_PAGE, filter, null, null, {}, { from: 0, to: TRANSACTION_PAGES_PROJECTS_LIMIT });

    const updateDashboardProgressDates = (startDate, endDate) => {
      dispatch(
        updateActiveDashboard({
          ...dashboard,
          progressStartDate: startDate,
          progressEndDate: endDate,
        }),
      );
    };

    const { widgets: dashboardWidgets, updateDashboardWidgets } = useDashboardWidgets({ dashboard, dashboardId });

    const widgetsGridLayout = useMemo(() => getGridLayoutFromWidgets(dashboardWidgets), [dashboardWidgets]);

    if (!hasAccessToDashboard) {
      return <AccessDenied />;
    }

    if (!activeDashboardView) {
      return <NewDashboard />;
    }

    return (
      <Component
        {...props}
        dashboardView={activeDashboardView}
        dashboardFilter={filter}
        dashboardWidgets={dashboardWidgets}
        dashboardState={dashboard}
        gridLayout={widgetsGridLayout}
        updateWidgets={updateDashboardWidgets}
        updateDateRange={updateDashboardProgressDates}
        inEditMode={isInEditMode}
      />
    );
  };
};

export default compose(withRouter, componentHOC);
