import { useMemo } from 'react';

import { defaultColumnDefCommonProps, getTitleColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import { ProgressCellRenderer, WYSIWYGCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { ProgressCellEditor, WYSIWYGCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { isNewRow } from 'design-system/molecules/AgGridReact-New/helpers';

import { returnsTrueIfKeyIsNotEscOrTab } from 'utils/agGrid';

const isUndefinedRow = isNewRow;

const useSnapshotTableComponentGridColumns = (title, displayLayer) => {
  const multilineTextCommonDefs = useMemo(
    () => ({
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      cellRenderer: WYSIWYGCellRenderer,
      cellEditor: WYSIWYGCellEditor,
      cellEditorParams: {
        maxHeight: 150,
        autoFocus: true,
      },
      width: 450,
      minWidth: 200,
      comparator: stringComparator,
      cellEditorPopup: true,
    }),
    [],
  );

  const titleColumnDef = useMemo(
    () => ({
      ...getTitleColumnDef(),
      headerName: title,
      cellClass: 'title-cell',
      width: 320,
      minWidth: 200,
      flex: 2,
      editable: params => !isUndefinedRow(params),
    }),
    [],
  );

  const ideasProgressColumnDef = useMemo(
    () => ({
      field: 'ideasProgress',
      headerName: `${displayLayer} Progress`,
      cellRenderer: ProgressCellRenderer,
      cellEditor: ProgressCellEditor,
      editable: false,
      width: 120,
      minWidth: 120,
    }),
    [displayLayer],
  );

  const summaryColumnDef = useMemo(
    () => ({
      ...multilineTextCommonDefs,
      field: 'summary',
      headerName: 'Summary',
      cellClass: 'align-items-top',
      width: 450,
      minWidth: 200,
      maxHeight: 150,
      flex: 2,
      editable: params => !isUndefinedRow(params),
    }),
    [multilineTextCommonDefs],
  );

  const columnDefs = useMemo(
    () => [titleColumnDef, ideasProgressColumnDef, summaryColumnDef],
    [titleColumnDef, ideasProgressColumnDef, summaryColumnDef],
  );

  const defaultColDef = useMemo(
    () => ({
      ...defaultColumnDefCommonProps,
      cellClass: params =>
        params.colDef.autoHeight ? 'ag-react-container--expandable' : 'ag-react-editable-container--expandable',
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
    }),
    [],
  );

  return { defaultColDef, columnDefs };
};

export default useSnapshotTableComponentGridColumns;
