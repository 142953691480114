import React from 'react';

import BulkUpdateModal from 'components/BulkUpdateModal';
import usePreviewListColumnsDefs from './hooks/usePreviewListColumnsDefs';
import useBulkUpdateFields from './hooks/useBulkUpdateFields';

/**
 * Represents a modal component for bulk updating requests.
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onClose - The function to close the modal.
 * @param {Array} props.selectedItems - The array of selected items to be updated.
 * @param {Function} props.onBulkUpdateFinished - The function to be called after the bulk update is finished.
 * @returns {JSX.Element} The JSX element representing the RequestsBulkUpdateModal.
 */
const RequestsBulkUpdateModal = ({ onClose, selectedItems, onBulkUpdateFinished }) => {
  const { idColumnDef, titleColumnDef } = usePreviewListColumnsDefs();

  const { fields, handleBulkUpdate } = useBulkUpdateFields({ onBulkUpdateFinished });

  return (
    <BulkUpdateModal
      selectedItems={selectedItems}
      resource="item"
      onClose={onClose}
      onConfirmed={handleBulkUpdate}
      fields={fields}
      mandatoryGridColumns={[idColumnDef, titleColumnDef]}
    />
  );
};

export default RequestsBulkUpdateModal;
