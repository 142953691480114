// External dependencies
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import isEmpty from 'lodash/isEmpty';
import { defaultTo, not } from 'ramda';

// Dragonboat imports
import { filterActiveItens } from 'utils';

import {
  PROJECT_CUSTOM_FIELD,
  CUSTOMER_REQUEST_CUSTOM_FIELD,
  CUSTOM_FIELD_TYPES,
  TYPES_OF_CUSTOM_FIELDS,
  CUSTOMER_CUSTOM_FIELD,
} from './constants';

import getFormattedFormulaDescription from 'store/customFields/helpers/getFormattedFormulaDescription';
import getCustomFormulaOptions from 'store/customFields/helpers/getCustomFormulaOptions';
import getSortedDropdownOptions from './helpers/getSortedDropdownOptions';
import { checkIfCustomFieldIsDropdown } from 'utils/customFields/customFieldsUtils';

const CUSTOM_FIELD_SUFIX = 'cf';

const defaultAsEmptyArray = defaultTo([]);

const _getCustomFieldTitleWithSufix = title => {
  return `${title} (${CUSTOM_FIELD_SUFIX})`;
};

const hasRequestsAssociation = cf =>
  defaultAsEmptyArray(cf.associations).some(a => a.association_type === CUSTOMER_REQUEST_CUSTOM_FIELD);
const hasProjectsAssociation = cf => defaultAsEmptyArray(cf.associations).some(a => a.association_type === PROJECT_CUSTOM_FIELD);
const hasCustomerAssociation = cf => defaultAsEmptyArray(cf.associations).some(a => a.association_type === CUSTOMER_CUSTOM_FIELD);

const hasProjectsAndRequestsAssociation = cf => hasProjectsAssociation(cf) && hasRequestsAssociation(cf);

export const getState = createCachedSelector(
  state => state.customFields,
  (_, showArchivedDropdownOptions = false) => showArchivedDropdownOptions,
  (state, showArchivedDropdownOptions) => {
    let customFields = state && state.customFields ? state.customFields : [];

    customFields = customFields
      .filter(cf => !cf.is_system_field)
      .map(cf => ({
        ...cf,
        titleWithSuffix: _getCustomFieldTitleWithSufix(cf.title),
      }))
      .map(cf => {
        if (cf.field_type === 'Formula') {
          const type =
            cf.association_type === PROJECT_CUSTOM_FIELD ? CUSTOM_FIELD_TYPES.PROJECTS : CUSTOM_FIELD_TYPES.CUSTOMER_REQUESTS;

          return {
            ...cf,
            description_text: getFormattedFormulaDescription(
              cf.description,
              getCustomFormulaOptions(customFields, type),
              'key',
              'title',
            ),
          };
        }

        if (checkIfCustomFieldIsDropdown(cf.field_type)) {
          const sortedValidOptions = getSortedDropdownOptions(cf.data_enhanced, showArchivedDropdownOptions) || [];

          return {
            ...cf,
            sortedValidOptions,
          };
        }

        return cf;
      });

    return customFields;
  },
)((_, showArchivedDropdownOptions) => `stateCustomFields_${showArchivedDropdownOptions}`);

export const getProjectsCustomFields = createCachedSelector(
  (state, _, showArchivedDropdownOptions) => getState(state, showArchivedDropdownOptions),
  (_, showArchived) => showArchived,
  (state, showArchived) => {
    const customFields = state.filter(cf => (showArchived ? true : filterActiveItens(cf)) && hasProjectsAssociation(cf));

    return customFields;
  },
)((_, showArchived, showArchivedDropdownOptions) => `projectsCustomFields_${showArchived}_${showArchivedDropdownOptions}`);

export const makeGetProjectsDropdownCustomFields = () =>
  createCachedSelector(
    (state, _, showArchivedDropdownOptions) => getState(state, showArchivedDropdownOptions),
    (_, showArchived) => showArchived,
    (state, showArchived) => {
      const customFields = state.filter(cf => (showArchived ? true : filterActiveItens(cf)) && hasProjectsAssociation(cf));

      return customFields;
    },
  )((_, showArchived, showArchivedDropdownOptions) => `makeProjectsCustomFields_${showArchived}_${showArchivedDropdownOptions}`);

export const getCustomerRequestsCustomFields = createCachedSelector(
  (state, _, showArchivedDropdownOptions) => getState(state, showArchivedDropdownOptions),
  (_, showArchived = false) => showArchived,
  (state, showArchived) => {
    const customFields = state.filter(
      cf => (showArchived ? true : filterActiveItens(cf)) && hasRequestsAssociation(cf) && !cf.isNew,
    );

    return customFields;
  },
)((_, showArchived, showArchivedDropdownOptions) => `requestsCustomFields_${showArchived}_${showArchivedDropdownOptions}`);

export const getCustomerCustomFields = createCachedSelector(
  [
    (state, _, showArchivedDropdownOptions) => getState(state, showArchivedDropdownOptions),
    (_, showArchived = true) => showArchived,
  ],
  (state, showArchived) => {
    const customFields = state.filter(
      cf => (showArchived ? true : filterActiveItens(cf)) && cf.association_type === CUSTOMER_CUSTOM_FIELD,
    );

    return customFields;
  },
)((_, showArchived = true, showArchivedDropdownOptions) => `customer-cf-${showArchived}_${showArchivedDropdownOptions}`);

export const getCustomerSegmentCustomField = createSelector(getCustomerCustomFields, state => {
  return state.find(cf => cf.key.startsWith('segment_2_'));
});

export const getCustomerTypeCustomField = createSelector(getCustomerCustomFields, state => {
  return state.find(cf => cf.key.startsWith('type_2_'));
});

export const getCustomFieldsIsLoaded = createSelector(
  state => state.customFields,
  state => {
    return state.isLoaded;
  },
);

export const getDropdownCustomFields = createCachedSelector(
  (state, showArchivedDropdownOptions) => getState(state, showArchivedDropdownOptions),
  customFields => {
    const result = isEmpty(customFields)
      ? []
      : customFields
          .filter(cf => cf?.field_type === TYPES_OF_CUSTOM_FIELDS.DROPDOWN)
          .filter(cf => cf?.status !== 'Archived')
          .filter(cf => hasProjectsAssociation(cf));

    return result;
  },
)((_, showArchivedDropdownOptions) => `dropdownCustomFields_${showArchivedDropdownOptions}`);

export const getCustomerRequestsCustomFieldsEnhanced = createCachedSelector(
  (state, _, showArchivedDropdownOptions) => getState(state, showArchivedDropdownOptions),
  (_, showArchived = true) => showArchived,
  (state, showArchived) => {
    const customFields = state
      .filter(cf => (showArchived ? true : filterActiveItens(cf)) && hasRequestsAssociation(cf))
      .map(cf => ({ ...cf, data: cf.data_enhanced }));

    return customFields;
  },
)(
  (_, showArchived, showArchivedDropdownOptions) => `requestsCustomFieldsEnhanced_${showArchived}_${showArchivedDropdownOptions}`,
);

export const getProjectsCustomFieldsNewSchema = createCachedSelector(
  (state, _, showArchivedDropdownOptions) => getState(state, showArchivedDropdownOptions),
  (_, showArchived) => showArchived,
  (state, showArchived) => {
    return state
      .filter(cf => (showArchived ? true : filterActiveItens(cf)) && hasProjectsAssociation(cf))
      .map(cf => ({
        ...cf,
        data: cf.data_enhanced,
      }));
  },
)(
  (_, showArchived, showArchivedDropdownOptions) =>
    `projectsCustomFieldsNewSchema_${showArchived}_${showArchivedDropdownOptions}`,
);

export const getCustomerRequestsCustomFieldsForCustomerRequestsTable = createCachedSelector(
  (state, showArchivedDropdownOptions) => getState(state, showArchivedDropdownOptions),
  (_, showArchived) => showArchived,
  (state, showArchived) => {
    const customFields = state
      .filter(cf => (showArchived ? true : filterActiveItens(cf)) && hasRequestsAssociation(cf))
      .map(cf => ({ ...cf, data: cf.data_enhanced }));

    return customFields;
  },
)(
  (_, showArchived, showArchivedDropdownOptions) => `requestsCustomFieldsEnhanced_${showArchived}_${showArchivedDropdownOptions}`,
);

export const getProjectAndRequestsCustomFields = createCachedSelector(
  state => getState(state),
  (_, showArchived) => showArchived,
  (state, showArchived) => {
    return state
      .filter(cf => (showArchived ? true : filterActiveItens(cf)))
      .filter(cf => not(hasCustomerAssociation(cf)))
      .map(cf => ({
        ...cf,
        data: cf.data_enhanced,
      }));
  },
)((_, showArchived) => `getProjectAndRequestsCustomFields_${showArchived}`);

export const getRequestsCustomFieldsSharedWithProjects = createCachedSelector(
  state => getState(state),
  (_, showArchived) => showArchived,
  (state, showArchived) => {
    return state.filter(cf => (showArchived ? true : filterActiveItens(cf))).filter(cf => hasProjectsAndRequestsAssociation(cf));
  },
)(state => `requestsCustomFieldsSharedWithProjects_${state}`);
