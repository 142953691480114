import { defaultTo, propOr } from 'ramda';
import { v4 as uuidv4 } from 'uuid';

import { CONFIGURABLE_CHART } from '../../helpers/templates';
import { getWidgetId } from '../../helpers/widgets';
import { SELECT_CHART_SCREEN, CHARTS, CHART_CONFIG_SCREEN } from '../hooks/useChartWidgetWizard';

const defaultToEmptyArray = defaultTo([]);
const xOrZero = propOr(0, 'x');
const yOrZero = propOr(0, 'y');

/**
 * Inserts `chart` as the top most widget of the first column by shifting down all existing
 * charts on the first column by one unit.
 * @param {object} dashboard
 * @param {object} chart
 * @returns
 */
const addNewChartWidgetToCurrentDashboard = (dashboard, widgetOrWidgets = {}) => {
  const widgetsToAdd = [].concat(widgetOrWidgets);
  const existingWidgets = defaultToEmptyArray(dashboard?.widgets);

  // Make room
  const shiftedWidgets = existingWidgets.map(widget => {
    if (xOrZero(widget) === 0) {
      return {
        ...widget,
        x: xOrZero(widget),
        y: yOrZero(widget) + widgetsToAdd.length,
      };
    }
    return widget;
  });

  shiftedWidgets.unshift(
    ...widgetsToAdd.map((widget, idx) => ({
      ...widget,
      uuid: uuidv4(),
      x: 0,
      y: idx,
      width: 1,
      height: 1,
    })),
  );

  return {
    ...dashboard,
    widgets: shiftedWidgets,
  };
};

/**
 * Parses configurable chart data into a format suitable for saving.
 *
 * @param {Object} options - An object containing chart configuration options.
 * @param {Object} options.selectedChart - The selected chart configuration.
 * @param {Object} options.selectedChartOptions - The selected chart's group-by and stack-by options.
 * @param {boolean} options.isStackedChart - Indicates whether the chart is stacked or not.
 * @returns {Object} A formatted object representing the chart data to save.
 * @property {Object} props - An object containing chart properties.
 * @property {string} props.selectedChart - The ID of the selected chart.
 * @property {string|null} props.groupBy - The key of the selected group-by option or null if not selected.
 * @property {string|null} props.stackBy - The key of the selected stack-by option or null if not
 * selected (if the chart is stacked).
 * @property {string} props.chartType - The type of chart.
 * @property {boolean} props.horizontal - Indicates whether the chart is horizontal or not.
 */
const parseConfigurableChartDataToSave = ({ selectedChart, selectedChartOptions, isStackedChart }) => ({
  ...CONFIGURABLE_CHART,
  props: {
    selectedChart: selectedChart.id,
    groupBy: selectedChartOptions.groupBy?.key,
    stackBy: isStackedChart ? selectedChartOptions.stackBy?.key : null,
    chartType: selectedChart.chartType,
    horizontal: selectedChart.horizontal,
  },
});

/**
 * Updates a widget within a dashboard's list of widgets with new data.
 *
 * @param {Object} dashboard - The dashboard object containing a list of widgets.
 * @param {String} widgetIdToEdit - The ID of the widget to update.
 * @param {Object} widgetData - The updated widget data to replace the existing widget with.
 * @returns {Array} A new array of widgets with the specified widget updated or replaced.
 */
const updateWidgetOnDashboardWidgets = (dashboard, widgetIdToEdit, widgetData) => {
  const currentWidgetIndex = dashboard.widgets.findIndex(w => getWidgetId(w) === widgetIdToEdit);

  // Widget not found
  if (dashboard.widgets.length > 0 && currentWidgetIndex === -1) {
    return dashboard.widgets;
  }

  const currentWidget = dashboard.widgets[currentWidgetIndex];
  const updatedWidget = {
    ...currentWidget,
    ...widgetData,
  };

  return [...dashboard.widgets.slice(0, currentWidgetIndex), updatedWidget, ...dashboard.widgets.slice(currentWidgetIndex + 1)];
};

/**
 * Loads data related to a widget for editing purposes.
 *
 * @param {Object|null} widget - The widget object to edit, or null if the widget is not found.
 * @param {Array} groupByOptions - An array of available group-by options.
 * @param {Array} stackByOptions - An array of available stack-by options.
 * @returns {Object} An object containing data for editing the widget.
 * @property {string} currentScreen - The current editing screen ('SELECT_CHART_SCREEN' or 'CHART_CONFIG_SCREEN').
 * @property {Object|null} selectedChart - The selected chart configuration or null if the widget is not found.
 * @property {Object} selectedChartOptions - The selected group-by and stack-by options for the widget.
 * @property {Object|null} selectedChartOptions.groupBy - The selected group-by option or null if not found.
 * @property {Object|null} selectedChartOptions.stackBy - The selected stack-by option or null if not found.
 */
const loadDataFromWidgetToEdit = (widget, groupByOptions, stackByOptions) => {
  // widget not found, resolve default state to select chart options
  if (!widget) {
    return {
      currentScreen: SELECT_CHART_SCREEN,
      selectedChartOptions: {},
    };
  }

  const selectedChart = CHARTS.find(c => c.id === widget.props.selectedChart);
  const currentScreen = selectedChart ? CHART_CONFIG_SCREEN : SELECT_CHART_SCREEN;
  const selectedChartOptions = {
    groupBy: groupByOptions.find(o => o.key === widget.props.groupBy),
    stackBy: stackByOptions.find(o => o.key === widget.props.stackBy),
  };

  return {
    currentScreen,
    selectedChart,
    selectedChartOptions,
  };
};

/**
 * Updates a chart widget on the current dashboard's state, identified by the provided widget ID.
 *
 * @param {Object} dashboard - The current dashboard object.
 * @param {String} widgetIdToEdit - The ID of the widget to update.
 * @param {Object} [chart={}] - The chart widget object to update.
 * @returns {Object} The updated dashboard state object.
 */
const updateChartWidgetOnCurrentDashboard = (dashboard, widgetIdToEdit, chart = {}) => {
  return {
    ...dashboard,
    widgets: updateWidgetOnDashboardWidgets(dashboard, widgetIdToEdit, chart),
  };
};

export {
  updateChartWidgetOnCurrentDashboard,
  parseConfigurableChartDataToSave,
  loadDataFromWidgetToEdit,
  updateWidgetOnDashboardWidgets,
  addNewChartWidgetToCurrentDashboard,
};
