import { useCallback, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { ACTIVE_STATUS } from 'constants/common';

import { NEW_ROW_ID } from 'design-system/molecules/AgGridReact-New/helpers';
import {
  createCustomer,
  updateCustomerById,
  mergeCustomers,
  fetchCustomers,
  removeUnsavedCustomers,
  addCustomerWithoutSave as addCustomerWithoutSaveAction,
  createCustomers,
  updateCustomers,
  bulkDeleteCustomers,
  deleteCustomer,
  createCustomerRoadmap,
  deleteCustomerRoadmap,
  bulkDeleteCustomerRoadmaps,
  updateSearchText,
  updateSettingsGroupByOption,
} from 'store/customers';
import { getActiveCustomers, getAllCustomers, getSearchText, getSettingsGroupByOption } from 'store/customers/selectors';
import { getCustomerCustomFields } from 'store/customFields/selectors';

import { NO_GROUP_OPTION, buildHierarchyByFieldValue } from '../helpers';

const useCustomers = hideInactiveCustomers => {
  const dispatch = useDispatch();

  const [isImportLightboxOpen, setIsImportLightboxOpen] = useState(false);

  const openImportLightbox = useCallback(() => setIsImportLightboxOpen(true), []);
  const closeImportLightbox = useCallback(() => setIsImportLightboxOpen(false), []);

  const [isHidingInactiveCustomers, setIsHidingInactiveCustomers] = useState(false);

  const toggleHidingInactiveCustomers = useCallback(() => {
    setIsHidingInactiveCustomers(previousState => !previousState);
  }, []);

  const shouldShowArchived = !hideInactiveCustomers && !isHidingInactiveCustomers;

  const selectorBasedOnStatusFilter = shouldShowArchived ? getAllCustomers : getActiveCustomers;

  const customers = useSelector(state => selectorBasedOnStatusFilter(state));

  const customFields = useSelector(getCustomerCustomFields);

  const searchText = useSelector(getSearchText);

  const selectedGroupByOptionFromStore = useSelector(getSettingsGroupByOption);

  const selectedGroupByOption = useMemo(() => {
    return selectedGroupByOptionFromStore || NO_GROUP_OPTION;
  }, [selectedGroupByOptionFromStore]);

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          fetchCustomers,
          createCustomer,
          createCustomers,
          updateCustomers,
          updateCustomerById,
          bulkDeleteCustomers,
          removeUnsavedCustomers,
          deleteCustomer,
          mergeCustomers,
          updateSearchText,
          updateSettingsGroupByOption,
        },
        dispatch,
      ),
    [dispatch],
  );

  const filteredCustomers = useMemo(() => {
    const processedSearchText = searchText?.toLowerCase();

    return customers.filter(({ name }) => {
      return !processedSearchText || name?.toLowerCase().includes(processedSearchText);
    });
  }, [customers, searchText]);

  const filteredCustomersWithHierarchy = useMemo(() => {
    const selectedGroupByOptionKey = selectedGroupByOption?.key;

    if (!selectedGroupByOptionKey) {
      return filteredCustomers.map(c => ({
        ...c,
        path: [c.id || NEW_ROW_ID],
      }));
    }

    const isGroupingByCustomField = selectedGroupByOption?.meta?.isCustomField;

    if (isGroupingByCustomField) {
      const customField = customFields.find(cf => cf.key.match(selectedGroupByOptionKey));

      if (customField) {
        return buildHierarchyByFieldValue(filteredCustomers, customer => {
          const customerCustomFieldOptionKey = customer.custom_fields?.[customField.key];

          return customField.data?.[customerCustomFieldOptionKey];
        });
      }
    }

    return buildHierarchyByFieldValue(filteredCustomers, customer => customer[selectedGroupByOptionKey]);
  }, [selectedGroupByOption, filteredCustomers, customFields]);

  const addCustomerWithoutSave = useCallback(() => {
    return dispatch(addCustomerWithoutSaveAction({ status: ACTIVE_STATUS }));
  }, [dispatch]);

  return {
    customers,
    filteredCustomersWithHierarchy,
    customFields,
    searchText,
    isImportLightboxOpen,
    openImportLightbox,
    closeImportLightbox,
    createCustomerRoadmap,
    deleteCustomerRoadmap,
    bulkDeleteCustomerRoadmaps,
    ...boundActionCreators,
    addCustomerWithoutSave,
    isHidingInactiveCustomers,
    toggleHidingInactiveCustomers,
    selectedGroupByOption,
    isGrouping: !!selectedGroupByOption?.key,
  };
};

export default useCustomers;
