import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UNDEFINED, UNDEFINED_LABEL } from 'constants/common';
import { getOrganization } from 'store/organization/selectors';
import {
  formatMetadataOptions,
  getCustomFieldsOptionsAsFlatArray,
  isCustomField,
  removeExistingGroupsFromMetadataOptions,
} from './helpers';
import { METADATA_KEY_MAPPER } from 'routes/Dashboard/Summary/helpers/summaryMetadataKeyMapper';
import { checkRolePermission } from 'containers/UserPermission/utils';
import { UPDATE_METADATA_PERMISSION } from '@dragonboat/permissions/lib/constants';
import { CUSTOM_FIELDS } from 'store/grids';
import { omit } from 'ramda';

const UNDEFINED_GROUP = { id: UNDEFINED, name: UNDEFINED_LABEL };

const useAddOrLinkColumnOrRowBox = ({
  metadata,
  selectedOptionKey,
  fieldsToIgnoreAddColumnRow,
  fieldsToIgnoreLinkColumnRow,
  currentlyVisibleMetadata,
}) => {
  const currentUser = useSelector(state => state.login.currentUser);
  const organization = useSelector(getOrganization);

  const userCanCreateNewMetadata = metadataKey =>
    checkRolePermission(UPDATE_METADATA_PERMISSION, currentUser, organization, METADATA_KEY_MAPPER[metadataKey]);

  const mappedMetadataKey = isCustomField(selectedOptionKey) ? selectedOptionKey : METADATA_KEY_MAPPER[selectedOptionKey];

  // We only want to display metadata options that are not already being displayed on the groups
  const getMetadataOptions = useCallback(
    currentlyVisibleMetadata => {
      const metadataWithCustomFields = {
        ...omit(CUSTOM_FIELDS, metadata),
        ...metadata.customFields.reduce(
          (cfOptions, { key, sortedValidOptions }) => ({
            ...cfOptions,
            ...getCustomFieldsOptionsAsFlatArray(key, sortedValidOptions),
          }),
          {},
        ),
      };

      const metadataWithUndefined = Object.entries(metadataWithCustomFields).reduce((acc, [key, val]) => {
        if (val.find(item => item.id === UNDEFINED)) {
          return acc;
        }

        return {
          ...acc,
          [key]: [UNDEFINED_GROUP, ...val],
        };
      }, {});

      return formatMetadataOptions(
        removeExistingGroupsFromMetadataOptions(metadataWithUndefined, currentlyVisibleMetadata, mappedMetadataKey),
        mappedMetadataKey,
      );
    },
    [metadata, mappedMetadataKey],
  );

  const fieldAllowsCreate = !fieldsToIgnoreAddColumnRow.includes(selectedOptionKey);
  const fieldAllowsLink = !fieldsToIgnoreLinkColumnRow.includes(selectedOptionKey);

  const canCreate = userCanCreateNewMetadata(mappedMetadataKey) && fieldAllowsCreate && !isCustomField(mappedMetadataKey);
  const canLink = fieldAllowsLink;

  const metadataOptions = useMemo(() => getMetadataOptions(currentlyVisibleMetadata), [currentlyVisibleMetadata]);

  return {
    metadataOptions,
    canCreate,
    canLink,
  };
};

export default useAddOrLinkColumnOrRowBox;
