import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTo } from 'ramda';

import { BASE_ROW_HEIGHT } from 'constants/grid';
import { DEFAULT_SELECTED_VIEW } from 'constants/ideas';

import {
  saveGridConfig as saveGridConfigAction,
  setSelectedItems as setSelectedItemsAction,
  saveGridState as saveGridStateAction,
} from 'store/grids/actions';
import { getGridConfigValue, getSelectedItems, getGridState } from 'store/grids/selectors';

import { loadGridViewColumns } from 'containers/IdeasList/IdeasList/New/helpers';
import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/userViews';
import { getActiveViewForPage } from 'store/userViews/selectors';
import { isApplyFiltersLoading } from 'store/projects/selectors';

import useEnrichedSelectedGroupsFromState from 'hooks/grid/useGridSelectedGroups';
import usePageViewOnGrid from 'hooks/grid/usePageViewOnGrid';

const defaultAsEmptyArray = defaultTo([]);

/**
 * Hook to abstract the access to and actions for the Grid state and config for the store.
 * @param {string} viewType Grid type. See `src/store/grids/constants.js`.
 */
const useCompareRoadmapVersionsGridState = viewType => {
  const dispatch = useDispatch();

  const rowHeight = useSelector(state => getGridConfigValue(state, viewType, 'rowHeight', BASE_ROW_HEIGHT));

  const selectedView = useSelector(state => getGridConfigValue(state, viewType, 'selectedView', DEFAULT_SELECTED_VIEW(viewType)));

  const expandedGroups = useSelector(state => getGridConfigValue(state, viewType, 'expandedGroups'));

  const { selectedGroup1, selectedGroup2, selectedGroup3 } = useEnrichedSelectedGroupsFromState(viewType);

  const isLoadingProjects = useSelector(isApplyFiltersLoading);

  const isGrouping = useMemo(() => {
    return !!selectedGroup1?.key;
  }, [selectedGroup1]);

  const searchString = useSelector(state => getGridConfigValue(state, viewType, 'searchText'));

  const isBulkDeleting = useSelector(state => getGridConfigValue(state, viewType, 'bulkDelete'));

  const selectedItems = useSelector(state => getSelectedItems(state, viewType));

  const setSelectedItems = useCallback(items => dispatch(setSelectedItemsAction(viewType, items)), [dispatch, viewType]);

  const saveGridConfig = useCallback((key, value) => dispatch(saveGridConfigAction(viewType, key, value)), [dispatch, viewType]);

  const gridStateFromStore = useSelector(state => getGridState(state, viewType));

  const path = getCurrentPath();
  const pageId = getPageIdFromPath(path);
  const activeView = useSelector(state => getActiveViewForPage(state, pageId));

  const { shouldConsiderViewDirty, shouldGridBeUnmounted, setDirtyViewMutex } = usePageViewOnGrid({
    activeView,
    isLoading: isLoadingProjects,
  });

  /*
   * For now will only update the grid state when active view changes
   */
  const gridState = useMemo(() => gridStateFromStore, [activeView?.id]);

  const saveColumnsState = useCallback(
    newColumnsState => {
      dispatch(
        saveGridStateAction(
          viewType,
          {
            ...gridState,
            columnState: newColumnsState,
          },
          shouldConsiderViewDirty,
        ),
      );
    },
    [dispatch, viewType, gridState],
  );

  const defaultViewCols = useMemo(
    () =>
      loadGridViewColumns(
        selectedView.key,
        [],
        {}, // organization ? { has_hierarchy: organization.has_hierarchy, currentUser } : {},
      ),
    [],
  );

  /**
   * This needs improvement, we had to duplicate this hook from the useGridState hook to
   * get the accurate visible columns for comparing column state - for other grids we 
   * rely on updating state based on the active view, that's not plausible for compare-scenarios
   * since views are not supported in this module
   */
  const visibleColumnsFromGridState = defaultAsEmptyArray(gridStateFromStore?.columnState)
    .filter(column => !column.hide)
    .map(column => column.colId);

  /*
   * Will have visibleColumnsFromGridState legnth as dependency because if is used the
   * gridState?.columnState will always change the visibleColumnsIds
   *
   * As the visibleColumnsFromGridState is an array if ids, we can trust on the length
   */
  const visibleColumnsIds = useMemo(() => {
    if (visibleColumnsFromGridState.length > 0) {
      return visibleColumnsFromGridState;
    }

    return defaultViewCols;
  }, [...visibleColumnsFromGridState, defaultViewCols]);

  const removeGridRow = useCallback(({ id } = {}) => dispatch(saveGridConfigAction(viewType, 'ideaToDelete', id)), []);

  return {
    rowHeight,
    expandedGroups,
    selectedView,
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    isGrouping,
    searchString,
    isBulkDeleting,
    selectedItems,
    columnsState: gridState.columnState,
    visibleColumnsIds,
    shouldGridBeUnmounted,

    saveGridConfig,
    setSelectedItems,
    saveColumnsState,
    removeGridRow,
    setDirtyViewMutex,
  };
};

export default useCompareRoadmapVersionsGridState;
