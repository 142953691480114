import buildTree from './utils/buildTree';
import { getSorting, stableSort, SortDirection } from './utils/sorting';
import actionableColumnsUtils from './utils/actionableColumnsUtils';
import isFunction from 'lodash/isFunction';
import buildEditMap from './utils/buildEditMap';

export const rowGetter = state => ({ index }) => {
  const { addingNew, page, rows, props } = state;
  const { overflowAfter, enablePagination } = props;

  let rowOffset = 0;

  if (enablePagination) {
    // eslint-disable-next-line no-mixed-operators
    rowOffset = page * overflowAfter + 1;
  }

  // const getItem = treeView ? i => indexedRows[indexedTree[i]] : i => rows[i];
  // const getItem =  i => rows[i];
  // eslint-disable-next-line no-mixed-operators
  const accessIndex = index + rowOffset - Number(addingNew);

  // return getItem(accessIndex);
  return rows[accessIndex];
};

export const _isEditable = (row, editable) => (row ? (isFunction(editable) ? editable(row) : editable) : true);

export const actions = {
  selectCell(state, payload) {
    const { scrollToRow, scrollToColumn, page, isClick } = payload;
    let newScrollToColumn = scrollToColumn === 'first-editable-column' ? state.firstEditableColumnIndex : scrollToColumn;
    let newScrollToRow = scrollToRow;

    const actionColumns = actionableColumnsUtils(state.props);

    newScrollToColumn = scrollToColumn < actionColumns.length ? actionColumns.length : scrollToColumn;

    const isEditable = state.editableMap[newScrollToRow] ? state.editableMap[newScrollToRow][newScrollToColumn] : true;

    if (state.props.skipArrowStepOnNonEditable && !isEditable) {
      if (isClick) {
        return state;
      }

      const isCellEditable = () => state.editableMap[newScrollToRow][newScrollToColumn];
      const totalRows = state.editableMap.length;
      const totalColumns = state.columnsProps.length;

      if (state.scrollToColumn > scrollToColumn) {
        // left
        for (; newScrollToColumn > 0 && !isCellEditable(); newScrollToColumn--);
      } else if (state.scrollToColumn < scrollToColumn) {
        // right
        for (; newScrollToColumn < totalColumns && !isCellEditable(); newScrollToColumn++);
      } else if (state.scrollToRow > scrollToRow) {
        // up
        for (; newScrollToRow >= 0 && !isCellEditable(); newScrollToRow--);
      } else {
        // down
        for (; newScrollToRow < totalRows && !isCellEditable(); newScrollToRow++);
      }

      // is out of bounds in some way
      if (newScrollToColumn <= 0 || newScrollToColumn === totalColumns) {
        newScrollToColumn = state.scrollToColumn;
      } else if (newScrollToRow < 0 || newScrollToRow === totalRows) {
        newScrollToRow = state.scrollToRow;
      }
    }

    return {
      ...state,
      scrollToColumn: newScrollToColumn,
      scrollToRow: newScrollToRow,
      editSelectedCell: false,
      page,
    };
  },
  addNew(state, payload) {
    const actionColumns = actionableColumnsUtils(state.props);

    return {
      ...state,
      addingNew: true,
      scrollToColumn: state.firstEditableColumnIndex + 1 + actionColumns.length,
      scrollToRow: 0,
    };
  },
  editCell: state => {
    return { ...state, editSelectedCell: true };
  },
  cancelEdit: state => ({
    ...state,
    addingNew: false,
    editSelectedCell: false,
  }),
  recalculateOverflow(state, payload) {
    const { tableContainer } = payload;

    if (!tableContainer) return state;

    const overflow = {
      height: Math.abs(tableContainer.scrollHeight - tableContainer.clientHeight),
      width: Math.abs(tableContainer.scrollWidth - tableContainer.clientWidth),
      containerHeight: tableContainer.clientHeight,
      containerWidth: tableContainer.clientWidth,
    };

    return { ...state, overflow };
  },
  calculateOverflowAfter(state, payload) {
    const { overflowAfter, headerHeight, rowHeight, rows, minRows, calculatedHeaderHeight } = payload;

    const renderRows = Math.min(Math.max(rows.length, minRows), overflowAfter);

    const rowTotalHeight = renderRows * rowHeight;

    return {
      ...state,
      height: rowTotalHeight + headerHeight + calculatedHeaderHeight,
    };
  },
  setRows(state, rows) {
    const indexedRows = indexRows(rows);

    return {
      ...state,
      rows,
      indexedRows,
      ...buildEditMap(state.rows, state.columnsProps),
    };
  },
  setProps(state, props) {
    const columnsProps = props.children.map(c => c.props);

    return {
      ...state,
      props,
      columnsProps,
      ...buildEditMap(state.rows, columnsProps),
    };
  },
  buildTree(state, parentPropertyKey) {
    const builtTree = buildTree(state.rows, parentPropertyKey);

    return { ...state, ...builtTree };
  },
  orderBy(state, { property, dataMapper }) {
    if (state.orderBy === property && state.order === SortDirection.ASC) {
      return {
        ...state,
        order: null,
        orderBy: null,
        orderByDataMapper: null,
      };
    }

    const orderBy = property;
    let order = SortDirection.DESC;

    if (state.orderBy === property && state.order === SortDirection.DESC) {
      order = SortDirection.ASC;
    }

    return { ...state, order, orderBy, orderByDataMapper: dataMapper };
  },
  updateOrder(state) {
    const { order, orderBy, orderByDataMapper, rows } = state;

    const sortedRows = stableSort(rows, getSorting(order, orderBy, orderByDataMapper));

    return { ...state, rows: sortedRows, indexedRows: indexRows(sortedRows) };
  },
  toggleSelectRow(state, id) {
    const selectedRows = { ...state.selectedRows };

    if (selectedRows[id]) {
      delete selectedRows[id];
    } else selectedRows[id] = true;

    return { ...state, selectedRows, totalSelectedRows: Object.keys(selectedRows).length };
  },
  selectAllRows(state) {
    if (state.rows.length === state.totalSelectedRows) return { ...state, selectedRows: {}, totalSelectedRows: 0 };

    return { ...state, selectedRows: { ...state.indexedRows }, totalSelectedRows: state.rows.length };
  },
  toggleParent(state, id) {
    const collapsedGroups = { ...state.collapsedGroups };

    if (collapsedGroups[id]) delete collapsedGroups[id];
    else collapsedGroups[id] = true;

    return {
      ...state,
      collapsedGroups,
      rows: state.props.rows.filter(r => !collapsedGroups[r[state.props.treeViewProperty]]),
    };
  },
};

export const initialState = {
  scrollToColumn: -1,
  scrollToRow: -1,
  editSelectedCell: false,
  addingNew: false,
  overflow: { width: null, height: null },
  page: -1,
  rows: [],
  order: null,
  orderBy: null,
  selectedRows: {},
  totalSelectedRows: 0,
  collapsedGroups: {},
};

function indexRows(rows) {
  return rows.reduce((acc, r) => {
    acc[r.id] = r;
    return acc;
  }, {});
}
