import { SET_AUTH_TOKEN, fetchUserIntegrations } from 'store/login';
import { fetchOrgIntegrations } from 'store/organization/actions';

export default store => next => action => {
  if (action.type === SET_AUTH_TOKEN) {
    store.dispatch(fetchOrgIntegrations);
    store.dispatch(fetchUserIntegrations);
  }

  return next(action);
};
