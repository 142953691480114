/* eslint-disable react/jsx-no-target-blank */
/**
 * AgGridJiraDisplay Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { INTEGRATIONS_ICON_MAPPER } from 'src/constants/integrations';

export default class AgGridIntegrationIconsColumn extends PureComponent {
  render() {
    const {
      data,
      colDef: { orgIntegrations },
    } = this.props;
    const integrations = data?.integrations || [];

    // Cleanup duplicates to avoid showing the same icon twice if something bad happens
    const uniqueIntegrations = [...new Map(integrations.map(i => [i.org_integration_id, i])).values()];

    if (!orgIntegrations || orgIntegrations.length === 0) return <span />;

    const icons = uniqueIntegrations.map(({ id, org_integration_id: orgIntegrationId }) => {
      const orgIntegration = orgIntegrations.find(orgIntegration => orgIntegration.id === orgIntegrationId);

      if (!orgIntegration) {
        return null;
      }

      const IntegrationIcon = INTEGRATIONS_ICON_MAPPER[orgIntegration.integrationType];

      return <IntegrationIcon key={id} />;
    });

    return <Wrapper>{icons}</Wrapper>;
  }
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 10px;
`;
