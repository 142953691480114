import {
  VOTE_ON_PROJECT_PENDING,
  VOTE_ON_PROJECT_REJECTED,
  UNVOTE_ON_PROJECT_PENDING,
  UNVOTE_ON_PROJECT_REJECTED,
  FETCH_VOTES_PER_CUSTOMER_REQUEST_FULFILLED,
  VOTE_ON_CUSTOMER_REQUEST_PENDING,
  VOTE_ON_CUSTOMER_REQUEST_REJECTED,
  UNVOTE_ON_CUSTOMER_REQUEST_PENDING,
  UNVOTE_ON_CUSTOMER_REQUEST_REJECTED,
  FETCH_VOTES_PER_PROJECT_FULFILLED,
} from './types';
import { APPLY_FILTERS_FULFILLED } from 'store/projects';

const initialState = {
  votesPerProject: {},
  votesPerCustomerRequest: {},
};

const getVotesDataByAction = (payload, actionType) => {
  return actionType === APPLY_FILTERS_FULFILLED ? payload?.votes : payload;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case APPLY_FILTERS_FULFILLED:
    case FETCH_VOTES_PER_PROJECT_FULFILLED:
      const votes = getVotesDataByAction(action.payload, action.type);

      if (!votes) {
        return state;
      }
      const votesPerProject = votes.reduce((votesPerProject, entry) => {
        const { project_id: projectId, ...params } = entry;

        return {
          ...votesPerProject,
          [projectId]: params,
        };
      }, {});

      return {
        ...state,
        votesPerProject,
      };
    case UNVOTE_ON_PROJECT_REJECTED:
    case VOTE_ON_PROJECT_PENDING:
      if (!action.meta.project_id) return state;

      const pendingVoteEntry = state.votesPerProject[action.meta.project_id] || { count: 0, '5_users_ids': [] };

      return {
        ...state,
        votesPerProject: {
          ...state.votesPerProject,
          [action.meta.project_id]: {
            count: +pendingVoteEntry.count + 1,
            '5_users_ids': [
              ...(pendingVoteEntry['5_users_ids'] || []),
              ...((pendingVoteEntry['5_users_ids'] || []).length < 5 ? [action.meta.user_id] : []),
            ],
            had_vote: true,
          },
        },
      };
    case VOTE_ON_PROJECT_REJECTED:
    case UNVOTE_ON_PROJECT_PENDING:
      if (!action.meta.project_id) return state;

      const rejectedVoteEntry = state.votesPerProject[action.meta.project_id];

      if (!rejectedVoteEntry) return;

      return {
        ...state,
        votesPerProject: {
          ...state.votesPerProject,
          [action.meta.project_id]: {
            count: +rejectedVoteEntry.count - 1,
            '5_users_ids': (rejectedVoteEntry['5_users_ids'] || []).filter(id => id !== action.meta.user_id),
            had_vote: false,
          },
        },
      };
    case FETCH_VOTES_PER_CUSTOMER_REQUEST_FULFILLED:
      const votesPerCustomerRequest = action.payload.reduce((votesPerCustomerRequest, entry) => {
        const { customer_request_id: customerRequestId, ...params } = entry;

        return {
          ...votesPerCustomerRequest,
          [customerRequestId]: params,
        };
      }, {});

      return {
        ...state,
        votesPerCustomerRequest,
      };
    case UNVOTE_ON_CUSTOMER_REQUEST_REJECTED:
    case VOTE_ON_CUSTOMER_REQUEST_PENDING:
      if (!action.meta.customer_request_id) return state;

      const pendingCustomerRequestVoteEntry = state.votesPerCustomerRequest[action.meta.customer_request_id] || { count: 0 };

      return {
        ...state,
        votesPerCustomerRequest: {
          ...state.votesPerCustomerRequest,
          [action.meta.customer_request_id]: {
            count: +pendingCustomerRequestVoteEntry.count + 1,
            had_vote: true,
          },
        },
      };
    case VOTE_ON_CUSTOMER_REQUEST_REJECTED:
    case UNVOTE_ON_CUSTOMER_REQUEST_PENDING:
      if (!action.meta.customer_request_id) return state;

      const rejectedCustomerRequestVoteEntry = state.votesPerCustomerRequest[action.meta.customer_request_id];

      if (!rejectedCustomerRequestVoteEntry) return;

      return {
        ...state,
        votesPerCustomerRequest: {
          ...state.votesPerCustomerRequest,
          [action.meta.customer_request_id]: {
            count: +rejectedCustomerRequestVoteEntry.count - 1,
            had_vote: false,
          },
        },
      };
    default:
      return state;
  }
}
