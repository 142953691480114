import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { equals, either, isEmpty, isNil, not, pipe } from 'ramda';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';

import { INTEGRATIONS_KEYS, WEBHOOKS_PROJECT_RESTRICTIONS_TYPES } from 'constants/integrations';

import { updateJiraIntegration as updateJiraIntegrationAction } from 'store/organization';

import useAppNotifications from 'hooks/useAppNotifications';
import useIntegrationWebhooksProjectRestrictions from 'hooks/useIntegrationWebhooksProjectRestrictions';

import AzureDevopsProjectsDropdown from '../../AzureDevops/components/AzureWebhook/components/AzureDevopsProjectsDropdown';
import JiraProjectsDropdown from '../../Jira/components/JiraWebhooks/components/JiraProjectsDropdown';
import IntegrationPermissionsDialog from '../IntegrationPermissionsDialog';
import { UPDATE_JIRA_WEBHOOKS_PROJECT_RESTRICTIONS } from '../../constants/validationDialogActionTypes';

const isEqualsAll = either(equals(WEBHOOKS_PROJECT_RESTRICTIONS_TYPES.ALL), isNil);
const isEqualsInclude = equals(WEBHOOKS_PROJECT_RESTRICTIONS_TYPES.INCLUDE);
const isNotEqualsInclude = pipe(equals(WEBHOOKS_PROJECT_RESTRICTIONS_TYPES.INCLUDE), not);
const isNotEmpty = pipe(isEmpty, not);

const isIntegrationTypeJira = equals(INTEGRATIONS_KEYS.JIRA);
const isIntegrationTypeAzureDevOps = equals(INTEGRATIONS_KEYS.azuredevops);

const UPDATE_RESTRICTIONS_TOAST_MESSAGE = 'Webhooks restrictions updated';

/**
 * This component (WebhooksProjectRestrictions) has specific behaviour for Jira integration
 * because Jira integration does not use Integration Gateway and already has specifc flows
 * regarding Org Integration configurations.
 */
const WebhooksProjectRestrictions = props => {
  const { integrationType, orgIntegrationId, webhooksEnabled, userCanUpdateOrgIntegration } = props;

  const dispatch = useDispatch();

  const includeProjectsDropdownRef = useRef();

  const { addNotification } = useAppNotifications();
  const { webhooksProjectRestrictions, isUpdating, includedProjects, updateWebhooksProjectRestrictions } =
    useIntegrationWebhooksProjectRestrictions(integrationType, orgIntegrationId);

  const [selectedType, setSelectedType] = useState(webhooksProjectRestrictions.type);
  const [validatePermissionsDialogOpen, setValidatePermissionsDialogOpen] = useState(false);
  const [actionPayload, setActionPayload] = useState({});

  const validatePermissionsBeforeUpdate = useCallback(
    payload => {
      setActionPayload(payload);
      setValidatePermissionsDialogOpen(true);
    },
    [setActionPayload, setValidatePermissionsDialogOpen],
  );

  const resetValidationPermissionsDialog = useCallback(() => {
    setValidatePermissionsDialogOpen(false);
    setActionPayload({});
  }, [setValidatePermissionsDialogOpen, setActionPayload]);

  const resetForm = useCallback(() => {
    setSelectedType(webhooksProjectRestrictions.type);
    includeProjectsDropdownRef.current.resetSelectedProjects();

    resetValidationPermissionsDialog();
  }, [webhooksProjectRestrictions.type, resetValidationPermissionsDialog]);

  const dispatchUpdateJiraIntegrationAction = useCallback(() => {
    return dispatch(updateJiraIntegrationAction(orgIntegrationId, actionPayload))
      .then(() => addNotification({ message: UPDATE_RESTRICTIONS_TOAST_MESSAGE }))
      .catch(() => resetForm());
  }, [orgIntegrationId, actionPayload, addNotification, resetForm]);

  const handleTypeUpdate = useCallback(
    type => {
      setSelectedType(type);

      if (isEqualsAll(type)) {
        if (isIntegrationTypeJira(integrationType)) {
          const payload = { webhooksProjectRestrictions: { type } };

          return validatePermissionsBeforeUpdate(payload);
        }

        return updateWebhooksProjectRestrictions({ type })
          .then(() => addNotification({ message: UPDATE_RESTRICTIONS_TOAST_MESSAGE }))
          .catch(() => resetForm());
      }
    },
    [
      integrationType,
      orgIntegrationId,
      setSelectedType,
      updateWebhooksProjectRestrictions,
      addNotification,
      validatePermissionsBeforeUpdate,
      resetForm,
    ],
  );

  const handleProjectsUpdate = useCallback(
    projects => {
      if (isNotEmpty(projects)) {
        if (isIntegrationTypeJira(integrationType)) {
          const payload = { webhooksProjectRestrictions: { type: selectedType, projects } };

          return validatePermissionsBeforeUpdate(payload);
        }

        return updateWebhooksProjectRestrictions({ type: selectedType, projects })
          .then(() => addNotification({ message: UPDATE_RESTRICTIONS_TOAST_MESSAGE }))
          .catch(() => resetForm());
      }
    },
    [integrationType, orgIntegrationId, selectedType],
  );

  const isDisabled = !userCanUpdateOrgIntegration || not(webhooksEnabled) || isUpdating;
  const isIncludeOptionDisabled = isNotEqualsInclude(selectedType) || isDisabled;

  /**
   * NOTE:
   *  - The UI/UX of this component (and child components) need to be revisted because the update action
   * trigger complex operations (re-create webhooks) for that reason it is risky to rely on just update
   * the data on the BE without user confirmation. Impacted use cases:
   *    - Change from All to Include. The user need to select at least on project, for that reason the
   *      update action should be dealyed but in the user prespective the type was already changed to Include
   *    - Since there is no button to confirm the save, currently the onblur of projects selector is the
   *      best option. Anyway the user can click outside of projects dropdown several times and cause several
   *      webhooks re-create actions
   */

  return (
    <Wrapper>
      <FormControlLabel
        control={
          <Radio
            color="primary"
            checked={isEqualsAll(selectedType)}
            onChange={() => handleTypeUpdate(WEBHOOKS_PROJECT_RESTRICTIONS_TYPES.ALL)}
          />
        }
        label="Include all projects"
        disabled={isDisabled}
      />
      <Grid container>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Radio
                color="primary"
                checked={isEqualsInclude(selectedType)}
                onChange={() => handleTypeUpdate(WEBHOOKS_PROJECT_RESTRICTIONS_TYPES.INCLUDE)}
              />
            }
            label="Include only these projects"
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          {isIntegrationTypeAzureDevOps(integrationType) && (
            <AzureDevopsProjectsDropdown
              disabled={isIncludeOptionDisabled}
              isSelected={isEqualsInclude(selectedType)}
              orgIntegrationId={orgIntegrationId}
              forwardedRef={includeProjectsDropdownRef}
              value={includedProjects}
              onChange={handleProjectsUpdate}
            />
          )}
          {isIntegrationTypeJira(integrationType) && (
            <JiraProjectsDropdown
              disabled={isIncludeOptionDisabled}
              isSelected={isEqualsInclude(selectedType)}
              orgIntegrationId={orgIntegrationId}
              forwardedRef={includeProjectsDropdownRef}
              value={includedProjects}
              onChange={handleProjectsUpdate}
            />
          )}
        </Grid>
      </Grid>
      <IntegrationPermissionsDialog
        isDialogVisible={validatePermissionsDialogOpen}
        integrationType="Jira"
        messageActionName={UPDATE_JIRA_WEBHOOKS_PROJECT_RESTRICTIONS}
        onCancel={resetForm}
        onClose={resetValidationPermissionsDialog}
        onConfirm={dispatchUpdateJiraIntegrationAction}
        orgIntegrationId={orgIntegrationId}
      />
    </Wrapper>
  );
};

WebhooksProjectRestrictions.propTypes = {
  integrationType: PropTypes.string.isRequired,
  orgIntegrationId: PropTypes.number.isRequired,
  webhooksEnabled: PropTypes.bool,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default WebhooksProjectRestrictions;
