import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { not } from 'ramda';

import Save from '@material-ui/icons/Save';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiMenuList from '@material-ui/core/MenuList';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '@material-ui/icons/Close';

import FilterIcon from 'design-system/atoms/FilterIcon/index';

import { spacing } from 'design-system/theme';
import { usePopperPlacement } from 'design-system/utils/usePopperPlacement';

import FilterCheckbox from './components/FilterCheckbox';
import FilterSearchbox from './components/FilterSearchbox';
import FilterRadioOptions from './components/FilterRadioOptions';

import { DIRECTION_LEFT, DIRECTION_RIGTH, useChildItemDirection } from './useChildItemDirection';

const CLEAR_FILTER_DEFAULT_LABEL = 'Clear All';
const ADVANCED_SEARCH = 'ADVANCED SEARCH';

const ChildItem = ({
  item,
  selectedItems,
  checked = false,
  draggable = false,
  checkable = false,
  colorable = false,
  renderClearFilter,
  onSelectItem,
  onChangeOrder,
  onChangeColor,
  onChangeSearch,
  direction = DIRECTION_RIGTH,
}) => {
  const [showChildren, setShowChildren] = React.useState(false);

  const _renderChildren = (child = {}) => {
    switch (item.type) {
      case 'check':
        return (
          <FilterCheckbox
            id={item.key}
            menu={item.children}
            menuListProps={item.menuListProps}
            selectedItems={selectedItems}
            draggable={draggable}
            checkable={checkable}
            colorable={colorable}
            renderClearFilter={renderClearFilter}
            onSelectItem={onSelectItem}
            onChangeOrder={onChangeOrder}
            onChangeColor={onChangeColor}
          />
        );

      case 'text':
        return <FilterSearchbox search={selectedItems || ''} onChange={onChangeSearch} />;
      case 'radio':
        return (
          <FilterRadioOptions
            id={item.key}
            menu={item.children}
            menuListProps={item.menuListProps}
            selectedItems={selectedItems}
            onSelectItem={onSelectItem}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <MenuItem id={item.id && `${item.id}`} key={`menu-${item.id}`} onMouseEnter={() => setShowChildren(true)}>
      <MenuItemContainer>
        <ListItemIcon>{checked && <ItemSelector />}</ListItemIcon>
        <ListItemText>{item.label}</ListItemText>
      </MenuItemContainer>
      {showChildren && <MenuChildContainer direction={direction}>{_renderChildren()}</MenuChildContainer>}
    </MenuItem>
  );
};

const MultiFilterSelector = ({
  label,
  anchorEl,
  menu,
  clickMenu,
  hasHeader,
  draggable = false,
  colorable = false,
  selectedItems = {},
  renderHeader,
  onSetAnchorEl,
  onClearFilter,
  onSelectItem,
  onClickItem,
  onStaticFilterClick,
  onApplyFilters,
  onSaveFilter,
  onChangeOrder,
  onChangeColor,
  showSaveFilterDialog,
  customHeaderStyle = {},
  onOpenSavedFilters,
  setRef,
  clearFilterLabel = CLEAR_FILTER_DEFAULT_LABEL,
  onOpenAdvancedSearchPopup,
  ignoreMultiFilterClearButton = false,
  defaultFilters = [],
  staticFilters = [],
  dataTestId,
}) => {
  const open = Boolean(anchorEl);
  const { placement } = usePopperPlacement(anchorEl);
  const { direction: childDirection } = useChildItemDirection(anchorEl);
  const advancedSearchIconRef = useRef(null);

  const _isChecked = items => {
    if (!items) return false;

    return !!Object.keys(items).filter(key => !!items[key]).length;
  };

  const handleClick = event => {
    onSetAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    if (!showSaveFilterDialog) {
      onSetAnchorEl(null);
      if (onApplyFilters) onApplyFilters();
    }
  };

  const _handleClearFilter = item => {
    if (onClearFilter) onClearFilter(item);
  };

  const _handleSearchChange = (event, item) => {
    onSelectItem(item.key, null, event.target.value);
  };

  const _handleChangeOrder = (item, startIndex, endIndex) => {
    onChangeOrder && onChangeOrder(item, startIndex, endIndex);
  };

  const _handleChangeColor = (parent, child, color) => {
    onChangeColor && onChangeColor(parent, child, color);
  };

  const _renderClearFilter = item => {
    return (
      <ClearFilterRow onClick={() => _handleClearFilter(item)}>
        <ListItemIcon>
          <CloseIcon />
        </ListItemIcon>
        <ListItemText uppercase small>
          {clearFilterLabel}
        </ListItemText>
      </ClearFilterRow>
    );
  };

  return (
    <Wrapper>
      {hasHeader && (
        <Header>
          {renderHeader && renderHeader()}
          {!renderHeader && label && (
            <Button
              id="filter-icon"
              aria-controls={open ? 'composition-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              style={{ ...(customHeaderStyle || {}) }}
            >
              {label}
            </Button>
          )}
        </Header>
      )}
      <PopperContainer open={open} anchorEl={anchorEl} role={undefined} placement={placement} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose} disableReactTree="true">
                <div ref={r => setRef && setRef(r)}>
                  <MenuList dataTestId={dataTestId}>
                    {menu &&
                      !clickMenu &&
                      menu.map(item => (
                        <ChildItem
                          key={`menu-${item.key}`}
                          item={item}
                          selectedItems={selectedItems[item.key]}
                          checked={_isChecked(selectedItems[item.key])}
                          draggable={draggable}
                          checkable={item.type === 'check'}
                          colorable={colorable}
                          renderClearFilter={() => <FooterWrapper>{_renderClearFilter(item)}</FooterWrapper>}
                          onSelectItem={(...params) => onSelectItem(item.key, ...params)}
                          onChangeOrder={(...params) => _handleChangeOrder(item, ...params)}
                          onChangeColor={(...params) => _handleChangeColor(item, ...params)}
                          onChangeSearch={event => _handleSearchChange(event, item)}
                          direction={childDirection}
                        />
                      ))}
                  </MenuList>
                  {clickMenu && (
                    <ClickMenuList>
                      {menu && <FilterCheckbox id="1" menu={menu} onClickItem={onClickItem} hideSearch />}
                      {(!!defaultFilters?.length || !!staticFilters?.length) && menu && <Divider />}
                      {!!defaultFilters?.length && (
                        <FilterCheckbox id="1" menu={defaultFilters} onClickItem={onClickItem} hideSearch />
                      )}
                      {!!staticFilters?.length && (
                        <FilterCheckbox id="1" menu={staticFilters} onClickItem={onStaticFilterClick} hideSearch />
                      )}
                    </ClickMenuList>
                  )}
                  <FooterWrapper>
                    {onOpenAdvancedSearchPopup && (
                      <AdvancedSearchRow
                        id="advanced-search-cta"
                        aria-haspopup="true"
                        buttonRef={advancedSearchIconRef}
                        onClick={onOpenAdvancedSearchPopup}
                      >
                        <ListItemIcon>
                          <FilterIcon />
                        </ListItemIcon>
                        <ListItemText uppercase small>
                          {ADVANCED_SEARCH}
                        </ListItemText>
                      </AdvancedSearchRow>
                    )}
                    {onClearFilter && not(ignoreMultiFilterClearButton) && _renderClearFilter()}
                    {onSaveFilter && (
                      <SaveFilterRow onClick={onSaveFilter}>
                        <ListItemIcon>
                          <Save fontSize="small" />
                        </ListItemIcon>
                        <ListItemText uppercase small>
                          Save
                        </ListItemText>
                      </SaveFilterRow>
                    )}
                    {onOpenSavedFilters && (
                      <SavedFiltersRow onClick={onOpenSavedFilters}>
                        <ListItemText uppercase small>
                          Saved filters
                        </ListItemText>
                      </SavedFiltersRow>
                    )}
                  </FooterWrapper>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </PopperContainer>
    </Wrapper>
  );
};

export default MultiFilterSelector;

const Wrapper = styled.div`
  &&&& {
  }
`;

const Header = styled.div`
  &&&& {
  }
`;

const PopperContainer = styled(Popper)`
  z-index: 1500;
`;

const ItemSelector = styled.span`
  &&&& {
    width: 10px;
    height: 10px;

    border-radius: 100%;
    background-color: #58ccc6;
  }
`;

const MenuList = styled(MuiMenuList)`
  &&&& {
    border-radius: 8px;
    overflow: unset;

    min-width: 200px;
  }
`;

const ClickMenuList = styled(MenuList)`
  &&&& {
    padding-top: 0;
    padding-bottom: 0;

    div {
      border-radius: 0;
      box-shadow: none;
    }
  }
`;

const MenuItemContainer = styled.div`
  &&&& {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 14px 1fr;
    gap: ${({ theme }) => theme.spacing.unit * 1.25}px;
  }
`;

const MenuChildContainer = styled.div`
  &&&& {
    position: absolute;
    top: 0;

    ${({ direction }) =>
      direction === DIRECTION_RIGTH &&
      `
      left: 100%;
      padding-left: 8px;
    `}
    ${({ direction }) =>
      direction === DIRECTION_LEFT &&
      `
      right: 100%;
      padding-right: 8px;
    `}
  }
`;

const ListItemText = styled(MuiListItemText)`
  &&&& {
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;

    ${({ uppercase }) =>
      uppercase &&
      `
      text-transform: uppercase;
    `}
    span {
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      font-size: ${props => props.theme.typography.fontSize}px;
      line-height: ${props => props.theme.typography.lineHeightRegular}px;
      color: ${props => props.theme.palette.text.lightGrey};
    }
  }
`;

const ListItemIcon = styled(MuiListItemIcon)`
  &&&& {
    margin: auto;

    display: flex;
    justify-content: space-between;

    svg {
      width: 12px !important;
      height: 12px !important;
    }
  }
`;

const MenuItem = styled(MuiMenuItem)`
  &&&& {
    position: relative;
    overflow: unset;

    padding: 5px 15px;
    box-sizing: border-box;
    height: 30px;
    margin: 0.5px 0;

    ${MenuChildContainer} {
      display: none;
    }

    &:hover {
      ${MenuChildContainer} {
        display: block;
      }

      ${MenuItemContainer} {
        ${ListItemText} {
          span {
            color: #2ea8e1;
          }
        }

        ${ListItemIcon} {
          svg {
            fill: #2ea8e1;
          }
        }
      }
    }
  }
`;

const ClearFilterRow = styled.div`
  &&&& {
    cursor: pointer;

    width: 100%;
    /* height: 100%; */

    display: grid;
    grid-template-columns: 14px 1fr;
    gap: ${spacing(1)}px;

    padding: 5px 15px;
    box-sizing: border-box;
    height: 30px;
    margin: 0.5px 0;

    svg {
      width: 16px !important;
      height: 16px !important;
    }

    span {
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      font-size: ${props => props.theme.typography.fontSize}px;
      line-height: ${props => props.theme.typography.lineHeightRegular}px;
      color: ${props => props.theme.palette.text.lightGrey};
    }
  }
`;

const SaveFilterRow = styled(ClearFilterRow)`
  &&&& {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    ${ListItemText} {
      span {
        color: #2ea8e1;
      }
    }

    ${ListItemIcon} {
      svg {
        fill: #2ea8e1;
      }
    }
  }
`;

const SavedFiltersRow = styled.div`
  &&&& {
    display: flex;
    align-items: center;

    width: 100%;
    min-width: 200px;
    height: 100%;

    box-sizing: border-box;

    padding: 10px 16px;

    cursor: pointer;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    border-top: 1px solid #fafafa;
    box-shadow: 0px -2px 12px 0px rgb(204 204 204 / 30%);

    ${ListItemText} {
      span {
        color: #2ea8e1;
      }
    }
  }
`;

const FooterWrapper = styled.div`
  &&&& {
    border-top: 1px solid #fafafa;
    box-shadow: 0px -2px 12px 0px rgb(204 204 204 / 30%);
  }
`;

const AdvancedSearchRow = styled(ClearFilterRow)`
  font-weight: ${props => props.theme.typography.fontWeightRegular};
  font-size: ${props => props.theme.typography.fontSize}px;
  line-height: ${props => props.theme.typography.lineHeightRegular}px;
  color: ${props => props.theme.palette.text.lightGrey};
`;

MultiFilterSelector.propTypes = {
  label: PropTypes.string,
  anchorEl: PropTypes.any,
  menu: PropTypes.arrayOf(
    PropTypes.objectOf({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      type: PropTypes.oneOf(['check', 'text']),
      children: PropTypes.arrayOf(
        PropTypes.objectOf({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string,
        }),
      ),
    }),
  ),
  selectedItems: PropTypes.object,
  renderHeader: PropTypes.func,
  onSetAnchorEl: PropTypes.func.isRequired,
  onClearFilter: PropTypes.func.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  onSaveFilter: PropTypes.func.isRequired,
  onOpenAdvancedSearchPopup: PropTypes.func,
  ignoreMultiFilterClearButton: PropTypes.bool,
};
