import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditorState } from 'draft-js';
import { convertFromHTML } from 'draft-convert';

import statusValues from 'store/customerRequests/constants/status';
import { notifyCustomerRequestWatchers as notifyCustomerRequestWatchersAction } from 'store/customerRequests/actions';
import { getCustomerRequestFormData } from 'store/customerRequests/selectors';

/**
 * Since TextAreaEditor always returns a html string with the content we must convert into plain text
 * for a proper and save render in the email template.
 * This way we keep consistency with how we render the email notification of a new 'Commment'.
 */
const convertHtmlMessageToPlain = (message) => {
  const editorState = EditorState.createWithContent(convertFromHTML(message));

  return editorState.getCurrentContent().getPlainText();
};

export default Component => {
  return props => {
    const { isOpen, onClose } = props;

    const dispatch = useDispatch();

    const formData = useSelector(getCustomerRequestFormData);

    const [message, setMessage] = useState('');
    const [prevStatus, setPrevStatus] = useState(formData.status);
    const [emailNotificationData, setEmailNotificationData] = useState({});

    const getStatusLabel = (status) => (statusValues[status] || {}).label;

    const onChangeHandler = value => setMessage(value);

    const onSendEmailNotification = () => {
      dispatch(notifyCustomerRequestWatchersAction(formData.id, {
        message: convertHtmlMessageToPlain(message),
        ...emailNotificationData,
      }));

      onClose();
    };

    useEffect(() => {
      const { title, status } = formData;
      const prevStatusLabel = getStatusLabel(prevStatus);
      const currentStatusLabel = getStatusLabel(status);

      setEmailNotificationData({ prevStatus: prevStatusLabel, currentStatus: currentStatusLabel });
      setMessage(`${title} status has been changed from ${prevStatusLabel} to ${currentStatusLabel}.`);
      setPrevStatus(formData.status);
    }, [formData.title, formData.status]);

    return (
      <Component
        isOpen={isOpen}
        message={message}
        onCancel={onClose}
        onConfirm={onSendEmailNotification}
        onChange={onChangeHandler}
      />
    );
  };
};
