import React from 'react';
import styled, { css } from 'styled-components';

import MapOutlinedIcon from '@material-ui/icons/MapOutlined';

export default ({ width, height, fontSize, ...props }) => (
  <StyledMapOutlinedIcon width={width} height={height} fontSize={fontSize} {...props} />
);

const StyledMapOutlinedIcon = styled(MapOutlinedIcon)`
  && {
    font-size: ${({ theme, fontSize }) => fontSize || `${theme.typography.fontSizeMedium}px`};

    ${({ width }) =>
      width &&
      css`
        width: ${width};
      `}

    ${({ height }) =>
      height &&
      css`
        height: ${height};
      `}
  }
`;
