import { addListener } from '@reduxjs/toolkit';
import { includes } from 'ramda';

import { SAVE_AUTO_TAGS_FULFILLED, SET_AUTO_TAGS_AS_TAGS_FULFILLED } from 'features/CustomerRequestsAutoTag/store/types';
import { ADD_TAG_TO_CUSTOMER_REQUEST_FULFILLED, REMOVE_TAG_FROM_CUSTOMER_REQUEST_FULFILLED } from 'store/customerRequests/types';
import { getSelectedAnalyseClusterBy } from './selectors';
import { getFiltersCompiled } from 'store/customerRequests/selectors';
import { fetchCustomerRequestsClustersForAnalyseChart } from './thunks';

/**
 * Subscribes to some actions to refresh the bubble chart clusters data when customer request tags are updated
 *
 * @returns {Object} the addListener action creator
 */
export const subscribeToRefreshBubbleChartClustersOnTagsChanged = () => {
  return addListener({
    matcher: action =>
      includes(action.type, [
        SAVE_AUTO_TAGS_FULFILLED,
        SET_AUTO_TAGS_AS_TAGS_FULFILLED,
        ADD_TAG_TO_CUSTOMER_REQUEST_FULFILLED,
        REMOVE_TAG_FROM_CUSTOMER_REQUEST_FULFILLED,
      ]),
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners();

      const { getState, dispatch } = listenerApi;

      const state = getState();
      const selectedClusterBy = getSelectedAnalyseClusterBy(state);
      const filters = getFiltersCompiled(state);

      dispatch(fetchCustomerRequestsClustersForAnalyseChart(selectedClusterBy?.key, filters));
    },
  });
};
