import moment from 'moment';
import { createSelector } from 'reselect';
import head from 'lodash/head';

import { getOrganizationSystemFieldsNames } from 'store/organization';
import { getIsDodActive } from 'store/accessControl/selectors';
import { getDisplayLayer, getPageFilters } from 'store/filters/selectors';
import { getNestedProjectGroups } from 'store/projects/helpers/groupSelectors';
import { getAllProjectsFiltered } from 'store/projects/groupSelectors';
import { selectKeyResults1, getObjectives, selectKeyResults2 } from 'store/objectives/selectors';
import { getProducts, getRoadmaps, getCorpRoadmaps } from 'store/roadmaps/selectors';
import { getTimeframes, getTimeframesLevel2, getTimeframesLevelCorp } from 'store/timeframes/selectors';
import { getCategories, getCategoriesLevelCorp } from 'store/categories/selectors';
import { getThemes } from 'store/themes/selectors';
import { getPriorities } from 'store/priorities/selectors';
import { getPhases } from 'store/phases/selectors';
import { getUsers } from 'store/users/selectors';
import { getTags } from 'store/tags/selectors';
import { getAllTeams } from 'store/teams/selectors';
import { getCustomersFilteredByStatus } from 'store/customers/selectors';
import { getAllBets, getAllInitiatives } from 'store/projects';
import { getDropdownCustomFields } from 'store/customFields/selectors';
import { getPlanningStages, getStatusColors } from 'store/projects/helpers/groupOptions';
import {
  getBaseCompareVersionSelected,
  getScenarioVersionsColors,
  getSelectedRoadmapVersionsWithColors,
} from 'store/roadmapVersions/selectors';

import getSystemFieldName from 'utils/getSystemFieldName';
import { getGroupOptions } from './helpers/groupOptions';
import { getGroupingObjects } from './helpers/groupingObjects';
import getStateDataForPage from 'store/utils/getStateDataForPage';

import { materialColors } from 'design-system/themes/default';
import { METADATA_LEVELS, PLAN_OF_RECORD_ID, PLAN_OF_RECORD_TITLE } from 'constants/common';
import { hideEmptyBasedOnPrefOrArchived } from 'utils/grouping';

const getDefaultGroupDataForUndefined = ({ id = PLAN_OF_RECORD_ID, name = PLAN_OF_RECORD_TITLE } = {}, scenarioColors = {}) => ({
  roadmap_version_id: id,
  scenarioVersions: { id, name, color: scenarioColors?.[id] ?? materialColors.mediumPurple },
});

const getGroupOptionsSelector = createSelector(
  getOrganizationSystemFieldsNames,
  getIsDodActive,
  (_, options) => options,
  (systemFieldsNames, isDodActive, options) => {
    const customGetSystemFiedName = name => getSystemFieldName(name, systemFieldsNames, false);

    return getGroupOptions({
      getSystemFieldName: customGetSystemFiedName,
      isDodActive,
      ...options,
    });
  },
);

/**
 * Creates a selector that retrieves all the data from the store needed for grouping.
 *
 * @returns A selector with all the data objects needed for grouping.
 */
const getDefaultGroupingObjects = createSelector(
  state =>
    getStateDataForPage(
      state,
      (state, showArchived) => getObjectives(state, showArchived, METADATA_LEVELS.LEVEL_CORP),
      'objectivesCorp',
    ),
  (state, overrideShowArchived) => getStateDataForPage(state, getObjectives, 'objectives', overrideShowArchived),
  state => getStateDataForPage(state, selectKeyResults1, 'keyResults'),
  state => getStateDataForPage(state, (state, showArchived) => selectKeyResults2(state, showArchived), 'keyResult2s'),
  (state, overrideShowArchived) =>
    getStateDataForPage(
      state,
      (state, showArchived) => getCorpRoadmaps(state, showArchived),
      'roadmapsCorp',
      overrideShowArchived,
    ),
  (state, overrideShowArchived) => getStateDataForPage(state, getRoadmaps, 'roadmaps', overrideShowArchived),
  state => getStateDataForPage(state, getProducts, 'products'),
  state => getStateDataForPage(state, getTimeframesLevelCorp, 'timeframesCorp'),
  (state, overrideShowArchived) => getStateDataForPage(state, getTimeframes, 'timeframes', overrideShowArchived),
  state => getStateDataForPage(state, getTimeframesLevel2, 'timeframes'),
  state => getStateDataForPage(state, getCategoriesLevelCorp, 'categoriesCorp'),
  state => getStateDataForPage(state, getCategories, 'categories'),
  state => getStateDataForPage(state, getThemes, 'themes'),
  state => getPriorities(state),
  state => getStateDataForPage(state, getPhases, 'phases'),
  state => getUsers(state),
  state => getTags(state),
  state => getStateDataForPage(state, getCustomersFilteredByStatus, 'customers'),
  state => getAllInitiatives(state),
  state => getAllBets(state),
  state => getDropdownCustomFields(state),
  state => getSelectedRoadmapVersionsWithColors(state),
  state => getAllTeams(state),
  (
    objectivesCorp,
    objectives,
    keyResults1,
    keyResults2,
    roadmapsCorp,
    roadmaps,
    products,
    timeframesCorp,
    timeframes,
    timeframes2,
    categoriesCorp,
    categories,
    themes,
    priorities,
    phases,
    users,
    tags,
    customers,
    initiatives,
    bets,
    customFields,
    scenarioVersions,
    teams,
  ) => {
    return {
      objectivesCorp,
      objectives,
      keyResults1,
      keyResults2,
      roadmapsCorp,
      roadmaps,
      products,
      timeframesCorp,
      timeframes,
      timeframes2,
      categoriesCorp,
      categories,
      themes,
      priorities,
      phases,
      users,
      tags,
      customers,
      initiatives,
      bets,
      statusColors: getStatusColors(),
      planningStages: getPlanningStages(),
      customFields,
      scenarioVersions,
      teams,
    };
  },
);

const formatGroupedDate = (date, selectedGroupByDates) => {
  switch (selectedGroupByDates?.groupBy) {
    case 'month': {
      return {
        id: moment(date).format('YYYY-MM'),
        title: moment(date).format('YYYY-MM'),
        start: moment(date).startOf('month'),
        end: moment(date).endOf('month'),
      };
    }
    case 'year': {
      return {
        id: moment(date).format('YYYY'),
        title: moment(date).format('YYYY'),
        start: moment(date).startOf('year'),
        end: moment(date).endOf('year'),
      };
    }
    default: {
      return {
        id: `${moment(date).format('YYYY')}-Q${moment(date).format('Q')}`,
        title: `${moment(date).format('YYYY')}-Q${moment(date).format('Q')}`,
        start: moment(date).startOf('quarter'),
        end: moment(date).endOf('quarter'),
      };
    }
  }
};

/**
 * Get all groups based on the filtered projects and the selected fields for grouping.
 *
 * @param {Object} groupingOptions
 * @param {Object} groupingOptions.selectedGroup1 The info about the field to be used for groups of level 1
 * @param {Object} groupingOptions.selectedGroup2 The info about the field to be used for groups of level 2
 * @param {Object} groupingOptions.selectedGroup3 The info about the field to be used for groups of level 3
 * @param {String} groupingOptions.page The page filter
 * @param {Boolean} groupingOptions.onlyProjects The flag to includes only projects
 * @param {Boolean} groupingOptions.hideEmpty If active should hide groups without elements
 * @param {Function} groupingOptions.hideEmptyWith use function to evaluate active should hide groups without elements
 * @param {Boolean} groupingOptions.withHierarchy If the result should consider the project hierarchy, meaning that
 * the projects should not be duplicated as children of other projects and also exists in the specific layer
 * @param {Function} groupingOptions.projectsFilters An array of filter functions to apply to all the projects
 * @param {Function} groupingOptions.projectMapper A mapper function to apply to the projects
 * @param {Function} groupingOptions.customMetadata A custom metadata object to override the store defaults
 * @param {Function} groupingOptions.customAllProjectsByLayer A custom set of projects by layer to be considered for grouping
 * @param {Boolean} groupingOptions.applyPageFiltersToMetadataGroup Use page filters to determine relevant metadata
 * @param {Object} groupingOptions.groupFilterFunctions Object of functions that has keys referring to group keys that need
 * to be filtered, mainly for the purpose of filtering groups if they have a hierarchal relationship
 * @returns The groups hierarchy structure with the three level groups
 */
const selectScenarioProjectGroups = createSelector(
  (state, { customAllProjectsByLayer, page, onlyProjects } = {}) => {
    return customAllProjectsByLayer || getAllProjectsFiltered(state, page, onlyProjects);
  },
  (state, { overrideShowArchived = true }) => getDefaultGroupingObjects(state, overrideShowArchived),
  state => getDisplayLayer(state),
  state => state.organization.organization.has_bet,
  state => getPageFilters(state),
  (_, groupingOptions = {}) => groupingOptions,
  state => getBaseCompareVersionSelected(state),
  state => getScenarioVersionsColors(state),
  (
    allProjectsByLayer,
    defaultGroupingObjects,
    displayLayer,
    hasBets,
    pageFilters,
    {
      selectedGroup1 = null,
      selectedGroup2 = null,
      selectedGroup3 = null,
      hideEmptyUserPref = true,
      withHierarchy = false,
      projectMapper = null,
      groupFilterFunctions = null,
    },
    baseCompareVersionSelected,
    scenarioColors,
  ) => {
    const groups = [selectedGroup1, selectedGroup2, selectedGroup3];

    const selectedGroupByDates = head([selectedGroup1, selectedGroup2, selectedGroup3].filter(group => group?.type === 'dates'));

    const hasDateGrouping = !!selectedGroupByDates;

    const projectsForGrouping = allProjectsByLayer[displayLayer];

    const groupedDates = hasDateGrouping
      ? projectsForGrouping
          .map(p => p[selectedGroupByDates?.field])
          .sort((a, b) => moment(a) - moment(b))
          .filter(d => moment(d).isValid())
          .map(d => formatGroupedDate(d, selectedGroupByDates))
          .reduce((result, d) => {
            result[d.title] = d;
            return result;
          }, {})
      : null;

    const groupingObjects = getGroupingObjects({
      defaultGroupingObjects,
      groupedDates,
    });

    const hideEmptyWith = (_, groupObject) => hideEmptyBasedOnPrefOrArchived({ groupObject, hideEmptyUserPref });

    const nestedProjectGroups = getNestedProjectGroups(projectsForGrouping, groupingObjects, groups, {
      groupFilterFunctions,
      hideEmptyWith,
      projectMapper,
      withHierarchy,
    });

    // Add groupData to undefined group. It is actually plan of Record group
    const defaultUndefinedGroup = nestedProjectGroups.find(group => group?.title === 'Undefined');

    if (defaultUndefinedGroup) {
      defaultUndefinedGroup.groupData = getDefaultGroupDataForUndefined(baseCompareVersionSelected, scenarioColors);
    }

    return nestedProjectGroups;
  },
);

export { formatGroupedDate, getGroupOptionsSelector, selectScenarioProjectGroups };
