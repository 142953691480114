import cloneDeep from 'lodash/cloneDeep';
import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';

export default (state, storeAsFilters, payload) => {
  const lastCallsMeta = cloneDeep(state.lastCallsMeta) || {};

  [IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER].forEach(layer => {
    if (!lastCallsMeta[layer] || !lastCallsMeta[layer][storeAsFilters ? 'filters' : 'notFilters']) {
      lastCallsMeta[layer] = {
        [storeAsFilters ? 'filters' : 'notFilters']: {
          ids: payload.filter(({ layer: entryLayer }) => +entryLayer === +layer).map(({ id }) => id),
        },
      };
    } else {
      const ids = lastCallsMeta[layer][storeAsFilters ? 'filters' : 'notFilters'].ids || [];

      lastCallsMeta[layer][storeAsFilters ? 'filters' : 'notFilters'] = {
        ...lastCallsMeta[layer][storeAsFilters ? 'filters' : 'notFilters'],
        ids: [...ids, ...payload.filter(({ layer: entryLayer }) => +entryLayer === +layer).map(({ id }) => id)],
      };
    }
  });
  return lastCallsMeta;
};
