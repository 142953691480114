// External dependencies
import { createSelector } from 'reselect';

export function getState(state) {
  return state.login;
}

export const getJwtToken = createSelector(getState, state => state.jwtToken);

export const getLogin = createSelector(getState, state => state);

export const getCurrentUser = createSelector(getState, state => state.currentUser);
export const getCurrentUserLoginCount = createSelector(
  state => getState(state).currentUser,
  obj => (obj || {}).login_count,
);

export const getCurrentUserRoleId = createSelector(
  state => getState(state).currentUser,
  obj => (obj || {}).role_id,
);

export const getUserIntegrations = createSelector(getState, state => state.integrations);

export const getUserIntegrationByOrgIntegrationId = orgIntegrationId =>
  createSelector(getUserIntegrations, (userIntegrations = []) =>
    userIntegrations.find(userIntegration => userIntegration.org_integration_id === orgIntegrationId),
  );

export const getAddUserIntegrationError = createSelector(getState, state => state.addUserIntegrationError);

export const getLoginOrg = createSelector(getState, state => state.organization);
