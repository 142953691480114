import isEmpty from 'lodash/isEmpty';

const DEFAULT_GROUP_OPTION = { key: null, title: 'No group' };

const DEFAULT_DATE_FIELD_KEY = 'estimatedStartDate';

const KEY_FIELD_MAP = {
  objectiveCorp: 'objective_corp_id',
  objective: 'objective_id',
  keyResult1: 'parent_id',
  roadmapsCorp: 'roadmapsCorp',
  roadmaps: 'roadmaps',
  products1: 'products1',
};

const mapDateFieldToGroup = {
  [DEFAULT_DATE_FIELD_KEY]: 'estimated_start_date',
  deadline: 'deadline',
  predictedEndDate: 'predictedEndDate',
};

const getObjectiveCorpGroupOption = getSystemFieldName => ({
  key: 'objectiveCorp',
  field: KEY_FIELD_MAP.objectiveCorp,
  title: getSystemFieldName('objectiveCorp'),
});

const getObjectiveGroupOption = getSystemFieldName => ({
  key: 'objective',
  field: KEY_FIELD_MAP.objective,
  title: getSystemFieldName('objective'),
});

const getRoadmapCorpGroupOption = getSystemFieldName => ({
  key: 'roadmapCorp',
  field: KEY_FIELD_MAP.roadmapsCorp,
  title: getSystemFieldName('roadmapCorp'),
});

const getRoadmapsOption = getSystemFieldName => ({
  key: 'roadmap',
  field: KEY_FIELD_MAP.roadmaps,
  title: getSystemFieldName('roadmap'),
});

const getProducts1Option = getSystemFieldName => ({
  key: 'product1',
  field: KEY_FIELD_MAP.products1,
  title: getSystemFieldName('product1'),
});

const getGroupOptions = ({ getSystemFieldName, withNullOption = false, dynamicDates = {}, isDodActive }) => {
  return [
    ...(withNullOption ? [DEFAULT_GROUP_OPTION] : []),
    ...(isDodActive ? [getObjectiveCorpGroupOption(getSystemFieldName)] : []),
    getObjectiveGroupOption(getSystemFieldName),
    {
      key: 'keyResult1',
      field: KEY_FIELD_MAP.keyResult1,
      title: getSystemFieldName('keyResult1'),
    },
    ...(isDodActive ? [getRoadmapCorpGroupOption(getSystemFieldName)] : []),
    getRoadmapsOption(getSystemFieldName),
    getProducts1Option(getSystemFieldName),
    ...(!isEmpty(dynamicDates)
      ? [
          {
            key: 'start-quarter',
            type: 'dates',
            title: 'Start Quarter',
            groupBy: 'quarter',
            field: 'start_date',
          },
          {
            key: 'end-quarter',
            type: 'dates',
            title: 'End Quarter',
            groupBy: 'quarter',
            field: 'end_date',
          },
          {
            key: 'start-year',
            type: 'dates',
            title: 'Start Year',
            groupBy: 'year',
            field: 'start_date',
          },
          {
            key: 'end-year',
            type: 'dates',
            title: 'End Year',
            groupBy: 'year',
            field: 'end_date',
          },
        ]
      : []),
  ];
};

const getGroupOptionByKey = (key, groupOptions, defaultValue = null) =>
  groupOptions?.find(option => option?.key === key) ?? defaultValue;

const mapGroupKeyToField = key => KEY_FIELD_MAP[key];

export {
  DEFAULT_GROUP_OPTION,
  mapDateFieldToGroup,
  getGroupOptions,
  getObjectiveGroupOption,
  getRoadmapsOption,
  getProducts1Option,
  getGroupOptionByKey,
  mapGroupKeyToField,
};
