import axios from 'axios';

import moveRowToPosition from 'utils/moveRowToPosition';
import throwRequestError from '../utils/throwRequestError';
import {
  SWITCH_METRICS_ROW_ORDER,
  CREATE_METRIC_VALUE,
  DELETE_METRIC_VALUE,
  UPDATE_METRIC_VALUE,
  CREATE_METRIC_ROADMAP,
  DELETE_METRIC_ROADMAP,
  BULK_DELETE_METRIC_ROADMAP,
  IMPORT_METRIC_FROM_DATA_SOURCE,
  UNLINK_METRIC_FROM_DATA_SOURCE,
  FETCH_METRIC_INTEGRATION_FILTER_OPTIONS,
} from './types';
import { createThunk } from 'utils/store/thunk';
import { fetchMetrics, removeUnsavedMetricValues } from 'store/metrics';

const switchMetricsRowOrder = (id1, id2, position) => {
  return async (dispatch, getState) => {
    const state = getState().metrics.metrics;
    const prevData = state.find(({ id }) => id === +id1);
    const movedRow = moveRowToPosition(state, id1, id2, position);

    return dispatch(
      createThunk(
        SWITCH_METRICS_ROW_ORDER,
        axios.put(`/api/metrics/rowOrder/${id1}/${id2}`, { position }).then(res => res.data),
        { row: movedRow, prev: prevData },
      ),
    );
  };
};

function addMetricValue(metricValue) {
  return async dispatch => {
    return dispatch(
      createThunk(
        CREATE_METRIC_VALUE,
        axios
          .post('/api/metric-values', metricValue)
          .then(res => {
            dispatch(fetchMetrics());

            return res?.data;
          })
          .catch(throwRequestError),
      ),
    );
  };
}

function updateMetricValue(metricValueId, update) {
  return async dispatch => {
    return dispatch(
      createThunk(
        UPDATE_METRIC_VALUE,
        axios
          .put(`/api/metric-values/${metricValueId}`, update)
          .then(res => {
            dispatch(fetchMetrics());
            return res?.data;
          })
          .catch(throwRequestError),
      ),
    );
  };
}

export function deleteMetricValue(metricValueId) {
  return async dispatch => {
    if (!metricValueId) {
      dispatch(removeUnsavedMetricValues());
      return;
    }

    return dispatch(
      createThunk(
        DELETE_METRIC_VALUE,
        axios
          .delete(`/api/metric-values/${metricValueId}`)
          .then(() => {
            dispatch(fetchMetrics());
          })
          .catch(throwRequestError),
      ),
    );
  };
}

const createMetricRoadmap = (metricId, metricRoadmap) =>
  createThunk(
    CREATE_METRIC_ROADMAP,
    axios.post(`/api/metrics/${metricId}/metricRoadmaps`, metricRoadmap).catch(throwRequestError),
    { metricId },
  );

const deleteMetricRoadmap = ({ id: metricId, roadmapId, subRoadmapId = null, product2Id = null }) => {
  let route = `/api/metrics/${metricId}/metricRoadmaps/${roadmapId}`;

  route += subRoadmapId ? `/${subRoadmapId}` : '';
  route += subRoadmapId && product2Id ? `/${product2Id}` : '';

  return createThunk(DELETE_METRIC_ROADMAP, axios.delete(route).catch(throwRequestError), { metricId });
};

const bulkDeleteMetricRoadmaps = metricId =>
  createThunk(BULK_DELETE_METRIC_ROADMAP, axios.delete(`/api/metrics/${metricId}/metricRoadmaps`).catch(throwRequestError), {
    metricId,
  });

const importMetricFromDataSource =
  (integrationType, orgIntegrationId, metricId, dataSource, entity, metricType, timePeriod, segment) => async dispatch =>
    dispatch(
      createThunk(
        IMPORT_METRIC_FROM_DATA_SOURCE,
        axios
          .post(`/api/integrations/${integrationType}/${orgIntegrationId}/metrics/${metricId}/link`, {
            externalUrl: dataSource,
            entity,
            metricType,
            timePeriod,
            segment,
          })
          .then(() => {
            dispatch(fetchMetrics());
          })
          .catch(throwRequestError),
        {
          metricId,
          dataSource,
          toastErrorMessage: 'Can’t link the URL. Please check URL or integration status.',
        },
      ),
    );

const unlinkMetricFromDataSource = (integrationType, orgIntegrationId, metricId) => async dispatch =>
  dispatch(
    createThunk(
      UNLINK_METRIC_FROM_DATA_SOURCE,
      axios
        .post(`/api/integrations/${integrationType}/${orgIntegrationId}/metrics/${metricId}/unlink`)
        .then(() => {
          dispatch(fetchMetrics());
        })
        .catch(throwRequestError),
    ),
  );

const fetchMetricIntegrationFilterOptions = (integrationType, orgIntegrationId) => async dispatch =>
  dispatch(
    createThunk(
      FETCH_METRIC_INTEGRATION_FILTER_OPTIONS,
      axios
        .get(`/api/integrations/${integrationType}/${orgIntegrationId}/metrics/filter-options`)
        .then(res => res.data)
        .catch(throwRequestError),
      {
        orgIntegrationId,
      },
    ),
  );

export {
  switchMetricsRowOrder,
  addMetricValue,
  updateMetricValue,
  createMetricRoadmap,
  deleteMetricRoadmap,
  bulkDeleteMetricRoadmaps,
  importMetricFromDataSource,
  unlinkMetricFromDataSource,
  fetchMetricIntegrationFilterOptions,
};
