import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';

class Alert extends Component {
  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Typography component="p" paragraph style={{ color: 'red' }}>
            Multiple teams are involved in building one roadmap.
          </Typography>
          <Typography component="p" paragraph>
            You may consider a &quot;temporary feature team&quot; structure to reduce cross team
            dependencies and improve shipping velocity.
          </Typography>
          <Typography component="p" paragraph>
            Check out <a href="https://dragonboat.io/blog/" target="_blank" rel="noopener noreferrer">our blog</a>
            on how to structure your scrum teams for success.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onCancel}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default Alert;
