import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  isFetchingViewRecurringNotificationsLoading,
  selectViewRecurringNotifications,
} from 'features/UserViewRecurringNotification/store/selectors';
import {
  deleteUserViewRecurringNotification,
  fetchRecurringNotificationsForUserView,
  updateUserViewRecurringNotification,
} from 'features/UserViewRecurringNotification/store';

const useUserViewRecurringNotification = view => {
  const dispatch = useDispatch();

  const viewRecurringNotifications = useSelector(state => selectViewRecurringNotifications(state, view?.id));
  const isLoadingRecurringNotifications = useSelector(isFetchingViewRecurringNotificationsLoading);

  useEffect(() => {
    if (view?.id) {
      dispatch(fetchRecurringNotificationsForUserView(view?.id));
    }
  }, [view?.id]);

  const updateViewRecurringNotification = (viewRecurringNotification, changes) => {
    dispatch(
      updateUserViewRecurringNotification(
        viewRecurringNotification.user_view_id,
        viewRecurringNotification.id,
        changes.channel,
        changes.comment,
        changes.dayOfTheWeek,
      ),
    );
  };

  const deleteViewRecurringNotification = viewRecurringNotification => {
    dispatch(deleteUserViewRecurringNotification(viewRecurringNotification.user_view_id, viewRecurringNotification.id));
  };

  return {
    viewRecurringNotifications,
    isLoadingRecurringNotifications,
    updateViewRecurringNotification,
    deleteViewRecurringNotification,
  };
};

export default useUserViewRecurringNotification;
