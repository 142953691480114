import React from 'react';

import NormalSingleGrouping from './NormalSingleGrouping';
import NormalDoubleGrouping from './NormalDoubleGrouping';
import InsideGroupsTripleGrouping from './InsideGroupsTripleGrouping';

const DisplayController = ({ selectedGroupsCount, ...props }) => {
  if (selectedGroupsCount === 1) {
    return <NormalSingleGrouping {...props} />;
  }

  if (selectedGroupsCount === 2) {
    return <NormalDoubleGrouping {...props} />;
  }

  if (selectedGroupsCount === 3) {
    return <InsideGroupsTripleGrouping {...props} />;
  }

  return null;
};

export default DisplayController;
