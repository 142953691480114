import { useDispatch, useSelector } from 'react-redux';
import { pick, keys, not } from 'ramda';

import {
  fetchFieldsLayout as fetchFieldsLayoutThunk,
  selectFieldsLayout,
  selectIsFetchingFieldsLayout,
  upsertFieldsLayout as upsertFieldsLayoutThunk,
} from '../store';
import { mergeFieldsLayoutWithDefaultFields } from '../helpers';

const ALLOWED_ATTRIBUTES = ['position', 'hidden', 'required', 'index', 'promote'];
const cleanFieldLayout = fieldData => pick(ALLOWED_ATTRIBUTES, fieldData);

const useFieldsLayoutForType = ({ defaultFieldsLayout, type, level }) => {
  const dispatch = useDispatch();

  const fieldsLayout = useSelector(state => selectFieldsLayout(state, type, level));
  const fieldsLayoutIsLoading = useSelector(selectIsFetchingFieldsLayout);
  const isUsingDefaultFieldsLayout = fieldsLayout === undefined;

  const currentFieldsLayout = mergeFieldsLayoutWithDefaultFields(fieldsLayout, defaultFieldsLayout);

  const fetchFieldsLayout = () => {
    const shouldIgnoreFetchFieldsLayout = fieldsLayoutIsLoading || not(isUsingDefaultFieldsLayout);

    if (shouldIgnoreFetchFieldsLayout) {
      return;
    }

    dispatch(fetchFieldsLayoutThunk(type, level));
  };

  const upsertFieldsLayout = newFieldsLayout => {
    const configuration = {
      ...keys(newFieldsLayout).reduce(
        (carry, fieldId) => ({ ...carry, [fieldId]: cleanFieldLayout(newFieldsLayout[fieldId]) }),
        {},
      ),
    };

    dispatch(upsertFieldsLayoutThunk(type, level, { configuration }));
  };

  return {
    fieldsLayoutIsLoading,
    isUsingDefaultFieldsLayout,
    fieldsLayout: currentFieldsLayout,

    fetchFieldsLayout,
    upsertFieldsLayout,
  };
};

export default useFieldsLayoutForType;
