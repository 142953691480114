import { useMemo } from 'react';

import { INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import CustomLoadingOverlay from 'design-system/molecules/AgGridReact-New/components/CustomLoadingOverlay';
import { sizeColumnsToFit } from 'design-system/molecules/AgGridReact-New/helpers';
import {
  sumNumericFields,
  sumScopeVarianceField,
  sumPointsVarianceField,
} from 'design-system/molecules/AgGridReact-New/aggFuncs';

import { getRowId, getDataPath } from '../helpers';

const DEFAULT_HEIGHT = 40;
const MAX_COLUMNS_TO_RESIZE = 5;

/**
 * This hook should only have a read only functionality.
 * For a editable projects grid, a new hook should be created extending this one.
 */
const useProjectsGridProps = visibleColumns => {
  return useMemo(() => {
    const initialOptions = {
      getRowId,
      treeData: true,
      getDataPath,
      aggFuncs: {
        sumNumericFields,
        sumScopeVarianceField,
        sumPointsVarianceField,
      },
      suppressAggFuncInHeader: true,
      suppressPaginationPanel: true,
      suppressRowClickSelection: true,
      suppressCellFocus: true,
      groupSelectsChildren: false,
      enableRowGroup: false,
      groupDefaultExpanded: -1,
      animateRows: true,
      rowHeight: DEFAULT_HEIGHT,
      components: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay,
      },
      loadingOverlayComponent: 'customLoadingOverlay',
      noRowsOverlayComponent: 'customNoRowsOverlay',
      rowClassRules: {
        'metadata-parent-row': params => {
          return !params.data || [INITIATIVE_LAYER, BET_LAYER].includes(params.data.layer);
        },
      },
    };

    const shouldResizeOnGridReady = visibleColumns.length <= MAX_COLUMNS_TO_RESIZE;

    if (shouldResizeOnGridReady) {
      return { ...initialOptions, onGridReady: sizeColumnsToFit };
    }

    return initialOptions;
  }, [visibleColumns]);
};

export default useProjectsGridProps;
