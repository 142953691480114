import React, { useState, useImperativeHandle } from 'react';
import styled, { css } from 'styled-components';

import { spacing } from 'design-system/theme';
import cellEditorHoc from 'design-system/molecules/AgGridReact-New/helpers/cellEditorHoc';
import FluidMetricDataSourceField from 'features/MetricsDialog/components/FluidMetricDataSourceField';
import { getMetricIntegrationByUrl } from './helpers';

const handleOnClickAway = (clickEvent, { stopEditing }) => {
  const clickedEventTargetIsRenderedAsPortal =
    clickEvent.target.closest('#react-autowhatever-1') || clickEvent.target.closest('#confirm-dialog');

  if (!clickedEventTargetIsRenderedAsPortal) {
    stopEditing();
  }
};

const MetricDataSourceCellEditor = cellEditorHoc(({ onChange, forwardedRef, width, ...params }) => {
  const {
    value,
    node: { data },
  } = params;

  const [internalValue, setInternalValue] = useState(value);

  const integration = getMetricIntegrationByUrl(data?.metricIntegrations, value);

  useImperativeHandle(forwardedRef, () => ({
    getValue() {
      return internalValue;
    },
  }));

  return (
    <Wrapper width={width}>
      <FluidMetricDataSourceField
        value={internalValue}
        integrationData={integration}
        onChange={params => {
          setInternalValue(params?.dataSource);
          return onChange(data?.id, params);
        }}
      />
    </Wrapper>
  );
}, handleOnClickAway);

export default MetricDataSourceCellEditor;

const Wrapper = styled.div`
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `};

  .saving-loader {
    top: ${spacing(0.5)}px;
  }
`;
