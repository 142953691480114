/**
 * Concats Title to a given field name
 *
 * @param {String} fieldName
 * @returns {String}
 */
export const getLevelTitlePropertyName = fieldName => `${fieldName}Title`;

export const flattenItems = items => {
  return items.reduce((acc, item) => {
    const flattenChildren = item?.children?.length ? flattenItems(item.children) : [];

    return [...acc, item, ...flattenChildren];
  }, []);
};
