import axios from 'axios';
import isFunction from 'lodash/isFunction';
import { equals } from 'ramda';
import { createThunk } from 'utils/store/thunk';

import { UPDATE_PROJECT, UPDATE_PROJECT_RESET } from 'store/projects';
import { getProjectIntegrationProgress } from 'store/projectLightbox';

import { syncJiraStoriesAllTime } from './jiraThunks';

import {
  FETCH_PROJECT_INTEGRATIONS,
  TEST_CONNECTION,
  TEST_CONNECTION_RESET,
  TEST_SLACK_CONNECTION,
  CREATE_PROJECT_INTEGRATION,
  CREATE_PROJECT_INTEGRATION_RESET,
  ADD_PROJECT_INTEGRATION,
  ADD_PROJECT_INTEGRATION_RESET,
  REMOVE_PROJECT_INTEGRATION,
  UPDATE_PROJECT_STORIES,
  UPDATE_PROJECT_STORIES_RESET,
  GET_PROJECT_STORIES,
  SYNC_STORIES,
  UPDATE_PROJECT_INTEGRATION,
  UPDATE_PROJECT_INTEGRATION_RESET,
  UPDATE_INTEGRATION_PROJECT_FROM_PROJECT,
  REVALIDATE_USER_INTEGRATION_AUTH,
  UPDATE_PROJECTS_MAPPED_FIELDS,
} from '../types';

import { createProjectAndGetId } from './helpers/createProjectAndGetId';

import openAuthenticationHandler from './helpers/authenticationHandlers';

const AUTH_TYPE_OAUTH2 = 'oauth2';

const isAuthTypeOAuth2 = equals(AUTH_TYPE_OAUTH2);

// TODO: This thunk should be moved to projects slice
const updateProjectFromEpic = (projectId, integrationType, orgIntegrationId) =>
  createThunk(
    UPDATE_PROJECT,
    () => axios.put(`/api/projects/${projectId}/mapped-fields`).then(({ data }) => data),
    { integrationType, orgIntegrationId },
    { includeRetryAction: true },
  );

const resetUpdateProjectFromEpic = () => ({ type: UPDATE_PROJECT_RESET });

const testConnection = (integrationType, orgIntegrationId) =>
  createThunk(
    TEST_CONNECTION,
    () => axios.get(`/api/integrations/${integrationType}/${orgIntegrationId}/test-connection`),
    {
      integrationType,
      orgIntegrationId,
    },
    { includeRetryAction: true },
  );

const resetTestConnection = () => ({ type: TEST_CONNECTION_RESET });

// This should use the generic action once slack integration starts to be handled by the integration gateway
const testSlackConnection = () => createThunk(TEST_SLACK_CONNECTION, axios.post('/api/integrations/slack/test'));

const fetchProjectIntegrations = projectId =>
  createThunk(FETCH_PROJECT_INTEGRATIONS, axios.get(`/api/projects/${projectId}/integrations`), { projectId });

const addProjectIntegration = (projectId, projectIntegrationId, data, updateProject) => {
  return async dispatch => {
    return dispatch(
      createThunk(
        ADD_PROJECT_INTEGRATION,
        async () => {
          const selectedProjectId = projectId ?? (await createProjectAndGetId(updateProject));

          return axios.post(`/api/projects/${selectedProjectId}/integrations/${projectIntegrationId}`, data).then(({ data }) => ({
            projectId: selectedProjectId,
            projectIntegration: data,
            projectData: data.project,
          }));
        },
        { projectId, orgIntegrationId: data.org_integration_id },
        { includeRetryAction: true },
      ),
    );
  };
};

// Link project integration with outbound field mapping - update from Dragonboat to integration to prevent overwriting DB data
const addProjectIntegrationOutboundLink = (projectId, projectIntegrationId, data, updateProject) => {
  return async dispatch => {
    return dispatch(
      createThunk(
        ADD_PROJECT_INTEGRATION,
        async () => {
          const selectedProjectId = projectId ?? (await createProjectAndGetId(updateProject));

          return axios
            .post(`/api/projects/${selectedProjectId}/integrations/${projectIntegrationId}/link-outbound`, data)
            .then(({ data }) => ({
              projectId: selectedProjectId,
              projectIntegration: data,
              projectData: data.project,
            }));
        },
        { projectId, orgIntegrationId: data.org_integration_id },
        { includeRetryAction: true },
      ),
    );
  };
};

const resetAddProjectIntegration = () => ({ type: ADD_PROJECT_INTEGRATION_RESET });

const removeProjectIntegration = (projectId, projectIntegrationId, data, updateProject) =>
  createThunk(
    REMOVE_PROJECT_INTEGRATION,
    async () => {
      const selectedProjectId = projectId ?? (await createProjectAndGetId(updateProject));

      return axios
        .delete(`/api/projects/${selectedProjectId}/integrations/${projectIntegrationId}`, {
          data,
        })
        .then(() => ({
          projectId: selectedProjectId,
          projectIntegrationId,
        }));
    },
    { projectId },
  );

const createProjectIntegration = (projectId, data, updateProject) =>
  createThunk(
    CREATE_PROJECT_INTEGRATION,
    async () => {
      const selectedProjectId = projectId ?? (await createProjectAndGetId(updateProject));

      return axios
        .post(`/api/projects/${selectedProjectId}/integrations`, {
          ...data,
        })
        .then(({ data }) => ({
          projectId: selectedProjectId,
          projectIntegration: data,
        }));
    },
    { projectId, orgIntegrationId: data.org_integration_id },
    { includeRetryAction: true },
  );

const resetCreateProjectIntegration = () => ({ type: CREATE_PROJECT_INTEGRATION_RESET });

const getProjectStories = projects =>
  createThunk(
    GET_PROJECT_STORIES,
    axios.get(`/api/projects/stories?projects=${projects}`).then(({ data }) => ({
      projects,
      stories: data,
    })),
  );

const updateProjectStories = (projectId, integrationType, orgIntegrationId) =>
  createThunk(
    UPDATE_PROJECT_STORIES,
    () => axios.put(`/api/projects/${projectId}/stories`),
    {
      integrationType,
      orgIntegrationId,
    },
    { includeRetryAction: true },
  );

const resetUpdateProjectStories = () => ({ type: UPDATE_PROJECT_STORIES_RESET });

const syncStories = () =>
  createThunk(
    SYNC_STORIES,
    axios.put('/api/projects/stories').then(({ data }) => data),
  );

const syncIntegration = (orgIntegrationId, integrationType, args, socket) => async dispatch => {
  switch (integrationType) {
    case 'JIRA':
      await dispatch(syncJiraStoriesAllTime(orgIntegrationId, [args.epicKey], socket));
      await dispatch(getProjectIntegrationProgress(args.projectId));
      return Promise.resolve();
    default:
      await dispatch(updateProjectStories(args.projectId, integrationType, orgIntegrationId));
      await dispatch(getProjectIntegrationProgress(args.projectId));
      return Promise.resolve();
  }
};

const updateProjectIntegration = (projectId, data) =>
  createThunk(UPDATE_PROJECT_INTEGRATION, axios.put(`/api/projects/${projectId}/integrations`, data), { projectId });

const resetUpdateProjectIntegration = () => ({ type: UPDATE_PROJECT_INTEGRATION_RESET });

const updateIntegrationProjectFromProject = (integrationType, orgIntegrationId, projectId, payload) =>
  createThunk(
    UPDATE_INTEGRATION_PROJECT_FROM_PROJECT,
    () => axios.put(`/api/integrations/${integrationType}/${orgIntegrationId}/project/${projectId}`, payload),
    { integrationType, orgIntegrationId },
    { includeRetryAction: true },
  );

/**
 * @function revalidateUserIntegrationAuth
 *
 * Revalidates the user's integration auth if IntegrationAuthorizationError type is thrown.
 * Will also prompt the user to create a new user integration if the integration is not found.
 *
 * @param {String} integrationType
 * @param {Number} orgIntegrationId
 * @param {*} retryAction
 * @param {String} authType // oauth2, pat, etc
 * @returns {Object}
 */
const revalidateUserIntegrationAuth = (integrationType, orgIntegrationId, retryAction, authType) => {
  return async dispatch => {
    return dispatch(
      createThunk(
        REVALIDATE_USER_INTEGRATION_AUTH,
        async () => {
          await axios.delete(`/api/user-integrations/${integrationType}/${orgIntegrationId}`);

          if (isAuthTypeOAuth2(authType)) {
            await authenticateWithOAuth2(integrationType, orgIntegrationId);
          } else {
            await openAuthenticationHandler(integrationType);
          }

          const payload = await axios.get('/api/integrations').then(res => res.data);

          if (isFunction(retryAction)) {
            dispatch(retryAction());
          }

          return { userIntegrations: payload };
        },
        { integrationType, orgIntegrationId },
      ),
    );
  };
};

const authenticateWithOAuth2 = async (integrationType, orgIntegrationId) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/integrations/${integrationType}/${orgIntegrationId}/auth`)
      .then(response => {
        window.open(response.data, `${integrationType}`, 'width=700,height=1100');
        window.addEventListener(
          'message',
          ({ data }) => {
            if (data?.ssoType === integrationType) {
              axios
                .post(`/api/integrations/${integrationType}/${orgIntegrationId}/auth/callback`, {
                  code: data.code,
                  state: data.state,
                })
                .then(res => {
                  if (res.data) {
                    resolve(true);
                  }
                })
                .catch(err => {
                  reject(err);
                });
            }
          },
          false,
        );
      })
      .catch(response => {
        reject(response);
      });
  });
};

const updateProjectsMappedFields = (integrationType, orgIntegrationId) => {
  return createThunk(UPDATE_PROJECTS_MAPPED_FIELDS, async () => {
    const { data } = await axios.put(`/api/integrations/${integrationType}/${orgIntegrationId}/projects/mapped-fields`);

    return data;
  });
};

export {
  updateProjectFromEpic,
  resetUpdateProjectFromEpic,
  testConnection,
  resetTestConnection,
  testSlackConnection,
  fetchProjectIntegrations,
  addProjectIntegration,
  addProjectIntegrationOutboundLink,
  resetAddProjectIntegration,
  removeProjectIntegration,
  createProjectIntegration,
  resetCreateProjectIntegration,
  updateProjectStories,
  resetUpdateProjectStories,
  getProjectStories,
  syncStories,
  syncIntegration,
  updateProjectIntegration,
  resetUpdateProjectIntegration,
  updateIntegrationProjectFromProject,
  revalidateUserIntegrationAuth,
  authenticateWithOAuth2,
  updateProjectsMappedFields,
};
