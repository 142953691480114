import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const ideasPages = (getSystemFieldName, hasPortfolioCanvas = false, isFlatGridActive = false) => [
  {
    path: 'ideas/overview',
    label: 'Overview',
    testId: 'module-portfolio-overview',
  },
  {
    path: 'ideas/board',
    label: 'Board',
    testId: 'module-portfolio-board',
  },
  ...(isFlatGridActive
    ? [
        {
          path: 'ideas/grid-flat',
          label: 'List',
          testId: 'module-portfolio-list',
        },
      ]
    : [
        {
          path: 'ideas/grid',
          label: 'List',
          testId: 'module-portfolio-list',
        },
      ]),
  {
    path: 'ideas/roadmap',
    label: 'Swimlane',
    testId: 'module-portfolio-swimlane',
  },
  {
    path: 'ideas/timeline',
    label: 'Gantt',
    testId: 'module-portfolio-gantt',
  },
  {
    path: 'ideas/summary',
    label: '3D Dynamic',
    testId: 'module-portfolio-3d',
  },
  {
    path: 'ideas/personas',
    label: `${getSystemFieldName('persona', true)}`,
    testId: 'module-portfolio-personas',
  },
  {
    path: 'ideas/lifecycles',
    label: `${getSystemFieldName('lifecycle', true)}`,
    testId: 'module-portfolio-lifecycles',
  },
  ...(hasPortfolioCanvas
    ? [
        {
          path: 'ideas/canvas',
          label: 'Canvas',
          testId: 'module-portfolio-canvas',
        },
      ]
    : []),
  {
    path: 'ideas/pdlc',
    label: `${getSystemFieldName('pdlc')}`,
    testId: 'module-portfolio-pdlc',
  },
];

const goalsPages = (hasGoalsCanvas = false) => [
  {
    path: 'goals/grid',
    label: 'List',
    testId: 'module-outcome-list',
  },
  {
    path: 'goals/summary',
    label: '3D Dynamic',
    testId: 'module-outcome-summary',
  },
  {
    path: 'goals/snapshot',
    label: 'Snapshot',
    testId: 'module-outcome-snapshot',
  },
  ...(hasGoalsCanvas
    ? [
        {
          path: 'goals/canvas',
          label: 'Canvas',
          testId: 'module-goals-canvas',
        },
      ]
    : []),
];

const forecastPages = [
  {
    label: 'Schedule',
    path: 'forecast/timeline',
    testId: 'module-forecast-timeline',
  },
  {
    label: 'List',
    path: 'forecast/list',
    testId: 'module-forecast-list',
  },
  {
    label: 'Staffing',
    path: 'forecast/resources',
    testId: 'module-forecast-staffing',
  },
  {
    label: 'Chart',
    path: 'forecast/chart',
    testId: 'module-forecast-chart',
  },
];

const metricsPages = (hasAdvancedMetricReporting, hasMetricsCanvas = false, hasMetricsAllocation = false) => [
  {
    label: 'List',
    path: 'metrics/grid',
    testId: 'module-metrics-list',
  },
  ...(hasAdvancedMetricReporting
    ? [
        {
          label: 'MoAR Chart',
          path: 'metrics/chart',
          testId: 'module-metrics-chart',
        },
      ]
    : []),
  ...(hasMetricsCanvas
    ? [
        {
          label: 'Canvas',
          path: 'metrics/canvas',
          testId: 'module-metrics-canvas',
        },
      ]
    : []),
];

const requestsPages = (isNotRequestor, hasRequestsAnalyzePage, getSystemFieldName) => [
  {
    label: 'Detail',
    path: 'requests/detail',
    testId: 'module-request-detail',
  },
  {
    label: 'List',
    path: 'requests/list',
    testId: 'module-request-list',
  },
  ...(isNotRequestor
    ? [
        {
          label: 'Insights',
          path: 'requests/insights',
          testId: 'module-request-insights',
        },
      ]
    : []),
  ...(hasRequestsAnalyzePage
    ? [
        {
          label: 'Analyze',
          path: 'requests/analyze',
          testId: 'module-request-analyze',
        },
      ]
    : []),
  {
    path: 'requests/personas',
    label: `${getSystemFieldName('persona', true)}`,
    testId: 'module-request-personas',
  },
  {
    path: 'requests/lifecycles',
    label: `${getSystemFieldName('lifecycle', true)}`,
    testId: 'module-request-lifecycles',
  },
];

const dashboardPages = (hideDashboardHome, isDashboardsEnabled, hasProgressReport) => [
  ...(hideDashboardHome ? [] : [{ label: 'Views', path: 'dashboard/home', testId: 'module-dashboard-views' }]),
  ...(isDashboardsEnabled
    ? [
        {
          label: 'Dashboards',
          path: 'dashboard/user-dashboards',
          testId: 'module-dashboard-dashboards',
        },
      ]
    : []),
  {
    label: 'Templates',
    path: 'dashboard/templates',
    testId: 'module-dashboard-templates',
  },
  {
    label: 'Portfolio Report',
    path: 'dashboard/portfolio-report',
    testId: 'module-dashboard-report',
  },
  {
    label: 'Snapshot',
    path: 'dashboard/snapshot',
    testId: 'module-dashboard-snapshot',
  },
  {
    label: 'Allocation',
    path: 'dashboard/allocation-report',
    testId: 'module-dashboard-allocation',
  },
  ...(hasProgressReport
    ? [
        {
          label: 'Progress',
          path: 'dashboard/progress',
          testId: 'module-dashboard-progress',
        },
      ]
    : []),
];

export const getModulePages = (
  page,
  getSystemFieldName,
  isNotRequestor,
  hideDashboardHome,
  isDashboardsEnabled,
  hasAdvancedMetricReporting,
  hasProgressReport,
  hasRequestsAnalyzePage = false,
  hasPortfolioCanvas = false,
  hasGoalsCanvas = false,
  hasMetricsCanvas = false,
  hasMetricsAllocation = true,
  canView,
  isFlatGridActive,
) => {
  switch (page) {
    case '/ideas':
      return ideasPages(getSystemFieldName, hasPortfolioCanvas, isFlatGridActive);

    case '/goals':
      return goalsPages(hasGoalsCanvas);

    case '/forecast':
      return forecastPages;

    case '/requests':
      return requestsPages(isNotRequestor, hasRequestsAnalyzePage, getSystemFieldName);

    case '/dashboard':
      return canView(PERMISSION_FEATURES.dashboardModule)
        ? dashboardPages(hideDashboardHome, isDashboardsEnabled, hasProgressReport)
        : [];

    case '/metrics':
      return metricsPages(hasAdvancedMetricReporting, hasMetricsCanvas, hasMetricsAllocation);

    default:
      return [];
  }
};
