// External dependencies
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

// Dragonboat depencies
import { filterActiveItens, getUserName, makeFilterByLevel } from 'utils';
import isNumeric from 'utils/isNumeric';

import { getNormalizedUsers } from 'store/users/selectors';

const LEVEL_2_INDEX = 1;

export function getState(state) {
  return state.teams || {};
}

export const getAllTeams = createSelector(
  getState,
  state => getNormalizedUsers(state),
  (state, users) => {
    const { teams } = state;

    return (teams || []).map(t => {
      if (t.updated_by_id) {
        const user = users[t.updated_by_id];

        return {
          ...t,
          updatedByName: user ? getUserName(user) : '',
        };
      }

      return t;
    });
  },
);

export const makeGetTeams = (defaultLevel = 0) =>
  createCachedSelector(
    [
      getState,
      (_, showArchived) => showArchived,
      (_, __, level) => (isNumeric(level) ? level : defaultLevel),
      (state, _, __) => getNormalizedUsers(state),
    ],
    (state, showArchived, level, users) => {
      let { teams } = state;

      teams = (teams || []).filter(makeFilterByLevel(level));

      if (!showArchived) {
        teams = (teams || []).filter(filterActiveItens);
      }

      return (teams || []).map(t => {
        if (t.updated_by_id) {
          const user = users[t.updated_by_id];

          return {
            ...t,
            updatedByName: user ? getUserName(user) : '',
          };
        }

        return t;
      });
    },
  )((_, showArchived) => `getTeams-${showArchived}`);

export const getTeams = makeGetTeams();

export const getTeamsLevel2 = makeGetTeams(LEVEL_2_INDEX);

export const makeGetNormalizedTeams = () => {
  const localGetTeams = makeGetTeams();

  return createSelector(
    state => localGetTeams(state, true),
    state => {
      return state.reduce((objs, obj) => {
        objs[obj.id] = obj;
        return objs;
      }, {});
    },
  );
};

export const getNormalizedTeams = makeGetNormalizedTeams();

export const getTeamsTitles = createSelector(
  state => getState(state),
  teams => (teams?.teams || []).map(t => t.title),
);
