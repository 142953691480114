import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { INCLUDE_ALL_OPTION } from 'constants/projects';
import { SHOW_UNCOMMITTED_PROJECTS } from 'constants/grid';
import { FORECAST_BY_HEADCOUNT, FORECAST_BY_TIME, FORECAST_LIST_FORECAST_BY_OPTION_KEY } from 'constants/forecast';
import { makeFilterProjectsByCommitted } from 'utils/projects/projectsUtils';
import { getGridConfigValue } from 'store/grids/selectors';

const useProjectsLocalFilterByCommited = (projects, viewType) => {
  const showUncommittedProjects = useSelector(state =>
    getGridConfigValue(state, viewType, SHOW_UNCOMMITTED_PROJECTS, INCLUDE_ALL_OPTION),
  );

  const forecastBy = useSelector(state =>
    getGridConfigValue(state, viewType, FORECAST_LIST_FORECAST_BY_OPTION_KEY, FORECAST_BY_TIME),
  );

  const isForecastByHeadcountSelected = forecastBy === FORECAST_BY_HEADCOUNT;

  const filterProjectsByCommittedStatus = useMemo(
    () => !isForecastByHeadcountSelected && makeFilterProjectsByCommitted(showUncommittedProjects),
    [isForecastByHeadcountSelected, showUncommittedProjects],
  );
  const filteredProjects = useMemo(() => {
    let filtered = projects;

    if (filterProjectsByCommittedStatus) {
      filtered = filterProjectsByCommittedStatus(projects);
    }

    return (filtered || []).map(({ id }) => id);
  }, [projects, filterProjectsByCommittedStatus]);

  const isProjectOnFilter = useMemo(
    () => (!isForecastByHeadcountSelected ? p => filteredProjects.includes(p?.id) : null),
    [filteredProjects],
  );

  return [isProjectOnFilter];
};

export default useProjectsLocalFilterByCommited;
