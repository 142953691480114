import { useMemo } from 'react';

import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';
import {
  getTitleColumnDef,
  statusColumnDef,
  colorColumnDef,
  updatedColumnDef,
  getMetadataRoadmapColumnDef,
  healthColumnDef,
  targetAllocationAmountColumnDef,
  targetAllocationPercentageColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';

const CATEGORY_KEY = 'category';
const CATEGORY_ROADMAP_FIELD = 'category_roadmaps';

const useCategoriesGridColumns = ({ roadmapColumn }) => {
  const { createCategoryRoadmap, deleteCategoryRoadmap, categories, bulkDeleteCategoryRoadmaps, hideMetadataRoadmaps } =
    roadmapColumn;

  const roadmapsMetadata = useRoadmapsMetadata(
    createCategoryRoadmap,
    deleteCategoryRoadmap,
    categories,
    CATEGORY_KEY,
    bulkDeleteCategoryRoadmaps,
  );

  return useMemo(
    () => [
      getTitleColumnDef('Title'),
      getMetadataRoadmapColumnDef({
        roadmapsMetadata: [...roadmapsMetadata, hideMetadataRoadmaps],
        field: CATEGORY_ROADMAP_FIELD,
        editableForNewRow: false,
      }),
      targetAllocationAmountColumnDef,
      targetAllocationPercentageColumnDef,
      statusColumnDef,
      healthColumnDef,
      colorColumnDef,
      updatedColumnDef,
    ],
    [],
  );
};

export default useCategoriesGridColumns;

export { CATEGORY_ROADMAP_FIELD };
