import { hasPath, ifElse, path } from 'ramda';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import React from 'react';
import styled from 'styled-components';

import usePermissions from 'hooks/permissions/usePermissions';

import { ConnectionSettings, WebhooksSettings } from './components';
import FieldMapping from '../FieldMapping';

const DATA = 'data';
const URI = 'uri';
const INSTANCE_URL = 'instanceUrl';

const hasUri = hasPath([DATA, URI]);
const getUri = path([DATA, URI]);
const getInstanceUrl = path([DATA, INSTANCE_URL]);

const getIntegrationUri = ifElse(hasUri, getUri, getInstanceUrl);

const IntegrationDetails = ({ oneStepAvailableIntegrations, orgIntegration }) => {
  const { integrationType } = orgIntegration;
  const { canView } = usePermissions();

  const webhooksSettingsVisible = canView(PERMISSION_RESOURCES.integrationWebhooks, {
    integrationType,
  });

  const fieldMappingSettingsVisible = canView(PERMISSION_RESOURCES.integrationFieldMapping, {
    integrationConfig: oneStepAvailableIntegrations[integrationType],
  });

  return (
    <>
      <IntegrationLink href={getIntegrationUri(orgIntegration)} target="_blank" rel="noreferrer">
        {getIntegrationUri(orgIntegration)}
      </IntegrationLink>
      {webhooksSettingsVisible && <WebhooksSettings orgIntegration={orgIntegration} />}
      {fieldMappingSettingsVisible && (
        <FieldMapping
          integrationType={integrationType}
          orgIntegration={orgIntegration}
          oneStepAvailableIntegrations={oneStepAvailableIntegrations}
        />
      )}
      <ConnectionSettings orgIntegration={orgIntegration} />
    </>
  );
};

const IntegrationLink = styled.a`
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: 16px;
`;

export default IntegrationDetails;
