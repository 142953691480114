import { createSelector } from 'reselect';
import { isUninitialized, isLoading, getError } from 'utils/store/thunk';
import { pipe, defaultTo, isEmpty, not, prop, head } from 'ramda';
import { FETCH_PROJECT_READERS, ADD_PROJECT_READERS, FETCH_ORGANIZATION_ACCESS_CONTROL } from './types';
import { getOrganization } from 'store/organization/selectors';

const DOD_V2_FEATURE_FLAG = 'has_dod_v2';

const isNotEmpty = pipe(isEmpty, not);

const PARENT_ORGANIZATION_ID = 'parent_organization_id';
const defaultToEmptyObject = defaultTo({});
const emptyArray = [];
const getParentOrganizationIdProp = prop(PARENT_ORGANIZATION_ID);
const getFirstParentOrganizationId = pipe(head, defaultToEmptyObject, getParentOrganizationIdProp);

const getOperations = state => {
  return state.accessControl.operations;
};

const isFetchProjectReadersUninitialized = createSelector(getOperations, state => isUninitialized(state, FETCH_PROJECT_READERS));
const isFetchProjectReadersLoading = createSelector(getOperations, state => isLoading(state, FETCH_PROJECT_READERS));
const isAddProjectReadersLoading = createSelector(getOperations, state => isLoading(state, ADD_PROJECT_READERS));
const getAddProjectReadersError = createSelector(getOperations, state => getError(state, ADD_PROJECT_READERS));

const getProjectReaders = state => {
  return state.accessControl.projectReaders;
};

const getProjectReadersData = createSelector([getProjectReaders, (state, projectId) => projectId], (state, projectId) => {
  return state[projectId];
});

const getOrganizationAccessControlData = state => defaultTo(emptyArray, state.accessControl?.organizationAccessControl);

const isOrganizationAccessControlUninitialized = createSelector(getOperations, state =>
  isUninitialized(state, FETCH_ORGANIZATION_ACCESS_CONTROL),
);

const isOrganizationAccessControlLoading = createSelector(getOperations, state =>
  isLoading(state, FETCH_ORGANIZATION_ACCESS_CONTROL),
);

const getIsDodActive = createSelector(
  state => state.accessControl?.organizationAccessControl,
  state => getOrganization(state),
  (organizationsAccessControl = [], organization = {}) => {
    const isFeatureFlagActive = prop(DOD_V2_FEATURE_FLAG, organization);
    const hasAccessConfigured = isNotEmpty(organizationsAccessControl);

    return isFeatureFlagActive || hasAccessConfigured;
  },
);

const getIsParentDragon = createSelector(
  getOrganizationAccessControlData,
  getOrganization,
  (organizationAccessControlData, organization = {}) => {
    return organization?.id && organization.id === getFirstParentOrganizationId(organizationAccessControlData);
  },
);

const getAllReaderTokens = createSelector(
  state => state.accessControl?.readerTokens,
  (tokens = {}) => {
    return tokens;
  },
);

export {
  isFetchProjectReadersUninitialized,
  isFetchProjectReadersLoading,
  getProjectReadersData,
  getOrganizationAccessControlData,
  isAddProjectReadersLoading,
  getAddProjectReadersError,
  isOrganizationAccessControlUninitialized,
  isOrganizationAccessControlLoading,
  getIsDodActive,
  getAllReaderTokens,
  getIsParentDragon,
};
