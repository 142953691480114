import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import dragonboatTheme from 'design-system/dragonboatTheme';

const theme = createMuiTheme({
  palette: {
    primary: dragonboatTheme.palette.primary,
    brandDarkBlue: dragonboatTheme.palette.brandDarkBlue,
    secondary: {
      main: '#ff4b90',
    },
    black: '#000',
    white: '#fff',
    background: {
      alabaster: '#F9F9F9',
      lightGrey: '#EDEFEF',
      grey: '#9E9E9E',
      main: '#f6f7fb',
      white: '#fff',
      lightestGray: '#F9F9F9E9',
      primary: '#2EA8E1',
      gainsboro: '#dcdcdc',
      gallery: '#efefef',
      lightGallery: '#efefef66',
      lightBlue: '#F4F9FE',
      christmasSilver: '#e0e0e0',
      bermuda: '#64DAA7',
      anakiwa: '#A4E1FC',
      alto: '#D8D8D8',
      heaven: '#FFD9E2',
      veyra: '#BBFDEC',
      lemonTint: '#fff3cc',
      maldives: '#00c8dd',
      dimGray: '#6B6B6B',
      cultured: '#F6F6F6',
      jet: '#363636',
      ...dragonboatTheme.palette.background,
    },
    border: {
      mercury: '#E3E3E3',
      cyan: '#00B0F3',
      white: '#fff',
      gray: '#eeecec',
      lightGray: '#f5f5f5',
      lightBlack: 'rgba(0, 0, 0, 0.23)',
      darkBlue: '#428FB9',
      platinum: '#e3e3e3',
      dark: '#131c23',
      ...dragonboatTheme.palette.border,
    },
    gridColors: {
      mineShaft: '#303030',
    },
    disabled: {
      main: 'rgba(0,0,0,0.15)',
    },
    dependencyArrows: {
      backward: '#C62828',
      forward: '#58CCC6',
      unknown: '#000',
    },
    text: {
      green: '#7ACD5B',
      info: '#009dff',
      jungleGreen: '#29C546',
      purple: '#934FEA',
      error: '#ff154b',
      grey: '#777777',
      lightGrey: '#71767B',
      darkerGrey: '#666B71',
      lightestGrey: '#0000008a',
      yellow: '#ffc820',
      black: '#161616',
      lilacTime: '#a5abc1',
      midnightSun: '#515c70',
      darkBlue: '#428FB9',
      lightBlack: 'rgba(0, 0, 0, 0.8)',
      dark: '#0000004d',
      quickSilver: '#a7a7a7',
      chineseSilver: '#cccccc',
      ...dragonboatTheme.palette.text,
    },
    viewIconBackgroundColors: {
      hospitalGreen: '#94e5a5',
      wish: '#b5b5e4',
      kendallRose: '#f8cecd',
      veilingWaterfalls: '#d5ebff',
      sunkissedYellow: '#ffecb7',
      marchYellow: '#f0d588',
      beyondTheClouds: '#aff1ff',
      pruneMousse: '#e6d5f9',
      dingyStickyNote: '#e9f7a5',
    },
    newLayout: {
      background: {
        primary: '#2ea8e1',
        lightPrimary: '#DEF5FF',
        lightestPrimary: '#2ea8e12e',
        secondary: '#234988',
        lightestSecondary: '#2349881a',
        disable: '#88929B',
        lightDisable: '#88929ba6',
        lightestDisable: '#aeb6bd2e',
        danger: '#E52F16',
        white: '#ffffff',
        header: '#F4F9FD',
        parentHeader: '#F2F2FF',
        dark: '#38444E',
        gray: 'rgba(0, 0, 0, 0.54)',
        lightGrey: '#9B9B9B',
        seashellGrey: '#F1F1F1',
        athensGrey: '#DFE2E6',
        desertStormGrey: '#F9F9F8',
        mercuryGrey: '#E9E9E9',
        lightBlack: '#0000001F',
        lighterBlack: '#00000014',
        transparentBlack: 'rgba(0,0,0,.03)',
        red: '#EB5757',
        highlightRed: '#EB575733',
        lightGray: '#eee',
        darkGray: '#9e9e9e',
        darkestGray: '#333',
        borderGray: '#d9d9d9',
        cobalt: '#224788',
        lightBlue: '#1391BD',
        turquoise: '#6be2dc',
        fountainBlue: '#51aac2',
        dodgerBlue: '#2E7CF6',
        duskBlue: '#255C9D',
        concrete: '#f3f3f3',
        pastelGrey: '#CFD1D1',
        iron: '#CED8E2',
        oldSilver: '#81858A',
        oneClickPlanMode: {
          startButtonBackground: '#3f60a5',
          closeButtonBackground: '#366f85',
          gradient: 'linear-gradient(170deg, #3486F5 0%, #53DCEA 100%)',
        },
      },
      text: {
        primary: '#2ea8e1',
        disabled: '#232323',
        lightDisable: '#393939',
        white: '#ffffff',
        dark: '#333',
        grey: '#6b6b6b',
        doveGrey: '#616161',
        siroccoGrey: '#747575',
        black: '#0F1F2C',
        blue: '#2191c6',
        label: 'rgba(0, 0, 0, 0.54)',
        labelDisabled: 'rgba(0, 0, 0, 0.30)',
        oneClickPlanMode: {
          label: '#849dbf',
          inputText: '#FFFFFF',
        },
      },
      border: {
        secondary: '#3461ac',
        disable: '#9BA3AF',
        lightGray: '#F2F2F2',
        textarea: '#0079bf',
      },
      checkbox: {
        checked: '#58CCC6',
      },
      progressBarSquared: {
        background: {
          lightGray: '#F2F2F2',
          gray: '#9e9e9e',
          red: '#ff154b',
          green: '#59d146',
          inProgressGreen: '#92C47C',
          yellow: '#fde000',
        },
        border: {
          gray: '#515C70',
          red: '#B73C3C',
          yellow: '#AC9416',
          green: '#219653',
          darkestGray: '#333',
        },
      },
      microChipBackground: {
        lightBlue: '#bffffc',
      },
    },
    healthStatus: dragonboatTheme.palette.healthStatus,
    icons: dragonboatTheme.palette.icons,
    buttonIcon: dragonboatTheme.palette.buttonIcon,
    charts: dragonboatTheme.palette.charts,
    scopeVariance: dragonboatTheme.palette.scopeVariance,
    progressBar: dragonboatTheme.palette.progressBar,
    switches: dragonboatTheme.palette.switches,
    sentiments: dragonboatTheme.palette.sentiments,
    pointsVariance: dragonboatTheme.palette.pointsVariance,
  },
  shape: {
    borderRadiusSmall: 2,
    borderRadiusRegular: 4,
    borderRadiusHigh: 8,
    borderRadiusLarge: 10,
    borderRadiusExtraLarge: 13,
    ...dragonboatTheme.shape,
  },
  typography: {
    fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    fontFamilyAlternative: 'Inter, Roboto, Helvetica, Arial, sans-serif',
    useNextVariants: true,
    fontSizeExtraSmall: 10,
    fontSizeSmaller: 11,
    fontSizeSmall: 12,
    fontSize: 14,
    fontSizeSmallLarge: 16,
    fontSizeMediumSmall: 18,
    fontSizeMedium: 20,
    fontSizeMediumLarge: 22,
    fontSizeH4: 24,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    lineHeightSmaller: 14,
    lineHeightSmall: 15,
    lineHeightSmallLarge: 18,
    lineHeightRegularSmall: 20,
    lineHeightRegular: 20,
    lineHeightRegularLarge: 22,
    lineHeightLarge: 30,
    lineHeightLarger: 36,
    ...dragonboatTheme.typography,
  },
  zIndex: {
    lowest: -9999,
    lower: -101,
    low: -100,
    high: 100,
    higher: 101,
    highest: 9999,
    highest2: 1000000,
    // metadataDrawer: 1400,
    // okrDrawer: 20000, // this must be > than 19000 because of the pendi i icon
    // confirmDialog: 1600,
    // okrDrawerDialogs: 21000, // higher than okr drawer
    gridPopover: 1301,
    filtersPopover: 1000, // should be used to all filters popovers to be on same level of z-index
    // metricDrawer: 20001, // must be > thant okrDrawer
    // datePicker: 20001, // also update the value on fluidTimePeriodFieldOverride.css
  },
  sizing: {
    toolbar: {
      height: '50px',
    },
    icon: {
      height: '18',
    },
    ...dragonboatTheme.sizing,
  },
  overrides: {
    MuiSwitch: {
      colorPrimary: {
        '&$checked + $bar': {
          opacity: 1,
        },
      },
      switchBase: {
        '&$checked': {
          transform: 'translateX(16px)',
        },
      },
      bar: {
        width: 36,
        height: 20,
        marginTop: -10,
        borderRadius: 10,
      },
      icon: {
        height: 16,
        width: 16,
        backgroundColor: 'white',
        boxShadow: null,
      },
      iconChecked: {
        boxShadow: null,
        // transform: 'translateX(15px)',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8,
      },
      elevation1: {
        'box-shadow': '0px 2px 3px #6F77821A',
      },
      elevation2: {
        'box-shadow': '-1px 3px 5px 3px rgba(0,0,0,0.05)',
      },
      elevation8: {
        'box-shadow': '-1px 6px 17px 3px rgba(0,0,0,0.26)',
      },
    },
    MuiTabs: {
      indicator: {
        height: 3,
        width: 60,
        backgroundColor: '#FFF',
        bottom: 0,
      },
    },
    MuiInput: {
      input: {
        '&::placeholder': {
          color: 'rgba(0, 0, 0, 0.26)',
          opacity: 1,
        },
      },
      root: {
        fontWeight: 400,
      },
      underline: {
        '&:before': {
          borderBottom: '1px solid #e6e6e6',
          // borderBottom: '0',
        },
        '&:after': {
          borderBottom: '2px solid #234988',
          // borderBottom: '0',
        },
        // '&:hover:not($disabled):not($focused):not($error):before': {
        //   borderBottom: '0',
        // },
      },
    },
    MuiAppBar: {
      // Name of the styleSheet
      root: {
        // Name of the rule
        background: '#2096F3',
        boxShadow: 'none',
        color: 'white',
        height: 48,
      },
    },
    MuiToolbar: {
      root: {
        minHeight: '48px !important',
      },
    },
    MuiTableCell: {
      root: {
        color: '#000',
        fontWeight: 'normal',
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 'normal',
      },
    },
    MuiFormControlLabel: {
      label: {
        color: 'rgba(0, 0, 0, 0.54)',
        // fontSize: '0.75rem',
        // lineHeight: '0.75rem',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '11px',
      },
    },
    // MuiPickers: {
    //   paper: {
    //     root: {
    //       zIndex: 20000, // for datepicker to not show behind okr drawer
    //     },
    //   },
    // },
  },
});

export default theme;

export const spacing = (multiplier = 1) => theme.spacing.unit * multiplier;
