import React from 'react';
import './chat.css';
import Chatbot from 'react-chatbot-kit';
import DiscoveryBotOptions from './components/DiscoveryBotOptions';
import ChatHeaderComponent from './components/ChatHeaderComponent';
import MessageParser from './components/MessageParser';
import ActionProvider from './components/ActionProvider';
import AsyncMessageLoader from './components/AsyncMessageLoader';
import { createWelcomeMessage, createTargetTeamQuestion } from './utils/createMessages';

const DiscoveryBot = ({ selfGuidedDemoData, saveMessages, messageHistory }) => {
  const config = {
    initialMessages: [createWelcomeMessage(), createTargetTeamQuestion(selfGuidedDemoData)],
    state: {
      selfGuidedDemoData,
    },
    widgets: [
      {
        widgetName: 'optionButtons',
        widgetFunc: props => <DiscoveryBotOptions {...props} />,
      },
    ],
    customMessages: {
      // Forces a loader in messages for async bot calls, remove when message is returned
      asyncMessageLoader: props => <AsyncMessageLoader {...props} />,
    },
    customComponents: {
      // Replaces the default header
      header: () => <ChatHeaderComponent />,
    },
  };

  return (
    <Chatbot
      config={config}
      messageParser={MessageParser}
      actionProvider={ActionProvider}
      messageHistory={messageHistory}
      saveMessages={saveMessages}
    />
  );
};

export default DiscoveryBot;
