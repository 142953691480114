import isFunction from 'lodash/isFunction';
import { omit } from 'ramda';
import { REHYDRATE } from 'redux-persist';

import { SET_ACTIVE_TEMPLATE, SET_ACTIVE_TEMPLATE_CATEGORY } from './types';
import { getTemplates } from './selectors';
import { ensureViewStateCompatibility, getPageIdFromPath } from 'utils/userViews';
import { PAGES_STATE_TO_STORE } from 'store/userViews/consts';
import { setPageActiveView } from 'store/userViews/actions';
import { generateViewFromTemplate } from './utils/viewFromTemplateFactory';

const FORBIDDEN_STATE_KEYS = ['filter', 'id', 'title'];
const removeForbiddenKeysFromTemplate = omit(FORBIDDEN_STATE_KEYS);

export const setActiveTemplate = templateId => ({
  payload: templateId,
  type: SET_ACTIVE_TEMPLATE,
});

export const setActiveTemplateCategory = category => ({
  payload: category,
  type: SET_ACTIVE_TEMPLATE_CATEGORY,
});

export const applyTemplateOnStore = templateId => async (dispatch, getState) => {
  const state = getState();
  const templates = getTemplates(state);
  const selectedTemplate = templates.find(template => template.id === templateId);

  if (!selectedTemplate) return;

  const pageId = getPageIdFromPath(selectedTemplate.path);

  const viewFromTemplate = generateViewFromTemplate(state, selectedTemplate);

  if (viewFromTemplate) {
    dispatch(setPageActiveView(pageId, viewFromTemplate));
  } else {
    let stateToStore = PAGES_STATE_TO_STORE[pageId];

    if (!stateToStore) return;
    const viewState = ensureViewStateCompatibility(selectedTemplate.state, state);
    const viewStateWithoutFilter = removeForbiddenKeysFromTemplate(viewState);

    const templateView = { ...selectedTemplate, state: viewStateWithoutFilter };

    stateToStore = isFunction(stateToStore) ? { ...stateToStore(templateView, state) } : viewState;

    await dispatch({
      type: REHYDRATE,
      key: 'root',
      payload: stateToStore,
    });
  }
};
