import React from 'react';
import styled from 'styled-components';
import FluidField from 'design-system/molecules/FluidField/index';

import Switch from '@material-ui/core/Switch';

const FluidSwitchField = props => (
  <FluidField
    {...props}
    editorRenderer={({ value, onChange, save, cancel, disableEdit }) => (
      <AlignedSwitch checked={value || false} disabled={disableEdit} onChange={_ => save(!value)} color="primary" />
    )}
  />
);

const AlignedSwitch = styled(Switch)`
  margin-left: -16px;
`;

FluidSwitchField.propTypes = { ...FluidField.propTypes };

export default FluidSwitchField;
