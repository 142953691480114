import { useState } from 'react';

export default (updateSelectedItems, updateGridColumns, confirm, changeConfirmationDialogVisibility) => {
  const [step, setStep] = useState(1);
  const [confirmationError, setConfirmationError] = useState('');
  const [confirmationPending, setConfirmationPending] = useState(false);

  const goToReviewStep = () => {
    updateSelectedItems();
    updateGridColumns();

    setStep(2);
  };

  const goToConfirmationStep = async () => {
    try {
      setConfirmationError('');
      setConfirmationPending(true);

      await confirm();

      setStep(3);
    } catch (err) {
      if (err.message) {
        setConfirmationError(err.message);
      }

      updateSelectedItems();
    } finally {
      setConfirmationPending(false);
      changeConfirmationDialogVisibility(false);
    }
  };

  return {
    step,
    setStep,
    goToReviewStep,
    goToConfirmationStep,
    confirmationPending,
    confirmationError,
  };
};
