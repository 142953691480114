import React from 'react';
import styled from 'styled-components';

import loading from './loading.gif';

export default props => {
  if (props.error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(props.error);
    }
    /* eslint-disable */
    return (
      <Error>
        Please <a onClick={() => window.location.reload()}>refresh</a> the page to continue
      </Error>
    );
    /* eslint-enable */
  }

  return (
    <Wrapper className={props.className}>
      <LoadingGif src={loading} size={50} data-testid="loading" />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const LoadingGif = styled.img`
  width: ${props => props.size}px;
  height: auto;
`;

const Error = styled.p`
  white-space: pre-line;
  margin: ${props => props.theme.spacing.unit}px;
  line-height: 1.5;
`;
