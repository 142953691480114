import { materialColors } from 'design-system/themes/default';

export const NewProjectFields = [
  {
    autoFocus: true,
    key: 'title',
    name: 'Title',
    multiline: false,
    rows: 1,
    required: true,
    saveOnKeyEnter: true,
  },
  {
    key: 'details',
    name: 'Details',
    multiline: true,
    rows: 5,
    required: false,
  },
];

export const NewColumnField = [
  {
    autoFocus: true,
    key: 'title',
    name: 'Title',
    multiline: false,
    rows: 1,
    required: true,
  },
];

export const INITIATIVE = 'initiative';

export const BET = 'bet';

export const IDEA = 'idea';

export const MILESTONE = 'milestone';

export const HEALTH_STATUS = {
  UNDEFINED: '',
  CLOSED_OR_PAUSED: 'Blue',
  OFF_TRACK: 'Red',
  AT_RISK: 'Yellow',
  ON_TRACK: 'Green',
};

export const HEALTH_COLORS = {
  [HEALTH_STATUS.UNDEFINED]: materialColors.lightGray,
  [HEALTH_STATUS.CLOSED_OR_PAUSED]: materialColors.blue,
  [HEALTH_STATUS.ON_TRACK]: materialColors.green,
  [HEALTH_STATUS.AT_RISK]: materialColors.yellow,
  [HEALTH_STATUS.OFF_TRACK]: materialColors.red,
};

export const HEALTH_COLORS_LABELS = Object.keys(HEALTH_COLORS);

export const HEALTH_LABEL_OPTIONS = [
  { label: 'Undefined', value: 'Undefined' },
  { label: 'On track', value: 'Green' },
  { label: 'At risk', value: 'Yellow' },
  { label: 'Off track', value: 'Red' },
  { label: 'Inactive', value: 'Blue' },
];

export const HEALTH_FILTERS_LABEL_OPTIONS = HEALTH_LABEL_OPTIONS.filter(option => option.value !== 'Undefined');

export const HEALTH_LABEL_OPTIONS_LABELS = {
  Green: 'On track',
  Red: 'Off track',
  Yellow: 'At risk',
  Blue: 'Inactive',
  Undefined: 'Undefined',
};

export const MANUAL_TYPE = 'manual';
export const AUTO_TYPE = 'auto';

export const COMMITTED = 'Above the Line';
export const COMMITTED_KEY = 'committed';

export const INCLUDE_ALL_OPTION = 'all';
export const EXCLUDE_UNCOMMITTED_OPTION = 'excludeUncommitted';
export const EXCLUDE_AND_DISPLAY_UNCOMMITTED_OPTION = 'excludeAndDisplayUncommitted';

export const DEFAULT_PAGINATION_LIMIT = 200;
export const PAGE_LIMIT_OPTIONS = [25, 50, 100, 200];

export const ALL_CUSTOM_USER_FIELDS_AVAILABLE = {
  user_1_id: { field: 'user1Name', systemFieldKey: 'user1' },
  user_2_id: { field: 'user2Name', systemFieldKey: 'user2' },
  user_3_id: { field: 'user3Name', systemFieldKey: 'user3' },
  user_4_id: { field: 'user4Name', systemFieldKey: 'user4' },
  user_5_id: { field: 'user5Name', systemFieldKey: 'user5' },
  user_6_id: { field: 'user6Name', systemFieldKey: 'user6' },
  user_7_id: { field: 'user7Name', systemFieldKey: 'user7' },
};
