import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

class NewCardTemplate extends Component {
  state = {
    title: '',
    details: '',
    objectives: [],
  };

  handleTitleChange = (e) => {
    this.setState({ title: e.target.value });
  };

  handleTitleKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.handleAdd();
    }
  };

  handleDetailsChange = (e) => {
    this.setState({ details: e.target.value });
  };

  handleAdd = () => {
    this.props.onAdd(this.state, this.props.laneId);
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.card}>
        <CardContent>
          <TextField
            fullWidth
            label="Title"
            value={this.state.title}
            onChange={this.handleTitleChange}
            onKeyDown={this.handleTitleKeyDown}
          />
          <TextField
            fullWidth
            multiline
            rows="5"
            label="Details"
            value={this.state.details}
            onChange={this.handleDetailsChange}
          />
        </CardContent>
        <CardActions>
          <Button onClick={this.props.onCancel}>Cancel</Button>
          <Button onClick={this.handleAdd} color="primary">Save</Button>
        </CardActions>
      </Card>
    );
  }
}

const styles = theme => ({
  card: {
    width: 250,
    maxWidth: 250,
    margin: 'auto',
  },
});

export default withStyles(styles)(NewCardTemplate);
