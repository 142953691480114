import React from 'react';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import NotesIcon from '@material-ui/icons/Assignment';
import HistoryIcon from '@material-ui/icons/History';
import CloseIcon from '@material-ui/icons/Close';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';

import { removeElemProps } from 'design-system/utils';

export default ({ isNew, title, currentTab, setCurrentTab, onClose }) => (
  <Wrapper>
    <TooltipOnOverflow text={title}>
      <TaskTitle>{title}</TaskTitle>
    </TooltipOnOverflow>
    <TaskTabsContainer>
      <Tabs
        value={isNew ? 1000 : currentTab}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        textColor="primary"
        onChange={(e, v) => v !== 'ignore' && setCurrentTab(v)}
        centered
      >
        <HeaderTab
          component="div"
          disableTouchRipple
          disableRipple
          value="details"
          label={
            <TabIcon active={currentTab === 'details'} title="Details">
              <NotesIcon />
            </TabIcon>
          }
        />

        <HeaderTab
          component="div"
          disableTouchRipple
          disableRipple
          value="history"
          label={
            <TabIcon active={currentTab === 'history'} title="History">
              <HistoryIcon />
            </TabIcon>
          }
        />

        <HeaderTab
          component="div"
          disableTouchRipple
          disableRipple
          value="ignore"
          label={
            <TabIcon title="Close" onClick={onClose}>
              <CloseIcon />
            </TabIcon>
          }
        />
      </Tabs>
    </TaskTabsContainer>
  </Wrapper>
);

const Wrapper = styled.div`
  flex: 0 0 auto;
  margin: 0 0 12px 0;
  display: flex;
  width: 100%;
  align-items: center;
`;

const TaskTitle = styled.div`
  color: ${({ theme }) => theme.palette.text.grey};
  font-size: 16px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: ${({ theme }) => theme.typography.lineHeight.heading}rem;
`;

const TaskTabsContainer = styled.div`
  flex: auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
`;

const TabIcon = styled(removeElemProps(ButtonIcon, ['active']))`
  &&&&& {
    color: ${({ theme }) => theme.palette.text.grey};

    ${({ active, theme }) =>
      active &&
      `
      color: ${theme.palette.text.info};
    `}
    svg {
      fill: ${({ theme }) => theme.palette.text.grey};

      ${({ active, theme }) =>
        active &&
        `
          fill: ${theme.palette.text.info};
        `}
    }
  }
`;

const HeaderTab = styled(Tab)`
  &&&& {
    min-height: 0;
    font-weight: 400;
    font-size: '0.875rem';
    min-width: 0;

    > span > span {
      padding: 0 2px;
    }

    svg {
      font-size: 20px;
    }
  }
`;
