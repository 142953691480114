import React from 'react';
import { useSelector } from 'react-redux';
import ceil from 'lodash/ceil';

import { getProjectsGroupedBy } from 'store/dashboards/selectors';
import { getNormalizedRoadmaps } from 'store/roadmaps/selectors';
import { statusColors } from 'utils';
import { materialColors } from 'design-system/themes/default';
import calculateTextLines from 'utils/calculateTextLines';
import { UNDEFINED_LABEL } from 'constants/common';
import { INITIATIVE_LAYER } from 'src/store/projects/constants';
import useHealthStackedChart from 'hooks/charts/useHealthStackedChart';

const GROUP_BY_KEY = 'roadmap';
const MAX_NUM_LINES = 1;
const MAX_NUM_CHARS = 25;
const FONT_SIZE = 11;
const MAX_WIDTH_FOR_LABEL = 120;

const componentHOC = Component => {
  return props => {
    const selectedGroup = { key: GROUP_BY_KEY };
    const { roadmaps, data } = useSelector(state => ({
      roadmaps: getNormalizedRoadmaps(state),
      data: getProjectsGroupedBy(state, props.pageId, { selectedGroupByLvl1: selectedGroup }, INITIATIVE_LAYER),
    }));

    const reversedStatusColors = React.useMemo(() => [...statusColors].reverse(), [statusColors]);

    const _makeDataToChart = React.useCallback(() => {
      const labels = Object.keys(data)
        .filter(k => Boolean(data[k].length))
        .map(r => {
          const label = roadmaps[r]?.title || UNDEFINED_LABEL;
          const numLines = calculateTextLines(label, MAX_WIDTH_FOR_LABEL, FONT_SIZE);

          if (numLines > MAX_NUM_LINES) return `${label.slice(0, MAX_NUM_CHARS)}...`;

          return label;
        });
      const datasets = reversedStatusColors.reduce((acc, statusColor) => {
        const statusColorData = Object.values(data)
          .filter(v => Boolean(v.length))
          .map(v => {
            const projectsLength = v.filter(p => p.status_color === statusColor.label).length;

            return ceil((projectsLength * 100) / v.length, 2) || 0;
          });

        acc.push({
          label: statusColor.label || UNDEFINED_LABEL,
          data: statusColorData,
          backgroundColor: materialColors[statusColor.label.toLowerCase()],
          hoverBackgroundColor: materialColors[statusColor.label.toLowerCase()],
        });

        return acc;
      }, []);

      return { labels, datasets };
    }, [data, roadmaps]);

    const chart = useHealthStackedChart({ generateData: _makeDataToChart });

    return <Component {...props} chart={chart} />;
  };
};

export default componentHOC;
