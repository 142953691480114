/**
 * @function isGroupRow
 *
 * Check if ag grid row is a group based on data or node children
 *
 * @param  {Object} parms
 * @return {Boolean}
 */
const isGroupRow = params => {
  /*
   * On hierarchy mode the rows for without parent (Witthou Bet / Initiative) will
   * not have data object
   *
   * For those rows will check if the row is group based on node children
   *
   * For other rows we should check on data because we can have rows with
   * children that are not groups, the parents rows are not a group but they
   * have children rows
   */
  return params.data ? params.data.group : params.node.allChildrenCount > 0;
};

export { isGroupRow };
