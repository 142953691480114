import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';

import {
  FETCH_TAGS_FULFILLED,
  CREATE_TAG_FULFILLED,
  DELETE_TAG_FULFILLED,
  ADD_TAG_WITHOUT_SAVE,
  REMOVE_UNSAVED_TAGS,
  MERGE_TAGS_FULFILLED,
  UNDO_BULK_DELETE_TAGS_FULFILLED,
  UNDO_CREATE_TAGS_FULFILLED,
  UNDO_UPDATE_TAGS_FULFILLED,
  BULK_DELETE_TAGS_FULFILLED,
  UPDATE_TAGS_FULFILLED,
  CREATE_TAGS_FULFILLED,
  BULK_DELETE_TAG_ROADMAP_FULFILLED,
  CREATE_TAG_ROADMAP_FULFILLED,
  DELETE_TAG_ROADMAP_FULFILLED,
  UPDATE_TAG_BY_ID_FULFILLED,
} from './types';

import { IMPORT_CUSTOMER_REQUESTS_FULFILLED } from 'store/customerRequests/types';
import { Map, fromJS } from 'immutable';
import updateRows from 'store/utils/updateRows';
import addRows from 'store/utils/addRows';
import upsertListItem from 'store/utils/upsertListItem';

export default (state = new Map(), action) => {
  switch (action.type) {
    case FETCH_TAGS_FULFILLED: {
      const byId = state.get('byId') || {};

      action.payload.data.forEach(tag => {
        byId[tag.id] = tag;
      });

      return state.merge({
        byId,
        rows: fromJS(action.payload.data),
        lastCallsDate: moment().valueOf(),
      });
    }
    case CREATE_TAG_FULFILLED: {
      return state.set(
        'rows',
        state
          .get('rows')
          .filter(tag => tag.get('id') && tag.get('id') !== action.payload.id)
          .unshift(fromJS(action.payload)),
      );
    }
    case DELETE_TAG_FULFILLED: {
      return state.set(
        'rows',
        state.get('rows').filter(tag => tag.get('id') !== action.payload),
      );
    }
    case ADD_TAG_WITHOUT_SAVE: {
      return state.set(
        'rows',
        state
          .get('rows')
          .filter(tag => tag.get('id'))
          .unshift(fromJS(action.tag || {})),
      );
    }
    case REMOVE_UNSAVED_TAGS: {
      return state.set(
        'rows',
        state.get('rows').filter(tag => {
          return tag.get('id');
        }),
      );
    }
    case MERGE_TAGS_FULFILLED: {
      if (!action.payload) {
        return state;
      }

      return state.set(
        'rows',
        state.get('rows').filter(tag => !action.payload.includes(tag.get('id'))),
      );
    }
    case CREATE_TAGS_FULFILLED: {
      const tags = addRows(state.get('rows'), action.payload);

      state = state.set('rows', tags);
      state = state.set(
        'lastActionIds',
        action.payload.map(tag => tag.id),
      );

      return state;
    }
    case UPDATE_TAG_BY_ID_FULFILLED: {
      const byId = state.get('byId') || new Map();

      const updatedRows = updateRows(state.get('rows'), action.payload);

      return state.set('byId', byId.set(action.payload.id, action.payload)).set('rows', updatedRows);
    }
    case UPDATE_TAGS_FULFILLED: {
      const tags = updateRows(state.get('rows'), action.payload);

      state = state.set('rows', tags);
      state = state.set(
        'lastActionIds',
        action.payload.map(tag => tag.id),
      );

      return state;
    }
    case BULK_DELETE_TAGS_FULFILLED: {
      const tags = state.get('rows').filter(tag => !action.payload.includes(String(tag.get('id'))));

      state = state.set('rows', tags);
      state = state.set('lastActionIds', action.payload);

      return state;
    }
    case UNDO_UPDATE_TAGS_FULFILLED: {
      const tags = updateRows(state.get('rows'), action.payload);

      state = state.set('rows', tags);
      state = state.set(
        'lastActionIds',
        action.payload.map(tag => tag.id),
      );

      return state;
    }
    case UNDO_CREATE_TAGS_FULFILLED: {
      const tags = state.get('rows').filter(tag => state.get('lastActionIds').indexOf(tag.get('id')) === -1);

      state = state.set('rows', tags);
      state = state.set('lastActionIds', null);

      return state;
    }
    case UNDO_BULK_DELETE_TAGS_FULFILLED: {
      const tags = updateRows(state.get('rows'), action.payload);

      state = state.set('rows', tags);
      state = state.set('lastActionIds', null);

      return state;
    }
    case IMPORT_CUSTOMER_REQUESTS_FULFILLED: {
      const tags = updateRows(
        state.get('rows'),
        action.payload.reduce((tags, cr) => {
          return [...tags, ...(cr.tags || [])];
        }, []),
      );

      state = state.set('rows', tags);

      return state;
    }
    case CREATE_TAG_ROADMAP_FULFILLED:
      const existing = cloneDeep(state.get('rows').toJS() || []);
      const tag = existing.find(obj => obj.id === action.payload.tag_id);

      tag.tag_roadmaps = [...(tag.tag_roadmaps || []), action.payload];
      tag.updated_at = action.payload.updated_at;

      const updatedTags = upsertListItem(tag, existing);

      return state.set('rows', fromJS(updatedTags));
    case DELETE_TAG_ROADMAP_FULFILLED:
      const tags = cloneDeep(state.get('rows').toJS() || []);
      const { id: tagId, tagRoadmaps } = action.payload;
      const parent = tags.find(obj => obj.id === parseInt(tagId));

      parent.tag_roadmaps = tagRoadmaps || [];

      const updated = upsertListItem(parent, tags);

      return state.set('rows', fromJS(updated));
    case BULK_DELETE_TAG_ROADMAP_FULFILLED:
      const rows = cloneDeep(state.get('rows').toJS() || []);
      const updatedObjs = rows.map(tag => {
        if (tag.id === action.payload) {
          return {
            ...tag,
            tag_roadmaps: [],
          };
        }

        return tag;
      });

      return state.set('rows', fromJS(updatedObjs));
    default:
      return state;
  }
};
