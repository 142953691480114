import { toast } from 'react-toastify';

import { getCreateErrorMessage } from './errorMessages';

const handleCreateMetricError = err => {
  toast(getCreateErrorMessage());

  throw err;
};

export default handleCreateMetricError;
