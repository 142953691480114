import React, { Component } from 'react';
import styled from 'styled-components';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import { brandColor } from 'design-system/themes/default';

class ToggleButton extends Component {
  render() {
    return (
      <Button
        variant={this.props.on && !this.props.highlight ? 'outlined' : 'text'}
        color={this.props.on && !this.props.highlight ? 'primary' : 'default'}
        size={this.props.size || 'small'}
        title={this.props.title}
        disableRipple
        disabled={this.props.disabled}
        onClick={this.props.onChange}
        on={this.props.on ? this.props.on.toString() : ''}
        className={
          this.props.highlight ? `highlight animated infinite bounce delay-2s ${this.props.className}` : this.props.className
        }
        id={this.props.id}
        data-testid={this.props['data-testid']}
        data-cy={this.props['data-cy']}
        style={this.props.style || {}}
      >
        {this.props.icon || null}
        {this.props.content}
      </Button>
    );
  }
}

export default ToggleButton;

const Button = styled(ButtonIcon)`
  &&&& {
    border: 1px solid transparent;
    max-height: 48px;
    max-width: 48px;

    ${({ on, highlight }) =>
      on === 'true' &&
      highlight !== 'true' &&
      `
      border: 1px solid ${brandColor};
      color: ${brandColor};
    `}

    ${({ buttonSmall }) =>
      buttonSmall === 'true' &&
      `
      padding: 2px 8px;
    `}
  }

  &&&&.highlight {
    background-color: #ff4a90;
    color: #fff;
  }
`;
