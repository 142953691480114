import React from 'react';
import styled from 'styled-components';

import LocalSearch from './LocalSearch';

const MIN_HEIGHT = 48;

const ControlsBar = () => {
  return (
    <Wrapper>
      <LocalSearch />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  min-height: ${MIN_HEIGHT}px;
`;

export default ControlsBar;
