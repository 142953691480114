import moment from 'moment-timezone';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';

import { calculateGroupProgress } from 'utils';
import { materialColors } from 'design-system/themes/default';
import { UNDEFINED_LABEL } from 'constants/common';

const generateSummaryData = (projectGroups, options) => {
  const { selectedColOption, selectedRowOption, selectedGroupByOption, getTitle } = options;

  const groupType = selectedGroupByOption?.key ? selectedGroupByOption.key : null;
  const notDefined = { id: null, color: null };

  const _getTitle = (item, groupKey) => {
    const title = item?.id ? item.title || item.name : UNDEFINED_LABEL;

    if (getTitle) {
      return getTitle(item, title, groupKey);
    }

    return title;
  };

  const _mapItems = (item, groupKey) =>
    item?.id
      ? {
          ...item,
          title: !item.title ? item.name : item.title,
          id: item.id,
          color: item.color || (item.status_color && materialColors[item.status_color.toLowerCase()]) || '#00c8dd',
          date: item.date ? moment(item.date) : null,
          row_order: item.row_order,
        }
      : { ...notDefined, title: _getTitle(null, groupKey) };

  const _generateCellValues = row =>
    row?.elements.reduce((accCols, col) => {
      const colData = col?.groupData?.[selectedColOption?.key];

      const colElements = col?.elements;

      if (!groupType) {
        return [
          ...accCols,
          ...colElements.map(project => ({
            col: colData?.id ? _mapItems(colData, selectedColOption?.key) : null,
            group: null,
            project,
          })),
        ];
      }

      return [
        ...accCols,
        ...(colElements?.reduce((accGroups, groupElement) => {
          const groupData = groupElement?.groupData?.[groupType];
          const groupElements = groupElement?.elements;

          return [
            ...accGroups,
            ...(groupElements
              ? groupElements?.map(project => {
                  return {
                    col: colData?.id ? _mapItems(colData, selectedColOption?.key) : null,
                    group: [
                      groupData?.id
                        ? _mapItems(groupData, selectedColOption?.key)
                        : { id: groupElement?.id ?? null, title: groupElement?.title, color: null },
                    ],
                    project,
                  };
                })
              : []),
          ];
        }, []) ?? []),
      ];
    }, []) ?? [];

  const _filterProjByCol = col => rowValue => {
    if (Array.isArray(rowValue.col)) return rowValue.col.includes(col.id);

    return (rowValue.col && rowValue.col.id === col.id) || rowValue.col === col.id;
  };

  const mappedRows = projectGroups.map(row => {
    const newGroups = uniqBy(
      row?.elements
        ?.flatMap(col => col?.elements)
        ?.filter(colElement => colElement?.group)
        ?.map(group => group?.groupData?.[groupType])
        ?.map(group => _mapItems(group, groupType)),
      group => group?.title,
    );

    const rowObjectData = row?.groupData?.[selectedRowOption?.key];

    return {
      ...(rowObjectData?.id ? rowObjectData : { id: row?.id ?? null, title: row?.title, color: null }),
      title: _getTitle(rowObjectData, selectedRowOption?.key),
      values: _generateCellValues(row),
      groups: isEmpty(newGroups) ? null : newGroups,
    };
  });

  const cols2 = projectGroups.flatMap(group => group.elements);
  const mappedCols2 = cols2.map(col => {
    const groupData = col?.groupData?.[selectedColOption?.key];

    return groupData?.id ? groupData : { ...notDefined, title: _getTitle(null, selectedColOption?.key) };
  });

  // map each column to get totals of jira by each project
  return {
    cols: mappedCols2.map(col => {
      const colProjects = flatten(mappedRows.map(r => r.values)).filter(_filterProjByCol(col));

      const validIntegrationProjects = colProjects.filter(p => p.project && p.project.integrationProgress).filter(Boolean);

      const calcTotal = field => {
        return validIntegrationProjects.map(p => p.project.integrationProgress[field]).reduce((a, v = 0) => a + v, 0);
      };

      const totals = {
        issuesTotal: calcTotal('issuesTotal'),
        issuesClosed: calcTotal('issuesClosed'),
        issuesInProgress: calcTotal('issuesInProgress'),
        pointsClosed: calcTotal('pointsClosed'),
        pointsTotal: calcTotal('pointsTotal'),
        timeInProgress: calcTotal('timeInProgress'),
        timeTotal: calcTotal('timeTotal'),
      };

      const totalTasks = colProjects.length;

      const completedTasks = colProjects.filter(p => p.planningStage === 'Completed').length;

      return {
        ...col,
        ...totals,
        title: _getTitle(col, selectedColOption?.key),
        totalTasks,
        completedTasks,
        overallProgress: calculateGroupProgress(
          colProjects.map(({ project }) => project),
          col,
        ),
      };
    }),
    rows: mappedRows,
  };
};

export default generateSummaryData;
