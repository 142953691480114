import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';
import { withTheme } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import invertedTextColor from 'design-system/utils/invertedTextColor';

const AccordionGroupDeprecated = ({
  title,
  color,
  width,
  children,
  showGroupColor,
  isExpandedDefault,
  isReadOnly,
  onAddNewProject,
  onToggle,
}) => {
  const [isExpanded, setIsExpanded] = useState(isExpandedDefault);

  const handleChange = (_, expanded) => {
    setIsExpanded(!isExpanded);
    onToggle && onToggle(expanded);
  };

  const handleAddNew = e => {
    e.stopPropagation();

    onAddNewProject();
  };

  useEffect(() => {
    setIsExpanded(isExpandedDefault);
  }, [isExpandedDefault]);

  return (
    <Wrapper color={color} onChange={handleChange} expanded={isExpanded}>
      <GroupHeader
        showGroupColor={showGroupColor}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1c-content"
        id="panel1c-header"
        groupColor={color}
        {...(color ? { component: Paper, elevation: 1 } : {})}
      >
        <TitleText color="textPrimary" groupColor={color} style={{ maxWidth: isReadOnly ? width : width - 50 }}>
          {title}
        </TitleText>
        {!isReadOnly && (
          <AddButton onClick={handleAddNew}>
            <AddIcon />
          </AddButton>
        )}
      </GroupHeader>
      {isExpanded && <GroupDetails>{children}</GroupDetails>}
    </Wrapper>
  );
};

const Wrapper = styled(ExpansionPanel)`
  &&&& {
    margin-bottom: 0;
    box-shadow: none;
    :before {
      opacity: 0;
    }
  }
`;

const AddButton = withTheme()(styled(IconButton)`
  &&&& {
    padding: 12px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: none;
  }
`);

const GroupHeader = withTheme()(styled(ExpansionPanelSummary)`
  &&&& {
    background-color: ${props => (props.showGroupColor ? props.groupColor || 'lightgray' : 'white')};
    cursor: pointer;
    margin-bottom: 3.5px;
    position: relative;
    min-height: 35px;
    align-items: center;
    box-shadow: 0px 2px 3px #6f77821a;
    border: 1px solid #e8ecee;
    border-radius: 4px;

    display: flex;

    z-index: 100;

    & > div {
      align-items: center;
      margin: 0;
    }

    [role='button'] {
      left: 10px;
      width: 0;
      color: ${props => invertedTextColor(props.groupColor, true, '#fff', props.theme.palette.text.primary)};
    }

    &:hover {
      ${AddButton} {
        display: block;
      }
    }
  }
`);

const GroupDetails = styled(ExpansionPanelDetails)`
  &&&& {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
`;

const TitleText = withTheme()(styled(Typography)`
  &&&& {
    margin-left: 20px;
    font-weight: 400;
    font-size: 14px;
    color: ${props => invertedTextColor(props.groupColor, true, '#fff', props.theme.palette.text.primary)};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    padding-right: 0;
  }
`);

export default withTheme()(AccordionGroupDeprecated);
