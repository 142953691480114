import React from 'react';
import { useSelector } from 'react-redux';

import shouldAllowAddingNewMetadata from 'utils/shouldAllowAddingNewMetadata';

export default (Component) => {
  return (props) => {
    const { currentUser } = useSelector(state => state.login);
    const { organization } = useSelector(state => state.organization);

    const addNew = shouldAllowAddingNewMetadata(currentUser, organization, props.addNew)(props.metadata);

    return (<Component
      {...props}
      addNew={addNew}
    />);
  };
};
