import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

import { materialColors } from 'design-system/themes/default';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-left: ${({ $indent }) => ($indent ? 25 : 0)}px;
`;

const TextWrapper = styled.span`
  white-space: break-spaces;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ $nOfTextRowsToDisplay = 1 }) => $nOfTextRowsToDisplay};
  -webkit-box-orient: vertical;
  flex: 1;
  gap: 4px;

  &:hover .actions {
    visibility: visible;
  }
`;

const ActionsWrapper = styled.div`
  &&&& {
    display: flex;
    right: 10px;
    visibility: hidden;
    margin-left: auto;
  }
`;

const ActionButton = styled(IconButton)`
  &&&& {
    display: ${({ hideButton }) => (hideButton ? 'none' : 'inherit')};
    width: 14px;
    height: 14px;
    color: ${materialColors.darkerGray};
  }
`;

const StyledAddIcon = styled(AddIcon)`
  &&&& {
    width: 18px;
    height: 18px;
    font-size: ${props => props.theme.typography.fontSize}px;
  }
`;

export { Wrapper, TextWrapper, ActionsWrapper, ActionButton, StyledAddIcon };
