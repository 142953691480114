import styled from 'styled-components';

import { spacing } from 'design-system/theme';

const Header = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${spacing(2)}px 0 0 ${spacing(3.125)}px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${spacing(1.25)}px ${spacing(2)}px ${spacing(1.25)}px;
`;

const Wrapper = styled.div`
  width: calc(46vw - ${spacing(6)}px);
  min-width: 385px;
  max-width: 590px;
`;

export { Header, ButtonsContainer, Wrapper };
