import React from 'react';
import styled from 'styled-components';

import Loading from 'design-system/atoms/Loading';
import { INTEGRATIONS_KEYS } from 'constants/integrations';
import Button from '@material-ui/core/Button';

const BUTTON_LABEL = `+ Add new recurring`;
const AddRecurringButton = ({ integrationType, onClick, active, disabled, loading }) => {
  if (!integrationType || !INTEGRATIONS_KEYS[integrationType]) return null;

  return (
    <div>
      <StyledButton onClick={onClick} active={active} disabled={disabled || loading}>
        {loading ? <Loading /> : <>{BUTTON_LABEL}</>}
      </StyledButton>
    </div>
  );
};

export default AddRecurringButton;

const StyledButton = styled(Button)`
  &&&& {
    color: ${({ theme }) => theme.palette.newLayout.text.blue};
  }
`;
