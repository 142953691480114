const getWidgetId = widget => {
  return widget.uuid;
};

const getGridLayoutFromWidgets = widgets =>
  widgets.map(widget => ({
    i: getWidgetId(widget),
    w: Number(widget.width),
    h: Number(widget.height),
    x: widget.x,
    y: widget.y,
  }));

const sortWidgetsByCoordinates = widgets =>
  widgets.sort((a, b) => {
    if (a.y !== b.y) {
      return a.y - b.y;
    }
    return a.x - b.x;
  });

export { getWidgetId, getGridLayoutFromWidgets, sortWidgetsByCoordinates };
