import { createSelector } from 'reselect';
import { defaultTo } from 'ramda';

import { FETCH_CUSTOMER_REQUESTS_FOR_CLUSTER, FETCH_CUSTOMER_REQUEST_CLUSTERS_FOR_ANALYSE_CHART } from './types';
import { getData, isUninitialized } from 'utils/store/thunk';
import { makeGetClusterColor } from '../utils';

import {
  getCustomerSegmentCustomField,
  getCustomerTypeCustomField,
} from 'store/customFields/selectors';
import { getGridCustomerRequest, getMetadata } from 'store/customerRequests/selectors';

const defaultAsEmptyArray = defaultTo([]);

export function getState(state) {
  return state.customerRequestsAnalyze;
}

// Operations
export const getOperationsState = state => {
  return getState(state).operations || {};
};

export const getSelectedAnalyseClusterBy = createSelector(getState, state => state.selectedAnalyseClusterBy);

export const getClustersDataForAnalyseChart = createSelector(
  getOperationsState,
  getSelectedAnalyseClusterBy,
  getCustomerSegmentCustomField,
  getCustomerTypeCustomField,
  (state, selectedClusterBy, customerSegmentCustomField, customerTypeCustomField) => {
    const data = getData(state, FETCH_CUSTOMER_REQUEST_CLUSTERS_FOR_ANALYSE_CHART);

    const getClusterColor = makeGetClusterColor(selectedClusterBy, customerSegmentCustomField, customerTypeCustomField);

    return defaultAsEmptyArray(data).map(cluster => ({
      ...cluster,
      color: getClusterColor(cluster),
    }));
  },
);

export const isUninitializedClustersDataForAnalyseChart = createSelector(getOperationsState, state =>
  isUninitialized(state, FETCH_CUSTOMER_REQUEST_CLUSTERS_FOR_ANALYSE_CHART),
);

export const getSelectedAnalyzeBubbleSizeBy = createSelector(getState, state => state.selectedAnalyzeBubbleSizeBy);

export const getCustomerRequestsForCluster = createSelector(getOperationsState, getMetadata, (state, metadata) => {
  const customerRequests = getData(state, FETCH_CUSTOMER_REQUESTS_FOR_CLUSTER) || [];

  const enrichedCustomerRequests = customerRequests.map(customerRequest => getGridCustomerRequest(metadata, customerRequest));

  return enrichedCustomerRequests;
});
