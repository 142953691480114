import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useSubscribeNewData from 'hooks/useSubscribeNewData';
import { getCustomerRequestComments } from 'store/comments/selectors';

import {
  saveCustomerRequestComment as saveCustomerRequestCommentAction,
  fetchCustomerRequestComments as fetchCustomerRequestCommentsAction,
  fetchNextCustomerRequestComments as fetchNextCustomerRequestCommentsAction,
  deleteCustomerRequestComment as deleteCustomerRequestCommentAction,
  editCustomerRequestComment as editCustomerRequestCommentAction,
} from 'store/comments/actions';

export default Component => {
  return props => {
    const dispatch = useDispatch();
    const commentsData = useSelector(state => getCustomerRequestComments(state, props.item?.id));

    const hasMoreComments = !!commentsData?._meta?.next;
    const comments = commentsData?.data || [];

    const addCommentToCustomerRequest = useCallback(
      data => {
        dispatch(saveCustomerRequestCommentAction(props.item?.id, { ...data, field: props?.field }));
      },
      [props.item?.id, props.field],
    );

    const onFetchNextPageHandler = () => dispatch(fetchNextCustomerRequestCommentsAction(props.item?.id, props?.field));

    const onDeleteCommentHandler = useCallback(
      commentId => {
        dispatch(deleteCustomerRequestCommentAction(props.item?.id, commentId));
      },
      [props.item?.id],
    );

    const onEditCommentHandler = useCallback(
      (commentId, data) => {
        dispatch(editCustomerRequestCommentAction(props.item?.id, commentId, data));
      },
      [props.item?.id],
    );

    useSubscribeNewData(['app']);

    useEffect(() => {
      if (props.item?.id) {
        dispatch(fetchCustomerRequestCommentsAction(props.item?.id, props?.field));
      }
    }, [props.item?.id, props?.field]);

    return (
      <Component
        {...props}
        addComment={addCommentToCustomerRequest}
        comments={comments.length > 0 ? comments : props.item?.comments}
        hasMoreComments={hasMoreComments}
        fetchNext={onFetchNextPageHandler}
        onDeleteComment={onDeleteCommentHandler}
        onEditComment={onEditCommentHandler}
      />
    );
  };
};
