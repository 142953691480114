import axios from 'axios';
import { omit } from 'ramda';

import { createThunk } from 'utils/store/thunk';

import {
  UPDATE_GRID_PERSONA,
  FETCH_PERSONAS_GRID_DATA,
  SAVE_GRID_NEW_PERSONA,
  DELETE_GRID_PERSONA,
  SWITCH_PERSONAS_GRID_ROW_ORDER,
} from './types';
import { moveRowToPositionUsingLexorank } from 'utils/lexorankUtils';
import { selectPersonasGridData } from './selectors';

const updateGridPersona = (id, dataToUpdate) => {
  return createThunk(UPDATE_GRID_PERSONA, () => {
    return {
      promise: axios.put(`/api/v1/personas/${id}`, dataToUpdate).then(({ data }) => data),
      data: {
        id,
        ...dataToUpdate,
      },
    };
  });
};

const createNewGridPersona = newPersonaData => {
  return createThunk(SAVE_GRID_NEW_PERSONA, async () => {
    const createdPersona = omit(['isNew'], newPersonaData);

    return axios.post('/api/v1/personas', createdPersona).then(({ data }) => data);
  });
};

const deleteGridPersona = personaId => {
  return createThunk(DELETE_GRID_PERSONA, async () => {
    return axios
      .delete(`/api/v1/personas/${personaId}`)
      .then(() => personaId)
      .catch(() => personaId);
  });
};

const fetchPersonasGridData = () =>
  createThunk(
    FETCH_PERSONAS_GRID_DATA,
    axios.get('/api/v1/personas').then(({ data }) => data),
  );

const switchRowOrder = (id1, id2, update, position) => {
  return (dispatch, getState) => {
    const state = selectPersonasGridData(getState());
    const prevData = state.find(({ id }) => id === +id1);
    const movedRow = moveRowToPositionUsingLexorank(state, id1, id2, position);

    const payload = axios.put(`/api/v1/personas/rowOrder/${id1}/${id2}`, { position }).then(res => res.data);

    dispatch({
      type: SWITCH_PERSONAS_GRID_ROW_ORDER,
      payload,
      meta: { prev: prevData, row: movedRow, update },
    });

    return payload;
  };
};

export { updateGridPersona, createNewGridPersona, fetchPersonasGridData, deleteGridPersona, switchRowOrder };
