import React, { useMemo, useCallback } from 'react';
import { PERMISSION_RESOURCES, SYSTEM_FIELD } from '@dragonboat/permissions';

import { TAGS } from 'store/grids/constants';
import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SettingsDialogs from 'components/SettingsDialogs';
import { BaseGrid } from 'containers/Grids';
import {
  useSettingsActions,
  useSettingsGridSelection,
  useSettingsGridProps,
  useSettingsGridEventHandlers,
  useSettingsGridColumns,
} from 'containers/Grids/SettingsGrid/hooks';

import useTags from './hooks/useTags';
import useTagsGridColumns from './hooks/useTagsGridColumns';
import useForceVisibleColumns from 'hooks/useForceVisibleColumns';
import usePermissions from 'hooks/permissions/usePermissions';

const TAG_KEY = 'tag';
const TITLE_FIELD = 'title';

const Tags = ({
  showActionBar = true,
  updateSystemFieldsName,
  systemFields,
  hideMetadataRoadmaps,
  forceVisibleFields,
  customHeight,
  hideArchivedItems,
}) => {
  const {
    tags,
    createTag,
    createTags,
    updateTags,
    bulkDeleteTags,
    addTagWithoutSave,
    createTagRoadmap,
    deleteTagRoadmap,
    bulkDeleteTagRoadmaps,
    removeUnsavedTags,
    updateTagById,
    deleteTag,
    mergeTags,
  } = useTags(hideArchivedItems);

  // Specific column definition for tags
  const tagsGridColumnDefs = useTagsGridColumns({
    roadmapColumn: {
      createTagRoadmap,
      deleteTagRoadmap,
      tags,
      bulkDeleteTagRoadmaps,
      hideMetadataRoadmaps,
    },
  });

  // Settings common agGrid configuration

  const { selectionMode, selectedItems, enableSelectionMode, disableSelectionMode, updateSelectedOnAgGridInit } =
    useSettingsGridSelection(TAGS, {
      actions: {
        remove: removeUnsavedTags,
      },
    });

  const {
    itemToDelete,
    setItemToDelete,
    deleteItem,
    showBulkDeleteDialog,
    setShowBulkDeleteDialog,
    deleteProgress,
    bulkDeleteItems,
    mergeLightboxVisible,
    showMergeLightbox,
    hideMergeLightbox,
  } = useSettingsActions({
    settingType: TAGS,
    selectedItems,
    disableSelectionMode,
    deleteById: deleteTag,
    bulkDelete: bulkDeleteTags,
  });

  const settingsGridProps = useSettingsGridProps({
    bulkCreate: createTags,
    bulkUpdate: updateTags,
  });

  const { canUpdate, canCreate, canMerge, canView } = usePermissions();
  const canUpdateOrCreate = canUpdate(PERMISSION_RESOURCES.tag) || canCreate(PERMISSION_RESOURCES.tag);

  const permissions = {
    allowActions: canUpdateOrCreate,
    canAdd: canCreate(PERMISSION_RESOURCES.tag),
    rename: {
      canUpdate: showActionBar && canUpdate(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.tag }),
      canView: showActionBar && canView(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.tag }),
    },
    canMerge: showActionBar && canMerge(PERMISSION_RESOURCES.tag),
    canBulkDelete: showActionBar && canUpdateOrCreate,

    canUpdate: useCallback(data => canUpdate(PERMISSION_RESOURCES.tag, { data }), [canUpdate]),
  };

  const settingsGridColumns = useSettingsGridColumns({
    allowActions: permissions.allowActions,
    canUpdate: permissions.canUpdate,
    selectionMode,
    setItemToDelete,
    items: tags,
  });

  const settingsGridEventHandlers = useSettingsGridEventHandlers({
    settingKey: TAG_KEY,
    settingType: TAGS,
    items: tags,
    focusField: TITLE_FIELD,
    actions: {
      update: updateTagById,
      save: createTag,
      remove: removeUnsavedTags,
    },
  });

  const visibleColumns = useForceVisibleColumns(tagsGridColumnDefs, forceVisibleFields);

  // Join settings and tags column definitions
  const columnDefs = useMemo(
    () => [...settingsGridColumns.beforeColumnDefs, ...visibleColumns, ...settingsGridColumns.afterColumnDefs],
    [settingsGridColumns.beforeColumnDefs, settingsGridColumns.afterColumnDefs, visibleColumns],
  );

  return (
    <>
      {permissions.allowActions && (
        <SettingsActionsToolbar
          settingKey={TAG_KEY}
          settingType={TAGS}
          systemFields={systemFields}
          updateSystemFieldsName={updateSystemFieldsName}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          onEnableSelection={enableSelectionMode}
          onCancelSelection={disableSelectionMode}
          setShowBulkDeleteDialog={setShowBulkDeleteDialog}
          showMergeLightbox={showMergeLightbox}
          add={addTagWithoutSave}
          renamePermissions={permissions.rename}
          canMerge={permissions.canMerge}
          canBulkDelete={permissions.canBulkDelete}
          hideAddButton={!permissions.canAdd}
        />
      )}
      <BaseGrid
        onGridReady={updateSelectedOnAgGridInit}
        rowData={tags}
        columnDefs={columnDefs}
        defaultColDef={settingsGridColumns.defaultColDef}
        {...settingsGridProps}
        {...settingsGridEventHandlers}
        height={customHeight ?? settingsGridProps.height}
      />
      <SettingsDialogs
        settingKey={TAG_KEY}
        settingType={TAGS}
        showBulkDeleteDialog={showBulkDeleteDialog}
        setShowBulkDeleteDialog={setShowBulkDeleteDialog}
        deleteProgress={deleteProgress}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        deleteItem={deleteItem}
        bulkDelete={bulkDeleteItems}
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
        mergeLightboxVisible={mergeLightboxVisible}
        hideMergeLightbox={hideMergeLightbox}
        merge={mergeTags}
      />
    </>
  );
};

export default Tags;
