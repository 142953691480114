import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

import Components from './Components';

const GridTable = withTheme()(styled.table`
  position: relative;
  border-collapse: collapse;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.palette.border.mercury};
`);

// export Components inside GridTable class
Object.keys(Components).forEach(columnKey => (GridTable[columnKey] = Components[columnKey]));

GridTable.displayName = 'GridTable';

export default GridTable;
