import React from 'react';

import ChartContent from '../../ChartContent';

const ConfigurableChartWrapper = ({ children, title, subtitle, actionButtons }) => {
  return (
    <ChartContent title={title} subtitle={subtitle} actionButtons={actionButtons}>
      {children}
    </ChartContent>
  );
};

export default ConfigurableChartWrapper;
