import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { pluck } from 'ramda';

import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import { getCurrentUser } from 'store/login/selectors';
import { selectOrganization } from 'store/organization';
import { getGroupOptionsSelector } from 'store/roadmapVersions/groupSelectors';
import { getGroupOptions, getGroupOptionByKey, getDateOptions } from 'store/roadmapVersions/helpers/groupOptions';

import useMetadata from 'routes/Dashboard/Summary/hooks/useMetadata';
import useScenarioVersionGroups from './hooks/useScenarioVersionsGroups';
import useEditRoadmapVersionsProjectsActions from './hooks/useEditRoadmapVersionsProjectsActions';
import useComparePageEditScenarioCreateNewScenario from '../../hooks/useComparePageEditScenarioCreateNewScenario';
import usePermissions from 'hooks/permissions/usePermissions';

import Summary from 'routes/Dashboard/Summary/Summary';
import ActionBarComponent from './components/ActionsBarComponent';
import { COMPARE_SCENARIOS_SUMMARY } from 'constants/summary';
import { PAGE_HEADER_HEIGHT } from 'constants/common';
import { COMPARE_SCENARIOS_SUMMARY_PAGE } from 'constants/filters';
import { openProjectLightboxWithScenario } from 'store/projectLightbox';
import { SUPPORTED_FIELDS as SCENARIO_SUPPORTED_FIELDS } from 'utils/roadmapVersions/supportedFieldsUtils';

const emptyTableMessage = `0 projects matching the search criteria. Please update filter or display option.`;
const getKeys = pluck('key');

const fieldsToIgnoreAddColumnRow = ['scenarioVersions', ...getKeys(getDateOptions())];

const lightboxDisabledFields = [...getKeys(SCENARIO_SUPPORTED_FIELDS), 'timeline'];

const innerGroupTitle = (group, groupProjects) => {
  return `${group.title} (${groupProjects.length})`;
};

const CompareScenariosSummary = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const organization = useSelector(selectOrganization);
  const metadata = useMetadata(organization, []);

  useComparePageEditScenarioCreateNewScenario();
  const { isValidDropUpdate, normalizedProjects, onDragStartCallback, updateProject } = useEditRoadmapVersionsProjectsActions();

  const { canUpdate } = usePermissions();
  const hasEditPermissions = canUpdate(PERMISSION_RESOURCES.project);

  return (
    <StyledSummary
      isLoading={false}
      projects={[]}
      normalizedProjects={normalizedProjects}
      metadata={metadata}
      isTrial={false}
      currentUser={currentUser}
      hasEditPermissions={hasEditPermissions}
      pageFilters={{}}
      showControlsForUser
      page={COMPARE_SCENARIOS_SUMMARY_PAGE}
      emptyTableMessage={emptyTableMessage}
      useGroupsHook={useScenarioVersionGroups}
      getGroupOptionsSelector={getGroupOptionsSelector}
      getGroupOptions={getGroupOptions}
      getGroupOptionByKey={getGroupOptionByKey}
      slice={COMPARE_SCENARIOS_SUMMARY}
      ActionbarComponent={ActionBarComponent}
      openProjectLightbox={(projectId, scenarioId) => {
        dispatch(openProjectLightboxWithScenario(projectId, scenarioId, lightboxDisabledFields));
      }}
      getInlineGroupTitle={innerGroupTitle}
      isValidDropUpdate={isValidDropUpdate}
      onDragStartCallback={onDragStartCallback}
      updateProject={updateProject}
      allowAddNewProject={false}
      fieldsToIgnoreAddColumnRow={fieldsToIgnoreAddColumnRow}
    />
  );
};

const StyledSummary = styled(Summary)`
  height: calc(100vh - ${PAGE_HEADER_HEIGHT} - 4px);
`;

export default CompareScenariosSummary;
