import { defaultTo, isEmpty, keys, not, pick, pipe, pluck } from 'ramda';
import moment from 'moment-timezone';

import LoadableUsersMultiSelect from 'containers/LoadableMultiSelect/LoadableUsersMultiSelect';

import { hasMatchOnFilterOptionByOperation, hasTextMatchOnFilterOptionByOperation } from 'utils/filters/optionsFilterUtils';
import { PORTFOLIO_LABEL } from 'constants/roadmaps';
import { hasMatchingRoadmaps, hasSelectedUndefined } from 'routes/Metrics/utils';
import { FILTERS_FUNCTIONS } from 'design-system/constants';
import { METRIC_HEALTH_COLORS_OPTIONS } from 'constants/metrics';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

const isNotEmpty = pipe(isEmpty, not);
const defaultToEmptyArray = defaultTo([]);
const mapAllToId = pluck('id');

const STATUS_FIELD_KEY = 'status';

const DETAILS_FIELD = { id: 'details', type: 'text', label: 'Details' };
const HEALTH_FIELD = { id: 'status_color', type: 'option', label: 'Health' };
const OWNER_FIELD = { id: 'owners', type: 'option', label: 'Owner', inputComponent: LoadableUsersMultiSelect };
const PRODUCT_FIELD = { id: 'products', type: 'option', labelKey: 'product1' };
const PRODUCT_2_FIELD = { id: 'products2', type: 'option', labelKey: 'product2' };
const ROADMAP_FIELD = { id: 'roadmaps', type: 'option', labelKey: 'roadmap' };
const STATUS_FIELD = { id: STATUS_FIELD_KEY, type: 'option', label: 'Status' };
const TITLE_FIELD = { id: 'name', type: 'option', label: 'Name', labelOverrideKey: 'name' };
const METRICS_LEVEL_FIELD = { id: 'level', type: 'option', label: 'Type' };

const ACTIVE_STATUS = { id: 'Active', title: 'Active' };
const INACTIVE_STATUS = { id: 'Archived', title: 'Archived' };

const STATUS_OPTIONS = [ACTIVE_STATUS, INACTIVE_STATUS];

const UNDEFINED_OPTION = {
  id: null,
  title: 'Undefined',
};

const UNDEFINED_OPTION_FOR_ROADMAPS = {
  id: null,
  title: PORTFOLIO_LABEL,
};

const HEALTH_OPTIONS = [...METRIC_HEALTH_COLORS_OPTIONS].map(color =>
  color ? { id: color.value, title: color.label } : { id: color.value, title: 'Undefined' },
);

/**
 * @function getAvailableFields
 *
 * Returns all available fields to be used on the goals and metrics advanced search
 *
 * @return {Array}
 */
const getAvailableFields = (availableFields, getSystemFieldName, hasMetadataRoadmaps, permissions = {}) => {
  const roadmapFields = [ROADMAP_FIELD, PRODUCT_FIELD, PRODUCT_2_FIELD];

  return availableFields.reduce((fields, field) => {
    if (!hasMetadataRoadmaps && roadmapFields.some(f => f.id === field.id)) {
      return fields;
    }

    if (field.id === PRODUCT_2_FIELD.id && !permissions?.canView(PERMISSION_RESOURCES.product2)) {
      return fields;
    }

    let { label } = field;

    if (field.labelKey && getSystemFieldName) {
      label = getSystemFieldName(field.labelKey);
    }

    fields.push({
      ...field,
      label,
    });

    return fields;
  }, []);
};

/**
 * @function getFieldFunctions
 *
 * Returns the list of function available on some field
 *
 * @param  {Object} functionsByFieldType
 * @param  {Object} field
 * @return {Array}
 */
const getFieldFunctions = (functionsByFieldId, functionsByFieldType, field) => {
  if (!field?.type) {
    return [];
  }

  if (keys(functionsByFieldId).includes(field?.id)) {
    return functionsByFieldId[field?.id];
  }

  return functionsByFieldType[field.type];
};

/**
 * @function parseModuleFilters
 *
 * Function to parse the goal mode filters to global format
 *
 * @param  {Object} filters
 * @return {Object}
 */
const parseModuleFilters = (availableFields, filters) => {
  const availableFieldsKeys = mapAllToId(availableFields);

  return {
    fields: {
      ...pick(availableFieldsKeys, filters),
    },
    op: filters.op,
    aplliedAt: moment().format(),
    quickFilters: null,
  };
};

/**
 * @function getModuleBadgeContent
 *
 * Function to get total number of filters being applied to a page
 *
 * @param {Array} allFields list of fields available for filtering
 * @param {Array} filters list of filters being applied on page
 * @return {Number} count of filte conditions
 */
const getModuleBadgeContent = (allFields, filters) => {
  const availableFilterKeys = allFields.map(f => f.id);

  const filteredKeys = Object.keys(filters).filter(key => !!availableFilterKeys.includes(key));

  const filtersCounter = filteredKeys.length;

  return filtersCounter;
};

/**
 * @function makeFilterObjectByOwner
 *
 * Make filter function to filter objects by owner field
 *
 * @param  {Array} filterValue  - value of the owner filter
 * @param  {String} ownerField  - owner field on object
 * @param  {String} operation   - filter operation (in, not in, is empty, ...)
 * @return {Boolean}
 */
const makeFilterObjectByOwner =
  (filterValue, ownerField, operation, considerChildrenOnFilter = true, getObjectChildren = () => []) =>
  object => {
    const owners = mapAllToId(defaultToEmptyArray(filterValue));
    const value = object[ownerField];

    const hasMatch = hasMatchOnFilterOptionByOperation(owners, value, operation);

    if (hasMatch) return true;

    if (considerChildrenOnFilter) {
      const children = getObjectChildren(object);

      if (isEmpty(children)) return false;

      const childrenWithAppliedFilter = children.filter(makeFilterObjectByOwner(filterValue, ownerField, operation));

      return isNotEmpty(childrenWithAppliedFilter);
    }

    return false;
  };

/**

 * @function makeFilterObjectById
 *
 * Make filter function to filter objects by id field from multi-select name filter
 *
 * @param  {Array} filterValue  - value of the owner filter
 * @param  {String} idField  - owner field on object
 * @param  {String} operation   - filter operation (in, not in, is empty, ...)
 * @return {Boolean}
 */
const makeFilterObjectById = (filterValue, idField, operation) => object => {
  const value = object[idField]?.toString();

  return hasMatchOnFilterOptionByOperation(filterValue, value, operation);
};

/**
 * @function makeFilterObjectByHealth
 *
 * @param {Array} filterValue - value of health/status_color filter
 * @param {String} healthField - health field on object
 * @param {String} operation - filter operation (in, not in, is empty, ...)
 * @returns {Boolean}
 */
const makeFilterObjectByHealth = (filterValue, healthField, operation) => object => {
  const value = object[healthField];
  const emptyValue = '';

  return hasMatchOnFilterOptionByOperation(filterValue, value, operation, emptyValue);
};

const makeFilterObjectByLevel = (filterValue, levelField, operation) => object => {
  const value = object[levelField];

  return hasMatchOnFilterOptionByOperation(filterValue, value, operation);
};

const validateHasMatchingRoadmaps = (type, objValue, filterValues) =>
  type === ROADMAP_FIELD.id ? hasMatchingRoadmaps(objValue, filterValues, []) : hasMatchingRoadmaps(objValue, [], filterValues);

/**
 * @function makeFilterObjectByRoadmap
 *
 * Filters object based on roadmap/sub-roadmap id based on the roadmap type passed in
 *
 * @param {*} filterOperation filter operation (in, not in, is empty, ...)
 * @param {*} filterValues list of roadmap ids from filter
 * @param {*} objValue list of metadata roadmaps for object
 * @param {*} roadmapType roadmap or product (used to identify key)
 * @returns {Boolean}
 */
const makeFilterObjectByRoadmap = (filterOperation, filterValues, objValue, roadmapType = ROADMAP_FIELD.id) => {
  let hasMatch;

  switch (filterOperation) {
    case FILTERS_FUNCTIONS.isEmpty.key:
      return not(defaultToEmptyArray(objValue).length);
    case FILTERS_FUNCTIONS.isNotEmpty.key:
      return !!defaultToEmptyArray(objValue).length;
    case FILTERS_FUNCTIONS.notIn.key:
      if (isEmpty(filterValues)) return true;

      if (hasSelectedUndefined(filterValues) && isEmpty(objValue)) return false;

      hasMatch = validateHasMatchingRoadmaps(roadmapType, objValue, filterValues);

      return not(hasMatch);
    case FILTERS_FUNCTIONS.in.key:
    default:
      if (isEmpty(filterValues)) return true;

      if (hasSelectedUndefined(filterValues) && isEmpty(objValue)) return true;

      hasMatch = validateHasMatchingRoadmaps(roadmapType, objValue, filterValues);

      return hasMatch;
  }
};

const makeFilterObjectByText = (filterValue, textField, operation) => object => {
  const formattedFilterValue = filterValue ? filterValue.toLowerCase() : filterValue;
  const value = object[textField] ? object[textField].toLowerCase() : object[textField];

  return hasTextMatchOnFilterOptionByOperation(formattedFilterValue, value, operation);
};

export {
  getAvailableFields,
  getFieldFunctions,
  getModuleBadgeContent,
  makeFilterObjectByHealth,
  makeFilterObjectById,
  makeFilterObjectByOwner,
  makeFilterObjectByRoadmap,
  makeFilterObjectByText,
  makeFilterObjectByLevel,
  parseModuleFilters,
  DETAILS_FIELD,
  HEALTH_FIELD,
  HEALTH_OPTIONS,
  OWNER_FIELD,
  PRODUCT_FIELD,
  PRODUCT_2_FIELD,
  ROADMAP_FIELD,
  STATUS_FIELD,
  STATUS_FIELD_KEY,
  STATUS_OPTIONS,
  TITLE_FIELD,
  UNDEFINED_OPTION,
  UNDEFINED_OPTION_FOR_ROADMAPS,
  METRICS_LEVEL_FIELD,
};
