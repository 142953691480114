import React from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { DASHBOARDS_PAGE } from 'constants/filters/pages';
import useDashboardInformation from '../../hooks/useDashboardInformation';
import { savePageActiveView, updatePageActiveView } from 'store/userViews/actions';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const { activeDashboardView, isReadOnlyUser } = useDashboardInformation(props.match);

    const title = activeDashboardView?.name || '';

    const _updateTitle = React.useCallback(
      title => {
        dispatch(updatePageActiveView(DASHBOARDS_PAGE, { name: title }, false));

        if (activeDashboardView?.id) {
          // Return here is important, 
          // title component is expecting the dispatch promise to show a loading indicator
          return dispatch(savePageActiveView(DASHBOARDS_PAGE));
        }
      },
      [dispatch, activeDashboardView],
    );

    const isSaveDisallowed = activeDashboardView?.state?.dashboard?.disallowSave;

    return (
      <Component
        {...props}
        title={title}
        updateTitle={_updateTitle}
        disableEdit={isReadOnlyUser || isSaveDisallowed}
        activeDashboardView={activeDashboardView}
      />
    );
  };
};

export default compose(withRouter, componentHOC);
