import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getChartTitle, getProgressChartData } from './utils';
import getSystemFieldName from 'utils/getSystemFieldName';

const progressTableHOC = Component => {
  return props => {
    const { data, loading, startDate, endDate, targetAndPredictedLinesOptions, titleType } = props;
    const systemFields = useSelector(state => state.organization.organization.system_fields_name);

    const [openTimeline, setOpenTimeline] = useState();

    const registerOpenTimelineCallback = useCallback(
      openTimeline => {
        setOpenTimeline(() => openTimeline);
      },
      [setOpenTimeline],
    );

    const chartData = useMemo(
      () => getProgressChartData(data, startDate, endDate, targetAndPredictedLinesOptions),
      [data, startDate, endDate, targetAndPredictedLinesOptions],
    );

    const chartTitle = loading ? 'Loading...' : getChartTitle(titleType, systemFields);

    return (
      <Component
        {...props}
        title={chartTitle}
        data={chartData}
        loading={loading}
        onChartClick={openTimeline}
        registerOpenTimelineCallback={registerOpenTimelineCallback}
        timelineLightboxTitleEntity={{ key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) }}
        timelineLightboxGroupBy={{ key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) }}
      />
    );
  };
};

export default progressTableHOC;
