import { CUSTOM_FIELD_TYPES } from 'store/customFields/constants';

export default (customFields, type) => {
  const mappedCustomFields = customFields
    .filter(cf => cf.field_type === 'Number' && cf.title)
    .reduce((acc, cf) => {
      return { ...acc, [cf.title.toUpperCase()]: cf.key };
    }, {});

  if (type === CUSTOM_FIELD_TYPES.CUSTOMER_REQUESTS) {
    return {
      ...mappedCustomFields,
    };
  }

  return {
    'REACH SCORE': 'reach_score',
    'IMPACT SCORE': 'impact_score',
    'CONFIDENCE SCORE': 'confidence_score',
    'RICE SCORE': 'riceScore',
    BENEFIT: 'business_value',
    EFFORT: 'effort_score',
    ...mappedCustomFields,
  };
};
