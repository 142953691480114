import React, { useState } from 'react';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';

import FluidContainer from 'design-system/atoms/FluidContainer/index';
import FluidSelectField from 'design-system/organisms/FluidSelectField/index';
import FluidTextField from 'design-system/organisms/FluidTextField/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import { materialColors } from 'design-system/themes/default';
import theme, { spacing } from 'design-system/theme';

import ConfirmDialog from 'components/ConfirmDialog';

import useDeepEffect from 'hooks/useDeepEffect';
import getDisplayLayerLabel from 'store/projects/helpers/getDisplayLayerLabel';
import { TAB_DETAILS } from 'constants/projectLightbox';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';

const ChangeTypeDialog = ({
  showChangeTypeDialog,
  closeChangeTypeDialog,
  selectedProject,
  hasBet,
  reload,
  onSave,
  onTabChange,
  getSystemFieldName,
}) => {
  const options = [IDEA_LAYER, INITIATIVE_LAYER, ...(hasBet ? [BET_LAYER] : [])]
    .filter(key => +key !== +selectedProject?.layer)
    .map(key => getDisplayLayerLabel(key, getSystemFieldName));

  const currentTypeLabel = getDisplayLayerLabel(selectedProject?.layer, getSystemFieldName);

  useDeepEffect(() => {
    setNewType(options[0]);
  }, [options]);

  const mapLayerToType = {
    [getDisplayLayerLabel(IDEA_LAYER, getSystemFieldName)]: IDEA_LAYER,
    [getDisplayLayerLabel(INITIATIVE_LAYER, getSystemFieldName)]: INITIATIVE_LAYER,
    [getDisplayLayerLabel(BET_LAYER, getSystemFieldName)]: BET_LAYER,
  };

  const [newType, setNewType] = useState(options[0]);

  const _onConfirm = e => {
    onSave(false, false, { id: selectedProject?.id, layer: mapLayerToType[newType], Jiras: [] }, false).then(reload);
    closeChangeTypeDialog();
    onTabChange(e, TAB_DETAILS);
  };

  return (
    <ConfirmDialog
      isOpen={showChangeTypeDialog}
      row={selectedProject}
      title="Change type"
      text={
        <React.Fragment>
          <form style={{ width: 500 }} autoComplete="off">
            <Grid container xs={12} justify="space-around">
              <FluidContainer size={6}>
                <FluidTextField key="type" disableEdit value={currentTypeLabel} label="From" format="row" />
              </FluidContainer>
              <FluidContainer size={6}>
                <FluidSelectField
                  key="type"
                  onChange={setNewType}
                  value={newType}
                  label="To"
                  format="row"
                  options={options}
                  zIndex={theme.zIndex.modalSelect}
                />
              </FluidContainer>
            </Grid>
          </form>
          {(selectedProject.Jiras || []).length > 0 && (
            <LinkedJiraIssuesWarn>
              <TextDeprecated>Linked Jira issues will be unlinked</TextDeprecated>
            </LinkedJiraIssuesWarn>
          )}
        </React.Fragment>
      }
      onCancel={closeChangeTypeDialog}
      onConfirm={_onConfirm}
      blankBody
    />
  );
};

const LinkedJiraIssuesWarn = styled.div`
  &&&& {
    padding-top: ${spacing(2)}px;
    padding-left: ${spacing()}px;

    ${TextDeprecated} {
      color: ${materialColors.darkGray};
    }
  }
`;

export default ChangeTypeDialog;
