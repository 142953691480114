import { List, Map } from 'immutable';

import upsertListItem from './upsertListItem';
import upsertImmutableListItem from './upsertImmutableListItem';

/**
 * @param {Array.Object|ImmutableList.Object} rows - list of elements to which it will add payload elements
 * @param {Array.Object} payload - list of elements to be added to rows
 * @param {Function} serialize - serialize every payload elements. It's a map function
 */
export default (rows, payload, serialize) => {
  if (!payload || !(payload instanceof Object)) {
    return rows;
  } else if (!(payload instanceof Array)) {
    payload = [payload];
  }

  let newRows = [];

  if (typeof serialize === 'function') {
    payload = payload.map(serialize);
  }

  if (rows instanceof Array) {
    newRows = rows.slice(0);

    payload.forEach(row => {
      newRows = upsertListItem(row, newRows);
    });
  } else if (List.isList(rows)) {
    newRows = rows;
    payload.forEach(row => {
      newRows = upsertImmutableListItem(new Map(row), newRows);
    });
  }

  return newRows;
};
