import { ScopeVarianceRenderer } from '../cellRenderers';
import { numberComparator } from '../comparators';

import textCenterAlignedColumnDef from './textCenterAlignedColumnDef';

const scopeVarianceColumnDef = {
  ...textCenterAlignedColumnDef,
  cellRenderer: ScopeVarianceRenderer,
  comparator: numberComparator,
  width: 180,
};

export default scopeVarianceColumnDef;
