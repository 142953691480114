import React from 'react';
import PropTypes from 'prop-types';
import { pluck, uniq } from 'ramda';

export const errorTypes = {
  INVALID_VIEW: 'INVALID_VIEW',
  INVALID_BASE_URL: 'INVALID_BASE_URL',
  VIEW_PROJECT_FILTER_WARNING: 'VIEW_PROJECT_FILTER_WARNING',
  INVALID_WORKSPACE_SELECTION: 'INVALID_WORKSPACE_SELECTION',
};

const ErrorMessage = ({ errorType, integratedURLs = [], workspacesList }) => {
  switch (errorType) {
    case errorTypes.INVALID_VIEW:
      return <span>Please save your Rally view first and then copy and paste the URL</span>;
    case errorTypes.INVALID_WORKSPACE_SELECTION:
       return (
         <span>Workspace selection must have a valid integration: ({pluck('title', workspacesList).join(', ')})</span>
       );
    case errorTypes.INVALID_BASE_URL:
      const uniqueIntegratedURLs = uniq(integratedURLs.map(url => new URL(url).hostname));

      return <span>The Work Item view URL must contain an integrated Rally domain: ({uniqueIntegratedURLs.join(', ')})</span>;
    case errorTypes.VIEW_PROJECT_FILTER_WARNING:
      return <span>To prevent importing unwanted work items, specify the Rally project in the view filters.</span>;
    default:
      return null;
  }
};

ErrorMessage.propTypes = {
  errorType: PropTypes.string.isRequired,
  integratedURLs: PropTypes.array,
};

export default ErrorMessage;
