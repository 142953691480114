import React from 'react';
import styled, { css } from 'styled-components';
import theme, { spacing } from 'design-system/theme';
import DividedList from 'design-system/organisms/DividedList/index';
import Text from 'design-system/atoms/Text/index';


const SidebarContentContainer = ({ navOptions }) => {
  return (
    <>
      <StyledNavTitle variant="h4">Select A Business Context</StyledNavTitle>
      <SidebarOptions topOptions={navOptions} />
    </>
  );
};

const linkStyles = css`
  cursor: pointer;
  text-decoration: none;
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;

  ${({ active, theme }) =>
    active &&
    `
    color: ${theme.palette.text.actionLabel.active};
  `}
  &:visited {
    color: initial;
    text-decoration: none;
  }

  &:hover {
    color: ${theme.palette.text.actionLabel.active};
    text-decoration: none;
  }
`;

const SidebarOptions = styled(DividedList)`
  padding-top: ${spacing(3.75)}px;

  & a {
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    line-height: ${({ theme }) => theme.typography.lineHeight.bold}rem;
    ${linkStyles}
  }
`;

const StyledNavTitle = styled(Text)`
  color: ${theme.palette.text.purple};
`;

export default SidebarContentContainer;
