import React from 'react';
import styled from 'styled-components';

import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import Popover from '@material-ui/core/Popover';

import ChromePicker from 'components/ReactColor/ChromePicker';
import MicroChip from 'design-system/atoms/MicroChip';

export default ({ initialColor, onChangeColor, disabled }) => {
  const [color, setColor] = React.useState(initialColor);
  const [showPicker, setShowPicker] = React.useState(false);
  const clickRef = React.useRef(null);

  const _handleClose = () => {
    setShowPicker(false);
    if (initialColor !== color) onChangeColor(color);
  };

  const _iconClicked = e => {
    if (disabled) {
      return;
    }

    setShowPicker(true);
    clickRef.current = e.target;
  };

  return (
    <div>
      <MenuIcon onClick={_iconClicked}>
        <StyledChip color={color} />
      </MenuIcon>
      <Popover
        open={!!showPicker}
        anchorEl={clickRef.current}
        onClose={_handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ChromePicker color={color} onChange={value => setColor(value.hex)} />
      </Popover>
    </div>
  );
};

const MenuIcon = styled(MuiListItemIcon)`
  &&&& {
    width: 10px;
    display: flex;
    justify-content: center;
    span {
      padding: 0;
      color: #2ea8e1;
    }
  }
`;

const StyledChip = styled(MicroChip)`
  &&&& {
    width: 10px;
    height: 10px;
    border-radius: 12px;
    cursor: pointer;
  }
`;
