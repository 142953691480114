import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { pipe, isEmpty, not, isNil, either } from 'ramda';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import { updateTeamEstimationSettingsById } from 'store/teams';

import { ESTIMATE_BY_POINTS, ESTIMATE_BY_TIME } from 'constants/teams';

const isNotEmpty = pipe(either(isEmpty, isNil), not);
const isNotZero = val => val !== 0;

const useTeamLightbox = ({ team, onClose }) => {
  const { canUpdate } = usePermissions();

  const canEdit = canUpdate(PERMISSION_FEATURES.usePointsToEstimate);

  const [estimatingByPoints, setEstimatingByPoints] = useState(team?.estimate_by === ESTIMATE_BY_POINTS);
  const [workdaysPerPoint, setWorkdaysPerPoint] = useState(team?.workdays_per_point || 1);
  const dispatch = useDispatch();

  const onToggleEstimateByPoints = () => {
    if (!canEdit) return;
    setEstimatingByPoints(prev => !prev);
  };

  const onWorkdaysPerPointChange = value => {
    setWorkdaysPerPoint(value?.floatValue);
  };

  const onSave = () => {
    if (!canEdit) return;
    dispatch(
      updateTeamEstimationSettingsById(team?.id, {
        estimate_by: estimatingByPoints ? ESTIMATE_BY_POINTS : ESTIMATE_BY_TIME,
        workdays_per_point: workdaysPerPoint,
      }),
    );
    onClose(onClose);
  };

  const canSaveChanges = isNotEmpty(workdaysPerPoint) && isNotZero(workdaysPerPoint) && canEdit;

  return {
    estimatingByPoints,
    workdaysPerPoint,
    onToggleEstimateByPoints,
    onWorkdaysPerPointChange,
    onSave,
    canEdit,
    canSaveChanges,
  };
};

export default useTeamLightbox;
