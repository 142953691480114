import axios from 'axios';

export const requestUserFilterCreate = async (filterPageId, filtersObject, currentUser) => {
  const userFilterObject = {
    state: filtersObject,
    page: filterPageId,
    default_filter: true,
    name: `Default Filter (${currentUser.id})`,
    user_id: currentUser.id,
  };

  if (currentUser.isSuperAdmin) return;

  const { data } = await axios.post('/api/userFilters/', userFilterObject);

  return data;
};

export const requestUserFilterUpdate = async (id, updatedUserFilterData) => {
  const { data } = await axios.put(`/api/userFilters/${id}`, updatedUserFilterData);

  return data;
};
