import React from 'react';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CircularProgress from '@material-ui/core/CircularProgress';

import ButtonIcon from 'design-system/molecules/ButtonIcon';
import TextDeprecated from 'design-system/atoms/TextDeprecated';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';
import SearchBar from 'components/SearchBar';

// TODO: create new constant for this and remove from snapshot context
import { GANTT_TIMELINE_PROJECTS_LIMIT } from 'constants/snapshot';

import {
  Container,
  ControlItem,
  Controls,
  ControlsContainer,
  GroupAndLimitContainer,
  LimitResultsWarning,
  LoadingContainer,
  PageTitle,
  SyledGroupByAutocomplete,
} from './styles';

const ControlsBar = ({
  search,
  setSearch,
  zoom,
  toggleZoom,
  loadingStories,
  groupByOptions,
  selectedGroup,
  setSelectedGroup,
  groupedBy,
  entityGroup,
  totalProjectsByLayer = 0,
  hideMaxItemDisplayMessage = false,
  isLoaded = false,
}) => {
  return (
    <Container>
      <TooltipOnOverflow text={entityGroup?.title && `${entityGroup?.title}: ${groupedBy.title}`}>
        <PageTitle variant="h2" withEllipsis>
          {entityGroup?.title && `${entityGroup?.title}: ${groupedBy.title}`}
        </PageTitle>
      </TooltipOnOverflow>
      <ControlsContainer>
        <GroupAndLimitContainer>
          <SyledGroupByAutocomplete
            name="selectedGroup"
            suggestions={groupByOptions}
            value={selectedGroup}
            onChange={setSelectedGroup}
          />
          {isLoaded && !hideMaxItemDisplayMessage && totalProjectsByLayer > GANTT_TIMELINE_PROJECTS_LIMIT ? (
            <LimitResultsWarning breakwords>Display the first {GANTT_TIMELINE_PROJECTS_LIMIT} items</LimitResultsWarning>
          ) : null}
        </GroupAndLimitContainer>
        <Controls>
          {loadingStories && (
            <ControlItem>
              <LoadingContainer>
                <CircularProgress size={20} disableShrink /> <TextDeprecated>Loading Stories</TextDeprecated>
              </LoadingContainer>
            </ControlItem>
          )}
          <ControlItem>
            <SearchBar searchString={search} updateSearchString={setSearch} style={{ marginRight: 15 }} />
          </ControlItem>
          <ControlItem>
            <ButtonIcon title={zoom ? 'Zoom in' : 'Zoom out'} onClick={toggleZoom} small>
              {zoom ? <ZoomInIcon /> : <ZoomOutIcon />}
            </ButtonIcon>
          </ControlItem>
        </Controls>
      </ControlsContainer>
    </Container>
  );
};

export default ControlsBar;
