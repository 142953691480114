import axios from 'axios';
import throwRequestError from 'src/store/utils/throwRequestError';

export const callCreateMetric = async metric => {
  return axios
    .post('/api/metrics', metric)
    .then(res => res.data)
    .catch(throwRequestError);
};

export const callAddMetricToProject = async (projectId, metricId) => {
  return axios
    .post(`/api/projects/${projectId}/metrics/${metricId}`)
    .then(res => res.data)
    .catch(throwRequestError);
};