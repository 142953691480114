import React, { useEffect } from 'react';

import PermissionsDialog from 'components/PermissionsDialog';
import { useDispatch } from 'react-redux';
import usePermissionsDialog from 'hooks/usePermissionsDialog';
import { showPermissionsDialog } from 'store/app';

export default function () {
  const dispatch = useDispatch();
  const { isDialogVisible } = usePermissionsDialog();

  useEffect(() => {
    if (!isDialogVisible) {
      dispatch(showPermissionsDialog());
    }
  }, [isDialogVisible]);

  return (
    <>
      <PermissionsDialog />
    </>
  );
}
