import React, { useState } from 'react';

import withTheme from '@material-ui/core/styles/withTheme';
import styled from 'styled-components';

import Checkbox from 'design-system/atoms/Checkbox/index';

import cellEditorHoc from 'design-system/molecules/AgGridReact-New/helpers/cellEditorHoc';

const CheckboxCellEditor = cellEditorHoc(({ column, node, value }) => {
  const [newValue, setNewValue] = useState(value);

  const checkedHandler = e => {
    const { checked } = e.target;
    const { colId } = column;

    try {
      node.setDataValue(colId, checked);
      setNewValue(checked);
    } catch {
      // pass
    }
  };

  return <StyledCheckbox disableTouchRipple fontSize="small" onChange={checkedHandler} checked={newValue} />;
});

export default CheckboxCellEditor;

const StyledCheckbox = withTheme()(styled(Checkbox)`
  &&&&&& {
    svg {
      color: ${({ theme }) => theme.palette.text.info};
    }

    padding: 0 !important;

    ${({ disabled }) => disabled && `opacity: .5;`}
  }
`);
