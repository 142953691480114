import React from 'react';
import styled from 'styled-components';

import FormLabel from 'design-system/atoms/FormLabel/index';

import Comments from 'components/Comments';

const CommentsWrapper = styled.div`
  padding: 0 8px;
  width: 100%;
`;

const Header = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    min-height: 48px;

    h5 {
      color: #111;
      font-size: 1.1rem;
    }
  }
`;

export default props => {
  return (
    <CommentsWrapper>
      <Header>
        <FormLabel>Comments</FormLabel>
      </Header>
      <Comments
        onSave={props.addComment}
        comments={props.comments}
        hasMoreComments={props.hasMoreComments}
        showMore={props.onFetchNextPage}
        onDelete={props.onDeleteComment}
        onEdit={props.onEditComment}
        readOnly={props.readOnly}
      />
    </CommentsWrapper>
  );
};
