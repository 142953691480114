import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import GanttWrapper from 'components/GanttWrapper';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';
import LoadingOverlay from 'design-system/atoms/LoadingOverlay/index';


import 'styles/gantt.css';
import useIntegrations from 'hooks/useIntegrations';
import useAppNotifications from 'hooks/useAppNotifications';

import { resetProjectUpdate } from 'store/projects';
import {
  isLoadingProjects,
  isCreateProjectLoading,
  isUpdateProjectLoading,
  isDeleteProjectLoading,
  isUpdateProjectError,
} from 'store/projects/selectors';
import { isSomeUpdateOnProjectsEstimatesOcurring } from 'store/estimates/selectors';

const GanttContainer = ({ height, ganttRef, gantt, pageControlsVisible, onGanttContainerMounted }) => {
  const dispatch = useDispatch();

  const { addError } = useAppNotifications();

  const timerRef = React.useRef();
  const [loading, setLoading] = React.useState(false);

  const isFetchingProjects = useSelector(isLoadingProjects);

  const isCreatingProject = useSelector(isCreateProjectLoading);
  const isUpdatingProject = useSelector(isUpdateProjectLoading);
  const isDeletingProject = useSelector(isDeleteProjectLoading);
  const hasProjectUpdateError = useSelector(isUpdateProjectError);

  const areEstimatesUpdating = useSelector(isSomeUpdateOnProjectsEstimatesOcurring);

  const { isFetching } = useIntegrations();

  React.useEffect(() => {
    if (!gantt) return;

    gantt.attachEvent('onGanttRender', () => {
      let alllDataCount = 0;

      // already loaded, so dont need to check
      if (loading) return;
      gantt.eachTask(() => {
        alllDataCount += 1;
      });

      if (alllDataCount > 0) setLoading(true);

      if (alllDataCount === 0) {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          timerRef.current = null;
          setLoading(true);
        }, 4000);
      }
    });
  }, [!gantt]);

  useEffect(() => {
    if (hasProjectUpdateError) {
      addError({ id: 'project-update-error', message: 'Update failed' });

      dispatch(resetProjectUpdate());
    }
  }, [dispatch, hasProjectUpdateError, addError]);

  useEffect(() => {
    if (onGanttContainerMounted) {
      onGanttContainerMounted(ganttRef.current);
    }
  }, []);

  const storeUpdateRequiresLoadingIndicator =
    isFetching || isFetchingProjects || isCreatingProject || isUpdatingProject || isDeletingProject || areEstimatesUpdating;

  return (
    <Container>
      {!loading && <PageLoading />}
      <GanttDiv
        style={{ display: !loading ? 'none' : '' }}
        height={height}
        pageControlsVisible={pageControlsVisible}
        ref={ganttRef}
      />
      {loading && storeUpdateRequiresLoadingIndicator && <LoadingOverlay />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  min-height: 400px;
`;

export const GanttDiv = styled.div`
  width: auto;
  height: ${({ height }) => height || 'auto'};
  border: 1px solid #efefef;
  border-radius: 5px;
  margin: ${({ pageControlsVisible }) => (pageControlsVisible ? '0 34px' : '116px 34px auto')};
  overflow: hidden;
`;

export default GanttWrapper(GanttContainer);
