import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';

import useTimelines from 'hooks/useTimelines/New/useTimelines';
import useSystemFields from 'hooks/useSystemFields';

import getDisplayLayerLabel from 'store/projects/helpers/getDisplayLayerLabel';

import { COMPARE_SCENARIOS_SWIMLANE_PAGE } from 'constants/filters/pages';
import { getDisplayLayer } from 'store/filters/selectors';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { snapToGridOptions } from 'design-system/organisms/Timeline/New';

const DEFAULT_GROUP_TYPE = 'roadmap';

const mapProjectLayerToKey = {
  [IDEA_LAYER]: 'idea',
  [INITIATIVE_LAYER]: 'initiative',
  [BET_LAYER]: 'bet',
};

const TARGET_TIMELINE_OPTION = { key: snapToGridOptions.TARGET_END_DATE, title: 'Target Timeline' };
const ESTIMATED_TIMELINE_OPTION = { key: snapToGridOptions.END_DATE_ESTIMATES, title: 'Estimated Timeline' };
const DISPLAY_TIMELINE_OPTIONS = [TARGET_TIMELINE_OPTION, ESTIMATED_TIMELINE_OPTION];

const getSelectedSnapToGridOn = pathOr(TARGET_TIMELINE_OPTION, ['lsState', 'snapToGridOn']);

const getCustomGroupByOption = (layer, title) => ({ key: mapProjectLayerToKey[layer], field: 'id', title });

const useTimelinesForSwimlaneCompare = () => {
  const [getSystemFieldName] = useSystemFields();
  const displayLayer = useSelector(getDisplayLayer);
  const defaultSwimlaneGroupByOption = useMemo(
    () => getCustomGroupByOption(displayLayer, getDisplayLayerLabel(displayLayer, getSystemFieldName)),
    [displayLayer],
  );
  const mapSwimlaneGroupByOptions = useCallback(() => [defaultSwimlaneGroupByOption], [defaultSwimlaneGroupByOption]);

  // Get the timeline configuration for the swimlane
  const timelineConfig = useTimelines(COMPARE_SCENARIOS_SWIMLANE_PAGE, {
    defaultGroupType: DEFAULT_GROUP_TYPE,
    defaultSwimlaneGroupByOption,
    mapSwimlaneGroupByOptions,
  });

  const groupByOptionsList = useMemo(
    () => [timelineConfig.groupByOptions, timelineConfig.swimlaneGroupByOptions],
    [timelineConfig.groupByOptions, timelineConfig.swimlaneGroupByOptions],
  );

  return {
    ...timelineConfig,
    groupByOptionsList,
    snapToGridOnOptions: DISPLAY_TIMELINE_OPTIONS,
    selectedSnapToGridOn: getSelectedSnapToGridOn(timelineConfig),
  };
};

export default useTimelinesForSwimlaneCompare;
