import moment from 'moment';
import extend from 'lodash/extend';

extend(moment.prototype, {
  addDuration: function addDuration(value, unit) {
    if (['day', 'days', 'week', 'weeks', 'month', 'months'].includes(unit) && value > 0) {
      return moment(this).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(value, unit).subtract(1, 'day');
    }

    return moment(this).add(value, unit);
  },
  subtractDuration: function subtractDuration(value, unit) {
    if (['day', 'days', 'week', 'weeks', 'month', 'months'].includes(unit) && value > 0) {
      return moment(this).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).subtract(value, unit).add(1, 'day');
    }
    return moment(this).subtract(value, unit);
  },
  diffDuration: function addDuration(date, unit, ignoreOneDayCorrection = false) {
    const date1 = moment(this).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const date2 = moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    if (ignoreOneDayCorrection) return date1.diff(date2, unit);

    if (['day', 'days', 'week', 'weeks', 'month', 'months'].includes(unit)) {
      return date1.add(1, 'day').diff(date2, unit);
    }
    return this.diff(date, unit);
  },
  parseFromGantt: function parseDate(isEndDate) {
    const date = moment.utc(moment.tz(this._i, typeof this._i === 'string' ? 'UTC' : moment.tz.guess()).format('L LT'));

    if (isEndDate) return date.clone().subtract('1', 'day');
    return date;
  },
  formatAsDate: function formatAsDate() {
    return this.utc().format('YYYY/MM/DD');
  },
});
