import getSystemFieldName from 'utils/getSystemFieldName';

export const rowDataOptions = (systemFields, hasKeyResults, hasProducts, hasHierarchy, hasBet) => [
  { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
  ...(hasProducts ? [{ key: 'product1', dataKey: 'product_1', title: getSystemFieldName('product1', systemFields) }] : []),
  { key: 'timeframe', title: getSystemFieldName('timeframe', systemFields) },
  { key: 'objective', title: getSystemFieldName('objective', systemFields) },
  ...(hasKeyResults
    ? [{ key: 'keyResult1', dataKey: 'key_result_1', title: getSystemFieldName('keyResult1', systemFields) }]
    : []),
  { key: 'customers', title: getSystemFieldName('customer', systemFields) },
  { key: 'tags', title: getSystemFieldName('tag', systemFields) },
  { key: 'theme', title: getSystemFieldName('theme', systemFields) },
  { key: 'category', title: getSystemFieldName('category', systemFields) },
  { key: 'phase', title: getSystemFieldName('phase', systemFields) },
  { key: 'priority', title: getSystemFieldName('priority', systemFields) },
  ...(hasHierarchy ? [{ key: 'initiative', title: getSystemFieldName('initiative', systemFields) }] : []),
  ...(hasBet ? [{ key: 'bet', title: getSystemFieldName('bet', systemFields) }] : []),
  { key: 'owner', title: 'Owner' },
  { key: 'planningStage', title: 'Planning Stage' },
  { key: 'dates', title: 'Dynamic dates' },
];

export const columnDataOptions = (systemFields, hasKeyResults, hasProducts, hasHierarchy, hasBet) => [
  { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
  ...(hasProducts ? [{ key: 'product1', dataKey: 'product_1', title: getSystemFieldName('product1', systemFields) }] : []),
  { key: 'timeframe', title: getSystemFieldName('timeframe', systemFields) },
  { key: 'objective', title: getSystemFieldName('objective', systemFields) },
  ...(hasKeyResults
    ? [{ key: 'keyResult1', dataKey: 'key_result_1', title: getSystemFieldName('keyResult1', systemFields) }]
    : []),
  { key: 'customers', title: getSystemFieldName('customer', systemFields) },
  { key: 'tags', title: getSystemFieldName('tag', systemFields) },
  { key: 'theme', title: getSystemFieldName('theme', systemFields) },
  { key: 'category', title: getSystemFieldName('category', systemFields) },
  { key: 'phase', title: getSystemFieldName('phase', systemFields) },
  { key: 'priority', title: getSystemFieldName('priority', systemFields) },
  ...(hasHierarchy ? [{ key: 'initiative', title: getSystemFieldName('initiative', systemFields) }] : []),
  ...(hasBet ? [{ key: 'bet', title: getSystemFieldName('bet', systemFields) }] : []),
  { key: 'owner', title: 'Owner' },
  { key: 'planningStage', title: 'Planning Stage' },
  { key: 'dates', title: 'Dynamic dates' },
];

export const groupByOptions = (
  systemFields,
  hasKeyResults,
  hasProducts,
  hasHierarchy,
  hasBet,
  hasMultiLevelPortfolioMetadata,
) => {
  const timeframe = { key: 'timeframe', title: getSystemFieldName('timeframe', systemFields) };

  return [
    { key: 'roadmapCorp', title: getSystemFieldName('roadmapCorp', systemFields) },
    { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
    ...(hasProducts ? [{ key: 'product1', dataKey: 'product_1', title: getSystemFieldName('product1', systemFields) }] : []),
    { key: 'timeframeCorp', title: getSystemFieldName('timeframeCorp', systemFields) },
    ...(hasMultiLevelPortfolioMetadata
      ? [
          timeframe,
          {
            key: 'timeframe2',
            field: 'timeframe_2_id',
            title: getSystemFieldName('timeframe2', systemFields),
          },
        ]
      : [timeframe]),
    { key: 'objectiveCorp', title: getSystemFieldName('objectiveCorp', systemFields) },
    { key: 'objective', title: getSystemFieldName('objective', systemFields) },
    ...(hasKeyResults
      ? [{ key: 'keyResult1', dataKey: 'key_result_1', title: getSystemFieldName('keyResult1', systemFields) }]
      : []),
    // { key: 'customers', title: getSystemFieldName('customer', systemFields) },
    { key: 'theme', title: getSystemFieldName('theme', systemFields) },
    // { key: 'categoryCorp', title: getSystemFieldName('categoryCorp', systemFields) },
    { key: 'category', title: getSystemFieldName('category', systemFields) },
    { key: 'tags', title: getSystemFieldName('tag', systemFields) },
    ...(hasHierarchy ? [{ key: 'initiative', title: getSystemFieldName('initiative', systemFields) }] : []),
    ...(hasBet ? [{ key: 'bet', title: getSystemFieldName('bet', systemFields) }] : []),
    { key: 'phase', title: getSystemFieldName('phase', systemFields) },
    { key: 'priority', title: getSystemFieldName('priority', systemFields) },
    { key: 'owner', title: 'Owner' },
    { key: 'status_color', title: 'Health' },
    { key: 'planningStage', title: 'Planning Stage' },
    { key: 'dates', title: 'Dynamic dates' },
    // { key: null, title: 'none' },
  ];
};

export const dateFieldToGroupOptions = [
  { key: 'estimatedStartDate', title: 'Target start date' },
  { key: 'deadline', title: 'Target end date' },
  { key: 'predictedEndDate', title: 'Predicted end date' },
];

export const groupDateByOptions = [
  { key: 'quarter', title: 'Year-Quarter' },
  { key: 'month', title: 'Year-Month' },
];
