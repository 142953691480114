import { IDEA_LAYER } from 'store/projects/constants';

export default (parent, layer, getSystemFieldName) => ({
  id: `${parent ? parent.id : ''}sep`,
  shortText: `Without ${getSystemFieldName(+layer === +IDEA_LAYER ? 'initiative' : 'bet', false)}`,
  text: `Without ${getSystemFieldName(+layer === +IDEA_LAYER ? 'initiative' : 'bet', false)}`,
  parent: parent ? parent.id : null,
  start_date: null,
  end_date: null,
  type: 'milestone',
});
