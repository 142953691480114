import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';

import LoadableAutocomplete from 'design-system/molecules/LoadableAutocomplete/index';

import { AUTOCOMPLETE_FROM_SERVER_DELAY } from 'constants';
import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';
import useLoadProjectCustomerRequestsOnAutocomplete from 'hooks/useLoadProjectCustomerRequestsOnAutocomplete';
import { spacing } from 'design-system/theme';
import { getUserName } from 'utils';

const SearchDuplicateRequestCellEditor = ({ onSave, ...props }) => {
  const [_loadAutocompleteCustomerRequests] = useLoadProjectCustomerRequestsOnAutocomplete({}, true);

  const _onSuggestionSelected = val => {
    onSave(val);
  };

  const loadData = async value => {
    const { customerRequestId, data: requests } = props.context;
    const data = await _loadAutocompleteCustomerRequests(value);

    const filterCurrentRequest = r => r.entity.id !== customerRequestId;
    const filterDuplicates = r => !requests.some(req => req.id === r.entity.id);

    return data.filter(r => filterCurrentRequest(r) && filterDuplicates(r));
  };

  return (
    <FormControl fullWidth>
      <LoadableAutocomplete
        style={{ width: '100%' }}
        name="search-duplicate-request"
        placeholder={TYPE_TO_SEARCH_PLACEHOLDER}
        loadData={loadData}
        onSuggestionSelected={_onSuggestionSelected}
        loadDelay={AUTOCOMPLETE_FROM_SERVER_DELAY}
        suffixSuggestion={s => s.entity?.owner && <Suffix>({getUserName(s.entity?.owner)})</Suffix>}
        {...props}
      />
    </FormControl>
  );
};

const Suffix = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSizeExtraSmallRem}rem;
  margin-left: ${spacing(0.5)}px;
  color: ${({ theme }) => theme.palette.text.disabled};
`;

export default SearchDuplicateRequestCellEditor;
