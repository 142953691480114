import React from 'react';
import styled from 'styled-components';
import withTheme from '@material-ui/core/styles/withTheme';

const NewDashboardComponent = ({ theme }) => {
  return <Wrapper />;
};

export default withTheme()(NewDashboardComponent);

const Wrapper = styled.div`
  padding: 20px 20px 0 32px;
`;
