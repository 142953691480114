// External dependencies
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import flatten from 'lodash/flatten';
import { isNil, not, pipe, defaultTo, prop, map, filter, propEq, concat, pick, find } from 'ramda';

// Dragonboat depencies
// DoD v2
import getMetadataTitle, { getOrgMetadataForCorpLevelTitles, OBJECTIVE_CORP } from '../utils/getMetadataCorpTitleBasedOnRoadmap';

import { filterActiveItens, getUserName, makeFilterByLevel } from 'utils';
import { OBJECT_OBJECTIVE, OBJECT_OBJECTIVE_CORP_STRING, OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2 } from 'store/objectives/types';
import { getState as getUsers } from 'store/users/selectors';
import sortByRank from 'utils/sortByRank';

import { KEY_RESULT_LEVEL, METADATA_LEVELS } from 'constants/common';

import getMetricValueOrNull from './helpers/getMetricValueOrNull';
import getMetricsForEnrichedEntity from 'store/utils/getMetricsForEnrichedEntity';
import { KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL } from 'constants/objectives';
import { filterChildrenObjectives } from 'utils/okrs';

const ACTUAL_VALUE = 'actual_value';
const ACTUAL_DATE = 'actual_date';
const TARGET_VALUE = 'target_value';
const TARGET_DATE = 'target_date';
const BASELINE_VALUE = 'baseline_value';
const BASELINE_DATE = 'baseline_date';
const NAME = 'name';

const defaultToEmptyObject = defaultTo({});
const defaultToEmptyArray = defaultTo([]);
const isNotNil = pipe(isNil, not);

const safeMap = mapFunc => pipe(defaultToEmptyArray, map(mapFunc));

const getRoadmaps = attribute => pipe(defaultToEmptyObject, prop(attribute), safeMap(prop('roadmap')));
const filterL1Roadmaps = filter(pipe(defaultToEmptyObject, propEq('level', METADATA_LEVELS.LEVEL_1)));
const filterCorpRoadmaps = filter(pipe(defaultToEmptyObject, propEq('level', METADATA_LEVELS.LEVEL_CORP)));

const getProducts1 = attribute => pipe(defaultToEmptyObject, prop(attribute), safeMap(prop('product1')));
const getRoadmapsL1 = attribute => pipe(getRoadmaps(attribute), filterL1Roadmaps);
const getRoadmapsCorp = attribute => obj => {
  const directAssociationCorpRoadmaps = pipe(getRoadmaps(attribute), filterCorpRoadmaps)(obj);
  const rolledUpCorpRoadmaps = safeMap(l1 => ({ id: prop('parent_id', l1) }))(getRoadmapsL1(attribute)(obj));

  return concat(directAssociationCorpRoadmaps, rolledUpCorpRoadmaps);
};

const formatProgress = progress => `${((progress || 0) * 100).toFixed(0)}%`;

const getOKRUser = (userId, users) => {
  if (!userId) return;

  const user = find(propEq('id', userId))(users);

  if (user) return pick(['id', 'first_name', 'last_name'], user);

  return null;
};

export function getState(state) {
  return state.objectives;
}

const _getMetricsForEnrichedOkr = (obj, metrics) => {
  const result = {
    metric_name: getMetricValueOrNull(obj, metrics, NAME),
    metric_current: getMetricValueOrNull(obj, metrics, ACTUAL_VALUE),
    metric_current_date: getMetricValueOrNull(obj, metrics, ACTUAL_DATE),
    metric_target: getMetricValueOrNull(obj, metrics, TARGET_VALUE),
    metric_target_date: getMetricValueOrNull(obj, metrics, TARGET_DATE),
    metric_baseline: getMetricValueOrNull(obj, metrics, BASELINE_VALUE),
    metric_baseline_date: getMetricValueOrNull(obj, metrics, BASELINE_DATE),
  };

  return result;
};

export const _getEnrichedObjective = (obj, metrics = [], users = [], orgMetadataForCorpLevelTitle = {}) => {
  const objectiveOwner = getOKRUser(obj?.owner_id, users);
  const objectiveUpdatedBy = getOKRUser(obj?.updated_by_id, users);

  const roadmapKey = [KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL].includes(obj?.level) ? 'key_result_roadmaps' : 'objective_roadmaps';

  let result = {
    ...obj,
    title: getMetadataTitle(obj, OBJECTIVE_CORP, orgMetadataForCorpLevelTitle),
    owner: objectiveOwner,
    ownerName: getUserName(objectiveOwner),
    updatedBy: objectiveUpdatedBy,
    roadmaps: getRoadmapsL1(roadmapKey)(obj),
    products1: getProducts1(roadmapKey)(obj),
    displayProgress: formatProgress(obj?.progress),
  };

  if (!result.progress_type) {
    result = {
      ...result,
      progress_type: 'manual',
    };
  }

  const enrichedMetrics = _getMetricsForEnrichedOkr(obj, metrics) || {};
  const enrichedMetricsArray = getMetricsForEnrichedEntity(metrics, obj) || {};

  result = {
    ...result,
    ...enrichedMetrics,
    metrics: enrichedMetricsArray,
  };

  return result;
};

export const _getEnrichedKeyResult = (keyResult, normalizedObjectives, metrics = [], users = []) => {
  const objectiveId = prop('objective_id', keyResult);
  const getParentId = pipe(defaultToEmptyObject, prop('parent_id'));
  const keyResultOwner = getOKRUser(keyResult?.owner_id, users);
  const keyResultUpdatedBy = getOKRUser(keyResult?.updated_by_id, users);

  let result = {
    ...keyResult,
    ownerName: getUserName(keyResultOwner) || getUserName(keyResult?.owner),
    owner: keyResultOwner,
    updatedBy: keyResultUpdatedBy,
    roadmapsCorp: getRoadmapsCorp('key_result_roadmaps')(keyResult),
    roadmaps: getRoadmapsL1('key_result_roadmaps')(keyResult),
    products1: getProducts1('key_result_roadmaps')(keyResult),
    objective_corp_id: getParentId(prop(objectiveId, normalizedObjectives)),
    displayProgress: formatProgress(keyResult?.progress),
  };

  const enrichedMetrics = _getMetricsForEnrichedOkr(keyResult, metrics) || {};
  const enrichedMetricsArray = getMetricsForEnrichedEntity(metrics, keyResult) || {};

  result = {
    ...result,
    ...enrichedMetrics,
    metrics: enrichedMetricsArray,
  };

  return result;
};

const emptyArray = [];
const emptyObject = {};

export const getObjectives = createCachedSelector(
  state => getState(state)?.objectives || emptyArray,
  state => state?.metrics?.metrics || emptyArray,
  state => getUsers(state) || emptyArray,
  getOrgMetadataForCorpLevelTitles,
  (_, showArchived) => showArchived,
  (_, __, level) => (isNotNil(level) ? level : METADATA_LEVELS.LEVEL_1),
  (objectives, metrics, users, orgMetadataForCorpLevelTitle, showArchived, level) => {
    return (
      showArchived
        ? objectives.filter(makeFilterByLevel(level))
        : objectives.filter(makeFilterByLevel(level)).filter(filterActiveItens)
    )
      .sort(sortByRank)
      .map(objective => _getEnrichedObjective(objective, metrics, users, orgMetadataForCorpLevelTitle));
  },
)((_, showArchived, level = METADATA_LEVELS.LEVEL_1) => `objectives-${String(showArchived)}-${level}`);

export const getAllObjectives = createSelector(
  state => getState(state)?.objectives || [],
  state => state?.metrics?.metrics || [],
  state => getUsers(state) || emptyArray,
  getOrgMetadataForCorpLevelTitles,
  (objectives, metrics, users, orgMetadataForCorpLevelTitle) => {
    return objectives
      .sort(sortByRank)
      .map(objective => _getEnrichedObjective(objective, metrics, users, orgMetadataForCorpLevelTitle));
  },
);

export const getAllKeyResults = createSelector(
  state => getState(state)?.keyResults || emptyArray,
  state => state?.metrics?.metrics || [],
  state => getUsers(state) || emptyArray,
  state => getNormalizedObjectives(state),
  (keyResults, metrics, users, normalizedObjectives) =>
    flatten(Object.values(keyResults)).map(keyResult => _getEnrichedKeyResult(keyResult, normalizedObjectives, metrics, users)),
);

const getKeyResultsState = state => getState(state)?.keyResults || emptyObject;

const getShowArchivedArgument = (state, showArchived = true) => !!showArchived;

export const selectKeyResults1 = createCachedSelector(
  getKeyResultsState,
  state => getNormalizedObjectives(state),
  getShowArchivedArgument,
  state => state?.metrics?.metrics || emptyArray,
  state => getUsers(state) || emptyArray,
  (keyResultsState, normalizedObjectives, showArchived, metrics, users) => {
    const result =
      keyResultsState?.[KEY_RESULT_LEVEL.keyResult]?.map(eachKeyResult =>
        _getEnrichedKeyResult(eachKeyResult, normalizedObjectives, metrics, users),
      ) || [];

    return showArchived ? result : result.filter(filterActiveItens);
  },
)((state, showArchived = true) => {
  return `krs:${getShowArchivedArgument(state, showArchived)}:${KEY_RESULT_LEVEL.keyResult}`;
});

export const selectKeyResults2 = createCachedSelector(
  getKeyResultsState,
  state => getNormalizedObjectives(state),
  getShowArchivedArgument,
  state => state?.metrics?.metrics || emptyArray,
  state => getUsers(state) || emptyArray,
  (keyResultsState, normalizedObjectives, showArchived, metrics, users) => {
    const result =
      keyResultsState?.[KEY_RESULT_LEVEL.keyResult2]?.map(eachKeyResult =>
        _getEnrichedKeyResult(eachKeyResult, normalizedObjectives, metrics, users),
      ) || [];

    return showArchived ? result : result.filter(filterActiveItens);
  },
)((state, showArchived = true) => {
  return `krs:${getShowArchivedArgument(state, showArchived)}:${KEY_RESULT_LEVEL.keyResult2}`;
});

export const getNormalizedObjectives = createSelector(
  state => getObjectives(state, true),
  state => state?.metrics?.metrics || emptyArray,
  state =>
    state.reduce((objs, obj) => {
      objs[obj.id] = obj;
      return objs;
    }, {}),
);

export const getNormalizedObjectivesLevelCorp = createSelector(
  state => getObjectives(state, true, METADATA_LEVELS.LEVEL_CORP),
  state => state?.metrics?.metrics || [],
  state =>
    state.reduce((objs, obj) => {
      objs[obj.id] = obj;
      return objs;
    }, {}),
);

export const getNormalizedKeyResults = createSelector(
  state => selectKeyResults1(state, true),
  state => state?.metrics?.metrics || [],
  state => getNormalizedObjectives(state),
  state => getUsers(state) || emptyArray,
  (state, metrics, normalizedObjectives, users) =>
    state.reduce((objs, obj) => {
      objs[obj.id] = _getEnrichedKeyResult(obj, normalizedObjectives, metrics, users);
      return objs;
    }, {}),
);

export const getNormalizedKeyResultsLevel2 = createSelector(
  state => selectKeyResults2(state, true),
  state => state?.metrics?.metrics || [],
  state => getNormalizedObjectives(state),
  state => getUsers(state) || emptyArray,
  (state, metrics, normalizedObjectives, users) =>
    state.reduce((objs, obj) => {
      objs[obj.id] = _getEnrichedKeyResult(obj, normalizedObjectives, metrics, users);
      return objs;
    }, {}),
);

export const getSelected = createSelector(
  getState,
  state => state?.metrics?.metrics || [],
  state => getUsers(state) || emptyArray,
  state => getAllKeyResults(state),
  getOrgMetadataForCorpLevelTitles,
  state => getNormalizedObjectives(state),
  (state, metrics, users, keyResults, orgMetadataForCorpLevelTitle, normalizedObjectives) => {
    const { selectedType = OBJECT_OBJECTIVE, selectedId, objectives } = state;
    const selected =
      selectedType.toString() === OBJECT_OBJECTIVE || selectedType.toString() === OBJECT_OBJECTIVE_CORP_STRING
        ? objectives.find(o => o.id === selectedId)
        : { ...(keyResults.find(o => o.id === selectedId) || {}) };

    const parent =
      selectedType === OBJECT_OBJECTIVE || !selected
        ? objectives.find(o => o.id === selected?.parent_id)
        : objectives.find(o => o.id === selected.objective_id);

    const grandParent =
      parent?.level === OBJECT_OBJECTIVE && !parent?.parent_id ? null : objectives.find(o => o.id === parent?.parent_id);

    if (selectedType === OBJECT_KEY_RESULT) {
      selected.keyResults = keyResults.filter(kr => kr.parent_id === selected.id);
    }

    const enrichOKR = okr =>
      [OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2].includes(okr?.level)
        ? _getEnrichedKeyResult(okr, normalizedObjectives, metrics, users)
        : _getEnrichedObjective(okr, metrics, users, orgMetadataForCorpLevelTitle);

    return {
      selectedType,
      selected: enrichOKR(selected),
      parent: parent ? enrichOKR(parent) : null,
      grandParent: grandParent ? enrichOKR(grandParent) : null,
    };
  },
);

export const isDrawerOpen = createSelector(getState, state => state.drawerOpen);

export const getObjectivesCorpWithoutChildrenAccounts = createSelector(
  (state, showArchived) => getObjectives(state, showArchived, METADATA_LEVELS.LEVEL_CORP),
  (_, __, childrenDragonsCorpObjectives) => childrenDragonsCorpObjectives,
  (objectivesCorp, childrenDragonsCorpObjectives) => filterChildrenObjectives(objectivesCorp, childrenDragonsCorpObjectives),
);
