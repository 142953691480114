import React from 'react';
import styled from 'styled-components';
import RemoveIcon from '@material-ui/icons/Close';

import TextField from 'design-system/atoms/TextField/index';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';


const AddOptionRow = ({ value, onRemoveOption, onChange, onKeyPress, focused, disabled, ...props }) => {
  return (
    <Wrapper {...props}>
      <Field
        value={value}
        disabled={disabled}
        inputRef={input => {
          if (input != null && focused) {
            input.focus();
          }
        }}
        onKeyPress={onKeyPress}
        onChange={onChange}
      />
      <Remove disabled={disabled} hidden={!value} onClick={onRemoveOption} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &&&& {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`;

const Field = styled(TextField)`
  &&&& {
    flex-grow: 1;

    label {
      display: none;
    }

    label + div {
      margin-top: 0;
    }
  }
`;

const Remove = styled(props => (
  <ButtonIcon {...props}>
    <RemoveIcon />
  </ButtonIcon>
))`
  &&&& {
    margin-right: 5px;
    ${props => props.hidden && `opacity: 0;`}

    svg {
      font-size: 18px;
    }
  }
`;

export default AddOptionRow;
