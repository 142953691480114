import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_MAPPING_KEY } from 'constants/integrations';
import {
  getOrganizationSystemFieldsNames as getOrganizationSystemFieldsNamesSelector,
  getIntegrationProjectsMapping as getIntegrationProjectsMappingSelector,
} from 'store/organization/selectors';

import useIntegrationLayerNames from '../../IntegrationLayerNames/useIntegrationLayerNames';

export default Component => {
  return props => {
    const { integrationType, orgIntegrationId, userCanUpdateOrgIntegration, layer } = props;

    const [isFieldMappingDialogOpen, setIsFieldMappingDialogOpen] = useState(false);

    const systemFields = useSelector(state => getOrganizationSystemFieldsNamesSelector(state));
    const selectedMapping = useSelector(getIntegrationProjectsMappingSelector(orgIntegrationId, DEFAULT_MAPPING_KEY, layer));
    const { namesByLayer: integrationLayerNames } = useIntegrationLayerNames(orgIntegrationId);

    const onOpenFieldMappingDialog = useCallback(() => setIsFieldMappingDialogOpen(true), []);
    const onCloseFieldMappingDialog = useCallback(() => setIsFieldMappingDialogOpen(false), []);

    return (
      <Component
        integrationType={integrationType}
        orgIntegrationId={orgIntegrationId}
        mapping={selectedMapping}
        layer={layer}
        systemFields={systemFields}
        isFieldMappingDialogOpen={isFieldMappingDialogOpen}
        onOpenFieldMappingDialog={onOpenFieldMappingDialog}
        onCloseFieldMappingDialog={onCloseFieldMappingDialog}
        userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
        integrationLayerNames={integrationLayerNames}
      />
    );
  };
};
