/* eslint-disable */
import React from 'react';
import styled, { withTheme } from 'styled-components';

export default withTheme(({ fill, height = 24, width = 24, ...props }) => {
  return (
    <SVG
      width={width}
      height={height}
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={9} cy={7} r={4}></circle>
      <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" style={{ fill: 'none' }}></path>
      <path d="M16 11h6m-3 -3v6"></path>
    </SVG>
  );
});

const SVG = styled.svg`
  path {
    /* fill: none !important; */
  }

  circle,
  path {
    color: ${props => props.color};
  }
`;
