import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_ANNOUNCEMENTS'),
  ...getThunkActionTypes('FETCH_UNREAD_ANNOUNCEMENTS'),
  ...getThunkActionTypes('ARCHIVE_ANNOUNCEMENT'),
  ...getThunkActionTypes('CREATE_ANNOUNCEMENT'),
  ...getThunkActionTypes('UPDATE_ANNOUNCEMENT'),
  ...getThunkActionTypes('DELETE_ANNOUNCEMENT'),
};

export const {
  ARCHIVE_ANNOUNCEMENT,
  ARCHIVE_ANNOUNCEMENT_FULFILLED,
  ARCHIVE_ANNOUNCEMENT_PENDING,
  ARCHIVE_ANNOUNCEMENT_REJECTED,
  CREATE_ANNOUNCEMENT,
  CREATE_ANNOUNCEMENT_FULFILLED,
  CREATE_ANNOUNCEMENT_PENDING,
  CREATE_ANNOUNCEMENT_REJECTED,
  DELETE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT_FULFILLED,
  DELETE_ANNOUNCEMENT_PENDING,
  DELETE_ANNOUNCEMENT_REJECTED,
  FETCH_ANNOUNCEMENTS,
  FETCH_ANNOUNCEMENTS_FULFILLED,
  FETCH_ANNOUNCEMENTS_PENDING,
  FETCH_ANNOUNCEMENTS_REJECTED,
  FETCH_UNREAD_ANNOUNCEMENTS,
  FETCH_UNREAD_ANNOUNCEMENTS_FULFILLED,
  FETCH_UNREAD_ANNOUNCEMENTS_PENDING,
  FETCH_UNREAD_ANNOUNCEMENTS_REJECTED,
  UPDATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT_FULFILLED,
  UPDATE_ANNOUNCEMENT_PENDING,
  UPDATE_ANNOUNCEMENT_REJECTED,
} = actionTypes;
