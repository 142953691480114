import React, { useMemo } from 'react';
import styled from 'styled-components';

import ObjectiveType from 'design-system/atoms/ObjectiveType';
import { TitleCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';

const StyledOkrIcon = styled(ObjectiveType)`
  && {
    width: 0.875rem;
    height: 0.875rem;
  }
`;

const OkrTitleRenderer = params => {
  const { okrType } = params.data;

  const icon = useMemo(() => {
    return <StyledOkrIcon type={okrType} active />;
  }, [okrType]);

  return <TitleCellRenderer {...params} icon={icon} displayChildrenCount bold />;
};

export default OkrTitleRenderer;
