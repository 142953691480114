import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { defaultTo, equals, isEmpty, pipe, pluck, prop } from 'ramda';

import useSystemFields from 'hooks/useSystemFields';

import { UserFilterDropdownWithDndList } from './styled';

const LEVEL_ONE = 0;
const LEVEL_TWO = 1;

const defaultToEmptyArray = defaultTo([]);
const filterByLevel = targetLevel => pipe(prop('level'), equals(targetLevel));
const getTeamsTitles = pluck('title');

const TeamsFilterDropdown = props => {
  const { className, teams, filters, onChange } = props;
  const { teams: selectedTeamsLevelOne = [], teams2: selectedTeamsLevelTwo = [] } = filters;

  const [getSystemFieldName] = useSystemFields();

  const onUpdateTeamHandler = useCallback(
    values => {
      const selectedItems = defaultToEmptyArray(values);

      onChange({
        teams: getTeamsTitles(selectedItems.filter(filterByLevel(LEVEL_ONE))),
        teams2: getTeamsTitles(selectedItems.filter(filterByLevel(LEVEL_TWO))),
      });
    },
    [onChange],
  );

  const selectedValueInString = useMemo(
    () => [...selectedTeamsLevelOne, ...selectedTeamsLevelTwo].join(', '),
    [selectedTeamsLevelOne, selectedTeamsLevelTwo],
  );

  const selectedItems = useMemo(() => {
    const filterSelectedItemsOnBothLevels = team =>
      selectedTeamsLevelOne.includes(team.title) || selectedTeamsLevelTwo.includes(team.title);

    return teams.filter(filterSelectedItemsOnBothLevels);
  }, [teams, selectedTeamsLevelOne, selectedTeamsLevelTwo]);

  const areBothLevelsEmpty = isEmpty(selectedTeamsLevelOne) && isEmpty(selectedTeamsLevelTwo);
  const inputLabel = areBothLevelsEmpty ? `All ${getSystemFieldName('team', true)}` : '';

  return (
    <UserFilterDropdownWithDndList
      items={teams}
      selectedItems={selectedItems}
      onChange={onUpdateTeamHandler}
      label={inputLabel}
      textFieldValue={selectedValueInString}
      className={className}
      hasShowArchived
    />
  );
};

TeamsFilterDropdown.propTypes = {
  className: PropTypes.string,
  teams: PropTypes.array,
  filters: PropTypes.shape({
    teams: PropTypes.array,
    teams2: PropTypes.array,
  }),
  onChange: PropTypes.func,
};

export default TeamsFilterDropdown;
