import React, { useMemo } from 'react';

import { BaseGrid } from 'containers/Grids';
import useOkrsRowDrag from 'containers/Grids/OkrsGrid/hooks/useOkrsRowDrag';

import useCommonGridProps from 'design-system/molecules/AgGridReact-New/hooks/useCommonGridProps';
import useCommonGridEventHandlers from 'design-system/molecules/AgGridReact-New/hooks/useCommonGridEventHandlers';

import useOkrTableGridColumns from './hooks/useOkrTableGridColumns';
import useSnapshotOkrTableGrid from './hooks/useSnapshotOkrTableGrid';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useProjectsListLightboxContext from 'hooks/useProjectsListLightbox';
// Todo: We should move this hook to the generic folder. Did not want to increase scope on this task
import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';

import { checkRowDrag, getDataPath, getRowId, makeCheckAddVisibility } from './helpers';

import { ADD_CELL_CLASS, stylesOverride } from './styled';

import { bulkDeleteObjectiveRoadmaps, createObjectiveRoadmap, deleteObjectiveRoadmap } from 'store/objectives';

import { OBJECTIVE_KEY } from 'constants/objectives';
import { METADATA_LEVELS } from 'constants/common';
import { BASE_ROW_HEIGHT } from 'constants/grid';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { getObjectiveKeyByLevel } from 'utils/objectives';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const KEY_RESULT_KEY = 'key_result';

const makeRowClassRules = (isGoalMode = false) => ({
  'objective-corp-row': params => {
    return isGoalMode ? params?.data?.level === METADATA_LEVELS.LEVEL_CORP : false;
  },
});

const OkrTable = ({
  items,
  hasKeyResults,
  hasKeyResults2,

  onGridReady,
  isTreeView,
  selectedField,
  tableHeight,
  rowHeight,
  pageSize,
  visibleColumns,
  searchString,

  metadataTitle,
  displayLayerLabel,

  users,

  metrics,
  hasAdvancedMetricReporting,
  openMetricView,
  createMetricOption,

  updateById,
  isGoalMode = false,
  withPagination = true,
  isGroupOpenByDefault,
}) => {
  const { isParentDragon } = useOrganizationsAccessControl();

  const { canView, canCreate } = usePermissions();
  const hasCorpLevel = canView(PERMISSION_RESOURCES.objectiveCorp);
  const canOpenProjectsListLightbox = canView(PERMISSION_FEATURES.goalProjectsLightbox);

  const { openProjectsListLightbox: openProjectsListLightboxAction } = useProjectsListLightboxContext();

  const openProjectsListLightbox = okr => openProjectsListLightboxAction(okr, getObjectiveKeyByLevel(okr));

  const {
    processedItems,
    allowedLevel,
    create,
    removeUnsaved,
    openObjectiveDrawer,

    handleGridReady,
    fetchObjectives,
    switchObjectivesRowOrder,
    switchKeyResultsRowOrder,
    moveKeyResultToObjective,
    handleAddOKRWithoutSave,
    moveObjectiveToObjective,
  } = useSnapshotOkrTableGrid(items, {
    hasKeyResults2,
    isTreeView,
    hasCorpLevel,
    onGridReady,
  });

  const handleRowDrag = useOkrsRowDrag({
    isTreeView,
    fetchObjectives,
    switchObjectivesRowOrder,
    switchKeyResultsRowOrder,
    moveKeyResultToObjective,
    moveObjectiveToObjective,
  });

  const disableDrag = useMemo(() => {
    return processedItems.some(item => item.id == null && item.title == null);
  }, [processedItems]);

  const checkAddVisibility = useMemo(
    () => makeCheckAddVisibility({ allowedLevel, isTreeView, hasKeyResults, isParentDragon, canCreate }),
    [allowedLevel, isTreeView, hasKeyResults, isParentDragon, canCreate],
  );

  const roadmapsMetadata = useRoadmapsMetadata(
    createObjectiveRoadmap,
    deleteObjectiveRoadmap,
    processedItems,
    OBJECTIVE_KEY,
    bulkDeleteObjectiveRoadmaps,
    KEY_RESULT_KEY,
  );

  const { columnDefs, defaultColDef } = useOkrTableGridColumns({
    hasKeyResults,
    hasKeyResults2,

    isTreeView,
    selectedField,
    visibleColumns,
    metadataTitle,
    displayLayerLabel,
    searchString,

    users,

    metrics,
    hasAdvancedMetricReporting,
    openMetricView,
    createMetricOption,

    checkRowDrag,
    disableDrag,
    addVisibility: checkAddVisibility,
    handleAdd: handleAddOKRWithoutSave,
    addCellClass: ADD_CELL_CLASS,
    handleOpenItem: openObjectiveDrawer,
    handleOpenProjectsLightbox: canOpenProjectsListLightbox && openProjectsListLightbox,
    rowHeight,

    roadmapsMetadata,
    hasCorpLevel,
  });

  const commonGridProps = useCommonGridProps({ isTreeView: true });

  const commonGridEvents = useCommonGridEventHandlers({
    items: processedItems,
    idKey: 'uniqueId',
    actions: {
      update: updateById,
      save: create,
      remove: removeUnsaved,
      drag: handleRowDrag,
    },
  });

  const gridContext = useMemo(() => ({ isParentDragon, isTreeView }), [isParentDragon, isTreeView]);
  const rowClassRules = useMemo(() => makeRowClassRules(isGoalMode), [isGoalMode]);

  return (
    <BaseGrid
      {...commonGridProps}
      {...commonGridEvents}
      onGridReady={handleGridReady}
      rowData={processedItems}
      getRowId={getRowId}
      getDataPath={getDataPath}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      groupDisplayType="custom"
      cssStyles={stylesOverride}
      height={tableHeight}
      rowHeight={rowHeight}
      headerHeight={BASE_ROW_HEIGHT}
      groupDefaultExpanded={0}
      pagination={withPagination}
      paginationPageSize={pageSize}
      suppressPaginationPanel={!withPagination}
      context={gridContext}
      rowClassRules={rowClassRules}
      enableColResize
      enableSorting
      enableFilter
      adjustColumnsWidth
      isGroupOpenByDefault={isGroupOpenByDefault}
    />
  );
};

export default OkrTable;
