import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { allPass, both, defaultTo, isNil, not, path, pipe, prop, propEq, equals, or } from 'ramda';
import { toast } from 'react-toastify';

import { updateProjectOnLigthbox, createProject, createProjectFromRequest } from 'store/projects';
import { secureUpdateProjectEstimates } from 'store/estimates';
import { bulkUpdateTasks, createTask, deleteTask } from 'store/tasks';
import { getOrgJiraIntegrations } from 'store/organization/selectors';
import { getDefaultPhaseByPlanningStage } from 'store/phases/selectors';

import { ARCHIVED_PLANNING_STAGE, UPDATE_PROJECT_ERROR } from 'constants/projectLightbox';
import { isEstimateValid } from '../EstimatesAndAssignments/helpers/validations';
import { selectOpenFromEntity } from 'store/projectLightbox/selectors';
import useCrossAccountRoadmapChange from 'hooks/projects/useCrossAccountRoadmapChange';
import { METRIC, REQUEST } from 'constants/common';
import { CORP_OBJECTIVE_KEY, OBJECTIVE_KEY, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY } from 'constants/objectives';
import { createProjectFromMetric } from 'features/MetricsDialog/LinkedPortfolioItems/store';
import { createProjectFromOkr } from 'features/OKR/Drawer/LinkedPortfolioItems/store';
// id property is not a field to update
// newTasks property is ignored on backend
const IGNORED_FIELDS = ['id', 'newTasks'];

const defaultToEmptyArray = defaultTo([]);

const doesntExist = value => Boolean(!value);
const isOfValidationTypeError = propEq('error_code', 'VALIDATION_ERROR');
const hasErrorDescription = pipe(path(['_data', 'description']), isNil, not);
const isValidationErrorWithDescription = both(isOfValidationTypeError, hasErrorDescription);
const shouldShowDefaultErrorMessage = allPass([pipe(prop('message'), isNil), pipe(propEq('errorAlreadyDisplayed', true), not)]);
const isNullOrEmptyString = v => or(isNil(v), v === '');

const makeCreateProjectFromRequest = requestId => project => createProjectFromRequest(project, requestId);
const makeCreateProjectFromMetric = metricId => project => createProjectFromMetric(project, metricId);
const makeCreateProjectFromOkr = (okrId, okrType) => project => createProjectFromOkr(project, okrId, okrType);

const getCreateThunkAction = (openFromEntity = {}) => {
  const { id, type } = openFromEntity;

  switch (type) {
    case REQUEST:
      return makeCreateProjectFromRequest(id);
    case METRIC:
      return makeCreateProjectFromMetric(id);
    case CORP_OBJECTIVE_KEY:
    case OBJECTIVE_KEY:
    case KEY_RESULT_1_KEY:
    case KEY_RESULT_2_KEY:
      return makeCreateProjectFromOkr(id, type);
    default:
      return createProject;
  }
};

export default function useSaveProjectOnLightbox(formData, currentProject, onClose, updateFormData) {
  const dispatch = useDispatch();
  const archivedPhase = useSelector(state => getDefaultPhaseByPlanningStage(state, ARCHIVED_PLANNING_STAGE));
  const jiraIntegrations = useSelector(getOrgJiraIntegrations);
  const openFromEntity = useSelector(selectOpenFromEntity);
  const createProjectThunk = getCreateThunkAction(openFromEntity);
  const { isUpdateAnAccountTransfer, transferProjectToOtherAccountFromProjectUpdate } = useCrossAccountRoadmapChange();

  const jiraIntegration =
    currentProject && !!currentProject.jira && jiraIntegrations.find(i => i.id === currentProject.jira.orgIntegration_id);
  const jiraConfig = jiraIntegration && jiraIntegration.data;

  const webhookEnabled = !!(jiraConfig && jiraConfig.webhook);
  const _onSaveProject = (jiraSync, shouldClose = true, newData = null, sendAll = false, archiveProject = false) => {
    const syncOnJira = !webhookEnabled ? jiraSync : webhookEnabled;
    const dataToSave = newData || formData;

    if (archiveProject && archivedPhase) {
      delete dataToSave.phaseTitle;

      dataToSave.phase = archivedPhase;
      dataToSave.phase_id = archivedPhase.id;
    }

    if (newData) {
      updateFormData(pick(newData, Object.keys(formData)));
    }

    delete dataToSave.start_date;
    delete dataToSave.end_date;
    delete dataToSave.ownerName;

    const _onError = err => {
      if (err.response && err.response.status !== 401) {
        const { data } = err.response;

        if (isValidationErrorWithDescription(data)) {
          toast(data._data.description);
        } else if (shouldShowDefaultErrorMessage(data)) {
          toast(UPDATE_PROJECT_ERROR);
        }
      }
    };

    const _checkIfIsToUpdate = field => {
      if (doesntExist(currentProject) || doesntExist(dataToSave) || doesntExist(dataToSave.id)) return false;

      const previousValue = currentProject[field];
      const newValue = dataToSave[field];

      const newValueIsNaN = Number.isNaN(newValue);
      const bothAreNil = isNil(previousValue) && isNil(newValue);
      const didntChange = equals(previousValue, newValue);

      if (newValueIsNaN || bothAreNil || didntChange) return false;

      return true;
    };

    let resultPromise = null;

    if (dataToSave.id) {
      if (currentProject && !sendAll) {
        Object.entries(currentProject).forEach(([key, currentValue]) => {
          const isNotIDField = key !== 'id';
          let hasSameValueAsCurrentProject = currentValue === dataToSave[key];

          if (dataToSave[key] === null) {
            hasSameValueAsCurrentProject = isNullOrEmptyString(currentValue);
          }

          if (isNotIDField && hasSameValueAsCurrentProject) {
            delete dataToSave[key];
          }
        });
      }

      if (currentProject && dataToSave.estimates && !isEqual(dataToSave.estimates, currentProject.estimates)) {
        const estimates = [...defaultToEmptyArray(dataToSave.estimates)];

        const validEstimates = estimates.reduce((allEstimates, estimate) => {
          if (isEstimateValid(estimate)) {
            allEstimates.push(estimate);
          } else if (estimate.id) {
            const existing = currentProject.estimates.find(e => e.id === estimate.id);

            if (existing) {
              allEstimates.push(existing);
            }
          }

          return allEstimates;
        }, []);

        delete dataToSave.estimates;

        dispatch(
          secureUpdateProjectEstimates(
            dataToSave.id,
            currentProject && currentProject.estimates ? currentProject.estimates : [],
            validEstimates,
          ),
        ).catch(_onError);
      }

      if (currentProject && dataToSave.tasks && !isEqual(dataToSave.tasks, currentProject.tasks)) {
        const tasksToUpdate = dataToSave.tasks.filter(t => !!t.id);
        const tasksToCreate = dataToSave.tasks.filter(t => !t.id);
        const tasksToDelete = (currentProject.tasks || []).filter(
          t => !(dataToSave.tasks || []).some(dataTask => t.id === dataTask.id),
        );

        dispatch(bulkUpdateTasks(tasksToUpdate)).catch(_onError);

        tasksToCreate.forEach(task => dispatch(createTask(task)).catch(_onError));
        tasksToDelete.forEach(task => dispatch(deleteTask(task.id)).catch(_onError));

        delete dataToSave.tasks;
      }

      let isToUpdate = false;

      Object.keys(dataToSave).forEach(key => {
        if (!IGNORED_FIELDS.includes(key)) {
          isToUpdate = !_checkIfIsToUpdate(key) ? isToUpdate : true;
        }
      });

      if (!isToUpdate) {
        resultPromise = Promise.resolve(currentProject);
      } else {
        const isToTransferAccountBasedOnDataChanged = isUpdateAnAccountTransfer(dataToSave);

        if (isToTransferAccountBasedOnDataChanged) {
          transferProjectToOtherAccountFromProjectUpdate(currentProject.id, dataToSave);

          resultPromise = Promise.resolve(currentProject);
        } else {
          resultPromise = dispatch(updateProjectOnLigthbox(dataToSave, syncOnJira, sendAll)).catch(_onError);
        }
      }
    } else {
      resultPromise = dispatch(createProjectThunk(dataToSave)).catch(_onError);
    }

    if (shouldClose) {
      onClose();
    }

    return resultPromise;
  };

  return [_onSaveProject];
}
