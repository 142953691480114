import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { isEmpty, match, pipe, trim } from 'ramda';

import { cleanUrl } from 'utils';

const ERROR_MESSAGE = 'An error occurred. Make sure you have the right permissions and the instance is not already integrated.';

const matchesDragonboatDomainRegex = v => {
  const host = window.location.host.slice(window.location.host.indexOf('.'));

  return match(new RegExp(`(${host})`, 'g'))(v);
};

const doesNotStartWithDragonboatDomain = pipe(matchesDragonboatDomainRegex, isEmpty);

const CreateChildDragonAccess = ({ onClickNext, onClickBack, hasError }) => {
  const [orgUri, setOrgUri] = useState('');

  const _handleURIInput = urlInput => {
    setOrgUri(trim(cleanUrl(urlInput)));
  };

  const _handleOnClick = useCallback(
    e => {
      e.preventDefault();
      if (onClickNext) onClickNext(orgUri);
    },
    [orgUri],
  );

  return (
    <>
      <Description>
        * You need <em>Dragonboat Admin permissions</em> to connect Dragonboat with Dragonboat.
      </Description>
      <Grid container>
        <Grid item xs={6}>
          <URLTextField
            id="fileName"
            label="Insert your Dragonboat organization URL"
            value={orgUri}
            onChange={e => _handleURIInput(e.target.value)}
            margin="normal"
          />
        </Grid>
        {hasError && <ErrorMessageContainer>{ERROR_MESSAGE}</ErrorMessageContainer>}
      </Grid>

      <ActionsWrapper>
        {onClickBack && (
          <BackButton color="primary" onClick={onClickBack}>
            Back
          </BackButton>
        )}
        <NextButton type="submit" color="primary" disabled={doesNotStartWithDragonboatDomain(orgUri)} onClick={_handleOnClick}>
          Next
        </NextButton>
      </ActionsWrapper>
    </>
  );
};

CreateChildDragonAccess.propTypes = {
  onClickNext: PropTypes.func,
};

const URLTextField = styled(TextField)`
  width: 100%;
`;

const NextButton = styled(Button)`
  &&&& {
    float: right;
  }
`;

const BackButton = styled(Button)`
  &&&& {
    float: left;
  }
`;

const Description = styled.p`
  margin-bottom: 16px;
`;

const ActionsWrapper = styled.div`
  &&&& {
    padding: 38px 0;
  }
`;

const ErrorMessageContainer = styled.p`
  color: ${props => props.theme.palette.text.error};
  font-size: ${props => props.theme.typography.fontSizeSmall}px;
  line-height: 1em;
`;

export default CreateChildDragonAccess;
