import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { defaultTo, path, pipe } from 'ramda';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import StepButton from '@material-ui/core/StepButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { toast } from 'react-toastify';
import { trimAndCleanUrl } from 'utils';
import {
  testJira,
  disconnectJira,
  updateJiraConfigMisc,
  createJiraConfigDetails,
  updateJiraConfigDetails,
} from 'store/integrations';

import ConfigDetails from '../ConfigDetails';
import ConfigStepOne from './components/ConfigStepOne';
import ConfigStepTwo from './components/ConfigStepTwo';

const getJiraTestedFlag = pipe(path(['data', 'jira_tested']), defaultTo(false));

export class JiraConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
      completed: {},
      baseUrl: props.jiraConfig ? props.jiraConfig.base_url : '',
      isNew: props.jiraConfig && props.jiraConfig.isNew,
      waitForAPI: false,
    };
    this.setJiraConfiguration = this.setJiraConfiguration.bind(this);
    this.makeJiraConnection = this.makeJiraConnection.bind(this);
  }

  getSteps() {
    return [
      <p>Enter your Jira URL</p>,
      <p>
        Create Application Link and <b>enter data below in Jira</b>
      </p>,
      'Allow application access',
    ];
  }

  setJiraConfiguration = async (moveForward = true) => {
    const { setSelectedIntegrationId, orgIntegration, updateJiraConfigDetails, createJiraConfigDetails } = this.props;
    const { currentStep, completed, baseUrl, formData } = this.state;

    this.setState({ waitForAPI: true });

    const jiraUrl = baseUrl.indexOf('://') === -1 ? `https://${baseUrl}` : baseUrl;
    const dataToSend = { base_url: jiraUrl, ...formData };
    const jiraActionRes = orgIntegration.id
      ? await updateJiraConfigDetails(orgIntegration.id, dataToSend)
      : await createJiraConfigDetails(dataToSend);

    const integrationId = jiraActionRes?.value?.data?.id;

    const newState = {
      waitForAPI: false,
      selectedIntegrationId: integrationId,
    };

    if (moveForward) {
      newState.currentStep = currentStep + 1;
      newState.completed = { ...completed, [currentStep]: true };
    }

    this.setState(newState);

    if (!orgIntegration.id) setSelectedIntegrationId(integrationId);
  };

  makeJiraConnection() {
    const { orgIntegration, testJira } = this.props;
    const { currentStep, completed, selectedIntegrationId } = this.state;

    const orgIntegrationId = orgIntegration.id || selectedIntegrationId;

    this.setState({ waitForAPI: true });
    testJira(orgIntegrationId, getJiraTestedFlag(orgIntegration)).then(r => {
      if (r && !r.error) {
        this.setState({
          currentStep: currentStep + 1,
          waitForAPI: false,
          completed: { ...completed, [currentStep]: true },
        });
        clearInterval(this.checkPopupInterval);
      } else {
        this.setState({ waitForAPI: false });
      }
    });

    // Interval to check if the user has popup allowed
    // just for the app dont be stuck with now response for the user
    this.waitNumber = 0;
    const checkPopup = () => {
      this.waitNumber += 1;

      if (this.waitNumber === 10) {
        if (this.state.waitForAPI) {
          toast('If you have a popup blocker please verify and try to disable it.');
          this.setState({ waitForAPI: false });
        }
        clearInterval(this.checkPopupInterval);
      }
    };

    this.checkPopupInterval = setInterval(checkPopup, 1000);
  }

  /**
   * If the user pastes something like
   * [spaces]https://account.atlassian.net/some/path/[spaces]
   * then we want to remove the `/some/path` part of it and get a clean url
   */
  cleanInsertedUrl = e => {
    const { allowFullBaseUrl } = this.props;
    const { value } = e.target;

    if (allowFullBaseUrl) {
      return value?.trim();
    }

    return trimAndCleanUrl(value);
  };

  render() {
    const { currentStep, completed, baseUrl, waitForAPI, orgIntegration } = this.state;

    const { jiraConfig, goBack } = this.props;

    const steps = this.getSteps();

    if (currentStep === 2) {
      return (
        <ConfigDetails
          justIntegrated
          orgIntegration={orgIntegration}
          jiraConfig={jiraConfig}
          disconnet={id => {
            this.props.disconnectJira(id);
            this.setState({ currentStep: 0, completed: {} });
          }}
        />
      );
    }

    return (
      <Card style={{ minHeight: '400px' }}>
        <CardContent>
          <ConfigStepper alternativeLabel activeStep={currentStep}>
            {steps.map((label, index) => (
              <JiraStep key={label}>
                <StepButton completed={completed[index]}>{label}</StepButton>
              </JiraStep>
            ))}
          </ConfigStepper>
          <ConfigContent>
            <Progress>{waitForAPI && <LinearProgress />}</Progress>
            {currentStep === 0 && (
              <ConfigStepOne
                goBack={goBack}
                baseUrl={baseUrl}
                baseUrlChange={e => this.setState({ baseUrl: this.cleanInsertedUrl(e) })}
                onNextClick={this.setJiraConfiguration}
              />
            )}
            {currentStep === 1 && (
              <ConfigStepTwo
                jiraConfig={jiraConfig}
                onNext={this.makeJiraConnection}
                onCancel={() => {
                  this.props.disconnectJira(this.props.orgIntegration.id);
                  this.setState({ currentStep: 0, completed: {} });
                }}
              />
            )}
          </ConfigContent>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = {
  updateJiraConfigDetails,
  createJiraConfigDetails,
  disconnectJira,
  updateJiraConfigMisc,
  testJira,
};

export default connect(mapStateToProps, mapDispatchToProps)(JiraConfig);

const ConfigStepper = styled(Stepper)`
  &&&& {
    justify-content: center;
  }
`;

const JiraStep = styled(Step)`
  &&&& {
    max-width: 270px;

    span {
      font-weight: 400;

      b {
        font-weight: 600;
      }
    }
  }
`;

const ConfigContent = styled.div`
  min-height: 200px;
  padding: 0 16px 16px;
`;

const Progress = styled.div`
  height: 6px;
  width: 100%;
`;
