import React from 'react';

import { ROADMAP_PAGE } from 'constants/filters';
import ColorToolbar from 'components/ColorToolbar';
import useColorLegend from 'hooks/timeline/useColorLegend';

const ColorLegend = ({ colorBy }) => {
  const [getLegendGroups, updateGroupColor] = useColorLegend(ROADMAP_PAGE);

  if (!colorBy || colorBy.key === 'idea') {
    return null;
  }

  return (
    <div style={{ display: 'inline-block' }}>
      <ColorToolbar
        groups={getLegendGroups(colorBy)}
        showPicker={colorBy.key !== 'health'}
        onUpdateGroupColor={updateGroupColor(colorBy)}
      />
    </div>
  );
};

export default ColorLegend;
