import React from 'react';
import styled, { css } from 'styled-components';

import isNumeric from 'utils/isNumeric';

const Text = ({ children, variant = 'p', withEllipsis, maxWidth, inline, bold, ...props }) => {
  return (
    <Element as={variant} withEllipsis={withEllipsis} maxWidth={maxWidth} inline={inline} bold={bold} {...props}>
      {children}
    </Element>
  );
};

export default Text;

const Element = styled.p`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  line-height: ${({ theme }) => theme.typography.lineHeightSmallLargeRem}rem;
  margin: 0;

  ${({ as }) =>
    as === 'h2' &&
    css`
      font-size: ${({ theme }) => theme.typography.fontSizeMediumRem}rem;
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      line-height: ${({ theme }) => theme.typography.lineHeight.bold};
    `}

  ${({ as }) =>
    as === 'h3' &&
    css`
      font-size: ${({ theme }) => theme.typography.fontSizeMediumSmallRem}rem;
      line-height: ${({ theme }) => theme.typography.lineHeightRegularSmallRem}rem;
    `}

  ${({ as }) =>
    as === 'h4' &&
    css`
      font-size: ${({ theme }) => theme.typography.fontSizeSmallLargeRem}rem;
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      line-height: ${({ theme }) => theme.typography.lineHeight.heading}rem;
    `}

  ${({ as }) =>
    as === 'h5' &&
    css`
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      line-height: ${({ theme }) => theme.typography.lineHeight.heading}rem;
    `}

  ${({ as }) =>
    as === 'h6' &&
    css`
      color: ${({ theme }) => theme.palette.text.secondary};
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      line-height: ${({ theme }) => theme.typography.lineHeight.heading}rem;
      text-transform: uppercase;
    `}

  ${({ as }) =>
    as === 'p' &&
    css`
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
      line-height: ${({ theme }) => theme.typography.lineHeight.body}rem;
    `}

  ${({ as }) =>
    as === 'label' &&
    css`
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      line-height: ${({ theme }) => theme.typography.lineHeightSmallerRem}rem;
    `}

  ${({ as }) =>
    as === 'small' &&
    css`
      font-size: ${({ theme }) => theme.typography.fontSizeSmallestRem}rem;
      line-height: ${({ theme }) => theme.typography.lineHeightSmallerRem}rem;
    `}

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}${isNumeric(maxWidth) ? 'px' : ''};
    `}

  ${({ bold }) =>
    bold &&
    css`
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      line-height: ${({ theme }) => theme.typography.lineHeight.bold}rem;
    `}

  ${({ inline }) =>
    inline &&
    css`
      display: inline;
    `};

  ${({ withEllipsis }) =>
    withEllipsis &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
`;
