import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  calculateInitialFromDateOfDateRange,
  calculateInitialToDateOfDateRange,
  VIEW_MODE_CHILD_FORMATS,
  VIEW_MODE_PARENT_FORMATS,
  VIEW_MODE_RELATIONS,
  zoomModes,
} from '../helpers';

/**
 * Custom hook to manage the date rang displayed on the timeline.
 * */
const useTimelineDateRange = zoomMode => {
  const [fromDate, setFromDate] = useState(() => calculateInitialFromDateOfDateRange(zoomMode));
  const [toDate, setToDate] = useState(() => calculateInitialToDateOfDateRange(zoomMode));

  const slots = useMemo(() => {
    const slots = [];

    if (fromDate && toDate) {
      const startDate = fromDate.clone().startOf(VIEW_MODE_RELATIONS[zoomMode]);
      const newDate = fromDate.clone().startOf(zoomMode);

      while (toDate.diff(startDate) > 0) {
        const children = [];
        const endDate = startDate.clone().endOf(VIEW_MODE_RELATIONS[zoomMode]);

        while (endDate.diff(newDate) > 0) {
          children.push(
            `${zoomMode === zoomModes.QUARTER ? 'Q ' : ''}${newDate.clone().format(VIEW_MODE_CHILD_FORMATS[zoomMode])}`,
          );
          newDate.add(1, zoomMode);
        }

        slots.push({
          parent: newDate.clone().subtract(1, zoomMode).format(VIEW_MODE_PARENT_FORMATS[VIEW_MODE_RELATIONS[zoomMode]]),
          children,
        });

        startDate.add(1, VIEW_MODE_RELATIONS[zoomMode]);
      }
    }

    return slots;
  }, [fromDate, toDate]);

  const updateDateRange = useCallback((fromDate, toDate) => {
    if (fromDate) {
      setFromDate(fromDate);
    }

    if (toDate) {
      setToDate(toDate);
    }
  }, []);

  useEffect(() => {
    setFromDate(calculateInitialFromDateOfDateRange(zoomMode));
    setToDate(calculateInitialToDateOfDateRange(zoomMode));
  }, [zoomMode]);

  return [fromDate, toDate, updateDateRange, slots];
};

export default useTimelineDateRange;
