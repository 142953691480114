import DOMPurify from 'dompurify';
import moment from 'moment';
import { defaultTo, pluck } from 'ramda';

import formatDate from 'utils/dates/formatDate';
import roundDecimalPlaces from 'utils/roundDecimalPlaces';
import { ALT_HEALTH_COLORS_LABELS } from 'constants/health';
import { formatMetadataRoadmapsForDisplay } from 'routes/Settings/hooks/helpers/useRoadmapsMetadata';

const defaultToEmptyArray = defaultTo([]);

const mapMultipleValues = (values, key = 'title') => {
  return values
    .filter(Boolean)
    .reduce((vals, val) => {
      if (val?.[key]) {
        return [...vals, val[key]];
      }

      return vals;
    }, [])
    .join(', ');
};

// TODO: use a more correct way to remove HTML markdown
function removeMarkdown(text) {
  if (!text) return '';

  // TODO: This is just a quick solution for what was here previously.
  //   A more correct solution may be needed (e.g., to keep line breaks).
  // return text.replace(/<\/?(p|em|u|h[1-3]|strong|code|blockquote|li|ol|ul)>/g, '');
  const dummyElement = document.createElement('div');

  dummyElement.innerHTML = DOMPurify.sanitize(text);

  let nodeText = [];

  dummyElement.childNodes.forEach(node => {
    if (node.textContent) {
      nodeText = [...nodeText, node.textContent];
    }
  });

  return nodeText.join(' ').trim();
}

function exportMultiLineText({ value }) {
  return removeMarkdown(value);
}

function exportDate({ value }) {
  if (value) {
    return formatDate(moment(value));
  }

  return '';
}

function exportMultiSelectMetadata({ value }, key = 'title') {
  if (value instanceof Array && value.length) {
    return mapMultipleValues(value, key);
  }

  return '';
}

function exportYesNo({ value }) {
  return value ? 'Yes' : 'No';
}

function exportIntegrationExternalId({ value }) {
  return mapMultipleValues(defaultToEmptyArray(value), 'external_id');
}

function exportDecimal({ value }) {
  if (value) {
    return roundDecimalPlaces(value);
  }

  return '';
}

function exportValueAsPercentage({ value }, convertToPercentage = false, decimalPrecision = 2) {
  let percentageValue = value;

  if (convertToPercentage && value) {
    percentageValue *= 100;
  }

  if (percentageValue) {
    return `${roundDecimalPlaces(percentageValue, decimalPrecision)}%`;
  }

  return '';
}

function exportHealth({ value }, healthLabels = ALT_HEALTH_COLORS_LABELS) {
  if (value) {
    const health = healthLabels[value];

    return health || '';
  }

  return '';
}

function exportMetadataRoadmap({ value }) {
  if (value?.length) {
    const formattedTitles = formatMetadataRoadmapsForDisplay(value);

    return pluck('title', formattedTitles).join(', ');
  }

  return '';
}

function defaultExport({ value }) {
  return value;
}

export {
  defaultExport,
  exportDate,
  exportDecimal,
  exportHealth,
  exportIntegrationExternalId,
  exportMetadataRoadmap,
  exportMultiLineText,
  exportMultiSelectMetadata,
  exportValueAsPercentage,
  exportYesNo,
  removeMarkdown,
};
