import axios from 'axios';
import { createThunk } from 'utils/store/thunk';
import {
  CREATE_USER_VIEW_RECURRING_NOTIFICATION,
  DELETE_USER_VIEW_RECURRING_NOTIFICATION,
  FETCH_EXISTING_RECURRING_NOTIFICATIONS_FOR_USER_VIEW,
  UPDATE_USER_VIEW_RECURRING_NOTIFICATION,
} from './types';

export const fetchRecurringNotificationsForUserView = userViewId =>
  createThunk(
    FETCH_EXISTING_RECURRING_NOTIFICATIONS_FOR_USER_VIEW,
    axios.get(`/api/userViews/${userViewId}/recurring`).then(res => res.data),
    { userViewId },
  );

export const createUserViewRecurringNotification = (integrationType, view, viewUrl, channel, comment, periodicity) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return createThunk(
    CREATE_USER_VIEW_RECURRING_NOTIFICATION,
    axios
      .post(`/api/userViews/${view.id}/${integrationType}/recurring`, {
        channel,
        view_url: viewUrl,
        comment,
        dayOfTheWeek: periodicity,
        timezone,
      })
      .then(response => response.data),
    { userViewId: view.id },
  );
};

export const updateUserViewRecurringNotification = (viewId, userViewRecurringNotificationId, channel, comment, periodicity) => {
  return createThunk(
    UPDATE_USER_VIEW_RECURRING_NOTIFICATION,
    axios
      .put(`/api/userViews/${viewId}/recurring/${userViewRecurringNotificationId}`, {
        channel,
        comment,
        dayOfTheWeek: periodicity,
      })
      .then(response => response.data),
    { userViewId: viewId },
  );
};

export const deleteUserViewRecurringNotification = (viewId, userViewRecurringNotificationId) => {
  return createThunk(
    DELETE_USER_VIEW_RECURRING_NOTIFICATION,
    axios.delete(`/api/userViews/${viewId}/recurring/${userViewRecurringNotificationId}`),
    {
      deletedId: userViewRecurringNotificationId,
      userViewId: viewId,
    },
  );
};
