import React from 'react';

import { ACTIVE_STATUS } from 'constants/common';

import { Wrapper, TextWrapper, StyledObjectiveType } from './styled';
import Actions from './Actions';

import getOKRIconByLevel from 'utils/okrs/getOKRIconByLevel';

const TitleCellRenderer = params => {
  const { data, value, selectedField, getNumberOfTextRowsToDisplayOnGridCell, hideIcon = () => false } = params;

  if (!data) {
    return <span />;
  }

  const isActive = data.status === ACTIVE_STATUS;

  const iconType = getOKRIconByLevel(data?.level) ?? selectedField;

  return (
    <Wrapper>
      {!hideIcon(value) && <StyledObjectiveType type={iconType} active={isActive} />}
      <TextWrapper
        $nOfTextRowsToDisplay={getNumberOfTextRowsToDisplayOnGridCell && getNumberOfTextRowsToDisplayOnGridCell()}
        $active={isActive}
      >
        {value}
      </TextWrapper>
      <Actions {...params} />
    </Wrapper>
  );
};

export default TitleCellRenderer;
