import { has } from 'ramda';

import { isNewRow } from './isNewRow';

const EDITABLE_FOR_NEW_ROW_KEY = 'editableForNewRow';

const hasEditableForNewRowParam = has(EDITABLE_FOR_NEW_ROW_KEY);

/**
 * This function applies standard checks to verify if a cell is
 * editable. If it is, then it evaluates the parameter passed.
 * @param {boolean|function} checkIsEditable
 */
const applyCheckIsEditable = checkIsEditable => {
  return params => {
    if (isNewRow(params)) {
      // this PR flipped the original condition to always show false
      // for new rows: https://github.com/dragonboat-io/dragonboat/pull/4192
      // in an attempt to not cause further regression, adding an extra param check for the grids that need it to be
      // controlled for new rows
      if (hasEditableForNewRowParam(params.colDef)) {
        return params.colDef?.[EDITABLE_FOR_NEW_ROW_KEY];
      }

      return true;
    }

    return typeof checkIsEditable === 'function' ? checkIsEditable(params) : checkIsEditable;
  };
};

export { applyCheckIsEditable };
