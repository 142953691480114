import AgGridAutocomplete from 'components/AgGridAutocomplete/new';

import { applyCheckIsEditable, returnsTrueIfKeyIsNotEscOrTab } from '../helpers';
import { stringComparator } from '../comparators';

const getDropdownCustomFieldOptionSelectionColumnDef = ({ editable, customField }) => {
  return {
    field: customField.key, // Needed for generic onCellValueChanged to send update to the server
    headerName: customField.title,
    editable: applyCheckIsEditable(editable),
    cellEditor: AgGridAutocomplete,
    cellEditorParams: {
      addNew: true,
      options: Object.entries(customField.data || {}).map(([key, value]) => ({
        value,
        label: value,
      })),
    },
    suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
    comparator: stringComparator,
    width: 100,
    cellEditorPopup: true,
    sortable: false,
    valueGetter: params => {
      if (!params.data) {
        return;
      }

      const selectedOptionId = params.data.custom_fields?.[customField.key];

      const valueToDisplay = customField.data?.[selectedOptionId];

      // Can be an option hash or an option value
      const nonNestedValue = params.data[customField.key];

      // Check if it is an option hash
      const nonNestedValueIsOptionKey = customField.data?.[nonNestedValue];

      // If it is an option hash, just use its display value (`valueToDisplay`)
      // Otherwise, it's a different option value from the previous one
      // (including a nonexistent option value).
      return nonNestedValueIsOptionKey ? valueToDisplay : nonNestedValue;
    },
    valueSetter: params => {
      params.data[customField.key] = params.newValue;

      return true;
    },
  };
};

export default getDropdownCustomFieldOptionSelectionColumnDef;
