import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { propEq } from 'ramda';

import {
  addOrgIntegration as addOrgIntegrationAction,
  removeOrgIntegration as removeOrgIntegrationAction,
  getIntegrationsByType as getIntegrationsByTypeSelector,
  hasIntegrationsOfType as hasIntegrationsOfTypeSelector,
  fetchIntegrationProjects as fetchIntegrationProjectsAction,
  importCountIntegrationItems as importCountIntegrationItemsAction,
  importInsertIntegrationItems as importInsertIntegrationItemsAction,
  importInsertIntegrationItemsAsync as importInsertIntegrationItemsAsyncAction,
  isIntegrationProjectsLoading as isIntegrationProjectsLoadingSelector,
  getIntegrationProjects as getIntegrationProjectsSelector,
  getIntegrationProjectsError as getIntegrationProjectsErrorSelector,
  isImportCountIntegrationItemsLoading as isImportCountIntegrationItemsLoadingSelector,
  getImportCountIntegrationItems as getImportCountIntegrationItemsSelector,
  getImportCountIntegrationItemsError as getImportCountIntegrationItemsErrorSelector,
  isImportInsertIntegrationItemsLoading as isImportInsertIntegrationItemsLoadingSelector,
  getImportInsertIntegrationItemsData as getImportInsertIntegrationItemsDataSelector,
  getImportInsertIntegrationItemsError as getImportInsertIntegrationItemsErrorSelector,
  getIdeasIntegrations as getIdeasIntegrationsSelector,
  fetchOrgIntegrations as fetchOrgIntegrationsAction,
  storeOrgIntegrationOauthCredentials as storeOrgIntegrationOauthCredentialsAction,
} from 'store/organization';
import {
  fetchProjectIntegrations as fetchProjectIntegrationsAction,
  isLoadingProjectIntegrations as isLoadingProjectIntegrationsSelector,
} from 'store/integrations';

/**
 * Filter integrations by orgIntegrationId
 *
 * @function filterByOrgIntegrationId
 * @param  {Number} orgIntegrationId
 * @return {Object}
 */
const filterByOrgIntegrationId = orgIntegrationId => propEq('id', orgIntegrationId);

const useIntegrations = (integrationType, orgIntegrationId) => {
  const dispatch = useDispatch();

  const addOrgIntegration = useCallback(data => dispatch(addOrgIntegrationAction(integrationType, data)), [integrationType]);

  const removeOrgIntegration = useCallback(
    orgIntegrationId => dispatch(removeOrgIntegrationAction(integrationType, orgIntegrationId)),
    [integrationType],
  );

  const storeOrgIntegrationOauthCredentials = useCallback(
    data => dispatch(storeOrgIntegrationOauthCredentialsAction(integrationType, orgIntegrationId, data)),
    [integrationType, orgIntegrationId],
  );

  const organizationIntegrations = useSelector(getIntegrationsByTypeSelector(integrationType));
  const hasOrgIntegrations = useSelector(hasIntegrationsOfTypeSelector(integrationType));

  const fetchIntegrationProjects = useCallback(
    () => dispatch(fetchIntegrationProjectsAction(integrationType, orgIntegrationId)),
    [integrationType, orgIntegrationId],
  );

  const isIntegrationProjectsLoading = useSelector(isIntegrationProjectsLoadingSelector(integrationType));
  const getIntegrationProjects = useSelector(getIntegrationProjectsSelector(integrationType));
  const getIntegrationProjectsError = useSelector(getIntegrationProjectsErrorSelector(integrationType));

  const getImportCountIntegrationItems = useCallback(
    (orgIntegrationId, queryId) => dispatch(importCountIntegrationItemsAction(integrationType, orgIntegrationId, queryId)),
    [],
  );

  const isImportCountIntegrationItemsLoading = useSelector(isImportCountIntegrationItemsLoadingSelector(orgIntegrationId));
  const importCountIntegrationItems = useSelector(getImportCountIntegrationItemsSelector(orgIntegrationId));
  const importCountIntegrationItemsError = useSelector(getImportCountIntegrationItemsErrorSelector(orgIntegrationId));

  const importInsertIntegrationItems = useCallback(
    (orgIntegrationId, queryId) => dispatch(importInsertIntegrationItemsAction(integrationType, orgIntegrationId, queryId)),
    [],
  );
  const asyncImportInsertIntegrationItems = useCallback(
    (orgIntegrationId, queryId) => dispatch(importInsertIntegrationItemsAsyncAction(integrationType, orgIntegrationId, queryId)),
    [],
  );
  const isImportInsertIntegrationItemsLoading = useSelector(isImportInsertIntegrationItemsLoadingSelector(orgIntegrationId));
  const importInsertIntegrationItemsData = useSelector(getImportInsertIntegrationItemsDataSelector(orgIntegrationId));
  const importInsertIntegrationItemsError = useSelector(getImportInsertIntegrationItemsErrorSelector(orgIntegrationId));

  const isFetching = useSelector(state => state.projects.isFetching);

  const getAllIntegratedURIs = useCallback(
    () => organizationIntegrations.map(integration => integration.data?.uri),
    [organizationIntegrations],
  );

  const fetchOrgIntegrations = useCallback(() => dispatch(fetchOrgIntegrationsAction()), []);
  const fetchProjectIntegrations = useCallback(projectId => dispatch(fetchProjectIntegrationsAction(projectId)), []);
  const orgIntegrations = useSelector(getIdeasIntegrationsSelector);
  const isProjectIntegrationsLoading = useSelector(state => isLoadingProjectIntegrationsSelector(state));

  const orgIntegration = useMemo(
    () => orgIntegrations.find(filterByOrgIntegrationId(orgIntegrationId)),
    [orgIntegrations, orgIntegrationId],
  );

  return {
    addOrgIntegration,
    removeOrgIntegration,
    storeOrgIntegrationOauthCredentials,
    organizationIntegrations,
    hasOrgIntegrations,
    fetchIntegrationProjects,
    isIntegrationProjectsLoading,
    getIntegrationProjects,
    getIntegrationProjectsError,
    importCountIntegrationItems,
    isImportCountIntegrationItemsLoading,
    getImportCountIntegrationItems,
    importCountIntegrationItemsError,
    importInsertIntegrationItems,
    isImportInsertIntegrationItemsLoading,
    importInsertIntegrationItemsData,
    importInsertIntegrationItemsError,
    isFetching,
    getAllIntegratedURIs,
    fetchOrgIntegrations,
    orgIntegrations,
    fetchProjectIntegrations,
    isProjectIntegrationsLoading,
    orgIntegration,
    asyncImportInsertIntegrationItems,
  };
};

export default useIntegrations;
