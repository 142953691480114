import omit from 'lodash/omit';
import pick from 'lodash/pick';
import getFiltersToFetchProjects from 'utils/getFiltersToFetchProjects';
import { CHILDREN_FILTERS } from 'constants/filters';
import { IDEA_LAYER } from 'store/projects/constants';

import getLayersFromFilters from './getLayersFromFilters';
import { IS_EMPTY_KEY, IS_NOT_EMPTY_KEY } from 'design-system/constants';

/**
 * When change/update something in this file, please update also on the following file
 * and related helpers:
 *  - dragonboat-api/server/userFilters/utils.js
 *
 * The duplication is required because for DoD integration it is required to use UserFilters
 * directly on the BE and currently is no possible to share logic between FE and BE.
 */

const fieldsInExtraFilters = extraFilters => {
  if (!extraFilters || !extraFilters.fields) {
    return {};
  }

  return Object.keys(extraFilters.fields).reduce((carry, fieldKey) => {
    const fieldValue = extraFilters.fields[fieldKey];

    // the next line allow us to filter by isEmpty or isNotEmpty in extraFilters
    if ([IS_EMPTY_KEY, IS_NOT_EMPTY_KEY].includes(fieldValue)) {
      carry[`${fieldKey}$${fieldValue}`] = true;
    } else {
      // if not filtering by isEmpty or isNotEmpty we keep the same logic
      carry[`${fieldKey}$in`] = fieldValue;
    }

    return carry;
  }, {});
};

/**
 * Compiles the main layer filters based on the provided parameters.
 *
 * @param {Object} options - The options for compiling the main layer filters.
 * @param {Object} options.pageFilters - The page filters.
 * @param {Object} options.userFilters - The user filters.
 * @param {string|Array<string>} options.layer - The layer or layers.
 * @param {string} options.pageId - The page ID.
 * @param {string} options.displayLayer - The display layer.
 * @param {string|null} [options.globalSearch=null] - The global search text
 * @param {Object} [options.extraFilters={}] - The extra filters.
 * @return {Array<Object>} The compiled main layer filters.
 */
const compileMainLayerFilters = ({
  pageFilters,
  userFilters,
  layer,
  pageId,
  displayLayer,
  globalSearch = null,
  extraFilters = {},
}) => {
  const filters = [
    {
      ...omit(getFiltersToFetchProjects(pageFilters.fields, pageId, displayLayer, userFilters, globalSearch), 'layer'),
      layer$in: (Array.isArray(layer) ? layer : [layer]).join(','),
      ...fieldsInExtraFilters(extraFilters),
    },
  ];

  return filters;
};

const compileFiltersBody = (pageFilters, userFilters, hasBet, pageId, displayLayer, globalSearch = null, extraFilters = {}) => {
  const { layer, secondLayer } = getLayersFromFilters(pageFilters, hasBet);

  // Adds layer filter corresponding to the top layer selected by the user
  const filters = compileMainLayerFilters({ pageFilters, userFilters, layer, pageId, displayLayer, globalSearch, extraFilters });

  // Adds another set of filters with secondLayer, to fetch the ones without parent (if second layer is empty it doesn't apply)
  if (layer !== IDEA_LAYER && (secondLayer || []).length) {
    filters.push({
      ...omit(getFiltersToFetchProjects(pageFilters.fields, pageId, displayLayer, userFilters, globalSearch), 'layer'),
      layer$in: secondLayer.join(','),
      parent_id$isEmpty: true,
      ...fieldsInExtraFilters(extraFilters),
    });
  }

  const childrenFilters = [];

  // By adding childrenFields the backend with fetch the children of the ones fetched before ☝️ applying (or not) these filters
  if (pageFilters.children === CHILDREN_FILTERS.allChildren) {
    childrenFilters.push({
      ...pick(
        getFiltersToFetchProjects(pageFilters.childrenFields, pageId, displayLayer, userFilters, globalSearch),
        'layer$notIn',
      ),
    });
  } else if (pageFilters.children === CHILDREN_FILTERS.filteredChildren) {
    childrenFilters.push({
      ...omit(getFiltersToFetchProjects(pageFilters.childrenFields, pageId, displayLayer, userFilters, globalSearch), 'layer'),
    });
  }

  return {
    ...pageFilters,
    fields: filters,
    childrenFields: childrenFilters,
  };
};

/**
 * Compiles the main layer filters body based on the provided parameters.
 *
 * @param {Object} pageFilters - The page filters.
 * @param {Object} userFilters - The user filters.
 * @param {boolean} hasBet - Indicates if there is a bet.
 * @param {string} pageId - The page ID.
 * @param {string} displayLayer - The display layer.
 * @param {string|null} [globalSearch=null] - The global search text.
 * @param {Object} [extraFilters={}] - The extra filters.
 * @return {Object} The compiled main layer filters body.
 */
export const compileMainLayerFiltersBody = (
  pageFilters,
  userFilters,
  hasBet,
  pageId,
  displayLayer,
  globalSearch = null,
  extraFilters = {},
) => {
  const { layer } = getLayersFromFilters(pageFilters, hasBet);

  // Adds layer filter corresponding to the top layer selected by the user
  const filters = compileMainLayerFilters({ pageFilters, userFilters, layer, pageId, displayLayer, globalSearch, extraFilters });

  // since we are compiling only main layer, childrenFilters is always empty
  const childrenFilters = [];

  return {
    ...pageFilters,
    secondLayer: [],
    children: CHILDREN_FILTERS.noChildren, // since we are compiling only main layer, children is always "no children"
    fields: filters,
    childrenFields: childrenFilters,
  };
};

export default compileFiltersBody;
