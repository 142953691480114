// External dependencies
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ADMIN_USER, LEADER_USER, OWNER_USER } from '@dragonboat/permissions';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// Dragonboat dependencies
import NewDialog from 'design-system/organisms/NewDialog/index';
import { spacing } from 'design-system/theme';

import Details from './Details';
import Header from './Header';

import { getUsers } from 'store/users/selectors';
import { updateUserViewOwner } from 'store/userViews/actions';
import { updateOwnerForAllUserViews } from 'store/userViews/sharedViews.thunks';
import { getCurrentUser } from 'store/login/selectors';

const isAdminOrEditorUser = roleId => [ADMIN_USER, LEADER_USER, OWNER_USER].includes(roleId);
const isAdminUser = roleId => [LEADER_USER, OWNER_USER].includes(roleId);
const getUsersForDropdown = users => users.filter(user => isAdminOrEditorUser(user.role_id));

const ViewDialog = ({
  show,
  onClose,
  onDelete,
  onSave,
  onUpdate,
  userViews,
  view,
  viewName,
  setViewName,
  viewDescription,
  setViewDescription,
  isSaveDisabled,
  onSaveAndShare,
}) => {
  const dispatch = useDispatch();

  const users = useSelector(getUsers);
  const currentUser = useSelector(getCurrentUser);

  const userCanUpdateOwner = isAdminUser(currentUser?.role_id) || currentUser?.id === view?.user_id;

  useEffect(() => {
    setViewName(view?.name || '');
    setViewDescription(view?.description || '');
  }, [view, show]);

  const renderDetails = (
    <Details
      show={show}
      onClose={onClose}
      onUpdate={onUpdate}
      view={view}
      userViews={userViews}
      ownerUserOptions={getUsersForDropdown(users)}
      viewName={viewName}
      setViewName={setViewName}
      viewDescription={viewDescription}
      setViewDescription={setViewDescription}
      onUpdateViewOwner={(viewId, userId) => dispatch(updateUserViewOwner(viewId, userId))}
      onUpdateViewOwnerAllViews={(userId, newUserId) => dispatch(updateOwnerForAllUserViews(userId, newUserId))}
      showUpdateOwner={userCanUpdateOwner}
    />
  );

  const cancelButton = (
    <Button
      data-test="cancel-view"
      onClick={() => {
        onClose();
        setViewName('');
        setViewDescription('');
      }}
    >
      Cancel
    </Button>
  );
  const renderDetailsActionButtons = (
    <RightActionButtons item xs={9}>
      {onSaveAndShare ? (
        <Button
          data-test="share-view"
          onClick={() => {
            onSaveAndShare(viewName, viewDescription);
          }}
          disabled={isSaveDisabled}
          color="primary"
        >
          Share
        </Button>
      ) : null}
      <Button
        data-test="save-view"
        onClick={() => {
          onSave(viewName, viewDescription);
          setViewName('');
          setViewDescription('');
          onClose();
        }}
        disabled={isSaveDisabled}
        color="primary"
      >
        Save
      </Button>
      {cancelButton}
    </RightActionButtons>
  );

  const ActionButtonsRenderer = () => (
    <Fragment>
      <Grid container>
        <Grid item xs={3}>
          {view && onDelete && (
            <RedButton data-test="delete-view" onClick={() => onDelete(view.id)} color="secondary">
              Delete
            </RedButton>
          )}
        </Grid>
        {renderDetailsActionButtons}
      </Grid>
    </Fragment>
  );

  return (
    <NewDialog
      id="view-dialog"
      data-test="view-dialog"
      open={show}
      onClose={onClose}
      maxWidth="md"
      scroll="paper"
      closeButton
      actions={ActionButtonsRenderer()}
      fullWidth
      header={<Header view={view} viewName={viewName} setViewName={setViewName} />}
    >
      <DialogWrapper>{renderDetails}</DialogWrapper>
    </NewDialog>
  );
};

const DialogWrapper = styled.div`
  padding-top: ${spacing()}px;
`;

const RedButton = styled(Button)`
  &&&& {
    position: absolute;
    left: ${spacing(2.5)}px;
  }
`;

const RightActionButtons = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

export default ViewDialog;
