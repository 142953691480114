import axios from 'axios';
import { createThunk } from 'utils/store/thunk';
import throwRequestError from '../utils/throwRequestError';

import { getOrganization } from 'store/organization/selectors';

import {
  FETCH_ORGANIZATION_ACCESS_CONTROL,
  FETCH_PROJECT_READERS,
  ADD_PROJECT_READERS,
  CREATE_ORGANIZATION_READER_TOKEN,
  REMOVE_PROJECT_READER,
  FETCH_PROJECT_READERS_RESET,
  ADD_ORGANIZATION_ACCESS_CONTROL,
  REMOVE_ORGANIZATION_ACCESS_CONTROL,
} from './types';

const fetchOrganizationAccessControl = () => {
  return async (dispatch, getState) => {
    const organization = getOrganization(getState());

    if (!organization?.id) return;

    return dispatch(
      createThunk(
        FETCH_ORGANIZATION_ACCESS_CONTROL,
        axios.get(`/api/organizations/${organization.id}/access`).catch(throwRequestError),
      ),
    );
  };
};

const addOrganizationAccessControl = (organizationId, body) => async dispatch => {
  const result = await dispatch(
    createThunk(
      ADD_ORGANIZATION_ACCESS_CONTROL,
      axios.post(`/api/organizations/${organizationId}/access`, body).catch(throwRequestError),
      { organizationId },
    ),
  );

  // when a portfolio is added we should create the reader tokens
  const childOrgId = result?.value?.data?.child_organization_id;

  if (childOrgId) {
    dispatch(createOrganizationReaderToken(organizationId, childOrgId));
  }
};

const removeOrganizationAccessControl = (organizationId, childOrganizationId) =>
  createThunk(
    REMOVE_ORGANIZATION_ACCESS_CONTROL,
    axios.delete(`/api/organizations/${organizationId}/access/${childOrganizationId}`).catch(throwRequestError),
    { organizationId, childOrganizationId },
  );

const createOrganizationReaderToken = (organizationId, childOrganizationId) =>
  createThunk(
    CREATE_ORGANIZATION_READER_TOKEN,
    axios.post(`/api/organizations/${organizationId}/access/${childOrganizationId}/reader-token`).catch(throwRequestError),
    { organizationId, childOrganizationId },
  );

const fetchProjectReaders = projectId =>
  createThunk(FETCH_PROJECT_READERS, axios.get(`/api/projects/${projectId}/readers`).catch(throwRequestError), {
    projectId,
  });

const shareProjectToOrganizations = (projectId, childOrganizationIds) =>
  createThunk(
    ADD_PROJECT_READERS,
    axios.post(`/api/projects/${projectId}/share`, { childOrganizationIds }).catch(throwRequestError),
    {
      projectId,
    },
  );

const unshareProjectWithOrganization = (projectId, childOrganizationId) =>
  createThunk(
    REMOVE_PROJECT_READER,
    axios.delete(`/api/projects/${projectId}/share/${childOrganizationId}`).catch(throwRequestError),
    {
      projectId,
      childOrganizationId,
    },
  );

const resetProjectReaders = () => ({ type: FETCH_PROJECT_READERS_RESET });

export {
  fetchOrganizationAccessControl,
  addOrganizationAccessControl,
  removeOrganizationAccessControl,
  createOrganizationReaderToken,
  fetchProjectReaders,
  shareProjectToOrganizations,
  unshareProjectWithOrganization,
  resetProjectReaders,
};
