import axios from 'axios';

import { SET_PROJECT_STORIES, SET_PROJECT_INTEGRATIONS } from './types';

const setProjectStories = (projectId, stories) => {
  return {
    type: SET_PROJECT_STORIES,
    payload: {
      projects: `${projectId}`,
      stories,
    },
  };
};

// TODO: I think this action is not being used at the moment
const setProjectIntegrations = (projectId, projectIntegrations) => {
  return {
    type: SET_PROJECT_INTEGRATIONS,
    payload: {
      projectId,
      projectIntegrations,
    },
  };
};

const authenticateOnJira = id =>
  new Promise((resolve, reject) =>
    axios
      .get(`/api/jira/${id}/auth`)
      .then(response => {
        window.open(response.data, 'Jira Authentication', 'height=700,width=1100');
        window.addEventListener(
          'message',
          event => {
            if (event.data === 'jiraAuth') {
              resolve(true);
            }
          },
          false,
        );
      })
      .catch(response => {
        reject(response);
      }),
  );

export { setProjectStories, setProjectIntegrations, authenticateOnJira };
