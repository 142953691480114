import { buildMetadataProxy } from './helpers';

const emptyObject = {};
const defaultMetadataProxy = buildMetadataProxy({});

const getState = state => {
  return state.metadataOnDemand || emptyObject;
};

export const selectLoadedMetadata = state => {
  return getState(state).metadata || defaultMetadataProxy;
};
