import axios from 'axios';

const useUploadFileAction = async (file, parentType, parent) => {
  if (!((parentType === 'projects' || parentType === 'customer-requests') && !!+parent.id)) return;

  if (!file) return;

  const path = `/api/${parentType}/${parent.id}/files`;

  const formData = new FormData();

  formData.append('file', file);

  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  const result = await axios.post(path, formData, config);

  return result.data;
};

export default useUploadFileAction;
