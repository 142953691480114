import { useMemo } from 'react';

import { DropdownCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';

import {
  planningStageColumnDef,
  statusColumnDef,
  colorColumnDef,
  updatedColumnDef,
  getMetadataRoadmapColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';
import { applyCheckIsEditable } from 'design-system/molecules/AgGridReact-New/helpers';
import useSystemFields from 'hooks/useSystemFields';
import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';
import { createPhaseRoadmap, deletePhaseRoadmap, bulkDeletePhaseRoadmaps } from 'store/phases';

import GroupHeaderInnerRenderer from '../components/GroupHeaderInnerRenderer';
import { checkIsEditable, createMetadataRoadmapColumnCellRenderer, retrieveColor, checkAddVisibility } from '../helpers';

const PHASE_KEY = 'phase';

const PHASE_ROADMAPS_FIELD = 'phase_roadmaps';

const phasesStatusColumnDef = {
  ...statusColumnDef,
  editable: applyCheckIsEditable(checkIsEditable),
  openByDefault: true,
  enableRowGroup: false,
  editableForNewRow: false,
};

const phasesColorColumnDef = {
  ...colorColumnDef,
  editable: applyCheckIsEditable(checkIsEditable),
  editableForNewRow: false,
};

const usePhasesGridColumns = (phases, { hideMetadataRoadmaps, planningStages, addNewPhaseToPlanningStage }) => {
  const [getSystemFieldName] = useSystemFields();

  const roadmapsMetadata = useRoadmapsMetadata(
    createPhaseRoadmap,
    deletePhaseRoadmap,
    phases,
    PHASE_KEY,
    bulkDeletePhaseRoadmaps,
  );

  const phasesPlanningStagesColumnDef = useMemo(
    () => ({
      ...planningStageColumnDef,
      editable: applyCheckIsEditable(checkIsEditable),
      field: 'planning_stage',
      headerName: 'Planning Stage',
      cellEditorPopup: true,
      openByDefault: true,
      enableRowGroup: false,
      cellEditor: DropdownCellEditor,
      cellEditorParams: {
        options: planningStages,
        showEmptyOption: false,
        sort: false,
      },
    }),
    [],
  );

  const columnDefs = useMemo(
    () => [
      getMetadataRoadmapColumnDef({
        roadmapsMetadata: [...roadmapsMetadata, hideMetadataRoadmaps],
        field: PHASE_ROADMAPS_FIELD,
        editable: applyCheckIsEditable(checkIsEditable),
        createCustomCellRenderer: createMetadataRoadmapColumnCellRenderer,
        editableForNewRow: false,
      }),
      phasesPlanningStagesColumnDef,
      phasesStatusColumnDef,
      phasesColorColumnDef,
      updatedColumnDef,
    ],
    [hideMetadataRoadmaps],
  );

  // TODO: For the future, make this colDef more generic
  const groupColumnDef = useMemo(
    () => ({
      headerName: `Planning Stage > ${getSystemFieldName('phase')}`,
      field: 'title',
      suppressMovable: true,
      cellClass: 'ag-react-editable-container--expandable ag-title-cell',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: GroupHeaderInnerRenderer,
        suppressCount: true,
        retrieveColor,
        checkAddVisibility,
        handleAddWithoutSave: addNewPhaseToPlanningStage,
      },
      editable: checkIsEditable,
    }),
    [],
  );

  return { columnDefs, groupColumnDef };
};

export default usePhasesGridColumns;

export { PHASE_ROADMAPS_FIELD };
