import { ColorCellRenderer } from '../cellRenderers';
import { ColorCellEditor } from '../cellEditors';

const colorColumnDef = {
  field: 'color',
  headerName: 'Color',
  width: 60,
  minWidth: 60,
  cellEditorPopup: true,
  cellRenderer: ColorCellRenderer,
  cellEditor: ColorCellEditor,
};

export default colorColumnDef;
