import { mergeRight as merge, propOr, filter, pipe, pathOr, complement, prop, concat, equals } from 'ramda';

import reduceReducers from 'reduce-reducers';
import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  FETCH_PROJECT_HISTORY_PENDING,
  FETCH_PROJECT_HISTORY_FULFILLED,
  FETCH_PROJECT_HISTORY_REJECTED,
  FETCH_PROJECT_HISTORY_NEXT_PAGE_REJECTED,
  FETCH_PROJECT_HISTORY_NEXT_PAGE_FULFILLED,
  FETCH_PROJECT_HISTORY,
  FETCH_PROJECT_HISTORY_NEXT_PAGE,
} from './types';
import { PROJECT } from './constants';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  FETCH_PROJECT_HISTORY,
  FETCH_PROJECT_HISTORY_NEXT_PAGE,
]);

const projectsInitialState = { [PROJECT]: { history: [], _meta: {} } };

const initialState = {
  ...projectsInitialState,
  ...thunksInitialState,
};

const notCustomField = complement(equals)('custom_fields');
const changeFieldIsNotCustomField = pipe(prop('changeField'), notCustomField);
const excludeCustomFieldChanges = changes => filter(changeFieldIsNotCustomField, changes);

// TODO rank_new to be removed in following release
const excludeRankNew = changes => changes.filter(c => c.changeField !== 'rank_new');

const getPayloadData = pathOr([], ['payload', 'data']);
const getHistoryData = pipe(getPayloadData, propOr([], 'data'), excludeCustomFieldChanges, excludeRankNew);
const getMeta = pipe(getPayloadData, propOr({}, '_meta'));

const setHistoryAndMeta = (entityType, action, state) => ({
  ...state,
  [entityType]: {
    ...propOr({}, entityType),
    _meta: getMeta(action),
    history: concat(pathOr([], [entityType, 'history'])(state), getHistoryData(action)),
  },
});

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROJECT_HISTORY_PENDING:
      return merge(state)(projectsInitialState);
    case FETCH_PROJECT_HISTORY_FULFILLED:
    case FETCH_PROJECT_HISTORY_NEXT_PAGE_FULFILLED:
      return setHistoryAndMeta(PROJECT, action, state);
    case FETCH_PROJECT_HISTORY_REJECTED:
    case FETCH_PROJECT_HISTORY_NEXT_PAGE_REJECTED:
      console.error('ERROR:', action);
      return state;
    default:
      return state;
  }
};

export { initialState };

const reducer = reduceReducers(initialState, historyReducer, ...thunksReducers);

export default reducer;
