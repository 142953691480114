export const PROJECT_CUSTOM_FIELD = '0';
export const CUSTOMER_REQUEST_CUSTOM_FIELD = '1';
export const CUSTOMER_CUSTOM_FIELD = '2';

export const associationTypes = [PROJECT_CUSTOM_FIELD, CUSTOMER_REQUEST_CUSTOM_FIELD, CUSTOMER_CUSTOM_FIELD];

export const CUSTOM_FIELD_TYPES = {
  PROJECTS: 'projects',
  CUSTOMER_REQUESTS: 'customerRequests',
  CUSTOMERS: 'customers',
};

export const TYPES_OF_CUSTOM_FIELDS = {
  DATE: 'Date',
  FORMULA: 'Formula',
  DROPDOWN: 'Dropdown',
  NUMBER: 'Number',
  SHORT_TEXT: 'Short Text',
  RICH_TEXT: 'Rich Text',
  YES_NO: 'Yes/No',
  MULTI_SELECT_DROPDOWN: 'Multi-select Dropdown',
};

export const AVAILABLE_PROJECT_CUSTOM_FIELDS = [
  TYPES_OF_CUSTOM_FIELDS.DATE,
  TYPES_OF_CUSTOM_FIELDS.FORMULA,
  TYPES_OF_CUSTOM_FIELDS.DROPDOWN,
  TYPES_OF_CUSTOM_FIELDS.NUMBER,
  TYPES_OF_CUSTOM_FIELDS.SHORT_TEXT,
  TYPES_OF_CUSTOM_FIELDS.RICH_TEXT,
  TYPES_OF_CUSTOM_FIELDS.YES_NO,
  TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN,
];

export const AVAILABLE_CUSTOMER_REQUEST_CUSTOM_FIELDS = [
  TYPES_OF_CUSTOM_FIELDS.DATE,
  TYPES_OF_CUSTOM_FIELDS.FORMULA,
  TYPES_OF_CUSTOM_FIELDS.DROPDOWN,
  TYPES_OF_CUSTOM_FIELDS.NUMBER,
  TYPES_OF_CUSTOM_FIELDS.SHORT_TEXT,
  TYPES_OF_CUSTOM_FIELDS.RICH_TEXT,
  TYPES_OF_CUSTOM_FIELDS.YES_NO,
  TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN,
];
