import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { COMPARE_SCENARIOS_LIST_PAGE } from 'constants/filters';
import { COMPARE_ROADMAP_VERSIONS } from 'store/grids/constants';

import CompareScenariosGrid from './components/CompareScenariosGrid';

import { CommentsLightboxProvider } from 'containers/IdeasList/IdeasList/New/hooks/useCommentsLightbox';
import useSystemFields from 'hooks/useSystemFields';
import useSubscribeNewData from 'hooks/useSubscribeNewData';
import { topics } from 'constants/topics';
import IdeasListControlsBar from 'containers/IdeasList/IdeasListControlsBar';
import useCompareRoadmapVersionsOnChangeVisibleColumns from './hooks/useCompareRoadmapVersionsOnChangeVisibleColumns';
import ConfirmDialog from 'components/ConfirmDialog';
import { getAvailableRoadmapVersions, isSettingVersionAsPlanOfRecordLoading } from 'store/roadmapVersions/selectors';
import { setVersionAsPlanOfRecord } from 'store/roadmapVersions';
import formatDateTime from 'utils/dates/formatDateTime';
import SwimlaneSelectedVersionsColors from './components/SwimlaneSelectedVersionsColors';
import useScenarioVersionColors from './hooks/useScenarioVersionColors';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { getTotalProjectResults } from 'store/projects';

const viewType = COMPARE_ROADMAP_VERSIONS;
const pageId = COMPARE_SCENARIOS_LIST_PAGE;

const RoadmapVersions = ({ location }) => {
  const dispatch = useDispatch();
  // In the ideas grid the withIntegrations must be forced in order to display the integration key in the grid

  const [grid, setGrid] = useState(null);
  const roadmapVersions = useSelector(state => getAvailableRoadmapVersions(state));
  const [getSystemFieldName] = useSystemFields();
  const [planOfRecordConfirmationDialogOpen, setPlanOfRecordConfirmationDialogOpen] = useState(false);
  const [selectedVersionIdToSetAsPlanOfRecord, setVersionIdToBeSetAsPlanOfRecord] = useState(null);
  const isSettingVersionAsPlanOfRecord = useSelector(isSettingVersionAsPlanOfRecordLoading);
  const totalProjects = useSelector(getTotalProjectResults);

  const { canView } = usePermissions();

  const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

  const { scenarioColors } = useScenarioVersionColors();

  const versionToSetAsPlanOfRecord = useMemo(() => {
    return roadmapVersions.find(version => version.id === Number(selectedVersionIdToSetAsPlanOfRecord));
  }, [roadmapVersions, selectedVersionIdToSetAsPlanOfRecord]);

  useEffect(() => {
    if (!isSettingVersionAsPlanOfRecord) {
      setPlanOfRecordConfirmationDialogOpen(false);
    }
  }, [isSettingVersionAsPlanOfRecord]);

  useSubscribeNewData(
    [
      'app',
      'projects',
      'tasks',
      'estimates',
      'phases',
      'roadmaps',
      topics.OBJECTIVES,
      topics.KEY_RESULTS,
      topics.METRICS,
      'users',
      'customFields',
      'timeframes',
      'priorities',
    ],
    false,
  );

  const { enhanceOnChangeVisibleColumns } = useCompareRoadmapVersionsOnChangeVisibleColumns();

  const _onGridReady = useCallback(params => setGrid({ gridApi: params.api, columnApi: params.columnApi }), []);
  const _getGridApi = () => grid;

  const nameForBackupVersion = useMemo(() => {
    const currentDate = formatDateTime(moment());

    return `Plan of record backup ${currentDate}`;
  }, [planOfRecordConfirmationDialogOpen]);

  const convertVersionToPlanOfRecordClick = useCallback(() => {
    dispatch(setVersionAsPlanOfRecord(selectedVersionIdToSetAsPlanOfRecord, nameForBackupVersion));
    setVersionIdToBeSetAsPlanOfRecord(null);
  }, [selectedVersionIdToSetAsPlanOfRecord, nameForBackupVersion]);

  const closeConvertPlanOfRecordDialog = useCallback(() => {
    setPlanOfRecordConfirmationDialogOpen(false);
    setVersionIdToBeSetAsPlanOfRecord(null);
  }, []);

  const openConvertPlanOfRecordDialog = useCallback(versionId => {
    setVersionIdToBeSetAsPlanOfRecord(versionId);
    setPlanOfRecordConfirmationDialogOpen(true);
  }, []);

  const getVersionToPlanOfRecordDialogContent = () => {
    const timeframeFieldName = getSystemFieldName('timeframe');

    return (
      <div>
        <p>
          <BoldText>{versionToSetAsPlanOfRecord?.name}</BoldText> will replace current plan of record with new{' '}
          {timeframeFieldName}, Owner, target start and end date.
        </p>
        <br />
        <p>
          Once you confirm, the current plan of record will automatically become a draft version with name{' '}
          <BoldText>{nameForBackupVersion}</BoldText>
        </p>
      </div>
    );
  };

  const _middleControlsRenderer = () => (
    <SwimlaneSelectedVersionsColors scenarioColors={scenarioColors} roadmapVersions={roadmapVersions} />
  );

  return (
    <div>
      {shouldDisplayControlsBar ? (
        <>
          <IdeasListControlsBar
            viewType={viewType}
            // availableViews={availableViews}
            pageId={pageId}
            numberOfRows={totalProjects}
            getGridApi={_getGridApi}
            showEstimatesFields={false}
            enhanceOnChangeVisibleColumns={enhanceOnChangeVisibleColumns}
            showAdditionalFields // to remove once new grid has additional fields implemented
            showCustomFields
            middleControlsRenderer={_middleControlsRenderer}
            hasGroupBy
          />
          <ConfirmDialog
            title="Please review and confirm"
            text={getVersionToPlanOfRecordDialogContent()}
            contentComponent="div"
            isOpen={planOfRecordConfirmationDialogOpen}
            onCancel={closeConvertPlanOfRecordDialog}
            onConfirm={convertVersionToPlanOfRecordClick}
            disableConfirm={isSettingVersionAsPlanOfRecord}
          />
        </>
      ) : (
        <EmptyHeight />
      )}
      <CommentsLightboxProvider>
        <CompareScenariosGrid
          viewType={viewType}
          onGridReady={_onGridReady}
          onConvertToPlanOfRecordHeaderClicked={openConvertPlanOfRecordDialog}
        />
      </CommentsLightboxProvider>
    </div>
  );
};

const EmptyHeight = styled.div`
  height: 48px;
`;

const BoldText = styled.strong`
  font-weight: bold;
`;

export default RoadmapVersions;
