import React from 'react';
import styled from 'styled-components';

import AppLogoLettering from 'design-system/atoms/AppLogoLettering';
import BackgroundImage from 'design-system/atoms/Background/images/Grupo5812.png';

export default ({ headerRightContent, children }) => {
  return (
    <Wrapper>
      <StyledHeader>
        <div>
          <a href="https://dragonboat.io" target="_blank" rel="noreferrer">
            <AppLogoLettering />
          </a>
        </div>

        <div>{headerRightContent}</div>
      </StyledHeader>
      <StyledChildren>{children}</StyledChildren>
    </Wrapper>
  );
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  margin: 0 2rem;

  div {
    display: flex;
    align-items: center;
  }
`;

const StyledChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 5rem);
`;

const Wrapper = styled.div`
  overflow-y: scroll;
  background-image: url(${BackgroundImage});
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  width: 100%;
  height: 100%;
`;
