import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';

const componentHOC = Component => {
  return props => {
    const { id, resource } = props;
    const [history, setHistory] = React.useState([]);
    const [historyMeta, setHistoryMeta] = React.useState({});
    const systemFields = useSelector(state => state.organization.organization.system_fields_name);
    const fetchNextPage = () => axios.get(historyMeta.next).then(handleData);

    const handleData = ({ data }) => {
      setHistory([...history, ...data.data]);
      setHistoryMeta(data._meta);
    };

    React.useEffect(() => {
      axios.get(`/api/${resource}/${id}/history`).then(handleData);
    }, []);

    return (
      <Component
        {...props}
        systemFields={systemFields}
        history={history}
        hasNext={historyMeta.next}
        fetchNextPage={fetchNextPage}
      />
    );
  };
};

componentHOC.propTypes = {
  id: PropTypes.number.isRequired,
  resource: PropTypes.oneOf(['objectives', 'keyResults']).isRequired,
};

export default componentHOC;
