import { defaultTo } from 'ramda';

import { DEFAULT_PLANNING_STAGE } from 'constants/projectLightbox';
import makeFilterOKRHierarchyByRoadmap from './makeFilterOKRHierarchyByRoadmap';

import { MAP_FILTERS_WITH_PROJECT } from 'constants/filters';

const defaultToEmptyArray = defaultTo([]);

const ROADMAP_TITLES = {
  categories: 'category',
  categorys: 'category',
  customers: 'customer',
  keyResults: 'key_result',
  keyResult1s: 'key_result',
  keyResult2s: 'key_result',
  keyResults1: 'key_result', // this is hacky but need to check all pages
  keyResults2: 'key_result', // this is hacky but need to check all pages
  objectives: 'objective',
  objectivesCorp: 'objective',
  phases: 'phase',
  priorities: 'priority',
  tags: 'tag',
  teams: 'team',
  themes: 'theme',
  timeframes: 'timeframe',
  personas: 'persona',
  lifecycles: 'lifecycle',
};

const ARRAY_VALUES = ['customers', 'tags'];
const ROADMAP_KEY = 'roadmap_id';
const SUB_ROADMAP_KEY = 'product_1_id';
const PRODUCT_2_KEY = 'product_2_id';
const okrTypes = [
  ROADMAP_TITLES.objectives,
  ROADMAP_TITLES.objectivesCorp,
  ROADMAP_TITLES.keyResults,
  ROADMAP_TITLES.keyResult1s,
  ROADMAP_TITLES.keyResult2s,
  ROADMAP_TITLES.keyResults1, // this is hacky but need to check all pages
  ROADMAP_TITLES.keyResults2, // this is hacky but need to check all pages
];

const isOkr = metaItem => okrTypes.includes(metaItem);

export function filterMetadataOptions(
  hasMetadataRoadmaps,
  data,
  metadata,
  quickFilters = {},
  organizationAccessControlOptions = {},
) {
  if (!hasMetadataRoadmaps) {
    return metadata;
  }
  const { roadmaps, products2 } = metadata;

  const quickFilterRoadmaps = [
    ...defaultToEmptyArray(quickFilters?.roadmaps),
    ...defaultToEmptyArray(quickFilters?.roadmapsCorp),
  ];
  const quickFilterProducts = defaultToEmptyArray(quickFilters?.products);
  const quickFilterProducts2 = defaultToEmptyArray(quickFilters?.products2);

  const filterOkrsByRoadmaps = makeFilterOKRHierarchyByRoadmap(
    quickFilterRoadmaps,
    quickFilterProducts,
    quickFilterProducts2,
    organizationAccessControlOptions,
  );

  const findRoadmap = (subRoadmaps = []) => {
    return (roadmaps || []).find(roadmap => (roadmap.products || []).some(product => subRoadmaps.includes(product.id)));
  };

  const findProduct2 = (quickFilter = []) => {
    return (products2 || []).find(product2 => quickFilter.includes(product2.id));
  };

  const filterMetadataRoadmaps = (metadataList = [], roadmapMetadataKey, metadataKey) => {
    return metadataList.filter(item => {
      const metadataRoadmaps = item[roadmapMetadataKey] || [];

      if (!metadataRoadmaps.length || !Object.keys(quickFilters || {}).length) {
        return item;
      }

      // if projects are not passed it must still work with no issues
      const dataMatch = (data || []).find(row => {
        if (Array.isArray(row[metadataKey])) {
          return row[metadataKey].find(meta => meta.id === item.id);
        }

        return row[metadataKey] === item.id;
      });

      const match = metadataRoadmaps.find(metaRoadmap => {
        // if roadmap but sub-roadmap selected in quick filter
        if (!metaRoadmap.product_1_id && !quickFilters.roadmaps?.length) {
          const parentRoadmap = findRoadmap(quickFilters.products);

          return metaRoadmap.roadmap_id === parentRoadmap?.id;
        }

        if (!metaRoadmap.product_2_id && !quickFilters.roadmaps?.length) {
          const product2 = findProduct2(quickFilters.products2);

          return metaRoadmap.roadmap_id === product2?.roadmap_id;
        }

        return (
          quickFilters.roadmaps?.includes(metaRoadmap.roadmap_id) ||
          quickFilters.products?.includes(metaRoadmap.product_1_id) ||
          quickFilters.products2?.includes(metaRoadmap.product_2_id)
        );
      });

      return dataMatch || match;
    });
  };

  return Object.keys(metadata).reduce((acc, metaItem) => {
    let currentData = metadata[metaItem];

    if (isOkr(metaItem)) {
      currentData = metadata[metaItem].filter(filterOkrsByRoadmaps);
    }

    const title = ROADMAP_TITLES[metaItem];

    if (title) {
      const roadmapMetadataKey = `${ROADMAP_TITLES[metaItem]}_roadmaps`;
      const metadataKey = ARRAY_VALUES.includes(metaItem) ? metaItem : `${MAP_FILTERS_WITH_PROJECT[metaItem]}`;

      return { ...acc, [metaItem]: filterMetadataRoadmaps(currentData, roadmapMetadataKey, metadataKey) };
    }

    return { ...acc, [metaItem]: currentData };
  }, {});
}

export function verifyMetadataEntities(entities = [], metadata = {}) {
  return entities.filter(entity => {
    const keyResultGroup = entity.group?.includes('keyResult');
    const isObjectiveCorp = entity.group?.includes('objectiveCorp');

    let group = isObjectiveCorp ? defaultToEmptyArray(metadata.objectivesCorp) : metadata[`${entity.group}s`];

    if (group) {
      if (keyResultGroup && entity.keyResults && entity.keyResults.length) {
        group = [...group, ...(metadata.keyResult2s || [])];
      }

      return group.some(meta => meta.id === entity.id || meta.parent_id === entity.id);
    }

    return entity;
  });
}

function getPhaseIds(filterValue, phaseRoadmaps = [], key = ROADMAP_KEY) {
  return phaseRoadmaps.reduce((ids, phaseRoadmap) => {
    if (key === SUB_ROADMAP_KEY && phaseRoadmap[key] === filterValue) {
      return [...ids, phaseRoadmap.phase_id];
    } else if (phaseRoadmap[key] === filterValue && !phaseRoadmap[SUB_ROADMAP_KEY]) {
      return [...ids, phaseRoadmap.phase_id];
    } else if (key === PRODUCT_2_KEY && phaseRoadmap[key] === filterValue) {
      return [...ids, phaseRoadmap.phase_id];
    }

    return ids;
  }, []);
}

function getPhaseDefault(phases = [], phaseIds = [], planningStage = null) {
  if (planningStage) {
    return phases.find(phase => phaseIds.includes(phase.id) && phase.planning_stage === planningStage);
  }

  return phases.find(phase => phaseIds.includes(phase.id));
}

export function getPhaseByMetadataRoadmap(phases = [], roadmapId, subRoadmapId = null, product2Id = null) {
  const phaseRoadmaps = phases.reduce((metaRoadmaps, phase) => {
    if (phase.phase_roadmaps?.length) {
      return [...metaRoadmaps, ...phase.phase_roadmaps];
    }

    return metaRoadmaps;
  }, []);

  if (subRoadmapId) {
    const subroadmapMatches = getPhaseIds(subRoadmapId, phaseRoadmaps, SUB_ROADMAP_KEY);

    // list of phase roadmaps with sub-roadmap id match
    if (subroadmapMatches.length) {
      const hasPlanningStageMatch = phases.some(
        phase => subroadmapMatches.includes(phase.id) && phase.planning_stage === DEFAULT_PLANNING_STAGE,
      );

      let phase;

      if (hasPlanningStageMatch) {
        phase = getPhaseDefault(phases, subroadmapMatches, DEFAULT_PLANNING_STAGE);
      } else {
        phase = getPhaseDefault(phases, subroadmapMatches);
      }

      return phase;
    }
  }

  if (product2Id) {
    const products2Matches = getPhaseIds(product2Id, phaseRoadmaps, PRODUCT_2_KEY);

    // list of phase roadmaps with sub-roadmap id match
    if (products2Matches.length) {
      const hasPlanningStageMatch = phases.some(
        phase => products2Matches.includes(phase.id) && phase.planning_stage === DEFAULT_PLANNING_STAGE,
      );

      let phase;

      if (hasPlanningStageMatch) {
        phase = getPhaseDefault(phases, products2Matches, DEFAULT_PLANNING_STAGE);
      } else {
        phase = getPhaseDefault(phases, products2Matches);
      }

      return phase;
    }
  }

  const roadmapMatches = getPhaseIds(roadmapId, phaseRoadmaps);

  if (roadmapMatches.length) {
    const phase = getPhaseDefault(phases, roadmapMatches, DEFAULT_PLANNING_STAGE);

    return phase || getPhaseDefault(phases, roadmapMatches);
  }

  const globalMatch = phases.find(p => !p.phase_roadmaps?.length && p.planning_stage === DEFAULT_PLANNING_STAGE);

  if (globalMatch) {
    return globalMatch;
  }

  return null;
}
