import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Delete from '@material-ui/icons/DeleteOutlined';
import { isNil, or } from 'ramda';

import UserPermission from 'containers/UserPermission';
import getUnroundedFixedDecimalValue from 'utils/getUnroundedFixedDecimalValue';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import Autocomplete from 'containers/Autocomplete';

import TeamField from './components/TeamField';
import { mapToAutocompleteSuggestion } from 'utils/mapToAutocompleteSuggestion';

import { draftBackgroundColor } from 'design-system/constants/draft';

const isNullOrEmptyString = value => or(isNil(value), value === '');
const defaultEmptyValueToZero = value => (isNullOrEmptyString(value) ? 0 : value);

export default ({
  estimates,
  userCanEdit,
  onDeleteEstimate,
  onUpdateEstimate,
  teams,
  skills,
  getSystemFieldName,
  oldLightboxActive,
  selectedRoadmapVersion,
  estimatesBeingCreated = [],
  disabledFields = [],
}) => {
  const _renderEstimate = (estimate, i) => {
    const isBeingCreated = estimatesBeingCreated.includes(estimate.uuid);
    const userCanEditEstimate =
      userCanEdit && (!disabledFields.includes('end_date_estimates') || !disabledFields.includes('start_date_estimates'));
    const estimateCanBeUpdated = userCanEditEstimate && !isBeingCreated;

    return (
      <EstimateRow
        key={i}
        container
        spacing={16}
        oldLightboxActive={oldLightboxActive}
        selectedRoadmapVersion={selectedRoadmapVersion}
      >
        <Grid item style={{ flexBasis: oldLightboxActive ? '15%' : '10%' }}>
          <TextField
            label="Number"
            value={estimate.numStaff}
            onChange={({ target }) => onUpdateEstimate(i, 'numStaff', target.value)}
            margin="normal"
            disabled={!estimateCanBeUpdated}
          />
        </Grid>

        <Grid item style={{ flexBasis: '30%' }}>
          <TeamField
            teams={teams}
            estimate={estimate}
            index={i}
            userCanEdit={estimateCanBeUpdated}
            onUpdateEstimate={onUpdateEstimate}
          />
        </Grid>

        <Grid item style={{ flexBasis: '30%' }}>
          <Autocomplete
            label={getSystemFieldName('skill')}
            metadata="skills"
            suggestions={skills.map(mapToAutocompleteSuggestion)}
            value={estimate.skill ? estimate.skill.title : ''}
            onValueChange={(_, value) => onUpdateEstimate(i, 'skill', value)}
            margin="normal"
            disabled={!estimateCanBeUpdated}
            required
            shrink
          />
        </Grid>

        <Grid item style={{ flexBasis: '15%' }}>
          <TextField
            label="Duration (week)"
            InputLabelProps={{ style: { minWidth: 185 } }}
            type="number"
            value={estimate.duration ? getUnroundedFixedDecimalValue(estimate.duration / 7) : ''}
            onChange={e => {
              let value = defaultEmptyValueToZero(e.target.value);

              if (value) {
                value = getUnroundedFixedDecimalValue(value) * 7;
              }

              onUpdateEstimate(i, 'duration', value);
            }}
            margin="normal"
            disabled={!estimateCanBeUpdated}
            required
            fullWidth
          />
        </Grid>

        <DelButtonGrid item style={{ flexBasis: '10%' }}>
          <UserPermission permission="DELETE_ESTIMATE">
            <ButtonIcon aria-label="Delete" disabled={!userCanEditEstimate} onClick={() => onDeleteEstimate(i)}>
              <Delete />
            </ButtonIcon>
          </UserPermission>
        </DelButtonGrid>
      </EstimateRow>
    );
  };

  return <Wrapper>{estimates.map((estimate, i) => _renderEstimate(estimate, i))}</Wrapper>;
};

const Wrapper = styled.div`
  width: 100%;

  > div:nth-child(1) {
    label {
      visibility: visible !important;
    }
  }
`;

const DelButtonGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const EstimateRow = styled(Grid)`
  &&&& {
    height: 60px;
    ${({ oldLightboxActive }) => (oldLightboxActive ? '' : 'justify-content: space-between')};

    label {
      visibility: hidden;
    }

    div[role='combobox'] svg {
      visibility: hidden;
    }

    button {
      margin-top: 20px;
    }

    > div {
      background-color: ${({ selectedRoadmapVersion }) => selectedRoadmapVersion && draftBackgroundColor};
    }

    input {
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    }
  }
`;
