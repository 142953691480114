import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import priorityValues from 'store/customerRequests/constants/priority';

import { createCustomer } from 'store/customers';
import { createTag } from 'store/tags';
import { getOrganization, getOrganizationIntegrations } from 'store/organization/selectors';
import { getCurrentUser } from 'store/login/selectors';
import { getUsers } from 'store/users/selectors';

import shouldAllowAddingNewMetadata from 'utils/shouldAllowAddingNewMetadata';
import useSystemFields from 'hooks/useSystemFields';
import useHiddenFields from 'hooks/useHiddenFields';
import EditForm from './EditForm';
import getStatuses from 'store/customerRequests/helpers/getStatuses';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { getAvailableIntegrations } from 'features/OneStepIntegration/store';

const emptyArray = [];

export default props => {
  const dispatch = useDispatch();
  const [getSystemFieldName] = useSystemFields();
  const { checkFieldDisplay } = useHiddenFields();

  const currentUser = useSelector(getCurrentUser);
  const organization = useSelector(getOrganization);
  const hiddenFields = organization?.portal_settings?.hiddenFields || emptyArray;
  const users = useSelector(getUsers);
  const orgIntegrations = useSelector(getOrganizationIntegrations);

  const { canView } = usePermissions();
  const hasExternalKeys = canView(PERMISSION_FEATURES.importRequestsWithExternalKey);

  const oneStepAvailableIntegrations = useSelector(getAvailableIntegrations);

  const priorities = Object.entries(priorityValues).map(([key, { label, color }]) => ({ value: key, label, color }));
  const statuses = getStatuses();

  // TODO: PERMISSION
  const _allowNewMetadata = shouldAllowAddingNewMetadata(currentUser, organization);

  const createMultiselectOption = field => {
    let createAction;

    switch (field) {
      case 'customers':
        createAction = value => dispatch(createCustomer({ name: value }));
        break;
      case 'tags':
        createAction = value => dispatch(createTag({ title: value }));
        break;
      default:
        throw new Error(`Please add action handler to ${field}`);
    }

    return value => createAction(value);
  };

  return (
    <EditForm
      currentUser={currentUser}
      priorities={priorities}
      statuses={statuses}
      createMultiselectOption={createMultiselectOption}
      allowNewMetadata={_allowNewMetadata}
      getSystemFieldName={getSystemFieldName}
      hiddenFields={hiddenFields}
      checkFieldDisplay={checkFieldDisplay}
      users={users}
      orgIntegrations={orgIntegrations}
      hasExternalKeys={hasExternalKeys}
      oneStepAvailableIntegrations={oneStepAvailableIntegrations}
      {...props}
    />
  );
};
