/**
 * Userpermission Container
 * Please write a description
 *
 * @author Nuno Silva <nuno.silva@dragonboat.io>
 */
import { Component } from 'react';
import { checkRolePermission } from './utils';
import { connect } from 'react-redux';

class UserPermission extends Component {
  render() {
    const { children, permission, permissionChecker, currentUser, fallbackComponent, isMetadata, organization, resourceEntity } =
      this.props;

    let userHasPermission = null;

    if (permissionChecker) userHasPermission = permissionChecker(currentUser, organization, isMetadata);
    else if (permission) userHasPermission = checkRolePermission(permission, currentUser, organization, isMetadata);

    if (!userHasPermission) {
      return fallbackComponent || '';
    }

    // TODO this needs to be migrated to use the hook usePermissions()
    if (resourceEntity && resourceEntity.organization_id !== organization.id) {
      return fallbackComponent || '';
    }

    return children;
  }
}

const mapStateToProps = state => {
  const { login, organization } = state;

  return {
    currentUser: login.currentUser,
    organization: organization.organization,
  };
};

export default connect(mapStateToProps, {})(UserPermission);
