import reduceReducers from 'reduce-reducers';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import { SAVE_AUTO_TAGS, GENERATE_AUTO_TAGS, GENERATE_AUTO_TAGS_FULFILLED, RESET_AUTO_TAGS } from './types';
import { WIZARD_STEPS } from '../constants';

const { initialState: operationsInitialState, reducers: operationsReducers } = getThunksInitialStateAndReducers([
  GENERATE_AUTO_TAGS,
  SAVE_AUTO_TAGS,
]);

export const initialState = {
  ...operationsInitialState,
  generatedAutoTags: [],
  wizardStep: WIZARD_STEPS.form,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_AUTO_TAGS_FULFILLED:
      return {
        ...state,
        generatedAutoTags: action.payload,
        wizardStep: WIZARD_STEPS.preview,
      };
    case RESET_AUTO_TAGS:
      return {
        ...state,
        generatedAutoTags: [],
        wizardStep: WIZARD_STEPS.form,
      };
    default:
      return state;
  }
};

export default reduceReducers(initialState, reducer, ...operationsReducers);
