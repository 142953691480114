import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import ConfirmDialog from 'components/ConfirmDialog';

import { INTEGRATIONS_KEYS, INTEGRATIONS_NAMES, INTEGRATIONS_ICON_MAPPER } from 'constants/integrations';

import useIntegrations from 'hooks/useIntegrations';
import useUserIntegrations from 'hooks/useUserIntegrations';

import Content from '../components/Content';
import Title from '../components/Title';
import Body from '../components/Body';

import IntegrationSteps from './components/IntegrationSteps';
import ZendeskConnection from './components/ZendeskConnection';

const ZENDESK_LINK = 'https://dragonboat.zendesk.com/hc/en-us/articles/25939532562071-Zendesk-and-Dragonboat-Integration';

const Component = props => {
  const { userCanUpdateOrgIntegration, userCanViewOrgIntegration } = props;

  const [confirmDialogDisplay, setConfirmDialogDisplay] = useState(false);
  const [addingOrgIntegration, setAddingOrgIntegration] = useState(false);

  const { hasOrgIntegrations, organizationIntegrations, removeOrgIntegration } = useIntegrations(INTEGRATIONS_KEYS.zendesk);

  const onShowDisconnectConfirmation = useCallback(() => setConfirmDialogDisplay(true), [setConfirmDialogDisplay]);

  const selectedOrgIntegration = useMemo(() => organizationIntegrations[0], [organizationIntegrations]);

  const { userIntegration, clearLoadedUserIntegrations } = useUserIntegrations(
    INTEGRATIONS_KEYS.zendesk,
    selectedOrgIntegration?.id,
  );

  const onIntegrationSwitchChangeHandler = useCallback(
    ({ target }) => {
      if (target?.checked) {
        setAddingOrgIntegration(true);
      } else if (!selectedOrgIntegration) {
        setAddingOrgIntegration(false);
      } else {
        setConfirmDialogDisplay(true);
      }
    },
    [selectedOrgIntegration],
  );

  const handleRemoveOrgIntegration = useCallback(() => {
    if (selectedOrgIntegration) {
      removeOrgIntegration(selectedOrgIntegration?.id).then(() => {
        clearLoadedUserIntegrations();
      });
    }
    setConfirmDialogDisplay(false);
    setAddingOrgIntegration(false);
  }, [selectedOrgIntegration, removeOrgIntegration]);

  const shouldShowIntegrationSteps = !hasOrgIntegrations || !userIntegration || addingOrgIntegration;

  const integrationNameLabel = INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.zendesk];

  const Icon = INTEGRATIONS_ICON_MAPPER.zendesk;

  return (
    <Content>
      <Title
        logo={<Icon style={{ width: 40, height: 40, marginRight: 16 }} />}
        title={`${integrationNameLabel} Integration`}
        helpLink={ZENDESK_LINK}
      />
      {shouldShowIntegrationSteps && (
        <Body>
          {/* Regarding the toggle switch: if LEADER, display the toggle; otherwise, 
          if editor, display only if there's a userIntegration (as editors cannot delete orgIntegrations) */}
          {userCanUpdateOrgIntegration && (
            <div>
              <StyledFormControl
                control={
                  <Switch
                    id="zendesk-integration-switch"
                    color="secondary"
                    checked={!!selectedOrgIntegration || addingOrgIntegration}
                    onChange={onIntegrationSwitchChangeHandler}
                  />
                }
                label={`${integrationNameLabel} Integration`}
                labelPlacement="start"
              />
              <IntegrationSteps
                orgIntegration={selectedOrgIntegration}
                userIntegration={userIntegration}
                addingOrgIntegration={addingOrgIntegration}
              />
            </div>
          )}
          {(!userCanUpdateOrgIntegration || !userCanViewOrgIntegration) && (
            <NonAdminInfo>Please contact your organization Admin to activate {integrationNameLabel} integration.</NonAdminInfo>
          )}
        </Body>
      )}
      {!shouldShowIntegrationSteps && userCanViewOrgIntegration && (
        <React.Fragment>
          {selectedOrgIntegration?.data?.uri && (
            <IntegrationLink href={selectedOrgIntegration.data.uri} target="_blank" rel="noreferrer">
              {selectedOrgIntegration.data.uri}
            </IntegrationLink>
          )}
          <ZendeskConnection
            orgIntegrationId={selectedOrgIntegration?.id}
            onShowDisconnectConfirmation={onShowDisconnectConfirmation}
            userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
          />
        </React.Fragment>
      )}
      <ConfirmDialog
        title={`Disconnect From ${integrationNameLabel}`}
        text={
          <div>
            <p>
              Disconnecting {integrationNameLabel} will disable {integrationNameLabel} linking and update for your entire
              Workspace. Do you want to continue?
            </p>
            <br />
            <p>You may reconnect in the future.</p>
          </div>
        }
        isOpen={confirmDialogDisplay}
        onCancel={() => setConfirmDialogDisplay(false)}
        onConfirm={handleRemoveOrgIntegration}
      />
    </Content>
  );
};

export default Component;

const StyledFormControl = styled(FormControlLabel)`
  &&&& {
    margin-bottom: 1rem;
  }
`;

const NonAdminInfo = styled(TextDeprecated)`
  &&&& {
    margin-left: 10px;
    padding-top: 30px;
  }
`;

const IntegrationLink = styled.a`
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: 16px;
`;
