import { useMemo } from 'react';

import {
  getTitleColumnDef,
  defaultColumnDefCommonProps,
  textCenterAlignedColumnDef,
  targetAllocationAmountColumnDef as TargetAllocationAmountColumnDef,
  targetAllocationPercentageColumnDef as TargetAllocationPercentageColumnDef,
  okrHealthColumnDef as OkrHealthColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';
import { ProgressCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import ChangeCellRenderer from 'design-system/molecules/AgGridReact-New/cellRenderers/ChangeCellRenderer';

import useNumberOfTextRowsToDisplayOnGridCell from 'hooks/useNumberOfTextRowsToDisplayOnGridCell';
import useSystemFields from 'hooks/useSystemFields';

import { GROUP_COLUMN_INITIAL_WIDTH } from 'constants/grid';
import { KEY_RESULT_1_KEY, KEY_RESULT_2_KEY, OBJECTIVE_KEY } from 'constants/objectives';
import GroupHeaderInnerRenderer from 'containers/Grids/OkrsGrid/components/GroupHeaderInnerRenderer';

const UNDEFINED_ID = 'null';
const TOTALS_COLUMN_NAME = 'Totals';

const exist = Boolean;

const checkShouldShowActions = ({ data }) => exist(data?.id) && data.id !== UNDEFINED_ID;

const useGoalsAllocationGridColumns = ({
  selectedField,
  handleOpenItem,
  rowHeight,
  dynamicColumns = [],
  preferencesOptions = {},
  allocationBy,
}) => {
  const isOkr = [OBJECTIVE_KEY, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY].includes(allocationBy);

  const [getSystemFieldName] = useSystemFields();

  const { showColumnTotal, showTargetAllocationPercentage, showTargetAllocationAmount, showHealth, showTwoDecimals } =
    preferencesOptions;

  const { getNumberOfTextRowsToDisplayOnGridCell } = useNumberOfTextRowsToDisplayOnGridCell(rowHeight);

  const titleColumnDef = useMemo(
    () => ({
      ...getTitleColumnDef(getSystemFieldName(allocationBy), 'title'),
      field: 'title',
      suppressMovable: true,
      cellClass: 'ag-react-editable-container--expandable ag-title-cell',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: GroupHeaderInnerRenderer,
        suppressCount: true,
        suppressDoubleClickExpand: true,
        openObjectiveDrawer: handleOpenItem,
        getNumberOfTextRowsToDisplayOnGridCell,
        showActions: params => isOkr && checkShouldShowActions(params),
        hideIcon: value => !isOkr || value === TOTALS_COLUMN_NAME,
      },
      editable: false,
      minWidth: 250,
      width: GROUP_COLUMN_INITIAL_WIDTH,
      pinned: 'left',
      lockPinned: true,
      rowDrag: false,
    }),
    [getSystemFieldName, selectedField, getNumberOfTextRowsToDisplayOnGridCell],
  );

  const outcomeProgressColumnDef = {
    width: 120,
    minWidth: 100,
    editable: false,
    field: 'progress',
    headerName: `${getSystemFieldName(allocationBy)} Progress`,
    cellRenderer: ProgressCellRenderer,
  };

  const totalColumnDef = {
    ...textCenterAlignedColumnDef,
    headerName: 'Total',
    field: 'total',
    width: 100,
    editable: false,
  };

  const changeColumnDef = {
    ...textCenterAlignedColumnDef,
    headerName: 'Change',
    field: 'change',
    width: 100,
    editable: false,
    cellRenderer: ChangeCellRenderer,
  };

  const targetAllocationAmountColumnDef = {
    ...TargetAllocationAmountColumnDef,
    editable: false,
    minWidth: 200,
  };

  const targetAllocationPercentageColumnDef = {
    ...TargetAllocationPercentageColumnDef,
    decimalScale: showTwoDecimals ? 2 : 0,
    editable: false,
    minWidth: 200,
  };

  const okrHealthColumnDef = {
    ...OkrHealthColumnDef,
    editable: false,
  };

  const dynamicColumnsDefs = useMemo(
    () =>
      dynamicColumns.map(({ headerName, field }) => ({
        ...textCenterAlignedColumnDef,
        headerName,
        field,
        width: 100,
        editable: false,
      })),
    [dynamicColumns],
  );

  const columnDefs = useMemo(
    () => [
      titleColumnDef,
      outcomeProgressColumnDef,
      ...(showHealth ? [okrHealthColumnDef] : []),
      ...dynamicColumnsDefs,
      ...(showColumnTotal ? [totalColumnDef] : []),
      changeColumnDef,
      ...(showTargetAllocationPercentage ? [targetAllocationPercentageColumnDef] : []),
      ...(showTargetAllocationAmount ? [targetAllocationAmountColumnDef] : []),
    ],
    [showHealth, showColumnTotal, dynamicColumnsDefs, showTargetAllocationPercentage, showTargetAllocationAmount],
  );

  const defaultColDef = {
    ...defaultColumnDefCommonProps,
    editable: true,
    sortable: true,
    resizable: true,
    filter: true,
  };

  return {
    columnDefs,
    defaultColDef,
  };
};

export default useGoalsAllocationGridColumns;
