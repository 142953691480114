import moment from 'moment-timezone';
import { convertDate } from 'utils';

/**
 * Function that checks if the current task overlaps with other project tasks
 *
 * @param {*} collapseGanttBars collapse gantt bars setting
 * @param {*} task task objects
 * @param {*} allTasks array with all tasks on current project
 */
const checkProjectTasksOverlap = (collapseGanttBars, task, allTasks) => {
  let overlaps = false;

  if (!collapseGanttBars) {
    return false;
  }

  allTasks.forEach(child => {
    if (
      (child.id !== task.id) &&
      (
        // eslint-disable-next-line max-len
        (// eslint-disable-next-line max-len
        (moment(task.start_date).startOf('day') < moment(convertDate(child.start_date, child.duration), 'DD-MM-YYYY HH:mm').startOf('day') && moment(convertDate(task.start_date, task.duration), 'DD-MM-YYYY HH:mm').startOf('day') > moment(child.start_date).startOf('day')))
      ) && !overlaps
      && task.id !== allTasks[0].id
    ) {
      overlaps = true;
    }
  });

  return overlaps;
};

export default checkProjectTasksOverlap;
