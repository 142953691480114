import * as d3 from 'd3';
import get from 'lodash/get';

// https://d3-graph-gallery.com/graph/bubble_tooltip.html

/**
 * Properties defined during construction:
 *   svg
 *   html
 *   legend
 *   bubble
 *   diameter
 *   colorRange
 *   colorLegend
 *   selectedColor
 *   legendSpacing
 *   smallDiameter
 *   textColorRange
 *   mediumDiameter
 *   selectedTextColor
 *   fontSizeFactor
 *   duration
 *   delay
 */
export default class ReactBubbleChartD3 {
  constructor(el, props = {}) {
    this.legendSpacing = typeof props.legendSpacing === 'number' ? props.legendSpacing : 3;
    this.selectedColor = props.selectedColor;
    this.selectedTextColor = props.selectedTextColor;
    this.smallDiameter = props.smallDiameter || 40;
    this.mediumDiameter = props.mediumDiameter || 115;
    this.fontSizeFactor = props.fontSizeFactor;
    this.duration = props.duration === undefined ? 0 : props.duration;
    this.delay = props.delay === undefined ? 0 : props.delay;

    // create an <svg> and <html> element - store a reference to it for later
    this.svg = d3.select(el).append('svg').attr('class', 'bubble-chart-d3').style('overflow', 'visible');

    this.tooltip = d3
      .select(el)
      .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('border-radius', '5px')
      .style('border', '3px solid')
      .style('padding', '5px')
      .style('z-index', 500);
    // create legend and update
    this.adjustSize(el);
    this.update(el, props);
  }

  /**
   * Set this.diameter and this.bubble, also size this.svg and this.html
   */
  adjustSize(el) {
    // helper values for positioning
    this.diameter = Math.min(el.offsetWidth, el.offsetHeight);
    const top = Math.max((el.offsetHeight - this.diameter) / 2, 0);

    // center some stuff vertically
    this.svg.attr('width', this.diameter).attr('height', this.diameter).style('position', 'relative').style('top', `${top}px`);
  }

  /**
   * Create and configure the tooltip
   */
  configureTooltip(el, props) {
    this.createTooltip = props.tooltip;
    this.tooltipFunc = props.tooltipFunc;
    // remove all existing divs from the tooltip
    this.tooltip.selectAll('div').remove();
    // intialize the styling
    this.tooltip.style('display', 'none');
    if (!this.createTooltip) return;

    // normalize the prop formats
    this.tooltipProps = (props.tooltipProps || []).map(tp => (typeof tp === 'string' ? { css: tp, prop: tp, display: tp } : tp));

    this.tooltipProps.forEach(({ css, prop, display }) => {
      this.tooltip.append('div').attr('class', css);
    });
  }

  /**
   * This is where the magic happens.
   * Update the tooltip and legend.
   * Set up and execute transitions of existing bubbles to new size/location/color.
   * Create and initialize new bubbles.
   * Remove old bubbles.
   * Maintain consistencies between this.svg and this.html
   */
  update(el, props) {
    this.adjustSize(el);
    this.configureTooltip(el, props);

    const { data } = props;

    if (!data) return;

    const { duration } = this;
    const { delay } = this;

    const pack = d3.pack().size([this.diameter, this.diameter]).padding(15);

    const _root = pack(d3.hierarchy(data.length ? { children: data } : data).sum(d => d.value));

    const nodes = _root.descendants().filter(d => d.depth);

    // assign new data to existing DOM for circles and labels
    const circles = this.svg.selectAll('circle').data(nodes, d => `g${d.data.id}`);

    // update - this is created before enter.append. it only applies to updating nodes.
    // create the transition on the updating elements before the entering elements
    // because enter.append merges entering elements into the update selection
    // for circles we transition their transform, r, and fill
    circles
      .transition()
      .duration(duration)
      .delay((d, i) => i * delay)
      .attr('transform', d => `translate(${d.x},${d.y})`)
      .attr('r', d => (d.data.children ? d.r : 4))
      .style('opacity', 1)
      .style('fill', d => d.data.bgColor)
      .style('stroke', d => d.data.color);

    // enter - only applies to incoming elements (once emptying data)
    if (nodes.length) {
      // initialize new circles
      circles
        .enter()
        .append('circle')
        .attr('transform', d => `translate(${d.x},${d.y})`)
        .attr('r', 0)
        .attr('class', d => (d.data.children ? 'bubble' : 'bubble leaf'))
        .on('click', (d, i) => {
          props.onClick(d);
        })
        .on('mouseover', this._tooltipMouseOver.bind(this, null, el))
        .on('mouseout', this._tooltipMouseOut.bind(this))
        .style('fill', d => d.data.bgColor)
        .style('stroke', d => d.data.color)
        .transition()
        .duration(duration * 1.2)
        .attr('transform', d => `translate(${d.x},${d.y})`)
        .attr('r', d => (d.data.children ? d.r : 4))
        .style('opacity', 1);
    }

    // exit - only applies to... exiting elements
    // for circles have them shrink to 0 as they're flying all over
    circles
      .exit()
      .transition()
      .duration(duration)
      .attr('transform', d => {
        const halfDiameter = this.diameter / 2;
        const dy = d.y - halfDiameter;
        const dx = d.x - halfDiameter;
        const theta = Math.atan2(dy, dx);
        const destX = (this.diameter * (1 + Math.cos(theta))) / 2;
        const destY = (this.diameter * (1 + Math.sin(theta))) / 2;

        return `translate(${destX},${destY})`;
      })
      .attr('r', 0)
      .remove();
  }

  /**
   * On mouseover of a bubble, populate the tooltip with that elements info
   * (if this.createTooltip is true of course)
   */
  _tooltipMouseOver(color, el, e, d) {
    if (!this.createTooltip) return;

    this.tooltipProps.forEach(({ css, prop, display }) => {
      this.tooltip.select(`.${css}`).html((display ? `${display}: ` : '') + get(d, prop));
    });

    this.tooltip.style('display', 'block');

    const tooltipNode = this.tooltip.node();

    if (this.tooltipFunc) {
      this.tooltipFunc(tooltipNode, el, d);
    }
    const width = tooltipNode.offsetWidth + 1;

    const left = e.x;
    const top = e.y;

    this.tooltip
      .style('background-color', 'black')
      .style('border-color', 'black')
      .style('border-radius', '5px')
      .style('padding', '10px')
      .style('color', 'white')
      .style('width', `${width}px`)
      .style('left', `${left}px`)
      .style('top', `${top}px`);
  }

  /**
   * On tooltip mouseout, hide the tooltip.
   */
  _tooltipMouseOut(d, i) {
    if (!this.createTooltip) return;
    this.tooltip.style('display', 'none').style('width', '').style('top', '').style('left', '');
  }

  /** Any necessary cleanup */
  destroy(el) {}
}
