import { getThunkActionTypes } from 'utils/store/thunk';

const RESET_ALLOCATION_DATA = 'RESET_ALLOCATION_DATA';
const UPDATE_GOAL_MODE_STATE = 'UPDATE_GOAL_MODE_STATE';
const UPDATE_GOAL_MODE_SNAPSHOT_STATE = 'UPDATE_GOAL_MODE_SNAPSHOT_STATE';
const UPDATE_GOAL_MODE_ALLOCATION_STATE = 'UPDATE_GOAL_MODE_ALLOCATION_STATE';

const { FETCH_OUTCOME_SNAPSHOT_DATA, FETCH_OUTCOME_SNAPSHOT_DATA_PENDING, FETCH_OUTCOME_SNAPSHOT_DATA_FULFILLED } =
  getThunkActionTypes('FETCH_OUTCOME_SNAPSHOT_DATA');

const { FETCH_OUTCOME_ALLOCATION_DATA, FETCH_OUTCOME_ALLOCATION_DATA_PENDING, FETCH_OUTCOME_ALLOCATION_DATA_FULFILLED } =
  getThunkActionTypes('FETCH_OUTCOME_ALLOCATION_DATA');

const {
  UPDATE_OUTCOME_MODULE_FILTERS,
  UPDATE_OUTCOME_MODULE_FILTERS_PENDING,
  UPDATE_OUTCOME_MODULE_FILTERS_FULFILLED,
  UPDATE_OUTCOME_MODULE_FILTERS_REJECTED,
} = getThunkActionTypes('UPDATE_OUTCOME_MODULE_FILTERS');

const {
  PAGE_LOAD_OUTCOME_MODULE_GOALS,
  PAGE_LOAD_OUTCOME_MODULE_GOALS_PENDING,
  PAGE_LOAD_OUTCOME_MODULE_GOALS_FULFILLED,
  PAGE_LOAD_OUTCOME_MODULE_GOALS_REJECTED,
} = getThunkActionTypes('PAGE_LOAD_OUTCOME_MODULE_GOALS');

export {
  FETCH_OUTCOME_ALLOCATION_DATA,
  FETCH_OUTCOME_ALLOCATION_DATA_PENDING,
  FETCH_OUTCOME_ALLOCATION_DATA_FULFILLED,
  FETCH_OUTCOME_SNAPSHOT_DATA,
  FETCH_OUTCOME_SNAPSHOT_DATA_PENDING,
  FETCH_OUTCOME_SNAPSHOT_DATA_FULFILLED,
  RESET_ALLOCATION_DATA,
  UPDATE_GOAL_MODE_ALLOCATION_STATE,
  UPDATE_GOAL_MODE_STATE,
  UPDATE_GOAL_MODE_SNAPSHOT_STATE,
  UPDATE_OUTCOME_MODULE_FILTERS,
  UPDATE_OUTCOME_MODULE_FILTERS_PENDING,
  UPDATE_OUTCOME_MODULE_FILTERS_FULFILLED,
  UPDATE_OUTCOME_MODULE_FILTERS_REJECTED,
  PAGE_LOAD_OUTCOME_MODULE_GOALS,
  PAGE_LOAD_OUTCOME_MODULE_GOALS_PENDING,
  PAGE_LOAD_OUTCOME_MODULE_GOALS_FULFILLED,
  PAGE_LOAD_OUTCOME_MODULE_GOALS_REJECTED,
};
