import { filter, pipe, map, defaultTo, keys, uniq } from 'ramda';

const STATIC_FIELDS = [
  'id',
  'title',
  'level',
  'total',
  'change',
  'status',
  'progress',
  'target_allocation_amount',
  'target_allocation_percentage',
  'status_color',
];

const isNotStaticField = field => !STATIC_FIELDS.includes(field);

const getFieldAndHeader = field => ({ field, headerName: field });

export const getDynamicFields = (fields = []) => {
  const keysFields = uniq(fields.reduce((acc, field) => [...acc, ...keys(field)], []));

  return pipe(filter(isNotStaticField), map(getFieldAndHeader), defaultTo([]))(keysFields);
};
