import { propOr } from 'ramda';

import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { OBJECT_OBJECTIVE_CORP_STRING, OBJECT_OBJECTIVE, OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2 } from 'store/objectives';

const objectiveTypeToResource = {
  [OBJECT_OBJECTIVE_CORP_STRING]: PERMISSION_RESOURCES.objectiveCorp,
  [OBJECT_OBJECTIVE]: PERMISSION_RESOURCES.objective,
  [OBJECT_KEY_RESULT]: PERMISSION_RESOURCES.keyResult,
  [OBJECT_KEY_RESULT_2]: PERMISSION_RESOURCES.keyResult2,
};

/**
 * Get the permission resource associated with a given objective type.
 *
 * @param {string} type - The objective type for which you want to retrieve the permission resource.
 * @returns {string} The permission resource associated with the provided objective type.
 */
const getPermissionResourceByObjectiveType = type => {
  const getResource = propOr(PERMISSION_RESOURCES.objective, type);

  return getResource(objectiveTypeToResource);
};

export default getPermissionResourceByObjectiveType;
