import React, { useState } from 'react';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import MuiButton from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuListItem from '@material-ui/core/MenuItem';
import MenuListItemIcon from '@material-ui/core/ListItemIcon';
import MenuListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';

import DragonMenu from 'design-system/atoms/DragonMenu/index';
import DragonIconButton from 'design-system/atoms/DragonIconButton/index';
import FluidRoadmapMetadataSelectField from 'design-system/organisms/FluidRoadmapMetadataSelectField/index';

import Add from 'design-system/atoms/DragonIcons/Add';
import { spacing } from 'design-system/theme';
import { NewColumnField } from 'constants/projects';
import {
  parseOptions,
  clickTargetIsPortal,
  getCreatePopoverProps,
  getAddOptionLabel,
  getCreateOptionLabel,
  getSelectPlaceholder,
  getCreatePopoverWidth,
} from './helpers';
import { FIELDS_TO_IGNORE_ADD_COLUMN_ROW, CONTAINER_MAX_HEIGHT, cellType } from './constants';
import useAddOrLinkColumnOrRowBox from './useAddOrLinkColumnOrRowBox';

const DragonMenuCustomItem = ({ item }) =>
  item.hide ? null : (
    <StyledMenuListItem key={item.key} onClick={item.onClick}>
      <StyledMenuListItemIcon>{item.icon}</StyledMenuListItemIcon>
      <StyledMenuListItemText>{item.renderContent()}</StyledMenuListItemText>
    </StyledMenuListItem>
  );

const AddOrLinkNewColumnOrRowBox = ({
  selectedOption,
  type,
  handleSave,
  handleClose,
  handleLinkGroup,
  currentlyVisibleMetadata,
  fieldsToIgnoreAddColumnRow = FIELDS_TO_IGNORE_ADD_COLUMN_ROW,
  fieldsToIgnoreLinkColumnRow = [],
  metadata,
}) => {
  const field = NewColumnField[0];
  const [value, setValue] = useState(null);
  const [dragonMenuAnchorEl, setDragonMenuAnchorEl] = useState(null);
  const [createPopoverAnchorEl, setCreatePopoverAnchorEl] = useState(null);
  const [createPopoverWidth, setCreatePopoverWidth] = useState(null);
  const [isSelectingAddOption, setIsSelectingAddOption] = useState(false);

  // Memoizing it as for now it still relies on metadata slices
  const { metadataOptions, canCreate, canLink } = useAddOrLinkColumnOrRowBox({
    metadata,
    selectedOptionKey: selectedOption?.key,
    fieldsToIgnoreAddColumnRow,
    fieldsToIgnoreLinkColumnRow,
    currentlyVisibleMetadata,
  });

  const closeAllMenus = () => {
    setDragonMenuAnchorEl(null);
    setCreatePopoverAnchorEl(null);
    setIsSelectingAddOption(false);
    setValue(null);
  };

  const handleSelectAddOption = optionLabel => {
    const option = metadataOptions.find(o => o.title === optionLabel);

    handleLinkGroup(option, selectedOption.key);
    closeAllMenus();
    handleClose(type);
  };

  const handleCreateInputChange = e => setValue(e.target.value);

  const handleCreateInputCancel = e => {
    e?.stopPropagation();
    setCreatePopoverAnchorEl(null);
  };

  const handleCreateInputSubmit = e => {
    e?.stopPropagation();
    handleSave({ [field.key]: value }, type);
    closeAllMenus();
  };

  const handleSelectDragonMenuItem = ({ onClick }, e) => onClick(e);

  const handleCloseDragonMenu = (anchorEl, e) => {
    if (!clickTargetIsPortal(e)) {
      closeAllMenus();
    }
  };

  const onClickDragonMenuAddOption = e => {
    e?.stopPropagation();

    if (!clickTargetIsPortal(e)) {
      setIsSelectingAddOption(true);
    }
  };

  const onClickDragonMenuCreateOption = e => {
    e?.stopPropagation();

    if (!createPopoverAnchorEl) {
      const anchorEl = e?.target?.closest('li')?.querySelector('span');

      setCreatePopoverAnchorEl(anchorEl);
      setCreatePopoverWidth(getCreatePopoverWidth(anchorEl));
    }
  };

  const renderDragonMenuAddOption = () =>
    isSelectingAddOption ? (
      <FluidRoadmapMetadataSelectField
        value=""
        key={selectedOption.key}
        name={selectedOption.key}
        onChange={handleSelectAddOption}
        options={parseOptions(metadataOptions)}
        disableSaveEmptyOption
        placeholder={getSelectPlaceholder(selectedOption.title, metadataOptions.length)}
        withEllipsis
        alwaysEditing
      />
    ) : (
      <StyledMenuListItemText>{getAddOptionLabel(selectedOption.title)}</StyledMenuListItemText>
    );

  const renderDragonMenuCreateOption = () => (
    <>
      <StyledMenuListItemText onClick={onClickDragonMenuCreateOption}>
        {getCreateOptionLabel(selectedOption.title)}
      </StyledMenuListItemText>
      <Popover
        open={Boolean(createPopoverAnchorEl)}
        anchorEl={createPopoverAnchorEl}
        onClose={() => setCreatePopoverAnchorEl(null)}
        {...getCreatePopoverProps(type)}
      >
        <PopoverContent width={createPopoverWidth}>
          <StyledTextField
            key={field.key}
            value={value}
            name={field.key}
            label={field.name}
            required={field.required}
            autoFocus={field.autoFocus}
            rows={field.rows}
            multiline={field.multiline}
            onChange={handleCreateInputChange}
            data-testid="add-new-metadata-input"
          />
          <ButtonsWrapper>
            <MuiButton onClick={handleCreateInputCancel}>Cancel</MuiButton>
            <MuiButton color="primary" onClick={handleCreateInputSubmit}>
              Save
            </MuiButton>
          </ButtonsWrapper>
        </PopoverContent>
      </Popover>
    </>
  );

  const menuItems = [
    {
      key: 'add',
      icon: (
        <IconWrapper>
          <VisibilityIcon />
        </IconWrapper>
      ),
      onClick: onClickDragonMenuAddOption,
      renderContent: renderDragonMenuAddOption,
      hide: !canLink,
    },
    {
      key: 'create',
      icon: <AddIcon />,
      onClick: onClickDragonMenuCreateOption,
      renderContent: renderDragonMenuCreateOption,
      hide: !canCreate,
    },
  ];

  if (!canCreate && !canLink) {
    return null;
  }

  return (
    <Container data-testid={`add-${type}-${selectedOption.key}`}>
      <DragonIconButton
        id={`add-${type}-${selectedOption.key}`}
        data-testid={`add-${type}-${selectedOption.key}-button`}
        type="fill"
        icon={<Add />}
        onClick={e => setDragonMenuAnchorEl(e.currentTarget)}
      />
      <StyledDragonMenu
        items={menuItems}
        anchorEl={dragonMenuAnchorEl}
        onSetAnchorEl={handleCloseDragonMenu}
        onSelectItem={handleSelectDragonMenuItem}
        closeOnSelectItem={false}
        CustomMenuItem={DragonMenuCustomItem}
      />
    </Container>
  );
};

export default AddOrLinkNewColumnOrRowBox;

export { cellType };

const Container = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  min-height: 52px;
  min-width: 170px;
  max-height: ${CONTAINER_MAX_HEIGHT}px;
  padding: ${spacing()}px ${spacing(2)}px;
  z-index: ${({ theme }) => theme.zIndex.highest};
`;

const StyledDragonMenu = styled(DragonMenu)`
  min-width: 240px;

  input {
    max-width: 100%;
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  }
`;

const StyledMenuListItem = styled(MenuListItem)`
  && {
    height: 18px;
    padding-right: ${spacing()}px;
  }
`;

const StyledMenuListItemIcon = styled(MenuListItemIcon)`
  && {
    margin-right: 0;
  }
`;

const StyledMenuListItemText = styled(MenuListItemText)`
  &&&& {
    padding: 0 0 0 ${spacing(2)}px;

    div {
      padding-left: 0;
    }

    span {
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
      font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
      color: ${({ theme }) => theme.palette.text.lightGrey};
    }
  }
`;

const IconWrapper = styled.span`
  display: flex;

  svg {
    font-size: ${({ theme }) => theme.typography.fontSizeMediumLarge}px;
  }
`;

const PopoverContent = styled.div`
  width: ${({ width }) => `${width}px`};
  padding: ${spacing(2)}px;
`;

const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    margin-top: ${spacing()}px;

    label {
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: ${spacing(2)}px;
`;
