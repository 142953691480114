import React from 'react';
import styled from 'styled-components';

import FluidTextAndDateField from 'design-system/organisms/FluidTextAndDateField/index';

import { spacing } from 'design-system/theme';

import useMetricValues from 'hooks/useMetricValues';

import isPercentage from 'utils/isPercentage';
import isNumeric from 'utils/isNumeric';
import { formatMetricValueNumber, TARGET, ACTUAL } from 'utils/metricValues';

const MetricValuesForm = ({ metric, canAddOrEdit, updateMetric, updateMetricValue, addMetricValue }) => {
  const { actualMetricValue, targetMetricValue, baselineMetricValue, handleUpdateMetricValue, handleUpdateMetricBaseline } =
    useMetricValues(metric, updateMetric, updateMetricValue, addMetricValue);

  const isValidMetricValue = value => {
    return isNumeric(value) || isPercentage(value) || value === '.';
  };

  return (
    <Wrapper>
      <StyledFluidTextAndDateField
        key="metricCurrent"
        label="Actual"
        value={formatMetricValueNumber(actualMetricValue.value || null)}
        onChange={v => handleUpdateMetricValue(ACTUAL, v, 'value', actualMetricValue)}
        dateValue={actualMetricValue.date || null}
        onDateChange={v => handleUpdateMetricValue(ACTUAL, v, 'date', actualMetricValue)}
        format="row"
        disableEdit={!canAddOrEdit}
        validateTextField={isValidMetricValue}
        highlightSingleField
      />
      <StyledFluidTextAndDateField
        key="metricTarget"
        label="Target"
        value={formatMetricValueNumber(targetMetricValue.value || null)}
        onChange={v => handleUpdateMetricValue(TARGET, v, 'value', targetMetricValue)}
        dateValue={targetMetricValue.date || null}
        onDateChange={v => handleUpdateMetricValue(TARGET, v, 'date', targetMetricValue)}
        format="row"
        disableEdit={!canAddOrEdit}
        validateTextField={isValidMetricValue}
        highlightSingleField
      />
      <StyledFluidTextAndDateField
        key="metricBaseline"
        label="Baseline"
        value={formatMetricValueNumber(baselineMetricValue.value || null)}
        onChange={v => handleUpdateMetricBaseline(metric?.id, { baseline_value: v })}
        dateValue={baselineMetricValue.date || null}
        onDateChange={v => handleUpdateMetricBaseline(metric?.id, { baseline_date: v })}
        format="row"
        disableEdit={!canAddOrEdit}
        validateTextField={isValidMetricValue}
        highlightSingleField
      />
    </Wrapper>
  );
};

export default MetricValuesForm;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing(1.25)}px;
`;

const StyledFluidTextAndDateField = styled(FluidTextAndDateField)`
  &&&& > div {
    padding-right: ${spacing(1.25)}px;
  }

  input {
    text-align: right;
  }
`;
