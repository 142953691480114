import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import FluidField from 'design-system/molecules/FluidField/index';
import TextFieldNoBorder from 'design-system/atoms/TextFieldNoBorder/index';

import { isEscape, isEnter } from 'design-system/utils/keyboard';

const FluidTextField = props => (
  <FluidField
    placeholder={null}
    readingPlaceholder={null}
    {...props}
    editorRenderer={({ cancel, disableEdit, isEditing, onChange, save, value, placeholder }) => {
      return props.showTooltip ? (
        <Tooltip title={props.tooltipText} placement="bottom-start">
          <TextFieldNoBorder
            $fontSize={props.$fontSize}
            defaultValue={value}
            placeholder={placeholder}
            inputRef={props.inputRef}
            value={
              !props.suffix
                ? value
                : (props.suffix && isEditing) || value === '' || value === null
                ? value
                : `${value}${props.suffix}`
            }
            onChange={e => onChange(e.target.value)}
            disabled={disableEdit}
            multiline={props.multiline}
            useEllipsis={props.useEllipsis}
            rowsMax={props.rowsMax}
            InputProps={props.InputProps}
            error={props.error}
            {...saveOnTabOrBlur(save, cancel, props.onBlur, props.onKeyDown, props.onEnterKeyDown)}
          />
        </Tooltip>
      ) : (
        <TextFieldNoBorder
          $fontSize={props.$fontSize}
          $width={props.$width}
          defaultValue={value}
          placeholder={placeholder}
          inputRef={props.inputRef}
          value={
            !props.suffix
              ? value
              : (props.suffix && isEditing) || value === '' || value === null
              ? value
              : `${value}${props.suffix}`
          }
          onChange={e => onChange(e.target.value)}
          disabled={disableEdit}
          multiline={props.multiline}
          rowsMax={props.rowsMax}
          error={props.error}
          InputProps={props.InputProps}
          {...saveOnTabOrBlur(save, cancel, props.onBlur, props.onKeyDown, props.onEnterKeyDown)}
        />
      );
    }}
  />
);
const saveOnTabOrBlur = (save, cancelEdit, onBlur, onKeyDown, onEnterKeyDown) => ({
  onKeyDown: e => {
    e.stopPropagation();
    const isEnterResult = isEnter(e);

    // mainly useful for multiline fields
    // reject ENTERs as they are not relevant
    if (isEnterResult) e.preventDefault();

    if (isEnterResult) {
      save();

      if (onEnterKeyDown) {
        onEnterKeyDown(e.target.value);
      }

      return;
    }
    if (isEscape(e)) cancelEdit();
    if (onKeyDown) onKeyDown(e);
  },
  onBlur: e => {
    save();
    if (onBlur) onBlur(e);
  },
});

FluidTextField.propTypes = {
  ...FluidField.propTypes,
  showTooltip: PropTypes.bool,
  suffix: PropTypes.string,
  tooltipText: PropTypes.string,
};

export default FluidTextField;
