import axios from 'axios';

import { createThunk } from 'utils/store/thunk';
import { CREATE, BULK_CREATE, UPDATE, BULK_UPDATE, DELETE, BULK_DELETE } from 'store/constants/realtimeUpdateTypes';
import { BULK_CREATE_PROJECTS_FULFILLED, DELETE_PROJECTS_FULFILLED } from 'store/projects';

import {
  ADD_PROJECT_TASKS_FULFILLED,
  CREATE_TASK,
  UPDATE_TASK,
  DELETE_TASK,
  BULK_UPDATE_TASKS,
  FETCH_TASKS,
  BULK_UPDATE_TASKS_FULFILLED,
  DELETE_TASK_FULFILLED,
} from './types';

const fetchTasks = params => createThunk(FETCH_TASKS, axios.get('/api/tasks', { params }));

const createTask = task => createThunk(CREATE_TASK, axios.post('/api/tasks/', task));

const updateTask = task =>
  createThunk(
    UPDATE_TASK,
    () => {
      if (typeof task !== 'object' || !task.id) {
        throw new Error('task must have an id');
      }

      return axios.put(`/api/tasks/${task.id}`, task).then(res => res.data);
    },
    { updatedTask: task },
  );

const bulkUpdateTasks = tasks =>
  createThunk(
    BULK_UPDATE_TASKS,
    axios.put('/api/tasks', tasks).then(res => res.data),
  );

const deleteTask = id =>
  createThunk(
    DELETE_TASK,
    axios.delete(`/api/tasks/${id}`).then(res => res.data),
  );

const gotTasksRealtimeUpdate = (type, data) => {
  return dispatch => {
    switch (type) {
      case CREATE:
        return dispatch({
          type: ADD_PROJECT_TASKS_FULFILLED,
          payload: data,
          projectId: data.length && data[0].project_id,
        });
      case BULK_CREATE:
        return dispatch({
          type: BULK_CREATE_PROJECTS_FULFILLED,
          payload: [data],
        });
      case UPDATE:
        return dispatch({
          type: BULK_UPDATE_TASKS_FULFILLED,
          payload: data,
        });
      case BULK_UPDATE:
        return dispatch({
          type: BULK_UPDATE_TASKS_FULFILLED,
          payload: data,
        });
      case DELETE:
        return dispatch({
          type: DELETE_TASK_FULFILLED,
          payload: data,
        });
      case BULK_DELETE:
        return dispatch({
          type: DELETE_PROJECTS_FULFILLED,
          payload: data,
        });
      default:
    }
  };
};

export { fetchTasks, createTask, updateTask, bulkUpdateTasks, deleteTask, gotTasksRealtimeUpdate };
