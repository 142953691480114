/* eslint-disable */
/**
 * UnfilterIcon Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import theme from '../../theme';

export default props => {
  return (
    <Checkbox
      {...props}
      style={{ ...(!props.checked ? { color: theme.palette.black } : {}) }}
      fontSize={props.fontSize}
      icon={
        !props.checked && props.showIndeterminate ? (
          <IndeterminateCheckBoxIcon
            fontSize={props.fontSize}
            style={{ ...(!props.checked ? { color: theme.palette.text.lightestGrey } : {}) }}
          />
        ) : (
          <CheckBoxOutlineBlankIcon
            fontSize={props.fontSize}
            style={{ ...(!props.checked ? { color: theme.palette.text.lightestGrey } : {}) }}
          />
        )
      }
      checkedIcon={
        <CheckBoxIcon style={props.color ? {} : { color: theme.palette.newLayout.checkbox.checked }} fontSize={props.fontSize} />
      }
    />
  );
};
