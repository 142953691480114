import React from 'react';

import Dialog from 'design-system/molecules/Dialog/index';


export default ({ open, onClose }) => {
  return (
    <Dialog open={!!open} maxWidth="md" onClose={onClose} closeButton fullWidth closeButtonColor="#1F8F9E">
      <iframe
        title="Demo Video"
        src="https://player.vimeo.com/video/307932194?title=0&byline=0&portrait=0"
        style={{
          width: '100%',
          height: '56vh',
          marginTop: 24,
        }}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </Dialog>
  );
};
