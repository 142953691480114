import React, { useEffect } from 'react';

import GanttContainer from 'containers/GanttTimeline/GanttContainer';

import ControlsBar from './ControlsBar';
import useTimeline from './hooks/useTimeline';

const TimelineComponent = ({
  pageId,
  openItems,
  portfolioMode,
  displayLayer,
  groupedBy,
  entityGroup,
  groupBySelection,
  hideMaxItemDisplayMessage = false,
}) => {
  const {
    ganttTasks,
    totalProjectsByLayer,
    ganttRef,
    search,
    isLoaded,
    zoom,
    lsState,
    gantt,
    selectedGroup,
    groupByOptions,

    refreshGantt,
    setSelectedGroup,
    setSearch,
    onGanttContainerMounted,
    toggleZoom,
    getProjectStories,
  } = useTimeline({
    pageId,
    openItems,
    portfolioMode,
    displayLayer,
  });

  useEffect(() => {
    if (groupBySelection?.key) {
      setSelectedGroup(groupBySelection);
    }
  }, [groupBySelection?.key]);

  useEffect(() => {
    refreshGantt(ganttTasks);
  }, [ganttTasks, zoom, !!gantt]);

  useEffect(() => {
    if (!gantt || !lsState.openTasks) return;

    lsState.openTasks.forEach(taskId => {
      const task = gantt.isTaskExists(taskId) ? gantt.getTask(taskId) : null;

      if (task && task.dbType === 'project' && task.integrationProgress && task.integrationProgress.issuesTotal > 0) {
        getProjectStories(taskId);
      }
    });
  }, [!!gantt]);

  return (
    <>
      <ControlsBar
        search={search}
        setSearch={setSearch}
        zoom={zoom}
        toggleZoom={toggleZoom}
        groupByOptions={groupByOptions}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        groupedBy={groupedBy}
        entityGroup={entityGroup}
        totalProjectsByLayer={totalProjectsByLayer}
        hideMaxItemDisplayMessage={hideMaxItemDisplayMessage}
        isLoaded={isLoaded}
      />
      <GanttContainer
        height="calc(100vh - 220px)"
        ganttRef={ganttRef}
        gantt={gantt}
        onGanttContainerMounted={onGanttContainerMounted}
        pageControlsVisible
      />
    </>
  );
};

export default TimelineComponent;
