import { pipe, prop, equals, either, not } from 'ramda';
import { filterActiveItens } from '.';

const propIsDate = propKey => pipe(prop(propKey), equals('dates'));
const keyIsDate = propIsDate('key');
const typeIsDate = propIsDate('type');
const isGroupFieldADate = either(keyIsDate, typeIsDate);

/**
 * @function updateGroupTitleWithSystemName updates the group title based on the system name of a given key field.
 * If no key is sent, returns the current object
 * @param  {Function} getSystemFieldName
 * @param  {Object} group
 * @return {Object} Object of the selected group with/without title update
 */
const updateGroupTitleWithSystemName = (getSystemFieldName, group) => {
  if (!group?.key) return group;
  return { ...group, title: getSystemFieldName(group?.key) };
};

const hideEmptyBasedOnPrefOrArchived = ({ groupObject, hideEmptyUserPref }) => {
  if (hideEmptyUserPref) return true;

  const isActive = filterActiveItens(groupObject);

  return not(isActive);
};

export { isGroupFieldADate, updateGroupTitleWithSystemName, hideEmptyBasedOnPrefOrArchived };
