import React, { useMemo } from 'react';
import anchorme from 'anchorme';

import WYSIWYGRender from './WYSIWYGRender';
import DOMPurify from 'dompurify';
import theme from 'design-system/theme';
import { convertEditorJsonToHtml } from 'design-system/molecules/RemirrorWYSIWYGEditor/helpers';
import { pipe, tryCatch } from 'ramda';

const DEFAULT_FONT_SIZE = `${theme.typography.fontSizeSmallRem}rem`;
const DEFAULT_LINE_HEIGHT = `${theme.typography.lineHeightRegularSmallRem}rem`;

const WYSIWYGCellRenderer = ({ value, columnApi, colDef, data }) => {
  const val = useMemo(() => {
    if (!value || !(typeof value === 'string')) {
      return '';
    }

    // TODO we may want to use linkifyHtmlString from utils instead of anchorme
    const valueToHtml = tryCatch(pipe(JSON.parse, convertEditorJsonToHtml), () => anchorme(value));

    return valueToHtml(value);
  }, [value]);

  const _onClick = e => {
    if (e.target.tagName === 'A') {
      window.open(e.target.href, '_blank');
      e.preventDefault();
    }

    if (data?.id && colDef?.onClick) {
      colDef.onClick(e, data.id, data);
    }
  };

  return (
    <WYSIWYGRender
      onClick={_onClick}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(val) }}
      style={{ whiteSpace: 'normal', fontSize: !!columnApi && DEFAULT_FONT_SIZE, lineHeight: DEFAULT_LINE_HEIGHT }}
    />
  );
};

export default WYSIWYGCellRenderer;
