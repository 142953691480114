import { head } from 'ramda';
import { getProjectProgress } from 'store/projects/helpers/getProjectProgress';
import getProjectStatusColor from 'store/projects/helpers/getProjectStatusColor';
import getStatusColorInHex from 'store/projects/helpers/getStatusColorInHex';
import { getUserName } from 'utils/index';
import formatPercent from 'design-system/utils/formatPercent';
import { _parseProjectIntegration, parseJiraIntegrationData } from 'store/projects/helpers/enrichProjects';

// We work in an enriched project. We need to mimic based on the snap project data structure. better approach?
export const normalizeSnapProject = (project, { orgIntegrations = [], users = [], teams }) => {
  let integration;
  let parsedIntegrations;

  const { progress, manualProgress } = getProjectProgress(project);
  const statusColor = getProjectStatusColor(project);
  const statusColorHex = getStatusColorInHex(project);

  if (project.integrations && project.integrations.length) {
    // parse project integrations to a format that is equal for all integrations
    parsedIntegrations = project.integrations.map(i => _parseProjectIntegration(i, orgIntegrations));

    // Should maintain the first project integration as property to not break other integrations
    integration = head(parsedIntegrations);
  }
  if (project.Jiras?.length) {
    const jiraData = head(project.Jiras)?.data || {};

    integration = parseJiraIntegrationData(jiraData);
  }

  // Custom User Fields
  const user1 = project.user1 || (project.user_1_id && users[project.user_1_id]);
  const user2 = project.user2 || (project.user_2_id && users[project.user_2_id]);
  const user3 = project.user3 || (project.user_3_id && users[project.user_3_id]);
  const user4 = project.user4 || (project.user_4_id && users[project.user_4_id]);
  const user5 = project.user5 || (project.user_5_id && users[project.user_5_id]);
  const user6 = project.user6 || (project.user_6_id && users[project.user_6_id]);
  const user7 = project.user7 || (project.user_7_id && users[project.user_7_id]);

  const resourceTeam = project.resource_team_id && teams.find(t => t.id === project.resource_team_id);

  return {
    ...project,
    roadmapCorpTitle: project?.roadmapCorp?.title || '',
    roadmapTitle: project?.roadmap?.title || '',
    product1Title: project?.product1?.title || '',
    product2Title: project?.product2?.title || '',
    objectiveCorpTitle: project?.objectiveCorp?.title || '',
    objectiveTitle: project?.objective?.title || '',
    keyResult1Title: project?.keyResult1?.title || '',
    keyResult2Title: project?.keyResult2?.title || '',
    categoryTitle: project?.category?.title || '',
    priorityTitle: project?.priority?.title || '',
    phaseTitle: project?.phase?.title || '',
    themeTitle: project?.theme?.title || '',
    timeframeCorpTitle: project?.timeframeCorp?.title || '',
    timeframeTitle: project?.timeframe?.title || '',
    timeframe2Title: project?.timeframe2?.title || '',
    progress,
    manualProgress,
    status_color: statusColor,
    statusColorHex,
    ownerName: project?.owner ? getUserName(project?.owner) : '',
    displayProgress: formatPercent(progress),
    integration,
    parsedIntegrations,
    // Custom User Fields
    user1Name: user1 ? getUserName(user1) : '',
    user2Name: user2 ? getUserName(user2) : '',
    user3Name: user3 ? getUserName(user3) : '',
    user4Name: user4 ? getUserName(user4) : '',
    user5Name: user5 ? getUserName(user5) : '',
    user6Name: user6 ? getUserName(user6) : '',
    user7Name: user7 ? getUserName(user7) : '',
    resourceTeam,
    resourceTeamTitle: resourceTeam?.title,
  };
};

export const columnToMetadataMap = {
  categoryTitle: ['category'],
  keyResult1Title: ['keyResult1'],
  keyResult2Title: ['keyResult2'],
  objectiveCorp: ['objectiveCorpTitle'],
  objectiveTitle: ['objective'],
  phaseTitle: ['phase'],
  priorityTitle: ['priority'],
  product1Title: ['product1'],
  product2Title: ['product2'],
  roadmapCorpTitle: ['roadmapCorp'],
  roadmapTitle: ['roadmap'],
  themeTitle: ['theme'],
  timeframe2Title: ['timeframe2'],
  timeframeCorpTitle: ['timeframeCorp'],
  timeframeTitle: ['timeframe'],
  ownerName: ['owner'],
  progress: ['progress', 'progress_calculated'],
  status_color: ['health_calculated', 'status_color'],
  integration: ['Jiras', 'integrations', 'jira', 'integration'],
  resourceTeamTitle: ['resource_team_id'],
  user1Name: ['user_1_id'],
  user2Name: ['user_2_id'],
  user3Name: ['user_3_id'],
  user4Name: ['user_4_id'],
  user5Name: ['user_5_id'],
  user6Name: ['user_6_id'],
  user7Name: ['user_7_id'],
};
