import React from 'react';
import styled from 'styled-components';

import WYSIWYGEditorField from 'design-system/molecules/WYSIWYGEditorField/index';

import { MAX_HEIGHT, MIN_HEIGHT } from 'design-system/molecules/RemirrorWYSIWYGEditor/constants';

import useUploadAttachmentImage from 'hooks/files/useUploadAttachmentImage';

const DESCRIPTION_PLACEHOLDER = 'Description';

export default function AnnouncementEditor({ handleValueChange, value, isAdmin }) {
  const { imageUploadHandler } = useUploadAttachmentImage();

  return (
    <EditorContainer>
      <WYSIWYGEditorField
        key="announcement-dialog-1"
        value={value}
        content={value}
        placeholder={DESCRIPTION_PLACEHOLDER}
        onChange={handleValueChange}
        disableEdit={!isAdmin}
        imageUploadHandler={imageUploadHandler}
        minHeight={MIN_HEIGHT}
        maxHeight={MAX_HEIGHT}
      />
    </EditorContainer>
  );
}

const EditorContainer = styled.div``;
