import React from 'react';
import PropTypes from 'prop-types';
import FluidField from 'design-system/molecules/FluidField/index';


const FluidAutocompleteField = props => {
  const AutocompleteComponent = props.autocompleteComponent;

  const { hideBottomBorder = true, hideIcon = true } = props;

  return (
    <FluidField
      {...props}
      editorRenderer={({ value, save, cancel, disableEdit, placeholder }) => (
        <AutocompleteComponent
          suggestions={props.options}
          value={props.value}
          onValueChange={value => save(value, value !== null)}
          hideBottomBorder={hideBottomBorder}
          hideIcon={hideIcon}
          onBlur={cancel}
          disabled={disableEdit}
          addNew={props.addNew}
          multilineRows={props.multilineRows}
          onKeyDown={props.onKeyDown}
          placeholder={placeholder}
          inputProps={{
            useEllipsis: props.useEllipsis,
          }}
        />
      )}
    />
  );
};

FluidAutocompleteField.propTypes = {
  ...FluidField.propTypes,
  options: PropTypes.array.isRequired,
};

export default FluidAutocompleteField;
