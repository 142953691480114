import React from 'react';
import styled from 'styled-components';

import Logo from './dragon.png';

export default props => <Image src={Logo} alt="Dragonboat Logo" {...props} />;

const Image = styled.img`
  &&& {
    width: 20px;
    height: 20px;
  }
`;
