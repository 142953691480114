import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const Actions = props => {
  const { onSave, onClose } = props;

  return (
    <Grid container direction="row" alignItems="center" justify="flex-end">
      <Button item color="primary" onClick={onSave}>
        Save
      </Button>
      <Button item onClick={onClose}>
        Cancel
      </Button>
    </Grid>
  );
};

Actions.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default Actions;
