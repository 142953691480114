/* eslint-disable */
/**
 * UnfilterIcon Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function jiraIcon(props) {
  return (
    <SvgIcon {...props} style={props.style} width="100%" height="100%" viewBox="0 0 32 32">
      <g id="icon-rocket" transform="translate(-1044.635 -386.847)">
        <path
          id="Union_17"
          data-name="Union 17"
          d="M1044.652,418.45a17.282,17.282,0,0,1,.835-4.4,7.475,7.475,0,0,1,1.724-3.031,3.246,3.246,0,0,1,2.6-1.08,3.876,3.876,0,0,1,2.527,1.08h0a3.521,3.521,0,0,1,1.056,2.568,3.6,3.6,0,0,1-1.006,2.566h0a8.5,8.5,0,0,1-3.1,1.721,15.922,15.922,0,0,1-4.433.785l-.221.008Zm5.106-6.961a2.047,2.047,0,0,0-1.462.613,6.3,6.3,0,0,0-1.181,2.057,12.46,12.46,0,0,0-.683,2.814,13.959,13.959,0,0,0,2.823-.725,5.861,5.861,0,0,0,2.057-1.227h0a1.855,1.855,0,0,0,.586-1.432,1.991,1.991,0,0,0-.591-1.48,2.1,2.1,0,0,0-1.482-.619.272.272,0,0,0-.068,0Zm11.7,6.771a1.443,1.443,0,0,1-1.1-.979l-2.232-5.406-6.643-6.544-5.36-2.33a1.314,1.314,0,0,1-.922-1.107,1.673,1.673,0,0,1,.447-1.416l.009-.01,5.591-5.539a2.342,2.342,0,0,1,1.111-.731,2.805,2.805,0,0,1,1.48-.082l3.939.83a10.4,10.4,0,0,1,.891-.844,22.955,22.955,0,0,1,16.357-7.238,1.349,1.349,0,0,1,.664.061,1.115,1.115,0,0,1,.507.355,1.337,1.337,0,0,1,.314.492,1.948,1.948,0,0,1,.083.592v.008a23.947,23.947,0,0,1-7.206,16.478s-.779.72-.922.84l.826,3.98a2.45,2.45,0,0,1-.11,1.439,3.336,3.336,0,0,1-.743,1.144l-5.507,5.554a1.607,1.607,0,0,1-1.477.451Zm2.127-8.88a44,44,0,0,1-3.981,2.326l2.09,4.908c.01.031.014.064.1.074a.243.243,0,0,0,.212-.064l5.453-5.351a1.3,1.3,0,0,0,.346-.609,1.709,1.709,0,0,0,.046-.664l-.683-3.184c-1.149.895-2.357,1.758-3.58,2.564Zm2.6-18.934a24.96,24.96,0,0,0-6.647,4.759,31.19,31.19,0,0,0-3.723,4.494,34.462,34.462,0,0,0-2.828,5.023l5.753,5.7a40.347,40.347,0,0,0,5-2.875,30.188,30.188,0,0,0,4.521-3.722,23.578,23.578,0,0,0,4.761-6.544,26.556,26.556,0,0,0,2.076-8.742.182.182,0,0,0-.047-.11.111.111,0,0,0-.092-.06A26.492,26.492,0,0,0,1066.183,390.446Zm-13.308,5.162a1.281,1.281,0,0,0-.586.346l-5.5,5.453a.182.182,0,0,0-.047.19c.023.1.065.115.106.123l.027.01,4.931,2.08c.711-1.342,1.48-2.67,2.3-3.953.793-1.24,1.646-2.459,2.541-3.617l-3.081-.678c-.078-.008-.157-.014-.23-.014a1.738,1.738,0,0,0-.458.06Zm9.756,1.734a3.5,3.5,0,1,1,3.5,3.5,3.5,3.5,0,0,1-3.5-3.5Zm1.6,0a1.9,1.9,0,1,0,1.9-1.9,1.9,1.9,0,0,0-1.9,1.9Z"
          transform="translate(0 -0.002)"
        />
      </g>
    </SvgIcon>
  );
}
