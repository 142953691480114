/* global Scheduler */
// External dependencies
import React, { useState } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import isObject from 'lodash/isObject';
import ReactDOM from 'react-dom';

// Dragonboat dependencies
import { rgbToHex } from 'utils';
import lightenDarkenColor from 'design-system/utils/lightenDarkenColor';
import invertedTextColor from 'design-system/utils/invertedTextColor';
import useDeepEffect from 'hooks/useDeepEffect';

import './TimelineScheduler.css';

let dates = {};

export default props => {
  const {
    className,
    height,
    tasks,
    dataTree,
    onInitScheduler,
    selectedZoom,
    dataTypeField,
    singleTimeScale,
    timeWindowStart,
    timeWindowEnd,
    showTooltip,
    renderTooltipText,
    disableDrag,
    defaultTaskTitle,
    localMode,
  } = props;
  const [scheduler, setScheduler] = useState();
  const [schedulerContainer, setSchedulerContainer] = useState();
  const timeWindow = { timeWindowStart, timeWindowEnd };
  const timeWindowRef = React.useRef(timeWindow);
  const selectedZoomRef = React.useRef(selectedZoom);
  const showTooltipRef = React.useRef(showTooltip);
  const disableDragRef = React.useRef(disableDrag);
  const defaultTaskTitleRef = React.useRef(defaultTaskTitle);

  timeWindowRef.current = timeWindow;
  selectedZoomRef.current = selectedZoom;
  showTooltipRef.current = showTooltip;
  disableDragRef.current = disableDrag;
  defaultTaskTitleRef.current = defaultTaskTitle;

  const _getZoomConfigs = () => {
    const zoomConfigs = {
      x_unit: 'week',
      x_date: '%M %d',
      x_step: 1,
      x_size: 10,
      x_length: 10,
      ...(singleTimeScale ? { x_date: '%M, %Y', second_scale: null } : { second_scale: { x_unit: 'month', x_date: '%M, %Y' } }),
    };

    switch ((selectedZoom || {}).id) {
      case 'day':
        zoomConfigs.x_date = singleTimeScale ? '%M %d' : '%d';
        break;
      case 'week':
        zoomConfigs.x_date = singleTimeScale ? '%M %d' : '%d';
        zoomConfigs.x_size = 16;
        break;
      case 'month':
        zoomConfigs.x_unit = 'month';
        zoomConfigs.x_date = singleTimeScale ? '%M %d' : '%d';
        zoomConfigs.x_size = 6;
        break;
      case 'quarter':
        zoomConfigs.x_unit = 'month';
        zoomConfigs.x_date = singleTimeScale ? '%M, %Y' : '%M';
        zoomConfigs.x_size = 12;
        zoomConfigs.second_scale = singleTimeScale ? null : { x_unit: 'year', x_date: '%Y' };
        break;

      default:
        break;
    }

    return zoomConfigs;
  };
  const _onPrevButtonClick = e => {
    const state = scheduler.getState();
    let step = -1;
    const unit = 'month';

    if ((selectedZoom || {}).id === 'quarter') step = -3;
    const newDate = scheduler.date.add(scheduler.date.timeline_start(new Date(state.date)), step, unit);

    scheduler.setCurrentView(newDate, state.mode);
  };
  const _onNextButtonClick = e => {
    const state = scheduler.getState();
    let step = 1;
    const unit = 'month';

    if ((selectedZoom || {}).id === 'quarter') step = 3;
    const newDate = scheduler.date.add(scheduler.date.timeline_start(new Date(state.date)), step, unit);

    scheduler.setCurrentView(newDate, state.mode);
  };
  const _updateColors = () => {
    const backlogElements = document.getElementsByClassName('planningStage--Backlog');
    const eventsElements = document.getElementsByClassName('dhx_cal_event_line');

    // eslint-disable-next-line no-restricted-syntax
    for (const backlogElem of backlogElements) {
      const color = rgbToHex(backlogElem.style.backgroundColor);

      backlogElem.style.setProperty('border-color', lightenDarkenColor(color, -40), 'important');
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const eventsElem of eventsElements) {
      const color = eventsElem.style.backgroundColor && rgbToHex(eventsElem.style.backgroundColor);

      eventsElem.style.setProperty('color', invertedTextColor(color, true));
    }
  };
  const _initScheduler = schedulerInstance => {
    if (onInitScheduler) onInitScheduler(schedulerInstance);

    const _getCellClass = (date, sectionKey) => {
      let classes = '';
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const key = `${sectionKey}${month}`;

      dates = {
        [key]: [...(dates[key] || []), day],
      };

      if ((selectedZoomRef.current || {}).id === 'quarter') {
        classes += `cellMonthNum${month}`;
      } else {
        classes += `cellMonthWeek${dates[key].length}`;
      }

      return classes;
    };
    const _getTimelineStart = () => schedulerInstance.date.month_start;

    schedulerInstance.templates.tooltip_date_format = schedulerInstance.date.date_to_str('%Y/%m/%d');
    schedulerInstance.templates.tooltip_text = (start, end, task) => {
      if (!showTooltipRef.current || !renderTooltipText) return;

      return renderTooltipText(
        task,
        schedulerInstance.templates.tooltip_date_format(start),
        schedulerInstance.templates.tooltip_date_format(end),
      );
    };
    schedulerInstance.locale.labels.section_title = 'Title';
    schedulerInstance.locale.labels.section_details = 'Details';
    schedulerInstance.locale.labels.section_statusColor = 'Status Color';
    schedulerInstance.locale.labels.section_project = 'Idea';
    schedulerInstance.templates.event_class = (start, end, ev) => {
      let classes = `planningStage--${ev.planningStage} eventType--${ev.type}`;
      const state = schedulerInstance.getState();

      if (moment(start) < moment(state.min_date)) {
        classes += ' leftTimelineContinue';
      }
      if (moment(end) > moment(state.max_date)) {
        classes += ' rightTimelineContinue';
      }
      if (disableDragRef.current) {
        classes += ' disabled';
      }

      return classes;
    };
    schedulerInstance.templates.event_bar_text = (start, end, event) => {
      if (!event.title) return defaultTaskTitleRef.current || 'New assignment';

      if (event.type === 'milestone') return '';

      return event.text;
    };

    schedulerInstance.createTimelineView({
      ..._getZoomConfigs(),
      name: 'timeline',
      render: 'tree',
      section_autoheight: false,
      y_unit: schedulerInstance.serverList('visibleUserTree'),
      y_property: dataTypeField,
      folder_dy: 35,
      dy: 35,
      event_min_dy: 28,
    });

    schedulerInstance.templates.timeline_cell_class = (evs, date, section) => _getCellClass(date, section.key);
    schedulerInstance.templates.timeline_scalex_class = date => _getCellClass(date, 'header');
    schedulerInstance.templates.timeline_second_scalex_class = date => {
      let classes = '';

      if (timeWindowRef.current.timeWindowStart && timeWindowRef.current.timeWindowEnd) {
        if (moment(date) < moment(timeWindowRef.current.timeWindowStart)) classes += ' hide';
        if (moment(date) > moment(timeWindowRef.current.timeWindowEnd)) classes += ' hide';
      }

      return classes;
    };
    schedulerInstance.templates.timeline_scale_date = date => {
      const timeline = schedulerInstance.getView();
      const func = schedulerInstance.date.date_to_str(timeline.x_date || schedulerInstance.config.hour_date);

      if ((selectedZoomRef.current || {}).id === 'quarter') {
        const monthNumber = date.getMonth();
        let quater = '';

        if (monthNumber === 0) {
          quater = 'Q1';
        } else if (monthNumber === 3) {
          quater = 'Q2';
        } else if (monthNumber === 6) {
          quater = 'Q3';
        } else if (monthNumber === 9) {
          quater = 'Q4';
        }
        return quater;
      }

      return func(date);
    };
    schedulerInstance.templates.timeline_second_scale_date = date => {
      const timeline = schedulerInstance.getView();
      const func = schedulerInstance.date.date_to_str(timeline.second_scale.x_date || schedulerInstance.config.hour_date);

      return func(date);
    };
    schedulerInstance.config.drag_create = (props.config || {}).drag_create === undefined ? true : props.config.drag_create;
    schedulerInstance.xy.nav_height = 0;
    schedulerInstance.xy.scale_height = 35;
    schedulerInstance.config.xml_date = '%Y-%m-%d %H:%i';
    schedulerInstance.date.timeline_start = timeWindowStart
      ? () => schedulerInstance.date.copy(moment(timeWindowStart).toDate())
      : _getTimelineStart();
    schedulerInstance.attachEvent('onDataRender', _updateColors);
    schedulerInstance.attachEvent('onBeforeDrag', (id, mode, e) => {
      return !disableDragRef.current;
    });

    schedulerInstance.init(schedulerContainer, new Date(), 'timeline');
    schedulerInstance.updateCollection('visibleUserTree', dataTree);
    schedulerInstance.parse(cloneDeep(tasks), 'json');
  };

  const _setTimeWindow = () => {
    const timeline = scheduler.getView();

    if (timeWindow.timeWindowStart && timeWindow.timeWindowEnd) {
      const numWeeks = moment(timeWindow.timeWindowEnd).diff(moment(timeWindow.timeWindowStart), 'week');
      const configs = {
        x_unit: 'day',
        x_start: numWeeks < 2 ? 1 : 7,
        x_step: numWeeks < 2 ? 1 : 7,
        x_size: numWeeks,
      };

      Object.keys(configs).forEach(key => {
        timeline[key] = configs[key];
      });

      scheduler.date.timeline_start = () => scheduler.date.copy(moment(timeWindowStart).toDate());
      scheduler.setCurrentView();

      return;
    }

    scheduler.date.timeline_start = scheduler.date.month_start;
    scheduler.setCurrentView(moment().toDate());
  };

  // =========================
  // EFFETCS

  React.useEffect(() => {
    if (!schedulerContainer) return;

    const schedulerInstance = Scheduler.getSchedulerInstance();

    setScheduler(schedulerInstance);
    _initScheduler(schedulerInstance);

    return () => {
      schedulerInstance.clearAll();
    };
    // reset the schedule when changing between local mode
  }, [schedulerContainer, localMode]);

  useDeepEffect(
    changes => {
      const _refreshAllGrid = () => {
        if (!scheduler) return;

        // prevent lossing scroll
        const scrollContainerTop = schedulerContainer.querySelector('.dhx_cal_data').scrollTop;

        scheduler.clearAll();
        scheduler.setCurrentView();
        scheduler.parse(cloneDeep(tasks), 'json');
        schedulerContainer.querySelector('.dhx_cal_data').scrollTop = scrollContainerTop;
      };

      _refreshAllGrid();

      // TODO: try to only render if multiple tasks changed
      // const [changedTasks] = changes;
      // const numOfTasksChanged = changedTasks.filter(Boolean).length;

      // if (numOfTasksChanged > 1) {
      //   return _refreshAllGrid();
      // }

      // changedTasks.forEach((changed, index) => {
      //   if (changed && tasks[index]) {
      //     let row = tasks[index];
      //     const currentRowData = scheduler.getEvent(row.id);

      //     row = {
      //       ...row,
      //       start_date: formatDateForGantt(row.start_date),
      //       ...(row.end_date ? { end_date: formatDateForGantt(row.end_date) } : {}),
      //     };

      //     if (currentRowData) {
      //       Object.assign(currentRowData, row);
      //       scheduler.updateEvent(row.id);
      //     }
      //   }
      // });
    },
    [tasks],
  );

  useDeepEffect(() => {
    if (!scheduler) return;
    scheduler.matrix.timeline.y_property = dataTypeField;

    scheduler.updateCollection('visibleUserTree', dataTree);

    scheduler.setCurrentView();
  }, [dataTypeField, dataTree]);

  useDeepEffect(() => {
    if (!scheduler) return;
    const zoomConfigs = _getZoomConfigs();
    const timeline = scheduler.getView();

    Object.keys(zoomConfigs).forEach(key => {
      timeline[key] = zoomConfigs[key];

      if (key === 'second_scale' && isObject(zoomConfigs[key])) {
        timeline.second_scale.x_unit = zoomConfigs.second_scale.x_unit;
        timeline.second_scale.x_date = zoomConfigs.second_scale.x_date;
      }
    });
    scheduler.date.timeline_start = scheduler.date.month_start;

    scheduler.setCurrentView(moment().toDate());

    _setTimeWindow();
  }, [selectedZoom, singleTimeScale, timeWindowStart, timeWindowEnd]);

  useDeepEffect(() => {
    if (!scheduler) return;

    _setTimeWindow();
  }, [timeWindowStart, timeWindowEnd]);

  React.useEffect(() => {
    if (!schedulerContainer) return;

    // eslint-disable-next-line react/no-find-dom-node
    const calDataDiv = ReactDOM.findDOMNode(schedulerContainer).getElementsByClassName('dhx_cal_data');
    const target = calDataDiv[0];

    target.addEventListener('scroll', e => {
      const offset = target.clientHeight * 0.3;
      const atBottom = target.scrollTop + target.clientHeight >= target.scrollHeight - offset;

      if (atBottom && props.nextPage) {
        props.nextPage();
      }
    });
  }, [schedulerContainer]);

  // On every render should update colors
  _updateColors();

  return (
    <ScheduleContainer
      id="staffing-scheduler"
      ref={input => setSchedulerContainer(input)}
      className={classnames('dhx_cal_container', className)}
      style={{ height }}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        style={{ display: !(timeWindowStart && timeWindowEnd) ? '' : 'none' }}
        className="my_dhx_cal_prev_button"
        onClick={_onPrevButtonClick}
      >
        &nbsp;
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        style={{ display: !(timeWindowStart && timeWindowEnd) ? '' : 'none' }}
        className="my_dhx_cal_next_button"
        onClick={_onNextButtonClick}
      >
        &nbsp;
      </div>

      <div style={{ display: !(timeWindowStart && timeWindowEnd) ? '' : 'none' }} className="dhx_cal_navline">
        <div className="dhx_cal_prev_button">&nbsp;</div>
        <div className="dhx_cal_next_button">&nbsp;</div>
        <div className="dhx_cal_today_button" />
      </div>
      <div className="dhx_cal_header" />
      <div className="dhx_cal_data" />
    </ScheduleContainer>
  );
};

const ScheduleContainer = styled.div`
  margin: 0 44px;
  border: 1px solid #efefef;
  border-radius: 5px;
  overflow: hidden;

  td[aria-label*='(Inactive)'] {
    color: #ccc;
  }
`;
