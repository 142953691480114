import axios from 'axios';

import { createThunk } from 'utils/store/thunk';
import throwRequestError from 'store/utils/throwRequestError';
import { addQueryParamToUrl } from 'utils/queryParamsUtils';
import { OPEN_METRIC } from 'constants/queryParams';

import {
  FETCH_METRIC_BY_ID_LIGHTBOX,
  OPEN_METRIC_LIGHTBOX,
  UPDATE_METRIC_BY_ID_LIGHTBOX,
  CREATE_METRIC_VALUE_LIGHTBOX,
  UPDATE_METRIC_VALUE_LIGHTBOX,
  DELETE_METRIC_VALUE_LIGHTBOX,
  CREATE_METRIC_LIGHTBOX,
  CREATE_METRIC_ROADMAP_LIGHTBOX,
  DELETE_METRIC_ROADMAP_LIGHTBOX,
  BULK_DELETE_METRIC_ROADMAP_LIGHTBOX,
  CREATE_METRIC_COMMENT,
  DELETE_METRIC_COMMENT,
  UPDATE_METRIC_COMMENT,
  FETCH_METRIC_NEXT_COMMENTS,
} from './types';
import { removeUnsavedMetricValues } from 'features/MetricsDialog/store/actions';
import { fetchMetrics } from 'store/metrics';
import { UPDATE_METRIC_BY_ID_FULFILLED } from 'store/metrics/types';
import handleUpdateMetricError from 'features/MetricsDialog/helpers/handleUpdateMetricError';
import handleCreateMetricError from 'features/MetricsDialog/helpers/handleCreateMetricError';

const fetchMetricById = metricId =>
  createThunk(
    FETCH_METRIC_BY_ID_LIGHTBOX,
    axios
      .get(`/api/metrics/${metricId}`)
      .then(res => res.data)
      .catch(throwRequestError),
  );

const openMetricLightbox = metricId => dispatch =>
  dispatch(
    createThunk(OPEN_METRIC_LIGHTBOX, async () => {
      const metricResponse = await axios.get(`/api/metrics/${metricId}`).catch(throwRequestError);
      const commentsResponse = await axios.get(`/api/metrics/${metricId}/comments`).catch(throwRequestError);

      return {
        metric: metricResponse.data,
        comments: commentsResponse.data,
      };
    }),
  );

const updateMetricById = (metricId, update) => async dispatch =>
  dispatch(
    createThunk(
      UPDATE_METRIC_BY_ID_LIGHTBOX,
      axios
        .put(`/api/metrics/${metricId}`, update)
        .then(res => {
          // todo: better check how to update the metadata slice
          dispatch({ type: UPDATE_METRIC_BY_ID_FULFILLED, payload: res.data });
          return res.data;
        })
        .catch(err => handleUpdateMetricError(err)),
    ),
  );

const addMetricValue = (metricId, metricValue) => {
  return async dispatch => {
    return dispatch(
      createThunk(
        CREATE_METRIC_VALUE_LIGHTBOX,
        axios
          .post('/api/metric-values', metricValue)
          .then(res => {
            // todo: orignal implmentation was fetching.
            //  We should evaluate if this makes sense or if we should just update metric values
            dispatch(fetchMetrics());

            return res?.data;
          })
          .catch(throwRequestError),
      ),
    );
  };
};

const updateMetricValue = (metricId, metricValueId, update) => {
  return async dispatch => {
    return dispatch(
      createThunk(
        UPDATE_METRIC_VALUE_LIGHTBOX,
        axios
          .put(`/api/metric-values/${metricValueId}`, update)
          .then(res => {
            // todo: orignal implmentation was fetching.
            //  We should evaluate if this makes sense or if we should just update metric values
            dispatch(fetchMetrics());

            return res?.data;
          })
          .catch(throwRequestError),
      ),
    );
  };
};

const deleteMetricValue = (metricId, metricValueId) => {
  return async dispatch => {
    if (!metricValueId) {
      dispatch(removeUnsavedMetricValues());
      return;
    }

    return dispatch(
      createThunk(
        DELETE_METRIC_VALUE_LIGHTBOX,
        axios
          .delete(`/api/metric-values/${metricValueId}`)
          .then(() => {
            // todo: orignal implmentation was fetching.
            //  We should evaluate if this makes sense or if we should just update metric values
            dispatch(fetchMetrics());

            return metricValueId;
          })
          .catch(throwRequestError),
      ),
    );
  };
};

const createMetric = metric => async dispatch =>
  dispatch(
    createThunk(
      CREATE_METRIC_LIGHTBOX,
      axios
        .post('/api/metrics/', metric)
        .then(res => {
          const { data } = res;

          // todo: orignal implmentation was fetching.
          //  We should evaluate if this makes sense or if we should just update metric values
          dispatch(fetchMetrics());

          addQueryParamToUrl(OPEN_METRIC, data?.id);

          return data;
        })
        .catch(err => handleCreateMetricError(err)),
    ),
  );

const createMetricRoadmap = (metricId, metricRoadmap) =>
  createThunk(
    CREATE_METRIC_ROADMAP_LIGHTBOX,
    axios.post(`/api/metrics/${metricId}/metricRoadmaps`, metricRoadmap).catch(throwRequestError),
    { metricId },
  );

const deleteMetricRoadmap = ({ id: metricId, roadmapId, subRoadmapId = null, product2Id = null }) => {
  let route = `/api/metrics/${metricId}/metricRoadmaps/${roadmapId}`;

  route += subRoadmapId ? `/${subRoadmapId}` : '';
  route += subRoadmapId && product2Id ? `/${product2Id}` : '';

  return createThunk(DELETE_METRIC_ROADMAP_LIGHTBOX, axios.delete(route).catch(throwRequestError), { metricId });
};

const bulkDeleteMetricRoadmaps = metricId =>
  createThunk(
    BULK_DELETE_METRIC_ROADMAP_LIGHTBOX,
    axios.delete(`/api/metrics/${metricId}/metricRoadmaps`).catch(throwRequestError),
    {
      metricId,
    },
  );

const createMetricComment = (metricId, comment) =>
  createThunk(CREATE_METRIC_COMMENT, axios.post(`/api/metrics/${metricId}/comments`, comment).catch(throwRequestError), {
    metricId,
  });

const fetchNextMetricComments = (metricId, nextUrl) =>
  createThunk(FETCH_METRIC_NEXT_COMMENTS, axios.get(nextUrl).catch(throwRequestError), {
    metricId,
  });

const deleteMetricComment = (metricId, commentId) =>
  createThunk(DELETE_METRIC_COMMENT, axios.delete(`/api/metrics/${metricId}/comments/${commentId}`).catch(throwRequestError), {
    metricId,
    commentId,
  });

const updateMetricComment = (metricId, commentId, data) =>
  createThunk(UPDATE_METRIC_COMMENT, axios.put(`/api/metrics/${metricId}/comments/${commentId}`, data).catch(throwRequestError), {
    metricId,
    commentId,
  });

export {
  fetchMetricById,
  openMetricLightbox,
  updateMetricById,
  addMetricValue,
  updateMetricValue,
  deleteMetricValue,
  createMetric,
  createMetricRoadmap,
  deleteMetricRoadmap,
  bulkDeleteMetricRoadmaps,
  fetchNextMetricComments,
  createMetricComment,
  deleteMetricComment,
  updateMetricComment,
};
