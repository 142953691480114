import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('GET_PROJECT_FIELDS_CONFIGURATION'),
  ...getThunkActionTypes('UPDATE_PROJECT_FIELDS_CONFIGURATION'),
  ...getThunkActionTypes('GET_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION'),
  ...getThunkActionTypes('UPDATE_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION'),
};

export const {
  GET_PROJECT_FIELDS_CONFIGURATION,
  GET_PROJECT_FIELDS_CONFIGURATION_FULFILLED,
  UPDATE_PROJECT_FIELDS_CONFIGURATION,
  UPDATE_PROJECT_FIELDS_CONFIGURATION_FULFILLED,
  GET_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION,
  GET_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION_FULFILLED,
  UPDATE_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION,
  UPDATE_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION_FULFILLED,
} = actionTypes;
