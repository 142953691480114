const { default: styled } = require('styled-components');

const GroupContainer = styled.div`
  padding-bottom: 4px;
  margin-bottom: 20px;
  ${({ bordered }) => bordered && `border-bottom: 1px solid rgba(44, 44, 44, 0.1);`}

  > label {
    margin-bottom: 10px;
    display: block;
  }
`;

export { GroupContainer };
