import React from 'react';
import styled, { css } from 'styled-components';

import theme from 'design-system/theme';
import ClickableTitle from 'design-system/atoms/ClickableTitle/index';
import FluidTextField from 'design-system/organisms/FluidTextField/index';


const FONT_SIZE = theme.typography.fontSize;
const ICON_SIZE = theme.typography.fontSizeSmallLarge;

// in this case we do not want to have link style when it does not have a link click action defined
const linkWithoutClickStyles = css`
  color: ${theme.typography.color}; // ellipsis are added in a level above

  & > a:hover {
    cursor: default;
  }
  & * {
    color: ${theme.typography.color};
  }
`;

const BreadcrumbTitle = ({
  icon,
  title,
  isEditable,
  placeholder,
  readingPlaceholder = null,
  onChange,
  onClick,
  showTooltip,
  fontSize = FONT_SIZE,
  validation,
  name,
  onBlur,
  onKeyDown,
  error,
  inputRef,
  useTextFieldWhenNonEditable,
  multiline = true,
  useEllipsis = false,
}) => {
  const shouldRenderTextField = isEditable || useTextFieldWhenNonEditable;

  return (
    <Container isEditable={isEditable}>
      {icon && <StyledIcon>{icon}</StyledIcon>}
      {shouldRenderTextField ? (
        <FluidTextField
          value={title}
          placeholder={placeholder}
          readingPlaceholder={readingPlaceholder}
          onChange={onChange}
          $fontSize={fontSize}
          rowsMax={2}
          multiline={multiline}
          useEllipsis={useEllipsis}
          showTooltip={showTooltip}
          tooltipText={title}
          validation={validation}
          name={name}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          error={error}
          inputRef={inputRef}
          disableEdit={!isEditable}
        />
      ) : (
        <StyledClickableTitle
          title={title}
          onClickTitle={onClick}
          $fontSize={fontSize}
          showTooltip={showTooltip}
          tooltipText={title}
        />
      )}
    </Container>
  );
};

export default BreadcrumbTitle;

const StyledClickableTitle = styled(ClickableTitle)`
  &&&& {
    margin-left: 10px;
    font-size: ${({ $fontSize }) => $fontSize}px;

    ${({ onClickTitle }) => (!onClickTitle ? linkWithoutClickStyles : '')}
  }
`;

const StyledIcon = styled.div`
  svg {
    font-size: ${ICON_SIZE}px;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;

  ${props =>
    props.isEditable &&
    `
      > * {
        display: inline-flex;
    
        :last-child {
            flex-grow: 1;
        }
      }
    `}

  ${props =>
    !props.isEditable &&
    `
      margin-right: 10px;
    `}
`;
