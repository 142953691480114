/**
 * @function getEstimateColIdByTeamSkill
 *
 * get estimate col id
 *
 * @param  {Number} teamId
 * @param  {Number} skillId
 * @return {String}
 */
const getEstimateColIdByTeamSkill = (teamId, skillId) => {
  return `estimate_${teamId}_${skillId}`;
};

export default getEstimateColIdByTeamSkill;
