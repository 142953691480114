import { BET, INITIATIVE } from 'constants/projects';

const GROUP_EXCLUDE_DRAG = 'Planning Stage';
const SIMPLE_GROUP_TYPES = ['status_color', 'planningStage'];
const PARENT_GROUP_TYPES = [BET, INITIATIVE];
const CUSTOM_FIELD_PREFIX = 'custom_fields.';
const UNDEFINED_GROUP_TITLE = 'Undefined';
const UNDEFINED_GROUP_ID = 'null';
const GROUP_KEY_SEPARATOR = '-';
const MILESTONE_TYPE = 'milestone';
const DEFAULT_SELECT_COLOR_BY = 'roadmap';
const AMOUNT_TO_LIGHTHEN = -40;
const WHITE = '#ffffff';
const DAY_UNIT_TIME = 'day';
const DAYS_UNIT_TIME = 'days';
const WEEKS_UNIT_TIME = 'weeks';
const DATE_FORMATTER = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]';
const FILTERED_PROJECTS_SELECTOR_TIMEOUT = 100;
const PROJECT_UPDATE_ERROR_ID = 'project-update-error';
const PROJECT_UPDATE_ERROR_MESSAGE = 'Update failed';

export {
  GROUP_EXCLUDE_DRAG,
  SIMPLE_GROUP_TYPES,
  PARENT_GROUP_TYPES,
  CUSTOM_FIELD_PREFIX,
  UNDEFINED_GROUP_TITLE,
  UNDEFINED_GROUP_ID,
  GROUP_KEY_SEPARATOR,
  MILESTONE_TYPE,
  DEFAULT_SELECT_COLOR_BY,
  AMOUNT_TO_LIGHTHEN,
  WHITE,
  DAY_UNIT_TIME,
  DAYS_UNIT_TIME,
  WEEKS_UNIT_TIME,
  DATE_FORMATTER,
  FILTERED_PROJECTS_SELECTOR_TIMEOUT,
  PROJECT_UPDATE_ERROR_ID,
  PROJECT_UPDATE_ERROR_MESSAGE,
};
