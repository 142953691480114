import React from 'react';

import Text from 'design-system/atoms/Text';
import PageLink from 'design-system/atoms/PageLink';

const renderUserIntegrationNotFoundToast = ({ integrationName, integrationKey }) => {
  return (
    <Text size="medium" breakwords>
      Couldn&apos;t connect to {integrationName}. Please set up your Personal Access Token clicking{' '}
      <PageLink href={`/settings/integrations/${integrationKey}`} target="_blank">
        here
      </PageLink>
      .
    </Text>
  );
};

export default renderUserIntegrationNotFoundToast;
