import { UPDATE_RELEASE_NOTES_VISIBILITY, ADD_CHUNK_TO_RELEASE_NOTES } from './types';

const initialState = {
  releaseNotesVisibility: {},
  releaseNotes: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_RELEASE_NOTES_VISIBILITY:
      const { payload } = action;
      const { page, visible } = payload;

      return {
        ...state,
        releaseNotesVisibility: {
          ...state.releaseNotesVisibility,
          [page]: visible,
        },
      };
    case ADD_CHUNK_TO_RELEASE_NOTES:
      return {
        ...state,
        releaseNotes: state.releaseNotes.concat('', action.payload),
      };
    default:
      return state;
  }
}
