import { defaultTo } from 'ramda';

const defaultToEmptyObject = defaultTo({});

/**
 * @function excludeLayersFromChildren
 *
 * Adds a new condition to exclude project layers from children fields query.
 *
 * @param {Object} childrenFields
 * @param {Array} layers
 * @returns {Object}
 */
export const excludeLayersFromChildren = (childrenFields = {}, layers = []) => {
  const childrenFieldsOp = defaultToEmptyObject(childrenFields.op);

  return {
    ...childrenFields,
    op: { ...childrenFieldsOp, layer: 'notIn' },
    layer: layers,
  };
};
