import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';

import isPlainObject from 'utils/isPlainObject';
import {
  MAP_FILTERS_WITH_PROJECT,
  INGORE_FILTERS_ON_PAGE,
  AVAILABLE_PLANNING_STAGES,
  DEFAULT_PLANNING_STAGES,
} from 'constants/filters';

/**
 * When change/update something in this file, please check an update is not needed also on the following file
 * and related helpers:
 *  - dragonboat-api/server/userFilters/utils.js
 *
 * The duplication is required because for DoD integration it is required to use UserFilters
 * directly on the BE and currently is no possible to share logic between FE and BE.
 */
export default (filters, page, displayLayer, userFilters, globalSearch) => {
  const activeFilter = filters?.activeFilter && userFilters && userFilters.find(f => f.id === filters.activeFilter);
  let pageFilters = filters;

  if (activeFilter && activeFilter.meta) {
    pageFilters = { ...pageFilters, ...activeFilter.meta };
  } else {
    // if dosent have any active filter should ignore limit
    pageFilters = omit(pageFilters, ['to', 'from']);
  }

  // this will check if array of strings or objects (temp fix for owner issues)
  const _formatFilterArray = (filterList = []) => {
    const mapped = filterList.map(item => (typeof item === 'string' || typeof item === 'number') ? item : item?.id || item);

    return mapped.join();
  };

  const _mergeFiltersWithQuickFilters = _pageFilters => {
    const result = cloneDeep(_pageFilters);

    if (_pageFilters && _pageFilters.quickFilters) {
      Object.keys(_pageFilters.quickFilters).forEach(filterKey => {
        const value = _pageFilters.quickFilters[filterKey];

        if (value && value.length) {
          result[filterKey] = value;

          // should ignore filter functions for quick filter
          if (result.op && result.op[filterKey]) delete result.op[filterKey];
        }
      });
    }

    return result;
  };

  pageFilters = _mergeFiltersWithQuickFilters(pageFilters);
  let filtersForApi = {};
  const filterKeysToIgnore = ['op', 'activeFilter', 'quickFilters', 'deselect', 'showArchived', 'aplliedAt'];

  Object.keys(pageFilters).forEach(key => {
    if (filterKeysToIgnore.includes(key)) return;
    if (!pageFilters) return;

    const filterId = MAP_FILTERS_WITH_PROJECT[key] || key;
    const filterKey = pageFilters.op && pageFilters.op[key] ? `${filterId}$${pageFilters.op[key]}` : filterId;
    const filterValue = pageFilters[key];

    if (key === 'planningStages') {
      const availableStages = AVAILABLE_PLANNING_STAGES(page);
      const defaultStages = DEFAULT_PLANNING_STAGES(page);

      filtersForApi[filterKey] = filterValue ? filterValue.filter(p => availableStages.includes(p)).join() : defaultStages.join();
    } else if (filterKey.includes('isEmpty') || filterKey.includes('isNotEmpty')) {
      filtersForApi[filterKey] = true;
    } else if (filterValue) {
      if (isPlainObject(filterValue)) {
        Object.keys(filterValue).forEach(k => {
          filtersForApi[`${filterId}$${k}`] = filterValue[k];
        });
        return;
      }
      filtersForApi[filterKey] = Array.isArray(pageFilters[key]) ? _formatFilterArray(pageFilters[key]) : pageFilters[key];
    } else if (filterValue === false) {
      filtersForApi[filterKey] = false;
    }
  });

  if (globalSearch) filtersForApi = { search: globalSearch };
  // When has a global search text should not send the layer
  if (!globalSearch && displayLayer) filtersForApi.layer = displayLayer;

  return omit(filtersForApi, INGORE_FILTERS_ON_PAGE[page] || []);
};
