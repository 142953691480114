import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import queryString from 'query-string';

import AuthWrapper from 'components/AuthWrapper';
import AuthMainButton from 'components/AuthMainButton';
import GoogleSignupApprovedDeclined from 'components/GoogleSignupApprovedDeclined';

import { getDefaultRoute } from 'utils';

import { approveGoogleSignup, declineGoogleSignup } from 'store/login';

class Login extends React.Component {
  componentDidMount() {
    if (this.props.isAuthenticated && this.props.isApproved) this.props.approveGoogleSignup();
    else if (this.props.isAuthenticated) this.props.declineGoogleSignup();
  }

  render() {
    const { isAuthenticated, approvedUserName, approvedUserEmail, approvalError, currentUser } = this.props;

    const next = `${this.props.location.pathname}${this.props.location.search}`;

    if (!isAuthenticated) {
      return <Redirect to={`/login?next=${encodeURIComponent(next)}`} />;
    }

    const renderPage = () => {
      if (approvedUserName && approvedUserEmail) {
        return (
          <GoogleSignupApprovedDeclined
            {...this.props}
            goToDragonboat={() => this.props.history.push(getDefaultRoute(currentUser))}
          />
        );
      }

      if (approvalError) {
        return (
          <AuthWrapper>
            <div className={this.props.classes.successText}>
              <Typography className={this.props.classes.successTextItem} variant="body2">
                Sorry, you are not authorized to do this action
              </Typography>
            </div>
            <AuthMainButton
              onClick={() => this.props.history.push(getDefaultRoute(currentUser))}
              variant="contained"
              color="primary"
            >
              Go to dragonboat
            </AuthMainButton>
          </AuthWrapper>
        );
      }

      return <div />;
    };

    return renderPage();
  }
}

const mapStateToProps = state => {
  const { login } = state;

  return {
    isAuthenticated: login.isAuthenticated,
    approvedUserName: login.approvedUserName,
    approvedUserEmail: login.approvedUserEmail,
    approvalError: login.approvalError,
    currentUser: login.currentUser,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  approveGoogleSignup: () =>
    dispatch(
      approveGoogleSignup(
        queryString.parse(props.location.search).t,
        queryString.parse(props.location.search).readonly,
        queryString.parse(props.location.search).liteEditor,
      ),
    ),
  declineGoogleSignup: () => dispatch(declineGoogleSignup(queryString.parse(props.location.search).t)),
});

const styles = theme => ({
  textFields: {
    width: 250,
  },
  googleLoginButton: {
    marginTop: 30,
    width: 250,
  },
  resetPasswordHead: {
    marginTop: 30,
  },
  successText: {
    marginTop: 30,
  },
  successTextItem: {
    marginTop: 30,
    width: 270,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
