import { createCachedSelector } from 're-reselect';
import { defaultTo } from 'ramda';

import { filterActiveItens } from 'utils/index';
import adapter from './entityAdapter';

const defaultToInitialState = defaultTo(adapter.getInitialState());

const entitySelectors = adapter.getSelectors(state => {
  return defaultToInitialState(state.personasData);
});

export const selectPersonasData = createCachedSelector(
  entitySelectors.selectAll,
  (_, showArchived) => showArchived,
  (personasData, showArchived) => {
    return showArchived ? personasData : personasData.filter(filterActiveItens);
  },
)((state, showArchived) => `personasData-${showArchived}`);
