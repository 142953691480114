import { createSelector } from 'reselect';
import { defaultTo, prop, sum, values } from 'ramda';

import { isLoading } from 'utils/store/thunk';

import {
  FETCH_OUTCOME_ALLOCATION_DATA,
  FETCH_OUTCOME_SNAPSHOT_DATA,
  PAGE_LOAD_OUTCOME_MODULE_GOALS,
  UPDATE_OUTCOME_MODULE_FILTERS,
} from './types';
import { keyResultsAdapter, objectivesAdapter } from './adapters';

export function getGoalModeState(state) {
  return state.goalMode;
}

const getGoalModeOperationsState = state => getGoalModeState(state)?.operations;

export const getGoalModeTableProps = createSelector(
  state => prop('tableVisibleFields', getGoalModeState(state)),
  state => prop('showTreeView', getGoalModeState(state)),
  state => prop('expandedItems', getGoalModeState(state)),
  state => prop('pageSize', getGoalModeState(state)),
  (tableVisibleFields, showTreeView, expandedItems, pageSize) => ({
    tableVisibleFields,
    showTreeView,
    expandedItems,
    pageSize,
  }),
);

export const getGoalModeSearchText = createSelector(getGoalModeState, state => state.searchText);

export const getFetchOutcomeSnapshotDataIsLoading = createSelector(getGoalModeState, state => {
  return !state?.operations || isLoading(state?.operations, FETCH_OUTCOME_SNAPSHOT_DATA);
});

export const getFetchOutcomeAllocationDataIsLoading = createSelector(getGoalModeState, state => {
  return !state?.operations || isLoading(state?.operations, FETCH_OUTCOME_ALLOCATION_DATA);
});

export const getOutcomeSnapshotReportData = createSelector(getGoalModeState, state => state.outcomeSnapshotData);

export const getOutcomeSnapshotState = createSelector(getGoalModeState, state => state.outcomeSnapshotState || {});

export const getOutcomeAllocationState = createSelector(getGoalModeState, state => state.outcomeAllocationState || {});

export const getOutcomeAllocationReportData = createSelector(getGoalModeState, state => state.outcomeAllocationData);

export const selectOutcomeGoalsLoadedCounts = createSelector(getGoalModeState, state => {
  return Object.keys(state.goalsCountByLevel).reduce(
    (acc, goalEntity) => {
      const { totalsByLevel = {}, loadedCount = 0 } = defaultTo({}, state.goalsCountByLevel[goalEntity]);

      return {
        total: acc.total + sum(values(totalsByLevel)),
        loaded: acc.loaded + loadedCount,
      };
    },
    { loaded: 0, total: 0 },
  );
});

export const selectIsLoadingOutcomeGoals = createSelector(getGoalModeOperationsState, operationsState => {
  const isLoadingModuleGoals = isLoading(operationsState, PAGE_LOAD_OUTCOME_MODULE_GOALS);
  const isUpdatingFilters = isLoading(operationsState, UPDATE_OUTCOME_MODULE_FILTERS);

  return isLoadingModuleGoals || isUpdatingFilters;
});

const loadedObjectivesSelector = objectivesAdapter.getSelectors(state => state.loadedObjectives);
const loadedKeyResultsSelector = keyResultsAdapter.getSelectors(state => state.loadedKeyResults);

export const selectLoadedOutcomeObjectives = state => loadedObjectivesSelector.selectAll(getGoalModeState(state));
export const selectLoadedOutcomeKeyResults = state => loadedKeyResultsSelector.selectAll(getGoalModeState(state));
