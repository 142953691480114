import React from 'react';
import { not } from 'ramda';

import MicroChip from 'design-system/atoms/MicroChip';
import { materialColors } from 'design-system/themes/default';

const exist = Boolean;
const DEFAULT_OPTION_COLOR = materialColors.gray;

const CustomFieldDropdownCellRenderer = params => {
  const {
    colDef: { cellEditorParams, field },
    data: rowData,
  } = params;
  const { dataEnhanced, data } = cellEditorParams;

  /*
   * (Without Bet / Without Initiative) rows does not have data object
   * will ingore it on that case
   */
  if (not(rowData)) {
    return '';
  }

  const key = rowData[field];

  if (!key) return '';

  const option = exist(dataEnhanced?.[key]) ? dataEnhanced[key] : data?.[key];

  if (!option) return '';

  const hasOldDataSchema = typeof option === 'string';

  // temporary solution while we don't update all the grids to use new data schema
  if (hasOldDataSchema) return option;

  const label = option.title;
  const color = option.color || DEFAULT_OPTION_COLOR;

  return (
    <MicroChip color={materialColors[color] || color} label={label} fullWidth style={{ margin: 0, width: 'auto' }}>
      {label}
    </MicroChip>
  );
};

export default CustomFieldDropdownCellRenderer;
