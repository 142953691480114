import { getTitleColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import { GroupCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import CapacityCellRenderer from 'routes/Settings/Users/components/AgGridCapacityRenderer/CapacityCellRenderer';
import TeamCellRenderer from 'routes/Settings/Users/components/AgGridColumnTeam/TeamCellRenderer';

const firstNameColumnDef = {
  ...getTitleColumnDef(),
  field: 'first_name',
  headerName: 'First Name',
  editable: false,
  width: 120,
};

const lastNameColumnDef = {
  ...getTitleColumnDef(),
  field: 'last_name',
  headerName: 'Last Name',
  editable: false,
  width: 120,
};

const roleColumnDef = {
  field: 'role_id',
  headerName: 'User Type',
  filter: false,
  enableRowGroup: true,
  comparator: stringComparator,
  width: 100,
};

const capacityColumnDef = {
  field: 'capacity',
  headerName: 'Capacity',
  filter: false,
  cellRenderer: CapacityCellRenderer,
  keyCreator: ({ value }) => (!value || value === 'false' ? 'No' : 'Yes'),
  enableRowGroup: true,
  comparator: stringComparator,
  width: 100,
};

const statusColumnDef = {
  field: 'status',
  headerName: 'Status',
  filter: false,
  enableRowGroup: true,
  comparator: stringComparator,
  width: 100,
};

const skillColumnDef = {
  field: 'skill.title',
  headerName: 'skill',
  cellRenderer: GroupCellRenderer('skill'),
  valueSetter: params => {
    params.data.skill = {
      title: params.newValue ?? '',
    };

    return true;
  },
  breakwords: true,
  comparator: stringComparator,
};

const teamColumnDef = {
  field: 'team.title',
  headerName: 'team',
  filter: false,
  cellRenderer: TeamCellRenderer,
  breakwords: true,
  enableRowGroup: false,
};

export {
  firstNameColumnDef,
  lastNameColumnDef,
  roleColumnDef,
  capacityColumnDef,
  statusColumnDef,
  skillColumnDef,
  teamColumnDef,
};
