import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import SignupFormComponent from './SignupForm/SignupFormComponent';
import Button from 'design-system/atoms/Button/index';

import PublicPageTemplateComponent from 'src/design-system/templates/PublicPageTemplate/PublicPageTemplateComponent';

export default ({ handleSubmit, functionRoles, signUpServerError }) => {
  const login = (
    <LoginCorner>
      <p>Already have an account?</p>
      <LoginBtn to="/login">
        <Button>Log In</Button>
      </LoginBtn>
    </LoginCorner>
  );

  return (
    <PublicPageTemplateComponent headerRightContent={login}>
      <SignupFormComponent handleSubmitProp={handleSubmit} roles={functionRoles} signUpServerError={signUpServerError} />
    </PublicPageTemplateComponent>
  );
};

const LoginBtn = styled(Link)`
  &&&& {
    text-decoration: none;
    button {
      margin-left: 20px;
      border-radius: 4px;
      background-color: #fff;
      color: #000000;
      font-size: 14px;
      width: 84px;
      height: 43px;
      border-color: #000000;

      &:hover {
        color: #fff;
        background-color: #000000;
      }
    }
  }
`;

const LoginCorner = styled.div`
  float: right;
  display: flex;
  position: relative;
  margin-right: 20px;
  margin-top: 12px;
  font-size: 14px;
`;
