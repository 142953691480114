import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { materialColorsAlt } from 'design-system/themes/default';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import isTruthyOrZero from 'utils/isTruthyOrZero';
import { areAllValuesInPercentages } from 'utils/metricValues';
import { getTimeUnit, getTicksInRange, DATE_FORMAT } from 'utils/chartsDateTicks';

import { TARGET, ACTUAL } from 'features/MetricsDialog/constants';
import useMetricAllocationForChart from 'features/MetricsAllocation/hooks/useMetricAllocationForChart';
import {
  getFormattedMetricValueForChart,
  getMetricValuesDates,
  createFakeTargetValuesIfNeeded,
  createItemForChart,
  formatDate,
} from '../helpers';

const DEFAULT_ITEMS = [TARGET, ACTUAL];

const useMetricValuesForLineChart = ({
  metricId,
  metricValues,
  startDate,
  endDate,
  inView,
  moarPreferences,
  displayActual,
  displayTarget,
}) => {
  const { canView } = usePermissions();

  const [chartItems, setChartItems] = useState([DEFAULT_ITEMS]);

  const getItemsByType = (type, data) => {
    return data.filter(item => item.type === type);
  };

  const useValuesAsPercentages = useMemo(
    () => areAllValuesInPercentages(metricValues.map(metricValue => metricValue.value)),
    [metricValues],
  );

  const dates = useMemo(() => getMetricValuesDates(metricValues), [metricValues]);

  const chartTimeUnit = getTimeUnit(startDate, endDate);
  const { datesForChart, dateToShowUndefinedBar } = useMemo(() => {
    const dateToShowUndefinedBar = moment(startDate).subtract(1, chartTimeUnit);

    return {
      dateToShowUndefinedBar,
      datesForChart: getTicksInRange(startDate, endDate, chartTimeUnit),
    };
  }, [startDate, endDate, chartTimeUnit]);

  useEffect(() => {
    if (metricValues.length) {
      const mappedValues = metricValues.map(val => {
        const dateKey = formatDate(val.date);
        const value = getFormattedMetricValueForChart(val.value, !useValuesAsPercentages);

        return {
          ...val,
          [dateKey]: isTruthyOrZero(value) ? Number(value) : null,
        };
      });

      const targetItems = getItemsByType('target', mappedValues);
      const actualItems = getItemsByType('actual', mappedValues);

      const targetObj = { type: TARGET.type };
      const actualObj = { type: ACTUAL.type };

      targetItems.forEach(item => {
        const key = formatDate(item.date);

        if (isTruthyOrZero(item[key])) {
          targetObj[key] = item[key];
        }
      });

      actualItems.forEach(item => {
        const key = formatDate(item.date);

        if (isTruthyOrZero(item[key])) {
          actualObj[key] = item[key];
        }
      });

      setChartItems([targetObj, actualObj]);
    }
  }, [metricValues]);

  const getValuesByDate = (dates, type) => {
    const items = chartItems.find(items => items.type === type);
    let values = dates.filter(date => items && isTruthyOrZero(items[date])).map(date => createItemForChart(items[date], date));

    // For Target values we might need to create fake metric values to create a flat line on the chart
    if (type === TARGET.type) {
      const hasDatesForChart = datesForChart && datesForChart.length > 0;

      const start = hasDatesForChart ? datesForChart[0] : startDate;
      const end = hasDatesForChart ? datesForChart[datesForChart.length - 1] : endDate;

      values = createFakeTargetValuesIfNeeded(values, start, end);
    }

    return values;
  };

  const hasMetricsAllocation = canView(PERMISSION_FEATURES.metricMoar);

  const { chartData: allocationChartData, isLoading: isLoadingAllocationData } = useMetricAllocationForChart(
    metricId,
    hasMetricsAllocation && inView && moarPreferences?.show,
    startDate,
    endDate,
    chartTimeUnit,
    dateToShowUndefinedBar,
    moarPreferences,
  );
  const showAllocationLoadingIndicator = inView && moarPreferences?.show && isLoadingAllocationData;

  if (allocationChartData.hasUndefinedData) {
    datesForChart.unshift(dateToShowUndefinedBar.format(DATE_FORMAT));
  }

  const metricDatasets = [
    {
      id: TARGET.type,
      label: TARGET.type,
      data: getValuesByDate(dates, TARGET.type),
      fill: false,
      borderColor: materialColorsAlt.lightBlue,
      backgroundColor: materialColorsAlt.lightBlue,
      borderDash: [5, 5],
      tension: 0,
      spanGaps: true,
      showLine: true,
      type: 'line',
    },
    {
      id: ACTUAL.type,
      label: ACTUAL.type,
      data: getValuesByDate(dates, ACTUAL.type),
      fill: false,
      borderColor: materialColorsAlt.lightGreen,
      backgroundColor: materialColorsAlt.lightGreen,
      tension: 0,
      spanGaps: true,
      showLine: true,
      type: 'line',
    },
  ];

  const filteredMetricDatasets = useMemo(() => {
    return metricDatasets.filter(dataset => {
      return (dataset.id === ACTUAL.type && displayActual !== false) || (dataset.id === TARGET.type && displayTarget !== false);
    });
  }, [metricDatasets, displayActual, displayTarget]);

  const chartData = {
    labels: datesForChart,
    datasets: [...(moarPreferences?.show ? allocationChartData.datasets : []), ...filteredMetricDatasets],
  };

  return {
    chartData,
    dates,
    formatDate,
    chartItems,
    useValuesAsPercentages,
    datesForChart,
    chartTimeUnit,
    isLoadingAllocationData: showAllocationLoadingIndicator,
  };
};

export default useMetricValuesForLineChart;
