import React, { useMemo, useCallback } from 'react';
import { PERMISSION_RESOURCES, SYSTEM_FIELD } from '@dragonboat/permissions';

import { THEMES } from 'store/grids/constants';
import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SettingsDialogs from 'components/SettingsDialogs';
import { BaseGrid } from 'containers/Grids';
import {
  useSettingsActions,
  useSettingsGridSelection,
  useSettingsGridProps,
  useSettingsGridEventHandlers,
  useSettingsGridColumns,
} from 'containers/Grids/SettingsGrid/hooks';

import useThemes from './hooks/useThemes';
import useThemesGridColumns from './hooks/useThemesGridColumns';
import useForceVisibleColumns from 'hooks/useForceVisibleColumns';
import usePermissions from 'hooks/permissions/usePermissions';

const THEME_KEY = 'theme';
const TITLE_FIELD = 'title';

const Themes = ({
  showActionBar = true,
  systemFields,
  updateSystemFieldsName,
  hideMetadataRoadmaps,
  forceVisibleFields,
  customHeight,
  hideArchivedItems,
}) => {
  const {
    themes,
    addThemeWithoutSave,
    mergeThemes,
    createThemes,
    createTheme,
    updateThemes,
    updateThemeById,
    deleteTheme,
    bulkDeleteThemes,
    removeUnsavedThemes,
    bulkDeleteThemeRoadmaps,
    createThemeRoadmap,
    deleteThemeRoadmap,
    switchThemesRowOrder,
  } = useThemes(hideArchivedItems);

  // Specific column definition for themes
  const themesGridColumnDefs = useThemesGridColumns({
    roadmapColumn: {
      createThemeRoadmap,
      deleteThemeRoadmap,
      themes,
      bulkDeleteThemeRoadmaps,
      hideMetadataRoadmaps,
    },
  });

  const handleRowDrag = useCallback(
    (currentNode, lastOverNode, { position }) => switchThemesRowOrder(currentNode.data.id, lastOverNode.data.id, null, position),
    [switchThemesRowOrder],
  );

  // Settings common agGrid configuration

  const {
    selectionMode,
    selectedItems,
    enableSelectionMode,
    disableSelectionMode,
    updateSelectedOnAgGridInit,
  } = useSettingsGridSelection(THEMES, { actions: { remove: removeUnsavedThemes } });

  const {
    itemToDelete,
    setItemToDelete,
    deleteItem,
    showBulkDeleteDialog,
    setShowBulkDeleteDialog,
    deleteProgress,
    bulkDeleteItems,
    mergeLightboxVisible,
    showMergeLightbox,
    hideMergeLightbox,
  } = useSettingsActions({
    settingType: THEMES,
    selectedItems,
    disableSelectionMode,
    deleteById: deleteTheme,
    bulkDelete: bulkDeleteThemes,
  });

  const settingsGridProps = useSettingsGridProps({
    bulkCreate: createThemes,
    bulkUpdate: updateThemes,
  });

  const { canUpdate, canCreate, canMerge, canView } = usePermissions();
  const canUpdateOrCreate = canUpdate(PERMISSION_RESOURCES.theme) || canCreate(PERMISSION_RESOURCES.theme);

  const permissions = {
    allowActions: canUpdateOrCreate,
    canAdd: canCreate(PERMISSION_RESOURCES.theme),
    rename: {
      canUpdate: showActionBar && canUpdate(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.theme }),
      canView: showActionBar && canView(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.theme }),
    },
    canMerge: showActionBar && canMerge(PERMISSION_RESOURCES.theme),
    canBulkDelete: showActionBar && canUpdateOrCreate,
    canDragRows: showActionBar && canUpdateOrCreate,
    
    canUpdate: useCallback(data => canUpdate(PERMISSION_RESOURCES.theme, { data }), [canUpdate]),
  };

  const settingsGridColumns = useSettingsGridColumns({
    allowActions: permissions.allowActions,
    canUpdate: permissions.canUpdate,
    selectionMode,
    setItemToDelete,
    items: themes,
    canDragRows: permissions.canDragRows,
  });

  const settingsGridEventHandlers = useSettingsGridEventHandlers({
    settingType: THEMES,
    items: themes,
    focusField: TITLE_FIELD,
    actions: {
      update: updateThemeById,
      save: createTheme,
      remove: removeUnsavedThemes,
      drag: handleRowDrag,
    },
  });

  const visibleColumns = useForceVisibleColumns(themesGridColumnDefs, forceVisibleFields);

  // Join settings and tags column definitions
  const columnDefs = useMemo(
    () => [...settingsGridColumns.beforeColumnDefs, ...visibleColumns, ...settingsGridColumns.afterColumnDefs],
    [settingsGridColumns.beforeColumnDefs, settingsGridColumns.afterColumnDefs, visibleColumns],
  );

  return (
    <>
      {permissions.allowActions && (
        <SettingsActionsToolbar
          settingKey={THEME_KEY}
          settingType={THEMES}
          systemFields={systemFields}
          updateSystemFieldsName={updateSystemFieldsName}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          onEnableSelection={enableSelectionMode}
          onCancelSelection={disableSelectionMode}
          setShowBulkDeleteDialog={setShowBulkDeleteDialog}
          showMergeLightbox={showMergeLightbox}
          add={addThemeWithoutSave}
          renamePermissions={permissions.rename}
          canMerge={permissions.canMerge}
          canBulkDelete={permissions.canBulkDelete}
          hideAddButton={!permissions.canAdd}
        />
      )}
      <BaseGrid
        onGridReady={updateSelectedOnAgGridInit}
        rowData={themes}
        columnDefs={columnDefs}
        defaultColDef={settingsGridColumns.defaultColDef}
        {...settingsGridProps}
        {...settingsGridEventHandlers}
        height={customHeight ?? settingsGridProps.height}
      />
      <SettingsDialogs
        settingKey={THEME_KEY}
        settingType={THEMES}
        showBulkDeleteDialog={showBulkDeleteDialog}
        setShowBulkDeleteDialog={setShowBulkDeleteDialog}
        deleteProgress={deleteProgress}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        deleteItem={deleteItem}
        bulkDelete={bulkDeleteItems}
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
        mergeLightboxVisible={mergeLightboxVisible}
        hideMergeLightbox={hideMergeLightbox}
        merge={mergeThemes}
      />
    </>
  );
};

export default Themes;
