import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('TEST_JIRA_CONNECTION'),
  ...getThunkActionTypes('JIRA_DISCONNECT'),
  ...getThunkActionTypes('CREATE_PROJECTS_JIRAS'),
  ...getThunkActionTypes('SYNC_PROJECT_FROM_JIRA'),
  ...getThunkActionTypes('UPDATE_JIRA_ISSUE_FROM_PROJECT'),
  ...getThunkActionTypes('SYNC_PROJECTS_JIRA_STORIES'),
  ...getThunkActionTypes('UPDATE_JIRA_CONFIG_MISC'),
  ...getThunkActionTypes('JIRA_TICKET_DETAIL'),
  ...getThunkActionTypes('JIRA_UNLINK'),
  ...getThunkActionTypes('FETCH_JIRA_PROJECTS'),
  ...getThunkActionTypes('CREATE_JIRA_CONFIG'),
  ...getThunkActionTypes('UPDATE_JIRA_CONFIG'),
  ...getThunkActionTypes('FETCH_JIRA_FIELDS'),
  ...getThunkActionTypes('SYNC_ORG_PROJECTS_FROM_JIRA'),
  ...getThunkActionTypes('UPDATE_JIRA_PROJECTS_KEY'),
  ...getThunkActionTypes('GET_JIRA_PERMISSIONS'),
};

export const {
  TEST_JIRA_CONNECTION,
  TEST_JIRA_CONNECTION_PENDING,
  TEST_JIRA_CONNECTION_FULFILLED,
  TEST_JIRA_CONNECTION_REJECTED,
  JIRA_DISCONNECT,
  JIRA_DISCONNECT_FULFILLED,
  CREATE_PROJECTS_JIRAS,
  CREATE_PROJECTS_JIRAS_FULFILLED,
  SYNC_PROJECT_FROM_JIRA,
  SYNC_PROJECT_FROM_JIRA_PENDING,
  SYNC_PROJECT_FROM_JIRA_FULFILLED,
  SYNC_PROJECT_FROM_JIRA_REJECTED,
  UPDATE_JIRA_ISSUE_FROM_PROJECT,
  UPDATE_JIRA_ISSUE_FROM_PROJECT_PENDING,
  UPDATE_JIRA_ISSUE_FROM_PROJECT_FULFILLED,
  UPDATE_JIRA_ISSUE_FROM_PROJECT_REJECTED,
  SYNC_PROJECTS_JIRA_STORIES,
  SYNC_PROJECTS_JIRA_STORIES_PENDING,
  SYNC_PROJECTS_JIRA_STORIES_FULFILLED,
  UPDATE_JIRA_CONFIG_MISC,
  UPDATE_JIRA_CONFIG_MISC_FULFILLED,
  JIRA_TICKET_DETAIL,
  JIRA_TICKET_DETAIL_PENDING,
  JIRA_TICKET_DETAIL_FULFILLED,
  JIRA_TICKET_DETAIL_REJECTED,
  JIRA_UNLINK,
  JIRA_UNLINK_FULFILLED,
  FETCH_JIRA_PROJECTS,
  CREATE_JIRA_CONFIG,
  CREATE_JIRA_CONFIG_FULFILLED,
  UPDATE_JIRA_CONFIG,
  UPDATE_JIRA_CONFIG_FULFILLED,
  FETCH_JIRA_FIELDS,
  FETCH_JIRA_FIELDS_PENDING,
  FETCH_JIRA_FIELDS_FULFILLED,
  SYNC_ORG_PROJECTS_FROM_JIRA,
  SYNC_ORG_PROJECTS_FROM_JIRA_PENDING,
  SYNC_ORG_PROJECTS_FROM_JIRA_FULFILLED,
  UPDATE_JIRA_PROJECTS_KEY,
  GET_JIRA_PERMISSIONS,
  GET_JIRA_PERMISSIONS_PENDING,
  GET_JIRA_PERMISSIONS_FULFILLED,
  GET_JIRA_PERMISSIONS_REJECTED,
  GET_JIRA_PERMISSIONS_RESET,
} = actionTypes;
