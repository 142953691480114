/**
 * LabelledButton Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export default class LabelledButton extends PureComponent {
  render() {
    return (
      <Wrapper>
        <Button {...this.props}>
          {this.props.children}
        </Button>
        <Label>
          ({this.props.label})
        </Label>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 0 12px;
`;

const Label = styled.div`
  font-size: 12px;
`;
