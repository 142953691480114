import reduceReducers from 'reduce-reducers';
import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import { viewRecurringNotificationsAdapter } from './helpers/adapters';

import {
  FETCH_EXISTING_RECURRING_NOTIFICATIONS_FOR_USER_VIEW,
  FETCH_EXISTING_RECURRING_NOTIFICATIONS_FOR_USER_VIEW_FULFILLED,
  CREATE_USER_VIEW_RECURRING_NOTIFICATION,
  CREATE_USER_VIEW_RECURRING_NOTIFICATION_FULFILLED,
  UPDATE_USER_VIEW_RECURRING_NOTIFICATION,
  UPDATE_USER_VIEW_RECURRING_NOTIFICATION_FULFILLED,
  DELETE_USER_VIEW_RECURRING_NOTIFICATION,
  FETCH_EXISTING_RECURRING_NOTIFICATIONS_FOR_USER_VIEW_PENDING,
  DELETE_USER_VIEW_RECURRING_NOTIFICATION_FULFILLED,
} from './types';
import { defaultTo } from 'ramda';

const { initialState: operationsInitialState, reducers: operationsReducers } = getThunksInitialStateAndReducers([
  FETCH_EXISTING_RECURRING_NOTIFICATIONS_FOR_USER_VIEW,
  CREATE_USER_VIEW_RECURRING_NOTIFICATION,
  UPDATE_USER_VIEW_RECURRING_NOTIFICATION,
  DELETE_USER_VIEW_RECURRING_NOTIFICATION,
]);

const initialState = {
  ...operationsInitialState,
  viewRecurringNotificationsByUserView: {},
};

const defaultToInitialState = defaultTo(viewRecurringNotificationsAdapter.getInitialState());

const ViewsRecurringNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EXISTING_RECURRING_NOTIFICATIONS_FOR_USER_VIEW_PENDING:
      return {
        ...state,
        viewRecurringNotificationsByUserView: {
          ...state.viewRecurringNotificationsByUserView,
          [action.meta.userViewId]: viewRecurringNotificationsAdapter.removeAll(
            defaultToInitialState(state.viewRecurringNotificationsByUserView[action.meta.userViewId]),
          ),
        },
      };
    case FETCH_EXISTING_RECURRING_NOTIFICATIONS_FOR_USER_VIEW_FULFILLED:
      return {
        ...state,
        viewRecurringNotificationsByUserView: {
          ...state.viewRecurringNotificationsByUserView,
          [action.meta.userViewId]: viewRecurringNotificationsAdapter.addMany(
            defaultToInitialState(state.viewRecurringNotificationsByUserView[action.meta.userViewId]),
            action.payload,
          ),
        },
      };
    case CREATE_USER_VIEW_RECURRING_NOTIFICATION_FULFILLED:
    case UPDATE_USER_VIEW_RECURRING_NOTIFICATION_FULFILLED:
      return {
        ...state,
        viewRecurringNotificationsByUserView: {
          ...state.viewRecurringNotificationsByUserView,
          [action.meta.userViewId]: viewRecurringNotificationsAdapter.upsertOne(
            defaultToInitialState(state.viewRecurringNotificationsByUserView[action.meta.userViewId]),
            action.payload,
          ),
        },
      };
    case DELETE_USER_VIEW_RECURRING_NOTIFICATION_FULFILLED:
      return {
        ...state,
        viewRecurringNotificationsByUserView: {
          ...state.viewRecurringNotificationsByUserView,
          [action.meta.userViewId]: viewRecurringNotificationsAdapter.removeOne(
            defaultToInitialState(state.viewRecurringNotificationsByUserView[action.meta.userViewId]),
            action.meta.deletedId,
          ),
        },
      };
    default:
      return state;
  }
};

const reducer = reduceReducers(initialState, ViewsRecurringNotificationsReducer, ...operationsReducers);

export default reducer;
