import React from 'react';
import { withTheme } from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

function SvgComponent(props) {
  const fill = props.fill || props.theme.palette.text.primary;

  return (
    <SvgIcon fontSize="inherit" width="25.02" height="19.021" viewBox="0 0 25.02 19.021" {...props}>
      <g id="icon-staffing" transform="translate(-38.18 -776.521)" opacity="0.6">
        <path id="Path_15609" data-name="Path 15609" d="M62.2,778.541H53.018a1,1,0,0,1,0-2H62.2a1,1,0,0,1,0,2Z" fill={fill} />
        <path id="Path_15610" data-name="Path 15610" d="M62.2,784.542H60.531a1,1,0,0,1,0-2H62.2a1,1,0,0,1,0,2Z" fill={fill} />
        <path id="Path_15611" data-name="Path 15611" d="M62.2,790.54H59.644a1,1,0,0,1,0-2H62.2a1,1,0,0,1,0,2Z" fill={fill} />
        <path id="Path_15612" data-name="Path 15612" d="M49.6,778.521H39.18a1,1,0,0,1,0-2H49.6a1,1,0,0,1,0,2Z" fill={fill} />
        <path id="Path_15613" data-name="Path 15613" d="M57.707,784.547H51a1,1,0,0,1,0-2h6.706a1,1,0,0,1,0,2Z" fill={fill} />
        <path 
          id="Path_15614"
          data-name="Path 15614"
          d="M50.761,790.54H49.743a1,1,0,0,1,0-2h1.018a1,1,0,0,1,0,2Z"
          fill={fill}
        />
        <path 
          id="Path_15615"
          data-name="Path 15615"
          d="M42.956,789.276a3.507,3.507,0,1,1,3.506-3.507A3.511,3.511,0,0,1,42.956,
          789.276Zm0-5.014a1.507,1.507,0,1,0,1.506,1.507A1.509,1.509,0,0,0,42.956,784.262Z"
          fill={fill}
        />
        <path 
          id="Path_15616"
          data-name="Path 15616"
          d="M46.716,795.542a1,1,0,0,1-1-1v-1.253a1.509,1.509,0,0,0-1.507-1.507H41.7a1.509,
          1.509,0,0,0-1.507,1.507v1.253a1,1,0,0,1-2,0v-1.253a3.511,3.511,0,0,1,3.507-3.507h2.506a3.511,3.511,
          0,0,1,3.507,3.507v1.253A1,1,0,0,1,46.716,795.542Z"
          fill={fill}
        />
        <path 
          id="Path_15617" 
          data-name="Path 15617"
          d="M56.848,790.531H54.291a1,1,0,0,1,0-2h2.557a1,1,0,0,1,0,2Z"
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
}

export default withTheme(SvgComponent);
