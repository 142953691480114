import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ChartWrapper from 'src/components/ReactChart/utils/ChartWrapper';
import Segment from 'design-system/atoms/Segment/index';

import BarChart from 'components/ReactChart/BarChart';
import { isNil, pluck } from 'ramda';
import usePageFilters from 'hooks/filters/usePageFilters';
import { DASHBOARDS_PAGE } from 'constants/filters/pages';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import { useSelector } from 'react-redux';
import { getUserFilters } from 'store/filters/selectors';
import axios from 'axios';
import TimelineLightbox from 'features/TimelineLightbox';
import { getOrganizationSystemFieldsNames } from 'store/organization';
import getSystemFieldName from 'utils/getSystemFieldName';

export default ({ label, wrapper: Wrapper, disableClick, minHeight, actionButtons }) => {
  const chartRef = useRef();
  const [openTimeline, setOpenTimeline] = useState();

  const { pageFilters: filters, displayLayer } = usePageFilters(DASHBOARDS_PAGE);
  const userFilters = useSelector(getUserFilters);
  const hasBet = useSelector(state => state.organization.organization.has_bet);
  const systemFields = useSelector(state => getOrganizationSystemFieldsNames(state));

  const [reportData, setReportData] = useState([]);

  const fetchData = async () => {
    const filtersBody = compileFiltersBody(filters, userFilters, hasBet, DASHBOARDS_PAGE, displayLayer, null);

    const { data } = await axios.post('/api/v1/reports/portfolio/delivery-risk/delay-totals', { projectsFilter: filtersBody });

    setReportData(data);
  };

  useEffect(() => {
    fetchData();
  }, [filters, userFilters, hasBet, displayLayer]);

  const labelFieldMap = [
    { key: 'delay_1_week_or_less', label: 'Delay 1 week or less' },
    { key: 'delay_1_2_weeks', label: 'Delay 1-2 weeks' },
    { key: 'delay_2_4_weeks', label: 'Delay 2-4 weeks' },
    { key: 'delay_over_4_weeks', label: 'Delay over 4 weeks' },
  ];

  const preparedDataForChart = useMemo(() => {
    const withoutDependenciesDataset = labelFieldMap.map(({ key, label }) => ({ x: label, y: reportData[key] }));

    const withDependenciesDataset = labelFieldMap.map(({ key, label }) => ({
      x: label,
      y: reportData[`${key}_considering_dependency`],
    }));

    return { withDependenciesDataset, withoutDependenciesDataset };
  }, [reportData]);

  const data = {
    labels: pluck('label', labelFieldMap),
    datasets: [
      {
        label: 'Total delayed items',
        data: preparedDataForChart.withoutDependenciesDataset,
        borderColor: '#aff1ff',
        backgroundColor: '#aff1ff',
      },
      {
        label: 'Total delayed items with dependencies',
        data: preparedDataForChart.withDependenciesDataset,
        borderColor: '#e6d5f9ff',
        backgroundColor: '#e6d5f9ff',
      },
    ],
  };

  const handleClickOnChartSlice = useCallback(
    e => {
      if (isNil(chartRef?.current)) {
        return;
      }

      const activePoints = chartRef.current.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);

      if (!activePoints.length) {
        return;
      }

      openTimeline();
    },
    [chartRef, preparedDataForChart],
  );

  return (
    <Wrapper>
      <Segment bordered label={label} actionButtons={actionButtons}>
        <ChartWrapper height={minHeight}>
          <BarChart
            setRef={ref => (chartRef.current = ref)}
            data={data}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              animation: {
                duration: 0,
              },
              onClick: disableClick ? null : handleClickOnChartSlice,
            }}
          />
        </ChartWrapper>
      </Segment>
      <TimelineLightbox
        onOpen={f => setOpenTimeline(() => f)}
        entityGroup={{ key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) }}
        groupedBy={{}}
        lazyLoadProjects
        pageId={DASHBOARDS_PAGE}
        groupBySelection={{ key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) }}
      />
    </Wrapper>
  );
};
