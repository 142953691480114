// import React from 'react';
// import { toast } from 'react-toastify';

// function buildUndoMessage(creates, updates) {
//   if (creates && updates) {
//     return `${creates} row${creates === 1 ? '' : 's'}
// have beend created and ${updates} row${updates === 1 ? '' : 's'} have been updated.`;
//   } else if (creates) {
//     return `${creates} row${creates === 1 ? '' : 's'} have beend created.`;
//   } else if (updates) {
//     return `${updates} row${updates === 1 ? '' : 's'} have been updated.`;
//   }
// }

export default (bulkCreate, bulkUpdate) => {
  return async (changesToDo, socketRoom) => {
    if (
      !changesToDo ||
      ((!changesToDo.create || !changesToDo.create.length) && (!changesToDo.update || !changesToDo.update.length))
    ) {
      return;
    }

    const bulks = [];

    if (changesToDo.create && changesToDo.create.length) {
      bulks.push(bulkCreate(changesToDo.create, socketRoom));
    } else if (changesToDo.update && changesToDo.update.length) {
      bulks.push(bulkUpdate(changesToDo.update[0], socketRoom));
    }

    return Promise.all(bulks);
    // .then(() => {
    // toast(() => {
    //   return (
    //     <div>
    //       {
    //         buildUndoMessage(changesToDo.create.length, changesToDo.update.length)
    //       }
    //     </div>);
    // }, { autoClose: 10000 });
    // });
  };
};
