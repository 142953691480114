import { cloneDeepWith } from 'lodash';
import { SET_DRAGONBOT_HISTORY_CACHE_EXPIRY, SET_DRAGONBOT_LAST_PROMPT_RESULT, TOGGLE_DRAGONBOT_VISIBILITY } from './types';
import moment from 'moment';
import { DRAGONBOT_HISTORY_CACHE_STORAGE_KEY, USER_PROMPT_CHAT_STEP_ID } from './constants';
import { stringify } from 'flatted/cjs';

export const setLastDragonbotPromptResult = dragonbotPromptResult => ({
  type: SET_DRAGONBOT_LAST_PROMPT_RESULT,
  payload: dragonbotPromptResult,
});

export const openDragonbot = () => ({
  type: TOGGLE_DRAGONBOT_VISIBILITY,
  payload: true,
});

export const hideDragonbot = () => ({
  type: TOGGLE_DRAGONBOT_VISIBILITY,
  payload: false,
});

export const storeDragonbotConversationHistory = botConversationData => {
  const valuesToStore = cloneDeepWith(botConversationData, (_, key, step) => {
    if(key === 'component') {
      // If react component only store the ID of the step component not the rendered component itself
      return step.id;
    }
  });

  if (valuesToStore.currentStep.trigger === USER_PROMPT_CHAT_STEP_ID) {
    // To ensure the chat component requests user input after cache is loaded
    valuesToStore.currentStep.user = true;
  }

  localStorage.setItem(DRAGONBOT_HISTORY_CACHE_STORAGE_KEY, stringify(valuesToStore));

  return {
    type: SET_DRAGONBOT_HISTORY_CACHE_EXPIRY,
    payload: {
      expiresIn: moment().add('1', 'day').toISOString(),
    },
  };
};
