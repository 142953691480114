import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import DndLayout from 'design-system/molecules/DndLayout/index';
import DndMenuList from 'design-system/molecules/DndMenuList/index';

import styled from 'styled-components';
import MuiPaper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';

import useOpenItems from './hooks/useOpenItems';

const exist = Boolean;

const DEFAULT_ITEM_HEIGHT = 30;
const DEFAULT_WIDTH = 200;

const DndList = ({
  items = [],
  selectedItems = {},
  draggable = false,
  checkable = false,
  colorable = false,
  fontSize = '',
  isSubTree = false,
  renderSearch,
  renderClear,
  renderShowArchived,
  onSelectItem,
  onClickItem,
  onChangeOrder,
  onChangeColor,
  onGroupOpenClose,
  onSeparatorOpenClose,
  showIndeterminate = false,
  textColor = '',
  width = DEFAULT_WIDTH,
  checkedByDefault,
  styleAsAutocomplete,
  showTooltip = false,
  titleLabel,
  renderAddOption,
  itemRightActions = [],
  renderCustomItem,
  renderCustomEmptyOptions,
  wrapperStyle = {},
  showActionsOnHover,
  itemHeight = DEFAULT_ITEM_HEIGHT,
  registerExpandAllCallback,
  registerCollapseAllCallback,
  avoidVirtualization = false,
  focusedRef,
  onSelectItemWithKeyboard,
  rootHeight,
}) => {
  const { openItems, onOpen, mainListRef } = useOpenItems({
    items,
    registerExpandAllCallback,
    registerCollapseAllCallback,
  });

  const _handleSelectItem = (...params) => {
    onSelectItem && onSelectItem(...params);
  };

  const _handleClickItem = item => {
    onClickItem && onClickItem(item);
  };

  const _handleChangeColor = (...params) => {
    onChangeColor && onChangeColor(...params);
  };

  const _handleChangeOrder = ({ destination, draggableId, source }) => {
    const stringIds = draggableId.split('draggable-null-')[1];
    const draggableIds = stringIds.split('-');

    draggableIds.pop();

    onChangeOrder && onChangeOrder(draggableIds, source.index, destination.index);
  };

  const useHierarchy = useMemo(() => items.some(i => exist(i.children?.length)), [items]);

  return (
    <Paper style={wrapperStyle} width={width} $styleAsAutocomplete={styleAsAutocomplete}>
      {titleLabel && <Label>{titleLabel}</Label>}
      <DndLayout
        color={textColor}
        draggable={draggable}
        fontSize={fontSize}
        onDragEnd={_handleChangeOrder}
        renderSearch={renderSearch}
        renderClear={renderClear}
        renderShowArchived={renderShowArchived}
        styleAsAutocomplete={styleAsAutocomplete}
        renderAddOption={renderAddOption}
      >
        <DndMenuList
          rootList
          items={items}
          parentId="null"
          selectedItems={selectedItems}
          openItems={openItems}
          draggable={draggable}
          checkable={checkable}
          colorable={colorable}
          isSubTree={isSubTree}
          onSelectItem={_handleSelectItem}
          onClickItem={_handleClickItem}
          onChangeColor={_handleChangeColor}
          onGroupOpenClose={onGroupOpenClose}
          onSeparatorOpenClose={onSeparatorOpenClose}
          textColor={textColor}
          showIndeterminate={showIndeterminate}
          checkedByDefault={checkedByDefault}
          showTooltip={showTooltip}
          itemRightActions={itemRightActions}
          renderCustomItem={renderCustomItem}
          renderCustomEmptyOptions={renderCustomEmptyOptions}
          useHierarchy={useHierarchy}
          showActionsOnHover={showActionsOnHover}
          onOpen={onOpen}
          parentListRef={mainListRef}
          itemHeight={itemHeight}
          rootHeight={rootHeight}
          avoidVirtualization={avoidVirtualization}
          focusedRef={focusedRef}
          onSelectItemWithKeyboard={onSelectItemWithKeyboard}
        />
      </DndLayout>
    </Paper>
  );
};

const Paper = styled(MuiPaper)`
  &&&& {
    ${({ width }) => (width ? `width: ${Math.max(width, DEFAULT_WIDTH)}px;` : 'min-width: 200px; max-width: 250px;')}

    ${({ $styleAsAutocomplete }) => ($styleAsAutocomplete ? 'border-radius: 0 0 8px 8px;' : '')}
  }
`;

const Label = styled(Typography)`
  &&&& {
    font-weight: 500;
    padding: 15px 15px 0px;
  }
`;

const ItemPropTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  color: PropTypes.string,
};

ItemPropTypes.children = PropTypes.arrayOf(PropTypes.shape(ItemPropTypes));

DndList.propTypes = {
  draggable: PropTypes.bool,
  checkable: PropTypes.bool,
  colorable: PropTypes.bool,
  fontSize: PropTypes.number,
  isSubTree: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape(ItemPropTypes)).isRequired,
  selectedItems: PropTypes.object,
  renderSearch: PropTypes.func,
  renderClear: PropTypes.func,
  onSelectItem: PropTypes.func.isRequired,
  onChangeOrder: PropTypes.func,
  onChangeColor: PropTypes.func,
  textColor: PropTypes.string,
  width: PropTypes.number,
};

export default DndList;
