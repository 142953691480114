import React from 'react';
import styled from 'styled-components';

import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';

const MetricValueChartLegend = ({ moarLegends, maxLegendItems, ...props }) => {
  const legendsToRender = moarLegends?.slice(0, maxLegendItems);

  return (
    <Container {...props}>
      <Flex>
        <LinkedItemsIcon />
        <Text variant="small">Linked Portfolio Items</Text>
      </Flex>
      <Flex>
        <GoalLineIcon />
        <Text variant="small">Target</Text>
      </Flex>
      <Flex>
        <PerformanceLineIcon />
        <Text variant="small">Actual</Text>
      </Flex>
      {legendsToRender.map(legend => (
        <Flex>
          <Circle legendColor={legend?.backgroundColor} />
          <Text variant="small">{legend?.label}</Text>
        </Flex>
      ))}
    </Container>
  );
};

export default MetricValueChartLegend;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2)}px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing()}px;
`;

const LinkedItemsIcon = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 50%;
`;

const GoalLineIcon = styled.div`
  width: 13px;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.charts.primary};
`;

const PerformanceLineIcon = styled.div`
  width: 13px;
  height: 2px;
  background-color: ${({ theme }) => theme.palette.charts.secondary};
`;

const Circle = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ theme, legendColor }) => legendColor || theme.palette.primary.main};
  border-radius: 50%;
`;
