import { createSelector } from 'reselect';
import { defaultTo } from 'ramda';

import { projectsAdapter, objectivesAdapter, keyResultsAdapter, timeframesAdapter, metricsAdapter } from './helpers/adapters';
import { isAddMetricsToProjectLoading, isBulkAddMetricsToProjectLoading, isUpdateProjectLoading } from 'store/projects';
import { selectMetricLevels } from 'features/MetricLevels/store';

import { APPLY_FILTERS_WHITEBOARD } from './types';

import { isLoading } from 'utils/store/thunk';
import { getUsers } from 'store/users/selectors';

import getOwnerUsersById from 'features/Canvas/helpers/getOwnerUsers';

const emptyObject = {};

const defaultToProjectsInitialState = defaultTo(projectsAdapter.getInitialState());
const defaultToObjectivesInitialState = defaultTo(objectivesAdapter.getInitialState());
const defaultToKeyResultsInitialState = defaultTo(keyResultsAdapter.getInitialState());
const defaultToTimeframesInitialState = defaultTo(timeframesAdapter.getInitialState());
const defaultToMetricsInitialState = defaultTo(metricsAdapter.getInitialState());

const getState = state => state?.whiteboard;

const getOperations = state => {
  return state.whiteboard.operations;
};

export const projectsSelector = projectsAdapter.getSelectors(state => {
  return defaultToProjectsInitialState(state.projects);
});

export const objectivesSelector = objectivesAdapter.getSelectors(state => {
  return defaultToObjectivesInitialState(state.objectives);
});

const keyResultsSelector = keyResultsAdapter.getSelectors(state => {
  return defaultToKeyResultsInitialState(state.keyResults);
});

const timeframesSelector = timeframesAdapter.getSelectors(state => {
  return defaultToTimeframesInitialState(state.timeframes);
});

export const metricsSelector = metricsAdapter.getSelectors(state => {
  return defaultToMetricsInitialState(state.metrics);
});

export const selectWhiteboardLayoutConfig = state => state.whiteboard.layoutConfig || emptyObject;
export const selectWhiteboardNodesLayout = state => state.whiteboard.nodesLayout || emptyObject;
export const selectWhiteboardEdgesLayout = state => state.whiteboard.edgesLayout || emptyObject;

export const isApplyingFiltersOnWhiteboardLoading = createSelector(getOperations, state =>
  isLoading(state, APPLY_FILTERS_WHITEBOARD),
);

export const selectProjectAndMetadataForIdeasWhiteboard = createSelector(
  state => projectsSelector.selectAll(getState(state)),
  state => objectivesSelector.selectAll(getState(state)),
  state => keyResultsSelector.selectAll(getState(state)),
  state => timeframesSelector.selectAll(getState(state)),
  state => metricsSelector.selectAll(getState(state)),
  getUsers,
  selectMetricLevels,
  (projects, objectives, keyResults, timeframes, metrics, users, metricLevels) => {
    return {
      projectsList: projects,
      objectivesList: objectives,
      keyResultsList: keyResults,
      timeframesList: timeframes,
      metricsList: metrics,
      usersList: getOwnerUsersById(users, [...projects, ...objectives, ...keyResults, ...metrics]),
      metricLevels,
    };
  },
);

export const isSomeUpdateOnProjectsOccurring = createSelector(
  isUpdateProjectLoading,
  isBulkAddMetricsToProjectLoading,
  isAddMetricsToProjectLoading,
  (isUpdatingProject, isBulkAddMetricsToProject, isAddingMetricsToProject) => {
    return isUpdatingProject || isBulkAddMetricsToProject || isAddingMetricsToProject;
  },
);

export const selectTotalProjects = state => getState(state)?.totalAllLayers || 0;
export const selectTotalProjectsByLayer = state => getState(state)?.totalByLayer;

export const selectWhiteboardUserActionsMeta = state => state.whiteboard.userActionsMeta || emptyObject;
