import { path } from 'ramda';
import React, { useMemo } from 'react';
import { css } from 'styled-components';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import useLightboxesControlContext from 'hooks/lightboxes/useLightboxesControl';
import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';
import {
  getMetadataRoadmapColumnDef,
  getOwnerColumnDef,
  healthColumnDef,
  nameColumnDef,
  numericColumnDef,
  statusColumnDef,
  remirrorColumnDef,
  getDragColumnDef,
  dateColumnDef,
  colorColumnDef,
  defaultColumnDefCommonProps,
} from 'design-system/molecules/AgGridReact-New/columns';
import { isNewRow } from 'design-system/molecules/AgGridReact-New/helpers';
import Roadmap from 'design-system/atoms/DragonIcons/Roadmap';
import MetricDataSourceCellEditor from 'routes/Metrics/MetricsGrid/components/MetricDataSourceCellEditor';
import { METRIC_HEALTH_COLORS_OPTIONS } from 'constants/metrics';
import { ALT_HEALTH_COLORS_LABELS } from 'constants/health';
import { SortableHeaderWithIconCellRenderer } from 'design-system/molecules/AgGridReact-New/headerCellRenderers';
import {
  DateTimeCellRenderer,
  TextCellRenderer,
  TextLinkCellRenderer,
} from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { DropdownCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import { stringComparator } from 'utils/agGridComparators';
import { selectMetricLevels } from 'features/MetricLevels/store/selectors';
import getLevelForMetric from 'features/MetricLevels/utils/getLevelForMetric';
import sortRowsByMetricLevel from 'features/MetricLevels/utils/sortRowsByMetricLevel';
import getMetricLevelsOptions from 'features/MetricLevels/utils/getMetricLevelsOptions';
import {
  NAME_COLUMN_TITLE,
  APPLIES_TO_COLUMN_TITLE,
  LEVEL_COLUMN_TITLE,
  DETAILS_COLUMN_TITLE,
  OWNER_COLUMN_TITLE,
  HEALTH_COLUMN_TITLE,
  ACTUAL_VALUE_COLUMN_TITLE,
  TARGET_VALUE_COLUMN_TITLE,
  BASELINE_VALUE_COLUMN_TITLE,
  SUMMARY_COLUMN_TITLE,
} from './constants';
import { defaultExport, exportHealth, exportMetadataRoadmap, exportMultiLineText } from 'features/GridExport/helpers/exportUtils';

const getRowLastSyncAt = path(['data', 'metricIntegrations', 0, 'values_synced_at']);

const METRIC_KEY = 'metric';
const METRIC_ROADMAP_FIELD = 'metric_roadmaps';

const textStyles = css`
  padding: 0 10px;
`;

const metricStatusColumnDef = {
  ...statusColumnDef,
  exportFn: defaultExport,
};

const metricColorColumnDef = {
  ...colorColumnDef,
  exportFn: defaultExport,
};

const useMetricsGridColumns = ({
  allowActions,
  users,
  roadmapColumn,
  openMetricView,
  openProjectsListLightbox,
  canDragRows,
  rowDrag = true,
  canViewMetricIntegration = false,
  canUpdateMetricIntegration = false,
  updateMetricIntegration = () => {},
}) => {
  const { metrics, createMetricRoadmap, deleteMetricRoadmap, bulkDeleteMetricRoadmaps, hideMetadataRoadmaps } = roadmapColumn;

  const { canView, canUpdate } = usePermissions();

  const canViewMetricLevels = canView(PERMISSION_FEATURES.metricLevels);
  const canViewMetricSummary = canView(PERMISSION_FEATURES.metricSummary);
  const canUpdateMetricLevels = canUpdate(PERMISSION_FEATURES.metricLevels);

  const metricLevels = useSelector(selectMetricLevels);

  const { openMetricLevelsLightbox } = useLightboxesControlContext();

  const roadmapsMetadata = useRoadmapsMetadata(
    createMetricRoadmap,
    deleteMetricRoadmap,
    metrics,
    METRIC_KEY,
    bulkDeleteMetricRoadmaps,
  );

  const metricHealthColumnDef = useMemo(() => {
    return {
      ...healthColumnDef,
      headerName: HEALTH_COLUMN_TITLE,
      cellEditorParams: {
        options: METRIC_HEALTH_COLORS_OPTIONS,
        sort: false,
      },
      optionsLabel: ALT_HEALTH_COLORS_LABELS,
      width: 130,
      exportFn: exportHealth,
    };
  }, [allowActions]);

  const metricNameColumnDef = useMemo(() => {
    return {
      ...nameColumnDef,
      headerName: NAME_COLUMN_TITLE,
      cellRendererParams: {
        textStyles,
      },
      headerClass: 'first-field',
      width: 400,
      hasLink: !!openMetricView,
      onLinkClick: metric => openMetricView(metric),
      linkIcon: <OpenInNewIcon />,
      hasSecondaryLink: !!openProjectsListLightbox,
      onSecondaryLinkClick: metric => openProjectsListLightbox(metric),
      secondaryLinkIcon: <Roadmap />,
      cellClass: 'align-center',
      editable: allowActions,
      exportFn: defaultExport,
    };
  }, [allowActions]);

  const metricActualValueColumnDef = useMemo(
    () => ({
      ...numericColumnDef,
      field: 'actual_value',
      headerName: ACTUAL_VALUE_COLUMN_TITLE,
      exportFn: defaultExport,
    }),
    [allowActions],
  );

  const metricBaselineValueColumnDef = useMemo(
    () => ({
      ...numericColumnDef,
      field: 'baseline_value',
      headerName: BASELINE_VALUE_COLUMN_TITLE,
      exportFn: defaultExport,
    }),
    [allowActions],
  );

  const metricTargetValueColumnDef = useMemo(
    () => ({
      ...numericColumnDef,
      field: 'target_value',
      headerName: TARGET_VALUE_COLUMN_TITLE,
      exportFn: defaultExport,
    }),
    [allowActions],
  );

  const metricDetailsColumnDef = useMemo(
    () => ({
      ...remirrorColumnDef,
      headerName: DETAILS_COLUMN_TITLE,
      suppressMovable: true,
      field: 'details',
      width: 300,
      cellClass: 'align-items-top',
      exportFn: exportMultiLineText,
    }),
    [allowActions],
  );

  const metricIntegrationColumnDef = useMemo(() => {
    return {
      cellRenderer: TextLinkCellRenderer,
      cellRendererParams: {
        handleClick: data => {
          if (data?.data_source) {
            window.open(data.data_source, '_blank');
          }
        },
        withEllipsis: true,
      },
      cellEditor: MetricDataSourceCellEditor,
      cellEditorParams: () => ({
        width: 600,
        onChange: (metricId, params) => updateMetricIntegration(metricId)(params),
      }),
      cellEditorPopup: true,
      comparator: stringComparator,
      headerName: 'Data source',
      field: 'data_source',
      width: 400,
      cellClass: 'align-center',
      editable: allowActions,
      exportFn: defaultExport,
    };
  }, [allowActions]);

  const metricLastSyncTimestampColumnDef = {
    ...dateColumnDef,
    cellRenderer: DateTimeCellRenderer,
    valueGetter: getRowLastSyncAt,
    headerName: 'Last update',
    width: 200,
    editable: false,
  };

  const metricLevelColumnDef = useMemo(
    () => ({
      field: 'level',
      headerName: LEVEL_COLUMN_TITLE,
      cellRenderer: TextCellRenderer,
      valueFormatter: data => getLevelForMetric(data, metricLevels)?.title,
      cellEditor: DropdownCellEditor,
      cellEditorPopup: true,
      cellEditorParams: {
        options: getMetricLevelsOptions(metricLevels, true),
        sort: false,
      },
      headerComponent: SortableHeaderWithIconCellRenderer,
      headerComponentParams: {
        icon: <SettingsIcon />,
        hasIcon: canUpdateMetricLevels,
        onIconClick: openMetricLevelsLightbox,
      },
      openByDefault: true,
      comparator: (...params) => sortRowsByMetricLevel(...params, metricLevels),
      editable: allowActions,
      width: 150,
      exportFn: ({ value }) => {
        if (value && metricLevels?.[value]) {
          return metricLevels[value].title;
        }

        return '';
      },
    }),
    [metricLevels, allowActions],
  );

  const metricSummaryColumnDef = useMemo(
    () => ({
      ...remirrorColumnDef,
      field: 'summary',
      headerName: SUMMARY_COLUMN_TITLE,
      width: 300,
      cellClass: 'align-items-top',
      editable: allowActions,
      exportFn: exportMultiLineText,
    }),
    [allowActions],
  );

  const columnDefs = useMemo(
    () => [
      metricNameColumnDef,
      getMetadataRoadmapColumnDef({
        headerName: APPLIES_TO_COLUMN_TITLE,
        roadmapsMetadata: [...roadmapsMetadata, hideMetadataRoadmaps],
        field: METRIC_ROADMAP_FIELD,
        editableForNewRow: false,
        exportFn: exportMetadataRoadmap,
      }),
      metricStatusColumnDef,
      metricDetailsColumnDef,
      getOwnerColumnDef({
        headerName: OWNER_COLUMN_TITLE,
        editable: allowActions,
        editableForNewRow: false,
        users,
        field: 'owner_id',
        cellClass: 'align-center',
        exportFn: defaultExport,
      }),
      metricHealthColumnDef,
      metricActualValueColumnDef,
      metricTargetValueColumnDef,
      metricBaselineValueColumnDef,
      metricColorColumnDef,
      ...(canViewMetricIntegration ? [metricIntegrationColumnDef, metricLastSyncTimestampColumnDef] : []),
      ...(canViewMetricLevels ? [metricLevelColumnDef] : []),
      ...(canViewMetricSummary ? [metricSummaryColumnDef] : []),
    ],
    [
      metricHealthColumnDef,
      metricNameColumnDef,
      metricActualValueColumnDef,
      metricBaselineValueColumnDef,
      metricTargetValueColumnDef,
      metricIntegrationColumnDef,
      canViewMetricIntegration,
      metricLevelColumnDef,
      canViewMetricLevels,
      metricSummaryColumnDef,
      canViewMetricSummary,
    ],
  );

  const defaultColumnDef = useMemo(
    () => ({
      ...defaultColumnDefCommonProps,
      cellClass: params =>
        params.colDef.autoHeight ? 'ag-react-container--expandable' : 'ag-react-editable-container--expandable',
      sortable: true,
      resizable: true,
      filter: true,
      editable: params => allowActions && !isNewRow(params),
    }),
    [allowActions],
  );

  const dragColumnDef = useMemo(() => getDragColumnDef({ checkRowDrag: rowDrag, pinned: 'left' }), [rowDrag]);

  const beforeColumnDefs = useMemo(() => {
    if (allowActions && canDragRows) {
      return [dragColumnDef];
    }
    return [];
  }, [allowActions, canDragRows]);

  return {
    columnDefs,
    defaultColumnDef,
    beforeColumnDefs,
  };
};

export default useMetricsGridColumns;

export { METRIC_ROADMAP_FIELD };
