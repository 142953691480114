// External dependencies
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

import { FeatureFlags } from '@dragonboat/config';

// Dragonboat imports
import { getProjectsCustomFields } from 'store/customFields/selectors';
import { getOrganization, getIdeasIntegrations } from 'store/organization/selectors';
import { getDisplayLayer } from 'store/filters/selectors';

import useSystemFields from 'hooks/useSystemFields';
import useCustomUserFields from 'hooks/useCustomUserFields';
import { COLOR_BY_OPTIONS, ZOOM_OPTIONS, DISPLAY_COLUMNS_OPTIONS } from 'constants/timeline';
import usePermissions from 'hooks/permissions/usePermissions';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const componentHOC = Component => {
  return props => {
    const { localState, updateLocalState, lsState, updateLsState, showResourceDisplayOpt } = props;
    const organization = useSelector(state => getOrganization(state));
    const orgIntegrations = useSelector(getIdeasIntegrations);
    const customFields = useSelector(state => getProjectsCustomFields(state, false));
    const displayLayer = useSelector(state => getDisplayLayer(state));
    const [showDialog, setShowDialog] = useState(false);
    const [tempState, _setTempState] = useState({
      ...lsState,
      ...localState,
    });

    const [getSystemFieldName] = useSystemFields();
    const { getAllCustomUserFields } = useCustomUserFields();

    const setTempState = data => _setTempState({ ...tempState, ...data });
    const onApply = () => {
      setShowDialog(false);

      if (tempState.showStories && (tempState.showHCTable || tempState.showBusinessValue)) {
        tempState.showStories = null;
      }
      updateLocalState(pick(tempState, Object.keys(localState)));
      const newLsState = omit(tempState, Object.keys(localState));

      setTimeout(() => updateLsState(newLsState), 500);
    };

    useEffect(() => {
      if (props.triggerOpenEvent) {
        props.triggerOpenEvent(() => () => setShowDialog(true));
      }
    }, []);
    useEffect(
      () =>
        _setTempState({
          ...lsState,
          ...localState,
        }),
      [showDialog],
    );
    const onChangeSetting = (setting, value) => setTempState({ [setting]: value });

    const systemFields = organization.system_fields_name;
    const hasKeyResults = organization.has_key_results;
    const hasKeyResults2 = organization.has_key_results_2;
    const hasProducts = organization.has_products;
    const hasHierarchy = organization.has_hierarchy;
    const hasIntegration = orgIntegrations && orgIntegrations.length > 0;
    const hasCompletedAllocation = useFeatureFlags([FeatureFlags.HAS_COMPLETED_ALLOCATION]);

    const permissions = usePermissions();
    const { canView } = permissions;

    const shouldShowPreCalculations = useFeatureFlags([FeatureFlags.HAS_OUTCOME_DASHBOARD]);
    const showsPredictedEndDateOption = canView(PERMISSION_FEATURES.rollupDates) || hasIntegration;

    const displayColumnOptions = DISPLAY_COLUMNS_OPTIONS({
      systemFields,
      hasKeyResults,
      hasProducts,
      customFields,
      customUserFields: getAllCustomUserFields(),
      hasHierarchy,
      canView,
      hasIntegration,
      orgIntegrations,
      hasKeyResults2,
      shouldShowPreCalculations,
      hasCompletedAllocation,
    });
    const onChangeDisplayColumns = displayColumnIds => {
      const selectedDisplayColumns = displayColumnIds
        .filter(col => displayColumnOptions.some(c => c.id === col))
        .map(col => displayColumnOptions.find(c => c.id === col));

      onChangeSetting('selectedDisplayColumns', selectedDisplayColumns);
    };

    const colorByOptions = COLOR_BY_OPTIONS(systemFields, hasKeyResults, hasProducts, hasHierarchy, canView, hasKeyResults2);

    return (
      <Component
        {...props}
        lsState={tempState}
        onChangeSetting={onChangeSetting}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        updateState={setTempState}
        zoomOptions={ZOOM_OPTIONS}
        displayColumnOptions={displayColumnOptions}
        onChangeDisplayColumns={onChangeDisplayColumns}
        colorByOptions={colorByOptions}
        systemFields={systemFields}
        onApply={onApply}
        hasHierarchy={hasHierarchy}
        displayLayer={displayLayer}
        getSystemFieldName={getSystemFieldName}
        showResourceDisplayOpt={showResourceDisplayOpt}
        showsPredictedEndDateOption={showsPredictedEndDateOption}
      />
    );
  };
};

export default componentHOC;
