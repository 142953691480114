import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import Comments from 'components/Comments';

import {
  saveProjectComment,
  fetchProjectComments,
  fetchNextProjectComments,
  deleteProjectComment,
  editProjectComment,
} from 'store/comments/thunks';

const CommentsSectionContainer = styled.div``;

const TitleContainer = styled.div``;

class CommentsSection extends Component {
  componentDidMount() {
    const { project, fetchProjectComments } = this.props;

    if (project) {
      fetchProjectComments(project.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.project && (!prevProps.project || prevProps.project.id !== this.props.project.id) && this.props.project) {
      this.props.fetchProjectComments(this.props.project.id);
    }
  }

  save = ({ text, mentions, editorState, integrationType, channel }) => {
    const { saveProjectComment, project } = this.props;

    let blocks = editorState.blocks; // eslint-disable-line

    const firstRealIndex = blocks.findIndex(s => s.text.length);

    blocks = blocks.slice(firstRealIndex);

    const lastRealIndex =
      blocks.length -
      blocks
        .slice()
        .reverse()
        .findIndex(s => s.text.length);

    blocks = blocks.slice(0, lastRealIndex);
    editorState.blocks = blocks;

    return saveProjectComment(project.id, { plainText: text.trim(), mentions, editorState, integrationType, channel });
  };

  fetchNext = () => {
    const { project } = this.props;

    if (project) {
      this.props.fetchNextProjectComments(project.id);
    }
  };

  deleteComment = commentId => {
    const { deleteProjectComment, project } = this.props;

    return deleteProjectComment(project.id, commentId);
  };

  editComment = (commentId, data) => {
    const { editProjectComment, project } = this.props;

    return editProjectComment(project.id, commentId, data);
  };

  render() {
    const { hasMoreComments, comments } = this.props;

    return (
      <CommentsSectionContainer>
        <TitleContainer>Comment</TitleContainer>

        <Comments
          comments={comments || []}
          onSave={this.save}
          hasMoreComments={hasMoreComments}
          showMore={this.fetchNext}
          onDelete={this.deleteComment}
          onEdit={this.editComment}
        />
      </CommentsSectionContainer>
    );
  }
}

export default connect(
  (state, ownProps) => {
    const { comments: commentsState } = state;
    const projectId = ownProps.project ? ownProps.project.id : null;

    const projectCommentsState = commentsState.projects.get(JSON.stringify(projectId));

    const hasMoreComments = projectCommentsState && projectCommentsState._meta && !!projectCommentsState._meta.next;

    const comments = projectCommentsState && projectCommentsState.data;

    return {
      hasMoreComments,
      comments,
    };
  },
  {
    saveProjectComment,
    fetchProjectComments,
    fetchNextProjectComments,
    deleteProjectComment,
    editProjectComment,
  },
)(CommentsSection);
