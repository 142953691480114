import { PointsVarianceCellRenderer } from '../cellRenderers';
import { numberComparator } from '../comparators';

import textCenterAlignedColumnDef from './textCenterAlignedColumnDef';

const pointsVarianceColumnDef = {
  ...textCenterAlignedColumnDef,
  cellRenderer: PointsVarianceCellRenderer,
  comparator: numberComparator,
  width: 180,
};

export default pointsVarianceColumnDef;
