import React, { useCallback, useMemo } from 'react';
import { Chart } from 'chart.js';
import styled from 'styled-components';

import { makeLeadLinesPlugin } from 'components/ReactChart/utils/plugins';

import normalizeArray from 'utils/normalizeArray';
import DoughnutChart from 'components/ReactChart/DoughnutChart';
import { HIDE_LEGEND_OPTION, calculatePiePercentage } from 'constants/charts';
import { sortChartDataDescending } from 'routes/Dashboard/Dashboards/helpers';
import ChartLegend from 'routes/Dashboard/Dashboards/Charts/ChartLegend';

export default (data, entities, lsState, chartId, showPercentage, leadLinesPluginOptions) => {
  const { displayLegendInline } = lsState;
  const normalizedEntities = normalizeArray(entities, 'id');
  const getMapped = (values = [], key) => values.map(val => val[key]);

  const formattedData = useMemo(() => {
    const formatted = Object.entries(data).reduce((values, [id, value]) => {
      values.push({
        id,
        value,
        color: normalizedEntities[id]?.color,
        label: normalizedEntities[id]?.title,
      });

      return values;
    }, []);

    return sortChartDataDescending(formatted);
  }, [data, entities]);

  /**
   * Defaults to slice size percentage when `showPercentage` is true.
   */
  const piePercentageFormatter = useCallback(() => {
    if (showPercentage) {
      return calculatePiePercentage;
    }

    // Hide otherwise
    return () => null;
  }, [showPercentage]);

  const legendOptions = useMemo(() => {
    const canvas = document.getElementById(`allocation-pie-chart-${chartId}`);
    const chart = Chart.getChart(canvas);
    const legend = HIDE_LEGEND_OPTION;
    const leadLinesPlugin = makeLeadLinesPlugin(piePercentageFormatter(), leadLinesPluginOptions);

    const plugins = displayLegendInline ? [leadLinesPlugin] : [];

    // If chart has already rendered, update the chart config
    if (chart) {
      if (displayLegendInline) {
        chart.config.plugins.push(leadLinesPlugin);
      } else {
        chart.config.plugins.shift();
      }
      chart.update();
    }

    return { legend, plugins };
  }, [displayLegendInline, piePercentageFormatter]);

  const colors = useMemo(() => getMapped(formattedData, 'color'), [formattedData]);
  const labels = useMemo(() => getMapped(formattedData, 'label'), [formattedData]);
  const ids = useMemo(() => getMapped(formattedData, 'id'), [formattedData]);
  const datasetData = useMemo(() => getMapped(formattedData, 'value'), [formattedData]);
  const datasetPercentageData = useMemo(() => getMapped(formattedData, 'percentage'), [formattedData]);

  const chartData = {
    labels,
    ids,
    datasets: [
      {
        data: datasetData,
        percentageData: datasetPercentageData,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  };

  const chart = (
    <PieChartWithLegend>
      <PieChartContainer>
        <DoughnutChart
          id={`allocation-pie-chart-${chartId}`}
          data={chartData}
          options={{
            plugins: {
              legend: legendOptions.legend,
            },
            layout: {
              padding: displayLegendInline ? 30 : 10,
            },
            radius: '80%',
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 2,
          }}
          plugins={legendOptions.plugins}
        />
      </PieChartContainer>
      {!displayLegendInline && (
        <ChartLegend
          legends={colors.map((color, index) => ({ backgroundColor: color, label: labels[index] }))}
          maxLegendItems={6}
        />
      )}
    </PieChartWithLegend>
  );

  return [chart];
};

const PieChartWithLegend = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.unit}px;
`;

const PieChartContainer = styled.div`
  width: 100%;
  height: 300px;
`;
