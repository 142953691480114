import Axios from 'axios';
import undoAction from './undoAction';
import throwRequestError from '../throwRequestError';

export default (ACTION_TYPE, endpoint, undoOptions, transform) => {
  return (ids, socketRoom) => {
    return async dispatch => {
      if (!ids) {
        return Promise.reject();
      }
      const rows = ids.join(',');

      const params = {
        ids: rows,
        ...(socketRoom ? { socketRoom } : {}),
      };

      const payload = Axios.delete(endpoint, { params })
        .then(res => {
          const { data } = res;

          if (undoOptions) {
            dispatch(undoAction(
              `${data.length} ${undoOptions.toastText}`,
              undoOptions.ACTION_TYPE,
              undoOptions.store,
              undoOptions.endpoint
            ));
          }

          return data;
        })
        .catch(throwRequestError);

      await dispatch({
        type: ACTION_TYPE,
        payload,
      });

      return payload;
    };
  };
};
