import React from 'react';

import theme from 'design-system/theme';
import formatDate from 'design-system/utils/formatDate';
import TextDeprecated from 'design-system/atoms/TextDeprecated';

const DateCellRenderer = params => (
  <TextDeprecated textColor={theme.palette.gridColors.mineShaft} size="small" breakwords={params.colDef.breakwords}>
    {formatDate(params.value)}
  </TextDeprecated>
);

export default DateCellRenderer;
