import axios from 'axios';
import { defaultTo, pathOr } from 'ramda';

import { createThunk } from 'utils/store/thunk';

import {
  FETCH_PORTFOLIO_OVERVIEW_METRICS,
  FETCH_PORTFOLIO_OVERVIEW_OKRS,
  FETCH_PORTFOLIO_OVERVIEW_SUB_ROADMAPS,
  FETCH_PORTFOLIO_OVERVIEW_BY_ROADMAP,
  UPDATE_PORTFOLIO_OVERVIEW_LAYOUT,
  UPDATE_PORTFOLIO_OVERVIEW_LOCAL_LAYOUT,
  UPDATE_PORTFOLIO_OVERVIEW_DESCRIPTION,
  UPDATE_PORTFOLIO_OVERVIEW_WIDGET_VISIBILITY,
  UPDATE_PORTFOLIO_OVERVIEW_COMMITTED_STATE,
  FETCH_PORTFOLIO_OVERVIEW_METRICS_FULFILLED,
  FETCH_PORTFOLIO_OVERVIEW_HISTORY,
  FETCH_PORTFOLIO_OVERVIEW_HISTORY_NEXT,
} from './types';
import { AVAILABLE_WIDGETS, INITIAL_LAYOUT } from '../constants/widgetsConfig';
import { PORTFOLIO_SUB_ROADMAPS_WIDGET_ID, PORTFOLIO_GOALS_WIDGET_ID, PORTFOLIO_METRICS_WIDGET_ID } from '../constants/widgetIds';
import makeFilterOKRHierarchyByRoadmap from 'utils/makeFilterOKRHierarchyByRoadmap';
import {
  defaultDescriptionBuilder,
  defaultEditMode,
  getFilteredOKRs,
  getFilteredMetrics,
  layoutOrDefaultForAllWidgets,
} from './helpers';
import { getCurrentUser } from 'store/login/selectors';
import { updatePortfolioOverviewLocalEditMode } from './actions';
import { selectIsInEditMode, selectPortfolioOverviewForActiveRoadmap } from './selectors';
import { METADATA_LEVELS } from 'constants/common';

const WIDGETS_DATA_LOADER_THUNK_BY_ID = {
  [PORTFOLIO_SUB_ROADMAPS_WIDGET_ID]: portfolioOverview =>
    fetchPortfolioOverviewSubRoadmaps(portfolioOverview.roadmap_id, portfolioOverview.roadmap_type),
  [PORTFOLIO_GOALS_WIDGET_ID]: portfolioOverview =>
    fetchPortfolioOverviewOkrs(portfolioOverview.roadmap_id, portfolioOverview.roadmap_type),
  [PORTFOLIO_METRICS_WIDGET_ID]: portfolioOverview =>
    fetchPortfolioOverviewMetrics(portfolioOverview.roadmap_id, portfolioOverview.roadmap_type),
};

const fetchPortfolioOverviewSubRoadmaps = (roadmapId, roadmapType) => {
  return createThunk(FETCH_PORTFOLIO_OVERVIEW_SUB_ROADMAPS, async () => {
    const { data } = await axios.post('/api/v1/roadmaps/search', {
      parent: {
        id: roadmapId || null,
        type: roadmapType || null,
      },
    });

    return data;
  });
};

const fetchPortfolioOverviewOkrs = (roadmapId, roadmapType) => {
  return (dispatch, getState) => {
    dispatch(
      createThunk(FETCH_PORTFOLIO_OVERVIEW_OKRS, async () => {
        roadmapId = roadmapId || 'null';
        const roadmapTypeOrDefault = defaultTo(METADATA_LEVELS.LEVEL_1)(roadmapType);

        const roadmaps = roadmapTypeOrDefault === METADATA_LEVELS.LEVEL_1 ? [roadmapId] : [];
        const subroadmaps = roadmapTypeOrDefault === METADATA_LEVELS.LEVEL_2 ? [roadmapId] : [];
        const products2 = roadmapTypeOrDefault === METADATA_LEVELS.LEVEL_3 ? [roadmapId] : [];

        const okrs = getState().objectives.objectives || [];

        const filterByRoadmap = makeFilterOKRHierarchyByRoadmap(roadmaps, subroadmaps, products2);

        return getFilteredOKRs(okrs, filterByRoadmap);
      }),
    );
  };
};

const fetchPortfolioOverviewMetrics = (roadmapId, roadmapType) => {
  return (dispatch, getState) => {
    dispatch(
      createThunk(FETCH_PORTFOLIO_OVERVIEW_METRICS, async () => {
        const metrics = getState().metrics.metrics || [];

        return getFilteredMetrics(metrics, roadmapId, roadmapType);
      }),
    );
  };
};

const refreshPortfolioOverviewMetrics = (roadmapId, roadmapType) => {
  return (dispatch, getState) => {
    const metrics = getState().metrics.metrics || [];

    dispatch({
      type: FETCH_PORTFOLIO_OVERVIEW_METRICS_FULFILLED,
      payload: getFilteredMetrics(metrics, roadmapId, roadmapType),
    });
  };
};

const putPortfolioOverview = async (portfolioOverviewId, changes) => {
  const { data } = await axios.put(`/api/v1/portfolio-overview/${portfolioOverviewId}`, {
    state: changes,
  });

  return data;
};

const updatePortfolioOverviewLayout = layout => {
  return (dispatch, getState) => {
    const portfolioOverview = selectPortfolioOverviewForActiveRoadmap(getState());
    const { widgets } = portfolioOverview.state;

    dispatch(
      createThunk(UPDATE_PORTFOLIO_OVERVIEW_LAYOUT, async () => {
        const updatedLayout = layoutOrDefaultForAllWidgets(widgets, layout);

        return putPortfolioOverview(portfolioOverview.id, { layout: updatedLayout, widgets });
      }),
    );
  };
};

const updatePortfolioOverviewLocalLayout = (widgetId, height) => {
  return (dispatch, getState) => {
    const portfolioOverview = selectPortfolioOverviewForActiveRoadmap(getState());
    const { widgets, layout } = portfolioOverview.state;

    const matchingWidget = layout?.find(item => item.i === widgetId);

    if (height !== matchingWidget?.h) {
      let updatedLayout = layout?.map(item => (item.i === widgetId ? { ...item, h: height || 1 } : item)) || [];

      updatedLayout = layoutOrDefaultForAllWidgets(widgets, updatedLayout);

      dispatch(createThunk(UPDATE_PORTFOLIO_OVERVIEW_LOCAL_LAYOUT, updatedLayout));
    }
  };
};

const updatePortfolioOverviewDescription = description => {
  return (dispatch, getState) => {
    const portfolioOverview = selectPortfolioOverviewForActiveRoadmap(getState());
    const { widgets } = portfolioOverview.state;

    dispatch(
      createThunk(UPDATE_PORTFOLIO_OVERVIEW_DESCRIPTION, async () => {
        return putPortfolioOverview(portfolioOverview.id, { description, widgets });
      }),
    );
  };
};

const updatePortfolioOverviewWidgetVisibility = (widgetId, isVisible) => {
  return (dispatch, getState) => {
    const portfolioOverview = selectPortfolioOverviewForActiveRoadmap(getState());
    const { widgets } = portfolioOverview.state;

    dispatch(
      createThunk(UPDATE_PORTFOLIO_OVERVIEW_WIDGET_VISIBILITY, async () => {
        const updatedWidgets = widgets.map(widget => {
          if (widget.id === widgetId) {
            return {
              ...widget,
              isVisible,
            };
          }
          return widget;
        });

        return putPortfolioOverview(portfolioOverview.id, { widgets: updatedWidgets });
      }),
    );
  };
};

const updatePortfolioOverviewCommittedState = (portfolioOverview, committed) => {
  return createThunk(UPDATE_PORTFOLIO_OVERVIEW_COMMITTED_STATE, async () => {
    return putPortfolioOverview(portfolioOverview.id, { committed });
  });
};

const fetchPortfolioOverviewtByRoadmap = activeRoadmapEntity => {
  return (dispatch, getState) => {
    dispatch(
      createThunk(FETCH_PORTFOLIO_OVERVIEW_BY_ROADMAP, async () => {
        const { data } = await axios.post('/api/v1/portfolio-overview/find-or-create', {
          roadmapId: activeRoadmapEntity?.id || null,
          roadmapType: activeRoadmapEntity?.type || null,
        });

        const createDefaultWidgets = () => {
          return AVAILABLE_WIDGETS.map(widgetConfig => {
            return {
              id: widgetConfig.id,
              isVisible: true,
            };
          });
        };

        const portfolioOverview = {
          ...data,
          state: {
            ...(data.state || {}),
            description: data.state?.description || defaultDescriptionBuilder(),
            layout: data.state?.layout || INITIAL_LAYOUT,
            widgets: data.state?.widgets || createDefaultWidgets(),
          },
        };

        const currentUser = getCurrentUser(getState());
        const portfolioOverviewIsCommitted = pathOr(false, ['state', 'committed'])(portfolioOverview);
        const shouldStartInEditMode = defaultEditMode(currentUser?.role_id, portfolioOverviewIsCommitted);

        return { portfolioOverview, initialEditModeState: shouldStartInEditMode };
      }),
    );
  };
};

/**
 * Toggles edit mode on/off. Toggling off will mark currently active portfolio overview as committed.
 */
const togglePortfolioOverviewEditMode = () => {
  return (dispatch, getState) => {
    const state = getState();
    const isInEditMode = selectIsInEditMode(state);
    const portfolioOverview = selectPortfolioOverviewForActiveRoadmap(state);

    dispatch(updatePortfolioOverviewLocalEditMode(!isInEditMode));

    const isTogglingOff = isInEditMode;
    const isAlreadyCommitted = pathOr(false, ['state', 'committed'])(portfolioOverview);

    if (isTogglingOff && !isAlreadyCommitted) {
      dispatch(updatePortfolioOverviewCommittedState(portfolioOverview, true));
    }
  };
};

const fetchPortfolioOverviewHistory = portfolioOverviewId =>
  createThunk(
    FETCH_PORTFOLIO_OVERVIEW_HISTORY,
    axios.get(`/api/v1/portfolio-overview/${portfolioOverviewId}/history`).then(res => res.data),
    { portfolioOverviewId },
  );

const fetchPortfolioOverviewHistoryNextPage = (portfolioOverviewId, nextPageUrl) =>
  createThunk(
    FETCH_PORTFOLIO_OVERVIEW_HISTORY_NEXT,
    axios.get(nextPageUrl).then(res => res.data),
    { portfolioOverviewId },
  );

export {
  WIDGETS_DATA_LOADER_THUNK_BY_ID,
  fetchPortfolioOverviewSubRoadmaps,
  fetchPortfolioOverviewOkrs,
  fetchPortfolioOverviewMetrics,
  refreshPortfolioOverviewMetrics,
  updatePortfolioOverviewLayout,
  updatePortfolioOverviewLocalLayout,
  updatePortfolioOverviewDescription,
  updatePortfolioOverviewWidgetVisibility,
  fetchPortfolioOverviewtByRoadmap,
  togglePortfolioOverviewEditMode,
  fetchPortfolioOverviewHistory,
  fetchPortfolioOverviewHistoryNextPage,
};
