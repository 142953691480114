import { head, path } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import getSystemFieldName from 'utils/getSystemFieldName';
import { DASHBOARDS_PAGE } from 'constants/filters';
import usePageFilters from 'hooks/filters/usePageFilters';
import { buildFilterForTimelineProjects } from '../helpers';

const supportedFilterFields = {
  roadmapCorp: 'roadmapsCorp',
  roadmap: 'roadmaps',
  product1: 'products',
  objectiveCorp: 'objectivesCorp',
  objective: 'objectives',
  keyResult1: 'keyResults1',
  keyResult2: 'keyResults2',
  category: 'categories',
  theme: 'themes',
  priority: 'priorities',
  timeframe: 'timeframes',
  initiative: 'parent_id',
  bet: 'parent_id',
};

/**
 * @function useChartTimelineLightbox
 *
 * @return {Object}
 */
export default function useChartTimelineLightbox(chartData, groupByEntityName = 'roadmap') {
  const { pageFilters, displayLayer } = usePageFilters(DASHBOARDS_PAGE);

  const systemFields = useSelector(state => state.organization.organization.system_fields_name);
  const [openTimeline, setOpenTimeline] = useState();

  const [clickedLabel, setClickedLabel] = useState();
  const [clickedElementId, setClickedElementId] = useState();

  const timelineProjectsGrouping = {
    key: groupByEntityName,
    title: clickedLabel,
  };

  const groupByEntity = useMemo(() => ({
    key: groupByEntityName,
    title: getSystemFieldName(groupByEntityName, systemFields),
  }));

  const registerOpenTimelineCallback = useCallback(
    openTimeline => {
      setOpenTimeline(() => openTimeline);
    },
    [setOpenTimeline],
  );

  const handleOnChartClick = useCallback(
    (event, elements) => {
      const clickedElement = head(elements);

      const clickedDataset = chartData?.datasets?.[clickedElement?.datasetIndex];

      if (!clickedDataset) {
        return;
      }

      const clickedGroupId = chartData?.ids?.[clickedElement?.index];

      const dataBucketId = path(['dataBucketIds', clickedGroupId], clickedDataset);

      const clickedLabel = chartData?.unclampedLabels?.[clickedElement?.index] || chartData?.labels?.[clickedElement?.index];

      if (!clickedLabel) {
        return;
      }

      const projectField = supportedFilterFields[groupByEntityName];

      setClickedElementId(String(clickedGroupId));
      setClickedLabel(clickedLabel);

      const timelineFilters = buildFilterForTimelineProjects(
        pageFilters,
        projectField,
        clickedGroupId,
        displayLayer,
        dataBucketId,
      );

      openTimeline(timelineFilters);
    },
    [chartData, openTimeline, displayLayer],
  );

  return {
    handleOnChartClick,
    registerOpenTimelineCallback,
    timelineLightboxOpenItems: [clickedElementId],
    timelineProjectsGrouping,
    groupByEntity,
    timelineDisplayLayer: displayLayer,
  };
}
