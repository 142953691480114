import React from 'react';
import styled from 'styled-components';

import TextField from '@material-ui/core/TextField';

const InputTextField = props => {
  return (
    <>
      <StyledInputTextField
        placeholder={props.placeholder}
        className={props.className}
        onBlur={props.onBlur}
        error={props.error}
        label={props.label}
        type={props.type}
        name={props.name}
        margin="normal"
        variant="outlined"
        size={props.size}
        value={props.value || ''}
        onChange={event => props.handleValue(event.target.value)}
        helperText={props.helperText}
        FormHelperTextProps={{ error: props.error }}
        InputProps={{
          classes: {
            focused: 'focused',
            error: 'error',
          },
        }}
      />
    </>
  );
};

export default InputTextField;

const mapPaddingSizes = {
  small: '8px 14px',
  medium: '12.5px 14px',
  large: '18.5px 14px',
};

const StyledInputTextField = styled(TextField)`
  &&&& {
    width: 100%;
    margin: 0;

    > div {
      background-color: white;
    }

    p {
      margin: ${props => props.theme.spacing.unit}px 0 0;
    }

    .focused fieldset {
      border-color: ${props => props.theme.palette.background.primary};
    }

    .error fieldset,
    .focused.error fieldset {
      border-color: ${props => props.theme.palette.text.error};
    }

    fieldset {
      border-color: ${props => props.theme.palette.text.lilacTime};
    }

    input {
      padding: ${props => mapPaddingSizes[props.size || 'large']};
    }

    input::placeholder {
      color: ${props => props.theme.palette.text.lilacTime};

      font-weight: ${props => props.theme.typography.fontWeightMedium};
      font-size: ${props => props.theme.typography.fontSize}px;
      font-family: ${props => props.theme.typography.fontFamily};
    }
  }
`;
