/* eslint-disable */
import React from 'react';

export default props => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.5 9.75V3.75C13.4988 3.35254 13.3404 2.9717 13.0594 2.69065C12.7783 2.4096 12.3975 2.25119 12 2.25H2.25C1.85254 2.25119 1.4717 2.4096 1.19065 2.69065C0.909604 2.9717 0.751187 3.35254 0.75 3.75V9.75C0.751187 10.1475 0.909604 10.5283 1.19065 10.8093C1.4717 11.0904 1.85254 11.2488 2.25 11.25H12C12.3975 11.2488 12.7783 11.0904 13.0594 10.8093C13.3404 10.5283 13.4988 10.1475 13.5 9.75ZM12 9.75H2.25V3.75H12V9.75ZM7.5 4.5C7.05499 4.5 6.61998 4.63196 6.24997 4.87919C5.87996 5.12643 5.59157 5.47783 5.42127 5.88896C5.25097 6.3001 5.20642 6.7525 5.29323 7.18895C5.38005 7.62541 5.59434 8.02632 5.90901 8.34099C6.22368 8.65566 6.62459 8.86995 7.06105 8.95677C7.4975 9.04358 7.9499 8.99903 8.36104 8.82873C8.77217 8.65843 9.12357 8.37004 9.37081 8.00003C9.61804 7.63002 9.75 7.19501 9.75 6.75C9.75 6.15326 9.51295 5.58097 9.09099 5.15901C8.66903 4.73705 8.09674 4.5 7.5 4.5Z"
      fill="#88929B"
    />
    <path
      d="M17.2499 13.5V8.25C17.2488 7.85254 17.0903 7.4717 16.8093 7.19065C16.5282 6.9096 16.1474 6.75119 15.7499 6.75H5.99994C5.60248 6.75119 5.22164 6.9096 4.9406 7.19065C4.65955 7.4717 4.50113 7.85254 4.49994 8.25V13.5C4.49994 14.325 4.42494 15 5.24994 15H15.7499C16.1474 14.9988 16.5282 14.8404 16.8093 14.5593C17.0903 14.2783 17.2488 13.8975 17.2499 13.5ZM15.7499 13.5H5.99994V8.25H15.7499V13.5ZM10.4999 8.25C10.0549 8.25 9.61992 8.38196 9.24991 8.62919C8.8799 8.87643 8.59151 9.22783 8.42122 9.63896C8.25092 10.0501 8.20636 10.5025 8.29318 10.939C8.37999 11.3754 8.59429 11.7763 8.90895 12.091C9.22362 12.4057 9.62453 12.62 10.061 12.7068C10.4974 12.7936 10.9498 12.749 11.361 12.5787C11.7721 12.4084 12.1235 12.12 12.3707 11.75C12.618 11.38 12.7499 10.945 12.7499 10.5C12.7499 9.90326 12.5129 9.33097 12.0909 8.90901C11.669 8.48705 11.0967 8.25 10.4999 8.25Z"
      fill="#88929B"
    />
    <path d="M3.04929 13.8751H1.45068L2.24999 12.6761L3.04929 13.8751Z" fill="#858D97" />
    <path d="M2.25 12L3.75 14.25H0.75L2.25 12Z" fill="#88929B" />
    <path d="M14.9507 4.1249H16.5493L15.75 5.32385L14.9507 4.1249Z" fill="#858D97" />
    <path d="M15.75 6L14.25 3.75L17.25 3.75L15.75 6Z" fill="#88929B" />
  </svg>
);
