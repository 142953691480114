import React, { useMemo } from 'react';

import { numericColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import { BubbleNumericCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import CommentIcon from 'design-system/atoms/CommentIcon';
import useCustomerRequestsCommentsLightbox from 'hooks/customerRequests/useCustomerRequestsCommentsLightbox';
import { defaultExport, exportDecimal, exportValueAsPercentage } from 'features/GridExport/helpers/exportUtils';

const numericColCommonDefs = { ...numericColumnDef, exportFn: defaultExport };

/**
 * @function useCustomerRequestsScoringFieldsColumnDefs
 *
 * Uses scoring fiels column definitions
 *
 * @return {Array}
 */
const useCustomerRequestsScoringFieldsColumnDefs = () => {
  const { openCommentsLightbox } = useCustomerRequestsCommentsLightbox();

  const commentColCommonDefs = useMemo(
    () => ({
      linkIcon: <CommentIcon />,
      cellRendererParams: {
        withoutZeros: true,
      },
      width: 120,
    }),
    [],
  );

  const benefitColumnDef = useMemo(() => {
    return {
      ...numericColCommonDefs,
      ...commentColCommonDefs,
      field: 'business_value',
      headerName: 'Benefit',
      onLinkClick: id => openCommentsLightbox({ requestId: id, field: 'business_value' }),
    };
  }, [commentColCommonDefs, openCommentsLightbox]);

  const effortColumnDef = useMemo(() => {
    return {
      ...numericColCommonDefs,
      ...commentColCommonDefs,
      field: 'effort_score',
      headerName: 'Effort',
      onLinkClick: id => openCommentsLightbox({ requestId: id, field: 'effort_score' }),
    };
  }, [commentColCommonDefs, openCommentsLightbox]);

  const riceScoreColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      ...commentColCommonDefs,
      editable: false,
      field: 'rice_score',
      headerName: 'RICE Score',
      cellRenderer: BubbleNumericCellRenderer,
      onLinkClick: id => openCommentsLightbox({ requestId: id, field: 'rice_score' }),
      exportFn: exportDecimal,
    };
  }, [commentColCommonDefs, openCommentsLightbox]);

  const moarColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      editable: false,
      field: 'moar',
      headerName: 'MoAR',
      cellRenderer: BubbleNumericCellRenderer,
      cellRendererParams: {
        withoutZeros: true,
        suffix: '%',
        styleAsLink: true,
      },
      width: 100,
      exportFn: exportValueAsPercentage,
    };
  }, [commentColCommonDefs, openCommentsLightbox]);

  const reachScoreColumnDef = useMemo(() => {
    return {
      ...numericColCommonDefs,
      ...commentColCommonDefs,
      field: 'reach_score',
      headerName: 'Reach Score',
      cellRendererParams: {
        withoutZeros: true,
      },
      onLinkClick: id => openCommentsLightbox({ requestId: id, field: 'reach_score' }),
    };
  }, [commentColCommonDefs, openCommentsLightbox]);

  const impactScoreColumnDef = useMemo(() => {
    return {
      ...numericColCommonDefs,
      ...commentColCommonDefs,
      field: 'impact_score',
      headerName: 'Impact Score',
      cellRendererParams: {
        withoutZeros: true,
      },
      onLinkClick: id => openCommentsLightbox({ requestId: id, field: 'impact_score' }),
    };
  }, [commentColCommonDefs, openCommentsLightbox]);

  const confidenceScoreColumnDef = useMemo(() => {
    return {
      ...numericColCommonDefs,
      ...commentColCommonDefs,
      field: 'confidence_score',
      headerName: 'Confidence Score',
      cellRendererParams: {
        withoutZeros: true,
      },
      onLinkClick: id => openCommentsLightbox({ requestId: id, field: 'confidence_score' }),
    };
  }, [commentColCommonDefs, openCommentsLightbox]);

  return useMemo(
    () => [
      benefitColumnDef,
      effortColumnDef,
      riceScoreColumnDef,
      moarColumnDef,
      reachScoreColumnDef,
      impactScoreColumnDef,
      confidenceScoreColumnDef,
    ],
    [
      benefitColumnDef,
      effortColumnDef,
      riceScoreColumnDef,
      moarColumnDef,
      reachScoreColumnDef,
      impactScoreColumnDef,
      confidenceScoreColumnDef,
    ],
  );
};

export default useCustomerRequestsScoringFieldsColumnDefs;
