import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { and, not } from 'ramda';

import { getCurrentUserLoginCount } from 'store/login/selectors';
import useOnboardingDemo from 'hooks/onboarding/useOnboardingDemo';

import NewDialog from 'design-system/organisms/NewDialog/index';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';

import { spacing } from 'design-system/theme';
import ChatIconTransition from './components/ChatIconTransition/ChatIconTransition';
import DemoDialogContent from 'containers/DiscoveryBotDialog/components/DemoDialogContent';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';
import useQueryParam from 'hooks/useQueryParam';

const DiscoveryBotDialog = () => {
  const loginCount = useSelector(getCurrentUserLoginCount);

  const hasDiscoveryBot = useFeatureFlags([FeatureFlags.HAS_DEMO_BOT_AI_ENABLED]);

  const {
    openDemoSplashPage,
    setDemoSplashPageShown,
    isGuideDemoDataLoading,
    selfGuidedDemoData,
    demoSplashPageShown,
    isDemoSplashPageOpen,
    isOnboardingDemoOrganization,
    isBookADemoOpen,
    shouldShowHeader,
    removeNavOptionsParams,
    onCloseDialog,
    handleSelectGuide,
    selectedMenuOption,
    selectedSubMenuOption,
    navigationOptions,
    setIsBookADemoOpen,
    handleMenuOptionChangeOnURL,
    handleGuideChangeOnURL,
    kickoffPendoFlow,
    hasDemoQueryParam,
  } = useOnboardingDemo();

  useQueryParam('option', handleMenuOptionChangeOnURL);
  useQueryParam('guideId', handleGuideChangeOnURL);

  useEffect(() => {
    kickoffPendoFlow();
  }, []);

  const demoSplashPageHasNotBeenSeen = and(not(demoSplashPageShown), not(isDemoSplashPageOpen));

  const shouldOpenSplashPageOnFirstLoad = isOnboardingDemoOrganization && demoSplashPageHasNotBeenSeen && loginCount === 1;

  const shouldOpenSplashPageWithQueryParams = hasDemoQueryParam && !isDemoSplashPageOpen;

  if (shouldOpenSplashPageOnFirstLoad || shouldOpenSplashPageWithQueryParams) {
    setDemoSplashPageShown();
    openDemoSplashPage();
  }

  const renderDialogContent = () => {
    if (isGuideDemoDataLoading) {
      return <PageLoading />;
    }

    return (
      <DemoDialogContent
        hasDiscoveryBot={hasDiscoveryBot}
        selfGuidedDemoData={selfGuidedDemoData}
        hasDemoQueryParam={hasDemoQueryParam}
        isBookADemoOpen={isBookADemoOpen}
        shouldShowHeader={shouldShowHeader}
        removeNavOptionsParams={removeNavOptionsParams}
        onCloseDialog={onCloseDialog}
        handleSelectGuide={handleSelectGuide}
        selectedMenuOption={selectedMenuOption}
        selectedSubMenuOption={selectedSubMenuOption}
        navigationOptions={navigationOptions}
        setIsBookADemoOpen={setIsBookADemoOpen}
      />
    );
  };

  return (
    <StyledDialog
      open={isDemoSplashPageOpen}
      TransitionComponent={ChatIconTransition}
      fullWidth
      fullHeight
      width="100%"
      closeButton
      paddingOverride="0px"
      onClose={onCloseDialog}
      disableBackdropClick
      increaseTopMargin={false}
      withMinimizeCloseButton
    >
      {renderDialogContent()}
    </StyledDialog>
  );
};

const StyledDialog = styled(NewDialog)`
  &&&& {
    div[role='document'] > div {
      height: 100%;
      max-height: 100%;
      margin: 0;

      //When on the Preview screen, we need a bigger top margin to display some content
      ${({ increaseTopMargin }) =>
        increaseTopMargin &&
        css`
          margin-top: ${spacing(10)}px;
          min-height: calc(100% - 128px);
          max-height: calc(100% - 128px);
        `}
    }
  }
`;

export default DiscoveryBotDialog;
