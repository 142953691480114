import axios from 'axios';
import { toast } from 'react-toastify';

import { fetchCustomFields } from 'store/customFields';

import {
  ADD_ORG_INTEGRATION,
  UPDATE_ORG_INTEGRATION,
  ADD_USER_INTEGRATION,
  FETCH_AVAILABLE_INTEGRATION_FIELDS,
  FETCH_ORG_INTEGRATION_FIELD_MAPPING,
  REMOVE_ONE_STEP_ORG_INTEGRATION,
  RESTORE_ORG_INTEGRATION_DATA,
  UPDATE_ORG_INTEGRATION_FIELD_MAPPING,
} from './types';
import { createThunk } from 'utils/store/thunk';
import { FETCH_ORG_INTEGRATIONS } from 'store/organization/types';
import { FETCH_USER_INTEGRATIONS } from 'store/login/types';
import { getAvailableIntegrations } from './selectors';
import { renderSalesforceErrorToast } from '../components/ErrorMessages';

const sendSuccessToast = integrationName => {
  toast(`${integrationName || ''} Integration Successful`, { success: true });
};

export const addOrgIntegration = (integrationType, data) => {
  return dispatch => {
    const payload = axios.post(`/api/org-integrations/${integrationType}`, data).then(res => res.data);

    dispatch({
      type: ADD_ORG_INTEGRATION,
      payload,
    });

    return payload;
  };
};

export const updateOrgIntegration = (orgIntegrationId, data) => {
  return dispatch => {
    const payload = axios.put(`/api/org-integrations/${orgIntegrationId}`, data).then(res => res.data);

    dispatch({
      type: UPDATE_ORG_INTEGRATION,
      payload,
    });

    return payload;
  };
};

export const removeOrgIntegration = (integrationType, orgIntegrationId) => {
  return dispatch => {
    const payload = axios.delete(`/api/org-integrations/${integrationType}/${orgIntegrationId}`);

    dispatch({
      type: REMOVE_ONE_STEP_ORG_INTEGRATION,
      payload,
      meta: {
        integrationType,
        orgIntegrationId,
      },
    });

    return payload;
  };
};

export const addUserIntegration = (integrationType, orgIntegrationId, data, showSuccessToast) => {
  return (dispatch, getState) => {
    const availableIntegrations = getAvailableIntegrations(getState());

    const payload = axios.post(`/api/user-integrations/${integrationType}/${orgIntegrationId}`, data).then(({ data }) => {
      dispatch(fetchCustomFields());
      if (showSuccessToast) {
        sendSuccessToast(availableIntegrations[integrationType]?.title);
      }
      return data;
    });

    dispatch(createThunk(ADD_USER_INTEGRATION, payload, { toastErrorMessage: 'Invalid credentials' }));
  };
};

export const restoreOrgIntegrationData = (integrationType, orgIntegrationId, showSuccessToast) => {
  return (dispatch, getState) => {
    const availableIntegrations = getAvailableIntegrations(getState());

    const payload = axios.put(`/api/org-integrations/${integrationType}/${orgIntegrationId}/restore`).then(() => {
      if (showSuccessToast) {
        sendSuccessToast(availableIntegrations[integrationType]?.title);
      }
    });

    dispatch({ type: RESTORE_ORG_INTEGRATION_DATA, payload });

    return payload;
  };
};

export const updateFieldMapping = (integrationType, orgIntegrationId, mapping) => {
  return dispatch => {
    // TODO: WE NEED TO FORMAT MAPPING TO ACTUALLY MATCH
    const payload = axios
      .put(`/api/org-integrations/${integrationType}/${orgIntegrationId}/field-mapping`, { mapping })
      .then(res => ({ orgIntegrationId, data: res.data }));

    dispatch({
      type: UPDATE_ORG_INTEGRATION_FIELD_MAPPING,
      payload,
    });

    return payload;
  };
};

export const getAvailableFieldsFromIntegration = (integrationType, orgIntegrationId) => {
  return dispatch => {
    const payload = axios
      .get(`/api/org-integrations/${integrationType}/${orgIntegrationId}/fields`)
      .then(res => res.data)
      .catch(err => {
        // this needs to be handled in a more generic way / on integration
        if (integrationType === 'salesforce') {
          toast(renderSalesforceErrorToast({ message: err.response.data.error_code }));
        }
      });

    dispatch({
      type: FETCH_AVAILABLE_INTEGRATION_FIELDS,
      payload,
    });

    return payload;
  };
};

export const getOrgIntegrationFieldMapping = (integrationType, orgIntegrationId) => {
  return dispatch => {
    const payload = axios
      .get(`/api/org-integrations/${integrationType}/${orgIntegrationId}/field-mapping`)
      .then(res => ({ orgIntegrationId, data: res.data }));

    dispatch({
      type: FETCH_ORG_INTEGRATION_FIELD_MAPPING,
      payload,
    });

    return payload;
  };
};

// Importing these two actions from  store/organization and store/login causes dependency cycles
// for now we can copy the action and import only the type
export const fetchOrgIntegrations = () => {
  return dispatch => {
    const payload = axios.get('/api/integrations/organization').then(({ data }) => data);

    dispatch({
      type: FETCH_ORG_INTEGRATIONS,
      payload,
    });

    return payload;
  };
};

export const fetchUserIntegrations = () => dispatch => {
  const payload = axios.get('/api/integrations').then(({ data }) => data);

  dispatch({
    type: FETCH_USER_INTEGRATIONS,
    payload,
  });

  return payload;
};
