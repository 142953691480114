import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getActiveViewForPage } from 'store/userViews/selectors';
import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/userViews';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions/lib/constants';

const useCreateRoadmapSnapshot = () => {
  const [viewId, setViewId] = useState();
  const path = getCurrentPath();
  const pageId = getPageIdFromPath(path);
  const { canCreate } = usePermissions();

  const pageActiveView = useSelector(state => getActiveViewForPage(state, pageId));

  const canCreateSnapshot =
    !!pageActiveView?.id && !pageActiveView?.default_view && canCreate(PERMISSION_RESOURCES.roadmapHistorySnapshot);

  const onClick = () => setViewId(pageActiveView?.id);
  const onClose = () => setViewId(undefined);

  return {
    canCreateSnapshot,
    viewId,
    onClick,
    onClose,
  };
};

export default useCreateRoadmapSnapshot;
