import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

import BreadcrumbTitle from 'design-system/molecules/BreadcrumbTitle/index';

import { StyledFluidItem } from 'design-system/molecules/FluidField';
import { spacing } from 'design-system/theme';

const HierarchyBreadcrumbTitles = ({ item, parentItem, ancestryItems, showTooltip, className }) => {
  if (!item) return null;

  const parentContainerMargin = spacing(8) * ancestryItems?.length;

  return (
    <Wrapper item className={className}>
      {parentItem ? (
        <ParentContainer $marginRight={parentContainerMargin}>
          {ancestryItems?.map(item => (
            <>
              <Tooltip title={item.title}>
                <StyledIcon onClick={item.onClick}>{item.icon}</StyledIcon>
              </Tooltip>
              <Separator>\</Separator>
            </>
          ))}
          <BreadcrumbTitle {...parentItem} showTooltip={showTooltip} />
        </ParentContainer>
      ) : null}
      <BreadcrumbTitle {...item} showTooltip={showTooltip} />
    </Wrapper>
  );
};

export default HierarchyBreadcrumbTitles;

const Wrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  width: 100%;
  min-width: 0;

  &&&& ${StyledFluidItem} {
    padding: 0 ${spacing()}px 0 ${spacing(0.5)}px;
  }
`;

const ParentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2)}px;
  margin-right: ${({ $marginRight }) => $marginRight || 0}px;

  > div {
    max-width: 100%;
  }
`;

const StyledIcon = styled.div`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  svg {
    font-size: ${({ theme }) => theme.typography.fontSizeSmallLargeRem}rem;
  }
`;

const Separator = styled.span`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSizeMediumSmallRem}rem;
`;
