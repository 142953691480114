import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';

import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { getOrganization, deleteOrganizationData } from 'store/organization';
import { clearAuthTokenAndStore } from 'store/login';

const DeleteOrganizaton = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const { title, slug, id } = useSelector(getOrganization);
  const { isSuperAdmin } = user;

  const closeDialog = () => setIsOpen(false);
  const handleDeleteOrganization = () => setIsOpen(true);

  const deleteOrganization = async () => {
    closeDialog();
    await dispatch(deleteOrganizationData(id));
    dispatch(clearAuthTokenAndStore());
  };

  return (
    <Wrapper>
      <Button color="secondary" onClick={handleDeleteOrganization}>
        Delete Organization
      </Button>
      <ConfirmDialog
        id="delete-organization-dialog"
        isOpen={isOpen}
        onCancel={closeDialog}
        onConfirm={deleteOrganization}
        text="This will permanently delete this organization and all associated data. Are you sure you want to continue?"
        title={`Delete ${title || slug || 'Organization'}`}
        disableConfirm={!isSuperAdmin}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export default DeleteOrganizaton;
