import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import MergeLightbox from 'components/MergeLightbox';
import Text from 'design-system/atoms/Text/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import { isResourceUser, transformMergeItemLabel } from '../../../utils';

const MergeUsersDialog = ({ selectedItems, isVisible, hideDialog, onMergeUsers, disableSelectionMode }) => {
  const [isMergeToNoAccessUserMessageVisible, setIsMergeToNoAccessUserMessageVisible] = useState(false);

  const handleMerge = useCallback(
    (itemsIdsToMerge, persistItem) => {
      const itemToPersist = selectedItems.find(item => item.id === +persistItem);

      // Allow admin to merge user of any type
      // and if no access is selected
      // have another alert - the user xyz will have no access
      if (itemToPersist && isResourceUser(itemToPersist) && !isMergeToNoAccessUserMessageVisible) {
        setIsMergeToNoAccessUserMessageVisible(true);

        return Promise.reject();
      }

      return onMergeUsers(itemsIdsToMerge, persistItem);
    },
    [selectedItems, isMergeToNoAccessUserMessageVisible, onMergeUsers],
  );

  const hideMergeLightbox = useCallback(() => {
    hideDialog();

    if (isMergeToNoAccessUserMessageVisible) {
      setIsMergeToNoAccessUserMessageVisible(false);
    }
  }, [hideDialog, isMergeToNoAccessUserMessageVisible]);

  const showMergeToNoAccessUserMessage = useCallback(() => {
    return (
      <>
        <br />
        <TextDeprecated color="red">
          You’ve selected a user with no access to Dragonboat.
          <br />
          Click Confirm to continue or choose another user with access.
        </TextDeprecated>
        <br />
      </>
    );
  }, []);

  return (
    <MergeLightbox
      title={
        <TitleWrapper>
          <Text as="h3">Merge User</Text>
          <StyledText as="label">(Select the user to merge into and the others will no longer have access)</StyledText>
        </TitleWrapper>
      }
      items={selectedItems}
      dialogVisible={isVisible}
      merge={handleMerge}
      customConfirm={isMergeToNoAccessUserMessageVisible && showMergeToNoAccessUserMessage()}
      saveButtonLabel={isMergeToNoAccessUserMessageVisible ? 'Confirm' : 'Merge'}
      transformLabel={transformMergeItemLabel}
      hideDialog={hideMergeLightbox}
      disableSelectionMode={disableSelectionMode}
    />
  );
};

export default MergeUsersDialog;

const TitleWrapper = styled.div`
  min-width: 450px;
`;

const StyledText = styled(Text)`
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
