import React from 'react';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default class ContentDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.state.open !== nextProps.open) {
      this.setState({ open: nextProps.open });
    }
  }

  render() {
    const { onClose, top, content, contentStyles } = this.props;

    const { open } = this.state;

    return (
      <Wrapper anchor="right" elevation={0} open={!!open} onClose={onClose} top={top}>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <Content top={top} contentStyles={contentStyles}>
          {content}
        </Content>
      </Wrapper>
    );
  }
}

const Wrapper = styled(Drawer)`
  div[aria-hidden='true'] {
    background-color: transparent;
  }

  div[role='document'] {
    top: ${({ top }) => (top ? `${top}px` : '0')};
    border-radius: 2px;
    padding: 16px;
    min-width: 400px;
    border: 1px solid #ddd;
    box-shadow: 0px 0px 3px 1px #ddd;
  }
`;

const CloseButton = styled(IconButton)`
  &&&& {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 10;
  }
`;

const Content = styled.div`
  margin-top: 16px;
  max-height: calc(100vh - ${({ top }) => (top ? `${top + 29}px` : '29px')});
  overflow-x: auto;
  position: relative;

  ${({ contentStyles }) => contentStyles};
`;
