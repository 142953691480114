/* eslint-disable camelcase */
// External dependencies
import React from 'react';
import { compose } from 'redux';
import pluralize from 'pluralize';

// Dragonboat imports
import Component from './Component';
import useSystemFields from 'hooks/useSystemFields';
import { BET_LAYER } from 'store/projects/constants';

const ComponentHOC = () => {
  return (props) => {
    const [getSystemFieldName] = useSystemFields();

    let childrenObjectName = `Active ${getSystemFieldName('idea')}`;

    if (props.project.layer === BET_LAYER) childrenObjectName = getSystemFieldName('initiative');

    if (props.project.childrenCount) {
      return (
        <Component
          children={props.project.childrenCount}
          childrenObjectName={pluralize(childrenObjectName)}
          {...props}
        />
      );
    }

    return <div />;
  };
};

// Apply HOCs
export default
compose(
  ComponentHOC,
);
