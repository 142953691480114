const getSelectItemColumnDef = ({ hide = false, headerCheckboxSelection, checkboxSelection, showDisabledCheckboxes }) => {
  return {
    field: 'select',
    headerName: '',
    editable: false,
    suppressMovable: true,
    suppressSorting: true,
    suppressMenu: true,
    lockVisible: true,
    suppressColumnsToolPanel: true,
    hide,
    headerCheckboxSelection,
    checkboxSelection,
    showDisabledCheckboxes,
    width: 40,
  };
};

export default getSelectItemColumnDef;
