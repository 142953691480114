let selectedRows = [];
let selecting;

const useOnGridRowSelected = (select, unselect) => {
  const onSelected = e => {
    selectedRows.push(e.node.data);

    selecting && clearTimeout(selecting); // eslint-disable-line
    selecting = setTimeout(() => {
      if (e.node && (e.node.selected || e.node.selected === undefined)) {
        select(selectedRows);
      } else {
        unselect(selectedRows);
      }
      selectedRows = [];
    }, 0);
  };

  return [onSelected];
};

export default useOnGridRowSelected;
