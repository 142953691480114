import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { getLogin } from 'src/store/login/selectors';
import { getDefaultRoute } from 'utils';

const componentHOC = Component => {
  return ({ location }) => {
    const login = useSelector(getLogin);

    useEffect(() => {
      if (login.isAuthenticated) {
        return <Redirect to={getDefaultRoute(login.currentUser)} />;
      }
    }, []);

    const { email } = queryString.parse(location.search);

    return <Component email={email} />;
  };
};

export default componentHOC;
