import React from 'react';
import styled from 'styled-components';
import NextIcon from '@material-ui/icons/KeyboardArrowRight';
import PrevIcon from '@material-ui/icons/KeyboardArrowLeft';

import ImportIdeasDialog from 'containers/ImportIdeasDialog';
import Dialog from 'design-system/molecules/Dialog/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import PrimaryButton from 'design-system/atoms/PrimaryButton/index';


export default ({
  open,
  onClose,
  currentSlider,
  currSliderIndex,
  sliders,
  nextSlider,
  prevSlider,
  showSlider,
  selectedTemplate,
}) => {
  return (
    <React.Fragment>
      <StartGuideDialog open={!!open} width="1141px" onClose={onClose} closeButton closeButtonColor="#1F8F9E">
        <SlidesContainer>
          <TextDeprecated variant="h5" component="h1" color="#0C5986">
            {currentSlider.title}
          </TextDeprecated>
          <Slide>{currentSlider.image && <img alt={currentSlider.title} src={currentSlider.image} />}</Slide>
        </SlidesContainer>
        <Actions>
          {((currSliderIndex === 0 && selectedTemplate) || currSliderIndex > 0) && (
            <PrimaryButton iconpos="left" onClick={prevSlider}>
              <PrevIcon /> Back
            </PrimaryButton>
          )}
          <SliderNavigation>
            {sliders.map((slider, i) => (
              <SliderOption key={slider.title} active={currSliderIndex === i} onClick={() => showSlider(i)} />
            ))}
          </SliderNavigation>
          {currSliderIndex < sliders.length - 1 ? (
            <PrimaryButton align="right" iconpos="right" onClick={nextSlider}>
              Next Step <NextIcon />
            </PrimaryButton>
          ) : (
            <PrimaryButton align="right" variant="contained" bgcolor="#1F8F9E" textcolor="#FFF" onClick={nextSlider}>
              {selectedTemplate ? 'Select template and import ideas' : 'Finish'}
            </PrimaryButton>
          )}
        </Actions>
      </StartGuideDialog>
      <ImportIdeasDialog />
    </React.Fragment>
  );
};

const StartGuideDialog = styled(Dialog)`
  &&&& {
    > div[role='document'] > div {
      border-radius: 8px;
    }
  }
`;

const SlidesContainer = styled.div`
  margin: 0 auto;
  width: 957px;
  min-height: 290px;
  text-align: center;
  padding-top: 26px;
  position: relative;

  h1 {
    font-weight: bold;
  }
`;

const Slide = styled.div`
  width: 844px;
  height: 417px;
  opacity: 1;
  margin: 22px auto 0;

  img {
    width: 100%;
    height: auto;
  }
`;

const SliderNavigation = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const SliderOption = styled.div`
  width: 8px;
  height: 8px;
  background-color: #1f8f9e;
  border-radius: 50%;
  margin: 0 20px;
  opacity: 0.5;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    opacity: 1;
  `}
`;

const Actions = styled.div`
  display: flex;
  padding: 42px 80px 18px;
  overflow: hidden;
  align-items: center;
`;
