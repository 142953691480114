import { useCallback, useState } from 'react';
import { either, equals, isEmpty, isNil, not, pipe } from 'ramda';

const isKeyDownEnter = equals('Enter');
const isNotNilOrEmpty = pipe(either(isNil, isEmpty), not);

const useAddNewProject = onAddNewProjectKey => {
  const [isAddingNewProject, setIsAddingNewProject] = useState(false);
  const [newProjectKey, setNewProjectKey] = useState(null);

  const onChangeHandler = useCallback(({ target }) => {
    setNewProjectKey(target.value);
  }, []);

  const onKeyDownHandler = useCallback(
    ev => {
      if (isKeyDownEnter(ev.key) && isNotNilOrEmpty(newProjectKey)) {
        onAddNewProjectKey(newProjectKey);

        setNewProjectKey(null);
        setIsAddingNewProject(false);
      }
    },
    [newProjectKey, onAddNewProjectKey],
  );

  const onClickAddNewProject = useCallback(() => {
    setIsAddingNewProject(true);
  }, []);

  const onCancel = useCallback(() => {
    setNewProjectKey(null);
    setIsAddingNewProject(false);
  }, []);

  return {
    isAddingNewProject,
    onChangeHandler,
    onKeyDownHandler,
    onCancel,
    onClickAddNewProject,
  };
};

export default useAddNewProject;
