import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import { defaultTo, prop, propOr, pathOr, not, pipe, isNil, head, equals } from 'ramda';

import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import { getAllProjectsFromGroups } from 'store/projects/helpers/groupSelectors';
import { getKeyResult1Data, getKeyResult2Data, getObjectiveCorpData, getObjectiveData } from '../getOkrDataForIdeasGrid';

import {
  makeAddDefaultMetadataRoadmapName,
  formatMetadataRoadmapsForDisplay,
} from 'routes/Settings/hooks/helpers/useRoadmapsMetadata';

import theme from 'design-system/theme';

const INVISIBLE_CHARACTER = '‎'; // yes, there is a character here. trust me!!! dont touch it 😡

const OBJECTIVE_CORP = 'objectiveCorp';
const OBJECTIVE = 'objective';
const KEY_RESULT_1 = 'keyResult';
const KEY_RESULT_2 = 'keyResult2';
const METRICS = 'metrics';
const DEFAULT_ROADMAP_COLOR = theme.palette.newLayout.microChipBackground.lightBlue;

const defaultToEmptyArray = defaultTo([]);
const defaultToEmptyObject = defaultTo({});
const getOkrDefaultMetric = pipe(prop(METRICS), defaultToEmptyArray, head, defaultToEmptyObject);

const layerToLabel = {
  [IDEA_LAYER]: 'idea',
  [INITIATIVE_LAYER]: 'initiative',
  [BET_LAYER]: 'bet',
};

const groupsAdapterForGrid = (groups, addParent = true) => {
  if (isEmpty(groups)) {
    return [];
  }

  return groups.reduce((res, group) => {
    const groupLevel2Elements = group?.elements?.some(item => item?.group)
      ? group.elements.map(item => ({
          ...item,
          id: `group-2-${group.title}-${item.title}`,
          path: [group.title, item.title],
          type: item.groupOption.key,
          ...(addParent && { parent: group }),
        }))
      : [];

    const groupLevel3Elements = groupLevel2Elements?.some(group => group?.elements?.some(item => item.group))
      ? flatten(
          groupLevel2Elements.map(groupLevel2 =>
            flatten(
              groupLevel2.elements.map(item => ({
                ...item,
                id: `group-3-${group.title}-${groupLevel2.title}-${item.title}`,
                path: [group.title, groupLevel2.title, item.title],
                type: item.groupOption.key,
                ...(addParent && { parent: groupLevel2 }),
              })),
            ),
          ),
        )
      : [];

    const adaptedGroup = {
      ...group,
      id: `group-1-${group.title}`,
      path: [group.title],
      type: group.groupOption.key,
    };

    return [...res, adaptedGroup, ...groupLevel2Elements, ...groupLevel3Elements];
  }, []);
};

const getWrapGroupOnPath = useBet => (path, p, bet, initiative) => {
  let entry = p;

  if (useBet && bet && p.layer === IDEA_LAYER) {
    entry = bet;
  } else if ((!useBet || !bet) && initiative && p.layer === IDEA_LAYER) {
    entry = initiative;
  } else if (useBet && bet && p.layer === INITIATIVE_LAYER) {
    entry = bet;
  } else if (!useBet && initiative && p.layer === INITIATIVE_LAYER) {
    entry = initiative;
  }

  const projectGroupPath = p?.parentGroups ? p.parentGroups.map(g => g.title) : null;
  const entryPath = !entry.id && isEmpty(projectGroupPath) ? entry?.path : projectGroupPath;

  return [...(entryPath || []), ...path];
};

const isOkr = p => {
  if (p.groupData) {
    return (
      p.groupData.objective_corp_id || p.groupData.objective_id || p.groupData.key_result_1_id || p.groupData.key_result_2_id
    );
  }

  return false;
};

const getOkrType = p => {
  let result;

  if (p.groupData && p.groupData.objective_corp_id) {
    result = OBJECTIVE_CORP;
  } else if (p.groupData && p.groupData.objective_id) {
    result = OBJECTIVE;
  } else if (p.groupData && p.groupData.key_result_1_id) {
    result = KEY_RESULT_1;
  } else if (p.groupData && p.groupData.key_result_2_id) {
    result = KEY_RESULT_2;
  }

  return result;
};

const getDataToBeUsed = (singleLayer, displayLayer, allData, projectGroups) => {
  if (!isEmpty(projectGroups)) {
    return {
      [IDEA_LAYER]: [],
      [INITIATIVE_LAYER]: [],
      [BET_LAYER]: [],
      ...groupBy(getAllProjectsFromGroups(projectGroups), project => project.layer),
    };
  }

  return singleLayer
    ? {
        [IDEA_LAYER]: [],
        [INITIATIVE_LAYER]: [],
        [BET_LAYER]: [],
        [displayLayer]: allData[displayLayer],
      }
    : allData;
};

const isNotNil = pipe(isNil, not);
const getProp = propOr('');
const getOwnerName = pathOr('', ['owner', 'first_name']);
const getRoadmapId = prop('roadmap_id');

const isRoadmapLevel = (roadmap = {}) => {
  const roadmapId = getRoadmapId(roadmap);

  return isNil(roadmapId);
};

const isProduct1Level = (product = {}) => {
  const roadmapId = getRoadmapId(product);

  return isNotNil(roadmapId);
};

// same logic as objective drawer:
// only show products that had a specific click. We know that because in that case
// the product1 is on roadmap is not null

/**
 * function to exctrat the okr properties to be shown on ideas grid
 * they are extrated from okr object to the row root level
 *
 * @param {Object} row - object with AgGrid row information
 * @params {Object} okrType - the type of OKR
 * @params {Boolean} hasMetadataRoadmaps - flag to know if Roadmap is enabled
 */
const extractOkrDataForRootLevel = (row, okrType, hasMetadataRoadmaps, getDefaultRoadmapTitleForMetadataItem) => {
  let okr;

  if (okrType === OBJECTIVE_CORP) {
    okr = getObjectiveCorpData(row);
  } else if (okrType === OBJECTIVE) {
    okr = getObjectiveData(row);
  } else if (okrType === KEY_RESULT_1) {
    okr = getKeyResult1Data(row);
  } else if (okrType === KEY_RESULT_2) {
    okr = getKeyResult2Data(row);
  }

  if (!okr) return;

  // in the future an OKR can have multiple metrics. That's why this prop is an array.
  // For now we can just get the first element
  const metricSelected = getOkrDefaultMetric(okr);

  if (metricSelected && metricSelected.id) row.metric_id = metricSelected.id;

  const props = [
    'status_color',
    'description',
    'summary',
    'start_date',
    'end_date',
    'metrics',
    'metric_name',
    'metric_current',
    'metric_target',
    'metric_baseline',
  ];

  props.forEach(prop => (row[prop] = getProp(prop)(okr)));

  row.ownerName = getOwnerName(okr);
  row.okr_progress = getProp('progress')(okr);

  if (hasMetadataRoadmaps && getDefaultRoadmapTitleForMetadataItem) {
    const roadmaps = okr?.key_result_roadmaps || okr?.objective_roadmaps || [];

    const dataToExtractRoadmaps = {
      value: formatMetadataRoadmapsForDisplay(roadmaps),
      data: okr,
    };

    const formattedRoadmaps = makeAddDefaultMetadataRoadmapName(getDefaultRoadmapTitleForMetadataItem)(dataToExtractRoadmaps);

    // these objects are under the title prop to be possible to use the same column than ideas
    row.roadmapTitle = formattedRoadmaps
      .filter(roadmap => isRoadmapLevel(roadmap))
      .map(roadmap => ({ title: roadmap.title, color: roadmap.color }));
    row.product1Title = formattedRoadmaps
      .filter(product => isProduct1Level(product))
      .map(product => ({ title: product.title, color: product.color }));

    if (isEmpty(row.roadmapTitle)) {
      const defaultRoadmap = getDefaultRoadmapTitleForMetadataItem(okr);

      row.roadmapTitle = [{ title: defaultRoadmap, color: DEFAULT_ROADMAP_COLOR }];
    }
  }
};

const buildHierarchyData = project => ({
  parent_id: project?.id,
  [layerToLabel[project.layer]]: project,
  isHierarchy: true,
  layer: project.layer,
});

export default (
  allData,
  displayLayer,
  initiativesLabel,
  betsLabel,
  singleLayer = false,
  projectGroups,
  hasMetadataRoadmaps,
  getDefaultRoadmapTitleForMetadataItem,
  addGroupParent = true,
) => {
  const useBet = displayLayer === BET_LAYER;

  const dataToBeUsed = getDataToBeUsed(singleLayer, displayLayer, allData, projectGroups);

  const initiativesDict = dataToBeUsed[INITIATIVE_LAYER]?.reduce((dict, initiative) => {
    dict[initiative.id] = initiative;

    return dict;
  }, {});

  const betsDict = dataToBeUsed[BET_LAYER]?.reduce((dict, bet) => {
    dict[bet.id] = bet;

    return dict;
  }, {});

  const pathCacheById = {};
  const pathCacheByTitle = {};
  const getPathTitle = (id, title = '') => {
    if (id in pathCacheById) return pathCacheById[id];

    let cacheTitle = title;

    while (cacheTitle in pathCacheByTitle) {
      cacheTitle += INVISIBLE_CHARACTER;
    }
    pathCacheById[id] = cacheTitle;
    pathCacheByTitle[cacheTitle] = id;

    return cacheTitle + id;
  };

  const wrapGroupOnPath = getWrapGroupOnPath(useBet);

  const groupsData = groupsAdapterForGrid(projectGroups, addGroupParent);

  // on group rows we need to extract the fields we want to show to the obj root level
  (groupsData || []).forEach(data => {
    if (data.group && isOkr(data)) {
      const okrType = getOkrType(data);

      extractOkrDataForRootLevel(data, okrType, hasMetadataRoadmaps, getDefaultRoadmapTitleForMetadataItem);
    }
  });

  return dataToBeUsed[IDEA_LAYER]?.map(i => ({ ...i, pathTitle: getPathTitle(i.id, i.title), type: 'idea' }))
    .concat(
      dataToBeUsed[INITIATIVE_LAYER]?.map(i => ({
        ...i,
        pathTitle: getPathTitle(i.id, i.title),
        type: 'initiative',
      })),
    )
    .concat(dataToBeUsed[BET_LAYER]?.map(i => ({ ...i, pathTitle: getPathTitle(i.id, i.title), type: 'bet' })))
    .concat(groupsData)
    .reduce((acc, p) => {
      p.title = p.title || '';

      if (p.group) {
        if (isOkr(p)) {
          p.okrType = getOkrType(p);
        }

        acc.push(p);

        return acc;
      }

      let path = [pathCacheById[p.id]];

      let initiative;
      let bet;

      let initiativeTitle = `Without ${initiativesLabel}`;
      let withoutInitiative = false;

      if (!singleLayer) {
        if (p.layer === IDEA_LAYER) {
          initiative = initiativesDict[p.parent_id];

          // if idea has initiative put it under initiative
          if (initiative) initiativeTitle = pathCacheById[initiative.id];
          else withoutInitiative = true;
          // if not put it under "Initiatives" placeholder if has_bet is true
          // otherwise returns because we don't want to show ideas without initiative
          // else if (!useBet) return acc;

          path = [`${initiativeTitle}`, pathCacheById[p.id]];
        } else if (p.layer === INITIATIVE_LAYER) {
          initiative = p;
        }

        if (useBet && p.layer !== BET_LAYER) {
          let betTitle = `Without ${betsLabel}`;

          if (!initiative && p.parent_id) {
            bet = betsDict[p.parent_id];
          } else if (initiative && initiative.parent_id) {
            bet = betsDict[initiative.parent_id];
          }

          // if idea/initiative has bet put it under bet
          if (bet) {
            betTitle = pathCacheById[bet.id];
            path = [betTitle, ...path];
            // if not returns because we don't want to show ideas/initiatives without bet
          } else {
            const checkPath = wrapGroupOnPath([betTitle], p, bet);

            if (!acc.find(({ path }) => equals(path, checkPath))) {
              acc.push({
                title: betTitle,
                groupData: buildHierarchyData({ layer: BET_LAYER }),
                id: checkPath,
                path: checkPath,
              });
            }

            path = [betTitle, ...path];
            // return acc;
          }

          if (withoutInitiative) {
            const checkPath = wrapGroupOnPath([betTitle, initiativeTitle], p, bet, initiative);

            if (!acc.find(({ path }) => equals(path, checkPath))) {
              acc.push({
                title: initiativeTitle,
                groupData: buildHierarchyData({ layer: INITIATIVE_LAYER }),
                id: checkPath,
                path: checkPath,
              });
            }
          }
        }
      }

      acc.push({
        ...p,
        path: wrapGroupOnPath(path, p, bet, initiative),
        groupData: buildHierarchyData(p),
      });

      return acc;
    }, [])
    .filter(Boolean);
};
