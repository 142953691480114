import React from 'react';
import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { materialColors } from 'design-system/themes/default';
import theme from 'design-system/theme';

/**
 * Gets all the props for the Add Button and renders it
 * @param {Object} addButtonProps
 * @returns add button component
 */
const renderAddButton = addButtonProps => {
  const { onClick, addChildVisible, disabled } = addButtonProps;

  return (
    <ActionButton onClick={onClick} hideButton={!addChildVisible} disabled={disabled}>
      <StyledAddIcon />
    </ActionButton>
  );
};

/**
 * Gets all the props for the Open Button and renders it
 * @param {Object} openButtonProps
 * @returns open button component
 */
const renderOpenButton = openButtonProps => {
  const { onClick, icon: CustomOpenIcon } = openButtonProps;
  const OpenIcon = CustomOpenIcon || StyledOpenInNewIcon;

  return (
    <ActionButton onClick={onClick}>
      <OpenIcon />
    </ActionButton>
  );
};

const TitleWithActions = ({
  children,
  addButtonProps,
  openButtonProps,
  hasAddButton = false,
  hasOpenButton = false,
  inLineRender = false,
}) => {
  return (
    <Wrapper inLineRender={inLineRender}>
      {children}
      {hasAddButton || hasOpenButton ? (
        <ActionsWrapper className="actions" inLineRender={inLineRender}>
          {hasAddButton && renderAddButton(addButtonProps)}
          {hasOpenButton && renderOpenButton(openButtonProps)}
        </ActionsWrapper>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  ${({ inLineRender }) => (inLineRender ? 'justify-content: space-between' : '')};

  &:hover .actions {
    visibility: visible;
  }
`;

const ActionsWrapper = styled.div`
  position: ${({ inLineRender }) => (inLineRender ? 'relative' : 'absolute')};
  display: flex;
  right: 2px;
  visibility: hidden;
`;

const ActionButton = styled(IconButton)`
  &&&& {
    position: relative;
    display: ${({ hideButton }) => (hideButton ? 'none' : 'inherit')};
    width: 14px;
    height: 14px;
    color: ${materialColors.darkerGray};

    &:hover {
      background-color: ${theme.palette.border.gray};
      box-shadow: 0 0 3px 1px ${theme.palette.border.gray};
    }
  }
`;

const StyledAddIcon = styled(AddIcon)`
  &&&& {
    width: 18px;
    height: 18px;
    font-size: ${props => props.theme.typography.fontSize}px;
  }
`;

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  &&&& {
    width: 14px;
    height: 14px;
    font-size: ${props => props.theme.typography.fontSize}px;
  }
`;

export default TitleWithActions;
