import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Popper from '@material-ui/core/Popper';
import MuiPaper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import ProjectInfoCard from 'containers/ProjectInfoCard';
import { openProjectLightbox } from 'store/projectLightbox';

export default ({ currentTarget, position, onClickAway, dependencies, type }) => {
  const dispatch = useDispatch();
  const _handleClickProject = React.useCallback(projectId => dispatch(openProjectLightbox(projectId)), []);

  return (
    <>
      <Popper
        // anchorEl={currentTarget}
        // placement={type === 'blocks' ? 'right' : 'left'}
        open={!!currentTarget}
        style={{
          zIndex: 1101,
          position: 'fixed',
          top: position && position.top,
          left: type === 'blockedBy' ? position && position.left - 288 : position && position.left,
        }}
      >
        <div style={{ ...(type === 'blocks' ? { paddingLeft: 26 } : { paddingRight: 14 }) }}>
          <Paper>
            <ClickAwayListener onClickAway={onClickAway}>
              <div>
                {dependencies &&
                  dependencies.map(p => <ProjectInfoCard key={p.id} project={p} disabled onClickProject={_handleClickProject} />)}
              </div>
            </ClickAwayListener>
          </Paper>
        </div>
      </Popper>
    </>
  );
};

const Paper = styled(MuiPaper)`
  &&& {
    width: 288px;
    overflow: auto;
    padding: 10px;
    min-height: 50px;
    margin-left: -14px;
  }
`;
