export const SET_GLOBAL_SEARCH = 'SET_GLOBAL_SEARCH';
export const SET_SEARCH_BAR_OPEN = 'SET_SEARCH_BAR_OPEN';

export const SHOW_ADD_NEW_PROJECT = 'SHOW_ADD_NEW_PROJECT';
export const CLOSE_ADD_NEW_PROJECT = 'CLOSE_ADD_NEW_PROJECT';
export const SHOW_WELCOME_DIALOG = 'SHOW_WELCOME_DIALOG';
export const CLOSE_WELCOME_DIALOG = 'CLOSE_WELCOME_DIALOG';

export const SET_APP_ERROR = 'SET_APP_ERROR';
export const STRIPE_LOADED = 'STRIPE_LOADED';
export const ZENDESK_LOADED = 'ZENDESK_LOADED';
export const SET_GLOBAL_MESSAGE = 'SET_GLOBAL_MESSAGE';

export const SAVE_LAST_IDEAS_VIEW = 'SAVE_LAST_IDEAS_VIEW';
export const SAVE_LAST_GOALS_VIEW = 'SAVE_LAST_GOALS_VIEW';

export const SHOW_GITHUB_IMPORT_PROJECTS_DIALOG = 'SHOW_GITHUB_IMPORT_PROJECTS_DIALOG';
export const HIDE_GITHUB_IMPORT_PROJECTS_DIALOG = 'HIDE_GITHUB_IMPORT_PROJECTS_DIALOG';

export const SET_APP_GLOBAL_CONFIG = 'SET_APP_GLOBAL_CONFIG';

export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS';

export const SHOW_PERMISSIONS_DIALOG = 'SHOW_PERMISSIONS_DIALOG';
export const HIDE_PERMISSIONS_DIALOG = 'HIDE_PERMISSIONS_DIALOG';

export const SET_LEFT_NAV_EXPANDED_ITEM = 'SET_LEFT_NAV_EXPANDED_ITEM';
export const SET_PAGE_MODE = 'SET_PAGE_MODE';
export const SET_IS_METADATA_FETCHED = 'SET_IS_METADATA_FETCHED';

export const SET_GO_BACK_URL = 'SET_GO_BACK_URL';
export const ENABLE_ONE_CLICK_PLAN_MODE = 'ENABLE_ONE_CLICK_PLAN_MODE';
export const DISABLE_ONE_CLICK_PLAN_MODE = 'DISABLE_ONE_CLICK_PLAN_MODE';

export const LOCATION_CHANGE = 'LOCATION_CHANGE';

export const UPDATE_IS_SCENARIO_ROUTE = 'UPDATE_IS_SCENARIO_ROUTE';
