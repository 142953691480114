import { isNewHomepageEnabled } from 'constants/flags';
import { organizationHasDashboardsEnabled } from './organizations/dashboards';

/**
 * @function hideDashboardHome
 * Checks if the new Homepage feature flag is active and if the organization has dashboards enabled.
 * Returns true if the new Homepage is active and if the Organization does not have dashboards enabled
 * meaning that the 'Dashboard - home' page should be hidden.
 * @param {*} organization Organization object
 */

export default organization => {
  const isDashboardsEnabled = organizationHasDashboardsEnabled(organization);

  return isNewHomepageEnabled && !isDashboardsEnabled;
};
