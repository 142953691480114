import React, { useState, useEffect, useMemo } from 'react';
import { flatten } from 'ramda';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import FieldsGroup from './components/FieldsGroup';
import { GROUP_CHECKBOXES, GROUP_MULTISELECT, CUSTOM_FIELDS_KEY } from './helpers';

const filterOnlyCheckboxesGroups = g => g.type === GROUP_CHECKBOXES;
const filterOnlyMultiselectGroups = g => g.type === GROUP_MULTISELECT;
const excludeCustomFieldsGroup = g => g.key !== CUSTOM_FIELDS_KEY;

const filterByDuplicatedFields = (acc, field) => {
  if (acc.some(f => f.field === field.field)) {
    return acc;
  }

  return [...acc, field];
};

const ShowColumnsDialog = ({ isOpen, gridColumns, onClose, changeColumnVisible, visibleColumnsOnGrid }) => {
  const [visibleColumns, setVisibleColumns] = useState(visibleColumnsOnGrid);

  useEffect(() => setVisibleColumns(visibleColumnsOnGrid), [isOpen]);

  const handleChangeColumnVisible = (field, visible) => {
    if (visible) {
      setVisibleColumns([...visibleColumns, ...(Array.isArray(field) ? field : [field])]);

      return;
    }

    setVisibleColumns(visibleColumns.filter(c => (Array.isArray(field) ? !field.includes(c) : c !== field)));
  };

  const hideColumns = columnsKeysToHide => {
    setVisibleColumns(visibleColumns.filter(c => !columnsKeysToHide.includes(c)));
  };

  const checkboxesGroups = gridColumns.filter(filterOnlyCheckboxesGroups).filter(excludeCustomFieldsGroup);

  /*
   * Will create a custom group fields to have all multiselect groups
   */
  const genericMultiselectGroup = useMemo(() => {
    const allMultiSelectGroups = gridColumns.filter(filterOnlyMultiselectGroups);
    const allFields = flatten(allMultiSelectGroups.map(g => g.fields));
    const fields = allFields.reduce(filterByDuplicatedFields, []);

    return {
      type: GROUP_MULTISELECT,
      title: '',
      fields,
    };
  }, [gridColumns]);

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth>
      <DialogTitle>Show Fields</DialogTitle>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <DialogContent>
        {/* Generic Multi select at top */}
        <FieldsGroup
          key="generic"
          group={genericMultiselectGroup}
          visibleColumns={visibleColumns}
          changeColumnVisible={handleChangeColumnVisible}
          hideColumns={hideColumns}
        />
        {checkboxesGroups &&
          checkboxesGroups.map(group => (
            <FieldsGroup
              key={group.key}
              group={group}
              visibleColumns={visibleColumns}
              changeColumnVisible={handleChangeColumnVisible}
              hideColumns={hideColumns}
            />
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
            changeColumnVisible(visibleColumns);
          }}
          color="primary"
        >
          Apply
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowColumnsDialog;

ShowColumnsDialog.propTypes = {
  isOpen: PropTypes.bool,
  gridColumns: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  changeColumnVisible: PropTypes.func.isRequired,
};

ShowColumnsDialog.defaultProps = {
  isOpen: false,
};

const CloseButton = styled(IconButton)`
  &&&& {
    position: absolute;
    top: 10px;
    right: 34px;
  }
`;
