/**
 * Text Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import styled from 'styled-components';
import { textColor } from 'design-system/themes/default';

export default styled.span`
  &&&& {
    font-weight: bold;
    color: ${({ color }) => color || textColor};
  }
`;
