import React, { useMemo } from 'react';
import styled from 'styled-components';
import { equals, pathOr, pipe } from 'ramda';

import ProjectType from 'containers/ProjectType';

import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { TitleCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import theme from 'design-system/theme';

const checkProjectIsBetOrInitiative = project => [+BET_LAYER, +INITIATIVE_LAYER].includes(+project.layer);

const PLANNING_STAGE_COMPLETED = 'Completed';
const checkPlanningStageIsCompleted = pipe(pathOr('', ['data', 'planningStage']), equals(PLANNING_STAGE_COMPLETED));

const ProjectTitleRenderer = params => {
  const { layer } = params.data;

  const isCompleted = checkPlanningStageIsCompleted(params);

  const icon = useMemo(() => {
    return <StyledProjectType layer={layer} />;
  }, [layer]);

  const projectIsBetOrInitiative = checkProjectIsBetOrInitiative(params.data);

  const shouldBeBoldAndDisplayChildrenCount = projectIsBetOrInitiative && params.portfolioMode;

  return (
    <TitleCellRenderer
      {...params}
      textColor={isCompleted && theme.palette.text.grey}
      icon={icon}
      displayChildrenCount={shouldBeBoldAndDisplayChildrenCount}
      bold={shouldBeBoldAndDisplayChildrenCount}
    />
  );
};

const StyledProjectType = styled(ProjectType)`
  width: 14px;

  & > svg {
    width: 100%;
  }
`;

export default ProjectTitleRenderer;
