import { useSelector } from 'react-redux';

import { getPermissionsSelector } from '../getPermissionsSelector';
import { useMemo } from 'react';

const usePermissions = () => {
  const permissions = useSelector(state => getPermissionsSelector(state));

  const { canView, canUpdate, canCreate, canDelete, canMerge } = useMemo(() => {
    // Calling permissions.canView to ensure permissions can use the correct this value inside the class
    return {
      canView: (resource, context) => permissions.canView(resource, context),
      canUpdate: (resource, context) => permissions.canUpdate(resource, context),
      canCreate: (resource, context) => permissions.canCreate(resource, context),
      canDelete: (resource, context) => permissions.canDelete(resource, context),
      canMerge: (resource, context) => permissions.canMerge(resource, context),
    };
  }, [permissions]);

  return {
    canView,
    canUpdate,
    canCreate,
    canDelete,
    canMerge,
  };
};

export default usePermissions;
