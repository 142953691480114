import React, { useRef, Fragment } from 'react';

import ImportWidget from 'components/ImportWidget';

import ImportWizardlightbox from './ImportWizardLightbox';

const ZENDESK_LINK = 'https://dragonboat.zendesk.com/hc/en-us/articles/9217842646807-Get-Started-with-the-Request-module';

export default props => {
  const importWidget = useRef();

  const onClickBrowseCSVFile = () => {
    props.close();
    if (importWidget) {
      importWidget.current.fileInput.click();
    }
  };

  return (
    <Fragment>
      <ImportWidget
        hideButton
        ref={ref => {
          importWidget.current = ref;
        }}
        import={props.importCustomerRequests}
        dataMapping={props.dataMapping}
        title={`Map Customer ${props.getSystemFieldName('request', true)} data`}
        titleUrl={ZENDESK_LINK}
        titleUrlMessage="(help here)"
        accept=".csv"
        isAsyncImport
      />
      <ImportWizardlightbox onClickBrowseCSVFile={onClickBrowseCSVFile} isOpen={props.isOpen} close={props.close} />
    </Fragment>
  );
};
