import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

export default withTheme()(styled.tbody`
  background: #fff;
  th {
    position: sticky;
    left: 0;
    z-index: 2;
    background: #fff;
  }
  tr:last-child {
    border-right: 1px solid ${({ theme }) => theme.palette.border.mercury};
  }
`);
