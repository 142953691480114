import React from 'react';
import withTheme from '@material-ui/core/styles/withTheme';
import FluidField from 'design-system/molecules/FluidField/index';
import InlineDatePickerNoBorder from 'design-system/atoms/InlineDatePickerNoBorder/index';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

const FluidDateField = props => (
  <FluidField
    {...props}
    editorRenderer={({ value, save, isEditing, disableEdit }) => (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <InlineDatePickerNoBorder
          clearable
          keyboard
          open
          format="YYYY-MM-DD"
          value={value || null}
          onChange={save}
          disabled={disableEdit}
          style={{ width: '100%' }}
        />
      </MuiPickersUtilsProvider>
    )}
    ignoreBlurOn={() => !!document.querySelector('#picker-popover')}
  />
);

FluidDateField.propTypes = { ...FluidField.propTypes };

export default withTheme()(FluidDateField);
