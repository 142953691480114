import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css, ThemeProvider } from 'styled-components';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Popper from '@material-ui/core/Popper';
import RootRef from '@material-ui/core/RootRef';

import MultiFilterSearchRow from 'design-system/atoms/MultiFilterSearchRow/index';
import MultiFilterClearRow from 'design-system/atoms/MultiFilterClearRow/index';

import DndList from 'design-system/organisms/DndList/DndList';
import theme from 'design-system/theme';

import ShowArchivedRow from './components/ShowArchivedRow';
import useTreeDropdown from './useTreeDropdown';

const TreeDropdown = props => {
  const {
    hasClearSelection,
    hasEmptyOption,
    hasMultiLevel,
    hasMultiSelection,
    hasSearchBar,
    hasShowArchived,
    inputRef, // Not a React ref; it is its `current` value.
    paperRef,
    isOpen,
    items,
    onChange,
    onClose,
    onGroupOpenClose,
    offsetLeft,
    offsetPaperY,
    placement,
    selectedItems,
    usePopper,
    displaySelectedValue, // For single selection only
    styleAsAutocomplete,
    onSearchChange,
    value: displayedValue,
    forceOpen,
  } = props;

  const {
    tree,
    search,
    showArchived,
    parsedSelectedItems,
    onClearSelectionHandler,
    onClickItemHandler,
    onSearchChangeHandler,
    onSelectItemHandler,
    onShowArchivedHandled,
  } = useTreeDropdown(items, {
    hasEmptyOption,
    hasMultiLevel,
    hasMultiSelection,
    onChange,
    onSearchChange,
    selectedItems,
  });

  const renderClearSelection = () => {
    return hasClearSelection ? (
      <ThemeProvider theme={theme}>
        <MultiFilterClearRow handleClear={onClearSelectionHandler} />
      </ThemeProvider>
    ) : (
      <></>
    );
  };

  const isSingleSelectionAndShouldDisplaySelectedValue = displaySelectedValue && !hasMultiSelection;

  const defaultSearchTerm = isSingleSelectionAndShouldDisplaySelectedValue ? displayedValue : '';

  const searchTerm = search ?? defaultSearchTerm;

  const searchBarInputRef = useRef(null);

  const renderSearchBar = () => {
    return hasSearchBar ? (
      <ThemeProvider theme={theme}>
        <MultiFilterSearchRow
          ref={searchBarInputRef}
          handleChange={onSearchChangeHandler}
          search={searchTerm}
          styleAsAutocomplete={styleAsAutocomplete}
        />
      </ThemeProvider>
    ) : null;
  };

  useEffect(() => {
    if (searchBarInputRef.current) {
      searchBarInputRef.current.focus();

      searchBarInputRef.current.select();
    }
  }, []);

  const renderShowArchived = () => {
    return hasShowArchived ? <ShowArchivedRow show={showArchived} onClick={onShowArchivedHandled} /> : <></>;
  };

  const DropdownComponent = usePopper ? Popper : Dropdown;

  return (
    <ThemeProvider theme={theme}>
      <DropdownComponent
        open={forceOpen || (usePopper ? !!inputRef : isOpen)}
        anchorEl={inputRef}
        placement={placement}
        offsetLeft={usePopper ? 0 : offsetLeft}
        disablePortal={usePopper}
      >
        <RootRef rootRef={paperRef}>
          <StyledPaper $usePopper={usePopper} $yOffset={offsetPaperY}>
            <ClickAwayListener onClickAway={onClose}>
              <DndList
                items={tree}
                selectedItems={parsedSelectedItems}
                checkable={hasMultiSelection}
                onSelectItem={onSelectItemHandler}
                onClickItem={onClickItemHandler}
                onGroupOpenClose={onGroupOpenClose}
                showIndeterminate
                renderClear={props.renderClear || renderClearSelection}
                renderSearch={renderSearchBar}
                renderShowArchived={renderShowArchived}
                styleAsAutocomplete={styleAsAutocomplete}
                width={!usePopper && inputRef?.offsetWidth - offsetLeft}
              />
            </ClickAwayListener>
          </StyledPaper>
        </RootRef>
      </DropdownComponent>
    </ThemeProvider>
  );
};

TreeDropdown.propTypes = {
  hasEmptyOption: PropTypes.bool,
  hasClearSelection: PropTypes.bool,
  hasMultiLevel: PropTypes.bool,
  hasMultiSelection: PropTypes.bool,
  hasSearchBar: PropTypes.bool,
  hasShowArchived: PropTypes.bool,
  inputRef: PropTypes.any,
  paperRef: PropTypes.any,
  usePopper: PropTypes.bool,
  isOpen: PropTypes.bool,
  items: PropTypes.any,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onGroupOpenClose: PropTypes.func,
  offsetLeft: PropTypes.number,
  offsetPaperY: PropTypes.number,
  placement: PropTypes.string,
  selectedItems: PropTypes.array,
};

TreeDropdown.defaultProps = {
  usePopper: false,
  hasEmptyOption: false,
  hasClearSelection: false,
  hasMultiLevel: false,
  hasMultiSelection: false,
  hasSearchBar: true,
  hasShowArchived: false,
  offsetLeft: 0,
  placement: 'bottom-start',
};

const Dropdown = styled(Popover)`
  &&&&& {
    left: ${({ offsetLeft }) => offsetLeft || 0}px;
  }
`;

const paperStylesAsGridCell = css`
  position: absolute;
  top: ${({ $yOffset }) => ($yOffset ? `-${$yOffset}px` : 0)};
`;

const StyledPaper = styled(Paper)`
  ${({ $usePopper }) => ($usePopper ? paperStylesAsGridCell : '')}
`;

export default TreeDropdown;
