import { defaultTo, isNil } from 'ramda';

const defaultToNullString = defaultTo('null');

/**
 * @function buildReleaseCapacityAllocationKey
 *
 * Biulds the key for release capacity allocation rows
 *
 * @param  {Number} timeframeId
 * @param  {String} metadataField {description}
 * @param  {Number} metadataId    {description}
 * @return {Strinf} {description}
 */
const buildReleaseCapacityAllocationKey = (timeframeId, metadataField, metadataId) => {
  if (isNil(timeframeId)) {
    throw new Error('timeframeId is required');
  }

  return [timeframeId, defaultToNullString(metadataField), defaultToNullString(metadataId)].join('-');
};

export default buildReleaseCapacityAllocationKey;
