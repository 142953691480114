import React, { useMemo } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import UserPermission from 'containers/UserPermission';
import DeleteAndConfirm from './DeleteAndConfirm';
import Button from '@material-ui/core/Button';
import usePermissions from 'hooks/permissions/usePermissions';
import { getPermissionResourceByObjectiveType } from 'utils/okrs';

const CLOSE = 'close';
const SAVE = 'save';

const Actions = ({ selected, resourceLabel, onDelete, onClose, onSave, disableButton, isNew, selectedType }) => {
  const { canDelete } = usePermissions();

  const disableDelete = useMemo(() => {
    if (!selected?.id) {
      return true;
    }

    const resource = getPermissionResourceByObjectiveType(selectedType);

    return !canDelete(resource, { data: selected });
  }, [selected, selectedType, canDelete]);

  const handleSave = () => {
    if (isNew) {
      onSave();
      return;
    }

    onClose();
  };

  return (
    <Footer container alignItems="center" justify="space-between" xs={12}>
      <UserPermission permission="DELETE_OBJECTIVE">
        <DeleteAndConfirm disableDelete={disableDelete} selected={selected} label={resourceLabel} onDelete={onDelete} />
      </UserPermission>
      <Button disabled={disableButton} onClick={handleSave} color="primary">
        {isNew ? SAVE : CLOSE}
      </Button>
    </Footer>
  );
};

export default Actions;

const Footer = styled(Grid)`
  height: 36px;
  padding: 0 60px 0 27px;
  width: 100%;
`;
