import React, { useCallback, useMemo } from 'react';
import SyncIcon from '@material-ui/icons/Sync';
import uniq from 'lodash/uniq';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import JiraIcon from 'design-system/atoms/JiraIcon/index';
import ClubHouseIcon from 'design-system/atoms/ClubHouseIcon/index';
import GithubIcon from 'design-system/atoms/GithubIcon/index';
import AzureDevopsIcon from 'design-system/atoms/AzureDevopsIcon/index';
import ToggleButton from 'design-system/molecules/ToggleButton/index';


export default ({ hasJiraIntegration, orgIntegrations, onChangeSetting, isSyncing, onSyncJira, onSyncStories, lsState }) => {
  const showStories = lsState.showStories || {};

  const orgIntegrationsExcludingJira = useMemo(
    () => orgIntegrations.filter(integration => integration.integrationType !== 'JIRA'),
    [orgIntegrations],
  );
  const availableOrgIntegrationTypes = useMemo(
    () => uniq(orgIntegrationsExcludingJira.map(integration => integration.integrationType)),
    [orgIntegrationsExcludingJira],
  );

  const onChangeShowStories = useCallback(
    integrationType => {
      let updatedValue = { ...showStories, [integrationType]: !showStories[integrationType] };

      if (Object.values(updatedValue).filter(v => !!v).length === 0) updatedValue = null;

      return onChangeSetting('showStories', updatedValue);
    },
    [onChangeSetting],
  );

  const _getIntegrationSyncButton = integrationType => {
    const _onSync = () => onSyncStories(integrationType);

    const mapper = {
      clubhouse: (
        <>
          <ToggleButton
            on={showStories.clubhouse}
            title="Display Shortcut Stories"
            icon={<ClubHouseIcon fill={showStories.clubhouse ? undefined : '#515151'} />}
            onChange={() => onChangeShowStories('clubhouse')}
            style={{ marginRight: 10 }}
          />
          <ButtonIcon
            size="small"
            style={{ marginRight: 10 }}
            title={isSyncing ? 'Syncing...' : 'Get issue update from Shortcut'}
            onClick={_onSync}
            disabled={isSyncing}
          >
            <SyncIcon />
          </ButtonIcon>
        </>
      ),
      github: (
        <>
          <ToggleButton
            on={showStories.github}
            title="Display GitHub Stories"
            icon={<GithubIcon fill={showStories.github ? undefined : '#000'} />}
            onChange={() => onChangeShowStories('github')}
            style={{ marginRight: 10 }}
          />
        </>
      ),
      azuredevops: (
        <ToggleButton
          on={showStories.azuredevops}
          title="Display Azure Devops Stories"
          icon={<AzureDevopsIcon fill={showStories.azuredevops ? null : '#000515'} />}
          onChange={() => onChangeShowStories('azuredevops')}
          style={{ marginRight: 10 }}
        />
      ),
    };

    if (!mapper[integrationType]) return '';

    return mapper[integrationType];
  };

  return (
    <React.Fragment>
      {hasJiraIntegration && (
        <React.Fragment>
          <ToggleButton
            on={showStories.jira}
            title="Display Jira issues"
            icon={<JiraIcon fill={showStories.jira ? undefined : '#515151'} />}
            onChange={() => onChangeShowStories('jira')}
            style={{ marginRight: 10 }}
          />
          {localStorage.getItem('syncJiraStories') === 'true' && (
            <ButtonIcon
              size="small"
              style={{ padding: '2px 8px', marginRight: 10 }}
              title={isSyncing ? 'Syncing...' : 'Get issue update from Jira'}
              onClick={onSyncJira}
              disabled={isSyncing}
            >
              <SyncIcon />
            </ButtonIcon>
          )}
        </React.Fragment>
      )}
      {availableOrgIntegrationTypes.map(integrationType => _getIntegrationSyncButton(integrationType))}
    </React.Fragment>
  );
};
