import { GET_PROJECT_FIELDS_CONFIGURATION_FULFILLED, UPDATE_PROJECT_FIELDS_CONFIGURATION_FULFILLED } from '../types';

const initialState = {};

const projectFieldsLayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECT_FIELDS_CONFIGURATION_FULFILLED: {
      return {
        ...state,
        [action?.meta?.layer]: action.payload?.data?.configuration || {},
      };
    }

    case UPDATE_PROJECT_FIELDS_CONFIGURATION_FULFILLED: {
      return {
        ...state,
        [action?.meta?.layer]: action.payload?.data?.configuration,
      };
    }

    default: {
      return state;
    }
  }
};

export { projectFieldsLayoutReducer, initialState };

export default projectFieldsLayoutReducer;
