import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentUser } from 'store/login/selectors';
import { showImportIdeasLightbox } from 'store/ideas';

import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useScenarioToolbarControls from 'containers/ScenarioToolbarControls/hooks/useScenarioToolbarControls';
import useScenariosNavigation from 'hooks/useScenariosNavigation';
import { selectHasKeyResults, selectHasKeyResults2, selectHasProducts2 } from 'store/organization';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = React.useState(false);

    const hasHierarchy = useSelector(state => state.organization.organization.has_hierarchy);
    const hasKeyResults = useSelector(selectHasKeyResults);
    const hasKeyResults2 = useSelector(selectHasKeyResults2);
    const hasProducts = useSelector(state => state.organization.organization.has_products);
    const hasProducts2 = useSelector(selectHasProducts2);
    const currentUser = useSelector(state => getCurrentUser(state));

    const { isRoadmapVersionCommitted, isRoadmapVersionsEnabled } = useRoadmapVersions();

    const _showImportIdeasLightbox = () => dispatch(showImportIdeasLightbox());

    const { isCreatingOrViewingScenario, onClickExitDraftMode } = useScenarioToolbarControls();

    const { navigateToScenarioModule } = useScenariosNavigation();

    const toggleDraftMode = () => {
      const localMode = isCreatingOrViewingScenario;

      if (!localMode) {
        navigateToScenarioModule();
      } else {
        onClickExitDraftMode();
      }
    };

    return (
      <Component
        {...props}
        hasHierarchy={hasHierarchy}
        hasKeyResults={hasKeyResults}
        hasKeyResults2={hasKeyResults2}
        hasProducts={hasProducts}
        hasProducts2={hasProducts2}
        isCreatingOrViewingScenario={isCreatingOrViewingScenario}
        isRoadmapVersionCommitted={isRoadmapVersionCommitted}
        currentUser={currentUser}
        showImportIdeasLightbox={_showImportIdeasLightbox}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        toggleDraftMode={toggleDraftMode}
        isRoadmapVersionsEnabled={isRoadmapVersionsEnabled}
      />
    );
  };
};

export default componentHOC;
