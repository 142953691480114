/* eslint-disable */
import React from 'react';

export default () => (
  <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 1.667C10 1.3373 10.0978 1.015 10.2809 0.740865C10.4641 0.466729 10.7245 0.253065 11.0291 0.126894C11.3337 0.000722349 11.6688 -0.0322899 11.9922 0.0320317C12.3156 0.0963532 12.6126 0.255119 12.8457 0.488253C13.0789 0.721387 13.2376 1.01842 13.302 1.34179C13.3663 1.66515 13.3333 2.00033 13.2071 2.30493C13.0809 2.60954 12.8673 2.86989 12.5931 3.05306C12.319 3.23623 11.9967 3.334 11.667 3.334C11.2249 3.334 10.8009 3.15837 10.4883 2.84575C10.1756 2.53312 10 2.10912 10 1.667ZM5 1.667C5 1.3373 5.09777 1.015 5.28094 0.740865C5.46411 0.466729 5.72446 0.253065 6.02907 0.126894C6.33367 0.000722349 6.66885 -0.0322899 6.99222 0.0320317C7.31558 0.0963532 7.61261 0.255119 7.84575 0.488253C8.07888 0.721387 8.23765 1.01842 8.30197 1.34179C8.36629 1.66515 8.33328 2.00033 8.20711 2.30493C8.08094 2.60954 7.86727 2.86989 7.59314 3.05306C7.319 3.23623 6.9967 3.334 6.667 3.334C6.22488 3.334 5.80088 3.15837 5.48825 2.84575C5.17563 2.53312 5 2.10912 5 1.667ZM0 1.667C0 1.3373 0.0977677 1.015 0.28094 0.740865C0.464112 0.466729 0.724462 0.253065 1.02907 0.126894C1.33367 0.000722349 1.66885 -0.0322899 1.99222 0.0320317C2.31558 0.0963532 2.61261 0.255119 2.84575 0.488253C3.07888 0.721387 3.23765 1.01842 3.30197 1.34179C3.36629 1.66515 3.33328 2.00033 3.20711 2.30493C3.08094 2.60954 2.86727 2.86989 2.59314 3.05306C2.319 3.23623 1.9967 3.334 1.667 3.334C1.22488 3.334 0.800876 3.15837 0.488253 2.84575C0.17563 2.53312 0 2.10912 0 1.667Z"
      fill="#2EA8E1"
    />
  </svg>
);
