import React from 'react';
import PropTypes from 'prop-types';
import { prop } from 'ramda';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import theme from 'design-system/theme';

import ChartWrapper from 'src/components/ReactChart/utils/ChartWrapper';
import useScenarioStackedBenefitChartData from './useScenarioStackedBenefitChartData';
import getLegendConfigForStackedChart from 'utils/charts/getLegendConfigForStackedChart';
import BarChart from 'components/ReactChart/BarChart';
import useBarStackedCharDatalabelsConfig from 'hooks/charts/useBarStackedCharDatalabelsConfig';
import { scaleForType } from '../utils/scales';
import ChartContent from '../ChartContent';

const MAX_LEGEND_ITEMS = 6;
const DATALABEL_USE_Y_PROPERTY = prop('y');

const ScenarioStackedBenefitChart = ({
  wrapper: Wrapper,
  minHeight,
  roadmapVersionId,
  projectedField,
  timeBucketMode,
  stackedByField,
  dateInterval,
  chartTitle,
  actionButtons,
}) => {
  const { chartData, chartScaleType } = useScenarioStackedBenefitChartData(
    roadmapVersionId,
    projectedField,
    timeBucketMode,
    stackedByField,
    dateInterval,
  );

  const datalabelsConfig = useBarStackedCharDatalabelsConfig(chartData.datasets, undefined, DATALABEL_USE_Y_PROPERTY);
  const xScale = scaleForType(chartScaleType);

  return (
    <Wrapper>
      <ChartContent title={chartTitle} actionButtons={actionButtons}>
        <ChartWrapper height={minHeight}>
          <BarChart
            data={chartData}
            plugins={[ChartDataLabels]}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'bottom',
                  ...getLegendConfigForStackedChart(MAX_LEGEND_ITEMS),
                },
                datalabels: {
                  ...datalabelsConfig,
                },
              },
              animation: {
                duration: 0,
              },
              scales: {
                x: {
                  stacked: true,
                  ...xScale({ dateInterval }),
                },
                y: {
                  stacked: true,
                  ticks: {
                    fontSize: theme.typography.fontSize,
                  },
                  grid: {
                    drawOnChartArea: false,
                  },
                },
              },
            }}
          />
        </ChartWrapper>
      </ChartContent>
    </Wrapper>
  );
};

ScenarioStackedBenefitChart.propTypes = {
  roadmapVersionId: PropTypes.number,
  wrapper: PropTypes.func,
};

export default ScenarioStackedBenefitChart;
