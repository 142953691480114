import Axios from 'axios';
import isFunction from 'lodash/isFunction';
import throwRequestError from '../throwRequestError';

export default (ACTION_TYPE, urlPath, stateKey) => {
  return (filename) => {
    return async (dispatch, getState) => {
      const state = getState();

      if (!filename) {
        return Promise.reject();
      }
      let bodyState = {};

      if (isFunction(stateKey)) {
        bodyState = stateKey(state);
      } else {
        bodyState = { [stateKey]: state[stateKey] };
      }

      const payload = Axios.post(
        '/api/screenshot/url',
        { url: urlPath, state: bodyState },
        { responseType: 'arraybuffer' }
      ).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }).catch(throwRequestError);

      dispatch({
        type: ACTION_TYPE,
        payload,
      });

      return payload;
    };
  };
};
