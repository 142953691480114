import React from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import Timeline, { snapToGridOptions } from 'design-system/organisms/Timeline/New';
import ControlsBar from './components/ControlsBar/ControlsBar';

import useSwimlaneProjects from 'routes/Swimlane/New/hooks/useSwimlaneProjects';
import useSwimlaneAuth from 'routes/Swimlane/New/hooks/useSwimlaneAuth';
import useScenarioVersionColors from 'routes/RoadmapVersions/hooks/useScenarioVersionColors';
import useBaseProjectsForSwimlaneComparison from './hooks/useBaseProjectsForSwimlaneComparison';
import useTimelinesForSwimlaneCompare from './hooks/useTimelinesForSwimlaneCompare';

import { makeCustomGroupTitleRenderer } from 'utils/swimlane/new';
import { tooltipLabels } from './helpers';

const milestoneChecker = () => true;

const CompareScenariosSwimlane = () => {
  const dispatch = useDispatch();

  // Get the projects and all related information
  const { isProjectLoading, displayLayer } = useSwimlaneProjects();

  // Get the timeline configuration for the swimlane
  // we need to untie with the ROADMAP_PAGE
  const timelineConfig = useTimelinesForSwimlaneCompare();

  const {
    lsState: { slotWidth, showLegend, showTooltip, hideEmptyLane, snapToGridOn },
    groupWidths,
    groupTitles,
    zoomMode,
  } = timelineConfig;
  // Get user's information
  const { isReadOnly } = useSwimlaneAuth();

  const { scenarioColors } = useScenarioVersionColors();
  const { groupedByProjectData, handleGroupHeaderResize } = useBaseProjectsForSwimlaneComparison(
    timelineConfig,
    displayLayer,
    scenarioColors,
  );

  const groupTitleRenderer = makeCustomGroupTitleRenderer(dispatch);

  return (
    <Wrapper isReadOnly={isReadOnly}>
      <ControlsBar />
      <StyledTimeline
        // for style purposes
        showLegend={showLegend}
        // data
        isLoading={isProjectLoading}
        data={groupedByProjectData}
        groupTitles={groupTitles}
        isMilestoneItemChecker={milestoneChecker}
        // options
        groupWidths={groupWidths}
        zoomMode={zoomMode}
        slotWidth={slotWidth}
        displayMilestone
        displayMilestoneOn
        showTooltip={showTooltip}
        snapToGridOn={snapToGridOn?.key || snapToGridOptions.TARGET_END_DATE}
        resizable={false}
        draggable={false}
        hideEmptyLane={hideEmptyLane}
        groupTitleRenderer={groupTitleRenderer}
        tooltipLabels={tooltipLabels}
        // handlers
        onResizeGroupHeader={handleGroupHeaderResize}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  margin: ${({ isReadOnly }) => (isReadOnly ? '116px 44px 0' : '0 44px')};
`;
const StyledTimeline = styled(Timeline)`
  height: ${({ showLegend }) => ` calc(100vh - (140px + ${showLegend ? '54px' : '0px'}))`};
  &&&& {
    width: 100%;
  }
`;

export default CompareScenariosSwimlane;
