import styled from 'styled-components';

const ButtonsContainer = styled.div`
  visibility: hidden;
  display: flex;
  padding-left: 8px;
`;

const OverflowTextContainer = styled.div`
  width: 100%;
  line-height: 24px;
  vertical-align: middle;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  &:hover {
    ${ButtonsContainer} {
      visibility: visible;
    }
  }
`;

export { OverflowTextContainer, ButtonsContainer, TextWrapper };
