import { BILLING_STATUSES } from 'constants/organizations';

const UNPAID_BILLING_STATUSES = [BILLING_STATUSES.SELFSERVE_TRIAL, BILLING_STATUSES.TRIAL, BILLING_STATUSES.FREE];

function checkIfOrgIsUnpaid(billingStatus) {
  return UNPAID_BILLING_STATUSES.includes(billingStatus);
}

export { 
  checkIfOrgIsUnpaid,
};
