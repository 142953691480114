import React, { useMemo } from 'react';
import styled from 'styled-components';

import Card from 'design-system/molecules/Card/index';

import WidgetGrid from 'design-system/organisms/WidgetGrid';
import GroupHeaderInnerRenderer from 'containers/Grids/OkrsGrid/components/GroupHeaderInnerRenderer';
import CenteredLoading from 'features/PortfolioOverview/components/CenteredLoading';
import useNumberOfTextRowsToDisplayOnGridCell from 'hooks/useNumberOfTextRowsToDisplayOnGridCell';
import { GRID_MAX_ROWS } from '../../constants/widgetsConfig';
import buildToolbarActions from '../buildToolbarActions';
import { getWidgetHeight } from '../helpers';
import useUpdateGridWidgetHeight from '../useUpdateGridWidgetHeight';
import usePortfolioOverviewOkrs from './usePortfolioOverviewOkrs';

const OkrsList = ({ id, isVisible, onVisibilityChange, userCanEdit, disableEdit, updateHeightOnLayout }) => {
  const {
    objectivesAndKeyResultsWithHierarchy,
    getRowId,
    dataLength,
    canGoToSettings,
    title,
    headerName,
    openObjectiveDrawer,
    goToOkrsSettings,
    isLoading,
  } = usePortfolioOverviewOkrs();

  const hasPagination = dataLength > GRID_MAX_ROWS;

  const { getNumberOfTextRowsToDisplayOnGridCell } = useNumberOfTextRowsToDisplayOnGridCell();

  const { updateWidgetHeight, gridHeight } = useUpdateGridWidgetHeight({
    widgetId: id,
    getWidgetHeight,
    updateHeightOnLayout,
    hasPagination,
    maxItems: GRID_MAX_ROWS,
  });

  const groupColumnDef = useMemo(
    () => ({
      headerName,
      field: 'title',
      suppressMovable: true,
      cellClass: 'ag-react-editable-container--expandable ag-title-cell',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: GroupHeaderInnerRenderer,
        suppressCount: true,
        suppressDoubleClickExpand: true,
        openObjectiveDrawer,
        getNumberOfTextRowsToDisplayOnGridCell,
      },
      rowDrag: false,
      editable: false,
      flex: 1,
    }),
    [openObjectiveDrawer],
  );

  const buildActions = () => {
    const showVisibilityAction = userCanEdit && !disableEdit;
    const visibilityAction = showVisibilityAction ? { value: isVisible, onChange: onVisibilityChange } : null;

    const settingsAction = canGoToSettings ? { onClick: goToOkrsSettings } : null;

    return buildToolbarActions({
      visibility: visibilityAction,
      settings: settingsAction,
    });
  };

  return (
    <StyledCard title={title} actions={buildActions()}>
      {isLoading ? (
        <CenteredLoading />
      ) : (
        <WidgetGrid
          hasPagination={hasPagination}
          gridHeight={gridHeight}
          getRowId={getRowId}
          data={objectivesAndKeyResultsWithHierarchy}
          isTreeView
          columnDefs={[groupColumnDef]}
          pageSize={GRID_MAX_ROWS}
          numberOfRecords={dataLength}
          setVisibleItems={i => updateWidgetHeight(i)}
        />
      )}
    </StyledCard>
  );
};

export default OkrsList;

const StyledCard = styled(Card)`
  height: 100%;
  overflow: hidden;
`;
