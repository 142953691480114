import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

import HistoryItem from './HistoryItem';

const History = props => {
  return (
    <div className={props.classes.container}>
      <ul>
        {props.objects.map(obj => (
          <HistoryItem
            {...obj}
            key={obj.id}
            systemFields={props.systemFields}
            orgMetadataForCorpLevels={props.orgMetadataForCorpLevels}
            customFormat={props.customFormat}
          />
        ))}
      </ul>
      {props.hasNext && (
        <Button
          disabled={props.isLoading}
          size="small"
          className={props.classes.showMoreButton}
          onClick={() => props.fetchNextPage()}
        >
          Show More
        </Button>
      )}
    </div>
  );
};

const styles = theme => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignContent: 'flex-start',
  },
  showMoreButton: {
    flexBasis: 'auto',
    width: '150',
    alignSelf: 'center',
    fontSize: 11,
  },
});

export default withStyles(styles)(History);
