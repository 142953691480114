import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  FETCH_OUTCOME_ALLOCATION_DATA,
  FETCH_OUTCOME_SNAPSHOT_DATA,
  PAGE_LOAD_OUTCOME_MODULE_GOALS,
  UPDATE_OUTCOME_MODULE_FILTERS,
} from '../types';

const { initialState, reducers } = getThunksInitialStateAndReducers([
  FETCH_OUTCOME_ALLOCATION_DATA,
  FETCH_OUTCOME_SNAPSHOT_DATA,
  UPDATE_OUTCOME_MODULE_FILTERS,
  PAGE_LOAD_OUTCOME_MODULE_GOALS,
]);

export { initialState };

export default reducers;
