import reduceReducers from 'reduce-reducers';
import { defaultTo } from 'ramda';

import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';

import {
  CLEAN_FORM_LAYOUT_STATE,
  CLOSE_FORM_LAYOUT_DIRTY_WARNING,
  FETCH_FIELDS_LAYOUT,
  FETCH_FIELDS_LAYOUT_FULFILLED,
  OPEN_FORM_LAYOUT_DIRTY_WARNING,
  SET_CURRENT_FORM_LAYOUT_DIRTY,
  UPSERT_FIELDS_LAYOUT,
  UPSERT_FIELDS_LAYOUT_FULFILLED,
} from './types';

export const initialState = {
  forms: {},
  operations: bulkThunkInitialState([FETCH_FIELDS_LAYOUT, UPSERT_FIELDS_LAYOUT]),
};

const defaultAsEmptyObject = defaultTo({});

const fieldsLayoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FIELDS_LAYOUT_FULFILLED:
    case UPSERT_FIELDS_LAYOUT_FULFILLED: {
      const { type, level } = action.meta;
      const { configuration } = defaultAsEmptyObject(action.payload.data);

      return {
        ...state,
        [type]: {
          ...defaultAsEmptyObject(state[type]),
          [level]: configuration,
        },
      };
    }
    case SET_CURRENT_FORM_LAYOUT_DIRTY: {
      const { type, isDirty } = action.payload;

      return {
        ...state,
        forms: {
          ...state.forms,
          [type]: {
            ...defaultAsEmptyObject(state.forms[type]),
            isDirty,
          },
        },
      };
    }
    case OPEN_FORM_LAYOUT_DIRTY_WARNING: {
      const { type } = action.payload;

      return {
        ...state,
        forms: {
          ...state.forms,
          [type]: {
            ...defaultAsEmptyObject(state.forms[type]),
            showFormLayoutDirtyWarning: true,
          },
        },
      };
    }
    case CLOSE_FORM_LAYOUT_DIRTY_WARNING: {
      const { type } = action.payload;

      return {
        ...state,
        forms: {
          ...state.forms,
          [type]: {
            ...defaultAsEmptyObject(state.forms[type]),
            showFormLayoutDirtyWarning: false,
          },
        },
      };
    }
    case CLEAN_FORM_LAYOUT_STATE: {
      const { type } = action.payload;

      return {
        ...state,
        forms: {
          ...state.forms,
          [type]: undefined,
        },
      };
    }
    default: {
      return state;
    }
  }
};

const operationsReducer = getThunksReducers([FETCH_FIELDS_LAYOUT, UPSERT_FIELDS_LAYOUT]);

const reducer = reduceReducers(initialState, fieldsLayoutReducer, ...operationsReducer);

export default reducer;
