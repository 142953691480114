import React, { useEffect } from 'react';
import { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import BaseLayout from 'design-system/organisms/BaseLayout/index';


import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import { PAGE_HEIGHT_OFFSET } from './helpers/constants';

import { fetchPersonasGridData } from 'features/PersonasGrid/store';
import { selectPersonasGridData } from 'features/PersonasGrid/store/selectors';

import PersonasList from './components/PersonasList';
import GridToolbar from 'features/PersonasGrid/components/GridToolbar';

const layoutContentStyles = css`
  min-height: calc(100vh - ${PAGE_HEIGHT_OFFSET}px);
  overflow-y: auto;
`;

const PersonasGrid = props => {
  const dispatch = useDispatch();
  const personasData = useSelector(selectPersonasGridData);

  const { canUpdate, canCreate, canDelete } = usePermissions();
  const canEditPersona = canUpdate(PERMISSION_RESOURCES.persona);
  const canCreatePersona = canCreate(PERMISSION_RESOURCES.persona);
  const canDeletePersona = canDelete(PERMISSION_RESOURCES.persona);

  useEffect(() => {
    dispatch(fetchPersonasGridData());
  }, []);

  return (
    <BaseLayout contentStyles={layoutContentStyles}>
      <GridToolbar canCreatePersona={canCreatePersona} />
      <PersonasList personas={personasData} canEditPersona={canEditPersona} canDeletePersona={canDeletePersona} />
    </BaseLayout>
  );
};

export default PersonasGrid;
