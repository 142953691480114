import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';

const ROW_HEIGHT_DEFAULT = 40;

const isLayer = (layer, compareTo) => compareTo.includes(layer);

const isBetOrInitiative = layer => isLayer(+layer, [+BET_LAYER, +INITIATIVE_LAYER]);

const isInitiativeOrIdea = layer => isLayer(+layer, [+INITIATIVE_LAYER, +IDEA_LAYER]);

export { ROW_HEIGHT_DEFAULT, isBetOrInitiative, isInitiativeOrIdea };
