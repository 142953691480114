import { useCallback, useEffect, useMemo, useState } from 'react';
import { keys, values, defaultTo } from 'ramda';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { getNormalizedRoadmaps } from 'store/roadmaps/selectors';

import usePageFilters from 'hooks/filters/usePageFilters';
import useSystemFields from 'hooks/useSystemFields';

import { DASHBOARDS_PAGE } from 'constants/filters';
import { UNDEFINED_LABEL } from 'constants/common';
import clampLabelSize from 'utils/charts/clampLabelSize';
import { materialColors } from 'design-system/themes/default';
import theme from 'design-system/theme';
import makeTooltipLabelWithPercentage from 'utils/charts/makeTooltipLabelWithPercentage';
import { getGroupOptions } from 'store/projects/helpers/groupOptions';
import useChartTimelineLightbox from '../../hooks/useChartTimelineLightbox';
import { HIDE_LEGEND_OPTION, HIDE_GRID_OPTION } from 'constants/charts';
import { tickStyles, tickWithPercentageSmallStyles } from '../utils/scales';
import { getOrgCustomUserProjectFields, getOrgHasCustomUserProjectFields } from 'store/organization';

const CHART_API_URL = '/api/v1/reports/dashboard/progress-by-roadmap-with-expected';
const EXPECTED_PROGRESS_LINE_COLOR = '#9900FE';
const MAX_NUM_CHARS_ROADMAP = 15;

const checkHasChartData = (chartData = {}) => Boolean(keys(chartData).length);
const defaultUndefinedLabel = defaultTo(UNDEFINED_LABEL);
const defaultToEmptyObject = defaultTo({});

const getLabelForRoadmap = (roadmapId, roadmaps) => {
  const label = defaultUndefinedLabel(defaultToEmptyObject(roadmaps)[roadmapId]?.title);

  return clampLabelSize(label, MAX_NUM_CHARS_ROADMAP);
};

const tooltipLabelFormatter = tooltipItem => makeTooltipLabelWithPercentage(tooltipItem);

const sliceIfNeeded = (arr, max) => (max ? arr.slice(0, max) : arr);

export default function useProgressByRoadmapExpectedChart(maxItems, dashboardState, disableClick) {
  const [reportData, setReportData] = useState({
    overallProgress: 0,
    dataset: {},
  });
  const roadmaps = useSelector(getNormalizedRoadmaps);

  const [getSystemFieldName] = useSystemFields();
  const { displayLayer, filtersForApiByLevels: projectsFilter } = usePageFilters(DASHBOARDS_PAGE);

  const expectedProgress = useMemo(() => reportData.overallProgress * 100, [reportData]);

  const chartData = useMemo(() => {
    const labels = keys(reportData.dataset).map(id => getLabelForRoadmap(id, roadmaps));
    const ids = keys(reportData.dataset);
    const dataValues = values(reportData.dataset).map(val => parseFloat((val * 100).toFixed(2)));

    const datasets = [
      {
        data: sliceIfNeeded(dataValues, maxItems),
        backgroundColor: ({ raw }) => (parseFloat(raw) > expectedProgress ? materialColors.green : materialColors.red),
        yAxisID: 'y',
      },
    ];

    return {
      ids: sliceIfNeeded(ids, maxItems),
      labels: sliceIfNeeded(labels, maxItems),
      datasets,
    };
  }, [expectedProgress, maxItems, reportData, roadmaps]);

  const {
    handleOnChartClick,
    registerOpenTimelineCallback,
    timelineDisplayLayer,
    timelineProjectsGrouping,
    groupByEntity,
    timelineLightboxOpenItems,
  } = useChartTimelineLightbox(chartData, 'roadmap');

  const chartOptions = useMemo(() => {
    return {
      onClick: disableClick ? null : handleOnChartClick,
      scales: {
        x: {
          min: 0,
          max: 100,
          ticks: {
            ...tickWithPercentageSmallStyles,
            beginAtZero: true,
          },
          grid: {
            ...HIDE_GRID_OPTION,
          },
        },
        y: {
          afterFit: scaleInstance => {
            scaleInstance.width = 150;
          },
          ticks: {
            ...tickStyles,
          },
          maxBarThickness: 30,
          grid: {
            ...HIDE_GRID_OPTION,
          },
        },
      },
      plugins: {
        legend: {
          ...HIDE_LEGEND_OPTION,
        },
        annotation: {
          annotations: {
            expectedProgressLine: {
              type: 'line',
              value: expectedProgress,
              endValue: expectedProgress,
              scaleID: 'x',
              borderColor: EXPECTED_PROGRESS_LINE_COLOR,
              borderDash: [5, 5],
            },
          },
        },
        datalabels: {
          align: 'end',
          anchor: 'end',
          offset: 5,
          font: {
            weight: theme.typography.fontWeightBold,
            color: theme.typography.color,
          },
          formatter: value => `${value}%`,
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelFormatter,
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    };
  }, [expectedProgress, disableClick, handleOnChartClick, chartData]);

  const hasData = useMemo(() => checkHasChartData(reportData.dataset), [reportData]);

  const hasCustomUserFields = useSelector(getOrgHasCustomUserProjectFields);
  const customUserFields = useSelector(getOrgCustomUserProjectFields);

  const groupOptions = useMemo(
    () =>
      getGroupOptions({
        getSystemFieldName,
        hasKeyResults: true,
        withNullOption: true,
        customUserFields: hasCustomUserFields ? customUserFields : {},
      }),
    [getSystemFieldName],
  );

  const selectedGroup1 = useMemo(() => groupOptions.find(go => go.key === 'roadmap'), [groupOptions]);

  const requestChartData = useCallback(async () => {
    const { data } = await axios.post(CHART_API_URL, projectsFilter);

    setReportData(data);
  }, [projectsFilter]);

  useEffect(() => {
    requestChartData();
  }, [projectsFilter]);

  return {
    chartData,
    chartOptions,
    displayLayer,
    getSystemFieldName,
    hasData,
    selectedGroup1,

    registerOpenTimelineCallback,
    timelineProjectsGrouping,
    groupByEntity,
    timelineLightboxOpenItems,
    timelineLightboxDisplayLayer: timelineDisplayLayer,
  };
}
