import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { when, isEmpty, head, match, not, pipe, trim } from 'ramda';

const isNotEmpty = pipe(isEmpty, not);
// eslint-disable-next-line no-useless-escape
const matchesAzureDomainRegex = match(/https:\/\/dev.azure.com\/[^\/]+/);

const doesNotStartWithAzureDomain = pipe(matchesAzureDomainRegex, isEmpty);

const CreateOrgIntegration = ({ onClickNext }) => {
  const [orgUri, setOrgUri] = useState('');

  // Auxiliary functions
  const saveFirstElement = pipe(head, setOrgUri);
  const ifMatchThenSave = when(isNotEmpty, saveFirstElement);
  const setCleanAzureURI = pipe(matchesAzureDomainRegex, ifMatchThenSave);

  useEffect(() => {
    setCleanAzureURI(orgUri);
  }, [orgUri]);

  const _handleURIInput = urlInput => {
    setOrgUri(trim(urlInput));
  };

  const _handleOnClick = useCallback(
    e => {
      e.preventDefault();
      if (onClickNext) onClickNext(orgUri);
    },
    [orgUri],
  );

  return (
    <Wrapper>
      <URLTextField
        id="fileName"
        label="Insert your Azure Devops organization URL"
        value={orgUri}
        onChange={e => _handleURIInput(e.target.value)}
        margin="normal"
      />
      <NextButton type="submit" color="primary" disabled={doesNotStartWithAzureDomain(orgUri)} onClick={_handleOnClick}>
        Next
      </NextButton>
    </Wrapper>
  );
};

CreateOrgIntegration.propTypes = {
  onClickNext: PropTypes.func,
};

const URLTextField = styled(TextField)``;

const NextButton = styled(Button)`
  &&&& {
    margin-left: 30px;
  }
`;

const Wrapper = styled.form`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;

  ${NextButton} {
    width: 100px;
    height: 40px;
  }

  ${URLTextField} {
    flex-grow: 1;
    margin-top: 0px;
  }
`;

export default CreateOrgIntegration;
