import React, { useEffect } from 'react';
import { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import BaseLayout from 'design-system/organisms/BaseLayout/index';


import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import { PAGE_HEIGHT_OFFSET } from './helpers/constants';

import { fetchLifecyclesGridData } from 'features/LifecyclesGrid/store';
import { selectLifecyclesGridData } from 'features/LifecyclesGrid/store/selectors';

import LifecyclesList from './components/LifecyclesList';
import GridToolbar from 'features/LifecyclesGrid/components/GridToolbar';

const layoutContentStyles = css`
  min-height: calc(100vh - ${PAGE_HEIGHT_OFFSET}px);
  overflow-y: auto;
`;

const LifecyclesGrid = props => {
  const dispatch = useDispatch();
  const lifecyclesData = useSelector(selectLifecyclesGridData);

  const { canUpdate, canCreate, canDelete } = usePermissions();
  const canEditLifecycle = canUpdate(PERMISSION_RESOURCES.lifecycle);
  const canCreateLifecycle = canCreate(PERMISSION_RESOURCES.lifecycle);
  const canDeleteLifecycle = canDelete(PERMISSION_RESOURCES.lifecycle);

  useEffect(() => {
    dispatch(fetchLifecyclesGridData());
  }, []);

  return (
    <BaseLayout contentStyles={layoutContentStyles}>
      <GridToolbar canCreateLifecycle={canCreateLifecycle} />
      <LifecyclesList lifecycles={lifecyclesData} canEditLifecycle={canEditLifecycle} canDeleteLifecycle={canDeleteLifecycle} />
    </BaseLayout>
  );
};

export default LifecyclesGrid;
