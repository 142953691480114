import { isEmpty } from 'ramda';

/**
 * @function assignDataToProjects creates a new list of project with the respective estimates
 * @param  {Array} projects List of projects
 * @param  {Array} estimates Estimates Data that should be filtered by project id
 * @return {Array} List of projects with new estimates field
 */
const assignEstimatesToProjects = (projects, estimates = []) => {
  if (isEmpty(estimates)) {
    return projects;
  }
  return projects.map(project => {
    const estimatesForProject = estimates.filter(data => project.id === data.project_id);

    return {
      ...project,
      estimates: estimatesForProject,
    };
  });
};

export default assignEstimatesToProjects;
