import { useState, useRef } from 'react';

const useAutocomplete = ({ onValueChange, onSelectOption, onGroupClickCallback, onCloseList }) => {
  const [showList, setShowList] = useState(false);

  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const popperRef = useRef(null);
  const focusedRef = useRef(null);

  const closeList = () => {
    setShowList(false);
    onCloseList && onCloseList();
  };

  const handleChange = event => {
    const eventValue = event?.target?.value;

    if (eventValue && !showList) setShowList(true);

    onValueChange && onValueChange(eventValue);
  };

  const handleFocus = () => setShowList(true);

  const handleItemClick = item => {
    onSelectOption(item);
    closeList();
  };

  const handleGroupClick = (event, ...rest) => {
    event.stopPropagation();
    onGroupClickCallback && onGroupClickCallback(event, ...rest);
  };

  const handleClickOutside = event => {
    const clickOutsideOfContainer = !containerRef.current?.contains(event.target);
    const clickOutsideOfPopper = !popperRef.current?.popper?.popper?.contains(event.target);

    if (clickOutsideOfContainer && clickOutsideOfPopper) {
      onValueChange && onValueChange(undefined);
      closeList();
    }
  };

  return {
    showList,
    inputRef,
    containerRef,
    popperRef,
    focusedRef,
    closeList,
    handleChange,
    handleFocus,
    handleItemClick,
    handleClickOutside,
    handleGroupClick,
  };
};

export default useAutocomplete;
