import { createSelector } from 'reselect';

import { getEnrichedProject } from 'store/projects/helpers/enrichProjects';
import { selectMetadata } from 'store/projects/metadataSelectors';
import { isLoading } from 'utils/store/thunk';

import {
  LINK_PROJECT_TO_METRIC,
  UNLINK_PROJECT_FROM_METRIC,
  FETCH_METRIC_LINKED_ITEMS,
  CREATE_PROJECT_FROM_METRIC,
} from './types';

const getState = state => state?.metricsDialog;

const getOperationsState = state => getState(state)?.operations;

export const selectMetricLinkedItems = createSelector(
  state => selectMetadata(state),
  state => getState(state)?.linkedItems,
  (metadata, linkedItems) => (linkedItems || []).map(item => getEnrichedProject(item, metadata)),
);

export const isFetchMetricLinkedItemsLoading = state => isLoading(getOperationsState(state), FETCH_METRIC_LINKED_ITEMS);

export const isLinkProjectToMetricLoading = state => isLoading(getOperationsState(state), LINK_PROJECT_TO_METRIC);

export const isUnlinkProjectToMetricLoading = state => isLoading(getOperationsState(state), UNLINK_PROJECT_FROM_METRIC);

export const isCreateProjectFromMetricLoading = state => isLoading(getOperationsState(state), CREATE_PROJECT_FROM_METRIC);
