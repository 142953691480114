import { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import useOrganizations from 'hooks/useOrganizations';
import useMetadataForOptions from 'hooks/metadata/useMetadataForOptions';
import useObjectivesSettings from 'routes/Settings/Objectives/New/hooks/useObjectivesSettings';
import { OBJECTIVE, KEY_RESULT, KEY_RESULT_2, USERS } from 'constants/common';
import { KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL, OBJECTIVE_LEVEL, OBJECTIVE_ID, PARENT_ID } from 'constants/objectives';
import { createKeyResultFromCanvas, createObjectiveFromCanvas } from 'features/Canvas/store/thunks';
import { getParentOptions } from './helpers';

const METADATA_KEYS = [USERS];

const layerByType = {
  [OBJECTIVE]: OBJECTIVE_LEVEL,
  [KEY_RESULT]: KEY_RESULT_1_LEVEL,
  [KEY_RESULT_2]: KEY_RESULT_2_LEVEL,
};

const initialFormValues = {
  title: null,
  owner: '',
  description: '',
  parent: null,
};

const getMatchingOkr = (okrTitle, okrs) => okrs?.find(okr => okr.title === okrTitle);

const processDataForCreateOkr = (formValues, parentOptions) => {
  const { type, title, owner, description, parent, objectTitle } = formValues;

  const matchingParentOkr = getMatchingOkr(parent, parentOptions);
  const parentFieldKey = type === KEY_RESULT_2 ? PARENT_ID : OBJECTIVE_ID;

  return {
    title: title || objectTitle,
    level: layerByType[type],
    ...(description ? { description } : {}),
    ...(owner ? { owner_id: owner } : {}),
    ...(matchingParentOkr ? { [parentFieldKey]: matchingParentOkr.id } : {}),
  };
};

const useCreateOkrForm = (type, objectTitle, textLabelId, onClose, isOpen) => {
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState(initialFormValues);

  const {
    filteredOptions: { users },
  } = useMetadataForOptions({
    metadataKeys: METADATA_KEYS,
  });

  const { hasKeyResults, hasKeyResults2 } = useOrganizations();

  const { objectives, keyResults } = useObjectivesSettings({
    hasKeyResults,
    hasKeyResults2,
    hideArchivedItems: true,
  });

  const parentOptions = useMemo(() => getParentOptions(objectives, keyResults, type), [objectives, keyResults, type]);

  const displayAlignedToField = type === KEY_RESULT || type === KEY_RESULT_2;

  const handleFormValueChange = (property, value) => setFormValues(prevFormValues => ({ ...prevFormValues, [property]: value }));

  const onCloseCallback = () => {
    setFormValues(initialFormValues);
  };

  const handleSave = () => {
    const isObjective = type === OBJECTIVE;

    const createCallback = isObjective ? createObjectiveFromCanvas : createKeyResultFromCanvas;

    dispatch(createCallback(processDataForCreateOkr({ ...formValues, type, objectTitle }, parentOptions), textLabelId, type));

    onClose(onCloseCallback);
  };

  return {
    objectTitle,
    formValues,
    type,
    ownerOptions: users,
    parentOptions,
    displayAlignedToField,
    disableCreate: displayAlignedToField && !formValues?.parent,
    handleFormValueChange,
    handleSave,
    onCloseCallback,
  };
};

export default useCreateOkrForm;
