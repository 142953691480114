import React from 'react';
import { useDispatch } from 'react-redux';

import ProjectType from '../containers/ProjectType';
import DragonLogo from 'design-system/atoms/DragonLogo/index';

import theme from 'design-system/theme';
import { openProjectLightbox } from 'store/projectLightbox';

const useGenerateProjectBreadcrumbTitles = (
  project,
  parentProject,
  grandParentProject,
  projectOwnerAccountName,
  updateProject,
  disabledEdit,
  validation,
  openProjectInOwnerOrganization,
) => {
  const dispatch = useDispatch();

  // 'marginRight:0' is needed because otherwise milestone icon would look bad
  // also we need to pass the height because the way we're creating the project icons
  const iconStyle = { height: 'unset', marginRight: '0' };

  return {
    item: {
      icon: <ProjectType layer={project?.layer} isMilestone={project?.isMilestone} style={iconStyle} />,
      title: project?.title,
      placeholder: project?.placeholder,
      readingPlaceholder: project?.readingPlaceholder,
      onChange: value => updateProject({ title: value }),
      isEditable: !disabledEdit,
      validation,
      name: 'title',
      multiline: false,
      useEllipsis: true,
    },
    parentItem: parentProject
      ? {
          icon: <ProjectType layer={parentProject?.layer} style={iconStyle} />,
          title: parentProject?.title,
          onClick: () => dispatch(openProjectLightbox(parentProject?.id)),
          fontSize: theme.typography.fontSizeSmall,
        }
      : null,
    ancestryItems: [
      ...(projectOwnerAccountName
        ? [
            {
              icon: <DragonLogo style={{ height: '18px', width: '18px' }} />,
              title: projectOwnerAccountName,
              onClick: openProjectInOwnerOrganization,
            },
          ]
        : []),
      ...(grandParentProject
        ? [
            {
              icon: <ProjectType layer={grandParentProject?.layer} style={iconStyle} />,
              title: grandParentProject?.title,
              onClick: () => dispatch(openProjectLightbox(grandParentProject?.id)),
            },
          ]
        : []),
    ],
  };
};

export default useGenerateProjectBreadcrumbTitles;
