import React from 'react';
import styled from 'styled-components';

import Loading from '../Loading';

export default () => {
  return <LoadingProgress />;
};

const LoadingProgress = styled(Loading)`
  &&&& {
    position: fixed;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
  }
`;
