import cloneDeep from 'lodash/cloneDeep';
import { defaultTo } from 'ramda';

import {
  CREATE_ROADMAP_FULFILLED,
  FETCH_ROADMAPS_FULFILLED,
  UPDATE_ROADMAP_FULFILLED,
  UPDATE_ROADMAP_ID_FULFILLED,
  REMOVE_UNSAVED_ROADMAPS,
  ADD_ROADMAP_WITHOUT_SAVE,
  DELETE_ROADMAP_FULFILLED,
  MERGE_ROADMAPS_FULFILLED,
  FETCH_ROADMAPS_PENDING,
  CREATE_ROADMAPS_FULFILLED,
  UPDATE_ROADMAPS_FULFILLED,
  UPDATE_PRODUCTS_FULFILLED,
  UNDO_CREATE_ROADMAPS_FULFILLED,
  UNDO_UPDATE_ROADMAPS_FULFILLED,
  BULK_DELETE_ROADMAPS_FULFILLED,
  UNDO_BULK_DELETE_ROADMAPS_FULFILLED,
  UPDATE_ROADMAP_ROW_ORDER,
  UPDATE_PRODUCT_ID_FULFILLED,
  UPDATE_PRODUCT_ROW_ORDER,
  MOVE_PRODUCT_TO_ROADMAP,
  REMOVE_UNSAVED_ROADMAPS_AND_PRODUCTS,
  CREATE_PRODUCT_FULFILLED,
  DELETE_PRODUCT_FULFILLED,
  MERGE_PRODUCTS_FULFILLED,
  BULK_DELETE_PRODUCTS_FULFILLED,
  UNDO_BULK_DELETE_PRODUCTS_FULFILLED,
  ADD_PRODUCT_WITHOUT_SAVE,
  OPEN_ROADMAP_LIGHTBOX,
  CLOSE_ROADMAP_LIGHTBOX,
  GET_ROADMAP_USER_ROLES_FULFILLED,
  UPDATE_ROADMAP_USER_ROLES_FULFILLED,
  MOVE_PRODUCT_TO_PRODUCT,
} from './types';
import { IMPORT_CUSTOMER_REQUESTS_FULFILLED } from 'store/customerRequests/types';

import upsertListItem from '../utils/upsertListItem';
import addRows from 'store/utils/addRows';
import updateRows from 'store/utils/updateRows';
import sortByRank from 'utils/sortByRank';

const initialState = {
  roadmaps: [],
  isLoaded: false,
  lightboxOpen: false,
  lightboxRoadmapId: null,
  roadmapUserRoles: {},
};

const defaultToEmptyArray = defaultTo([]);

function updateRoadmap(roadmaps, roadmap) {
  return roadmaps.map(bg => (bg.id === roadmap.id ? roadmap : bg));
}

function updateProductWithParent(roadmaps, product) {
  return roadmaps.map(roadmap => {
    return {
      ...roadmap,
      products: (roadmap.products || []).map(productLevelOne => {
        return {
          ...productLevelOne,
          products: (productLevelOne.products || []).map(productLevelTwo =>
            productLevelTwo.id === product.id ? product : productLevelTwo,
          ),
        };
      }),
    };
  });
}

function updateProductWithoutParent(roadmaps, product) {
  return roadmaps.map(bg => {
    return {
      ...bg,
      products: bg.products ? bg.products.map(i => (i.id === product.id ? { products: i.products, ...product } : i)) : [],
    };
  });
}

function updateProduct(roadmaps, product) {
  if (product.parent_id) {
    return updateProductWithParent(roadmaps, product);
  }

  return updateProductWithoutParent(roadmaps, product);
}

function updateProducts(roadmaps, products = []) {
  let roadmapsUpdated = [...roadmaps];

  products.forEach(product => {
    if (product.parent_id) {
      roadmapsUpdated = updateProductWithParent(roadmapsUpdated, product);
    } else {
      roadmapsUpdated = updateProductWithoutParent(roadmaps, product);
    }
  });

  return roadmapsUpdated;
}

/**
 * Removes, recursively, unsaved entries from nested roadmaps and products.
 *
 * @param {Array} list
//  * @returns {Array}
 */
function removeUnsaved(list) {
  return (list || []).reduce((result, item) => {
    if (!item.id) {
      return result;
    }

    if (item.products && item.products.length) {
      const newProductsList = removeUnsaved(item.products);

      return [...result, { ...item, products: newProductsList }];
    }

    return [...result, item];
  }, []);
}

const buildProductsFromRoadmaps = roadmaps => {
  const processProduct = product => {
    return [product, ...(product.products?.flatMap(processProduct) || [])];
  };

  return roadmaps.flatMap(r => {
    return r.products.flatMap(processProduct);
  });
};

export default function roadmapsReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ROADMAP_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        return state;
      }

      let roadmaps = state.roadmaps || [];
      const products = action.payload.products || [];

      roadmaps = removeUnsaved(roadmaps);

      roadmaps = upsertListItem({ ...action.payload, products }, roadmaps);

      return {
        ...state,
        roadmaps,
        isLoaded: true,
      };
    }
    case FETCH_ROADMAPS_PENDING: {
      return Object.assign({}, state, { isLoaded: false });
    }
    case FETCH_ROADMAPS_FULFILLED: {
      return Object.assign({}, state, {
        roadmaps: action.payload.data,
        isLoaded: true,
        // lastCallsDate: moment().valueOf(),
      });
    }
    case UPDATE_ROADMAP_FULFILLED: {
      return Object.assign({}, state, { roadmaps: updateRoadmap(state.roadmaps, action.payload), isLoaded: true });
    }
    case UPDATE_ROADMAP_ID_FULFILLED: {
      return Object.assign({}, state, { roadmaps: updateRoadmap(state.roadmaps, action.payload), isLoaded: true });
    }
    case UPDATE_PRODUCT_ID_FULFILLED: {
      return Object.assign({}, state, { roadmaps: updateProduct(state.roadmaps, action.payload), isLoaded: true });
    }
    case REMOVE_UNSAVED_ROADMAPS:
      return {
        ...state,
        roadmaps: state.roadmaps.filter(roadmap => roadmap.id),
      };
    case ADD_ROADMAP_WITHOUT_SAVE:
      const roadmaps = state.roadmaps ? removeUnsaved(state.roadmaps) : [];

      const roadmap = action.roadmap || {};

      roadmaps.unshift({ parent_id: roadmap?.id, level: action.defaultLevel } || {});

      return {
        ...state,
        roadmaps,
      };
    case DELETE_ROADMAP_FULFILLED:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        roadmaps: state.roadmaps.filter(roadmap => roadmap.id !== action.payload && roadmap.parent_id !== action.payload),
      };
    case MERGE_ROADMAPS_FULFILLED:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        roadmaps: state.roadmaps.filter(roadmap => !action.payload.includes(roadmap.id)),
      };
    case CREATE_ROADMAPS_FULFILLED: {
      const roadmaps = addRows(state.roadmaps, action.payload);

      return {
        ...state,
        roadmaps,
        lastActionIds: action.payload.map(roadmap => roadmap.id),
      };
    }
    case UPDATE_ROADMAPS_FULFILLED: {
      const roadmaps = updateRows(state.roadmaps, action.payload);

      return {
        ...state,
        roadmaps,
        lastActionIds: action.payload.map(roadmap => roadmap.id),
      };
    }
    case UPDATE_PRODUCTS_FULFILLED: {
      const roadmaps = updateProducts(state.roadmaps, action.payload);

      return {
        ...state,
        roadmaps,
      };
    }
    case BULK_DELETE_ROADMAPS_FULFILLED: {
      const roadmaps = state.roadmaps.filter(
        r => !action.payload.includes(String(r.id)) && !action.payload.includes(String(r.parent_id)),
      );

      return {
        ...state,
        roadmaps,
        lastActionIds: action.payload,
      };
    }
    case UNDO_UPDATE_ROADMAPS_FULFILLED: {
      const roadmaps = updateRows(state.roadmaps, action.payload);

      return {
        ...state,
        roadmaps,
        lastActionIds: null,
      };
    }
    case UNDO_CREATE_ROADMAPS_FULFILLED: {
      return {
        ...state,
        roadmaps: state.roadmaps.filter(c => state.lastActionIds.indexOf(c.id) === -1),
        lastActionIds: null,
      };
    }
    case UPDATE_ROADMAP_ROW_ORDER: {
      if (!action.roadmap || !action.roadmap.id) {
        console.error('UPDATE_ROADMAP_ROW_ORDER::Roadmap passed does not have id');
        return state;
      }

      let roadmapsList = state.roadmaps || [];

      roadmapsList = upsertListItem(action.roadmap, roadmapsList);

      return {
        ...state,
        roadmaps: roadmapsList,
      };
    }
    case UPDATE_PRODUCT_ROW_ORDER: {
      if (!action.product || !action.product.id) {
        console.error('UPDATE_PRODUCT_ROW_ORDER::Product passed does not have id');
        return state;
      }

      const isLevelTwoProduct = action.product.parent_id != null;
      const roadmaps = state.roadmaps || [];

      const productsMap = {};
      let allProducts = [];

      roadmaps.forEach(roadmap => {
        const subRoadmaps = defaultToEmptyArray(roadmap.products);

        if (isLevelTwoProduct) {
          subRoadmaps.forEach(product => {
            allProducts = [...allProducts, ...defaultToEmptyArray(product.products)];
          });
        } else {
          allProducts = [...allProducts, ...subRoadmaps];
        }
      });

      allProducts = upsertListItem(action.product, allProducts);
      allProducts.sort(sortByRank);

      const indexKey = isLevelTwoProduct ? 'parent_id' : 'roadmap_id';

      allProducts.forEach(kr => {
        if (productsMap[kr[indexKey]]) {
          productsMap[kr[indexKey]] = [...productsMap[kr[indexKey]], kr];
        } else {
          productsMap[kr[indexKey]] = [kr];
        }
      });

      if (isLevelTwoProduct) {
        const newRoadmapsState = roadmaps.map(roadmap => ({
          ...roadmap,
          products: defaultToEmptyArray(roadmap.products).map(product => {
            return { ...product, products: productsMap[product.id] || [] };
          }),
        }));

        return { ...state, roadmaps: newRoadmapsState };
      }

      return {
        ...state,
        roadmaps: roadmaps.map(roadmap => {
          return { ...roadmap, products: productsMap[roadmap.id] || [] };
        }),
      };
    }
    case MOVE_PRODUCT_TO_ROADMAP: {
      if (!action.product || !action.product.id) {
        console.error('UPDATE_PRODUCT_ROW_ORDER::Product passed does not have id');
        return state;
      }

      const roadmaps = state.roadmaps || [];
      const previousRoadmap = { ...roadmaps.find(roadmap => roadmap.id === action.previousRoadmapId) };
      const previousRoadmapProducts = (previousRoadmap.products || []).filter(i => {
        return i.id !== action.product.id;
      });

      const roadmap = { ...roadmaps.find(roadmap => roadmap.id === action.product.roadmap_id) };

      let products = [];

      if (roadmap) {
        products = [...(roadmap.products || []), action.product];
        products.sort(sortByRank);
      }

      return {
        ...state,
        roadmaps: roadmaps.map(roadmap => {
          if (roadmap.id === action.product.roadmap_id) {
            return {
              ...roadmap,
              products,
            };
          } else if (roadmap.id === action.previousRoadmapId) {
            return {
              ...roadmap,
              products: previousRoadmapProducts,
            };
          }
          return roadmap;
        }),
      };
    }
    case MOVE_PRODUCT_TO_PRODUCT: {
      // Code copied and adjusted from MOVE_KEY_RESULT_TO_KEY_RESULT listener,
      // in order to maintain same logic and presentation.
      if (!action.product?.id) {
        console.error('MOVE_PRODUCT_TO_PRODUCT::Product passed does not have an ID');

        return state;
      }

      let roadmaps = state.roadmaps || [];

      const previousRoadmap = {
        ...roadmaps.find(r => r.id === action.previousRoadmapId),
      };

      const previousRoadmapProducts = previousRoadmap.products || [];

      previousRoadmap.products = previousRoadmapProducts.map(p => {
        if (+p.id !== +action.previousParentId) {
          return p;
        }

        return {
          ...p,
          products: p.products.filter(p => +p.id !== +action.product.id),
        };
      });

      let newRoadmap;

      if (action.product.roadmap_id === action.previousRoadmapId) {
        newRoadmap = previousRoadmap;
      } else {
        newRoadmap = {
          ...roadmaps.find(r => r.id === action.product.roadmap_id),
        };
      }

      const parentProduct = {
        ...(newRoadmap.products || []).find(p => p.id === action.product.parent_id),
      };

      let products = [];

      if (parentProduct) {
        products = [
          ...parentProduct.products,
          {
            ...action.product,
          },
        ];

        products.sort(sortByRank);

        newRoadmap.products = newRoadmap.products.map(p => {
          if (p.id === action.product.parent_id) {
            return {
              ...p,
              products,
            };
          }

          return p;
        });
      }

      roadmaps = roadmaps.map(r => {
        if (r.id === action.product.roadmap_id) {
          return newRoadmap;
        } else if (r.id === action.previousRoadmapId) {
          return previousRoadmap;
        }

        return r;
      });

      return {
        ...state,
        roadmaps,
        products: buildProductsFromRoadmaps(roadmaps),
      };
    }
    case ADD_PRODUCT_WITHOUT_SAVE: {
      const roadmaps = state.roadmaps ? removeUnsaved(state.roadmaps) : [];
      const roadmapId = action.isProduct ? action.parent.roadmap_id : action.parent?.id;

      const addLevelTwoProductTextField = roadmap => {
        const productsList = defaultToEmptyArray(roadmap.products);
        const selectedProduct = productsList.find(p => p.id === action.parent.id);
        const selectedProductIndex = productsList.indexOf(selectedProduct);
        const newProduct = {
          ...selectedProduct,
          products: [
            { roadmap_id: roadmapId, parent_id: selectedProduct.id, level: action.level },
            ...defaultToEmptyArray(selectedProduct.products),
          ],
        };

        return {
          ...roadmap,
          products: [...productsList.slice(0, selectedProductIndex), newProduct, ...productsList.slice(selectedProductIndex + 1)],
        };
      };

      const newRoadmaps = roadmaps.map(roadmap => {
        if (roadmap.id === roadmapId) {
          if (action.isProduct) {
            return addLevelTwoProductTextField(roadmap);
          }

          return {
            ...roadmap,
            products: [{ roadmap_id: roadmapId, parent_id: null, level: action.level }, ...defaultToEmptyArray(roadmap.products)],
          };
        }

        return { ...roadmap };
      });

      return {
        ...state,
        roadmaps: newRoadmaps,
      };
    }
    case CREATE_PRODUCT_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        return state;
      }

      let roadmaps = state.roadmaps || [];

      roadmaps = removeUnsaved(roadmaps);

      const roadmap = roadmaps.find(r => r.id === action.payload.roadmap_id);

      if (!roadmap) {
        if (action.payload.roadmap) {
          const newRoadmap = { ...action.payload.roadmap };

          delete action.payload.roadmap;

          newRoadmap.products = [action.payload];
          return {
            ...state,
            roadmaps: [...roadmaps, newRoadmap],
          };
        }
        return state;
      }

      let { products } = roadmap;

      if (action.payload.parent_id) {
        const parentProduct = products.find(p => p.id === action.payload.parent_id);
        const childProducts = upsertListItem(action.payload, parentProduct.products || []);

        products = upsertListItem({ ...parentProduct, products: childProducts }, products);
      } else {
        products = upsertListItem(action.payload, products);
      }

      const newRoadmaps = roadmaps.map(roadmap => {
        if (roadmap.id === action.payload.roadmap_id) {
          return {
            ...roadmap,
            products,
          };
        }

        return { ...roadmap };
      });

      return {
        ...state,
        roadmaps: newRoadmaps,
        isLoaded: true,
      };
    }
    case REMOVE_UNSAVED_ROADMAPS_AND_PRODUCTS: {
      return {
        ...state,
        roadmaps: removeUnsaved(state.roadmaps || []),
      };
    }
    case DELETE_PRODUCT_FULFILLED: {
      if (!action.payload) {
        return state;
      }

      const roadmaps = state.roadmaps.map(roadmap => {
        if (!roadmap.products) return roadmap;

        const newProducts = roadmap.products.reduce((result, product) => {
          if (product.id === action.payload) return result;

          if (product.products) {
            const newChildProducts = product.products.filter(childProduct => childProduct.id !== action.payload);

            return [...result, { ...product, products: newChildProducts }];
          }

          return [...result, product];
        }, []);

        return { ...roadmap, products: newProducts };
      });

      return {
        ...state,
        roadmaps,
      };
    }
    case BULK_DELETE_PRODUCTS_FULFILLED: {
      if (!action.payload) {
        return state;
      }

      const idsToDelete = action.payload;

      const roadmaps = state.roadmaps.map(roadmap => {
        if (!roadmap.products) return roadmap;

        const newProducts = roadmap.products.reduce((result, product) => {
          if (idsToDelete.includes(String(product.id))) return result;

          if (product.products) {
            const newChildProducts = product.products.filter(childProduct => !idsToDelete.includes(String(childProduct.id)));

            return [...result, { ...product, products: newChildProducts }];
          }

          return [...result, product];
        }, []);

        return { ...roadmap, products: newProducts };
      });

      return {
        ...state,
        roadmaps,
        lastActionIds: action.payload,
      };
    }
    case MERGE_PRODUCTS_FULFILLED:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
      };
    case UNDO_BULK_DELETE_ROADMAPS_FULFILLED: {
      const roadmaps = updateRows(state.roadmaps, action.payload);

      return {
        ...state,
        roadmaps,
        lastActionIds: null,
      };
    }
    case UNDO_BULK_DELETE_PRODUCTS_FULFILLED: {
      const roadmaps = cloneDeep(state.roadmaps) || [];

      action.payload.forEach(product => {
        const roadmap = roadmaps.find(r => r.id === product.roadmap_id);

        roadmap.products = [...roadmap.products, product];
      });

      return {
        ...state,
        roadmaps,
        lastActionIds: null,
      };
    }
    case IMPORT_CUSTOMER_REQUESTS_FULFILLED: {
      return {
        ...state,
        roadmaps: [
          ...state.roadmaps,
          ...action.payload
            .filter(cr => cr.roadmap)
            .map(cr => {
              return cr.roadmap;
            }),
        ],
      };
    }
    case OPEN_ROADMAP_LIGHTBOX: {
      return {
        ...state,
        lightboxOpen: true,
        lightboxRoadmapId: action.roadmapId,
      };
    }
    case CLOSE_ROADMAP_LIGHTBOX: {
      return {
        ...state,
        lightboxOpen: false,
        lightboxRoadmapId: null,
      };
    }
    case UPDATE_ROADMAP_USER_ROLES_FULFILLED:
    case GET_ROADMAP_USER_ROLES_FULFILLED: {
      return {
        ...state,
        roadmapUserRoles: {
          ...state.roadmapUserRoles,
          [action.payload.roadmapId]: action.payload.roles,
        },
      };
    }
    default:
      return state;
  }
}
