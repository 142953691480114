import React, { useCallback, useMemo } from 'react';

import { BaseGrid } from 'containers/Grids';
import { useCommonGridEventHandlers, useCommonGridProps } from 'design-system/molecules/AgGridReact-New/hooks';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import usePermissions from 'hooks/permissions/usePermissions';
import useCustomerRequests from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequests';
import useCustomerRequestsGridState from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsGridState';
import useCustomerRequestsGrid from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsGrid';
import useCustomerRequestsGridMetadata from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsGridMetadata';
import { HEADER_HEIGHT, ID_KEY } from 'routes/CustomerRequests/Grid/New/constants';
import { sumNumericFields } from 'design-system/molecules/AgGridReact-New/aggFuncs';
import useGridApiContext from 'hooks/grid/useGridApiContext';

import useRequestsGridColumnsDefs from '../../hooks/useRequestsGridColumnsDefs';
import { REQUESTS_GRID_MODAL } from '../../constants';

const GRID_HEIGHT = 'calc(100vh - 240px)';
const PAGE_SIZE = 20;

const getRowId = ({ data }) => data?.id || null;

export default ({ customerRequests, isBulkUpdate, setSelectedItems, gridHeight = GRID_HEIGHT }) => {
  const { onOpenCustomerRequestInfo, onSwitchRowOrder } = useCustomerRequests();

  const { registerGridApi } = useGridApiContext();

  // TODO: this uses the state from costumer requests > List
  const {
    rowHeight,
    searchString,
    visibleColumnsIds,
    columnsState,
    shouldGridBeUnmounted,

    setDirtyViewMutex,
    saveColumnsState,
  } = useCustomerRequestsGridState();

  const {
    handleGridReady,
    onChangeColumnVisibility,
    updateCustomerRequestByIdOnCellValueChange,
    handleDisplayedColumnsChanged,
    handleColumnResized,
  } = useCustomerRequestsGrid({ onOpenCustomerRequestInfo, saveColumnsState });

  const handleSelectionChanged = useCallback(
    ({ api }) => {
      const currentlySelectedItems = api.getSelectedRows();

      if (setSelectedItems) {
        setSelectedItems(currentlySelectedItems);
      }
    },
    [setSelectedItems],
  );

  const { metadata, createMultiSelectOption, filteredMetadata } = useCustomerRequestsGridMetadata();

  const { columnDefs, defaultColDef } = useRequestsGridColumnsDefs({
    searchString,
    isBulkUpdate,
    visibleColumnsIds,
    columnsState,
    rowHeight,

    updateCustomerRequestById: updateCustomerRequestByIdOnCellValueChange,
    onChangeColumnVisibility,
    onOpenCustomerRequestInfo,
    createMultiSelectOption,
  });

  const commonGridProps = useCommonGridProps({
    aggFuncs: {
      sumNumericFields,
    },
  });

  const commonGridEvents = useCommonGridEventHandlers({
    items: customerRequests,
    idKey: ID_KEY,
    actions: {
      update: updateCustomerRequestByIdOnCellValueChange,
      drag: onSwitchRowOrder,
      sortChanged: handleDisplayedColumnsChanged,
    },
  });

  const { canUpdate } = usePermissions();

  const gridContext = useMemo(
    () => ({
      metadata,
      filteredMetadata,
      isBulkDeleting: isBulkUpdate,
      allowEdit: data => canUpdate(PERMISSION_RESOURCES.request, { data }),
    }),
    [metadata, canUpdate, isBulkUpdate, filteredMetadata],
  );

  const onGridReady = params => {
    registerGridApi(REQUESTS_GRID_MODAL, params.api, params.columnApi);

    handleGridReady(params);
  };

  if (shouldGridBeUnmounted) {
    return <PageLoading />;
  }

  return (
    <>
      <BaseGrid
        // Common props:
        {...commonGridProps}
        {...commonGridEvents}
        // General props:
        onGridReady={onGridReady}
        getRowId={getRowId}
        rowData={customerRequests}
        context={gridContext}
        onFirstDataRendered={setDirtyViewMutex}
        // isGroupOpenByDefault
        // isRowSelectable
        rowSelection="multiple"
        suppressRowClickSelection
        // Styling:
        // cssStyles
        // getRowStyle
        height={gridHeight}
        headerHeight={HEADER_HEIGHT}
        rowHeight={rowHeight}
        // Columns:
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        // Events:
        // onRowGroupOpened
        onSelectionChanged={handleSelectionChanged}
        onDisplayedColumnsChanged={handleDisplayedColumnsChanged}
        onColumnResized={handleColumnResized}
        paginationPageSize={PAGE_SIZE}
        pagination
      />
    </>
  );
};
