import AgGridReact from './AgGridReact';

export const CSS_SCOPE_CLASS = 'ag-grid-30';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';

// const loadableComponent = Loadable.Map({
//   loader: {
//     Styles: async () => {
//       const [{ default: newAgGridCss }, { default: newAgThemeMaterialCss }] = await Promise.all([
//         /* eslint-disable import/no-webpack-loader-syntax */
//         import('!!css-to-string-loader!css-loader!ag-grid-community/styles/ag-grid.css'),
//         import('!!css-to-string-loader!css-loader!ag-grid-community/styles/ag-theme-material.css'),
//         /* eslint-enable import/no-webpack-loader-syntax */
//       ]);

//       await scopeAndInjectCss(CSS_SCOPE_CLASS, {
//         newAgGridCss,
//         newAgThemeMaterialCss,
//       });
//     },
//     Grid: () => import('./AgGridReact'),
//   },
//   loading: PageLoading,
//   render(loaded, props) {
//     const Grid = loaded.Grid.default;
//     return <Grid {...props} />;
//   },
// });

// const componentToExport = process.env.NODE_ENV === 'test' ? AgGridReactForTesting : loadableComponent;

export default AgGridReact;
