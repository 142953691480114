import axios from 'axios';

import { createThunk } from 'utils/store/thunk';
import throwRequestError from 'store/utils/throwRequestError';

import { FETCH_METRIC_LEVELS, UPDATE_METRIC_LEVELS } from './types';

const fetchMetricLevels = () => createThunk(FETCH_METRIC_LEVELS, axios.get(`/api/metric-levels`).catch(throwRequestError));

const updateMetricLevels = update =>
  createThunk(UPDATE_METRIC_LEVELS, axios.put(`/api/metric-levels`, update).catch(throwRequestError));

export { fetchMetricLevels, updateMetricLevels };
