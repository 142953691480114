import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PAGES_STATE_TO_SAVE, setPageActiveView, createOrUpdateUserView, DISABLE_DEFAULT_VIEW_FOR_PAGES } from 'store/userViews';
import { getActiveViewForPage, getDefaultUserViewForPage } from 'store/userViews/selectors';
import { getPageIdFromPath } from 'utils/userViews';
import useLoadActiveViewFromPrivateLink from './useLoadActiveViewFromPrivateLink';
import useLoadTemplateViewFromPrivateLink from './useLoadTemplateViewFromPrivateLink';
import { getLogin } from 'store/login/selectors';

const generateDefaultUserViewForPage = (pageId, state) => {
  const pageInitialStateMapper = PAGES_STATE_TO_SAVE[pageId];

  const userViewInitialState = pageInitialStateMapper ? pageInitialStateMapper(state) : {};

  return {
    id: null,
    page: pageId,
    state: { ...userViewInitialState },
    default_view: true,
    path: window.location.pathname.replace(/^\/+/, ''),
  };
};

/**
 * @function useLoadPageActiveView
 *
 * Sets the correct active view for the page being loaded
 *
 */
const useLoadPageActiveView = () => {
  const dispatch = useDispatch();
  const [defaultViewsCreating, setDefaultViewsCreating] = useState({});
  const pageId = getPageIdFromPath(window.location.pathname.slice(1));
  const storedDefaultUserView = useSelector(state => getDefaultUserViewForPage(state, pageId));
  const activePageView = useSelector(state => getActiveViewForPage(state, pageId));
  const generatedDefaultUserView = useSelector(state => generateDefaultUserViewForPage(pageId, state));
  const { isAuthenticated } = useSelector(getLogin);

  const defaultUserView = storedDefaultUserView ?? generatedDefaultUserView;
  const isDefaultViewDisabled = !isAuthenticated || DISABLE_DEFAULT_VIEW_FOR_PAGES.includes(pageId);

  const loadingViewFromURL = useLoadActiveViewFromPrivateLink(pageId);

  const loadingTemplateFromURL = useLoadTemplateViewFromPrivateLink();

  useEffect(() => {
    if (!pageId || loadingViewFromURL || isDefaultViewDisabled || loadingTemplateFromURL) {
      // Do nothing, no pageId is found or we are still loading view from URL
      return;
    }

    if (defaultUserView) {
      if (!defaultUserView.id && !defaultViewsCreating[pageId]) {
        dispatch(createOrUpdateUserView(defaultUserView));
        setDefaultViewsCreating({ ...defaultViewsCreating, [pageId]: true });
      }

      if (activePageView) {
        // We already have an active view for this page not setting again
        return;
      }

      // Setting active the default user view
      dispatch(setPageActiveView(pageId, defaultUserView));
    }
  }, [
    pageId,
    loadingViewFromURL,
    activePageView,
    isDefaultViewDisabled,
    defaultViewsCreating,
    setDefaultViewsCreating,
    loadingTemplateFromURL,
  ]);

  return {
    activePageView,
    canIgnoreActiveView: !pageId || (isDefaultViewDisabled && !loadingViewFromURL),
  };
};

export default useLoadPageActiveView;
