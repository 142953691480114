import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Input from '@material-ui/core/Input';
import Popover from '@material-ui/core/Popover';
import useResizeObserver from 'use-resize-observer';

import DndList from 'design-system/organisms/DndList/index';
import FluidField from 'design-system/molecules/FluidField/index';
import MetadataFluidField from 'design-system/molecules/MetadataFluidField/index';
import SavedFiltersRow from 'design-system/atoms/SavedFiltersRow/index';


const DEFAULT_OFFSET_LEFT = 174;
const DEFAULT_OFFSET_WIDTH = 332;

// To be aligned with the text on the input fields we must adjust its position and padding
const ALIGN_ITEMS_TOP = 'flex-start';

const FluidObjectiveMenuField = props => {
  const { options = [], selectedProject, okrSelectedArchived, filterOptionsFunc } = props;

  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const popoverRef = useRef(null);
  const [popoverActionCallback, setPopoverActionCallback] = useState();
  const { ref: listRef, height: listHeight } = useResizeObserver();
  const updateCallback = callback => {
    setShowMoreOptions(false);
    setPopoverActionCallback(callback);
  };

  const toggleMoreOptions = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  const filterOptions = options => {
    const { roadmap_id: roadmapId, product_1_id: subRoadmapId } = selectedProject || {};

    if (roadmapId && !showMoreOptions) {
      const filterChildren = (children = []) => {
        return children.filter(child => {
          if (!child.key_result_roadmaps?.length) return true;

          return child.key_result_roadmaps.find(item => {
            const roadmapMatch = item.roadmap_id === roadmapId;

            if (subRoadmapId && item.product_1_id) {
              return roadmapMatch && item.product_1_id === subRoadmapId;
            }

            return roadmapMatch;
          });
        });
      };

      return cloneDeep(options).reduce((opts, opt) => {
        opt.children = filterChildren(opt.children);

        opt.children = opt.children.map(child => {
          return {
            ...child,
            children: filterChildren(child.children),
          };
        });

        opts.push(opt);
        return opts;
      }, []);
    }

    return options;
  };

  const filteredOptions = useMemo(() => {
    if (showMoreOptions) {
      return options;
    }

    if (filterOptionsFunc) {
      return filterOptionsFunc(options);
    }

    return filterOptions(options);
  }, [options, showMoreOptions, filterOptionsFunc]);

  const handleClick = (selectedValue, save) => {
    if ('objective_id' in selectedValue) {
      const objective = options.find(opt => opt.id === selectedValue.objective_id);

      if (selectedValue.level === 0) {
        save({ objectiveTitle: objective.title, keyResult1Title: selectedValue.title, keyResult2Title: null });
      }

      if (selectedValue.level === 1) {
        const keyResult = objective.keyResults.find(opt => opt.id === selectedValue.parent_id);

        save({ objectiveTitle: objective.title, keyResult1Title: keyResult.title, keyResult2Title: selectedValue.title });
      }
    } else {
      save({ objectiveTitle: selectedValue.title, keyResult1Title: null });
    }
  };

  const setFilterRowText = () =>
    showMoreOptions && selectedProject?.roadmapTitle ? `Show ${selectedProject.roadmapTitle} OKRs` : 'Show All OKRs';

  const renderShowAll = () => <SavedFiltersRow handleClick={toggleMoreOptions} text={setFilterRowText()} />;

  const setOkrValue = (okrTitles = []) => {
    return okrTitles.filter(Boolean).join(' / \n');
  };

  useEffect(() => {
    if (popoverActionCallback?.updatePosition instanceof Function) popoverActionCallback.updatePosition();
  }, [listHeight, popoverActionCallback]);

  return (
    <div ref={popoverRef}>
      <MetadataFluidField
        {...props}
        renderer={(value, { placeholder } = {}) => (
          <StyledInput
            type="text"
            value={setOkrValue(value)}
            minRows={props?.value?.filter(Boolean)?.length || 1}
            archivedValue={okrSelectedArchived}
            placeholder={placeholder}
            multiline
            disableUnderline
            readOnly
          />
        )}
        editorRenderer={({ anchorEl, isEditing, save, cancel, disableEdit }) => (
          <PopoverContainer
            anchorEl={disableEdit ? null : popoverRef?.current}
            parent={anchorEl}
            open={isEditing}
            action={updateCallback}
          >
            <ClickAwayListener onClickAway={cancel}>
              <div ref={listRef}>
                <DndList
                  items={filteredOptions}
                  onClickItem={selected => handleClick(selected, save)}
                  renderClear={() => renderShowAll()}
                  width={anchorEl?.offsetWidth || DEFAULT_OFFSET_WIDTH}
                  openByDefault
                  showTooltip
                />
              </div>
            </ClickAwayListener>
          </PopoverContainer>
        )}
        alignItems={ALIGN_ITEMS_TOP}
        forceInputRenderingOnEdit
      />
    </div>
  );
};

FluidObjectiveMenuField.propTypes = {
  ...FluidField.propTypes,
  metadata: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

const PopoverContainer = styled(Popover)`
  &&&&& {
    left: ${({ parent }) => parent?.offsetLeft || DEFAULT_OFFSET_LEFT}px;
  }
`;

const StyledInput = styled(Input)`
  &&&& {
    ${({ archivedValue, theme }) =>
      archivedValue
        ? `
    color: ${theme.palette.text.lightGrey}`
        : ''}
  }
`;

export default FluidObjectiveMenuField;
