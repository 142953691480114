import React from 'react';
import Button from '@material-ui/core/Button';

import { INTEGRATIONS_NAMES } from 'constants/integrations';
import styled from 'styled-components';
import { spacing } from 'design-system/theme';

export default ({ integrationType, onClick }) => {
  const integrationNameLabel = INTEGRATIONS_NAMES[integrationType];

  return (
    <NewButton id={`new-${integrationType}-integration-button`} onClick={onClick} color="primary">
      {`+ New ${integrationNameLabel} Integration`}
    </NewButton>
  );
};

const NewButton = styled(Button)`
  &&& {
    margin-top: ${spacing(2)}px;
  }
`;
