import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import DataSourceUrlInput from '../../../../MetricsDialog/components/FluidMetricDataSourceField/components/DataSourceUrlInput';
import LinkButtons from '../../../../MetricsDialog/components/FluidMetricDataSourceField/components/LinkButtons';
import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete/index';

import { spacing } from 'design-system/theme';
import { fetchMetricIntegrationFilterOptions } from 'store/metrics/thunks';
import { getInitialMetricType, getInitialTimePeriod, getIntegrationEntityFromUrl } from '../helpers';
import { DEFAULT_PENDO_SEGMENT, PAGE_TYPE_OPTIONS, TIME_PERIOD_OPTIONS, OPTIONS_BY_ENTITY_TYPE } from '../constants';
import { getMetricIntegrationFilterOptions } from 'store/metrics/selectors';

export default function PendoDataSourceField({
  cancel,
  disableEdit,
  handleInputChange,
  handleLink,
  integrationData,
  onChange,
  orgIntegration,
  originalValue,
  save,
  setShowConfirmDialog,
  value,
}) {
  const dispatch = useDispatch();

  const { data } = integrationData || {};

  const {
    metricType: originalMetricType,
    timePeriod: originalTimePeriod,
    segment: originalSegment,
    entity: originalEntity,
  } = data || {};

  const filterOptions = useSelector(state => getMetricIntegrationFilterOptions(state, orgIntegration?.id));

  const initialTimePeriod = getInitialTimePeriod(originalTimePeriod);
  const initialMetricType = getInitialMetricType(originalMetricType, originalValue);

  const [timePeriod, setTimePeriod] = useState(initialTimePeriod);
  const [metricType, setMetricType] = useState(initialMetricType);
  const [metricSegment, setMetricSegment] = useState(originalSegment || DEFAULT_PENDO_SEGMENT);
  const [entity, setEntity] = useState(originalEntity);

  const metricTypeSuggestions = entity ? OPTIONS_BY_ENTITY_TYPE[entity] : PAGE_TYPE_OPTIONS;

  const handleSave = () => {
    handleLink(save, value, {
      entity,
      metricType: metricType?.key,
      timePeriod: timePeriod?.key,
      segment: metricSegment?.id && metricSegment,
    });
  };

  const updateEntityAndMetricType = value => {
    const integrationEntity = getIntegrationEntityFromUrl(value);

    if (integrationEntity) {
      setEntity(integrationEntity);

      const optionsByEntity = OPTIONS_BY_ENTITY_TYPE[integrationEntity];

      setMetricType(optionsByEntity[0]);
    }
  };

  const handleDataSourceUrlChange = (value, onChange) => {
    updateEntityAndMetricType(value);

    handleInputChange(value, onChange);
  };

  useEffect(() => {
    if (orgIntegration?.type && orgIntegration?.id) {
      dispatch(fetchMetricIntegrationFilterOptions(orgIntegration.type, orgIntegration.id));
    }
  }, [orgIntegration]);

  useEffect(() => {
    if (value && !integrationData) {
      updateEntityAndMetricType(value);
    }
  }, [value]);

  return (
    <>
      <Container>
        <DataSourceUrlInput
          cancel={cancel}
          disableEdit={disableEdit}
          handleInputChange={handleDataSourceUrlChange}
          integrationData={integrationData}
          onChange={onChange}
          value={value}
        />
      </Container>
      <Container>
        <SegmentContainer>
          <StyledSegmentAutocomplete
            name="segment"
            label=""
            inputStyle={{ width: '100%' }}
            suggestions={filterOptions?.segments || [DEFAULT_PENDO_SEGMENT]}
            value={metricSegment}
            onChange={setMetricSegment}
            disabled={disableEdit || integrationData?.id}
          />
        </SegmentContainer>
        <StyledGroupByAutocomplete
          name="time-period"
          label=""
          suggestions={TIME_PERIOD_OPTIONS}
          value={timePeriod}
          onChange={setTimePeriod}
          disabled={disableEdit || integrationData?.id}
        />
        <GroupByAutocomplete
          name="metricType"
          label=""
          suggestions={metricTypeSuggestions}
          value={metricType}
          onChange={setMetricType}
          disabled={disableEdit || integrationData?.id}
        />
        <LinkButtons
          disableEdit={disableEdit}
          handleLink={handleSave}
          metricIntegration={integrationData}
          orgIntegration={orgIntegration}
          originalValue={originalValue}
          save={save}
          setShowConfirmDialog={setShowConfirmDialog}
          value={value}
        />
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2)}px;
`;

const StyledGroupByAutocomplete = styled(GroupByAutocomplete)`
  > div > div > div {
    min-width: 60px;
  }
`;

const SegmentContainer = styled.div`
  width: 50%;
  display: flex;
`;

const StyledSegmentAutocomplete = styled(GroupByAutocomplete)`
  width: 100%;

  > div > div {
    gap: 0px;
  }

  > div > div > div {
    max-width: unset;
    width: 100%;
  }

  input {
    color: ${({ theme, disabled }) => (disabled ? theme.palette.text.disabled : theme.palette.text.primary)};
  }
`;
