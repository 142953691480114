import React from 'react';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';

import getDateFormat from 'utils/dates/getDateFormat';

import FieldWrapper from './FieldWrapper';
import { FILTERS_FUNCTIONS } from 'design-system/constants';

const FUNCTIONS_TO_APPLY_BY_DEFAULT = [
  FILTERS_FUNCTIONS.thisWeek.key,
  FILTERS_FUNCTIONS.thisMonth.key,
  FILTERS_FUNCTIONS.thisQuarter.key,
  FILTERS_FUNCTIONS.thisYear.key,
  FILTERS_FUNCTIONS.lastWeek.key,
  FILTERS_FUNCTIONS.lastMonth.key,
  FILTERS_FUNCTIONS.lastQuarter.key,
  FILTERS_FUNCTIONS.lastYear.key,
  FILTERS_FUNCTIONS.nextWeek.key,
  FILTERS_FUNCTIONS.nextMonth.key,
  FILTERS_FUNCTIONS.nextQuarter.key,
  FILTERS_FUNCTIONS.nextYear.key,
  FILTERS_FUNCTIONS.today.key,
];

const FUNCTIONS_TO_USE_MIN_DATE = [FILTERS_FUNCTIONS.between.key];
const FUNCTIONS_TO_USE_MAX_DATE = [FILTERS_FUNCTIONS.between.key];

export default ({ fieldFunction, value, onChange }) => {
  const [currentFunction, setCurrentFunction] = React.useState(fieldFunction);
  const isStartVisible = fieldFunction && ['before', 'after', 'between'].includes(fieldFunction.key);
  const isEndVisible = fieldFunction && ['between'].includes(fieldFunction.key);
  const isAndLabelVisible = fieldFunction && ['between'].includes(fieldFunction.key);
  const startFunc = ['after', 'between'].includes(fieldFunction?.key) ? 'gte' : 'lte';
  const _formatDate = date => moment(date).startOf('day').format();
  const _onChange = dateVal => {
    onChange({
      ...(['between'].includes(fieldFunction.key) ? value || {} : {}),
      ...dateVal,
    });
  };

  React.useEffect(() => {
    setCurrentFunction(fieldFunction);

    if (FUNCTIONS_TO_APPLY_BY_DEFAULT.includes(fieldFunction?.key)) {
      onChange({
        equals: fieldFunction.key,
      });
      return;
    }

    if (currentFunction && currentFunction.key !== fieldFunction?.key) onChange(null);
  }, [fieldFunction?.key]);

  return (
    <React.Fragment>
      {isStartVisible && (
        <FieldWrapper input date style={{ flexBasis: 150 }}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <InlineDatePicker
              data-testid="filter-start-date-input"
              keyboard
              clearable
              {...(value && value.lte && FUNCTIONS_TO_USE_MAX_DATE.includes(fieldFunction?.key) ? { maxDate: value.lte } : {})}
              value={value && value[startFunc] ? value[startFunc] : null}
              onChange={date => _onChange({ [startFunc]: _formatDate(date) })}
              format={getDateFormat()}
            />
          </MuiPickersUtilsProvider>
        </FieldWrapper>
      )}
      {isAndLabelVisible && (
        <FieldWrapper label style={{ flexBasis: 40 }}>
          and
        </FieldWrapper>
      )}
      {isEndVisible && (
        <FieldWrapper input date style={{ flexBasis: 150 }}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <InlineDatePicker
              data-testid="filter-end-date-input"
              keyboard
              clearable
              {...(value && value.gte && FUNCTIONS_TO_USE_MIN_DATE.includes(fieldFunction?.key) ? { minDate: value.gte } : {})}
              value={value && value.lte ? value.lte : null}
              onChange={date => _onChange({ lte: _formatDate(date) })}
              format={getDateFormat()}
            />
          </MuiPickersUtilsProvider>
        </FieldWrapper>
      )}
    </React.Fragment>
  );
};
