import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

import Components from './Components';

const RoadmapTable = withTheme()(styled.div`
  display: table;
  position: relative;
  border-collapse: collapse;
  height: 100%;
`);

// export Components inside RoadmapTable class
Object.keys(Components).forEach(columnKey => (RoadmapTable[columnKey] = Components[columnKey]));

RoadmapTable.displayName = 'RoadmapTable';

export default RoadmapTable;
