import omit from 'lodash/omit';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProjects } from 'store/projects';
import { getUserFilters } from 'store/filters/selectors';
import useDeepEffect from 'hooks/useDeepEffect';
import { IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { isLoadingProjects } from 'store/projects/selectors';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import getDisplayLayerAndPortfolioModeFromFilters from 'src/utils/filters/getDisplayLayerAndPortfolioModeFromFilters';

export default (
  pageId,
  filters,
  projectsAssociations,
  forceAssociations,
  extraFilters = {},
  paginationAttributes = {},
) => {
  const dispatch = useDispatch();
  const areProjectsBeingLoaded = useSelector(isLoadingProjects);
  const hasHierarchy = useSelector(state => state.organization.organization.has_hierarchy);
  const hasBet = useSelector(state => state.organization.organization.has_bet);
  const userFilters = useSelector(state => getUserFilters(state));

  const { displayLayer } = getDisplayLayerAndPortfolioModeFromFilters(filters, hasBet);

  const _fetchProjects = useCallback(async () => {
    const filtersForApiByLevels = compileFiltersBody(filters, userFilters, hasBet, pageId, displayLayer, null, extraFilters);

    const withParents = hasHierarchy && [IDEA_LAYER, INITIATIVE_LAYER].includes(displayLayer);

    const associations = {
      withTasks: true,
      withEstimates: true,
      withIntegrations: true,
      withParents,
      withVotes: true,
      ...(projectsAssociations || {}),
    };

    return dispatch(
      fetchProjects(filtersForApiByLevels, associations, {}, false, true, forceAssociations, extraFilters, paginationAttributes),
    );
  }, [filters, userFilters, displayLayer, extraFilters]);

  useDeepEffect(() => {
    _fetchProjects();
  }, [
    Object.keys(omit(filters, ['displayLayer', 'portfolioMode'])),
    Object.values(omit(filters, ['displayLayer', 'portfolioMode'])),
  ]);

  return [areProjectsBeingLoaded, _fetchProjects];
};
