import React from 'react';
import { connect } from 'react-redux';

import { stripeLoaded } from 'store/app';

const Stripe = ({ stripeLoaded }) => {
  if (!window.Cypress) {
    const s = document.createElement('script');

    s.type = 'text/javascript';
    s.src = 'https://js.stripe.com/v3/';
    s.onload = stripeLoaded;
    document.body.appendChild(s);
  }

  return <span data-cy="stripe-plugin" />;
};

export default connect(null, {
  stripeLoaded,
})(Stripe);
