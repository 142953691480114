import axios from 'axios';
import { toast } from 'react-toastify';

import { createThunk } from 'utils/store/thunk';

import {
  ENABLE_INTEGRATION_WEBHOOKS,
  DISABLE_INTEGRATION_WEBHOOKS,
  FETCH_AVAILABLE_INTEGRATIONS,
  ONE_STEP_OAUTH2,
  FETCH_ORG_INTEGRATION_DRAGONBOAT_FIELDS,
} from './types';

import { fetchOrgIntegrations, fetchUserIntegrations } from './actions';
import { getAvailableIntegrations } from './selectors';

export const enableIntegrationWebhooks = (integrationType, orgIntegrationId, showSuccessToast) => (dispatch, getState) => {
  const availableIntegrations = getAvailableIntegrations(getState());

  return dispatch(
    createThunk(
      ENABLE_INTEGRATION_WEBHOOKS,
      () =>
        axios.put(`/api/integrations/${integrationType}/${orgIntegrationId}/webhooks`).then(res => {
          if (showSuccessToast) {
            toast(`${availableIntegrations[integrationType]?.title || ''} Integration Successful`, { success: true });
          }
          return res.data;
        }),
      { integrationType, orgIntegrationId },
      { includeRetryAction: true },
    ),
  );
};

export const disableIntegrationWebhooks = (integrationType, orgIntegrationId) =>
  createThunk(
    DISABLE_INTEGRATION_WEBHOOKS,
    () => axios.delete(`/api/integrations/${integrationType}/${orgIntegrationId}/webhooks`).then(res => res.data),
    { integrationType, orgIntegrationId },
    { includeRetryAction: true },
  );

export const fetchAvailableIntegrations = () =>
  createThunk(FETCH_AVAILABLE_INTEGRATIONS, () => axios.get('/api/integrations/available-integrations').then(res => res.data));

export const authenticateWithOAuth2 = (integrationType, orgIntegrationId) => {
  return dispatch => {
    const eventListenerCallback = async ({ data }) => {
      if (!data || !data.code) return;

      try {
        const response = await axios.post(`/api/user-integrations/${integrationType}/${orgIntegrationId}/oauth/callback`, {
          code: data.code,
          integrationId: orgIntegrationId,
        });

        const { data: responseData } = response;

        dispatch(fetchOrgIntegrations());

        if (responseData.success) {
          dispatch(fetchUserIntegrations());
          toast(responseData?.message, { type: 'success' });
        } else {
          toast(responseData?.message, { type: 'error' });
        }
      } catch (err) {
        console.error('Salesforce Auth Failed', err);
        toast('Authentication Failed', { type: 'error' });
      } finally {
        window.removeEventListener('message', eventListenerCallback);
      }
    };

    const payload = axios
      .post(`/api/user-integrations/${integrationType}/${orgIntegrationId}/oauth/authorization-code`)
      .then(async res => {
        window.open(res.data, 'OAuth2 Authentication', 'height=700,width=1100');

        await window.addEventListener('message', eventListenerCallback, false);
      })
      .catch(err => {
        console.error('Salesforce Auth Failed', err);
        toast('Authentication Failed', { type: 'error' });
      });

    return { type: ONE_STEP_OAUTH2, payload };
  };
};

export const fetchIntegrationDragonboatFields = (integrationType, orgIntegrationId) =>
  createThunk(FETCH_ORG_INTEGRATION_DRAGONBOAT_FIELDS, () =>
    axios
      .get(`/api/org-integrations/${integrationType}/${orgIntegrationId}/dragonboat-fields`)
      .then(res => ({ data: res.data, orgIntegrationId })),
  );
