import React from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';

import { INTEGRATIONS_KEYS, INTEGRATIONS_NAMES } from 'constants/integrations';

const MicrosoftTeamsConnection = props => {
  const { onShowDisconnectConfirmation } = props;

  const integrationNameLabel = INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.microsoftTeams];

  return (
    <Wrapper>
      <Info>Your dragonboat workspace has been linked to your {integrationNameLabel} instance.</Info>
      <ButtonRow>
        <Button color="secondary" onClick={onShowDisconnectConfirmation}>
          Disconnect From {integrationNameLabel}
        </Button>
        <Note>(This will disconnect the connection between {integrationNameLabel} and your entire workspace)</Note>
      </ButtonRow>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Info = styled.div`
  margin: 0 8px 30px;
`;

const Note = styled.p`
  font-size: 12px;
  margin-left: 8px;
  margin-top: 3px;
  color: #b3b3b3;
`;

const ButtonRow = styled.div`
  padding-bottom: 5px;
`;

export default MicrosoftTeamsConnection;
