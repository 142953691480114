import { IDEA_LAYER } from 'store/projects/constants';
import { HIDDEN_FIELDS, LEFT_FIELDS, RIGHT_FIELDS } from 'constants/projectLightbox';
import {
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_PRODUCTS_2,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_THEMES,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
  PERSONAS,
  LIFECYCLES,
  RESOURCE_TEAM,
} from 'constants/common';

const showRevenueFields = layer => [IDEA_LAYER].includes(layer);

const getAdditionalRoadmapsMultiSelectFields = (hasProducts, hasProducts2) => [
  ADDITIONAL_ROADMAPS,
  ...(hasProducts ? [ADDITIONAL_PRODUCTS] : []),
  ...(hasProducts2 ? [ADDITIONAL_PRODUCTS_2] : []),
];

const getAdditionalOKRMultiSelectFields = (hasKeyResults, hasKeyResults2) => [
  ADDITIONAL_OBJECTIVES,
  ...(hasKeyResults ? [ADDITIONAL_KEY_RESULTS] : []),
  ...(hasKeyResults2 ? [ADDITIONAL_KEY_RESULTS_2] : []),
];

const getAdditionalTimeframesMultiSelectFields = hasMultiLevelMetadata => [
  ADDITIONAL_TIMEFRAMES,
  ...(hasMultiLevelMetadata ? [ADDITIONAL_TIMEFRAMES_2] : []),
];

const getAdditionalTeamsMultiSelectFields = hasTeams2 => [ADDITIONAL_TEAMS, ...(hasTeams2 ? [ADDITIONAL_TEAMS_2] : [])];

const getAllAdditionalFields = ({
  hasKeyResults,
  hasKeyResults2,
  hasProducts,
  hasProducts2,
  hasMultiLevelMetadata,
  hasTeams2,
}) => [
  ...getAdditionalOKRMultiSelectFields(hasKeyResults, hasKeyResults2),
  ...getAdditionalRoadmapsMultiSelectFields(hasProducts, hasProducts2),
  ...getAdditionalTimeframesMultiSelectFields(hasMultiLevelMetadata),
  ADDITIONAL_THEMES,
  ADDITIONAL_CATEGORIES,
  ...getAdditionalTeamsMultiSelectFields(hasTeams2),
];

const generateDefaultLayoutConfiguration = ({
  layer = IDEA_LAYER,
  hasProjectMetrics = false,
  hasMetadataMultiSelect = true,
  hasProducts = false,
  hasProducts2 = false,
  hasKeyResults = false,
  hasKeyResults2 = false,
  hasMultiLevelMetadata = false,
  hasTeams2 = false,
  hasPersonas = false,
  hasLifecycles = false,
  hasExternalKey = false,
} = {}) => ({
  [LEFT_FIELDS]: [
    'title',
    'links',
    'timeline',
    'statusSummary',
    'integrations',
    'details',
    'files',
    'customers',
    'customersCount',
    ...(showRevenueFields(layer) ? ['totalRevenue', 'activeRevenue', 'inactiveRevenue'] : []),
    'tags',
    'watchers',
    'user1',
    'user2',
    'user3',
    'user4',
    'user5',
    'user6',
    'user7',
    ...(hasExternalKey ? ['externalKey', 'externalParentKey'] : []),
    RESOURCE_TEAM,
  ],
  [RIGHT_FIELDS]: [
    'owner',
    'priority',
    'timeframes',
    'health',
    'progress',
    'phases',
    'completed_date',
    'parent',
    'allObjectives',
    ...(hasProjectMetrics ? ['metrics'] : []),
    'roadmaps',
    'themes',
    'categories',
  ],
  [HIDDEN_FIELDS]: [
    ...(hasPersonas ? [PERSONAS] : []),
    ...(hasLifecycles ? [LIFECYCLES] : []),
    ...(hasMetadataMultiSelect
      ? getAllAdditionalFields({ hasKeyResults, hasKeyResults2, hasProducts, hasProducts2, hasMultiLevelMetadata, hasTeams2 })
      : []),
  ],
});

const generateDefaultDrawerLayoutConfiguration = ({
  layer,
  hasProjectMetrics = false,
  hasMetadataMultiSelect = true,
  hasProducts = false,
  hasProducts2 = false,
  hasKeyResults = false,
  hasKeyResults2 = false,
  hasMultiLevelMetadata = false,
  hasTeams2 = false,
  hasPersonas = false,
  hasLifecycles = false,
  hasExternalKey = false,
} = {}) => ({
  [LEFT_FIELDS]: [
    'allObjectives',
    ...(hasProjectMetrics ? ['metrics'] : []),
    'roadmaps',
    'title',
    'links',
    'timeline',
    'statusSummary',
    'integrations',
    'details',
    'files',
    'customers',
    'customersCount',
    ...(showRevenueFields(layer) ? ['totalRevenue', 'activeRevenue', 'inactiveRevenue'] : []),
    'tags',
    'watchers',
    'user1',
    'user2',
    'user3',
    'user4',
    'user5',
    'user6',
    'user7',
    ...(hasExternalKey ? ['externalKey', 'externalParentKey'] : []),
    RESOURCE_TEAM,
  ],
  [RIGHT_FIELDS]: [
    'owner',
    'priority',
    'timeframes',
    'health',
    'progress',
    'phases',
    'completed_date',
    'parent',
    'themes',
    'categories',
  ],
  [HIDDEN_FIELDS]: [
    ...(hasPersonas ? [PERSONAS] : []),
    ...(hasLifecycles ? [LIFECYCLES] : []),
    ...(hasMetadataMultiSelect
      ? getAllAdditionalFields({ hasKeyResults, hasKeyResults2, hasProducts, hasProducts2, hasMultiLevelMetadata, hasTeams2 })
      : []),
  ],
});

export {
  getAdditionalRoadmapsMultiSelectFields,
  getAdditionalOKRMultiSelectFields,
  generateDefaultLayoutConfiguration,
  generateDefaultDrawerLayoutConfiguration,
};
