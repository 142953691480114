import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import BulkUpdateModal from 'components/BulkUpdateModal';
import getSystemFieldName from 'utils/getSystemFieldName';
import Autocomplete from 'design-system/atoms/Autocomplete/index';

import UserTypeMapper from 'utils/UserTypeMapper';
import useRoleAvailability from 'hooks/users/useRoleAvailability';

import {
  capacityColumnDef,
  firstNameColumnDef,
  lastNameColumnDef,
  roleColumnDef,
  statusColumnDef,
  skillColumnDef,
  teamColumnDef,
} from './helpers';

const AutocompleteEnhanced = (suggestions, addNew) => props => {
  return <Autocomplete value={props.value} onValueChange={props.onChange} suggestions={suggestions} addNew={addNew} />;
};

const BulkUpdateUsersModal = ({ onClose, selectedItems, teams, skills, systemFields, bulkUpdate, allowAddNewSettings }) => {
  const { isRoleAvailable } = useRoleAvailability();
  const roleOptions = UserTypeMapper.filter(u => isRoleAvailable(u.value));

  const fields = {
    'team.title': {
      editingComponent: AutocompleteEnhanced(teams, allowAddNewSettings),
      gridColumn: teamColumnDef,
      label: getSystemFieldName('team', systemFields),
    },
    'skill.title': {
      editingComponent: AutocompleteEnhanced(skills, allowAddNewSettings),
      gridColumn: skillColumnDef,
      label: getSystemFieldName('skill', systemFields),
    },
    status: {
      editingComponent: props => {
        return (
          <Select {...props} style={{ width: '100%' }}>
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </Select>
        );
      },
      gridColumn: statusColumnDef,
      label: 'Status',
      defaultValue: 'Active',
    },
    role_id: {
      editingComponent: props => {
        return (
          <Select {...props} style={{ width: '100%' }}>
            {roleOptions.map(ut => (
              <MenuItem value={ut.value}>{ut.label}</MenuItem>
            ))}
          </Select>
        );
      },
      gridColumn: roleColumnDef,
      label: 'Role',
    },
    capacity: {
      editingComponent: props => {
        return (
          <Select {...props} style={{ width: '100%' }}>
            <MenuItem value="true">Yes</MenuItem>
            <MenuItem value="false">No</MenuItem>
          </Select>
        );
      },
      gridColumn: capacityColumnDef,
      label: 'Capacity',
      defaultValue: 'true',
    },
  };

  return (
    <BulkUpdateModal
      selectedItems={selectedItems}
      resource="user"
      onClose={onClose}
      onConfirmed={users => {
        return bulkUpdate(users);
      }}
      fields={fields}
      mandatoryGridColumns={[firstNameColumnDef, lastNameColumnDef]}
    />
  );
};

export default BulkUpdateUsersModal;
