import { useCallback, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { getPhases } from 'store/phases/selectors';
import {
  createPhase,
  updatePhaseById,
  mergePhases,
  fetchPhases,
  addPhaseWithoutSave as addPhaseWithoutSaveAction,
  removeUnsavedPhases,
  createPhases,
  updatePhases,
  deletePhaseById,
  bulkDeletePhases,
  switchPhasesRowOrder,
} from 'store/phases';
import { bulkUpdateProjects } from 'store/projects/actions';

import { planningStages } from 'utils';

const DEFAULT_PHASE_TITLE = 'Backlog';

const DEFAULT_PLANNING_STAGE = 'Backlog';

const usePhases = hideArchivedItems => {
  const dispatch = useDispatch();

  const phases = useSelector(state => getPhases(state, !hideArchivedItems));

  const defaultPhase = useMemo(() => phases.find(phase => phase.system && phase.title === DEFAULT_PHASE_TITLE), [phases]);

  const phasesWithHierarchy = useMemo(() => {
    const phasesWithPath = phases.map(phase => ({
      ...phase,
      path: [phase.planning_stage, phase.id],
    }));

    const planningStagesWithPath = planningStages.map(planningStage => ({
      id: planningStage,
      title: planningStage,
      isGroupRow: true,
      path: [planningStage],
    }));

    return [...phasesWithPath, ...planningStagesWithPath];
  }, [phases]);

  const boundActionCreators = useMemo(() => {
    return bindActionCreators(
      {
        createPhase,
        createPhases,
        updatePhaseById,
        updatePhases,
        fetchPhases,
        mergePhases,
        removeUnsavedPhases,
        deletePhaseById,
        bulkDeletePhases,
        switchPhasesRowOrder,
        bulkUpdateProjects,
      },
      dispatch,
    );
  }, [dispatch]);

  const addPhaseWithoutSave = useCallback(
    (planningStage = DEFAULT_PLANNING_STAGE) => {
      return dispatch(
        addPhaseWithoutSaveAction({
          planning_stage: planningStage,
        }),
      );
    },
    [dispatch],
  );

  return {
    phases,
    phasesWithHierarchy,
    defaultPhase,
    planningStages,

    ...boundActionCreators,
    addPhaseWithoutSave,
  };
};

export default usePhases;
