import axios from 'axios';
import { getOrganizationSystemFieldsNames } from 'store/organization';
import { getCreateProjectPromise } from 'store/projects';
import handleCreateProjectError from 'features/Canvas/helpers/handleCreateProjectError';
import { createThunk } from 'utils/store/thunk';
import {
  CREATE_METRIC_FROM_CANVAS,
  CREATE_PROJECT_FROM_CANVAS,
  CREATE_OBJECTIVE_FROM_CANVAS,
  CREATE_KEY_RESULT_FROM_CANVAS,
} from 'features/Canvas/store/types';
import handleCreateMetricError from 'features/Canvas/helpers/handleCreateMetricError';
import { CREATE_METRIC_FULFILLED } from 'store/metrics/types';

import handleCreateOkrError from 'features/Canvas/helpers/handleCreateOkrError';
import { CREATE_KEY_RESULT_FULFILLED, CREATE_OBJECTIVE_FULFILLED } from 'store/objectives';

export const createProjectFromCanvas = (projectData, textLabelId, type) => (dispatch, getState) => {
  const systemFieldsNames = getOrganizationSystemFieldsNames(getState());

  const createProjectPromise = new Promise(async (resolve, reject) => {
    try {
      const result = await getCreateProjectPromise(dispatch, { ...projectData, isNew: true }).catch(err =>
        handleCreateProjectError(err, type, systemFieldsNames),
      );

      resolve(result);
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });

  return dispatch(createThunk(CREATE_PROJECT_FROM_CANVAS, createProjectPromise, { projectCreatedFrom: textLabelId }));
};

export const createObjectiveFromCanvas = (objectiveData, textlabelId, type) => (dispatch, getState) => {
  const systemFieldsNames = getOrganizationSystemFieldsNames(getState());

  const createOkrPromise = new Promise(async (resolve, reject) => {
    try {
      const result = await axios
        .post(`/api/objectives/`, objectiveData)
        .then(response => response.data)
        .catch(err => handleCreateOkrError(err, objectiveData?.title, type, systemFieldsNames));

      // we should use different approach. probably the rtk listners to update the main data source with the new okr.
      // If not, when opening the drawer after the creation won't show the details, just an empty new OKR drawer
      dispatch({
        payload: result,
        type: CREATE_OBJECTIVE_FULFILLED,
      });

      resolve(result);
    } catch (err) {
      // todo: properly handle duplicate name error
      console.error(err);
      reject(err);
    }
  });

  return dispatch(
    createThunk(CREATE_OBJECTIVE_FROM_CANVAS, createOkrPromise, {
      objectiveCreatedFrom: textlabelId,
    }),
  );
};

export const createKeyResultFromCanvas = (keyResultData, textlabelId, type) => (dispatch, getState) => {
  const systemFieldsNames = getOrganizationSystemFieldsNames(getState());

  const createOkrPromise = new Promise(async (resolve, reject) => {
    try {
      const result = await axios
        .post('/api/keyResults/', keyResultData)
        .then(response => response.data)
        .catch(err => handleCreateOkrError(err, keyResultData?.title, type, systemFieldsNames));

      // we should use different approach. probably the rtk listners to update the main data source with the new okr.
      // If not, when opening the drawer after the creation won't show the details, just an empty new OKR drawer
      dispatch({
        payload: result,
        type: CREATE_KEY_RESULT_FULFILLED,
      });

      resolve(result);
    } catch (err) {
      // todo: properly handle duplicate name error
      console.error(err);
      reject(err);
    }
  });

  return dispatch(
    createThunk(CREATE_KEY_RESULT_FROM_CANVAS, createOkrPromise, {
      keyResultCreatedFrom: textlabelId,
    }),
  );
};

export const createMetricFromCanvas = (metricData, textLabelId) => (dispatch, getState) => {
  const systemFieldsNames = getOrganizationSystemFieldsNames(getState());

  const createMetricPromise = new Promise(async (resolve, reject) => {
    try {
      const result = await axios
        .post(`/api/metrics/`, metricData)
        .then(response => response.data)
        .catch(err => handleCreateMetricError(err, metricData?.title, systemFieldsNames));

      dispatch({
        payload: result,
        type: CREATE_METRIC_FULFILLED,
      });

      resolve(result);
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });

  return dispatch(createThunk(CREATE_METRIC_FROM_CANVAS, createMetricPromise, { metricCreatedFrom: textLabelId }));
};
