import { always, complement, identity, pluck, when } from 'ramda';

import { returnsTrueIfKeyIsNotEnterOrTab, returnsTrueIfKeyIsNotEscOrTab } from 'utils/agGrid';

// TODO: move this to design-system
import AgGridYYYYMMDDRenderer from 'components/AgGridDateRenderer/AgGridYYYYMMDDRenderer';
import AgGridTextArea from 'design-system/atoms/AgGridTextArea/AgGridTextArea';
import AgGridNumeric from 'design-system/atoms/AgGridNumeric/AgGridNumeric';
import AgGridDropdown from 'design-system/atoms/AgGridDropdown/AgGridDropdown';
import AgGridMultiSelect from 'design-system/atoms/AgGridMultiSelect/AgGridMultiSelect';

import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';
import { multiSelectComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { ChipsCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { DatePickerCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import getSortedDropdownOptions from 'store/customFields/helpers/getSortedDropdownOptions';

const getKeys = pluck('key');
const defaultToEmptyArray = when(complement(identity), always([]));

const editOptions = {
  editable: true,
};

export default defaultOptions =>
  ({ key, title, description, field_type: fieldType, data, status, data_enhanced: dataEnhanced }) => {
    const cell = {
      ...editOptions,
      ...defaultOptions,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEnterOrTab,
      headerName: title,
      headerTooltip: 'test',
      field: key,
      cellEditorParams: {
        autoFocus: true,
      },
      enableRowGroup: true,
      cellEditor: AgGridTextArea,
    };

    switch (fieldType) {
      case 'Short Text':
        return cell;
      case 'Number':
        return {
          ...cell,
          cellEditor: AgGridNumeric,
        };
      case 'Date':
        return {
          ...cell,
          cellRenderer: AgGridYYYYMMDDRenderer,
          cellEditor: DatePickerCellEditor,
          cellEditorPopup: true,
        };
      case 'Yes/No':
        return {
          ...cell,
          enableRowGroup: true,
          cellEditor: AgGridDropdown,
          cellEditorParams: {
            options: [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ],
          },
          valueFormatter: option => {
            switch (option.value) {
              case 'true':
                return 'Yes';
              case 'false':
                return 'No';
              default:
                return 'No';
            }
          },
        };
      case 'Dropdown':
        return {
          ...cell,
          enableRowGroup: true,
          cellEditor: AgGridDropdown,
          valueFormatter: params => {
            return (data || {})[params.value];
          },
          cellEditorParams: {
            options: Object.entries(data || {}).map(([value, label]) => ({
              label,
              value,
            })),
            showEmptyOption: true,
          },
        };
      case TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN:
        const sortedValidOptions = getSortedDropdownOptions(dataEnhanced, false) || [];

        return {
          ...cell,
          valueSetter: params => {
            const value = params.newValue ? getKeys(params.newValue) : params.newValue;

            // sets the new value as a list of selected option keys/ids
            params.data[key] = value;

            return true;
          },
          formatValue: value => {
            const valueKeys = defaultToEmptyArray(value);
            const val = valueKeys.map(optionKey => sortedValidOptions.find(opt => opt.key === optionKey));

            return val;
          },
          cellRenderer: ChipsCellRenderer,
          cellEditor: AgGridMultiSelect,
          cellEditorParams: {
            idField: 'key',
            options: sortedValidOptions,
            optionsMapper: { label: 'title', value: 'key' },
            showEmptyOption: false,
            hideCreateOption: true,
          },
          cellEditorPopup: true,
          comparator: multiSelectComparator,
          suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
          width: 120,
          showTooltip: true,
          labelField: 'title',
          chipVariant: 'outlined',
          colorField: '',
        };
      default:
        return cell;
    }
  };
