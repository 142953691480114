import React from 'react';
import NumberFormat from 'react-number-format';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

import MultiSelect from 'design-system/atoms/MultiSelect/index';

import FieldText from 'design-system/molecules/FilterCondition/components/FieldText';
import DatePicker from 'components/DatePicker';

import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';

const CustomFieldEditorFactory = (fieldType, options = [], value, onChange) => {
  switch (fieldType) {
    case TYPES_OF_CUSTOM_FIELDS.DROPDOWN:
      return (
        <Select style={{ width: '100%' }} value={value} onChange={onChange}>
          <MenuItem value="" />
          {options.map(option => (
            <MenuItem value={option.id}>{option.title}</MenuItem>
          ))}
        </Select>
      );

    case TYPES_OF_CUSTOM_FIELDS.YES_NO:
      return <Checkbox checked={!!value} onChange={() => onChange(!value)} />;

    case TYPES_OF_CUSTOM_FIELDS.SHORT_TEXT:
      return <FieldText fullWidth value={value || ''} onChange={({ target }) => onChange(target.value)} />;

    case TYPES_OF_CUSTOM_FIELDS.DATE:
      return DatePicker({ value, onChange });

    case TYPES_OF_CUSTOM_FIELDS.NUMBER:
      return (
        <NumberFormat
          customInput={TextField}
          thousandSeparator
          onValueChange={values => onChange(values.value)}
          className="ag-cell-edit-input"
          type="text"
          style={{ textAlign: 'center' }}
          value={value}
        />
      );

    case TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN:
      return <MultiSelect options={options} onChange={onChange} optionsMapper={{ label: 'title', value: 'id' }} value={value} />;

    default:
      return '';
  }
};

export default CustomFieldEditorFactory;
