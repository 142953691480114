import React from 'react';
import { css } from 'styled-components';
import { defaultTo } from 'ramda';

import { INITIATIVE_LAYER, BET_LAYER, IDEA_LAYER } from 'store/projects/constants';
import theme from 'design-system/theme';
import ProjectType from 'containers/ProjectType';

import { ROW_HEIGHT_DEFAULT, isBetOrInitiative } from './helpers';

import Title from './components/Title';

const defaultEmptyObj = defaultTo({});

const textStyles = css`
  font-size: ${theme.typography?.fontSize}px !important;
  color: ${theme.palette?.text.lightGrey} !important;
`;

const HierarchyCellRenderer = params => {
  const rendererParams = params?.colDef?.cellRendererParams || {};

  const { showChildrenCount = true } = rendererParams;

  if (!params.data) {
    return <Title params={params} showChildrenCount={showChildrenCount} textStyles={textStyles} />;
  }

  switch (params.data && +params.data.layer) {
    case +BET_LAYER:
    case +INITIATIVE_LAYER:
    case +IDEA_LAYER:
      return (
        <Title
          params={params}
          icon={<ProjectType layer={params.data.layer} />}
          showChildrenCount={showChildrenCount && isBetOrInitiative(params.data.layer)}
          rowHeight={ROW_HEIGHT_DEFAULT}
          textStyles={textStyles}
        />
      );
    default:
      const { renderHierarchicalTitleIcon } = defaultEmptyObj(params.colDef?.cellRendererParams);

      return (
        <Title
          isChild
          params={params}
          childrenCount={params.data.childrenCountFiltered}
          rowHeight={ROW_HEIGHT_DEFAULT}
          textStyles={textStyles}
          icon={renderHierarchicalTitleIcon && renderHierarchicalTitleIcon(params)}
        />
      );
  }
};

export default HierarchyCellRenderer;
