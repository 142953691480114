import axios from 'axios';

export const createNewRoadmapVersionApiCall = (pageFilters, name, description = '', committed = true) => {
  const requestBody = {
    pageFilters,
    name,
    committed,
    description,
  };

  return axios.post('/api/v1/roadmap-versions', requestBody).then(res => res.data);
};
