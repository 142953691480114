import React, { useState } from 'react';
import styled from 'styled-components';
import Iframe from 'react-iframe';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import InputTextField from 'design-system/atoms/InputTextField/index';
import Button from 'design-system/atoms/Button/index';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';

import { materialColors } from 'design-system/themes/default';
import AuthErrorMessage from 'components/AuthErrorMessage';

const RequestAccessFormComponent = ({ error, email, handleEmailValue, organization, handleRequest }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  if (!organization) {
    return <PageLoading />;
  }

  return (
    <Wrapper style={{ display: isLoaded ? 'block' : 'none' }}>
      <Grid container direction="column" alignItems="center" spacing={0}>
        <Grid item xs={12}>
          <StyledTitle>
            Go to {organization.title && organization.title}
            {!organization.title && organization.slug}
          </StyledTitle>
        </Grid>
        <Grid item xs={12}>
          <StyledSubTitle>{organization.host}</StyledSubTitle>
        </Grid>
        <Grid item xs={12} container spacing={16}>
          <Grid item xs={12}>
            <SSOLoginButton
              onLoad={() => setIsLoaded(true)}
              title="Google Button"
              url={`${process.env.REACT_APP_PUBLIC_URL}/google_button`}
              height="46px"
              width="100%"
              id="myId"
              display="initial"
              position="relative"
              allowFullScreen
            />
          </Grid>
          <Grid item xs>
            <StyledSeparator>
              <div />
              <div>or</div>
              <div />
            </StyledSeparator>
          </Grid>
          <form onSubmit={handleRequest}>
            <StyledFormGrid item xs={12} container spacing={16}>
              <Grid item xs={12}>
                <StyledInputTextField
                  multiline
                  variant="outlined"
                  placeholder="Enter your email"
                  type="email"
                  handleValue={handleEmailValue}
                  value={email}
                />
              </Grid>
              <Grid item xs={12}>
                {error && <AuthErrorMessage errorMessage={error} />}
              </Grid>
              <Grid item xs={12}>
                <StyledLoginButton
                  background={materialColors.mediumPurple}
                  variant="contained"
                  type="submit"
                  disabled={!/\S+@\S+\.\S+/.test(email)}
                >
                  Request access
                </StyledLoginButton>
              </Grid>
            </StyledFormGrid>
          </form>
          <StyledFooter item xs={12} container spacing={8}>
            <Grid item xs={12}>
              <StyledFooterSpan>
                Create or recover your password? <Link to="/reset_password">Click here</Link>
              </StyledFooterSpan>
            </Grid>
            <Grid item xs={12}>
              <StyledFooterSpan>
                Don’t have a dragonboat account? <a href={`${process.env.REACT_APP_PUBLIC_URL}/signup`}>Signup here</a>
              </StyledFooterSpan>
            </Grid>
          </StyledFooter>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default RequestAccessFormComponent;

const Wrapper = styled.div`
  max-width: 360px;
`;

const StyledTitle = styled.h1`
  color: #3461ac;
  font-size: 2.25rem; // -> 36px -> with :root font-size of 16px;
  font-weight: bold;
  margin: 0 0 1.813rem 0;
`;

const StyledSubTitle = styled.h5`
  color: #131c23;
  font-size: 1.125rem;
  line-height: 1.2rem;
  font-weight: 400;
  margin: 0 0 1.813rem 0;
`;

const SSOLoginButton = styled(Iframe)`
  &&&& {
    box-shadow: 0px 1px 3px #00000029;
    border: 1px solid #131c2329;
    border-radius: 2px;
    overflow: hidden;

    html {
      overflow: hidden;
    }
  }
`;

const StyledSeparator = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;

    div:first-child,
    div:last-child {
      width: 40%;
      height: 0;
      border: #dddddd solid 1px;
    }

    div:nth-child(2) {
      width: 20%;
      text-align: center;
    }
  }
`;

const StyledLoginButton = styled(Button)`
  &&&& {
    color: #ffffff;
    width: 100%;
  }
`;

const StyledFooter = styled(Grid)`
  &&&& {
    margin-top: 0.8rem;
  }
`;

const StyledFooterSpan = styled.span`
  &&&& {
    color: #131c23;
    font-weight: 400;
    font-size: 0.875rem;

    a {
      color: #2ca6ae;
      text-decoration: none;
    }
  }
`;

const StyledInputTextField = styled(InputTextField)`
  &&&& {
    input::placeholder {
      color: ${({ theme }) => theme.palette.text.secondary};
      font-size: ${({ theme }) => theme.typography.fontSizeSmallLargeRem}rem;
      font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    }
  }
`;

const StyledFormGrid = styled(Grid)`
  &&&& {
    margin: 0;
  }
`;
