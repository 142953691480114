import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import { equals, isNil, or, pipe } from 'ramda';

import { KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL } from 'constants/objectives';

import { OBJECT_OBJECTIVE, OBJECT_OBJECTIVE_CORP_STRING } from '../types';
import { filterActiveItems } from 'utils/index';

const UNDEFINED_ROW_ID = 'null';
const isUndefinedId = equals(UNDEFINED_ROW_ID);
const rowIdIsUndefined = pipe(or(isUndefinedId, isNil));

const removeKeyResultsFromKeyResult = (keyResult, keyResultIdsToRemove) => {
  return keyResult.keyResults?.length
    ? {
        ...keyResult,
        keyResults: keyResult.keyResults.filter(kr => !keyResultIdsToRemove.includes(kr.id)),
      }
    : keyResult;
};

export const deleteKeyResults = (keyResults, keyResultsById) => {
  return Object.entries(keyResults).reduce((acc, [key, keyResultsForLevel]) => {
    acc[key] = keyResultsForLevel.reduce((cleanedKrs, kr) => {
      return !keyResultsById.includes(kr.id) ? [...cleanedKrs, removeKeyResultsFromKeyResult(kr, keyResultsById)] : cleanedKrs;
    }, []);

    return acc;
  }, {});
};

const KEY_RESULTS_L1_STATE_INDEX = 0;

export const addKeyResult = (keyResults, keyResult) => {
  const levelKeyResults = keyResults[keyResult.level] || [];

  if (levelKeyResults.some(kr => kr.id === keyResult.id)) {
    return keyResults;
  }

  const newKeyResultsState = {
    ...keyResults,
  };

  const keyResultsL1 = keyResults[KEY_RESULTS_L1_STATE_INDEX];

  if (keyResult.level === 1) {
    // Must update parent's key results on state as well.
    const parentKeyResultIndex = keyResultsL1.findIndex(kr => kr.id === keyResult.parent_id);

    if (parentKeyResultIndex !== -1) {
      const parentKeyResult = keyResultsL1[parentKeyResultIndex];

      const updatedParentKeyResult = {
        ...parentKeyResult,
        keyResults: [keyResult, ...(parentKeyResult?.keyResults ?? [])],
      };

      newKeyResultsState[KEY_RESULTS_L1_STATE_INDEX] = [
        ...keyResultsL1.slice(0, parentKeyResultIndex),
        updatedParentKeyResult,
        ...keyResultsL1.slice(parentKeyResultIndex + 1),
      ];
    }
  }

  levelKeyResults.unshift(keyResult);

  return {
    ...newKeyResultsState,
    [keyResult.level]: levelKeyResults,
  };
};

export const updateKeyResult = (keyResults, keyResult) => {
  const needToUpdateKeyResultParent = keyResult.level === 1;

  let parentLevelKeyResults = keyResults[0];

  if (needToUpdateKeyResultParent) {
    const parentIndex = parentLevelKeyResults.findIndex(kr => kr.id === keyResult.parent_id);

    const parentKeyResult = parentLevelKeyResults[parentIndex];

    let parentKeyResultChild = parentKeyResult.keyResults ?? [];
    const childIndex = parentKeyResultChild.findIndex(kr => kr.id === keyResult.id);

    parentKeyResultChild = parentKeyResultChild.filter(kr => kr.id !== keyResult.id);
    parentKeyResultChild.splice(childIndex, 0, keyResult);

    parentKeyResult.keyResults = parentKeyResultChild;

    parentLevelKeyResults = parentLevelKeyResults.filter(kr => kr.id !== keyResult.parent_id);
    parentLevelKeyResults.splice(parentIndex, 0, parentKeyResult);
  }

  let levelKeyResults = keyResults[keyResult.level];
  const index = levelKeyResults?.findIndex(kr => kr.id === keyResult.id);

  // keep the old key result childs if necessary
  const oldKeyResultChilds = [...(levelKeyResults?.[index]?.keyResults ?? [])];
  const newKeyResult = isEmpty(oldKeyResultChilds) ? keyResult : { ...keyResult, keyResults: oldKeyResultChilds };

  levelKeyResults = (levelKeyResults || []).filter(kr => kr.id !== keyResult.id);
  levelKeyResults.splice(index, 0, newKeyResult);

  if (needToUpdateKeyResultParent) {
    return {
      ...keyResults,
      0: parentLevelKeyResults,
      [keyResult.level]: levelKeyResults,
    };
  }

  return {
    ...keyResults,
    [keyResult.level]: levelKeyResults,
  };
};

export const removeUnsaved = list => {
  return list ? list.filter(obj => obj.id) : [];
};

export const removeUnsavedKeyResults = keyResults => {
  return deleteKeyResults(keyResults, [undefined]);
};

const KEY_RESULTS_LEVELS = [KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL];

export const buildKeyResultsFromObjectives = objectives => {
  const _getKeyResults = (data, currentAcc) => {
    return data.reduce((acc, entry) => {
      if (KEY_RESULTS_LEVELS.includes(entry.level)) {
        acc[entry.level] = [...(acc[entry.level] || []), entry];
      }

      if (entry.keyResults) {
        return {
          ...acc,
          ..._getKeyResults(entry.keyResults, acc),
        };
      }

      return acc;
    }, currentAcc || {});
  };
  const result = _getKeyResults(objectives);

  return result;
};

export const buildObjectivesWithKeyResults = (objectives, keyResults) => {
  const allKeyResults = flatten(Object.values(keyResults));
  const normalizedKeyResultsByObjective = allKeyResults.reduce((acc, kr) => {
    if (!acc[kr.objective_id]) acc[kr.objective_id] = [];
    acc[kr.objective_id].push(kr);
    return acc;
  }, {});

  return objectives.reduce((acc, objective) => {
    let highLevel = 0;
    const objectiveKeyResults = normalizedKeyResultsByObjective[objective.id] || [];
    const objectiveKeyResultsByLevel = objectiveKeyResults.reduce((accObjKr, kr) => {
      if (kr.level > highLevel) highLevel = kr.level;
      if (!accObjKr[kr.level]) accObjKr[kr.level] = [];
      accObjKr[kr.level].push(kr);
      return accObjKr;
    }, {});
    const clonedObjective = { ...objective };

    const _addKeyResults = (obj, currentLevel) => {
      const keyResultsOnLevel = objectiveKeyResultsByLevel[currentLevel] || [];
      const keyResults = obj.objective_id ? keyResultsOnLevel.filter(kr => kr.parent_id === obj.id) : keyResultsOnLevel;

      if (currentLevel < highLevel) {
        return keyResults.reduce((acc, kr) => {
          acc.push({
            ...kr,
            keyResults: _addKeyResults(kr, currentLevel + 1),
          });
          return acc;
        }, []);
      }

      return keyResults;
    };

    clonedObjective.keyResults = _addKeyResults(clonedObjective, 0);

    acc.push(clonedObjective);

    return acc;
  }, []);
};

export const getKeyResultFromMetadataRoadmap = (keyResults, keyResultRoadmap, id) => {
  let currentKeyResult;

  Object.keys(keyResults).forEach(level => {
    if (!currentKeyResult) {
      currentKeyResult = keyResults[level].find(keyResult => keyResult.id === parseInt(keyResultRoadmap[id]));
    }
  });

  return currentKeyResult;
};

export const isObjective = level => [OBJECT_OBJECTIVE, OBJECT_OBJECTIVE_CORP_STRING].includes(level);

export const isRowIdUndefined = id => rowIdIsUndefined(id);

export const hideArchived = (items = []) =>
  items.filter(filterActiveItems).map(item => ({
    ...item,
    ...(item.children ? { children: hideArchived(item.children) } : {}),
    ...(item.keyResults ? { keyResults: hideArchived(item.keyResults) } : {}),
  }));
