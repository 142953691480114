import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Notifications from 'containers/Main/Notifications';
import DragonIconButton from 'design-system/atoms/DragonIconButton/index';

import Bell from 'design-system/atoms/DragonIcons/Bell';
import Logout from 'design-system/atoms/DragonIcons/Logout';
import Person from 'design-system/atoms/DragonIcons/Person';
import { getCurrentUser } from 'store/login/selectors';
import history from 'store/utils/history';
import { getUserName, getUserShortName } from 'utils';

const TinyMenu = props => {
  const { onLogout } = props;
  const [notificationsAnchor, setNotificationsAnchor] = useState(null);
  const currentUser = useSelector(getCurrentUser);
  const notificationsNotRead = useSelector(({ notifications }) => notifications._meta && notifications._meta.notRead);
  const profile = {
    label: getUserName(currentUser),
    short: getUserShortName(currentUser),
    path: null,
    getPath: () => {
      localStorage.setItem('closeLeftMenu', 'true');

      return 'user/preferences';
    },
  };

  const _handleNavigate = route => {
    const path = typeof route.getPath === 'function' ? route.getPath() : route.path;

    if (path) history.push(`/${path}`);
  };

  return (
    <Wrapper>
      <NotificationWrapper id="notification-wrapper">
        <Notifications
          anchorElem={notificationsAnchor}
          open={!!notificationsAnchor}
          onClose={() => setNotificationsAnchor(null)}
        />
        <ItemWrapper id="item-wrapper-alert">
          <IconWrapper id="icon-wrapper">
            <DragonIconButton
              id="button-alert"
              variant="primary"
              type="transparent"
              icon={<Bell />}
              onClick={e => setNotificationsAnchor(e.currentTarget)}
            />
            {notificationsNotRead > 0 && <Badge />}
          </IconWrapper>
        </ItemWrapper>
      </NotificationWrapper>
      <ItemWrapper id="item-wrapper-person">
        <IconWrapper>
          <DragonIconButton variant="primary" type="transparent" icon={<Person />} onClick={() => _handleNavigate(profile)} />
        </IconWrapper>
      </ItemWrapper>
      <ItemWrapper id="item-wrapper-logout">
        <IconWrapper>
          <DragonIconButton variant="primary" type="transparent" icon={<Logout />} onClick={onLogout} />
        </IconWrapper>
      </ItemWrapper>
    </Wrapper>
  );
};

export default TinyMenu;

// Reference: HeaderOptionsComponent's Wrapper
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 30px 30px 30px 1fr;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

// Reference: DragonNavItem's IconWrapper
const IconWrapper = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    position: relative;

    svg path {
      fill: ${({ theme }) => theme.palette.newLayout.background.disable};
    }

    & > * > button {
      padding: 8px;
    }
  }
`;

// Reference: DragonNavItem's Badge
const Badge = styled.div`
  &&&& {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 6px;
    height: 6px;
    border-radius: 50%;

    background: ${({ theme }) => theme.palette.newLayout.background.danger};
  }
`;

// Reference: DragonNavItem Wrapper
const ItemWrapper = styled.div`
  &&&& {
    padding: 0;

    display: grid;
    align-items: center;
    position: relative;
    margin: 0;

    &:hover {
      ${IconWrapper} {
        svg path {
          fill: ${({ theme }) => theme.palette.newLayout.background.primary};
        }
      }
    }

    ${({ checked, theme }) =>
      checked &&
      `

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      background: ${theme.palette.newLayout.background.header} 0% 0% no-repeat padding-box;
      border-radius: 3px;
      width: calc(100% - 18px);
      height: 100%;
      margin: 0 9px;
    }

    ${IconWrapper} {
      svg path {
        fill: ${theme.palette.newLayout.background.primary};
      }
    }
    `}
  }
`;

const NotificationWrapper = styled.div`
  display: flex;
  position: relative;
`;
