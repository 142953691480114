import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import { FeatureFlags } from '@dragonboat/config';

import { filter, pipe, length, gte, propEq } from 'ramda';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import Loading from 'design-system/atoms/Loading/Loading';
import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete';
import ToggleButton from 'design-system/molecules/ToggleButton/index';

import theme from 'design-system/theme';

import { isGroupFieldADate } from 'utils/grouping';

import useFeatureFlags from 'hooks/useFeatureFlags';

import EditOff from 'routes/Dashboard/Summary/ActionsBar/images/EditOff';
import PreferencesDialog from 'routes/Dashboard/Summary/ActionsBar/PreferencesDialog';
import LocalSearchInput from 'containers/LocalSearchInput';
import SwimlaneSelectedVersionsColors from 'routes/RoadmapVersions/components/SwimlaneSelectedVersionsColors';

import downloadSummaryPNG from 'routes/Dashboard/Summary/helpers/downloadSummaryPNG';
import { selectHasHierarchy, selectHasKeyResults, selectHasProducts } from 'store/organization';

import { useSummaryTableData } from 'routes/Dashboard/Summary/hooks/useSummaryTableData';
import useSummaryState from 'routes/Dashboard/Summary/hooks/useSummaryState';
import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useScenarioVersionColors from 'routes/RoadmapVersions/hooks/useScenarioVersionColors';
import useGetOptionsForDropwdowns from '../hooks/useGetOptionsForDropwdowns';

const READ_ONLY_MODE_ONLY_FIELDS = ['customers', 'dates', 'tags'];

const GROUP_BY_MIN_WIDTH = '50px';
// const GROUP_BY_FONT_SIZE = `${theme.typography.fontSizeSmallLarge}px`;
const SMALL_GROUP_BY_MIN_WIDTH = '40px';
const SMALL_GROUP_BY_FONT_SIZE = `${theme.typography.fontSize}px`;

const maxOneIsDateGroupField = pipe(filter(isGroupFieldADate), length, gte(1));

const isDateType = propEq('type', 'dates');

const ActionBarComponent = React.memo(({ isGoalMode, slice }) => {
  const [showMenu, setShowMenu] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [showDisplayPreferences, setShowDisplayPreferences] = React.useState(false);

  const hasEditScenarioComparePage = useFeatureFlags([FeatureFlags.HAS_EDIT_SCENARIO_COMPARE_PAGE]);

  const { allRoadmapVersions } = useRoadmapVersions();
  const { scenarioColors } = useScenarioVersionColors();

  const { isReadOnly, selectedColOption, selectedRowOption, selectedGroupByOption, hideEmptyLanes, allCollapsed, updateState } =
    useSummaryState(isGoalMode, slice);

  const { toggleAllTableDataGroups } = useSummaryTableData();

  const hasHierarchy = useSelector(selectHasHierarchy);
  const hasKeyResults = useSelector(selectHasKeyResults);
  const hasProducts = useSelector(selectHasProducts);

  const _handleMenuItemClick = React.useCallback(() => {
    setShowDisplayPreferences(true);
    setShowMenu(null);
  });

  const _handleReadOnlyClick = () => {
    updateState({ isReadOnly: !isReadOnly });
    setShowMenu(null);
  };

  const _handleMenuBoxClose = React.useCallback(() => setShowMenu(null));
  const _handleMoreOptionsButtonClick = React.useCallback(e => setShowMenu(e.currentTarget));
  const _handleShowPreferencesClick = React.useCallback(() => setShowDisplayPreferences(false));

  const _handleHideEmptyLanesClick = React.useCallback(() => {
    updateState({ hideEmptyLanes: !hideEmptyLanes });
    setShowMenu(null);
  }, [hideEmptyLanes]);

  const { colGroupOptions, rowGroupOptions, inlineGroupOptions } = useGetOptionsForDropwdowns();

  const _onChangeRowOrColData = (key, val) => {
    const stateToUpdate = { [key]: val };

    // dynamic dates not supported
    if (isDateType(val) && !isReadOnly) stateToUpdate.isReadOnly = true;
    else stateToUpdate.isReadOnly = isReadOnly;

    updateState(stateToUpdate);
  };
  const _onChangeChipData = d => {
    return updateState({
      groupByData: d,
      isReadOnly: READ_ONLY_MODE_ONLY_FIELDS.includes(d.key) || isReadOnly,
    });
  };

  const _onDownloadPngClick = () => {
    setShowMenu(null);
    setIsLoading(true);
    downloadSummaryPNG().then(() => {
      setIsLoading(false);
    });
  };

  return (
    <Wrapper data-html2canvas-ignore>
      {isLoading && <StyledLoading />}
      <Grid container alignItems="center">
        <ReadOnlyDropdowns item xs={4}>
          <FormGroup row>
            <StyledGroupByAutoComplete
              label="Column by"
              name="selectedColData"
              suggestions={colGroupOptions.filter(o => maxOneIsDateGroupField([o, selectedRowOption, selectedGroupByOption]))}
              value={selectedColOption}
              onChange={option => {
                if (option)
                  _onChangeRowOrColData(
                    'selectedColData',
                    colGroupOptions.find(o => o.key === option.key),
                  );
              }}
              data-testid="dropdown-column"
              inputStyle={{ fontSize: SMALL_GROUP_BY_FONT_SIZE }}
              inputContainerStyle={{ minWidth: GROUP_BY_MIN_WIDTH }}
            />

            <StyledGroupByAutoComplete
              label="In cell group by"
              name="groupByData"
              suggestions={inlineGroupOptions.filter(o => maxOneIsDateGroupField([o, selectedRowOption, selectedColOption]))}
              value={selectedGroupByOption}
              onChange={_onChangeChipData}
              data-testid="dropdown-groupBy"
              inputStyle={{ fontSize: SMALL_GROUP_BY_FONT_SIZE }}
              inputContainerStyle={{ minWidth: SMALL_GROUP_BY_MIN_WIDTH }}
            />
          </FormGroup>
        </ReadOnlyDropdowns>
        <StyledScenarioColorsGrid item xs={4}>
          <SwimlaneSelectedVersionsColors scenarioColors={scenarioColors} roadmapVersions={allRoadmapVersions} />
        </StyledScenarioColorsGrid>

        <Grid item xs={4}>
          <FormGroup row style={{ justifyContent: 'flex-end' }}>
            <LocalSearchInput />

            {selectedGroupByOption && selectedGroupByOption.key && (
              <ControlItem>
                <ButtonIcon
                  title={allCollapsed ? 'Expand all' : 'Collapse all'}
                  onClick={toggleAllTableDataGroups}
                  id="expand-collapse"
                  data-testid="expand-collapse"
                >
                  {allCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </ButtonIcon>
              </ControlItem>
            )}

            {hasEditScenarioComparePage ? (
              <ControlItem>
                <ButtonIcon id="edit-scenario" onClick={_handleReadOnlyClick} disabled={isDateType(selectedColOption)}>
                  {isReadOnly ? <EditOff /> : <CreateOutlinedIcon />}
                </ButtonIcon>
              </ControlItem>
            ) : null}

            <ControlItem
              id="port-3d-more-options"
              data-testid="port-3d-more-options"
              aria-controls="display-options"
              aria-haspopup="true"
              onClick={_handleMoreOptionsButtonClick}
            >
              <ToggleButton icon={<MoreHorizIcon />} />
            </ControlItem>

            <MenuBox id="display-options" anchorEl={showMenu} keepMounted open={Boolean(showMenu)} onClose={_handleMenuBoxClose}>
              <>
                <StyledMenuItem onClick={_handleMenuItemClick}>
                  <MenuItemWrapper>
                    <SettingsIcon />
                    <MenuItemText>Display Preferences</MenuItemText>
                  </MenuItemWrapper>
                  <PreferencesDialog
                    open={showDisplayPreferences}
                    onCancel={_handleShowPreferencesClick}
                    hasProducts={hasProducts}
                    hasKeyResults={hasKeyResults}
                    hasHierarchy={hasHierarchy}
                    groupByOptions={inlineGroupOptions}
                    colGroupOptions={colGroupOptions}
                    rowGroupOptions={rowGroupOptions}
                    slice={slice}
                    updateStateAction={updateState}
                    avoidDatesConfig
                  />
                </StyledMenuItem>
              </>
              <StyledMenuItem onClick={_handleHideEmptyLanesClick}>
                <MenuItemWrapper>
                  {!hideEmptyLanes ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  <MenuItemText>Hide empty lanes</MenuItemText>
                </MenuItemWrapper>
              </StyledMenuItem>

              <StyledMenuItem onClick={_onDownloadPngClick}>
                <MenuItemWrapper>
                  <PhotoCamera />
                  <MenuItemText>Download PNG</MenuItemText>
                </MenuItemWrapper>
              </StyledMenuItem>
            </MenuBox>
          </FormGroup>
        </Grid>
      </Grid>
    </Wrapper>
  );
});

ActionBarComponent.displayName = 'CompareScenariosActionBar';

export default ActionBarComponent;

const Wrapper = styled.div`
  min-height: 50px;
  margin: 5px 0;
`;

const ControlItem = styled.div``;

const MenuBox = styled(Menu)`
  &&&& {
    margin-top: 50px;
  }
`;

const MenuItemWrapper = styled.div`
  &&&& {
    display: flex;
    align-items: end;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &&&& {
    height: 14px;
    font-size: ${props => props.theme.typography.fontSize}px;
  }
`;

const MenuItemText = styled.span`
  &&&& {
    padding-left: 13px;
    font-size: ${props => props.theme.typography.fontSize}px;
  }
`;

const ReadOnlyDropdowns = styled(Grid)`
  &&&& {
    display: flex;
  }
`;

const StyledLoading = styled(Loading)`
  &&&& {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 1000;
  }
`;

const StyledGroupByAutoComplete = styled(GroupByAutocomplete)`
  padding: 10px 10px 0 0;
`;

const StyledScenarioColorsGrid = styled(Grid)`
  justify-content: center;

  & > div {
    padding-bottom: 0;
  }
`;
