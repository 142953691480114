import { toast } from 'react-toastify';

import { getDefaultErrorMessage } from './errorMessages';

const handleUpdateMetricError = err => {
  toast(getDefaultErrorMessage());

  throw err;
};

export default handleUpdateMetricError;
