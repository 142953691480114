import React, { useMemo } from 'react';
import styled from 'styled-components';

import { CSM_USER } from '@dragonboat/permissions';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import ApiTokens from './ApiTokens';
import PageTitle from 'design-system/atoms/PageTitle/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';
import HelpIconTooltip from 'design-system/molecules/HelpIconTooltip/index';

import checkIfUserIsAdmin from 'utils/checkIfUserIsAdmin';
import useOrganizations from 'src/hooks/useOrganizations.js';
import onlyVisibleForUsers from 'utils/onlyVisibleForUsers';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/login/selectors';
import FeatureFlagsList from './components/FeatureFlagsList';
import DeleteOrganization from './components/DeleteOrganization';
import UpdateProjectsHierarchyFromExternalKey from './components/UpdateProjectsHierarchyFromExternalKey';
import Rename from '../Base/Components/Rename/Rename';

import usePermissions from 'hooks/permissions/usePermissions';
import { IDEAS_OVERVIEW_PAGE } from 'constants/filters';
import { PERMISSION_FEATURES, PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';
import useSystemFields from 'hooks/useSystemFields';

export default props => {
  const {
    hasAdvancedMetricReporting,
    hasBets,
    hasInitiatives,
    hasCustomerRequests,
    user,
    onboardingGuide,
    setOnboardingGuide,
    showConfigForReadOnly,
    updateReadOnlyHasPageConfiguration,
    updateHasAdvancedMetricsReporting,
    updateHasHeadlessShareEnabled,
    hasHeadlessShareEnabled,
    disableAdvancedMetricReporting,
    hasMetricChartEnabled = false,
    allowReadOnlyUsersToUpdateRequestFields,
    updateAllowReadOnlyUsersToUpdateRequestFields,
    allowReadOnlyUsersToViewPortfolioOverview,
    updateAllowReadOnlyUsersToViewPortfolioOverview,
    hasPersonas,
    updateHasPersonas,
    hasLifecycles,
    updateHasLifecycles,
    hasIdeaAutoHealth,
    hasInitiativeAutoHealth,
    hasBetAutoHealth,
    updateIdeaHasAutoHealth,
    updateInitiativeHasAutoHealth,
    updateBetHasAutoHealth,
    shouldDisplayUpdateProjectsHierarchyFromExternalKey,
  } = props;

  const [getSystemFieldName] = useSystemFields();
  const currentUser = useSelector(getCurrentUser);
  const userIsAdmin = checkIfUserIsAdmin(currentUser);
  const featureFlagIsOn = useOrganizations().hasExternalApiKeys;
  const shouldDisplayAPIKeysSection = userIsAdmin && featureFlagIsOn;
  const shouldDisplayOrgFeatureFlagsSection = useMemo(
    () => user.isSuperAdmin || onlyVisibleForUsers(CSM_USER)(user.role_id),
    [user.isSuperAdmin, user.role_id],
  );
  const shouldDisplayDeleteOrganizationButton = user.isSuperAdmin;
  const permissions = usePermissions();

  const rows = [
    hasCustomerRequests ? 'request' : null,
    hasBets ? 'bet' : null,
    hasInitiatives ? 'initiative' : null,
    'idea',
  ].filter(Boolean);

  return (
    <Container container direction="column">
      <SuperloginActionsWrapper>
        {shouldDisplayDeleteOrganizationButton ? <DeleteOrganization user={user} /> : null}
        {shouldDisplayUpdateProjectsHierarchyFromExternalKey ? <UpdateProjectsHierarchyFromExternalKey user={user} /> : null}
      </SuperloginActionsWrapper>
      <StyledMicroTable>
        <StyledText>Internal onboarding guide</StyledText>
        <RelativeDiv>
          <FluidRemirrorWYSIWYGEditor
            key="onboarding_guide"
            value={onboardingGuide}
            content={onboardingGuide}
            onChange={setOnboardingGuide}
            style={{ minHeight: '34px' }}
            disableEdit={!userIsAdmin}
            smallInput
            disableToolbar
          />
        </RelativeDiv>
      </StyledMicroTable>
      <Rename rows={rows} />
      <Grid item>
        <ApiKeysContainer>
          {shouldDisplayAPIKeysSection && (
            <>
              <PageTitle>API tokens</PageTitle>
              <ApiTokens />
            </>
          )}
        </ApiKeysContainer>
      </Grid>
      <Grid item>
        <TextDeprecated variant="h6">Customize feature availability</TextDeprecated>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          label="Hide page configuration for read only users"
          control={
            <Switch
              checked={!showConfigForReadOnly}
              onChange={() => updateReadOnlyHasPageConfiguration(!showConfigForReadOnly)}
            />
          }
        />
      </Grid>
      {permissions.canView(PERMISSION_FEATURES.defaultsForAutoHealth) ? (
        <>
          <Grid item xs={12}>
            <FormControlLabel
              id="hasIdeaAutoHealth"
              label={`Default to ${getSystemFieldName('idea')} Auto Health`}
              control={<Switch checked={hasIdeaAutoHealth} onChange={() => updateIdeaHasAutoHealth(!hasIdeaAutoHealth)} />}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              id="hasInitiativeAutoHealth"
              label={`Default to ${getSystemFieldName('initiative')} Auto Health`}
              control={
                <Switch
                  checked={hasInitiativeAutoHealth}
                  onChange={() => updateInitiativeHasAutoHealth(!hasInitiativeAutoHealth)}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              id="hasBetAutoHealth"
              label={`Default to ${getSystemFieldName('bet')} Auto Health`}
              control={<Switch checked={hasBetAutoHealth} onChange={() => updateBetHasAutoHealth(!hasBetAutoHealth)} />}
            />
          </Grid>
        </>
      ) : null}
      {hasMetricChartEnabled ? (
        <Grid item xs={12}>
          <FormControlLabel
            id="metric__history_chart"
            data-testid="toggle-metric-chart"
            label="Turn on metric history chart"
            control={
              <Switch
                disabled={disableAdvancedMetricReporting}
                checked={hasAdvancedMetricReporting}
                onChange={() => updateHasAdvancedMetricsReporting(!hasAdvancedMetricReporting)}
              />
            }
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <FormControlLabel
          id="toggle-headless-share"
          label="Turn on share without login"
          control={
            <Switch checked={hasHeadlessShareEnabled} onChange={() => updateHasHeadlessShareEnabled(!hasHeadlessShareEnabled)} />
          }
        />
      </Grid>
      {userIsAdmin ? (
        <Grid item xs={12}>
          <FormControlLabel
            id="readonly_update_requests"
            label="Allow Read Only users to update custom fields for their own requests"
            control={
              <Switch
                checked={allowReadOnlyUsersToUpdateRequestFields}
                onChange={() => updateAllowReadOnlyUsersToUpdateRequestFields(!allowReadOnlyUsersToUpdateRequestFields)}
              />
            }
          />
          <HelpIconTooltip label="This toggle enables Read Only users to update custom fields on requests they have created" />
        </Grid>
      ) : null}
      {permissions.canView(PERMISSION_PAGES[IDEAS_OVERVIEW_PAGE]) ? (
        <Grid item xs={12}>
          <FormControlLabel
            id="readonly_view_product_hub"
            label="Allow Read Only users to view Portfolio Overview page"
            control={
              <Switch
                checked={allowReadOnlyUsersToViewPortfolioOverview}
                onChange={() => updateAllowReadOnlyUsersToViewPortfolioOverview(!allowReadOnlyUsersToViewPortfolioOverview)}
              />
            }
          />
        </Grid>
      ) : null}
      {permissions.canView(PERMISSION_FEATURES.updateOrgFlagPersonas) ? (
        <Grid item xs={12}>
          <FormControlLabel
            id="hasPersonas"
            label="Enable Personas"
            control={<Switch checked={hasPersonas} onChange={() => updateHasPersonas(!hasPersonas)} />}
          />
        </Grid>
      ) : null}
      {userIsAdmin && permissions.canView(PERMISSION_FEATURES.updateOrgFlagLifecycles) ? (
        <Grid item xs={12}>
          <FormControlLabel
            id="hasLifecycles"
            label="Enable Lifecycles"
            control={<Switch checked={hasLifecycles} onChange={() => updateHasLifecycles(!hasLifecycles)} />}
          />
        </Grid>
      ) : null}
      {shouldDisplayOrgFeatureFlagsSection ? <FeatureFlagsList /> : null}
    </Container>
  );
};

const Container = styled(Grid)`
  && {
    padding: 52px 32px 32px;
    height: calc(100vh - 68px);
    overflow-y: auto;
    display: block;
  }
`;

const ApiKeysContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  margin-bottom: 24px;
`;

const StyledMicroTable = styled.div`
  display: flex;
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-bottom: 24px;
  width: 800px;
`;

const StyledText = styled(TextDeprecated)`
  &&&& {
    max-width: 400px;
    font-size: 12px;
    line-height: 24px;
    padding: 4px 8px 4px 12px;
    position: relative;
    flex: 1 1 0%;
    display: flex;
    align-items: center;
    &:after {
      content: ' ';
      position: absolute;
      right: 0;
      bottom: 0;
      top: 12px;
      display: block;
      border-right: 1px solid #e0e0e0;
    }
  }
`;

const RelativeDiv = styled.div`
  flex: 1 1 0%;
  font-size: 12px;
  position: relative;
  min-height: 48px;
`;

const SuperloginActionsWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
`;
