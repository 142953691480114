import React from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import DOMPurify from 'dompurify';
import ReactHtmlParser from 'react-html-parser';
import { isNil, not, pipe } from 'ramda';
import Editor from 'draft-js-plugins-editor';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { toast } from 'react-toastify';

import formatDate from 'utils/dates/formatDate';
import useCreateMentionPlugin from 'hooks/useCreateMentionPlugin';
import Text from 'design-system/atoms/Text';

import useDraftJsEditor from '../../hooks/useDraftJsEditor';
import useSearchUsers from '../../hooks/useSearchUsers';
import getHtmlFromDraftjsEditorState from '../../helpers/getHtmlFromDraftjsEditorState';
import CommentActions from '../CommentActions';
import EditorContainer from '../EditorContainer';
import UserMention from '../UserMention';

const isNotNil = pipe(isNil, not);

const CommentItem = ({ comment, classes, onEdit, onDelete, editable, onSave, onCancelEdit, disabledEdit }) => {
  const { plugins, MentionSuggestions } = useCreateMentionPlugin();
  const { suggestions, onSearchUsers } = useSearchUsers();
  const {
    editorState,
    handleKeyCommand,
    onChangeEditorState,
    onEditorClick,
    onSetEditorRef,
    getEditorPayload,
    resetEditorState,
  } = useDraftJsEditor(comment.draftjsEditorState);

  const commentValue = getEditorPayload();

  const onSaveHandler = async () => {
    try {
      const res = await onSave(comment.id, commentValue);

      return res;
    } catch (error) {
      resetEditorState();
      toast('Error on edit comment');
      console.error('Error on edit comment', error);
    }
  };

  const hasActions = isNotNil(onEdit) || isNotNil(onDelete);

  const onEditHandler = () => {
    return onEdit(comment);
  };

  const onDeleteHandler = () => {
    return onDelete(comment.id);
  };

  const onCancelEditHandler = () => {
    onCancelEdit();

    resetEditorState();
  };

  return (
    <>
      <CommentContainer className={classes.item}>
        <Text>
          {comment.createdBy && (
            <span className={classes.relevant}>
              {comment.createdBy.first_name} {comment.createdBy.last_name || ''}
            </span>
          )}
          <span className={[classes.date]}>
            {moment().diff(comment.created_at, 'days') === 0 ? 'today' : formatDate(comment.created_at)}
          </span>
        </Text>
        <Grid container>
          <Grid item xs={12}>
            {!editable && (
              <Text className={classes.secondaryText}>
                {ReactHtmlParser(
                  DOMPurify.sanitize(getHtmlFromDraftjsEditorState(comment.draftjsEditorState, comment.mentions, comment.text)),
                )}
              </Text>
            )}
            {editable && (
              <EditorContainer onClick={onEditorClick} minHeight={10}>
                <Editor
                  editorState={editorState}
                  onChange={onChangeEditorState}
                  handleKeyCommand={handleKeyCommand}
                  plugins={plugins}
                  ref={onSetEditorRef}
                  spellCheck
                  readOnly={disabledEdit}
                />
                <MentionSuggestions onSearchChange={onSearchUsers} suggestions={suggestions} entryComponent={UserMention} />
              </EditorContainer>
            )}
          </Grid>
          {hasActions && (
            <Grid item xs={12}>
              <CommentActions
                commentCreatorId={comment.user_id}
                onEdit={onEditHandler}
                onDelete={onDeleteHandler}
                editMode={editable}
                onSave={onSaveHandler}
                onCancelEdit={onCancelEditHandler}
                disabledState={{
                  save: disabledEdit || !commentValue.plainText || commentValue.plainText === comment.text,
                  cancel: disabledEdit,
                }}
              />
            </Grid>
          )}
        </Grid>
      </CommentContainer>
    </>
  );
};

const CommentContainer = styled.li`
  width: 100%;
  .mention {
    color: ${props => props.theme.palette.primary.main};
  }
`;

const styles = theme => ({
  item: {
    paddingBottom: 20,
  },
  secondaryText: {
    fontSize: 13,
    paddingTop: 5,
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
  date: {
    marginLeft: 5,
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  none: {
    color: theme.palette.text.secondary,
  },
  relevant: {
    fontWeight: 500,
  },
  arrow: {
    height: '0.5em',
    width: '0.5em',
    marginLeft: 5,
    marginRight: 5,
  },
});

export default withStyles(styles)(CommentItem);
