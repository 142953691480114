import reduceReducers from 'reduce-reducers';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import { buildReleaseCapacityAllocationKey } from 'utils/releaseCapacityAllocation';

import {
  FETCH_ALL_RELEASE_CAPACITY_ALLOCATION,
  UPSERT_RELEASE_CAPACITY_ALLOCATION,
  UPSERT_RELEASE_CAPACITY_ALLOCATION_FULFILLED,
  UPSERT_RELEASE_CAPACITY_ALLOCATION_PENDING,
  UPSERT_RELEASE_CAPACITY_ALLOCATION_REJECTED,
} from '../types';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  FETCH_ALL_RELEASE_CAPACITY_ALLOCATION,
  UPSERT_RELEASE_CAPACITY_ALLOCATION,
]);

const initialState = {
  ...thunksInitialState,
  loadingByKey: {},
};

// eslint-disable-next-line camelcase
const getKeyFromActionMeta = ({ timeframeId, metadataField, metadataId }) =>
  buildReleaseCapacityAllocationKey(timeframeId, metadataField, metadataId);

const operationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPSERT_RELEASE_CAPACITY_ALLOCATION_PENDING: {
      const key = getKeyFromActionMeta(action.meta);

      return {
        ...state,
        loadingByKey: {
          ...state.loadingByKey,
          [key]: true,
        },
      };
    }
    case UPSERT_RELEASE_CAPACITY_ALLOCATION_FULFILLED:
    case UPSERT_RELEASE_CAPACITY_ALLOCATION_REJECTED: {
      const key = getKeyFromActionMeta(action.meta);

      return {
        ...state,
        loadingByKey: {
          ...state.loadingByKey,
          [key]: false,
        },
      };
    }
    default:
      return state;
  }
};

const reducer = reduceReducers(initialState, operationsReducer, ...thunksReducers);

export { initialState };

export default reducer;
