import sortByRank from './sortByRank';

export default function (a, b) {
  // some enities were migrated to rank order
  if (a.rank && b.rank) {
    return sortByRank(a, b);
  }

  if (a.row_order !== b.row_order) {
    return a.row_order > b.row_order ? -1 : 1;
  }
  return 0;
}
