import { useState } from 'react';

import useSinglePurposeSocket from 'hooks/useSinglePurposeSocket';

const useBulkDeleteWithProgress = (selectedItems, bulkDeleteAction, disableSelectionMode) => {
  const socket = useSinglePurposeSocket();

  const [deleteProgress, setDeleteProgress] = useState(0);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [showConfirmBulkDelete, setShowConfirmBulkDelete] = useState(false);

  const bulkDelete = async customItemsIds => {
    const roomId = socket.join();

    setDeleteProgress(0);

    socket.subscribe(({ progress }) => {
      setDeleteProgress(previousProgress => (previousProgress < progress ? progress : previousProgress));
    });

    setDeleteIsLoading(true);
    setShowConfirmBulkDelete(false);

    await bulkDeleteAction(customItemsIds || selectedItems.map(i => i.id), roomId, selectedItems);

    socket.unsubscribe(roomId);
    setDeleteIsLoading(false);
    setDeleteProgress(100);
    disableSelectionMode();
  };

  return [bulkDelete, deleteProgress, deleteIsLoading, showConfirmBulkDelete, setShowConfirmBulkDelete];
};

export default useBulkDeleteWithProgress;
