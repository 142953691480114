import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import RemoveIcon from '@material-ui/icons/LinkOff';
import EditIcon from '@material-ui/icons/Edit';
import { either, isNil, isEmpty } from 'ramda';

import FluidFieldLabel from 'design-system/molecules/FluidFieldLabel/index';
import InsightsIcon from 'design-system/atoms/InsightsIcon/index';

import FluidDragonTableField from 'design-system/organisms/FluidDragonTableField';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import MetricChangeValuesPopover from './components/MetricChangeValuesPopover/MetricChangeValuesPopover';
import MetricAutocomplete from './components/MetricAutocomplete';

import { getMetricById } from 'store/metrics/selectors';

const isNilOrEmpty = either(isNil, isEmpty);

const EDITOR_MIN_WIDTH = 160;
const EDITOR_WIDTH_OFFSET = 130;

const MetricsForm = ({
  selectedEntity,
  objectMetrics,
  onLabelClick,
  labelTooltip,
  canAddOrEdit,
  toggleMetricsDialog,
  metrics,
  metricsOptions,
  onAddMetric,
  onRemoveMetric,
  onUpdateMetric,
  getSystemFieldName,
}) => {
  const shouldUseMetricsDialog = !!toggleMetricsDialog;

  const { canView } = usePermissions();
  const hasNewMetadataAutocompleteForMetrics = canView(PERMISSION_FEATURES.newRoadmapMetadataAutocompleteOnMetricsForm);

  const tableRef = useRef(null);
  const circleFieldRef = useRef(null);

  const [editMetricAnchorEl, setEditMetricAnchorEl] = useState(null);
  const [metricRowData, setMetricRowData] = useState(null);
  const currentMetric = useSelector(state => getMetricById(state, metricRowData?.id));

  const { width: tableWidth = 0 } = tableRef?.current?.getBoundingClientRect?.() || {};

  const metricEditorWidth = Math.max(EDITOR_MIN_WIDTH, tableWidth - EDITOR_WIDTH_OFFSET);

  const handleAddMetric = metricName => {
    if (isNilOrEmpty(metricName)) {
      return;
    }

    const metric = metrics.find(m => m?.name === metricName);

    if (isNil(metric)) {
      return;
    }

    onAddMetric(metric?.id);
  };

  const handleRemoveMetric = metricId => {
    if (isNilOrEmpty(metricId)) {
      return;
    }

    onRemoveMetric(metricId);
  };

  const handleOnClickEditButton = (row, currentTarget) => {
    setMetricRowData(row);
    setEditMetricAnchorEl(currentTarget);
  };

  const handleUpdateMetricHealth = (metric, value) => {
    if (isNilOrEmpty(metric)) {
      return;
    }
    onUpdateMetric(metric.id, { status_color: value });
  };

  // If hasNewMetadataAutocompleteForMetrics we use the new metadata Autocomplete
  // otherwise we'll use the default editor from the Table
  const renderMetricEditor = hasNewMetadataAutocompleteForMetrics
    ? editorProps => (
      <MetricAutocomplete {...editorProps} selectedEntity={selectedEntity} getSystemFieldName={getSystemFieldName} />
      )
    : null;

  return (
    <>
      <FluidDragonTableField
        title={<FluidFieldLabel label="Metric(s)" onLabelClick={onLabelClick} labelTooltip={labelTooltip} />}
        value={objectMetrics || []}
        newRowButtonTitle="Add or Link Metric"
        allowNewRow={canAddOrEdit}
        hideTableIfEmpty
        overflowAfter={6}
        tableRef={tableRef}
      >
        <FluidDragonTableField.CircleColumn
          ref={circleFieldRef}
          anchorEl={circleFieldRef}
          map={r => r.status_color}
          width={40}
          editable={canAddOrEdit}
          onChange={(row, value) => handleUpdateMetricHealth(row, value)}
        />
        <FluidDragonTableField.TextColumn
          main
          options={metricsOptions}
          map={r => r.name}
          header="Title"
          onChange={(row, value) => handleAddMetric(value)}
          flexGrow={1.0}
          width={80}
          editorWidth={metricEditorWidth}
          editorComponent={renderMetricEditor}
        />
        <FluidDragonTableField.ButtonColumn
          map={row => row}
          icon={InsightsIcon}
          onClickButton={(row, e) => toggleMetricsDialog(e, row.id)}
          width={26}
          disableSort
          hidden={!shouldUseMetricsDialog}
        />
        <FluidDragonTableField.ButtonColumn
          map={row => row}
          icon={EditIcon}
          onClickButton={(row, e) => handleOnClickEditButton(row, e.currentTarget)}
          width={26}
          disableSort
          disabled={!canAddOrEdit}
        />
        <FluidDragonTableField.ButtonColumn
          map={row => row}
          icon={RemoveIcon}
          onClickButton={row => handleRemoveMetric(row.id)}
          width={26}
          disableSort
          disabled={!canAddOrEdit}
        />
      </FluidDragonTableField>
      {metricRowData && (
        <MetricChangeValuesPopover
          anchorEl={editMetricAnchorEl}
          setAnchorEl={el => setEditMetricAnchorEl(el)}
          metric={currentMetric}
          canAddOrEdit={canAddOrEdit}
        />
      )}
    </>
  );
};

export default MetricsForm;
