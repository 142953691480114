import React, { useCallback, useMemo } from 'react';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { defaultTo, not } from 'ramda';

import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import MuiSaveIcon from '@material-ui/icons/Save';
import Radio from '@material-ui/core/Radio';
import MenuListItem from '@material-ui/core/MenuItem';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';

import { CHILDREN_FILTERS, FILTER_CHILDREN_FIELDS_PROPERTY, FILTERS_FUNCTIONS } from 'constants/filters';
import AddLayerToSearch from 'containers/AddLayerToSearch';
import AddFilterPopper from 'design-system/organisms/AddFilterPopper/index';
import SavedFiltersPopper from 'design-system/organisms/SavedFiltersPopper/index';
import SaveFilterDialog from 'design-system/organisms/SaveFilterDialog/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import FilterCondition from 'design-system/molecules/FilterCondition/index';

import useDeepEffect from 'hooks/useDeepEffect';
import copyToClipboard from 'utils/copyToClipboard';
import normalizeArray from 'utils/normalizeArray';
import { buildFilterLink } from 'utils/filters/buildFilterLink';
import getHighestLayer from 'utils/filters/getHighestLayer';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';
import AddMultiLayerToSearch from 'containers/AddMultiLayerToSearch';
import { removeQueryParamFromUrl } from 'utils/queryParamsUtils';
import { ADD_FILTER_TITLE, OPEN_ADVANCED_SEARCH } from 'constants/queryParams';

import getFieldFunctions from './helpers/getFieldFunctions';

const DISPLAY_ALL_SUBITEMS = 'Display all sub-items';
const DISPLAY_DOD_CORP_SUBITEMS = 'Display all sub-items across {corpName}';
const DISPLAY_ONLY_SUBITEMS_IN_THESE_CONDITIONS = 'Display only sub-items in these conditions';
const DONT_DISPLAY_SUBITEMS = "Don't display sub-items";

const isMultiLayer = l => Array.isArray(l);

const addTitleFiltersFrom = (filters = {}, value) => {
  filters.fields = {
    ...(filters?.fields || {}),
    title: value,
    op: {
      ...(filters?.fields?.op || {}),
      title: FILTERS_FUNCTIONS.contains.key,
    },
  };
};

export default ({
  anchorEl,
  fields,
  setAnchorEl,
  getFieldOptions,
  pageFilters,
  applyFilters,
  userFilters,
  staticFilters,
  onSaveFilter,
  onDeleteFilter,
  onCreateFilter,
  loadUsers,
  filterToEdit,
  quickFilters,
  onSavedFilterClick,
  page,
  layerOptions,
  layers,
  bottomLayer,
  topLayer,
  globalSearch,
  doSearch,
  setFilterToEdit,
  anchorOrigin,
  transformOrigin,
  hasCopyFilterLink,
  selectLayerProps = {},
  userCanEditFilter = true,
  hasCrossAccountFiltering = false,
  corpAccountName,
  enableMultiLayerSearch = false,
  canViewDbqlAdvancedSearch = false,
  setIsUsingDbqlAdvancedSearch,
}) => {
  const [addFilterAnchorEl, setAddFilterAnchorEl] = React.useState(null);
  const [savedFiltersAnchorEl, setSavedFiltersAnchorEl] = React.useState(null);

  const [editableItem, setEditableItem] = React.useState(null);

  const [filters, setFilters] = React.useState(pageFilters);

  // this allows to wait for filters to load before displaying them
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);

  const [addingOn, setAddingOn] = React.useState('fields');
  const [showSaveFilterDialog, setShowSaveFilterDialog] = React.useState(false);
  const [saveCurrentFilter, setSaveCurrentFilter] = React.useState(false);
  const addFilterPopperRef = React.useRef(null);
  const savedFiltersPopperRef = React.useRef(null);
  const normalizedFields = normalizeArray(fields, 'id');
  const [searchValue, setSearchValue] = React.useState(globalSearch);

  const actionRef = React.useRef();

  // TODO: if some quick filter is applied maybe we should not allow multi layer search?
  const hasMultiLayerSearch = enableMultiLayerSearch && useFeatureFlags([FeatureFlags.HAS_MULTI_LAYER_SEARCH]);

  const isMultiLayerFilterSelected = hasMultiLayerSearch && isMultiLayer(filters.layer);

  const currentLayer = useMemo(() => {
    const isCurrentFiltersLayerMultiLayer = isMultiLayer(filters.layer);

    /*
     * if searched layer is an array should use hightest layer as current layer
     * and only if multi layer search is not active
     */
    const shouUseHighestLayerAsCurrentLayer = isCurrentFiltersLayerMultiLayer && not(hasMultiLayerSearch);

    if (shouUseHighestLayerAsCurrentLayer) {
      return getHighestLayer(filters.layer);
    }

    return filters.layer || topLayer;
  }, [filters.layer, topLayer, hasMultiLayerSearch]);

  const _getFiltersList = filter => {
    return Object.keys(filter || {}).filter(f => normalizedFields[f]);
  };

  const getFilterConditionFields = useCallback(
    filterProperty => {
      const isChildrenFilters = filterProperty === FILTER_CHILDREN_FIELDS_PROPERTY;

      if (!isChildrenFilters || !hasCrossAccountFiltering) {
        return fields;
      }

      return fields.map(field => {
        switch (field.labelKey) {
          case 'roadmap': {
            return {
              ...field,
              hasGroups: true,
            };
          }
          default:
            return field;
        }
      });
    },
    [fields, hasCrossAccountFiltering],
  );

  const _onAddNewFilter = fieldsAttr => e => {
    setAddingOn(fieldsAttr);
    setAddFilterAnchorEl(addFilterAnchorEl ? null : e.currentTarget);
  };
  const _onShowSavedFilters = e => setSavedFiltersAnchorEl(savedFiltersAnchorEl ? null : e.currentTarget);
  const _handleAddNewFilterClick = field => {
    if (Object.keys(filters[addingOn] || {}).some(k => k === field.id)) return;
    const fieldFunctions = getFieldFunctions(normalizedFields[field.id]);
    const op = fieldFunctions.length && fieldFunctions[0].key;

    const newFilters = cloneDeep(filters);

    newFilters[addingOn] = {
      ...(newFilters[addingOn] || {}),
      [field.id]: defaultTo(null, field?.defaultInitialValue),
      op: {
        ...(newFilters[addingOn]?.op || {}),
        [field.id]: op,
      },
    };

    setFilters(newFilters);

    setAddFilterAnchorEl(null);

    if (actionRef.current) actionRef.current.updatePosition();
    // if (Object.keys(_getFiltersList(filters[addingOnIndex].fields)).length === 0) setForceToMount(true);
  };

  const _handleRemoveFilter = index => field => {
    const newFilters = cloneDeep(filters);

    let showArchived = newFilters.showArchived || [];

    if (field.showArchived) {
      showArchived = showArchived.filter(f => f !== field.field);
    }

    newFilters[index] = {
      ...omit(newFilters[index], field.field),
      op: {
        ...omit(newFilters[index].op || {}, field.field),
      },
    };

    setFilters({
      ...newFilters,
      showArchived,
    });
  };
  const _handleOnChangeFilter = changingOn => (filter, param, value) => {
    const filtersUpdate = cloneDeep(filters);

    if (param === 'field') {
      delete filtersUpdate[changingOn][filter];
      filtersUpdate[changingOn][value] = null;
    } else if (param === 'value') {
      filtersUpdate[changingOn][filter] = value;
    } else if (param === 'showArchived') {
      setFilters({
        ...filtersUpdate,
        showArchived:
          value === true ? [...(filtersUpdate.showArchived || []), filter] : filtersUpdate.showArchived.filter(f => f !== filter),
      });
      return;
    } else if (param === 'op') {
      filtersUpdate[changingOn].op = {
        ...(filtersUpdate[changingOn].op || {}),
        [filter]: value,
      };

      // if we change operation keep previous value is exists otherwise reset to defaultInitialValue
      // last fallback will be null (default for filter mechanism)
      if (!filtersUpdate?.fields?.[filter]) {
        const field = fields.find(field => field.id === filter);

        filtersUpdate.fields = {
          ...filtersUpdate.fields,
          [filter]: defaultTo(null, field?.defaultInitialValue),
        };
      }

      setFilters(filtersUpdate);
      return;
    }

    setFilters(filtersUpdate);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    removeQueryParamFromUrl(OPEN_ADVANCED_SEARCH);
    removeQueryParamFromUrl(ADD_FILTER_TITLE);
  };

  const _onCancel = () => {
    setFilters(pageFilters);
    handleClosePopover();
  };
  const _onApply = () => {
    const filterState = filterToEdit ? filterToEdit.state : {};
    const filterId = filterToEdit && isEqual(filterState, filters) ? filterToEdit.id : null;
    let { layer } = filters;

    if (isMultiLayerFilterSelected) {
      // if doing the flat search, need to ensure the layers aren't empty
      layer = filters.layer && Array.isArray(filters.layer) && filters.layer.length ? filters.layer : layers;
    }

    applyFilters({ ...filters, layer, dbql: null }, filterId);
    doSearch(searchValue || '');
    handleClosePopover();
  };
  const _onClickAway = e => {
    _onCancel();
  };
  const _onClickAwayAddFilterPopper = e => {
    if (e.composedPath().includes(addFilterAnchorEl)) return;

    setAddFilterAnchorEl(null);
  };
  const _onClickAwaySavedFiltersPopper = e => {
    if (e.composedPath().includes(savedFiltersAnchorEl)) return;

    setSavedFiltersAnchorEl(null);
  };
  const _onClickSaveEditedFilter = () => {
    setSaveCurrentFilter(true);
    setShowSaveFilterDialog(true);
  };
  const _renderFilterCondition = attr => f => {
    const currentFunction = filters?.[attr]?.op?.[f];
    const functions = getFieldFunctions(normalizedFields[f], currentFunction);

    const data = {
      value: filters[attr][f],
      field: f,
      op: currentFunction || (functions && functions[0].key),
      showArchived: filters.showArchived && filters.showArchived.includes(f),
    };

    return (
      <FilterCondition
        {...normalizedFields[f]}
        key={f}
        fields={getFilterConditionFields(attr)}
        data={data}
        options={getFieldOptions ? getFieldOptions(f, data.showArchived, attr) : []}
        functions={functions}
        onChange={_handleOnChangeFilter(attr)}
        onRemove={_handleRemoveFilter(attr)}
      />
    );
  };
  const _onDeleteCurrentFilter = () => {
    onDeleteFilter(filterToEdit.id);
    _onCancel();
  };

  const _updateUsersOptions = async (filter, field) => {
    if (!pageFilters[filter][field] || !anchorEl || !loadUsers) return null;

    const users = await loadUsers(pageFilters[filter][field].map(u => (u.id ? u.id : u)));

    return users;
  };

  const _onSelectLayer = layer => {
    setFilters({ ...filters, layer });
  };

  const _onSelectSecondLayer = layer => {
    setFilters({ ...filters, secondLayer: layer });
  };

  const _onSelectChildren = (childrenValue, otherOpts = {}) => {
    setFilters({
      ...filters,
      allCorpSubItems: otherOpts.allCorpSubItems,
      children: childrenValue,
    });
  };

  React.useEffect(() => {
    if (addFilterAnchorEl) setAddFilterAnchorEl(null);
    if (savedFiltersAnchorEl) setSavedFiltersAnchorEl(null);

    // closed the popup, set filters as not loaded
    if (!anchorEl) setFiltersLoaded(false);
  }, [!anchorEl]);

  const urlParams = new URLSearchParams(window.location.search);
  const openAdvancedSearchParam = urlParams.get(OPEN_ADVANCED_SEARCH);
  const addFilterTitle = urlParams.get(ADD_FILTER_TITLE);

  useDeepEffect(() => {
    const _updateFilters = async () => {
      const newFilters = { ...pageFilters };
      const usersFilter = await _updateUsersOptions('fields', 'users');
      const createdByFilter = await _updateUsersOptions('fields', 'created_by');

      if (usersFilter) newFilters.fields.users = usersFilter;
      if (createdByFilter) newFilters.fields.created_by = createdByFilter;

      const childrenUsersFilter = await _updateUsersOptions('childrenFields', 'users');
      const childrenCreatedByFilter = await _updateUsersOptions('childrenFields', 'created_by');

      if (childrenUsersFilter) newFilters.childrenFields.users = childrenUsersFilter;
      if (childrenCreatedByFilter) newFilters.childrenFields.created_by = childrenCreatedByFilter;

      if (openAdvancedSearchParam && addFilterTitle) {
        addTitleFiltersFrom(newFilters, addFilterTitle);
      }

      setFilters(newFilters);
    };

    _updateFilters();

    const isVisible = !!anchorEl;

    if (isVisible) {
      // if is visible and went through this, filter is ready to be loaded
      // make sure it runs after when the filter is actually loaded to ensure
      // any transformation applied afterwards is not overwritten
      setTimeout(() => setFiltersLoaded(true), 0);
    }
  }, [pageFilters, !anchorEl, openAdvancedSearchParam]);

  React.useEffect(() => {
    setSearchValue(globalSearch);
  }, [globalSearch]);

  if (!anchorEl) return '';

  const _onEditFilter = filter => {
    setFilterToEdit(filter);
    setSavedFiltersAnchorEl(null);
  };

  const _onCopyFilterLink = filter => {
    _onCancel();

    /**
     * The setTimeout is required because the hidden textarea used to select and
     * copy the text to the clipboard (check copyToClipboard function) seems to
     * have a conflit with the Popover used to show the saved filters sub-menu.
     * For that reason it is required to:
     *  - call popover close (_onCancel)
     *  - wrap copyToClipboard with setTimeout to use the next render cycle
     */
    setTimeout(async () => {
      const link = buildFilterLink(filter.key);

      await copyToClipboard(link);
    }, 1);
  };

  const renderSavedFiltersSubMenu = item => {
    return (
      <>
        <ListItem onClick={() => setEditableItem(item)}>
          <ListItemText>Rename</ListItemText>
        </ListItem>
        <ListItem onClick={() => _onEditFilter(item)}>
          <ListItemText>Edit</ListItemText>
        </ListItem>
        <ListItem onClick={() => onDeleteFilter(item.id)}>
          <ListItemText>Delete</ListItemText>
        </ListItem>
        {hasCopyFilterLink && (
          <ListItem onClick={() => _onCopyFilterLink(item)}>
            <ListItemText>Copy filter link</ListItemText>
            <MuiListItemIcon>
              <ShareIcon fontSize="small" />
            </MuiListItemIcon>
          </ListItem>
        )}
      </>
    );
  };

  const rendertLayerSelect = () =>
    hasMultiLayerSearch ? (
      <AddFirstLayerWithMultiLayerSearch
        disabled={selectLayerProps.disabled}
        layer={currentLayer}
        bottomLayer={bottomLayer}
        secondLayer={filters.secondLayer}
        options={layerOptions}
        onSelectLayer={_onSelectLayer}
        onSelectSecondLayer={_onSelectSecondLayer}
      />
    ) : (
      <AddFirstLayer
        disabled={selectLayerProps.disabled}
        layer={currentLayer}
        bottomLayer={bottomLayer}
        secondLayer={filters.secondLayer}
        options={layerOptions}
        onSelectLayer={_onSelectLayer}
        onSelectSecondLayer={_onSelectSecondLayer}
      />
    );

  const renderFilterConditions = fieldsKey => (
    <>
      {!!_getFiltersList(filters[fieldsKey]).length && (
        <FiltersContainer>
          {_getFiltersList(filters[fieldsKey]).map(f => (
            <FilterDiv>{_renderFilterCondition(fieldsKey)(f)}</FilterDiv>
          ))}
        </FiltersContainer>
      )}
      {userCanEditFilter && (
        <AddConditionContainer>
          <Button data-testid="advanced-search-add-condition" color="primary" onClick={_onAddNewFilter(fieldsKey)}>
            + Add Condition
          </Button>
        </AddConditionContainer>
      )}
    </>
  );

  const renderChildrenSelect = () => {
    const isCurrentLayerBottomLayer = filters.layer === bottomLayer;
    const shouldNotRenderChildrenSelect = isCurrentLayerBottomLayer || isMultiLayerFilterSelected;

    if (shouldNotRenderChildrenSelect) {
      return;
    }

    return (
      <Line>
        <Radio
          color="primary"
          checked={filters.children === CHILDREN_FILTERS.allChildren}
          onChange={() => _onSelectChildren(CHILDREN_FILTERS.allChildren)}
        />
        <TextDeprecated>{DISPLAY_ALL_SUBITEMS}</TextDeprecated>
        {hasCrossAccountFiltering && (
          <Radio
            color="primary"
            checked={filters.children === CHILDREN_FILTERS.filteredChildren && defaultTo(false, filters.allCorpSubItems)}
            onChange={() => _onSelectChildren(CHILDREN_FILTERS.filteredChildren, { allCorpSubItems: true })}
          />
        )}
        {hasCrossAccountFiltering && (
          <TextDeprecated>{DISPLAY_DOD_CORP_SUBITEMS.replace('{corpName}', corpAccountName)}</TextDeprecated>
        )}
        <Radio
          color="primary"
          checked={filters.children === CHILDREN_FILTERS.filteredChildren && !filters.allCorpSubItems}
          onChange={() => _onSelectChildren(CHILDREN_FILTERS.filteredChildren)}
        />
        <TextDeprecated>{DISPLAY_ONLY_SUBITEMS_IN_THESE_CONDITIONS}</TextDeprecated>
        <Radio
          color="primary"
          checked={filters.children === CHILDREN_FILTERS.noChildren}
          onChange={() => _onSelectChildren(CHILDREN_FILTERS.noChildren)}
        />
        <TextDeprecated>{DONT_DISPLAY_SUBITEMS}</TextDeprecated>
      </Line>
    );
  };

  const renderSavedFiltersPopper = () => (
    <SavedFiltersPopper
      anchorEl={savedFiltersAnchorEl}
      onClickAway={_onClickAwaySavedFiltersPopper}
      staticFilters={staticFilters}
      myFilters={userFilters}
      setRef={r => (savedFiltersPopperRef.current = r)}
      editableItem={editableItem}
      onSetEditableItem={setEditableItem}
      onSaveFilter={(id, name) => onSaveFilter(id, name)}
      onFilterClick={f => {
        onSavedFilterClick(f);
        setSavedFiltersAnchorEl(null);
        handleClosePopover();
      }}
      renderSubMenu={renderSavedFiltersSubMenu}
    />
  );

  const renderAddFilterPopper = () => (
    <AddFilterPopper
      anchorEl={addFilterAnchorEl}
      title="Add Filter"
      items={fields}
      onItemClick={_handleAddNewFilterClick}
      onClickAway={_onClickAwayAddFilterPopper}
      setRef={r => (addFilterPopperRef.current = r)}
    />
  );

  const renderSaveFilterDialog = () => (
    <SaveFilterDialog
      filterName={filterToEdit && filterToEdit.name}
      showDialog={showSaveFilterDialog}
      onClose={() => setShowSaveFilterDialog(false)}
      onSave={name => {
        if (filterToEdit && (name === filterToEdit.name || saveCurrentFilter)) {
          onSaveFilter(filterToEdit.id, name, filters);
          setSaveCurrentFilter(false);
        } else {
          onCreateFilter(name, filters);
        }
      }}
    />
  );

  const renderFooter = () => {
    const shouldShowSaveFilterButton = !!filters?.fields && userCanEditFilter && not(isMultiLayerFilterSelected);
    const shouldShowSavedFiltersButton =
      userCanEditFilter && ((userFilters && !!userFilters.length) || (staticFilters && !!staticFilters.length));

    return (
      <ButtonsContainer>
        {filterToEdit && (
          <Button color="secondary" onClick={_onDeleteCurrentFilter}>
            Delete this filter
          </Button>
        )}
        {shouldShowSavedFiltersButton && <Button onClick={_onShowSavedFilters}>Saved Filters</Button>}
        <ActionsButtonsContainer>
          {shouldShowSaveFilterButton && (
            <Button color="primary" onClick={() => setShowSaveFilterDialog(true)}>
              <SaveIcon />
              {filterToEdit ? 'Save' : 'Save active filter'}
            </Button>
          )}
          {userCanEditFilter && (
            <Button onClick={_onApply} color="primary">
              Apply
            </Button>
          )}
          <Button onClick={_onCancel}>Cancel</Button>
        </ActionsButtonsContainer>
      </ButtonsContainer>
    );
  };

  return (
    <React.Fragment>
      {filtersLoaded && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          action={r => (actionRef.current = r)}
          style={{ zIndex: 1000 }}
          onClose={_onClickAway}
          anchorOrigin={
            anchorOrigin || {
              vertical: 'bottom',
              horizontal: 'left',
            }
          }
          transformOrigin={
            transformOrigin || {
              vertical: 'top',
              horizontal: 'left',
            }
          }
        >
          <FiltersWrapper>
            {canViewDbqlAdvancedSearch ? (
              <DBQLButton color="primary" onClick={() => setIsUsingDbqlAdvancedSearch(true)}>
                New Advanced Search (BETA)
              </DBQLButton>
            ) : null}
            <FiltersHeader
              handleClickFilterName={() => (filterToEdit ? _onClickSaveEditedFilter() : null)}
              savedFilter={filterToEdit}
            >
              {filterToEdit ? `${filterToEdit.name}` : 'Active Filters'}
            </FiltersHeader>
            {rendertLayerSelect()}
            {renderFilterConditions('fields')}
            {renderChildrenSelect()}
            {filters.layer !== bottomLayer &&
              filters.children === CHILDREN_FILTERS.filteredChildren &&
              renderFilterConditions('childrenFields')}
            {renderFooter()}
            {renderSavedFiltersPopper()}
            {renderAddFilterPopper()}
            {renderSaveFilterDialog()}
          </FiltersWrapper>
        </Popover>
      )}
    </React.Fragment>
  );
};

const FiltersWrapper = styled(Paper)`
  &&&& {
    position: relative;
    min-width: 250px;
  }
`;

const AddFirstLayer = styled(AddLayerToSearch)`
  &&&&&& {
    width: 600px;
    padding: 0 20px 0px 20px;
  }
`;

const AddFirstLayerWithMultiLayerSearch = styled(AddMultiLayerToSearch)`
  &&&&&& {
    width: 600px;
    padding: 0 20px 0px 20px;
  }
`;

const AddConditionContainer = styled.div`
  padding: 0 16px 8px;

  button {
    text-transform: initial;
  }
`;

const Button = styled(MuiButton)``;

const SaveIcon = styled(MuiSaveIcon)`
  &&& {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px 10px;
  border-top: 1px solid #fafafa;
  box-shadow: 0px -2px 12px 0px rgb(204 204 204 / 30%);
`;

const ActionsButtonsContainer = styled.div`
  margin-left: auto;
  padding-left: 50px;
`;

const FiltersContainer = styled.div`
  padding: 12px 12px;
`;

const FiltersHeader = styled(props => (
  <div {...omit(props, ['children'])}>
    {props.savedFilter && <Typography variant="filtersTitle">Saved filter / &nbsp;</Typography>}
    <StyledTypography onClick={props.handleClickFilterName} savedFilter={props.savedFilter} variant="filtersTitle">
      {props.children}
    </StyledTypography>
  </div>
))`
  &&&& {
    display: flex;
    justify-content: left;
    flex-shrink: 0;
    padding: 20px 18px 10px;
    // text-transform: uppercase;
    white-space: nowrap;

    span {
      font-size: 1.1rem;
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    }

    span:first-child {
      color: ${({ theme }) => theme.palette.newLayout.text.labelDisabled};
    }
  }
`;

const StyledTypography = styled(Typography)`
  &&&& {
    ${props =>
      props.savedFilter &&
      `
        &:hover {
          cursor: pointer;
        }
     `}
  }
`;

const FilterDiv = styled.div`
  width: 100%;
  min-width: 650px;
`;

const Line = styled.div`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
    padding-right: 20px;
  }
`;

const ListItemText = styled(MuiListItemText)`
  &&&& {
    margin: 0;

    ${({ uppercase }) =>
      uppercase &&
      `
      text-transform: uppercase;
    `}
    span {
      font-size: ${props => props.theme.typography.fontSize}px;
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      color: ${props => props.theme.palette.text.lightGrey};
    }
  }
`;

const ListItem = styled(MenuListItem)`
  &&&& {
    height: 18px;

    &:hover {
      background-color: #00000014;

      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      ${ListItemText} {
        color: #2ea8e1;
      }
    }
  }
`;

const DBQLButton = styled(Button)`
  &&&& {
    position: absolute;
    right: 8px;
    top: 12px;
  }
`;
