const takeGridSnapshot = gridApi => {
  const snapshot = [];

  gridApi.forEachNodeAfterFilter(
    ({ id, data, rowIndex, group, field, key, parent, level, childrenAfterFilter, expanded, dragging }) => {
      snapshot.push({
        id,
        dragging,
        expanded,
        data: data ? { ...data } : {},
        rowIndex,
        group,
        field,
        key,
        parent,
        level,
        ...(group && childrenAfterFilter
          ? {
              childrenAfterFilter: childrenAfterFilter.map(children => children.data && children.data.id),
            }
          : {
              parentRowIndex: parent.rowIndex,
            }),
      });
    },
  );

  return snapshot;
};

export { takeGridSnapshot };
