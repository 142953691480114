import styled from 'styled-components';

const TableHeaderTitleWrapper = styled.div`
  height: 100%;

  ${({ bordered, theme }) =>
    bordered &&
    `
      border-right: 1px solid ${theme.palette.border.mercury};
    `}
`;

TableHeaderTitleWrapper.displayName = 'TableHeaderTitleWrapper';

export default TableHeaderTitleWrapper;
