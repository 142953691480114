import {
  DETAILS_FIELD,
  HEALTH_FIELD,
  OWNER_FIELD,
  PRODUCT_FIELD,
  PRODUCT_2_FIELD,
  ROADMAP_FIELD,
  STATUS_FIELD,
  TITLE_FIELD,
  METRICS_LEVEL_FIELD,
} from 'utils/filters/fieldFilters';
import { FILTERS_FUNCTIONS } from 'design-system/constants';
import { getUserName } from 'utils';

export const RECENTLY_CREATED_BY_ME_FILTER_KEY = 'recently-created-by-me';
export const RECENTLY_CREATED_FILTER_KEY = 'recently-created';
export const USER_IS_OWNER_FILTER_KEY = 'user-is-owner';

export const DETAILS_META_KEY = 'details';
export const HEALTH_META_KEY = 'status_color';
export const NAME_META_KEY = 'name';
export const OWNER_META_KEY = OWNER_FIELD.id;
export const PRODUCT_META_KEY = 'products';
export const PRODUCT_2_META_KEY = 'products2';
export const RECENTLY_CREATED_META_KEY = 'created_at';
export const ROADMAP_META_KEY = 'roadmaps';
export const USER_META_KEY = 'users';
export const LEVEL_META_KEY = 'level';

export const getDefaultFilters = currentUser => [
  {
    key: RECENTLY_CREATED_BY_ME_FILTER_KEY,
    children: [],
    label: 'Recently created by me',
    metaType: USER_META_KEY,
    type: 'click',
  },
  {
    key: RECENTLY_CREATED_FILTER_KEY,
    children: [],
    label: 'Recently created',
    metaType: RECENTLY_CREATED_META_KEY,
    type: 'click',
  },
  {
    key: USER_IS_OWNER_FILTER_KEY,
    children: [],
    label: `I'm the owner`,
    metaType: 'owners',
    type: 'click',
    filter: [
      {
        ...currentUser,
        title: getUserName(currentUser),
      },
    ],
    op: FILTERS_FUNCTIONS.in.key,
  },
];

export const getAvailableFilterFields = (hasMetricLevels = false) => [
  STATUS_FIELD,
  OWNER_FIELD,
  ROADMAP_FIELD,
  PRODUCT_FIELD,
  PRODUCT_2_FIELD,
  TITLE_FIELD,
  DETAILS_FIELD,
  HEALTH_FIELD,
  ...(hasMetricLevels ? [METRICS_LEVEL_FIELD] : []),
];

export const FUNCTIONS_BY_FIELD_ID = {
  [STATUS_FIELD.id]: [FILTERS_FUNCTIONS.in],
  [TITLE_FIELD.id]: [FILTERS_FUNCTIONS.in, FILTERS_FUNCTIONS.notIn],
};

export const FUNCTIONS_BY_FIELD_TYPE = {
  option: [FILTERS_FUNCTIONS.in, FILTERS_FUNCTIONS.notIn, FILTERS_FUNCTIONS.isEmpty, FILTERS_FUNCTIONS.isNotEmpty],
  text: [FILTERS_FUNCTIONS.in, FILTERS_FUNCTIONS.notIn, FILTERS_FUNCTIONS.isEmpty],
};
