import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';

import ToastMessage from 'design-system/atoms/ToastMessage/index';


import useSystemFields from 'hooks/useSystemFields';

import { PRODUCT_1_KEY, PRODUCT_2_KEY, ROADMAP_KEY } from '../helpers';

/**
 * @function getChangeParentErrorMessageByType
 *
 * Gets the drag and drop to change parent error message by level type.
 *
 * @param {String} type
 * @param {Function} getSystemFieldName
 * @returns {String}
 */
export const getChangeParentErrorMessageByType = (type, getSystemFieldName) => {
  const level1Label = getSystemFieldName(ROADMAP_KEY);
  const level2Label = getSystemFieldName(PRODUCT_1_KEY);
  const level3Label = getSystemFieldName(PRODUCT_2_KEY);

  switch (type) {
    case PRODUCT_1_KEY:
      return `This ${level2Label} can only be moved within its current ${level1Label}.`;
    case PRODUCT_2_KEY:
      return `This ${level3Label} can only be moved within its current ${level2Label}.`;
    default:
      return '';
  }
};

const useCallErrorToast = () => {
  const [getSystemFieldName] = useSystemFields();

  return useCallback(
    type => {
      const errorMessage = getChangeParentErrorMessageByType(type, getSystemFieldName);

      if (isEmpty(errorMessage)) {
        return;
      }

      return toast(<ToastMessage title={errorMessage} type="error" />);
    },
    [getSystemFieldName],
  );
};

export default useCallErrorToast;
