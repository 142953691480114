import cloneDeep from 'lodash/cloneDeep';

import upsertListItem from '../utils/upsertListItem';
import sortByRank from 'utils/sortByRank';

import {
  FETCH_PRIORITIES_FULFILLED,
  UPDATE_PRIORITY_BY_ID_FULFILLED,
  UPDATE_PRIORITY_FULFILLED,
  CREATE_PRIORITY_FULFILLED,
  ADD_PRIORITY_WITHOUT_SAVE,
  REMOVE_UNSAVED_PRIORITIES,
  SWITCH_PRIORITIES_ROW_ORDER_PENDING,
  SWITCH_PRIORITIES_ROW_ORDER_FULFILLED,
  DELETE_PRIORITY_FULFILLED,
  MERGE_PRIORITIES_FULFILLED,
  SWITCH_PRIORITIES_ROW_ORDER_REJECTED,
  BULK_DELETE_PRIORITY_ROADMAP_FULFILLED,
  CREATE_PRIORITY_ROADMAP_FULFILLED,
  DELETE_PRIORITY_ROADMAP_FULFILLED,
} from './types';

const initialState = {
  priorities: [],
};

function updatePriority(priorities, priority) {
  return priorities.map(item => (item.id === priority.id ? priority : item));
}

function removeUnsaved(list) {
  return list.filter(el => el.id);
}

export default function prioritiesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRIORITIES_FULFILLED:
      const { data } = action.payload;

      return {
        ...state,
        priorities: data,
      };
    case UPDATE_PRIORITY_BY_ID_FULFILLED:
    case UPDATE_PRIORITY_FULFILLED:
      return {
        ...state,
        priorities: updatePriority(state.priorities, action.payload),
        CREATE_PRIORITY_FULFILLED: updatePriority(state.priorities, action.payload),
        isLoaded: true,
      };
    case CREATE_PRIORITY_FULFILLED:
      if (!action.payload || !action.payload.id) {
        return state;
      }

      let priorities = state.priorities || [];

      priorities = removeUnsaved(priorities);
      priorities = upsertListItem(action.payload, priorities);

      return {
        ...state,
        priorities,
        isLoaded: true,
      };
    case ADD_PRIORITY_WITHOUT_SAVE: {
      const priorities = state.priorities ? removeUnsaved(state.priorities) : [];

      priorities.unshift(action.priority || {});

      return {
        ...state,
        priorities,
      };
    }
    case REMOVE_UNSAVED_PRIORITIES:
      return {
        ...state,
        priorities: removeUnsaved(state.priorities),
      };
    case SWITCH_PRIORITIES_ROW_ORDER_REJECTED: {
      if (!action.meta || !action.meta.prev) return state;

      let priorities = state.priorities ? state.priorities.slice(0) : [];

      priorities = upsertListItem(action.meta.prev, priorities);

      return {
        ...state,
        priorities: priorities.sort(sortByRank),
      };
    }
    case SWITCH_PRIORITIES_ROW_ORDER_PENDING: {
      if (!action.meta || !action.meta.row) {
        return state;
      }

      let priorities = state.priorities ? state.priorities.slice(0) : [];

      priorities = upsertListItem(action.meta.row, priorities);

      return {
        ...state,
        priorities: priorities.sort(sortByRank),
      };
    }
    case SWITCH_PRIORITIES_ROW_ORDER_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        console.error('SWITCH_PRIORITIES_ROW_ORDER_FULFILLED::Priority passed does not have id');
        return state;
      }

      let priorities = state.priorities ? state.priorities.slice(0) : [];

      priorities = upsertListItem(action.payload, priorities);

      return {
        ...state,
        priorities: priorities.sort(sortByRank),
      };
    }
    case MERGE_PRIORITIES_FULFILLED:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        priorities: state.priorities.filter(priority => !action.payload.includes(priority.id)),
      };
    case DELETE_PRIORITY_FULFILLED:
      return {
        ...state,
        priorities: state.priorities.filter(p => p.id !== action.payload),
      };
    case CREATE_PRIORITY_ROADMAP_FULFILLED:
      const prioritiesState = cloneDeep(state.priorities);
      const priority = prioritiesState.find(priority => priority.id === action.payload.priority_id);

      priority.priority_roadmaps = [...(priority.priority_roadmaps || []), action.payload];
      priority.updated_at = action.payload.updated_at;

      const updatedPriorities = upsertListItem(priority, prioritiesState);

      return {
        ...state,
        priorities: updatedPriorities,
      };
    case DELETE_PRIORITY_ROADMAP_FULFILLED:
      const ptyState = cloneDeep(state.priorities);
      const { id: priorityId, priorityRoadmaps } = action.payload;
      const parent = ptyState.find(obj => obj.id === parseInt(priorityId));

      parent.priority_roadmaps = priorityRoadmaps || [];

      const updated = upsertListItem(parent, ptyState);

      return {
        ...state,
        priorities: updated,
      };
    case BULK_DELETE_PRIORITY_ROADMAP_FULFILLED:
      const existing = cloneDeep(state.priorities);
      const updatedObjs = existing.map(priority => {
        if (priority.id === action.payload) {
          return {
            ...priority,
            priority_roadmaps: [],
          };
        }

        return priority;
      });

      return {
        ...state,
        priorities: updatedObjs,
      };
    default:
      return state;
  }
}
