import { useCallback, useMemo, useState } from 'react';
import { not, pipe, propEq } from 'ramda';
import isBoolean from 'lodash/isBoolean';

import { filterActiveItens } from 'utils';
import { searchMenuItems } from 'design-system/utils';

import { excludeChildren, unflattenItems, parseMultiSelectionSelectedItems } from './utils';

const DUMMY_EMPTY_OPTION = { id: 'empty', label: '', status: 'Active' };

const excludeItemById = targetId => pipe(propEq('id', targetId), not);
const findItemById = targetId => propEq('id', targetId);

const useTreeDropdown = (items, options = {}) => {
  const { hasEmptyOption, hasMultiLevel, hasMultiSelection, onChange, onSearchChange, selectedItems } = options;

  const [search, setSearch] = useState(null);
  const [showArchived, setShowArchived] = useState(false);

  const tree = useMemo(() => {
    if (!items) return [];

    const filteredItems = showArchived ? items : items.filter(filterActiveItens);
    const dropdownItems = hasMultiLevel ? unflattenItems(filteredItems) : excludeChildren(filteredItems);

    return searchMenuItems(search || '', [...(hasEmptyOption ? [DUMMY_EMPTY_OPTION] : []), ...dropdownItems]);
  }, [items, search, showArchived, hasMultiLevel]);

  const onClearSelectionHandler = useCallback(() => {
    if (onChange) onChange();
  }, [onChange]);

  const onClickItemHandler = useCallback(
    selectedItem => {
      const item = items.find(item => item.id === selectedItem.id);

      if (onChange) onChange(item);
    },
    [items, onChange],
  );

  const onSearchChangeHandler = useCallback(
    ({ target }) => {
      setSearch(target.value);

      if (onSearchChange) {
        onSearchChange(target.value);
      }
    },
    [onSearchChange],
  );

  const onSelectItemHandler = useCallback(
    (rootSelectedItemId, selectionStatus, selectedItem) => {
      /**
       * The following conditions are required because based on the level of the clicked item the
       * typeof the params provided by DndList component (design-system) is different.
       *
       * rootSelectedItemId - always refer to the Id of the root item even when the user selects level 2 items.
       * selectionStatus - Boolean when select a root item and object when is a child ({ 1: true, 2: false })
       * selectedItem - refer to the item clicked by the user (object)
       *
       * For that reason, when using DndList is required to handle the onChange diferenty based on the level clicked.
       * One way to easily targert to the correct handler is to check the type of selectionStatus param.
       */
      const targetSelectedId = isBoolean(selectionStatus) ? rootSelectedItemId : selectedItem.id;
      const targetIsSelected = isBoolean(selectionStatus) ? selectionStatus : Number(selectionStatus[selectedItem.id]);

      let newSelectedItems = [];

      if (targetIsSelected) {
        const item = items.find(findItemById(targetSelectedId));

        newSelectedItems = [...selectedItems, item];
      } else {
        newSelectedItems = selectedItems.filter(excludeItemById(targetSelectedId));
      }

      if (onChange) onChange(newSelectedItems);
    },
    [items, selectedItems],
  );

  const onShowArchivedHandled = useCallback(() => {
    setShowArchived(prevValue => !prevValue);
  }, []);

  const parsedSelectedItems = useMemo(() => {
    if (hasMultiSelection) {
      return parseMultiSelectionSelectedItems(selectedItems);
    }

    return selectedItems;
  }, [hasMultiSelection, selectedItems]);

  return {
    tree,
    search,
    showArchived,
    parsedSelectedItems,
    onClearSelectionHandler,
    onClickItemHandler,
    onSearchChangeHandler,
    onSelectItemHandler,
    onShowArchivedHandled,
  };
};

export default useTreeDropdown;
