import { useState, useEffect } from 'react';

const PLACEMENT_BOTTOM_START = 'bottom-start';
const PLACEMENT_BOTTOM_END = 'bottom-end';

const POPPER_WIDTH_DEFAULT = 200;

/**
 * @function usePopperPlacement
 *
 * get the placement of the popper based on the current anchor position
 * and the width of the window
 *
 * @param  {Object} anchorEl
 * @param  {Number} popperWidth
 * @return {Object}
 */
const usePopperPlacement = (anchorEl, popperWidth = POPPER_WIDTH_DEFAULT) => {
  const [placement, setPlacement] = useState(PLACEMENT_BOTTOM_START);

  useEffect(() => {
    if (!anchorEl) {
      return;
    }

    const { left } = anchorEl.getBoundingClientRect();

    const requiredSpace = left + popperWidth;
    let nextPlacement = PLACEMENT_BOTTOM_START;

    if (requiredSpace > window.innerWidth) {
      nextPlacement = PLACEMENT_BOTTOM_END;
    }

    setPlacement(nextPlacement);
  }, [anchorEl, popperWidth]);

  return {
    placement,
  };
};

export { usePopperPlacement, PLACEMENT_BOTTOM_START, PLACEMENT_BOTTOM_END };
