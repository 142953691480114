import React from 'react';
import PropTypes from 'prop-types';

import NewDialog from 'design-system/organisms/NewDialog/index';


import FieldsMappingEditor from 'containers/FieldsMappingEditor';

import Actions from './components/Actions';
import Title from './components/Title';

const SetupFieldsMappingDialog = props => {
  const { systemFields, integrationType, integrationLayerNames, orgIntegrationId, mapping, onChange, onClose, onSave, layer } =
    props;

  return (
    <NewDialog
      open
      title={
        <Title
          systemFields={systemFields}
          integrationType={integrationType}
          layer={layer}
          integrationLayerNames={integrationLayerNames}
        />
      }
      fullWidth
      maxWidth="lg"
      scroll="paper"
      closeButton
      onClose={onClose}
      actions={<Actions onSave={onSave} onClose={onClose} />}
    >
      <FieldsMappingEditor
        integrationType={integrationType}
        orgIntegrationId={orgIntegrationId}
        mapping={mapping}
        onChange={onChange}
        layer={layer}
      />
    </NewDialog>
  );
};

SetupFieldsMappingDialog.propTypes = {
  systemFields: PropTypes.array.isRequired,
  integrationType: PropTypes.string.isRequired,
  mapping: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default SetupFieldsMappingDialog;
