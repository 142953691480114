import { path } from 'ramda';
import { isElementDomNode } from '@remirror/core-utils';
import { ExtensionPriority } from '@remirror/core-constants';

import {
  BoldExtension,
  UnderlineExtension,
  StrikeExtension,
  CalloutExtension,
  HeadingExtension,
  TextColorExtension,
  OrderedListExtension,
  BulletListExtension,
  HistoryExtension,
  DocExtension,
  TextExtension,
  ParagraphExtension,
  PositionerExtension,
  BlockquoteExtension,
  HorizontalRuleExtension,
  CodeBlockExtension,
  CodeExtension,
  EventsExtension,
  SubExtension,
  SupExtension,
  ImageExtension,
  ListItemExtension,
  TableExtension,
} from 'remirror/extensions';

import LinkExtension from './LinkExtension';
import ItalicExtension from './ItalicExtension';

export default ({ onClick = () => {}, onBlur = () => {}, extraAttributes = {}, imageUploadHandler } = {}) => {
  const eventsExtension = new EventsExtension();

  eventsExtension.addHandler('blur', onBlur);
  eventsExtension.addHandler('click', onClick, ExtensionPriority.Lowest);

  return () => [
    eventsExtension,
    new HistoryExtension(),
    new DocExtension(),
    new TextExtension(),
    new ParagraphExtension(),
    new PositionerExtension(),
    new BoldExtension(),
    new ItalicExtension(),
    new UnderlineExtension({
      markOverride: {
        parseDOM: [
          {
            tag: 'ins',
            getAttrs: element => (!isElementDomNode(element) ? false : {}),
          },
        ],
      },
    }),
    new StrikeExtension(),
    new TableExtension(),
    new ImageExtension({
      ...(imageUploadHandler ? { uploadHandler: imageUploadHandler } : {}),
      // if we need to later customize the progress / placeholder item that appears on the editor
      // while the img is loading
      // createPlaceholder: (view, pos) => {},
      // updatePlaceholder: (view, pos, element, progress)  => {}
      extraAttributes: {
        onerror: { default: '' },
        originalSrc: { default: '' },
        uploadedFileId: {
          default: false,
          toDOM: attrs => ['data-fileId', attrs.uploadedFileId],
        },
      },
      nodeOverride: {
        parseDOM: [
          {
            tag: 'span[class="image-wrap"]',
            getAttrs: element => {
              if (!isElementDomNode(element) || !['A', 'IMG'].includes(element.firstChild?.nodeName)) {
                return {};
              }

              const img = element.firstChild;

              const { width, height } = img.style;
              const imgWidth = width || img.getAttribute('width') || '';
              const imgHeight = height || img.getAttribute('height') || '';

              let imgAlt = img.getAttribute('alt') || img.getAttribute('file-preview-title') || '';

              const originalImgSrc = img.getAttribute('src') || img.getAttribute('href');
              let imgSrc = originalImgSrc;

              const baseURL = path(['baseURLForAttachments', 'default'], extraAttributes) || '';

              // To support jira inline images in details
              // TODO this should be handled by backend in Jira inbound operation
              // convert jira url to our attachment url like we do on outbound
              if (baseURL && ['attachment/content', 'secure/attachment'].some(url => imgSrc.includes(url))) {
                const attachmentId = imgSrc.split('/').reverse()[0];

                imgSrc = `${baseURL}?attachmentUrl=${imgSrc}`;
                imgAlt = imgAlt || attachmentId;
              }

              return {
                alt: imgAlt,
                src: imgSrc,
                title: img.getAttribute('title') || '',
                height: imgHeight,
                width: imgWidth,
                onerror: `this.src = ${originalImgSrc}`,
                originalSrc: originalImgSrc,
                uploadedFileId: img.dataset?.fileId,
              };
            },
          },
        ],
      },
      enableResizing: true,
    }),
    new TextColorExtension({
      extraAttributes: {
        is_date: { default: false },
        is_status_badge: { default: false },
      },
      markOverride: {
        parseDOM: [
          {
            priority: false,
            tag: 'font[color]',
            getAttrs: node => {
              if (!isElementDomNode(node)) {
                return false;
              }

              const color = node.getAttribute('color');
              const attributes = { color };

              if (node.firstChild && node.firstChild.nodeName === 'B' && node.firstChild.textContent.startsWith('[ ')) {
                attributes.is_status_badge = true;
              }

              return attributes;
            },
          },
          {
            tag: 'tt',
            getAttrs: node => {
              if (!isElementDomNode(node)) return false;
              const isJiraDate = (node.textContent || '').split('-').length === 3;

              if (!isJiraDate) {
                // Must return false so other handlers can pick up this node
                return false;
              }

              return { is_date: true };
            },
          },
        ],
      },
    }),
    new CodeExtension({
      markOverride: {
        parseDOM: [
          {
            tag: 'tt',
            getAttrs: node => (!isElementDomNode(node) ? false : {}),
          },
        ],
      },
    }),
    new CodeBlockExtension({
      nodeOverride: {
        parseDOM: [
          {
            tag: 'pre',
            getAttrs: node => (!isElementDomNode(node) ? false : {}),
          },
        ],
      },
    }),
    new CalloutExtension({
      nodeOverride: {
        parseDOM: [
          {
            tag: 'div[class="panel"]',
            getAttrs: node => (!isElementDomNode(node) ? false : {}),
          },
        ],
      },
    }),
    new HeadingExtension(),
    new LinkExtension({
      openLinkOnClick: false,
      autoLink: true,
      extraAttributes,
    }),
    new OrderedListExtension(),
    new BulletListExtension(),
    new BlockquoteExtension(),
    new HorizontalRuleExtension(),
    new SupExtension(),
    new SubExtension(),
    new ListItemExtension(),
  ];
};
