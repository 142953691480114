import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import Input from '@material-ui/core/Input';
import CloseIcon from '@material-ui/icons/Close';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import MicroChip from 'design-system/atoms/MicroChip/index';

import DeleteIcon from 'design-system/atoms/DragonIcons/MultiSelectDelete';
import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';
import theme, { spacing } from 'design-system/theme';

import ListPopper from './components/ListPopper';
import EmptyOption from './components/EmptyOption';
import useAutocomplete from './hooks/useAutocomplete';

const BACKSPACE_KEY_CODE = 8;
const ESC_KEY_CODE = 27;
const DOWN_ARROW_KEY_CODE = 40;

// Adding these specific colors in order to match the colors of the delete icon on the
// multi select fields we have - these should be changed in the future to use our theme
const DELETE_ICON_LIGHT_GREY = '#cccccc';
const DELETE_ICON_GREY = '#999999';
const DELETE_ICON_DARK_GREY = '#666666';

const MultiSelectAutocomplete = ({
  name,
  searchValue,
  options,
  onSelectOption,
  onRemoveOption,
  onRemoveAllOptions,
  onCloseList,
  placeholder = TYPE_TO_SEARCH_PLACEHOLDER,
  disabled = false,
  focusOnInit = false,
  onGroupClickCallback,
  onValueChange,
  registerExpandAllCallback,
  registerCollapseAllCallback,
  canAddNew,
  selectedOptions,
  borderless,
  ...props
}) => {
  const {
    showList,
    inputRef,
    containerRef,
    popperRef,
    focusedRef,
    closeList,
    handleChange,
    handleFocus,
    handleClickOutside,
    handleGroupClick,
  } = useAutocomplete({ onValueChange, onSelectOption, onGroupClickCallback, onCloseList });

  const inputWrapperRef = useRef(null);

  const handleKeyDown = event => {
    const value = event?.target?.value;
    const key = event?.keyCode || event?.charCode;

    const isEscKey = key === ESC_KEY_CODE;
    const isBackspaceKey = key === BACKSPACE_KEY_CODE;
    const isDownArrowKey = key === DOWN_ARROW_KEY_CODE;
    const hasSelectedOptions = selectedOptions?.length > 0;

    // Close list when pressing Escape key
    if (isEscKey && showList) {
      closeList();
    }

    // Focus first item on list when pressing Down arrow key
    if (isDownArrowKey) {
      if (focusedRef?.current) {
        focusedRef?.current.focus();
      }
    }

    // Remove the last selected option when pressing Backspace key on an empty input
    if (isBackspaceKey && hasSelectedOptions && !value) {
      onRemoveOption?.(selectedOptions[selectedOptions?.length - 1]);
    }
  };

  const handleItemClick = item => {
    onSelectOption(item);

    inputRef.current.value = '';
    inputRef.current.focus();
    onValueChange('', { avoidCollapse: true });
  };

  const onSelectItemWithKeyboard = item => {
    if (item?.id) {
      handleItemClick(item);
    }
  };

  useEffect(() => {
    if (focusOnInit) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, []);

  return (
    <ClickAwayListener onClickAway={handleClickOutside}>
      <Container ref={containerRef} {...props}>
        <InputWrapper ref={inputWrapperRef} borderless={borderless}>
          {selectedOptions?.map(opt => (
            <StyledMicroChip
              key={opt.id}
              theme={theme}
              tabIndex={-1}
              label={opt.label}
              fullWidth
              onDelete={() => onRemoveOption(opt)}
              className="multiselect-chip"
              deleteIcon={<CloseIcon fontSize="small" />}
            />
          ))}
          <StyledInput
            type="text"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            placeholder={selectedOptions?.length ? '' : placeholder}
            inputRef={inputRef}
            disabled={disabled}
            disableUnderline
            data-testid={`${name}-autocomplete`}
          />
          {selectedOptions?.length ? <StyledDeleteIcon onClick={onRemoveAllOptions} data-testid="remove-all-options" /> : null}
        </InputWrapper>
        <ListPopper
          open={!!showList}
          anchorEl={containerRef?.current}
          inputRef={popperRef}
          items={options}
          onClickItem={handleItemClick}
          onGroupOpenClose={handleGroupClick}
          width={inputWrapperRef.current?.offsetWidth}
          registerExpandAllCallback={registerExpandAllCallback}
          registerCollapseAllCallback={registerCollapseAllCallback}
          focusedRef={focusedRef}
          onSelectItemWithKeyboard={onSelectItemWithKeyboard}
          renderCustomEmptyOptions={() => (
            <EmptyOption
              showCreateOption={canAddNew}
              label={searchValue}
              onClick={() => handleItemClick({ isNew: true, title: searchValue })}
            />
          )}
        />
      </Container>
    </ClickAwayListener>
  );
};

const Container = styled.div`
  position: relative;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  font-size: ${({ theme }) => theme.sizing.icons.small}rem;
  color: ${DELETE_ICON_LIGHT_GREY};
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: color 0.25s;

  &:hover {
    color: ${DELETE_ICON_GREY};
  }
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-right: ${spacing(4)}px;

  ${({ borderless }) =>
    !borderless &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};

      &:hover,
      &:focus-within {
        border-bottom: 2px solid ${({ theme }) => theme.palette.text.black};
      }
    `}

  &:focus-within {
    ${StyledDeleteIcon} {
      color: ${DELETE_ICON_DARK_GREY};
    }
  }
`;

const StyledMicroChip = styled(MicroChip)`
  &&&& {
    background-color: ${({ theme }) => theme.palette.background.white};
    border: 1px solid ${({ theme }) => theme.palette.background.gainsboro};
    border-radius: ${({ theme }) => theme.shape.borderRadiusExtraLarge}px;
    height: auto;
    padding: 2px 0 2px ${spacing()}px;

    span {
      padding: 0;
      color: ${({ theme }) => theme.palette.text.black};
      font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    }

    svg {
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
      margin: 0 ${spacing()}px;
    }
  }
`;

const StyledInput = styled(Input)`
  width: 100%;
  flex: 1;
  min-width: 100px;
`;

export default MultiSelectAutocomplete;
