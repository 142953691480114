import React, { useEffect } from 'react';
import queryString from 'query-string';
import { removeQueryParam } from 'utils';

const REDIRECT_URI = `${process?.env?.REACT_APP_PUBLIC_URL}/miro-redirect/`;
const MIRO_APP_CLIENT_ID = process.env.REACT_APP_MIRO_INTEGRATION_CLIENT_ID;

export default Component => {
  return props => {
    const {
      orgIntegration,
      userIntegration,
      addUserIntegration,
      addOrgIntegration,
      userCanUpdateOrgIntegration,
      userCanViewOrgIntegration,
    } = props;

    const shouldRenderAdminControl = userCanUpdateOrgIntegration;
    const shouldRenderCreateUserIntegration = userCanUpdateOrgIntegration && orgIntegration && !userIntegration;
    const shouldRenderRemoveUserIntegration = !userCanUpdateOrgIntegration && orgIntegration && userIntegration;
    const shouldRenderTestConnection = orgIntegration && userIntegration && userCanViewOrgIntegration;
    const shouldRenderContactAdmin = !userCanUpdateOrgIntegration || !userCanViewOrgIntegration;
    const shouldRenderConnectedTeam = !!orgIntegration?.data?.team_id;
    const { authorize = false, ...params } = queryString.parse(window.location.search);

    const receiveMessage = event => {
      let obj;

      try {
        if (typeof event.data === 'string') obj = JSON.parse(event.data);
        else if (typeof event.data === 'object') obj = event.data;
        else return;
      } catch (err) {
        return;
      }

      if (obj.integrationType === 'miro') {
        addUserIntegration({ ...obj, redirect_uri: REDIRECT_URI });
      }
    };

    const createUserIntegrationWithMiro = () => {
      window.open(
        queryString.stringifyUrl({
          url: 'https://miro.com/oauth/authorize',
          query: {
            response_type: 'code',
            client_id: MIRO_APP_CLIENT_ID,
            redirect_uri: REDIRECT_URI,
            team_id: orgIntegration?.data?.team_id,
          },
        }),
      );
      window.addEventListener('message', receiveMessage, false);
    };

    useEffect(() => {
      if (!userCanUpdateOrgIntegration && !orgIntegration) {
        window.history.pushState(null, null, window.location.pathname);
      } else if (authorize) {
        const createOrgIntegration = userCanUpdateOrgIntegration && !orgIntegration ? addOrgIntegration : () => Promise.resolve();

        window.history.pushState(null, null, removeQueryParam(window.location.href, 'authorize'));
        createOrgIntegration()
          .then(() => addUserIntegration({ ...params, redirect_uri: REDIRECT_URI }))
          .then(() => window.history.pushState(null, null, window.location.pathname))
          .catch(e => console.log('ERROR - ', e));
      }
    }, [authorize]);

    return (
      <Component
        shouldRenderAdminControl={shouldRenderAdminControl}
        shouldRenderCreateUserIntegration={shouldRenderCreateUserIntegration}
        shouldRenderRemoveUserIntegration={shouldRenderRemoveUserIntegration}
        shouldRenderTestConnection={shouldRenderTestConnection}
        shouldRenderContactAdmin={shouldRenderContactAdmin}
        shouldRenderConnectedTeam={shouldRenderConnectedTeam}
        createUserIntegrationWithMiro={createUserIntegrationWithMiro}
        {...props}
      />
    );
  };
};
