import styled from 'styled-components';
import Input from '@material-ui/core/Input';

export const Wrapper = styled.div`
  margin: 20px 32px;
  min-height: 200px;
`;

export const ImportInstructions = styled.p`
  display: flex;
  font-size: 16px;
  line-height: 23px;

  strong {
    font-weight: bold;
  }

  a {
    margin-left: 120px;
  }
`;

export const ErrorMessageContainer = styled.p`
  color: ${props => props.theme.palette.text.error};
  font-size: ${props => props.theme.typography.fontSizeSmall}px;
  line-height: 1em;
`;

export const InputContainer = styled.div`
  margin: 36px 0 11px 0;
  text-align: right;
  width: 550px;
  max-width: 100%;
  border: 1px solid;
  border-color: ${props => (props.hasError ? props.theme.palette.text.error : `rgba(0, 0, 0, 0.23)`)};
  display: flex;

  button {
    background-color: rgba(0, 0, 0, 0.08);
    text-transform: initial;
    border-radius: 0;
    font-weight: 400;
    height: 42px;
    width: 90px;
  }
`;

export const QueryInput = styled(Input)`
  &&&& {
    width: calc(100% - 42px);

    input {
      width: 100%;
      height: 100%;
      padding: 10px;
      color: #4689f0;
      font-size: 14px;
    }
  }
`;

export const AutocompleteWrapper = styled.div`
  &&&& {
    margin: 36px 0 11px 0;
  }
`;
