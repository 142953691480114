import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import useDeepEffect from 'hooks/useDeepEffect';
import { removeGanttTolltip } from 'components/ganttCommon';
import { getPageFilters } from 'store/filters/selectors';

export default ({ gantt, refreshGantt, reloadGanttTimeline, lsState, columns, tasks, ganttTasks, reloadGantt }) => {
  const pageFilters = useSelector(state => getPageFilters(state));

  const nTasks = useRef(0);

  // Refresh gantt with data
  useDeepEffect(
    changes => {
      if (!gantt) return;

      const numOfChanges = changes?.flat().filter(Boolean).length;
      const changesOnData = changes[changes.length - 1];
      const numOfChangesOnData = changesOnData.filter(Boolean).length;

      // If has more tha one change should refresh all gantt data
      if (!tasks.changedIds || numOfChanges !== numOfChangesOnData || nTasks.current !== ganttTasks.data.length) {
        refreshGantt(ganttTasks);
        nTasks.current = ganttTasks.data.length;
        return;
      }

      changesOnData.forEach((changed, index) => {
        if (changed) {
          const row = ganttTasks.data[index];

          if (!row) return;

          // row = {
          //   ...row,
          //   start_date: moment(row.start_date, 'DD-MM-YYYY').toDate(),
          //   ...(row.end_date ? { end_date: moment(row.end_date, 'DD-MM-YYYY').toDate() } : {}),
          // };
          // const _convertRowToCompare = data => ({
          //   ...data,
          //   start_date: moment(data.start_date).toString(),
          //   ...(data.end_date ? { end_date: moment(data.end_date).toString() } : {}),
          // });
          // const _convertRowToGantt = data => ({
          //   ...data,
          //   ...(data.start_date ? { start_date: moment(data.start_date).toDate() } : {}),
          //   ...(data.end_date ? { end_date: moment(data.end_date).toDate() } : {}),
          // });

          try {
            const currentRowData = gantt.getTask(row.id);

            // if (currentRowData && currentRowData.draggedOn && new Date().getTime() - currentRowData.draggedOn < 4000) return;

            if (!currentRowData) return;

            // const newRowData = objectShallowDifference(_convertRowToCompare(currentRowData), _convertRowToCompare(row));

            // console.log(currentRowData, row);
            Object.assign(currentRowData, row); // _convertRowToGantt(newRowData));
          } catch (err) {
            // pass
          }
        }
      });

      gantt.refreshData();
    },
    [
      gantt,
      tasks.data.length,
      tasks.links,
      pageFilters,
      lsState.displayStoryProgEndDate,
      lsState.plannedEndDate,
      lsState.localMode,
      lsState.showTasks,
      lsState.showEstimates,
      lsState.hideDependencyLines,
      tasks.data,
    ],
  );
  // Refresh gantt without data
  useDeepEffect(() => {
    if (!gantt) return;
    // will refresh only the gantt without refresh the data
    refreshGantt();
  }, [
    columns,
    lsState.zoom,
    lsState.selectedZoom,
    lsState.showTooltip,
    lsState.showGroupedTimeline,
    lsState.showTimeframes,
    lsState.displayAlerts,
    lsState.sort,
  ]);
  useEffect(() => {
    if (!gantt) return;
    // when we change zoom should set scroll on the current day
    gantt.showDate(new Date());
  }, [lsState.zoom, !tasks.data.length]);

  useDeepEffect(() => {
    if (!gantt) return;
    reloadGantt();
    removeGanttTolltip();
  }, [
    lsState.showBusinessValue,
    lsState.showHCTable,
    lsState.selectedRounding,
    lsState.selectedHighlight,
    lsState.selectedTimeWindow,
    lsState.showResourceUsers,
    lsState.resourceUsersGroup,
  ]);
};
