import axios from 'axios';

import { createThunk } from 'utils/store/thunk';
import deserializeCustomerRequest from 'store/customerRequests/helpers/deserialize';

import { FETCH_CUSTOMER_REQUESTS_FOR_CLUSTER, FETCH_CUSTOMER_REQUEST_CLUSTERS_FOR_ANALYSE_CHART } from './types';

const fetchCustomerRequestsClustersForAnalyseChart = (clusterBy, filters = {}) => {
  return createThunk(FETCH_CUSTOMER_REQUEST_CLUSTERS_FOR_ANALYSE_CHART, async () => {
    const { data } = await axios.get('/api/customer-requests/analyze/cluster', {
      params: {
        clusterBy,
        filters,
      },
    });

    return data;
  });
};

const fetchCustomerRequestsForCluster = uuid => {
  return createThunk(FETCH_CUSTOMER_REQUESTS_FOR_CLUSTER, async () => {
    const { data } = await axios.get(`/api/customer-requests/analyze/cluster/${uuid}`);

    return data.map(deserializeCustomerRequest);
  });
};

export { fetchCustomerRequestsClustersForAnalyseChart, fetchCustomerRequestsForCluster };
