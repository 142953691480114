import { head } from 'ramda';

const PORTFOLIO_LABEL_PLACEHOLDER = 'Portfolio';
const CORP_LABEL_PLACEHOLDER = 'Corp';

const makeMetadataRoadmapsOptionsFormatter = (
  organization,
  selectedRoadmapId,
  organizationAccessControl = {},
  roadmaps = [],
  corpRoadmaps = [],
) => {
  const { isDodActive, isChildDragon, isParentDragon, organizationAccessControlData, getDefaultRoadmapTitleForMetadataItem } =
    organizationAccessControl;

  const cleanMetadataRoadmaps = metadataRoadmaps => {
    return metadataRoadmaps.filter(option => !!option.roadmap_id && option.roadmap);
  };

  const getRoadmapChildren = (roadmap, options, listKey, needsObject, childRoadmapKey) => {
    const { id: roadmapId, title: roadmapTitle } = roadmap || {};

    return options.reduce((matches, option) => {
      const optionRoadmaps = cleanMetadataRoadmaps(option[listKey] || option[childRoadmapKey] || []);

      let childrenMatches = [];

      if (option?.children?.length) {
        childrenMatches = getRoadmapChildren(roadmap, option.children, listKey, needsObject, childRoadmapKey);
      }

      const hasMatchesInChildren = childrenMatches?.length;
      const hasDirectMatch = optionRoadmaps.find(opt => opt.roadmap_id === roadmapId);
      const isAppliedToPortfolio = !optionRoadmaps?.length && !roadmapId;

      const match = hasMatchesInChildren || hasDirectMatch || isAppliedToPortfolio;

      if (match) {
        const enhanceName = name => (isDodActive ? `${name} (${getDefaultRoadmapTitleForMetadataItem(option)})` : name);
        const optionName = option.title || option.name;
        const displayName = enhanceName(optionName);
        const tooltip = isDodActive
          ? displayName
          : `${optionName}${roadmapTitle ? ` (${roadmapTitle})` : ` (${getDefaultRoadmapTitleForMetadataItem(option)})`}`;
        const childOption = needsObject
          ? {
              ...option,
              displayName,
              name: optionName,
              children: childrenMatches,
              tooltip,
            }
          : optionName;

        matches.push(childOption);
      }

      return matches;
    }, []);
  };

  const renderGlobalItem = (
    key,
    label,
    organizationId,
    options,
    needsObject,
    listKey,
    suffixItemNameWithLabel,
    id = null,
    childListKey,
  ) => ({
    id,
    key,
    label,
    children: options.reduce((childItems, option) => {
      const cleanList = cleanMetadataRoadmaps(option[listKey] || option[childListKey] || []);

      if (!cleanList.length && option.organization_id === organizationId) {
        const optionName = option.title || option.name;
        const enhanceName = name => (suffixItemNameWithLabel ? `${name} (${label})` : name);
        const displayName = enhanceName(optionName);
        const childOption = needsObject
          ? {
              ...option,
              displayName,
              name: optionName,
              children: option.children ? getRoadmapChildren(undefined, option.children, listKey, needsObject, childListKey) : [],
            }
          : optionName;

        childItems.push(childOption);
      }
      return childItems;
    }, []),
  });

  const formatMetadataOptions = (listKey, options, config = {}) => {
    const { needsObject = false, includeCorpRoadmap = false, childRoadmapKey } = config;
    const menuList = [];

    roadmaps.forEach(roadmap => {
      const curCorpRoadmapTitle = corpRoadmaps.find(rm => rm.id === roadmap?.parent_id)?.title;

      const shouldDisplayCorpRoadmapTitle = includeCorpRoadmap && curCorpRoadmapTitle;

      const listItem = {
        id: roadmap.id,
        key: `${roadmap.title}-options`,
        label: `${roadmap.title} ${shouldDisplayCorpRoadmapTitle ? `(${curCorpRoadmapTitle})` : ''}`,
        children: getRoadmapChildren(roadmap, options, listKey, needsObject, childRoadmapKey),
      };

      if (listItem.children.length) {
        if (roadmap.id === selectedRoadmapId) {
          menuList.unshift(listItem);
        } else {
          menuList.push(listItem);
        }
      }
    });

    const ownOrganizationGlobalItem = renderGlobalItem(
      'portfolio-options',
      getDefaultRoadmapTitleForMetadataItem({ organization_id: organization.id }),
      organization.id,
      options,
      needsObject,
      listKey,
      includeCorpRoadmap,
      null,
      childRoadmapKey,
    );

    const itemsWithoutMetadataRoadmaps = [ownOrganizationGlobalItem];
    // todo: how can we avoid the following isChildDragon / isParentDragon ? or is unavoidable
    const shouldAddCorpRoadmapToMetadataRoadmaps = isChildDragon && includeCorpRoadmap;

    if (isParentDragon) {
      itemsWithoutMetadataRoadmaps.push(
        ...organizationAccessControlData.map(access => {
          const corpRoadmapForAccess = corpRoadmaps.find(rm => rm.id === access.portfolio_roadmap_id);
          const key = `${access.child_organization_slug}-options`;
          const label = corpRoadmapForAccess?.title ?? PORTFOLIO_LABEL_PLACEHOLDER;
          const organizationId = access.child_organization_id;

          return renderGlobalItem(
            key,
            label,
            organizationId,
            options,
            needsObject,
            listKey,
            includeCorpRoadmap,
            key,
            childRoadmapKey,
          );
        }),
      );
    } else if (shouldAddCorpRoadmapToMetadataRoadmaps) {
      const corpRoadmapForAccess =
        corpRoadmaps.find(rm => rm.id === access.portfolio_roadmap_id) || organizationAccessControl?.corpAccountRoadmap;
      const access = organizationAccessControlData.find(access => access.child_organization_id === organization.id);
      const corpOrganizationId = access.parent_organization_id;
      const label = corpRoadmapForAccess?.title ?? CORP_LABEL_PLACEHOLDER;
      const key = `${label}-options`;
      const corpGlobalItem = renderGlobalItem(
        key,
        label,
        corpOrganizationId,
        options,
        needsObject,
        listKey,
        includeCorpRoadmap,
        key,
        corpRoadmapForAccess?.id,
      );

      if (corpGlobalItem?.children?.length) {
        itemsWithoutMetadataRoadmaps.push(corpGlobalItem);
      }
    }

    const hasGlobal = itemsWithoutMetadataRoadmaps.some(item => item?.children?.length);
    const globalItem = head(itemsWithoutMetadataRoadmaps.splice(0, 1));

    const hasApplicableRoadmaps = !!menuList.find(item => item.id === selectedRoadmapId);

    if (selectedRoadmapId && hasGlobal && hasApplicableRoadmaps) {
      menuList.splice(1, 0, globalItem);
    } else if (hasGlobal) {
      menuList.unshift(globalItem);
    }

    menuList.push(...itemsWithoutMetadataRoadmaps);

    return menuList;
  };

  return formatMetadataOptions;
};

export default makeMetadataRoadmapsOptionsFormatter;
