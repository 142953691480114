import { ADD_SUMMARY_GROUP, REMOVE_SUMMARY_GROUP } from './types';

export const addSummaryGroup = (metadataEntity, metadataType) => ({
  type: ADD_SUMMARY_GROUP,
  payload: {
    metadataEntity,
    metadataType,
  },
  meta: {
    makesActiveViewDirty: true,
  },
});

export const removeSummaryGroup = (metadataEntityId, metadataType) => ({
  type: REMOVE_SUMMARY_GROUP,
  payload: {
    metadataEntityId,
    metadataType,
  },
  meta: {
    makesActiveViewDirty: true,
  },
});
