import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Text from 'design-system/atoms/Text/index';

import MergeLightbox from 'components/MergeLightbox';
import { mergeCustomerRequests } from 'store/customerRequests';

import { transformMergeRequestLabel } from './helpers';

const RequestsMergeModal = ({ selectedItems, isVisible, hideDialog, disable }) => {
  const dispatch = useDispatch();

  const handleMerge = useCallback((itemsIdsToMerge, persistItem) => {
    return dispatch(mergeCustomerRequests(persistItem, itemsIdsToMerge));
  }, []);

  return (
    <MergeLightbox
      title={
        <>
          <Text as="h3">Merge Requests</Text>
          <StyledText as="label">(Select the request to merge into and the others will be archived)</StyledText>
        </>
      }
      items={selectedItems}
      dialogVisible={isVisible}
      merge={handleMerge}
      saveButtonLabel="Merge"
      transformLabel={transformMergeRequestLabel}
      hideDialog={hideDialog}
      disableSelectionMode={disable}
    />
  );
};

export default RequestsMergeModal;

const StyledText = styled(Text)`
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
