import React, { useEffect, useMemo } from 'react';

import { BaseGrid } from 'containers/Grids';
import { useMetadata, useProjectsOnGrids, useSystemFields } from 'containers/IdeasList/IdeasList/New/hooks';
import useIdeasGridRowDrag from 'containers/IdeasList/IdeasList/New/hooks/useIdeasGridRowDrag';
import { GRID_HEIGHT, ID_KEY } from 'containers/IdeasList/IdeasList/New/constants';
import { useCommonGridEventHandlers, useCommonGridProps } from 'design-system/molecules/AgGridReact-New/hooks';
import {
  sumNumericFields,
  sumScopeVarianceField,
  sumPointsVarianceField,
} from 'design-system/molecules/AgGridReact-New/aggFuncs';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';

import { checkRowSelectable, getDataPath, getRowStyle } from 'containers/IdeasList/IdeasList/New/helpers';
import { BASE_ROW_HEIGHT } from 'constants/grid';

import usePageFilters from 'hooks/filters/usePageFilters';
import useLoginInfo from 'hooks/useLoginInfo';
import useOrganizations from 'hooks/useOrganizations';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useAdditionalMetadataDropdownOptions from 'hooks/metadata/useAdditionalMetadataDropdownOptions';

import useCompareRoadmapHistoryGridState from './hooks/useCompareRoadmapHistoryGridState';
import useCompareRoadmapHistorySnapsGrid from './hooks/useCompareRoadmapHistorySnapsGrid';
import useCompareRoadmapHistoryColumnsDefinitions from './hooks/useCompareRoadmapHistoryColumnsDefinitions';
import useGridStylesWithSnapsHeader from './hooks/useGridStylesWithSnapsHeaderColors';
import useGetMetadataFromSelectedSnapsToCompare from 'features/RoadmapHistory/hooks/useGetMetadataFromSelectedSnapsToCompare';
import useCompareRoadmapHistoryGridSnapsDiffs from './hooks/useCompareRoadmapHistoryGridSnapsDiffs';

const getRowId = ({ data }) => data?.id;

const CompareRoadmapHistorySnapsGrid = ({ viewType, data, onGridReady: parentHandleGridReady }) => {
  const { getSystemFieldName } = useSystemFields();

  const { displayLayer, portfolioMode, searchString: pageFiltersSearchString } = usePageFilters();

  const { currentUser } = useLoginInfo();

  const gridStylesWithSnapsColor = useGridStylesWithSnapsHeader();

  const {
    organization,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    filters: { topLayer },
    customUserFields,
    integrations,
    customFields,
    hasMetadataMultiSelect,
  } = useOrganizations();

  const { isParentDragon } = useOrganizationsAccessControl();

  const { metadata, filteredMetadata, checkUserCanCreateNewMetadata, createMetadataMultiSelectOption } = useMetadata(data, {
    currentUser,
    organization,
  });

  const additionalMetadataOptions = useAdditionalMetadataDropdownOptions();

  const {
    hasUnsavedProject,
    defaultPhase,

    updateProjectById,
    createUnsavedProject,
    removeUnsavedProjects,
    createNewProject,
    openProjectInfo,
    openOkrInfo,
    loadParentProjects,
    switchProjectRowOrder,
  } = useProjectsOnGrids();

  const changedFieldsByProjectBySnapDiff = useCompareRoadmapHistoryGridSnapsDiffs();

  const {
    rowHeight,
    expandedGroups,
    isGrouping,
    searchString,
    isBulkDeleting,
    visibleColumnsIds,
    columnsToCompareIds,
    columnsState,
    selectedGroup1,
    showItemsWithDifferences,
    isLoadingProjects,
    highlightField,

    saveGridConfig,
    setSelectedItems,
    saveColumnsState,
    removeGridRow,
    setDirtyViewMutex,
  } = useCompareRoadmapHistoryGridState(viewType);

  const {
    processedData,

    getGridIsReady,
    getGridApi,

    handleGridReady,
    handleRowGroupOpened,
    handleSelectionChanged,
    handleDisplayedColumnsChanged,
    handleOnColumnResized,
    checkIsGroupOpenByDefault,
    addNewInlineProject,
    openRowInfo,
    updateProjectByIdOnCellValueChange,
    loadParentProjectsOptions,
    handleMetadataCellDoubleClick,
  } = useCompareRoadmapHistorySnapsGrid(data, {
    parentHandleGridReady,
    displayLayer,
    portfolioMode,
    currentUser,
    hasBet,
    defaultPhase,
    expandedGroups,
    visibleColumnsIds,
    columnsToCompareIds,
    columnsState,
    viewType,
    selectedGroup1,
    showItemsWithDifferences,
    changedFieldsByProjectBySnapDiff,
    highlightField,

    saveGridConfig,
    getSystemFieldName,
    createUnsavedProject,
    openProjectInfo,
    openOkrInfo,
    setSelectedItems,
    updateProjectById,
    loadParentProjects,
    saveColumnsState,
  });

  const { columnDefs, defaultColDef, groupColumnDef } = useCompareRoadmapHistoryColumnsDefinitions({
    portfolioMode,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    topLayer,
    displayLayer,
    rowHeight,
    metadata,
    filteredMetadata,
    createMetadataMultiSelectOption,
    visibleColumnsIds,
    columnsToCompareIds,
    titleSearchString: searchString || pageFiltersSearchString,
    customUserFields,
    columnsState,
    integrations,
    currentUser,
    customFields,
    hasMetadataMultiSelect,

    getSystemFieldName,
    addNewInlineProject,
    openProjectInfo,
    openRowInfo,
    loadParentProjectsOptions,
    checkUserCanCreateNewMetadata,
    updateProjectById,
    handleMetadataCellDoubleClick,
    removeGridRow,
  });

  const commonGridProps = useCommonGridProps({
    isTreeView: true,
    aggFuncs: { sumNumericFields, sumScopeVarianceField, sumPointsVarianceField },
  });

  const { handleRowDrag } = useIdeasGridRowDrag({
    selectedGroup1,
    switchProjectRowOrder,
    updateProjectById,
  });

  const commonGridEvents = useCommonGridEventHandlers({
    items: processedData,
    idKey: ID_KEY,
    actions: {
      update: updateProjectByIdOnCellValueChange,
      save: createNewProject,
      remove: removeUnsavedProjects,
      drag: handleRowDrag,
      sortChanged: handleDisplayedColumnsChanged,
    },
  });

  const metadataWithSnapMetadata = useGetMetadataFromSelectedSnapsToCompare({ metadata });

  const gridContext = useMemo(
    () => ({
      hasUnsavedProject,
      isParentDragon,
      portfolioMode,
      isGrouping,
      isBulkDeleting,
      processedData,
      parents: metadata.parents, // todo: Fix - we do not have parents here
      metadata: metadataWithSnapMetadata,
      filteredMetadata,
      additionalMetadataOptions,
      changedFieldsByProjectBySnapDiff,
      highlightField,
    }),
    [
      hasUnsavedProject,
      isParentDragon,
      portfolioMode,
      isGrouping,
      isBulkDeleting,
      processedData,
      metadataWithSnapMetadata,
      filteredMetadata,
      additionalMetadataOptions,
      changedFieldsByProjectBySnapDiff,
      highlightField,
    ],
  );

  useEffect(() => {
    if (getGridIsReady()) {
      getGridApi().resetRowHeights();
    }
  }, [rowHeight]);

  useEffect(() => {
    // Cleanup selected items if stopped bulk deleting.
    if (!isBulkDeleting && getGridIsReady()) {
      getGridApi().deselectAll();
    }
  }, [isBulkDeleting]);

  useEffect(() => {
    if (getGridIsReady()) {
      getGridApi().redrawRows();
    }
  }, [highlightField]);

  if (isLoadingProjects) {
    return <PageLoading />;
  }

  return (
    <BaseGrid
      // Common props:
      {...commonGridProps}
      {...commonGridEvents}
      maintainColumnOrder={false}
      // General props:
      onGridReady={handleGridReady}
      onFirstDataRendered={setDirtyViewMutex}
      rowData={processedData}
      context={gridContext}
      isGroupOpenByDefault={checkIsGroupOpenByDefault}
      isRowSelectable={checkRowSelectable}
      rowSelection="multiple"
      suppressRowClickSelection
      // Getters/Accessors:
      getRowId={getRowId}
      getDataPath={getDataPath}
      // Styling:
      cssStyles={gridStylesWithSnapsColor}
      getRowStyle={getRowStyle}
      height={GRID_HEIGHT}
      rowHeight={rowHeight}
      headerHeight={BASE_ROW_HEIGHT}
      // Columns:
      defaultColDef={defaultColDef}
      autoGroupColumnDef={groupColumnDef}
      columnDefs={columnDefs}
      // Events:
      onRowGroupOpened={handleRowGroupOpened}
      onSelectionChanged={handleSelectionChanged}
      onDisplayedColumnsChanged={handleDisplayedColumnsChanged}
      onColumnResized={handleOnColumnResized}
    />
  );
};

export default CompareRoadmapHistorySnapsGrid;
