import React, { Component } from 'react';
import styled from 'styled-components';
import { func, string, object } from 'prop-types';
import Delete from '@material-ui/icons/DeleteOutlined';
import Button from '@material-ui/core/Button';

import { ConfirmDialog } from '../gridCommon';

export default class DeleteButton extends Component {
  static propTypes = {
    remove: func.isRequired,
    confirmDialogTitle: string,
    confirmDialogText: string,
    classes: object,
  };

  static defaultProps = {
    confirmDialogTitle: 'Delete',
    confirmDialogText: 'Are you sure you want to delete this item?',
    classes: {},
  };

  constructor(props) {
    super(props);

    this.openConfirmDialog = this.openConfirmDialog.bind(this);
    this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
  }

  state = {
    confirmDialogOpened: false,
  };

  openConfirmDialog() {
    this.setState({ confirmDialogOpened: true });
  }

  closeConfirmDialog() {
    this.setState({ confirmDialogOpened: false });
  }

  onDelete = () => {
    this.setState({
      loading: true,
    }, () => {
      this.props.remove();
      this.closeConfirmDialog();
    });
  };

  render() {
    const {
      confirmDialogTitle,
      confirmDialogText,
      classes,
      style,
    } = this.props;

    return (
      <div>
        <DButton className={classes.button} onClick={this.openConfirmDialog} style={style}><Delete /></DButton>
        <ConfirmDialog
          title={confirmDialogTitle}
          text={confirmDialogText}
          isOpen={this.state.confirmDialogOpened}
          onCancel={this.closeConfirmDialog}
          onDelete={this.onDelete}
          disableActions={this.state.loading}
        />
      </div>
    );
  }
}

const DButton = styled(Button)`
  &&&&{
    &:hover, &:focus, &:active{
      background-color: transparent;
    }
  }
`;
