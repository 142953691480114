import { IDEA_LAYER } from 'store/projects/constants';
import { CHILDREN_FILTERS } from 'constants/filters';

const getFiltersCounter = (pageFilters, fields) => {
  const shouldUseChildrenFields = pageFilters.layer !== IDEA_LAYER && pageFilters.children === CHILDREN_FILTERS.filteredChildren;
  const filterConditionsCleared = ['fields', ...(shouldUseChildrenFields ? ['childrenFields'] : [])].reduce(
    (acc, filtersObjKey) => [
      ...acc,
      ...Object.keys(pageFilters[filtersObjKey] || {}).filter(key => {
        if (!fields.map(f => f.id).includes(key)) return false;
        return true;
      }),
    ],
    [],
  );

  return filterConditionsCleared.length;
};

export default getFiltersCounter;
