import { useCallback, useMemo } from 'react';

import { processGroomedColumnDefs, processColumnsGroup as processColumnsGroupUtil } from 'utils/grids/helpers';
import { useSystemFields } from 'containers/IdeasList/IdeasList/New/hooks';
import { ChipsCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { MultiSelectCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import useNumberOfTextRowsToDisplayOnGridCell from 'hooks/useNumberOfTextRowsToDisplayOnGridCell';
import { multiSelectComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { useCustomerRequestsColumnsDefs } from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsColumnsDefs';

const TAGS_KEY = 'tag';

const useAIAnalyzerGridColumnsDefs = ({
  searchString,
  isBulkUpdate,
  visibleColumnsIds,
  columnsState,
  rowHeight,

  updateCustomerRequestById: updateCustomerRequestByIdOnCellValueChange,
  onChangeColumnVisibility,
  onOpenCustomerRequestInfo,
  createMultiSelectOption,
}) => {
  const { getSystemFieldName } = useSystemFields();

  const { defaultColDef, groupColumnDef } = useCustomerRequestsColumnsDefs({
    searchString,
    isBulkUpdate,
    visibleColumnsIds,
    columnsState,
    rowHeight,

    updateCustomerRequestById: updateCustomerRequestByIdOnCellValueChange,
    onChangeColumnVisibility,
    onOpenCustomerRequestInfo,
    createMultiSelectOption,
  });

  const processColumnsGroup = useCallback(
    columns => processColumnsGroupUtil(columns, visibleColumnsIds, columnsState),
    [visibleColumnsIds, columnsState],
  );

  const { getNumberOfTextRowsToDisplayOnGridCell } = useNumberOfTextRowsToDisplayOnGridCell(rowHeight);

  const titleGroupColumnDef = useMemo(
    () => ({
      ...groupColumnDef,
      rowDrag: false,
      editable: false,
      minWidth: 600,
      flex: 1,
      headerCheckboxSelection: true,
      checkboxSelection: true,
    }),
    [groupColumnDef],
  );

  const tagsColumnDef = useMemo(
    () => ({
      field: 'tags',
      headerName: getSystemFieldName(TAGS_KEY),
      minWidth: 150,
      flex: 1,
      labelField: 'title',
      cellRenderer: ChipsCellRenderer,
      cellEditor: MultiSelectCellEditor,
      cellEditorPopup: true,
      cellEditorParams: params => ({
        options: params.context.metadata.tags,
        createOption: createMultiSelectOption(params),
      }),
      cellRendererParams: {
        getNumberOfTextRowsToDisplayOnGridCell,
      },
      comparator: multiSelectComparator,
    }),
    [getSystemFieldName, createMultiSelectOption],
  );

  const autoTagColumnDef = useMemo(
    () => ({
      field: 'autoTags',
      headerName: 'AI Tag',
      minWidth: 150,
      flex: 1,
      labelField: 'title',
      cellRenderer: ChipsCellRenderer,
      cellEditor: MultiSelectCellEditor,
      cellEditorPopup: true,
      cellEditorParams: params => ({
        options: params.context.metadata.tags,
        createOption: createMultiSelectOption(params),
      }),
      cellRendererParams: {
        getNumberOfTextRowsToDisplayOnGridCell,
      },
      editable: false,
      comparator: multiSelectComparator,
    }),
    [getSystemFieldName, createMultiSelectOption],
  );

  const aiAnalyzerColumnDefs = useMemo(() => [tagsColumnDef, autoTagColumnDef], [tagsColumnDef, autoTagColumnDef]);

  const columnDefs = useMemo(
    () =>
      processGroomedColumnDefs(
        [
          titleGroupColumnDef,

          // AI Analizer fields
          ...processColumnsGroup(aiAnalyzerColumnDefs),
        ],
        columnsState,
      ),
    [processColumnsGroup, titleGroupColumnDef, columnsState, aiAnalyzerColumnDefs],
  );

  return {
    columnDefs,
    defaultColDef,
    groupColumnDef: titleGroupColumnDef,
  };
};

export default useAIAnalyzerGridColumnsDefs;
