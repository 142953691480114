import { ADMIN_USER, OWNER_USER, LEADER_USER, CSM_USER, EDITOR_USER, MANAGER_USER } from '@dragonboat/permissions';

export default {
  ADD_NEW_IDEA: [ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER],
  UPDATE_IDEA: [ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER],
  DELETE_IDEA: [ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER],
  IMPORT_IDEAS: [ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER],
  BOARD_ADD_NEW_LANE: [ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER],
  BOARD_EDIT_LANE: [ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER],
  BOARD_VIEW_GROUP_SETTINGS: [ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER],
  SOW_OUTDATED_IDEAS_ALERT: [ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER],
  DELETE_ESTIMATE: [ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER],
  ADD_ESTIMATE: [ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER],
  EDIT_ADO_INTEGRATION: [OWNER_USER, LEADER_USER],
  EDIT_JIRA_INTEGRATION: [OWNER_USER, LEADER_USER, CSM_USER],
  EDIT_RALLY_INTEGRATION: [OWNER_USER, LEADER_USER],
  UPDATE_METADATA: [OWNER_USER, LEADER_USER, ADMIN_USER, MANAGER_USER, EDITOR_USER],
  DELETE_OBJECTIVE: [OWNER_USER, LEADER_USER, ADMIN_USER, MANAGER_USER, EDITOR_USER],
  EDIT_ORGANIZATION: [OWNER_USER, LEADER_USER, ADMIN_USER, MANAGER_USER, EDITOR_USER],
  EDIT_PROJECT_FIELDS_LAYOUT: [OWNER_USER, LEADER_USER],
};
