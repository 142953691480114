import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotification, getNotificationById } from '../../store/app';

const containerHOC = Component => {
  return () => {
    const currentState = useSelector(state => state);
    const slackErrorNotification = getNotificationById(currentState, 'SLACK_AUTHENTICATION_ERROR');
    const [showDialog, setShowDialog] = useState(false);
    const dispatch = useDispatch();

    const [error, setError] = useState('');

    useEffect(() => {
      if (slackErrorNotification) {
        if (slackErrorNotification.id === 'SLACK_AUTHENTICATION_ERROR') {
          setShowDialog(true);
        }
      }
    }, [slackErrorNotification]);

    const handleOnConfirm = async () => {
      setShowDialog(false);
      try {
        const response = await axios.post('/api/integrations/slack/request');

        window.open(response.data.uri, 'Slack_Authentication', 'height=600,width=600');
      } catch (err) {
        setError(err.response.data.message || 'Invalid credentials');
      }
      await dispatch(removeNotification(slackErrorNotification.id));
    };
    const handleOnCancel = async () => {
      setShowDialog(false);
      await dispatch(removeNotification(slackErrorNotification.id));
    };

    return <Component error={error} showDialog={showDialog} handleOnCancel={handleOnCancel} handleOnConfirm={handleOnConfirm} />;
  };
};

export default containerHOC;
