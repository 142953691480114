import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import NewDialog from 'design-system/organisms/NewDialog/index';


import FieldRow from './FieldRow';
import FieldMappingHeader from './FieldMappingHeader';

import useSystemFields from 'hooks/useSystemFields';

export default function UpdateFieldMappingDialog({
  integrationNameLabel,
  integrationAvailableFields = [],
  dragonboatAvailableFields = [],
  mapping,
  onChange,
  onClose,
  onSave,
  systemFieldName = 'customer',
}) {
  const [getSystemFieldName] = useSystemFields();

  const renderDialogTitle = () => (
    <TitleWrapper>
      Map {integrationNameLabel} Fields to Dragonboat {getSystemFieldName(systemFieldName)} Fields{' '}
    </TitleWrapper>
  );

  const renderDialogActions = () => (
    <Grid container direction="row" alignItems="center" justify="flex-end">
      <Button item color="primary" onClick={onSave}>
        Save
      </Button>
      <Button item onClick={onClose}>
        Cancel
      </Button>
    </Grid>
  );

  return (
    <NewDialog
      open
      title={renderDialogTitle()}
      maxWidth="lg"
      width="45%"
      scroll="paper"
      closeButton
      onClose={onClose}
      actions={renderDialogActions()}
    >
      <Wrapper>
        <FieldMappingHeader integrationNameLabel={integrationNameLabel} />
        {mapping.map(fieldData => (
          <FieldRow
            key={fieldData.key}
            fieldData={fieldData}
            integrationSuggestions={integrationAvailableFields}
            disableDefaultFields={['name', 'crm_record_url']}
            onChange={onChange}
          />
        ))}
      </Wrapper>
    </NewDialog>
  );
}

const TitleWrapper = styled.span``;
const Wrapper = styled.div``;
