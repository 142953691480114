import { useCallback } from 'react';
import ReactDOM from 'react-dom';

import useDropdownStyles from './useDropDownStyles';
import useCalculateDropdownPosition from './useCalculateDropdownPosition';

/**
 * @function useRenderDropdownContainer
 *
 * User render dropdown options container
 *
 * @param  {Function} props.renderOptions
 * @param  {Object} props.inputRef
 * @param  {Boolean} props.isOpen
 * @param  {Object} props.dropdownRef
 * @return {Function}
 */
const useRenderDropdownContainer = ({ renderOptions, inputRef, isOpen, dropdownRef }) => {
  const calculateDropdownPosition = useCalculateDropdownPosition();
  const dropdownStyle = useDropdownStyles();

  const renderDropdownContainer = useCallback(
    ({ containerProps, children, query }) => {
      if (!inputRef?.current) return;
      if (!isOpen) return;

      const inputCoords = inputRef.current.getBoundingClientRect();
      const dropdownCoords = dropdownRef?.current ? dropdownRef.current.getBoundingClientRect() : {};
      const position = calculateDropdownPosition(inputCoords, dropdownCoords);

      const style = {
        ...dropdownStyle,
        ...position,
        width: inputCoords.width,
        maxHeight: `${position.maxHeight}px`,
      };

      const renderProps = {
        ...containerProps,
        ref: dropdownRef,
        className: `${containerProps.className} autocomplete-portal`,
        style,
      };

      return ReactDOM.createPortal(renderOptions(renderProps), document.body);
    },
    [inputRef?.current, dropdownRef?.current, calculateDropdownPosition, renderOptions, dropdownStyle],
  );

  return renderDropdownContainer;
};

export default useRenderDropdownContainer;
