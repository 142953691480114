import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const GroupByDescription = styled(Typography)`
  &&&&& {
    font-size: ${({ theme }) => theme.typography.fontSizeSmall}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    color: ${({ theme }) => theme.palette.text.actionLabel.primary};
    margin: 0 17px 0 12px;
    display: inline-block;
    padding-top: ${props => props.paddingTop || 0}px;
  }
`;

export default ({ color = 'textSecondary', text, paddingTop }) => {
  return (
    <GroupByDescription color={color} paddingTop={paddingTop}>
      {text}
    </GroupByDescription>
  );
};
