/**
 * PageTitle Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import styled from 'styled-components';
import { textColor } from 'design-system/themes/default';

export default styled.h1`
  color: ${textColor};
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 18px;
`;
