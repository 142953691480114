import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { archiveAnnouncement, createAnnouncement, deleteAnnouncement, updateAnnouncement } from 'store/announcements';

import { getAnnouncements } from 'store/announcements/selectors';
import { getCurrentUser } from 'store/login/selectors';

import checkIfUserIsAdmin from 'utils/checkIfUserIsAdmin';
import { ADMIN_USER, EDITOR_USER, MANAGER_USER, REQUESTOR_USER, READ_ONLY_USER } from '@dragonboat/permissions';

const ADMIN_ANNOUNCEMENT_TITLE = 'Create Announcements for Your Teams';
const DEFAULT_ANNOUNCEMENT_TITLE = 'Announcements';

export default function useOrgAnnouncements(selectedAnnouncementId) {
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const allAnnouncements = useSelector(getAnnouncements);

  const userIsAdmin = useMemo(() => checkIfUserIsAdmin(currentUser), [currentUser]);

  const userCanArchiveAnnouncements = useMemo(
    () => [ADMIN_USER, EDITOR_USER, MANAGER_USER, REQUESTOR_USER, READ_ONLY_USER].includes(currentUser?.role_id),
    [currentUser],
  );

  const announcementCardTitle = useMemo(
    () => (userIsAdmin ? ADMIN_ANNOUNCEMENT_TITLE : DEFAULT_ANNOUNCEMENT_TITLE),
    [userIsAdmin],
  );

  const selectedAnnouncement = useMemo(
    () => allAnnouncements.find(announcement => announcement.id === selectedAnnouncementId),
    [selectedAnnouncementId],
  );

  const handleAddAnnouncement = useCallback(
    (message, channel, integrationType) => {
      if (message) {
        dispatch(createAnnouncement({ message, channel, integrationType }));
      }
    },
    [dispatch],
  );

  const handleUpdateAnnouncement = useCallback(
    (id, message, channel, integrationType) => {
      if (id && message) {
        dispatch(updateAnnouncement(id, { message, channel, integrationType }));
      }
    },
    [dispatch],
  );

  const saveAnnouncement = useCallback(
    (message, channel, integrationType) => {
      if (selectedAnnouncementId) {
        return handleUpdateAnnouncement(selectedAnnouncementId, message, channel, integrationType);
      }

      return handleAddAnnouncement(message, channel, integrationType);
    },
    [handleAddAnnouncement, selectedAnnouncementId],
  );

  const handleDeleteAnnouncement = useCallback(
    id => {
      if (id) {
        dispatch(deleteAnnouncement(id));
      }
    },
    [dispatch],
  );

  const handleArchiveAnnouncement = useCallback(
    id => {
      if (id) {
        dispatch(archiveAnnouncement(id));
      }
    },
    [dispatch],
  );

  return {
    addAnnouncementsVisible: userIsAdmin,
    announcementCardTitle,
    announcements: allAnnouncements,
    handleArchiveAnnouncement,
    handleDeleteAnnouncement,
    isAdmin: userIsAdmin,
    canArchiveAnnouncements: userCanArchiveAnnouncements,
    saveAnnouncement,
    selectedAnnouncement,
  };
}
