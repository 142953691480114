import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { organizationHasSlackIntegration, selectSlackChannels } from 'store/organization/selectors';
import { getSlackIntegrationChannels } from 'store/organization';
import { getCurrentUser } from 'store/login/selectors';
import { updateMe } from 'store/login/actions';

export default Component => props => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => getCurrentUser(state));
  const notificationsPreferences = currentUser.notifications_preferences;
  const hasSlackIntegration = useSelector(organizationHasSlackIntegration);
  const slackChannels = useSelector(selectSlackChannels);

  const handleChange = notificationsPreferences => dispatch(updateMe({ notifications_preferences: notificationsPreferences }));

  useEffect(() => {
    if (hasSlackIntegration) dispatch(getSlackIntegrationChannels());
  }, []);

  return (
    <Component
      showSlackColumn={hasSlackIntegration}
      updateNotifications={handleChange}
      notificationsPreferences={notificationsPreferences}
      slackChannels={slackChannels}
    />
  );
};
