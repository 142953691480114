import React from 'react';

import DndList from 'design-system/organisms/DndList/DndList';

import ListRow from './ListRow';
import { N_OF_ROWS_TO_DISPLAY, ROW_HEIGHT } from '../constants';
import { DataList, FooterContainer, StyledLoading, Container } from './styles';

const GAP = 16;
const HEIGHT_BOTTOM_OFFSET = 2;

const getRowHeight = rowHeight => rowHeight || ROW_HEIGHT;
const getAllRowsHeight = rowHeight => getRowHeight(rowHeight) * N_OF_ROWS_TO_DISPLAY;
const getHeight = rowHeight => getAllRowsHeight(rowHeight) + HEIGHT_BOTTOM_OFFSET;

const ListContainer = ({ inputRef, listRef, footerComponent, data, isLoading, isOpen, getContainerPosition, onClickItem }) => {
  const footerComponentHeight = footerComponent ? ROW_HEIGHT : 0;

  const getContainerStylesProps = () => {
    const inputBoundingClientRect = inputRef.current?.getBoundingClientRect();

    try {
      const defaultContainerTopPosition = inputBoundingClientRect.bottom + GAP;

      const containerWouldBeOutOfBounds = defaultContainerTopPosition + getContainerHeight(ROW_HEIGHT) > window.innerHeight;

      const width = Math.max(500, inputBoundingClientRect.width);

      const defaultPostion = {
        $top: containerWouldBeOutOfBounds
          ? inputBoundingClientRect.top - GAP - getContainerHeight(ROW_HEIGHT)
          : defaultContainerTopPosition,
        $left: inputBoundingClientRect.right - width,
        $width: width,
      };

      if (getContainerPosition) {
        return getContainerPosition(defaultPostion, inputBoundingClientRect);
      }

      return defaultPostion;
    } catch {
      return {};
    }
  };

  const getContainerHeight = () => getHeight(ROW_HEIGHT) + footerComponentHeight;

  const _renderTableComponent = () => (
    <DataList $height={getHeight(ROW_HEIGHT)}>
      <DndList
        wrapperStyle={{
          boxShadow: 'none',
        }}
        items={data}
        $height={getHeight(ROW_HEIGHT)}
        width={500}
        itemHeight={ROW_HEIGHT}
        rootHeight={getHeight(ROW_HEIGHT)}
        styleAsAutocomplete
        renderCustomItem={item => (
          <ListRow key={item?.id} onClick={() => onClickItem(item)}>
            {item?.label}
          </ListRow>
        )}
      />
    </DataList>
  );

  const _renderCustomFooter = () => {
    if (!footerComponent) return '';

    const FooterComponent = footerComponent;

    return <FooterComponent />;
  };

  const _renderFooterComponent = () => <FooterContainer>{_renderCustomFooter()}</FooterContainer>;

  return (
    isOpen && (
      <Container {...getContainerStylesProps()} ref={listRef} containerHeight={getContainerHeight()}>
        {isLoading && <StyledLoading />}
        {!isLoading && _renderTableComponent()}
        {_renderFooterComponent()}
      </Container>
    )
  );
};

export default ListContainer;
