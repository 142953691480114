import React, { Fragment } from 'react';
import styled from 'styled-components';
import xor from 'lodash/xor';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Autocomplete from 'design-system/atoms/Autocomplete/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import MultiSelect from 'design-system/atoms/MultiSelect/index';

import { ucFirst } from 'utils';
import getSystemFieldName from 'utils/getSystemFieldName';
import { dateFieldToGroupOptions, groupDateByOptions } from 'store/summary/consts';
import { isGroupFieldADate } from 'utils/grouping';
import { getActiveCustomUserFields } from 'utils/customUserFields/customUserFields';
import {
  getAdditionalRoadmapsPreferences,
  getAdditionalObjectivesPreferences,
  getAdditionalTimeframesPreferences,
  getAdditionalCategoriesPreferences,
  getAdditionalThemesPreferences,
  getTeamsPreferences,
  getPersonasPreferences,
  getLifecyclesPreferences,
} from 'utils/metadata';
import { ALL_CUSTOM_USER_FIELDS_AVAILABLE } from 'constants/projects';

export default ({
  rowDataOptions,
  selectedRowData,
  colDataOptions,
  groupByOptions,
  selectedColData,
  groupByData,
  headerColor,
  displayPreferences,
  onChangeRowData,
  onChangeColData,
  onChangeDisplayPref,
  onChangeHeaderColor,
  onChangeChipData,
  systemFields,
  hasProducts,
  hasProducts2,
  hasKeyResults,
  hasKeyResults2,
  hasTeams2,
  customFields,
  customUserFields,
  hasHierarchy,
  open,
  // onOpen,
  onCancel,
  onApply,
  lsState,
  updateState,
  errors,
  orgIntegrations,
  avoidDatesConfig = false,
  shouldShowPreCalculations,
  hasMetadataMultiSelect = true,
  hasMultiLevelPortfolioMetadata,
  hasCompletedAllocation,
  hasPersonas,
  hasLifecycles,
}) => {
  const { dateFieldToGroup, groupDatesBy } = lsState;

  const activeCustomUserFields = getActiveCustomUserFields(customUserFields);

  const preferencesToRender = [
    { key: 'key', label: 'Key' },
    { key: 'health', label: 'Health', fitContent: true },
    { key: 'title', label: 'Title' },
    { key: 'roadmap', label: getSystemFieldName('roadmap', systemFields) },
    ...(hasProducts ? [{ key: 'product1', label: getSystemFieldName('product1', systemFields) }] : []),
    ...(hasMetadataMultiSelect
      ? getAdditionalRoadmapsPreferences(hasProducts, hasProducts2, getSystemFieldName, systemFields)
      : []),
    { key: 'timeframe', label: getSystemFieldName('timeframe', systemFields) },
    ...(hasMultiLevelPortfolioMetadata ? [{ key: 'timeframe2', label: getSystemFieldName('timeframe2', systemFields) }] : []),
    ...(hasMetadataMultiSelect
      ? getAdditionalTimeframesPreferences(hasMultiLevelPortfolioMetadata, getSystemFieldName, systemFields)
      : []),
    { key: 'progress', label: 'Progress' },
    { key: 'objective', label: getSystemFieldName('objective', systemFields) },
    ...(hasKeyResults ? [{ key: 'keyResult1', label: getSystemFieldName('keyResult1', systemFields) }] : []),
    ...(hasKeyResults2 ? [{ key: 'keyResult2', label: getSystemFieldName('keyResult2', systemFields) }] : []),
    ...(hasMetadataMultiSelect
      ? getAdditionalObjectivesPreferences(hasKeyResults, hasKeyResults2, getSystemFieldName, systemFields)
      : []),
    ...(hasPersonas ? getPersonasPreferences(getSystemFieldName, systemFields) : []),
    ...(hasLifecycles ? getLifecyclesPreferences(getSystemFieldName, systemFields) : []),
    { key: 'customers', label: getSystemFieldName('customer', systemFields) },
    { key: 'tags', label: getSystemFieldName('tag', systemFields) },
    { key: 'theme', label: getSystemFieldName('theme', systemFields) },
    ...(hasMetadataMultiSelect ? getAdditionalThemesPreferences(getSystemFieldName, systemFields) : []),
    { key: 'category', label: getSystemFieldName('category', systemFields) },
    ...(hasMetadataMultiSelect ? getAdditionalCategoriesPreferences(getSystemFieldName, systemFields) : []),
    { key: 'progressCount', label: 'Progress issue count', fitContent: true },
    { key: 'scopeVariance', label: 'Scope Variance', fitContent: true },
    { key: 'plannedAllocation', label: 'Planned Scope (weeks)', fitContent: true },
    { key: 'reportedAllocation', label: 'Reported Scope (weeks)', fitContent: true },
    ...(hasCompletedAllocation ? [{ key: 'completedAllocation', label: 'Completed Scope (weeks)', fitContent: true }] : []),
    { key: 'owner', label: 'Owner' },
    { key: 'planningStage', label: 'Planning Stage', fitContent: true },
    { key: 'phase', label: getSystemFieldName('phase', systemFields), fitContent: true },
    { key: 'status_summary', label: 'Status summary', fitContent: true },
    { key: 'details', label: 'Details' },
    { key: 'priority', label: getSystemFieldName('priority', systemFields) },
    { key: 'estimated_start_date', label: 'Target start date' },
    { key: 'deadline', label: 'Target end date' },
    { key: 'predictedEndDate', label: 'Predicted end date' },
    { key: 'team', label: getSystemFieldName('team', systemFields) },
    ...(shouldShowPreCalculations
      ? [
          { key: 'totalRevenue', label: 'Total value' },
          { key: 'activeRevenue', label: 'Active value' },
          { key: 'inactiveRevenue', label: 'Inactive value' },
        ]
      : []),
    { key: 'customersCount', label: '# of Customers' },
    { key: 'customerRequestsCount', label: 'Total requests' },
    ...(orgIntegrations && orgIntegrations.length > 0 ? [{ key: 'integrationKey', label: 'Integration Key' }] : []),
    ...(hasHierarchy
      ? [
          { key: 'bet', label: getSystemFieldName('bet', systemFields) },
          { key: 'initiative', label: getSystemFieldName('initiative', systemFields) },
        ]
      : []),
    ...(hasMetadataMultiSelect ? getTeamsPreferences(hasTeams2, getSystemFieldName, systemFields) : []),
    ...customFields.map(f => ({ key: f.key, label: `${f.title} (cf)` })),
    ...(activeCustomUserFields || []).map(cf => ({
      key: ALL_CUSTOM_USER_FIELDS_AVAILABLE[cf]?.field,
      label: getSystemFieldName(ALL_CUSTOM_USER_FIELDS_AVAILABLE[cf]?.systemFieldKey, systemFields),
    })),
  ];

  const dateCustomFields = customFields.filter(f => f.field_type === 'Date');
  const dateFieldOptions = [...dateCustomFields, ...dateFieldToGroupOptions];

  const headerPreferencesToRender = [
    {
      key: 'issueCount',
      checked: !!displayPreferences.issueCount,
      label: 'Sum of issue count',
      onChange: () => onChangeDisplayPref('issueCount', !displayPreferences.issueCount),
    },
    {
      key: 'overallProgress',
      checked: !!displayPreferences.overallProgress,
      label: 'Overall Progress',
      onChange: () => onChangeDisplayPref('overallProgress', !displayPreferences.overallProgress),
    },
    {
      key: 'verticalRowHeader',
      checked: displayPreferences.verticalRowHeader,
      label: 'Vertical Row header',
      fitContent: true,
      onChange: () => onChangeDisplayPref('verticalRowHeader', !displayPreferences.verticalRowHeader),
    },
    {
      key: 'headerColor.row',
      checked: !!headerColor.row,
      label: 'Color Row header',
      fitContent: true,
      onChange: () => onChangeHeaderColor('row', !headerColor.row),
    },
    {
      key: 'headerColor.column',
      checked: !!headerColor.column,
      label: 'Color Column header',
      fitContent: true,
      onChange: () => onChangeHeaderColor('column', !headerColor.column),
    },
    {
      key: 'showGroupColor',
      checked: !!displayPreferences.showGroupColor,
      label: 'Display group color',
      fitContent: true,
      onChange: () => onChangeDisplayPref('showGroupColor', !displayPreferences.showGroupColor),
    },
  ];

  const displayFields = displayPreferences.displayFields || ['title', 'timeframe', 'health', 'key'];
  const shouldShowDatesConfig =
    !avoidDatesConfig &&
    ((selectedColData && isGroupFieldADate(selectedColData)) ||
      (selectedRowData && isGroupFieldADate(selectedRowData)) ||
      (groupByData && isGroupFieldADate(groupByData)));

  const handleCancel = e => {
    e.stopPropagation();

    onCancel();
  };

  const handleApply = e => {
    e.stopPropagation();

    onApply();
  };

  return (
    <Fragment>
      {/* <ButtonIcon title="Display preferences" onClick={onOpen}>
        <SettingsIcon />
      </ButtonIcon> */}
      <Dialog open={!!open} maxWidth="md" fullWidth data-testid="summary-preferences-dialog">
        <DialogTitle>
          Display Preferences
          <CloseButton onClick={handleCancel}>
            <CloseIcon />
          </CloseButton>
        </DialogTitle>
        <DialogContent>
          <LeftContent showDates={shouldShowDatesConfig}>
            <PreferenceGroup>
              <FormControl fullWidth margin="dense" data-testid="summary-preferences-dialog__header-fields">
                <MultiSelect
                  options={headerPreferencesToRender}
                  optionsMapper={{ label: 'label', value: 'key' }}
                  label="Include in header fields"
                  placeholder="Select fields to display"
                  onChange={activeOptions => {
                    const allCheckedOptions = headerPreferencesToRender.filter(p => !!p.checked).map(p => p.key);
                    const changedOption = xor(activeOptions, allCheckedOptions);

                    const headerPreference = headerPreferencesToRender.find(p => p.key === changedOption[0]);

                    if (headerPreference.onChange) headerPreference.onChange();
                  }}
                  value={headerPreferencesToRender.filter(p => !!p.checked)}
                  hideCreateOption
                  hideIcon
                />
              </FormControl>
            </PreferenceGroup>
            <PreferenceGroup>
              <FormControl fullWidth margin="dense">
                <Autocomplete
                  label="Build column by"
                  name="selectedColData"
                  suggestions={colDataOptions.map(o => o.title)}
                  value={selectedColData && selectedColData.title}
                  onValueChange={option => {
                    if (option) onChangeColData(colDataOptions.find(o => o.title === option));
                  }}
                  hideIcon
                />
              </FormControl>
            </PreferenceGroup>
            <PreferenceGroup>
              <FormControl fullWidth margin="dense">
                <Autocomplete
                  label="Build row by"
                  name="selectedRowData"
                  suggestions={rowDataOptions.map(o => o.title)}
                  value={selectedRowData && selectedRowData.title}
                  onValueChange={option => {
                    if (option) onChangeRowData(rowDataOptions.find(o => o.title === option));
                  }}
                  hideIcon
                />
              </FormControl>
            </PreferenceGroup>
            <PreferenceGroup>
              <FormControl
                fullWidth
                margin="dense"
                error={errors.displayFields}
                data-testid="summary-preferences-dialog__include-in-table"
              >
                <MultiSelect
                  options={preferencesToRender}
                  optionsMapper={{ label: 'label', value: 'key' }}
                  label="Include in the table"
                  placeholder="Select fields to display"
                  onChange={visibleCols => onChangeDisplayPref('displayFields', visibleCols)}
                  value={displayFields.map(f => preferencesToRender.find(p => p.key === f))}
                  hideCreateOption
                  error={errors.displayFields}
                  hideIcon
                  draggable
                />
                {errors.displayFields && <FormHelperText>{errors.displayFields}</FormHelperText>}
              </FormControl>
            </PreferenceGroup>
            <PreferenceGroup>
              <FormControl fullWidth margin="dense">
                <Autocomplete
                  label="In cell grouping by"
                  name="groupByData"
                  suggestions={groupByOptions.map(o => ucFirst(o.title))}
                  value={groupByData && ucFirst(groupByData.title)}
                  onValueChange={option => {
                    if (option) onChangeChipData(groupByOptions.find(o => ucFirst(o.title) === option));
                    if (!option) onChangeChipData(null);
                  }}
                  hideIcon
                  requireOption
                />
              </FormControl>
            </PreferenceGroup>
          </LeftContent>
          {shouldShowDatesConfig && (
            <RightContent>
              <TextDeprecated size="medium">Dynamic date grouping</TextDeprecated>
              <PreferenceGroup>
                <FormControl fullWidth margin="dense">
                  <Autocomplete
                    label="Select a date field to group"
                    name="dateFieldToGroup"
                    suggestions={dateFieldOptions.map(f => f.title)}
                    value={dateFieldToGroup && dateFieldToGroup.title}
                    onValueChange={option => {
                      if (option) {
                        updateState({
                          dateFieldToGroup: dateFieldOptions.find(o => o.title === option),
                        });
                      }
                    }}
                    hideIcon
                    requireOption
                  />
                </FormControl>
              </PreferenceGroup>
              <PreferenceGroup>
                <FormControl fullWidth margin="dense">
                  <Autocomplete
                    label="Group dates by"
                    name="groupDatesBy"
                    suggestions={groupDateByOptions.map(o => o.title)}
                    value={groupDatesBy && groupDatesBy.title}
                    onValueChange={option => {
                      if (option) {
                        updateState({
                          groupDatesBy: groupDateByOptions.find(o => o.title === option),
                        });
                      }
                    }}
                    hideIcon
                    requireOption
                  />
                </FormControl>
              </PreferenceGroup>
              <PreferenceGroup>
                <TextDeprecated color="red" breakwords>
                  The page will be Read Only when Dynamic Date grouping is applied
                </TextDeprecated>
              </PreferenceGroup>
            </RightContent>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleApply} color="primary">
            Apply
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const PreferenceGroup = styled.div`
  margin: 12px 0;
`;

const CloseButton = styled(IconButton)`
  &&&& {
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;

const LeftContent = styled.div`
  float: left;
  width: 100%;

  ${({ showDates }) =>
    showDates &&
    `
    width: 50%;
  `}
`;

const RightContent = styled.div`
  float: left;
  width: 50%;
  padding-left: 50px;
`;
