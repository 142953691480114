import { PAGE_HEADER_HEIGHT } from 'constants/common';

const AG_GRID_POPUP_EDITOR_CLASS = '.ag-popup-editor';

const LINE_HEIGHT = 21;

const BASE_ROW_HEIGHT = 35.0;

const TOOLBAR_HEIGHT = 50;

const OFFSET = PAGE_HEADER_HEIGHT + TOOLBAR_HEIGHT;

const DEFAULT_TABLE_HEIGHT = `calc(100vh - ${OFFSET}px)`;

const GROUP_COLUMN_INITIAL_WIDTH = 400;

const ROW_HEIGHT_OPTIONS = [
  { id: BASE_ROW_HEIGHT, title: '1x', key: BASE_ROW_HEIGHT },
  { id: BASE_ROW_HEIGHT + LINE_HEIGHT, title: '2x', key: BASE_ROW_HEIGHT + LINE_HEIGHT },
  // prettier-ignore
  { id: BASE_ROW_HEIGHT + (LINE_HEIGHT * 2), title: '3x', key: BASE_ROW_HEIGHT + (LINE_HEIGHT * 2) },
  // prettier-ignore
  { id: BASE_ROW_HEIGHT + (LINE_HEIGHT * 3), title: '4x', key: BASE_ROW_HEIGHT + (LINE_HEIGHT * 3) },
];

const SHOW_UNCOMMITTED_PROJECTS = 'showUncommittedProjects';

export {
  TOOLBAR_HEIGHT,
  AG_GRID_POPUP_EDITOR_CLASS,
  BASE_ROW_HEIGHT,
  DEFAULT_TABLE_HEIGHT,
  LINE_HEIGHT,
  ROW_HEIGHT_OPTIONS,
  SHOW_UNCOMMITTED_PROJECTS,
  GROUP_COLUMN_INITIAL_WIDTH,
};
