import container from './Ideas';
import HeaderOptions from './HeaderOptions';
import HeaderFilters from './HeaderFilters';

const Route = container;

export default {
  Route,
  HeaderOptions,
  HeaderFilters,
};
