import { createSelector } from 'reselect';
import { pathOr } from 'ramda';

import { OBJECTIVE_METADATA_POPOVER, PROJECT_METADATA_POPOVER } from './types';

const AVAILABLE_METADATA_POPOVER_TYPES = [OBJECTIVE_METADATA_POPOVER, PROJECT_METADATA_POPOVER];

const getState = state => state?.metadataPopover || {};

export const isMetadataPopoverOpen = createSelector(
  getState,
  (metadataPopoverState, popoverType) => popoverType,
  (metadataPopoverState, popoverType) => {
    if (!AVAILABLE_METADATA_POPOVER_TYPES.includes(popoverType)) return false;

    const isOpen = pathOr(false, [popoverType, 'isPopoverOpen'], metadataPopoverState);

    return isOpen;
  },
);

export const selectMetadataSelected = createSelector(
  getState,
  (metadataPopoverState, popoverType) => popoverType,
  (metadataPopoverState, popoverType) => {
    if (!AVAILABLE_METADATA_POPOVER_TYPES.includes(popoverType)) return '';

    const metadataSelected = pathOr('', [popoverType, 'metadataSelected'], metadataPopoverState);

    return metadataSelected;
  },
);
