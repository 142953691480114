import { defaultTo, head } from 'ramda';
import noop from 'lodash/noop';

import { AND } from 'features/DBQL/AdvancedQueryBuilder/helpers/constants';
import getFieldFunctions from 'containers/AdvancedSearchPopover/helpers/getFieldFunctions';

const emptyArray = [];

const useGroupConditionQueryBuilder = ({ index, allFields = emptyArray, onChange = noop, conditions = emptyArray }) => {
  const groupOperator = head(conditions)?.groupOperator || AND;

  const addNewCondition = () => onChange(index, { conditions: [...conditions, { groupOperator }] });
  const addNewConditionGroup = () =>
    onChange(index, {
      conditions: [
        ...conditions,
        {
          group: true,
          groupOperator,
          conditions: [],
        },
      ],
    });

  const onChangeCondition = (updatedIndex, changes) => {
    const updatedConditions = conditions.map((prevCondition, index) => {
      if (index !== updatedIndex) {
        return prevCondition;
      }

      const updates = changes;

      if (changes?.field) {
        const field = allFields.find(f => f.id === changes?.field);
        const functions = getFieldFunctions(field);

        updates.field = field;
        updates.value = defaultTo(null, field?.defaultInitialValue);
        updates.op = head(functions)?.key;
      }

      return { ...prevCondition, ...updates };
    });

    onChange(index, { conditions: updatedConditions });
  };
  const onRemoveCondition = removeIndex => {
    const updatedConditions = conditions.filter((_, index) => index !== removeIndex);

    onChange(index, { conditions: updatedConditions });
  };

  const onUpdateGroupOperator = newOperator => {
    const updatedConditions = conditions.map(prevCondition => ({
      ...prevCondition,
      groupOperator: newOperator,
    }));

    onChange(index, { conditions: updatedConditions });
  };

  return {
    conditions,
    groupOperator,
    addNewCondition,
    addNewConditionGroup,
    onChangeCondition,
    onRemoveCondition,
    onUpdateGroupOperator,
  };
};

export default useGroupConditionQueryBuilder;
