import { getThunkActionTypes } from 'utils/store/thunk';

const { CREATE_ROADMAP_HISTORY_SNAPSHOT, CREATE_ROADMAP_HISTORY_SNAPSHOT_FULFILLED } = getThunkActionTypes(
  'CREATE_ROADMAP_HISTORY_SNAPSHOT',
);

const { FETCH_ROADMAP_HISTORY_PROJECTS, FETCH_ROADMAP_HISTORY_PROJECTS_FULFILLED } =
  getThunkActionTypes('FETCH_ROADMAP_HISTORY_PROJECTS');

const { SELECT_VIEW_SNAP_TO_COMPARE, SELECT_VIEW_SNAP_TO_COMPARE_FULFILLED, SELECT_VIEW_SNAP_TO_COMPARE_PENDING } =
  getThunkActionTypes('SELECT_VIEW_SNAP_TO_COMPARE');

const {
  REMOVE_SELECTED_SNAP_TO_COMPARE,
  REMOVE_SELECTED_SNAP_TO_COMPARE_FULFILLED,
  REMOVE_SELECTED_SNAP_TO_COMPARE_PENDING,
  REMOVE_SELECTED_SNAP_TO_COMPARE_REJECTED,
} = getThunkActionTypes('REMOVE_SELECTED_SNAP_TO_COMPARE');

const {
  UPDATE_ROADMAP_HISTORY_SNAPSHOT,
  UPDATE_ROADMAP_HISTORY_SNAPSHOT_FULFILLED,
  UPDATE_ROADMAP_HISTORY_PENDING,
  UPDATE_ROADMAP_HISTORY_REJECTED,
} = getThunkActionTypes('UPDATE_ROADMAP_HISTORY_SNAPSHOT');

const {
  FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW,
  FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW_FULFILLED,
  FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW_PENDING,
  FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW_REJECTED,
} = getThunkActionTypes('FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW');

const { DELETE_ROADMAP_HISTORY_SNAPSHOT, DELETE_ROADMAP_HISTORY_SNAPSHOT_FULFILLED } = getThunkActionTypes(
  'DELETE_ROADMAP_HISTORY_SNAPSHOT',
);

const {
  FETCH_ROADMAP_HISTORY_COMPARE_DATA,
  FETCH_ROADMAP_HISTORY_COMPARE_DATA_FULFILLED,
  FETCH_ROADMAP_HISTORY_COMPARE_DATA_PENDING,
  FETCH_ROADMAP_HISTORY_COMPARE_DATA_REJECTED,
} = getThunkActionTypes('FETCH_ROADMAP_HISTORY_COMPARE_DATA');

const REMOVE_ALL_SELECTED_SNAP_TO_COMPARE = 'REMOVE_ALL_SELECTED_SNAP_TO_COMPARE';
const UPDATE_ROADMAP_HISTORY_SNAPSHOT_REALTIME = 'UPDATE_ROADMAP_HISTORY_SNAPSHOT_REALTIME';

const OPEN_VIEW_HISTORY_SNAPS_DIALOG = 'OPEN_VIEW_HISTORY_SNAPS_DIALOG';
const CLOSE_VIEW_HISTORY_SNAPS_DIALOG = 'CLOSE_VIEW_HISTORY_SNAPS_DIALOG';

export {
  // thunks
  CREATE_ROADMAP_HISTORY_SNAPSHOT,
  CREATE_ROADMAP_HISTORY_SNAPSHOT_FULFILLED,
  FETCH_ROADMAP_HISTORY_PROJECTS,
  FETCH_ROADMAP_HISTORY_PROJECTS_FULFILLED,
  SELECT_VIEW_SNAP_TO_COMPARE,
  SELECT_VIEW_SNAP_TO_COMPARE_FULFILLED,
  SELECT_VIEW_SNAP_TO_COMPARE_PENDING,
  REMOVE_SELECTED_SNAP_TO_COMPARE,
  REMOVE_SELECTED_SNAP_TO_COMPARE_FULFILLED,
  REMOVE_SELECTED_SNAP_TO_COMPARE_PENDING,
  REMOVE_SELECTED_SNAP_TO_COMPARE_REJECTED,
  UPDATE_ROADMAP_HISTORY_SNAPSHOT,
  UPDATE_ROADMAP_HISTORY_SNAPSHOT_FULFILLED,
  UPDATE_ROADMAP_HISTORY_PENDING,
  UPDATE_ROADMAP_HISTORY_REJECTED,
  FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW,
  FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW_FULFILLED,
  FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW_PENDING,
  FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW_REJECTED,
  DELETE_ROADMAP_HISTORY_SNAPSHOT,
  DELETE_ROADMAP_HISTORY_SNAPSHOT_FULFILLED,
  FETCH_ROADMAP_HISTORY_COMPARE_DATA,
  FETCH_ROADMAP_HISTORY_COMPARE_DATA_FULFILLED,
  FETCH_ROADMAP_HISTORY_COMPARE_DATA_PENDING,
  FETCH_ROADMAP_HISTORY_COMPARE_DATA_REJECTED,
  // actions
  REMOVE_ALL_SELECTED_SNAP_TO_COMPARE,
  OPEN_VIEW_HISTORY_SNAPS_DIALOG,
  CLOSE_VIEW_HISTORY_SNAPS_DIALOG,
  UPDATE_ROADMAP_HISTORY_SNAPSHOT_REALTIME,
};
