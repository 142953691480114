import { useState } from 'react';

import useQueryParam from 'hooks/useQueryParam';
import { addQueryParamToUrl, removeQueryParamFromUrl } from 'utils/queryParamsUtils';
import { METRIC_TAB_QUERY_PARAM } from 'constants/queryParams';

const DETAILS_TAB = 'details';
const LINKED_ITEMS_TAB = 'linkedItems';
const CHART_TAB = 'chart';
const TABS = [DETAILS_TAB, LINKED_ITEMS_TAB, CHART_TAB];

const isValidTab = tab => TABS.includes(tab);

const useMetricsDialogTabs = () => {
  const [activeTab, setActiveTab] = useState(DETAILS_TAB);

  const onTabQueryParamChange = tab => {
    if (isValidTab(tab)) setActiveTab(tab);
  };

  const onTabClick = tab => {
    if (isValidTab(tab)) {
      removeQueryParamFromUrl(METRIC_TAB_QUERY_PARAM);
      addQueryParamToUrl(METRIC_TAB_QUERY_PARAM, tab);
    }
  };

  useQueryParam(METRIC_TAB_QUERY_PARAM, onTabQueryParamChange);

  return {
    activeTab,
    detailsTab: DETAILS_TAB,
    linkedItemsTab: LINKED_ITEMS_TAB,
    chartTab: CHART_TAB,
    onTabClick,
  };
};

export default useMetricsDialogTabs;
