import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import theme, { spacing } from 'design-system/theme';
import NewDialog from 'design-system/organisms/NewDialog/index';

import CreateViewDialog from 'containers/ViewDialog/CreateViewDialog';
import FormScreen from './components/FormScreen';
import PreviewScreen from './components/PreviewScreen';
import LoadingScreen from './components/LoadingScreen';
import { topics } from 'constants/topics';

import useSubscribeNewData from 'hooks/useSubscribeNewData';

const GenerateAiReleaseNotesDialog = ({
  isOpen,
  isLoading,
  releaseNotes,
  saveViewDialogProps,
  editableUserViews,
  onSaveReleaseNotes,
  onGenerateReleaseNotes,
  onSaveUserView,
  onUpdateUserView,
  updateReleaseNotes,
  closeDialog,
  closeSaveViewDialog,
}) => {
  useSubscribeNewData([topics.STREAM_RELEASE_NOTES]);

  const renderDialogContent = useMemo(() => {
    if (isLoading) {
      return <LoadingScreen />;
    }

    if (releaseNotes) {
      return (
        <PreviewScreen
          releaseNotes={releaseNotes}
          updateReleaseNotes={updateReleaseNotes}
          onSave={onSaveReleaseNotes}
          onClose={closeDialog}
        />
      );
    }

    return <FormScreen onClickGenerate={onGenerateReleaseNotes} />;
  }, [isLoading, releaseNotes, onGenerateReleaseNotes, onSaveReleaseNotes]);

  const isDisplayingPreviewPage = !isLoading && !!releaseNotes;

  return (
    <>
      <StyledDialog
        open={isOpen}
        fullWidth
        fullHeight
        width="100%"
        closeButton={!isDisplayingPreviewPage}
        paddingOverride="0px"
        onClose={closeDialog}
        BackdropProps={{
          style: { background: theme.palette.newLayout.background.oneClickPlanMode.gradient },
        }}
        disableBackdropClick
        increaseTopMargin={isDisplayingPreviewPage}
      >
        {renderDialogContent}
      </StyledDialog>
      <CreateViewDialog
        show={saveViewDialogProps?.open}
        onClose={closeSaveViewDialog}
        onSave={onSaveUserView}
        userViews={editableUserViews}
        onUpdate={onUpdateUserView}
      />
    </>
  );
};

const StyledDialog = styled(NewDialog)`
  &&&& {
    div[role='document'] > div {
      height: 100%;

      //When on the Preview screen, we need a bigger top margin to display some content
      ${({ increaseTopMargin, theme }) =>
        increaseTopMargin &&
        css`
          margin-top: ${spacing(10)}px;
          min-height: calc(100% - 128px);
          max-height: calc(100% - 128px);
        `}
    }
  }
`;

export default GenerateAiReleaseNotesDialog;
