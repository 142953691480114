import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import history from 'store/utils/history';

import useSystemFields from 'hooks/useSystemFields';
import { openProjectLightbox } from 'store/projectLightbox';
import Link from 'components/Link';

import JiraUpdateErrorItem from './Components/JiraUpdateErrorItem';
import JiraProjectRenamedWarning from './Components/JiraProjectRenamedWarning';
import { loadAndOpenCustomerRequest } from 'store/customerRequests/actions';
import { openObjectiveDrawer } from 'store/objectives/actions';
import { OBJECT_KEY_RESULT } from 'store/objectives/types';
import { getUserViewTypeForComment } from 'utils/userViews';

const SHOW_COMMENT = 'SHOW_COMMENT';
const SHOW_CUSTOMER_REQUEST_COMMENT = 'SHOW_CUSTOMER_REQUEST_COMMENT';
const SHOW_OBJECTIVE_COMMENT = 'SHOW_OBJECTIVE_COMMENT';
const SHOW_KEY_RESULT_COMMENT = 'SHOW_KEY_RESULT_COMMENT';
const SHOW_USER_VIEW_COMMENT = 'SHOW_USER_VIEW_COMMENT';
const JIRA_UPDATE_ERROR = 'JIRA_UPDATE_ERROR';
const JIRA_PROJECT_RENAMED_WARNING = 'JIRA_PROJECT_RENAMED_WARNING';
const PROJECT_FIELD = 'PROJECT_FIELD';
const PROJECT_HEALTH = 'PROJECT_HEALTH';
const PROJECT_FIELD_DEPS = 'PROJECT_FIELD_DEPS';
const PROJECT_HEALTH_DEPS = 'PROJECT_HEALTH_DEPS';
const SHOW_METRIC_COMMENT = 'SHOW_METRIC_COMMENT';

const NotificationItem = ({ notification, classes, noTrunk }) => {
  const dispatch = useDispatch();
  const [getSystemFieldName] = useSystemFields();

  const _handleClickProject = useCallback(projectId => dispatch(openProjectLightbox(projectId)), []);

  const _handleClickCustomerRequest = useCallback(crKey => {
    history.push('/requests');
    dispatch(loadAndOpenCustomerRequest(crKey));
  }, []);

  const _handleClickObjective = useCallback(objectiveId => {
    if (notification.notification_payload && notification.notification_payload.originSubPage) {
      history.push(notification.notification_payload.originSubPage);
    } else {
      history.push('/settings/objectives');
    }
    dispatch(openObjectiveDrawer(objectiveId));
  }, []);

  const _handleClickKeyResult = useCallback(keyResultId => {
    if (notification.notification_payload && notification.notification_payload.originSubPage) {
      history.push(notification.notification_payload.originSubPage);
    } else {
      history.push('/settings/objectives');
    }
    dispatch(openObjectiveDrawer(keyResultId, OBJECT_KEY_RESULT));
  }, []);

  const _getKeyResultLabel = useCallback(keyResult => {
    return getSystemFieldName(`keyResult${keyResult.level + 1}`);
  }, []);

  const _handleClickUserView = useCallback(notification => {
    if (notification.notification_payload && notification.notification_payload.originSubPage) {
      history.push(notification.notification_payload.originSubPage);
    }
  }, []);

  const _handleClickMetric = useCallback(notification => {
    history.push(`/metrics/grid?openMetric=${notification.metric.id}`);
  }, []);

  const mapFieldToSystemFieldName = {
    timeframe_id: getSystemFieldName('timeframe'),
    phase_id: getSystemFieldName('phase'),
  };

  return (
    <span className={classes.root}>
      {(() => {
        switch (notification.notification_type) {
          case SHOW_COMMENT: {
            return (
              <StyledItem>
                <span className={classes.bold}>
                  {notification.user ? `${notification.user.first_name || ''} ${notification.user.last_name || ''}` : 'Someone'}
                </span>{' '}
                commented on{' '}
                {notification.project ? (
                  <Link onClick={() => _handleClickProject(notification.project.id)} role="button" tabIndex={0}>
                    {`${notification.project.title} (DB-${notification.project.id})`}
                  </Link>
                ) : (
                  'some project'
                )}
              </StyledItem>
            );
          }
          case SHOW_CUSTOMER_REQUEST_COMMENT: {
            return (
              <StyledItem>
                <span className={classes.bold}>
                  {notification.user ? `${notification.user.first_name || ''} ${notification.user.last_name || ''}` : 'Someone'}
                </span>{' '}
                commented on{' '}
                {notification.customer_request ? (
                  <Link onClick={() => _handleClickCustomerRequest(notification.customer_request.key)} role="button" tabIndex={0}>
                    {`${notification.customer_request.title} (CR-${notification.customer_request.key})`}
                  </Link>
                ) : (
                  'some customer request'
                )}
              </StyledItem>
            );
          }
          case SHOW_OBJECTIVE_COMMENT: {
            return (
              <StyledItem>
                <span className={classes.bold}>
                  {notification.user ? `${notification.user.first_name || ''} ${notification.user.last_name || ''}` : 'Someone'}
                </span>{' '}
                commented on{' '}
                {notification.objective ? (
                  <Link onClick={() => _handleClickObjective(notification.objective.id)} role="button" tabIndex={0}>
                    {`${notification.objective.title} (${getSystemFieldName('objective')} - ${notification.objective.id})`}
                  </Link>
                ) : (
                  'some OKR'
                )}
              </StyledItem>
            );
          }
          case SHOW_KEY_RESULT_COMMENT: {
            return (
              <StyledItem>
                <span className={classes.bold}>
                  {notification.user ? `${notification.user.first_name || ''} ${notification.user.last_name || ''}` : 'Someone'}
                </span>{' '}
                commented on{' '}
                {notification.key_result ? (
                  <Link onClick={() => _handleClickKeyResult(notification.key_result.id)} role="button" tabIndex={0}>
                    {`${notification.key_result.title} (${_getKeyResultLabel(notification.key_result)} - ${
                      notification.key_result.id
                    })`}
                  </Link>
                ) : (
                  'some OKR'
                )}
              </StyledItem>
            );
          }
          case SHOW_USER_VIEW_COMMENT: {
            return (
              <StyledItem>
                <span className={classes.bold}>
                  {notification.user ? `${notification.user.first_name || ''} ${notification.user.last_name || ''}` : 'Someone'}
                </span>{' '}
                commented on {getUserViewTypeForComment(notification.user_view)}{' '}
                {notification.user_view ? (
                  <Link onClick={() => _handleClickUserView(notification)} role="button" tabIndex={0}>
                    {`${notification.user_view.name} - ${notification.user_view.id}`}
                  </Link>
                ) : (
                  'some View'
                )}
              </StyledItem>
            );
          }
          case SHOW_METRIC_COMMENT: {
            return (
              <StyledItem>
                <span className={classes.bold}>
                  {notification.user ? `${notification.user.first_name || ''} ${notification.user.last_name || ''}` : 'Someone'}
                </span>{' '}
                commented on Metric{' '}
                {notification.metric ? (
                  <Link onClick={() => _handleClickMetric(notification)} role="button" tabIndex={0}>
                    {`${notification.metric.name} - ${notification.metric.id}`}
                  </Link>
                ) : (
                  'some Metric'
                )}
              </StyledItem>
            );
          }
          case PROJECT_FIELD: {
            return (
              <StyledItem>
                Just wanted to let you know that{' '}
                {notification.project ? (
                  <Link onClick={() => _handleClickProject(notification.project.id)} role="button" tabIndex={0}>
                    {`${notification.project.title} (DB-${notification.project.id})`}
                  </Link>
                ) : (
                  'some project'
                )}{' '}
                has changed{' '}
                {mapFieldToSystemFieldName[notification.notification_payload.field] ||
                  notification.notification_payload.field_name}{' '}
                from <span className={classes.bold}>{notification.notification_payload.prev_value || 'Undefined'}</span> to{' '}
                <span className={classes.bold}>{notification.notification_payload.new_value || 'Undefined'}</span>.
              </StyledItem>
            );
          }
          case PROJECT_FIELD_DEPS: {
            return (
              <StyledItem>
                Just wanted to let you know that{' '}
                {notification.projects ? (
                  <Link onClick={() => _handleClickProject(notification.projects[0].id)} role="button" tabIndex={0}>
                    {`${notification.projects[0].title} (DB-${notification.projects[0].id})`}
                  </Link>
                ) : (
                  'some project'
                )}
                , which is dependent of{' '}
                {notification.projects ? (
                  <Link onClick={() => _handleClickProject(notification.projects[1].id)} role="button" tabIndex={0}>
                    {`${notification.projects[1].title} (DB-${notification.projects[1].id})`}
                  </Link>
                ) : (
                  'other project'
                )}{' '}
                has changed{' '}
                {mapFieldToSystemFieldName[notification.notification_payload.field] ||
                  notification.notification_payload.field_name}{' '}
                from <span className={classes.bold}>{notification.notification_payload.prev_value || 'Undefined'}</span> to{' '}
                <span className={classes.bold}>{notification.notification_payload.new_value || 'Undefined'}</span>.
              </StyledItem>
            );
          }
          case PROJECT_HEALTH: {
            return (
              <StyledItem>
                It seems{' '}
                {notification.project ? (
                  <Link onClick={() => _handleClickProject(notification.project.id)} role="button" tabIndex={0}>
                    {`${notification.project.title} (DB-${notification.project.id})`}
                  </Link>
                ) : (
                  'some project'
                )}{' '}
                just turned <span className={classes.bold}>{notification.notification_payload.new_value || 'Undefined'}</span>.
              </StyledItem>
            );
          }
          case PROJECT_HEALTH_DEPS: {
            return (
              <StyledItem>
                It seems{' '}
                {notification.projects.length && notification.projects[0] ? (
                  <Link onClick={() => _handleClickProject(notification.projects[0].id)} role="button" tabIndex={0}>
                    {`${notification.projects[0].title} (DB-${notification.projects[0].id})`}
                  </Link>
                ) : (
                  'some project'
                )}
                , which is dependent of{' '}
                {notification.projects.length && notification.projects[1] ? (
                  <Link onClick={() => _handleClickProject(notification.projects[1].id)} role="button" tabIndex={0}>
                    {`${notification.projects[1].title} (DB-${notification.projects[1].id})`}
                  </Link>
                ) : (
                  'other project'
                )}{' '}
                just turned <span className={classes.bold}>{notification.notification_payload.new_value || 'Undefined'}</span>.
              </StyledItem>
            );
          }
          case JIRA_UPDATE_ERROR: {
            return <JiraUpdateErrorItem {...notification.notification_payload} />;
          }
          case JIRA_PROJECT_RENAMED_WARNING: {
            return <JiraProjectRenamedWarning {...JSON.parse(notification.notification_payload)} truncateLines="10" />;
          }
          default: {
            return <span />;
          }
        }
      })()}
    </span>
  );
};

export default withStyles(theme => ({
  root: {},
  bold: {
    fontWeight: 700,
  },
  linkColor: {
    color: theme.palette.primary,
    fontWeight: 700,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}))(NotificationItem);

const StyledItem = styled.div`
  &&&& {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
  }
`;
