import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PageLink from 'design-system/atoms/PageLink/index';


import { INTEGRATIONS_NAMES, INTEGRATIONS_DOCS_LINKS } from 'constants/integrations';

import getSystemFieldNameUtil from 'utils/getSystemFieldName';
import { getTypeNameBasedOnLayer } from 'constants/projectLightbox';
import { integrationLayerNameOrDefault } from 'routes/Settings/Integration/IntegrationLayerNames/helpers';

const Actions = props => {
  const { integrationType, systemFields, layer, integrationLayerNames } = props;

  const integrationNameLabel = INTEGRATIONS_NAMES[integrationType];
  const mappingFieldsDocsLink = INTEGRATIONS_DOCS_LINKS[integrationType].MAPPING_FIELDS_FIELDS_SYNC;

  const getSystemFieldName = name => getSystemFieldNameUtil(name, systemFields);

  const integrationLayerName = integrationLayerNameOrDefault(layer, integrationLayerNames);
  const title = [
    `Map ${integrationNameLabel} ${integrationLayerName} Fields`,
    `to dragonboat ${getSystemFieldName(getTypeNameBasedOnLayer(layer))} Fields`,
  ].join(' ');

  return (
    <Wrapper>
      {title}{' '}
      <PageLink href={mappingFieldsDocsLink} target="_blank" size="medium">
        (See how)
      </PageLink>
    </Wrapper>
  );
};

Actions.propTypes = {
  systemFields: PropTypes.array.isRequired,
  integrationType: PropTypes.string.isRequired,
};

const Wrapper = styled.span``;

export default Actions;
