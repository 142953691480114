import reduceReducers from 'reduce-reducers';

import sortByRank from 'utils/sortByRank';
import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  ADD_METRIC_WITHOUT_SAVE,
  CREATE_METRIC_FULFILLED,
  FETCH_METRICS_FULFILLED,
  UPDATE_METRIC_BY_ID_FULFILLED,
  REMOVE_UNSAVED_METRICS,
  SWITCH_METRICS_ROW_ORDER,
  SWITCH_METRICS_ROW_ORDER_FULFILLED,
  SWITCH_METRICS_ROW_ORDER_PENDING,
  SWITCH_METRICS_ROW_ORDER_REJECTED,
  CREATE_METRIC_VALUE,
  DELETE_METRIC_VALUE,
  UPDATE_METRIC_VALUE,
  ADD_METRIC_VALUE_WITHOUT_SAVE,
  REMOVE_UNSAVED_METRIC_VALUES,
  UPDATE_METRICS_SEARCH_TEXT,
  UPDATE_IS_HIDING_ARCHIVED_METRICS,
  CREATE_METRIC_ROADMAP_FULFILLED,
  DELETE_METRIC_ROADMAP_FULFILLED,
  BULK_DELETE_METRIC_ROADMAP_FULFILLED,
  CREATE_METRIC_ROADMAP,
  DELETE_METRIC_ROADMAP,
  BULK_DELETE_METRIC_ROADMAP,
  UPDATE_TABLE_VISIBLE_FIELDS,
  UPDATE_METRIC_CLUSTERS_VISIBILITY,
  UPDATE_METRIC_VIEW_MODE_TIME_PERIOD,
  UPDATE_METRIC_CHARTS_LAYOUT,
  UPDATE_METRIC_CHARTS_LAYOUT_EDIT_MODE,
  UPDATE_METRIC_MOAR_VISIBILITY,
  UPDATE_CHART_VISIBLE_LAYERS,
  FETCH_METRIC_INTEGRATION_FILTER_OPTIONS,
  FETCH_METRIC_INTEGRATION_FILTER_OPTIONS_FULFILLED,
} from './types';
import {
  CREATE_METRIC_ROADMAP_LIGHTBOX_FULFILLED,
  DELETE_METRIC_ROADMAP_LIGHTBOX_FULFILLED,
  BULK_DELETE_METRIC_ROADMAP_LIGHTBOX_FULFILLED,
} from 'features/MetricsDialog/store/types';

import upsertListItem from '../utils/upsertListItem';
import cloneDeep from 'lodash/cloneDeep';
import {
  CHART_LAYER_ACTUAL_METRIC,
  CHART_LAYER_COMPLETED_ALLOCATION,
  CHART_LAYER_PLANNED_ALLOCATION,
  CHART_LAYER_REPORTED_ALLOCATION,
  CHART_LAYER_TARGET_METRIC,
} from './chartLayers';

const DEFAULT_METRIC_COLOR = '#ccc';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  CREATE_METRIC_VALUE,
  UPDATE_METRIC_VALUE,
  SWITCH_METRICS_ROW_ORDER,
  DELETE_METRIC_VALUE,
  CREATE_METRIC_ROADMAP,
  DELETE_METRIC_ROADMAP,
  BULK_DELETE_METRIC_ROADMAP,
  FETCH_METRIC_INTEGRATION_FILTER_OPTIONS,
]);

const initialState = {
  metrics: [],
  searchText: '',
  isHidingArchivedMetrics: true,
  tableVisibleFields: ['Name', 'Applies To', 'Status', 'Details', 'Owner', 'Health'],
  metricClustersVisible: false,
  metricViewModeTimePeriod: {},
  chartsLayout: null,
  inEditMode: false,
  metricMoarVisible: false,
  chartVisibleLayers: [
    CHART_LAYER_ACTUAL_METRIC,
    CHART_LAYER_TARGET_METRIC,
    CHART_LAYER_PLANNED_ALLOCATION,
    CHART_LAYER_COMPLETED_ALLOCATION,
    CHART_LAYER_REPORTED_ALLOCATION,
  ],
  metricIntegrationFilterOptions: {},
  ...thunksInitialState,
};

function updateMetric(metrics, metric) {
  return (metrics || []).map(m => (m.id === metric.id ? metric : m));
}

function removeUnsaved(list) {
  return list.filter(el => el.id);
}

function getSelectedMetric(metrics, id) {
  return metrics.find(m => m.id === id);
}

function metricsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_METRICS_FULFILLED: {
      return {
        ...state,
        metrics: action.payload.map(m => ({ ...m, color: m.color || DEFAULT_METRIC_COLOR })),
      };
    }
    case CREATE_METRIC_FULFILLED:
      if (!action.payload || !action.payload.id) {
        return state;
      }

      let metrics = state.metrics || [];

      metrics = removeUnsaved(metrics);
      metrics = upsertListItem(action.payload, metrics);

      return {
        ...state,
        metrics,
      };
    case UPDATE_METRIC_BY_ID_FULFILLED: {
      return {
        ...state,
        metrics: updateMetric(state.metrics, action.payload),
      };
    }
    case ADD_METRIC_WITHOUT_SAVE: {
      const metrics = state.metrics ? removeUnsaved(state.metrics) : [];

      metrics.unshift(action.metric || {});

      return {
        ...state,
        metrics,
      };
    }
    case REMOVE_UNSAVED_METRICS:
      return {
        ...state,
        metrics: state.metrics.filter(metric => metric.id),
      };

    case SWITCH_METRICS_ROW_ORDER_REJECTED: {
      if (!action.meta || !action.meta.prev) return state;

      let metrics = state.metrics ? state.metrics.slice(0) : [];

      metrics = upsertListItem(action.meta.prev, metrics);

      return {
        ...state,
        metrics: metrics.sort(sortByRank),
      };
    }
    case SWITCH_METRICS_ROW_ORDER_PENDING: {
      if (!action.meta || !action.meta.row) {
        return state;
      }

      let metrics = state.metrics ? state.metrics.slice(0) : [];

      metrics = upsertListItem(action.meta.row, metrics);

      return {
        ...state,
        metrics: metrics.sort(sortByRank),
      };
    }
    case SWITCH_METRICS_ROW_ORDER_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        console.error('SWITCH_METRICS_ROW_ORDER_FULFILLED::Metric passed does not have id');
        return state;
      }

      let metrics = state.metrics ? state.metrics.slice(0) : [];

      metrics = upsertListItem(action.payload, metrics);

      return {
        ...state,
        metrics: metrics.sort(sortByRank),
      };
    }
    case ADD_METRIC_VALUE_WITHOUT_SAVE:
      const metric = getSelectedMetric(state.metrics, action.metricId);

      if (!metric) return state;

      const cleanedMetricValues = removeUnsaved(metric?.metricValues || []);

      cleanedMetricValues.unshift(action.metricValue);

      metric.metricValues = cleanedMetricValues;
      const newState = updateMetric(state.metrics, metric);

      return {
        ...state,
        metrics: newState,
      };
    case REMOVE_UNSAVED_METRIC_VALUES:
      const cleanedMetrics = state.metrics.map(m => {
        return {
          ...m,
          metricValues: removeUnsaved(m?.metricValues || []),
        };
      });

      return {
        ...state,
        metrics: cleanedMetrics,
      };

    case UPDATE_METRICS_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case UPDATE_IS_HIDING_ARCHIVED_METRICS: {
      return {
        ...state,
        isHidingArchivedMetrics: !!action.payload,
      };
    }
    case CREATE_METRIC_ROADMAP_FULFILLED:
    case CREATE_METRIC_ROADMAP_LIGHTBOX_FULFILLED:
      const currentMetrics = cloneDeep(state.metrics);
      const actionData = action.payload.data;
      const foundMetric = currentMetrics.find(obj => obj.id === actionData.metric_id);

      foundMetric.metric_roadmaps = [...(foundMetric?.metric_roadmaps || []), actionData];
      foundMetric.updated_at = action.payload.updated_at;

      const updatedMetrics = upsertListItem(foundMetric, currentMetrics);

      return {
        ...state,
        metrics: updatedMetrics,
      };
    case DELETE_METRIC_ROADMAP_FULFILLED:
    case DELETE_METRIC_ROADMAP_LIGHTBOX_FULFILLED:
      const existingMetrics = cloneDeep(state.metrics);
      const metricId = action.meta?.metricId;

      const parent = existingMetrics.find(obj => obj.id === parseInt(metricId));

      parent.metric_roadmaps = action?.payload?.data || [];

      const updated = upsertListItem(parent, existingMetrics);

      return {
        ...state,
        metrics: updated,
      };
    case BULK_DELETE_METRIC_ROADMAP_FULFILLED:
    case BULK_DELETE_METRIC_ROADMAP_LIGHTBOX_FULFILLED:
      const stateMetrics = cloneDeep(state.metrics);
      const metricsUpdated = stateMetrics.map(metric => {
        if (metric.id === action.meta?.metricId) {
          return {
            ...metric,
            metric_roadmaps: [],
          };
        }

        return metric;
      });

      return {
        ...state,
        metrics: metricsUpdated,
      };
    case UPDATE_TABLE_VISIBLE_FIELDS: {
      return {
        ...state,
        tableVisibleFields: action.payload,
      };
    }
    case UPDATE_METRIC_CLUSTERS_VISIBILITY: {
      return {
        ...state,
        metricClustersVisible: action.payload,
      };
    }
    case UPDATE_METRIC_MOAR_VISIBILITY: {
      return {
        ...state,
        metricMoarVisible: action.payload,
      };
    }
    case UPDATE_CHART_VISIBLE_LAYERS: {
      if (!Array.isArray(action.payload)) return state;

      return {
        ...state,
        chartVisibleLayers: action.payload,
      };
    }
    case UPDATE_METRIC_VIEW_MODE_TIME_PERIOD: {
      return {
        ...state,
        metricViewModeTimePeriod: action.payload,
      };
    }
    case UPDATE_METRIC_CHARTS_LAYOUT: {
      return {
        ...state,
        chartsLayout: action.payload,
      };
    }
    case UPDATE_METRIC_CHARTS_LAYOUT_EDIT_MODE: {
      return {
        ...state,
        editingChartsLayout: action.payload,
      };
    }
    case FETCH_METRIC_INTEGRATION_FILTER_OPTIONS_FULFILLED: {
      const { orgIntegrationId } = action.meta;

      return {
        ...state,
        metricIntegrationFilterOptions: {
          ...state.metricIntegrationFilterOptions,
          [orgIntegrationId]: action.payload,
        },
      };
    }
    default:
      return state;
  }
}

export { initialState };

const reducer = reduceReducers(initialState, metricsReducer, ...thunksReducers);

export default reducer;
