import { DASHBOARD_HOME_PATHNAME } from 'hooks/useApplicationRoutes/consts';
import useQueryParam from 'hooks/useQueryParam';
import { useEffect, useState } from 'react';

const useExploreOpenPendoGuide = () => {
  const [pendoIsReady, setPendoIsReady] = useState(false);
  const [exploreGuideId, setExploreGuideId] = useState(null);
  const [readyToRedirect, setReadyToRedirect] = useState(false);

  useQueryParam('id', setExploreGuideId);

  useEffect(() => {
    return window.pendo?.events?.guidesLoaded(() => {
      setPendoIsReady(true);
    });
  }, []);

  if (!pendoIsReady || !exploreGuideId) return null;

  const activeGuide = window.pendo.getActiveGuide();

  if (readyToRedirect) {
    window.location.href = DASHBOARD_HOME_PATHNAME;
    return;
  }

  if (activeGuide?.guide?.id === exploreGuideId) return;

  window.pendo.onGuideDismissed();

  const pendoGuideIsAvailable = Boolean(window.pendo.findGuideById(exploreGuideId));

  if (pendoGuideIsAvailable) {
    window.pendo.showGuideById(exploreGuideId);

    // Check if the guide is still active
    // It seems we can't subscribe a pendo event for that
    setInterval(() => {
      if (!window.pendo.getActiveGuide()) {
        setReadyToRedirect(true);
      }
    }, 200);
  }
};

export default useExploreOpenPendoGuide;
