// External dependencies
import React, { useState } from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// Dragonboat dependencies
import Autocomplete from 'design-system/atoms/Autocomplete/index';
import WYSIWYGEditorField from 'design-system/molecules/WYSIWYGEditorField/index';

import ChangeOwnerDialog from './ChangeOwnerDialog';
import { HEIGHT_3_ROWS, HEIGHT_6_ROWS } from 'design-system/molecules/RemirrorWYSIWYGEditor/constants';

const Details = ({
  onClose,
  onUpdate,
  userViews,
  view,
  viewDescription,
  setViewDescription,
  onUpdateViewOwner,
  onUpdateViewOwnerAllViews,
  showUpdateOwner = false,
  ownerUserOptions = [],
  disableEditDetails = false,
}) => {
  const [selectedView, setSelectedView] = useState('');
  const [selectedViewOwner, setSelectedViewOwner] = useState(null);
  const [isChangeOwnerDialogOpen, setIsChangeOwnerDialogOpen] = useState(false);

  const handleToggleAfterUpdateOwner = () => {
    setIsChangeOwnerDialogOpen(false);
    setSelectedViewOwner(null);
    onClose();
  };

  const updateSelectedViewOwner = id => {
    const newOwner = ownerUserOptions.find(user => user.id === id);

    if (newOwner) {
      setSelectedViewOwner(newOwner);
    }
  };

  return (
    <>
      <DialogWrapper>
        <WYSIWYGEditorField
          key="view-description"
          value={viewDescription}
          content={viewDescription}
          placeholder="Enter a brief description of the purpose of this view and how to use it"
          callOnChangeWhenTyping
          onChange={setViewDescription}
          disableEdit={disableEditDetails}
          smallInput={false}
          minHeight={HEIGHT_3_ROWS}
          maxHeight={HEIGHT_6_ROWS}
        />
        {!view && (
          <Grid container style={{ marginTop: 30 }}>
            <Grid item xs={9}>
              <Select onChange={e => setSelectedView(e.target.value)} value={selectedView} displayEmpty fullWidth>
                <MenuItem value="" disabled>
                  Update an existing view
                </MenuItem>
                {userViews &&
                  userViews.map(option => {
                    return (
                      <MenuItem key={option.name} value={option.id}>
                        {option.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button
                onClick={() => {
                  const viewToUpdate = userViews.find(uv => uv.id === selectedView);

                  onUpdate(viewToUpdate);
                  onClose();
                  setSelectedView('');
                }}
                disabled={!selectedView}
                color="primary"
              >
                Update
              </Button>
            </Grid>
          </Grid>
        )}
        {view && showUpdateOwner && (
          <Grid container style={{ marginTop: 30 }}>
            <Grid item xs={9} style={{ display: 'flex', alignItems: 'center' }}>
              <StyledAutocomplete
                placeholder="Update Owner"
                fullWidth
                value={selectedViewOwner?.name}
                suggestions={ownerUserOptions}
                icon={<ArrowDropDownIcon />}
                onValueChange={updateSelectedViewOwner}
              />
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Button onClick={() => setIsChangeOwnerDialogOpen(true)} color="primary" disabled={!selectedViewOwner}>
                Update
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogWrapper>
      <ChangeOwnerDialog
        isOpen={isChangeOwnerDialogOpen}
        onCancel={() => setIsChangeOwnerDialogOpen(false)}
        onChangeOwnerForAllViews={() => {
          onUpdateViewOwnerAllViews(view.user_id, selectedViewOwner?.id);
          handleToggleAfterUpdateOwner();
        }}
        onChangeOwnerForSingleView={() => {
          onUpdateViewOwner(view.id, selectedViewOwner?.id);
          handleToggleAfterUpdateOwner();
        }}
        ownerUserOptions={ownerUserOptions}
        selectedViewOwnerId={selectedViewOwner?.id}
        view={view}
      />
    </>
  );
};

const DialogWrapper = styled.div``;

const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
`;

export default Details;
