import React, { useEffect } from 'react';
import { either, isEmpty, isNil, not, pipe } from 'ramda';

import Button from '@material-ui/core/Button';
import { useImportMetricValues } from './hooks';
import { useDispatch } from 'react-redux';
import { subscribeToIntegrationError } from '../../OneStepIntegration/store/listenerMiddleware';

const isNilOrEmpty = either(isNil, isEmpty);
const isNotNilOrEmpty = pipe(isNilOrEmpty, not);

const ImportMetricValues = props => {
  const dispatch = useDispatch();
  const { metricId, metricIntegrations } = props;

  const { isImportingMetricValues, onImportMetricValues } = useImportMetricValues(metricId, metricIntegrations);

  const hasMetricIntegration = isNotNilOrEmpty(metricIntegrations);

  useEffect(() => {
    const unsubscribeRefreshData = dispatch(subscribeToIntegrationError());

    return unsubscribeRefreshData;
  }, []);

  return hasMetricIntegration ? (
    <Button color="primary" onClick={onImportMetricValues} disabled={isImportingMetricValues}>
      Update
    </Button>
  ) : null;
};

export default ImportMetricValues;
