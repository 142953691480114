/*
 * In some cases we have a difference of 0.0000000000001 on the original x and the x of the element on click.
 * Probably a re-calc of the lib
 * */
const pixelDiffThreshold = 0.0000000000002;

const isValidDifference = (valA, ValB) => valA === ValB || (valA && ValB && Math.abs(valA - ValB) < pixelDiffThreshold);

/**
 * @function checkIfClickWasNotDrag given a delta and the initial left and top position verify
 * if the click corresponds to the end of the drag or not.
 *
 * with React-Rnd a click is considered as a drag end in react-rnd, is likely caused by the default configuration of the
 * react-draggable library, which is used internally by react-rnd for the dragging functionality.
 *
 * By default, react-draggable treats a click event as the end of a drag operation.
 * This behavior is intentional and designed to provide a smooth and intuitive user experience.
 *
 * @param  {Object} delta
 * @param  {Number} left
 * @param  {Number} top
 * @return {Boolean} checks if the element was dragged or not
 */
const isClickEventNoDragEnd = (delta, left, top) => {
  return isValidDifference(delta?.x, left) && isValidDifference(delta?.y, top);
};

export default isClickEventNoDragEnd;
