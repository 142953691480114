/* eslint jsx-a11y/anchor-is-valid: 0 */
// External dependencies
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

// Dragonboat dependencies
import { searchForTasksByJiraUrl, previewTasksByJiraUrl } from 'store/ideas';
import { removeDuplicates as uniq, getUserName } from 'utils';
import { bulkCreateProjects, bulkUpdateProjects } from 'store/projects';
import { syncJiraStories, createAllProjectsJiras, updateJiraConfigMisc, syncOrgProjectsFromJira } from 'store/integrations';
import { getPriorities } from 'store/priorities/selectors';
import { getPageFilters } from 'store/filters/selectors';
import getFiltersToFetchProjects from 'utils/getFiltersToFetchProjects';
import { GLOBAL_FILTER } from 'constants/filters';
import { updateState } from 'store/ideasBoard';
import getSystemFieldName from 'utils/getSystemFieldName';
import {
  getOrgHasJiraIntegrated,
  getOrgJiraIntegrations,
  getOrgHasJiraFieldMappingByProject,
} from 'store/organization/selectors';
import { getProjectsCustomFields } from 'store/customFields/selectors';

import ImportFromJQL from './ImportFromJQL';
import { getCorpAccountRoadmap } from 'store/roadmaps/selectors';
import { getTeamsTitles } from 'store/teams/selectors';

function mapStateToProps(state) {
  const {
    organization: { organization },
    users: usersState,
    objectives,
    phases,
    themes,
    categories,
    roadmaps: roadmapsState,
    timeframes: timeframesState,
  } = state;

  const users = usersState?.rows ? uniq(usersState?.rows) : [];
  const jiraIntegrated = getOrgHasJiraIntegrated(state);
  const jiraOrgIntegrations = getOrgJiraIntegrations(state);
  const productOwners = users ? users.map(getUserName) : [];
  const objectiveTitles = objectives.objectives ? objectives.objectives.map(objective => objective.title) : [];
  const priorities = getPriorities(state);
  const timeframes = timeframesState.has('rows')
    ? timeframesState
        .get('rows')
        .toJS()
        .map(({ title, id, status }) => ({ title, id, status }))
    : [];
  const roadmaps = roadmapsState.roadmaps ? roadmapsState.roadmaps.map(({ title, id }) => ({ title, id })) : [];
  const timeframesTitles = timeframes ? timeframes.map(timeframe => timeframe.title) : [];
  const roadmapsTitles = roadmaps ? roadmaps.map(roadmap => roadmap.title) : [];
  const globalFilters = getFiltersToFetchProjects(getPageFilters(state), GLOBAL_FILTER);
  const corpAccountName = getCorpAccountRoadmap(state)?.title;
  const hasJiraFieldMappingByProject = getOrgHasJiraFieldMappingByProject(state);

  const teamsTitles = getTeamsTitles(state);

  return {
    productOwners,
    priorities,
    objectiveTitles,
    teamsTitles,
    phaseTitles: phases.phases.map(p => p.title),
    themeTitles: themes.themes.map(t => t.title),
    categoryTitles: categories.categories.map(c => c.title),
    timeframesTitles,
    roadmapsTitles,
    jiraIntegrated,
    orgIntegrations: jiraOrgIntegrations,
    roadmaps,
    objectives: objectives.objectives ? objectives.objectives : [],
    organization,
    customFields: getProjectsCustomFields(state),
    globalFilters,
    getSystemFieldName: (name, plural) => getSystemFieldName(name, organization.system_fields_name, plural),
    corpAccountName,
    hasJiraFieldMappingByProject,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    searchForTasksByJiraUrl: (orgIntegrationId, url) => dispatch(searchForTasksByJiraUrl(orgIntegrationId, url)),
    previewTasksByJiraUrl: (orgIntegrationId, jiraUrl, fields) =>
      dispatch(previewTasksByJiraUrl(orgIntegrationId, jiraUrl, fields)),
    insertJirasForProjects: (orgIntegrationId, jiraUrl, socketRoom) =>
      dispatch(createAllProjectsJiras(orgIntegrationId, jiraUrl, socketRoom)),
    bulkCreate: projects => dispatch(bulkCreateProjects(projects)),
    bulkUpdate: projects => dispatch(bulkUpdateProjects(projects)),
    updateJiraConfigMisc: (orgIntegrationId, data) => dispatch(updateJiraConfigMisc(orgIntegrationId, data)),
    syncJiraStories: (orgIntegrationId, epicKeys, socket, isJQLImport) =>
      dispatch(syncJiraStories(orgIntegrationId, epicKeys, socket, isJQLImport)),
    updateBoardState: state => dispatch(updateState(state)),
    syncOrgProjectsFromJira: orgIntegrationId => dispatch(syncOrgProjectsFromJira(orgIntegrationId)),
  };
}
export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(ImportFromJQL);
