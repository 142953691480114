import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isSomeUpdateOnProjectsOccurring } from 'features/whiteboard/store/selectors';
import { updateMetadataEntitiesOnWhiteboard } from 'features/whiteboard/store/thunks';
import { subscribeToRefreshMetadataOnPortfolioCanvas } from 'features/whiteboard/store/listenerMiddleware';

/**
 * Temporary approach, to be discussed. Maybe here we could follow redux-saga approach to attach the dispatch of
 * on the actions that affect the data seen on the whiteboard
 */
const useUpdateProjectOnWhiteboard = () => {
  const isUpdatingProject = useSelector(isSomeUpdateOnProjectsOccurring);
  const isLoadingRef = useRef(isUpdatingProject);
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = dispatch(subscribeToRefreshMetadataOnPortfolioCanvas());

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (isUpdatingProject && !isLoadingRef?.current) {
      isLoadingRef.current = isUpdatingProject;
    } else if (!isUpdatingProject && isLoadingRef?.current) {
      isLoadingRef.current = false;
      dispatch(updateMetadataEntitiesOnWhiteboard());
    }
  }, [isUpdatingProject]);
};

export default useUpdateProjectOnWhiteboard;
