import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sum } from 'ramda';

import { getProjectStories } from 'store/integrations';
import { getOrganization, getOrganizationIntegrations } from 'store/organization/selectors';
import { getGroupOptionsSelector } from 'store/projects/groupSelectors';
import { getGroupOptionByKey } from 'store/projects/helpers/groupOptions';
import useDeepEffect from 'hooks/useDeepEffect';
import useProjectsForGantt from 'hooks/timeline/useProjectsForGantt';
import useCollapsedTasks from 'hooks/timeline/useCollapsedTasks';
import useTodayMarker from 'hooks/timeline/useTodayMarker';
import useInitTimelineGantt from 'hooks/timeline/useInitTimelineGantt';
import useLazyLoadStories from 'hooks/timeline/useLazyLoadStories';
import useProjectsLocalSearch from 'hooks/projects/useProjectsLocalSearch';

import TimelineConfig from './helpers/TimelineConfig';
import useTimelineProjectsGantt from 'containers/GanttTimeline/useTimelineProjects';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';

import { filterGroupedByAll } from './helpers/utils';
import useLoadProjectsForTimeline from './hooks/useLoadProjectsForTimeline';
import useTimelineLightbox from './hooks/useTimelineLightbox';

const componentHOC = Component => {
  return props => {
    const {
      groupedBy,
      lazyLoadProjects,
      getLazyLoadProjectsSubFilter = () => {},
      pageId,
      isGoalMode,
      groupBySelection,
      showAllocationColumns,
      openItems = [],
    } = props;

    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [zoom, setZoom] = useState(true);
    const organization = useSelector(state => getOrganization(state));
    const totalByLayer = useSelector(state => state.projects.totalByLayer);

    const { displayLayer, getColumns, hasBets, isPortfolioMode, lsState, portfolioMode, updateState } = useTimelineLightbox(
      isGoalMode,
      showAllocationColumns,
    );

    groupedBy.ideas = groupedBy.ideas || [];

    const groupingOptions = useMemo(
      () => ({
        withNullOption: true,
        portfolioMode: true,
      }),
      [],
    );
    const groupByOptions = useSelector(state => getGroupOptionsSelector(state, groupingOptions));

    const updateTodayMaker = useTodayMarker();

    const selectedGroup = getGroupOptionByKey((lsState.selectedGroup || { key: null })?.key, groupByOptions);

    const setSelectedGroup = group => updateState({ selectedGroup: group });
    const orgIntegrations = useSelector(getOrganizationIntegrations);

    const { ideas, initiatives, bets, projectsForGroup, isLoaded } = useLoadProjectsForTimeline(
      pageId,
      groupedBy,
      lazyLoadProjects,
      getLazyLoadProjectsSubFilter(),
      isGoalMode,
    );

    useEffect(() => {
      if (groupBySelection?.key) {
        setSelectedGroup(groupBySelection);
      }
    }, [groupBySelection?.key]);

    const { visibleIdeas, visibleInitiatives, visibleBets } = useMemo(
      () => filterGroupedByAll(displayLayer, isPortfolioMode, projectsForGroup, ideas, initiatives, bets, organization.has_bet),
      [displayLayer, isPortfolioMode, projectsForGroup, ideas, initiatives, bets],
    );

    const projectsInAllLayers = [...visibleIdeas, ...visibleInitiatives, ...(hasBets ? visibleBets : [])];
    const [filterProjects] = useProjectsLocalSearch(projectsInAllLayers, search);

    const projects = useMemo(() => {
      switch (displayLayer) {
        case IDEA_LAYER:
          return visibleIdeas;
        case INITIATIVE_LAYER:
          return visibleInitiatives;
        case BET_LAYER:
          return visibleBets;
        default:
          return visibleIdeas;
      }
    }, [ideas, initiatives, bets, displayLayer]);

    const [tasks] = useProjectsForGantt(pageId, {
      groupBy: [selectedGroup],

      projects: filterProjects ? projects.filter(filterProjects) : projects,
      ideas: filterProjects ? visibleIdeas.filter(filterProjects) : visibleIdeas,
      initiatives: filterProjects ? visibleInitiatives.filter(filterProjects) : visibleInitiatives,
      // only if organization has bets
      ...(hasBets ? { bets: filterProjects ? visibleBets.filter(filterProjects) : visibleBets } : {}),

      portfolioMode,
      showEstimates: !isPortfolioMode,
      showTasks: !isPortfolioMode,

      // always show stories if the integration exists
      showStories: {
        jira: orgIntegrations.some(i => i.integrationType === 'JIRA'),
        clubhouse: orgIntegrations.some(i => i.integrationType === 'clubhouse'),
        github: orgIntegrations.some(i => i.integrationType === 'github'),
        azuredevops: orgIntegrations.some(i => i.integrationType === 'azuredevops'),
      },
      showId: false,
      ignoreProjectsWithNoParent: true,
      showJiraKey: false,
      coloredGroups: true,
      searchStr: search,
    });

    const onInitGantt = ganttInstance => {
      const columns = getColumns();

      TimelineConfig(ganttInstance, zoom, columns);

      ganttInstance.attachEvent('onTaskOpened', id => {
        const task = ganttInstance.getTask(id);

        if (task.dbType === 'project') {
          _loadProjectStories(id);
        }
      });
    };
    const onRefreshGantt = (ganttInstance, data) => {
      const columns = getColumns();

      TimelineConfig(ganttInstance, zoom, columns);
      updateTodayMaker(gantt);
    };

    const { gantt, refreshGantt, ganttRef, reloadGantt } = useInitTimelineGantt({
      lsState,
      onRefresh: onRefreshGantt,
      data: tasks,
      ganttInitFuncs: [onInitGantt],
    });

    const _mapGanttData = opens => task => {
      const isIdOpen = opens && opens.includes(String(task.id));

      const isEntityIdOpen = openItems.includes(String(task.entityId));

      const open = isIdOpen || isEntityIdOpen;

      return {
        ...task,
        open,
        $open: open,
      };
    };

    const ganttTasks = useMemo(
      () => ({
        data: tasks.data.map(_mapGanttData(lsState.openTasks)),
        links: tasks.links,
      }),
      [tasks, lsState.openTasks],
    );

    useCollapsedTasks(gantt, updateState, lsState);

    const _loadProjectStories = useLazyLoadStories(gantt, ganttTasks, lsState);

    const toggleZoom = () => setZoom(!zoom);

    useDeepEffect(() => {
      refreshGantt(ganttTasks);
    }, [ganttTasks, zoom, !!gantt]);

    useEffect(() => {
      if (!gantt || !lsState.openTasks) return;
      lsState.openTasks.forEach(taskId => {
        const task = gantt.isTaskExists(taskId) ? gantt.getTask(taskId) : null;

        if (task && task.dbType === 'project' && task.integrationProgress && task.integrationProgress.issuesTotal > 0) {
          dispatch(getProjectStories(taskId));
        }
      });
    }, [!!gantt]);

    useTimelineProjectsGantt({
      gantt,
      selectedGroup,
      localMode: false,
      portfolioMode: isPortfolioMode,
      editAllTasks: true,
    });
    const totalProjectsByLayer = useMemo(() => sum(Object.values(totalByLayer)), [totalByLayer]);

    const onGanttContainerMounted = ganttContainerDiv => {
      if (ganttContainerDiv) {
        reloadGantt();
      }
    };

    return (
      <Component
        {...props}
        search={search}
        setSearch={setSearch}
        zoom={zoom}
        groupByOptions={groupByOptions}
        selectedGroup={selectedGroup}
        groupedBy={groupedBy}
        setSelectedGroup={setSelectedGroup}
        toggleZoom={toggleZoom}
        loadingStories={false}
        lsState={lsState}
        portfolioMode={isPortfolioMode}
        ganttRef={ganttRef}
        gantt={gantt}
        totalProjectsByLayer={totalProjectsByLayer}
        isLoaded={isLoaded}
        onGanttContainerMounted={onGanttContainerMounted}
      />
    );
  };
};

export default componentHOC;
