import React, { useState } from 'react';
import styled from 'styled-components';

import DragonInput from 'design-system/atoms/DragonInput/index';


/*
 * Header options on top nav - Will use HomeTopBar
 */
const HeaderOptions = () => {
  const [search, setSearch] = useState('');

  const onChangeSearch = value => {
    setSearch(value);
  };

  return (
    <Wrapper>
      <DragonInput id="local-search" type="search" value={search} onChange={onChangeSearch} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-right: 18px;
`;

HeaderOptions.propTypes = {};

export default HeaderOptions;
