import { values, pluck, defaultTo } from 'ramda';

import isNilOrEmpty from 'utils/isNilOrEmpty';
import { DEFAULT_UNDEFINED_COLOR } from 'constants/charts';

const defaultToEmptyObject = defaultTo({});
const defaultAsUndefinedColor = defaultTo(DEFAULT_UNDEFINED_COLOR);

const CLUSTER_BY_AUTO_TAGS = 'autoTags';
const CLUSTER_BY_TAGS = 'tags';
const CLUSTER_BY_CUSTOMER_SEGMENT = 'customerSegment';
const CLUSTER_BY_CUSTOMER_TYPE = 'customerType';

const CLUSTER_BY_TAGS_OPTION = { key: CLUSTER_BY_TAGS, title: 'tag' };
const CLUSTER_BY_CUSTOMER_SEGMENT_OPTION = { key: CLUSTER_BY_CUSTOMER_SEGMENT, title: 'Customer segment' };
const CLUSTER_BY_CUSTOMER_TYPE_OPTION = { key: CLUSTER_BY_CUSTOMER_TYPE, title: 'Customer type' };
const CLUSTER_BY_AUTO_TAGS_OPTION = { key: CLUSTER_BY_AUTO_TAGS, title: 'AI Tag' };

const CLUSTER_TYPE = 'cluster';
const CUSTOMER_REQUEST_TYPE = 'customerRequest';

const CLUSTER_BY_CUSTOM_FIELDS_TYPES_ALLOWED = ['Dropdown', 'Multi-select Dropdown'];

const BUBBLE_SIZE_NUMBER_OF_REQUESTS = 'numRequests';
const BUBBLE_SIZE_NUMBER_OF_CUSTOMERS = 'n_customers';
const BUBBLE_SIZE_TOTAL_ACTIVE_VALUE = 'total_active_value';
const BUBBLE_SIZE_TOTAL_VALUE = 'total_value';

const BUBBLE_SIZE_BY_OPTIONS = [
  { key: BUBBLE_SIZE_NUMBER_OF_REQUESTS, title: '# of requests' },
  { key: BUBBLE_SIZE_NUMBER_OF_CUSTOMERS, title: '# of customers' },
  { key: BUBBLE_SIZE_TOTAL_ACTIVE_VALUE, title: 'Amount of active revenue' },
  { key: BUBBLE_SIZE_TOTAL_VALUE, title: 'Amount of total revenue' },
];

/**
 * Get the customer IDs that have the exact given custom field option
 *
 * @param {Array} customers - Array of Customer objects
 * @param {String} customFieldOption - The custom field option value we are searching
 *
 * @returns {Array} - Array of customerIDs to use as filter values
 */
const getCustomerIdsFilterValuesFromGivenCustomFieldOption = (customers, customFieldOption) => {
  const matchesWithCustomFieldOptionFilter = customer => {
    if (isNilOrEmpty(customer?.custom_fields)) {
      return false;
    }

    return values(customer.custom_fields).includes(customFieldOption);
  };

  return pluck('id', customers.filter(matchesWithCustomFieldOptionFilter));
};

/**
 * Get the customer IDs that dont have a value set on the given custom field key
 *
 * @param {Array} customers - Array of Customer objects
 * @param {String} customFieldKey - The custom field key we are searching
 *
 * @returns {Array} - Array of customerIDs to use as filter values
 */
const getCustomerIdsFilterValuesThatDontHaveCustomField = (customers, customFieldKey) => {
  const customersWithoutTheGivenCustomField = customers.filter(customer => {
    const customerCustomFields = defaultToEmptyObject(customer?.custom_fields);

    return isNilOrEmpty(customerCustomFields[customFieldKey]);
  });

  return ['null', ...pluck('id', customersWithoutTheGivenCustomField)];
};

/**
 * Get the customer IDs to fetch customer requests with, for the given cluster
 *
 * @param {Array} customers - Array of Customer objects
 * @param {Object} selectedCluster - Object with the selected cluster (bubble) properties
 * @returns {Array} - Customer IDs filter values to use when fetching customer requests for the selected cluster
 */
const getCustomerIdsFilterValuesForCluster = (customers, selectedCluster) => {
  if (!selectedCluster?.custom_field) {
    return [];
  }

  if (selectedCluster?.id) {
    return getCustomerIdsFilterValuesFromGivenCustomFieldOption(customers, selectedCluster?.id);
  }

  return getCustomerIdsFilterValuesThatDontHaveCustomField(customers, selectedCluster?.custom_field);
};

const makeGetClusterColor = (selectedClusterBy, customerSegmentCustomField, customerTypeCustomField) => {
  const getCustomerCustomFieldOptionColor = (customField, entity) => {
    const { data_enhanced: dataEnhanced } = customField;
    const customFieldOption = dataEnhanced[entity?.id];

    return defaultAsUndefinedColor(customFieldOption?.color);
  };

  return entity => {
    if (customerSegmentCustomField && selectedClusterBy?.key === CLUSTER_BY_CUSTOMER_SEGMENT) {
      return getCustomerCustomFieldOptionColor(customerSegmentCustomField, entity);
    }

    if (customerTypeCustomField && selectedClusterBy?.key === CLUSTER_BY_CUSTOMER_TYPE) {
      return getCustomerCustomFieldOptionColor(customerTypeCustomField, entity);
    }

    return defaultAsUndefinedColor(entity?.color);
  };
};

export {
  CLUSTER_BY_CUSTOM_FIELDS_TYPES_ALLOWED,
  CLUSTER_BY_TAGS_OPTION,
  CLUSTER_BY_CUSTOMER_SEGMENT_OPTION,
  CLUSTER_BY_CUSTOMER_TYPE_OPTION,
  CLUSTER_BY_AUTO_TAGS_OPTION,
  CLUSTER_BY_TAGS,
  CLUSTER_BY_AUTO_TAGS,
  CLUSTER_BY_CUSTOMER_SEGMENT,
  CLUSTER_BY_CUSTOMER_TYPE,
  BUBBLE_SIZE_BY_OPTIONS,
  BUBBLE_SIZE_NUMBER_OF_REQUESTS,
  BUBBLE_SIZE_NUMBER_OF_CUSTOMERS,
  BUBBLE_SIZE_TOTAL_ACTIVE_VALUE,
  BUBBLE_SIZE_TOTAL_VALUE,
  CLUSTER_TYPE,
  CUSTOMER_REQUEST_TYPE,
  getCustomerIdsFilterValuesForCluster,
  makeGetClusterColor,
};
