import React, { useState, Fragment, useMemo } from 'react';
import styled, { css } from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import UnsubscribeIcon from '@material-ui/icons/VisibilityOffOutlined';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

// Dragonboat dependencies
import ViewStarredTitle from 'design-system/molecules/ViewStarredTitle/index';
import DndAtoms from 'design-system/atoms/DndAtoms/index';

import { spacing } from 'design-system/theme';
import ConfirmDialog from 'components/ConfirmDialog';
import UpdateViewDialog from 'containers/ViewDialog/UpdateViewDialog';
import CreateViewDialog from 'containers/ViewDialog/CreateViewDialog';
import { aintStatic, isExplicitSubscribedView } from 'utils/userViews';
import createdByText from 'utils/userViews/createdByText';
import DropdownToggle from './DropdownToggle';
import usePermissions from 'hooks/permissions/usePermissions';
import useViewRoadmapHistorySnaps from 'features/RoadmapHistory/hooks/useViewRoadmapHistorySnaps';

import { getUserName } from 'utils';

import { ViewRoadmapHistorySnapsDialog } from 'features/RoadmapHistory/components';

const { MenuText } = DndAtoms;

const renderTooltipContent = view => (
  <>
    <P>{view.name}</P>
    <P small>Created by: {getUserName(view.user)}</P>
  </>
);

export default React.memo(
  ({
    showSaveDialog,
    closeSaveDialog,
    showShareDialog,
    openShareDialog,
    closeShareDialog,
    onDropdownChange,
    onSelectUserView,
    onSaveUserViewForCreate,
    onSaveUserViewForUpdate,
    onSaveUserViewAndShareForCreate,
    onSaveUserViewAndShareForUpdate,
    onUpdateUserView,
    onEditUserView,
    onShareUserView,
    onDeleteUserView,
    activeView,
    viewToChange,
    userViews,
    hasAnyActiveView,
    editableUserViews,
    shareUrl,
    copyLinkRefInput,
    id,
    isShareViewMenuOpen,
    closeShareViewMenuPopover,
    isDeleteConfirmationVisible,
    setIsDeleteConfirmationVisible,
    viewOwner,
    shouldRenderToggleButton = true,
    updateDialogProps,
    saveDialogProps,
    closeDialog,
    viewsDropdownTabs,
  }) => {
    const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);

    const permissions = usePermissions();

    const closeDropdown = () => (shouldRenderToggleButton ? setDropdownAnchorEl(null) : closeShareViewMenuPopover());

    const { historySnapsButtonProps } = useViewRoadmapHistorySnaps(closeDropdown);

    const handleDropdownChange = (e, key) => {
      onDropdownChange(e, { key });
      closeDropdown();
    };

    const handleSelectView = viewId => {
      onSelectUserView(viewId);
      closeDropdown();
    };

    const handleEditView = view => {
      onEditUserView(view);
      closeDropdown();
    };

    const handleShareView = view => {
      onShareUserView(view);
      closeDropdown();
    };

    const handleDeleteView = viewId => {
      onDeleteUserView(viewId);
      closeDropdown();
    };

    const renderListItem = (view, longerName = false) => (
      <DropdownOption onClick={e => handleSelectView(view.id)}>
        <MenuText>
          {aintStatic(view) ? (
            <ViewStarredTitle view={view}>
              <Tooltip title={renderTooltipContent(view)}>
                <StyledTypography variant="body2" title={view.name} $longerName={longerName}>
                  {view.name}
                </StyledTypography>
              </Tooltip>
            </ViewStarredTitle>
          ) : (
            <Tooltip title={renderTooltipContent(view)}>
              <StyledTypography variant="body2" title={view.name} $longerName={longerName}>
                {view.name}
              </StyledTypography>
            </Tooltip>
          )}
        </MenuText>
      </DropdownOption>
    );

    const listItemsRightActions = [
      historySnapsButtonProps,
      {
        renderIcon: () => <EditIcon />,
        onClick: view => handleEditView(view),
        visible: view => permissions.canUpdate(PERMISSION_RESOURCES.userView, { view }),
      },
      {
        renderIcon: () => <ShareIcon />,
        onClick: view => handleShareView(view),
        visible: view => aintStatic(view),
      },
      {
        renderIcon: () => <UnsubscribeIcon />,
        onClick: view => handleDeleteView(view.id),
        visible: view => isExplicitSubscribedView(view),
      },
    ];

    const renderListHeaderOptions = () => (
      <HeaderContainer>
        <HeaderOption onClick={e => handleDropdownChange(e, 'copyLink')}>Copy link</HeaderOption>
        <HeaderOption onClick={e => handleDropdownChange(e, 'save')}>Save / Update View</HeaderOption>
      </HeaderContainer>
    );

    const onDelete = () => setIsDeleteConfirmationVisible(true);

    const toggleButtonTitle = useMemo(() => {
      if (!hasAnyActiveView) {
        return 'View';
      }

      return `${activeView?.name} ${createdByText(viewOwner?.name)}`;
    }, [activeView, hasAnyActiveView]);

    return (
      <Fragment>
        <UrlLink ref={copyLinkRefInput} type="text" readOnly value="empty" />
        <DropdownToggle
          anchorEl={dropdownAnchorEl}
          setAnchorEl={setDropdownAnchorEl}
          isDropdownOpen={isShareViewMenuOpen}
          closeDropdown={closeDropdown}
          buttonTitle={toggleButtonTitle}
          isToggled={hasAnyActiveView}
          viewsOptions={userViews}
          id={id}
          renderListItem={renderListItem}
          renderListHeaderOptions={renderListHeaderOptions}
          listItemsRightActions={listItemsRightActions}
          shouldRenderToggleButton={shouldRenderToggleButton}
          viewsDropdownTabs={viewsDropdownTabs}
        />
        <>
          <CreateViewDialog
            show={saveDialogProps.open}
            onClose={closeDialog}
            onSave={onSaveUserViewForCreate}
            onSaveAndShare={onSaveUserViewAndShareForCreate}
            userViews={editableUserViews}
            onUpdate={onUpdateUserView}
          />
          <UpdateViewDialog
            key={updateDialogProps?.selectedTab}
            show={updateDialogProps?.open}
            tabs={updateDialogProps?.visibleTabs}
            userCanEditView={updateDialogProps?.userCanEditView}
            onClose={closeDialog}
            initialViewTab={updateDialogProps.selectedTab}
            onUpdate={onUpdateUserView}
            onSave={onSaveUserViewForUpdate}
            onSaveAndShare={onSaveUserViewAndShareForUpdate}
            onDelete={onDelete}
            view={viewToChange}
            shareUrl={shareUrl}
            userViews={editableUserViews}
          />
        </>
        <ConfirmDialog
          isOpen={isDeleteConfirmationVisible}
          onCancel={() => setIsDeleteConfirmationVisible(false)}
          onConfirm={() => onDeleteUserView(viewToChange.id)}
          title="Delete selected view"
          text="Are you sure you want to delete the selected view?"
        />
        <ViewRoadmapHistorySnapsDialog />
      </Fragment>
    );
  },
);

const DropdownOption = styled.div`
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  width: ${({ $longerName }) => ($longerName ? 230 : 150)}px;
`;

const UrlLink = styled.input`
  &&&& {
    border: none;
    font-size: 11px;
    color: #2191c6;
    cursor: pointer;
    width: 0;
    padding: 0;
    outline: none;
  }
`;

const HeaderContainer = styled.div``;

const HeaderOption = styled.div`
  color: ${({ theme }) => theme.palette.text.lightGrey};
  font-size: ${({ theme }) => theme.typography.fontSize}px;
  line-height: ${({ theme }) => theme.typography.lineHeightRegularSmall}px;
  width: 100%;
  padding: ${spacing(0.625)}px ${spacing(1.875)}px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.newLayout.background.lighterBlack};
  }
`;

const P = styled.p`
  ${({ small }) =>
    small &&
    css`
      font-size: 9px;
    `}
`;
