import axios from 'axios';

import { createThunk } from 'utils/store/thunk';
import { fetchMetrics } from 'store/metrics';

import { IMPORT_METRIC_VALUES } from './types';

export const importMetricValues = (integrationType, orgIntegrationId, metricId) => dispatch => {
  dispatch(
    createThunk(
      IMPORT_METRIC_VALUES,
      () =>
        axios.post(`/api/integrations/${integrationType}/${orgIntegrationId}/metrics/${metricId}/import-values`).then(res => {
          dispatch(fetchMetrics());
          return res.data;
        }),

      { integrationType, orgIntegrationId, metricId },
      { includeRetryAction: true },
    ),
  );
};
