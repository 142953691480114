import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@material-ui/core/Input';
import styled from 'styled-components';
import debounce from 'lodash/debounce';

import Search from 'design-system/atoms/DragonIcons/Search';
import theme, { spacing } from 'design-system/theme';
import { setSearch as setSearchAction } from 'store/customerRequests/actions';
import { getSearch } from 'store/customerRequests/selectors';

const exist = Boolean;

const LocalSearch = ({ placeholderStyle, inputStyle, iconStyle }) => {
  const dispatch = useDispatch();
  const value = useSelector(getSearch);
  const onChangeSearch = v => dispatch(setSearchAction(v));

  const [val, setVal] = useState(value);
  const debouncedOnChangeSearch = useMemo(() => onChangeSearch && debounce(onChangeSearch, 100), []);

  const _handleChange = e => {
    setVal(e.target.value);
    if (debouncedOnChangeSearch) debouncedOnChangeSearch(e.target.value);
  };

  useEffect(() => {
    if (value !== val) setVal(value);
  }, [value]);

  return (
    <Wrapper placeholderStyle={placeholderStyle} inputStyle={inputStyle}>
      <Icon iconStyle={iconStyle}>
        <Search />
      </Icon>
      <Input id="request_local_search" placeholder="Search this page" type="text" value={val} onChange={_handleChange} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 130px;

  input {
    padding-left: ${spacing(2.75)}px;

    ${({ inputStyle }) => exist(inputStyle?.color) && `color: ${inputStyle.color};`}
    &::placeholder {
      color: ${theme.palette.text.quickSilver};
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;

      ${({ placeholderStyle }) => exist(placeholderStyle?.color) && `color: ${placeholderStyle.color};`}
      ${({ placeholderStyle }) => exist(placeholderStyle?.opacity) && `opacity: ${placeholderStyle.opacity};`}
    }
  }
`;

const Icon = styled.div`
  &&&& {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    svg path {
      fill: ${theme.palette.text.primary};

      ${({ iconStyle }) => exist(iconStyle?.color) && `fill: ${iconStyle.color};`}
    }
  }
`;

export default LocalSearch;
