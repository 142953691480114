import isNumber from 'lodash/isNumber';

export const getWidgetDefaultCoordinatesBasedOnIndex = (index, columns = 2) => {
  if (!isNumber(index) || !isNumber(columns)) {
    return { x: 0, y: 0 };
  }

  return { x: index % columns, y: Math.floor(index / columns) };
};

export const mapEntitiesToLayout = (entities, columns = 2) =>
  entities.map((entity, i) => ({
    i: entity.id.toString(),
    w: entity.width || 1,
    h: entity.height || 1,
    ...getWidgetDefaultCoordinatesBasedOnIndex(i, columns),
  }));
