import { defaultTo } from 'ramda';
import { createSelector } from 'reselect';

import { isLoading } from 'utils/store/thunk';
import { buildReleaseCapacityAllocationKey } from 'utils/releaseCapacityAllocation';
import { UPSERT_RELEASE_CAPACITY_ALLOCATION } from './types';

const getEntitiesState = state => {
  return state.entities.releaseCapacityAllocation;
};

const getState = state => {
  return state.releaseCapacityAllocation;
};

const getOperationsState = state => {
  return state.releaseCapacityAllocation.operations;
};

export const getNormalizedReleaseCapacityAllocation = createSelector(getEntitiesState, state => {
  return state.byId;
});

const getNormalizedOperationsLoadingState = createSelector(getState, state => {
  return state.loadingByKey;
});

/**
 * Check if specific release capacity allocation is loading
 * @param {*} state
 * @param {Number} timeframeId
 * @param {String} metadataField - optional
 * @param {String} metadataId - optional
 */
export const isReleaseCapacityAllocationLoading = createSelector(
  [
    getNormalizedOperationsLoadingState,
    (_, { timeframeId }) => timeframeId,
    (_, { metadataField }) => metadataField,
    (_, { metadataId }) => metadataId,
  ],
  (byKey, timeframeId, metadataField, metadataId) => {
    const key = buildReleaseCapacityAllocationKey(timeframeId, metadataField, metadataId);

    return defaultTo(false)(byKey[key]);
  },
);

/**
 * Get specific release capacity allocation
 * @param {*} state
 * @param {Number} timeframeId
 * @param {String} metadataField - optional
 * @param {String} metadataId - optional
 */
export const getReleaseCapacityAllocation = createSelector(
  [
    getNormalizedReleaseCapacityAllocation,
    (_, { timeframeId }) => timeframeId,
    (_, { metadataField }) => metadataField,
    (_, { metadataId }) => metadataId,
  ],
  (byId, timeframeId, metadataField, metadataId) => {
    const key = buildReleaseCapacityAllocationKey(timeframeId, metadataField, metadataId);

    return byId[key];
  },
);

/**
 * Get specific release capacity allocation by key
 * @param {*} state
 * @param {String} key - {@link buildReleaseCapacityAllocationKey}
 */
export const getReleaseCapacityAllocationByKey = createSelector(
  [getNormalizedReleaseCapacityAllocation, (_, key) => key],
  (byId, key) => {
    return byId[key];
  },
);

/**
 * @function isUpsertReleaseCapacityAllocationLoading
 *
 * Get UPSERT_RELEASE_CAPACITY_ALLOCATION action loading state
 *
 * @param  {Object} getOperationsState
 * @param  {Object} state
 * @return {Boolean}
 */
export const isUpsertReleaseCapacityAllocationLoading = createSelector(getOperationsState, state =>
  isLoading(state, UPSERT_RELEASE_CAPACITY_ALLOCATION),
);
