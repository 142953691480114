import { DEFAULT_PROJECTED_OPTION, DEFAULT_DATE_INTERVAL } from './constants/defaultChartOptions';
import {
  DISCARD_ROADMAP_VERSION_UPDATED_PROJECTS,
  SET_ROADMAP_VERSION_SELECTED,
  UPDATE_ROADMAP_VERSION_DIALOG_PROPERTIES,
  SET_SELECTED_ROADMAP_VERSIONS_TO_COMPARE,
  SET_SELECTED_ROADMAP_VERSIONS_FIELD,
  SET_STACKED_BY_CHART_OPTION_SELECTED,
  SET_PROJECTED_CHART_OPTION_SELECTED,
  SET_TIME_BUCKET_CHART_OPTION_SELECTED,
  SET_BASE_COMPARE_VERSION_SELECTED,
  SET_COMPARE_SCENARIOS_VERSIONS_COLORS,
  SET_CHARTS_DATE_INTERVAL,
  TOGGLE_HIDE_ROWS_WITH_SAME_DATA,
  SET_COMPARE_SCENARIOS_EDIT_SCENARIO,
  UNSET_SELECTED_SCENARIO,
  OPEN_DELETE_SCENARIO_DIALOG,
  CLOSE_DELETE_SCENARIO_DIALOG,
  SET_SCENARIOS_DIALOG_OPEN,
} from './types';
import { isNil, propOr } from 'ramda';
import { SET_PAGE_FILTERS, setPageFilters } from 'store/filters';
import { COMPARE_SCENARIOS_FILTER, SCENARIO_FILTER } from 'constants/filters/pages';
import { getGlobalFilter } from 'store/filters/selectors';
import { fetchRoadmapVersionProjectsApiCall, setRoadmapVersionProjectsApiCall } from 'store/roadmapVersions/thunks';
import { getHasPendingChanges, isRecentlyCloned } from 'store/roadmapVersions/selectors';

export const setRoadmapVersionSelected = roadmapVersion => {
  return dispatch => {
    dispatch({
      type: SET_ROADMAP_VERSION_SELECTED,
      payload: roadmapVersion,
    });

    const filters = propOr(undefined, 'page_filters')(roadmapVersion);

    if (filters) dispatch({ type: SET_PAGE_FILTERS, payload: { page: SCENARIO_FILTER, filters } });
  };
};

export const updateRoadmapVersionDialogProperties = updatedProperties => {
  return {
    type: UPDATE_ROADMAP_VERSION_DIALOG_PROPERTIES,
    payload: updatedProperties,
  };
};

export const discardRoadmapVersionUpdatedProjects = () => {
  return {
    type: DISCARD_ROADMAP_VERSION_UPDATED_PROJECTS,
  };
};

export const setSelectedRoadmapVersionsToCompare = roadmapVersionsIds => {
  return {
    type: SET_SELECTED_ROADMAP_VERSIONS_TO_COMPARE,
    payload: roadmapVersionsIds,
  };
};

export const setSelectedRoadmapVersionsField = roadmapVersionsIds => {
  return {
    type: SET_SELECTED_ROADMAP_VERSIONS_FIELD,
    payload: roadmapVersionsIds,
  };
};

export const setStackedByChartOptionSelected = option => {
  return {
    type: SET_STACKED_BY_CHART_OPTION_SELECTED,
    payload: option,
  };
};

export const setProjectedChartOptionSelected = option => {
  return {
    type: SET_PROJECTED_CHART_OPTION_SELECTED,
    payload: option || DEFAULT_PROJECTED_OPTION,
  };
};

export const setTimeBucketChartOptionSelected = option => {
  return {
    type: SET_TIME_BUCKET_CHART_OPTION_SELECTED,
    payload: option,
  };
};

export const setBaseCompareVersionSelected = (version, needsGlobalFilter = true) => {
  return (dispatch, getState) => {
    const setVersionSelectedAction = {
      type: SET_BASE_COMPARE_VERSION_SELECTED,
      payload: version,
    };

    dispatch(setVersionSelectedAction);

    let filters = propOr(undefined, 'page_filters')(version);

    if (isNil(version) && needsGlobalFilter) {
      filters = getGlobalFilter(getState());
    }

    if (filters) dispatch(setPageFilters(COMPARE_SCENARIOS_FILTER, filters));
  };
};

export const setCompareScenariosVersionsColors = colors => {
  return {
    type: SET_COMPARE_SCENARIOS_VERSIONS_COLORS,
    payload: colors,
  };
};

export const setChartsDateInterval = dateInterval => {
  return {
    type: SET_CHARTS_DATE_INTERVAL,
    payload: dateInterval || DEFAULT_DATE_INTERVAL,
  };
};

export const fetchProjectsOrApplyFilter = (selectedRoadmapVersion, filters, getStore) => {
  const store = getStore();
  // gantt special case committed status
  const isVersionCloned = isRecentlyCloned(store);
  const hasAnyUpdateToBeSaved = getHasPendingChanges(store);
  const ganttCloneCommittedUnchanged = selectedRoadmapVersion && isVersionCloned && !hasAnyUpdateToBeSaved;
  const committedScenario = selectedRoadmapVersion?.committed || ganttCloneCommittedUnchanged;

  if (committedScenario) {
    return fetchRoadmapVersionProjectsApiCall(selectedRoadmapVersion?.id, filters);
  }
  return setRoadmapVersionProjectsApiCall(selectedRoadmapVersion?.id, filters);
};

export const toggleHideRowsWithSameData = () => ({ type: TOGGLE_HIDE_ROWS_WITH_SAME_DATA });

export const setCompareVersionEditScenario = scenario => ({
  type: SET_COMPARE_SCENARIOS_EDIT_SCENARIO,
  payload: scenario,
});

export const unsetSelectedScenario = () => ({
  type: UNSET_SELECTED_SCENARIO,
});

export const openDeleteScenarioDialog = scenarioToDelete => ({
  type: OPEN_DELETE_SCENARIO_DIALOG,
  payload: { scenarioToDelete },
});

export const closeDeleteScenarioDialog = () => ({ type: CLOSE_DELETE_SCENARIO_DIALOG });

export const setScenariosDialogOpen = () => ({
  type: SET_SCENARIOS_DIALOG_OPEN,
  payload: { open: true },
});

export const setScenariosDialogClose = () => ({
  type: SET_SCENARIOS_DIALOG_OPEN,
  payload: { open: false },
});
