import React, { useState } from 'react';
import styled from 'styled-components';

import Content from '../components/Content';
import Title from '../components/Title';
import Body from '../components/Body';
import DisconnectIntegration from '../components/DisconnectIntegration/DisconnectIntegration';

import MiroIcon from 'design-system/atoms/MiroIcon/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import Switch from '@material-ui/core/Switch';
import ConfirmDialog from 'components/ConfirmDialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useAppNotifications from 'hooks/useAppNotifications';

const Component = props => {
  const {
    orgIntegration,
    addOrgIntegration,
    removeIntegration,
    removeUserIntegration,
    handleTestConnection,
    shouldRenderAdminControl,
    shouldRenderCreateUserIntegration,
    shouldRenderRemoveUserIntegration,
    shouldRenderTestConnection,
    shouldRenderContactAdmin,
    shouldRenderConnectedTeam,
    createUserIntegrationWithMiro,
  } = props;
  const { addNotification } = useAppNotifications();

  const [confirmDialogDisplay, setConfirmDialogDisplay] = useState(false);
  const [testingMiro, setTestingMiro] = useState(false);

  const handleChange = event => {
    event.preventDefault();
    if (event.target.checked) {
      addOrgIntegration();
    } else {
      setConfirmDialogDisplay(true);
    }
  };

  const handleRemoveOrgIntegration = () => {
    removeIntegration();
    setConfirmDialogDisplay(false);
  };

  const handleTestMiroConnection = () => {
    setTestingMiro(true);

    handleTestConnection('miro', orgIntegration.id)
      .then(({ value }) => {
        if (!value?.data) {
          removeUserIntegration();
        }
      })
      .catch(err => {
        console.error(err);
        removeUserIntegration();
      })
      .finally(() => {
        setTestingMiro(false);
        addNotification({ message: 'Miro is configured correctly.' });
      });
  };

  const _renderAdminControl = () => (
    <StyledAdminControl>
      <StyledFormControl
        control={<Switch color="secondary" checked={!!orgIntegration} onClick={handleChange} />}
        label="Miro Integration"
        labelPlacement="Deactivate"
      />
      <ConfirmDialog
        title="Disconnect From Miro"
        text={
          <div>
            <p>Disconnecting Miro will disable Miro linking and update for your entire Workspace. Do you want to continue?</p>
            <br />
            <p>You may reconnect in the future.</p>
          </div>
        }
        isOpen={confirmDialogDisplay}
        onCancel={() => setConfirmDialogDisplay(false)}
        onConfirm={handleRemoveOrgIntegration}
      />
    </StyledAdminControl>
  );

  const _renderRemoveUserIntegration = () => (
    <StyledIntegrationAction isVisible={shouldRenderRemoveUserIntegration}>
      <DisconnectIntegration integrationName="Miro Integration" disconnect={removeUserIntegration} />
    </StyledIntegrationAction>
  );

  const _renderTestConnection = () => (
    <StyledIntegrationAction isVisible={shouldRenderTestConnection}>
      <Button color="primary" onClick={handleTestMiroConnection} disabled={testingMiro}>
        {testingMiro && 'TESTING...'}
        {!testingMiro && 'TEST MY MIRO CONNECTION'}
      </Button>
      {testingMiro && <CircularProgress size={25} />}
    </StyledIntegrationAction>
  );

  const _renderCreateUserIntegration = () => (
    <Button id="miro-integrate-button" color="primary" onClick={createUserIntegrationWithMiro}>
      Integrate with Miro
    </Button>
  );

  const _renderConnectedTeam = () => (
    <StyledConnectedTeam>
      <StyledTitle size="medium">Your workspace is connected to your Miro Team:</StyledTitle>
      <TextDeprecated size="medium" variant="bold">
        {orgIntegration?.data?.team_name}
      </TextDeprecated>
    </StyledConnectedTeam>
  );

  return (
    <Content>
      <Title
        title="Miro Integration"
        helpLink="https://dragonboat.zendesk.com/hc/en-us/articles/4424315974551"
        logo={<MiroIcon size={30} marginRight={20} />}
      />
      <Body>
        {shouldRenderAdminControl && _renderAdminControl()}
        {shouldRenderConnectedTeam && _renderConnectedTeam()}
        {shouldRenderCreateUserIntegration && _renderCreateUserIntegration()}
        {_renderRemoveUserIntegration()}
        {_renderTestConnection()}
        {shouldRenderContactAdmin && <p>Please contact your organization Admin to activate Miro integration.</p>}
      </Body>
    </Content>
  );
};

export default Component;

const StyledFormControl = styled(FormControlLabel)`
  &&&& {
    margin-bottom: 1rem;
  }
`;

const StyledAdminControl = styled.div`
  &&&& {
    padding: 6px 8px;
  }
`;

const StyledTitle = styled(TextDeprecated)`
  &&&& {
    width: 100%;
    padding-bottom: 6px;
  }
`;

const StyledConnectedTeam = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 6px 8px 15px 6px;
  }
`;

const StyledIntegrationAction = styled('div', {
  shouldForwardProp: prop => prop !== 'isVisible',
})`
  &&&& {
    display: ${prop => (prop.isVisible ? 'flex' : 'none')};
    align-items: center;
  }
`;
