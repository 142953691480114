import { UNDEFINED } from 'constants/common';
import {
  COL_CREATE_POPOVER_PROPS,
  ROW_CREATE_POPOVER_PROPS,
  CREATE_POPOVER_DEFAULT_WIDTH,
  CREATE_POPOVER_PADDING_OFFSET,
  cellType,
} from './constants';

const EMPTY_ARRAY = [];

const customFieldPrefix = 'custom_fields';

const buildCustomFieldKey = key => `${customFieldPrefix}.${key}`;

export const isCustomField = option => option?.startsWith('custom_fields.');
const isUndefinedGroup = group => group?.id === UNDEFINED;

const isSameGroup = (rowOrColumn, metadataEntry) => {
  if (isUndefinedGroup(metadataEntry)) return rowOrColumn.id === null;

  return rowOrColumn.id === metadataEntry.id;
};

export const clickTargetIsPortal = e =>
  e?.target?.closest('#react-autowhatever-1') || e?.target?.closest('div[role="presentation"]');

export const parseOptions = options => [{ children: options?.map(option => ({ key: option.id, name: option?.title })) }];

export const removeExistingGroupsFromMetadataOptions = (metadata, existingGroups, selectedMetadata) => {
  return (
    metadata?.[selectedMetadata]?.filter(item => !existingGroups.find(rowOrColumn => isSameGroup(rowOrColumn, item))) ||
    EMPTY_ARRAY
  );
};

export const getCustomFieldsOptionsAsFlatArray = (key, customFieldOptionsData) => {
  return {
    [buildCustomFieldKey(key)]: customFieldOptionsData
      ? customFieldOptionsData.map(option => ({
          id: option.key,
          title: option.title,
          color: option.color,
        }))
      : [],
  };
};

export const formatMetadataOptions = (options, selectedOptionKey) => {
  return options.map(opt => ({
    ...opt,
    title: opt.title || opt.name,
  }));
};

export const getCreatePopoverProps = type => (type === cellType.ROW ? ROW_CREATE_POPOVER_PROPS : COL_CREATE_POPOVER_PROPS);

export const getAddOptionLabel = optionTitle => `Add a ${optionTitle} to the view`;
export const getCreateOptionLabel = optionTitle => `Create a new ${optionTitle}`;
export const getSelectPlaceholder = (optionTitle, optionsLength) =>
  optionsLength > 0 ? `Select a ${optionTitle}` : 'No options to select';

export const getCreatePopoverWidth = popoverAnchorEl => {
  const popoverAnchorElWidth = popoverAnchorEl?.getBoundingClientRect()?.width;

  if (!popoverAnchorElWidth) return CREATE_POPOVER_DEFAULT_WIDTH;

  return popoverAnchorElWidth + CREATE_POPOVER_PADDING_OFFSET;
};
