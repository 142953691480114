import { useCallback, useMemo } from 'react';

import { PAGE_NAVIGATION_DIRECTIONS } from 'constants/pagination';

const determineLastPage = (count, limit) => (limit && count ? Math.ceil(count / limit) : 1);
const determineIndexedPage = currentPage => (currentPage - 1 >= 0 ? currentPage - 1 : 0);

/**
 * Hook to handle the logic for adding Pagination to a page.
 *
 * @param {*} changePage - function that should be triggered when changing page
 * @param {*} currentPage - current page number
 * @param {*} nextPage - next page number
 * @param {*} previousPage - previous page number
 * @param {*} resultsCount - total number of results
 * @param {*} pageLimit - currently selected option for limit of results per page
 * @param {*} updatePageLimit - function to update the limit of resutls per page
 */

export default function usePagination(changePage, currentPage, nextPage, previousPage, resultsCount, pageLimit, updatePageLimit) {
  const lastPage = useMemo(() => determineLastPage(resultsCount, pageLimit), [pageLimit, resultsCount]);
  const indexedCurrentPage = useMemo(() => determineIndexedPage(currentPage), [currentPage]);

  const handleChangePage = useCallback(
    page => {
      const options = { limit: pageLimit };

      switch (page) {
        case PAGE_NAVIGATION_DIRECTIONS.FIRST:
          options.page = 1;
          break;
        case PAGE_NAVIGATION_DIRECTIONS.PREVIOUS:
          options.page = previousPage;
          break;
        case PAGE_NAVIGATION_DIRECTIONS.NEXT:
          options.page = nextPage;
          break;
        case PAGE_NAVIGATION_DIRECTIONS.LAST:
          options.page = lastPage;
          break;
        default:
          break;
      }

      if (options.page) {
        changePage(options);
      }
    },
    [changePage, lastPage, nextPage, pageLimit, previousPage],
  );

  const handleChangePageLimit = useCallback(limit => updatePageLimit(limit), [updatePageLimit]);

  return {
    handleChangePage,
    handleChangePageLimit,
    indexedCurrentPage,
    lastPage,
  };
}
