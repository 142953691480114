import React from 'react';
import styled from 'styled-components';

import MicroChip from 'design-system/atoms/MicroChip/index';

import theme from 'design-system/theme';

const getHealthLabel = (value, optionsLabel) => optionsLabel?.[value] || value;

const getLabelAndColors = (value, optionsLabel) => {
  const label = getHealthLabel(value, optionsLabel);

  return {
    label,
    color: theme.palette.healthStatus[label]?.color,
    background: theme.palette.healthStatus[label]?.background,
  };
};

const HealthCellRenderer = params => {
  const value = params.getValue();
  const { optionsLabel } = params.colDef;
  const { label, color, background } = getLabelAndColors(value, optionsLabel);
  const hasValue = !!value && !!background;

  return hasValue ? (
    <Wrapper>
      <MicroChip label={label} color={background} textColor={color} variant="bold" fullWidth />
    </Wrapper>
  ) : null;
};

export default HealthCellRenderer;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
