import React from 'react';
import styled from 'styled-components';
import { Rnd } from 'react-rnd';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { materialColors } from 'design-system/themes/default';

import TooltipContent from './TooltipContent';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    background: '#fff',
    maxWidth: 350,
    maxHeight: '100%',
    borderRadius: 0,
    border: `1px solid ${theme.palette.border.mercury}`,
    padding: 14,
    opacity: 1,
  },
}))(Tooltip);

const TableMilestone = React.memo(
  ({
    id,
    title,
    name,
    textColor,
    startDate,
    endDate,
    top,
    left,
    width,
    height,
    boundsname,
    openLightBox,
    onDoubleClick,
    onDragStop,
    onResizeStop,
    isLoading,
    showTooltip,
  }) => {
    const [open, setOpen] = React.useState(false);

    const style = {
      zIndex: '50',
      // overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 5,
    };

    const onOpenTooltip = () => {
      setOpen(true);
    };

    const onCloseTooltip = () => {
      setOpen(false);
    };

    const handleDragStop = (_, delta) => {
      // should not trigger the drag event on double click
      // prevent the project update when we only want to open the lightbox
      if (delta?.x === left && delta?.y === top) {
        return;
      }

      onDragStop && onDragStop(delta);
    };

    const handleResizeStop = (_, direction, __, delta, position) => {
      onResizeStop && onResizeStop(direction, delta, position);
    };

    const handleClick = event => {
      event.stopPropagation();
    };

    const handleDoubleClick = event => {
      openLightBox && openLightBox(id);
      onDoubleClick && onDoubleClick(id);
      event.stopPropagation();
    };

    return (
      <Rnd
        style={style}
        default={{
          x: left,
          y: top,
          width,
          height,
        }}
        position={{
          x: left,
          y: top,
        }}
        size={{
          width: 34,
          height: 34,
        }}
        enableResizing={{
          left: false,
          right: false,
        }}
        disableDragging={isLoading}
        dragGrid={[1, 35]}
        bounds={`.${boundsname}`}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        onDragStart={onCloseTooltip}
        onDragStop={handleDragStop}
        onResizeStart={onCloseTooltip}
        onResizeStop={handleResizeStop}
      >
        <HtmlTooltip
          open={showTooltip && open}
          onOpen={onOpenTooltip}
          onClose={onCloseTooltip}
          title={<TooltipContent name={name} title={title} startDate={startDate} endDate={endDate} />}
        >
          <BarWrapper style={{ backgroundColor: materialColors.mediumPurple, color: textColor }}>
            <BarContent showTextOverflow>{title}</BarContent>
          </BarWrapper>
        </HtmlTooltip>
      </Rnd>
    );
  },
);

TableMilestone.displayName = 'TableMilestone';

export default TableMilestone;

const BarWrapper = styled.div`
  &&&& {
    height: 20px;
    width: 20px;

    margin-top: 1px;

    display: flex;
    align-items: center;
    padding: 0 10px;

    cursor: pointer;
    transform: rotateZ(45deg);

    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  }
`;

const BarContent = styled.p`
  &&&& {
    max-width: 100%;
    height: 26px;

    padding-left: 20px;

    overflow: ${props => (props.showTextOverflow ? 'unset' : 'hidden')};
    white-space: nowrap;
    text-overflow: ellipsis;

    user-select: none;

    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    font-stretch: 100%;
    font-weight: normal;
    color: ${({ theme }) => theme.palette.text.primary};

    transform: rotateZ(-45deg);
  }
`;
