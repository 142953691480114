import { useEffect, useState } from 'react';

import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';

const emptyArray = [];
const emptyObject = {};

const useDbqlAdvancedSearch = ({ pageFilters = emptyObject }) => {
  const { canView } = usePermissions();

  const canViewDbqlAdvancedSearch = canView(PERMISSION_FEATURES.dbql);

  const dbqlConditions = pageFilters?.dbql?.conditions || emptyArray;
  const [isUsingDbqlAdvancedSearch, setIsUsingDbqlAdvancedSearch] = useState(
    !!dbqlConditions?.length && canViewDbqlAdvancedSearch,
  );

  useEffect(() => {
    setIsUsingDbqlAdvancedSearch(!!dbqlConditions?.length && canViewDbqlAdvancedSearch);
  }, [dbqlConditions.length]);

  return {
    isUsingDbqlAdvancedSearch,
    setIsUsingDbqlAdvancedSearch,
    canViewDbqlAdvancedSearch,
  };
};

export default useDbqlAdvancedSearch;
