import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ScreenContainer } from './styles';

import Step1 from './animationSteps/Step1';
import Step2 from './animationSteps/Step2';

const ANIMATION_STEPS = [<Step1 />, <Step2 />];

// We'll assume that on average the AI Planner takes 10 seconds to run,
// so the animation steps will be equally split across this length
const DEFAULT_DURATION = 10000;

const TRANSITION_CLASSNAMES = {
  enter: 'fade-enter',
  enterActive: 'fade-enter-active',
  exit: 'fade-exit',
  exitActive: 'fade-exit-active',
};

const LoadingScreen = ({ animationSteps = ANIMATION_STEPS }) => {
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentStep < animationSteps.length) {
        setCurrentStep(prevStep => prevStep + 1);
      } else {
        setCurrentStep(1);
      }
    }, DEFAULT_DURATION / animationSteps.length);

    return () => {
      clearInterval(interval);
    };
  }, [currentStep]);

  return (
    <ScreenContainer>
      {animationSteps.map((step, i) => (
        <CSSTransition in={currentStep === i + 1} timeout={500} classNames={TRANSITION_CLASSNAMES} unmountOnExit>
          {step}
        </CSSTransition>
      ))}
    </ScreenContainer>
  );
};

export default LoadingScreen;
