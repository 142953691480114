import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { prop } from 'ramda';

import { getAllTeams as getAllTeamsSelector } from 'store/teams/selectors';
import { getSkills } from 'store/skills/selectors';
import { getUsers } from 'store/users/selectors';
import { getUserName } from 'utils';
import useSystemFields from 'hooks/useSystemFields';

import isOldLightboxActive from '../utils/isOldLightboxActive';
import useProjectEstimatesLightboxTabCrud from './hooks/useProjectEstimatesLightboxTabCrud';

const ID = 'id';

const getId = prop(ID);

const componentHOC = Component => {
  return props => {
    const teams = useSelector(state => getAllTeamsSelector(state));
    const skills = useSelector(state => getSkills(state));
    const users = useSelector(getUsers);
    const [getSystemFieldName] = useSystemFields();
    const { selectedProject, formData, updateFormData, onSave } = props;
    const oldLightboxActive = isOldLightboxActive();

    const { estimates, estimatesBeingCreated, onAddEstimate, onUpdateEstimate, onDeleteEstimate } =
      useProjectEstimatesLightboxTabCrud(selectedProject, formData);

    const onSaveHandler = (updateObj, jiraSync = false) => {
      onSave(jiraSync, false, { id: getId(selectedProject), ...updateObj }, false);
    };

    const _onAddTask = () => {
      const blankTask = {
        owner: '',
        startDate: moment(formData.estimated_start_date || moment()).formatAsDate(),
        endDate: moment(formData.estimated_start_date || moment()).addDuration(14, 'days'),
        project_id: formData.id,
        duration: 14,
        status: 'Open',
        title: 'New assignment',
        ownerAllocation: 100,
      };

      updateFormData({
        tasks: [...formData.tasks, blankTask],
      });
    };
    const _onUpdateTask = (index, data) => {
      const { tasks } = formData;
      let newTasks = cloneDeep(tasks);

      newTasks[index] = {
        ...tasks[index],
        ...data,
      };

      newTasks = newTasks.map(t => ({
        ...t,
        duration: parseFloat(t.duration),
        ownerName: t.owner && getUserName(t.owner),
        owner_id: t.owner ? t.owner.id : null,
        title: t.owner && !t.id ? `New assignment for ${getUserName(t.owner)}` : t.title,
        start_date: t.startDate || t.start_date,
        end_date: t.endDate || t.end_date,
      }));

      updateFormData({ tasks: newTasks });
    };
    const _onDeleteTask = index => {
      updateFormData({ tasks: formData.tasks.filter((_, i) => i !== index) });
    };

    return (
      <Component
        {...props}
        teams={teams}
        skills={skills}
        users={users}
        estimates={estimates}
        estimatesBeingCreated={estimatesBeingCreated}
        onUpdateEstimate={onUpdateEstimate}
        onDeleteEstimate={onDeleteEstimate}
        onAddEstimate={onAddEstimate}
        onAddTask={_onAddTask}
        onDeleteTask={_onDeleteTask}
        onUpdateTask={_onUpdateTask}
        onUpdateProject={onSaveHandler}
        getSystemFieldName={getSystemFieldName}
        oldLightboxActive={oldLightboxActive}
      />
    );
  };
};

export default componentHOC;
