import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import jiraOauth2CredentialsScreenshot from '../../images/jira_oauth2_credentials.png';
import { isURL } from 'utils';

export default class ConfigStepTwo extends Component {
  render() {
    const { onCancel, onNext, changeForm, baseUrl, baseUrlChange, goBack, formData } = this.props;

    return (
      <StepTwoContent>
        <Grid container spacing={16}>
          <Grid item xs={7}>
            <HelperImage src={jiraOauth2CredentialsScreenshot} alt="jira-oauth-credentials" />
          </Grid>
          <Grid item xs={5}>
            <TextControl fullWidth margin="dense">
              <TextField
                label="Enter your Jira URL"
                inputProps={{
                  name: 'base_url',
                  onClick: e => e.target.select(),
                }}
                onChange={baseUrlChange}
                value={baseUrl}
                style={{ width: '100%' }}
                required
              />
            </TextControl>
            <TextControl fullWidth margin="dense">
              <TextField
                name="client_id"
                label="Client ID"
                onChange={changeForm('client_id')}
                value={formData.client_id}
                required
              />
            </TextControl>
            <TextControl fullWidth margin="dense">
              <TextField
                name="client_secret"
                label="Client Secret"
                type="password"
                onChange={changeForm('client_secret')}
                value={formData.client_secret}
                required
              />
            </TextControl>
          </Grid>
        </Grid>
        <Actions>
          <Grid container spacing={16}>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <BackButton id="back-button" color="primary" onClick={goBack}>
                Back
              </BackButton>
              <CancelButton id="cancel-button" onClick={onCancel}>
                Cancel
              </CancelButton>
              <NextButton
                id="next-button"
                disabled={!isURL(baseUrl) || !formData.client_id || !formData.client_secret}
                color="primary"
                onClick={onNext}
              >
                Next
              </NextButton>
            </Grid>
          </Grid>
        </Actions>
      </StepTwoContent>
    );
  }
}

const StepTwoContent = styled.div`
  textarea,
  input {
    font-size: 12px !important;
  }
`;

const TextControl = styled(FormControl)`
  &&&& {
    margin-bottom: 12px;
    padding-right: 40px;
  }
`;

const BackButton = styled(Button)`
  &&&& {
    float: left;
  }
`;

const HelperImage = styled.img`
  width: 90%
`;

const NextButton = styled(Button)`
  &&&& {
    float: right;
  }
`;

const CancelButton = styled(Button)`
  &&&& {
    float: right;
  }
`;

const Actions = styled.div`
  margin-top: 22px;
`;
