import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import getSystemFieldName from 'utils/getSystemFieldName';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import MultiSelect from 'design-system/atoms/MultiSelect/index';

import Content from '../components/Content';
import Title from '../components/Title';
import Body from '../components/Body';
import Logo from './slack-logo.png';
import DisconnectIntegration from '../components/DisconnectIntegration/DisconnectIntegration';

export default props => {
  const { orgIntegration, clickNext, updateSlackIntegration, userCanUpdateOrgIntegration, userCanViewOrgIntegration } = props;

  const orgIntegrationData = orgIntegration?.data || {};

  const fieldOptions = [
    ...['customer', 'tag', 'roadmap', 'priority'].map(value => ({
      label: getSystemFieldName(value, props.systemFields),
      value,
    })),
    { label: 'Owner', value: 'owner' },
  ];

  const shouldShowContactInfo = !userCanUpdateOrgIntegration && !userCanViewOrgIntegration;

  return (
    <Content>
      <Title
        logo={<SlackLogo src={Logo} />}
        helpLink="https://dragonboat.zendesk.com/hc/en-us/articles/4411665985815"
        title="Slack Integration"
      />
      <Body>
        {shouldShowContactInfo && <p>Please contact your organization Admin to activate Slack integration.</p>}
        {userCanUpdateOrgIntegration && !orgIntegration && (
          <SlackContainer>
            <SlackIntegrateButton id="slack-integrate-button" color="primary" onClick={() => clickNext()}>
              Integrate with Slack
            </SlackIntegrateButton>
          </SlackContainer>
        )}
        {orgIntegration && (
          <IntegratedContainer>
            {userCanUpdateOrgIntegration && (
              <OptionalFieldControl fullWidth margin="dense">
                <FormLabel>Display 3 optional fields: </FormLabel>
                <OptionMultiSelect>
                  <MultiSelect
                    options={fieldOptions}
                    placeholder="Select fields to display"
                    onChange={fields => updateSlackIntegration({ ...orgIntegrationData, requestFormFields: fields })}
                    value={orgIntegrationData.requestFormFields || []}
                    hideCreateOption
                    getOptionLabel={v => fieldOptions.find(o => o.value === v?.value)?.label ?? ''}
                    optionsMapper={{ label: 'label', value: 'value' }}
                    fullWidth
                  />
                </OptionMultiSelect>
              </OptionalFieldControl>
            )}
            {userCanViewOrgIntegration && (
              <>
                <SlackConnected size="medium">
                  Your dragonboat workspace has been linked to your Slack Team: {orgIntegrationData.slack_team_name}.
                </SlackConnected>
                <Button id="test-button" color="primary" onClick={props.testSlackConnection}>
                  Test Connection
                </Button>
                <DisconnectIntegration
                  integrationName="Slack"
                  disconnect={props.removeIntegration}
                  disabled={!userCanUpdateOrgIntegration}
                />
              </>
            )}
          </IntegratedContainer>
        )}
      </Body>
    </Content>
  );
};

const SlackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  width: 100%;
`;

const OptionalFieldControl = styled(FormControl)`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const OptionMultiSelect = styled.div`
  flex: 1 0 0;
  padding-left: 10px;
`;

const IntegratedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  width: 100%;
`;

const SlackIntegrateButton = styled(Button)`
  &&&& {
    margin-top: 12px;
    align-self: center;
  }
`;

const SlackConnected = styled(TextDeprecated)`
  &&&& {
    margin-top: 20px;
  }
`;

const SlackLogo = styled.img`
  &&&& {
    height: 35px;
    width: 35px;
    margin-right: 20px;
  }
`;
