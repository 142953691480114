import React, { useMemo } from 'react';

import { BaseGrid } from 'containers/Grids';
import { useCommonGridEventHandlers, useCommonGridProps } from 'design-system/molecules/AgGridReact-New/hooks';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import usePermissions from 'hooks/permissions/usePermissions';
import useCustomerRequests from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequests';
import useCustomerRequestsGrid from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsGrid';
import useCustomerRequestsGridMetadata from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsGridMetadata';

import { HEADER_HEIGHT, ID_KEY } from 'routes/CustomerRequests/Grid/New/constants';
import useGridApiContext from 'hooks/grid/useGridApiContext';

import useAIAnalyzerGridState from '../../../hooks/useAIAnalyzerGridState';
import useAIAnalyzerGridColumnsDefs from '../../../hooks/useAIAnalyzerGridColumnsDefs';
import { GridWrapper } from './styles';
import { AI_ANALYZER_GRID } from '../../../constants';

const GRID_HEIGHT = 'calc(100vh - 160px)';
const PAGE_SIZE = 100;

const getRowId = ({ data }) => data?.id || null;

export default ({ customerRequests, handleSelectionChanged }) => {
  const { onOpenCustomerRequestInfo, onSwitchRowOrder } = useCustomerRequests();

  const { registerGridApi } = useGridApiContext();

  const {
    rowHeight,
    searchString,
    isBulkUpdate,
    visibleColumnsIds,
    columnsState,
    shouldGridBeUnmounted,

    setDirtyViewMutex,
    saveColumnsState,
  } = useAIAnalyzerGridState();

  const {
    handleGridReady,
    onChangeColumnVisibility,
    updateCustomerRequestByIdOnCellValueChange,
    handleDisplayedColumnsChanged,
    handleColumnResized,
  } = useCustomerRequestsGrid({ onOpenCustomerRequestInfo, saveColumnsState });

  const { metadata, createMultiSelectOption } = useCustomerRequestsGridMetadata();

  const { columnDefs, defaultColDef } = useAIAnalyzerGridColumnsDefs({
    searchString,
    isBulkUpdate,
    visibleColumnsIds,
    columnsState,
    rowHeight,

    updateCustomerRequestById: updateCustomerRequestByIdOnCellValueChange,
    onChangeColumnVisibility,
    onOpenCustomerRequestInfo,
    createMultiSelectOption,
  });

  const commonGridProps = useCommonGridProps({});

  const commonGridEvents = useCommonGridEventHandlers({
    items: customerRequests,
    idKey: ID_KEY,
    actions: {
      update: updateCustomerRequestByIdOnCellValueChange,
      drag: onSwitchRowOrder,
      sortChanged: handleDisplayedColumnsChanged,
    },
  });

  const { canUpdate } = usePermissions();

  const gridContext = useMemo(
    () => ({ metadata, allowEdit: data => canUpdate(PERMISSION_RESOURCES.request, { data }) }),
    [metadata, canUpdate],
  );

  const onGridReady = params => {
    registerGridApi(AI_ANALYZER_GRID, params.api, params.columnApi);

    handleGridReady(params);
  };

  if (shouldGridBeUnmounted) {
    return <PageLoading />;
  }

  return (
    <GridWrapper>
      <BaseGrid
        // Common props:
        {...commonGridProps}
        {...commonGridEvents}
        // General props:
        getRowId={getRowId}
        onGridReady={onGridReady}
        rowData={customerRequests}
        context={gridContext}
        onFirstDataRendered={setDirtyViewMutex}
        // isGroupOpenByDefault
        // isRowSelectable
        rowSelection="multiple"
        suppressRowClickSelection
        // Styling:
        // cssStyles
        // getRowStyle
        height={GRID_HEIGHT}
        headerHeight={HEADER_HEIGHT}
        rowHeight={rowHeight}
        // Columns:
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        // Events:
        // onRowGroupOpened
        onSelectionChanged={handleSelectionChanged}
        onDisplayedColumnsChanged={handleDisplayedColumnsChanged}
        onColumnResized={handleColumnResized}
        paginationPageSize={PAGE_SIZE}
        pagination
      />
    </GridWrapper>
  );
};
