import React from 'react';
import styled, { css } from 'styled-components';

import { spacing } from 'design-system/theme';
import WYSIWYGEditorField from 'design-system/molecules/WYSIWYGEditorField/index';

import SendToIntegrationChannelButton from 'design-system/molecules/SendToIntegrationChannelButton';
import { MAX_HEIGHT, MIN_HEIGHT } from 'design-system/molecules/RemirrorWYSIWYGEditor/constants';

import useSendToIntegrationChannel from 'hooks/integrations/useSendToIntegrationChannel';

const SendViewToIntegrationChannel = ({
  integrationType,
  message,
  setMessage,
  onSend,
  canCreateRecurringNotifications,
  isLoading,
}) => {
  const { channels, selectedChannel, setSelectedChannel, periodicity, setSelectedPeriodicity } =
    useSendToIntegrationChannel(integrationType);

  const isDisabled = !selectedChannel || isLoading;

  const handleOnSend = () => {
    onSend({ channel: selectedChannel, integrationType, periodicity });
    setSelectedChannel(null);
  };

  return (
    <Container>
      <StyledWYSIWYGEditorField
        key={`view-${integrationType}-message`}
        value={message}
        content={message}
        placeholder="Optional: Add a message"
        onChange={setMessage}
        minHeight={MIN_HEIGHT}
        maxHeight={MAX_HEIGHT}
      />
      <Flex margin={`${spacing(2)}px 0 0`}>
        <SendToIntegrationChannelButton
          integrationType={integrationType}
          onSend={handleOnSend}
          channels={channels}
          setSelectedChannel={setSelectedChannel}
          selectedChannel={selectedChannel}
          isDisabled={isDisabled}
          periodicity={periodicity}
          setSelectedPeriodicity={setSelectedPeriodicity}
          canCreateRecurringNotifications={canCreateRecurringNotifications}
        />
      </Flex>
    </Container>
  );
};

export default SendViewToIntegrationChannel;

const Container = styled.div``;

const StyledWYSIWYGEditorField = styled(WYSIWYGEditorField)`
  && {
    margin-top: ${spacing(2)}px;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${spacing()}px;
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `};
`;
