export const SAVE_DASHBOARD_COMMENT = 'SAVE_DASHBOARD_COMMENT';
export const SAVE_DASHBOARD_COMMENT_FULFILLED = 'SAVE_DASHBOARD_COMMENT_FULFILLED';

export const FETCH_DASHBOARD_COMMENTS = 'FETCH_DASHBOARD_COMMENTS';
export const FETCH_DASHBOARD_COMMENTS_FULFILLED = 'FETCH_DASHBOARD_COMMENTS_FULFILLED';
export const FETCH_DASHBOARD_COMMENTS_PENDING = 'FETCH_DASHBOARD_COMMENTS_PENDING';

export const FETCH_NEXT_DASHBOARD_COMMENTS = 'FETCH_NEXT_DASHBOARD_COMMENTS';
export const FETCH_NEXT_DASHBOARD_COMMENTS_FULFILLED = 'FETCH_NEXT_DASHBOARD_COMMENTS_FULFILLED';

export const DELETE_DASHBOARD_COMMENT = 'DELETE_DASHBOARD_COMMENT';
export const DELETE_DASHBOARD_COMMENT_FULFILLED = 'DELETE_DASHBOARD_COMMENT_FULFILLED';
export const DELETE_DASHBOARD_COMMENT_PENDING = 'DELETE_DASHBOARD_COMMENT_PENDING';

export const EDIT_DASHBOARD_COMMENT = 'EDIT_DASHBOARD_COMMENT';
export const EDIT_DASHBOARD_COMMENT_FULFILLED = 'EDIT_DASHBOARD_COMMENT_FULFILLED';
export const EDIT_DASHBOARD_COMMENT_PENDING = 'EDIT_DASHBOARD_COMMENT_PENDING';
