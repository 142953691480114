import { getThunkActionTypes } from 'utils/store/thunk';

export const UPDATE_WHITEBOARD_LAYOUT_CONFIG = 'UPDATE_WHITEBOARD_LAYOUT_CONFIG';
export const UPDATE_WHITEBOARD_NODES_LAYOUT = 'UPDATE_WHITEBOARD_NODES_LAYOUT';
export const UPDATE_WHITEBOARD_EDGES_LAYOUT = 'UPDATE_WHITEBOARD_EDGES_LAYOUT';

const { APPLY_FILTERS_WHITEBOARD, APPLY_FILTERS_WHITEBOARD_FULFILLED } = getThunkActionTypes('APPLY_FILTERS_WHITEBOARD');

const { UPDATE_METADATA_ENTITIES_WHITEBOARD, UPDATE_METADATA_ENTITIES_WHITEBOARD_FULFILLED } = getThunkActionTypes(
  'UPDATE_METADATA_ENTITIES_WHITEBOARD',
);

export {
  APPLY_FILTERS_WHITEBOARD,
  APPLY_FILTERS_WHITEBOARD_FULFILLED,
  UPDATE_METADATA_ENTITIES_WHITEBOARD,
  UPDATE_METADATA_ENTITIES_WHITEBOARD_FULFILLED,
};
