import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_METRIC_LEVELS'),
  ...getThunkActionTypes('UPDATE_METRIC_LEVELS'),
};

export const {
  FETCH_METRIC_LEVELS,
  FETCH_METRIC_LEVELS_FULFILLED,
  FETCH_METRIC_LEVELS_PENDING,
  FETCH_METRIC_LEVELS_REJECTED,
  UPDATE_METRIC_LEVELS,
  UPDATE_METRIC_LEVELS_FULFILLED,
  UPDATE_METRIC_LEVELS_PENDING,
  UPDATE_METRIC_LEVELS_REJECTED,
} = actionTypes;
