export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_FULFILLED = 'FETCH_NOTIFICATIONS_FULFILLED';

export const FETCH_NEXT_NOTIFICATIONS = 'FETCH_NEXT_NOTIFICATIONS';
export const FETCH_NEXT_NOTIFICATIONS_FULFILLED = 'FETCH_NEXT_NOTIFICATIONS_FULFILLED';
export const FETCH_NEXT_NOTIFICATIONS_PENDING = 'FETCH_NEXT_NOTIFICATIONS_PENDING';
export const FETCH_NEXT_NOTIFICATIONS_REJECTED = 'FETCH_NEXT_NOTIFICATIONS_REJECTED';

export const PROCESS_NOTIFICATION = 'PROCESS_NOTIFICATION';

export const SHOW_NOTIFICATION_COMMENT = 'SHOW_COMMENT';
export const SHOW_NOTIFICATION_FIELD = 'PROJECT_FIELD';
export const SHOW_NOTIFICATION_FIELD_DEPS = 'PROJECT_FIELD_DEPS';
export const SHOW_NOTIFICATION_HEALTH = 'PROJECT_HEALTH';
export const SHOW_NOTIFICATION_HEALTH_DEPS = 'PROJECT_HEALTH_DEPS';
export const SHOW_CUSTOMER_REQUEST_NOTIFICATION_COMMENT = 'SHOW_CUSTOMER_REQUEST_COMMENT';
export const JIRA_UPDATE_ERROR = 'JIRA_UPDATE_ERROR';
export const JIRA_PROJECT_RENAMED_WARNING = 'JIRA_PROJECT_RENAMED_WARNING';
export const PROJECT_FIELD = 'PROJECT_FIELD';

export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const MARK_NOTIFICATION_AS_READ_FULFILLED = 'MARK_NOTIFICATION_AS_READ_FULFILLED';

export const MARK_ALL_NOTIFICATIONS_AS_READ = 'MARK_ALL_NOTIFICATIONS_AS_READ';
export const MARK_ALL_NOTIFICATIONS_AS_READ_FULFILLED = 'MARK_ALL_NOTIFICATIONS_AS_READ_FULFILLED';

export const MARK_NOTIFICATION_AS_ARCHIVED = 'MARK_NOTIFICATION_AS_ARCHIVED';
export const MARK_NOTIFICATION_AS_ARCHIVED_FULFILLED = 'MARK_NOTIFICATION_AS_ARCHIVED_FULFILLED';

export const MARK_ALL_NOTIFICATIONS_AS_ARCHIVED = 'MARK_ALL_NOTIFICATIONS_AS_ARCHIVED';
export const MARK_ALL_NOTIFICATIONS_AS_ARCHIVED_FULFILLED = 'MARK_ALL_NOTIFICATIONS_AS_ARCHIVED_FULFILLED';

export const RECEIVE_REALTIME_NOTIFICATION = 'RECEIVE_REALTIME_NOTIFICATION';
