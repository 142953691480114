import { connect } from 'react-redux';
import { compose } from 'redux';
import { closeAddNewProject } from 'store/app';


const mapStateToProps = (store, props) => ({
  systemFields: store.organization.organization.system_fields_name,
});

// Apply HOCs
export default
compose(
  connect(
    mapStateToProps,
    {
      closeAddNewProject,
    }
  ),
);
