import React, { useState } from 'react';
import styled from 'styled-components';
import isString from 'lodash/isString';
import { useSelector } from 'react-redux';

import ConfirmDialog from 'components/ConfirmDialog';
import DataSourceUrlInput from './components/DataSourceUrlInput';
import LinkButtons from './components/LinkButtons';
import FluidField from 'design-system/molecules/FluidField/index';

import { spacing } from 'design-system/theme';
import { getMetricOrgIntegrations } from 'store/organization/selectors';
import getOrgIntegrationForDataSource from 'utils/metrics/getOrgIntegrationForDataSource';

import IntegrationConfigs from 'features/Integrations/integrations.registry';
import { FeatureTypes } from 'features/Integrations/IntegrationConfigs/constants';

const getEditorComponentFromIntegrationConfig = integrationConfig => {
  if (!integrationConfig) {
    return null;
  }

  const featureConfig = integrationConfig.features.find(feature => feature.key === FeatureTypes.linkMetric);

  if (featureConfig) {
    const { components } = featureConfig;

    return components.FluidMetricDataSourceField || null;
  }

  return null;
};

const FluidMetricDataSourceField = props => {
  const { value: originalValue, integrationData, disableEdit } = props;

  const metricOrgIntegrations = useSelector(getMetricOrgIntegrations);

  const initialOrgIntegration = getOrgIntegrationForDataSource(originalValue, metricOrgIntegrations);

  const [integration, setIntegration] = useState({
    id: initialOrgIntegration?.id,
    type: initialOrgIntegration?.integrationType,
  });

  const integrationConfig = IntegrationConfigs[integration?.type];

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const updateIntegration = value => {
    const orgIntegration = getOrgIntegrationForDataSource(value, metricOrgIntegrations);

    setIntegration({ id: orgIntegration?.id, type: orgIntegration?.integrationType });
  };

  const handleInputChange = (value, onChange) => {
    onChange(value);
    updateIntegration(value);
  };

  const handleLink = (save, value, additionalValues = {}) => {
    save({
      integrationType: integration?.type,
      orgIntegrationId: integration?.id,
      dataSource: value,
      ...additionalValues,
    });
  };

  const handleUnlink = save => {
    save({
      integrationType: integration?.type,
      orgIntegrationId: integration?.id,
      dataSource: null,
    });

    setIntegration({});
    setShowConfirmDialog(false);
  };

  const renderEditor = ({ value: editingValue, onChange, save, cancel }) => {
    const value = isString(editingValue) ? editingValue : editingValue?.dataSource || '';

    const renderDefaultComponent = () => (
      <Container>
        <DataSourceUrlInput
          cancel={cancel}
          disableEdit={disableEdit}
          handleInputChange={handleInputChange}
          integrationData={integrationData}
          onChange={onChange}
          value={value}
        />
        <LinkButtons
          disableEdit={disableEdit}
          handleLink={handleLink}
          metricIntegration={integrationData}
          orgIntegration={integration}
          originalValue={originalValue}
          save={save}
          setShowConfirmDialog={setShowConfirmDialog}
          value={value}
        />
      </Container>
    );

    const EditorComponent = getEditorComponentFromIntegrationConfig(integrationConfig);

    return (
      <>
        {EditorComponent ? (
          <EditorComponent
            cancel={cancel}
            disableEdit={disableEdit}
            handleInputChange={handleInputChange}
            handleLink={handleLink}
            integrationData={integrationData}
            onChange={onChange}
            originalValue={originalValue}
            orgIntegration={integration}
            save={save}
            setShowConfirmDialog={setShowConfirmDialog}
            value={value}
          />
        ) : (
          renderDefaultComponent()
        )}
        <ConfirmDialog
          isOpen={showConfirmDialog}
          onCancel={() => setShowConfirmDialog(false)}
          onConfirm={() => handleUnlink(save)}
          title="Remove Integration"
          text="Are you sure you want to remove this Integration?"
        />
      </>
    );
  };

  return <FluidField {...props} editorRenderer={renderEditor} />;
};

export default FluidMetricDataSourceField;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2)}px;
`;
