import { createSelector } from 'reselect';
import cloneDeep from 'lodash/cloneDeep';
import { defaultTo, indexBy, is, prop } from 'ramda';

import { getCustomerRequestsCustomFields } from 'store/customFields/selectors';
import { getNormalizedRoadmaps } from 'store/roadmaps/selectors';
import { getAllCustomers } from 'store/customers/selectors';
import { getAllTags } from 'store/tags/selectors';
import { getVotesPerCustomerRequest } from 'store/votes/selectors';
import { getNormalizedPhases } from 'store/phases/selectors';
import { calculateRiceScore, getUserName } from 'utils';
import { getData, isLoading, isUninitialized } from 'utils/store/thunk';

import priorities from 'store/customerRequests/constants/priority';
import statuses from 'store/customerRequests/constants/status';
import { AVAILABLE_VIEWS } from 'constants/customerRequests';
import sortByRank from 'utils/sortByRank';
import compileFilters from './helpers/compileFilters';
import {
  MERGE_CUSTOMER_REQUESTS,
  FETCH_CUSTOMER_REQUESTS,
  FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_PIE_CHART,
  FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_STACKED_CHART,
} from './types';
import { GROUP_BY_OPTIONS } from 'routes/CustomerRequests/Insights/constants';
import { REQUESTS_FILTERS } from 'constants/filters';
import getEnrichedWithCustomFields from 'utils/customerRequests/getEnrichedWithCustomFields';

const emptyArray = [];
const defaultToZero = defaultTo(0);
const byId = indexBy(prop('id'));

const filterOutMergedRequests = r => !r.parent_id;

const ensureFilterStateIsArray = userFilter => {
  if (is(Object, userFilter.state)) {
    return { ...userFilter, state: Object.values(userFilter.state) };
  }

  return userFilter;
};

export function getState(state) {
  return state.customerRequests;
}

export const getSelectedView = createSelector(
  state => getState(state).selectedView,
  selectView => selectView || AVAILABLE_VIEWS.LIST,
);

export const getMetadata = createSelector(
  getNormalizedRoadmaps,
  getAllCustomers,
  getAllTags,
  getCustomerRequestsCustomFields,
  getVotesPerCustomerRequest,
  (roadmaps, customers, tags, customFields, votes) => {
    return {
      roadmaps,
      customers: byId(customers),
      tags: byId(tags),
      customFields,
      votes,
    };
  },
);

export const getCalculatedValues = customerRequest => {
  const {
    business_value: benefit,
    reach_score: reachScore,
    impact_score: impactScore,
    confidence_score: confidenceScore,
    effort_score: effortScore,
  } = customerRequest;

  const calculatedMoar = benefit && effortScore ? (benefit / effortScore) * 100 : undefined;
  const calculatedRiceScore = calculateRiceScore(reachScore, impactScore, confidenceScore, effortScore);

  return {
    calculatedMoar,
    calculatedRiceScore,
  };
};

export const getPrecalculationsValues = customerRequest => {
  const { preCalculations } = customerRequest;

  const { total_revenue: totalRevenue, total_churned_revenue: totalChurnedRevenue } = preCalculations || {};

  const totalNetRevenue = defaultToZero(totalRevenue) + defaultToZero(totalChurnedRevenue);

  return {
    ...preCalculations,
    totalRevenue,
    totalChurnedRevenue,
    totalNetRevenue,
  };
};

export const getEnrichedCustomerRequest = (id, metadata, customerRequests) => {
  const { roadmaps, customers, tags, customFields, votes } = metadata;
  const customerRequest = { ...defaultTo({}, customerRequests[id]) };

  const enrichedCustomerRequest = getEnrichedWithCustomFields(customerRequest, customFields);
  const { calculatedMoar, calculatedRiceScore } = getCalculatedValues(customerRequest);

  const customerRequestVotes = (votes && votes[id]) || { count: 0, had_vote: false };

  const product1 =
    customerRequest.product_1_id && roadmaps[customerRequest.roadmap_id]
      ? roadmaps[customerRequest.roadmap_id].products.find(p => p.id === customerRequest.product_1_id)
      : null;
  const product2 =
    customerRequest.product_2_id && product1 ? product1.products?.find(p => p.id === customerRequest.product_2_id) : null;

  return {
    ...enrichedCustomerRequest,
    created_by: customerRequest.created_by
      ? { ...customerRequest.created_by, name: getUserName(customerRequest.created_by) }
      : null,
    owner: customerRequest.owner ? { ...customerRequest.owner, name: getUserName(customerRequest.owner) } : null,
    roadmap: customerRequest.roadmap_id ? roadmaps[customerRequest.roadmap_id] : null,
    product1,
    product2,
    customers: (customerRequest.customer_ids || []).map(id => customers[id]).filter(obj => obj),
    tags: (customerRequest.tag_ids || []).map(id => tags[id]).filter(obj => obj),
    votes: customerRequestVotes,
    moar: calculatedMoar,
    rice_score: calculatedRiceScore,
    ...(customerRequest.preCalculations ? { preCalculations: getPrecalculationsValues(customerRequest) } : {}),
  };
};

export const getGridCustomerRequest = (metadata, customerRequest) => {
  const { roadmaps, customers, tags, customFields } = metadata;

  const enrichedCustomerRequest = getEnrichedWithCustomFields({ ...defaultTo({}, customerRequest) }, customFields);
  const { calculatedMoar, calculatedRiceScore } = getCalculatedValues(customerRequest);

  const product1 =
    customerRequest.product_1_id && roadmaps[customerRequest.roadmap_id]
      ? roadmaps[customerRequest.roadmap_id].products.find(p => p.id === customerRequest.product_1_id)
      : null;
  const product2 =
    customerRequest.product_2_id && product1 ? product1.products?.find(p => p.id === customerRequest.product_2_id) : null;

  return {
    ...enrichedCustomerRequest,
    roadmap: customerRequest.roadmap_id ? roadmaps[customerRequest.roadmap_id] : null,
    created_by_name: customerRequest.created_by ? getUserName(customerRequest.created_by) : '',
    owner: customerRequest.owner ? { ...customerRequest.owner, name: getUserName(customerRequest.owner) } : null,
    owner_name: customerRequest.owner ? getUserName(customerRequest.owner) : '',
    roadmap_title: customerRequest.roadmap_id ? (roadmaps[customerRequest.roadmap_id] || {}).title : '',
    product1_title: product1 ? product1.title : '',
    product2_title: product2 ? product2.title : '',
    status_label: (statuses[customerRequest.status] || {}).label,
    customers: (customerRequest.customer_ids || []).map(id => customers[id]).filter(obj => obj),
    tags: (customerRequest.tag_ids || []).map(id => tags[id]).filter(obj => obj),
    moar: calculatedMoar,
    rice_score: calculatedRiceScore,
    ...(customerRequest.preCalculations ? { preCalculations: getPrecalculationsValues(customerRequest) } : {}),
  };
};

export const getCustomerRequests = createSelector(
  state => getState(state).byId,
  state => getState(state).allIds,
  getMetadata,
  (byId, allIds, metadata) => {
    return allIds
      .map(id => getEnrichedCustomerRequest(id, metadata, byId))
      .filter(filterOutMergedRequests)
      .sort(sortByRank);
  },
);

export const getGridCustomerRequests = createSelector(
  state => getState(state).byId,
  state => getState(state).allIds,
  getMetadata,
  (byId, allIds, metadata) => {
    return allIds
      .map(id => getGridCustomerRequest(metadata, byId[id]))
      .filter(filterOutMergedRequests)
      .sort(sortByRank);
  },
);

export const getTotalCustomerRequests = createSelector(getState, state => state.totalResultsCount);

/**
 * Returns all customer requests from the redux store
 * @param  {Object} state      Redux state
 * @return {Array}             All customer requests
 */
export const getAllCustomerRequests = createSelector(
  state => getState(state).byId,
  getMetadata,
  state => getState(state).allIds,
  (byId, metadata, ids) => {
    return ids.map(id => getEnrichedCustomerRequest(id, metadata, byId)).filter(filterOutMergedRequests);
  },
);

/**
 * Returns customer requests from the redux store matching a given array of ids
 * @param  {Object} state      Redux state
 * @param  {Array} ids         Array of customer request ids
 * @return {Array}             Customer requests filtered by ids
 */
export const getCustomerRequestsByIds = createSelector(
  state => getState(state).byId,
  getMetadata,
  (_, ids = []) => ids,
  (byId, metadata, ids) => {
    return ids.map(id => getEnrichedCustomerRequest(id, metadata, byId));
  },
);

export const getIsCreatingOrEditingCustomerRequest = createSelector(getState, state => {
  return state.editing || state.creating;
});

export const getIsCreatingCustomerRequest = createSelector(getState, state => {
  return state.creating;
});

export const getIsEditingCustomerRequest = createSelector(getState, state => {
  return state.editing;
});

export const getIsCommmentingCustomerRequest = createSelector(getState, state => {
  return state.commenting;
});

export const getCustomerRequestFormData = createSelector(
  state => getState(state),
  getMetadata,
  getNormalizedPhases,
  (state, metadata, phases) => {
    const { byId, formData } = state;

    const requests = cloneDeep(byId);

    requests[formData.id] = formData;

    const enrichedRequest = getEnrichedCustomerRequest(formData.id, metadata, requests);

    const projects = (enrichedRequest?.projects || []).map(project => {
      const phase = phases[project.phase_id];

      return { ...project, phase };
    });

    return {
      ...enrichedRequest,
      projects,
    };
  },
);

export const getFilters = createSelector(
  state => getState(state).filters,
  getMetadata,
  (filters = [], metadata) => {
    const { roadmaps, customers, tags } = metadata;

    return {
      roadmap_id: filters.roadmap_id ? filters.roadmap_id.map(id => roadmaps[id]) : null,
      customer_ids: filters.customer_ids ? filters.customer_ids.map(id => customers[id]) : null,
      tag_ids: filters.tag_ids ? filters.tag_ids.map(id => tags[id]) : null,
      priority: filters.priority ? filters.priority.map(val => priorities[val]) : null,
      status: filters.status ? filters.status.map(val => statuses[val]) : null,
    };
  },
);

export const getSearch = createSelector(
  state => getState(state).search,
  search => search,
);

export const getMultiFilters = createSelector(
  state => defaultTo(emptyArray, getState(state).multiFilters),
  multiFilters => multiFilters.filter(Boolean),
);

export const getFiltersCompiled = createSelector(
  state => getState(state).search || '',
  state => defaultTo(emptyArray, getState(state).multiFilters),
  (_, withChildren) => withChildren,
  (search, multiFilters, withChildren) => {
    return compileFilters(search, multiFilters.filter(Boolean), withChildren);
  },
);

export const getNonDefaultMultiFiltersSaved = createSelector(
  state => getState(state).multiFiltersSaved || {},
  multiFilters => {
    const nonDefaultFilters = Object.values(multiFilters).filter(savedFilter => !savedFilter.default_filter);

    return indexBy(prop('id'), nonDefaultFilters);
  },
);

export const getLoadedCustomerRequestsUserFilters = createSelector(
  state => state._filters.userFilters,
  (userFilters = []) => {
    return userFilters.filter(userFilter => userFilter.page === REQUESTS_FILTERS).map(ensureFilterStateIsArray);
  },
);

export const getCustomerRequestsDefaultUserFilter = createSelector(getLoadedCustomerRequestsUserFilters, (userFilters = []) =>
  userFilters.find(userFilter => userFilter.default_filter),
);

export const getMultiFilterActive = createSelector(
  state => getState(state).multiFilterActive || null,
  multiFilters => multiFilters,
);

export const getIsCustomFieldsVisible = createSelector(getState, state => state.customFieldsDrawer);

export const getIsPortalSettingsVisible = createSelector(getState, state => state.portalSettingsDrawer);

export const getPortalSettings = createSelector(getState, state => state.portalSettings);

export const getIsForwardEmailsVisible = createSelector(getState, state => state.forwardEmailsDrawer);

export const getVisibleFields = createSelector(getState, state => state.visibleFields);

export const getIsBulkMerge = createSelector(getState, state => state.isBulkMerge);

export const getIsBulkMergeDialogVisible = createSelector(getState, state => state.isBulkMergeDialogVisible);

export const getIsBulkUpdate = createSelector(getState, state => state.isBulkUpdate);

export const getIsBulkUpdatePending = createSelector(getState, state => state.isBulkUpdatePending);

export const getResultsPage = createSelector(getState, state => state.paginationInformation);

export const getPageLimit = createSelector(getState, state => state.pageLimit);

export const getTotalResults = createSelector(getState, state => state.totalResultsCount);

// TODO: move this to insights store
export const getSelectedCounterPieChartGroupBy = createSelector(
  state => getState(state).selectedCounterPieChartGroupBy || GROUP_BY_OPTIONS.find(o => o.key === 'roadmap_id'),
  selectedCounterPieChartGroupBy => selectedCounterPieChartGroupBy,
);

// TODO: move this to insights store
export const getSelectedCounterStackedChartGroupBy = createSelector(
  state => getState(state).selectedCounterStackedChartGroupBy || GROUP_BY_OPTIONS.find(o => o.key === 'roadmap_id'),
  selectedCounterStackedChartGroupBy => selectedCounterStackedChartGroupBy,
);

// TODO: move this to insights store
export const getSelectedCounterStackedChartStackedBy = createSelector(
  state => getState(state).selectedCounterStackedChartStackedBy || GROUP_BY_OPTIONS.find(o => o.key === 'customer_id'),
  selectedCounterStackedChartStackedBy => selectedCounterStackedChartStackedBy,
);

// Operations
const getOperationsState = state => {
  return getState(state).operations || {};
};

export const getIsLoadingCustomerRequests = createSelector(getOperationsState, state =>
  isLoading(state, FETCH_CUSTOMER_REQUESTS),
);

export const getInsightsDataForCounterPieChart = createSelector(getOperationsState, state =>
  getData(state, FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_PIE_CHART),
);

export const isUninitializedInsightsDataForCounterPieChart = createSelector(getOperationsState, state =>
  isUninitialized(state, FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_PIE_CHART),
);

export const getInsightsDataForCounterStackedChart = createSelector(getOperationsState, state =>
  getData(state, FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_STACKED_CHART),
);

export const isUninitializedInsightsDataForCounterStackedChart = createSelector(getOperationsState, state =>
  isUninitialized(state, FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_STACKED_CHART),
);

export const getIsMergingRequests = createSelector(getOperationsState, state => isLoading(state, MERGE_CUSTOMER_REQUESTS));
