import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import AddIcon from '@material-ui/icons/Add';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import ProjectLayersTabs from '../../../components/ProjectLayersTabs/ProjectLayersTabs';

import FieldMapping from '../FieldMapping';
import ProgressTracking from '../ProgressTracking';
import MappingTabs, { TABS_OPTIONS } from '../MappingTabs';

import AddNewMappingInput from './components/AddNewMappingInput';

const JiraSettings = props => {
  const {
    orgIntegrationId,
    hasMappingByProject,
    integrationSettings,
    mappings,
    selectedMappingOperation,
    setSelectedMappingOperation,
    onChangeProgressTracking,
    onSaveFieldMapping,
    onRemoveFieldMapping,
    onAddNewJiraProject,
    availableJiraProjects,
    isAddMappingVisible,
    onStartAddMappingFlow,
    selectedMappingLayer,
    setSelectedMappingLayer,
    userCanUpdateOrgIntegration,
  } = props;

  return (
    <Wrapper elevation={1}>
      {hasMappingByProject ? (
        <ProjectLayersTabs selectedOption={selectedMappingLayer} onChange={setSelectedMappingLayer} />
      ) : null}
      {mappings.map(mapping => (
        <CollapsePanel key={mapping.key} elevation={0}>
          <CollapsePanelSummary expandIcon={<ExpandMoreIcon />}>
            <Title size="medium">{mapping.title}</Title>
          </CollapsePanelSummary>
          <CollapsePanelDetails>
            <MappingTabs selectedOption={selectedMappingOperation} onChange={setSelectedMappingOperation} />
            <MappingOperationsContainer>
              {selectedMappingOperation === TABS_OPTIONS.FIELD_MAPPING && (
                <Grid container>
                  <Grid item xs={12}>
                    <FieldMapping
                      mappingKey={mapping.key}
                      orgIntegrationId={orgIntegrationId}
                      data={mapping.fieldsMapping}
                      integrationSettings={integrationSettings}
                      onSave={data => onSaveFieldMapping(mapping.key, data)}
                      onRemove={() => onRemoveFieldMapping(mapping.key)}
                      mappingLayer={selectedMappingLayer}
                      userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
                    />
                  </Grid>
                </Grid>
              )}
              {selectedMappingOperation === TABS_OPTIONS.PROGRESS_TRACKING && (
                <ProgressTracking
                  data={mapping.progressTracking}
                  onChange={data => onChangeProgressTracking(mapping.key, data)}
                />
              )}
            </MappingOperationsContainer>
          </CollapsePanelDetails>
        </CollapsePanel>
      ))}
      {hasMappingByProject && (
        <>
          {isAddMappingVisible && (
            <AddNewMappingInput availableJiraProjects={availableJiraProjects} onChange={onAddNewJiraProject} />
          )}
          <ButtonConainer>
            <Button
              id="add-mapping-button"
              color="primary"
              onClick={onStartAddMappingFlow}
              disabled={!userCanUpdateOrgIntegration}
            >
              <AddIcon />
              Add new custom field mapping
            </Button>
          </ButtonConainer>
        </>
      )}
    </Wrapper>
  );
};

JiraSettings.propTypes = {
  orgIntegrationId: PropTypes.number,
  hasMappingByProject: PropTypes.bool,
  integrationSettings: PropTypes.object,
  mappings: PropTypes.array.isRequired,
  selectedMappingOperation: PropTypes.string,
  setSelectedMappingOperation: PropTypes.func,
  onChangeProgressTracking: PropTypes.func,
  onSaveFieldMapping: PropTypes.func,
  onRemoveFieldMapping: PropTypes.func,
  onAddNewJiraProject: PropTypes.func,
  availableJiraProjects: PropTypes.array,
  isAddMappingVisible: PropTypes.bool,
  onStartAddMappingFlow: PropTypes.func,
  userCanUpdateOrgIntegration: PropTypes.bool,
};

const Wrapper = styled(Paper)`
  &&&& {
    margin-bottom: 24px;
    border-radius: 2px;
    padding: 0 20px;
  }
`;

const CollapsePanel = styled(ExpansionPanel)`
  &&&& {
    border-radius: 0;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #3333331a;
  }
`;

const CollapsePanelSummary = styled(ExpansionPanelSummary)`
  &&&& {
    padding: 0;

    > div:nth-child(1) {
      padding-left: 34px;
      line-height: 22px;
    }
    > div:nth-child(2) {
      left: -12px;
      right: auto;

      span {
        color: #333;
      }
    }
  }
`;

const CollapsePanelDetails = styled(ExpansionPanelDetails)`
  &&&& {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 0 20px 28px;
  }
`;

const MappingOperationsContainer = styled.div`
  flex: 1;
`;

const ButtonConainer = styled.div`
  padding: 14px 0;
`;

const Title = styled(TextDeprecated)`
  &&&& {
    color: ${props => (props.isExpanded ? props.theme.palette.primary.main : '')};
  }
`;

export default JiraSettings;
