import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeMetadataPopover, OBJECTIVE_METADATA_POPOVER, PROJECT_METADATA_POPOVER } from 'store/metadataPopover';
import { isMetadataPopoverOpen, selectMetadataSelected } from 'store/metadataPopover/selectors';
import history from 'store/utils/history';
import { closeProjectLightbox } from 'store/projectLightbox';
import { closeObjectiveDrawer } from 'store/objectives/actions';
import { updateMetadataNotes } from 'store/organization';
import { getOrganizationMetadataNotes, getOrgHasMetadataRoadmaps } from 'store/organization/selectors';
import { getCurrentUserRoleId } from 'store/login/selectors';
import isOldLightboxActive from 'containers/ProjectLightBox/utils/isOldLightboxActive';

import Roadmaps from 'routes/Settings/Roadmaps';
import Objectives from 'routes/Settings/Objectives';
import Timeframes from 'routes/Settings/Timeframes';
import Priorities from 'routes/Settings/Priorities';
import Themes from 'routes/Settings/Themes';
import Categories from 'routes/Settings/Categories';
import CustomFields from 'routes/Settings/CustomFields';
import Tags from 'routes/Settings/Tags';
import Customers from 'routes/Settings/Customers';
import Phases from 'routes/Settings/Phases';
import Metrics from 'routes/Settings/Metrics';
import Teams from 'routes/Settings/Teams';
import { PRIORITY_ROADMAPS_FIELD } from 'routes/Settings/Priorities/New/hooks/usePrioritiesGridColumns';
import { TIMEFRAME_ROADMAPS_FIELD } from 'routes/Settings/Timeframes/New/hooks/useTimeframesGridColumns';
import { PHASE_ROADMAPS_FIELD } from 'routes/Settings/Phases/New/hooks/usePhasesGridColumns';
import { THEME_ROADMAP_FIELD } from 'routes/Settings/Themes/New/hooks/useThemesGridColumns';
import { CATEGORY_ROADMAP_FIELD } from 'routes/Settings/Categories/New/hooks/useCategoriesGridColumns';
import { CUSTOMER_ROADMAP_FIELD } from 'routes/Settings/Customers/New/hooks/useCustomersGridColumns';
import { TAG_ROADMAPS_FIELD } from 'routes/Settings/Tags/New/hooks/useTagsGridColumns';
import { METRIC_ROADMAP_FIELD } from 'hooks/grid/metrics/useMetricsGridColumns';
import { OWNER_USER, LEADER_USER } from '@dragonboat/permissions';
import { metadataKeys } from 'utils/metadata/metadataKeys';
import { ROADMAP_OBJECTIVE_FIELD_KEY, ROADMAP_FIELD_KEY } from 'routes/Settings/Objectives/New/hooks/useObjectivesGridColumns';
import { TEAM_ROADMAP_FIELD } from 'routes/Settings/Teams/helpers/constants';

const mapMetadataSettingsLinks = {
  user: '/settings/users',
  roadmap: '/settings/roadmaps',
  objective: '/settings/objectives',
  timeframe: '/settings/timeframes',
  priority: '/settings/priorities',
  theme: '/settings/themes',
  category: '/settings/categories',
  customFields: '/settings/customfields',
  tag: '/settings/tags',
  customer: '/settings/customers',
  phase: '/settings/phases',
  metric: '/settings/metrics',
  team: '/settings/teams',
};

const getVisibleFields = hideMetadataRoadmaps => [
  'drag',
  'title',
  'name',
  'color',
  'actions',
  ...(!hideMetadataRoadmaps
    ? [
        PRIORITY_ROADMAPS_FIELD,
        TIMEFRAME_ROADMAPS_FIELD,
        PHASE_ROADMAPS_FIELD,
        THEME_ROADMAP_FIELD,
        CATEGORY_ROADMAP_FIELD,
        CUSTOMER_ROADMAP_FIELD,
        TAG_ROADMAPS_FIELD,
        ROADMAP_OBJECTIVE_FIELD_KEY,
        ROADMAP_FIELD_KEY,
        METRIC_ROADMAP_FIELD,
        TEAM_ROADMAP_FIELD,
      ]
    : []),
];

const exist = Boolean;

const OLD_LIGHTBOX_HEIGHT = 'calc(100vh - 300px)';
const DEFAULT_HEIGHT = 'calc(100vh - 190px)';

export default metadataPopoverType => {
  const dispatch = useDispatch();

  const oldLightboxActive = isOldLightboxActive();

  const NEW_SETTINGS_TABLE_HEIGHT = useMemo(
    () => (oldLightboxActive ? OLD_LIGHTBOX_HEIGHT : DEFAULT_HEIGHT),
    [oldLightboxActive],
  );

  const isOpen = useSelector(state => isMetadataPopoverOpen(state, metadataPopoverType));
  const metadataSelected = useSelector(state => selectMetadataSelected(state, metadataPopoverType));
  const metadataNotes = useSelector(getOrganizationMetadataNotes);
  const currenUserRole = useSelector(getCurrentUserRoleId);
  const hideMetadataRoadmaps = !useSelector(getOrgHasMetadataRoadmaps);

  const metadataSelectedSettingsLink = mapMetadataSettingsLinks[metadataSelected] || '';

  const metadataSelectedNotes = useMemo(() => metadataNotes.find(meta => meta.key === metadataSelected), [metadataSelected]);

  const isAllowedToWriteMetadataNotes = useMemo(() => [OWNER_USER, LEADER_USER].includes(currenUserRole), [currenUserRole]);
  const onClose = closeAll => {
    if (closeAll) {
      dispatch(closeMetadataPopover(OBJECTIVE_METADATA_POPOVER));
      dispatch(closeMetadataPopover(PROJECT_METADATA_POPOVER));
    } else dispatch(closeMetadataPopover(metadataPopoverType));
  };

  const onSettingsLinkClicked = () => {
    history.push(metadataSelectedSettingsLink);
    onClose(true);
    dispatch(closeProjectLightbox());
    dispatch(closeObjectiveDrawer());
  };

  const updateNote = note => {
    if (!exist(metadataSelected) || !isAllowedToWriteMetadataNotes) return;

    dispatch(updateMetadataNotes({ key: metadataSelected, note }));
  };

  const contentComponent = useCallback(
    ({ height, actionsPadding }) => {
      let Component = null;

      const MetadataComponent = {
        [metadataKeys.roadmap]: Roadmaps,
        [metadataKeys.objective]: Objectives,
        [metadataKeys.timeframe]: Timeframes,
        [metadataKeys.priority]: Priorities,
        [metadataKeys.theme]: Themes,
        [metadataKeys.category]: Categories,
        [metadataKeys.customFields]: CustomFields,
        [metadataKeys.tag]: Tags,
        [metadataKeys.customer]: Customers,
        [metadataKeys.phase]: Phases,
        [metadataKeys.metric]: Metrics,
        [metadataKeys.team]: Teams,
      };

      const MetadataComponentShowSearchBar = {
        [metadataKeys.metric]: true,
      };

      if (!MetadataComponent[metadataSelected]) return null;

      Component = MetadataComponent[metadataSelected];

      return (
        <Component
          showActionBar={MetadataComponentShowSearchBar[metadataSelected] || false}
          showSettingsToggle={false}
          forceVisibleFields={getVisibleFields(hideMetadataRoadmaps)}
          height={height}
          customHeight={NEW_SETTINGS_TABLE_HEIGHT}
          actionsPadding={actionsPadding}
          hideArchivedItems
        />
      );
    },
    [metadataSelected],
  );

  return {
    oldLightboxActive,
    isOpen,
    onClose,
    metadataSelected,
    metadataSelectedSettingsLink,
    contentComponent,
    onSettingsLinkClicked,
    metadataSelectedNotes,
    updateNote,
    isAllowedToWriteMetadataNotes,
  };
};
