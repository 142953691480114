import { useCallback } from 'react';
import { both, defaultTo, propEq, pipe } from 'ramda';

import { METADATA_LEVELS } from 'constants/common';

import { OBJECT_OBJECTIVE } from 'store/objectives/types';

const defaultToEmptyObject = defaultTo({});
const isTypeObjective = pipe(defaultToEmptyObject, propEq('type', OBJECT_OBJECTIVE));
const isLevelCorp = pipe(defaultToEmptyObject, propEq('level', METADATA_LEVELS.LEVEL_CORP));
const isBothObjectiveAndLevelCorp = both(isTypeObjective, isLevelCorp);

import useOkrsRowDrag from 'containers/Grids/OkrsGrid/hooks/useOkrsRowDrag';

const useObjectivesSettingsGrid = ({
  addKeyResultWithoutSave,
  switchObjectivesRowOrder,
  switchKeyResultsRowOrder,
  moveKeyResultToObjective,
  fetchObjectives,
  addObjectiveWithoutSave,
  moveObjectiveToObjective,
  hasCorpLevel,
}) => {
  const handleAddOKRWithoutSave = useCallback(
    (data, { node }) => {
      if (!node.expanded) {
        node.setExpanded(true);
      }

      return isBothObjectiveAndLevelCorp(data) ? addObjectiveWithoutSave(data) : addKeyResultWithoutSave(data);
    },
    [addKeyResultWithoutSave, addObjectiveWithoutSave],
  );

  const handleRowDrag = useOkrsRowDrag({
    switchObjectivesRowOrder,
    switchKeyResultsRowOrder,
    moveKeyResultToObjective,
    moveObjectiveToObjective,
    fetchObjectives,
    hasCorpLevel,
  });

  return {
    handleAddOKRWithoutSave,
    handleRowDrag,
  };
};

export default useObjectivesSettingsGrid;
