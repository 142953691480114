import {
  PROCESS_NOTIFICATION,
  SHOW_NOTIFICATION_COMMENT,
  SHOW_CUSTOMER_REQUEST_NOTIFICATION_COMMENT,
  JIRA_UPDATE_ERROR,
  JIRA_PROJECT_RENAMED_WARNING,
  SHOW_NOTIFICATION_FIELD,
  SHOW_NOTIFICATION_FIELD_DEPS,
  SHOW_NOTIFICATION_HEALTH,
  SHOW_NOTIFICATION_HEALTH_DEPS,
} from '../types';
import { openProjectLightbox } from 'store/projectLightbox';
import { loadAndOpenCustomerRequest } from 'store/customerRequests/actions';
import { TAB_COMMENTS } from 'constants/projectLightbox';

export default store => next => action => {
  if (action.type === PROCESS_NOTIFICATION) {
    switch (action.payload.notification_type) {
      case SHOW_NOTIFICATION_FIELD:
        if (action.payload.notification_payload) {
          store.dispatch(openProjectLightbox(action.payload.notification_payload.projects[0]));
        }
        break;
      case SHOW_NOTIFICATION_FIELD_DEPS:
        if (action.payload.notification_payload) {
          store.dispatch(openProjectLightbox(action.payload.notification_payload.projects[0]));
        }
        break;
      case SHOW_NOTIFICATION_HEALTH:
        if (action.payload.notification_payload) {
          store.dispatch(openProjectLightbox(action.payload.notification_payload.projects[0]));
        }
        break;
      case SHOW_NOTIFICATION_HEALTH_DEPS:
        if (action.payload.notification_payload) {
          store.dispatch(openProjectLightbox(action.payload.notification_payload.projects[0]));
        }
        break;
      case SHOW_NOTIFICATION_COMMENT:
        if (action.payload.notification_payload) {
          store.dispatch(openProjectLightbox(action.payload.notification_payload.projects, TAB_COMMENTS));
        }
        break;
      case SHOW_CUSTOMER_REQUEST_NOTIFICATION_COMMENT:
        if (action.payload.notification_payload) {
          store.dispatch(loadAndOpenCustomerRequest(action.payload.notification_payload.customer_request_key));
        }
        break;
      case JIRA_UPDATE_ERROR:
        if (action.payload.notification_payload) {
          store.dispatch(openProjectLightbox(action.payload.notification_payload.project.id));
        }
        break;
      case JIRA_PROJECT_RENAMED_WARNING:
        if (action.payload.notification_payload) {
          store.dispatch(openProjectLightbox(action.payload.notification_payload.project.id));
        }
        break;
      default:
        break;
    }
  }

  return next(action);
};
