import React from 'react';
import { useSelector } from 'react-redux';
import { defaultTo, head, pipe, prop } from 'ramda';
import styled from 'styled-components';

import Loading from 'design-system/atoms/Loading/Loading';

import { useCommonGridProps } from 'design-system/molecules/AgGridReact-New/hooks';

import { selectIsLoadingGoalsAllocationData, selectIsUnitializedGoalsAllocationData } from '../store/selectors';

import { BaseGrid } from 'containers/Grids';

import useGoalsAllocationGridColumns from '../hooks/useGoalsAllocationGridColumns';
import useGoalsAllocationGrid from '../hooks/useGoalsAllocationGrid';

import { BASE_ROW_HEIGHT } from 'constants/grid';
import { getDynamicFields } from '../helpers/getDynamicFields';
import { getRowId } from 'design-system/molecules/AgGridReact-New/helpers';

const getObjectiveLevel = pipe(defaultTo([]), head, prop('level'));

const GRID_HEIGHT = `calc(50vh - 152px)`;

const GoalsAllocationGrid = ({ allocationData, preferencesOptions, allocationBy }) => {
  const isLoadingData = useSelector(selectIsLoadingGoalsAllocationData);
  const isUninitializedData = useSelector(selectIsUnitializedGoalsAllocationData);

  const commonGridProps = useCommonGridProps({});

  const { openObjectiveDrawer } = useGoalsAllocationGrid();

  const { columnDefs, defaultColDef } = useGoalsAllocationGridColumns({
    selectedField: getObjectiveLevel(allocationData),
    handleOpenItem: openObjectiveDrawer,
    rowHeight: BASE_ROW_HEIGHT,
    dynamicColumns: getDynamicFields(allocationData),
    preferencesOptions,
    allocationBy,
  });

  if (isLoadingData || isUninitializedData) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  return (
    <BaseGrid
      {...commonGridProps}
      rowData={allocationData}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      rowHeight={BASE_ROW_HEIGHT}
      headerHeight={BASE_ROW_HEIGHT}
      height={GRID_HEIGHT}
      getRowId={getRowId}
    />
  );
};

export default GoalsAllocationGrid;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: ${GRID_HEIGHT};
`;
