import React from 'react';
import { withTheme } from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

function SvgComponent(props) {
  const fill = props.fill || props.theme.palette.text.primary;

  return (
    <SvgIcon fontSize="inherit" width="22.116" height="22.115" viewBox="0 0 22.116 22.115" {...props}>
      <g id="icon-progress" transform="translate(-42.674 -849.18)" opacity="0.6">
        <g id="Group_8930" data-name="Group 8930">
          <path 
            id="Path_15618" 
            data-name="Path 15618"
            d="M63.79,871.295H46.548a3.879,3.879,0,0,1-3.874-3.874V850.18a1,1,0,0,1,2,0v17.241a1.877,
            1.877,0,0,0,1.874,1.874H63.79a1,1,0,1,1,0,2Z"
            fill={fill}
          />
        </g>
        <path 
          id="Path_15619" 
          data-name="Path 15619"
          d="M47.561,861.391a1,1,0,0,1-.776-1.631l3-3.691a1,1,0,0,1,1.223-.263l3.172,1.586,2.524-4.206a1,1,0,0,1,
          .481-.413l5.824-2.366a1,1,0,0,1,.753,1.854l-5.515,2.24-2.829,4.714a1,1,0,0,1-1.3.38l-3.283-1.642-2.5,
          3.068A1,1,0,0,1,47.561,861.391Z"
          fill={fill}
        />
        <path 
          id="Path_15620" 
          data-name="Path 15620"
          d="M47.562,867.6a1,1,0,0,1-.593-1.807l4.763-3.495a1,1,0,0,1,1.021-.1l3.237,1.538,2.487-4.537a1,1,0,0,1,
          .441-.42l4.312-2.087a1,1,0,0,1,.872,1.8l-4.025,1.947-2.787,5.085a1,1,0,0,1-1.306.423l-3.55-1.687L48.153,
          867.4A1,1,0,0,1,47.562,867.6Z" 
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
}

export default withTheme(SvgComponent);
