// External dependencies
import { createSelector } from 'reselect';

// Dragonboat depencies

export function getState(state) {
  return state.comments;
}

export const getAllProjectsComments = createSelector(getState, state => (state.projects ? state.projects.toJS() : {}));

export const getAllCustomerRequestsComments = createSelector(getState, state => state.byCustomerRequest || {});

const makeGetProjectComments = () => {
  return createSelector(
    getAllProjectsComments,
    (_, projectId) => projectId,
    (comments, projectId) => {
      const projectComments = comments[projectId] || {};

      return projectComments.data || [];
    },
  );
};

const makeGetCustomerRequestComments = () => {
  return createSelector(
    getAllCustomerRequestsComments,
    (_, customerRequestId) => customerRequestId,
    (comments, customerRequestId) => {
      const customerRequestComments = comments[customerRequestId] || {};

      return customerRequestComments;
    },
  );
};

export const getProjectComments = makeGetProjectComments();

export const getProjectCommentsMeta = createSelector(
  getAllProjectsComments,
  (_, projectId) => projectId,
  (comments, projectId) => {
    const projectComments = comments[projectId] || {};

    return projectComments._meta || {};
  },
);

export const getCustomerRequestComments = makeGetCustomerRequestComments();
