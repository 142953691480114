import React from 'react';
import Select from '@material-ui/core/Select';

import styled from 'styled-components';

import { conditionalOperators } from 'features/DBQL/AdvancedQueryBuilder/helpers/constants';
import { spacing } from 'design-system/theme';
import Text from 'design-system/atoms/Text';
import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown';

const isFirst = index => index === 0;
const WHERE_LABEL = 'Where';

const OperatorSelector = ({ index = 0, operator, onChangeOperator, canChangeOperator }) => {
  return (
    <Wrapper>
      {isFirst(index) || !canChangeOperator ? (
        <StyledText as="h4">{isFirst(index) ? WHERE_LABEL : operator}</StyledText>
      ) : (
        <StyledSelect disabled={!canChangeOperator} value={operator} onChange={e => onChangeOperator(e?.target?.value)}>
          {conditionalOperators.map(option => (
            <StyledMenuItemDropdown value={option}>{option}</StyledMenuItemDropdown>
          ))}
        </StyledSelect>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 80px;
  padding: 0 ${spacing()}px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledText = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
`;

export default OperatorSelector;
