import { CUSTOMER_REQUEST_LAYERS } from '../constants/layers';

const compileFilters = (search = '', multiFilters = [], withChildren = false) => {
  const finalFilters = { customFields: {} };

  multiFilters.forEach(mf => {
    if (mf.key === 'created_after') {
      if (mf.createdAfter) finalFilters.created_after = mf.createdAfter;
    } else if (mf.customKey === 'custom_fields') {
      finalFilters.customFields[mf.key] = mf.values;
    } else {
      finalFilters[mf.key] = mf.values;
    }
  });

  finalFilters.layer = withChildren ? undefined : CUSTOMER_REQUEST_LAYERS.REQUEST;

  return { ...finalFilters, search };
};

export default compileFilters;
