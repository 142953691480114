import React from 'react';
import styled from 'styled-components';

import MultiSelect from 'design-system/atoms/MultiSelect/index';


import { getColumnChangedForVisibility, getFieldKeyForMultiselect, makeSortByGridColumns } from '../helpers';

const MULTISELECT_OPTION_MAPPER = { label: 'headerName', value: 'field' };
const PLACEHOLDER = 'Type to search and add fields';

const sortByHeaderName = (a, b) => {
  return a.headerName.toLowerCase() < b.headerName.toLowerCase() ? -1 : 1;
};

const MultiSelectColumns = ({ groupFields, checkFieldVisible, changeColumnVisible, visibleColumns, hideColumns }) => {
  const sortedGroupFields = groupFields.sort(makeSortByGridColumns(visibleColumns));

  const visibleGroupFieldsKeys = sortedGroupFields
    .filter(({ field }) => checkFieldVisible(field))
    .map(({ field }) => getFieldKeyForMultiselect(field));

  const groupFieldsOptions = sortedGroupFields
    .map(f => ({ ...f, field: getFieldKeyForMultiselect(f.field) }))
    .sort(sortByHeaderName);

  const onChangeMultiSelect = fieldsKeys => {
    /* it should handle clear all visible columns on group */
    if (fieldsKeys.length === 0) {
      hideColumns(visibleGroupFieldsKeys);
      return;
    }

    const column = getColumnChangedForVisibility(groupFields, visibleGroupFieldsKeys, fieldsKeys);

    if (!column) {
      return;
    }

    changeColumnVisible(column.field, !checkFieldVisible(column.field));
  };

  return (
    <FluidMultiSelect
      options={groupFieldsOptions}
      optionsMapper={MULTISELECT_OPTION_MAPPER}
      placeholder={PLACEHOLDER}
      onChange={onChangeMultiSelect}
      value={visibleGroupFieldsKeys}
      hideCreateOption
      hideIcon
      inlinePlaceholder
    />
  );
};

export default MultiSelectColumns;

const FluidMultiSelect = styled(MultiSelect)`
  width: 100%;

  input {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  }
`;
