import reduceReducers from 'reduce-reducers';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  FETCH_METRIC_LEVELS,
  FETCH_METRIC_LEVELS_FULFILLED,
  UPDATE_METRIC_LEVELS,
  UPDATE_METRIC_LEVELS_FULFILLED,
} from './types';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  FETCH_METRIC_LEVELS,
  UPDATE_METRIC_LEVELS,
]);

const initialState = {
  metricLevels: {},
  ...thunksInitialState,
};

const metricLevelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_METRIC_LEVELS_FULFILLED:
    case UPDATE_METRIC_LEVELS_FULFILLED: {
      return {
        ...state,
        metricLevels: action.payload?.data?.levels || {},
      };
    }

    default: {
      return state;
    }
  }
};

export { initialState };

const reducer = reduceReducers(initialState, metricLevelsReducer, ...thunksReducers);

export default reducer;
