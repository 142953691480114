/* eslint-disable */
import React from 'react';

export default ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon icon-tabler icon-tabler-trash"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    stroke-width={2}
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
    <line x1={4} y1={7} x2={20} y2={7} color={color}></line>
    <line x1={10} y1={11} x2={10} y2={17} color={color}></line>
    <line x1={14} y1={11} x2={14} y2={17} color={color}></line>
    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" color={color}></path>
    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" color={color}></path>
  </svg>
);
