import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useSubscribeNewData from 'hooks/useSubscribeNewData';
import { topics } from 'constants/topics';
import { ROADMAPS } from 'store/grids';
import { getOrganization } from 'store/organization/selectors';
import { getCurrentUser } from 'store/login/selectors';
import { getUsers } from 'store/users/selectors';
import { updateSystemFieldsName as updateSystemFieldsNameAction } from 'store/organization';
import { checkRolePermission } from 'containers/UserPermission/utils';

const TOPICS_TO_SUBSCRIBE = [topics.ROADMAPS, topics.PRODUCTS, topics.USER_FILTERS, topics.USER_VIEWS];
const UPDATE_METADATA_PERMISSION = 'UPDATE_METADATA';

const RoadmapsHOC =
  Component =>
  ({ hideArchivedItems, showActionBar, customHeight, forceVisibleFields }) => {
    useSubscribeNewData(TOPICS_TO_SUBSCRIBE);

    const dispatch = useDispatch();

    const users = useSelector(getUsers);
    const currentUser = useSelector(getCurrentUser);
    const organization = useSelector(getOrganization);
    const allowActions = checkRolePermission(UPDATE_METADATA_PERMISSION, currentUser, organization, ROADMAPS);

    const updateSystemFieldsName = useCallback(
      fieldRename => dispatch(updateSystemFieldsNameAction(fieldRename)),
      [updateSystemFieldsNameAction],
    );

    return (
      <Component
        allowActions={allowActions}
        systemFields={organization.system_fields_name}
        hasProducts={organization.has_products}
        hasProducts2={organization.has_products_2}
        entityPermissionsEnabled={organization.entity_level_permissions}
        updateSystemFieldsName={updateSystemFieldsName}
        users={users}
        hideArchivedItems={hideArchivedItems}
        showActionBar={showActionBar}
        customHeight={customHeight}
        forceVisibleFields={forceVisibleFields}
        organizationId={organization?.id}
      />
    );
  };

export default RoadmapsHOC;
