/* eslint-disable camelcase */
// External dependencies
import React from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';

// Dragonboat imports
import { getIdeasIntegrations, getOrgHasJiraIntegrated } from 'store/organization/selectors';
import Component from './Component';

const ComponentHOC = () => {
  return props => {
    const { integrationProgress } = props.project;
    const hasJira = useSelector(state => getOrgHasJiraIntegrated(state));
    const integrations = useSelector(getIdeasIntegrations);

    if (!integrationProgress) return '';
    if (!hasJira && !integrations.length) return '';

    return (
      <Component
        total={integrationProgress.issuesTotal}
        inProgress={integrationProgress.issuesInProgress}
        closed={integrationProgress.issuesClosed}
        {...props}
      />
    );
  };
};

// Apply HOCs
export default compose(ComponentHOC);
