import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import usePermissions from 'hooks/permissions/usePermissions';

export default formData => {
  const { canUpdate } = usePermissions();

  const canUpdateRequest = canUpdate(PERMISSION_RESOURCES.request, { data: formData });

  const canComplexEditRequest = canUpdateRequest;
  const canRoadmapEditRequest = canUpdateRequest;
  const canSimpleEditRequest = canUpdateRequest;
  const canEditCustomRequestFields = canUpdateRequest;

  return [canComplexEditRequest, canRoadmapEditRequest, canSimpleEditRequest, canEditCustomRequestFields];
};
