import { fromJS, List } from 'immutable';
import searchTaskRecursively from 'store/utils/searchTaskRecursively';

export default (task, projects) => {
  task.subtasks = task.subtasks || [];

  const projectIndex = projects.findIndex(project => project.get('id') === task.project_id);

  if (projectIndex < 0) {
    if (process.env.NODE_ENV) {
      console.error(`UPDATE_TASK_FULFILLED::project with id "${task.project_id}" does not exist in state.`);
    }
    return projects;
  }

  // search task on project
  let taskPath = searchTaskRecursively(
    projects.get(projectIndex).toJS(), projectIndex,
    [], task.id
  );

  // task doesn't exist on project
  if (!taskPath) {
    /* eslint-disable */
    for (const [index, project] of projects.toJS().entries()) {
      taskPath = searchTaskRecursively(project, index, [], task.id);

      if (taskPath) {
        projects = projects.removeIn(taskPath);
        break;
      }
    }
    /* eslint-enable */

    if (!taskPath && process.env.NODE_ENV) {
      console.error(`UPDATE_TASK_FULFILLED::task with id "${task.id}" is not associated to any project.`);
    }

    // https://github.com/facebook/immutable-js/issues/331
    return projects
      .updateIn(
        [projectIndex, 'tasks'],
        List(),
        list => list.push(fromJS(task))
      );
  }

  return projects.setIn(taskPath, fromJS(task));
};
