import React, { useEffect, useState, useRef, useMemo } from 'react';
import { compose } from 'redux';
import pick from 'lodash/pick';
import { useSelector, useDispatch } from 'react-redux';
import isBoolean from 'lodash/isBoolean';
import moment from 'moment-timezone';

import { READ_ONLY_USER } from '@dragonboat/permissions';
import subscribeNewData from 'subscribeNewData';
import { FORECAST_PAGE } from 'constants/filters';
import { DEFAULT_TIME_WINDOW } from 'constants/timeline';
import { generateGanttColumns } from 'components/ganttCommon';
import useProjectsForGantt from 'hooks/timeline/useProjectsForGantt';
import useCollapsedTasks from 'hooks/timeline/useCollapsedTasks';
import useTodayMarker from 'hooks/timeline/useTodayMarker';
import useGanttSort from 'hooks/timeline/useGanttSort';
import setGanttColumnWidths from 'hooks/timeline/setGanttColumnWidths';
import useDisplayTimeframeMarkers from 'hooks/timeline/useDisplayTimeframeMarkers';
import useTimelineLsState from 'hooks/timeline/useTimelineLsState';
import { updateState } from 'store/forecast';
import useTimelineProjects from 'hooks/timeline/useTimelineProjects';
import setTimelineGanttConfigs from 'hooks/timeline/setTimelineGanttConfigs';
import setTimelineGanttTimeWindowAndZoom from 'hooks/timeline/setTimelineGanttTimeWindowAndZoom';
import useTimelineGanttEffects from 'hooks/timeline/useTimelineGanttEffects';
import useInitTimelineGantt from 'hooks/timeline/useInitTimelineGantt';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import { getUsers } from 'store/users/selectors';
import { getTeams } from 'store/teams/selectors';
import { getSkills } from 'store/skills/selectors';
import { getIdeasIntegrations } from 'store/organization/selectors';
import useLoadProjectsForTransactionPages from 'hooks/projects/useLoadProjectsForTransactionPages';
import useTimelineProjectsGantt from 'containers/GanttTimeline/useTimelineProjects';
import setGanttPlannedEndDateDisplay from 'containers/GanttTimeline/setGanttPlannedEndDateDisplay';
import setGanttPlannedStartDisplay from 'containers/GanttTimeline/setGanttPlannedStartDisplay';
import setGanttPredictedEndDateDisplay from 'containers/GanttTimeline/setGanttPredictedEndDateDisplay';
import useTimelineHCTable from 'containers/GanttTimeline/TimelineHCTable';
import setGanttTimelineBusinessValue from 'containers/GanttTimeline/TimelineBusinessValue';
import useBookADemoLocker from 'hooks/useBookADemoLocker';
import useRoadmapVersions from 'hooks/useRoadmapVersions';

import autoSchedule from './helpers/autoSchedule';
import useOneClickPlan from 'hooks/useOneClickPlan';
import { draftBackgroundColor } from 'design-system/constants/draft';

import { makeFilterProjectsByCommitted } from 'utils/projects/projectsUtils';
import { INCLUDE_ALL_OPTION } from 'constants/projects';
import { has } from 'ramda';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const getStateConfigValuesFromProps = props => {
  const possibilities = ['showAllDependencies', 'hideDependencyLines'];

  return possibilities.reduce((carry, item) => {
    if (has(item, props)) {
      carry[item] = props[item];
    }

    return carry;
  }, {});
};

const optionsForGanttData = [
  'coloredGroups',
  'selectedColorBy',
  'showEstimates',
  'showTasks',
  'displayAlerts',
  'useTaskOnConfirmedIdeas',
  'showOwnerAllocation',
  'selectedTimeWindow',
  'showGroupedTimeline',
  'searchStr',
];

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();

    const [compState, _setCompState] = useState({
      selectedTimeWindow: DEFAULT_TIME_WINDOW,
      collapseGanttBars: false,
    });
    const setCompState = data => _setCompState({ ...compState, ...data });

    const currentUser = useSelector(state => state.login.currentUser);

    const { canView } = usePermissions();

    const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

    const isReadOnlyUser = currentUser.role_id === READ_ONLY_USER;
    const forecastState = useSelector(state => state.forecast);
    const updateLsState = (state, makesActiveViewDirty = true) => dispatch(updateState(state, makesActiveViewDirty));
    const orgIntegrations = useSelector(getIdeasIntegrations);

    const { isRoadmapVersionSelected } = useRoadmapVersions();

    const defaultLsState = {
      showHCTable: isBoolean(forecastState.showHCTable) ? forecastState.showHCTable : true,
      useTaskOnConfirmedIdeas: isBoolean(forecastState.useTaskOnConfirmedIdeas) ? forecastState.useTaskOnConfirmedIdeas : true,
      showEstimates: isBoolean(forecastState.showEstimates) ? forecastState.showEstimates : true,
    };
    const lsState = {
      showUncommittedProjects: INCLUDE_ALL_OPTION,
      ...useTimelineLsState(forecastState, updateLsState, FORECAST_PAGE, defaultLsState),
      selectedTimeWindow: compState.selectedTimeWindow,
      ...getStateConfigValuesFromProps(props),
    };

    const { isOneClickPlanModeActive, toggleOneClickPlanMode } = useOneClickPlan();

    const handleRunOneClickPlan = () => {
      toggleOneClickPlanMode(true);
    };

    const getBackgroundColor = useMemo(() => {
      if (isOneClickPlanModeActive) return '';

      const isDraftModeActive = lsState?.localMode || isRoadmapVersionSelected;

      if (isDraftModeActive) return draftBackgroundColor;

      return '';
    }, [lsState?.localMode, isRoadmapVersionSelected, isOneClickPlanModeActive]);

    useLoadProjectsForTransactionPages(FORECAST_PAGE, null, null, { includeMilestones: true });
    const [projects, ideas, initiatives, bets] = useTimelineProjects(lsState, FORECAST_PAGE, false, isOneClickPlanModeActive);
    const updateTodayMaker = useTodayMarker();
    const [updateTimeframeMarkers] = useDisplayTimeframeMarkers();
    const lsStateRef = useRef(lsState);

    const filterProjectsByCommittedStatus = useMemo(() => {
      return makeFilterProjectsByCommitted(lsState?.showUncommittedProjects);
    }, [lsState?.showUncommittedProjects]);

    const [filteredProjects, filteredIdeas, filteredInitiatives, filteredBets] = useMemo(() => {
      return [
        filterProjectsByCommittedStatus(projects),
        filterProjectsByCommittedStatus(ideas),
        filterProjectsByCommittedStatus(initiatives),
        filterProjectsByCommittedStatus(bets),
      ];
    }, [projects, ideas, initiatives, bets, filterProjectsByCommittedStatus]);

    const BookADemoLockerComponent = useBookADemoLocker(FORECAST_PAGE, false, props.isDashboardModule);

    if (BookADemoLockerComponent) return <BookADemoLockerComponent />;

    lsStateRef.current = lsState;

    const groupBy = [lsState.selectedGroup1];
    const [tasks] = useProjectsForGantt(FORECAST_PAGE, {
      ...pick(lsState, optionsForGanttData),
      collapseGanttBars: compState.collapseGanttBars,
      groupBy,
      projects: filteredProjects,
      ideas: filteredIdeas,
      initiatives: filteredInitiatives,
      bets: filteredBets,
      showId: lsState.selectedDisplayColumns && lsState.selectedDisplayColumns.some(c => c.id === 'key'),
      showIntegrationKey: lsState.selectedDisplayColumns && lsState.selectedDisplayColumns.some(c => c.id === 'integrationKey'),
    });

    const onInitGantt = ganttInstance => {
      setTimelineGanttConfigs(ganttInstance, lsState, columns, isReadOnlyUser);
      setTimelineGanttTimeWindowAndZoom(ganttInstance, lsState);
      initGanttSort(ganttInstance);
    };
    const onRefreshGantt = (ganttInstance, data) => {
      setTimelineGanttConfigs(ganttInstance, lsState, columns, isReadOnlyUser);
      setTimelineGanttTimeWindowAndZoom(ganttInstance, lsState);

      if (data) {
        sortGantt(lsState);
      }

      updateTodayMaker(ganttInstance);
      updateTimeframeMarkers(ganttInstance, lsState.showTimeframes);
    };

    const columns = generateGanttColumns({
      selectedDisplayColumns: lsState.selectedDisplayColumns,
      savedWidths: lsState.columnsWidth,
      orgIntegrations,
      showLockColumn: isOneClickPlanModeActive,
    });

    const _onHCTableCellClick = (gantt, { teamId, skillId, userId }) => {
      if (gantt) {
        const scrollState = { ...gantt.getScrollState() };

        if (lsStateRef.current.HCTableFilter) {
          updateLsState({ HCTableFilter: null });
        } else {
          updateLsState({
            HCTableFilter: {
              teamId,
              skillId,
              userId,
              startDate: moment(gantt.getState().min_date),
              endDate: moment(gantt.getState().max_date),
            },
          });
        }

        // after render will force to scroll to the previous position
        setTimeout(() => {
          gantt.scrollTo(scrollState.x, scrollState.y);
        }, 500);
      }
    };

    const mapGanttData = opens => task => {
      const open = opens && opens.includes(String(task.id));

      return {
        ...task,
        open,
        $open: open,
      };
    };

    const ganttTasks = {
      data: tasks.data.map(mapGanttData(lsState.openTasks)),
      links: tasks.links,
    };

    const setGanttTimelineHCTable = useTimelineHCTable({
      tasks: ganttTasks,
      HCTableFilter: lsState.HCTableFilter,
      selectedTimelineInterval: lsState.selectedZoom,
      selectedRounding: lsState.selectedRounding,
      selectedHighlight: lsState.selectedHighlight,
      selectedTeam: lsState.selectedTeam,
      onHCTableCellClick: _onHCTableCellClick,
      showResourceUsers: lsState.showResourceUsers,
      resourceUsersGroup: lsState.resourceUsersGroup,
      showUncommittedProjects: lsState.showUncommittedProjects,
    });

    const { gantt, refreshGantt, ganttRef, reloadGantt } = useInitTimelineGantt({
      lsState,
      onRefresh: onRefreshGantt,
      data: tasks,
      columns,
      columnSort: lsState.sort,
      ganttInitFuncs: [
        onInitGantt,
        setGanttPlannedStartDisplay(lsState.plannedStartDate),
        setGanttPlannedEndDateDisplay(lsState.plannedEndDate),
        setGanttPredictedEndDateDisplay(lsState.displayStoryProgEndDate, lsState.showStories, orgIntegrations),
        lsState.showBusinessValue &&
          setGanttTimelineBusinessValue({
            selectedTimelineInterval: lsState.selectedZoom,
            selectedRounding: lsState.selectedRounding,
          }),
        lsState.showHCTable && setGanttTimelineHCTable,
        setGanttColumnWidths(updateLsState),
      ],
    });

    const { collapseExpandAll } = useCollapsedTasks(gantt, updateLsState, lsState);
    const [initGanttSort, sortGantt] = useGanttSort(gantt, updateLsState, ganttTasks, lsState.sort);

    useTimelineGanttEffects({
      gantt,
      refreshGantt,
      lsState,
      columns,
      tasks,
      ganttTasks,
      reloadGantt,
    });
    const searchStringRef = useRef(lsState.searchStr);

    searchStringRef.current = lsState.searchStr;
    const _afterAddInlineProject = () => {
      if (searchStringRef.current) updateLsState({ searchStr: '' }, false);
    };

    useEffect(() => {
      const openTasks = collapseExpandAll(lsState.allExpanded);
      const updatedTasks = {
        data: tasks.data.map(mapGanttData(openTasks)),
        links: tasks.links,
      };

      refreshGantt(updatedTasks);
    }, [lsState.allExpanded]);

    useEffect(() => {
      reloadGantt();
      refreshGantt(ganttTasks);
    }, [lsState.showUncommittedProjects]);

    const teams = useSelector(state => getStateDataForPage(state, getTeams, 'teams'));
    const skills = useSelector(state => getStateDataForPage(state, getSkills, 'skills'));
    const users = useSelector(state => getStateDataForPage(state, getUsers, 'users'));

    const clickAutoSchedule = () => {
      updateLsState({ localMode: true });
      setTimeout(() => {
        const planned = autoSchedule(lsState, gantt, ganttTasks, teams, skills, users);

        refreshGantt(planned);
      }, 0);
    };

    useTimelineProjectsGantt({
      gantt,
      localMode: lsState.localMode,
      selectedGroup: groupBy,
      editConfirmedTasks: lsState.editConfirmedTasks,
      afterAddInlineProject: _afterAddInlineProject,
      shouldOpenLightbox: true,
    });

    return (
      <Component
        {...props}
        currentUser={currentUser}
        lsState={lsState}
        gantt={gantt}
        projects={filteredProjects}
        compState={compState}
        setCompState={setCompState}
        updateLsState={updateLsState}
        clickAutoSchedule={clickAutoSchedule}
        handleRunOneClickPlanClicked={handleRunOneClickPlan}
        ganttRef={ganttRef}
        isReadOnlyUser={isReadOnlyUser}
        showPageConfigForReadOnly={shouldDisplayControlsBar}
        getBackgroundColor={getBackgroundColor}
        isOneClickPlanModeActive={isOneClickPlanModeActive}
      />
    );
  };
};

export default compose(subscribeNewData(['app', 'projects', 'tasks', 'estimates', 'customFields', 'timeframes']), componentHOC);
