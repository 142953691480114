import React from 'react';
import styled, { css } from 'styled-components';

import bg from './images/home_background.png';

const HomeBackground = props => {
  return <Background {...props}>{props.children}</Background>;
};

export default HomeBackground;

export const homeBackgroundStyles = css`
  background-color: ${({ theme }) => theme.palette.newLayout.background.header};
  background-image: url(${bg});
  background-size: 100%;
  background-position: center bottom;
  background-repeat: no-repeat;
`;

const Background = styled.div`
  ${homeBackgroundStyles}
`;
