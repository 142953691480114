import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { pipe, isNil, not } from 'ramda';
import { FeatureFlags } from '@dragonboat/config';

import useFeatureFlags from 'hooks/useFeatureFlags';
import { hasIntegrationsOfType as hasIntegrationsOfTypeSelector } from 'store/organization';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

const isNotNil = pipe(isNil, not);

const INTEGRATION_FEATURE_FLAGS = {
  [INTEGRATIONS_KEYS.microsoftTeams]: FeatureFlags.HAS_MICROSOFT_TEAMS_INTEGRATION,
};

const useHasIntegrationsOfType = integrationType => {
  const integrationFeatureFlag = INTEGRATION_FEATURE_FLAGS[integrationType];
  const featureFlagEnabled = useFeatureFlags([integrationFeatureFlag]);
  const hasExistingIntegrations = useSelector(hasIntegrationsOfTypeSelector(integrationType));

  const hasIntegrationsOfType = useMemo(() => {
    if (isNotNil(integrationFeatureFlag)) {
      return hasExistingIntegrations && featureFlagEnabled;
    }

    return hasExistingIntegrations;
  }, [integrationType, featureFlagEnabled]);

  return hasIntegrationsOfType;
};

export default useHasIntegrationsOfType;
