import React from 'react';

import usePreCloneScenario from 'routes/Scenario/hooks/usePreCloneScenario';
import OneClickDialog from 'containers/OneClickDialog';

const AIPlanner = props => {
  usePreCloneScenario();

  return <OneClickDialog {...props} />;
};

export default AIPlanner;
