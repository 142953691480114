import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import useCommonGridProps from 'design-system/molecules/AgGridReact-New/hooks/useCommonGridProps';
import usePermissions from 'hooks/permissions/usePermissions';
import useCustomFields from './hooks/useCustomFields';
import useCustomFieldsGridColumns from './hooks/useCustomFieldsGridColumns';
import useCustomFieldsGridEventHandlers from './hooks/useCustomFieldsGridEventHandlers';
import useCustomFieldsActions from './hooks/useCustomFieldsActions';

import ActionsToolbar from './components/ActionsToolbar';
import { BaseGrid } from 'containers/Grids';
import FormulaLightbox from 'containers/FormulaLightbox';
import DeleteConfirmDialog from './components/DeleteConfirmDialog';

import { BASE_ROW_HEIGHT } from 'constants/grid';
import DrawerHeader from 'design-system/molecules/DrawerHeader/index';
import NewDialog from 'design-system/organisms/NewDialog/index';

import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { HeaderIconButton } from 'design-system/organisms/NewDialog';
import { DRAWER_CUSTOM_FIELDS_TITLE } from './helpers/constants';

const getRowId = ({ data }) => data?.id || null;

const CustomFields = ({ showActionBar = true, onClose, ...rest }) => {
  const gridApi = useRef();

  const {
    customFields,
    createCustomField,
    handleAddRow,
    removeUnsavedCustomFields,
    updateCustomFieldById,
    deleteCustomField,
    openFormula,
    setOpenFormula,
    updateFormula,
    formulaOptions,
    onLinkClickFormula,
    showArchivedItems,
    toggleArchivedItems,
    customFieldName,
  } = useCustomFields();

  const { canUpdate, canCreate, canDelete, canView } = usePermissions();
  const permissions = {
    canCreate: canCreate(PERMISSION_RESOURCES.requestCustomField),
    canUpdate: customField => canUpdate(PERMISSION_RESOURCES.requestCustomField, { customField }),
    canDelete: canDelete(PERMISSION_RESOURCES.requestCustomField),
  };

  const { itemToDelete, setItemToDelete, deleteItem } = useCustomFieldsActions({
    deleteById: deleteCustomField,
  });

  const { afterColumnDefs, columnDefs, defaultColDef } = useCustomFieldsGridColumns({
    canCreate: permissions.canCreate,
    canUpdate: permissions.canUpdate,
    onLinkClickFormula,
    removeUnsavedCustomFields,
    canDelete: permissions.canDelete,
    setItemToDelete,
    updateCustomFieldById,
    hasColorColumn: canView(PERMISSION_FEATURES.customFieldColor),
  });

  const commonGridProps = useCommonGridProps({ isTreeView: false });

  const onGridReady = params => {
    gridApi.current = params.api;
  };

  const gridEventHandlers = useCustomFieldsGridEventHandlers({
    customFields,
    updateCustomFieldById,
    createCustomField,
    removeUnsavedCustomFields,
  });

  const customFieldsColumnDefs = useMemo(() => [...columnDefs, ...afterColumnDefs], [columnDefs, afterColumnDefs]);

  return (
    <StyledDialog
      type="DRAWER"
      withoutBackdrop
      headerComponent={DrawerHeader}
      headerProps={{
        onClose,
        title: DRAWER_CUSTOM_FIELDS_TITLE,
        leftIcon: <ChevronLeftIcon />,
      }}
      paddingOverride="0px"
      closeButton
      onClose={onClose}
      {...rest}
    >
      {permissions.canCreate && (
        <ActionsToolbar
          add={handleAddRow}
          hideAddButton={!permissions.canCreate}
          showArchivedItems={showArchivedItems}
          toggleArchivedItems={toggleArchivedItems}
        />
      )}
      <BaseGrid
        rowData={customFields}
        columnDefs={customFieldsColumnDefs}
        {...commonGridProps}
        {...gridEventHandlers}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        headerHeight={BASE_ROW_HEIGHT}
        getRowId={getRowId}
        enableSorting
      />
      <DeleteConfirmDialog
        customFieldName={customFieldName}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        deleteItem={deleteItem}
      />
      <FormulaLightbox
        fieldTitle={openFormula?.title}
        isOpen={!!openFormula}
        onClose={() => setOpenFormula(null)}
        onSave={updateFormula}
        currentValue={openFormula?.value || ''}
        options={formulaOptions?.map(f => f.title) || []}
        editable={openFormula && permissions.canUpdate(openFormula)}
      />
    </StyledDialog>
  );
};

export default CustomFields;

const StyledDialog = styled(NewDialog)`
  &&&& ${HeaderIconButton} {
    top: 18px;
  }
`;
