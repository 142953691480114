import styled from 'styled-components';

/**
 * As recommended by chart.js all charts should be wrapped in a
 * dedicated container to properly handle size stylings.
 * See {@link https://www.chartjs.org/docs/latest/configuration/responsive.html}
 * for more details.
 */
const ChartWrapper = styled.div`
  ${({ height }) => (height ? `height: ${height}px` : '')}
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight}px` : '')}
  ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight}px` : '')}
  width: 100%
`;

export default ChartWrapper;
