import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import pluralize from 'pluralize';

import useSystemFields from 'hooks/useSystemFields';
import usePageQueryParamFilters from 'hooks/usePageQueryParamFilters';
import useProjectsListLightboxContext from 'hooks/useProjectsListLightbox';
import usePermissions from 'hooks/permissions/usePermissions';
import useOpenMetricFromEntityWithMetrics from 'hooks/useOpenMetricFromEntityWithMetrics';
import { getHasAdvancedMetricReporting } from 'store/organization/selectors';
import { METRIC } from 'constants/common';
import { METRICS_GRID_PATH } from 'constants/filters/pages';
import { USER_IS_OWNER_FILTER_KEY } from 'routes/Metrics/constants';
import {
  fetchMissionControlMetrics,
  selectMissionControlHomeMetrics,
  selectIsLoadingMissionControlHomeMetrics,
  selectMetricById,
} from 'features/MissionControlHome/store';
import { subscribeToRefreshMissionControlMetricsChanged } from 'features/MissionControlHome/store/listenerMiddleware';

const getTitle = getSystemFieldName => {
  const titleSuffix = 'My';
  const entityTitle = pluralize(getSystemFieldName(METRIC));

  return `${titleSuffix} ${entityTitle}`;
};

const useMissionControlMyMetrics = () => {
  const dispatch = useDispatch();

  const [getSystemFieldName] = useSystemFields();

  const { canUpdate } = usePermissions();

  const { handleOpenMetricView } = useOpenMetricFromEntityWithMetrics();

  const { navigateToPageWithFilter } = usePageQueryParamFilters();

  const { openProjectsListLightbox: handleOpenProjectsListLightbox } = useProjectsListLightboxContext();

  const [selectedMetric, setSelectedMetric] = useState(null);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const canUpdateMetric = canUpdate(PERMISSION_RESOURCES.metric);

  const metrics = useSelector(selectMissionControlHomeMetrics);

  // We need to get the Metric from the metrics slice since the MetricValuesPopover operates in that slice as well
  const metricForPopover = useSelector(state => selectMetricById(state, selectedMetric?.id));

  const isLoading = useSelector(selectIsLoadingMissionControlHomeMetrics);

  const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);

  const fetchMetrics = () => dispatch(fetchMissionControlMetrics());

  const goToMetricsPage = () => navigateToPageWithFilter(`/${METRICS_GRID_PATH}`, USER_IS_OWNER_FILTER_KEY);

  const getRowId = ({ data }) => data.id;

  const openMetricPopover = (metric, e) => {
    setSelectedMetric(metric);
    setPopoverAnchorEl(e?.currentTarget);
  };

  useEffect(() => {
    const unsubscribeRefreshData = dispatch(subscribeToRefreshMissionControlMetricsChanged());

    fetchMetrics();

    return unsubscribeRefreshData;
  }, []);

  return {
    metrics,
    isLoading,
    dataLength: metrics?.length || 0,
    title: getTitle(getSystemFieldName),
    entitySystemFieldName: getSystemFieldName(METRIC),
    metricForPopover,
    popoverAnchorEl,
    canUpdateMetric,
    openMetricView: hasAdvancedMetricReporting && handleOpenMetricView,
    openProjectsListLightbox: metric => handleOpenProjectsListLightbox(metric, METRIC),
    goToMetricsPage,
    getRowId,
    openMetricPopover,
    setPopoverAnchorEl,
  };
};

export default useMissionControlMyMetrics;
