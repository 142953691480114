import { INTEGRATIONS_NAMES } from 'constants/integrations';

import { FieldIntegrationError } from './FieldIntegrationError';
import { notificationTypes } from './notificationTypes';
import { BaseNotification } from './BaseNotification';
import { notificationSystems } from './NotificationSystems';

export class NotificationsCreator {
  constructor() {
    this.notificationType = null;
  }

  static createNotification(notification) {
    switch (notification.type) {
      case notificationTypes.UPDATE_FIELD_INTEGRATION_ERROR: {
        if (notification?.integrationType?.toLowerCase() === INTEGRATIONS_NAMES.JIRA.toLowerCase()) {
          return new FieldIntegrationError(notification);
        }

        return null;
      }
      case notificationTypes.SLACK_MESSAGING_ERROR: {
        return new BaseNotification(notification?.message, notification?.type, notificationSystems.LOCAL);
      }
      case notificationTypes.CREATE_AZURE_DEVOPS_WORK_ITEM_VALIDATION_ERROR: {
        return new BaseNotification(notification?.message, notification?.type, notificationSystems.LOCAL);
      }
      default:
        return new BaseNotification(notification?.message, notification?.type, notification?.system);
    }
  }
}
