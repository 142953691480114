import Objectives from 'routes/Settings/Objectives';
import Roadmaps from 'routes/Settings/Roadmaps';
import Phases from 'routes/Settings/Phases';
import Teams from 'routes/Settings/Teams';
import Themes from 'routes/Settings/Themes';
import CustomFields from 'routes/Settings/CustomFields';
import CustomUserFields from 'routes/Settings/CustomUserFields';
import Categories from 'routes/Settings/Categories';
import Timeframes from 'routes/Settings/Timeframes';
import Component from 'routes/Settings/Integration';
import Skills from 'routes/Settings/Skills';
import Tags from 'routes/Settings/Tags';
import Customers from 'routes/Settings/Customers';
import Users from 'routes/Settings/Users';
import Organization from 'routes/Settings/Organization';
import Billing from 'routes/Billing';
import Metrics from 'routes/Settings/Metrics';
import Priorities from 'routes/Settings/Priorities';
import CustomerRequests from 'routes/Settings/CustomerRequests';
import ErrorLogs from 'routes/Settings/ErrorLogs';
import PDLC from 'routes/Settings/pdlc';
import RoadmapItems from 'routes/Settings/RoadmapItems';
import DragonbotTrainingManager from 'containers/Dragonbot/Settings/TrainingDataManager.jsx';
import onlyVisibleForUsers from 'utils/onlyVisibleForUsers';

import {
  ADMIN_USER,
  OWNER_USER,
  LEADER_USER,
  READ_ONLY_USER,
  SUPER_ADMIN_USER,
  CSM_USER,
  EDITOR_USER,
  MANAGER_USER,
} from '@dragonboat/permissions';

const isUserCustomFieldsVisible = (roleId, user, organization) =>
  onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER)(roleId) &&
  organization?.has_custom_user_project_fields;

const isCustomerRequestsVisible = (roleId, user, organization) =>
  onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER)(roleId) &&
  organization?.has_customer_requests;

const isPDLCVisible = (roleId, user, organization) =>
  onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER)(roleId);

export default getSystemFieldName => ({
  account: {
    label: 'Account',
    path: 'settings/account',
    component: Organization,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  errorLogs: {
    label: 'Integration Alerts',
    path: 'settings/error-logs',
    component: ErrorLogs,
    visible: (roleId, user, organization) => {
      const { isSuperAdmin } = user;
      const { has_integration_log: hasIntegrationLog } = organization;

      return !!hasIntegrationLog && (isSuperAdmin || onlyVisibleForUsers(OWNER_USER, LEADER_USER)(roleId));
    },
  },
  objectives: {
    label: getSystemFieldName('objective', false),
    path: 'settings/objectives',
    component: Objectives,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  roadmaps: {
    label: getSystemFieldName('roadmap', false),
    path: 'settings/roadmaps',
    component: Roadmaps,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  themes: {
    label: getSystemFieldName('theme', false),
    path: 'settings/themes',
    component: Themes,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  categories: {
    label: getSystemFieldName('category', false),
    path: 'settings/categories',
    component: Categories,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  phases: {
    label: getSystemFieldName('phase', false),
    path: 'settings/phases',
    component: Phases,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  timeframes: {
    label: getSystemFieldName('timeframe', false),
    path: 'settings/timeframes',
    component: Timeframes,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  teams: {
    label: getSystemFieldName('team', true),
    path: 'settings/teams',
    component: Teams,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  skills: {
    label: getSystemFieldName('skill', true),
    path: 'settings/skills',
    component: Skills,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  users: {
    label: 'Users',
    path: 'settings/users',
    component: Users,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  tags: {
    label: getSystemFieldName('tag', false),
    path: 'settings/tags',
    component: Tags,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  pdlc: {
    label: getSystemFieldName('pdlc'),
    path: 'settings/pdlc',
    component: PDLC,
    visible: (roleId, user, organization) => isPDLCVisible(roleId, user, organization),
  },
  dragonbotTrainingManager: {
    path: 'settings/dragonbot/training-manager',
    component: DragonbotTrainingManager,
    visible: (roleId, user) => user.isSuperAdmin || onlyVisibleForUsers(CSM_USER, SUPER_ADMIN_USER)(roleId),
  },
  dragonbot: {
    label: getSystemFieldName('Dragonbot'),
    path: 'settings/dragonbot',
    visible: (roleId, user) => user.isSuperAdmin || onlyVisibleForUsers(CSM_USER, SUPER_ADMIN_USER)(roleId),
    subMenu: [
      {
        label: 'Manage training data',
        path: 'settings/dragonbot/training-manager',
      },
    ],
  },
  customers: {
    label: getSystemFieldName('customer', false),
    path: 'settings/customers',
    component: Customers,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  customFields: {
    label: 'Custom Field',
    path: 'settings/customfields',
    component: CustomFields,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  customUserFields: {
    label: 'User Custom Field',
    path: 'settings/customuserfields',
    component: CustomUserFields,
    visible: (roleId, user, organization) => isUserCustomFieldsVisible(roleId, user, organization),
  },
  integrations: {
    label: 'Integrations',
    path: 'settings/integrations',
    component: Component,
    exact: false,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
    subMenu: ({ visibleIntegrations }) => {
      return visibleIntegrations
        .map(integration => ({
          label: integration.label,
          path: `settings/integrations/${integration.name}`,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
  },
  metrics: {
    label: 'Metric',
    path: 'settings/metrics',
    component: Metrics,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  priorities: {
    label: getSystemFieldName('priority', false),
    path: 'settings/priorities',
    component: Priorities,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
  billing: {
    label: 'Billing',
    path: 'settings/billing',
    component: Billing,
    exact: false,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
    subMenu: [
      {
        label: 'Subscription Details',
        path: 'settings/billing/info',
      },
      {
        label: 'Add Editors',
        path: 'settings/billing/addusers',
        visible: (_, __, organization) => organization?.stripe_customer_id,
      },
      {
        label: 'Receipts',
        path: 'settings/billing/receipts',
        visible: (_, __, organization) => organization?.stripe_customer_id,
      },
    ],
  },
  customerRequests: {
    label: `${getSystemFieldName('request', false)}`,
    path: 'settings/request',
    id: 'request-settings-menu-navigation',
    component: CustomerRequests,
    visible: (roleId, user, organization) => isCustomerRequestsVisible(roleId, user, organization),
  },
  roadmapItems: {
    label: 'Roadmap Items',
    path: 'settings/roadmapitems',
    id: 'roadmap-items-settings-menu-navigation',
    component: RoadmapItems,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER),
  },
});
