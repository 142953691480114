import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';

import ConfirmDialog from 'components/ConfirmDialog';

export default ({ label, onDelete }) => {
  const [isDialogOpen, setShowDialog] = useState(false);

  const showDialog = () => setShowDialog(true);
  const closeDialog = () => setShowDialog(false);

  return (
    <Fragment>
      <Button color="secondary" onClick={showDialog}>
        delete
      </Button>
      <ConfirmDialog
        isOpen={isDialogOpen}
        onCancel={closeDialog}
        onConfirm={onDelete}
        title={`Delete selected ${label}`}
        text={`Are you sure you want to delete selected ${label}?`}
      />
    </Fragment>
  );
};
