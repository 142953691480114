import React, { useState, Fragment } from 'react';
import styled from 'styled-components';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import ErrorMessage from 'design-system/atoms/ErrorMessage/index';


import useContactForm from 'hooks/useContactForm';

import Content from '../components/Content';
import Title from '../components/Title';
import Body from '../components/Body';
import Logo from './azure-logo.png';

export default props => {
  const { orgIntegration, clickNext, hasAzureAD, userCanUpdateOrgIntegration, userCanViewOrgIntegration } = props;

  const [azureTenantId, setAzureTenantId] = useState(orgIntegration ? orgIntegration.data.uri : '');
  const [ContactForm, toggleContactForm] = useContactForm('Azure Active Directory contact message');

  const shouldShowContactInfo = !userCanViewOrgIntegration || !hasAzureAD || (!userCanUpdateOrgIntegration && !orgIntegration);

  return (
    <Content>
      <Title
        logo={<AzureLogo src={Logo} />}
        title="Azure Active Directory Integration"
        helpLink="https://dragonboat.zendesk.com/hc/en-us/articles/4405858659223"
      />
      <Body>
        <TextDeprecated size="medium">Azure Connection</TextDeprecated>
        {shouldShowContactInfo && (
          <Fragment>
            <ContactSupportTeam size="medium" breakwords>
              <a id="contact-support-link" href="#" onClick={toggleContactForm}>
                Click here
              </a>
              {' to contact our support team to enable Azure for your account.'}
            </ContactSupportTeam>
            {ContactForm}
          </Fragment>
        )}
        {!shouldShowContactInfo && userCanUpdateOrgIntegration && !orgIntegration && (
          <AzureFieldsContainer>
            <AzureNote breakwords>
              {`* Once a Dragonboat account is set to use Azure Active Directory, 
                  existing Dragonboat users will no longer be able to log in with their previous email and password.`}
            </AzureNote>
            <AzureTextField
              id="azure-tenant-id-input"
              label="Azure Tenant ID"
              value={azureTenantId}
              onChange={e => setAzureTenantId(e.target.value)}
              required
            />
            {props.error && (
              <AzureErrorMessage>
                <ErrorMessage>{props.error}</ErrorMessage>
              </AzureErrorMessage>
            )}
            <AzureNextButton
              id="azure-next-button"
              color="primary"
              disabled={!azureTenantId}
              onClick={() => clickNext(azureTenantId)}
            >
              Next
            </AzureNextButton>
          </AzureFieldsContainer>
        )}
        {!shouldShowContactInfo && (userCanViewOrgIntegration || userCanUpdateOrgIntegration) && orgIntegration && (
          <AzureConnected size="medium">
            Your dragonboat workspace has been linked to your Azure Tenant: {azureTenantId}.
          </AzureConnected>
        )}
      </Body>
    </Content>
  );
};

const AzureTextField = styled(TextField)`
  &&&& {
    width: 500px;
    margin-bottom: 10px;
  }
`;

const AzureFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
`;

const AzureNextButton = styled(Button)`
  &&&& {
    margin-top: 12px;
    align-self: flex-end;
  }
`;

const AzureConnected = styled(TextDeprecated)`
  &&&& {
    margin-top: 20px;
  }
`;

const AzureErrorMessage = styled.div`
  &&&& {
    margin-top: 20px;
  }
`;

const AzureLogo = styled.img`
  &&&& {
    height: 35px;
    width: 35px;
    margin-right: 20px;
  }
`;

const AzureNote = styled(TextDeprecated)`
  &&&& {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

const ContactSupportTeam = styled(TextDeprecated)`
  &&&& {
    margin-top: 20px;
  }
`;
