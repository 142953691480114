import sortColumnsByColumnState from './sortColumnsByColumnState';

const shouldAddParentToOrder = (order, columnDef) => columnDef?.parent && !order.find(c => c.colId === columnDef.parent.field);

const filterHiddenColumns = c => c.hide !== true;

const findColumnDef = (flatColumnDefs, colId) => {
  return flatColumnDefs.find(column => column.field === colId);
};

// columnsState doesn't include parent columns, so we need to add them to the order
// right before the first children
const buildSortColumnState = (columnDefs, columnsState) => {
  const order = [];

  const flatColumnDefs = columnDefs.reduce((acc, col) => [...acc, col, ...(col.children || [])], []);

  columnsState.forEach(col => {
    const columnDef = findColumnDef(flatColumnDefs, col.colId);

    if (shouldAddParentToOrder(order, columnDef)) {
      order.push({ colId: columnDef.parent.field });
    }

    order.push(col);
  });

  // Ingore hidden columns to sort, on add new columns they will be added to the end
  return order.filter(filterHiddenColumns);
};

/**
 * @function processGroomedColumnDefs
 *
 * Will process groomed columns defs to show on the grid
 *
 *
 *
 * @param  {Array} columnDefs
 * @param  {Array} columnsState
 * @return {Array}
 */
const processGroomedColumnDefs = (columnDefs, columnsState = []) => {
  const order = buildSortColumnState(columnDefs, columnsState);

  return columnDefs
    .map(colDef => {
      if (colDef.children) {
        return {
          ...colDef,
          children: processGroomedColumnDefs(colDef.children, order),
        };
      }

      return colDef;
    })
    .sort(sortColumnsByColumnState(order));
};

export default processGroomedColumnDefs;
