import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { propOr } from 'ramda';

import BaseLayout from 'design-system/organisms/BaseLayout/index';

import { spacing } from 'design-system/theme';
import { draftBackgroundColor } from 'design-system/constants/draft';

import ScenariosChartsOptions from 'containers/ScenariosChartsOptions/ScenariosChartsOptions';
import {
  SCENARIOS_BENEFITS_COMPARISON_CHART,
  SCENARIO_STACKED_BENEFIT_CHART,
} from 'routes/Dashboard/Dashboards/helpers/templates';
import { getSelectedRoadmapVersion } from 'store/roadmapVersions/selectors';
import useScenariosChartsOptions from 'containers/ScenariosChartsOptions/useScenariosChartsOptions';
import ChartWidget from 'routes/Dashboard/Dashboards/ChartWidget';
import { SCENARIO_CHART_PAGE } from 'constants/filters/pages';
import useUpsellPage from 'hooks/useUpsellPage';
import { PLAN_OF_RECORD_ID, PLAN_OF_RECORD_TITLE, PAGE_HEADER_HEIGHT } from 'constants/common';
import useRoadmapVersions from 'hooks/useRoadmapVersions';

const getScenarioNameWithDefault = propOr('', 'name');

/**
 * Creates widgets based on the selected roadmap versions.
 * @param {number[]} selectedRoadmapVersionsIds
 * @returns
 */
const createWidgets = (selectedRoadmapVersionsId, projectedField, stackedByField, timeBucketMode, dateInterval, labelsMap) => {
  const comparisonChart = {
    ...SCENARIOS_BENEFITS_COMPARISON_CHART,
    props: {
      roadmapVersionIds: [selectedRoadmapVersionsId],
      projectedField,
      timeBucketMode,
      dateInterval,
      labelsMap,
    },
  };

  const planOfRecordStackedChartWidget = {
    ...SCENARIO_STACKED_BENEFIT_CHART,
    props: {
      roadmapVersionId: selectedRoadmapVersionsId,
      chartTitle: labelsMap[selectedRoadmapVersionsId],
      dateInterval,
      projectedField,
      timeBucketMode,
      stackedByField,
    },
  };

  return [comparisonChart, planOfRecordStackedChartWidget];
};

const ScenarioChart = () => {
  const selectedRoadmapVersion = useSelector(state => getSelectedRoadmapVersion(state));

  const { projectedOptionSelected, stackedByOptionSelected, timeBucketOptionSelected, dateInterval } =
    useScenariosChartsOptions();

  const { isRoadmapVersionSelected } = useRoadmapVersions();

  const UpsellScenarioChart = useUpsellPage(SCENARIO_CHART_PAGE);

  const widgets = useMemo(() => {
    const labelsMap = {
      [selectedRoadmapVersion?.id]: getScenarioNameWithDefault(selectedRoadmapVersion),
      [PLAN_OF_RECORD_ID]: PLAN_OF_RECORD_TITLE,
    };

    return createWidgets(
      selectedRoadmapVersion?.id,
      projectedOptionSelected?.key,
      stackedByOptionSelected?.key,
      timeBucketOptionSelected?.key,
      dateInterval,
      labelsMap,
    );
  }, [selectedRoadmapVersion, projectedOptionSelected, timeBucketOptionSelected, dateInterval]);

  if (UpsellScenarioChart) return <UpsellScenarioChart />;

  return (
    <Wrapper useDraftStyle={isRoadmapVersionSelected}>
      <BaseLayout>
        <ScenariosChartsOptions showSelectedVersionsBadge={false} />
        <ChartsContainer>
          {widgets.map(widget => (
            <ChartWidget widget={widget} inEditMode={false} />
          ))}
        </ChartsContainer>
      </BaseLayout>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  // padding bottom needed while this page has only 1 chart to doesn't cut the module menu dropdown
  padding-bottom: ${spacing(6.25)}px;

  height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
  ${({ useDraftStyle }) => (useDraftStyle ? `background-color: ${draftBackgroundColor}` : '')};
`;

const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${spacing(2.25)}px;
`;

export default ScenarioChart;
