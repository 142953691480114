import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import theme, { spacing } from '../../theme';

const StyledLineSeparator = styled.div`
  border-left: 1px solid ${theme.palette.border.gray};
  position: absolute;
  left: calc(50% - 0.5px);
  top: 5%;
  bottom: 0;
`;

const RowWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 380px;
  display: flex;
  margin-bottom: ${spacing(2)}px;
  justify-content: stretch;
  border-bottom: 1px solid ${theme.palette.border.gray};
`;

const TopListWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  &&&& > div {
    height: 100%;
    border: 1px dashed transparent;
    margin: 0 ${spacing()}px;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const BottomListWrapper = styled.div`
  width: 100%;
  margin-top: ${spacing(2)}px;
  border-bottom: 1px solid ${theme.palette.border.gray};
  transition: all ease 0.4s;

  &&&& > div {
    position: relative;
    height: 91px;
    border: 1px dashed transparent;
    margin: 0 ${spacing()}px;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const StyledHideMessage = styled(Typography)`
  padding-left: ${spacing(3.125)}px;
`;

export { StyledHideMessage, BottomListWrapper, TopListWrapper, RowWrapper, StyledLineSeparator };
