import { fromJS } from 'immutable';
import omit from 'lodash/omit';
import { defaultTo, pick, prop } from 'ramda';

import {
  IDEAS_BOARD_PAGE,
  FORECAST_PAGE,
  MANAGE_PAGE,
  SUMMARY_PAGE,
  GOALS_SUMMARY_PAGE,
  GOALS_GRID_PAGE,
  GOALS_SNAPSHOT_PAGE,
  GOALS_ALLOCATION_PAGE,
  SNAPSHOT_PAGE,
  IDEAS_GRID_PAGE,
  IDEAS_ESTIMATES_PAGE,
  ROADMAP_PAGE,
  ALLOCATION_REPORT_PAGE,
  DASHBOARDS_PAGE,
  REQUESTS_INSIGHTS,
  REQUESTS_DETAIL,
  REQUESTS_LIST,
  IDEAS_PDLC_PAGE,
  METRICS_GRID_PAGE,
  METRICS_CHART_PAGE,
  OBJECTIVES_FILTER,
  GLOBAL_FILTER,
  METRICS_FILTER,
  PORTFOLIO_CANVAS_PAGE,
  GOALS_CANVAS_PAGE,
  METRICS_CANVAS_PAGE,
} from 'constants/filters';
import { IDEAS, ESTIMATES, CUSTOMER_REQUESTS, GOAL_MODE, PDLC, METRICS } from 'store/grids/constants';
import buildPageFiltersWithPlanningStages from 'utils/filters/buildPageFiltersWithPlanningStages';
import {
  COMPARE_SCENARIOS_FILTER,
  COMPARE_SCENARIOS_LIST_PAGE,
  COMPARE_SCENARIOS_SWIMLANE_PAGE,
  COMPARE_SCENARIOS_CHARTS_PAGE,
  COMPARE_SCENARIOS_SUMMARY_PAGE,
  SCENARIO_AI_PLANNER_PAGE,
  SCENARIO_FILTER,
  SCENARIO_LIST_PAGE,
  SCENARIO_SWIMLANE_PAGE,
  SCENARIO_GANTT_PAGE,
  SCENARIO_SUMMARY_PAGE,
  MULTI_LAYER_FILTER,
  IDEAS_FLAT_GRID_PAGE,
  PROGRESS_PAGE,
  SCENARIO_CHART_PAGE,
  FORECAST_CHART_PAGE,
  IDEAS_OVERVIEW_PAGE,
  REQUESTS_FILTERS,
  ALLOCATION_REPORT_FILTERS,
  SNAPSHOT_FILTERS,
  PROGRESS_FILTERS,
} from 'constants/filters/pages';

const defaultEmptyObject = defaultTo({});

export const READ_ONLY_VIEW_PROPS = ['total_view_count', 'current_month_view_count'];

/**
 * Unless specified here views for the pages will only use state from the view
 * If the page needs to use the portfolio project filters must be mentioned here
 */
export const PAGES_WITH_PORTFOLIO_FILTERS = [
  IDEAS_BOARD_PAGE,
  SUMMARY_PAGE,
  FORECAST_PAGE,
  MANAGE_PAGE,
  IDEAS_GRID_PAGE,
  IDEAS_ESTIMATES_PAGE,
  ROADMAP_PAGE,
  IDEAS_PDLC_PAGE,
  PORTFOLIO_CANVAS_PAGE,
];

export const SHARED_FILTERS_BY_PAGE = {
  [GLOBAL_FILTER]: [
    IDEAS_BOARD_PAGE,
    SUMMARY_PAGE,
    FORECAST_PAGE,
    FORECAST_CHART_PAGE,
    MANAGE_PAGE,
    IDEAS_GRID_PAGE,
    IDEAS_ESTIMATES_PAGE,
    ROADMAP_PAGE,
    IDEAS_PDLC_PAGE,
    PORTFOLIO_CANVAS_PAGE,
    IDEAS_OVERVIEW_PAGE,
  ],
  [MULTI_LAYER_FILTER]: [IDEAS_FLAT_GRID_PAGE],
  [OBJECTIVES_FILTER]: [GOALS_GRID_PAGE, GOALS_SUMMARY_PAGE, GOALS_SNAPSHOT_PAGE, GOALS_ALLOCATION_PAGE, GOALS_CANVAS_PAGE],
  [METRICS_FILTER]: [METRICS_GRID_PAGE, METRICS_CHART_PAGE, METRICS_CANVAS_PAGE],
  [COMPARE_SCENARIOS_FILTER]: [
    COMPARE_SCENARIOS_LIST_PAGE,
    COMPARE_SCENARIOS_SWIMLANE_PAGE,
    COMPARE_SCENARIOS_CHARTS_PAGE,
    COMPARE_SCENARIOS_SUMMARY_PAGE,
  ],
  [SCENARIO_FILTER]: [
    SCENARIO_LIST_PAGE,
    SCENARIO_SWIMLANE_PAGE,
    SCENARIO_GANTT_PAGE,
    SCENARIO_AI_PLANNER_PAGE,
    SCENARIO_SUMMARY_PAGE,
    SCENARIO_CHART_PAGE,
  ],
  [REQUESTS_FILTERS]: [REQUESTS_LIST, REQUESTS_DETAIL, REQUESTS_INSIGHTS],
  [ALLOCATION_REPORT_FILTERS]: [ALLOCATION_REPORT_PAGE],
  [SNAPSHOT_FILTERS]: [SNAPSHOT_PAGE],
  [PROGRESS_FILTERS]: [PROGRESS_PAGE],
};

/**
 * Unless specified all the pages that are integrated with views engine will have a default view ensured on the first visit
 * Some pages may not need this behavior like dashboards were we only wan't to store/edit the view with specific behaviour
 */
export const DISABLE_DEFAULT_VIEW_FOR_PAGES = [
  DASHBOARDS_PAGE,
  COMPARE_SCENARIOS_LIST_PAGE,
  COMPARE_SCENARIOS_SWIMLANE_PAGE,
  COMPARE_SCENARIOS_CHARTS_PAGE,
  COMPARE_SCENARIOS_SUMMARY_PAGE,
  SCENARIO_LIST_PAGE,
  SCENARIO_SWIMLANE_PAGE,
  SCENARIO_GANTT_PAGE,
  SCENARIO_AI_PLANNER_PAGE,
];

const GOAL_MODE_FILTER_FIELDS = ['fields', 'aplliedAt', 'op', 'quickFilters'];

const METRIC_MODE_FILTER_FIELDS = ['metrics', 'operations', 'editingChartsLayout'];

const WHITEBOARD_FILTER_FIELDS = ['nodesLayout', 'edgesLayout', 'layoutConfig'];

export const PAGES_STATE_TO_SAVE = {
  [REQUESTS_INSIGHTS]: ({ customerRequests }) => ({
    multiFilters: customerRequests.multiFilters,
    multiFilterActive: customerRequests.multiFilterActive,
    visibleFields: customerRequests.visibleFields,
    selectedCounterPieChartGroupBy: customerRequests.selectedCounterPieChartGroupBy,
    selectedCounterStackedChartGroupBy: customerRequests.selectedCounterStackedChartGroupBy,
    selectedCounterStackedChartStackedBy: customerRequests.selectedCounterStackedChartStackedBy,
  }),
  [REQUESTS_LIST]: ({ grids, customerRequests }) => ({
    grids: grids.get(CUSTOMER_REQUESTS, fromJS({})).toJS(),
    multiFilters: customerRequests.multiFilters,
    multiFilterActive: customerRequests.multiFilterActive,
    visibleFields: customerRequests.visibleFields,
  }),
  [REQUESTS_DETAIL]: ({ customerRequests }) => ({
    multiFilters: customerRequests.multiFilters,
    multiFilterActive: customerRequests.multiFilterActive,
  }),
  [IDEAS_BOARD_PAGE]: ({ ideasBoard, _filters }) => ({
    ideasBoard,
    filter: _filters.global,
  }),
  [SUMMARY_PAGE]: ({ summary, _filters, portfolioSummaryGrouping }) => ({
    summary: summary.portfolioSummary,
    filter: _filters.global,
    portfolioSummaryGrouping: pick(['userAddedMetadata', 'userRemovedMetadata'], portfolioSummaryGrouping),
  }),
  [GOALS_SUMMARY_PAGE]: ({ summary, _filters }) => ({
    filter: pick(GOAL_MODE_FILTER_FIELDS, _filters.objectives ?? {}),
    summary: summary.goalSummary,
  }),
  [GOALS_GRID_PAGE]: ({ goalMode, _filters, grids, app }) => ({
    goalMode: omit(goalMode, [
      'operations',
      'outcomeSnapshotData',
      'outcomeSnapshotState',
      'outcomeAllocationData',
      'outcomeAllocationState',
    ]),
    filter: pick(GOAL_MODE_FILTER_FIELDS, _filters.objectives ?? {}),
    grids: grids?.get(GOAL_MODE, fromJS({}))?.toJS() ?? {},
    app: { pageMode: app?.pageMode },
  }),
  [GOALS_SNAPSHOT_PAGE]: ({ goalMode, _filters }) => ({
    goalMode: pick(['outcomeSnapshotState'], goalMode),
    filter: pick(GOAL_MODE_FILTER_FIELDS, _filters.objectives ?? {}),
  }),
  [GOALS_ALLOCATION_PAGE]: ({ goalsAllocation, _filters }) => {
    return {
      goalsAllocation: omit(goalsAllocation, ['operations']),
      filter: pick(GOAL_MODE_FILTER_FIELDS, _filters.objectives ?? {}),
    };
  },
  [SNAPSHOT_PAGE]: ({ snapshot, grids, _filters }) => ({
    snapshot: omit(snapshot, ['operations', 'snapshotData']),
    filter: _filters.snapshot,
    grids: grids?.get(SNAPSHOT_PAGE)?.toJS() ?? {},
  }),
  [FORECAST_PAGE]: ({ forecast, _filters }) => ({
    forecast,
    filter: _filters.global,
  }),
  [MANAGE_PAGE]: ({ manage, _filters }) => ({
    manageState: manage.manageState,
    filter: _filters.global,
  }),
  [IDEAS_GRID_PAGE]: ({ grids, _filters }) => {
    return {
      grids: {
        ...grids.get(IDEAS, fromJS({})).toJS(),
        selectedItems: [],
      },
      filter: _filters.global,
    };
  },
  [IDEAS_FLAT_GRID_PAGE]: ({ grids, _filters }) => {
    return {
      grids: {
        ...grids.get(IDEAS, fromJS({})).toJS(),
        selectedItems: [],
      },
      filter: _filters[MULTI_LAYER_FILTER],
    };
  },
  [IDEAS_ESTIMATES_PAGE]: ({ grids, _filters, forecastHeadcount }) => ({
    grids: grids.get(ESTIMATES, fromJS({})).toJS(),
    forecastHeadcount: prop(IDEAS_ESTIMATES_PAGE, forecastHeadcount),
    filter: _filters.global,
    displayLayer: _filters.displayLayer,
    portfolioMode: _filters.portfolioMode,
  }),
  [ROADMAP_PAGE]: ({ roadmap, _filters, timelines }) => ({
    roadmap,
    filter: _filters.global,
    timelines,
  }),
  [ALLOCATION_REPORT_PAGE]: ({ allocationReport, goalsAllocation, _filters }) => ({
    allocationReport,
    goalsAllocation: omit(goalsAllocation, ['operations']),
    filter: _filters.allocationReport,
  }),
  [DASHBOARDS_PAGE]: ({ dashboards, _filters }) => ({
    dashboard: omit(dashboards, ['inEditMode']),
    filter: _filters.dashboards || _filters.global,
  }),
  [IDEAS_PDLC_PAGE]: ({ grids, _filters }) => ({
    grids: grids.get(PDLC, fromJS({})).toJS(),
    filter: _filters.global,
  }),
  [METRICS_GRID_PAGE]: ({ metrics, _filters, grids }) => ({
    metricMode: omit(metrics, [...METRIC_MODE_FILTER_FIELDS, 'chartsLayout']),
    filter: _filters.metrics,
    grids: grids?.get(METRICS)?.toJS() ?? {},
  }),
  [METRICS_CHART_PAGE]: ({ metrics, _filters }) => ({
    metricMode: omit(metrics, METRIC_MODE_FILTER_FIELDS),
    filter: _filters.metrics,
  }),
  [PROGRESS_PAGE]: ({ progress, _filters }) => ({
    progress: omit(progress, ['operations']),
    filter: _filters.progress || _filters.global,
  }),
  [PORTFOLIO_CANVAS_PAGE]: ({ whiteboard, _filters }) => ({
    whiteboard: pick(WHITEBOARD_FILTER_FIELDS, whiteboard),
    filter: _filters.global,
  }),
  [GOALS_CANVAS_PAGE]: ({ goalsCanvas, _filters, app }) => ({
    goalsCanvas,
    filter: pick(GOAL_MODE_FILTER_FIELDS, _filters.objectives ?? {}),
    app: { pageMode: app?.pageMode },
  }),
  [METRICS_CANVAS_PAGE]: ({ metricsCanvas, _filters, app }) => ({
    metricsCanvas,
    filter: _filters.metrics,
    app: { pageMode: app?.pageMode },
  }),
};

// Use here the util that deal with the planningStages logic
const _loadFilter = filter => omit(buildPageFiltersWithPlanningStages(filter), ['activeFilter']) || {};

const applyGridState = gridState => ({
  ...(gridState || {}),
  selectedItems: [],
  bulkDelete: false,
});

export const PAGES_STATE_TO_STORE = {
  [REQUESTS_INSIGHTS]: (
    {
      state: {
        multiFilters,
        multiFilterActive,
        visibleFields,
        selectedCounterPieChartGroupBy,
        selectedCounterStackedChartGroupBy,
        selectedCounterStackedChartStackedBy,
      },
    },
    state,
  ) => ({
    customerRequests: {
      ...state.customerRequests,
      multiFilters,
      multiFilterActive,
      visibleFields,
      selectedCounterPieChartGroupBy,
      selectedCounterStackedChartGroupBy,
      selectedCounterStackedChartStackedBy,
    },
  }),
  [REQUESTS_LIST]: ({ state: { grids: requestsGridState, multiFilters, multiFilterActive, visibleFields } }, state) => {
    const allGridsState = state.grids.toJS();

    return {
      grids: fromJS({
        ...allGridsState,
        [CUSTOMER_REQUESTS]: applyGridState(requestsGridState || allGridsState[CUSTOMER_REQUESTS]),
      }),
      customerRequests: {
        ...state.customerRequests,
        multiFilters,
        multiFilterActive,
        visibleFields,
      },
    };
  },
  [REQUESTS_DETAIL]: ({ state: { multiFilters, multiFilterActive } }, state) => ({
    customerRequests: {
      ...state.customerRequests,
      multiFilters,
      multiFilterActive,
    },
  }),
  [IDEAS_BOARD_PAGE]: ({ state: { ideasBoard, filter } }, state) => ({
    ideasBoard,
    _filters: {
      ...state._filters,
      ...(filter ? { global: _loadFilter(filter) } : {}),
    },
  }),
  [SUMMARY_PAGE]: ({ state: { summary, filter, portfolioSummaryGrouping } }, state) => ({
    summary: {
      ...state.summary,
      portfolioSummary: summary,
    },
    _filters: {
      ...state._filters,
      ...(filter ? { global: _loadFilter(filter) } : {}),
    },
    portfolioSummaryGrouping: {
      ...state.portfolioSummaryGrouping,
      ...portfolioSummaryGrouping,
    },
  }),
  [GOALS_SUMMARY_PAGE]: ({ state: { summary, filter } }, state) => ({
    summary: {
      ...state.summary,
      goalSummary: summary,
    },
    _filters: pick(GOAL_MODE_FILTER_FIELDS, {
      ...state._filters,
      ...(filter ? { objectives: filter } : {}),
    }),
  }),
  [GOALS_GRID_PAGE]: ({ state: { goalMode, filter, grids: goalsGridState, app = {} } }, state) => {
    const allGridsState = state.grids.toJS();

    return {
      goalMode: {
        ...state.goalMode,
        ...goalMode,
      },
      _filters: pick(GOAL_MODE_FILTER_FIELDS, {
        ...state._filters,
        ...(filter ? { objectives: filter } : {}),
      }),
      grids: fromJS({
        ...allGridsState,
        [GOAL_MODE]: applyGridState(goalsGridState || allGridsState[GOAL_MODE]),
      }),
      app: {
        ...state?.app,
        ...app,
      },
    };
  },
  [GOALS_SNAPSHOT_PAGE]: ({ state: { goalMode, filter } }, state) => ({
    goalMode: {
      ...state.goalMode,
      ...goalMode,
    },
    _filters: pick(GOAL_MODE_FILTER_FIELDS, {
      ...state._filters,
      ...(filter ? { objectives: filter } : {}),
    }),
  }),
  [GOALS_ALLOCATION_PAGE]: ({ state: { goalsAllocation, filter } }, state) => ({
    goalsAllocation: {
      ...state.goalsAllocation,
      ...goalsAllocation,
    },
    _filters: pick(GOAL_MODE_FILTER_FIELDS, {
      ...state._filters,
      ...(filter ? { objectives: filter } : {}),
    }),
  }),
  [SNAPSHOT_PAGE]: ({ state: { snapshot, filter, grids: snapshotGridState } }, state) => {
    const allGridsState = state.grids.toJS();

    return {
      snapshot: {
        operations: state?.snapshot?.operations,
        snapshotData: state?.snapshot?.snapshotData,
        ...snapshot,
      },
      _filters: {
        ...state._filters,
        ...(filter ? { global: _loadFilter(filter) } : {}),
      },
      grids: fromJS({
        ...allGridsState,
        [SNAPSHOT_PAGE]: applyGridState(snapshotGridState || allGridsState[SNAPSHOT_PAGE]),
      }),
    };
  },
  [FORECAST_PAGE]: ({ state: { forecast, filter } }, state) => ({
    forecast: {
      ...state.forecast,
      ...forecast,
    },
    _filters: {
      ...state._filters,
      ...(filter ? { global: _loadFilter(filter) } : {}),
    },
  }),
  [MANAGE_PAGE]: ({ state: { manageState, filter } }, state) => ({
    manage: { ...state.manage, manageState },
    _filters: {
      ...state._filters,
      ...(filter ? { global: _loadFilter(filter) } : {}),
    },
  }),
  [IDEAS_GRID_PAGE]: ({ state: { grids: ideasGridState, filter } }, state) => {
    const allGridsState = state.grids.toJS();

    return {
      grids: fromJS({
        ...allGridsState,
        [IDEAS]: applyGridState(ideasGridState || allGridsState[IDEAS]),
      }),
      _filters: {
        ...state._filters,
        ...(filter ? { global: _loadFilter(filter) } : {}),
      },
    };
  },
  [IDEAS_FLAT_GRID_PAGE]: ({ state: { grids: ideasGridState, filter } }, state) => {
    const allGridsState = state.grids.toJS();

    return {
      grids: fromJS({
        ...allGridsState,
        [IDEAS]: applyGridState(ideasGridState || allGridsState[IDEAS]),
      }),
      _filters: {
        ...state._filters,
        ...(filter ? { [MULTI_LAYER_FILTER]: _loadFilter(filter) } : {}),
      },
    };
  },
  [IDEAS_ESTIMATES_PAGE]: ({ state: { grids: estimatesGridState, filter, forecastHeadcount } }, state) => {
    const allGridsState = state.grids.toJS();

    return {
      grids: fromJS({
        ...allGridsState,
        [ESTIMATES]: applyGridState({ ...allGridsState[ESTIMATES], ...estimatesGridState }),
      }),
      _filters: {
        ...state._filters,
        ...(filter ? { global: _loadFilter(filter) } : {}),
      },
      forecastHeadcount: {
        ...defaultEmptyObject(state.forecastHeadcount),
        [IDEAS_ESTIMATES_PAGE]: defaultEmptyObject(forecastHeadcount),
      },
    };
  },
  [ROADMAP_PAGE]: ({ state: { roadmap, filter, timelines } }, state) => ({
    roadmap,
    _filters: {
      ...state._filters,
      ...(filter ? { global: _loadFilter(filter) } : {}),
    },
    timelines: {
      ...(timelines || {}),
      roadmap: {
        ...(state.timelines?.roadmap || {}),
        ...(timelines?.roadmap || {}),
      },
    },
  }),
  [ALLOCATION_REPORT_PAGE]: ({ state: { allocationReport, goalsAllocation, filter } }, state) => ({
    allocationReport,
    goalsAllocation: {
      ...state.goalsAllocation,
      ...goalsAllocation,
    },
    _filters: {
      ...state._filters,
      ...(filter ? { allocationReport: _loadFilter(filter) } : {}),
    },
  }),
  [DASHBOARDS_PAGE]: ({ state: { dashboard, filter } }, state) => ({
    dashboards: dashboard,
    _filters: {
      ...state._filters,
      ...(filter ? { dashboards: _loadFilter(filter) } : {}),
    },
  }),
  [IDEAS_PDLC_PAGE]: ({ state: { grids: pdlcGridState, filter } }, state) => {
    const allGridsState = state.grids.toJS();

    return {
      grids: fromJS({
        ...allGridsState,
        [PDLC]: applyGridState(pdlcGridState || allGridsState[PDLC]),
      }),
      _filters: {
        ...state._filters,
        ...(filter ? { global: _loadFilter(filter) } : {}),
      },
    };
  },
  [METRICS_GRID_PAGE]: ({ state: { metricMode, filter, grids: metricsGridState } }, state) => {
    const allGridsState = state.grids.toJS();

    return {
      metrics: {
        ...state.metrics,
        ...metricMode,
      },
      _filters: {
        ...state._filters,
        ...(filter ? { metrics: filter } : {}),
      },
      grids: fromJS({
        ...allGridsState,
        [METRICS]: applyGridState(metricsGridState || allGridsState[METRICS]),
      }),
    };
  },
  [METRICS_CHART_PAGE]: ({ state: { metricMode, filter }, default_view: defaultView, id }, state) => {
    // If we are loading a default view we should keep the store state.
    // If loading a saved view we should load the metric chartsLayout and reset edition mode flag
    const chartsLayout = defaultView ? state.metrics?.chartsLayout : metricMode?.chartsLayout;
    const editingChartsLayout = defaultView ? state.metrics?.editingChartsLayout : false;

    return {
      metrics: {
        ...state.metrics,
        ...metricMode,
        chartsLayout,
        editingChartsLayout,
      },
      _filters: {
        ...state._filters,
        ...(filter ? { metrics: filter } : {}),
      },
    };
  },
  [PROGRESS_PAGE]: ({ state: { progress, filter } }, state) => ({
    progress: {
      ...state.progress,
      ...progress,
      operations: state?.progress?.operations,
    },
    _filters: {
      ...state._filters,
      ...(filter ? { global: _loadFilter(filter) } : {}),
    },
  }),
  [PORTFOLIO_CANVAS_PAGE]: ({ state: { whiteboard, filter } }, state) => {
    return {
      whiteboard: {
        ...state?.whiteboard,
        ...whiteboard,
      },
      _filters: {
        ...state._filters,
        ...(filter ? { global: _loadFilter(filter) } : {}),
      },
    };
  },
  [GOALS_CANVAS_PAGE]: ({ state: { goalsCanvas, filter, app } }, state) => {
    return {
      goalsCanvas: {
        ...state?.goalsCanvas,
        ...goalsCanvas,
      },
      _filters: pick(GOAL_MODE_FILTER_FIELDS, {
        ...state._filters,
        ...(filter ? { objectives: filter } : {}),
      }),
      app: {
        ...state?.app,
        ...app,
      },
    };
  },
  [METRICS_CANVAS_PAGE]: ({ state: { metricsCanvas, filter, app } }, state) => {
    return {
      metricsCanvas: {
        ...state?.metricsCanvas,
        ...metricsCanvas,
      },
      _filters: {
        ...state._filters,
        ...(filter ? { metrics: filter } : {}),
      },
      app: {
        ...state?.app,
        ...app,
      },
    };
  },
};

// fields that hold filter data to omit from the view state update - default view
export const FILTER_FIELDS_TO_OMIT_FROM_VIEW_STATE = ['filter', 'multiFilters', 'multiFilterActive'];
