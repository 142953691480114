import React, { Fragment } from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';

import DateRange from 'components/DateRange';
import DateRangeMode from 'components/DateRangeMode';
import getUnroundedFixedDecimalValue from 'utils/getUnroundedFixedDecimalValue';

export default ({ isProject, isMilestone, formData, updateForm, dateRangeMode, onUpdateDateRangeMode }) => {
  const { deadline, estimated_start_date: estimatedStartDate, estimated_duration: estimatedDuration } = formData;

  const _onEstimatedDurationChange = ({ target }) => {
    if (!target.value) return updateForm({ estimated_duration: null, deadline: null });

    const newDuration = getUnroundedFixedDecimalValue(target.value);
    const oldDuration = getUnroundedFixedDecimalValue(timespan.duration / 7);

    // same value no need to trigger updates
    if (newDuration === oldDuration) {
      return;
    }

    if (dateRangeMode === 'duration') {
      updateForm({
        estimated_duration: newDuration ? newDuration * 7 : '',
        deadline: moment(estimatedStartDate, 'YYYY/MM/DD').addDuration(newDuration, 'weeks'),
      });
    }
  };
  const _onEstimatedStartDateChange = value => {
    if (!value) return updateForm({ estimated_start_date: null, estimated_duration: null, deadline: null });

    const startDate = moment(value);
    const endDate = deadline && moment(deadline).isValid() ? moment(deadline) : null;

    if (!startDate.isValid() || (endDate && dateRangeMode === 'endDate' && endDate.isBefore(startDate))) {
      updateForm({ estimated_start_date: moment(estimatedStartDate) });
      return;
    }

    if (!endDate) {
      updateForm({
        estimated_start_date: startDate,
        estimated_duration: 14,
        deadline: moment(startDate, 'YYYY/MM/DD').addDuration(14, 'days'),
      });
      return;
    }

    if (dateRangeMode === 'duration' && estimatedDuration) {
      updateForm({
        estimated_start_date: startDate,
        deadline: moment(startDate, 'YYYY/MM/DD').addDuration(estimatedDuration, 'days'),
      });
    } else if (dateRangeMode === 'endDate' && endDate) {
      updateForm({
        estimated_start_date: startDate,
        estimated_duration: endDate.diffDuration(startDate, 'days'),
      });
    } else {
      updateForm({ estimated_start_date: startDate });
    }
  };
  const _onDeadlineChange = value => {
    if (!value) return updateForm({ deadline: null, estimated_duration: null });

    const endDate = moment(value);
    const startDate = estimatedStartDate && moment(estimatedStartDate).isValid() ? moment(estimatedStartDate) : null;

    if (!endDate.isValid() || (startDate && dateRangeMode === 'endDate' && startDate.isAfter(endDate))) {
      updateForm({ deadline: moment(deadline) });
      return;
    }

    if (startDate && dateRangeMode === 'endDate') {
      updateForm({
        deadline: endDate,
        estimated_duration: endDate.diffDuration(startDate, 'days'),
      });
    } else {
      updateForm({ deadline: endDate });
    }
  };
  const startDate = estimatedStartDate && new Date(estimatedStartDate);
  const endDate = deadline && new Date(deadline);
  const timespan = {
    startDate,
    endDate,
    duration: estimatedDuration,
    changeStartDate: _onEstimatedStartDateChange,
    changeEndDate: _onDeadlineChange,
    changeDuration: _onEstimatedDurationChange,
  };

  return (
    <Fragment>
      <TimelineTabsContent>
        <FormControl fullWidth margin="normal">
          <DateRangeMode
            mode={dateRangeMode}
            onChange={({ target }) => onUpdateDateRangeMode(target.value)}
            hideModes={isMilestone}
            label={isProject ? '' : 'Timeline'}
          />
          <DateRange
            startDate={timespan.startDate}
            endDate={timespan.endDate}
            duration={timespan.duration ? getUnroundedFixedDecimalValue(timespan.duration / 7) : timespan.duration}
            onStartDateChange={timespan.changeStartDate}
            onDurationChange={timespan.changeDuration}
            onEndDateChange={timespan.changeEndDate}
            showDuration={isProject}
            showEndDate={isProject}
            disableDuration={dateRangeMode === 'endDate' || !estimatedStartDate}
            disableEndDate={dateRangeMode === 'duration'}
            withPadding
            durationMode="weeks"
            startLabel="Target start date"
            endLabel="Target end date"
          />
        </FormControl>
      </TimelineTabsContent>
    </Fragment>
  );
};

const TimelineTabsContent = styled.div`
  padding: 10px;

  > div {
    margin: 0;

    label[control='legend'] {
      margin: 0;
    }
  }
  small {
    display: block;
    margin-top: 10px;
    color: #a8a8a8;
    font-size: 14px;
  }
`;
