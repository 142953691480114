export default (estimateId, projects) => {
  if (!projects) return {};
  let estimateIndex;
  const projectIndex = projects.findIndex(project => {
    if (!project.has('estimates')) return false;

    estimateIndex = project.get('estimates').findIndex(estimate => {
      return estimate.get('id') === estimateId;
    });
    return estimateIndex >= 0;
  });

  return { estimateIndex, projectIndex };
};
