import { useCallback } from 'react';
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';

import { ROADMAP_PAGE } from 'constants/filters';

import useTimelines from 'hooks/useTimelines/New/useTimelines';

import useSwimlaneProjects from './useSwimlaneProjects';

import {
  DAY_UNIT_TIME,
  DAYS_UNIT_TIME,
  WEEKS_UNIT_TIME,
  makeOpenNewProjectLightbox,
  updateProjectWithGroupData,
} from '../helpers';

/**
 * Custom hook responsible for the decoration of projects actions to be used
 * in the Swimlane handlers passed to Timeline component.
 * */
const useSwimlaneActions = groupsData => {
  // Get the timeline configuration
  const { selectedGroupOptions } = useTimelines(ROADMAP_PAGE);

  // Get the projects and all the project's information
  const { projects, isUpdatingProject, displayLayer, updateProject, openProjectLightbox } = useSwimlaneProjects();

  const openNewProjectLightbox = makeOpenNewProjectLightbox({
    displayLayer,
    groupsData,
    selectedGroupOptions,
    openProjectLightbox,
  });

  const openNewMilestoneLightbox = () => openNewProjectLightbox(null, true);

  // Update project
  const updateProjectTimeline = useCallback(
    (projectId, fromDiff, toDiff, groupIds = []) => {
      if (isUpdatingProject) {
        return;
      }

      const existingProject = projects.find(project => project.id === projectId);

      if (!existingProject) {
        return;
      }

      const newProject = cloneDeep(existingProject);

      // Update start date
      const shouldUpdateStartDate = fromDiff || !newProject.estimated_start_date;

      if (shouldUpdateStartDate) {
        const startDate = newProject.estimated_start_date ? moment(newProject.estimated_start_date) : moment();

        newProject.estimated_start_date = startDate.add(fromDiff, DAYS_UNIT_TIME).startOf(DAY_UNIT_TIME);
      }

      // Update end date
      const shouldUpdateEndDate = toDiff || !existingProject.deadline;

      if (shouldUpdateEndDate) {
        const deadline = newProject.deadline ? moment(newProject.deadline) : moment().add(2, WEEKS_UNIT_TIME);

        newProject.deadline = deadline.add(toDiff, DAYS_UNIT_TIME).endOf(DAY_UNIT_TIME);
      }

      // Update groups fields on project
      if (!isEmpty(groupIds)) {
        groupIds.forEach((groupId, index) =>
          updateProjectWithGroupData(newProject, selectedGroupOptions[index], groupsData[index], groupId),
        );
      }

      updateProject(newProject);
    },
    [isUpdatingProject, projects, groupsData, selectedGroupOptions, updateProject],
  );

  return {
    openNewProjectLightbox,
    openNewMilestoneLightbox,
    openProjectLightbox,
    updateProjectTimeline,
  };
};

export default useSwimlaneActions;
