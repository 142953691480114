import { createSelector } from 'reselect';

const getAnnouncementsState = state => {
  return state.announcements;
};

const getAnnouncements = createSelector(getAnnouncementsState, state => state.announcements);

export { 
  getAnnouncements,
};