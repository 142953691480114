import React from 'react';
import styled from 'styled-components';

import secondaryColors from './secondaryColors';
import ChromePicker from 'components/ReactColor/ChromePicker';

export default (props) => (
  <Wrapper>
    <ChromePicker
      color={props.color}
      colors={secondaryColors}
      onChange={props.onChange}
      handleClose={props.handleClose}
    />
  </Wrapper>
);

const Wrapper = styled.div`
  // .block-picker > div:nth-child(1) {
  //   display: none!important;
  // }
  width: 100%;
  height: 100%;
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',

`;
