import { defaultTo, indexBy, prop } from 'ramda';
import { createSelector } from 'reselect';

import { selectMetadata } from 'store/projects/metadataSelectors';
import { getEnrichedProject } from 'store/projects/helpers/enrichProjects';
import { getOrganizationIntegrations } from 'store/organization/selectors';
import { getNormalizedUsers } from 'store/users/selectors';
import { getAllTeams } from 'store/teams/selectors';

import { projectsAdapter, snapsAdapter, snapDiffsAdapter } from './helpers/adapters';
import { normalizeSnapProject } from 'features/RoadmapHistory/store/helpers/projects';
import { isLoading } from 'utils/store/thunk';
import {
  FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW,
  SELECT_VIEW_SNAP_TO_COMPARE,
  REMOVE_SELECTED_SNAP_TO_COMPARE,
  FETCH_ROADMAP_HISTORY_COMPARE_DATA,
} from './types';

const byId = indexBy(prop('id'));
const emptyArray = [];
const emptyObject = {};

export const getState = state => state?.roadmapHistory;

const getOperationsState = state => getState(state)?.operations || emptyObject;

const defaultToProjectsInitialState = defaultTo(projectsAdapter.getInitialState());
const defaultToSnapsInitialState = defaultTo(snapsAdapter.getInitialState());
const defaultToSnapDiffsInitialState = defaultTo(snapDiffsAdapter.getInitialState());

const projectsSelector = projectsAdapter.getSelectors(state => {
  return defaultToProjectsInitialState(state.projects);
});

const snapsSelector = snapsAdapter.getSelectors(state => {
  return defaultToSnapsInitialState(state.snapshots);
});

const snapDiffsSelector = snapDiffsAdapter.getSelectors(state => {
  return defaultToSnapDiffsInitialState(state.snapDiffs);
});

export const selectAllDiffs = state => snapDiffsSelector.selectAll(getState(state));
export const selectSelectedSnapsDiffs = createSelector(
  state => snapDiffsSelector.selectAll(getState(state)),
  state => snapsSelector.selectIds(getState(state)),
  (_, targetSnap = 'current') => targetSnap,
  (diffs = emptyArray, selectedSnapsIds = emptyArray, targetSnap) => {
    const selectedSnapDiffsIds = selectedSnapsIds.map(snapId => `${snapId}-${targetSnap}`);

    return diffs.filter(diff => selectedSnapDiffsIds.includes(diff?.id));
  },
);

export const selectSelectedSnapshotsIdsToCompare = state => snapsSelector.selectIds(getState(state));

const parseProjectJiras = ({ Jiras = [] }) => Jiras?.map(jira => ({ ...jira, ...jira?.data, ...jira?.progress }));

export const selectCurrentProjects = createSelector(
  state => selectMetadata(state),
  state => projectsSelector.selectAll(getState(state)),
  (metadata, projects) => {
    return projects.map(project => {
      const projectJirasParsed = parseProjectJiras(project);

      return getEnrichedProject({ ...project, Jiras: projectJirasParsed }, metadata);
    });
  },
);

export const selectCurrentProjectsIds = state => projectsSelector.selectIds(getState(state));

export const selectSelectedSnapsToCompare = state => snapsSelector.selectAll(getState(state));

export const selectProjectsOfSelectedSnapsToCompare = createSelector(
  state => snapsSelector.selectAll(getState(state)) || emptyArray,
  state => getOrganizationIntegrations(state, true),
  state => getNormalizedUsers(state, true),
  state => getAllTeams(state, true),
  (snapshots, orgIntegrations, users, teams) => {
    const snapsAndProjectsById = snapshots.reduce((acc, snapshot) => {
      return {
        ...acc,
        [snapshot?.id]: {
          ...snapshot,
          projects: byId(
            snapshot?.projects?.map(project =>
              normalizeSnapProject(project?.project_data, {
                orgIntegrations,
                users,
                teams,
              }),
            ) || emptyArray,
          ),
        },
      };
    }, emptyObject);

    return snapsAndProjectsById;
  },
);

export const selectViewRoadmapHistorySnapsDialogProperties = state => getState(state)?.viewSnapsDialog || emptyObject;

export const selectRoadmapHistorySnapshotsByUserView = state => getState(state)?.snapshotsListByUserView || emptyObject;

export const selectIsLoadingSnapshotsByUserView = state =>
  isLoading(getOperationsState(state), FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW);

export const selectRoadmapHistorySnapsForCurrentView = createSelector(
  state => getState(state)?.currentViewId,
  selectRoadmapHistorySnapshotsByUserView,
  (currentViewId, roadmapViewHistorySnaps) => {
    return roadmapViewHistorySnaps?.[currentViewId] || emptyArray;
  },
);

export const selectCurrentViewId = state => getState(state)?.currentViewId;

export const selectSelectedSnapshotsToCompareWithoutProjects = createSelector(
  selectSelectedSnapshotsIdsToCompare,
  selectRoadmapHistorySnapsForCurrentView,
  (selectedSnapsIds = emptyArray, snaps = emptyArray) => {
    return snaps.filter(snap => selectedSnapsIds.includes(snap?.id));
  },
);

export const selectIsLoadingSnapProjects = state =>
  isLoading(getOperationsState(state), FETCH_ROADMAP_HISTORY_COMPARE_DATA) ||
  isLoading(getOperationsState(state), SELECT_VIEW_SNAP_TO_COMPARE) ||
  isLoading(getOperationsState(state), REMOVE_SELECTED_SNAP_TO_COMPARE);

export const selectIsLoadingCompareData = state => isLoading(getOperationsState(state), FETCH_ROADMAP_HISTORY_COMPARE_DATA);
export const selectCompareUuid = state => getState(state)?.compareUuid;
