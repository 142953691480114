import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

const numberComparator = (a, b, _1, _2, isInverted, includeZero = false, forceInversion = false) => {
  const sanitizedA = isString(a) ? a?.replace(',', '') : a;
  const sanitizedB = isString(b) ? b?.replace(',', '') : b;

  const valueA = parseFloat(sanitizedA);
  const valueB = parseFloat(sanitizedB);

  if ((isNaN(valueA) && isNaN(valueB)) || valueA === valueB) {
    return 0;
  }

  if ((isNaN(valueA) || (!includeZero && valueA === 0)) && isNumber(valueB)) {
    return isInverted ? -1 : 1;
  }

  if (isNumber(valueA) && (isNaN(valueB) || (!includeZero && valueB === 0))) {
    return isInverted ? 1 : -1;
  }

  const result = valueA < valueB ? -1 : 1;

  return forceInversion ? result * -1 : result;
};

export default numberComparator;
