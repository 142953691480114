import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { defaultTo, isEmpty } from 'ramda';

import { useCommonGridProps, useCommonGridEventHandlers, useInitAgGrid } from 'design-system/molecules/AgGridReact-New/hooks';

import { getUsers } from 'store/users/selectors';
import { getOrganization } from 'store/organization/selectors';
import { selectGridRowHeight } from '../store/selectors';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

import {
  createNewGridPersona,
  updateGridPersona,
  removeUnsavedPersona as removeUnsavedPersonaAction,
  deleteGridPersona,
  createPersonaRoadmap,
  deletePersonaRoadmap,
  bulkDeletePersonaRoadmaps,
  switchRowOrder,
} from '../store';
import usePersonasGridColumnDefinition from './usePersonasGridColumnDefinition';
import { PERSONA_ROADMAP_FIELD } from '../helpers/constants';

const defaultToEmptyArray = defaultTo([]);

const usePersonaList = ({ personas, canUpdate = false, canDelete = false }) => {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const organization = useSelector(getOrganization);
  const rowHeight = useSelector(selectGridRowHeight);
  const { getDefaultRoadmapTitleForMetadataItem } = useOrganizationsAccessControl();

  const data = useMemo(() => {
    return personas.map(item => {
      let personaRoadmaps = defaultToEmptyArray(item[PERSONA_ROADMAP_FIELD]);

      if (isEmpty(personaRoadmaps)) {
        const defaultRoadmapTitle = getDefaultRoadmapTitleForMetadataItem(item);

        personaRoadmaps = [{ title: defaultRoadmapTitle }];
      }

      return {
        ...item,
        [PERSONA_ROADMAP_FIELD]: personaRoadmaps,
      };
    });
  }, [personas, getDefaultRoadmapTitleForMetadataItem]);

  const updatePersona = useCallback((id, data) => dispatch(updateGridPersona(id, data)), []);
  const savePersona = useCallback(data => dispatch(createNewGridPersona(data)), []);
  const removeUnsavedPersona = useCallback(() => dispatch(removeUnsavedPersonaAction()), []);
  const deletePersona = useCallback(({ id }) => dispatch(deleteGridPersona(id)), []);
  const handleRowDrag = useCallback(
    (currentNode, lastOverNode, { position }) =>
      dispatch(switchRowOrder(currentNode.data.id, lastOverNode.data.id, null, position)),
    [],
  );

  const roadmapColumn = {
    personas: data,
    createPersonaRoadmap,
    deletePersonaRoadmap,
    bulkDeletePersonaRoadmaps,
    hideMetadataRoadmaps: !organization.enable_metadata_roadmaps,
  };
  const { columnDefs, defaultColumnDef } = usePersonasGridColumnDefinition({
    canUpdate,
    canDelete,
    users,
    roadmapColumn,
    deletePersona,
  });
  const commonGridProps = useCommonGridProps({ isTreeView: false });
  const commonGridEvents = useCommonGridEventHandlers({
    items: data,
    idKey: 'id',
    actions: {
      save: savePersona,
      update: updatePersona,
      remove: removeUnsavedPersona,
      drag: handleRowDrag,
    },
  });
  const { isGridReady: getGridIsReady, getGridApi, initAgGrid } = useInitAgGrid();

  const handleGridReady = useCallback(
    params => {
      initAgGrid(params.api, params.columnApi);
    },
    [initAgGrid],
  );

  return {
    data,
    columnDefs,
    defaultColumnDef,
    commonGridProps,
    commonGridEvents,
    rowHeight,
    getGridIsReady,
    getGridApi,
    handleGridReady,
  };
};

export default usePersonaList;
