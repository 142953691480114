import { mapFields } from './updateProjectGroupData';

const makeCreateProject =
  ({
    displayLayer,
    filter,
    filterData,
    mapLayers,
    firstGroupType,
    firstGroupData,
    secondGroupType,
    secondGroupData,
    handleOpenProjectLightBox,
  }) =>
  (laneId = null, groupId = null, isMilestone = false) => {
    const payload = {
      layer: isMilestone ? '0' : displayLayer,
    };

    if (isMilestone) {
      payload.type = 'milestone';
    }

    if (filter.key) {
      const field = mapFields[filter.key] || filter.key;
      const value = filterData.find(f => `${f.id}` === `${filter.value}`);

      if (value) {
        if (['bet', 'initiative'].includes(field)) {
          payload.parent_id = value.id;
          payload.parent = value.title;
          payload.parentLayer = mapLayers[field];
        } else {
          payload[field] = value;
          payload[`${field}Title`] = value.title;
          payload[`${filter.key}_id`] = value.id;
        }
      }
    }

    if (laneId !== null) {
      const field = mapFields[firstGroupType.key] || firstGroupType.key;
      const lane = firstGroupData.find(l => l.id === laneId);

      if (lane) {
        if (['bet', 'initiative'].includes(field)) {
          payload.parent = lane.title;
          payload.parent_id = lane.id;
          payload.parentLayer = mapLayers[field];
        } else {
          payload[field] = lane;
          payload[`${field}Title`] = lane.title;
          payload[`${firstGroupType.key}_id`] = laneId;
        }
      }
    }

    if (groupId !== null) {
      const field = mapFields[secondGroupType.key] || secondGroupType.key;
      const group = secondGroupData.find(g => g.id === groupId);

      if (group) {
        if (['bet', 'initiative'].includes(field)) {
          payload.parent = group.title;
          payload.parent_id = group.id;
          payload.parentLayer = mapLayers[field];
        } else {
          payload[field] = group;
          payload[`${field}Title`] = group.title;
          payload[`${secondGroupType.key}_id`] = groupId;
        }
      }
    }

    handleOpenProjectLightBox(null, null, payload);
  };

export default makeCreateProject;
