import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import {
  ADD_METRIC_VALUE_WITHOUT_SAVE_LIGHTBOX,
  CREATE_METRIC_VALUE_LIGHTBOX,
  CREATE_METRIC_VALUE_LIGHTBOX_FULFILLED,
  OPEN_METRIC_LIGHTBOX,
  OPEN_METRIC_LIGHTBOX_FULFILLED,
  FETCH_METRIC_BY_ID_LIGHTBOX,
  FETCH_METRIC_BY_ID_LIGHTBOX_FULFILLED,
  REMOVE_UNSAVED_METRIC_VALUES_LIGHTBOX,
  UPDATE_METRIC_VALUE_LIGHTBOX,
  UPDATE_METRIC_VALUE_LIGHTBOX_FULFILLED,
  DELETE_METRIC_VALUE_LIGHTBOX,
  DELETE_METRIC_VALUE_LIGHTBOX_FULFILLED,
  UPDATE_METRIC_BY_ID_LIGHTBOX,
  UPDATE_METRIC_BY_ID_LIGHTBOX_FULFILLED,
  CREATE_METRIC_LIGHTBOX,
  CREATE_METRIC_LIGHTBOX_FULFILLED,
  RESET_DATA,
  CREATE_METRIC_ROADMAP_LIGHTBOX,
  CREATE_METRIC_ROADMAP_LIGHTBOX_FULFILLED,
  DELETE_METRIC_ROADMAP_LIGHTBOX,
  DELETE_METRIC_ROADMAP_LIGHTBOX_FULFILLED,
  BULK_DELETE_METRIC_ROADMAP_LIGHTBOX,
  BULK_DELETE_METRIC_ROADMAP_LIGHTBOX_FULFILLED,
  CREATE_METRIC_COMMENT_FULFILLED,
  DELETE_METRIC_COMMENT_FULFILLED,
  UPDATE_METRIC_COMMENT_FULFILLED,
  FETCH_METRIC_NEXT_COMMENTS_FULFILLED,
  FETCH_METRIC_NEXT_COMMENTS,
  CREATE_METRIC_COMMENT,
  DELETE_METRIC_COMMENT,
  UPDATE_METRIC_COMMENT,
} from './types';
import reduceReducers from 'reduce-reducers';

import { commentsAdapter } from './helpers/adapters';

import linkedPortfolioItemsReducer, {
  operationsReducer as linkedPortfolioItemsOperationsReducer,
  initialState as linkedPortfolioItemsInitialState,
} from '../LinkedPortfolioItems/store/reducer';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  OPEN_METRIC_LIGHTBOX,
  FETCH_METRIC_BY_ID_LIGHTBOX,
  CREATE_METRIC_VALUE_LIGHTBOX,
  UPDATE_METRIC_VALUE_LIGHTBOX,
  DELETE_METRIC_VALUE_LIGHTBOX,
  UPDATE_METRIC_BY_ID_LIGHTBOX,
  CREATE_METRIC_LIGHTBOX,
  CREATE_METRIC_ROADMAP_LIGHTBOX,
  DELETE_METRIC_ROADMAP_LIGHTBOX,
  BULK_DELETE_METRIC_ROADMAP_LIGHTBOX,
  FETCH_METRIC_NEXT_COMMENTS,
  CREATE_METRIC_COMMENT,
  DELETE_METRIC_COMMENT,
  UPDATE_METRIC_COMMENT,
]);

const initialState = {
  metric: {},
  comments: commentsAdapter.getInitialState(),
  commentsMetadata: {},
  ...thunksInitialState,
  ...linkedPortfolioItemsInitialState,
  operations: {
    ...linkedPortfolioItemsInitialState.operations,
    ...thunksInitialState?.operations,
  },
};

const removeUnsaved = list => {
  return list.filter(el => el.id);
};

const metricDialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_METRIC_BY_ID_LIGHTBOX_FULFILLED:
    case CREATE_METRIC_LIGHTBOX_FULFILLED:
    case FETCH_METRIC_BY_ID_LIGHTBOX_FULFILLED: {
      return {
        ...state,
        metric: action.payload || {},
      };
    }
    case OPEN_METRIC_LIGHTBOX_FULFILLED: {
      const { metric, comments } = action.payload || {};

      return {
        ...state,
        metric: metric || {},
        commentsMetadata: comments?._meta,
        comments: commentsAdapter.setAll(state?.comments, comments?.data),
      };
    }
    case ADD_METRIC_VALUE_WITHOUT_SAVE_LIGHTBOX:
    case CREATE_METRIC_VALUE_LIGHTBOX_FULFILLED: {
      const metric = state?.metric;

      const cleanedMetricValues = removeUnsaved(metric?.metricValues || []);

      const updatedMetricValues = [action.payload, ...cleanedMetricValues];

      return {
        ...state,
        metric: {
          ...state?.metric,
          metricValues: updatedMetricValues,
        },
      };
    }
    case UPDATE_METRIC_VALUE_LIGHTBOX_FULFILLED: {
      const metric = state?.metric;

      return {
        ...state,
        metric: {
          ...state?.metric,
          metricValues: metric?.metricValues?.map(metricValue => {
            if (metricValue?.id === action?.payload?.id) {
              return action?.payload;
            }
            return metricValue;
          }),
        },
      };
    }
    case DELETE_METRIC_VALUE_LIGHTBOX_FULFILLED: {
      const metric = state?.metric;

      return {
        ...state,
        metric: {
          ...state?.metric,
          metricValues: metric?.metricValues?.filter(metricValue => metricValue?.id !== action?.payload),
        },
      };
    }
    case REMOVE_UNSAVED_METRIC_VALUES_LIGHTBOX: {
      const metric = state?.metric;

      const cleanedMetricValues = removeUnsaved(metric?.metricValues || []);

      return {
        ...state,
        metric: {
          ...state?.metric,
          metricValues: cleanedMetricValues,
        },
      };
    }
    case CREATE_METRIC_ROADMAP_LIGHTBOX_FULFILLED: {
      const actionData = action.payload.data;

      return {
        ...state,
        metric: {
          ...state.metric,
          metric_roadmaps: [...(state.metric.metric_roadmaps || []), actionData],
          updated_at: action.payload.updated_at,
        },
      };
    }
    case DELETE_METRIC_ROADMAP_LIGHTBOX_FULFILLED: {
      return {
        ...state,
        metric: {
          ...state.metric,
          metric_roadmaps: action?.payload?.data || [],
        },
      };
    }
    case BULK_DELETE_METRIC_ROADMAP_LIGHTBOX_FULFILLED: {
      return {
        ...state,
        metric: {
          ...state.metric,
          metric_roadmaps: [],
        },
      };
    }
    case FETCH_METRIC_NEXT_COMMENTS_FULFILLED:
      const { data: nextCommentsBody } = action.payload;

      return {
        ...state,
        commentsMetadata: nextCommentsBody?._meta,
        comments: commentsAdapter.addMany(state?.comments, nextCommentsBody?.data),
      };
    case CREATE_METRIC_COMMENT_FULFILLED:
      const { data: newComment } = action.payload;

      return {
        ...state,
        comments: commentsAdapter.setOne(state?.comments, newComment),
      };
    case DELETE_METRIC_COMMENT_FULFILLED:
      const { commentId } = action.meta;

      return {
        ...state,
        comments: commentsAdapter.removeOne(state?.comments, commentId),
      };
    case UPDATE_METRIC_COMMENT_FULFILLED:
      const { data: updatedComment } = action.payload;

      return {
        ...state,
        comments: commentsAdapter.upsertOne(state?.comments, updatedComment),
      };
    case RESET_DATA:
      return initialState;
    default: {
      return state;
    }
  }
};

export { initialState };

const operationsReducer = reduceReducers(initialState.operations, ...thunksReducers, ...linkedPortfolioItemsOperationsReducer);

const reducer = reduceReducers(initialState, metricDialogReducer, linkedPortfolioItemsReducer, operationsReducer);

export default reducer;
