import {
  SUMMARY_PAGE,
  PORTFOLIO_CANVAS_PAGE,
  SNAPSHOT_PAGE,
  FORECAST_PAGE,
  SCENARIO_CHART_PAGE,
  FORECAST_CHART_PAGE,
  IDEAS_OVERVIEW_PAGE,
  PORTFOLIO_LIFECYCLES_PAGE,
  PORTFOLIO_PERSONAS_PAGE,
  REQUESTS_PERSONAS,
  REQUESTS_LIFECYCLES,
  MISSION_CONTROL_HOME_PAGE,
  GOALS_CANVAS_PAGE,
  METRICS_CANVAS_PAGE,
  FORECAST_RESOURCES_PAGE,
  HOME_PAGE,
  IDEAS_PDLC_PAGE,
  WELCOME_PAGE,
  REQUESTS_ANALYZE,
  IDEAS_GRID_PAGE,
  IDEAS_BOARD_PAGE,
  ROADMAP_PAGE,
  MANAGE_PAGE,
  DASHBOARD_HOME_PAGE,
  ALLOCATION_REPORT_PAGE,
  DASHBOARDS_PAGE,
  METRICS_ALLOCATION_PAGE,
  METRICS_CHART_PAGE,
  PORTFOLIO_REPORT_PAGE,
  PROGRESS_PAGE,
  TEMPLATES_PAGE,
  WELCOME_SELECT_TEMPLATE_PAGE,
  USER_DASHBOARDS_PAGE,
  DASHBOARD_EXPLORE_PAGE,
  METRICS_GRID_PAGE,
  GOALS_SUMMARY_PAGE,
  GOALS_SNAPSHOT_PAGE,
  GOALS_GRID_PAGE,
  IDEAS_ESTIMATES_PAGE,
} from 'constants/filters';

export const PERMISSION_FEATURES = {
  inviteUsers: 'feature.inviteUsers',
  childOrganizationReaders: 'feature.childOrganizationReaders',
  rollupDates: 'feature.rollupDates',
  crossBabyRoadmapSupport: 'feature.crossBabyRoadmapSupport',
  userViewDialogTabs: 'feature.userViewDialogTabs',
  projectProgressReport: 'feature.projectProgressReport',
  controlsBar: 'feature.controlsBar',
  headlessShare: 'feature.headlessShare',
  personas: 'feature.personas',
  lifecycles: 'feature.lifecycles',
  updateOrgFlagPersonas: 'feature.updateOrgFlagPersonas',
  updateOrgFlagLifecycles: 'feature.updateOrgFlagLifecycles',
  importRequestsWithExternalKey: 'feature.importRequestsWithExternalKey',
  importProjectsWithExternalKey: 'feature.importProjectsWithExternalKey',
  missionControlBookADemo: 'feature.missionControlBookADemo',
  missionControlRequests: 'feature.missionControlRequests',
  missionControlMetrics: 'feature.missionControlMetrics',
  missionControlOkrs: 'feature.missionControlOkrs',
  outcomeModule: 'feature.outcomeModule',
  metricLevels: 'feature.metricLevels',
  metricLightboxDetails: 'feature.metricLightboxDetails',
  metadataRoadmaps: 'feature.metadataRoadmaps',
  customersCustomFieldsCustomizer: 'feature.customersCustomFieldsCustomizer',
  metricSummary: 'feature.metricSummary',
  roadmapHistory: 'feature.roadmapHistory',
  requestDetailsFieldsLayout: 'feature.requestDetailsFieldsLayout',
  projectMetrics: 'feature.projectMetrics',
  aiReleaseNotes: 'feature.aiReleaseNotes',
  aiAnswerBot: 'feature.aiAnswerBot',
  metricMoar: 'feature.metricMoar',
  requestsListBulkUpdate: 'feature.requestsListBulkUpdate',
  requestsMerge: 'feature.requestsMerge',
  requestsBulkDelete: 'feature.requestsBulkDelete',
  metricProjectsLightbox: 'metricProjectsLightbox',
  goalProjectsLightbox: 'goalProjectsLightbox',
  completedAllocation: 'feature.completedAllocation',
  summaryLazyLoading: 'feature.summaryLazyLoading',
  actualAllocationByTime: 'feature.actualAllocationByTime',
  defaultsForAutoHealth: 'feature.defaultsForAutoHealth',
  newRoadmapMetadataAutocomplete: 'feature.newRoadmapMetadataAutocomplete',
  newRoadmapMetadataAutocompleteOnGrids: 'feature.newRoadmapMetadataAutocompleteOnGrids',
  newRoadmapMetadataAutocompleteOnMetricsForm: 'feature.newRoadmapMetadataAutocompleteOnMetricsForm',
  exportMetricOkrsFromGrid: 'feature.exportMetricOkrsFromGrid',
  usePointsToEstimate: 'feature.usePointsToEstimate',
  projectEstimateByPoints: 'feature.projectEstimateByPoints',
  pdlcGovernance: 'feature.pdlcGovernance',
  userViewRecurringNotifications: 'feature.userViewRecurringNotifications',
  scenariosModule: 'feature.scenariosModule',
  compareScenariosModule: 'feature.compareScenariosModule',
  portfolioModule: 'feature.portfolioModule',
  forecastModule: 'feature.forecastModule',
  settingsModule: 'feature.settingsModule',
  pdlcModule: 'feature.pdlcModule',
  requestsModule: 'feature.requestsModule',
  metricsModule: 'feature.metricsModule',
  dashboardModule: 'feature.dashboardModule',
  userDashbboard: 'feature.userDashbboard',
  pageHeaderFilters: 'feature.pageHeaderFilters',
  pageHeaderOptions: 'feature.pageHeaderOptions',
  userViewCounts: 'feature.userViewCounts',
  ideasGridTreeView: 'feature.ideasGridTreeView',
  customerRequestsDrawer: 'feature.customerRequestsDrawer',
  dbql: 'feature.dbql',
  customFieldAppliesTo: 'feature.customFieldAppliesTo',
  customFieldColor: 'feature.customFieldColor',
};

export const PERMISSION_PAGES = {
  [SUMMARY_PAGE]: `page.${SUMMARY_PAGE}`,
  [PORTFOLIO_CANVAS_PAGE]: `page.${PORTFOLIO_CANVAS_PAGE}`,
  [SNAPSHOT_PAGE]: `page.${SNAPSHOT_PAGE}`,
  [FORECAST_PAGE]: `page.${FORECAST_PAGE}`,
  [SCENARIO_CHART_PAGE]: `page.${SCENARIO_CHART_PAGE}`,
  [FORECAST_CHART_PAGE]: `page.${FORECAST_CHART_PAGE}`,
  settingsRoadmapsPage: `page.settingsRoadmapsPage`,
  settingsOkrsPage: `page.settingsOkrsPage`,
  [IDEAS_OVERVIEW_PAGE]: `page.${IDEAS_OVERVIEW_PAGE}`,
  [PORTFOLIO_LIFECYCLES_PAGE]: `page.${PORTFOLIO_LIFECYCLES_PAGE}`,
  [PORTFOLIO_PERSONAS_PAGE]: `page.${PORTFOLIO_PERSONAS_PAGE}`,
  [REQUESTS_PERSONAS]: `page.${REQUESTS_PERSONAS}`,
  [REQUESTS_LIFECYCLES]: `page.${REQUESTS_LIFECYCLES}`,
  [MISSION_CONTROL_HOME_PAGE]: `page.${MISSION_CONTROL_HOME_PAGE}`,
  [GOALS_CANVAS_PAGE]: `page.${GOALS_CANVAS_PAGE}`,
  [METRICS_CANVAS_PAGE]: `page.${METRICS_CANVAS_PAGE}`,
  [FORECAST_RESOURCES_PAGE]: `page.${FORECAST_RESOURCES_PAGE}`,
  [IDEAS_GRID_PAGE]: `page.${IDEAS_GRID_PAGE}`,
  [IDEAS_BOARD_PAGE]: `page.${IDEAS_BOARD_PAGE}`,
  [ROADMAP_PAGE]: `page.${ROADMAP_PAGE}`,
  [MANAGE_PAGE]: `page.${MANAGE_PAGE}`,
  [SUMMARY_PAGE]: `page.${SUMMARY_PAGE}`,
  [IDEAS_PDLC_PAGE]: `page.${IDEAS_PDLC_PAGE}`,
  [WELCOME_PAGE]: `page.${WELCOME_PAGE}`,
  [HOME_PAGE]: `page.${HOME_PAGE}`,
  [REQUESTS_ANALYZE]: `page.${REQUESTS_ANALYZE}`,
  [METRICS_ALLOCATION_PAGE]: `page.${METRICS_ALLOCATION_PAGE}`,
  [DASHBOARD_HOME_PAGE]: `page.${DASHBOARD_HOME_PAGE}`,
  [DASHBOARDS_PAGE]: `page.${DASHBOARDS_PAGE}`,
  [PORTFOLIO_REPORT_PAGE]: `page.${PORTFOLIO_REPORT_PAGE}`,
  [PROGRESS_PAGE]: `page.${PROGRESS_PAGE}`,
  [ALLOCATION_REPORT_PAGE]: `page.${ALLOCATION_REPORT_PAGE}`,
  [TEMPLATES_PAGE]: `page.${TEMPLATES_PAGE}`,
  [METRICS_CHART_PAGE]: `page.${METRICS_CHART_PAGE}`,
  [WELCOME_SELECT_TEMPLATE_PAGE]: `page.${WELCOME_SELECT_TEMPLATE_PAGE}`,
  [USER_DASHBOARDS_PAGE]: `page.${USER_DASHBOARDS_PAGE}`,
  [DASHBOARD_EXPLORE_PAGE]: `page.${DASHBOARD_EXPLORE_PAGE}`,
  [METRICS_GRID_PAGE]: `page.${METRICS_GRID_PAGE}`,
  [GOALS_SUMMARY_PAGE]: `page.${GOALS_SUMMARY_PAGE}`,
  [GOALS_SNAPSHOT_PAGE]: `page.${GOALS_SNAPSHOT_PAGE}`,
  [GOALS_GRID_PAGE]: `page.${GOALS_GRID_PAGE}`,
  [IDEAS_ESTIMATES_PAGE]: `page.${IDEAS_ESTIMATES_PAGE}`,
};
