import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';

import CancelContinueToast from 'components/CancelContinueToast';
import useHideableOptionMappings from 'hooks/useHideableOptions';
import useHiddenFields from 'hooks/useHiddenFields';
import {
  deletePortalLogoFile as deletePortalLogoFileAction,
  updatePortalSettings as updatePortalSettingsAction,
  uploadPortalLogoFile as uploadPortalLogoFileAction,
} from 'store/organization';
import { getPortalSettings } from 'store/organization/selectors';

export default Component => {
  return props => {
    const dispatch = useDispatch();

    const [hideableOptions] = useHideableOptionMappings();
    const portalSettings = useSelector(getPortalSettings);
    const { hiddenFields } = useHiddenFields();

    const settingsState = useMemo(
      () => ({
        allow: portalSettings?.allow || [],
        deny: portalSettings?.deny || [],
        listType: portalSettings?.listType || 'allow',
        hiddenFields: portalSettings?.hiddenFields || [],
        logo: portalSettings?.logo || null,
      }),
      [portalSettings],
    );

    const uploadLogo = async file => {
      const upload = async () => {
        if (file.size / 1024 / 1024 > 5) {
          toast('Exceeded file size of 5MB');
          return;
        }

        dispatch(uploadPortalLogoFileAction(file));
      };

      if (portalSettings?.logo?.length > 0) {
        toast(
          <CancelContinueToast
            title="You may attach 1 file (up to 5MB).
              If you upload a new file, you’ll lose access to the currently attached file."
            handleContinue={upload}
            handleCancel={() => toast.dismiss()}
          />,
          {
            autoClose: 10000,
          },
        );
      } else {
        upload();
      }
    };

    const removeFileFromPortalSettings = () => dispatch(deletePortalLogoFileAction());

    const updateHiddenField = newHiddenFields => {
      dispatch(
        updatePortalSettingsAction({
          ...settingsState,
          hiddenFields: newHiddenFields,
        }),
      );
    };

    const getLogoLink = async () => {
      const response = await axios.get(`/api/customer-requests/portal/logo`);

      return response.data.url;
    };

    return (
      <Component
        {...props}
        // logo
        files={portalSettings?.logo}
        uploadLogo={uploadLogo}
        handleDeleteLink={removeFileFromPortalSettings}
        getLogoLink={getLogoLink}
        // hidden fields
        hideableOptions={hideableOptions}
        hiddenFields={hiddenFields}
        updateHiddenField={updateHiddenField}
        accessConfig={settingsState}
        updateConfig={config => dispatch(updatePortalSettingsAction(config))}
      />
    );
  };
};
