import styled from 'styled-components';

export default styled.div`
width: 24px;
height: 24px;
display: flex;
align-items: center;
justify-content: center;

span {
  background: #37a5da;
  border-radius: 50%;
  display: block;
  width: 22px;
  height: 22px;

  ${({ size }) => size === 'small' && `
    width: 12px;
    height: 12px;
  `}
  ${({ size }) => size === 'medium' && `
    width: 16px;
    height: 16px;
  `}
  ${({ size }) => size === 'big' && `
    width: 24px;
    height: 24px;
  `}
}
`;
