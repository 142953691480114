import updateRows from 'store/utils/updateRows';

const addObjectiveToParentCorp = (objectives, child, parentId) => {
  let result = [...objectives];

  const parent = objectives.find(obj => obj.id === parentId);

  if (parent) {
    const currentChildren = parent.children || [];
    const alreadyOnList = currentChildren.some(c => c.id === child.id);

    if (!alreadyOnList) {
      parent.children = [child, ...currentChildren];

      result = updateRows(objectives, parent);
    }
  }

  return result;
};

export default addObjectiveToParentCorp;
