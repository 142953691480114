import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { BILLING_STATUSES } from 'constants/organizations';
import {
  getIdeasIntegrations,
  getOrganizationSystemFieldsNames,
  getOrgBillingStatus,
  getOrgHasBet,
  getOrgHasMetadataRoadmaps,
  getOrgJiraIntegrations,
  hasMultiLevelPortfolioMetadata,
  hasRenameJiraProjectEnabled,
  selectHasHierarchy,
  selectHasKeyResults,
  selectHasKeyResults2,
  selectHasProducts,
  selectHasProducts2,
  getOrganizationHasForecastByHeadcount,
  getOrgHasExternalApiKeysEnabled,
  getOrganization,
  getOrgCustomUserProjectFields,
  getHasProjectMetrics,
  getOrgHasTeamsLevelTwo,
  getOrgHasTargetedOnboarding,
  getIsOnboardingDemo,
} from 'store/organization';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';

const useOrganizations = () => {
  const organization = useSelector(getOrganization);
  const hasBet = useSelector(getOrgHasBet);
  const billingStatus = useSelector(getOrgBillingStatus);
  const integrations = useSelector(getIdeasIntegrations);
  const systemFields = useSelector(getOrganizationSystemFieldsNames);
  const hasProducts = useSelector(selectHasProducts);
  const hasProducts2 = useSelector(selectHasProducts2);
  const hasKeyResults = useSelector(selectHasKeyResults);
  const hasKeyResults2 = useSelector(selectHasKeyResults2);
  const jiraIntegrations = useSelector(getOrgJiraIntegrations);
  const hasRenameJiraProject = useSelector(hasRenameJiraProjectEnabled);
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);
  const hasHierarchy = useSelector(selectHasHierarchy);
  const hasMultiLevelMetadata = useSelector(hasMultiLevelPortfolioMetadata);
  const hasForecastByHeadcount = useSelector(getOrganizationHasForecastByHeadcount);
  const hasExternalApiKeys = useSelector(getOrgHasExternalApiKeysEnabled);
  const customUserFields = useSelector(getOrgCustomUserProjectFields);
  const hasProjectMetrics = useSelector(getHasProjectMetrics);
  const customFields = useSelector(state => getProjectsCustomFields(state, false));
  const hasMetadataMultiSelect = useFeatureFlags([FeatureFlags.HAS_METADATA_MULTI_SELECT]);
  const hasTeams2 = useSelector(getOrgHasTeamsLevelTwo);
  const hasTargetedOnboarding = useSelector(getOrgHasTargetedOnboarding);
  const isOnboardingDemo = useSelector(getIsOnboardingDemo);

  const isTrial = useMemo(
    () => [BILLING_STATUSES.SELFSERVE_TRIAL, BILLING_STATUSES.TRIAL].includes(billingStatus),
    [billingStatus],
  );

  const topLayer = useMemo(() => (hasBet ? BET_LAYER : INITIATIVE_LAYER), [hasBet]);

  const availableLayers = useMemo(
    () => ({
      IDEA_LAYER,
      INITIATIVE_LAYER,
      ...(hasBet ? { BET_LAYER } : {}),
    }),
    [hasBet],
  );

  return {
    organization,
    hasBet,
    integrations,
    systemFields,
    hasProducts,
    hasProducts2,
    hasKeyResults,
    hasKeyResults2,
    billingStatus,
    isTrial,
    filters: {
      topLayer,
      availableLayers,
    },
    jiraIntegrations,
    hasRenameJiraProject,
    hasMetadataRoadmaps,
    hasHierarchy,
    hasMultiLevelMetadata,
    hasForecastByHeadcount,
    hasExternalApiKeys,
    hasProjectMetrics,
    hasTeams2,
    customUserFields,
    customFields,
    hasMetadataMultiSelect,
    hasTargetedOnboarding,
    isOnboardingDemo,
  };
};

export default useOrganizations;
