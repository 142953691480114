import { defaultTo, equals, pipe, prop } from 'ramda';

import { IDEA_LAYER } from 'store/projects/constants';

export const DEFAULT_MAPPING_KEY = 'DEFAULT';

const FIELD_MAPPING = 'field_mapping';
const MAPPING = 'mapping';
const PROJECTS_ENTITY = 'PROJECTS';

const defaultToEmptyArray = defaultTo([]);

const getIntegrationFieldMapping = pipe(prop(FIELD_MAPPING), defaultToEmptyArray);
const getMapping = pipe(prop(MAPPING), defaultToEmptyArray);

const isEqualsProjectsEntity = equals(PROJECTS_ENTITY);

const findProjectsMapping = (mappingList, mappingKey, projectLayer) => {
  return mappingList.find(
    ({ entity, project, layer }) => isEqualsProjectsEntity(entity) && equals(project, mappingKey) && equals(layer, projectLayer),
  );
};

/**
 * @function getMappingByJiraTicketId
 *
 * Gets mapping for a given Jira ticket id
 *
 * check -> server/utils/mapping.js
 *
 * @param {Object} integrationData
 * @param {String} ticketId
 * @param {String} layer
 * @returns {Array}
 */
export const getMappingByJiraTicketId = (integrationData = {}, ticketId = '', layer = IDEA_LAYER) => {
  const [projectKey] = ticketId.split('-');
  const integrationFieldMapping = getIntegrationFieldMapping(integrationData);

  const selectedMapping = findProjectsMapping(integrationFieldMapping, projectKey, layer);

  if (selectedMapping) {
    return getMapping(selectedMapping);
  }

  const fallbackMappingOfSameProject = findProjectsMapping(integrationFieldMapping, projectKey, IDEA_LAYER);

  if (fallbackMappingOfSameProject) {
    return getMapping(fallbackMappingOfSameProject);
  }

  const fallbackMappingOfSameLayer = findProjectsMapping(integrationFieldMapping, DEFAULT_MAPPING_KEY, layer);

  if (fallbackMappingOfSameLayer) {
    return getMapping(fallbackMappingOfSameLayer);
  }

  const fallbackMapping = findProjectsMapping(integrationFieldMapping, DEFAULT_MAPPING_KEY, IDEA_LAYER);

  if (fallbackMapping) {
    return getMapping(fallbackMapping);
  }

  return [];
};

/**
 * @function hasIntegrationProjectsMapping
 *
 * Allow to check if there is any field mapping configured
 *
 * @param {Object} integrationData
 * @returns {Boolean}
 */
export const hasIntegrationProjectsMapping = (integrationData = {}) => {
  const integrationFieldMapping = getIntegrationFieldMapping(integrationData);

  return integrationFieldMapping.some(({ entity }) => isEqualsProjectsEntity(entity));
};

/**
 * @function hasOutboundMappingByField
 *
 * Checks if the provided field mapping contains outbound mapping for a given Dragonboat field.
 *
 * @param {Array} fieldMapping
 * @param {String} fieldName
 * @returns {Boolean}
 */
export const hasOutboundMappingByField = (fieldMapping, fieldName) => {
  return fieldMapping.some(mappingEntry => mappingEntry.jiraAutoSync && equals(mappingEntry.field, fieldName));
};
