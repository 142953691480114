import React from 'react';
import styled from 'styled-components';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import PageLink from 'design-system/atoms/PageLink/index';

import { materialColors } from 'design-system/themes/default';

const ListItem = styled.li`
  width: 100%;

  :not(:first-child)::before {
    width: 95%;
    display: block;
    border-bottom: 2px solid #3333331a;
    content: '';
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 10px;
`;

const Details = styled.div`
  padding-top: 10px;
  max-height: 105px;
  overflow: hidden;
  position: relative;
  font-size: ${props => props.theme.typography.fontSize}px;
  font-weight: ${props => props.theme.typography.fontWeightRegular};
  color: ${props => props.theme.palette.text.lightGrey};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
  min-width: 100%;
  max-width: 548px;

  &:after {
    content: '...';
    position: absolute;
    left: 0px;
    top: 85px;
    width: 100%;
    background: #fff;
    color: ${({ theme }) => theme.palette.text.lightGrey};
    font-weight: bold;
    font-size: 12px;
    height: 18px;
  }
`;

const Footer = styled.div`
  padding-top: 10px;
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding-right: 10px;
`;

export default props => {
  const _onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    props.onClickTitle();
  };

  return (
    <ListItem>
      <Wrapper>
        <MainContent>
          <PageLink color={materialColors.darkerGray} href="" onClick={_onClick}>
            {props.title}
          </PageLink>
          <Details>
            <TextDeprecated breakwords>{props.details || ''}</TextDeprecated>
          </Details>
          <Footer>{props.children}</Footer>
        </MainContent>
        {props.rightSideComponent && <props.rightSideComponent {...props} />}
      </Wrapper>
    </ListItem>
  );
};
