/* eslint-disable */
/**
 * UnfilterIcon Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function jiraIcon(props) {
  return (
    <SvgIcon {...props} style={{ ...props.style }} width="100%" height="100%" viewBox="0 0 39.75 53.25">
      <g transform="translate(-357.507 -202.524)">
        <path
          class="a"
          d="M367.632,222.4a9.75,9.75,0,1,0,9.75-9.75A9.776,9.776,0,0,0,367.632,222.4Zm9.75-7.125a7.125,7.125,0,0,1,0,14.25h0a7.125,7.125,0,0,1,0-14.25Z"
        />
        <path
          class="a"
          d="M386.222,244.709c9.134-13.013,11.035-15.72,11.035-22.31a19.875,19.875,0,0,0-39.75,0c0,6.591,1.9,9.3,11.041,22.319,1.994,2.84,4.329,6.167,7.062,10.128a2.158,2.158,0,0,0,3.545,0C381.889,250.881,384.227,247.551,386.222,244.709Zm-8.531,7.638-.309.446-.308-.446c-2.544-3.679-4.713-6.771-6.56-9.4-8.8-12.542-10.382-14.8-10.382-20.543a17.252,17.252,0,0,1,17.206-17.25h.091a17.25,17.25,0,0,1,17.2,17.251c0,5.74-1.585,8-10.382,20.542C382.4,245.576,380.235,248.668,377.691,252.347Z"
        />
      </g>
    </SvgIcon>
  );
}
