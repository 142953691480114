import { useState } from 'react';

const useDelete = onDelete => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const showDeleteConfirmation = () => {
    setShowDeleteDialog(true);
  };

  const onDeleteConfirmed = value => {
    setShowDeleteDialog(false);
    onDelete();
  };

  const onDeleteCanceled = value => {
    setShowDeleteDialog(false);
  };

  return {
    shouldShowDeleteDialog: showDeleteDialog,
    showDeleteConfirmation,
    onDeleteConfirmed,
    onDeleteCanceled,
  };
};

export default useDelete;
