import React, { useMemo } from 'react';
import styled from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Card from 'design-system/molecules/Card/index';
import WidgetGrid from 'design-system/organisms/WidgetGrid/index';

import Roadmap from 'design-system/atoms/DragonIcons/Roadmap';
import { nameColumnDef, numericColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import CenteredLoading from 'features/PortfolioOverview/components/CenteredLoading';
import MetricChangeValuesPopover from 'containers/MetricsForm/components/MetricChangeValuesPopover/MetricChangeValuesPopover';
import MetricNameCellRenderer from './MetricNameCellRenderer';
import buildToolbarActions from '../buildToolbarActions';
import usePortfolioOverviewMetrics from './usePortfolioOverviewMetrics';
import useUpdateGridWidgetHeight from '../useUpdateGridWidgetHeight';
import { getWidgetHeight } from '../helpers';
import { GRID_MAX_ROWS } from '../../constants/widgetsConfig';

const metricActualValueColumnDef = {
  ...numericColumnDef,
  field: 'actual_value',
  headerName: 'Actual Value',
  headerClass: 'ag-header-cell-text-centered',
};

const MetricsList = ({ id, isVisible, onVisibilityChange, userCanEdit, disableEdit, updateHeightOnLayout }) => {
  const {
    data,
    dataLength,
    isLoading,
    title,
    metricForPopover,
    popoverAnchorEl,
    canUpdateMetric,
    canGoToSettings,
    openMetricView,
    openProjectsListLightbox,
    goToMetricsSettings,
    getRowId,
    openMetricPopover,
    setPopoverAnchorEl,
  } = usePortfolioOverviewMetrics();

  const hasPagination = dataLength > GRID_MAX_ROWS;

  const { updateWidgetHeight, gridHeight } = useUpdateGridWidgetHeight({
    widgetId: id,
    getWidgetHeight,
    updateHeightOnLayout,
    hasPagination,
    maxItems: GRID_MAX_ROWS,
  });

  const metricNameColumnDef = useMemo(() => {
    return {
      ...nameColumnDef,
      cellRenderer: MetricNameCellRenderer,
      suppressMenu: true,
      actions: [
        {
          icon: <OpenInNewIcon />,
          onClick: openMetricView,
        },
        {
          icon: <Roadmap />,
          onClick: openProjectsListLightbox,
        },
        {
          icon: <EditIcon />,
          onClick: openMetricPopover,
        },
      ],
      cellClass: 'align-center',
      flex: 0.7,
    };
  }, [openMetricView, openProjectsListLightbox]);

  const buildActions = () => {
    const showVisibilityAction = userCanEdit && !disableEdit;
    const visibilityAction = showVisibilityAction ? { value: isVisible, onChange: onVisibilityChange } : null;

    const settingsAction = canGoToSettings ? { onClick: goToMetricsSettings } : null;

    return buildToolbarActions({
      visibility: visibilityAction,
      settings: settingsAction,
    });
  };

  return (
    <StyledCard title={title} actions={buildActions()}>
      {isLoading ? (
        <CenteredLoading />
      ) : (
        <>
          <MetricChangeValuesPopover
            anchorEl={popoverAnchorEl}
            setAnchorEl={el => setPopoverAnchorEl(el)}
            metric={metricForPopover}
            canAddOrEdit={canUpdateMetric}
          />

          <WidgetGrid
            data={data}
            hasPagination={hasPagination}
            gridHeight={gridHeight}
            getRowId={getRowId}
            columnDefs={[metricNameColumnDef, metricActualValueColumnDef]}
            pageSize={GRID_MAX_ROWS}
            numberOfRecords={dataLength}
            setVisibleItems={i => updateWidgetHeight(i)}
          />
        </>
      )}
    </StyledCard>
  );
};

export default MetricsList;

const StyledCard = styled(Card)`
  height: 100%;
  overflow: hidden;
`;
