import axios from 'axios';

import { FETCH_PROJECT_HISTORY } from './types';

export const fetchProjectHistory = (projectId, params = {}) => {
  return {
    payload: axios.get(`/api/projects/${projectId}/history`, { params }),
    type: FETCH_PROJECT_HISTORY,
  };
};

export const historyActions = {
  fetchProjectHistory,
};
