export default (component, select, unselect) => {
  return (e) => {
    component.selectedRows = component.selectedRows || [];
    component.selectedRows.push(e.node.data);
    component.selecting && clearTimeout(component.selecting); // eslint-disable-line
    component.selecting = setTimeout(() => {
      if (e.node && (e.node.selected || e.node.selected === undefined)) {
        select(component.selectedRows);
      } else {
        unselect(component.selectedRows);
      }
      component.selectedRows = [];
    }, 0);
  };
};
