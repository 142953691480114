import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import compact from 'lodash/compact';

import {
  getBaseCompareVersionSelected,
  getHideRowsWithSameData,
  getSelectedRoadmapVersionsIdsToCompare,
} from 'store/roadmapVersions/selectors';
import { getPageFilters } from 'store/filters/selectors/getPageFilters';

import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useSwimlaneMetadata from 'hooks/swimlane/useSwimlaneMetadata';
import useCompareScenariosSwimlaneGroups from './useCompareScenariosSwimlaneGroups';

import { convertProjectGroupsToTimelineGroups } from 'routes/Swimlane/New/helpers';

import prepareDataForSwimlaneComparison from '../../../utils/prepareDataForSwimlaneComparison';
import useBaseProjectsForComparison from 'routes/RoadmapVersions/hooks/useBaseProjectsForComparison';

const useBaseProjectsForSwimlaneComparison = (timelineConfig, displayLayer, scenarioColors, oneClickRoadmapVersion) => {
  const { selectedGroupOptions } = timelineConfig;
  const baseCompareVersionSelected = useSelector(getBaseCompareVersionSelected);
  const hideRowsWithSameData = useSelector(getHideRowsWithSameData);
  const { roadmapVersionsProjects, allRoadmapVersions } = useRoadmapVersions();

  const selectedRoadmapVersionsIds = useSelector(getSelectedRoadmapVersionsIdsToCompare);

  const { projects } = useBaseProjectsForComparison(displayLayer);

  // Generate Timeline expected format data from projects
  // #########
  const { quickFilters } = useSelector(getPageFilters);

  // Get applicable metadata by roadmap based on the quick filters
  // Note: I believe that this logic is not right due to 2 reasons:
  // 1- The change of the roadmaps in the filter can also happens in the page filters;
  // 2 - The quick filters already filters the projects so the metadata should not be filtered (imo);
  const filteredMetadata = useSwimlaneMetadata(projects, quickFilters);

  // Grouping
  const projectGroups = useCompareScenariosSwimlaneGroups(filteredMetadata, timelineConfig, projects);

  // Convert data from project groups for the Timeline component structure
  const timelineData = useMemo(() => {
    const selectedGroupKeys = compact(selectedGroupOptions.map(option => option.key));

    // Convert project groups to timeline groups
    return convertProjectGroupsToTimelineGroups(projectGroups, selectedGroupKeys);
  }, [projectGroups, quickFilters, selectedGroupOptions]);

  const groupedByProjectData = useMemo(() => {
    const roadmapVersionsIds = oneClickRoadmapVersion ? [oneClickRoadmapVersion.id] : selectedRoadmapVersionsIds;

    return prepareDataForSwimlaneComparison(
      timelineData,
      roadmapVersionsIds,
      scenarioColors,
      roadmapVersionsProjects,
      allRoadmapVersions,
      baseCompareVersionSelected,
      hideRowsWithSameData,
    );
  }, [
    timelineData,
    selectedRoadmapVersionsIds,
    scenarioColors,
    roadmapVersionsProjects,
    allRoadmapVersions,
    baseCompareVersionSelected,
    oneClickRoadmapVersion,
    hideRowsWithSameData,
  ]);

  return { groupedByProjectData, handleGroupHeaderResize: timelineConfig.resizeGroupHeader };
};

export default useBaseProjectsForSwimlaneComparison;
