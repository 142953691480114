export const AiPlanner = {
  PortfolioItems: 'portfolioItem',
  Unit: 'unit',
};

export const OrderByMode = {
  Rank: 'rank',
  Benefit: 'business_value',
  Moar: 'planned_moar',
  Field: 'field',
  MaxBenefit: 'max_benefit',
};

export const MaxBenefitOptionsKeys = {
  SixMonths: '6_months',
  TwelveMonths: '12_months',
  EighteenMonths: '18_months',
};

export const MaxBenefitOptionsValues = {
  [MaxBenefitOptionsKeys.SixMonths]: 6,
  [MaxBenefitOptionsKeys.TwelveMonths]: 12,
  [MaxBenefitOptionsKeys.EighteenMonths]: 18,
};

export const SolverType = {
  DETERMINISTIC: 'deterministic',
  ADVANCED: 'advanced',
};
