import { List, Map } from 'immutable';

/**
 * @param {Array.Object|ImmutableList.Object} rows - list of elements to which it will add payload elements
 * @param {Array.Object} payload - list of elements to be added to rows
 * @param {Function} serialize - serialize every payload elements. It's a map function
 */
export default (rows, payload, serialize) => {
  if (!payload || !(payload instanceof Array)) {
    return rows;
  }

  let newRows = [];

  if (typeof serialize === 'function') {
    payload = payload.map(serialize);
  }

  const payloadIds = payload.map(({ id }) => id);

  if (List.isList(rows)) {
    newRows = rows.filter(row => row.get('id') && !payloadIds.includes(row.get('id')));

    payload.forEach(row => {
      newRows = newRows.unshift(new Map(row));
    });
  } else if (rows instanceof Array) {
    newRows = rows
      .filter(row => row.id && !payloadIds.includes(row.id)) // remove unsaved rows
      .slice(0);

    payload.forEach(row => newRows.unshift(row));
  }


  return newRows;
};
