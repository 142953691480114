/* eslint-disable camelcase */
// External dependencies
import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

// Dragonboat dependencies
import Dialog from 'design-system/molecules/Dialog/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import {
  discardRoadmapVersionUpdatedProjects,
  unsetSelectedScenario,
  updateRoadmapVersionDialogProperties,
} from 'store/roadmapVersions';
import { useDispatch, useSelector } from 'react-redux';
import { getRoadmapVersionDialogProperties } from 'store/roadmapVersions/selectors';
import { getPageIdFromPath } from 'utils/userViews';
import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useSystemFields from 'hooks/useSystemFields';
import {
  SCENARIO_AI_PLANNER_PAGE,
  SCENARIO_GANTT_PAGE,
  SCENARIO_LIST_PAGE,
  SCENARIO_SWIMLANE_PAGE,
  SCENARIO_SUMMARY_PAGE,
  SCENARIO_CHART_PAGE,
} from 'constants/filters';

const SCENARIO_PAGES = [
  SCENARIO_LIST_PAGE,
  SCENARIO_GANTT_PAGE,
  SCENARIO_SWIMLANE_PAGE,
  SCENARIO_SUMMARY_PAGE,
  SCENARIO_AI_PLANNER_PAGE,
  SCENARIO_CHART_PAGE,
];

const exitingScenarioModule = page => !SCENARIO_PAGES.includes(page);

const SaveScenarioChangesPromptDialog = ({ history, pageId }) => {
  const dispatch = useDispatch();

  const destroyRouteBlockListener = useRef(null);

  const { showSavePopup, nextRoute } = useSelector(state => getRoadmapVersionDialogProperties(state));

  const { hasAnyUpdateToBeSaved, selectedRoadmapVersion, compareVersionEditScenario } = useRoadmapVersions();

  const [getSystemFieldName] = useSystemFields();

  // create listener to check if prompt popup should be displayed on path changed
  useEffect(() => {
    destroyRouteBlockListener.current && destroyRouteBlockListener.current();

    destroyRouteBlockListener.current = history.block(location => {
      const pageIdFromPath = getPageIdFromPath(location.pathname.replace('/', ''));

      const needsToBlockRouteChange = (selectedRoadmapVersion || compareVersionEditScenario) && hasAnyUpdateToBeSaved;

      if (exitingScenarioModule(pageIdFromPath)) {
        if (needsToBlockRouteChange) {
          dispatch(
            updateRoadmapVersionDialogProperties({
              showSavePopup: true,
              nextRoute: location.pathname,
            }),
          );
          return false;
        }

        if (selectedRoadmapVersion || compareVersionEditScenario) {
          // We are moving away from the original page, force the selected roadmap version to be null
          dispatch(unsetSelectedScenario(null));
        }
      }
    });

    return () => destroyRouteBlockListener.current();
  }, [history, pageId, selectedRoadmapVersion, hasAnyUpdateToBeSaved]);

  const navigateAfterDiscardOrSave = useCallback(() => {
    if (nextRoute) {
      destroyRouteBlockListener.current();
      history.push(nextRoute);
      dispatch(updateRoadmapVersionDialogProperties({ nextRoute: null }));
    }
  }, [nextRoute, destroyRouteBlockListener, history]);

  const onClose = () => {
    dispatch(updateRoadmapVersionDialogProperties({ showSavePopup: false }));
  };

  const onSave = () => {
    dispatch(updateRoadmapVersionDialogProperties({ showSaveDialog: true, showSavePopup: false }));
  };

  const onDiscardChanges = useCallback(() => {
    dispatch(discardRoadmapVersionUpdatedProjects());
    navigateAfterDiscardOrSave();
    dispatch(unsetSelectedScenario());
  }, [navigateAfterDiscardOrSave]);

  return (
    <Dialog
      id="save-filter-dialog"
      data-test="save-filter-dialog"
      open={showSavePopup}
      onClose={onClose}
      maxWidth="lg"
      scroll="paper"
      closeButton
      actions={
        <Fragment>
          <Button
            data-test="cancel-save-scenario"
            onClick={() => {
              onDiscardChanges();
              onClose();
            }}
          >
            Discard
          </Button>
          <Button
            data-test="save-scenario"
            onClick={() => {
              onSave();
            }}
            color="primary"
          >
            {`Save ${getSystemFieldName('scenario')}`}
          </Button>
        </Fragment>
      }
    >
      <DialogWrapper>
        <Grid container style={{ marginTop: 15 }}>
          <Grid item xs={9}>
            <TextDeprecated>{`Save current ${getSystemFieldName('scenario')} or discard changes?`}</TextDeprecated>
          </Grid>
        </Grid>
      </DialogWrapper>
    </Dialog>
  );
};

const DialogWrapper = styled.div`
  min-width: 600px;
`;

export default SaveScenarioChangesPromptDialog;
