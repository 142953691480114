import React from 'react';
import PropTypes from 'prop-types';

import PlanBasedOnFilterSelector from './PlanBasedOnFilterSelector';

const ForecastPlanBasedOn = ({ pageId }) => {
  return (
    <>
      <PlanBasedOnFilterSelector pageId={pageId} />
    </>
  );
};

ForecastPlanBasedOn.propTypes = {
  pageId: PropTypes.string,
};

export default ForecastPlanBasedOn;
