import React from 'react';
import styled from 'styled-components';

import RemirrorWYSIWYGEditor from 'design-system/molecules/RemirrorWYSIWYGEditor/index';

import { spacing } from 'design-system/theme';

const WYSIWYGEditorField = ({ width, height, smallInput = true, ...props }) => (
  <StyledRemirrorWYSIWYGEditor width={width} height={height} smallInput={smallInput} {...props} />
);

export default WYSIWYGEditorField;

const StyledRemirrorWYSIWYGEditor = styled(RemirrorWYSIWYGEditor)`
  && {
    width: ${({ width }) => width || '100%'};
    max-height: ${({ height }) => height || 'unset'};
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    border: ${({ theme }) => `1px solid ${theme.palette.border.primary}`};
    border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;
    padding: ${spacing(2)}px;
    overflow-y: ${({ height }) => (height ? 'auto' : 'unset')};

    .remirror-editor-wrapper {
      padding: 0;
    }

    .remirror-is-empty {
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
      color: ${({ theme }) => theme.palette.text.disabled};

      &:before {
        color: currentColor;
        font-style: normal;
      }
    }
  }
`;
