import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export default styled(props => {
  const Icon = props.icon;

  return (
    <IconButton {...props}>
      <Icon fontSize="small" />
    </IconButton>
  );
})`
  &&&& {
    padding: 3px;
    svg {
      font-size: ${({ theme }) => theme.typography.fontSizeSmall}px;
    }
  }
`;
