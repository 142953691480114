import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { not } from 'ramda';

import subscribeNewData from 'subscribeNewData';
import { ESTIMATES_GRID_VIEWS } from 'constants/ideas';
import { FORECAST_PAGE } from 'constants/filters';
import { ESTIMATES } from 'store/grids/constants';
import { getDisplayLayer } from 'store/filters/selectors';
import { openProjectLightbox } from 'store/projectLightbox';
import { getCurrentUser } from 'store/login/selectors';
import { getTimeframeOption } from 'store/projects/helpers/groupOptions';
import { getGridConfigValue } from 'store/grids/selectors';
import { saveGridConfig } from 'store/grids';
import { getGroupOptionsSelector } from 'store/projects/groupSelectors';

import useLoadProjectsForTransactionPages from 'hooks/projects/useLoadProjectsForTransactionPages';
import useBookADemoLocker from 'hooks/useBookADemoLocker';
import handleGroupsLinkClick from 'utils/okrs/handleGroupsLinkClick';

import useSystemFields from 'hooks/useSystemFields';
import { TEAMS } from 'constants/common';
import useLoadPageMetadata from 'hooks/useLoadPageMetadata';
import usePageFilters from 'hooks/filters/usePageFilters';
import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useScenarioToolbarControls from 'containers/ScenarioToolbarControls/hooks/useScenarioToolbarControls';
import useEnrichedSelectedGroupsFromState from 'hooks/grid/useGridSelectedGroups';
import useMetadataGroupByOptions from 'hooks/useMetadataGroupByOptions';

import useEstimatesListData from './hooks/useEstimatesListData';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const viewType = ESTIMATES;
const pageId = `ideasGrid:${viewType}`;

const UNSUPPORTED_GROUP_KEYS = ['priority', 'owner', 'status_color', 'phase', 'planningStage'];

const filterOutGroupRows = r => not(r.group);

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [grid, setGrid] = useState(null);
    const displayLayer = useSelector(getDisplayLayer);
    const showAllocationReport = useSelector(state => getGridConfigValue(state, viewType, 'showAllocationReport'));
    const { selectedGroup1, selectedGroup2 } = useEnrichedSelectedGroupsFromState(viewType);

    const currentUser = useSelector(getCurrentUser);
    const rowHeight = useSelector(state => getGridConfigValue(state, viewType, 'rowHeight'));
    const allocationReportState = useSelector(state => getGridConfigValue(state, viewType, 'allocationReportState', {}));
    const isSingleLayerGroupedBy = !!selectedGroup1?.key;
    const localSearchString = useSelector(state => getGridConfigValue(state, viewType, 'searchText'));

    const { searchString: globalSearchString } = usePageFilters();

    const groupByOptionsFromStore = useSelector(state => getGroupOptionsSelector(state, { withNullOption: true }));
    const { groupByOptions } = useMetadataGroupByOptions(groupByOptionsFromStore);

    const customGroup2Options = useMemo(() => {
      return groupByOptions.filter(group => !UNSUPPORTED_GROUP_KEYS.includes(group.key));
    }, [groupByOptions]);

    const searchString = localSearchString || globalSearchString;

    const updateAllocationReportState = state =>
      dispatch(saveGridConfig(viewType, 'allocationReportState', { ...allocationReportState, ...state }));

    /*
     * Is getting the most recent teams data from api
     *
     * Otherwise if admin user go to users page and change the team of other user
     * that change will not be reflected on the numStaff of each team.
     *
     * And on forecast list is required to have the most recent number of staff for each team
     */
    const loadPageMetadata = useLoadPageMetadata([TEAMS]);

    const { isRoadmapVersionsEnabled, isRoadmapVersionSelected, openCompareSelectedRoadmapVersion } = useRoadmapVersions();
    const { isCreatingOrViewingScenario } = useScenarioToolbarControls();

    const [getSystemFieldName] = useSystemFields();

    const [loadedProjects] = useLoadProjectsForTransactionPages(pageId);

    const { canView } = usePermissions();

    const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

    const data = useEstimatesListData({
      viewType,
      displayLayer,
      isSingleLayerGroupedBy,
      getSystemFieldName,
      selectedGroup1,
      selectedGroup2,
      pageId,
    });

    const _onGridReady = useCallback(params => setGrid({ gridApi: params.api, columnApi: params.columnApi }), []);

    const _getGridApi = useCallback(() => grid, [grid]);

    const _onCloseAllocationReport = () => dispatch(saveGridConfig(viewType, 'showAllocationReport', false));
    const _onProjectLinkClick = (id, tab) => dispatch(openProjectLightbox(id, tab));
    const _onGroupsLinkClick = data => handleGroupsLinkClick(data, dispatch);

    const BookADemoLockerComponent = useBookADemoLocker(FORECAST_PAGE, false, props.isDashboardModule);

    if (BookADemoLockerComponent) return <BookADemoLockerComponent />;

    useEffect(() => {
      if (!selectedGroup1) {
        dispatch(saveGridConfig(viewType, 'selectedGroup1', getTimeframeOption(getSystemFieldName)));
      }
      loadPageMetadata();
    }, []);

    const dataForAllocation = data.filter(filterOutGroupRows);

    return (
      <Component
        {...props}
        loaded={loadedProjects}
        data={data}
        viewType={viewType}
        pageId={pageId}
        availableViews={ESTIMATES_GRID_VIEWS}
        onGridReady={_onGridReady}
        getGridApi={_getGridApi}
        showAllocationReport={showAllocationReport}
        onCloseAllocationReport={_onCloseAllocationReport}
        searchString={searchString}
        currentUser={currentUser}
        singleLayerGroupedBy={isSingleLayerGroupedBy}
        getSystemFieldName={getSystemFieldName}
        onProjectLinkClick={_onProjectLinkClick}
        onGroupsLinkClick={_onGroupsLinkClick}
        rowHeight={rowHeight}
        showPageOptionsReadOnly={shouldDisplayControlsBar}
        allocationReportState={allocationReportState}
        updateAllocationReportState={updateAllocationReportState}
        isCreatingOrViewingScenario={isCreatingOrViewingScenario}
        isRoadmapVersionsEnabled={isRoadmapVersionsEnabled}
        isRoadmapVersionSelected={isRoadmapVersionSelected}
        compareVersionWithPlanOfRecord={openCompareSelectedRoadmapVersion}
        customGroupBy2Options={customGroup2Options}
        dataForAllocation={dataForAllocation}
      />
    );
  };
};

export default compose(subscribeNewData(['app', 'projects', 'tasks', 'estimates', 'customFields', 'timeframes']), componentHOC);
