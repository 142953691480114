/**
 * ErrorDialog Organism Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Dialog from 'design-system/molecules/Dialog/index';

import Button from '@material-ui/core/Button';

export default class ErrorDialog extends PureComponent {
  render() {
    const { onClose, message } = this.props;

    return (
      <Dialog
        open
        title="Warning"
        onClose={onClose}
        actions={
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        }
      >
        <Wrapper>{message}</Wrapper>
      </Dialog>
    );
  }
}

const Wrapper = styled.div``;
