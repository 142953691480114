import LoadableUsersMultiSelect from 'containers/LoadableMultiSelect/LoadableUsersMultiSelect';
import {
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  RESOURCE_TEAM,
} from 'constants/common';

export const FILTERS_FIELDS = {
  details: { type: 'text', label: 'Details' },
  title: { type: 'text', label: 'Title' },
  objectivesCorp: { type: 'option', labelKey: 'objectiveCorp', archivedOptions: true },
  objectives: { type: 'option', labelKey: 'objective', archivedOptions: true },
  [ADDITIONAL_OBJECTIVES]: {
    type: 'option',
    labelKey: ADDITIONAL_OBJECTIVES,
    systemKey: 'objective',
    archivedOptions: true,
  },
  keyResults: { type: 'option', labelKey: 'keyResult1', archivedOptions: true },
  [ADDITIONAL_KEY_RESULTS]: {
    type: 'option',
    labelKey: ADDITIONAL_KEY_RESULTS,
    systemKey: 'keyResult1',
    archivedOptions: true,
  },
  keyResults2: { type: 'option', labelKey: 'keyResult2', archivedOptions: true },
  [ADDITIONAL_KEY_RESULTS_2]: {
    type: 'option',
    labelKey: ADDITIONAL_KEY_RESULTS_2,
    systemKey: 'keyResult2',
    archivedOptions: true,
  },
  roadmapsCorp: { type: 'option', labelKey: 'roadmapCorp', archivedOptions: true },
  roadmaps: { type: 'option', labelKey: 'roadmap', archivedOptions: true },
  [ADDITIONAL_ROADMAPS]: { type: 'option', labelKey: ADDITIONAL_ROADMAPS, systemKey: 'roadmap', archivedOptions: true },
  products: { type: 'option', labelKey: 'product1', archivedOptions: true },
  products2: { type: 'option', labelKey: 'product2', archivedOptions: true },
  [ADDITIONAL_PRODUCTS]: {
    type: 'option',
    labelKey: ADDITIONAL_PRODUCTS,
    systemKey: 'product1',
    archivedOptions: true,
  },
  themes: { type: 'option', labelKey: 'theme', archivedOptions: true },
  [ADDITIONAL_THEMES]: { type: 'option', labelKey: ADDITIONAL_THEMES, systemKey: 'theme', archivedOptions: true },
  timeframes: { type: 'option', labelKey: 'timeframe', archivedOptions: true },
  [ADDITIONAL_TIMEFRAMES]: {
    type: 'option',
    labelKey: ADDITIONAL_TIMEFRAMES,
    systemKey: 'timeframe',
    archivedOptions: true,
  },
  timeframes2: { type: 'option', labelKey: 'timeframe2', archivedOptions: true },
  [ADDITIONAL_TIMEFRAMES_2]: {
    type: 'option',
    labelKey: ADDITIONAL_TIMEFRAMES_2,
    systemKey: 'timeframe2',
    archivedOptions: true,
  },
  planningStages: { type: 'option', label: 'Planning Stage', hideRemoveButton: true },
  phases: { type: 'option', labelKey: 'phase', archivedOptions: true },
  categories: { type: 'option', labelKey: 'category', archivedOptions: true },
  [ADDITIONAL_CATEGORIES]: {
    type: 'option',
    labelKey: ADDITIONAL_CATEGORIES,
    systemKey: 'category',
    archivedOptions: true,
  },
  priorities: { type: 'option', labelKey: 'priority' },
  users: { type: 'option', label: 'Owner', inputComponent: LoadableUsersMultiSelect, showUndefined: true },
  created_by: {
    type: 'option',
    label: 'Creator',
    metadata: 'users',
    inputComponent: LoadableUsersMultiSelect,
    showUndefined: true,
  },
  tags: { type: 'option', labelKey: 'tag', archivedOptions: true },
  customers: { type: 'option', labelKey: 'customer', archivedOptions: true },
  metrics: { type: 'option', labelKey: 'metric' },
  teams: { type: 'option', labelKey: 'team', archivedOptions: true },
  teams2: { type: 'option', labelKey: 'team2', archivedOptions: true },
  [ADDITIONAL_TEAMS]: { type: 'option', labelKey: ADDITIONAL_TEAMS, systemKey: 'team', archivedOptions: true },
  [ADDITIONAL_TEAMS_2]: { type: 'option', labelKey: ADDITIONAL_TEAMS_2, systemKey: 'team2', archivedOptions: true },
  skills: { type: 'option', labelKey: 'skill', archivedOptions: true },
  status_colors: { type: 'option', label: 'Health' },
  created_at: { type: 'date', label: 'Created' },
  updated_at: { type: 'date', label: 'Updated' },
  estimated_start_date: { type: 'date', label: 'Target Start Date' },
  deadline: { type: 'date', label: 'Target End Date' },
  business_value: { type: 'number', label: 'Benefit' },
  effort_score: { type: 'number', label: 'Effort' },
  integrationKey: { type: 'text', label: 'Integration Key' },
  resource_team_id: { type: 'option', labelKey: RESOURCE_TEAM, archivedOptions: true },
  user_1_id: {
    type: 'option',
    labelKey: 'user1',
    inputComponent: LoadableUsersMultiSelect,
    showUndefined: true,
  },
  user_2_id: {
    type: 'option',
    labelKey: 'user2',
    inputComponent: LoadableUsersMultiSelect,
    showUndefined: true,
  },
  user_3_id: {
    type: 'option',
    labelKey: 'user3',
    inputComponent: LoadableUsersMultiSelect,
    showUndefined: true,
  },
  user_4_id: {
    type: 'option',
    labelKey: 'user4',
    inputComponent: LoadableUsersMultiSelect,
    showUndefined: true,
  },
  user_5_id: {
    type: 'option',
    labelKey: 'user5',
    inputComponent: LoadableUsersMultiSelect,
    showUndefined: true,
  },
  user_6_id: {
    type: 'option',
    labelKey: 'user6',
    inputComponent: LoadableUsersMultiSelect,
    showUndefined: true,
  },
  user_7_id: {
    type: 'option',
    labelKey: 'user7',
    inputComponent: LoadableUsersMultiSelect,
    showUndefined: true,
  },
};
