import {
  UPDATE_MANAGE_STATE,
} from './types';

export const updateState = (page, state, makesActiveViewDirty = true) => {
  return {
    payload: { page, state },
    type: UPDATE_MANAGE_STATE,
    meta: { makesActiveViewDirty },
  };
};

export const manageActions = {
  updateState,
};
