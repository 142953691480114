import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import List from '@material-ui/core/List';

import ListItem from 'design-system/molecules/SecondaryNavListItem/index';

import { getTemplatesCategories, getActiveTemplateCategory, setActiveTemplateCategory } from 'store/templates';

export default () => {
  const dispatch = useDispatch();
  const categories = useSelector(getTemplatesCategories);

  const activeCategory = useSelector(getActiveTemplateCategory);

  const setActiveCategory = id => dispatch(setActiveTemplateCategory(id));

  return (
    <Wrapper>
      <List>
        <ListItem
          key="all"
          label="Show all"
          isActive={!activeCategory}
          onClick={() => setActiveCategory(null)}
          showArrow={false}
        />
        {categories &&
          categories.map(category => (
            <ListItem
              key={category.id}
              label={category.name}
              isActive={category.id === activeCategory}
              onClick={() => setActiveCategory(category.id)}
              showArrow={false}
            />
          ))}
      </List>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 230px;

  position: relative;
  border-right: 1px solid ${({ theme }) => theme.palette.newLayout.background.lightBlack};

  &&&& {
    span {
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      font-size: ${({ theme }) => theme.typography.fontSize}px;
      color: ${({ theme }) => theme.palette.newLayout.background.disable};
    }
  }
`;
