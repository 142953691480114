import { useState } from 'react';

import {
  ADMIN_USER,
  LEADER_USER,
  READ_ONLY_USER,
  REQUESTOR_USER,
  EDITOR_USER,
  MANAGER_USER,
  COLLABORATOR_USER,
} from '@dragonboat/permissions';

const exist = Boolean;

export default (fields, selectedItems, mandatoryGridColumns, fieldsToUpdate) => {
  const [updatedRows, setUpdatedRows] = useState(selectedItems);
  const [needToFillEmailRows, setNeedToFillEmailRows] = useState([]);
  const [gridColumns, setGridColumns] = useState(mandatoryGridColumns);

  const updateSelectedItems = newSelectedItems => {
    const itemsAux = newSelectedItems || selectedItems;

    const update = fieldsToUpdate.reduce((final, currentField) => {
      if (fields[currentField.field] && currentField.field.indexOf('.') >= 0) {
        const parts = currentField.field.split('.');

        final[parts[0]] = {};
        final[parts[0]][parts[1]] = currentField.value;
      } else if (fields[currentField.field]) {
        final[currentField.field] = currentField.value;
      }

      return final;
    }, {});

    const items = itemsAux.map(item => ({ ...item, ...update }));

    const hasRoleId = fieldsToUpdate.some(currentField => currentField.field === 'role_id');
    const needToHaveEmail = fieldsToUpdate.some(currentField =>
      [ADMIN_USER, MANAGER_USER, EDITOR_USER, LEADER_USER, READ_ONLY_USER, REQUESTOR_USER, COLLABORATOR_USER].includes(
        currentField.value,
      ),
    );

    // if we want to update for some role_id users must have email
    if (hasRoleId && needToHaveEmail) {
      const withEmailItems = items.filter(item => exist(item.email));
      const withoutEmailItems = items.filter(item => !exist(item.email));

      setUpdatedRows(withEmailItems);
      setNeedToFillEmailRows(withoutEmailItems);
    } else {
      setUpdatedRows(items);
    }
  };

  const updateGridColumns = () => {
    const updatedGridColumns = fieldsToUpdate
      .filter(updateField => {
        return fields[updateField.field];
      })
      .map(updateField => {
        return fields[updateField.field].gridColumn;
      });

    const gridColumns = [...mandatoryGridColumns];

    updatedGridColumns.forEach(element => (Array.isArray(element) ? gridColumns.push(...element) : gridColumns.push(element)));

    setGridColumns(gridColumns);
  };

  return {
    updatedRows,
    gridColumns,
    updateSelectedItems,
    updateGridColumns,
    needToFillEmailRows,
    setNeedToFillEmailRows,
  };
};
