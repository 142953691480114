import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateKeyResultById, updateObjectiveById } from 'store/objectives';
import { updateProductById, updateRoadmapById } from 'store/roadmaps';
import { updateThemeById } from 'store/themes';
import { updateCategoryById } from 'store/categories';
import { updatePhaseById } from 'store/phases';
import { updateTimeframeById } from 'store/timeframes';
import { updateProjectById } from 'store/projects';
import { metricActions } from 'store/metrics';
import { getHasAdvancedMetricReporting } from 'store/organization';

const useUpdateMetadataByFieldId = () => {
  const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);

  const dispatch = useDispatch();
  const updateMetadataByFieldId = useCallback(
    async (metricToUpdate, id, field, oldValue, cellData) => {
      if (!id || !metricToUpdate) return;

      const mapMetadataFieldToAction = {
        objectiveCorp: (id, update) => dispatch(updateObjectiveById(id, update)),
        objective: (id, update) => dispatch(updateObjectiveById(id, update)),
        keyResult1: (id, update) => dispatch(updateKeyResultById(id, update)),
        keyResult2: (id, update) => dispatch(updateKeyResultById(id, update)),
        roadmapCorp: (id, update) => dispatch(updateRoadmapById(id, update)),
        roadmap: (id, update) => dispatch(updateRoadmapById(id, update)),
        product1: (id, update) => dispatch(updateProductById(id, update)),
        product2: (id, update) => dispatch(updateProductById(id, update)),
        theme: (id, update) => dispatch(updateThemeById(id, update)),
        category: (id, update) => dispatch(updateCategoryById(id, update)),
        phase: (id, update) => dispatch(updatePhaseById(id, update)),
        timeframeCorp: (id, update) => dispatch(updateTimeframeById(id, update)),
        timeframe: (id, update) => dispatch(updateTimeframeById(id, update)),
        initiative: (id, update) => dispatch(updateProjectById(id, update)),
        bet: (id, update) => dispatch(updateProjectById(id, update)),
      };

      const updateAction = field.metric_current || field.metric_target ? undefined : mapMetadataFieldToAction[metricToUpdate];

      if (field.metric_current || field.metric_target) {
        const type = field.metric_current ? 'actual' : 'target';
        const metricId = cellData.data.metrics[0]?.id;
        const value = cellData.newValue;

        if (oldValue && !hasAdvancedMetricReporting) {
          const metricVal = cellData.data.metrics[0]?.metricValues?.find(val => val.value === oldValue && val.type === type);

          dispatch(metricActions.updateMetricValue(metricVal.id, { value: cellData.newValue }));
        } else {
          const newMetricVal = { metric_id: metricId, type, date: new Date(), value };

          dispatch(metricActions.addMetricValue(newMetricVal));
        }
      }

      if (updateAction) {
        await updateAction(id, field);
      }
    },
    [dispatch, hasAdvancedMetricReporting],
  );

  return { updateMetadataByFieldId };
};

export default useUpdateMetadataByFieldId;
