import { useEffect, useRef } from 'react';

let openTasks = [];

/**
 * Custom hook that set open tasks on the gantt to use before parse on the gantt
 *
 * @param {*} gantt the gantt instance
 * @returns
 */
const useCollapsedTasks = (gantt, updateState, lsState) => {
  const updateStateRef = useRef(updateState);

  useEffect(() => {
    updateStateRef.current = updateState;
  }, [updateState]);

  useEffect(() => {
    openTasks = lsState.openTasks || [];

    if (gantt) {
      gantt.attachEvent('onTaskOpened', id => {
        openTasks.push(String(id));

        updateStateRef.current({ openTasks });
        return true;
      });
      gantt.attachEvent('onTaskClosed', id => {
        openTasks.splice(
          openTasks.findIndex(i => i === String(id)),
          1,
        );
        updateStateRef.current({ openTasks });
        return true;
      });
    }
  }, [!!gantt]);

  const collapseExpandAll = allExpanded => {
    if (!gantt) return [];
    gantt.eachTask(task => {
      const children = gantt.getChildren(task.id);

      if (children.length) {
        if (allExpanded) {
          openTasks.push(String(task.id));
        } else {
          openTasks.splice(
            openTasks.findIndex(i => i === String(task.id)),
            1,
          );
        }
      }
    });
    updateState({ openTasks });

    return openTasks;
  };

  return { collapseExpandAll };
};

export default useCollapsedTasks;
