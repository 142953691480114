import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinkOffOutlined from '@material-ui/icons/LinkOffOutlined';

import useNumberOfTextRowsToDisplayOnGridCell from 'hooks/useNumberOfTextRowsToDisplayOnGridCell';
import { openProjectLightbox as openProjectLightboxAction } from 'store/projectLightbox';
import { addChildrenToProjectOnLightbox as addChildrenToProjectOnLightboxAction } from 'store/projects';
import { getProjectLayers } from 'store/filters/selectors';
import AgGridInput from 'design-system/atoms/AgGridInput';
import theme from 'design-system/theme';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { isRowDraggable, isNewRow } from 'design-system/molecules/AgGridReact-New/helpers';
import { ActionsCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { TitleCellInnerRenderer } from 'containers/IdeasList/IdeasList/New/hooks/useIdeasGridColumnsDefinitions/renderers';
import { useIdeasGridColumnsDefinitions, useSystemFields } from 'containers/IdeasList/IdeasList/New/hooks';
import LinkIdeaCellEditor from './LinkIdeaCellEditor';

const VISIBLE_COLUMNS = ['ownerName', 'timeframeTitle', 'timeframe2Title', 'progress', 'phaseTitle', 'integration'];

const addCustomWidthToColumns = (columns, isDrawerView) =>
  columns.map(col => ({
    ...col,
    width: isDrawerView ? 100 : 150,
  }));

const useProjectLightboxChildrenColumns = ({
  updateIdea,
  createChildProject,
  isDrawerView,
  integrations,
  hasHierarchy,
  hasBet,
  hasKeyResults,
  hasKeyResults2,
  hasProducts,
  hasProducts2,
  hasMultiLevelMetadata,
  hasTeams2,
  topLayer,
  availableLayers,
  hasMetadataMultiSelect,
  displayLayer,
  currentUser,
  portfolioMode,
  checkUserCanCreateNewMetadata,
}) => {
  const dispatch = useDispatch();
  const { getSystemFieldName } = useSystemFields();
  const { hasIdeas } = useSelector(getProjectLayers);
  const { getNumberOfTextRowsToDisplayOnGridCell } = useNumberOfTextRowsToDisplayOnGridCell();

  const openProjectLightbox = useCallback(row => dispatch(openProjectLightboxAction(row.id)), [openProjectLightboxAction]);

  const unlinkFromParent = useCallback(row => updateIdea(row.id, { parent_id: null }), [updateIdea]);

  const addChildrenToProjectOnLightbox = useCallback(
    update => dispatch(addChildrenToProjectOnLightboxAction(update)),
    [addChildrenToProjectOnLightboxAction],
  );

  const { columnDefs, defaultColDef } = useIdeasGridColumnsDefinitions({
    portfolioMode,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    hasIdeas,
    topLayer,
    displayLayer,
    customUserFields: [],
    currentUser,
    integrations,
    hasMetadataMultiSelect,
    getSystemFieldName,
    openProjectInfo: openProjectLightbox,
    visibleColumnsIds: VISIBLE_COLUMNS,
    checkUserCanCreateNewMetadata,
    updateProjectById: updateIdea,
  });

  const titleColumnDef = useMemo(
    () => ({
      field: 'title',
      headerName: 'Title',
      suppressMovable: true,
      comparator: stringComparator,
      headerClass: 'first-field',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: params => ({
        innerRenderer: TitleCellInnerRenderer,
        suppressCount: true,
        suppressDoubleClickExpand: true,
        getNumberOfTextRowsToDisplayOnGridCell,
        displayChildrenCount: false,
        openButtonProps: {
          hasOpenButton: true,
          onClick: () => openProjectLightbox(params.data),
        },
      }),
      cellEditorSelector: params => {
        const isLinkRow = params.data.isLink;
        const parentId = params.context.selectedProject.id;

        if (!isLinkRow) {
          return {
            component: AgGridInput,
          };
        }

        return {
          component: LinkIdeaCellEditor,
          params: {
            onSave: data => {
              if (data?.new && data.inputVal) {
                return createChildProject({ title: data.inputVal });
              }

              addChildrenToProjectOnLightbox({ id: data.entity.id, parent_id: parentId });
            },
            topLayer,
            availableLayers,
            selectedProject: params.context.selectedProject,
          },
        };
      },
      rowDrag: isRowDraggable,
      pinned: 'left',
      lockPinned: true,
      lockPosition: 'left',
      initialHide: false,
      editable: isNewRow,
      width: isDrawerView ? 300 : 430,
    }),
    [getNumberOfTextRowsToDisplayOnGridCell, openProjectLightbox, isDrawerView],
  );

  const unlinkColumnDef = useMemo(
    () => ({
      headerName: '',
      field: 'unlink',
      editable: false,
      suppressMovable: true,
      suppressMenu: true,
      filter: false,
      resizable: false,
      cellRenderer: ActionsCellRenderer,
      headerClass: 'centered-header',
      cellRendererParams: {
        actions: [
          {
            icon: LinkOffOutlined,
            color: theme.palette.icons.primary,
            onClick: unlinkFromParent,
            getVisibility: () => true,
          },
        ],
      },
      width: 38,
      initialHide: false,
    }),
    [unlinkFromParent],
  );

  return useMemo(
    () => ({
      columnDefs: [titleColumnDef, ...addCustomWidthToColumns(columnDefs, isDrawerView), unlinkColumnDef],
      defaultColumnDef: defaultColDef,
    }),
    [columnDefs, defaultColDef, isDrawerView, titleColumnDef, unlinkColumnDef],
  );
};

export default useProjectLightboxChildrenColumns;
