/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Component, Fragment } from 'react';
import { not, isEmpty, both, find, propEq, or, equals, isNil, pipe, defaultTo, either } from 'ramda';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { toast } from 'react-toastify';
import isObject from 'lodash/isObject';

import { fetchCustomFields } from 'store/customFields';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import Dialog from 'design-system/molecules/Dialog/index';
import Autocomplete from 'design-system/atoms/Autocomplete/index';
import Checkbox from 'design-system/atoms/Checkbox/index';

import { textColor } from 'design-system/themes/default';
import { ucFirst, getRandom5DigitNum } from 'utils';
import { array, func } from 'prop-types';
import {
  DISABLE_DEFAULT_FIELDS,
  DEFAULT_JIRA_AUTOSYNC_FIELDS,
  DEFAULT_DB_AUTOSYNC_FIELDS,
  NOT_DELETABLE_FIELDS,
  DISABLE_DEFAULT_FIELDS_ON_DRAGONBOAT,
  DISABLE_DEFAULT_FIELDS_DRAGONBOAT_AUTO_SYNC,
  DISABLE_DEFAULT_FIELDS_JIRA_AUTO_SYNC,
  TITLE_FIELD,
} from '../constants';
import ChangedSummaryFieldAlert from '../ChangedSummaryFieldAlert';
import { IMPORT_COLUMNS, sortByTitle } from './utils';

const JIRA_MAPPING = 'jira-mapping';
const SUMMARY_KEY = 'summary';
const KEY_ATTR = 'key';
const KEYS_TO_INGNORE_ON_CLEAN_FIELD = ['id', 'isNew', 'jiraField'];
const COL_KEY = 'col';

const existsAndIsEmpty = both(Boolean, isEmpty);
const findSummaryField = find(propEq(KEY_ATTR, SUMMARY_KEY));
const isNotObject = pipe(isObject, not);
const defaultToEmptyString = defaultTo('');

class MapImportDataFields extends Component {
  static propTypes = {
    columns: array,
    updateDataMapping: func.isRequired,
  };
  state = {
    dataMapping: [],
    titleFieldSelectedData: null,
  };

  constructor(props) {
    super(props);

    this.state.dataMapping = this.generateDataMapping();
  }

  async componentDidMount(props) {
    await this.props.fetchCustomFields();
    const dataMapping = this.generateDataMapping();

    this.updateExternalDataMapping(dataMapping);

    dataMapping.forEach(({ deletedOnJira, jiraField }) => {
      if (deletedOnJira) {
        toast(`Couldn't find ${jiraField ? `'${jiraField.name}' field` : 'a field'} on JIRA.`, {
          toastId: 123,
        });
      }
    });

    this.setState({ dataMapping });
  }

  componentDidUpdate(prevProps, prevState) {
    const { dataMapping } = this.state;
    const { columns } = this.props;

    const hasColumnsChanged = () => not(isEqual(prevProps.columns, columns));
    const hasDataMappingChanged = () => not(isEqual(prevState.dataMapping, dataMapping));
    const shouldUpdateDataMapping = either(hasDataMappingChanged, hasColumnsChanged);

    if (shouldUpdateDataMapping()) {
      this.updateExternalDataMapping(dataMapping);
    }
  }

  generateDataMapping = () => {
    const { defaultMap, organization, customFields, systemFields } = this.props;
    let { dataMapping } = this.props;

    if (!dataMapping.length && defaultMap) {
      dataMapping = IMPORT_COLUMNS(organization, customFields, systemFields).map(c => ({
        ...c,
        ...{
          col: defaultMap[c.field],
          dbAutoSync: DEFAULT_DB_AUTOSYNC_FIELDS.includes(c.field),
          jiraAutoSync: DEFAULT_JIRA_AUTOSYNC_FIELDS.includes(c.field),
        },
      }));
    } else if (dataMapping.length) {
      dataMapping = IMPORT_COLUMNS(organization, customFields, systemFields).map(d => ({
        ...d,
        ...dataMapping.find(i => i.field === d.field),
      }));
    }

    return dataMapping.sort(sortByTitle);
  };

  getColIndexByMappedField(field) {
    const data = this.state.dataMapping.find(d => d.field === field);

    if (data) {
      return data.col ? this.props.columns.findIndex(col => col === data.col) : null;
    }
    return null;
  }

  getMapValue(field) {
    const fieldOnMapping = this.state.dataMapping.find(d => d.field === field);

    if (isNil(fieldOnMapping)) {
      return '';
    }

    return this.parseFieldColTitle(fieldOnMapping);
  }

  setMapValue(attr, value, field) {
    const isJira = this.props.integrationType === 'jira-mapping';
    const { dataMapping } = this.state;
    const mapping = cloneDeep(dataMapping.find(d => d.field === field));

    if (!value) {
      mapping[attr] = null;

      // on jira remove autosync on remove
      if (isJira && attr === 'col') {
        mapping.dbAutoSync = false;
        mapping.jiraAutoSync = false;
      }
    } else {
      mapping[attr] = value;

      // on jira add autosync on associate a new valid field
      if (isJira && attr === 'col') {
        mapping.dbAutoSync = this.props.columns.some(c => c.title === value);
        mapping.jiraAutoSync = this.props.columns.some(c => c.title === value) && !this.checkAutosyncDisabled(mapping);
      }
    }
    const newDataMapping = dataMapping.map(map => {
      if (map.field === mapping.field) {
        return mapping;
      }

      return map;
    });

    this.setState({ dataMapping: newDataMapping });
  }

  checkFieldSameType = (field, jiraField, type) => {
    type = !field[type] ? 'datatype' : type;

    if (Array.isArray(field[type])) {
      if (jiraField.schema && jiraField.schema.type === 'array') {
        return field[type].includes(jiraField.schema.items);
      }
      return jiraField.schema && field[type].includes(jiraField.schema.type);
    }
    return jiraField.schema && jiraField.schema.type === field[type];
  };

  checkAutosyncDisabled = field => {
    const jiraField = this.props.columns.find(c => c.title === field.col);

    const notSyncable = field.col && jiraField && !jiraField.syncable;
    const notSameType = field.col && jiraField && jiraField.schema && !this.checkFieldSameType(field, jiraField, 'datatypeOut');

    return !field.col || notSyncable || notSameType;
  };

  renderColOptions(field, disabled, title) {
    const fieldData = this.state.dataMapping.find(f => f.field === field);
    const suggestions = this.props.columns.filter(c => this.checkFieldSameType(fieldData, c, 'datatypeIn')).map(c => c.name);

    const _onValueChange = value => {
      if (!value) return;

      this.setMapValue('col', value, field);
      this.showAlertMessageOnUpdateIntegrationFieldWithSelectedValue(field, value);
    };

    return (
      <Autocomplete
        title={title}
        suggestions={suggestions}
        value={this.getMapValue(field)}
        onValueChange={_onValueChange}
        disabled={disabled}
      />
    );
  }

  /**
   * Updates the data mapping on external state
   *
   * @function updateExternalDataMapping
   * @param  {Array} dataMapping
   * @return {void}
   */
  updateExternalDataMapping = dataMapping => {
    if (isNil(this.props.updateDataMapping)) {
      return;
    }

    this.props.updateDataMapping(this.cleanDataMapping(dataMapping));
  };

  /**
   * Clean data mapping to be saved on the org integration configurations
   *
   * @function cleanDataMapping
   * @param  {Array} data
   * @return {Array}
   */
  cleanDataMapping = data => {
    const sortDataMapping = dataToSort => dataToSort.sort(sortByTitle);
    const cleanedData = data.map(field => ({
      ...field,
      col: this.parseFieldColTitle(field),
    }));

    return sortDataMapping(cleanedData);
  };

  /**
   * Parse mapping field col to a title label
   *
   * If the col is an object should replace the title on the object by the
   * title that comes from jira fields based on the key on the obejct
   *
   * @function parseFieldColTitle
   * @param  {Object} field
   * @return {String}
   */
  parseFieldColTitle = field => {
    const { col } = field;
    const { columns } = this.props;

    if (isNotObject(col)) {
      return defaultToEmptyString(col);
    }

    const result = defaultToEmptyString(col?.title);

    if (existsAndIsEmpty(columns)) {
      return result;
    }

    const findColByKey = find(propEq(KEY_ATTR, col.key));
    const jiraColumn = findColByKey(columns);

    return or(jiraColumn?.title, result);
  };

  /**
   * Will check if should show alter message on update integration field with new selected value
   *
   * @function showAlertMessageOnUpdateIntegrationFieldWithSelectedValue
   * @param  {String} field            field on Dragonboat side
   * @param  {String} selectedValue    selected field on Integration side
   * @return {void}                 true if the field should be updated, false otherwise (default true)
   */
  showAlertMessageOnUpdateIntegrationFieldWithSelectedValue = (field, selectedValue) => {
    const { columns, integrationType } = this.props;
    const isNotJiraIntegration = integrationType !== JIRA_MAPPING;

    // this is only for jira integration type
    if (isNotJiraIntegration) return;

    const selectedValueIsNotSummaryField = () => {
      if (existsAndIsEmpty(columns)) return true;

      const summaryField = findSummaryField(columns);

      if (!summaryField) return true;

      const fieldIsSummaryField = equals(summaryField.title);

      return not(fieldIsSummaryField(selectedValue));
    };

    const isFieldTitle = equals(TITLE_FIELD);
    const isFieldTitleAndIsNotSummarySelected = both(isFieldTitle, selectedValueIsNotSummaryField);

    // if user changed the title field should alert the user to use the summary field on jira side
    if (isFieldTitleAndIsNotSummarySelected(field)) {
      this.setState({ titleFieldSelectedData: { field, selectedValue } });
    }
  };

  /**
   * Confirm that should update the Summary field to a different field
   * This logic is applyed only for Jira integration
   *
   * @function confirmUpdateSummaryFieldToDifferentField
   * @return {void}
   */
  confirmUpdateSummaryFieldToDifferentField = () => {
    const { field, selectedValue } = this.state.titleFieldSelectedData;

    this.setMapValue(COL_KEY, selectedValue, field);
    this.setState({ titleFieldSelectedData: null });
  };

  /**
   * Cancel that should update the Summary field to a different field
   *
   * @function cancelUpdateSummaryFieldToDifferentField
   * @return {void}
   */
  cancelUpdateSummaryFieldToDifferentField = () => {
    const { columns } = this.props;
    const { field } = this.state.titleFieldSelectedData;

    const summaryField = findSummaryField(columns);

    this.setState({ titleFieldSelectedData: null });

    if (!summaryField) return;

    // should update with Summary field value
    this.setMapValue(COL_KEY, summaryField.title, field);
  };

  /**
   * Clear field data to be hidden on mapping array
   *
   * Will remove keys not needed and put the col as null to not have value selected
   *
   * @function clearFieldToBeHidden
   * @param  {Object} fieldData
   * @return {Object}
   */
  clearFieldToBeHidden = fieldData => {
    return { ...omit(fieldData, KEYS_TO_INGNORE_ON_CLEAN_FIELD), col: null };
  };

  /**
   * Condition that filters some field from mapping array
   *
   * @function filterFieldCondition
   * @param  {Object} fieldData
   * @return {Boolean}
   */
  filterFieldCondition = fieldData => f => {
    return f.isNew ? f.id !== fieldData.id : f.field !== fieldData.field;
  };

  /**
   * Action that returns the event handler to handle with deletion of field row
   *
   * @function onDeleteFieldRow
   * @param  {Object} fieldData
   * @return {function}
   */
  onDeleteFieldRow = fieldData => e => {
    const { displayTitle } = fieldData;
    const { dataMapping } = this.state;

    const otherFieldsWithoutCurrent = dataMapping.filter(this.filterFieldCondition(fieldData));
    const fieldDataToDeleteOnMapping = displayTitle ? [this.clearFieldToBeHidden(fieldData)] : [];

    const newDataMapping = [...otherFieldsWithoutCurrent, ...fieldDataToDeleteOnMapping];

    this.setState({
      dataMapping: newDataMapping,
    });
  };

  /**
   * Action that return the event handler to handle with change on a dragonboat field
   *
   * @function onChangeDragonboatFieldValue
   * @param  {Object}
   * @return {function}
   */
  onChangeDragonboatFieldValue = fieldData => value => {
    const { dataMapping } = this.state;
    const isValueNull = isNil(value);

    if (isValueNull) return;

    const changedField = dataMapping.find(f => f.displayTitle === value);

    const isChangedFieldNull = isNil(changedField);

    if (isChangedFieldNull) return;

    /*
     * We will have two fields to update the old one to remove the value on col attribute
     * and the new field that will have the col attribute value from the old field
     *
     * On the case that is a new added row it will use only the new field as the old one will be the same
     * but without the isNew and the random id
     */
    const oldField = {
      ...changedField,
      ...this.clearFieldToBeHidden(fieldData),
    };
    const newField = {
      ...fieldData,
      ...changedField,
      col: fieldData.col,
    };

    const allFieldsWithoutChangedFields = dataMapping
      .filter(this.filterFieldCondition(newField))
      .filter(f => f.displayTitle !== oldField.displayTitle);

    let arrayWithOldField = [];
    const oldFieldAndNewFieldAreNotTheSame = oldField.displayTitle !== newField.displayTitle;

    // to avoid duplicates
    if (oldField?.displayTitle && oldFieldAndNewFieldAreNotTheSame) {
      arrayWithOldField = [oldField];
    }

    const newDataMapping = [...allFieldsWithoutChangedFields, ...arrayWithOldField, newField];

    const sortedNewDataMapping = newDataMapping.sort(sortByTitle);

    this.setState({ dataMapping: sortedNewDataMapping });
  };

  /**
   * Action to add new field to the mapping
   *
   * @function onAddNewFieldToMapping
   * @return {void}
   */
  onAddNewFieldToMapping = () => {
    const { dataMapping } = this.state;

    this.setState({ dataMapping: [...dataMapping, { isNew: true, id: getRandom5DigitNum() }] });
  };

  renderFieldRow = (fieldData, isJiraMap) => {
    const { field, displayTitle, disabled, title, col, dbAutoSync, jiraAutoSync, isNew } = fieldData;
    const { dataMapping } = this.state;
    const readOnly = DISABLE_DEFAULT_FIELDS.includes(field);
    const readOnlyOnDragonboat = or(readOnly, DISABLE_DEFAULT_FIELDS_ON_DRAGONBOAT.includes(field));
    const readOnlyOnDragonboatAutoSync = or(readOnly, DISABLE_DEFAULT_FIELDS_DRAGONBOAT_AUTO_SYNC.includes(field));
    const readOnlyOnJiraAutoSync = or(readOnly, DISABLE_DEFAULT_FIELDS_JIRA_AUTO_SYNC.includes(field));
    const isDeletable = not(NOT_DELETABLE_FIELDS.includes(field));

    if (!col && !isNew) {
      return '';
    }

    return (
      <Grid container spacing={8} key={field} style={{ alignItems: 'center' }}>
        <Grid item xs={isJiraMap ? 3 : 5} style={{ textAlign: 'right', paddingRight: 20 }}>
          <div style={{ float: 'right', width: '90%' }}>
            <Autocomplete
              title={title}
              suggestions={dataMapping.filter(f => !f.col && !f.isNew).map(f => f.displayTitle)}
              value={displayTitle}
              disabled={readOnlyOnDragonboat}
              onValueChange={this.onChangeDragonboatFieldValue(fieldData)}
            />
          </div>
        </Grid>
        <Grid item xs={isJiraMap ? 3 : 5}>
          {this.renderColOptions(field, disabled ? disabled(dataMapping) : readOnly, title ? title(dataMapping) : '')}
        </Grid>
        {isJiraMap && (
          <Fragment>
            <GridCheckbox item xs={2}>
              <SyncCheckbox
                color="primary"
                red={col && (!this.checkAutosyncDisabled(fieldData) ? !dbAutoSync && !jiraAutoSync : !dbAutoSync)}
                checked={(col && dbAutoSync) || false}
                disabled={readOnlyOnDragonboatAutoSync || (disabled && disabled(dataMapping)) || !col}
                onChange={({ target }) => this.setMapValue('dbAutoSync', target.checked, field)}
              />
            </GridCheckbox>
            <GridCheckbox item xs={2}>
              <SyncCheckbox
                color="primary"
                red={
                  col &&
                  !this.checkAutosyncDisabled(fieldData) &&
                  (!this.checkAutosyncDisabled(fieldData) ? !dbAutoSync && !jiraAutoSync : !dbAutoSync)
                }
                checked={(col && jiraAutoSync) || false}
                disabled={readOnlyOnJiraAutoSync || (disabled && disabled(dataMapping)) || this.checkAutosyncDisabled(fieldData)}
                onChange={({ target }) => {
                  this.setMapValue('jiraAutoSync', target.checked, field);

                  if (!target.checked) {
                    this.setState({ removeJiraAutoSyncAlert: true });
                  }
                }}
              />
            </GridCheckbox>
          </Fragment>
        )}
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          {isDeletable && (
            <DeleteButton title="Delete" disabled={readOnly}>
              <DeleteIcon onClick={this.onDeleteFieldRow(fieldData)} />
            </DeleteButton>
          )}
        </Grid>
      </Grid>
    );
  };

  render() {
    const { dataMapping } = this.state;
    const { integrationType } = this.props;
    const isJiraMap = integrationType === 'jira-mapping';
    const integrationFieldLabels = {
      'jira-mapping': 'Jira',
    };

    return (
      <Wrapper>
        <HeaderGrid container spacing={8} style={{ alignItems: 'center' }}>
          <Grid item xs={isJiraMap ? 3 : 5} style={{ textAlign: 'center' }}>
            <a
              href="https://dragonboat.zendesk.com/hc/en-us/articles/360013269194-Dragonboat-Overview-Data-Structure"
              target="_blank"
              rel="noopener noreferrer"
            >
              dragonboat Data Fields
            </a>
          </Grid>
          <Grid item xs={isJiraMap ? 3 : 5} style={{ textAlign: 'center' }}>
            <span>
              {integrationFieldLabels[integrationType] ? integrationFieldLabels[integrationType] : ucFirst(integrationType)} Field
            </span>
          </Grid>
          {isJiraMap && (
            <Fragment>
              <Grid item xs={2} style={{ textAlign: 'center' }}>
                <span>Jira → dragonboat</span>
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'center' }}>
                <span>dragonboat → Jira</span>
              </Grid>
            </Fragment>
          )}
          <Grid item xs={2} />
        </HeaderGrid>
        {dataMapping.map(field => this.renderFieldRow(field, isJiraMap))}
        <Grid container spacing={8}>
          <Grid item xs={4} style={{ padding: '10px 20px' }}>
            <Button size="small" onClick={this.onAddNewFieldToMapping} color="primary">
              <AddIcon /> New Field
            </Button>
          </Grid>
        </Grid>
        <Dialog
          open={this.state.removeJiraAutoSyncAlert}
          title="Warning"
          closeButton
          onClose={() => this.setState({ removeJiraAutoSyncAlert: false })}
          actions={
            <Fragment>
              <Button onClick={() => this.setState({ removeJiraAutoSyncAlert: false })}>Close</Button>
            </Fragment>
          }
        >
          <TextDeprecated breakwords>
            Disabling update from dragonboat to Jira will cause loss of data entered by your user in dragonboat.
          </TextDeprecated>
          <br />
          <br />
          <TextDeprecated breakwords variant="bold">
            Applying this setting only if no change of this field in dragonboat is allowed.
          </TextDeprecated>
        </Dialog>
        <ChangedSummaryFieldAlert
          isOpen={Boolean(this.state.titleFieldSelectedData)}
          onConfirm={this.confirmUpdateSummaryFieldToDifferentField}
          onCancel={this.cancelUpdateSummaryFieldToDifferentField}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  color: ${textColor};
  margin-top: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 450px);
`;

const HeaderGrid = styled(Grid)`
  &&&& {
    position: absolute;
    z-index: 1000;
    margin-top: -30px;
    background: #ffff;
    width: calc(100% - 40px);
  }
`;

const GridCheckbox = styled(Grid)`
  &&&& {
    display: flex;
    justify-content: center;
  }
`;

const SyncCheckbox = styled(Checkbox)`
  &&&& {
    padding: 0;

    ${({ disabled }) =>
      disabled &&
      `
      span {
        color: rgba(0, 0, 0, 0.15)!important;
      }
    `}

    ${({ red }) =>
      red &&
      `
      span {
        color: red!important;
      }
    `}
  }
`;

const DeleteButton = styled(IconButton)`
  &&&& {
    padding: 4px;
  }
`;

const mapStateToProps = state => {
  const {
    organization: { organization },
  } = state;

  return {
    organization,
    customFields: getProjectsCustomFields(state),
    systemFields: organization.system_fields_name,
  };
};

export default connect(mapStateToProps, {
  fetchCustomFields,
})(MapImportDataFields);
