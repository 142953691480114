import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { BaseGrid } from 'containers/Grids';
import BaseLayout from 'design-system/organisms/BaseLayout/index';

import { useCommonGridEventHandlers, useCommonGridProps } from 'design-system/molecules/AgGridReact-New/hooks';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import usePermissions from 'hooks/permissions/usePermissions';
import { spacing } from 'design-system/theme';

import RequestsPagination from '../../Pagination/RequestsPaginationComponent';
import { GRID_HEIGHT, HEADER_HEIGHT, ID_KEY } from './constants';
import useCustomerRequests from './hooks/useCustomerRequests';
import useCustomerRequestsGridMetadata from './hooks/useCustomerRequestsGridMetadata';
import { useCustomerRequestsColumnsDefs } from './hooks/useCustomerRequestsColumnsDefs';
import useCustomerRequestsGridState from './hooks/useCustomerRequestsGridState';
import useCustomerRequestsGrid from './hooks/useCustomerRequestsGrid';
import LocalSearch from '../../ControlsBar/LocalSearch';

const gridStyles = css`
  .ag-layout-normal.ag-root {
    margin: 0;
  }
`;

const LocalSearchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  padding-bottom: ${spacing(0.75)}px;
`;

const getRowId = ({ data }) => {
  return data?.id;
};

export default () => {
  const { customerRequests, onOpenCustomerRequestInfo, onSwitchRowOrder } = useCustomerRequests();

  const {
    rowHeight,
    searchString,
    isBulkUpdate,
    visibleColumnsIds,
    isLoading,
    columnsState,
    shouldGridBeUnmounted,
    selectedItems,

    setDirtyViewMutex,
    saveColumnsState,
  } = useCustomerRequestsGridState();

  const {
    handleSelectionChanged,
    getGridIsReady,
    getGridApi,
    handleGridReady,
    onChangeColumnVisibility,
    updateCustomerRequestByIdOnCellValueChange,
    handleDisplayedColumnsChanged,
    handleColumnResized,
  } = useCustomerRequestsGrid({ onOpenCustomerRequestInfo, saveColumnsState });

  const { metadata, filteredMetadata, createMultiSelectOption } = useCustomerRequestsGridMetadata();

  const { columnDefs, defaultColDef } = useCustomerRequestsColumnsDefs({
    searchString,
    isBulkUpdate,
    visibleColumnsIds,
    columnsState,
    rowHeight,

    updateCustomerRequestById: updateCustomerRequestByIdOnCellValueChange,
    onChangeColumnVisibility,
    onOpenCustomerRequestInfo,
    createMultiSelectOption,
  });

  const commonGridProps = useCommonGridProps({});

  const commonGridEvents = useCommonGridEventHandlers({
    items: customerRequests,
    idKey: ID_KEY,
    actions: {
      update: updateCustomerRequestByIdOnCellValueChange,
      drag: onSwitchRowOrder,
      sortChanged: handleDisplayedColumnsChanged,
    },
  });

  const { canUpdate } = usePermissions();

  const gridContext = useMemo(
    () => ({
      metadata,
      filteredMetadata,
      isBulkDeleting: isBulkUpdate,
      allowEdit: data => canUpdate(PERMISSION_RESOURCES.request, { data }),
    }),
    [metadata, filteredMetadata, isBulkUpdate, canUpdate],
  );

  useEffect(() => {
    // Cleanup selected items if stopped bulk deleting.
    if (!isBulkUpdate && getGridIsReady()) {
      getGridApi().deselectAll();
    }
  }, [isBulkUpdate]);

  if (shouldGridBeUnmounted) {
    return <PageLoading />;
  }

  const disablePagination = isLoading || selectedItems?.length > 0;

  return (
    <BaseLayout
      toolbarComponent={
        <RequestsPagination
          customContentComponent={
            <LocalSearchWrapper>
              <LocalSearch />
            </LocalSearchWrapper>
          }
        />
      }
    >
      <BaseGrid
        // Common props:
        {...commonGridProps}
        {...commonGridEvents}
        // General props:
        onGridReady={handleGridReady}
        rowData={customerRequests}
        context={gridContext}
        onFirstDataRendered={setDirtyViewMutex}
        // isGroupOpenByDefault
        // isRowSelectable
        rowSelection="multiple"
        suppressRowClickSelection
        getRowId={getRowId}
        // Styling:
        // getRowStyle
        cssStyles={gridStyles}
        height={GRID_HEIGHT}
        headerHeight={HEADER_HEIGHT}
        rowHeight={rowHeight}
        // Columns:
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        // Events:
        // onRowGroupOpened
        onSelectionChanged={handleSelectionChanged}
        onDisplayedColumnsChanged={handleDisplayedColumnsChanged}
        onColumnResized={handleColumnResized}
      />
      <RequestsPagination hideIfNoResult isDisabled={disablePagination} />
    </BaseLayout>
  );
};
