import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { both, isEmpty, isNil, not, pipe } from 'ramda';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';
import noop from 'lodash/noop';

import ActiveCircleIndicator from 'design-system/atoms/ActiveCircleIndicator/index';
import DndFooterActions from 'design-system/molecules/DndFooterActions/DndFooterActions';
import SearchableDndList from 'design-system/molecules/SearchableDndList/index';
import ToggleButton from 'design-system/molecules/ToggleButton/index';


import { spacing } from 'design-system/theme';

const MENU_PLACEMENT = 'bottom-end';
const SUB_MENU_PLACEMENT = 'right-start';

const isNotNil = pipe(isNil, not);
const isNotEmpty = pipe(isEmpty, not);
const isNotNilOrEmpty = both(isNotNil, isNotEmpty);

export default ({
  anchorEl,
  setAnchorEl,
  onCloseDropdown,

  items,
  buttonProps = {},

  menuPlacement = MENU_PLACEMENT,
  searchPlaceholder = '',
  checkable = false,

  renderListHeaderOptions,
  renderCustomFooter,

  handleClear = noop,
  handleSubMenuOptionClick = noop,
  handleSelectAllSubmenu = noop,
  handleClearAllSubmenu = noop,
  searchMenuItems,
}) => {
  const isPopoverOpen = !!anchorEl;
  const [subMenuData, setSubMenuData] = useState(null);
  const [subMenuItemAnchorEl, setSubMenuItemAnchorEl] = useState(null);

  const isSubmenuOpen = Boolean(subMenuItemAnchorEl);

  const closeSubMenuPopover = () => {
    setSubMenuData(null);
    setSubMenuItemAnchorEl(null);
  };

  // only close dropdown if not clicking on submenu
  const onCloseMenu = () => {
    if (!isSubmenuOpen) {
      onCloseDropdown();
      closeSubMenuPopover();
    }
  };

  const renderListItem = item => {
    const { id, title, subMenuItems, selectedItems } = item;

    const subMenuFooterActions = [
      { text: 'Select All', icon: <CheckIcon />, onClick: () => handleSelectAllSubmenu(id) },
      { text: 'Clear All', onClick: () => handleClearAllSubmenu(id) },
    ];

    return (
      <MenuItem
        key={id}
        onMouseEnter={arg => {
          if (isNotNilOrEmpty(subMenuItems)) {
            setSubMenuItemAnchorEl(arg.currentTarget);
            setSubMenuData(item);
          } else {
            closeSubMenuPopover();
          }
        }}
      >
        {isNotNil(subMenuItems) && <StyledActiveCircleIndicator active={isNotNilOrEmpty(selectedItems)} />}
        <StyledTypography variant="body2" title={title} hasSubmenu={isNotNil(subMenuItems)}>
          {title}
        </StyledTypography>
        <StyledPopper
          open={subMenuData?.id === id}
          anchorEl={subMenuItemAnchorEl?.parentElement}
          placement={SUB_MENU_PLACEMENT}
          onMouseLeave={closeSubMenuPopover}
        >
          <SearchableDndList
            items={subMenuItems}
            width={200}
            renderCustomFooter={() => <DndFooterActions actions={subMenuFooterActions} />}
            onSelectItem={(subMenuItemId, selected) => handleSubMenuOptionClick(id, subMenuItemId, selected)}
            selectedItems={selectedItems}
            searchMenuItems={searchMenuItems}
            checkable
          />
        </StyledPopper>
      </MenuItem>
    );
  };

  return (
    <ClickAwayListener onClickAway={onCloseMenu}>
      <div>
        <ToggleButton
          onChange={e => {
            setAnchorEl(isPopoverOpen ? null : e.currentTarget);
          }}
          {...buttonProps}
        />
        <StyledPopper
          anchorEl={anchorEl}
          open={isPopoverOpen}
          onMouseLeave={closeSubMenuPopover}
          placement={menuPlacement}
          disablePortal
        >
          <SearchableDndList
            items={items}
            renderCustomItem={renderListItem}
            renderCustomHeaderOptions={renderListHeaderOptions}
            renderCustomFooter={renderCustomFooter}
            searchPlaceholder={searchPlaceholder}
            checkable={checkable}
            handleClear={handleClear}
            width={280}
            searchMenuItems={searchMenuItems}
            avoidVirtualization
            hideSearchIfNoItems
          />
        </StyledPopper>
      </div>
    </ClickAwayListener>
  );
};

const StyledPopper = styled(Popper)`
  z-index: ${({ theme }) => theme.zIndex.high};
`;

const MenuItem = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
`;

// this should be revisted for DnDMenuList usages to keep consistency
const StyledTypography = styled(Typography)`
  ${({ hasSubmenu }) =>
    hasSubmenu &&
    css`
      padding-left: ${spacing(1)}px;
    `}
`;

// we need to custom align it. We do not have control on the parent and how it's build by DndMenuList
const StyledActiveCircleIndicator = styled(ActiveCircleIndicator)`
  position: absolute;
  left: -9px; // pixel perfect
`;
