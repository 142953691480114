import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { and } from 'ramda';
import styled from 'styled-components';

import { Elements } from 'react-stripe-elements';

import PageTitle from 'design-system/atoms/PageTitle/index';


import CheckoutForm from './CheckoutForm';
import SubscriptionDetails from './SubscriptionDetails';
import DropdownFilter from 'components/DropdownFilter';
import { CSM_USER } from '@dragonboat/permissions';

import { updateBillingStatus as updateOrgBillingStatus } from 'store/organization';

const BILLING_STATUS_OPTIONS = [
  { key: 'trial', title: 'Trial' },
  { key: 'free', title: 'Free' },
  { key: 'invoiced', title: 'Invoiced' },
  { key: 'paid', title: 'Paid' },
  { key: 'inactive', title: 'Inactive' },
];

const SubscriptionInfo = props => {
  const { firstLoad, billingInfo, currentUser, updateBillingInfo, prices } = props;
  const dispatch = useDispatch();
  const [selected, setSelected] = useState({});

  const updateBillingStatus = async statusOption => {
    const billingStatus = statusOption.key;

    await dispatch(updateOrgBillingStatus(billingStatus));
    await updateBillingInfo();
  };

  const canUpdateBillingStatus = currentUser.role_id === CSM_USER || currentUser.isSuperAdmin;
  const isPaid = billingInfo && billingInfo.billing_status === 'paid';

  const showUpdateBillingStatusDropdown = canUpdateBillingStatus;
  const showBillingStatusPaid = and(!canUpdateBillingStatus, isPaid);
  const showEnterBillingInformation = and(!canUpdateBillingStatus, !isPaid);

  useEffect(() => {
    const billingStatus = billingInfo?.billing_status;
    const selected = BILLING_STATUS_OPTIONS.find(opt => opt.key === billingStatus);

    setSelected(selected);
  }, [billingInfo?.billing_status]);

  const _renderUpdateBillingStatus = () => {
    return (
      <>
        {'Billing status: '}
        <InlineDropdownFilter
          name="updateBilling"
          options={BILLING_STATUS_OPTIONS}
          selectedValue={selected}
          onChange={updateBillingStatus}
          hideAll
        />
      </>
    );
  };

  const _renderPaidStatus = () => {
    return 'Billing Status: Paid';
  };

  const _renderBillingInfoFormTitle = () => {
    return 'Enter Billing Information';
  };

  return (
    <>
      {!firstLoad && (
        <BillingPaper>
          <PageTitle id="billing-information" data-testid="billing-information" style={{ marginLeft: 30, lineHeight: 1.6 }}>
            {showUpdateBillingStatusDropdown && _renderUpdateBillingStatus()}
            {showBillingStatusPaid && _renderPaidStatus()}
            {showEnterBillingInformation && _renderBillingInfoFormTitle()}
          </PageTitle>
          {isPaid && (
            <Elements>
              <SubscriptionDetails billingInfo={billingInfo} />
            </Elements>
          )}
          {!isPaid && prices && (
            <Elements>
              <CheckoutForm billingInfo={billingInfo} prices={prices} />
            </Elements>
          )}
        </BillingPaper>
      )}
      {firstLoad && <div />}
    </>
  );
};

const BillingPaper = styled.div`
  max-width: 70%;
  // flex-grow: 1;
  padding: 40px 40px 30px 40px;
`;

const InlineDropdownFilter = styled(DropdownFilter)`
  &&& {
    #select-selectField {
      font-size: 1.25rem;
      color: ${props => props.theme.palette.newLayout.text.primary};
    }
  }
`;

export default SubscriptionInfo;
