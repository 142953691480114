import { css } from 'styled-components';

import { spacing } from 'design-system/theme';
import { draftBackgroundColor } from 'design-system/constants/draft';

const INDENTATION = spacing(2);

const genericStyles = css`
  &&&& {
    .ag-group-child-count {
      display: none;
    }

    /* we should revisit how the grid is being stylized once everything is migrated. 
     * The base common styling is messing with some leafs title sizes and creating misalignment of actions  
     */
    .ag-row-group-leaf-indent {
      padding-right: 8px;
    }

    /* we should revisit how the grid is being stylized once everything is migrated. 
     * The base common styling has a lot of spacing being rendered without need on the new grid 
     */
    .ag-row-group-indent-1 {
      padding-left: ${INDENTATION}px !important;
    }

    .ag-row-group-indent-2 {
      padding-left: ${INDENTATION * 2}px !important;
    }

    .ag-row-group-indent-3 {
      padding-left: ${INDENTATION * 3}px !important;
    }

    .ag-row-group-indent-4 {
      padding-left: ${INDENTATION * 4}px !important;
    }

    .ag-row-group-indent-5 {
      padding-left: ${INDENTATION * 5}px !important;
    }

    .ag-theme-material .ag-ltr .ag-group-contracted,
    .ag-theme-material .ag-ltr .ag-group-expanded {
      margin-right: 0;
    }

    .ag-row .ag-cell.align-items-top > div {
      align-items: unset !important;
      justify-content: start;
      height: auto;
      max-height: 100%;
    }

    .allocate-drag-icon-space {
      padding-left: 34px;
    }

    .ag-cell.draft-mode-editable,
    .ag-header-cell.draft-mode-editable {
      background: ${draftBackgroundColor};
    }
  }
`;

export { genericStyles };
