import queryString from 'query-string';
import { useCallback, useEffect } from 'react';
import { not } from 'ramda';
import { useSelector } from 'react-redux';

import { isApplyFiltersLoading, isApplyFiltersUninitialized } from 'store/projects';

/**
 * @function useProjectDeliverableLightboxUrl
 *
 * hook to handle with project deliverable lightbox url
 *
 * @param  {Boolean}  isOpen
 * @param  {Boolean}  onOpen
 * @param  {Number}   projectId
 * @param  {Number}   cycleDeliverableId
 * @return {Object}
 */
const useProjectDeliverableLightboxUrl = (isOpen, onOpen, projectId, cycleDeliverableId) => {
  const isDataOnPageLoading = useSelector(state => isApplyFiltersUninitialized(state) || isApplyFiltersLoading(state));

  const baseUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

  useEffect(() => {
    const params = queryString.parse(window.location.search.substring(1));

    if (isDataOnPageLoading !== false) {
      return;
    }

    if (not(isOpen) && params.openProjectDeliverable) {
      const [projectIdFromUrl, cycleDeliverableIdFromUrl] = params.openProjectDeliverable.split(',');

      onOpen({ projectId: parseInt(projectIdFromUrl), cycleDeliverableId: parseInt(cycleDeliverableIdFromUrl) });
    } else if (isOpen && projectId && cycleDeliverableId) {
      const queryParameters = `?openProjectDeliverable=${projectId},${cycleDeliverableId}`;
      const url = `${baseUrl}${queryParameters}`;

      window.history.pushState('', '', url);
    }
  }, [isOpen, onOpen, projectId, cycleDeliverableId, isDataOnPageLoading]);

  const cleanUrl = useCallback(() => {
    window.history.pushState('', '', baseUrl);
  }, []);

  return {
    cleanUrl,
  };
};

export default useProjectDeliverableLightboxUrl;
