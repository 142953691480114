import React, { Component, Fragment } from 'react';

import { bool, arrayOf, object, array, func, string } from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { set, lensProp, drop, take, concat } from 'ramda';

import LightBox from 'components/LightBox';
import DataGrid from 'components/DataGrid';
import Link from 'components/Link';
import parseArrayIntoObject from 'components/DataGrid/processDataFromClipboard/parseArrayIntoObject';
import mapEditableColumns from 'components/DataGrid/processDataFromClipboard/mapEditableColumns';
import { IDEA_LAYER } from 'store/projects/constants';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import parseClipboardDataToArray from 'design-system/utils/parseClipboardDataToArray';

import columnDefsFactory from './columnDefsFactory';
import getSystemFieldName from 'utils/getSystemFieldName';
import CopyPasteFieldSelectionModal from './CopyPasteFieldSelectionModal';

const TRANSFORM_FROM_CLIPBOARD = [{}];
const EXCLUDED_FIELD_OPTIONS = ['title', 'planningStage'];

const rowDataChanged = (prevRowData, nextRowData) => prevRowData !== nextRowData;
const shouldAutoselectGrid = (gridApi, selectMode) => gridApi && selectMode;
const parentKeyLens = lensProp('parentKey');

const getRowId = ({ data }) => data?.id || null;

class IdeasCopyPasteLightbox extends Component {
  static propsTypes = {
    isOpen: bool,
    isLightbox: bool,
    rowData: arrayOf(object).isRequired,
    height: string,

    productOwners: array,
    phaseTitles: array,
    roadmapsTitles: array,
    timeframesTitles: array,
    jiraIntegrated: bool,
    objectiveTitles: array,
    themeTitles: array,
    categoryTitles: array,
    priorities: array,
    rowsAutoheight: bool,
    isCopyPaste: bool,

    save: func.isRequired,
    cancel: func.isRequired,
    updateRowData: func.isRequired,
    onRowSelected: func,
  };

  static defaultProps = {
    productOwners: [],
    phaseTitles: [],
    roadmapsTitles: [],
    timeframesTitles: [],
    jiraIntegrated: true,
    objectiveTitles: [],
    themeTitles: [],
    categoryTitles: [],
    priorities: [],
    isLightbox: true,
    isCopyPaste: true,
    rowsAutoheight: false,
    onRowSelected: () => {},
  };

  constructor(props) {
    super(props);

    this.content = <div>Lightbox</div>;

    this.state = {
      progressTxt: '',
      rowData: props.rowData,
      disableSaveButton: false,
      contentPasted: false,
      layer: IDEA_LAYER,
      columnDefs: columnDefsFactory(props, this.removeRow, IDEA_LAYER),
      selectedFields: [
        { key: 'initiativeTitle' },
        { key: 'roadmapTitle' },
        { key: 'product1Title' },
        { key: 'objectiveTitle' },
        { key: 'keyResult1Title' },
        { key: 'ownerName' },
        { key: 'timeframeTitle' },
      ],
      showSelectFieldsModal: this.props.isCopyPaste,
    };

    this.afterProcessClipboardData = this.afterProcessClipboardData.bind(this);
    this.updateByRowIndex = this.updateByRowIndex.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (rowDataChanged(prevProps.rowData, this.props.rowData) && shouldAutoselectGrid(this.props.selectMode, this.gridApi)) {
      this.gridApi.selectAll();
    }

    if (prevState.layer !== this.state.layer) {
      this.setState({ columnDefs: columnDefsFactory(this.props, this.removeRow, this.state.layer) });
    }
  }

  componentWillReceiveProps(newProps) {
    if (rowDataChanged(this.props.rowData, newProps.rowData)) {
      this.setState({
        rowData: newProps.rowData,
      });
    }
  }

  processRowDataFromClipboard = async (gridColumnApi, columnDefs) => {
    const clipboardData = await navigator.clipboard.readText();

    if (!clipboardData) {
      return;
    }

    const clipboardDataArrays = parseClipboardDataToArray(clipboardData);
    const columns = mapEditableColumns(
      gridColumnApi
        .getColumnState()
        .filter(column => !column.hide)
        .map(column => column.colId),
      columnDefs,
    );

    return clipboardDataArrays.map(row => {
      return parseArrayIntoObject(row, columns, 'title');
    });
  };

  afterProcessClipboardData(data) {
    if (!data || (!data.create && !data.update)) {
      return;
    }

    const { rowData } = this.state;

    let newRowData = rowData.slice(0);

    if (data.create) {
      newRowData = newRowData.concat(data.create);
    }

    let lastRowIndex = newRowData.findIndex(r => !r.title) - 1;

    if (data.update) {
      data.update.forEach(({ rowIndex, ...row }) => {
        if (rowIndex > lastRowIndex) lastRowIndex += 1;
        newRowData[rowIndex > lastRowIndex ? lastRowIndex : rowIndex] = {
          ...newRowData[rowIndex],
          ...row,
        };
      });
    }

    this.setState({
      rowData: newRowData,
      contentPasted: true,
    });
  }

  updateByRowIndex(rowIndex, update) {
    const { rowData } = this.state;

    // if just have empty values should cancel the update
    if (!Object.values(update).filter(v => !!v).length) {
      return;
    }

    rowData[rowIndex] = {
      ...rowData[rowIndex],
      ...update,
    };

    // this.props.updateRowData(rowData);
    this.setState({
      rowData,
    });

    const { rowsAutoheight } = this.props;

    if (rowsAutoheight && this.gridApi) {
      setTimeout(() => this.gridApi.resetRowHeights(), 0);
    }
  }

  removeRow = rowIndex => {
    const { rowData } = this.state;
    const data = cloneDeep(rowData);

    data.splice(rowIndex, 1);

    this.setState({
      rowData: data,
    });
  };

  onSave = () => {
    const { save } = this.props;
    const { rowData } = this.state;
    const roomId = this.props.socket.join();

    this.props.socket.subscribe(
      ({ created, total }) => {
        this.setState({ progressTxt: `Imported ${created} from ${total}` });
      },
      { messageType: `message-${roomId}` },
    );

    this.setState({
      disableSaveButton: true,
    });

    const dataToSave = rowData
      .map(row => ({ ...row, layer: this.state.layer }))
      .filter(row => row.title && row.title.trim().length);

    return save(dataToSave, roomId)
      .then(() => {
        this.setState({
          disableSaveButton: false,
        });
      })
      .catch(err => {
        console.log({ err });
        // eslint-disable-next-line max-len
        // toast(
        //   `This import failed as one or more ${getSystemFieldName(
        //     'idea',
        //     systemFields,
        //   )} titles have too many characters. Please correct your source data and retry. `,
        // );
        this.setState({
          disableSaveButton: false,
        });
      });
  };

  clearAll = () => {
    const newRowData = [];

    for (let i = 0; i < 100; i++) newRowData.push({});

    this.setState({
      rowData: newRowData,
    });
    this.setState({ contentPasted: false });
  };

  onGettingGridApis = async ({ api, columnApi }) => {
    this.gridApi = api;

    if (isEqual(TRANSFORM_FROM_CLIPBOARD, this.props.rowData)) {
      const clipboardData = await this.processRowDataFromClipboard(columnApi, this.state.columnDefs);

      if (clipboardData) {
        this.setState({
          rowData: clipboardData,
        });
      }
    }
    if (this.gridApi) this.gridApi.selectAll();
  };

  _setLayer = ({ target }) => {
    this.setState({ layer: target.value });
  };

  removeSelectColumn = gridColumns => {
    const firstTwo = take(2, gridColumns);
    const lastFew = drop(3, gridColumns);

    return concat(firstTwo, lastFew);
  };

  render() {
    const { cancel, isLightbox, height, isCopyPaste, onRowSelected, systemFields } = this.props;
    const { rowData, contentPasted, columnDefs, selectedFields, layer, showSelectFieldsModal } = this.state;
    const fieldsOptions = columnDefs
      .map(c => ({ title: c.headerName, key: c.field }))
      .filter(c => !!c.key && !EXCLUDED_FIELD_OPTIONS.includes(c.key));

    if (showSelectFieldsModal) {
      return (
        <CopyPasteFieldSelectionModal
          selectedLayer={layer}
          onChangeLayer={l => this.setState({ layer: l })}
          fieldsOptions={fieldsOptions}
          selectedFields={selectedFields.filter(f => !!fieldsOptions.find(o => o.key === f.key))}
          onChangeSelectedFields={fields => {
            this.setState({ selectedFields: fields.map(f => fieldsOptions.find(o => o.key === f)) });
          }}
          onClickNext={() => this.setState({ showSelectFieldsModal: false })}
          onClose={cancel}
        />
      );
    }

    const selectedFieldsKey = ['title', ...selectedFields.map(f => f.key)];
    let gridColumns = isCopyPaste
      ? columnDefs
          .filter(c => !c.field || selectedFieldsKey.includes(c.field))
          .sort((a, b) => selectedFieldsKey.indexOf(a.field) - selectedFieldsKey.indexOf(b.field))
      : columnDefs;

    if (this.props.skipSelectionCheckboxes) {
      gridColumns = this.removeSelectColumn(gridColumns);
    }

    const enhancedRowData = rowData.map((each, index) => ({
      ...set(parentKeyLens, each?.parent?.key, each),
      id: index,
    }));

    // remove empty rows to not count them
    const validRowsToImport = rowData.filter(row => row.title && row.title.trim().length);

    const content = (
      <div>
        {isCopyPaste && (
          <Fragment>
            <InfoContainer>
              <p>Copy your spreadsheet data and paste into relevant columns</p>
              You may paste up to 100 rows at a time.&nbsp; (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://dragonboat.zendesk.com/hc/en-us/articles/360013269194-Dragonboat-Overview-Data-Structure"
              >
                Data structure here
              </a>
              ).
              {contentPasted && (
                <Grid container>
                  <Grid item xs={6}>
                    {/* <Typography onClick={this.onSave}> */}
                    {/* <Link> */}
                    {validRowsToImport && validRowsToImport.length} {getSystemFieldName('idea', systemFields)}s are ready for
                    import.
                    {/* </Link> */}
                    {/* </Typography> */}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography onClick={this.clearAll} align="right">
                      <Link>Clear data</Link>
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </InfoContainer>
          </Fragment>
        )}
        <GridContainer className="ag-theme-material" height={height}>
          <DataGrid
            columnDefs={gridColumns}
            rowData={enhancedRowData}
            updateById={this.updateByRowIndex}
            remove={() => Promise.resolve()}
            saveNew={() => Promise.resolve()}
            disableFocusOnNewRow
            onGettingGridApis={this.onGettingGridApis}
            pasteToUnlimitedCells
            isGridWithoutIds
            afterProcessClipboardData={this.afterProcessClipboardData}
            gridOptions={{
              enableSorting: false,
              enableFiltering: false,
              onRowSelected,
              enableFilter: false,
              getRowStyle: params => {
                if (params.data && Object.keys(params.data).length !== 0 && !params.data.title) {
                  return { backgroundColor: 'rgb(249, 244, 255)' };
                }
              },
              toolPanelSuppressPivots: true,
              toolPanelSuppressPivotMode: true,
              toolPanelSuppressRowGroups: true,
              toolPanelSuppressValues: true,
              getRowId,
            }}
          />
        </GridContainer>
      </div>
    );

    if (!isLightbox) {
      return content;
    }

    return (
      <LightBox
        isOpen={this.props.isOpen}
        fullWidth
        maxWidth="lg"
        content={content}
        actions={
          <React.Fragment>
            {this.state.disableSaveButton && (
              <>
                <TextDeprecated style={{ marginRight: 10 }}>{this.state.progressTxt}</TextDeprecated>
                <CircularProgress size={20} />
              </>
            )}
            {isCopyPaste && (
              <Button color="primary" onClick={() => this.setState({ showSelectFieldsModal: true })}>
                Back
              </Button>
            )}
            <div style={{ flex: '1 0 0' }} />
            <Button aria-label="save" color="primary" onClick={this.onSave} disabled={this.state.disableSaveButton}>
              import
            </Button>
            <Button onClick={cancel}>cancel</Button>
          </React.Fragment>
        }
      />
    );
  }
}

export default IdeasCopyPasteLightbox;

const InfoContainer = styled(DialogContentText)`
  &&&& {
    margin: 0px 0px 20px 0px;
  }
`;

const GridContainer = styled.div`
  height: ${({ height }) => (!height ? 'calc(100vh - 350px)' : height)};

  .ag-header-cell-text-centered {
    .ag-header-cell-text {
      flex: 1;
      text-align: center;
    }
  }

  .ag-layout-normal.ag-root {
    margin: 0;
  }
`;
