import { EXTERNAL_REQUESTOR, OWNER_USER } from '@dragonboat/permissions';
import { returnsTrueIfKeyIsNotEscOrTab } from 'utils/agGrid';
import getSystemFieldName from 'utils/getSystemFieldName';
import { stringComparator, integrationComparator } from 'utils/agGridComparators';
import UserTypeMapper from 'utils/UserTypeMapper';
import AgGridUserIntegrationIconsColumn from 'components/AgGridUserIntegrationIconsColumn';
import { applyCheckIsEditable } from 'design-system/molecules/AgGridReact-New/helpers';
import { TextCellRenderer, GroupCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import {
  AutocompleteCellEditor,
  DropdownCellEditor,
  MetadataTreeEditor,
} from 'design-system/molecules/AgGridReact-New/cellEditors';

import TeamCellRenderer from '../components/AgGridColumnTeam/TeamCellRenderer';
import CapacityCellRenderer from '../components/AgGridCapacityRenderer/CapacityCellRenderer';
import { getToolTipPerUserIntegrationType } from '../utils';
import { getJiraAccountId } from './getJiraAccountId';

const getNameColumnDef = (field, headerName, editable) => ({
  field,
  headerName,
  editable: applyCheckIsEditable(editable),
  suppressMovable: true,
  filter: false,
  cellRenderer: TextCellRenderer,
  comparator: stringComparator,
});

const getFirstNameColumnDef = editable => ({
  ...getNameColumnDef('first_name', 'First Name', editable),
  filterParams: { values: [] },
});

const getLastNameColumnDef = editable => ({
  ...getNameColumnDef('last_name', 'Last Name', editable),
});

const getEmailColumnDef = editable => ({
  ...getNameColumnDef('email', 'Email', editable),
  width: 250,
});

const getTeamsColumnDef = ({
  editable,
  systemFields,
  allTeams,
  teamsTitles,
  hasTeams2,
  teams2LevelsDropdownAnchor,
  onTeamChangeHandler,
}) => {
  const teamName = getSystemFieldName('team', systemFields);

  const commonDefs = {
    field: 'team.title',
    headerName: teamName,
    editable: applyCheckIsEditable(editable),
    filter: false,
    cellRenderer: TeamCellRenderer,
    hasTeams2,
    breakwords: true,
    enableRowGroup: false,
    cellEditorPopup: true,
    overrideCellValueChanged: hasTeams2,
  };

  if (hasTeams2) {
    return {
      ...commonDefs,
      onCellClicked: ({ event }) => {
        teams2LevelsDropdownAnchor.current = event.target;
      },
      cellEditor: MetadataTreeEditor,
      cellEditorParams: params => {
        return {
          inputRef: teams2LevelsDropdownAnchor.current,
          items: allTeams,
          onChange: onTeamChangeHandler(params),
          onClose: () => (teams2LevelsDropdownAnchor.current = null),
          hasClearSelection: true,
          hasMultiLevel: true,
          hasShowArchived: true,
          isAgGridCell: true,
        };
      },
    };
  }

  return {
    ...commonDefs,
    cellEditor: AutocompleteCellEditor,
    cellEditorParams: params => ({
      autoFocus: true,
      options: () => {
        const options = teamsTitles;

        if (params.colDef.filterSuggestions) {
          return params.colDef.filterSuggestions(options, params);
        }

        return options;
      },
      field: params.colDef.field,
    }),
    valueSetter: params => {
      params.data.team = {
        title: params.newValue ?? '',
      };

      return true;
    },
    suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
    comparator: stringComparator,
  };
};

const getSkillColumnDef = ({ editable, systemFields, skillsTitles }) => {
  const skillName = getSystemFieldName('skill', systemFields);

  return {
    field: 'skill.title',
    headerName: skillName,
    editable: applyCheckIsEditable(editable),
    cellRenderer: GroupCellRenderer(skillName),
    cellEditorPopup: true,
    cellEditor: AutocompleteCellEditor,
    cellEditorParams: params => ({
      autoFocus: true,
      options: () => {
        const options = skillsTitles;

        if (params.colDef.filterSuggestions) {
          return params.colDef.filterSuggestions(options, params);
        }

        return options;
      },
      field: params.colDef.field,
    }),
    valueSetter: params => {
      params.data.skill = {
        title: params.newValue ?? '',
      };

      return true;
    },
    breakwords: true,
    suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
    comparator: stringComparator,
  };
};

const getCapacityColumnDef = editable => ({
  field: 'capacity',
  headerName: 'Capacity',
  editable: applyCheckIsEditable(editable),
  filter: false,
  cellRenderer: CapacityCellRenderer,
  cellEditorPopup: true,
  cellEditor: DropdownCellEditor,
  cellEditorParams: {
    showEmptyOption: false,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
  keyCreator: ({ value }) => (!value || value === 'false' ? 'No' : 'Yes'),
  openByDefault: true,
  enableRowGroup: true,
  comparator: stringComparator,
  width: 100,
  maxWidth: 100,
});

const getUserTypeLabelFromGridParams = params => {
  const userType = UserTypeMapper.find(type => type.value === +params.value);

  return userType ? userType.label : '';
};

const getRoleColumDef = ({ editable, isPortalSettingsEnabled, isRoleAvailable }) => {
  const options = UserTypeMapper.filter(u => u.value !== OWNER_USER).filter(
    u => isRoleAvailable(u.value) && (isPortalSettingsEnabled || u.value !== EXTERNAL_REQUESTOR),
  );

  return {
    field: 'role_id',
    headerName: 'User Type',
    editable: applyCheckIsEditable(editable),
    filter: false,
    cellEditor: DropdownCellEditor,
    cellEditorPopup: true,
    cellEditorParams: {
      options,
    },
    keyCreator: getUserTypeLabelFromGridParams,
    valueFormatter: getUserTypeLabelFromGridParams,
    openByDefault: true,
    enableRowGroup: true,
    comparator: stringComparator,
    width: 100,
  };
};

const getStatusColumDef = ({ editable }) => ({
  field: 'status',
  headerName: 'Status',
  editable: applyCheckIsEditable(editable),
  filter: false,
  cellEditor: DropdownCellEditor,
  cellEditorPopup: true,
  cellEditorParams: {
    options: ['Active', 'Inactive'],
  },
  openByDefault: true,
  enableRowGroup: true,
  comparator: stringComparator,
  width: 100,
});

const getJiraColumnDef = ({ hide }) => ({
  field: 'jira_reference',
  headerName: 'Jira Reference',
  editable: false,
  filter: false,
  sortable: false,
  suppressMovable: true,
  cellRenderer: TextCellRenderer,
  comparator: stringComparator,
  cellStyle: {
    'text-align': 'center',
    'background-color': '#f8f8f8',
    color: 'rgba(0,0,0,0.6)',
  },
  valueFormatter: getJiraAccountId,
  hide,
});

const getIntegrationColumnDef = ({ hideUserIntegrationColumn, orgIntegrations }) => ({
  key: 'integrations',
  field: 'userIntegrations',
  headerName: 'Integration',
  editable: false,
  width: 100,
  hide: hideUserIntegrationColumn,
  cellRenderer: AgGridUserIntegrationIconsColumn,
  comparator: integrationComparator,
  allowedIntegrationTypes: [],
  getTooltip: getToolTipPerUserIntegrationType(orgIntegrations),
});

export {
  getFirstNameColumnDef,
  getLastNameColumnDef,
  getEmailColumnDef,
  getTeamsColumnDef,
  getSkillColumnDef,
  getCapacityColumnDef,
  getRoleColumDef,
  getStatusColumDef,
  getJiraColumnDef,
  getIntegrationColumnDef,
};
