/**
 * AppLogo Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';
// import Avatar from '@material-ui/core/Avatar';

import logo from './images/logo.svg';

export default class AppLogoLettering extends PureComponent {
  render() {
    return (
      <>
        <Image src={logo} />
      </>
    );
  }
}

const Image = styled.img`
  &&& {
    width: 201px;
    height: auto;
    margin-right: 10px;
    margin-left: 20px;
    margin-top: 12px;
  }
`;
