import { CREATE_METRIC_FROM_CANVAS_FULFILLED } from 'features/Canvas/store/types';
import { pickMetricFields } from 'features/Canvas/helpers/fieldPickers';
import { BULK_REMOVE_PROJECT_METRICS_FULFILLED, REMOVE_PROJECT_METRIC_FULFILLED } from 'store/projects';
import { UPDATE_METRIC_BY_ID_FULFILLED } from 'store/metrics/types';

import { initialState } from '../reducer';

import { projectsSelector, metricsSelector } from '../selectors';
import { APPLY_FILTERS_WHITEBOARD_FULFILLED, UPDATE_METADATA_ENTITIES_WHITEBOARD } from '../types';

import { metricsAdapter } from '../helpers/adapters';
import { filterProjectsMetrics, projectHasMetric } from '../helpers/entitiesFilters';

const processMetrics = (metrics, projects) => filterProjectsMetrics(metrics, projects).map(pickMetricFields);
const metricsReducer = (state = initialState, action) => {
  const { payload } = action;
  const storeProjects = projectsSelector.selectAll(state);
  const storeMetrics = metricsSelector.selectAll(state);

  switch (action.type) {
    case APPLY_FILTERS_WHITEBOARD_FULFILLED: {
      const { projects, metrics } = payload;

      if (!metrics?.length) return state;

      return {
        ...state,
        metrics: metricsAdapter.setAll(state?.metrics, processMetrics(metrics, projects)),
      };
    }
    case UPDATE_METADATA_ENTITIES_WHITEBOARD: {
      const { metrics } = payload;

      if (!metrics?.length) return state;

      return {
        ...state,
        metrics: metricsAdapter.setAll(state?.metrics, processMetrics(metrics, storeProjects)),
      };
    }
    case UPDATE_METRIC_BY_ID_FULFILLED: {
      const shouldAddToStore = storeProjects.some(project => projectHasMetric(project?.metrics, payload?.id));

      if (!shouldAddToStore) {
        return state;
      }

      return {
        ...state,
        metrics: metricsAdapter.upsertOne(state?.metrics, pickMetricFields(payload)),
      };
    }
    case REMOVE_PROJECT_METRIC_FULFILLED:
    case BULK_REMOVE_PROJECT_METRICS_FULFILLED: {
      if (!storeMetrics?.length) return state;

      return {
        ...state,
        metrics: metricsAdapter.setAll(state?.metrics, processMetrics(storeMetrics, storeProjects)),
      };
    }
    case CREATE_METRIC_FROM_CANVAS_FULFILLED: {
      const { meta: { metricCreatedFrom } = {} } = action;

      if (!payload?.id) return state;

      return {
        ...state,
        metrics: metricsAdapter.upsertOne(state?.metrics, pickMetricFields(payload)),
        userActionsMeta: metricCreatedFrom
          ? {
              entityCreatedFrom: metricCreatedFrom,
              entityId: payload?.id,
              type: 'metric',
            }
          : {},
      };
    }

    default:
      return state;
  }
};

export { metricsReducer };
