import React from 'react';

import { AUTOCOMPLETE_FROM_SERVER_DELAY } from 'constants';
import LoadableAutocomplete from 'design-system/molecules/LoadableAutocomplete/index';

import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';

export default props => {
  return <LoadableAutocomplete loadDelay={AUTOCOMPLETE_FROM_SERVER_DELAY} placeholder={TYPE_TO_SEARCH_PLACEHOLDER} {...props} />;
};
