import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import { pipe, isNil, not, prop, defaultTo } from 'ramda';

import ProjectLink from 'design-system/atoms/ProjectLink/index';


import useProjectAccessControl from 'hooks/useProjectAccessControl';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import { generateProjectOwnerOrganizationUrl } from '../../../utils/generateProjectOwnerOrganizationUrl';

const CHILD_ORGANIZATION_ID = 'child_organization_id';

const isNotNil = pipe(isNil, not);
const defaultToEmptyObject = defaultTo({});
const getChildOrganizationId = pipe(defaultToEmptyObject, prop(CHILD_ORGANIZATION_ID));

const DragonboatComponentV2 = ({ selectedProject, selectedOrganizationAccess }) => {
  const {
    shareProject,
    unshareProject,
    currentProjectReaders,
    isProjectReadersLoading,
    isAddProjectReadersLoading,
    isUninitialized,
    addProjectReadersError,
    currentProjectReadersOrgIds,
  } = useProjectAccessControl(selectedProject.id);
  const { childrenDragons } = useOrganizationsAccessControl();
  const { getReaderTokenByOrganizationId, fetchChildOrganizationReaderToken, getAccountNameAndUrlForOrganizationId } =
    useOrganizationsAccessControl();

  // this will be provided to the component in a different way in the future.
  // For now, we will rely on current implementation and orgIntegration
  const currentChildOrgId = getChildOrganizationId(selectedOrganizationAccess);

  const isCurrentlySharedWithOrg = useMemo(() => {
    if (isProjectReadersLoading || isUninitialized || isAddProjectReadersLoading) {
      return false;
    }

    const isShared = currentProjectReaders.find(projectReaders => projectReaders.organization_id === currentChildOrgId);

    return Boolean(isShared);
  }, [currentChildOrgId, currentProjectReaders, isProjectReadersLoading, isUninitialized, isAddProjectReadersLoading]);

  const shareWithOrganizationClick = useCallback(() => {
    if (currentChildOrgId) shareProject([currentChildOrgId]);
  }, [currentChildOrgId]);

  const handleUnshare = childOrgId => {
    if (childOrgId) unshareProject(childOrgId);
  };

  const currentlySharedWith = useMemo(
    () => childrenDragons.filter(childDragon => currentProjectReadersOrgIds.includes(childDragon.child_organization_id)),
    [childrenDragons, currentProjectReadersOrgIds],
  );

  const openProjectInChildOrganization = useCallback(
    organizationAccess => {
      const childOrganizationId = organizationAccess?.child_organization_id;
      const token = getReaderTokenByOrganizationId(childOrganizationId);

      if (isNotNil(token)) {
        const accountUrl = organizationAccess?.child_organization_url;
        const url = generateProjectOwnerOrganizationUrl(selectedProject.id, accountUrl, token);

        window.open(url, '_blank');

        fetchChildOrganizationReaderToken(childOrganizationId);
      }
    },
    [getReaderTokenByOrganizationId, fetchChildOrganizationReaderToken, selectedProject.id],
  );

  return isProjectReadersLoading || isUninitialized ? null : (
    <Wrapper>
      {!isCurrentlySharedWithOrg && isNotNil(currentChildOrgId) && (
        <ButtonContainer>
          <Button
            id="btn-dod-share-item"
            color="primary"
            onClick={shareWithOrganizationClick}
            disable={isAddProjectReadersLoading}
          >
            <AddIcon style={{ marginRight: 6, width: 16 }} />
            {isAddProjectReadersLoading ? 'Sharing...' : 'Share'}
          </Button>

          {addProjectReadersError && (
            <ErrorDiv item xs={12}>
              <ErrorSpan>{addProjectReadersError?.response?.data?.message}</ErrorSpan>
            </ErrorDiv>
          )}
        </ButtonContainer>
      )}

      {currentlySharedWith.length ? (
        <Grid container>
          {currentlySharedWith.map(organizationAccess => {
            const { accountName } = getAccountNameAndUrlForOrganizationId(organizationAccess?.child_organization_id);

            return (
              <OrganizationAccessContainer container>
                <Grid container xs={8} justify="flex-start">
                  <ProjectLink href={null} onClick={() => openProjectInChildOrganization(organizationAccess)}>
                    {accountName ?? organizationAccess?.code}
                  </ProjectLink>
                </Grid>
                <Grid container xs={4} justify="flex-end">
                  <Button color="primary" onClick={() => handleUnshare(getChildOrganizationId(organizationAccess))}>
                    Unshare
                  </Button>
                </Grid>
              </OrganizationAccessContainer>
            );
          })}
        </Grid>
      ) : null}
    </Wrapper>
  );
};

DragonboatComponentV2.propTypes = {
  selectedProject: PropTypes.object.isRequired,
  selectedOrganizationAccess: PropTypes.object,
};

export default DragonboatComponentV2;

const Wrapper = styled.div``;

const ButtonContainer = styled.div`
  &&&& {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    min-height: 88px;
  }
`;

const OrganizationAccessContainer = styled(Grid)`
  &&&& {
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
  }
`;

const ErrorDiv = styled(Grid)`
  &&&& {
    padding-top: 5px;
  }
`;

const ErrorSpan = styled.span`
  &&&& {
    color: red;
  }
`;
