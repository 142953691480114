import React, { useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getOrganization, getOrgJiraIntegrations } from 'store/organization/selectors';
import useColorLegend from 'hooks/timeline/useColorLegend';
import { showImportIdeasLightbox } from 'store/ideas';
import { removeGanttTolltip } from 'components/ganttCommon';
import { getDisplayLayer, getPageFilters, getUserFilters } from 'store/filters/selectors';
import { ZOOM_OPTIONS } from 'constants/timeline';
import { fetchProjectsDependencies } from 'store/dependencies/thunks';
import useSystemFields from 'hooks/useSystemFields';
import { JIRA_PROJECT_RENAMED_WARNING } from 'constants/integrations';
import getJiraIntegrationWarnings from 'utils/getJiraIntegrationWarnings';
import { setAppGlobalConfig } from 'store/app';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import { getGroupOptionsSelector } from 'store/projects/groupSelectors';
import useOneClickPlan from 'hooks/useOneClickPlan';
import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useScenariosListDialog from 'hooks/useScenariosListDialog/useScenariosListDialog';

import { getMoreDropdownOptions } from './helpers';
import useScenarioToolbarControls from 'containers/ScenarioToolbarControls/hooks/useScenarioToolbarControls';
import { SCENARIO_GANTT_PAGE } from 'constants/filters';
import useScenariosNavigation from 'hooks/useScenariosNavigation';
import useMetadataGroupByOptions from 'hooks/useMetadataGroupByOptions';

const componentHOC = Component => {
  return props => {
    const { localState, updateLocalState, pageId, updateLsState, lsState, showResourceDisplayOpt } = props;
    const dispatch = useDispatch();
    const [openPreferences, setOpenPreferences] = useState(null);
    const organization = useSelector(state => getOrganization(state));
    const displayLayer = useSelector(state => getDisplayLayer(state));
    const pageFilters = useSelector(state => getPageFilters(state));
    const userFilters = useSelector(state => getUserFilters(state));
    const [getSystemFieldName] = useSystemFields();
    const onChangeSetting = (setting, value) => updateLsState({ [setting]: value });
    const hasBet = organization.has_bet;
    const currentUser = useSelector(state => state.login.currentUser);
    const [getLegendGroups, updateGroupColor] = useColorLegend(pageId);
    const [showImportJsonDialog, setShowImportJsonDialog] = useState(false);
    const orgJiraIntegrations = useSelector(getOrgJiraIntegrations);
    const { isRoadmapVersionCommitted } = useRoadmapVersions();

    const { openScenariosDialog } = useScenariosListDialog();

    const { toggleOneClickPlanMode: openOneClickPlanMode } = useOneClickPlan();

    const toggleOneClickPlanMode = useCallback(() => {
      openOneClickPlanMode(true);
    }, [openOneClickPlanMode]);

    const { isCreatingOrViewingScenario, onClickExitDraftMode } = useScenarioToolbarControls();
    const [target, setTarget] = useState(null);

    const { has_rename_jira_project_enabled: hasRenameJiraProjectEnabled } = organization;
    const warning = hasRenameJiraProjectEnabled && getJiraIntegrationWarnings(orgJiraIntegrations, JIRA_PROJECT_RENAMED_WARNING);

    const groupingOptions = useMemo(() => ({ withNullOption: true, allowGroupByBetInPortfolioMode: true }), []);
    const groupByOptionsFromStore = useSelector(state => getGroupOptionsSelector(state, groupingOptions));

    const { groupByOptions } = useMetadataGroupByOptions(groupByOptionsFromStore);

    const groupByGroup2Options = groupByOptions
      .filter(o => (lsState.selectedGroup1?.key === 'initiative' ? o.key !== 'bet' : true))
      .filter(o => !o.key || o.key !== lsState.selectedGroup1?.key);

    const onGroupLevelChange = level => option => {
      const nullOption = groupByOptions.find(o => o.key === null);

      let selectedGroup1 = level === 'selectedGroup1' ? option : lsState.selectedGroup1;
      let selectedGroup2 = level === 'selectedGroup2' ? option : lsState.selectedGroup2;

      if (!selectedGroup1) {
        selectedGroup1 = nullOption;
        selectedGroup2 = nullOption;
      }
      if (!selectedGroup2) {
        selectedGroup2 = nullOption;
      }
      if (
        (selectedGroup1 && selectedGroup1.key === 'initiative' && selectedGroup2 && selectedGroup2.key === 'bet') ||
        selectedGroup2.key === selectedGroup1.key
      ) {
        selectedGroup2 = nullOption;
      }

      updateLsState({ selectedGroup1, selectedGroup2 });
    };
    const onDropdownChange = (e, value) => {
      switch (value.key) {
        case 'chart':
          updateLsState({ showAllocationReport: true }, false);
          break;
        case 'import':
          dispatch(showImportIdeasLightbox());
          break;
        case 'preferences':
          removeGanttTolltip();
          if (openPreferences) openPreferences();
          break;
        case 'collapseGanttBars':
          updateLocalState({ collapseGanttBars: !localState.collapseGanttBars });
          break;
        default:
          break;
      }
    };

    const { navigateToScenarioModule } = useScenariosNavigation();

    const toggleLocalMode = useCallback(() => {
      const localMode = isCreatingOrViewingScenario;

      if (!localMode) {
        navigateToScenarioModule(SCENARIO_GANTT_PAGE);
      } else {
        onClickExitDraftMode();
      }
    }, [isCreatingOrViewingScenario, currentUser]);

    const onAllocationReportClick = () => updateLsState({ showAllocationReport: true }, false);
    const changeSelectedZoom = () => {
      if (lsState.selectedZoom.id === 'week') {
        onChangeSetting(
          'selectedZoom',
          ZOOM_OPTIONS.find(g => g.id === 'month'),
        );
      } else if (lsState.selectedZoom.id === 'month') {
        onChangeSetting(
          'selectedZoom',
          ZOOM_OPTIONS.find(g => g.id === 'quarter'),
        );
      } else if (lsState.selectedZoom.id === 'quarter') {
        onChangeSetting(
          'selectedZoom',
          ZOOM_OPTIONS.find(g => g.id === 'day'),
        );
      } else if (lsState.selectedZoom.id === 'day') {
        onChangeSetting(
          'selectedZoom',
          ZOOM_OPTIONS.find(g => g.id === 'week'),
        );
      }
    };

    const dropdownOptions = getMoreDropdownOptions({
      ...lsState,
      ...localState,
      toggleLocalMode,
      currentUser,
      organization,
      onClickAutoSchedule: props.clickAutoSchedule,
      onClickImportJsonDialog: () => setShowImportJsonDialog(true),
      toggleOneClickPlanMode,
      pageId,
      isCreatingOrViewingScenario,
      openScenariosDialog,
    });
    const onShowDependenciesBtnClick = () => {
      if (!lsState.showAllDependencies) {
        const filtersForApiByLevels = compileFiltersBody(pageFilters, userFilters, hasBet, pageId, displayLayer);

        dispatch(fetchProjectsDependencies(filtersForApiByLevels));
      }
      updateLsState({ showAllDependencies: !lsState.showAllDependencies });
    };
    const applyDepTableState = state => {
      updateLsState({ depTableState: { ...(lsState.depTableState || {}), ...state } });
    };

    const _onWarningClick = () => {
      dispatch(setAppGlobalConfig({ showUpdateJiraKeyDialog: true }));
    };

    const updateShowUncommitted = showUncommittedProjects => {
      updateLsState({ showUncommittedProjects });
    };

    return (
      <Component
        {...props}
        lsState={lsState}
        groupByOptions={groupByOptions}
        groupByGroup2Options={groupByGroup2Options}
        onChangeSetting={onChangeSetting}
        onGroupLevelChange={onGroupLevelChange}
        getLegendGroups={getLegendGroups}
        updateGroupColor={updateGroupColor}
        currentUser={currentUser}
        moreDropdownOptions={dropdownOptions}
        onDropdownChange={onDropdownChange}
        setOpenPreferences={setOpenPreferences}
        changeSelectedZoom={changeSelectedZoom}
        updateLsState={updateLsState}
        displayLayer={displayLayer}
        onAllocationReportClick={onAllocationReportClick}
        onShowDependenciesBtnClick={onShowDependenciesBtnClick}
        getSystemFieldName={getSystemFieldName}
        showImportJsonDialog={showImportJsonDialog}
        setShowImportJsonDialog={setShowImportJsonDialog}
        applyDepTableState={applyDepTableState}
        showResourceDisplayOpt={showResourceDisplayOpt}
        warning={warning}
        onWarningClick={_onWarningClick}
        isRoadmapVersionCommitted={isRoadmapVersionCommitted}
        isCreatingOrViewingScenario={isCreatingOrViewingScenario}
        setTarget={setTarget}
        target={target}
        updateShowUncommitted={updateShowUncommitted}
        showDependenciesButton={props.showDependenciesButton}
      />
    );
  };
};

export default componentHOC;
