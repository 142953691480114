import { prop, equals, either } from 'ramda';
import { FILTERS_FUNCTIONS } from 'constants/filters';
import { getFilterKeyFromMetadataKey } from 'store/projects/helpers/groupMetadata';
import { GROUP_FIELD_TO_FILTER_MAPPING } from 'store/projects/constants';

const getKeyByValue = (object, value) => Object.keys(object).find(key => object[key] === value);

const getFilterIdsAndFilterOp = (pageFilters, key) => {
  const { fields } = pageFilters || {};
  const { [key]: filterIds, op } = fields || {};
  const { [key]: filterOp } = op || {};

  return { filterIds, filterOp };
};

const isNotIn = equals(FILTERS_FUNCTIONS.notIn.key);
const isIn = equals(FILTERS_FUNCTIONS.in.key);

const isChildOfExcludedObject = (parentFilterOp, isChildOfAFilteredObject) => isNotIn(parentFilterOp) && isChildOfAFilteredObject;
const isNotChildOfAnyIncludedObject = (parentFilterOp, isChildOfAFilteredObject) =>
  isIn(parentFilterOp) && !isChildOfAFilteredObject;
const checkIsExcluded = either(isChildOfExcludedObject, isNotChildOfAnyIncludedObject);

const getGroupParentFilterFunctionFactory = groupFilterFunctions => groupField => (groupFilterFunctions || {})[groupField];
const getGroupField = key => getKeyByValue(GROUP_FIELD_TO_FILTER_MAPPING, key);

/**
 * @function checkIsParentExcludedFromFilters
 * returns true if item is isntance of a metadata type that may have a parent
 * and if that parent is not included on the filters. if the current filter doesn't
 * have any condition for the parent entity it will consider the parent IS included.
 * Otherwise returns false
 *
 * @param {Object} item
 * @param {String} key
 * @param {Array<Object>} allMetadataEntities
 * @param {Object} groupFilterFunctions
 * @param {Object} pageFilters
 * @returns {Boolean}
 */
const checkIsParentExcludedFromFilters = (item, key, allMetadataEntities, groupFilterFunctions, pageFilters) => {
  const isUndefinedObject = !item.id;

  // Undefined object is never excluded by parent filter
  if (isUndefinedObject) return false;

  const getGroupParentFilterFunction = getGroupParentFilterFunctionFactory(groupFilterFunctions);
  const groupField = getGroupField(key);
  const filterByParentFunction = getGroupParentFilterFunction(groupField);
  const metadataTypeHasNoParentsToFilters = !filterByParentFunction;

  if (metadataTypeHasNoParentsToFilters) {
    return false;
  }

  let isParentExcluded = false;

  Object.entries(filterByParentFunction).forEach(([parentKey, filterFunction]) => {
    if (isParentExcluded) return;
    const { filterIds: parentFilterIds, filterOp: parentFilterOp } = getFilterIdsAndFilterOp(
      pageFilters,
      GROUP_FIELD_TO_FILTER_MAPPING[parentKey],
    );

    const noFilters = !Array.isArray(parentFilterIds) || !parentFilterIds?.length;

    if (noFilters) {
      return;
    }

    const parentFilterObjs = prop(GROUP_FIELD_TO_FILTER_MAPPING[parentKey], allMetadataEntities).filter(({ id }) =>
      (parentFilterIds || []).some(fid => +fid === +id),
    );

    const isChildOfAFilteredObject = parentFilterObjs.some(parent => filterFunction(item, parent));

    if (checkIsExcluded(parentFilterOp, isChildOfAFilteredObject)) {
      isParentExcluded = true;
    }
  });

  return isParentExcluded;
};

/**
 * @function tagMetadataOptionsIfExcludedByPageFilter
 * tags metadata with excludedFromFilter based on page filters, when applicable (contains / not contains)
 * by default supports objectives
 *
 * @param {*} allMetadataEntities
 * @param {*} pageFilters
 * @param {*} metadataKey for referencing filter
 * @param {*} groupFilterFunctions
 * @returns {Array<Object>}
 */
export default function tagMetadataOptionsIfExcludedByPageFilter(
  allMetadataEntities,
  pageFilters,
  metadataKey = 'objective',
  groupFilterFunctions,
) {
  const key = getFilterKeyFromMetadataKey(metadataKey);
  const metadataEntities = prop(metadataKey, allMetadataEntities);
  const { filterIds, filterOp } = getFilterIdsAndFilterOp(pageFilters, key);

  const filteredMetadata = metadataEntities.map(item => {
    const parentIsExcludedFromFilters = checkIsParentExcludedFromFilters(
      item,
      key,
      allMetadataEntities,
      groupFilterFunctions,
      pageFilters,
    );

    if (parentIsExcludedFromFilters) return { ...item, excludedFromFilter: true };

    const noFilters = !Array.isArray(filterIds) || !filterIds?.length;

    if (noFilters) {
      return item;
    }

    const includedInList = filterIds.some(filterId => String(filterId) === String(item.id));

    if (filterOp === FILTERS_FUNCTIONS.notIn.key) {
      return { ...item, excludedFromFilter: includedInList };
    }

    return { ...item, excludedFromFilter: !includedInList };
  });

  return filteredMetadata;
}
