export const TITLE_FIELD = 'title';
export const ISSUELINKS_FIELD = 'issuelinks';

// Default values for autosync
export const DEFAULT_DB_AUTOSYNC_FIELDS = [TITLE_FIELD, ISSUELINKS_FIELD];
export const DEFAULT_JIRA_AUTOSYNC_FIELDS = [TITLE_FIELD, ISSUELINKS_FIELD];

// Disabled fields by default for both sides, will override the two configurations bellow
export const DISABLE_DEFAULT_FIELDS = [];

// Disabled selection field by default on Dragonboat
export const DISABLE_DEFAULT_FIELDS_ON_DRAGONBOAT = [TITLE_FIELD];
export const DISABLE_DEFAULT_FIELDS_ON_JIRA = [];

// Disabled fields for autosync on Dragonboat by default
export const DISABLE_DEFAULT_FIELDS_DRAGONBOAT_AUTO_SYNC = [TITLE_FIELD];

// Disabled fields for autosync on Integration by default
export const DISABLE_DEFAULT_FIELDS_JIRA_AUTO_SYNC = [TITLE_FIELD];

// Not delatable fields
export const NOT_DELETABLE_FIELDS = [TITLE_FIELD];
