import React from 'react';
import { useDispatch } from 'react-redux';
import Popover from '@material-ui/core/Popover';

import theme from 'design-system/theme';
import { updateMetricById, metricActions } from 'store/metrics/actions';
import MetricValuesForm from '../MetricValuesForm';

const MetricChangeValuesPopover = ({ anchorEl, setAnchorEl, metric, canAddOrEdit }) => {
  const dispatch = useDispatch();
  const _onClose = () => {
    setAnchorEl(null);
  };

  const updateMetric = (metricId, update) => dispatch(updateMetricById(metricId, update));
  const updateMetricValue = (metricValueId, update) => dispatch(metricActions.updateMetricValue(metricValueId, update));
  const addMetricValue = metricValue => dispatch(metricActions.addMetricValue(metricValue));

  return (
    <Popover
      id="metric-values-popover"
      data-test="metric-values-popover"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      style={{ zIndex: theme.zIndex.gridPopover }}
      onClose={_onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MetricValuesForm
        metric={metric}
        canAddOrEdit={canAddOrEdit}
        updateMetric={updateMetric}
        updateMetricValue={updateMetricValue}
        addMetricValue={addMetricValue}
      />
    </Popover>
  );
};

export default MetricChangeValuesPopover;
