import { stringComparator } from 'design-system/utils/agGridComparators';

const integrationComparator = (integrationA, integrationB, node1, node2, isInverted) => {
  const keyA = integrationA?.key;
  const keyB = integrationB?.key;

  return stringComparator(keyA, keyB, node1, node2, isInverted);
};

export default integrationComparator;
