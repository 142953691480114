import React, { useState } from 'react';
import styled from 'styled-components';
import DialogActions from '@material-ui/core/DialogActions';

import Dialog from 'design-system/molecules/Dialog/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import PrimaryButton from 'design-system/atoms/PrimaryButton/index';

import OverviewVideoLightbox from 'components/OverviewVideoLightbox';
import BookDemoLightbox from 'components/BookDemoLightbox';

import Template1 from './icons/Template1';
import Template2 from './icons/Template2';
import Template3 from './icons/Template3';
import Template4 from './icons/Template4';
import Template5 from './icons/Template5';
import Template6 from './icons/Template6';
import VideoIcon from './icons/VideoIcon';

const mapIconsById = {
  1: <Template1 />,
  2: <Template2 />,
  3: <Template3 />,
  4: <Template4 />,
  5: <Template5 />,
  6: <Template6 />,
};

const OVERVIEW_VIDEO_PARAMETER = 'overviewVideo';
const LOADS_ON_INIT_PARAMETER = 'loadsOnInit';

export default ({ open, onClose, templates, disabled, applyTemplate, selectedTemplate }) => {
  const params = new URLSearchParams(window.location.search);
  const loadsOnInitParam = params.get(LOADS_ON_INIT_PARAMETER);

  const shouldInitOverviewVideo = loadsOnInitParam === OVERVIEW_VIDEO_PARAMETER;

  const [showOverviewVideo, setShowOverviewVideo] = useState(shouldInitOverviewVideo);
  const [showBookDemo, setShowBookDemo] = useState(false);

  return (
    <Wrapper>
      <TemplatesDialog
        open={!!open}
        width="1141px"
        onClose={onClose}
        closeButton
        closeButtonColor="#1F8F9E"
        footer={
          <Footer>
            <div style={{ flex: 'auto' }}>
              <PrimaryButton
                align="right"
                iconposition="left"
                variant="contained"
                bgcolor="#2861b1"
                textcolor="#FFF"
                onClick={() => setShowOverviewVideo(true)}
              >
                <VideoIcon style={{ marginRight: 10 }} />
                Watch a Video
              </PrimaryButton>
              <PrimaryButton
                align="right"
                variant="contained"
                bgcolor="#58CCC6"
                textcolor="#FFF"
                onClick={() => setShowBookDemo(true)}
              >
                Book a Demo
              </PrimaryButton>
            </div>
          </Footer>
        }
      >
        <HeaderContainer>
          <TextDeprecated variant="h5" component="h1">
            Select a Template and Get Rowing
          </TextDeprecated>
          <TextDeprecated size="medium" color="#445460">
            One platform for product alignment, prioritization, planning, tracking and communication
          </TextDeprecated>
        </HeaderContainer>
        <TemplatesGrid>
          {templates.map(template => (
            <TemplateOption
              key={template.id}
              id={`button-select-template-${template.id}`}
              onClick={() => applyTemplate(template.id)}
              selected={selectedTemplate === template.id}
              disabled={disabled}
            >
              <TemplateIcon>{mapIconsById[template.id]}</TemplateIcon>
              <TemplateInfo>
                <p>{template.name}</p>
              </TemplateInfo>
              {selectedTemplate === template.id && <SelectedFlag>Selected</SelectedFlag>}
            </TemplateOption>
          ))}
        </TemplatesGrid>
      </TemplatesDialog>
      <OverviewVideoLightbox open={showOverviewVideo} onClose={() => setShowOverviewVideo(false)} />
      <BookDemoLightbox open={showBookDemo} onClose={() => setShowBookDemo(false)} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const TemplatesDialog = styled(Dialog)`
  &&&& {
    > div[role='document'] > div {
      border-radius: 8px;
    }
  }
`;

const HeaderContainer = styled.div`
  text-align: center;
  margin-bottom: 24px;
  margin-top: 32px;

  h1 {
    margin-bottom: 10px;
    font-weight: bold;
  }
  p {
    font-size: 18px !important;
  }
`;

const TemplatesGrid = styled.div`
  margin: 0 auto;
  width: 957px;
  min-height: 390px;
`;

const TemplateOption = styled.div`
  min-width: 304px;
  max-width: 304px;
  height: 125px;
  margin: 7.5px;
  display: inline-flex;
  position: relative;
  padding: 0 22px;
  border-radius: 10px;
  align-content: center;
  align-items: center;
  border: 2px solid transparent;

  &:after {
    content: '';
    transition: background-color 0.4s ease-in-out;
  }

  ${({ disabled }) =>
    !disabled &&
    `
    cursor: pointer;

    &:hover:after {
      width: 100%;
      height: 100%;
      background: #58CCC6 0% 0% no-repeat padding-box;
      border-radius: 9px;
      opacity: 0.2;
      position: absolute;
      left: 0;
      right: 0;
    }
  `}

  ${({ selected }) =>
    selected &&
    `
    border: 2px solid #58CCC6;
  `}
`;

const TemplateIcon = styled.div`
  margin-left: 14px;
`;

const TemplateInfo = styled.div`
  text-align: left;
  margin-left: 32px;
  line-height: 21px;

  p {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 6px;
    color: #2861b1;
  }
  span {
    font-size: 14px;
    color: #445460;
  }
`;

const SelectedFlag = styled.div`
  background: #58ccc6;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  left: 18px;
  padding: 4px 12px 2px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

const Footer = styled(DialogActions)`
  &&&& {
    margin: 0;
    padding: 10px 16px 10px;
    background: #f3f3f3;
    height: 84px;
    justify-content: end;
  }
`;
