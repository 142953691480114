import reduceReducers from 'reduce-reducers';

import linkedPortfolioItemsReducer, {
  operationsReducer as linkedPortfolioItemsOperationsReducer,
  initialState as linkedPortfolioItemsInitialState,
} from '../LinkedPortfolioItems/store/reducer';

const initialState = {
  ...linkedPortfolioItemsInitialState,
  operations: {
    ...linkedPortfolioItemsInitialState.operations,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const operationsReducer = reduceReducers(initialState.operations, ...linkedPortfolioItemsOperationsReducer);

export default reduceReducers(initialState, reducer, linkedPortfolioItemsReducer, operationsReducer);
