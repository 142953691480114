import { useDispatch } from 'react-redux';

import usePortfolioOverview from '../../hooks/usePortfolioOverview';
import { updatePortfolioOverviewDescription } from '../../store';
import { useState } from 'react';

const useDescription = () => {
  const dispatch = useDispatch();
  const { portfolioOverview } = usePortfolioOverview();
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  const onOverviewDescriptonChange = text => {
    dispatch(updatePortfolioOverviewDescription(text));
  };

  const onOpenHistory = () => setIsHistoryOpen(true);
  const onCloseHistory = () => setIsHistoryOpen(false);

  return {
    data: portfolioOverview?.state?.description,
    isLoading: false,
    otherProps: {
      handleTextEditorUpdate: onOverviewDescriptonChange,
      onOpenHistory,
      onCloseHistory,
      isHistoryOpen,
    },
  };
};

export default useDescription;
