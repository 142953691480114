import React from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { getOrganization } from 'store/organization/selectors';
import useGetOktaQueryString from 'hooks/integrations/okta/useGetOktaQueryString';

export default Component => {
  return props => {
    // eslint-disable-next-line no-unused-vars
    const [qs, error, setQs, setError] = useGetOktaQueryString(window.location);
    const organization = useSelector(getOrganization);

    const clickNext = async (uri, clientId, clientSecret) => {
      try {
        const response = await axios.post('/api/integrations/okta', { uri, client_id: clientId, client_secret: clientSecret });

        window.location.href = response.data.uri;
      } catch (err) {
        setError(err.response.data.message || 'Invalid credentials');
      }
    };

    return (
      <Component
        clickNext={clickNext}
        orgIntegration={props.orgIntegration}
        hasOkta={organization.has_okta}
        error={error}
        userCanUpdateOrgIntegration={props.userCanUpdateOrgIntegration}
        userCanViewOrgIntegration={props.userCanViewOrgIntegration}
      />
    );
  };
};
