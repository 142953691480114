import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { defaultTo } from 'ramda';

import {
  bulkAddMetricsToKeyResult,
  bulkAddMetricsToObjective,
  bulkRemoveMetricsFromKeyResult,
  bulkRemoveMetricsFromObjective,
  createMetricAndAddToOkr as createMetricAndAddToOkrAction,
  OBJECT_OBJECTIVE,
} from 'store/objectives';
import { createMetric } from 'store/metrics';

const defaultToEmptyArray = defaultTo([]);
const mapObjectsToIds = objects => objects.map(obj => obj.id);

export default function useMultipleMetricsGridActions() {
  const dispatch = useDispatch();

  const bulkAddMetricsToOkr = useCallback(
    (type, okrId, metricIds) => {
      const action = type === OBJECT_OBJECTIVE ? bulkAddMetricsToObjective : bulkAddMetricsToKeyResult;

      return dispatch(action(okrId, metricIds));
    },
    [bulkAddMetricsToKeyResult, bulkAddMetricsToObjective],
  );

  const bulkRemoveMetricsFromOkr = useCallback(
    (type, okrId, metricIds) => {
      const action = type === OBJECT_OBJECTIVE ? bulkRemoveMetricsFromObjective : bulkRemoveMetricsFromKeyResult;

      return dispatch(action(okrId, metricIds));
    },
    [bulkRemoveMetricsFromKeyResult, bulkRemoveMetricsFromObjective],
  );

  const createMetricAndAddToOkr = useCallback(
    (okr, data, type) => {
      if (!okr?.id || !data?.name) return;

      return dispatch(createMetricAndAddToOkrAction(okr, data, type));
    },
    [createMetricAndAddToOkrAction],
  );

  const createNewMetric = useCallback(
    name => {
      if (!name) return;

      return dispatch(createMetric({ name }));
    },
    [createMetric],
  );

  const updateOkrMetrics = useCallback(
    (okrId, existingMetrics, okrType, fieldData) => {
      const { metrics: updatedMetrics } = fieldData;

      const newMetrics = updatedMetrics.filter(metric => !defaultToEmptyArray(existingMetrics).some(m => m.id === metric.id));
      const removedMetrics = existingMetrics.filter(metric => !defaultToEmptyArray(updatedMetrics).some(m => m.id === metric.id));

      if (newMetrics.length) {
        const metricIds = mapObjectsToIds(newMetrics);

        bulkAddMetricsToOkr(okrType, okrId, metricIds);
      }

      if (removedMetrics.length) {
        const metricIds = mapObjectsToIds(removedMetrics);

        bulkRemoveMetricsFromOkr(okrType, okrId, metricIds);
      }
    },
    [bulkAddMetricsToOkr, bulkRemoveMetricsFromOkr],
  );

  return {
    bulkAddMetricsToOkr,
    bulkRemoveMetricsFromOkr,
    createMetricAndAddToOkr,
    createNewMetric,
    updateOkrMetrics,
  };
}
