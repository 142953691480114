import { useCallback, useMemo, useState } from 'react';
import { isNil } from 'ramda';
import { EditorState, convertFromRaw, RichUtils, convertToRaw } from 'draft-js';

import { getEditorMentions } from '../helpers/getEditorMentions';

const getEditorStateInitialValue = draftjsEditorState => {
  if (isNil(draftjsEditorState)) {
    return EditorState.createEmpty();
  }

  return EditorState.createWithContent(convertFromRaw(draftjsEditorState));
};

const useDraftJsEditor = draftjsEditorState => {
  const [editor, setEditor] = useState(null);
  const [editorState, setEditorState] = useState(() => getEditorStateInitialValue(draftjsEditorState));

  const isEditorNotEmpty = useMemo(() => {
    return editorState && editorState.getCurrentContent().hasText();
  }, [editorState]);

  const onChangeEditorState = useCallback(
    newEditorState => {
      setEditorState(newEditorState);
    },
    [setEditorState],
  );

  const onEditorClick = useCallback(() => {
    if (editor) {
      editor.focus();
    }
  }, [editor]);

  const onSetEditorRef = useCallback(
    element => {
      setEditor(element);
    },
    [setEditor],
  );

  const handleKeyCommand = useCallback(
    (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);

      if (newState) {
        onChangeEditorState(newState);

        return 'handled';
      }

      return 'not-handled';
    },
    [onChangeEditorState],
  );

  const getEditorPayload = useCallback(() => {
    const currentState = editorState.getCurrentContent();

    const rawEditorState = convertToRaw(currentState);
    const plainText = currentState.getPlainText();

    const mentions = getEditorMentions(rawEditorState);

    return { plainText, mentions, editorState: rawEditorState };
  }, [editorState]);

  const resetEditorState = () => {
    setEditorState(getEditorStateInitialValue(draftjsEditorState));
  };

  return {
    editor,
    editorState,
    isEditorNotEmpty,
    handleKeyCommand,
    onChangeEditorState,
    onEditorClick,
    onSetEditorRef,
    getEditorPayload,
    resetEditorState,
  };
};

export default useDraftJsEditor;
