import React, { useMemo } from 'react';
import styled from 'styled-components';

import HomeBackground from 'design-system/atoms/HomeBackground';
import useUserViews from 'hooks/userViews/useUserViews';
import useUserTeamViews from 'hooks/userViews/useUserTeamViews';
import calculateFullScreenHeight from 'utils/calculateFullScreenHeight';
import formatUserViews from 'utils/userViews/formatUserViews';
import AllViews from './AllViews';
import AllDashboards from './AllDashboards';
import MyFavoriteViews from './MyFavoriteViews';
import ViewsByMyTeam from './ViewsByMyTeam';

const Home = () => {
  const { userViewsWithoutDashboardViews: userViews, userDashboards } = useUserViews();
  const { teamViews } = useUserTeamViews();

  const savedViews = useMemo(() => formatUserViews(userViews, []), [userViews]);

  const userHasSavedViews = savedViews.length > 0;
  const userTeamHasViews = teamViews.length > 0;
  const userHasDashboards = userDashboards.length > 0;

  const shouldRenderWidgetsGrid = userHasSavedViews || userTeamHasViews;

  return (
    <StyledHomeBackground data-testid="home-background">
      <ScrollWrapper>
        {shouldRenderWidgetsGrid && (
          <WidgetsGrid>
            <MyFavoriteViews />
            <ViewsByMyTeam />
          </WidgetsGrid>
        )}

        <AllViewsWrapper withMarginTop={userHasSavedViews}>
          {userHasDashboards && <AllDashboards />}
          <AllViews userHasSavedViews={userHasSavedViews} />
        </AllViewsWrapper>
      </ScrollWrapper>
    </StyledHomeBackground>
  );
};

const StyledHomeBackground = styled(HomeBackground)`
  height: 100%;
`;

const ScrollWrapper = styled.div`
  padding: 28px 28px 0;
  height: ${calculateFullScreenHeight()};
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.newLayout.background.athensGrey};
    border-radius: ${({ theme }) => theme.shape.borderRadiusExtraLarge}px;
  }
`;

const WidgetsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 56px;
  align-items: flex-start;

  @media only screen and (max-width: 1250px) {
    grid-template-columns: 1fr;
    grid-gap: 28px;
  }
`;

const AllViewsWrapper = styled.div`
  ${({ withMarginTop }) =>
    withMarginTop &&
    `
    margin-top: 26px;
  `}
`;

export default Home;
