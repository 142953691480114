import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getOrganization,
  hasMultiLevelPortfolioMetadata as hasMultiLevelPortfolioMetadataSelector,
} from 'store/organization/selectors';
import { updateSystemFieldsName as updateSystemFieldsNameAction } from 'store/organization';
import useSubscribeNewData from 'hooks/useSubscribeNewData';

const TOPICS_TO_SUBSCRIBE = ['timeframes', 'userFilters', 'userViews'];

const TimeframesHOC = Component => {
  return props => {
    const dispatch = useDispatch();

    const organization = useSelector(getOrganization);
    const hasMultiLevelPortfolioMetadata = useSelector(hasMultiLevelPortfolioMetadataSelector);

    useSubscribeNewData(TOPICS_TO_SUBSCRIBE);

    const updateSystemFieldsName = useCallback(
      fieldRename => dispatch(updateSystemFieldsNameAction(fieldRename)),
      [updateSystemFieldsNameAction],
    );

    const forceVisibleFields = useMemo(() => {
      if (Array.isArray(props.forceVisibleFields)) {
        return [...props.forceVisibleFields, 'date', 'end_date'];
      }
      return props.forceVisibleFields;
    }, [props.forceVisibleFields]);

    return (
      <Component
        {...props}
        organization={organization}
        systemFields={organization.system_fields_name}
        updateSystemFieldsName={updateSystemFieldsName}
        hideMetadataRoadmaps={!organization.enable_metadata_roadmaps}
        hasMultiLevelPortfolioMetadata={hasMultiLevelPortfolioMetadata}
        forceVisibleFields={forceVisibleFields}
      />
    );
  };
};

export default TimeframesHOC;
