import { getUserName } from 'utils/index';
import { TextCellRenderer } from '../cellRenderers';

const createdByUserColumnDef = {
  headerName: 'Created By',
  width: 110,
  cellRenderer: TextCellRenderer,
  editable: false,
  valueGetter: ({ node }) => {
    return getUserName(node.data.createdBy);
  },
};

export default createdByUserColumnDef;
