/**
 * Formats an array of timeframes ID's for the DndList (ie: {2: true, 4: { 5: true, 6: false }})
 * @param {Array} selectedTimeframes
 * @param {Array} timeframes
 */
export const formatTimeframesForDndList = (selectedTimeframes, timeframes) => {
  const result = {};

  selectedTimeframes.forEach(selectedTimeframe => {
    const parent = timeframes.find(timeframe => timeframe.children?.some(child => child.id?.toString() === selectedTimeframe));

    if (parent) {
      result[parent.id] = result[parent.id] || {};
      if (typeof result[parent.id] === 'object') {
        result[parent.id][selectedTimeframe] = true;
      }
    } else {
      result[selectedTimeframe] = true;
    }
  });

  return result;
};

/**
 * Merges L1 and L2 timeframes with Corp timeframes
 * @param {Array} corpTimeframes
 * @param {Array} timeframes
 */
export const mergeTimeframes = (corpTimeframes, timeframes) => {
  return corpTimeframes.map(corpTimeframe => ({
    ...corpTimeframe,
    children: corpTimeframe.children.map(corpTimeframeChild =>
      timeframes.find(timeframe => timeframe.id === corpTimeframeChild.id),
    ),
  }));
};
