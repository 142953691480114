import {
  UPDATE_SUMMARY_STATE,
  UPDATE_SUMMARY_COLUMN_STATE,
  EXPORT_SUMMARY,
  DEPENDENCY_LINES_TOGGLE,
  ALL_DEPENDENCIES_TOGGLE,
  ITEMS_WITHOUT_DEPENDENCIES_TOGGLE,
  UPDATE_SUMMARY_EXPANDED_GROUPS,
  UPDATE_SUMMARY_DISPLAY_FIELDS,
} from './types';
import exportPage from 'store/utils/factory/exportPage';

export const updateState = (state, slice, makesActiveViewDirty = true) => ({
  meta: { slice, makesActiveViewDirty },
  payload: state,
  type: UPDATE_SUMMARY_STATE,
});

export const updateDisplayFields = (state, slice) => {
  return { meta: { slice, makesActiveViewDirty: true }, payload: state, type: UPDATE_SUMMARY_DISPLAY_FIELDS };
};

export const updateColumnWidth = (state, slice) => {
  return {
    meta: { slice, makesActiveViewDirty: true },
    payload: state,
    type: UPDATE_SUMMARY_COLUMN_STATE,
  };
};

export const updateExpandedGroups = (payload, slice) => ({
  meta: { slice, makesActiveViewDirty: true },
  payload,
  type: UPDATE_SUMMARY_EXPANDED_GROUPS,
});

export const exportSummary = exportPage(EXPORT_SUMMARY, '/dashboard/summary/', ({ summary, _filters }) => ({
  summary,
  _filters,
}));

export const summaryActions = {
  updateState,
  exportSummary,
};

export const dependencyLinesToggleAction = slice => ({
  slice,
  type: DEPENDENCY_LINES_TOGGLE,
  meta: { makesActiveViewDirty: true },
});

export const allDependenciesToggleAction = slice => ({
  slice,
  type: ALL_DEPENDENCIES_TOGGLE,
  meta: { makesActiveViewDirty: true },
});

export const itemsWithoutDependenciesToggleAction = slice => ({
  slice,
  type: ITEMS_WITHOUT_DEPENDENCIES_TOGGLE,
  meta: { makesActiveViewDirty: true },
});
