import { useMemo, useCallback } from 'react';
import { defaultTo } from 'ramda';
import { useSelector } from 'react-redux';

import clickableHeaderWithBackgroundHOC from 'design-system/molecules/AgGridReact-New/helpers/clickableHeaderWithBackgroundHOC';
import { getDeliverableColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import { processColumnsGroup as processColumnsGroupUtil, processGroomedColumnDefs } from 'utils/grids/helpers';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { getProjectsDeliverables } from 'store/cycleDeliverables/selectors';

import { useIdeasGridColumnsDefinitions } from '../../IdeasList/IdeasList/New/hooks';
import useOrganizationCycleDeliverables from 'hooks/useOrganizationCycleDeliverables';

import useCycleDeliverableLightbox from './useCycleDeliverableLightbox';
import useProjectCycleDeliverableLightbox from './useProjectCycleDeliverableLightbox';

const defaultToEmptyArray = defaultTo([]);

const useCycleDeliverableColumnsDefinitions = ({
  portfolioMode,
  hasHierarchy,
  hasBet,
  hasKeyResults,
  hasKeyResults2,
  hasProducts,
  hasProducts2,
  hasMultiLevelMetadata,
  hasTeams2,
  topLayer,
  displayLayer,
  rowHeight,
  visibleColumnsIds,
  titleSearchString,
  customUserFields,
  columnsState,
  currentUser,
  integrations,
  customFields,
  organization,
  hasMetadataMultiSelect,
  isBulkDeleting,

  getSystemFieldName,
  addNewInlineProject,
  openProjectInfo,
  openRowInfo,
  loadParentProjectsOptions,
  checkUserCanCreateNewMetadata,
  updateProjectById,
  handleMetadataCellDoubleClick,
  createMetadataMultiSelectOption,
  removeGridRow,
  hasIdeas,
}) => {
  const { cycles } = useOrganizationCycleDeliverables();
  const { openCycleDeliverableLightbox } = useCycleDeliverableLightbox();
  const { openProjectCycleDeliverableLightbox } = useProjectCycleDeliverableLightbox();

  const projectsDeliverables = useSelector(getProjectsDeliverables);

  const { canUpdate, canCreate } = usePermissions();

  const cycleColumnsDefs = useMemo(() => {
    return cycles.map(cycle => ({
      headerName: cycle.title,
      field: `cycle_${cycle.id}`,
      headerClass: 'ag-header-cell-small-padding',
      headerGroupComponent: clickableHeaderWithBackgroundHOC(cycle.color, () => openCycleDeliverableLightbox(cycle.id)),
      children: defaultToEmptyArray(cycle.children).map(d => {
        const allowActionsFn = params => {
          const projectDeliverable = projectsDeliverables[params.data.id]?.find(pd => pd.cycle_deliverable_id === d.id);

          if (!projectDeliverable) {
            return canCreate(PERMISSION_RESOURCES.projectDeliverable);
          }

          return canUpdate(PERMISSION_RESOURCES.projectDeliverable, { data: projectDeliverable });
        };

        return getDeliverableColumnDef(
          cycle,
          d,
          openCycleDeliverableLightbox,
          openProjectCycleDeliverableLightbox,
          allowActionsFn,
        );
      }),
      menuTabs: [],
    }));
  }, [cycles, canUpdate]);

  const visibleColumnsIdsWithCycleDeliverableCols = useMemo(() => {
    if (columnsState) {
      return visibleColumnsIds;
    }

    const allCycleChildrenColumns = cycleColumnsDefs.reduce((acc, cycleColumnDef) => {
      return [...acc, ...defaultToEmptyArray(cycleColumnDef.children).map(c => c.field)];
    }, []);

    return [...visibleColumnsIds, ...allCycleChildrenColumns];
  }, [columnsState, visibleColumnsIds, cycleColumnsDefs]);

  const processColumnsGroup = useCallback(
    columns => processColumnsGroupUtil(columns, visibleColumnsIdsWithCycleDeliverableCols, columnsState),
    [visibleColumnsIdsWithCycleDeliverableCols, columnsState],
  );

  const {
    columnDefs: ideasGridColumnDefs,
    defaultColDef,
    groupColumnDef,
  } = useIdeasGridColumnsDefinitions({
    portfolioMode,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    topLayer,
    displayLayer,
    rowHeight,
    visibleColumnsIds,
    titleSearchString,
    customUserFields,
    currentUser,
    integrations,
    customFields,
    columnsState,
    hasMetadataMultiSelect,
    isBulkDeleting,

    getSystemFieldName,
    addNewInlineProject,
    openProjectInfo,
    openRowInfo,
    loadParentProjectsOptions,
    updateProjectById,
    handleMetadataCellDoubleClick,
    checkUserCanCreateNewMetadata,
    createMetadataMultiSelectOption,
    removeGridRow,
    hasIdeas,
  });

  const columnDefs = useMemo(
    () =>
      processGroomedColumnDefs(
        [
          ...ideasGridColumnDefs,

          // Cycle Deliverable columns
          ...processColumnsGroup(cycleColumnsDefs),
        ],
        columnsState,
      ),
    [ideasGridColumnDefs, cycleColumnsDefs, processColumnsGroup, columnsState],
  );

  return {
    columnDefs,
    defaultColDef,
    groupColumnDef,
  };
};

export default useCycleDeliverableColumnsDefinitions;
