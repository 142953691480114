import React, { forwardRef, memo, useRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { arrayOf, string, bool, oneOfType, object } from 'prop-types';
import Select from '@material-ui/core/Select';
import RootRef from '@material-ui/core/RootRef';

import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';


const AgGridDropdown = memo(
  forwardRef(({ value: originalValue = '', options = [], showEmptyOption = false, sort = true, stopEditing }, ref) => {
    const domRef = useRef(null);

    const [value, setValue] = useState(null);

    const handleOnChange = useCallback(event => {
      setValue(
        event.target.value instanceof Object && 'value' in event.target.value ? event.target.value.value : event.target.value,
      );
    });

    const sortOptions = useCallback(opt => {
      return opt.sort((a, b) => {
        const labelA = a.label ? a.label : a;
        const labelB = b.label ? b.label : b;

        return String(labelA).trim() > String(labelB).trim() ? 1 : -1;
      });
    }, []);

    useImperativeHandle(ref, () => ({
      getValue() {
        return value;
      },
    }));

    const valueToRender = useMemo(() => (value || originalValue)?.toString(), [value, originalValue]);

    useEffect(() => {
      setTimeout(() => {
        if (domRef.current) {
          domRef.current.childNodes[0].childNodes[0].focus();
          domRef.current.childNodes[0].childNodes[0].click();
        }
      }, 0);
    }, []);

    useEffect(() => {
      if (value != null) {
        stopEditing();
      }
    }, [value]);

    return (
      <RootRef rootRef={domRef}>
        <Select
          style={{
            padding: '15px',
            minWidth: '180px',
          }}
          value={valueToRender}
          onChange={handleOnChange}
        >
          {showEmptyOption && <StyledMenuItemDropdown value="" />}
          {(sort ? sortOptions(options) : options).map(item => {
            const label = item.label ? item.label : item;
            const value = item.value ? item.value : item;

            return (
              <StyledMenuItemDropdown key={value} value={value}>
                {label}
              </StyledMenuItemDropdown>
            );
          })}
        </Select>
      </RootRef>
    );
  }),
);

AgGridDropdown.displayName = 'AgGridDropdown';

AgGridDropdown.propTypes = {
  options: oneOfType([arrayOf(string), arrayOf(object)]),
  showEmptyOption: bool,
  sort: bool,
};

export default AgGridDropdown;
