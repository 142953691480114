import React, { createContext, useState, useContext, useCallback } from 'react';

import TeamDialog from 'features/EstimateByPoints/components/TeamDialog';

const TeamLightboxContext = createContext({});

const TeamLightboxProvider = ({ children }) => {
  const [openedTeam, setOpenedTeam] = useState();

  const openTeamLightbox = useCallback(data => setOpenedTeam(data), []);
  const closeTeamLightbox = () => setOpenedTeam(null);

  return (
    <TeamLightboxContext.Provider value={{ openTeamLightbox }}>
      {children}
      <TeamDialog key={openedTeam?.id} open={!!openedTeam} onClose={closeTeamLightbox} team={openedTeam} />
    </TeamLightboxContext.Provider>
  );
};

/**
 * @function useEstimatesLightboxContext
 *
 * hook with react context to persist the current opened estimates lightbox
 *
 * @return {Object}
 */
const useTeamLightboxContext = () => useContext(TeamLightboxContext);

export { TeamLightboxProvider };

export default useTeamLightboxContext;
