import { not } from 'ramda';

import { FILTERS_FUNCTIONS } from 'design-system/constants';

/**
 * @function hasMatchOnFilterOptionByOperation
 *
 * Check if filter option value has match based on filter operation
 *
 * @param  {Array} filterValue  - value of the filter option
 * @param  {String} objValue     - object value to match
 * @param  {String} operation   - filter operation (in, not in, is empty, ...)
 * @param {*} emptyValue       - empty value to use for isEmpty/isNotEmpty in case default value is not null
 * @return {Boolean}
 */
const hasMatchOnFilterOptionByOperation = (filterValue, objValue, operation, emptyValue = null) => {
  switch (operation) {
    case FILTERS_FUNCTIONS.notIn.key:
      return not(filterValue.includes(objValue));
    case FILTERS_FUNCTIONS.isEmpty.key:
      return objValue === emptyValue;
    case FILTERS_FUNCTIONS.isNotEmpty.key:
      return objValue !== emptyValue;
    default:
      return filterValue.includes(objValue);
  }
};

/**
 * @function hasTextMatchOnFilterOptionByOperation
 *
 * Check if filter option value has match based on filter operation for text filtering
 * This requires a different util since the includes is opposite
 *
 * @param  {Array} filterValue  - value of the filter option
 * @param  {String} objValue     - object value to match
 * @param  {String} operation   - filter operation (in, not in, is empty, ...)
 * @return {Boolean}
 */
const hasTextMatchOnFilterOptionByOperation = (filterValue, objValue, operation) => {
  switch (operation) {
    case FILTERS_FUNCTIONS.notIn.key:
      return not(objValue?.includes(filterValue));
    case FILTERS_FUNCTIONS.isEmpty.key:
      return objValue === null;
    case FILTERS_FUNCTIONS.isNotEmpty.key:
      return objValue !== null;
    default:
      return objValue?.includes(filterValue);
  }
};

export { hasMatchOnFilterOptionByOperation, hasTextMatchOnFilterOptionByOperation };
