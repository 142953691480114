import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import MuiListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import RightArrowIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import PageTitle from 'design-system/atoms/PageTitle/index';

import theme from 'design-system/theme';
import Preferences from './Preferences';
import Notifications from './Notifications';
import ChangePassword from './ChangePassword';

const PREFERENCES_TAB = 'preferences';
const NOTIFICATIONS_TAB = 'notifications';

const MenuListItem = ({ label, onClick, isActive }) => (
  <ListItem button key={label} onClick={onClick} style={{ paddingLeft: 26 }}>
    <ListItemText
      primary={label}
      primaryTypographyProps={{ style: { color: isActive && theme.palette.primary.main } }}
      style={{ paddingRight: 4 }}
    />
    <ListItemSecondaryAction style={{ right: 10, pointerEvents: 'none' }}>
      <RightArrowIcon style={{ color: theme.palette.primary.main }} />
    </ListItemSecondaryAction>
  </ListItem>
);

const UserPreferencesComponent = ({ history }) => {
  const [activeTab, setActiveTab] = React.useState(PREFERENCES_TAB);

  return (
    <Wrapper>
      <MenuWrapper>
        <List subheader={<ListHeader component="div">&nbsp;</ListHeader>} className="list">
          <MenuListItem
            label="My Preferences"
            isActive={activeTab === PREFERENCES_TAB}
            onClick={() => setActiveTab(PREFERENCES_TAB)}
          />
          <MenuListItem
            label="Notifications"
            isActive={activeTab === NOTIFICATIONS_TAB}
            onClick={() => setActiveTab(NOTIFICATIONS_TAB)}
          />
        </List>
      </MenuWrapper>
      <PreferencesWrapper>
        {activeTab === PREFERENCES_TAB && (
          <section>
            <PageTitle>My Preferences</PageTitle>
            <Preferences />
            <ChangePassword history={history} />
          </section>
        )}
        {activeTab === NOTIFICATIONS_TAB && (
          <>
            <section>
              <Notifications />
            </section>
          </>
        )}
      </PreferencesWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 68px);
`;

const PreferencesWrapper = styled.div`
  width: 900px;
  max-width: 100%;
  padding: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ListHeader = styled(ListSubheader)`
  &&&& {
    padding-left: 26px;
    line-height: 32px;
  }
`;

const ListItemText = styled(MuiListItemText)`
  &&&& {
    &:hover {
      color: ${({ theme }) => theme.palette.newLayout.background.primary};
    }
  }
`;

const MenuWrapper = styled.div`
  width: 230px;
  min-height: 100%;

  position: relative;
  border-right: 1px solid ${({ theme }) => theme.palette.newLayout.background.lightBlack};

  &&&& {
    span {
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      font-size: ${({ theme }) => theme.typography.fontSize}px;
      color: ${({ theme }) => theme.palette.newLayout.background.disable};
    }

    > .list {
      margin-left: 0;
      height: 100%;
      overflow-y: auto;
    }
  }
`;

UserPreferencesComponent.propTypes = {
  history: PropTypes.object,
};

export default UserPreferencesComponent;
