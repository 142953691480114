import React, { createContext, useState, useContext, useCallback } from 'react';

const buildGridContextState = (gridId, api, columnApi) => ({
  [gridId]: {
    api,
    columnApi,
  },
});

/**
 * Context for managing the Grid API.
 * @type {React.Context}
 */
const GridApiContext = createContext({});

/**
 * Context provider for the Grid API.
 * @param {Object} props - React component props.
 * @param {React.ReactNode} props.children - The child components to be wrapped by this provider.
 * @returns {React.ReactNode} - The wrapped child components with access to the Grid API context.
 */
const GridApiContextProvider = ({ children }) => {
  const [gridApi, setGridApi] = useState({});

  const registerGridApi = useCallback(
    (gridId, api, columnApi) => setGridApi(curState => ({ ...curState, ...buildGridContextState(gridId, api, columnApi) })),
    [],
  );

  const getGridApi = useCallback(
    gridId => {
      return gridApi[gridId]?.api;
    },
    [gridApi],
  );

  const getColumnApi = useCallback(
    gridId => {
      return gridApi[gridId]?.columnApi;
    },
    [gridApi],
  );

  return <GridApiContext.Provider value={{ getGridApi, getColumnApi, registerGridApi }}>{children}</GridApiContext.Provider>;
};

/**
 * Custom hook to access the Grid API context.
 * This hook should be used within components wrapped by `GridApiContextProvider`.
 *
 * @returns {Object} - An object containing the Grid API and the `defineGridApi` function.
 * @property {Function} getGridApi - A function to the Grid API for a specific grid.
 * @property {Function} getColumnApi - A function to get the Column API for a specific grid.
 * @property {Function} registerGridApi - A function to define the Grid API and Column API on a specific grid
 */
const useGridApiContext = () => useContext(GridApiContext);

export { GridApiContextProvider };

export default useGridApiContext;
