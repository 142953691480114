import React from 'react';
import styled from 'styled-components';
import { not } from 'ramda';

import AddProjectButton from 'components/AddProjectButton';
import ProjectsGlobalSearchInput from 'containers/ProjectsGlobalSearchInput';

export default ({ selectedView, setSelectedView, isParentDragon }) => {
  // todo: address this on styling task
  const gridTemplateColumns = not(isParentDragon) ? '30px 140px 1fr' : '140px 1fr';

  return (
    <>
      <Wrapper gridTemplateColumns={gridTemplateColumns}>
        <AddProjectButton />
        <ProjectsGlobalSearchInput />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
`;
