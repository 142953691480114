import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';

import useSystemFields from 'hooks/useSystemFields';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import Checkbox from 'design-system/atoms/Checkbox/index';
import Autocomplete from 'design-system/atoms/Autocomplete/index';


const FieldMapping = props => {
  const {
    mappingToDisplay,
    availableCRStatusSuggestions,
    availableProjectPhasesSuggestions,
    handleSendEmailChange,
    handleAddNewMapping,
    handleRemoveMapping,
  } = props;
  const [isAdding, setIsAdding] = useState(false);
  const [newMappingData, setNewMappingData] = useState({});
  const [getSystemFieldName] = useSystemFields();

  const handleAddClick = () => {
    setIsAdding(!isAdding);
    setNewMappingData({});
  };
  const handleSaveMappingClick = () => {
    handleAddNewMapping(newMappingData);
    setIsAdding(false);
    setNewMappingData({});
  };

  const _renderHeader = () => (
    <>
      <TextDeprecated size="medium" breakwords>
        When an {getSystemFieldName('initiative')}/{getSystemFieldName('idea')} {getSystemFieldName('phase')} changes, update:
      </TextDeprecated>
      <Grid container style={{ margin: '14px 0' }}>
        <Grid item xs={4}>
          <TextDeprecated variant="bold">
            {getSystemFieldName('initiative')}/{getSystemFieldName('idea')} {getSystemFieldName('phase')}
          </TextDeprecated>
        </Grid>
        <Grid item xs={4}>
          <TextDeprecated variant="bold">{getSystemFieldName('request')} status</TextDeprecated>
        </Grid>
        <Grid item xs={4}>
          <TextDeprecated variant="bold">Email {getSystemFieldName('request')} watcher?</TextDeprecated>
        </Grid>
      </Grid>
    </>
  );

  const _renderMapping = () =>
    mappingToDisplay.map(mappingEntry => (
      <Grid container key={mappingEntry.field} style={{ marginBottom: '15px', order: mappingEntry.rowOrder }}>
        <Grid item xs={3} justify="center">
          <TextDeprecated>{mappingEntry.projectStatusLabel}</TextDeprecated>
        </Grid>
        <Grid item xs={1} justify="center">
          <ArrowsText>→</ArrowsText>
        </Grid>
        <Grid item xs={4} justify="center">
          <TextDeprecated>{mappingEntry.customerRequestStatusLabel}</TextDeprecated>
        </Grid>
        <Grid container xs={4}>
          <Grid container xs={10} justify="center">
            <StyledCheckbox
              color="primary"
              checked={mappingEntry.sendEmail}
              onChange={() => handleSendEmailChange(mappingEntry.key)}
            />
          </Grid>
          <Grid item xs={2}>
            <StyledIconButton id="delete-mapping-button" onClick={() => handleRemoveMapping(mappingEntry.key)}>
              <DeleteIcon />
            </StyledIconButton>
          </Grid>
        </Grid>
      </Grid>
    ));

  const _renderAddNewMapping = () => (
    <Grid container style={{ marginBottom: '6px' }}>
      <Grid item xs={3} justify="center">
        <Autocomplete
          suggestions={availableProjectPhasesSuggestions}
          value={newMappingData.projectPhaseLabel}
          onValueChange={value => setNewMappingData({ ...newMappingData, projectPhaseLabel: value })}
          requireOption
        />
      </Grid>
      <Grid item xs={1} justify="center">
        <ArrowsText>→</ArrowsText>
      </Grid>
      <Grid item xs={4} justify="center">
        <Autocomplete
          suggestions={availableCRStatusSuggestions}
          value={newMappingData.customerRequestStatusLabel}
          onValueChange={value => setNewMappingData({ ...newMappingData, customerRequestStatusLabel: value })}
          requireOption
        />
      </Grid>
      <Grid container xs={4}>
        <Grid container xs={10} justify="center">
          <StyledCheckbox
            color="primary"
            onChange={() => setNewMappingData({ ...newMappingData, sendEmail: !newMappingData.sendEmail })}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const _renderActions = () => (
    <ActionsContainer>
      {isAdding ? (
        <ActionItem>
          <Button
            color="primary"
            onClick={handleSaveMappingClick}
            disabled={!newMappingData.projectPhaseLabel || !newMappingData.customerRequestStatusLabel}
          >
            Save
          </Button>
        </ActionItem>
      ) : null}
      <ActionItem>
        <Button color={isAdding ? 'secondary' : 'primary'} onClick={handleAddClick}>
          {isAdding ? 'Cancel' : '+ Add mapping'}
        </Button>
      </ActionItem>
    </ActionsContainer>
  );

  return (
    <Wrapper>
      {_renderHeader()}
      <MappingEntries>{_renderMapping()}</MappingEntries>
      {isAdding && _renderAddNewMapping()}
      {_renderActions()}
    </Wrapper>
  );
};

FieldMapping.propTypes = {
  mappingToDisplay: PropTypes.array,
  availableCRStatusSuggestions: PropTypes.array,
  availableProjectPhasesSuggestions: PropTypes.array,
  handleAddNewMapping: PropTypes.func,
  handleRemoveMapping: PropTypes.func,
  handleSendEmailChange: PropTypes.func,
};

const Wrapper = styled.div`
  position: relative;
  width: 650px;
  margin-top: 30px;
`;

const ArrowsText = styled(TextDeprecated)`
  &&&& {
    line-height: 8px;
    display: inline-flex;
    align-items: center;
    height: 20px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
`;

const ActionItem = styled.div``;

const StyledCheckbox = styled(Checkbox)`
  &&&& {
    padding: 0;
    align-items: flex-start;
  }
`;

const StyledIconButton = styled(IconButton)`
  &&&& {
    padding: 0;
    align-items: flex-start;
    justify-content: flex-end;
  }
`;

const MappingEntries = styled.div`
  &&&& {
    display: flex;
    flex-direction: column;
  }
`;

export default FieldMapping;
