import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { not } from 'ramda';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

import { getOrganization } from 'store/organization';
import { transferProjectToOtherAccount } from 'store/projects';
import { getAllRoadmaps } from 'store/roadmaps/selectors';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

export default function useCrossAccountRoadmapChange() {
  const dispatch = useDispatch();
  const { organizationAccessControlData } = useOrganizationsAccessControl();
  const { canUpdate } = usePermissions();
  const organization = useSelector(getOrganization);
  const roadmaps = useSelector(getAllRoadmaps);

  const getOrgIdForCorpRoadmapId = useCallback(
    corpRoadmapId => {
      const portfolioAccountForCorpRoadmapId = organizationAccessControlData.find(
        dodAccount => dodAccount.portfolio_roadmap_id === corpRoadmapId,
      );

      return portfolioAccountForCorpRoadmapId?.child_organization_id;
    },
    [organizationAccessControlData],
  );

  const getRoadmapDataFromRoadmapTitlesChanged = useCallback(
    (roadmapTitle, product1Title, product2Title) => {
      const dataFound = {
        targetAccountOrgId: null,
        corpRoadmapId: null,
        roadmapId: null,
        product1Id: null,
        product2Id: null,
      };

      if (product2Title) {
        const roadmap = roadmaps.find(r => {
          const product1 = r.products.find(p => {
            const product2 = p.products.find(p2 => p2.title === product2Title);

            if (product2) {
              dataFound.product2Id = product2.id;
            }

            return product2;
          });

          if (product1) {
            dataFound.product1Id = product1.id;
          }

          return product1;
        });

        if (roadmap) {
          dataFound.roadmapId = roadmap.id;
          dataFound.corpRoadmapId = roadmap.parent_id;
        }

        dataFound.targetAccountOrgId = getOrgIdForCorpRoadmapId(roadmap?.parent_id);

        return dataFound;
      }

      if (product1Title) {
        const roadmap = roadmaps.find(r => {
          const product1 = r.products.find(p => p.title === product1Title);

          if (product1) {
            dataFound.product1Id = product1.id;
          }

          return product1;
        });

        if (roadmap) {
          dataFound.roadmapId = roadmap.id;
          dataFound.corpRoadmapId = roadmap.parent_id;
        }

        dataFound.targetAccountOrgId = getOrgIdForCorpRoadmapId(roadmap?.parent_id);

        return dataFound;
      }

      const roadmap = roadmaps.find(r => r.title === roadmapTitle);

      if (roadmap) {
        dataFound.roadmapId = roadmap.id;
        dataFound.corpRoadmapId = roadmap.parent_id;
      }

      dataFound.targetAccountOrgId = getOrgIdForCorpRoadmapId(roadmap?.parent_id);

      return dataFound;
    },
    [roadmaps, getOrgIdForCorpRoadmapId],
  );

  const isUpdateAnAccountTransfer = useCallback(
    projectUpdate => {
      if (not(canUpdate(PERMISSION_FEATURES.crossBabyRoadmapSupport))) {
        return false;
      }

      const { roadmapTitle, product1Title, product2Title } = projectUpdate;

      const { targetAccountOrgId } = getRoadmapDataFromRoadmapTitlesChanged(roadmapTitle, product1Title, product2Title);

      return targetAccountOrgId && targetAccountOrgId !== organization.id;
    },
    [getRoadmapDataFromRoadmapTitlesChanged, canUpdate, organization.id],
  );

  const transferProjectToOtherAccountFromProjectUpdate = useCallback(
    (projectId, projectUpdate) => {
      if (!isUpdateAnAccountTransfer(projectUpdate)) {
        return;
      }

      const { roadmapTitle, product1Title, product2Title } = projectUpdate;

      const { targetAccountOrgId, corpRoadmapId, roadmapId, product1Id, product2Id } = getRoadmapDataFromRoadmapTitlesChanged(
        roadmapTitle,
        product1Title,
        product2Title,
      );

      dispatch(
        transferProjectToOtherAccount(projectId, targetAccountOrgId, {
          corpRoadmapId,
          roadmapId,
          product1Id,
          product2Id,
        }),
      );
    },
    [isUpdateAnAccountTransfer, getRoadmapDataFromRoadmapTitlesChanged],
  );

  return {
    isUpdateAnAccountTransfer,
    transferProjectToOtherAccountFromProjectUpdate,
  };
}
