import React, { Fragment } from 'react';
import ProjectTaskDialog from 'containers/ProjectTaskDialog';

const TimelineTasks = React.memo(({ setOpenTaskFn }) => (
  <Fragment>
    <ProjectTaskDialog
      onOpen={(fn) => setOpenTaskFn(() => fn)}
      onlyTask
    />
  </Fragment>
));

export default TimelineTasks;