import React from 'react';
import styled from 'styled-components';

import DragonAvatar from 'design-system/atoms/DragonAvatar/index';

import { getCurrentUser } from 'store/login/selectors';
import { useSelector } from 'react-redux';
import { getUserName, getUserShortName } from 'utils';
import history from 'store/utils/history';
import { getUserRoleLabel } from 'utils/UserTypeMapper';

const getUserTeamTitle = user => {
  return `${user.team ? `(${user.team.title} Team) ` : ''}`;
};

const HeaderFilters = () => {
  const user = useSelector(getCurrentUser);

  const profile = {
    label: getUserName(user),
    short: getUserShortName(user),
    description: `${getUserTeamTitle(user)}${getUserRoleLabel(user.role_id)}`,
  };
  const onNavigate = () => {
    localStorage.setItem('closeLeftMenu', 'true');

    history.push('/user/preferences');
  };

  return (
    <Wrapper>
      <UserAvatar id="user-avatar" {...profile} nameHighlighted onClick={() => onNavigate(profile)} />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const UserAvatar = styled(DragonAvatar)`
  &&&& {
    padding-left: 0;
    border-top: none;
    margin: 0;
    width: 100%;
  }
`;

export default HeaderFilters;
