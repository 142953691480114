// External dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

// dragonboat dependencies
import LightBox from 'components/LightBox';
import CloseButton from 'components/DialogTitle/CloseButton';

class ImportJsonDialog extends Component {
  render() {
    const {
      currentProjectsState,
      importNewProjectsState,
      newProjectsState,
      setNewProjectsState,
      showImportJsonDialog,
      setShowImportJsonDialog,
    } = this.props;

    return (
      <LightBox
        isOpen={showImportJsonDialog}
        onClose={() => setShowImportJsonDialog(false)}
        fullWidth
        maxWidth="sm"
        content={
          <Wrapper>
            <CloseButton onClick={() => setShowImportJsonDialog(false)} />
            <DialogContent>
              <DialogTitle>Export/Import Projects</DialogTitle>
              <StyledBold>Current state:</StyledBold>
              <JsonOutput>{JSON.stringify(currentProjectsState, null, 2)}</JsonOutput>
              <StyledBold>New state:</StyledBold>
              <StyledTextarea
                rows={10}
                onChange={e => setNewProjectsState(e.target.value)}
                data-gramm_editor="false"
                placeholder="Paste the state you wish to import here"
              />
              <Flex>
                <Button color="primary" onClick={() => importNewProjectsState(newProjectsState)}>
                  Import
                </Button>
                <Button onClick={() => setShowImportJsonDialog(false)}>Cancel</Button>
              </Flex>
            </DialogContent>
          </Wrapper>
        }
      />
    );
  }
}

const Wrapper = styled.div`
  max-width: 700px;
`;

const JsonOutput = styled.pre`
  background-color: #eee;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 32px;
  max-height: 200px;
  overflow: auto;
`;

const DialogContent = styled.div``;

const DialogTitle = styled.h4`
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 1rem;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 0 0%;
`;

const StyledBold = styled.p`
  font-weight: bold;
  color: black;
  margin-bottom: 8px;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 32px;
  resize: none;
`;

ImportJsonDialog.defaultProps = {
  currentProjectsState: PropTypes.array.isRequired,
  newProjectsState: PropTypes.array.isRequired,
  setNewProjectsState: PropTypes.func.isRequired,
  importNewProjectsState: PropTypes.func.isRequired,
  setShowImportJsonDialog: PropTypes.func.isRequired,
  showImportJsonDialog: PropTypes.bool.isRequired,
};

export default ImportJsonDialog;
