import React from 'react';
import './chatbot.css';

import { ThemeProvider } from 'styled-components';
import Avatar from '@material-ui/core/Avatar';

import ChatBot from 'react-simple-chatbot';
import DragonbotIcon from 'design-system/atoms/DragonbotIcon/index';

import chatbotTheme from './dragonbot.theme';
import ChatWindowHeaderComponent from './components/ChatWindowHeaderComponent';
import { DRAGONBOT_HISTORY_CACHE_STORAGE_KEY } from 'store/dragonbot/constants';
import DragonbotHelpWindow from './components/DragonbotHelpWindow';

export default ({
  dragonbotSteps,
  dragonbotOpen,
  onHeaderOptionClicked,
  onChatbotRef,
  toggleDragonbot,
  enableCache,
  onToggleHelpWindow,
  helpWindowOpen,
}) => {
  const ChatHeaderComponent = (
    <ChatWindowHeaderComponent
      handleCloseDragonbotClick={toggleDragonbot}
      handleOptionClicked={onHeaderOptionClicked}
      handleToggleHelpWindow={onToggleHelpWindow}
    />
  );

  return (
    <ThemeProvider theme={chatbotTheme}>
      <div>
        <ChatBot
          botDelay={1500}
          headerComponent={ChatHeaderComponent}
          contentStyle={{
            paddingLeft: '4px',
            overflowY: 'auto',
            height: '91%',
            paddingBottom: '3em',
            marginTop: 0,
          }}
          bubbleStyle={{
            lineHeight: '1.4em',
            maxWidth: '65%',
            marginBottom: '1.5em',
          }}
          avatarStyle={{
            height: '50px',
            boxShadow: 'none',
          }}
          cache={enableCache}
          cacheName={DRAGONBOT_HISTORY_CACHE_STORAGE_KEY}
          bubbleOptionStyle={{
            cursor: 'pointer',
            border: `1px solid rgb(67, 190, 223)`,
            marginBottom: '10px',
          }}
          botAvatar={DragonbotIcon}
          opened={dragonbotOpen}
          floating
          floatingIcon={<Avatar src={DragonbotIcon} style={{ height: '100px', width: '100px' }} />}
          footerStyle={{
            width: '100%',
            position: 'absolute',
            bottom: 0,
          }}
          floatingStyle={{ background: 'none', boxShadow: 'none', height: 'auto', width: 'auto' }}
          toggleFloating={toggleDragonbot}
          steps={dragonbotSteps}
          headerTitle="Dragon Bot"
          hideUserAvatar
          placeholder="Type your question here"
          ref={el => onChatbotRef(el)}
          width="480px"
          height="85%"
        />
        {dragonbotOpen && helpWindowOpen && <DragonbotHelpWindow onClose={onToggleHelpWindow} />}
      </div>
    </ThemeProvider>
  );
};
