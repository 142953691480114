/**
 * Dialog Molecule Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import { omit } from 'ramda';
import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import Drawer from '@material-ui/core/Drawer';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import { textColor } from 'design-system/themes/default';
import { removeElemProps } from 'design-system/utils';
import theme, { spacing } from 'design-system/theme';

const OKR_DRAWER_ID = 'objectives-lightbox';
const TITLE_KEY = 'title';

export default class ModalDialog extends PureComponent {
  render() {
    const {
      actions,
      children,
      closeButton,
      closeButtonColor,
      customHeaderBackgroundColor,
      footer,
      header,
      onClose,
      subtitle,
      textColorOverride,
      title,
      type,
      headerCustomStyles,
      contentCustomStyles,
      fullScreenOnMobile,
      actionsCustomProps = {},
      subHeader = null,
    } = this.props;

    const body = (
      <React.Fragment>
        {title && (
          <Header backgroundColor={customHeaderBackgroundColor}>
            {title && <Title textColorOverride={textColorOverride}>{title}</Title>}
            {subtitle && <SubTitle textColorOverride={textColorOverride}>{subtitle}</SubTitle>}
          </Header>
        )}
        {closeButton && (
          <CloseButton color={closeButtonColor} onClick={onClose} data-cy="close-button" data-test="close-button">
            <CloseIcon />
          </CloseButton>
        )}
        {header && (
          <Header backgroundColor={customHeaderBackgroundColor} headerCustomStyles={headerCustomStyles}>
            {header}
          </Header>
        )}
        {subHeader}
        <Content contentCustomStyles={contentCustomStyles} fullScreenOnMobile={fullScreenOnMobile}>
          {children}
        </Content>
        {actions && <Actions {...actionsCustomProps}>{actions}</Actions>}
        {footer}
      </React.Fragment>
    );

    if (!type || type !== 'DRAWER') {
      return <DialogWrapper {...omit([TITLE_KEY], this.props)}>{body}</DialogWrapper>;
    }

    return (
      <DrawerWrapper anchor="right" {...omit([TITLE_KEY], this.props)}>
        {body}
      </DrawerWrapper>
    );
  }
}

const DialogWrapper = styled(removeElemProps(Dialog, ['fullHeight']))`
  &&&& {
    ${({ zIndex }) => zIndex && `z-index: ${zIndex} !important;`}
    > div[role='document'] > div {
      ${({ fullHeight }) =>
        fullHeight &&
        `
        min-height: calc(100% - 96px);
      `}
      ${({ width }) =>
        width &&
        `
        max-width: ${width};
        width: ${width};
      `}

            ${({ fullScreenOnMobile }) =>
        fullScreenOnMobile &&
        `
        @media screen and (max-width: 1024px) {
          width: 100%;
          max-width: 100%;
          height: 100%;
          max-height: 100%;
          margin: 0;
          border-radius: 0;
        }
      `}
    }

    ${({ withoutBackdrop }) =>
      withoutBackdrop &&
      `
      > div:nth-child(1) {
        background-color: transparent;
      }
    `}
  }
`;

const DrawerWrapper = styled(removeElemProps(Drawer, ['fullHeight', 'fullWidth']))`
  &&&& {
    ${({ id }) =>
      id &&
      id === OKR_DRAWER_ID &&
      css`
        z-index: ${theme.zIndex.okrDrawer} !important;
      `};

    > div[role='document'] {
      ${({ fullWidth }) =>
        fullWidth &&
        `
        width: 70%;
      `}
      height: ${({ headerHeight }) => (headerHeight ? `calc(100% - ${headerHeight})` : `calc(100% - ${spacing(6)}px)`)};
      margin-top: ${({ headerHeight }) => headerHeight || `${spacing(6)}px`};

      ${({ fullHeight }) =>
        fullHeight &&
        css`
          height: 100%;
          margin-top: 0;
        `};

      ${({ matchOldLightboxHeight }) =>
        matchOldLightboxHeight &&
        css`
          height: calc(100% - 96px); // same height than project lightbox
          margin-top: ${spacing(6)}px;
        `};
    }

    > div:nth-child(1) {
      ${({ withoutBackdrop }) =>
        withoutBackdrop &&
        `
      background-color: transparent;
    `}
      height: ${({ headerHeight }) => (headerHeight ? `calc(100% - ${headerHeight})` : `calc(100% - ${spacing(6)}px)`)};
      margin-top: ${({ headerHeight }) => headerHeight || `${spacing(6)}px`};

      ${({ fullHeight }) =>
        fullHeight &&
        css`
          height: 100%;
          margin-top: 0;
        `};
    }

    ${({ zIndex }) => zIndex && `z-index: ${zIndex} !important;`}
    width: 60%;
  }
`;

export const Header = styled.div`
  padding: ${spacing(3)}px ${spacing(3)}px ${spacing()}px;
  display: flex;
  box-shadow: 0px 2px 12px 0px rgba(204, 204, 204, 0.3);
  z-index: 1;
  background-color: ${props => props.backgroundColor};

  ${({ headerCustomStyles }) => headerCustomStyles && headerCustomStyles}
`;

const Content = styled(DialogContent)`
  &&&& {
    padding: ${spacing(3)}px;

    ${({ fullScreenOnMobile }) =>
      fullScreenOnMobile &&
      css`
        @media screen and (max-width: 1024px) {
          padding: ${spacing(2)}px;
        }
      `}

    ${({ contentCustomStyles }) => contentCustomStyles && contentCustomStyles}
  }
`;

const Actions = styled(DialogActions)`
  &&&& {
    margin: 0;
    padding: 10px ${spacing(2)}px 10px;
    border-top: 1px solid #fafafa;
    box-shadow: 0px -2px 12px 0px rgba(204, 204, 204, 0.3);
    /* z-index: 9999999; */
  }
`;

const Title = styled(DialogTitle)`
  &&&& {
    padding: 0;

    h6 {
      color: ${props => props.textColorOverride || textColor};
    }
  }
`;

const SubTitle = styled.span`
  &&&& {
    padding: 0;
    color: ${props => props.textColorOverride || textColor};
    font-size: 12px;
    margin: 0 ${spacing()}px;
    align-self: center;
  }
`;

const CloseButton = styled(ButtonIcon)`
  &&&& {
    position: absolute;
    top: ${spacing()}px;
    right: 15px;
    z-index: 10000;

    ${({ color }) =>
      color &&
      `
      svg {
        color: ${color};
      }
    `}
  }
`;
