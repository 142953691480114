import React from 'react';
import styled from 'styled-components';

import MicroChip from 'design-system/atoms/MicroChip/index';


const Wrapper = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

const MultiChipCellRenderer = ({ items }) => {
  const fullWidth = items.length < 2;

  return (
    <Wrapper>
      {items.map(item => {
        return <MicroChip key={item.id} label={item.label} color={item.color} fullWidth={fullWidth} />;
      })}
    </Wrapper>
  );
};

export default MultiChipCellRenderer;
