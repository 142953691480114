import React, { useMemo } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export default ({ error, totalSalesforceCustomers }) => {
  const importText = useMemo(() => {
    if (error) {
      return error;
    }

    return `${totalSalesforceCustomers} Accounts are being imported. Close this window and check back later for imported items.`;
  }, [error, totalSalesforceCustomers]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <TextContainer>{importText}</TextContainer>
        </Grid>
      </Grid>
    </div>
  );
};

const TextContainer = styled.div`
  padding: 30px 0;
`;
