import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCustomerRequestsInsightsForCounterPieChart } from 'store/customerRequests/thunks';
import {
  getFiltersCompiled,
  getInsightsDataForCounterPieChart,
  isUninitializedInsightsDataForCounterPieChart,
  getSelectedCounterPieChartGroupBy,
} from 'store/customerRequests/selectors';
import { setSelectedCounterPieChartGroupBy } from 'store/customerRequests';

import isNumeric from 'utils/isNumeric';
import normalizeArray from 'utils/normalizeArray';
import { UNDEFINED_LABEL } from 'constants/common';
import { DEFAULT_UNDEFINED_COLOR } from 'constants/charts';
import useGroupsData from '../hooks/useGroupsData';
import { onCounterPieChartEntityClick } from '../store';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const selectedGroupBy = useSelector(getSelectedCounterPieChartGroupBy);
    const getGroupData = useGroupsData();
    const filters = useSelector(getFiltersCompiled);
    const insightsData = useSelector(getInsightsDataForCounterPieChart);
    const isInsightsDataUninitialized = useSelector(isUninitializedInsightsDataForCounterPieChart);

    const _handleGroupedByChange = React.useCallback(value => dispatch(setSelectedCounterPieChartGroupBy(value)), []);

    React.useEffect(() => {
      dispatch(fetchCustomerRequestsInsightsForCounterPieChart(selectedGroupBy.key, filters));
    }, [selectedGroupBy.key, filters]);

    const chartData = React.useMemo(() => {
      if (isInsightsDataUninitialized || !insightsData) return {};

      const normalizedDataByGroup = normalizeArray(insightsData, selectedGroupBy.key);

      const groupEntities = getGroupData(selectedGroupBy.key);

      let allGroupByEntities = Object.keys(normalizedDataByGroup)
        .map(i => groupEntities[i])
        .filter(i => !!i);
      const _getItemId = item => {
        if (item?.key || isNumeric(item?.key)) return item.key;

        return item?.id || null;
      };

      allGroupByEntities = allGroupByEntities
        .map(entity => ({ ...entity, count: normalizedDataByGroup[_getItemId(entity)]?.count || 0 }))
        .sort((a, b) => b.count - a.count);

      return {
        labels: allGroupByEntities.map(e => e?.title || e?.name || UNDEFINED_LABEL),
        datasets: [
          {
            label: '',
            data: allGroupByEntities.map(e => e.count),
            backgroundColor: allGroupByEntities.map(e => e?.color || DEFAULT_UNDEFINED_COLOR),
            hoverOffset: 4,
            labelMaxWidth: 65,
          },
        ],
      };
    }, [insightsData]);

    const handleChartItemClick = groupItemLabel => {
      const groupEntities = getGroupData(selectedGroupBy.key);
      const selectedEntity = Object.values(groupEntities).find(e => (e.title || e.name) === groupItemLabel);

      dispatch(onCounterPieChartEntityClick(selectedEntity));
    };

    return (
      <Component
        {...props}
        chartData={chartData}
        groupedBy={selectedGroupBy}
        handleGroupedByChange={_handleGroupedByChange}
        handleChartItemClick={handleChartItemClick}
      />
    );
  };
};

export default componentHOC;
