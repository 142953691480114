import { useReducer, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from 'hooks/usePrevious';
import useAppNotifications from 'hooks/useAppNotifications';
import {
  isCreateProjectLoading,
  isUpdateProjectLoading,
  isDeleteProjectLoading,
  isCreateProjectError,
  isUpdateProjectError,
  isUpdateRowOrderLoading,
  isUpdateRowOrderError,
} from 'store/projects/selectors';
import { resetProjectCreation, resetProjectUpdate, resetProjectRowOrderUpdate } from 'store/projects';
import { isAddProjectDependenciesLoading, isRemoveProjectDependenciesLoading } from 'store/dependencies/selectors';

import localProjectsReducer from './reducer';
import { resetLocalProjects, addLocalProject, updateLocalProject, updateRowOrderLocalProject } from './actionCreators';

const useProjectsLocalState = (data, options = { syncOnSuccess: true, syncOnError: true }) => {
  const { syncOnSuccess, syncOnError } = options;

  const dispatch = useDispatch();

  const { addError } = useAppNotifications();

  const [localProjects, localDispatch] = useReducer(localProjectsReducer, data, data => ({ ...data }));

  const isCreatingProject = useSelector(isCreateProjectLoading);
  const isUpdatingProject = useSelector(isUpdateProjectLoading);
  const isDeletingProject = useSelector(isDeleteProjectLoading);
  const isUpdatingRowOrderProject = useSelector(isUpdateRowOrderLoading);
  const isAddingProjectDependencies = useSelector(isAddProjectDependenciesLoading);
  const isRemovingProjectDependencies = useSelector(isRemoveProjectDependenciesLoading);

  const hasProjectCreationError = useSelector(isCreateProjectError);
  const hasProjectUpdateError = useSelector(isUpdateProjectError);
  const hasProjectRowOrderError = useSelector(isUpdateRowOrderError);

  const localAddProject = useCallback(newProject => localDispatch(addLocalProject(newProject)), []);
  const localUpdateProject = useCallback(updatedProject => localDispatch(updateLocalProject(updatedProject)), []);
  const localUpdateRowOrderProject = useCallback(
    (sourceProject, prev, next) => localDispatch(updateRowOrderLocalProject(sourceProject, prev, next)),
    [],
  );

  const isLoading =
    isCreatingProject ||
    isUpdatingProject ||
    isDeletingProject ||
    isUpdatingRowOrderProject ||
    isAddingProjectDependencies ||
    isRemovingProjectDependencies;
  const previousLoading = usePrevious(isLoading);

  const hasError = hasProjectCreationError || hasProjectUpdateError || hasProjectRowOrderError;

  useEffect(() => {
    if (syncOnSuccess && !hasError && previousLoading && !isLoading) {
      // Syncronize the backend response on successful response
      localDispatch(resetLocalProjects(data));
    }
  }, [data, isLoading, hasError]);

  useEffect(() => {
    if (syncOnError && hasError) {
      // Syncronize the backend response on failure
      localDispatch(resetLocalProjects(data));

      if (hasProjectCreationError) {
        addError({ id: 'project-creation-error', message: 'Creation failed' });

        dispatch(resetProjectCreation());
      }

      if (hasProjectUpdateError || hasProjectRowOrderError) {
        addError({ id: 'project-update-error', message: 'Update failed' });

        dispatch(resetProjectUpdate());
        dispatch(resetProjectRowOrderUpdate());
      }
    }
  }, [data, isLoading, syncOnError, hasProjectCreationError, hasProjectUpdateError, hasProjectRowOrderError, addError]);

  return { localProjects, localAddProject, localUpdateProject, localUpdateRowOrderProject };
};

export default useProjectsLocalState;
