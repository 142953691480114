import { useEffect, useRef, useState, useMemo } from 'react';

import useSystemFields from 'hooks/useSystemFields';
import useOrganizations from 'hooks/useOrganizations';
import useProjectsListLightboxContext from 'hooks/useProjectsListLightbox';
import useMetricsDialogTabs from './useMetricsDialogTabs';
import { ACTIVE_STATUS, ARCHIVED_STATUS } from 'constants/common';

const useMetricsDialogHeader = (metric, createMetric, updateMetric, isCreating, isAnonymousUser) => {
  const [showTitleError, setShowTitleError] = useState();

  const [getSystemFieldName] = useSystemFields();

  const { hasProjectMetrics } = useOrganizations();

  const { openProjectsListLightbox: openProjectsListLightboxAction } = useProjectsListLightboxContext();

  const { activeTab, detailsTab, linkedItemsTab, chartTab, onTabClick } = useMetricsDialogTabs();

  const titleInputRef = useRef();

  const isMetricActive = metric?.status === ACTIVE_STATUS || isCreating;

  const onToggleActive = () => updateMetric({ status: isMetricActive ? ARCHIVED_STATUS : ACTIVE_STATUS });

  const onChangeTitle = value => {
    if (isCreating && value && createMetric) createMetric({ name: value });
    else {
      if (!value || value === metric?.name) return;
      else if (!updateMetric) return;
      return updateMetric({ name: value });
    }
  };

  const onBlurTitle = e => setShowTitleError(!e.target.value);

  const onKeyDownTitle = e => setShowTitleError(!e.target.value);

  const openProjectsListLightbox = () => openProjectsListLightboxAction(metric, 'metric');

  const displayProjectsLightboxIcon = useMemo(() => hasProjectMetrics && !isAnonymousUser, [hasProjectMetrics, isAnonymousUser]);

  useEffect(() => {
    if (titleInputRef.current && isCreating) titleInputRef.current.focus();
  }, [!titleInputRef.current]);

  return {
    showTitleError,
    titleInputRef,
    metricLabel: getSystemFieldName('metric'),
    displayProjectsLightboxIcon,
    activeTab,
    detailsTab,
    linkedItemsTab,
    chartTab,
    isMetricActive,
    onTabClick,
    onToggleActive,
    onChangeTitle,
    onBlurTitle,
    onKeyDownTitle,
    openProjectsListLightbox,
  };
};

export default useMetricsDialogHeader;
