import { getPageFilters } from 'store/filters/selectors';
import { UPDATE_DASHBOARDS_STATE, SET_DASHBOARD_IN_EDIT_MODE } from './types';
import { DASHBOARDS_PAGE } from 'constants/filters';
import { updatePageActiveView } from 'store/userViews';

export const updateState = state => ({
  payload: state,
  type: UPDATE_DASHBOARDS_STATE,
});

/**
 *
 * @param {Object} updates - data to update on the active dashboard
 */
export const updateActiveDashboard =
  (newDashboardState = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const filter = getPageFilters(state, DASHBOARDS_PAGE);

    dispatch(
      updatePageActiveView(DASHBOARDS_PAGE, {
        state: {
          dashboard: newDashboardState,
          filter,
        },
      }),
    );
  };

export const setDashboardInEditMode = value => ({
  payload: value,
  type: SET_DASHBOARD_IN_EDIT_MODE,
});

export default {
  updateState,
};
