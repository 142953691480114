import axios from 'axios';

import {
  FETCH_FUNCTIONROLES,
  FETCH_USER_FUNCTIONROLES,
  UPDATE_USER_FUNCTIONROLES,
} from './types';

export const fetchFunctionRoles = () => {
  return dispatch => {
    const payload = axios.get('/api/functionRoles/')
      .then(res => res.data);

    dispatch({
      payload,
      type: FETCH_FUNCTIONROLES,
    });

    return payload;
  };
};

export const fetchUserFunctionRoles = (userId) => {
  return {
    payload: axios.get(`/api/users/${userId}/functions/`),
    type: FETCH_USER_FUNCTIONROLES,
  };
};

export const updateUserFunctionRoles = (userId, functionRoles) => {
  return {
    payload: axios.put(`/api/users/${userId}/functions/`, functionRoles),
    type: UPDATE_USER_FUNCTIONROLES,
  };
};


export const functionRolesActions = {
  fetchFunctionRoles,
  fetchUserFunctionRoles,
  updateUserFunctionRoles,
};
