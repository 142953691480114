import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DropdownFilter from 'components/DropdownFilter';

const ProgressTypeSelector = ({ progressTypeOptions, progressType, changeProgressType, disabled }) => {
  const hasProgressTypeOptions = (progressTypeOptions || []).length > 0;
  const onChangeProgressType = changeProgressType || (() => {});

  if (hasProgressTypeOptions) {
    return (
      <DropdownFilter
        style={{
          margin: '0px 8px 0px 16px',
          minWidth: '100px',
          maxWidth: '200px',
          textAlign: 'left',
        }}
        title=""
        hideAll
        name="progressTypeDropdown"
        options={progressTypeOptions}
        selectedValue={{ key: progressType }}
        onChange={({ key }) => onChangeProgressType(key)}
        disabled={disabled}
      />
    );
  }

  return (
    <FormControlLabel
      control={
        <Switch
          color="primary"
          checked={progressType === 'manual'}
          onChange={() => onChangeProgressType(progressType === 'auto' ? 'manual' : 'auto')}
        />
      }
      label="Manual"
      labelPlacement="start"
      disabled={disabled}
      style={disabled ? { opacity: 0, pointerEvents: 'none' } : {}}
    />
  );
};

export default ProgressTypeSelector;
