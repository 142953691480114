import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import AgGridYYYYMMDDRenderer from 'components/AgGridDateRenderer/AgGridYYYYMMDDRenderer';
import {
  TitleCellInnerRenderer,
  EmptyValueIfNotProjectRenderer,
  ParentCellRenderer,
} from 'containers/IdeasList/IdeasList/New/hooks/useIdeasGridColumnsDefinitions/renderers';
import dateColumnDef from 'design-system/molecules/AgGridReact-New/columns/dateColumnDef';

import theme from 'design-system/theme';

export const getTitleColumnDef = ({ openProjectLightbox }) => ({
  field: 'title',
  width: 400,
  headerName: 'Title',
  pinned: 'left',
  lockPinned: true,
  suppressMovable: true,
  headerClass: 'first-field',
  cellClass: 'ag-title-wrapper',
  cellRenderer: 'agGroupCellRenderer',
  comparator: stringComparator,
  cellRendererParams: params => ({
    suppressCount: true,
    suppressDoubleClickExpand: true,
    innerRenderer: TitleCellInnerRenderer,
    fontSize: theme.typography.fontSize,
    textColor: theme.palette.text.lightGrey,
    openButtonProps: {
      hasOpenButton: !params?.data?.group,
      onClick: () => openProjectLightbox(params?.data),
    },
  }),
});

export const parentColumnDef = {
  field: 'parent',
  headerName: 'Parent',
  cellRenderer: EmptyValueIfNotProjectRenderer(ParentCellRenderer),
  comparator: stringComparator,
  width: 240,
};

export const completedDateColumnDef = {
  ...dateColumnDef,
  field: 'completed_date',
  headerName: 'Completed Date',
  cellRenderer: AgGridYYYYMMDDRenderer,
};

export const targetEndDateColumnDef = {
  ...dateColumnDef,
  field: 'deadline',
  headerName: 'Target end date',
  cellRenderer: AgGridYYYYMMDDRenderer,
};

export const getShowFieldsPopupColumns = getSystemFieldName => ({
  leftColumns: [
    'Parent',
    getSystemFieldName('roadmap'),
    getSystemFieldName('product1'),
    getSystemFieldName('timeframe'),
    getSystemFieldName('phase'),
  ],
  rightColumns: [
    getSystemFieldName('status_color'),
    getSystemFieldName('progress'),
    getSystemFieldName('owner'),
    'Completed Date',
    'Target end date',
  ],
});

export const baseFieldKeys = [
  'title',
  'parent',
  'roadmapTitle',
  'product1Title',
  'timeframeTitle',
  'phaseTitle',
  'status_color',
  'progress',
  'ownerName',
  'completed_date',
  'deadline',
];
