import axios from 'axios';

import { createThunk } from 'utils/store/thunk';
import throwRequestError from 'store/utils/throwRequestError';

import {
  CREATE_PROJECT_DELIVERABLE_COMMENT,
  DELETE_PROJECT_DELIVERABLE_COMMENT,
  FETCH_PROJECT_DELIVERABLE_HISTORY,
  FETCH_PROJECT_DELIVERABLE_HISTORY_NEXT,
  FETCH_PROJECT_DELIVERABLE_NEXT_COMMENTS,
  UPDATE_PROJECT_DELIVERABLE_COMMENT,
} from './types';

export const fetchProjectDeliverableHistory = projectDeliverableId =>
  createThunk(
    FETCH_PROJECT_DELIVERABLE_HISTORY,
    axios.get(`/api/projects/deliverables/${projectDeliverableId}/history`).then(res => res.data),
    { projectDeliverableId },
  );

export const fetchProjectDeliverableHistoryNextPage = (projectDeliverableId, nextPageUrl) =>
  createThunk(
    FETCH_PROJECT_DELIVERABLE_HISTORY_NEXT,
    axios.get(nextPageUrl).then(res => res.data),
    { projectDeliverableId },
  );

export const createProjectDeliverableComment = (projectDeliverableId, comment) =>
  createThunk(
    CREATE_PROJECT_DELIVERABLE_COMMENT,
    axios.post(`/api/projects/deliverables/${projectDeliverableId}/comments`, comment).catch(throwRequestError),
    {
      projectDeliverableId,
    },
  );

export const fetchNextProjectDeliverableComments = (projectDeliverableId, nextUrl) =>
  createThunk(FETCH_PROJECT_DELIVERABLE_NEXT_COMMENTS, axios.get(nextUrl).catch(throwRequestError), {
    projectDeliverableId,
  });

export const deleteProjectDeliverableComment = (projectDeliverableId, commentId) =>
  createThunk(
    DELETE_PROJECT_DELIVERABLE_COMMENT,
    axios.delete(`/api/projects/deliverables/${projectDeliverableId}/comments/${commentId}`).catch(throwRequestError),
    {
      projectDeliverableId,
      commentId,
    },
  );

export const updateProjectDeliverableComment = (projectDeliverableId, commentId, data) =>
  createThunk(
    UPDATE_PROJECT_DELIVERABLE_COMMENT,
    axios.put(`/api/projects/deliverables/${projectDeliverableId}/comments/${commentId}`, data).catch(throwRequestError),
    {
      projectDeliverableId,
      commentId,
    },
  );
