import React, { useRef } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { getElementAtEvent } from 'react-chartjs-2';

import { LEAD_LINES_PLUGIN } from 'components/ReactChart/utils/plugins';

import DoughnutChart from 'components/ReactChart/DoughnutChart';
import Segment from 'design-system/atoms/Segment/index';
import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete/index';

import { HIDE_LEGEND_OPTION } from 'constants/charts';
import showCursorPointerOnHover from 'utils/charts/showCursorPointerOnHover';

import { checkForChartData } from '../helpers';
import useGroupByOptions from '../hooks/useGroupByOptions';

export default ({ chartData, groupedBy, handleGroupedByChange, handleChartItemClick }) => {
  const pieChartRef = useRef(null);

  const hasData = checkForChartData(chartData);
  const groupOptions = useGroupByOptions();

  return (
    <Wrapper bordered>
      <Grid container>
        <Grid item xs={12} md={12}>
          <GroupByAutocomplete
            label="By"
            name="selectedGroup"
            suggestions={groupOptions}
            value={groupedBy}
            onChange={handleGroupedByChange}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <PieChartContainer>
            {hasData && (
              <DoughnutChart
                height={350}
                setRef={ref => (pieChartRef.current = ref)}
                // height={chartHeight > MIN_HEIGHT ? chartHeight : MIN_HEIGHT}
                data={chartData}
                options={{
                  plugins: {
                    legend: {
                      ...HIDE_LEGEND_OPTION,
                    },
                  },
                  animation: {
                    duration: 0,
                  },
                  radius: '55%',
                  maintainAspectRatio: true,
                  responsive: true,
                  aspectRatio: 0.75,
                  onHover: showCursorPointerOnHover,
                }}
                onClick={event => {
                  const elm = getElementAtEvent(pieChartRef.current, event);

                  elm.length > 0 && handleChartItemClick(chartData.labels[[elm[0].index]]);
                }}
                plugins={[LEAD_LINES_PLUGIN]}
              />
            )}
          </PieChartContainer>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled(Segment)`
  &&&& {
    overflow: hidden;
  }
`;

const PieChartContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
