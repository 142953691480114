/**
 * @function checkIsEditable
 *
 * Check if row cell is editable
 *
 * @param  {Object} params.context
 * @return {Boolean}
 */
const checkIsEditable = ({ context, data }) => {
  return context.allowEdit && context.allowEdit(data);
};

export default checkIsEditable;
