import { useState } from 'react';

export const ASCENDING = 'asc';

export const DESCENDING = 'desc';

const getNextSortDirection = direction => {
  if (direction === null) return ASCENDING;
  if (direction === ASCENDING) return DESCENDING;
  if (direction === DESCENDING) return null;
};

const useTableSort = (defaultSortBy, defaultDirection) => {
  const [sortBy, setSortBy] = useState(defaultSortBy);
  const [sortDirection, setSortDirection] = useState(defaultDirection);

  const handleSort = field => {
    if (field === sortBy) {
      return setSortDirection(prevDirection => getNextSortDirection(prevDirection));
    }

    setSortBy(field);
    setSortDirection(ASCENDING);
  };

  return {
    sortBy,
    sortDirection,
    handleSort,
  };
};

export default useTableSort;
