// Copied from hooks/useDeepEffect

import { useRef, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

const useDeepCompareRef = (dependencies, comparator = isEqual) => {
  const dependencyList = useRef(dependencies);
  let hasChanges = false;
  const whatChanged = [];

  dependencies.map((dependency, index) => {
    whatChanged[index] = false;
    if (Array.isArray(dependency) && Array.isArray(dependencyList.current[index])) {
      whatChanged[index] = Array(Math.max(dependency.length, dependencyList.current[index].length))
        .fill(true)
        .map((_, dIndex) => {
          const d = dependency[dIndex];

          const objectIsEqual = dependencyList.current[index] && comparator(dependencyList.current[index][dIndex], d);

          if (!objectIsEqual) {
            hasChanges = true;
          }
          return !objectIsEqual;
        });
    } else if (!comparator(dependencyList.current[index], dependency)) {
      hasChanges = true;
      whatChanged[index] = true;
    }
    return null;
  });
  if (hasChanges) dependencyList.current = dependencies;
  return [dependencyList.current, whatChanged];
};

/**
 * Custom Hook to use useEffect but compare deep objects and arrays with a custom comparator
 *
 * @param {*} callback
 * @param {*} dependencies
 * @param {*} customCompare
 */
const useDeepEffect = (callback, dependencies, customCompare = undefined) => {
  const [newDependencies, whatChanged] = useDeepCompareRef(dependencies, customCompare);

  return useEffect(() => callback(whatChanged), newDependencies);
};

export default useDeepEffect;
