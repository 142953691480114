import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pluck, uniq } from 'ramda';

import { getMetrics } from 'store/metrics/selectors';
import { getSnapshotIsMetricViewModeOn, getSnapshotAreMetricClustersVisible } from 'store/snapshot/selectors';
import { getOutcomeSnapshotState } from 'store/goalMode/selectors';
import { getHasProjectMetrics } from 'store/organization';

import { CORP_OBJECTIVE_KEY, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY, OBJECTIVE_KEY } from 'constants/objectives';

import useMetricsChartView from 'routes/Metrics/hooks/useMetricsChartView';
import { updateViewMode, updateClustersVisibility } from 'store/snapshot';
import { updateSnapshotState } from 'store/goalMode';

const ID = 'id';
const OKR_FIELDS = [CORP_OBJECTIVE_KEY, OBJECTIVE_KEY, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY];

const isOKRSelected = selectedSnapshotField => OKR_FIELDS.includes(selectedSnapshotField);
const getMetricIds = pluck(ID);

export default (selectedSnapshotField, okrs, isGoalMode) => {
  const dispatch = useDispatch();
  const metrics = useSelector(getMetrics);

  const hasProjectMetrics = useSelector(getHasProjectMetrics);

  const goalsSnapshotState = useSelector(getOutcomeSnapshotState);

  const snapshotMetricViewModeOn = useSelector(state => getSnapshotIsMetricViewModeOn(state));
  const goalsSnapshotMetricViewModeOn = goalsSnapshotState?.metricViewModeOn;
  const isMetricChartViewOn = isGoalMode ? goalsSnapshotMetricViewModeOn : snapshotMetricViewModeOn;

  const snapshotClustersVisible = useSelector(getSnapshotAreMetricClustersVisible);
  const goalsSnapshotClustersVisible = goalsSnapshotState?.metricClustersVisible;

  const areClustersVisible = useMemo(
    () => (isGoalMode ? goalsSnapshotClustersVisible : snapshotClustersVisible),
    [isGoalMode, goalsSnapshotClustersVisible, snapshotClustersVisible],
  );

  const handleChartViewToggle = useCallback(() => {
    if (isGoalMode) {
      dispatch(updateSnapshotState({ metricViewModeOn: !isMetricChartViewOn }));
      return;
    }

    dispatch(updateViewMode(!isMetricChartViewOn));
  }, [dispatch, updateViewMode, isMetricChartViewOn, isGoalMode]);

  const handleChartClustersToggle = useCallback(() => {
    if (isGoalMode) {
      dispatch(updateSnapshotState({ metricClustersVisible: !areClustersVisible }));
      return;
    }

    dispatch(updateClustersVisibility(!areClustersVisible));
  }, [dispatch, updateClustersVisibility, areClustersVisible, isGoalMode]);

  const { startDate, endDate, userCanEditChartView, userCanViewChartToggle, handleDateRangeChange } = useMetricsChartView();

  const canViewMetricModeToggle = useMemo(
    () => userCanViewChartToggle && isOKRSelected(selectedSnapshotField),
    [userCanViewChartToggle, selectedSnapshotField],
  );

  const shouldRenderMetricMode = useMemo(
    () => canViewMetricModeToggle && isMetricChartViewOn,
    [canViewMetricModeToggle, isMetricChartViewOn],
  );

  const associatedMetrics = useMemo(() => {
    if (!shouldRenderMetricMode) return [];

    const metricIds = uniq(okrs.reduce((metricIds, okr) => [...metricIds, ...getMetricIds(okr.metrics)], []));

    return metrics.filter(metric => metricIds.includes(metric.id));
  }, [shouldRenderMetricMode, okrs, metrics]);

  return {
    hasPermissionForMetricMode: userCanEditChartView,
    hasProjectMetrics,
    metricViewModeOn: isMetricChartViewOn,
    areClustersVisible,
    shouldRenderMetricMode,
    canViewMetricModeToggle,
    onToggleMetricViewMode: handleChartViewToggle,
    onToggleClustersVisibility: handleChartClustersToggle,
    associatedMetrics,
    startDate,
    endDate,

    handleDateRangeChange,
  };
};
