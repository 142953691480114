import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'rc-slider';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { isEnter, isTab, isEscape } from 'design-system/utils/keyboard';
import FluidField from 'design-system/molecules/FluidField/index';
import ProgressValueField from 'design-system/molecules/ProgressValueField/index';

import ProgressTypeSelector from './components/ProgressTypeSelector';
import formatPercent from 'design-system/utils/formatPercent';
import { PROGRESS_PERCENTAGE_DECIMAL_PLACES } from 'constants/common';

const PROGRESS_TYPE_AUTO = 'auto';
const PROGRESS_TYPE_MANUAL = 'manual';
const PROGRESS_TYPE_ROLLUP = 'auto-rollup-children';

const Progress = styled(({ value, customSlider, props, disabled }) => {
  const innerValue = useMemo(() => {
    return formatPercent(value, PROGRESS_PERCENTAGE_DECIMAL_PLACES);
  }, [value]);

  return (
    <ProgressContainer>
      <div className="progressbar-container">
        <ProgressBarContainer
          progressType={props.progressType}
          style={{ background: props.progressType === PROGRESS_TYPE_MANUAL && 'transparent' }}
        >
          {props.progressType === PROGRESS_TYPE_MANUAL && customSlider}
          {[PROGRESS_TYPE_AUTO, PROGRESS_TYPE_ROLLUP].includes(props.progressType) && <ProgressBar value={innerValue} />}
        </ProgressBarContainer>
        <ProgressValueField
          canEditProgressText={props.canEditProgressText}
          disabledEditableText={disabled}
          onChange={props.onChange}
          progressValue={value}
          updateIsEditing={props.updateIsEditingText}
        />
        <ProgressTypeSelector {...props} disabled={disabled} />
      </div>
    </ProgressContainer>
  );
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressBarContainer = styled.div`
  position: relative;
  border-radius: 8px;
  flex-grow: 1;
  height: ${props => (props.progressType === PROGRESS_TYPE_MANUAL ? 24 : 8)}px;

  margin-right: 8px;
  ${props => props.progressType === PROGRESS_TYPE_MANUAL && 'margin-top: 5px;'}

  overflow: visible;

  background: #eaeaea;
`;
const ProgressBar = styled.div`
  border-radius: 8px;
  height: 8px;
  width: ${props => props.value};
  max-width: 100%;

  background: #00b1f3;
`;

const ProgressContainer = styled.div`
  flex-direction: column;
  align-items: center;

  .manual-auto-switch {
    display: flex;
    justify-content: center;
  }

  .progressbar-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .rc-slider-disabled {
      background-color: unset;
    }
  }

  .auto-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
`;

const FluidProgressField = props => {
  const [isEditingProgressText, setIsEditingText] = useState(false);

  return (
    <FluidField
      {...props}
      editorRenderer={({ value, save, onChange, cancel, disableEdit }) => {
        if (props.value !== value && props.progressType !== PROGRESS_TYPE_MANUAL && !Number.isNaN(props.value)) {
          onChange(props.value);
          props.onChange(props.value);
        }

        // if user updates manual progress text, need to update slider
        if (props.value !== value && props.canEditProgressText && isEditingProgressText) {
          onChange(props.value);
          setIsEditingText(false);
        }

        return (
          <div>
            <ClickAwayListener mouseEvent="false" onClickAway={() => props.progressType === PROGRESS_TYPE_MANUAL && save()}>
              <Progress
                value={value}
                progressType={props.progressType}
                disabled={disableEdit}
                customSlider={
                  <Slider
                    min={0}
                    max={1}
                    step={0.01}
                    s
                    value={value}
                    onChange={onChange}
                    onAfterChange={_ => save()}
                    onKeyDown={e => {
                      e.stopPropagation();

                      if (isTab(e) || isEnter(e)) save();
                      if (isEscape(e)) cancel();
                    }}
                    onBlur={_ => save()}
                    trackStyle={{ backgroundColor: '#00b1f3', height: 8 }}
                    handleStyle={{ borderColor: '#00b1f3', top: 7 }}
                    railStyle={{ backgroundColor: '#eaeaea', height: 8 }}
                    disabled={disableEdit}
                  />
                }
                props={{
                  ...props,
                  updateIsEditingText: setIsEditingText,
                }}
                changeProgressType={props.changeProgressType}
                save={save}
              />
            </ClickAwayListener>
          </div>
        );
      }}
    />
  );
};

FluidProgressField.propTypes = {
  ...FluidField.propTypes,
  value: PropTypes.number,
  changeProgressType: PropTypes.func,
  disableSwitch: PropTypes.bool,
  progressType: PropTypes.oneOf([PROGRESS_TYPE_MANUAL, PROGRESS_TYPE_AUTO]),
  progressTypeOptions: PropTypes.array,
};

export default FluidProgressField;
