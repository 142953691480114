import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAppGlobalConfig } from 'store/app/actions';
import { getCurrentUser } from 'store/login/selectors';
import { getOrganization } from 'store/organization/selectors';
import useSystemFields from 'hooks/useSystemFields';
import { showImportIdeasLightbox } from 'store/ideas';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const _openTemplateLightbox = () => dispatch(setAppGlobalConfig({ showTemplatesLightbox: true, showPreviewTemplate: false }));
    const currentUser = useSelector(getCurrentUser);
    const organization = useSelector(getOrganization);
    const appState = useSelector(state => state.app);
    const currentUserIsOwnerOfOrg = organization.owner_id === currentUser.id;
    const [getSystemFieldName] = useSystemFields();
    const _showImportIdeasLightbox = () => dispatch(showImportIdeasLightbox());

    React.useEffect(() => {
      if (window.location.pathname === '/welcome/select-template' && !appState.showTemplatesLightbox) _openTemplateLightbox();
    }, []);

    React.useEffect(() => {
      if (appState.showTemplatesLightbox || appState.showPreviewTemplate) {
        window.history.pushState('', '', '/welcome/select-template');
      } else {
        const params = new URLSearchParams(window.location.search);
        const loadsOnInitParam = params.get('loadsOnInit');

        window.history.pushState('', '', `/welcome?loadsOnInit=${loadsOnInitParam}`);
      }
    }, [appState.showTemplatesLightbox, appState.showPreviewTemplate]);

    return (
      <Component
        {...props}
        openTemplateLightbox={_openTemplateLightbox}
        currentUser={currentUser}
        currentUserIsOwnerOfOrg={currentUserIsOwnerOfOrg}
        getSystemFieldName={getSystemFieldName}
        showImportIdeasLightbox={_showImportIdeasLightbox}
      />
    );
  };
};

export default componentHOC;
