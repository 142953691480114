import { List } from 'immutable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { FeatureFlags } from '@dragonboat/config';

import { getPriorities } from 'store/priorities/selectors';

import { projectActions, importProjects } from 'store/projects';
import { secureUpdateProjectEstimates } from 'store/estimates';
import {
  showImportIdeasLightbox,
  hideImportIdeasLightbox,
  setIdeasCopyPasteLightboxData,
  showIdeasCopyPasteLightbox,
  hideIdeasCopyPasteLightbox,
  showImportJQLLightbox,
  hideImportJQLLightbox,
  showImportTip,
  hideImportTip,
  showImportADOQueryLightbox,
  hideImportADOQueryLightbox,
  showImportDragonboatFilterLightbox,
  hideImportDragonboatFilterLightbox,
  showImportRallyViewLightbox,
  hideImportRallyViewLightbox,
} from 'store/ideas';
import { removeDuplicates as uniq, getUserName } from 'utils';
import ImportIdeasDialog from './ImportIdeasDialog';
import {
  getOrgHasJiraIntegrated,
  hasIntegrationsOfType as hasIntegrationsOfTypeSelector,
  selectHasMultipleIntegrationsByIntegrationType,
} from 'store/organization/selectors';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import { INTEGRATIONS_KEYS } from 'constants/integrations';
import { checkOrganizationFlags } from 'hooks/useFeatureFlags';

const mapStateToProps = (state, props) => {
  const {
    users: usersState,
    ideas,
    objectives,
    themes,
    phases,
    categories,
    timeframes: timeframesState,
    roadmaps: roadmapsState,
    organization: { organization },
  } = state;

  const jiraIntegrated = getOrgHasJiraIntegrated(state);
  const azureDevopsIntegrated = hasIntegrationsOfTypeSelector(INTEGRATIONS_KEYS.azuredevops)(state);
  const rallyIntegrated = hasIntegrationsOfTypeSelector(INTEGRATIONS_KEYS.rally)(state);

  const hasMultipleRallyIntegrations = selectHasMultipleIntegrationsByIntegrationType(INTEGRATIONS_KEYS.rally)(state);

  const hasExternalKeyFieldsOnImport = checkOrganizationFlags(organization, [FeatureFlags.HAS_EXTERNAL_KEY_ON_PROJECTS_REQUESTS]);

  const importLightboxVisible = ideas.get('importIdeasLightboxVisible');
  const objectiveTitles = objectives.objectives ? objectives.objectives.map(objective => objective.title) : [];
  const users = usersState?.rows ? uniq(usersState.rows) : [];
  const productOwners = users ? users.map(getUserName) : [];

  const priorities = getPriorities(state);

  const timeframes = timeframesState.has('rows')
    ? timeframesState
        .get('rows')
        .toJS()
        .map(({ title, id, status }) => ({ title, id, status }))
    : [];
  const roadmaps = roadmapsState.roadmaps ? roadmapsState.roadmaps.map(({ title, id }) => ({ title, id })) : [];
  const timeframesTitles = timeframes ? timeframes.map(timeframe => timeframe.title) : [];
  const roadmapsTitles = roadmaps ? roadmaps.map(roadmap => roadmap.title) : [];

  return {
    copyPasteLightboxVisible: ideas.get('ideasCopyPasteLightboxVisible'),
    copyPasteData: List.isList(ideas.get('ideasCopyPasteLightboxData')) ? ideas.get('ideasCopyPasteLightboxData').toJS() : [],
    showImportJqlModal: ideas.get('importJQLLightboxVisible'),
    showImportADOQueryModal: ideas.get('importADOQueryLightboxVisible'),
    showImportDragonboatFilterModal: ideas.get('importDragonboatFilterLightboxVisible'),
    importDragonboatFilterModalVariant: ideas.get('importDragonboatFilterModalVariant'),
    importDragonboatFilterOrgIntegrationId: ideas.get('importDragonboatFilterOrgIntegrationId'),
    showImportRallyViewModal: ideas.get('importRallyViewLightboxVisible'),
    jiraIntegrated,
    azureDevopsIntegrated,
    rallyIntegrated,
    importLightboxVisible,
    objectiveTitles,
    phaseTitles: phases.phases.map(p => p.title),
    themeTitles: themes.themes.map(t => t.title),
    categoryTitles: categories.categories.map(c => c.title),
    productOwners,
    priorities,
    timeframesTitles,
    roadmapsTitles,
    organization,
    roadmaps: roadmapsState.roadmaps,
    objectives: objectives.objectives,
    jiraConfig: {},
    customFields: getProjectsCustomFields(state),
    systemFields: organization.system_fields_name,
    hasExternalKeyFieldsOnImport,
    showRallyWorkspaceList: hasMultipleRallyIntegrations,
  };
};

export default compose(
  connect(mapStateToProps, {
    ...projectActions,
    importProjects,
    secureUpdateProjectEstimates,
    showImportIdeasLightbox,
    hideImportIdeasLightbox,
    setIdeasCopyPasteLightboxData,
    showIdeasCopyPasteLightbox,
    hideIdeasCopyPasteLightbox,
    showImportJQLLightbox,
    hideImportJQLLightbox,
    showImportTip,
    hideImportTip,
    showImportADOQueryLightbox,
    hideImportADOQueryLightbox,
    showImportDragonboatFilterLightbox,
    hideImportDragonboatFilterLightbox,
    showImportRallyViewLightbox,
    hideImportRallyViewLightbox,
  }),
  withRouter,
)(ImportIdeasDialog);
