import Axios from 'axios';
import undoAction from './undoAction';
import throwRequestError from '../throwRequestError';

export default (ACTION_TYPE, endpoint, undoOptions, transform) => {
  return (rows) => {
    return async dispatch => {
      if (!rows || typeof rows !== 'object') {
        return Promise.reject();
      } else if (!(rows instanceof Array)) {
        rows = [rows];
      }

      if (typeof transform === 'function') {
        rows = rows.map(transform);
      }

      const payload = Axios.post(endpoint, rows)
        .then(res => {
          if (undoOptions) {
            dispatch(undoAction(
              `${res.data.length} ${undoOptions.toastText}`,
              undoOptions.ACTION_TYPE,
              undoOptions.store,
              undoOptions.endpoint
            ));
          }

          return res.data;
        })
        .catch(throwRequestError);

      await dispatch({
        type: ACTION_TYPE,
        payload,
      });

      return payload;
    };
  };
};
