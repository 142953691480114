import { useMemo } from 'react';
import { path } from 'ramda';

import { numericColumnDef } from 'design-system/molecules/AgGridReact-New/columns';

import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';

const totalRevenueValueGetter = path(['data', 'totalRevenue']);
const activeRevenueValueGetter = path(['data', 'activeRevenue']);
const inactiveRevenueValueGetter = path(['data', 'inactiveRevenue']);
const customersCountValueGetter = path(['data', 'customersCount']);
const customerRequestsCountValueGetter = path(['data', 'customerRequestsCount']);

/**
 * @function usePreCalculatedFieldsColumnsDefinitions
 *
 * Define pre calculated fiels columns definitions
 *
 * @return {Array} - columns definitions
 */
const usePreCalculatedFieldsColumnsDefinitions = () => {
  const shouldShowPreCalculations = useFeatureFlags([FeatureFlags.HAS_OUTCOME_DASHBOARD]);

  const totalRevenueColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      editable: false,
      field: 'totalRevenue',
      headerName: 'Total value',
      valueGetter: totalRevenueValueGetter,
      width: 100,
    };
  }, []);

  const activeRevenueColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      editable: false,
      field: 'activeRevenue',
      headerName: 'Active value',
      valueGetter: activeRevenueValueGetter,
      width: 100,
    };
  }, []);

  const inactiveRevenueColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      editable: false,
      field: 'inactiveRevenue',
      headerName: 'Inactive value',
      valueGetter: inactiveRevenueValueGetter,
      width: 100,
    };
  }, []);

  const customersCountColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      editable: false,
      field: 'customersCount',
      headerName: '# of Customers',
      valueGetter: customersCountValueGetter,
      width: 140,
    };
  }, []);

  const customerRequestsCountColumnDef = useMemo(() => {
    return {
      ...numericColumnDef,
      editable: false,
      field: 'customerRequestsCount',
      headerName: 'Total requests',
      valueGetter: customerRequestsCountValueGetter,
      width: 100,
    };
  }, []);

  return useMemo(
    () => [
      ...(shouldShowPreCalculations ? [totalRevenueColumnDef, activeRevenueColumnDef, inactiveRevenueColumnDef] : []),
      customersCountColumnDef,
      customerRequestsCountColumnDef,
    ],
    [
      shouldShowPreCalculations,
      totalRevenueColumnDef,
      activeRevenueColumnDef,
      inactiveRevenueColumnDef,
      customersCountColumnDef,
      customerRequestsCountColumnDef,
    ],
  );
};

export default usePreCalculatedFieldsColumnsDefinitions;
