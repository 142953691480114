import { values, not } from 'ramda';

import { FIELDS_LAYOUT_LEVELS, FIELDS_LAYOUT_TYPES } from 'features/FieldsLayout/constants';
import useFieldsLayoutForType from 'features/FieldsLayout/hooks/useFieldsLayoutForType';

import useDefaultFieldsLayout from './useDefaultFieldsLayout';

const LEFT_POSITION = 'left';
const RIGHT_POSITION = 'right';

const sortByIndex = (a, b) => a.index - b.index;

const useRequestsDetailFieldsLayout = () => {
  const { defaultFields, enrichFieldsLayout } = useDefaultFieldsLayout();

  const { fieldsLayoutIsLoading, fieldsLayout, fetchFieldsLayout, upsertFieldsLayout } = useFieldsLayoutForType({
    defaultFieldsLayout: defaultFields,
    type: FIELDS_LAYOUT_TYPES.requests,
    level: FIELDS_LAYOUT_LEVELS.level_0,
  });

  const fields = enrichFieldsLayout(fieldsLayout);
  const allFields = values(fields);

  const leftFields = allFields.filter(f => not(f.hidden) && f.position === LEFT_POSITION).sort(sortByIndex);
  const rightFields = allFields.filter(f => not(f.hidden) && f.position === RIGHT_POSITION).sort(sortByIndex);
  const requiredFields = allFields.filter(f => not(f.hidden) && f.required);
  const fieldsToPromote = allFields.filter(f => not(f.hidden) && f.promote);

  return {
    fields,
    leftFields,
    rightFields,
    requiredFields,
    fieldsLayoutIsLoading,
    fieldsToPromote,

    fetchFieldsLayout,
    upsertFieldsLayout,
  };
};

export default useRequestsDetailFieldsLayout;
