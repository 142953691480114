import queryString from 'query-string';

const LOGIN_ROUTE = '/login';
const HOME_ROUTE = '/dashboard/home';

/**
 * @function generateOwnerOrganizationIUrl
 *
 * Generate a redirect url for owner of the provided project.
 * The url redirects login using the passwordless auth mechanism and use the
 * query param next to open the project right after the login/authorization flow.
 *
 * @param {Number} projectId
 * @param {String} organizationUrl
 * @param {String} token
 * @returns {String}
 */
export const generateProjectOwnerOrganizationUrl = (projectId, organizationUrl, token) => {
  const nextQueryParams = queryString.stringify({ openIdea: projectId });
  const queryParams = queryString.stringify({
    t: token,
    next: `${HOME_ROUTE}?${nextQueryParams}`,
  });

  return `${organizationUrl}${LOGIN_ROUTE}?${queryParams}`;
};
