import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';

import { ConfirmDialog as ConfirmDeleteDialog } from 'components/gridCommon';
import theme from 'design-system/theme';

export default ({ selected, label, onDelete, disableDelete = false }) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <Fragment>
      <Button disabled={disableDelete} color="secondary" onClick={() => setShowDialog(true)}>
        delete
      </Button>
      <ConfirmDeleteDialog
        isOpen={showDialog}
        row={selected}
        title={`Delete selected ${label}`}
        text={`Are you sure you want to delete selected ${label}?`}
        onCancel={() => setShowDialog(false)}
        onDelete={() => {
          onDelete();
          setShowDialog(false);
        }}
        blankBody
        zIndex={theme.zIndex.okrDrawerDialogs}
      />
    </Fragment>
  );
};
