import React, { useCallback } from 'react';

import {
  BarElement,
  ArcElement,
  RadialLinearScale,
  CategoryScale,
  Chart,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

import 'chartjs-adapter-moment';
import Annotation from 'chartjs-plugin-annotation';

Chart.register(
  // Elements
  LineElement,
  PointElement,
  BarElement,
  ArcElement,

  // Scales
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  TimeScale,

  // Plugins
  Legend,
  Title,
  Tooltip,
  Filler,
  Annotation,
);

export default function BaseChart({ chart: Chart, ...props }) {
  const setRef = useCallback(r => {
    if (props.setRef) {
      props.setRef(r);
    }
  });

  return <Chart {...props} ref={setRef} />;
}
