import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { equals, filter, keys, pipe } from 'ramda';

import Tooltip from '@material-ui/core/Tooltip';

import SearchableDndList from 'design-system/molecules/SearchableDndList/index';
import DndAtoms from 'design-system/atoms/DndAtoms/index';

import theme from 'design-system/theme';

const { MenuText } = DndAtoms;

import formatDate from 'design-system/utils/formatDate';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@material-ui/core/Popover';
import EditIcon from '@material-ui/icons/Edit';

import { setSelectedRoadmapVersionsToCompare as setSelectedRoadmapVersionsToCompareAction } from 'store/roadmapVersions';
import { getSelectedRoadmapVersionsIdsToCompare } from 'store/roadmapVersions/selectors';
import { getUsers } from 'store/users/selectors';

import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useScenariosListDialog from 'hooks/useScenariosListDialog/useScenariosListDialog';

const CUSTOM_EMPTY_MESSAGE = 'No roadmap versions created for comparison.';

const exist = Boolean;
const getSelectedKeys = pipe(filter(exist), keys);

const renderCustomItem = item => {
  const name = `Title: ${item.name}`;
  const user = `Owner: ${item.user}`;
  const date = `Created: ${item.date}`;
  const tooltip = (
    <>
      {name}
      <br />
      {user}
      <br />
      {date}
    </>
  );

  return (
    <MenuText>
      <Tooltip title={tooltip}>
        <Typography variant="body2">{item.label}</Typography>
      </Tooltip>
    </MenuText>
  );
};

const renderCustomEmptyOptions = () => {
  return <EmptyMessage>{CUSTOM_EMPTY_MESSAGE}</EmptyMessage>;
};

const SelectRoadmapVersionsPopover = ({ anchorEl, open, onClose }) => {
  const dispatch = useDispatch();

  const users = useSelector(state => getUsers(state));
  const selectedRoadmapVersionsIds = useSelector(state => getSelectedRoadmapVersionsIdsToCompare(state));
  const { roadmapVersions } = useRoadmapVersions();
  const { openSaveScenarioDialog } = useScenariosListDialog();

  const selectedRoadmapVersionsKeysMap = useMemo(
    () => selectedRoadmapVersionsIds.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
    [selectedRoadmapVersionsIds],
  );

  const roadmapVersionsData = useMemo(
    () =>
      roadmapVersions.map(item => {
        const user = users.find(user => user?.id === item?.created_by_id);
        const versionName = item?.name;
        const userName = user?.name;
        const date = formatDate(item?.created_at);
        const label = `${versionName}`;
        const committed = `${item?.committed}`;
        const description = item?.description;

        return {
          id: item.id,
          label,
          name: versionName,
          user: userName,
          date,
          committed,
          description,
          color: item.color,
        };
      }),
    [roadmapVersions],
  );

  const [selectedItems, setSelectedItems] = useState(selectedRoadmapVersionsKeysMap);

  const handleSelectedRoadmapVersions = useCallback(
    (selectedId, selected) => {
      const formattedSelectedId = String(selectedId);

      setSelectedItems({ ...selectedItems, [formattedSelectedId]: selected });
    },
    [selectedItems],
  );

  const _itemRightActions = [
    {
      renderIcon: () => <EditIcon />,
      onClick: params => openSaveScenarioDialog(params),
    },
  ];

  const handleClearSelectedVersions = () => {
    setSelectedItems({});
  };

  const _onClose = () => {
    onClose();

    const originalSelectedItems = getSelectedKeys(selectedRoadmapVersionsKeysMap).sort();
    const newSelectedItems = getSelectedKeys(selectedItems).sort();
    const selectedItemsAreTheSame = equals(originalSelectedItems, newSelectedItems);

    if (!selectedItemsAreTheSame) {
      dispatch(setSelectedRoadmapVersionsToCompareAction(getSelectedKeys(selectedItems)));
    }
  };

  return (
    <StyledPopover
      anchorEl={anchorEl}
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Paper>
        <ClickAwayListener onClickAway={_onClose}>
          <div>
            <SearchableDndList
              items={roadmapVersionsData}
              selectedItems={selectedItems}
              onSelectItem={handleSelectedRoadmapVersions}
              handleClear={handleClearSelectedVersions}
              renderCustomItem={renderCustomItem}
              renderCustomEmptyOptions={renderCustomEmptyOptions}
              itemRightActions={_itemRightActions}
              checkable
              showActionsOnHover
            />
          </div>
        </ClickAwayListener>
      </Paper>
    </StyledPopover>
  );
};

const StyledPopover = styled(Popover)`
  &&&& {
    > div {
      min-width: 400px;
      max-width: 450px;
      overflow: auto;
    }
  }
`;

const EmptyMessage = styled.div`
  margin: 30px;
  font-size: ${theme.typography.fontSize}px;
  font-style: italic;
  color: ${theme.palette.text.secondary};
  text-align: center;
`;

export default SelectRoadmapVersionsPopover;
