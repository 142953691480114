import React, { useCallback } from 'react';
import pluralize from 'pluralize';

import ProgressLightbox from 'design-system/molecules/ProgressLightbox/index';

import useSystemFields from 'hooks/useSystemFields';
import MergeLightbox from 'components/MergeLightbox';

import BulkDeleteConfirmDialog from './components/BulkDeleteConfirmDialog';
import DeleteConfirmDialog from './components/DeleteConfirmDialog';

const PHASE_SETTING_KEY = 'phase';

const defaultTransformLabel = item => `${item.title} (${item.status})`;

const defaultShouldDisableMergeOption = () => false;

const SettingsModals = ({
  settingKey,
  settingType,
  itemToDelete,
  setItemToDelete,
  deleteItem,
  showBulkDeleteDialog,
  setShowBulkDeleteDialog,
  deleteProgress,
  bulkDelete,
  items = [],
  selectedItems,
  disableSelectionMode,
  mergeLightboxVisible,
  hideMergeLightbox,
  merge,
  transformLabel = defaultTransformLabel,
  shouldDisableMergeOption = defaultShouldDisableMergeOption,
  bulkUpdateProjects,
  defaultPhase = {},
}) => {
  const [getSystemField] = useSystemFields();
  const settingName = getSystemField(settingKey);
  const settingNamePlural = pluralize(settingName);

  const isPhasesSettings = settingKey === PHASE_SETTING_KEY;

  const handleCancelBulkDeleteItems = useCallback(() => setShowBulkDeleteDialog(false), [setShowBulkDeleteDialog]);

  // Needed to avoid passing the first arg to the `bulkDelete` function, that otherwise would take action on it.
  const handleConfirmBulkDeleteItems = useCallback(() => bulkDelete(), [bulkDelete]);

  const handleCancelDeleteItem = useCallback(() => setItemToDelete(null), [setItemToDelete]);

  const handleHideMergeLightbox = useCallback(() => hideMergeLightbox(), [hideMergeLightbox]);

  const handleDisableSelectionMode = useCallback(() => disableSelectionMode(settingType), [disableSelectionMode, settingType]);

  const handleDisableMergeOption = useCallback(
    item => shouldDisableMergeOption(item, selectedItems),
    [shouldDisableMergeOption, selectedItems],
  );

  return (
    <>
      <BulkDeleteConfirmDialog
        isPhasesSettings={isPhasesSettings}
        settingNamePlural={settingNamePlural}
        items={items}
        selectedItems={selectedItems}
        itemToDelete={itemToDelete}
        isOpen={showBulkDeleteDialog}
        onCancel={handleCancelBulkDeleteItems}
        bulkDelete={handleConfirmBulkDeleteItems}
        bulkUpdateProjects={bulkUpdateProjects}
        defaultPhase={defaultPhase}
      />
      <DeleteConfirmDialog
        isPhasesSettings={isPhasesSettings}
        settingName={settingName}
        items={items}
        selectedItems={selectedItems}
        itemToDelete={itemToDelete}
        isOpen={!!itemToDelete}
        onCancel={handleCancelDeleteItem}
        deleteItem={deleteItem}
        bulkUpdateProjects={bulkUpdateProjects}
        defaultPhase={defaultPhase}
      />
      <ProgressLightbox progress={deleteProgress} />
      <MergeLightbox
        items={selectedItems}
        dialogVisible={mergeLightboxVisible}
        merge={merge}
        transformLabel={transformLabel}
        hideDialog={handleHideMergeLightbox}
        disableSelectionMode={handleDisableSelectionMode}
        shouldDisableOption={handleDisableMergeOption}
      />
    </>
  );
};

export default SettingsModals;
