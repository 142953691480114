import React from 'react';
import styled from 'styled-components';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import HelpIcon from '@material-ui/icons/HelpOutline';

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0 8px;
  font-size: 24px;
  color: #6b6b6b;
`;

export default ({ title, helpLink, logo, hideHelp, iconId }) => (
  <Title>
    {logo}
    {title}
    {!hideHelp && (
      <ButtonIcon
        id={iconId || undefined}
        title="Help"
        // eslint-disable-next-line no-script-url
        href={helpLink || 'https://dragonboat.zendesk.com/hc/en-us/articles/360037378353'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <HelpIcon />
      </ButtonIcon>
    )}
  </Title>
);
