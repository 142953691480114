import { Map } from 'immutable';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { subtract } from 'ramda';
import reduceReducers from 'reduce-reducers';

import dashboardsCommentsReducer, { initialState as dashboardsCommentsInitialState } from './dashboards/reducer';
import {
  FETCH_PROJECT_COMMENTS_FULFILLED,
  SAVE_PROJECT_COMMENT_FULFILLED,
  EDIT_PROJECT_COMMENT_FULFILLED,
  FETCH_NEXT_PROJECT_COMMENTS_FULFILLED,
  FETCH_PROJECT_COMMENTS_PENDING,
  SAVE_CUSTOMER_REQUEST_COMMENT_FULFILLED,
  FETCH_CUSTOMER_REQUEST_COMMENTS_FULFILLED,
  FETCH_CUSTOMER_REQUEST_COMMENTS_PENDING,
  FETCH_NEXT_CUSTOMER_REQUEST_COMMENTS_FULFILLED,
  DELETE_CUSTOMER_REQUEST_COMMENT_FULFILLED,
  EDIT_CUSTOMER_REQUEST_COMMENT_FULFILLED,
  SAVE_OBJECTIVE_COMMENT_FULFILLED,
  FETCH_OBJECTIVE_COMMENTS_FULFILLED,
  FETCH_OBJECTIVE_COMMENTS_PENDING,
  FETCH_NEXT_OBJECTIVE_COMMENTS_FULFILLED,
  SAVE_KEY_RESULT_COMMENT_FULFILLED,
  FETCH_KEY_RESULT_COMMENTS_FULFILLED,
  FETCH_KEY_RESULT_COMMENTS_PENDING,
  FETCH_NEXT_KEY_RESULT_COMMENTS_FULFILLED,
  DELETE_PROJECT_COMMENT_FULFILLED,
  DELETE_OBJECTIVE_COMMENT_FULFILLED,
  EDIT_OBJECTIVE_COMMENT_FULFILLED,
  DELETE_KEY_RESULT_COMMENT_FULFILLED,
  EDIT_KEY_RESULT_COMMENT_FULFILLED,
} from './types';

const defaultCustomerRequestMeta = {
  page: 1,
  next: null,
  previous: null,
  count: 1,
};

export const initialState = {
  ...dashboardsCommentsInitialState,
  projects: new Map(),
  objectives: new Map(),
  keyResults: new Map(),
  byCustomerRequest: {},
};

const commentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROJECT_COMMENTS_PENDING: {
      if (!action.meta.projectId) {
        return state;
      }

      return {
        ...state,
        projects: state.projects.update(JSON.stringify(action.meta.projectId), () => []),
      };
    }
    case FETCH_PROJECT_COMMENTS_FULFILLED: {
      if (!action.payload.projectId || !action.payload.data) {
        return state;
      }

      return {
        ...state,
        projects: state.projects.update(JSON.stringify(action.payload.projectId), value => {
          return action.payload.data;
        }),
      };
    }
    case FETCH_NEXT_PROJECT_COMMENTS_FULFILLED: {
      if (!action.payload.projectId || !action.payload.data) {
        return state;
      }

      return {
        ...state,
        projects: state.projects.update(JSON.stringify(action.payload.projectId), value => {
          if (!value) {
            return action.payload.data;
          }

          value._meta = action.payload.data._meta;
          value.data = uniqBy([...value.data, ...action.payload.data.data], 'id');

          return value;
        }),
      };
    }
    case SAVE_PROJECT_COMMENT_FULFILLED: {
      if (!action.payload.projectId || !action.payload.data) {
        return state;
      }

      return {
        ...state,
        projects: state.projects.update(JSON.stringify(action.payload.projectId), value => {
          if (!value) {
            return {
              data: [action.payload.data],
              _meta: {
                page: 1,
                next: null,
                previous: null,
                count: 1,
              },
            };
          }

          value._meta.count++;
          value.data = [action.payload.data, ...value.data];

          return value;
        }),
      };
    }
    case FETCH_CUSTOMER_REQUEST_COMMENTS_PENDING: {
      const { customerRequestId } = action.meta || {};

      if (!customerRequestId) {
        return state;
      }

      return {
        ...state,
        byCustomerRequest: {
          ...state.byCustomerRequest,
          [customerRequestId]: {},
        },
      };
    }
    case FETCH_CUSTOMER_REQUEST_COMMENTS_FULFILLED: {
      const { customerRequestId } = action.meta || {};
      const { data, _meta } = action.payload || {};

      if (!customerRequestId || !data || !_meta) {
        return state;
      }

      return {
        ...state,
        byCustomerRequest: {
          ...state.byCustomerRequest,
          [customerRequestId]: { data, _meta },
        },
      };
    }
    case FETCH_NEXT_CUSTOMER_REQUEST_COMMENTS_FULFILLED: {
      const { customerRequestId } = action.meta || {};
      const { data, _meta } = action.payload || {};

      if (!customerRequestId || !data || !_meta) {
        return state;
      }

      const currentCommentsData = get(state, `byCustomerRequest[${customerRequestId}].data`, []);

      return {
        ...state,
        byCustomerRequest: {
          ...state.byCustomerRequest,
          [customerRequestId]: {
            _meta,
            data: uniqBy([...currentCommentsData, ...data], 'id'),
          },
        },
      };
    }
    case SAVE_CUSTOMER_REQUEST_COMMENT_FULFILLED: {
      const { customerRequestId } = action.meta || {};

      if (!customerRequestId || !action.payload) {
        return state;
      }

      const currentCommentsData = get(state, `byCustomerRequest[${customerRequestId}].data`, []);
      const currentCommentsMeta = get(state, `byCustomerRequest[${customerRequestId}]._meta`, defaultCustomerRequestMeta);

      return {
        ...state,
        byCustomerRequest: {
          ...state.byCustomerRequest,
          [customerRequestId]: {
            _meta: {
              ...currentCommentsMeta,
              count: currentCommentsMeta.count + 1,
            },
            data: [action.payload, ...currentCommentsData],
          },
        },
      };
    }
    case DELETE_CUSTOMER_REQUEST_COMMENT_FULFILLED: {
      if (!action.meta.customerRequestId || !action.meta.id) {
        return state;
      }

      const { customerRequestId, id } = action.meta;
      const currentCommentsData = get(state, `byCustomerRequest[${customerRequestId}].data`, []);
      const currentCommentsMeta = get(state, `byCustomerRequest[${customerRequestId}]._meta`, defaultCustomerRequestMeta);

      return {
        ...state,
        byCustomerRequest: {
          ...state.byCustomerRequest,
          [customerRequestId]: {
            _meta: {
              ...currentCommentsMeta,
              count: subtract(currentCommentsMeta.count, 1),
            },
            data: currentCommentsData.filter(comment => comment.id !== id),
          },
        },
      };
    }
    case EDIT_CUSTOMER_REQUEST_COMMENT_FULFILLED: {
      if (!action.meta.customerRequestId || !action.meta.id) {
        return state;
      }

      const { customerRequestId, id } = action.meta;
      const currentComments = get(state, `byCustomerRequest[${customerRequestId}]`, {});
      const currentCommentsData = get(state, `byCustomerRequest[${customerRequestId}].data`, []);

      return {
        ...state,
        byCustomerRequest: {
          ...state.byCustomerRequest,
          [customerRequestId]: {
            ...currentComments,
            data: currentCommentsData.map(comment => {
              return comment.id === id ? action.payload : comment;
            }),
          },
        },
      };
    }
    case FETCH_OBJECTIVE_COMMENTS_PENDING: {
      if (!action.meta.objectiveId) {
        return state;
      }

      return {
        ...state,
        objectives: (state.objectives || new Map()).update(JSON.stringify(action.meta.objectiveId), () => []),
      };
    }
    case FETCH_OBJECTIVE_COMMENTS_FULFILLED: {
      if (!action.payload.objectiveId || !action.payload.data) {
        return state;
      }

      return {
        ...state,
        objectives: state.objectives.update(JSON.stringify(action.payload.objectiveId), value => {
          return action.payload.data;
        }),
      };
    }
    case FETCH_NEXT_OBJECTIVE_COMMENTS_FULFILLED: {
      if (!action.payload.objectiveId || !action.payload.data) {
        return state;
      }

      return {
        ...state,
        objectives: state.objectives.update(JSON.stringify(action.payload.objectiveId), value => {
          if (!value) {
            return action.payload.data;
          }

          value._meta = action.payload.data._meta;
          value.data = uniqBy([...value.data, ...action.payload.data.data], 'id');

          return value;
        }),
      };
    }
    case SAVE_OBJECTIVE_COMMENT_FULFILLED: {
      if (!action.payload.objectiveId || !action.payload.data) {
        return state;
      }

      return {
        ...state,
        objectives: state.objectives.update(JSON.stringify(action.payload.objectiveId), value => {
          if (!value || !Object.keys(value).length) {
            return {
              data: [action.payload.data],
              _meta: {
                page: 1,
                next: null,
                previous: null,
                count: 1,
              },
            };
          }

          value._meta.count++;
          value.data = [action.payload.data, ...value.data];

          return value;
        }),
      };
    }
    case DELETE_OBJECTIVE_COMMENT_FULFILLED: {
      if (!action.meta.objectiveId || !action.meta.id) {
        return state;
      }

      return {
        ...state,
        objectives: state.objectives.update(action.meta.objectiveId.toString(), value => {
          value.data = [...value.data].filter(comment => comment.id !== action.meta.id);
          return value;
        }),
      };
    }
    case EDIT_OBJECTIVE_COMMENT_FULFILLED: {
      if (!action.meta.objectiveId || !action.meta.id) {
        return state;
      }

      return {
        ...state,
        objectives: state.objectives.update(action.meta.objectiveId.toString(), value => {
          value.data = value.data.map(comment => {
            return comment.id === action.meta.id ? action.payload : comment;
          });

          return value;
        }),
      };
    }
    case FETCH_KEY_RESULT_COMMENTS_PENDING: {
      if (!action.meta.keyResultId) {
        return state;
      }

      return {
        ...state,
        keyResults: (state.keyResults || new Map()).update(JSON.stringify(action.meta.keyResultId), () => []),
      };
    }
    case FETCH_KEY_RESULT_COMMENTS_FULFILLED: {
      if (!action.payload.keyResultId || !action.payload.data) {
        return state;
      }

      return {
        ...state,
        keyResults: state.keyResults.update(JSON.stringify(action.payload.keyResultId), value => {
          return action.payload.data;
        }),
      };
    }
    case FETCH_NEXT_KEY_RESULT_COMMENTS_FULFILLED: {
      if (!action.payload.keyResultId || !action.payload.data) {
        return state;
      }

      return {
        ...state,
        keyResults: state.keyResults.update(JSON.stringify(action.payload.keyResultId), value => {
          if (!value) {
            return action.payload.data;
          }

          value._meta = action.payload.data._meta;
          value.data = uniqBy([...value.data, ...action.payload.data.data], 'id');

          return value;
        }),
      };
    }
    case SAVE_KEY_RESULT_COMMENT_FULFILLED: {
      if (!action.payload.keyResultId || !action.payload.data) {
        return state;
      }

      return {
        ...state,
        keyResults: state.keyResults.update(JSON.stringify(action.payload.keyResultId), value => {
          if (!value || !Object.keys(value).length) {
            return {
              data: [action.payload.data],
              _meta: {
                page: 1,
                next: null,
                previous: null,
                count: 1,
              },
            };
          }

          value._meta.count++;
          value.data = [action.payload.data, ...value.data];

          return value;
        }),
      };
    }
    case DELETE_KEY_RESULT_COMMENT_FULFILLED: {
      if (!action.meta.keyResultId || !action.meta.id) {
        return state;
      }

      return {
        ...state,
        keyResults: state.keyResults.update(action.meta.keyResultId.toString(), value => {
          value.data = [...value.data].filter(comment => comment.id !== action.meta.id);
          return value;
        }),
      };
    }
    case EDIT_KEY_RESULT_COMMENT_FULFILLED: {
      if (!action.meta.keyResultId || !action.meta.id) {
        return state;
      }

      return {
        ...state,
        keyResults: state.keyResults.update(action.meta.keyResultId.toString(), value => {
          value.data = value.data.map(comment => {
            return comment.id === action.meta.id ? action.payload : comment;
          });

          return value;
        }),
      };
    }
    case DELETE_PROJECT_COMMENT_FULFILLED: {
      if (!action.meta.projectId || !action.meta.id) {
        return state;
      }

      return {
        ...state,
        projects: state.projects.update(action.meta.projectId.toString(), value => {
          value.data = [...value.data].filter(comment => comment.id !== action.meta.id);
          return value;
        }),
      };
    }
    case EDIT_PROJECT_COMMENT_FULFILLED: {
      if (!action.meta.projectId || !action.meta.id) {
        return state;
      }

      return {
        ...state,
        projects: state.projects.update(action.meta.projectId.toString(), value => {
          value.data = value.data.map(comment => {
            if (comment.id === action.meta.id) {
              return action.payload;
            }

            return comment;
          });

          return value;
        }),
      };
    }
    default:
      return state;
  }
};

const reducer = reduceReducers(initialState, commentsReducer, dashboardsCommentsReducer);

export default reducer;
