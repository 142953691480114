import React from 'react';
import styled from 'styled-components';

import Heading from 'design-system/molecules/Heading/index';

import { spacing } from 'design-system/theme';

export default ({ title, subtitle, actionButtons, children }) => {
  return (
    <Content>
      <Header>
        <Heading title={title} subTitle={subtitle} />
        {actionButtons}
      </Header>
      {children}
    </Content>
  );
};

const Content = styled.div`
  height: 100%;
  min-height: 40px;
  padding: ${spacing(3)}px;
`;

const Header = styled.div`
  padding-bottom: ${spacing(1.5)}px;
`;
