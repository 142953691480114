import React, { useRef, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { not } from 'ramda';

import Arrow from 'design-system/atoms/DragonIcons/ArrowDown';

const DragonNavItem = ({
  icon,
  label,
  badge,
  checked,
  onClick,
  showArrow,
  separator,
  expandable,
  isExpanded,
  onClickExpandableArrow,
  ...props
}) => {
  const expandArrowRef = useRef();

  const SubMenu = props.submenuComponent;
  const expanded = useMemo(() => isExpanded, [isExpanded]);

  const handleClickExpandableArrow = () => {
    if (not(onClickExpandableArrow)) {
      return;
    }

    onClickExpandableArrow(not(expanded));
  };

  const handleClick = e => {
    const hasExpandArrowRef = Boolean(expandArrowRef?.current);
    const targetIsInsideOfExpandArrow = hasExpandArrowRef && expandArrowRef.current.contains(e.target);

    if (targetIsInsideOfExpandArrow) {
      if (checked && hasExpandArrowRef) {
        handleClickExpandableArrow();
      }
      return;
    }

    onClick(e);
  };

  return (
    <Wrapper>
      <ItemContent checked={checked} onClick={handleClick} separator={separator} {...props}>
        {icon && (
          <IconWrapper>
            {icon}
            {badge && <Badge />}
          </IconWrapper>
        )}
        <LabelWrapper>{label}</LabelWrapper>
        {expandable && (
          <ArrowWrapper ref={expandArrowRef} onClick={handleClickExpandableArrow}>
            {expanded ? <ArrowUp /> : <ArrowDown />}
          </ArrowWrapper>
        )}
        {showArrow && (
          <ArrowWrapper>
            <ArrowRight />
          </ArrowWrapper>
        )}
      </ItemContent>
      {expanded && (
        <SubMenuWrapper>
          <SubMenu />
        </SubMenuWrapper>
      )}
      {separator && <Separator />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 9px 0 9px;
`;

const SubMenuWrapper = styled.div`
  padding: 0 6px 16px 6px;
`;

const ArrowWrapper = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 26px;

    svg {
      width: 10px;
      height: 10px;

      path:not([fill='none']) {
        fill: ${({ theme }) => theme.palette.newLayout.background.lightGrey};
      }
    }
  }
`;

const IconWrapper = styled.div`
  &&&& {
    display: flex;
    align-items: center;

    position: relative;

    svg path:not([fill='none']) {
      fill: ${({ theme }) => theme.palette.newLayout.background.disable};
    }

    svg circle,
    svg path:not([fill='none']) {
      color: ${({ theme }) => theme.palette.newLayout.background.disable};
    }
  }
`;

const Badge = styled.div`
  &&&& {
    position: absolute;
    top: 0;
    right: 5px;

    width: 8px;
    height: 8px;

    border-radius: 50%;

    background: ${({ theme }) => theme.palette.newLayout.background.danger};
  }
`;

const LabelWrapper = styled.div`
  &&&& {
    color: ${({ theme }) => theme.palette.newLayout.background.disable};

    font-family: Inter;
    font-style: normal;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    font-size: ${({ theme }) => theme.typography.fontSize}px;
    line-height: 15px;
    position: relative;
  }
`;

const Separator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.newLayout.background.lightBlack};
  margin-bottom: 4px;
`;

const ItemContent = styled.div`
  &&&& {
    padding: 0 6px;
    cursor: pointer;

    display: grid;
    grid-template-columns: 20px 1fr 16px;
    align-items: center;
    gap: 10px;
    position: relative;
    height: 33px;
    margin: 7px 0;

    ${({ separator }) =>
      separator &&
      `
      margin-bottom: 10px;
    `}

    &:hover {
      ${LabelWrapper} {
        color: ${({ theme }) => theme.palette.newLayout.background.primary};
      }

      ${IconWrapper} {
        svg path:not([fill='none']) {
          fill: ${({ theme }) => theme.palette.newLayout.background.primary};
        }
        svg path:not([fill='none']),
        svg circle {
          color: ${({ theme }) => theme.palette.newLayout.background.primary};
        }
      }
    }

    ${({ checked, theme }) =>
      checked &&
      `
    ${LabelWrapper} {
      color: ${theme.palette.newLayout.background.primary};
    }

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      background: ${theme.palette.newLayout.background.header} 0% 0% no-repeat padding-box;
      border-radius: 3px;
      width: 100%;
      height: 100%;
      margin: 0;
    }

    ${IconWrapper} {
      svg path:not([fill='none']) {
        fill: ${theme.palette.newLayout.background.primary};
      }
    }
    `}
  }
`;

const ArrowUp = styled(Arrow)`
  &&&& {
    transform: rotate(-180deg);
  }
`;

const ArrowDown = styled(Arrow)`
  &&&& {
    transform: rotate(0);
  }
`;

const ArrowRight = styled(Arrow)`
  &&&& {
    transform: rotate(-90deg);
  }
`;

DragonNavItem.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  badge: PropTypes.bool,
};

export default DragonNavItem;
