import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';

import { getProjectsEntities } from 'store/projects/selectors';

export default () => {
  const projects = useSelector(state => getProjectsEntities(state));

  const { initiatives, bets } = useMemo(() => {
    const initiatives = [];
    const bets = [];

    Object.entries(projects.byId).forEach(([_, obj]) => {
      if (+obj.layer === +INITIATIVE_LAYER) initiatives.push(obj);
      if (+obj.layer === +BET_LAYER) bets.push(obj);
    });

    return { initiatives, bets };
  }, [projects]);

  const hasHierarchy = useSelector(state => state.organization.organization.has_hierarchy);
  const hasBet = useSelector(state => state.organization.organization.has_bet);

  return [initiatives, bets, hasHierarchy, hasBet];
};
