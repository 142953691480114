import { RoundMode } from 'utils/unitConversion/roundMode';
import { Unit, convertDaysTo } from 'utils/unitConversion/unitConversion';

/**
 * Ceil estimate to nearest decimal number with two decimal places.
 */
export const estimateRounder = new RoundMode(value => {
  return Math.ceil(value * 100) / 100;
});

export const convertEstimateToCalendarWeeks = (days, staff = 1) => {
  return convertDaysTo(staff * days, Unit.CALENDAR_WEEK, estimateRounder);
};
