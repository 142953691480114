import React from 'react';

import useGetOrSaveLastPageView from 'hooks/useGetOrSaveLastPageView';
import { SetScenarioRouteProvider } from 'hooks/useSetScenarioRoute';

const GetScenariosRouteProxyFactory = props => {
  const { history, location } = props;

  const queryString = location.search || '';

  const View = useGetOrSaveLastPageView(location.pathname, queryString, history);

  if (!View) return '';

  return (
    <SetScenarioRouteProvider>
      <View {...props} />
    </SetScenarioRouteProvider>
  );
};

export default GetScenariosRouteProxyFactory;
