import axios from 'axios';
import { omit, dissocPath, defaultTo } from 'ramda';

import { getPageFilters } from 'store/filters/selectors/getPageFilters';
import { getActiveViewState } from './selectors';

import {
  SUBSCRIBE_USER_TO_USER_VIEW,
  SHARE_USER_VIEW,
  UPDATE_USER_VIEW,
  CLONE_USER_VIEW,
  SHARE_VIEW_ON_SLACK,
  UPDATE_USER_VIEW_OWNER_ALL_VIEWS,
  SHARE_VIEW_ON_INTEGRATION,
} from './types';
import { getViewStateFromStoreState } from './helpers';
import { IDEAS_BOARD_PAGE, IDEAS_GRID_PAGE, IDEAS_PDLC_PAGE, ROADMAP_PAGE, MANAGE_PAGE, SUMMARY_PAGE } from 'constants/filters';
import { fetchUserViews } from './actions';
import throwRequestError from 'store/utils/throwRequestError';
import { createThunk } from 'utils/store/thunk';
import { READ_ONLY_VIEW_PROPS } from './consts';

const defaultToEmptyObject = defaultTo({});

const viewsBlacklist = {
  [IDEAS_BOARD_PAGE]: [['ideasBoard', 'showMyItemsOnly']],
  [IDEAS_GRID_PAGE]: [['grids', 'showMyItemsOnly']],
  [IDEAS_PDLC_PAGE]: [['grids', 'showMyItemsOnly']],
  [ROADMAP_PAGE]: [['timelines', 'roadmap', 'showMyItemsOnly']],
  [MANAGE_PAGE]: [['manageState', 'showMyItemsOnly']],
  [SUMMARY_PAGE]: [['summary', 'showMyItemsOnly']],
};

const cleanViewState = view => {
  const pageBlackList = viewsBlacklist[view.page] || [];

  const filteredActiveViewState = pageBlackList.reduce((acc, path) => {
    return dissocPath(path, acc);
  }, view.state);

  const filteredActiveViewForPage = {
    ...view,
    state: filteredActiveViewState,
  };

  return filteredActiveViewForPage;
};

const subscribeUserToTheUserView = key => {
  return {
    payload: axios.put(`/api/userViews/${key}/subscribe/`),
    meta: { key },
    type: SUBSCRIBE_USER_TO_USER_VIEW,
  };
};

const shareUserViewWithOtherUsers = (key, data) => {
  return {
    payload: axios.put(`/api/userViews/${key}/share/`, data),
    meta: { key },
    type: SHARE_USER_VIEW,
  };
};

const cloneView =
  (view, newViewName, newViewDescription = '', setAsActiveOnCloneSuccess = true) =>
  (dispatch, getState) => {
    const viewStateFromStore = getViewStateFromStoreState(getState(), view?.page);

    const viewCloneObject = {
      ...omit(['id', 'key', 'default_view', 'created_at', 'updated_at', 'expiration_date'], view),
      name: newViewName,
      description: newViewDescription,
      state: {
        ...viewStateFromStore,
        ...view.state,
      },
    };

    const cleanView = cleanViewState(viewCloneObject);

    return dispatch({
      type: CLONE_USER_VIEW,
      payload: axios.post('/api/userViews/', cleanView),
      meta: { setAsActiveOnCloneSuccess },
    });
  };

const updateSharedView = view => (dispatch, getState) => {
  const state = getState();

  const activeViewState = getActiveViewState(state, view?.page);
  const filterState = defaultToEmptyObject({ filter: getPageFilters(state) });

  // If for some reason the view we are updating does not have some properties that we defined on the
  // mapping lets add them to state, example: missing filter property
  const viewUpdateRequestBody = {
    ...view,
    state: {
      ...view.state,
      ...activeViewState,
      ...filterState,
    },
  };

  const cleanView = cleanViewState(viewUpdateRequestBody);

  return dispatch({
    type: UPDATE_USER_VIEW,
    payload: axios.post(`/api/userViews/update/${view.id}`, omit(READ_ONLY_VIEW_PROPS, cleanView)),
  });
};

const updateSharedViewWithoutModifyingState = view => dispatch => {
  const cleanView = cleanViewState(view);

  return dispatch({
    type: UPDATE_USER_VIEW,
    payload: axios.post(`/api/userViews/update/${view.id}`, omit(READ_ONLY_VIEW_PROPS, cleanView)),
  });
};

const shareViewOnSlack =
  (view, channel, viewUrl, comment = undefined) =>
  (dispatch, getState) => {
    return dispatch({
      type: SHARE_VIEW_ON_SLACK,
      payload: axios.post(`/api/userViews/${view.id}/slack`, {
        slack_channel: channel,
        view_url: viewUrl,
        slack_comment: comment,
      }),
    });
  };

const updateOwnerForAllUserViews = (userId, newUserId) => {
  return dispatch => {
    const payload = axios
      .put(`/api/userViews/owner/${userId}`, { user_id: newUserId })
      .then(() => dispatch(fetchUserViews()))
      .catch(throwRequestError);

    return dispatch({
      payload,
      type: UPDATE_USER_VIEW_OWNER_ALL_VIEWS,
    });
  };
};

const shareViewOnIntegration = (integrationType, view, viewUrl, channel, comment = null) =>
  createThunk(SHARE_VIEW_ON_INTEGRATION, () =>
    axios.post(`/api/userViews/${view.id}/${integrationType}`, {
      channel,
      view_url: viewUrl,
      comment,
    }),
  );

export {
  cloneView,
  shareUserViewWithOtherUsers,
  shareViewOnIntegration,
  shareViewOnSlack,
  subscribeUserToTheUserView,
  updateOwnerForAllUserViews,
  updateSharedView,
  updateSharedViewWithoutModifyingState,
};
