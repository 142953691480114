import { useCallback, useEffect, useRef } from 'react';

import { BASE_ROW_HEIGHT, LINE_HEIGHT } from 'constants/grid';

const calculate = rowHeight => {
  // prettier-ignore
  return Math.round(((rowHeight - BASE_ROW_HEIGHT) / LINE_HEIGHT) + 1);
};

// Hook to calculate the number of rows to display on AgGrid cells,
// in order to be possible to add ellipsis truncation on multiline text.
// Also accounts a bug with AgGrid's cell renderer params generation between re-renders.
const useNumberOfTextRowsToDisplayOnGridCell = (rowHeight = BASE_ROW_HEIGHT) => {
  // Needs to be a ref updated through useEffect and
  // retrievable via a getter due to a bug with AgGrid.
  const numberOfTextRowsToDisplayOnGridCell = useRef(calculate(rowHeight));

  useEffect(() => {
    numberOfTextRowsToDisplayOnGridCell.current = calculate(rowHeight);
  }, [rowHeight]);

  const getNumberOfTextRowsToDisplayOnGridCell = useCallback(() => {
    return numberOfTextRowsToDisplayOnGridCell.current;
  }, [rowHeight]);

  return {
    numberOfTextRowsToDisplayOnGridCell: numberOfTextRowsToDisplayOnGridCell.current,
    getNumberOfTextRowsToDisplayOnGridCell,
  };
};

export default useNumberOfTextRowsToDisplayOnGridCell;
