import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import RequestsMergedGrid from 'features/RequestsMergedGrid';
import Tabs from 'design-system/organisms/Tabs';
import theme from 'design-system/theme';

import PotentialDuplicatesGrid from '../PotentialDuplicatesGrid';

const EMPTY_ROW = {};

const selectedTabBorderStyles = {
  background: theme.palette.primary.main,
};

const MergedRequests = ({ customerRequest }) => {
  const [requestsToMerge, setRequestsToMerge] = useState([EMPTY_ROW]);

  const tabs = [{ label: 'Search for potential duplicates' }, { label: 'Previously merged items' }];
  const [currentTab, setCurrentTab] = useState(tabs[1].label);

  return (
    <>
      <Tabs
        tabs={tabs}
        selectedTab={currentTab}
        handleTabChange={(e, val) => setCurrentTab(val)}
        tabStyles={tabStyles}
        TabIndicatorProps={{ style: selectedTabBorderStyles }}
      />
      <ContentWrapper>
        {currentTab === tabs[1].label && <RequestsMergedGrid />}
        {currentTab === tabs[0].label && (
          <PotentialDuplicatesGrid customerRequest={customerRequest} data={requestsToMerge} updateData={setRequestsToMerge} />
        )}
      </ContentWrapper>
    </>
  );
};

export default MergedRequests;

const ContentWrapper = styled.div``;

const tabStyles = css`
  white-space: nowrap;
  font-size: ${({ theme }) => theme.typography.lineHeightSmallRem}rem;

  color: ${({ isSelectedTab, theme }) => (isSelectedTab ? theme.palette.primary.main : theme.palette.text.primary)};
`;
