import React, { createContext, useState, useContext, useCallback } from 'react';

import EstimateDialog from 'containers/EstimateDialog';

const EstimatesLightboxContext = createContext({});
const getProjectIdFromEstimate = estimate => estimate?.project_id || estimate?.project?.id;
const EstimatesLightboxProvider = ({ children }) => {
  const [openedEstimate, setOpenedEstimate] = useState();

  const openEstimatesLightbox = useCallback(data => setOpenedEstimate(data), []);
  const closeEstimatesLightbox = () => setOpenedEstimate(null);
  const projectId = getProjectIdFromEstimate(openedEstimate);

  return (
    <EstimatesLightboxContext.Provider value={{ openEstimatesLightbox }}>
      {children}
      <EstimateDialog open={!!openedEstimate} onClose={closeEstimatesLightbox} estimate={openedEstimate} projectId={projectId} />
    </EstimatesLightboxContext.Provider>
  );
};

/**
 * @function useEstimatesLightboxContext
 *
 * hook with react context to persist the current opened estimates lightbox
 *
 * @return {Object}
 */
const useEstimatesLightboxContext = () => useContext(EstimatesLightboxContext);

export { EstimatesLightboxProvider };

export default useEstimatesLightboxContext;
