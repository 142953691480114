import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DragDropContext } from 'react-beautiful-dnd';
import Typography from '@material-ui/core/Typography';
import { not } from 'ramda';
import Tooltip from '@material-ui/core/Tooltip';

import DndMenuList from 'design-system/molecules/DndMenuList/index';

import Inherit from 'design-system/atoms/DragonIcons/Inherit';

import { reorderDraggedElement } from './utils';

import theme from '../../theme';

const LEFT_AREA = 'leftFields';
const RIGHT_AREA = 'rightFields';
const BOTTOM_AREA = 'hiddenFields';

const DROPPABLE_TYPE = 'configuration';

const onDraggingStyle = {
  backgroundColor: theme.palette.background.lightBlue,
  border: `1px dashed ${theme.palette.border.darkBlue}`,
  borderRadius: '4px',
  strokeWidth: '2px',
};

const InheritButton = ({ active }) => {
  return (
    <Tooltip title="Propagate">
      <InheritIconWrapper active={active}>
        <Inherit />
      </InheritIconWrapper>
    </Tooltip>
  );
};

const renderList = (parentId, items, draggable, isDragging, itemRightActions, checkable, handleSelectItems, selectedItems) => (
  <DndMenuList
    parentId={parentId}
    items={items}
    type={DROPPABLE_TYPE}
    draggable={draggable}
    isDragging={isDragging}
    onDraggingStyle={onDraggingStyle}
    itemRightActions={itemRightActions}
    checkable={checkable}
    onSelectItem={handleSelectItems}
    selectedItems={selectedItems}
  />
);

/**
 * @deprecated
 *
 * Please check new FieldsLayoutConfigurator for new developments
 */
const ProjectFieldsLayoutConfigurator = ({
  fieldsLayout,
  fieldsInherit,
  fieldsRequired,
  draggable = true,
  onChange,
  allowToInherit,
  editable,
  enableRequiredFields,
  allowToBeRequired,
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const _onDragStart = () => {
    setIsDragging(true);
  };

  const _onDragEnd = ({ destination, source, draggableId }) => {
    setIsDragging(false);
    const newFieldsLayout = reorderDraggedElement(Object.assign({}, fieldsLayout), destination, source, draggableId);

    if (onChange) onChange({ fieldsLayout: newFieldsLayout });
  };

  const _renderInheritIcon = item => {
    if (allowToInherit && allowToInherit(item.id)) {
      return <InheritButton editable={editable} active={fieldsInherit[item.id]?.inherit} />;
    }

    return '';
  };

  const _onClickInheritButton = item => {
    if (editable && onChange && allowToInherit && allowToInherit(item.id)) {
      const newFieldsInherit = {
        ...fieldsInherit,
        [item.id]: {
          inherit: !fieldsInherit[item.id]?.inherit,
        },
      };

      onChange({ fieldsInherit: newFieldsInherit });
    }
  };
  const _handleSelectItems = itemId => {
    const newFieldsRequired = {
      ...fieldsRequired,
      [itemId]: {
        required: !fieldsRequired[itemId]?.required,
      },
    };

    onChange({ fieldsRequired: newFieldsRequired });
  };

  const rightActionsWhenIsNotDragging = [
    {
      renderIcon: _renderInheritIcon,
      onClick: _onClickInheritButton,
    },
  ];

  const itemRightActions = [...(not(isDragging) ? rightActionsWhenIsNotDragging : [])];
  const checkable = fieldId => enableRequiredFields && editable && allowToBeRequired(fieldId);
  const selectedRequiredFields = Object.keys(fieldsRequired).reduce((acc, fieldId) => {
    return { ...acc, [fieldId]: fieldsRequired[fieldId]?.required };
  }, {});

  return (
    <DragDropContext onDragStart={_onDragStart} onDragEnd={_onDragEnd}>
      <RowWrapper>
        <TopListWrapper>
          {renderList(
            LEFT_AREA,
            fieldsLayout[LEFT_AREA].items,
            editable && draggable,
            isDragging,
            itemRightActions,
            checkable,
            _handleSelectItems,
            selectedRequiredFields,
          )}
        </TopListWrapper>
        <StyledLineSeparator />
        <TopListWrapper>
          {renderList(
            RIGHT_AREA,
            fieldsLayout[RIGHT_AREA].items,
            editable && draggable,
            isDragging,
            itemRightActions,
            checkable,
            _handleSelectItems,
            selectedRequiredFields,
          )}
        </TopListWrapper>
      </RowWrapper>
      <BottomListWrapper>
        <StyledHideMessage variant="body2" color="textSecondary">
          {editable && 'Drag fields below to hide'}
        </StyledHideMessage>
        {renderList(BOTTOM_AREA, fieldsLayout[BOTTOM_AREA].items, editable && draggable, isDragging)}
      </BottomListWrapper>
    </DragDropContext>
  );
};

const InheritIconWrapper = styled.div`
  ${({ active }) =>
    active &&
    `
    svg {
      opacity: 1;
    }
  `}

  ${({ active }) =>
    !active &&
    `
    svg {
      opacity: 0.1;
    }
  `}

  ${({ editable }) =>
    editable &&
    `
    &:hover {
      svg {
        opacity: 0.6;
      }
    }
  `}
`;

const StyledLineSeparator = styled.div`
  border-left: 1px solid ${theme.palette.border.gray};
  position: absolute;
  left: calc(50% - 0.5px);
  top: 5%;
  bottom: 0;
`;

const RowWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 380px;
  display: flex;
  margin-bottom: 16px;
  justify-content: stretch;
  border-bottom: 1px solid ${theme.palette.border.gray};
`;

const TopListWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  &&&& > div {
    height: 100%;
    border: 1px dashed transparent;
    margin: 0 7px;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const BottomListWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  border-bottom: 1px solid ${theme.palette.border.gray};
  transition: all ease 0.4s;

  &&&& > div {
    position: relative;
    height: 91px;
    border: 1px dashed transparent;
    margin: 0 7px;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;
const StyledHideMessage = styled(Typography)`
  padding-left: 25px;
`;

const ItemPropTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  color: PropTypes.string,
};

const ListPropTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  items: PropTypes.arrayOf(PropTypes.shape(ItemPropTypes)).isRequired,
};

ProjectFieldsLayoutConfigurator.propTypes = {
  fieldsLayout: PropTypes.arrayOf(PropTypes.shape(ListPropTypes)),
};

export default ProjectFieldsLayoutConfigurator;
