import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import getStateDataForPage from 'store/utils/getStateDataForPage';
import { getUsers } from 'store/users/selectors';
import { getTeams } from 'store/teams/selectors';
import { getSkills } from 'store/skills/selectors';
import { calculateGridWidth } from 'components/ganttCommon';
import renderHCResourceLine from './helpers/renderHCResourceLine';
import generateHCTableResources from './helpers/generateHCTableResources';
import { fetchUsers } from 'store/users';

import { INCLUDE_ALL_OPTION } from 'constants/projects';

let onBeforeParseEventID = '';

const useTimelineHCTable = ({
  selectedHighlight,
  showResourceUsers,
  resourceUsersGroup,
  selectedTimelineInterval,
  selectedRounding,
  onHCTableCellClick,
  selectedTeam,
  HCTableFilter,
  tasks,
  showUncommittedProjects = INCLUDE_ALL_OPTION,
}) => {
  const teams = useSelector(state => getStateDataForPage(state, getTeams, 'teams'));
  const skills = useSelector(state => getStateDataForPage(state, getSkills, 'skills'));
  const users = useSelector(state => getStateDataForPage(state, getUsers, 'users'));

  const resourcesData = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  resourcesData.current = {
    teams,
    skills,
    users,
    HCTableFilter,
    tasks,
    showResourceUsers,
    resourceUsersGroup,
    showUncommittedProjects,
  };

  let revisionsTimeout;

  const initLayout = ganttInstance => {
    if (onBeforeParseEventID) {
      ganttInstance.detachEvent(onBeforeParseEventID);
    }

    ganttInstance.getDatastore('task').attachEvent('onStoreUpdated', (id, item, mode) => {
      const later = () => {
        if (ganttInstance.$resourcesStore) ganttInstance.$resourcesStore.refresh();
      };

      clearTimeout(revisionsTimeout);
      revisionsTimeout = setTimeout(later, 200);
    });

    const resourceLayers = [
      (resource, timeline) =>
        renderHCResourceLine({
          resource,
          timeline,
          gantt: ganttInstance,
          selectedTeam,
          selectedHighlight,
          selectedTimelineInterval,
          selectedRounding,
          onHCTableCellClick,
          showResourceUsers,
          showUncommittedProjects,
        }),
    ];
    const _getSkillTitle = resource =>
      `${resource.skillTitle} ${resource.skillTitle && resource.skillStaff ? `(${resource.skillStaff} staff)` : ''}`;

    ganttInstance.config.layout.rows.push({
      cols: [
        {
          view: 'grid',
          id: 'resourceGrid',
          width: calculateGridWidth(ganttInstance),
          bind: 'resource',
          config: {
            columns: [
              {
                name: 'name',
                align: 'center',
                label:
                  showResourceUsers && !resourceUsersGroup
                    ? 'User'
                    : showResourceUsers && resourceUsersGroup === 'skillAndTeam'
                    ? 'Skill'
                    : 'Team',
                tree: showResourceUsers && resourceUsersGroup,
                width: 287,
                template: resource =>
                  showResourceUsers && resourceUsersGroup === 'skillAndTeam' ? _getSkillTitle(resource) : resource.teamTitle,
              },
              {
                name: 'skill',
                align: 'center',
                label:
                  showResourceUsers && !resourceUsersGroup
                    ? ''
                    : showResourceUsers && resourceUsersGroup === 'skillAndTeam'
                    ? 'Team'
                    : 'Skill',
                width: 287,
                template: resource =>
                  showResourceUsers && resourceUsersGroup === 'skillAndTeam' ? resource.teamTitle : _getSkillTitle(resource),
              },
            ],
          },
          scrollY: 'resourceVScroll',
        },
        { resizer: true, width: 1 },
        {
          view: 'timeline',
          id: 'resourceTimeline',
          scrollX: 'scrollHor',
          bind: 'resource',
          bindLinks: null,
          layers: resourceLayers,
          scrollY: 'resourceVScroll',
          css: selectedHighlight && selectedHighlight.key === 'overUnder' ? 'overUnder' : '',
        },
        { view: 'scrollbar', scroll: 'y', id: 'resourceVScroll' },
      ],
      gravity: 1,
    });

    // move scroll bar to the end
    ganttInstance.config.layout.rows.splice(
      ganttInstance.config.layout.rows.findIndex(r => r.view === 'scrollbar'),
      1,
    );
    ganttInstance.config.layout.rows.push({ view: 'scrollbar', scroll: 'x', id: 'scrollHor' });

    ganttInstance.$resourcesStore = ganttInstance.createDatastore({
      name: 'resource',
      type: 'treeDatastore',
      initItem: item => {
        // item.open = false;
        return item;
      },
    });

    const onBeforeParse = () => {
      if (ganttInstance.$resourcesStore) {
        const resources = generateHCTableResources(resourcesData.current || {});

        ganttInstance.$resourcesStore.parse([]);
        ganttInstance.$resourcesStore.parse(resources);
      }
    };

    onBeforeParseEventID = ganttInstance.attachEvent('onBeforeParse', onBeforeParse);
  };

  return initLayout;
};

export default useTimelineHCTable;
