import { useCallback } from 'react';

import validateURL from 'utils/validateURL';

const useUrlUtils = () => {
  const getQueryParam = useCallback((targetUrl, paramName) => {
  const queryParams = new URL(targetUrl)?.searchParams;

    return queryParams.get(paramName);
  }, []);

  const isUrlValid = useCallback(targetUrl => validateURL(targetUrl), []);

  return {
    getQueryParam,
    isUrlValid,
  };
};

export default useUrlUtils;
