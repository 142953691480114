import React, { useMemo } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { FeatureFlags } from '@dragonboat/config';

import { SUMMARY_PAGE } from 'constants/filters';

import normalizeArray from 'utils/normalizeArray';

import { createTheme } from 'store/themes';
import { createPhase } from 'store/phases';
import { showImportIdeasLightbox } from 'store/ideas';
import { createProject, updateProject, switchProjectRowOrder } from 'store/projects';
import { createRoadmap } from 'store/roadmaps';
import { createTag } from 'store/tags';
import { createCategory } from 'store/categories';
import { createObjective } from 'store/objectives';
import { createTimeframe } from 'store/timeframes';
import { openProjectLightbox } from 'store/projectLightbox';
import { createCustomer } from 'store/customers';
import { getCurrentUser } from 'store/login/selectors';
import { selectOrganization } from 'store/organization/selectors';
import { makeSelectFilteredProjectsByRoadmapForPage } from 'store/filters/selectors';

import useOrganizations from 'hooks/useOrganizations';
import useUserPermissions from 'hooks/useUserPermissions';
import useLoadProjectsForTransactionPages from 'hooks/projects/useLoadProjectsForTransactionPages';
import { checkOrganizationFlags } from 'hooks/useFeatureFlags';

import Summary from './Summary';
import useMetadata from './hooks/useMetadata';
import useRoadmapVersions from 'hooks/useRoadmapVersions';
import usePageFilters from 'hooks/filters/usePageFilters';
import { getPageIdFromPath } from 'utils/userViews';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import useLoadProjectsForSummary from 'features/PortfolioSummary/Grouping/hooks/useLoadProjectsForSummary';

const hasSummaryLazyLoading = checkOrganizationFlags({}, [FeatureFlags.HAS_SUMMARY_LAZY_LOADING]);

const useLoadProjectsHook = hasSummaryLazyLoading ? useLoadProjectsForSummary : useLoadProjectsForTransactionPages;

const getFilteredProjectsForPage = makeSelectFilteredProjectsByRoadmapForPage();

const withHooksHOC = Component => {
  return props => {
    const { isTrial } = useOrganizations();

    const [areProjectsLoaded] = useLoadProjectsHook(SUMMARY_PAGE);

    const { pageFilters, displayLayer } = usePageFilters(SUMMARY_PAGE);
    const currentUser = useSelector(getCurrentUser);
    const organization = useSelector(selectOrganization);
    const projects = useSelector(state => getFilteredProjectsForPage(state, SUMMARY_PAGE, true, displayLayer), isEqual);

    const { isRoadmapVersionSelected } = useRoadmapVersions();

    const { canView } = usePermissions();

    const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

    const normalizedProjects = useMemo(() => normalizeArray(projects, 'id'), [projects]);

    const { isAdmin, isOwner, isSuperAdmin, isLeaderUser, isEditor, isManager } = useUserPermissions();

    const hasEditPermissions = useMemo(
      () => isAdmin || isOwner || isSuperAdmin || isLeaderUser || isEditor || isManager,
      [isAdmin, isOwner, isSuperAdmin, isLeaderUser, isEditor, isManager],
    );

    const metadata = useMetadata(organization, projects);

    const currentPageId = getPageIdFromPath(window?.location?.pathname);
    const hasShowMyItemFilter = currentPageId === SUMMARY_PAGE;

    return (
      <Component
        {...props}
        isLoading={!areProjectsLoaded}
        projects={projects}
        normalizedProjects={normalizedProjects}
        metadata={metadata}
        isTrial={isTrial}
        currentUser={currentUser}
        displayLayer={displayLayer}
        hasEditPermissions={hasEditPermissions}
        pageFilters={pageFilters}
        isWorkingWithRoadmapVersion={isRoadmapVersionSelected}
        showControlsForUser={shouldDisplayControlsBar}
        hasShowMyItemFilter={hasShowMyItemFilter}
      />
    );
  };
};

export default compose(
  connect(() => ({}), {
    createProject,
    updateProject,
    switchProjectRowOrder,
    createObjective,
    createTimeframe,
    createRoadmap,
    createTheme,
    createPhase,
    createCategory,
    createTag,
    createCustomer,
    showImportIdeasLightbox,
    openProjectLightbox,
  }),
  withHooksHOC,
)(Summary);
