import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getQueryParamFromUrl } from 'utils/queryParamsUtils';

import { COMPARE_SNAPS_SELECTED_SNAPS_QUERY_PARAM, COMPARE_SNAPS_VIEW_QUERY_PARAM } from '../constants';
import { loadCompareRoadmapHistoryData } from 'features/RoadmapHistory/store';
import { selectCurrentViewId, selectIsLoadingCompareData } from 'features/RoadmapHistory/store/selectors';
import { removeAllSelectedSnapToCompare } from 'features/RoadmapHistory/store/actions';

const useLoadProjectsForSnapComparison = () => {
  const dispatch = useDispatch();
  const viewToCompareId = getQueryParamFromUrl(COMPARE_SNAPS_VIEW_QUERY_PARAM);
  const currentViewId = useSelector(selectCurrentViewId);
  const isLoading = useSelector(selectIsLoadingCompareData);

  useEffect(() => {
    if (viewToCompareId && String(currentViewId) !== viewToCompareId && !isLoading) {
      dispatch(removeAllSelectedSnapToCompare());

      const value = getQueryParamFromUrl(COMPARE_SNAPS_SELECTED_SNAPS_QUERY_PARAM);

      const selectedSnapsOnUrl = (value || '')
        .split(',')
        .map(v => Number(v))
        .filter(v => v && !Number.isNaN(v));

      dispatch(loadCompareRoadmapHistoryData(+viewToCompareId, selectedSnapsOnUrl));
    }
  }, [viewToCompareId, currentViewId, isLoading]);
};

export default useLoadProjectsForSnapComparison;
