import { getUserName } from 'utils';

export const templatesColumns = {
  1: [
    {
      header: 'Product',
      map: row => row.roadmap_id.title,
      width: 50,
    },
    {
      header: 'Initiative',
      map: row => row.parent_id.title,
      width: 100,
    },
    {
      header: 'Idea',
      map: row => row.title,
      width: 100,
    },
    {
      header: 'Objective',
      map: row => row.objective_id.title,
      width: 100,
    },
    {
      header: 'Quarter',
      map: row => row.timeframe_id.title,
      width: 50,
    },
    {
      header: 'Owner',
      map: row => getUserName(row.owner_id),
      width: 50,
    },
  ],
  2: [
    {
      header: 'Value Stream',
      map: row => row.roadmap_id.title,
      width: 50,
    },
    {
      header: 'Initiative (SAFe EPIC)',
      map: row => row.parent_id.title,
      width: 100,
    },
    {
      header: 'Idea (Feature)',
      map: row => row.title,
      width: 100,
    },
    {
      header: 'Objective',
      map: row => row.objective_id.title,
      width: 100,
    },
    {
      header: 'PI',
      map: row => row.timeframe_id.title,
      width: 50,
    },
    {
      header: 'Owner',
      map: row => getUserName(row.owner_id),
      width: 50,
    },
  ],
  3: [
    {
      header: 'Squad',
      map: row => row.roadmap_id.title,
      width: 50,
    },
    {
      header: 'Initiative (may cross squad)',
      map: row => row.parent_id.title,
      width: 100,
    },
    {
      header: 'Idea (feature)',
      map: row => row.title,
      width: 100,
    },
    {
      header: 'Goal',
      map: row => row.objective_id.title,
      width: 100,
    },
    {
      header: 'Quarter',
      map: row => row.timeframe_id.title,
      width: 50,
    },
    {
      header: 'Owner',
      map: row => getUserName(row.owner_id),
      width: 50,
    },
  ],
  4: [
    {
      header: 'Client',
      map: row => row.roadmap_id.title,
      width: 100,
    },
    {
      header: 'Initiative',
      map: row => row.parent_id.title,
      width: 100,
    },
    {
      header: 'Idea',
      map: row => row.title,
      width: 100,
    },
    {
      header: 'Goal',
      map: row => row.objective_id.title,
      width: 100,
    },
    {
      header: 'Quarter',
      map: row => row.timeframe_id.title,
      width: 50,
    },
    {
      header: 'Owner',
      map: row => getUserName(row.owner_id),
      width: 50,
    },
  ],
  5: [
    {
      header: 'Workstream',
      map: row => row.roadmap_id.title,
      width: 100,
    },
    {
      header: 'Initiative',
      map: row => row.parent_id.title,
      width: 100,
    },
    {
      header: 'Idea',
      map: row => row.title,
      width: 100,
    },
    {
      header: 'Goal',
      map: row => row.objective_id.title,
      width: 100,
    },
    {
      header: 'Quarter',
      map: row => row.timeframe_id.title,
      width: 50,
    },
    {
      header: 'Owner',
      map: row => getUserName(row.owner_id),
      width: 50,
    },
  ],
  6: [
    {
      header: 'Business Unit',
      map: row => row.roadmap_id.title,
      width: 100,
    },
    {
      header: 'Initiative',
      map: row => row.parent_id.title,
      width: 100,
    },
    {
      header: 'Idea',
      map: row => row.title,
      width: 100,
    },
    {
      header: 'Goal',
      map: row => row.objective_id.title,
      width: 100,
    },
    {
      header: 'Quarter',
      map: row => row.timeframe_id.title,
      width: 50,
    },
    {
      header: 'Owner',
      map: row => getUserName(row.owner_id),
      width: 50,
    },
  ],
};
