import { useState, useEffect } from 'react';

import filterViewsBySearch from 'utils/userViews/filterViewsBySearch';

/**
 * @function useSearchViews
 *
 * Returns the search query and an handler to handle the logic for searching on a views list
 *
 * @return {array} views - Array with all the views being displayed
 * @return {Function} setViews - Function to to set views so we can filter them
 */

const useSearchViews = (views, setViews) => {
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (!searchText) {
      setViews(views);
    } else {
      handleSearchViews(searchText);
    }
  }, [views]);

  const handleSearchViews = search => {
    setSearchText(search);

    if (search) {
      const filtered = filterViewsBySearch(views, search);

      setViews(filtered);
    } else {
      setViews(views);
    }
  };

  return {
    searchText,
    handleSearchViews,
  };
};

export default useSearchViews;
