import { useEffect, useState } from 'react';
import queryString from 'query-string';

export default (location) => {
  const [error, setError] = useState('');
  const [qs, setQs] = useState(null);

  useEffect(() => {
    const params = queryString.parse(location.search);

    setQs(params);
    const { error: oktaError } = params;

    if (oktaError) {
      switch (oktaError) {
        case 'access_denied':
          setError('Please contact your Okta admin for access to the dragonboat application.');
          break;
        default:
          setError('Please contact your Okta admin for access to the dragonboat application.');
          break;
      }
    }
  }, []);

  return [qs, error, setQs, setError];
};
