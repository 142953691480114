import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrganizationSystemFieldsNames } from 'store/organization';

import {
  fetchProjectDeliverableHistory,
  fetchProjectDeliverableHistoryNextPage,
  selectProjectDeliverableHistory,
  selectProjectDeliverableHistoryNextPage,
} from '../../store';

const useProjectDeliverableHistory = ({ projectDeliverableId }) => {
  const dispatch = useDispatch();

  const projectDeliverableHistory = useSelector(state => selectProjectDeliverableHistory(state, projectDeliverableId));
  const projectDeliverableHistoryNextPage = useSelector(state =>
    selectProjectDeliverableHistoryNextPage(state, projectDeliverableId),
  );
  const systemFields = useSelector(getOrganizationSystemFieldsNames);
  const hasNext = !!projectDeliverableHistoryNextPage;

  const fetchNextPage = () => {
    if (hasNext) {
      dispatch(fetchProjectDeliverableHistoryNextPage(projectDeliverableId, projectDeliverableHistoryNextPage));
    }
  };

  useEffect(() => {
    if (projectDeliverableId) {
      dispatch(fetchProjectDeliverableHistory(projectDeliverableId));
    }
  }, [projectDeliverableId]);

  return {
    projectDeliverableHistory,
    hasNext,
    systemFields,
    fetchNextPage,
  };
};

export default useProjectDeliverableHistory;
