import moment from 'moment-timezone';
import { defaultTo } from 'ramda';

const defaultToEmptyObject = defaultTo({});

/**
 * @function assignIntegrationProgressToProjects creates a new list of project with the respective integration progress
 * @param  {Array} projects List of projects
 * @param  {Object} intProgressByProj Object with integration progres by project
 * @return {Array} List of projects with new integration progress field
 */
const assignIntegrationProgressToProjects = (projects, intProgressByProj) => {
  return projects.map(project => {
    const [integrationProgress] = intProgressByProj[project.id]
      ? intProgressByProj[project.id].sort((a, b) => moment(a) - moment(b))
      : [];

    return {
      ...project,
      integrationProgress: defaultToEmptyObject(integrationProgress),
    };
  });
};

export default assignIntegrationProgressToProjects;
