import { F } from 'ramda';

import useCustomerRequestsGrid from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsGrid';
import useCustomerRequestsGridMetadata from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsGridMetadata';

const EMPTY_ROW = {};

/**
 * Custom hook for managing potential duplicates grid in the customer requests detailed view.
 *
 * @returns {Object} An object containing the following properties and functions:
 *   - requests: An array of potential duplicate requests.
 *   - handleGridReady: A function to handle the grid ready event.
 *   - gridContext: An object containing metadata for the grid.
 *   - removeUnsavedRows: A function to remove unsaved rows.
 *   - setDirtyViewMutex: A function to set the dirty view mutex.
 *   - addPotentialDuplicateRequest: A function to add a potential duplicate request.
 *   - removePotentialDuplicateRequest: A function to remove a potential duplicate request.
 *   - cleanTableData: A function to clean the table data.
 */
const usePotentialDuplicatesGrid = ({ customerRequest, data, updateData }) => {
  const { handleGridReady } = useCustomerRequestsGrid({ saveColumnsState: F });

  const { metadata: requestsGridMetadata } = useCustomerRequestsGridMetadata();

  const addPotentialDuplicateRequest = entity => {
    const filterDuplicates = r => r.id !== entity.id;
    const filterEmptyRow = r => Boolean(r.id);

    updateData(prevRows => [EMPTY_ROW, entity, ...prevRows.filter(r => filterEmptyRow(r) && filterDuplicates(r))]);
  };

  const removePotentialDuplicateRequest = entity => {
    updateData(prevRows => prevRows.filter(r => r.id !== entity.id));
  };

  const cleanTableData = () => updateData([EMPTY_ROW]);

  return {
    handleGridReady,
    gridContext: { metadata: requestsGridMetadata, data, customerRequestId: customerRequest?.id },

    removeUnsavedRows: F,
    setDirtyViewMutex: F,
    addPotentialDuplicateRequest,
    removePotentialDuplicateRequest,
    cleanTableData,
  };
};

export default usePotentialDuplicatesGrid;
