/* eslint jsx-a11y/anchor-is-valid: 0 */
// External dependencies
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { isEmpty, not } from 'ramda';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import CloseButton from 'components/DialogTitle/CloseButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';
import ViewWeekIcon from '@material-ui/icons/ViewWeekOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import Button from '@material-ui/core/Button';

// dragonboat dependencies
import LightBox from 'components/LightBox';
import IdeasCopyPasteLightbox from 'containers/IdeasCopyPasteLightbox';
import MapImportDataFields from 'containers/MapImportDataFields';
import afterProcessClipboardDataFactory from 'components/DataGrid/afterProcessClipboardDataFactory';
import onRowSelectedFactory from 'utils/onRowSelectedFactory';
import Strong from 'design-system/atoms/Strong/index';
import PageLink from 'design-system/atoms/PageLink/index';
import Text from 'design-system/atoms/Text';
import { spacing } from 'design-system/theme';

import EndOfImportComponent from '../EndOfImportComponent/EndOfImportComponent';
import ImportJQLStepOne from './ImportJQLStepOne';
import { DEFAULT_FIELDS_MAPPING } from './utils';
import useSinglePurposeSocket from 'hooks/useSinglePurposeSocket';
import { getMappingByJiraTicketId, hasIntegrationProjectsMapping } from 'utils/mapping';

import { FIELDS_MAPPING_TYPES_FOR_IMPORT, IMPORT_JQL_PREVIEW_MESSAGE } from 'constants/integrations';

const hoc = Component => {
  return props => {
    const socket = useSinglePurposeSocket();

    return <Component {...props} socket={socket} />;
  };
};

class ImportFromJQL extends Component {
  state = {
    currentStep: PropTypes.number,
    totalTasks: PropTypes.number,
    fields: PropTypes.array,
    jiraUrl: PropTypes.string,
    mappedFields: PropTypes.array,
    jiraData: PropTypes.array,
    waitForApi: PropTypes.bool,
    completed: PropTypes.object,
    jiraErrors: PropTypes.any,
    badValues: PropTypes.array,
  };

  constructor(params) {
    super(params);

    this.state = {
      searched: false,
      totalTasks: 0,
      currentStep: 0,
      notImported: 0,
      jiraUrl: '',
      fields: [],
      jiraData: [],
      defaultJiraData: [],
      waitForApi: false,
      completed: {},
      mappedFields: [],
      jiraErrors: null,
      selectedItems: [],
      syncJiraDataStatus: null,
      syncCompleted: false,
      imported: 0,
      existing: 0,
      portfolioImport: false,
      defaultFieldsMapping: DEFAULT_FIELDS_MAPPING,
      orgIntegration: null,
      hasSavedMappingForJiraProjects: false,
      selectedFieldsMappingType: '',
      selectedOrgIntegration: null,
    };

    this.selectedItems = [];
    this.searchForTasks = this.searchForTasks.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.nextButtonDisabled = this.nextButtonDisabled.bind(this);
    this.onItemSelected = this.onItemSelected.bind(this);
    this.isSavedFieldMappingSelected = this.isSavedFieldMappingSelected.bind(this);
    this.bulkCreateAndUpdateData = afterProcessClipboardDataFactory(this.props.bulkCreate, this.props.bulkUpdate);
  }

  isSavedFieldMappingSelected() {
    const { hasJiraFieldMappingByProject } = this.props;
    const { selectedFieldsMappingType } = this.state;

    return hasJiraFieldMappingByProject || selectedFieldsMappingType === FIELDS_MAPPING_TYPES_FOR_IMPORT.SAVED_MAPPING;
  }

  searchForTasks(jiraUrl) {
    const { searchForTasksByJiraUrl, orgIntegrations } = this.props;

    const orgIntegration = orgIntegrations.find(i => jiraUrl.includes(i.data.base_url));

    if (!orgIntegration) return;

    const jiraConfig = orgIntegration.data;

    this.setState({ waitForApi: true, jiraErrors: null, selectedOrgIntegration: orgIntegration.id });
    searchForTasksByJiraUrl(orgIntegration.id, jiraUrl)
      .then(resp => {
        // success
        if (resp && resp.data) {
          const { total, fields } = resp.data;
          const mappedFields = getMappingByJiraTicketId(jiraConfig).map(f => ({
            ...f,
            col: fields ? (fields.find(j => j.key === f.jiraKey) || {}).name : null,
            deletedOnJira: fields && !fields.find(j => j.key === f.jiraKey),
          }));

          const portfolioImport = false;
          const { defaultFieldsMapping } = this.state;

          this.setState({
            waitForApi: false,
            searched: true,
            totalTasks: total,
            fields,
            mappedFields,
            jiraUrl,
            defaultFieldsMapping,
            portfolioImport,
            orgIntegration,
            hasSavedMappingForJiraProjects: hasIntegrationProjectsMapping(jiraConfig),
          });
          // error
        }
      })
      .catch(err => {
        if (!err.response) throw err;
        const error = err.response.data;

        console.log(error);

        let jiraErrors = null;

        if (error.error_code === 'INVALID_JIRA_REQUEST') {
          jiraErrors = error._data;
        } else if (error.error_code === 'JIRA_MISSING_JQL_ON_URL') {
          jiraErrors =
            'Enter your Jira URL containing the JQL. Please do not use saved Jira filter, and use Jira issue search instead.';
        }

        toast('Error fetching the jira EPICs');
        this.setState({
          waitForApi: false,
          totalTasks: 0,
          fields: [],
          jiraErrors,
        });
      });
  }

  previewSearchedTasks(next, stepsToFinal = 3) {
    const { jiraUrl, fields, mappedFields, orgIntegration } = this.state;
    const { previewTasksByJiraUrl } = this.props;

    if (!orgIntegration) return;

    const finalFields = this.isSavedFieldMappingSelected()
      ? null
      : mappedFields
          .filter(d => d.col)
          .map(d => ({
            jiraKey: fields.find(f => f.name === d.col).key,
            jiraField: fields.find(f => f.name === d.col),
            field: d.field,
            dbAutoSync: d.dbAutoSync,
            jiraAutoSync: d.jiraAutoSync,
          }));

    this.setState({ waitForApi: true });
    previewTasksByJiraUrl(orgIntegration.id, jiraUrl, finalFields)
      .then(previewBatch => {
        const { result, badValues } = previewBatch.data;

        const firstPageResults = result.slice(0, 100);

        this.setState({
          waitForApi: false,
          totalJiraData: result,
          jiraData: firstPageResults,
          defaultJiraData: result,
          notImported: firstPageResults.filter(d => d.exists).length,
          jiraDataPreviewedCursor: firstPageResults.length,
          cursorStart: 0,
          cursorEnd: firstPageResults.length - 1,
          badValues,
        });

        if (isEmpty(result)) {
          // if we have no results, jump to step 4 directly
          this.setState({
            syncJiraDataStatus: 'fulfilled',
          });
          next(stepsToFinal);
        } else {
          next(stepsToFinal - 1);
        }
      })
      .catch(err => {
        toast('Error fetching preview data from jira EPICs');
        this.setState({
          waitForApi: false,
          jiraData: [],
        });
      });
  }

  importFromJql(socketRoom = null) {
    const { insertJirasForProjects } = this.props;
    const { orgIntegration } = this.state;

    // NOTE: this should be using the value from the redux store, needs refactoring
    return insertJirasForProjects(orgIntegration.id, this.state.jiraUrl, socketRoom);
  }

  getSteps() {
    return ['Search EPICs', 'Map data fields', 'Preview', 'Complete Import'];
  }

  getFinalUrl = () => {
    // PROD-1190 if product manager, redirect to board, otherwise progress page
    if (this.props.organization && this.props.organization.function_role_id === 1) {
      return '/ideas/board';
    }

    return '/ideas/timeline';
  };

  handlePrev() {
    const { currentStep, completed } = this.state;

    if (currentStep === 2) {
      this.setState({
        jiraData: [],
        selectedItems: [],
        currentStep: this.isSavedFieldMappingSelected() ? 0 : 1,
        completed: { ...completed, ...{ 1: false } },
      });
    } else {
      this.setState({
        currentStep: currentStep - 1,
      });
    }
  }

  async handleNext() {
    const { currentStep, jiraData, orgIntegration } = this.state;
    let { completed } = this.state;

    const moveOnToNextStep = (stepIncrement = 1, disableLoading = true) => {
      if (stepIncrement === 1) {
        completed = { ...completed, ...{ [currentStep]: true } };
      } else {
        completed = { ...completed, ...{ [currentStep]: true, [currentStep + stepIncrement]: true } };
      }
      this.setState({
        completed,
        currentStep: currentStep + stepIncrement,
        waitForApi: !disableLoading,
      });
    };

    if (currentStep === 0) {
      if (this.isSavedFieldMappingSelected()) {
        this.previewSearchedTasks(moveOnToNextStep);
      } else {
        moveOnToNextStep();
      }
    } else if (currentStep === 1) {
      this.previewSearchedTasks(moveOnToNextStep, 2);
    } else if (currentStep === 2) {
      if (jiraData instanceof Array) {
        const selectedData = jiraData.filter(d => this.state.selectedItems.includes(d.jiraKey));
        const { portfolioImport } = this.state;
        const dataSelectedInTable = selectedData.map(d => ({
          ...d,
          planningStage: d.inProgress ? 'Confirmed' : 'Backlog',
          portfolioImport,
        }));

        await this.setState({
          waitForApi: true,
        });

        if (this.state.jiraDataPreviewedCursor !== this.state.totalJiraData.length) {
          if (dataSelectedInTable && dataSelectedInTable.length) {
            await this.importFromJql();
            const epicsKeys = dataSelectedInTable.map(epic => epic.jiraKey);

            this.props.syncJiraStories(orgIntegration.id, epicsKeys, null, true);
          }

          const newJiraToDisplay = this.state.totalJiraData.slice(
            this.state.jiraDataPreviewedCursor,
            this.state.jiraDataPreviewedCursor + 100,
          );

          return this.setState({
            syncJiraDataStatus: 'fulfilled',
            waitForApi: false,
            jiraData: newJiraToDisplay,
            notImported: newJiraToDisplay.filter(d => d.exists).length,
            jiraDataPreviewedCursor: this.state.jiraDataPreviewedCursor + newJiraToDisplay.length,
            cursorStart: this.state.jiraDataPreviewedCursor,
            // eslint-disable-next-line no-mixed-operators
            cursorEnd: this.state.jiraDataPreviewedCursor - 1 + newJiraToDisplay.length,
            selectedItems: [],
          });
        }

        const socketRoom = this.props.socket ? this.props.socket.join() : null;

        if (socketRoom) {
          this.props.socket.subscribe(
            data => {
              this.setState({
                existing: data.alreadyLinked,
                imported: data.imported,
                waitForApi: false,
                syncJiraDataStatus: 'fulfilled',
              });
            },
            { messageType: `message-${socketRoom}` },
          );
        }

        this.setState({
          imported: this.state.totalJiraData.length,
          syncJiraDataStatus: 'pending',
          waitForApi: true,
        });

        this.importFromJql(socketRoom).then(() => {
          moveOnToNextStep(1, false);
        });
      }
    } else if (currentStep === 3) {
      this.props.onClose();
      this.props.history.push(this.getFinalUrl());
    } else {
      moveOnToNextStep();
    }
  }

  handleStepClick(index) {
    const { completed } = this.state;

    const isMappingStepBlocked = index === 1 && this.isSavedFieldMappingSelected();

    if (completed[index] && !isMappingStepBlocked) {
      this.setState({ currentStep: index });
    }
  }

  nextButtonDisabled() {
    const { currentStep, fields, totalTasks, hasSavedMappingForJiraProjects, selectedFieldsMappingType } = this.state;
    const { hasJiraFieldMappingByProject } = this.props;

    if (this.state.waitForApi) {
      return true;
    }

    const isInvalidFieldMappingSelection =
      not(hasJiraFieldMappingByProject) && hasSavedMappingForJiraProjects && !selectedFieldsMappingType;

    if (
      (currentStep === 0 && !fields.length) ||
      (currentStep === 0 && !totalTasks) ||
      (currentStep === 0 && isInvalidFieldMappingSelection)
    ) {
      return true;
    }

    return false;
  }

  onItemSelected(e) {
    const select = data => {
      data = data.map(i => i.jiraKey);
      this.setState({ selectedItems: [...this.state.selectedItems, ...data] });
    };
    const unselect = data => {
      data = data.map(i => i.jiraKey);
      this.setState({ selectedItems: this.state.selectedItems.filter(i => !data.includes(i)) });
    };
    const selectEvent = onRowSelectedFactory(this, select, unselect);

    selectEvent(e);
  }

  handleClose = () => {
    this.props.onClose();
  };

  close = redirectPage => {
    switch (redirectPage) {
      case 'ideas-board':
        this.props.updateBoardState({
          selectedStages: {
            Backlog: true,
            Planning: true,
            Confirmed: true,
            Completed: true,
            Archived: false,
          },
        });
        break;
      default:
        break;
    }

    this.handleClose();
  };

  skipImport = () => {
    this.setState({
      syncJiraDataStatus: 'fulfilled',
      currentStep: 3,
      completed: { ...this.state.completed, ...{ 2: true } },
    });
  };

  render() {
    const { isOpen, getSystemFieldName, corpAccountName, hasJiraFieldMappingByProject } = this.props;

    const {
      currentStep,
      totalTasks,
      fields,
      jiraData,
      jiraUrl,
      waitForApi,
      completed,
      imported,
      existing,
      // notImported,
      mappedFields,
      searched,
      jiraErrors,
      badValues,
      selectedOrgIntegration,
      hasSavedMappingForJiraProjects,
      selectedFieldsMappingType,
    } = this.state;

    const steps = this.getSteps();

    return (
      <CustomLightBox
        onClose={this.handleClose}
        isOpen={isOpen}
        maxWidth="lg"
        big
        content={
          <Wrapper>
            <CloseButton onClick={this.handleClose} />
            <Stepper alternativeLabel activeStep={currentStep}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton
                    onClick={() => this.handleStepClick(index)}
                    completed={completed[index]}
                    disabled={!completed[index] || (index === 1 && this.isSavedFieldMappingSelected())}
                  >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <Content className="content">
              {currentStep === 0 && (
                <ImportJQLStepOne
                  loading={waitForApi}
                  totalTasks={totalTasks}
                  searched={searched}
                  onSearch={this.searchForTasks}
                  jiraUrl={jiraUrl}
                  jiraErrors={jiraErrors}
                  getSystemFieldName={getSystemFieldName}
                  orgIntegrationId={selectedOrgIntegration}
                  hasSavedMappingForJiraProjects={hasSavedMappingForJiraProjects}
                  selectedFieldsMappingType={selectedFieldsMappingType}
                  onChangeFieldsMappingType={selectedType =>
                    this.setState({
                      selectedFieldsMappingType: selectedType,
                    })
                  }
                  hasJiraFieldMappingByProject={hasJiraFieldMappingByProject}
                />
              )}
              {currentStep === 1 && (
                <MapImportDataFields
                  integrationType="jira"
                  columns={sortBy(fields, ['name'])}
                  defaultMap={this.state.defaultFieldsMapping}
                  dataMapping={mappedFields}
                  updateDataMapping={data => this.setState({ mappedFields: data })}
                />
              )}
              {currentStep === 2 && this.state.syncJiraDataStatus !== 'pending' && (
                <Fragment>
                  <StyledText>{IMPORT_JQL_PREVIEW_MESSAGE}</StyledText>
                  <IdeasCopyPasteLightbox
                    isOpen
                    isLightbox={false}
                    isCopyPaste={false}
                    rowData={jiraData}
                    rowsAutoheight
                    showJiraKey
                    selectMode
                    skipSelectionCheckboxes
                    height="calc(100vh - 400px)"
                    updateRowData={jiraData => this.setState({ jiraData })}
                    onRowSelected={() => {}}
                    {...pick(this.props, [
                      'productOwners',
                      'phaseTitles',
                      'teamsTitles',
                      'roadmapsTitles',
                      'timeframesTitles',
                      'jiraIntegrated',
                      'objectiveTitles',
                      'themeTitles',
                      'categoryTitles',
                      'priorities',
                      'organization',
                      'roadmaps',
                      'objective',
                      'customFields',
                    ])}
                    hidePlanningStage
                    hideProgress
                    hideDeleteButton
                  />
                  {badValues && !!badValues.length && (
                    <InfoTypography>
                      {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                      <Strong color="red">{badValues.join(', ')} fields</Strong>
                      {` can't be mapped to dragonboat fields. Click `}
                      <PageLink onClick={this.handlePrev}>back</PageLink> to remap or ignore these fields and{' '}
                      <PageLink
                        onClick={() => (this.nextButtonDisabled() ? this.handleNext() : undefined)}
                        disabled={this.nextButtonDisabled()}
                      >
                        {' '}
                        Import Selected
                      </PageLink>
                      .
                    </InfoTypography>
                  )}
                </Fragment>
              )}
              {this.state.syncJiraDataStatus === 'pending' && (
                <Fragment>
                  <div>
                    <Typography style={{ fontSize: '18px' }}>
                      <br />
                      <br />
                      Mapping data in progress...
                      <br />
                      <br />
                      In the meantime you may
                    </Typography>
                    <br />
                    <Typography style={{ fontSize: '18px' }}>
                      <Link onClick={() => this.close('manage')} to="/ideas/timeline" style={{ color: 'initial' }}>
                        <TimelineIcon style={{ verticalAlign: 'bottom', marginRight: 10 }} />
                      </Link>
                      Adjust timelines and assign resources on the&nbsp;
                      <Link onClick={() => this.close('manage')} to="/ideas/timeline">
                        Manage
                      </Link>{' '}
                      page
                    </Typography>
                    <br />
                    <Typography style={{ fontSize: '18px' }}>
                      <Link onClick={() => this.close('ideas-board')} to="/ideas/board" style={{ color: 'initial' }}>
                        <ViewWeekIcon style={{ verticalAlign: 'bottom', marginRight: 10 }} />
                      </Link>
                      Align and prioritize {getSystemFieldName('idea', true)} on the&nbsp;
                      <Link onClick={() => this.close('ideas-board')} to="/ideas/board">
                        {getSystemFieldName('idea', true)} Board
                      </Link>
                    </Typography>
                    <br />
                    <br />
                    The mapping will continue even if you close this screen or go to other pages.
                  </div>
                </Fragment>
              )}
              {currentStep === 3 && this.state.syncJiraDataStatus === 'fulfilled' && (
                <EndOfImportComponent
                  getSystemFieldName={getSystemFieldName}
                  imported={imported}
                  existing={existing}
                  existInAccountName={corpAccountName}
                  onClose={this.close}
                />
              )}
            </Content>
            {currentStep > 0 && waitForApi ? (
              <div style={{ marginBottom: '30px' }}>
                <LinearProgress />
              </div>
            ) : (
              <Grid container>
                <Grid item xs={6}>
                  {currentStep > 0 &&
                    currentStep < steps.length - 1 &&
                    this.state.syncJiraDataStatus !== 'pending' &&
                    ((currentStep === 2 && this.state.cursorStart === 0) || currentStep !== 2) && (
                      <Button disabled={currentStep === 0 || this.state.waitForApi} onClick={this.handlePrev}>
                        Back
                      </Button>
                    )}
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  {currentStep < steps.length - 1 && this.state.syncJiraDataStatus !== 'pending' && (
                    <Button color="primary" onClick={this.handleNext} disabled={this.nextButtonDisabled()}>
                      {(() => {
                        if (currentStep !== steps.length - 2) {
                          return 'Next';
                        }
                        return 'Import';
                      })()}
                    </Button>
                  )}
                  {currentStep === 2 && this.state.cursorStart !== 0 && (
                    <Button onClick={this.skipImport} disabled={this.nextButtonDisabled()}>
                      Skip
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
          </Wrapper>
        }
      />
    );
  }
}

const CustomLightBox = styled(LightBox)`
  &&&& {
    > div > div {
      height: 60%;
      width: 980px;
      overflow: hidden;
      transition: width 0.6s, height 0.6s;

      ${({ big }) =>
        big &&
        `
        width: 1128px;
        height: 100%;
      `}
    }

    > div > div > div {
      height: 100%;
      overflow: hidden;
    }
  }
`;

const Wrapper = styled.div`
  height: 100%;
  position: relative;

  > button {
    top: -18px;
    right: -16px;
  }
`;

const Content = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 166px);
  margin-bottom: 30px;
`;

const InfoTypography = styled(Typography)`
  &&&& {
    font-size: 17px;
    margin-left: 12px;
  }
`;

const StyledText = styled(Text)`
  margin: ${spacing(2)}px 0;
`;

/*
const TableUnderRow = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  margin-top: 5px;
  margin-left: 12px;
  margin-right: 12px;
`;
*/

ImportFromJQL.propTypes = {
  onClose: PropTypes.func.isRequired,
  searchForTasksByJiraUrl: PropTypes.func,
};

export default hoc(ImportFromJQL);
