import moment from 'moment';
import itemsTimespan from 'utils/itemsTimespan';
import formatDateForGantt from 'utils/dates/formatDateForGantt';

import parseProjectStories from '../parsers/parseProjectStories';

export default (showStories, orgIntegrations, shouldRenderTasks, shouldRenderEstimates, getTasksAndSubtasks, getEstimates) =>
  (p, ganttProject) => {
    const result = {
      stories: [],
      data: {},
    };

    const _showProjectStories = [
      p.jira && showStories?.jira,
      showStories &&
        p.integrations &&
        p.integrations.length &&
        orgIntegrations.length &&
        p.integrations
          .map(i => orgIntegrations.find(oi => oi.id === i.org_integration_id)?.integrationType)
          .some(type => !!showStories[type]),
    ];

    if (showStories && orgIntegrations.length && _showProjectStories.filter(v => !!v).length > 0) {
      const { integrationProgress, jira, integrations } = p;
      let orgIntegration;

      if (jira) orgIntegration = orgIntegrations.find(i => i.id === jira.orgIntegration_id);
      else if (integrations.length) orgIntegration = orgIntegrations.find(i => i.id === p.integrations[0].org_integration_id);

      if (!orgIntegration) return result;

      const projectStories = parseProjectStories(p);

      if (integrationProgress) result.data.$has_child = integrationProgress.issuesTotal;

      if (integrationProgress && integrationProgress.issuesTotal > 0 && !projectStories) {
        const dates = [moment(p.integrationProgress.startDate), moment(p.integrationProgress.endDate)];
        const integrationTimespan = itemsTimespan(dates);

        result.data.start_date = formatDateForGantt(integrationTimespan.startDate);
        result.data.end_date = formatDateForGantt(integrationTimespan.endDate);
        result.data.type = 'task';
      } else if (projectStories) {
        result.data.start_date = projectStories.startDate;
        result.data.end_date = projectStories.endDate;

        result.stories = projectStories.result;
      } else if (!ganttProject?.$has_child) {
        // Timeline page
        //  - if an idea has tasks or estimate, but not linked to Jira / GH / CH, in story mode, should display task or estimate
        let projectTasks = shouldRenderTasks(p) ? getTasksAndSubtasks(p) : [];

        if (!projectTasks.length && shouldRenderEstimates(ganttProject)) projectTasks = getEstimates(p);
        if (projectTasks.length) result.data.type = 'project';

        result.stories = projectTasks;
      }
    }

    return result;
  };
