import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { archiveAnnouncement, createAnnouncement, deleteAnnouncement, updateAnnouncement } from 'store/announcements';
import { updateOnboardingGuide, getOrgOnboardingGuide } from 'store/organization';

import { getAnnouncements } from 'store/announcements/selectors';
import { getCurrentUser } from 'store/login/selectors';

import checkIfUserIsAdmin from 'utils/checkIfUserIsAdmin';
import { ADMIN_USER, EDITOR_USER, MANAGER_USER } from '@dragonboat/permissions';
import { DEFAULT_ONBOARDING_GUIDE } from 'constants/organizations';

const ADMIN_ANNOUNCEMENT_TITLE = 'Create Announcements for Your Teams';
const DEFAULT_ANNOUNCEMENT_TITLE = 'Announcements';

export default function useOrgAnnouncements(selectedAnnouncementId) {
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const allAnnouncements = useSelector(getAnnouncements);
  const orgOnboardingGuide = useSelector(getOrgOnboardingGuide);

  const userIsAdmin = useMemo(() => checkIfUserIsAdmin(currentUser), [currentUser]);
  const userIsEditor = useMemo(() => [ADMIN_USER, MANAGER_USER, EDITOR_USER].includes(currentUser?.role_id), [currentUser]);

  const announcementCardTitle = useMemo(
    () => (userIsAdmin ? ADMIN_ANNOUNCEMENT_TITLE : DEFAULT_ANNOUNCEMENT_TITLE),
    [userIsAdmin],
  );

  const selectedAnnouncement = useMemo(
    () => allAnnouncements.find(announcement => announcement.id === selectedAnnouncementId),
    [selectedAnnouncementId],
  );

  const onboardingGuide = useMemo(() => {
    if (!orgOnboardingGuide || orgOnboardingGuide === '<p></p>') {
      return DEFAULT_ONBOARDING_GUIDE;
    }

    return orgOnboardingGuide;
  }, [orgOnboardingGuide]);

  const handleAddAnnouncement = useCallback(
    message => {
      if (message) {
        dispatch(createAnnouncement({ message }));
      }
    },
    [dispatch],
  );

  const handleUpdateAnnouncement = useCallback(
    (id, message) => {
      if (id && message) {
        dispatch(updateAnnouncement(id, { message }));
      }
    },
    [dispatch],
  );

  const saveAnnouncement = useCallback(
    message => {
      if (selectedAnnouncementId) {
        return handleUpdateAnnouncement(selectedAnnouncementId, message);
      }

      return handleAddAnnouncement(message);
    },
    [handleAddAnnouncement, selectedAnnouncementId],
  );

  const handleDeleteAnnouncement = useCallback(
    id => {
      if (id) {
        dispatch(deleteAnnouncement(id));
      }
    },
    [dispatch],
  );

  const handleArchiveAnnouncement = useCallback(
    id => {
      if (id) {
        dispatch(archiveAnnouncement(id));
      }
    },
    [dispatch],
  );

  const saveOnboardingGuide = useCallback(
    onboardingGuide => {
      if (onboardingGuide) {
        dispatch(updateOnboardingGuide(onboardingGuide));
      }
    },
    [dispatch],
  );

  return {
    addAnnouncementsVisible: userIsAdmin,
    announcementCardTitle,
    announcements: allAnnouncements,
    handleArchiveAnnouncement,
    handleDeleteAnnouncement,
    isAdmin: userIsAdmin,
    isEditor: userIsEditor,
    onboardingGuide,
    saveAnnouncement,
    saveOnboardingGuide,
    selectedAnnouncement,
  };
}
