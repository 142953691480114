import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import omit from 'lodash/omit';

import useDeepEffect from 'hooks/useDeepEffect';
import usePageFilters from 'hooks/filters/usePageFilters';
import { getDisplayLayer, getUserFilters } from 'store/filters/selectors';
import { getUserViewsIsLoading, getUserViewsIsUninitialized } from 'store/userViews/selectors';
import { getOrgHasBet } from 'store/organization';
import { SUMMARY_PAGE } from 'constants/filters';
import { TRANSACTION_PAGES_PROJECTS_LIMIT } from 'constants';
import { applyFiltersOnSummary } from 'features/PortfolioSummary/Grouping/store/thunks';

const paginationAttributes = {
  from: 0,
  to: TRANSACTION_PAGES_PROJECTS_LIMIT,
};

const useLoadProjectsForSummary = (pageId = SUMMARY_PAGE) => {
  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(true);

  const { pageFilters: filters } = usePageFilters(pageId);

  const userFilters = useSelector(getUserFilters);
  const hasBet = useSelector(getOrgHasBet);
  const hasHierarchy = useSelector(state => state.organization.organization.has_hierarchy);
  const displayLayer = useSelector(getDisplayLayer);
  const userViewsIsLoading = useSelector(getUserViewsIsLoading);
  const userViewsIsUninitialized = useSelector(getUserViewsIsUninitialized);

  const fetchProjectsWithMetadata = useCallback(() => {
    const asyncFetch = async () => {
      setIsLoaded(false);

      await dispatch(
        applyFiltersOnSummary({ filters, userFilters, hasBet, hasHierarchy, pageId, displayLayer, paginationAttributes }),
      );

      setIsLoaded(true);
    };

    if (!userViewsIsLoading && !userViewsIsUninitialized) {
      asyncFetch();
    }
  }, [userViewsIsLoading, userViewsIsUninitialized, filters, userFilters, displayLayer]);

  useDeepEffect(() => {
    fetchProjectsWithMetadata();
  }, [
    Object.keys(omit(filters, ['displayLayer', 'portfolioMode'])),
    Object.values(omit(filters, ['displayLayer', 'portfolioMode'])),
  ]);

  return [isLoaded];
};

export default useLoadProjectsForSummary;
