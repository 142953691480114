import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ArrowDown from '../DragonIcons/ArrowDown';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';
import Text from 'design-system/atoms/Text/index';


const DragonSelectButton = ({
  icon,
  value,
  onClick,
  placeholder,
  variant = 'primary',
  noUnderline = false,
  higherLabel,
  textStyles,
  arrowIcon,
  ...rest
}) => {
  return (
    <ButtonWrapper onClick={onClick} {...rest}>
      <Select variant={variant} icon={!!icon} noUnderline={noUnderline}>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <LabelWrapper textStyles={textStyles}>
          {higherLabel && (
            <TooltipOnOverflow text={value} elementWithOverflow="h4">
              <Text variant="h4" withEllipsis>
                {!value && placeholder && <Placeholder>{placeholder}</Placeholder>}
                {value}
              </Text>
            </TooltipOnOverflow>
          )}
          {!higherLabel && !value && placeholder && <Placeholder>{placeholder}</Placeholder>}
          {!higherLabel && value}
        </LabelWrapper>
        <ArrowWrapper>{arrowIcon || <ArrowDown />}</ArrowWrapper>
      </Select>
    </ButtonWrapper>
  );
};

const IconWrapper = styled.div`
  &&&& {
    display: flex;
    align-items: center;

    svg {
      width: 14px;
      height: 14px;

      path:not([fill='none']) {
        fill: ${({ theme }) => theme.palette.newLayout.background.gray};
      }
    }
  }
`;

const ArrowWrapper = styled(IconWrapper)`
  &&&& {
    svg path {
      fill: ${({ theme }) => theme.palette.newLayout.background.gray};
    }
  }
`;

const Placeholder = styled.span`
  color: ${({ theme }) => theme.palette.newLayout.background.lightDisable};
`;

const LabelWrapper = styled.div`
  &&&& {
    width: 100%;
    max-width: 380px;

    font-family: Inter;
    font-style: normal;
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    line-height: ${({ theme }) => theme.typography.lineHeightSmallRem}rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    text-align: left;

    color: ${({ theme }) => theme.palette.newLayout.text.label};

    h4 {
      color: ${({ theme }) => theme.palette.newLayout.background.primary};

      ${({ textStyles }) => textStyles}
    }
  }
`;

const Select = styled.button`
  &&&& {
    width: 100%;
    box-sizing: border-box;

    padding: 7px 13px;
    min-width: 140px;
    max-width: 400px;

    display: flex;
    /* grid-template-columns: ${({ icon }) => (icon ? `14px 1fr 14px` : `1fr 14px`)}; */
    align-items: center;

    gap: 8px;

    cursor: pointer;

    ${({ variant, theme }) =>
      variant === 'primary' &&
      `
      border: 1px solid ${theme.palette.newLayout.background.lightestSecondary};
      border-radius: 2px;
      background: ${theme.palette.newLayout.background.white};
    
    `}

    ${({ variant, theme }) =>
      variant === 'secondary' &&
      `
      background: transparent;
      border: none;
      border-bottom: 1px solid ${theme.palette.newLayout.background.lightestSecondary};
      padding-right: 0;
      padding-left: 0;

    `}

    ${({ noUnderline }) => noUnderline && `border-bottom: 0;`}
  }
`;

const ButtonWrapper = styled.div`
  &&&& {
    padding: 0;
    background: transparent;
    border-radius: 2px;
    min-width: 140px;
    max-width: 400px;
    width: 100%;
  }
`;

DragonSelectButton.propTypes = {
  icon: PropTypes.any,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

export default DragonSelectButton;
