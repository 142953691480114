import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { METADATA_LEVELS } from 'constants/common';

import { statusColors, orderedPlanningStages } from 'utils';
import { filterMetadataOptions } from 'utils/metadataRoadmaps';

import { materialColorsAlt } from 'design-system/themes/default';

import { getTags } from 'store/tags/selectors';
import { getSkills } from 'store/skills/selectors';
import { getUsers } from 'store/users/selectors';
import { getAllTeams } from 'store/teams/selectors';
import { getPhases } from 'store/phases/selectors';
import { getThemes } from 'store/themes/selectors';
import { getPriorities } from 'store/priorities/selectors';
import { getCategories } from 'store/categories/selectors';
import { getActiveCustomers } from 'store/customers/selectors';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import { getAllBets, getAllInitiatives } from 'store/projects/selectors';
import { getProducts, getRoadmaps, getCorpRoadmaps } from 'store/roadmaps/selectors';
import { selectKeyResults1, getObjectives, selectKeyResults2 } from 'store/objectives/selectors';
import { getDropdownCustomFields } from 'store/customFields/selectors';
import { getTimeframesLevelCorp, getTimeframes, getTimeframesLevel2 } from 'store/timeframes/selectors';

import useOrganizations from 'hooks/useOrganizations';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

import { usersMapper } from 'routes/Swimlane/New/helpers';

const useSwimlaneMetadata = (projects, quickFilters) => {
  const { hasBet, hasMetadataRoadmaps, hasHierarchy, hasMultiLevelMetadata } = useOrganizations();
  const { isDodActive, getDefaultRoadmapForMetadataItem } = useOrganizationsAccessControl();

  const storeData = useSelector(state => ({
    objectives: getStateDataForPage(state, getObjectives, 'objectives'),
    keyResults1: getStateDataForPage(state, selectKeyResults1, 'keyResults'),
    keyResults2: getStateDataForPage(state, (state, showArchived) => selectKeyResults2(state, showArchived), 'keyResult2s'),
    roadmaps: getStateDataForPage(state, getRoadmaps, 'roadmaps'),
    products: getStateDataForPage(state, getProducts, 'products'),
    timeframes: getStateDataForPage(state, getTimeframes, 'timeframes'),
    ...(hasMultiLevelMetadata
      ? {
          timeframes2: getStateDataForPage(state, getTimeframesLevel2, 'timeframes'),
        }
      : {}),
    categories: getStateDataForPage(state, getCategories, 'categories'),
    themes: getStateDataForPage(state, getThemes, 'themes'),
    priorities: getPriorities(state),
    phases: getStateDataForPage(state, getPhases, 'phases'),
    users: usersMapper(getStateDataForPage(state, getUsers, 'users')),
    tags: getTags(state),
    customers: getActiveCustomers(state),
    ...(hasHierarchy ? { initiatives: getAllInitiatives(state) } : {}),
    ...(hasBet ? { bets: getAllBets(state) } : {}),
    statusColors: statusColors.map(c => ({
      id: c.label,
      title: c.label || 'Undefined',
      color: materialColorsAlt[c.label.toLowerCase()],
    })),
    planningStages: orderedPlanningStages,
    // extra grouping
    teams: getStateDataForPage(state, getAllTeams, 'teams'),
    skills: getStateDataForPage(state, getSkills, 'skills'),
    stages: orderedPlanningStages,
    customFields: getDropdownCustomFields(state),
    roadmapsCorp: getStateDataForPage(state, (state, showArchived) => getCorpRoadmaps(state, showArchived), 'roadmapsCorp'),
    objectivesCorp: getStateDataForPage(
      state,
      (state, showArchived) => getObjectives(state, showArchived, METADATA_LEVELS.LEVEL_CORP),
      'objectivesCorp',
    ),
    timeframesCorp: getStateDataForPage(state, getTimeframesLevelCorp, 'timeframesCorp'),
  }));

  if (isEmpty(Object.keys(quickFilters || {}))) {
    return storeData;
  }

  return filterMetadataOptions(hasMetadataRoadmaps, projects, storeData, quickFilters, {
    isDodActive,
    getDefaultRoadmapForMetadataItem,
  });
};

export default useSwimlaneMetadata;
