import moment from 'moment-timezone';

import {
  DEFAULT_VIEWPORTS,
  MONTH_UNIT_TIME,
  QUARTER_UNIT_TIME,
  VIEW_MODE_RELATIONS,
  WEEK_UNIT_TIME,
  YEAR_UNIT_TIME,
} from './constants';
import { isSnapToEnd } from './snapToGrid';

/**
 * Convert a duration with initiall time unit into days.
 *
 * @param {any} duration The initial duration
 * @param {any} mode The initial time unit
 *
 * @return {Number} duration in days
 * */
const durationInDays = (duration, mode) => {
  const sourceDuration = moment.duration(duration, `${mode}s`);

  return sourceDuration.asDays();
};

/**
 * Calculate the date difference of steps into days.
 *
 * @param {Object} item The item with the source date
 * @param {Number} steps The number of slots translated
 * @param {String} zoomMode The calendar zoom mode (daily, monthly, weekly or quarterly)
 * @param {snapToGridOn} snapToGridOn The snap to grid options selected by the user if any
 *
 * @return {Number} the number of days that correspond to the date difference
 * */
const calculateDateDiffBySteps = (item, steps, zoomMode, snapToGridOn) => {
  if (!snapToGridOn) {
    return durationInDays(steps, zoomMode);
  }

  const clonedTargetDate = item[snapToGridOn].clone().add(steps, zoomMode);

  const withSnap = isSnapToEnd(snapToGridOn) ? clonedTargetDate.endOf(zoomMode) : clonedTargetDate.startOf(zoomMode);

  return durationInDays(moment.duration(withSnap.diff(item[snapToGridOn])), zoomMode);
};

/**
 * Calculate difference between 2 dates by time unit.
 *
 * @param {Date} fromDate The initial date
 * @param {Date} toDate The ending date
 * @param {String} zoomMode The calendar zoom mode (daily, monthly, weekly or quarterly)
 *
 * @return {Number} the difference in time unit passed as argument
 * */
const differenceBetweenDatesByUnit = (fromDate, toDate, zoomMode) => {
  const diff = moment.duration(toDate.diff(fromDate));

  switch (zoomMode) {
    case YEAR_UNIT_TIME:
      return diff.asYears();
    case QUARTER_UNIT_TIME:
      return diff.asQuarters();
    case MONTH_UNIT_TIME:
      return diff.asMonths();
    case WEEK_UNIT_TIME:
      return diff.asWeeks();
    default:
      return diff.asDays();
  }
};

/**
 * Calculates the initial from date of the date range.
 *
 * @param {String} zoomMode The calendar zoom mode (daily, monthly, weekly or quarterly)
 *
 * @return {Date} the inital from date
 * */
const calculateInitialFromDateOfDateRange = zoomMode => {
  const relationMode = VIEW_MODE_RELATIONS[zoomMode];

  const newDate = moment()
    .subtract(DEFAULT_VIEWPORTS[zoomMode] / 2, relationMode)
    .startOf(relationMode)
    .startOf(zoomMode);

  if (newDate.date() !== 1) {
    newDate.add(1, zoomMode);
  }

  return newDate;
};

/**
 * Calculates the initial to date of the date range.
 *
 * @param {String} zoomMode The calendar zoom mode (daily, monthly, weekly or quarterly)
 *
 * @return {Date} the inital to date
 * */
const calculateInitialToDateOfDateRange = zoomMode => {
  const relationMode = VIEW_MODE_RELATIONS[zoomMode];

  return moment()
    .add(DEFAULT_VIEWPORTS[zoomMode] / 2, relationMode)
    .endOf(relationMode);
};

export {
  durationInDays,
  differenceBetweenDatesByUnit,
  calculateInitialFromDateOfDateRange,
  calculateInitialToDateOfDateRange,
  calculateDateDiffBySteps,
};
