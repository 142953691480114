import React from 'react';
import styled from 'styled-components';

import NewDialog from 'design-system/organisms/NewDialog/index';
import DialogActions from 'design-system/molecules/DialogActions/index';

import useOptionsForm from 'hooks/useOptionsForm';
import Header from './Header';

const LEVELS_LIST_ADD_LABEL = '+ Add Metric Type';

const MetricLevelsProjectLightbox = ({
  isOpen,
  closeMetricLevelsLightbox,
  metricLevels,
  canUpdateMetricLevels,
  handleMetricLevelsChange,
}) => {
  const OptionsComponent = useOptionsForm({
    options: metricLevels,
    handleUpdateOptions: handleMetricLevelsChange,
    titleLabel: '',
    addOptionLabel: LEVELS_LIST_ADD_LABEL,
    optionIdPrefix: '',
    disableEdit: !canUpdateMetricLevels,
    showStatusButton: false,
    wrapperDndListStyle: {
      boxShadow: 'none',
    },
  });

  return (
    <NewDialog
      header={<Header />}
      actions={<DialogActions onCancel={closeMetricLevelsLightbox} cancelLabel="Close" cancelProps={{ color: 'primary' }} />}
      onClose={closeMetricLevelsLightbox}
      open={isOpen}
      width="750px"
      withActionsShadow
      closeButton
    >
      <Container>{OptionsComponent}</Container>
    </NewDialog>
  );
};

export default MetricLevelsProjectLightbox;

const Container = styled.div`
  width: 100%;
`;
