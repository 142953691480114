
import { toast } from 'react-toastify';
import {
  FETCH_FUNCTIONROLES_FULFILLED,
  FETCH_FUNCTIONROLES_REJECTED,
  FETCH_USER_FUNCTIONROLES_FULFILLED,
  FETCH_USER_FUNCTIONROLES_REJECTED,
  UPDATE_USER_FUNCTIONROLES_FULFILLED,
  UPDATE_USER_FUNCTIONROLES_REJECTED,
} from './types';

const initialState = {
  functions: [],
  currentUserFunctions: [],
  isLoaded: false,
};

export default function functionRolesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FUNCTIONROLES_FULFILLED: {
      return {
        ...state,
        functions: action.payload,
        isLoaded: true,
      };
    }
    case FETCH_USER_FUNCTIONROLES_FULFILLED: {
      const { data } = action.payload;

      return {
        ...state,
        currentUserFunctions: data,
      };
    }
    case UPDATE_USER_FUNCTIONROLES_FULFILLED: {
      const { data } = action.payload;

      toast('We caught your hats!');

      return {
        ...state,
        currentUserFunctions: data,
      };
    }
    case FETCH_FUNCTIONROLES_REJECTED:
    case FETCH_USER_FUNCTIONROLES_REJECTED:
    case UPDATE_USER_FUNCTIONROLES_REJECTED:
      console.error('ERROR:', action);
      return state;
    default:
      return state;
  }
}
