import React from 'react';
import styled, { css } from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

import invertedTextColor from 'design-system/utils/invertedTextColor';

import TableHeaderTitle from './TableHeaderTitle';

const TableHeader = React.memo(({ children, ...otherProps }) => {
  return (
    <Wrapper className="tableheader" {...otherProps}>
      <HeadItem
        style={{
          color: otherProps.color ? invertedTextColor(otherProps.color, true) : null,
          minHeight: otherProps.minHeight,
        }}
        $vertical={otherProps.vertical}
      >
        {children}
      </HeadItem>
    </Wrapper>
  );
});

TableHeader.displayName = 'GridTableHeader';

export default TableHeader;

const leftStyles = css`
  min-height: 20px;
  z-index: 111;
`;

const notVerticalStyles = css`
  ${TableHeaderTitle} {
    line-height: 1.3;
  }
`;

const verticalStyles = css`
  background: transparent;
`;

const notVerticalRowHeaderStyles = css`
  max-width: 25vw;
  padding: 12px 10px 10px 16px;

  p {
    white-space: break-spaces;
  }
`;

const verticalRowHeaderStyles = css`
  padding: 15px 10px 10px 15px;

  > div {
    transform: rotate(270deg);
    padding-top: 30px;
    min-height: 100px;
    min-width: 45px;
    max-width: 45px;
    writing-mode: vertical-rl;
    display: flex;
    flex-direction: column;

    ${TableHeaderTitle} {
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: right;
      max-height: 100px;
      max-width: 118px;
      padding-right: 12px;
      margin-right: -30px;
    }
  }
`;

const Wrapper = withTheme()(styled.div`
  &&&& {
    padding: 20px 10px 16px 15px;
    justify-content: center;
    align-items: center;
    line-height: 24px;
    color: #6b6b6b;
    height: 100%;

    ${({ vertical }) => !vertical && notVerticalStyles}

    ${({ color }) => color && `box-shadow: inset 0px 4px 0px 0px ${color};`}

    ${({ left }) => left && leftStyles}

    ${({ verticalRowHeader, vertical }) => !verticalRowHeader && vertical && notVerticalRowHeaderStyles};

    ${({ verticalRowHeader, topHeader }) => verticalRowHeader && !topHeader && verticalRowHeaderStyles}

    ${({ borderLeftColor }) => borderLeftColor && `box-shadow: inset 4px 0px 0px 0px ${borderLeftColor};`}
  }
`);

const HeadItem = styled.div`
  padding: 0 8px;
  width: auto;
  text-align: left;
  color: #6b6b6b;
  font-size: 16px;
  border-radius: 2px;
  min-height: 40px;

  ${({ $vertical }) => $vertical && verticalStyles}
`;
