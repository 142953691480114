import { getThunkActionTypes } from 'utils/store/thunk';

const { FETCH_REQUEST_HISTORY, FETCH_REQUEST_HISTORY_FULFILLED } = getThunkActionTypes('FETCH_REQUEST_HISTORY');
const { FETCH_REQUEST_HISTORY_NEXT, FETCH_REQUEST_HISTORY_NEXT_FULFILLED } = getThunkActionTypes('FETCH_REQUEST_HISTORY_NEXT');

const RESET_REQUEST_HISTORY = 'RESET_REQUEST_HISTORY';

export {
  RESET_REQUEST_HISTORY,
  FETCH_REQUEST_HISTORY,
  FETCH_REQUEST_HISTORY_FULFILLED,
  FETCH_REQUEST_HISTORY_NEXT,
  FETCH_REQUEST_HISTORY_NEXT_FULFILLED,
};
