import React from 'react';
import ReactDOM from 'react-dom';
import isEqual from 'lodash/isEqual';

const assertValidRef = ref => {
  if (!('getValue' in ref)) {
    throw new Error('Your component should implement imperative handle "getValue".');
  }
  if (!('setInitialValue' in ref)) {
    throw new Error('Your component should implement imperative handle "setInitialValue".');
  }
};

const build = gantt => {
  gantt.config.editor_types.reactEditor = {
    show(id, column, config, placeholder) {
      const componentRef = React.createRef();

      ReactDOM.render(config.renderReact(componentRef), placeholder);

      assertValidRef(componentRef);
      column.reactRef = componentRef;
    },
    hide(placeholder) {
      ReactDOM.unmountComponentAtNode(placeholder);
    },
    set_value(value, id, column, node) {
      const { reactRef } = column;

      reactRef.current.setInitialValue(value);
    },
    get_value(id, column, node) {
      const { reactRef } = column;

      return reactRef.current.getValue();
    },
    is_changed(value, id, column, node) {
      // called before save/close. Return true if new value differs from the original one
      // returning true will trigger saving changes, returning false will skip saving
      const currentValue = this.get_value(id, column, node);

      return !isEqual(currentValue, value);
    },
    is_valid(value, id, column, node) {
      // validate, changes will be discarded if the method returns false
      return true;
    },
    focus(node) {
      // TODO:
      //   debugger;
      //   const input = getInput(node);
      //   if (!input) {
      //     return;
      //   }
      //   if (input.focus) {
      //     input.focus();
      //   }
      //   if (input.select) {
      //     input.select();
      //   }
      //   input.onblur = () => {
      //     gantt.ext.inlineEditors.save();
      //     setTimeout(() => node.remove(), 100);
      //   };
    },
  };
};

export default build;
