import { path } from 'ramda';

const mapAllocationDataToChart = (allocationData, showAllocationInWeeks) => {
  if (!allocationData || !allocationData.metadata) {
    return { data: {}, entities: [] };
  }

  return allocationData?.metadata?.reduce(
    (chartData, entity) => {
      return {
        data: {
          ...chartData.data,
          [entity.id]: path(['completed_allocation', entity.id, showAllocationInWeeks ? 'totalWeeks' : 'total'])(allocationData),
        },
        entities: [...chartData.entities, entity],
      };
    },
    { data: {}, entities: [] },
  );
};

export default mapAllocationDataToChart;
