import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import getFields from 'containers/AdvancedSearchPopover/helpers/getFields';

import useCustomUserFields from 'hooks/useCustomUserFields';
import useSystemFields from 'hooks/useSystemFields';
import usePageFilters from 'hooks/filters/usePageFilters';
import useBaseProjectsForComparison from 'routes/RoadmapVersions/hooks/useBaseProjectsForComparison';

import { getOrganization } from 'store/organization';
import { getProjectsCustomFields } from 'store/customFields/selectors';

import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/userViews';
import {
  COMPARE_SCENARIOS_CHARTS_PAGE,
  COMPARE_SCENARIOS_FILTER,
  COMPARE_SCENARIOS_LIST_PAGE,
  COMPARE_SCENARIOS_SWIMLANE_PAGE,
  COMPARE_SCENARIOS_SUMMARY_PAGE,
} from 'constants/filters/pages';
import { FILTERS_FIELDS } from 'constants/filters/fields';
import flatten from 'lodash/flatten';
import usePermissions from 'hooks/permissions/usePermissions';

const useCompareScenariosFilters = () => {
  const [getSystemFieldName] = useSystemFields();
  const { pageFilters, displayLayer } = usePageFilters(COMPARE_SCENARIOS_FILTER);
  const path = getCurrentPath();
  const pageId = getPageIdFromPath(path);

  const { customFields, hasKeyResults, hasKeyResults2, hasProducts, hasMultiLevelPortfolioMetadata, hasTeams2 } = useSelector(
    state => ({
      customFields: getProjectsCustomFields(state, true),
      hasKeyResults: getOrganization(state).has_key_results,
      hasKeyResults2: getOrganization(state).has_key_results2,
      hasProducts: getOrganization(state).has_products,
      hasMultiLevelPortfolioMetadata: getOrganization(state).has_multi_level_portfolio_metadata,
      hasTeams2: getOrganization(state).has_teams_2,
    }),
  );

  const { canView } = usePermissions();

  const { getCustomUserFieldByFieldKey } = useCustomUserFields();

  const fields = React.useMemo(
    () =>
      getFields(
        getSystemFieldName,
        FILTERS_FIELDS,
        true,
        customFields,
        hasKeyResults,
        hasKeyResults2,
        hasProducts,
        hasMultiLevelPortfolioMetadata,
        hasTeams2,
        false,
        { canView, getCustomUserFieldByFieldKey },
      ),
    [
      FILTERS_FIELDS,
      customFields,
      hasKeyResults,
      hasProducts,
      hasMultiLevelPortfolioMetadata,
      hasTeams2,
      canView,
      getCustomUserFieldByFieldKey,
    ],
  );

  const { projects } = useBaseProjectsForComparison(displayLayer);

  const total = useMemo(() => {
    switch (pageId) {
      case COMPARE_SCENARIOS_LIST_PAGE:
      case COMPARE_SCENARIOS_CHARTS_PAGE:
        return flatten(Object.values(projects))?.length;
      case COMPARE_SCENARIOS_SWIMLANE_PAGE:
      case COMPARE_SCENARIOS_SUMMARY_PAGE:
        return projects?.[displayLayer]?.length;
      default:
        return 0;
    }
  }, [projects, pageId, displayLayer]);

  return {
    pageFilters,
    fields,
    total,
  };
};

export default useCompareScenariosFilters;
