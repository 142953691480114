import getSystemFieldName from 'utils/getSystemFieldName';

export const groupByOptions = (systemFields, hasKeyResults, hasProducts, hasHierarchy, hasBet) => [
  { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
  ...(hasProducts ? [{ key: 'product_1', title: getSystemFieldName('product1', systemFields) }] : []),
  { key: 'timeframe', title: getSystemFieldName('timeframe', systemFields) },
  { key: 'objective', title: getSystemFieldName('objective', systemFields) },
  ...(hasKeyResults ? [{ key: 'key_result_1', title: getSystemFieldName('keyResult1', systemFields) }] : []),
  // { key: 'customers', title: getSystemFieldName('customer', systemFields) },
  { key: 'theme', title: getSystemFieldName('theme', systemFields) },
  { key: 'category', title: getSystemFieldName('category', systemFields) },
  // { key: 'tags', title: getSystemFieldName('tag', systemFields) },
  ...(hasHierarchy ? [{ key: 'initiative', title: getSystemFieldName('initiative', systemFields) }] : []),
  ...(hasBet ? [{ key: 'bet', title: getSystemFieldName('bet', systemFields) }] : []),
  { key: 'phase', title: getSystemFieldName('phase', systemFields) },
  { key: 'priority', title: getSystemFieldName('priority', systemFields) },
  { key: 'owner', title: 'Owner' },
  { key: 'status_color', title: 'Health' },
  { key: 'planningStage', title: 'Planning Stage' },
];
