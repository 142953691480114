import styled from 'styled-components';

import LightBox from 'components/LightBox';

export const CustomLightBox = styled(LightBox)`
  &&&& {
    > div > div {
      height: 60%;
      width: 980px;
      overflow: hidden;
      transition: width 0.6s, height 0.6s;

      ${({ big }) =>
        big &&
        `
        width: 1128px;
        height: 100%;
      `}
    }

    > div > div > div {
      height: 100%;
      overflow: hidden;
    }
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  position: relative;

  > button {
    top: -18px;
    right: -16px;
  }
`;

export const Content = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 166px);
  margin-bottom: 30px;
`;
