import isNaN from 'lodash/isNaN';

const SECONDS_IN_HOUR = 3600;

const converTimeToString = seconds => {
  if (typeof seconds === 'string') return seconds;

  const weekParts = intSeconds => {
    return [52, 5, 8, 60, 60].reduceRight(
      (a, x) => {
        const intRest = a.remaining;
        const intMod = isNaN(x) ? intRest : intRest % x;

        return {
          remaining: (intRest - intMod) / (x || 1),
          parts: [intMod].concat(a.parts),
        };
      },
      {
        remaining: intSeconds,
        parts: [],
      },
    ).parts;
  };
  const zip = (xs, ys) => {
    return xs.length === ys.length
      ? xs.map((x, i) => {
          return [x, ys[i]];
        })
      : undefined;
  };
  const angloDuration = intSeconds => {
    return zip(weekParts(intSeconds), ['w', 'd', 'h', 'm', 's'])
      .reduce((a, x) => {
        return a.concat(x[0] ? [`${x[0].toString()}${x[1]}`] : []);
      }, [])
      .join(' ');
  };

  return angloDuration(seconds);
};

const convertHoursToString = hours => (typeof hours === 'string' ? hours : converTimeToString(hours * SECONDS_IN_HOUR));

export default converTimeToString;

export { convertHoursToString };
