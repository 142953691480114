import React from 'react';
import styled from 'styled-components';

import { materialColors } from 'design-system/themes/default';

const DetailsLineComponent = ({ label, value }) => {
  return (
    <DetailsLineItem key={value} breakLine>
      <span>{label}: </span>
      {value}
    </DetailsLineItem>
  );
};

export default DetailsLineComponent;

const DetailsLineItem = styled.span`
  ${({ breakLine }) =>
    breakLine &&
    `
    display: block;
    margin-bottom: 4px;
  `}

  &&&& {
    margin-left: 5px;
    font-size: 12px;
    font-weight: 400;

    > span {
      font-size: 13px;
      color: ${materialColors.azure};
      text-transform: capitalize;
      font-style: italic;
    }
  }
`;
