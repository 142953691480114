import { F } from 'ramda';

import { VISIBLE_COLUMNS_IDS } from '../constants';

/**
 * Custom hook for managing state of the merged requests grid.
 *
 * @returns {Object} The merged requests grid state object.
 * @property {number} rowHeight - The height of each row in the grid.
 * @property {string} searchString - The search string used to filter the grid.
 * @property {boolean} isBulkUpdate - Indicates whether bulk update is enabled.
 * @property {string[]} visibleColumnsIds - An array of column IDs that are visible in the grid.
 * @property {Object} columnsState - The state of each column in the grid.
 * @property {boolean} shouldGridBeUnmounted - Indicates whether the grid should be unmounted.
 * @property {Function} setDirtyViewMutex - A function to set the dirty view mutex.
 * @property {Function} saveColumnsState - A function to save the state of the columns.
 */
const useRequestsMergedGridState = () => {
  const rowHeight = 40;

  return {
    rowHeight,
    searchString: '',
    isBulkUpdate: false,
    visibleColumnsIds: VISIBLE_COLUMNS_IDS,
    columnsState: [],
    shouldGridBeUnmounted: false,

    setDirtyViewMutex: F,
    saveColumnsState: F,
  };
};

export default useRequestsMergedGridState;
