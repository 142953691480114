import React, { useState } from 'react';
import styled from 'styled-components';

import { spacing } from 'design-system/theme';
import SendViewToIntegrationChannel from 'containers/ViewDialog/ShareWithUsers/SendViewToIntegrationChannel';

import { INTEGRATIONS_KEYS } from 'constants/integrations';
import useHasIntegrationsOfType from 'hooks/integrations/useHasIntegrationsOfType';
import ShareUserViewViaIntegrationButton from 'features/UserViews/ShareUserViewViaIntegrationButton';

const ShareViewViaSlack = ({ view, shareUrl }) => {
  const [isSlackExpanded, setIsSlackExpanded] = useState(false);
  const hasSlackIntegration = useHasIntegrationsOfType(INTEGRATIONS_KEYS.slack);

  return (
    <Wrapper>
      {hasSlackIntegration ? (
        <ShareUserViewViaIntegrationButton
          integrationType={INTEGRATIONS_KEYS.slack}
          onClick={() => setIsSlackExpanded(prev => !prev)}
          active={isSlackExpanded}
        />
      ) : null}

      {isSlackExpanded ? (
        <SendViewToIntegrationChannel
          integrationType={INTEGRATIONS_KEYS.slack}
          handleCloseDialog={() => setIsSlackExpanded(false)}
          viewUrl={shareUrl}
          view={view}
        />
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: ${spacing(2)}px ${spacing()}px 0;
`;

export default ShareViewViaSlack;
