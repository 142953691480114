import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';

import { ConfirmDialog as ConfirmDeleteDialog } from 'components/gridCommon';
import useSystemFields from 'hooks/useSystemFields';

import { IDEA_LAYER } from 'store/projects/constants';

export default ({ selectedProject, onDelete, onArchive }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [getSystemFieldName] = useSystemFields();

  const _getFieldName = layer => {
    if (layer === IDEA_LAYER) return getSystemFieldName('idea');

    return 'Item';
  };

  return (
    <Fragment>
      <Button color="secondary" onClick={() => setShowDialog(true)}>
        delete
      </Button>
      <ConfirmDeleteDialog
        isOpen={showDialog}
        row={selectedProject}
        text={
          <div>
            <p style={{ marginBottom: '1rem' }}>
              Deleting an {_getFieldName(selectedProject.layer)} permanently removes all data, including change history, its tasks
              and assignments.
            </p>

            {/* {((this.state.tasks && !!this.state.tasks.length) || (this.state.estimates && !!this.state.estimates.length)) &&
              <p style={{ color: materialColors.red, marginBottom: '1rem' }}>
                This idea has tasks and estimates
              </p>
            } */}

            <p>We recommend Archiving it if you may need access to it later.</p>
          </div>
        }
        onCancel={() => setShowDialog(false)}
        onDelete={onDelete}
        onArchive={onArchive}
        blankBody
      />
    </Fragment>
  );
};
