import React from 'react';
import { head } from 'ramda';

import styled from 'styled-components';

import FilterCondition, { FieldWrapper } from 'design-system/molecules/FilterCondition';
import OperatorSelector from 'features/DBQL/AdvancedQueryBuilder/components/OperatorSelector';
import getFieldFunctions from 'containers/AdvancedSearchPopover/helpers/getFieldFunctions';

const Condition = ({ index, condition, allFields = [], onChange, onRemove, onUpdateGroupOperator, getFieldOptions }) => {
  const { field = {}, value, op, showArchived, groupOperator } = condition;
  const options = getFieldOptions(field?.id, field?.showArchived);
  const functions = field ? getFieldFunctions(field) : [];

  return (
    <Wrapper>
      <Operator>
        <OperatorSelector
          index={index}
          operator={groupOperator}
          canChangeOperator={index === 1}
          onChangeOperator={onUpdateGroupOperator}
        />
      </Operator>

      <FilterCondition
        key={field?.id}
        inputComponent={field?.inputComponent}
        data={{
          field: field?.id,
          value,
          op: op ?? head(functions)?.key,
          showArchived,
        }}
        fields={allFields}
        functions={functions}
        options={options}
        onChange={(event, fieldKey, change) => {
          onChange(index, {
            [fieldKey]: change,
          });
        }}
        onRemove={() => onRemove(index)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & ${FieldWrapper} {
    &:last-of-type {
      margin: 0 1px;
    }
  }
`;

const Operator = styled.div``;

export default Condition;
