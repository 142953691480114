// External dependencies
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

// Dragonboat imports
import { getOrganization } from 'store/organization/selectors';
import { updateState, groupByOptions, sumByOptions, durationOptions, defaultTableColumns } from 'store/allocationReport';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import usePermissions from 'hooks/permissions/usePermissions';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const updateAllocationReportState = state => dispatch(updateState(state));
    const lsState = useSelector(state => state.allocationReport);
    const organization = useSelector(state => getOrganization(state));
    const { canView } = usePermissions();
    const [showDialog, setShowDialog] = useState(lsState.showPreferences);
    const [tempState, _setTempState] = useState(cloneDeep(lsState));
    const _onCancel = () => {
      if (lsState.showPreferences) updateAllocationReportState({ showPreferences: false });
      setShowDialog(false);
    };
    const _onOpen = () => setShowDialog(true);
    const setTempState = data => {
      _setTempState({ ...tempState, ...data });
    };
    const _onApply = () => {
      setShowDialog(false);
      if (lsState.showPreferences) tempState.showPreferences = false;
      setTimeout(() => updateAllocationReportState(tempState), 500);
    };
    const systemFields = organization.system_fields_name;
    const hasKeyResults = organization.has_key_results;
    const hasProducts = organization.has_products;
    const hasCorpRoadmap = canView(PERMISSION_RESOURCES.roadmapCorp);
    const hasCorpObjective = canView(PERMISSION_RESOURCES.objectiveCorp);
    const onChangeSetting = (setting, value) => setTempState({ [setting]: value });
    const groupOptions = groupByOptions({ systemFields, hasCorpRoadmap, hasProducts, hasCorpObjective, hasKeyResults, canView });
    const selectedGroupBy = tempState.selectedGroupBy || groupOptions.find(o => o.key === 'roadmap');
    const selectedDuration = tempState.selectedDuration || durationOptions[0];
    const selectedSumBy = tempState.selectedSumBy || sumByOptions()[0];

    useEffect(() => {
      _setTempState(cloneDeep(lsState));
    }, [showDialog]);

    useEffect(() => {
      setShowDialog(lsState.showPreferences);
    }, [lsState.showPreferences]);

    const processedLsState = {
      dataType: selectedGroupBy,
      sumBy: selectedSumBy,
      duration: selectedDuration,
      selectedGroupBy,
      selectedDuration,
      selectedSumBy,
      selectedDisplayOptions: tempState.selectedDisplayOptions || defaultTableColumns,
    };

    return (
      <Component
        {...props}
        isOpen={showDialog}
        onOpen={_onOpen}
        onCancel={_onCancel}
        onApply={_onApply}
        groupByOptions={groupOptions}
        lsState={processedLsState}
        updateLsState={setTempState}
        includeEmptyProjects={tempState.includeEmptyProjects}
        onChangeIncludeEmptyProjects={val => onChangeSetting('includeEmptyProjects', val)}
        hideArchivedData={tempState.hideArchivedData}
        onChangeHideArchivedData={val => onChangeSetting('hideArchivedData', val)}
      />
    );
  };
};

export default componentHOC;
