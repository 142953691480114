import React, { useState } from 'react';
import styled from 'styled-components';

import DragonMenu from 'design-system/atoms/DragonMenu/index';
import DragonAvatar from 'design-system/atoms/DragonAvatar/index';


import useDragonAvatarDropdown from './hooks/useDragonAvatarDropdown';

import { getUserShortName } from 'utils/index';

const DragonAvatarDropdown = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const { dropdownOptions, user, handleOptionClick } = useDragonAvatarDropdown();

  const handleChangeOption = event => setMenuAnchorEl(event.currentTarget);

  return (
    <Container>
      <StyledDragonAvatar
        id="user-avatar-button"
        data-testid="user-avatar-button"
        short={getUserShortName(user)}
        onClick={handleChangeOption}
        showBorder={false}
        noLabel
      />
      <DragonMenu
        items={dropdownOptions}
        anchorEl={menuAnchorEl}
        onSetAnchorEl={setMenuAnchorEl}
        onSelectItem={handleOptionClick}
        placement="bottom-end"
      />
    </Container>
  );
};

const StyledDragonAvatar = styled(DragonAvatar)`
  width: 38px;
  height: 38px;
  margin: 0;

  border: none;
  border-radius: 50%;

  padding: 5px;
  box-sizing: border-box;

  &:hover {
    background: ${({ theme }) => theme.palette.newLayout.background.lightestDisable};
  }

  div {
    width: 28px;
    height: 28px;
  }
`;

const Container = styled.div``;

export default DragonAvatarDropdown;
