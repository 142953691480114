import { useSelector } from 'react-redux';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import usePermissions from 'hooks/permissions/usePermissions';

import { selectPortfolioOverviewForActiveRoadmap, selectIsLoadingPortfolioOverviewForActiveRoadmap } from '../store';

const usePortfolioOverview = () => {
  const portfolioOverview = useSelector(selectPortfolioOverviewForActiveRoadmap);
  const isLoadingPortfolioOverview = useSelector(selectIsLoadingPortfolioOverviewForActiveRoadmap);

  const { canUpdate } = usePermissions();
  const userCanEdit = canUpdate(PERMISSION_RESOURCES.portfolioOverview);

  return { portfolioOverview, isLoadingPortfolioOverview, userCanEdit };
};

export default usePortfolioOverview;
