import { not, equals, isNil } from 'ramda';

import { FORECAST_BY_TIME_CAPACITY } from 'constants/forecast';
import {
  checkCapacityCellIsNotOnSameResourceTeamGroupRow,
  checkIfShouldShowTimeframeCapacityOnGrid,
  getReleaseCapacityAllocationMetadataByGroupRow,
} from 'utils/releaseCapacityAllocation';

const isForecastByTimeCapacity = equals(FORECAST_BY_TIME_CAPACITY);

export const METADATA_TO_DISABLE_EDIT = ['priority_id', 'owner_id', 'status_color', 'planningStage'];

/**
 * @function checkIsEstimateColEditable
 *
 * Helper function to check if estimate cell should be editable or not
 *
 * @param  {Object} params
 * @param  {Object} params.context
 * @param  {Object} params.data
 * @return {Boolean}
 */
const checkIsEstimateColEditable = params => {
  const { context, data, colDef } = params;
  const { forecastBy, pageFilters, selectedGroup1, selectedGroup2 } = context;

  const isGroup = data.group;
  const isForecastByTimeCapacityActive = isForecastByTimeCapacity(forecastBy);

  if (not(isGroup)) {
    return true;
  }

  if (not(isForecastByTimeCapacityActive)) {
    return false;
  }

  const showTimeframeCapacityOnGrid = checkIfShouldShowTimeframeCapacityOnGrid(selectedGroup1, selectedGroup2, data.groupOption);

  if (
    !showTimeframeCapacityOnGrid ||
    checkCapacityCellIsNotOnSameResourceTeamGroupRow(selectedGroup1, selectedGroup2, data.groupOption, data.groupData, colDef)
  ) {
    return false;
  }

  const { timeframeId, metadataField, metadataId } = getReleaseCapacityAllocationMetadataByGroupRow(data, pageFilters);

  const isTimeframeIdNotNull = not(isNil(timeframeId));

  /*
   * For metadata field rows will be editable for:
   *
   * Groups that are not on METADATA_TO_DISABLE_EDIT
   * metadata group is not undefined group
   */
  if (metadataField) {
    const isMetadataNotIncludedOnGroupsToDisableEdit = not(METADATA_TO_DISABLE_EDIT.includes(metadataField));
    const isMetadataIdNotNull = not(isNil(metadataId));

    return isTimeframeIdNotNull && isMetadataIdNotNull && isMetadataNotIncludedOnGroupsToDisableEdit;
  }

  /*
   * For timeframes rows will be editable for not undefined groups
   */
  return isTimeframeIdNotNull;
};

export default checkIsEstimateColEditable;
