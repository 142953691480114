export const metadataKeys = {
  user: 'user',
  roadmap: 'roadmap',
  objective: 'objective',
  timeframe: 'timeframe',
  priority: 'priority',
  theme: 'theme',
  category: 'category',
  customFields: 'customFields',
  tag: 'tag',
  customer: 'customer',
  phase: 'phase',
  metric: 'metric',
  persona: 'persona',
  lifecycle: 'lifecycle',
  team: 'team',
};
