import { css } from 'styled-components';
import theme from 'design-system/theme';

const ADD_CELL_CLASS = 'objectives-settings-add-item-cell';

const genericStyles = css`
  .ag-row-hover .${ADD_CELL_CLASS}, .ag-row-hover [col-id='actions'] {
    visibility: visible;
  }

  .${ADD_CELL_CLASS}, [col-id='actions'] {
    visibility: hidden;
  }

  .objective-corp-row {
    background-color: ${theme.palette.background.gallery} !important;
  }
`;

export { ADD_CELL_CLASS, genericStyles };
