import React, { Fragment, useRef, useState, useCallback } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import styled from 'styled-components';
import { List as VirtualizedList, CellMeasurerCache, CellMeasurer, InfiniteLoader } from 'react-virtualized';
// import InfiniteLoader from 'react-window-infinite-loader';

import NotificationItem from './NotificationItem';
import FullTextNotificationItem from './FullTextNotificationItem';

const Loading = styled.div`
  color: #1191bd;
`;

const NotificationsList = props => {
  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      fixHeight: true,
      height: 70,
    }),
  );

  const [selectedNotification, setSelectedNotification] = useState(null);

  const _handleOnClickNotificationFactory = useCallback(notification => {
    props.markNotificationAsRead(notification.id);
    setSelectedNotification(notification);
  }, []);

  const _handleGoBack = useCallback(() => setSelectedNotification(null), []);

  const _handleOnClickArchiveNotification = useCallback(notification => props.markNotificationAsArchived(notification.id), []);
  const _handleOnClickArchiveAllNotifications = useCallback(() => props.markAllNotificationsAsArchived(), []);

  const isItemLoaded = index => {
    return !props.notificationsMeta.next || index < props.notifications.length;
  };

  const _rowRenderer = ({ index, key, parent, style }) => {
    const notification = isItemLoaded(index) ? props.notifications[index] : null;

    return (
      notification &&
      !notification.archived && (
        <CellMeasurer cache={cache.current} columnIndex={0} key={key} rowIndex={index} parent={parent}>
          {({ measure, registerChild }) => (
            <div ref={registerChild} style={style}>
              <div>
                <Divider />
                <NotificationListItem
                  button
                  key={notification.id}
                  className={!notification.read ? props.classes.notificationNotRead : null}
                >
                  <ListItemText onClick={() => _handleOnClickNotificationFactory(notification)}>
                    <Typography>
                      <NotificationItem notification={notification} />
                    </Typography>
                  </ListItemText>
                  <ListItemIcon onClick={() => _handleOnClickArchiveNotification(notification)}>
                    <DeleteIcon fontSize="inherit" color="error" />
                  </ListItemIcon>
                </NotificationListItem>
              </div>
              {!notification && (
                <ListItem>
                  <Loading>Loading...</Loading>
                </ListItem>
              )}
            </div>
          )}
        </CellMeasurer>
      )
    );
  };

  return (
    <Fragment>
      <>
        <List subheader={<ListSubheader>Notifications</ListSubheader>}>
          <IconButton onClick={props.onClose} className={props.classes.closeIcon}>
            <CloseIcon />
          </IconButton>
          {selectedNotification && (
            <>
              <GoBack onClick={_handleGoBack}>
                <ArrowBackIosIcon fontSize="inherit" /> Go back
              </GoBack>
              <FullTextNotificationItemContainer>
                <FullTextNotificationItem notification={selectedNotification} />
              </FullTextNotificationItemContainer>
            </>
          )}
          {props.notifications && !selectedNotification && (
            <InfiniteLoader
              isRowLoaded={({ index }) => isItemLoaded(index)}
              rowCount={props.notificationsMeta.count}
              loadMoreRows={props.fetchNextNotifications}
            >
              {({ onRowsRendered, registerChild }) => (
                <VirtualizedList
                  className="List"
                  // deferredMeasurementCache={cache.current}
                  rowCount={props.notificationsMeta.count}
                  rowHeight={cache.current.rowHeight}
                  rowRenderer={_rowRenderer}
                  overscanRowCount={0}
                  // width={width}

                  height={300}
                  width={400}
                  // itemCount={props.notificationsMeta.count}
                  // itemSize={100}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                />
              )}
            </InfiniteLoader>
          )}
          <>
            <Divider />
            <ListItem>
              <Grid container>
                <Grid item xs={6}>
                  {props.notifications && !selectedNotification && (
                    <Typography
                      color="textPrimary"
                      variant="button"
                      size="medium"
                      className={props.classes.markAllAsArchived}
                      onClick={_handleOnClickArchiveAllNotifications}
                    >
                      Archive all
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </ListItem>
          </>
          {/* <Grid container item xs={6} >
              <Grid item>
                <FormControlLabel
                  label="Email notifications"
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={props.allowNotificationsEmails}
                      onChange={e => props.enableEmailNotifications && props.enableEmailNotifications(e.target.checked)}
                      value={props.allowNotificationsEmails}
                    />
                  }
                />
              </Grid>
            </Grid> */}
        </List>
      </>
    </Fragment>
  );
};

export default withStyles(theme => ({
  markAllAsArchived: {
    marginTop: '14px',
    textTransform: 'none',
    fontWeight: 400,
    cursor: 'pointer',
    width: 'fit-content',
    '&:hover': 'underline',
  },
  notificationNotRead: {
    backgroundColor: '#ffeff5',
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 100,
  },
}))(NotificationsList);

const GoBack = styled.span`
  &&&& {
    display: block;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text.info};
    // font-weight: 500;
    margin: 6px 0 12px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: fit-content;
  }
`;

const NotificationListItem = styled(ListItem)`
  &&&& {
    min-height: 70px;
    max-height: 70px;
  }
`;

const FullTextNotificationItemContainer = styled(ListItem)`
  &&&& {
    padding-top: 0;
    align-items: flex-start;
    height: 300px;
  }
`;
