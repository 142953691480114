import React from 'react';

import { IndentedWrapper, Wrapper } from './styled';

const GroupHeaderInnerRenderer = ({ value, data, isGrouping }) => {
  const isGroupRow = isGrouping && data.path.length === 1;

  return isGroupRow ? <Wrapper>{value}</Wrapper> : <IndentedWrapper $isGrouping={isGrouping}>{value}</IndentedWrapper>;
};

export default GroupHeaderInnerRenderer;
