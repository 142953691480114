import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { equals } from 'ramda';
import Button from '@material-ui/core/Button';

import AddFilterPopper from 'design-system/organisms/AddFilterPopper/index';
import AdvancedSearchHeader from 'design-system/molecules/AdvancedSearchHeader/index';
import AdvancedSearchPopover from 'design-system/organisms/AdvancedSearchPopover/index';
import CustomAdvancedSearchFooter from 'design-system/molecules/CustomAdvancedSearchFooter/index';
import FilterButtonWithBadge from 'design-system/atoms/FilterButtonWithBadge/index';
import FilterCondition from 'design-system/molecules/FilterCondition/index';


import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';

import useSystemFields from 'hooks/useSystemFields';
import useModuleAdvancedSearch from 'hooks/useModuleAdvancedSearch';

import useMetricsAdvancedSearch from '../hooks/useMetricsAdvancedSearch';
import { FUNCTIONS_BY_FIELD_ID, FUNCTIONS_BY_FIELD_TYPE, getAvailableFilterFields } from '../constants';

const HEADER_TITLE = 'Advanced Search';
const ADD_CONDITION = '+ Add Condition';
const ADD_FILTER = 'Add Filter';

const isNull = val => equals(val, null);

export default function MetricsAdvancedSearch({ onApplyFilters }) {
  const [metricAdvancedFilterAnchorEl, setMetricAdvancedFilterAnchorEl] = useState(null);
  const [addFilterAnchorEl, setAddFilterAnchorEl] = useState(null);

  const { canView } = usePermissions();
  const canViewMetricLevels = canView(PERMISSION_FEATURES.metricLevels);

  const filterPopperRef = useRef(null);

  const isOpen = useMemo(() => !!metricAdvancedFilterAnchorEl, [metricAdvancedFilterAnchorEl]);

  const [getSystemFieldName] = useSystemFields();
  const {
    addNewFilter,
    applyFilters,
    changeFilter,
    filters,
    getFieldFunction,
    getFieldOptions,
    getFieldValue,
    hasMetadataRoadmaps,
    removeFilter,
  } = useMetricsAdvancedSearch(isOpen);
  const availableFilterFields = getAvailableFilterFields(canViewMetricLevels);
  const { allFields, availableFields, badgeContent, getFieldFunctions, parsedFilters } = useModuleAdvancedSearch(
    filters,
    getSystemFieldName,
    hasMetadataRoadmaps,
    availableFilterFields,
    FUNCTIONS_BY_FIELD_ID,
    FUNCTIONS_BY_FIELD_TYPE,
  );

  const handleClose = useCallback(() => setMetricAdvancedFilterAnchorEl(null), [setMetricAdvancedFilterAnchorEl]);

  const handleSubmit = useCallback(() => {
    applyFilters(parsedFilters);
    handleClose();
    onApplyFilters && onApplyFilters(parsedFilters);
  }, [applyFilters, handleClose, parsedFilters, onApplyFilters]);

  const handleClickFilterButton = event => {
    if (isNull(metricAdvancedFilterAnchorEl)) {
      setMetricAdvancedFilterAnchorEl(event.currentTarget);
    }
  };

  const handleAddNewFilter = item => {
    addNewFilter(item);
    setAddFilterAnchorEl(null);
  };

  const handleClickAddNewFilterButton = event => setAddFilterAnchorEl(event.currentTarget);

  const _renderFilterCondition = filterKey => {
    const field = allFields.find(f => f.id === filterKey);

    if (!field) return '';

    const functions = getFieldFunctions(field);

    return (
      <FilterCondition
        key={filterKey}
        inputComponent={field.inputComponent}
        labelOverride={field.labelOverrideKey}
        data={{
          field: filterKey,
          value: getFieldValue(field),
          op: getFieldFunction(field),
        }}
        fields={allFields}
        functions={functions}
        options={getFieldOptions(field)}
        onChange={changeFilter}
        onRemove={removeFilter}
      />
    );
  };

  const _renderAddConditionButton = () =>
    availableFields.length > 0 && (
      <AddConditionContainer>
        <Button color="primary" onClick={handleClickAddNewFilterButton}>
          {ADD_CONDITION}
        </Button>
      </AddConditionContainer>
    );

  const _renderAddFilterPopper = () => (
    <AddFilterPopper
      anchorEl={addFilterAnchorEl}
      title={ADD_FILTER}
      items={availableFields}
      onItemClick={handleAddNewFilter}
      onClickAway={() => setAddFilterAnchorEl(null)}
      setRef={r => (filterPopperRef.current = r)}
    />
  );

  const _renderFilterConditions = () => useMemo(() => Object.keys(filters).map(_renderFilterCondition), [filters]);

  return (
    <>
      <FilterButton onClick={handleClickFilterButton} badgeContent={badgeContent} />
      <AdvancedSearchPopover
        header={<AdvancedSearchHeader>{HEADER_TITLE}</AdvancedSearchHeader>}
        footer={<CustomAdvancedSearchFooter onCancel={handleClose} onApply={handleSubmit} />}
        anchorEl={metricAdvancedFilterAnchorEl}
        onClose={handleClose}
        onSubmit={handleSubmit}
      >
        <FiltersContainer>
          {_renderFilterConditions()}
          {_renderAddConditionButton()}
          {_renderAddFilterPopper()}
        </FiltersContainer>
      </AdvancedSearchPopover>
    </>
  );
}

const AddConditionContainer = styled.div`
  padding: 0 16px 8px;

  button {
    text-transform: initial;
    font-weight: bold;
  }
`;

const FilterButton = styled(FilterButtonWithBadge)`
  &&&& {
    margin-left: 10px;
  }
`;

const FiltersContainer = styled.div`
  padding: 12px;
`;
