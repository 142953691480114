import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getEnrichedProject } from 'store/projects/selectors';

import { loadAutocompleteProjects } from 'store/projects/thunks';

export default (filters, includeMilestones = false) => {
  const [projects, setProjects] = useState([]);
  const dispatch = useDispatch();

  const selector = useCallback(
    state => {
      return (!includeMilestones ? projects.filter(p => p.type !== 'milestone') : projects).map(p =>
        getEnrichedProject(state, p),
      );
    },
    [projects, includeMilestones],
  );
  const _enrichedProjects = useSelector(selector);

  const _loadProjects = (value, extraFilters = []) => {
    return new Promise(resolve => {
      dispatch(loadAutocompleteProjects(value, filters, extraFilters)).then(({ value: { data } }) => {
        const projectsData = data?.data || [];

        setProjects(projectsData);

        const result = (!includeMilestones ? projectsData.filter(p => p.type !== 'milestone') : projectsData).map(p => ({
          entity: p,
          label: p.title,
          value: p.title,
        }));

        resolve(result);
      });
    });
  };

  return [_loadProjects, _enrichedProjects];
};
