import getSystemFieldName from 'utils/getSystemFieldName';
import TitleType from '../titleType';
import { BET, INITIATIVE } from 'constants/projects';

/**
 * Gets chart title based on `titleType`
 * @param {TitleType} titleType
 * @param {*} systemFields
 * @returns {String} title
 */
export const getChartTitle = (titleType = TitleType.PORTFOLIO, systemFields) => {
  if (titleType === TitleType.PORTFOLIO) {
    return 'Portfolio Burn Down';
  }
  if (titleType === TitleType.INITIATIVE) {
    return `${getSystemFieldName(INITIATIVE, systemFields)} Burn Down`;
  }
  if (titleType === TitleType.BET) {
    return `${getSystemFieldName(BET, systemFields)} Burn Down`;
  }

  throw new Error(`Unsupported title type: ${titleType}`);
};
