import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import theme from 'design-system/theme';
import PageHeaderFilters from 'containers/PageHeaderFilters';
import GoalMetricDropdown from 'containers/GoalMetricDropdown';
import usePageNavigation from 'hooks/usePagesNavigation/usePageNavigation';
import usePageQueryParamFilters from 'hooks/usePageQueryParamFilters';
import isQuickFilterApplied from 'utils/filters/isQuickFilterApplied';
import parseOutcomeDefaultFilter from 'utils/filters/parseOutcomeDefaultFilter';
import { OBJECTIVES_FILTER } from 'constants/filters/pages';
import { PORTFOLIO_LABEL } from 'constants/roadmaps';
import { setLastGoalsView } from 'store/app';
import { getCurrentUser } from 'store/login/selectors';
import { getPageFilters } from 'store/filters/selectors';
import { getOrgHasMetadataRoadmaps } from 'store/organization/selectors';
import getSelectedQuickFilterFromOutcomeDefaultFilters from 'utils/filters/getSelectedQuickFilterFromOutcomeDefaultFilters';

import { getDefaultFilters, needsToShowResultsCounterForFilters } from './utils';
import GoalsAdvancedSearch from '../GoalsAdvancedSearch';
import { updateOutcomeModuleFilters } from 'store/goalMode/thunks';
import { selectIsLoadingOutcomeGoals, selectOutcomeGoalsLoadedCounts } from 'store/goalMode/selectors';
import { TRANSACTION_PAGES_PROJECTS_LIMIT } from 'constants/index';

const GoalsPageHeaderFilters = props => {
  const dispatch = useDispatch();

  const { pages, selectedPage, setSelectedPage } = usePageNavigation('/goals', props.history, 'lastGoalsView', setLastGoalsView);

  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);
  const currentUser = useSelector(getCurrentUser);

  const isLoadingOutcomeGoals = useSelector(selectIsLoadingOutcomeGoals);
  const { total, loaded } = useSelector(selectOutcomeGoalsLoadedCounts);
  const pageFilters = useSelector(state => getPageFilters(state, OBJECTIVES_FILTER));
  const hasNoResults = total === 0;
  const showResultsCounter = hasNoResults || needsToShowResultsCounterForFilters(pageFilters);

  const defaultFilters = useMemo(() => getDefaultFilters(currentUser), [currentUser]);

  const totalByLayer = useMemo(() => ({ 0: total }), [total]);

  const applyFilterFromQueryParams = useCallback(
    filterId => {
      if (!filterId || isQuickFilterApplied(filterId, pageFilters)) return;

      const defaultFilter = defaultFilters.find(filter => filter.key === filterId);

      if (defaultFilter) {
        dispatch(updateOutcomeModuleFilters(parseOutcomeDefaultFilter(defaultFilter)));
      }
    },
    [defaultFilters, pageFilters],
  );

  const { removeFilterFromQueryParams } = usePageQueryParamFilters(applyFilterFromQueryParams);

  const customFilterRender = () => <GoalsAdvancedSearch onApplyFilters={removeFilterFromQueryParams} />;

  return (
    <PageHeaderFilters
      {...props}
      page={OBJECTIVES_FILTER}
      showDisplayLayer={false}
      showResultsCounter={showResultsCounter}
      showAdvancedSearch={false}
      showFilterIcon={false}
      showSavedFiltersOnQuickFilter={false}
      showQuickFilter={hasMetadataRoadmaps}
      quickFilterUndefinedLabel={PORTFOLIO_LABEL}
      // This is temporary
      customFilterRender={customFilterRender}
      subNavigationTabs={pages}
      selectedSubNavigationTab={selectedPage}
      handleSubNavigationChange={setSelectedPage}
      pageIconBackground={theme.palette.text.purple}
      customNumberOfResults={loaded}
      customIsLoading={isLoadingOutcomeGoals}
      customTotalByLayer={totalByLayer}
      customShouldDisplayTotalCount={total > TRANSACTION_PAGES_PROJECTS_LIMIT}
      ModuleCustomDropdownComponent={GoalMetricDropdown}
      defaultFilters={defaultFilters}
      onApplyQuickFilter={removeFilterFromQueryParams}
      getSelectedQuickFilterFromDefaultFilters={getSelectedQuickFilterFromOutcomeDefaultFilters}
    />
  );
};

export default GoalsPageHeaderFilters;
