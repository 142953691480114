import React, { useCallback, useMemo, createContext, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import intersection from 'lodash/intersection';
import flatten from 'lodash/flatten';

import { SUMMARY_PAGE } from 'constants/filters';
import useProjectGroups from 'hooks/useProjectGroups';
import { getAllProjectsFiltered } from 'store/projects/groupSelectors';

import useProjectsLocalState from 'hooks/useProjectsLocalState';
import { PORTFOLIO_LABEL } from 'constants/roadmaps';

import { generateSummaryData, computeGroupId } from '../helpers';
import useSummaryState from './useSummaryState';
import useProjectsLocalSearch from 'hooks/projects/useProjectsLocalSearch';
import { selectHasKeyResults2 } from 'store/organization';
import {
  selectSummaryMetadata,
  selectSummaryUserAddedMetadataIds,
  selectSummaryUserRemovedMetadataIds,
} from 'features/PortfolioSummary/Grouping/store/selectors';
import { groupAllowsLaneVisibilityToggle } from 'features/PortfolioSummary/Grouping/helpers';
import useProjectsLocalFilterByOwner from 'hooks/projects/useProjectsLocalFilterByOwner';
import useProjectsFrontendFilters from 'hooks/filters/useProjectsFontendFilters';
import { METADATA_KEY_MAPPER } from '../helpers/summaryMetadataKeyMapper';
import { shouldHideEmptyBasedOnUserPref } from 'utils/projects/grouping';
import { UNDEFINED } from 'constants/common';

const ROADMAP_KEY = 'roadmap';

/**
 * For goal mode we should render the label of undefined item as
 * Portfolio for roadmap items inside of (columns, rows, groups)
 *
 * Its used on the getTitle prop on generateSummaryData helper
 * because this controls all the title renders and send as param
 * the group key
 */
const renderItemTitleOnGoalMode = (item, title, groupKey) => {
  // only apply for undefined items
  if (!item?.id) {
    return groupKey === ROADMAP_KEY ? PORTFOLIO_LABEL : title;
  }

  return title;
};

const tableDataContext = createContext({});

const useSummaryTableData = () => useContext(tableDataContext);

const SummaryTableDataProvider = ({
  children,
  metadata,
  pageFilters,
  useGroupsHook = useProjectGroups,
  isGoalMode,
  slice,
  page = SUMMARY_PAGE,
}) => {
  const {
    allCollapsed,
    expandedGroups,
    hideEmptyLanes,
    selectedColOption,
    selectedRowOption,
    selectedGroupByOption,
    searchStr,
    updateState,
    showMyItemsOnly = false,
    userCanToggleLanesVisibility,
  } = useSummaryState(isGoalMode, slice);

  const projects = useSelector(state => getAllProjectsFiltered(state, page, false));

  const summaryMetadata = useSelector(selectSummaryMetadata);
  const summaryUserRemovedMetadataIds = useSelector(selectSummaryUserRemovedMetadataIds);
  const summaryUserAddedMetadataIds = useSelector(selectSummaryUserAddedMetadataIds);

  const hasKeyResults2 = useSelector(selectHasKeyResults2);

  // Operations to the local Summary data to prevent the back and forth update
  const { localProjects, localAddProject, localUpdateProject, localUpdateRowOrderProject } = useProjectsLocalState(projects);

  const projectsInAllLayers = useMemo(() => flatten(Object.values(localProjects)), [localProjects]);
  const [filterBySearchString] = useProjectsLocalSearch(projectsInAllLayers, searchStr);
  const filterMyItems = useProjectsLocalFilterByOwner(projectsInAllLayers, showMyItemsOnly);

  const { applyFrontendFiltersOnProject } = useProjectsFrontendFilters(filterMyItems, filterBySearchString);

  const shouldHideGroup = (groupEntity, groupKey, isCellGroup, isEmpty) => {
    const groupShouldAlwaysBeVisible = !groupAllowsLaneVisibilityToggle(groupKey);

    if (isCellGroup || groupShouldAlwaysBeVisible) return false;

    const metadataKey = METADATA_KEY_MAPPER[groupKey] || groupKey;

    const groupEntityId = groupEntity?.id || UNDEFINED;

    const groupIsRemovedByUser = summaryUserRemovedMetadataIds[metadataKey]?.some(id => id === groupEntityId);
    const groupIsAddedByUser = summaryUserAddedMetadataIds[metadataKey]?.some(id => id === groupEntityId);

    if (isEmpty) {
      return !groupIsAddedByUser;
    }

    return groupIsRemovedByUser;
  };

  const groupingOptions = useMemo(
    () => ({
      selectedGroup1: selectedRowOption,
      selectedGroup2: selectedColOption,
      selectedGroup3: selectedGroupByOption,
      page,
      hideEmptyUserPref: hideEmptyLanes,
      hideEmptyHandler: userCanToggleLanesVisibility ? undefined : shouldHideEmptyBasedOnUserPref,
      projectsFilters: [applyFrontendFiltersOnProject],
      customAllProjectsByLayer: localProjects,
      keyResultLevel: hasKeyResults2 ? 1 : 0,
      shouldHideGroup: userCanToggleLanesVisibility ? shouldHideGroup : undefined,
      customMetadata: userCanToggleLanesVisibility ? summaryMetadata : undefined, // if FF is off assume default grouping data
    }),
    [
      applyFrontendFiltersOnProject,
      hasKeyResults2,
      hideEmptyLanes,
      localProjects,
      metadata,
      selectedRowOption,
      selectedColOption,
      selectedGroupByOption,
      shouldHideGroup,
      summaryMetadata,
    ],
  );

  const projectGroups = useGroupsHook(groupingOptions);

  const tableData = useMemo(
    () =>
      generateSummaryData(projectGroups, {
        pageFilters,
        selectedColOption,
        selectedRowOption,
        selectedGroupByOption,
        getTitle: isGoalMode ? renderItemTitleOnGoalMode : null,
      }),
    [projectGroups, pageFilters, selectedColOption, selectedRowOption, selectedGroupByOption, isGoalMode],
  );

  const tableDataGroups = useMemo(() => {
    const { rows } = tableData ?? {};

    if (isEmpty(rows)) {
      return [];
    }

    return uniq(
      rows?.reduce(
        (acc, row) => [...acc, ...(row?.values.map(value => computeGroupId(row.id, value.col?.id, value.group?.[0]?.id)) ?? [])],
        [],
      ) ?? [],
    );
  }, [tableData]);

  const tableHasGroups = useMemo(
    () => !!(selectedGroupByOption?.key && tableDataGroups.length),
    [selectedGroupByOption, tableDataGroups],
  );

  const toggleAllTableDataGroups = useCallback(() => {
    const expandedGroups = allCollapsed ? tableDataGroups : [];

    updateState({ allCollapsed: !allCollapsed, expandedGroups });
  }, [allCollapsed, tableDataGroups]);

  useEffect(() => {
    if (selectedGroupByOption.key) {
      if (!allCollapsed && isEmpty(expandedGroups)) {
        updateState({ allCollapsed: true }, false);
      } else if (tableHasGroups) {
        const areAllExpanded = intersection(uniq(expandedGroups), tableDataGroups).length === tableDataGroups.length;

        if (!areAllExpanded !== allCollapsed) {
          updateState({ allCollapsed: !areAllExpanded }, false);
        }
      }
    }
  }, [selectedGroupByOption, allCollapsed, expandedGroups, tableHasGroups]);

  const value = useMemo(
    () => ({
      tableData,
      toggleAllTableDataGroups,
      localAddProject,
      localUpdateProject,
      localUpdateRowOrderProject,
      isGoalMode,
    }),
    [tableData, toggleAllTableDataGroups, localUpdateProject, localUpdateRowOrderProject, isGoalMode],
  );

  return <tableDataContext.Provider value={value}>{children}</tableDataContext.Provider>;
};

export { useSummaryTableData, SummaryTableDataProvider };
