import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';
import {
  FETCH_METRIC_LINKED_ITEMS,
  FETCH_METRIC_LINKED_ITEMS_FULFILLED,
  ADD_NEW_LINKED_ITEM_ROW,
  REMOVE_UNSAVED_LINKED_ITEMS_ROWS,
  LINK_PROJECT_TO_METRIC,
  UNLINK_PROJECT_FROM_METRIC,
  LINK_PROJECT_TO_METRIC_FULFILLED,
  UNLINK_PROJECT_FROM_METRIC_FULFILLED,
  CREATE_PROJECT_FROM_METRIC,
  CREATE_PROJECT_FROM_METRIC_FULFILLED,
} from './types';

const EMPTY_ARRAY = [];

export const initialState = {
  linkedItems: [],
  operations: bulkThunkInitialState([
    FETCH_METRIC_LINKED_ITEMS,
    LINK_PROJECT_TO_METRIC,
    UNLINK_PROJECT_FROM_METRIC,
    CREATE_PROJECT_FROM_METRIC,
  ]),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_METRIC_LINKED_ITEMS_FULFILLED:
      return {
        ...state,
        linkedItems: action.payload || EMPTY_ARRAY,
      };
    case ADD_NEW_LINKED_ITEM_ROW:
      return {
        ...state,
        linkedItems: [{ isLink: action.payload }, ...(state?.linkedItems || EMPTY_ARRAY)],
      };
    case REMOVE_UNSAVED_LINKED_ITEMS_ROWS:
      return {
        ...state,
        linkedItems: (state?.linkedItems || EMPTY_ARRAY).filter(r => r.id),
      };
    case LINK_PROJECT_TO_METRIC_FULFILLED:
    case CREATE_PROJECT_FROM_METRIC_FULFILLED:
      const project = action?.payload;
      const isDuplicated = (state?.linkedItems || EMPTY_ARRAY).some(r => r.id === project?.id);

      if (!project?.id) return state;

      if (isDuplicated) {
        return {
          ...state,
          linkedItems: (state?.linkedItems || EMPTY_ARRAY).filter(r => !r.isLink),
        };
      }

      return {
        ...state,
        linkedItems: [project, ...(state?.linkedItems || EMPTY_ARRAY)],
      };
    case UNLINK_PROJECT_FROM_METRIC_FULFILLED: {
      const project = action?.payload;

      if (!project?.id) return state;

      return {
        ...state,
        linkedItems: (state?.linkedItems || EMPTY_ARRAY).filter(r => r.id !== project?.id),
      };
    }

    default:
      return state;
  }
};

export const operationsReducer = getThunksReducers([
  FETCH_METRIC_LINKED_ITEMS,
  LINK_PROJECT_TO_METRIC,
  UNLINK_PROJECT_FROM_METRIC,
  CREATE_PROJECT_FROM_METRIC,
]);

export default reducer;
