import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import Dropdown from 'design-system/molecules/Dropdown/index';
import InlineTextField from 'design-system/atoms/InlineTextField/index';
import Checkbox from 'design-system/atoms/Checkbox/index';

import TruncateMarkup from 'react-truncate-markup';
import isNumber from 'lodash/isNumber';

const CustomLaneHeader = ({
  totalCount,
  jiraIntegrated,
  toSelect: isMerging,
  editDisabled,
  description,
  onMergeSelect,
  checked,
  id,
  group,
  canUpdateLane,
  displayPreferences,
  customFields,
  isProject,
  editLane,
  cancelEditLane,
  progressCount,
  cards,
  onChangeTitle,
  onArchive,
  onDelete,
  onMerge,
  ...props
}) => {
  const inputContainer = useRef(null);
  const dropdownContainer = useRef(null);
  const input = useRef(null);

  const [isEditing, setIsEditing] = useState(false);

  const [title, setTitle] = useState(props.title);
  const [initialTitle, setInitialTitle] = useState(props.title);

  const handleClickOutside = useCallback(
    event => {
      /* eslint-disable */
      const nodeInputContainer = ReactDOM.findDOMNode(inputContainer.current);
      const nodeDropdownContainer = ReactDOM.findDOMNode(dropdownContainer.current);
      /* eslint-enable */

      const outsideInput = !nodeInputContainer || !nodeInputContainer.contains(event.target);
      const outsideDropdown = !nodeDropdownContainer || !nodeDropdownContainer.contains(event.target);

      if (outsideInput && outsideDropdown) {
        stopEditing();
      }
    },
    [isEditing],
  );

  useEffect(() => {
    if (props.title) {
      setTitle(props.title);
    }
  }, [props.title]);

  useEffect(() => {
    if (isEditing) {
      if (input && input.current) {
        input.current.focus();
        input.current.select();
      }

      document.addEventListener('click', handleClickOutside, true);
    } else {
      document.removeEventListener('click', handleClickOutside, true);
    }
  }, [isEditing]);

  useEffect(() => {
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {}, [isEditing]);

  useEffect(() => {
    if (input && input.current) {
      getTitleHeight(input.current);
    }
  }, [input]);

  const [sumFieldAgg, sumFieldLabel] = useMemo(() => {
    let sumFieldAgg;
    let sumFieldLabel;

    if (displayPreferences.sumField) {
      const sumUpLabels = {
        effort_score: 'Effort',
        business_value: 'Benefit',
        ...customFields.reduce((acc, cf) => {
          acc[cf.key] = cf.title;
          return acc;
        }, {}),
      };

      sumFieldAgg = cards
        .filter(c => isNumber(c[displayPreferences.sumField]))
        .reduce((acc, entry) => acc + +entry[displayPreferences.sumField], 0);
      sumFieldLabel = sumUpLabels[displayPreferences.sumField];
    }

    return [sumFieldAgg, sumFieldLabel];
  }, [displayPreferences.sumField]);

  const startEditing = () => {
    if (!isEditing) {
      if (!isProject && !isEditing && !editDisabled && canUpdateLane) setIsEditing(true);

      editLane();
    }
  };

  const stopEditing = () => {
    if (isEditing) {
      setIsEditing(false);

      cancelEditLane();
    }
  };

  const updateTitle = () => {
    if (title !== initialTitle) {
      onChangeTitle(title);
      setInitialTitle(title);
    }
  };

  const onBlurTitle = () => {
    updateTitle();
  };

  const onTitleKeyDown = e => {
    const isReturn = e.keyCode === 13;
    const isEsc = e.keyCode === 27;

    if (isReturn) {
      updateTitle();
    } else if (isEsc) {
      setTitle(initialTitle);
    }

    if (isReturn || isEsc) {
      stopEditing();
    }
  };

  const handleOnChangeTitle = e => {
    setTitle(e.target.value);
  };

  const onChangeMoreOption = (e, { id }) => {
    if (id === 'archive') {
      onArchive();
    } else if (id === 'delete') {
      onDelete();
    } else if (id === 'merge') {
      onMerge();
    }

    stopEditing();
  };

  const getTitleHeight = input => {
    /* eslint-disable */
    const el = ReactDOM.findDOMNode(input);
    /* eslint-enable */
    const minHeight = 28;

    if (!el) return;

    // compute the height difference which is caused by border and outline
    const outerHeight = parseInt(window.getComputedStyle(el).height, 10);
    const diff = outerHeight - el.clientHeight;

    // set the height to 0 in case of it has to be shrinked
    el.style.height = 0;

    // set the correct height
    // el.scrollHeight is the full height of the content, not just the visible part
    el.style.height = `${Math.max(minHeight, el.scrollHeight + diff)}px`;
  };

  const moreOptions = [
    { id: 'merge', key: 'merge', title: 'Merge' },
    { id: 'archive', key: 'archive', title: 'Archive' },
    { id: 'delete', key: 'delete', title: 'Delete' },
  ];

  const _renderTitle = () => (
    <Title disabled={editDisabled || !canUpdateLane} edit={isEditing}>
      {editDisabled || !canUpdateLane ? (
        <span>
          {title}
          <TruncateMarkup lines="2">
            <SubTitle>{description ? ` (${description})` : ''}</SubTitle>
          </TruncateMarkup>
        </span>
      ) : (
        // eslint-disable-next-line jsx-a11y/interactive-supports-focus
        <a onClick={startEditing} role="link">
          {' '}
          <InlineTextField
            disabled={!isEditing}
            onBlur={onBlurTitle}
            onChange={handleOnChangeTitle}
            onKeyDown={onTitleKeyDown}
            ref={input}
            value={isEditing ? title : `${title}${description ? ` (${description})` : ''}`}
          />
        </a>
      )}
      {!isEditing && !editDisabled && canUpdateLane && (
        <EditLane onClick={startEditing}>
          <EditIcon />
        </EditLane>
      )}
    </Title>
  );

  const _renderTotalCountAndProgress = () => (
    <Stats>
      <Count>
        {totalCount} Total
        {!['initiative', 'bet'].includes(group) && jiraIntegrated && progressCount && (
          <a href={`/dashboard/progress/?${group}=${id !== '-1' ? id : null}`} style={{ marginLeft: 5 }}>
            View Progress
          </a>
        )}
      </Count>
      {displayPreferences.sumField && !!cards.length && (
        <SumField>
          {sumFieldLabel}: {sumFieldAgg}
        </SumField>
      )}
    </Stats>
  );

  const _renderMoreOptionsDropdown = () => (
    <SelectDiv>
      <Dropdown
        placeholder={
          <ButtonIcon>
            <MoreHorizIcon />
          </ButtonIcon>
        }
        getRef={r => {
          dropdownContainer.current = r;
        }}
        options={moreOptions}
        onChange={onChangeMoreOption}
        isButton={false}
      />
    </SelectDiv>
  );

  const _renderMergeCheckbox = () => (
    <CheckboxDiv>
      <Checkbox onChange={onMergeSelect} color="primary" checked={checked} />
    </CheckboxDiv>
  );

  return (
    <Wrapper ref={inputContainer}>
      {_renderTitle()}
      {!isEditing && !isMerging && _renderTotalCountAndProgress()}
      {isEditing && _renderMoreOptionsDropdown()}
      {isMerging && parseInt(id) !== -1 && _renderMergeCheckbox()}
    </Wrapper>
  );
};

const Wrapper = styled.header`
  padding: 0 6px 5px 6px;
  min-height: 34px;
`;

const Count = styled.div`
  padding: 2px 0 0 8px;
  margin-bottom: 8px;
`;

const SumField = styled.div`
  display: inline-block;
  float: right;
  margin-top: -20px;
`;

const Title = styled.div`
  position: relative;
  font-size: 14px;
  width: 100%;
  padding: 2px;
  border-bottom: 1px solid #0000001a;

  ${({ edit }) =>
    edit &&
    `
    width: calc(100% - 40px);
    margin-bottom: 25px;
  `}

  ${({ disabled }) =>
    disabled &&
    `
    textarea{
      cursor: inherit!important;
    }
    padding: 6px 0;
  `}

  textarea, span {
    font-size: 14px;
    font-weight: normal;
    color: rgb(57, 57, 57);
    font-family: Inter, Helvetica, Arial, sans-serif;
  }

  span {
    margin-bottom: 8px;
    margin-left: 8px;
    display: block;
  }

  button {
    opacity: 0;
    transition: all 0.5s;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
`;

const SubTitle = styled.div`
  &&&& {
    font-size: 12px;
    color: #8b8b8b;
    margin-top: 5px;
    line-height: 14px;
  }
`;

const SelectDiv = styled.div`
  div[role='button'] {
    position: absolute;
    top: 0px;
    right: 6px;
  }
`;

const CheckboxDiv = styled.div`
  > span {
    padding: 8px 0;
  }
`;

const EditLane = styled(ButtonIcon)`
  &&&& {
    position: absolute;
    right: 0;
    top: 0;
    padding: 6px;
    color: #a8a1a1;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const Stats = styled.div`
  margin-top: 10px;
  font-size: 12px;
`;

export default CustomLaneHeader;
