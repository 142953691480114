import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { defaultTo } from 'ramda';

import { notificationTypes } from 'hooks/useAppNotifications';
import { PORTFOLIO_MODE } from 'constants/common';

const defaultEmptyArray = defaultTo([]);
const defaultToPortfolioMode = defaultTo(PORTFOLIO_MODE);

export const getNotifications = state => state.app.notifications || [];

export const getAppState = state => state.app;

export const getIsPermissionsDialogVisible = createSelector(getAppState, state => state.showPermissionsDialog);

export const getFieldIntegrationErrors = createSelector(getNotifications, notifications =>
  notifications.filter(notification => notification.type === notificationTypes.FIELD_INTEGRATION_ERROR),
);

export const getNotificationById = createSelector([state => getNotifications(state), (state, id) => id], (notifications, id) =>
  notifications.find(notification => notification.id === id),
);

export const getLeftNavigationExpandedItems = createCachedSelector([state => getAppState(state)], appState => {
  return defaultEmptyArray(appState.leftNavigationExpandedItems);
})(() => `getLeftNavigationExpandedItems`);

export const getPageMode = createCachedSelector([state => getAppState(state)], appState => {
  return defaultToPortfolioMode(appState?.pageMode);
})(() => `getPageMode`);

export const getIsMetadataFetched = createSelector(getAppState, state => state.isMetadataFetched);

export const getGoBackUrls = createSelector(getAppState, state => state.goBackUrls);

export const getDidOneClickPlanModeRun = createSelector(getAppState, state => state.oneClickPlanMode?.didRun || false);
export const getOneClickPlanModeReturnVersion = createSelector(
  getAppState,
  state => state.oneClickPlanMode?.returnToVersion || null,
);

export const selectIsScenarioRoute = state => getAppState(state)?.isScenarioRoute || false;
