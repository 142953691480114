import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from '@material-ui/core/Input';

import MetadataFluidField from 'design-system/molecules/MetadataFluidField/index';
import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';

import ByTeamAutocomplete from '../ByTeamAutocomplete';

const FluidGroupAutocompleteField = props => {
  const { name, options = [], getSystemFieldName, isArchived, focusOnInit, withNullOption } = props;

  return (
    <MetadataFluidField
      placeholder={TYPE_TO_SEARCH_PLACEHOLDER}
      {...props}
      renderer={(value, { placeholder } = {}) => (
        <StyledInput
          type="text"
          value={value}
          minRows={1}
          isArchived={isArchived}
          readOnly={props.disableEdit}
          placeholder={placeholder}
          multiline
          disableUnderline
        />
      )}
      editorRenderer={({ save, cancel, disableEdit, placeholder }) => (
        <ByTeamAutocomplete
          name={name}
          options={options}
          disabled={disableEdit}
          placeholder={placeholder}
          getSystemFieldName={getSystemFieldName}
          onSelectOptionCallback={save}
          onCloseList={cancel}
          focusOnInit={focusOnInit}
          withNullOption={withNullOption}
        />
      )}
    />
  );
};

FluidGroupAutocompleteField.propTypes = {
  ...MetadataFluidField.propTypes,
  options: PropTypes.array.isRequired,
};

const StyledInput = styled(Input)`
  &&&& {
    ${({ isArchived, theme }) =>
      isArchived
        ? `
    color: ${theme.palette.text.lightGrey}`
        : ''}
  }
`;

export default FluidGroupAutocompleteField;
