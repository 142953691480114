import axios from 'axios';
import { omit } from 'ramda';

import { createThunk } from 'utils/store/thunk';

import {
  UPDATE_GRID_LIFECYCLE,
  FETCH_LIFECYCLES_GRID_DATA,
  SAVE_GRID_NEW_LIFECYCLE,
  DELETE_GRID_LIFECYCLE,
  SWITCH_LIFECYCLES_GRID_ROW_ORDER,
} from './types';
import { moveRowToPositionUsingLexorank } from 'utils/lexorankUtils';
import { selectLifecyclesGridData } from './selectors';

const updateGridLifecycle = (id, dataToUpdate) => {
  return createThunk(UPDATE_GRID_LIFECYCLE, () => {
    return {
      promise: axios.put(`/api/v1/lifecycles/${id}`, dataToUpdate).then(({ data }) => data),
      data: {
        id,
        ...dataToUpdate,
      },
    };
  });
};

const createNewGridLifecycle = newLifecycleData => {
  return createThunk(SAVE_GRID_NEW_LIFECYCLE, async () => {
    const createdLifecycle = omit(['isNew'], newLifecycleData);

    return axios.post('/api/v1/lifecycles', createdLifecycle).then(({ data }) => data);
  });
};

const deleteGridLifecycle = lifecycleId => {
  return createThunk(DELETE_GRID_LIFECYCLE, async () => {
    return axios
      .delete(`/api/v1/lifecycles/${lifecycleId}`)
      .then(() => lifecycleId)
      .catch(() => lifecycleId);
  });
};

const fetchLifecyclesGridData = () =>
  createThunk(
    FETCH_LIFECYCLES_GRID_DATA,
    axios.get('/api/v1/lifecycles').then(({ data }) => data),
  );

const switchRowOrder = (id1, id2, update, position) => {
  return (dispatch, getState) => {
    const state = selectLifecyclesGridData(getState());
    const prevData = state.find(({ id }) => id === +id1);
    const movedRow = moveRowToPositionUsingLexorank(state, id1, id2, position);

    const payload = axios.put(`/api/v1/lifecycles/rowOrder/${id1}/${id2}`, { position }).then(res => res.data);

    dispatch({
      type: SWITCH_LIFECYCLES_GRID_ROW_ORDER,
      payload,
      meta: { prev: prevData, row: movedRow, update },
    });

    return payload;
  };
};

export { updateGridLifecycle, createNewGridLifecycle, fetchLifecyclesGridData, deleteGridLifecycle, switchRowOrder };
