import React, { useMemo, useCallback, useRef } from 'react';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';

import AgGridHierarchyTitle from 'design-system/organisms/AgGridHierarchyTitle/index';

import ProjectType from 'containers/ProjectType';
import IdeasList from 'containers/IdeasList/IdeasList';
import IdeasListControlsBar from 'containers/IdeasList/IdeasListControlsBar';
import { checkRolePermission } from 'containers/UserPermission/utils';
import { INITIATIVE_LAYER, BET_LAYER, IDEA_LAYER } from 'store/projects/constants';
import { getRowStyle, getGridTreeDataOptions } from 'utils/projectsGrid';
import { DISABLE_ADD_GROUPS } from 'constants/common';
import useNumberOfTextRowsToDisplayOnGridCell from 'hooks/useNumberOfTextRowsToDisplayOnGridCell';
import generateOkrIcon from 'utils/okrs/generateOkrIcon';
import { getIdeasHiearchyNameByDisplayLayer } from 'utils/projects/common';

const IdeasGrid = props => {
  const {
    data,
    pageId,
    viewType,
    availableViews,
    portfolioMode,
    onGridReady,
    getGridApi,
    onProjectLinkClick,
    searchString,
    getSystemFieldName,
    currentUser,
    singleLayerGroupedBy,
    rowHeight,
    addUnsavedProject,
    showPageConfigForReadOnly,
    onGroupsLinkClick,
    displayLayer,
    hasIdeas,
    showMyItemsOnly = false,
    viewsDropdownTabs,
    hasTreeView,
    isTreeView,
  } = props;

  // TODO: legacy
  const userCanCreate = checkRolePermission('ADD_NEW_IDEA', currentUser);

  const { getNumberOfTextRowsToDisplayOnGridCell } = useNumberOfTextRowsToDisplayOnGridCell(rowHeight);

  const searchRef = useRef();

  searchRef.current = searchString;

  const renderTitle = row => {
    if (!row) return '';
    if (!searchRef.current) return row.title;

    return (
      <>
        <Highlighter
          highlightClassName="hightlightText"
          searchWords={[searchRef.current]}
          autoEscape
          textToHighlight={row.title}
        />
      </>
    );
  };

  const rendererForPortfolio = useCallback(
    params => {
      if (!params.data) {
        params.node.notExpandable = true;

        return (
          <AgGridHierarchyTitle
            padChild
            params={params}
            showChildrenCount
            showAddOnHover={userCanCreate}
            addChild={() => addUnsavedProject(params)}
          />
        );
      }
      const { groupOption } = params.data;
      const showAddOnHover = userCanCreate && !DISABLE_ADD_GROUPS.includes(groupOption?.key);

      switch (params.data && +params.data.layer) {
        case +BET_LAYER:
          return (
            <AgGridHierarchyTitle
              renderTitle={renderTitle}
              icon={<ProjectType layer={params.data.layer} />}
              padChild
              params={params}
              showChildrenCount={!singleLayerGroupedBy}
              childrenCount={params.data.childrenCountFiltered}
              link={id => onProjectLinkClick(id)}
              wrapText
              showAddOnHover={showAddOnHover}
              addChild={() => addUnsavedProject(params)}
              getMaxLineNumber={getNumberOfTextRowsToDisplayOnGridCell}
            />
          );
        case +INITIATIVE_LAYER:
          return (
            <AgGridHierarchyTitle
              renderTitle={renderTitle}
              icon={<ProjectType layer={params.data.layer} />}
              isChild
              padChild
              params={params}
              showChildrenCount={!singleLayerGroupedBy}
              childrenCount={params.data.childrenCountFiltered}
              link={(id, data) => onProjectLinkClick(id, data)}
              wrapText
              showAddOnHover={showAddOnHover}
              addChild={() => addUnsavedProject(params)}
              getMaxLineNumber={getNumberOfTextRowsToDisplayOnGridCell}
            />
          );
        case +IDEA_LAYER:
          return (
            <AgGridHierarchyTitle
              renderTitle={renderTitle}
              icon={<ProjectType layer={params.data.layer} />}
              isChild
              padChild
              params={params}
              childrenCount={params.data.childrenCountFiltered}
              link={(id, data) => onProjectLinkClick(id, data)}
              wrapText
              getMaxLineNumber={getNumberOfTextRowsToDisplayOnGridCell}
            />
          );
        default:
          return (
            <AgGridHierarchyTitle
              renderTitle={renderTitle}
              icon={params.data.okrType && generateOkrIcon(params.data.okrType)}
              isChild
              padChild
              params={params}
              childrenCount={params.data.childrenCountFiltered}
              wrapText
              showAddOnHover={showAddOnHover}
              addChild={() => addUnsavedProject(params)}
              link={(id, data) => onGroupsLinkClick(data)}
              getMaxLineNumber={getNumberOfTextRowsToDisplayOnGridCell}
            />
          );
      }
    },
    [addUnsavedProject, singleLayerGroupedBy, onProjectLinkClick],
  );

  const headerName = singleLayerGroupedBy ? '' : getIdeasHiearchyNameByDisplayLayer(displayLayer, getSystemFieldName, hasIdeas);

  const gridOptions = useMemo(
    () => ({
      getRowStyle,
      ...(portfolioMode || singleLayerGroupedBy
        ? getGridTreeDataOptions(
            portfolioMode,
            singleLayerGroupedBy,
            headerName,
            rendererForPortfolio,
            getSystemFieldName,
            currentUser.is_anonymous,
          )
        : {}),
    }),
    [portfolioMode, singleLayerGroupedBy, headerName, rendererForPortfolio, getSystemFieldName],
  );

  return (
    <>
      {showPageConfigForReadOnly ? (
        <IdeasListControlsBar
          viewType={viewType}
          availableViews={availableViews}
          pageId={pageId}
          numberOfRows={data.length}
          getGridApi={getGridApi}
          showMyItemsOnly={showMyItemsOnly}
          viewsDropdownTabs={viewsDropdownTabs}
          isTreeView={isTreeView}
          usePortolioMode
          hasViews
          hasImport
          hasBulkUpdate
          hasBulkDelete
          hasGroupBy
          hasShowMyItemFilter
          hasRoadmapHistory
          hasTreeView={hasTreeView}
        />
      ) : (
        <EmptyHeight />
      )}
      <IdeasList
        data={data}
        viewType={viewType}
        gridOptions={gridOptions}
        treeData={portfolioMode || !!singleLayerGroupedBy}
        onGridReady={onGridReady}
        renderTitle={renderTitle}
        isTreeView={isTreeView}
      />
    </>
  );
};

const EmptyHeight = styled.div`
  height: 48px;
`;

export default IdeasGrid;
