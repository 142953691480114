import React from 'react';

import { TitleCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';

const GroupTitleRenderer = params => {
  const { displayChildrenCount = true } = params;

  return <TitleCellRenderer {...params} displayChildrenCount={displayChildrenCount} bold />;
};

export default GroupTitleRenderer;
