import React from 'react';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DetailsIcon from '@material-ui/icons/Assignment';
import HistoryIcon from '@material-ui/icons/History';
import CloseIcon from '@material-ui/icons/Close';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import HierarchyBreadcrumbTitles from 'design-system/organisms/HierarchyBreadcrumbTitles/index';

import { spacing } from 'design-system/theme';
import Roadmap from 'design-system/atoms/DragonIcons/Roadmap';
import { removeElemProps } from 'design-system/utils';

import useGenerateOKRBreadcrumbTitles from 'hooks/useGenerateOKRBreadcrumbTitles';
import { isValidTab, DETAILS_TAB, LINKED_ITEMS_TAB, HISTORY_TAB } from '../constants';

export default ({ selected, selectedType, updateTitle, active, toggleActive, onClose, activeTab, changeTab, isEditable }) => {
  const isNew = !selected?.id;

  const _renderTab = (name, icon, active, visible, value, onClick, disabled = false) =>
    visible && (
      <HeaderTab
        component="div"
        disableTouchRipple
        disableRipple
        onClick={onClick}
        value={value}
        label={
          <TabIcon active={active} title={name} disabled={disabled}>
            {icon}
          </TabIcon>
        }
        disabled={disabled}
      />
    );

  // creates a list of items with the correct icons, titles, actions and other props for breadcrumb title
  const { item, parentItem, ancestryItems } = useGenerateOKRBreadcrumbTitles(
    selected,
    active,
    updateTitle,
    isEditable,
    selectedType,
  );

  return (
    <Container>
      <StyledHierarchyBreadcrumbTitles item={item} parentItem={parentItem} ancestryItems={ancestryItems} showTooltip />

      <TabsWrapper>
        <FormControlLabel
          value="start"
          control={<Switch color="secondary" disabled={!isEditable || isNew} checked={active} onChange={toggleActive} />}
          label="Active"
          labelPlacement="start"
        />
        <ObjectiveTabs
          value={activeTab}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          textColor="primary"
          onChange={(_, tab) => {
            if (!isValidTab(tab)) return;
            changeTab(tab);
          }}
        >
          {_renderTab(DETAILS_TAB.label, <DetailsIcon />, activeTab === DETAILS_TAB.id, true, DETAILS_TAB.id)}
          {_renderTab(
            LINKED_ITEMS_TAB.label,
            <Roadmap />,
            activeTab === LINKED_ITEMS_TAB.id,
            true,
            LINKED_ITEMS_TAB.id,
            null,
            isNew,
          )}
          {_renderTab(HISTORY_TAB.label, <HistoryIcon />, activeTab === HISTORY_TAB.id, true, HISTORY_TAB.id, null, isNew)}
          {_renderTab('Close', <CloseIcon />, false, true, null, onClose)}
        </ObjectiveTabs>
      </TabsWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledHierarchyBreadcrumbTitles = styled(HierarchyBreadcrumbTitles)`
  padding: ${spacing(0.5)}px 0;
`;

const TabsWrapper = styled.div`
  flex: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ObjectiveTabs = styled(Tabs)`
  && {
    padding-left: 20px;
    min-height: unset;
  }
`;

const TabIcon = styled(removeElemProps(ButtonIcon, ['active']))`
  &&&&& {
    font-size: ${({ theme }) => theme.typography.fontSizeMediumSmallRem}rem;
    max-height: 35px;
    max-width: 35px;
    margin-left: ${spacing(0.5)}px;

    ${({ active }) =>
      active &&
      `
      color: #009dff;
    `}
    svg {
      ${({ active }) =>
        active &&
        `
          fill: #009dff;
        `}
    }
  }
`;

const HeaderTab = styled(Tab)`
  &&&& {
    min-height: 0;
    font-weight: 400;
    font-size: 0.875rem;
    min-width: 0;

    > span > span {
      padding: 0 2px;
    }

    svg {
      font-size: 20px;
    }
  }
`;
