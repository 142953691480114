import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import { defaultTo, not } from 'ramda';

import upsertListItem from '../utils/upsertListItem';
import {
  ADD_CUSTOM_FIELD_WITHOUT_SAVE,
  CREATE_CUSTOM_FIELD_FULFILLED,
  CREATE_CUSTOM_FIELD_REJECTED,
  CREATE_CUSTOM_FIELDS_FULFILLED,
  DELETE_CUSTOM_FIELD_FULFILLED,
  FETCH_CUSTOM_FIELDS_FULFILLED,
  FETCH_CUSTOM_FIELDS_PENDING,
  REMOVE_UNSAVED_CUSTOM_FIELDS,
  UPDATE_CUSTOM_FIELD_BY_ID_FULFILLED,
  UPDATE_CUSTOM_FIELD_FULFILLED,
  UPDATE_CUSTOM_FIELDS_FULFILLED,
  CREATE_CUSTOM_FIELD_ROADMAP_FULFILLED,
  DELETE_CUSTOM_FIELD_ROADMAP_FULFILLED,
  BULK_DELETE_CUSTOM_FIELD_ROADMAP_FULFILLED,
  CREATE_CUSTOM_FIELD_ASSOCIATION_FULFILLED,
  DELETE_CUSTOM_FIELD_ASSOCIATION_FULFILLED,
} from './types';
import addRows from 'store/utils/addRows';
import updateRows from 'store/utils/updateRows';
import { enrichCustomFieldsWithCustomerFields, isCustomerCustomField } from './helpers/customerCustomFields';

const defaultAsEmptyArray = defaultTo([]);

const initialState = {
  customFields: [],
  isLoaded: false,
};

function removeUnsaved(list) {
  return list.filter(el => el.id);
}

function updateCustomField(customFields, customField) {
  return customFields.map(bg => (bg.id === customField.id ? customField : bg));
}

function resetUnsaved(customFields) {
  return customFields.map(cf => (cf.id ? cf : { ...cf, title: '' }));
}

export default function customFieldsReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_CUSTOM_FIELD_FULFILLED:
      if (!action.payload || !action.payload.id) {
        return state;
      }

      let customFields = state.customFields || [];

      customFields = removeUnsaved(customFields);
      customFields = upsertListItem(action.payload, customFields);

      return {
        ...state,
        customFields,
        isLoaded: true,
      };
    case CREATE_CUSTOM_FIELD_REJECTED:
      const mappedFields = resetUnsaved(state.customFields);

      return {
        ...state,
        customFields: mappedFields,
        isLoaded: true,
      };
    case FETCH_CUSTOM_FIELDS_PENDING:
      return Object.assign({}, state, {
        isLoaded: false,
      });
    case FETCH_CUSTOM_FIELDS_FULFILLED:
      const fulfilledState = {
        isLoaded: true,
        lastCallsDate: moment().valueOf(),
      };
      const includesCustomerCustomFields = action.payload.data.some(isCustomerCustomField);

      if (includesCustomerCustomFields) {
        return Object.assign({}, state, {
          customFields: enrichCustomFieldsWithCustomerFields(action.payload.data),
          ...fulfilledState,
        });
      }

      return Object.assign({}, state, {
        customFields: action.payload.data,
        ...fulfilledState,
      });
    case UPDATE_CUSTOM_FIELD_BY_ID_FULFILLED:
    case UPDATE_CUSTOM_FIELD_FULFILLED: {
      return {
        ...state,
        customFields: updateCustomField(state.customFields, action.payload),
        isLoaded: true,
      };
    }
    case DELETE_CUSTOM_FIELD_FULFILLED:
      return {
        ...state,
        customFields: state.customFields.filter(customField => customField.id !== action.payload),
      };
    case ADD_CUSTOM_FIELD_WITHOUT_SAVE: {
      const customFields = state.customFields ? removeUnsaved(state.customFields) : [];

      customFields.unshift({
        ...(action.customField || {}),
        associations: [{ association_type: action.customField.association_type }],
        isNew: true,
      });

      return {
        ...state,
        customFields,
      };
    }
    case REMOVE_UNSAVED_CUSTOM_FIELDS:
      return {
        ...state,
        customFields: state.customFields.filter(customField => customField.id),
      };
    case CREATE_CUSTOM_FIELDS_FULFILLED: {
      const customFields = addRows(state.customFields, action.payload);

      return {
        ...state,
        customFields,
        lastActionIds: action.payload.map(customField => customField.id),
      };
    }
    case UPDATE_CUSTOM_FIELDS_FULFILLED: {
      const customFields = updateRows(state.customFields, action.payload);

      return {
        ...state,
        customFields,
        lastActionIds: action.payload.map(customField => customField.id),
      };
    }
    case CREATE_CUSTOM_FIELD_ROADMAP_FULFILLED:
      const cfs = cloneDeep(state.customFields);
      const customField = cfs.find(obj => obj.id === action.payload.custom_field_id);

      customField.custom_field_roadmaps = [...(customField.custom_field_roadmaps || []), action.payload];

      const updatedCustomFields = upsertListItem(customField, cfs);

      return {
        ...state,
        customFields: updatedCustomFields,
      };
    case DELETE_CUSTOM_FIELD_ROADMAP_FULFILLED:
      const existing = cloneDeep(state.customFields);
      const { id: customFieldId, customFieldRoadmaps } = action.payload;
      const parent = existing.find(obj => obj.id === parseInt(customFieldId));

      parent.custom_field_roadmaps = customFieldRoadmaps || [];

      const updated = upsertListItem(parent, existing);

      return {
        ...state,
        customFields: updated,
      };
    case BULK_DELETE_CUSTOM_FIELD_ROADMAP_FULFILLED:
      const existingCfs = cloneDeep(state.customFields);
      const updatedObjs = existingCfs.map(customField => {
        if (customField.id === action.payload) {
          return {
            ...customField,
            custom_field_roadmaps: [],
          };
        }

        return customField;
      });

      return {
        ...state,
        customFields: updatedObjs,
      };
    case CREATE_CUSTOM_FIELD_ASSOCIATION_FULFILLED:
      return {
        ...state,
        customFields: state.customFields.map(cf => {
          if (cf.id === action.meta.customFieldId) {
            return {
              ...cf,
              associations: [...defaultAsEmptyArray(cf.associations), action.payload],
            };
          }

          return cf;
        }),
      };
    case DELETE_CUSTOM_FIELD_ASSOCIATION_FULFILLED:
      return {
        ...state,
        customFields: state.customFields.map(cf => {
          if (cf.id === action.meta.customFieldId) {
            const isSameAssociationType = association => association.association_type === action.meta.associationType;
            const isSameCustomField = association => association.custom_field_id === action.meta.customFieldId;

            const excludeDeletedAssociation = association =>
              not(isSameCustomField(association) && isSameAssociationType(association));

            return {
              ...cf,
              associations: defaultAsEmptyArray(cf.associations).filter(excludeDeletedAssociation),
            };
          }

          return cf;
        }),
      };
    default:
      return state;
  }
}
