import React from 'react';

import { INITIATIVE_LAYER, BET_LAYER, IDEA_LAYER } from 'store/projects/constants';

import { checkDataIsOkr } from 'containers/IdeasList/IdeasList/New/helpers';

import FillerGroupRenderer from './FillerGroupRenderer';
import GroupTitleRenderer from './GroupTitleRenderer';
import OkrTitleRenderer from './OkrTitleRenderer';
import ProjectTitleRenderer from './ProjectTitleRenderer';

/**
 * Serves as an entry point. It then chooses the best strategy to present the content.
 */
const GroupHeaderInnerRenderer = params => {
  if (!params.data) {
    return <FillerGroupRenderer {...params} />;
  }

  if (checkDataIsOkr(params.data)) {
    return <OkrTitleRenderer {...params} />;
  }

  switch (params.data && +params.data.layer) {
    case +BET_LAYER:
    case +INITIATIVE_LAYER:
    case +IDEA_LAYER:
      return <ProjectTitleRenderer {...params} />;
    default:
      return <GroupTitleRenderer {...params} />;
  }
};

export default GroupHeaderInnerRenderer;
