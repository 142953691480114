import React from 'react';

import FluidField from 'design-system/molecules/FluidField/index';
import SecondaryColorPicker from 'design-system/atoms/SecondaryColorPicker/index';

const FluidColorField = props => (
  <FluidField
    {...props}
    renderer={value => (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          backgroundColor: value,
          width: 80,
          height: 20,
          borderRadius: 5,
          transform: 'translateY(-50%)',
        }}
      />
    )}
    editorRenderer={({ value, onChange, save, cancel }) => (
      <SecondaryColorPicker color={value} onChange={onChange} handleClose={save} />
    )}
    valueOrPlaceholderStyles={{ position: 'relative' }}
    ignoreBlurOn={() => !!document.querySelector('.chrome-picker')}
  />
);

// FluidColorField.propTypes = { ...FluidField.propTypes, };

export default FluidColorField;
