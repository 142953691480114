import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import IssuesProgressBar from 'design-system/organisms/IssuesProgressBar/index';
import Segment from 'design-system/atoms/Segment/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import { materialColors } from 'design-system/themes/default';

import useGetProgressDateRange from './hooks/useGetProgressDateRange';

import { formatNumberWithCommas } from 'design-system/utils/numbers';
import ChartWrapper from 'components/ReactChart/utils/ChartWrapper';
import { withTheme } from '@material-ui/core';
import TimelineLightbox from 'features/TimelineLightbox';
import { DASHBOARDS_PAGE } from 'constants/filters';
import usePageFilters from 'hooks/filters/usePageFilters';
import { buildFilterForTimelineProjects } from '../helpers';

const LINKED_ISSUES_SUMMARY_URL = '/api/v1/reports/dashboard/linked-issue-summary';
const DEFAULT_REPORT_DATA = { totalLinkedIssues: 0, inProgressIssues: 0, completedIssues: 0 };
const DEFAULT_WIDGET_HEIGHT = 100;

const calcPercent = (value, total) => (value / total) * 100;

function LinkedIssuesSummaryWidget({ wrapper: Wrapper, dashboardState, actionButtons, theme }) {
  const [openTimeline, setOpenTimeline] = useState();

  const { pageFilters, displayLayer } = usePageFilters(DASHBOARDS_PAGE);

  const registerOpenTimelineCallback = useCallback(
    openTimeline => {
      setOpenTimeline(() => openTimeline);
    },
    [setOpenTimeline],
  );

  const { reportData } = useGetProgressDateRange({
    dashboardState,
    defaultReportData: DEFAULT_REPORT_DATA,
    fetchUrl: LINKED_ISSUES_SUMMARY_URL,
  });

  const totalIssues = useMemo(() => formatNumberWithCommas(reportData.totalLinkedIssues), [reportData]);

  const onProgressBarClick = useCallback(
    ({ searchUuid }) => {
      const timelineFilters = buildFilterForTimelineProjects(pageFilters, null, null, displayLayer, searchUuid);

      openTimeline(timelineFilters);
    },
    [pageFilters, displayLayer, openTimeline],
  );

  const progressBarValues = useMemo(() => {
    const {
      totalLinkedIssues,
      todoIssuesSearchUuid,
      inProgressIssues,
      inProgressIssuesSearchUuid,
      completedIssues,
      completedSearchUuid,
    } = reportData;

    const openIssues = totalLinkedIssues - (inProgressIssues + completedIssues);

    return [
      {
        percentage: calcPercent(completedIssues, totalLinkedIssues),
        label: 'Closed',
        value: formatNumberWithCommas(completedIssues),
        color: materialColors.green,
        searchUuid: completedSearchUuid,
      },
      {
        percentage: calcPercent(inProgressIssues, totalLinkedIssues),
        label: 'In Progress',
        value: formatNumberWithCommas(inProgressIssues),
        color: materialColors.blue,
        searchUuid: inProgressIssuesSearchUuid,
      },
      {
        percentage: calcPercent(openIssues, totalLinkedIssues),
        label: 'To do',
        value: formatNumberWithCommas(openIssues),
        color: materialColors.lightGray,
        searchUuid: todoIssuesSearchUuid,
      },
    ];
  }, [reportData]);

  return (
    <Wrapper>
      <Segment actionButtons={actionButtons}>
        <ProgressBarChartWrapper height={DEFAULT_WIDGET_HEIGHT}>
          <TextDeprecated color={theme.palette.text.primary} size="medium">
            Total{' '}
            <TextDeprecated color={theme.palette.text.primary} size="medium" variant="bold" inline>
              {totalIssues}
            </TextDeprecated>{' '}
            linked issues
          </TextDeprecated>
          <IssuesProgressBar progressValues={progressBarValues} onProgressBarClick={onProgressBarClick} />
        </ProgressBarChartWrapper>
        <TimelineLightbox
          onOpen={registerOpenTimelineCallback}
          groupedBy={{ key: null, title: '' }}
          groupBySelection={{ key: null, title: '' }}
          pageId={DASHBOARDS_PAGE}
          openItems={[]}
          entityGroup={{ key: null, title: '' }}
          displayLayer={displayLayer}
          lazyLoadProjects
        />
      </Segment>
    </Wrapper>
  );
}

const ProgressBarChartWrapper = styled(ChartWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default withTheme()(LinkedIssuesSummaryWidget);
