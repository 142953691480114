import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { uniq } from 'ramda';
import Tooltip from '@material-ui/core/Tooltip';

import SearchableDndList from 'design-system/molecules/SearchableDndList/index';
import DndAtoms from 'design-system/atoms/DndAtoms/index';

import theme from 'design-system/theme';

const { MenuText } = DndAtoms;

import formatDate from 'design-system/utils/formatDate';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@material-ui/core/Popover';
import EditIcon from '@material-ui/icons/Edit';

import { getUsers } from 'store/users/selectors';

import {
  selectRoadmapHistorySnapsForCurrentView,
  selectSelectedSnapshotsIdsToCompare,
} from 'features/RoadmapHistory/store/selectors';
import { selectViewSnapToCompare, removeSelectedSnapToCompare } from 'features/RoadmapHistory/store';
import { removeAllSelectedSnapToCompare } from 'features/RoadmapHistory/store/actions';

import UpdateRoadmapSnapDialog from 'features/RoadmapHistory/components/UpdateRoadmapSnapDialog/UpdateRoadmapSnapDialog';
import { openEditSnap } from 'features/RoadmapHistory/utils/editSnap';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import { addQueryParamToUrl } from 'utils/queryParamsUtils';
import { COMPARE_SNAPS_SELECTED_SNAPS_QUERY_PARAM, MAX_SNAPS_TO_COMPARE } from 'features/RoadmapHistory/constants';

const CUSTOM_EMPTY_MESSAGE = 'No roadmap snaps created for comparison.';

const renderCustomItem = item => {
  const name = `Title: ${item.name}`;
  const user = `Created by: ${item.user}`;
  const date = `Created: ${item.date}`;
  const tooltip = (
    <>
      {name}
      <br />
      {user}
      <br />
      {date}
    </>
  );

  return (
    <MenuText>
      <Tooltip title={tooltip}>
        <Typography variant="body2">{item.label}</Typography>
      </Tooltip>
    </MenuText>
  );
};

const renderCustomEmptyOptions = () => {
  return <EmptyMessage>{CUSTOM_EMPTY_MESSAGE}</EmptyMessage>;
};

const SelectRoadmapHistorySnapsPopover = ({ anchorEl, open, onClose }) => {
  const dispatch = useDispatch();

  const users = useSelector(state => getUsers(state));
  const selectedSnapsForCompare = useSelector(selectSelectedSnapshotsIdsToCompare);
  const viewSnaps = useSelector(selectRoadmapHistorySnapsForCurrentView);
  const selectedSnapsForCompareObject = selectedSnapsForCompare.reduce((acc, snapId) => ({ ...acc, [snapId]: true }), {});

  const { canUpdate } = usePermissions();
  const viewSnapsData = useMemo(
    () =>
      viewSnaps.map(item => {
        const user = users.find(user => user?.id === item?.user_id);
        const snapName = item?.name;
        const userName = user?.name;
        const date = formatDate(item?.created_at);
        const label = `${snapName}`;

        return {
          id: item.id,
          label,
          name: snapName,
          user: userName,
          date,
          color: item.color,
          disabled: !selectedSnapsForCompare?.includes(item.id) && selectedSnapsForCompare?.length >= MAX_SNAPS_TO_COMPARE,
        };
      }),
    [viewSnaps, selectedSnapsForCompare],
  );

  const updateQueryParamOnUnselect = snapId =>
    addQueryParamToUrl(
      COMPARE_SNAPS_SELECTED_SNAPS_QUERY_PARAM,
      selectedSnapsForCompare.filter(v => v !== snapId),
    );

  const handleSelectedRoadmapSnapshots = (selectedId, selected) => {
    if (selected) {
      dispatch(selectViewSnapToCompare(selectedId));

      addQueryParamToUrl(COMPARE_SNAPS_SELECTED_SNAPS_QUERY_PARAM, uniq([...selectedSnapsForCompare, selectedId]));

      return;
    }

    dispatch(removeSelectedSnapToCompare(selectedId));

    updateQueryParamOnUnselect(selectedId);
  };

  const handleClearSelectedSnaps = () => {
    dispatch(removeAllSelectedSnapToCompare());
  };

  const _itemRightActions = canUpdate(PERMISSION_RESOURCES.roadmapHistorySnapshot)
    ? [
        {
          renderIcon: () => <EditIcon />,
          onClick: params => {
            openEditSnap(params?.id);
            onClose();
          },
        },
      ]
    : undefined;

  return (
    <>
      <StyledPopover
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={onClose}>
            <div>
              <SearchableDndList
                items={viewSnapsData}
                selectedItems={selectedSnapsForCompareObject}
                onSelectItem={handleSelectedRoadmapSnapshots}
                handleClear={handleClearSelectedSnaps}
                renderCustomItem={renderCustomItem}
                renderCustomEmptyOptions={renderCustomEmptyOptions}
                itemRightActions={_itemRightActions}
                checkable
                showActionsOnHover
              />
            </div>
          </ClickAwayListener>
        </Paper>
      </StyledPopover>
      <UpdateRoadmapSnapDialog onDeleteCallback={updateQueryParamOnUnselect} />
    </>
  );
};

const StyledPopover = styled(Popover)`
  &&&& {
    > div {
      min-width: 400px;
      max-width: 450px;
      overflow: auto;
    }
  }
`;

const EmptyMessage = styled.div`
  margin: 30px;
  font-size: ${theme.typography.fontSize}px;
  font-style: italic;
  color: ${theme.palette.text.secondary};
  text-align: center;
`;

export default SelectRoadmapHistorySnapsPopover;
