import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';


const Actions = (props) => {
  const { onConfirm, onCancel } = props;

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="space-between"
    >
      <Button
        item
        color="secondary"
        onClick={onCancel}
      >
        Do Not Send
      </Button>
      <ButtonIcon
        item
        size="small"
        color="default"
        onClick={onConfirm}
      >
        <EmailOutlinedIcon />
      </ButtonIcon>
    </Grid>
  );
};

export default Actions;
