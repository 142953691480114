import { addListener } from '@reduxjs/toolkit';
import { includes } from 'ramda';

import { FETCH_METRICS_FULFILLED, UPDATE_METRIC_BY_ID_FULFILLED } from 'store/metrics/types';
import { refreshPortfolioOverviewMetrics } from './thunks';
import { selectPortfolioOverviewForActiveRoadmap } from './selectors';

/**
 * Subscribes to some actions to refresh metrics data on portfolio overview metrics
 *
 * @returns {Object} the addListener action creator
 */
export const subscribeToRefreshPortfolioOverviewMetricsChanged = () => {
  return addListener({
    matcher: action => includes(action.type, [FETCH_METRICS_FULFILLED, UPDATE_METRIC_BY_ID_FULFILLED]),
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners();

      const { dispatch, getState } = listenerApi;

      const portfolioOverview = selectPortfolioOverviewForActiveRoadmap(getState());
      const { roadmap_id: roadmapId, roadmap_type: roadmapType } = portfolioOverview || {};

      dispatch(refreshPortfolioOverviewMetrics(roadmapId, roadmapType));
    },
  });
};
