import React, { useMemo } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import queryString from 'query-string';

import validateURL from 'utils/validateURL';

export default ({
  error,
  handleSearch,
  importUrl,
  parsedImportUrl,
  totalSalesforceCustomers,
  updateImportUrl,
  updateParsedImportUrl,
}) => {
  const handleUrlChange = e => {
    e.preventDefault();

    const { value } = e.target;

    updateImportUrl(value);
    if (validateURL(value)) {
      const { query } = queryString.parseUrl(value);

      updateParsedImportUrl(query.filterName);
    } else {
      updateParsedImportUrl(value);
    }
  };

  const informationText = useMemo(() => {
    if (parseInt(totalSalesforceCustomers)) {
      return `${totalSalesforceCustomers} Accounts will be imported.`;
    }

    return totalSalesforceCustomers;
  }, [totalSalesforceCustomers]);

  return (
    <div>
      <InstructionText>
        Create a list view / filter on your Salesforce instance, then <strong>paste the url or id</strong> below
      </InstructionText>
      {/* TODO: add zendesk article/tooltip  -- <Tooltip enterDelay={200} title="Salesforce integration overview">
            <TextDeprecated size="medium">
              <HelpLink
                // href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <InlineIcon>
                  <HelpIcon />
                </InlineIcon>
              </HelpLink>
            </TextDeprecated>
          </Tooltip> */}
      <Grid container>
        <Grid item xs={12}>
          <InputContainer hasError={!!error}>
            <QueryInput
              value={importUrl}
              placeholder="Paste your Salesforce list view / query url or id here"
              onChange={handleUrlChange}
            />
            <Button onClick={handleSearch} disabled={!parsedImportUrl}>
              Search
            </Button>
          </InputContainer>
        </Grid>
        <Grid item xs={12}>
          {!!totalSalesforceCustomers && <TextContainer>{informationText}</TextContainer>}
        </Grid>
      </Grid>
    </div>
  );
};

const TextContainer = styled.div`
  padding: 30px 0;
  text-align: center;
`;

const InputContainer = styled.div`
  margin: 30px 13%;
  text-align: right;
  width: 550px;
  max-width: 100%;
  border: 1px solid;
  border-color: ${props => (props.hasError ? props.theme.palette.text.error : `rgba(0, 0, 0, 0.23)`)};
  display: flex;
  justify-content: center;

  button {
    background-color: rgba(0, 0, 0, 0.08);
    text-transform: initial;
    border-radius: 0;
    font-weight: 400;
    height: 42px;
    width: 90px;
  }
`;

const QueryInput = styled(Input)`
  &&&& {
    width: calc(100% - 42px);

    input {
      width: 100%;
      height: 100%;
      padding: 10px;
      color: #4689f0;
      font-size: 14px;
    }
  }
`;

const InstructionText = styled.div`
  text-align: center;
`;
