import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { DEFAULT_COLOR_BY_OPTION, DEFAULT_GROUPLANE_TYPE } from 'constants/roadmap';

import { getState, updateState as updateStateAction } from 'store/timelines';
import { getGroupOptionsSelector } from 'store/projects/groupSelectors';
import { DEFAULT_GROUP_OPTION, getGroupOptionByKey } from 'store/projects/helpers/groupOptions';

import {
  DEFAULT_SIDEBAR_WIDTH,
  DEFAULT_VIEW_MODE,
  DEFAULT_ZOOM_OPTION,
  SLOT_WIDTH,
  ZOOM_OPTIONS,
} from 'design-system/organisms/Timeline/New/helpers/constants';

import useSystemFields from 'hooks/useSystemFields';
import useOrganizations from 'hooks/useOrganizations';

const mapFields = {
  key_result_1: 'keyResult1',
  key_result_2: 'keyResult2',
  product_1: 'product1',
  product_2: 'product2',
};

const DEFAULT_GROUP_TYPE = 'objective';
const CUSTOM_FIELD_PREFIX = 'custom_fields.';
const MILESTONE_TOP_LANE = 'topLane';

const includesOption = (groupByOptions, optionToCheck) => groupByOptions.some(option => option.key === optionToCheck.key);

const isCustomField = type => type && type?.startsWith(CUSTOM_FIELD_PREFIX);

const getGroupTitle = (groupType, getSystemFieldName) =>
  isCustomField(groupType?.key) ? groupType.title : groupType && getSystemFieldName(mapFields[groupType.key] || groupType.key);

const defaultOptions = {
  defaultGroupType: DEFAULT_GROUP_TYPE,
  defaultSwimlaneGroupByOption: DEFAULT_GROUP_OPTION,
  mapSwimlaneGroupByOptions: groupByOptions => groupByOptions,
};
/**
 * Custom hook responsible to abstract the access and manipulation of the Timeline configuration data in the store,
 * for a given key (may be a page key or something else).
 *
 * This hook should be reused in all the pages that are going to include the Timeline component.
 *
 * It offers a centralized point to all the Timeline configuration.
 * */
const useTimelines = (
  key,
  {
    defaultGroupType = defaultOptions.defaultGroupType,
    defaultSwimlaneGroupByOption = defaultOptions.defaultSwimlaneGroupByOption,
    mapSwimlaneGroupByOptions = defaultOptions.mapSwimlaneGroupByOptions,
  } = defaultOptions,
) => {
  const dispatch = useDispatch();

  const [getSystemFieldName] = useSystemFields();

  const { systemFields } = useOrganizations();

  const lsState = useSelector(getState(key));

  const { id: zoomMode } = lsState?.selectedZoom ?? DEFAULT_VIEW_MODE;

  // Grouping configuration

  const groupingOptions = useMemo(() => ({ portfolioMode: false, withCustomers: true }), []);
  const groupByOptions = useSelector(state => getGroupOptionsSelector(state, groupingOptions));
  const swimlaneGroupByOptions = useMemo(() => mapSwimlaneGroupByOptions(groupByOptions), [groupByOptions]);

  const groupType = lsState?.grouplaneType ? lsState.grouplaneType.key : defaultGroupType;
  const dataType = lsState?.swimlaneType ? lsState.swimlaneType.key : defaultSwimlaneGroupByOption.key;
  const thirdGroupKey = lsState?.thirdGroupType?.key ?? null;

  const selectedGrouplaneType = getGroupOptionByKey(
    lsState?.grouplaneType?.key ? lsState?.grouplaneType.key : defaultGroupType,
    groupByOptions,
  );
  const selectedSwimlaneType = getGroupOptionByKey(
    lsState?.swimlaneType?.key ? lsState?.swimlaneType.key : null,
    swimlaneGroupByOptions,
    defaultSwimlaneGroupByOption,
  );

  const selectedThirdGroupType = getGroupOptionByKey(thirdGroupKey, groupByOptions);

  const groupTypeTitle = getGroupTitle(selectedGrouplaneType, getSystemFieldName);
  const dataTypeTitle = getGroupTitle(selectedSwimlaneType, getSystemFieldName);
  const thirdGroupTitle = getGroupTitle(selectedThirdGroupType, getSystemFieldName);

  const hasTopMilestoneLane = !!(lsState?.displayMilestone && lsState?.displayMilestoneOn === MILESTONE_TOP_LANE);

  // Actions to update state

  const updateState = options => {
    dispatch(updateStateAction(key, options));
  };

  const resizeGroupHeader = useCallback(
    index => (_, width) => {
      if (index === 0) {
        return updateState({
          groupbarWidth: +width,
        });
      }

      if (index === 1) {
        return updateState({ sidebarWidth: +width });
      }
    },
    [],
  );

  const changeSelectedZoom = useCallback(() => {
    if (lsState.selectedZoom.id === 'week') {
      updateState({ selectedZoom: ZOOM_OPTIONS.find(g => g.id === 'month') });
    } else if (lsState.selectedZoom.id === 'month') {
      updateState({ selectedZoom: ZOOM_OPTIONS.find(g => g.id === 'quarter') });
    } else if (lsState.selectedZoom.id === 'day') {
      updateState({ selectedZoom: ZOOM_OPTIONS.find(g => g.id === 'week') });
    } else if (lsState.selectedZoom.id === 'quarter') {
      updateState({ selectedZoom: ZOOM_OPTIONS.find(g => g.id === 'day') });
    }
  }, [lsState]);

  const updateSearchString = searchString => updateState({ searchString });

  const changeSelectedGroupOption = useCallback(
    index => option => {
      const isEqualToSecondGroup = option.key === lsState?.swimlaneType?.key;
      const isEqualToThirdGroup = option.key === lsState?.thirdGroupType?.key;

      if (index === 0) {
        return updateState({
          grouplaneType: option,
          swimlaneType: isEqualToSecondGroup ? defaultSwimlaneGroupByOption : lsState?.swimlaneType,
          thirdGroupType: isEqualToSecondGroup || isEqualToThirdGroup ? DEFAULT_GROUP_OPTION : lsState?.thirdGroupType,
          sidebarWidth: isEqualToSecondGroup ? 0 : lsState.sidebarWidth,
          thirdGroupWidth: isEqualToSecondGroup || isEqualToThirdGroup ? 0 : lsState.thirdGroupWidth,
        });
      }

      if (index === 1) {
        return updateState({
          swimlaneType: option,
          sidebarWidth: !option.key ? 0 : lsState.sidebarWidth || DEFAULT_SIDEBAR_WIDTH,
          thirdGroupType: !option.key || isEqualToThirdGroup ? DEFAULT_GROUP_OPTION : lsState?.thirdGroupType,
          thirdGroupWidth: !option.key || isEqualToThirdGroup ? 0 : lsState.thirdGroupWidth,
        });
      }

      updateState({
        thirdGroupType: option,
        thirdGroupWidth: 0,
      });
    },
    [lsState?.swimlaneType, lsState?.thirdGroupType],
  );

  const toggleShowItemsWithoutTimeline = useCallback(
    () => updateState({ showItemsWithoutTimeline: !lsState?.showItemsWithoutTimeline }),
    [lsState?.showItemsWithoutTimeline],
  );

  const confirmPlanningStageWarning = useCallback(() => updateState({ isDialogVisible: false }), []);

  const toggleShowTextOverflow = useCallback(
    () => updateState({ showTextOverflow: !lsState?.showTextOverflow }),
    [lsState?.showTextOverflow],
  );

  const changeSlotWidth = (_, { id }) => updateState({ slotWidth: id });

  const changeSelectedSnapToGridOn = useCallback(option => updateState({ snapToGridOn: option }), []);

  // Set the defaults

  useEffect(() => {
    const defaultState = {};

    if (!lsState.grouplaneType) defaultState.grouplaneType = DEFAULT_GROUPLANE_TYPE(systemFields, defaultGroupType);

    if (
      !selectedGrouplaneType ||
      (selectedGrouplaneType?.key && !includesOption(groupByOptions, selectedGrouplaneType)) ||
      (selectedSwimlaneType?.key && !includesOption(swimlaneGroupByOptions, selectedSwimlaneType))
    ) {
      defaultState.grouplaneType = DEFAULT_GROUPLANE_TYPE(systemFields, defaultGroupType);
      defaultState.swimlaneType = defaultSwimlaneGroupByOption;
      defaultState.thirdGroupType = DEFAULT_GROUP_OPTION;
    }

    if (
      selectedSwimlaneType?.key === selectedGrouplaneType?.key &&
      lsState?.swimlaneType?.key !== defaultSwimlaneGroupByOption?.key
    ) {
      defaultState.swimlaneType = defaultSwimlaneGroupByOption;
      defaultState.thirdGroupType = DEFAULT_GROUP_OPTION;
    }

    if (!lsState.groupbarWidth) defaultState.groupbarWidth = DEFAULT_SIDEBAR_WIDTH;

    if (!lsState.sidebarWidth && selectedSwimlaneType?.key) {
      defaultState.sidebarWidth = DEFAULT_SIDEBAR_WIDTH;
    } else if (!lsState.sidebarWidth || !selectedSwimlaneType?.key) defaultState.sidebarWidth = 0;
    if (!lsState.thirdGroupWidth || !selectedThirdGroupType?.key) defaultState.thirdGroupWidth = 0;

    if (!lsState.slotWidth) defaultState.slotWidth = SLOT_WIDTH;
    if (!lsState.selectedColorBy) defaultState.selectedColorBy = DEFAULT_COLOR_BY_OPTION(systemFields);
    if (!lsState.selectedZoom) defaultState.selectedZoom = DEFAULT_ZOOM_OPTION;
    if (lsState.showTooltip === undefined) defaultState.showTooltip = true;
    if (lsState.isDialogVisible === undefined) defaultState.isDialogVisible = false;
    if (lsState.showItemsWithoutTimeline === undefined) defaultState.showItemsWithoutTimeline = false;

    if (!isEmpty(defaultState)) {
      updateState(defaultState);
    }
  }, []);

  const selectedGroupOptions = [
    selectedGrouplaneType ?? DEFAULT_GROUP_OPTION,
    selectedSwimlaneType ?? defaultSwimlaneGroupByOption,
    selectedThirdGroupType ?? DEFAULT_GROUP_OPTION,
  ];

  const hasMultiGrouping = selectedGroupOptions.filter(groupOption => !isNil(groupOption.key)).length > 1;

  return {
    lsState: { ...(lsState || {}) },
    zoomMode,
    groupType, // key for group level 1
    dataType, // key for group level 2
    thirdGroupKey, // key for group level 3
    selectedGrouplaneType,
    selectedSwimlaneType,
    selectedThirdGroupType,
    selectedGroupOptions,
    hasMultiGrouping,
    groupWidths: [lsState.groupbarWidth, lsState.sidebarWidth, lsState.thirdGroupWidth],
    groupTitles: [groupTypeTitle, dataTypeTitle, thirdGroupTitle],
    groupByOptions,
    swimlaneGroupByOptions,
    hasTopMilestoneLane,
    updateState,
    resizeGroupHeader,
    changeSelectedZoom,
    updateSearchString,
    changeSelectedGroupOption,
    toggleShowItemsWithoutTimeline,
    toggleShowTextOverflow,
    changeSlotWidth,
    confirmPlanningStageWarning,
    changeSelectedSnapToGridOn,
  };
};

export default useTimelines;
