import axios from 'axios';

import { createThunk } from 'utils/store/thunk';

import { FETCH_ADO_PROJECTS, FETCH_ADO_PROJECTS_RESET } from '../types';

const fetchADOProjects = orgIntegrationId =>
  createThunk(
    FETCH_ADO_PROJECTS,
    () => axios.get(`/api/integrations/azuredevops/${orgIntegrationId}/projects`),
    {
      orgIntegrationId,
    },
    { includeRetryAction: true },
  );

const resetADOProjects = () => ({ type: FETCH_ADO_PROJECTS_RESET });

export { fetchADOProjects, resetADOProjects };
