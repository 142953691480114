import { useMemo } from 'react';
import { pluck } from 'ramda';

import {
  ChipsCellRenderer,
  GroupCellRenderer,
  TextCellRenderer,
  TextLinkCellRenderer,
  WYSIWYGCellRenderer,
  RequestIntegrationKeyCellRenderer,
} from 'design-system/molecules/AgGridReact-New/cellRenderers';
import {
  RemirrorWYSIWYGCellEditor,
  MultiSelectCellEditor,
  DropdownCellEditor,
} from 'design-system/molecules/AgGridReact-New/cellEditors';
import { dateColumnDef, textCenterAlignedColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import { multiSelectComparator, stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { returnsTrueIfKeyIsNotEscOrTab } from 'design-system/molecules/AgGridReact-New/helpers';
import AgGridAutocomplete from 'components/AgGridAutocomplete/new';
import LoadableUsersAutocomplete from 'containers/LoadableUsersAutocomplete';
import { getUserName } from 'utils';
import { useSystemFields } from 'containers/IdeasList/IdeasList/New/hooks';
import { PRODUCT_1_LEVEL, PRODUCT_2_LEVEL } from 'constants/roadmaps';
import { getProductColumnDef } from 'utils/grids/columns';
import statusMap from 'store/customerRequests/constants/status';
import priorityMap from 'store/customerRequests/constants/priority';
import useNumberOfTextRowsToDisplayOnGridCell from 'hooks/useNumberOfTextRowsToDisplayOnGridCell';
import { LIFECYCLES, PERSONAS } from 'constants/common';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import {
  defaultExport,
  exportDate,
  exportIntegrationExternalId,
  exportMultiLineText,
  exportMultiSelectMetadata,
} from 'features/GridExport/helpers/exportUtils';

const CUSTOMERS_KEY = 'customer';
const TAGS_KEY = 'tag';
const getIds = pluck('id');

const dateCommonDefs = { ...dateColumnDef, exportFn: exportDate };

/**
 * @function useCustomerRequestsGeneralFieldsColumnDefs
 *
 * Uses general fiels column definitions
 *
 * @return {Array}
 */
const useCustomerRequestsGeneralFieldsColumnDefs = ({
  rowHeight,

  onOpenCustomerRequestInfo,
  createMultiSelectOption,
  updateCustomerRequestById,
  integrations,
}) => {
  const { getSystemFieldName } = useSystemFields();
  const { getNumberOfTextRowsToDisplayOnGridCell } = useNumberOfTextRowsToDisplayOnGridCell(rowHeight);
  const { canView } = usePermissions();

  const hasPersonas = canView(PERMISSION_FEATURES.personas);
  const hasLifecycles = canView(PERMISSION_FEATURES.lifecycles);
  const hasProducts2 = canView(PERMISSION_RESOURCES.product2);
  const hasExternalKeys = canView(PERMISSION_FEATURES.importRequestsWithExternalKey);

  const multilineTextCommonDefs = useMemo(
    () => ({
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      cellRenderer: WYSIWYGCellRenderer,
      cellEditor: RemirrorWYSIWYGCellEditor,
      cellEditorParams: {
        maxHeight: 150,
        autoFocus: true,
      },
      width: 450,
      minWidth: 200,
      comparator: stringComparator,
      cellEditorPopup: true,
      exportFn: exportMultiLineText,
    }),
    [],
  );

  const idColumnDef = useMemo(() => {
    return {
      field: 'key',
      headerName: 'ID',
      cellClass: onOpenCustomerRequestInfo && 'cell-link',
      editable: false,
      cellRenderer: onOpenCustomerRequestInfo && TextLinkCellRenderer,
      cellRendererParams: {
        handleClick: onOpenCustomerRequestInfo,
      },
      width: 60,
      exportFn: defaultExport,
    };
  }, [onOpenCustomerRequestInfo]);

  const statusColumnDef = useMemo(
    () => ({
      cellRenderer: GroupCellRenderer('Status'),
      cellEditor: DropdownCellEditor,
      enableRowGroup: true,
      openByDefault: true,
      field: 'status',
      headerName: 'Status',
      cellEditorParams: params => ({
        options: params.context.metadata.statuses,
        sort: false,
      }),
      cellRendererParams: params => ({
        entity: statusMap[params.data?.status],
      }),
      valueGetter: params => (statusMap[params.data?.status] || {}).label,
      width: 150,
      exportFn: defaultExport,
    }),
    [],
  );

  const priorityColumnDef = useMemo(
    () => ({
      cellRenderer: GroupCellRenderer(getSystemFieldName('priority')),
      cellEditor: DropdownCellEditor,
      enableRowGroup: true,
      openByDefault: true,
      field: 'priority',
      headerName: getSystemFieldName('priority'),
      cellEditorParams: params => ({
        options: params.context.metadata.priorities,
        sort: false,
      }),
      cellRendererParams: params => ({
        entity: priorityMap[params.data?.priority],
      }),
      valueGetter: params => (priorityMap[params.data?.priority] || {}).label,
      width: 150,
      exportFn: defaultExport,
    }),
    [getSystemFieldName],
  );

  const detailsColumnDef = useMemo(() => {
    return {
      ...multilineTextCommonDefs,
      headerName: 'Details',
      field: 'details',
      width: 300,
      cellClass: 'align-items-top',
    };
  }, [multilineTextCommonDefs]);

  const linksColumnDef = useMemo(() => {
    return {
      ...multilineTextCommonDefs,
      headerName: 'Links',
      field: 'links',
      width: 400,
      cellClass: 'align-items-top',
    };
  }, [multilineTextCommonDefs]);

  const ownerColumnDef = useMemo(() => {
    return {
      field: 'owner_name',
      headerName: 'Owner',
      cellRenderer: TextCellRenderer,
      cellEditor: AgGridAutocomplete,
      cellEditorParams: {
        autoFocus: true,
        autocompleteComponent: LoadableUsersAutocomplete,
        dataMapper: u => ({
          value: getUserName(u),
          label: getUserName(u),
        }),
      },
      cellEditorPopup: true,
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      width: 120,
      exportFn: defaultExport,
    };
  }, []);

  const createdByColumnDef = useMemo(
    () => ({
      editable: false,
      width: 100,
      field: 'created_by_name',
      headerName: 'Created by',
      cellClass: 'text-center',
      cellRenderer: TextCellRenderer,
      exportFn: defaultExport,
    }),
    [],
  );

  const createdAtColumnDef = useMemo(
    () => ({
      ...dateCommonDefs,
      editable: false,
      width: 100,
      field: 'created_at',
      headerName: 'Created At',
    }),
    [],
  );

  const roadmapColumnDef = useMemo(() => {
    return {
      field: 'roadmap_title',
      headerName: getSystemFieldName('roadmap'),
      cellRenderer: GroupCellRenderer(getSystemFieldName('roadmap')),
      cellEditor: AgGridAutocomplete,
      cellEditorPopup: true,
      comparator: stringComparator,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      enableRowGroup: true,
      width: 120,
      cellRendererParams: params => {
        return {
          entity: params.context.metadata.roadmaps.find(p => p.title === params.value),
        };
      },
      cellEditorParams: params => {
        return {
          options: params.context.filteredMetadata.roadmaps.map(o => o.title),
          autoFocus: true,
        };
      },
      exportFn: defaultExport,
    };
  }, [getSystemFieldName]);

  const product1ColumnDef = useMemo(() => {
    return getProductColumnDef({
      field: 'product1_title',
      headerName: getSystemFieldName('product1'),
      selectedLevel: PRODUCT_1_LEVEL,
      enableRowGroup: true,

      getFilteredRoadmaps: params => params.context.filteredMetadata.roadmaps,

      update: updateCustomerRequestById,
      cellRenderer: GroupCellRenderer(getSystemFieldName('product1')),
      cellRendererParams: params => {
        return {
          entity: params.context.metadata.products.find(p1 => p1.title === params.value),
        };
      },
      exportFn: defaultExport,
    });
  }, [getSystemFieldName]);

  const product2ColumnDef = useMemo(() => {
    if (!hasProducts2) return [];
    return [
      getProductColumnDef({
        field: 'product2_title',
        headerName: getSystemFieldName('product2'),
        selectedLevel: PRODUCT_2_LEVEL,

        getFilteredRoadmaps: params => params.context.filteredMetadata.roadmaps,

        update: updateCustomerRequestById,
        cellRenderer: GroupCellRenderer(getSystemFieldName('product2')),
        cellRendererParams: params => {
          return {
            entity: params.context.metadata.products2.find(p2 => p2.title === params.value),
          };
        },
        exportFn: defaultExport,
      }),
    ];
  }, [getSystemFieldName, hasProducts2]);

  const customersColumnDef = useMemo(
    () => ({
      field: 'customers',
      headerName: getSystemFieldName(CUSTOMERS_KEY),
      width: 120,
      labelField: 'name',
      keyCreator: ({ value }) => (value?.length ? value.map(c => c.name) : [null]).join('-'),
      cellRenderer: ChipsCellRenderer,
      cellEditor: MultiSelectCellEditor,
      cellEditorPopup: true,
      showTooltip: true,
      cellEditorParams: params => ({
        options: params.context.filteredMetadata.customers,
        optionsMapper: { label: 'name', value: 'id' },
        createOption: createMultiSelectOption(params),
      }),
      cellRendererParams: {
        getNumberOfTextRowsToDisplayOnGridCell,
      },
      comparator: multiSelectComparator,
      exportFn: params => exportMultiSelectMetadata(params, 'name'),
    }),
    [getSystemFieldName, createMultiSelectOption, getNumberOfTextRowsToDisplayOnGridCell],
  );

  const tagsColumnDef = useMemo(
    () => ({
      field: 'tags',
      headerName: getSystemFieldName(TAGS_KEY),
      width: 120,
      labelField: 'title',
      cellRenderer: ChipsCellRenderer,
      cellEditor: MultiSelectCellEditor,
      cellEditorPopup: true,
      cellEditorParams: params => ({
        options: params.context.filteredMetadata.tags,
        createOption: createMultiSelectOption(params),
      }),
      cellRendererParams: {
        getNumberOfTextRowsToDisplayOnGridCell,
      },
      comparator: multiSelectComparator,
      exportFn: exportMultiSelectMetadata,
    }),
    [getSystemFieldName, createMultiSelectOption],
  );

  const personasColumnDef = useMemo(() => {
    if (!hasPersonas) return [];
    return [
      {
        field: PERSONAS,
        headerName: `${getSystemFieldName('persona', true)}`,
        labelField: 'title',
        cellRenderer: ChipsCellRenderer,
        cellRendererParams: {
          getNumberOfTextRowsToDisplayOnGridCell,
        },
        cellEditor: MultiSelectCellEditor,
        cellEditorPopup: true,
        cellEditorParams: params => ({
          options: params.context.filteredMetadata.filteredPersonas,
          optionsMapper: { label: 'title', value: 'id' },
          hideCreateOption: true,
        }),
        valueGetter: ({ context, data: { personas = [] } = {} } = {}) => {
          const requestPersonasIds = getIds(personas);

          return context.metadata.personas.filter(({ id }) => requestPersonasIds.includes(id));
        },
        showTooltip: true,
        chipVariant: 'outlined',
        exportFn: exportMultiSelectMetadata,
      },
    ];
  }, [getSystemFieldName, hasPersonas, getNumberOfTextRowsToDisplayOnGridCell]);

  const lifecyclesColumnDef = useMemo(() => {
    if (!hasLifecycles) return [];
    return [
      {
        field: LIFECYCLES,
        headerName: `${getSystemFieldName('lifecycle', true)}`,
        labelField: 'title',
        cellRenderer: ChipsCellRenderer,
        cellRendererParams: {
          getNumberOfTextRowsToDisplayOnGridCell,
        },
        cellEditor: MultiSelectCellEditor,
        cellEditorPopup: true,
        cellEditorParams: params => ({
          options: params.context.filteredMetadata.filteredLifecycles,
          optionsMapper: { label: 'title', value: 'id' },
          hideCreateOption: true,
        }),
        valueGetter: ({ context, data: { lifecycles = [] } = {} } = {}) => {
          const requestLifecyclesIds = getIds(lifecycles);

          return context.metadata.lifecycles.filter(({ id }) => requestLifecyclesIds.includes(id));
        },
        showTooltip: true,
        chipVariant: 'outlined',
        exportFn: exportMultiSelectMetadata,
      },
    ];
  }, [getSystemFieldName, hasLifecycles, getNumberOfTextRowsToDisplayOnGridCell]);

  const integrationKeyColumnDefs = useMemo(() => {
    if (!integrations.length) return [];

    return integrations.filter(Boolean).map(() => ({
      width: 150,
      headerName: 'Integration Key',
      field: 'integrations',
      editable: false,
      filterParams: {
        values: ['(Blanks)', 'Has Integration'],
      },
      orgIntegrations: integrations,
      keyCreator: ({ value }) => (value ? 'Has Integration' : '(Blanks)'),
      cellRenderer: RequestIntegrationKeyCellRenderer,
      exportFn: exportIntegrationExternalId,
    }));
  }, [integrations]);

  const externalKeyColumnDef = useMemo(
    () =>
      hasExternalKeys && {
        ...textCenterAlignedColumnDef,
        width: 150,
        headerName: 'External Key',
        field: 'external_key',
        editable: false,
      },
    [hasExternalKeys],
  );

  const externalPortfolioKeyColumnDef = useMemo(
    () =>
      hasExternalKeys && {
        ...textCenterAlignedColumnDef,
        width: 150,
        headerName: 'External Portfolio Key',
        field: 'external_portfolio_key',
        editable: false,
      },
    [hasExternalKeys],
  );

  return useMemo(
    () => [
      idColumnDef,
      statusColumnDef,
      priorityColumnDef,
      detailsColumnDef,
      linksColumnDef,
      ownerColumnDef,
      createdByColumnDef,
      roadmapColumnDef,
      product1ColumnDef,
      ...product2ColumnDef,
      ...personasColumnDef,
      ...lifecyclesColumnDef,
      customersColumnDef,
      tagsColumnDef,
      createdAtColumnDef,
      ...integrationKeyColumnDefs,
      externalKeyColumnDef,
      externalPortfolioKeyColumnDef,
    ],
    [
      idColumnDef,
      detailsColumnDef,
      linksColumnDef,
      ownerColumnDef,
      createdByColumnDef,
      roadmapColumnDef,
      product1ColumnDef,
      hasProducts2,
      product2ColumnDef,
      customersColumnDef,
      tagsColumnDef,
      statusColumnDef,
      priorityColumnDef,
      personasColumnDef,
      lifecyclesColumnDef,
      createdAtColumnDef,
      integrationKeyColumnDefs,
      externalKeyColumnDef,
      externalPortfolioKeyColumnDef,
    ],
  );
};

export default useCustomerRequestsGeneralFieldsColumnDefs;
