import React, { useCallback, useMemo } from 'react';

import DragonNavItem from 'design-system/atoms/DragonNavItem/index';

import useUserFavoriteViews from 'hooks/userViews/useUserFavoriteViews';
import useLeftNavigationExpandedItems from 'hooks/app/useLeftNavigationExpandedItems';

const NavBarItem = props => {
  const { path } = props;
  const { userViews } = useUserFavoriteViews();
  const { isItemExpanded, handleToggleExpandLefNavItem } = useLeftNavigationExpandedItems();

  const isExpanded = useMemo(() => isItemExpanded(path), [path, isItemExpanded]);
  const expandable = useMemo(() => userViews.length > 0, [userViews.length]);

  const onClickExpandableArrow = useCallback(() => {
    handleToggleExpandLefNavItem(path);
  }, [path, handleToggleExpandLefNavItem]);

  return (
    <DragonNavItem {...props} expandable={expandable} isExpanded={isExpanded} onClickExpandableArrow={onClickExpandableArrow} />
  );
};

export default NavBarItem;
