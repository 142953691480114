import { isNil } from 'ramda';

import { UPDATE_TIMELINE_STATE } from './types';

const initialState = {};

const timelinesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TIMELINE_STATE:
      const { payload } = action;

      const { key, options = {} } = payload;

      if (isNil(key)) {
        return state;
      }

      return {
        ...state,
        [key]: { ...(state[key] ?? {}), ...options },
      };
    default:
      return state;
  }
};

export default timelinesReducer;
