import styled from 'styled-components';
import { textColor } from 'design-system/themes/default';

export default styled.div`
  color: ${textColor};
  font-size: 14px;
  flex-direction: column;

  ul {
    list-style: disc;
    padding-left: 30px;
    line-height: 22px;
  }
  ol {
    list-style: decimal;
    padding-left: 30px;
    line-height: 22px;
  }
  h1 {
    font-size: 40px;
  }
  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 26px;
  }
  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
  }
  blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  blockquote p {
    display: inline;
  }
  pre code {
    background-color: #eee;
    border: 1px solid #999;
    display: block;
    padding: 20px;
  }
  a {
    color: #1291bd;
    text-decoration: underline;
    cursor: pointer;
  }
  strong {
    font-weight: bold;
  }

  &&&& {
    p,
    span {
      line-height: ${({ theme }) => theme.typography.lineHeightRegularSmallRem}rem;
    }
  }
`;
