import { useMemo } from 'react';

import useInitAgGrid from 'design-system/molecules/AgGridReact-New/hooks/useInitAgGrid';

import takeGridSnapshot from '../helpers/takeGridSnapshot';

const methodsToDecorate = {
  takeGridSnapshot,
};

const useAgGrid = () => {
  const { isGridReady, getGridApi, getGridColumnApi, initAgGrid, methods } = useInitAgGrid(methodsToDecorate);

  // TODO: add other grid props, grid options, methods, etc. here.
  // TODO: this hook could be the generic hook for the lowest levels of the AgGrid's
  //   wrappers (e.g., DataGrid, AgGridContainer).

  return useMemo(
    () => ({
      isGridReady,
      getGridApi,
      getGridColumnApi,

      initAgGrid,

      methods,
    }),
    [methods],
  );
};

export default useAgGrid;
