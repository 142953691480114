import axios from 'axios';
import { equals } from 'ramda';
import { createThunk } from 'utils/store/thunk';

const FUNCTION = 'function';
const isFunction = equals(FUNCTION);

import {
  // Project => Task Dependencies:
  ADD_PROJECT_TASK_DEPENDENCY,
  REMOVE_PROJECT_TASK_DEPENDENCY,

  // Project => Estimate Dependencies:
  ADD_PROJECT_ESTIMATE_DEPENDENCY,
  REMOVE_PROJECT_ESTIMATE_DEPENDENCY,

  // Project => Project Dependencies:
  ADD_PROJECT_PROJECT_DEPENDENCY,
  REMOVE_PROJECT_PROJECT_DEPENDENCY,

  // Estimate => Estimate Dependencies:
  ADD_ESTIMATE_ESTIMATE_DEPENDENCY,
  REMOVE_ESTIMATE_ESTIMATE_DEPENDENCY,

  // Estimate => Project Dependencies:
  ADD_ESTIMATE_PROJECT_DEPENDENCY,
  REMOVE_ESTIMATE_PROJECT_DEPENDENCY,

  // Task => Task Dependencies:
  ADD_TASK_TASK_DEPENDENCY,
  REMOVE_TASK_TASK_DEPENDENCY,

  // Task => Project Dependencies:
  ADD_TASK_PROJECT_DEPENDENCY,
  REMOVE_TASK_PROJECT_DEPENDENCY,

  // Other:
  FETCH_PROJECT_DEPENDENCIES,
  SET_PROJECTS_DEPENDENCIES,
  SYNC_INTEGRATION_PROJECT_DEPENDENCIES,
} from './types';

const syncProjectDependenciesToIntegration = projectId => {
  return createThunk(
    SYNC_INTEGRATION_PROJECT_DEPENDENCIES,
    axios.post(`/api/projects/${projectId}/integrations/update/dependencies`),
    {
      projectId,
    },
  );
};

// Abstract Action Creators:
const addDependencyActionFactory = (actionType, getUrl, mapToPayload, callback) => {
  return (targetId, dependencyId, type) => {
    return createThunk(
      actionType,
      axios.post(getUrl(targetId, dependencyId), { type }).then(response => {
        if (isFunction(typeof callback)) {
          callback(targetId);
          callback(dependencyId);
        }

        return {
          dependency: response.data,
          ...mapToPayload(targetId, dependencyId),
          response,
        };
      }),
    );
  };
};

const removeDependencyActionFactory = (actionType, getUrl, mapToPayload, callback) => {
  return (targetId, dependencyId) => {
    return createThunk(
      actionType,
      axios.delete(getUrl(targetId, dependencyId)).then(() => {
        if (isFunction(typeof callback)) {
          callback(targetId);
          callback(dependencyId);
        }
        return mapToPayload(targetId, dependencyId);
      }),
    );
  };
};

// Action Payload Mappers:

const projectDependencyActionMapper = (projectId, dependencyId) => ({ projectId, dependencyId });

const estimateDependencyActionMapper = (estimateId, dependencyId) => ({ estimateId, dependencyId });

const taskDependencyActionMapper = (taskId, dependencyId) => ({ taskId, dependencyId });

// Project => Task Dependencies:

const addProjectTaskDependency = addDependencyActionFactory(
  ADD_PROJECT_TASK_DEPENDENCY,
  (pId, dId) => `/api/projects/${pId}/tasks-dependencies/${dId}`,
  projectDependencyActionMapper,
);

const removeProjectTaskDependency = removeDependencyActionFactory(
  REMOVE_PROJECT_TASK_DEPENDENCY,
  (pId, dId) => `/api/projects/${pId}/tasks-dependencies/${dId}`,
  projectDependencyActionMapper,
);

// Project => Estimate Dependencies:

const addProjectEstimateDependency = addDependencyActionFactory(
  ADD_PROJECT_ESTIMATE_DEPENDENCY,
  (pId, dId) => `/api/projects/${pId}/estimates-dependencies/${dId}`,
  projectDependencyActionMapper,
);

const removeProjectEstimateDependency = removeDependencyActionFactory(
  REMOVE_PROJECT_ESTIMATE_DEPENDENCY,
  (pId, dId) => `/api/projects/${pId}/estimates-dependencies/${dId}`,
  projectDependencyActionMapper,
);

// Project => Project Dependencies:

const addProjectProjectDependency = addDependencyActionFactory(
  ADD_PROJECT_PROJECT_DEPENDENCY,
  (pId, dId) => `/api/projects/${pId}/projects-dependencies/${dId}`,
  projectDependencyActionMapper,
  syncProjectDependenciesToIntegration,
);

const removeProjectProjectDependency = removeDependencyActionFactory(
  REMOVE_PROJECT_PROJECT_DEPENDENCY,
  (pId, dId) => `/api/projects/${pId}/projects-dependencies/${dId}`,
  projectDependencyActionMapper,
  syncProjectDependenciesToIntegration,
);

// Estimate => Estimate Dependencies:

const addEstimateEstimateDependency = addDependencyActionFactory(
  ADD_ESTIMATE_ESTIMATE_DEPENDENCY,
  (estimateId, dId) => `/api/estimates/${estimateId}/estimates-dependencies/${dId}`,
  estimateDependencyActionMapper,
);

const removeEstimateEstimateDependency = removeDependencyActionFactory(
  REMOVE_ESTIMATE_ESTIMATE_DEPENDENCY,
  (estimateId, dId) => `/api/estimates/${estimateId}/estimates-dependencies/${dId}`,
  estimateDependencyActionMapper,
);

// Estimate => Project Dependencies:

const addEstimateProjectDependency = addDependencyActionFactory(
  ADD_ESTIMATE_PROJECT_DEPENDENCY,
  (estimateId, dId) => `/api/estimates/${estimateId}/projects-dependencies/${dId}`,
  estimateDependencyActionMapper,
);

const removeEstimateProjectDependency = removeDependencyActionFactory(
  REMOVE_ESTIMATE_PROJECT_DEPENDENCY,
  (estimateId, dId) => `/api/estimates/${estimateId}/projects-dependencies/${dId}`,
  estimateDependencyActionMapper,
);

// Task => Task Dependencies:

const addTaskTaskDependency = addDependencyActionFactory(
  ADD_TASK_TASK_DEPENDENCY,
  (taskId, dId) => `/api/tasks/${taskId}/tasks-dependencies/${dId}`,
  taskDependencyActionMapper,
);

const removeTaskTaskDependency = removeDependencyActionFactory(
  REMOVE_TASK_TASK_DEPENDENCY,
  (taskId, dId) => `/api/tasks/${taskId}/tasks-dependencies/${dId}`,
  taskDependencyActionMapper,
);

// Task => Project Dependencies:

const addTaskProjectDependency = addDependencyActionFactory(
  ADD_TASK_PROJECT_DEPENDENCY,
  (taskId, dId) => `/api/tasks/${taskId}/projects-dependencies/${dId}`,
  taskDependencyActionMapper,
);

const removeTaskProjectDependency = removeDependencyActionFactory(
  REMOVE_TASK_PROJECT_DEPENDENCY,
  (taskId, dId) => `/api/tasks/${taskId}/projects-dependencies/${dId}`,
  taskDependencyActionMapper,
);

// Other:

const fetchProjectDependencies = project => {
  return createThunk(FETCH_PROJECT_DEPENDENCIES, axios.get(`/api/projects/${project.id}/projects-dependencies/`), {
    projectId: project.id,
  });
};

const fetchProjectsDependencies = filters => {
  return createThunk(SET_PROJECTS_DEPENDENCIES, axios.post(`/api/projects/search/dependencies`, filters));
};

export {
  // Project => Task Dependencies:
  addProjectTaskDependency,
  removeProjectTaskDependency,
  // Project => Estimate Dependencies:
  addProjectEstimateDependency,
  removeProjectEstimateDependency,
  // Project => Project Dependencies:
  addProjectProjectDependency,
  removeProjectProjectDependency,
  // Estimate => Estimate Dependencies:
  addEstimateEstimateDependency,
  removeEstimateEstimateDependency,
  // Estimate => Project Dependencies:
  addEstimateProjectDependency,
  removeEstimateProjectDependency,
  // Task => Task Dependencies:
  addTaskTaskDependency,
  removeTaskTaskDependency,
  // Task => Project Dependencies:
  addTaskProjectDependency,
  removeTaskProjectDependency,
  // Other:
  fetchProjectDependencies,
  fetchProjectsDependencies,
  syncProjectDependenciesToIntegration,
};
