import { createSelector } from 'reselect';

import sortByRank from 'utils/sortByRank';

const emptyArray = [];

export function getState(state) {
  return state.metrics;
}

export const getMetrics = createSelector(
  state => state?.metrics?.metrics,
  metrics => metrics?.sort(sortByRank),
);

export const getMetricById = createSelector([getState, getMetrics, (_, id) => id], (_, state, id) => {
  return state.find(m => m.id === +id);
});

export const getActiveMetrics = createSelector(getMetrics, metrics => metrics.filter(m => m.status === 'Active'));

export const getSearchText = createSelector(
  state => state.metrics,
  metricsState => metricsState?.searchText || '',
);

export const getIsHidingArchivedMetrics = createSelector(
  state => state.metrics,
  metricsState => metricsState?.isHidingArchivedMetrics,
);

export const getTableVisibleFields = createSelector(getState, metricsState => metricsState?.tableVisibleFields);

export const getAreMetricClustersVisible = createSelector(getState, metricsState => metricsState?.metricClustersVisible);
export const getMetricViewModeTimePeriod = createSelector(getState, metricsState => metricsState?.metricViewModeTimePeriod);

export const getChartsLayout = createSelector(getState, metricsState => metricsState?.chartsLayout);

export const isInEditModeChartsLayout = createSelector(getState, metricsState => metricsState?.editingChartsLayout ?? false);

export const selectIsMetricMoarVisible = createSelector(getState, metricsState => metricsState?.metricMoarVisible);

export const selectChartVisibleLayers = createSelector(getState, metricsState => metricsState?.chartVisibleLayers || emptyArray);

export const getMetricIntegrationFilterOptions = createSelector(
  [getState, (_, orgIntegrationId) => orgIntegrationId],
  (state, orgIntegrationId) => {
    const { metricIntegrationFilterOptions } = state || {};

    return metricIntegrationFilterOptions?.[orgIntegrationId] || {};
  },
);
