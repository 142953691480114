import moment from 'moment-timezone';
import getRoundedValue from 'routes/Forecast/helpers/getRoundedValue';

const DAY = 1;
const WEEK = 7;
const MONTH = 365 / 12;
const QUARTER = 365 / 4;
const YEAR = 365;

/**
 * Function that generates the timeline with Bussiness Values
 *
 * @param {*} gantt
 * @param {*} tasks
 * @param {*} scale
 * @param {*} selectedRounding
 */
const calculateBusinessValueLoad = (gantt, tasks, scale, selectedRounding) => {
  const step = scale.unit;
  const timegrid = {};

  const mapStepToStepLength = {
    day: DAY,
    week: WEEK,
    month: MONTH,
    quarter: QUARTER,
  };

  const stepLength = mapStepToStepLength[step];

  for (let i = 0; i < (tasks || []).filter(t => t.dbType === 'project').length; i++) {
    const task = tasks[i];
    const startDate = new Date(task.end_date);

    let currDate = gantt.date[`${step}_start`](startDate);

    currDate = gantt.date.add_quarter(currDate, 1);

    const maxDate = moment(currDate).add(2, 'years');

    while (currDate < maxDate) {
      const date = currDate;

      currDate = gantt.date.add(currDate, 1, step);

      if (!gantt.isWorkTime({ date, task })) {
        continue;
      }
      const startDiff = moment(task.end_date).diffDuration(moment(date), 'days');
      const endDiff = moment(currDate).diffDuration(maxDate, 'days');
      let value = ((task.business_value || 0) / (YEAR * 2)) * stepLength;

      if (startDiff > 0) {
        value *= (stepLength - startDiff) / stepLength;
      }
      if (endDiff > 0) {
        value *= (stepLength - endDiff) / stepLength;
      }

      const timestamp = date.valueOf();

      if (!timegrid[timestamp]) {
        timegrid[timestamp] = {};
        timegrid[timestamp].value = 0;
      }
      timegrid[timestamp].value += value;
    }
  }

  const timetable = [];
  let start;
  let end;

  Object.keys(timegrid).forEach(i => {
    start = new Date(i * 1);
    end = gantt.date.add(start, 1, step);
    timetable.push({
      start_date: start,
      end_date: end,
      value: getRoundedValue(timegrid[i].value, selectedRounding),
    });
  });

  return timetable;
};

/**
 * Function that renders the Bussiness Value row on the gantt
 *
 * @param {*} options
 */
const renderBusinessValueLine = ({ businessValue, timeline, gantt, selectedTimelineInterval, selectedRounding }) => {
  const scaleUnit =
    selectedTimelineInterval && ['month', 'week'].includes(selectedTimelineInterval.key)
      ? { unit: selectedTimelineInterval.key }
      : timeline.getScale();

  const tasks = gantt.getTaskByTime();
  const timetable = calculateBusinessValueLoad(gantt, tasks, scaleUnit, selectedRounding);

  const row = document.createElement('div');

  for (let i = 0; i < timetable.length; i++) {
    const day = timetable[i];

    const sizes = timeline.getItemPosition(businessValue, day.start_date, day.end_date);
    const el = document.createElement('div');

    el.style.cssText = [
      `left:${sizes.left}px`,
      `width:${sizes.width - 1}px`,
      'position:absolute',
      'cursor:pointer',
      `height:${gantt.config.row_height}px`,
      `line-height:${sizes.height}px`,
      `top:${sizes.top}px`,
    ].join(';');

    el.className = day.value ? 'businessValueCell' : 'businessValueCell_null';
    el.innerHTML = day.value || '';
    row.appendChild(el);
  }
  return row;
};

export default renderBusinessValueLine;
