import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export default styled((props) => (
  <IconButton {...props}>
    <OpenInNewIcon fontSize="small" style={{ fontSize: 14 }} />
  </IconButton>)
)`
  &&&& {
    padding: 3px;
    font-size: 1.125rem;
  }
`;
