import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { defaultTo, isEmpty } from 'ramda';

import { useCommonGridProps, useCommonGridEventHandlers, useInitAgGrid } from 'design-system/molecules/AgGridReact-New/hooks';

import { getUsers } from 'store/users/selectors';
import { getOrganization } from 'store/organization/selectors';
import { selectGridRowHeight } from 'features/LifecyclesGrid/store/selectors';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

import {
  createNewGridLifecycle,
  updateGridLifecycle,
  removeUnsavedLifecycle as removeUnsavedLifecycleAction,
  deleteGridLifecycle,
  createLifecycleRoadmap,
  deleteLifecycleRoadmap,
  bulkDeleteLifecycleRoadmaps,
  switchRowOrder,
} from '../store';
import useLifecyclesGridColumnDefinition from './useLifecyclesGridColumnDefinition';
import { LIFECYCLE_ROADMAP_FIELD } from '../helpers/constants';

const defaultToEmptyArray = defaultTo([]);

const useLifecycleList = ({ lifecycles, canUpdate = false, canDelete = false }) => {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const organization = useSelector(getOrganization);
  const rowHeight = useSelector(selectGridRowHeight);
  const { getDefaultRoadmapTitleForMetadataItem } = useOrganizationsAccessControl();

  const data = useMemo(() => {
    return lifecycles.map(item => {
      let lifecycleRoadmaps = defaultToEmptyArray(item[LIFECYCLE_ROADMAP_FIELD]);

      if (isEmpty(lifecycleRoadmaps)) {
        const defaultRoadmapTitle = getDefaultRoadmapTitleForMetadataItem(item);

        lifecycleRoadmaps = [{ title: defaultRoadmapTitle }];
      }

      return {
        ...item,
        [LIFECYCLE_ROADMAP_FIELD]: lifecycleRoadmaps,
      };
    });
  }, [lifecycles, getDefaultRoadmapTitleForMetadataItem]);

  const updateLifecycle = useCallback((id, data) => dispatch(updateGridLifecycle(id, data)), []);
  const saveLifecycle = useCallback(data => dispatch(createNewGridLifecycle(data)), []);
  const removeUnsavedLifecycle = useCallback(() => dispatch(removeUnsavedLifecycleAction()), []);
  const deleteLifecycle = useCallback(({ id }) => dispatch(deleteGridLifecycle(id)), []);
  const handleRowDrag = useCallback(
    (currentNode, lastOverNode, { position }) =>
      dispatch(switchRowOrder(currentNode.data.id, lastOverNode.data.id, null, position)),
    [],
  );

  const roadmapColumn = {
    lifecycles: data,
    createLifecycleRoadmap,
    deleteLifecycleRoadmap,
    bulkDeleteLifecycleRoadmaps,
    hideMetadataRoadmaps: !organization.enable_metadata_roadmaps,
  };
  const { columnDefs, defaultColumnDef } = useLifecyclesGridColumnDefinition({
    canUpdate,
    canDelete,
    users,
    roadmapColumn,
    deleteLifecycle,
  });
  const commonGridProps = useCommonGridProps({ isTreeView: false });
  const commonGridEvents = useCommonGridEventHandlers({
    items: data,
    idKey: 'id',
    actions: {
      save: saveLifecycle,
      update: updateLifecycle,
      remove: removeUnsavedLifecycle,
      drag: handleRowDrag,
    },
  });

  const { isGridReady: getGridIsReady, getGridApi, initAgGrid } = useInitAgGrid();

  const handleGridReady = useCallback(
    params => {
      initAgGrid(params.api, params.columnApi);
    },
    [initAgGrid],
  );

  return {
    data,
    columnDefs,
    defaultColumnDef,
    commonGridProps,
    commonGridEvents,
    rowHeight,
    getGridIsReady,
    getGridApi,
    handleGridReady,
  };
};

export default useLifecycleList;
