import {
  getGroupOptions as getGroupOptionsProjects,
  getTimeframeOption,
  getTimeframesOptions,
} from 'store/projects/helpers/groupOptions';
import { DEFAULT_GROUP_OPTION } from 'store/roadmapVersions/helpers/groupOptions';

const KEY_FIELD_MAP = {
  objectiveCorp: 'objective_corp_id',
  objective: 'objective_id',
  timeframeCorp: 'timeframe_corp_id',
  timeframe: 'timeframe_id',
  timeframe2: 'timeframe_2_id',
  keyResult1: 'key_result_1_id',
  keyResult2: 'key_result_2_id',
  roadmapCorp: 'roadmap_corp_id',
  roadmap: 'roadmap_id',
  product1: 'product_1_id',
  theme: 'theme_id',
  phase: 'phase_id',
  category: 'category_id',
  priority: 'priority_id',
  owner: 'owner_id',
  status_color: 'status_color',
  planningStage: 'planningStage',
  tags: 'tags',
  customers: 'customers',
  initiative: 'parent_id',
  bet: 'parent_id',
  metrics: 'metrics',
  roadmapHistorySnap: 'roadmap_history_snap_id',
};

const TARGET_DATE_OPTIONS = [
  {
    key: 'start-quarter',
    type: 'dates',
    title: 'Target Start Quarter',
    groupBy: 'quarter',
    field: 'estimated_start_date',
  },
  {
    key: 'end-quarter',
    type: 'dates',
    title: 'Target End Quarter',
    groupBy: 'quarter',
    field: 'deadline',
  },
  {
    key: 'start-year',
    type: 'dates',
    title: 'Target Start Year',
    groupBy: 'year',
    field: 'estimated_start_date',
  },
  {
    key: 'end-year',
    type: 'dates',
    title: 'Target End Year',
    groupBy: 'year',
    field: 'deadline',
  },
];

const ESTIMATED_DATE_OPTIONS = [
  {
    key: 'estimated-start-month',
    type: 'dates',
    title: 'Estimated Start Month',
    groupBy: 'month',
    field: 'start_date_estimates',
  },
  {
    key: 'estimated-end-month',
    type: 'dates',
    title: 'Estimated End Month',
    groupBy: 'month',
    field: 'end_date_estimates',
  },
  {
    key: 'estimated-start-quarter',
    type: 'dates',
    title: 'Estimated Start Quarter',
    groupBy: 'quarter',
    field: 'start_date_estimates',
  },
  {
    key: 'estimated-end-quarter',
    type: 'dates',
    title: 'Estimated End Quarter',
    groupBy: 'quarter',
    field: 'end_date_estimates',
  },
  {
    key: 'estimated-start-year',
    type: 'dates',
    title: 'Estimated Start Year',
    groupBy: 'year',
    field: 'start_date_estimates',
  },
  {
    key: 'estimated-end-year',
    type: 'dates',
    title: 'Estimated End Year',
    groupBy: 'year',
    field: 'end_date_estimates',
  },
];

export const getDateOptions = () => [...TARGET_DATE_OPTIONS, ...ESTIMATED_DATE_OPTIONS];

export const getRoadmapHistorySnapOption = getSystemFieldName => ({
  key: 'roadmapHistorySnaps',
  field: KEY_FIELD_MAP.roadmapHistorySnap,
  title: getSystemFieldName('snap'),
});

export const getGroupOptionByKey = (key, groupOptions, defaultValue = null) =>
  groupOptions?.find(option => option?.key === key) ?? defaultValue;

// return all options for grouping and hook logic purposes
export const getGroupOptions = ({ dynamicDates, ...configs }) => {
  return [...getGroupOptionsProjects(configs), ...getDateOptions(), getRoadmapHistorySnapOption(configs.getSystemFieldName)];
};

// allowed options for inline grouping
export const getInlineGroupOptions = configs => {
  return [DEFAULT_GROUP_OPTION, ...getGroupOptionsProjects(configs).filter(option => !option?.key?.startsWith('timeframe'))];
};

// Options for row grouping
export const getColGroupOptions = ({ getSystemFieldName, hasMultiLevelPortfolioMetadata }) => {
  return [
    ...(hasMultiLevelPortfolioMetadata ? getTimeframesOptions(getSystemFieldName) : [getTimeframeOption(getSystemFieldName)]),
    ...getDateOptions(),
  ];
};
