import React from 'react';

import ChartContent from '../ChartContent';

export default ({ chart, label, wrapper: Wrapper, title, subtitle, actionButtons }) => {
  if (!chart) return '';

  return (
    <Wrapper>
      <ChartContent title={title} subtitle={subtitle} actionButtons={actionButtons}>
        {chart}
      </ChartContent>
    </Wrapper>
  );
};
