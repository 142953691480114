import React, { Component } from 'react';
import getOrCreateSocket from 'utils/getOrCreateSocket';

import { connect } from 'react-redux';

import { gotThemesRealtimeUpdate } from 'store/themes';
import { gotProjectRealtimeUpdate } from 'store/projects';
import { gotTasksRealtimeUpdate } from 'store/tasks';
import { gotEstimatesRealtimeUpdate } from 'store/estimates';
import { gotCategoriesRealtimeUpdate } from 'store/categories';
import { gotPhasesRealtimeUpdate } from 'store/phases';
import { gotObjectivesRealtimeUpdate, gotKeyResultsRealtimeUpdate } from 'store/objectives';
import { gotRoadmapsRealtimeUpdate, gotProductsRealtimeUpdate } from 'store/roadmaps';
import { gotTimeframesRealtimeUpdate } from 'store/timeframes';
import { gotTeamsRealtimeUpdate } from 'store/teams';
import { gotSkillsRealtimeUpdate } from 'store/skills';
import { gotTagsRealtimeUpdate } from 'store/tags';
import { gotCustomersRealtimeUpdate } from 'store/customers';
import { gotUsersRealtimeUpdate } from 'store/users';
import { gotOrgIntegrationsRealtimeUpdate } from 'store/organization';
import { getCurrentUser } from 'store/login/selectors';
import { receiveRealtimeNotification } from 'store/notifications/thunks';
import { handleStreamReleaseNotes } from 'features/AIReleaseNotes/store';

export default function subscribeNewData(topics) {
  return WrappedComponent => {
    class RealtimeWrapper extends Component {
      componentDidMount() {
        this.updatedIds = [];
        this.updatedTopics = [];
        this.types = [];
        this.dataObjects = [];
        this.socket = getOrCreateSocket();

        this.socket.onReconnect(this.joinSocketio);
        this.joinSocketio();

        this.socket.subscribe(
          ({ updatedIds, type, data, topic, user }) => {
            const currentUserId = (this.props.user || {}).id;

            // if (!allowOwnChanges && userId === currentUserId) {
            //   return;
            // }
            switch (topic) {
              case 'projects':
                this.props.gotProjectRealtimeUpdate(type, data, currentUserId, user);
                break;
              case 'estimates':
                this.props.gotEstimatesRealtimeUpdate(type, data);
                break;
              case 'tasks':
                this.props.gotTasksRealtimeUpdate(type, data);
                break;
              case 'themes':
                this.props.gotThemesRealtimeUpdate(type, data);
                break;
              case 'categories':
                this.props.gotCategoriesRealtimeUpdate(type, data);
                break;
              case 'phases':
                this.props.gotPhasesRealtimeUpdate(type, data);
                break;
              case 'objectives':
                this.props.gotObjectivesRealtimeUpdate(type, data);
                break;
              case 'roadmaps':
                this.props.gotRoadmapsRealtimeUpdate(type, data);
                break;
              case 'timeframes':
                this.props.gotTimeframesRealtimeUpdate(type, data);
                break;
              case 'teams':
                this.props.gotTeamsRealtimeUpdate(type, data);
                break;
              case 'skills':
                this.props.gotSkillsRealtimeUpdate(type, data);
                break;
              case 'tags':
                this.props.gotTagsRealtimeUpdate(type, data);
                break;
              case 'customers':
                this.props.gotCustomersRealtimeUpdate(type, data);
                break;
              case 'users':
                this.props.gotUsersRealtimeUpdate(type, data);
                break;
              case 'products':
                this.props.gotProductsRealtimeUpdate(type, data);
                break;
              case 'keyResults':
                this.props.gotKeyResultsRealtimeUpdate(type, data);
                break;
              case 'orgIntegrations':
                this.props.gotOrgIntegrationsRealtimeUpdate(type, data);
                break;
              case 'notifications':
                this.props.receiveRealtimeNotification(data);
                break;
              case 'streamReleaseNotes':
                this.props.handleStreamReleaseNotes(type, data);
                break;
              default:
                break;
            }
            // if (data.user !== JSON.parse(localStorage.getItem('user')).id && !toast.isActive('NEW_DATA')) {
            //   if (this.refreshCallback) {

            //     this.updatedIds = [...this.updatedIds, ...data.updatedIds];
            //     this.updatedTopics = [...this.updatedTopics, data.topic];
            //     this.types = [...this.types, data.type];
            //     this.dataObjects = [...this.dataObjects, data.data];
            //     if (this.timeoutHandle) window.clearTimeout(this.timeoutHandle);
            //     this.timeoutHandle = window.setTimeout(
            //       this.refresh,
            //       // REALTIME_WAIT_TIMEOUT * Math.floor(Math.random() * 10)
            //     );
            //   } else {
            //     if (this.timeoutHandle) window.clearTimeout(this.timeoutHandle);
            //     this.timeoutHandle = window.setTimeout(() => {
            //       toast(<ToastNewDataAvailable onClick={(ev) => {
            //         ev.preventDefault();
            //         this.refresh();
            //       }}
            //       />, {
            //         autoClose: false,
            //         toastId: 'NEW_DATA',
            //         onClose: this.refresh,
            //       });
            //     });// , REALTIME_WAIT_TIMEOUT * Math.floor(Math.random() * 10));
            //   }
            // }
          },
          { isRealtimeChangesTopicHandler: true },
        );
      }

      componentWillUnmount() {
        if (this.timeoutHandle) window.clearTimeout(this.timeoutHandle);
        topics.forEach(group => {
          this.socket.leaveRealtimeChangesTopic(group);
        });
        this.socket.destructor();
      }

      joinSocketio = () => {
        topics.forEach(group => {
          this.socket.joinRealtimeChangesTopic(group);
        });
      };

      refresh = () => {
        setTimeout(() => {
          if (this.refreshCallback) this.refreshCallback(this.updatedTopics, this.updatedIds, this.types, this.dataObjects);
          else {
            // if (!this.props.location) {
            //   return;
            // }
            // const current = this.props.location.pathname;
            // this.props.history.replace('/reload');
            // this.props.history.replace(current);
          }
          this.updatedIds = [];
          this.updatedTopics = [];
          this.types = [];
          this.dataObjects = [];
        });
      };

      render() {
        return (
          <WrappedComponent
            {...this.props}
            setRefreshCallback={callback => {
              this.refreshCallback = callback.bind(this);
            }}
          />
        );
      }
    }

    const mapStateToProps = state => {
      return {
        user: getCurrentUser(state),
      };
    };

    const mapDispatchToProps = (dispatch, props) => {
      return {
        gotProjectRealtimeUpdate: (type, data) => dispatch(gotProjectRealtimeUpdate(type, data)),
        gotEstimatesRealtimeUpdate: (type, data) => dispatch(gotEstimatesRealtimeUpdate(type, data)),
        gotThemesRealtimeUpdate: (type, data) => dispatch(gotThemesRealtimeUpdate(type, data)),
        gotCategoriesRealtimeUpdate: (type, data) => dispatch(gotCategoriesRealtimeUpdate(type, data)),
        gotPhasesRealtimeUpdate: (type, data) => dispatch(gotPhasesRealtimeUpdate(type, data)),
        gotObjectivesRealtimeUpdate: (type, data) => dispatch(gotObjectivesRealtimeUpdate(type, data)),
        gotKeyResultsRealtimeUpdate: (type, data) => dispatch(gotKeyResultsRealtimeUpdate(type, data)),
        gotRoadmapsRealtimeUpdate: (type, data) => dispatch(gotRoadmapsRealtimeUpdate(type, data)),
        gotProductsRealtimeUpdate: (type, data) => dispatch(gotProductsRealtimeUpdate(type, data)),
        gotTimeframesRealtimeUpdate: (type, data) => dispatch(gotTimeframesRealtimeUpdate(type, data)),
        gotTeamsRealtimeUpdate: (type, data) => dispatch(gotTeamsRealtimeUpdate(type, data)),
        gotSkillsRealtimeUpdate: (type, data) => dispatch(gotSkillsRealtimeUpdate(type, data)),
        gotTagsRealtimeUpdate: (type, data) => dispatch(gotTagsRealtimeUpdate(type, data)),
        gotCustomersRealtimeUpdate: (type, data) => dispatch(gotCustomersRealtimeUpdate(type, data)),
        gotUsersRealtimeUpdate: (type, data) => dispatch(gotUsersRealtimeUpdate(type, data)),
        gotTasksRealtimeUpdate: (type, data) => dispatch(gotTasksRealtimeUpdate(type, data)),
        gotOrgIntegrationsRealtimeUpdate: (type, data) => dispatch(gotOrgIntegrationsRealtimeUpdate(type, data)),
        receiveRealtimeNotification: data => dispatch(receiveRealtimeNotification(data)),
        handleStreamReleaseNotes: (type, data) => dispatch(handleStreamReleaseNotes(type, data)),
      };
    };

    return connect(mapStateToProps, mapDispatchToProps)(RealtimeWrapper);
  };
}
