import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import useMetricValuesForLineChart from 'routes/Dashboard/Snapshot/hooks/useMetricValuesForLineChart';
import { ProjectsListLightboxProvider } from 'hooks/useProjectsListLightbox';
import usePermissions from 'hooks/permissions/usePermissions';
import makeChangeHandlerForDateRange from 'utils/makeChangeHandlerForDateRange';
import { openMetricLightbox } from 'features/MetricsDialog/store/thunks';
import { resetData } from 'features/MetricsDialog/store/actions';
import { selectMetric } from 'features/MetricsDialog/store/selectors';
import { subscribeToRefreshMetricsDialogMetric } from 'features/MetricsDialog/store/listenerMiddleware';

import useMetricDialogActions from './hooks/useMetricDialogActions';
import MetricsDialogComponent from './MetricsDialogComponent';
import getChartDates from './helpers/getChartDates';

const MetricsDialogContainer = props => {
  const { currentUser, selectedMetricId, isCreating } = props;
  const isAnonymousUser = currentUser.is_anonymous;
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const dispatch = useDispatch();

  const { canUpdate, canCreate } = usePermissions();

  const { parentStartDate, parentEndDate } = props;

  const dateWindowForMetric = useRef(null);

  const metric = useSelector(selectMetric);

  useEffect(() => {
    if (selectedMetricId && metric?.id !== selectedMetricId) {
      dispatch(openMetricLightbox(selectedMetricId));
    } else if (!selectedMetricId) {
      dispatch(resetData());
    }
  }, [selectedMetricId, metric]);

  const metricTargetValues = useMemo(() => metric?.metricValues?.filter(value => value.type === 'target'), [metric]);

  const { chartData, dates, formatDate, chartItems, useValuesAsPercentages, datesForChart, chartTimeUnit } =
    useMetricValuesForLineChart({
      metricId: metric?.id,
      metricValues: metric?.metricValues || [],
      startDate,
      endDate,
      inView: true,
      moarPreferences: {
        show: false,
      },
    });

  const {
    handleAddMetricValue,
    handleAddUnsavedMetricValue,
    handleDeleteMetricValue,
    handleRemoveMetricValue,
    handleUpdateMetricValue,
    updateMetric,
    createMetric,
  } = useMetricDialogActions(metric?.id);

  useEffect(() => {
    const unsubscribeRefreshMetric = dispatch(subscribeToRefreshMetricsDialogMetric());

    return unsubscribeRefreshMetric;
  }, []);

  useEffect(() => {
    const { startDate, endDate } = getChartDates(parentStartDate, parentEndDate, metricTargetValues);
    const shouldUpdateDate =
      dateWindowForMetric?.current?.selectedMetricId !== selectedMetricId || !dateWindowForMetric?.current?.isDirty;

    // We only want to apply this logic on 1st open so we check if this metric's date window was already updated
    if (metricTargetValues && shouldUpdateDate) {
      setStartDate(new Date(startDate));
      setEndDate(new Date(endDate));
    }
  }, [parentStartDate, parentEndDate, metricTargetValues, selectedMetricId]);

  const handleDateRangeChange = useMemo(
    () => makeChangeHandlerForDateRange(setStartDate, setEndDate),
    [setStartDate, setEndDate],
  );

  const handleDateChange = useCallback(
    dates => {
      handleDateRangeChange(dates);

      dateWindowForMetric.current = { selectedMetricId, isDirty: true };
    },
    [handleDateRangeChange],
  );

  const canEditMetric = canUpdate(PERMISSION_RESOURCES.metric) && !isCreating;
  const canCreateMetric = canCreate(PERMISSION_RESOURCES.metric);

  return (
    <ProjectsListLightboxProvider>
      <MetricsDialogComponent
        {...props}
        addMetricValue={handleAddMetricValue}
        addUnsavedMetricValue={handleAddUnsavedMetricValue}
        chartData={chartData}
        chartItems={chartItems || []}
        dates={dates || []}
        datesForChart={datesForChart}
        chartTimeUnit={chartTimeUnit}
        deleteMetricValue={handleDeleteMetricValue}
        endDate={endDate}
        formatDate={formatDate}
        handleDateChange={handleDateChange}
        isAnonymousUser={isAnonymousUser}
        items={metric?.metricValues || []}
        metric={metric}
        removeMetricValue={handleRemoveMetricValue}
        startDate={startDate}
        updateMetricValue={handleUpdateMetricValue}
        useValuesAsPercentages={useValuesAsPercentages}
        updateMetric={updateMetric}
        canEditMetric={canEditMetric}
        createMetric={createMetric}
        canCreateMetric={canCreateMetric}
      />
    </ProjectsListLightboxProvider>
  );
};

export default MetricsDialogContainer;
