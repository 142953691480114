import styled from 'styled-components';

import SelectableItem from 'design-system/molecules/SelectableItem/index';
import Button from 'design-system/atoms/Button/index';

import { spacing } from 'design-system/theme';
import Text from 'design-system/atoms/Text';

const Flex = styled.div`
  display: flex;
  height: 100%;
`;

const Left = styled.div`
  width: 45%;
  background-color: ${({ theme }) => theme.palette.newLayout.background.concrete};
  position: relative;
  overflow: hidden;
`;

const Background = styled.img`
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

const Right = styled.div`
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing(10)}px;
`;

const Content = styled.div`
  max-width: 450px;
`;

const Title = styled(Text)`
  && {
    font-size: ${({ theme }) => theme.typography.fontSizeH4}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    margin-top: ${spacing(3)}px;
  }
`;

const StyledText = styled(Text)`
  && {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    margin-top: ${spacing()}px;
  }
`;

const FormContainer = styled.div``;

const FormGroup = styled.div`
  margin-top: ${spacing(4)}px;
`;

const StyledSelectableItem = styled(SelectableItem)`
  max-width: 350px;
  margin-top: ${spacing()}px;
`;

const StyledButton = styled(Button)`
  &&&& {
    margin-top: ${spacing(3)}px;
    text-transform: uppercase;
  }
`;

export {
  Flex,
  Left,
  Background,
  Right,
  Content,
  Title,
  StyledText,
  FormContainer,
  FormGroup,
  StyledSelectableItem,
  StyledButton,
};
