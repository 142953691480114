import React, { useMemo } from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';

import Loading from 'design-system/atoms/Loading/Loading';

import { BaseGrid } from 'containers/Grids';
import { getGridHeight } from 'containers/Grids/ProjectsGrid/helpers';
import agGridStylesOverride from 'containers/Grids/ProjectsGrid/styles/agGridStylesOverride';
import { getRowId } from 'design-system/molecules/AgGridReact-New/helpers';

const GRID_MIN_HEIGHT = '350px';
const NUM_OF_ROWS_FOR_MIN_HEIGHT = 5;

export default ({
  projects,
  isLoading,
  columnsDefs,
  gridProps,
  hasSelectedGroupBy,
  autoGroupColumn,
  defaultColumnDef,
  handleGridReady,
  handleDisplayedColumnsChanged,
}) => {
  if (isLoading) {
    return (
      <LoadingWrapper style={{ height: GRID_MIN_HEIGHT }}>
        <Loading />
      </LoadingWrapper>
    );
  }

  const gridHeight = useMemo(
    () => (projects?.length > NUM_OF_ROWS_FOR_MIN_HEIGHT ? getGridHeight() : GRID_MIN_HEIGHT),
    [projects],
  );

  const autoGroupColumnDef = useMemo(() => (hasSelectedGroupBy ? autoGroupColumn : null), [hasSelectedGroupBy]);

  return (
    <Container>
      <StyledCard elevation={2}>
        <BaseGrid
          cssStyles={agGridStylesOverride}
          rowData={projects}
          height={gridHeight}
          context={{}}
          {...gridProps}
          autoGroupColumnDef={hasSelectedGroupBy ? autoGroupColumnDef : undefined}
          columnDefs={columnsDefs}
          treeData={!!hasSelectedGroupBy}
          rowClassRules={null}
          defaultColDef={defaultColumnDef}
          onGridReady={handleGridReady}
          onDisplayedColumnsChanged={handleDisplayedColumnsChanged}
          getRowId={getRowId}
        />
      </StyledCard>
    </Container>
  );
};

const Container = styled.div``;

const StyledCard = styled(Card)`
  &&&& {
    width: 100%;
    margin: auto;
    position: relative;
    border: 0.5px solid #dfe2e6;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
