import { useState } from 'react';

export default fields => {
  const [fieldsToUpdate, setFieldsToUpdate] = useState([]);
  const availableFields = Object.keys(fields).filter(field => !fieldsToUpdate.find(u => u.field === field));

  const removeField = fieldIndex => {
    setFieldsToUpdate(fieldsToUpdate.filter((_, index) => index !== fieldIndex));
  };

  const getFieldData = (field, fieldData) => ({
    field,
    editingComponent: fieldData ? fieldData.editingComponent : null,
    label: fieldData ? fieldData.label : field,
    value: fieldData ? fieldData.defaultValue : undefined,
  });

  const changeField = (fieldIndex, field) => {
    const updatedFields = [...fieldsToUpdate];
    const fieldData = fields[field];

    if (updatedFields[fieldIndex]) {
      updatedFields[fieldIndex] = {
        ...fieldsToUpdate[+fieldIndex],
        ...getFieldData(field, fieldData),
      };
    }

    if (fieldData && fieldData.parentField && fieldData.updateParent) {
      const hasParentField = updatedFields.some(field => field.field === fieldData.parentField);

      if (!hasParentField) {
        updatedFields.push(getFieldData(fieldData.parentField, fields[fieldData.parentField]));
      }
    }

    if (fieldData && fieldData.grandParentField && fieldData.updateGrandParent) {
      const hasGrandParentField = updatedFields.some(field => field.field === fieldData.grandParentField);

      if (!hasGrandParentField) {
        updatedFields.push(getFieldData(fieldData.grandParentField, fields[fieldData.grandParentField]));
      }
    }

    if (fieldData && fieldData.greatGrandParentField && fieldData.updateGreatGrandParent) {
      const hasGreatGrandParentField = updatedFields.some(field => field.field === fieldData.greatGrandParentField);

      if (!hasGreatGrandParentField) {
        updatedFields.push(getFieldData(fieldData.greatGrandParentField, fields[fieldData.greatGrandParentField]));
      }
    }

    setFieldsToUpdate(updatedFields);
  };

  const changeFieldValue = (fieldIndex, value) => {
    const field = fieldsToUpdate[fieldIndex];
    const fieldData = field && field.field ? fields[field.field] : null;

    const updatedFields = [...fieldsToUpdate];

    if (updatedFields[fieldIndex]) {
      updatedFields[fieldIndex] = { ...field, value };
    }

    if (fieldData && fieldData.parentField && fieldData.updateParent) {
      const parentFieldIndex = updatedFields.findIndex(field => field.field === fieldData.parentField);

      updatedFields[parentFieldIndex] = { ...updatedFields[parentFieldIndex], value: fieldData.updateParent(value) };
    }
    if (fieldData && fieldData.grandParentField && fieldData.updateGrandParent) {
      const grandParentFieldIndex = updatedFields.findIndex(field => field.field === fieldData.grandParentField);

      updatedFields[grandParentFieldIndex] = {
        ...updatedFields[grandParentFieldIndex],
        value: fieldData.updateGrandParent(value),
      };
    }
    if (fieldData && fieldData.greatGrandParentField && fieldData.updateGreatGrandParent) {
      const greatGrandParentFieldIndex = updatedFields.findIndex(field => field.field === fieldData.greatGrandParentField);

      updatedFields[greatGrandParentFieldIndex] = {
        ...updatedFields[greatGrandParentFieldIndex],
        value: fieldData.updateGreatGrandParent(value),
      };
    }

    setFieldsToUpdate(updatedFields);
  };

  const appendField = () => {
    setFieldsToUpdate([...fieldsToUpdate, {}]);
  };

  return {
    availableFields,
    fieldsToUpdate,
    removeField,
    changeField,
    changeFieldValue,
    appendField,
  };
};
