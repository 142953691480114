import { OWNER_FIELD } from 'utils/filters/fieldFilters';

export const RECENTLY_CREATED_BY_ME_FILTER_KEY = 'recently-created-by-me';
export const RECENTLY_CREATED_FILTER_KEY = 'recently-created';
export const USER_IS_OWNER_FILTER_KEY = 'user-is-owner';

export const DETAILS_META_KEY = 'details';
export const HEALTH_META_KEY = 'status_color';
export const NAME_META_KEY = 'name';
export const OWNER_META_KEY = OWNER_FIELD.id;
export const PRODUCT_META_KEY = 'products';
export const RECENTLY_CREATED_META_KEY = 'created_at';
export const ROADMAP_META_KEY = 'roadmaps';
export const USER_META_KEY = 'users';
export const TITLE_KEY = 'title';
