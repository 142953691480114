import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, not } from 'ramda';
import moment from 'moment-timezone';

import useApplicationRoutes from 'hooks/useApplicationRoutes';
import { getUsers } from 'store/users/selectors';
import { getCurrentUser } from 'store/login/selectors';
import { viewMatchesQuery } from 'utils/userViews/filterViewsBySearch';
import { READ_ONLY_USER } from '@dragonboat/permissions';

export const OWNER_FILTER = 'owner';
export const PAGE_FILTER = 'page';
export const UPDATED_FILTER = 'updated';

const UPDATED_TODAY = 'today';
const UPDATED_THIS_WEEK = 'week';
const UPDATED_THIS_MONTH = 'month';
const UPDATED_EARLIER = 'earlier';

const UPDATED_AT_OPTIONS = [
  { id: UPDATED_TODAY, label: 'Today' },
  { id: UPDATED_THIS_WEEK, label: 'This week' },
  { id: UPDATED_THIS_MONTH, label: 'This month' },
  { id: UPDATED_EARLIER, label: 'Earlier' },
];

const pageHasIdAndViews = page => !!page.props?.pageId && page.props?.hasViews;
const pageIsValidForUserRole = (page, userRole) => userRole === READ_ONLY_USER || not(page.props?.isReadOnlyView);

const useViewsFilters = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilters, setSelectedFilters] = useState({ [OWNER_FILTER]: [], [PAGE_FILTER]: [], [UPDATED_FILTER]: [] });

  const users = useSelector(state => getUsers(state));
  const currentUser = useSelector(state => getCurrentUser(state));

  const [pages] = useApplicationRoutes();

  const handleFilterChange = (filter, selectedOptions) =>
    setSelectedFilters(prevSelectedFilters => ({ ...prevSelectedFilters, [filter]: selectedOptions || [] }));

  const hasOwnerFilterActive = useMemo(() => !isEmpty(selectedFilters[OWNER_FILTER]), [selectedFilters]);
  const hasPageFilterActive = useMemo(() => !isEmpty(selectedFilters[PAGE_FILTER]), [selectedFilters]);
  const hasUpdatedFilterActive = useMemo(() => !isEmpty(selectedFilters[UPDATED_FILTER]), [selectedFilters]);

  const ownerOptions = useMemo(
    () => [{ id: currentUser.id, label: 'Me' }, ...(users || []).map(user => ({ id: user.id, label: user.name }))],
    [users, currentUser],
  );

  const pageOptions = useMemo(
    () =>
      pages
        .filter(page => pageHasIdAndViews(page) && pageIsValidForUserRole(page, currentUser.role_id))
        .map(page => ({ id: page.props.pageId, label: page.props.displayName || page.label }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [pages],
  );

  const viewMatchesOwnerFilter = view =>
    !hasOwnerFilterActive || selectedFilters[OWNER_FILTER].some(owner => owner.id === view.user_id);

  const viewMatchesPageFilter = view => !hasPageFilterActive || selectedFilters[PAGE_FILTER].some(page => page.id === view.page);

  const viewMatchesUpdatedFilter = view => {
    if (!hasUpdatedFilterActive) return true;

    const filters = selectedFilters[UPDATED_FILTER].map(filter => filter.id);
    const currentDate = moment();
    const updatedAt = moment(view.updated_at);

    return (
      (filters.includes(UPDATED_TODAY) && updatedAt.isSame(currentDate, 'day')) ||
      (filters.includes(UPDATED_THIS_WEEK) &&
        updatedAt.isBetween(moment(currentDate).startOf('week'), currentDate, null, '[]')) ||
      (filters.includes(UPDATED_THIS_MONTH) &&
        updatedAt.isBetween(moment(currentDate).startOf('month'), currentDate, null, '[]')) ||
      (filters.includes(UPDATED_EARLIER) && updatedAt.isBefore(moment(currentDate).startOf('month'), 'day'))
    );
  };

  const applyFiltersOnViews = views =>
    views.filter(
      view =>
        viewMatchesQuery(view, searchQuery) &&
        viewMatchesOwnerFilter(view) &&
        viewMatchesPageFilter(view) &&
        viewMatchesUpdatedFilter(view),
    );

  return {
    searchQuery,
    setSearchQuery,
    selectedFilters,
    handleFilterChange,
    ownerOptions,
    pageOptions,
    updatedAtOptions: UPDATED_AT_OPTIONS,
    hasOwnerFilterActive,
    hasPageFilterActive,
    hasUpdatedFilterActive,
    applyFiltersOnViews,
  };
};

export default useViewsFilters;
