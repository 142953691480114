/**
 * @notice Find foreignKey of root from branch group name
 *
 * `keyResult1` => branch group name
 * `objective_id` => branch field name which is related with parent root
 */
export const TREE_BRANCH_FIELDS = isDodActive => {
  return {
    ...(isDodActive && { objective: 'parent_id' }),
    keyResult1: 'objective_id',
    keyResult2: 'parent_id',
    ...(isDodActive && { roadmap: 'parent_id' }),
    product1: 'roadmap_id',
  };
};

/**
 * @notice Find branch group name from root group name
 *
 * `objective` => root group name
 * `keyResult1` => branch group name
 */
export const TREE_BRANCHES = {
  objectiveCorp: 'objective',
  objective: 'keyResult1',
  keyResult1: 'keyResult2',
  roadmapCorp: 'roadmap',
  roadmap: 'product1',
};

/**
 * @notice Make sure if entity has got metric data
 */
export const HAS_METRIC_DATA = {
  objectiveCorp: true,
  objective: true,
  keyResult1: true,
  keyResult2: true,
};

export const TREE_BRANCH_METADATA_REF = {
  objective: 'objectivesCorp',
  keyResult1: 'objectives',
  keyResult2: 'keyResult1s',
  roadmap: 'roadmapsCorp',
  product1: 'roadmaps',
};
