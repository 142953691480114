import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const URLTextField = styled(TextField)``;

const NextButton = styled(Button)`
  &&&& {
    margin-left: 30px;
  }
`;

const Wrapper = styled.form`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;

  ${NextButton} {
    width: 100px;
    height: 40px;
  }

  ${URLTextField} {
    flex-grow: 1;
    margin-top: 0px;
  }
`;

export { URLTextField, NextButton, Wrapper };
