import React from 'react';

import usePageNavigation from 'hooks/usePagesNavigation/usePageNavigation';
import { setLastIdeasView } from 'store/app';
import PageHeaderFilters from 'containers/PageHeaderFilters';
import theme from 'design-system/theme';

const ForecastPageHeaderFilters = props => {
  const { pages, selectedPage, setSelectedPage } = usePageNavigation(
    '/forecast',
    props.history,
    'lastIdeasView',
    setLastIdeasView,
  );

  return (
    <PageHeaderFilters
      {...props}
      subNavigationTabs={pages}
      selectedSubNavigationTab={selectedPage}
      handleSubNavigationChange={setSelectedPage}
      pageIconBackground={theme.palette.newLayout.background.cobalt}
      enableMultiLayerSearch
    />
  );
};

export default ForecastPageHeaderFilters;
