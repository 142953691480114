import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentUser } from 'store/login/selectors';

import { checkRolePermission } from 'containers/UserPermission/utils';

import { ISSUE_COUNT_PROGRESS } from 'constants/integrations';

import converStringTimeToSeconds from 'utils/converStringTimeToSeconds';
import converTimeToString from 'utils/converTimeToString';

const DEFAULT_DATA = {
  track_progress_by: ISSUE_COUNT_PROGRESS,
  display_days_per_issue: 1,
  default_story_points: 2,
  display_storypoint_per_day: 1,
  default_time_estimate: 0,
};

const DEFAULT_TIME_ESTIMATE_VALUE = '1d';

const container = Component => {
  return props => {
    const { data, onChange } = props;

    const [currentData, setCurrentData] = useState({ ...DEFAULT_DATA, ...data });
    const [defaultTimeEstimateInString, setDefaultTimeEstimateInString] = useState(
      currentData.default_time_estimate ? converTimeToString(currentData.default_time_estimate) : DEFAULT_TIME_ESTIMATE_VALUE,
    );

    const currentUser = useSelector(getCurrentUser);

    const isReadOnly = useMemo(() => !checkRolePermission('EDIT_JIRA_INTEGRATION', currentUser));

    const onTrackProgressTypeChange = useCallback(
      type => {
        const newData = {
          ...data,
          track_progress_by: type,
        };

        setCurrentData(newData);
        onChange(newData);
      },
      [data, onChange, setCurrentData],
    );

    const onDefaultTimeEstimateChange = useCallback(
      value => {
        const newData = {
          ...data,
          default_time_estimate: converStringTimeToSeconds(value),
        };

        setDefaultTimeEstimateInString(value);
        onChange(newData);
        setCurrentData(newData);
      },
      [data, onChange, setCurrentData, setDefaultTimeEstimateInString],
    );

    const onSettingChange = useCallback(
      (field, value) => {
        const newData = {
          ...data,
          [field]: value,
        };

        onChange(newData);
        setCurrentData(newData);
      },
      [data, onChange, setCurrentData],
    );

    return (
      <Component
        data={currentData}
        isReadOnly={isReadOnly}
        defaultTimeEstimateInString={defaultTimeEstimateInString}
        onTrackProgressTypeChange={onTrackProgressTypeChange}
        onDefaultTimeEstimateChange={onDefaultTimeEstimateChange}
        onChange={onSettingChange}
      />
    );
  };
};

export default container;
