import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrganizationSystemFieldsNames as getOrganizationSystemFieldsNamesSelector } from 'store/organization/selectors';
import { getUsers as getUsersSelector } from 'store/users/selectors';

import { syncOrgProjectsFromJira as syncOrgProjectsFromJiraAction } from 'store/integrations';

import { DEFAULT_MAPPING_KEY } from 'constants/integrations';
import getSystemFieldNameHelper from 'utils/getSystemFieldName';

const container = Component => {
  return props => {
    const {
      mappingKey,
      orgIntegrationId,
      data,
      integrationSettings,
      onSave,
      onRemove,
      mappingLayer,
      userCanUpdateOrgIntegration,
    } = props;
    const dispatch = useDispatch();

    const [isConfirmSyncOpen, setIsConfirmSyncOpen] = useState(false);
    const [isJiraMappingLightboxOpen, setIsJiraMappingLightboxOpen] = useState(false);
    const [isSyncing, setIsSyncing] = useState(false);
    const [isConfirmSyncInProgressOpen, setIsConfirmSyncInProgressOpen] = useState(false);

    const systemFields = useSelector(state => getOrganizationSystemFieldsNamesSelector(state));
    const users = useSelector(state => getUsersSelector(state));

    const isRemoveMappingButtonVisible = useMemo(() => mappingKey !== DEFAULT_MAPPING_KEY, [mappingKey]);
    const mappingWithSyncEnabled = useMemo(() => (data || []).filter(f => !!f.dbAutoSync || !!f.jiraAutoSync), [data]);
    const lastUpdateUserName = useMemo(() => {
      return users.find(user => user.id === integrationSettings.last_edit_mapping_by)?.name;
    }, [integrationSettings.last_edit_mapping_by, users]);

    const getSystemFieldName = useCallback(name => getSystemFieldNameHelper(name, systemFields), []);

    const onOpenConfirmSync = useCallback(() => setIsConfirmSyncOpen(true), [setIsConfirmSyncOpen]);

    const onCloseConfirmSync = useCallback(() => setIsConfirmSyncOpen(false), [setIsConfirmSyncOpen]);

    const toggleSyncInProgressDialog = useCallback(
      isOpen => setIsConfirmSyncInProgressOpen(isOpen),
      [setIsConfirmSyncInProgressOpen],
    );

    const onConfirmSync = useCallback(() => {
      if (isSyncing) {
        return;
      }

      setIsSyncing(true);
      dispatch(syncOrgProjectsFromJiraAction(orgIntegrationId)).then(() => setIsSyncing(false));
      setIsConfirmSyncOpen(false);
      toggleSyncInProgressDialog(true);
    }, [orgIntegrationId]);

    const onOpenJiraMappingLightbox = useCallback(() => {
      setIsJiraMappingLightboxOpen(true);
    }, [setIsJiraMappingLightboxOpen]);

    const onCloseJiraMappingLightbox = useCallback(() => {
      setIsJiraMappingLightboxOpen(false);
    }, [setIsJiraMappingLightboxOpen]);

    const onSaveJiraMappingLightbox = useCallback(data => {
      onSave(data);
      setIsJiraMappingLightboxOpen(false);
    });

    return (
      <Component
        orgIntegrationId={orgIntegrationId}
        data={data || {}}
        integrationSettings={integrationSettings}
        userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
        isSyncing={isSyncing}
        isRemoveMappingButtonVisible={isRemoveMappingButtonVisible}
        lastUpdateUserName={lastUpdateUserName}
        mappingToDisplay={mappingWithSyncEnabled}
        getSystemFieldName={getSystemFieldName}
        isConfirmSyncOpen={isConfirmSyncOpen}
        onOpenConfirmSync={onOpenConfirmSync}
        onCloseConfirmSync={onCloseConfirmSync}
        onConfirmSync={onConfirmSync}
        isJiraMappingLightboxOpen={isJiraMappingLightboxOpen}
        onOpenJiraMappingLightbox={onOpenJiraMappingLightbox}
        onCloseJiraMappingLightbox={onCloseJiraMappingLightbox}
        onSaveJiraMappingLightbox={onSaveJiraMappingLightbox}
        onRemove={onRemove}
        toggleInProgressDialog={toggleSyncInProgressDialog}
        isConfirmSyncInProgressOpen={isConfirmSyncInProgressOpen}
        mappingLayer={mappingLayer}
      />
    );
  };
};

export default container;
