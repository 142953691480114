import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  resetCustomerCount as resetCustomerCountAction,
  resetCustomerImportError as resetCustomerImportErrorAction,
} from 'store/customers/actions';
import { getSalesforceImportError, getTotalSalesforceCustomers } from 'store/customers/selectors';

import useSystemFields from 'hooks/useSystemFields';

import {
  fetchCustomersFromSalesforce,
  importCustomersFromSalesforce as importCustomersFromSalesforceAction,
} from 'store/integrations';
import { getOrgHasSalesforceIntegration, getOrganizationIntegrations } from 'store/organization/selectors';

export default Component => {
  return props => {
    const dispatch = useDispatch();
    const [getSystemFieldName] = useSystemFields();

    const orgIntegrations = useSelector(getOrganizationIntegrations);
    const totalSalesforceCustomers = useSelector(getTotalSalesforceCustomers);
    const hasSalesforceIntegration = useSelector(getOrgHasSalesforceIntegration);

    const selectedOrgIntegration = useMemo(() => {
      return (orgIntegrations || []).find(integration => integration.integrationType === 'salesforce');
    }, [orgIntegrations]);

    const canImportFromSalesforce = useMemo(() => {
      if (hasSalesforceIntegration && selectedOrgIntegration) {
        return true;
      }

      return false;
    }, [hasSalesforceIntegration, orgIntegrations]);

    const errorMessage = useSelector(getSalesforceImportError);

    const getCustomersFromSalesforce = queryId => {
      dispatch(fetchCustomersFromSalesforce(selectedOrgIntegration.id, queryId));
    };

    const importCustomersFromSalesforce = queryId => {
      dispatch(importCustomersFromSalesforceAction(selectedOrgIntegration.id, queryId));
    };

    const resetCustomerCount = () => {
      dispatch(resetCustomerCountAction());
    };

    const resetCustomerImportError = () => dispatch(resetCustomerImportErrorAction());

    return (
      <Component
        {...props}
        getSystemFieldName={getSystemFieldName}
        error={errorMessage}
        getCustomersFromSalesforce={getCustomersFromSalesforce}
        importCustomersFromSalesforce={importCustomersFromSalesforce}
        resetCustomerCount={resetCustomerCount}
        resetCustomerImportError={resetCustomerImportError}
        totalSalesforceCustomers={totalSalesforceCustomers}
        canImportFromSalesforce={canImportFromSalesforce}
      />
    );
  };
};
