import { useCallback } from 'react';
import { without, difference, pluck } from 'ramda';
import { useDispatch } from 'react-redux';

import { bulkAddMetricsToProject, bulkRemoveMetricsFromProject } from 'store/projects/thunks';

const ID_KEY = 'id';
const mapObjectsToIds = pluck(ID_KEY);

/**
 * @function useUpdateProjectMetrics
 *
 * hook that returns actions to update project metrics
 *
 * @return {Object}
 */
const useUpdateProjectMetrics = () => {
  const dispatch = useDispatch();

  const updateProjectMetrics = useCallback(
    (id, newValue, oldValue) => {
      const oldValueIds = mapObjectsToIds(oldValue);
      const newValueIds = mapObjectsToIds(newValue);

      const newMetricsIds = without(oldValueIds, newValueIds);
      const removedMetricsIds = difference(oldValueIds, newValueIds);

      if (newMetricsIds.length) {
        dispatch(bulkAddMetricsToProject(id, newMetricsIds));
      }

      if (removedMetricsIds.length) {
        dispatch(bulkRemoveMetricsFromProject(id, removedMetricsIds));
      }
    },
    [dispatch, bulkAddMetricsToProject, bulkRemoveMetricsFromProject],
  );

  return { updateProjectMetrics };
};

export default useUpdateProjectMetrics;
