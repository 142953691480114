import { PAGES_STATE_TO_SAVE } from './consts';

/**
 * Get the view state based on mapping with store state
 * @param {Object} storeState Complete store state object
 * @param {String} viewPageId Page ID from view
 * @returns {Object} The mapping result between view with store state or emopty object if non existent
 */
const getViewStateFromStoreState = (storeState, viewPageId) => {
  if (!viewPageId || !PAGES_STATE_TO_SAVE[viewPageId]) {
    return {};
  }

  return PAGES_STATE_TO_SAVE[viewPageId](storeState);
};

export { getViewStateFromStoreState };
