import React from 'react';

import PublicPageTemplateComponent from 'src/design-system/templates/PublicPageTemplate/PublicPageTemplateComponent';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';

export default ({ handleEmailValue, handleSubmit, resetPasswordEmail }) => {
  return (
    <PublicPageTemplateComponent>
      <ForgotPasswordForm handleEmailValue={handleEmailValue} handleSubmit={handleSubmit} email={resetPasswordEmail} />
    </PublicPageTemplateComponent>
  );
};
