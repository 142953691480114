import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';

import { isMilestoneTopGroup } from '../../helpers';

import { TimelineCellContent } from '../styled';

import TimelineColumnGroupRow from './TimelineColumnGroupRow';

const NormalSingleGrouping = ({ itemRenderer, data, groupsWidths, onRowClick, groupTitleRenderer }) =>
  data.map((groupLevel1, level1Index) => {
    return (
      <TimelineColumnGroupRow
        key={groupLevel1.key}
        group={groupLevel1}
        groupWidth={groupsWidths[0]}
        onClick={onRowClick([groupLevel1])}
        groupTitleRenderer={groupTitleRenderer}
      >
        {isEmpty(groupLevel1.rows) ? (
          <TimelineCellContent />
        ) : (
          groupLevel1.rows.map((row, rowIndex) => {
            // TODO removes uuid
            // const key = generateSingleGroupRowKey(orders, groupLevel1, rowIndex, groupOffsets, hideEmptyLane);

            return (
              <TimelineCellContent key={uuidv4()}>
                {row.map(item => itemRenderer(item, [level1Index], rowIndex, isMilestoneTopGroup(groupLevel1)))}
              </TimelineCellContent>
            );
          })
        )}
      </TimelineColumnGroupRow>
    );
  });

export default NormalSingleGrouping;
