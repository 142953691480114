import { useMemo } from 'react';
import isFunction from 'lodash/isFunction';
import { filter } from 'ramda';

import { makeProjectFrontendFilters } from 'utils/projects/projectsUtils';

/**
 * @function useProjectsFrontendFilters Receives any number of filter conditions and memoize the valid ones in an array
 * and creates callback to filter items checking all conditions of passed filter
 * @param  {Array} filterFuncs List of filter functions to be considered
 * @return {Object}
 */
const useProjectsFrontendFilters = (...filterFuncs) => {
  const projectsFiltersArray = useMemo(() => filterFuncs.filter(isFunction), [...filterFuncs]);

  const applyFrontendFiltersOnProject = useMemo(
    () => makeProjectFrontendFilters(...projectsFiltersArray),
    [projectsFiltersArray],
  );
  const applyFrontendFilters = useMemo(() => filter(applyFrontendFiltersOnProject), [applyFrontendFiltersOnProject]);

  return { projectsFiltersArray, applyFrontendFiltersOnProject, applyFrontendFilters };
};

export default useProjectsFrontendFilters;
