import { TIMEFRAME_KEY_CORP, TIMEFRAME_KEY } from 'constants/timeframes';

import usePermissions from 'hooks/permissions/usePermissions/usePermissions';
import { PERMISSION_RESOURCES, REFERER_SETTINGS } from '@dragonboat/permissions';

const useMetadataTopLevel = () => {
  const { canView } = usePermissions();

  return {
    timeframes: canView(PERMISSION_RESOURCES.timeframeCorp, { referer: REFERER_SETTINGS }) ? TIMEFRAME_KEY_CORP : TIMEFRAME_KEY,
  };
};

export default useMetadataTopLevel;
