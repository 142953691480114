import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { DEFAULT_PLANNING_STAGE } from 'constants/projectLightbox';

import useProjectsSearch from 'hooks/useProjectsSearch';

import { openObjectiveDrawer as openObjectiveDrawerAction } from 'store/objectives/actions';
import { getDefaultPhaseByPlanningStage } from 'store/phases/selectors';
import { openProjectLightbox as openProjectLightboxAction } from 'store/projectLightbox/actions';
import {
  updateProjectOnGrids as updateProjectOnGridsAction,
  createUnsavedProject as createUnsavedProjectAction,
  removeUnsavedProject as removeUnsavedProjectsAction,
  createProject as createProjectAction,
} from 'store/projects/actions';
import { switchProjectRowOrder as switchProjectRowOrderAction } from 'store/projects';
import { getUnsavedProject } from 'store/projects/selectors';
import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import useCrossAccountRoadmapChange from 'hooks/projects/useCrossAccountRoadmapChange';

import { getProjectKey } from 'utils/projects/getProjectKey';

/**
 * Hook to abstract data and actions related to Projects from the store.
 */
const useProjects = () => {
  const dispatch = useDispatch();

  const unsavedProject = useSelector(getUnsavedProject);

  const hasUnsavedProject = useMemo(() => !!unsavedProject, [unsavedProject]);

  const defaultPhase = useSelector(state => getDefaultPhaseByPlanningStage(state, DEFAULT_PLANNING_STAGE));

  const { isUpdateAnAccountTransfer, transferProjectToOtherAccountFromProjectUpdate } = useCrossAccountRoadmapChange();

  // TODO: add other actions on this bindActionCreators
  const {
    updateProjectOnGridsAction: updateProjectOnGridsBoundAction,
    switchProjectRowOrderAction: switchProjectRowOrderBoundAction,
  } = useMemo(() => {
    return bindActionCreators(
      {
        updateProjectOnGridsAction,
        switchProjectRowOrderAction,
      },
      dispatch,
    );
  }, [dispatch]);

  const updateProjectOnGrids = useCallback(
    (projectId, updatedData) => {
      if (isUpdateAnAccountTransfer(updatedData)) {
        return transferProjectToOtherAccountFromProjectUpdate(projectId, updatedData);
      }

      return updateProjectOnGridsBoundAction(projectId, updatedData);
    },
    [updateProjectOnGridsBoundAction, isUpdateAnAccountTransfer, transferProjectToOtherAccountFromProjectUpdate],
  );

  const createUnsavedProject = useCallback(newProjectData => dispatch(createUnsavedProjectAction(newProjectData)), [dispatch]);

  const removeUnsavedProjects = useCallback(() => dispatch(removeUnsavedProjectsAction()), [dispatch]);

  const createNewProject = useCallback(
    projectData => {
      return dispatch(createProjectAction(projectData));
    },
    [dispatch, removeUnsavedProjects],
  );

  const openProjectInfo = useCallback(
    (projectData, tab) => {
      const key = getProjectKey(projectData);

      return dispatch(openProjectLightboxAction(key, tab));
    },
    [dispatch],
  );

  const openOkrInfo = useCallback(
    (okrId, okrLevel) => {
      return dispatch(openObjectiveDrawerAction(okrId, okrLevel));
    },
    [dispatch],
  );

  const { searchProjectsByTitle } = useProjectsSearch();

  const loadParentProjects = useCallback(async (valueToSearch, layer = IDEA_LAYER) => {
    const parentLayersToSearch = layer === IDEA_LAYER ? [INITIATIVE_LAYER, BET_LAYER] : [BET_LAYER];

    const parentProjects = await searchProjectsByTitle(valueToSearch, {
      overwriteLayers: parentLayersToSearch,
      showCompleted: true,
      loadTree: false,
    });

    return parentProjects.filter(p => parentLayersToSearch.includes(p.layer));
  }, []);
  // NOTE: `searchProjects` should belong to the array of dependencies, but it is not
  //   properly memoized, because it depends on `state` (the whole store state).
  //   It would cause flickering. Not being on the dependencies array should not cause
  //   issues.

  return {
    hasUnsavedProject,
    defaultPhase,

    updateProjectById: updateProjectOnGrids,
    createUnsavedProject,
    removeUnsavedProjects,
    createNewProject,
    openProjectInfo,
    openOkrInfo,
    loadParentProjects,
    switchProjectRowOrder: switchProjectRowOrderBoundAction,
  };
};

export default useProjects;
