import cloneDeep from 'lodash/cloneDeep';
import { UPDATE_MANAGE_STATE } from './types';

const defaultPageState = {
  selectedTasksView: '',
  timelineBasedOn: 'jira-stories',
  searchString: '',
  showTimeframes: false,
  showGroupedTimeline: true,
  showTooltip: true,
  projectSettings: {},
  selectedDisplayColumns: null,
  selectedGroup1: {},
  selectedGroup2: {},
  selectedColorBy: {},
  selectedZoom: {},
  showLegend: false,
  coloredGroups: [],
};

const initialState = {
  manageState: cloneDeep(defaultPageState),
  timelineState: cloneDeep(defaultPageState),
};

export default function manageReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_MANAGE_STATE:
      const { payload } = action;

      return {
        ...state,
        ...{
          [payload.page]: {
            ...state[payload.page],
            ...payload.state,
          },
        },
      };
    default:
      return state;
  }
}
