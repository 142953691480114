import { checkAddColumnVisibility } from 'containers/IdeasList/IdeasList/New/hooks/useIdeasGridColumnsDefinitions/helpers';
import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';

const PARENT_LAYERS = [BET_LAYER, INITIATIVE_LAYER];
const isParentProjectRow = data => data?.layer && PARENT_LAYERS.includes(data?.layer);

/**
 * @function checkAddChildrenButtonVisibility
 *
 * Checks if add children button should be visible
 *
 * @param  {Object} params
 * @return {Boolean}
 */
const checkAddChildrenButtonVisibility = params => {
  const { data } = params;

  /*
   * On Forecast List the hearchy mode is not active, for that reason
   * the user should not be able to add children projects (bets / initiatives)
   */
  if (isParentProjectRow(data)) {
    return false;
  }

  return checkAddColumnVisibility(params);
};

export default checkAddChildrenButtonVisibility;
