import updateRows from 'store/utils/updateRows';

export default (state, updatedRoadmapHistorySnapshot) => {
  const { user_view_id: userViewId } = updatedRoadmapHistorySnapshot;
  const snapshotsForUserView = state.snapshotsListByUserView[userViewId] || [];

  const snapshotsListByUserView = {
    ...state.snapshotsListByUserView,
    [userViewId]: updateRows(snapshotsForUserView, updatedRoadmapHistorySnapshot),
  };

  return {
    ...state,
    snapshotsListByUserView,
  };
};
