import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { pluck } from 'ramda';

import { BASE_ROW_HEIGHT } from 'constants/grid';

import { useCommonGridProps, useCommonGridEventHandlers } from 'design-system/molecules/AgGridReact-New/hooks';
import {
  sumNumericFields,
  sumScopeVarianceField,
  sumPointsVarianceField,
} from 'design-system/molecules/AgGridReact-New/aggFuncs';

import useLoginInfo from 'hooks/useLoginInfo';
import usePageFilters from 'hooks/filters/usePageFilters';
import useOrganizations from 'hooks/useOrganizations';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useCompareRoadmapVersionsColumnsDefinitions from './hooks/useCompareRoadmapVersionsColumnsDefinitions';
import useCompareRoadmapVersionsGrid from './hooks/useCompareRoadmapVersionsGrid';
import useAdditionalMetadataDropdownOptions from 'hooks/metadata/useAdditionalMetadataDropdownOptions';

import { BaseGrid } from 'containers/Grids';
import { ID_KEY, GRID_HEIGHT } from 'containers/IdeasList/IdeasList/New/constants';
import { getDataPath, getRowId, getRowStyle, checkRowSelectable } from 'containers/IdeasList/IdeasList/New/helpers';
import { useMetadata, useProjectsOnGrids, useSystemFields } from 'containers/IdeasList/IdeasList/New/hooks';
import { genericStyles } from 'containers/IdeasList/IdeasList/New/styled';
import useIdeasGridRowDrag from 'containers/IdeasList/IdeasList/New/hooks/useIdeasGridRowDrag';
import { getHideRowsWithSameData, getSelectedRoadmapVersionsIdsToCompare } from 'store/roadmapVersions/selectors';
import useRoadmapVersions from 'hooks/useRoadmapVersions';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';
import useCompareRoadmapVersionsGridState from 'routes/RoadmapVersions/hooks/useCompareRoadmapVersionsGridState';

const getIds = pluck('id');

const CompareScenariosGrid = ({ viewType, onGridReady: parentHandleGridReady, onConvertToPlanOfRecordHeaderClicked }) => {
  const { getSystemFieldName } = useSystemFields();

  const { displayLayer, portfolioMode, searchString: pageFiltersSearchString } = usePageFilters();
  const selectedRoadmapVersionsIds = useSelector(getSelectedRoadmapVersionsIdsToCompare);
  const hideRowsWithSameData = useSelector(getHideRowsWithSameData);
  const { roadmapVersions, isFetchMultipleRoadmapVersionsProjectsLoading, isRoadmapVersionsLoading } = useRoadmapVersions();
  const hasLoadingsOngoing = isFetchMultipleRoadmapVersionsProjectsLoading || isRoadmapVersionsLoading;
  const { currentUser } = useLoginInfo();

  const {
    organization,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    filters: { topLayer },
    customUserFields,
    integrations,
    customFields,
    hasMetadataMultiSelect,
  } = useOrganizations();

  const { isParentDragon } = useOrganizationsAccessControl();

  const additionalMetadataOptions = useAdditionalMetadataDropdownOptions();

  const {
    hasUnsavedProject,
    defaultPhase,

    updateProjectById,
    createUnsavedProject,
    removeUnsavedProjects,
    createNewProject,
    openProjectInfo,
    openOkrInfo,
    loadParentProjects,
    switchProjectRowOrder,
  } = useProjectsOnGrids();

  const {
    rowHeight,
    expandedGroups,
    isGrouping,
    searchString,
    isBulkDeleting,
    visibleColumnsIds,
    columnsState,
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    shouldGridBeUnmounted,

    saveGridConfig,
    setSelectedItems,
    saveColumnsState,
    removeGridRow,
    setDirtyViewMutex,
  } = useCompareRoadmapVersionsGridState(viewType);

  const {
    processedData,

    getGridIsReady,
    getGridApi,

    handleGridReady,
    handleRowGroupOpened,
    handleSelectionChanged,
    handleDisplayedColumnsChanged,
    handleOnColumnResized,
    checkIsGroupOpenByDefault,
    addNewInlineProject,
    openRowInfo,
    updateProjectByIdOnCellValueChange,
    loadParentProjectsOptions,
    handleMetadataCellDoubleClick,
  } = useCompareRoadmapVersionsGrid({
    parentHandleGridReady,
    displayLayer,
    portfolioMode,
    currentUser,
    hasBet,
    defaultPhase,
    expandedGroups,
    visibleColumnsIds,
    columnsState,
    viewType,
    hasOtherStoreUpdates: hasLoadingsOngoing,
    shouldGridBeUnmounted,
    hideRowsWithSameData,

    saveGridConfig,
    getSystemFieldName,
    createUnsavedProject,
    openProjectInfo,
    openOkrInfo,
    setSelectedItems,
    updateProjectById,
    loadParentProjects,
    saveColumnsState,
  });

  const { metadata, filteredMetadata, checkUserCanCreateNewMetadata, createMetadataMultiSelectOption } = useMetadata(
    processedData,
    {
      currentUser,
      organization,
    },
  );

  const { columnDefs, defaultColDef, groupColumnDef } = useCompareRoadmapVersionsColumnsDefinitions({
    portfolioMode,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    topLayer,
    displayLayer,
    rowHeight,
    metadata,
    filteredMetadata,
    createMetadataMultiSelectOption,
    visibleColumnsIds,
    titleSearchString: searchString || pageFiltersSearchString,
    customUserFields,
    columnsState,
    integrations,
    currentUser,
    customFields,
    hasMetadataMultiSelect,

    getSystemFieldName,
    addNewInlineProject,
    openProjectInfo,
    openRowInfo,
    loadParentProjectsOptions,
    checkUserCanCreateNewMetadata,
    updateProjectById,
    handleMetadataCellDoubleClick,
    removeGridRow,
    onConvertToPlanOfRecordHeaderClicked,
  });

  const commonGridProps = useCommonGridProps({
    isTreeView: true,
    aggFuncs: { sumNumericFields, sumScopeVarianceField, sumPointsVarianceField },
  });

  const { handleRowDrag } = useIdeasGridRowDrag({
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    switchProjectRowOrder,
    updateProjectById,
  });

  const commonGridEvents = useCommonGridEventHandlers({
    items: processedData,
    idKey: ID_KEY,
    actions: {
      update: updateProjectByIdOnCellValueChange,
      save: createNewProject,
      remove: removeUnsavedProjects,
      drag: handleRowDrag,
      sortChanged: handleDisplayedColumnsChanged,
    },
  });

  const sortedSelectedRoadmapVersionsIds = useMemo(
    () => getIds(roadmapVersions).filter(roadmapVersionId => selectedRoadmapVersionsIds.includes(String(roadmapVersionId))),
    [roadmapVersions, selectedRoadmapVersionsIds],
  );

  const gridContext = useMemo(
    () => ({
      hasUnsavedProject,
      isParentDragon,
      portfolioMode,
      isGrouping,
      isBulkDeleting,
      processedData,
      sortedSelectedRoadmapVersionsIds,
      parents: metadata.parents,
      metadata,
      filteredMetadata,
      additionalMetadataOptions,
    }),
    [
      hasUnsavedProject,
      isParentDragon,
      portfolioMode,
      isGrouping,
      isBulkDeleting,
      processedData,
      sortedSelectedRoadmapVersionsIds,
      metadata,
      filteredMetadata,
      additionalMetadataOptions,
    ],
  );

  useEffect(() => {
    if (getGridIsReady()) {
      getGridApi().resetRowHeights();
    }
  }, [rowHeight]);

  useEffect(() => {
    // Cleanup selected items if stopped bulk deleting.
    if (!isBulkDeleting && getGridIsReady()) {
      getGridApi().deselectAll();
    }
  }, [isBulkDeleting]);

  if (shouldGridBeUnmounted) {
    return <PageLoading />;
  }

  return (
    <BaseGrid
      // Common props:
      {...commonGridProps}
      {...commonGridEvents}
      maintainColumnOrder={false}
      // General props:
      onGridReady={handleGridReady}
      onFirstDataRendered={setDirtyViewMutex}
      rowData={processedData}
      context={gridContext}
      isGroupOpenByDefault={checkIsGroupOpenByDefault}
      isRowSelectable={checkRowSelectable}
      rowSelection="multiple"
      suppressRowClickSelection
      // Getters/Accessors:
      getRowId={getRowId}
      getDataPath={getDataPath}
      // Styling:
      cssStyles={genericStyles}
      getRowStyle={getRowStyle}
      height={GRID_HEIGHT}
      rowHeight={rowHeight}
      headerHeight={BASE_ROW_HEIGHT}
      // Columns:
      defaultColDef={defaultColDef}
      autoGroupColumnDef={groupColumnDef}
      columnDefs={columnDefs}
      // Events:
      onRowGroupOpened={handleRowGroupOpened}
      onSelectionChanged={handleSelectionChanged}
      onDisplayedColumnsChanged={handleDisplayedColumnsChanged}
      onColumnResized={handleOnColumnResized}
    />
  );
};

export default CompareScenariosGrid;
