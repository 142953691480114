import { createSelector } from 'reselect';
import { prop } from 'ramda';

import getSystemFieldName from 'utils/getSystemFieldName';
import { DEFAULT_GROUP_OPTION, getGroupOptions, getGroupOptionByKey } from 'store/projects/helpers/groupOptions';

import {
  selectHasHierarchy,
  getOrgHasBet,
  selectHasKeyResults,
  selectHasKeyResults2,
  selectHasProducts,
  hasMultiLevelPortfolioMetadata as hasMultiLevelPortfolioMetadataSelector,
  getOrganizationSystemFieldsNames,
  getOrgCustomUserProjectFields,
  getOrgHasCustomUserProjectFields,
} from 'store/organization/selectors';
import { getDisplayLayer, usePortfolioMode } from 'store/filters/selectors';
import { getDropdownCustomFields } from 'store/customFields/selectors';
import { getObjectiveGroupOption } from '../projects/helpers/groupOptions';
import { getIsDodActive } from '../accessControl/selectors';

import { PORTFOLIO_SUMMARY } from 'constants/summary';

export const getSlice = (state, slice = PORTFOLIO_SUMMARY) => prop(slice, state?.summary);
export const getSliceProp =
  attribute =>
  (state, slice = PORTFOLIO_SUMMARY) =>
    prop(attribute, getSlice(state, slice));

export const selectExpandedGroups = createSelector(getSliceProp('expandedGroups'), expandedGroups => expandedGroups);

export const selectIsReadOnly = createSelector(getSliceProp('isReadOnly'), isReadOnly => isReadOnly);

export const selectHeaderColor = createSelector(getSliceProp('headerColor'), headerColor => headerColor);

export const selectDisplayPreferences = createSelector(
  getSliceProp('displayPreferences'),
  displayPreferences => displayPreferences,
);

export const selectSearchStr = createSelector(getSliceProp('searchStr'), searchStr => searchStr);

export const selectHideEmptyLanes = createSelector(getSliceProp('hideEmptyLanes'), hideEmptyLanes => hideEmptyLanes);

export const selectShowMyItemsOnly = createSelector(getSliceProp('showMyItemsOnly'), hideEmptyLanes => hideEmptyLanes);

export const selectAllCollapsed = createSelector(getSliceProp('allCollapsed'), allCollapsed => allCollapsed);

export const selectColsWidth = createSelector(getSliceProp('colsWidth'), colsWidth => colsWidth);

export const selectColsOrder = createSelector(getSliceProp('colsOrder'), colsOrder => colsOrder);

export const selectRowsOrder = createSelector(
  (state, slice = PORTFOLIO_SUMMARY) => prop(slice, state.summary)?.rowsOrder,
  rowsOrder => rowsOrder ?? [],
);

export const selectGroupDates = createSelector(
  getSliceProp('groupDatesBy'),
  getSliceProp('dateFieldToGroup'),
  (groupDatesBy, dateFieldToGroup) => ({
    groupDatesBy,
    dateFieldToGroup,
  }),
);

const EMPTY_OBJECT = {};

export const selectSelectedGroupOptions = createSelector(
  getSliceProp('selectedColData'),
  getSliceProp('selectedRowData'),
  getSliceProp('groupByData'),
  selectGroupDates,
  usePortfolioMode,
  getDisplayLayer,
  getDropdownCustomFields,
  selectHasHierarchy,
  getOrgHasBet,
  selectHasKeyResults,
  selectHasKeyResults2,
  selectHasProducts,
  hasMultiLevelPortfolioMetadataSelector,
  getIsDodActive,
  getOrganizationSystemFieldsNames,
  state => (getOrgHasCustomUserProjectFields(state) ? getOrgCustomUserProjectFields(state) : EMPTY_OBJECT),
  (_, __, getGroupOptionsMethods = { getGroupOptions, getGroupOptionByKey }) => getGroupOptionsMethods,
  (
    selectedColData,
    selectedRowData,
    groupByData,
    groupDates,
    portfolioMode,
    displayLayer,
    dropdownCustomFields,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasMultiLevelPortfolioMetadata,
    isDodActive,
    systemFieldsNames,
    customUserFields,
    { getGroupOptions, getGroupOptionByKey },
  ) => {
    const { dateFieldToGroup, groupDatesBy } = groupDates;

    const customGetSystemFieldName = name => getSystemFieldName(name, systemFieldsNames, false);

    const objectiveGroupOption = getObjectiveGroupOption(customGetSystemFieldName);

    const groupOptions = getGroupOptions({
      getSystemFieldName: customGetSystemFieldName,
      hasKeyResults,
      hasKeyResults2,
      hasProducts,
      hasHierarchy,
      hasBet,
      hasMultiLevelPortfolioMetadata,
      isDodActive,
      portfolioMode,
      displayLayer,
      dropdownCustomFields,
      withNullOption: true,
      withTags: true,
      withCustomers: true,
      customUserFields,
      dynamicDates: { field: dateFieldToGroup?.key, groupBy: groupDatesBy?.key },
    });

    return {
      selectedColOption: getGroupOptionByKey(selectedColData?.key, groupOptions, objectiveGroupOption),
      selectedRowOption: getGroupOptionByKey(selectedRowData?.key, groupOptions, objectiveGroupOption),
      selectedGroupByOption: getGroupOptionByKey(groupByData?.key ? groupByData.key : null, groupOptions, DEFAULT_GROUP_OPTION),
    };
  },
);
