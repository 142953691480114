import React, { useEffect, useState } from 'react';

import Dialog from 'design-system/molecules/Dialog/index';
import DialogActions from 'design-system/molecules/DialogActions/index';

import AnnouncementEditor from './AnnouncementEditor';

export default function AnnouncementLightbox({
  isOpen,
  onClose,
  onDeleteAnnouncement,
  onSaveAnnouncement,
  selectedAnnouncement,
  isAdmin,
}) {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (!isOpen) setValue('');
  }, [isOpen]);

  return (
    <>
      <Dialog open={isOpen} closeButton title="Announcements" onClose={onClose} fullWidth>
        <AnnouncementEditor
          handleValueChange={e => setValue(e.target.value)}
          value={selectedAnnouncement ? selectedAnnouncement?.message : value}
          isAdmin={isAdmin}
        />
        {isAdmin && <DialogActions onCancel={onClose} onDelete={onDeleteAnnouncement} onSave={() => onSaveAnnouncement(value)} />}
      </Dialog>
    </>
  );
}
