import { createSelector } from 'reselect';
import { head, last, not } from 'ramda';

import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import { FeatureFlags } from '@dragonboat/config';
import { BET, IDEA, INITIATIVE } from 'constants/projects';

import getSystemFieldName from 'utils/getSystemFieldName';

import { getIsParentDragon } from 'store/accessControl/selectors';
import { getOrganizationSystemFieldsNames } from 'store/organization';

import { checkOrganizationFlags } from 'hooks/useFeatureFlags';

export const getProjectLayers = createSelector(
  [state => state.organization.organization, getOrganizationSystemFieldsNames, getIsParentDragon],
  (organization, systemFieldsNames, isParentDragon) => {
    const hasHiddenIdeasLayerAndStories = checkOrganizationFlags(organization, [FeatureFlags.HAS_HIDDEN_IDEAS_LAYER_AND_STORIES]);
    const hasBet = checkOrganizationFlags(organization, [FeatureFlags.HAS_BET]);

    const hasIdea = not(isParentDragon && hasHiddenIdeasLayerAndStories);

    const layers = [...(hasIdea ? [IDEA_LAYER] : []), INITIATIVE_LAYER, ...(hasBet ? [BET_LAYER] : [])];

    const layerOptions = [
      ...(hasIdea ? [{ key: IDEA_LAYER, value: getSystemFieldName(IDEA, systemFieldsNames, false) }] : []),
      { key: INITIATIVE_LAYER, value: getSystemFieldName(INITIATIVE, systemFieldsNames, false) },
      ...(hasBet ? [{ key: BET_LAYER, value: getSystemFieldName(BET, systemFieldsNames, false) }] : []),
    ];

    return {
      layers,
      layerOptions,
      hasBet,
      hasInitiative: true,
      hasIdea,
      bottomLayer: head(layers),
      topLayer: last(layers),
    };
  },
);
