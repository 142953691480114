import { pipe, keys, map } from 'ramda';

import { defaultIssueTypeMapping } from '../contants/issueTypes';

const STATIC_LAYER_NAMES = {
  0: 'idea',
  1: 'initiative',
  2: 'bet',
};

const getIssueTypeMapping = layer => {
  return STATIC_LAYER_NAMES[layer];
};

export const issueTypeNames = pipe(keys, map(getIssueTypeMapping))(defaultIssueTypeMapping);
