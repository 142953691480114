import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Comments from 'components/Comments';
import FormLabel from 'design-system/atoms/FormLabel/index';


import {
  saveObjectiveComment,
  fetchObjectiveComments,
  fetchNextObjectiveComments,
  deleteObjectiveComment,
  editObjectiveComment,
  saveKeyResultComment,
  fetchKeyResultComments,
  fetchNextKeyResultComments,
  deleteKeyResultComment,
  editKeyResultComment,
} from 'store/comments/thunks';
import { METADATA_LEVELS } from 'constants/common';

const arrayIsEmpty = array => (array || []).length === 0;

const isObjectiveLevel = okr => okr && !okr.objective_id;
const isKeyResultLevel = okr => okr && okr.objective_id;
const isCorpLevel = okr => okr?.level === METADATA_LEVELS.LEVEL_CORP;

const getOkrLevel = okr => {
  if (isObjectiveLevel(okr) || isCorpLevel(okr)) {
    return 'objective';
  } else if (isKeyResultLevel(okr)) {
    return 'keyResult';
  }
};

export default ({ okr }) => {
  const dispatch = useDispatch();

  const commentsState = useSelector(state => state.comments);

  const okrId = okr ? okr.id : null;

  const getComments = {
    objective: commentsState.objectives?.get(JSON.stringify(okrId)) || {},
    keyResult: commentsState.keyResults?.get(JSON.stringify(okrId)) || {},
  };

  const comments = getComments[getOkrLevel(okr)];

  const hasMoreComments = comments && comments._meta && !!comments._meta.next;

  const commentsData = comments && comments.data;

  const containerHeight = arrayIsEmpty(commentsData) ? '175px' : '325px';

  const _save = ({ text, mentions, editorState, integrationType, channel }) => {
    let blocks = editorState.blocks; // eslint-disable-line

    const firstRealIndex = blocks.findIndex(s => s.text.length);

    blocks = blocks.slice(firstRealIndex);

    const lastRealIndex =
      blocks.length -
      blocks
        .slice()
        .reverse()
        .findIndex(s => s.text.length);

    blocks = blocks.slice(0, lastRealIndex);
    editorState.blocks = blocks;

    const actions = {
      objective: () =>
        dispatch(saveObjectiveComment(okr.id, { text: text.trim(), mentions, editorState, integrationType, channel })),
      keyResult: () =>
        dispatch(saveKeyResultComment(okr.id, { text: text.trim(), mentions, editorState, integrationType, channel })),
    };

    actions[getOkrLevel(okr)]();
  };

  const _fetchNext = () => {
    if (okr) {
      const actions = {
        objective: () => dispatch(fetchNextObjectiveComments(okr.id)),
        keyResult: () => dispatch(fetchNextKeyResultComments(okr.id)),
      };

      actions[getOkrLevel(okr)]();
    }
  };

  const onDeleteCommentHandler = useCallback(
    commentId => {
      if (okr) {
        const actions = {
          objective: () => dispatch(deleteObjectiveComment(okr.id, commentId)),
          keyResult: () => dispatch(deleteKeyResultComment(okr.id, commentId)),
        };

        actions[getOkrLevel(okr)]();
      }
    },
    [okr],
  );

  const onEditCommentHandler = useCallback(
    (commentId, data) => {
      if (okr) {
        const actions = {
          objective: () => dispatch(editObjectiveComment(okr.id, commentId, data)),
          keyResult: () => dispatch(editKeyResultComment(okr.id, commentId, data)),
        };

        return actions[getOkrLevel(okr)]();
      }
    },
    [okr],
  );

  useEffect(() => {
    if (okr) {
      const actions = {
        objective: () => dispatch(fetchObjectiveComments(okr.id)),
        keyResult: () => dispatch(fetchKeyResultComments(okr.id)),
      };

      actions[getOkrLevel(okr)]();
    }
  }, []);

  return (
    <CommentsSectionContainer containerHeight={containerHeight}>
      <FormLabel>Comment</FormLabel>

      <Comments
        comments={commentsData || []}
        onSave={_save}
        hasMoreComments={!!hasMoreComments}
        showMore={_fetchNext}
        onDelete={onDeleteCommentHandler}
        onEdit={onEditCommentHandler}
      />
    </CommentsSectionContainer>
  );
};

const CommentsSectionContainer = styled.div`
  margin-top: 30px;
  padding-left: 8px;
  min-height: ${props => props.containerHeight};
  overflow: auto;
  width: 100%;

  > div:nth-child(2) {
    color: ${props => props.theme.palette.text.primary};
  }
`;
