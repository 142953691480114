import { useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { ROADMAP_PAGE } from 'constants/filters';

import {
  isCreateProjectLoading,
  isDeleteProjectLoading,
  isLoadingProjects,
  isApplyFiltersUninitialized,
  isUpdateProjectError,
  isUpdateProjectLoading,
  resetProjectUpdate,
  updateProject,
} from 'store/projects';
import { openProjectLightbox } from 'store/projectLightbox';
import { getDisplayLayer, makeSelectFilteredProjectsByRoadmapForPage } from 'store/filters/selectors';

import useAppNotifications from 'hooks/useAppNotifications';
import useDebounceSelector from 'hooks/useDebounceSelector';

import { FILTERED_PROJECTS_SELECTOR_TIMEOUT, PROJECT_UPDATE_ERROR_ID, PROJECT_UPDATE_ERROR_MESSAGE } from '../helpers';

const getFilteredProjectsForPage = makeSelectFilteredProjectsByRoadmapForPage();

/**
 * Custom hook responsible to retrieve projects information and actions to act on them.
 * */
const useSwimlaneProjects = () => {
  const dispatch = useDispatch();

  const { addError } = useAppNotifications();

  // Data

  const displayLayer = useSelector(getDisplayLayer);
  const isUninitializedApplyingFilters = useSelector(isApplyFiltersUninitialized);
  const isFetchingProjects = useSelector(isLoadingProjects);
  const isCreatingProject = useSelector(isCreateProjectLoading);
  const isUpdatingProject = useSelector(isUpdateProjectLoading);
  const isDeletingProject = useSelector(isDeleteProjectLoading);
  const hasProjectUpdateError = useSelector(isUpdateProjectError);

  const projects = useDebounceSelector(
    state => getFilteredProjectsForPage(state, ROADMAP_PAGE, false, displayLayer),
    isEqual,
    FILTERED_PROJECTS_SELECTOR_TIMEOUT,
  );

  const hasGlobalLoading = isFetchingProjects || isUninitializedApplyingFilters;
  const isProjectLoading = isCreatingProject || isUpdatingProject || isDeletingProject;

  // Actions

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          updateProject,
          resetProjectUpdate,
          openProjectLightbox,
        },
        dispatch,
      ),
    [dispatch],
  );

  useEffect(() => {
    if (hasProjectUpdateError) {
      addError({ id: PROJECT_UPDATE_ERROR_ID, message: PROJECT_UPDATE_ERROR_MESSAGE });

      const { resetProjectUpdate } = boundActionCreators;

      resetProjectUpdate();
    }
  }, [dispatch, hasProjectUpdateError, addError]);

  return {
    projects,
    hasGlobalLoading,
    isProjectLoading,
    hasProjectUpdateError,
    displayLayer,
    ...boundActionCreators,
  };
};

export default useSwimlaneProjects;
