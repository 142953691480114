/**
 * Build the link for given filter
 *
 * @function buildFilterLink
 * @param  {String} filterKey
 * @param  {String} origin
 * @return {String}
 */
const buildFilterLink = (filterKey, origin = window.location.origin) => {
  return `${origin}/userFilters?key=${filterKey}`;
};

export { buildFilterLink };
