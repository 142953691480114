import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { equals, isNil, not, pipe } from 'ramda';

import ToastMessage from 'design-system/atoms/ToastMessage/index';


import { NotificationsCreator } from 'hooks/useAppNotifications/NotificationsCreator';
import useAppNotifications from 'hooks/useAppNotifications';

const notificationSystems = {
  TOAST: 'toast',
};

const isNotNil = pipe(isNil, not);

/**
 * @function makeExcludeActiveNotificationsFilter
 *
 * Creates a filter function to ignore the creation of new nofitications if the
 * a given notitication id is already being presented to the user (is active).
 *
 * If a given notification doesn't have id, ignore the is active logic.
 *
 * @param {Array} activeNotifications
 * @returns {Function}
 */
const makeExcludeActiveNotificationsFilter =
  (activeNotifications = []) =>
  notification =>
    isNil(notification?.id) || not(activeNotifications.includes(notification?.id));

const NotificationsWrapper = ({ children }) => {
  const [activeNotifications, setActiveNotifications] = useState([]);

  const { removeNotification, notifications } = useAppNotifications();

  useEffect(() => {
    notifications.filter(makeExcludeActiveNotificationsFilter(activeNotifications)).forEach(notification => {
      const notificationCreated = NotificationsCreator.createNotification(notification);

      if (notificationCreated) {
        if (notificationCreated.getSystem() === notificationSystems.TOAST) {
          toast(<ToastMessage title={notificationCreated.getMessage()} type={notificationCreated.getType()} />, {
            toastId: notification.id,
            onClose: () => {
              if (isNotNil(notification.id)) {
                const notEqualsCurrentNotification = pipe(equals(notification.id), not);

                setActiveNotifications(currentValue => currentValue.filter(notEqualsCurrentNotification));
              }

              removeNotification(notification.id);
            },
          });

          if (isNotNil(notification.id)) {
            setActiveNotifications(currentValue => [...currentValue, notification.id]);
          }
        }
      }
    });
  }, [activeNotifications, notifications]);

  return children;
};

export default NotificationsWrapper;
