import React from 'react';
import styled from 'styled-components';

import TopBar from 'containers/LinkedPortfolioItems/components/TopBar';
import Grid from 'containers/LinkedPortfolioItems/components/Grid';

import useMetricLinkedPortfolioItems from './hooks/useMetricLinkedPortfolioItems';

const LinkedPortfolioItems = ({ entity, openProjectsListLightbox }) => {
  const {
    linkedItems,
    isLoadingLinkedItems,
    isLoadingLinkProjectToMetric,
    isLoadingUnlinkProjectToMetric,
    isLoadingCreateProjectFromMetric,
    linkProjectToEntity,
    unlinkProjectFromEntity,
    addNewLinkedItemRow,
    removeUnsavedLinkedItemsRows,
    onClickCreate,
  } = useMetricLinkedPortfolioItems(entity);

  const isLoadingGrid = isLoadingLinkProjectToMetric || isLoadingUnlinkProjectToMetric || isLoadingCreateProjectFromMetric;

  return (
    <Container>
      <TopBar
        items={linkedItems}
        onClickLink={addNewLinkedItemRow}
        onClickCreate={onClickCreate}
        onClickExpand={() => openProjectsListLightbox(entity)}
        isLoading={isLoadingLinkedItems || isLoadingGrid}
      />
      <Grid
        items={linkedItems}
        isLoadingItems={isLoadingLinkedItems}
        isLoadingGrid={isLoadingGrid}
        linkProjectToEntity={linkProjectToEntity}
        unlinkProjectFromEntity={unlinkProjectFromEntity}
        removeUnsavedRows={removeUnsavedLinkedItemsRows}
      />
    </Container>
  );
};

export default LinkedPortfolioItems;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
