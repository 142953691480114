import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { spacing } from 'design-system/theme';
import { addQueryParamToUrl } from 'utils/queryParamsUtils';
import { ADD_FILTER_TITLE, OPEN_ADVANCED_SEARCH } from '../../../constants/queryParams';

const ADVANCED_SEARCH_LABEL = 'Advanced Search';
const TRUE_KEY = 'true';

const GlobalSearchPopoverFooter = ({ closeTree, inputValue }) => {
  const handleClickAdvancedSearch = () => {
    closeTree();

    addQueryParamToUrl(OPEN_ADVANCED_SEARCH, TRUE_KEY);
    addQueryParamToUrl(ADD_FILTER_TITLE, inputValue);
  };

  return (
    <Wrapper tabindex="0" onClick={handleClickAdvancedSearch}>
      {ADVANCED_SEARCH_LABEL}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-block;
  padding: ${spacing(1.25)}px ${spacing(2.5)}px;
  font-size: ${({ theme }) => theme.typography.fontSize}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

GlobalSearchPopoverFooter.propsTypes = {
  closeTree: PropTypes.func.isRequired,
};

export default GlobalSearchPopoverFooter;
