/**
 * EditSystemSettingName Organism Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import InlineTextField from 'design-system/atoms/InlineTextField/index';

import EditIcon from '@material-ui/icons/Edit';

export default class EditSystemSettingName extends PureComponent {
  state = {
    editing: false,
    rename: null,
  };

  constructor(props) {
    super(props);

    this.state.rename = props.renamedTitle;
  }

  componentWillReceiveProps(nextProps) {
    const { editing } = this.state;

    if (!editing && isEqual(this.props.renamedTitle, nextProps.renamedTitle)) {
      this.setState({ rename: nextProps.renamedTitle });
    }
  }

  componentDidUpdate() {
    if (this.state.editing) {
      document.addEventListener('click', this.handleClickOutside, true);
    } else {
      document.removeEventListener('click', this.handleClickOutside, true);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    /* eslint-disable */
    const nodeInputContainer = ReactDOM.findDOMNode(this.inputContainer);
    /* eslint-enable */

    const outsideInput = !nodeInputContainer || !nodeInputContainer.contains(event.target);

    if (outsideInput) {
      this.cancelEditName();
    }
  };

  editName = () => {
    this.setState({ editing: true });
    setTimeout(() => {
      this.input.focus();
      this.input.select();
    }, 100);
  };

  cancelEditName = () => {
    const { field, onRenameField, renamedTitle, defaultTitle } = this.props;

    this.setState({ editing: false }, () => {
      if (!isEqual(renamedTitle, this.state.rename)) {
        const value = { key: field, defaultTitle, title: this.state.rename };

        onRenameField(value);
      }
      setTimeout(() => window.getSelection().empty(), 100);
    });
  };

  render() {
    const { editing, rename } = this.state;
    const { defaultTitle, canEdit = true } = this.props;

    return (
      <Wrapper>
        <Field>
          <TextDeprecated>Rename {defaultTitle} Field to</TextDeprecated>
        </Field>
        <Field value={rename}>
          <EditNameText
            disabled={!canEdit}
            edit={editing}
            onClick={() => (!editing && canEdit ? this.editName() : undefined)}
            ref={n => {
              this.inputContainer = n;
            }}
            data-cy="rename-system-field"
          >
            <InlineTextField
              disabled={!editing || !canEdit}
              onChange={e => {
                this.setState({ rename: e.target.value });
              }}
              onKeyDown={e => (e.keyCode === 13 ? this.cancelEditName() : undefined)}
              value={rename}
              ref={n => {
                this.input = n;
              }}
            />
            {!editing && canEdit && (
              <EditName onClick={this.editName}>
                <EditIcon />
              </EditName>
            )}
          </EditNameText>
        </Field>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

// prettier-ignore
const getWidth = props => `${props.value ? (props.value.length * 8) + 40 : 100}px`;

const Field = styled.div`
  display: flex;
  height: 36px;
  justify-content: center;
  align-items: center;

  textarea {
    height: 24px;
    width: ${getWidth};
    color: #6b6b6b;
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    line-height: ${({ theme }) => theme.typography.lineHeightRegularLargeRem}rem;
    margin-left: 2px;
    padding: 4px 4px;
  }
`;

const EditNameText = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: bold;

  ${({ disabled }) =>
    disabled &&
    `
    textarea{
      cursor: inherit!important;
    }
  `}

  button {
    opacity: 0;
    transition: all 0.5s;
  }

  &:hover {
    button {
      opacity: 1;
    }
  }
`;

const EditName = styled(ButtonIcon)`
  &&&& {
    position: absolute;
    right: 0;
    top: 0;
    padding: 6px;
    color: #a8a1a1;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;
