import React from 'react';
import invert from 'lodash/invert';
import { defaultTo, not } from 'ramda';

import FluidDateField from 'design-system/organisms/FluidDateField/index';
import FluidNumberField from 'design-system/organisms/FluidNumberField/index';
import FluidMultiSelectField from 'design-system/organisms/FluidMultiSelectField/index';
import FluidSelectField from 'design-system/organisms/FluidSelectField/index';
import FluidSwitchField from 'design-system/organisms/FluidSwitchField/index';
import FluidTextField from 'design-system/organisms/FluidTextField/index';
import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';
import { MAX_HEIGHT } from 'design-system/molecules/RemirrorWYSIWYGEditor/constants';

import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';
import { NONE_PLACEHOLDER } from 'constants/common';

const CustomFieldNumberField = props => <FluidNumberField {...props} placeholder="" readingPlaceholder="" format="row" />;
const CustomFieldYesNoField = props => <FluidSwitchField {...props} readingPlaceholder={NONE_PLACEHOLDER} format="row" />;
const CustomFieldDateField = props => <FluidDateField {...props} placeholder="" readingPlaceholder="" format="row" />;
const CustomFieldShortTextField = props => (
  <FluidTextField
    {...props}
    readingPlaceholder={NONE_PLACEHOLDER}
    rowsMax="2"
    multiline
    useEllipsis
    showTooltip
    format="column"
  />
);
const CustomFieldRichTextField = ({ value, ...props }) => (
  <FluidRemirrorWYSIWYGEditor
    {...props}
    content={value}
    placeholder={NONE_PLACEHOLDER}
    maxHeight={MAX_HEIGHT}
    smallInput
    format="column"
  />
);
const CustomFieldFormulaField = props => (
  <FluidTextField {...props} readingPlaceholder="" placeholder="" disableEdit showTooltip format="row" />
);
const CustomFieldDropdownField = props => (
  <FluidSelectField {...props} placeholder={NONE_PLACEHOLDER} readingPlaceholder={NONE_PLACEHOLDER} format="row" />
);
const CustomFieldMultiSelectField = props => (
  <FluidMultiSelectField {...props} readingPlaceholder={NONE_PLACEHOLDER} format="row" />
);

// TODO: we should be using a props mapper to better support this
// In addition, this should be moved to a hook to account for all the field-specific logic
const customFieldTypesToComponent = {
  [TYPES_OF_CUSTOM_FIELDS.NUMBER]: CustomFieldNumberField,
  [TYPES_OF_CUSTOM_FIELDS.YES_NO]: CustomFieldYesNoField,
  [TYPES_OF_CUSTOM_FIELDS.DATE]: CustomFieldDateField,
  [TYPES_OF_CUSTOM_FIELDS.SHORT_TEXT]: CustomFieldShortTextField,
  [TYPES_OF_CUSTOM_FIELDS.RICH_TEXT]: CustomFieldRichTextField,
  [TYPES_OF_CUSTOM_FIELDS.FORMULA]: CustomFieldFormulaField,
  [TYPES_OF_CUSTOM_FIELDS.DROPDOWN]: CustomFieldDropdownField,
  [TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN]: CustomFieldMultiSelectField,
};

const defaultToEmptyArray = defaultTo([]);
const defaultToEmptyObject = defaultTo({});

export default (formData, customFields, _checkFieldDisabled, update, onLabelClick, labelTooltip, fieldValidation) =>
  (filter = () => true) => {
    const handleUpdateCustomField = (updateValue, fieldKey, fieldType, fieldData) => {
      if (fieldType === TYPES_OF_CUSTOM_FIELDS.DROPDOWN) {
        update({ [fieldKey]: invert(fieldData)[updateValue] || '' });
        return;
      }

      update({ [fieldKey]: updateValue });
    };

    const getCustomFieldValue = (fieldType, fieldKey, fieldData) => {
      switch (fieldType) {
        case TYPES_OF_CUSTOM_FIELDS.DROPDOWN:
          return defaultToEmptyObject(fieldData)[formData[fieldKey]];
        case TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN:
          return defaultToEmptyArray(formData[fieldKey]);
        default:
          return formData[fieldKey];
      }
    };

    return customFields
      .filter(filter)
      .reduce(
        (
          acc,
          {
            key: customFieldKey,
            title,
            field_type: fieldType,
            data: existingData,
            sortedValidOptions,
            description_text: descriptionText,
          },
        ) => {
          const isMultiSelectDropdown = fieldType === TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN;
          const fieldNeedsEmptyOption = not(isMultiSelectDropdown);
          const Component = customFieldTypesToComponent[fieldType];

          const value = getCustomFieldValue(fieldType, customFieldKey, existingData);
          const tooltipText = descriptionText || value;

          const dataOptions = isMultiSelectDropdown
            ? defaultToEmptyArray(sortedValidOptions)
            : defaultToEmptyArray(sortedValidOptions).map(o => o.title);

          const options = [...(fieldNeedsEmptyOption ? [''] : []), ...dataOptions];

          if (Component) {
            acc[customFieldKey] = (
              <Component
                name={customFieldKey}
                validation={fieldValidation}
                key={customFieldKey}
                value={value}
                label={title}
                showEmptyOption
                onChange={newValue => handleUpdateCustomField(newValue, customFieldKey, fieldType, existingData)}
                disableEdit={_checkFieldDisabled(customFieldKey)}
                options={options}
                optionsMapper={{ label: 'title', value: 'key' }}
                tooltipText={tooltipText}
                onLabelClick={onLabelClick}
                labelTooltip={labelTooltip}
                idField="key"
                showTooltipOnOverflow
                hideCreateOption
              />
            );
          }
          return acc;
        },
        {},
      );
  };
