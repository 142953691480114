import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import { indexBy, prop } from 'ramda';

import {
  getNormalizedRoadmaps,
  getNormalizedProducts,
  getNormalizedProductsLevel2,
  getNormalizedCorpRoadmaps,
} from 'store/roadmaps/selectors';
import {
  getNormalizedObjectives,
  getNormalizedKeyResults,
  getNormalizedKeyResultsLevel2,
  getNormalizedObjectivesLevelCorp,
} from 'store/objectives/selectors';
import { getNormalizedCategories } from 'store/categories/selectors';
import { getNormalizedPriorities } from 'store/priorities/selectors';
import { getNormalizedPhases } from 'store/phases/selectors';
import { getNormalizedThemes } from 'store/themes/selectors';
import { getAllNormalizedTimeframes, getNormalizedTimeframesLevelCorp } from 'store/timeframes/selectors';
import { getNormalizedUsers } from 'store/users/selectors';
import { getOrganizationIntegrations } from 'store/organization/selectors';
import { getTeamsLevel2, makeGetTeams } from 'store/teams/selectors';
import { getVotesPerProject } from 'store/votes/selectors';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import { getAllCustomers } from 'store/customers/selectors';
import { getAllTags } from 'store/tags/selectors';
import { getMetrics } from 'store/metrics/selectors';
import { selectPersonasData } from 'features/Personas/store/selectors';
import { selectLifecyclesData } from 'features/Lifecycles/store/selectors';

const getTeams = makeGetTeams();
const byId = indexBy(prop('id'));

export const selectMetadata = createSelectorCreator(defaultMemoize, isEqual)(
  state => getOrganizationIntegrations(state, true),
  state => getNormalizedCorpRoadmaps(state),
  state => getNormalizedRoadmaps(state, true),
  state => getNormalizedProducts(state, true),
  state => getNormalizedProductsLevel2(state, true),
  state => getNormalizedObjectivesLevelCorp(state, true),
  state => getNormalizedObjectives(state, true),
  state => getNormalizedKeyResults(state, true),
  state => getNormalizedKeyResultsLevel2(state, true),
  state => getNormalizedCategories(state, true),
  state => getNormalizedPriorities(state, true),
  state => getNormalizedPhases(state, true),
  state => getNormalizedThemes(state),
  state => getNormalizedTimeframesLevelCorp(state),
  state => getAllNormalizedTimeframes(state, true),
  state => getNormalizedUsers(state, true),
  state => getTeams(state, true),
  state => getTeamsLevel2(state, true),
  state => getVotesPerProject(state, true),
  state => getProjectsCustomFields(state, true),
  state => state.projects.stories,
  state => getAllCustomers(state),
  state => getAllTags(state, true),
  state => getMetrics(state),
  state => selectPersonasData(state, true),
  state => selectLifecyclesData(state, true),
  (
    orgIntegrations,
    roadmapsCorp,
    roadmaps,
    products,
    productsLevel2,
    objectivesCorp,
    objectives,
    keyResults,
    keyResultsLevel2,
    categories,
    priorities,
    phases,
    themes,
    timeframesCorp,
    timeframes,
    users,
    teams,
    teams2,
    votes,
    customFields,
    stories,
    customers,
    tags,
    metrics,
    personas,
    lifecycles,
  ) => {
    return {
      orgIntegrations,
      roadmapsCorp,
      roadmaps,
      products,
      productsLevel2,
      objectivesCorp,
      objectives,
      keyResults,
      keyResultsLevel2,
      categories,
      priorities,
      phases,
      themes,
      timeframesCorp,
      timeframes,
      users,
      teams,
      teams2,
      votes,
      customFields,
      stories,
      customers: byId(customers),
      tags: byId(tags),
      metrics,
      personas,
      lifecycles,
    };
  },
);
