import React from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';

import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';

import theme from 'design-system/theme';

export default ({ isBulk, items = [], objectToDelete = {}, selectedItems = [], selectedPhaseId, setSelectedPhaseId }) => {
  const formatItems = items => {
    const filtered = items.filter(item => {
      if (isBulk) {
        const ids = selectedItems.map(i => i.id);

        return !ids.includes(item.id);
      }

      return item.id !== objectToDelete.id;
    });

    return filtered.map(item => (
      <StyledMenuItemDropdown key={item.id} value={item.id}>
        {item.title}
      </StyledMenuItemDropdown>
    ));
  };

  return (
    <UpdatePhaseDiv>
      <FormLabel control="legend">Update phase to</FormLabel>
      <StyledFormControl margin="dense">
        <Select
          value={selectedPhaseId}
          label="Phase"
          onChange={e => setSelectedPhaseId(e.target.value)}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            style: {
              zIndex: theme.zIndex.confirmDialog,
            },
          }}
        >
          {formatItems(items)}
        </Select>
      </StyledFormControl>
    </UpdatePhaseDiv>
  );
};

const UpdatePhaseDiv = styled.div`
  display: flex;
  align-items: center;
  color: inherit;
`;

const StyledFormControl = styled(FormControl)`
  &&&&& {
    margin: 0 10px;
  }
`;
