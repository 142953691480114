import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('LOAD_PROGRESS_REPORT_DATA'),
  UPDATE_PROGRESS_PAGE_STATE: 'UPDATE_PROGRESS_PAGE_STATE',
};

export const {
  UPDATE_PROGRESS_PAGE_STATE,
  LOAD_PROGRESS_REPORT_DATA,
  LOAD_PROGRESS_REPORT_DATA_FULFILLED,
  LOAD_PROGRESS_REPORT_DATA_PENDING,
} = actionTypes;
