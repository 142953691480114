import { pick } from 'ramda';
import getFormattedMetricProgressValues from 'utils/metrics/calculateMetricProgress';
import { getProjectProgress } from 'store/projects/helpers/getProjectProgress';

const ARCHIVED = 'Archived';
const INACTIVE = 'Inactive';

export const pickProjectFields = project => {
  const {
    id,
    title,
    layer,
    parent_id: parentId,
    objective_id: objectiveId,
    key_result_1_id: keyResult1Id,
    key_result_2_id: keyResult2Id,
    timeframe_id: timeframeId,
    health_calculated: health,
    metrics,
    owner_id: ownerId,
  } = project;

  const { progress } = getProjectProgress(project);

  return {
    id,
    title,
    layer,
    parentId,
    objectiveId,
    keyResult1Id,
    keyResult2Id,
    timeframeId,
    health,
    ownerId,
    progress,
    metrics: metrics?.map(metric => metric?.id),
  };
};

export const pickOkrFields = okr => {
  const {
    id,
    title,
    level,
    status_color: statusColor,
    metrics,
    parent_id: parentId,
    objective_id: objectiveId,
    owner_id: ownerId,
    progress,
    status,
  } = okr;

  return {
    id,
    title,
    level,
    statusColor,
    parentId,
    objectiveId,
    ownerId,
    progress,
    // Todo: We're doing this switch since, for the OKRS' filters, the status is called 'Inactive' instead of 'Archived'
    // Maybe we should change the filter name instead of changing it here
    status: status === ARCHIVED ? INACTIVE : status,
    metrics: metrics?.map(metric => metric?.id),
  };
};
export const pickTimeframeFields = pick(['id', 'title', 'level']);
export const pickMetricFields = metric => {
  const {
    id,
    name,
    status_color: statusColor,
    owner_id: ownerId,
    level,
    actual_value: actualValue,
    baseline_value: baselineValue,
    target_value: targetValue,
    status,
  } = metric;

  const { percentageValue, formattedActualValue } = getFormattedMetricProgressValues(actualValue, targetValue, baselineValue);

  return {
    id,
    name,
    statusColor,
    ownerId,
    level,
    actualValue: formattedActualValue,
    progress: percentageValue,
    status,
  };
};
export const pickUserFields = pick(['id', 'first_name', 'last_name']);
