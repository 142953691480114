import { METADATA_LEVELS } from 'constants/common';

/**
 * @function cleanTimeframesL1ParentId
 *
 * Clean the parent id on timeframes L1
 *
 * When org does not have timeframe corp level will not send the
 * parent id to the dropdown options, otherwise will not be visible
 *
 * @param  {Array} timeframes
 * @return {Array}
 */
export const cleanTimeframesL1ParentId = timeframes =>
  timeframes.map(t => {
    if (t.level === METADATA_LEVELS.LEVEL_1) {
      return { ...t, parent_id: null };
    }

    return t;
  });

export const cleanTimeframesL1ParentIdHierarchy = options => {
  return options.map(o => {
    let { children } = o;

    if (o.children?.length) {
      children = cleanTimeframesL1ParentIdHierarchy(o.children);
    }
    if (o.level === METADATA_LEVELS.LEVEL_1) {
      return { ...o, parent_id: null, children };
    }
    return { ...o, children };
  });
};
