import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { getUserName } from 'utils';
import { getColor } from 'components/ganttCommon';
import formatDateForGantt from 'utils/dates/formatDateForGantt';
import { getStaffingTasks } from 'store/staffing/selectors';
import { getDisplayLayer } from 'store/filters/selectors';
import { getAllFromMultipleLayers } from 'store/projects/selectors';

import { getTaskText } from '../helpers';

export default users => {
  const lsState = useSelector(state => state.staffing);
  const displayLayer = useSelector(getDisplayLayer);
  const { showAllocation, selectedColorBy, displayTimelineBy, hideTasksName } = lsState;

  const projectsTaks = useSelector(getStaffingTasks);
  const projects = useSelector(state => getAllFromMultipleLayers(state, [displayLayer]));

  const _configureTask = task => {
    const parentProject = projects.find(p => p.id === task.project_id) || task.project;
    const colorBy = selectedColorBy ? selectedColorBy.key : 'roadmap';
    const color = parentProject && getColor(parentProject, null, colorBy);

    if (!task.owner && task.owner_id) {
      task.owner = users.find(u => u.id === task.owner_id);
    }
    let { end_date: endDate } = task;
    const startDate = task.start_date;

    if (task.duration) endDate = moment(startDate).addDuration(task.duration, 'days');

    if (!startDate || !endDate) return;

    return Object.assign({}, task, {
      id: task.id,
      type: 'task',
      color,
      owner_id: task.owner_id ? task.owner_id : 'N/A',
      ownerName: getUserName(task.owner),
      project_id: parentProject && parentProject.id,
      original_project_id: parentProject && parentProject.id,
      projectName: parentProject ? parentProject.title : '',
      text: getTaskText(task, parentProject, showAllocation, hideTasksName),
      start_date: formatDateForGantt(startDate),
      end_date: formatDateForGantt(endDate, true),
    });
  };

  const tasks = useMemo(() => projectsTaks.map(_configureTask).filter(Boolean), [
    projectsTaks,
    showAllocation,
    displayTimelineBy,
    hideTasksName,
  ]);

  return [tasks];
};
