export default items => {
  const result = [];

  const traverse = obj => {
    result.push(obj);

    if (Array.isArray(obj.children)) {
      obj.children.forEach(traverse);
    }
  };

  items.forEach(traverse);
  return result;
};
