import fill from 'lodash/fill';
import head from 'lodash/head';

import {
  DISPLAY_MILESTONE_TOP_LANE,
  GROUP_KEY_SEPARATOR,
  MILESTONE_GROUP_TITLE,
  MILESTONE_TOP_LANE_PREFIX,
  UNDEFINED_GROUP_TITLE,
} from './constants';
import { generateRows } from './rows';
import { defaultToMercuyColor, hasGroups, hasItems } from './basic';

/**
 * Check is the milestone artificial group when the milestone top lane preference is active.
 *
 * @param {Array | String} groups The group(s) to verify
 *
 * @return {Boolean} if is the top lane artificial group for milestones
 * */
const isMilestoneTopGroup = groups =>
  Array.isArray(groups)
    ? head(groups)?.id?.startsWith?.(MILESTONE_TOP_LANE_PREFIX)
    : groups?.id?.startsWith?.(MILESTONE_TOP_LANE_PREFIX);

/**
 * Creates a filter function to keep the milestones unless the top milestones preference is active.
 *
 * @param {Boolean} displayMilestone The option to display the items of type milestone
 * @param {String} displayMilestoneOn The option for top lane milestone
 * @param {Function} isMilestoneChecker The custom function to check if a given item is a milestone
 *
 * @return {Function} filter function
 * */
const makeMilestoneFilter = (displayMilestone, displayMilestoneOn, isMilestoneChecker) => item =>
  !isMilestoneChecker(item) ||
  (isMilestoneChecker(item) && displayMilestone && displayMilestoneOn !== DISPLAY_MILESTONE_TOP_LANE);

/**
 * Creates a single milestone group for a given group level.
 *
 * @param {Number} level The current group level
 *
 * @return {Object} the milestone group
 * */
const createMilestoneGroup = level => {
  const id = [MILESTONE_TOP_LANE_PREFIX, 'group', level].join(GROUP_KEY_SEPARATOR);
  const key = fill(Array(level), UNDEFINED_GROUP_TITLE).join(GROUP_KEY_SEPARATOR);

  return {
    id,
    key,
    title: MILESTONE_GROUP_TITLE,
    color: defaultToMercuyColor(null),
    level,
    meta: {
      type: 'milestone',
    },
  };
};

/**
 * Build the milestone groups iteratively depending on the number of groups selected.
 *
 * @param {Number} groupCount The number of selected groups options
 * @param {Array} milestones A list of imilestones
 * @param {Number} level The current group level
 * @param {Object} acc The current group structure so far
 *
 * @return {Object} the final milestone group strucure
 * */
const buildMilestoneTopGroups = (groupCount, milestones, level = 1, acc = {}) => {
  const isLastLevel = level === groupCount;

  const rows = generateRows(milestones);

  if (isLastLevel) {
    return {
      ...acc,
      ...createMilestoneGroup(level),
      items: milestones,
      rows,
      rowsCount: rows.length,
    };
  }

  return {
    ...acc,
    ...createMilestoneGroup(level),
    [level === 2 ? 'groupings' : 'groups']: [buildMilestoneTopGroups(groupCount, milestones, level + 1, acc)],
    rowsCount: rows.length,
  };
};

/**
 * Get all the milestones from data to build the artificial milestones top lane.
 *
 * @param {Array} data The all groups information
 * @param {Function} isMilestoneItemChecker The custom function to check if item is a milestone
 *
 * @return {Array} all the existing milestones
 * */
const getMilestonesFromData = (data, isMilestoneItemChecker) => {
  return data.reduce((accLevel, group) => {
    if (hasItems(group)) {
      return [...accLevel, ...group?.items?.filter(isMilestoneItemChecker)];
    }

    if (hasGroups(group)) {
      return [
        ...accLevel,
        ...group?.groups?.reduce((accSublevel, subGroup) => {
          if (hasItems(subGroup)) {
            return [...accSublevel, ...subGroup?.items?.filter(isMilestoneItemChecker)];
          }

          if (hasGroups(subGroup)) {
            return [
              ...accSublevel,
              ...subGroup?.groups?.reduce(
                (lastLevel, leafGroup) => [...lastLevel, ...(leafGroup?.items?.filter(isMilestoneItemChecker) ?? [])],
                [],
              ),
            ];
          }

          return accSublevel;
        }, []),
      ];
    }

    return accLevel;
  }, []);
};

export { isMilestoneTopGroup, makeMilestoneFilter, buildMilestoneTopGroups, getMilestonesFromData };
