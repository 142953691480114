import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import LockOpen from '@material-ui/icons/LockOpen';

import { materialColors } from 'design-system/themes/default';

const LockIcon = styled(LockOpen)`
  &&&& {
    color: ${({ hasUserRoles }) => (hasUserRoles ? materialColors.red : materialColors.darkGray)};
    font-size: 15px !important;
  }
`;

const ActionButton = styled(IconButton)`
  &&&& {
    width: 25px;
    height: 25px !important;
    padding: 0;
    opacity: ${props => (props.hover === true ? 1 : 0)};
  }
`;

const Row = styled.div`
  &&&& {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export { LockIcon, ActionButton, Row };
