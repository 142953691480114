import axios from 'axios';

export const loadMetadataForProjectFilters = async projectFilters => {
  const { data } = await axios.post(`/api/projects/search/metadata`, { filters: projectFilters });

  return data;
};

export const loadMetadataForProjectIds = async projectIds => {
  if (!projectIds.length) {
    return {};
  }

  const { data } = await axios.post(`/api/projects/search/metadata`, { projectIds });

  return data;
};
