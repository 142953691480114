export const SUMMARY_KEY = 'summary';

export const DEFAULT_FIELDS_MAPPING = {
  title: {
    title: 'Summary',
    key: SUMMARY_KEY,
  },
  issuelinks: 'Linked Issues',
  ownerName: 'Assignee',
  details: 'Description',
  priority: 'Priority',
  // roadmapTitle: 'Project',
  phaseTitle: 'Status',
};
