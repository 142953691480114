import React, { useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import history from 'store/utils/history';
import {
  getTemplatesCategories,
  getActiveTemplateCategory,
  applyTemplateOnStore,
  setActiveTemplate,
  isFetchTemplatesLoading,
  getTemplatesOrderedByMostPopular,
} from 'store/templates';

import useFetchTemplatesAndCategories from './hooks/useFetchTemplatesAndCategories';

const componentHOC = Component => {
  return props => {
    const [search, setSearch] = useState('');

    const dispatch = useDispatch();

    const templates = useSelector(getTemplatesOrderedByMostPopular);
    const isLoading = useSelector(isFetchTemplatesLoading);
    const categories = useSelector(getTemplatesCategories);
    const activeTemplateCategory = useSelector(getActiveTemplateCategory);

    const filteredTemplates = useMemo(
      () =>
        templates.filter(template => {
          const titleMatchesQuery = template.name.toLowerCase().includes(search.toLowerCase());
          const descriptionMatchesQuery = template.description?.toLowerCase().includes(search.toLowerCase());

          const matchesCategory = activeTemplateCategory ? template.categories.includes(activeTemplateCategory) : true;
          const matchesQuery = search ? titleMatchesQuery || descriptionMatchesQuery : true;

          return matchesCategory && matchesQuery;
        }),
      [activeTemplateCategory, search, templates],
    );

    const handleSearch = query => setSearch(query);

    const handleSelectTemplate = template => {
      dispatch(setActiveTemplate(template.id));
      dispatch(applyTemplateOnStore(template.id));
      history.push(`/${template.path}`);
    };

    useFetchTemplatesAndCategories();

    return (
      <Component
        {...props}
        templates={filteredTemplates}
        categories={categories}
        searchQuery={search}
        handleSearch={handleSearch}
        handleSelectTemplate={handleSelectTemplate}
        isLoading={isLoading}
      />
    );
  };
};

export default componentHOC;
