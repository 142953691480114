export default items => {
  if (!items || !items.length) {
    return;
  }

  const startDate = Math.min(...items.map(item => !item.start_date ? item : new Date(item.start_date)));

  const endDate = Math.max(...items.map(item => {
    if (!item.start_date) {
      return item;
    }

    const date = new Date(item.start_date);

    if (item.duration) {
      date.setDate(date.getDate() + item.duration);
    }

    return date;
  }));

  const duration = Math.floor((Math.abs(endDate - startDate) / 1000) / 86400);

  return {
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    duration,
  };
};
