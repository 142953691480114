import React from 'react';
import styled from 'styled-components';

import Text from 'design-system/atoms/Text/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';

import { spacing } from 'design-system/theme';

const ChartLegend = ({ legends = [], maxLegendItems = legends?.length, ...props }) => {
  const legendsToRender = legends?.slice(0, maxLegendItems);

  return (
    <Container {...props}>
      {legendsToRender.map(legend => (
        <Flex>
          <Circle legendColor={legend?.backgroundColor} />
          <TooltipOnOverflow text={legend?.label}>
            <StyledText variant="small">{legend?.label}</StyledText>
          </TooltipOnOverflow>
        </Flex>
      ))}
    </Container>
  );
};

export default ChartLegend;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: ${spacing(2)}px 0 0 ${spacing(5)}px;
  gap: ${spacing(2)}px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing()}px;
`;

const Circle = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ theme, legendColor }) => legendColor || theme.palette.primary.main};
  border-radius: 50%;
`;

const StyledText = styled(Text)`
  white-space: nowrap;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
