import { useMemo } from 'react';

import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';
import {
  getTitleColumnDef,
  statusColumnDef,
  colorColumnDef,
  updatedColumnDef,
  getMetadataRoadmapColumnDef,
  healthColumnDef,
  targetAllocationAmountColumnDef,
  targetAllocationPercentageColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';

const THEME_KEY = 'theme';
const THEME_ROADMAP_FIELD = 'theme_roadmaps';

const useThemesGridColumns = ({ roadmapColumn }) => {
  const { createThemeRoadmap, deleteThemeRoadmap, themes, bulkDeleteThemeRoadmaps, hideMetadataRoadmaps } = roadmapColumn;

  const roadmapsMetadata = useRoadmapsMetadata(
    createThemeRoadmap,
    deleteThemeRoadmap,
    themes,
    THEME_KEY,
    bulkDeleteThemeRoadmaps,
  );

  return useMemo(
    () => [
      getTitleColumnDef('Title'),
      getMetadataRoadmapColumnDef({
        roadmapsMetadata: [...roadmapsMetadata, hideMetadataRoadmaps],
        editableForNewRow: false,
        field: THEME_ROADMAP_FIELD,
      }),
      targetAllocationAmountColumnDef,
      targetAllocationPercentageColumnDef,
      statusColumnDef,
      healthColumnDef,
      colorColumnDef,
      updatedColumnDef,
    ],
    [],
  );
};

export default useThemesGridColumns;

export { THEME_ROADMAP_FIELD };
