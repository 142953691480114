import axios from 'axios';
import moment from 'moment';

import { createThunk } from 'utils/store/thunk';

import { FETCH_GOALS_ALLOCATION_DATA, RECALCULATE_COMPLETED_ALLOCATION_DATA } from './types';

export const fetchGoalsAllocationData = ({ timeUnit = 'quarter', startDate, endDate, projectsFilter, allocationBy }) => {
  const fetchDataRequestBody = {
    projectsFilter,
    allocationQuery: {
      timeUnit,
      startDate: moment.utc(startDate).toISOString(),
      endDate: moment.utc(endDate).toISOString(),
      allocationBy,
    },
  };

  return createThunk(FETCH_GOALS_ALLOCATION_DATA, () =>
    axios.post('/api/v1/reports/outcome/completed-allocation-by-time', fetchDataRequestBody).then(data => data.data),
  );
};

export const recalculateCompletedAllocationData = () => {
  return createThunk(RECALCULATE_COMPLETED_ALLOCATION_DATA, () =>
    axios.post('/api/v1/reports/outcome/completed-allocation/recalculate').then(data => data.data),
  );
};
