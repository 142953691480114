import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { defaultTo } from 'ramda';

import { getOrgHasMetadataRoadmaps } from 'store/organization';

import usePageFilters from 'hooks/filters/usePageFilters';
import useAdvancedSearchPopover from 'design-system/organisms/AdvancedSearchPopover/hooks/useAdvancedSearchPopover';

import { METRICS_FILTER } from 'constants/filters';

import { HEALTH_OPTIONS, STATUS_OPTIONS, UNDEFINED_OPTION, UNDEFINED_OPTION_FOR_ROADMAPS } from 'utils/filters/fieldFilters';
import { getProducts, getProductsLevelTwo, getRoadmaps } from 'store/roadmaps/selectors';
import { getMetrics } from 'store/metrics/selectors';
import { selectMetricLevels } from 'features/MetricLevels/store';
import getMetricLevelsFilterOptions from 'features/MetricLevels/utils/getMetricLevelsFilterOptions';

const defaultEmptyObject = defaultTo({});

export default function useMetricsAdvancedSearch(isOpen) {
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);
  const metrics = useSelector(getMetrics);
  const roadmaps = useSelector(getRoadmaps);
  const products = useSelector(getProducts);
  const products2 = useSelector(getProductsLevelTwo);
  const metricLevels = useSelector(selectMetricLevels);

  const { pageFilters: metricModeFilters, applyFilters } = usePageFilters(METRICS_FILTER);

  const options = useMemo(
    () => ({
      ...(hasMetadataRoadmaps
        ? {
            roadmaps: [UNDEFINED_OPTION_FOR_ROADMAPS, ...roadmaps],
            products: [UNDEFINED_OPTION, ...products],
            products2: [UNDEFINED_OPTION, ...products2],
          }
        : {}),
      status: STATUS_OPTIONS,
      owners: [],
      name: [...metrics],
      status_color: HEALTH_OPTIONS,
      level: getMetricLevelsFilterOptions(metricLevels),
    }),
    [hasMetadataRoadmaps, metrics, products, roadmaps, metricLevels],
  );

  const { filters, setFilters, removeFilter, changeFilter, addNewFilter, getFieldValue, getFieldOptions, getFieldFunction } =
    useAdvancedSearchPopover(options);

  useEffect(() => {
    setFilters({
      ...metricModeFilters?.fields,
      op: defaultEmptyObject(metricModeFilters?.op),
    });
  }, [isOpen, metricModeFilters?.fields]);

  return {
    addNewFilter,
    applyFilters,
    changeFilter,
    filters,
    getFieldValue,
    getFieldOptions,
    getFieldFunction,
    hasMetadataRoadmaps,
    removeFilter,
  };
}
