import { PRODUCTION_ENVIRONMENTS } from 'constants/deployEnvironments';

/**
 * Throws an error with a fallback function in production environment.
 * Logs the error using logRollbarError and returns the result of the fallback function.
 * Throws the error in non-production environment.
 *
 * @param {Error} error - The error to be thrown.
 * @param {Function} fallbackFn - The fallback function to be executed in production environment.
 * @returns {*} - The result of the fallback function in production mode.
 * @throws {Error} - The error in non-production environment.
 */
export const throwWithProductionFallback = (error, fallbackFn) => {
  const isProduction = PRODUCTION_ENVIRONMENTS.includes(process.env.REACT_APP_DEPLOY_ENVIRONMENT);
  const rollbar = window.Rollbar;

  if (isProduction) {
    if (rollbar) {
      window.Rollbar.error(error);
    } else {
      console.error(error);
    }
    return fallbackFn();
  }

  throw error;
};
