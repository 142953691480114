import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import history from 'store/utils/history';
import { GLOBAL_FILTER } from 'constants/filters/pages';
import useRoadmapQuickFilter from 'hooks/filters/useRoadmapQuickFilter';
import { selectActiveRoadmapOrProduct } from '../store';
import { METADATA_LEVELS } from '../../../constants/common';
import { getRodmapEntityUrl } from '../helpers';
import { flatten } from 'ramda';

const PORTFOLIO_OPTION_KEY = 'null';

const useSelectedRoadmapOnPortfolioOverview = () => {
  const activeRoadmapOrProduct = useSelector(selectActiveRoadmapOrProduct);

  const { setRoadmapQuickFilter, roadmapsFilterTree } = useRoadmapQuickFilter({
    filterType: GLOBAL_FILTER,
  });

  const handleSelectPortfolioRoadmap = useCallback(() => {
    const portfolioOption = roadmapsFilterTree.find(r => r.key === PORTFOLIO_OPTION_KEY);

    history.push(getRodmapEntityUrl(null, null));
    setRoadmapQuickFilter(portfolioOption);
  }, [roadmapsFilterTree]);

  const handleSelectRoadmap = useCallback(
    roadmap => {
      const option = roadmapsFilterTree.find(r => r.key === roadmap?.id);

      history.push(getRodmapEntityUrl(roadmap?.id, METADATA_LEVELS.LEVEL_1));
      setRoadmapQuickFilter(option);
    },
    [roadmapsFilterTree],
  );

  const handleSelectProduct = useCallback(
    product => {
      // include all the options available
      const allChildren = roadmapsFilterTree.reduce((acc, r) => {
        return [...acc, ...r.children];
      }, []);

      const option = allChildren.find(r => r.key === product?.id);

      history.push(getRodmapEntityUrl(product?.id, METADATA_LEVELS.LEVEL_2));
      setRoadmapQuickFilter(option);
    },
    [roadmapsFilterTree],
  );

  const handleSelectProduct2 = useCallback(
    product2 => {
      // include all the options available
      const allProducts2 = roadmapsFilterTree.reduce((acc, r) => {
        return [...acc, ...flatten(r.children.map(p => p.children))];
      }, []);

      const option = allProducts2.find(r => r.key === product2?.id);

      history.push(getRodmapEntityUrl(product2?.id, METADATA_LEVELS.LEVEL_3));
      setRoadmapQuickFilter(option);
    },
    [roadmapsFilterTree],
  );

  return {
    activeRoadmapEntity: activeRoadmapOrProduct,
    handleSelectPortfolioRoadmap,
    handleSelectRoadmap,
    handleSelectProduct,
    handleSelectProduct2,
  };
};

export default useSelectedRoadmapOnPortfolioOverview;
