import { pick } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import {
  createProjectDeliverableComment,
  deleteProjectDeliverableComment,
  fetchNextProjectDeliverableComments,
  selectProjectDeliverableComments,
  selectProjectDeliverableCommentsMetadata,
  updateProjectDeliverableComment,
} from 'features/Pdlc/store';

const useProjectDeliverableComments = ({ projectDeliverableId }) => {
  const dispatch = useDispatch();
  const comments = useSelector(selectProjectDeliverableComments);
  const commentsMetadata = useSelector(selectProjectDeliverableCommentsMetadata);

  const hasMoreComments = Boolean(commentsMetadata?.next);

  const addComment = async data => {
    const otherFieldsAllowed = ['mentions', 'editorState', 'integrationType', 'channel'];

    // will use plainText to maintain consistency with edit action
    return dispatch(
      createProjectDeliverableComment(projectDeliverableId, { plainText: data.text, ...pick(otherFieldsAllowed, data) }),
    );
  };

  const fetchNextPage = () => dispatch(fetchNextProjectDeliverableComments(projectDeliverableId, commentsMetadata?.next));

  const deleteComment = commentId => dispatch(deleteProjectDeliverableComment(projectDeliverableId, commentId));

  const editComment = (commentId, data) => dispatch(updateProjectDeliverableComment(projectDeliverableId, commentId, data));

  return {
    comments,
    hasMoreComments,

    addComment,
    fetchNextPage,
    deleteComment,
    editComment,
  };
};

export default useProjectDeliverableComments;
