const FeatureTypes = {
  customerRequests: {
    importCustomerRequests: 'importCustomerRequests',
    updateCustomerRequests: 'updateCustomerRequests',
  },
  metrics: {
    importMetricValues: 'importMetricValues',
    linkMetric: 'linkMetric',
    unlinkMetric: 'unlinkMetric',
  },
};

export default FeatureTypes;
