import React, { useMemo } from 'react';

import { EXTERNAL_REQUESTOR, OWNER_USER } from '@dragonboat/permissions';
import AgGridAutocomplete, { cellParams } from 'components/AgGridAutocomplete/new';
import AgGridDeleteButton from 'components/AgGridDeleteButton';
import AgGridEmailInput from 'design-system/atoms/AgGridEmailInput/index';
import AgGridInput from 'design-system/atoms/AgGridInput/AgGridInput';

import AgGridDropdown from 'design-system/atoms/AgGridDropdown/new/AgGridDropdown';
import { returnsTrueIfKeyIsNotEnterOrTab } from 'utils/agGrid';
import UserTypeMapper from 'utils/UserTypeMapper';

const useGridColumns = ({ isPortalSettingsEnabled, teams, skills, removeRow } = {}) => {
  return useMemo(
    () => [
      {
        lockPosition: true,
        valueGetter: ({ node }) => {
          return parseInt(node.rowIndex) + 1;
        },
        width: 50,
      },
      {
        cellRenderer: params => <AgGridDeleteButton {...params} onClick={removeRow} />,
        width: 50,
      },
      {
        editable: true,
        headerName: 'First Name (required)',
        field: 'first_name',
        cellEditor: AgGridInput,
        suppressKeyboardEvent: returnsTrueIfKeyIsNotEnterOrTab,
      },
      {
        editable: true,
        headerName: 'Last Name',
        field: 'last_name',
      },
      {
        editable: true,
        headerName: 'Email',
        field: 'email',
        cellEditor: AgGridEmailInput,
        suppressKeyboardEvent: returnsTrueIfKeyIsNotEnterOrTab,
        minWidth: 250,
        maxWidth: 350,
      },
      {
        editable: true,
        headerName: 'Team',
        field: 'team.title',
        enableRowGroup: true,
        cellEditor: AgGridAutocomplete,
        ...cellParams(teams, 'team.title'),
        cellEditorPopup: true,
      },
      {
        editable: true,
        headerName: 'Main skill',
        field: 'skill.title',
        enableRowGroup: true,
        cellEditor: AgGridAutocomplete,
        ...cellParams(skills, 'skill.title'),
        cellEditorPopup: true,
      },
      {
        editable: true,
        headerName: 'User Type',
        field: 'role_id',
        enableRowGroup: false,
        cellEditor: AgGridDropdown,
        ...cellParams(
          UserTypeMapper.filter(u => u.value !== OWNER_USER).filter(u => {
            return isPortalSettingsEnabled || u.value !== EXTERNAL_REQUESTOR;
          }),
          'role_id',
          false,
        ),
        valueFormatter: params => {
          const userType = UserTypeMapper.find(type => type.value === +params.value);

          return userType ? userType.label : '';
        },
        cellEditorPopup: true,
      },
    ],
    [isPortalSettingsEnabled, teams, skills, removeRow],
  );
};

export default useGridColumns;
