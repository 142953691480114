import { useDispatch, useSelector } from 'react-redux';

import { selectFormLayoutDirtyWarningIsOpen, selectFormLayoutIsDirty } from '../store';
import { cleanFormLayoutState, closeFormLayoutDirtyWarning, openFormLayoutDirtyWarning } from '../store/actions';

export const CLOSE_AND_CONTINUE = 'CLOSE_AND_CONTINUE';

const useFormLayoutDirtyWarning = ({ type, onCloseForm }) => {
  const dispatch = useDispatch();
  const formIsDirty = useSelector(state => selectFormLayoutIsDirty(state, type));
  const formLayoutDirtyWarningOpen = useSelector(state => selectFormLayoutDirtyWarningIsOpen(state, type));

  const onCloseFormLayoutDirtyWarning = () => {
    dispatch(closeFormLayoutDirtyWarning(type));
  };

  const onOpenFormLayoutDirtyWarning = () => {
    dispatch(openFormLayoutDirtyWarning(type));
  };

  const onCloseFormWithValidation = (...params) => {
    // first param is the trigger sent on close and continue
    const shouldContinue = params && params[0] === CLOSE_AND_CONTINUE;

    if (formIsDirty && !shouldContinue) {
      onOpenFormLayoutDirtyWarning();
      return;
    }

    onCloseForm(...params);
  };

  const onContinueAndClose = () => {
    dispatch(cleanFormLayoutState(type));
    onCloseForm(CLOSE_AND_CONTINUE);
  };

  return {
    formLayoutDirtyWarningOpen,

    onContinueAndClose,
    onOpenFormLayoutDirtyWarning,
    onCloseFormLayoutDirtyWarning,
    onCloseFormWithValidation,
  };
};

export default useFormLayoutDirtyWarning;
