import {
  FETCH_NOTIFICATIONS_FULFILLED,
  MARK_NOTIFICATION_AS_READ_FULFILLED,
  FETCH_NEXT_NOTIFICATIONS_FULFILLED,
  MARK_ALL_NOTIFICATIONS_AS_READ_FULFILLED,
  RECEIVE_REALTIME_NOTIFICATION,
  MARK_NOTIFICATION_AS_ARCHIVED_FULFILLED,
  MARK_ALL_NOTIFICATIONS_AS_ARCHIVED_FULFILLED,
  FETCH_NEXT_NOTIFICATIONS_PENDING,
  FETCH_NEXT_NOTIFICATIONS_REJECTED,
} from './types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_FULFILLED: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case FETCH_NEXT_NOTIFICATIONS_PENDING: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_NEXT_NOTIFICATIONS_REJECTED: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_NEXT_NOTIFICATIONS_FULFILLED: {
      return {
        ...state,
        _meta: action.payload._meta,
        data: state.data ? state.data.concat(action.payload.data) : [],
        isFetching: false,
      };
    }
    case RECEIVE_REALTIME_NOTIFICATION: {
      return {
        ...state,
        data: [action.payload, ...(state.data || [])],
        _meta: {
          ...state._meta,
          notRead: (state._meta.notRead || 0) + 1,
        },
      };
    }
    case MARK_NOTIFICATION_AS_READ_FULFILLED: {
      return {
        ...state,
        _meta: {
          ...state._meta,
          notRead: state._meta && state._meta.notRead ? state._meta.notRead - 1 : 0,
        },
        data: state.data
          ? state.data.map(notification => (notification.id === action.payload.id ? action.payload : notification))
          : [],
      };
    }
    case MARK_ALL_NOTIFICATIONS_AS_READ_FULFILLED: {
      return {
        ...state,
        _meta: {
          ...state._meta,
          notRead: 0,
        },
        data: state.data ? state.data.map(notification => ({ ...notification, read: true })) : [],
      };
    }
    case MARK_NOTIFICATION_AS_ARCHIVED_FULFILLED: {
      return {
        ...state,
        _meta: {
          ...state._meta,
          notRead: state._meta && state._meta.notRead ? state._meta.notRead - 1 : 0,
        },
        data: state.data
          ? state.data.map(notification => (notification.id === action.payload.id ? action.payload : notification))
          : [],
      };
    }
    case MARK_ALL_NOTIFICATIONS_AS_ARCHIVED_FULFILLED: {
      return {
        ...state,
        _meta: {
          ...state._meta,
          notRead: 0,
        },
        data: state.data ? state.data.map(notification => ({ ...notification, archived: true })) : [],
      };
    }
    default:
      return state;
  }
};
