import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PageHeaderFilters from 'containers/PageHeaderFilters';
import GoalMetricDropdown from 'containers/GoalMetricDropdown';
import MetricsAdvancedSearch from '../MetricsAdvancedSearch';
import theme from 'design-system/theme';
import { clearPageFilters, setPageFilters } from 'store/filters';
import { getPageFilters } from 'store/filters/selectors';
import { getOrgHasMetadataRoadmaps } from 'store/organization';
import { getCurrentUser } from 'store/login/selectors';
import usePageNavigation from 'hooks/usePagesNavigation/usePageNavigation';
import useMetrics from 'hooks/grid/metrics/useMetrics';
import usePageQueryParamFilters from 'hooks/usePageQueryParamFilters';
import useMetricModeMetrics from '../hooks/useMetricModeMetrics';
import isQuickFilterApplied from 'utils/filters/isQuickFilterApplied';
import parseOutcomeDefaultFilter from 'utils/filters/parseOutcomeDefaultFilter';
import { getPageIdFromPath } from 'utils/userViews';
import getSelectedQuickFilterFromOutcomeDefaultFilters from 'utils/filters/getSelectedQuickFilterFromOutcomeDefaultFilters';
import { METRICS_FILTER } from 'constants/filters/pages';
import { PORTFOLIO_LABEL } from 'constants/roadmaps';
import { getDefaultFilters } from '../constants';

export default function HeaderFilters(props) {
  const dispatch = useDispatch();

  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);
  const currentUser = useSelector(getCurrentUser);

  const pageFilters = useSelector(state => getPageFilters(state, METRICS_FILTER));
  const defaultFilters = useMemo(() => getDefaultFilters(currentUser), [currentUser]);

  const { metrics } = useMetrics();
  const pageId = getPageIdFromPath(window.location.pathname);
  const quickFilteredMetrics = useMetricModeMetrics(metrics, pageId);

  const { pages, selectedPage, setSelectedPage } = usePageNavigation('/metrics', props.history);

  const applyFilterFromQueryParams = useCallback(
    filterId => {
      if (!filterId || isQuickFilterApplied(filterId, pageFilters)) return;

      const defaultFilter = defaultFilters.find(filter => filter.key === filterId);

      if (defaultFilter) {
        dispatch(setPageFilters(METRICS_FILTER, parseOutcomeDefaultFilter(defaultFilter)));
      }
    },
    [defaultFilters, pageFilters],
  );

  const { removeFilterFromQueryParams } = usePageQueryParamFilters(applyFilterFromQueryParams);

  const handleClearFilter = () => {
    dispatch(clearPageFilters(METRICS_FILTER));
  };

  const renderAdvancedFilter = () => <MetricsAdvancedSearch onApplyFilters={removeFilterFromQueryParams} />;

  return (
    <PageHeaderFilters
      {...props}
      defaultFilters={defaultFilters}
      onClearFilter={handleClearFilter}
      quickFilterUndefinedLabel={PORTFOLIO_LABEL}
      page={METRICS_FILTER}
      shouldIncludeRoadmapsQuickFilter={hasMetadataRoadmaps}
      showAdvancedSearch={false}
      showDisplayLayer={false}
      showFilterIcon={false}
      showResultsCounter
      showSavedFiltersOnQuickFilter={false}
      customNumberOfResults={quickFilteredMetrics?.length}
      customFilterRender={renderAdvancedFilter}
      subNavigationTabs={pages}
      selectedSubNavigationTab={selectedPage}
      handleSubNavigationChange={setSelectedPage}
      pageIconBackground={theme.palette.text.purple}
      ModuleCustomDropdownComponent={GoalMetricDropdown}
      onApplyQuickFilter={removeFilterFromQueryParams}
      getSelectedQuickFilterFromDefaultFilters={getSelectedQuickFilterFromOutcomeDefaultFilters}
    />
  );
}
