import { defaultTo, propEq, pipe } from 'ramda';

import { METADATA_LEVELS } from 'constants/common';
import { OBJECT_KEY_RESULT, OBJECT_OBJECTIVE } from 'store/objectives';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import getPermissionResourceByKeyResultLevel from './getPermissionResourceByKeyResultLevel';

const defaultToEmptyObject = defaultTo({});
const isTypeObjective = pipe(defaultToEmptyObject, propEq('type', OBJECT_OBJECTIVE));
const isLevelCorp = pipe(defaultToEmptyObject, propEq('level', METADATA_LEVELS.LEVEL_CORP));
const isTypeKeyResult = propEq('type', OBJECT_KEY_RESULT);

const makeCanObjectiveMetadataRoadmapBeUpdated = (hasCorpLevel, organizationId, userIsNotReadOnly = false, canUpdate) => ({
  data: objective,
}) => {
  if (!objective.id) {
    return false;
  }
  if (!userIsNotReadOnly) {
    return false;
  }
  if (hasCorpLevel) {
    return objective?.organization_id === organizationId && !isLevelCorp(objective);
  }

  if (typeof canUpdate === 'function') {
    const resource = isTypeKeyResult(objective)
      ? getPermissionResourceByKeyResultLevel(objective.level)
      : PERMISSION_RESOURCES.objective;

    return canUpdate(resource, { data: objective });
  }

  return !isTypeObjective(objective);
};

export default makeCanObjectiveMetadataRoadmapBeUpdated;
