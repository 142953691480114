import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import DragonSelectButton from 'design-system/atoms/DragonSelectButton/index';
import DragonMenu from 'design-system/atoms/DragonMenu/index';


import { getOrganization } from 'store/organization/selectors';
import getDisplayLayerLabel from 'store/projects/helpers/getDisplayLayerLabel';
import { setDisplayLayer } from 'store/filters/actions';
import useSystemFields from 'hooks/useSystemFields';
import usePageFilters from 'hooks/filters/usePageFilters';
import { GLOBAL_FILTER, CHILDREN_FILTERS } from 'constants/filters';

/*
 * This component should be visible only if some quick filter is applied
 */

const selectButtonTextStyles = css`
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme }) => theme.palette.text.primary};
`;

const SelectDisplayLayer = ({ hasPortfolioOption = false }) => {
  const dispatch = useDispatch();
  const [viewAnchorEl, setViewAnchorEl] = useState(null);
  const organization = useSelector(getOrganization);
  const { pageFilters, displayLayer, portfolioMode, availableLayers } = usePageFilters(GLOBAL_FILTER, hasPortfolioOption);
  const [getSystemFieldName] = useSystemFields();

  const hasQuickFilterActive = Boolean(pageFilters.quickFilters);

  const options = useMemo(() => {
    return [
      ...availableLayers.map(l => ({ key: l, label: getDisplayLayerLabel(l, getSystemFieldName) })),
      ...(availableLayers.length > 1 && hasPortfolioOption ? [{ key: 'ALL_LEVELS', label: 'All Levels' }] : []),
    ];
  }, [availableLayers, hasPortfolioOption]);

  const _handleChangeDisplay = event => {
    setViewAnchorEl(event.currentTarget);
  };

  const handleSetDisplayLayer = option => {
    dispatch(setDisplayLayer(option.key === 'ALL_LEVELS' ? availableLayers : option.key, option.key === 'ALL_LEVELS'));
  };

  // Makes sure the displayLayer is included on displayLayer and
  // that if all layers is selected if shows the top filtered level
  useEffect(() => {
    if (!availableLayers.includes(displayLayer.toString())) {
      // if there is a specific action to update display layer we should use it instead
      dispatch(
        setDisplayLayer(
          availableLayers[availableLayers.length - 1],
          selectedOption === 'ALL_LEVELS' && availableLayers.length > 1,
        ),
      );
    } else if (selectedOption === 'ALL_LEVELS' && availableLayers.length === 1) {
      dispatch(setDisplayLayer(displayLayer, false));
    }
  }, [portfolioMode, availableLayers, displayLayer]);

  const selectedOption = useMemo(() => {
    /*
     * all levels option is only available on
     * portfolioMode active
     * page has portfolioMode option
     * the current page filters has children
     */
    const showChildren = [CHILDREN_FILTERS.allChildren, CHILDREN_FILTERS.filteredChildren].includes(pageFilters.children);

    if (portfolioMode && hasPortfolioOption && showChildren) return 'ALL_LEVELS';

    return displayLayer;
  }, [displayLayer, portfolioMode, pageFilters]);

  const isSelectVisible = hasQuickFilterActive && organization?.has_hierarchy && options.length > 0;

  const _renderDisplayLayerSelection = () => (
    <Wrapper>
      <DisplayLabel>Display:</DisplayLabel>
      <Spacing />
      <SelectButton
        id="filters-list-dropdown"
        data-testid="select-display-layer-button"
        value={options.find(opt => opt.key === selectedOption)?.label}
        variant="secondary"
        noUnderline
        higherLabel
        placeholder="Select Display"
        onClick={_handleChangeDisplay}
        textStyles={selectButtonTextStyles}
        arrowIcon={<StyledKeyboardArrowDownIcon />}
      />
      <DragonMenu
        items={options}
        anchorEl={viewAnchorEl}
        placement="bottom-end"
        data-testid="select-display-layer-dropdown"
        onSetAnchorEl={setViewAnchorEl}
        onSelectItem={handleSetDisplayLayer}
      />
    </Wrapper>
  );

  return <Fragment>{isSelectVisible && _renderDisplayLayerSelection()}</Fragment>;
};

export default SelectDisplayLayer;

SelectDisplayLayer.propTypes = {
  hasPortfolioOption: PropTypes.bool,
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
`;

const SelectButton = styled(DragonSelectButton)`
  &&&& {
    // max-width: 10pxpx;
    min-width: auto;
    width: auto;
    button {
      min-width: auto;
      width: auto;
    }

    div {
      min-width: auto;
      width: auto;
    }
  }
`;

const DisplayLabel = styled(Typography)`
  &&&& {
    color: ${props => props.theme.palette.text.darkerGrey};
    font-size: ${props => props.theme.typography.fontSize}px;
  }
`;

const Spacing = styled.div`
  margin-left: 10px;
`;

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
  &&&&& {
    width: 24px;
    height: 24px;

    path {
      fill: none;
    }
  }
`;

// const WarningText = styled(TextDeprecated)`
//   &&&& {
//     position: absolute;
//     left: 100%;
//     top: 16px;
//     color: ${props => props.theme.palette.text.error};
//   }
// `;
