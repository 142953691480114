import { IDEA_LAYER } from 'store/projects/constants';
import { CHILDREN_FILTERS } from 'constants/filters';

export default state => {
  return {
    ...state,
    childrenFields: { ...state },
    layer: IDEA_LAYER,
    children: CHILDREN_FILTERS.noChildren,
    fields: { ...state },
  };
};
