import { EDITOR_USER, MANAGER_USER, ADMIN_USER, LEADER_USER, OWNER_USER } from '@dragonboat/permissions';

export default (currentUser, organization, addNew = true) =>
  type => {
    if (!addNew) return false;

    if ([LEADER_USER, OWNER_USER].includes(currentUser.role_id)) return true;

    if ([ADMIN_USER, MANAGER_USER, EDITOR_USER].includes(currentUser.role_id) && organization.editor_can_create_metadata) {
      return organization.editor_can_create_metadata[type];
    }

    return false;
  };
