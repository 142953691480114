import { removeHtmlTags } from 'utils';

const isValid = value => Boolean(value);

const basicValidationField = value => {
  if (Array.isArray(value)) {
    return value.filter(v => isValid(v)).length > 0;
  }

  return isValid(value);
};
const validateHtmlField = value => isValid(value && Boolean(removeHtmlTags(value)));

export { basicValidationField, validateHtmlField };
