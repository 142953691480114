import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { not, pipe, path, equals, defaultTo, isEmpty, pluck } from 'ramda';
import { css } from 'styled-components';

import {
  healthColumnDef,
  dateColumnDef,
  createMultipleActionsColumnDefGetter,
  planningStageColumnDef,
  numericColumnDef,
  textCenterAlignedColumnDef,
  sumNumericFieldAggColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';
import {
  IntegrationKeyCellRenderer,
  NumericCellRenderer,
  TextCellRenderer,
  WYSIWYGCellRenderer,
  ChipsCellRenderer,
} from 'design-system/molecules/AgGridReact-New/cellRenderers';
import {
  RemirrorWYSIWYGCellEditor,
  MultiSelectCellEditor,
  ProgressCellEditor,
} from 'design-system/molecules/AgGridReact-New/cellEditors';
import { returnsTrueIfKeyIsNotEscOrTab, applyCheckIsEditable } from 'design-system/molecules/AgGridReact-New/helpers';

import {
  stringComparator,
  multiSelectComparator,
  integrationComparator,
} from 'design-system/molecules/AgGridReact-New/comparators';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import {
  getOrganization,
  getHasAdvancedMetricReporting,
  getHasMultipleMetrics,
  getHasProjectMetrics,
  getOrgHasJiraIntegrated,
  getOrgHasMetricCharts,
  getOrgHasMetadataRoadmaps,
} from 'store/organization';
import { INTEGRATIONS_KEYS } from 'constants/integrations';
import { formatIssueType } from 'containers/Grids/ProjectsGrid/helpers';
import {
  getOkrProgressDisplayRenderer,
  getIntegrationIssuesCount,
  getIntegrationStoryPoints,
  makeGetMetadataChipCellRendererParams,
  progressValueFormatter,
  makeCellClassForCreatingOrViewingScenario,
  applyCheckIsCreatingOrViewingScenario,
  applyCheckUserCanUpdateRow,
  checkIsEditable,
} from './helpers';
import { ChipCellRenderer, ProgressDisplayRenderer, TimeStringRenderer } from './renderers';
// eslint-disable-next-line max-len
import getMetadataAutocompleteEditorColumnDef from 'shared/components/inputs/Autocomplete/helpers/getMetadataAutocompleteEditorColumnDef';
import getFormattedMetadataFieldOptions from 'shared/components/inputs/Autocomplete/helpers/getFormattedMetadataFieldOptions';

import MetricNameRenderer from './renderers/MetricNameRenderer';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { materialColors } from 'design-system/themes/default';
import AgGridAutocomplete from 'components/AgGridAutocomplete/new';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useNumberOfTextRowsToDisplayOnGridCell from 'hooks/useNumberOfTextRowsToDisplayOnGridCell';
import useLightboxesControlContext from 'hooks/lightboxes/useLightboxesControl';

import { PERSONA, PERSONAS, LIFECYCLE, LIFECYCLES, PHASE, PHASES, CUSTOMER, CUSTOMERS, TAG, TAGS } from 'constants/common';
import { PROGRESS_MANUAL } from 'store/projects/constants';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const HEALTH_KEY = 'status_color';
const SUMMARY_KEY = 'status_summary';
const PROGRESS_KEY = 'progress';
const CUSTOMERS_KEY = 'customer';
const TAGS_KEY = 'tag';
const PHASE_KEY = 'phase';

const DATA = 'data';
const PROGRESS_TYPE = 'progress_type';
const JIRAS = 'Jiras';

const isManualType = equals(PROGRESS_MANUAL);
const defaultToEmptyArray = defaultTo([]);
const defaultToEmptyObject = defaultTo({});
const getIds = pluck('id');

const isProgressManual = pipe(defaultToEmptyObject, path([DATA, PROGRESS_TYPE]), isManualType);
const projectDoesNotHaveJiraIntegration = pipe(defaultToEmptyObject, path([DATA, JIRAS]), defaultToEmptyArray, isEmpty);

const useAdditionalFieldsColumnsDefinitions = ({
  getSystemFieldName,
  integrations,
  isReadOnly,
  handleMetadataCellDoubleClick,
  createMetadataMultiSelectOption,
  removeGridRow,
  rowHeight,
  canView,
  canUpdate,
  canDelete,
  updateProjectById,
}) => {
  const organization = useSelector(state => getOrganization(state));
  const organizationsAccessControl = useOrganizationsAccessControl();
  const orgHasJiraSuccessfullyIntegrated = useSelector(getOrgHasJiraIntegrated);
  const hasMultipleMetrics = useSelector(getHasMultipleMetrics);
  const hasProjectMetrics = useSelector(getHasProjectMetrics);
  const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);
  const hasMetricCharts = useSelector(getOrgHasMetricCharts);
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);

  const { getNumberOfTextRowsToDisplayOnGridCell } = useNumberOfTextRowsToDisplayOnGridCell(rowHeight);
  const { openMetricLightbox } = useLightboxesControlContext();
  const hasPersonas = canView(PERMISSION_FEATURES.personas);
  const hasLifecycles = canView(PERMISSION_FEATURES.lifecycles);

  const hasNewMetadataAutocompleteOnGrids = canView(PERMISSION_FEATURES.newRoadmapMetadataAutocompleteOnGrids);
  const shouldUseNewMetadataAutocomplete = hasNewMetadataAutocompleteOnGrids;

  const multilineTextCommonDefs = useMemo(
    () => ({
      editable: applyCheckUserCanUpdateRow(
        canUpdate,
        applyCheckIsCreatingOrViewingScenario(applyCheckIsEditable(checkIsEditable)),
      ),
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      cellRenderer: WYSIWYGCellRenderer,
      cellEditor: RemirrorWYSIWYGCellEditor,
      cellEditorParams: {
        maxHeight: 150,
        autoFocus: true,
      },
      width: 450,
      minWidth: 200,
      comparator: stringComparator,
      cellEditorPopup: true,
    }),
    [canUpdate],
  );

  const detailsColumnDef = useMemo(() => {
    return {
      ...multilineTextCommonDefs,
      headerName: 'Details',
      field: 'details',
      cellClass: 'align-items-top',
    };
  }, []);

  const linksColumnDef = useMemo(() => {
    return {
      ...multilineTextCommonDefs,
      headerName: 'Links',
      field: 'links',
      width: 400,
      cellClass: 'align-items-top',
    };
  }, []);

  const integrationIssueTypeHeaderFormatted = useMemo(() => {
    const hasJira = Boolean((integrations || []).find(integration => integration.integrationType === INTEGRATIONS_KEYS.JIRA));

    if (hasJira) {
      return 'Jira Issue Type';
    }

    return 'Integration Issue Type';
  }, [integrations]);

  const integrationIssueTypeColumnDefs = useMemo(
    () =>
      [integrations?.length > 0].filter(Boolean).map(() => ({
        field: 'integrationIssueType',
        headerName: integrationIssueTypeHeaderFormatted,
        cellClass: 'text-center',
        valueFormatter: formatIssueType,
        cellRenderer: TextCellRenderer,
        enableValue: true,
        editable: false,
      })),
    [integrations],
  );

  const integrationKeyColumnDefs = useMemo(
    () =>
      [integrations?.length > 0].filter(Boolean).map(() => ({
        ...textCenterAlignedColumnDef,
        width: 150,
        headerName: 'Integration Key',
        field: 'integration',
        filterParams: {
          values: ['(Blanks)', 'Has Integration'],
        },
        editable: false,
        orgIntegrations: integrations,
        keyCreator: ({ value }) => (value ? 'Has Integration' : '(Blanks)'),
        cellRenderer: IntegrationKeyCellRenderer,
        comparator: integrationComparator,
      })),
    [integrations],
  );

  const estimatedStartDateColumnDef = useMemo(
    () => ({
      ...dateColumnDef,
      field: 'estimated_start_date',
      headerName: 'Target start date',
      width: 100,
      headerClass: makeCellClassForCreatingOrViewingScenario(dateColumnDef.headerClass),
      cellClass: makeCellClassForCreatingOrViewingScenario(dateColumnDef.cellClass),
    }),
    [],
  );

  const deadlineColumnDef = useMemo(
    () => ({
      ...dateColumnDef,
      field: 'deadline',
      headerName: 'Target end date',
      width: 100,
      headerClass: makeCellClassForCreatingOrViewingScenario(dateColumnDef.headerClass),
      cellClass: makeCellClassForCreatingOrViewingScenario(dateColumnDef.cellClass),
    }),
    [],
  );

  const completedDateColumnDef = useMemo(
    () => ({
      ...dateColumnDef,
      field: 'completed_date',
      headerName: 'Completed Date',
      width: 100,
    }),
    [],
  );

  const predictedEndDateColumnDef = useMemo(
    () => ({
      ...dateColumnDef,
      field: 'predictedEndDate',
      headerName: 'Predicted end date',
      width: 100,
      editable: false,
    }),
    [],
  );

  const projectHealthColumnDef = useMemo(() => {
    return {
      ...healthColumnDef,
      headerName: getSystemFieldName(HEALTH_KEY),
      field: HEALTH_KEY,
      width: 110,
      minWidth: 110,
    };
  }, [getSystemFieldName]);

  const statusSummaryColumnDef = useMemo(
    () => ({
      ...multilineTextCommonDefs,
      headerName: getSystemFieldName(SUMMARY_KEY),
      field: SUMMARY_KEY,
      cellClass: 'align-items-top',
    }),
    [getSystemFieldName],
  );

  const progressColumnDef = useMemo(
    () => ({
      ...numericColumnDef,
      field: PROGRESS_KEY,
      headerName: getSystemFieldName(PROGRESS_KEY),
      cellRenderer: ProgressDisplayRenderer,
      cellRendererParams: {
        jiraIntegrated: orgHasJiraSuccessfullyIntegrated,
      },
      cellEditor: ProgressCellEditor,
      cellClassRules: {
        'over-limit': node => node.value > 1,
      },
      valueFormatter: progressValueFormatter,
      width: 120,
      editable: node => {
        const isEditable = applyCheckUserCanUpdateRow(
          canUpdate,
          applyCheckIsCreatingOrViewingScenario(applyCheckIsEditable(checkIsEditable)),
        )(node);

        if (!isEditable) {
          return false;
        }

        return !orgHasJiraSuccessfullyIntegrated || projectDoesNotHaveJiraIntegration(node) || isProgressManual(node);
      },
    }),
    [getSystemFieldName, orgHasJiraSuccessfullyIntegrated, canUpdate],
  );

  const projectPlanningStageColumnDef = useMemo(
    () => ({
      ...planningStageColumnDef,
      editable: false,
    }),
    [],
  );

  const phaseColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params =>
        getFormattedMetadataFieldOptions(
          params,
          PHASES,
          PHASE,
          organization,
          organizationsAccessControl,
          canView(PERMISSION_RESOURCES.roadmapCorp),
          hasMetadataRoadmaps,
        ),
      handleUpdate: updateProjectById,
      autocompleteParams: {
        name: PHASES,
        roadmapsKey: PHASE,
        levelOneFieldName: PHASE,
        getSystemFieldName,
      },
    });

    return {
      field: 'phaseTitle',
      headerName: getSystemFieldName(PHASE_KEY),
      cellRenderer: ChipCellRenderer,
      comparator: stringComparator,
      cellRendererParams: params => {
        return makeGetMetadataChipCellRendererParams(params.context.filteredMetadata.phases)(params);
      },
      ...(shouldUseNewMetadataAutocomplete
        ? metadataAutocompleteEditorColumnDef
        : {
            cellEditor: AgGridAutocomplete,
            cellEditorParams: params => ({
              autoFocus: true,
              options: params?.context?.filteredMetadata?.phases?.map(t => t.title),
            }),
          }),
      width: 120,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    };
  }, [handleMetadataCellDoubleClick]);

  // needs to have the popup to confirm deletion implemented
  const actionsColumnDef = useMemo(() => {
    return createMultipleActionsColumnDefGetter({
      field: 'actions',
      headerName: 'Actions',
      // TODO: PERMISSION
      getVisibility: !isReadOnly,
      actions: [
        {
          icon: DeleteIcon,
          color: materialColors.darkerGray,
          getVisibility: () => true,
          onClick: removeGridRow,
          disabled: params => !canDelete(PERMISSION_RESOURCES.project, { project: params.data }),
        },
      ],
    });
  }, [canDelete, isReadOnly, removeGridRow]);

  const customersColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params => params?.context?.filteredMetadata?.[CUSTOMERS],
      autocompleteParams: {
        name: CUSTOMERS,
        roadmapsKey: CUSTOMER,
        levelOneFieldName: CUSTOMER,
        getSystemFieldName,
        canAddNew: !isReadOnly,
        onCreateNew: createMetadataMultiSelectOption?.(CUSTOMERS),
        formatByRoadmap: false,
      },
      isMultiSelect: true,
    });

    return {
      field: 'customers',
      headerName: getSystemFieldName(CUSTOMERS_KEY),
      width: 120,
      labelField: 'name',
      keyCreator: ({ value }) => (value?.length ? value.map(c => c.name) : [null]).join('-'),
      cellRenderer: ChipsCellRenderer,
      showTooltip: true,
      cellRendererParams: {
        getNumberOfTextRowsToDisplayOnGridCell,
      },
      ...(shouldUseNewMetadataAutocomplete
        ? metadataAutocompleteEditorColumnDef
        : {
            cellEditor: MultiSelectCellEditor,
            cellEditorParams: params => ({
              options: params.context.filteredMetadata.customers,
              optionsMapper: { label: 'name', value: 'id' },
              createOption: createMetadataMultiSelectOption('customers'),
              // TODO: PERMISSION
              hideCreateOption: isReadOnly,
            }),
          }),
      cellEditorPopup: true,
      comparator: multiSelectComparator,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    };
  }, [getSystemFieldName, createMetadataMultiSelectOption, isReadOnly, handleMetadataCellDoubleClick]);

  const tagsColumnDef = useMemo(() => {
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params =>
        getFormattedMetadataFieldOptions(
          params,
          TAGS,
          TAG,
          organization,
          organizationsAccessControl,
          canView(PERMISSION_RESOURCES.roadmapCorp),
          hasMetadataRoadmaps,
        ),
      autocompleteParams: {
        name: TAGS,
        roadmapsKey: TAG,
        levelOneFieldName: TAG,
        getSystemFieldName,
        canAddNew: !isReadOnly,
        onCreateNew: createMetadataMultiSelectOption?.(TAGS),
      },
      isMultiSelect: true,
    });

    return {
      field: 'tags',
      headerName: getSystemFieldName(TAGS_KEY),
      width: 120,
      labelField: 'title',
      cellRenderer: ChipsCellRenderer,
      ...(shouldUseNewMetadataAutocomplete
        ? metadataAutocompleteEditorColumnDef
        : {
            cellEditor: MultiSelectCellEditor,
            cellEditorParams: params => ({
              options: params.context.filteredMetadata.tags,
              createOption: createMetadataMultiSelectOption('tags'),
              // TODO: PERMISSION
              hideCreateOption: isReadOnly,
            }),
          }),
      cellEditorPopup: true,
      comparator: multiSelectComparator,
      onCellDoubleClicked: handleMetadataCellDoubleClick,
    };
  }, [getSystemFieldName, createMetadataMultiSelectOption, isReadOnly, handleMetadataCellDoubleClick]);

  const updatedByColumnDef = useMemo(
    () => ({
      editable: false,
      width: 100,
      field: 'updatedByName',
      headerName: 'Updated by',
      cellClass: 'text-center',
      cellRenderer: TextCellRenderer,
      cellRendererParams: {
        textStyles: css`
          color: ${({ theme }) => theme.palette.text.disabled};
          font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
        `,
      },
    }),
    [],
  );

  const issueCountColumnDef = useMemo(
    () => ({
      ...numericColumnDef,
      ...sumNumericFieldAggColumnDef,
      editable: false,
      width: 80,
      field: 'issueCounts',
      headerName: 'Issue Count',
      cellRenderer: NumericCellRenderer,
      valueGetter: getIntegrationIssuesCount,
      cellRendererParams: {
        withoutZeros: true,
      },
    }),
    [],
  );

  const estimateTimeColumnDef = useMemo(
    () => ({
      ...numericColumnDef,
      ...sumNumericFieldAggColumnDef,
      editable: false,
      width: 80,
      field: 'timeEstimate',
      headerName: 'Time Estimate',
      cellRenderer: TimeStringRenderer(TextCellRenderer),
      cellRendererParams: {
        withoutZeros: true,
      },
    }),
    [],
  );

  const storyPointsColumnDef = useMemo(
    () => ({
      ...numericColumnDef,
      ...sumNumericFieldAggColumnDef,
      editable: false,
      width: 80,
      field: 'storyPoint',
      headerName: 'Reported Points',
      cellRenderer: NumericCellRenderer,
      valueGetter: getIntegrationStoryPoints,
      cellRendererParams: {
        withoutZeros: true,
      },
    }),
    [],
  );

  const OkrProgressColumnRenderer = useMemo(() => getOkrProgressDisplayRenderer, []);

  const okrProgressColumnDef = useMemo(
    () => ({
      ...numericColumnDef,
      field: 'okr_progress',
      headerName: 'OKR Progress',
      cellRenderer: OkrProgressColumnRenderer,
      width: 80,
    }),
    [OkrProgressColumnRenderer],
  );

  const orgDoesNotHaveProjectMetricsOrMultipleMetricsActive = not(hasProjectMetrics) || not(hasMultipleMetrics);

  const metricColumnDef = useMemo(
    () => ({
      ...(orgDoesNotHaveProjectMetricsOrMultipleMetricsActive ? { editable: false } : {}),
      width: 80,
      field: hasMultipleMetrics ? 'metrics' : 'metric_name',
      headerName: 'Metric(s)',
      labelField: 'name',
      cellRenderer: hasMultipleMetrics ? ChipsCellRenderer : MetricNameRenderer,
      showTooltip: true,
      chipVariant: 'outlined',
      cellRendererParams: {
        hasAdvancedMetricReporting,
        setSelectedMetricId: openMetricLightbox,
        getNumberOfTextRowsToDisplayOnGridCell,
        onChipClick: metric => hasMetricCharts && openMetricLightbox(metric?.id),
      },
      cellEditor: MultiSelectCellEditor,
      cellEditorPopup: true,
      cellEditorParams: params => ({
        optionsMapper: { label: 'name', value: 'id' },
        options: params.context.filteredMetadata.metrics,
        createOption: createMetadataMultiSelectOption('metrics'),
      }),
      onCellDoubleClicked: handleMetadataCellDoubleClick,
      comparator: multiSelectComparator,
    }),
    [
      orgDoesNotHaveProjectMetricsOrMultipleMetricsActive,
      hasMultipleMetrics,
      hasAdvancedMetricReporting,
      handleMetadataCellDoubleClick,
      getNumberOfTextRowsToDisplayOnGridCell,
    ],
  );

  const metricActualValueColumnDef = useMemo(
    () =>
      not(hasMultipleMetrics)
        ? [
            {
              ...numericColumnDef,
              editable: false,
              width: 80,
              field: 'metric_current',
              headerName: 'Actual Value',
              cellRenderer: TextCellRenderer,
            },
          ]
        : [],
    [hasMultipleMetrics],
  );

  const metricTargetValueColumnDef = useMemo(
    () =>
      not(hasMultipleMetrics)
        ? [
            {
              ...numericColumnDef,
              editable: false,
              width: 80,
              field: 'metric_target',
              headerName: 'Target Value',
              cellRenderer: TextCellRenderer,
            },
          ]
        : [],
    [hasMultipleMetrics],
  );

  const personasColumnDef = useMemo(() => {
    if (!hasPersonas) return [];

    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params =>
        getFormattedMetadataFieldOptions(
          params,
          PERSONAS,
          PERSONA,
          organization,
          organizationsAccessControl,
          canView(PERMISSION_RESOURCES.roadmapCorp),
          hasMetadataRoadmaps,
        ),
      autocompleteParams: {
        name: PERSONAS,
        roadmapsKey: PERSONA,
        levelOneFieldName: PERSONA,
        getSystemFieldName,
      },
      isMultiSelect: true,
    });

    return [
      {
        field: PERSONAS,
        headerName: `${getSystemFieldName('persona', true)}`,
        labelField: 'title',
        cellRenderer: ChipsCellRenderer,
        cellRendererParams: {
          getNumberOfTextRowsToDisplayOnGridCell,
        },
        ...(shouldUseNewMetadataAutocomplete
          ? metadataAutocompleteEditorColumnDef
          : {
              cellEditor: MultiSelectCellEditor,
              cellEditorParams: params => ({
                options: params.context.filteredMetadata.personas,
                optionsMapper: { label: 'title', value: 'id' },
                hideCreateOption: true,
              }),
            }),
        cellEditorPopup: true,
        valueGetter: ({ context, data = {}, colDef } = {}) => {
          const personas = defaultToEmptyArray(data?.[colDef.field]);
          const projectPersonasIds = getIds(personas);

          return context.metadata.personas.filter(({ id }) => projectPersonasIds.includes(id));
        },
        showTooltip: true,
        chipVariant: 'outlined',
        onCellDoubleClicked: handleMetadataCellDoubleClick,
      },
    ];
  }, [getSystemFieldName, hasPersonas, getNumberOfTextRowsToDisplayOnGridCell]);

  const lifecyclesColumnDef = useMemo(() => {
    if (!hasLifecycles) return [];
    const metadataAutocompleteEditorColumnDef = getMetadataAutocompleteEditorColumnDef({
      getItems: params =>
        getFormattedMetadataFieldOptions(
          params,
          LIFECYCLES,
          LIFECYCLE,
          organization,
          organizationsAccessControl,
          canView(PERMISSION_RESOURCES.roadmapCorp),
          hasMetadataRoadmaps,
        ),
      autocompleteParams: {
        name: LIFECYCLES,
        roadmapsKey: LIFECYCLE,
        levelOneFieldName: LIFECYCLE,
        getSystemFieldName,
      },
      isMultiSelect: true,
    });

    return [
      {
        field: LIFECYCLES,
        headerName: `${getSystemFieldName('lifecycle', true)}`,
        labelField: 'title',
        cellRenderer: ChipsCellRenderer,
        cellRendererParams: {
          getNumberOfTextRowsToDisplayOnGridCell,
        },
        ...(shouldUseNewMetadataAutocomplete
          ? metadataAutocompleteEditorColumnDef
          : {
              cellEditor: MultiSelectCellEditor,
              cellEditorParams: params => ({
                options: params.context.filteredMetadata.lifecycles,
                optionsMapper: { label: 'title', value: 'id' },
                hideCreateOption: true,
              }),
            }),
        cellEditorPopup: true,
        valueGetter: ({ context, data = {}, colDef } = {}) => {
          const lifecycles = defaultToEmptyArray(data?.[colDef.field]);
          const projectLifecyclesIds = getIds(lifecycles);

          return context.metadata.lifecycles.filter(({ id }) => projectLifecyclesIds.includes(id));
        },
        showTooltip: true,
        chipVariant: 'outlined',
        onCellDoubleClicked: handleMetadataCellDoubleClick,
      },
    ];
  }, [getSystemFieldName, hasLifecycles]);

  return useMemo(
    () => [
      detailsColumnDef,
      linksColumnDef,
      ...integrationIssueTypeColumnDefs,
      ...integrationKeyColumnDefs,
      estimatedStartDateColumnDef,
      deadlineColumnDef,
      completedDateColumnDef,
      predictedEndDateColumnDef,
      projectHealthColumnDef,
      statusSummaryColumnDef,
      progressColumnDef,
      phaseColumnDef,
      projectPlanningStageColumnDef,
      // actionsColumnDef,
      customersColumnDef,
      tagsColumnDef,
      updatedByColumnDef,
      issueCountColumnDef,
      storyPointsColumnDef,
      estimateTimeColumnDef,
      okrProgressColumnDef,
      metricColumnDef,
      ...metricActualValueColumnDef,
      ...metricTargetValueColumnDef,
      ...personasColumnDef,
      ...lifecyclesColumnDef,
    ],
    [
      detailsColumnDef,
      linksColumnDef,
      integrationIssueTypeColumnDefs,
      integrationKeyColumnDefs,
      estimatedStartDateColumnDef,
      deadlineColumnDef,
      completedDateColumnDef,
      predictedEndDateColumnDef,
      projectHealthColumnDef,
      statusSummaryColumnDef,
      progressColumnDef,
      actionsColumnDef,
      customersColumnDef,
      tagsColumnDef,
      updatedByColumnDef,
      issueCountColumnDef,
      storyPointsColumnDef,
      estimateTimeColumnDef,
      okrProgressColumnDef,
      metricColumnDef,
      metricActualValueColumnDef,
      metricTargetValueColumnDef,
      projectPlanningStageColumnDef,
      phaseColumnDef,
      personasColumnDef,
      lifecyclesColumnDef,
    ],
  );
};

export default useAdditionalFieldsColumnsDefinitions;
