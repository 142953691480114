import React from 'react';
import styled from 'styled-components';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';

import MicroChip from 'design-system/atoms/MicroChip/index';

import TextDeprecated from 'design-system/atoms/TextDeprecated';

const retrieveTextualValue = value => (isString(value) ? value : value?.title ?? '');

const retrieveColorEntity = (data = {}, field) => {
  const originalFieldName = field.split('Title')[0];

  return data[originalFieldName];
};

const GroupCellRenderer = label => params => {
  const value = retrieveTextualValue(params.value);

  const isGroupWithContent = params.node.group && 'value' in params;

  if (isGroupWithContent && label && !isArray(params.value)) {
    return (
      <TextDeprecated breakwords={params.colDef && params.colDef.autoHeight} inline color="#303030" size="small">
        {label}: {String(value).trim() || 'Undefined'}
      </TextDeprecated>
    );
  }

  const colorEntity = params.entity || retrieveColorEntity(params.data, params.colDef.field);

  if (value && colorEntity && colorEntity.color) {
    return (
      <MicroChip color={colorEntity.color} label={value} fullWidth style={{ margin: 0, width: 'auto' }}>
        {value}
      </MicroChip>
    );
  }

  const text = (
    <TextDeprecated breakwords={params.colDef && params.colDef.autoHeight} color="#303030" size="small">
      {value}
    </TextDeprecated>
  );

  if (params.colDef.onTextClick) {
    return (
      <TextWithLinkContainer>
        {text}
        <RightPositionedIconButton onClick={() => params.colDef.onTextClick(params)}>
          <OpenInNewIcon style={{ fontSize: 14 }} />
        </RightPositionedIconButton>
      </TextWithLinkContainer>
    );
  }

  return text;
};

export default GroupCellRenderer;

const TextWithLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -16px;
  padding-left: 40px;
`;

const RightPositionedIconButton = styled(props => <IconButton {...props}>{props.children}</IconButton>)`
  &&&& {
    position: relative;
    visibility: hidden;
    padding: 3px;
    font-size: 1.125rem;
  }
`;
