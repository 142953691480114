import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { BET, INITIATIVE } from 'constants/projects';

import { DEFAULT_GROUP_OPTION } from 'store/projects/helpers/groupOptions';

/**
 * Get the available options to populate the group by dropdowns.
 * This helper removes the previous selected options in the subjacent dropdowns.
 *
 * @param {Array} selectedGroupOptions The options selected on the group by dropdowns
 * @param {Number} selectedGroupIndex The current selected group index to be evaluated
 * @param {Array} groupByOptions All the possible options to be used
 *
 * @return {Array} the available option for the current group by dropdown
 * */
const getSuggestionsForGroupByDropdown = (selectedGroupOptions, selectedGroupIndex, groupByOptions) => {
  if (selectedGroupIndex === 0) {
    return groupByOptions;
  }

  const previousOptions = selectedGroupOptions.slice(0, selectedGroupIndex);
  const previousGroupKeys = compact(previousOptions.map(groupOption => groupOption.key));

  // filter the options already selected for the previous groups
  // if any of the previous group has initiative selected the followings should not display bet as option
  return [DEFAULT_GROUP_OPTION, ...groupByOptions]
    .filter(option => !previousGroupKeys.includes(option.key))
    .filter(option => (previousGroupKeys.includes(INITIATIVE) ? option.key !== BET : true));
};

/**
 * Check if the following group by dropdown is going to be displayed.
 *
 * @param {Array} selectedGroupOptions The options selected on the group by dropdowns
 * @param {Number} selectedGroupIndex The current selected group index to be evaluated
 *
 * @return {Boolean} true if the following group by dropdown is displayed
 * */
const hasNextGroupByDropdown = (selectedGroupOptions, selectedGroupIndex) => {
  const previousOptions = selectedGroupOptions.slice(0, selectedGroupIndex);

  return !isEmpty(previousOptions) && previousOptions.every(option => !isNil(option.key));
};

export { getSuggestionsForGroupByDropdown, hasNextGroupByDropdown };
