import { toast } from 'react-toastify';

import getSystemFieldName from 'utils/getSystemFieldName';
import { getDefaultErrorMessage } from './errorMessages';

export default (err, type, systemFieldsNames) => {
  const typeSystemFieldName = getSystemFieldName(type, systemFieldsNames, false);

  toast(getDefaultErrorMessage(typeSystemFieldName));

  throw err;
};
