import styled from 'styled-components';

import { spacing } from 'design-system/theme';
import Text from 'design-system/atoms/Text';
import TextDeprecated from 'design-system/atoms/TextDeprecated';
import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete';

const Container = styled.div``;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${spacing(1.5)}px ${spacing(3)}px ${spacing(1.5)}px ${spacing(4.5)}px;
`;

const GroupAndLimitContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex: 1;
  width: 0;
`;

const Controls = styled.div`
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  width: 0;
`;

const ControlItem = styled.div``;

const LoadingContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 50px;

  p {
    margin-left: 10px;
  }
`;

const PageTitle = styled(Text)`
  margin: 0 ${spacing(4)}px;
  text-align: center;
`;

const SyledGroupByAutocomplete = styled(GroupByAutocomplete)`
  width: 200px;
`;

const LimitResultsWarning = styled(TextDeprecated)`
  &&&& {
    padding-left: 10px;
    font-size: ${props => props.theme.typography.fontSizeSmall}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
  }
`;

export {
  Container,
  ControlsContainer,
  LimitResultsWarning,
  SyledGroupByAutocomplete,
  PageTitle,
  LoadingContainer,
  ControlItem,
  Controls,
  GroupAndLimitContainer,
};
