import { BET_PLACEHOLDER_ID, EDITABLE_METADATA_FIELDS, INITIATIVE_PLACEHOLDER_ID } from '../../../constants';

const isPlaceholderRow = uniqueId => [BET_PLACEHOLDER_ID, INITIATIVE_PLACEHOLDER_ID].some(id => uniqueId?.includes(id));

const isTreeViewParentRow = (data, context) => context?.isTreeView && data?.layer > context?.displayLayer;

/**
 * Checks if a cell is editable, based on if it is a group row or if it's
 * a field that can be editable when using DoD.
 * This function is passed directly to the `editable` field of
 * AgGrid's column defs. For more information check the official AgGrid
 * documentation: https://www.ag-grid.com/react-data-grid/cell-editing/#enable-editing
 */
const checkIsEditable = ({ data, context, colDef }) => {
  if (context.isParentDragon) {
    const fieldToCheck = colDef.field;

    if (EDITABLE_METADATA_FIELDS.includes(fieldToCheck)) {
      // Mommy DoD should not inline edit metadata fields
      return false;
    }
  }

  return !data?.group && !isPlaceholderRow(data?.uniqueId) && !isTreeViewParentRow(data, context);
};

export default checkIsEditable;
