import parseArrayIntoObject from './parseArrayIntoObject';

/**
 * @param {Array.Array} data
 * @param {string} selectedColumn
 * @param {Object} selectedRowData
 * @param {Array.Object} columns
 * @param {Array.Object} rowData
 * @returns {Array}
 */
export default (data, selectedColumn, selectedRowIndex, columns, rowData) => {
  if (!data
    || !(data instanceof Array)
    || !data.length
    || !selectedColumn
    || selectedRowIndex === null
    || selectedRowIndex === undefined
    || !columns
  ) {
    return;
  }

  if (selectedRowIndex >= 0) {
    const transformedData = data.map((row, index) => {
      const resource = rowData[selectedRowIndex + index];

      const update = parseArrayIntoObject(row, columns, selectedColumn);

      if (!Object.keys(update).length) {
        // if there is nothing to update or create
        return null;
      }

      if (resource && 'rowIndex' in resource) {
        return {
          rowIndex: resource.rowIndex,
          ...update,
        };
      }

      return update;
    }).filter(row => row !== null);

    return transformedData.reduce((final, row) => {
      if (!('rowIndex' in row)) {
        final.create.push(row);
      } else {
        final.update.push(row);
      }

      final.rawData = data;

      return final;
    }, { update: [], create: [] });
  }
};
