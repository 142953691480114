import React from 'react';
import PropTypes from 'prop-types';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import Checkbox from 'design-system/atoms/Checkbox/index';
import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';


const MultiSelectDropdown = ({ items = [], selected = [], onChange, label, ...props }) => {
  const id = React.useRef(`multi-select-dropdown-${Math.random()}`);

  return (
    <FormControl {...props}>
      <InputLabel htmlFor={id.current}>{selected.length === 0 ? `All ${label}` : ''}</InputLabel>
      <Select
        {...props}
        multiple
        value={selected}
        onChange={e => onChange(e.target.value)}
        renderValue={selected => selected.map(item => item || 'Empty').join(', ')}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
          transformOrigin: { vertical: 'top', horizontal: 'center' },
        }}
        inputProps={{ id: id.current }}
        style={{ color: 'rgb(88, 204, 198)' }}
      >
        {items.map(item => (
          <StyledMenuItemDropdown key={item} value={item} disableGutters>
            <Checkbox checked={selected.indexOf(item) > -1} />
            <ListItemText primary={item || <span style={{ color: '#ccc' }}>Empty</span>} style={{ paddingLeft: 0 }} />
          </StyledMenuItemDropdown>
        ))}
      </Select>
    </FormControl>
  );
};

MultiSelectDropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default MultiSelectDropdown;
