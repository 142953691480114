import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IDEA_LAYER, BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import useProjectsDropdownContext from 'hooks/useProjectsDropdownContext';

const NULL_ID = 'null-id';
const NEW_ID = 'new-id';

const clickedOutside = (ref, event) => !ref.current?.contains(event.target);

const useProjectsDropdown = (
  componentId,
  {
    onProjectSelect,
    onProjectCreation,
    onClickOutside,
    nullOption,
    footerComponent,
    showShouldShowCompletedSwitcher,
    treeComponentProps,
    getContainerPosition,
    projectRowHeight,
    openOnInit = false,
  } = {},
) => {
  const { register, unregister } = useProjectsDropdownContext();

  const inputRef = useRef(null);

  const dropDownRef = useRef(null);

  const [data, setData] = useState({
    [IDEA_LAYER]: [],
    [INITIATIVE_LAYER]: [],
    [BET_LAYER]: [],
  });

  const [displayLayer, setDisplayLayer] = useState(IDEA_LAYER);

  const [isLoading, setIsLoading] = useState(false);

  const [treeIsOpen, setTreeIsOpen] = useState(openOnInit);

  const [shouldShowCompleted, setShouldShowCompleted] = useState(false);

  const closeTree = useCallback(() => {
    setTreeIsOpen(false);
  }, []);

  const decoratedOnProjectSelect = useCallback(
    (project, bag) => {
      if (onProjectSelect) {
        onProjectSelect(project, { ...bag, closeTree });
      }
    },
    [onProjectSelect, closeTree],
  );

  const decoratedOnProjectCreation = useCallback(
    (project, bag) => {
      if (onProjectCreation) {
        onProjectCreation(project, { ...bag, closeTree });
      }
    },
    [onProjectCreation, closeTree],
  );

  const handleProjectClick = useCallback(
    ({ event = {}, ...params } = {}) => {
      if (!params.data) return;

      if ([BET_LAYER, INITIATIVE_LAYER, IDEA_LAYER].includes(params.data?.layer)) {
        return decoratedOnProjectSelect(params?.data, { params, event });
      }

      if (params.data?.id === NEW_ID) {
        return decoratedOnProjectCreation(params.data.title, { params, event });
      }

      if (params.data?.id === NULL_ID) {
        return decoratedOnProjectSelect(null, { params, event });
      }
    },
    [decoratedOnProjectSelect, decoratedOnProjectCreation],
  );

  const hookPayload = useMemo(
    () => ({
      inputRef,
      dropDownRef,
      data,
      setData,
      displayLayer,
      setDisplayLayer,
      isLoading,
      setIsLoading,
      treeIsOpen,
      setTreeIsOpen,
      shouldShowCompleted,
      setShouldShowCompleted,
      onProjectCreation: onProjectCreation && decoratedOnProjectCreation,
      nullOption,
      footerComponent,
      closeTree,
      showShouldShowCompletedSwitcher,
      treeComponentProps,
      getContainerPosition,
      projectRowHeight,
      handleProjectClick,
    }),
    [
      inputRef.current,
      data,
      displayLayer,
      isLoading,
      treeIsOpen,
      shouldShowCompleted,
      footerComponent,
      closeTree,
      showShouldShowCompletedSwitcher,
      treeComponentProps,
      getContainerPosition,
      projectRowHeight,
      handleProjectClick,
    ],
  );

  const handleClickOutside = event => {
    if (clickedOutside(inputRef, event) && clickedOutside(dropDownRef, event)) {
      setTreeIsOpen(false);

      if (onClickOutside) {
        onClickOutside();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [inputRef.current, dropDownRef.current]);

  useEffect(() => {
    register(componentId, hookPayload);
  }, [register, componentId, hookPayload]);

  useEffect(
    () => () => {
      unregister(componentId);
    },
    [unregister, componentId],
  );

  return hookPayload;
};

export default useProjectsDropdown;
