import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SvgComponent(props) {
  // const height = Math.ceil((props.style?.height || 16) * 0.875);

  return (
    <SvgIcon width={24} height={24} viewBox="0 -960 960 960" {...props}>
      {/* eslint-disable-next-line max-len */}
      <path d="M480-200v-80h120l142-200-142-200H200v120h-80v-120q0-33 23.5-56.5T200-760h400q20 0 37.5 9t28.5 25l174 246-174 246q-11 16-28.5 25t-37.5 9H480Zm-9-280ZM200-160v-120H80v-80h120v-120h80v120h120v80H280v120h-80Z" />
    </SvgIcon>
  );
}
