import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={13.5}
      viewBox="0 0 18 13.5"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path fill="none" d="M0 0h18v13.5H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          // eslint-disable-next-line max-len
          d="M18 .844v11.812a.842.842 0 01-.844.844h-.281v-.7a.423.423 0 00-.422-.422h-1.406a.423.423 0 00-.422.422v.7H3.375v-.7a.423.423 0 00-.422-.422H1.547a.423.423 0 00-.422.422v.7H.844A.842.842 0 010 12.656V.844A.842.842 0 01.844 0h.281v.7a.423.423 0 00.422.422h1.406A.423.423 0 003.375.7V0h11.25v.7a.423.423 0 00.422.422h1.406A.423.423 0 0016.875.7V0h.281A.842.842 0 0118 .844M3.375 2.672a.423.423 0 00-.422-.422H1.547a.423.423 0 00-.422.422v1.406a.423.423 0 00.422.422h1.406a.423.423 0 00.422-.422zm0 3.375a.423.423 0 00-.422-.422H1.547a.423.423 0 00-.422.422v1.406a.423.423 0 00.422.422h1.406a.423.423 0 00.422-.422zm0 3.375A.423.423 0 002.953 9H1.547a.423.423 0 00-.422.422v1.406a.423.423 0 00.422.422h1.406a.423.423 0 00.422-.422zM13.5 1.547a.423.423 0 00-.422-.422H4.922a.423.423 0 00-.422.422v4.219a.423.423 0 00.422.422h8.156a.423.423 0 00.422-.422zm0 6.188a.423.423 0 00-.422-.422H4.922a.423.423 0 00-.422.422v4.218a.423.423 0 00.422.422h8.156a.423.423 0 00.422-.422zm3.375-5.063a.423.423 0 00-.422-.422h-1.406a.423.423 0 00-.422.422v1.406a.423.423 0 00.422.422h1.406a.423.423 0 00.422-.422zm0 3.375a.423.423 0 00-.422-.422h-1.406a.423.423 0 00-.422.422v1.406a.423.423 0 00.422.422h1.406a.423.423 0 00.422-.422zm0 3.375A.423.423 0 0016.453 9h-1.406a.423.423 0 00-.422.422v1.406a.423.423 0 00.422.422h1.406a.423.423 0 00.422-.422z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
