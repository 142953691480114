import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { not } from 'ramda';
import MoreHoriz from 'design-system/atoms/DragonIcons/MoreHoriz';
import SettingsIcon from '@material-ui/icons/Settings';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Switch from '@material-ui/core/Switch';
import { FeatureFlags } from '@dragonboat/config';

import AddProjectButton from 'components/AddProjectButton';
import lightenDarkenColor from 'design-system/utils/lightenDarkenColor';
import DragonIconButton from 'design-system/atoms/DragonIconButton/index';
import DragonMenu from 'design-system/atoms/DragonMenu/index';
import ButtonV2 from 'design-system/atoms/ButtonV2/index';

import theme from 'design-system/theme';
import { updateState as updateAllocationLsState } from 'store/allocationReport';
import {
  PLANNED,
  REPORTED,
  COMPLETED,
  TARGET,
  BAR_CHART,
  PIE_CHART,
  DISPLAY_LEGEND_INLINE,
  PREFERENCES,
  DOWNLOAD_PNG,
  STACKED_BY_TIMEFRAME_CHART,
  STACKED_BY_TIMEFRAME_PERCENTAGE_CHART,
  ACTUAL,
  STACKED_CHART,
  STACKED_CHART_PERCENTAGE,
  TABLE,
} from './constants';
import downloadAllocationPagePNG from './helpers/downloadAllocationPagePNG';
import DashboardsHeaderOptions from '../Dashboards/HeaderOptions';
import ProjectsGlobalSearchInput from 'containers/ProjectsGlobalSearchInput';
import { clearPageActiveView } from 'store/userViews/actions';
import { DASHBOARDS_PAGE } from 'constants/filters';
import { organizationHasDashboardsEnabled } from 'utils/organizations/dashboards';
import useNavigationToPathWithGoBackUrl from 'hooks/useNavigationToPathWithGoBackUrl';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useSystemFields from 'hooks/useSystemFields';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { hasActualAllocationOption } from 'store/allocationReport/helpers';

const pieChartOption = allocationState => ({
  key: PIE_CHART,
  label: (
    <SwitchMenuOption>
      <Switch color="primary" checked={allocationState.showPieChart} />
      Pie Chart
    </SwitchMenuOption>
  ),
});

const displayLegendInlineOption = allocationState => ({
  key: DISPLAY_LEGEND_INLINE,
  label: (
    <SwitchMenuOption>
      <Switch color="primary" checked={allocationState.displayLegendInline} />
      Display Legend Inline
    </SwitchMenuOption>
  ),
});

const stackedChartOption = allocationState => ({
  key: STACKED_CHART,
  label: (
    <SwitchMenuOption>
      <Switch color="primary" checked={allocationState.showStackedChart} />
      Stacked Chart
    </SwitchMenuOption>
  ),
});

const stackedChartPercentageOption = allocationState => ({
  key: STACKED_CHART_PERCENTAGE,
  label: (
    <SwitchMenuOption>
      <Switch color="primary" checked={allocationState.showStackedChartPercentage} />
      Stacked Chart %
    </SwitchMenuOption>
  ),
});

const tableOption = allocationState => ({
  key: TABLE,
  label: (
    <SwitchMenuOption>
      <Switch color="primary" checked={allocationState.showTable} />
      Table
    </SwitchMenuOption>
  ),
});

export default ({
  allocationState,
  isReadOnlyUser,
  organization,
  selectedView,
  search,
  setSearch,
  history,
  isAccessDenied,
  isParentDragon,
}) => {
  const dispatch = useDispatch();
  const { canView } = usePermissions();

  const isDashboardsEnabled = organizationHasDashboardsEnabled(organization);

  const [getSystemFieldName] = useSystemFields();
  const { navigateToWithGoBackUrl } = useNavigationToPathWithGoBackUrl();

  const isAllocationReportPage = selectedView && selectedView.includes('/dashboard/allocation-report');
  const isDashboardsPage = selectedView && selectedView.includes('/dashboard/dashboards');
  const isHomePage = selectedView && selectedView.includes('/dashboard/home');
  const isTemplatesPage = window?.location?.pathname.includes('/dashboard/templates');
  const isSnapshotPage = selectedView && selectedView.includes('/dashboard/snapshot');
  const isUserDashboardsPage = selectedView && selectedView.includes('/user-dashboards');

  const hasCompletedAllocation = useFeatureFlags([FeatureFlags.HAS_COMPLETED_ALLOCATION]);

  const canViewActualAllocation = canView(PERMISSION_FEATURES.actualAllocationByTime);
  const hasActualAllocationByTime = canViewActualAllocation && hasActualAllocationOption(allocationState.selectedGroupBy?.key);
  const isActualAllocationEnabled = canViewActualAllocation && allocationState.showActual === true;

  const useSearch = useMemo(() => {
    if (isHomePage || isTemplatesPage || isAllocationReportPage || isSnapshotPage || isUserDashboardsPage) {
      return false;
    }

    return !!setSearch;
  }, [setSearch, isHomePage, isTemplatesPage, isUserDashboardsPage]);

  const gridTemplateColumns = () => {
    if (isReadOnlyUser && useSearch) return '140px 24px';
    if ((isHomePage || isUserDashboardsPage) && isDashboardsEnabled) return '1fr 0';
    if (isHomePage || isTemplatesPage || isUserDashboardsPage) return '140px 0';
    // todo: address in a different story for styling
    if (isParentDragon) return useSearch ? '140px 140px 1fr' : '140px 1fr';
    return useSearch ? '30px 140px 1fr' : '30px 1fr';
  };
  const _onShowMoreOptions = e => {
    switch (selectedView) {
      case '/dashboard/allocation-report':
        setAllocationReportMoreOptionsAncherEl(e.currentTarget);
        break;

      default:
        break;
    }
  };
  const ALLOCATION_REPORT_MORE_OPTIONS = [
    ...(organization.has_reported_allocation_report
      ? [
          {
            key: PLANNED,
            label: (
              <SwitchMenuOption>
                <Switch color="primary" checked={allocationState.showPlanned} />
                Planned
              </SwitchMenuOption>
            ),
          },
          {
            key: REPORTED,
            label: (
              <SwitchMenuOption>
                <Switch color="primary" checked={allocationState.showReported} />
                Reported
              </SwitchMenuOption>
            ),
          },
          {
            key: TARGET,
            label: (
              <SwitchMenuOption>
                <Switch color="primary" checked={allocationState.showTarget} />
                Target
              </SwitchMenuOption>
            ),
          },
          ...(hasCompletedAllocation
            ? [
                {
                  key: COMPLETED,
                  label: (
                    <SwitchMenuOption>
                      <Switch color="primary" checked={allocationState.showCompleted} />
                      Completed
                    </SwitchMenuOption>
                  ),
                },
              ]
            : []),
          ...(hasActualAllocationByTime
            ? [
                {
                  key: ACTUAL,
                  label: (
                    <SwitchMenuOption>
                      <Switch color="primary" checked={allocationState.showActual} />
                      Actual
                    </SwitchMenuOption>
                  ),
                },
              ]
            : []),
          ...(!isActualAllocationEnabled
            ? [
                {
                  key: BAR_CHART,
                  label: (
                    <SwitchMenuOption>
                      <Switch color="primary" checked={allocationState.showBarChart} />
                      Bar Chart
                    </SwitchMenuOption>
                  ),
                },
                pieChartOption(allocationState),
                {
                  key: STACKED_BY_TIMEFRAME_CHART,
                  label: (
                    <SwitchMenuOption>
                      <Switch color="primary" checked={allocationState.showStackedByTimeframeChart} />
                      Stacked by {getSystemFieldName('timeframe', false)}
                    </SwitchMenuOption>
                  ),
                },
                ...(allocationState.showPieChart ? [displayLegendInlineOption(allocationState)] : []),

                // TODO: {
                //   key: STACKED_BY_TIMEFRAME_PERCENTAGE_CHART,
                //   label: (
                //     <SwitchMenuOption>
                //       <Switch color="primary" checked={allocationState.showStackedByTimeframePercentageChart} />
                //        % Stacked by{' '}
                //       {getSystemFieldName('timeframe', false)}
                //     </SwitchMenuOption>
                //   ),
                // },
              ]
            : [
                tableOption(allocationState),
                pieChartOption(allocationState),
                ...(allocationState.showPieChart ? [displayLegendInlineOption(allocationState)] : []),
                stackedChartPercentageOption(allocationState),
                stackedChartOption(allocationState),
              ]),
        ]
      : []),
    ...(!isActualAllocationEnabled
      ? [
          {
            key: PREFERENCES,
            icon: <SettingsIcon />,
            label: 'Preferences',
          },
        ]
      : []),
    {
      key: DOWNLOAD_PNG,
      icon: <PhotoCamera />,
      label: 'Download PNG',
    },
  ];
  const [allocationReportMoreOptionsAncherEl, setAllocationReportMoreOptionsAncherEl] = useState(null);
  const _handleSelectAllocationMoreOptions = option => {
    switch (option.key) {
      case PLANNED:
        dispatch(updateAllocationLsState({ showPlanned: !allocationState.showPlanned }));
        break;
      case REPORTED:
        dispatch(updateAllocationLsState({ showReported: !allocationState.showReported }));
        break;
      case COMPLETED:
        dispatch(updateAllocationLsState({ showCompleted: !allocationState.showCompleted }));
        break;
      case TARGET:
        dispatch(updateAllocationLsState({ showTarget: !allocationState.showTarget }));
        break;
      case ACTUAL:
        dispatch(updateAllocationLsState({ showActual: !allocationState.showActual }));
        break;
      case BAR_CHART:
        dispatch(updateAllocationLsState({ showBarChart: !allocationState.showBarChart }));
        break;
      case PIE_CHART:
        dispatch(updateAllocationLsState({ showPieChart: !allocationState.showPieChart }));
        break;
      case TABLE:
        dispatch(updateAllocationLsState({ showTable: !allocationState.showTable }));
        break;
      case DISPLAY_LEGEND_INLINE:
        dispatch(updateAllocationLsState({ displayLegendInline: !allocationState.displayLegendInline }));
        break;
      case STACKED_CHART:
        dispatch(updateAllocationLsState({ showStackedChart: !allocationState.showStackedChart }));
        break;
      case STACKED_CHART_PERCENTAGE:
        dispatch(updateAllocationLsState({ showStackedChartPercentage: !allocationState.showStackedChartPercentage }));
        break;
      case STACKED_BY_TIMEFRAME_CHART:
        dispatch(updateAllocationLsState({ showStackedByTimeframeChart: !allocationState.showStackedByTimeframeChart }));
        break;
      case STACKED_BY_TIMEFRAME_PERCENTAGE_CHART:
        dispatch(
          updateAllocationLsState({
            showStackedByTimeframePercentageChart: !allocationState.showStackedByTimeframePercentageChart,
          }),
        );
        break;
      case PREFERENCES:
        dispatch(updateAllocationLsState({ showPreferences: true }));
        break;
      case DOWNLOAD_PNG:
        downloadAllocationPagePNG();
        break;

      default:
        break;
    }
  };
  const _handleCreateNewDashboard = () => {
    dispatch(clearPageActiveView(DASHBOARDS_PAGE));
    navigateToWithGoBackUrl('/dashboard/dashboards');
  };
  const _renderAddDashboardButton = () =>
    isDashboardsEnabled && (
      <CreateDashboardButton
        id="add-dashboard-button"
        color={theme.palette.background.white}
        background={theme.palette.newLayout.background.fountainBlue}
        hover={{ background: lightenDarkenColor(theme.palette.background.primary, -20) }}
        onClick={_handleCreateNewDashboard}
        size="small"
      >
        Create dashboard
      </CreateDashboardButton>
    );

  const shouldRenderAddNewProjectButton = not(isHomePage) && not(isTemplatesPage) && not(isUserDashboardsPage);

  // all header options should be hidden if user cannot see view
  if (isAccessDenied) return '';

  /**
   * Dashboards page has a different header options
   */
  if (isDashboardsPage) return <DashboardsHeaderOptions />;

  return (
    <>
      <Wrapper gridTemplateColumns={gridTemplateColumns}>
        {!isReadOnlyUser && (
          <>
            {(isHomePage || isUserDashboardsPage) && _renderAddDashboardButton()}
            {shouldRenderAddNewProjectButton && <AddProjectButton />}
            {useSearch && <ProjectsGlobalSearchInput />}

            {isAllocationReportPage && (
              <MoreButtonIconButton id="more-options-button" type="fill" icon={<MoreHoriz />} onClick={_onShowMoreOptions} />
            )}
          </>
        )}

        <DragonMenu
          items={ALLOCATION_REPORT_MORE_OPTIONS}
          anchorEl={allocationReportMoreOptionsAncherEl}
          placement="bottom-end"
          onSetAnchorEl={setAllocationReportMoreOptionsAncherEl}
          onSelectItem={_handleSelectAllocationMoreOptions}
        />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
`;

const MoreButtonIconButton = styled(DragonIconButton)`
  &&&& {
    margin-right: 14px;
  }
`;

const SwitchMenuOption = styled.div`
  margin-left: -12px;
`;

const CreateDashboardButton = styled(ButtonV2)`
  justify-self: flex-end;
  padding: 0 14px !important;
  min-width: 142px !important;
`;
