import { useCallback } from 'react';
import { equals, pipe, prop } from 'ramda';

import { useSettingsGridEventHandlers } from 'containers/Grids/SettingsGrid/hooks';

import { findParentWithLevel } from 'design-system/molecules/AgGridReact-New/helpers';

import { ROADMAPS } from 'store/grids';

import { ROOT_LEVEL, isOfObjectRoadmapType, isOfObjectProductType } from '../helpers';

import useCallErrorToast from './useCallErrorToast';

const TITLE_FIELD = 'title';

const bothNodesAreRoadmaps = (node1, node2) => {
  return isOfObjectRoadmapType(node1.data) && isOfObjectRoadmapType(node2.data);
};

const bothNodesAreProducts = (node1, node2) => {
  return isOfObjectProductType(node1.data) && isOfObjectProductType(node2.data);
};

const productNodeIsL1 = pipe(prop('data'), prop('level'), equals(ROOT_LEVEL));

const productNodeIsL2 = pipe(prop('data'), prop('level'), equals(ROOT_LEVEL + 1));

const bothProductNodesHaveTheSameLevel = (productNode1, productNode2) => {
  return productNode1.data.level === productNode2.data.level;
};

const bothProductNodesHaveTheSameLevelAndParent = (productNode1, productNode2) => {
  return (
    (productNodeIsL1(productNode1) &&
      productNodeIsL1(productNode2) &&
      productNode1.data.roadmap_id === productNode2.data.roadmap_id) ||
    (productNodeIsL2(productNode1) &&
      productNodeIsL2(productNode2) &&
      productNode1.data.parent_id === productNode2.data.parent_id)
  );
};

const roadmapNodesHaveSameLevelAndDifferentParent = (roadmap1, roadmap2) => {
  const { data: { parent_id: roadmap1ParentId } = {} } = roadmap1;
  const { data: { parent_id: roadmap2ParentId } = {} } = roadmap2;

  return roadmap1ParentId !== roadmap2ParentId;
};

const useRoadmapsGridEventHandlers = ({
  roadmaps,
  actions: {
    save,
    update,
    removeUnsavedRoadmapsAndProducts,
    switchRoadmapsRowOrder,
    switchProductsRowOrder,
    moveProductToRoadmap,
    fetchRoadmapsWithProducts,
    moveRoadmapToRoadmap,
  },
}) => {
  const showNotAllowedParentChangeToast = useCallErrorToast();

  const handleRowDragCore = useCallback(
    async (currentNode, lastOverNode, { position }) => {
      const currentNodeData = currentNode.data;

      const lastOverNodeData = lastOverNode.data;

      const currentNodeDataId = currentNodeData.id;

      const lastOverNodeDataId = lastOverNodeData.id;

      if (currentNodeData.organization_id !== lastOverNodeData.organization_id) {
        // Only allow roadmaps to be moved between items of same account (DoD)
        return;
      }

      if (bothNodesAreRoadmaps(currentNode, lastOverNode)) {
        if (roadmapNodesHaveSameLevelAndDifferentParent(currentNode, lastOverNode)) {
          await moveRoadmapToRoadmap(currentNodeData, lastOverNodeData, position);
        }

        return switchRoadmapsRowOrder(currentNodeDataId, lastOverNodeDataId, null, position);
      }

      if (isOfObjectRoadmapType(currentNodeData) && !isOfObjectRoadmapType(lastOverNodeData)) {
        const lastOverNodeParentOfTheSameLevelAsTheCurrentNode = findParentWithLevel(lastOverNode, currentNode.level);

        return switchRoadmapsRowOrder(
          currentNodeDataId,
          lastOverNodeParentOfTheSameLevelAsTheCurrentNode.data.id,
          null,
          position,
        );
      }

      if (bothNodesAreProducts(currentNode, lastOverNode)) {
        if (bothProductNodesHaveTheSameLevelAndParent(currentNode, lastOverNode)) {
          return switchProductsRowOrder(currentNodeDataId, lastOverNodeDataId, null, position);
        }

        if (bothProductNodesHaveTheSameLevel(currentNode, lastOverNode)) {
          return moveProductToRoadmap(currentNodeData, null, lastOverNodeData, position);
        }

        if (productNodeIsL2(currentNode) && productNodeIsL1(lastOverNode)) {
          if (currentNodeData.parent_id === lastOverNodeDataId) {
            const firstProductL2 = lastOverNodeData.products.find(p => p.id !== currentNodeDataId);

            return switchProductsRowOrder(currentNodeDataId, firstProductL2.id, null, 'top');
          }

          return moveProductToRoadmap(currentNodeData, null, lastOverNodeData, 'top');
        }

        if (productNodeIsL1(currentNode) && productNodeIsL2(lastOverNode)) {
          const lastOverNodeParentOfTheSameLevelAsTheCurrentNode = findParentWithLevel(lastOverNode, currentNode.level);

          if (currentNodeData.roadmap_id === lastOverNodeParentOfTheSameLevelAsTheCurrentNode.data.roadmap_id) {
            return switchProductsRowOrder(
              currentNodeDataId,
              lastOverNodeParentOfTheSameLevelAsTheCurrentNode.data.id,
              null,
              position,
            );
          }

          return moveProductToRoadmap(currentNodeData, null, lastOverNodeParentOfTheSameLevelAsTheCurrentNode.data, position);
        }
      }

      if (isOfObjectProductType(currentNodeData) && productNodeIsL1(currentNode) && isOfObjectRoadmapType(lastOverNodeData)) {
        if (currentNodeData.roadmap_id === lastOverNodeData.id) {
          const firstProduct = lastOverNodeData.products.find(p => p.id !== currentNodeDataId);

          if (firstProduct) {
            return switchProductsRowOrder(currentNodeDataId, firstProduct.id, null, 'top');
          }
        }

        return moveProductToRoadmap(currentNodeData, lastOverNodeData, null, position);
      }
    },
    [
      switchRoadmapsRowOrder,
      switchProductsRowOrder,
      moveProductToRoadmap,
      fetchRoadmapsWithProducts,
      showNotAllowedParentChangeToast,
    ],
  );

  const handleRowDrag = useCallback(
    async (currentNode, lastOverNode, options) => {
      await handleRowDragCore(currentNode, lastOverNode, options);

      return fetchRoadmapsWithProducts();
    },
    [handleRowDragCore, fetchRoadmapsWithProducts],
  );

  const { onRowSelected, ...settingsGridEventHandlers } = useSettingsGridEventHandlers({
    settingType: ROADMAPS,
    items: roadmaps,
    focusField: TITLE_FIELD,
    idKey: 'uniqueId',
    actions: {
      update,
      save,
      remove: removeUnsavedRoadmapsAndProducts,
      drag: handleRowDrag,
    },
  });

  return {
    ...settingsGridEventHandlers,
    onRowSelected,
  };
};

export default useRoadmapsGridEventHandlers;
