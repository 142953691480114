export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const CURRENT_USER = 'CURRENT_USER';
export const ON_USER_LOGOUT = 'ON_USER_LOGOUT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_FULFILLED = 'CHANGE_PASSWORD_FULFILLED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const GOOGLE_LOGIN_FAILED = 'GOOGLE_LOGIN_FAILED';
export const GOOGLE_LOGIN_NO_ACCOUNT = 'GOOGLE_LOGIN_NO_ACCOUNT';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const SENT_RESET_PASSWORD_INSTRUCTIONS = 'SENT_RESET_PASSWORD_INSTRUCTIONS';
export const CLEAR_RESET_PASSWORD = 'CLEAR_RESET_PASSWORD';
export const NEW_PASSWORD_SET = 'NEW_PASSWORD_SET';
export const NEW_PASSWORD_SET_FULFILLED = 'NEW_PASSWORD_SET_FULFILLED';
export const SET_NEW_PASSWORD_FAILED = 'SET_NEW_PASSWORD_FAILED';
export const GOOGLE_SIGNUP = 'GOOGLE_SIGNUP';
export const GOOGLE_SIGNUP_APPROVED = 'GOOGLE_SIGNUP_APPROVED';
export const GOOGLE_SIGNUP_DECLINED = 'GOOGLE_SIGNUP_DECLINED';
export const GOOGLE_SIGNUP_APPROVAL_FAILED = 'GOOGLE_SIGNUP_APPROVAL_FAILED';
export const GOOGLE_SIGNUP_DECLINED_FAILED = 'GOOGLE_SIGNUP_DECLINED_FAILED';
export const ACCOUNT_SIGNUP_SUCCESS = 'ACCOUNT_SIGNUP_SUCCESS';
export const ACCOUNT_SIGNUP_ERROR = 'ACCOUNT_SIGNUP_ERROR';
export const ACCOUNT_SIGNUP_STEP2_SUCCESS = 'ACCOUNT_SIGNUP_STEP2_SUCCESS';
export const SET_ORGANIZATION = 'SET_ORGANIZATION';

export const SET_ALLOW_RECEIVE_NOTIFICATIONS_EMAILS = 'SET_ALLOW_RECEIVE_NOTIFICATIONS_EMAILS';
export const SET_ALLOW_RECEIVE_NOTIFICATIONS_EMAILS_FULFILLED = 'SET_ALLOW_RECEIVE_NOTIFICATIONS_EMAILS_FULFILLED';

export const FETCH_USER_INTEGRATIONS = 'FETCH_USER_INTEGRATIONS';
export const FETCH_USER_INTEGRATIONS_FULFILLED = 'FETCH_USER_INTEGRATIONS_FULFILLED';

export const ADD_USER_INTEGRATION = 'ADD_USER_INTEGRATION';
export const ADD_USER_INTEGRATION_FULFILLED = 'ADD_USER_INTEGRATION_FULFILLED';
export const ADD_USER_INTEGRATION_REJECTED = 'ADD_USER_INTEGRATION_REJECTED';

export const REMOVE_USER_INTEGRATION = 'REMOVE_USER_INTEGRATION';
export const REMOVE_USER_INTEGRATION_FULFILLED = 'REMOVE_USER_INTEGRATION_FULFILLED';

export const CLEAR_LOADED_USER_INTEGRATIONS_FOR_ORG_INTEGRATION = 'CLEAR_LOADED_USER_INTEGRATIONS_FOR_ORG_INTEGRATION';

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_CURRENT_USER_FULFILLED = 'UPDATE_CURRENT_USER_FULFILLED';
export const UPDATE_CURRENT_USER_REJECTED = 'UPDATE_CURRENT_USER_REJECTED';

export const REQUEST_ACCESS = 'REQUEST_ACCESS';
export const REQUEST_ACCESS_FULFILLED = 'REQUEST_ACCESS_FULFILLED';
export const REQUEST_ACCESS_REJECTED = 'REQUEST_ACCESS_REJECTED';
