import { createCachedSelector } from 're-reselect';
import { defaultTo } from 'ramda';

import { filterActiveItens } from 'utils/index';
import adapter from './entityAdapter';

const defaultToInitialState = defaultTo(adapter.getInitialState());

const entitySelectors = adapter.getSelectors(state => {
  return defaultToInitialState(state.lifecyclesData);
});

export const selectLifecyclesData = createCachedSelector(
  entitySelectors.selectAll,
  (_, showArchived) => showArchived,
  (lifecyclesData, showArchived) => {
    return showArchived ? lifecyclesData : lifecyclesData.filter(filterActiveItens);
  },
)((state, showArchived) => `lifecyclesData-${showArchived}`);
