import moment from 'moment-timezone';
import { toString } from 'ramda';

import formatDate from 'utils/dates/formatDate';
import isNumeric from 'utils/isNumeric';
import validateEmail from 'utils/validateEmail';

import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';

function validateAndFormatNumericField(value) {
  const valueAsNumber = Number(value);

  if (isNumeric(valueAsNumber)) {
    return value;
  }

  return undefined;
}

function validateAndFormatOwnerField(value) {
  if (validateEmail(value)) {
    return { email: value };
  }

  if (value) {
    return { name: value };
  }

  return undefined;
}

function validateAndFormatDateField(date) {
  if (date) {
    return moment(date).isValid() ? formatDate(moment(date)) : undefined;
  }

  return undefined;
}

function validateAndFormatYesNoField(value) {
  if (value) {
    if (typeof value === 'boolean') {
      return value;
    }

    if (typeof value === 'string') {
      const formattedValue = value.trim().toUpperCase();

      if (['Y', 'YES', 'TRUE'].includes(formattedValue)) {
        return true;
      }

      if (['N', 'NO', 'FALSE'].includes(formattedValue)) {
        return false;
      }
    }
  }

  return undefined;
}

function validateAndFormatMultiValueField(values, key = 'title') {
  try {
    if (values) {
      const stringifiedValues = typeof values === 'string' ? values : toString(values);

      const validValues = stringifiedValues.split(',').reduce((formattedValues, value) => {
        if (value) {
          return [...formattedValues, { [key]: value.trim() }];
        }

        return formattedValues;
      }, []);

      return validValues.length ? validValues : undefined;
    }

    return undefined;
  } catch (err) {
    return undefined;
  }
}

function parseValuesByCustomFieldType(type) {
  switch (type) {
    case TYPES_OF_CUSTOM_FIELDS.DATE:
      return validateAndFormatDateField;
    case TYPES_OF_CUSTOM_FIELDS.NUMBER:
      return validateAndFormatNumericField;
    case TYPES_OF_CUSTOM_FIELDS.YES_NO:
      return validateAndFormatYesNoField;
    case TYPES_OF_CUSTOM_FIELDS.DROPDOWN:
    case TYPES_OF_CUSTOM_FIELDS.SHORT_TEXT:
    case TYPES_OF_CUSTOM_FIELDS.RICH_TEXT:
    default:
      return null;
  }
}

function createCustomFieldDataMapping(customFields) {
  return customFields.reduce((cfs, cf) => {
    const { field_type: fieldType } = cf;

    if (fieldType === TYPES_OF_CUSTOM_FIELDS.FORMULA) {
      return cfs;
    }

    const fieldTypeParse = parseValuesByCustomFieldType(fieldType);

    const formatted = {
      field: cf.key,
      displayTitle: cf.title,
      col: null,
      ...(fieldTypeParse ? { parseToCorrectType: value => fieldTypeParse(value) } : {}),
    };

    return [...cfs, formatted];
  }, []);
}

export {
  createCustomFieldDataMapping,
  parseValuesByCustomFieldType,
  validateAndFormatDateField,
  validateAndFormatMultiValueField,
  validateAndFormatNumericField,
  validateAndFormatOwnerField,
  validateAndFormatYesNoField,
};
