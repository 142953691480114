import { defaultTo, reverse } from 'ramda';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { forwardRef, useMemo } from 'react';
import styled, { css } from 'styled-components';

import Text from 'design-system/atoms/Text/index';

import Roadmap from 'design-system/atoms/DragonIcons/Roadmap';
import { spacing } from 'design-system/theme';

import { Cluster } from 'features/MetricsDialog/components/MetricValueChart/ChartClusters';
import MetricValueChartComponent from 'features/MetricsDialog/components/MetricValueChart/MetricValueChartComponent';
import IntegrationOpenExternalUrlButton from 'features/Integrations/IntegrationOpenExternalUrlButton';
import SnapshotItemWrapper from '../SnapshotItemWrapper';

import useMetricValuesForLineChart from '../hooks/useMetricValuesForLineChart';
import useOrganizations from 'hooks/useOrganizations';
import useProjectsListLightboxContext from 'hooks/useProjectsListLightbox';
import { DATE_DISPLAY_FORMATS } from 'utils/chartsDateTicks';
import useMetricsDialogTabs from 'features/MetricsDialog/hooks/useMetricsDialogTabs';

const defaultToEmptyArray = defaultTo([]);

const extraChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  dateDisplayFormats: {
    ...DATE_DISPLAY_FORMATS,
    month: 'MMM YYYY',
  },
};

const chartStyle = {
  minHeight: '250px',
};

const SnapshotMetricItem = forwardRef(
  (
    {
      inView,
      metric,
      startDate,
      endDate,
      handleOpenMetricView,
      displayClusters,
      isAnonymousUser,
      inEditMode,
      handleDelete,
      moarPreferences,
      displayActual,
      displayTarget,
    },
    ref,
  ) => {
    const { chartData, datesForChart, chartTimeUnit, useValuesAsPercentages } = useMetricValuesForLineChart({
      metricId: metric?.id,
      metricValues: metric?.metricValues || [],
      startDate,
      endDate,
      inView,
      moarPreferences,
      displayActual,
      displayTarget,
    });

    const { hasProjectMetrics } = useOrganizations();

    const { chartTab } = useMetricsDialogTabs();

    const shouldDisplayProjectsLightboxIcon = useMemo(
      () => hasProjectMetrics && !isAnonymousUser,
      [hasProjectMetrics, isAnonymousUser],
    );

    const { openProjectsListLightbox: openProjectsListLightboxAction } = useProjectsListLightboxContext();

    const openProjectsListLightbox = () => openProjectsListLightboxAction(metric, 'metric');
    const openProjectsListLightboxFromChart = projectsIds => openProjectsListLightboxAction(metric, 'metric', projectsIds);

    const openMetricDialogChart = () =>
      handleOpenMetricView(metric, chartTab, {
        parentStartDate: startDate,
        parentEndDate: endDate,
      });

    const renderHeaderControls = () => {
      const headerControls = [];

      if (inEditMode) {
        const deleteControl = (
          <StyledIconButton onClick={() => handleDelete(metric.id)}>
            <CloseIcon />
          </StyledIconButton>
        );

        headerControls.push(deleteControl);
      } else {
        if (shouldDisplayProjectsLightboxIcon) {
          const projectsLightboxControl = (
            <StyledIconButton onClick={openProjectsListLightbox}>
              <Roadmap />
            </StyledIconButton>
          );

          headerControls.push(projectsLightboxControl);
        }

        const openMetricIntegrationActions = defaultToEmptyArray(metric?.metricIntegrations).map(metricIntegration => {
          return (
            <StyledIntegrationOpenExternalUrlButton
              orgIntegrationId={metricIntegration.org_integration_id}
              externalUrl={metricIntegration.external_url}
            />
          );
        });

        headerControls.push(...openMetricIntegrationActions);

        const openMetricViewControl = (
          <StyledIconButton onClick={openMetricDialogChart}>
            <OpenInNewIcon />
          </StyledIconButton>
        );

        headerControls.push(openMetricViewControl);
      }

      return reverse(headerControls).map((control, idx) => {
        const rightAbsolutePositionPx = spacing(4) * idx;

        return <ButtonWrapper $right={`${rightAbsolutePositionPx}px`}>{control}</ButtonWrapper>;
      });
    };

    return (
      <>
        <StyledSnapshotItemWrapper inEditMode={inEditMode}>
          <HeaderWrapper>
            <Text variant="h3" withEllipsis maxWidth="80%">
              {metric?.name}
            </Text>
            {renderHeaderControls()}
          </HeaderWrapper>
          <Body ref={ref}>
            <MetricValueChartComponent
              metric={metric}
              chartData={chartData}
              datesForChart={datesForChart}
              timeUnit={chartTimeUnit}
              endDate={endDate}
              startDate={startDate}
              showDatePicker={false}
              extraChartOptions={extraChartOptions}
              chartStyle={chartStyle}
              openLightbox={openProjectsListLightboxFromChart}
              displayClusters={displayClusters}
              useValuesAsPercentages={useValuesAsPercentages}
              drawLastValueOnChart
            />
          </Body>
        </StyledSnapshotItemWrapper>
      </>
    );
  },
);

const StyledSnapshotItemWrapper = styled(SnapshotItemWrapper)`
  &&&& {
    width: calc(50% - ${spacing()}px);
    margin: 0;

    > div {
      padding: ${spacing(3)}px;
      margin: 0;
      cursor: initial;
    }

    ${({ inEditMode }) =>
      inEditMode &&
      css`
        > div {
          cursor: grab;
          border-style: dashed;
        }
      `}
  }
`;

const HeaderWrapper = styled.div`
  position: relative;
`;

const Body = styled.div`
  margin-top: ${spacing()}px;

  & > div {
    padding: 0;
  }

  ${Cluster} {
    width: 22px;
    height: 22px;
    font-size: ${props => props.theme.typography.fontSizeSmallRem}rem;
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${({ $right }) => $right};
`;

const StyledIconButton = styled(IconButton)`
  && {
    padding: ${spacing(0.5)}px;

    svg {
      font-size: ${({ theme }) => theme.sizing.icons.small}rem;
      color: ${({ theme }) => theme.palette.icons.primary};
    }
  }
`;

const StyledIntegrationOpenExternalUrlButton = styled(IntegrationOpenExternalUrlButton)`
  &&&&& {
    font-size: ${({ theme }) => theme.typography.fontSizeMediumSmallRem}rem;
    max-height: ${spacing(3)}px;
    max-width: ${spacing(3)}px;

    img {
      width: ${({ theme }) => theme.sizing.icons.small}rem;
      height: ${({ theme }) => theme.sizing.icons.small}rem;
    }
  }
`;

export default SnapshotMetricItem;
