import { defaultTo, isNil, path, pipe, prop, isEmpty, either, split, tail } from 'ramda';

const DATA = 'data';
const FORMATTED_ID = 'FormattedID';
const OBJECT_ID = 'ObjectID';
const PORTFOLIO_ITEM_BASE_TYPE_PATH = 'portfolioitem';
const URI = 'uri';

const isNilOrEmpty = either(isNil, isEmpty);

const defaultToEmptyObject = defaultTo({});
const getOrgIntegrationUri = pipe(defaultToEmptyObject, prop(URI));

const getProjectIntegrationFormattedId = pipe(defaultToEmptyObject, path([DATA, FORMATTED_ID]));
const getProjectIntegrationProjectObjectId = pipe(defaultToEmptyObject, path([DATA, 'Project', OBJECT_ID]));
const getRelativeRef = pipe(defaultToEmptyObject, path([DATA, '_ref']), split(PORTFOLIO_ITEM_BASE_TYPE_PATH), tail);

/**
 * @function getRallyObjectUrl
 *
 * Gets Rally object Url and Key
 *
 * @param {Object} orgIntegrationData
 * @param {Object} projectIntegration
 * @returns {Object}
 */
export const getRallyObjectUrl = (orgIntegrationData, projectIntegration) => {
  if (isNilOrEmpty(orgIntegrationData) || isNilOrEmpty(projectIntegration)) {
    return null;
  }

  const integrationBaseUrl = getOrgIntegrationUri(orgIntegrationData);

  const formattedId = getProjectIntegrationFormattedId(projectIntegration);
  const rallyProjectId = getProjectIntegrationProjectObjectId(projectIntegration);

  const relativeRef = getRelativeRef(projectIntegration);

  const key = formattedId;

  const link = new URL(
    `/#/${rallyProjectId}/myrally?detail=/${PORTFOLIO_ITEM_BASE_TYPE_PATH}${relativeRef}/details&fdp=true`,
    integrationBaseUrl,
  ).href;

  return {
    link,
    key,
  };
};
