import { useCallback, useMemo } from 'react';

import { ROADMAP_PAGE } from 'constants/filters';

import { isSnapToPredictedEnd } from 'design-system/organisms/Timeline/New/helpers';

import useProjectGroups from 'hooks/useProjectGroups';
import useTimelines from 'hooks/useTimelines/New/useTimelines';
import { shouldHideEmptyBasedOnUserPref } from 'utils/projects/grouping';

import { planningStages, getIntegrationTimeSpan, makeProjectMapper } from '../helpers';

const hasTimeline = project => {
  if (!project.estimated_start_date || !project.deadline) {
    return false;
  }

  return (project.integrationProgress && project.integrationProgress.endDate) || project.deadline;
};

/**
 * Custom hook responsible for the groups generation based on the filtered projects and metadata.
 * */
const useSwimlaneGroups = (filteredMetadata, page = ROADMAP_PAGE, customAllProjectsByLayer) => {
  // Get Timeline configuration
  const {
    lsState: { hideEmptyLane = true, selectedColorBy, snapToGridOn, showItemsWithoutTimeline, displayMilestone },
    selectedGroupOptions,
    hasTopMilestoneLane,
  } = useTimelines(page);

  // Project filter by snap to grid for predicted end date, if active should only add the items with a integration end date
  const projectSnapPredictedEndFilter = useCallback(
    project => {
      const integrationTimespan = project?.integrationProgress ? getIntegrationTimeSpan(project.integrationProgress) : null;

      return !isSnapToPredictedEnd(snapToGridOn) || (isSnapToPredictedEnd(snapToGridOn) && integrationTimespan?.endDate);
    },
    [snapToGridOn],
  );

  // Project filter by show items without timeline
  const projectShowItemsWithoutTimelineFilter = useCallback(
    project => showItemsWithoutTimeline || hasTimeline(project),
    [showItemsWithoutTimeline],
  );

  // Join all project filters
  const projectsFilter = useCallback(
    project => {
      const hasValidPlanningStage = !!planningStages[project.planningStage];

      return hasValidPlanningStage && projectSnapPredictedEndFilter(project) && projectShowItemsWithoutTimelineFilter(project);
    },
    [projectSnapPredictedEndFilter, projectShowItemsWithoutTimelineFilter, displayMilestone, hasTopMilestoneLane],
  );

  const projectMapper = useMemo(() => makeProjectMapper(selectedColorBy), [selectedColorBy]);

  const selectGroups = selectedGroupOptions.reduce(
    (acc, groupOption, index) => ({
      ...acc,
      ...(groupOption?.key ? { [`selectedGroup${index + 1}`]: groupOption } : {}),
    }),
    {},
  );

  const projectGroups = useProjectGroups({
    ...selectGroups,
    page,
    onlyProjects: false,
    hideEmptyHandler: shouldHideEmptyBasedOnUserPref,
    hideEmptyUserPref: hideEmptyLane,
    projectsFilters: [projectsFilter],
    // projectsFilters: [projectsFilter, milestoneFilter],
    projectMapper,
    customMetadata: filteredMetadata,
    customAllProjectsByLayer,
  });

  return projectGroups;
};

export default useSwimlaneGroups;
