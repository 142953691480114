/* eslint-disable max-len */
import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={38.88}
      height={46.525}
      viewBox="0 0 38.88 46.525"
      {...props}
    >
      <defs>
        <style>{'.prefix__a{fill:#5accc6;stroke:#5accc6}'}</style>
      </defs>
      <path
        className="prefix__a"
        d="M35.375 33.774c-.291-.014-.518-.035-.746-.035-4.51 0-9.021.016-13.531-.009-.608 0-.761.191-.732.754.04.781.023 1.567 0 2.35-.017.691-.356 1.05-.931 1.045s-.9-.365-.92-1.059a50.06 50.06 0 010-2.541c.011-.418-.147-.548-.555-.547q-6.956.014-13.912 0c-.418 0-.553.149-.542.557.024.8.017 1.61.005 2.414-.011.725-.407 1.178-.982 1.159-.557-.019-.888-.445-.889-1.159v-3.621c0-.932.27-1.2 1.224-1.2q4.542-.012 9.084-.007c1.969 0 3.939-.01 5.908.012.478.005.685-.108.655-.634-.043-.738-.018-1.482 0-2.223a.923.923 0 01.894-1.025c.559-.019.935.365.957 1.027a20.29 20.29 0 010 2.032c-.042.612.111.835.789.83 4.87-.032 9.741-.017 14.611-.016.169 0 .339-.005.508.008.662.052.957.343.965 1.01q.022 2 0 4a.909.909 0 01-.87.977.921.921 0 01-.984-.942c-.034-.761-.011-1.524-.013-2.287.007-.273.007-.545.007-.87zM19.458 46.022a2.032 2.032 0 112.017-2 2 2 0 01-2.017 2zM36.346 46.025a2.031 2.031 0 01-.023-4.062 2.032 2.032 0 11.023 4.063zM2.516 46.023a2.03 2.03 0 112.047-2.03 2 2 0 01-2.047 2.03z"
      />
      <g>
        <path
          className="prefix__a"
          d="M19.087 19.013c.89-1.056 1.757-2.011 2.536-3.033a1.792 1.792 0 011.815-.735 8.6 8.6 0 013.723 1.148 4.932 4.932 0 012.346 3.868 6.215 6.215 0 01.017.635v7.039h-1.841c-.01-.195-.03-.4-.03-.6v-6.353a3.655 3.655 0 00-4.24-3.836 1.09 1.09 0 00-.607.392c-.943 1.088-1.861 2.2-2.8 3.293-.632.741-1.12.746-1.78.025-1.016-1.108-2.034-2.214-3.031-3.339a.97.97 0 00-1.005-.361c-.33.068-.672.087-1 .166a3.065 3.065 0 00-2.613 3.258c-.019 2.033 0 4.066 0 6.1v1.26H8.751c-.015-.181-.043-.362-.043-.543 0-2.118-.014-4.235 0-6.353a5.348 5.348 0 015.11-5.7c.189-.022.379-.041.566-.076a1.9 1.9 0 012 .74c.824 1.015 1.748 1.951 2.703 3.005zM24.724 6.989a5.975 5.975 0 01-1.824 4.907 5.008 5.008 0 01-5.72 1.223 5.6 5.6 0 01-3.614-4.961 11.888 11.888 0 01.073-2.785A5.516 5.516 0 0124.551 5.1a17.765 17.765 0 01.173 1.889zm-1.852.083l-.067-.008c0-.36.012-.72 0-1.079a3.6 3.6 0 00-3.538-3.6 3.487 3.487 0 00-3.763 3.108 12.152 12.152 0 00.056 3.343 3.4 3.4 0 003.431 2.816 3.513 3.513 0 003.635-2.577 14.636 14.636 0 00.25-2.003z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
