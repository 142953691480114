import { OKR_HEALTH_COLORS_OPTIONS } from 'constants/objectives';
import { ALT_HEALTH_COLORS_LABELS } from 'constants/health';

import healthColumnDef from './healthColumnDef';

const okrHealthColumnDef = {
  ...healthColumnDef,
  cellEditorParams: {
    options: OKR_HEALTH_COLORS_OPTIONS,
    sort: false,
  },
  optionsLabel: ALT_HEALTH_COLORS_LABELS,
  width: 110,
};

export default okrHealthColumnDef;
