import sortByRank from './sortByRank';

export default function (a, b) {
  // some enities were migrated to rank order
  if (a.rank && b.rank) {
    return sortByRank(a, b);
  }

  if (a.sort_order !== b.sort_order) {
    return a.sort_order > b.sort_order ? 1 : -1;
  }
  return a.id > b.id ? 1 : -1;

  // return 0;
}
