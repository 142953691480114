import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ObjectiveType from 'design-system/atoms/ObjectiveType/index';

import theme from 'design-system/theme';

import { getAllObjectives, getAllKeyResults } from 'store/objectives/selectors';
import { openObjectiveDrawer } from 'store/objectives/actions';
import { OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2, OBJECT_OBJECTIVE, OBJECT_OBJECTIVE_CORP_STRING } from 'store/objectives';

import { OBJECTIVE_CORP_LEVEL, OBJECTIVE_LEVEL, KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL } from 'constants/objectives';
import { ENTER_TITLE_PLACEHOLDER } from 'constants/common';

const OBJECTIVE_CORP = 'objectiveCorp';
const OBJECTIVE = 'objective';
const KEY_RESULT_1 = 'keyResult';
const KEY_RESULT_2 = 'keyResult2';

// The types coming from the store are different from the types we're expecting for the icon
const mapStoreTypeIntoType = storeType => {
  switch (storeType) {
    case OBJECT_OBJECTIVE_CORP_STRING:
      return OBJECTIVE_CORP;
    case OBJECT_OBJECTIVE:
      return OBJECTIVE;
    case OBJECT_KEY_RESULT:
      return KEY_RESULT_1;
    case OBJECT_KEY_RESULT_2:
      return KEY_RESULT_2;
    default:
      return OBJECTIVE;
  }
};

// If it was an object 0 and '0' are the same when used as a key
const mapLevelIntoType = level => {
  switch (level) {
    case OBJECTIVE_CORP_LEVEL:
      return OBJECTIVE_CORP;
    case OBJECTIVE_LEVEL:
      return OBJECTIVE;
    case KEY_RESULT_1_LEVEL:
      return KEY_RESULT_1;
    case KEY_RESULT_2_LEVEL:
      return KEY_RESULT_2;
    default:
      return OBJECTIVE;
  }
};

// If it was an object 0 and '0' are the same when used as a key
const mapLevelIntoObject = level => {
  switch (level) {
    case OBJECTIVE_CORP_LEVEL:
      return OBJECT_OBJECTIVE_CORP_STRING;
    case OBJECTIVE_LEVEL:
      return OBJECT_OBJECTIVE;
    case KEY_RESULT_1_LEVEL:
      return OBJECT_KEY_RESULT;
    case KEY_RESULT_2_LEVEL:
      return OBJECT_KEY_RESULT_2;
    default:
      return OBJECT_OBJECTIVE;
  }
};

const makeGetParentOkrById = (objectives, keyResults) => (id, level) => {
  switch (level) {
    case OBJECTIVE_LEVEL:
    case KEY_RESULT_1_LEVEL:
      return objectives.find(item => item.id === id);
    case KEY_RESULT_2_LEVEL:
      return keyResults.find(item => item.id === id);
    default:
      return null;
  }
};

const getAncestry = (okr, getParentOkrById) => {
  const parentOkrId = okr?.parent_id || okr?.objective_id;

  if (parentOkrId) {
    const parent = getParentOkrById(parentOkrId, okr?.level);

    if (!parent) {
      return [okr];
    }
    const ancestry = getAncestry(parent, getParentOkrById);

    return [...ancestry, okr];
  }
  return [okr];
};

const useGenerateOKRBreadcrumbTitles = (okr, active, updateOkr, isEditable = true, type) => {
  const dispatch = useDispatch();
  const objectives = useSelector(state => getAllObjectives(state));
  const keyResults = useSelector(state => getAllKeyResults(state));

  return useMemo(() => {
    const getParentOkrById = makeGetParentOkrById(objectives, keyResults);

    const okrItems = getAncestry(okr, getParentOkrById);

    // removes the last item from array because it renders differently
    const parentOkrList = okrItems.slice(0, -1).map(item => ({
      icon: <ObjectiveType type={mapLevelIntoType(item?.level)} active={active} />,
      title: item?.title,
      onClick: () => dispatch(openObjectiveDrawer(item?.id, mapLevelIntoObject(item?.level))),
      fontSize: theme.typography.fontSizeSmall,
    }));

    const item = {
      icon: <ObjectiveType type={type ? mapStoreTypeIntoType(type) : mapLevelIntoType(okr.level)} active={active} />,
      title: okr?.title,
      placeholder: ENTER_TITLE_PLACEHOLDER,
      readingPlaceholder: ENTER_TITLE_PLACEHOLDER,
      onChange: updateOkr,
      isEditable,
      multiline: false,
      useEllipsis: true,
    };

    return {
      item,
      parentItem: parentOkrList[parentOkrList.length - 1],
      ancestryItems: parentOkrList.slice(0, -1),
    };
  }, [okr, active, objectives, keyResults]);
};

export default useGenerateOKRBreadcrumbTitles;
