import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getAllKeyResults, getObjectives } from 'store/objectives/selectors';

import usePortfolioOKRs from 'hooks/usePortfolioOKRs';

import { setGoalsCanvasData } from 'features/GoalsCanvas/store';
import { selectDataForGoalsCanvas } from 'features/GoalsCanvas/store/selectors';
import usePageFilters from 'hooks/filters/usePageFilters';
import { getIsMetadataFetched } from 'store/app';
import { GOALS_CANVAS_PAGE } from 'constants/filters';

const getMetricsFromAllOkrs = (okrs = []) => {
  return okrs.reduce((acc, okr) => {
    const metrics = okr?.metrics || [];

    acc = [...acc, ...metrics];

    return acc;
  }, []);
};

const useGoalsCanvasData = () => {
  const dispatch = useDispatch();
  const objectives = useSelector(state => getObjectives(state, true));
  const keyResults = useSelector(getAllKeyResults);

  const portfolioObjectives = usePortfolioOKRs(true, objectives, true);
  const portfolioKeyResults = usePortfolioOKRs(true, keyResults, true);
  const { pageFilters: filters } = usePageFilters(GOALS_CANVAS_PAGE);
  const isMetadataFetched = useSelector(getIsMetadataFetched);

  const dataForCanvas = useSelector(selectDataForGoalsCanvas);

  useEffect(() => {
    if (isMetadataFetched) {
      const metrics = getMetricsFromAllOkrs([...portfolioObjectives, ...portfolioKeyResults]);

      dispatch(setGoalsCanvasData({ objectives: portfolioObjectives, keyResults: portfolioKeyResults, metrics }));
    }
  }, [filters, isMetadataFetched, portfolioObjectives, portfolioKeyResults]);

  return { dataForCanvas };
};

export default useGoalsCanvasData;
