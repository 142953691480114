import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import PageHeaderFilters from 'containers/PageHeaderFilters';
import ButtonV2 from 'design-system/atoms/ButtonV2/index';
import HeaderTitle from 'design-system/organisms/HeaderTitle/index';

import lightenDarkenColor from 'design-system/utils/lightenDarkenColor';
import theme from 'design-system/theme';

import history from 'store/utils/history';
import { setActiveTemplate } from 'store/templates';
import { getPageIdFromPath } from 'utils/userViews';
import { savePageActiveView, updatePageActiveView } from 'store/userViews/actions';
import { getActiveViewForPage } from 'store/userViews/selectors';

const TEMPLATE_TITLE_PLACEHOLDER = 'Save this template as a view';

export default function DragonTemplateHeader() {
  const dispatch = useDispatch();
  const [localTitle, setLocalTitle] = useState('');
  const inputRef = useRef(null);
  const path = window.location.pathname.replace(/^\/+/, '');
  const pageId = getPageIdFromPath(path);
  const activeViewForPage = useSelector(state => getActiveViewForPage(state, pageId));
  const handleTitleChange = title => {
    setLocalTitle(title);
    if (activeViewForPage) {
      dispatch(updatePageActiveView(pageId, { name: title }));
    }
  };

  const handleClickBack = () => {
    dispatch(setActiveTemplate(null));
    history.push('/dashboard/templates');
  };

  const handleCreateViewFromTemplate = async () => {
    dispatch(savePageActiveView(pageId));
    dispatch(setActiveTemplate(null));
  };

  return (
    <Wrapper>
      <LeftContainer>
        <HeaderTitle
          backButtonId="go-to-dashboard-templates-button"
          inputRef={inputRef}
          onClickBack={handleClickBack}
          placeholder={TEMPLATE_TITLE_PLACEHOLDER}
          title={localTitle}
          updateTitle={handleTitleChange}
        />
        <PageHeaderFilters
          page={pageId}
          showQuickFilter={false}
          showDisplayLayer={false}
          showPageIcon={false}
          showResultsCounter={false}
        />
      </LeftContainer>
      {!!localTitle && (
        <SaveButton
          id="save-template-button"
          color={theme.palette.background.white}
          background={theme.palette.background.primary}
          hover={{ background: lightenDarkenColor(theme.palette.background.primary, -20) }}
          onClick={handleCreateViewFromTemplate}
          size="small"
        >
          Save
        </SaveButton>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const SaveButton = styled(ButtonV2)`
  justify-self: flex-end;
  padding: 0 14px !important;
  margin-right: 24px !important;
`;
