import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import DragonSelectButton from 'design-system/atoms/DragonSelectButton/index';

import SubNavigationTabs from 'design-system/organisms/SubNavigationTabs';
import theme, { spacing } from 'design-system/theme';

import { setAppGlobalConfig } from 'store/app';
import history from 'store/utils/history';

import { getPathWithoutLeadingSlash } from 'utils/index';
import useApplicationRoutes from 'hooks/useApplicationRoutes';

import {
  PAGEID_TO_PATH,
  ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PAGE,
  ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PAGE,
} from 'constants/filters/pages';
import ModulesMenu from 'components/ModulesMenu';
import useSystemFields from 'hooks/useSystemFields';

import SelectRoadmapHistorySnapsPopover from './SelectRoadmapHistorySnapsPopover/SelectRoadmapHistorySnapsPopover';
import { fetchRoadmapHistorySnapshotsForUserView } from 'features/RoadmapHistory/store';
import { getQueryParamFromUrl } from 'utils/queryParamsUtils';
import { COMPARE_SNAPS_VIEW_QUERY_PARAM, CURRENT_DATA_TITLE } from 'features/RoadmapHistory/constants';

const COMPARE_VIEWS = [
  {
    path: PAGEID_TO_PATH[ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PAGE].main,
    label: 'List',
  },
  {
    path: PAGEID_TO_PATH[ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PAGE].main,
    label: '3D Dynamic',
  },
];

const ICON_MODULE_PURPLE = theme.palette.text.purple;
const ICON_MODULE_BLUE = theme.palette.newLayout.background.lightPrimary;
const ICON_MODULE_BACKGROUND = `linear-gradient(to bottom right, ${ICON_MODULE_PURPLE}, ${ICON_MODULE_BLUE});`;

// TODO revisit this, why we need to have this logic to set last view in this component?
const COMPARE_SNAPS_PATH = 'compare-snaps';

const HeaderVersionsSelection = () => {
  const dispatch = useDispatch();

  const [showSelectSnapList, setShowSelectSnapList] = useState(false);

  const selectSnapButton = useRef(null);

  const [getSystemFieldName] = useSystemFields();
  const [pages] = useApplicationRoutes();

  const lastView = useSelector(state => state.app?.lastPagesView[COMPARE_SNAPS_PATH]);

  const path = window.location.pathname;
  const myLastView = path === `/${COMPARE_SNAPS_PATH}` && lastView ? `/${COMPARE_SNAPS_PATH}/${lastView}` : path;

  const subNavigationTabs = useMemo(() => COMPARE_VIEWS.filter(o => !!pages.find(p => p.path === o.path)), [pages]);

  const selectedSubNavigationTab = useMemo(() => getPathWithoutLeadingSlash(myLastView), [myLastView]);

  const _onOpenSelectSnapList = () => setShowSelectSnapList(true);
  const _onCloseSelectSnapList = () => setShowSelectSnapList(false);

  const setSelectedPage = useCallback(path => {
    const params = new URLSearchParams(window.location.search);

    history.push(`/${path}?${params.toString()}`);
    const pathParts = path.split('/');

    const selectedPage = pathParts.length > 1 ? pathParts[1] : '';

    dispatch(setAppGlobalConfig({ lastPagesView: { [COMPARE_SNAPS_PATH]: selectedPage } }));
  }, []);

  const renderSubNavigation = () => (
    <>
      {subNavigationTabs && (
        <StyledSubNavigationTabs
          items={subNavigationTabs}
          selected={selectedSubNavigationTab}
          handleNavigationChange={setSelectedPage}
        />
      )}
    </>
  );

  useEffect(() => {
    const viewToCompareId = getQueryParamFromUrl(COMPARE_SNAPS_VIEW_QUERY_PARAM);

    viewToCompareId && dispatch(fetchRoadmapHistorySnapshotsForUserView(viewToCompareId));
  }, []);

  return (
    <>
      <HeaderContainer>
        <ModulesMenuWrapper>
          <ModulesMenu pageIconBackground={ICON_MODULE_BACKGROUND} moduleTitle="Compare Snaps" />
        </ModulesMenuWrapper>
        <FlexColumnContainer>
          <Header>
            Compare
            <SelectButtonContainer ref={selectSnapButton}>
              <DragonSelectButton
                id="filters-list-dropdown"
                variant="secondary"
                noUnderline
                onClick={_onOpenSelectSnapList}
                placeholder={`Select ${getSystemFieldName('snap', true)}`}
                arrowIcon={<StyledKeyboardArrowDownIcon />}
              />
            </SelectButtonContainer>
            with {CURRENT_DATA_TITLE}
          </Header>
          {renderSubNavigation()}
        </FlexColumnContainer>
      </HeaderContainer>
      <SelectRoadmapHistorySnapsPopover
        anchorEl={selectSnapButton?.current}
        open={showSelectSnapList}
        onClose={_onCloseSelectSnapList}
      />
    </>
  );
};

export default HeaderVersionsSelection;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2.5)}px;
  height: 100%;
`;

const ModulesMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2.5)}px;
`;

const Header = styled.div`
  &&&& {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: ${({ theme }) => theme.typography.fontSize}px;
  }
`;

const SelectButtonContainer = styled.div`
  &&&& {
    margin: 0 10px;

    button {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
`;

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
  &&&&& {
    width: 24px;
    height: 24px;

    path {
      fill: none;
    }
  }
`;

const StyledSubNavigationTabs = styled(SubNavigationTabs)`
  &&&& {
    min-height: unset;
  }
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
`;

// const SpaceContainer = styled.div`
//   display: flex;
//   align-items: baseline;
// `;
