import React from 'react';
import { useSelector } from 'react-redux';

import { getUserById } from 'store/users/selectors';
import { getActiveViewForPage } from 'store/userViews/selectors';
import { showPageConfigurationForReadOnlyUsers } from 'store/organization/selectors';

import useUserFavoriteViews from 'hooks/userViews/useUserFavoriteViews';

import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/userViews';

const componentHOC = Component => {
  return props => {
    const { isFavoriteView, handleFavoriteUserView } = useUserFavoriteViews();

    const path = getCurrentPath();
    const pageId = getPageIdFromPath(path);
    const activeView = useSelector(state => getActiveViewForPage(state, pageId));
    const showConfigForReadOnly = useSelector(showPageConfigurationForReadOnlyUsers);
    const viewOwner = useSelector(state => getUserById(state, activeView?.user_id));

    return (
      <Component
        selectedView={activeView || {}}
        currentPath={path}
        showConfigForReadOnly={showConfigForReadOnly}
        viewOwner={viewOwner}
        isFavoriteView={isFavoriteView}
        handleFavoriteUserView={handleFavoriteUserView}
        {...props}
      />
    );
  };
};

export default componentHOC;
