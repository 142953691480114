import React from 'react';
import objectShallowDifference from 'utils/objectShallowDifference';

export default (original, resetFlag) => {
  const [state, _setState] = React.useState({});

  function setState(value) {
    _setState(objectShallowDifference(original, { ...state, ...value }));
  }

  React.useEffect(() => {
    if (!original || resetFlag) {
      _setState({});
      return;
    }

    setState(state);
  }, [original, resetFlag]);

  return [{ ...original, ...state }, setState, state];
};
