import React, { memo, useCallback, useMemo } from 'react';
import { Resizable } from 're-resizable';
import PropTypes from 'prop-types';

const TimelineResizableCell = memo(({ colId, width, onResize, children, resizable = true }) => {
  const resizableProps = useMemo(
    () => ({
      defaultSize: {
        width,
        height: 'auto',
      },
      style: {
        position: 'initial',
      },
      enable: {
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      },
    }),
    [width],
  );

  const _handleOnResizeColumnStop = useCallback(
    (e, direction, ref, d) => {
      if (onResize) {
        onResize(colId, width + d.width);
      }
    },
    [width, colId],
  );

  if (!resizable) return children;

  const minWidth = colId && colId.includes('auto') ? '50px' : '250px';

  return (
    <Resizable {...resizableProps} minWidth={minWidth} onResizeStop={_handleOnResizeColumnStop}>
      {children}
    </Resizable>
  );
});

TimelineResizableCell.propTypes = {
  colId: PropTypes.any,
  width: PropTypes.number,
  onResize: PropTypes.func,
  resizable: PropTypes.bool,
};

TimelineResizableCell.displayName = 'TimelineResizableCell';

export default TimelineResizableCell;
