import React, { useEffect } from 'react';

import useSubscribeNewData from 'hooks/useSubscribeNewData';
import useDashboardComments from 'hooks/dashboards/useDashboardComments';

import CommentsLightbox from '../CommentsLightbox';

export default props => {
  const {
    hasMoreComments,
    comments,

    addCommentHandler,
    onFetchCommmentsHandler,
    onFetchNextPageHandler,
    onDeleteCommentHandler,
    onEditCommentHandler,
  } = useDashboardComments({ dashboard: props.item });

  useSubscribeNewData(['app']);

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }

    onFetchCommmentsHandler();
  }, [props.item?.id]);

  return (
    <CommentsLightbox
      {...props}
      addComment={addCommentHandler}
      comments={comments}
      hasMoreComments={hasMoreComments}
      fetchNext={onFetchNextPageHandler}
      onDeleteComment={onDeleteCommentHandler}
      onEditComment={onEditCommentHandler}
      fullScreenOnMobile
    />
  );
};
