import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import AddIcon from '@material-ui/icons/Add';

import DisableSyncWarningDialog from './components/DisableSyncWarningDialog';
import Header from './components/Header';
import FieldRow from './components/FieldRow';

const FieldsMappingEditor = props => {
  const {
    isLoading,
    integrationType,
    mapping,
    dragonboatAvailableFields,
    integrationAvailableFields,
    onAddNewField,
    onChangeField,
    onRemoveField,
    isDisableSyncWarningDialogOpen,
    onOpenDisableSyncWarningDialogHandler,
    onCloseDisableSyncWarningDialogHandler,
  } = props;

  return (
    <Wrapper>
      {isLoading && (
        <LoadingWrapper>
          <CircularProgress variant="indeterminate" disableShrink size={32} thickness={4} />
        </LoadingWrapper>
      )}
      {!isLoading && (
        <React.Fragment>
          <Header integrationType={integrationType} />
          {mapping.map(fieldData => (
            <FieldRow
              key={fieldData.field}
              fieldData={fieldData}
              suggestions={dragonboatAvailableFields}
              integrationSuggestions={integrationAvailableFields}
              onChange={onChangeField}
              onRemove={onRemoveField}
              onTriggerWarningDialog={onOpenDisableSyncWarningDialogHandler}
            />
          ))}
          <Grid container spacing={8}>
            <Grid item xs={4} style={{ padding: '10px 20px' }}>
              <Button size="small" onClick={onAddNewField} color="primary">
                <AddIcon /> New Field
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      <DisableSyncWarningDialog
        isOpen={isDisableSyncWarningDialogOpen}
        onClose={onCloseDisableSyncWarningDialogHandler}
        integrationType={integrationType}
      />
    </Wrapper>
  );
};

FieldsMappingEditor.propTypes = {
  isLoading: PropTypes.bool,
  integrationType: PropTypes.string.isRequired,
  mapping: PropTypes.array.isRequired,
  dragonboatAvailableFields: PropTypes.array.isRequired,
  integrationAvailableFields: PropTypes.array.isRequired,
  onAddNewField: PropTypes.func,
  onChangeField: PropTypes.func,
  onRemoveField: PropTypes.func,
  isDisableSyncWarningDialogOpen: PropTypes.bool,
  onOpenDisableSyncWarningDialogHandler: PropTypes.func,
  onCloseDisableSyncWarningDialogHandler: PropTypes.func,
};

const Wrapper = styled.div`
  color: ${({ theme }) => theme.palette.text.lightGrey};
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 450px);
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export default FieldsMappingEditor;
