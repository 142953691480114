import React, { useEffect, useRef } from 'react';
import { css } from 'styled-components';

import theme from 'design-system/theme';
import HeaderTitle from 'design-system/organisms/HeaderTitle/index';


import useNavigationToPathWithGoBackUrl from 'hooks/useNavigationToPathWithGoBackUrl';
import ModulesMenu from 'components/ModulesMenu';

const NEW_DASHBOARD_TITLE_PLACEHOLDER = 'New dashboard name';
const BACK_BUTTON_ID = 'go-to-dashboard-home-button';

const titleInputStyles = css`
  min-width: 150px;

  @media screen and (min-width: 600px) {
    min-width: 300px;
  }
  @media screen and (min-width: 1300px) {
    min-width: 450px;
  }
  @media screen and (min-width: 1500px) {
    min-width: 550px;
  }
`;

const DashboardTitleComponent = ({ disableEdit, currentDashboard, title = '', updateTitle }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current && !currentDashboard) inputRef.current.focus();
  }, [currentDashboard, inputRef.current]);

  const { navigateBack } = useNavigationToPathWithGoBackUrl();

  const onClickBack = () => {
    navigateBack('/dashboard/user-dashboards');
  };

  const pageIconRenderer = () => <ModulesMenu pageIconBackground={theme.palette.newLayout.background.fountainBlue} />;

  return (
    <HeaderTitle
      backButtonId={BACK_BUTTON_ID}
      disableEdit={disableEdit}
      inputRef={inputRef}
      onClickBack={onClickBack}
      placeholder={NEW_DASHBOARD_TITLE_PLACEHOLDER}
      title={title}
      updateTitle={updateTitle}
      textFieldProps={{ testId: 'view-name' }}
      inputStyles={titleInputStyles}
      pageIconRenderer={pageIconRenderer}
    />
  );
};

export default DashboardTitleComponent;
