import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import { materialColors } from 'design-system/themes/default';
import InputWithFixedText from 'src/design-system/atoms/InputWithFixedText';
import Button from 'design-system/atoms/Button/index';


const WorkspaceFormComponent = ({ handleWorkspaceValue, handleSubmit, value }) => {
  return (
    <Wrapper>
      <Grid container direction="column" alignItems="center" spacing={0}>
        <Grid item xs={12}>
          <StyledTitle>Log in to your Workspace</StyledTitle>
        </Grid>
        <Grid item xs={12}>
          <StyledSubTitle>Enter your workspace’s dragonboat URL</StyledSubTitle>
        </Grid>
        <FormWrapper item xs={12} container spacing={16}>
          <Grid item xs={12}>
            <InputWithFixedText
              value={value}
              placeholder="workspace-name"
              fixedText=".dragonboat.io"
              handleValue={handleWorkspaceValue}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledStartButton background={materialColors.mediumPurple} variant="contained" onClick={handleSubmit}>
              Continue
            </StyledStartButton>
          </Grid>
        </FormWrapper>
        <StyledFooter item xs={12} container spacing={8}>
          <Grid item xs={12}>
            <StyledFooterSpan>
              Don&apos;t know your workspace URL? <Link to="/forgot_workspace">Click here</Link>
            </StyledFooterSpan>
          </Grid>
          <Grid item xs={12}>
            <StyledFooterSpan>
              Don&apos;t have a dragonboat account? <Link to="/signup">Signup here</Link>
            </StyledFooterSpan>
          </Grid>
        </StyledFooter>
      </Grid>
    </Wrapper>
  );
};

export default WorkspaceFormComponent;

const Wrapper = styled.div`
  &&&& {
    @media screen and (min-height: 700px) {
      margin-top: -5rem;
    }
  }
`;

const FormWrapper = styled(Grid)`
  &&&& {
    max-width: 360px;
  }
`;

const StyledTitle = styled.h1`
  color: #3461ac;
  font-size: 2.25rem; // -> 36px -> with :root font-size of 16px;
  font-weight: bold;
  margin: 0 0 1.813rem 0;
  text-align: center;
`;

const StyledSubTitle = styled.h5`
  color: #131c23;
  font-size: 1.125rem;
  line-height: 1.2rem;
  font-weight: 400;
  margin: 0 0 1.813rem 0;
`;

const StyledStartButton = styled(Button)`
  &&&& {
    color: #ffffff;
    width: 100%;
  }
`;

const StyledFooter = styled(Grid)`
  &&&& {
    margin-top: 0.438rem;
    text-align: center;
  }
`;

const StyledFooterSpan = styled.span`
  &&&& {
    color: #777777;
    font-weight: 400;
    font-size: 0.875rem;

    a {
      color: #3c4348;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
