import Legacy, * as legacyHelpers from './Legacy';
import New, * as newHelpers from './New';

const shouldUseNewVersion = localStorage.getItem('threeGroupingLevelsSwimlane') === 'true';

const component = shouldUseNewVersion ? New : Legacy;
const helpers = shouldUseNewVersion ? newHelpers : legacyHelpers;

export { helpers };

export default component;
