import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import {
  loginWithGoogle,
  getOrganization as getOrganizationAction,
  loginWithOkta as loginWithOktaAction,
  loginWithAzure as loginWithAzureAction,
  login as loginAction,
} from 'store/login';
import { getDefaultRoute } from 'utils';

import useEmailLogin from './hooks/useEmailLogin';
import azureButtonClicked from './helpers/azureButtonClicked';
import oktaButtonClicked from './helpers/oktaButtonClicked';

import { DOD_LOGIN_POPOP_WINDOW_NAME } from 'constants/integrations';
import getLoginOptionsVisibility from './helpers/getLoginOptionsVisibility';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [oktaErrorMessage, setOktaError] = useState('');

    const [email, password, login, setEmail, setPassword] = useEmailLogin();

    const isAuthenticated = useSelector(state => state.login.isAuthenticated) && window.name !== DOD_LOGIN_POPOP_WINDOW_NAME;
    const errorMessage = useSelector(state => state.login.errorMessage);
    const googleErrorMessage = useSelector(state => state.login.googleErrorMessage);
    const organization = useSelector(state => state.login.organization);
    const currentUser = useSelector(state => state.login.currentUser);

    const handleGoogleLogin = googleToken => dispatch(loginWithGoogle(googleToken, props));
    const getOrganization = () => dispatch(getOrganizationAction(props));
    const loginWithOkta = (code, state, props) => dispatch(loginWithOktaAction(code, state, props));
    const loginWithAzure = (token, user, props) => dispatch(loginWithAzureAction(token, user, props));

    const hasNext = !!queryString.parse(props.location.search).next;

    React.useEffect(() => {
      const token = queryString.parse(props.location.search).t;

      if (isAuthenticated || !token) return;

      dispatch(loginAction({ token }, props)).catch(() => {
        window.location.href = '/login';
      });
    }, []);

    const handleEmailValue = value => {
      setEmail(value);
    };

    const handlePasswordValue = value => {
      setPassword(value);
    };

    const handleLogin = () => {
      login(props);
    };

    const receiveMessage = event => {
      let obj;

      try {
        if (typeof event.data === 'string') obj = JSON.parse(event.data);
        else if (typeof event.data === 'object') obj = event.data;
        else return;
      } catch (err) {
        return;
      }

      switch (obj.ssoType) {
        case 'google':
          handleGoogleLogin(obj.token);
          break;
        case 'okta':
          if (obj.error_description) {
            setOktaError(obj.error_description);
          } else {
            setOktaError('');
            loginWithOkta(obj.code, obj.state, props);
          }
          break;
        case 'azure':
          loginWithAzure(obj.code, obj.state, props);
          break;
        default:
          break;
      }
    };

    const handleAzureButtonClicked = () => {
      const newWindow = window.open('about:blank', 'Azure_Authentication', 'height=600,width=600');

      azureButtonClicked(organization.id)
        .then(response => {
          newWindow.location = response.data.uri;
        })
        .catch(err => {
          console.error(err);
        });
    };

    const handleOktaButtonClicked = () => {
      const newWindow = window.open('about:blank', 'Okta_Authentication', 'height=600,width=600');

      oktaButtonClicked(organization.id)
        .then(response => {
          newWindow.location = response.data.uri;
        })
        .catch(err => {
          console.error(err);
        });
    };

    useEffect(() => {
      getOrganization();
      window.addEventListener('message', receiveMessage, false);

      return () => window.removeEventListener('message', receiveMessage);
    }, []);

    const { next, autologin, ...queryParams } = queryString.parse(props.location.search);

    if (isAuthenticated) {
      if (next) {
        const { url: nextUrl, query: nextQueryParams } = queryString.parseUrl(next);
        const allQueryParams = { ...queryParams, ...nextQueryParams };
        const redirectQueryParams = queryString.stringify(allQueryParams) ? `?${queryString.stringify(allQueryParams)}` : '';

        return <Redirect to={`${nextUrl}${redirectQueryParams}`} />;
      }

      return <Redirect to={getDefaultRoute(currentUser)} />;
    }

    if (!organization) {
      return <div />;
    }

    const { useAzure, useEmailPassword, useGoogle, useOkta } = getLoginOptionsVisibility(organization);

    return (
      <Component
        email={email}
        password={password}
        useOkta={useOkta}
        useAzure={useAzure}
        useGoogle={useGoogle}
        useEmailPassword={useEmailPassword}
        organization={organization}
        autologin={autologin || hasNext}
        handleEmailValue={handleEmailValue}
        handlePasswordValue={handlePasswordValue}
        handleLogin={handleLogin}
        oktaErrorMessage={oktaErrorMessage}
        errorMessage={errorMessage}
        googleErrorMessage={googleErrorMessage}
        azureButtonClicked={handleAzureButtonClicked}
        oktaButtonClicked={handleOktaButtonClicked}
      />
    );
  };
};

export default componentHOC;
