import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';

import AgGridInput from 'design-system/atoms/AgGridInput/AgGridInput';

import theme from 'design-system/theme';
import { INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import { getChildProjectPlaceholder } from 'utils/childProject';

const GROUP_PREFIX_ID = 'group-';

const getListGroupColor = (theme, groupLevel) => {
  if (groupLevel === 1) {
    return theme?.palette?.background?.gallery;
  }
  if (groupLevel === 2) {
    return theme?.palette?.background?.lightGallery;
  }

  return theme?.palette?.background?.white;
};

const getRowStyle = params => {
  const rowId = params?.data?.id;

  if (isString(rowId) && rowId.startsWith(GROUP_PREFIX_ID)) {
    const groupLevel = Number(rowId.slice(6, 7));

    if (groupLevel) {
      const levelColor = getListGroupColor(theme, groupLevel);

      return { backgroundColor: levelColor };
    }
  } else if (params.node?.group) {
    const levelColor = getListGroupColor(theme, params.node.level + 1);

    return { backgroundColor: levelColor };
  }

  return { backgroundColor: theme?.palette?.background?.white };
};

const getGridTreeDataOptions = (
  portfolioMode,
  singleLayerGroupedBy,
  headerName,
  renderer,
  getSystemFieldName,
  isAnonymousUser,
) => ({
  groupDisplayType: 'groupRows',
  groupRowRendererParams: {
    innerRenderer: null,
  },
  shouldHideTitleColumn: true,
  groupSelectsChildren: false,
  enableRowGroup: false,
  rowClassRules: {
    'metadata-parent-row': params => {
      return !params.data || [INITIATIVE_LAYER, BET_LAYER].includes(params.data.layer);
    },
  },
  autoGroupColumnDef: {
    headerName,
    field: 'title',
    width: 400,
    rowDragManaged: true,
    rowDrag: params => {
      const hasSort = !isEmpty(params?.api?.getSortModel());

      if (hasSort || isAnonymousUser) {
        return false;
      }

      // only allow non-groups to be dragged
      const rowId = params?.data?.id;
      const isGroup = isString(rowId) && rowId.startsWith(GROUP_PREFIX_ID);

      return !isGroup;
    },
    pinned: 'left',
    lockPinned: true,
    // singleClickEdit: true,
    editable: params => !params.data.group,
    headerClass: 'first-field',
    cellEditor: AgGridInput,
    cellEditorParams: params => ({
      placeholder: getChildProjectPlaceholder(params?.data, getSystemFieldName),
    }),
    cellRendererParams: {
      autoHeight: true,
      suppressCount: true,
      suppressDoubleClickExpand: true,
      checkbox: params => {
        return params.data && !params.data.group && params.data.selectable;
      },
      innerRenderer: renderer,
    },
  },
});

export { getRowStyle, getGridTreeDataOptions };
