import { CREATE, UPDATE, DELETE, BULK_DELETE, BULK_CREATE, BULK_UPDATE } from 'store/constants/realtimeUpdateTypes';

export default (createAction, deleteAction, updateAction, bulkDeleteAction, bulkCreateAction, bulkUpdateAction) => {
  return (type, data) => {
    return dispatch => {
      if (!data) return;
      switch (type) {
        case CREATE:
          if (!createAction) return;
          return dispatch({
            type: createAction,
            payload: data,
          });
        case DELETE:
          if (!deleteAction) return;
          return dispatch({
            type: deleteAction,
            payload: data.id,
          });
        case UPDATE:
          if (!updateAction) return;
          return dispatch({
            type: updateAction,
            payload: data,
          });
        case BULK_DELETE:
          if (!bulkDeleteAction) return;
          return dispatch({
            type: bulkDeleteAction,
            payload: data.map(id => String(id)),
          });
        case BULK_CREATE:
          if (!bulkCreateAction) return;
          return dispatch({
            type: bulkCreateAction,
            payload: data,
          });
        case BULK_UPDATE:
          if (!bulkUpdateAction) return;
          return dispatch({
            type: bulkUpdateAction,
            payload: data,
          });
        default:
      }
    };
  };
};
