import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { BASE_ROW_HEIGHT } from 'constants/grid';

import { BaseGrid } from 'containers/Grids';

import { useCommonGridProps, useCommonGridEventHandlers } from 'design-system/molecules/AgGridReact-New/hooks';
import {
  sumNumericFields,
  sumScopeVarianceField,
  sumPointsVarianceField,
} from 'design-system/molecules/AgGridReact-New/aggFuncs';

import useLoginInfo from 'hooks/useLoginInfo';
import usePageFilters from 'hooks/filters/usePageFilters';
import useOrganizations from 'hooks/useOrganizations';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useAdditionalMetadataDropdownOptions from 'hooks/metadata/useAdditionalMetadataDropdownOptions';

import { ID_KEY, GRID_HEIGHT } from './constants';
import { getDataPath, getRowId, checkRowSelectable } from './helpers';
import {
  useIdeasGrid,
  useIdeasGridColumnsDefinitions,
  useMetadata,
  useProjectsOnGrids,
  useGridState,
  useSystemFields,
} from './hooks';
import { genericStyles } from './styled';
import useIdeasGridRowDrag from './hooks/useIdeasGridRowDrag';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';
import { getProjectLayers } from 'store/filters/selectors';

const IdeasGrid = ({ viewType, data, onGridReady: parentHandleGridReady, gridHeight = GRID_HEIGHT, isTreeView = false }) => {
  const { getSystemFieldName } = useSystemFields();

  const { hasIdeas } = useSelector(getProjectLayers);

  const { displayLayer, portfolioMode, searchString: pageFiltersSearchString } = usePageFilters();

  const { currentUser } = useLoginInfo();

  const {
    organization,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    filters: { topLayer },
    customUserFields,
    integrations,
    customFields,
    hasMetadataMultiSelect,
  } = useOrganizations();

  const { isParentDragon, isDodActive } = useOrganizationsAccessControl();

  const { metadata, filteredMetadata, checkUserCanCreateNewMetadata, createMetadataMultiSelectOption } = useMetadata(data, {
    currentUser,
    organization,
  });

  const additionalMetadataOptions = useAdditionalMetadataDropdownOptions();

  const {
    hasUnsavedProject,
    defaultPhase,

    updateProjectById,
    createUnsavedProject,
    removeUnsavedProjects,
    createNewProject,
    openProjectInfo,
    openOkrInfo,
    loadParentProjects,
    switchProjectRowOrder,
  } = useProjectsOnGrids();

  const {
    rowHeight,
    expandedGroups,
    isGrouping,
    searchString,
    isBulkDeleting,
    visibleColumnsIds,
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    columnsState,
    shouldGridBeUnmounted,

    saveGridConfig,
    setSelectedItems,
    saveColumnsState,
    removeGridRow,
    setDirtyViewMutex,
  } = useGridState(viewType);

  const {
    processedData,

    getGridIsReady,
    getGridApi,

    handleGridReady,
    handleRowGroupOpened,
    handleSelectionChanged,
    handleDisplayedColumnsChanged,
    handleColumnResized,
    checkIsGroupOpenByDefault,
    addNewInlineProject,
    openRowInfo,
    updateProjectByIdOnCellValueChange,
    loadParentProjectsOptions,
    handleMetadataCellDoubleClick,
  } = useIdeasGrid(data, {
    parentHandleGridReady,
    displayLayer,
    portfolioMode,
    currentUser,
    hasBet,
    defaultPhase,
    expandedGroups,
    columnsState,
    viewType,
    hasMultiLevelMetadata,
    shouldGridBeUnmounted,

    saveGridConfig,
    getSystemFieldName,
    createUnsavedProject,
    openProjectInfo,
    openOkrInfo,
    setSelectedItems,
    updateProjectById,
    loadParentProjects,
    saveColumnsState,
  });

  const { columnDefs, defaultColDef, groupColumnDef } = useIdeasGridColumnsDefinitions({
    portfolioMode,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    topLayer,
    displayLayer,
    isDodActive,
    rowHeight,
    visibleColumnsIds,
    titleSearchString: searchString || pageFiltersSearchString,
    customUserFields,
    currentUser,
    integrations,
    customFields,
    columnsState,
    hasMetadataMultiSelect,
    isBulkDeleting,

    getSystemFieldName,
    addNewInlineProject,
    openProjectInfo,
    openRowInfo,
    loadParentProjectsOptions,
    checkUserCanCreateNewMetadata,
    updateProjectById,
    handleMetadataCellDoubleClick,
    removeGridRow,
    createMetadataMultiSelectOption,
    hasIdeas,
  });

  const commonGridProps = useCommonGridProps({
    isTreeView: true,
    aggFuncs: {
      sumNumericFields,
      sumScopeVarianceField,
      sumPointsVarianceField,
    },
  });

  const { handleRowDrag } = useIdeasGridRowDrag({
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    switchProjectRowOrder,
    updateProjectById,
  });

  const commonGridEvents = useCommonGridEventHandlers({
    items: processedData,
    idKey: ID_KEY,
    actions: {
      update: updateProjectByIdOnCellValueChange,
      save: createNewProject,
      remove: removeUnsavedProjects,
      drag: handleRowDrag,
      sortChanged: handleDisplayedColumnsChanged,
    },
  });

  const gridContext = useMemo(
    () => ({
      hasUnsavedProject,
      isParentDragon,
      portfolioMode,
      isGrouping,
      isBulkDeleting,
      processedData,
      parents: metadata.parents,
      filteredMetadata,
      metadata,
      additionalMetadataOptions,
      displayLayer,
      isShowingParentsTree: isTreeView,
    }),
    [
      hasUnsavedProject,
      isParentDragon,
      portfolioMode,
      isGrouping,
      isBulkDeleting,
      processedData,
      metadata,
      filteredMetadata,
      additionalMetadataOptions,
      displayLayer,
      isTreeView,
    ],
  );

  useEffect(() => {
    if (getGridIsReady()) {
      getGridApi().resetRowHeights();
    }
  }, [rowHeight]);

  useEffect(() => {
    // We need to refresh Header in order to see the checkbox when bulk updating
    if (getGridIsReady()) {
      getGridApi().refreshHeader();
    }

    // Cleanup selected items if stopped bulk deleting.
    if (!isBulkDeleting && getGridIsReady()) {
      getGridApi().deselectAll();
    }
  }, [isBulkDeleting]);

  // Only for debug proposes
  // TODO: remove this when new grid is stable
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log(`columnDefs changed: if we see this message on every rerender somethin is wrong with columns def memoization`);
    }
  }, [columnDefs]);

  if (shouldGridBeUnmounted) {
    return <PageLoading />;
  }

  return (
    <>
      <BaseGrid
        // Common props:
        {...commonGridProps}
        {...commonGridEvents}
        // General props:
        onGridReady={handleGridReady}
        onFirstDataRendered={setDirtyViewMutex}
        rowData={processedData}
        context={gridContext}
        isGroupOpenByDefault={checkIsGroupOpenByDefault}
        isRowSelectable={checkRowSelectable}
        rowSelection="multiple"
        suppressRowClickSelection
        // Getters/Accessors:
        getRowId={getRowId}
        getDataPath={getDataPath}
        // Styling:
        cssStyles={genericStyles}
        height={gridHeight}
        rowHeight={rowHeight}
        headerHeight={BASE_ROW_HEIGHT}
        // Columns:
        defaultColDef={defaultColDef}
        autoGroupColumnDef={groupColumnDef}
        columnDefs={columnDefs}
        // Events:
        onRowGroupOpened={handleRowGroupOpened}
        onSelectionChanged={handleSelectionChanged}
        onDisplayedColumnsChanged={handleDisplayedColumnsChanged}
        onColumnResized={handleColumnResized}
      />
    </>
  );
};

export default IdeasGrid;
