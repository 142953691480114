import reduceReducers from 'reduce-reducers';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import { dec, defaultTo, inc, indexBy, mergeRight, pluck, prop, propOr } from 'ramda';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  FETCH_CUSTOMER_REQUESTS_FULFILLED,
  FETCH_CUSTOMER_REQUESTS_PENDING,
  FETCH_CUSTOMER_REQUESTS_REJECTED,
  FETCH_CUSTOMER_REQUEST_FULFILLED,
  DELETE_CUSTOMER_REQUEST_PENDING,
  CREATE_CUSTOMER_REQUEST_FULFILLED,
  UPDATE_CUSTOMER_REQUEST_FULFILLED,
  ADD_CUSTOMER_TO_CUSTOMER_REQUEST_FULFILLED,
  REMOVE_CUSTOMER_FROM_CUSTOMER_REQUEST_FULFILLED,
  ADD_TAG_TO_CUSTOMER_REQUEST_FULFILLED,
  REMOVE_TAG_FROM_CUSTOMER_REQUEST_FULFILLED,
  START_EDITING_CUSTOMER_REQUEST,
  STOP_EDITING_CUSTOMER_REQUEST,
  START_EDITING_COMMENT,
  STOP_EDITING_COMMENT,
  START_CREATING_CUSTOMER_REQUEST,
  STOP_CREATING_CUSTOMER_REQUEST,
  CREATE_PROJECT_FROM_CUSTOMER_REQUEST_FULFILLED,
  REMOVE_PROJECT_FROM_CUSTOMER_REQUEST_FULFILLED,
  ADD_CUSTOMER_REQUESTS_FILTER,
  SET_CUSTOMER_REQUESTS_MULTI_FILTERS,
  SET_CUSTOMER_REQUESTS_MULTI_FILTERS_ACTIVE,
  REMOVE_CUSTOMER_REQUESTS_FILTER,
  SET_CUSTOMER_REQUESTS_FILTER,
  SET_CUSTOMER_REQUESTS_SEARCH,
  IMPORT_CUSTOMER_REQUESTS_FULFILLED,
  SET_SELECTED_VIEW,
  SWITCH_CUSTOMER_REQUEST_ROW_ORDER_PENDING,
  SWITCH_CUSTOMER_REQUEST_ROW_ORDER_FULFILLED,
  SAVE_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED,
  UPDATE_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED,
  LOAD_CUSTOMER_REQUESTS_MULTI_FILTERS,
  DELETE_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED,
  TOGGLE_CUSTOMER_REQUEST_CUSTOM_FIELDS_VIEW,
  TOGGLE_CUSTOMER_REQUEST_PORTAL_SETTINGS_VIEW,
  TOGGLE_CUSTOMER_REQUEST_FORWARD_EMAILS_VIEW,
  SET_CUSTOMER_REQUESTS_GRID_VISIBLE_FIELDS,
  SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_PIE_CHART_GROUP_BY,
  SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_GROUP_BY,
  SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_STACKED_BY,
  UPDATE_CUSTOMER_REQUEST_FORM_DATA,
  BULK_UPDATE_CUSTOMER_REQUESTS_PENDING,
  BULK_UPDATE_CUSTOMER_REQUESTS_FULFILLED,
  TOGGLE_BULK_UPDATE_CUSTOMER_REQUESTS,
  BULK_DELETE_CUSTOMER_REQUESTS_FULFILLED,
  BULK_DELETE_CUSTOMER_REQUESTS_PENDING,
  BULK_DELETE_CUSTOMER_REQUESTS_REJECTED,
  BULK_UPDATE_CUSTOMER_REQUESTS_REJECTED,
  SWITCH_CUSTOMER_REQUEST_ROW_ORDER_REJECTED,
  WATCH_CUSTOMER_REQUESTS_FULFILLED,
  UNWATCH_CUSTOMER_REQUESTS_FULFILLED,
  ADD_USER_TO_CUSTOMER_REQUESTS_WATCHERS,
  CREATE_CUSTOMER_REQUEST_FROM_PROJECT,
  CREATE_CUSTOMER_REQUEST_FROM_PROJECT_FULFILLED,
  ADD_CUSTOMER_REQUEST_FROM_PROJECT,
  ADD_CUSTOMER_REQUEST_FROM_PROJECT_FULFILLED,
  SET_CUSTOMER_REQUEST_LIST_PAGE,
  SET_CUSTOMER_REQUEST_RESULT_COUNT,
  SET_CUSTOMER_REQUEST_PAGE_LIMIT,
  REALTIME_PARTIAL_BULK_UPDATE_CUSTOMER_REQUESTS,
  SET_CUSTOMER_REQUESTS_MULTI_FILTERS_PENDING,
  SET_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED,
  SET_MULTI_FILTERS_LOADED,
  UPDATE_CUSTOMER_REQUESTS_PERSONAS_FULFILLED,
  UPDATE_CUSTOMER_REQUESTS_LIFECYCLES_FULFILLED,
  UPDATE_CUSTOMER_REQUEST_DEBOUNCED_FULFILLED,
  MERGE_CUSTOMER_REQUESTS_FULFILLED,
} from '../types';
import { FETCH_USER_FILTERS_FULFILLED } from 'store/filters/types';

import { FETCH_PROJECT_CUSTOMER_REQUESTS_FULFILLED } from 'store/projects/types';

import { SAVE_CUSTOMER_REQUEST_COMMENT_FULFILLED, DELETE_CUSTOMER_REQUEST_COMMENT_FULFILLED } from 'store/comments/types';

import deserializeCustomerRequest from '../helpers/deserialize';
import { AVAILABLE_VIEWS, CUSTOMER_REQUESTS_DEFAULT_PAGINATION_LIMIT } from 'constants/customerRequests';
import { DEFAULT_PAGINATION_INFORMATION } from 'constants/pagination';
import visibleFields from '../constants/visibleFields';
import operationsReducers, { initialState as operationsInitialState } from './operationsReducer';
import { RECENTLY_CREATED_FILTER_KEY } from 'routes/CustomerRequests/HeaderFilters/constants';
import { SAVE_AUTO_TAGS_FULFILLED, SET_AUTO_TAGS_AS_TAGS_FULFILLED } from 'features/CustomerRequestsAutoTag/store/types';
import { REQUESTS_FILTERS } from 'constants/filters/pages';
import { LOCATION_CHANGE } from 'store/app/types';

const { initialState: defaultInitialState, reducers } = getThunksInitialStateAndReducers([
  CREATE_CUSTOMER_REQUEST_FROM_PROJECT,
  ADD_CUSTOMER_REQUEST_FROM_PROJECT,
  SET_CUSTOMER_REQUESTS_MULTI_FILTERS,
]);

const initialState = {
  ...defaultInitialState,
  ...operationsInitialState,
  allIds: [],
  byId: {},
  creating: false,
  customFieldsDrawer: false,
  portalSettingsDrawer: false,
  forwardEmailsDrawer: false,
  editing: false,
  commenting: false,
  filters: {},
  formData: {},
  isBulkUpdate: false,
  isBulkUpdatePending: false,
  multiFilterActive: RECENTLY_CREATED_FILTER_KEY,
  multiFilters: [
    {
      key: 'created_after',
      values: ['4'],
    },
  ],
  multiFiltersSaved: {},
  pageLimit: CUSTOMER_REQUESTS_DEFAULT_PAGINATION_LIMIT,
  paginationInformation: DEFAULT_PAGINATION_INFORMATION,
  resultsPage: 1,
  selectedView: AVAILABLE_VIEWS.LIST,
  totalResultsCount: 0,
  visibleFields,
  isLoading: false,
  multiFiltersLoaded: false,
};

const getRequestFilterFieldsAsArray = (filterFields = {}) =>
  Object.entries(filterFields).reduce((acc, [key, val]) => {
    const keyNumber = Number(key);

    if (!Number.isNaN(keyNumber)) {
      return [...acc, val];
    }
    return acc;
  }, []);

/**
 * Updates the store requests with refreshed data
 * @param state
 * @param payload
 * @return {*&{byId: *}}
 */
const updateRequestOnStore = (state, payload) => {
  const deserializedPayload = deserializeCustomerRequest(payload);

  const newStateDiff = { byId: { ...state.byId, [payload.id]: deserializedPayload } };

  if (state.editing && +payload.id === +state.formData.id) {
    newStateDiff.formData = deserializedPayload;
  }

  return {
    ...state,
    ...newStateDiff,
  };
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROJECT_CUSTOMER_REQUESTS_FULFILLED: {
      const newState = action.payload.reduce(
        (newState, entry) => {
          const deserializedEntry = deserializeCustomerRequest(entry);

          return {
            byId: {
              ...newState.byId,
              [entry.id]: deserializedEntry,
            },
          };
        },
        { byId: state.byId || {} },
      );

      return {
        ...state,
        ...newState,
      };
    }
    case FETCH_CUSTOMER_REQUESTS_FULFILLED: {
      const newState = action.payload.reduce(
        (newState, entry) => {
          const deserializedEntry = deserializeCustomerRequest(entry);

          return {
            byId: {
              ...newState.byId,
              [entry.id]: deserializedEntry,
            },
            allIds: [...newState.allIds, entry.id],
            isLoading: false,
          };
        },
        { byId: state.byId || {}, allIds: [] },
      );

      return {
        ...state,
        ...newState,
      };
    }
    case FETCH_CUSTOMER_REQUESTS_PENDING: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_CUSTOMER_REQUESTS_REJECTED: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case FETCH_CUSTOMER_REQUEST_FULFILLED: {
      const customerRequest = deserializeCustomerRequest(action.payload);

      return {
        ...state,
        byId: {
          ...state.byId,
          [customerRequest.id]: customerRequest,
        },
        formData: state.formData && state.formData.id === customerRequest.id ? customerRequest : state.formData,
        allIds: state.allIds.includes(customerRequest.id) ? state.allIds : [customerRequest.id, ...state.allIds],
      };
    }
    case CREATE_CUSTOMER_REQUEST_FULFILLED: {
      const newState = {
        ...state,
        byId: { ...state.byId, [action.payload.id]: deserializeCustomerRequest(action.payload) },
        allIds: [action.payload.id, ...state.allIds],
      };

      if (state.creating) {
        newState.creating = false;
        newState.editing = true;
        newState.formData = deserializeCustomerRequest({
          ...action.payload,
          customers: action.meta?.customers,
          tags: action.meta?.tags,
        });
      }
      return newState;
    }
    case UPDATE_CUSTOMER_REQUEST_FULFILLED:
    case UPDATE_CUSTOMER_REQUESTS_PERSONAS_FULFILLED:
    case UPDATE_CUSTOMER_REQUESTS_LIFECYCLES_FULFILLED: {
      if (action.meta?.batch) return state;

      return updateRequestOnStore(state, action?.payload);
    }
    case UPDATE_CUSTOMER_REQUEST_DEBOUNCED_FULFILLED: {
      if (Array.isArray(action.payload)) {
        return action.payload.reduce((currState, eachPayload) => {
          return updateRequestOnStore(currState, eachPayload);
        }, state);
      }

      return updateRequestOnStore(state, action);
    }
    case BULK_UPDATE_CUSTOMER_REQUESTS_PENDING: {
      return {
        ...state,
        isBulkUpdatePending: true,
      };
    }
    case BULK_UPDATE_CUSTOMER_REQUESTS_FULFILLED: {
      const byIdDiff = {};

      const deserializedPayload = action.payload.map(item => deserializeCustomerRequest(item));

      deserializedPayload.forEach(item => {
        byIdDiff[item.id] = item;
      });

      const newState = { byId: { ...state.byId, ...byIdDiff } };

      return {
        ...state,
        ...newState,
        isBulkUpdate: false,
        isBulkUpdatePending: false,
      };
    }
    case REALTIME_PARTIAL_BULK_UPDATE_CUSTOMER_REQUESTS: {
      if (!Array.isArray(action.payload)) {
        return;
      }

      const byIdDiff = {};
      let formDataDiff = state.formData;

      action.payload.forEach(partialUpdate => {
        const current = propOr({}, partialUpdate.id)(state.byId);

        byIdDiff[partialUpdate.id] = mergeRight(current, partialUpdate);

        if (state.formData?.id === partialUpdate.id) {
          formDataDiff = mergeRight(state.formData, partialUpdate);
        }
      });

      return {
        ...state,
        byId: { ...state.byId, ...byIdDiff },
        formData: formDataDiff,
      };
    }
    case DELETE_CUSTOMER_REQUEST_PENDING: {
      return {
        ...state,
        byId: omit(state.byId, [action.meta.id]),
        allIds: state.allIds.filter(id => +id !== +action.meta.id),
        ...(state.formData && +state.formData.id === +action.meta.id
          ? {
              formData: {},
              creating: false,
              editing: false,
            }
          : {}),
      };
    }
    case ADD_CUSTOMER_TO_CUSTOMER_REQUEST_FULFILLED: {
      let customerRequest = state.byId[action.meta.id];

      if (!customerRequest) return state;

      customerRequest = {
        ...customerRequest,
        customer_ids: [...customerRequest.customer_ids, action.meta.customerId],
      };
      const newStateDiff = {
        byId: { ...state.byId, [action.meta.id]: customerRequest },
        formData: { ...state.formData },
      };

      if (+action.meta.id === +state.formData.id) {
        newStateDiff.formData.customer_ids = [...state.formData.customer_ids, action.meta.customerId];
      }

      return {
        ...state,
        ...newStateDiff,
      };
    }
    case REMOVE_CUSTOMER_FROM_CUSTOMER_REQUEST_FULFILLED: {
      let customerRequest = state.byId[action.meta.id];

      if (!customerRequest) return state;

      customerRequest = {
        ...customerRequest,
        customer_ids: customerRequest.customer_ids.filter(id => +id !== +action.meta.customerId),
      };
      const newStateDiff = { byId: { ...state.byId, [action.meta.id]: customerRequest }, formData: customerRequest };

      return {
        ...state,
        ...newStateDiff,
      };
    }
    case ADD_TAG_TO_CUSTOMER_REQUEST_FULFILLED: {
      let customerRequest = state.byId[action.meta.id];

      if (!customerRequest) return state;

      customerRequest = {
        ...customerRequest,
        tag_ids: [...customerRequest.tag_ids, action.meta.tagId],
      };
      const newStateDiff = {
        byId: { ...state.byId, [action.meta.id]: customerRequest },
        formData: { ...state.formData },
      };

      if (action.meta.id === state.formData.id) {
        newStateDiff.formData.tag_ids = [...state.formData.tag_ids, action.meta.tagId];
      }

      return {
        ...state,
        ...newStateDiff,
      };
    }
    case REMOVE_TAG_FROM_CUSTOMER_REQUEST_FULFILLED: {
      let customerRequest = state.byId[action.meta.id];

      if (!customerRequest) return state;

      customerRequest = {
        ...customerRequest,
        tag_ids: customerRequest.tag_ids.filter(id => +id !== +action.meta.tagId),
      };
      const newStateDiff = { byId: { ...state.byId, [action.meta.id]: customerRequest }, formData: customerRequest };

      return {
        ...state,
        ...newStateDiff,
      };
    }
    case SAVE_AUTO_TAGS_FULFILLED:
      const { payload } = action;

      const updatedRequests = payload.reduce((carry, { customerRequestId, tags }) => {
        const customerRequest = state.byId[customerRequestId];

        if (!customerRequest) {
          return carry;
        }

        return {
          ...carry,
          [customerRequestId]: {
            ...customerRequest,
            autoTags: tags,
          },
        };
      }, {});

      return {
        ...state,
        byId: {
          ...state.byId,
          ...updatedRequests,
        },
      };
    case SET_AUTO_TAGS_AS_TAGS_FULFILLED: {
      const { payload } = action;

      const updatedRequests = payload.reduce((carry, { customerRequestId, tags }) => {
        const customerRequest = state.byId[customerRequestId];

        if (!customerRequest) {
          return carry;
        }

        const currentTagIds = customerRequest.tag_ids || [];

        return {
          ...carry,
          [customerRequestId]: {
            ...customerRequest,
            autoTags: tags,
            tag_ids: uniq([...currentTagIds, ...pluck('id', tags)]),
          },
        };
      }, {});

      return {
        ...state,
        byId: {
          ...state.byId,
          ...updatedRequests,
        },
      };
    }
    case CREATE_PROJECT_FROM_CUSTOMER_REQUEST_FULFILLED: {
      let customerRequest = state.byId[action.meta.id];

      if (!customerRequest) return state;

      customerRequest = {
        ...customerRequest,
        projects: [...(customerRequest.projects || []), action.payload],
      };
      const newStateDiff = { byId: { ...state.byId, [action.meta.id]: customerRequest } };

      return {
        ...state,
        ...newStateDiff,
        formData: customerRequest,
      };
    }
    case REMOVE_PROJECT_FROM_CUSTOMER_REQUEST_FULFILLED: {
      let customerRequest = state.byId[action.meta.id];

      if (!customerRequest) return state;

      customerRequest = {
        ...customerRequest,
        projects: (customerRequest.projects || []).filter(project => project.id !== action.meta.projectId),
      };
      const newStateDiff = { byId: { ...state.byId, [action.meta.id]: customerRequest } };

      return {
        ...state,
        ...newStateDiff,
        formData: customerRequest,
      };
    }
    case START_EDITING_CUSTOMER_REQUEST: {
      if (!action.payload || !action.payload.id) return state;

      const { loadedRequestData } = action.payload;

      const actionLoadedRequestData = loadedRequestData ? deserializeCustomerRequest(loadedRequestData) : null;

      const formData = defaultTo(state.byId[action.payload.id], actionLoadedRequestData);

      return {
        ...state,
        editing: true,
        creating: false,
        formData,
      };
    }
    case STOP_EDITING_CUSTOMER_REQUEST: {
      return {
        ...state,
        editing: false,
        formData: {},
      };
    }
    case START_EDITING_COMMENT: {
      if (!action.payload || !action.payload.id) return state;

      return {
        ...state,
        commenting: true,
        formData: state.byId[action.payload.id],
      };
    }
    case STOP_EDITING_COMMENT: {
      return {
        ...state,
        commenting: false,
        formData: {},
      };
    }
    case START_CREATING_CUSTOMER_REQUEST: {
      if (state.creating) return state;
      return {
        ...state,
        editing: false,
        creating: true,
        formData: {},
      };
    }
    case STOP_CREATING_CUSTOMER_REQUEST: {
      return {
        ...state,
        creating: false,
        formData: {},
      };
    }
    case SET_CUSTOMER_REQUESTS_FILTER: {
      if (!action.payload) return state;

      const { field, values } = action.payload;

      return {
        ...state,
        filters: {
          ...(state.filters || []),
          [field]: values,
        },
      };
    }
    case ADD_CUSTOMER_REQUESTS_FILTER: {
      if (!action.payload) return state;

      const { field, value } = action.payload;

      return {
        ...state,
        filters: {
          ...(state.filters || []),
          [field]: uniq([...((state.filters || {})[field] || []), value]),
        },
      };
    }
    case REMOVE_CUSTOMER_REQUESTS_FILTER: {
      if (!action.payload) return state;

      const { field, value } = action.payload;

      return {
        ...state,
        filters: {
          ...(state.filters || []),
          [field]: ((state.filters || {})[field] || []).filter(entry => entry !== value),
        },
      };
    }
    case LOAD_CUSTOMER_REQUESTS_MULTI_FILTERS: {
      const requestsFilters = defaultTo([], action.payload);

      const multiFiltersSaved = indexBy(prop('id'), requestsFilters);

      return {
        ...state,
        multiFiltersSaved,
      };
    }
    case SET_CUSTOMER_REQUESTS_MULTI_FILTERS_ACTIVE: {
      if (!action.payload) return state;

      const { id } = action.payload;

      return {
        ...state,
        multiFilterActive: id,
      };
    }
    case SET_CUSTOMER_REQUESTS_MULTI_FILTERS_PENDING: {
      const { filters, multiFilterActive } = action.meta;

      return {
        ...state,
        multiFilters: filters,
        multiFilterActive,
      };
    }
    case SET_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED: {
      if (!action.payload) return state;

      const savedUserFilter = action.payload;

      return {
        ...state,
        multiFiltersSaved: {
          ...state.multiFiltersSaved,
          [savedUserFilter.id]: savedUserFilter,
        },
      };
    }
    case SET_MULTI_FILTERS_LOADED: {
      return {
        ...state,
        multiFiltersLoaded: action.payload,
      };
    }
    case SAVE_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED:
    case UPDATE_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED: {
      if (!action.payload) return state;

      const { data } = action.payload;

      return {
        ...state,
        multiFilterActive: data?.id,
        multiFilters: data?.state,
        multiFiltersSaved: {
          ...state.multiFiltersSaved,
          [data.id]: {
            id: data.id,
            name: data.name,
            state: data.state,
          },
        },
      };
    }
    case DELETE_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED: {
      const { data: id } = action.payload;

      const myMultiFiltersSaved = Object.keys(state.multiFiltersSaved)
        .filter(f => +f !== id)
        .reduce((obj, key) => {
          obj[key] = state.multiFiltersSaved[key];
          return obj;
        }, {});

      return {
        ...state,
        multiFiltersSaved: myMultiFiltersSaved,
        multiFilterActive: state?.multiFilterActive === id ? null : state.multiFilterActive,
      };
    }
    case SET_CUSTOMER_REQUESTS_SEARCH: {
      if (!action.payload) return state;

      const { value } = action.payload;

      return {
        ...state,
        search: value,
      };
    }
    case LOCATION_CHANGE:
      return {
        ...state,
        search: undefined,
      };
    case IMPORT_CUSTOMER_REQUESTS_FULFILLED: {
      if (!action.payload) return state;

      const newState = {
        ...state,
        byId: { ...state.byId },
        allIds: [...state.allIds],
      };

      action.payload.forEach(customerRequest => {
        newState.byId[customerRequest.id] = deserializeCustomerRequest(customerRequest);
        newState.allIds.unshift(customerRequest.id);
      });

      return newState;
    }
    case ADD_CUSTOMER_REQUEST_FROM_PROJECT_FULFILLED:
    case CREATE_CUSTOMER_REQUEST_FROM_PROJECT_FULFILLED: {
      const { data } = action.payload;

      if (!data) return state;

      return {
        ...state,
        byId: {
          ...state.byId,
          [data.id]: deserializeCustomerRequest(data),
        },
        allIds: uniq([...state.allIds, +data.id]),
      };
    }
    case SET_SELECTED_VIEW: {
      if (!action.view || !Object.keys(AVAILABLE_VIEWS).includes(action.view)) return state;

      return {
        ...state,
        selectedView: action.view,
      };
    }
    case SWITCH_CUSTOMER_REQUEST_ROW_ORDER_REJECTED: {
      if (!action.meta || !action.meta.prev) return state;

      const newState = { byId: { ...state.byId, [action.meta.prev.id]: action.meta.prev } };

      return {
        ...state,
        ...newState,
      };
    }
    case SWITCH_CUSTOMER_REQUEST_ROW_ORDER_FULFILLED:
    case SWITCH_CUSTOMER_REQUEST_ROW_ORDER_PENDING: {
      if (!action.payload) return state;

      const deserializedPayload = deserializeCustomerRequest(action.payload);
      const newState = { byId: { ...state.byId, [action.payload.id]: deserializedPayload } };

      return {
        ...state,
        ...newState,
      };
    }
    case TOGGLE_CUSTOMER_REQUEST_CUSTOM_FIELDS_VIEW: {
      return {
        ...state,
        customFieldsDrawer: !state.customFieldsDrawer,
      };
    }
    case TOGGLE_CUSTOMER_REQUEST_PORTAL_SETTINGS_VIEW: {
      return {
        ...state,
        portalSettingsDrawer: !state.portalSettingsDrawer,
      };
    }
    case TOGGLE_CUSTOMER_REQUEST_FORWARD_EMAILS_VIEW: {
      return {
        ...state,
        forwardEmailsDrawer: !state.forwardEmailsDrawer,
      };
    }
    case SET_CUSTOMER_REQUESTS_GRID_VISIBLE_FIELDS: {
      return {
        ...state,
        visibleFields: action.visibleFields || [],
      };
    }
    case SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_PIE_CHART_GROUP_BY: {
      return {
        ...state,
        selectedCounterPieChartGroupBy: action.selectedCounterPieChartGroupBy || {},
      };
    }
    case SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_GROUP_BY: {
      return {
        ...state,
        selectedCounterStackedChartGroupBy: action.selectedCounterStackedChartGroupBy || {},
      };
    }
    case SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_STACKED_BY: {
      return {
        ...state,
        selectedCounterStackedChartStackedBy: action.selectedCounterStackedChartStackedBy || {},
      };
    }
    case UPDATE_CUSTOMER_REQUEST_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };
    case TOGGLE_BULK_UPDATE_CUSTOMER_REQUESTS: {
      return {
        ...state,
        isBulkUpdate: !state.isBulkUpdate,
      };
    }
    case BULK_DELETE_CUSTOMER_REQUESTS_PENDING: {
      return {
        ...state,
        isBulkUpdatePending: true,
      };
    }
    case MERGE_CUSTOMER_REQUESTS_FULFILLED:
    case BULK_DELETE_CUSTOMER_REQUESTS_FULFILLED: {
      return {
        ...state,
        isBulkUpdate: false,
        isBulkUpdatePending: false,
        byId: omit(state.byId, action.payload),
        allIds: state.allIds.filter(id => !(action.payload || []).some(item => +item === +id)),
      };
    }
    case BULK_UPDATE_CUSTOMER_REQUESTS_REJECTED:
    case BULK_DELETE_CUSTOMER_REQUESTS_REJECTED: {
      return {
        ...state,
        isBulkUpdatePending: false,
        isBulkUpdate: false,
        isBulkMerge: false,
      };
    }
    case WATCH_CUSTOMER_REQUESTS_FULFILLED: {
      const customerRequest = state.byId[action.meta.id];

      if (!customerRequest) return state;

      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            ...customerRequest,
            watchers: customerRequest.watchers?.some(watcher => watcher.id === action.payload.user_id)
              ? customerRequest.watchers
              : [...(customerRequest.watchers || []), action.payload.user],
          },
        },
        formData: {
          ...state.formData,
          watchers:
            action.meta.id === state.formData.id
              ? [...(state.formData.watchers || []), action.payload.user]
              : state.formData.watchers || [],
        },
      };
    }
    case UNWATCH_CUSTOMER_REQUESTS_FULFILLED: {
      const customerRequest = state.byId[action.meta.id];

      if (!customerRequest) return state;

      return {
        ...state,
        byId: {
          ...state.byId,
          [action.meta.id]: {
            ...customerRequest,
            watchers: customerRequest.watchers.filter(watcher => watcher.user_id !== action.meta.userId),
          },
        },
        formData: {
          ...state.formData,
          watchers: state.formData.watchers.filter(watcher => watcher.id !== action.meta.userId),
        },
      };
    }
    case ADD_USER_TO_CUSTOMER_REQUESTS_WATCHERS: {
      const { id } = action.meta || {};
      const customerRequest = state.byId[id];

      if (!customerRequest || !action.payload) {
        return state;
      }

      const newWatchers = customerRequest.watchers?.some(watcher => watcher.id === action.payload.id)
        ? customerRequest.watchers
        : [...(customerRequest.watchers || []), action.payload];

      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: { ...customerRequest, watchers: newWatchers },
        },
        formData: { ...state.formData, watchers: newWatchers },
      };
    }
    case SAVE_CUSTOMER_REQUEST_COMMENT_FULFILLED: {
      const { field } = action.payload;
      const { customerRequestId } = action.meta || {};
      const customerRequest = state.byId[customerRequestId];

      if (!customerRequest) {
        return state;
      }

      const newCountValue = inc(customerRequest.comments_count || 0);
      const countValue = !field ? { comments_count: newCountValue } : {};

      return {
        ...state,
        byId: {
          ...state.byId,
          [customerRequestId]: { ...customerRequest, ...countValue },
        },
        formData: { ...state.formData, ...countValue },
      };
    }
    case DELETE_CUSTOMER_REQUEST_COMMENT_FULFILLED: {
      const { field } = action.payload;
      const { customerRequestId } = action.meta || {};
      const customerRequest = state.byId[customerRequestId];

      if (!customerRequest) {
        return state;
      }

      // decrements by 1 if counter is greater than 0, else returns 0
      const newCountValue = dec(customerRequest.comments_count || 1);
      const countValue = !field ? { comments_count: newCountValue } : {};

      return {
        ...state,
        byId: {
          ...state.byId,
          [customerRequestId]: { ...customerRequest, ...countValue },
        },
        formData: { ...state.formData, ...countValue },
      };
    }
    case SET_CUSTOMER_REQUEST_LIST_PAGE: {
      return {
        ...state,
        paginationInformation: action.payload || DEFAULT_PAGINATION_INFORMATION,
      };
    }
    case SET_CUSTOMER_REQUEST_RESULT_COUNT: {
      return {
        ...state,
        totalResultsCount: action.payload,
      };
    }
    case SET_CUSTOMER_REQUEST_PAGE_LIMIT: {
      return {
        ...state,
        pageLimit: action.payload,
      };
    }
    // we need to keep the store updated for proper filter usage while on default views
    // and while changing tabs on the request page
    case FETCH_USER_FILTERS_FULFILLED: {
      const requestDefaultFilter = action?.payload?.data?.find(
        filter => filter?.page === REQUESTS_FILTERS && filter?.default_filter,
      );

      if (!requestDefaultFilter) {
        return state;
      }

      // to ensure retrocompatibility we need to grab all filter fields that were selected and not under the multiFilters state
      const { state: { multiFilters = [], multiFilterActive = null, ...filterFields } = {} } = requestDefaultFilter;

      // Convert the old filter structure to the new one
      // Also avoid any unwanted fields injected but mutability on the page filters - only numeric keys are fields for requests
      // fetchUserFilters() converts array to object when doing the cleanup
      const convertedFilterFieldsToMultiFilterArray = getRequestFilterFieldsAsArray(filterFields);

      const hasStoredFilters = convertedFilterFieldsToMultiFilterArray.length || multiFilters?.length;

      if (hasStoredFilters) {
        return {
          ...state,
          multiFilters: convertedFilterFieldsToMultiFilterArray.length ? convertedFilterFieldsToMultiFilterArray : multiFilters,
          multiFilterActive,
        };
      }

      return state;
    }
    default: {
      return state;
    }
  }
};

const reducer = reduceReducers(initialState, ...reducers, ...operationsReducers, mainReducer);

export default reducer;
