import React, { useState } from 'react';
import styled from 'styled-components';
import { not } from 'ramda';

import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PieChartOutlinedIcon from '@material-ui/icons/PieChartOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import Dropdown from 'design-system/molecules/Dropdown/index';
import ZoomIcon from 'design-system/atoms/ZoomIcon/index';
import UnfilterIcon from 'design-system/atoms/UnfilterIcon/index';
import DependenciesTogglePopover from 'design-system/molecules/DependenciesTogglePopover/index';
import ToggleButton from 'design-system/molecules/ToggleButton/index';

import { spacing } from 'design-system/theme';
import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete';
import ColorToolbar from 'components/ColorToolbar';
import ShareView from 'containers/ShareView';
import ImportIdeasDialog from 'containers/ImportIdeasDialog';
import DependenciesTableLightbox from 'containers/DependenciesTableLightbox';
import { removeGanttTolltip } from 'components/ganttCommon';
import { getGroupOptionByKey } from 'store/projects/helpers/groupOptions';
import LocalSearchInput from 'containers/LocalSearchInput';
import AboveTheLineViewingSelector from 'containers/AboveTheLineViewingSelector/AboveTheLineViewingSelector';

import PreferencesDialog from './PreferencesDialog';
import SyncIntegration from './SyncIntegration';
import ImportJsonDialog from './ImportJsonDialog';
import ShowMyItemsToggle from 'containers/ShowMyItemsToggle';
import { CreateRoadmapSnapshotButton } from 'features/RoadmapHistory/components';

export default ({
  lsState,
  groupByOptions,
  groupByGroup2Options,
  onChangeSetting,
  onGroupLevelChange,
  getLegendGroups,
  updateGroupColor,
  moreDropdownOptions,
  onDropdownChange,
  setOpenPreferences,
  changeSelectedZoom,
  localState,
  updateLocalState,
  updateLsState,
  pageId,
  showSyncIntegration,
  showGroupBy2,
  showDependenciesButton,
  onAllocationReportClick,
  onShowDependenciesBtnClick,
  showImportJsonDialog,
  setShowImportJsonDialog,
  currentProjectsState,
  projects,
  applyDepTableState,
  showResourceDisplayOpt,
  warning,
  onWarningClick,
  isRoadmapVersionCommitted,
  isCreatingOrViewingScenario,
  updateShowUncommitted,
  hasShowMyItemFilter = false,
  hideAllocationReportButton = false,
  hideMoreOptionsMenu = false,
  showUncommittedProjectsToggle = false,
  hasRoadmapHistory = false,
  viewsDropdownTabs,
}) => {
  const {
    selectedGroup1,
    selectedGroup2,
    selectedZoom,
    showHCTable,
    showBusinessValue,
    HCTableFilter,
    showAllDependencies,
    hideItemsWithoutDependencies,
    hideDependencyLines,
    showMyItemsOnly,
  } = lsState;

  const selectedGroup1Option = getGroupOptionByKey(selectedGroup1?.key, groupByOptions);
  const selectedGroup2Option = getGroupOptionByKey(selectedGroup2?.key, groupByOptions);

  const [isDependenciesDetailsOpen, setDependenciesDetailsOpen] = useState(false);

  const showColorLegend = colorBy => {
    return colorBy && colorBy.title !== 'Idea' ? (
      <ControlItem style={{ display: 'inline-block' }}>
        <ColorToolbar
          groups={getLegendGroups(colorBy)}
          showPicker={colorBy.title !== 'Health'}
          onUpdateGroupColor={updateGroupColor(colorBy)}
        />
      </ControlItem>
    ) : (
      ''
    );
  };

  const onDependencyLinesToggle = React.useCallback(
    () => updateLsState({ hideDependencyLines: !hideDependencyLines }),
    [updateLsState, hideDependencyLines],
  );

  const onItemsWithoutDependenciesToggle = React.useCallback(
    () => updateLsState({ hideItemsWithoutDependencies: !hideItemsWithoutDependencies }),
    [updateLsState, hideItemsWithoutDependencies],
  );

  const showViewsButton = not(isCreatingOrViewingScenario);

  return (
    <>
      <Wrapper container spacing={8}>
        <Left item>
          <FormGroup row>
            <GroupByAutocomplete
              name="selectedGroup1"
              suggestions={groupByOptions}
              value={selectedGroup1Option}
              onChange={onGroupLevelChange('selectedGroup1')}
            />
            {selectedGroup1Option && selectedGroup1Option.key && showGroupBy2 && (
              <>
                <Spacing />
                <GroupByAutocomplete
                  name="selectedGroup2"
                  suggestions={groupByGroup2Options}
                  value={selectedGroup2Option}
                  onChange={onGroupLevelChange('selectedGroup2')}
                  label="then by"
                />
              </>
            )}
            {showUncommittedProjectsToggle ? (
              <AboveTheLineViewingSelectorWrapper>
                <AboveTheLineViewingSelector
                  onValueChange={updateShowUncommitted}
                  selectedValue={lsState?.showUncommittedProjects}
                />
              </AboveTheLineViewingSelectorWrapper>
            ) : null}
          </FormGroup>
        </Left>
        {warning && (
          <WarningMsg>
            <a role="link" tabIndex={0} onClick={() => onWarningClick()}>
              {warning.message}
            </a>
          </WarningMsg>
        )}
        <Right item style={{ textAlign: 'right' }}>
          <FormGroup row style={{ justifyContent: 'flex-end' }}>
            <LocalSearchInput />
            {hasRoadmapHistory && <CreateRoadmapSnapshotButton WrapperComponent={IconWrapper} />}
            {hasShowMyItemFilter && (
              <IconWrapper>
                <ShowMyItemsToggle
                  showMyItemsOnly={showMyItemsOnly}
                  updateShowMyItemsOnly={() => updateLsState({ showMyItemsOnly: !showMyItemsOnly })}
                />
              </IconWrapper>
            )}
            {!showHCTable && !showBusinessValue && showSyncIntegration && (
              <StyledSyncIntegration lsState={lsState} onChangeSetting={onChangeSetting} />
            )}
            {showHCTable && !!HCTableFilter && (
              <IconWrapper>
                <ToggleButton
                  title="Unfilter"
                  data-test="unfilter-button"
                  onChange={() => {
                    updateLsState({ HCTableFilter: null });
                  }}
                  icon={<UnfilterIcon fontSize="inherit" />}
                  on
                />
              </IconWrapper>
            )}
            {showDependenciesButton && (
              <IconWrapper>
                <DependenciesTogglePopover
                  showDependencyLines={!hideDependencyLines}
                  onDependencyLinesToggle={onDependencyLinesToggle}
                  showAllDependencies={showAllDependencies}
                  onAllDependenciesToggle={onShowDependenciesBtnClick}
                  hideItemsWithoutDependencies={hideItemsWithoutDependencies}
                  onItemsWithoutDependenciesToggle={onItemsWithoutDependenciesToggle}
                  extraRows={[
                    {
                      id: 'dependency-details',
                      key: 'dependency-details',
                      onClick: () => {
                        setDependenciesDetailsOpen(true);
                        removeGanttTolltip();
                      },
                      title: (
                        <div>
                          <DependenciesDetailsButtonIcon>
                            <OpenInNewIcon />
                          </DependenciesDetailsButtonIcon>
                          Dependency Details
                        </div>
                      ),
                    },
                  ]}
                />
              </IconWrapper>
            )}
            {!hideAllocationReportButton && (
              <ControlItem>
                <ButtonIcon title="Allocation Report" onClick={onAllocationReportClick} id="allocation-report">
                  <PieChartOutlinedIcon />
                </ButtonIcon>
              </ControlItem>
            )}
            <ControlItem>
              <ButtonIcon title={selectedZoom && selectedZoom.title} onClick={changeSelectedZoom} id="zoom-icon">
                {selectedZoom && selectedZoom.id === 'day' && (
                  <ZoomIcon size="small">
                    <span />
                  </ZoomIcon>
                )}
                {(!selectedZoom || selectedZoom.id === 'week') && (
                  <ZoomIcon size="medium">
                    <span />
                  </ZoomIcon>
                )}
                {selectedZoom && selectedZoom.id === 'month' && (
                  <ZoomIcon>
                    <span />
                  </ZoomIcon>
                )}
                {selectedZoom && selectedZoom.id === 'quarter' && (
                  <ZoomIcon size="big">
                    <span />
                  </ZoomIcon>
                )}
              </ButtonIcon>
            </ControlItem>
            <PreferencesDialog
              showColorLegend={showColorLegend}
              triggerOpenEvent={open => setOpenPreferences(open)}
              localState={localState}
              updateLocalState={updateLocalState}
              lsState={lsState}
              updateLsState={updateLsState}
              pageId={pageId}
              showResourceDisplayOpt={showResourceDisplayOpt}
            />
            <ImportJsonDialog
              showImportJsonDialog={showImportJsonDialog}
              setShowImportJsonDialog={setShowImportJsonDialog}
              currentProjectsState={currentProjectsState}
            />
            {showViewsButton && <ShareView pageId={pageId} viewsDropdownTabs={viewsDropdownTabs} id="view-icon" />}
            {!isRoadmapVersionCommitted && (
              <Dropdown
                onChange={onDropdownChange}
                placeholder={
                  <ButtonIcon>
                    <MoreHorizIcon />
                  </ButtonIcon>
                }
                options={moreDropdownOptions}
                isButton={false}
                MenuListProps={{
                  style: { maxHeight: '500px', overflowY: 'auto' },
                }}
              />
            )}
            <ImportIdeasDialog />
          </FormGroup>
        </Right>

        {lsState.checkedColorLegend && <ColorLegend>{showColorLegend(lsState.selectedColorBy)}</ColorLegend>}
        <DependenciesTableLightbox
          projects={projects}
          isOpen={isDependenciesDetailsOpen}
          onClose={() => setDependenciesDetailsOpen(false)}
          tableState={lsState.depTableState}
          applyTableState={applyDepTableState}
        />
      </Wrapper>
    </>
  );
};

export const Wrapper = styled(Grid)`
  &&&& {
    min-height: ${props => props.theme.sizing.toolbar.height};
    max-width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0 30px 0 36px;

    margin: 0 0 10px 0;
    justify-content: space-between;
  }
`;

const Left = styled(Grid)`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0 0;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: flex-start;
  }
`;

const Right = styled(Grid)`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: right;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 400px;
    justify-content: flex-end;
  }
`;

const ControlItem = styled.div``;

const ColorLegend = styled.div`
  border: 1px solid #efefef;
  border-radius: 5px;
  margin: 0 4px 4px 4px;
  overflow-y: auto;
  width: 100%;
  max-height: 66px;
  padding: 8px;
`;

const DependenciesDetailsButtonIcon = styled(ButtonIcon)`
  &&&& {
    margin: 0 10px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const WarningMsg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: red;
  }
`;

const Spacing = styled.div`
  padding: 5px;
`;

const AboveTheLineViewingSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${spacing(1.25)}px;
`;

const IconWrapper = styled.div`
  width: 46px;
  display: flex;

  &:not(:last-of-type) {
    margin-right: ${spacing(0.65)}px; // 5px
  }
`;

const StyledSyncIntegration = styled(SyncIntegration)`
  width: 46px;
  display: flex;

  &:not(:last-of-type) {
    margin-right: ${spacing(0.65)}px; // 5px
  }
`;
