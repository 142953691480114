import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { materialColorsAlt } from 'design-system/themes/default';
import getSystemFieldName from 'utils/getSystemFieldName';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import { planningStages, statusColors } from 'utils';
import { getObjectives, selectKeyResults1 } from 'store/objectives/selectors';
import { getCategories } from 'store/categories/selectors';
import { getThemes } from 'store/themes/selectors';
import { getTimeframes } from 'store/timeframes/selectors';
import { getRoadmaps, getProducts } from 'store/roadmaps/selectors';
import { getTags } from 'store/tags/selectors';
import { getCustomersFilteredByStatus } from 'store/customers/selectors';
import { getPhases } from 'store/phases/selectors';
import { getUsers } from 'store/users/selectors';
import { getAllInitiatives, getAllBets } from 'store/projects/selectors';
import { getPriorities } from 'store/priorities/selectors';
import getStateDataForPage from 'store/utils/getStateDataForPage';

import { groupByOptions, generateTableData } from './helpers';
import { openProjectLightbox } from 'src/store/projectLightbox';
import { getIdeasIntegrations } from 'store/organization/selectors';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const { selectedGroup, preferences } = props.tableState || {};
    const [rows, setRows] = React.useState([]);
    const systemFields = useSelector(state => state.organization.organization.system_fields_name);
    const hasHierarchy = useSelector(state => state.organization.organization.has_hierarchy);
    const hasBet = useSelector(state => state.organization.organization.has_bet);
    const hasKeyResults = useSelector(state => state.organization.organization.has_key_results);
    const hasProducts = useSelector(state => state.organization.organization.has_products);
    const customFields = useSelector(state => getProjectsCustomFields(state));
    const metadataFromStore = useSelector(
      state => ({
        roadmaps: getStateDataForPage(state, getRoadmaps, 'roadmaps'),
        timeframes: getStateDataForPage(state, getTimeframes, 'timeframes'),
        key_result_1s: getStateDataForPage(state, selectKeyResults1, 'keyResults'),
        product_1s: getStateDataForPage(state, getProducts, 'products'),
        objectives: getStateDataForPage(state, getObjectives, 'objectives'),
        themes: getStateDataForPage(state, getThemes, 'themes'),
        categorys: getStateDataForPage(state, getCategories, 'categories'),
        users: getStateDataForPage(state, getUsers, 'users'),
        customers: getStateDataForPage(state, getCustomersFilteredByStatus, 'customers'),
        phases: getStateDataForPage(state, getPhases, 'phases'),
        tags: getStateDataForPage(state, getTags, 'tags'),
        priorities: getPriorities(state),
        ...(hasHierarchy ? { initiatives: getAllInitiatives(state) } : {}),
        ...(hasBet ? { bets: getAllBets(state) } : {}),
      }),
      isEqual,
    );
    const integrations = useSelector(getIdeasIntegrations);
    const metadata = React.useMemo(
      () => ({
        ...metadataFromStore,
        planningStages: planningStages.reverse().map((stage, index) => ({
          id: stage,
          title: stage,
          row_order: index,
        })),
        status_colors: statusColors.map(c => ({
          id: c.label,
          title: c.label || 'Not Defined',
          color: materialColorsAlt[c.label.toLowerCase()],
        })),
      }),
      [metadataFromStore],
    );

    const groupOptions = groupByOptions(systemFields, hasKeyResults, hasProducts, hasHierarchy, hasBet);
    const displayFieldsOptions = [
      { key: 'key', label: 'Key' },
      { key: 'health', label: 'Health', fitContent: true },
      { key: 'title', label: 'Title' },
      { key: 'roadmap', label: getSystemFieldName('roadmap', systemFields) },
      ...(hasProducts ? [{ key: 'product1', label: getSystemFieldName('product1', systemFields) }] : []),
      { key: 'timeframe', label: getSystemFieldName('timeframe', systemFields) },
      { key: 'progress', label: 'Progress' },
      { key: 'objective', label: getSystemFieldName('objective', systemFields) },
      ...(hasKeyResults ? [{ key: 'keyResult1', label: getSystemFieldName('keyResult1', systemFields) }] : []),
      { key: 'customers', label: getSystemFieldName('customer', systemFields) },
      { key: 'tags', label: getSystemFieldName('tag', systemFields) },
      { key: 'theme', label: getSystemFieldName('theme', systemFields) },
      { key: 'category', label: getSystemFieldName('category', systemFields) },
      { key: 'progressCount', label: 'Progress issue count', fitContent: true },
      { key: 'owner', label: 'Owner' },
      { key: 'planningStage', label: 'Planning Stage', fitContent: true },
      { key: 'phase', label: getSystemFieldName('phase', systemFields), fitContent: true },
      { key: 'status_summary', label: 'Status summary', fitContent: true },
      { key: 'details', label: 'Details' },
      { key: 'priority', label: getSystemFieldName('priority', systemFields) },
      { key: 'estimated_start_date', label: 'Target start date' },
      { key: 'deadline', label: 'Target end date' },
      { key: 'predictedEndDate', label: 'Predicted end date' },
      ...(integrations.length > 0 ? [{ key: 'integrationKey', label: 'Integration Key' }] : []),
      ...(hasHierarchy ? [{ key: 'initiative', label: getSystemFieldName('initiative', systemFields) }] : []),
      ...(hasBet ? [{ key: 'bet', label: getSystemFieldName('bet', systemFields) }] : []),
      ...customFields.map(f => ({ key: f.key, label: `${f.title} (cf)` })),
    ];

    React.useEffect(() => {
      setRows(generateTableData(props.projects));
    }, [props.projects]);

    const _onChangeSelectedGroup = option => {
      props.applyTableState({ selectedGroup: option });
    };
    const _onApplyPreferences = state => {
      props.applyTableState({ preferences: state });
    };
    const _handleClickProject = React.useCallback(projectId => dispatch(openProjectLightbox(projectId)), []);

    return (
      <Component
        {...props}
        groupOptions={groupOptions}
        displayFieldsOptions={displayFieldsOptions}
        customFields={customFields}
        rows={rows}
        onChangeSelectedGroup={_onChangeSelectedGroup}
        selectedGroup={selectedGroup}
        onApplyPreferences={_onApplyPreferences}
        preferences={preferences}
        metadata={metadata}
        handleClickProject={_handleClickProject}
        systemFields={systemFields}
        orgIntegrations={integrations}
      />
    );
  };
};

export default componentHOC;
