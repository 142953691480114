import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  getDateGroup,
  getGroupingDates,
  getGroupingObjects,
  getNestedProjectGroups,
} from 'store/projects/helpers/groupSelectors';
import { BET_LAYER, GROUP_FILTER_FUNCTIONS, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { getDefaultGroupingObjects } from 'store/projects/groupSelectors';
import { hideEmptyBasedOnPrefOrArchived } from 'utils/grouping';
import setIdeasHierarchyPath from 'utils/setIdeasHierarchyPath';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import { getOrgHasMetadataRoadmaps } from 'store/organization';

import { BET, INITIATIVE } from 'constants/projects';
import useSystemFields from 'hooks/useSystemFields';

const UNDEFINED_GROUP_TITLE = 'Undefined';

const getProjectsByLayer = (projects = []) => {
  let highestLayer = IDEA_LAYER;

  const projectsByLayer = projects.reduce(
    (acc, project) => {
      if (project.layer > highestLayer) {
        highestLayer = project.layer;
      }
      return {
        ...acc,
        [project?.layer]: [...acc[project?.layer], project],
      };
    },
    {
      [IDEA_LAYER]: [],
      [INITIATIVE_LAYER]: [],
      [BET_LAYER]: [],
    },
  );

  return { projectsByLayer, highestLayer };
};

const undefinedGroupTitleOverride = 'Not in Current View';

const changeUndefinedOptionTitle = (groupingObjects, title = UNDEFINED_GROUP_TITLE) => {
  return Object.entries(groupingObjects).reduce(
    (acc, [key, options]) => ({
      ...acc,
      [key]: options.map(option => (option?.id ? option : { ...option, title: undefinedGroupTitleOverride })),
    }),
    {},
  );
};

const emptyArray = [];

const useProjectGroupsRoadmapHistory = ({
  projects = emptyArray,
  selectedGroup1,
  groupFilterFunctions = GROUP_FILTER_FUNCTIONS,
}) => {
  const { getDefaultRoadmapTitleForMetadataItem } = useOrganizationsAccessControl();
  const [getSystemFieldName] = useSystemFields();
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);
  const defaultGroupingObjects = useSelector(state => getDefaultGroupingObjects(state, true));

  const projectsWithGroups = useMemo(() => {
    if (!selectedGroup1?.key) {
      return projects;
    }

    const hideEmptyWith = (_, groupObject) => hideEmptyBasedOnPrefOrArchived({ groupObject, hideEmptyUserPref: true });

    const groups = [selectedGroup1];
    const selectedGroupByDates = getDateGroup(groups);
    const hasDateGrouping = !!selectedGroupByDates;

    const groupedDates = hasDateGrouping ? getGroupingDates(projects, selectedGroupByDates) : null;

    const groupingObjects = changeUndefinedOptionTitle(
      getGroupingObjects({
        defaultGroupingObjects,
        groupedDates,
      }),
      undefinedGroupTitleOverride,
    );

    const nestedProjectGroups = getNestedProjectGroups(projects, groupingObjects, groups, {
      groupFilterFunctions,
      hideEmptyWith,
    });

    const { highestLayer, projectsByLayer } = getProjectsByLayer(projects);

    const projectsHierarchyPath = setIdeasHierarchyPath(
      projectsByLayer,
      highestLayer,
      getSystemFieldName(INITIATIVE, false),
      getSystemFieldName(BET, false),
      true, // no hierarchy on this grid
      nestedProjectGroups,
      hasMetadataRoadmaps,
      getDefaultRoadmapTitleForMetadataItem,
    );

    return projectsHierarchyPath;
  }, [selectedGroup1, defaultGroupingObjects, hasMetadataRoadmaps, getDefaultRoadmapTitleForMetadataItem, projects]);

  return projectsWithGroups;
};

export default useProjectGroupsRoadmapHistory;
