import React from 'react';
import styled from 'styled-components';

const ActiveCircleIndicator = ({ active = false, ...props }) => {
  return <IndicatorWrapper {...props}>{active && <IndicatorElement />}</IndicatorWrapper>;
};

const IndicatorElement = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 100%;
  background-color: ${({ theme }) =>
    theme.palette.newLayout.checkbox.checked}; // to be addressed when changing the color to the new one for all items
`;

const IndicatorWrapper = styled.div`
  width: 10px;
  height: 10px;
`;

export default ActiveCircleIndicator;
