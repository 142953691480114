import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchPortfolioOverviewtByRoadmap,
  selectIsInEditMode,
  togglePortfolioOverviewEditMode,
  updatePortfolioOverviewLayout,
  updatePortfolioOverviewLocalLayout,
  updatePortfolioOverviewWidgetVisibility,
} from '../store';
import usePortfolioOverview from './usePortfolioOverview';

const usePortfolioOverviewLayout = () => {
  const dispatch = useDispatch();

  const inEditMode = useSelector(selectIsInEditMode);
  const toggleEditMode = () => {
    dispatch(togglePortfolioOverviewEditMode());
  };

  const { portfolioOverview, isLoadingPortfolioOverview, userCanEdit } = usePortfolioOverview();

  const onOverviewLayoutChange = layout => dispatch(updatePortfolioOverviewLayout(layout));

  const onWidgetHeightChange = (widgetId, height) => dispatch(updatePortfolioOverviewLocalLayout(widgetId, height));

  const onWidgetVisibilityChange = (widgetId, isVisible) => {
    dispatch(updatePortfolioOverviewWidgetVisibility(widgetId, isVisible));
  };

  const fetchPortfolioOverviewForActiveRoadmap = useCallback(activeRoadmapEntity => {
    dispatch(fetchPortfolioOverviewtByRoadmap(activeRoadmapEntity));
  }, []);

  return {
    inEditMode,
    userCanEdit,
    portfolioOverview,
    isLoadingPortfolioOverview,

    toggleEditMode,
    fetchPortfolioOverviewForActiveRoadmap,
    onOverviewLayoutChange,
    onWidgetHeightChange,
    onWidgetVisibilityChange,
  };
};

export default usePortfolioOverviewLayout;
