/**
 * PageTemplate Template Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import styled from 'styled-components';

const DrawerView = ({ header, children, drawerPosition = 'right', drawerProps = {}, ...props }) => {
  const DrawerComponent = props.drawerComponent;

  return (
    <Wrapper {...props}>
      {drawerPosition === 'left' && (
        <DrawerComponent
          {...drawerProps}
          type="DRAWER"
          withoutBackdrop
          open={!!props.isDrawerOpen}
          onClose={props.closeDrawer}
          width={props.drawerWidth || '900x'}
        />
      )}
      <Content
        onClick={props.closeDrawer}
        isDrawerOpen={props.isDrawerOpen}
        drawerWidth={props.drawerWidth}
        scrollable={props.scrollable}
      >
        {children}
      </Content>
      {drawerPosition === 'right' && (
        <DrawerComponent
          {...drawerProps}
          type="DRAWER"
          withoutBackdrop
          open={!!props.isDrawerOpen}
          onClose={props.closeDrawer}
          width={props.drawerWidth || '900x'}
        />
      )}
    </Wrapper>
  );
};

export default DrawerView;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
`;

const Content = styled.section`
  min-height: ${props => (props.scrollable ? '100vh' : '100%')};
  box-sizing: border-box;
  margin: 0;
  width: 100vw;
  transition: width 0.3s ease-in-out;
`;
