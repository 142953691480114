import { useMemo } from 'react';

import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';
import {
  getTitleColumnDef,
  statusColumnDef,
  colorColumnDef,
  updatedColumnDef,
  getMetadataRoadmapColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';

const TAG_KEY = 'tag';
const TAG_ROADMAPS_FIELD = 'tag_roadmaps';

const useTagsGridColumns = ({ roadmapColumn }) => {
  const { createTagRoadmap, deleteTagRoadmap, tags, bulkDeleteTagRoadmaps, hideMetadataRoadmaps } = roadmapColumn;

  const roadmapsMetadata = useRoadmapsMetadata(createTagRoadmap, deleteTagRoadmap, tags, TAG_KEY, bulkDeleteTagRoadmaps);

  return useMemo(
    () => [
      getTitleColumnDef('Tag Title'),
      getMetadataRoadmapColumnDef({
        roadmapsMetadata: [...roadmapsMetadata, hideMetadataRoadmaps],
        editableForNewRow: false,
        field: 'tag_roadmaps',
      }),
      statusColumnDef,
      colorColumnDef,
      updatedColumnDef,
    ],
    [],
  );
};

export default useTagsGridColumns;

export { TAG_ROADMAPS_FIELD };
