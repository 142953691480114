import React from 'react';
import styled from 'styled-components';
import DialogActions from '@material-ui/core/DialogActions';
import PrevIcon from '@material-ui/icons/KeyboardArrowLeft';

import Dialog from 'design-system/molecules/Dialog/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import PrimaryButton from 'design-system/atoms/PrimaryButton/index';
import DragonTable from 'design-system/organisms/DragonTable/index';

import ImportIdeasDialog from 'containers/ImportIdeasDialog';

import { templatesColumns } from './helpers';

export default ({ open, onClose, selectedTemplate, tableData, takeProductTour, importIdeas, goBack }) => {
  if (!selectedTemplate) return '';

  const columns = templatesColumns[selectedTemplate.id];

  return (
    <React.Fragment>
      <TemplatesDialog
        open={!!open}
        width="1141px"
        onClose={onClose}
        closeButton
        closeButtonColor="#1F8F9E"
        footer={
          <Footer>
            <div style={{ flex: 'auto' }}>
              <PrimaryButton iconpos="left" onClick={goBack}>
                <PrevIcon /> Back
              </PrimaryButton>
              <PrimaryButton align="right" variant="contained" bgcolor="#2861b1" textcolor="#FFF" onClick={takeProductTour}>
                Take a product tour
              </PrimaryButton>
              <PrimaryButton
                align="right"
                iconposition="left"
                variant="contained"
                bgcolor="#58CCC6"
                textcolor="#FFF"
                onClick={importIdeas}
              >
                Select template and import ideas
              </PrimaryButton>
            </div>
          </Footer>
        }
      >
        <HeaderContainer>
          <TextDeprecated variant="h5" component="h1">
            Template for {selectedTemplate.name}
          </TextDeprecated>
          <TextDeprecated size="medium" color="#445460">
            {selectedTemplate.previewDescription}
          </TextDeprecated>
        </HeaderContainer>
        <PreviewContainer>
          <DragonTable rows={tableData} rowCount={tableData.length}>
            {columns.map(c => (
              <DragonTable.TextColumn {...c} flexGrow={1.0} />
            ))}
          </DragonTable>
        </PreviewContainer>
      </TemplatesDialog>
      <ImportIdeasDialog />
    </React.Fragment>
  );
};

const TemplatesDialog = styled(Dialog)`
  &&&& {
    > div[role='document'] > div {
      border-radius: 8px;
    }
  }
`;

const HeaderContainer = styled.div`
  text-align: center;
  margin-bottom: 24px;
  margin-top: 32px;

  h1 {
    margin-bottom: 10px;
    font-weight: bold;
  }
  p {
    font-size: 18px !important;
  }
`;

const PreviewContainer = styled.div`
  margin: 0 auto;
  width: 957px;
  min-height: 390px;
`;

const Footer = styled(DialogActions)`
  &&&& {
    margin: 0;
    padding: 10px 16px 10px;
    /* background: #f3f3f3; */
    height: 84px;
    justify-content: end;
  }
`;
