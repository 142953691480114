import { useMemo } from 'react';

import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';
import {
  getTitleColumnDef,
  statusColumnDef,
  updatedColumnDef,
  getMetadataRoadmapColumnDef,
  colorColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';

const PRIORITY_KEY = 'priority';
const PRIORITY_ROADMAPS_FIELD = 'priority_roadmaps';

const usePrioritiesGridColumns = ({ roadmapColumn }) => {
  const { createPriorityRoadmap, deletePriorityRoadmap, priorities, bulkDeletePriorityRoadmaps, hideMetadataRoadmaps } =
    roadmapColumn;

  const roadmapsMetadata = useRoadmapsMetadata(
    createPriorityRoadmap,
    deletePriorityRoadmap,
    priorities,
    PRIORITY_KEY,
    bulkDeletePriorityRoadmaps,
  );

  return useMemo(
    () => [
      getTitleColumnDef('Title'),
      getMetadataRoadmapColumnDef({
        roadmapsMetadata: [...roadmapsMetadata, hideMetadataRoadmaps],
        field: PRIORITY_ROADMAPS_FIELD,
        editableForNewRow: false,
      }),
      statusColumnDef,
      colorColumnDef,
      updatedColumnDef,
    ],
    [roadmapsMetadata, hideMetadataRoadmaps],
  );
};

export default usePrioritiesGridColumns;

export { PRIORITY_ROADMAPS_FIELD };
