import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Dialog from 'design-system/molecules/Dialog/index';
import SelectColor from 'design-system/molecules/SelectColor/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import WYSIWYGEditorField from 'design-system/molecules/WYSIWYGEditorField/index';

import { spacing } from 'design-system/theme';

import useUpdateRoadmapSnapshotDialog from './hooks/useUpdateRoadmapSnapDialog';

const UpdateRoadmapSnapDialog = ({ onDeleteCallback }) => {
  const {
    snapId,
    isOpen,
    onClose,
    snapshotDescription,
    snapshotName,
    snapshotColor,
    setSnapshotDescription,
    setSnapshotColor,
    setSnapshotName,
    onSaveSnap,
    onDeleteSnap,
    userCanDelete,
  } = useUpdateRoadmapSnapshotDialog({ onDeleteCallback });

  return (
    <Dialog
      key={snapId}
      id="update-snapshot-dialog"
      data-test="update-snapshot-dialog"
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      scroll="paper"
      closeButton
      contentCustomStyles={dialogContentStyles}
      actions={
        <Fragment>
          {snapId && userCanDelete && (
            <DeleteButton data-test="delete-snap" onClick={onDeleteSnap} color="secondary">
              Delete
            </DeleteButton>
          )}
          <Button
            data-test="save-snap"
            onClick={() => {
              onSaveSnap();
              onClose();
            }}
            color="primary"
          >
            Save
          </Button>
          <Button
            data-test="cancel-save-snap"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </Fragment>
      }
    >
      <DialogWrapper>
        <Grid container>
          <Grid item xs={12}>
            <Flex>
              <TextDeprecated>Update snap</TextDeprecated>
              <ColorInputWrapper>
                <TextDeprecated>Color</TextDeprecated>
                <SelectColor color={snapshotColor} onChange={color => setSnapshotColor(color)} />
              </ColorInputWrapper>
            </Flex>
            <TextInput
              data-test="name-input"
              placeholder="Enter the name for the roadmap snap"
              fullWidth
              margin="normal"
              value={snapshotName}
              onChange={({ target: { value } }) => setSnapshotName(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledWYSIWYGEditorField
              key="snapshot-dialog-description"
              value={snapshotDescription}
              content={snapshotDescription}
              placeholder="Add a brief description of this snap"
              onChange={setSnapshotDescription}
              width="600px"
              height="165px"
            />
          </Grid>
        </Grid>
      </DialogWrapper>
    </Dialog>
  );
};

const dialogContentStyles = css`
  && {
    padding-top: 22px;
  }
`;

const DialogWrapper = styled.div`
  width: 600px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: ${spacing(7)}px;
`;

const ColorInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2)}px;
`;

const TextInput = styled(TextField)`
  &&&& {
    margin-bottom: 0;
  }
`;

const StyledWYSIWYGEditorField = styled(WYSIWYGEditorField)`
  && {
    margin-top: ${spacing(2)}px;
  }
`;

const DeleteButton = styled(Button)`
  &&&& {
    position: absolute;
    left: 20px;
  }
`;

export default UpdateRoadmapSnapDialog;
