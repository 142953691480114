import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

export default styled(props => (<CircularProgress
  variant="indeterminate"
  disableShrink
  size={12}
  thickness={4}
  {...props}
/>))`
  position: absolute;
  right: 8px;
  top: calc(50% - 4px);
`;
