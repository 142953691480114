import { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { prop } from 'ramda';

import { setPageFilters } from 'store/filters';
import { GLOBAL_FILTER, METRICS_FILTER, OBJECTIVES_FILTER } from 'constants/filters';
import { getProjectLayers } from 'store/filters/selectors';

import { getRoadmaps, getCorpRoadmaps } from 'store/roadmaps/selectors';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { updateOutcomeModuleFilters } from 'store/goalMode/thunks';
import { filterActiveItems } from 'utils/index';
import { throwWithProductionFallback } from 'utils/errorHandling';

export const ROADMAP_LEVEL_CORP_META_TYPE = 'roadmapsCorp';
export const ROADMAP_LEVEL_1_META_TYPE = 'roadmaps';
export const ROADMAP_LEVEL_2_META_TYPE = 'products';
export const ROADMAP_LEVEL_3_META_TYPE = 'products2';
const UNDEFINED_VALUE = 'null';
const UNDEFINED_DEFAULT_LABEL = 'Undefined';

const ROADMAP_LEVELS = [
  ROADMAP_LEVEL_CORP_META_TYPE,
  ROADMAP_LEVEL_1_META_TYPE,
  ROADMAP_LEVEL_2_META_TYPE,
  ROADMAP_LEVEL_3_META_TYPE,
];

const ID = 'id';

const getId = prop(ID);

/**
 * @function filterChildrenByParentId
 *
 * Filter a given list of roadmaps by parentId
 *
 * @param {Array} children
 * @param {Number} parentId
 * @returns {Array}
 */
const filterChildrenByParentId = (roadmaps, parentId) => roadmaps.filter(roadmap => roadmap.parent_id === parentId);

/**
 * @function getUndefinedOption
 *
 * Gets the Undefined filter option
 *
 * @param {String} metaType
 * @returns {Object}
 */
const getUndefinedOption = (metaType, undefinedLabel = UNDEFINED_DEFAULT_LABEL) => {
  return {
    key: 'null',
    label: undefinedLabel,
    type: 'click',
    metaType,
    children: [],
  };
};

const getProductTree = metaType => product => {
  const { id: productId, title: productTitle, products = [] } = product;
  const nextLevel = ROADMAP_LEVELS[ROADMAP_LEVELS.findIndex(l => l === metaType) + 1];

  return {
    id: productId,
    key: productId,
    label: productTitle,
    type: 'click',
    metaType,
    ...(nextLevel ? { children: products.filter(filterActiveItems).map(getProductTree(nextLevel)) } : {}),
  };
};

/**
 * @function getRoadmapTree
 *
 * Gets the filter option for a provided roadmap.
 * Also creates filter options for the children products.
 *
 * @param {Object} roadmap
 * @returns {Object}
 */
const getRoadmapTree = roadmap => {
  const { id, title, products = [] } = roadmap;

  return {
    id,
    key: id,
    label: title,
    type: 'click',
    metaType: ROADMAP_LEVEL_1_META_TYPE,
    children: products.filter(filterActiveItems).map(getProductTree(ROADMAP_LEVEL_2_META_TYPE)),
  };
};

/**
 * @function getCorpRoadmapTree
 *
 * Gets the filter option for a provided corp level roadmap.
 * Also includes the children roadmaps.
 *
 * @param {Object} roadmap
 * @param {Array} children
 * @param {Object} currentOrgCorpRoadmap
 * @returns {Object}
 */
const getCorpRoadmapTree = (roadmap, children, currentOrgCorpRoadmap) => {
  const { id, title } = roadmap;
  const isCurrentCorpRoadmap = id === currentOrgCorpRoadmap?.id;

  return {
    id,
    key: id,
    label: title,
    type: 'click',
    metaType: ROADMAP_LEVEL_CORP_META_TYPE,
    children: children.filter(filterActiveItems).map(getRoadmapTree),
    ...(isCurrentCorpRoadmap ? { multiValues: [id, UNDEFINED_VALUE] } : {}),
  };
};

const applyProjectsQuickFilter = (dispatch, roadmapHierarchyItem, layer) => {
  const quickFilter = {
    layer,
    secondLayer: [],
    children: null,
    fields: {
      planningStages: ['Backlog', 'Planning', 'Confirmed'],
      [roadmapHierarchyItem.metaType]: roadmapHierarchyItem.filter
        ? roadmapHierarchyItem.filter
        : roadmapHierarchyItem.multiValues ?? [roadmapHierarchyItem.key || roadmapHierarchyItem.id],
    },
    quickFilters: {
      [roadmapHierarchyItem.metaType]: roadmapHierarchyItem.multiValues ?? [roadmapHierarchyItem.key || roadmapHierarchyItem.id],
    },
    op: {
      ...(roadmapHierarchyItem.op ? { [roadmapHierarchyItem.metaType]: roadmapHierarchyItem.op } : {}),
    },
  };

  dispatch(setPageFilters(GLOBAL_FILTER, quickFilter));
};

const applyOKRQuickFilter = (dispatch, roadmapHierarchyItem) => {
  const quickFilter = {
    fields: {
      status: ['Active'],
      [roadmapHierarchyItem.metaType]: roadmapHierarchyItem.filter
        ? roadmapHierarchyItem.filter
        : roadmapHierarchyItem.multiValues ?? [roadmapHierarchyItem.key || roadmapHierarchyItem.id],
    },
    quickFilters: {
      [roadmapHierarchyItem.metaType]: roadmapHierarchyItem.multiValues ?? [roadmapHierarchyItem.key || roadmapHierarchyItem.id],
    },
    op: {
      ...(roadmapHierarchyItem.op ? { [roadmapHierarchyItem.metaType]: roadmapHierarchyItem.op } : {}),
    },
  };

  dispatch(updateOutcomeModuleFilters(quickFilter));
};

const applyMetricsQuickFilter = (dispatch, roadmapHierarchyItem) => {
  const quickFilter = {
    fields: {
      status: ['Active'],
      [roadmapHierarchyItem.metaType]: roadmapHierarchyItem.filter
        ? roadmapHierarchyItem.filter
        : roadmapHierarchyItem.multiValues ?? [roadmapHierarchyItem.key || roadmapHierarchyItem.id],
    },
    quickFilters: {
      [roadmapHierarchyItem.metaType]: roadmapHierarchyItem.multiValues ?? [roadmapHierarchyItem.key || roadmapHierarchyItem.id],
    },
    op: {
      ...(roadmapHierarchyItem.op ? { [roadmapHierarchyItem.metaType]: roadmapHierarchyItem.op } : {}),
    },
  };

  dispatch(setPageFilters(METRICS_FILTER, quickFilter));
};

const makeSetRoadmapQuickFilter =
  ({ filterType, dispatch, bottomLayer }) =>
  roadmapHierarchyItem => {
    if (!roadmapHierarchyItem) {
      return;
    }

    const quickFilterApplyFuncs = {
      [GLOBAL_FILTER]: () => applyProjectsQuickFilter(dispatch, roadmapHierarchyItem, bottomLayer),
      [OBJECTIVES_FILTER]: () => applyOKRQuickFilter(dispatch, roadmapHierarchyItem),
      [METRICS_FILTER]: () => applyMetricsQuickFilter(dispatch, roadmapHierarchyItem),
    };

    if (!quickFilterApplyFuncs[filterType]) {
      throwWithProductionFallback(new Error(`Unsupported ${filterType} filter type on useRoadmapQuickFilter`));

      return;
    }

    const applyQuickFilterFn = quickFilterApplyFuncs[filterType];

    applyQuickFilterFn();
  };

const useRoadmapQuickFilter = ({ filterType, undefinedLabel, displayUndefinedLabel = true }) => {
  const { bottomLayer } = useSelector(state => getProjectLayers(state));
  const dispatch = useDispatch();

  const roadmapCorp = useSelector(state => getCorpRoadmaps(state, false));
  const roadmaps = useSelector(state => getRoadmaps(state, false));
  const { getCurrentOrgCorpRoadmap } = useOrganizationsAccessControl();
  const { canView } = usePermissions();
  const currentOrgCorpRoadmap = getCurrentOrgCorpRoadmap();

  const roadmapsFilterTree = useMemo(() => {
    if (canView(PERMISSION_RESOURCES.roadmapCorp)) {
      return [
        ...roadmapCorp
          .filter(roadmap => canView(PERMISSION_RESOURCES.roadmapCorp, { roadmap }))
          .map(roadmap => getCorpRoadmapTree(roadmap, filterChildrenByParentId(roadmaps, getId(roadmap)), currentOrgCorpRoadmap)),
      ];
    }

    return [
      ...(displayUndefinedLabel ? [getUndefinedOption(ROADMAP_LEVEL_1_META_TYPE, undefinedLabel)] : []),
      ...roadmaps.filter(roadmap => canView(PERMISSION_RESOURCES.roadmap, { roadmap })).map(getRoadmapTree),
    ];
  }, [roadmaps, roadmapCorp, undefinedLabel, canView]);

  const setRoadmapQuickFilter = makeSetRoadmapQuickFilter({
    dispatch,
    filterType,
    bottomLayer,
  });

  return { setRoadmapQuickFilter, roadmapsFilterTree };
};

export default useRoadmapQuickFilter;
