import React from 'react';
import { useSelector } from 'react-redux';

import { values, defaultTo } from 'ramda';

import { getPageFilters } from 'store/filters/selectors';
import { makeSelectorToMetadataByKey } from 'utils/metadata';

const KEYS = ['roadmaps', 'products'];

const defaultToEmptyArray = defaultTo([]);

const getSelectorsByKey = makeSelectorToMetadataByKey({ keysToPick: KEYS });

export default () => {
  const filters = useSelector(state => getPageFilters(state));
  const roadmapsAndProducts = useSelector(state => getSelectorsByKey(state));

  const filterFieldName = Object.keys(filters.quickFilters || [''])[0];
  const filterValue = filters.quickFilters && filters.quickFilters[filterFieldName];

  const autoFillData = React.useMemo(() => {
    const myAutoFillData = {};

    if (roadmapsAndProducts[filterFieldName] && filterValue.length) {
      const value = roadmapsAndProducts[filterFieldName].find(f => f.id === +filterValue[0]);

      if (value) {
        switch (filterFieldName) {
          case 'roadmaps':
            myAutoFillData.roadmapTitle = value.title;
            break;
          case 'products':
            const roadmap = roadmapsAndProducts.roadmaps.find(roadmap => {
              const subRoadmaps = defaultToEmptyArray(roadmap.products);

              return subRoadmaps.findIndex(p => p.title === value.title) > -1;
            });

            if (roadmap) {
              myAutoFillData.roadmapTitle = roadmap.title;
            }
            
            myAutoFillData.product1Title = value.title;
            break;
          default:
            break;
        }
      }
    }

    return myAutoFillData;
  }, [...values(roadmapsAndProducts), filterFieldName, filterValue]);

  return autoFillData;
};
