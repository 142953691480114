import reduceReducers from 'reduce-reducers';

import goalModeReducer, { initialState as goalModeInitialState } from './reducer';
import operationsReducers, { initialState as operationsInitialState } from './operationsReducer';

const initialState = {
  ...operationsInitialState,
  ...goalModeInitialState,
};

const reducer = reduceReducers(initialState, goalModeReducer, ...operationsReducers);

export { goalModeInitialState, initialState, operationsInitialState };

export default reducer;
