import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { ArrowDropDown } from '@material-ui/icons';

import TreeDropdown from 'design-system/molecules/TreeDropdown';
import { brandColor } from 'design-system/themes/default';
import { spacing } from 'design-system/theme';

const FilterDropdownWithDndList = ({
  items,
  selectedItems,
  onChange,
  label,
  textFieldValue,
  hasClearSelection = true,
  hasMultiSelection = true,
  hasShowArchived = false,
  withLabelAnimation = true,
  hasSearchBar = true,
  className,
}) => {
  const [inputRef, setInputRef] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const onOpenDropdownHandler = useCallback(() => setIsEditing(true), []);

  const onSelectedItemsChange = items => onChange(items);

  return (
    <FormControl className={className}>
      <TextFieldWrapper withLabelAnimation={withLabelAnimation} onClick={onOpenDropdownHandler}>
        <TextField
          inputRef={node => setInputRef(node)}
          label={label}
          value={textFieldValue}
          margin="none"
          InputProps={{
            readOnly: true,
            style: { color: brandColor },
          }}
        />
        <StyledArrowDropDown />
      </TextFieldWrapper>
      <TreeDropdown
        inputRef={inputRef}
        isOpen={isEditing}
        items={items}
        selectedItems={selectedItems}
        onChange={onSelectedItemsChange}
        onClose={() => setIsEditing(false)}
        hasClearSelection={hasClearSelection}
        hasMultiSelection={hasMultiSelection}
        hasShowArchived={hasShowArchived}
        hasSearchBar={hasSearchBar}
      />
    </FormControl>
  );
};

export default FilterDropdownWithDndList;

const TextFieldWrapper = styled.div`
  position: relative;
  cursor: pointer;

  > div > div {
    margin-top: ${({ theme, withLabelAnimation }) => (withLabelAnimation ? `${theme.spacing.unit * 2}px` : 0)};
  }

  label {
    font-size: ${({ theme }) => theme.typography.fontSize}px;

    ${({ withLabelAnimation }) =>
      !withLabelAnimation &&
      css`
        top: unset;
        bottom: 10px;
        transform: none;
      `}
  }

  input {
    cursor: pointer;

    padding-right: ${spacing(2.5)}px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledArrowDropDown = styled(ArrowDropDown)`
  position: absolute;
  bottom: 5px;
  right: 0;
  color: ${({ theme }) => theme.palette.background.dimGray};
`;
