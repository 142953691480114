import React, { useState, useMemo } from 'react';
import styled, { css } from 'styled-components';

import theme from 'design-system/theme';
import NewDialog from 'design-system/organisms/NewDialog/index';

import useOneClickDialog from 'containers/OneClickDialog/hooks/useOneClickDialog';
import FormScreen from './components/FormScreen';
import PreviewScreen from './components/PreviewScreen';
import LoadingScreen from './components/LoadingScreen';
import LoadingFinishedScreen from './components/LoadingFinishedScreen';

const OneClickDialog = () => {
  const {
    isOpen,
    recommendedPlanningItems,
    otherPlanningItems,
    selectedPlanOrderBy,
    selectPlanOrderBy,
    selectedOptions,
    isOneClickPlanLoading,
    didOneClickPlanModeRun,
    handleSelectOption,
    onClose,
    onConfirm,
  } = useOneClickDialog();

  const [isViewingPlan, setIsViewingPlan] = useState(false);

  const onCloseDialog = () => {
    setIsViewingPlan(false);
    onClose();
  };

  const selectedPlanningLabel = useMemo(() => {
    const selectedItem = [...recommendedPlanningItems, ...otherPlanningItems].find(item => item.key === selectedPlanOrderBy);

    if (!selectedItem?.options) return selectedItem?.label || '';

    const selectedOption = selectedItem.options.find(option => option.key === selectedOptions[selectedPlanOrderBy]);

    return selectedOption?.label || '';
  }, [selectedPlanOrderBy, selectedOptions, recommendedPlanningItems, otherPlanningItems]);

  const renderDialogContent = useMemo(() => {
    if (isOneClickPlanLoading) {
      return <LoadingScreen />;
    }

    if (didOneClickPlanModeRun) {
      return isViewingPlan ? (
        <PreviewScreen onClose={onCloseDialog} selectedPlanningLabel={selectedPlanningLabel} />
      ) : (
        <LoadingFinishedScreen onViewPlanClick={() => setIsViewingPlan(true)} />
      );
    }

    return (
      <FormScreen
        recommendedPlanningItems={recommendedPlanningItems}
        otherPlanningItems={otherPlanningItems}
        selectedPlanOrderBy={selectedPlanOrderBy}
        selectPlanOrderBy={selectPlanOrderBy}
        selectedOptions={selectedOptions}
        handleSelectOption={handleSelectOption}
        generatePlan={onConfirm}
      />
    );
  }, [
    isOneClickPlanLoading,
    didOneClickPlanModeRun,
    recommendedPlanningItems,
    otherPlanningItems,
    selectedPlanOrderBy,
    selectPlanOrderBy,
    selectedOptions,
    handleSelectOption,
    onConfirm,
  ]);

  const isDisplayingPreviewPage = useMemo(() => didOneClickPlanModeRun && isViewingPlan, [didOneClickPlanModeRun, isViewingPlan]);

  return (
    <StyledDialog
      open={isOpen}
      fullWidth
      fullHeight
      width="100%"
      closeButton={!isDisplayingPreviewPage}
      paddingOverride="0px"
      onClose={onCloseDialog}
      BackdropProps={{
        style: { background: theme.palette.newLayout.background.oneClickPlanMode.gradient },
      }}
      disableBackdropClick
      increaseTopMargin={isDisplayingPreviewPage}
    >
      {renderDialogContent}
    </StyledDialog>
  );
};

const StyledDialog = styled(NewDialog)`
  &&&& {
    div[role='document'] > div {
      height: 100%;

      //When on the Preview screen, we need a bigger top margin to display some content
      ${({ increaseTopMargin, theme }) =>
        increaseTopMargin &&
        css`
          margin-top: ${theme.spacing.unit * 10}px;
          min-height: calc(100% - 128px);
          max-height: calc(100% - 128px);
        `}
    }
  }
`;

export default OneClickDialog;
