import { combineReducers } from 'redux';

import { combineThunksReducers } from 'utils/store/thunk';

import {
  GET_PROJECT_FIELDS_CONFIGURATION,
  UPDATE_PROJECT_FIELDS_CONFIGURATION,
  GET_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION,
  UPDATE_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION,
} from '../types';

import projectFieldsLayout from './projectFieldsLayoutReducer';
import customerRequestStatusMapping from './customerRequestStatusMappingReducer';
import projectFieldsInherit from './projectFieldsInheritReducer';
import projectFieldsRequired from './projectFieldsRequiredReducer';

const reducer = combineReducers({
  projectFieldsLayout,
  projectFieldsInherit,
  projectFieldsRequired,
  customerRequestStatusMapping,
  operations: combineThunksReducers([
    GET_PROJECT_FIELDS_CONFIGURATION,
    UPDATE_PROJECT_FIELDS_CONFIGURATION,
    GET_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION,
    UPDATE_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION,
  ]),
});

export default reducer;
