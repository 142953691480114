import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { defaultTo, isEmpty, isNil, not, path, pipe, reject } from 'ramda';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import { hasEnterpriseEnabled as hasEnterpriseEnabledSelector } from 'store/organization';

import WebhookSwitcher from 'routes/Settings/Integration/components/WebhookSwitcher';
import WebhooksProjectRestrictions from 'routes/Settings/Integration/components/WebhooksProjectRestrictions';

const defaultToEmptyObject = defaultTo({});
const isNotEmpty = pipe(reject(isNil), isEmpty, not);

const getIntegrationData = pipe(defaultToEmptyObject, path(['data']), defaultToEmptyObject);

const JiraWebhooks = props => {
  const { orgIntegration, registerWebhook, unregisterWebhook, userCanUpdateOrgIntegration } = props;
  const integrationData = getIntegrationData(orgIntegration);

  const hasEnterpriseEnabled = useSelector(hasEnterpriseEnabledSelector);

  const webhooks = useMemo(() => {
    return [integrationData.webhook].concat(integrationData.webhooks);
  }, [integrationData.webhook, integrationData.webhooks]);

  return (
    <Grid container spacing={16}>
      <Grid item xs={hasEnterpriseEnabled ? 6 : 12}>
        <FormControlLabelContainer
          control={
            <WebhookSwitcher
              register={registerWebhook}
              unregister={unregisterWebhook}
              webhooks={webhooks}
              orgIntegrationId={orgIntegration.id}
            />
          }
          disabled={!userCanUpdateOrgIntegration}
          label={integrationData.webhook ? 'Disable Webhook' : 'Enable Webhook'}
        />
        <WarningText breakwords>Jira system admin permission is required to turn on / off webhook</WarningText>
      </Grid>
      {hasEnterpriseEnabled && (
        <Grid item xs={6}>
          <WebhooksProjectRestrictions
            integrationType={orgIntegration?.integrationType}
            orgIntegrationId={orgIntegration?.id}
            webhooksEnabled={isNotEmpty(webhooks)}
            userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
          />
        </Grid>
      )}
    </Grid>
  );
};

const FormControlLabelContainer = styled(FormControlLabel)`
  margin-top: -10px;
  padding-left: 5px;
`;

const WarningText = styled(TextDeprecated)`
  &&&& {
    margin-top: 3px;
    font-size: ${props => props.theme.typography.fontSizeSmall}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    ${props => `color: ${props.theme.palette.text.error}`};
  }
`;

export default JiraWebhooks;
