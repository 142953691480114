import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { ACTUAL, TARGET } from 'features/MetricsDialog/constants';
import ImportMetricValues from 'features/Integrations/ImportMetricValues';
import MetricDataSourcePopoverButton from 'features/MetricsDialog/components/MetricDataSourcePopoverButton';

import { spacing } from 'design-system/theme';

const ActionsToolbar = ({ metric, addMetricValueWithoutSave, handleTabChange, selectedTab, allowCreate, allowUpdate }) => {
  return (
    <Wrapper>
      {allowCreate && (
        <ButtonWrapper>
          <AddNewButton id="metrics-add-new-button" color="primary" onClick={() => addMetricValueWithoutSave()}>
            + New
          </AddNewButton>
        </ButtonWrapper>
      )}
      <TabsWrapper>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="primary"
          TabIndicatorProps={{ style: { background: 'transparent' } }}
        >
          <StyledTab label={ACTUAL.type} value={ACTUAL.type} />
          <StyledTab label={TARGET.type} value={TARGET.type} />
        </Tabs>
      </TabsWrapper>
      {/* TODO - Tableau - add FF/permission validation */}
      <ButtonWrapper>
        <MetricDataSourcePopoverButton metric={metric} />
        <ImportMetricValues metricId={metric?.id} metricIntegrations={metric?.metricIntegrations} />
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ActionsToolbar;

const Wrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TabsWrapper = styled.div`
  flex: 1;
`;

const AddNewButton = styled(Button)``;

const ButtonWrapper = styled.div`
  padding: ${spacing(1.5)}px ${spacing()}px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${spacing(2)}px;
`;

const StyledTab = styled(Tab)`
  && {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    text-transform: inherit;
    min-width: 80px;
  }
`;
