import React from 'react';
import styled from 'styled-components';

import createdByText from 'utils/userViews/createdByText';

import ShareUrlText from 'containers/ViewDialog/ShareUrlText';
import PublicLinks from 'containers/ViewDialog/SharePublicLink/PublicLinks';

import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';
import { useSelector } from 'react-redux';
import { getUserById } from 'store/users/selectors';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';

const SHARE = 'Share';

const SharePublicLink = ({ shareUrl, view, onClose }) => {
  const viewOwner = useSelector(state => getUserById(state, view?.user_id));

  const permissions = usePermissions();
  const arePublicLinksEnabled = permissions.canView(PERMISSION_FEATURES.headlessShare);

  const showPublicLinks = view && arePublicLinksEnabled;

  return (
    <DialogWrapper>
      <Container>
        <Text>{`${SHARE} `}</Text>
        <ViewText maxWidth={300} withEllipsis>
          {view?.name}
        </ViewText>
        <StyledCreator>&nbsp;{createdByText(viewOwner?.name)}</StyledCreator>
      </Container>
      <FlexContainer>
        {!arePublicLinksEnabled && <ShareUrlText shareUrl={shareUrl} />}

        {showPublicLinks && <PublicLinks userView={view} closeDialog={onClose} />}
      </FlexContainer>
    </DialogWrapper>
  );
};

const DialogWrapper = styled.div``;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: ${spacing()}px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ViewText = styled(Text)`
  padding-left: ${spacing(0.5)}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

const StyledCreator = styled(Text)`
  color: ${({ theme }) => theme.palette.newLayout.text.grey};
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
  line-height: ${({ theme }) => theme.typography.lineHeightSmallLargeRem}rem;
`;

export default SharePublicLink;
