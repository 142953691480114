const searchTaskRecursively = (currentObj, currentIndex, currentPath, taskId) => {
  let path = [...currentPath, currentIndex];

  if (currentObj.id === taskId && !currentObj.tasks) return path;

  let scope = 'tasks';

  if (!currentObj.tasks) scope = 'subtasks';

  if (!currentObj[scope] || currentObj[scope].length === 0 || !Array.isArray(currentObj[scope])) return null;

  path = [...path, scope];

  /* eslint-disable */
  for (const [index, newObj] of currentObj[scope].entries()) {
    const res = searchTaskRecursively(newObj, index, path, taskId);

    if (res) return res;
  }
  /* eslint-enable */

  return null;
};

export default searchTaskRecursively;
