import moment from 'moment-timezone';

import { computeEndDate } from 'utils';
import formatDate from 'utils/dates/formatDate';

/**
 * @param {Object} entity
 * @param {Object} lsState
 */
export default entity => {
  // the allocation report calculation coalesces both of these fields -- originally only using entity.start_date
  let parsedStartDate = entity.estimated_start_date || entity.start_date;
  const parsedEndDate = entity.deadline || entity.end_date;

  if (!moment(parsedStartDate).isValid()) parsedStartDate = formatDate(parsedStartDate, 'DD-MM-YYYY');

  const entityStartDate = moment(parsedStartDate).startOf('day');
  // project duration isn't always valid, defaults to 0
  const entityEndDate = (entity.duration ? computeEndDate(parsedStartDate, entity.duration) : moment(parsedEndDate))
    .startOf('day')
    .add(1, 'day');
  const entityDuration = entityEndDate.diffDuration(entityStartDate, 'days', true);

  if (!entityStartDate || !entityEndDate) return 0;

  const duration = entityDuration || 0;

  const numStaff = entity.numStaff || 1;
  const ownerAllocation = (entity.ownerAllocation || 100) / 100;
  const result = duration * numStaff * ownerAllocation;

  return result;
};
