import { allPass, isEmpty, not } from 'ramda';
import isFunction from 'lodash/isFunction';

import { INCLUDE_ALL_OPTION, EXCLUDE_UNCOMMITTED_OPTION } from 'constants/projects';

/**
 * @function filterProjectsMilestones
 *
 * Helper function to filter the list of projects that is not a milestone
 *
 * @param  {Array} projects          the list of projects to filter
 * @return {Array}
 */
const filterProjectsMilestones = projects => {
  return projects.filter(sp => sp.type !== 'milestone');
};

/**
 * @function getPlanningStagesForProjectsFilter
 *
 * Uses 'showCompleted' boolean to create planningStages filter for projects search
 *
 * @param {Boolean} showCompleted
 * @return {Array}
 */
const getPlanningStagesForProjectsFilter = showCompleted => {
  return ['Backlog', 'Confirmed', 'Planning', ...(showCompleted ? ['Completed'] : [])];
};

/**
 * @function getFiltersForProjectsSearch
 *
 * Gets the filters for projects search with static default values
 *
 * @param  {Array} layers             the layers to search for
 * @param  {Number} limit             limit the number of results to return
 * @param  {String} order             order the order of results
 * @param  {String} search            string search string
 * @param  {Boolean} showCompleted    whether to show the completed projects
 * @return {Object}
 */
const getFiltersForProjectsSearch = ({ layers, limit, order, search, offset = 0, showCompleted = false }) => {
  const planningStages = getPlanningStagesForProjectsFilter(showCompleted);

  return {
    fields: {
      layer: layers,
      planningStages,
      ...(search ? { search } : {}),
    },
    from: offset,
    to: limit + offset,
    layer: layers,
    order,
  };
};

/**
 * @function getFiltersForProjectsSearchByTitle
 *
 * Gets the filters for projects search by title
 *
 * @param  {String} searchText
 * @param  {Array} layersToSearch
 * @param  {Boolean} showCompleted
 * @param  {Number} limit
 * @param  {Number} offset
 * @return {Object}
 */
const getFiltersForProjectsSearchByTitle = ({ searchText, layersToSearch, showCompleted = false, limit = 20, offset = 0 }) => {
  const planningStages = getPlanningStagesForProjectsFilter(showCompleted);

  return {
    fields: {
      layersToSearch,
      planningStages,
      searchText,
    },
    limit,
    offset,
  };
};

const shouldIncludeUncommitted = showUncommittedProjectsOption =>
  not(showUncommittedProjectsOption === EXCLUDE_UNCOMMITTED_OPTION);

const makeFilterProjectsByCommitted =
  (showUncommittedProjectsOption = INCLUDE_ALL_OPTION) =>
  (projects = []) => {
    // Consider projects that are being created, committed or all if include uncommitted is truthy
    return projects.filter(
      project => !project?.id || shouldIncludeUncommitted(showUncommittedProjectsOption) || project?.committed,
    );
  };

/**
 * Makes a filtering function that checks a list of filtering conditions.
 * @param<List> filterFuncs
 * @return {(function(*): *)|*|(function(*): (*))}
 */
const makeProjectFrontendFilters = (...filterFuncs) => {
  const validFilters = filterFuncs.filter(isFunction);

  if (isEmpty(validFilters)) return item => item;

  return allPass(validFilters);
};

const makeFilterProjectByOwner = userId => project => project?.owner_id === userId;

export {
  filterProjectsMilestones,
  getFiltersForProjectsSearch,
  getFiltersForProjectsSearchByTitle,
  makeFilterProjectsByCommitted,
  shouldIncludeUncommitted,
  makeProjectFrontendFilters,
  makeFilterProjectByOwner,
};
