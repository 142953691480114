const onlyGeneralTabOnHeaderMenu = {
  menuTabs: ['generalMenuTab'],
};

const defaultWidthProps = {
  width: 200,
  minWidth: 100,
  cellDataType: false,
};

const defaultColumnDefCommonProps = {
  ...onlyGeneralTabOnHeaderMenu,
  ...defaultWidthProps,
};

export { onlyGeneralTabOnHeaderMenu, defaultColumnDefCommonProps, defaultWidthProps };
