import React, { useMemo, useCallback } from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';

import Loading from 'design-system/atoms/Loading/Loading';

import ListComponent from './ListComponent';
import theme from 'design-system/theme';

const GAP = 2;

const ROW_HEIGHT = 40; // Best value, in terms of display. Otherwise, will have issues.

const N_OF_ROWS_TO_DISPLAY = 5;

const HEIGHT_BOTTOM_OFFSET = 2;

const BORDER_OFFSET = 2;

const INDENTATION = 16;

const getRowHeight = rowHeight => rowHeight || ROW_HEIGHT;
const getAllRowsHeight = rowHeight => getRowHeight(rowHeight) * N_OF_ROWS_TO_DISPLAY;
const getHeight = rowHeight => getAllRowsHeight(rowHeight) + HEIGHT_BOTTOM_OFFSET;

const Container = styled.div`
  position: fixed;
  height: ${({ containerHeight, $height }) => $height || containerHeight}px;
  z-index: ${theme.zIndex.gridPopover};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  top: ${({ $top }) => $top != null && `${$top}px`};
  left: ${({ $left }) => $left != null && `${$left}px`};
  width: ${({ $width }) => $width != null && `${$width}px`};
  background-color: white;

  .ag-layout-normal.ag-root {
    margin: 0;
  }

  .ag-header {
    display: none;
  }

  .ag-row.ag-row {
    border: none !important;

    &.metadata-parent-row p,
    &.metadata-parent-row div {
      font-weight: normal;
    }
  }

  .ag-row-hover.ag-row-hover {
    background-color: ${({ theme }) => theme.palette.newLayout.background.lighterBlack} !important;
  }

  .ag-theme-material .ag-cell {
    cursor: pointer;
    padding: 5px 15px;

    :hover {
      background-color: ${({ theme }) => theme.palette.newLayout.background.lighterBlack};
    }
  }

  .ag-row-group-indent-1 {
    padding-left: ${INDENTATION}px !important;
  }

  .ag-row-group-indent-2 {
    padding-left: ${INDENTATION * 2}px !important;
  }

  .ag-row-group-indent-3 {
    padding-left: ${INDENTATION * 3}px !important;
  }

  .ag-grid-legacy .ag-layout-normal.ag-root {
    border: none !important;
  }

  .ag-body-container .ag-row .ag-react-container {
    display: flex;
    align-items: center;
  }
`;

const StyledLoading = styled(Loading)`
  &&&& {
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 12px);
    z-index: 1;
  }
`;

const ShowCompletedContainer = styled.div`
  height: ${({ rowHeight }) => rowHeight}px;

  display: flex;
  align-items: center;

  padding-left: ${INDENTATION}px;

  font-size: ${({ theme }) => theme.typography?.fontSize}px;
  color: ${({ theme }) => theme.palette?.text.lightGrey};

  > label {
    flex: 1;
  }
`;

const FooterContainer = styled.div`
  bottom: 0;
  position: absolute;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.palette.background.gallery};
`;

const GlobalComponent = ({
  inputRef,
  dropDownRef,
  data,
  displayLayer,
  isLoading,
  treeIsOpen,
  shouldShowCompleted,
  setShouldShowCompleted,
  onProjectCreation,
  handleProjectClick,
  nullOption,
  closeTree,
  footerComponent,
  showShouldShowCompletedSwitcher = true,
  treeComponentProps = {},
  getContainerPosition,
  projectRowHeight,
}) => {
  const footerComponentHeight = footerComponent ? ROW_HEIGHT : 0;

  const getContainerHeight = useCallback(
    rowHeight => getHeight(rowHeight) + getRowHeight(rowHeight) + footerComponentHeight,
    [footerComponentHeight],
  );

  const getContainerStylesProps = useCallback(() => {
    const inputBoundingClientRect = inputRef.current?.getBoundingClientRect();

    try {
      const defaultContainerTopPosition = inputBoundingClientRect.bottom + GAP;

      const containerWouldBeOutOfBounds = defaultContainerTopPosition + getContainerHeight(projectRowHeight) > window.innerHeight;

      const defaultPostion = {
        $top: containerWouldBeOutOfBounds
          ? inputBoundingClientRect.top - GAP - getContainerHeight(projectRowHeight)
          : defaultContainerTopPosition,
        $left: inputBoundingClientRect.left,
        $width: inputBoundingClientRect.width,
      };

      if (getContainerPosition) {
        return getContainerPosition(defaultPostion, inputBoundingClientRect);
      }

      return defaultPostion;
    } catch {
      return {};
    }
  }, [inputRef.current, getContainerPosition, getContainerHeight]);

  const shouldDisplay = useMemo(() => isLoading || treeIsOpen, [isLoading, treeIsOpen]);

  const inputValue = useMemo(() => inputRef.current?.value, [inputRef.current?.value]);

  const handleShowCompletedButtonChange = useCallback(
    e => {
      const { checked } = e.target;

      setShouldShowCompleted(checked);
    },
    [setShouldShowCompleted],
  );

  const _renderCustomFooter = () => {
    if (!footerComponent) return '';

    const FooterComponent = footerComponent;

    return <FooterComponent closeTree={closeTree} inputValue={inputValue} />;
  };

  const _renderTableComponent = () => (
    <ListComponent
      data={data}
      displayLayer={displayLayer}
      width={getContainerStylesProps().$width - BORDER_OFFSET}
      height={getHeight(projectRowHeight)}
      rowHeight={getRowHeight(projectRowHeight)}
      indentation={INDENTATION}
      inputValue={inputValue}
      onProjectCreation={onProjectCreation}
      handleProjectClick={handleProjectClick}
      nullOption={nullOption}
      {...treeComponentProps}
    />
  );

  const _renderFooterComponent = () => (
    <FooterContainer>
      {_renderCustomFooter()}
      {showShouldShowCompletedSwitcher && (
        <ShowCompletedContainer rowHeight={getRowHeight(projectRowHeight)}>
          <FormControlLabel
            control={<Switch color="primary" checked={shouldShowCompleted} onChange={handleShowCompletedButtonChange} />}
            onClick={e => e.stopPropagation()}
            label="Show completed"
          />
        </ShowCompletedContainer>
      )}
    </FooterContainer>
  );

  return (
    shouldDisplay && (
      <Container {...getContainerStylesProps()} ref={dropDownRef} containerHeight={getContainerHeight(projectRowHeight)}>
        {isLoading && <StyledLoading />}
        {treeIsOpen && (
          <>
            {!isLoading && _renderTableComponent()}
            {_renderFooterComponent()}
          </>
        )}
      </Container>
    )
  );
};

export default GlobalComponent;
