import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import reduceReducers from 'reduce-reducers';
import { isNil } from 'ramda';
import moment from 'moment';
import { REHYDRATE } from 'redux-persist';

import {
  SET_DISCOVERY_BOT_HISTORY_CACHE_EXPIRY,
  CREATE_DISCOVERY_BOT_THREAD,
  CREATE_DISCOVERY_BOT_THREAD_FULFILLED,
  PROCESS_USER_CHAT_MESSAGE,
  PROCESS_USER_CHAT_MESSAGE_FULFILLED,
} from './types';
import { DISCOVERY_BOT_HISTORY_CACHE_STORAGE_KEY } from './constants';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  CREATE_DISCOVERY_BOT_THREAD,
  PROCESS_USER_CHAT_MESSAGE,
]);

const initialState = {
  threadId: null,
  threadMessages: [],
  latestBotResponse: null,
  cacheDiscoveryBotChatHistory: {
    expiresIn: null,
  },
  ...thunksInitialState,
};

const discoveryBotReducer = (state = initialState, { type, payload, meta, key }) => {
  switch (type) {
    case SET_DISCOVERY_BOT_HISTORY_CACHE_EXPIRY:
      return {
        ...state,
        cacheDiscoveryBotChatHistory: { ...payload },
      };
    case CREATE_DISCOVERY_BOT_THREAD_FULFILLED:
      return {
        ...state,
        threadId: payload.id,
      };
    case PROCESS_USER_CHAT_MESSAGE_FULFILLED:
      return {
        ...state,
        threadMessages: [...state.threadMessages, payload],
        latestBotResponse: payload,
      };
    case REHYDRATE:
      if (key !== 'root') {
        return state;
      }

      const currentState = payload?.discoveryBot || {};

      const { cacheDiscoveryBotChatHistory = {} } = currentState;

      const discoveryBotHistoryExpiresIn = cacheDiscoveryBotChatHistory?.expiresIn;

      if (isNil(discoveryBotHistoryExpiresIn || moment(discoveryBotHistoryExpiresIn).isBefore(moment()))) {
        localStorage.removeItem(DISCOVERY_BOT_HISTORY_CACHE_STORAGE_KEY);
      }

      return {
        ...initialState,
        ...currentState,
      };
    default:
      return state;
  }
};

export { initialState };

const reducer = reduceReducers(initialState, discoveryBotReducer, ...thunksReducers);

export default reducer;
