import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { getThemes } from 'store/themes/selectors';
import {
  createTheme,
  updateThemeById,
  mergeThemes,
  fetchThemes,
  removeUnsavedThemes,
  addThemeWithoutSave,
  createThemes,
  updateThemes,
  bulkDeleteThemes,
  switchThemesRowOrder,
  deleteTheme,
  bulkDeleteThemeRoadmaps,
  createThemeRoadmap,
  deleteThemeRoadmap,
} from 'store/themes';

const useThemes = hideArchivedItems => {
  const dispatch = useDispatch();

  const themes = useSelector(state => getThemes(state, !hideArchivedItems));

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          createTheme,
          updateThemeById,
          mergeThemes,
          fetchThemes,
          removeUnsavedThemes,
          addThemeWithoutSave,
          createThemes,
          updateThemes,
          bulkDeleteThemes,
          switchThemesRowOrder,
          deleteTheme,
        },
        dispatch,
      ),
    [dispatch],
  );

  return {
    themes,
    bulkDeleteThemeRoadmaps,
    createThemeRoadmap,
    deleteThemeRoadmap,
    ...boundActionCreators,
  };
};

export default useThemes;
