import React, { useCallback } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

import LaunchIconButton from 'design-system/molecules/LaunchIconButton/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import TextWrapper from 'design-system/atoms/TextWrapper/index';


const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledText = styled(TextDeprecated)`
  && {
    display: flex;
    align-items: center;
  }
`;

const ButtonWrapper = styled.div`
  visibility: hidden;

  &:hover {
    visibility: visible;
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    padding: 6px;
  }

  svg {
    font-size: ${({ theme }) => theme.typography.fontSize}px;
  }
`;

const defaultGetNumberOfTextRowsToDisplayOnGridCell = () => 1;

const AutocompleteCellRenderer = params => {
  const { getNumberOfTextRowsToDisplayOnGridCell = defaultGetNumberOfTextRowsToDisplayOnGridCell } = params;

  const cellText = (
    <StyledText
      textColor="#303030"
      size="small"
      align={params.colDef.align}
      breakwords={params.colDef.breakwords || params.colDef.autoHeight}
      component="div"
    >
      <TextWrapper $nOfTextRowsToDisplay={getNumberOfTextRowsToDisplayOnGridCell()}>
        {params.colDef.formatter ? params.colDef.formatter(params.data) : params.value}
      </TextWrapper>
    </StyledText>
  );

  const handleOnClickLink = useCallback(() => {
    return params.onClickLink && params.onClickLink(params.data);
  }, [params.onClickLink, params.data]);

  if (params.colDef.cellWrapper) {
    return params.colDef.cellWrapper(params.data, cellText);
  }

  if (params.expandRenderer && params.value) {
    return (
      <CellContainer>
        {cellText}
        <ButtonWrapper>
          {params.openLinkIcon ? (
            <StyledIconButton onClick={handleOnClickLink}>{params.openLinkIcon}</StyledIconButton>
          ) : (
            <LaunchIconButton handleClick={handleOnClickLink} iconFontSize={14} />
          )}
        </ButtonWrapper>
      </CellContainer>
    );
  }

  return cellText;
};

export default AutocompleteCellRenderer;
