import { createSelector } from 'reselect';
import { pipe, defaultTo, isEmpty, not } from 'ramda';
import Permissions from '@dragonboat/permissions';

import { getOrganization } from 'store/organization';
import { getIsDodActive, getIsParentDragon, getOrganizationAccessControlData } from 'store/accessControl/selectors';
import { getCurrentUser } from 'store/login/selectors';

import { Configuration } from 'utils/configuration';

import generateFeaturesPermissions from './generateFeaturePermissions';
import generatePagePermissions from './generatePagePermissions';

const defaultToEmptyArray = defaultTo([]);
const isNotEmpty = pipe(defaultToEmptyArray, isEmpty, not);

export const getPermissionsSelector = createSelector(
  [getOrganization, getCurrentUser, getIsDodActive, getIsParentDragon, getOrganizationAccessControlData],
  (organization, currentUser, isDodActive, isParentDragon, organizationAccessControlData = []) => {
    const isChildDragon = !isParentDragon && isNotEmpty(organizationAccessControlData);

    const additionalPermissionsFactory = parameters => ({
      ...generateFeaturesPermissions(parameters),
      ...generatePagePermissions(parameters),
    });

    const permissions = new Permissions(
      isParentDragon,
      isDodActive,
      isChildDragon,
      organization,
      currentUser,
      additionalPermissionsFactory,
      Configuration.configurator,
    );

    return permissions;
  },
);
