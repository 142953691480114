import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { without, uniq } from 'ramda';

import useOrganizations from 'hooks/useOrganizations';
import useSystemFields from 'containers/IdeasList/IdeasList/New/hooks/useSystemFields';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

import useProjectsGridProps from 'containers/Grids/ProjectsGrid/hooks/useProjectsGridProps';
import useProjectsGridColumns from 'containers/Grids/ProjectsGrid/hooks/useProjectsGridColumns';
import { StyledObjectiveType as GridObjectiveType } from 'containers/Grids/OkrsGrid/components/styled';

import { getGroupOptions } from 'store/projects/helpers/groupOptions';

import { getDropdownCustomFields } from 'store/customFields/selectors';
import { openProjectLightbox as openProjectLightboxAction } from 'store/projectLightbox/actions';
import { getOrgCustomUserProjectFields, getOrgHasCustomUserProjectFields } from 'store/organization';

import {
  getTitleColumnDef,
  parentColumnDef,
  completedDateColumnDef,
  targetEndDateColumnDef,
  getShowFieldsPopupColumns,
  baseFieldKeys,
} from './columns';

const extraColumnDefs = [completedDateColumnDef];

const useProjectsListTable = (selectedGroupOption, columnState, setColumnState, visibleFields = [], setVisibleFields) => {
  const dispatch = useDispatch();

  const [tableShowFieldsEl, setTableShowFieldsEl] = useState(null);
  const [grid, setGrid] = useState(null);

  const dropdownCustomFields = useSelector(getDropdownCustomFields);

  const { getSystemFieldName } = useSystemFields();

  const { isDodActive } = useOrganizationsAccessControl();

  const {
    getDefaultRoadmapTitleForMetadataItem,
    hasKeyResults,
    hasKeyResults2,
    hasMultiLevelMetadata,
    hasProducts,
    systemFields,
  } = useOrganizations();

  const allFieldsColumns = useMemo(() => {
    const columnApi = grid?.columnApi;
    const allColumns = columnApi?.getColumns() ?? [];

    return allColumns.map(({ colDef }) => ({ key: colDef.field, label: colDef.headerName }));
  }, [grid]);

  const baseFields = useMemo(() => allFieldsColumns?.filter(({ key }) => baseFieldKeys.includes(key)), [allFieldsColumns]);
  const moreFields = useMemo(() => allFieldsColumns?.filter(({ key }) => !baseFieldKeys.includes(key)), [allFieldsColumns]);

  const { leftColumns, rightColumns } = useMemo(() => getShowFieldsPopupColumns(getSystemFieldName), [getSystemFieldName]);

  const tableVisibleFields = useMemo(
    () => visibleFields || [...leftColumns, ...rightColumns],
    [visibleFields, leftColumns, rightColumns],
  );

  const hasSelectedGroupBy = useMemo(() => !!selectedGroupOption.key, [selectedGroupOption]);

  const renderOkrIcon = useCallback(params => {
    const { okrType } = params.data;

    if (!okrType) {
      return;
    }

    return <GridObjectiveType type={okrType} active />;
  }, []);

  const renderTitleHeader = useCallback(() => 'Title', []);

  const openProjectLightbox = useCallback(data => {
    data?.id && dispatch(openProjectLightboxAction(data?.id));
  }, []);

  const visibleColumns = useMemo(() => {
    return tableVisibleFields.reduce((acc, field) => {
      const foundFieldColumn = allFieldsColumns.find(({ label }) => label === field);

      if (foundFieldColumn) return [...acc, foundFieldColumn];

      return acc;
    }, []);
  }, [tableVisibleFields, allFieldsColumns]);

  const projectsGridProps = useProjectsGridProps(visibleColumns);

  // since title is a pinned column we need to handle its visibility manually
  const titleColumnDef = useMemo(() => {
    return getTitleColumnDef({ openProjectLightbox });
  }, [openProjectLightbox]);

  const columnsDefsOverride = useMemo(() => {
    return {
      parent: parentColumnDef,
      deadline: targetEndDateColumnDef,
      title: titleColumnDef,
    };
  }, [titleColumnDef]);

  const {
    columnDefs: gridColumnsDefs,
    defaultColDef,
    autoGroupColumnDef,
  } = useProjectsGridColumns({
    systemFields,
    hasKeyResults2,
    customFields: [],
    integrations: [],
    visibleColumns,
    renderHierarchicalTitleIcon: renderOkrIcon,
    renderHierarchicalTitleLabel: renderTitleHeader,
    showChildrenCount: false,
    columnsDefsOverride,
    extraColumnDefs,
    hasGroupBy: !!selectedGroupOption.key,
    hasTotalEstimates: false,
  });

  const hasCustomUserFields = useSelector(getOrgHasCustomUserProjectFields);
  const customUserFields = useSelector(getOrgCustomUserProjectFields);

  const groupByOptions = useMemo(() => {
    return getGroupOptions({
      dropdownCustomFields,
      getSystemFieldName,
      hasKeyResults,
      hasKeyResults2,
      hasMultiLevelPortfolioMetadata: hasMultiLevelMetadata,
      hasProducts,
      isDodActive,
      withNullOption: true,
      customUserFields: hasCustomUserFields ? customUserFields : {},
    });
  }, [dropdownCustomFields, getSystemFieldName, hasKeyResults, hasKeyResults2, hasMultiLevelMetadata, hasProducts, isDodActive]);

  const handleChangeFields = fields => setVisibleFields(fields);
  const handleUncheckAllFields = () => setVisibleFields(without([...leftColumns, ...rightColumns], visibleFields ?? []));
  const handleCheckAllFields = () => setVisibleFields(uniq([...leftColumns, ...rightColumns, ...(visibleFields ?? [])]));

  const handleGridReady = useCallback(params => setGrid({ gridApi: params.api, columnApi: params.columnApi }), [setGrid]);

  const handleDisplayedColumnsChanged = useCallback(
    ({ columnApi }) => {
      if (!grid?.gridApi) {
        return;
      }
      const newColumnsState = columnApi.getColumnState();

      setColumnState(newColumnsState);
    },
    [grid, setColumnState],
  );

  return {
    tableShowFieldsEl,
    setTableShowFieldsEl,
    tableVisibleFields,
    leftColumnFields: leftColumns,
    rightColumnFields: rightColumns,
    handleChangeFields,
    handleUncheckAllFields,
    handleCheckAllFields,
    autoGroupColumn: autoGroupColumnDef,
    defaultColumnDef: defaultColDef,
    getDefaultRoadmapTitleForMetadataItem,
    columnsDefs: gridColumnsDefs,
    gridProps: projectsGridProps,
    groupByOptions,
    hasSelectedGroupBy,
    handleGridReady,
    handleDisplayedColumnsChanged,
    moreFields,
    baseFields,
  };
};

export default useProjectsListTable;
