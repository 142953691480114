import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SvgComponent(props) {
  // const height = Math.ceil((props.style?.height || 16) * 0.875);

  return (
    <SvgIcon width={24} height={24} viewBox="0 0 24 24" fill="black" enableBackground="new 0 0 24 24" {...props}>
      <rect fill="none" height="24" width="24" />
      {/* eslint-disable-next-line max-len */}
      <path d="M22,11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3H22z M7,9H4V5h3V9z M17,15h3v4h-3V15z M17,5h3v4h-3V5z" />
    </SvgIcon>
  );
}
