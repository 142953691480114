import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';

const FilterRadioOptions = ({ selectedItems, menu, onSelectItem, menuListProps: { defaultSelected } = {} }) => {
  return (
    <Wrapper>
      <RadioGroup value={selectedItems ?? defaultSelected} onChange={onSelectItem}>
        {menu.map(entry => (
          <SytledFormControlLabel
            value={entry.value}
            label={entry.label}
            control={<Radio color="primary" style={{ width: 32, height: 32 }} />}
          />
        ))}
      </RadioGroup>
    </Wrapper>
  );
};

const Wrapper = styled(Paper)`
  &&&& {
    width: 280px;
    padding: 10px 0 10px 13px;
    overflow-y: hidden;
  }
`;

const SytledFormControlLabel = styled(FormControlLabel)`
  &&&& {
    padding-left: ${({ theme }) => theme.spacing.unit * 1.25}px;
    margin-right: 0;

    & > svg {
      width: 0.8em;
      height: 0.8em;
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.newLayout.background.lighterBlack};
    }
  }
`;

export default FilterRadioOptions;
