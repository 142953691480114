import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import useMultiFilterData from '../HeaderFilters/hooks/useMultiFilterData';
import { saveCustomerRequestsMultiFilters, updateCustomerRequestsMultiFilters } from 'store/customerRequests/actions';
import { getMultiFilters, getNonDefaultMultiFiltersSaved } from 'store/customerRequests/selectors';

import { CUSTOM_FILTERS, DEFAULT_FILTER, CUSTOM_FIELDS } from 'constants/customerRequests';
import normalizeArray from 'utils/normalizeArray';
import { setCustomerRequestsMultiFilters } from 'store/customerRequests/thunks';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();

    const [multiFilterData] = useMultiFilterData(true);
    const multiFilters = useSelector(getMultiFilters);
    const normalizedMultiFilters = multiFilters && normalizeArray(multiFilters, 'key');

    const multiFiltersSaved = useSelector(getNonDefaultMultiFiltersSaved);
    const multiFilterList = [];

    // Initialize search popup filters with multifilters or default
    const pageFilters = useMemo(() => {
      if (Object.keys(normalizedMultiFilters).length > 0) {
        const newFilters = {};
        const filtersCopy = cloneDeep(normalizedMultiFilters);

        Object.keys(normalizedMultiFilters)?.forEach(itemKey => {
          // Joining values array for 'title', 'details', 'key' and 'custom_fields'
          if (CUSTOM_FILTERS.includes(itemKey) || CUSTOM_FILTERS.includes(CUSTOM_FIELDS)) {
            return (newFilters[itemKey] = filtersCopy[itemKey]?.values);
          }
          newFilters[itemKey] = [...filtersCopy[itemKey]?.values];
        });

        return newFilters;
      }

      return { [DEFAULT_FILTER]: [] };
    }, [normalizedMultiFilters]);

    const _handleApplyFilter = filters => {
      dispatch(setCustomerRequestsMultiFilters(filters));
    };

    // TODO This needs an improvement - check CustomerRequests/HeaderFilters/container.js multiFilterList
    useMemo(() => {
      Object.keys(multiFiltersSaved).forEach(key => {
        multiFilterList.push({
          id: key,
          label: multiFiltersSaved[key].name,
          state: multiFiltersSaved[key].state,
        });
      });
    }, [multiFiltersSaved]);

    const _handleSaveFilter = useCallback(
      (name, multiFilterItems) => {
        const id = Object.keys(multiFiltersSaved).find(id => multiFiltersSaved[id].name === name);

        if (id) {
          dispatch(updateCustomerRequestsMultiFilters(id, multiFilterItems));
        } else {
          dispatch(saveCustomerRequestsMultiFilters(name, multiFilterItems));
        }
      },
      [dispatch],
    );

    const _handleOnSavedFilterClick = f => {
      _handleApplyFilter(f.state);
    };

    return (
      <Component
        {...props}
        fields={multiFilterData}
        pageFilters={pageFilters}
        onApplyFilter={_handleApplyFilter}
        onSaveFilter={_handleSaveFilter}
        onSavedFilterClick={_handleOnSavedFilterClick}
        savedFilters={multiFilterList}
      />
    );
  };
};

export default componentHOC;
