import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentUser } from 'store/login/selectors';
import { updateUserById } from 'store/users';

export default Component => props => {
  const currentUser = useSelector(state => getCurrentUser(state));
  const dispatch = useDispatch();

  const onChange = data => dispatch(updateUserById(currentUser.id, data));

  return (
    <Component
      onChange={onChange}
      data={{ first_name: currentUser.first_name, last_name: currentUser.last_name, email: currentUser.email }}
    />
  );
};
