import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useOrganizationsAccessControl from 'src/hooks/useOrganizationsAccessControl';

import { getOrgHasMetadataRoadmaps as getOrgHasMetadataRoadmapsSelector } from 'store/organization/selectors';
import useFilterMetadataBasedOnRoadmap from 'hooks/metadata/useFilterMetadataBasedOnRoadmap';

const DEFAULT_ROADMAP_NAME = 'Portfolio';

/**
 * Builds the label of a given item based on title and linked roadmaps
 *
 * @param {Object} item
 * @param {Object} options
 * @param {String} options.roadmapsPropName
 * @returns {String}
 */
const buildItemLabel = (item, { roadmapsPropName }, defaultRoadmapName = DEFAULT_ROADMAP_NAME) => {
  const roadmaps = item[roadmapsPropName] || [];
  const roadmapsTitles = roadmaps.map(entry => entry?.roadmap?.title).filter(r => r);

  const roadmapsNames = roadmapsTitles.length > 0 ? roadmapsTitles.join(', ') : defaultRoadmapName;
  const label = `${item.title} (${roadmapsNames})`;

  return label;
};

/**
 * Gets the linked roadmaps property name for a given metadata entiry
 *
 * @param {String} roadmapsKey
 * @returns {String}
 */
const getItemRoadmapsPropertyName = roadmapsKey => `${roadmapsKey}_roadmaps`;

/**
 * Concats Title to a given field name
 *
 * @param {String} fieldName
 * @returns {String}
 */
const getLevelTitlePropertyName = fieldName => `${fieldName}Title`;

const useFluidMetadataTreeDropdown = ({
  value,
  options,
  selectedProject,
  roadmapsKey,
  levelZeroFieldName,
  levelOneFieldName,
  levelTwoFieldName,
}) => {
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmapsSelector);
  const hasLevelCorp = !!levelZeroFieldName;

  const [showAllOptions, setShowAllOptions] = useState(!hasMetadataRoadmaps);
  const { filterOptions } = useFilterMetadataBasedOnRoadmap(selectedProject);
  const { getDefaultRoadmapTitleForMetadataItem } = useOrganizationsAccessControl();

  const formatOptionsList = useCallback(
    optionsToFormat => {
      const roadmapsPropName = getItemRoadmapsPropertyName(roadmapsKey);

      return optionsToFormat.map(item => {
        const defaultRoadmapTitle = getDefaultRoadmapTitleForMetadataItem(item);

        return {
          ...item,
          label: buildItemLabel(item, { roadmapsPropName }, defaultRoadmapTitle),
        };
      });
    },
    [getDefaultRoadmapTitleForMetadataItem],
  );

  const formattedValue = useMemo(() => {
    return value?.filter(Boolean).join(' / \n');
  }, [value]);

  const inputTotalRows = useMemo(() => {
    return value?.filter(Boolean)?.length || 1;
  }, [value]);

  const dropdownOptions = useMemo(() => {
    /**
     * If metadata roadmaps is not available skip filter and transform
     */
    if (!hasMetadataRoadmaps) return options;

    // If show all options is toggled discard any filtering
    if (showAllOptions) {
      return formatOptionsList(options);
    }

    return formatOptionsList(filterOptions(options, roadmapsKey));
  }, [hasMetadataRoadmaps, showAllOptions, options, formatOptionsList, filterOptions]);

  const onChangeHandler = useCallback(
    (selectedItem, saveCallback) => {
      const levelCorpTitle = getLevelTitlePropertyName(levelZeroFieldName);
      const levelOneTitle = getLevelTitlePropertyName(levelOneFieldName);
      const levelTwoTitle = getLevelTitlePropertyName(levelTwoFieldName);

      if (selectedItem?.parent_id == null) {
        if (hasLevelCorp) {
          return saveCallback({
            [levelCorpTitle]: selectedItem?.title,
            [levelOneTitle]: null,
            [levelTwoTitle]: null,
          });
        }
        return saveCallback({ [levelOneTitle]: selectedItem?.title, [levelTwoTitle]: null });
      }

      const parentIdItem = options.find(item => item.id === selectedItem.parent_id);

      // If it has 3 levels
      if (hasLevelCorp) {
        if (parentIdItem && parentIdItem.parent_id) {
          const grandParentIdItem = options.find(item => item.id === parentIdItem.parent_id);

          return saveCallback({
            [levelCorpTitle]: grandParentIdItem?.title,
            [levelOneTitle]: parentIdItem?.title,
            [levelTwoTitle]: selectedItem.title,
          });
        }
        return saveCallback({
          [levelCorpTitle]: parentIdItem?.title,
          [levelOneTitle]: selectedItem?.title,
          [levelTwoTitle]: null,
        });
      }

      return saveCallback({
        [levelOneTitle]: parentIdItem?.title,
        [levelTwoTitle]: selectedItem.title,
      });
    },
    [options],
  );

  const onToggleShowAllOptions = useCallback(() => {
    setShowAllOptions(prevValue => !prevValue);
  }, []);

  return {
    dropdownOptions,
    formattedValue,
    inputTotalRows,
    hasMetadataRoadmaps,
    showAllOptions,
    onChangeHandler,
    onToggleShowAllOptions,
  };
};

export default useFluidMetadataTreeDropdown;
