import React, { useCallback } from 'react';
import styled from 'styled-components';
import { prop } from 'ramda';

import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete/index';
import InlineDateRange from 'design-system/organisms/InlineDateRange/InlineDateRange';

import { spacing } from 'design-system/theme';

import { TIME_BUCKET_TIMEFRAME_KEY } from 'store/roadmapVersions/constants/defaultChartOptions';

import useScenarioVersionColors from 'routes/RoadmapVersions/hooks/useScenarioVersionColors';
import useRoadmapVersions from 'hooks/useRoadmapVersions';
import SwimlaneSelectedVersionsColors from 'routes/RoadmapVersions/components/SwimlaneSelectedVersionsColors';

import useScenariosChartsOptions, {
  PROJECTED_DROPDOWN,
  STACKED_BY_DROPDOWN,
  TIME_BUCKET_DROPDOWN,
} from './useScenariosChartsOptions';

const getStartDate = prop('start_date');
const getEndDate = prop('end_date');

const exist = Boolean;

const DEFAULT_DROPDOWN_OPTIONS = [TIME_BUCKET_DROPDOWN, PROJECTED_DROPDOWN, STACKED_BY_DROPDOWN];

const ScenariosChartsOptions = ({ dropdownOptionsToBeRendered = DEFAULT_DROPDOWN_OPTIONS, showSelectedVersionsBadge = true }) => {
  const {
    projectedOptionSelected,
    stackedByOptionSelected,
    timeBucketOptionSelected,
    onDropdownChange,
    projectedOptions,
    stackedByOptions,
    timeBucketOptions,
    dateInterval,
    setDateInterval,
  } = useScenariosChartsOptions();

  const { roadmapVersions } = useRoadmapVersions();
  const { scenarioColors } = useScenarioVersionColors();

  const _onDateIntervalChange = useCallback(
    value => {
      const startDate = getStartDate(value);
      const endDate = getEndDate(value);

      let newDateInterval = { ...dateInterval };

      if (exist(startDate)) newDateInterval = { ...newDateInterval, startDate };
      if (exist(endDate)) newDateInterval = { ...newDateInterval, endDate };

      setDateInterval(newDateInterval);
    },
    [dateInterval, setDateInterval],
  );

  const showDateInterval = timeBucketOptionSelected?.key !== TIME_BUCKET_TIMEFRAME_KEY;

  const shouldRenderDropdown = dropdown => dropdownOptionsToBeRendered.includes(dropdown);

  return (
    <Wrapper>
      <LeftControls>
        {shouldRenderDropdown(TIME_BUCKET_DROPDOWN) && (
          <GroupByAutocomplete
            id="time-period"
            name={TIME_BUCKET_DROPDOWN}
            label="Time period by"
            suggestions={timeBucketOptions}
            value={timeBucketOptionSelected}
            onChange={onDropdownChange(TIME_BUCKET_DROPDOWN)}
          />
        )}
        {shouldRenderDropdown(PROJECTED_DROPDOWN) && (
          <GroupByAutocomplete
            name={PROJECTED_DROPDOWN}
            label="Projected"
            suggestions={projectedOptions}
            value={projectedOptionSelected}
            onChange={onDropdownChange(PROJECTED_DROPDOWN)}
          />
        )}
        {shouldRenderDropdown(STACKED_BY_DROPDOWN) && (
          <GroupByAutocomplete
            name={STACKED_BY_DROPDOWN}
            label="Stacked by"
            suggestions={stackedByOptions}
            value={stackedByOptionSelected}
            onChange={onDropdownChange(STACKED_BY_DROPDOWN)}
          />
        )}
      </LeftControls>
      {showSelectedVersionsBadge && (
        <SwimlaneSelectedVersionsColors scenarioColors={scenarioColors} roadmapVersions={roadmapVersions} />
      )}
      <RightControls>
        {showDateInterval && (
          <InlineDateRange
            startDate={dateInterval.startDate}
            endDate={dateInterval.endDate}
            useLocalTimezone
            onChange={_onDateIntervalChange}
          />
        )}
      </RightControls>
    </Wrapper>
  );
};

ScenariosChartsOptions.propTypes = {};

export default ScenariosChartsOptions;

const Wrapper = styled.div`
  &&&& {
    min-height: ${({ theme }) => theme.sizing.toolbar.height};
    margin: 0 auto ${spacing(0.625)}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${spacing(1.25)}px;
  }
`;

const LeftControls = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    gap: ${spacing(2.125)}px;
  }
`;

const RightControls = styled.div``;
