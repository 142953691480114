import React, { memo } from 'react';
import styled from 'styled-components';

import GridTable from 'design-system/organisms/GridTable/index';

import { spacing } from 'design-system/theme';

import useColHeader from '../hooks/useColHeader';
import HeaderTitle from './HeaderTitle';
import HeaderIssueCount from './HeaderIssueCount';
import { DateLower } from './headerComponents';
import { UNDEFINED } from 'constants/common';

const ColumnHeader = memo(
  ({ col, isReadOnly, selectedColData, overallProgress, shouldDisplayIssueCount, onVisilityIconClick, isHover }) => {
    const { isEditable, progress, date, hasDetailedProgress } = useColHeader({
      colId: col.id,
      colDate: col.date,
      colOverallProgress: col.overallProgress,
      isReadOnly,
      selectedColData,
      overallProgress,
    });

    const shouldDisplayDate = !!date;

    return (
      <Container>
        <GridTable.HeaderTitle component="div">
          <Flex>
            <HeaderTitle
              id={`port-3d-column-${col.id}`}
              title={col.title}
              postTitle={progress && `(${progress})`}
              dataId={col.id}
              dataKey={selectedColData.key}
              editable={isEditable}
              maxHeight={86}
              onVisilityIconClick={
                onVisilityIconClick ? () => onVisilityIconClick(col?.id || UNDEFINED, selectedColData?.key) : null
              }
              showIcons={isHover}
              isColumnHeader
            />
          </Flex>
        </GridTable.HeaderTitle>
        {shouldDisplayDate && <DateLower>{date}</DateLower>}
        {shouldDisplayIssueCount && <HeaderIssueCount col={col} hasDetailedProgress={hasDetailedProgress} />}
      </Container>
    );
  },
);

ColumnHeader.displayName = 'SummaryColHeaderCell';

export default ColumnHeader;

const Container = styled.div``;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing()}px;
`;
