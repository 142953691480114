import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { either, head, isEmpty, isNil, not, pipe } from 'ramda';

import {
  disableIntegrationWebhooks as disableIntegrationWebhooksAction,
  enableIntegrationWebhooks as enableIntegrationWebhooksAction,
} from '../store/thunks';
import {
  selectIsDisablingIntegrationWebhooks,
  selectIsEnablingIntegrationWebhooks,
  selectOrganizationIntegration,
} from '../store/selectors';

const hasWebhooks = pipe(either(isEmpty, isNil), not);

const useOrgIntegrationWebhooks = (integrationType, orgIntegrationId) => {
  const dispatch = useDispatch();

  const orgIntegration = useSelector(selectOrganizationIntegration(orgIntegrationId));

  const isEnablingWebhooks = useSelector(selectIsEnablingIntegrationWebhooks);
  const isDisablingWebhooks = useSelector(selectIsDisablingIntegrationWebhooks);

  const enableWebhooks = useCallback(showSuccessToast => {
    return dispatch(enableIntegrationWebhooksAction(integrationType, orgIntegrationId, showSuccessToast));
  }, [integrationType, orgIntegrationId]);

  const disableWebhooks = useCallback(() => {
    return dispatch(disableIntegrationWebhooksAction(integrationType, orgIntegrationId));
  }, [integrationType, orgIntegrationId]);

  const hasWebhooksEnabled = hasWebhooks(orgIntegration?.data?.webhooks);
  const firstWebhookUri = hasWebhooksEnabled ? head(orgIntegration?.data?.webhooks)?.webhookUri : null;

  return {
    hasWebhooksEnabled,
    firstWebhookUri,
    isEnablingWebhooks,
    isDisablingWebhooks,
    enableWebhooks,
    disableWebhooks,
  };
};

export default useOrgIntegrationWebhooks;
