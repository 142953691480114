import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { sendWorkspacesInstructions, clearResetPassword } from 'src/store/login';

import { getLogin } from 'src/store/login/selectors';
import { getDefaultRoute } from 'utils';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');

    const login = useSelector(getLogin);

    useEffect(() => {
      dispatch(clearResetPassword());

      if (login.isAuthenticated) {
        return <Redirect to={getDefaultRoute(login.currentUser)} />;
      }
    }, []);

    const handleEmailValue = value => {
      setEmail(value);
    };

    const handleSubmit = () => {
      dispatch(sendWorkspacesInstructions(email, props));
    };

    return (
      <Component
        email={email}
        resetPasswordEmail={login.resetPasswordEmail}
        handleEmailValue={handleEmailValue}
        handleSubmit={handleSubmit}
      />
    );
  };
};

export default componentHOC;
