import React from 'react';
import { withRouter } from 'react-router-dom';

import useLoadCustomerRequests from 'hooks/customerRequests/useLoadCustomerRequests';

import Grid from '../Grid';

const GridView = ({ ...otherProps }) => {
  useLoadCustomerRequests();

  return <Grid {...otherProps} />;
};

export default withRouter(GridView);
