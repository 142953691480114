import { createSelector } from 'reselect';

import { isLoading, isSuccess, isError } from 'utils/store/thunk';

import {
  ADD_PROJECT_ESTIMATE_DEPENDENCY,
  ADD_PROJECT_PROJECT_DEPENDENCY,
  ADD_PROJECT_TASK_DEPENDENCY,
  REMOVE_PROJECT_TASK_DEPENDENCY,
  REMOVE_PROJECT_ESTIMATE_DEPENDENCY,
  REMOVE_PROJECT_PROJECT_DEPENDENCY,
} from './types';

const getProjectDependenciesOperations = state => state.dependencies.operations;

const isAddProjectDependenciesLoading = createSelector(getProjectDependenciesOperations, state => {
  return [ADD_PROJECT_TASK_DEPENDENCY, ADD_PROJECT_ESTIMATE_DEPENDENCY, ADD_PROJECT_PROJECT_DEPENDENCY].some(actionType => {
    return isLoading(state, actionType);
  });
});

const isAddProjectDependenciesSuccess = createSelector(getProjectDependenciesOperations, state => {
  return [ADD_PROJECT_TASK_DEPENDENCY, ADD_PROJECT_ESTIMATE_DEPENDENCY, ADD_PROJECT_PROJECT_DEPENDENCY].some(actionType => {
    return isSuccess(state, actionType);
  });
});

const isAddProjectDependenciesError = createSelector(getProjectDependenciesOperations, state => {
  return [ADD_PROJECT_TASK_DEPENDENCY, ADD_PROJECT_ESTIMATE_DEPENDENCY, ADD_PROJECT_PROJECT_DEPENDENCY].some(actionType => {
    return isError(state, actionType);
  });
});

const isRemoveProjectDependenciesLoading = createSelector(getProjectDependenciesOperations, state => {
  return [REMOVE_PROJECT_TASK_DEPENDENCY, REMOVE_PROJECT_ESTIMATE_DEPENDENCY, REMOVE_PROJECT_PROJECT_DEPENDENCY].some(
    actionType => {
      return isLoading(state, actionType);
    },
  );
});

const isRemoveProjectDependenciesSuccess = createSelector(getProjectDependenciesOperations, state => {
  return [REMOVE_PROJECT_TASK_DEPENDENCY, REMOVE_PROJECT_ESTIMATE_DEPENDENCY, REMOVE_PROJECT_PROJECT_DEPENDENCY].some(
    actionType => {
      return isSuccess(state, actionType);
    },
  );
});

const isRemoveProjectDependenciesError = createSelector(getProjectDependenciesOperations, state => {
  return [REMOVE_PROJECT_TASK_DEPENDENCY, REMOVE_PROJECT_ESTIMATE_DEPENDENCY, REMOVE_PROJECT_PROJECT_DEPENDENCY].some(
    actionType => {
      return isError(state, actionType);
    },
  );
});

export {
  isAddProjectDependenciesLoading,
  isAddProjectDependenciesSuccess,
  isAddProjectDependenciesError,
  isRemoveProjectDependenciesLoading,
  isRemoveProjectDependenciesSuccess,
  isRemoveProjectDependenciesError,
};
