import {
  SET_GOALS_CANVAS_DATA,
  UPDATE_GOALS_CANVAS_EDGES_LAYOUT,
  UPDATE_GOALS_CANVAS_LAYOUT_CONFIG,
  UPDATE_GOALS_CANVAS_NODES_LAYOUT,
} from './types';

export const updateLayoutConfig = layoutConfig => ({
  type: UPDATE_GOALS_CANVAS_LAYOUT_CONFIG,
  payload: layoutConfig,
  meta: {
    makesActiveViewDirty: true,
  },
});

export const updateNodesLayout = nodesLayout => ({
  type: UPDATE_GOALS_CANVAS_NODES_LAYOUT,
  payload: nodesLayout,
  meta: {
    makesActiveViewDirty: true,
  },
});

export const updateEdgesLayout = edgesLayout => ({
  type: UPDATE_GOALS_CANVAS_EDGES_LAYOUT,
  payload: edgesLayout,
  meta: {
    makesActiveViewDirty: true,
  },
});

export const setGoalsCanvasData = ({ objectives = [], keyResults = [], metrics = [] }) => ({
  type: SET_GOALS_CANVAS_DATA,
  payload: {
    objectives,
    keyResults,
    metrics,
  },
  meta: {
    makesActiveViewDirty: true,
  },
});
