import React from 'react';

import ChartContent from '../ChartContent';
import ChartLegend from '../ChartLegend';

export default ({ chart, wrapper: Wrapper, title, subtitle, actionButtons }) => {
  return (
    <Wrapper>
      <ChartContent title={title} subtitle={subtitle} actionButtons={actionButtons}>
        {chart}
        <ChartLegend legends={chart?.datasets} />
      </ChartContent>
    </Wrapper>
  );
};
