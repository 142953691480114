export const FEATURES_KEY = 'feature';

export const PAGES_KEY = 'page';

export const ACCOUNTS_KEY = 'account';

export const ALL_ACCOUNTS_KEY = 'all-accounts';

export const VISITORS_KEY = 'visitor';

export const PENDO_ENTITY_TYPES = [FEATURES_KEY, PAGES_KEY, ACCOUNTS_KEY, VISITORS_KEY];

export const METRIC_TYPE_OPTIONS = [
  { title: 'Visitors', key: 'visitorId' },
  { title: 'Accounts', key: 'accountId' },
];

export const PAGE_TYPE_OPTIONS = [{ title: 'Page Views', key: 'numEvents' }, ...METRIC_TYPE_OPTIONS];

export const FEATURE_TYPE_OPTIONS = [{ title: 'Feature Clicks', key: 'numEvents' }, ...METRIC_TYPE_OPTIONS];

export const ACCOUNT_TYPE_OPTIONS = [
  { title: 'Events', key: 'numEvents' },
  { title: 'Visitors', key: 'visitorId' },
  { title: 'Time on site', key: 'numMinutes' },
];

export const ALL_ACCOUNT_TYPE_OPTIONS = [
  { title: 'Accounts', key: 'accountId' },
  { title: 'Events', key: 'numEvents' },
  { title: 'Time on site', key: 'numMinutes' },
];

export const VISITOR_TYPE_OPTIONS = [
  { title: 'Visitors', key: 'visitorId' },
  { title: 'Events', key: 'numEvents' },
  { title: 'Time on site', key: 'numMinutes' },
];

export const OPTIONS_BY_ENTITY_TYPE = {
  [FEATURES_KEY]: FEATURE_TYPE_OPTIONS,
  [ACCOUNTS_KEY]: ACCOUNT_TYPE_OPTIONS,
  [PAGES_KEY]: PAGE_TYPE_OPTIONS,
  [VISITORS_KEY]: VISITOR_TYPE_OPTIONS,
  [ALL_ACCOUNTS_KEY]: ALL_ACCOUNT_TYPE_OPTIONS,
};

export const TIME_PERIOD_OPTIONS = [
  { title: 'Weekly', key: 'weekly' },
  { title: 'Monthly', key: 'monthly' },
];

export const TIME_PERIOD_MAPPER = {
  weekRange: 'weekly',
  monthRange: 'monthly',
};

export const DEFAULT_PENDO_SEGMENT = { title: 'Everyone', key: null };
