import getValidFieldNames from './getValidFieldNames';
import getValidDateFieldNames from './getValidDateFieldNames';
import { CUSTOM_FIELD_TYPES } from 'store/customFields/constants';
import { sanitizeCustomFieldKey } from 'utils/customFields';

const getValidFormula = (formula, projectPrefix = 'project', customFields, customFieldsType = CUSTOM_FIELD_TYPES.PROJECTS) => {
  const validOperators = ['+', '-', '*', '/', '(', ')', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, '.', ','];

  if (!formula) return null;

  let invalidChars = formula.replaceAll(' ', '');
  let replacedFormula = formula.replaceAll(' ', '');

  const validDateFields = getValidDateFieldNames(customFields, customFieldsType);
  const validNumberFields = getValidFieldNames(customFields, customFieldsType);

  const validFields = {
    ...validDateFields,
    ...validNumberFields,
  };

  const fieldNames = Object.keys(validFields).sort((a, b) => b.length - a.length);

  fieldNames.forEach(field => {
    invalidChars = invalidChars.replaceAll(validFields[field], '');

    if (fieldNames.includes(field)) {
      const fieldSanitized = sanitizeCustomFieldKey(validFields[field]);

      // replace the custom field key by sanitized key
      if (replacedFormula.includes(validFields[field])) {
        replacedFormula = replacedFormula.replace(validFields[field], fieldSanitized);
      }

      const functionString = validDateFields[field]
        ? `new Date(${projectPrefix}['${fieldSanitized}'])`
        : `parseFloat(${projectPrefix}['${fieldSanitized}'])`;

      // adds word boundary for field name in case CF has a "reserved" field name (ex. business_value / Business Value)
      const replaceAllRegex = new RegExp(`\\b${fieldSanitized}\\b`, 'g');

      replacedFormula = replacedFormula.replaceAll(replaceAllRegex, functionString);
    }
  });

  validOperators.forEach(op => {
    invalidChars = invalidChars.replaceAll(op, '');
  });

  if (replacedFormula.includes('new Date')) {
    replacedFormula = `(${replacedFormula})/1000/3600/24`;
  }

  return invalidChars === '' ? replacedFormula : null;
};

export default getValidFormula;
