import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pluck } from 'ramda';

import { getMetrics } from 'store/metrics/selectors';
import { getAllKeyResults, getObjectives } from 'store/objectives/selectors';
import useMetricModeMetrics from 'routes/Metrics/hooks/useMetricModeMetrics';
import { METRICS_CANVAS_PAGE } from 'constants/filters';
import { setMetricsCanvasData } from 'features/MetricsCanvas/store';
import { selectDataForMetricsCanvas } from 'features/MetricsCanvas/store/selectors';
import { getIsMetadataFetched } from 'store/app';

const getIds = pluck('id');

const useMetricsCanvasData = () => {
  const dispatch = useDispatch();
  const metrics = useSelector(getMetrics);
  const quickFilteredMetrics = useMetricModeMetrics(metrics, METRICS_CANVAS_PAGE);
  const objectives = useSelector(state => getObjectives(state));
  const keyResults = useSelector(getAllKeyResults);
  const isMetadataFetched = useSelector(getIsMetadataFetched);

  const dataForCanvas = useSelector(selectDataForMetricsCanvas);

  useMemo(() => {
    if (isMetadataFetched) {
      const filteredMetricsIds = getIds(quickFilteredMetrics);
      const filteredObjectives = objectives.filter(objective =>
        objective?.metrics.some(objectiveMetric => filteredMetricsIds.includes(objectiveMetric?.id)),
      );
      const filteredKeyResults = keyResults.filter(keyResult =>
        keyResult?.metrics.some(keyResultMetric => filteredMetricsIds.includes(keyResultMetric?.id)),
      );

      dispatch(
        setMetricsCanvasData({ metrics: quickFilteredMetrics, objectives: filteredObjectives, keyResults: filteredKeyResults }),
      );
    }
  }, [isMetadataFetched, metrics, objectives, keyResults]);

  return { dataForCanvas };
};

export default useMetricsCanvasData;
