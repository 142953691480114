/**
 * Custom hook to
 *
 * @param {*} updateState
 * @param {*} lsState
 */
const setGanttColumnWidths = updateState => ganttInstance => {
  ganttInstance.attachEvent('onColumnResizeEnd', (index, column, width) => {
    const columns = ganttInstance.getGridColumns();
    const columnsWidth = {};

    columns.forEach(c => {
      columnsWidth[c.name] = c.width;
    });

    columnsWidth[column.name] = width;
    updateState({ columnsWidth });
    return true;
  });
};

export default setGanttColumnWidths;
