import React, { forwardRef, useCallback } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

/**
 * Decorates Cell Editor components:
 * - Forwards a ref. Forwarded as `forwardedRef` prop.
 * - Stops editing when a click occurs outside the Cell Editor. Customizable.
 */
const cellEditorHoc = (Component, customHandleOnClickAway) => {
  return forwardRef((params, ref) => {
    const { stopEditing } = params;

    const handleOnClickAway = useCallback(
      event => {
        if (typeof customHandleOnClickAway === 'function') {
          return customHandleOnClickAway(event, params);
        }

        stopEditing();
      },
      [stopEditing],
    );

    return (
      <ClickAwayListener onClickAway={handleOnClickAway}>
        <Component {...params} forwardedRef={ref} />
      </ClickAwayListener>
    );
  });
};

export default cellEditorHoc;
