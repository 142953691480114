/**
 * @function getFluidFieldValidation
 *
 * Gets the validation properties for Fluid Field
 *
 * @param  {type} name
 * @param  {type} value
 * @param  {type} validation.isFieldValid
 * @param  {type} validation.isFieldRequired
 * @return {Object}
 */
const getFluidFieldValidation = (name, value, { isFieldValid, isFieldRequired } = {}) => {
  const { isValid, isRequired } = {
    ...(isFieldValid ? { isValid: isFieldValid(name, value) } : {}),
    ...(isFieldRequired ? { isRequired: isFieldRequired(name) } : {}),
  };

  return {
    isValid,
    required: isRequired,
    invalid: isRequired && !isValid,
  };
};

export default getFluidFieldValidation;
