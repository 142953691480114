import React from 'react';
import styled, { css } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

import { spacing } from 'design-system/theme';
import Text from 'design-system/atoms/Text/index';


import { PAGE_HEADER_HEIGHT } from 'constants/common';

const Wrapper = styled.div`
  display: flex;
  margin: 0 0 2px 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing()}px ${spacing(3)}px ${spacing()}px ${spacing()}px;
  box-shadow: 0px 1px 2px 0px rgba(204, 204, 204, 0.8);
  ${({ showBorderBottom, theme }) =>
    showBorderBottom &&
    css`
      border-bottom: 1px solid ${theme.palette.newLayout.background.primary};
    `}
  z-index: 1;
  height: ${({ headerHeight }) => headerHeight || `${PAGE_HEADER_HEIGHT}px`};
  min-height: ${({ headerHeight }) => headerHeight || `${PAGE_HEADER_HEIGHT}px`};
  ${({ customBorderBottom }) =>
    customBorderBottom &&
    css`
      border-bottom: ${customBorderBottom};
    `}
  ${({ hideBoxShadow }) =>
    hideBoxShadow &&
    css`
      box-shadow: none;
    `}
`;

const LeftWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
`;

const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 auto;
  padding-left: ${spacing()}px;
  ${({ onClose }) =>
    onClose &&
    css`
      padding-right: 32px;
    `}
`;

const titleStyles = css`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TitleContainer = styled.div`
  width: ${({ $hasIcon, size }) => size || ($hasIcon ? 75 : 100)}%;
  color: ${({ theme }) => theme.palette.newLayout.text.grey};
  font-size: ${({ theme }) => theme.typography.fontSizeSmallLargeRem}rem;
  ${({ leftIcon }) =>
    leftIcon &&
    css`
      margin-left: 5px;
    `}
  align-items: center;
`;

const Title = styled(Text)`
  ${titleStyles};
`;

const MetricTitle = styled.div`
  ${titleStyles};
  margin: 0 ${spacing(2)}px;
  ${({ theme }) => {
    return css`
      color: ${theme.palette.text.black};
      font-weight: ${theme.typography.fontWeightMedium};
      font-size: ${theme.typography.fontSizeMediumRem}rem;
      line-height: ${theme.typography.lineHeightRegularLarge}px;
    `;
  }}
`;

const BlackHelpIcon = styled(HelpIcon)`
  color: ${({ theme }) => theme.palette.black};
`;

export default props => {
  const {
    headerHeight,
    leftIcon,
    title,
    onClose,
    helpLink,
    actions,
    metricTitle,
    showBorderBottom = true,
    customBorderBottom,
    hideBoxShadow,
    className,
    titleWidth,
    tooltipTitle,
  } = props;
  const _handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Wrapper
      headerHeight={headerHeight}
      showBorderBottom={showBorderBottom}
      customBorderBottom={customBorderBottom}
      hideBoxShadow={hideBoxShadow}
      className={className}
    >
      <LeftWrapper>
        {leftIcon && (
          <IconButton color="primary" onClick={_handleClose}>
            {leftIcon}
          </IconButton>
        )}
        {metricTitle && (
          <Tooltip title={metricTitle}>
            <MetricTitle>{metricTitle}</MetricTitle>
          </Tooltip>
        )}
        {title && (
          <TitleContainer $hasIcon={!!leftIcon} size={titleWidth}>
            <Tooltip title={tooltipTitle}>
              <Title>{title}</Title>
            </Tooltip>
            {helpLink && (
              <IconButton href={helpLink} target="_blank" rel="noopener noreferrer">
                <BlackHelpIcon fontSize="small" />
              </IconButton>
            )}
          </TitleContainer>
        )}
      </LeftWrapper>
      {actions && <RightWrapper onClose={onClose}>{actions.map(iconButton => iconButton)}</RightWrapper>}
    </Wrapper>
  );
};
