import { useState } from 'react';

const useEdit = (initialFormData, onUpdate) => {
  const [editState, setEditState] = useState(() => ({
    formData: initialFormData,
    isEditingMessage: false,
  }));

  const startEdit = () => {
    setEditState(state => ({
      ...state,
      isEditingMessage: true,
    }));
  };

  const onChannelUpdated = value => {
    setEditState(state => ({
      ...state,
      formData: { ...state.formData, channel: value },
    }));
    onUpdate({ channel: value });
  };

  const onDayOfTheWeekUpdated = value => {
    setEditState(state => ({
      ...state,
      formData: { ...state.formData, dayOfTheWeek: value },
    }));
    onUpdate({ dayOfTheWeek: value });
  };

  const onCommentUpdated = value => {
    setEditState(state => ({
      ...state,
      formData: { ...state.formData, comment: value },
    }));
  };

  const onCancelCommentChanges = () => {
    setEditState(state => ({
      ...state,
      formData: { ...state.formData, comment: initialFormData.comment },
      isEditingMessage: false,
    }));
  };

  const onSaveCommentUpdates = () => {
    setEditState(state => ({
      ...state,
      isEditingMessage: false,
    }));
    onUpdate({ comment: editState.formData.comment });
  };

  const onExpandCollapseClick = () => {
    if (editState?.isEditingMessage) {
      onCancelCommentChanges();
    } else {
      startEdit();
    }
  };

  return {
    editState,
    startEdit,
    onExpandCollapseClick,
    onChannelUpdated,
    onDayOfTheWeekUpdated,
    onCommentUpdated,
    onCancelCommentChanges,
    onSaveCommentUpdates,
  };
};

export default useEdit;
