import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_OBJECTIVE_LINKED_ITEMS'),
  ...getThunkActionTypes('LINK_PROJECT_TO_OKR'),
  ...getThunkActionTypes('UNLINK_PROJECT_FROM_OKR'),
  ...getThunkActionTypes('CREATE_PROJECT_FROM_OKR'),
};

export const ADD_NEW_LINKED_ITEM_ROW = 'ADD_NEW_LINKED_ITEM_ROW';
export const REMOVE_UNSAVED_LINKED_ITEMS_ROWS = 'REMOVE_UNSAVED_LINKED_ITEMS_ROWS';

export const {
  FETCH_OBJECTIVE_LINKED_ITEMS,
  FETCH_OBJECTIVE_LINKED_ITEMS_FULFILLED,
  FETCH_OBJECTIVE_LINKED_ITEMS_PENDING,
  FETCH_OBJECTIVE_LINKED_ITEMS_REJECTED,
  LINK_PROJECT_TO_OKR,
  LINK_PROJECT_TO_OKR_FULFILLED,
  LINK_PROJECT_TO_OKR_PENDING,
  LINK_PROJECT_TO_OKR_REJECTED,
  UNLINK_PROJECT_FROM_OKR,
  UNLINK_PROJECT_FROM_OKR_FULFILLED,
  UNLINK_PROJECT_FROM_OKR_PENDING,
  UNLINK_PROJECT_FROM_OKR_REJECTED,
  CREATE_PROJECT_FROM_OKR,
  CREATE_PROJECT_FROM_OKR_FULFILLED,
} = actionTypes;
