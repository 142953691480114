import React from 'react';
import styled from 'styled-components';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';

const SettingsToggle = ({ isChecked, onToggle, label }) => {
  return (
    <Container>
      <StyledInputLabel onClick={onToggle}>{label}</StyledInputLabel>
      <Switch color="secondary" checked={isChecked} onChange={onToggle} />
    </Container>
  );
};

export default SettingsToggle;

const StyledInputLabel = styled(InputLabel)`
  &&&&:hover {
    cursor: pointer;
  }
`;

const Container = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    min-width: 160px;
  }
`;
