import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { prop, pipe, isNil, not, isEmpty, and, either, equals, flatten } from 'ramda';

import { getUsers } from 'store/users/selectors';
import { getActiveCustomers } from 'store/customers/selectors';
import { getTags } from 'store/tags/selectors';
import { getCurrentUser } from 'store/login/selectors';
import { fetchUsers } from 'store/users';
import statuses from 'store/customerRequests/constants/status';
import priorities from 'store/customerRequests/constants/priority';
import { getCustomerRequestsCustomFields } from 'store/customFields/selectors';

import useRoadmapAutocompletes from 'hooks/useRoadmapAutocompletes';
import useSystemFields from 'hooks/useSystemFields';

import {
  CUSTOM_FIELD_TYPES,
  CUSTOM_FIELD_TYPES_INITIAL_VALUES,
  CUSTOM_FIELDS,
  MULTI_SELECT_DROPDOWN_CUSTOM_FIELD_TYPE,
} from 'constants/customerRequests';
import { getProducts } from 'store/roadmaps/selectors';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import usePermissions from 'hooks/permissions/usePermissions';

const FORMULA = 'Formula';
const DATE = 'Date';
const DROPDOWN = 'Dropdown';

const getItemData = prop('data');
const getItemFieldType = prop('field_type');
const getItemKey = prop('key');
const getItemTitle = prop('titleWithSuffix');
const notNil = pipe(isNil, not);
const notEmptyNorNil = pipe(either(isEmpty, isNil), not);
const isFormulaOrDate = either(equals(FORMULA), equals(DATE));
const isDropdown = either(equals(DROPDOWN), equals(MULTI_SELECT_DROPDOWN_CUSTOM_FIELD_TYPE));

const ADVANCED_SEARCH_FIELDS_ORDER = [
  'details',
  'title',
  'key',
  'roadmap_id',
  'product_1_id',
  'product_2_id',
  'customer_ids',
  'status',
  'priority',
  'tag_ids',
  'created_by_id',
  'owner_id',
  'created_after',
];

const sortAdvancedSearchFields = (a, b) => {
  const indexA = ADVANCED_SEARCH_FIELDS_ORDER.indexOf(a.key);
  const indexB = ADVANCED_SEARCH_FIELDS_ORDER.indexOf(b.key);

  return indexA - indexB;
};

export default isAdvanceSearchPopup => {
  const dispatch = useDispatch();
  const users = useSelector(state => getUsers(state));
  const currentUser = useSelector(state => getCurrentUser(state));
  const customers = useSelector(state => getActiveCustomers(state));
  const tags = useSelector(state => getTags(state, false));
  const products = useSelector(state => getProducts(state, false));
  const customFields = useSelector(getCustomerRequestsCustomFields);
  const [getSystemFieldName] = useSystemFields();
  const { canView } = usePermissions();

  const { roadmaps } = useRoadmapAutocompletes({});

  const advancedSearchFilterData = [
    {
      label: 'Title',
      key: 'title',
      type: 'text',
      children: [],
    },
    {
      label: 'Details',
      key: 'details',
      type: 'text',
      children: [],
    },
    {
      label: 'Key',
      key: 'key',
      type: 'number',
      children: [],
    },
    {
      label: getSystemFieldName('product1'),
      key: 'product_1_id',
      type: 'check',
      children: [],
    },
    ...(canView(PERMISSION_RESOURCES.product2)
      ? [
          {
            label: getSystemFieldName('product2'),
            key: 'product_2_id',
            type: 'check',
            children: [],
          },
        ]
      : []),
  ];

  const filterData = [
    {
      label: getSystemFieldName('customer', true),
      key: 'customer_ids',
      type: 'check',
      children: [],
    },
    {
      label: 'Status',
      key: 'status',
      type: 'check',
      children: [],
    },
    {
      label: getSystemFieldName('priority'),
      key: 'priority',
      type: 'check',
      children: [],
    },
    {
      label: getSystemFieldName('tag', true),
      key: 'tag_ids',
      type: 'check',
      children: [],
    },
    {
      label: 'Creator',
      key: 'created_by_id',
      type: 'check',
      children: [],
    },
    {
      label: getSystemFieldName('roadmap'),
      key: 'roadmap_id',
      type: 'check',
      children: [],
    },
    {
      label: 'Owner',
      key: 'owner_id',
      type: 'check',
      children: [],
    },
    {
      label: 'Created',
      key: 'created_after',
      type: 'check',
      children: [
        { id: 1, label: 'Today' },
        { id: 4, label: 'Most recent' },
        { id: 2, label: 'Last 7 days' },
        { id: 3, label: 'Last 30 days' },
      ],
    },
  ];

  // Adds custom fields to the bottom of filterData
  if (isAdvanceSearchPopup && customFields?.length > 0) {
    (customFields || []).forEach(itemKey => {
      const fieldType = getItemFieldType(itemKey);
      const data = getItemData(itemKey);
      const key = getItemKey(itemKey);
      const title = getItemTitle(itemKey);

      if (isNil(fieldType)) return;

      // TODO - add date to custom fields
      if (isFormulaOrDate(fieldType)) return;

      let children = [];

      if (isDropdown(fieldType) && notEmptyNorNil(data)) {
        children = Object.keys(data).map(item => ({
          id: item,
          label: data[item],
        }));
      }

      const allPropsDefined = and(notNil(key), notNil(title));

      if (allPropsDefined) {
        filterData.push({
          label: title,
          key,
          customKey: CUSTOM_FIELDS,
          type: CUSTOM_FIELD_TYPES[fieldType],
          children,
          defaultInitialValue: CUSTOM_FIELD_TYPES_INITIAL_VALUES[fieldType],
        });
      }
    });
  }

  // todo: this should be refactored to not have to run a foreach whenever a filter / metadata is changed
  const updateFilterData = (users, currentUser, customers, tags, roadmaps, products) => {
    filterData.forEach(mf => {
      switch (mf.key) {
        case 'owner_id':
          mf.children = (users || []).map(user => ({ id: user.id, label: user.name }));
          mf.children.unshift({ id: currentUser.id, label: 'Current User' });
          mf.children.unshift({ id: 'notNull', label: 'Not empty' });
          mf.children.unshift({ id: 'null', label: 'Is empty' });
          break;

        case 'status':
          mf.children = Object.keys(statuses)
            .map(key => ({ id: statuses[key].value, label: statuses[key].label, order: statuses[key].order }))
            .sort((a, b) => a.order - b.order);
          mf.children.unshift({ id: 'notNull', label: 'Not empty' });
          mf.children.unshift({ id: 'null', label: 'Is empty' });
          break;

        case 'priority':
          mf.children = Object.keys(priorities).map(key => ({ id: priorities[key].value, label: priorities[key].label }));
          mf.children.unshift({ id: 'notNull', label: 'Not empty' });
          mf.children.unshift({ id: 'null', label: 'Is empty' });
          break;

        case 'tag_ids':
          mf.children = (tags || []).map(t => ({ id: t.id, label: t.title }));
          mf.children.unshift({ id: 'notNull', label: 'Not empty' });
          mf.children.unshift({ id: 'null', label: 'Is empty' });
          break;

        case 'customer_ids':
          mf.children = (customers || []).map(c => ({ id: c.id, label: c.name }));
          mf.children.unshift({ id: 'notNull', label: 'Not empty' });
          mf.children.unshift({ id: 'null', label: 'Is empty' });
          break;

        case 'created_by_id':
          mf.children = (users || []).map(user => ({ id: user.id, label: user.name }));
          mf.children.unshift({ id: currentUser.id, label: 'Current User' });
          break;

        case 'roadmap_id':
          mf.children = [];

          (roadmaps || []).forEach(roadmap => {
            const products = (roadmap.products || []).map(p => ({ id: p.id, label: p.title, key: 'product_1_id' }));

            mf.children.push({ id: roadmap.id, label: roadmap.title, children: products });
          });

          mf.children.unshift({ id: 'notNull', label: 'Not empty' });
          mf.children.unshift({ id: 'null', label: 'Is empty' });

          break;
        default:
          break;
      }
    });

    advancedSearchFilterData.forEach(mf => {
      switch (mf.key) {
        case 'product_1_id':
          mf.children = (products || []).map(p => ({ id: p.id, label: p.title }));
          mf.children.unshift({ id: 'notNull', label: 'Not empty' });
          mf.children.unshift({ id: 'null', label: 'Is empty' });
          break;
        case 'product_2_id':
          const products2 = flatten((products || []).map(p => p.products || [])).map(p2 => ({ id: p2.id, label: p2.title }));

          mf.children = products2;
          mf.children.unshift({ id: 'notNull', label: 'Not empty' });
          mf.children.unshift({ id: 'null', label: 'Is empty' });
          break;
        default:
          break;
      }
    });
  };

  const multiFilterData = useMemo(() => {
    updateFilterData(users, currentUser, customers, tags, roadmaps, products);

    return isAdvanceSearchPopup ? [...advancedSearchFilterData, ...filterData].sort(sortAdvancedSearchFields) : filterData;
  }, [users, currentUser, customers, tags, roadmaps, products]);

  // TODO - Sérgio - get users with API request to prevent from fetch All users at same time
  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  return [multiFilterData];
};
