import React from 'react';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import moment from 'moment-timezone';
import { isEmpty, isNil, not } from 'ramda';

import theme from 'design-system/theme';
import ShowFieldsIcon from 'design-system/atoms/ShowFieldsIcon';
import { TREE_BRANCH_FIELDS, TREE_BRANCH_METADATA_REF } from 'constants';
import sortRowOrder from 'utils/sortRowOrder';
import isPercentage from 'utils/isPercentage';
import isTruthyOrZero from 'utils/isTruthyOrZero';

const ShowFieldsContainer = styled.div`
  &&&& {
    width: 100%;
    display: flex;
    padding-left: 16px;

    svg {
      padding: 0 14px 0 11px;
    }
  }
`;

/**
 * Returns the dropdown options to use on controls bar
 *
 * @param {*} options
 */
export const getMoreDropdownOptions = (
  updateState,
  tableShowFieldsClicked,
  isOKRLastLevel,
  showSnapshotTreeView,
  showSnapshotTable,
  selectedSnapshotField,
  hideCards,
  hideEmptyCards,
  isGoalMode = false,
  visibleCardElements,
) => {
  const includeTablePreferences = not(isGoalMode);

  const snapshotTableOptions = [];

  const updateVisibleCardElements = element =>
    updateState({ visibleCardElements: { ...visibleCardElements, [element]: !visibleCardElements?.[element] } });

  if (includeTablePreferences) {
    snapshotTableOptions.push({
      id: 'showSnapshotTable',
      key: 'showSnapshotTable',
      onClick: () => updateState({ showSnapshotTable: !showSnapshotTable }),
      title: (
        <div>
          <Switch
            color="primary"
            checked={showSnapshotTable}
            onChange={() => updateState({ showSnapshotTable: !showSnapshotTable })}
          />
          Show table
        </div>
      ),
    });
  }

  if (
    showSnapshotTable &&
    (selectedSnapshotField === 'objectiveCorp' ||
      selectedSnapshotField === 'objective' ||
      selectedSnapshotField === 'keyResult1' ||
      selectedSnapshotField === 'keyResult2')
  ) {
    snapshotTableOptions.push({
      id: 'selectSnapshotTableFields',
      key: 'selectSnapshotTableFields',
      onClick: () => {},
      title: (
        <ShowFieldsContainer onClick={() => tableShowFieldsClicked()}>
          <ShowFieldsIcon fill="#6b6b6b" style={{ fontSize: '20px' }} />
          Select table fields
        </ShowFieldsContainer>
      ),
    });

    if (!isOKRLastLevel) {
      snapshotTableOptions.push({
        id: 'showSnapshotTreeView',
        key: 'showSnapshotTreeView',
        onClick: () => updateState({ showSnapshotTreeView: !showSnapshotTreeView }),
        title: (
          <div>
            <Switch
              color="primary"
              checked={showSnapshotTreeView}
              onChange={() => updateState({ showSnapshotTreeView: !showSnapshotTreeView })}
            />
            Enable tree view
          </div>
        ),
      });
    }

    if (includeTablePreferences) {
      snapshotTableOptions.push({
        id: 'show-cards',
        key: 'show-cards',
        onClick: () => updateState({ hideCards: !hideCards }),
        title: (
          <div>
            <Switch color="primary" checked={!hideCards} onChange={() => updateState({ hideCards: !hideCards })} />
            Show cards
          </div>
        ),
      });
    }
  }

  const snapshotCardVisibilityOptions = [
    {
      id: 'show-card-metrics',
      key: 'show-card-metrics',
      onClick: () => updateVisibleCardElements('metrics'),
      title: (
        <div>
          <Switch color="primary" checked={visibleCardElements?.metrics} onChange={() => updateVisibleCardElements('metrics')} />
          Show Metrics
        </div>
      ),
    },
    {
      id: 'show-card-portfolio-progress',
      key: 'show-card-portfolio-progress',
      onClick: () => updateVisibleCardElements('portfolioProgress'),
      title: (
        <div>
          <Switch
            color="primary"
            checked={visibleCardElements?.portfolioProgress}
            onChange={() => updateVisibleCardElements('portfolioProgress')}
          />
          Show Portfolio Progress
        </div>
      ),
    },
    {
      id: 'show-card-portfolio-items',
      key: 'show-card-portfolio-items',
      onClick: () => updateVisibleCardElements('portfolioItems'),
      title: (
        <div>
          <Switch
            color="primary"
            checked={visibleCardElements?.portfolioItems}
            onChange={() => updateVisibleCardElements('portfolioItems')}
          />
          Show Portfolio Items
        </div>
      ),
    },
    {
      id: 'show-card-owner-avatar',
      key: 'show-card-owner-avatar',
      onClick: () => updateVisibleCardElements('ownerAvatar'),
      title: (
        <div>
          <Switch
            color="primary"
            checked={visibleCardElements?.ownerAvatar}
            onChange={() => updateVisibleCardElements('ownerAvatar')}
          />
          Show Owner Avatar
        </div>
      ),
    },
  ];

  return [
    ...snapshotTableOptions,
    {
      id: 'show-empty-cards',
      key: 'show-empty-cards',
      onClick: () => updateState({ hideEmptyCards: !hideEmptyCards }),
      title: (
        <div>
          <Switch color="primary" checked={!hideEmptyCards} onChange={() => updateState({ hideEmptyCards: !hideEmptyCards })} />
          Show empty cards
        </div>
      ),
    },
    ...(hideCards ? [] : snapshotCardVisibilityOptions),
  ];
};

/**
 * Sorts lower level items (L2 OKR, sub-roadmap) for cards
 *
 * @param {*} options
 */
export const getOrderForEntities = (selectedSnapshotField, entities, usableMetadata, isDodActive) => {
  // split by parent
  const parentField = TREE_BRANCH_FIELDS(isDodActive)[selectedSnapshotField];
  const metadataField = TREE_BRANCH_METADATA_REF[selectedSnapshotField];

  const ids = entities.map(entity => entity[parentField]);
  const parentIds = new Set(ids);

  if (isNil(usableMetadata[metadataField]) || isEmpty(usableMetadata[metadataField])) {
    // There is no usable metadata for parents, return the same list of entities
    return entities;
  }

  const parents = usableMetadata[metadataField]
    .filter(parent => {
      return [...parentIds].includes(parent.id);
    })
    .sort(sortRowOrder);

  // concatenate
  const mappedParents = parents.map(parent => {
    return {
      ...parent,
      children: entities.filter(entity => entity[parentField] === parent.id).sort(sortRowOrder),
    };
  });

  const sorted = [...entities.filter(entity => !entity.id).sort(sortRowOrder)];

  return mappedParents.reduce((acc, entity) => {
    return [...acc, ...entity.children];
  }, sorted);
};

export const getMetricValueRecord = (originalValue, type, date, formatDate, metricValues = []) => {
  return metricValues.find(val => {
    const dateFormatted = formatDate(val.date);

    return Number(val.value) === originalValue && date === dateFormatted && val.type === type;
  });
};

/**
 * Returns a value that can convert to a number
 *
 * If value is in percentage it converts that value to decimal (and removes %)
 *
 * @param {*} options
 */
export const getFormattedMetricValueForChart = (value, convertPercentageToDecimal) => {
  const valueIsInPercentage = isPercentage(value);

  let formattedValue = (value || '').replace('%', '');

  if (Number.isNaN(+formattedValue)) return '';

  if (valueIsInPercentage && convertPercentageToDecimal) {
    formattedValue = String(formattedValue / 100);
  }

  return formattedValue;
};

/**
 * Date formatter for metrics values
 *
 * @param {*} metricValues
 */
export const formatDate = dateString => {
  const date = new Date(dateString);

  const [month, day, year] = [date.getUTCMonth(), date.getUTCDate(), date.getUTCFullYear()];

  return `${month + 1}/${day}/${year}`;
};

/**
 * Returns dates associated with valid metric values
 *
 * @param {*} metricValues
 */
export const getMetricValuesDates = metricValues => {
  return (metricValues || [])
    .reduce((list, data) => {
      const formattedDate = formatDate(data.date);
      const value = getFormattedMetricValueForChart(data.value);
      const valueIsValid = isTruthyOrZero(value);
      const alreadyHasDate = list.includes(formattedDate);

      if (!alreadyHasDate && valueIsValid) {
        return [...list, formattedDate];
      }
      return list;
    }, [])
    .sort((a, b) => new Date(a) - new Date(b));
};

/**
 * Returns the dates that are in the provided timeframe
 *
 * @param {*} dates
 * @param {*} start
 * @param {*} end
 */
export const getDatesInTimeframe = (dates, start, end) =>
  dates.filter(date => {
    const dateValue = moment(date);
    const startDate = moment(start);
    const endDate = moment(end);

    const isSameDayOrAfterStartDate = dateValue.isSameOrAfter(startDate);
    const isSameDayOrBeforeEndDate = dateValue.isSameOrBefore(endDate);

    return isSameDayOrAfterStartDate && isSameDayOrBeforeEndDate;
  });

/**
 * Returns an object with x and y properties (date and value) to be used on the Scatter chart
 *
 * @param {*} value
 * @param {*} date
 */
export const createItemForChart = (value, date) => ({
  x: date,
  y: value,
});

/**
 * Returns the Target metric values. In cases where the date window is bigger than the
 * dates of the Target values we need to create "fake" Target values before and after
 * the date window to create a flat line
 *
 * @param {*} targetValues
 * @param {*} start
 * @param {*} end
 */
export const createFakeTargetValuesIfNeeded = (targetValues, start, end) => {
  const firstTarget = targetValues[0];
  const lastTarget = targetValues[targetValues.length - 1];

  const isStartDateBeforeFirstTarget = firstTarget && moment(start).isBefore(moment(firstTarget?.x));
  const isEndDateAfterLastTarget = lastTarget && moment(end).isAfter(moment(lastTarget?.x));

  let values = targetValues;

  if (isStartDateBeforeFirstTarget) {
    values = [createItemForChart(firstTarget?.y, formatDate(moment(start).subtract(1, 'days').toISOString())), ...values];
  }

  if (isEndDateAfterLastTarget) {
    values = [...values, createItemForChart(lastTarget?.y, formatDate(moment(end).add(1, 'days').toISOString()))];
  }

  return values;
};

export const getNextNumberOfCardsPerRow = cardsPerRow => {
  switch (cardsPerRow) {
    case 2:
      return 3;
    case 3:
      return 4;
    default:
      return 2;
  }
};

export const getGapForCardsContainer = cardsPerRow => {
  switch (cardsPerRow) {
    case 2:
      return theme.spacing.unit * 6;
    case 3:
      return theme.spacing.unit * 3;
    default:
      return theme.spacing.unit * 2;
  }
};
