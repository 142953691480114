import React, { useRef } from 'react';

import theme from 'design-system/theme';
import BaseLayout from 'design-system/organisms/BaseLayout/index';

import usePermissions from 'hooks/permissions/usePermissions';
import Canvas from 'features/Canvas/Canvas';
import useCreateDrawer from 'features/Canvas/components/CreateDrawer/useCreateDrawer';
import CanvasControlsBar from 'features/Canvas/components/CanvasControlsBar';
import CreateDrawer from 'features/Canvas/components/CreateDrawer';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import { KEYRESULT1_NODE, KEYRESULT2_NODE, METRIC_NODE, OBJECTIVE_NODE } from 'features/Canvas/constants';
import { METRICS_CANVAS_PAGE } from 'constants/filters';

import useMetricsCanvas from 'features/MetricsCanvas/hooks/useMetricsCanvas';

const pageId = METRICS_CANVAS_PAGE;

const availableNodes = {
  [OBJECTIVE_NODE]: true,
  [KEYRESULT1_NODE]: true,
  [KEYRESULT2_NODE]: true,
  [METRIC_NODE]: true,
};

const READ_ONLY_TOOLBAR_HEIGHT = 0;

const fixedNodesToDisplay = ['idea', 'initiative', 'bet'];

const MetricsCanvas = ({ isReadOnlyView = false }) => {
  const ref = useRef();
  const { canView } = usePermissions();
  const { openCreateDrawer, ...createDrawerProps } = useCreateDrawer();
  const {
    dataForCanvas,
    canvasLayoutConfig,
    canvasNodesLayout,
    canvasEdgesLayout,
    userActionsMeta,
    nodeTypeLabels,
    onNodesLayoutUpdate,
    onEdgesLayoutUpdate,
    onToggleOkrRelations,
    onToggleBoardRelations,
    onToggleMetricRelations,
    onToggleHighlightHealth,
    onToggleOkrMetricsRelations,
    onToggleOwnerAvatar,
    onToggleProgress,
    onToggleStatus,
    handleDisplayNodesChange,
  } = useMetricsCanvas();

  const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);
  const onAddTextBoxClick = () => {
    ref.current.contentWindow.postMessage(
      {
        type: 'addTextBox',
      },
      '*',
    );
  };

  return (
    <BaseLayout
      displayBorderOnToolbar
      withoutContentMargin
      background={theme.palette.background.secondary}
      toolbarComponent={
        shouldDisplayControlsBar ? (
          <CanvasControlsBar
            pageId={pageId}
            onAddTextBoxClick={onAddTextBoxClick}
            relationsConfig={canvasLayoutConfig.relationsConfig}
            displayPreferences={canvasLayoutConfig.displayPreferences}
            displayNodes={canvasLayoutConfig.displayNodes}
            onToggleOkrRelations={onToggleOkrRelations}
            onToggleBoardRelations={onToggleBoardRelations}
            onToggleMetricRelations={onToggleMetricRelations}
            onToggleHighlightHealth={onToggleHighlightHealth}
            onToggleOkrMetricsRelations={onToggleOkrMetricsRelations}
            handleDisplayNodesChange={handleDisplayNodesChange}
            onToggleOwnerAvatar={onToggleOwnerAvatar}
            onToggleProgress={onToggleProgress}
            onToggleStatus={onToggleStatus}
            availableNodes={availableNodes}
          />
        ) : null
      }
    >
      <Canvas
        innerRef={ref}
        id="metrics-canvas"
        title="metrics canvas"
        dataForCanvas={dataForCanvas}
        openCreateDrawer={openCreateDrawer}
        canvasLayoutConfig={canvasLayoutConfig}
        canvasNodesLayout={canvasNodesLayout}
        canvasEdgesLayout={canvasEdgesLayout}
        userActionsMeta={userActionsMeta}
        nodeTypeLabels={nodeTypeLabels}
        onNodesLayoutUpdate={onNodesLayoutUpdate}
        onEdgesLayoutUpdate={onEdgesLayoutUpdate}
        toolbarHeight={isReadOnlyView ? READ_ONLY_TOOLBAR_HEIGHT : undefined}
        fixedNodesToDisplay={fixedNodesToDisplay}
      />

      <CreateDrawer {...createDrawerProps} />
    </BaseLayout>
  );
};

export default MetricsCanvas;
