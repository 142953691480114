import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import { goToWorkspace } from 'store/login';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [workspaceLogin, setWorkspaceLogin] = useState((queryString.parse(window.location.search) || {}).workspace || '');

    const handleSubmit = () => {
      dispatch(goToWorkspace(workspaceLogin, props));
    };

    return <Component handleWorkspaceValue={setWorkspaceLogin} value={workspaceLogin} handleSubmit={handleSubmit} />;
  };
};

export default componentHOC;
