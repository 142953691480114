import React from 'react';
import TrendingUp from '@material-ui/icons/TrendingUp';

import { ADMIN_USER, OWNER_USER, LEADER_USER, EXTERNAL_REQUESTOR, EDITOR_USER, MANAGER_USER } from '@dragonboat/permissions';

import Roadmap from 'design-system/atoms/DragonIcons/Roadmap';
import ObjectiveIcon from 'design-system/atoms/ObjectiveIcon';
import Bulb from 'design-system/atoms/DragonIcons/Bulb';
import Gallery from 'design-system/atoms/DragonIcons/Gallery';
import CircleChart from 'design-system/atoms/DragonIcons/CircleChart';
import Home from 'routes/Home';
import Staffing from 'routes/Staffing';
import Ideas from 'routes/Ideas';
import Goals from 'routes/Goals';
import Base from 'routes/Settings/Base';
import SettingsHeaderOptions from 'routes/Settings/HeaderOptions';
import SettingsHeaderFilters from 'routes/Settings/HeaderFilters';
import Welcome from 'routes/Dashboard/Welcome';
import Dashboard from 'routes/Dashboard';
import Forecast from 'routes/Forecast';
import Manage from 'routes/Manage';
import CustomerRequests from 'routes/CustomerRequests';
import UserPreferences from 'routes/UserPreferences';
import UserPreferencesHeaderFilters from 'routes/UserPreferences/HeaderFilters';
import Portal from 'routes/Portal';
import Templates from 'routes/Dashboard/Templates';
import Metrics from 'routes/Metrics';
import UserHome from 'routes/UserHome';
import IdeasWhiteboard from 'routes/Ideas/IdeasWhiteboard';
import Explore from 'routes/Dashboard/Explore';
import MissionControlHome from 'features/MissionControlHome';
import CompareRoadmapHistorySnapsHeader from 'features/RoadmapHistory/components/CompareRoadmapHistorySnapsHeader';
import getParentRouteProxyFactory from 'routes/getParentRouteProxyFactory';
import getScenariosRouteProxyFactory from 'routes/getScenariosRouteProxyFactory';
import HeaderVersionsSelection from 'routes/RoadmapVersions/components/HeaderVersionsSelection';
import TinyMenu from 'containers/TinyMenu';
import getSystemFieldName from 'utils/getSystemFieldName';
import onlyVisibleForUsers from 'utils/onlyVisibleForUsers';
import { isNewHomepageEnabled } from 'constants/flags';
import { GOAL_MODE, METRICS_MODE } from 'constants/common';
import { PAGES_WITH_PORTFOLIO_MODE } from 'constants/filters';
import {
  DASHBOARD_EXPLORE_PAGE,
  IDEAS_BOARD_PAGE,
  IDEAS_GRID_PAGE,
  IDEAS_FLAT_GRID_PAGE,
  IDEAS_ESTIMATES_PAGE,
  IDEAS_PDLC_PAGE,
  IDEAS_OVERVIEW_PAGE,
  ALLOCATION_REPORT_PAGE,
  PROGRESS_PAGE,
  FORECAST_PAGE,
  FORECAST_RESOURCES_PAGE,
  SUMMARY_PAGE,
  GOALS_SUMMARY_PAGE,
  GOALS_GRID_PAGE,
  GOALS_SNAPSHOT_PAGE,
  GOALS_ALLOCATION_PAGE,
  GOALS_CANVAS_PAGE,
  SNAPSHOT_PAGE,
  MANAGE_PAGE,
  ROADMAP_PAGE,
  PORTFOLIO_REPORT_PAGE,
  REQUESTS_DETAIL,
  REQUESTS_LIST,
  REQUESTS_INSIGHTS,
  REQUESTS_ANALYZE,
  DASHBOARD_HOME_PAGE,
  DASHBOARDS_PAGE,
  METRICS_GRID_PAGE,
  METRICS_CHART_PAGE,
  METRICS_CANVAS_PAGE,
  COMPARE_SCENARIOS_LIST_PAGE,
  COMPARE_SCENARIOS_SWIMLANE_PAGE,
  COMPARE_SCENARIOS_CHARTS_PAGE,
  COMPARE_SCENARIOS_SUMMARY_PAGE,
  SCENARIO_LIST_PAGE,
  SCENARIO_SWIMLANE_PAGE,
  SCENARIO_GANTT_PAGE,
  SCENARIO_SUMMARY_PAGE,
  SCENARIO_AI_PLANNER_PAGE,
  MANAGE_TIMELINE_PATH,
  MANAGE_RESOURCES_PATH,
  PORTFOLIO_CANVAS_PAGE,
  SCENARIO_CHART_PAGE,
  FORECAST_CHART_PAGE,
  PORTFOLIO_PERSONAS_PAGE,
  REQUESTS_PERSONAS,
  PORTFOLIO_LIFECYCLES_PAGE,
  REQUESTS_LIFECYCLES,
  MISSION_CONTROL_HOME_PAGE,
  ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PAGE,
  ROADMAP_HISTORY_COMPARE_SNAPS_SWIMLANE_PAGE,
  ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PAGE,
  WELCOME_PAGE,
  HOME_PAGE,
  TEMPLATES_PAGE,
  WELCOME_SELECT_TEMPLATE_PAGE,
  USER_DASHBOARDS_PAGE,
} from 'constants/filters/pages';
import ScenarioHeader from 'routes/Scenario/ScenarioHeader';

import DashboardsHeaderFilters from 'routes/Dashboard/Dashboards/HeaderFilters';

import { getPathFromPageId } from 'utils/pages';
import { PERMISSION_FEATURES, PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';
import UpsellPage from 'containers/UpsellFeatures/UpsellPage';
import UpsellFiltersComponent from 'containers/UpsellFeatures/UpsellFiltersComponent';
import { VIEWS_DROPDOWN_TABS } from 'constants/userViews';

// const agGridPreload = () => AgGridReact.preload();
// const ganttPreload = () => GanttWrapper.preload();
const _checkHasPortfolioOptionOnPage = pageId => PAGES_WITH_PORTFOLIO_MODE.includes(pageId);

const EmptyDashboardFiltersHeader = props => {
  const Component = Dashboard.HeaderFilters;

  return (
    <Component
      history={props.history}
      showQuickFilter={false}
      showFilterIcon={false}
      showResultsCounter={false}
      showDisplayLayer={false}
    />
  );
};

/*
 * ********************************
 * IMPORTANT NOTE:
 * ********************************
 *
 * Every time we change some url on this routes we should confirm
 * if the old url is used on the hook useHeaderSearchString, this
 * hook uses static urls
 */
export default (systemFields, user, canView) => [
  {
    label: 'Dashboard',
    path: 'dashboard',
    icon: <CircleChart />,
    component: getParentRouteProxyFactory,
    headerFilters: canView(PERMISSION_FEATURES.pageHeaderFilters) && Dashboard.HeaderFilters,
    headerOptions: canView(PERMISSION_FEATURES.pageHeaderOptions) && Dashboard.HeaderOptions,
    headerLeftContent:
      !canView(PERMISSION_FEATURES.pageHeaderFilters) && !canView(PERMISSION_FEATURES.pageHeaderOptions) && Dashboard.HeaderView,
    visible: () => canView(PERMISSION_PAGES[DASHBOARD_HOME_PAGE]) || canView(PERMISSION_FEATURES.dashboardModule),
    props: { user },
    separator: true,
    navItemComponent: Home.NavBarItem,
    submenuComponent: Home.NavBarSubMenu,
    subMenu: [
      {
        label: 'Dashboards',
        path: getPathFromPageId(DASHBOARDS_PAGE),
        component: getParentRouteProxyFactory,
        visible: (_, org) => canView(PERMISSION_PAGES[DASHBOARDS_PAGE]),
        props: {
          pageId: DASHBOARDS_PAGE,
          hasViews: true,
          headerFilters: DashboardsHeaderFilters,
          headerOptions: Dashboard.HeaderOptions,
          headerLeftContent: () => null,
          headerFiltersProps: { showFilters: canView(PERMISSION_FEATURES.pageHeaderFilters) },
        },
      },
      {
        label: 'Dashboards',
        path: `${getPathFromPageId(DASHBOARDS_PAGE)}/:dashboardId`,
        component: getParentRouteProxyFactory,
        visible: (_, org) => canView(PERMISSION_PAGES[DASHBOARDS_PAGE], { isDashboardModule: true }),
        props: {
          headerFilters: DashboardsHeaderFilters,
          headerOptions: Dashboard.HeaderOptions,
          headerLeftContent: () => null,
          headerFiltersProps: { showFilters: canView(PERMISSION_FEATURES.pageHeaderFilters) },
        },
      },
      {
        label: 'Portfolio Report',
        path: getPathFromPageId(PORTFOLIO_REPORT_PAGE),
        component: getParentRouteProxyFactory,
        visible: () => localStorage.getItem('showPortfolioReport'),
        props: { pageId: PORTFOLIO_REPORT_PAGE },
      },
      {
        label: 'My Views',
        path: getPathFromPageId(DASHBOARD_HOME_PAGE),
        component: isNewHomepageEnabled ? Home.Route : getParentRouteProxyFactory,
        props: { pageId: DASHBOARD_HOME_PAGE, headerFilters: EmptyDashboardFiltersHeader },
        visible: () => canView(PERMISSION_PAGES[DASHBOARD_HOME_PAGE]),
      },
      {
        label: 'Dashboards',
        path: 'dashboard/user-dashboards',
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[USER_DASHBOARDS_PAGE]),
        props: {
          pageId: USER_DASHBOARDS_PAGE,
          headerLeftContent: () => null,
          headerFilters: EmptyDashboardFiltersHeader,
        },
      },
      {
        label: 'explore',
        path: 'dashboard/explore',
        component: Explore,
        props: { pageId: DASHBOARD_EXPLORE_PAGE },
        visible: () => canView(PERMISSION_PAGES[DASHBOARD_EXPLORE_PAGE]),
      },
      {
        label: 'Summary',
        path: getPathFromPageId(SUMMARY_PAGE, true),
        props: { pageId: SUMMARY_PAGE, hasViews: true, displayName: 'Dashboard Summary' },
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[SUMMARY_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'Snapshot',
        path: getPathFromPageId(SNAPSHOT_PAGE),
        component: getParentRouteProxyFactory,
        props: {
          hasPortfolioOption: _checkHasPortfolioOptionOnPage(SNAPSHOT_PAGE),
          pageId: SNAPSHOT_PAGE,
          hasViews: true,
          displayName: 'Dashboard Snapshot',
          isDashboardModule: true,
        },
        visible: () => canView(PERMISSION_PAGES[SNAPSHOT_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'Progress',
        path: getPathFromPageId(PROGRESS_PAGE),
        props: { pageId: PROGRESS_PAGE },
        component: getParentRouteProxyFactory,
        hideMenu: ({ jiraIntegrated, orgIntegrations }) => !jiraIntegrated && (!orgIntegrations || !orgIntegrations.length),
        visible: () => canView(PERMISSION_PAGES[PROGRESS_PAGE]),
      },
      {
        label: 'Allocation Report',
        path: getPathFromPageId(ALLOCATION_REPORT_PAGE),
        props: { pageId: ALLOCATION_REPORT_PAGE, hasViews: true, displayName: 'Dashboard Allocation' },
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[ALLOCATION_REPORT_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'Timeline',
        path: getPathFromPageId(MANAGE_PAGE, true),
        component: Manage,
        props: {
          allowedPlanningStages: ['Planning', 'Confirmed', 'Completed'],
          defaultSelectedPlanningStages: ['Confirmed', 'Completed'],
          stateLS: 'timeline',
          pageId: 'TIMELINE',
          hasPortfolioOption: _checkHasPortfolioOptionOnPage(MANAGE_PAGE),
          hasViews: true,
          displayName: 'Portfolio Gantt',
          isReadOnlyView: true,
        },
        visible: () => canView(PERMISSION_PAGES[MANAGE_PAGE], { isDashboardModule: true }),
        // onMouseEnter: ganttPreload,
      },
      // forecast/timeline - diff from other timeline pages
      {
        label: 'Schedule',
        path: getPathFromPageId(FORECAST_PAGE, true),
        props: {
          pageId: FORECAST_PAGE,
          hasViews: true,
          displayName: 'Forecast Schedule',
          isReadOnlyView: true,
          showUncommittedProjectsToggle: true,
          isDashboardModule: true,
        },
        component: getParentRouteProxyFactory,
        // onMouseEnter: ganttPreload,
        visible: () => canView(PERMISSION_PAGES[FORECAST_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'Staffing',
        path: getPathFromPageId(FORECAST_RESOURCES_PAGE, true),
        component: Staffing,
        visible: () => canView(PERMISSION_PAGES[FORECAST_RESOURCES_PAGE]),
        props: { pageId: FORECAST_RESOURCES_PAGE },
      },
      {
        label: 'List',
        path: getPathFromPageId(IDEAS_ESTIMATES_PAGE, true),
        props: {
          pageId: IDEAS_ESTIMATES_PAGE,
          hasViews: true,
          displayName: 'Forecast List',
          isReadOnlyView: true,
          isDashboardModule: true,
        },
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[IDEAS_ESTIMATES_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'Board',
        path: getPathFromPageId(IDEAS_BOARD_PAGE, true),
        props: { pageId: IDEAS_BOARD_PAGE, hasViews: true, displayName: 'Portfolio Board', isReadOnlyView: true },
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[IDEAS_BOARD_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'List',
        path: getPathFromPageId(IDEAS_GRID_PAGE, true),
        component: getParentRouteProxyFactory,
        props: {
          hasPortfolioOption: _checkHasPortfolioOptionOnPage(IDEAS_GRID_PAGE),
          pageId: IDEAS_GRID_PAGE,
          hasViews: true,
          displayName: 'Portfolio List',
          isReadOnlyView: true,
        },
        visible: () => canView(PERMISSION_PAGES[IDEAS_GRID_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'List Flat',
        path: getPathFromPageId(IDEAS_FLAT_GRID_PAGE, true),
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[IDEAS_FLAT_GRID_PAGE], { isDashboardModule: true }),
        props: {
          hasPortfolioOption: _checkHasPortfolioOptionOnPage(IDEAS_FLAT_GRID_PAGE),
          pageId: IDEAS_FLAT_GRID_PAGE,
          hasViews: true,
          displayName: 'Portfolio List',
          isReadOnlyView: true,
        },
      },
      {
        label: 'Canvas',
        path: getPathFromPageId(PORTFOLIO_CANVAS_PAGE, true),
        props: {
          pageId: 'ideasCanvas',
          hasViews: true,
          displayName: `Portfolio Canvas`,
          isReadOnlyView: true,
        },
        component: IdeasWhiteboard,
        visible: () => canView(PERMISSION_PAGES[PORTFOLIO_CANVAS_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'Roadmap',
        path: getPathFromPageId(ROADMAP_PAGE, true),
        props: { pageId: ROADMAP_PAGE, hasViews: true, displayName: 'Portfolio Swimlane', isReadOnlyView: true },
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[ROADMAP_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'Goal Grid',
        path: getPathFromPageId(GOALS_GRID_PAGE, true),
        props: { pageId: GOALS_GRID_PAGE, hasViews: true, displayName: 'Goal List', isReadOnlyView: true },
        component: getParentRouteProxyFactory,
        mode: GOAL_MODE,
        visible: () => canView(PERMISSION_PAGES[GOALS_GRID_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'Goal Snapshot',
        path: getPathFromPageId(GOALS_SNAPSHOT_PAGE, true),
        props: { pageId: GOALS_SNAPSHOT_PAGE, hasViews: true, displayName: 'Goal Snapshot', isReadOnlyView: true },
        component: getParentRouteProxyFactory,
        mode: GOAL_MODE,
        visible: () => canView(PERMISSION_PAGES[GOALS_SNAPSHOT_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'Goal Summary',
        path: getPathFromPageId(GOALS_SUMMARY_PAGE, true),
        props: { pageId: GOALS_SUMMARY_PAGE, hasViews: true, displayName: 'Goal Summary', isReadOnlyView: true },
        component: getParentRouteProxyFactory,
        mode: GOAL_MODE,
        visible: () => canView(PERMISSION_PAGES[GOALS_SUMMARY_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'Goal Canvas',
        path: getPathFromPageId(GOALS_CANVAS_PAGE, true),
        props: { pageId: GOALS_CANVAS_PAGE, hasViews: true, displayName: 'Canvas', isReadOnlyView: true },
        component: getParentRouteProxyFactory,
        mode: GOAL_MODE,
        visible: () => canView(PERMISSION_PAGES[GOALS_CANVAS_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'Templates',
        path: 'dashboard/templates',
        component: Templates.Route,
        visible: () => canView(PERMISSION_PAGES[TEMPLATES_PAGE]),
        props: { headerFilters: EmptyDashboardFiltersHeader },
      },
      {
        label: `${getSystemFieldName('pdlc', systemFields)}`,
        path: getPathFromPageId(IDEAS_PDLC_PAGE, true),
        props: {
          pageId: IDEAS_PDLC_PAGE,
          hasViews: true,
          displayName: `Portfolio ${getSystemFieldName('pdlc', systemFields)}`,
          isReadOnlyView: true,
        },
        visible: () => canView(PERMISSION_PAGES[IDEAS_PDLC_PAGE], { isDashboardModule: true }),
        component: getParentRouteProxyFactory,
      },
      {
        label: `Overview`,
        path: getPathFromPageId(IDEAS_OVERVIEW_PAGE, true),
        props: {
          pageId: IDEAS_OVERVIEW_PAGE,
          displayName: `Portfolio Overview`,
          isReadOnlyView: true,
        },
        visible: () => canView(PERMISSION_PAGES[IDEAS_OVERVIEW_PAGE], { isDashboardModule: true }),
        component: getParentRouteProxyFactory,
      },
      {
        label: 'Metrics Canvas',
        path: getPathFromPageId(METRICS_CANVAS_PAGE, true),
        props: { pageId: METRICS_CANVAS_PAGE, hasViews: true, displayName: 'Canvas', isReadOnlyView: true },
        component: getParentRouteProxyFactory,
        mode: GOAL_MODE,
        visible: () => canView(PERMISSION_PAGES[METRICS_CANVAS_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'Metrics Grid',
        path: getPathFromPageId(METRICS_GRID_PAGE, true),
        props: { pageId: METRICS_GRID_PAGE, hasViews: true, displayName: 'Metrics List', isReadOnlyView: true },
        component: getParentRouteProxyFactory,
        mode: METRICS_MODE,
        visible: () => canView(PERMISSION_PAGES[METRICS_GRID_PAGE], { isDashboardModule: true }),
      },
      {
        label: 'Metrics Chart',
        path: getPathFromPageId(METRICS_CHART_PAGE, true),
        props: { pageId: METRICS_CHART_PAGE, hasViews: true, isReadOnlyView: true },
        component: getParentRouteProxyFactory,
        mode: METRICS_MODE,
        visible: () => canView(PERMISSION_PAGES[METRICS_CHART_PAGE], { isDashboardModule: true }),
      },
    ],
  },
  {
    label: 'welcome',
    path: 'welcome',
    hideMenu: true,
    component: Welcome.Route,
    headerFilters: Welcome.HeaderFilters,
    headerOptions: Welcome.HeaderOptions,
    visible: () => canView(PERMISSION_PAGES[WELCOME_PAGE]),
    hideHeaderInviteUsersIcon: true,
  },
  {
    label: 'home',
    path: 'home',
    hideMenu: true,
    ...(canView(PERMISSION_PAGES[MISSION_CONTROL_HOME_PAGE])
      ? {
          component: MissionControlHome.Route,
          headerFilters: MissionControlHome.HeaderFilters,
          headerOptions: MissionControlHome.HeaderOptions,
          visible: () => canView(PERMISSION_PAGES[MISSION_CONTROL_HOME_PAGE]),
        }
      : {
          component: UserHome.Route,
          headerFilters: UserHome.HeaderFilters,
          headerOptions: UserHome.HeaderOptions,
          visible: () => canView(PERMISSION_PAGES[HOME_PAGE]),
        }),
    hideHeaderInviteUsersIcon: true,
  },
  {
    label: 'welcome',
    path: 'welcome/select-template',
    hideMenu: true,
    component: Welcome.Route,
    headerOptions: Welcome.HeaderOptions,
    visible: () => canView(PERMISSION_PAGES[WELCOME_SELECT_TEMPLATE_PAGE]),
    hideHeaderInviteUsersIcon: true,
  },
  {
    label: `${getSystemFieldName('request', systemFields)}`,
    path: 'requests',
    icon: <Bulb />,
    visible: () => canView(PERMISSION_FEATURES.requestsModule),
    component: getParentRouteProxyFactory,
    subMenu: [
      {
        label: 'Detail',
        path: getPathFromPageId(REQUESTS_DETAIL),
        component: getParentRouteProxyFactory,
        props: {
          detailView: true,
          pageId: REQUESTS_DETAIL,
          hasViews: true,
          displayName: `${getSystemFieldName('request', systemFields)} Detail`,
        },
      },
      {
        label: 'List',
        path: getPathFromPageId(REQUESTS_LIST),
        component: getParentRouteProxyFactory,
        props: {
          listView: true,
          pageId: REQUESTS_LIST,
          hasViews: true,
          displayName: `${getSystemFieldName('request', systemFields)} List`,
        },
      },
      {
        label: 'Insights',
        path: getPathFromPageId(REQUESTS_INSIGHTS),
        component: getParentRouteProxyFactory,
        props: {
          insightsView: true,
          pageId: REQUESTS_INSIGHTS,
          hasViews: true,
          displayName: `${getSystemFieldName('request', systemFields)} Insights`,
        },
      },
      {
        label: 'Analyze',
        path: getPathFromPageId(REQUESTS_ANALYZE),
        component: getParentRouteProxyFactory,
        props: {
          pageId: REQUESTS_ANALYZE,
          displayName: `${getSystemFieldName('request', systemFields)} Analyze`,
          analyzeView: true,
        },
        visible: () => canView(PERMISSION_PAGES[REQUESTS_ANALYZE]),
      },
      {
        label: 'Personas',
        path: getPathFromPageId(REQUESTS_PERSONAS),
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[REQUESTS_PERSONAS]),
        props: {
          pageId: REQUESTS_PERSONAS,
          displayName: `Personas`,
          personasView: true,
        },
      },
      {
        label: 'Lifecycles',
        path: getPathFromPageId(REQUESTS_LIFECYCLES),
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[REQUESTS_LIFECYCLES]),
        props: {
          pageId: REQUESTS_LIFECYCLES,
          displayName: `Lifecycles`,
          lifecyclesView: true,
        },
      },
    ],
    headerOptions: CustomerRequests.HeaderOptions,
    headerFilters: CustomerRequests.HeaderFilters,
  },
  {
    label: 'Portal',
    path: 'portal',
    component: CustomerRequests.Route,
    hideMenu: true,
    visible: true,
    hideMainMenu: true,
    headerOptions: Portal.HeaderOptions,
  },
  {
    label: 'Scenario',
    path: 'scenario',
    component: getScenariosRouteProxyFactory,
    hideMenu: true,
    visible: () => canView(PERMISSION_FEATURES.scenariosModule),
    headerFilters: ScenarioHeader,
    subMenu: [
      {
        label: 'List',
        path: getPathFromPageId(SCENARIO_LIST_PAGE),
        props: { pageId: SCENARIO_LIST_PAGE },
        component: getScenariosRouteProxyFactory,
      },
      {
        label: 'Swimlane',
        path: getPathFromPageId(SCENARIO_SWIMLANE_PAGE),
        props: { pageId: SCENARIO_SWIMLANE_PAGE },
        component: getScenariosRouteProxyFactory,
      },
      {
        label: 'Gantt',
        path: getPathFromPageId(SCENARIO_GANTT_PAGE),
        props: { pageId: SCENARIO_GANTT_PAGE, showUncommittedProjectsToggle: true },
        component: getScenariosRouteProxyFactory,
      },
      {
        label: '3D',
        path: getPathFromPageId(SCENARIO_SUMMARY_PAGE),
        props: { pageId: SCENARIO_SUMMARY_PAGE },
        component: getScenariosRouteProxyFactory,
      },
      {
        label: 'Chart',
        path: getPathFromPageId(SCENARIO_CHART_PAGE),
        props: { pageId: SCENARIO_CHART_PAGE },
        component: getScenariosRouteProxyFactory,
      },
      {
        label: 'AI Planner',
        path: getPathFromPageId(SCENARIO_AI_PLANNER_PAGE),
        props: { pageId: SCENARIO_AI_PLANNER_PAGE },
        component: getScenariosRouteProxyFactory,
      },
    ],
  },
  {
    label: 'Compare Scenarios',
    path: 'compare-scenarios',
    component: getParentRouteProxyFactory,
    hideMenu: true,
    visible: () => canView(PERMISSION_FEATURES.compareScenariosModule),
    headerFilters: HeaderVersionsSelection,
    subMenu: [
      {
        label: 'List',
        path: getPathFromPageId(COMPARE_SCENARIOS_LIST_PAGE),
        props: { pageId: COMPARE_SCENARIOS_LIST_PAGE, showHideRowsWithSameDataToggle: true },
        component: getParentRouteProxyFactory,
      },
      {
        label: 'Charts',
        path: getPathFromPageId(COMPARE_SCENARIOS_CHARTS_PAGE),
        props: { pageId: COMPARE_SCENARIOS_CHARTS_PAGE, showHideRowsWithSameDataToggle: false },
        component: getParentRouteProxyFactory,
      },
      {
        label: 'Swimlane',
        path: getPathFromPageId(COMPARE_SCENARIOS_SWIMLANE_PAGE),
        props: { pageId: COMPARE_SCENARIOS_SWIMLANE_PAGE, showHideRowsWithSameDataToggle: true },
        component: getParentRouteProxyFactory,
      },
      {
        label: 'Summary',
        path: getPathFromPageId(COMPARE_SCENARIOS_SUMMARY_PAGE),
        props: { pageId: COMPARE_SCENARIOS_SUMMARY_PAGE, showHideRowsWithSameDataToggle: true },
        component: getParentRouteProxyFactory,
      },
    ],
  },
  {
    label: 'Compare History Snaps',
    path: 'compare-snaps',
    component: getParentRouteProxyFactory,
    hideMenu: true,
    visible: (_, organization) => true, // TODO Flag,
    headerFilters: CompareRoadmapHistorySnapsHeader,
    subMenu: [
      {
        label: 'List',
        path: getPathFromPageId(ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PAGE),
        props: { pageId: ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PAGE },
        component: getParentRouteProxyFactory,
      },
      {
        label: 'Swimlane',
        path: getPathFromPageId(ROADMAP_HISTORY_COMPARE_SNAPS_SWIMLANE_PAGE),
        props: { pageId: ROADMAP_HISTORY_COMPARE_SNAPS_SWIMLANE_PAGE },
        component: getParentRouteProxyFactory,
      },
      {
        label: 'Summary',
        path: getPathFromPageId(ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PAGE),
        props: { pageId: ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PAGE },
        component: getParentRouteProxyFactory,
      },
    ],
  },
  {
    label: 'Portfolio',
    path: 'ideas',
    icon: <Roadmap />,
    component: getParentRouteProxyFactory,
    visible: () => canView(PERMISSION_FEATURES.portfolioModule) || canView(PERMISSION_FEATURES.pdlcModule),
    // onMouseEnter: agGridPreload,
    subMenu: [
      {
        label: 'Board',
        path: getPathFromPageId(IDEAS_BOARD_PAGE),
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[IDEAS_BOARD_PAGE]),
        props: {
          pageId: IDEAS_BOARD_PAGE,
          hasViews: true,
          displayName: 'Portfolio Board',
        },
      },
      {
        label: 'List',
        path: getPathFromPageId(IDEAS_GRID_PAGE),
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[IDEAS_GRID_PAGE]),
        props: {
          hasPortfolioOption: _checkHasPortfolioOptionOnPage(IDEAS_GRID_PAGE),
          pageId: IDEAS_GRID_PAGE,
          hasViews: true,
          displayName: 'Portfolio List',
          viewsDropdownTabs: VIEWS_DROPDOWN_TABS,
        },
      },
      {
        label: 'List Flat',
        path: getPathFromPageId(IDEAS_FLAT_GRID_PAGE),
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[IDEAS_FLAT_GRID_PAGE]),
        props: {
          hasPortfolioOption: _checkHasPortfolioOptionOnPage(IDEAS_FLAT_GRID_PAGE),
          pageId: IDEAS_FLAT_GRID_PAGE,
          hasViews: true,
          displayName: 'Portfolio List',
          viewsDropdownTabs: VIEWS_DROPDOWN_TABS,
        },
      },
      {
        label: `${getSystemFieldName('pdlc', systemFields)}`,
        path: getPathFromPageId(IDEAS_PDLC_PAGE),
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[IDEAS_PDLC_PAGE]),
        props: {
          hasPortfolioOption: _checkHasPortfolioOptionOnPage(IDEAS_PDLC_PAGE),
          pageId: IDEAS_PDLC_PAGE,
          hasViews: true,
          displayName: `Portfolio ${getSystemFieldName('pdlc', systemFields)}`,
        },
      },
      {
        label: 'Roadmap',
        path: getPathFromPageId(ROADMAP_PAGE),
        component: getParentRouteProxyFactory,
        props: {
          pageId: ROADMAP_PAGE,
          hasViews: true,
          displayName: 'Portfolio Swimlane',
        },
        visible: () => canView(PERMISSION_PAGES[ROADMAP_PAGE]),
      },
      {
        label: 'Timeline',
        // onMouseEnter: ganttPreload,
        path: getPathFromPageId(MANAGE_PAGE),
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[MANAGE_PAGE]),
        props: {
          hasPortfolioOption: _checkHasPortfolioOptionOnPage(MANAGE_PAGE),
          pageId: MANAGE_PAGE,
          hasViews: true,
          displayName: 'Portfolio Gantt',
        },
      },
      {
        visible: () => canView(PERMISSION_PAGES[SUMMARY_PAGE]),
        label: 'Summary',
        path: getPathFromPageId(SUMMARY_PAGE),
        component: getParentRouteProxyFactory,
        hideMainMenu: ![ADMIN_USER, MANAGER_USER, EDITOR_USER].includes(user?.role_id),
        props: {
          pageId: SUMMARY_PAGE,
          hasViews: true,
          displayName: 'Portfolio 3D Dynamic',
        },
      },
      {
        label: 'Overview',
        path: getPathFromPageId(IDEAS_OVERVIEW_PAGE),
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[IDEAS_OVERVIEW_PAGE]),
        props: {
          pageId: IDEAS_OVERVIEW_PAGE,
          displayName: `Portfolio Overview`,
        },
      },
      {
        label: 'Personas',
        path: getPathFromPageId(PORTFOLIO_PERSONAS_PAGE),
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[PORTFOLIO_PERSONAS_PAGE]),
        props: {
          pageId: PORTFOLIO_PERSONAS_PAGE,
          displayName: `Personas`,
        },
      },
      {
        label: 'Lifecycles',
        path: getPathFromPageId(PORTFOLIO_LIFECYCLES_PAGE),
        component: getParentRouteProxyFactory,
        visible: () => canView(PERMISSION_PAGES[PORTFOLIO_LIFECYCLES_PAGE]),
        props: {
          pageId: PORTFOLIO_LIFECYCLES_PAGE,
          displayName: `Lifecycles`,
        },
      },
      {
        label: 'Canvas',
        path: getPathFromPageId(PORTFOLIO_CANVAS_PAGE),
        props: {
          pageId: 'ideasCanvas',
          hasViews: true,
          displayName: `Portfolio Canvas`,
        },
        visible: () => canView(PERMISSION_PAGES[PORTFOLIO_CANVAS_PAGE]),
        component: IdeasWhiteboard,
      },
    ],
    headerOptions: Ideas.HeaderOptions,
    headerFilters: Ideas.HeaderFilters,
    hideHeaderInviteUsersIcon: true,
  },
  {
    label: 'Goal',
    path: 'goals',
    icon: <ObjectiveIcon />,
    hideMenu: true,
    mode: GOAL_MODE,
    component: getParentRouteProxyFactory,
    // onMouseEnter: agGridPreload,
    subMenu: [
      {
        label: 'Goal Grid',
        path: getPathFromPageId(GOALS_GRID_PAGE),
        props: { pageId: GOALS_GRID_PAGE, hasViews: true, displayName: 'Goal List' },
        component: getParentRouteProxyFactory,
        mode: GOAL_MODE,
      },
      {
        label: 'Goal Summary',
        path: getPathFromPageId(GOALS_SUMMARY_PAGE),
        props: { pageId: GOALS_SUMMARY_PAGE, hasViews: true, displayName: 'Goal 3D Dynamic' },
        component: getParentRouteProxyFactory,
        hideMainMenu: ![ADMIN_USER, MANAGER_USER, EDITOR_USER].includes(user?.role_id),
        mode: GOAL_MODE,
      },
      {
        label: 'Goal Snapshot',
        path: getPathFromPageId(GOALS_SNAPSHOT_PAGE),
        props: { pageId: GOALS_SNAPSHOT_PAGE, hasViews: true, displayName: 'Goal Snapshot' },
        component: getParentRouteProxyFactory,
        mode: GOAL_MODE,
      },
      {
        label: 'Goal Allocation',
        path: getPathFromPageId(GOALS_ALLOCATION_PAGE),
        props: { pageId: GOALS_ALLOCATION_PAGE },
        component: getParentRouteProxyFactory,
        mode: GOAL_MODE,
        visible: () => canView(PERMISSION_PAGES[GOALS_ALLOCATION_PAGE]),
      },
      {
        label: 'Goal Canvas',
        path: getPathFromPageId(GOALS_CANVAS_PAGE),
        props: { pageId: GOALS_CANVAS_PAGE, hasViews: true, displayName: 'Canvas' },
        component: getParentRouteProxyFactory,
        mode: GOAL_MODE,
        visible: () => canView(PERMISSION_PAGES[GOALS_CANVAS_PAGE]),
      },
    ],
    visible: () => canView(PERMISSION_FEATURES.outcomeModule),
    headerOptions: Goals.HeaderOptions,
    headerFilters: Goals.HeaderFilters,
    hideHeaderInviteUsersIcon: true,
  },
  {
    label: 'Metrics',
    path: 'metrics',
    icon: <TrendingUp />,
    hideMenu: true,
    mode: METRICS_MODE,
    component: getParentRouteProxyFactory,
    // onMouseEnter: agGridPreload,
    subMenu: [
      {
        label: 'Metrics Grid',
        path: getPathFromPageId(METRICS_GRID_PAGE),
        props: { pageId: METRICS_GRID_PAGE, hasViews: true, displayName: 'Metrics List' },
        component: getParentRouteProxyFactory,
        mode: METRICS_MODE,
      },
      {
        label: 'Metrics Chart',
        path: getPathFromPageId(METRICS_CHART_PAGE),
        props: { pageId: METRICS_CHART_PAGE, hasViews: true },
        component: getParentRouteProxyFactory,
        mode: METRICS_MODE,
        visible: (_, organization) => organization.has_advanced_metric_reporting,
      },
      {
        label: 'Metrics Canvas',
        path: getPathFromPageId(METRICS_CANVAS_PAGE),
        props: { pageId: METRICS_CANVAS_PAGE, hasViews: true, displayName: 'Canvas' },
        component: getParentRouteProxyFactory,
        mode: GOAL_MODE,
        visible: () => canView(PERMISSION_PAGES[METRICS_CANVAS_PAGE]),
      },
    ],
    visible: () => canView(PERMISSION_FEATURES.metricsModule),
    hideHeaderInviteUsersIcon: true,
    headerOptions: Metrics.HeaderOptions,
    headerFilters: Metrics.HeaderFilters,
  },
  // {
  //   path: 'ideas/:view',
  //   hideMenu: true,
  //   component: Ideas,
  //   onMouseEnter: agGridPreload,
  // },
  // {
  //   path: 'ideas/:view/:gridView',
  //   hideMenu: true,
  //   component: Ideas,
  //   onMouseEnter: agGridPreload,
  // },
  {
    label: 'Forecast',
    path: 'forecast',
    icon: <Gallery />,
    component: getParentRouteProxyFactory,
    // onMouseEnter: ganttPreload,
    visible: () => canView(PERMISSION_FEATURES.forecastModule),
    headerOptions: Forecast.HeaderOptions,
    headerFilters: Forecast.HeaderFilters,
    subMenu: [
      {
        label: 'Schedule',
        path: getPathFromPageId(FORECAST_PAGE),
        props: {
          pageId: FORECAST_PAGE,
          hasViews: true,
          displayName: 'Forecast Schedule',
          showUncommittedProjectsToggle: true,
        },
        component: getParentRouteProxyFactory,
        // onMouseEnter: ganttPreload,
      },
      {
        label: 'List',
        path: getPathFromPageId(IDEAS_ESTIMATES_PAGE),
        props: { pageId: IDEAS_ESTIMATES_PAGE, hasViews: true, displayName: 'Forecast List' },
        component: getParentRouteProxyFactory,
      },
      {
        label: 'Staffing',
        path: getPathFromPageId(FORECAST_RESOURCES_PAGE),
        props: { pageId: FORECAST_RESOURCES_PAGE },
        component: getParentRouteProxyFactory,
        visible: (_, organization) => organization.has_staffing_page,
      },
      {
        label: 'Chart',
        path: getPathFromPageId(FORECAST_CHART_PAGE),
        props: { pageId: FORECAST_CHART_PAGE },
        component: getParentRouteProxyFactory,
      },
    ],
  },
  // legacy routes
  {
    label: 'Manage',
    path: 'manage',
    icon: <Gallery />,
    component: Manage,
    // onMouseEnter: ganttPreload,
    getPath: () => MANAGE_TIMELINE_PATH,
    visible: onlyVisibleForUsers(ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER),
    subMenu: [
      {
        label: 'Timeline',
        // onMouseEnter: ganttPreload,
        path: MANAGE_TIMELINE_PATH,
        component: Manage,
        props: { hasPortfolioOption: _checkHasPortfolioOptionOnPage(MANAGE_PAGE) },
      },
      {
        label: 'Staffing',
        path: MANAGE_RESOURCES_PATH,
        component: Staffing,
        visible: (_, organization) => organization.has_staffing_page,
      },
    ],
  },

  {
    label: 'Settings',
    path: 'settings',
    visible: () => canView(PERMISSION_FEATURES.settingsModule),
    component: Base,
    hideMenu: true,
    exact: false,
    headerFilters: SettingsHeaderFilters,
    headerOptions: SettingsHeaderOptions,
    hideHeaderInviteUsersIcon: true,
  },
  {
    label: 'User Preferences',
    path: 'user/preferences',
    visible: true,
    component: UserPreferences,
    hideMenu: true,
    exact: false,
    headerFilters: UserPreferencesHeaderFilters,
    hideMainMenu: [EXTERNAL_REQUESTOR].includes(user?.role_id),
    headerOptions: [EXTERNAL_REQUESTOR].includes(user?.role_id) && TinyMenu,
  },
  {
    label: 'Not Found',
    path: 'not-found',
    visible: true,
    component: UpsellPage,
    hideMenu: true,
    exact: true,
    headerFilters: UpsellFiltersComponent,
  },
];
