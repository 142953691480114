// External dependencies
import React, { Fragment } from 'react';
import styled from 'styled-components';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CircularProgress from '@material-ui/core/CircularProgress';

import ButtonIcon from 'design-system/molecules/ButtonIcon';
import TextDeprecated from 'design-system/atoms/TextDeprecated';
import GanttContainer from 'containers/GanttTimeline/GanttContainer';
import SearchBar from 'components/SearchBar';
import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';
import Text from 'design-system/atoms/Text';

import { GANTT_TIMELINE_PROJECTS_LIMIT } from 'constants/snapshot';
import { spacing } from 'design-system/theme';

const SnapshotTimelineComponent = ({
  search,
  setSearch,
  zoom,
  toggleZoom,
  loadingStories,
  groupByOptions,
  selectedGroup,
  setSelectedGroup,
  groupedBy,
  entityGroup,
  ganttRef,
  gantt,
  totalProjectsByLayer = 0,
  hideMaxItemDisplayMessage = false,
  isLoaded = false,
  onGanttContainerMounted,
}) => (
  <Fragment>
    <TooltipOnOverflow text={entityGroup?.title && `${entityGroup?.title}: ${groupedBy.title}`}>
      <PageTitle variant="h2" withEllipsis>
        {entityGroup?.title && `${entityGroup?.title}: ${groupedBy.title}`}
      </PageTitle>
    </TooltipOnOverflow>
    <ControlsContainer>
      <GroupAndLimitContainer>
        <SyledGroupByAutocomplete
          name="selectedGroup"
          suggestions={groupByOptions}
          value={selectedGroup}
          onChange={setSelectedGroup}
        />
        {isLoaded && !hideMaxItemDisplayMessage && totalProjectsByLayer > GANTT_TIMELINE_PROJECTS_LIMIT ? (
          <LimitResultsWarning breakwords>Display the first {GANTT_TIMELINE_PROJECTS_LIMIT} items</LimitResultsWarning>
        ) : null}
      </GroupAndLimitContainer>
      <Controls>
        {loadingStories && (
          <ControlItem>
            <LoadingContainer>
              <CircularProgress size={20} disableShrink /> <TextDeprecated>Loading Stories</TextDeprecated>
            </LoadingContainer>
          </ControlItem>
        )}
        <ControlItem>
          <SearchBar searchString={search} updateSearchString={setSearch} style={{ marginRight: 15 }} />
        </ControlItem>
        <ControlItem>
          <ButtonIcon title={zoom ? 'Zoom in' : 'Zoom out'} onClick={toggleZoom}>
            {zoom ? <ZoomInIcon /> : <ZoomOutIcon />}
          </ButtonIcon>
        </ControlItem>
      </Controls>
    </ControlsContainer>
    <GanttContainer
      height="calc(100vh - 230px)"
      ganttRef={ganttRef}
      gantt={gantt}
      onGanttContainerMounted={onGanttContainerMounted}
      pageControlsVisible
    />
  </Fragment>
);

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 26px 10px 40px;
`;

const GroupAndLimitContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex: 1;
  width: 0;
`;

const Controls = styled.div`
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  width: 0;
`;

const ControlItem = styled.div``;

const LoadingContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 50px;

  p {
    margin-left: 10px;
  }
`;

const PageTitle = styled(Text)`
  margin: 0 ${spacing(4)}px;
  text-align: center;
`;

const SyledGroupByAutocomplete = styled(GroupByAutocomplete)`
  width: 200px;
`;

const LimitResultsWarning = styled(TextDeprecated)`
  &&&& {
    padding-left: 10px;
    font-size: ${props => props.theme.typography.fontSizeSmall}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
  }
`;

export default SnapshotTimelineComponent;
