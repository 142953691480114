import { useMemo } from 'react';
import { isEmpty, omit, defaultTo } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';

import { getAllCycles, getAllDeliverables, getSelectedDeliverableStatusPDLCGrid } from 'store/cycleDeliverables/selectors';
import { PDLC, saveGridConfig } from 'store/grids';
import { getGridConfigValue } from 'store/grids/selectors';

import normalizeArray from 'utils/normalizeArray';

const defaultToEmptyObject = defaultTo({});
const defaultToEmptyArray = defaultTo([]);

const SHOW_MY_ITEMS_GRID_CONFIG = 'showMyItemsOnly';
const SELECTED_DELIVERABLE_STATUS_GRID_CONFIG = 'selectedDeliverableStatus';

const transformDeliverableStatus = (deliverableStatus = []) =>
  defaultToEmptyArray(deliverableStatus).reduce(
    (acc, statusId) => ({
      ...acc,
      [statusId]: true,
    }),
    {},
  );

const parseCycle = (cycle, selectedDeliverableStatus) => ({
  ...cycle,
  label: cycle.title,
  children: cycle.children?.map(deliverable => parseDeliverable(deliverable, selectedDeliverableStatus)),
});
const parseDeliverable = (deliverable, selectedDeliverableStatus) => ({
  ...deliverable,
  subMenuItems: parseStatuses(deliverable?.status),
  label: deliverable.title,
  selectedItems: transformDeliverableStatus(selectedDeliverableStatus[deliverable?.id]),
});

const parseStatus = ([statusId, data]) => ({ id: statusId, label: data.title, ...data });
const parseStatuses = status =>
  Object.entries(defaultToEmptyObject(status)).reduce((acc, statusEntry) => {
    return [...acc, parseStatus(statusEntry)];
  }, []);

const addDeliverableStatus = (currentDeliverableStatus, newStatusId) => [...currentDeliverableStatus, newStatusId];
const removeDeliverableStatus = (currentDeliverableStatus, statusId) =>
  currentDeliverableStatus.filter(status => status !== statusId);

const updateDeliverableFilters = (selectedDeliverableStatus, deliverableId, updatedDeliverableStatus) => ({
  ...selectedDeliverableStatus,
  [deliverableId]: updatedDeliverableStatus,
});

const removeDeliverableFilters = (selectedDeliverableStatus, deliverableId) => {
  const updated = omit([deliverableId], selectedDeliverableStatus);

  if (isEmpty(updated)) {
    return null;
  }
  return updated;
};

/**
 * Hook that handles the filtering options of the user in the new local filter dropdown
 * My Items and Filter by delivery status are mutually exclusive
 * @return {{cycleDeliverableDropdownOptions: *}}
 */
const usePDLCLocalFilter = () => {
  const dispatch = useDispatch();
  const cycles = useSelector(getAllCycles);
  const deliverables = useSelector(getAllDeliverables);
  const showMyItemsOnly = useSelector(state => getGridConfigValue(state, PDLC, SHOW_MY_ITEMS_GRID_CONFIG));
  const selectedDeliverableStatus = useSelector(getSelectedDeliverableStatusPDLCGrid);
  const normalizedDeliverables = useMemo(() => normalizeArray(deliverables, 'id'), [deliverables]);

  const cycleDeliverableDropdownOptions = useMemo(
    () => cycles.map(cycle => parseCycle(cycle, selectedDeliverableStatus)),
    [cycles, selectedDeliverableStatus],
  );
  const _updateGridConfig = (key, value) => dispatch(saveGridConfig(PDLC, key, value));

  const onClickMyItems = () => {
    _updateGridConfig(SHOW_MY_ITEMS_GRID_CONFIG, !showMyItemsOnly);
    _updateGridConfig(SELECTED_DELIVERABLE_STATUS_GRID_CONFIG, null);
  };

  const handleClearFilters = () => {
    _updateGridConfig(SHOW_MY_ITEMS_GRID_CONFIG, false);
    _updateGridConfig(SELECTED_DELIVERABLE_STATUS_GRID_CONFIG, null);
  };

  const handleStatusClick = (deliverableId, statusId, selected) => {
    const currentDeliverableStatus = defaultToEmptyArray(selectedDeliverableStatus[deliverableId]);

    const updatedDeliverableStatus = selected
      ? addDeliverableStatus(currentDeliverableStatus, statusId)
      : removeDeliverableStatus(currentDeliverableStatus, statusId);

    const hasNoStatusSelected = isEmpty(updatedDeliverableStatus);

    const newSelectedDeliverableStatus = hasNoStatusSelected
      ? removeDeliverableFilters(selectedDeliverableStatus, deliverableId)
      : updateDeliverableFilters(selectedDeliverableStatus, deliverableId, updatedDeliverableStatus);

    if (showMyItemsOnly) {
      _updateGridConfig(SHOW_MY_ITEMS_GRID_CONFIG, false);
    }
    _updateGridConfig(SELECTED_DELIVERABLE_STATUS_GRID_CONFIG, newSelectedDeliverableStatus);
  };

  const selectAllStatus = deliverableId => {
    const deliverableAvailableStatus = normalizedDeliverables[deliverableId]?.status || {};

    const updatedDeliverableStatus = Object.keys(deliverableAvailableStatus).reduce((acc, status) => [...acc, status], []);
    const newSelectedDeliverableStatus = updateDeliverableFilters(
      selectedDeliverableStatus,
      deliverableId,
      updatedDeliverableStatus,
    );

    if (showMyItemsOnly) {
      _updateGridConfig(SHOW_MY_ITEMS_GRID_CONFIG, false);
    }

    _updateGridConfig(SELECTED_DELIVERABLE_STATUS_GRID_CONFIG, newSelectedDeliverableStatus);
  };

  const clearAllStatus = deliverableId => {
    const newSelectedDeliverableStatus = removeDeliverableFilters(selectedDeliverableStatus, deliverableId);

    if (showMyItemsOnly) {
      _updateGridConfig(SHOW_MY_ITEMS_GRID_CONFIG, false);
    }
    _updateGridConfig(SELECTED_DELIVERABLE_STATUS_GRID_CONFIG, newSelectedDeliverableStatus);
  };

  return {
    cycleDeliverableDropdownOptions,
    showMyItemsOnly,
    selectedDeliverableStatus,

    onClickMyItems,
    handleClearFilters,
    handleStatusClick,
    selectAllStatus,
    clearAllStatus,
  };
};

export default usePDLCLocalFilter;
