import React, { useCallback, useMemo, memo } from 'react';
import styled from 'styled-components';

import { spacing } from 'design-system/theme';
import AccordionGroup from 'design-system/organisms/AccordionGroup/index';
import Droppable from 'design-system/molecules/Droppable/index';

import { makeProjectId, computeGroupId } from 'routes/Dashboard/Summary/helpers';

import CellProject from './CellProject';

const defaultInnerGroupTitleGenerator = (group, groupProjects) => `${group.title} (${groupProjects.length})`;

const CellGroup = memo(
  ({
    rowId,
    colId,
    group,
    groupProjects,
    isReadOnly,
    displayFields,
    selectedColData,
    selectedRowData,
    localSearchStr,
    showGroupColor,
    disableAdd,
    onToggleGroup,
    handleAddNewProject,
    showNewCard,
    renderNewCard,
    isExpanded,
    onProjectClick,
    isLast,
    draggingProjectId,
    setDraggingProjectId,
    allProjectsInfo,
    expandedGroups,
    isGrouping,
    isGoalMode,
    isAnonymousUser,
    getInlineGroupTitle = defaultInnerGroupTitleGenerator,
  }) => {
    const groupId = useMemo(() => computeGroupId(rowId, colId, group.id), [rowId, colId, group]);

    const handleToggleGroup = useCallback(() => {
      onToggleGroup(groupId, !isExpanded);
    }, [groupId, isExpanded]);

    const _renderProjects = () =>
      groupProjects.map(({ project }, index) => {
        const dragId = makeProjectId(rowId, colId, group?.id, project.id);

        return (
          <CellProject
            key={project.id}
            dragId={dragId}
            project={project}
            isReadOnly={isReadOnly}
            displayFields={displayFields}
            selectedColData={selectedColData}
            selectedRowData={selectedRowData}
            localSearchStr={localSearchStr}
            onProjectClick={onProjectClick}
            index={index}
            draggingProjectId={draggingProjectId}
            setDraggingProjectId={setDraggingProjectId}
            allProjectsInfo={allProjectsInfo}
            expandedGroups={expandedGroups}
            isGrouping={isGrouping}
            isGoalMode={isGoalMode}
            isAnonymousUser={isAnonymousUser}
          />
        );
      });

    return (
      <DroppableRegion key={`group*${groupId}`} id={`group*${groupId}`} allowFlexGrow={false} isReadOnly={isReadOnly} flexHeight>
        <AccordionGroup
          id={groupId}
          color={showGroupColor ? group.color : null}
          title={getInlineGroupTitle(group, groupProjects)}
          isExpandedDefault={isExpanded}
          hideAddButton={isReadOnly || disableAdd}
          onToggle={handleToggleGroup}
          showGroupColor={showGroupColor}
          onAddNewProject={handleAddNewProject({
            rowId,
            colId,
            groupId: group.id,
            placement: isExpanded ? 'accordion' : 'bottom',
          })}
        >
          <CardsContainer padded={isReadOnly}>
            {showNewCard(rowId, colId, 'accordion', group.id) && renderNewCard()}
            {_renderProjects()}
          </CardsContainer>
        </AccordionGroup>
        {showNewCard(rowId, colId, 'bottom', group.id) && renderNewCard()}
      </DroppableRegion>
    );
  },
);

CellGroup.displayName = 'CellGroup';

const CardsContainer = styled.div`
  ${({ padded }) => {
    if (padded) {
      return `
        padding: ${spacing(1.5)}px;
      `;
    }
  }}
`;

export default CellGroup;

const DroppableRegion = styled(Droppable)`
  order: ${({ asLast }) => (asLast ? 1 : 0)};
  &&&& {
    padding-bottom: ${({ isReadOnly }) => isReadOnly && '0'};
  }
`;

DroppableRegion.displayName = 'GroupDroppableRegion';
