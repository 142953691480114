import React from 'react';

import ShowMaterialColor from 'design-system/atoms/ShowMaterialColor/ShowMaterialColor';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const ColorCellRenderer = props => (
  <Wrapper>
    <ShowMaterialColor {...props} />
  </Wrapper>
);

export default ColorCellRenderer;
