import React from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment-timezone';
import Warning from '@material-ui/icons/Warning';
import isEmpty from 'lodash/isEmpty';
import Highlighter from 'react-highlight-words';

import { getUserName, getProgressNumber } from 'utils';
import { getColor, GANTT_OFFSETS, getTotalCost } from 'components/ganttCommon';
import ProjectType from 'containers/ProjectType';
import invertedTextColor from 'design-system/utils/invertedTextColor';
import { materialColors } from 'design-system/themes/default';
import { spacing } from 'design-system/theme';
import formatDate from 'utils/dates/formatDate';
import getProjectStatusColor from 'store/projects/helpers/getProjectStatusColor';
import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import formatNumber from 'utils/formatNumber';
import { getProjectKeyLabel } from 'utils/projects/getProjectKey';

const getText = (project, showId, showIntegrationKey, displayAlerts, options = {}) => {
  const { searchStr } = options;
  const projectKey = getProjectKeyLabel(project);
  let text = showId ? `${projectKey} ` : '';

  const _getIntegrationLink = integration => `<a href="${integration.url}" target="_blank">${integration.key}</a>`;

  const { integration } = project;

  text = showIntegrationKey && integration ? `${text} ${_getIntegrationLink(integration)} ` : text;

  const { integrationProgress, deadline } = project;
  const intEndDate = integrationProgress && integrationProgress.endDate;
  let alert = '';
  let icon = '';

  if (project.layer) {
    icon = `${ReactDOMServer.renderToString(
      <ProjectType
        style={{
          ...(project.type === 'milestone' ? { transform: 'translateY(1px)', marginRight: `${spacing(0.5)}px` } : {}),
        }}
        layer={project.layer}
        isMilestone={project.type === 'milestone'}
      />,
    )}`;
  }

  if (displayAlerts) {
    const predictedEndDate = moment(intEndDate);
    // eslint-disable-next-line max-len
    const title = `Target end date ${moment(deadline).format('YYYY-MM-DD')} \n Predicted end date ${predictedEndDate.format(
      'YYYY-MM-DD',
    )} (based on progress)`;
    const statusColorByIntegration = getProjectStatusColor(project);

    if (statusColorByIntegration === 'Yellow') {
      alert = ReactDOMServer.renderToString(
        <div title={title} className="gantt__projectWarning">
          <Warning />
        </div>,
      );
    } else if (statusColorByIntegration === 'Red') {
      alert = ReactDOMServer.renderToString(
        <div title={title} className="gantt__projectWarning">
          <Warning style={{ color: materialColors.red }} />
        </div>,
      );
    }
  }

  let { title } = project;

  if (searchStr) {
    title = ReactDOMServer.renderToString(
      <Highlighter
        className="hightlightWrapper"
        highlightClassName="hightlightText"
        searchWords={[searchStr]}
        autoEscape
        textToHighlight={title}
      />,
    );
  }

  return alert + icon + text + title;
};

/**
 * Check is a Bet or Initiative that should have group timeline.
 *
 * @param {*} project The project to be evaluated
 * @param {*} options The Gantt options
 * @returns true if the project is a group timeline parent
 */
const isGroupTimelineParent = (project, options) =>
  options?.portfolioMode &&
  options?.showGroupedTimeline &&
  [BET_LAYER, INITIATIVE_LAYER].includes(project.layer) &&
  project?.children.some(
    child => !!child.start_date || (child?.children && child.children.some(grandChild => !!grandChild?.start_date)),
  );

const getProjectRenderedType = (project, options) => {
  const { showTasks, showEstimates, showStories, useTaskOnConfirmedIdeas, showGroupedTimeline, portfolioMode } = options;

  if (project.type === 'milestone') return 'milestone';
  const hasEstimates = project.estimates && project.estimates.length;
  const hasTasks = project.tasks && project.tasks.length;
  let type = 'task';

  if (showStories && project.layer === IDEA_LAYER) return 'task';

  if (
    (showTasks && hasTasks) ||
    (showEstimates && hasEstimates) ||
    (!showTasks && hasTasks && useTaskOnConfirmedIdeas && project.planningStage === 'Confirmed')
  ) {
    type = 'project';
  }

  if (
    portfolioMode &&
    showGroupedTimeline &&
    [BET_LAYER, INITIATIVE_LAYER].includes(project.layer) &&
    project.children?.length &&
    project.childrenCountFiltered &&
    project.childrenCountFiltered > 0
  ) {
    type = 'task';
  }

  if (isGroupTimelineParent(project, options)) {
    type = 'project';
  }

  return type;
};

export const getProjectParent = project => {
  let parent = '';

  if (isEmpty(project.parentGroups)) {
    return 0;
  }

  project.parentGroups.forEach(group => {
    const groupKey = group?.groupOption?.key?.startsWith('custom_fields.') ? 'customField' : group?.groupOption?.key;
    const groupField = group?.groupOption?.field;
    const isDropdownCustomField = groupKey === 'customField';
    const groupId =
      isDropdownCustomField || ['status_color', 'planningStage', 'tags', 'customers'].includes(groupField)
        ? group?.groupData?.[groupField]?.id
        : group?.groupData?.[groupField];

    if (
      (groupKey === 'bet' && project?.parentLayer !== BET_LAYER) ||
      (groupKey === 'initiative' && project?.parentLayer !== INITIATIVE_LAYER)
    ) {
      parent = `${GANTT_OFFSETS[groupKey]}null${parent}`;
    } else if (groupId) {
      parent = `${GANTT_OFFSETS[groupKey] + groupId}${parent}`;
    } else {
      parent = `${GANTT_OFFSETS[groupKey]}null${parent}`;
    }
  });

  return parent;
};

const getProjectProgress = project => {
  const { progress } = project;
  let timeEstimated = 0;
  let storyPoints = 0;
  let predictedEndDate = project.predictedEndDate || '';
  let issuesTotal = 0;

  const recursiveSum = (obj, key) => {
    if (obj?.integrationProgress?.[key]) {
      return obj?.integrationProgress?.[key];
    }

    if (obj.children && obj.children.length) {
      return obj.children.reduce((a, b) => a + (recursiveSum(b, key) || 0), 0);
    }

    return 0;
  };

  if (!predictedEndDate && project.integrationProgress) {
    predictedEndDate = project.integrationProgress.endDate;
    ({ issuesTotal } = project.integrationProgress);
  } else {
    issuesTotal = recursiveSum(project, 'issuesTotal');
  }

  storyPoints = recursiveSum(project, 'pointsTotal');
  timeEstimated = recursiveSum(project, 'timeEstimated');

  return {
    progressOriginal: progress,
    progressGridValue: getProgressNumber(progress),
    progressPercent: getProgressNumber(progress),
    storyPoints: formatNumber(storyPoints),
    timeEstimated,
    predictedEndDate: formatDate(predictedEndDate),
    issuesTotal,
  };
};

/**
 * Function that parses the project object from the store to the gantt desired format
 *
 * @param {*} project project object
 * @param {*} teams teams from the store
 * @param {*} options object with options
 */
const parseGanttProject = (project, teams, options, orgIntegration) => {
  const {
    showIntegrationKey,
    showId,
    collapseGanttBars,
    displayAlerts,
    portfolioMode,
    // showStories,
    showGroupedTimeline,
  } = options;

  const colorBy = options.selectedColorBy ? options.selectedColorBy.key : 'roadmap';
  const color = getColor(project, null, colorBy);

  const useCalculatedDates =
    project.childrenCountFiltered && project.childrenCountFiltered > 0 && portfolioMode && showGroupedTimeline;

  // const usePredictedCalculatedDates =
  //   project.childrenCountFiltered && project.childrenCountFiltered > 0 && showStories && showGroupedTimeline;

  let estimatedStartDate = project.estimated_start_date;
  let estimatedEndDate = project.deadline;

  const hasSomeTargetDate = estimatedStartDate || estimatedEndDate;

  // if (usePredictedCalculatedDates) {
  //   estimatedStartDate = project.predicted_start_date_calculated || project.estimated_start_date;
  //   estimatedEndDate = project.predicted_end_date_calculated || project.deadline;
  // } else
  if (!hasSomeTargetDate || useCalculatedDates) {
    estimatedStartDate = project.start_date_calculated || project.estimated_start_date;
    estimatedEndDate = project.end_date_calculated || project.deadline;
  }

  if (isGroupTimelineParent(project, options)) {
    estimatedStartDate = estimatedStartDate ?? new Date();
  }

  return {
    ...project,
    id: project.id,
    entityId: project.id,
    type: getProjectRenderedType(project, options),
    dbType: 'project',
    parent: getProjectParent(project),
    parentTitle: project.parent,
    text: getText(project, showId, showIntegrationKey, displayAlerts, options),
    shortText: project.title,
    ownerName: getUserName(project.owner),
    status_color: project.status_color || '',
    textColor: invertedTextColor(color, true, '#ccc', '#111'),
    color,
    estimates: project.estimates || [],
    tasks: project.tasks || [],
    objectives: project.objectives || [],
    roadmapTitle: project.roadmapTitle || '',
    ...getProjectProgress(project),
    totalCost: getTotalCost(project.estimates, teams),
    open: false,
    render: collapseGanttBars ? 'split' : '',
    $has_child: options.portfolioMode && project.childrenCountFiltered,
    estimatedStartDate: formatDate(estimatedStartDate),
    estimatedEndDate: formatDate(estimatedEndDate),
    ganttStartDate: estimatedStartDate,
    ganttEndDate: estimatedEndDate,
    $committed: project.committed,
  };
};

export default parseGanttProject;
