import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

import Text from 'design-system/atoms/Text/index';
import Button from 'design-system/atoms/Button/index';

import Stars from 'design-system/atoms/DragonIcons/Stars';
import { spacing } from 'design-system/theme';

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${spacing(10)}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${`0 ${spacing(6)}px`};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2)}px;
`;

const StyledStarsIcon = styled(Stars)`
  width: 27px;
  height: 27px;

  path {
    fill: ${({ theme }) => theme.palette.white};
  }
`;

const StyledText = styled(Text)`
  &&&& {
    color: ${({ theme }) => theme.palette.white};
    font-size: ${({ theme }) => theme.typography.fontSizeH4}px;

    b {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
      margin-left: ${spacing()}px;
    }
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    text-transform: uppercase;
    border: ${({ theme }) => `1px solid ${theme.palette.white}`};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.white};
`;

const GridWrapper = styled.div`
  padding: ${spacing(2)}px;

  .ag-layout-normal.ag-root {
    margin: 0;
  }
`;

export { HeaderWrapper, Flex, StyledStarsIcon, StyledText, StyledButton, StyledCloseIcon, GridWrapper };
