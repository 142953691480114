import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_USER_VIEWS'),
  ...getThunkActionTypes('FETCH_USER_TEAM_VIEWS'),
  ...getThunkActionTypes('CREATE_USER_VIEW'),
  ...getThunkActionTypes('UPDATE_USER_VIEW'),
  ...getThunkActionTypes('CLONE_USER_VIEW'),
  ...getThunkActionTypes('SHARE_VIEW_ON_SLACK'),
  ...getThunkActionTypes('SHARE_VIEW_ON_INTEGRATION'),

  SAVE_USER_VIEW: 'SAVE_USER_VIEW', // TODO deprecate into individual actions create/update
  SAVE_USER_VIEW_FULFILLED: 'SAVE_USER_VIEW_FULFILLED',
  DELETE_USER_VIEW: 'DELETE_USER_VIEW',
  DELETE_USER_VIEW_FULFILLED: 'DELETE_USER_VIEW_FULFILLED',
  SET_ACTIVE_USER_VIEW: 'SET_ACTIVE_USER_VIEW', // TODO deprecate
  SET_ACTIVE_USER_VIEW_ONLOAD: 'SET_ACTIVE_USER_VIEW_ONLOAD', // TODO deprecate
  SET_PAGE_ACTIVE_VIEW: 'SET_PAGE_ACTIVE_VIEW',
  UPDATE_PAGE_ACTIVE_VIEW_DATA: 'UPDATE_PAGE_ACTIVE_VIEW_DATA',
  SAVE_PAGE_ACTIVE_VIEW: 'SAVE_PAGE_ACTIVE_VIEW',
  CLEAR_PAGE_ACTIVE_VIEW: 'CLEAR_PAGE_ACTIVE_VIEW',
  SUBSCRIBE_USER_TO_USER_VIEW: 'SUBSCRIBE_USER_TO_USER_VIEW',
  SUBSCRIBE_USER_TO_USER_VIEW_FULFILLED: 'SUBSCRIBE_USER_TO_USER_VIEW_FULFILLED',
  SHARE_USER_VIEW: 'SHARE_USER_VIEW',
  SHARE_USER_VIEW_FULLFILLED: 'SHARE_USER_VIEW_FULLFILLED',
  UPDATE_USER_VIEW_BY_REALTIME: 'UPDATE_USER_VIEW_BY_REALTIME',
  ADD_USER_VIEW_FAVORITE: 'ADD_USER_VIEW_FAVORITE',
  ADD_USER_VIEW_FAVORITE_FULFILLED: 'ADD_USER_VIEW_FAVORITE_FULFILLED',
  DELETE_USER_VIEW_FAVORITE: 'DELETE_USER_VIEW_FAVORITE',
  DELETE_USER_VIEW_FAVORITE_FULFILLED: 'DELETE_USER_VIEW_FAVORITE_FULFILLED',
  CREATE_USER_VIEW_PUBLIC_LINK: 'CREATE_USER_VIEW_PUBLIC_LINK',
  CREATE_USER_VIEW_PUBLIC_LINK_FULFILLED: 'CREATE_USER_VIEW_PUBLIC_LINK_FULFILLED',
  UPDATE_USER_VIEW_PUBLIC_LINK: 'UPDATE_USER_VIEW_PUBLIC_LINK',
  UPDATE_USER_VIEW_PUBLIC_LINK_FULFILLED: 'UPDATE_USER_VIEW_PUBLIC_LINK_FULFILLED',
  UPDATE_USER_VIEW_DIALOG_PROPERTIES: 'UPDATE_USER_VIEW_DIALOG_PROPERTIES',
  DELETE_USER_VIEW_PUBLIC_LINK: 'DELETE_USER_VIEW_PUBLIC_LINK',
  DELETE_USER_VIEW_PUBLIC_LINK_FULFILLED: 'DELETE_USER_VIEW_PUBLIC_LINK_FULFILLED',
  FETCH_PUBLIC_USER_VIEW: 'FETCH_PUBLIC_USER_VIEW',
  FETCH_PUBLIC_USER_VIEW_FULFILLED: 'FETCH_PUBLIC_USER_VIEW_FULFILLED',
  FETCH_PUBLIC_USER_VIEW_REJECTED: 'FETCH_PUBLIC_USER_VIEW_REJECTED',
  RESET_USER_VIEW_DIALOG_PROPERTIES: 'RESET_USER_VIEW_DIALOG_PROPERTIES',
  TOGGLE_VIEWS_DIALOG: 'TOGGLE_VIEWS_DIALOG',
  UPDATE_USER_VIEW_OWNER: 'UPDATE_USER_VIEW_OWNER',
  UPDATE_USER_VIEW_OWNER_FULFILLED: 'UPDATE_USER_VIEW_OWNER_FULFILLED',
  UPDATE_USER_VIEW_OWNER_ALL_VIEWS: 'UPDATE_USER_VIEW_OWNER_ALL_VIEWS',
};

export const {
  FETCH_USER_VIEWS,
  FETCH_USER_VIEWS_PENDING,
  FETCH_USER_VIEWS_FULFILLED,
  FETCH_USER_VIEWS_REJECTED,
  FETCH_USER_TEAM_VIEWS,
  FETCH_USER_TEAM_VIEWS_PENDING,
  FETCH_USER_TEAM_VIEWS_FULFILLED,
  FETCH_USER_TEAM_VIEWS_REJECTED,
  SAVE_USER_VIEW,
  SAVE_USER_VIEW_FULFILLED,
  DELETE_USER_VIEW,
  DELETE_USER_VIEW_FULFILLED,
  SET_ACTIVE_USER_VIEW,
  SET_ACTIVE_USER_VIEW_ONLOAD,
  SET_PAGE_ACTIVE_VIEW,
  UPDATE_PAGE_ACTIVE_VIEW_DATA,
  CLEAR_PAGE_ACTIVE_VIEW,
  CREATE_USER_VIEW,
  CREATE_USER_VIEW_FULFILLED,
  UPDATE_USER_VIEW,
  UPDATE_USER_VIEW_FULFILLED,
  CLONE_USER_VIEW,
  CLONE_USER_VIEW_FULFILLED,
  SUBSCRIBE_USER_TO_USER_VIEW,
  SUBSCRIBE_USER_TO_USER_VIEW_FULFILLED,
  SHARE_USER_VIEW,
  SHARE_USER_VIEW_FULLFILLED,
  UPDATE_USER_VIEW_BY_REALTIME,
  ADD_USER_VIEW_FAVORITE,
  ADD_USER_VIEW_FAVORITE_FULFILLED,
  DELETE_USER_VIEW_FAVORITE,
  DELETE_USER_VIEW_FAVORITE_FULFILLED,
  CREATE_USER_VIEW_PUBLIC_LINK,
  CREATE_USER_VIEW_PUBLIC_LINK_FULFILLED,
  UPDATE_USER_VIEW_PUBLIC_LINK,
  UPDATE_USER_VIEW_PUBLIC_LINK_FULFILLED,
  UPDATE_USER_VIEW_DIALOG_PROPERTIES,
  DELETE_USER_VIEW_PUBLIC_LINK,
  DELETE_USER_VIEW_PUBLIC_LINK_FULFILLED,
  FETCH_PUBLIC_USER_VIEW,
  FETCH_PUBLIC_USER_VIEW_FULFILLED,
  FETCH_PUBLIC_USER_VIEW_REJECTED,
  RESET_USER_VIEW_DIALOG_PROPERTIES,
  TOGGLE_VIEWS_DIALOG,
  SHARE_VIEW_ON_SLACK,
  UPDATE_USER_VIEW_OWNER,
  UPDATE_USER_VIEW_OWNER_FULFILLED,
  UPDATE_USER_VIEW_OWNER_ALL_VIEWS,
  SHARE_VIEW_ON_INTEGRATION,
} = actionTypes;

export default actionTypes;
