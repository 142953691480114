import { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { modifyPath, head, pick } from 'ramda';

const calculateCounts = (openItems = {}) => {
  return Object.entries(openItems).reduce((acc, [key, value]) => {
    const hasChildren = value?.childrenKeys?.length;

    let count = 1;

    if (hasChildren && value.open) {
      const childrenUpdated = calculateCounts(pick(value?.childrenKeys, value));

      value.childrenKeys.forEach(childKey => {
        count += childrenUpdated[childKey]?.count;
      });

      return {
        ...acc,
        [key]: {
          ...value,
          ...childrenUpdated,
          count,
        },
      };
    }
    return {
      ...acc,
      [key]: {
        ...value,
        count,
      },
    };
  }, {});
};

/*
 *
 * */
const calculateOpenState = (items = [], hasGroupedItems = false, prevState = {}) => {
  if (!hasGroupedItems) {
    return {};
  }
  return items.reduce((acc, item) => {
    const currentPrevState = prevState[item?.id];
    const defaultIsOpen = item?.openByDefault || false;
    const childrenState = item?.children?.length ? calculateOpenState(item?.children, hasGroupedItems, currentPrevState) : {};

    // todo: maybe we need to think a better way to reset the open state, should we have a second control bool
    //  to be used on the element. For example have a 'forceOpen' this would allow to bypass the open state without
    //  overriding the previous state
    const isOpen = currentPrevState ? currentPrevState?.open : defaultIsOpen;

    const option = calculateCounts({
      [item?.id]: {
        open: isOpen,
        childrenKeys: Object.keys(childrenState),
        ...childrenState,
      },
    });

    return {
      ...acc,
      ...option,
    };
  }, {});
};

const updateExpandCollapse = (openItems, open) =>
  Object.entries(openItems).reduce((acc, [key, value]) => {
    const hasChildren = value?.childrenKeys?.length;
    const childrenUpdated = hasChildren ? updateExpandCollapse(pick(value?.childrenKeys, value), open) : {};
    let count = 1;

    if (open) {
      value.childrenKeys.forEach(childKey => {
        count += childrenUpdated[childKey]?.count;
      });
    }

    return {
      ...acc,
      [key]: {
        ...value,
        ...childrenUpdated,
        count,
        open,
      },
    };
  }, {});

const useOpenItems = ({ items, registerExpandAllCallback, registerCollapseAllCallback }) => {
  const hasGroupedItems = useMemo(() => {
    return items?.some(item => !!item?.children?.length);
  }, [items]);
  const [openItems, setOpenItems] = useState(() => calculateOpenState(items, hasGroupedItems));
  const mainListRef = useRef();

  const onOpen = (path = [], isOpen = false) => {
    if (!hasGroupedItems) return;
    const firstNodePath = head(path);
    const updatedTree = modifyPath(path, val => ({ ...val, open: isOpen }), openItems);
    const treeToUpdate = { [firstNodePath]: updatedTree[firstNodePath] };

    const updatedTreeCountState = calculateCounts(treeToUpdate);

    setOpenItems({
      ...openItems,
      ...updatedTreeCountState,
    });

    mainListRef?.current?.resetAfterIndex(0, true);
  };

  useEffect(() => {
    // If the children are lazyloaded or items changes do tue some toggle/filter option
    // we need to re calculate open state based on the total children and
    // preserving previous open state so the list height is properly adjusted
    setOpenItems(prevOpenItems => calculateOpenState(items, hasGroupedItems, prevOpenItems));
    mainListRef?.current?.resetAfterIndex(0, true);
  }, [items]); // how could we avoid the memo based on the items?

  const onExpandAll = useCallback(() => {
    setOpenItems(prevState => updateExpandCollapse(prevState, true));
    mainListRef?.current?.resetAfterIndex(0);
  }, []);

  const onCollapseAll = useCallback(() => {
    setOpenItems(prevState => updateExpandCollapse(prevState, false));
    mainListRef?.current?.resetAfterIndex(0);
  }, []);

  useEffect(() => {
    if (registerExpandAllCallback && onExpandAll) {
      registerExpandAllCallback(onExpandAll);
    }
  }, [registerExpandAllCallback, onExpandAll]);

  useEffect(() => {
    if (registerCollapseAllCallback && onCollapseAll) {
      registerCollapseAllCallback(onCollapseAll);
    }
  }, [registerCollapseAllCallback, onCollapseAll]);

  return { openItems, onOpen, mainListRef };
};

export default useOpenItems;
