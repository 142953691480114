// External dependencies
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';

// Dragonboat imports
import { log } from 'utils';
import { setIsMetadataFetched as setIsAppMetadataFetched } from 'store/app';
import { fetchUserFilters } from 'store/filters';
import { fetchOrganization, fetchOrgIntegrations } from 'store/organization/actions';
import { fetchUserIntegrations, getCurrentUser as getAuthenticatedUser } from 'store/login/actions';
import { fetchNotifications } from 'store/notifications';
import { fetchUserViews, fetchUserTeamViews } from 'store/userViews';
import useLoadPageMetadata from 'hooks/useLoadPageMetadata';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useInterval from 'hooks/useInterval';
import useIntegrationsAuthenticationErrors from 'hooks/integrations/useIntegrationsAuthenticationErrors';
import { ALL_METADATA } from 'constants/common';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';

import { fetchOrganizationAccessControl } from 'store/accessControl/thunks';
import { getCurrentUser } from 'store/login/selectors';
import { fetchAnnouncements, fetchUnreadAnnouncements } from 'store/announcements/thunks';
import { checkIfUserIsExternalRequestor } from 'containers/UserPermission/utils';
import checkIfUserIsAdmin from 'utils/checkIfUserIsAdmin';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { fetchTemplates } from 'store/templates';
import useOnboardingDemo from 'hooks/onboarding/useOnboardingDemo';

import { fetchAvailableIntegrations } from 'features/OneStepIntegration/store';
import { throwWithProductionFallback } from 'utils/errorHandling';

const DEFAULT_FETCH_READER_TOKENS_INTERVAL = 86400000; // 1 day

// Backup the original sort method
const originalSort = Array.prototype.sort;

// eslint-disable-next-line
Array.prototype.sort = function (compareFn) {
  try {
    return originalSort.call(this, compareFn);
  } catch (err) {
    return throwWithProductionFallback(err, () => originalSort.call([...this], compareFn));
  }
};

export default ({ children, ignore }) => {
  const dispatch = useDispatch();
  const [started, setStarted] = useState(false);
  const [isMetadataFetched, setIsMetadataFetched] = useState(false);
  const isAuthenticated = useSelector(state => state.login.isAuthenticated);
  const currentUser = useSelector(getCurrentUser);

  const { canCreate } = usePermissions();
  const { receiveResourceCenterMessage } = useOnboardingDemo();

  const { hasFetchedOrganizationAccessControl, createChildOrganizationsReaderTokens } = useOrganizationsAccessControl();

  const loadMetadata = useLoadPageMetadata(ALL_METADATA);

  const fetchAnnouncementAction = useMemo(() => {
    if (currentUser && checkIfUserIsExternalRequestor(currentUser)) {
      return;
    }

    if (checkIfUserIsAdmin(currentUser)) {
      return fetchAnnouncements;
    }

    return fetchUnreadAnnouncements;
  }, [currentUser]);

  useIntegrationsAuthenticationErrors();

  useEffect(() => {
    window.addEventListener('message', receiveResourceCenterMessage, false);

    return () => window.removeEventListener('message', receiveResourceCenterMessage);
  }, []);

  useEffect(() => {
    /* TODO: Ensure that metadata is loaded after loading the organizationAccessControl since it influences if we
     * skip cache or not
     */

    if (isAuthenticated && hasFetchedOrganizationAccessControl && !isMetadataFetched) {
      loadMetadata().then(() => dispatch(setIsAppMetadataFetched(true)));
      setIsMetadataFetched(true);
    }
  }, [isAuthenticated, hasFetchedOrganizationAccessControl, isMetadataFetched]);

  useEffect(() => {
    if (!ignore) {
      dispatch(getAuthenticatedUser());
    }
  }, []);

  useEffect(() => {
    createChildOrganizationsReaderTokens();
  }, [hasFetchedOrganizationAccessControl]);

  // Make sure the child organization tokens are renewed after createChildOrganizationReaderTokensTimeout time
  const createChildOrganizationReaderTokensTimeout = canCreate(PERMISSION_FEATURES.childOrganizationReaders)
    ? DEFAULT_FETCH_READER_TOKENS_INTERVAL
    : null;

  useInterval(() => createChildOrganizationsReaderTokens(), createChildOrganizationReaderTokensTimeout);

  useEffect(() => {
    const init = async () => {
      log('[DB-CORE] 🚀 Booting up ', 'cornflowerblue');
      const [slug] = window.location.hostname.split('.');

      if (isAuthenticated) {
        const organization = await dispatch(fetchOrganization());
        const sameOrg = organization && organization.slug === slug;

        if (sameOrg) {
          const loadFiltersAndViews = dispatch(fetchUserFilters()).then(() => dispatch(fetchUserViews()));

          await Promise.all([
            loadFiltersAndViews,
            dispatch(fetchUserTeamViews()),
            dispatch(fetchNotifications()),
            dispatch(fetchOrgIntegrations()),
            dispatch(fetchUserIntegrations()),
            dispatch(fetchOrganizationAccessControl()),
            dispatch(fetchTemplates()),
            dispatch(fetchAvailableIntegrations()),
            ...(fetchAnnouncementAction ? [dispatch(fetchAnnouncementAction())] : []),
          ]);
        }
      }

      if (window.location !== window.parent.location && !ignore) {
        setTimeout(() => {
          setStarted(true);
        }, parseInt(Math.random() * 1000));
      } else {
        setStarted(true);
      }
    };

    if (ignore) setStarted(true);
    else {
      setStarted(false);
      if (isAuthenticated !== null) init();
    }
  }, [isAuthenticated]);

  if (!started) {
    return <PageLoading />;
  }

  return children;
};
