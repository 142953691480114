import styled, { css } from 'styled-components';

import ObjectiveType from 'design-system/atoms/ObjectiveType';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { spacing } from 'design-system/theme';
import { materialColors } from 'design-system/themes/default';
import Roadmap from 'design-system/atoms/DragonIcons/Roadmap';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  //justify-content: space-between;
  //width: 100%;

  white-space: nowrap;
  margin-left: ${({ $indent }) => ($indent ? spacing(3.125) : 0)}px;
`;

const TextWrapper = styled.span`
  white-space: break-spaces;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ $nOfTextRowsToDisplay = 0 }) => $nOfTextRowsToDisplay};
  -webkit-box-orient: vertical;
  flex: 1;
  gap: ${spacing(0.5)}px;

  ${({ $active }) =>
    !$active
      ? css`
          color: ${materialColors.silver} !important;
        `
      : ''}

  &:hover .actions {
    visibility: visible;
  }
`;

const Bold = styled.b`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

const StyledObjectiveType = styled(ObjectiveType)`
  && {
    height: 14px;
    margin: 0 6px 0 0;
  }
`;

const ActionsWrapper = styled.div`
  &&&& {
    display: flex;
    right: 10px;
    visibility: hidden;
  }
`;

const ActionButton = styled(IconButton)`
  &&&& {
    display: ${({ hideButton }) => (hideButton ? 'none' : 'inherit')};
    width: 14px;
    height: 14px;
    color: ${materialColors.darkerGray};
  }
`;

const StyledAddIcon = styled(AddIcon)`
  &&&& {
    width: 18px;
    height: 18px;
    font-size: ${props => props.theme.typography.fontSize}px;
  }
`;

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  &&&& {
    width: 14px;
    height: 14px;
    font-size: ${props => props.theme.typography.fontSize}px;
  }
`;

const StyledRoadmapIcon = styled(Roadmap)`
  &&&& {
    width: 14px;
    height: 14px;
    font-size: ${props => props.theme.typography.fontSize}px;
  }
`;

export {
  Wrapper,
  TextWrapper,
  StyledObjectiveType,
  ActionButton,
  ActionsWrapper,
  StyledAddIcon,
  StyledOpenInNewIcon,
  StyledRoadmapIcon,
  Bold,
};
