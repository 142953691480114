import camelCase from 'lodash/camelCase';

const isUninitialized = (state, actionPrefix) => state[camelCase(actionPrefix)]?.isUninitialized;
const isLoading = (state, actionPrefix) => state[camelCase(actionPrefix)]?.isLoading;
const getData = (state, actionPrefix) => state[camelCase(actionPrefix)]?.data;
const getError = (state, actionPrefix) => state[camelCase(actionPrefix)]?.error;
const isSuccess = (state, actionPrefix) => !!getData(state, actionPrefix) && !getError(state, actionPrefix);
const isError = (state, actionPrefix) => !!getError(state, actionPrefix);

export { isUninitialized, isLoading, getData, getError, isSuccess, isError };
