const SEARCH_KEYS = ['name'];

export default (scenarios, query) =>
  query
    ? scenarios.filter(scenario => {
        const match = Object.entries(scenario).some(([key, value]) => {
          if (!SEARCH_KEYS.includes(key) || !value) {
            return false;
          }

          return value?.toLowerCase().includes(query.toLowerCase());
        });

        return match;
      })
    : scenarios;
