import { getThunkActionTypes } from 'utils/store/thunk';

export const SET_CUSTOMER_REQUESTS_ANALYSE_CLUSTER_BY = 'SET_CUSTOMER_REQUESTS_ANALYSE_CLUSTER_BY';

export const SET_CUSTOMER_REQUESTS_ANALYSE_BUBBLE_SIZE_BY = 'SET_CUSTOMER_REQUESTS_ANALYSE_BUBBLE_SIZE_BY';

const actionTypes = {
  ...getThunkActionTypes('FETCH_CUSTOMER_REQUEST_CLUSTERS_FOR_ANALYSE_CHART'),
  ...getThunkActionTypes('FETCH_CUSTOMER_REQUESTS_FOR_CLUSTER'),
};

export const { FETCH_CUSTOMER_REQUEST_CLUSTERS_FOR_ANALYSE_CHART, FETCH_CUSTOMER_REQUESTS_FOR_CLUSTER } = actionTypes;
