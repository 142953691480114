import React from 'react';
import styled, { css } from 'styled-components';

import { PAGE_HEADER_HEIGHT } from 'constants/common';
import BaseLayout from 'design-system/organisms/BaseLayout/index';

import theme, { spacing } from 'design-system/theme';
import { ProjectsListLightboxProvider } from 'hooks/useProjectsListLightbox';
import FavoriteViews from './widgets/FavoriteViews';
import Announcements from './widgets/Announcements';
import OnboardingGuide from './widgets/OnboardingGuide';
import OkrsList from './widgets/OkrsList';
import MetricsList from './widgets/MetricsList';
import RequestsList from './widgets/RequestsList';
import RequestsCreatedList from './widgets/RequestsCreatedList';
import useMissionControlHome from './useMissionControlHome';

const layoutContentStyles = css`
  min-height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
  overflow-y: auto;
`;

const MissionControlHome = () => {
  const { displayOkrsWidget, displayMetricsWidget, displayRequestsWidgets } = useMissionControlHome();

  return (
    <BaseLayout
      contentStyles={layoutContentStyles}
      background={theme.palette.background.missionControl}
      wrapperAttributes={{ tabIndex: -1 }}
    >
      <ProjectsListLightboxProvider>
        <Widgets>
          <LeftSection>
            {displayOkrsWidget ? <OkrsList /> : null}
            {displayMetricsWidget ? <MetricsList /> : null}
            {displayRequestsWidgets ? (
              <>
                <RequestsList />
                <RequestsCreatedList />
              </>
            ) : null}
          </LeftSection>
          <RightSection>
            <FavoriteViews />
            <Announcements />
            <OnboardingGuide />
          </RightSection>
        </Widgets>
      </ProjectsListLightboxProvider>
    </BaseLayout>
  );
};

const Widgets = styled.div`
  display: flex;
  gap: ${spacing(2)}px;
  margin: ${spacing(2)}px 0;
`;

const LeftSection = styled.div`
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: ${spacing(2)}px;
`;

const RightSection = styled.div`
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: ${spacing(2)}px;
`;

export default MissionControlHome;
