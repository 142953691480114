import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

const componentHOC = Component => {
  return props => {
    return <Component {...props} />;
  };
};

export default compose(withRouter, componentHOC);
