import { toast } from 'react-toastify';

export default {
  syncJiraProgress: () => {
    toast('Jira sync was completed successfully');
  },
  syncStories: (integrationName = '') => {
    toast(`${integrationName} stories sync was completed successfully`);
  },
};
