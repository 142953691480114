import React from 'react';
import styled from 'styled-components';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import theme from 'design-system/theme';

const ASCENDING = 'asc';
const DESCENDING = 'desc';

const DEFAULT_COLOR = 'rgba(0, 0, 0, 0.54)';

const SortIcon = ({ direction, size = theme.typography.fontSizeMediumSmallRem, color = DEFAULT_COLOR, className }) => {
  return direction === ASCENDING ? (
    <StyledArrowUpward size={size} color={color} className={className} />
  ) : direction === DESCENDING ? (
    <StyledArrowDownward size={size} color={color} className={className} />
  ) : null;
};

export default SortIcon;

const StyledArrowUpward = styled(ArrowUpward)`
  && {
    font-size: ${({ size }) => size}rem;
    color: ${({ color }) => color};
  }
`;

const StyledArrowDownward = styled(ArrowDownward)`
  && {
    font-size: ${({ size }) => size}rem;
    color: ${({ color }) => color};
  }
`;
