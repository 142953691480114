import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import copyToClipboard from 'utils/copyToClipboard';
import FormControl from '@material-ui/core/FormControl';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import { JIRA_SERVICE_NAME } from 'constants/common';
import jiraIncomingLinkImage from '../../images/jira_oauth2_incoming_link.png';
import HelpIcon from '@material-ui/icons/HelpOutline';

const redirectURL = `${window.location.origin}/jira/callback`;

export default class ConfigStepOne extends Component {
  render() {
    const { onNextClick, goBack } = this.props;

    const onClickCopyToClipboard = async copyText => {
      await copyToClipboard(copyText);
    };

    return (
      <StepOneContent>
        <Grid container>
          <Grid item xs={7}>
            <HelperImage src={jiraIncomingLinkImage} alt="jira-incoming-link" />
          </Grid>
          <Grid item xs={5}>
            <NumericList>
              <li>
                Go to the Application Link page of your Jira Admin site, click the Create Link button
                <ButtonIcon
                  title="Help"
                  href="https://confluence.atlassian.com/adminjiraserver/configure-an-incoming-link-1115659067.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HelpIcon />
                </ButtonIcon>
              </li>
              <li>On the popup screen, select External Application, and select Incoming</li>
              <li>On the Configure income link screen (see left), enter the name and redirect URL of the integration</li>
            </NumericList>
            <TextControl fullWidth margin="dense">
              <TextField
                name="setup_name"
                label="Incoming link name"
                value={JIRA_SERVICE_NAME}
                inputProps={{
                  onClick: e => e.target.select(),
                }}
              />
              <CopyButton onClick={() => onClickCopyToClipboard(JIRA_SERVICE_NAME)} title="Copy to clipboard">
                <CopyIcon />
              </CopyButton>
            </TextControl>
            <TextControl fullWidth margin="dense">
              <TextField
                name="redirect_url"
                label="Redirect URL"
                value={redirectURL}
                inputProps={{
                  onClick: e => e.target.select(),
                }}
              />
              <CopyButton onClick={() => onClickCopyToClipboard(redirectURL)} title="Copy url to clipboard">
                <CopyIcon />
              </CopyButton>
            </TextControl>
            <PermissionsHelperText>
              Select <Bold>Admin</Bold> in the Permission field
            </PermissionsHelperText>
          </Grid>
        </Grid>
        {goBack && (
          <BackButton id="back-button" color="primary" onClick={goBack}>
            Back
          </BackButton>
        )}
        <ConnectButton id="next-button" color="primary" onClick={onNextClick}>
          Next
        </ConnectButton>
      </StepOneContent>
    );
  }
}

const StepOneContent = styled.div`
  padding-bottom: 80px;
  padding-top: 40px;
`;

const NumericList = styled.ol`
  list-style: decimal;
  margin-top: 4em;
  & > li {
    margin-bottom: 20px;
  }
`;

const TextControl = styled(FormControl)`
  &&&& {
    margin-bottom: 12px;
    padding-right: 40px;
  }
`;

const HelperImage = styled.img`
  width: 90%;
`;

const ConnectButton = styled(Button)`
  &&&& {
    margin-top: 38px;
    float: right;
  }
`;

const BackButton = styled(Button)`
  &&&& {
    margin-top: 38px;
    float: left;
  }
`;

const CopyButton = styled(ButtonIcon)`
  &&&& {
    position: absolute;
    right: 0;
    top: -4px;
    padding: 6px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const PermissionsHelperText = styled.p`
  margin-top: 20px;
`;

const Bold = styled.span`
  font-weight: bold;
`;
