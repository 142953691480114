import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getJiraResponseData } from 'store/login';

class JiraCallback extends React.Component {
  constructor(props) {
    super(props);
    this.getJiraData = this.getJiraData.bind(this);
  }

  componentWillMount() {
    this.getJiraData();
  }

  getJiraData() {
    const { jira } = this.props;

    const queryParams = new URLSearchParams(window.location.search);

    if (queryParams.has('code')) {
      const code = queryParams.get('code');
      const state = queryParams.get('state');

      jira({ code, state });
      return;
    }

    const str = window.location.href;
    const idex = str.lastIndexOf('oauth_verifier=');
    const code = str.substring(idex + 15).replace('#', '');
    const verifier = { code: {} };

    verifier.code = code;
    jira(verifier);
  }

  render() {
    return (
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        marginTop: '-1em', /* set to a negative number 1/2 of your height */
        marginLeft: '-2em', /* set to a negative number 1/2 of your width */
      }}
      >
        <CircularProgress size={50} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    jira: data => getJiraResponseData(data, props.history),
  };
};

export default connect(mapDispatchToProps)(JiraCallback);
