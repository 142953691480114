import { addListener } from '@reduxjs/toolkit';
import { includes } from 'ramda';
import { toast } from 'react-toastify';

import { IMPORT_METRIC_VALUES_REJECTED } from '../../Integrations/ImportMetricValues/store';
import { renderUserIntegrationNotFoundToast } from '../components/ErrorMessages';

const USER_INTEGRATION_NOT_FOUND_ERROR = 'User integration must be created.';

export const subscribeToIntegrationError = () => {
  return addListener({
    matcher: action => includes(action.type, [IMPORT_METRIC_VALUES_REJECTED]),
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners();

      const {
        payload: {
          response: { data },
        },
      } = action;

      switch (true) {
        case data?.message === USER_INTEGRATION_NOT_FOUND_ERROR:
          // TODO: get this configs from integration data
          toast(renderUserIntegrationNotFoundToast({ integrationName: 'Tableau', integrationKey: 'tableau' }));
          break;

        default:
          break;
      }
    },
  });
};
