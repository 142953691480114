import { useSelector } from 'react-redux';

import { selectRoadmapHistoryProjectGroups } from '../helpers/groupSelectors';
import useProjectsForSummaryCompare from './useProjectsForSummaryCompare';
import useGetMetadataFromSelectedSnapsToCompare from 'features/RoadmapHistory/hooks/useGetMetadataFromSelectedSnapsToCompare';
import useMetadata from 'routes/Dashboard/Summary/hooks/useMetadata';
import { selectOrganization } from 'store/organization';

const useSnapsGroups = config => {
  const projects = useProjectsForSummaryCompare();

  const organization = useSelector(selectOrganization);
  const metadata = useMetadata(organization, []);
  const metadataWithSnapMetadata = useGetMetadataFromSelectedSnapsToCompare({ metadata });

  const projectGroups = useSelector(state =>
    selectRoadmapHistoryProjectGroups(state, { ...config, customAllProjectsByLayer: projects, metadataWithSnapMetadata }),
  );

  return projectGroups;
};

export default useSnapsGroups;
