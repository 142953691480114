import { defaultTo, indexBy, mergeRight, prop, values } from 'ramda';
import { createSelector } from 'reselect';

import { getMultiFilters } from 'store/customerRequests/selectors';

const defaultAsEmptyArray = defaultTo([]);
const indexByKey = indexBy(prop('key'));

export function getState(state) {
  return state.customerRequestsInsights;
}

export const selectInsightsFilters = state => {
  const insights = getState(state);

  return defaultAsEmptyArray(insights.filters);
};

export const selectInsightsFiltersWithMultiFilters = createSelector(
  selectInsightsFilters,
  getMultiFilters,
  (insightsFilters, multiFilters) => {
    const insightsFiltersByKey = indexByKey(insightsFilters);
    const multiFiltersByKey = indexByKey(multiFilters);

    const mergedFiltersByKey = mergeRight(multiFiltersByKey, insightsFiltersByKey);

    const output = values(mergedFiltersByKey);

    return output;
  },
);

export const selectShowInsightsGrid = state => {
  const insightsFilters = selectInsightsFilters(state);

  return insightsFilters.length > 0;
};
