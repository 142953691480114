import { convertToRaw, EditorState, Modifier, RichUtils } from "draft-js";
import { useCallback, useState } from "react";
import { getUserName } from 'utils';

const createInitialEditorState = storedUsers => {
  return storedUsers.reduce((editorState, user) => {
    const userName = getUserName(user);
    const userMention = {
      ...user,
      name: userName,
      link: '',
    };

    const stateWithEntity = editorState.getCurrentContent().createEntity(
      'mention',
      'IMMUTABLE',
      { mention: userMention },
    );

    const entityKey = stateWithEntity.getLastCreatedEntityKey();
    const stateWithText = Modifier.insertText(
      stateWithEntity,
      editorState.getSelection(),
      userMention.name,
      null,
      entityKey,
    );

    const stateWithSpace = Modifier.insertText(
      stateWithText,
      stateWithText.getSelectionAfter(),
      ' ',
    );

    return EditorState.push(editorState, stateWithSpace);
  }, EditorState.createEmpty());
};

export default (initialUsersList, onUserMentionsChanged) => {
  const [editorState, setEditorState] = useState(() => createInitialEditorState(initialUsersList));

  const onEditorContentChange = useCallback(newEditorState => {
    const currentState = newEditorState.getCurrentContent();
    const rawEditorState = convertToRaw(currentState);

    const mentions = Object.entries(rawEditorState.entityMap)
      .filter(([_, entity]) => entity.type === 'mention')
      .map(([_, entity]) => entity.data.mention);

    if (mentions.length > 0) {
      onUserMentionsChanged(mentions);
    };

    setEditorState(newEditorState);
  }, [onUserMentionsChanged]);

  const handleKeyCommand = useCallback((command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      onEditorContentChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }, [onEditorContentChange]);

  return [editorState, onEditorContentChange, handleKeyCommand];
};
