import axios from 'axios';

import throwRequestError from 'store/utils/throwRequestError';
import { createThunk } from 'utils/store/thunk';

import { FETCH_REQUESTS_MERGED } from './types';

const fetchRequestsMergedOnRequest = requestId =>
  createThunk(FETCH_REQUESTS_MERGED, axios.get(`/api/customer-requests/${requestId}/merged`).catch(throwRequestError), {
    requestId,
  });

export { fetchRequestsMergedOnRequest };
