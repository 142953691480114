import { HEALTH_LABEL_OPTIONS_LABELS } from 'constants/projects';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';

const DEFAULT_ORGANIZATION = {
  default_idea_auto_health: false,
  default_initiative_auto_health: false,
  default_bet_auto_health: false,
};

/**
 * @function getProjectHealthLabel
 *
 * returns the label for project status_color value
 *
 * @param  {string} healthValue
 * @return {string}
 */
const getProjectHealthLabel = healthValue => {
  const label = HEALTH_LABEL_OPTIONS_LABELS[healthValue];

  if (!label) {
    return healthValue;
  }

  return label;
};

const getDefaultAutoHealth = (layer, organization = DEFAULT_ORGANIZATION) => {
  const _getDefaultAutoHealth = () => {
    switch (layer) {
      case IDEA_LAYER:
        return organization.default_idea_auto_health;
      case INITIATIVE_LAYER:
        return organization.default_initiative_auto_health;
      case BET_LAYER:
        return organization.default_bet_auto_health;
      default:
        return false;
    }
  };

  return {
    status_color_auto: _getDefaultAutoHealth(),
  };
};

export { getProjectHealthLabel, getDefaultAutoHealth };
