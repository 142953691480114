import React from 'react';
import styled from 'styled-components';

import TextDeprecated from '../TextDeprecated';
import theme from 'design-system/theme';

const HeadingH1 = ({ children, ...props }) => (
  <StyledText as="h1" color={theme.palette.text.primary} {...props}>
    {children}
  </StyledText>
);

export default HeadingH1;

const StyledText = styled(TextDeprecated)`
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;
