import React, { Fragment } from 'react';
import { has, not } from 'ramda';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import Autocomplete from 'design-system/atoms/Autocomplete/index';


const hasEditingComponent = has('editingComponent');

export default ({ allFields, fields, onRemove, fieldsAvailable, onFieldChange, onValueChange }) => (
  <Fragment>
    {fields &&
      fields.map((item, index) => {
        if (hasEditingComponent(item) && not(item.editingComponent)) return null;

        const options =
          item && item.field ? [{ label: item.label || item.field, value: item.field }, ...fieldsAvailable] : fieldsAvailable;

        // check if this field is a parent of another field that has been added to the bulk update
        // if it is a parent then editing should be disabled
        const parent =
          allFields && item.field && Object.entries(allFields).find(([key, value]) => item.field === value.parentField);
        const isParent = parent && fields.some(f => f.field === parent[0]);

        return (
          <div className="update-field">
            <Grid container spacing={8}>
              <Grid item xs={5}>
                <Autocomplete
                  onValueChange={value => onFieldChange(index, value)}
                  className="select-field-dropdown"
                  value={item.label}
                  style={{ width: '90%' }}
                  suggestions={options}
                  disabled={isParent}
                />
              </Grid>
              <Grid item xs={5}>
                {item.editingComponent && (
                  <item.editingComponent
                    value={item.value}
                    onChange={e => {
                      const value = e && e.target ? e.target.value : e;

                      onValueChange(index, value);
                    }}
                    disabled={isParent}
                  />
                )}
              </Grid>
              <Grid item xs={2} container justify="flex-end">
                <Grid item>
                  {!isParent && (
                    <Button className="remove-field-button" onClick={() => onRemove(index)}>
                      <DeleteIcon />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        );
      })}
  </Fragment>
);
