import React from 'react';
import TaskDialog from './TaskDialog';
import isEmpty from 'lodash/isEmpty';

export default props => {
  const {
    selectedTask,
    onSaveTaskDetails,
    onCancelTask,
    onDeleteTask,
    onCloneTask,
    getSystemFieldName,
    displayLayer,
    hideTasksName,
    orgHasBet,
    openProjectLightbox,
  } = props;

  if (!selectedTask) return '';

  const title = selectedTask ? selectedTask.title : 'New Assignment';
  const hasChildren = !!selectedTask && selectedTask.subtasks && selectedTask.subtasks.length;

  return (
    <TaskDialog
      displayLayer={displayLayer}
      title={title}
      fullWidth
      isNew={selectedTask && selectedTask.$new}
      isOpen={!isEmpty(selectedTask)}
      onSave={onSaveTaskDetails}
      onClose={onCancelTask}
      onDelete={onDeleteTask}
      selectedTask={selectedTask}
      onClone={onCloneTask}
      hasChildren={hasChildren}
      getSystemFieldName={getSystemFieldName}
      hideTasksName={hideTasksName}
      orgHasBet={orgHasBet}
      openProjectLightbox={openProjectLightbox}
      hasHierarchy
      hideTabs
    />
  );
};
