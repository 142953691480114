import { propOr } from 'ramda';

import { METADATA_LEVELS } from 'constants/common';
import {
  ROADMAP_LEVEL_1_META_TYPE,
  ROADMAP_LEVEL_2_META_TYPE,
  ROADMAP_LEVEL_3_META_TYPE,
  ROADMAP_LEVEL_CORP_META_TYPE,
} from 'hooks/filters/useRoadmapQuickFilter';

const getRoadmapLevelByMetaType = metaType => {
  const mapMetaTypeToLevel = {
    [ROADMAP_LEVEL_CORP_META_TYPE]: METADATA_LEVELS.LEVEL_CORP,
    [ROADMAP_LEVEL_1_META_TYPE]: METADATA_LEVELS.LEVEL_1,
    [ROADMAP_LEVEL_2_META_TYPE]: METADATA_LEVELS.LEVEL_2,
    [ROADMAP_LEVEL_3_META_TYPE]: METADATA_LEVELS.LEVEL_3,
  };

  return propOr(METADATA_LEVELS.LEVEL_1, metaType)(mapMetaTypeToLevel);
};

export default getRoadmapLevelByMetaType;
