import React from 'react';

export default Component => props => {
  const items = React.useMemo(() => props.customerRequests, [props.customerRequests]);

  return (
    <Component
      items={items}
      startEditingComment={props.startEditingComment}
    />
  );
};
