
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import Component from './Component';
import container from './container';

export default compose(
  withRouter,
  container,
)(Component);
