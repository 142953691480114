import React from "react";

export default ({ idea, onClick }) => {
  return (
    <div>
      <span>{idea.title} has been created but may not be visible, </span>
      <a onClick={onClick}>click here to view</a>
    </div>
  );
};
