import { not } from 'ramda';

/**
 * Helper function to check if a group is a parent of a new item row.
 * @param params The params argument from the `isGroupOpenByDefault` AgGrid callback.
 * For more details check the official AgGrid docs:
 * https://www.ag-grid.com/react-data-grid/grid-options/#reference-rowGrouping-isGroupOpenByDefault
 * @return {boolean}
 */
const checkIfGroupShouldOpenForNewItemCreation = params => {
  const newItemNode = params.context.processedData.find(item => !item.id);

  const thereIsANewItemNodeAndTheCurrentGroupIsAnUndefinedGroup = newItemNode && params.key === 'Undefined';

  // Because new items are created on Undefined groups.
  // NOTE: Even for cases where there are default values, like Phase, the
  //  new item row is created under the "Undefined" group. This comes from the
  //  logic of `src/utils/childProject/createChildFromProject.js` and even if that
  //  changes to omit the `path`, `src/utils/setIdeasHierarchyPath.js` will still
  //  add a `path` to place it under the "Undefined" group.

  if (thereIsANewItemNodeAndTheCurrentGroupIsAnUndefinedGroup) {
    const newItemNodePath = newItemNode.path;

    const parentNodeDataDoesNotExists = not(params.rowNode.parent.data);

    if (parentNodeDataDoesNotExists) {
      return false;
    }

    const parentNodePath = params.rowNode.parent.data.path;

    if (newItemNodePath.length > parentNodePath.length) {
      // Check if the path for the parent node is the same as the first portion
      // of the same size of the path of the new item node. If it is, it means the
      // current node is a parent of the new item node (because it's an Undefined node).
      if (parentNodePath.every((pathPart, index) => pathPart === newItemNodePath[index])) {
        return true;
      }
    }
  }

  return false;
};

export default checkIfGroupShouldOpenForNewItemCreation;
