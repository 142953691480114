import HierarchyAutocomplete, { cellParams as HierarchyAutocompleteCellParams } from 'components/AgGridHierarchyAutocomplete';
import AgGridGroupRenderer from 'components/AgGridGroupRenderer';
import getSystemFieldName from 'utils/getSystemFieldName';

import { editOptions, returnsTrueIfKeyIsNotEscOrTab } from './agGrid';

export default (organization, objectives, wrapTextOptions, systemFields) => {
  if (organization && organization.has_key_results) {
    return [
      {
        ...editOptions,
        ...HierarchyAutocompleteCellParams(
          'keyResult1Title',
          [
            {
              parentAttribute: 'objectiveTitle',
              optionsAttribute: 'keyResults',
            },
          ],
          objectives,
          { size: '2x' },
        ),
        ...wrapTextOptions,
        suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
        headerName: getSystemFieldName('keyResult1', systemFields),
        field: 'keyResult1Title',
        enableRowGroup: true,
        cellRenderer: AgGridGroupRenderer(getSystemFieldName('keyResult1', systemFields)),
        cellEditor: HierarchyAutocomplete,
        width: 120,
      },
    ];
  }

  return [];
};
