import React, { useEffect, useRef, useState } from 'react';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import { defaultTo } from 'ramda';

import AddFilterPopper from 'design-system/organisms/AddFilterPopper/index';
import SavedFiltersPopper from 'design-system/organisms/SavedFiltersPopper/index';
import SaveFilterDialog from 'design-system/organisms/SaveFilterDialog/index';
import FilterCondition from 'design-system/molecules/FilterCondition/index';


import normalizeArray from 'utils/normalizeArray';

import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import MuiButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MuiSaveIcon from '@material-ui/icons/Save';

import { CUSTOM_FIELDS } from 'constants/customerRequests';

import styled from 'styled-components';
import { CREATED_AFTER_LIMIT } from '../HeaderFilters/constants';

const ADD_CONDITION = '+ Add Condition';
const APPLY = 'Apply';
const CANCEL = 'Cancel';
const ACTIVE_FILTERS = 'Active Filters';
const SAVE_ACTIVE_FILTER = 'Save active filter';
const SAVED_FILTERS = 'Saved Filters';

export default ({
  anchorEl,
  setAnchorEl,
  fields,
  pageFilters,
  onApplyFilter,
  onSaveFilter,
  onSavedFilterClick,
  savedFilters,
}) => {
  const [addFilterAnchorEl, setAddFilterAnchorEl] = useState(null);
  const [savedFiltersAnchorEl, setSavedFiltersAnchorEl] = React.useState(null);
  const [filters, setFilters] = useState(pageFilters);
  const [showSaveFilterDialog, setShowSaveFilterDialog] = React.useState(false);
  const [editableItem, setEditableItem] = React.useState(null);
  const [editingFilterName, setEditingFilterName] = useState('');

  const normalizedFields = normalizeArray(fields, 'key');
  const actionRef = useRef();
  const addFilterPopperRef = useRef(null);
  const savedFiltersPopperRef = React.useRef(null);

  // rebuilds savedFilters array to match 'title' and 'name' key in SearchableList
  const savedFiltersNormalized = savedFilters.map(({ id, label, state }) => ({ id, title: label, name: label, state }));

  useEffect(() => {
    setFilters(pageFilters);
  }, [pageFilters]);

  const _getFiltersList = filter => {
    return Object.keys(filter || {}).filter(f => normalizedFields[f]);
  };

  const _onAddNewFilter = e => {
    setAddFilterAnchorEl(addFilterAnchorEl ? null : e.currentTarget);
  };
  const _handleOnShowSavedFilters = e => setSavedFiltersAnchorEl(savedFiltersAnchorEl ? null : e.currentTarget);
  const _handleAddNewFilterClick = field => {
    if (Object.keys(filters || {}).some(k => k.includes(field.key))) return;

    const newFilters = cloneDeep(filters);

    newFilters[field.key] = defaultTo([], field.defaultInitialValue);

    setFilters(newFilters);
    setAddFilterAnchorEl(null);

    if (actionRef.current) actionRef.current.updatePosition();
  };

  const _handleRemoveFilter = field => {
    let newFilters = cloneDeep(filters);

    newFilters = {
      ...omit(newFilters, field.field),
    };

    setFilters({
      ...newFilters,
    });
  };

  const _handleOnChangeFilter = (filter, param, value) => {
    const filtersUpdate = cloneDeep(filters);

    if (param === 'field') {
      delete filtersUpdate[filter];
      filtersUpdate[value] = null;
    } else if (param === 'value') {
      filtersUpdate[filter] = value;
    }
    setFilters(filtersUpdate);
  };

  const _handleSaveFilter = name => {
    setEditingFilterName('');
    const appliedMultiFilters = _onApply();

    onSaveFilter(name, appliedMultiFilters);
    setAddFilterAnchorEl(null);
  };

  const _onCancel = () => {
    setAnchorEl(null);
    setFilters(pageFilters);
  };

  const _onApply = () => {
    setEditingFilterName('');
    const finalFilters = [];
    const createdField = filters.created_after;

    if (createdField) {
      let createdAfter;
      let limit;

      if (Object.keys(createdField).find(key => createdField[key] === '3')) {
        createdAfter = moment().subtract(30, 'd').toDate();
      } else if (Object.keys(createdField).find(key => createdField[key] === '2')) {
        createdAfter = moment().subtract(7, 'd').toDate();
      } else if (Object.keys(createdField).find(key => createdField[key] === '1')) {
        createdAfter = moment().subtract(1, 'd').toDate();
      } else if (Object.keys(createdField).find(key => createdField[key] === '4')) {
        limit = CREATED_AFTER_LIMIT;
      }

      const field = {
        key: 'created_after',
        values: [],
        createdAfter,
        limit,
      };

      Object.values(filters.created_after)?.forEach(item => {
        field.values.push(item);
      });

      finalFilters.push(field);
    }

    Object.keys(filters)?.forEach(itemKey => {
      if (itemKey === 'created_after') return;

      const data = fields?.find(item => item.key === itemKey);
      const isCustomField = data?.customKey === CUSTOM_FIELDS;

      // Checking if it's 'Yes/No'/checkbox custom field, if so we want to pass a boolean
      const isCheckboxType = data?.type === 'checkbox';
      let checkboxValue;

      if (isCheckboxType) {
        checkboxValue = Boolean(filters[itemKey]);
      }

      const filterValues = isCheckboxType ? checkboxValue : filters[itemKey];

      if (!data) return;

      const field = {
        key: data?.key,
        // If custom field, use its original key
        customKey: isCustomField ? data?.customKey : undefined,
        values: filterValues,
      };

      finalFilters.push(field);
    });

    if (onApplyFilter) onApplyFilter(finalFilters);

    onApplyFilter(finalFilters);
    setAnchorEl(null);
    return finalFilters;
  };

  const _onClickAway = e => {
    _onCancel();
  };

  const _onClickAwayAddFilterPopper = e => {
    if (e.composedPath().includes(addFilterAnchorEl)) return;

    setAddFilterAnchorEl(null);
  };
  const _onClickAwaySavedFiltersPopper = e => {
    if (e.composedPath().includes(savedFiltersAnchorEl)) return;

    setSavedFiltersAnchorEl(null);
  };

  if (!anchorEl) return '';

  const _renderFilterCondition = f => {
    const field = fields?.find(item => item.key === f);

    const optionsDraft = field?.children;
    // rebuilds optionsDraft array to match 'title' key in FieldMultiselect
    const options = optionsDraft.map(({ id, label }) => ({ id, title: label }));

    const data = {
      value: filters[f],
      field: f,
      op: 'equals',
    };

    return (
      <FilterCondition
        key={f}
        fields={fields}
        data={data}
        options={options}
        onChange={_handleOnChangeFilter}
        onRemove={_handleRemoveFilter}
        isCustomerRequest
      />
    );
  };

  const renderFilterConditions = () => (
    <>
      {!!_getFiltersList(filters).length && (
        <FiltersContainer>
          {_getFiltersList(filters).map(f => (
            <FilterDiv>{_renderFilterCondition(f)}</FilterDiv>
          ))}
        </FiltersContainer>
      )}
      <AddConditionContainer>
        <Button color="primary" onClick={_onAddNewFilter}>
          {ADD_CONDITION}
        </Button>
      </AddConditionContainer>
    </>
  );

  const renderAddFilterPopper = () => (
    <AddFilterPopper
      anchorEl={addFilterAnchorEl}
      title="Add Filter"
      items={fields}
      onItemClick={_handleAddNewFilterClick}
      onClickAway={_onClickAwayAddFilterPopper}
      setRef={r => (addFilterPopperRef.current = r)}
    />
  );

  const renderSavedFiltersPopper = () => (
    <SavedFiltersPopper
      anchorEl={savedFiltersAnchorEl}
      onClickAway={_onClickAwaySavedFiltersPopper}
      myFilters={savedFiltersNormalized}
      setRef={r => (savedFiltersPopperRef.current = r)}
      editableItem={editableItem}
      onSetEditableItem={setEditableItem}
      onSaveFilter={(id, name) => onSaveFilter(id, name)}
      onFilterClick={f => {
        onSavedFilterClick(f);
        setSavedFiltersAnchorEl(null);
        setAnchorEl(null);
      }}
    />
  );

  const renderSaveFilterDialog = () => (
    <SaveFilterDialog
      filterName={editingFilterName}
      showDialog={showSaveFilterDialog}
      onClose={() => setShowSaveFilterDialog(false)}
      onSave={name => _handleSaveFilter(name)}
    />
  );

  const renderFooter = () => (
    <ButtonsContainer>
      {savedFilters && !!savedFilters.length && <Button onClick={_handleOnShowSavedFilters}>{SAVED_FILTERS}</Button>}
      <ActionsButtonsContainer>
        {!!filters && (
          <Button color="primary" onClick={() => setShowSaveFilterDialog(true)}>
            <SaveIcon />
            {SAVE_ACTIVE_FILTER}
          </Button>
        )}
        <Button onClick={_onApply} color="primary">
          {APPLY}
        </Button>
        <Button onClick={_onCancel}>{CANCEL}</Button>
      </ActionsButtonsContainer>
    </ButtonsContainer>
  );

  return (
    <React.Fragment>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        action={r => (actionRef.current = r)}
        style={{ zIndex: 1000 }}
        onClose={_onClickAway}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <FiltersWrapper>
          <FiltersHeader>{ACTIVE_FILTERS}</FiltersHeader>
          {renderFilterConditions()}
          {renderFooter()}
          {renderSavedFiltersPopper()}
          {renderAddFilterPopper()}
          {renderSaveFilterDialog()}
        </FiltersWrapper>
      </Popover>
    </React.Fragment>
  );
};

const FiltersWrapper = styled(Paper)`
  &&&& {
    min-width: 250px;
    max-width: 788px;
  }
`;

const AddConditionContainer = styled.div`
  padding: 0 16px 8px;

  button {
    text-transform: initial;
  }
`;

const Button = styled(MuiButton)``;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px 10px;
  border-top: 1px solid #fafafa;
  box-shadow: 0px -2px 12px 0px rgb(204 204 204 / 30%);
`;

const ActionsButtonsContainer = styled.div`
  margin-left: auto;
  padding-left: 50px;
`;

const FiltersContainer = styled.div`
  padding: 12px 12px;
`;

const FiltersHeader = styled(props => (
  <div {...omit(props, ['children'])}>
    {props.savedFilter && <Typography variant="filtersTitle">Saved filter / &nbsp;</Typography>}
    <StyledTypography onClick={props.handleClickFilterName} savedFilter={props.savedFilter} variant="filtersTitle">
      {props.children}
    </StyledTypography>
  </div>
))`
  &&&& {
    display: flex;
    justify-content: left;
    flex-shrink: 0;
    padding: 20px 18px 10px;
    white-space: nowrap;

    span {
      font-size: 1.1rem;
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    }

    span:first-child {
      color: ${({ theme }) => theme.palette.newLayout.text.labelDisabled};
    }
  }
`;

const StyledTypography = styled(Typography)`
  &&&& {
    ${props =>
      props.savedFilter &&
      `
        &:hover {
          cursor: pointer;
        }
     `}
  }
`;

const FilterDiv = styled.div`
  width: 100%;
  min-width: 650px;
`;

const SaveIcon = styled(MuiSaveIcon)`
  &&& {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
`;
