import { indexOf, keys } from 'ramda';
import { useSelector } from 'react-redux';

import useSystemFields from 'hooks/useSystemFields';
import { getCustomerRequestsCustomFields, getRequestsCustomFieldsSharedWithProjects } from 'store/customFields/selectors';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';

import { getCustomerRequestFieldLabel } from '../helpers';
import {
  getDefaultRightFields,
  getDefaultLeftFields,
  getDefaultHiddenFields,
  getDefaultPromoteFields,
} from '../helpers/defaultFieldsLayoutUtils';

const useDefaultFieldsLayout = () => {
  const [getSystemFieldName] = useSystemFields();
  const customFields = useSelector(getCustomerRequestsCustomFields);
  const customFieldsSharedWithProjects = useSelector(getRequestsCustomFieldsSharedWithProjects);

  const { canView } = usePermissions();
  const hasPersonas = canView(PERMISSION_FEATURES.personas);
  const hasLifecycles = canView(PERMISSION_FEATURES.lifecycles);
  const hasExternalKeys = canView(PERMISSION_FEATURES.importRequestsWithExternalKey);
  const shouldShowPreCalculations = useFeatureFlags([FeatureFlags.HAS_OUTCOME_DASHBOARD]);

  const commonOptions = { customFields, hasPersonas, hasLifecycles, hasExternalKeys, shouldShowPreCalculations };

  const defaultRightFields = getDefaultRightFields({ ...commonOptions });
  const defaultLeftFields = getDefaultLeftFields({ ...commonOptions });
  const defaultHiddenFields = getDefaultHiddenFields();
  const defaultPromoteFields = getDefaultPromoteFields({ ...commonOptions, customFields: customFieldsSharedWithProjects });

  const allFields = [...defaultRightFields, ...defaultLeftFields, ...defaultHiddenFields];

  const fields = allFields.reduce(
    (acc, f) => ({
      ...acc,
      [f]: {
        hidden: false,
        promote: defaultPromoteFields.includes(f),
        ...(defaultLeftFields.includes(f) ? { position: 'left', index: indexOf(f, defaultLeftFields) } : {}),
        ...(defaultRightFields.includes(f) ? { position: 'right', index: indexOf(f, defaultRightFields) } : {}),
        ...(defaultHiddenFields.includes(f) ? { hidden: true, index: indexOf(f, defaultHiddenFields) } : {}),
      },
    }),
    {},
  );

  const enrichFieldsLayout = fieldsLayout => {
    return keys(fieldsLayout).reduce(
      (carry, fieldId) => ({
        ...carry,
        [fieldId]: {
          id: fieldId,
          label: getCustomerRequestFieldLabel(fieldId, getSystemFieldName, customFields),
          ...fieldsLayout[fieldId],
        },
      }),
      {},
    );
  };

  return {
    defaultFields: fields,

    enrichFieldsLayout,
  };
};

export default useDefaultFieldsLayout;
