const recursiveSum = (obj, key) => {
  if (obj?.integrationProgress?.[key]) {
    return obj?.integrationProgress?.[key];
  }

  if (obj?.children && obj?.children?.length) {
    return obj.children.reduce((a, b) => a + recursiveSum(b, key), 0);
  }

  return 0;
};

export { recursiveSum };
