import React, { useState } from 'react';
import { path } from 'ramda';
import styled from 'styled-components';
import { FeatureFlags } from '@dragonboat/config';

import Button from '@material-ui/core/Button';

import RallyConnection from '../RallyConnection';
import RallyWebhooks from '../RallyWebhooks';
import FieldMapping from 'routes/Settings/Integration/components/FieldMapping';
import RecurringImport from '../RecurringImport';
import { INTEGRATIONS_KEYS } from 'constants/integrations';

import useFeatureFlags from 'hooks/useFeatureFlags';
import { IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import ProjectLayersTabs from 'routes/Settings/Integration/components/ProjectLayersTabs';

const DATA = 'data';
const URI = 'uri';

const getUri = path([DATA, URI]);

const IntegrationDetails = props => {
  const {
    selectedOrgIntegration,
    userCanViewOrgIntegration,
    userCanUpdateOrgIntegration,
    onShowDisconnectConfirmation,
    handleTestConnection,
    goBack,
  } = props;

  const hasRallyRecurringImport = useFeatureFlags(FeatureFlags.HAS_RALLY_RECURRING_IMPORT);
  const hasRallyFieldMapping = useFeatureFlags(FeatureFlags.HAS_RALLY_FIELD_MAPPING);
  const [selectedMappingLayer, setSelectedMappingLayer] = useState(IDEA_LAYER);

  return (
    <>
      <IntegrationLink href={getUri(selectedOrgIntegration)} target="_blank" rel="noreferrer">
        {getUri(selectedOrgIntegration)}
      </IntegrationLink>
      {hasRallyFieldMapping && (
        <>
          <ProjectLayersTabs
            selectedOption={selectedMappingLayer}
            onChange={setSelectedMappingLayer}
            supportedLayers={[IDEA_LAYER, INITIATIVE_LAYER]}
          />
          <FieldMapping
            integrationType={INTEGRATIONS_KEYS.rally}
            orgIntegrationId={selectedOrgIntegration.id}
            userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
            layer={selectedMappingLayer}
          />
        </>
      )}
      {hasRallyRecurringImport && (
        <RecurringImport orgIntegrationId={selectedOrgIntegration.id} userCanUpdateOrgIntegration={userCanUpdateOrgIntegration} />
      )}
      <RallyConnection
        orgIntegrationId={selectedOrgIntegration?.id}
        onShowDisconnectConfirmation={onShowDisconnectConfirmation}
        userCanViewOrgIntegration={userCanViewOrgIntegration}
        userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
        handleTestConnection={handleTestConnection}
      />
      <RallyWebhooks orgIntegration={selectedOrgIntegration} userCanUpdateOrgIntegration={userCanUpdateOrgIntegration} />
      <Button color="primary" onClick={goBack}>
        Back
      </Button>
    </>
  );
};

const IntegrationLink = styled.a`
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: 16px;
`;

export default IntegrationDetails;
