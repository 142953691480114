import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { match, nth, pipe, allPass, isNil, not, isEmpty, either, path, defaultTo } from 'ramda';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Input from '@material-ui/core/Input';
import Tooltip from '@material-ui/core/Tooltip';

import HelpLink from 'design-system/atoms/HelpLink/index';
import InlineIcon from 'design-system/atoms/InlineIcon/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import Autocomplete from 'design-system/atoms/Autocomplete/index';

import { selectHasMultipleIntegrationsByIntegrationType } from 'store/organization';

import useAppNotifications from 'hooks/useAppNotifications';
import useIntegrations from 'hooks/useIntegrations';
import { INTEGRATIONS_KEYS } from 'constants/integrations';

import validateURL from 'utils/validateURL';

import ErrorMessage, { errorTypes } from './ErrorMessage';

const HELP_LINK = 'https://dragonboat.zendesk.com/hc/en-us/articles/15684176368151-Rally-Software-Integration';
const WORKSPACE_PLACEHOLDER = 'Select a workspace';

const isNotNil = pipe(isNil, not);
const isNilOrEmpty = either(isNil, isEmpty);
const defaultToNull = defaultTo(null);
const getErrorMessage = path(['response', 'data', 'error_code']);

const getViewId = pipe(match(/view=([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})/), nth(1));
const isValidViewId = pipe(getViewId, isNotNil);

const isInvalidViewUrl = pipe(allPass([validateURL, isValidViewId]), not);

const RallySearchStep = ({
  parsedImportUrl,
  orgIntegrationId,
  onOrgIntegrationIdChange,
  onSelectedValueChange,
  recurringImportConfig,
  isRecurringImportVariant,
  showWorkspaceList,
}) => {
  const [error, setError] = useState(null);
  const [importUrl, setImportUrl] = useState('');
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);

  const hasMultipleRallyIntegrations = useSelector(selectHasMultipleIntegrationsByIntegrationType(INTEGRATIONS_KEYS.rally));

  const { addError } = useAppNotifications();
  const {
    organizationIntegrations,
    getImportCountIntegrationItems,
    isImportCountIntegrationItemsLoading,
    importCountIntegrationItems,
    importCountIntegrationItemsError,
  } = useIntegrations(INTEGRATIONS_KEYS.rally, orgIntegrationId);

  const workspacesList = useMemo(
    () =>
      organizationIntegrations
        .map(integration =>
          integration.data?.workspaceId
            ? { title: integration.data?.workspaceName || integration.data?.workspaceId, value: integration.data?.workspaceId }
            : null,
        )
        .filter(Boolean),
    [organizationIntegrations],
  );

  useEffect(() => {
    if (isRecurringImportVariant) {
      handleUrlChange(recurringImportConfig.recurring_import_link);
    }
  }, [recurringImportConfig, isRecurringImportVariant]);

  const integratedURLs = useMemo(
    () => organizationIntegrations.map(integration => integration.data?.uri),
    [organizationIntegrations],
  );

  const isDisabled = useMemo(
    () => !parsedImportUrl || isImportCountIntegrationItemsLoading || isNotNil(error),
    [parsedImportUrl, isImportCountIntegrationItemsLoading, error],
  );

  const showUrlInput = not(hasMultipleRallyIntegrations && isNil(orgIntegrationId) && isNil(selectedWorkspace));

  const handleUrlChange = useCallback(
    value => {
      setImportUrl(value);

      if (isNilOrEmpty(value)) {
        return setError(null);
      }

      if (isInvalidViewUrl(value)) {
        return setError(errorTypes.INVALID_VIEW);
      }

      const viewContainsIntegratedBaseUrl = integratedURLs.some(integratedUrl => !value.startsWith(integratedUrl));

      if (viewContainsIntegratedBaseUrl) {
        return setError(errorTypes.INVALID_BASE_URL);
      }

      setError(null);
      onSelectedValueChange(value);
    },
    [setImportUrl, onSelectedValueChange],
  );

  const handleSelectedWorkspaceChange = useCallback(
    value => {
      if (!value || !hasMultipleRallyIntegrations) {
        return;
      }

      const selectedOrgIntegration = organizationIntegrations.find(integration => integration?.data?.workspaceId === value);

      if (!selectedOrgIntegration && !orgIntegrationId) {
        return setError(errorTypes.INVALID_WORKSPACE_SELECTION);
      }

      setSelectedWorkspace(workspacesList.find(workspace => workspace.value === value));
    },
    [organizationIntegrations, orgIntegrationId, workspacesList],
  );

  const handleSearch = useCallback(() => {
    setError(null);

    const selectedOrgIntegration = !hasMultipleRallyIntegrations
      ? organizationIntegrations[0]
      : organizationIntegrations.find(integration => integration?.data?.workspaceId === selectedWorkspace?.value);

    const integrationId = selectedOrgIntegration?.id || orgIntegrationId;

    if (!integrationId) {
      return setError(errorTypes.INVALID_WORKSPACE_SELECTION);
    }

    if (isInvalidViewUrl(parsedImportUrl)) {
      return setError(errorTypes.INVALID_VIEW);
    }

    onOrgIntegrationIdChange(integrationId);
    getImportCountIntegrationItems(integrationId, parsedImportUrl);
  }, [organizationIntegrations, importUrl, parsedImportUrl, getImportCountIntegrationItems, setError]);

  useEffect(() => {
    if (importCountIntegrationItemsError) {
      const message = defaultToNull(getErrorMessage(importCountIntegrationItemsError)) || 'Error fetching the Rally work items';

      addError({ id: 'rally-view-error', message });
    }
  }, [importCountIntegrationItemsError]);

  return (
    <Wrapper>
      <ImportInstructions>
        <span>
          Create a Work View on your Rally instance, then <strong>paste the url</strong> below
        </span>
        <Tooltip enterDelay={200} title="Rally integration overview">
          <TextDeprecated size="medium">
            <HelpLink href={HELP_LINK} target="_blank" rel="noopener noreferrer">
              <InlineIcon>
                <HelpIcon />
              </InlineIcon>
            </HelpLink>
          </TextDeprecated>
        </Tooltip>
      </ImportInstructions>
      <Grid container spacing={0}>
        {showWorkspaceList && (
          <Grid item xs={12}>
            <WorkspacesDropdownContainer>
              <Autocomplete
                placeholder={WORKSPACE_PLACEHOLDER}
                suggestions={workspacesList}
                value={selectedWorkspace?.title}
                onValueChange={handleSelectedWorkspaceChange}
                required
              />
            </WorkspacesDropdownContainer>
          </Grid>
        )}
        {showUrlInput && (
          <Grid item xs={12}>
            <InputContainer hasError={!!error}>
              <QueryInput
                value={importUrl}
                placeholder="Paste your Rally Work View url here"
                onChange={e => handleUrlChange(e?.target?.value)}
              />
              <Button onClick={handleSearch} disabled={isDisabled}>
                Search
              </Button>
            </InputContainer>
          </Grid>
        )}
      </Grid>
      {/* User Warning/Instruction for building view filters - unlike other errors, always show */}
      {showUrlInput && (
        <ErrorMessageContainer>
          <ErrorMessage errorType={errorTypes.VIEW_PROJECT_FILTER_WARNING} />
        </ErrorMessageContainer>
      )}
      {isImportCountIntegrationItemsLoading && <CircularProgress variant="indeterminate" disableShrink size={24} thickness={4} />}
      {!isImportCountIntegrationItemsLoading && importCountIntegrationItems && !error && (
        <ImportInstructions>
          {!!importCountIntegrationItems[parsedImportUrl] && (
            <span>
              <strong>{importCountIntegrationItems[parsedImportUrl]}</strong> Work Items found.
            </span>
          )}
          {importCountIntegrationItems[parsedImportUrl] === 0 && <span>There are no work items in the search result.</span>}
        </ImportInstructions>
      )}
      {error && (
        <ErrorMessageContainer>
          <ErrorMessage errorType={error} integratedURLs={integratedURLs} workspacesList={workspacesList} />
        </ErrorMessageContainer>
      )}
    </Wrapper>
  );
};

RallySearchStep.propTypes = {
  parsedImportUrl: PropTypes.string,
  orgIntegrationId: PropTypes.number,
  onOrgIntegrationIdChange: PropTypes.func.isRequired,
  onSelectedValueChange: PropTypes.func.isRequired,
};

export const Wrapper = styled.div`
  margin: 20px 32px;
  min-height: 200px;
`;

export const ImportInstructions = styled.p`
  display: flex;
  font-size: 16px;
  line-height: 23px;

  strong {
    font-weight: bold;
  }

  a {
    margin-left: 120px;
  }
`;

export const ErrorMessageContainer = styled.p`
  margin: 5px 0px 10px 0px;
  color: ${props => props.theme.palette.text.error};
  font-size: ${props => props.theme.typography.fontSizeSmall}px;
  line-height: 1em;
`;

const WorkspacesDropdownContainer = styled.div`
  margin: 36px 0 11px 0;
  width: 550px;
  max-width: 100%;
`;

export const InputContainer = styled.div`
  margin: 36px 0 11px 0;
  text-align: right;
  width: 550px;
  max-width: 100%;
  border: 1px solid;
  border-color: ${props => (props.hasError ? props.theme.palette.text.error : `rgba(0, 0, 0, 0.23)`)};
  display: flex;

  button {
    background-color: rgba(0, 0, 0, 0.08);
    text-transform: initial;
    border-radius: 0;
    font-weight: 400;
    height: 42px;
    width: 90px;
  }
`;

export const QueryInput = styled(Input)`
  &&&& {
    width: calc(100% - 42px);

    input {
      width: 100%;
      height: 100%;
      padding: 10px;
      color: #4689f0;
      font-size: 14px;
    }
  }
`;

export const AutocompleteWrapper = styled.div`
  &&&& {
    margin: 36px 0 11px 0;
  }
`;

export default RallySearchStep;
