/* eslint-disable */
import React, { useState, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import GridTable from 'design-system/organisms/GridTable/index';

import { generateColumnsOrder } from './utils';

const ROW_DRAG_TYPE = 'row';
const COLUMN_DRAG_TYPE = 'column';

const Column = React.memo(
  ({
    index,
    column,
    columnColor,
    columnHeight,
    getColumnWidth,
    renderHeader,
    onMoveColumn,
    onChangeColumnOrder,
    onResizeColumn,
  }) => {
    if (!column) return;

    const { col } = column;

    const dropRef = React.useRef(null);
    const dragRef = React.useRef(null);

    const [isHover, setIsHover] = useState(false);

    const [, drop] = useDrop({
      accept: COLUMN_DRAG_TYPE,
      hover(item, monitor) {
        if (!dropRef.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = dropRef.current.getBoundingClientRect();
        // Get vertical middle
        //   const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        //   const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        const hoverClientX = clientOffset.x - hoverBoundingRect.left;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return;
        }
        // Time to actually perform the action
        onMoveColumn(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      },
      drop() {
        onChangeColumnOrder && onChangeColumnOrder();
      },
    });

    const [, drag, preview] = useDrag({
      type: COLUMN_DRAG_TYPE,
      item: { index },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    });

    preview(drop(dropRef));
    drag(dragRef);

    const handleOnMouseEnter = useCallback(() => setIsHover(true), []);

    const handleOnMouseLeave = useCallback(() => setIsHover(false), []);

    return (
      <TableHeaderCell ref={index ? dropRef : null} style={{ width: `${getColumnWidth(col ? col.id : 'auto')}px` }}>
        <div ref={index ? dragRef : null} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
          {!!index && (
            <DragHandleIconWrapperColumn $show={isHover}>
              <DragHandleIcon />
            </DragHandleIconWrapperColumn>
          )}
          <GridTable.Header color={columnColor && col ? col.color : null} maxHeight={columnHeight}>
            <GridTable.HeaderCell
              colId={col ? col.id : 'auto'}
              width={getColumnWidth(col ? col.id : 'auto')}
              onResizeColumnStop={onResizeColumn}
            >
              <TableHeaderCellContent>{renderHeader({ ...column, isHover })}</TableHeaderCellContent>
            </GridTable.HeaderCell>
          </GridTable.Header>
        </div>
      </TableHeaderCell>
    );
  },
);

Column.displayName = 'Column';

const Row = React.memo(({ index, row, columnsOrder, renderCell, onMoveRow, onChangeRowOrder, managedHeight }) => {
  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);

  const [isHover, setIsHover] = useState(false);

  const [, drop] = useDrop({
    accept: ROW_DRAG_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      onMoveRow(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop() {
      onChangeRowOrder && onChangeRowOrder();
    },
  });

  const [, drag, preview] = useDrag({
    type: ROW_DRAG_TYPE,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(dropRef));
  drag(dragRef);

  const handleOnMouseEnter = useCallback(() => setIsHover(true), []);

  const handleOnMouseLeave = useCallback(() => setIsHover(false), []);

  return (
    <TableRow ref={dropRef}>
      {columnsOrder &&
        columnsOrder.map((column, mapIndex) => (
          <TableBodyCell
            ref={!mapIndex ? dragRef : null}
            key={mapIndex}
            managedHeight={managedHeight}
            onMouseEnter={!mapIndex ? handleOnMouseEnter : undefined}
            onMouseLeave={!mapIndex ? handleOnMouseLeave : undefined}
          >
            {!mapIndex && (
              <DragHandleIconWrapperRow $show={isHover}>
                <DragHandleIcon />
              </DragHandleIconWrapperRow>
            )}
            {(renderCell && renderCell(row[column], dragRef.current)) || row[column]}
          </TableBodyCell>
        ))}
    </TableRow>
  );
});

Column.displayName = 'Column';

const ReactTable = React.memo(
  React.forwardRef(
    (
      {
        columns,
        data,
        colsOrder,
        renderCell,
        columnColor,
        columnHeight,
        getColumnWidth,
        renderHeader,
        changeColumnOrder,
        changeRowOrder,
        resizeColumnStop,
        managedHeight,
      },
      ref,
    ) => {
      const [records, setRecords] = React.useState(data);
      const [columnsOrder, setColumnsOrder] = React.useState(generateColumnsOrder(columns, colsOrder));

      React.useEffect(() => {
        setRecords(data);
      }, [data, setRecords]);

      React.useEffect(() => {
        setColumnsOrder(generateColumnsOrder(columns, colsOrder));
      }, [columns, data, setColumnsOrder]);

      const handleColumnsOrderChange = () => {
        changeColumnOrder && changeColumnOrder(columnsOrder);
      };

      const handleChangeRowOrder = () => {
        changeRowOrder && changeRowOrder(records);
      };

      const handleMoveColumn = (dragIndex, dropIndex) => {
        dropIndex = dragIndex !== 0 && dropIndex === 0 ? 1 : dropIndex;

        const cols = [...columnsOrder];
        const dragColumn = cols.splice(dragIndex, 1)[0];

        cols.splice(dropIndex, 0, dragColumn);

        setColumnsOrder(cols);
      };

      const handleMoveRow = (dragIndex, dropIndex) => {
        const items = [...records];
        const dragRow = items.splice(dragIndex, 1)[0];

        items.splice(dropIndex, 0, dragRow);

        setRecords(items);
      };

      const _renderCell = (param, celRef) => (renderCell ? renderCell(param, celRef) : param);

      return (
        <Box>
          <Table ref={ref}>
            <TableHeader>
              <TableRow>
                {columnsOrder &&
                  columnsOrder.map((column, index) => (
                    <Column
                      key={column}
                      index={index}
                      column={columns[column]}
                      columnColor={columnColor}
                      columnHeight={columnHeight}
                      getColumnWidth={getColumnWidth}
                      renderHeader={renderHeader}
                      onMoveColumn={handleMoveColumn}
                      onResizeColumn={resizeColumnStop}
                      onChangeColumnOrder={handleColumnsOrderChange}
                    />
                  ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {records.map((record, index) => (
                <Row
                  key={index}
                  index={index}
                  row={record}
                  columnsOrder={columnsOrder}
                  renderCell={_renderCell}
                  onMoveRow={handleMoveRow}
                  onChangeRowOrder={handleChangeRowOrder}
                  managedHeight={managedHeight}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      );
    },
  ),
);

ReactTable.displayName = 'ReactTable';

export default ReactTable;

const Box = styled.div`
  &&&& {
  }
`;

const Table = styled.table`
  &&&& {
    border-collapse: collapse;
    border-spacing: 0;

    th {
      box-shadow: inset 0px 1px 0px 0px ${({ theme }) => theme.palette.border.secondary},
        inset 0px -1px 0px 0px ${({ theme }) => theme.palette.border.secondary};
    }

    th:first-child {
      box-shadow: inset 1px 0px 0px 0px ${({ theme }) => theme.palette.border.secondary},
        inset 0px 1px 0px 0px ${({ theme }) => theme.palette.border.secondary},
        inset 0px -1px 0px 0px ${({ theme }) => theme.palette.border.secondary};
    }
  }
`;

Table.displayName = 'Table';

const TableRow = styled.tr`
  &&&& {
    border-bottom: 1px solid ${({ theme }) => theme.palette.border.secondary};
    height: 75px;
  }
`;

TableRow.displayName = 'TableRow';

const TableHeaderCell = styled.th`
  &&&& {
    margin: 0;
    padding: 0;
    position: relative;
    vertical-align: top;
    background: ${({ theme }) => theme.palette.white};

    &:first-child {
      cursor: pointer;
      border-right: 0 !important;
      position: sticky !important;

      left: 0;
      z-index: 1000;

      > div {
      }
    }

    > div {
    }

    &:last-child {
      border-right: 0;
    }
  }
`;

TableHeaderCell.displayName = 'TableHeaderCell';

const TableHeaderCellContent = styled.div`
  &&&& {
  }
`;

TableHeaderCellContent.displayName = 'TableHeaderCellContent';

const TableBodyCell = styled.td`
  &&&& {
    margin: 0;
    padding: 0;
    position: relative;
    ${({ managedHeight }) => (managedHeight ? 'height: inherit;' : '')}

    &:first-child {
      cursor: pointer;
      position: sticky;
      left: 0;
      z-index: 1000;
    }
  }
`;

const TableHeader = styled.thead`
  th {
    position: sticky !important;
    top: 0;
    z-index: 750;
    ${TableHeaderCell} {
      cursor: pointer;

      &:first-child {
        border-bottom: 1px solid ${({ theme }) => theme.palette.border.secondary};

        cursor: unset;
        position: sticky !important;
        background-color: ${({ theme }) => theme.palette.white};
        left: 0;
        top: 0;
        z-index: 750;
      }
    }
  }
`;

const TableBody = styled.tbody`
  &&&& {
    ${TableBodyCell} {
      cursor: pointer;

      &:first-child {
        cursor: unset;
        position: sticky !important;
        background-color: ${({ theme }) => theme.palette.white};
        left: 0;
        z-index: 750;
      }
    }
  }
`;

const DragHandleIconWrapperRow = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  transform: rotate(90deg);
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
`;

const DragHandleIconWrapperColumn = styled.div`
  position: absolute;
  left: calc(50% - 13px);
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
`;
