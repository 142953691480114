import React, { useCallback, useImperativeHandle, useState } from 'react';

import MetadataTreeDropdown from 'containers/MetadataTreeDropdown';

import cellEditorHoc from '../helpers/cellEditorHoc';

const MetadataTreeEditor = cellEditorHoc(({ onChange, forwardedRef, ...params }) => {
  const {
    value,
    stopEditing,
    node: { data },
    displaySelectedValue,
  } = params;

  const [internalValue, setInternalValue] = useState(undefined);

  const [textChanged, setTextChanged] = useState(false);

  const [searchValue, setSearchValue] = useState(null);

  useImperativeHandle(forwardedRef, () => ({
    getValue() {
      if (displaySelectedValue && textChanged && !searchValue) {
        return handleChange();
      }

      return internalValue !== undefined ? internalValue : value;
    },
  }));

  const handleSearchChange = useCallback(searchValue => {
    setTextChanged(true);

    setSearchValue(searchValue);
  }, []);

  const handleChange = selectedItem => {
    setInternalValue(selectedItem?.title);

    onChange(selectedItem, { stopEditing, data });
  };

  return (
    <MetadataTreeDropdown
      {...params}
      onChange={handleChange}
      onSearchChange={handleSearchChange}
      onClose={params.onClose || stopEditing}
    />
  );
});

export default MetadataTreeEditor;
