import React, { useMemo } from 'react';
import isNumber from 'lodash/isNumber';

import MultiSelectAutocomplete from 'design-system/atoms/Autocomplete/v2/MultiSelectAutocomplete';
import useTreeAutocompleteSearch from 'design-system/atoms/Autocomplete/v2/hooks/useTreeAutocompleteSearch';
import useMultiSelectAutocomplete from 'design-system/atoms/Autocomplete/v2/hooks/useMultiSelectAutocomplete';
import useAppliesToAutocompleteOptions from './hooks/useAppliesToAutocompleteOptions';

const findSelectedOption = (options, selectedOptionId) =>
  options.reduce((found, option) => {
    if (found) return found;
    if (option.id === selectedOptionId) return option;
    if (option.children) return findSelectedOption(option.children, selectedOptionId);
    return null;
  }, null);

const parseSelectedOptions = (selectedOptions, options) =>
  selectedOptions?.map(option => {
    const baseOption = isNumber(option) ? findSelectedOption(options, option) : option;

    return { ...baseOption, label: baseOption?.label || baseOption?.title || baseOption?.name };
  });

const MetadataMultiSelectAutocomplete = ({
  name,
  options,
  selectedOptions,
  onChange,
  onCloseList,
  disabled,
  canAddNew,
  onCreateNew,
  placeholder,
  roadmapsKey,
  selectedEntity,
  getSystemFieldName,
  getOptionLabel,
  borderless,
  focusOnInit,
  formatByRoadmap = true,
}) => {
  const parsedSelectedOptions = useMemo(() => parseSelectedOptions(selectedOptions, options), [selectedOptions, options]);

  const metadataOptions = useAppliesToAutocompleteOptions({
    options,
    roadmapsKey,
    selectedEntity,
    getSystemFieldName,
    formatByRoadmap,
  });

  const {
    filteredOptions: optionsWithouSelected,
    onSelectOption,
    onRemoveOption,
    onRemoveAllOptions,
  } = useMultiSelectAutocomplete({
    options: metadataOptions,
    selectedOptions: parsedSelectedOptions,
    onChange,
    onCreateNew,
    getOptionLabel,
  });

  const { filteredOptions, searchValue, onSearchChange, registerExpandAllCallback, registerCollapseAllCallback } =
    useTreeAutocompleteSearch(optionsWithouSelected);

  return (
    <MultiSelectAutocomplete
      name={name}
      options={filteredOptions}
      selectedOptions={parsedSelectedOptions}
      searchValue={searchValue}
      onValueChange={onSearchChange}
      onSelectOption={onSelectOption}
      onRemoveOption={onRemoveOption}
      onRemoveAllOptions={onRemoveAllOptions}
      registerExpandAllCallback={registerExpandAllCallback}
      registerCollapseAllCallback={registerCollapseAllCallback}
      onCloseList={onCloseList}
      canAddNew={canAddNew}
      disabled={disabled}
      placeholder={placeholder}
      borderless={borderless}
      focusOnInit={focusOnInit}
    />
  );
};

export default MetadataMultiSelectAutocomplete;
