/**
 * SelectableItem Molecule Component
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import styled from 'styled-components';

import theme from 'design-system/theme';
import RadioButton from 'design-system/atoms/RadioButton/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


const SelectableItem = ({ label, selected, onChange, ...props }) => {
  const handleContainerClick = () => onChange(!selected);

  const handleRadioChange = e => onChange(e.target.checked);

  return (
    <Container selected={selected} onClick={handleContainerClick} {...props}>
      <StyledRadioButton checked={selected} onChange={handleRadioChange} />
      <TextDeprecated color={theme.palette.text.black}>{label}</TextDeprecated>
    </Container>
  );
};

export default SelectableItem;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.unit * 1.5}px;
  border: ${({ theme, selected }) =>
    `1px solid ${selected ? theme.palette.newLayout.background.duskBlue : theme.palette.newLayout.background.pastelGrey}`};
  border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;
  cursor: pointer;
`;

const StyledRadioButton = styled(RadioButton)`
  && {
    margin-right: ${({ theme }) => theme.spacing.unit * 1.5}px;
    margin-left: 0;

    > span {
      padding: 0;
    }
  }
`;
