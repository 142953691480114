import React from 'react';
import LinkIcon from '@material-ui/icons/Link';
import Grid from '@material-ui/core/Grid';

import PageLink from 'design-system/atoms/PageLink/index';


import JiraButton from './JiraButton';
import EpicLink from './EpicLink';

export default props => {
  const { epic, unlinkJira } = props;

  return (
    <div style={{ marginTop: 10 }}>
      <Grid container>
        <Grid container style={{ marginBottom: '5px' }}>
          <Grid item xs={6}>
            <EpicLink>
              <PageLink href={epic.ticket_url} target="_blank" rel="noopener noreferrer">
                {epic.key}
              </PageLink>
            </EpicLink>
          </Grid>
          <Grid item xs={6}>
            <JiraButton disabled={props.disableActions} size="small" onClick={() => unlinkJira(epic.key)}>
              <LinkIcon style={{ marginRight: 6 }} />
              Unlink Jira
            </JiraButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
