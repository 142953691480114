import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flatten } from 'ramda';

import { getGridConfigValue } from 'store/grids/selectors';
import {
  COMPARE_ROADMAP_HISTORY,
  GRID_COMPARE_FIELDS_CONFIG,
  GRID_HIGHLIGHT_FIELD_CONFIG,
  GRID_SHOW_ITEMS_DIFFERENCES_CONFIG,
} from 'features/RoadmapHistory/constants';
import { saveGridConfig as saveGridConfigAction } from 'store/grids';
import { hasMultiLevelPortfolioMetadata as hasMultiLevelPortfolioMetadataSelector } from 'store/organization';
import useSystemFields from 'hooks/useSystemFields';
import { defaultColumnsToCompareVisible } from '../../components/hooks/useShowColumnsRoadmapHistory';
import { columnToMetadataMap } from 'features/RoadmapHistory/store/helpers/projects';

const getHightlightByOptions = (getSystemFieldName, hasMultiLevelPortfolioMetadata) => [
  { key: null, title: 'No highlight' },
  { key: 'timeframeTitle', title: getSystemFieldName('timeframe') },
  ...(hasMultiLevelPortfolioMetadata ? [{ key: 'timeframe2Title', title: getSystemFieldName('timeframe2') }] : []),
  { key: 'status_color', title: 'Health' },
  { key: 'progress', title: 'Progress' },
];

const parentKeys = ['bet', 'initiative'];
const isNotParentOption = key => !parentKeys.includes(key);

const filterOutGroupingOptions = (groupByOptions, columnsToCompareIds) => {
  const fieldsToIgnore = flatten(columnsToCompareIds.map(column => columnToMetadataMap[column] || column));

  return groupByOptions.filter(({ key }) => !fieldsToIgnore.includes(key) && isNotParentOption(key));
};

const useRoadmapHistoryLeftControls = ({ groupByOptions }) => {
  const dispatch = useDispatch();
  const [getSystemFieldName] = useSystemFields();
  const hasMultiLevelPortfolioMetadata = useSelector(hasMultiLevelPortfolioMetadataSelector);
  const columnsToCompareIds = useSelector(state =>
    getGridConfigValue(state, COMPARE_ROADMAP_HISTORY, GRID_COMPARE_FIELDS_CONFIG, defaultColumnsToCompareVisible),
  );

  const showItemsWithDifferences = useSelector(state =>
    getGridConfigValue(state, COMPARE_ROADMAP_HISTORY, GRID_SHOW_ITEMS_DIFFERENCES_CONFIG, true),
  );
  const highlightField = useSelector(state => getGridConfigValue(state, COMPARE_ROADMAP_HISTORY, GRID_HIGHLIGHT_FIELD_CONFIG));

  const onToggleItemsWithDifferences = () =>
    dispatch(saveGridConfigAction(COMPARE_ROADMAP_HISTORY, GRID_SHOW_ITEMS_DIFFERENCES_CONFIG, !showItemsWithDifferences));

  const onHighlightFieldChange = field =>
    dispatch(saveGridConfigAction(COMPARE_ROADMAP_HISTORY, GRID_HIGHLIGHT_FIELD_CONFIG, field));

  const highlightByOptions = useMemo(() => {
    return getHightlightByOptions(getSystemFieldName, hasMultiLevelPortfolioMetadata);
  }, [getSystemFieldName, hasMultiLevelPortfolioMetadata]);

  return {
    highlightField,
    highlightByOptions,
    roadmapHistoryGroupByOptions: filterOutGroupingOptions(groupByOptions, columnsToCompareIds),
    onHighlightFieldChange,
    showItemsWithDifferences,
    onToggleItemsWithDifferences,
  };
};

export default useRoadmapHistoryLeftControls;
