import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import withClickOutside from 'react-click-outside';
import Portal from '@material-ui/core/Portal';

import SecondaryColorPicker from 'design-system/atoms/SecondaryColorPicker/index';
import MicroChip from 'design-system/atoms/MicroChip/index';

import { materialColorsArr, materialColorsAlt } from 'design-system/themes/default';
import invertedTextColor from 'design-system/utils/invertedTextColor';

class ColorToolbar extends Component {
  menuRef = null;
  constructor(props) {
    super(props);

    this.state = {
      showColorPicker: false,
      pickerLeftOffset: 0,
      activeGroup: {},
    };

    this.handleColorPickStart = this.handleColorPickStart.bind(this);
    this.handleColorPickComplete = this.handleColorPickComplete.bind(this);
  }

  handleClickOutside() {
    this.setState({ showColorPicker: false });
  }

  handleColorPickStart(e, group) {
    const { showOnTop } = this.props;
    const { showColorPicker, activeGroup } = this.state;
    const node = ReactDOM.findDOMNode(e.currentTarget); // eslint-disable-line

    if (showColorPicker === true && activeGroup.id === group.id) {
      this.setState({
        showColorPicker: false,
      });
    } else {
      const left = e.clientX;
      const top = e.clientY;

      this.setState({
        showColorPicker: true,
        pickerLeftOffset: left,
        pickerTop: showOnTop ? top - 265 : top + 8,
        activeGroup: { ...group, color: group.color || materialColorsAlt[0] },
      });
    }
  }

  handleColorPickComplete(color) {
    this.props.onUpdateGroupColor(this.state.activeGroup, color.hex);
    this.setState({ activeGroup: { ...this.state.activeGroup, color: color.hex } });
    // this.setState({
    //   // showColorPicker: false,
    //   // activeGroup: {},
    // });
  }

  onColorPickerOut = () => {
    this.setState({ showColorPicker: false });
  };

  render() {
    const { classes, groups, showPicker, showOnTop } = this.props;

    const { showColorPicker, activeGroup, pickerLeftOffset, pickerTop } = this.state;

    return (
      <div className={classes.infoContainer}>
        <div
          style={{ position: !showOnTop ? 'relative' : '' }}
          ref={r => {
            this.menuRef = r;
          }}
        >
          <ul className={classes.colorLegend}>
            {groups
              .filter(group => group !== undefined)
              .map(group => (
                <li className={classes.colorLi} key={group.title || ''}>
                  <MicroChip
                    label={group.title || ''}
                    color={group.color}
                    textColor={invertedTextColor(group.color, true)}
                    onClick={showPicker ? e => this.handleColorPickStart(e, group) : undefined}
                    fullWidth
                  />
                </li>
              ))}
          </ul>
          {showColorPicker && (
            <Portal>
              <div
                style={{
                  left: pickerLeftOffset,
                  top: pickerTop,
                  position: 'absolute',
                  zIndex: 100000,
                }}
              >
                <SecondaryColorPicker
                  colors={materialColorsArr}
                  color={activeGroup.color}
                  triangle={showOnTop ? 'hide' : 'top'}
                  onChange={this.handleColorPickComplete}
                  handleClose={this.onColorPickerOut}
                />
              </div>
            </Portal>
          )}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  infoContainer: {
    display: 'inline-flex',
    // marginBottom: '15px',
  },
  colorLegend: {
    margin: 0,
  },
  colorLi: {
    display: 'inline-block',
    marginRight: 8,
    overflow: 'hidden',
  },
  colorLabel: {
    display: 'inline-block',
    verticalAlign: 'text-top',
  },
  colorSquare: {
    display: 'inline-block',
    width: '1rem',
    height: '1rem',
    marginLeft: '5px',
    cursor: 'pointer',
  },
  colorPickerContainer: {
    position: 'absolute',
    zIndex: 10000000,
  },
});

export default compose(withStyles(styles), withClickOutside)(ColorToolbar);
