import React, { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { defaultTo } from 'ramda';

import NewDialog from 'design-system/organisms/NewDialog/index';
import DialogActions from 'design-system/molecules/DialogActions/index';

import RequestsMergeModal from 'features/RequestsMergeModal';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/TooltipOnOverflow';
import RequestsBulkUpdateModal from 'features/RequestsBulkUpdateModal';
import useGridApiContext from 'hooks/grid/useGridApiContext';

import { ActionsWrapper, HeaderWrapper, TableWrapper, Title } from './styles';
import RequestsGrid from './components/RequestsGrid';
import { REQUESTS_GRID_MODAL } from './constants';

const defaultAsEmptyArray = defaultTo([]);

const GRID_HEIGHT = 'calc(100vh - 276px)';

const MERGE_KEY = 'merge';
const BULK_UPDATE_KEY = 'bulk-update';

const RequestsGridModal = ({ customerRequests, title, isOpen, handleClose }) => {
  const [bulkUpdateActive, setBulkUpdateActive] = React.useState(null);
  const [showMergeModal, setShowMergeModal] = React.useState(false);
  const [showBulkUpdateModal, setShowBulkUpdateModal] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState([]);

  const { canUpdate, canView } = usePermissions();

  const { getGridApi } = useGridApiContext();

  const userCanViewMerge = canView(PERMISSION_FEATURES.requestsMerge);
  const userCanViewBulkUpdate = canView(PERMISSION_FEATURES.requestsListBulkUpdate);

  const selectedItemsAllowedForMerge = defaultAsEmptyArray(selectedItems).filter(i =>
    canUpdate(PERMISSION_FEATURES.requestsMerge, i),
  );
  const selectedItemsAllowedForUpdate = defaultAsEmptyArray(selectedItems).filter(i =>
    canUpdate(PERMISSION_FEATURES.requestsListBulkUpdate, i),
  );

  const handleCloseMergeDialog = () => {
    setShowMergeModal(false);
  };

  const renderModalHeader = () => (
    <HeaderWrapper>
      <TooltipOnOverflow text={title}>
        <Title as="h3">{title}</Title>
      </TooltipOnOverflow>
      <ActionsWrapper>
        {bulkUpdateActive === MERGE_KEY && (
          <>
            <Button color="primary" disabled={selectedItemsAllowedForMerge.length < 2} onClick={() => setShowMergeModal(true)}>
              Merge
            </Button>
            <Button onClick={() => setBulkUpdateActive(null)}>Cancel</Button>
          </>
        )}
        {bulkUpdateActive === BULK_UPDATE_KEY && (
          <>
            <Button
              color="primary"
              disabled={selectedItemsAllowedForUpdate.length === 0}
              onClick={() => setShowBulkUpdateModal(true)}
            >
              Update
            </Button>
            <Button onClick={() => setBulkUpdateActive(null)}>Cancel</Button>
          </>
        )}
        {!bulkUpdateActive && userCanViewMerge && <Button onClick={() => setBulkUpdateActive(MERGE_KEY)}>Merge</Button>}
        {!bulkUpdateActive && userCanViewBulkUpdate && (
          <Button onClick={() => setBulkUpdateActive(BULK_UPDATE_KEY)}>Bulk Update</Button>
        )}
      </ActionsWrapper>
    </HeaderWrapper>
  );

  const resetSelection = () => {
    setBulkUpdateActive(null);
    setSelectedItems([]);

    const gridApi = getGridApi(REQUESTS_GRID_MODAL);

    if (gridApi) {
      gridApi.deselectAll();
    }
  };

  useEffect(() => {
    resetSelection();
  }, [isOpen]);

  return (
    <>
      <NewDialog
        id="cluster-grid-data-lightbox"
        open={isOpen}
        onClose={handleClose}
        maxWidth="lg"
        scroll="paper"
        fullWidth
        fullHeight
        closeButton
        header={renderModalHeader()}
        actions={<DialogActions cancelLabel="Close" onCancel={handleClose} />}
      >
        <TableWrapper>
          <RequestsGrid
            customerRequests={customerRequests}
            isBulkUpdate={!!bulkUpdateActive}
            gridHeight={GRID_HEIGHT}
            setSelectedItems={setSelectedItems}
          />
        </TableWrapper>
      </NewDialog>
      {showMergeModal && (
        <RequestsMergeModal
          selectedItems={selectedItemsAllowedForMerge}
          isVisible={showMergeModal}
          hideDialog={handleCloseMergeDialog}
          disable={() => setShowMergeModal(false)}
        />
      )}
      {showBulkUpdateModal && (
        <RequestsBulkUpdateModal
          selectedItems={selectedItemsAllowedForUpdate}
          onClose={() => setShowBulkUpdateModal(false)}
          onBulkUpdateFinished={resetSelection}
        />
      )}
    </>
  );
};

export default RequestsGridModal;
