import React, { useCallback } from 'react';

import Autocomplete from 'design-system/atoms/Autocomplete/v2';
import useTreeAutocompleteSearch from 'design-system/atoms/Autocomplete/v2/hooks/useTreeAutocompleteSearch';

import useAppliesToAutocompleteOptions from './hooks/useAppliesToAutocompleteOptions';
import { flattenItems, getLevelTitlePropertyName } from './helpers/componentHelpers';

const emptyArray = [];

const AppliesToAutocomplete = ({
  name,
  options = emptyArray,
  roadmapsKey,
  roadmapKeysByLevel,
  selectedEntity,
  getSystemFieldName,
  disabled = false,
  onCloseList,
  placeholder,
  focusOnInit,
  withNullOption,
  levelZeroFieldName,
  levelOneFieldName,
  levelTwoFieldName,
  onSelectOptionCallback,
  canAddNew,
  formatByRoadmap,
  renderPopperOnPortal,
}) => {
  const hasLevelCorp = !!levelZeroFieldName;
  const metadataOptions = useAppliesToAutocompleteOptions({
    options,
    roadmapsKey,
    roadmapKeysByLevel,
    selectedEntity,
    getSystemFieldName,
    withNullOption,
    formatByRoadmap,
  });

  const { searchValue, filteredOptions, onSearchChange, registerExpandAllCallback, registerCollapseAllCallback } =
    useTreeAutocompleteSearch(metadataOptions);

  const onSelectOption = useCallback(
    selectedItem => {
      const flatOptions = flattenItems(options);

      const levelCorpTitle = getLevelTitlePropertyName(levelZeroFieldName);
      const levelOneTitle = getLevelTitlePropertyName(levelOneFieldName);
      const levelTwoTitle = getLevelTitlePropertyName(levelTwoFieldName);

      const isCreatingNew = canAddNew && selectedItem?.isNew;

      if (!selectedItem?.id && !isCreatingNew) {
        return onSelectOptionCallback({
          ...(hasLevelCorp ? { [levelCorpTitle]: null } : {}),
          [levelOneTitle]: null,
          [levelTwoTitle]: null,
        });
      }
      if (selectedItem?.parent_id == null) {
        if (hasLevelCorp) {
          return onSelectOptionCallback({
            [levelCorpTitle]: selectedItem?.title,
            [levelOneTitle]: null,
            [levelTwoTitle]: null,
          });
        }
        return onSelectOptionCallback({ [levelOneTitle]: selectedItem?.title || selectedItem?.name, [levelTwoTitle]: null });
      }

      const parentIdItem = flatOptions.find(item => item.id === selectedItem.parent_id);

      // If it has 3 levels
      if (hasLevelCorp) {
        if (parentIdItem && parentIdItem.parent_id) {
          const grandParentIdItem = flatOptions.find(item => item.id === parentIdItem.parent_id);

          return onSelectOptionCallback({
            [levelCorpTitle]: grandParentIdItem?.title,
            [levelOneTitle]: parentIdItem?.title,
            [levelTwoTitle]: selectedItem.title,
          });
        }
        return onSelectOptionCallback({
          [levelCorpTitle]: parentIdItem?.title,
          [levelOneTitle]: selectedItem?.title,
          [levelTwoTitle]: null,
        });
      }

      return onSelectOptionCallback({
        [levelOneTitle]: parentIdItem?.title,
        [levelTwoTitle]: selectedItem.title,
      });
    },
    [options],
  );

  return (
    <Autocomplete
      name={name}
      searchValue={searchValue}
      options={filteredOptions}
      disabled={disabled}
      onSelectOption={onSelectOption}
      onValueChange={onSearchChange}
      onCloseList={onCloseList}
      registerExpandAllCallback={registerExpandAllCallback}
      registerCollapseAllCallback={registerCollapseAllCallback}
      placeholder={placeholder}
      focusOnInit={focusOnInit}
      canAddNew={canAddNew}
      renderPopperOnPortal={renderPopperOnPortal}
    />
  );
};

export default AppliesToAutocomplete;
