import { css } from 'styled-components';

const ADD_CELL_CLASS = 'okr-table-add-item-cell';

const stylesOverride = css`
  // Start copied from old version
  // routes/Dashboard/Snapshot/SnapshotTable/SnapshotTableContainer.jsx Wrapper

  .ag-layout-normal.ag-root {
    margin: 0;
  }

  .ag-header {
    .ag-header-cell-resize {
      top: 0;
    }
    .ag-header-cell-resize::after {
      margin-top: 0;
      height: 100%;
    }
  }

  .ag-icon-expanded,
  .ag-group-expanded .ag-icon-contracted:empty {
    margin-top: 12px !important;
  }

  .ag-row .ag-cell {
    & > div {
      display: flex !important;
      align-items: center !important;
    }

    &.align-items-top > div {
      align-items: unset !important;
      justify-content: start;
      height: auto;
      max-height: 100%;
    }
  }

  // icons that expand or collapse tree view on ag grid
  .ag-group-contracted .ag-icon.ag-icon-expanded,
  .ag-theme-material .ag-group-expanded .ag-icon.ag-icon-contracted:empty {
    margin-top: 9px !important;
  }

  .ag-row .title-cell.ag-cell-not-inline-editing {
    padding-left: 24px !important;
    padding-top: 2px !important;

    div {
      color: ${({ theme }) => theme.palette.text.primary};
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
      line-height: ${({ theme }) => theme.typography.lineHeightSmallLargeRem};
      width: 100%;
      display: flex !important;
      align-items: center !important;

      & div:first-child {
        width: ${({ treeView }) => treeView && `calc(100% - 20px) !important`};
      }

      & div:not(:first-child) {
        width: 26px !important;
      }

      svg {
        top: 19px !important;
        right: 0 !important;
      }
    }

    p {
      color: ${({ theme }) => theme.palette.text.primary};
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
      line-height: ${({ theme }) => theme.typography.lineHeightSmallLargeRem};
    }
  }

  .ag-row-hover {
    background-color: #f9f9f9 !important;
  }

  .ag-paging-panel {
    height: 60px !important;
  }

  // End copied from old version

  .ag-row-hover .${ADD_CELL_CLASS} {
    visibility: visible;
  }

  .${ADD_CELL_CLASS} {
    visibility: hidden;
  }

  // Position caret on center for different row heights
  .ag-group-expanded.ag-group-expanded.ag-group-expanded .ag-icon.ag-icon-tree-open:before,
  .ag-group-contracted.ag-group-contracted.ag-group-contracted .ag-icon.ag-icon-tree-closed:before {
    top: calc(50% - (17px / 2)); // 17px is line-height
  }

  &&&& {
    .objective-corp-row {
      background-color: ${({ theme }) => theme.palette.background.gallery};
    }
  }
`;

export { ADD_CELL_CLASS, stylesOverride };
