import { defaultTo } from 'ramda';
import flatten from 'lodash/flatten';

import { createThunk } from 'utils/store/thunk';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import { fetchFromProjectsApi } from 'store/projects/helpers/fetchProjects';

import { APPLY_FILTERS_WHITEBOARD, UPDATE_METADATA_ENTITIES_WHITEBOARD } from './types';

import getStore from 'store/store';
import { TRANSACTION_PAGES_PROJECTS_LIMIT } from 'constants';

const defaultToEmptyArray = defaultTo([]);
const defaultToEmptyObject = defaultTo({});
const paginationAttributes = {
  from: 0,
  to: TRANSACTION_PAGES_PROJECTS_LIMIT,
};

export const applyFiltersOnWhiteboard =
  ({ filters, userFilters, hasBet, pageId, displayLayer }) =>
  (dispatch, getState) => {
    const state = getState();
    const objectives = defaultToEmptyArray(state?.objectives?.objectives);
    const keyResults = defaultToEmptyArray(flatten(Object.values(defaultToEmptyObject(state?.objectives?.keyResults))));
    const timeframes = defaultToEmptyArray(state?.timeframes?.get('rows')?.toJS());
    const metrics = defaultToEmptyArray(state?.metrics?.metrics);

    const result = new Promise(async (resolve, reject) => {
      const filtersForApiByLevels = compileFiltersBody(filters, userFilters, hasBet, pageId, displayLayer);

      try {
        const { projectsResult, projectsSearchMetadataResult } = await fetchFromProjectsApi({
          getStore,
          params: filtersForApiByLevels,
          paginationAttributes,
        });

        resolve({ projects: projectsResult, objectives, keyResults, timeframes, metrics, projectsSearchMetadataResult });
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });

    dispatch(createThunk(APPLY_FILTERS_WHITEBOARD, result));
  };

export const updateMetadataEntitiesOnWhiteboard = () => (dispatch, getState) => {
  const state = getState();
  const objectives = defaultToEmptyArray(state?.objectives?.objectives);
  const keyResults = defaultToEmptyArray(flatten(Object.values(defaultToEmptyObject(state?.objectives?.keyResults))));
  const timeframes = defaultToEmptyArray(state?.timeframes?.get('rows')?.toJS());
  const metrics = defaultToEmptyArray(state?.metrics?.metrics);

  dispatch(createThunk(UPDATE_METADATA_ENTITIES_WHITEBOARD, { objectives, keyResults, timeframes, metrics }));
};
