import moment from 'moment';
import { defaultTo, isNil, path, pipe, not } from 'ramda';

import { getBottomTasks } from 'utils';
import isNumeric from 'utils/isNumeric';

import calcDurationBasedOnTimeline from './calcDurationBasedOnTimeline';

const defaultToNull = defaultTo(null);
const isNotNil = pipe(isNil, not);

/**
 * Calculate project duration based on project data using the following fall-back mechanism:
 *  1. use estimates duration if available
 *  2. use tasks duration if available
 *  3. calculate weeks based on project effort score if available and greater than 0
 *  4. calculate based on project timeline dates if it has owner
 *
 * @param {Object} project
 * @param {Number | undefined} teamSkillId
 * @param {String | undefined} teamSkillType
 */
export default (project, teamSkillId, teamSkillType) => {
  const shouldCheckTeamSkillId = isNotNil(teamSkillType);
  const sumByDuration = (sum, entity) => sum + calcDurationBasedOnTimeline(entity);
  let duration = 0;

  if (project.estimates && project.estimates.length) {
    const ests = shouldCheckTeamSkillId
      ? project.estimates.filter(e => e[`${teamSkillType}_id`] === teamSkillId)
      : project.estimates;

    duration = ests.reduce(sumByDuration, 0);
  } else if (project.tasks && project.tasks.length) {
    const bottomTasks = getBottomTasks(project);
    const tasks = shouldCheckTeamSkillId
      ? bottomTasks.filter(t => t.owner && t.owner[`${teamSkillType}_id`] === teamSkillId)
      : bottomTasks;

    duration = tasks.reduce(sumByDuration, 0);
  } else if (project.effort_score && !shouldCheckTeamSkillId) {
    // switch order to be consistent with BE report
    duration = parseInt(project.effort_score) * 7;
  } else {
    const hasOwner = isNotNil(project.owner_id) || isNotNil(project.owner);
    const projectStartDate = moment(defaultToNull(project.estimated_start_date));
    const projectEndDate = moment(defaultToNull(project.deadline));
    const areProjectsDatesValid = projectStartDate.isValid() && projectEndDate.isValid();

    if (hasOwner && areProjectsDatesValid) {
      const ownerTeamSkillValue = path(['owner', `${teamSkillType}_id`])(project);
      const ownerTeamSkillMatches = ownerTeamSkillValue === teamSkillId;

      if ((shouldCheckTeamSkillId && ownerTeamSkillMatches) || !shouldCheckTeamSkillId) {
        duration = calcDurationBasedOnTimeline(project);
      }
    }
  }

  return isNumeric(duration) ? duration : 0;
};
