import {
  keyResultsAdapter,
  metricsAdapter,
  objectivesAdapter,
  projectsAdapter,
  timeframesAdapter,
} from 'features/MetricsCanvas/store/helpers/adapters';
import { createSelector } from 'reselect';
import { defaultTo } from 'ramda';
import getOwnerUsersById from 'features/Canvas/helpers/getOwnerUsers';
import { getUsers } from 'store/users/selectors';
import { selectMetricLevels } from 'features/MetricLevels/store';

const emptyObject = {};

const defaultToProjectsInitialState = defaultTo(projectsAdapter.getInitialState());
const defaultToObjectivesInitialState = defaultTo(objectivesAdapter.getInitialState());
const defaultToKeyResultsInitialState = defaultTo(keyResultsAdapter.getInitialState());
const defaultToTimeframesInitialState = defaultTo(timeframesAdapter.getInitialState());
const defaultToMetricsInitialState = defaultTo(metricsAdapter.getInitialState());

export const selectMetricsCanvasLayoutConfig = state => state.metricsCanvas?.layoutConfig || emptyObject;
export const selectMetricsCanvasNodesLayout = state => state.metricsCanvas?.nodesLayout || emptyObject;
export const selectMetricsCanvasEdgesLayout = state => state.metricsCanvas?.edgesLayout || emptyObject;
export const selectMetricsCanvasUserActionsMeta = state => state.metricsCanvas?.userActionsMeta || emptyObject;

const getState = state => state?.metricsCanvas;

export const projectsSelector = projectsAdapter.getSelectors(state => {
  return defaultToProjectsInitialState(state.projects);
});

export const objectivesSelector = objectivesAdapter.getSelectors(state => {
  return defaultToObjectivesInitialState(state.objectives);
});

const keyResultsSelector = keyResultsAdapter.getSelectors(state => {
  return defaultToKeyResultsInitialState(state.keyResults);
});

const timeframesSelector = timeframesAdapter.getSelectors(state => {
  return defaultToTimeframesInitialState(state.timeframes);
});

export const metricsSelector = metricsAdapter.getSelectors(state => {
  return defaultToMetricsInitialState(state.metrics);
});

export const selectDataForMetricsCanvas = createSelector(
  state => projectsSelector.selectAll(getState(state)),
  state => objectivesSelector.selectAll(getState(state)),
  state => keyResultsSelector.selectAll(getState(state)),
  state => timeframesSelector.selectAll(getState(state)),
  state => metricsSelector.selectAll(getState(state)),
  getUsers,
  selectMetricLevels,
  (projects, objectives, keyResults, timeframes, metrics, users, metricLevels) => {
    return {
      projectsList: projects,
      objectivesList: objectives,
      keyResultsList: keyResults,
      timeframesList: timeframes,
      metricsList: metrics,
      usersList: getOwnerUsersById(users, [...projects, ...objectives, ...keyResults, ...metrics]),
      metricLevels,
    };
  },
);
