import React, { useMemo } from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormLabel from '@material-ui/core/FormLabel';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import MicroChip from 'design-system/atoms/MicroChip';
import flattenItemsWithChildren from 'utils/flattenItemsWithChildren';
import getIdsFromDndList from 'utils/getIdsFromDndList';

export default ({ label, placeholder, items, selectedItems, onOpen, onChange }) => {
  const handleChipDelete = itemId => onChange(deleteItem(itemId));

  const deleteItem = itemId => {
    const updatedItems = { ...selectedItems };

    const deleteItemRecursive = items => {
      Object.keys(items).forEach(key => {
        if (key === itemId.toString()) {
          items[key] = false;
        } else if (typeof items[key] === 'object' && items[key] !== null) {
          deleteItemRecursive(items[key]);
        }
      });
    };

    deleteItemRecursive(updatedItems);

    return updatedItems;
  };

  const filteredItemsBySelected = useMemo(() => {
    const allItems = flattenItemsWithChildren(items);
    const selectedItemsIds = getIdsFromDndList(selectedItems);

    return allItems.filter(item => selectedItemsIds?.includes(item.id?.toString()));
  }, [items, selectedItems]);

  const value = useMemo(() => items.map(item => item.id), [items]);

  return (
    <FormControl fullWidth>
      <StyledFormLabel control="legend">{label}</StyledFormLabel>
      <SelectContainer>
        <StyledSelect
          multiple
          value={value}
          open={false}
          onOpen={onOpen}
          placeholder={placeholder}
          IconComponent={StyledArrowIcon}
          renderValue={() => (
            <ChipsContainer>
              {filteredItemsBySelected.map(item => (
                <StyledMicroChip
                  key={item.id}
                  label={item.label}
                  onDelete={() => handleChipDelete(item.id)}
                  deleteIcon={<CloseIcon fontSize="small" />}
                />
              ))}
            </ChipsContainer>
          )}
        />
        <StyledCloseIcon onClick={() => onChange({})} />
      </SelectContainer>
    </FormControl>
  );
};

const StyledSelect = styled(Select)`
  > div > div:focus {
    background: inherit;
  }
`;

const SelectContainer = styled.div`
  position: relative;

  > div {
    width: 100%;
    min-height: 42px;
  }
`;

const StyledFormLabel = styled(FormLabel)`
  &&& {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const ChipsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const StyledMicroChip = styled(MicroChip)`
  &&&& {
    background-color: ${({ theme }) => theme.palette.background.white};
    border: 1px solid ${({ theme }) => theme.palette.background.gainsboro};
    border-radius: 20px;
    height: auto;
    padding: 2px 0 2px 8px;

    span {
      padding: 0;
      color: ${({ theme }) => theme.palette.text.black};
      font-size: ${({ theme }) => theme.typography.fontSizeSmall}px;
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    }

    svg {
      font-size: 15px;
      margin-left: -4px;
      margin: 0 8px;
    }
  }
`;

const StyledArrowIcon = styled(KeyboardArrowDownIcon)`
  && {
    font-size: 23px;
    color: hsl(0, 0%, 80%);
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  && {
    position: absolute;
    top: 50%;
    right: 25px;
    font-size: 17px;
    color: hsl(0, 0%, 80%);
    transform: translateY(-50%);

    path:first-child {
      stroke: currentColor;
    }

    &:hover {
      color: hsl(0, 0%, 60%);
    }
  }
`;
