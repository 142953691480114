import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
import MenuList from '@material-ui/core/MenuList';
import MenuListItem from '@material-ui/core/MenuItem';
import MenuListItemIcon from '@material-ui/core/ListItemIcon';
import MenuListItemText from '@material-ui/core/ListItemText';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';

import DndList from 'design-system/organisms/DndList/index';

import { MenuItemContainer } from 'design-system/atoms/DndAtoms';

const itemHeight = 40;
const menuWidth = 220;

const ChildMenu = ({ item, handleSelect }) => {
  return (
    <ChildMenuContainer>
      <Popper open style={{ zIndex: 1060, position: 'relative' }} transition disablePortal onClick={e => e.stopPropagation()}>
        {item.useDndList ? (
          <DndList
            items={item.children}
            isSubTree
            onClickItem={handleSelect}
            width={menuWidth}
            itemHeight={itemHeight}
            showTooltip
          />
        ) : (
          <Paper>
            <MenuList>
              {item.children.map(childItem => (
                <MenuItem item={childItem} handleSelect={handleSelect} isChild />
              ))}
            </MenuList>
          </Paper>
        )}
      </Popper>
    </ChildMenuContainer>
  );
};

const MenuItem = ({ item, handleSelect, isChild }) => {
  const [showChildren, setShowChildren] = useState(false);

  const hasChildren = item.children?.length > 0;
  const displayChildren = hasChildren > 0 && showChildren;

  const handleItemClick = e => {
    e.stopPropagation();
    handleSelect(item);
  };

  const itemLabel = isChild ? (
    <Tooltip title={item.label}>
      <StyledChildMenuListItemText isActive={item.isActive} styles={item.styles}>
        {item.label}
      </StyledChildMenuListItemText>
    </Tooltip>
  ) : (
    <StyledMenuListItemText styles={item.styles}>{item.label}</StyledMenuListItemText>
  );

  if (item.isDivider) {
    return <StyledDivider />;
  }

  return (
    <StyledMenuListItem
      key={item.key}
      id={item.id}
      onClick={handleItemClick}
      onMouseEnter={() => setShowChildren(true)}
      onMouseLeave={() => setShowChildren(false)}
      isChild={isChild}
      data-testid={item.testId}
    >
      {item.icon && <StyledMenuListItemIcon isActive={item.isActive}>{item.icon}</StyledMenuListItemIcon>}
      {itemLabel}
      {hasChildren && <StyledChevron />}
      {displayChildren && <ChildMenu item={item} handleSelect={handleSelect} />}
    </StyledMenuListItem>
  );
};

const MultiLevelDragonMenu = ({
  items,
  anchorEl,
  onSelectItem,
  onSetAnchorEl,
  placement = 'bottom-start',
  dataTestId,
  ...rest
}) => {
  const open = Boolean(anchorEl);

  const _handleClose = () => {
    onSetAnchorEl(null);
  };

  const _handleSelectItem = item => {
    onSelectItem(item);
    _handleClose();
  };

  return (
    <Wrapper
      open={open}
      anchorEl={anchorEl}
      role={undefined}
      placement={placement}
      style={{ zIndex: 1060, position: 'relative' }}
      transition
      disablePortal
      {...rest}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: 'right top',
            marginTop: 2,
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={_handleClose}>
              <MenuList data-testid={dataTestId}>
                {items && items.map(item => !item.hide && <MenuItem item={item} handleSelect={_handleSelectItem} />)}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Wrapper>
  );
};

const ChildMenuContainer = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  padding-left: 2px;

  ${MenuItemContainer} {
    height: ${itemHeight}px !important;
    place-content: center;
  }
`;

const StyledMenuListItem = styled(MenuListItem)`
  &&&& {
    position: relative;
    overflow: unset;
    height: 18px;

    ${ChildMenuContainer} {
      display: none;
    }

    &:hover {
      ${ChildMenuContainer} {
        display: block;
      }
    }
  }
`;

const StyledMenuListItemIcon = styled(MenuListItemIcon)`
  &&&& {
    margin-right: 0;

    svg {
      width: 1em;
    }

    svg path:not([fill='none']) {
      fill: ${props =>
        props.isActive ? props.theme.palette.newLayout.background.primary : props.theme.palette.newLayout.background.disable};
    }

    svg circle,
    svg path:not([fill='none']) {
      color: ${props =>
        props.isActive ? props.theme.palette.newLayout.background.primary : props.theme.palette.newLayout.background.disable};
    }
  }
`;

const StyledMenuListItemText = styled(MenuListItemText)`
  &&&& {
    padding-right: 10px;

    span {
      font-size: ${props => props.theme.typography.fontSize}px;
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      color: ${props => props.theme.palette.text.lightGrey};
      ${({ styles }) => styles};
    }
  }
`;

const StyledChildMenuListItemText = styled(MenuListItemText)`
  &&&& {
    padding-right: 10px;

    span {
      font-size: ${props => props.theme.typography.fontSize}px;
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      color: ${props => (props.isActive ? props.theme.palette.newLayout.background.primary : props.theme.palette.text.lightGrey)};
      ${props => props.styles};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 35ch;
    }
  }
`;

const Wrapper = styled(Popper)`
  &&&& {
    display: block;
    // position: relative !important;
  }
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 4px 10px;
  }
`;

const StyledChevron = styled(ChevronRight)`
  && {
    font-size: ${props => props.theme.typography.fontSizeSmallLarge}px;
    fill: ${props => props.theme.palette.text.lightGrey};
  }
`;

MultiLevelDragonMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.objectOf({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      icon: PropTypes.any,
      label: PropTypes.string,
    }),
  ),
  anchorEl: PropTypes.any,
  onSelectItem: PropTypes.func,
  onSetAnchorEl: PropTypes.func,
};

export default MultiLevelDragonMenu;
