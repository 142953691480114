import React, { useEffect } from 'react';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { values, not } from 'ramda';

import Promote from 'design-system/atoms/DragonIcons/Inherit';
import FieldsLayout from 'features/FieldsLayout';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import usePermissions from 'hooks/permissions/usePermissions';
import { getCustomerRequestsCustomFields, getRequestsCustomFieldsSharedWithProjects } from 'store/customFields/selectors';

import useRequestsDetailFieldsLayout from './hooks/useRequestsDetailFieldsLayout';
import getFieldsAvailableToBeRequired from './helpers/getFieldsAvailableToBeRequired';
import useTempConfigurationOnFieldsLayout from './hooks/useTempConfigurationOnFieldsLayout';
import getFieldsAvailableToBePromoted from './helpers/getFieldsAvailableToBePromoted';

const PromoteButton = ({ active }) => {
  return (
    <Tooltip title="Promote">
      <PromoteIconWrapper active={active}>
        <Promote />
      </PromoteIconWrapper>
    </Tooltip>
  );
};

const getFieldsToPromote = fields => {
  const allFields = values(fields);

  return allFields.filter(f => not(f.hidden) && f.promote === true);
};

export default ({ anchorEl, isOpen, onClose }) => {
  const customFields = useSelector(getCustomerRequestsCustomFields);
  const customFieldsSharedWithProjects = useSelector(getRequestsCustomFieldsSharedWithProjects);

  const { fields, upsertFieldsLayout } = useRequestsDetailFieldsLayout();
  const { fieldslayout, onChange, setTemporaryFieldsLayout } = useTempConfigurationOnFieldsLayout();

  const handleSave = () => {
    upsertFieldsLayout(fieldslayout);

    onClose();
  };

  const permissions = usePermissions();
  const editable = permissions.canUpdate(PERMISSION_RESOURCES.fieldsLayout);

  const availableFieldsToBeRequired = getFieldsAvailableToBeRequired({ customFields });
  const availableFieldsToBePromoted = getFieldsAvailableToBePromoted({ customFields: customFieldsSharedWithProjects });
  const promoteFieldsIds = getFieldsToPromote(fieldslayout).map(f => f.id);

  const _renderPromoteIcon = item => {
    if (!availableFieldsToBePromoted.includes(item.id)) {
      return null;
    }

    return <PromoteButton editable={editable} active={promoteFieldsIds.includes(item.id)} />;
  };

  const _onClickPromoteButton = item => {
    onChange({
      ...fieldslayout,
      [item.id]: {
        ...fieldslayout[item.id],
        promote: !promoteFieldsIds.includes(item.id),
      },
    });
  };

  const itemRightActions = [
    {
      renderIcon: _renderPromoteIcon,
      onClick: item => (editable ? _onClickPromoteButton(item) : undefined),
    },
  ];

  useEffect(() => {
    setTemporaryFieldsLayout(fields);
  }, [isOpen]);

  return (
    <StyledPopover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl?.current?.parentElement}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <FieldsLayout
        onClose={onClose}
        onChange={onChange}
        onSave={handleSave}
        fieldslayout={fieldslayout}
        editable={editable}
        enableRequiredFields
        canFieldBeRequired={fieldId => availableFieldsToBeRequired.includes(fieldId)}
        itemRightActions={itemRightActions}
      />
    </StyledPopover>
  );
};

const StyledPopover = styled(Popover)`
  &&&& {
    div[role='document'] {
      transform: none !important;
    }
  }
`;

const PromoteIconWrapper = styled.div`
  ${({ active }) =>
    active &&
    `
    svg {
      opacity: 1;
    }
  `}

  ${({ active }) =>
    !active &&
    `
    svg {
      opacity: 0.1;
    }
  `}

  ${({ editable }) =>
    editable &&
    `
    &:hover {
      svg {
        opacity: 0.6;
      }
    }
  `}
`;
