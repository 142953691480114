import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import PropTypes from 'prop-types';

// TODO replace AccordionGroupDeprecated for AccordionGroup (new design)
import ReportTable from 'design-system/organisms/ReportTable/index';
import AccordionGroupDeprecated from 'design-system/organisms/AccordionGroupDeprecated/index';

import ProjectInfoCard from 'containers/ProjectInfoCard';
import sortRowOrder from 'utils/sortRowOrder';
import getSystemFieldName from 'utils/getSystemFieldName';
import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete';

import PreferencesDialog from './PreferencesDialog';

const DependenciesTable = ({
  rows,
  groupOptions,
  selectedGroup,
  onChangeSelectedGroup,
  metadata,
  displayFieldsOptions,
  onApplyPreferences,
  preferences,
  customFields,
  handleClickProject,
  systemFields,
  orgIntegrations,
}) => {
  const cols = [
    {
      id: 'idea',
      title: (
        <HeaderTitle>
          <ReportTable.HeaderTitle>{getSystemFieldName('idea', systemFields)}</ReportTable.HeaderTitle>
        </HeaderTitle>
      ),
    },
    {
      id: 'blocking',
      title: (
        <HeaderTitle>
          <ReportTable.HeaderTitle>Blocking</ReportTable.HeaderTitle>
          <TrendingFlatIcon />
        </HeaderTitle>
      ),
    },
    {
      id: 'isBlockedBy',
      title: (
        <HeaderTitle style={{ paddingLeft: 28 }}>
          <ReverseDiv>
            <TrendingFlatIcon />
          </ReverseDiv>
          <ReportTable.HeaderTitle>is blocked by</ReportTable.HeaderTitle>
        </HeaderTitle>
      ),
    },
  ];

  const _renderCellProjects = (row, col, projects) => {
    const _renderProjects = _projects =>
      _projects.map(p => (
        <ProjectDiv>
          <ProjectInfoCard
            project={p}
            disabled
            displayFields={preferences ? preferences.displayFields : undefined}
            fieldsToIgnore={[selectedGroup && selectedGroup.key]}
            customFields={customFields}
            onClickProject={handleClickProject}
            orgIntegrations={orgIntegrations}
          />
        </ProjectDiv>
      ));

    if (!projects) return '';
    if (!selectedGroup) return _renderProjects(projects);

    const notDefined = { title: 'Not Defined', id: null, color: null };
    const groups = [notDefined, ...(metadata[`${selectedGroup.key}s`] || [])];
    const _filterProjectForGroup = (project, group) => {
      const field = selectedGroup.key;

      // for customers and tags
      if (Array.isArray(project[field])) {
        if (!project[field].length && group.id === null) {
          return true;
        }
        return project[field].map(c => c.id).includes(group.id);
      } else if (['initiative', 'bet'].includes(field)) {
        return project.parent_id === group.id;
      } else if (field === 'priority') {
        return project[field] === group.id || (!project[field] && group.id === null);
      } else if (['planningStage', 'status_color'].includes(field)) {
        return project[field] === group.id;
      }

      return project[field] ? project[field].id === group.id : project[`${field}_id`] === group.id;
    };

    return groups.map(group => {
      const groupProjects = projects.filter(p => _filterProjectForGroup(p, group)).sort(sortRowOrder);

      if (!groupProjects.length) return '';

      return (
        <AccordionGroupDeprecated
          id={`${row.id}-${col.id}-${group.id}`}
          color={group.color}
          title={group.title}
          isReadOnly
          showGroupColor
        >
          {_renderProjects(groupProjects)}
        </AccordionGroupDeprecated>
      );
    });
  };
  const _renderIdea = idea => {
    return (
      <ProjectInfoCard
        project={idea}
        disabled
        displayFields={preferences ? preferences.displayFields : undefined}
        fieldsToIgnore={['key']}
        customFields={customFields}
        titleComponent={RowHeaderTitle}
        onClickProject={handleClickProject}
      />
    );
  };

  return (
    <Wrapper>
      <PreferencesBar>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <FormGroup row>
              <GroupByAutocomplete
                label="Group dependencies by"
                name="selectedGroup"
                suggestions={groupOptions}
                value={selectedGroup}
                onChange={onChangeSelectedGroup}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <PreferencesDialog
              onApply={onApplyPreferences}
              preferences={preferences}
              displayFieldsOptions={displayFieldsOptions}
            />
          </Grid>
        </Grid>
      </PreferencesBar>
      <ReportTable>
        <ReportTable.Head>
          <ReportTable.Row>
            {cols.map(col => (
              <ReportTable.Header key={col.id}>
                <ReportTable.HeaderCell resizable={false}>{col.title}</ReportTable.HeaderCell>
              </ReportTable.Header>
            ))}
          </ReportTable.Row>
        </ReportTable.Head>
        <ReportTable.Body>
          {rows.map(row => (
            <ReportTable.Row>
              {cols.map(col => (
                <ReportTable.Cell>
                  <div>
                    {['blocking', 'isBlockedBy'].includes(col.id) ? _renderCellProjects(row, col, row[col.id]) : ''}
                    {['idea'].includes(col.id) ? _renderIdea(row[col.id]) : ''}
                  </div>
                </ReportTable.Cell>
              ))}
            </ReportTable.Row>
          ))}
        </ReportTable.Body>
      </ReportTable>
    </Wrapper>
  );
};

DependenciesTable.propTypes = {
  rows: PropTypes.array,
  groupOptions: PropTypes.array,
  selectedGroup: PropTypes.object,
  onChangeSelectedGroup: PropTypes.func,
  metadata: PropTypes.object,
  displayFieldsOptions: PropTypes.array,
  onApplyPreferences: PropTypes.func,
  preferences: PropTypes.object,
  handleClickProject: PropTypes.func,
};
DependenciesTable.defaultProps = {
  rows: [],
  groupOptions: [],
  metadata: {},
  displayFieldsOptions: [],
  preferences: {},
};

export default DependenciesTable;

const Wrapper = styled.div``;

const PreferencesBar = styled.div`
  margin: 10px 0;
`;

const ReverseDiv = styled.div`
  display: flex;
  position: absolute;
  left: 0;

  svg {
    transform: rotate(180deg);
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0 0;
  min-width: 250px;
  position: relative;
`;

const ProjectDiv = styled.div`
  &&&& {
    height: auto;
  }
`;

const RowHeaderTitle = styled(ReportTable.HeaderTitle)`
  &&&&& {
    white-space: break-spaces;
  }
`;
