import React, { useCallback, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { defaultTo } from 'ramda';
import Grid from '@material-ui/core/Grid';
import get from 'lodash/get';

import DialogActions from 'design-system/molecules/DialogActions/index';
import NewDialog from 'design-system/organisms/NewDialog/index';
import FluidContainer from 'design-system/atoms/FluidContainer/index';
import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';
import FluidHealthField from 'design-system/organisms/FluidHealthField/index';
import FluidTimePeriodField from 'design-system/organisms/FluidTimePeriodField/index';

import FluidMetadataAutocompleteField from 'containers/FluidMetadataAutocompleteField';
import { mapDeliverableStatusToAutocompleteOption } from 'utils/cycleDeliverables/cycleDeliverablesUtils';
import LoadableUsersAutocomplete from 'containers/LoadableUsersAutocomplete';
import formatDate from 'utils/dates/formatDate';
import ProjectCycleDeliverableHistory from 'features/Pdlc/components/ProjectDeliverableHistory/ProjectCycleDeliverableHistory';
import { detailsTab, historyTab } from 'features/Pdlc/constants';
import ProjectDeliverableComments from 'features/Pdlc/components/ProjectDeliverableComments';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import ProjectCycleDeliverableLightboxHeader from './ProjectCycleDeliverableLightboxHeader';
import useProjectDeliverableDetailsForm from './hooks/useProjectDeliverableDetailsForm';
import useProjectDeliverableLightboxUrl from './hooks/useProjectDeliverableLightboxUrl';

const OVERRIDE_CANCEL_LABEL = 'Close';

const cancelButtonProps = {
  color: 'primary',
};

const defaultToEmptyObject = defaultTo({});

const getTargetTimelineValue = formData => {
  const formattedStartDate = formatDate(formData?.startDate) || '';
  const formattedEndDate = formatDate(formData?.endDate) || '';

  return `${formattedStartDate} ${formattedStartDate && '-'} ${formattedEndDate}`;
};

const ProjectCycleDeliverableLightbox = props => {
  const [selectedTab, setSelectedTab] = useState(detailsTab);
  const { isOpen, onClose, onOpen, selectedProjectCycleDeliverable } = props;
  const { canView } = usePermissions();

  const hasPdlcGovernance = canView(PERMISSION_FEATURES.pdlcGovernance);

  const { projectId, cycleDeliverableId } = isOpen ? defaultToEmptyObject(selectedProjectCycleDeliverable) : {};

  const { cleanUrl } = useProjectDeliverableLightboxUrl(isOpen, onOpen, projectId, cycleDeliverableId);

  const {
    formData,
    onSave,
    isLoading,
    cleanForm,
    onDelete,
    allowEdit,
    allowDelete,
    users,
    owner,
    cycleDeliverable,
    project,
    projectDeliverable,
  } = useProjectDeliverableDetailsForm(projectId, cycleDeliverableId);

  const cycleDeliverableStatusOptions = useMemo(() => defaultToEmptyObject(cycleDeliverable?.status), [cycleDeliverable?.status]);
  const statusEntry = useMemo(
    () => get(cycleDeliverableStatusOptions, formData?.status),
    [cycleDeliverableStatusOptions, formData?.status],
  );

  const disableEdit = !allowEdit || isLoading;

  useEffect(() => {
    if (selectedProjectCycleDeliverable && selectedTab !== detailsTab) {
      setSelectedTab(detailsTab);
    }
  }, [selectedProjectCycleDeliverable]);

  const handleOnClose = useCallback(() => {
    onClose();
    cleanForm();
    cleanUrl();
  }, [onClose, cleanForm, cleanUrl]);

  const handleOnSave = useCallback(
    newData => {
      onSave(newData);
    },
    [onSave],
  );

  const handleOnDelete = useCallback(() => {
    onDelete();
    handleOnClose();
  }, [onDelete]);

  const _renderFluidForm = () => (
    <form autoComplete="off">
      <Grid container xs={12} justify="space-around">
        <FluidContainer size={12}>
          <FluidMetadataAutocompleteField
            key="owner"
            value={owner?.name}
            label="Owner"
            onChange={value => handleOnSave({ ownerId: value })}
            disableEdit={disableEdit}
            autocompleteComponent={LoadableUsersAutocomplete}
            autocompleteProps={{
              suggestions: users,
            }}
            format="row"
          />
          <FluidHealthField
            key="health"
            disableEdit={disableEdit}
            value={formData?.health}
            label="Health"
            onChange={v => handleOnSave({ health: v })}
            format="row"
          />
          <FluidMetadataAutocompleteField
            key="status"
            value={statusEntry?.title}
            label="Status"
            onChange={value => handleOnSave({ status: value })}
            disableEdit={disableEdit}
            autocompleteProps={{
              suggestions: Object.entries(cycleDeliverableStatusOptions).map(([id, status]) =>
                mapDeliverableStatusToAutocompleteOption(id, status),
              ),
            }}
            format="row"
          />
          <FluidTimePeriodField
            key="timeline"
            label="Target Timeline"
            format="row"
            value={getTargetTimelineValue(formData)}
            startDate={formData?.startDate}
            endDate={formData?.endDate}
            onDateChange={handleOnSave}
            startDateField="startDate"
            endDateField="endDate"
            disableEdit={disableEdit}
            dateGridItemSize={4}
            alignItems="start"
          />
          <FluidRemirrorWYSIWYGEditor
            key="link"
            value={formData?.link}
            content={formData?.link}
            disableEdit={disableEdit}
            label="Link"
            onChange={value => handleOnSave({ link: value })}
            disableToolbar
            smallInput
            format="row"
            alignItems="start"
          />
          <FluidRemirrorWYSIWYGEditor
            key="summary"
            value={formData?.summary}
            content={formData?.summary}
            placeholder="Write something here..."
            label="Status Summary"
            disableEdit={disableEdit}
            onChange={value => handleOnSave({ summary: value })}
            format="row"
            alignItems="start"
          />
          {hasPdlcGovernance && projectDeliverable?.id && (
            <ProjectDeliverableComments projectDeliverableId={projectDeliverable.id} />
          )}
        </FluidContainer>
      </Grid>
    </form>
  );

  const deleteButtonProps = {
    disabled: !allowDelete,
  };

  return (
    <NewDialog
      type="DRAWER"
      scroll="paper"
      fullHeight
      header={
        <ProjectCycleDeliverableLightboxHeader
          cycleDeliverable={cycleDeliverable}
          project={project}
          onClose={handleOnClose}
          selectedTab={selectedTab}
          onClickTabButton={newTab => setSelectedTab(newTab)}
          hasPdlcGovernance={hasPdlcGovernance}
        />
      }
      actions={
        <DialogActions
          onCancel={handleOnClose}
          onDelete={handleOnDelete}
          cancelLabel={OVERRIDE_CANCEL_LABEL}
          cancelProps={cancelButtonProps}
          deleteProps={deleteButtonProps}
        />
      }
      onClose={handleOnClose}
      open={isOpen}
      width="750px"
      withActionsShadow
    >
      <Container>
        {selectedTab === detailsTab || !hasPdlcGovernance ? _renderFluidForm() : null}
        {selectedTab === historyTab && hasPdlcGovernance ? (
          <ProjectCycleDeliverableHistory projectDeliverableId={projectDeliverable?.id} cycleDeliverable={cycleDeliverable} />
        ) : null}
      </Container>
    </NewDialog>
  );
};

const Container = styled.div`
  width: 100%;
`;

export default ProjectCycleDeliverableLightbox;
