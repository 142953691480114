import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

import cellEditorHoc from '../helpers/cellEditorHoc';
import { focusAndSelectText } from 'containers/Grids/utils/handleFieldFocus';

const ProgressCellEditor = cellEditorHoc(
  ({ column, value: originalValue = '', forwardedRef, handleInputFocus = focusAndSelectText }) => {
    const inputRef = useRef();

    const [internalValue, setInternalValue] = useState(originalValue ? originalValue * 100 : '');

    useImperativeHandle(forwardedRef, () => ({
      getValue() {
        return +internalValue / 100;
      },
    }));

    const handleChange = event => {
      setInternalValue(event.value ? event.value : null);
    };

    useEffect(() => {
      handleInputFocus(inputRef.current);
    }, []);

    return (
      <NumberFormat
        type="text"
        className="ag-cell-edit-input"
        thousandSeparator
        decimalScale={column.colDef.decimalScale || 2}
        customInput={TextField}
        inputRef={el => {
          inputRef.current = el;
        }}
        onValueChange={handleChange}
        value={internalValue}
      />
    );
  },
);

export default ProgressCellEditor;
