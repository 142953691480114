// External dependencies
import React, { memo, useCallback, useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import cloneDeep from 'lodash/cloneDeep';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

// dragonboat dependencies
import { READ_ONLY_USER } from '@dragonboat/permissions';
import CopyPasteLightbox from 'components/CopyPasteLightbox/new/CopyPasteLightbox';
import ImportWidget from 'components/ImportWidget';
import ConfirmDialog from 'components/ConfirmDialog';
import Link from 'components/Link';
import isEmail from 'utils/isEmail';
import UserTypeMapper from 'utils/UserTypeMapper';

import ImportUsersWizardLightbox from './ImportUsersWizardLightbox';
import useGridColumns from './hooks/useGridColumns';
import { DATA_MAPPING, correctUser, noop } from './helpers';
import useUsersImport from './hooks/useUsersImport';

const BoldSpan = styled.span`
  font-weight: 700;
`;

const InfoContainer = styled.div`
  &&&& {
    margin-bottom: 20px;
  }
`;

const defaultToReadOnly = user => (isEmail(user.email) && !user.role_id ? READ_ONLY_USER : user.role_id);

const findRoleByLabel = label => UserTypeMapper.find(type => type.label.toLowerCase() === label.toLowerCase());

const computeRoleId = user => {
  const roleId = defaultToReadOnly(user);

  // if roleId is a string, map to int value
  const roleIdIsALabel = typeof roleId === 'string';

  return roleIdIsALabel ? findRoleByLabel(roleId)?.value : roleId;
};

const ImportUsersDialog = memo(
  ({ isPortalSettingsEnabled, teams, skills, createUsers, updateUsers, opened, close: onClose, afterImport, importUsers }) => {
    const importWidgetRef = useRef(null);

    const [stateCopyPasteData, setStateCopyPasteData] = useState([]);

    const [stateContentPasted, setStateContentPasted] = useState(false);

    const removeRow = useCallback(
      rowIndex => {
        const data = cloneDeep(stateCopyPasteData);

        data.splice(rowIndex, 1);

        setStateCopyPasteData(data);
      },
      [stateCopyPasteData],
    );

    const columnDefs = useGridColumns({
      isPortalSettingsEnabled,
      teams,
      skills,
      removeRow,
    });

    const {
      stateShowImportWarningDialog,
      stateCopyPasteLightboxOpened,
      stateDisableImport,
      stateImportConfirm,
      stateWarningDialogText,

      saveCopyPasteData,
      clearCopyPasteData,
      selectCopyPaste,
      cancelCopyPaste,
      importAndSendEmailInvitation,
      onUsersReadyToImportClick,
      onCancelImport,
      importClickHandler,
      onImportCsvData,
    } = useUsersImport({
      createUsers,
      updateUsers,
      importUsers,
      afterImport,
      stateCopyPasteData,
      setStateCopyPasteData,
      setStateContentPasted,
      onClose,
      importWidgetRef,
    });

    const updateRowData = useCallback(copyPasteData => {
      const newData = copyPasteData.map((row, index) => {
        const user = correctUser(row);

        return {
          ...user,
          id: index,
          role_id: computeRoleId(user),
        };
      });

      setStateCopyPasteData(newData);

      setStateContentPasted(true);
    }, []);

    const generateIntroText = useCallback(
      () => (
        <>
          <InfoContainer>
            <BoldSpan>Import Users</BoldSpan> -&nbsp; copy your spreadsheet data and paste into relevant columns
          </InfoContainer>
          <InfoContainer>
            You may paste up to 100 users at a time.
            {stateContentPasted && (
              <Grid container>
                <Grid item xs={6}>
                  <Typography onClick={onUsersReadyToImportClick}>
                    <Link>
                      {stateCopyPasteData.filter(row => row.first_name && row.first_name.trim().length).length} users are ready
                      for import.
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography onClick={clearCopyPasteData} align="right">
                    <Link>Clear data</Link>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </InfoContainer>
        </>
      ),
      [stateContentPasted, onUsersReadyToImportClick, clearCopyPasteData],
    );

    return (
      <>
        <ImportWidget
          hideButton
          ref={importWidgetRef}
          import={onImportCsvData}
          dataMapping={DATA_MAPPING}
          title="Map user data"
          titleUrl="https://dragonboat.zendesk.com/hc/en-us/articles/360013274554"
          titleUrlMessage="(help here)"
        />
        {opened && (
          <ImportUsersWizardLightbox
            isOpen
            onClose={onClose}
            onSelectCopyPaste={selectCopyPaste}
            importClick={importClickHandler}
            setCopyPasteData={noop}
            showCopyPasteLightbox={noop}
          />
        )}
        {stateCopyPasteLightboxOpened && (
          <CopyPasteLightbox
            isOpen
            rowData={stateCopyPasteData}
            cancel={cancelCopyPaste}
            save={saveCopyPasteData}
            updateRowData={updateRowData}
            otherActions={
              <>
                <Button onClick={importAndSendEmailInvitation} aria-label="save" color="primary">
                  import and email invitation
                </Button>
              </>
            }
            columnDefs={columnDefs}
            mandatoryField="first_name"
            introText={generateIntroText}
          />
        )}
        <ConfirmDialog
          title=" "
          text={stateWarningDialogText}
          confirmText="Import"
          isOpen={stateShowImportWarningDialog}
          onConfirm={stateImportConfirm}
          onCancel={onCancelImport}
          disableConfirm={stateDisableImport}
          maxWidth="lg"
          width="lg"
        />
      </>
    );
  },
);

ImportUsersDialog.displayName = 'ImportUsersDialog';

export default ImportUsersDialog;
