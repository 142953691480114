import React from 'react';
import DragonTableCell from '../DragonTableCell';
import DragonTableCellChip from '../DragonTableCellChip';
import invertedTextColor from 'design-system/utils/invertedTextColor';

export default ({ map, row, ...props }) => {
  const result = row && (map(row) || []);

  return (
    <DragonTableCell {...props} style={{ height: props.cellProps.rowHeight, width: props.width }}>
      {result &&
        result.slice(0, props.maxChips || 1000).map(value => {
          return <DragonTableCellChip label={value.label} color={value.color} textColor={invertedTextColor(null, true)} />;
        })}
      {result && result.length > props.maxChips && '...'}
    </DragonTableCell>
  );
};
