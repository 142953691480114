import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

export default class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.setSearchString = debounce(this.setSearchString.bind(this), 100);
    this.onChange = this.onChange.bind(this);
    this.onClick = props.onClick;
    this.state.searchText = props.searchString;
  }

  state = {
    searchText: '',
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.searchString !== this.props.searchString) {
      this.setState({
        searchText: nextProps.searchString,
      });
    }
  }

  setSearchString(searchString) {
    this.props.updateSearchString(searchString);
  }

  onChange(e) {
    const searchText = e.target.value;

    this.setSearchString(searchText);
    this.setState({
      searchText,
    });
  }

  render() {
    return (
      <div style={Object.assign({ display: 'inline-block' }, this.props.style || {})}>
        <Input
          value={this.state.searchText}
          placeholder={this.props.placeholder || ''}
          onClick={this.onClick}
          onChange={this.onChange}
          disabled={this.props.disabled}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          inputProps={this.props.inputProps}
        />
      </div>
    );
  }
}
