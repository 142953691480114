import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { spacing } from 'design-system/theme';

import useGridTableApi from 'routes/Settings/hooks/useGridTableApi';
import { useCommonGridEventHandlers } from 'design-system/molecules/AgGridReact-New/hooks';
import useCommonGridProps from 'design-system/molecules/AgGridReact-New/hooks/useCommonGridProps';
import useMultiChildDragonsListGridColumns from './hooks/useMultiChildDragonsListGridColumns';
import useMultiChildDragonsList from './hooks/useMultiChildDragonsList';

import NewIntegrationButton from 'routes/Settings/Integration/components/NewIntegrationButton/NewIntegrationButton';
import { BaseGrid } from 'containers/Grids';
import { BASE_ROW_HEIGHT } from 'constants/grid';
import { TITLE_HEIGHT } from './helpers/constants';

const getRowId = ({ data }) => data?.id || null;

const MultiChildDragonsList = props => {
  const { childrenDragons, readOnly, onCreateChildAccess, onSelectChildDragon, shouldRenderNewButton = true } = props;

  const { rows, openRowBaseUrlById, updateOrganizationAccessById, integrationType } = useMultiChildDragonsList(childrenDragons);
  const { tableHeightInPx } = useGridTableApi(rows);

  const commonGridProps = useCommonGridProps({});
  const commonGridEventHandlers = useCommonGridEventHandlers({
    items: rows,
    idKey: 'id',
    actions: {
      update: updateOrganizationAccessById,
    },
  });

  const { columnDefs, defaultColDef } = useMultiChildDragonsListGridColumns(openRowBaseUrlById, onSelectChildDragon, readOnly);

  return (
    <Wrapper>
      {rows.length > 0 && (
        <BaseGrid
          {...commonGridProps}
          {...commonGridEventHandlers}
          rowData={rows}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          height={tableHeightInPx}
          headerHeight={BASE_ROW_HEIGHT}
          getRowId={getRowId}
          enableSorting
        />
      )}
      {shouldRenderNewButton ? <NewIntegrationButton integrationType={integrationType} onClick={onCreateChildAccess} /> : null}
    </Wrapper>
  );
};

MultiChildDragonsList.propTypes = {
  readOnly: PropTypes.bool,
  onCreateChildAccess: PropTypes.func,
  onSelectChildDragon: PropTypes.func,
};

const Wrapper = styled.div`
  margin-top: ${spacing(2)}px;
  height: calc(100% - ${TITLE_HEIGHT}px);

  .ag-layout-normal.ag-root {
    margin: 0;
  }
`;

export default MultiChildDragonsList;
