export const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION';
export const FETCH_ORGANIZATION_FULFILLED = 'FETCH_ORGANIZATION_FULFILLED';
export const FETCH_ORGANIZATION_PENDING = 'FETCH_ORGANIZATION_PENDING';

export const MAKE_PAYMENT = 'MAKE_PAYMENT';
export const MAKE_PAYMENT_FULFILLED = 'MAKE_PAYMENT_FULFILLED';

export const DELETE_ALL_DEMO_DATA = 'DELETE_ALL_DEMO_DATA';
export const DELETE_ALL_DEMO_DATA_FULFILLED = 'DELETE_ALL_DEMO_DATA_FULFILLED';

export const FETCH_ORG_INTEGRATIONS = 'FETCH_ORG_INTEGRATIONS';
export const FETCH_ORG_INTEGRATIONS_FULFILLED = 'FETCH_ORG_INTEGRATIONS_FULFILLED';

export const ADD_ORG_INTEGRATION = 'ADD_ORG_INTEGRATION';
export const ADD_ORG_INTEGRATION_FULFILLED = 'ADD_ORG_INTEGRATION_FULFILLED';

export const UPDATE_ORG_INTEGRATION_FULFILLED = 'UPDATE_ORG_INTEGRATION_FULFILLED';
export const UPDATE_ORG_INTEGRATION_BY_REALTIME_FULFILLED = 'UPDATE_ORG_INTEGRATION_BY_REALTIME_FULFILLED';

export const RESTORE_ORG_INTEGRATION_DATA = 'RESTORE_ORG_INTEGRATION_DATA';

export const REMOVE_ORG_INTEGRATION = 'REMOVE_ORG_INTEGRATION';
export const REMOVE_ORG_INTEGRATION_FULFILLED = 'REMOVE_ORG_INTEGRATION_FULFILLED';

export const UPDATE_ORGANIZATION_DATA = 'UPDATE_ORGANIZATION_DATA';
export const UPDATE_ORGANIZATION_DATA_FULFILLED = 'UPDATE_ORGANIZATION_DATA_FULFILLED';

export const DELETE_ORGANIZATION_DATA = 'DELETE_ORGANIZATION_DATA';
export const DELETE_ORGANIZATION_DATA_FULFILLED = 'DELETE_ORGANIZATION_DATA_FULFILLED';
export const DELETE_ORGANIZATION_DATA_PENDING = 'DELETE_ORGANIZATION_DATA_PENDING';
export const DELETE_ORGANIZATION_DATA_REJECTED = 'DELETE_ORGANIZATION_DATA_REJECTED';

export const UPDATE_JIRA_INTEGRATION = 'UPDATE_JIRA_INTEGRATION';
export const UPDATE_JIRA_INTEGRATION_FULFILLED = 'UPDATE_JIRA_INTEGRATION_FULFILLED';

export const UPDATE_HAS_SURNAME_FIRST_ON_JIRA = 'UPDATE_HAS_SURNAME_FIRST_ON_JIRA';
export const UPDATE_HAS_SURNAME_FIRST_ON_JIRA_PENDING = 'UPDATE_HAS_SURNAME_FIRST_ON_JIRA_PENDING';
export const UPDATE_HAS_SURNAME_FIRST_ON_JIRA_FULFILLED = 'UPDATE_HAS_SURNAME_FIRST_ON_JIRA_FULFILLED';
export const UPDATE_HAS_SURNAME_FIRST_ON_JIRA_REJECTED = 'UPDATE_HAS_SURNAME_FIRST_ON_JIRA_REJECTED';

export const ENABLE_INTEGRATION_WEBHOOKS = 'ENABLE_INTEGRATION_WEBHOOKS';
export const ENABLE_INTEGRATION_WEBHOOKS_FULFILLED = 'ENABLE_INTEGRATION_WEBHOOKS_FULFILLED';

export const DISABLE_INTEGRATION_WEBHOOKS = 'DISABLE_INTEGRATION_WEBHOOKS';
export const DISABLE_INTEGRATION_WEBHOOKS_FULFILLED = 'DISABLE_INTEGRATION_WEBHOOKS_FULFILLED';

export const UPDATE_PORTAL_SETTINGS = 'UPDATE_PORTAL_SETTINGS';
export const UPDATE_PORTAL_SETTINGS_FULFILLED = 'UPDATE_PORTAL_SETTINGS_FULFILLED';

export const IMPORT_USING_RECURRING_JQL_QUERY = 'IMPORT_USING_RECURRING_JQL_QUERY';
export const IMPORT_USING_RECURRING_JQL_QUERY_FULFILLED = 'IMPORT_USING_RECURRING_JQL_QUERY_FULFILLED';

export const ADD_AZURE_INTEGRATION = 'ADD_AZURE_INTEGRATION';
export const ADD_AZURE_INTEGRATION_FULFILLED = 'ADD_AZURE_INTEGRATION_FULFILLED';

export const UPDATE_SLACK_INTEGRATION = 'UPDATE_SLACK_INTEGRATION';
export const UPDATE_SLACK_INTEGRATION_FULFILLED = 'UPDATE_SLACK_INTEGRATION_FULFILLED';

export const TEST_SLACK_CONNECTION = 'TEST_SLACK_CONNECTION';
export const TEST_SLACK_CONNECTION_FULFILLED = 'TEST_SLACK_CONNECTION_FULFILLED';
export const REMOVE_TEST_SLACK_CONNECTION = 'REMOVE_TEST_SLACK_CONNECTION';

export const GET_SLACK_INTEGRATION_CHANNELS = 'GET_SLACK_INTEGRATION_CHANNELS';
export const GET_SLACK_INTEGRATION_CHANNELS_FULFILLED = 'GET_SLACK_INTEGRATION_CHANNELS_FULFILLED';

export const UPDATE_FORWARD_EMAILS_INTEGRATION = 'UPDATE_FORWARD_EMAILS_INTEGRATION';
export const UPDATE_FORWARD_EMAILS_INTEGRATION_PENDING = 'UPDATE_FORWARD_EMAILS_INTEGRATION_PENDING';
export const UPDATE_FORWARD_EMAILS_INTEGRATION_REJECTED = 'UPDATE_FORWARD_EMAILS_INTEGRATION_REJECTED';

export const FETCH_INTEGRATION_PROJECTS = 'FETCH_INTEGRATION_PROJECTS';
export const FETCH_INTEGRATION_PROJECTS_FULFILLED = 'FETCH_INTEGRATION_PROJECTS_FULFILLED';
export const FETCH_INTEGRATION_PROJECTS_PENDING = 'FETCH_INTEGRATION_PROJECTS_PENDING';
export const FETCH_INTEGRATION_PROJECTS_REJECTED = 'FETCH_INTEGRATION_PROJECTS_REJECTED';

export const FETCH_INTEGRATION_DRAGONBOAT_FIELDS = 'FETCH_INTEGRATION_DRAGONBOAT_FIELDS';
export const FETCH_INTEGRATION_DRAGONBOAT_FIELDS_FULFILLED = 'FETCH_INTEGRATION_DRAGONBOAT_FIELDS_FULFILLED';
export const FETCH_INTEGRATION_DRAGONBOAT_FIELDS_PENDING = 'FETCH_INTEGRATION_DRAGONBOAT_FIELDS_PENDING';
export const FETCH_INTEGRATION_DRAGONBOAT_FIELDS_REJECTED = 'FETCH_INTEGRATION_DRAGONBOAT_FIELDS_REJECTED';

export const IMPORT_COUNT_INTEGRATION_ITEMS = 'IMPORT_COUNT_INTEGRATION_ITEMS';
export const IMPORT_COUNT_INTEGRATION_ITEMS_FULFILLED = 'IMPORT_COUNT_INTEGRATION_ITEMS_FULFILLED';
export const IMPORT_COUNT_INTEGRATION_ITEMS_PENDING = 'IMPORT_COUNT_INTEGRATION_ITEMS_PENDING';
export const IMPORT_COUNT_INTEGRATION_ITEMS_REJECTED = 'IMPORT_COUNT_INTEGRATION_ITEMS_REJECTED';

export const IMPORT_INSERT_INTEGRATION_ITEMS = 'IMPORT_INSERT_INTEGRATION_ITEMS';
export const IMPORT_INSERT_INTEGRATION_ITEMS_FULFILLED = 'IMPORT_INSERT_INTEGRATION_ITEMS_FULFILLED';
export const IMPORT_INSERT_INTEGRATION_ITEMS_PENDING = 'IMPORT_INSERT_INTEGRATION_ITEMS_PENDING';
export const IMPORT_INSERT_INTEGRATION_ITEMS_REJECTED = 'IMPORT_INSERT_INTEGRATION_ITEMS_REJECTED';

export const IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC = 'IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC';
export const IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC_FULFILLED = 'IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC_FULFILLED';
export const IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC_PENDING = 'IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC_PENDING';
export const IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC_REJECTED = 'IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC_REJECTED';
export const IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC_RESULT = 'IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC_RESULT';

export const FETCH_INTEGRATION_FIELDS = 'FETCH_INTEGRATION_FIELDS';
export const FETCH_INTEGRATION_FIELDS_FULFILLED = 'FETCH_INTEGRATION_FIELDS_FULFILLED';
export const FETCH_INTEGRATION_FIELDS_PENDING = 'FETCH_INTEGRATION_FIELDS_PENDING';
export const FETCH_INTEGRATION_FIELDS_REJECTED = 'FETCH_INTEGRATION_FIELDS_REJECTED';

export const UPDATE_INTEGRATION_FIELD_MAPPING = 'UPDATE_INTEGRATION_FIELD_MAPPING';
export const UPDATE_INTEGRATION_FIELD_MAPPING_FULFILLED = 'UPDATE_INTEGRATION_FIELD_MAPPING_FULFILLED';
export const UPDATE_INTEGRATION_FIELD_MAPPING_PENDING = 'UPDATE_INTEGRATION_FIELD_MAPPING_PENDING';
export const UPDATE_INTEGRATION_FIELD_MAPPING_REJECTED = 'UPDATE_INTEGRATION_FIELD_MAPPING_REJECTED';

export const UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS = 'UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS';
export const UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS_FULFILLED =
  'UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS_FULFILLED';
export const UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS_PENDING =
  'UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS_PENDING';
export const UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS_REJECTED =
  'UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS_REJECTED';

export const UPDATE_INTEGRATION_IMPORT_CONFIGURATION = 'UPDATE_INTEGRATION_IMPORT_CONFIGURATION';
export const UPDATE_INTEGRATION_IMPORT_CONFIGURATION_FULFILLED = 'UPDATE_INTEGRATION_IMPORT_CONFIGURATION_FULFILLED';
export const UPDATE_INTEGRATION_IMPORT_CONFIGURATION_PENDING = 'UPDATE_INTEGRATION_IMPORT_CONFIGURATION_PENDING';
export const UPDATE_INTEGRATION_IMPORT_CONFIGURATION_REJECTED = 'UPDATE_INTEGRATION_IMPORT_CONFIGURATION_REJECTED';

export const UPDATE_INTEGRATION_PROGRESS_TRACKING = 'UPDATE_INTEGRATION_PROGRESS_TRACKING';
export const UPDATE_INTEGRATION_PROGRESS_TRACKING_FULFILLED = 'UPDATE_INTEGRATION_PROGRESS_TRACKING_FULFILLED';
export const UPDATE_INTEGRATION_PROGRESS_TRACKING_PENDING = 'UPDATE_INTEGRATION_PROGRESS_TRACKING_PENDING';
export const UPDATE_INTEGRATION_PROGRESS_TRACKING_REJECTED = 'UPDATE_INTEGRATION_PROGRESS_TRACKING_REJECTED';

export const STORE_INTEGRATION_OAUTH_CLIENT_CREDENTIALS = 'STORE_INTEGRATION_OAUTH_CLIENT_CREDENTIALS';
export const STORE_INTEGRATION_OAUTH_CLIENT_CREDENTIALS_FULFILLED = 'STORE_INTEGRATION_OAUTH_CLIENT_CREDENTIALS_FULFILLED';
export const STORE_INTEGRATION_OAUTH_CLIENT_CREDENTIALS_PENDING = 'STORE_INTEGRATION_OAUTH_CLIENT_CREDENTIALS_PENDING';
export const STORE_INTEGRATION_OAUTH_CLIENT_CREDENTIALS_REJECTED = 'STORE_INTEGRATION_OAUTH_CLIENT_CREDENTIALS_REJECTED';

export const SET_SELECTED_ORG_INTEGRATION_ID = 'SET_SELECTED_ORG_INTEGRATION_ID';
