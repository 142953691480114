import { useCallback, useMemo, useRef, useEffect } from 'react';

/**
 * @function usePageViewOnGrid
 *
 * Hook that manages the apply page view on grid
 *
 * @param  {Object} activeView
 * @param  {Boolean} isLoading
 * @return {Object}
 */
const usePageViewOnGrid = ({ activeView, isLoading }) => {
  /*
   * Since we cannot know if the grid is triggering the saveColumnsState
   * by user or automatic event we need to control manually if it's being trigger
   * by a change of columns originated by a change in view.
   * With this mechanism we can avoid making dirty the active view on the first load/apply view changes
   */
  const shouldConsiderViewDirty = useRef(false);

  useEffect(() => {
    shouldConsiderViewDirty.current = false;
  }, [activeView?.id]);

  // When first data arrives we must start considering the view dirty on user changes
  const setDirtyViewMutex = useCallback(() => {
    // setTimeout is because both this and the saveColumnState are triggered concurrently and this one must be executed after
    setTimeout(() => {
      shouldConsiderViewDirty.current = true;
    });
  }, []);

  const shouldGridBeUnmounted = useMemo(() => {
    const didViewChange = !shouldConsiderViewDirty.current;

    // If view changed (and probably the grid config changed)
    // and we are loading projects we must unmount the idea component and remount when loaded
    if (isLoading && didViewChange) {
      return true;
    }

    return false;
  }, [isLoading, shouldConsiderViewDirty.current]);

  return {
    shouldConsiderViewDirtyRef: shouldConsiderViewDirty,
    shouldConsiderViewDirty: shouldConsiderViewDirty.current,

    setDirtyViewMutex,
    shouldGridBeUnmounted,
  };
};

export default usePageViewOnGrid;
