import formatDateForGantt from 'utils/dates/formatDateForGantt';

/**
 * @function _isProjectUnscheduled
 *
 * function that checks if the project is unscheduled based on:
 *  - estimates
 *  - tasks
 *  - start_date and end_date
 *
 * @param {object} p
 * @return {boolean}}
 */
const _isProjectUnscheduled = p => {
  const { estimates, showEstimates, tasks, showTasks } = p;

  if (estimates && estimates.length > 0 && showEstimates) return false;
  if (tasks && tasks.length > 0 && showTasks) return false;

  return !p.ganttStartDate && !p.ganttEndDate;
};

/**
 * @function calcProjectTimeline
 *
 * Function that calculates the project timeline
 *
 * @param {object} project
 * @returns {object} object with start_date and end_date
 */
const calcProjectTimeline = project => {
  const result = {
    start_date: project.ganttStartDate && formatDateForGantt(project.ganttStartDate),
    end_date: project.ganttEndDate && formatDateForGantt(project.ganttEndDate, true),
    unscheduled: _isProjectUnscheduled(project),
  };

  return result;
};

export default calcProjectTimeline;
