import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { reject, isNil, pipe, not, isEmpty } from 'ramda';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import { enableADOWebhooks, disableADOWebhooks, hasEnterpriseEnabled as hasEnterpriseEnabledSelector } from 'store/organization';

import useAppNotifications from 'hooks/useAppNotifications';

import CollapsePanel from 'components/CollapsePanel';

import WebhookSwitcher from 'routes/Settings/Integration/components/WebhookSwitcher';
import WebhooksProjectRestrictions from 'routes/Settings/Integration/components/WebhooksProjectRestrictions';

const isNotEmpty = pipe(reject(isNil), isEmpty, not);

const AzureWebhook = props => {
  const dispatch = useDispatch();
  const { orgIntegration, currentUser, userCanUpdateOrgIntegration } = props;
  const organizationId = orgIntegration?.organization_id;
  const userId = currentUser.id;
  const orgIntegrationId = orgIntegration?.id;
  const integrationData = orgIntegration?.data || {};

  // If integrationData.webhooks property does not exist, they may have webhooks using the previous webhook creation flow.
  // If so, the integrationData.webhooks array would not be created.
  // If the integrationData.webhooks property does not exist, we set webhooks to an array with an empty object
  // so the toggle shows webhooks enabled by default
  const hasWebhooksProperty = Object.hasOwn(integrationData, 'webhooks');
  const webhooks = hasWebhooksProperty ? integrationData.webhooks : [{}];

  const { addNotification } = useAppNotifications();

  const hasEnterpriseEnabled = useSelector(hasEnterpriseEnabledSelector);

  const createDefaultAdoWebhooks = () => {
    return dispatch(enableADOWebhooks(organizationId, userId, orgIntegrationId)).then(() => {
      addNotification({ id: 'ado-webhooks-enabled-successfully', message: 'Webhooks enabled successfully' });
    });
  };
  const deleteAllAdoWebhooks = () => dispatch(disableADOWebhooks(organizationId, userId, orgIntegrationId));

  return (
    <Wrapper>
      {/* {this.props.organization.has_webhooks && ( */}
      <CollapsePanel title={<TextDeprecated size="medium">Configure Webhook</TextDeprecated>}>
        <PanelContent>
          <Grid container spacing={16}>
            <Grid item xs={hasEnterpriseEnabled ? 6 : 12}>
              <FormControlLabelContainer
                control={
                  <WebhookSwitcher
                    register={createDefaultAdoWebhooks}
                    unregister={deleteAllAdoWebhooks}
                    webhooks={webhooks}
                    orgIntegrationId={orgIntegrationId}
                  />
                }
                disabled={!userCanUpdateOrgIntegration}
                label={webhooks?.length ? 'Disable Webhook' : 'Enable Webhook'}
              />
              <WarningText breakwords>Azure Devops system admin permission is required to turn on / off webhook</WarningText>
            </Grid>
            {hasEnterpriseEnabled && (
              <Grid item xs={6}>
                <WebhooksProjectRestrictions
                  integrationType={orgIntegration?.integrationType}
                  orgIntegrationId={orgIntegrationId}
                  webhooksEnabled={isNotEmpty(webhooks)}
                  userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
                />
              </Grid>
            )}
          </Grid>
        </PanelContent>
      </CollapsePanel>
      {/* )} */}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const FormControlLabelContainer = styled(FormControlLabel)`
  margin-top: -10px;
  padding-left: 5px;
`;

const PanelContent = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.text.lightGrey};
  padding-bottom: 16px;
`;

const WarningText = styled(TextDeprecated)`
  &&&& {
    margin-top: 3px;
    font-size: ${props => props.theme.typography.fontSizeSmall}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    ${props => `color: ${props.theme.palette.text.error}`};
  }
`;

export default AzureWebhook;
