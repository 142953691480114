import { useCallback, useState } from 'react';

const useMetricValueActions = ({ deleteById }) => {
  const [itemToDelete, setItemToDelete] = useState();

  const deleteItem = useCallback(() => {
    if (!itemToDelete) {
      return;
    }

    deleteById(itemToDelete?.metric_id, itemToDelete?.id);

    setItemToDelete(null);
  }, [itemToDelete, deleteById]);

  return {
    itemToDelete,
    setItemToDelete,
    deleteItem,
  };
};

export default useMetricValueActions;
