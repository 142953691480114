import {
  REMOVE_ALL_SELECTED_SNAP_TO_COMPARE,
  CLOSE_VIEW_HISTORY_SNAPS_DIALOG,
  OPEN_VIEW_HISTORY_SNAPS_DIALOG,
  UPDATE_ROADMAP_HISTORY_SNAPSHOT_REALTIME,
} from 'features/RoadmapHistory/store/types';
import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';

const removeAllSelectedSnapToCompare = () => ({
  type: REMOVE_ALL_SELECTED_SNAP_TO_COMPARE,
});

const openViewHistorySnapsDialog = selectedUserViewId => ({
  type: OPEN_VIEW_HISTORY_SNAPS_DIALOG,
  payload: {
    selectedUserViewId,
  },
});

const closeViewHistorySnapsDialog = () => ({
  type: CLOSE_VIEW_HISTORY_SNAPS_DIALOG,
});

const handleRoadmapHistoryRealtimeUpdate = generateRealtimeUpdateAction(null, null, UPDATE_ROADMAP_HISTORY_SNAPSHOT_REALTIME);

export {
  removeAllSelectedSnapToCompare,
  openViewHistorySnapsDialog,
  closeViewHistorySnapsDialog,
  handleRoadmapHistoryRealtimeUpdate,
};
