import styled from 'styled-components';

import Text from 'design-system/atoms/Text';
import { spacing } from 'design-system/theme';

const ScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .fade-enter {
    opacity: 0;
    transform: scale(0.85);
  }

  .fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 0.5s ease;
  }

  .fade-exit {
    opacity: 1;
    transform: scale(1);
    position: absolute;
  }

  .fade-exit-active {
    opacity: 0;
    transform: scale(0.85);
    transition: all 0.5s ease;
  }
`;

const IllustrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${spacing(6)}px;
`;

const StyledText = styled(Text)`
  &&&& {
    max-width: ${({ maxWidth }) => maxWidth || '95%'};
    font-size: ${({ theme }) => theme.typography.fontSizeH4}px;
    color: ${({ theme }) => theme.palette.text.black};
    line-height: normal;
    text-align: center;
    margin: ${({ withMarginTop }) => (withMarginTop ? `${spacing(2)}px auto 0 auto` : '0 auto')};

    b {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    }
  }
`;

export { ScreenContainer, IllustrationWrapper, StyledText };
