import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { materialColors } from 'design-system/themes/default';

const FluidContainer = styled(props => (
  <Grid
    container
    xs={props.size}
    direction="column"
    className={props.className}
  >
    {props.children}
  </Grid>)
)`
  position: relative;
  
  ${props => props.leftBorder && `    
    :after {
      position: absolute;
      content: '';
      border-left: 2px solid ${materialColors.lightGray};
      height: 100%;
      transform: translateX(-20px);
      top: 0;
    }
  `}
`;

FluidContainer.propTypes = {
  ...Grid.propTypes,
};

export default FluidContainer;
