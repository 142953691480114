import React, { useCallback, useState } from 'react';

import converTimeToString from 'utils/converTimeToString';
import converStringTimeToSeconds from 'utils/converStringTimeToSeconds';

import { InlineSetting, InlineTextField, PreferenceGroup } from '../../ProgressTrackingConfig.styles';

const TimeEstimatesDetails = props => {
  const { value, onChange, disabled } = props;

  const [valueInString, setValueInString] = useState(converTimeToString(value) || '1d');

  const onChangeHandler = useCallback(
    ({ target }) => {
      setValueInString(target.value);

      onChange(converStringTimeToSeconds(target.value));
    },
    [onChange],
  );

  return (
    <PreferenceGroup>
      <InlineSetting>
        <span>Default Time Estimates</span>
        &nbsp;
        <InlineTextField
          width="100px"
          placeholder="1w 1d 1h 1m"
          value={valueInString}
          onChange={onChangeHandler}
          disabled={disabled}
        />
      </InlineSetting>
    </PreferenceGroup>
  );
};

export default TimeEstimatesDetails;
