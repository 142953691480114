import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { updateRoadmapById, updateProductById } from 'store/roadmaps';
import { updateKeyResultById, updateObjectiveById } from 'store/objectives';
import { updateTimeframeById } from 'store/timeframes';
import { updateCustomerById } from 'store/customers';
import { updateTagById } from 'store/tags';
import { updateThemeById } from 'store/themes';
import { updateCategoryById } from 'store/categories';
import { updatePhaseById } from 'store/phases';
import { updatePriorityById } from 'store/priorities';

const systemFieldsToActionsMap = {
  roadmap: updateRoadmapById,
  product1: updateProductById,
  timeframe: updateTimeframeById,
  objective: updateObjectiveById,
  keyResult1: updateKeyResultById,
  customers: updateCustomerById,
  tags: updateTagById,
  theme: updateThemeById,
  category: updateCategoryById,
  phase: updatePhaseById,
  priority: updatePriorityById,
};

const useUpdateSystemFields = () => {
  const dispatch = useDispatch();

  const supportedFields = useMemo(
    () => Object.keys(systemFieldsToActionsMap),
    [],
  );

  const makeUpdater = useCallback(
    (dataKey) => {
      if (!(dataKey in systemFieldsToActionsMap)) {
        throw new Error(`Invalid data key passed to update handler: ${dataKey}`);
      }

      const updateAction = systemFieldsToActionsMap[dataKey];

      return (dataId, dataFields = {}) => dispatch(updateAction(dataId, dataFields));
    },
    [dispatch],
  );

  return {
    supportedFields,
    makeUpdater,
  };
};

export default useUpdateSystemFields;
