import { UPDATE_ALLOCATION_REPORT_STATE } from './types';

export const updateState = (state, makesActiveViewDirty = true) => {
  return {
    payload: state,
    type: UPDATE_ALLOCATION_REPORT_STATE,
    meta: { makesActiveViewDirty },
  };
};

export const allocationReportActions = {
  updateState,
};
