import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { F, defaultTo, tryCatch } from 'ramda';
import { createMarkPositioner } from 'remirror/extensions';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import FluidTextField from 'design-system/organisms/FluidTextField/index';

import {
  CommandButton,
  FloatingToolbar,
  useActive,
  useAttrs,
  useChainedCommands,
  useCurrentSelection,
  useMarkRange,
} from '@remirror/react';

const validateURL = tryCatch(url => new URL(url), F);

const isValidURL = url => {
  let inputUrl = defaultTo('', url);

  if (inputUrl.startsWith('http')) {
    return validateURL(inputUrl);
  }

  if (inputUrl.startsWith('//')) {
    inputUrl = `${window.location.protocol}${inputUrl}`;
  } else {
    inputUrl = `${window.location.protocol}//${inputUrl}`;
  }

  return validateURL(inputUrl);
};

function useFloatingLinkState() {
  const chain = useChainedCommands();

  const link = useAttrs().link();
  const linkMarkRange = useMarkRange('link');

  const [isEditing, setIsEditing] = useState(false);
  const [href, setHref] = useState(link?.href);
  const [linkText, setLinkText] = useState(linkMarkRange?.text);

  // A positioner which only shows for links.
  const linkPositioner = useMemo(() => createMarkPositioner({ type: 'link' }), []);

  const updateLinkText = useCallback(commandProps => {
    const { tr, state } = commandProps;
    const startOfLinkText = linkMarkRange.from;
    const currentTextNode = tr.doc.nodeAt(startOfLinkText);
    const updatedTextNode = state.config.schema.text(linkText);

    tr.replaceWith(startOfLinkText, startOfLinkText + currentTextNode.nodeSize, updatedTextNode);

    return tr;
  }, [linkMarkRange, linkText]);

  const saveLinkChanges = useCallback(() => {
    setIsEditing(false);

    if (linkMarkRange && isValidURL(href)) {
      chain
        .selectLink()
        .customDispatch(updateLinkText)
        .updateLink(
          { href, auto: false },
          { from: linkMarkRange.from, to: linkMarkRange.from + linkText.length },
        ).run();
    }
  }, [setIsEditing, chain, href, linkText, linkMarkRange]);

  const cancelLinkEditing = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const startEditingLink = useCallback(() => {
    setIsEditing(true);
  }, [chain, setIsEditing]);

  const onRemove = useCallback(() => {
    return chain.removeLink().focus().run();
  }, [chain]);

  const onLinkOpen = useCallback(() => {
    if (!link) {
      return;
    }

    if (link.data.downloadUrl) {
      window.open(link.data.downloadUrl, '_self');
      return;
    }
    window.open(link.href, link.target || '_blank');
  }, [chain, link]);

  useEffect(() => {
    if (!link?.href && isEditing) {
      setIsEditing(false);
    }

    setHref(link?.href);
  }, [link?.href, isEditing, setIsEditing]);

  useEffect(() => {
    if (linkMarkRange?.text) {
      setLinkText(linkMarkRange?.text);
    }
  }, [linkMarkRange?.text]);

  return useMemo(
    () => ({
      setHref,
      setLinkText,
      linkText,
      href,
      isEditing,
      linkPositioner,
      onRemove,
      onLinkOpen,
      cancelLinkEditing,
      startEditingLink,
      saveLinkChanges,
    }),
    [
      setHref,
      setLinkText,
      linkText,
      href,
      isEditing,
      linkPositioner,
      onRemove,
      onLinkOpen,
      cancelLinkEditing,
      startEditingLink,
      saveLinkChanges,
    ],
  );
}

const FloatingLinkToolbar = () => {
  const {
    setLinkText,
    linkText,
    setHref,
    href,
    isEditing,
    linkPositioner,
    onRemove,
    onLinkOpen,
    cancelLinkEditing,
    startEditingLink,
    saveLinkChanges,
  } = useFloatingLinkState();

  const { empty: emptyTextSelection } = useCurrentSelection();
  const [enterKeyPressToStore, setEnterKeyPressToStore] = useState(false);

  useEffect(() => {
    if (enterKeyPressToStore) {
      setEnterKeyPressToStore(false);
      saveLinkChanges();
    }
  }, [linkText, href, enterKeyPressToStore]);

  const active = useActive();
  const activeLink = active.link();

  if (!activeLink || !emptyTextSelection) {
    return null;
  }

  return (
    <StyledFloatingToolbar
      positioner={linkPositioner}
      placement="bottom"
      direction="column"
      fullWidth={isEditing}
      className='link-toolbar'
      disablePortal
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {isEditing &&
        <LinkEditForm>
          <FluidTextField
            key="linkHref"
            onChange={href => setHref(href)}
            value={href}
            label="Link"
            format="row"
            onEnterKeyDown={() => setEnterKeyPressToStore(true)}
          />
          <FluidTextField
            key="linkText"
            onChange={text => setLinkText(text)}
            value={linkText}
            label="Text"
            format="row"
            onEnterKeyDown={() => setEnterKeyPressToStore(true)}
          />
          <LinkEditFormButtonRow>
            <Button id="cancel-button" onClick={cancelLinkEditing}>
              Cancel
            </Button>
            <Button id="confirm-button" onClick={saveLinkChanges} color="primary">
              Update
            </Button>
          </LinkEditFormButtonRow>
        </LinkEditForm>
      }
      {activeLink && !isEditing &&
        <>
          <CommandButton commandName='updateLink' onSelect={startEditingLink} icon='pencilLine' enabled />
          <CommandButton commandName='removeLink' onSelect={onRemove} icon='linkUnlink' enabled />
          <CommandButton commandName='openLink' onSelect={onLinkOpen} icon='externalLinkFill' enabled />
        </>
      }
    </StyledFloatingToolbar>
  );
};

const StyledFloatingToolbar = styled(FloatingToolbar)`
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(9, 30, 66, 0.31), 0 4px 8px -2px rgba(9, 30, 66, 0.25);
  display: flex;
  line-height: 1;
  box-sizing: border-box;
  padding: 4px 8px;
  z-index: 9999;
  ${props => props.fullWidth && `
    width: 60%;

    >div:first-child {
      width: 100%;
    }`
  }
`;

const LinkEditForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LinkEditFormButtonRow = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
`;

export default FloatingLinkToolbar;
