import React from 'react';

import Actions from './Actions';
import { ColoredSpan, Wrapper, TextWrapper } from './styled';

const GroupHeaderInnerRenderer = params => {
  const { value, data, retrieveColor } = params;

  const getValue = () => {
    if (data.isGroupRow)
      return (
        <TextWrapper>
          <ColoredSpan $color={retrieveColor(data)}>{value}</ColoredSpan>
        </TextWrapper>
      );

    return <TextWrapper>{value}</TextWrapper>;
  };

  return (
    <Wrapper $indent={!data.isGroupRow}>
      {getValue()}
      <Actions {...params} />
    </Wrapper>
  );
};

export default GroupHeaderInnerRenderer;
