import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { not } from 'ramda';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';

import Checkbox from 'design-system/atoms/Checkbox/index';
import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';
import MultiSelect from 'design-system/atoms/MultiSelect/index';

import { IDEA_LAYER } from 'store/projects/constants';

const isMultiLayer = l => Array.isArray(l);
const MULTI_LEVEL_KEY = 'MULTI';
const MULTI_LAYER_OPTION = { key: MULTI_LEVEL_KEY, value: 'Multiple levels' };

const AddMultiLayerToSearch = ({
  onSelectLayer = () => {},
  onSelectSecondLayer = () => {},
  layer = null,
  secondLayer = null,
  options = [
    { key: '0', value: 'Ideas' },
    { key: '1', value: 'Initiatives' },
    { key: '2', value: 'Bets' },
  ],
  secondLayerOptions = [
    { key: '0', value: 'Ideas' },
    { key: '1', value: 'Initiatives' },
  ],
  disabled = false,
  bottomLayer = IDEA_LAYER,
  ...props
}) => {
  const [currentLayer, setCurrentLayer] = useState(layer);
  const [currentSecondLayer, setCurrentSecondLayer] = useState(secondLayer);

  useEffect(() => setCurrentLayer(layer), [layer]);

  const optionsWithMultilayer = useMemo(() => [...options, MULTI_LAYER_OPTION], [options]);
  const optionsForSelectLayerDropdown = useMemo(
    () => optionsWithMultilayer.map(obj => ({ label: obj.value, value: obj.key })),
    [optionsWithMultilayer],
  );

  const optionsLabels = useMemo(() => options.map(obj => ({ label: obj.value, value: obj.key })), [options]);

  const _onChangeLayer = useCallback(
    e => {
      if (e.target.value === MULTI_LEVEL_KEY) {
        // TODO: should we use botoom layer first time enters on multi layer option?
        onSelectLayer([bottomLayer]);
        setCurrentLayer([bottomLayer]);
        return;
      }

      onSelectLayer(e.target.value);
      setCurrentLayer(e.target.value);
    },
    [onSelectLayer, bottomLayer],
  );

  const _onChangeMultiSelectLayer = useCallback(
    v => {
      onSelectLayer(v);
      setCurrentLayer(v);
    },
    [onSelectLayer],
  );

  const _onChangeSecondLayers = useCallback(
    e => {
      let values;

      if (e.target.checked) {
        values = (secondLayerOptions || []).map(o => o.key);
      } else {
        values = [];
      }

      onSelectSecondLayer(values);
      setCurrentSecondLayer(values);
    },
    [onSelectSecondLayer, secondLayerOptions],
  );

  const currentLayerIsMultiLayer = isMultiLayer(currentLayer);
  const currentLayerIsNotMultiLayer = not(currentLayerIsMultiLayer);

  const showIncludeItemsWithoutParentCheckbox = currentLayerIsNotMultiLayer && !!currentLayer && currentLayer !== bottomLayer;
  const selectedLayerOnDropdown = currentLayerIsMultiLayer ? MULTI_LEVEL_KEY : currentLayer;

  return (
    <div className={props.className}>
      <Line>
        <StyledTypography>Search </StyledTypography>
        <SelectLayer
          data-testid="advanced-search-select-layer"
          disabled={disabled}
          value={selectedLayerOnDropdown}
          onChange={_onChangeLayer}
          SelectDisplayProps={{
            id: 'search_guide',
          }}
        >
          {optionsForSelectLayerDropdown.map(option => (
            <StyledMenuItemDropdown value={option.value}>{option.label}</StyledMenuItemDropdown>
          ))}
        </SelectLayer>
        {showIncludeItemsWithoutParentCheckbox && (
          <>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={!!(currentSecondLayer || []).length} onChange={_onChangeSecondLayers} />
              }
              label="include items without parent"
            />
          </>
        )}
        {currentLayerIsMultiLayer && (
          <MultiSelectWrapper>
            <MultiSelect options={optionsLabels} hideCreateOption value={currentLayer} onChange={_onChangeMultiSelectLayer} />
          </MultiSelectWrapper>
        )}
      </Line>
    </div>
  );
};

const Line = styled.div`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const StyledTypography = styled(props => <Typography {...props} variant="body2" />)`
  margin-top: 10px;
`;

const SelectLayer = styled(Select)`
  &&&& {
    margin: 0 10px 0 10px;
    max-width: 200px;
  }
`;

const MultiSelectWrapper = styled.div`
  width: 100%;

  div[type='text'] {
    padding: 2px;
    min-height: 29px;
  }
`;

export default AddMultiLayerToSearch;
