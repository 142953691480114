import { useRef, useState, useEffect } from 'react';

import { removeGanttTolltip } from 'components/ganttCommon';
import moment from 'moment-timezone';
import defaultGanttConfigs from '../../containers/GanttTimeline/GanttContainer/helpers/defaultGanttConfigs';

let selectedTask;

export default ({ lsState, data, onRefresh, ganttInitFuncs }) => {
  const [gantt, setGantt] = useState(null);
  const showStoriesRef = useRef(lsState.showStories);
  const [ganttInitialized, setGanttInitialized] = useState(false);
  const ganttRef = useRef(null);

  showStoriesRef.current = lsState.showStories;

  const refreshGantt = data => {
    if (!gantt) return;
    const { x, y } = gantt.getScrollState();

    if (lsState.hideDependencyLines && data) {
      data.links = [];
    }

    if (data) {
      gantt.clearAll();
      gantt.parse(data);
    }

    if (onRefresh) onRefresh(gantt, data);

    if (selectedTask && gantt.getTask(selectedTask)) gantt.selectTask(selectedTask);

    gantt.scrollTo(x, y);
    gantt.render();
    removeGanttTolltip();
  };

  const reloadGantt = () => {
    const divRef = ganttRef.current;

    if (!divRef) return;

    if (gantt) {
      gantt.clearAll();
    }
    const ganttInstance = gantt ?? window.Gantt.getGanttInstance();

    defaultGanttConfigs(ganttInstance);
    setGantt(ganttInstance);

    ganttInitFuncs.forEach(f => f && f(ganttInstance));

    selectedTask = null;
    ganttInstance.attachEvent('onTaskSelected', id => {
      selectedTask = id;
    });
    ganttInstance.attachEvent('onBeforeLinkAdd', () => !showStoriesRef.current);
    ganttInstance.attachEvent('onBeforeLinkDelete', () => !showStoriesRef.current);
    ganttInstance.attachEvent('onLinkDblClick', () => !showStoriesRef.current);

    ganttInstance.init(divRef);
    setGanttInitialized(true);
    ganttInstance.parse(data);

    window.onresize = () => {
      ganttInstance.render();
    };

    ganttInstance.showDate(moment());
  };

  useEffect(() => {
    if (ganttRef.current && !ganttInitialized) {
      reloadGantt();
    }
  }, [ganttRef.current]);

  // Sometimes the gantt tolltip stay stuck on the page
  useEffect(() => {
    return () => removeGanttTolltip();
  }, []);

  return { gantt, refreshGantt, ganttRef, reloadGantt };
};
