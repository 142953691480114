import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import Search from 'design-system/atoms/DragonIcons/Search';
import theme from 'design-system/theme';

const NewDragonInput = ({ type, value, onChange, inputRef, ...props }) => {
  const [val, setVal] = React.useState(value);
  const debouncedCall = React.useMemo(() => onChange && debounce(onChange, 100), []);

  React.useEffect(() => {
    if (value !== val) setVal(value);
  }, [value]);

  const _handleChange = e => {
    setVal(e.target.value);
    if (debouncedCall) debouncedCall(e.target.value);
  };

  return (
    <Wrapper {...props}>
      <Input ref={inputRef} type={type} value={val} onChange={_handleChange} />
      {type === 'search' && (
        <Icon>
          <Search />
        </Icon>
      )}
    </Wrapper>
  );
};

const Input = styled.input`
  &&&& {
    width: 100%;
    height: 30px;

    padding: 9px 15px;
    box-sizing: border-box;

    border: 0.67px solid ${({ theme }) => theme.palette.newLayout.background.lightDisable};
    border-radius: 100px;

    outline: none;

    color: ${({ theme }) => theme.palette.newLayout.text.primary};

    &[type='search']::-webkit-search-decoration,
    &[type='search']::-webkit-search-cancel-button,
    &[type='search']::-webkit-search-results-button,
    &[type='search']::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    &:focus {
      background: ${({ theme }) => theme.palette.newLayout.background.white};
    }

    ${({ type }) =>
      type === 'search' &&
      `
      padding-right: 30px;
    `}
  }
`;

const Icon = styled.div`
  &&&& {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);

    svg path {
      fill: ${theme.palette.newLayout.background.lightDisable};
    }
  }
`;

const Wrapper = styled.div`
  &&&& {
    position: relative;
    background: transparent;

    padding: 5px;
    border-radius: 100px;

    &:hover {
      background: ${({ theme }) => theme.palette.newLayout.background.lightestDisable};
    }
  }
`;

NewDragonInput.propTypes = {
  type: PropTypes.oneOf(['email', 'search', 'text', 'password']).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default NewDragonInput;
