/* eslint-disable max-len */
import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={46.275}
      height={50.375}
      viewBox="0 0 46.275 50.375"
      {...props}
    >
      <defs>
        <style>
          {'.prefix__a{fill:#5accc6;stroke:#5accc6;stroke-width:.8px}'}
        </style>
      </defs>
      <path
        className="prefix__a"
        d="M44 6.553V4.592H2.287v1.993h6.05c.679 0 1.066.322 1.08.865.014.576-.378.95-1.082.95q-3.425.022-6.851 0C.713 8.4.408 8.049.4 7.257v-3.2c0-1.05.288-1.342 1.33-1.342H22.237c0-.495-.014-.928 0-1.36.025-.625.367-.969.919-.956.527.012.837.343.869.944.021.4.024.793.04 1.19 0 .037.036.073.1.184H44.158c.251 0 .5-.011.754.008a.93.93 0 01.956 1.015 224.35 224.35 0 010 3.576c-.005.734-.331 1.054-1.076 1.092-1.166.061-1.166.061-1.166 1.221v21.205a4.865 4.865 0 01-4.982 5.009q-15.524.013-31.049 0a4.9 4.9 0 01-4.858-3.85 3.549 3.549 0 01-.089-.806q-.009-9.944-.007-19.888c0-.185.018-.371.033-.644a9.336 9.336 0 011.446.028c.142.023.328.373.33.576.025 2.07.017 4.14.017 6.211 0 4.475.006 8.951-.006 13.426a2.716 2.716 0 001.261 2.63 4.266 4.266 0 001.963.5c8.254.034 16.509.024 24.763.025h6.034c2.24 0 3.322-1.071 3.323-3.3V8.447c-.274-.015-.477-.036-.68-.036h-25.2c-.293 0-.588.011-.88-.008-.627-.041-.989-.381-.992-.91 0-.562.374-.9 1.04-.907 1.781-.008 3.562 0 5.343 0h22.941c.185 0 .371-.017.624-.03M24.036 43.993v2.781c0 .711.01 1.421-.005 2.132-.014.643-.331.99-.876 1-.571.011-.907-.347-.913-1.023-.013-1.379-.006-2.759-.011-4.138 0-.2-.023-.4-.042-.717-.245.2-.4.318-.553.449q-2.769 2.458-5.535 4.919a2.819 2.819 0 01-.435.358.872.872 0 01-1.191-.153.83.83 0 01.012-1.19 4.849 4.849 0 01.456-.43c2.251-2 4.494-4.013 6.762-6a1.4 1.4 0 00.542-1.243 18.729 18.729 0 010-1.88.89.89 0 111.78.023c.021.668.032 1.339 0 2.006a1.182 1.182 0 00.467 1.022c2.246 1.976 4.477 3.969 6.712 5.957.172.153.351.3.509.466a.875.875 0 01.07 1.286.922.922 0 01-1.3.046c-.61-.51-1.192-1.051-1.786-1.579l-4.174-3.712c-.107-.095-.224-.177-.488-.383"
      />
      <path
        className="prefix__a"
        d="M22.589 16.124c1.239.826 2.491 1.551 3.62 2.433a13.379 13.379 0 015.18 9.191 5.667 5.667 0 01.038.75.825.825 0 01-.787.89.807.807 0 01-.969-.686c-.144-.714-.194-1.449-.366-2.156-1.019-4.181-3.8-6.834-7.665-8.574.191.423.386.844.571 1.27a3.935 3.935 0 01.289.756.837.837 0 01-.577 1.042.8.8 0 01-1.067-.408 127.206 127.206 0 01-1.89-4.091.859.859 0 01.566-1.155q1.878-.786 3.771-1.537a.91.91 0 11.688 1.678c-.473.217-.958.407-1.4.595M16.715 15.972a4.136 4.136 0 11-4.149-4.112 4.269 4.269 0 014.149 4.112m-1.83-.011a2.3 2.3 0 10-2.291 2.326 2.128 2.128 0 002.291-2.326M21.857 27.388c.623.582 1.2 1.1 1.747 1.638.522.512.569 1.084.158 1.477s-.907.352-1.425-.173c-.541-.548-1.056-1.12-1.656-1.761-.634.652-1.163 1.21-1.707 1.753s-1.056.584-1.474.149c-.388-.4-.34-.928.167-1.425.551-.54 1.124-1.056 1.791-1.68-.658-.622-1.226-1.14-1.772-1.68s-.58-1.071-.161-1.471.918-.348 1.431.17c.542.548 1.06 1.119 1.711 1.809.594-.649 1.086-1.211 1.605-1.746.573-.59 1.089-.646 1.518-.2.389.4.318.957-.209 1.479s-1.08 1.045-1.723 1.665M34.554 17.944c-.567.61-1.039 1.137-1.532 1.644-.6.616-1.142.709-1.564.3s-.35-.97.255-1.562c.507-.5 1.03-.977 1.614-1.53-.575-.575-1.089-1.082-1.6-1.6a2.229 2.229 0 01-.439-.524.834.834 0 01.243-1.119.85.85 0 011.2.073c.593.587 1.154 1.207 1.794 1.882.616-.614 1.156-1.156 1.7-1.693s1.067-.6 1.477-.2.358.952-.169 1.472-1.086 1.038-1.7 1.62c.6.6 1.143 1.142 1.682 1.684s.6 1.069.2 1.474-.962.352-1.476-.166c-.529-.534-1.044-1.082-1.691-1.755"
      />
    </svg>
  );
}

export default SvgComponent;
