import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';

import { ROADMAP_PAGE } from 'constants/filters';
import { COLOR_BY_OPTIONS } from 'constants/roadmap';
import Checkbox from 'design-system/atoms/Checkbox/index';
import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';

import { helpers as timelineHelpers } from 'design-system/organisms/Timeline';
import useOrganizations from 'hooks/useOrganizations';
import useTimelines from 'hooks/useTimelines';
import { ucFirst } from 'utils';
import usePermissions from 'hooks/permissions/usePermissions';

import ColorLegend from './ColorLegend';

const PreferencesDialog = ({ onClose }) => {
  const { systemFields, hasKeyResults, hasKeyResults2, hasProducts } = useOrganizations();

  const { canView } = usePermissions();

  const colorByOptions = COLOR_BY_OPTIONS(systemFields, hasKeyResults, hasProducts, canView, hasKeyResults2);

  const { lsState, updateState } = useTimelines(ROADMAP_PAGE);

  const [tempState, _setTempState] = useState(lsState);

  const { showLegend, selectedColorBy, displayMilestone, displayMilestoneOn, showTooltip, snapToGridOn } = tempState;

  const setTempState = data => _setTempState(prevState => ({ ...prevState, ...data }));

  const onCancel = () => onClose();
  const onApply = () => {
    updateState(tempState);
    onClose();
  };

  const handleChangeDisplayMilestone = useCallback(
    () =>
      setTempState({
        displayMilestone: !displayMilestone,
        displayMilestoneOn: null,
      }),
    [displayMilestone],
  );

  const handleDisplayMilestoneInOwnLane = useCallback(() => setTempState({ displayMilestoneOn: null }), []);
  const handleDisplayMilestoneInTopLane = useCallback(() => setTempState({ displayMilestoneOn: 'topLane' }), []);

  const handleChangeColorBy = useCallback(event => {
    const newColorBy = colorByOptions.find(option => option.key === event.target.value);
    const stateToUpdate = { selectedColorBy: newColorBy };

    if (newColorBy.key === 'idea') stateToUpdate.showLegend = false;

    setTempState(stateToUpdate);
  }, []);

  const handleChangeShowLegend = useCallback(() => setTempState({ showLegend: !showLegend }), [showLegend]);

  const handleChangeShowTooltip = useCallback(() => setTempState({ showTooltip: !showTooltip }), [showTooltip]);

  const handleChangeSnapToGrid = useCallback(() => setTempState({ snapToGridOn: snapToGridOn ? null : 'startDate' }), [
    snapToGridOn,
  ]);

  const handleSnapToGridOnStartDate = useCallback(() => setTempState({ snapToGridOn: 'startDate' }), []);

  const handleSnapToGridOnEndDate = useCallback(() => setTempState({ snapToGridOn: 'endDate' }), []);

  const handleSnapToGridOnPredictedEndDate = useCallback(() => setTempState({ snapToGridOn: 'predictedEndDate' }), []);

  return (
    <Dialog open maxWidth="md" fullWidth>
      <DialogTitle>Display Preferences</DialogTitle>
      <DialogContent>
        <PreferenceGroup>
          <StyledFormControlLabel
            control={<Checkbox color="primary" checked={displayMilestone} onChange={handleChangeDisplayMilestone} />}
            label="Display Milestone"
          />
          {displayMilestone && (
            <Preference row>
              <StyledFormControlLabel
                control={<Radio checked={!displayMilestoneOn} color="primary" onChange={handleDisplayMilestoneInOwnLane} />}
                label="In its own lane"
                key="ownLane"
              />
              <StyledFormControlLabel
                control={
                  <Radio checked={displayMilestoneOn === 'topLane'} color="primary" onChange={handleDisplayMilestoneInTopLane} />
                }
                label="On top lane"
                key="displayMilestoneOn"
              />
            </Preference>
          )}
        </PreferenceGroup>

        <PreferenceGroup>
          <StyledFormControlLabel
            control={<Checkbox color="primary" checked={!!snapToGridOn} onChange={handleChangeSnapToGrid} />}
            label="Snap to grid"
          />
          {!!snapToGridOn && (
            <Preference row>
              <StyledFormControlLabel
                control={
                  <Radio
                    checked={snapToGridOn === timelineHelpers.snapToGridOptions.TARGET_START_DATE}
                    color="primary"
                    onChange={handleSnapToGridOnStartDate}
                  />
                }
                label="Use target start date"
                key={timelineHelpers.snapToGridOptions.TARGET_START_DATE}
              />
              <StyledFormControlLabel
                control={
                  <Radio
                    checked={snapToGridOn === timelineHelpers.snapToGridOptions.TARGET_END_DATE}
                    color="primary"
                    onChange={handleSnapToGridOnEndDate}
                  />
                }
                label="Use target end date"
                key={timelineHelpers.snapToGridOptions.TARGET_END_DATE}
              />
              <StyledFormControlLabel
                control={
                  <Radio
                    checked={snapToGridOn === timelineHelpers.snapToGridOptions.PREDICTED_END_DATE}
                    color="primary"
                    onChange={handleSnapToGridOnPredictedEndDate}
                  />
                }
                label="Use predicted end date"
                key={timelineHelpers.snapToGridOptions.PREDICTED_END_DATE}
              />
            </Preference>
          )}
        </PreferenceGroup>
        <PreferenceGroup>
          <FormLabel control="legend">Color by</FormLabel>
          <FormControl fullWidth margin="dense">
            <Select
              value={selectedColorBy && selectedColorBy.key}
              label="Color by"
              onChange={handleChangeColorBy}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              }}
            >
              {colorByOptions &&
                colorByOptions.map(colorBy => (
                  <StyledMenuItemDropdown key={colorBy.key} value={colorBy.key}>
                    {ucFirst(colorBy.title)}
                  </StyledMenuItemDropdown>
                ))}
            </Select>
          </FormControl>
        </PreferenceGroup>
        <PreferenceGroup>
          <StyledFormControlLabel
            control={
              <Checkbox
                key={selectedColorBy?.key || ''}
                color="primary"
                checked={showLegend && selectedColorBy?.key !== 'idea'}
                disabled={selectedColorBy?.key === 'idea'}
                onChange={handleChangeShowLegend}
              />
            }
            label="Show color legend"
          />
        </PreferenceGroup>
        {showLegend && (
          <ColorLegendContainer>
            <ColorLegend colorBy={selectedColorBy} />
          </ColorLegendContainer>
        )}
        <PreferenceGroup>
          <StyledFormControlLabel
            control={<Checkbox color="primary" checked={showTooltip} onChange={handleChangeShowTooltip} />}
            label="Show Tooltip"
          />
        </PreferenceGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onApply} color="primary">
          Apply
        </Button>
        <Button onClick={onCancel}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreferencesDialog;

const PreferenceGroup = styled.div`
  margin: 12px 0;
`;

const ColorLegendContainer = styled.div`
  width: 100%;
  display: block;
`;

const Preference = styled(FormGroup)`
  &&&& {
    margin: 0 6px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &&& {
    min-width: 250px;
  }
`;
