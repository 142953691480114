import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureFlags } from '@dragonboat/config';

import useFeatureFlags from 'hooks/useFeatureFlags';
import useMetadataForOptions from 'hooks/metadata/useMetadataForOptions';
import useFormatRoadmapsForDropdown from 'containers/ProjectLightBox/hooks/useFormatRoadmapsForDropdown';
import { IDEA, INITIATIVE, BET } from 'constants/projects';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { USERS, PRODUCTS, ROADMAPS } from 'constants/common';
import { createProjectFromCanvas } from 'features/Canvas/store/thunks';

const METADATA_KEYS = [USERS, ROADMAPS, PRODUCTS];

const layerByType = {
  [IDEA]: IDEA_LAYER,
  [INITIATIVE]: INITIATIVE_LAYER,
  [BET]: BET_LAYER,
};

const initialFormValues = {
  title: null,
  roadmap: {},
  owner: '',
  details: '',
};

const getMatchingRoadmap = (roadmapTitle, roadmaps) => roadmaps?.find(roadmap => roadmap.title === roadmapTitle);

const processDataForCreateProject = (formValues, roadmaps, objectTitle) => {
  const { type, title, roadmap, owner, details } = formValues;
  const { roadmapTitle, product1Title, product2Title } = roadmap || {};

  const matchingRoadmap = roadmapTitle ? getMatchingRoadmap(roadmapTitle, roadmaps) : null;
  const matchingProduct1 = product1Title && matchingRoadmap ? getMatchingRoadmap(product1Title, matchingRoadmap?.products) : null;
  const matchingProduct2 =
    product2Title && matchingProduct1 ? getMatchingRoadmap(product2Title, matchingProduct1?.products) : null;

  return {
    title: title || objectTitle,
    layer: layerByType[type],
    ...(details ? { details } : {}),
    ...(owner ? { owner_id: owner } : {}),
    ...(matchingRoadmap ? { roadmap_id: matchingRoadmap.id } : {}),
    ...(matchingProduct1 ? { product_1_id: matchingProduct1.id } : {}),
    ...(matchingProduct2 ? { product_2_id: matchingProduct2.id } : {}),
  };
};

const useCreateProjectForm = (type, objectTitle, textLabelId, onClose) => {
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState(initialFormValues);

  const hasProducts = useFeatureFlags([FeatureFlags.HAS_PRODUCTS]);
  const hasProducts2 = useFeatureFlags([FeatureFlags.HAS_PRODUCTS_2]);

  const {
    filteredOptions: { roadmaps, roadmapCorps: roadmapsCorp, users },
  } = useMetadataForOptions({
    metadataKeys: METADATA_KEYS,
  });

  const roadmapOptions = useFormatRoadmapsForDropdown(roadmaps, roadmapsCorp, hasProducts, hasProducts2);

  const handleFormValueChange = (property, value) => setFormValues(prevFormValues => ({ ...prevFormValues, [property]: value }));

  const onCloseCallback = () => setFormValues(initialFormValues);

  const handleSave = () => {
    dispatch(
      createProjectFromCanvas(processDataForCreateProject({ ...formValues, type }, roadmaps, objectTitle), textLabelId, type),
    );

    onClose(onCloseCallback);
  };

  return {
    objectTitle,
    formValues,
    roadmapOptions,
    ownerOptions: users,
    handleFormValueChange,
    handleSave,
    onCloseCallback,
  };
};

export default useCreateProjectForm;
