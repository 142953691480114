import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';

import sortOrder from 'utils/sortOrder';
import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';

import calcProjectTimeline from './helpers/calcProjectTimeline';
import generateGanttLinks from './helpers/generateGanttLinks';
import generateGroups from './helpers/generateGroups';
import generateGanttProjectEstimatesFactory from './helpers/generateGanttProjectEstimatesFactory';
import generateGanttProjectTasksAndSubtasksFactory from './helpers/generateGanttProjectTasksAndSubtasksFactory';
import generateGanttProjectStoriesFactory from './helpers/generateGanttProjectStoriesFactory';
import generateGanttProjectChildrenFactory from './helpers/generateGanttProjectChildrenFactory';
import getOrCreateWithoutParentSeparators from './helpers/getOrCreateWithoutParentSeparators';
import groomGanttObjectFactory from './helpers/groomGanttObjectFactory';
import genericParseGanttProject from './parsers/parseGanttProject';

const IGNORE_PROJECTS_WITH_NO_PARENT = false;

/**
 * Helper function that parse projects from the store to the gantt format and use all the logic aggregated to each property
 *
 * @param {*} data data used to parse (projects, roadmaps, timeframes, ...)
 * @param {*} options parsing options (showEstimates, showTasks, showStories, ...)
 * @param {*} orgIntegration organization integration
 * @returns parsed projects with dependency links
 */
const parseProjectsToGantt = (data, options, orgIntegrations, changedIds, displayLayer) => {
  const { projects, teams, ideas = [], initiatives = [], bets = [] } = data;

  const {
    showEstimates,
    showTasks,
    showStories,
    getSystemFieldName,
    coloredGroups,
    customFields,
    portfolioMode,
    useTaskOnConfirmedIdeas,
    hasBet,
    hideItemsWithoutDependencies,
    projectGroups,
    allProjectsFromGroups,
  } = options;

  const allInitialProjects = isEmpty(allProjectsFromGroups)
    ? projects
    : [...projects.flatMap(p => allProjectsFromGroups.filter(groupProject => groupProject.id === p.id))];
  const allIdeas = isEmpty(allProjectsFromGroups) ? ideas : allProjectsFromGroups.filter(p => p.layer === IDEA_LAYER);
  const allInitiatives = isEmpty(allProjectsFromGroups)
    ? initiatives
    : allProjectsFromGroups.filter(p => p.layer === INITIATIVE_LAYER);
  const allBets = isEmpty(allProjectsFromGroups) ? bets : allProjectsFromGroups.filter(p => p.layer === BET_LAYER);

  const changedProjects = changedIds ? allInitialProjects.filter(o => changedIds.includes(o.id)) : allInitialProjects;
  const changedIdeas = changedIds ? allIdeas.filter(o => changedIds.includes(o.id)) : allIdeas;
  const changedInitiatives = changedIds ? allInitiatives.filter(o => changedIds.includes(o.id)) : allInitiatives;
  const changedBets = changedIds ? allBets.filter(o => changedIds.includes(o.id)) : allBets;

  const collapseGanttBars = showStories ? false : options.collapseGanttBars;

  const _shouldRenderTasks = p => {
    if (showStories) return false;
    if (!showTasks && useTaskOnConfirmedIdeas) return p.planningStage === 'Confirmed';
    return showTasks;
  };

  const _shouldRenderEstimates = p => {
    if (showStories) return false;

    if (p.planningStage === 'Confirmed') {
      if (showEstimates && useTaskOnConfirmedIdeas && p?.tasks?.length > 0) {
        // When flag to show only assignment on confirmed ideias is set to true
        // and we have at least one assignment, dont render estimates
        // Otherwise let estimates render if we have them
        return false;
      }
    }

    return showEstimates;
  };

  const estTeams = [];

  const groomGanttObject = groomGanttObjectFactory(customFields);
  const parseGanttProject = p => genericParseGanttProject(p, teams, options, orgIntegrations);
  const generateGanttProjectEstimates = generateGanttProjectEstimatesFactory(estTeams, collapseGanttBars, sortOrder);
  const generateGanttProjectTasksAndSubtasks = generateGanttProjectTasksAndSubtasksFactory(
    estTeams,
    collapseGanttBars,
    sortOrder,
    options,
  );
  const generateGanttProjectStories = generateGanttProjectStoriesFactory(
    showStories,
    orgIntegrations,
    _shouldRenderTasks,
    _shouldRenderEstimates,
    generateGanttProjectTasksAndSubtasks,
    generateGanttProjectEstimates,
  );

  let refProjects = changedProjects;

  let [allProjectsByParentId = {}, noParentsChildren, noParentsChildrenIds] = [];
  const betLevelSep = [];
  const initLevelSep = [];

  /* ===================================
  If portfolio mode get all objects with no parents + objects whose parent is not in-scope
  =================================== */
  if (portfolioMode) {
    allProjectsByParentId = {};

    const allProjects = [...changedBets, ...changedInitiatives, ...changedIdeas];
    const allProjectsById = allProjects.reduce((allProjectsById, entry) => {
      allProjectsById[entry.id] = entry;
      allProjectsByParentId[entry.parent_id] = [...(allProjectsByParentId[entry.parent_id] || []), entry];
      return allProjectsById;
    }, {});
    const allProjectsIds = Object.keys(allProjectsById);
    const parents = [...changedInitiatives, ...changedIdeas];

    noParentsChildren = parents.filter(({ parent_id: parentId }) => !allProjectsIds.includes(String(parentId)));
    noParentsChildrenIds = noParentsChildren.map(({ id }) => id);

    if (!IGNORE_PROJECTS_WITH_NO_PARENT) {
      // Should remove no parent children from the initial projects to consider
      // this overlap may happen when the allInitialProject is from a parent layer (INITIATIVE or BET)
      refProjects = [...noParentsChildren, ...allInitialProjects.filter(project => !noParentsChildrenIds.includes(project.id))];
    }
  }

  const generateGanttProjectChildren = generateGanttProjectChildrenFactory(
    allProjectsByParentId,
    getSystemFieldName,
    _shouldRenderEstimates,
    _shouldRenderTasks,
    parseGanttProject,
    generateGanttProjectEstimates,
    generateGanttProjectTasksAndSubtasks,
    generateGanttProjectStories,
    sortOrder,
  );

  /* ===================================
  Gets an array with all inscope tasks
  =================================== */
  const projectsWithChildren = refProjects.reduce((acc, project) => {
    let projectWithAllLayersChildren = project;

    // For the group timeline we need all layers children and not direct children only
    if (portfolioMode && allProjectsByParentId[project.id]) {
      projectWithAllLayersChildren = { ...project, children: allProjectsByParentId[project.id] };
    }

    const ganttProject = parseGanttProject(projectWithAllLayersChildren);

    ganttProject.showEstimates = _shouldRenderEstimates(project);
    ganttProject.showTasks = _shouldRenderTasks(project);

    // Builds whats we call 'separators' which are placeholder gantt tasks
    // added on all levels view to group objects with no parent under it
    if (portfolioMode && !IGNORE_PROJECTS_WITH_NO_PARENT) {
      if (noParentsChildrenIds.includes(project.id)) {
        const separators = getOrCreateWithoutParentSeparators(
          project,
          betLevelSep,
          initLevelSep,
          hasBet,
          projectGroups,
          getSystemFieldName,
          displayLayer,
        );

        if (separators?.betLevelSeparator) {
          if (separators.betLevelSeparator.isNew) {
            betLevelSep.push(separators.betLevelSeparator.object);
          }

          ganttProject.parent = separators.betLevelSeparator.object?.id || ganttProject.parent;
        }

        if (separators?.initiativeLevelSeparator) {
          if (separators.initiativeLevelSeparator.isNew) {
            initLevelSep.push(separators.initiativeLevelSeparator.object);
          }
          ganttProject.parent = separators.initiativeLevelSeparator.object?.id || ganttProject.parent;
        }
      }
    }

    const projectTimeline = calcProjectTimeline(ganttProject);

    // ================================
    // ADD ESTIMATES AND TASKS
    if (_shouldRenderEstimates(project)) {
      acc = acc.concat(generateGanttProjectEstimates(project));
    }
    if (_shouldRenderTasks(project)) {
      acc = acc.concat(generateGanttProjectTasksAndSubtasks(project));
    }

    // ================================
    // ADD children
    if (allProjectsByParentId[String(project.id)] && portfolioMode) {
      acc = acc.concat(generateGanttProjectChildren(project));
    }

    // ================================
    // ADD STORIES FROM INTEGRATION
    const projectStories = generateGanttProjectStories(project, ganttProject);

    acc = acc.concat(projectStories.stories);

    acc.push({ ...ganttProject, ...projectTimeline, ...projectStories.data });

    return acc;
  }, []);

  if (betLevelSep?.length && portfolioMode) projectsWithChildren.push(...uniqBy(betLevelSep, sep => sep.id));
  if (initLevelSep?.length && portfolioMode) projectsWithChildren.push(...uniqBy(initLevelSep, sep => sep.id));

  // ===============
  // GROUP BY
  const groups = generateGroups(projectsWithChildren, data, projectGroups, {
    coloredGroups,
    getSystemFieldName,
    customFields,
  });

  let ganttData = projectsWithChildren.concat(groups);

  const links = generateGanttLinks(ganttData, true, showEstimates, portfolioMode);

  if (hideItemsWithoutDependencies) {
    let allDependencies = [];
    const allData = portfolioMode ? [...bets, ...initiatives, ...ideas] : projects;
    const dataIds = allData.reduce((acc, p) => {
      const _getChildren = project => {
        if (project.children && portfolioMode) {
          return [
            project.id,
            ...project.children.reduce((accChildren, child) => {
              if (child.children) {
                return [child.id, ..._getChildren(child)];
              }

              return [...accChildren, child.id];
            }, []),
          ];
        }

        return [project.id];
      };

      return [...acc, ...flatten(_getChildren(p))];
    }, []);

    const _getDataDependencies = _data => {
      return _data.map(p => {
        const projectDependencies = (p.projectDependencies || []).filter(d => dataIds.includes(d.id));

        if (!projectDependencies.length && p.children && p.children.length && portfolioMode) {
          return flatten(_getDataDependencies(p.children));
        }

        return projectDependencies.length
          ? [
              p.id,
              ...(p.parent_id && portfolioMode ? [p.parent_id] : []),
              ...flatten(
                p.projectDependencies
                  .filter(d => dataIds.includes(d.id))
                  .map(d => [d.id, portfolioMode && allData.find(p => p.id === d.id)?.parent_id].filter(Boolean)),
              ),
            ]
          : [];
      });
    };

    allDependencies = flatten(_getDataDependencies(allData));

    ganttData = ganttData.filter(
      p => p.group || ['estimate', 'task', 'story'].includes(p.dbType) || allDependencies.includes(p.id),
    );
    ganttData = ganttData.filter(p => {
      if (p.group) {
        return ganttData.some(d => d.parent === p.id);
      }

      return true;
    });
  }

  return {
    data: ganttData.map(groomGanttObject),
    links,
  };
};

export default parseProjectsToGantt;
