import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import TruncateMarkup from 'react-truncate-markup';

import useSystemFields from 'hooks/useSystemFields';
import { getUserViewTypeForComment } from 'utils/userViews';

import JiraUpdateErrorItem from './Components/JiraUpdateErrorItem';
import JiraProjectRenamedWarning from './Components/JiraProjectRenamedWarning';

const SHOW_COMMENT = 'SHOW_COMMENT';
const SHOW_CUSTOMER_REQUEST_COMMENT = 'SHOW_CUSTOMER_REQUEST_COMMENT';
const SHOW_OBJECTIVE_COMMENT = 'SHOW_OBJECTIVE_COMMENT';
const SHOW_KEY_RESULT_COMMENT = 'SHOW_KEY_RESULT_COMMENT';
const SHOW_USER_VIEW_COMMENT = 'SHOW_USER_VIEW_COMMENT';
const JIRA_UPDATE_ERROR = 'JIRA_UPDATE_ERROR';
const JIRA_PROJECT_RENAMED_WARNING = 'JIRA_PROJECT_RENAMED_WARNING';
const PROJECT_FIELD = 'PROJECT_FIELD';
const PROJECT_HEALTH = 'PROJECT_HEALTH';
const PROJECT_FIELD_DEPS = 'PROJECT_FIELD_DEPS';
const PROJECT_HEALTH_DEPS = 'PROJECT_HEALTH_DEPS';
const SHOW_METRIC_COMMENT = 'SHOW_METRIC_COMMENT';

const NotificationItem = ({ notification, classes }) => {
  const [getSystemFieldName] = useSystemFields();

  const mapFieldToSystemFieldName = {
    timeframe_id: getSystemFieldName('timeframe'),
    phase_id: getSystemFieldName('phase'),
  };

  const _renderProjectLabel = (project, notFoundLabel = 'other project') => {
    if (!project) return notFoundLabel;

    return `${project.title} (DB-${project.id})`;
  };
  const _renderCustomerRequestLabel = (customerRequest, notFoundLabel = 'some customer request') => {
    if (!customerRequest) return notFoundLabel;

    return `${customerRequest.title} (CR-${customerRequest.key})`;
  };
  const _renderObjectiveLabel = (objective, notFoundLabel = 'some OKR') => {
    if (!objective) return notFoundLabel;

    return `${objective.title} (${getSystemFieldName('objective')} - ${objective.id})`;
  };
  const _renderKeyResultLabel = (keyResult, notFoundLabel = 'some OKR') => {
    if (!keyResult) return notFoundLabel;

    return `${keyResult.title} (${getSystemFieldName(`keyResult${keyResult.level + 1}`)} - ${keyResult.id})`;
  };
  const _renderUserViewLabel = (userView, notFoundLabel = 'some View') => {
    if (!userView) return notFoundLabel;

    return `${userView.name} (${getUserViewTypeForComment(userView)} - ${userView.id})`;
  };

  return (
    <span className={classes.root}>
      {(() => {
        switch (notification.notification_type) {
          case SHOW_COMMENT: {
            return (
              <TruncateMarkup lines="2">
                <div>
                  <span className={classes.bold}>
                    {notification.user ? `${notification.user.first_name || ''} ${notification.user.last_name || ''}` : 'Someone'}
                  </span>{' '}
                  commented on{' '}
                  <span className={classes.linkColor}>{_renderProjectLabel(notification.project, 'some project')}</span>
                </div>
              </TruncateMarkup>
            );
          }
          case SHOW_CUSTOMER_REQUEST_COMMENT: {
            return (
              <TruncateMarkup lines="2">
                <div>
                  <span className={classes.bold}>
                    {notification.user ? `${notification.user.first_name || ''} ${notification.user.last_name || ''}` : 'Someone'}
                  </span>{' '}
                  commented on{' '}
                  <span className={classes.linkColor}>{_renderCustomerRequestLabel(notification.customer_request)}</span>
                </div>
              </TruncateMarkup>
            );
          }
          case SHOW_OBJECTIVE_COMMENT: {
            return (
              <TruncateMarkup lines="2">
                <div>
                  <span className={classes.bold}>
                    {notification.user ? `${notification.user.first_name || ''} ${notification.user.last_name || ''}` : 'Someone'}
                  </span>{' '}
                  commented on <span className={classes.linkColor}>{_renderObjectiveLabel(notification.objective)}</span>
                </div>
              </TruncateMarkup>
            );
          }
          case SHOW_KEY_RESULT_COMMENT: {
            return (
              <TruncateMarkup lines="2">
                <div>
                  <span className={classes.bold}>
                    {notification.user ? `${notification.user.first_name || ''} ${notification.user.last_name || ''}` : 'Someone'}
                  </span>{' '}
                  commented on <span className={classes.linkColor}>{_renderKeyResultLabel(notification.key_result)}</span>
                </div>
              </TruncateMarkup>
            );
          }
          case SHOW_USER_VIEW_COMMENT: {
            return (
              <TruncateMarkup lines="2">
                <div>
                  <span className={classes.bold}>
                    {notification.user ? `${notification.user.first_name || ''} ${notification.user.last_name || ''}` : 'Someone'}
                  </span>{' '}
                  commented on <span className={classes.linkColor}>{_renderUserViewLabel(notification.user_view)}</span>
                </div>
              </TruncateMarkup>
            );
          }
          case SHOW_METRIC_COMMENT: {
            return (
              <TruncateMarkup lines="2">
                <div>
                  <span className={classes.bold}>
                    {notification.user ? `${notification.user.first_name || ''} ${notification.user.last_name || ''}` : 'Someone'}
                  </span>{' '}
                  commented on <span className={classes.linkColor}>Metric</span>
                </div>
              </TruncateMarkup>
            );
          }
          case PROJECT_FIELD: {
            const { notification_payload: payload } = notification;

            return (
              <TruncateMarkup lines="2">
                <div>
                  <span className={classes.linkColor}>{_renderProjectLabel(notification.project, 'some project')}</span>{' '}
                  {payload.field_name === 'owner' ? (
                    <>changed to set you as the owner.</>
                  ) : (
                    <>
                      <span>changed </span>
                      {mapFieldToSystemFieldName[payload.field] || payload.field_name}
                      from <span className={classes.bold}>{payload.prev_value || 'Undefined'}</span> to{' '}
                      <span className={classes.bold}>{payload.new_value || 'Undefined'}</span>.
                    </>
                  )}
                </div>
              </TruncateMarkup>
            );
          }
          case PROJECT_FIELD_DEPS: {
            return (
              <TruncateMarkup lines="2">
                <div>
                  <span className={classes.linkColor}>
                    {notification.projects.filter(p => !!p).length > 0
                      ? _renderProjectLabel(notification.projects[0], 'some project')
                      : 'some project'}
                  </span>
                  , which is dependent of{' '}
                  <span className={classes.linkColor}>
                    {notification.projects.filter(p => !!p).length > 1
                      ? _renderProjectLabel(notification.projects[1], 'other project')
                      : 'other project'}
                  </span>{' '}
                  changed{' '}
                  {mapFieldToSystemFieldName[notification.notification_payload.field] ||
                    notification.notification_payload.field_name}{' '}
                  from <span className={classes.bold}>{notification.notification_payload.prev_value || 'Undefined'}</span> to{' '}
                  <span className={classes.bold}>{notification.notification_payload.new_value || 'Undefined'}</span>.
                </div>
              </TruncateMarkup>
            );
          }
          case PROJECT_HEALTH: {
            return (
              <TruncateMarkup lines="2">
                <div>
                  <span className={classes.linkColor}>{_renderProjectLabel(notification.project, 'some project')}</span>
                  <span> turned </span>
                  <span className={classes.bold}>{notification.notification_payload.new_value || 'Undefined'}</span>.
                </div>
              </TruncateMarkup>
            );
          }
          case PROJECT_HEALTH_DEPS: {
            return (
              <TruncateMarkup lines="2">
                <div>
                  <span className={classes.linkColor}>
                    {notification.projects.filter(p => !!p).length > 0
                      ? _renderProjectLabel(notification.projects[0], 'some project')
                      : 'some project'}
                  </span>
                  , which is dependent of{' '}
                  <span className={classes.linkColor}>
                    {notification.projects.filter(p => !!p).length > 1
                      ? _renderProjectLabel(notification.projects[1], 'other project')
                      : 'other project'}
                  </span>{' '}
                  turned <span className={classes.bold}>{notification.notification_payload.new_value || 'Undefined'}</span>.
                </div>
              </TruncateMarkup>
            );
          }
          case JIRA_UPDATE_ERROR: {
            return <JiraUpdateErrorItem {...notification.notification_payload} />;
          }
          case JIRA_PROJECT_RENAMED_WARNING: {
            return <JiraProjectRenamedWarning {...JSON.parse(notification.notification_payload)} truncateLines="2" />;
          }
          default: {
            return <span />;
          }
        }
      })()}
    </span>
  );
};

export default withStyles(theme => ({
  root: {},
  bold: {
    fontWeight: 700,
  },
  linkColor: {
    color: theme.palette.primary,
    fontWeight: 700,
  },
}))(NotificationItem);
