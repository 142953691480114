import axios from 'axios';

import { createThunk } from 'utils/store/thunk';

import { FETCH_METRIC_ALLOCATION_CHART_DATA } from './types';
import { IDEA_LAYER } from 'store/projects/constants';

export const fetchMetricAllocationChartData = (metricId, startDate, endDate, timeUnit, sumBy) => {
  const fetchDataRequestBody = {
    projectsFilter: {},
    allocationQuery: {
      sumBy: sumBy ?? 'duration',
      timeUnit,
      displayLayer: IDEA_LAYER,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
    },
  };

  return createThunk(
    FETCH_METRIC_ALLOCATION_CHART_DATA,
    () => axios.post(`/api/metrics/${metricId}/allocation-report`, fetchDataRequestBody).then(data => data.data),
    {
      metricId,
    },
  );
};
