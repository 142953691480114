/**
 * AppLogo Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import styled from 'styled-components';
import omit from 'lodash/omit';
import isNumber from 'lodash/isNumber';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '../Button';

export default props => {
  const [loading, setLoading] = React.useState(false);

  const _onClick = React.useCallback(
    async (...attrs) => {
      if (!props.onClick) return;

      setLoading(true);
      try {
        await props.onClick(...attrs);
      } catch (e) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
    [props.onClick],
  );

  return (
    <ButtonV2 {...omit(props, ['children'])} onClick={_onClick} loading={loading}>
      {props.icon && !loading && (
        <Icon iconSize={{ width: 'auto', height: 'auto', ...(props.iconSize || {}) }}>{props.icon}</Icon>
      )}
      {loading && <Loading size={20} color={props.color} />}
      {props.children}
    </ButtonV2>
  );
};

const ButtonV2 = styled(Button)`
  &&&&& {
    width: auto;
    height: auto;
    font-weight: ${props => props.theme.typography.fontWeightMedium};
    font-family: ${props => props.theme.typography.fontFamily};
    border-radius: ${props => props.theme.shape.borderRadiusHigh}px;
    padding: ${props =>
      props.background === 'transparent'
        ? `${props.theme.spacing.unit / 2}px 0`
        : `${props.theme.spacing.unit / 2}px ${props.theme.spacing.unit * 1.75}px`};
    border-width: 1px;
    ${props => props.borderColor && `border-color: ${props.borderColor}`};

    ${props =>
      props.size === 'small' &&
      `
      padding: 0;
      font-size: ${props.theme.typography.fontSize}px;
    `}

    ${props =>
      props.rightArrowVisible &&
      props.borderColor &&
      `
        padding-right: 34px !important;

        &:after {
          content: '';
          position: absolute;
          right: 10px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 6px 0 6px;
          border-color: ${props.borderColor} transparent transparent transparent;
        }
    `}
  }
`;

const Icon = styled.span`
  display: inherit;

  ${({ iconSize }) => `
    svg {
      width: ${isNumber(iconSize.width) ? `${iconSize.width}px` : iconSize.width};
      height: ${isNumber(iconSize.height) ? `${iconSize.height}px` : iconSize.height};
    }
  `}
  margin-right: ${props => props.theme.spacing.unit}px;
`;

const Loading = styled(CircularProgress)`
  &&&& {
    margin-right: 10px;

    ${props => props.color && `color: ${props.color}`}
  }
`;
