import { useRef, useState, useEffect } from 'react';
import { isNil } from 'ramda';
import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';

const TAB_KEY_CODE = 9;
const UP_KEY_CODE = 38;
const DOWN_KEY_CODE = 40;
const ENTER_KEY_CODE = 13;

/**
 * @function useInputForDropdown
 *
 * Use input text for the dropdown select to control the dropdown options
 *
 * @param  {String} props.value
 * @param  {Function} props.onClose
 * @param  {Boolean} props.disableEdit
 * @param  {Function} props.onSuggestionSelected
 * @param  {Function} props.handleEnterKey
 * @param  {Object} props.dropdownRef
 * @param  {Number} props.totalOptions
 * @param  {String} props.inputPlaceholder
 * @return {Object}
 */
const useInputForDropdown = ({
  value,
  onClose,
  disableEdit,
  onSuggestionSelected,
  handleEnterKey,
  dropdownRef,
  totalOptions = 0,
  inputPlaceholder = TYPE_TO_SEARCH_PLACEHOLDER,
}) => {
  let tempValue = '';

  if (!isNil(value)) tempValue = value;

  const inputRef = useRef();
  const [inputValue, setInputValue] = useState(tempValue);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(0);

  const closeDropdown = () => {
    inputRef.current = null;
    setIsDropdownOpen(false);
    onClose();
  };
  const openDropdown = () => {
    if (!inputRef.current) {
      return;
    }

    setIsDropdownOpen(true);
  };

  const handleClickOutside = event => {
    const isSameAsCurrentInput = inputRef.current === event.target;

    if (isSameAsCurrentInput) {
      return;
    }

    if (!dropdownRef.current) {
      closeDropdown();
      return;
    }
    if (!dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const setInputRef = ref => {
    inputRef.current = ref;

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const inputProps = {
    placeholder: inputPlaceholder,
    value: inputValue,
    disabled: disableEdit,
    inputRef: setInputRef,
    onChange: event => {
      const { value: currentValue } = event.target;

      if (!currentValue && tempValue) {
        onSuggestionSelected(null);
      }

      setInputValue(currentValue);
    },
    onKeyDown: e => {
      const isShiftTab = e.keyCode === TAB_KEY_CODE && e.shiftKey;

      if (e.keyCode === TAB_KEY_CODE || isShiftTab) {
        closeDropdown();
      } else if (e.keyCode === UP_KEY_CODE && hoveredRowIndex > 0) {
        setHoveredRowIndex(hoveredRowIndex - 1);
      } else if (e.keyCode === DOWN_KEY_CODE) {
        // go to first position if hovered row index is the last
        if (hoveredRowIndex + 1 === totalOptions) {
          setHoveredRowIndex(0);
          return;
        }
        setHoveredRowIndex(hoveredRowIndex + 1);
      } else if (e.keyCode === ENTER_KEY_CODE) {
        handleEnterKey(hoveredRowIndex);
      }
    },
    onBlur: e => {},
    onFocus: () => {
      openDropdown();
    },
  };

  return {
    inputRef,
    setInputRef,
    inputValue,
    inputProps,
    isDropdownOpen,
    hoveredRowIndex,
  };
};

export default useInputForDropdown;
