const exist = Boolean;

const isLock = val => exist(val) && val === 'Above';

export const COMMITTED_PROJECTS = 'Above';
export const UNCOMMITTED_PROJECTS = 'Below';

export default (projects = []) => {
  const lockedIds = projects.filter(project => isLock(project.committed)).map(project => project.id);

  return lockedIds;
};
