import React from 'react';
import styled from 'styled-components';

import theme from 'design-system/theme';
import Image from './Image';
import { ScreenContainer, IllustrationWrapper, StyledText, StyledButton } from '../styled';

export const LoadingFinishedScreen = ({ onViewPlanClick }) => (
  <ScreenContainer>
    <IllustrationWrapper>
      <Image />
      <Content>
        <StyledText>
          <b>Your scenario is ready!</b>
        </StyledText>
        <StyledText maxWidth="600px" breakwords withMarginTop>
          Your new scenario is ready. View benefits, roadmaps and save plans. Our tools give you complete control over your
          resources and scenarios.
        </StyledText>
        <StyledButton
          background={theme.palette.newLayout.background.duskBlue}
          color={theme.palette.newLayout.background.white}
          hover={{ background: theme.palette.newLayout.background.dodgerBlue }}
          fontSize={theme.typography.fontSize}
          onClick={onViewPlanClick}
          width="200px"
        >
          View Plan
        </StyledButton>
      </Content>
    </IllustrationWrapper>
  </ScreenContainer>
);

export default LoadingFinishedScreen;

const Content = styled.div`
  text-align: center;
`;
