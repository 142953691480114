import { pick, omit, mapObjIndexed, keys } from 'ramda';

import { getRoadmaps, getCorpRoadmaps, getProducts, getProductsLevelTwo } from 'store/roadmaps/selectors';
import { getThemes } from 'store/themes/selectors';
import { getObjectives, selectKeyResults1, selectKeyResults2 } from 'store/objectives/selectors';
import { getPriorities } from 'store/priorities/selectors';
import { getCategories } from 'store/categories/selectors';
import { getTimeframes, getTimeframesLevel2 } from 'store/timeframes/selectors';
import { getPhases } from 'store/phases/selectors';
import { getAllTags } from 'store/tags/selectors';
import { getAllCustomers } from 'store/customers/selectors';
import { getTeams, getTeamsLevel2 } from 'store/teams/selectors';
import { getSkills } from 'store/skills/selectors';
import { getUsers } from 'store/users/selectors';
import { selectPersonasData } from 'features/Personas/store/selectors';
import { selectLifecyclesData } from 'features/Lifecycles/store/selectors';
import {
  ADDITIONAL_TEAMS_2,
  METADATA_LEVELS,
  TEAMS_2,
  TEAMS,
  ROADMAPS,
  TIMEFRAMES,
  TIMEFRAMES_2,
  PRIORITIES,
  PRODUCTS,
  PRODUCTS_2,
  USERS,
  SKILLS,
  THEMES,
  OBJECTIVES,
  TAGS,
  CUSTOMERS,
  PHASES,
  CATEGORIES,
  KEY_RESULTS,
  KEY_RESULTS_2,
  ROADMAPS_CORP,
  OBJECTIVES_CORP,
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_PRODUCTS_2,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_TEAMS,
  PERSONAS,
  LIFECYCLES,
  METRICS,
} from 'constants/common';
import { filterActiveItems, filterActiveUsers } from '../index';
import { getMetrics } from 'store/metrics/selectors';
import { hideArchived as hideArchivedRoadmaps } from 'store/roadmaps/helpers';
import { hideArchived as hideArchivedObjectives } from 'store/objectives/helpers';

// still using legacy selectors
// do not change this the boolean to pick only active data
// that must be done on a layer above
const SELECTOR_BY_KEY = {
  [ROADMAPS_CORP]: state => getCorpRoadmaps(state, true),
  [ROADMAPS]: state => getRoadmaps(state, true),
  [PRODUCTS]: state => getProducts(state, true),
  [PRODUCTS_2]: state => getProductsLevelTwo(state, true),
  [TIMEFRAMES]: state => getTimeframes(state, true),
  [TIMEFRAMES_2]: state => getTimeframesLevel2(state, true),
  [OBJECTIVES_CORP]: state => getObjectives(state, true, METADATA_LEVELS.LEVEL_CORP),
  [OBJECTIVES]: state => getObjectives(state, true),
  [KEY_RESULTS]: state => selectKeyResults1(state, true),
  [KEY_RESULTS_2]: state => selectKeyResults2(state, true),
  [THEMES]: state => getThemes(state, true),
  [CATEGORIES]: state => getCategories(state, true),
  [USERS]: state => getUsers(state, true),
  [CUSTOMERS]: state => getAllCustomers(state),
  [PHASES]: state => getPhases(state, true),
  [TAGS]: state => getAllTags(state),
  [TEAMS]: state => getTeams(state, true),
  [TEAMS_2]: state => getTeamsLevel2(state, true),
  [SKILLS]: state => getSkills(state, true),
  [PRIORITIES]: state => getPriorities(state, true),
  [PERSONAS]: state => selectPersonasData(state, true),
  [LIFECYCLES]: state => selectLifecyclesData(state, true),
  [METRICS]: state => getMetrics(state, true),
};

/**
 * @function getSelectorsByMetadataKey
 * Returns the selectors to be used by metadata key
 * @param {Object} options
 * @param {Array} options.keysToOmit omit these keys from the result
 * @param {Array} options.keysToPick  pick only these keys for the result
 * @returns {Object}
 */
const makeSelectorToMetadataByKey =
  ({ keysToOmit, keysToPick } = {}) =>
  state => {
    let selectors = SELECTOR_BY_KEY;

    if (keysToOmit) {
      selectors = omit(keysToOmit, SELECTOR_BY_KEY);
    } else if (keysToPick) {
      selectors = pick(keysToPick, SELECTOR_BY_KEY);
    }

    return keys(selectors).reduce((acc, eachKey) => ({ ...acc, [eachKey]: selectors[eachKey](state) }), {});
  };

const hideArchived = (items = [], filterMethod = filterActiveItems) =>
  items.filter(filterActiveItems).map(item => ({
    ...item,
    ...(item.children ? { children: hideArchived(item.children, filterMethod) } : {}),
  }));

/**
 * @function filterActiveMetadata - filters all the received metadata
 * excluding all the archived/inactive items
 * @param {Object} metadataByKey
 * @returns {Object}
 */
const filterActiveMetadata = metadataByKey => {
  return mapObjIndexed((values, key) => {
    switch (key) {
      case ROADMAPS_CORP:
      case 'roadmapsCorp':
      case ROADMAPS:
      case PRODUCTS:
      case 'products1':
      case PRODUCTS_2:
        return hideArchivedRoadmaps(values);
      case OBJECTIVES_CORP:
      case 'objectivesCorp':
      case OBJECTIVES:
      case KEY_RESULTS:
      case 'keyResults1':
      case KEY_RESULTS_2:
        return hideArchivedObjectives(values);
      case USERS:
        return hideArchived(values, filterActiveUsers);
      default:
        return hideArchived(values);
    }
  }, metadataByKey);
};

const getAdditionalRoadmapsPreferences = (hasProducts, hasProducts2, getSystemFieldName, systemFields, commonProps = {}) => [
  {
    key: ADDITIONAL_ROADMAPS,
    label: `Additional ${getSystemFieldName('roadmap', systemFields, true)}`,
    ...commonProps,
  },
  ...(hasProducts
    ? [
        {
          key: ADDITIONAL_PRODUCTS,
          label: `Additional ${getSystemFieldName('product1', systemFields, true)}`,
          ...commonProps,
        },
      ]
    : []),
  ...(hasProducts2
    ? [
        {
          key: ADDITIONAL_PRODUCTS_2,
          label: `Additional ${getSystemFieldName('product2', systemFields, true)}`,
          ...commonProps,
        },
      ]
    : []),
];

const getAdditionalObjectivesPreferences = (
  hasKeyResults,
  hasKeyResults2,
  getSystemFieldName,
  systemFields,
  commonProps = {},
) => [
  {
    key: ADDITIONAL_OBJECTIVES,
    label: `Additional ${getSystemFieldName('objective', systemFields, true)}`,
    ...commonProps,
  },
  ...(hasKeyResults
    ? [
        {
          key: ADDITIONAL_KEY_RESULTS,
          label: `Additional ${getSystemFieldName('keyResult1', systemFields, true)}`,
          ...commonProps,
        },
      ]
    : []),
  ...(hasKeyResults2
    ? [
        {
          key: ADDITIONAL_KEY_RESULTS_2,
          label: `Additional ${getSystemFieldName('keyResult2', systemFields, true)}`,
          ...commonProps,
        },
      ]
    : []),
];

const getAdditionalTimeframesPreferences = (
  hasMultiLevelPortfolioMetadata,
  getSystemFieldName,
  systemFields,
  commonProps = {},
) => [
  {
    key: ADDITIONAL_TIMEFRAMES,
    label: `Additional ${getSystemFieldName('timeframe', systemFields, true)}`,
    ...commonProps,
  },
  ...(hasMultiLevelPortfolioMetadata
    ? [
        {
          key: ADDITIONAL_TIMEFRAMES_2,
          label: `Additional ${getSystemFieldName('timeframe2', systemFields, true)}`,
          ...commonProps,
        },
      ]
    : []),
];

const getAdditionalCategoriesPreferences = (getSystemFieldName, systemFields, commonProps = {}) => [
  {
    key: ADDITIONAL_CATEGORIES,
    label: `Additional ${getSystemFieldName('category', systemFields, true)}`,
    ...commonProps,
  },
];

const getAdditionalThemesPreferences = (getSystemFieldName, systemFields, commonProps = {}) => [
  {
    key: ADDITIONAL_THEMES,
    label: `Additional ${getSystemFieldName('theme', systemFields, true)}`,
    ...commonProps,
  },
];

const getTeamsPreferences = (hasTeams2, getSystemFieldName, systemFields, commonProps = {}) => [
  {
    key: ADDITIONAL_TEAMS,
    label: `All ${getSystemFieldName('team', systemFields, true)}`,
    ...commonProps,
  },
  ...(hasTeams2
    ? [
        {
          key: ADDITIONAL_TEAMS_2,
          label: `All ${getSystemFieldName('team2', systemFields, true)}`,
          ...commonProps,
        },
      ]
    : []),
];

const getPersonasPreferences = (getSystemFieldName, systemFields, commonProps = {}) => [
  {
    key: PERSONAS,
    label: getSystemFieldName('persona', systemFields, true),
    ...commonProps,
  },
];

const getLifecyclesPreferences = (getSystemFieldName, systemFields, commonProps = {}) => [
  {
    key: LIFECYCLES,
    label: getSystemFieldName('lifecycle', systemFields, true),
    ...commonProps,
  },
];

export {
  makeSelectorToMetadataByKey,
  filterActiveMetadata,
  getAdditionalRoadmapsPreferences,
  getAdditionalObjectivesPreferences,
  getAdditionalTimeframesPreferences,
  getAdditionalCategoriesPreferences,
  getAdditionalThemesPreferences,
  getTeamsPreferences,
  getPersonasPreferences,
  getLifecyclesPreferences,
};
