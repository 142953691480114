import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'ramda';
import { spacing } from 'design-system/theme';


import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Description from '../../../components/Description';
import ConnectButton from '../../../components/ConnectButton';

import { INTEGRATIONS_NAMES } from 'constants/integrations';

const CreateOAuthClientCredentials = ({ onClickConnect, uri }) => {
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  return (
    <Wrapper>
      <Grid container>
        <InputRow item xs={12}>
          <Description>
            You need an OAuth2 <em>Client ID</em> and <em>Secret Key</em> to connect dragonboat with {INTEGRATIONS_NAMES.rally}
            .&nbsp; Create a new Rally OAuth2 Client&nbsp;
            <a
              id="get-rally-oauth-settings-link"
              href={`${uri}/#/oauth_clients`}
              target="_blank"
              rel="noreferrer noopener"
            >
              here
            </a>
            &nbsp;and enter the OAuth Client Name and Callback URL as shown below.
          </Description>
        </InputRow>
        <InputRow item xs={5}>
          <RallyOAuthClientValue
            name="client_name"
            label="OAuth Client Name"
            fullWidth
            value="Dragonboat"
            inputProps={{
              onClick: e => e.target.select(),
            }}
          />
          <RallyOAuthClientValue
            name="callback_url"
            label="Callback URL"
            fullWidth
            value={`${window.location.origin}/rally/callback`}
            inputProps={{
              onClick: e => e.target.select(),
            }}
          />
        </InputRow>
        <InputRow item xs={10}>
          <CredentialsInput
            id="rally-client-id"
            label="Client ID"
            size="small"
            value={clientId}
            onChange={e => setClientId(e.target.value)}
            required
            fullWidth
          />
        </InputRow>
        <InputRow item xs={10}>
          <CredentialsInput
            id="rally-client-secret"
            label="Secret Key"
            size="small"
            value={clientSecret}
            onChange={e => setClientSecret(e.target.value)}
            fullWidth
            required
            type="password"
            autoComplete="off"
            inputProps={{
              form: {
                autoComplete: 'off',
              },
            }}
          />
        </InputRow>

        <InputRow item xs={12}>
          <ConnectButton
            variant="contained"
            disabled={isEmpty(clientId) || isEmpty(clientSecret)}
            color="primary"
            onClick={() => onClickConnect(clientId, clientSecret)}
          >
            Connect
          </ConnectButton>
        </InputRow>
      </Grid>
    </Wrapper>
  );
};

CreateOAuthClientCredentials.propTypes = {
  onClickConnect: PropTypes.func.isRequired,
  uri: PropTypes.string.isRequired,
};

const CredentialsInput = styled(TextField)``;
const RallyOAuthClientValue = styled(TextField)``;
const InputRow = styled(Grid)``;

const Wrapper = styled.form`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${spacing(2.5)}px;

  ${RallyOAuthClientValue} {
    margin-top: ${spacing(2)}px;
    input {
      font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
    }
  }

  ${InputRow} {
    margin-left: ${spacing(2)}px;
    margin-right: ${spacing(2)}px;
    min-width: 400px;
    margin-top: ${spacing(2.5)}px;
  }
`;


export default CreateOAuthClientCredentials;
