import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_PIE_CHART,
  FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_STACKED_CHART,
  UPDATE_CUSTOMER_REQUESTS_PERSONAS,
  UPDATE_CUSTOMER_REQUESTS_LIFECYCLES,
  FETCH_CUSTOMER_REQUESTS,
  MERGE_CUSTOMER_REQUESTS,
} from '../types';

const { initialState, reducers } = getThunksInitialStateAndReducers([
  FETCH_CUSTOMER_REQUESTS,
  FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_PIE_CHART,
  FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_STACKED_CHART,
  UPDATE_CUSTOMER_REQUESTS_PERSONAS,
  UPDATE_CUSTOMER_REQUESTS_LIFECYCLES,
  MERGE_CUSTOMER_REQUESTS,
]);

export { initialState };

export default reducers;
