import moment from 'moment-timezone';

export default (value, isEndDate) => {
  if (value && moment(value).isValid()) {
    const baseDate = moment.tz(value, 'UTC').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    if (isEndDate) baseDate.add(1, 'day');
    const date = baseDate.toDate();
    const timezoneOffset = date.getTimezoneOffset();
    const userTimezoneOffset = timezoneOffset * 60000;

    return new Date(date.getTime() + userTimezoneOffset);
  }

  return '';
};
