import React from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { getDisplayLayer } from 'store/filters/selectors';
import { getAllBets, getAllInitiatives } from 'store/projects/selectors';
import { getTimeframes } from 'store/timeframes/selectors';
import { getProducts, getRoadmaps } from 'store/roadmaps/selectors';
import { selectKeyResults1, getObjectives } from 'store/objectives/selectors';
import { getThemes } from 'store/themes/selectors';
import { getCategories } from 'store/categories/selectors';
import { getUsers } from 'store/users/selectors';
import { getCustomersFilteredByStatus } from 'store/customers/selectors';
import { getPhases } from 'store/phases/selectors';
import { getTags } from 'store/tags/selectors';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import { getPriorities } from 'store/priorities/selectors';
import useDeepEffect from 'hooks/useDeepEffect';
import { planningStages, statusColors } from 'utils';

export const MAP_PROJECT_METADATA = {
  roadmap: 'roadmaps',
  timeframe: 'timeframes',
  key_result_1: 'keyResults',
  product_1: 'products',
  objective: 'objectives',
  theme: 'themes',
  category: 'categorys',
  owner: 'users',
  customers: 'customers',
  phase: 'phases',
  tags: 'tags',
  bet: 'bets',
  initiative: 'initiatives',
  priority: 'priorities',
  dates: 'dates',
  planningStage: 'stages',
  status_color: 'statusColors',
};

export default (projects, options) => {
  const [tableData, setTableData] = React.useState({});

  const lsState = useSelector(state => state.portfolioReport);
  const displayLayer = useSelector(state => getDisplayLayer(state));
  const hasHierarchy = useSelector(state => state.organization.organization.has_hierarchy);
  const hasBet = useSelector(state => state.organization.organization.has_bet);
  const metadataFromStore = useSelector(
    state => ({
      roadmaps: getStateDataForPage(state, getRoadmaps, 'roadmaps'),
      timeframes: getStateDataForPage(state, getTimeframes, 'timeframes'),
      keyResults: getStateDataForPage(state, selectKeyResults1, 'keyResults'),
      products: getStateDataForPage(state, getProducts, 'products'),
      objectives: getStateDataForPage(state, getObjectives, 'objectives'),
      themes: getStateDataForPage(state, getThemes, 'themes'),
      categorys: getStateDataForPage(state, getCategories, 'categories'),
      users: getStateDataForPage(state, getUsers, 'users'),
      customers: getStateDataForPage(state, getCustomersFilteredByStatus, 'customers'),
      phases: getStateDataForPage(state, getPhases, 'phases'),
      tags: getStateDataForPage(state, getTags, 'tags'),
      priorities: getPriorities(state),
      ...(hasHierarchy ? { initiatives: getAllInitiatives(state) } : {}),
      ...(hasBet ? { bets: getAllBets(state) } : {}),
    }),
    isEqual,
  );

  const metadata = React.useMemo(
    () => ({
      ...metadataFromStore,
      stages: planningStages.reverse().map((stage, index) => ({
        id: stage,
        title: stage,
        row_order: index,
      })),
      statusColors: statusColors.map(c => ({
        id: c.label,
        title: c.label,
      })),
    }),
    [metadataFromStore],
  );

  const _generateDataForTable = () => {
    const groups = [
      ...(lsState.group1 ? [lsState.group1] : []),
      ...(lsState.group2 ? [lsState.group2] : []),
      ...(lsState.group3 ? [lsState.group3] : []),
    ];

    const mapData = MAP_PROJECT_METADATA;
    const groupsDataKeys = groups.map(group => (mapData[group.key] ? mapData[group.key] : []));

    const notDefined = { title: 'Not Defined', id: null, color: null };
    const groupsData = groupsDataKeys.map(groupDataKey => [...metadata[groupDataKey], notDefined]);
    const _mapToTableCell = (obj, childrenCount) => ({
      id: obj.id,
      title: obj.title,
      childrenCount,
    });
    const _getProjectGroupField = group => {
      if (['initiative', 'bet'].includes(group.key)) return 'parent_id';
      else if (['planningStage', 'status_color'].includes(group.key)) {
        return group.key;
      }

      return `${group.key}_id`;
    };

    let dataOnTable = [];
    const addedGroups = {};

    const _generateTableRow = project => {
      const entryToTable = [];

      for (let index = 0; index < groups.length; index++) {
        const curGroup = groups[index];
        const curGroupData = groupsData[index];
        const group = curGroupData.find(g => g.id === project[_getProjectGroupField(curGroup)]);

        if (!group) return;

        const ideasOnGroup = projects.filter(p => {
          return groups.slice(0, index + 1).every(g => p[_getProjectGroupField(g)] === project[_getProjectGroupField(g)]);
        });
        const entryGroups = groups
          .slice(0, index + 1)
          .map((g, i) => project[_getProjectGroupField(g)] || 'null')
          .join('-');
        const groupAdded = !!addedGroups[entryGroups];

        entryToTable.push(!groupAdded ? _mapToTableCell(group, ideasOnGroup.length) : '');

        addedGroups[
          groups
            .slice(0, index + 1)
            .map((g, i) => project[_getProjectGroupField(g)] || 'null')
            .join('-')
        ] = true;
      }

      entryToTable.push(_mapToTableCell(project));

      return entryToTable;
    };
    const _generateGroupTree = (data, level = 0, parentGroup) => {
      if (level < groups.length - 1) {
        return data.reduce((acc, cur) => {
          const group = { ...cur, parentGroup };

          return [
            ...acc,
            {
              group,
              children: _generateGroupTree(groupsData[level + 1], level + 1, group),
            },
          ];
        }, []);
      }

      // last level
      return data.reduce((acc, cur) => {
        const group = { ...cur, parentGroup };
        const _getProjectFilters = (g, l, filters = {}) => {
          const groupKey = groups[l].key;

          if (g.parentGroup) {
            return { ..._getProjectFilters(g.parentGroup, l - 1, filters), [groupKey]: g };
          }

          return { [groupKey]: g };
        };
        const ideas = projects.filter(p => {
          const projectFilters = _getProjectFilters(group, level);

          return Object.keys(projectFilters).every(f => p[_getProjectGroupField({ key: f })] === projectFilters[f].id);
        });

        ideas.forEach(project => {
          const row = _generateTableRow(project);

          if (row) dataOnTable.push(row);
        });

        if (!ideas.length) return acc;

        return [
          ...acc,
          {
            group,
            children: ideas,
          },
        ];
      }, []);
    };

    if (groupsData.length) _generateGroupTree(groupsData[0]);
    else dataOnTable = projects.map(p => [p]);

    setTableData({
      groups,
      rows: dataOnTable,
    });
  };

  useDeepEffect(
    changes => {
      if (!projects) return;

      _generateDataForTable();
    },
    [
      projects,
      metadata,
      lsState,
      // pageFilters,
      displayLayer,
    ],
  );

  return [tableData];
};
