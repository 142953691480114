import React from 'react';
import moment from 'moment-timezone';

import { materialColors } from 'design-system/themes/default';

import formatDate from 'utils/dates/formatDate';
import { IDEA_LAYER } from 'store/projects/constants';

/**
 * Create the layer element that shows the given predicted end date
 *
 * @param {Object} ganttTask - The gantt task that we are working with, the data from the project
 * @param {String} predictedEnd - The predicted end date as string
 * @param {String} projectDeadline - The set project deadline
 * @param {Number} progress - The reported project progress
 *
 * @returns {Node} the dom element to be rendered
 */
const createPredictedEndDateLayerElement = function (gantt, task, predictedEnd, projectDeadline, progress) {
  const el = document.createElement('div');
  const predictedEndDate = moment(predictedEnd);
  const daysBetweenPredictedPlanned = predictedEndDate.diffDuration(moment(projectDeadline), 'days');
  let color = materialColors.red;

  // if less than 5 days, show yellow color (like yellow in health) more than or equal to 5 days show red color
  // otherwise show green like health color and if 100% done, show blue like the health color
  if (daysBetweenPredictedPlanned <= 0) {
    color = materialColors.green;
  } else if (daysBetweenPredictedPlanned <= 5) {
    color = materialColors.yellow;
  } else if (progress === 1) {
    color = materialColors.blue;
  }

  el.className = 'deadline';
  const sizes = gantt.getTaskPosition(task, predictedEndDate);

  el.style.left = `${sizes.left}px`;
  el.style.top = `${sizes.top}px`;
  el.style.borderLeft = `2px solid ${color}`;
  el.style.borderRight = `2px solid ${color}`;

  el.setAttribute('title', `Predicted end date: ${formatDate(predictedEndDate)}`);
  return el;
};

const setGanttPredictedEndDateDisplay = (displayStoryProgEndDate, showStories, orgIntegrations) => {
  const displayStoryProgEndDateRef = React.useRef(displayStoryProgEndDate);
  const showStoriesRef = React.useRef(showStories);

  displayStoryProgEndDateRef.current = displayStoryProgEndDate;
  showStoriesRef.current = showStories;

  return gantt => {
    gantt.addTaskLayer({
      renderer: {
        render: task => {
          const { layer, integrationProgress, deadline, progress, integrations, jira } = task;

          if (displayStoryProgEndDateRef.current && layer !== IDEA_LAYER && task.predictedEndDate) {
            // If not idea do not validate if we are showing stories or not

            return createPredictedEndDateLayerElement(gantt, task, task.predictedEndDate, deadline, progress);
          }

          // Validate if we are showing the stories predicted end date before showing our predicted end date marker
          // as showing stories already adds a predicted end date marker

          const intEndDate = integrationProgress && integrationProgress.endDate;
          const hasStories = integrationProgress && integrationProgress.issuesTotal > 0;

          if (!jira && (!integrations || !integrations.length)) return;

          const showingJiraStories = jira && showStoriesRef.current && showStoriesRef.current.jira;

          // if is showing jira stories should not show predicted end date
          if (jira && showingJiraStories) return;

          const orgIntegration = orgIntegrations.find(
            oi => oi.id === (integrations && integrations.length > 0 && integrations[0].org_integration_id),
          );
          const showingProjectIntegrationStories =
            !showingJiraStories &&
            orgIntegration &&
            showStoriesRef.current &&
            showStoriesRef.current[orgIntegration.integrationType];

          // if is showing project integration stories should not show predicted end date
          if (showingProjectIntegrationStories) return;

          if (intEndDate && displayStoryProgEndDateRef.current && hasStories) {
            return createPredictedEndDateLayerElement(gantt, task, intEndDate, deadline, progress);
          }
          return false;
        },
        // define getRectangle in order to hook layer with the smart rendering
        getRectangle: (task, view) => {
          const { integrationProgress } = task;
          const intEndDate = integrationProgress && integrationProgress.endDate;
          const hasStories = integrationProgress && integrationProgress.issuesTotal > 0;

          if (intEndDate && displayStoryProgEndDateRef.current && hasStories) {
            return gantt.getTaskPosition(task, moment(intEndDate));
          }
          return null;
        },
      },
    });

    // ================
    // GANTT WINDOW LIMITS
    // if the predicted end date is far to the right, the gantt should extend its window to the right
    // gantt.attachEvent('onParse', () => {
    //   if (displayStoryProgEndDateRef.current) {
    //     gantt.eachTask(task => {
    //       const { integrationProgress } = task;
    //       const intEndDate = integrationProgress && integrationProgress.endDate;
    //       const hasStories = integrationProgress && integrationProgress.issuesTotal > 0;

    //       if (intEndDate && hasStories) {
    //         const deadline = moment(intEndDate);
    //         const state = gantt.getState();

    //         // if (state.min_date > deadline) {
    //         //   gantt.config.start_date = subtractWeeks(moment(deadline), 2);
    //         //   gantt.render();
    //         // }
    //         // if (state.max_date < deadline) {
    //         //   gantt.config.end_date = addWeeks(moment(deadline), 2);
    //         //   gantt.render();
    //         // }
    //       }
    //     });
    //   }
    // });
  };
};

export default setGanttPredictedEndDateDisplay;
