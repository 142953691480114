import { useMemo } from 'react';

import { INTEGRATIONS_KEYS } from 'constants/integrations';
import useSystemFields from 'hooks/useSystemFields';
import useNumberOfTextRowsToDisplayOnGridCell from 'hooks/useNumberOfTextRowsToDisplayOnGridCell';
import { returnsTrueIfKeyIsNotEscOrTab, updateVisibleColumns } from 'design-system/molecules/AgGridReact-New/helpers';
import {
  healthColumnDef,
  getOwnerColumnDef,
  planningStageColumnDef,
  defaultColumnDefCommonProps,
} from 'design-system/molecules/AgGridReact-New/columns';
import { numberComparator, stringComparator, multiSelectComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import {
  HierarchyCellRenderer,
  PercentageCellRenderer,
  TextCellRenderer,
  WeeksCellRenderer,
  ParentCellRenderer,
  DateCellRenderer,
  GroupCellRenderer,
  IntegrationKeyCellRenderer,
  WYSIWYGCellRenderer,
  ChipsCellRenderer,
  BubbleNumericCellRenderer,
} from 'design-system/molecules/AgGridReact-New/cellRenderers';

import {
  formatIssueType,
  generateTitleHeaderName,
  generateCustomFields,
  generateAutocompleteFields,
  generateNumericFields,
  generateTextFields,
} from '../helpers';
import { dateColumnDef, numericColumnDef } from 'design-system/molecules/AgGridReact-New/columns/index';

const PROJECT_ROADMAP_FIELD = 'roadmapTitle';

const ROADMAP_KEY = 'roadmap';
const PROGRESS_KEY = 'progress';
const HEALTH_KEY = 'status_color';
const PRODUCT_LEVEL1_KEY = 'product1';
const PRODUCT_LEVEL2_KEY = 'product2';
const KEY_RESULT_LEVEL1_KEY = 'keyResult1';
const KEY_RESULT_LEVEL2_KEY = 'keyResult2';
const OBJECTIVE_KEY = 'objective';

const useProjectsGridColumns = ({
  hasBet,
  systemFields,
  hasProducts2,
  hasKeyResults2,
  customFields,
  integrations,
  visibleColumns,
  renderHierarchicalTitleLabel,
  renderHierarchicalTitleIcon,
  showChildrenCount,
  columnsDefsOverride = {},
  extraColumnDefs = [],
  hasGroupBy = false,
  hasTotalEstimates = true,
}) => {
  const [getSystemFieldName] = useSystemFields();
  const { getNumberOfTextRowsToDisplayOnGridCell } = useNumberOfTextRowsToDisplayOnGridCell();

  const defaultColDef = {
    ...defaultColumnDefCommonProps,
    cellClass: params =>
      params.colDef.autoHeight ? 'ag-react-container--expandable' : 'ag-react-editable-container--expandable',
    sortable: true,
    resizable: true,
    filter: true,
  };

  const autoGroupColumnDef = useMemo(
    () =>
      columnsDefsOverride.title ?? {
        field: 'title',
        width: 400,
        headerName: generateTitleHeaderName(hasBet, renderHierarchicalTitleLabel),
        pinned: 'left',
        lockPinned: true,
        cellRendererParams: {
          autoHeight: true,
          suppressCount: true,
          cellClass: 'group-cell',
          innerRenderer: HierarchyCellRenderer,
          renderHierarchicalTitleIcon,
          showChildrenCount,
        },
      },
    [hasBet, renderHierarchicalTitleIcon, renderHierarchicalTitleLabel, showChildrenCount],
  );

  const ownerReadOnlyColumnDef = getOwnerColumnDef({
    editable: false,
    field: 'ownerName',
    width: 120,
    minWidth: 120,
  });

  const roadmapReadOnlyColumnDef = {
    editable: false,
    field: PROJECT_ROADMAP_FIELD,
    headerName: getSystemFieldName(ROADMAP_KEY),
    cellRenderer: GroupCellRenderer(getSystemFieldName(ROADMAP_KEY)),
    width: 120,
    minWidth: 120,
  };

  const progressReadOnlyColumnDef = {
    field: 'progress',
    headerName: getSystemFieldName(PROGRESS_KEY),
    cellRenderer: PercentageCellRenderer,
    cellClassRules: {
      'over-limit': node => node.value > 1,
    },
    width: 150,
  };

  const healthReadOnlyColumnDef = {
    ...healthColumnDef,
    headerName: getSystemFieldName(HEALTH_KEY),
    width: 110,
    minWidth: 110,
  };

  const integrationKeyColumnDefs = [integrations.length > 0].filter(Boolean).map(() => ({
    width: 150,
    headerName: 'Integration Key',
    field: 'integration',
    filterParams: {
      values: ['(Blanks)', 'Has Integration'],
    },
    orgIntegrations: integrations,
    keyCreator: ({ value }) => (value ? 'Has Integration' : '(Blanks)'),
    cellRenderer: IntegrationKeyCellRenderer,
  }));

  const integrationIssueTypeHeaderFormatted = useMemo(() => {
    const hasJira = Boolean((integrations || []).find(integration => integration.integrationType === INTEGRATIONS_KEYS.JIRA));

    if (hasJira) {
      return 'Jira Issue Type';
    }

    return 'Integration Issue Type';
  }, [integrations]);

  const integrationIssueTypeColumnDefs = [integrations.length > 0].filter(Boolean).map(() => ({
    field: 'integrationIssueType',
    headerName: integrationIssueTypeHeaderFormatted,
    cellClass: 'text-center',
    valueFormatter: formatIssueType,
    cellRenderer: TextCellRenderer,
    enableValue: true,
  }));

  const autocompleteColumnDefs = generateAutocompleteFields(systemFields);
  const numericColumnDefs = generateNumericFields();
  const textColumnDefs = generateTextFields();
  const customColumnDefs = generateCustomFields(customFields);

  const tagsColumnDef = {
    field: 'tags',
    headerName: getSystemFieldName('tag'),
    width: 120,
    labelField: 'title',
    comparator: multiSelectComparator,
    keyCreator: ({ value }) => (value?.length ? value.map(c => c.title) : [null]),
    cellRenderer: ChipsCellRenderer,
    cellRendererParams: {
      getNumberOfTextRowsToDisplayOnGridCell,
    },
  };

  const customersColumnDef = {
    field: 'customers',
    headerName: getSystemFieldName('customer'),
    width: 120,
    labelField: 'name',
    comparator: multiSelectComparator,
    keyCreator: ({ value }) => (value?.length ? value.map(c => c.title) : [null]),
    cellRenderer: ChipsCellRenderer,
    cellRendererParams: {
      getNumberOfTextRowsToDisplayOnGridCell,
    },
  };

  const statusSummaryColumnDef = {
    headerName: 'Status Summary',
    field: 'status_summary',
    width: 300,
    cellRenderer: WYSIWYGCellRenderer,
    suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
    comparator: stringComparator,
  };

  const predictedEndDateColumnDef = {
    ...dateColumnDef,
    field: 'predictedEndDate',
    headerName: 'Predicted end date',
    width: 100,
    cellRenderer: DateCellRenderer,
  };

  const deadlineColumnDef = columnsDefsOverride?.deadline ?? {
    ...dateColumnDef,
    field: 'deadline',
    headerName: 'Target end date',
    width: 100,
    cellRenderer: DateCellRenderer,
  };

  const estimatedStartDateColumnDef = {
    ...dateColumnDef,
    field: 'estimated_start_date',
    headerName: 'Target start date',
    width: 100,
    cellRenderer: DateCellRenderer,
  };

  const linksSummaryColumnDef = {
    headerName: 'Links',
    field: 'links',
    width: 400,
    cellRenderer: WYSIWYGCellRenderer,
    suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
    comparator: stringComparator,
  };

  const detailsSummaryColumnDef = {
    headerName: 'Details',
    field: 'details',
    width: 300,
    cellRenderer: WYSIWYGCellRenderer,
    suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
    comparator: stringComparator,
  };

  const moarColumnDef = {
    ...numericColumnDef,
    headerName: 'Planned MoAR',
    suffix: '%',
    field: 'planned_moar',
    width: 100,
    cellRenderer: BubbleNumericCellRenderer,
  };

  const riceScoreColumnDef = {
    ...numericColumnDef,
    headerName: 'RICE Score',
    field: 'riceScore',
    width: 100,
    cellRenderer: BubbleNumericCellRenderer,
  };

  const parentColumnDef = columnsDefsOverride.parent ?? {
    headerName: `${getSystemFieldName('idea')} Parent`,
    field: 'parent',
    width: 240,
    cellRenderer: ParentCellRenderer,
  };

  const objectiveColumnDef = {
    headerName: getSystemFieldName(OBJECTIVE_KEY),
    field: 'objectiveTitle',
    width: 120,
    cellRenderer: GroupCellRenderer(getSystemFieldName(OBJECTIVE_KEY)),
  };

  const keyResultLevel1ColumnDef = {
    headerName: getSystemFieldName(KEY_RESULT_LEVEL1_KEY),
    field: 'keyResult1Title',
    width: 120,
    cellRenderer: GroupCellRenderer(getSystemFieldName(KEY_RESULT_LEVEL1_KEY)),
  };

  const keyResultsColumnDefs = [keyResultLevel1ColumnDef];

  if (hasKeyResults2) {
    const keyResultLevel2ColumnDef = {
      headerName: getSystemFieldName(KEY_RESULT_LEVEL2_KEY),
      field: 'keyResult2Title',
      width: 120,
      cellRenderer: GroupCellRenderer(getSystemFieldName(KEY_RESULT_LEVEL2_KEY)),
    };

    keyResultsColumnDefs.push(keyResultLevel2ColumnDef);
  }

  const productLevel1ColumnDef = {
    headerName: getSystemFieldName(PRODUCT_LEVEL1_KEY),
    field: 'product1Title',
    width: 120,
    cellRenderer: GroupCellRenderer(getSystemFieldName(PRODUCT_LEVEL1_KEY)),
  };

  const productsColumnDefs = [productLevel1ColumnDef];

  if (hasProducts2) {
    const productLevel2ColumnDef = {
      headerName: getSystemFieldName(PRODUCT_LEVEL2_KEY),
      field: 'product2Title',
      width: 120,
      cellRenderer: GroupCellRenderer(getSystemFieldName(PRODUCT_LEVEL2_KEY)),
    };

    productsColumnDefs.push(productLevel2ColumnDef);
  }

  const totalEstimateColumnDef = {
    ...numericColumnDef,
    field: 'sum',
    headerName: 'Total Estimate',
    comparator: numberComparator,
    width: 80,
    aggFunc: 'sumNumericFields',
    cellRenderer: WeeksCellRenderer,
    enableValue: true,
  };

  return useMemo(() => {
    const initialColumnDefs = [
      ...(hasGroupBy ? [] : [autoGroupColumnDef]),
      healthReadOnlyColumnDef,
      roadmapReadOnlyColumnDef,
      progressReadOnlyColumnDef,
      ownerReadOnlyColumnDef,
      ...integrationKeyColumnDefs,
      ...integrationIssueTypeColumnDefs,
      ...autocompleteColumnDefs,
      ...numericColumnDefs,
      ...textColumnDefs,
      ...customColumnDefs,
      tagsColumnDef,
      customersColumnDef,
      planningStageColumnDef,
      statusSummaryColumnDef,
      predictedEndDateColumnDef,
      deadlineColumnDef,
      estimatedStartDateColumnDef,
      linksSummaryColumnDef,
      detailsSummaryColumnDef,
      moarColumnDef,
      riceScoreColumnDef,
      parentColumnDef,
      objectiveColumnDef,
      ...keyResultsColumnDefs,
      ...productsColumnDefs,
      ...(hasTotalEstimates ? [totalEstimateColumnDef] : []),
      ...extraColumnDefs,
    ];

    const columnsDefsWithVisibility = updateVisibleColumns(initialColumnDefs, visibleColumns, customFields);

    return {
      columnDefs: columnsDefsWithVisibility,
      defaultColDef,
      autoGroupColumnDef,
    };
  }, [visibleColumns, extraColumnDefs]);
};

export default useProjectsGridColumns;
