import { ROADMAP_LEVEL } from 'constants/roadmaps';

const ROADMAP_KEY = 'Roadmap';

const computeParentId = roadmapOrProduct => {
  return roadmapOrProduct.parent_id != null ? roadmapOrProduct.parent_id : `${ROADMAP_KEY}-${roadmapOrProduct.roadmap_id}`;
};

const processRoadmapsToMetadataTreeDropdownItems = (roadmapsOrProducts = []) => {
  return roadmapsOrProducts.reduce((acc, roadmapOrProduct) => {
    const isRoadmap = roadmapOrProduct.level === ROADMAP_LEVEL;

    const roadmapOrProductUniqueId = isRoadmap ? `${ROADMAP_KEY}-${roadmapOrProduct.id}` : roadmapOrProduct.id;

    const uniqueParentId = isRoadmap ? null : computeParentId(roadmapOrProduct);

    const processedRoadmapOrProduct = {
      data: roadmapOrProduct,
      parent_id: uniqueParentId,
      id: roadmapOrProductUniqueId,
      title: roadmapOrProduct.title,
    };

    return [...acc, processedRoadmapOrProduct, ...processRoadmapsToMetadataTreeDropdownItems(roadmapOrProduct.products)];
  }, []);
};

const makeGetItemsForProductsMetadataTreeDropdownCellEditor = (filteredRoadmaps, selectedLevel) => params => {
  const dataRoadmapId = params.data.roadmap?.id;

  const dataProduct1Id = params.data.product1?.id;

  const dataRoadmap = dataRoadmapId ? filteredRoadmaps.find(r => r.id === dataRoadmapId) : null;

  const dataProduct1 = dataRoadmap && dataProduct1Id ? dataRoadmap?.products?.find(r => r.id === dataProduct1Id) : null;

  // For cases where an L1 is selected
  const roadmapsToProcess = dataRoadmap ? [dataRoadmap] : filteredRoadmaps;

  const processedRoadmapsAndProducts = processRoadmapsToMetadataTreeDropdownItems(
    dataProduct1 && selectedLevel > dataProduct1.level
      ? [
          {
            ...dataRoadmap,
            products: [dataProduct1],
          },
        ]
      : roadmapsToProcess,
  );

  return processedRoadmapsAndProducts.filter(r => r.data.level <= selectedLevel);
};

export { makeGetItemsForProductsMetadataTreeDropdownCellEditor };
