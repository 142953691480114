import { useSelector } from 'react-redux';

import { getTimeframes } from 'store/timeframes/selectors';
import getStateDataForPage from 'store/utils/getStateDataForPage';

const timeframeMarkers = [];

/**
 * Custom hook to set the Timeframes markers on the gantt
 *
 * @returns [updateMarkers]
 */
const useDisplayTimeframeMarkers = () => {
  const timeframes = useSelector(state => getStateDataForPage(state, getTimeframes, 'timeframes'));
  // const times = timeframes.filter(t => !!t.date).map(t => new Date(t.date));
  // const timeframesTimespan = itemsTimespan(times);

  const updateMarkers = (gantt, showTimeframes) => {
    const displayTimeframes = () => {
      timeframes.forEach(timeframe => {
        if (!gantt.getMarker(timeframe.id)) {
          timeframeMarkers.push(
            gantt.addMarker({
              id: timeframe.id,
              start_date: new Date(timeframe.date),
              css: 'marker_line_timeframe',
              text: timeframe.title,
              title: timeframe.title,
            }),
          );
        }
      });
    };
    const hideTimeframes = () => timeframeMarkers.forEach(m => gantt.deleteMarker(m));

    if (gantt) {
      if (showTimeframes) {
        displayTimeframes();
        // const state = gantt.getState();

        // if (timeframesTimespan && timeframesTimespan.startDate && state.min_date > timeframesTimespan.startDate) {
        //   gantt.config.start_date = addWeeks(moment(timeframesTimespan.startDate), 2);
        // }
        // if (timeframesTimespan && timeframesTimespan.endDate && state.max_date < timeframesTimespan.endDate) {
        //   gantt.config.end_date = subtractWeeks(moment(timeframesTimespan.endDate), 2);
        // }
      } else if (!showTimeframes) hideTimeframes();
    }
  };

  return [updateMarkers];
};

export default useDisplayTimeframeMarkers;
