import React, { Component, Fragment } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import { injectStripe } from 'react-stripe-elements';

import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import styled from 'styled-components';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';

import { materialColors, linkColor } from 'design-system/themes/default';
import { spacing } from 'design-system/theme';

import { PLANS_BY_TYPE, COMPLETE_PLAN, CUSTOM_PLAN } from './billingUtils';

const querystringToPlan = {
  1: COMPLETE_PLAN,
  2: CUSTOM_PLAN,
};

const MIN_EDITORS = 3;

const PLANS_TO_SHOW_BILLING_FORM = []; // Current business strategy is to not show billing form for any plan
// trial accounts will always contact sales

const showBillingForm = plan => PLANS_TO_SHOW_BILLING_FORM.includes(plan);

const SALES_TEAM_CONTACT = 'sales@dragonboat.io';

const getPlayTypeMappings = prices => ({
  complete: [{ value: prices['complete-monthly'] }, { value: prices['complete-yearly'] }],
});

const minOrHigher = value => (value >= MIN_EDITORS ? value : MIN_EDITORS);

class CheckoutForm extends Component {
  constructor(props) {
    super(props);

    const { product } = queryString.parse(window.location.search);

    this.state = {
      planType: querystringToPlan[product] || COMPLETE_PLAN,
      paymentType: 0,
      editors: 5,
    };
  }

  parsePlanTypeFromProps = () => {
    const mappings = {
      'complete-yearly': { planType: COMPLETE_PLAN, paymentType: 0 },
      'complete-monthly': { planType: COMPLETE_PLAN, paymentType: 1 },
    };

    if (this.props.billingInfo && Object.keys(mappings).indexOf(this.props.billingInfo.plan_type) !== -1) {
      return mappings[this.props.billingInfo.plan_type];
    }
    return { planType: COMPLETE_PLAN, paymentType: 0 };
  };

  componentDidUpdate = prevProps => {
    if (
      (this.props.billingInfo &&
        prevProps.billingInfo &&
        JSON.stringify(this.props.billingInfo) !== JSON.stringify(prevProps.billingInfo)) ||
      (this.props.billingInfo && !prevProps.billingInfo)
    ) {
      this.setState({
        ...this.parsePlanTypeFromProps(),
        editors: this.props.billingInfo.num_seats || this.state.editors,
      });
    }
  };

  checkout = async () => {
    const res = await axios.post('/api/billing/session', {
      plan_type: this.state.planType,
      editors: this.state.editors,
      payment_type: this.state.paymentType,
    });

    await this.props.stripe.redirectToCheckout({
      sessionId: res.data.id,
    });
  };

  areFieldsDisabled = () => {
    return this.props.billingInfo && this.props.billingInfo.billing_status === 'paid';
  };

  setEditors = () => {};

  render = () => {
    const { prices } = this.props;

    const planTypeMappings = getPlayTypeMappings(prices);

    return (
      <Wrapper>
        <FieldsWrapper>
          {!!PLANS_TO_SHOW_BILLING_FORM.length && (
            <PlanTypeFormControl>
              <InputLabel htmlFor="plan-type">Product Type</InputLabel>
              <Select
                disabled={this.areFieldsDisabled()}
                value={this.state.planType}
                onChange={e => this.setState({ planType: e.target.value })}
                inputProps={{
                  name: 'planType',
                  id: 'plan-type',
                }}
              >
                <StyledMenuItemDropdown value={COMPLETE_PLAN}>{PLANS_BY_TYPE[COMPLETE_PLAN]}</StyledMenuItemDropdown>
                <StyledMenuItemDropdown value={CUSTOM_PLAN}>{PLANS_BY_TYPE[CUSTOM_PLAN]}</StyledMenuItemDropdown>
              </Select>
            </PlanTypeFormControl>
          )}
          {showBillingForm(this.state.planType) && (
            <>
              <TextField
                name="duration"
                label="Number of editors"
                type="number"
                disabled={this.areFieldsDisabled()}
                value={this.state.editors}
                onChange={e => this.setState({ editors: minOrHigher(e?.target?.value || 0) })}
                InputProps={{ min: 1 }}
                // className={`custom-light-box-number-field ${this.props.durationClass}`}
              />
              <PlanSelector>
                <PlanPriceButton
                  selected={this.state.paymentType === 0}
                  disabled={this.areFieldsDisabled()}
                  onClick={() => this.setState({ paymentType: 0 })}
                >
                  <PlanPriceWrapper>
                    <PlanPriceType selected={this.state.paymentType === 0}>Annually</PlanPriceType>
                    <PlanPriceDescription selected={this.state.paymentType === 0}>
                      ${planTypeMappings[this.state.planType][1].value} /year /editor
                    </PlanPriceDescription>
                  </PlanPriceWrapper>
                </PlanPriceButton>
                <PlanPriceButton
                  selected={this.state.paymentType === 1}
                  disabled={this.areFieldsDisabled()}
                  onClick={() => this.setState({ paymentType: 1 })}
                >
                  <PlanPriceWrapper>
                    <PlanPriceType selected={this.state.paymentType === 1}>Monthly</PlanPriceType>
                    <PlanPriceDescription selected={this.state.paymentType === 1}>
                      ${planTypeMappings[this.state.planType][0].value} /month /editor
                    </PlanPriceDescription>
                  </PlanPriceWrapper>
                </PlanPriceButton>
              </PlanSelector>
              {!this.areFieldsDisabled() && (
                <Fragment>
                  <PaymentButton disabled={this.state.editors < 1} color="primary" onClick={this.checkout}>
                    Pay Now
                  </PaymentButton>
                  <PaymentStripeInfo>
                    {'You will be redirected to Stripe for secure payment. ' +
                      'At the end of payment on Stripe, you will be redirected back to dragonboat.'}
                  </PaymentStripeInfo>
                </Fragment>
              )}

              <ContactInfo>
                {'Contact us for custom invoicing, change plan or other billing related questions '}
                <a href="https://dragonboat.zendesk.com/hc/en-us/requests/new" target="_blank" rel="noreferrer">
                  clicking here
                </a>
              </ContactInfo>
            </>
          )}

          {!showBillingForm(this.state.planType) && (
            <>
              <SalesContactInfo>
                {'Please contact our '}
                <a href={`mailto:${SALES_TEAM_CONTACT}`}>sales team</a>
                {' to best configure the right product for you.'}
              </SalesContactInfo>
              <LearnMore id="billing-learn-more" data-testid="billing-learn-more">
                Learn more
              </LearnMore>
            </>
          )}
        </FieldsWrapper>
      </Wrapper>
    );
  };
}

const PlanPriceButton = styled(Button)`
  &&&& {
    ${({ selected }) => (selected ? `border: 2px solid ${linkColor}` : '')};
  }
`;

const PlanTypeFormControl = styled(FormControl)`
  &&&& {
    width: 100%;
    margin-right: 20px;
    margin-bottom: 20px;
  }
`;

const PlanPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PlanPriceType = styled.span`
  color: ${({ selected }) => (selected ? linkColor : materialColors.darkGray)};
  font-size: 17px;
`;

const PlanPriceDescription = styled.span`
  color: ${({ selected }) => (selected ? linkColor : materialColors.darkGray)};
  font-size: 11px;
`;

const FieldsWrapper = styled.div`
  &&&& {
    padding: 30px;
    width: 800px;
    display: flex;
    flex-direction: column;
  }
`;

const PlanSelector = styled.div`
  width: 100%;
  margin-top: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PaymentStripeInfo = styled(TextDeprecated).attrs({
  breakwords: true,
})`
  &&&& {
    margin-top: 20px;
  }
`;

const ContactInfo = styled(TextDeprecated).attrs({
  breakwords: true,
})`
  &&&& {
    margin-top: 30px;
  }
`;

const LearnMore = styled(Link).attrs({
  breakwords: true,
})`
  &&&& {
    margin-top: ${spacing(3.75)}px;
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  }
`;

const SalesContactInfo = styled(TextDeprecated).attrs({
  breakwords: true,
})``;

const PaymentButton = styled(Button)`
  &&&& {
    margin-top: 30px;
    align-self: flex-end;
    width: 150px;
    margin-right: -30px;
  }
`;

export default injectStripe(CheckoutForm);
