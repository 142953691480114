import { FILTERS_FUNCTIONS } from 'design-system/constants';

const functionsByType = {
  text: [
    FILTERS_FUNCTIONS.contains,
    FILTERS_FUNCTIONS.notContains,
    FILTERS_FUNCTIONS.containsAnyOf,
    FILTERS_FUNCTIONS.isEmptyText,
    FILTERS_FUNCTIONS.isNotEmptyText,
  ],
  option: [FILTERS_FUNCTIONS.in, FILTERS_FUNCTIONS.notIn, FILTERS_FUNCTIONS.isEmpty, FILTERS_FUNCTIONS.isNotEmpty],
  date: [
    FILTERS_FUNCTIONS.after,
    FILTERS_FUNCTIONS.before,
    FILTERS_FUNCTIONS.between,
    FILTERS_FUNCTIONS.thisWeek,
    FILTERS_FUNCTIONS.thisMonth,
    FILTERS_FUNCTIONS.today,
    FILTERS_FUNCTIONS.isEmpty,
  ],
  number: [FILTERS_FUNCTIONS.equals, FILTERS_FUNCTIONS.gte, FILTERS_FUNCTIONS.lte],
  // when deprecate it is only returned if currently in use
  checkbox: [FILTERS_FUNCTIONS.equals, { ...FILTERS_FUNCTIONS.isEmpty, deprecated: true }],
};

/**
 * Returns an array of filter functions that are allowed for a given field type.
 * If a function is deprecated, it will not be included in the returned array if it is not the current function
 *
 * @param {Object} options - Object containing the field type.
 * @param {string} options.type - The type of field.
 * @param {string} currentFunction - The current function being used.
 * @return {Array} An array of filter functions.
 */
export default ({ type }, currentFunction) => {
  // If no type is provided, return an empty array.
  if (!type) return [];

  // Filter the functionsByType array by the provided type.
  return functionsByType[type].filter(func => {
    return (func.deprecated && func.key === currentFunction) || !func.deprecated;
  });
};
