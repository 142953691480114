import { createChatBotMessage } from 'react-chatbot-kit';
import {
  WELCOME_MESSAGE_TEXT,
  TARGET_TEAM_OPTIONS,
  TARGET_TEAM_QUESTION_TEXT,
  BUSINESS_DRIVER_QUESTION_TEXT,
  DEFAULT_BUSINESS_DRIVER_RESPONSE,
} from '../constants';

const OTHER = 'Other';
const OPTION_BUTTONS = 'optionButtons';

// Message 1:
export const createWelcomeMessage = () => {
  return createChatBotMessage(WELCOME_MESSAGE_TEXT, {
    withAvatar: false,
    loading: true,
  });
};

// Message 4:
export const createBusinessDriverResponseMessage = botBusinessDriverResponse => {
  return createChatBotMessage(botBusinessDriverResponse, {
    withAvatar: false,
    loading: true,
  });
};

// Message 3:
export const createBusinessDriverQuestion = selfGuidedDemoData => {
  const options = [
    ...selfGuidedDemoData.map(({ label, id, onClick, botBusinessDriverResponse }) => ({
      label,
      value: id,
      metadata: {
        handleOnClick: onClick, // Trigger L1 Pendo guide
        createNextMessage: () => createBusinessDriverResponseMessage(botBusinessDriverResponse),
      },
    })),
    {
      label: OTHER,
      value: OTHER,
      metadata: {
        createNextMessage: () => createBusinessDriverResponseMessage(DEFAULT_BUSINESS_DRIVER_RESPONSE),
      },
    },
  ];

  return createChatBotMessage(BUSINESS_DRIVER_QUESTION_TEXT, {
    widget: OPTION_BUTTONS,
    withAvatar: false,
    loading: true,
    payload: {
      options,
    },
  });
};

// Message 2:
export const createTargetTeamQuestion = selfGuidedDemoData => {
  const options = TARGET_TEAM_OPTIONS.map(option => {
    return {
      ...option,
      metadata: {
        createNextMessage: () => createBusinessDriverQuestion(selfGuidedDemoData),
      },
    };
  });

  return createChatBotMessage(TARGET_TEAM_QUESTION_TEXT, {
    widget: OPTION_BUTTONS,
    withAvatar: false,
    loading: true,
    delay: 1000,
    payload: {
      options,
    },
  });
};
