import React from 'react';

const MentionComponent = props => {
  const {
    mention,
    theme,
    ...parentProps
  } = props;

  return (
    <div {...parentProps}>
      <div className={theme.mentionSuggestionsEntryContainer}>
        <div className={theme.mentionSuggestionsEntryContainerRight}>
          <div className={theme.mentionSuggestionsEntryText}>{mention.name}</div>
        </div>
      </div>
    </div>
  );
};

export default MentionComponent;
