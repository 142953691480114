import getValidFormula from 'store/customFields/helpers/getValidFormula';
import { parseEntityWithSanitizedKeys } from './customFields';

const withFlatCustomFields = customFields => customer => {
  const customFieldKeys = customer.custom_fields ? Object.keys(customer.custom_fields) : [];

  if (customFieldKeys.length) {
    Object.keys(customer.custom_fields || {})
      .filter(crcf => customFields.find(cf => cf.key === crcf)?.field_type !== 'Formula')
      .forEach(key => {
        customer[key] = customer.custom_fields[key];
      });

    // eslint-disable-next-line no-unused-vars
    const entityWithSanitizedKeys = parseEntityWithSanitizedKeys(customer, customFields);

    customFields
      .filter(cf => cf.field_type === 'Formula')
      .forEach(cf => {
        try {
          customer[cf.key] =
            // eslint-disable-next-line no-eval
            +parseFloat(eval(getValidFormula(cf.description, 'entityWithSanitizedKeys', customFields, 'customers'))).toFixed(2);
          if ([Infinity, NaN].includes(customer[cf.key])) customer[cf.key] = '';
        } catch (err) {
          customer[cf.key] = '';
        }
      });
  }

  return customer;
};

const withIntegrations = orgIntegrations => customer => {
  const { integrations } = customer || {};

  if (integrations) {
    const mappedIntegrations = integrations.map(integration => {
      const matchingOrgIntegration = orgIntegrations.find(orgIntegration => orgIntegration.id === integration.org_integration_id);
  
      return {
        ...integration,
        integrationType: matchingOrgIntegration?.integrationType,
      };
    });
  
    return {
      ...customer,
      integrations: mappedIntegrations,
    };
  }

  return customer;
};

export { withFlatCustomFields, withIntegrations };
