import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showMergeLightbox as showMergeLightboxAction, hideMergeLightbox as hideMergeLightboxAction } from 'store/grids/actions';
import { getMergeLightboxVisible } from 'store/grids/selectors';
import useBulkDeleteWithProgress from 'design-system/molecules/AgGridReact-New/hooks/useBulkDeleteWithProgress';

const useSettingsActions = ({ settingType, selectedItems, disableSelectionMode, deleteById, bulkDelete }) => {
  const dispatch = useDispatch();

  // Delete

  const [itemToDelete, setItemToDelete] = useState();

  const deleteItem = useCallback(() => {
    if (!itemToDelete) {
      return;
    }

    deleteById(itemToDelete.id, itemToDelete);

    setItemToDelete(null);
  }, [itemToDelete, deleteById]);

  // Delete with progress

  const [bulkDeleteItems, deleteProgress, deleteIsLoading, showBulkDeleteDialog, setShowBulkDeleteDialog] =
    useBulkDeleteWithProgress(selectedItems, bulkDelete, disableSelectionMode);

  // Merge

  const mergeLightboxVisible = useSelector(state => getMergeLightboxVisible(state, settingType));

  const showMergeLightbox = useCallback(() => dispatch(showMergeLightboxAction(settingType)), [dispatch, settingType]);

  const hideMergeLightbox = useCallback(() => dispatch(hideMergeLightboxAction(settingType)), [dispatch, settingType]);

  return {
    // Delete
    itemToDelete,
    setItemToDelete,
    deleteItem,
    bulkDeleteItems,
    showBulkDeleteDialog,
    setShowBulkDeleteDialog,
    deleteProgress,
    deleteIsLoading,
    // Merge
    mergeLightboxVisible,
    showMergeLightbox,
    hideMergeLightbox,
  };
};

export default useSettingsActions;
