import { isNil, not } from 'ramda';

import buildReleaseCapacityAllocationKey from './buildReleaseCapacityAllocationKey';

const GROUP_LEVEL_TWO = 2;
const checkFirstGroupIsTimeframeL1OrL2 = field => ['timeframe_id', 'timeframe_2_id'].includes(field);

/**
 * @function getReleaseCapacityAllocationMetadataByGroupRow
 *
 * Get the release capacity allocation metadata based on
 * the given group row
 *
 * @param  {Object} row
 * @return {Object}
 */
const getReleaseCapacityAllocationMetadataByGroupRow = (row, pageFilters) => {
  const { groupData, groupOption, level, parent, group } = row;
  let metadataField = null;
  let metadataId = null;
  let timeframeId = null;
  let isFirstGroupTimeframeL1OrL2 = checkFirstGroupIsTimeframeL1OrL2(groupOption.field);

  // is not a group should not proceed
  if (not(group)) {
    return {};
  }

  const groupId = groupData[groupOption.field];

  if (level === GROUP_LEVEL_TWO && parent) {
    const { groupData: parentGroupData, groupOption: parentGroupOption } = parent;
    const parentGroupId = parentGroupData[parentGroupOption.field];

    timeframeId = parentGroupId;
    metadataField = groupOption.field;
    metadataId = groupId;
    isFirstGroupTimeframeL1OrL2 = checkFirstGroupIsTimeframeL1OrL2(parentGroupOption.field);
  } else {
    timeframeId = groupId;
  }

  if (not(isFirstGroupTimeframeL1OrL2)) {
    return {};
  }

  // TODO: check if is supposed to have values on undefined timeframe
  if (isNil(timeframeId)) {
    return { timeframeId, metadataField, metadataId };
  }

  const key = buildReleaseCapacityAllocationKey(timeframeId, metadataField, metadataId);

  return {
    key,
    timeframeId,
    metadataField,
    metadataId,
  };
};

export default getReleaseCapacityAllocationMetadataByGroupRow;
