import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateIntegrationProgressTracking as updateIntegrationProgressTrackingAction } from 'store/organization';
import { getIntegrationProgressTracking as getIntegrationProgressTrackingSelector } from 'store/organization/selectors';

const useProgressTrackingConfig = (integrationType, orgIntegrationId) => {
  const dispatch = useDispatch();

  const progressTracking = useSelector(getIntegrationProgressTrackingSelector(orgIntegrationId));

  const updateProgressTracking = useCallback(data => {
    dispatch(updateIntegrationProgressTrackingAction(integrationType, orgIntegrationId, data));
  }, [integrationType, orgIntegrationId]);

  return {
    progressTracking,
    updateProgressTracking,
  };
};

export default useProgressTrackingConfig;
