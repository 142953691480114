import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import HelpIcon from '@material-ui/icons/HelpOutline';

import HelpLink from 'design-system/atoms/HelpLink/index';
import InlineIcon from 'design-system/atoms/InlineIcon/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import { ISSUE_COUNT_PROGRESS, STORY_POINTS_PROGRESS, TIME_ESTIMATES_PROGRESS } from 'constants/integrations';

const TRACKING_OPTIONS = [
  { key: ISSUE_COUNT_PROGRESS, title: 'Issue count' },
  { key: STORY_POINTS_PROGRESS, title: 'Story Points' },
  { key: TIME_ESTIMATES_PROGRESS, title: 'Time Tracking' },
];

const ProgressTracking = props => {
  const { data, isReadOnly, defaultTimeEstimateInString, onTrackProgressTypeChange, onDefaultTimeEstimateChange, onChange } =
    props;

  return (
    <Wrapper>
      <Section>
        <Tooltip enterDelay={200} title="Create custom progress tracking by entering 1 JIRA project at a time.">
          <FormLabel control="legend">
            Track progress by
            <HelpLink
              href="https://dragonboat.zendesk.com/hc/en-us/articles/360041999874"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InlineIcon>
                <HelpIcon />
              </InlineIcon>
            </HelpLink>
          </FormLabel>
        </Tooltip>
        <CustomFormGroup row>
          {TRACKING_OPTIONS.map(({ key, title }) => (
            <FormControlLabel
              key={key}
              label={title}
              disabled={isReadOnly}
              control={
                <Radio color="primary" checked={data.track_progress_by === key} onChange={() => onTrackProgressTypeChange(key)} />
              }
            />
          ))}
        </CustomFormGroup>
      </Section>
      {data.track_progress_by === ISSUE_COUNT_PROGRESS && (
        <Section>
          <InlineInputGroup>
            <TextDeprecated inline>Display</TextDeprecated>
            <InlineTextField
              value={data.display_days_per_issue}
              onChange={({ target }) => onChange('display_days_per_issue', target.value)}
              disabled={isReadOnly}
            />
            <TextDeprecated inline breakwords>
              day per issue on the Timeline page
            </TextDeprecated>
          </InlineInputGroup>
        </Section>
      )}
      {data.track_progress_by === STORY_POINTS_PROGRESS && (
        <Section>
          <InlineInputGroup>
            <TextDeprecated inline>Default Story Points</TextDeprecated>
            <InlineTextField
              value={data.default_story_points}
              onChange={({ target }) => onChange('default_story_points', target.value)}
              disabled={isReadOnly}
            />
          </InlineInputGroup>
          <InlineInputGroup>
            <TextDeprecated inline>Display</TextDeprecated>
            <InlineTextField
              value={data.display_storypoint_per_day}
              onChange={({ target }) => onChange('display_storypoint_per_day', target.value)}
              disabled={isReadOnly}
            />
            <TextDeprecated inline breakwords>
              day per Story Point on the Timeline page
            </TextDeprecated>
          </InlineInputGroup>
        </Section>
      )}
      {data.track_progress_by === TIME_ESTIMATES_PROGRESS && (
        <Section>
          <InlineInputGroup>
            <TextDeprecated inline>Default Time Estimates</TextDeprecated>
            <InlineTextField
              value={defaultTimeEstimateInString}
              width="100px"
              placeholder="1w 1d 1h 1m"
              onChange={({ target }) => onDefaultTimeEstimateChange(target.value)}
              disabled={isReadOnly}
            />
          </InlineInputGroup>
        </Section>
      )}
      <FormControlLabel
        disabled={isReadOnly}
        label="Exclude bugs from progress calculation"
        control={
          <Switch
            checked={data.exclude_bugs_progress}
            onChange={() => onChange('exclude_bugs_progress', !data.exclude_bugs_progress)}
          />
        }
      />
    </Wrapper>
  );
};

ProgressTracking.propTypes = {
  data: PropTypes.shape({
    track_progress_by: PropTypes.string,
    display_days_per_issue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    default_story_points: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    display_storypoint_per_day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    default_time_estimate: PropTypes.number,
    exclude_bugs_progress: PropTypes.bool,
  }),
  isReadOnly: PropTypes.bool,
  defaultTimeEstimateInString: PropTypes.string,
  onTrackProgressTypeChange: PropTypes.func,
  onDefaultTimeEstimateChange: PropTypes.func,
  onChange: PropTypes.func,
};

const Wrapper = styled.div`
  position: relative;
`;

const Section = styled.div`
  margin-bottom: 12px;
`;

const CustomFormGroup = styled(FormGroup)`
  &&&& {
    margin: 0 6px;
  }
`;

const InlineInputGroup = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
`;

const InlineTextField = styled(TextField)`
  input {
    width: ${({ width }) => width || '50px'};
    text-align: center;
    padding: 4px 0;
    font-size: 14px;
  }
`;

export default ProgressTracking;
