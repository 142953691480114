import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_METRIC_ALLOCATION_CHART_DATA'),
};

export const {
  FETCH_METRIC_ALLOCATION_CHART_DATA,
  FETCH_METRIC_ALLOCATION_CHART_DATA_PENDING,
  FETCH_METRIC_ALLOCATION_CHART_DATA_FULFILLED,
  FETCH_METRIC_ALLOCATION_CHART_DATA_REJECTED,
} = actionTypes;

export const UPDATE_METRIC_ALLOCATION_TIME_PERIOD = 'UPDATE_METRIC_ALLOCATION_TIME_PERIOD';
