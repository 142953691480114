
export default function (url) {
  if (!url || typeof (url) !== 'string') {
    return false;
  }

  const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
  const regex = new RegExp(expression);

  return url.match(regex);
}
