import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import capitalize from 'lodash/capitalize';

import Link from '@material-ui/core/Link';

import BookADemoPageTemplate from 'design-system/templates/BookADemoPageTemplate';
import {
  FORECAST_PAGE,
  SNAPSHOT_PAGE,
  ALLOCATION_REPORT_PAGE,
  GOALS_SNAPSHOT_PAGE,
  GOALS_ALLOCATION_PAGE,
} from 'constants/filters';
import BookDemoLightbox from 'components/BookDemoLightbox';
import TimelineAndAllocation from './timelineAndAllocation.png';
import Snapshot from './snapshot.png';
import { BILLING_STATUSES } from 'constants/organizations';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';

const useBookADemoLocker = (pageId, ignoreUpgrade = false, isDashboardModule = false) => {
  const organization = useSelector(state => state.organization.organization);
  const [showBookDemo, setShowBookDemo] = useState(false);

  const { canView } = usePermissions();

  const _conditionalCapitalize = text => (ignoreUpgrade ? capitalize(text) : text);

  if (pageId === SNAPSHOT_PAGE) {
    const hasSnapshotPage = canView(PERMISSION_PAGES[SNAPSHOT_PAGE], { isDashboardModule });

    if (hasSnapshotPage) return null;

    const image = Snapshot;
    const text = (
      <>
        {!ignoreUpgrade && (
          <>
            <Link href="/settings/billing">Upgrade now</Link>
            {' or '}{' '}
          </>
        )}
        <Link href="#" onClick={() => setShowBookDemo(true)}>
          {_conditionalCapitalize('contact our team')}
        </Link>{' '}
        to turn on this advanced feature
        <BookDemoLightbox open={showBookDemo} onClose={() => setShowBookDemo(false)} />
      </>
    );

    return () => <BookADemoPageTemplate image={image} text={text} />;
  }

  if (pageId === FORECAST_PAGE) {
    const hasForecastModule = canView(PERMISSION_PAGES[FORECAST_PAGE], { isDashboardModule });

    if (hasForecastModule) return null;

    const image = TimelineAndAllocation;
    const text = (
      <>
        {!ignoreUpgrade && (
          <>
            <Link href="/settings/billing">Upgrade now</Link>
            {' or '}{' '}
          </>
        )}
        <Link href="#" onClick={() => setShowBookDemo(true)}>
          {_conditionalCapitalize('book a demo')}
        </Link>{' '}
        to experience the powerful Portfolio Forecast and Allocation Module
        <BookDemoLightbox open={showBookDemo} onClose={() => setShowBookDemo(false)} />
      </>
    );

    return () => <BookADemoPageTemplate image={image} text={text} />;
  }

  if ((organization || {}).billing_status === BILLING_STATUSES.SELFSERVE_TRIAL) {
    let image;
    let text;

    switch (pageId) {
      case GOALS_SNAPSHOT_PAGE:
      case SNAPSHOT_PAGE: {
        image = Snapshot;
        text = (
          <>
            {!ignoreUpgrade && (
              <>
                <Link href="/settings/billing">Upgrade now</Link>
                {' or '}{' '}
              </>
            )}
            <Link href="#" onClick={() => setShowBookDemo(true)}>
              {_conditionalCapitalize('contact our team')}
            </Link>{' '}
            to turn on this advanced feature
            <BookDemoLightbox open={showBookDemo} onClose={() => setShowBookDemo(false)} />
          </>
        );
        break;
      }
      case GOALS_ALLOCATION_PAGE:
      case ALLOCATION_REPORT_PAGE: {
        image = TimelineAndAllocation;
        text = (
          <>
            {!ignoreUpgrade && (
              <>
                <Link href="/settings/billing">Upgrade now</Link>
                {' or '}{' '}
              </>
            )}
            <Link href="#" onClick={() => setShowBookDemo(true)}>
              {_conditionalCapitalize('contact our team')}
            </Link>{' '}
            to turn on this advanced feature
            <BookDemoLightbox open={showBookDemo} onClose={() => setShowBookDemo(false)} />
          </>
        );
        break;
      }
      case 'INTEGRATIONS': {
        image = null;
        text = (
          <>
            {!ignoreUpgrade && (
              <>
                <Link href="/settings/billing">Upgrade now</Link>
                {' or '}{' '}
              </>
            )}
            <Link href="#" onClick={() => setShowBookDemo(true)}>
              {_conditionalCapitalize('contact our team')}
            </Link>{' '}
            to turn on this advanced feature
            <BookDemoLightbox open={showBookDemo} onClose={() => setShowBookDemo(false)} />
          </>
        );
        break;
      }

      default:
        return null;
    }

    return () => <BookADemoPageTemplate image={image} text={text} />;
  }

  return null;
};

export default useBookADemoLocker;
