import React from 'react';
import styled from 'styled-components';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';


import useCompareScenariosToolbarControls from './hooks/useCompareScenariosToolbarControls';

import useSystemFields from 'hooks/useSystemFields';

const CompareScenariosToolbarControls = () => {
  const { isEditingScenarioOnComparePage, onClickExitDraftMode, onClickSaveScenario } = useCompareScenariosToolbarControls();
  const [getSystemFieldName] = useSystemFields();

  return isEditingScenarioOnComparePage ? (
    <Wrapper>
      <StyledButtonIcon
        id="save-draft-mode"
        title={`Save ${getSystemFieldName('scenario')}`}
        onClick={onClickSaveScenario}
        disabled={!isEditingScenarioOnComparePage}
      >
        <SaveIcon />
      </StyledButtonIcon>

      <StyledButtonIcon
        title={`Discard ${getSystemFieldName('scenario')} changes`}
        onClick={onClickExitDraftMode}
        id="exit-draft-mode"
      >
        <ArrowBackIcon />
      </StyledButtonIcon>
    </Wrapper>
  ) : null;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledButtonIcon = styled(ButtonIcon)`
  &&&& {
    width: 38px;
    height: 38px;
  }
`;

export default CompareScenariosToolbarControls;
