import React from 'react';
import styled, { css } from 'styled-components';

import Text from 'design-system/atoms/Text/index';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import { spacing } from 'design-system/theme';

const Card = ({ title, actions, children, contentStyles, onClickTitle, ...props }) => {
  return (
    <Container {...props}>
      {title ? (
        <StyledText variant="h2" withEllipsis maxWidth="80%" onClick={onClickTitle}>
          {title}
        </StyledText>
      ) : null}
      {actions?.length > 0 ? (
        <Actions>
          {actions.map(action => (
            <StyledButtonIcon
              key={action.icon}
              onClick={action.onClick}
              {...(action.testId ? { 'data-testid': action.testId } : {})}
            >
              {action.icon}
            </StyledButtonIcon>
          ))}
        </Actions>
      ) : null}

      <Content contentStyles={contentStyles}>{children}</Content>
    </Container>
  );
};

export default Card;

const Actions = styled.div`
  visibility: hidden;
  position: absolute;
  top: ${spacing(2.75)}px;
  right: ${spacing(2.25)}px;
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  position: relative;
  padding: ${spacing(3)}px;
  background: ${({ theme }) => theme.palette.white};
  border: ${({ theme }) => `1px solid ${theme.palette.border.primary}`};
  border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;
  box-sizing: border-box;
  :hover {
    ${Actions} {
      visibility: visible;
    }
  }
`;

const StyledButtonIcon = styled(ButtonIcon)`
  && {
    padding: ${spacing(0.75)}px;

    svg {
      font-size: ${({ theme }) => theme.sizing.icons.small}rem;
      color: ${({ theme }) => theme.palette.buttonIcon.secondary};
    }
  }
`;

const StyledText = styled(Text)`
  ${({ onClick }) =>
    onClick &&
    css`
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    `}
`;

const Content = styled.div`
  margin-top: ${spacing()}px;

  ${({ contentStyles }) => contentStyles};
`;
