import React from 'react';
import isString from 'lodash/isString';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import pluralize from 'pluralize';
import styled, { css } from 'styled-components';
import ListSubheader from '@material-ui/core/ListSubheader';

import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';

import useRoadmapMetadataShowMoreOptions from './hooks/useRoadmapMetadataShowMoreOptions';

const ellipsisStyles = css`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`;

/**
 * @Component RoadmapMetadataOptions
 *
 * Roadmap metadata option on dropdown select
 *
 * @param  {Array}     props.options
 * @param  {Array}     props.filteredOptions
 * @param  {Array}     props.optionsByTitle
 * @param  {Number}    props.hoveredRowIndex
 * @param  {String}    props.inputValue
 * @param  {Boolean}   props.addNew
 * @param  {Function}  props.onSuggestionSelected
 * @param  {String}    props.value
 * @param  {String}    props.label
 * @return {Function}
 */
const RoadmapMetadataOptions = ({
  options,
  filteredOptions,
  optionsByTitle,
  hoveredRowIndex,
  inputValue,
  addNew,
  onSuggestionSelected,
  value,
  label,
  withEllipsis,
}) => {
  const { toggleShowMoreOptions, showMoreOptions } = useRoadmapMetadataShowMoreOptions({
    value,
    options,
    optionsByTitle,
    hoveredRowIndex,
  });

  const renderCollapseArrow = isOpen => {
    return isOpen ? <KeyboardArrowDownIcon id="arrow-down" /> : <KeyboardArrowRightIcon id="arrow-right" />;
  };

  const checkRowIsHovered = row => {
    const rowIndex = optionsByTitle.findIndex(o => o === row);

    return rowIndex === hoveredRowIndex;
  };

  const showCreateNewOption = addNew && inputValue && optionsByTitle.length === 0;

  const renderOption = (option, hidden = false) => {
    const optionIsString = isString(option);
    const optionName = optionIsString ? option : option?.name;
    const optionKey = optionIsString ? option : option?.key;
    const label = optionIsString ? optionName : option?.displayName;
    const itemContent = label ?? optionName;

    const renderMenuItem = () => (
      <StyledMenuItemDropdown
        onClick={() => onSuggestionSelected(optionName)}
        hidden={hidden}
        key={optionKey}
        value={optionName}
        selected={value === optionName}
        hovered={checkRowIsHovered(optionName)}
      >
        {itemContent}
      </StyledMenuItemDropdown>
    );

    const renderMenuItemWithEllipsis = () => (
      <TooltipOnOverflow text={itemContent}>
        <StyledMenuItemDropdown
          onClick={() => onSuggestionSelected(optionName)}
          hidden={hidden}
          key={optionKey}
          value={optionName}
          selected={value === optionName}
          hovered={checkRowIsHovered(optionName)}
          customStyles={ellipsisStyles}
        >
          {itemContent}
        </StyledMenuItemDropdown>
      </TooltipOnOverflow>
    );

    return withEllipsis ? renderMenuItemWithEllipsis() : renderMenuItem();
  };

  return (
    <>
      {!options.length && <StyledMenuItemDropdown disabled>No Options (Update in Settings)</StyledMenuItemDropdown>}
      {showCreateNewOption && (
        <StyledMenuItemDropdown onClick={() => onSuggestionSelected(inputValue)}>
          {`Create "${inputValue}"`}
        </StyledMenuItemDropdown>
      )}
      {!!filteredOptions.length &&
        filteredOptions.map((option, index) => {
          return option.children.map(child => renderOption(child, index > 0));
        })}
      {!showCreateNewOption && filteredOptions.length > 1 && (
        <ShowMoreHeader onClick={toggleShowMoreOptions} id="show-more-header" key="show-more-header" component="div">
          Show all {pluralize(label)}
          {renderCollapseArrow(showMoreOptions)}
        </ShowMoreHeader>
      )}
      {!showCreateNewOption &&
        showMoreOptions &&
        !!filteredOptions.length &&
        filteredOptions.map((option, index) => {
          if (index === 0) return '';

          const childItems = option.children.map(child => renderOption(child));

          const header = (
            <RoadmapSubheader component="div" key={option.key}>
              {option.label}
            </RoadmapSubheader>
          );

          return [header, ...childItems];
        })}
    </>
  );
};

const RoadmapSubheader = styled(ListSubheader)`
  &&&&& {
    display: flex;
    align-items: center;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 32px;
  }
`;

const ShowMoreHeader = styled(ListSubheader)`
  &&&&& {
    display: flex;
    align-items: center;
    font-weight: normal;
    color: ${props => props.theme.palette.text.info};
    line-height: 32px;

    &:hover {
      cursor: pointer;
    }
  }
`;

export default RoadmapMetadataOptions;
