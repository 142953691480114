import { createSelector } from 'reselect';
import { either, isEmpty, isNil, not, pipe } from 'ramda';

import { makeSelectFilteredProjectsByRoadmapForPage, getDisplayLayer } from 'store/filters/selectors';
import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { selectFilteredProjectsForPage } from 'store/projects/selectors';
import { getNormalizedTimeframes } from 'store/timeframes/selectors';
import { getNormalizedRoadmaps, getNormalizedProducts } from 'store/roadmaps/selectors';
import { PROGRESS_PAGE, GLOBAL_FILTER } from 'constants/filters';
import { INITIATIVE, BET } from 'constants/projects';
import { TABLE_SELECTED_LEVELS } from './consts';
import { getData, isLoading } from 'utils/store/thunk';
import { LOAD_PROGRESS_REPORT_DATA } from './types';

const isNotNilOrEmpty = pipe(either(isNil, isEmpty), not);

const isAtProjectLevel = Array.isArray;
const isInitiativeOrBet = value => [INITIATIVE, BET].includes(value);
const projectsLayerMapping = {
  initiative: INITIATIVE_LAYER,
  bet: BET_LAYER,
};

const _convertToNumberOrUndefined = value => {
  if (value === 'undefined') {
    value = undefined;
  } else if (typeof value === 'string') {
    value = +value;
  }

  return value;
};

export const getProgressPagePreferences = createSelector(
  state => state.progress,
  state => {
    const { selectedGroupByLvl1, selectedGroupByLvl2, tableSelectedLevel, level1ClickedId, level2ClickedId, projectActiveId } =
      state;

    return { selectedGroupByLvl1, selectedGroupByLvl2, tableSelectedLevel, level1ClickedId, level2ClickedId, projectActiveId };
  },
);

export const makeSelectProjectsForProgressTable = () => {
  const _selectProjects = makeSelectFilteredProjectsByRoadmapForPage();

  return createSelector(
    state => {
      const displayLayer = getDisplayLayer(state);

      return _selectProjects(state, PROGRESS_PAGE, false, displayLayer);
    },
    (_, progressPagePreferences) => progressPagePreferences,
    getNormalizedTimeframes,
    getNormalizedRoadmaps,
    getNormalizedProducts,
    (projects, progressPagePreferences, timeframes, roadmaps, product1) => {
      const {
        selectedGroupByLvl1 = { key: 'timeframe' },
        selectedGroupByLvl2 = { key: 'roadmap' },
        tableSelectedLevel = 1,
        level1ClickedId = null,
        level2ClickedId = null,
      } = progressPagePreferences;

      // 1. create an object thats groups projects by selected group like
      // { timeframe_1: { roadmap_1: [project1, project2, project3], roadmap_2: [project4, project5, project6] },
      //   timeframe_2: {roadmap_3: [project7, project8]} }

      const metadata = {
        timeframe: timeframes,
        roadmap: roadmaps,
        product1,
      };

      const filteredProjects = {};

      // if we are in the first level we start fill the obj with GroupByLvl1
      if (tableSelectedLevel === TABLE_SELECTED_LEVELS.first) {
        if (!isInitiativeOrBet(selectedGroupByLvl1.key)) {
          Object.keys(metadata[selectedGroupByLvl1.key]).forEach(md => {
            filteredProjects[md] = {};
          });
        } else {
          // add an entry for initiative id or bet id
          // when showing we filter by initiative or bet - now it doesn't matter
          projects.forEach(p => {
            if (p.parent_id && p.parentLayer === projectsLayerMapping[selectedGroupByLvl1.key]) {
              filteredProjects[p.parent_id] = {};
            }
          });
        }

        filteredProjects.undefined = {};

        Object.values(filteredProjects).forEach(val => {
          Object.keys(metadata[selectedGroupByLvl2.key]).forEach(md => {
            val[md] = [];
          });
          val.undefined = [];
        });
      } else if (tableSelectedLevel === TABLE_SELECTED_LEVELS.second) {
        Object.keys(metadata[selectedGroupByLvl2.key]).forEach(md => {
          filteredProjects[md] = {};
        });
        filteredProjects.undefined = {};
      }

      const getProjectByAllGroups = (groupBy1Id, groupBy2Id) => {
        groupBy1Id = _convertToNumberOrUndefined(groupBy1Id);
        groupBy2Id = _convertToNumberOrUndefined(groupBy2Id);

        return projects.filter(p => {
          if (!isInitiativeOrBet(selectedGroupByLvl1.key)) {
            return p[selectedGroupByLvl1.key]?.id === groupBy1Id && p[selectedGroupByLvl2.key]?.id === groupBy2Id;
          }

          return (
            p.parent_id === groupBy1Id &&
            p.parentLayer === projectsLayerMapping[selectedGroupByLvl1.key] &&
            p[selectedGroupByLvl2.key]?.id === groupBy2Id
          );
        });
      };

      // fill with projects
      if (tableSelectedLevel === TABLE_SELECTED_LEVELS.first) {
        Object.entries(filteredProjects).forEach(([groupId, groupValues]) => {
          Object.keys(groupValues).forEach(subGroupId => {
            filteredProjects[groupId][subGroupId] = getProjectByAllGroups(groupId, subGroupId);
          });
        });
      } else if (tableSelectedLevel === TABLE_SELECTED_LEVELS.second) {
        Object.keys(filteredProjects).forEach(groupId => {
          filteredProjects[groupId] = getProjectByAllGroups(level1ClickedId, groupId);
        });
      } else if (tableSelectedLevel === TABLE_SELECTED_LEVELS.third && level1ClickedId && level2ClickedId) {
        getProjectByAllGroups(level1ClickedId, level2ClickedId).forEach(p => {
          filteredProjects[p.id] = [p];
        });
      }

      return filteredProjects; // returns a list of projects filtered by the selected level 1 / level 2 group metadata
    },
  );
};

const TOTALS_KEYS = [
  'issuesClosed',
  'issuesInProgress',
  'issuesTotal',
  'pointsClosed',
  'pointsTotal',
  'timeInProgress',
  'timeTotal',
  'progress',
  'withInt',
  'withoutInt',
];

const _initTotals = () => TOTALS_KEYS.reduce((obj, key) => ({ ...obj, [key]: 0 }), {});
const _groomTotals = (obj1, obj2) =>
  TOTALS_KEYS.reduce((obj, key) => ({ ...obj, [key]: (obj1[key] || 0) + (obj2[key] || 0) }), {});

const _getTotalsByLevel = (levelGroupedProjects, tableSelectedLevel, forceContinue = false) => {
  const totals = Object.entries(levelGroupedProjects).reduce((values, [groupId, groupValues]) => {
    if (!isAtProjectLevel(groupValues)) {
      return {
        ...values,
        [groupId]: _getTotalsByLevel(groupValues, tableSelectedLevel),
      };
    }

    if (
      isAtProjectLevel(groupValues) &&
      (tableSelectedLevel === TABLE_SELECTED_LEVELS.second || tableSelectedLevel === TABLE_SELECTED_LEVELS.third) &&
      !forceContinue
    ) {
      return {
        ...values,
        [groupId]: _getTotalsByLevel({ [groupId]: groupValues }, tableSelectedLevel, true),
      };
    }

    const groupTotals = groupValues.reduce((totals, project) => {
      const subTotal = _groomTotals(totals, project.integrationProgress || {});
      const hasIntegrationProgress = isNotNilOrEmpty(project.integrationProgress);

      subTotal.progress = totals.progress + (project.progress || 0);
      subTotal.withInt = totals.withInt + (hasIntegrationProgress ? 1 : 0);
      subTotal.withoutInt = totals.withoutInt + (hasIntegrationProgress ? 0 : 1);

      return subTotal;
    }, _initTotals());

    return _groomTotals(values, groupTotals || {});
  }, {});

  return totals;
};

export const makeGetProgressTableData = () => {
  const selectProjectsForProgressTable = makeSelectProjectsForProgressTable();

  return createSelector(
    (state, progressPagePreferences) => selectProjectsForProgressTable(state, progressPagePreferences),
    state => {
      const { tableSelectedLevel } = state.progress;

      return tableSelectedLevel;
    },
    (groupedProjects, tableSelectedLevel) => {
      // 1. iterate over the projects based on the object of projects and calculate the values for the table by group
      // like {timeframe_1: { total: X, inProgress: Y, closed: Z}}
      // or {roadmap__1: { total: X, inProgress: Y, closed: Z}}
      let totalOverall = _initTotals();

      const totalsByLevel = _getTotalsByLevel(groupedProjects, tableSelectedLevel);

      Object.keys(totalsByLevel).forEach(
        id =>
          (totalsByLevel[id].progress =
            totalsByLevel[id].withInt + totalsByLevel[id].withoutInt
              ? totalsByLevel[id].progress / (totalsByLevel[id].withInt + totalsByLevel[id].withoutInt)
              : 0),
      );

      totalOverall = Object.entries(totalsByLevel).reduce((totals, [id, values]) => {
        return _groomTotals(totals, values || {});
      }, totalOverall);

      totalOverall.progress /= Object.keys(totalsByLevel).length;

      return [totalsByLevel, totalOverall];
    },
  );
};

export const makeSelectProjectsForProgressChart = () => {
  const _selectProjects = makeSelectFilteredProjectsByRoadmapForPage();

  return createSelector(
    state => {
      const displayLayer = getDisplayLayer(state);

      return _selectProjects(state, PROGRESS_PAGE, false, displayLayer);
    },
    (_, progressPagePreferences) => progressPagePreferences,
    (projects, progressPagePreferences) => {
      const {
        selectedGroupByLvl1 = { key: 'timeframe' },
        selectedGroupByLvl2 = { key: 'roadmap' },
        tableSelectedLevel = 1,
        projectActiveId = null,
      } = progressPagePreferences;

      let { level1ClickedId, level2ClickedId } = progressPagePreferences;

      let result = [];

      level1ClickedId = _convertToNumberOrUndefined(level1ClickedId);
      level2ClickedId = _convertToNumberOrUndefined(level2ClickedId);

      if (!projectActiveId) {
        result = projects.filter(p => {
          if (!selectedGroupByLvl1 && !selectedGroupByLvl2) return false;

          if (tableSelectedLevel === TABLE_SELECTED_LEVELS.second) {
            if (!isInitiativeOrBet(selectedGroupByLvl1.key)) {
              return p[selectedGroupByLvl1.key]?.id === level1ClickedId;
            }

            return p.parent_id === level1ClickedId && p.parentLayer === projectsLayerMapping[selectedGroupByLvl1.key];
          } else if (tableSelectedLevel === TABLE_SELECTED_LEVELS.third) {
            if (!isInitiativeOrBet(selectedGroupByLvl1.key)) {
              return p[selectedGroupByLvl1.key]?.id === level1ClickedId && p[selectedGroupByLvl2.key]?.id === level2ClickedId;
            }

            return (
              p.parent_id === level1ClickedId &&
              p.parentLayer === projectsLayerMapping[selectedGroupByLvl1.key] &&
              p[selectedGroupByLvl2.key]?.id === level2ClickedId
            );
          }

          // if table is on level 1 we should not filter any project
          return true;
        });
      } else {
        result = projects.filter(p => p.id === +projectActiveId);
      }

      return result;
    },
  );
};

export const makeMetadataByLevel = () => {
  const _selectProjects = selectFilteredProjectsForPage;

  return createSelector(
    state => _selectProjects(state, GLOBAL_FILTER, false, INITIATIVE_LAYER, false),
    state => _selectProjects(state, GLOBAL_FILTER, false, BET_LAYER, false),
    (_, progressPagePreferences) => progressPagePreferences,
    getNormalizedTimeframes,
    getNormalizedRoadmaps,
    getNormalizedProducts,
    (initiatives, bets, progressPagePreferences, timeframes, roadmaps, product1) => {
      const {
        selectedGroupByLvl1 = { key: 'timeframe' },
        selectedGroupByLvl2 = { key: 'roadmap' },
        tableSelectedLevel = 1,
      } = progressPagePreferences;

      const metadata = {
        initiative: initiatives.reduce((acc, value) => ({ ...acc, [value.id]: value }), {}),
        bet: bets.reduce((acc, value) => ({ ...acc, [value.id]: value }), {}),
        timeframe: timeframes,
        roadmap: roadmaps,
        product1,
      };

      let selectedMetadata = {};

      if (tableSelectedLevel === TABLE_SELECTED_LEVELS.first && selectedGroupByLvl1) {
        selectedMetadata = metadata[selectedGroupByLvl1.key];
      } else if (tableSelectedLevel === TABLE_SELECTED_LEVELS.second && selectedGroupByLvl2) {
        selectedMetadata = metadata[selectedGroupByLvl2.key];
      }

      return selectedMetadata;
    },
  );
};

export const makeProjectsSelected = () => {
  const _selectProjects = makeSelectFilteredProjectsByRoadmapForPage();

  return createSelector(
    state => {
      const displayLayer = getDisplayLayer(state);

      return _selectProjects(state, PROGRESS_PAGE, false, displayLayer);
    },
    (_, progressPagePreferences) => progressPagePreferences,
    (projects, progressPagePreferences) => {
      const { tableSelectedLevel = 1, level1ClickedId = null, level2ClickedId = null } = progressPagePreferences;

      const result = {};

      if (tableSelectedLevel === TABLE_SELECTED_LEVELS.third && level1ClickedId && level2ClickedId) {
        projects.forEach(p => {
          result[p.id] = p;
        });
      }

      return result;
    },
  );
};

export const makeGetActiveProject = () => {
  const _selectProjects = makeSelectFilteredProjectsByRoadmapForPage();

  return createSelector(
    state => {
      const displayLayer = getDisplayLayer(state);

      return _selectProjects(state, PROGRESS_PAGE, false, displayLayer);
    },
    (_, progressPagePreferences) => progressPagePreferences,
    (projects, progressPagePreferences) => {
      const { projectActiveId = null } = progressPagePreferences;

      if (!projectActiveId) return {};

      return projects.find(p => p.id === +projectActiveId) || {};
    },
  );
};

const getOperations = state => {
  return state.progress.operations || {};
};

export const isLoadingProgressReportData = createSelector(getOperations, state => isLoading(state, LOAD_PROGRESS_REPORT_DATA));
export const getProgressReportData = createSelector(
  getOperations,
  state => getData(state, LOAD_PROGRESS_REPORT_DATA) || { data: [] },
);
