import React from 'react';
import { ListRowWrapper, RowText } from './styles';

const ListRow = ({ children, onClick }) => {
  return (
    <ListRowWrapper onClick={onClick}>
      <RowText withEllipsis>{children}</RowText>
    </ListRowWrapper>
  );
};

export default ListRow;
