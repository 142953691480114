const getFormattedFormulaDescription = (formula, formulaFields = [], current = 'title', expected = 'key') => {
  let replacedFormula = formula;

  // by sorting by length we replace the longest field first
  // this way ensure custom fields that include the name of regular fields
  // are replaced first
  formulaFields
    .sort((a, b) => b.title.length - a.title.length)
    .forEach(field => {
      if (formula?.includes(field[current])) {
        replacedFormula = replacedFormula.replaceAll(field[current], field[expected]);
      }
    });

  return replacedFormula;
};

export default getFormattedFormulaDescription;
