import React, { useEffect } from 'react';
import { css } from 'styled-components';
import { either, indexBy, isEmpty, isNil, not, pipe, prop } from 'ramda';

import BaseLayout from 'design-system/organisms/BaseLayout/index';
import DraggableGridLayout from 'design-system/organisms/DraggableGridLayout/index';

import HideIfMobile from 'design-system/utils/HideIfMobile';
import pageBackground from 'design-system/atoms/HomeBackground/images/home_background.png';
import { ProjectsListLightboxProvider } from 'hooks/useProjectsListLightbox';
import { PAGE_HEADER_HEIGHT } from 'constants/common';
import { getQueryParamFromUrl } from 'utils/queryParamsUtils';

import { WIDGETS_CONFIG_BY_ID, GRID_LAYOUT_ROW_HEIGHT } from './constants/widgetsConfig';
import Toolbar from './Toolbar';
import CenteredLoading from './components/CenteredLoading';
import usePortfolioOverviewLayout from './hooks/usePortfolioOverviewLayout';
import useSelectedRoadmapOnPortfolioOverview from './hooks/useSelectedRoadmapOnPortfolioOverview';
import useWidgetsOverrideVisibilityConditions from './hooks/useWidgetsOverrideVisibilityConditions';
import { PRODUCT_2_QUERY_PARM, PRODUCT_QUERY_PARM, ROADMAP_QUERY_PARM } from './constants';

const TOOLBAR_HEIGHT = 56;
const PAGE_HEIGHT_OFFSET = PAGE_HEADER_HEIGHT + TOOLBAR_HEIGHT;

const layoutContentStyles = css`
  min-height: calc(100vh - ${PAGE_HEIGHT_OFFSET}px);
  overflow-y: auto;
`;

const isNilOrEmpty = either(isNil, isEmpty);
const isNotNilOrEmpty = pipe(isNilOrEmpty, not);

const mapStateToGridItems = (
  portfolioOverview,
  shouldHideWidget,
  userCanEdit,
  disableEdit,
  onVisibilityChange,
  updateHeightOnLayout,
) => {
  const { id: portfolioOverviewId, state: portfolioOverviewState } = portfolioOverview;
  const { widgets, layout } = portfolioOverviewState;

  const layoutIndexedById = indexBy(prop('i'))(layout);

  const filteredLayout = [];
  const allDragDisabledElements = [];

  const items = widgets
    .map(widget => {
      const shouldHideOrDataIsEmpty = shouldHideWidget(widget.id);
      const widgetIsVisibleOrIsEditing = widget.isVisible || !disableEdit;
      const widgetHasConfig = widget.id in WIDGETS_CONFIG_BY_ID;

      if (!shouldHideOrDataIsEmpty && widgetIsVisibleOrIsEditing && widgetHasConfig) {
        const { widgetComponent: Component, dragDisabledElements } = WIDGETS_CONFIG_BY_ID[widget.id];

        filteredLayout.push(layoutIndexedById[widget.id]);
        allDragDisabledElements.push(...(dragDisabledElements || []));

        return {
          id: widget.id,
          content: (
            <Component
              id={widget.id}
              portfolioOverviewId={portfolioOverviewId}
              isVisible={widget.isVisible}
              userCanEdit={userCanEdit}
              disableEdit={disableEdit}
              onVisibilityChange={() => onVisibilityChange(widget.id, !widget.isVisible)}
              updateHeightOnLayout={updateHeightOnLayout}
            />
          ),
        };
      }
      return null;
    })
    .filter(Boolean);

  return {
    items,
    layout: filteredLayout,
    dragDisabledElements: allDragDisabledElements,
  };
};

const PortfolioOverviewGrid = ({
  portfolioOverview,
  userCanEdit,
  inEditMode,
  handleLayoutChange,
  handleWidgetHeightChange,
  handleWidgetVisibilityChange,
}) => {
  if (isNil(portfolioOverview)) {
    return null;
  }

  const { shouldHide } = useWidgetsOverrideVisibilityConditions(portfolioOverview);

  const { items, layout, dragDisabledElements } = mapStateToGridItems(
    portfolioOverview,
    shouldHide,
    userCanEdit,
    !inEditMode,
    handleWidgetVisibilityChange,
    handleWidgetHeightChange,
  );

  return (
    <DraggableGridLayout
      items={items}
      layout={layout}
      onDragStop={handleLayoutChange}
      isDraggable={inEditMode}
      rowHeight={GRID_LAYOUT_ROW_HEIGHT}
      draggableCancel={dragDisabledElements.join(',')}
    />
  );
};

const PortfolioOverview = () => {
  const {
    userCanEdit,
    inEditMode,
    portfolioOverview,
    isLoadingPortfolioOverview,
    fetchPortfolioOverviewForActiveRoadmap,
    toggleEditMode,
    onOverviewLayoutChange,
    onWidgetHeightChange,
    onWidgetVisibilityChange,
  } = usePortfolioOverviewLayout();

  const { activeRoadmapEntity, handleSelectRoadmap, handleSelectProduct, handleSelectProduct2 } =
    useSelectedRoadmapOnPortfolioOverview();

  useEffect(() => {
    const roadmapIdFromUrl = getQueryParamFromUrl(ROADMAP_QUERY_PARM);
    const product1IdFromUrl = getQueryParamFromUrl(PRODUCT_QUERY_PARM);
    const product2IdFromUrl = getQueryParamFromUrl(PRODUCT_2_QUERY_PARM);

    const entityIdOnUrl = roadmapIdFromUrl || product1IdFromUrl || product2IdFromUrl;
    const hasUrlParams = isNotNilOrEmpty(entityIdOnUrl);

    const urlParamsMatchSelectedRoadmap = hasUrlParams && activeRoadmapEntity?.id === parseInt(entityIdOnUrl);
    const shouldLoadActiveRoadmapEntity = !hasUrlParams;

    const shouldLoadPortfolioOverview = urlParamsMatchSelectedRoadmap || shouldLoadActiveRoadmapEntity;

    if (shouldLoadPortfolioOverview) {
      fetchPortfolioOverviewForActiveRoadmap(activeRoadmapEntity);

      return;
    }

    /*
     * Will select roadmap from url params
     */
    if (roadmapIdFromUrl) {
      handleSelectRoadmap({ id: +roadmapIdFromUrl });
    } else if (product1IdFromUrl) {
      handleSelectProduct({ id: +product1IdFromUrl });
    } else if (product2IdFromUrl) {
      handleSelectProduct2({ id: +product2IdFromUrl });
    }
  }, [activeRoadmapEntity?.id]);

  const handleLayoutChange = (newLayout, local) => onOverviewLayoutChange(newLayout, local);

  const isPortfolioOverviewReady = !isLoadingPortfolioOverview && !isNil(portfolioOverview);

  return (
    <BaseLayout
      contentStyles={layoutContentStyles}
      background={`url(${pageBackground}) bottom/cover;`}
      toolbarComponent={
        <HideIfMobile>
          <Toolbar inEditMode={inEditMode} toggleEditMode={toggleEditMode} userCanEdit={userCanEdit} />
        </HideIfMobile>
      }
      wrapperAttributes={{ tabIndex: -1 }}
    >
      {isPortfolioOverviewReady ? (
        <ProjectsListLightboxProvider>
          <PortfolioOverviewGrid
            portfolioOverview={portfolioOverview}
            userCanEdit={userCanEdit}
            inEditMode={inEditMode}
            handleLayoutChange={handleLayoutChange}
            handleWidgetHeightChange={onWidgetHeightChange}
            handleWidgetVisibilityChange={onWidgetVisibilityChange}
          />
        </ProjectsListLightboxProvider>
      ) : (
        <CenteredLoading />
      )}
    </BaseLayout>
  );
};

export default PortfolioOverview;
