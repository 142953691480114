import axios from 'axios';

import { createThunk } from 'utils/store/thunk';
import { FETCH_RELEASE_DASHBOARDS_PROJECTS } from './types';
import { getEstimatesData } from 'containers/IdeasForecastList/hooks/useProjectsWithEstimates';
import { fetchProjectAssociations } from 'store/projects/actions';
import { defaultTo } from 'ramda';

/**
 * filters the projects that are not of the type milestone
 *
 * @param {Array} searchedProjects - list of projects to be filtered
 * @returns - all projects thar are not of type milestone
 */
const _filterMilestones = (searchedProjects = []) => searchedProjects.filter(sp => sp.type !== 'milestone');

/**
 * fetches the list of projects with specific filters for the release dashboard page
 *
 * @param {*} params - the filters to apply to the projects requests
 */
export const fetchReleaseDashboardsProjects = params => (dispatch, getState) => {
  const result = new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post('/api/projects/search', { ...params });

      const projects = _filterMilestones(response.data?.data || []);

      const searchMetadata = defaultTo({}, response.data?.metadata);

      const associations = { withEstimates: true, withIntegrations: true };

      const { projects: projectsWithAssociations } = await fetchProjectAssociations(
        projects,
        params,
        associations,
        {},
        false,
        null,
        {},
        searchMetadata,
      );

      const projectsWithEstimatesData = getEstimatesData(projectsWithAssociations);

      resolve({ projectsWithEstimatesData });
    } catch (err) {
      console.error(err);
      throw err;
    }
  });

  dispatch(createThunk(FETCH_RELEASE_DASHBOARDS_PROJECTS, result));
};
