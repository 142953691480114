import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import TeamsFilter from './TeamsFilter';
import useOverbookedAndUnderbookedTeams from './useOverbookedAndUnderbookedTeams';

const OVERBOOKED = 'overbooked';
const UNDERBOOKED = 'underbooked';
const TEAM = 'team';

const getTeamsPluralized = (count = 0) => {
  if (count > 1) {
    return pluralize(TEAM);
  }

  return TEAM;
};

const ForecastByHeadcountTeamsFilter = ({
  unselectedOverbookedTeams,
  unselectedUnderbookedTeams,
  onSelectOverbookedTeam,
  onSelectUnderbookedTeam,
  selectedGroup,
}) => {
  const { overbookedTeams, underbookedTeams } = useOverbookedAndUnderbookedTeams(selectedGroup);

  return (
    <Wrapper>
      <TeamsFilter
        teams={overbookedTeams}
        id={OVERBOOKED}
        variant="red"
        label={`${overbookedTeams.length} ${getTeamsPluralized(overbookedTeams.length)} ${OVERBOOKED}`}
        selectedTeams={unselectedOverbookedTeams}
        onSelectTeam={onSelectOverbookedTeam}
      />
      <TeamsFilter
        teams={underbookedTeams}
        id={UNDERBOOKED}
        variant="green"
        label={`${underbookedTeams.length} ${getTeamsPluralized(underbookedTeams.length)} ${UNDERBOOKED}`}
        selectedTeams={unselectedUnderbookedTeams}
        onSelectTeam={onSelectUnderbookedTeam}
      />
    </Wrapper>
  );
};

ForecastByHeadcountTeamsFilter.propTypes = {
  unselectedOverbookedTeams: PropTypes.object.isRequired,
  unselectedUnderbookedTeams: PropTypes.object.isRequired,
  onSelectOverbookedTeam: PropTypes.func.isRequired,
  onSelectUnderbookedTeam: PropTypes.func.isRequired,
  selectedGroup: PropTypes.object.isRequired,
};

const Wrapper = styled.div`
  display: flex;
`;

export default ForecastByHeadcountTeamsFilter;
