import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { FeatureFlags } from '@dragonboat/config';

import useFeatureFlags from 'hooks/useFeatureFlags';
import useSystemFields from 'hooks/useSystemFields';

import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';

import Tabs from '../Tabs';

const ProjectLayersTabs = props => {
  const { selectedOption, onChange, supportedLayers = [IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER] } = props;

  const hasBet = useFeatureFlags([FeatureFlags.HAS_BET]);
  const [getSystemFieldName] = useSystemFields();

  const options = useMemo(() => {
    return [
      { key: IDEA_LAYER, title: `${getSystemFieldName('idea')} Field Mapping` },
      { key: INITIATIVE_LAYER, title: `${getSystemFieldName('initiative')} Field Mapping` },
      ...(hasBet ? [{ key: BET_LAYER, title: `${getSystemFieldName('bet')} Field Mapping` }] : []),
    ].filter(({ key }) => supportedLayers.includes(key));
  }, [hasBet, getSystemFieldName]);

  return <Tabs options={options} selected={selectedOption} onChange={onChange} />;
};

ProjectLayersTabs.propTypes = {
  selectedOption: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ProjectLayersTabs;
