import axios from 'axios';

import {
  FETCH_METRICS,
  FETCH_METRICS_FULFILLED,
  UPDATE_METRIC_BY_ID,
  ADD_METRIC_WITHOUT_SAVE,
  REMOVE_UNSAVED_METRICS,
  REMOVE_UNSAVED_METRIC_VALUES,
  CREATE_METRIC,
  CREATE_METRIC_FULFILLED,
  UPDATE_METRIC_BY_ID_FULFILLED,
  ADD_METRIC_VALUE_WITHOUT_SAVE,
  UPDATE_METRICS_SEARCH_TEXT,
  UPDATE_IS_HIDING_ARCHIVED_METRICS,
  UPDATE_TABLE_VISIBLE_FIELDS,
  UPDATE_METRIC_CLUSTERS_VISIBILITY,
  UPDATE_METRIC_VIEW_MODE_TIME_PERIOD,
  UPDATE_METRIC_CHARTS_LAYOUT,
  UPDATE_METRIC_CHARTS_LAYOUT_EDIT_MODE,
  UPDATE_METRIC_MOAR_VISIBILITY,
  UPDATE_CHART_VISIBLE_LAYERS,
} from './types';

import { CREATE, UPDATE } from 'store/constants/realtimeUpdateTypes';

import { addMetricValue, deleteMetricValue, updateMetricValue } from './thunks';

import throwRequestError from '../utils/throwRequestError';

export const fetchMetrics = () => {
  return dispatch => {
    return axios
      .get('/api/metrics/')
      .then(response => {
        dispatch({
          payload: response.data,
          type: FETCH_METRICS_FULFILLED,
        });

        return response.data;
      })
      .catch(err => {
        dispatch({
          type: FETCH_METRICS,
          payload: err,
          error: err,
        });
      });
  };
};

export const updateMetricById = (metricId, update) => {
  return {
    payload: axios
      .put(`/api/metrics/${metricId}`, update)
      .then(res => res.data)
      .catch(throwRequestError),
    type: UPDATE_METRIC_BY_ID,
  };
};

export const addMetricWithoutSave = metric => ({
  type: ADD_METRIC_WITHOUT_SAVE,
  metric,
});

export const removeUnsavedMetrics = _ => ({
  type: REMOVE_UNSAVED_METRICS,
});

export const addMetricValueWithoutSave = (metricValue, metricId) => ({
  type: ADD_METRIC_VALUE_WITHOUT_SAVE,
  metricValue,
  metricId,
});

export const removeUnsavedMetricValues = () => ({
  type: REMOVE_UNSAVED_METRIC_VALUES,
});

export const createMetric = metric => {
  return dispatch => {
    const payload = axios
      .post('/api/metrics/', metric)
      .then(res => res.data)
      .catch(throwRequestError);

    dispatch({
      payload,
      type: CREATE_METRIC,
    });

    return payload;
  };
};

export const gotMetricsRealtimeUpdate = (type, data) => {
  return dispatch => {
    switch (type) {
      case CREATE:
        return dispatch({
          type: CREATE_METRIC_FULFILLED,
          payload: data,
        });
      // case DELETE:
      //   return dispatch({
      //     type: REMOVE_METRIC_BY_ID,
      //     id: data.id,
      //   });
      case UPDATE:
        return dispatch({
          type: UPDATE_METRIC_BY_ID_FULFILLED,
          payload: data,
        });
      // case BULK_DELETE:
      //   return dispatch({
      //     type: BULK_DELETE_OBJECTIVES_FULFILLED,
      //     payload: data.map(id => String(id)),
      //   });
      default:
    }
  };
};

export const updateSearchText = payload => ({
  type: UPDATE_METRICS_SEARCH_TEXT,
  payload,
  meta: { makesActiveViewDirty: true },
});

export const updateIsHidingArchivedMetrics = payload => ({
  type: UPDATE_IS_HIDING_ARCHIVED_METRICS,
  payload,
  meta: { makesActiveViewDirty: true },
});

export const updateTableVisibleFields = fields => ({
  type: UPDATE_TABLE_VISIBLE_FIELDS,
  payload: fields,
  meta: { makesActiveViewDirty: true },
});

export const updateClustersVisibility = state => {
  return {
    payload: state,
    type: UPDATE_METRIC_CLUSTERS_VISIBILITY,
    meta: { makesActiveViewDirty: true },
  };
};

export const updateMoarVisibility = state => {
  return dispatch => {
    if (state === true) dispatch(updateChartsLayoutEditToggle(false));

    dispatch({
      payload: state,
      type: UPDATE_METRIC_MOAR_VISIBILITY,
    });
  };
};

export const updateChartVisibleLayers = state => {
  return {
    payload: state,
    type: UPDATE_CHART_VISIBLE_LAYERS,
  };
};

export const updateMetricViewTimePeriod = state => {
  return {
    payload: state,
    type: UPDATE_METRIC_VIEW_MODE_TIME_PERIOD,
    meta: { makesActiveViewDirty: true },
  };
};

export const updateChartsLayout = payload => {
  return {
    payload,
    type: UPDATE_METRIC_CHARTS_LAYOUT,
    meta: { makesActiveViewDirty: true },
  };
};

export const updateChartsLayoutEditToggle = payload => {
  return {
    payload,
    type: UPDATE_METRIC_CHARTS_LAYOUT_EDIT_MODE,
  };
};

export const metricActions = {
  addMetricValue,
  deleteMetricValue,
  updateMetricValue,
};
