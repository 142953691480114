import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { bindActionCreators } from 'redux';

import { useIdeasGrid } from 'containers/IdeasList/IdeasList/New/hooks';
import { FORECAST_BY_TIME, FORECAST_BY_TIME_CAPACITY, FORECAST_LIST_FORECAST_BY_OPTION_KEY } from 'constants/forecast';
import { updateProjectEstimateOnGrid, deleteProjectEstimate } from 'store/estimates';
import { getGridConfigValue } from 'store/grids/selectors';
import useProjectsGridDataWithReleaseCapacityAllocation from 'hooks/forecast/useProjectsGridDataWithReleaseCapacityAllocation';
import { isSomeUpdateOnProjectsEstimatesOcurring } from 'store/estimates/selectors';
import { isUpsertReleaseCapacityAllocationLoading } from 'store/releaseCapacityAllocation';
import { useGridRowDataWithUpdateDataLoading } from 'design-system/molecules/AgGridReact-New/hooks';

import useProjectsWithEstimates from './useProjectsWithEstimates';
import useUpdateEstimatesOnForecastList from './useUpdateEstimatesOnForecastList';
import { SHOW_UNCOMMITTED_PROJECTS } from 'constants/grid';
import { INCLUDE_ALL_OPTION } from 'constants/projects';

const emptyArray = [];

/**
 * @function useForecastGrid
 *
 * hook that includes all the specific data and event handlers for the forecast list, also extends from parent hook useIdeasGrid
 *
 * @return {Object}
 */
const useForecastGrid = (
  data = emptyArray,
  {
    parentHandleGridReady,
    displayLayer,
    portfolioMode,
    currentUser,
    hasBet,
    defaultPhase,
    expandedGroups = emptyArray,
    columnsState,
    viewType,
    metadata,
    selectedGroup2,
    selectedGroup1,
    shouldGridBeUnmounted,

    saveGridConfig,
    getSystemFieldName,
    createUnsavedProject,
    openProjectInfo,
    openOkrInfo,
    setSelectedItems,
    updateProjectById,
    loadParentProjects,
    saveColumnsState,
  },
) => {
  const {
    processedData,

    getGridIsReady,
    getGridApi,

    handleGridReady,
    handleRowGroupOpened,
    handleSelectionChanged,
    handleDisplayedColumnsChanged,
    handleColumnResized,
    checkIsGroupOpenByDefault,
    addNewInlineProject,
    openRowInfo,
    updateProjectByIdOnCellValueChange: updateProjectByIdOnCellValueChangeFromIdeasGrid,
    loadParentProjectsOptions,
    handleMetadataCellDoubleClick,
  } = useIdeasGrid(data, {
    parentHandleGridReady,
    displayLayer,
    portfolioMode,
    currentUser,
    hasBet,
    defaultPhase,
    expandedGroups,
    columnsState,
    viewType,
    shouldGridBeUnmounted,

    saveGridConfig,
    getSystemFieldName,
    createUnsavedProject,
    openProjectInfo,
    openOkrInfo,
    setSelectedItems,
    updateProjectById,
    loadParentProjects,
    saveColumnsState,
  });
  const dispatch = useDispatch();

  const hasSomeUpdateOnProjectsEstimatesOcurring = useSelector(isSomeUpdateOnProjectsEstimatesOcurring);
  const isReleaseCapacityAllocationUpdating = useSelector(isUpsertReleaseCapacityAllocationLoading);
  const includeCommittedSelectionOption = useSelector(state =>
    getGridConfigValue(state, viewType, SHOW_UNCOMMITTED_PROJECTS, INCLUDE_ALL_OPTION),
  );

  const isSomeUpdateOcurringOnEstimatesOrReleaseCapacityAllocation =
    hasSomeUpdateOnProjectsEstimatesOcurring || isReleaseCapacityAllocationUpdating;

  const { rowData } = useGridRowDataWithUpdateDataLoading(
    processedData,
    isSomeUpdateOcurringOnEstimatesOrReleaseCapacityAllocation,
    { getGridApi },
  );

  const { teams, skills } = metadata;

  const forecastBy = useSelector(state =>
    getGridConfigValue(state, viewType, FORECAST_LIST_FORECAST_BY_OPTION_KEY, FORECAST_BY_TIME),
  );

  const projectsDataWithEstimates = useProjectsWithEstimates(rowData, { forecastBy, includeCommittedSelectionOption });

  const projectsData = useProjectsGridDataWithReleaseCapacityAllocation(
    projectsDataWithEstimates,
    selectedGroup1,
    selectedGroup2,
    forecastBy === FORECAST_BY_TIME_CAPACITY,
  );

  const { deleteProjectEstimate: deleteProjectEstimateAction, updateProjectEstimateOnGrid: updateProjectEstimateAction } =
    useMemo(() => {
      return bindActionCreators(
        {
          updateProjectEstimateOnGrid,
          deleteProjectEstimate,
        },
        dispatch,
      );
    }, [dispatch]);

  const { onEstimateChangeHandler } = useUpdateEstimatesOnForecastList({
    teams,
    skills,
    forecastBy,

    deleteProjectEstimate: deleteProjectEstimateAction,
    updateProjectEstimate: updateProjectEstimateAction,
  });

  const updateProjectByIdOnCellValueChange = useCallback(
    async (id, updatedData, previousValue, params) => {
      const {
        colDef: { isEstimateColumn },
      } = params;

      // if is a estimate update / create or delete the estimate
      // else is a project field update the project
      if (isEstimateColumn) {
        return onEstimateChangeHandler(id, updatedData, previousValue, params);
      }

      return updateProjectByIdOnCellValueChangeFromIdeasGrid(id, updatedData, previousValue, params);
    },
    [dispatch, updateProjectByIdOnCellValueChangeFromIdeasGrid, teams, skills],
  );

  return {
    processedData: projectsData,
    forecastBy,

    getGridIsReady,
    getGridApi,

    handleGridReady,
    handleRowGroupOpened,
    handleSelectionChanged,
    handleDisplayedColumnsChanged,
    handleColumnResized,
    checkIsGroupOpenByDefault,
    addNewInlineProject,
    openRowInfo,
    updateProjectByIdOnCellValueChange,
    loadParentProjectsOptions,
    handleMetadataCellDoubleClick,
  };
};

export default useForecastGrid;
