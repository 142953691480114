import React from 'react';

import { materialColorsAlt } from 'design-system/themes/default';
import { statusColors } from 'utils';
import DoughnutChart from 'components/ReactChart/DoughnutChart';
import { HEALTH_LABEL_OPTIONS_LABELS } from 'constants/projects';

const getHealthLabelForStatusColor = statusColor => {
  const color = statusColor || 'Undefined';

  return HEALTH_LABEL_OPTIONS_LABELS[color];
};

export default function SnapshotItemPie({ entity, noTitle, ...props }) {
  const mapStatusColors = {
    green: 'lightGreen',
    blue: 'lightBlue',
    '': 'darkGray',
  };

  const mapStatusColorFunc = c => mapStatusColors[c.value.toLowerCase()] || c.value.toLowerCase();
  const numbIdeasByStatus = {};

  const data = statusColors.map(c => {
    const numIdeas = entity.countersByStatusColor[c.value] || 0;

    numbIdeasByStatus[getHealthLabelForStatusColor(c.label)] = numIdeas;

    if (numIdeas < 0) return 0;

    return numIdeas;
  });

  const colors = statusColors.map(c => materialColorsAlt[mapStatusColorFunc(c)]);
  const labels = statusColors.map(c => getHealthLabelForStatusColor(c.label) || '');

  const pieData = {
    labels,
    ids: labels,
    datasets: [
      {
        data: data.map(v => parseInt(v.toFixed('2'))),
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
  };
  const pieOptions = {
    plugins: {
      legend: {
        display: false,
      },
      title: !noTitle && {
        display: entity.nProjects > 0,
        text: 'Feature Health',
        position: 'bottom',
      },
      tooltip: {
        bodyFont: {
          size: 10,
        },
        bodySpacing: 1,
        callbacks: {
          label: tooltipItem => {
            const status = labels[tooltipItem.dataIndex];
            const value = numbIdeasByStatus[status];

            return `${status}: ${value || '0'}`;
          },
        },
      },
    },
  };

  return <DoughnutChart {...props} data={pieData} options={pieOptions} />;
}
