import isObject from 'lodash/isObject';
import uniqBy from 'lodash/uniqBy';
import { difference, without } from 'ramda';
import {
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_PRODUCTS_2,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
  PERSONAS,
  LIFECYCLES,
} from 'constants/common';
import { getAllColumnsFromAgGridColumnState } from 'utils/grids/helpers';

const ESTIMATES_KEY = 'estimates';
const PDLC_KEY = 'pdlc';
const REACH_KEY = 'reach_score';
const IMPACT_KEY = 'impact_score';
const CONFIDENCE_KEY = 'confidence_score';
const EFFORT_KEY = 'effort_score';
const MONTHS_TO_KEY = 'value_offset_in_months';
const SCOPE_VARIANCE_KEY = 'scopeVariance';
const PLANNED_SCOPE_KEY = 'planned_scope_in_weeks';
const PLANNED_POINTS_KEY = 'plannedPoints';
const POINTS_VARIANCE_KEY = 'pointsVariance';
const COMPLETED_POINTS_KEY = 'completedPoints';
const REPORTED_ALLOCATION_KEY = 'reportedAllocation';
const COMPLETED_ALLOCATION_KEY = 'completedAllocation';
const SUM_KEY = 'sum';

export const CUSTOM_FIELDS_KEY = 'customFields';
export const GROUP_MULTISELECT = 'multiselect';
export const GROUP_CHECKBOXES = 'checkboxes';

/**
 *
 * @param {*} ideasGridColumns
 * @param {*} gridState
 */
export const getGridColumnsForDialog = (
  ideasGridColumns,
  gridState,
  customFields,
  integrations = [],
  organization,
  isDodActive,
  getSystemFieldName,
  showPdlcFields = false,
  showEstimatesFields = true,
  showAdditionalFields = true,
  showCustomFields = true,
  hasMetadataMultiSelect = true,
  currentPageIsEstimates = false,
  hasCompletedAllocation,
  hasExternalKey,
) => {
  // get grid columns for columns visibility dialog
  const { columnState } = gridState || {};

  const allColumnsOnGrid = getAllColumnsFromAgGridColumnState(columnState);

  const sortedIdeasGridColumns = ideasGridColumns.sort(makeSortByGridColumns(allColumnsOnGrid));

  const gridColumns = sortedIdeasGridColumns
    .filter(column => column && column.field !== 'row_order')
    .map(column => {
      let visible = false;

      if (columnState) {
        const col = columnState.find(c => c.colId === column.field);

        visible = col && !col.hide;
      }

      return {
        ...column,
        visible,
      };
    });

  const CUSTOM_FIELDS = customFields.map(({ key }) => key);

  const GROUPED_FIELDS = [
    {
      type: GROUP_MULTISELECT,
      title: 'Summary Fields',
      fields: [
        ...(hasMetadataMultiSelect ? [ADDITIONAL_TEAMS_2, ADDITIONAL_TEAMS] : []),
        'id',
        'title',
        organization.has_hierarchy ? 'parent' : '',
        'ownerName',
        'user7Name',
        'user6Name',
        'user5Name',
        'user4Name',
        'user3Name',
        'user2Name',
        'user1Name',
        ...(hasMetadataMultiSelect ? [ADDITIONAL_CATEGORIES] : []),
        'categoryTitle',
        ...(hasMetadataMultiSelect ? [ADDITIONAL_THEMES] : []),
        'themeTitle',
        ...(hasMetadataMultiSelect ? [ADDITIONAL_TIMEFRAMES_2, ADDITIONAL_TIMEFRAMES] : ''),
        organization.has_multi_level_portfolio_metadata ? 'timeframe2Title' : '',
        'timeframeTitle',
        isDodActive ? 'timeframeCorpTitle' : '',
        ...(hasMetadataMultiSelect ? [ADDITIONAL_PRODUCTS_2, ADDITIONAL_PRODUCTS, ADDITIONAL_ROADMAPS] : []),
        'product2Title',
        'product1Title',
        'roadmapTitle',
        isDodActive ? 'roadmapCorpTitle' : '',
        ...(hasMetadataMultiSelect ? [ADDITIONAL_KEY_RESULTS_2, ADDITIONAL_KEY_RESULTS, ADDITIONAL_OBJECTIVES] : []),
        organization.has_key_results_2 ? 'keyResult2Title' : '',
        'keyResult1Title',
        'objectiveTitle',
        isDodActive ? 'objectiveCorpTitle' : '',
        'priorityTitle',
        'resourceTeamTitle',
        'blocksTotal',
        'blockedByTotal',
        ...(hasExternalKey ? ['external_key', 'external_parent_key'] : []),
      ].filter(v => v),
    },

    ...(showAdditionalFields
      ? [
          {
            type: GROUP_MULTISELECT,
            title: 'Additional Fields',
            fields: [
              'end_date_estimates',
              'start_date_estimates',
              'committed',
              'okr_progress',
              'metric_target',
              'metric_current',
              'metrics',
              'metric_name',
              'timeEstimate',
              'storyPoint',
              'issueCounts',
              'updatedByName',
              'notes',
              // 'updated_at',
              // organization.has_hierarchy ? 'layer' : '',
              'tags',
              'customers',
              'actions',
              'planningStage',
              'phaseTitle',
              'progress',
              'status_summary',
              'status_color',
              'predictedEndDate',
              'deadline',
              'estimated_start_date',
              'completed_date',
              integrations.length > 0 ? 'integration' : '',
              integrations.length > 0 ? 'integrationIssueType' : '',
              'links',
              'details',
              PERSONAS,
              LIFECYCLES,
            ].filter(v => v),
          },
        ]
      : []),
    {
      type: GROUP_MULTISELECT,
      title: 'Prioritization Related Fields',
      fields: [
        { headerName: 'Total requests', fields: ['customerRequestsCount'] },
        { headerName: '# of customers', fields: ['customersCount'] },
        { headerName: 'Inactive value', fields: ['inactiveRevenue'] },
        { headerName: 'Active value', fields: ['activeRevenue'] },
        { headerName: 'Total value', fields: ['totalRevenue'] },
        // estimates related fields (not estimates columns) are
        // duplicated on 'Prioritization Related Fields' and 'Estimation Fields'
        SCOPE_VARIANCE_KEY,
        PLANNED_SCOPE_KEY,
        REPORTED_ALLOCATION_KEY,
        ...(hasCompletedAllocation ? [COMPLETED_ALLOCATION_KEY] : []),
        REACH_KEY,
        IMPACT_KEY,
        CONFIDENCE_KEY,
        EFFORT_KEY,
        SUM_KEY,
        PLANNED_POINTS_KEY,
        COMPLETED_POINTS_KEY,
        POINTS_VARIANCE_KEY,
        { headerName: getSystemFieldName(MONTHS_TO_KEY), fields: ['value_offset_in_months'] },
        { headerName: 'Benefit', fields: ['business_value'] },
        { headerName: 'SUFC', fields: ['benefit1', 'benefit2', 'benefit3', 'cost1', 'priorityScore'] },
        { headerName: 'Planned MoAR', fields: ['planned_moar'] },
        {
          headerName: 'RICE Formula',
          fields: ['reach_score', 'impact_score', 'confidence_score', 'effort_score', 'riceScore'],
        },
        { headerName: 'RICE Score', fields: ['riceScore'] },
      ],
    },
    ...(showEstimatesFields
      ? [
          {
            // On estimates list show as checkbox to mantain the teams / skills hierarchy
            type: currentPageIsEstimates ? GROUP_CHECKBOXES : GROUP_MULTISELECT,
            key: ESTIMATES_KEY,
            title: 'Estimation Fields',
            fields: [
              SCOPE_VARIANCE_KEY,
              PLANNED_SCOPE_KEY,
              REPORTED_ALLOCATION_KEY,
              EFFORT_KEY,
              SUM_KEY,
              PLANNED_POINTS_KEY,
              COMPLETED_POINTS_KEY,
              POINTS_VARIANCE_KEY,
            ],
          },
        ]
      : []),
    ...(showPdlcFields
      ? [
          {
            type: GROUP_CHECKBOXES,
            title: `${getSystemFieldName(PDLC_KEY)} Fields`,
            key: PDLC_KEY,
            fields: [],
          },
        ]
      : []),
    ...(showCustomFields
      ? [
          {
            type: GROUP_MULTISELECT,
            title: 'Custom Fields',
            key: CUSTOM_FIELDS_KEY,
            fields: CUSTOM_FIELDS,
          },
        ]
      : []),
  ];

  const _getGridField = f => {
    if (typeof f === 'string') {
      return gridColumns.find(c => c.field === f);
    } else if (isObject(f)) {
      const fieldsVisible = f.fields
        .map(c => gridColumns.find(gc => gc.field === c))
        .filter(c => !!c)
        .map(c => c.visible);

      const isVisble = fieldsVisible.length === fieldsVisible.filter(c => c === true).length;

      return { field: f.fields, headerName: f.headerName, visible: isVisble };
    }

    return null;
  };

  const _generateGroupFieldsForGroupWithChildren = (childrenCols, parentsCols) => {
    const childrenFields = parentsCols.filter(Boolean).map(p => {
      const children = childrenCols
        .filter(col => col.parent && col.parent.field === p.field)
        .map(col => _getGridField(col.field))
        .sort(makeSortByGridColumns(allColumnsOnGrid));

      return {
        ...p,
        children,
        field: children.map(col => col.field),
        visible: children.some(col => col.visible),
      };
    });

    return childrenFields;
  };

  const generateGroupFields = group => {
    const fields = group.fields.map(_getGridField).filter(f => !!f);

    if (group.key === ESTIMATES_KEY) {
      const estimatesCols = sortedIdeasGridColumns.filter(column => {
        return column.isEstimateColumn;
      });
      const estimatesParents = uniqBy(
        estimatesCols.map(column => column.parent),
        'field',
      );

      return [..._generateGroupFieldsForGroupWithChildren(estimatesCols, estimatesParents), ...fields];
    } else if (group.key === PDLC_KEY) {
      const pdlcColumns = sortedIdeasGridColumns.filter(column => column.field && column.field.match(/deliverable_\d/));
      const pdlcColumnsParents = uniqBy(
        pdlcColumns.map(column => column.parent),
        'field',
      );

      return [..._generateGroupFieldsForGroupWithChildren(pdlcColumns, pdlcColumnsParents), ...fields];
    }

    return fields;
  };

  return GROUPED_FIELDS.map(g => ({
    ...g,
    fields: generateGroupFields(g).filter(col => !!col.headerName),
  }));
};

const SPLIT_GROUP_COLUMNS_CHAR = '##';

/**
 * Get the key for a multiselect field. If the field is an array, it joins its elements with underscores.
 *
 * @param {string|string[]} field - The field or an array of field values.
 * @returns {string} The key for the multiselect field.
 */
const getFieldKeyForMultiselect = field => (Array.isArray(field) ? field.join(SPLIT_GROUP_COLUMNS_CHAR) : field);

/**
 * Get the column that changed its visibility status within a set of group fields.
 *
 * @param {Array} groupFields - An array of group fields.
 * @param {Array} visibleGroupFieldsKeys - An array of keys representing visible group fields.
 * @param {Array} updatedFieldsKeys - An array of keys representing updated fields.
 * @returns {Object|null} The group field that corresponds to the changed column's key, or null if not found.
 */
const getColumnChangedForVisibility = (groupFields, visibleGroupFieldsKeys, updatedFieldsKeys) => {
  const [columnToHideKey] = difference(visibleGroupFieldsKeys, updatedFieldsKeys);
  const [columnToShowKey] = without(visibleGroupFieldsKeys, updatedFieldsKeys);
  const columnChangedKey = columnToHideKey || columnToShowKey;

  return groupFields.find(c => getFieldKeyForMultiselect(c.field) === columnChangedKey);
};

/**
 * Creates a comparator function for sorting data objects based on the order of columns.
 *
 * @param {Array} gridColumns - An array containing the keys of columns.
 * @returns {Function} A comparator function that can be used for sorting data objects.
 *
 * Some columns may have their field as an Array, and this function will get the first element from the array
 * to use it as a reference for ordering the group of columns.
 *
 * @example
 * const visibleCols = ["name", "age", "city"];
 * const sortByCols = makeSortByGridColumns(gridColumns);
 * const data = [
 *   { field: "age" },
 *   { field: ["city", "state"] },
 *   { field: "name" },
 * ];
 * data.sort(sortByCols); // Sorts the 'data' array based on 'visibleCols' order.
 */
const makeSortByGridColumns = gridColumns => (a, b) => {
  const aField = Array.isArray(a.field) ? a.field[0] : a.field;
  const bField = Array.isArray(b.field) ? b.field[0] : b.field;

  return gridColumns.findIndex(f => aField === f) - gridColumns.findIndex(f => bField === f);
};

export { getFieldKeyForMultiselect, getColumnChangedForVisibility, makeSortByGridColumns };
