import axios from 'axios';
import throwRequestError from 'store/utils/throwRequestError';
import {
  MERGE_SKILLS,
  DELETE_SKILL,
  CREATE_SKILLS,
  UPDATE_SKILLS,
  UNDO_CREATE_SKILLS,
  UNDO_UPDATE_SKILLS,
  BULK_DELETE_SKILLS,
  UNDO_BULK_DELETE_SKILLS,
  SWITCH_SKILLS_ROW_ORDER,
} from './types';
import bulkCreateAction from 'store/utils/factory/bulkCreateAction';
import bulkUpdateAction from 'store/utils/factory/bulkUpdateAction';
import bulkDeleteAction from 'store/utils/factory/bulkDeleteAction';
import moveRowToPosition from 'utils/moveRowToPosition';

export function switchSkillsRowOrder(id1, id2, _, position) {
  return (dispatch, getState) => {
    const state = getState().skills.skills || [];
    const prevData = state.find(({ id }) => id === +id1);
    const movedRow = moveRowToPosition(state, id1, id2, position);

    const promise = axios.put(`/api/skills/rowOrder/${id1}/${id2}`, { position }).then(res => res.data);

    return dispatch({
      type: SWITCH_SKILLS_ROW_ORDER,
      payload: {
        promise,
        data: movedRow,
      },
      meta: { prev: prevData },
    });
  };
}

/**
 *
 * @param {*} skillsIdsToMerge
 * @param {*} categoryId
 */
export function mergeSkills(skillsIdsToMerge, categoryId) {
  return dispatch => {
    const payload = axios
      .post(`/api/skills/merge/${categoryId}`, {
        skillsIdsToMerge,
      })
      .then(response => {
        return response.data;
      })
      .catch(throwRequestError);

    dispatch({
      type: MERGE_SKILLS,
      payload,
    });

    return payload;
  };
}

/**
 *
 * @param {*} skillId
 */
export const deleteSkill = skillId => {
  return dispatch => {
    const payload = axios
      .delete(`/api/skills/${skillId}`)
      .then(res => res.data)
      .catch(throwRequestError);

    dispatch({
      payload,
      type: DELETE_SKILL,
    });

    return payload;
  };
};

export const bulkCreateSkills = bulkCreateAction(CREATE_SKILLS, '/api/skills', {
  toastText: 'Skills have been created',
  ACTION_TYPE: UNDO_CREATE_SKILLS,
  endpoint: '/api/skills/versions/last',
  store: 'skills',
});
export const bulkUpdateSkills = bulkUpdateAction(UPDATE_SKILLS, '/api/skills', {
  toastText: 'Skills have been changed',
  ACTION_TYPE: UNDO_UPDATE_SKILLS,
  endpoint: '/api/skills/versions/last',
  store: 'skills',
});
export const bulkDeleteSkills = bulkDeleteAction(BULK_DELETE_SKILLS, '/api/skills/', {
  toastText: 'Skills have been deleted',
  ACTION_TYPE: UNDO_BULK_DELETE_SKILLS,
  endpoint: '/api/skills/versions/last',
  store: 'skills',
});
