import React from 'react';
import styled from 'styled-components';

import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';

const DialogHeaderBorderless = ({ children, title, className }) => {
  return (
    <Container className={className}>
      {title ? <Text as="h3">{title}</Text> : null}
      {children}
    </Container>
  );
};

export default DialogHeaderBorderless;

const Container = styled.div`
  display: flex;
  width: 95%;
  min-height: ${spacing(4)}px;
`;
