import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import theme from 'design-system/theme';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import Button from 'design-system/atoms/Button/index';

import Stars from 'design-system/atoms/DragonIcons/Stars';

const Header = ({ onClose, onSavePlan, generateNewPlan, selectedPlanningLabel }) => (
  <Container>
    <Flex>
      <StyledStarsIcon />
      <StyledText>
        New AI Plan: <b>{selectedPlanningLabel}</b>
      </StyledText>
    </Flex>
    <Flex>
      <StyledButton
        color={theme.palette.newLayout.background.white}
        hover={{ background: theme.palette.newLayout.background.white, color: theme.palette.newLayout.background.dodgerBlue }}
        fontSize={theme.typography.fontSize}
        width="135px"
        onClick={onSavePlan}
      >
        Save Plan
      </StyledButton>
      <StyledButton
        color={theme.palette.newLayout.background.white}
        hover={{ background: theme.palette.newLayout.background.white, color: theme.palette.newLayout.background.dodgerBlue }}
        fontSize={theme.typography.fontSize}
        width="200px"
        onClick={generateNewPlan}
      >
        Generate New Plan
      </StyledButton>
      <IconButton onClick={onClose}>
        <StyledCloseIcon />
      </IconButton>
    </Flex>
  </Container>
);

export default Header;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ theme }) => theme.spacing.unit * 10}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `0 ${theme.spacing.unit * 6}px`};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.unit * 2}px;
`;

const StyledStarsIcon = styled(Stars)`
  width: 27px;
  height: 27px;

  path {
    fill: ${({ theme }) => theme.palette.newLayout.background.white};
  }
`;

const StyledText = styled(TextDeprecated)`
  &&&& {
    color: ${({ theme }) => theme.palette.newLayout.background.white};
    font-size: ${({ theme }) => theme.typography.fontSizeH4}px;

    b {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
      margin-left: ${({ theme }) => theme.spacing.unit}px;
    }
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    text-transform: uppercase;
    border: ${({ theme }) => `1px solid ${theme.palette.newLayout.background.white}`};
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.palette.newLayout.background.white};
`;
