import { defaultTo, flatten, isEmpty, isNil, pathOr } from 'ramda';
import { createSelector } from 'reselect';

import { getData, isUninitialized, isLoading } from 'utils/store/thunk';
import getActiveOKRWithHierarchy from 'utils/getActiveOKRWithHierarchy';
import { getOrganization } from 'store/organization/selectors';
import { getPageFilters } from 'store/filters/selectors';
import { METADATA_LEVELS } from 'constants/common';
import { getProductsLevelTwo, getRoadmaps } from 'store/roadmaps/selectors';

import {
  FETCH_PORTFOLIO_OVERVIEW_SUB_ROADMAPS,
  FETCH_PORTFOLIO_OVERVIEW_OKRS,
  FETCH_PORTFOLIO_OVERVIEW_METRICS,
  FETCH_PORTFOLIO_OVERVIEW_BY_ROADMAP,
  FETCH_PORTFOLIO_OVERVIEW_HISTORY,
} from './types';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

// Slice state
export const getState = state => state.portfolioOverview;

// Operations
export const selectOperationsState = state => getState(state).operations || {};

export const selectPortfolioOverviewSubRoadmapsData = state =>
  getData(selectOperationsState(state), FETCH_PORTFOLIO_OVERVIEW_SUB_ROADMAPS) || [];
export const selectIsUninitializedFetchPortfolioOverviewSubRoadmaps = state =>
  isUninitialized(selectOperationsState(state), FETCH_PORTFOLIO_OVERVIEW_SUB_ROADMAPS);
export const selectIsloadingFetchPortfolioOverviewSubRoadmaps = state =>
  isLoading(selectOperationsState(state), FETCH_PORTFOLIO_OVERVIEW_SUB_ROADMAPS);

export const selectPortfolioOverviewSubRoadmapsDataEmpty = state => {
  const data = selectPortfolioOverviewSubRoadmapsData(state);
  const isUninitialized = selectIsUninitializedFetchPortfolioOverviewSubRoadmaps(state);
  const isLoading = selectIsloadingFetchPortfolioOverviewSubRoadmaps(state);

  return !isUninitialized && !isLoading && isEmpty(data);
};

export const selectPortfolioOverviewOkrsData = state => getState(state).objectives || [];
export const selectPortfolioOverviewOkrsWithHierarchy = createSelector(
  state => getState(state).objectives || [],
  state => getOrganization(state).has_key_results,
  state => getOrganization(state).has_key_results_2,
  (objectives, hasKeyResults, hasKeyResults2) => {
    const keysResults = objectives.reduce((acc, okr) => {
      return [...acc, ...okr.keyResults];
    }, []);

    return getActiveOKRWithHierarchy(objectives, hasKeyResults, hasKeyResults2, keysResults);
  },
);

export const selectIsUninitializedFetchPortfolioOverviewOkrs = state =>
  isUninitialized(selectOperationsState(state), FETCH_PORTFOLIO_OVERVIEW_OKRS);
export const isLoadingFetchPortfolioOverviewOkrs = state =>
  isLoading(selectOperationsState(state), FETCH_PORTFOLIO_OVERVIEW_OKRS);

export const selectIsPortfolioOverviewOkrsDataEmpty = state => {
  const data = selectPortfolioOverviewOkrsData(state);
  const isUninitialized = selectIsUninitializedFetchPortfolioOverviewOkrs(state);
  const isLoading = isLoadingFetchPortfolioOverviewOkrs(state);

  return !isUninitialized && !isLoading && isEmpty(data);
};

export const selectPortfolioOverviewMetricsData = state => getState(state).metrics || [];
export const selectIsUninitializedFetchPortfolioOverviewMetrics = state =>
  isUninitialized(selectOperationsState(state), FETCH_PORTFOLIO_OVERVIEW_METRICS);
export const selectIsLoadingFetchPortfolioOverviewMetrics = state =>
  isLoading(selectOperationsState(state), FETCH_PORTFOLIO_OVERVIEW_METRICS);

export const selectIsPortfolioOverviewMetricsDataEmpty = state => {
  const data = selectPortfolioOverviewMetricsData(state);
  const isUninitialized = selectIsUninitializedFetchPortfolioOverviewMetrics(state);
  const isLoading = selectIsLoadingFetchPortfolioOverviewMetrics(state);

  return !isUninitialized && !isLoading && isEmpty(data);
};

export const selectPortfolioOverviewMetricById = (state, id) => {
  const { metrics } = getState(state);

  return metrics.find(m => m.id === +id);
};

export const selectPortfolioOverviewForActiveRoadmap = state => {
  const portfolioOverviewState = getState(state);

  return portfolioOverviewState.portfolioOverviewForActiveRoadmap;
};
export const selectIsLoadingPortfolioOverviewForActiveRoadmap = state =>
  isLoading(selectOperationsState(state), FETCH_PORTFOLIO_OVERVIEW_BY_ROADMAP);

export const selectActiveRoadmapOrProduct = createSelector(
  getRoadmaps,
  getProductsLevelTwo,
  state => getPageFilters(state)?.quickFilters?.roadmaps,
  state => getPageFilters(state)?.quickFilters?.products,
  state => getPageFilters(state)?.quickFilters?.products2,
  (roadmaps, products2, roadmapsQuickfilters, productsQuickfilters, products2Quickfilters) => {
    if (roadmapsQuickfilters?.length) {
      const roadmap = roadmaps.find(r => r.id === roadmapsQuickfilters[0]);

      if (!isNil(roadmap)) {
        return {
          id: roadmap.id,
          type: roadmap.is_corp_roadmap ? METADATA_LEVELS.LEVEL_CORP : METADATA_LEVELS.LEVEL_1,
          entity: roadmap,
        };
      }
    }

    if (productsQuickfilters?.length) {
      const allProducts = flatten(roadmaps.map(r => r.products || []));

      const product = allProducts.find(child => child.id === productsQuickfilters[0]);
      const roadmap = roadmaps.find(r => r.id === product?.roadmap_id);

      if (!isNil(product)) {
        return {
          id: product.id,
          type: METADATA_LEVELS.LEVEL_2,
          entity: product,
          parentRoadmap: roadmap,
        };
      }
    }

    if (products2Quickfilters?.length) {
      const allProducts = flatten(roadmaps.map(r => r.products || []));
      const product2 = products2.find(child => child.id === products2Quickfilters[0]);
      const parentProduct = allProducts.find(child => child.id === product2.parent_id);
      const roadmap = roadmaps.find(r => r.id === product2?.roadmap_id);

      if (!isNil(product2)) {
        return {
          id: product2.id,
          type: METADATA_LEVELS.LEVEL_3,
          entity: product2,
          parentProduct,
          parentRoadmap: roadmap,
        };
      }
    }

    return null;
  },
);

export const selectIsInEditMode = state => getState(state).isInEditMode;

const defaultToEmptyObject = defaultTo(EMPTY_OBJECT);

export const getPortfolioOverviewHistoryState = state => defaultToEmptyObject(getState(state)?.portfolioOverviewHistory);

export const selectPortfolioOverviewHistory = (state, id) => {
  const portfolioOverviewHistory = getPortfolioOverviewHistoryState(state);

  return pathOr(EMPTY_ARRAY, [id, 'history'])(portfolioOverviewHistory);
};

export const selectPortfolioOverviewHistoryNextPage = (state, id) => {
  const portfolioOverviewHistory = getPortfolioOverviewHistoryState(state);

  return pathOr(null, [id, '_meta', 'next'])(portfolioOverviewHistory);
};

export const selectIsLoadingHistory = state => isLoading(selectOperationsState(state), FETCH_PORTFOLIO_OVERVIEW_HISTORY);
