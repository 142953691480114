import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_TEMPLATES'),
  ...getThunkActionTypes('FETCH_TEMPLATES_CATEGORIES'),

  SET_ACTIVE_TEMPLATE: 'SET_ACTIVE_TEMPLATE',
  SET_ACTIVE_TEMPLATE_CATEGORY: 'SET_ACTIVE_TEMPLATE_CATEGORY',
};

export const {
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_PENDING,
  FETCH_TEMPLATES_FULFILLED,
  FETCH_TEMPLATES_REJECTED,
  FETCH_TEMPLATES_CATEGORIES,
  FETCH_TEMPLATES_CATEGORIES_PENDING,
  FETCH_TEMPLATES_CATEGORIES_FULFILLED,
  FETCH_TEMPLATES_CATEGORIES_REJECTED,
  SET_ACTIVE_TEMPLATE,
  SET_ACTIVE_TEMPLATE_CATEGORY,
} = actionTypes;

export default actionTypes;
