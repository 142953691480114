export const TIMEFRAME_STATE_CHANGE_ACTION = 'TIMEFRAME_STATE_CHANGE';

export const FETCH_TIMEFRAMES = 'FETCH_TIMEFRAMES';
export const FETCH_TIMEFRAMES_FULFILLED = 'FETCH_TIMEFRAMES_FULFILLED';
export const FETCH_TIMEFRAMES_PENDING = 'FETCH_TIMEFRAMES_PENDING';

export const CREATE_TIMEFRAME = 'CREATE_TIMEFRAME';
export const CREATE_TIMEFRAME_FULFILLED = 'CREATE_TIMEFRAME_FULFILLED';

export const UPDATE_TIMEFRAME = 'UPDATE_TIMEFRAME';
export const UPDATE_TIMEFRAME_FULFILLED = 'UPDATE_TIMEFRAME_FULFILLED';

export const DELETE_TIMEFRAMES = 'DELETE_TIMEFRAMES';
export const DELETE_TIMEFRAMES_FULFILLED = 'DELETE_TIMEFRAMES_FULFILLED';

export const ADD_SELECTED_GRID_TIMEFRAME = 'ADD_SELECTED_GRID_TIMEFRAME';
export const REMOVE_SELECTED_GRID_TIMEFRAME = 'REMOVE_SELECTED_GRID_TIMEFRAME';

export const MERGE_TIMEFRAMES = 'MERGE_TIMEFRAMES';
export const MERGE_TIMEFRAMES_FULFILLED = 'MERGE_TIMEFRAMES_FULFILLED';

export const UPDATE_TIMEFRAME_BY_ID = 'UPDATE_TIMEFRAME_BY_ID';
export const UPDATE_TIMEFRAME_BY_ID_FULFILLED = 'UPDATE_TIMEFRAME_BY_ID_FULFILLED';

export const DELETE_TIMEFRAME_BY_ID = 'DELETE_TIMEFRAME_BY_ID';
export const DELETE_TIMEFRAME_BY_ID_FULFILLED = 'DELETE_TIMEFRAME_BY_ID_FULFILLED';

export const ADD_TIMEFRAME_WITHOUT_SAVE = 'ADD_TIMEFRAME_WITHOUT_SAVE';
export const REMOVE_UNSAVED_TIMEFRAME = 'REMOVE_UNSAVED_TIMEFRAME';

export const CREATE_TIMEFRAMES = 'CREATE_TIMEFRAMES';
export const CREATE_TIMEFRAMES_FULFILLED = 'CREATE_TIMEFRAMES_FULFILLED';
export const UPDATE_TIMEFRAMES = 'UPDATE_TIMEFRAMES';
export const UPDATE_TIMEFRAMES_FULFILLED = 'UPDATE_TIMEFRAMES_FULFILLED';
export const BULK_DELETE_TIMEFRAMES = 'BULK_DELETE_TIMEFRAMES';
export const BULK_DELETE_TIMEFRAMES_FULFILLED = 'BULK_DELETE_TIMEFRAMES_FULFILLED';

export const UNDO_CREATE_TIMEFRAMES = 'UNDO_CREATE_TIMEFRAMES';
export const UNDO_CREATE_TIMEFRAMES_FULFILLED = 'UNDO_CREATE_TIMEFRAMES_FULFILLED';
export const UNDO_UPDATE_TIMEFRAMES = 'UNDO_UPDATE_TIMEFRAMES';
export const UNDO_UPDATE_TIMEFRAMES_FULFILLED = 'UNDO_UPDATE_TIMEFRAMES_FULFILLED';
export const UNDO_BULK_DELETE_TIMEFRAMES = 'UNDO_BULK_DELETE_TIMEFRAMES';
export const UNDO_BULK_DELETE_TIMEFRAMES_FULFILLED = 'UNDO_BULK_DELETE_TIMEFRAMES_FULFILLED';

export const UPDATE_TIMEFRAME_ROW_ORDER = 'UPDATE_TIMEFRAME_ROW_ORDER';
export const UPDATE_TIMEFRAME_ROW_ORDER_PENDING = 'UPDATE_TIMEFRAME_ROW_ORDER_PENDING';
export const UPDATE_TIMEFRAME_ROW_ORDER_FULFILLED = 'UPDATE_TIMEFRAME_ROW_ORDER_FULFILLED';
export const UPDATE_TIMEFRAME_ROW_ORDER_REJECTED = 'UPDATE_TIMEFRAME_ROW_ORDER_REJECTED';

export const CREATE_TIMEFRAME_ROADMAP = 'CREATE_TIMEFRAME_ROADMAP';
export const CREATE_TIMEFRAME_ROADMAP_FULFILLED = 'CREATE_TIMEFRAME_ROADMAP_FULFILLED';
export const DELETE_TIMEFRAME_ROADMAP = 'DELETE_TIMEFRAME_ROADMAP';
export const DELETE_TIMEFRAME_ROADMAP_FULFILLED = 'DELETE_TIMEFRAME_ROADMAP_FULFILLED';
export const BULK_DELETE_TIMEFRAME_ROADMAP = 'BULK_DELETE_TIMEFRAME_ROADMAP';
export const BULK_DELETE_TIMEFRAME_ROADMAP_FULFILLED = 'BULK_DELETE_TIMEFRAME_ROADMAP_FULFILLED';
