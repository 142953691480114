import React from 'react';

import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete/index';

import { ToolbarLeftGroupsContainer, TollbarWrapper } from './styles';
import LocalSearch from '../ControlsBar/LocalSearch';

const AnalyzeToolbar = ({
  clusterByOptions,
  clusterByValue,
  handleClusterByChange,
  bubbleSizeByOptions,
  bubbleSizeByValue,
  handleBubbleSizeByChange,
}) => {
  return (
    <TollbarWrapper>
      <ToolbarLeftGroupsContainer>
        <GroupByAutocomplete
          label="Cluster by"
          name="selectedClusterBy"
          suggestions={clusterByOptions}
          value={clusterByValue}
          onChange={handleClusterByChange}
        />
        <GroupByAutocomplete
          label="Bubble size by"
          name="selectedBubbleSizeBy"
          suggestions={bubbleSizeByOptions}
          value={bubbleSizeByValue}
          onChange={handleBubbleSizeByChange}
        />
      </ToolbarLeftGroupsContainer>
      <LocalSearch />
    </TollbarWrapper>
  );
};

export default AnalyzeToolbar;
