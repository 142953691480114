import React, { useCallback } from 'react';
import styled from 'styled-components';

import ProjectsDropdown from 'containers/ProjectsDropdown';

const LinkProjectCellEditor = ({ onSave, topLayer, availableLayers }) => {
  const handleProjectSelection = useCallback(
    (project, { closeTree }) => {
      onSave(project);

      closeTree();
    },
    [onSave],
  );

  return (
    <StyledProjectsDropdown
      componentId="lightbox-children-tab"
      displayLayer={topLayer}
      searchLayers={Object.values(availableLayers)}
      onProjectSelect={handleProjectSelection}
      smallInput
    />
  );
};

export default LinkProjectCellEditor;

const StyledProjectsDropdown = styled(ProjectsDropdown)`
  width: 100%;
`;
