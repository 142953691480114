import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { TokenContainer } from '../styles';

import ConnectButton from 'routes/Settings/Integration/components/ConnectButton';
import RequiredFieldsLabel from './RequiredFieldsLabel';
import { getIntegrationLabel } from 'features/OneStepIntegration/helpers/getIntegrationLabel';

const TokenAuthConfig = ({ integrationConfigs, onClickConnect, isConnecting }) => {
  const hasUsernameField = integrationConfigs.auth.hasTokenUsername;
  const integrationName = integrationConfigs.title;
  const authClientIdLabel = getIntegrationLabel(integrationConfigs, 'authCientId');
  const authClientSecretLabel = getIntegrationLabel(integrationConfigs, 'authClientSecret');

  const [token, setToken] = useState('');
  const [username, setUsername] = useState('');

  const _allValid = () => {
    const validToken = !!token;
    const validUsername = !!username;

    return [validToken, ...(hasUsernameField ? [validUsername] : [])].every(v => v === true);
  };

  return (
    <Grid container>
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <RequiredFieldsLabel
            integrationName={integrationName}
            hasCliendId={hasUsernameField}
            authClientIdLabel={authClientIdLabel}
            authClientSecretLabel={authClientSecretLabel}
          />
        </Grid>
        {hasUsernameField && (
          <TokenContainer item xs={6}>
            <TextField
              id="integration-username-input"
              label={`Enter ${authClientIdLabel}`}
              value={username}
              onChange={e => setUsername(e.target.value)}
              required
              fullWidth
            />
          </TokenContainer>
        )}
        <TokenContainer item xs={6}>
          <TextField
            id="integration-token-input"
            label={`Enter ${authClientSecretLabel}`}
            value={token}
            onChange={e => setToken(e.target.value)}
            required
            fullWidth
          />
        </TokenContainer>
      </Grid>
      <Grid item xs={12}>
        <ConnectButton
          id="connect-integration-button"
          color="primary"
          disabled={!_allValid() || isConnecting}
          onClick={() => {
            const userIntegrationData = { dtoToken: token, username };

            onClickConnect(userIntegrationData);
          }}
        >
          Connect
        </ConnectButton>
      </Grid>
    </Grid>
  );
};

export default TokenAuthConfig;
