import styled from 'styled-components';

import { materialColorsAlt } from 'design-system/themes/default';

export default styled.div`
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: ${materialColorsAlt.darkGray};
  border-radius: 50%;
  vertical-align: middle;
`;
