import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { toast } from 'react-toastify';
import pick from 'lodash/pick';
import isString from 'lodash/isString';
import moment from 'moment-timezone';

import { getUserName } from 'utils';
import { updateProject, createProject, switchProjectRowOrder } from 'store/projects';
import {
  addProjectEstimateDependency,
  addProjectProjectDependency,
  removeProjectProjectDependency,
  removeProjectEstimateDependency,
  addEstimateProjectDependency,
  addEstimateEstimateDependency,
  removeEstimateProjectDependency,
  removeEstimateEstimateDependency,
  addProjectTaskDependency,
  removeProjectTaskDependency,
  addTaskProjectDependency,
  addTaskTaskDependency,
  removeTaskProjectDependency,
  removeTaskTaskDependency,
} from 'store/dependencies/thunks';
import { openProjectLightbox } from 'store/projectLightbox';
import useDeepEffect from 'hooks/useDeepEffect';
import { removeGanttTolltip } from 'components/ganttCommon';
import { getDefaultFormData } from 'constants/projectLightbox';
import { getDisplayLayer } from 'store/filters/selectors';
import useSystemFields from 'hooks/useSystemFields';
import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import getDisplayLayerLabel from 'store/projects/helpers/getDisplayLayerLabel';

const events = [];

const useTimelineProjects = ({
  gantt,
  selectedGroup,
  editConfirmedTasks,
  localMode,
  editAllTasks,
  shouldOpenLightbox,
  afterAddInlineProject,
  portfolioMode,
  showStories,
}) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(state => state.login.currentUser);
  const recentlyUsedData = useSelector(state => state.projects.recentlyUsedData);
  const displayLayer = useSelector(getDisplayLayer);
  const recentlyUsedDataRef = useRef(recentlyUsedData);
  const displayLayerRef = useRef(displayLayer);
  const portfolioModeRef = useRef(portfolioMode);
  const [getSystemFieldName] = useSystemFields();

  recentlyUsedDataRef.current = recentlyUsedData;
  displayLayerRef.current = displayLayer;
  portfolioModeRef.current = portfolioMode;
  const [debouncedUpdateProject] = useDebouncedCallback(proj => dispatch(updateProject(proj)), 250);

  const onBeforeLightbox = id => {
    const task = gantt.isTaskExists(id) ? gantt.getTask(id) : null;
    const isNewTask = task && task.$new && !task.parent;
    const isNewTaskOnGroup = task && task.$new && task.parent && gantt.getTask(task.parent).group;
    const isNewTaskOnParent =
      task && task.$new && task.parent && [INITIATIVE_LAYER, BET_LAYER].includes(gantt.getTask(task.parent).layer);
    const addNewProject = (data = {}) => {
      gantt.deleteTask(task.id);
      if (!shouldOpenLightbox) {
        const newProjectData = {
          ...getDefaultFormData(getSystemFieldName, displayLayerRef.current),
          ...pick(recentlyUsedDataRef.current, ['roadmapTitle']),
          title: `New ${getDisplayLayerLabel(displayLayerRef.current, getSystemFieldName)}`,
          planningStage: 'Planning',
          ownerName: getUserName(currentUser),
          layer: displayLayerRef.current,
          ...data,
        };

        dispatch(createProject(newProjectData)).then(data => {
          if (afterAddInlineProject) afterAddInlineProject();
          // wait for gantt render and set the new project editable
          setTimeout(() => {
            const parentId = gantt.getParent(data.id);
            const parent = gantt.getTask(parentId);

            // eslint-disable-next-line security/detect-non-literal-fs-filename
            if (parent && !parent.$open) gantt.open(parent.id);

            gantt.ext.inlineEditors.startEdit(data.id, 'text');
          }, 1000);
        });
      } else {
        dispatch(openProjectLightbox());
      }
    };

    if (task && task.dbType === 'project') {
      removeGanttTolltip();
      dispatch(openProjectLightbox(task.id));
    } else if (isNewTask) {
      addNewProject();
    } else if (isNewTaskOnGroup) {
      const parent = gantt.getTask(task.parent);
      const data = {
        [`${parent.dbType}Title`]: parseInt(parent.entityId) ? parent.title : null,
      };

      addNewProject(data);
    } else if (isNewTaskOnParent && portfolioModeRef.current) {
      const parent = gantt.getTask(task.parent);

      const inheritedParentData = pick(parent, 'roadmapTitle', 'product1Title', 'objectiveTitle', 'keyResult1Title');

      const data = {
        ...inheritedParentData,
        parent_id: parent.id,
        layer: IDEA_LAYER,
        title: `New ${getSystemFieldName('idea')}`,
      };

      addNewProject(data);
    }

    return false;
  };
  const onAfterTaskUpdate = (id, task) => {
    if (localMode) return true;
    if (!gantt.isTaskExists(id)) return true;

    const ganttTask = gantt.getTask(id);
    const {
      start_date: startDate, // becomes from gantt columns
      end_date: endDate, // becomes from gantt columns
      // estimated_start_date: estimatedStartDate,
      // deadline,
    } = ganttTask;

    if (ganttTask.dbType === 'project') {
      const hasEstimates = (gantt.getChildren(ganttTask.id) || []).some(child => isString(child) && child.includes('estimate'));
      const hasTasks = (gantt.getChildren(ganttTask.id) || []).some(child => isString(child) && child.includes('task'));
      const { columns } = gantt.config;

      const updateDates =
        !hasEstimates &&
        !hasTasks &&
        (!showStories || !(ganttTask.integrationProgress && ganttTask.integrationProgress.issuesTotal > 0));

      const projectUpdated = {
        id: ganttTask.id,
        progress: +ganttTask.progress,
        ...(updateDates ? { estimated_start_date: moment(startDate).parseFromGantt() } : {}),
        ...(updateDates ? { deadline: moment(endDate).parseFromGantt(true) } : {}),
        estimates: ganttTask.estimates,
        ...pick(
          ganttTask,
          columns.filter(c => !!c.editor).map(c => c.editor.map_to || c.name),
        ),
      };

      // if user changes the progress on the grid or on the gantt bar should change
      // the progress_type to manual
      if (+projectUpdated.progressGridValue !== ganttTask.progressOriginal * 100) {
        projectUpdated.progress_type = 'manual';
        projectUpdated.progress = +projectUpdated.progressGridValue / 100;
      } else if (+projectUpdated.progress !== ganttTask.progressOriginal) {
        projectUpdated.progress_type = 'manual';
        projectUpdated.progress = +projectUpdated.progress.toFixed(2);
      }

      debouncedUpdateProject(projectUpdated);
    }
    return true;
  };
  const onBeforeRowDragEnd = (id, lastParent, fromIndex) => {
    if (localMode) return true;

    const task = gantt.getTask(id);
    const toIndex = gantt.getTaskIndex(id);
    let taskIdThatWasInToIndex;
    let position;

    if (task.parent !== lastParent && task.dbType === 'project') {
      let parentTask = task.parent ? gantt.getTask(task.parent) : null;

      let update = {};
      let closeTask;

      taskIdThatWasInToIndex = gantt.getPrevSibling(id);
      closeTask = taskIdThatWasInToIndex ? gantt.getTask(taskIdThatWasInToIndex) : null;
      position = 'bottom';

      if (!closeTask || closeTask.dbType !== 'project') {
        taskIdThatWasInToIndex = gantt.getNextSibling(id);
        closeTask = taskIdThatWasInToIndex ? gantt.getTask(taskIdThatWasInToIndex) : null;
        position = 'top';
      }

      if (!closeTask || closeTask.dbType !== 'project') {
        taskIdThatWasInToIndex = null;
        closeTask = null;
        position = null;
      }

      while (parentTask && !parentTask.dbType && parentTask.parent) {
        parentTask = gantt.getTask(parentTask.parent);
        update = { parent_id: null };
      }

      if (parentTask && parentTask.entityId !== 'null' && parentTask.dbType === 'project') {
        update = { ...update, parent_id: parseInt(parentTask.entityId) };
      } else if (parentTask && parentTask.dbType) {
        const _getGroupKey = key => (['initiative', 'bet'].includes(key) ? 'parent_id' : key);

        const groupKey = _getGroupKey(parentTask.dbType);
        const isCustomField = groupKey.startsWith('custom_fields.');
        const uniformedGroupKey = isCustomField ? groupKey.replace('custom_fields.', '') : groupKey;

        update = {
          ...update,
          [uniformedGroupKey]:
            isCustomField || ['status_color', 'planningStage'].includes(parentTask.dbType)
              ? parentTask.entityId
              : parseInt(parentTask.entityId) || null,
        };

        if (parentTask.group && Array.isArray(selectedGroup)) {
          // update = {};
          const _getGroupTaskUpdate = (t, group) => {
            if (t.dbType === group.field) {
              const groupField = group.field;
              const isCustomField = groupField.startsWith('custom_fields.');
              const uniformedGroupKey = isCustomField ? groupField.replace('custom_fields.', '') : groupField;

              return {
                [uniformedGroupKey]:
                  isCustomField || ['status_color', 'planningStage'].includes(t.dbType)
                    ? t.entityId
                    : parseInt(t.entityId) || null,
              };
            } else if (t.parent) {
              const parent = gantt.getTask(t.parent);

              return _getGroupTaskUpdate(parent, group);
            }

            return {};
          };

          selectedGroup.forEach(group => {
            update = { ...update, ..._getGroupTaskUpdate(parentTask, group) };
          });
        }
      } else if (parentTask && parentTask.entityId === 'null') {
        update = { [parentTask.dbType]: null };
      }

      if (taskIdThatWasInToIndex) {
        dispatch(switchProjectRowOrder(id, taskIdThatWasInToIndex, update, position));
      } else {
        debouncedUpdateProject({ id, ...update });
      }
    } else if (task.dbType === 'project') {
      if (toIndex > fromIndex) {
        taskIdThatWasInToIndex = gantt.getPrevSibling(id);
        position = 'bottom';
      } else {
        taskIdThatWasInToIndex = gantt.getNextSibling(id);
        position = 'top';
      }

      dispatch(switchProjectRowOrder(id, taskIdThatWasInToIndex, null, position));
    }

    return true;
  };
  const onBeforeTaskDrag = id => {
    if (localMode) return true;

    const task = gantt.getTask(id);

    task.draggedOn = new Date().getTime();
    let parent = task.parent === 0 ? task : gantt.getTask(task.parent);

    if (task.group) return false;
    if (editAllTasks) return true;

    parent = parent.dbType !== 'project' ? task : parent;
    const parentCanDrag = !['Planning', 'Confirmed', 'Backlog'].includes(parent.planningStage);
    const cannotEditConfirmed = parent.planningStage === 'Confirmed' && !editConfirmedTasks;

    if (parentCanDrag || cannotEditConfirmed) {
      toast('Please uncheck "Lock Confirmed Ideas" option in Display Preferences.');
      return false;
    }

    return true;
  };
  const onBeforeTaskMove = (id, parent, tindex) => {
    if (localMode) return true;

    const task = gantt.getTask(id);
    const parentTask = gantt.isTaskExists(parent) ? gantt.getTask(parent) : null;

    const preventTaskMove = [
      task.dbType === 'estimate' && task.parent !== parent,
      task.dbType === 'project' && parentTask && parentTask.dbType === 'estimate',
      task.dbType === 'project' && !portfolioModeRef.current && parentTask && parentTask.dbType === 'project',
      !parent && selectedGroup && selectedGroup.key,
      task.group,
      task.dbType !== 'project' && (!parentTask || parentTask.type === 'milestone'),
      task.dbType === 'project' && parentTask && parentTask.dbType === 'task',
      task.dbType === 'project' && portfolioModeRef.current && ![INITIATIVE_LAYER, BET_LAYER].includes(task.layer) && !parentTask,
      task.dbType === 'project' &&
        portfolioModeRef.current &&
        parentTask &&
        parentTask.dbType === 'project' &&
        ![INITIATIVE_LAYER, BET_LAYER].includes(parentTask.layer),
      task.dbType === 'project' &&
        portfolioModeRef.current &&
        [INITIATIVE_LAYER, BET_LAYER].includes(task.layer) &&
        parentTask &&
        parentTask.dbType === 'project',
    ];

    if (preventTaskMove.includes(true)) {
      return false;
    }

    return true;
  };
  const onAfterLinkAdd = (id, link) => {
    if (localMode) return true;

    const source = gantt.getTask(link.source);
    const target = gantt.getTask(link.target);
    const sourceId = source.entityId || source.id;
    const targetId = target.entityId || target.id;

    if (source.dbType === 'project' && target.dbType === 'project') {
      dispatch(addProjectProjectDependency(+sourceId, +targetId, link.type));
    } else if (source.dbType === 'project' && target.dbType === 'estimate') {
      dispatch(addProjectEstimateDependency(+sourceId, +targetId, link.type));
    } else if (source.dbType === 'estimate' && target.dbType === 'project') {
      dispatch(addEstimateProjectDependency(+sourceId, +targetId, link.type));
    } else if (source.dbType === 'estimate' && target.dbType === 'estimate') {
      dispatch(addEstimateEstimateDependency(+sourceId, +targetId, link.type));
    } else if (source.dbType === 'project' && target.dbType === 'task') {
      dispatch(addProjectTaskDependency(+sourceId, +targetId, link.type));
    } else if (source.dbType === 'task' && target.dbType === 'project') {
      dispatch(addTaskProjectDependency(+sourceId, +targetId, link.type));
    } else if (source.dbType === 'task' && target.dbType === 'task') {
      dispatch(addTaskTaskDependency(+sourceId, +targetId, link.type));
    }
    return true;
  };
  const onAfterLinkDelete = (id, link) => {
    if (localMode) return true;

    const source = gantt.getTask(link.source);
    const target = gantt.getTask(link.target);
    const sourceId = source.entityId || source.id;
    const targetId = target.entityId || target.id;

    if (source.dbType === 'project' && target.dbType === 'project') {
      dispatch(removeProjectProjectDependency(+sourceId, +targetId));
    } else if (source.dbType === 'project' && target.dbType === 'estimate') {
      dispatch(removeProjectEstimateDependency(+sourceId, +targetId));
    } else if (source.dbType === 'estimate' && target.dbType === 'project') {
      dispatch(removeEstimateProjectDependency(+sourceId, +targetId));
    } else if (source.dbType === 'estimate' && target.dbType === 'estimate') {
      dispatch(removeEstimateEstimateDependency(+sourceId, +targetId));
    } else if (source.dbType === 'project' && target.dbType === 'task') {
      dispatch(removeProjectTaskDependency(+sourceId, +targetId));
    } else if (source.dbType === 'task' && target.dbType === 'project') {
      dispatch(removeTaskProjectDependency(+sourceId, +targetId));
    } else if (source.dbType === 'task' && target.dbType === 'task') {
      dispatch(removeTaskTaskDependency(+sourceId, +targetId));
    }

    return true;
  };

  useDeepEffect(() => {
    if (gantt) {
      if (events.length) {
        while (events.length) {
          gantt.detachEvent(events.pop());
        }
      }
      events.push(gantt.attachEvent('onBeforeLightbox', onBeforeLightbox));
      events.push(gantt.attachEvent('onAfterTaskUpdate', onAfterTaskUpdate));
      events.push(gantt.attachEvent('onBeforeRowDragEnd', onBeforeRowDragEnd));
      events.push(gantt.attachEvent('onBeforeTaskDrag', onBeforeTaskDrag));
      events.push(gantt.attachEvent('onBeforeTaskMove', onBeforeTaskMove));
      events.push(gantt.attachEvent('onAfterLinkAdd', onAfterLinkAdd));
      events.push(gantt.attachEvent('onAfterLinkDelete', onAfterLinkDelete));
    }

    return () => {
      while (events.length) {
        gantt.detachEvent(events.pop());
      }
    };
  }, [!!gantt, selectedGroup, editConfirmedTasks, localMode, showStories]);

  return null;
};

export default useTimelineProjects;
