import React from 'react';

import useMetadataPopover from './hooks/useMetadataPopover';

const componentHOC = Component => {
  return props => {
    const { metadataPopoverType } = props;

    const {
      oldLightboxActive,
      isOpen,
      onClose,
      metadataSelected,
      metadataSelectedSettingsLink,
      contentComponent,
      onSettingsLinkClicked,
      metadataSelectedNotes,
      updateNote,
      isAllowedToWriteMetadataNotes,
    } = useMetadataPopover(metadataPopoverType);

    return (
      <Component
        isOpen={isOpen}
        onClose={onClose}
        oldLightboxActive={oldLightboxActive}
        metadataSelected={metadataSelected}
        metadataSelectedSettingsLink={metadataSelectedSettingsLink}
        contentComponent={contentComponent}
        anchorEl={props.anchorEl}
        onSettingsLinkClicked={onSettingsLinkClicked}
        metadataSelectedNotes={metadataSelectedNotes}
        updateNote={updateNote}
        isAllowedToWriteMetadataNotes={isAllowedToWriteMetadataNotes}
        {...props}
      />
    );
  };
};

export default componentHOC;
