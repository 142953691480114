import { defaultTo } from 'ramda';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteCustomFieldAssociation } from 'store/customFields';
import { CUSTOMER_REQUEST_CUSTOM_FIELD } from 'store/customFields/constants';

const defaultAsEmptyArray = defaultTo([]);

const useCustomFieldActions = ({ deleteById }) => {
  const [itemToDelete, setItemToDelete] = useState();

  const dispatch = useDispatch();

  const deleteItem = useCallback(() => {
    if (!itemToDelete) {
      return;
    }

    /* If the custom field is associated with more than one entity, only delete the association */
    if (defaultAsEmptyArray(itemToDelete.associations).length > 1) {
      dispatch(deleteCustomFieldAssociation(itemToDelete.id, CUSTOMER_REQUEST_CUSTOM_FIELD));
    } else {
      deleteById(itemToDelete?.id);
    }

    setItemToDelete(null);
  }, [itemToDelete, deleteById]);

  return {
    itemToDelete,
    setItemToDelete,
    deleteItem,
  };
};

export default useCustomFieldActions;
