import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { addUserIntegration } from 'store/login';

const asanaHOC = Component => {
  return props => {
    const {
      integration,
      userIntegrations,
      linkProjectIntegration,
      unlinkProjectIntegration,
      projectIntegrations,
      createProjectIntegration,
      linkErrorMsg,
    } = props;

    const dispatch = useDispatch();

    const [taskLink, setTaskLink] = useState('');
    const [projectId, setProjectId] = useState('');
    const [asanaProjects, setAsanaProjects] = useState([]);

    const _fetchAsanaProjects = async () => {
      if (integration) {
        axios
          .get(`/api/integrations/asana/${integration.id}/projects`)
          .then(response => {
            setAsanaProjects(response.data);
          })
          .catch(err => {
            console.error(err);
          });
      }
    };

    useEffect(() => {
      if (projectIntegrations && projectIntegrations.length && integration) {
        projectIntegrations.forEach(pi => {
          if (pi.org_integration_id === integration.id) {
            setTaskLink(pi.data.permalink_url);
          }
        });
      }

      if (integration) {
        _fetchAsanaProjects();
      }
    }, [projectIntegrations, integration]);

    const handleLinkTask = () => {
      if (taskLink) {
        const link = taskLink.replace('/f', '').match(/([^/]+$)/g);

        linkProjectIntegration(link, { org_integration_id: integration.id });
      }
    };

    const handleUnlinkTask = () => {
      if (projectIntegrations && projectIntegrations.length > 0) {
        unlinkProjectIntegration(projectIntegrations[0].id, { org_integration_id: integration.id });
        setTaskLink('');
      }
    };

    const handleCreateTask = () => {
      if (projectId) {
        createProjectIntegration({
          asanaProjectId: projectId,
          org_integration_id: integration.id,
        });
      }
    };

    const handleAddUserIntegration = token => {
      return dispatch(addUserIntegration(integration.integrationType, integration.id, token)).then(() => {
        _fetchAsanaProjects();
      });
    };

    return (
      <Component
        integration={integration}
        userIntegrations={userIntegrations}
        projectIntegrations={projectIntegrations}
        linkProjectIntegration={linkProjectIntegration}
        handleLinkTask={handleLinkTask}
        handleUnlinkTask={handleUnlinkTask}
        taskLink={taskLink}
        setTaskLink={setTaskLink}
        projectId={projectId}
        setProjectId={setProjectId}
        handleCreateTask={handleCreateTask}
        asanaProjects={asanaProjects}
        linkErrorMsg={linkErrorMsg}
        addUserIntegration={handleAddUserIntegration}
      />
    );
  };
};

export default asanaHOC;
