import calcProjectTimeline from './calcProjectTimeline';
import buildGanttSeparator from './buildGanttSeparator';

export default (
  allProjectsByParentId,
  getSystemFieldName,
  shouldRenderEstimates,
  shouldRenderTasks,
  parseGanttProject,
  generateGanttProjectEstimates,
  generateGanttProjectTasksAndSubtasks,
  generateGanttProjectStories,
  sortOrder,
) => {
  const generate = p => {
    let childrenStories = [];
    let childrenChilds = [];
    let childrenTasksAndEstimates = [];

    const separator = buildGanttSeparator(p, +p.layer - 2, getSystemFieldName);
    let useSeparator = false;

    const children = (allProjectsByParentId[String(p.id)] || []).map(child => {
      const projectStories = generateGanttProjectStories(child);

      childrenStories = [...childrenStories, ...projectStories.stories];

      if (shouldRenderEstimates(child)) {
        childrenTasksAndEstimates = childrenTasksAndEstimates.concat(generateGanttProjectEstimates(child));
      }
      if (shouldRenderTasks(child)) {
        childrenTasksAndEstimates = childrenTasksAndEstimates.concat(generateGanttProjectTasksAndSubtasks(child));
      }

      if (allProjectsByParentId[String(child.id)]) {
        childrenChilds = [...childrenChilds, ...generate(child)];
      }

      let parent = p.id;

      if (+p.layer === 2 && +child.layer === 0) {
        parent = separator.id;
        useSeparator = true;
      }

      const ganttChild = parseGanttProject(child);

      ganttChild.showEstimates = shouldRenderEstimates(child);
      ganttChild.showTasks = shouldRenderTasks(child);

      return {
        ...ganttChild,
        ...calcProjectTimeline(ganttChild),
        ...projectStories.data,
        parent,
      };
    });

    return [
      ...children.sort(sortOrder),
      ...childrenStories,
      ...childrenChilds,
      ...childrenTasksAndEstimates,
      ...(useSeparator ? [separator] : []),
    ];
  };

  return generate;
};
