import React from 'react';
import { and } from 'ramda';
import useOnboardingDemo from 'hooks/onboarding/useOnboardingDemo';
import useOrganizations from 'hooks/useOrganizations';
import useDiscoveryBot from 'hooks/discoveryBot/useDiscoveryBot';

const DiscoveryBotHOC = Component => {
  return props => {
    const { selfGuidedDemoData } = props;

    const { hasDiscoveryBot } = useOrganizations();
    const { isDemoSplashPageOpen } = useOnboardingDemo();
    const { storeDiscoveryBotMessageHistory, loadDiscoveryBotMessageHistory } = useDiscoveryBot();

    const isOpen = and(isDemoSplashPageOpen, hasDiscoveryBot);

    return (
      <Component
        {...props}
        discoveryBotOpen={isOpen}
        selfGuidedDemoData={selfGuidedDemoData}
        saveMessages={storeDiscoveryBotMessageHistory}
        messageHistory={loadDiscoveryBotMessageHistory()}
      />
    );
  };
};

export default DiscoveryBotHOC;
