import React from 'react';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import styled from 'styled-components';

import FormControl from 'design-system/molecules/FormControl/index';


const CustomInput = styled(Input)`
  &&&& {
    :before {
      border-bottom: 0 !important;
    }
  }
`;

export default props => {
  props = {
    fullWidth: true,
    InputLabelProps: {
      shrink: true,
      ...props.InputLabelProps,
    },
    ...props,
  };

  return (
    <FormControl
      fullWidth={'fullWidth' in props ? props.fullWidth : true}
      labelPosition={props.labelPosition}
      margin={props.margin}
      {...props}
    >
      <InputLabel shrink={'shrink' in props ? props.shrink : true}>{props.label}</InputLabel>
      <CustomInput
        {...props}
        {...props.InputProps}
      />
      {/* <FormHelperText id="component-helper-text">Some important helper text</FormHelperText> */}
    </FormControl>
  );
};
