// TODO: the JSX should be removed from here
import React from 'react';
import { toast } from 'react-toastify';

import { pipe, prop, equals, not, path, isNil, either } from 'ramda';

import InfoToast from 'components/InfoToast';

import axios from 'axios';

import { createThunk } from 'utils/store/thunk';
import jiraActionWrapper from 'utils/jiraActionWrapper';
import { getIntegrationProjectKey, getProjectKey } from 'utils/projects/getProjectKey';
import { getMappingByJiraTicketId, hasOutboundMappingByField } from 'utils/mapping';

import ErrorToast from 'store/middlewares/errorHandler/Error';

import { UPDATE_PROJECT_FULFILLED } from 'store/projects/types';
import { getEnrichedProject } from 'store/projects/selectors';
import { registerJiraWebhook as registerJiraWebhookAction } from 'store/organization';
import { getOrgJiraIntegrations } from 'store/organization/selectors';
import { fetchUsers } from 'store/users/actions';

import serializeProject from 'store/projects/helpers/serializeProject';

import {
  CREATE_PROJECTS_JIRAS,
  SYNC_PROJECT_FROM_JIRA,
  UPDATE_JIRA_ISSUE_FROM_PROJECT,
  SYNC_PROJECTS_JIRA_STORIES,
  TEST_JIRA_CONNECTION,
  JIRA_DISCONNECT,
  UPDATE_JIRA_CONFIG_MISC,
  JIRA_TICKET_DETAIL,
  JIRA_UNLINK,
  FETCH_JIRA_PROJECTS,
  CREATE_JIRA_CONFIG,
  UPDATE_JIRA_CONFIG,
  FETCH_JIRA_FIELDS,
  SYNC_ORG_PROJECTS_FROM_JIRA,
  UPDATE_JIRA_PROJECTS_KEY,
  GET_JIRA_PERMISSIONS,
  GET_JIRA_PERMISSIONS_RESET,
} from '../types';
import { convertHtmlStringToEditorJson, unshiftLinkToDocument } from 'design-system/molecules/RemirrorWYSIWYGEditor/helpers';

const DATA = 'data';
const LAYER = 'layer';
const DETAILS = 'details';
const JIRA_CONNECTION_TIMEOUT_ERROR = 'JIRA_CONNECTION_TIMEOUT_ERROR';

const isJiraConnectionTimeout = pipe(path(['response', 'data', 'error_code']), equals(JIRA_CONNECTION_TIMEOUT_ERROR));
const hasNoError = pipe(prop('error'), isNil);

const getAddIdeaNumberToJira = path(['data', 'add_idea_number_to_jira']);
const getData = prop(DATA);
const getLayer = prop(LAYER);

const shouldIncludeIdeaNumberInJiraTitle = either(
  pipe(getAddIdeaNumberToJira, equals(true)),
  pipe(getAddIdeaNumberToJira, isNil),
);

const testJira = (id, jiraTested) => async dispatch =>
  jiraActionWrapper(
    dispatch,
    async () => {
      return new Promise(async (resolve, reject) => {
        let payload;

        try {
          payload = await axios.post(`/api/jira/${id}/auth/test`);
        } catch (err) {
          if (isJiraConnectionTimeout(err)) {
            toast.error(
              <ErrorToast>
                <span>Connection test failed. Please check integration set up.</span>
              </ErrorToast>,
              {
                toastId: JIRA_CONNECTION_TIMEOUT_ERROR,
              },
            );
          }

          return reject(err);
        }

        toast(<InfoToast>JIRA is configured correctly.</InfoToast>, {
          toastId: 'JIRA_CONNECTION',
        });

        if (!jiraTested) {
          dispatch(registerJiraWebhookAction(id)).then(data => {
            if (hasNoError(data)) {
              toast(<InfoToast>Webhooks enabled successfully.</InfoToast>, {
                toastId: 'JIRA_WEBHOOKS_ENABLED',
              });
            }
          });
        }

        return resolve(payload);
      });
    },
    TEST_JIRA_CONNECTION,
    id,
  );

const disconnectJira = id => createThunk(JIRA_DISCONNECT, axios.delete(`/api/jira/${id}/config`), { id });

const updateJiraConfigMisc = (id, data) => createThunk(UPDATE_JIRA_CONFIG_MISC, axios.put(`/api/jira/${id}/config/misc`, data));

const createAllProjectsJiras = (integrationId, jiraUrl, socketRoom) => {
  return createThunk(
    CREATE_PROJECTS_JIRAS,
    axios.post(`/api/jira/${integrationId}/import/insert`, { all: true, jiraUrl, socketRoom }),
  );
};

const syncProjectFromJira =
  (id, integrationId, autoSave = 'false') =>
  (dispatch, getState) =>
    jiraActionWrapper(
      dispatch,
      async () => {
        return axios.put(`/api/jira/${integrationId}/sync_from_jira/${id}?autoSave=${String(autoSave)}`).then(async res => {
          dispatch({
            payload: res.data,
            type: UPDATE_PROJECT_FULFILLED,
          });

          await dispatch(fetchUsers());

          return getEnrichedProject(getState(), res.data);
        });
      },
      SYNC_PROJECT_FROM_JIRA,
      integrationId,
    );

const updateJiraIssueFromProject = (integrationId, projectId, payload) => (dispatch, getState) =>
  jiraActionWrapper(
    dispatch,
    async () => {
      return axios.put(`/api/integrations/jira/${integrationId}/project/${projectId}`, payload);
    },
    UPDATE_JIRA_ISSUE_FROM_PROJECT,
    integrationId,
  );

const _syncJiraStoriesCall = (orgIntegrationId, epicsKeys, allTimeImport = false, socket, isJQLImport = false) => {
  return dispatch => {
    return new Promise((accept, reject) => {
      try {
        jiraActionWrapper(
          dispatch,
          async () => {
            if (socket) {
              const socketRoom = socket.join();

              socket.subscribe(accept, { messageType: `message-${socketRoom}` });
              return axios
                .post(`/api/jira/${orgIntegrationId}/stories/sync`, {
                  socketRoom,
                  epicsKeys,
                  isJQLImport,
                  allTimeImport,
                })
                .catch(reject);
            }

            return axios
              .post(`/api/jira/${orgIntegrationId}/stories/sync`, { epicsKeys, isJQLImport, allTimeImport })
              .then(accept)
              .catch(reject);
          },
          SYNC_PROJECTS_JIRA_STORIES,
          orgIntegrationId,
        );
      } catch (err) {
        reject(err);
      }
    });
  };
};

const syncJiraStories = (orgIntegrationId, epicsKeys, socket, isJQLImport = false) =>
  _syncJiraStoriesCall(orgIntegrationId, epicsKeys, false, socket, isJQLImport);

const syncJiraStoriesAllTime = (orgIntegrationId, epicsKeys, socket) =>
  _syncJiraStoriesCall(orgIntegrationId, epicsKeys, true, socket, false);

const prepareProjectDetailsField = projectData => {
  const details = projectData.details || '<p></p>';

  try {
    const jsonDetailsDoc = convertHtmlStringToEditorJson(details);

    const currentUrl = new URL(window.location.href);
    const projectKey = getProjectKey(projectData);
    const projectKeyLabel = getIntegrationProjectKey(projectData);

    const linkHref = `${currentUrl.origin}/ideas?openIdea=${projectKey}`;

    const docWithLinkToDb = unshiftLinkToDocument(jsonDetailsDoc, 'Links to dragonboat ', linkHref, projectKeyLabel);

    return JSON.stringify(docWithLinkToDb);
  } catch (e) {
    return projectData.details;
  }
};

const createJiraTicket = (projectData, jiraProjectKey, integrationId, jiraIssueType) => async (dispatch, getState) =>
  jiraActionWrapper(
    dispatch,
    async () => {
      const state = getState();
      const jiraIntegrations = getOrgJiraIntegrations(state);

      const orgIntegration = jiraIntegrations.find(i => i.id === integrationId);

      if (!orgIntegration) return;

      const mapping = getMappingByJiraTicketId(getData(orgIntegration), jiraProjectKey, getLayer(projectData));

      let { title, details = '' } = projectData;

      if (hasOutboundMappingByField(mapping, DETAILS)) {
        details = prepareProjectDetailsField(projectData);
      }

      const projectKeyLabel = getIntegrationProjectKey(projectData);
      const projectTitleDoesntHaveIdeaNumber = pipe(prop('title'), title => title.includes(projectKeyLabel), not);

      if (shouldIncludeIdeaNumberInJiraTitle(orgIntegration) && projectTitleDoesntHaveIdeaNumber(projectData)) {
        title = `[${projectKeyLabel}] ${projectData.title}`;
      }

      const payload = {
        project_data: serializeProject({
          ...projectData,
          title,
          details,
        }),
        jira_project_key: jiraProjectKey,
        jira_issue_type: jiraIssueType,
      };

      return axios.post(`/api/jira/${integrationId}/issues`, payload);
    },
    JIRA_TICKET_DETAIL,
    integrationId,
  );

const linkWithJiraTicket = (data, integrationId) => async dispatch =>
  jiraActionWrapper(
    dispatch,
    async () => axios.post(`/api/jira/${integrationId}/issues/imported`, data),
    JIRA_TICKET_DETAIL,
    integrationId,
  );

const linkWithJiraTicketOutbound = (data, integrationId) => async dispatch =>
  jiraActionWrapper(
    dispatch,
    async () => axios.post(`/api/jira/${integrationId}/issues/link-outbound`, data),
    JIRA_TICKET_DETAIL,
    integrationId,
  );

const unlinkWithJiraTicket = (key, integrationId) =>
  createThunk(JIRA_UNLINK, axios.delete(`/api/jira/${integrationId}/issues`, { data: { issueKey: key } }), { issueKey: key });

const getJiraProjects = integrationId => async dispatch =>
  jiraActionWrapper(
    dispatch,
    async () => axios.get(`/api/jira/${integrationId}/import/jira-projects`),
    FETCH_JIRA_PROJECTS,
    integrationId,
  );

const createJiraConfigDetails = config => createThunk(CREATE_JIRA_CONFIG, axios.post(`/api/jira/config`, config));

const updateJiraConfigDetails = (id, config) => createThunk(UPDATE_JIRA_CONFIG, axios.put(`/api/jira/${id}/config`, config));

const fetchJiraFields = integrationId => async dispatch =>
  jiraActionWrapper(
    dispatch,
    async () => axios.get(`/api/jira/${integrationId}/import/fields`),
    FETCH_JIRA_FIELDS,
    integrationId,
  );

const syncOrgProjectsFromJira = integrationId => async dispatch =>
  jiraActionWrapper(
    dispatch,
    async () => axios.put(`/api/jira/${integrationId}/sync_from_jira`),
    SYNC_ORG_PROJECTS_FROM_JIRA,
    integrationId,
  );

const updateJiraProjectsKey = (orgIntegrationId, projectKeys) => {
  return async dispatch => {
    return jiraActionWrapper(
      dispatch,
      async () => {
        return axios.put(`/api/jira/${orgIntegrationId}/project-key`, projectKeys);
      },
      UPDATE_JIRA_PROJECTS_KEY,
      orgIntegrationId,
    );
  };
};

const getJiraPermissions = orgIntegrationId => {
  return async dispatch => {
    return jiraActionWrapper(
      dispatch,
      () => {
        return axios.get(`/api/integrations/jira/${orgIntegrationId}/permissions`);
      },
      GET_JIRA_PERMISSIONS,
      orgIntegrationId,
    );
  };
};

const resetGetJiraPermissions = () => ({ type: GET_JIRA_PERMISSIONS_RESET });

export {
  createAllProjectsJiras,
  syncProjectFromJira,
  updateJiraIssueFromProject,
  syncJiraStories,
  syncJiraStoriesAllTime,
  testJira,
  disconnectJira,
  updateJiraConfigMisc,
  createJiraTicket,
  linkWithJiraTicket,
  linkWithJiraTicketOutbound,
  unlinkWithJiraTicket,
  getJiraProjects,
  createJiraConfigDetails,
  updateJiraConfigDetails,
  fetchJiraFields,
  syncOrgProjectsFromJira,
  updateJiraProjectsKey,
  getJiraPermissions,
  resetGetJiraPermissions,
};
