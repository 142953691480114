import reduceReducers from 'reduce-reducers';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import {
  FETCH_MISSION_CONTROL_REQUESTS,
  FETCH_MISSION_CONTROL_REQUESTS_FULFILLED,
  FETCH_MISSION_CONTROL_OKRS,
  FETCH_MISSION_CONTROL_OKRS_FULFILLED,
  FETCH_MISSION_CONTROL_METRICS,
  FETCH_MISSION_CONTROL_METRICS_FULFILLED,
  REFRESH_MISSION_CONTROL_OKRS,
  REFRESH_MISSION_CONTROL_OKRS_FULFILLED,
  REFRESH_MISSION_CONTROL_METRICS,
  REFRESH_MISSION_CONTROL_METRICS_FULFILLED,
  FETCH_MISSION_CONTROL_CREATED_REQUESTS,
  FETCH_MISSION_CONTROL_CREATED_REQUESTS_FULFILLED,
} from './types';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  FETCH_MISSION_CONTROL_REQUESTS,
  FETCH_MISSION_CONTROL_OKRS,
  FETCH_MISSION_CONTROL_METRICS,
  REFRESH_MISSION_CONTROL_OKRS,
  REFRESH_MISSION_CONTROL_METRICS,
  FETCH_MISSION_CONTROL_CREATED_REQUESTS,
]);

export const initialState = {
  requests: [],
  requestsCreatedByMe: [],
  okrs: [],
  metrics: [],
  ...thunksInitialState,
};

const missionControlReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MISSION_CONTROL_REQUESTS_FULFILLED: {
      return {
        ...state,
        requests: action.payload?.data || [],
      };
    }
    case FETCH_MISSION_CONTROL_CREATED_REQUESTS_FULFILLED: {
      return {
        ...state,
        requestsCreatedByMe: action.payload?.data || [],
      };
    }
    case FETCH_MISSION_CONTROL_OKRS_FULFILLED:
    case REFRESH_MISSION_CONTROL_OKRS_FULFILLED: {
      return {
        ...state,
        okrs: action.payload?.data || [],
      };
    }
    case FETCH_MISSION_CONTROL_METRICS_FULFILLED:
    case REFRESH_MISSION_CONTROL_METRICS_FULFILLED: {
      return {
        ...state,
        metrics: action.payload?.data || [],
      };
    }
    default: {
      return state;
    }
  }
};

const reducer = reduceReducers(initialState, missionControlReducer, ...thunksReducers);

export default reducer;
