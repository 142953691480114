import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FormGroup from '@material-ui/core/FormGroup';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';

import { ROADMAP_PAGE } from 'constants/filters';
import { BET, INITIATIVE } from 'constants/projects';
import { JIRA_PROJECT_RENAMED_WARNING } from 'constants/integrations';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import Dropdown from 'design-system/molecules/Dropdown/index';
import ReadMoreIcon from 'design-system/atoms/ReadMoreIcon/index';
import ToggleButton from 'design-system/molecules/ToggleButton/index';
import ZoomIcon from 'design-system/atoms/ZoomIcon/index';

import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete';
import ShowItemsWithoutDatesButton from '../../components/ShowItemsWithoutDatesButton';

import ShareView from 'containers/ShareView';
import useScenarioToolbarControls from 'containers/ScenarioToolbarControls/hooks/useScenarioToolbarControls';
import ShowMyItemsToggle from 'containers/ShowMyItemsToggle';

import { setAppGlobalConfig } from 'store/app';
import { DEFAULT_GROUP_OPTION } from 'store/projects/helpers/groupOptions';

import useOrganizations from 'hooks/useOrganizations';
import useTimelines from 'hooks/useTimelines';
import useMoreDropdownOptions from './hooks/useMoreDropdownOptions';

import getJiraIntegrationWarnings from 'utils/getJiraIntegrationWarnings';

import PreferencesDialog from './PreferencesDialog';
import ShowColorLegend from './PreferencesDialog/ColorLegend';

import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useScenariosNavigation from 'hooks/useScenariosNavigation';
import useMetadataGroupByOptions from 'hooks/useMetadataGroupByOptions';
import { CreateRoadmapSnapshotButton } from 'features/RoadmapHistory/components';

const BASE_COL_WIDTH = 25;
const getColWidth = (gap = 1) => gap * BASE_COL_WIDTH;

const columnWidths = [
  { id: getColWidth(1), title: '1x', key: getColWidth(1) },
  { id: getColWidth(2), title: '2x', key: getColWidth(2) },
  { id: getColWidth(4), title: '4x', key: getColWidth(4) },
  { id: getColWidth(6), title: '6x', key: getColWidth(6) },
  { id: getColWidth(8), title: '8x', key: getColWidth(8) },
];

const ControlsBar = ({ viewsDropdownTabs, hasRoadmapHistory, withCustomersGrouping }) => {
  const dispatch = useDispatch();

  const [openPreferences, setOpenPreferences] = useState(false);

  const handleOpenPreferences = useCallback(() => setOpenPreferences(true), []);
  const handleClosePreferences = useCallback(() => setOpenPreferences(false), []);

  const { jiraIntegrations, hasRenameJiraProject } = useOrganizations();
  const warning = hasRenameJiraProject && getJiraIntegrationWarnings(jiraIntegrations, JIRA_PROJECT_RENAMED_WARNING);

  const handleWarningClick = () => {
    dispatch(setAppGlobalConfig({ showUpdateJiraKeyDialog: true }));
  };

  const {
    lsState: { selectedColorBy, showLegend, selectedZoom, selectedTimeWindow, grouplaneType, showTextOverflow, showMyItemsOnly },
    selectedGrouplaneType,
    selectedSwimlaneType,
    groupByOptions: groupByOptionsFromStore,
    changeSelectedZoom,
    updateState,
    changeGroupLane,
    changeDataLane,
  } = useTimelines(ROADMAP_PAGE, { withCustomers: withCustomersGrouping });

  const TARGET_TIMELINE_OPTION = { key: 'targetTimeline', title: 'Target Timeline' };

  const { groupByOptions } = useMetadataGroupByOptions(groupByOptionsFromStore);

  // Remove the Bet option in the second group dropdown if the first have the initiative selected
  const groupByGroup2Options = [DEFAULT_GROUP_OPTION, ...groupByOptions].filter(option =>
    grouplaneType && grouplaneType.key === INITIATIVE ? option.key !== BET : true,
  );

  const currentUser = useSelector(state => state.login.currentUser);

  const { isCreatingOrViewingScenario, onClickExitDraftMode } = useScenarioToolbarControls();
  const { isRoadmapVersionCommitted } = useRoadmapVersions();

  const { navigateToScenarioModule } = useScenariosNavigation();

  const toggleDraftMode = useCallback(() => {
    const localMode = isCreatingOrViewingScenario;

    if (!localMode) {
      navigateToScenarioModule();
    } else {
      onClickExitDraftMode();
    }
  }, [isCreatingOrViewingScenario, currentUser]);

  const moreDropdownOptions = useMoreDropdownOptions({ handleOpenPreferences, isCreatingOrViewingScenario, toggleDraftMode });

  return (
    <GridWrapper container spacing={8}>
      <Grid item>
        <FormGroup row>
          <GroupByAutocomplete
            name="groupByData"
            suggestions={groupByOptions}
            value={selectedGrouplaneType}
            onChange={changeGroupLane}
          />
          {selectedGrouplaneType?.key && (
            <>
              <Spacing />
              <GroupByAutocomplete
                name="groupByData"
                suggestions={groupByGroup2Options.filter(o => !o.key || o.key !== selectedGrouplaneType.key)}
                value={selectedSwimlaneType ?? DEFAULT_GROUP_OPTION}
                onChange={changeDataLane}
                label="then by"
              />
            </>
          )}
          {isCreatingOrViewingScenario && (
            <>
              <Spacing />
              <GroupByAutocomplete
                name="displayTimeline"
                suggestions={[TARGET_TIMELINE_OPTION]}
                value={TARGET_TIMELINE_OPTION}
                label="Display"
                disabled
              />
            </>
          )}
        </FormGroup>
      </Grid>
      {warning && (
        <WarningMsg>
          <a role="link" tabIndex={0} onClick={handleWarningClick}>
            {warning.message}
          </a>
        </WarningMsg>
      )}
      <Grid item style={{ textAlign: 'right' }}>
        <FormGroup row style={{ justifyContent: 'flex-end' }}>
          {hasRoadmapHistory && <CreateRoadmapSnapshotButton WrapperComponent={IconWrapper} />}
          <IconWrapper>
            <ShowMyItemsToggle
              showMyItemsOnly={showMyItemsOnly}
              updateShowMyItemsOnly={() => updateState({ showMyItemsOnly: !showMyItemsOnly })}
            />
          </IconWrapper>
          {!isCreatingOrViewingScenario && (
            <IconWrapper>
              <ShareView pageId={ROADMAP_PAGE} viewsDropdownTabs={viewsDropdownTabs} id="view-icon" />{' '}
            </IconWrapper>
          )}
          <IconWrapper>
            <ShowItemsWithoutDatesButton />
          </IconWrapper>
          <IconWrapper>
            <ToggleButton
              on={showTextOverflow}
              icon={<ReadMoreIcon id="text-overflow-icon" />}
              title="Show Text Overflow"
              onChange={() => updateState({ showTextOverflow: !showTextOverflow })}
            />
          </IconWrapper>
          <IconWrapper>
            <Dropdown
              label="Col width"
              placeholder={
                <RotatedButtonIcon title="Col width" data-cy="grid-col-width">
                  <FormatLineSpacingIcon />
                </RotatedButtonIcon>
              }
              options={columnWidths}
              onChange={(e, { id }) => updateState({ slotWidth: id })}
              isButton={false}
            />
          </IconWrapper>
          <IconWrapper>
            <ButtonIcon
              title={selectedZoom && selectedZoom.title}
              onClick={changeSelectedZoom}
              disabled={selectedTimeWindow === 'manual'}
            >
              {selectedZoom && selectedZoom.id === 'day' && (
                <ZoomIcon size="small">
                  <span />
                </ZoomIcon>
              )}
              {(!selectedZoom || selectedZoom.id === 'week') && (
                <ZoomIcon size="medium">
                  <span />
                </ZoomIcon>
              )}
              {selectedZoom && selectedZoom.id === 'month' && (
                <ZoomIcon>
                  <span />
                </ZoomIcon>
              )}
              {selectedZoom && selectedZoom.id === 'quarter' && (
                <ZoomIcon size="big">
                  <span />
                </ZoomIcon>
              )}
            </ButtonIcon>
          </IconWrapper>
          {!isRoadmapVersionCommitted && (
            <IconWrapper>
              <Dropdown
                placeholder={
                  <ButtonIcon>
                    <MoreHorizIcon />
                  </ButtonIcon>
                }
                options={moreDropdownOptions}
                isButton={false}
                inline
              />
            </IconWrapper>
          )}
        </FormGroup>
      </Grid>
      {showLegend && (
        <ColorLegend>
          <ShowColorLegend colorBy={selectedColorBy} />
        </ColorLegend>
      )}
      {openPreferences && <PreferencesDialog onClose={handleClosePreferences} />}
    </GridWrapper>
  );
};

export default ControlsBar;

const GridWrapper = styled(Grid)`
  &&& {
    padding: 8px 18px 8px 44px;
    max-width: 100vw;
    position: relative;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
  }
`;

const ColorLegend = styled.div`
  border: 1px solid #efefef;
  border-radius: 5px;
  margin: 0 22px 4px 8px;
  overflow-y: auto;
  width: 100%;
  max-height: 66px;
  padding: 8px;
`;

const WarningMsg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: red;
    cursor: pointer;
  }
`;

const RotatedButtonIcon = styled(ButtonIcon)`
  transform: rotate(90deg);
`;

const IconWrapper = styled.div`
  width: 46px;
  display: flex;

  &:not(:last-of-type) {
    margin-right: ${({ theme }) => theme.spacing.unit * 0.65}px; // 5px
  }
`;

const Spacing = styled.div`
  padding: 5px;
`;
