import { useCallback } from 'react';
import { pipe, not, path, isNil, prop } from 'ramda';

import { KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL } from 'constants/objectives';

import { findParentWithLevel } from 'design-system/molecules/AgGridReact-New/helpers';

const TOP_LEVEL = 0;

const getOrganizationId = prop('organization_id');
const nodeIsKeyResult = pipe(path(['data', 'objective_id']), isNil, not);
const nodeIsObjective = pipe(nodeIsKeyResult, not);

const areOkrsFromDifferentAccounts = (okr1, okr2) => {
  const orgFromOkr1 = getOrganizationId(okr1);
  const orgFromOkr2 = getOrganizationId(okr2);

  return orgFromOkr1 && orgFromOkr2 && orgFromOkr1 !== orgFromOkr2;
};

const bothNodesAreObjectives = (node1, node2) => {
  return nodeIsObjective(node1) && nodeIsObjective(node2);
};

const objectiveNodesHaveSameLevelAndDifferentParent = (objective1, objective2) => {
  const { data: { parent_id: objective1ParentId } = {} } = objective1;
  const { data: { parent_id: objective2ParentId } = {} } = objective2;

  return objective1ParentId !== objective2ParentId;
};

const objectiveNodesHaveDifferentLevel = (objective1, objective2) => {
  return objective1.level !== objective2.level;
};

const bothNodesAreKeyResults = (node1, node2) => {
  return nodeIsKeyResult(node1) && nodeIsKeyResult(node2);
};

const keyResultNodeIsL1 = keyResultNode => keyResultNode.data.level === KEY_RESULT_1_LEVEL;

const keyResultNodeIsL2 = keyResultNode => keyResultNode.data.level === KEY_RESULT_2_LEVEL;

const bothKeyResultNodesHaveTheSameLevel = (keyResultNode1, keyResultNode2) => {
  return keyResultNode1.data.level === keyResultNode2.data.level;
};

const bothKeyResultNodesHaveTheSameParent = (keyResultNode1, keyResultNode2) => {
  return (
    keyResultNode1.data.objective_id === keyResultNode2.data.objective_id &&
    keyResultNode1.data.parent_id === keyResultNode2.data.parent_id
  );
};

const isTopLevelNode = node => node.level === TOP_LEVEL;

const useOkrsRowDrag = ({
  isTreeView = true,
  hasCorpLevel = false,
  switchObjectivesRowOrder,
  switchKeyResultsRowOrder,
  moveKeyResultToObjective,
  moveObjectiveToObjective,
  fetchObjectives,
}) => {
  const handleRowDragForTreeView = useCallback(
    async (currentNode, lastOverNode, { position }) => {
      const currentNodeData = currentNode.data;

      const lastOverNodeData = lastOverNode.data;

      const currentNodeDataId = currentNodeData.id;

      const lastOverNodeDataId = lastOverNodeData.id;

      if (!lastOverNodeDataId) {
        return;
      }

      if (hasCorpLevel) {
        const areWeDraggingAnObjective = nodeIsObjective(currentNode);

        if (!areWeDraggingAnObjective && areOkrsFromDifferentAccounts(currentNodeData, lastOverNodeData)) {
          // Don't allow other levels to be moved between items from different accounts (DoD)
          // Only objectives and their tree can be moved to mommy corp OKR
          return;
        }
      }

      if (bothNodesAreObjectives(currentNode, lastOverNode)) {
        if (objectiveNodesHaveSameLevelAndDifferentParent(currentNode, lastOverNode) && !isTopLevelNode(currentNode)) {
          // not top level, must drag between parents before swithing row order
          await moveObjectiveToObjective(currentNodeData, lastOverNodeData, position);
        }

        if (objectiveNodesHaveDifferentLevel(currentNode, lastOverNode)) {
          await moveObjectiveToObjective(currentNodeData, lastOverNodeData, position);
        }

        return switchObjectivesRowOrder(currentNodeDataId, lastOverNodeDataId, null, position);
      }

      if (nodeIsObjective(currentNode) && !nodeIsObjective(lastOverNode)) {
        const lastOverNodeParentOfTheSameLevelAsTheCurrentNode = findParentWithLevel(lastOverNode, currentNode.level);

        return switchObjectivesRowOrder(
          currentNodeDataId,
          lastOverNodeParentOfTheSameLevelAsTheCurrentNode.data.id,
          null,
          position,
        );
      }

      if (bothNodesAreKeyResults(currentNode, lastOverNode)) {
        if (bothKeyResultNodesHaveTheSameLevel(currentNode, lastOverNode)) {
          if (bothKeyResultNodesHaveTheSameParent(currentNode, lastOverNode) || isTopLevelNode(currentNode)) {
            // Both nodes are Key Results and have the same level and either:
            // * they have the same parent, so simply update the rank;
            // * it's a top level node we are moving (e.g., on Snapshot OKR table with
            //   Key Results 1 as selected layer and tree view), so we only need to update the rank.
            return switchKeyResultsRowOrder(currentNodeDataId, lastOverNodeDataId, null, position);
          }

          return moveKeyResultToObjective(currentNodeData, null, lastOverNodeData, position);
        }

        if (keyResultNodeIsL2(currentNode) && keyResultNodeIsL1(lastOverNode)) {
          if (currentNodeData.parent_id === lastOverNodeDataId) {
            const firstKeyResultL2 = lastOverNodeData.keyResults.find(kr => kr.id !== currentNodeDataId);

            return switchKeyResultsRowOrder(currentNodeDataId, firstKeyResultL2.id, null, 'top');
          }

          return moveKeyResultToObjective(currentNodeData, null, lastOverNodeData, 'top');
        }

        if (keyResultNodeIsL1(currentNode) && keyResultNodeIsL2(lastOverNode)) {
          const lastOverNodeParentOfTheSameLevelAsTheCurrentNode = findParentWithLevel(lastOverNode, currentNode.level);

          if (
            currentNodeData.objective_id === lastOverNodeParentOfTheSameLevelAsTheCurrentNode.data.objective_id ||
            isTopLevelNode(currentNode)
          ) {
            return switchKeyResultsRowOrder(
              currentNodeDataId,
              lastOverNodeParentOfTheSameLevelAsTheCurrentNode.data.id,
              null,
              position,
            );
          }

          return moveKeyResultToObjective(currentNodeData, null, lastOverNodeParentOfTheSameLevelAsTheCurrentNode.data, position);
        }
      }

      if (nodeIsKeyResult(currentNode) && keyResultNodeIsL1(currentNode) && nodeIsObjective(lastOverNode)) {
        if (currentNodeData.objective_id === lastOverNodeData.id) {
          const firstKeyResult = lastOverNodeData.keyResults.find(kr => kr.id !== currentNodeDataId);

          if (firstKeyResult) {
            return switchKeyResultsRowOrder(currentNodeDataId, firstKeyResult.id, null, 'top');
          }
        }

        return moveKeyResultToObjective(currentNodeData, lastOverNodeData, null, position);
      }
    },
    [switchObjectivesRowOrder, switchKeyResultsRowOrder, moveKeyResultToObjective],
  );

  const handleRowDragForPlainView = useCallback(
    (currentNode, lastOverNode, { position }) => {
      if (!lastOverNode.data.id) {
        return;
      }

      if (nodeIsObjective(currentNode)) {
        return switchObjectivesRowOrder(currentNode.data.id, lastOverNode.data.id, null, position);
      }

      return switchKeyResultsRowOrder(currentNode.data.id, lastOverNode.data.id, null, position);
    },
    [switchObjectivesRowOrder, switchKeyResultsRowOrder],
  );

  const handleRowDrag = useCallback(
    async (currentNode, lastOverNode, options) => {
      if (isTreeView) {
        await handleRowDragForTreeView(currentNode, lastOverNode, options);
      } else {
        await handleRowDragForPlainView(currentNode, lastOverNode, options);
      }

      return fetchObjectives();
    },
    [isTreeView, handleRowDragForTreeView, handleRowDragForPlainView, fetchObjectives],
  );

  return handleRowDrag;
};

export default useOkrsRowDrag;
