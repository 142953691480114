const recursiveSum = (obj, key) => {
  if (obj?.integrationProgress?.[key]) {
    return obj?.integrationProgress?.[key];
  }

  if (obj?.children?.length) {
    return obj.children.reduce((a, b) => a + recursiveSum(b, key), 0);
  }

  return 0;
};

const getIntegrationIssuesCount = ({ data }) => recursiveSum(data, 'issuesTotal');

const getIntegrationStoryPoints = ({ data }) => recursiveSum(data, 'pointsTotal');

const numericFields = {
  storyPoint: {
    label: 'Reported Points',
    valueGetter: getIntegrationStoryPoints,
    width: 80,
  },
  issueCounts: {
    label: 'Issue Count',
    valueGetter: getIntegrationIssuesCount,
    width: 80,
  },
  priorityScore: {
    label: 'Total Score',
    width: 100,
  },
  reach_score: {
    label: 'Reach Score',
    width: 100,
  },
  impact_score: {
    label: 'Impact Score',
    width: 100,
  },
  confidence_score: {
    label: 'Confidence Score',
    width: 100,
  },
  effort_score: {
    label: 'Effort',
    width: 100,
    aggFunc: 'sumNumericFields',
  },
  benefit1: {
    label: 'Strategic Benefit (0-5)',
    width: 100,
    withoutZeros: false,
  },
  benefit2: {
    label: 'Urgency (0-5)',
    width: 100,
    withoutZeros: false,
  },
  benefit3: {
    label: 'Frequency (0-5)',
    width: 100,
    withoutZeros: false,
  },
  cost1: {
    label: 'Cost (0-5)',
    width: 100,
    withoutZeros: false,
  },
};

export default numericFields;
