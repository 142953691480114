import React, { useState } from 'react';
import styled from 'styled-components';

import theme, { spacing } from 'design-system/theme';
import Text from 'design-system/atoms/Text/index';
import Button from 'design-system/atoms/Button/index';
import MultiSelect from 'design-system/atoms/MultiSelect/index';
import SelectableItem from 'design-system/molecules/SelectableItem/index';

import Stars from 'design-system/atoms/DragonIcons/Stars';
import useSystemFields from 'hooks/useSystemFields';

import panelBackground from './background.svg';

const SUMMARY_FORMAT_OPTIONS = [
  { primaryField: 'timeframe', secondaryField: 'roadmap' },
  { primaryField: 'timeframe', secondaryField: 'customers' },
  { primaryField: 'roadmap', secondaryField: 'timeframe' },
  { primaryField: 'roadmap', secondaryField: 'product1' },
];

// TODO: REMOVE THIS HARCODED OPTIONS
const FIELDS_OPTIONS = getSystemFieldName => [
  { value: 'title', label: 'Title' },
  { value: 'details', label: 'Details' },
  { value: 'status_summary', label: 'Summary' },
  { value: 'roadmap', label: getSystemFieldName('roadmap') },
  { value: 'timeframe', label: getSystemFieldName('timeframe') },
  { value: 'product1', label: getSystemFieldName('product1') },
  { value: 'personas', label: 'Persona' },
  { value: 'customers', label: 'Customer' },
];

// TODO: REMOVE THIS HARCODED OPTIONS
const FORMAT_OPTIONS = getSystemFieldName =>
  SUMMARY_FORMAT_OPTIONS.map(opt => ({
    label: `By ${getSystemFieldName(opt.primaryField)} and ${getSystemFieldName(opt.secondaryField)}`,
    value: opt,
  }));

const FormScreen = ({ onClickGenerate }) => {
  const [getSystemFieldName] = useSystemFields();

  const formatOptions = FORMAT_OPTIONS(getSystemFieldName);
  const fieldOptions = FIELDS_OPTIONS(getSystemFieldName);

  const fieldOptionsLabels = fieldOptions.map(opt => opt.label);

  const [selectedFields, setSelectedFields] = useState(fieldOptionsLabels);
  const [selectedFormat, setSelectedFormat] = useState(formatOptions[0]?.value);

  const handleClickGenerate = () => {
    const summarizeBy = selectedFields.map(field => fieldOptions.find(opt => opt.label === field).value);

    onClickGenerate(selectedFormat, summarizeBy);
  };

  return (
    <Flex>
      <Left>
        <Background src={panelBackground} />
      </Left>
      <Right>
        <Content>
          <Stars />
          <Title variant="h2" bold color={theme.palette.text.black}>
            Generate Summary Using AI
          </Title>
          <FormContainer>
            <FormGroup>
              <Text variant="p" bold>
                Add data fields in the summary
              </Text>
              <MultiSelect options={fieldOptionsLabels} value={selectedFields} onChange={setSelectedFields} />
            </FormGroup>
            <FormGroup>
              <Text variant="p" bold>
                Choose a template
              </Text>
              {formatOptions.map(option => (
                <StyledSelectableItem
                  label={option.label}
                  selected={selectedFormat === option.value}
                  onChange={() => setSelectedFormat(option.value)}
                />
              ))}
            </FormGroup>
            <StyledButton
              background={theme.palette.newLayout.background.duskBlue}
              color={theme.palette.newLayout.background.white}
              hover={{ background: theme.palette.newLayout.background.dodgerBlue }}
              fontSize={theme.typography.fontSize}
              onClick={handleClickGenerate}
              width="200px"
            >
              Generate Summary
            </StyledButton>
          </FormContainer>
        </Content>
      </Right>
    </Flex>
  );
};

export default FormScreen;

const Flex = styled.div`
  display: flex;
  height: 100%;
`;

const Left = styled.div`
  width: 45%;
  background-color: ${({ theme }) => theme.palette.newLayout.background.concrete};
  position: relative;
  overflow: hidden;
`;

const Background = styled.img`
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

const Right = styled.div`
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing(10)}px;
`;

const Content = styled.div`
  max-width: 450px;
`;

const Title = styled(Text)`
  font-size: ${({ theme }) => theme.typography.fontSizeLargeRem}rem;
  margin-top: ${spacing(3)}px;
`;

const FormContainer = styled.div``;

const FormGroup = styled.div`
  margin-top: ${spacing(4)}px;
`;

const StyledSelectableItem = styled(SelectableItem)`
  max-width: 350px;
  margin-top: ${spacing()}px;
`;

const StyledButton = styled(Button)`
  &&&& {
    margin-top: ${spacing(3)}px;
    text-transform: uppercase;
  }
`;
