/**
 * AppLogo Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import omit from 'lodash/omit';

import lightenDarkenColor from 'design-system/utils/lightenDarkenColor';

export default class ButtonComponent extends PureComponent {
  render() {
    return (
      <>
        <ButtonOutlined {...omit(this.props, ['children'])}>{this.props.children}</ButtonOutlined>
      </>
    );
  }
}

const ButtonOutlined = styled(Button)`
  &&&& {
    text-transform: none;
    background-color: transparent;
    text-align: center;
    font-size: ${({ fontSize, theme }) => fontSize || `${theme.typography.fontSizeSmallLarge}px`};
    font-family: ${props => props.theme.typography.fontFamily};
    font-weight: ${props => props.theme.typography.fontWeightBold};
    letter-spacing: 0px;
    width: ${({ width }) => width || '84px'};
    height: ${({ height }) => height || '43px'};
    background-color: ${({ background }) => background || 'transparent'};
    color: ${({ color, theme }) => color || theme.palette.text.primary};
    border: 2px solid ${({ borderColor }) => borderColor || 'transparent'};
  }
  &:hover {
    background-color: ${({ background, theme }) =>
      background ? lightenDarkenColor(background, -50) : theme.palette.text.primary}!important;

    ${({ hover }) =>
      hover &&
      hover.background &&
      `
      background-color: ${hover.background}!important;
    `}

    ${({ hover }) =>
      hover &&
      hover.color &&
      `
      color: ${hover.color}!important;
    `}
  }
`;
