// intialize configurations
let showToolbox = process.env.REACT_APP_SHOW_TOOLBOX === 'true';
let development = false;
let staging = false;
let locally = false;
let apiBaseUrl = null;

switch (process.env.REACT_APP_ROLLBAR_ENVIRONMENT) {
  case undefined: // locally
    showToolbox = true;
    development = true;
    locally = true;
    apiBaseUrl = process.env.REACT_APP_API_BASE_URL; // 'https://app.dragonboat.io';
    break;
  case 'development':
    development = true;
    break;
  case 'staging':
    showToolbox = false;
    staging = true;
    break;
  case 'production':
    showToolbox = false;
    break;
  default:
    showToolbox = false;
}

export const LOCAL = locally;
export const DEVELOPMENT = development;
export const STAGING = staging;
export const SHOW_TOOLBOX = showToolbox;
export const API_BASE_URL = apiBaseUrl;
export const isNotProductionEnv = () => LOCAL || DEVELOPMENT || STAGING;

export const GUIDE_STEPS = 4;

// SYSTEM SETTINGS FIELDS
export const DEFAULT_SETTINGS_NAME = {
  objectiveCorp: 'Corp OKR',
  objective: 'L1 OKR',
  keyResult1: 'L2 OKR',
  keyResult2: 'L3 OKR',
  phase: 'Phase',
  roadmapCorp: 'Corp Roadmap',
  roadmap: 'Roadmap',
  product1: 'Sub-roadmap',
  product2: 'Product',
  // categoryCorp: 'Corp Category',
  category: 'Category',
  priority: 'Priority',
  skill: 'Skill',
  team: 'Team',
  team2: 'Group',
  theme: 'Theme',
  user: 'User',
  timeframeCorp: 'Corp Timeframe',
  timeframe: 'Group Timeframe',
  timeframe2: 'Timeframe',
  tag: 'Tag',
  customer: 'Customer',
  integrations: 'EPIC Key',
  storiesProgress: 'Stories Progress',
  progress: 'Progress',
  initiative: 'Initiative',
  bet: 'Bet',
  idea: 'Idea',
  request: 'Request',
  milestone: 'Milestone',
  status_summary: 'Status Summary',
  details: 'Details',
  allLevels: 'All Levels',
  planningStage: 'Planning Stage',
  owner: 'Owner',
  status_color: 'Health',
  customFields: 'Custom Fields',
  metric: 'Metric',
  // Custom User Fields
  user1: 'Product Manager',
  user2: 'Tech Lead',
  user3: 'Program Lead',
  user4: 'Design Lead',
  user5: 'Exec Sponsor',
  user6: 'PMM',
  user7: 'BU',
  cycle: 'Cycle',
  deliverable: 'Deliverable',
  pdlc: 'PDLC',
  scenario: 'Scenario',
  value_offset_in_months: 'Months to Value',
  persona: 'Persona',
  lifecycle: 'Lifecycle',
  metrics: 'Metrics',
};

export const ROADMAP = 'roadmap';
export const SUB_ROADMAP = 'product1';

export const READ_ONLY_ROUTES = ['home', 'forecast', 'ideas', 'manage'];

// ERRORS
export const DUPLICATED_ERROR_CODE = 'DUPLICATED_RECORD_WITH_SAME_TITLE';
export const VALIDATION_ERROR_CODE = 'VALIDATION_ERROR';

export const getReadOnlyRoutes = (canViewMissionControlHome = false) =>
  canViewMissionControlHome ? READ_ONLY_ROUTES.filter(r => r !== 'home') : READ_ONLY_ROUTES;
