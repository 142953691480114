import { useCallback, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getGoalModeTableProps } from 'store/goalMode/selectors';
import { updateState } from 'store/goalMode';
import useGoalTableColumnOptions from 'hooks/objectives/useGoalTableColumnOptions';
import { GOAL_MODE } from 'store/grids';
import useExportCsvFromGrid from 'features/GridExport/hooks/useExportCsvFromGrid';

/**
 * @function useGoalModeGridControls hook that will return flags and callbacks to be able to control the goals grid table
 *
 * @return {Object} All flags and callbacks to control the table of goad grid
 */
const useGoalModeGridControls = () => {
  const dispatch = useDispatch();
  const moreButtonRef = useRef();

  const [tableShowFieldsEl, setTableShowFieldsEl] = useState(null);

  const { tableVisibleFields: storedVisibleFields, expandedItems, pageSize } = useSelector(getGoalModeTableProps);

  const { handleExportToCsv } = useExportCsvFromGrid(GOAL_MODE, 'outcome');

  const handleExpandClick = useCallback(
    () => dispatch(updateState({ expandedItems: !expandedItems })),
    [dispatch, expandedItems],
  );

  const handleTableShowFieldsClicked = () => {
    if (moreButtonRef.current) setTableShowFieldsEl(moreButtonRef.current);
  };

  const { leftItems, rightItems } = useGoalTableColumnOptions();

  const tableVisibleFields = useMemo(() => {
    return storedVisibleFields.filter(field => !![...leftItems, ...rightItems].includes(field));
  }, [leftItems, rightItems, storedVisibleFields]);

  const handleShowAll = useCallback(() => {
    dispatch(updateState({ tableVisibleFields: [...leftItems, ...rightItems] }));
  }, [dispatch, leftItems, rightItems]);

  const handleHideAll = useCallback(() => {
    dispatch(updateState({ tableVisibleFields: [] }));
  }, [dispatch]);

  const handleChangeFields = useCallback(
    fields => {
      dispatch(updateState({ tableVisibleFields: fields }));
    },
    [dispatch],
  );

  const setPageSize = useCallback(
    pageSize => {
      dispatch(updateState({ pageSize }));
    },
    [dispatch],
  );

  return {
    expandItems: expandedItems,
    tableVisibleFields,
    moreButtonRef,
    pageSize,

    setPageSize,
    handleExpandClick,
    handleTableShowFieldsClicked,

    tableShowFieldsEl,
    setTableShowFieldsEl,
    leftItems,
    rightItems,
    handleShowAll,
    handleHideAll,
    handleChangeFields,
    handleExportToCsv,
  };
};

export default useGoalModeGridControls;
