import React from 'react';
import styled from 'styled-components';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


export default props => {
  return (
    <Wrapper
      backgroundColor={props.backgroundColor}
      bordered={props.bordered}
      style={props.style}
      className={`segment ${props.className || ''}`}
    >
      <Header>
        {props.label && <Label>{props.label}</Label>}
        {props.actionButtons}
      </Header>
      {props.children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ bordered, theme }) => bordered && `border: 1px solid ${theme.palette.background.christmasSilver};`}
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.shape.borderRadiusLarge}px;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  min-height: 40px;
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
`;

const Header = styled.div``;

const Label = styled(props => (
  <div className={props.className}>
    <TextDeprecated color="textPrimary" size="medium">
      {props.children}
    </TextDeprecated>
  </div>
))`
  width: 100%;
  text-align: center;
`;
