export const LANE_WIDTH = 280;

export const METADATA_ROADMAP_DATA_TYPES = [
  'category',
  'keyResult',
  'phase',
  'theme',
  'timeframe',
  'timeframe2',
];

export const SECOND_LAYER_METADATA_GROUPS = {
  keyResult2: 'objectives',
  keyResult: 'objectives',
  product: 'roadmaps',
  timeframe2: 'timeframes',
};

export const SECOND_LAYER_GROUPS = Object.keys(SECOND_LAYER_METADATA_GROUPS);
