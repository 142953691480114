import { useMemo } from 'react';

const emptyArray = [];

const defaultGetOptionLabel = option => option?.title;

const filterSelectedOptions = (options, selectedOptions) => {
  const isSelected = option => selectedOptions?.some(selected => selected.id === option.id && selected.label === option.label);

  const filterOptions = options =>
    options
      .filter(option => !isSelected(option))
      .map(option => (option.children ? { ...option, children: filterOptions(option.children) } : option));

  return filterOptions(options);
};

const useMultiSelectAutocomplete = ({
  options = emptyArray,
  selectedOptions,
  onChange,
  onCreateNew,
  getOptionLabel = defaultGetOptionLabel,
  disableGroupSelection = false,
}) => {
  const filteredOptions = useMemo(() => filterSelectedOptions(options, selectedOptions), [options, selectedOptions]);

  const onSelectOption = option => {
    if (disableGroupSelection && option.children) return;

    if (option?.isNew && onCreateNew) {
      return onCreateNew(option?.title)?.then(newOption =>
        onChange([...(selectedOptions || emptyArray), { ...newOption, label: getOptionLabel(newOption) }]),
      );
    }

    onChange([...(selectedOptions || emptyArray), option]);
  };

  const onRemoveOption = option => {
    onChange(selectedOptions?.filter(o => o.id !== option.id));
  };

  const onRemoveAllOptions = () => {
    onChange([]);
  };

  return {
    filteredOptions,
    onSelectOption,
    onRemoveOption,
    onRemoveAllOptions,
  };
};

export default useMultiSelectAutocomplete;
