import { useState } from 'react';

import useDeepEffect from 'hooks/useDeepEffect';

export default selectedTemplate => {
  const [tableData, setTableData] = useState([]);

  useDeepEffect(() => {
    if (!selectedTemplate) return;
    const { data } = selectedTemplate;

    const projects = data.projects.filter(p => p.layer === '0');
    const parsedTableData = projects.map(p => {
      const parsedProject = {};
      const relationshipStrMatch = /\[(.*?)\]/;

      Object.keys(p).forEach(field => {
        const fieldValue = p[field];

        if (relationshipStrMatch.exec(fieldValue)) {
          const matchedValue = relationshipStrMatch.exec(fieldValue)[1];
          const [relationTable, relationIndex] = matchedValue.split('.');

          parsedProject[field] =
            data[relationTable] && data[relationTable][parseInt(relationIndex)]
              ? data[relationTable][parseInt(relationIndex)]
              : null;
        }
      });
      return {
        ...p,
        ...parsedProject,
      };
    });

    setTableData(parsedTableData);
  }, [selectedTemplate]);

  return tableData;
};
