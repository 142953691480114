import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';

import AzureDevopsIcon from 'design-system/atoms/AzureDevopsIcon/index';

import { spacing } from 'design-system/theme';

import ConfirmDialog from 'components/ConfirmDialog';

import { INTEGRATIONS_KEYS, INTEGRATIONS_NAMES } from 'constants/integrations';

import useIntegrations from 'hooks/useIntegrations';
import useUserIntegrations from 'hooks/useUserIntegrations';

import { getOrgHasMultipleIntegrations as getOrgHasMultipleIntegrationsSelector } from 'store/organization/selectors';

import Title from '../components/Title';
import Body from '../components/Body';
import ProgressTrackingConfig from '../components/ProgressTrackingConfig';

import AzureConnection from './components/AzureConnection';
import AzureWebhook from './components/AzureWebhook';
import FieldMapping from '../components/FieldMapping';
import IntegrationSteps from './components/IntegrationSteps';
import MultiIntegrationsList from '../components/MultiIntegrationsList';
import Container from '../components/Container';

const Component = props => {
  const { currentUser, handleTestConnection, userCanViewOrgIntegration, userCanUpdateOrgIntegration } = props;

  const [selectedOrgIntegrationId, setSelectedOrgIntegrationId] = useState(null);
  const [confirmDialogDisplay, setConfirmDialogDisplay] = useState(false);
  const [addingOrgIntegration, setAddingOrgIntegration] = useState(false);

  const hasMultipleIntegrations = useSelector(getOrgHasMultipleIntegrationsSelector);
  const { hasOrgIntegrations, organizationIntegrations, removeOrgIntegration } = useIntegrations(INTEGRATIONS_KEYS.azuredevops);
  const { userIntegration, clearLoadedUserIntegrations } = useUserIntegrations(
    INTEGRATIONS_KEYS.azuredevops,
    selectedOrgIntegrationId,
  );

  const selectedOrgIntegration = useMemo(
    () => organizationIntegrations.find(integration => integration.id === selectedOrgIntegrationId),
    [organizationIntegrations, selectedOrgIntegrationId],
  );

  const onSelectOrgIntegrationHandler = useCallback(orgIntegration => setSelectedOrgIntegrationId(orgIntegration.id), []);

  const onCreateNewIntegrationHandler = useCallback(() => setAddingOrgIntegration(true), []);

  const onIntegrationSwitchChangeHandler = useCallback(
    ({ target }) => {
      if (target?.checked) {
        setAddingOrgIntegration(true);
      } else if (!selectedOrgIntegrationId) {
        setAddingOrgIntegration(false);
      } else {
        setConfirmDialogDisplay(true);
      }
    },
    [selectedOrgIntegrationId],
  );

  const onShowDisconnectConfirmation = useCallback(() => setConfirmDialogDisplay(true), [setConfirmDialogDisplay]);

  const handleRemoveOrgIntegration = useCallback(() => {
    if (selectedOrgIntegrationId) {
      removeOrgIntegration(selectedOrgIntegrationId).then(() => {
        clearLoadedUserIntegrations();

        setSelectedOrgIntegrationId(null);
      });
    }
    setConfirmDialogDisplay(false);
    setAddingOrgIntegration(false);
  }, [selectedOrgIntegrationId, removeOrgIntegration]);

  useEffect(() => {
    if (!hasMultipleIntegrations && hasOrgIntegrations) {
      setSelectedOrgIntegrationId(organizationIntegrations[0]?.id);
    }
  }, [hasMultipleIntegrations, hasOrgIntegrations]);

  const shouldShowIntegrationSteps =
    !hasOrgIntegrations || (selectedOrgIntegrationId && !userIntegration) || addingOrgIntegration;
  const shouldShowIntegrationsList =
    hasMultipleIntegrations && hasOrgIntegrations && !selectedOrgIntegrationId && !addingOrgIntegration;
  const shouldShowIntegrationDetails = selectedOrgIntegrationId && userIntegration;
  const integrationNameLabel = INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.azuredevops];

  const shouldShowContactInfo = !userCanViewOrgIntegration || (!userCanUpdateOrgIntegration && !hasOrgIntegrations);

  return (
    <Container>
      <Title
        logo={<AzureDevopsIcon style={{ width: 40, height: 40, marginRight: 16 }} />}
        title={`${integrationNameLabel} Integration`}
      />
      {shouldShowContactInfo && (
        <Body>
          <p>Please contact your organization Admin to activate {integrationNameLabel} integration.</p>
        </Body>
      )}
      {!shouldShowContactInfo && shouldShowIntegrationSteps && (
        <Body>
          {/*
            Integration Switch:
            - For Leader/Owner, switch is always visible. Toggling the switch creates/deletes an orgIntegration
            - For Editor roles, display only if the toggle is ON (both orgIntegration & userIntegration exist)
              when toggled off it will only delete their own userIntegration, the orgIntegration will remain
          */}
          <div>
            <StyledFormControl
              control={
                <Switch
                  id="azure-devops-integration-switch"
                  color="secondary"
                  checked={!!selectedOrgIntegrationId || addingOrgIntegration}
                  onChange={onIntegrationSwitchChangeHandler}
                  disabled={!userCanUpdateOrgIntegration}
                />
              }
              label={`${integrationNameLabel} Integration`}
              labelPlacement="start"
            />
          </div>
          <IntegrationSteps
            orgIntegration={selectedOrgIntegration}
            userIntegration={userIntegration}
            addingOrgIntegration={addingOrgIntegration}
            onAddOrgIntegration={id => setSelectedOrgIntegrationId(id)}
          />
        </Body>
      )}
      {!shouldShowContactInfo && shouldShowIntegrationsList && (
        <MultiIntegrationsList
          integrationType={INTEGRATIONS_KEYS.azuredevops}
          onCreateNewIntegration={onCreateNewIntegrationHandler}
          onSelectIntegration={onSelectOrgIntegrationHandler}
          userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
          userCanViewOrgIntegration={userCanViewOrgIntegration}
        />
      )}
      {!shouldShowContactInfo && shouldShowIntegrationDetails && (
        <React.Fragment>
          {selectedOrgIntegration?.data?.uri && (
            <IntegrationLink href={selectedOrgIntegration.data.uri} target="_blank" rel="noreferrer">
              {selectedOrgIntegration.data.uri}
            </IntegrationLink>
          )}
          <FieldMapping
            integrationType={INTEGRATIONS_KEYS.azuredevops}
            orgIntegrationId={selectedOrgIntegrationId}
            userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
          />
          <ProgressTrackingConfig
            integrationType={INTEGRATIONS_KEYS.azuredevops}
            orgIntegrationId={selectedOrgIntegrationId}
            userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
          />
          <AzureConnection
            orgIntegrationId={selectedOrgIntegrationId}
            handleTestConnection={handleTestConnection}
            onShowDisconnectConfirmation={onShowDisconnectConfirmation}
            userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
          />
          <AzureWebhook
            orgIntegration={selectedOrgIntegration}
            currentUser={currentUser}
            userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
          />
          {hasMultipleIntegrations && (
            <Button color="primary" onClick={() => setSelectedOrgIntegrationId(null)}>
              Back
            </Button>
          )}
        </React.Fragment>
      )}
      <ConfirmDialog
        title={`Disconnect From ${integrationNameLabel}`}
        text={
          <div>
            <p>
              Disconnecting {integrationNameLabel} will disable {integrationNameLabel} linking and update for your entire
              Workspace. Do you want to continue?
            </p>
            <br />
            <p>You may reconnect in the future.</p>
          </div>
        }
        isOpen={confirmDialogDisplay}
        onCancel={() => setConfirmDialogDisplay(false)}
        onConfirm={handleRemoveOrgIntegration}
      />
    </Container>
  );
};

export default Component;

const StyledFormControl = styled(FormControlLabel)`
  &&&& {
    margin-bottom: 1rem;
  }
`;

const IntegrationLink = styled.a`
  text-align: center;
  width: 100%;
  display: block;
  margin-bottom: ${spacing(2)}px;
`;
