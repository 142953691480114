// External dependencies
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

// Dragonboat depencies
import { filterActiveItens } from 'utils';

export function getState(state) {
  return state.phases;
}

export const makeGetPhases = () =>
  createCachedSelector([getState, (_, showArchived) => showArchived], (state, showArchived) =>
    showArchived ? state?.phases : state?.phases.filter(filterActiveItens),
  )((_, showArchived) => `${showArchived}`);

export const getPhases = makeGetPhases();

export const makeGetNormalizedPhases = () => {
  const localGetPhases = makeGetPhases();

  return createSelector(
    state => localGetPhases(state, true),
    state => {
      return state?.reduce((objs, obj) => {
        objs[obj.id] = obj;
        return objs;
      }, {});
    },
  );
};

export const getNormalizedPhases = makeGetNormalizedPhases();

export const getDefaultPhaseByPlanningStage = createCachedSelector(
  [state => getPhases(state, false), (_, planningStage) => planningStage],
  (state, planningStage) => state.find(phase => phase.planning_stage === planningStage),
)((_, planningStage) => planningStage);
