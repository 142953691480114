import { addListener } from '@reduxjs/toolkit';
import { includes } from 'ramda';

import { FETCH_METRICS_FULFILLED } from 'store/metrics/types';
import { updateMetadataEntitiesOnWhiteboard } from './thunks';

/**
 * Subscribes to some actions to refresh the metrics on portfolio Canvas
 *
 * For now is only listning for metrics since other metadata is already covered on the reducer.
 * But ideally we would migrate everything to listners and we could add the actions here
 *
 * @returns {Object} the addListener action creator
 */
export const subscribeToRefreshMetadataOnPortfolioCanvas = () => {
  return addListener({
    matcher: action => includes(action.type, [FETCH_METRICS_FULFILLED]),
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners();

      const { dispatch } = listenerApi;

      dispatch(updateMetadataEntitiesOnWhiteboard());
    },
  });
};
