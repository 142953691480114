import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

export default React.memo(({ colId, rowId, width, children }) => {
  const id = `col-${rowId}-${colId}-null`;

  return (
    <TableCol key={id} style={{ maxWidth: width + 32 }}>
      {children}
    </TableCol>
  );
});

const TableCol = withTheme()(styled.td`
  padding: 14px 16px;
  color: #6b6b6b;
  /* border-right: 6px solid #fff; */
  border: null;
  vertical-align: top;
  height: 100%;
  &:first-child {
    border-right: 1px solid ${({ theme }) => theme.palette.border.mercury};
  }
  > div {
    height: 100%;
  }
`);
