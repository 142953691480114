import moment from 'moment-timezone';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import { head, identity } from 'ramda';

import lightenDarkenColor from 'design-system/utils/lightenDarkenColor';
import { rgbToHex } from 'utils';
import formatDate from 'utils/dates/formatDate';

import { GANTT_FIELD_TYPE } from 'components/GanttWrapper/fieldType';

import buildReactEditor from './reactEditor';
import { throwWithProductionFallback } from 'utils/errorHandling';

/**
 * Definition of default configurations on gantt
 *
 * @param {*} gantt
 */
const defaultGanttConfigs = gantt => {
  gantt.config.show_errors = false;
  gantt.config.round_dnd_dates = false;
  gantt.config.show_progress = true;
  gantt.config.row_height = 32;
  gantt.config.task_height = 24;
  gantt.config.order_branch = true;
  gantt.config.order_branch_free = true;
  gantt.config.sort = false;
  gantt.config.undo = false;
  gantt.config.drag_project = true;
  gantt.config.autoscroll = true;
  gantt.config.autoscroll_speed = 40;
  gantt.config.smart_rendering = true;
  gantt.config.static_background = true;
  gantt.config.branch_loading = true;
  gantt.config.smart_scales = true;
  gantt.config.scale_unit = 'month';
  gantt.config.step = 1;
  gantt.config.scale_height = 60;
  gantt.config.min_column_width = 25;
  gantt.config.date_scale = '%M %Y';
  gantt.config.show_unscheduled = true;
  gantt.config.fit_tasks = true;
  // gantt.config.start_date = gantt.date.add(new Date(), -10, 'year');
  // gantt.config.end_date = gantt.date.add(new Date(), 10, 'year');
  // gantt.config.subscales = [
  //   { unit: 'week', step: 1, date: '%d' },
  // ];
  gantt.templates.task_class = (start, end, task) => {
    let classes = `gantt_task_${task.dbType} `;

    if (task.dbType === 'project') classes += `stage${task.planningStage}`;
    if (task.group) classes += 'gantt_group';
    if (task.format === 'circle') classes += 'gantt_circle_task_format';

    return classes;
  };
  gantt.attachEvent('onTaskDrag', (id, mode, task, original) => {
    const state = gantt.getState();
    const minDate = state.min_date;
    const maxDate = state.max_date;

    if (task.start_date <= moment(minDate).add(4, gantt.config.duration_unit)) {
      gantt.config.start_date = gantt.date.add(minDate, -1, gantt.config.duration_unit);
      gantt.render();
    }
    if (task.end_date >= moment(maxDate).subtract(1, gantt.config.duration_unit)) {
      gantt.config.end_date = gantt.date.add(maxDate, 1, gantt.config.duration_unit);
      gantt.render();
    }
    // const state = gantt.getState();
    // const minDate = state.min_date;
    // const maxDate = state.max_date;
    // const scaleStep = gantt.date.add(new Date(), state.scale_step, state.scale_unit) - new Date();
    // let showDate = false;
    // let repaint = false;
    // if (Math.abs(task.start_date - minDate) < scaleStep) {
    //   showDate = task.start_date;
    //   repaint = true;
    // } else if (Math.abs(task.end_date - maxDate) < scaleStep) {
    //   showDate = task.end_date;
    //   repaint = true;
    // }
    // if (repaint) {
    //   gantt.render();
    //   gantt.showDate(showDate);
    // }
  });
  gantt.attachEvent('onTaskClick', (id, e) => {
    if (!e) return true;
    const path = e.composedPath ? e.composedPath() : e.path;

    if (path && path.map(p => p.className).includes('gantt__openLightbox')) {
      gantt.showLightbox(id);
      return false;
    }

    return true;
  });
  const setTasksBorderColors = () => {
    const backlogElements = document.getElementsByClassName('stageBacklog');
    const planningElements = document.getElementsByClassName('stagePlanning');

    // eslint-disable-next-line no-restricted-syntax
    for (const backlogElem of Array.from(backlogElements)) {
      backlogElem.style.setProperty('border-color', backlogElem.style.backgroundColor, 'important');
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const planningElem of Array.from(planningElements)) {
      const color = rgbToHex(planningElem.style.backgroundColor);

      planningElem.style.setProperty('border-color', lightenDarkenColor(color, -40), 'important');
    }
  };

  gantt.attachEvent('onGanttRender', setTasksBorderColors);
  gantt.attachEvent('onTaskSelected', setTasksBorderColors);
  gantt.attachEvent('onGanttScroll', setTasksBorderColors);

  // ======================== EDITORS ================================
  // ***

  const getInput = node => {
    const elementExists = node.getElementsByTagName('input');

    if (!elementExists.length) {
      return node.querySelector('select');
    }

    return node.querySelector('input');
  };
  const editableColsForTasks = ['text', 'ownerName', 'status_color'];
  const setCursorOnEditableCells = () => {
    // For editabble columns should show the text editor cursor on hover
    const gantCells = document.getElementsByClassName('gantt_cell');
    const { columns } = gantt.config;

    // eslint-disable-next-line no-restricted-syntax
    for (const gantCell of Array.from(gantCells)) {
      const dataColumnName = gantCell.attributes.getNamedItem('data-column-name')?.value;
      const rowElem = gantCell.parentElement;
      const taskIDElem = rowElem?.attributes.getNamedItem('task_id');
      const taskId = taskIDElem ? taskIDElem.value : '';
      const col = columns.find(c => c.name === dataColumnName);
      const isDate = taskId.includes('date');
      const isStory = taskId.includes('story');
      const isEstimate = taskId.includes('estimate');
      const isTask = taskId.includes('task');
      const isProject = !isEstimate && !isTask && !isStory && !isDate;

      if (
        (col && col.editor && isProject) ||
        (col && col.editor && isTask && dataColumnName && editableColsForTasks.includes(dataColumnName))
      ) {
        gantCell.style.setProperty('cursor', 'text', 'important');
      }
    }
  };

  gantt.attachEvent('onBeforeLightbox', () => false);
  gantt.attachEvent('onGanttRender', setCursorOnEditableCells);
  gantt.attachEvent('onGanttScroll', setCursorOnEditableCells);
  gantt.ext.inlineEditors.attachEvent('onBeforeEditStart', ({ id, columnName }) => {
    const ganttTask = gantt.getTask(id);

    if (['estimate', 'story'].includes(ganttTask.dbType)) return false;
    if (ganttTask.group) return false;
    if (ganttTask.dbType === 'task' && !editableColsForTasks.includes(columnName)) return false;

    return true;
  });
  gantt.config.editor_types.inlineEditor = {
    show(id, column, config, placeholder) {
      // called when input is displayed, put html markup of the editor into placeholder
      // and initialize your editor if needed:
      let html = '';

      switch (config.field) {
        case GANTT_FIELD_TYPE.DATE:
          html = `<div><input type='date' name='${column.name}'></div>`;
          break;
        case GANTT_FIELD_TYPE.NUMBER:
          html = `<div><input type='number' name='${column.name}'></div>`;
          break;
        case GANTT_FIELD_TYPE.TEXT:
          html = `<div><input type='text' name='${column.name}'></div>`;
          break;
        case GANTT_FIELD_TYPE.SELECT: {
          const options = [...(config.hideEmptyOption ? [] : ['']), ...(config.options || [])]
            .map(o => `<option value="${isObject(o) ? o.value : o}">${isObject(o) ? o.label : o}</option>`)
            .join('');

          html = `<div><select name='${column.name}'>${options}</select></div>`;
          break;
        }
        case GANTT_FIELD_TYPE.MULTI_SELECT: {
          const options = [...(config.hideEmptyOption ? [] : ['']), ...(config.options || [])]
            .map(o => `<option value="${isObject(o) ? o.value : o}">${isObject(o) ? o.label : o}</option>`)
            .join('');

          html = `<div><select name='${column.name}' multiple>${options}</select></div>`;
          break;
        }
        default:
          throwWithProductionFallback(new Error(`Not yet implemented: ${config.field}`));
      }

      placeholder.innerHTML = html;
    },
    hide(placeholder) {
      // called when input is hidden
      // destroy any complex editors or detach event listeners from here
    },
    set_value(value, id, column, node) {
      const {
        editor: { field },
      } = column;

      // set input value
      if (field === 'date' && value) {
        value = moment(value).toDate().toISOString().substr(0, 10);
      } else if (value === undefined) {
        value = '';
      }
      getInput(node).value = value;
    },
    get_value(id, column, node) {
      // return input value
      const {
        editor: { field: fieldType, mapValue },
      } = column;
      const mapValueOrDefault = mapValue ?? identity;

      const input = getInput(node);

      if (fieldType === GANTT_FIELD_TYPE.MULTI_SELECT) {
        const values = Array.from(input.selectedOptions).map(option => option.value);

        return values.map(mapValueOrDefault);
      }

      if (fieldType === GANTT_FIELD_TYPE.SELECT) {
        const value = head(Array.from(input.selectedOptions).map(option => option.value));

        return mapValueOrDefault(value);
      }

      const { value } = input;

      return mapValueOrDefault(value);
    },
    is_changed(value, id, column, node) {
      // called before save/close. Return true if new value differs from the original one
      // returning true will trigger saving changes, returning false will skip saving
      const currentValue = this.get_value(id, column, node);
      const {
        editor: { field },
      } = column;

      if (field === 'date' && value) {
        value = formatDate(value);
      } else if (field === 'text') {
        value = String(value);
      }

      return !isEqual(currentValue, value);
    },
    is_valid(value, id, column, node) {
      // validate, changes will be discarded if the method returns false
      return true;
    },
    // save(id, column, node) {
    //   console.log('save');
    //   // only for inputs with map_to:auto. complex save behavior goes here
    // },
    focus(node) {
      const input = getInput(node);

      if (!input) {
        return;
      }
      if (input.focus) {
        input.focus();
      }
      if (input.select) {
        input.select();
      }

      input.onblur = () => {
        gantt.ext.inlineEditors.save();
        setTimeout(() => node.remove(), 100);
      };
    },
  };
  gantt.config.editor_types.reactEditor = buildReactEditor(gantt);
};

export default defaultGanttConfigs;
