import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { importCustomerRequests as importCustomerRequestsAction } from 'store/customerRequests/actions';
import { getCustomerRequestsCustomFields } from 'store/customFields/selectors';

import useSystemFields from 'hooks/useSystemFields';
import useOrganizations from 'hooks/useOrganizations';

import {
  createCustomFieldDataMapping,
  validateAndFormatDateField,
  validateAndFormatMultiValueField,
  validateAndFormatNumericField,
  validateAndFormatOwnerField,
} from 'utils/import';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

export default Component => {
  return props => {
    const dispatch = useDispatch();

    const [getSystemFieldName] = useSystemFields();
    const { hasProducts } = useOrganizations();

    const { canView } = usePermissions();

    const canImportRequestsWithExternalKey = canView(PERMISSION_FEATURES.importRequestsWithExternalKey);

    const customFields = useSelector(getCustomerRequestsCustomFields);

    const customFieldDataMapping = useMemo(() => createCustomFieldDataMapping(customFields), [customFields]);

    const dataMapping = useMemo(
      () => [
        {
          field: 'title',
          displayTitle: 'Title',
          displayHelpText: 'required',
          required: true,
          col: null,
          parseToCorrectType: title => String(title)?.substring(0, 255),
        },
        {
          field: 'details',
          displayTitle: 'Details',
          col: null,
        },
        {
          field: 'links',
          displayTitle: 'Links',
          col: null,
        },
        {
          field: 'desired_time',
          displayTitle: 'Desired Time',
          col: null,
          parseToCorrectType: date => validateAndFormatDateField(date),
        },
        {
          field: 'roadmap',
          displayTitle: getSystemFieldName('roadmap'),
          col: null,
          parseToCorrectType: title => ({ title }),
        },
        ...(hasProducts
          ? [
              {
                field: 'product1',
                displayTitle: getSystemFieldName('product1'),
                disabled: dataMapping => !dataMapping.some(col => col.field === 'roadmap' && col.col),
                col: null,
                parseToCorrectType: title => ({ title }),
              },
            ]
          : []),
        {
          field: 'tags',
          displayTitle: 'Tags',
          col: null,
          parseToCorrectType: tagTitles => validateAndFormatMultiValueField(tagTitles),
        },
        {
          field: 'customers',
          displayTitle: 'Customers',
          col: null,
          parseToCorrectType: customerTitles => validateAndFormatMultiValueField(customerTitles, 'name'),
        },
        {
          field: 'owner',
          displayTitle: 'Owner',
          col: null,
          parseToCorrectType: value => validateAndFormatOwnerField(value),
        },
        {
          field: 'business_value',
          displayTitle: 'Benefit',
          col: null,
          parseToCorrectType: value => validateAndFormatNumericField(value),
        },
        {
          field: 'effort_score',
          displayTitle: 'Effort',
          col: null,
          parseToCorrectType: value => validateAndFormatNumericField(value),
        },
        {
          field: 'confidence_score',
          displayTitle: 'Confidence',
          col: null,
          parseToCorrectType: value => validateAndFormatNumericField(value),
        },
        {
          field: 'impact_score',
          displayTitle: 'Impact',
          col: null,
          parseToCorrectType: value => validateAndFormatNumericField(value),
        },
        {
          field: 'reach_score',
          displayTitle: 'Reach',
          col: null,
          parseToCorrectType: value => validateAndFormatNumericField(value),
        },
        ...(canImportRequestsWithExternalKey
          ? [
              { field: 'external_key', displayTitle: 'External Key', col: null },
              { field: 'external_portfolio_key', displayTitle: 'External Portfolio Key', col: null },
              { field: 'createdByEmail', displayTitle: 'Created by', displayHelpText: 'email', col: null },
            ]
          : []),
        ...customFieldDataMapping,
      ],
      [
        customFieldDataMapping,
        getSystemFieldName,
        hasProducts,
        validateAndFormatDateField,
        validateAndFormatNumericField,
        validateAndFormatOwnerField,
      ],
    );

    const importCustomerRequests = data => dispatch(importCustomerRequestsAction(data));

    return (
      <Component
        {...props}
        importCustomerRequests={importCustomerRequests}
        dataMapping={dataMapping}
        getSystemFieldName={getSystemFieldName}
      />
    );
  };
};
