import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { prepend } from 'ramda';

import { ACTIVE_STATUS, METADATA_LEVELS } from 'constants/common';
import { KEY_RESULT_1_LEVEL } from 'constants/objectives';

import { getCategories } from 'store/categories/selectors';
import { getActiveCustomers } from 'store/customers/selectors';
import { getObjectives } from 'store/objectives/selectors';
import { getPhases } from 'store/phases/selectors';
import { getPriorities } from 'store/priorities/selectors';
import { getActiveTags } from 'store/tags/selectors';
import { getThemes } from 'store/themes/selectors';
import { getTimeframes } from 'store/timeframes/selectors';
import { getOrganization } from 'store/organization/selectors';
import { selectPersonasData } from 'features/Personas/store/selectors';
import { selectLifecyclesData } from 'features/Lifecycles/store/selectors';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import makeMetadataRoadmapsOptionsFormatter from 'utils/makeMetadataRoadmapsOptionsFormatter';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { PERSONA_ROADMAP_FIELD } from 'features/PersonasGrid/helpers/constants';
import { LIFECYCLE_ROADMAP_FIELD } from 'features/LifecyclesGrid/helpers/constants';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const isActive = obj => obj.status === ACTIVE_STATUS;
const addEmpty = prepend({});

export default function (
  hasMetadataRoadmaps,
  hasKeyResults2,
  selectedProject,
  roadmaps = [],
  corpRoadmaps = [],
  { roadmapTitle = '', subRoadmapTitle = '' },
) {
  const categories = useSelector(getCategories);
  const customers = useSelector(getActiveCustomers);
  const objectives = useSelector(getObjectives);
  const objectivesCorp = useSelector(state => getObjectives(state, false, METADATA_LEVELS.LEVEL_CORP));
  const phases = useSelector(getPhases);
  const priorities = useSelector(getPriorities);
  const tags = useSelector(getActiveTags);
  const themes = useSelector(getThemes);
  const timeframes = useSelector(getTimeframes);
  const personas = useSelector(selectPersonasData);
  const lifecycles = useSelector(selectLifecyclesData);
  const organization = useSelector(state => getOrganization(state));
  const organizationsAccessControl = useOrganizationsAccessControl();
  const { isDodActive, isChildDragon, getDefaultRoadmapTitleForMetadataItem } = organizationsAccessControl;
  const { canView } = usePermissions();

  const hasNewMetadataAutocomplete = canView(PERMISSION_FEATURES.newRoadmapMetadataAutocomplete);
  const includeCorpRoadmapsForObjectivesAndTimeframes = isChildDragon && hasNewMetadataAutocomplete;

  const newProject = !selectedProject || !Object.keys(selectedProject).length;

  if (newProject && roadmapTitle && selectedProject) {
    const roadmapMatch = roadmaps.find(roadmap => roadmap.title === roadmapTitle);

    selectedProject.roadmap_id = roadmapMatch?.id;

    if (subRoadmapTitle) {
      const productMatch = roadmapMatch.products.find(product => product.title === subRoadmapTitle);

      selectedProject.product_1_id = productMatch?.id;
    }
  }

  const { roadmap_id: roadmapId, product_1_id: product1Id } = selectedProject || {};

  const [options, setOptions] = useState({
    categories: [],
    customers: [],
    objectives: [],
    phases: [],
    priorities: [],
    tags: [],
    themes: [],
    timeframes: [],
    personas: [],
    lifecycles: [],
  });

  const getTitle = (metadataItem, metadataRoadmaps) => {
    if (!hasMetadataRoadmaps) return '';
    const defaultRoadmapTitle = getDefaultRoadmapTitleForMetadataItem(metadataItem);

    if (!metadataRoadmaps?.length) {
      const hasDefaultRoadmapToShow = isDodActive && defaultRoadmapTitle;

      return hasDefaultRoadmapToShow ? ` (${defaultRoadmapTitle})` : ' (Portfolio)';
    }

    const titles = metadataRoadmaps.reduce((list, metadataRoadmap) => {
      const { roadmap } = metadataRoadmap;

      if (roadmap) {
        list.push(roadmap.title);
      }
      return list;
    }, []);

    return titles.length ? ` (${titles.join(', ')})` : '';
  };

  const formatOkrOptions = (objectives, addRoadmapTitle = true) => {
    const getKeyResults2IfOrgHasKeyResults2 = (keyResults, level) => {
      const isKeyResult1Level = level === KEY_RESULT_1_LEVEL;
      const shouldRenderKeyResults2 = isKeyResult1Level && hasKeyResults2;

      return shouldRenderKeyResults2 ? formatChildren(keyResults, addRoadmapTitle) : [];
    };

    const formatKeyResult = keyResult => ({
      ...keyResult,
      children: getKeyResults2IfOrgHasKeyResults2(keyResult.keyResults, keyResult.level),
      key: keyResult.id,
      label: `${keyResult.title}${addRoadmapTitle ? getTitle(keyResult, keyResult.key_result_roadmaps) : ''}`,
      type: 'click',
    });
    const formatChildren = (keyResults = [], addRoadmapTitle = true) => {
      return keyResults.filter(isActive).map(formatKeyResult);
    };

    const options = objectives.map(obj => {
      const defaultRoadmapTitle = getDefaultRoadmapTitleForMetadataItem(obj);

      return {
        ...obj,
        key: obj.id,
        label: isDodActive && addRoadmapTitle ? `${obj.title} (${defaultRoadmapTitle})` : obj.title,
        type: 'click',
        children: formatChildren(obj.keyResults, addRoadmapTitle),
      };
    });

    return options;
  };

  useEffect(() => {
    const formatMetadataOptions = makeMetadataRoadmapsOptionsFormatter(
      organization,
      roadmapId,
      organizationsAccessControl,
      roadmaps,
      corpRoadmaps,
    );

    setOptions({
      categories: formatMetadataOptions('category_roadmaps', addEmpty(categories), {
        needsObject: isDodActive || hasNewMetadataAutocomplete,
        includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp),
      }),
      customers,
      // TODO change this to be able to use formatMetadataOptions
      // todo: better distinguish between both use cases
      objectives: formatOkrOptions(hasNewMetadataAutocomplete ? objectives : addEmpty(objectives), isDodActive),
      objectiveOptions2: formatMetadataOptions('objective_roadmaps', formatOkrOptions(objectives, false), {
        needsObject: true,
        includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp) || includeCorpRoadmapsForObjectivesAndTimeframes,
        childRoadmapKey: 'key_result_roadmaps',
      }),

      phases: formatMetadataOptions('phase_roadmaps', phases, {
        needsObject: hasNewMetadataAutocomplete,
        includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp),
      }),
      priorities: formatMetadataOptions('priority_roadmaps', addEmpty(priorities), {
        needsObject: isDodActive || hasNewMetadataAutocomplete,
        includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp),
      }),
      tags: formatMetadataOptions('tag_roadmaps', tags, {
        needsObject: true,
        includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp),
      }),
      themes: formatMetadataOptions('theme_roadmaps', addEmpty(themes), {
        needsObject: isDodActive || hasNewMetadataAutocomplete,
        includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp),
      }),
      timeframes: formatMetadataOptions('timeframe_roadmaps', addEmpty(timeframes), {
        needsObject: true,
        includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp) || includeCorpRoadmapsForObjectivesAndTimeframes,
      }),
      objectivesCorp: formatMetadataOptions('objective_roadmaps', addEmpty(objectivesCorp), {
        needsObject: isDodActive,
        includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp),
      }),
      personas: formatMetadataOptions(PERSONA_ROADMAP_FIELD, addEmpty(personas), {
        needsObject: isDodActive || hasNewMetadataAutocomplete,
        includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp),
      }),
      lifecycles: formatMetadataOptions(LIFECYCLE_ROADMAP_FIELD, addEmpty(lifecycles), {
        needsObject: isDodActive || hasNewMetadataAutocomplete,
        includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp),
      }),
    });
  }, [
    roadmapId,
    product1Id,
    categories,
    customers,
    objectives,
    phases,
    priorities,
    tags,
    themes,
    timeframes,
    personas,
    lifecycles,
  ]);

  return options;
}
