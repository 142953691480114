import React from 'react';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';

import { groupByOptions } from 'store/portfolioReport/consts';
import DropdownFilter from 'components/DropdownFilter';

export default React.memo(({ systemFields, hasKeyResults, hasProducts, hasHierarchy, hasBet, lsState, updateState }) => {
  const _groupByOptions = groupByOptions(systemFields, hasKeyResults, hasProducts, hasHierarchy, hasBet);
  const _group1Options = _groupByOptions.filter(
    o => ![lsState.group2 && lsState.group2.key, lsState.group3 && lsState.group3.key].includes(o.key),
  );
  const _group2Options = _groupByOptions.filter(
    o => ![lsState.group1 && lsState.group1.key, lsState.group3 && lsState.group3.key].includes(o.key),
  );
  const _group3Options = _groupByOptions.filter(
    o => ![lsState.group1 && lsState.group1.key, lsState.group2 && lsState.group2.key].includes(o.key),
  );

  return (
    <Wrapper>
      <Grid container>
        <Grid item xs={6}>
          <FormGroup row>
            <DropdownFilter
              title="Group by"
              name="groupByData1"
              options={_group1Options}
              selectedValue={lsState.group1}
              allTitle="None"
              onChange={option => {
                updateState({ group1: option });
              }}
            />
            {lsState.group1 && (
              <DropdownFilter
                title="then group by"
                name="groupByData2"
                options={_group2Options}
                selectedValue={lsState.group2}
                allTitle="None"
                onChange={option => {
                  updateState({ group2: option });
                }}
              />
            )}
            {lsState.group2 && (
              <DropdownFilter
                title="then group by"
                name="groupByData3"
                options={_group3Options}
                selectedValue={lsState.group3}
                allTitle="None"
                onChange={option => {
                  updateState({ group3: option });
                }}
              />
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }} />
      </Grid>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  min-height: 50px;
  margin: 5px 0;
`;
