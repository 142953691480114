import React from 'react';

import TinyMenu from 'containers/TinyMenu';
import CustomerRequests from 'routes/CustomerRequests';

const HeaderOptions = props => (
  <>
    <CustomerRequests.HeaderOptions {...props} />
    <TinyMenu onLogout={props.onLogout} />
  </>
);

export default HeaderOptions;
