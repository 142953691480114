import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applyTemplateOnStore, getTemplates, setActiveTemplate } from 'store/templates';

import { extractTemplateIdFromURL } from 'utils/userViews';

const findMatchingTemplate = (templateId, templates = []) => templates.find(template => String(template.id) === templateId);

export default function useLoadTemplateViewFromPrivateLink() {
  const dispatch = useDispatch();
  const [isLoadingActiveTemplate, setIsLoadingActiveTemplate] = useState(false);

  const templates = useSelector(getTemplates);

  const templateId = extractTemplateIdFromURL(window.location.href);

  useEffect(() => {
    if (templateId) {
      const hasTemplate = templateId ? !!findMatchingTemplate(templateId, templates) : false;
    
      setIsLoadingActiveTemplate(hasTemplate);
      dispatch(setActiveTemplate(templateId));
      dispatch(applyTemplateOnStore(templateId));
    }
  }, [templateId]);

  return isLoadingActiveTemplate;
}