import React from 'react';
import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';
import { isNil, multiply, gte } from 'ramda';

import { spacing } from 'design-system/theme';
import { getPointsVarianceColor } from 'utils/estimates/getPointsVarianceColor';

const getValueInPercentage = value => {
  return isNil(value) || Number.isNaN(value) ? 0 : multiply(value, 100);
};

const PointsVarianceCellRenderer = params => {
  const { value } = params;

  return (
    <Wrapper withDangerColor={gte(value, 1.05)}>
      <NumberFormat value={getValueInPercentage(value)} displayType="text" thousandSeparator decimalScale={2} suffix="%" />
      <Circle backgroundColor={getPointsVarianceColor(value)} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacing()}px;

  &&&& span {
    width: fit-content;

    ${({ withDangerColor }) =>
      withDangerColor &&
      css`
        color: ${({ theme }) => theme.palette.scopeVariance.danger};
      `}
  }
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.palette.text.primary};
  border-radius: 50%;
`;

export default PointsVarianceCellRenderer;
