import React from 'react';
import isEmpty from 'lodash/isEmpty';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { StyledIconButton, TimelineGroupBorder, TimelineGroupWrapper } from '../../styled';
import { ROW_HEIGHT, OVERFLOW_TEXT_COLOR } from '../../../helpers';

const TimelineInsideGroup = ({ isOpen, group, groupsWidths, onClick, ...props }) => {
  if (isEmpty(group.info)) {
    return null;
  }

  const { title } = group;
  const groupColor = group?.color;

  const groupInfo = group?.selfTimelineInfo
    ? {
        left: group.selfTimelineInfo.left + groupsWidths[0] + groupsWidths[1],
        width: group.selfTimelineInfo.width,
      }
    : {
        left: group.info.left + groupsWidths[0] + groupsWidths[1],
        width: group.info.width,
      };

  const isSelfWithoutTimeline = group.isSelfTimelineGroup && !group?.selfTimelineInfo;

  const bgColor = isSelfWithoutTimeline ? 'transparent' : groupColor;
  const color = isSelfWithoutTimeline ? OVERFLOW_TEXT_COLOR : null;

  const height = isOpen ? (group?.rows?.length + 1) * ROW_HEIGHT : ROW_HEIGHT;

  return (
    <>
      <TimelineGroupWrapper bgColor={bgColor} color={color} onClick={onClick} {...groupInfo} {...props}>
        <span>{title}</span>
        <StyledIconButton bgColor={bgColor} iconColor={color}>
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </StyledIconButton>
      </TimelineGroupWrapper>
      <TimelineGroupBorder
        left={group.info.left + groupsWidths[0] + groupsWidths[1]}
        width={group.info.width}
        height={height}
        color={group.color}
        isDashed={group?.hasOutsideItems}
        hasTop
        hasBottom
      />
    </>
  );
};

export default TimelineInsideGroup;
