import React from 'react';

import TimelineLightbox from 'features/TimelineLightbox';
import ChartContent from '../ChartContent';

export default ({ chart, wrapper: Wrapper, timelineLightboxProps, title, subtitle, actionButtons }) => {
  if (!chart) {
    return <div />;
  }
  return (
    <Wrapper>
      <ChartContent title={title} subtitle={subtitle} actionButtons={actionButtons}>
        {chart}
        <TimelineLightbox {...timelineLightboxProps} />
      </ChartContent>
    </Wrapper>
  );
};
