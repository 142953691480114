import React from 'react';
import styled from 'styled-components';

const Index = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 68px);

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Index;
