import React from 'react';

import Text from 'design-system/atoms/Text/index';


export default props => {
  return (
    <Text variant="h3" bold {...props}>
      {props.children}
    </Text>
  );
};
