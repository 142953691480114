import { createEntityAdapter } from '@reduxjs/toolkit';
import sortByRank from 'utils/sortByRank';

const objectivesAdapter = createEntityAdapter({
  sortComparer: sortByRank,
});

const keyResultsAdapter = createEntityAdapter({
  sortComparer: sortByRank,
});

export { objectivesAdapter, keyResultsAdapter };
