import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Dialog from 'design-system/molecules/Dialog/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import ImportFromSalesforce from './ImportOptions/ImportFromSalesforce';

export default ({
  canImportFromSalesforce,
  close,
  error,
  getCustomersFromSalesforce,
  getSystemFieldName,
  isOpen,
  importCustomersFromSalesforce,
  onClickBrowseCSVFile,
  resetCustomerCount,
  resetCustomerImportError,
  totalSalesforceCustomers,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [importType, setImportType] = useState('csv');
  const [importUrl, setImportUrl] = useState('');
  const [parsedImportUrl, setParsedImportUrl] = useState('');
  const [showStepper, setShowStepper] = useState(false);
  const [shouldDisplayImportOptions, setShouldDisplayImportOptions] = useState(true);
  const [imported, setImported] = useState(false);

  const salesforceCustomerCountOrMessage = useMemo(() => parseInt(totalSalesforceCustomers), [totalSalesforceCustomers]);

  const handleImportTypeChange = e => {
    setImportType(e.target.value);
  };

  const handleClickNext = async () => {
    if (!imported && importType === 'salesforce' && salesforceCustomerCountOrMessage) {
      await importCustomersFromSalesforce(parsedImportUrl);
      setImported(true);
      setActiveStep(activeStep + 1);
    } else if (imported) {
      handleCancelImport();
    } else if (['salesforce'].includes(importType)) {
      setShouldDisplayImportOptions(false);
      setShowStepper(true);
    }
  };

  const handleCancelImport = () => {
    setActiveStep(0);
    setShowStepper(false);
    resetCustomerCount();
    resetCustomerImportError();
    setImportType('csv');
    setShouldDisplayImportOptions(true);
    setImported(false);
    close();
  };

  const handleSearch = useCallback(async () => {
    resetCustomerImportError();
    if (['salesforce'].includes(importType) && salesforceCustomerCountOrMessage) {
      setActiveStep(activeStep + 1);
      close();
    } else if (importType === 'salesforce') {
      getCustomersFromSalesforce(parsedImportUrl);
      setShouldDisplayImportOptions(false);
      setShowStepper(true);
    }
  }, [parsedImportUrl]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancelImport}
      closeButton
      title={`Import ${getSystemFieldName('customer', true)}`}
      width={showStepper ? '800px' : '500px'}
    >
      {shouldDisplayImportOptions && (
        <RadioGroup value={importType} onChange={handleImportTypeChange}>
          <FormControlLabel
            value="csv"
            control={<Radio />}
            label={
              <TextDeprecated breakwords size="medium">
                Import from a csv file
              </TextDeprecated>
            }
          />
          <ImportCsvContainer $hidden={!isOpen}>
            <InputFileContainer onClick={onClickBrowseCSVFile}>
              <BrowseButton size="small" variant="contained">
                Browse
              </BrowseButton>
            </InputFileContainer>
            <ImportInstructions>You may then map the columns to dragonboat fields</ImportInstructions>
          </ImportCsvContainer>
          {canImportFromSalesforce && (
            <FormControlLabel
              value="salesforce"
              control={<Radio />}
              label={
                <TextDeprecated breakwords size="medium">
                  Import from Salesforce
                </TextDeprecated>
              }
            />
          )}
        </RadioGroup>
      )}
      {importType === 'salesforce' && (
        <>
          {showStepper && (
            <ImportFromSalesforce
              activeStep={activeStep}
              error={error}
              handleSearch={handleSearch}
              importUrl={importUrl}
              parsedImportUrl={parsedImportUrl}
              totalSalesforceCustomers={totalSalesforceCustomers}
              updateImportUrl={url => setImportUrl(url)}
              updateParsedImportUrl={url => setParsedImportUrl(url)}
            />
          )}
          <ButtonContainer>
            <Button onClick={handleCancelImport}>Cancel</Button>
            <Button disabled={!totalSalesforceCustomers && showStepper} color="primary" onClick={handleClickNext}>
              Next
            </Button>
          </ButtonContainer>
        </>
      )}
    </Dialog>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`;

const BrowseButton = styled(Button)`
  &&& {
    text-transform: none;
    box-shadow: none;
    border-radius: 0px;
  }
`;

const ImportCsvContainer = styled.div`
  margin-left: 68px;
  padding-top: 5px;
  padding-bottom: 5px;

  visibility: ${({ $hidden }) => ($hidden ? 'hidden' : 'visible')};
`;

const InputFileContainer = styled.div`
  text-align: right;
  width: 300px;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  cursor: pointer;

  &&&:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
`;

const ImportInstructions = styled.p`
  margin-top: 15px;
  font-size: 0.875rem;
`;
