import moment from 'moment-timezone';
import theme from 'design-system/theme';
import { materialColorsAlt } from 'design-system/themes/default';
import { head, isNil, last, omit, pluck } from 'ramda';
import regression from 'regression';
import { addAlphaToHexColor } from 'src/utils';
import TitleType from '../titleType';
import getSystemFieldName from 'utils/getSystemFieldName';
import { BET, INITIATIVE } from 'constants/projects';

const getLinearRegressionTrendlinePoints = (field, logData) => {
  const pointsForLinearRegression = pluck(field, logData).map((n, idx) => [idx, n]);
  const regressionDataPoints = regression.linear(pointsForLinearRegression).points;
  const [xMinIndex, yMinValue] = head(regressionDataPoints);
  const [xMaxIndex, yMaxValue] = last(regressionDataPoints);

  const hasAnyNullValue = [xMinIndex, yMinValue, xMaxIndex, yMaxValue].some(isNil);

  if (hasAnyNullValue) {
    // Don't show trendline if some value fails to be fetched because this can break the chart
    // Like showing a 1970 date or some other low y value that doesn't make sense
    return null;
  }

  const firstDate = logData[xMinIndex]?.date;
  const lastDate = logData[xMaxIndex]?.date;

  if (!firstDate || !lastDate) {
    return null;
  }

  return {
    xMin: moment(firstDate),
    xMax: moment(lastDate),
    yMin: yMinValue,
    yMax: yMaxValue,
  };
};

/**
 * Update display and position values of hovered label
 * @param {Object} ctx chartjs context
 * @param {Object} event  chartjs event
 */
const toggleChartLabel = (ctx, event) => {
  const { id: annotationId } = ctx;
  const { chart, x } = event;

  const annotationOpts = chart?.options?.plugins?.annotation?.annotations?.[annotationId];

  if (!annotationOpts) {
    return;
  }

  annotationOpts.label.display = !annotationOpts.label.display;
  annotationOpts.label.position = `${(x / chart.chartArea.width) * 100}%`;

  chart.update();
};

export const getTrendlineAnnotations = logData => {
  const closedIssuesTrendlineValues = getLinearRegressionTrendlinePoints('issuesClosed', logData);
  const totalIssuesTrendlineValues = getLinearRegressionTrendlinePoints('issuesTotal', logData);

  const trendlines = {};

  if (closedIssuesTrendlineValues) {
    trendlines.closedIssuesTrendline = {
      label: {
        content: 'Completed trend line',
        display: false,
      },
      type: 'line',
      borderColor: materialColorsAlt.lightGreen,
      borderWidth: 1,
      xMin: closedIssuesTrendlineValues.xMin,
      xMax: closedIssuesTrendlineValues.xMax,
      yMin: closedIssuesTrendlineValues.yMin,
      yMax: closedIssuesTrendlineValues.yMax,
      enter: toggleChartLabel,
      leave: toggleChartLabel,
    };
  }

  if (totalIssuesTrendlineValues) {
    trendlines.totalIssuesTrendline = {
      label: {
        content: 'Total trend line',
        display: false,
      },
      type: 'line',
      borderColor: theme.palette.newLayout.text.grey,
      borderWidth: 1,
      xMin: totalIssuesTrendlineValues.xMin,
      xMax: totalIssuesTrendlineValues.xMax,
      yMin: totalIssuesTrendlineValues.yMin,
      yMax: totalIssuesTrendlineValues.yMax,
      enter: toggleChartLabel,
      leave: toggleChartLabel,
    };
  }

  return trendlines;
};

/**
 * Get predicted date Annotation objects for Line chart
 * @param {Object} targetAndPredictedLinesOptions - Contains info about the max deadline and predicted end date
 * @returns {Object}
 */
const getPredictedDateAnnotations = (targetAndPredictedLinesOptions = {}) => {
  const { maxEndDate, maxPredictedEndDate } = targetAndPredictedLinesOptions;
  const predictedLinesColor = maxEndDate.isAfter(maxPredictedEndDate) ? 'rgb(89, 209, 70)' : 'rgb(255, 21, 75)';

  const annotations = {
    targetDate: {
      label: {
        content: ['Target end date', maxEndDate.format('MMM D YYYY')],
        display: false,
      },
      type: 'line',
      borderColor: predictedLinesColor,
      borderWidth: 2,
      xMin: maxEndDate,
      xMax: maxEndDate,
      yMin: 0,
      enter: toggleChartLabel,
      leave: toggleChartLabel,
    },
    predictedEndDate: {
      label: {
        content: ['Predicted end date', maxPredictedEndDate.format('MMM D YYYY')],
        display: false,
      },
      type: 'line',
      borderColor: predictedLinesColor,
      borderWidth: 2,
      xMin: maxPredictedEndDate,
      xMax: maxPredictedEndDate,
      yMin: 0,
      borderDash: [20, 10],
      enter: toggleChartLabel,
      leave: toggleChartLabel,
    },
  };

  return omit([!maxEndDate.isValid() ? 'targetDate' : '', !maxPredictedEndDate.isValid() ? 'predictedEndDate' : ''], annotations);
};

/**
 * Based on the given annotations find the max X value (date) for the chart
 * @param {moment} originalMaxDate
 * @param {Object} annotations
 * @returns {moment}
 */
const getMaxXValueBasedOnDateAnnotations = (originalMaxDate, annotations) => {
  const validDateXLabelValues = Object.entries(annotations)
    .map(([, val]) => moment(val.xMax))
    .filter(date => date.isValid());

  const sortedArray = validDateXLabelValues.sort((a, b) => b.valueOf() - a.valueOf());
  const maxDateBasedOnAnnotation = head(sortedArray);

  if (maxDateBasedOnAnnotation && maxDateBasedOnAnnotation.isAfter(originalMaxDate)) {
    // Adding 1 week so the line doesn't end up exactly at the end of the chart
    return maxDateBasedOnAnnotation.clone().add(1, 'week');
  }

  return originalMaxDate;
};

/**
 * Get data properties for the progress report line chart
 * @param {Array} logData
 * @param {String} startDate
 * @param {String} endDate
 * @param {Object} targetAndPredictedLinesOptions - Setup for the target and predicted lines chart overlay
 */
export const getProgressChartData = (logData, startDate, endDate, targetAndPredictedLinesOptions) => {
  if (!logData.length) return {};

  const showPredictedDateAnnotations = targetAndPredictedLinesOptions?.checked;

  const combinedData = {
    times: logData.map(d => d.date),
    inProgress: logData.map(d => d.issuesInProgressAndCompleted),
    total: logData.map(d => d.issuesTotal),
    closed: logData.map(d => d.issuesClosed),
  };

  const totalData = combinedData.total;
  const times = combinedData.times.map(d => moment(d).toDate());
  const labels = [moment(startDate).toDate(), ...times, moment(endDate).toDate()];
  const annotations = {
    // ...getTrendlineAnnotations(logData),
    ...(showPredictedDateAnnotations ? getPredictedDateAnnotations(targetAndPredictedLinesOptions) : {}),
  };

  const xSuggestedMax = getMaxXValueBasedOnDateAnnotations(moment(endDate), annotations);

  return {
    labels,
    datasets: [
      {
        label: 'Total',
        data: [null, ...totalData, null],
        borderColor: materialColorsAlt.darkGray,
        fill: false,
        borderWidth: 1,
      },
      {
        label: 'In Progress + Completed',
        data: [null, ...combinedData.inProgress, null],
        borderColor: materialColorsAlt.lightBlue,
        backgroundColor: [materialColorsAlt.lightBlue],
        fill: false,
        borderWidth: 1,
      },
      {
        label: 'Completed',
        borderColor: addAlphaToHexColor(materialColorsAlt.lightGreen, 0.5),
        data: [null, ...combinedData.closed, null],
        backgroundColor: addAlphaToHexColor(materialColorsAlt.lightGreen, 0.5),
        fill: true,
      },
    ],
    annotations,
    scales: {
      x: {
        suggestedMax: xSuggestedMax.toDate(),
      },
    },
  };
};

/**
 * Gets chart title based on `titleType`
 * @param {TitleType} titleType
 * @param {*} systemFields
 * @returns {String} title
 */
export const getChartTitle = (titleType = TitleType.PORTFOLIO, systemFields) => {
  if (titleType === TitleType.PORTFOLIO) {
    return 'Portfolio Progress Trend';
  }
  if (titleType === TitleType.INITIATIVE) {
    return `${getSystemFieldName(INITIATIVE, systemFields)} Progress Trend`;
  }
  if (titleType === TitleType.BET) {
    return `${getSystemFieldName(BET, systemFields)} Progress Trend`;
  }

  throw new Error(`Unsupported title type: ${titleType}`);
};
