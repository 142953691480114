import React, { useMemo } from 'react';
import BarChart from 'components/ReactChart/BarChart';
import { uniq, defaultTo, values, keys } from 'ramda';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import invertedTextColor from 'design-system/utils/invertedTextColor';

import ChartLegend from 'routes/Dashboard/Dashboards/Charts/ChartLegend';

import normalizeArray from 'utils/normalizeArray';
import calculateTextLines from 'utils/calculateTextLines';
import ticksFontStyles from 'utils/charts/ticksFontStyles';

import { STACKED_CHART_ROW_HEIGHT } from 'constants/charts';
import { UNDEFINED_LABEL } from 'constants/common';

import useBarStackedCharDatalabelsConfig from 'hooks/charts/useBarStackedCharDatalabelsConfig';

const MAX_NUM_LINES = 1;
const MAX_NUM_CHARS = 25;
const FONT_SIZE = 11;
const MAX_WIDTH_FOR_LABEL = 120;
const CHART_MIN_HEIGHT = 200;

const defaultZero = defaultTo(0);

const truncateLabel = label => {
  const numLines = calculateTextLines(label, MAX_WIDTH_FOR_LABEL, FONT_SIZE);

  if (numLines > MAX_NUM_LINES && label.length > MAX_NUM_CHARS) return `${label.slice(0, MAX_NUM_CHARS)}...`;

  return label;
};

const formattedNumber = value => parseFloat(defaultZero(value).toFixed(2)).toLocaleString('en-US');

const createDataset = (entityId, dataLength, normalizedEntities, formatter) => {
  const color = normalizedEntities[entityId]?.color;

  return {
    id: entityId,
    label: normalizedEntities[entityId]?.title,
    data: Array.from(Array(dataLength)),
    backgroundColor: color,
    hoverBackgroundColor: color,
    datalabels: {
      color: invertedTextColor(color, true),
      formatter(value) {
        return formatter(value);
      },
    },
  };
};

const mapData = (data, entities, stackEntities, formatter) => {
  const normalizedEntities = normalizeArray(entities, 'id');
  const normalizedStackEntities = normalizeArray(stackEntities, 'id');

  const allIds = uniq(keys(data));

  const labels = allIds.map(id => normalizedStackEntities[id]?.title || UNDEFINED_LABEL);

  const keyedData = allIds.reduce((keyedData, stackId, index) => {
    const stackData = data[stackId];
    const ids = uniq(keys(stackData));

    ids.forEach(id => {
      if (!(id in keyedData)) {
        keyedData[id] = createDataset(id, allIds.length, normalizedEntities, formatter);
      }
      keyedData[id].data[index] = stackData[id];
    });

    return keyedData;
  }, {});

  return { keyedData, labels, allIds };
};

const useAllocationStackedChart = (data, entities, stackEntities, lsState, formatter = formattedNumber, tickYProps) => {
  const mappedData = useMemo(() => {
    return mapData(data, entities, stackEntities, formatter);
  }, [data, entities, stackEntities, formatter]);

  const { displayLegendInline } = lsState;

  const chartData = useMemo(() => {
    return {
      labels: mappedData.labels,
      ids: mappedData.allIds,
      datasets: values(mappedData.keyedData),
    };
  }, [mappedData, lsState.duration, lsState.showPlanned, lsState.showCompleted, lsState.showReported, lsState.sumBy]);

  const datalabelsConfig = useBarStackedCharDatalabelsConfig(chartData.datasets);

  const barOptions = {
    hover: {
      animationDuration: 0,
    },
    maintainAspectRatio: true,
    animation: {
      duration: 0,
    },
    scales: {
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          drawTicks: true,
          offset: true,
        },
        ticks: {
          beginAtZero: true,
          callback(value) {
            const label = this.getLabelForValue(value);

            return truncateLabel(label);
          },
          crossAlign: 'far',
          display: true,
          font: ticksFontStyles,
          ...tickYProps,
        },
      },
      x: {
        stacked: true,

        grid: {
          display: false,
        },
        ticks: {
          font: ticksFontStyles,
        },
      },
    },
    plugins: {
      datalabels: datalabelsConfig,
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label(context) {
            const { dataset } = context || {};
            const { label: dataType } = dataset || {};

            const label = `${dataType}: ${formatter(context.raw)}`;

            return label;
          },
        },
      },
    },
  };

  const height = defaultZero(chartData?.labels?.length) * STACKED_CHART_ROW_HEIGHT;

  if (height === 0) return '';

  const chart = (
    <>
      <BarChart
        id="allocation-bar-chart"
        height={CHART_MIN_HEIGHT}
        plugins={[ChartDataLabels]}
        data={chartData}
        options={barOptions}
      />
      {!displayLegendInline && <ChartLegend legends={chartData?.datasets} maxLegendItems={6} />}
    </>
  );

  return [chart];
};

export default useAllocationStackedChart;
