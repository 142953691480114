import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';
import ErrorMessage from 'design-system/atoms/ErrorMessage/index';


import ConnectButton from 'routes/Settings/Integration/components/ConnectButton';

const TokenTextField = styled(TextField)``;

const Wrapper = styled.form`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;

  ${ConnectButton} {
    width: 100px;
    height: 40px;
  }

  ${TokenTextField} {
    flex-grow: 1;
    margin-top: 0px;
  }
`;

const LogoContainer = styled(Grid)`
  &&&& {
    display: flex;
    justify-content: flex-end;
    align-items: start;

    img {
      width: 50%;
      margin: 0 8px;
    }
  }
`;

const TokenContainer = styled(Grid)`
  &&&& {
    min-width: 400px;
  }
`;

const TokenError = styled(({ className, ...props }) => {
  return (
    <div className={className}>
      <ErrorMessage {...props} />
    </div>
  );
})`
  &&&& {
    margin-top: 10px;
  }
`;

export { TokenTextField, ConnectButton, Wrapper, LogoContainer, TokenContainer, TokenError };
