import React, { useState } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import SettingsBoxIcon from '@material-ui/icons/SettingsApplicationsOutlined';

import CollapsePanel from 'components/CollapsePanel';

import ProjectEstimates from './Estimates';
import BusinessValue from './BusinessValue';
import ProjectRicePanel from './ProjectRicePanel';

export default ({
  formData,
  updateFormData,
  teams,
  skills,
  users,
  onUpdateEstimate,
  onDeleteEstimate,
  onAddEstimate,
  onUpdateProject,
  onAddTask,
  onDeleteTask,
  onUpdateTask,
  getSystemFieldName,
  userCanEdit,
  oldLightboxActive,
  selectedRoadmapVersion,
  selectedProject,
  estimates,
  estimatesBeingCreated,
  disabledFields = [],
}) => {
  const {
    business_value: businessValue,
    reach_score: reachScore,
    impact_score: impactScore,
    confidence_score: confidenceScore,
    effort_score: effortScore,
  } = formData;

  const { planned_moar: plannedMoar } = selectedProject;

  const [expanded, setExpanded] = useState({
    estimates: true,
    tasks: true,
  });
  const _handlePanelChange = panel => (e, val) => {
    setExpanded({ ...expanded, [panel]: val });
  };

  const _handleProjectUpdate = update => onUpdateProject(update, true);

  const totalCost = estimates.reduce((acc, est) => {
    const team = teams.find(t => (est.team ? est.team.title === t.title : est.team_id === t.id));

    acc += team ? Math.round((est.numStaff || 0) * (team.cost || 0) * (est.duration / 7)) : 0;
    return acc;
  }, 0);

  const estimatesPanelTitle = (
    <AddButton
      aria-label="add project estimate"
      size="small"
      onClick={e => {
        e.stopPropagation();
        setExpanded({ ...expanded, estimates: true });
        onAddEstimate();
      }}
      disabled={
        !userCanEdit ||
        (disabledFields || []).includes('start_date_estimates') ||
        (disabledFields || []).includes('end_date_estimates')
      }
      color="primary"
    >
      <AddIcon /> New Estimate
    </AddButton>
  );

  return (
    <Grid container spacing={24}>
      <Grid item xs={10} style={{ maxWidth: '78%', ...(!oldLightboxActive ? { padding: '0' } : {}) }}>
        <BusinessValue
          businessValue={businessValue}
          onBusinessValueChange={val => updateFormData({ business_value: val })}
          effortScore={effortScore}
          calculatedMoar={plannedMoar}
          updateEffortScore={val => updateFormData({ effort_score: val })}
          userCanEdit={userCanEdit}
          saveProject={_handleProjectUpdate}
        />
        <StaffCostContainer oldLightboxActive={oldLightboxActive}>
          <StyledFormControl fullWidth margin="dense">
            <NumberFormat
              customInput={TextField}
              thousandSeparator
              InputLabelProps={{ shrink: true }}
              label={
                <span>
                  Staff cost
                  <a href="/settings/teams" target="_blank" rel="noopener noreferrer">
                    <SettingsBoxIcon style={{ color: '#6b6b6b', marginLeft: 3, verticalAlign: 'middle', marginTop: -2 }} />
                  </a>
                </span>
              }
              value={totalCost}
              disabled
            />
          </StyledFormControl>
        </StaffCostContainer>
        <CollapsePanel
          style={{ marginTop: -69, boxShadow: 'none' }}
          title={estimatesPanelTitle}
          expanded={expanded.estimates}
          onChange={_handlePanelChange('estimates')}
        >
          <ProjectEstimates
            estimates={estimates}
            teams={teams}
            skills={skills}
            userCanEdit={userCanEdit}
            onUpdateEstimate={onUpdateEstimate}
            onDeleteEstimate={onDeleteEstimate}
            onAddEstimate={onAddEstimate}
            getSystemFieldName={getSystemFieldName}
            oldLightboxActive={oldLightboxActive}
            selectedRoadmapVersion={selectedRoadmapVersion}
            estimatesBeingCreated={estimatesBeingCreated}
            disabledFields={disabledFields}
          />
        </CollapsePanel>
      </Grid>
      <Grid item xs={3} style={{ maxWidth: '21%', ...(!oldLightboxActive ? { padding: '0' } : {}) }}>
        <ProjectRicePanel
          formData={formData}
          reachScore={reachScore}
          impactScore={impactScore}
          confidenceScore={confidenceScore}
          effortScore={effortScore}
          onReachScoreChange={val => updateFormData({ reach_score: val })}
          onImpactScoreChange={val => updateFormData({ impact_score: val })}
          onConfidenceScoreChange={val => updateFormData({ confidence_score: val })}
          onEffortScoreChange={val => updateFormData({ effort_score: val })}
          saveProject={_handleProjectUpdate}
          userCanEdit={userCanEdit}
        />
      </Grid>
    </Grid>
  );
};

const AddButton = styled(Button)`
  &&&& {
    margin: 0;
  }
`;

const StaffCostContainer = styled.div`
  margin: ${({ oldLightboxActive }) => `38px 0 0 ${oldLightboxActive ? '0' : '6px'}`};
  width: calc(30% - 24px);
  position: relative;
  left: 46.4%;
  z-index: 1;
  display: inline-block;
`;

const StyledFormControl = styled(FormControl)`
  input {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  }
`;
