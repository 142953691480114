import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import compact from 'lodash/compact';

import MicroChip from 'design-system/atoms/MicroChip/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';


const defaultGetNumberOfTextRowsToDisplayOnGridCell = () => 0;

const StyledMicroChip = styled(MicroChip)`
  &&&& {
    margin: 2px;
    max-width: calc(100% - 4px);
  }
`;

const Wrapper = styled.div`
  &&&&& {
    text-overflow: ellipsis;
    white-space: initial;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: ${({ $nOfTextRowsToDisplay = 0 }) => ($nOfTextRowsToDisplay > 0 ? $nOfTextRowsToDisplay - 1 || 1 : 0)};
    -webkit-box-orient: vertical;
    height: auto;
  }
`;

const ChipsCellRenderer = ({
  api,
  colDef,
  value,
  reactContainer,
  getNumberOfTextRowsToDisplayOnGridCell = defaultGetNumberOfTextRowsToDisplayOnGridCell,
}) => {
  const [parentColumnWidth, setParentColumnWidth] = useState(null);
  const {
    field,
    formatValue,
    labelField,
    chipVariant,
    chipFullWidth = true,
    microChipComponent: MicroChipComponent = StyledMicroChip,
    showTooltip = false,
    cellRendererParams: { onChipClick } = {},
    colorField = 'color',
  } = colDef;

  useEffect(() => {
    if (!showTooltip) {
      return;
    }

    const _onColumnResize = e => {
      const resizedCol = e.column;
      const parentColName = field;
      const isParentColumn = resizedCol && parentColName === resizedCol.colDef.field;

      if (isParentColumn) {
        setParentColumnWidth(resizedCol.actualWidth);
      }
    };

    api.addEventListener('columnResized', _onColumnResize);

    return () => {
      api.removeEventListener('columnResized', _onColumnResize);
    };
  }, [showTooltip, field]);

  if (!value) {
    return null;
  }

  const formattedValue = formatValue ? formatValue(value) : value;

  if (typeof formattedValue === 'string') {
    if (reactContainer) {
      reactContainer.style.display = 'inline-block';
    }
    return <span>{formattedValue}</span>;
  }

  if (formattedValue instanceof Array) {
    const _renderChips = () => {
      return compact(formattedValue).map(valueFormatted => {
        const _renderChip = () => (
          <MicroChipComponent
            key={valueFormatted[labelField]}
            label={valueFormatted[labelField]}
            color={valueFormatted[colorField]}
            variant={chipVariant}
            fullWidth={chipFullWidth}
            onClick={() => onChipClick && onChipClick(valueFormatted)}
          />
        );

        if (showTooltip) {
          return (
            <TooltipOnOverflow
              text={valueFormatted[labelField]}
              containerWidth={parentColumnWidth}
              elementWithOverflow="span > span"
            >
              {_renderChip()}
            </TooltipOnOverflow>
          );
        }

        return _renderChip();
      });
    };

    return <Wrapper $nOfTextRowsToDisplay={getNumberOfTextRowsToDisplayOnGridCell()}>{_renderChips()}</Wrapper>;
  }

  return null;
};

export default ChipsCellRenderer;
