import { equals } from 'ramda';
import { PERMISSION_ACTIONS, PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { FeatureFlags } from '@dragonboat/config';

import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import { DETAILS_TAB, RECURRING_NOTIFICATIONS_TAB, SHARE_PUBLIC_LINK_TAB } from './constants';
import { INTEGRATIONS_KEYS } from 'constants/integrations';

const isSharePublicLinkTab = equals(SHARE_PUBLIC_LINK_TAB);
const isRecurringNotificationsTab = equals(RECURRING_NOTIFICATIONS_TAB);

export default ({ checks }) => {
  const userCanViewTab = ({ tab, view, excludedTabs }, permissions) => {
    if (excludedTabs.includes(tab)) {
      return false;
    }

    if (isSharePublicLinkTab(tab)) {
      return permissions.canView(PERMISSION_FEATURES.headlessShare);
    }

    if (isRecurringNotificationsTab(tab)) {
      return permissions.canView(PERMISSION_FEATURES.userViewRecurringNotifications, { view });
    }

    if (tab !== DETAILS_TAB || checks.userIsOwnerOrLeader()) return true;

    return view
      ? permissions.canUpdate(PERMISSION_RESOURCES.userView, { view })
      : permissions.canCreate(PERMISSION_RESOURCES.userView);
  };

  const userCanUpdateViewDetailsAndTitle = ({ view }) => checks.userViewIsNotStaticAndIsOwner(view);

  return {
    [PERMISSION_FEATURES.userViewDialogTabs]: {
      [PERMISSION_ACTIONS.view]: [userCanViewTab],
      [PERMISSION_ACTIONS.update]: [userCanUpdateViewDetailsAndTitle],
    },
    [PERMISSION_FEATURES.userViewRecurringNotifications]: {
      [PERMISSION_ACTIONS.view]: [
        () => checks.isFeatureFlagEnabled(FeatureFlags.HAS_USER_VIEW_RECURRING_NOTIFICATIONS),
        ({ view }) => !view || !!view?.userViewRecurringNotifications?.length,
      ],
      [PERMISSION_ACTIONS.update]: [
        () => checks.isFeatureFlagEnabled(FeatureFlags.HAS_USER_VIEW_RECURRING_NOTIFICATIONS),
        (context, permissions) => permissions.canUpdate(PERMISSION_RESOURCES.userViewRecurringNotification, context),
      ],
      [PERMISSION_ACTIONS.create]: [
        () => checks.isFeatureFlagEnabled(FeatureFlags.HAS_USER_VIEW_RECURRING_NOTIFICATIONS),
        (context, permissions) => permissions.canCreate(PERMISSION_RESOURCES.userViewRecurringNotification, context),
        ({ integrationType }) => integrationType === INTEGRATIONS_KEYS.slack,
      ],
      [PERMISSION_ACTIONS.delete]: [
        () => checks.isFeatureFlagEnabled(FeatureFlags.HAS_USER_VIEW_RECURRING_NOTIFICATIONS),
        (context, permissions) => permissions.canDelete(PERMISSION_RESOURCES.userViewRecurringNotification, context),
      ],
    },
  };
};
