import axios from 'axios';
import React, { Component, useEffect, useRef } from 'react';
import find from 'lodash/find';
import { ToastContainer } from 'react-toastify';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { bool } from 'prop-types';
import { path } from 'ramda';

import { SHOW_TOOLBOX, getReadOnlyRoutes } from 'config';
import {
  ADMIN_USER,
  OWNER_USER,
  LEADER_USER,
  READ_ONLY_USER,
  EXTERNAL_REQUESTOR,
  MANAGER_USER,
  EDITOR_USER,
} from '@dragonboat/permissions';
import { appActions } from 'store/app';
import { loginActions } from 'store/login';
import { hideImportJQLLightbox } from 'store/ideas';
import { getPathWithoutLeadingSlash } from 'utils/index';
import setOrganizationHeader from 'utils/setOrganizationHeader';
import { setAuthorizationToken } from 'utils/setAuthorizationToken';
import NotFoundRedirect from 'routes/NotFound/NotFoundRedirect';
import ConfigToolBox from 'containers/ConfigToolBox';
import OutdatedAlert from 'containers/OutdatedAlert';
import NotificationsWrapper from 'containers/NotificationsWrapper';
import { getWelcomeRoute, parseQuery } from 'utils';
import ProjectLightBox from 'containers/ProjectLightBox';
import TemplatesLightbox from 'containers/TemplatesLightbox';
import ObjectivesLightBox from 'containers/ObjectivesLightBox';
import ViewsDialog from 'containers/ViewsDialog';
import ScenariosListDialog from 'containers/ScenariosListDialog';
import { getOrgHasJiraIntegrated } from 'store/organization/selectors';
import ErrorDialog from 'design-system/organisms/ErrorDialog/index';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';
import DragonIconHelpButton from 'design-system/molecules/DragonIconHelpButton/index';

import useApplicationRoutes from 'hooks/useApplicationRoutes';
import InviteUsersDialog from 'containers/InviteUsersDialog';
// import UpdateJiraKeyDialog from 'containers/UpdateJiraKeyDialog';
import SlackAuthenticationErrorDialog from '../../components/SlackAuthenticationErrorDialog';

import ErrorBoundary from './ErrorBoundary';
import NewLayout from './NewLayout';
import { DASHBOARD_HOME_PATHNAME } from 'hooks/useApplicationRoutes/consts';
import { AUTH_HOST } from 'constants/browserCookies';
import { OPEN_IDEA } from 'constants/queryParams';
import { FORECAST_PAGE, PAGES_WITH_MOBILE_SUPPORT } from 'constants/filters/pages';
import useLoadPageActiveView from 'hooks/userViews/useLoadPageActiveView';

import useTemplateChanges from 'hooks/useTemplateChanges';
import useOneClickPlan from 'hooks/useOneClickPlan';
import SaveScenarioDialog from 'containers/SaveScenarioDialog';
import SaveScenarioChangesPromptDialog from 'containers/SaveScenarioChangesPromptDialog/SaveScenarioChangesPromptDialog';
import useFeatureFlags from 'hooks/useFeatureFlags';
import usePermissions from 'hooks/permissions/usePermissions';
import { FeatureFlags } from '@dragonboat/config';
import { PERMISSION_FEATURES, PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';
import Dragonbot from 'containers/Dragonbot';
import { getPageIdFromPath } from 'utils/userViews';
import { LightboxesControlProvider } from 'hooks/lightboxes/useLightboxesControl';
import { WidgetLightboxProvider } from 'routes/Dashboard/Dashboards/WidgetLightbox/hooks/useWidgetLightboxContext';
import DiscoveryBotDialog from 'containers/DiscoveryBotDialog';
import { LOCATION_CHANGE } from 'store/app/types';
import { GenericErrorDialogProvider } from 'hooks/useGenericErrorDialog';
import useUserRoutesContext from 'hooks/useApplicationRoutes/useUserRoutesContext';
import DeleteScenarioDialog from 'containers/DeleteScenarioDialog';

const HOME_PAGE = DASHBOARD_HOME_PATHNAME;

const getQueryParameters = path(['location', 'search']);
const getPathname = path(['location', 'pathname']);
const createCookieDomainFromURL = url => ['', ...new URL(url).hostname.split('.').slice(1)].join('.');

const exist = Boolean;

class Main extends Component {
  /* eslint-disable */
  static propTypes = {
    jiraIntegrated: bool,
    showOutdatedAlert: bool,
  };
  /* eslint-enable */

  static defaultProps = {
    jiraIntegrated: false,
    showOutdatedAlert: false,
  };

  state = {
    showOutdatedAlert: false,
    isFirstLoad: true,
  };

  componentDidMount() {
    window.addEventListener('popstate', () => {
      if (this.props.showImportJqlModal) {
        this.props.hideImportJQLLightbox();
      }
    });

    // Temporarily disable popup alert until further implementation/fix
    let showOutdatedAlert = false; // checkRolePermission('SOW_OUTDATED_IDEAS_ALERT', this.props.user);

    const { jwt, slug, stateKey } = parseQuery(window.location.search);

    if (jwt) {
      this.props.setTokenValue(jwt, {});
      setAuthorizationToken(jwt);
      showOutdatedAlert = false;
    }

    if (stateKey) {
      this.props.fetchStateFromAPI(stateKey);
    }
    if (slug) {
      setOrganizationHeader(slug);
    }

    // on tests not show outdated alert
    if (window.Cypress) {
      showOutdatedAlert = false;
    }

    this.setState({
      showOutdatedAlert,
    });
  }

  _userLogout = async () => {
    await axios.delete('/api/users/default-state');
    window.history.pushState({}, '', `/login?next=${getWelcomeRoute()}`);
    this.props.clearAuthTokenAndStore();
  };

  getSelectedPage = () =>
    find(
      this.props.pages,
      page => page.path === this.props.match.params.tab || page.path === this.props.location.pathname.substring(1),
    );

  hasAccess = path => {
    const pathWithoutLeadingSlash = getPathWithoutLeadingSlash(path);

    return (this.props.pages || []).some(page => {
      return page.path === pathWithoutLeadingSlash;
    });
  };

  render() {
    const { appError, classes, globalMessage, location, match, pages, setGlobalMessage, systemFields, tabs, user } = this.props;

    if (this.state.isFirstLoad) {
      const pathname = getPathname(this.props);
      const queryParameters = getQueryParameters(this.props);

      const isAlreadyOnHomePage = pathname.includes(HOME_PAGE);

      if (!isAlreadyOnHomePage) {
        const hasOpenIdeaParameter = exist(queryParameters) && queryParameters.includes(OPEN_IDEA);

        if (hasOpenIdeaParameter && !this.hasAccess(pathname)) {
          const homePathWithParameters = `${HOME_PAGE}${queryParameters}`;

          return <Redirect to={homePathWithParameters} />;
        }
      }

      this.setState({
        isFirstLoad: false,
      });
    }

    const selectedTab = this.getSelectedPage();

    const pageId = getPageIdFromPath(getPathname(this.props));
    const pageHasMobileSupport = PAGES_WITH_MOBILE_SUPPORT.includes(pageId);

    if (
      selectedTab?.path !== 'portal' &&
      this.props.location.pathname !== '/user/preferences' &&
      /* TODO: PERMISSION */
      [EXTERNAL_REQUESTOR].includes(user.role_id)
    ) {
      return <Redirect to="/portal" />;
    }

    if (
      this.props.location.pathname.includes('/settings/billing') &&
      /* TODO: PERMISSION */
      ![ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER].includes(user.role_id)
    ) {
      return <Redirect to={getWelcomeRoute(user)} />;
    }

    const isReduxStoreReady = this.props.isLoginRehydrated;

    if (!this.props.isAuthenticated) {
      if (!isReduxStoreReady) {
        return <div />;
      }

      const next = encodeURIComponent(this.props.location.pathname);
      const search = this.props.location.search?.replace('?', '');

      this.props.clearAuthTokenAndStore();

      return <Redirect to={`/login?next=${next}&${search}`} />;
    }

    if (this.props.location.pathname.includes('/manage/timeline')) {
      return <Redirect to={`/ideas/timeline/${this.props.location.search}`} />;
    } else if (this.props.location.pathname.includes('/manage/staffing')) {
      return <Redirect to={`/forecast/resources/${this.props.location.search}`} />;
    }

    if (!this.props.canView(PERMISSION_FEATURES.portfolioModule)) {
      const { params } = match;
      const { pathname, search } = location;

      if (pathname.includes('forecast/timeline')) {
        const hasForecastTimelineAccess = this.hasAccess('dashboard/forecast-timeline', FORECAST_PAGE);

        if (hasForecastTimelineAccess) {
          return <Redirect to={`/dashboard/forecast-timeline/${search}`} />;
        }
      } else if (pathname.includes('goals/grid')) {
        const hasGoalsModeGridAccess = this.hasAccess('dashboard/goals-grid');

        if (hasGoalsModeGridAccess) {
          return <Redirect to={`/dashboard/goals-grid/${search}`} />;
        }
      } else if (pathname.includes('goals/snapshot')) {
        const hasGoalsModeSnapshotAccess = this.hasAccess('dashboard/goals-snapshot');

        if (hasGoalsModeSnapshotAccess) {
          return <Redirect to={`/dashboard/goals-snapshot/${search}`} />;
        }
      } else if (pathname.includes('goals/allocation-report')) {
        const hasGoalsModeAllocationAccess = this.hasAccess('dashboard/goals-allocation-report');

        if (hasGoalsModeAllocationAccess) {
          return <Redirect to={`/dashboard/goals-allocation-report/${search}`} />;
        }
      } else if (pathname.includes('ideas/pdlc')) {
        const hasPDLCGridAccess = this.hasAccess('dashboard/ideas-pdlc');

        if (hasPDLCGridAccess) {
          return <Redirect to={`/dashboard/ideas-pdlc/${search}`} />;
        }
      } else if (pathname.includes('goals/summary')) {
        const hasGoalSummaryAccess = this.hasAccess('dashboard/goals-summary');

        if (hasGoalSummaryAccess) {
          return <Redirect to={`/dashboard/goals-summary/${search}`} />;
        }
      } else if (pathname.includes('goals/canvas')) {
        const hasGoalCanvasAccess = this.hasAccess('dashboard/goals-canvas');

        if (hasGoalCanvasAccess) {
          return <Redirect to={`/dashboard/goals-canvas/${search}`} />;
        }
      } else if (pathname.includes('metrics/canvas')) {
        const hasMetricsCanvasAccess = this.hasAccess('dashboard/metrics-canvas');

        if (hasMetricsCanvasAccess) {
          return <Redirect to={`/dashboard/metrics-canvas/${search}`} />;
        }
      } else if (pathname.includes('metrics/grid')) {
        const hasMetricsGridAccess = this.hasAccess('dashboard/metrics-grid');

        if (hasMetricsGridAccess) {
          return <Redirect to={`/dashboard/metrics-grid/${search}`} />;
        }
      } else if (pathname.includes('metrics/chart')) {
        const hasMetricsChartAccess = this.hasAccess('dashboard/metrics-chart');

        if (hasMetricsChartAccess) {
          return <Redirect to={`/dashboard/metrics-chart/${search}`} />;
        }
      } else if (getReadOnlyRoutes(this.props.canViewMissionControlHome).includes(params.tab)) {
        const redirectPath = pathname.replace(params.tab, 'dashboard');
        const hasRedirectAccess = this.hasAccess(redirectPath?.substring(1));

        if (hasRedirectAccess) {
          return <Redirect to={`${redirectPath}/${search}`} />;
        }
      }
    }

    return (
      <div className={`${classes.Main} ${pageHasMobileSupport ? classes.MobileWrapper : ''}`}>
        <LightboxesControlProvider>
          <WidgetLightboxProvider>
            <GenericErrorDialogProvider>
              <ToastContainer progressClassName="dragonboat-react-toastify-progress-bar" />
              {(SHOW_TOOLBOX || localStorage.getItem('showConfigTollbox')) && <ConfigToolBox systemFields={systemFields} />}

              {this.state.showOutdatedAlert && <OutdatedAlert />}
              {globalMessage && <ErrorDialog message={globalMessage} onClose={() => setGlobalMessage(null)} />}

              <ProjectLightBox />
              <ObjectivesLightBox />
              <TemplatesLightbox location={this.props.location} />
              <ViewsDialog />
              <InviteUsersDialog />
              {/* Ideally this could be handled by a single component to avoid confusion. Future work */}
              {/* This dialog handles the prompt to user if pending changes exists */}
              <SaveScenarioChangesPromptDialog history={this.props.history} pageId={pageId} />
              {/* This dialog handles actual save action (naming and descriptions) of the changes */}
              <SaveScenarioDialog history={this.props.history} pageId={pageId} />

              <DeleteScenarioDialog pageId={pageId} />

              {/* This dialog has been removed temporarily: <UpdateJiraKeyDialog /> */}
              <SlackAuthenticationErrorDialog />

              <ScenariosListDialog />

              <DiscoveryBotDialog />

              <ErrorBoundary appError={appError} setAppError={this.props.setAppError} userLogout={this._userLogout}>
                <div className={classes.MainContent} id="outer-container">
                  <div className={classes.TabComponentWrapper}>
                    <NotificationsWrapper>
                      <Switch>
                        {pages.map(({ exact, path, component: Component, props }) => (
                          <Route
                            {...(exact === false ? {} : { exact: true })}
                            path={`/${path}`}
                            render={() =>
                              wrapRouteComponent(Component, {
                                ...props,
                                selectedTab,
                                tabs,
                                user: this.props.user,
                                location: this.props.location,
                                history: this.props.history,
                                onLogout: this.props.clearAuthTokenAndStore,
                                organization: this.props.organization,
                                isOneClickPlanModeActive: this.props.isOneClickPlanModeActive,
                              })
                            }
                            key={path}
                          />
                        ))}

                        <Redirect exact path="/manage/timeline" to={`/dashboard/timeline/${this.props.location.search}`} />
                        <NotFoundRedirect />
                      </Switch>
                    </NotificationsWrapper>
                  </div>
                </div>
              </ErrorBoundary>
              {this.props.isDragonbotEnabled && <Dragonbot />}
              <DragonIconHelpButton id="dragon-help-icon-button" variant="secondary" type="fill" />
            </GenericErrorDialogProvider>
          </WidgetLightboxProvider>
        </LightboxesControlProvider>
      </div>
    );
  }
}

const wrapRouteComponent = (Component, props = {}) => {
  // const getHasPortfolioMode = () => {
  //   const route = find(
  //     props.pages,
  //     page => {
  //       const matches = page.path === this.props.match.params.tab || page.path === this.props.location.pathname.substring(1);

  //       if (matches) return true;

  //       if
  //     }
  //   );

  //   if (!route.subMenu) return route.hasPortfolioMode;

  //   const subMenuOption = route.subMenu.find(sm => sm.path === this.props.location.pathname.substring(1));

  //   if (subMenuOption) return subMenuOption.hasPortfolioMode;

  //   return route.hasPortfolioMode;
  // };

  let {
    headerFilters: HeaderFilters,
    headerOptions: HeaderOptions,
    headerLeftContent: HeaderLeftContent,
  } = props.selectedTab || {};

  HeaderFilters = props.headerFilters || HeaderFilters;
  HeaderOptions = props.headerOptions || HeaderOptions;
  HeaderLeftContent = props.headerLeftContent || HeaderLeftContent;

  return (
    <>
      <NewLayout
        organization={props.organization}
        history={props.history}
        tabs={props.tabs}
        selectedTab={props.selectedTab}
        onLogout={props.onLogout}
        user={props.user}
        headerFilters={HeaderFilters && <HeaderFilters hasPortfolioOption={props.hasPortfolioOption} history={props.history} />}
        headerFiltersProps={props.headerFiltersProps}
        headerOptions={HeaderOptions && <HeaderOptions history={props.history} onLogout={props.onLogout} />}
        headerLeftContent={HeaderLeftContent && <HeaderLeftContent />}
        disableNav={exist(props.isOneClickPlanModeActive)}
      >
        <Component {...props} />
      </NewLayout>
    </>
  );
};

const withHooksHOC = Component => {
  return props => {
    const { location } = props;

    const [pages, tabs] = useApplicationRoutes();
    const dispatch = useDispatch();

    const { registerUserRoutes } = useUserRoutesContext();

    /*
     * Register user routes
     */
    useEffect(() => {
      registerUserRoutes(pages);
    }, []);

    useEffect(() => {
      const authHost = window.location.host;

      const cookieDomain = createCookieDomainFromURL(process.env.REACT_APP_PUBLIC_URL);
      const cookieExpirationDate = new Date();

      cookieExpirationDate.setDate(new Date().getDate() + 180);

      const expires = cookieExpirationDate.toString();

      document.cookie = `${AUTH_HOST}=${authHost}; Domain=${cookieDomain}; Path=/; SameSite=lax; Expires=${expires};`;
    }, []);

    const currentPath = useRef(location.pathname);

    // locations listener
    useEffect(() => {
      return props.history.listen(location => {
        if (currentPath.current !== location?.pathname) {
          dispatch({
            type: LOCATION_CHANGE,
            payload: location,
          });
          currentPath.current = location.pathname;
        }
      });
    }, [props.history]);

    useTemplateChanges(location);

    const { activePageView, canIgnoreActiveView } = useLoadPageActiveView();

    const { isOneClickPlanModeActive } = useOneClickPlan();

    const { canView } = usePermissions();

    const canViewMissionControlHome = canView(PERMISSION_PAGES.missionControlHome);

    const isDragonbotEnabled = useFeatureFlags([FeatureFlags.HAS_DRAGONBOT_ENABLED]);

    if (!canIgnoreActiveView && !activePageView) {
      return <PageLoading />;
    }

    return (
      <Component
        {...props}
        pages={pages}
        tabs={tabs}
        isOneClickPlanModeActive={isOneClickPlanModeActive}
        isDragonbotEnabled={isDragonbotEnabled}
        canViewMissionControlHome={canViewMissionControlHome}
        canView={canView}
      />
    );
  };
};

const styles = ({ zIndex, media }) => ({
  Main: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minWidth: '1128px',
  },
  MainContent: {
    display: 'flex',
    flex: '1 1 100%',
    flexDirection: 'column',
    position: 'relative',
  },
  TabComponentWrapper: {
    zIndex: zIndex.high,
  },
  ContentWrapper: {
    display: 'flex',
    flex: '1 1 100%',
  },
  MobileWrapper: {
    minWidth: 'unset',
  },
});

const mapStateToProps = state => {
  const {
    app,
    organization: { organization, integrations },
    login,
    users,
    ideas,
  } = state;

  const jiraIntegrated = getOrgHasJiraIntegrated(state);

  return {
    jiraIntegrated,
    appError: app.appError,
    globalMessage: app.globalMessage,
    isAuthenticated: login.isAuthenticated,
    user: login.currentUser,
    isLoginRehydrated: true,
    users,
    organization,
    organizationIntegrations: integrations,
    systemFields: organization.system_fields_name,
    showImportJqlModal: ideas.get('importJQLLightboxVisible'),
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { ...loginActions, ...appActions, hideImportJQLLightbox }),
  withRouter,
  withHooksHOC,
)(Main);
