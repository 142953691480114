import React from 'react';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';

import { INTEGRATIONS_NAMES, INTEGRATIONS_DOCS_LINKS } from 'constants/integrations';

const Header = props => {
  const { integrationType } = props;

  const integrationNameLabel = INTEGRATIONS_NAMES[integrationType];
  const dataStructureDocsLink = INTEGRATIONS_DOCS_LINKS[integrationType].DATA_STRUCTURE;

  return (
    <Wrapper container spacing={8}>
      <Grid item xs={3} style={{ textAlign: 'center' }}>
        <a
          href={dataStructureDocsLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          dragonboat Data Fields
        </a>
      </Grid>
      <Grid item xs={3} style={{ textAlign: 'center' }}>
        <span>{integrationNameLabel} Field</span>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <span>{integrationNameLabel} → dragonboat</span>
      </Grid>
      <Grid item xs={2} style={{ textAlign: 'center' }}>
        <span>dragonboat → {integrationNameLabel}</span>
      </Grid>
      <Grid item xs={2} />
    </Wrapper>
  );
};

const Wrapper = styled(Grid)`
  &&&& {
    position: sticky;
    top: 0;
    z-index: 1000;
    margin-bottom: 8px;
    background: #ffff;
    align-items: 'center';
  }
`;

export default Header;
