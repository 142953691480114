import { useSelector } from 'react-redux';
import { useIdeasGrid } from 'containers/IdeasList/IdeasList/New/hooks';

import { selectIsLoadingSnapProjects, selectIsLoadingSnapshotsByUserView } from 'features/RoadmapHistory/store/selectors';
import useProjectsWithSnapDataForGrid from './useProjectsWithSnapDataForGrid';
import useProjectGroupsRoadmapHistory from './useProjectGroupsRoadmapHistory';

const emptyArray = [];

const useCompareRoadmapHistorySnapsGrid = (
  data = emptyArray,
  {
    parentHandleGridReady,
    displayLayer,
    portfolioMode,
    currentUser,
    hasBet,
    defaultPhase,
    expandedGroups = emptyArray,
    visibleColumnsIds,
    columnsToCompareIds,
    columnsState,
    viewType,
    selectedGroup1,
    showItemsWithDifferences,
    changedFieldsByProjectBySnapDiff,
    highlightField,

    saveGridConfig,
    getSystemFieldName,
    createUnsavedProject,
    openProjectInfo,
    openOkrInfo,
    setSelectedItems,
    updateProjectById,
    loadParentProjects,
    saveColumnsState,
  } = {},
) => {
  const projectsWithSnapsData = useProjectsWithSnapDataForGrid({
    data,
    viewType,
    columnsToCompareIds,
    showItemsWithDifferences,
    changedFieldsByProjectBySnapDiff,
    highlightField,
  });
  const projectsGrouped = useProjectGroupsRoadmapHistory({ projects: projectsWithSnapsData, selectedGroup1 });
  const isLoadingSnapProjects = useSelector(selectIsLoadingSnapProjects);
  const isLoadingViewSnaps = useSelector(selectIsLoadingSnapshotsByUserView);
  const hasOtherStoreUpdates = isLoadingSnapProjects || isLoadingViewSnaps;

  const {
    processedData,

    getGridIsReady,
    getGridApi,
    // getGridColumnApi,

    handleGridReady,
    handleRowGroupOpened,
    handleSelectionChanged,
    handleDisplayedColumnsChanged,
    handleOnColumnResized,
    checkIsGroupOpenByDefault,
    addNewInlineProject,
    openRowInfo,
    updateProjectByIdOnCellValueChange,
    loadParentProjectsOptions,
    handleMetadataCellDoubleClick,
  } = useIdeasGrid(projectsGrouped, {
    parentHandleGridReady,
    displayLayer,
    portfolioMode,
    currentUser,
    hasBet,
    defaultPhase,
    expandedGroups,
    columnsState,
    viewType,
    hasOtherStoreUpdates,

    saveGridConfig,
    getSystemFieldName,
    createUnsavedProject,
    openProjectInfo,
    openOkrInfo,
    setSelectedItems,
    updateProjectById,
    loadParentProjects,
    saveColumnsState,
  });

  return {
    processedData,
    hasOtherStoreUpdates,

    getGridIsReady,
    getGridApi,

    handleGridReady,
    handleRowGroupOpened,
    handleSelectionChanged,
    handleDisplayedColumnsChanged,
    handleOnColumnResized,
    checkIsGroupOpenByDefault,
    addNewInlineProject,
    openRowInfo,
    updateProjectByIdOnCellValueChange,
    loadParentProjectsOptions,
    handleMetadataCellDoubleClick,
  };
};

export default useCompareRoadmapHistorySnapsGrid;
