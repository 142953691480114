import { useSelector, useDispatch } from 'react-redux';
import { useMemo, useCallback } from 'react';

import { getCurrentUser } from 'store/login/selectors';
import { getActiveCustomers } from 'store/customers/selectors';
import { getTags } from 'store/tags/selectors';
import { getCustomerRequestsCustomFields } from 'store/customFields/selectors';
import { getUsers } from 'store/users/selectors';
import getStatuses from 'store/customerRequests/helpers/getStatuses';
import priorityMap from 'store/customerRequests/constants/priority';
import useRoadmapAutocompletes from 'hooks/useRoadmapAutocompletes';
import { createCustomer } from 'store/customers';
import { createTag } from 'store/tags';
import { addCustomerToCustomerRequest, addTagToCustomerRequest } from 'store/customerRequests';
import { selectPersonasData } from 'features/Personas/store/selectors';
import { selectLifecyclesData } from 'features/Lifecycles/store/selectors';
import { filterActiveMetadata } from 'utils/metadata';
import { omit } from 'ramda';

/**
 * @function useCustomerRequestsGridMetadata
 *
 * Hook that resolves all metadata required on the grid
 *
 * @return {Object}
 */
const useCustomerRequestsGridMetadata = () => {
  const dispatch = useDispatch();
  const customers = useSelector(getActiveCustomers);
  const tags = useSelector(getTags);
  const customFields = useSelector(getCustomerRequestsCustomFields);
  const filteredPersonas = useSelector(selectPersonasData);
  const personas = useSelector(state => selectPersonasData(state, true));
  const filteredLifecycles = useSelector(selectLifecyclesData);
  const lifecycles = useSelector(state => selectLifecyclesData(state, true));

  const users = useSelector(getUsers);
  const currentUser = useSelector(getCurrentUser);
  const statuses = useMemo(() => getStatuses(), [getStatuses]);

  const priorities = useMemo(
    () => Object.entries(priorityMap).map(([key, value]) => ({ label: value.label, value: key })),
    [priorityMap],
  );

  const { roadmaps, hasProducts, products, products2 } = useRoadmapAutocompletes({
    showArchived: false,
  });

  const createMultiSelectOption = useCallback(params => {
    let createAction;

    const { field } = params.colDef;
    const id = params?.data?.id;

    switch (field) {
      case 'customers':
        createAction = async value => {
          const newCustomer = await dispatch(createCustomer({ name: value }));

          await dispatch(addCustomerToCustomerRequest(id, newCustomer.id));

          return newCustomer;
        };
        break;
      case 'tags':
        createAction = async value => {
          const newTag = await dispatch(createTag({ title: value }));

          await dispatch(addTagToCustomerRequest(id, newTag.id));

          return newTag;
        };
        break;
      default:
        throw new Error(`Please add action handler to ${field}`);
    }

    return createAction;
  }, []);

  const metadata = useMemo(
    () => ({
      customers,
      tags,
      users,
      currentUser,
      statuses,
      priorities,
      roadmaps,
      products,
      products2,
      personas,
      filteredPersonas,
      filteredLifecycles,
      lifecycles,
    }),
    [
      customers,
      tags,
      users,
      currentUser,
      statuses,
      priorities,
      roadmaps,
      products,
      products2,
      personas,
      filteredPersonas,
      filteredLifecycles,
      lifecycles,
    ],
  );

  const filteredWithoutArchivedItems = useMemo(() => {
    return filterActiveMetadata(omit(['currentUser'], metadata));
  }, [metadata]);

  return {
    customFields,
    hasProducts,
    metadata,
    filteredMetadata: filteredWithoutArchivedItems,
    createMultiSelectOption,
  };
};

export default useCustomerRequestsGridMetadata;
