import Metrics from './Metrics';
import HeaderFilters from './HeaderFilters';
import HeaderOptions from './HeaderOptions/HeaderOptionsComponent';

const Route = Metrics;

export default {
  Route,
  HeaderFilters,
  HeaderOptions,
};
