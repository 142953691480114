import React from 'react';
import { useDispatch } from 'react-redux';
import { shareUserViewWithOtherUsers } from 'store/userViews/sharedViews.thunks';

const componentHOC = Component => {
  return props => {
    const { view } = props;
    const dispatch = useDispatch();

    const onShare = data => {
      dispatch(shareUserViewWithOtherUsers(view.key, data));
    };

    return (
      <Component
        {...props}
        onShare={onShare}
      />
    );
  };
};

export default componentHOC;
