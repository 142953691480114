import React from 'react';
import styled from 'styled-components';
import { spacing } from 'design-system/theme';
import Text from 'design-system/atoms/Text/index';

import BackgroundImage from 'containers/DiscoveryBotDialog/media/splash_page_background.png';
import { PAGE_HEADER_HEIGHT } from 'constants/common';

const NAV_WIDTH = 380;
const WELCOME_TEXT = 'Welcome to Dragonboat';

const GuideContainer = ({ selectedNavOption, selectedOption }) => {
  return (
    <>
      <PageTitle>
        {!selectedOption ? (
          <StyledTitle variant="h2">{WELCOME_TEXT}</StyledTitle>
        ) : (
          <NavOptionText variant="h2">{selectedNavOption?.level_2_header || selectedNavOption.label}</NavOptionText>
        )}
        {!selectedOption && (
          <StyledSubTitle variant="h3" bold>
            Build Products that Customers Love and Accelerate Business Outcomes!
          </StyledSubTitle>
        )}
      </PageTitle>
      <Image />
    </>
  );
};

const PageTitle = styled(Text)`
  position: fixed;
  left: 50%;
  width: 100%;
  padding-left: ${NAV_WIDTH}px;
  transform: translateX(-50%);
  text-align: center;
`;

const Image = styled.div`
  position: fixed;
  bottom: 0;
  width: 80%;
  height: calc(100% - ${PAGE_HEADER_HEIGHT}px);

  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 70% auto;
  background-image: url(${BackgroundImage});
  background: ${({ hideBackground }) => hideBackground && 'none'};
`;

// This title is specific to this page only
const StyledTitle = styled(Text)`
  font-size: 1.875rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  line-height: 1.875rem;
`;

const StyledSubTitle = styled(Text)`
  padding-top: ${spacing(2)}px;
`;

const NavOptionText = styled(Text)`
  text-align: center;
`;

export default GuideContainer;
