import makeFieldKeyForSnapProjectField from './makeFieldKeyForSnapProjectField';
import { getSnapBackgroundColorCssKey } from './getSnapBackgroundColorCssKey';
import { textCenterAlignedColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import { CURRENT_TITLE } from 'features/RoadmapHistory/constants';
import {
  getCurrentIsHighlighted,
  getIsSnapFieldHighlighted,
} from 'features/RoadmapHistory/components/CompareRoadmapHistorySnapsList/helpers/fieldsHighlights';

export const getSnapParentColumnDef = (field, headerName, columns, withBorder = false) => ({
  headerName,
  field,
  children: columns,
  headerClass: `${textCenterAlignedColumnDef.headerClass} with-uppercase ${withBorder ? 'border-left' : ''}`,
  marryChildren: true,
  hide: true,
});

export const generateSnapChildrenColumnDefs = (snap, columnDef) => ({
  ...columnDef,
  headerName: snap.name,
  field: makeFieldKeyForSnapProjectField(snap?.id, columnDef?.field),
  editable: false,
  cellClass: params =>
    `${textCenterAlignedColumnDef.cellClass} ${getSnapBackgroundColorCssKey(snap?.id)} ${getIsSnapFieldHighlighted(params)}`,
  headerClass: `${textCenterAlignedColumnDef.headerClass} ${getSnapBackgroundColorCssKey(snap?.id)} no-uppercase`,
  clonedFrom: columnDef?.field,
  hide: false,
});

export const getCurrentColumnAsChild = column => ({
  ...column,
  headerComponentParams: {
    displayName: CURRENT_TITLE,
  },
  headerClass: `${textCenterAlignedColumnDef.headerClass} no-uppercase border-left`,
  cellClass: params => `${textCenterAlignedColumnDef.cellClass} border-left ${getCurrentIsHighlighted(params)}`,
  editable: false,
  hide: false,
});
