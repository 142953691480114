import React, { Component } from 'react';
import isFunction from 'lodash/isFunction';

import Autocomplete from 'design-system/atoms/Autocomplete/index';


export default class AgGridHierarchyAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.getValue = this.getValue.bind(this);

    this.state = {
      value: props.value || '',
    };
  }

  getValue() {
    return this.state.value;
  }

  onChange(value) {
    this.setState({ value });
  }

  isPopup() {
    return true;
  }

  render() {
    const { value } = this.state;
    const { autoFocus, node, hierarchyParams, size } = this.props;
    let { parentObjects = [] } = this.props;

    if (isFunction(parentObjects)) parentObjects = parentObjects() || [];

    let options;

    hierarchyParams.forEach(({ parentAttribute, optionsAttribute }, index) => {
      try {
        const parentObj = parentObjects.filter(obj => obj.title === node.data[parentAttribute])[0];

        if (index < hierarchyParams.length - 1) {
          parentObjects = parentObj[optionsAttribute];
        } else {
          options = parentObj[optionsAttribute].filter(obj => obj.status !== 'Archived').map(obj => obj.title);
        }
      } catch (err) {
        // ignore
      }
    });

    if (!options) {
      options = (parentObjects || []).reduce((objs, parent) => {
        if (parent.status === 'Archived') return objs;
        return [
          ...objs,
          ...(parent[hierarchyParams[0].optionsAttribute] || []).filter(c => c.status !== 'Archived').map(c => c.title),
        ];
      }, []);
    }

    return (
      <Autocomplete suggestions={options} value={value} autoFocus={autoFocus} onValueChange={this.onChange} addNew size={size} />
    );
  }
}
