import { List } from 'immutable';

import { projectsInitialState } from 'store/projects/reducers';
import findEstimateInProjects from 'store/projects/helpers/findEstimateInProjects';
import removeDependency from 'store/projects/helpers/removeDependency';
import addDependency from 'store/projects/helpers/addDependency';
import updateEstimateInProject from 'store/projects/helpers/updateEstimateInProject';
import findTaskInProjects from 'store/projects/helpers/findTaskInProjects';
import updateTaskInProject from 'store/projects/helpers/updateTaskInProject';
import addProjectDependency from 'store/projects/helpers/addProjectDependency';
import removeProjectDependency from 'store/projects/helpers/removeProjectDependency';
import { updateProjectOnCollectionsUsingMethod } from 'store/projects/helpers/collections';
import {
  ADD_ESTIMATE_ESTIMATE_DEPENDENCY_FULFILLED,
  REMOVE_ESTIMATE_ESTIMATE_DEPENDENCY_FULFILLED,
  ADD_ESTIMATE_PROJECT_DEPENDENCY_FULFILLED,
  REMOVE_ESTIMATE_PROJECT_DEPENDENCY_FULFILLED,
  ADD_PROJECT_PROJECT_DEPENDENCY_FULFILLED,
  ADD_PROJECT_TASK_DEPENDENCY_FULFILLED,
  ADD_PROJECT_ESTIMATE_DEPENDENCY_FULFILLED,
  REMOVE_PROJECT_PROJECT_DEPENDENCY_FULFILLED,
  REMOVE_PROJECT_TASK_DEPENDENCY_FULFILLED,
  REMOVE_PROJECT_ESTIMATE_DEPENDENCY_FULFILLED,
  ADD_TASK_TASK_DEPENDENCY_FULFILLED,
  ADD_TASK_PROJECT_DEPENDENCY_FULFILLED,
  REMOVE_TASK_TASK_DEPENDENCY_FULFILLED,
  REMOVE_TASK_PROJECT_DEPENDENCY_FULFILLED,
  SET_PROJECTS_DEPENDENCIES_FULFILLED,
} from '../types';
import { APPLY_FILTERS_FULFILLED, DELETE_PROJECTS_FULFILLED } from 'store/projects';

const getDependenciesDataByAction = (payload, actionType) => {
  return actionType === APPLY_FILTERS_FULFILLED ? payload?.dependencies : payload?.data;
};

export default (state = projectsInitialState, action) => {
  switch (action.type) {
    case ADD_ESTIMATE_ESTIMATE_DEPENDENCY_FULFILLED: {
      const { estimateId, dependency } = action.payload;

      if (!estimateId || !dependency) {
        return state;
      }

      const _updateMethod = collection => {
        const { estimateIndex, projectIndex } = findEstimateInProjects(estimateId, collection);

        if (estimateIndex === -1 || projectIndex === -1) return collection;

        return updateEstimateInProject(collection, projectIndex, estimateIndex, estimate => {
          return addDependency(estimate, dependency, 'estimateDependencies');
        });
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateMethod),
      };
    }
    case ADD_ESTIMATE_PROJECT_DEPENDENCY_FULFILLED: {
      const { estimateId, dependency } = action.payload;

      if (!estimateId || !dependency) {
        return state;
      }

      const _updateMethod = collection => {
        const { estimateIndex, projectIndex } = findEstimateInProjects(estimateId, collection);

        if (estimateIndex === -1 || projectIndex === -1) return collection;

        return updateEstimateInProject(collection, projectIndex, estimateIndex, estimate => {
          return addDependency(estimate, dependency, 'projectDependencies');
        });
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateMethod),
      };
    }
    case ADD_TASK_TASK_DEPENDENCY_FULFILLED: {
      const { taskId, dependency } = action.payload;

      if (!taskId || !dependency) {
        return state;
      }

      const _updateMethod = collection => {
        const { taskIndex, projectIndex } = findTaskInProjects(taskId, collection);

        if (taskIndex === -1 || projectIndex === -1) return collection;

        return updateTaskInProject(collection, projectIndex, taskIndex, task => {
          return addDependency(task, dependency, 'taskDependencies');
        });
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateMethod),
      };
    }
    case ADD_TASK_PROJECT_DEPENDENCY_FULFILLED: {
      const { taskId, dependency } = action.payload;

      if (!taskId || !dependency) {
        return state;
      }

      const _updateMethod = collection => {
        const { taskIndex, projectIndex } = findTaskInProjects(taskId, collection);

        if (taskIndex === -1 || projectIndex === -1) return collection;

        return updateTaskInProject(collection, projectIndex, taskIndex, task => {
          return addDependency(task, dependency, 'projectDependencies');
        });
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateMethod),
      };
    }
    case ADD_PROJECT_PROJECT_DEPENDENCY_FULFILLED: {
      const { projectId, dependency } = action.payload;

      if (!projectId || !dependency) {
        return state;
      }

      const _updateBlocks = collection => {
        if (!collection) return null;
        const projectIndex = collection.findIndex(project => project.get('id') === projectId);

        if (projectIndex === -1) return collection;

        return collection.update(projectIndex, addProjectDependency(dependency, 'projectDependencies'));
      };

      const _updateBlockedBy = collection => {
        if (!collection) return null;
        const projectIndex = collection.findIndex(project => project.get('id') === dependency.id);

        if (projectIndex === -1) return collection;

        return collection.update(projectIndex, project => {
          const currentValue = project.get('projectProjectDependants', []);

          return project.set('projectProjectDependants', new List([...currentValue, dependency?.info]));
        });
      };

      const stateWithBlocks = updateProjectOnCollectionsUsingMethod(state, _updateBlocks);
      const finalUpdatedState = updateProjectOnCollectionsUsingMethod(stateWithBlocks, _updateBlockedBy);

      return {
        ...state,
        ...finalUpdatedState,
      };
    }
    case ADD_PROJECT_TASK_DEPENDENCY_FULFILLED: {
      const { projectId, dependency } = action.payload;

      if (!projectId || !dependency) {
        return state;
      }

      const _updateMethod = collection => {
        if (!collection) return null;
        const projectIndex = collection.findIndex(project => project.get('id') === projectId);

        if (projectIndex === -1) return collection;

        return collection.update(projectIndex, addProjectDependency(dependency, 'taskDependencies'));
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateMethod),
      };
    }
    case ADD_PROJECT_ESTIMATE_DEPENDENCY_FULFILLED: {
      const { projectId, dependency } = action.payload;

      if (!projectId || !dependency) {
        return state;
      }

      const _updateMethod = collection => {
        if (!collection) return null;
        const projectIndex = collection.findIndex(project => project.get('id') === projectId);

        if (projectIndex === -1) return collection;

        return collection.update(projectIndex, addProjectDependency(dependency, 'estimateDependencies'));
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateMethod),
      };
    }
    case REMOVE_ESTIMATE_ESTIMATE_DEPENDENCY_FULFILLED: {
      const { estimateId, dependencyId } = action.payload;

      if (!estimateId || !dependencyId) {
        return state;
      }

      const _updateMethod = collection => {
        const { estimateIndex, projectIndex } = findEstimateInProjects(estimateId, collection);

        if (projectIndex === -1 || estimateIndex === -1) return collection;

        return updateEstimateInProject(collection, projectIndex, estimateIndex, estimate => {
          return removeDependency(estimate, dependencyId, 'estimateDependencies');
        });
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateMethod),
      };
    }
    case REMOVE_ESTIMATE_PROJECT_DEPENDENCY_FULFILLED: {
      const { estimateId, dependencyId } = action.payload;

      if (!estimateId || !dependencyId) {
        return state;
      }

      const _updateMethod = collection => {
        const { estimateIndex, projectIndex } = findEstimateInProjects(estimateId, collection);

        if (projectIndex === -1 || estimateIndex === -1) return collection;

        return updateEstimateInProject(collection, projectIndex, estimateIndex, estimate => {
          return removeDependency(estimate, dependencyId, 'projectDependencies');
        });
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateMethod),
      };
    }
    case REMOVE_TASK_TASK_DEPENDENCY_FULFILLED: {
      const { taskId, dependencyId } = action.payload;

      if (!taskId || !dependencyId) {
        return state;
      }
      let stateTasks = state.tasks;

      const _updateMethod = collection => {
        const { taskIndex, projectIndex } = findTaskInProjects(taskId, collection);

        if (projectIndex === -1 || taskIndex === -1) return collection;

        if (stateTasks) {
          const index = stateTasks.findIndex(t => t.id === taskId);
          const task = stateTasks.find(t => t.id === taskId);

          task.taskDependencies = task.taskDependencies ? task.taskDependencies.filter(d => d.id !== dependencyId) : [];

          stateTasks = stateTasks.filter(t => t.id !== taskId);
          stateTasks.splice(index, 0, task);
        }

        return updateTaskInProject(collection, projectIndex, taskIndex, task => {
          return removeDependency(task, dependencyId, 'taskDependencies');
        });
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateMethod),
        tasks: stateTasks,
      };
    }
    case REMOVE_TASK_PROJECT_DEPENDENCY_FULFILLED: {
      const { taskId, dependencyId } = action.payload;

      if (!taskId || !dependencyId) {
        return state;
      }

      const _updateMethod = collection => {
        const { taskIndex, projectIndex } = findTaskInProjects(taskId, collection);

        if (projectIndex === -1 || taskIndex === -1) return collection;

        return updateTaskInProject(collection, projectIndex, taskIndex, task => {
          return removeDependency(task, dependencyId, 'projectDependencies');
        });
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateMethod),
      };
    }
    case REMOVE_PROJECT_PROJECT_DEPENDENCY_FULFILLED: {
      const { projectId, dependencyId } = action.payload;

      if (!projectId || !dependencyId) {
        return state;
      }

      const _updateBlocks = collection => {
        if (!collection) return null;
        const projectIndex = collection.findIndex(project => project.get('id') === projectId);

        if (projectIndex === -1) return collection;

        return collection.update(projectIndex, removeProjectDependency(dependencyId, 'projectDependencies'));
      };

      const _updateBlockedBy = collection => {
        if (!collection) return null;
        const projectIndex = collection.findIndex(project => project.get('id') === dependencyId);

        if (projectIndex === -1) return collection;

        return collection.update(
          projectIndex,
          removeProjectDependency(dependencyId, 'projectProjectDependants', 'project_dependency_id'),
        );
      };

      const stateWithBlocks = updateProjectOnCollectionsUsingMethod(state, _updateBlocks);
      const finalUpdatedState = updateProjectOnCollectionsUsingMethod(stateWithBlocks, _updateBlockedBy);

      return {
        ...state,
        ...finalUpdatedState,
      };
    }
    case REMOVE_PROJECT_TASK_DEPENDENCY_FULFILLED: {
      const { projectId, dependencyId } = action.payload;

      if (!projectId || !dependencyId) {
        return state;
      }

      const _updateMethod = collection => {
        if (!collection) return null;
        const projectIndex = collection.findIndex(project => project.get('id') === projectId);

        if (projectIndex === -1) return collection;

        return collection.update(projectIndex, removeProjectDependency(dependencyId, 'taskDependencies'));
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateMethod),
      };
    }
    case REMOVE_PROJECT_ESTIMATE_DEPENDENCY_FULFILLED: {
      const { projectId, dependencyId } = action.payload;

      if (!projectId || !dependencyId) {
        return state;
      }

      const _updateMethod = collection => {
        if (!collection) return null;
        const projectIndex = collection.findIndex(project => project.get('id') === projectId);

        if (projectIndex === -1) return collection;

        return collection.update(projectIndex, removeProjectDependency(dependencyId, 'estimateDependencies'));
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateMethod),
      };
    }
    case DELETE_PROJECTS_FULFILLED: {
      const _updateBlocks = collection => {
        if (!collection) return null;
        return action.payload.reduce((curr, id) => {
          const projectIndex = collection.findIndex(project => project.get('projectDependencies', []).some(d => d.id === id));

          if (projectIndex === -1) return collection;

          return collection.update(projectIndex, removeProjectDependency(id, 'projectDependencies'));
        }, collection);
      };

      const _updateBlockedBy = collection => {
        if (!collection) return null;

        return action.payload.reduce((curr, id) => {
          const projectIndex = collection.findIndex(project =>
            project.get('projectProjectDependants', []).some(d => d.project_id === id),
          );

          if (projectIndex === -1) return collection;

          return collection.update(projectIndex, removeProjectDependency(id, 'projectProjectDependants', 'project_id'));
        }, collection);
      };

      const stateWithBlocks = updateProjectOnCollectionsUsingMethod(state, _updateBlocks);
      const finalUpdatedState = updateProjectOnCollectionsUsingMethod(stateWithBlocks, _updateBlockedBy);

      return {
        ...state,
        ...finalUpdatedState,
      };
    }
    case APPLY_FILTERS_FULFILLED:
    case SET_PROJECTS_DEPENDENCIES_FULFILLED: {
      const data = getDependenciesDataByAction(action.payload, action.type);

      if (!data) return state;

      return {
        ...state,
        loadedAsDependency: data.reduce(
          (byId, project) => ({
            ...byId,
            [project.id]: project,
          }),
          {},
        ),
      };
    }

    default: {
      return state;
    }
  }
};
