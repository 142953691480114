import { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { css } from 'styled-components';

import useLoginInfo from 'hooks/useLoginInfo';
import useOrganizations from 'hooks/useOrganizations';
import usePageFilters from 'hooks/filters/usePageFilters';

import { updateProjectById, switchProjectRowOrder as switchProjectRowOrderAction } from 'store/projects';

import { openProjectLightbox as openProjectLightboxAction } from 'store/projectLightbox';

import { useMetadata } from 'containers/IdeasList/IdeasList/New/hooks';
import useIdeasGridRowDrag from 'containers/IdeasList/IdeasList/New/hooks/useIdeasGridRowDrag';
import useProcessUpdateProjectData from 'containers/IdeasList/IdeasList/New/hooks/useProcessUpdateProjectData';

import { useCommonGridProps, useCommonGridEventHandlers } from 'design-system/molecules/AgGridReact-New/hooks';
import {
  sumNumericFields,
  sumScopeVarianceField,
  sumPointsVarianceField,
} from 'design-system/molecules/AgGridReact-New/aggFuncs';

import useLinkedPortfolioItemsColumns from './useLinkedPortfolioItemsColumns';

const OFFSET = 350;
const TABLE_HEIGHT = `calc(100vh - ${OFFSET}px)`;

const gridStyles = css`
  .ag-layout-normal.ag-root {
    margin: 0;
  }
`;

const useLinkedPortfolioItemsGrid = ({ items = [], linkProjectToEntity, unlinkProjectFromEntity, removeUnsavedRows }) => {
  const dispatch = useDispatch();

  const { currentUser } = useLoginInfo();

  const { portfolioMode } = usePageFilters();

  const { organization, hasMultiLevelMetadata } = useOrganizations();

  const { metadata, filteredMetadata, checkUserCanCreateNewMetadata } = useMetadata(items, {
    currentUser,
    organization,
  });

  const { processUpdateData: processUpdateDataForProjectUpdate } = useProcessUpdateProjectData([], hasMultiLevelMetadata);

  const updateProject = useCallback((id, update) => dispatch(updateProjectById(id, update)), [updateProjectById]);

  const openProjectLightbox = useCallback(row => dispatch(openProjectLightboxAction(row.id)), [openProjectLightboxAction]);

  const updateProjectByIdOnCellValueChange = (id, updatedData, previousValue, params) => {
    const updatedField = params.colDef.field;

    return updateProject(params.data.id, processUpdateDataForProjectUpdate(updatedData, updatedField));
  };

  const switchProjectRowOrder = useCallback(
    (currentNodeDataId, lastOverNodeDataId, updateData, position) =>
      dispatch(switchProjectRowOrderAction(currentNodeDataId, lastOverNodeDataId, updateData, position)),
    [switchProjectRowOrderAction],
  );

  const { handleRowDrag } = useIdeasGridRowDrag({
    switchProjectRowOrder,
    updateProjectById: updateProject,
  });

  const commonGridProps = useCommonGridProps({
    isTreeView: false,
    aggFuncs: {
      sumNumericFields,
      sumScopeVarianceField,
      sumPointsVarianceField,
    },
  });

  const commonGridEvents = useCommonGridEventHandlers({
    items,
    idKey: 'id',
    actions: {
      update: updateProjectByIdOnCellValueChange,
      /* save: createChildProject, */
      remove: removeUnsavedRows,
      drag: handleRowDrag,
    },
  });

  const gridContext = useMemo(
    () => ({
      portfolioMode,
      processedData: items,
      parents: metadata.parents,
      filteredMetadata,
      metadata,
    }),
    [portfolioMode, items, metadata, filteredMetadata],
  );

  const { columnDefs, defaultColumnDef } = useLinkedPortfolioItemsColumns({
    openProjectLightbox,
    updateProject,
    linkProjectToEntity,
    unlinkProjectFromEntity,
    currentUser,
    checkUserCanCreateNewMetadata,
  });

  return {
    columnDefs,
    defaultColumnDef,
    gridContext,
    commonGridProps,
    commonGridEvents,
    gridStyles,
    tableHeight: TABLE_HEIGHT,
  };
};

export default useLinkedPortfolioItemsGrid;
