import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { defaultTo, isEmpty, isNil, not, pipe, prop } from 'ramda';
import MenuListItem from '@material-ui/core/MenuItem';
import MuiListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { spacing } from 'design-system/theme';
import DragonSelectButton from 'design-system/atoms/DragonSelectButton/index';
import MultiFilterSelector from 'design-system/molecules/MultiFilterSelector/index';
import SavedFiltersPopper from 'design-system/organisms/SavedFiltersPopper/index';
import ViewName from 'design-system/molecules/ViewName/index';
import SubNavigationTabs from 'design-system/organisms/SubNavigationTabs/index';

import { FavoriteButton } from 'design-system/molecules/ViewName';

import { GLOBAL_FILTER } from 'constants/filters';
import { FILTERS_FIELDS } from 'constants/filters/fields';
import { OPEN_ADVANCED_SEARCH } from 'constants/queryParams';

import SelectDisplayLayer from 'containers/SelectDisplayLayer';
import AdvancedSearchPopover from 'containers/AdvancedSearchPopover';
import TotalCountComponent from './components/TotalCountComponent';
import FilterButtonComponent from './components/FilterButtonComponent';
import ModulesMenu from 'components/ModulesMenu';

import getFiltersCounter from 'utils/filters/getFiltersCounter';
import { checkPathIsDashboardPath, shouldDisplayDashboardViewName } from './helpers';

const SELECT_VIEW_NAME = 'name';

const defaultToEmptyObject = defaultTo({});
const hasViewName = pipe(defaultToEmptyObject, prop(SELECT_VIEW_NAME), isNil, not);

const selectButtonTextStyles = css`
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  line-height: ${({ theme }) => theme.typography.lineHeightRegularLargeRem}rem;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const exist = Boolean;

const isCommitted = selectedRoadmapVersion => exist(selectedRoadmapVersion) && exist(selectedRoadmapVersion.committed);

export default ({
  hasLoaded,
  roadmaps,
  setQuickFilter,
  selectedQuickFilter,
  onSaveFilter,
  onDeleteFilter,
  onSavedFilterClick,
  userFilters,
  staticFilters,
  total,
  pageFilters,
  fields,
  setSelectedQuickFilter,
  hasPortfolioOption,
  showPageIcon = true,
  showQuickFilter = true,
  showDisplayLayer = true,
  totalCountForDisplayLayer,
  shouldDisplayTotalCount,
  history,
  showResultsCounter = true,
  showAdvancedSearch = true,
  showFilterIcon = true,
  customFilterRender,
  showSavedFiltersOnQuickFilter = true,
  page = GLOBAL_FILTER,
  pageId,
  defaultFilters = [],
  onClearFilter,
  selectedView,
  viewOwner,
  isFavoriteView,
  handleFavoriteUserView,
  shouldIncludeRoadmapsQuickFilter = true,
  subNavigationTabs,
  selectedSubNavigationTab,
  handleSubNavigationChange,
  pageIconBackground,
  selectedRoadmapVersion,
  isCreatingOrViewingScenario,
  currentUser,
  showWelcomeHeader = false,
  ModuleCustomDropdownComponent,
  enableMultiLayerSearch,
  multiFilterHeaderRender,
  onApplyQuickFilter,
  customWelcomeMessage,
  customWelcomeMessageStyles,
  showFilters = true,
}) => {
  const [filterIdToEdit, setFilterIdToEdit] = useState(pageFilters.activeFilter);
  const [multiFilterAnchorEl, setMultiFilterAnchorEl] = useState(null);
  const [pageFilterFilterAnchorEl, setPageFilterFilterAnchorEl] = useState(null);
  const [savedFiltersAnchorEl, setSavedFiltersAnchorEl] = useState(null);
  const [editableItem, setEditableItem] = useState(null);

  const globalFilterIconRef = useRef(null);
  const savedFiltersPopperRef = useRef(null);

  const urlParams = new URLSearchParams(window.location.search);
  const openAdvancedSearchParam = urlParams.get(OPEN_ADVANCED_SEARCH);

  const selectFilterToEdit = useMemo(() => userFilters.find(f => f.id === filterIdToEdit), [filterIdToEdit, userFilters]);

  const path = window.location.pathname;

  const viewAllowsQuickFilter = isEmpty(selectedView) || selectedView.default_view || selectedView.template_id;

  const shouldDisplayQuickFilter = showQuickFilter && viewAllowsQuickFilter && !selectedRoadmapVersion;

  const isDashboardPath = checkPathIsDashboardPath(path);
  const shouldDisplayViewName =
    selectedView.id && hasViewName(selectedView) && (!isDashboardPath || shouldDisplayDashboardViewName(path));

  useEffect(() => {
    setFilterIdToEdit(pageFilters.activeFilter);
  }, [pageFilters.activeFilter]);

  const ignoreClearButton = useMemo(() => not(onClearFilter), [onClearFilter]);

  const _handleClickItem = item => {
    setMultiFilterAnchorEl(null);
    setQuickFilter(item);

    onApplyQuickFilter && onApplyQuickFilter(item);
  };

  const _onClickAwaySavedFiltersPopper = e => {
    if (e.composedPath().includes(savedFiltersAnchorEl)) return;

    setSavedFiltersAnchorEl(null);
  };

  const _handleOpenSavedFilters = event => {
    setSavedFiltersAnchorEl(event.currentTarget);
  };

  const _handleClickClearFilter = () => {
    if (onClearFilter) {
      onClearFilter();
      setMultiFilterAnchorEl(null);
    }
  };

  const _handleSavedFilterClicked = f => {
    onSavedFilterClick(f);
    setSavedFiltersAnchorEl(null);
    setMultiFilterAnchorEl(null);
  };

  const _multiFilterHeader = () => {
    const dropdownAttributes = {
      id: 'filters-list-dropdown',
      'data-testid': 'quickfilter-name',
      value: selectedQuickFilter,
      variant: 'secondary',
      noUnderline: true,
      higherLabel: true,
      placeholder: 'Select saved filter',
      onClick: event => setMultiFilterAnchorEl(event.currentTarget),
      textStyles: selectButtonTextStyles,
      arrowIcon: <StyledKeyboardArrowDownIcon />,
    };

    if (multiFilterHeaderRender) {
      return multiFilterHeaderRender(dropdownAttributes);
    }

    return <DragonSelectButton {...dropdownAttributes} />;
  };

  const _onEditFilter = filter => {
    setFilterIdToEdit(filter.id);
    setPageFilterFilterAnchorEl(globalFilterIconRef.current);
    setSavedFiltersAnchorEl(null);
  };

  const renderSavedFiltersSubMenu = item => {
    return (
      <>
        <ListItem onClick={() => setEditableItem(item)}>
          <ListItemText>Rename</ListItemText>
        </ListItem>
        <ListItem onClick={() => _onEditFilter(item)}>
          <ListItemText>Edit</ListItemText>
        </ListItem>
        <ListItem onClick={() => onDeleteFilter(item.id)}>
          <ListItemText>Delete</ListItemText>
        </ListItem>
      </>
    );
  };

  const renderSavedFiltersPopover = () => (
    <SavedFiltersPopper
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      marginLeft="10"
      anchorEl={savedFiltersAnchorEl}
      onClickAway={_onClickAwaySavedFiltersPopper}
      myFilters={userFilters}
      setRef={r => (savedFiltersPopperRef.current = r)}
      editableItem={editableItem}
      onSetEditableItem={setEditableItem}
      onSaveFilter={(id, name) => onSaveFilter(id, name)}
      onFilterClick={_handleSavedFilterClicked}
      renderSubMenu={renderSavedFiltersSubMenu}
    />
  );

  const renderAdvancedSearchPopover = () => {
    return (
      <AdvancedSearchPopover
        anchorEl={pageFilterFilterAnchorEl}
        filterToEdit={selectFilterToEdit}
        setAnchorEl={el => setPageFilterFilterAnchorEl(el)}
        page={page}
        availableFields={FILTERS_FIELDS}
        includeCustomFields
        setSelectedQuickFilter={setSelectedQuickFilter}
        onSaveFilter={onSaveFilter}
        enableMultiLayerSearch={enableMultiLayerSearch}
      />
    );
  };

  const renderResultsCounter = () => (
    <TotalCountComponent
      hasLoaded={hasLoaded}
      total={total}
      shouldDisplayTotalCount={shouldDisplayTotalCount}
      totalCountForDisplayLayer={totalCountForDisplayLayer}
    />
  );

  const renderFiltersSelectAndButton = () => (
    <SpaceContainer>
      {!isCreatingOrViewingScenario && shouldDisplayQuickFilter && (
        <MultiFilterContainer>
          <MultiFilterSelector
            hasHeader
            renderHeader={_multiFilterHeader}
            anchorEl={multiFilterAnchorEl}
            defaultFilters={defaultFilters}
            staticFilters={staticFilters}
            menu={shouldIncludeRoadmapsQuickFilter && roadmaps}
            clickMenu
            onSetAnchorEl={setMultiFilterAnchorEl}
            onClickItem={_handleClickItem}
            onStaticFilterClick={_handleSavedFilterClicked}
            onOpenSavedFilters={showSavedFiltersOnQuickFilter ? _handleOpenSavedFilters : null}
            onClearFilter={_handleClickClearFilter}
            ignoreMultiFilterClearButton={ignoreClearButton}
            dataTestId="module-filter-dropdown"
          />
        </MultiFilterContainer>
      )}
      {showFilterIcon && (
        <StyledFilterButtonComponent
          badgeContent={getFiltersCounter(pageFilters, fields)}
          ref={globalFilterIconRef}
          onClick={event => {
            if (pageFilterFilterAnchorEl === null) setPageFilterFilterAnchorEl(event.currentTarget);
          }}
        />
      )}
      {customFilterRender && customFilterRender()}
    </SpaceContainer>
  );

  const renderPageIcon = () => <ModulesMenu pageIconBackground={pageIconBackground} />;

  const renderSubNavigation = () => (
    <SubNavigationContainer>
      {subNavigationTabs && (
        <SubNavigationTabs
          items={subNavigationTabs}
          selected={selectedSubNavigationTab}
          handleNavigationChange={handleSubNavigationChange}
        />
      )}
    </SubNavigationContainer>
  );

  const renderWelcomeHeader = () => (
    <WelcomeText customStyles={customWelcomeMessageStyles}>
      {customWelcomeMessage || `Welcome, ${currentUser.first_name}!`}
    </WelcomeText>
  );

  const renderPageFilters = () => (
    <FlexContainer withOffset={!!subNavigationTabs}>
      {renderFiltersSelectAndButton()}
      {showDisplayLayer && <SelectDisplayLayer hasPortfolioOption={hasPortfolioOption} />}
      {showResultsCounter && renderResultsCounter()}
      {shouldDisplayViewName && (
        <ViewName
          selectedView={selectedView}
          viewOwner={viewOwner}
          isFavoriteView={isFavoriteView}
          handleFavoriteUserView={handleFavoriteUserView}
          maxWidth="35vw"
        />
      )}
      {showAdvancedSearch && renderAdvancedSearchPopover()}
      {renderSavedFiltersPopover()}
      {selectedRoadmapVersion && isCommitted(selectedRoadmapVersion) && (
        <SelectedVersion>
          <SelectedVersionName>{selectedRoadmapVersion.name}</SelectedVersionName>
          <SelectedVersionCreatedBy>(created by {selectedRoadmapVersion.createdByName})</SelectedVersionCreatedBy>
        </SelectedVersion>
      )}
    </FlexContainer>
  );

  const shouldRenderPageIcon = useMemo(
    () => !isCreatingOrViewingScenario && showPageIcon,
    [isCreatingOrViewingScenario, showPageIcon],
  );

  useEffect(() => {
    if (openAdvancedSearchParam) {
      setPageFilterFilterAnchorEl(globalFilterIconRef.current);
    }
  }, [openAdvancedSearchParam]);

  return (
    <Wrapper>
      {shouldRenderPageIcon && renderPageIcon()}
      {showWelcomeHeader && renderWelcomeHeader()}
      {ModuleCustomDropdownComponent && <ModuleCustomDropdownComponent history={history} />}
      <FlexColumnContainer>
        {showFilters && renderPageFilters()}
        {renderSubNavigation()}
      </FlexColumnContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  min-height: 38px;
  ${({ withOffset }) => withOffset && 'transform: translateY(5px)'};

  ${FavoriteButton} {
    padding: ${spacing()}px;
  }
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SubNavigationContainer = styled.div`
  height: 100%;

  > div {
    min-height: unset;
    display: flex;
    height: 100%;
  }
`;

const SpaceContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${spacing(2.5)}px;
`;

const MultiFilterContainer = styled.div`
  margin-right: ${spacing(1.25)}px;

  &&&& button {
    padding: 0;
  }
`;

const ListItemText = styled(MuiListItemText)`
  &&&& {
    margin: 0;

    ${({ uppercase }) =>
      uppercase &&
      `
      text-transform: uppercase;
    `}
    span {
      font-size: ${props => props.theme.typography.fontSizeRem}rem;
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      color: ${props => props.theme.palette.text.lightGrey};
    }
  }
`;

const ListItem = styled(MenuListItem)`
  &&&& {
    height: 18px;

    &:hover {
      background-color: ${props => props.theme.palette.newLayout.background.lighterBlack};

      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      ${ListItemText} {
        color: #2ea8e1;
      }
    }
  }
`;

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
  &&&&& {
    width: 24px;
    height: 24px;

    path {
      fill: none;
    }
  }
`;

const SelectedVersion = styled.div`
  display: flex;
`;

const SelectedVersionName = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizeSmallLargeRem}rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SelectedVersionCreatedBy = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  color: ${({ theme }) => theme.palette.newLayout.text.grey};
  padding: 0;
  margin: 0 8px;
  align-self: center;
  white-space: nowrap;
`;

const WelcomeText = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizeSmallLargeRem}rem;

  ${({ customStyles }) => customStyles};
`;

const StyledFilterButtonComponent = styled(FilterButtonComponent)`
  &&&& {
    margin-left: 0;
  }
`;
