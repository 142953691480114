import React from 'react';

import DialogActions from 'design-system/molecules/DialogActions/index';


import { SELECT_CHART_SCREEN } from '../../ChartWidgetWizard/hooks/useChartWidgetWizard';

const WidgetLightboxActions = ({ currentScreen, goToPreviousScreen, saveLabel, onSaveWidget, canSave }) => {
  if (currentScreen === SELECT_CHART_SCREEN) {
    return <DialogActions />;
  }

  return (
    <DialogActions
      deleteLabel="Back"
      onDelete={goToPreviousScreen}
      deleteProps={{ color: 'primary' }}
      saveLabel={saveLabel}
      onSave={onSaveWidget}
      saveProps={{ disabled: !canSave }}
    />
  );
};

export default WidgetLightboxActions;
