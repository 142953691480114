import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { PERMISSION_RESOURCES, SYSTEM_FIELD } from '@dragonboat/permissions';

import { TEAMS } from 'store/grids/constants';
import { updateSystemFieldsName as updateSystemFieldsNameAction } from 'store/organization';

import usePermissions from 'hooks/permissions/usePermissions';
import useForceVisibleColumns from 'hooks/useForceVisibleColumns';
import useTeams from './hooks/useTeams';
import useTeamsGridColumns from './hooks/useTeamsGridColumns';
import {
  useSettingsActions,
  useSettingsGridSelection,
  useSettingsGridEventHandlers,
  useSettingsGridColumns,
} from 'containers/Grids/SettingsGrid/hooks';

import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SettingsDialogs from 'components/SettingsDialogs';
import { BaseGrid } from 'containers/Grids';
import { TEAM_KEY, TITLE_FIELD } from './helpers/constants';
import useTeamsGridProps from 'routes/Settings/Teams/hooks/useTeamsGridProps';

const Teams = ({ showActionBar = true, forceVisibleFields, customHeight }) => {
  const {
    teams,
    childrenSettingKeys,
    createTeam,
    addTeamWithoutSave,
    removeUnsavedTeams,
    updateTeamById,
    deleteTeamById,
    mergeTeams,
    switchTeamsRowOrder,
    bulkCreateTeams,
    bulkUpdateTeams,
    bulkDeleteTeams,
    systemFields,
    createTeamRoadmap,
    deleteTeamRoadmap,
    bulkDeleteTeamRoadmaps,
    hideMetadataRoadmaps,
    hasTeamsLevelTwo,
  } = useTeams();
  const dispatch = useDispatch();

  const updateSystemFieldsName = useCallback(
    fieldRename => dispatch(updateSystemFieldsNameAction(fieldRename)),
    [updateSystemFieldsNameAction],
  );

  const { canUpdate, canCreate, canView, canMerge } = usePermissions();
  const canUpdateOrCreate = canUpdate(PERMISSION_RESOURCES.team) || canCreate(PERMISSION_RESOURCES.team);

  const permissions = {
    allowActions: canUpdateOrCreate,
    canAdd: canCreate(PERMISSION_RESOURCES.team),
    rename: {
      canUpdate: showActionBar && canUpdate(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.team }),
      canView: showActionBar && canView(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.team }),
    },
    canMerge: showActionBar && canMerge(PERMISSION_RESOURCES.team),
    canBulkDelete: showActionBar && canUpdateOrCreate,
    canDragRows: showActionBar && canUpdateOrCreate,

    canUpdate: useCallback(data => canUpdate(PERMISSION_RESOURCES.team, { data }), [canUpdate]),
  };

  // Specific column definition for Teams
  const teamsGridColumnDefs = useTeamsGridColumns({
    roadmapColumn: {
      teams,
      createTeamRoadmap,
      deleteTeamRoadmap,
      bulkDeleteTeamRoadmaps,
      hideMetadataRoadmaps,
    },
    canAdd: permissions.canAdd,
    allowActions: permissions.allowActions,
    addTeamWithoutSave,
  });

  const teamsGridProps = useTeamsGridProps({
    bulkCreateTeams,
    bulkUpdateTeams,
    hasTeamsLevelTwo,
  });

  const handleRowDrag = useCallback(
    (currentNode, lastOverNode, { position }) => switchTeamsRowOrder(currentNode.data.id, lastOverNode.data.id, null, position),
    [switchTeamsRowOrder],
  );

  // Settings common agGrid configuration
  const { selectionMode, selectedItems, enableSelectionMode, disableSelectionMode, updateSelectedOnAgGridInit } =
    useSettingsGridSelection(TEAMS, {
      actions: { remove: removeUnsavedTeams },
    });

  const {
    itemToDelete,
    setItemToDelete,
    deleteItem,
    deleteProgress,
    mergeLightboxVisible,
    showMergeLightbox,
    hideMergeLightbox,
    bulkDeleteItems,
    showBulkDeleteDialog,
    setShowBulkDeleteDialog,
  } = useSettingsActions({
    settingType: TEAMS,
    selectedItems,
    disableSelectionMode,
    deleteById: deleteTeamById,
    bulkDelete: bulkDeleteTeams,
  });

  const settingsGridColumns = useSettingsGridColumns({
    allowActions: permissions.allowActions,
    canUpdate: permissions.canUpdate,
    canDragRows: permissions.canDragRows,
    selectionMode,
    setItemToDelete,
    items: teams,
  });

  const settingsGridEventHandlers = useSettingsGridEventHandlers({
    settingType: TEAMS,
    items: teams,
    focusField: TITLE_FIELD,
    actions: {
      update: updateTeamById,
      save: createTeam,
      remove: removeUnsavedTeams,
      drag: handleRowDrag,
    },
  });

  const visibleColumns = useForceVisibleColumns(teamsGridColumnDefs, forceVisibleFields);

  // Join settings and teams column definitions
  const columnDefs = useMemo(
    () => [...settingsGridColumns.beforeColumnDefs, ...visibleColumns, ...settingsGridColumns.afterColumnDefs],
    [settingsGridColumns, visibleColumns],
  );

  return (
    <>
      {permissions.allowActions && (
        <SettingsActionsToolbar
          settingKey={TEAM_KEY}
          settingType={TEAMS}
          childrenSettingKeys={childrenSettingKeys}
          systemFields={systemFields}
          updateSystemFieldsName={updateSystemFieldsName}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          onEnableSelection={enableSelectionMode}
          onCancelSelection={disableSelectionMode}
          add={addTeamWithoutSave}
          renamePermissions={permissions.rename}
          hideAddButton={!permissions.canAdd}
          // Merge
          canMerge={permissions.canMerge}
          showMergeLightbox={showMergeLightbox}
          // Bulk
          canBulkDelete={permissions.canBulkDelete}
          setShowBulkDeleteDialog={setShowBulkDeleteDialog}
        />
      )}
      <BaseGrid
        rowData={teams}
        columnDefs={columnDefs}
        {...teamsGridProps}
        {...settingsGridEventHandlers}
        onGridReady={updateSelectedOnAgGridInit}
        defaultColDef={settingsGridColumns.defaultColDef}
        height={customHeight ?? teamsGridProps.height}
      />
      <SettingsDialogs
        settingKey={TEAM_KEY}
        settingType={TEAMS}
        deleteProgress={deleteProgress}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        deleteItem={deleteItem}
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
        // Merge
        mergeLightboxVisible={mergeLightboxVisible}
        hideMergeLightbox={hideMergeLightbox}
        merge={mergeTeams}
        // Bulk
        bulkDelete={bulkDeleteItems}
        showBulkDeleteDialog={showBulkDeleteDialog}
        setShowBulkDeleteDialog={setShowBulkDeleteDialog}
      />
    </>
  );
};

export default Teams;
