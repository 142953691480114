import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PageLoading from 'design-system/atoms/PageLoading/PageLoading';


import { selectFilteredProjectsForPage } from 'store/projects/selectors';
import { updateState } from 'store/allocationReport';

import useAllocationData from 'hooks/allocation/useAllocationData';
import useColorLegend from 'hooks/timeline/useColorLegend';
import useLoadProjects from 'hooks/projects/useLoadProjects';
import useMetadataForAllocation from 'hooks/useMetadataForAllocation';
import usePageFilters from 'hooks/filters/usePageFilters';

import { ALLOCATION_REPORT_PAGE } from 'constants/filters';

const withHigherOrderComponent = WrappedComponent => {
  const EnhancedComponent = props => {
    const { lsState, onChangeGroupBy, groupByOptions, shouldDisplayControlsBar } = props;

    const dispatch = useDispatch();

    const { displayLayer } = usePageFilters(ALLOCATION_REPORT_PAGE);

    const projects = useSelector(state => selectFilteredProjectsForPage(state, ALLOCATION_REPORT_PAGE, false, displayLayer));

    const showArchivedMetadata = !lsState?.hideArchivedData;

    const metadata = useMetadataForAllocation({ showArchived: showArchivedMetadata });
    const [, updateGroupColor] = useColorLegend(ALLOCATION_REPORT_PAGE);
    const [loadedProjects] = useLoadProjects(ALLOCATION_REPORT_PAGE, undefined, undefined, {}, {}, false);

    const allMetadata = { ...metadata, projects, lsState };

    const [isAllocationDataLoaded, allocationData] = useAllocationData(allMetadata, lsState);

    const _onTabChange = React.useCallback(
      (e, currentTab) => {
        dispatch(updateState({ currentTab }));
      },
      [dispatch, updateState],
    );

    if (!loadedProjects || !isAllocationDataLoaded) return <PageLoading />;

    return (
      <WrappedComponent
        {...props}
        allocationData={allocationData}
        projects={projects}
        metadata={allMetadata}
        lsState={lsState}
        updateGroupColor={updateGroupColor}
        groupByOptions={groupByOptions}
        onChangeGroupBy={onChangeGroupBy}
        onTabChange={_onTabChange}
        shouldDisplayControlsBar={shouldDisplayControlsBar}
      />
    );
  };

  return EnhancedComponent;
};

export default withHigherOrderComponent;
