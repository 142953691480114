import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { difference, pluck, pipe, defaultTo } from 'ramda';
import isEmpty from 'lodash/isEmpty';
import { projectFieldsLayoutConfiguration } from 'store/configuration/selectors';

import { LEFT_FIELDS, RIGHT_FIELDS, HIDDEN_FIELDS } from 'constants/projectLightbox';
import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';

import {
  getHasProjectMetrics,
  getOrgHasTeamsLevelTwo,
  hasMultiLevelPortfolioMetadata,
  selectHasKeyResults,
  selectHasKeyResults2,
  selectHasProducts,
  selectHasProducts2,
} from 'store/organization';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';
import { generateDefaultDrawerLayoutConfiguration, generateDefaultLayoutConfiguration } from 'utils/projectLightboxUtils';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const LEFT_SIDE_CUSTOM_FIELDS = [TYPES_OF_CUSTOM_FIELDS.SHORT_TEXT, TYPES_OF_CUSTOM_FIELDS.RICH_TEXT];

const KEY = 'key';
const defaultToEmptyArray = defaultTo([]);
const extractCustomFieldsKeys = pipe(defaultToEmptyArray, pluck(KEY));

const getMissingKeys = (list, existingKeys) => difference(list, existingKeys);

const makeValidateIfFieldKeyIsValid = allValidFieldsKeys => key => allValidFieldsKeys.includes(key);

const getHydratedFieldList = (currentSavedFields, defaultFields, baseFields, isValidFieldKey) => {
  const filterRemovedFields = currentSavedFields.filter(isValidFieldKey);

  return [...filterRemovedFields, ...getMissingKeys(defaultFields, baseFields)];
};

const getLeftCustomFieldsKeys = customFields =>
  customFields.filter(cf => LEFT_SIDE_CUSTOM_FIELDS.includes(cf.field_type)).map(cf => cf.key);

const getRightCustomFieldsKeys = customFields =>
  customFields.filter(cf => !LEFT_SIDE_CUSTOM_FIELDS.includes(cf.field_type)).map(cf => cf.key);

/**
 * @function generateSavedFieldsLayout
 *
 * creates the layout configuration based on the saved one and hydrates it with possible bew fields or new custom fields
 *
 * @param {Object} fieldsConfiguration
 * @param {Object} defaultFieldsLayoutConfiguration
 * @param {Array} customFields
 * @param {Array} allSavedFields
 * @param {Array} allDefaultFieldsKeys
 * @param {Function} isValidFieldKey
 * @returns {Object}
 */
const generateSavedFieldsLayout = (
  fieldsConfiguration,
  defaultFieldsLayoutConfiguration,
  customFields,
  allSavedFields,
  allDefaultFieldsKeys = [],
  isValidFieldKey = () => true,
) => ({
  ...fieldsConfiguration,
  leftFields: [
    ...getHydratedFieldList(
      fieldsConfiguration.leftFields,
      defaultFieldsLayoutConfiguration.leftFields,
      allSavedFields,
      isValidFieldKey,
    ),
    ...getMissingKeys(getLeftCustomFieldsKeys(customFields), allSavedFields),
  ],
  rightFields: [
    ...getHydratedFieldList(
      fieldsConfiguration.rightFields,
      defaultFieldsLayoutConfiguration.rightFields,
      allSavedFields,
      isValidFieldKey,
    ),
    ...getMissingKeys(getRightCustomFieldsKeys(customFields), allSavedFields),
  ],
  hiddenFields: [
    ...getHydratedFieldList(
      fieldsConfiguration.hiddenFields,
      defaultFieldsLayoutConfiguration.hiddenFields,
      allSavedFields,
      isValidFieldKey,
    ),
  ],
});

/**
 * @function generateDefaultFieldsLayout
 *
 * Gets the default layout configuration, with customFields too
 *
 * @param {Object} defaultFieldsLayoutConfiguration
 * @param {Object} customFields
 * @returns {Object}
 */
const generateDefaultFieldsLayout = (defaultFieldsLayoutConfiguration, customFields) => ({
  ...defaultFieldsLayoutConfiguration,
  leftFields: [...defaultFieldsLayoutConfiguration.leftFields, ...getLeftCustomFieldsKeys(customFields)],
  rightFields: [...defaultFieldsLayoutConfiguration.rightFields, ...getRightCustomFieldsKeys(customFields)],
});

/**
 * @function useProjectFieldsLayoutConfiguration
 *
 * Use project fields layout configuration data
 * paresed with default state
 *
 * @param  {String}  layer
 * @param  {Array}   customFields
 * @param  {Boolean} oldLightboxActive
 * @return {Object}
 */
export default (layer, customFields, oldLightboxActive) => {
  const { canView } = usePermissions();

  const hasProjectMetrics = useSelector(getHasProjectMetrics);
  const hasMetadataMultiSelect = useFeatureFlags([FeatureFlags.HAS_METADATA_MULTI_SELECT]);
  const hasProducts = useSelector(selectHasProducts);
  const hasProducts2 = useSelector(selectHasProducts2);
  const hasKeyResults = useSelector(selectHasKeyResults);
  const hasKeyResults2 = useSelector(selectHasKeyResults2);
  const hasMultiLevelMetadata = useSelector(hasMultiLevelPortfolioMetadata);
  const hasTeams2 = useSelector(getOrgHasTeamsLevelTwo);
  const hasPersonas = canView(PERMISSION_FEATURES.personas);
  const hasLifecycles = canView(PERMISSION_FEATURES.lifecycles);
  const hasExternalKey = canView(PERMISSION_FEATURES.importProjectsWithExternalKey);

  const defaultFieldsLayoutConfiguration = useMemo(
    () =>
      oldLightboxActive
        ? generateDefaultLayoutConfiguration({
            layer,
            hasProjectMetrics,
            hasMetadataMultiSelect,
            hasProducts,
            hasProducts2,
            hasKeyResults,
            hasKeyResults2,
            hasMultiLevelMetadata,
            hasTeams2,
            hasPersonas,
            hasLifecycles,
            hasExternalKey,
          })
        : generateDefaultDrawerLayoutConfiguration({
            layer,
            hasProjectMetrics,
            hasMetadataMultiSelect,
            hasProducts,
            hasProducts2,
            hasKeyResults,
            hasKeyResults2,
            hasMultiLevelMetadata,
            hasTeams2,
            hasPersonas,
            hasLifecycles,
            hasExternalKey,
          }),
    [
      hasKeyResults,
      hasKeyResults2,
      hasMultiLevelMetadata,
      hasProducts,
      hasProducts2,
      hasProjectMetrics,
      hasTeams2,
      oldLightboxActive,
      hasPersonas,
      hasLifecycles,
      hasExternalKey,
    ],
  );

  const projectsFieldsLayout = useSelector(state => projectFieldsLayoutConfiguration(state, layer));
  const isProjectsFieldsLayoutEmptyOnLayer = isEmpty(projectsFieldsLayout);
  const isProjectsFieldsLayoutUninitializedOnLayer = projectsFieldsLayout === undefined;

  const allSavedFields = useMemo(
    () =>
      !isEmpty(projectsFieldsLayout)
        ? [...projectsFieldsLayout.leftFields, ...projectsFieldsLayout.rightFields, ...projectsFieldsLayout.hiddenFields]
        : [],
    [projectsFieldsLayout],
  );

  const allValidFieldsKeys = useMemo(
    () => [
      ...defaultFieldsLayoutConfiguration[LEFT_FIELDS],
      ...defaultFieldsLayoutConfiguration[RIGHT_FIELDS],
      ...defaultFieldsLayoutConfiguration[HIDDEN_FIELDS],
      ...extractCustomFieldsKeys(customFields),
    ],
    [customFields, defaultFieldsLayoutConfiguration],
  );

  const isValidFieldKey = useMemo(() => makeValidateIfFieldKeyIsValid(allValidFieldsKeys), [allValidFieldsKeys]);

  const generatedFieldsConfiguration = useMemo(
    () =>
      isProjectsFieldsLayoutEmptyOnLayer
        ? generateDefaultFieldsLayout(defaultFieldsLayoutConfiguration, customFields)
        : generateSavedFieldsLayout(
            projectsFieldsLayout,
            defaultFieldsLayoutConfiguration,
            customFields,
            allSavedFields,
            allValidFieldsKeys,
            isValidFieldKey,
          ),
    [
      isProjectsFieldsLayoutEmptyOnLayer,
      projectsFieldsLayout,
      allSavedFields,
      isValidFieldKey,
      defaultFieldsLayoutConfiguration,
      customFields,
    ],
  );

  return {
    projectsFieldsLayout: generatedFieldsConfiguration,
    isProjectsFieldsLayoutEmptyOnLayer,
    isProjectsFieldsLayoutUninitializedOnLayer,
  };
};
