import React from 'react';
import axios from 'axios';
import uniqBy from 'lodash/uniqBy';
import { equals, uniq } from 'ramda';

import { getUserName } from 'utils';
import normalizeArray from 'utils/normalizeArray';
import useDeepEffect from 'hooks/useDeepEffect';
import { LEADER_USER, OWNER_USER } from '@dragonboat/permissions';

const valueDidNotChange = (newValue, currentValue) => equals(uniq(newValue), currentValue);

const componentHOC = Component => {
  return props => {
    const [currentValue, setCurrentValue] = React.useState([]);
    const [data, setData] = React.useState([]);
    const dataRef = React.useRef(data);
    const undefinedOption = {
      id: null,
      title: 'Undefined',
      name: 'Undefined',
    };

    dataRef.current = data;

    const _loadData = search => {
      return new Promise(resolve => {
        const params = {
          name: search,
          from: 0,
          to: 20,
        };

        axios.get('/api/users', { params }).then(({ data: resData }) => {
          const usersData = resData.data
            .map(user => ({
              ...user,
              name: getUserName(user),
              title: getUserName(user),
            }))
            .filter(u => !props.excludeAdminUsers || ![OWNER_USER, LEADER_USER].includes(u.role_id));

          if (props.showUndefined && undefinedOption.title.toLowerCase().includes(search.toLowerCase())) {
            usersData.unshift(undefinedOption);
          }

          setData(uniqBy([...dataRef.current, ...usersData], 'id'));
          const result = usersData.map(user => ({
            label: user.name,
            value: user.id,
          }));

          if (props.staticOptions) {
            resolve([
              ...props.staticOptions.filter(o => !search || o.label.toLowerCase().includes(search.toLowerCase())),
              ...result,
            ]);
            return;
          }

          resolve(result);
        });
      });
    };
    const _onValueChange = value => {
      const normalizedData = {
        ...normalizeArray(dataRef.current, 'id'),
        ...normalizeArray(currentValue.filter(Boolean), 'id'),
      };
      const newValue = value.map(v => normalizedData[v] || v);

      // if the new value as the same values as current value ignore
      if (valueDidNotChange(newValue, currentValue)) return;

      setCurrentValue(newValue);
      if (props.onChange) props.onChange(newValue);
    };

    useDeepEffect(() => {
      if (props.value) setCurrentValue(props.value);
    }, [props.value]);

    return <Component {...props} onChange={_onValueChange} options={[]} loadOptions={_loadData} />;
  };
};

export default componentHOC;
