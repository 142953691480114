import React from 'react';

import TableauIcon from './Icons/tableau.png';
import PendoIcon from './Icons/pendo-chevron-pink.png';
import AmplitudeIcon from './Icons/amplitude.png';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

const getIcon = integrationType => {
  switch (integrationType) {
    case INTEGRATIONS_KEYS.tableau:
      return {
        src: TableauIcon,
        alt: 'Tableau Icon',
      };
    case INTEGRATIONS_KEYS.pendo:
      return {
        src: PendoIcon,
        alt: 'Pendo Icon',
      };
    case INTEGRATIONS_KEYS.amplitude:
      return {
        src: AmplitudeIcon,
        alt: 'Amplitude Icon',
      };
    default: {
      throw new Error(`Missing icon for integration type: ${integrationType}`);
    }
  }
};

const IntegrationIcon = ({ integrationType }) => {
  const { src, alt } = getIcon(integrationType);

  return <img src={src} alt={alt} />;
};

export default IntegrationIcon;
