import React, { useState, useRef, useMemo, useEffect, useImperativeHandle } from 'react';
import { arrayOf, string, bool, oneOfType, object } from 'prop-types';
import Select from '@material-ui/core/Select';
import RootRef from '@material-ui/core/RootRef';

import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';

import cellEditorHoc from '../helpers/cellEditorHoc';

const OPTION_CLASS_NAME = 'dropdown-cell-editor__option';

const OPTION_SELECTOR = `li[role="option"].${OPTION_CLASS_NAME}`;

const handleOnClickAway = (clickEvent, { stopEditing }) => {
  const clickedEventTargetIsAnOption = clickEvent.target.closest(OPTION_SELECTOR);

  if (!clickedEventTargetIsAnOption) {
    stopEditing();
  }
};

const DropdownCellEditor = cellEditorHoc(
  ({ value: originalValue, options = [], showEmptyOption = false, sort = true, api, forwardedRef }) => {
    const [internalValue, setInternalValue] = useState(null);

    const domRef = useRef(null);
    const inputRef = useRef(null);

    useImperativeHandle(forwardedRef, () => ({
      getValue() {
        return internalValue ?? originalValue;
      },
    }));

    const isNotStringOrNull = val => typeof val !== 'string' && val !== null && val !== undefined;

    const handleChange = event => {
      setInternalValue(
        event.target.value instanceof Object && 'value' in event.target.value ? event.target.value.value : event.target.value,
      );
    };

    const sortOptions = options =>
      options.sort((a, b) => {
        const labelA = a.label ? a.label : a;
        const labelB = b.label ? b.label : b;

        return String(labelA).trim() > String(labelB).trim() ? 1 : -1;
      });

    const internalOptions = useMemo(() => (sort ? sortOptions(options) : options), [sort, options]);

    useEffect(() => {
      if (domRef.current && domRef.current.childNodes.length) {
        domRef.current.childNodes[0].childNodes[0].focus();
        domRef.current.childNodes[0].childNodes[0].click();
      }
    }, [domRef.current]);

    useEffect(() => {
      if (api && internalValue !== null) {
        api.stopEditing();
      }
    }, [internalValue]);

    const value = internalValue ?? originalValue;

    return (
      <RootRef rootRef={domRef}>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          style={{
            padding: '15px',
            minWidth: '180px',
            fontSize: 14,
          }}
          inputRef={node => {
            inputRef.current = node;
          }}
          value={isNotStringOrNull(value) ? value.toString() : value}
          onChange={handleChange}
          onBlur={() => api?.stopEditing()}
        >
          {showEmptyOption && <StyledMenuItemDropdown value="" />}
          {internalOptions.map(item => {
            const label = item.label ? item.label : item;
            const value = item.value ? item.value : item;

            return (
              <StyledMenuItemDropdown key={value} value={value} className={OPTION_CLASS_NAME}>
                {label}
              </StyledMenuItemDropdown>
            );
          })}
        </Select>
      </RootRef>
    );
  },
  handleOnClickAway,
);

DropdownCellEditor.propTypes = {
  options: oneOfType([arrayOf(string), arrayOf(object)]),
  showEmptyOption: bool,
  sort: bool,
};

export default DropdownCellEditor;
