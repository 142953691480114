import React, { useEffect } from 'react';
import queryString from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';

const AzureSSO = props => {
  useEffect(() => {
    const params = queryString.parse(props.location.search);

    if (!params) return;
    const obj = { ssoType: 'azure', ...params };

    window.opener?.postMessage(JSON.stringify(obj), '*');
    window.close();
  }, [props.location.search]);

  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        marginTop: '-1em' /* set to a negative number 1/2 of your height */,
        marginLeft: '-2em' /* set to a negative number 1/2 of your width */,
      }}
    >
      <CircularProgress size={50} />
    </div>
  );
};

export default AzureSSO;
