// import legacyContainer from './Legacy/container';
// import legacyComponent from './Legacy/Roadmaps';

import newContainer from './New/container';
import newComponent from './New/Roadmaps';

// const shouldUseNewVersion = localStorage.getItem('newRoadmapsTable');

// const component = shouldUseNewVersion ? newContainer(newComponent) : legacyContainer(legacyComponent);

// export default component;
export default newContainer(newComponent);
// export default legacyContainer(legacyComponent);
