// External dependencies
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { filter } from 'ramda';

// Dragonboat depencies
import { filterActiveItens } from 'utils';

export const getState = createSelector(
  state => state.tags,
  state => (state.has('rows') ? state.get('rows').toJS() : []),
);

export const getTags = createCachedSelector([getState, (_, showArchived) => showArchived], (state, showArchived) =>
  showArchived ? state : state.filter(filterActiveItens),
)((_, showArchived) => `${showArchived}`);

export const getAllTags = getState;
export const getActiveTags = createSelector(getState, tags => filter(filterActiveItens, tags));

export const getNormalizedTags = createSelector(
  state => getTags(state, true),
  state =>
    state.reduce((objs, obj) => {
      objs[obj.id] = obj;
      return objs;
    }, {}),
);

export const getTagsById = createSelector(getTags, tags => tags.reduce((byId, tag) => ({ ...byId, [tag.id]: tag }), {}));
