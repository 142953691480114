// External dependencies
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Dragonboat dependencies
import Button from '@material-ui/core/Button';
import { ConfirmDialog } from 'components/gridCommon';
import MergeLightbox from 'components/MergeLightbox';

import BulkUpdateIdeasModal from './BulkUpdateIdeasModal';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import { spacing } from 'design-system/theme';
import HelpIcon from '@material-ui/icons/HelpOutline';

export class ConfirmBulkDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      showBulkUpdateDialog: false,
      showMergeDialog: false,
    };
  }

  render() {
    const { selectedItems, onConfirmBulkDelete, onConfirmBulkArchive, hideBulkDelete, getSystemFieldName } = this.props;

    const onCancel = () => this.setState({ showDialog: false });
    const _transformLabel = idea => {
      const metadata = ['roadmapTitle', 'product1Title', 'integration', 'timeframeTitle', 'planningStage'].reduce(
        (acc, field) => {
          let fieldValue = idea[field];

          if (!fieldValue) return acc;

          if (field === 'integration') {
            fieldValue = idea.integration.key;
          }

          return [...acc, fieldValue];
        },
        [],
      );

      let metadataLabel = '';

      if (metadata.length > 0) {
        metadataLabel = ` (${metadata.join(', ')})`;
      }

      return `${idea.title}${metadataLabel}`;
    };
    const _merge = (itemsIdsToMerge, persistItem) => {
      return this.props.onMerge(persistItem, itemsIdsToMerge);
    };

    return (
      <Fragment>
        {!hideBulkDelete && (
          <Button
            title="Bulk Delete"
            color="secondary"
            disabled={!selectedItems.length}
            onClick={() => this.setState({ showDialog: true })}
          >
            Delete
          </Button>
        )}
        <Button color="primary" onClick={() => this.setState({ showBulkUpdateDialog: true })} disabled={!selectedItems.length}>
          Update
        </Button>
        {!this.props.portfolioMode && (
          <Button color="primary" onClick={() => this.setState({ showMergeDialog: true })} disabled={!selectedItems.length}>
            Merge
          </Button>
        )}
        {this.state.showDialog && (
          <ConfirmDialog
            isOpen
            title={`You are about to delete ${selectedItems.length} items.`}
            /* eslint-disable */
            text={
              <p>
                Deleting an {getSystemFieldName('idea')} permanently removes all of its data, including change history, its tasks
                and assignments.
                <br />
                <br />
                We recommend Archiving it if you may need to access it later
              </p>
            }
            /* eslint-enable */
            onCancel={onCancel}
            onDelete={() => {
              onConfirmBulkDelete();
              onCancel();
            }}
            onArchive={() => {
              onConfirmBulkArchive();
              onCancel();
            }}
          />
        )}
        {this.state.showBulkUpdateDialog && (
          <BulkUpdateIdeasModal
            selectedItems={selectedItems}
            bulkUpdate={this.props.bulkUpdate}
            onClose={() => this.setState({ showBulkUpdateDialog: false })}
          />
        )}
        <MergeLightbox
          items={selectedItems}
          dialogVisible={this.state.showMergeDialog}
          merge={_merge}
          transformLabel={_transformLabel}
          hideDialog={() => this.setState({ showMergeDialog: false })}
          saveButtonLabel="Merge"
          shouldConfirm
          title={
            <>
              Select the item to keep
              <StyledButtonIcon title="Help" id="merge-ideas-help-button">
                <HelpIcon />
              </StyledButtonIcon>
            </>
          }
        />
      </Fragment>
    );
  }
}
ConfirmBulkDialog.propTypes = {
  selectedItems: PropTypes.array,
  onConfirmBulkDelete: PropTypes.func.isRequired,
  onConfirmBulkArchive: PropTypes.func.isRequired,
};

ConfirmBulkDialog.defaultProps = {
  selectedItems: [],
};

export default ConfirmBulkDialog;

const StyledButtonIcon = styled(ButtonIcon)`
  && {
    padding: ${spacing(0.5)}px;
    margin-left: ${spacing(0.5)}px;

    svg {
      font-size: 18px;
    }
  }
`;
