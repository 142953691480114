import React from 'react';
import styled from 'styled-components';
import withTheme from '@material-ui/core/styles/withTheme';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/HelpOutline';

import LightBox from 'components/LightBox';
import CloseButton from 'components/DialogTitle/CloseButton';
import ButtonV2 from 'design-system/atoms/ButtonV2/index';
import AutocompleteV2 from 'design-system/atoms/AutocompleteV2/index';
import InputTextField from 'design-system/atoms/InputTextField/index';
import DragonIconButton from 'design-system/atoms/DragonIconButton/index';

import lightenDarkenColor from 'design-system/utils/lightenDarkenColor';
import CloudUploadIcon from 'design-system/atoms/DragonIcons/CloudUpload';
import AddIcon from 'design-system/atoms/DragonIcons/Add';
import RemoveIcon from 'design-system/atoms/DragonIcons/Remove';
import UserTypeMapper from 'utils/UserTypeMapper';
import normalizeArray from 'utils/normalizeArray';
import ImportUsersDialog from 'containers/ImportUsersDialog';
import { RESOURCE_USER, OWNER_USER, EXTERNAL_REQUESTOR } from '@dragonboat/permissions';
import useRoleAvailability from 'hooks/users/useRoleAvailability';

const MAX_USERS_TO_IMPORT = 50;

const InviteUsersDialog = ({
  isOpen,
  onClose,
  theme,
  usersToInvite,
  addNewUserToInvite,
  teams,
  removeUserToInvite,
  updateUserToInvite,
  onSubmit,
  onBulkImport,
  showBulkImportDialog,
  onCloseBulkImportDialog,
  currentUser,
  isPortalSettingsEnabled,
  canSelectRole,
}) => {
  const { isRoleAvailable } = useRoleAvailability();

  const userTypesOptions = UserTypeMapper.filter(
    ut =>
      ![RESOURCE_USER, OWNER_USER, ...(!isPortalSettingsEnabled ? [EXTERNAL_REQUESTOR] : [])].includes(ut.value) &&
      isRoleAvailable(ut.value),
  );

  // const teamsOptions = React.useMemo(() => teams.map(t => ({ value: t.id, label: t.title })), [teams]);
  const userTypesByValue = React.useMemo(() => normalizeArray(userTypesOptions, 'value'), []);
  // const teamsOptionsByValue = React.useMemo(() => normalizeArray(teamsOptions, 'value'), [teamsOptions]);
  const _shouldShowAddNewUser = () => usersToInvite.length < MAX_USERS_TO_IMPORT;

  return (
    <>
      <ImportUsersDialog opened={showBulkImportDialog} close={onCloseBulkImportDialog} isRoleAvailable={isRoleAvailable} />
      <InviteUsersLightBox
        isOpen={isOpen}
        onClose={onClose}
        maxWidth="lg"
        width="lg"
        dataCy="invite-users-dialog"
        content={
          <Wrapper>
            <CloseButton onClick={onClose} />
            <DialogContent>
              <DialogTitle>
                Invite your team and share a view or build a view for them
                <DialogHelpIcon id="invite-users-help-icon" />
              </DialogTitle>
              <InvitesListContainer>
                {usersToInvite.map(user => (
                  <UsersInvitesRow container spacing={theme.spacing.unit}>
                    <UsersInvitesCell item md={canSelectRole ? 3 : 4}>
                      <InputTextField
                        placeholder="Name"
                        size="medium"
                        error={user.errors && user.errors.name}
                        helperText={user.errors && user.errors.name}
                        value={user.name}
                        handleValue={value => updateUserToInvite(user.rowId, { name: value })}
                      />
                    </UsersInvitesCell>
                    <UsersInvitesCell item md={canSelectRole ? 3 : 4}>
                      <InputTextField
                        placeholder="Email address"
                        size="medium"
                        error={user.errors && user.errors.email}
                        helperText={user.errors && user.errors.email}
                        value={user.email}
                        handleValue={value => updateUserToInvite(user.rowId, { email: value })}
                      />
                    </UsersInvitesCell>
                    {canSelectRole && (
                      <UsersInvitesCell item md={2}>
                        <AutocompleteV2
                          suggestions={userTypesOptions}
                          inputProps={{
                            error: user.errors && user.errors.role_id,
                            helperText: user.errors && user.errors.role_id,
                          }}
                          placeholder="Select role"
                          size="medium"
                          value={user.role_id && userTypesByValue[user.role_id] && userTypesByValue[user.role_id].label}
                          onValueChange={value => updateUserToInvite(user.rowId, { role_id: value })}
                        />
                      </UsersInvitesCell>
                    )}
                    <UsersInvitesCell item md={4}>
                      <Grid container spacing={0}>
                        <Grid item md={10}>
                          <InputTextField
                            placeholder="Paste Shared View URL here (optional)"
                            size="medium"
                            error={user.errors && user.errors.shared_view}
                            helperText={user.errors && user.errors.shared_view}
                            value={user.shared_view}
                            handleValue={value => updateUserToInvite(user.rowId, { shared_view: value })}
                          />
                          {/* <AutocompleteV2
                            suggestions={teamsOptions}
                            placeholder="Select team (optional)"
                            size="medium"
                            value={user.team_id && teamsOptionsByValue[user.team_id] && teamsOptionsByValue[user.team_id].label}
                            onValueChange={value => updateUserToInvite(user.rowId, { team_id: value })}
                          /> */}
                        </Grid>
                        <RemoveGridCol item md={2}>
                          <RemoveIconButton
                            type="transparent"
                            icon={<RemoveIcon color={theme.palette.newLayout.background.red} />}
                            onClick={() => removeUserToInvite(user.rowId)}
                          />
                        </RemoveGridCol>
                      </Grid>
                    </UsersInvitesCell>
                  </UsersInvitesRow>
                ))}
              </InvitesListContainer>
              {_shouldShowAddNewUser() && (
                <ButtonV2
                  fontSize={theme.typography.fontSize}
                  color={theme.palette.background.primary}
                  background="transparent"
                  // borderColor={theme.palette.background.primary}
                  icon={<AddIcon />}
                  iconSize={{ width: 20 }}
                  onClick={addNewUserToInvite}
                >
                  Invite new teammate
                </ButtonV2>
              )}
            </DialogContent>
          </Wrapper>
        }
        actions={
          <ActionsWrapper>
            {canSelectRole && (
              <ButtonV2
                id="bulk-import-button"
                fontSize={theme.typography.fontSize}
                // borderColor={theme.palette.background.primary}
                color={theme.palette.background.primary}
                background="transparent"
                icon={<CloudUploadIcon />}
                iconSize={{ width: 20 }}
                onClick={onBulkImport}
              >
                Bulk import
              </ButtonV2>
            )}
            <InviteButton
              id="invite-button"
              color={theme.palette.background.white}
              background={theme.palette.background.primary}
              hover={{
                background: lightenDarkenColor(theme.palette.background.primary, -20),
              }}
              onClick={onSubmit}
            >
              Invite
            </InviteButton>
          </ActionsWrapper>
        }
      />
    </>
  );
};

export default withTheme()(InviteUsersDialog);

const Wrapper = styled.div``;

const InviteUsersLightBox = styled(LightBox)`
  &&&& {
    > div > div {
      width: 1128px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    button[aria-label='Close'] {
      color: ${props => props.theme.palette.text.black};
      margin: ${props => props.theme.spacing.unit * 1.25}px ${props => props.theme.spacing.unit}px;

      svg {
        width: 20.6px;
        height: 20.6px;
      }
    }
  }
`;

const DialogContent = styled.div`
  min-height: 335px;
  padding: ${props => props.theme.spacing.unit}px 0 0;
`;

const DialogTitle = styled.h4`
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: ${props => props.theme.typography.fontWeightBold};
  font-size: ${props => props.theme.typography.fontSizeMedium}px;
  margin-bottom: ${props => props.theme.spacing.unit * 0.5}px;
  font-family: ${props => props.theme.typography.fontFamily};
  line-height: 30px;
`;

const DialogHelpIcon = styled(HelpIcon)`
  margin-left: 10px;

  && {
    font-size: 22px;
  }
`;

const ActionsWrapper = styled.div`
  border-top: 4px solid ${props => props.theme.palette.background.alabaster};
  width: 100%;
  padding: ${props => props.theme.spacing.unit * 2}px ${props => props.theme.spacing.unit * 2.5}px;
  display: flex;
`;

const InviteButton = styled(ButtonV2)`
  &&&&& {
    margin-left: auto;
    min-width: ${props => props.theme.spacing.unit * 13.6}px;
    height: ${props => props.theme.spacing.unit * 5.5}px;
  }
`;

const InvitesListContainer = styled.div`
  padding: ${props => props.theme.spacing.unit * 2.5}px 0 ${props => props.theme.spacing.unit * 0.875}px 0;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const RemoveIconButton = styled(DragonIconButton)`
  &&&& {
    width: 34px;
    height: 34px;
  }
`;

const UsersInvitesCell = styled(Grid)``;

const UsersInvitesRow = styled(Grid)`
  &&&& {
    padding: ${props => props.theme.spacing.unit}px 0;

    ${RemoveIconButton} {
      opacity: 0;
    }

    ${UsersInvitesCell} {
      padding: ${props => props.theme.spacing.unit * 0.25}px ${props => props.theme.spacing.unit}px;
    }

    &:hover {
      ${RemoveIconButton} {
        opacity: 1;
      }
    }
  }
`;

const RemoveGridCol = styled(Grid)`
  &&&& {
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }
`;
