import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';

const InputWithFixedText = ({ placeholder, fixedText, handleValue, error, value }) => {
  const [theme, setTheme] = useState({
    inputWidth: placeholder.length,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    color: 'rgba(0, 0, 0, 0.87)',
  });

  useEffect(() => {
    if (error) {
      setTheme({ ...theme, borderColor: 'red!important', color: 'red!important' });
    } else {
      setTheme({ ...theme, borderColor: 'rgba(0, 0, 0, 0.23)', color: 'rgba(0, 0, 0, 0.87)' });
    }
  }, [error]);

  const handleInputChange = value => {
    const width = value.length > 0 ? value.length + 1 : placeholder.length + 1;

    setTheme({
      ...theme,
      inputWidth: width,
    });
    handleValue(value);
  };

  const setInputFocus = element => {
    let myElement = element;

    if (element.tagName === 'SPAN' || element.tagName === 'INPUT') {
      myElement = element.parentElement;
    }
    myElement.getElementsByTagName('input')[0].focus();
  };

  return (
    <ThemeProvider theme={theme}>
      <InputBlock onClick={event => setInputFocus(event.target)}>
        <input type="text" value={value} placeholder={placeholder} onChange={event => handleInputChange(event.target.value)} />
        <span onClick={event => setInputFocus(event.target)} aria-hidden="true">
          {fixedText}
        </span>
      </InputBlock>
    </ThemeProvider>
  );
};

export default InputWithFixedText;

const InputBlock = styled.div`
  & {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid;
    border-radius: 4px;
    border-color: ${props => props.theme.borderColor};
    margin: 0;
    padding: 18.5px 14px;
    color: ${props => props.theme.color};
    font-size: 1rem;
    cursor: text;
    background-color: white;
  }

  &:hover {
    border-color: black;
  }

  &:focus-within,
  &:focus-within:hover {
    border: 2px solid #1291bd;
  }

  input {
    text-align: right;
    border: none;
    padding: 0;
    box-sizing: content-box;
    font-size: 1rem;
    width: ${props => props.theme.inputWidth}ch;
    transition: width 0.15s linear;
    color: ${props => props.theme.color};

    &:focus {
      outline: none;
      width: 50%;
    }
  }

  span {
    font-weight: bold;
  }
`;
