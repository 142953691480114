import stringComparator from './stringComparator';

const multiSelectComparator = (a, b, _1, _2, isInverted) => {
  const valueA = a?.[0]?.name ?? a?.[0]?.title;
  const valueB = b?.[0]?.name ?? b?.[0]?.title;

  return stringComparator(valueA, valueB, _1, _2, isInverted);
};

export default multiSelectComparator;
