import React, { useState, useCallback, useRef, useEffect, useImperativeHandle } from 'react';
import RootRef from '@material-ui/core/RootRef';

import Autocomplete from 'design-system/atoms/Autocomplete/index';

import cellEditorHoc from '../helpers/cellEditorHoc';

const handleOnClickAway = (clickEvent, { stopEditing }) => {
  const clickedEventTargetIsRenderedAsPortal = clickEvent.target.closest('#react-autowhatever-1');

  if (!clickedEventTargetIsRenderedAsPortal) {
    stopEditing();
  }
};

const AutocompleteCellEditor = cellEditorHoc(
  ({ addNew, charPress, autocompleteComponent, autoFocus, value: originalValue, options = [], api, forwardedRef }) => {
    const [internalValue, setInternalValue] = useState(undefined);

    const domRef = useRef(null);

    useImperativeHandle(forwardedRef, () => ({
      getValue() {
        return internalValue !== undefined ? internalValue : originalValue;
      },
    }));

    const handleChange = useCallback(value => {
      setInternalValue(value);
    }, []);

    useEffect(() => {
      const autocompleteChildren = domRef.current?.childNodes;

      const autocompleteHasChildren = !!autocompleteChildren?.length;

      if (autocompleteHasChildren) {
        const inputWrapper = autocompleteChildren[0].childNodes[0];

        inputWrapper.focus();
        inputWrapper.click();
      }
    }, [domRef.current]);

    useEffect(() => {
      if (api && internalValue !== undefined) {
        api.stopEditing();
      }
    }, [internalValue]);

    const value = internalValue ?? originalValue;

    const AutocompleteComponent = autocompleteComponent || Autocomplete;

    return (
      <RootRef rootRef={domRef}>
        <AutocompleteComponent
          suggestions={options}
          value={value}
          autoFocus={autoFocus}
          onValueChange={handleChange}
          addNew={addNew}
          charPress={charPress}
        />
      </RootRef>
    );
  },
  handleOnClickAway,
);

export default AutocompleteCellEditor;
