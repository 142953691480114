import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import { propEq, isEmpty, isNil, equals, or, not, pipe } from 'ramda';

import { INTEGRATIONS_ICON_MAPPER, INTEGRATIONS_KEYS } from 'constants/integrations';
import { BET_LAYER, INITIATIVE_LAYER } from 'src/store/projects/constants';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import { removeElemProps } from 'design-system/utils';
import { brandColor } from 'design-system/themes/default';
import { getOrgHasMultipleIntegrations, getIdeasIntegrations } from 'store/organization/selectors';
import useIntegrations from 'hooks/useIntegrations';
import MultiIntegrationDropdown from '../components/MultiIntegrationDropdown';
import useDoDV2asIntegration from './useDoDV2asIntegration';

const MULTI_INTEGRATION_TYPES = [INTEGRATIONS_KEYS.JIRA, INTEGRATIONS_KEYS.azuredevops, INTEGRATIONS_KEYS.rally];
const INTEGRATIONS_TO_HIDE_IF_BET = [INTEGRATIONS_KEYS.azuredevops, INTEGRATIONS_KEYS.rally];
const INTEGRATIONS_TO_HIDE_IF_INITIATIVE = [];

const isDoDv2Integration = equals(INTEGRATIONS_KEYS.dodV2);
const isNotDoDv2Integration = pipe(isDoDv2Integration, not);

const integrationIsToShowIfInitiative = integration => {
  return !INTEGRATIONS_TO_HIDE_IF_INITIATIVE.includes(integration?.integrationType);
};

const integrationIsToShowIfBet = integration => {
  return !INTEGRATIONS_TO_HIDE_IF_BET.includes(integration?.integrationType);
};

const isCurrentProjectBet = (selectedProject, initialLayer) => {
  return initialLayer === BET_LAYER || selectedProject?.layer === BET_LAYER;
};

const isCurrentProjectInitiative = (selectedProject, initialLayer) => {
  return initialLayer === INITIATIVE_LAYER || selectedProject?.layer === INITIATIVE_LAYER;
};

const allowMultipleProjectIntegrations = (integrationType, selectedProject, initialLayer) => {
  return isCurrentProjectInitiative(selectedProject, initialLayer);
};

export default (selectedProject, renderIntegration, projectIntegrations, initialLayer) => {
  const orgIntegrations = useSelector(getIdeasIntegrations);
  const orgHasMultipleIntegrations = useSelector(getOrgHasMultipleIntegrations);
  const isBetOrInitiative = or(
    isCurrentProjectInitiative(selectedProject, initialLayer),
    isCurrentProjectBet(selectedProject, initialLayer),
  );

  // DoD v2 as an 'integration'
  const { shouldShowDoDv2, renderDoDv2Tab, renderDoDv2asIntegration } = useDoDV2asIntegration(
    selectedProject,
    isBetOrInitiative,
    Tab,
    TabIcon,
  );

  const [selectedIntegrationTab, setSelectedIntegrationTab] = useState(shouldShowDoDv2 ? INTEGRATIONS_KEYS.dodV2 : null);
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);
  const isDoDV2Selected = selectedIntegrationTab === INTEGRATIONS_KEYS.dodV2;

  const { organizationIntegrations } = useIntegrations(selectedIntegrationTab);

  const processedOrganizationIntegrations = useMemo(() => {
    if (isCurrentProjectBet(selectedProject, initialLayer)) {
      return organizationIntegrations.filter(integrationIsToShowIfBet) ?? [];
    }

    return organizationIntegrations ?? [];
  }, [organizationIntegrations, initialLayer, selectedProject]);

  const processedOrgIntegrations = useMemo(() => {
    if (isCurrentProjectInitiative(selectedProject, initialLayer)) {
      return orgIntegrations.filter(integrationIsToShowIfInitiative) ?? [];
    }

    if (isCurrentProjectBet(selectedProject, initialLayer)) {
      return orgIntegrations.filter(integrationIsToShowIfBet) ?? [];
    }

    return orgIntegrations ?? [];
  }, [orgIntegrations, initialLayer]);

  const isProjectIntegrationsAnArray = Array.isArray(projectIntegrations);
  const isNotEmptyProjectIntegrations = isProjectIntegrationsAnArray && projectIntegrations.length > 0;

  const hasProjectIntegration = useMemo(
    () => !!selectedProject.jira || isNotEmptyProjectIntegrations,
    [selectedProject.jira, isNotEmptyProjectIntegrations],
  );

  const activeIntegration = useMemo(() => {
    if (!selectedIntegrationTab) return processedOrgIntegrations[0];

    if (MULTI_INTEGRATION_TYPES.includes(selectedIntegrationTab)) {
      const activeOrgIntegrationTypes = processedOrgIntegrations.map(integration => integration.integrationType);

      if (!activeOrgIntegrationTypes.length) return null;

      return selectedIntegrationId
        ? processedOrganizationIntegrations.find(
            integration =>
              integration.id === selectedIntegrationId && activeOrgIntegrationTypes.includes(integration.integrationType),
          )
        : processedOrganizationIntegrations[0];
    }

    if (selectedIntegrationTab === INTEGRATIONS_KEYS.dodV2) {
      return INTEGRATIONS_KEYS.dodV2;
    }

    return processedOrgIntegrations.find(integration => integration.integrationType === selectedIntegrationTab);
  }, [processedOrgIntegrations, processedOrganizationIntegrations, selectedIntegrationTab, selectedIntegrationId]);

  const projectIntegration = useMemo(
    () =>
      isNotEmptyProjectIntegrations &&
      activeIntegration?.id &&
      projectIntegrations.find(propEq('org_integration_id', activeIntegration?.id)),
    [isNotEmptyProjectIntegrations, activeIntegration?.id, projectIntegrations],
  );
  const findOrgIntegrationBySomeProjectIntegration = () =>
    processedOrgIntegrations.find(integration => projectIntegrations.some(propEq('org_integration_id', integration.id)));
  const projectOrgIntegration = useMemo(() => {
    if (isEmpty(projectIntegrations)) return null;
    if (isNil(projectIntegration)) {
      return findOrgIntegrationBySomeProjectIntegration();
    }

    return processedOrgIntegrations.find(propEq('id', projectIntegration.org_integration_id));
  }, [projectIntegrations, processedOrgIntegrations, projectIntegration]);

  const onSelectedIntegrationTabChange = useCallback(
    (ev, value) => {
      if (!hasProjectIntegration) {
        const shouldResetSelectedIntegrationId = value !== selectedIntegrationTab;

        if (shouldResetSelectedIntegrationId) {
          setSelectedIntegrationId(null);
        }

        setSelectedIntegrationTab(value);
      }
    },
    [hasProjectIntegration, selectedIntegrationTab],
  );

  useEffect(() => {
    const notHasProjectOrgIntegration = isNil(projectOrgIntegration);
    const currentTabIsTheSameAsSelectedOrgIntegration = equals(selectedIntegrationTab, projectOrgIntegration?.integrationType);
    const shouldNotAutomaticallyChangeToCurrentProjectIntegrationTab =
      notHasProjectOrgIntegration || currentTabIsTheSameAsSelectedOrgIntegration;

    if (shouldNotAutomaticallyChangeToCurrentProjectIntegrationTab) {
      return;
    }

    setSelectedIntegrationTab(projectOrgIntegration.integrationType);
    setSelectedIntegrationId(projectOrgIntegration.id);
  }, [projectOrgIntegration?.integrationType, projectOrgIntegration?.id, selectedIntegrationTab]);

  useEffect(() => {
    const notHasProjectIntegrationAndNotSelectedAnyTab = !hasProjectIntegration && !selectedIntegrationTab;
    const hasProjectIntegrationAndAnyTabSelected = hasProjectIntegration && !selectedIntegrationId && projectOrgIntegration;
    const hasJiraProjectIntegarationAndAnyTabSelected = hasProjectIntegration && selectedProject.jira && !selectedIntegrationId;

    if (notHasProjectIntegrationAndNotSelectedAnyTab) {
      setSelectedIntegrationTab(activeIntegration?.integrationType);
    } else if (hasProjectIntegrationAndAnyTabSelected) {
      setSelectedIntegrationTab(projectOrgIntegration.integrationType);
      setSelectedIntegrationId(projectOrgIntegration.id);
    } else if (hasJiraProjectIntegarationAndAnyTabSelected) {
      setSelectedIntegrationTab(selectedProject.jira?.integrationType);
      setSelectedIntegrationId(selectedProject.jira?.orgIntegration_id);
    }
  }, [hasProjectIntegration, activeIntegration, projectIntegration, projectOrgIntegration]);

  if (!orgHasMultipleIntegrations && activeIntegration && isNotDoDv2Integration(activeIntegration)) {
    return [<>{renderIntegration(activeIntegration)}</>];
  }

  const _tabButtonPropsForIntegrationType = integrationType => {
    if (!['miro'].includes(integrationType)) {
      return {};
    }

    return {
      disableRipple: true,
      disableFocusRipple: true,
      style: { backgroundColor: 'transparent' },
    };
  };
  const _isIntegrationTabVisible = integrationType => {
    if (integrationType === 'miro' && (!projectIntegration || projectOrgIntegration?.integrationType !== 'miro')) {
      // Only show miro tab when integration is active for this project
      return false;
    }

    if (selectedProject.jira) return integrationType === INTEGRATIONS_KEYS.JIRA;
    if (projectOrgIntegration) return projectOrgIntegration.integrationType === integrationType;

    // by default the integration tab is visible
    return true;
  };
  const _getIcon = integrationType => {
    const IconIntegration = INTEGRATIONS_ICON_MAPPER[integrationType];

    if (!IconIntegration) return '';

    return <IconIntegration />;
  };

  const tabs = (
    <>
      <Tabs
        value={selectedIntegrationTab}
        onChange={onSelectedIntegrationTabChange}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
      >
        {processedOrgIntegrations
          .reduce((acc, i) => (acc.some(accI => accI.integrationType === i.integrationType) ? acc : [...acc, i]), [])
          .map(integration => (
            <Tab
              component="div"
              disableTouchRipple
              disableRipple
              value={integration.integrationType}
              label={
                <>
                  <TabIcon
                    {..._tabButtonPropsForIntegrationType(integration.integrationType)}
                    visible={_isIntegrationTabVisible(integration.integrationType)}
                    active={!hasProjectIntegration && selectedIntegrationTab === integration.integrationType}
                  >
                    {_getIcon(integration.integrationType)}
                  </TabIcon>
                  {MULTI_INTEGRATION_TYPES.includes(selectedIntegrationTab) &&
                    selectedIntegrationTab === integration.integrationType &&
                    organizationIntegrations.length > 1 && (
                      <MultiIntegrationDropdown
                        selectedProject={selectedProject}
                        integrationType={integration.integrationType}
                        selectedIntegration={activeIntegration}
                        onChange={id => setSelectedIntegrationId(id)}
                        disabled={hasProjectIntegration}
                        enableMultipleIntegrations={allowMultipleProjectIntegrations(
                          integration.integrationType,
                          selectedProject,
                          initialLayer,
                        )}
                      />
                    )}
                </>
              }
            />
          ))}
        {shouldShowDoDv2 ? renderDoDv2Tab(isDoDV2Selected) : null}
      </Tabs>
      <Integration>{isDoDV2Selected ? renderDoDv2asIntegration() : renderIntegration(activeIntegration)}</Integration>
    </>
  );

  return [tabs];
};

const Tab = styled(MuiTab)`
  &&&& {
    min-height: 0;
    font-weight: 400;
    font-size: 0.875rem;
    min-width: 0;
    max-width: 330px;
    height: 64px;
    display: block;

    > span > span {
      padding: 0;

      > span {
        display: flex;
      }
    }

    svg {
      font-size: 20px;
    }
  }
`;

const Integration = styled.div`
  margin: 15px 0;
`;

const TabIcon = styled(removeElemProps(ButtonIcon, ['active']))`
  &&&&& {
    color: #6b6b6b;
    margin: 0 2px;
    border: 1px solid transparent;

    ${({ active }) =>
      active &&
      `
      border: 1px solid ${brandColor};
    `}

    ${({ visible }) =>
      !visible &&
      `
      display: none;
    `}

    svg {
      fill: #6b6b6b;

      ${({ active }) => active && 'fill: #009dff;'}
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
`;
