import axios from 'axios';

import { omit, pipe, prop, isNil, isEmpty, path } from 'ramda';

import { getIfModifiedSince, getAllFromMultipleLayers } from 'store/projects/selectors';

import { IDEA_LAYER } from 'store/projects/constants';
import { fetchProjectsOrApplyFilter } from 'store/roadmapVersions';

const isNilOrEmpty = value => isNil(value) || isEmpty(value);

const propIsNilOrEmpty = key => pipe(prop(key), isNilOrEmpty);

const filterOutNullPaginationAttributes = filters => {
  if (propIsNilOrEmpty('from')(filters) || propIsNilOrEmpty('to')(filters)) {
    return omit(['from', 'to'], filters);
  }

  return filters;
};

const fetchFromProjectsApi = async ({
  getStore,
  layers,
  storeAsFilters,
  params,
  paginationAttributes,
  extraFilters,
  selectedRoadmapVersion,
}) => {
  const ifModifiedSinceHeader = getIfModifiedSince(
    getStore().getState(),
    layers,
    storeAsFilters,
    params ? omit(['layer'], params) : {},
  );

  const options = { params };

  if (ifModifiedSinceHeader) {
    options.headers = { 'If-Modified-Since': ifModifiedSinceHeader };
  }

  let projectsResult;

  let projectsSearchMetadataResult;

  try {
    const filters = {
      ...paginationAttributes,
      ...filterOutNullPaginationAttributes(params),
      ...omit(['fields'], extraFilters),
    };

    let response;

    if (selectedRoadmapVersion?.id) {
      response = await fetchProjectsOrApplyFilter(selectedRoadmapVersion, filters, getStore);
    } else {
      response = await axios.post('/api/projects/search', {
        ...filters,
      });
    }

    projectsResult = response.data.data;

    projectsSearchMetadataResult = response.data.metadata;
  } catch (err) {
    if (path(['response', 'status'], err) === 304) {
      projectsResult = getAllFromMultipleLayers(getStore().getState(), layers || [IDEA_LAYER], storeAsFilters);
    } else {
      throw err;
    }
  }

  return { projectsResult, projectsSearchMetadataResult };
};

export { fetchFromProjectsApi };
