import { defaultTo } from 'ramda';
import { RoundMode } from './roundMode';

const WORK_WEEK_DURATION_IN_DAYS = 5;
const CALENDAR_WEEK_DURATION_IN_DAYS = 7;

/**
 * Enum for unit values.
 * @readonly
 * @enum {number}
 */
export const Unit = {
  DAYS: 1,
  WORK_WEEK: 2,
  CALENDAR_WEEK: 3,
};

const defaultToDays = defaultTo(Unit.DAYS);
const defaultToRound = defaultTo(RoundMode.ROUND);

/**
 * @typedef {Object} Converter
 * @property {Unit} unit
 * @property {Function} convert Function used to convert days to the desired format
 */

/**
 * @type {Converter}
 */
const DAYS_DAYS_CONVERTER = {
  unit: Unit.DAYS,
  convert: (days, rounder) => rounder(days),
};

/**
 * @type {Converter}
 */
const DAYS_WEEK_CONVERTER = {
  unit: Unit.WORK_WEEK,
  convert: (days, rounder) => rounder(days / WORK_WEEK_DURATION_IN_DAYS),
};

/**
 * @type {Converter}
 */
const DAYS_CALENDAR_WEEK_CONVERTER = {
  unit: Unit.CALENDAR_WEEK,
  convert: (days, rounder) => rounder(days / CALENDAR_WEEK_DURATION_IN_DAYS),
};

/**
 * @type {Converter}
 */
const WEEK_WEEK_CONVERTER = {
  unit: Unit.WORK_WEEK,
  convert: (weeks, rounder) => rounder(weeks),
};

/**
 * @type {Converter}
 */
const WEEK_DAYS_CONVERTER = {
  unit: Unit.DAYS,
  convert: (weeks, rounder) => rounder(weeks * WORK_WEEK_DURATION_IN_DAYS),
};

/**
 * @type {Converter}
 */
const CALENDAR_WEEK_CALENDAR_WEEK_CONVERTER = {
  unit: Unit.CALENDAR_WEEK,
  convert: (weeks, rounder) => rounder(weeks),
};

/**
 * @type {Converter}
 */
const CALENDAR_WEEK_DAYS_CONVERTER = {
  unit: Unit.DAYS,
  convert: (weeks, rounder) => rounder(weeks * CALENDAR_WEEK_DURATION_IN_DAYS),
};

const AVAILABLE_DAY_CONVERTERS = {
  [Unit.DAYS]: DAYS_DAYS_CONVERTER,
  [Unit.WORK_WEEK]: DAYS_WEEK_CONVERTER,
  [Unit.CALENDAR_WEEK]: DAYS_CALENDAR_WEEK_CONVERTER,
};

const AVAILABLE_WEEK_CONVERTERS = {
  [Unit.DAYS]: WEEK_DAYS_CONVERTER,
  [Unit.WORK_WEEK]: WEEK_WEEK_CONVERTER,
};

const AVAILABLE_CALENDAR_WEEK_CONVERTERS = {
  [Unit.DAYS]: CALENDAR_WEEK_DAYS_CONVERTER,
  [Unit.CALENDAR_WEEK]: CALENDAR_WEEK_CALENDAR_WEEK_CONVERTER,
};

/**
 * Converts `days` based on `unit` and `roundMode` when provided or round({@link Unit.DAYS}) otherwise.
 * @param {Number} days
 * @param {Unit|undefined} unit
 * @returns {Number}
 */
export const convertDaysTo = (days, unit = undefined, roundMode = undefined) => {
  const converter = AVAILABLE_DAY_CONVERTERS[defaultToDays(unit)];
  const mathRounder = defaultToRound(roundMode).rounder;

  return converter.convert(days, mathRounder);
};

/**
 * Converts `weeks` based on `unit` and `roundMode` when provided or round({@link Unit.WEEKS}) otherwise.
 * @param {Number} weeks
 * @param {Unit|undefined} unit
 * @returns {Number}
 */
export const convertWorkWeeksTo = (days, unit = undefined, roundMode = undefined) => {
  const converter = AVAILABLE_WEEK_CONVERTERS[defaultToDays(unit)];
  const mathRounder = defaultToRound(roundMode).rounder;

  return converter.convert(days, mathRounder);
};

/**
 * Converts `weeks` based on `unit` and `roundMode` when provided or round({@link Unit.WEEKS}) otherwise.
 * @param {Number} weeks
 * @param {Unit|undefined} unit
 * @returns {Number}
 */
export const convertCalendarWeeksTo = (days, unit = undefined, roundMode = undefined) => {
  const converter = AVAILABLE_CALENDAR_WEEK_CONVERTERS[defaultToDays(unit)];
  const mathRounder = defaultToRound(roundMode).rounder;

  return converter.convert(days, mathRounder);
};
