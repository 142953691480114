import { ChipsCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { MultiSelectCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import { multiSelectComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { returnsTrueIfKeyIsNotEscOrTab } from 'design-system/molecules/AgGridReact-New/helpers';

import { EmptyValueIfNotProjectRenderer } from '../renderers';

const getAdditionalMetadataColumnsCommonProps = ({ getNumberOfTextRowsToDisplayOnGridCell, handleMetadataCellDoubleClick }) => ({
  cellRenderer: EmptyValueIfNotProjectRenderer(ChipsCellRenderer),
  cellEditor: MultiSelectCellEditor,
  cellEditorPopup: true,
  cellRendererParams: {
    getNumberOfTextRowsToDisplayOnGridCell,
  },
  comparator: multiSelectComparator,
  suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
  onCellDoubleClicked: handleMetadataCellDoubleClick,
  width: 120,
  showTooltip: true,
  labelField: 'title',
  chipVariant: 'outlined',
  colorField: '',
});

export default getAdditionalMetadataColumnsCommonProps;
