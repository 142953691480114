import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addProjectIntegration, isLoadingAddProjectIntegration, getAddProjectIntegrationError } from 'store/integrations';

export default (projectId, integrationId, updateProject) => {
  const dispatch = useDispatch();
  const error = useSelector(getAddProjectIntegrationError);
  const isLoading = useSelector(isLoadingAddProjectIntegration);

  const _linkProjectIntegration = useCallback(
    (epicId, data) => {
      dispatch(
        addProjectIntegration(
          projectId,
          epicId,
          {
            org_integration_id: integrationId,
            ...data,
          },
          updateProject,
        ),
      );
    },
    [projectId, integrationId, updateProject],
  );

  return [_linkProjectIntegration, isLoading, error];
};
