import EditIcon from '@material-ui/icons/Edit';
import theme from 'design-system/theme';

import createActionColumnDefGetter from './createActionColumnDefGetter';

const EDIT_COLUMN_ID = 'editItem';

const getEditColumnDef = createActionColumnDefGetter({
  field: EDIT_COLUMN_ID,
  icon: EditIcon,
  otherActionDefs: {
    iconStyles: {
      fontSize: theme.typography.fontSize,
      width: '20px',
    },
  },
});

export { EDIT_COLUMN_ID };

export default getEditColumnDef;
