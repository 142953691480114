import { useCallback, useState } from 'react';

import { TRANSACTION_PAGES_PROJECTS_LIMIT } from 'constants';
import { getPaginationInformation } from 'utils/pagination';

import { filterProjectsMilestones } from 'utils/projects/projectsUtils';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectMetadata } from 'store/projects/metadataSelectors';
import { getEnrichedProject } from 'store/projects/helpers/enrichProjects';

export default function useLoadProjectsByEntity(updatePaginationInformation) {
  const [isLoading, setIsLoading] = useState([]);
  const [projects, setProjects] = useState([]);
  const [totalProjects, setTotalProjects] = useState(0);

  const enrichProjectsMetadata = useSelector(state => selectMetadata(state));

  const handleSearchProjects = useCallback(
    async ({ entity, entityType }, params, showCompleted = false, limit = TRANSACTION_PAGES_PROJECTS_LIMIT, offset = 0) => {
      setIsLoading(true);

      const { data } = await axios.get(`/api/projects/${entityType}/${entity}/list`, {
        params: {
          ...params,
          showCompleted,
          from: offset,
          limit: offset + limit,
        },
      });

      const { metadata, data: projects } = data;
      const { total } = metadata || {};

      updatePaginationInformation(getPaginationInformation(limit, offset));

      const enrichedProjects = filterProjectsMilestones(projects).map(p => getEnrichedProject(p, enrichProjectsMetadata, {}));

      setIsLoading(false);
      setTotalProjects(total);
      setProjects(enrichedProjects);
    },
    [enrichProjectsMetadata, updatePaginationInformation, setIsLoading, setTotalProjects, setProjects],
  );

  return {
    projects,
    handleSearchProjects,
    isLoadingProjects: isLoading,
    totalProjects,
  };
}
