import React from 'react';

import SelectTemplate from './SelectTemplate';
import PreviewTemplate from './PreviewTemplate';
import StartGuideLightbox from './StartGuideLightbox';

export default props => {
  return (
    <React.Fragment>
      <SelectTemplate location={props.location} />
      <PreviewTemplate />
      <StartGuideLightbox />
    </React.Fragment>
  );
};
