import React from 'react';

import { FeatureFlags } from '@dragonboat/config';
import { checkOrganizationFlags } from 'hooks/useFeatureFlags';

import MapImportDataFields from './MapImportDataFields';
import MapImportDataFieldsLegacy from './Legacy/MapImportDataFieldsLegacy';

export default props => {
  const useBlocksFieldMappingByType = checkOrganizationFlags({}, [FeatureFlags.BLOCKS_FIELD_MAPPING_BY_TYPE]);

  if (useBlocksFieldMappingByType) {
    return <MapImportDataFields {...props} />;
  }

  return <MapImportDataFieldsLegacy {...props} />;
};
