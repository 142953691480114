import reduceReducers from 'reduce-reducers';
import moment from 'moment';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  FETCH_GOALS_ALLOCATION_DATA,
  UPDATE_GOALS_ALLOCATION_GRID_PREFERENCES,
  UPDATE_GOALS_ALLOCATION_TIME_PERIOD,
  UPDATE_GOALS_ALLOCATION_TIME_UNIT,
} from './types';

const { initialState: thunksInitialState, reducers: operationsReducers } = getThunksInitialStateAndReducers([
  FETCH_GOALS_ALLOCATION_DATA,
]);

const defaultStartDate = moment().utcOffset(0).startOf('year').valueOf();
const defaultEndDate = moment().utcOffset(0).startOf('month').valueOf();

const initialState = {
  ...thunksInitialState,
  timePeriod: {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
  },
  timeUnit: 'month',
  gridPreferences: {
    showHealth: true,
    showAllocationInWeeks: true,
    showAllocationAsPercentage: false,
    showColumnTotal: true,
    showTargetAllocationPercentage: true,
    showTargetAllocationAmount: true,
    showTwoDecimals: true,
  },
};

const goalsAllocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_GOALS_ALLOCATION_TIME_PERIOD:
      return {
        ...state,
        timePeriod: action?.payload,
      };
    case UPDATE_GOALS_ALLOCATION_TIME_UNIT:
      return {
        ...state,
        timeUnit: action?.payload,
      };
    case UPDATE_GOALS_ALLOCATION_GRID_PREFERENCES:
      return {
        ...state,
        gridPreferences: {
          ...state.gridPreferences,
          ...action?.payload,
        },
      };
    default:
      return state;
  }
};

const reducer = reduceReducers(initialState, goalsAllocationReducer, ...operationsReducers);

export default reducer;
