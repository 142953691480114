export default function cleanUserFilterState(state) {
  const INVALID_KEYS = ['quickFilters', 'from', 'to', 'order'];
  const isValidKey = key => !INVALID_KEYS.includes(key);

  return Object.entries(state || {}).reduce((result, [key, value]) => {
    if (isValidKey(key)) {
      result[key] = value;
    }
    return result;
  }, {});
}
