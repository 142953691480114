import React from 'react';

import { returnsTrueIfKeyIsNotEnterOrTab, wrapTextOptionsFactory } from 'utils/agGrid';
import Autocomplete, { cellParams } from 'components/AgGridAutocomplete';
import AgGridGroupRenderer from 'components/AgGridGroupRenderer';
import AgGridParseLinks from 'components/AgGridParseLinks';
import AgGridDeleteButton from 'components/AgGridDeleteButton';
import AgGridTimeframeDisplay from 'components/AgGridTimeframeDisplay';
import keyResultsColumnsFactory from 'utils/keyResultsColumnsFactory';
import productsColumnsFactory from 'utils/productsColumnsFactory';
import AgGridTextareaEditor from 'components/AgGridTextareaEditor';
import ShowMaterialColor from 'components/ShowMaterialColor';
import CustomFieldColumnDefFactory from 'components/CustomFieldColumnDefFactory';
import getSystemFieldName from 'utils/getSystemFieldName';
import AgGridTextarea from 'design-system/atoms/AgGridTextArea/AgGridTextArea';
import TextAreaEditorRender from 'design-system/organisms/TextAreaEditorRender/index';
import AgGridDropdown from 'design-system/atoms/AgGridDropdown/AgGridDropdown';

import AgGridYYYYMMDDRenderer from 'components/AgGridDateRenderer/AgGridYYYYMMDDRenderer';
import { DatePickerCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import { IDEA_LAYER } from 'store/projects/constants';

const FORMULA_FIELD_TYPE = 'Formula';

const editOptions = {
  editable: true,
};

export default (
  {
    productOwners,
    phaseTitles,
    roadmapsTitles,
    timeframesTitles,
    objectiveTitles,
    themeTitles,
    categoryTitles,
    priorities,
    teamsTitles,
    rowsAutoheight,
    showJiraKey,
    selectMode,
    organization,
    objectives,
    roadmaps,
    showGithubKey,
    customFields,
    hidePlanningStage,
    hideProgress,
    hideDeleteButton,
    systemFields,
  },
  removeRow = () => {},
  layer,
) => {
  const wrapTextOptions = wrapTextOptionsFactory(rowsAutoheight);

  const keyResultsColumns = keyResultsColumnsFactory(organization, objectives, wrapTextOptions, systemFields);
  const productsColumns = productsColumnsFactory(organization, roadmaps, wrapTextOptions, systemFields);

  customFields = customFields || [];

  const initiativeColumn = {
    ...editOptions,
    ...wrapTextOptions,
    cellEditor: AgGridTextarea,
    headerName: getSystemFieldName('initiative', systemFields),
    field: 'initiativeTitle',
    cellRenderer: AgGridGroupRenderer('Initiative'),
    minWidth: 140,
  };

  let hierarchyColumns = [];

  if (layer === IDEA_LAYER) {
    hierarchyColumns = [initiativeColumn];
  }

  let columns = [
    {
      valueGetter: ({ node }) => {
        return node.rowIndex + 1;
      },
      width: 40,
      minWidth: 30,
      maxWidth: 40,
      pinned: 'left',
      lockPinned: true,
    },
    {
      ...editOptions,
      ...wrapTextOptions,
      headerName: 'Title (Required)',
      field: 'title',
      cellEditor: AgGridTextarea,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEnterOrTab,
      cellEditorParams: {
        autoFocus: true,
        placeholder: 'Title',
      },
      pinned: 'left',
      lockPinned: true,
      minWidth: 300,
    },
    ...(organization && organization.has_hierarchy ? hierarchyColumns : []),
    {
      ...editOptions,
      headerName: 'Target start date',
      field: 'estimated_start_date',
      minWidth: 140,
      cellRenderer: AgGridYYYYMMDDRenderer,
      cellEditor: DatePickerCellEditor,
      cellEditorPopup: true,
    },
    {
      ...editOptions,
      headerName: 'Target end date',
      field: 'deadline',
      minWidth: 140,
      cellRenderer: AgGridYYYYMMDDRenderer,
      cellEditor: DatePickerCellEditor,
      cellEditorPopup: true,
    },
    {
      ...editOptions,
      ...wrapTextOptions,
      ...cellParams(productOwners),
      headerName: 'Owner',
      field: 'ownerName',
      cellRenderer: AgGridGroupRenderer('Owner'),
      cellEditor: Autocomplete,
      minWidth: 140,
    },
    {
      ...editOptions,
      ...wrapTextOptions,
      ...cellParams(phaseTitles),
      headerName: getSystemFieldName('phase', systemFields),
      field: 'phaseTitle',
      cellRenderer: AgGridGroupRenderer(getSystemFieldName('phase', systemFields)),
      cellEditor: Autocomplete,
      minWidth: 140,
    },
    {
      ...editOptions,
      ...wrapTextOptions,
      ...cellParams(roadmapsTitles),
      headerName: getSystemFieldName('roadmap', systemFields),
      field: 'roadmapTitle',
      cellEditor: Autocomplete,
      minWidth: 120,
      cellRenderer: AgGridGroupRenderer(getSystemFieldName('roadmap', systemFields)),
      filterParams: {
        values: roadmapsTitles,
      },
    },
    ...productsColumns,
    {
      ...editOptions,
      ...wrapTextOptions,
      ...cellParams(timeframesTitles),
      headerName: getSystemFieldName('timeframe', systemFields),
      field: 'timeframeTitle',
      cellEditor: Autocomplete,
      cellRenderer: AgGridTimeframeDisplay(false, getSystemFieldName('timeframe', systemFields)),
      filterParams: {
        values: timeframesTitles,
      },
      minWidth: 140,
    },
    {
      ...editOptions,
      ...wrapTextOptions,
      ...cellParams(objectiveTitles),
      headerName: getSystemFieldName('objective', systemFields),
      field: 'objectiveTitle',
      cellRenderer: AgGridGroupRenderer(getSystemFieldName('objective', systemFields)),
      cellEditor: Autocomplete,
      minWidth: 140,
    },
    ...keyResultsColumns,
    {
      ...editOptions,
      ...wrapTextOptions,
      ...cellParams(themeTitles),
      headerName: getSystemFieldName('theme', systemFields),
      field: 'themeTitle',
      cellEditor: Autocomplete,
      minWidth: 140,
      cellRenderer: AgGridGroupRenderer(getSystemFieldName('theme', systemFields)),
    },
    {
      ...editOptions,
      ...wrapTextOptions,
      ...cellParams(categoryTitles),
      headerName: getSystemFieldName('category', systemFields),
      field: 'categoryTitle',
      minWidth: 140,
      cellRenderer: AgGridGroupRenderer(getSystemFieldName('category', systemFields)),
      cellEditor: Autocomplete,
    },
    {
      ...editOptions,
      ...cellParams(priorities),
      headerName: getSystemFieldName('priority', systemFields),
      field: 'priorityTitle',
      cellEditor: Autocomplete,
      minWidth: 140,
      cellRenderer: AgGridGroupRenderer(getSystemFieldName('priority', systemFields)),
    },
    {
      ...editOptions,
      ...cellParams(teamsTitles),
      headerName: getSystemFieldName('team', systemFields),
      field: 'resourceTeamTitle',
      cellEditor: Autocomplete,
      minWidth: 140,
      cellRenderer: AgGridGroupRenderer(getSystemFieldName('team', systemFields)),
    },
    {
      ...editOptions,
      ...wrapTextOptions,
      headerName: 'Details',
      cellEditor: AgGridTextareaEditor,
      cellRenderer: TextAreaEditorRender,
      field: 'details',
      minWidth: 400,
    },
    {
      ...editOptions,
      ...wrapTextOptions,
      headerName: 'Links',
      cellRenderer: AgGridParseLinks,
      cellEditor: AgGridTextarea,
      field: 'links',
      cellEditorParams: {
        autoFocus: true,
      },
      minWidth: 140,
    },
    // TODO: Dragonboat project notes field is deprecated and should be removed from project field mapping
    {
      ...editOptions,
      ...wrapTextOptions,
      headerName: 'Notes',
      cellEditor: AgGridTextareaEditor,
      cellRenderer: TextAreaEditorRender,
      field: 'notes',
      cellEditorParams: {
        autoFocus: true,
      },
    },
    {
      ...editOptions,
      headerName: 'Strategic Benefit (0-5)',
      field: 'benefit1',
      cellClass: 'text-center',
      hide: true,
    },
    {
      ...editOptions,
      headerName: 'Urgency (0-5)',
      field: 'benefit2',
      cellClass: 'text-center',
      hide: true,
    },
    {
      ...editOptions,
      headerName: 'Frequency (0-5)',
      field: 'benefit3',
      cellClass: 'text-center',
      hide: true,
    },
    {
      ...editOptions,
      headerName: 'Cost (0-5)',
      field: 'cost1',
      cellClass: 'text-center',
      hide: true,
    },
    {
      headerName: 'Reach Score',
      field: 'reach_score',
      cellClass: 'text-center',
      ...editOptions,
    },
    {
      headerName: 'Impact Score',
      field: 'impact_score',
      cellClass: 'text-center',
      ...editOptions,
    },
    {
      headerName: 'Confidence Score',
      field: 'confidence_score',
      cellClass: 'text-center',
      ...editOptions,
    },
    {
      headerName: 'Effort',
      field: 'effort_score',
      cellClass: 'text-center',
      ...editOptions,
    },
    {
      ...editOptions,
      ...wrapTextOptions,
      cellEditor: AgGridTextareaEditor,
      cellRenderer: TextAreaEditorRender,
      headerName: 'Status Summary',
      field: 'status_summary',
      cellEditorParams: {
        autoFocus: true,
      },
    },
    {
      ...editOptions,
      headerName: 'Health',
      field: 'status_color',
      enableRowGroup: true,
      cellRenderer: ShowMaterialColor,
      cellEditor: AgGridDropdown,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEnterOrTab,
      cellEditorParams: {
        options: ['', 'Red', 'Yellow', 'Green', 'Blue'],
      },
      width: 80,
    },
    ...customFields
      .filter(({ field_type: fieldType, status }) => status === 'Active' && fieldType !== FORMULA_FIELD_TYPE)
      .map(CustomFieldColumnDefFactory({ ...wrapTextOptions })),
  ];

  if (!hidePlanningStage) {
    columns.push({
      ...editOptions,
      headerName: 'Planning Stage',
      field: 'planningStage',
      enableRowGroup: true,
      cellRenderer: AgGridGroupRenderer('Planning Stage'),
      cellClassRules: {
        'material--blue': params => params.value === 'Planning',
        'material--green': params => params.value === 'Confirmed',
        'material--darkGray': params => params.value === 'Archived',
        'material--darkerGray': params => params.value === 'Completed',
      },
      cellEditor: AgGridDropdown,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEnterOrTab,
      cellEditorParams: {
        showEmptyOption: false,
        options: ['Archived', 'Backlog', 'Planning', 'Confirmed', 'Completed'],
      },
      width: 150,
      hide: true,
    });
  }

  // if (!hideProgress) {
  //   columns.push({
  //     headerName: 'Progress',
  //     field: 'progress',
  //     cellRenderer: ProgressDisplay(false),
  //     editable: false,
  //     width: 95,
  //     hide: true,
  //   });
  // }

  if (selectMode) {
    columns.splice(1, 0, {
      headerName: '',
      field: 'select',
      editable: false,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      suppressToolPanel: true,
      width: 40,
      maxWidth: 40,
      pinned: 'left',
      lockPinned: true,
    });
  } else if (!hideDeleteButton) {
    columns.splice(0, 0, {
      cellRenderer: params => <AgGridDeleteButton {...params} onClick={removeRow} />,
      width: 40,
      minWidth: 40,
      maxWidth: 40,
      pinned: 'left',
      lockPinned: true,
    });
  }

  if (showJiraKey) {
    columns.splice(1, 0, {
      headerName: 'JIRA KEY',
      field: 'jiraKey',
      minWidth: 140,
    });
  }

  if (showGithubKey) {
    columns.splice(1, 0, {
      headerName: 'GITHUB KEY',
      field: 'githubKey',
      editable: false,
      minWidth: 140,
    });

    const columnsFilter = ['select', 'title', 'githubKey', 'ownerName', 'businessGoalTitle', 'details', 'notes', 'end_date'];

    columns = columns.filter(column => !column.field || columnsFilter.includes(column.field));
  }

  return columns;
};
