// External dependencies
import { connect } from 'react-redux';

// Dragonboat dependencies
import { createUsers, updateUsers, importUsers } from 'store/users';
import ImportUsersDialog from './ImportUsersDialog';

export default connect(
  state => {
    const { teams: teamState, skills: skillsState, organization } = state;

    const teams = teamState.teams.map(team => (team ? team.title : null)).filter(team => team);
    const skills = skillsState.skills.map(skill => (skill ? skill.title : null)).filter(skill => skill);

    return {
      teams,
      skills,
      isPortalSettingsEnabled: organization?.organization?.enable_portal_settings,
    };
  },
  { createUsers, updateUsers, importUsers },
)(ImportUsersDialog);
