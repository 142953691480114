import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { not } from 'ramda';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import pluralize from 'pluralize';

import BarChart from 'components/ReactChart/BarChart';
import ChartWrapper from 'src/components/ReactChart/utils/ChartWrapper';
import useBarStackedCharDatalabelsConfig from 'hooks/charts/useBarStackedCharDatalabelsConfig';

import useRoadmapByOkrStackedChartData from './useRoadmapByOkrStackedChartData';

import useSystemFields from 'hooks/useSystemFields';

import useDetailsTableLightboxOnDashboardChart from '../../hooks/useDetailsTableLightboxOnDashboardChart';
import DetailsTableLightbox from '../../DetailsTableLightbox/DetailsTableLightbox';
import { getDetailsTableVisibleColumns, getObjectiveId } from './helpers';
import getDisplayLayerLabel from 'store/projects/helpers/getDisplayLayerLabel';
import ChartContent from '../ChartContent';
import ChartLegend from '../ChartLegend';
import { HIDE_LEGEND_OPTION, HIDE_GRID_OPTION } from 'constants/charts';
import { tickSmallStyles, tickStyles } from '../utils/scales';

const MAX_LEGEND_ITEMS = 6;

const RoadmapByOkrStackedChart = ({ wrapper: Wrapper, disableClick, minHeight, title, subtitle, actionButtons }) => {
  const { chartData, hasData, selectedGroup1, selectedGroup2, displayLayer, hasKeyResults1 } = useRoadmapByOkrStackedChartData();

  const [getSystemFieldName] = useSystemFields();

  const datalabelsConfig = useBarStackedCharDatalabelsConfig(chartData.datasets);

  const getFilterForLightboxData = useCallback(
    objectiveIndex => {
      const objectiveId = getObjectiveId(objectiveIndex, chartData);

      return {
        objective_id: `${objectiveId}`,
      };
    },
    [chartData],
  );

  const { chartRef, detailsTableOpen, handleClickOnChartSlice, handleCloseDetailsTableLightbox, projectsSubFilters } =
    useDetailsTableLightboxOnDashboardChart({
      getFilterForLightboxData,
    });

  const visibleColumns = useMemo(
    () => getDetailsTableVisibleColumns(getSystemFieldName, hasKeyResults1),
    [getSystemFieldName, hasKeyResults1],
  );

  const _renderDetailsTableTitle = () =>
    `${pluralize(getSystemFieldName('objective'))} shared amongst ${pluralize(getSystemFieldName('roadmap'))}`;

  const _renderTitleHeaderLabel = () =>
    `${getSystemFieldName('objective')} > ${getSystemFieldName('roadmap')} > ${getDisplayLayerLabel(
      displayLayer,
      getSystemFieldName,
    )}`;

  if (not(hasData)) {
    return '';
  }

  return (
    <Wrapper>
      <ChartContent title={title} subtitle={subtitle} actionButtons={actionButtons}>
        <ChartWrapper height={minHeight}>
          <BarChart
            setRef={ref => (chartRef.current = ref)}
            data={chartData}
            plugins={[ChartDataLabels]}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  ...HIDE_LEGEND_OPTION,
                },
                datalabels: {
                  ...datalabelsConfig,
                },
              },
              animation: {
                duration: 0,
              },
              scales: {
                x: {
                  stacked: true,
                  ticks: {
                    ...tickStyles,
                  },
                  grid: {
                    ...HIDE_GRID_OPTION,
                  },
                },
                y: {
                  stacked: true,
                  ticks: {
                    ...tickSmallStyles,
                    stepSize: 1,
                  },
                },
              },
              onClick: disableClick ? null : handleClickOnChartSlice,
            }}
          />
        </ChartWrapper>
        <ChartLegend legends={chartData?.datasets} maxLegendItems={MAX_LEGEND_ITEMS} />
      </ChartContent>
      <DetailsTableLightbox
        open={detailsTableOpen}
        onClose={handleCloseDetailsTableLightbox}
        projectsSubFilters={projectsSubFilters}
        title={_renderDetailsTableTitle()}
        displayLayer={displayLayer}
        allLevels={false}
        selectedGroup1={selectedGroup1}
        selectedGroup2={selectedGroup2}
        renderTitleHeaderLabel={_renderTitleHeaderLabel}
        visibleColumns={visibleColumns}
      />
    </Wrapper>
  );
};

RoadmapByOkrStackedChart.propTypes = {
  wrapper: PropTypes.func,
};

export default RoadmapByOkrStackedChart;
