import { useMemo, useCallback } from 'react';
import { defaultTo } from 'ramda';
import { useSelector } from 'react-redux';

import { defaultColumnDefCommonProps, getCustomFieldColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { TitleCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { processGroomedColumnDefs, processColumnsGroup as processColumnsGroupUtil } from 'utils/grids/helpers';
import { applyCheckIsEditable } from 'design-system/molecules/AgGridReact-New/helpers';
import { getCustomerRequestIntegrations } from 'store/organization';
import useCustomerRequestsGridMetadata from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsGridMetadata';
import {
  useCustomerRequestsGeneralFieldsColumnDefs,
  useCustomerRequestsScoringFieldsColumnDefs,
  useCustomerRequestsPreCalculatedFieldsColumnDefs,
} from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsColumnsDefs';
import { checkIsEditable } from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsColumnsDefs/helpers';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const defaultToEmptyArray = defaultTo([]);

const TITLE_COLUMN_WIDTH = 400;
const GROUP_COLUMN_ID = 'title';

/**
 * @function useRequestsGridColumnsDefs
 *
 * Hook to collect all column defs for the requests grid
 *
 * @return {Object}
 */
const useRequestsGridColumnsDefs = ({
  searchString,
  isBulkUpdate,
  visibleColumnsIds,
  columnsState,
  rowHeight,

  updateCustomerRequestById,
  onOpenCustomerRequestInfo,
  createMultiSelectOption,
}) => {
  const { customFields } = useCustomerRequestsGridMetadata();

  const integrations = useSelector(getCustomerRequestIntegrations);

  const { canView } = usePermissions();

  const canViewRequestsDrawer = canView(PERMISSION_FEATURES.customerRequestsDrawer);

  const generalFieldsColumnDefs = useCustomerRequestsGeneralFieldsColumnDefs({
    rowHeight,

    onOpenCustomerRequestInfo: canViewRequestsDrawer ? onOpenCustomerRequestInfo : null,
    createMultiSelectOption,
    updateCustomerRequestById,
    integrations,
  });

  const scoringFieldsColumnDefs = useCustomerRequestsScoringFieldsColumnDefs();

  const preCalculatedFieldsColumnDefs = useCustomerRequestsPreCalculatedFieldsColumnDefs();

  const customFieldsColumnDefs = useMemo(
    () =>
      defaultToEmptyArray(customFields).map(cf =>
        getCustomFieldColumnDef({
          ...cf,
          fieldType: cf.field_type,
          dataEnhanced: cf.data_enhanced,
        }),
      ),
    [customFields],
  );

  const processColumnsGroup = useCallback(
    columns => processColumnsGroupUtil(columns, visibleColumnsIds, columnsState),
    [visibleColumnsIds, columnsState],
  );

  const groupColumnState = useMemo(() => columnsState && columnsState.find(c => c.colId === GROUP_COLUMN_ID), [columnsState]);

  /*
   * isBulkUpdate is included on depencies because the grid is not
   * changing the select on rows if the title column def does not change
   */
  const groupColumnDef = useMemo(
    () => ({
      field: 'title',
      headerName: 'Title',
      suppressMovable: true,
      comparator: stringComparator,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: params => ({
        innerRenderer: TitleCellRenderer,
        suppressCount: true,
        suppressDoubleClickExpand: true,
        titleSearchString: searchString,
        icon: OpenInNewIcon,
        openButtonProps: {
          hasOpenButton: canViewRequestsDrawer && onOpenCustomerRequestInfo && !!params.data,
          onClick: () => onOpenCustomerRequestInfo(params.data),
        },
      }),
      rowDrag: true,
      initialHide: false,
      pinned: 'left',
      lockPinned: true,
      width: groupColumnState?.width || TITLE_COLUMN_WIDTH,
      headerCheckboxSelection: () => isBulkUpdate,
      checkboxSelection: () => isBulkUpdate,
    }),
    [groupColumnState, searchString, onOpenCustomerRequestInfo, isBulkUpdate],
  );

  const columnDefs = useMemo(
    () =>
      processGroomedColumnDefs(
        [
          groupColumnDef,

          // general fields
          ...processColumnsGroup(generalFieldsColumnDefs),

          // custom fields
          ...processColumnsGroup(customFieldsColumnDefs),

          // scoring fields
          ...processColumnsGroup(scoringFieldsColumnDefs),

          // pre calculated fields
          ...processColumnsGroup(preCalculatedFieldsColumnDefs),
        ],
        columnsState,
      ),
    [
      processColumnsGroup,
      groupColumnDef,
      columnsState,
      generalFieldsColumnDefs,
      customFieldsColumnDefs,
      scoringFieldsColumnDefs,
      preCalculatedFieldsColumnDefs,
    ],
  );

  const defaultColDef = useMemo(
    () => ({
      ...defaultColumnDefCommonProps,
      editable: applyCheckIsEditable(checkIsEditable),
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: false,
      initialHide: true,
    }),
    [],
  );

  return {
    columnDefs,
    defaultColDef,
    groupColumnDef,
  };
};

export default useRequestsGridColumnsDefs;
