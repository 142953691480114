import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import DialogActions from 'design-system/molecules/DialogActions/index';
import NewDialog from 'design-system/organisms/NewDialog/index';

import { getCycleDeliverableById } from 'store/cycleDeliverables/selectors';
import history from 'store/utils/history';
import { CYCLE_DELIVERABLE_LEVEL } from 'constants/common';
import useCycleDeliverableLightbox from 'containers/IdeasCycleDeliverableList/hooks/useCycleDeliverableLightbox';

import useUpdateCycleDeliverable from '../hooks/useUpdateCycleDeliverable';

import CycleDeliverableLightboxHeader from './CycleDeliverableLightboxHeader';
import CycleSettingsContent from './CycleSettingsContent';
import DeliverableSettingsContent from './DeliverableSettingsContent';
import CycleDeliverableDetails from './CycleDeliverableDetails';

const OVERRIDE_CANCEL_LABEL = 'Close';
const GO_TO_SETTINGS_PAGE_LABEL = 'Go to the settings page';
const PDLC_SETTINGS_PAGE_URL = '/settings/pdlc';

const cancelButtonProps = {
  color: 'primary',
};

const gotoSettingsButtonProps = {
  color: 'primary',
};

const isDeliverable = cycleDeliverable => cycleDeliverable?.level === CYCLE_DELIVERABLE_LEVEL.deliverable;

const CycleDeliverableLightbox = props => {
  const { isOpen, onClose, selectedCycleDeliverableId } = props;
  const [showSettingsContent, setShowSettingsContent] = useState(false);

  const cycleDeliverable = useSelector(state => getCycleDeliverableById(state, selectedCycleDeliverableId));

  const { onChangeTitle, canUpdateCycleDeliverable } = useUpdateCycleDeliverable(cycleDeliverable);
  const { openCycleDeliverableLightbox } = useCycleDeliverableLightbox();

  const handleOnClose = useCallback(() => {
    setShowSettingsContent(false);
    onClose();
  }, [onClose, setShowSettingsContent]);

  const handleOnOpen = useCallback(
    cycleDeliverableId => {
      setShowSettingsContent(false);
      openCycleDeliverableLightbox(cycleDeliverableId);
    },
    [setShowSettingsContent, openCycleDeliverableLightbox],
  );

  const lightboxContent = useMemo(() => {
    if (!selectedCycleDeliverableId) {
      return null;
    }

    if (!showSettingsContent) {
      return (
        <CycleDeliverableDetails
          selectedCycleDeliverableId={selectedCycleDeliverableId}
          cycleDeliverable={cycleDeliverable}
          onClose={handleOnClose}
        />
      );
    }

    if (isDeliverable(cycleDeliverable)) {
      return <DeliverableSettingsContent cycleDeliverable={cycleDeliverable} />;
    }

    return <CycleSettingsContent filterByCycle={cycleDeliverable} handleOnOpenDeliverable={handleOnOpen} />;
  }, [cycleDeliverable, showSettingsContent, selectedCycleDeliverableId, handleOnClose, handleOnOpen]);

  useEffect(() => {
    if (isOpen && !cycleDeliverable) {
      onClose();
    }
  }, [cycleDeliverable, isOpen, onClose]);

  const handleGoToSettingsPage = useCallback(() => {
    history.push(PDLC_SETTINGS_PAGE_URL);
  }, []);

  return (
    <NewDialog
      header={
        <CycleDeliverableLightboxHeader
          cycleDeliverable={cycleDeliverable}
          onChangeTitle={onChangeTitle}
          onClose={onClose}
          showSettingsContent={showSettingsContent}
          setShowSettingsContent={setShowSettingsContent}
          canUpdateCycleDeliverable={canUpdateCycleDeliverable}
        />
      }
      actions={
        <DialogActions
          onCancel={handleOnClose}
          cancelLabel={OVERRIDE_CANCEL_LABEL}
          cancelProps={cancelButtonProps}
          deleteProps={gotoSettingsButtonProps}
          deleteLabel={GO_TO_SETTINGS_PAGE_LABEL}
          onDelete={handleGoToSettingsPage}
        />
      }
      onClose={handleOnClose}
      open={isOpen}
      width="750px"
      withActionsShadow
      closeButton
    >
      <Container>{lightboxContent}</Container>
    </NewDialog>
  );
};

const Container = styled.div`
  width: 100%;
`;

export default CycleDeliverableLightbox;
