import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { allPass, both, isNil, not, path, pipe, prop, propEq } from 'ramda';
import { ADMIN_USER, EDITOR_USER, LEADER_USER, MANAGER_USER, READ_ONLY_USER } from '@dragonboat/permissions';

import useSystemFields from 'hooks/useSystemFields';
import { closeRoadmapLightbox, updateRoadmapUserRoles } from 'store/roadmaps';
import { getRoadmapLightboxData } from 'store/roadmaps/selectors';

import { toast } from 'react-toastify';

import { UPDATE_PROJECT_ERROR } from 'constants/projectLightbox';

const isOfValidationTypeError = propEq('error_code', 'VALIDATION_ERROR');
const hasErrorDescription = pipe(path(['_data', 'description']), isNil, not);
const isValidationErrorWithDescription = both(isOfValidationTypeError, hasErrorDescription);
const shouldShowDefaultErrorMessage = allPass([pipe(prop('message'), isNil), pipe(propEq('errorAlreadyDisplayed', true), not)]);

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.roadmaps.loadingData);
    const isOpen = useSelector(state => state.roadmaps.lightboxOpen);
    const roadmapId = useSelector(state => state.roadmaps.lightboxRoadmapId);
    const userRoles = useSelector(getRoadmapLightboxData);
    const [getSystemFieldName] = useSystemFields();

    const defaultFormData = {
      [ADMIN_USER]: [],
      [MANAGER_USER]: [],
      [EDITOR_USER]: [],
      [LEADER_USER]: [],
      [READ_ONLY_USER]: [],
    };

    const _getDefaultData = () => ({
      ...defaultFormData,
    });

    const [formData, setFormData] = useState(_getDefaultData());

    const _onClose = () => {
      dispatch(closeRoadmapLightbox());

      setFormData(_getDefaultData());
    };

    useEffect(() => {
      setFormData({
        [ADMIN_USER]: userRoles.filter(r => r.role_id === ADMIN_USER),
        [MANAGER_USER]: userRoles.filter(r => r.role_id === MANAGER_USER),
        [EDITOR_USER]: userRoles.filter(r => r.role_id === EDITOR_USER),
        [READ_ONLY_USER]: userRoles.filter(r => r.role_id === READ_ONLY_USER),
        [LEADER_USER]: userRoles.filter(r => r.role_id === LEADER_USER),
      });
    }, [userRoles, roadmapId]);

    const _onSave = (shouldClose = true) => {
      if (shouldClose) _onClose();

      const _onError = err => {
        if (err.response && err.response.status !== 401) {
          const { data } = err.response;

          if (isValidationErrorWithDescription(data)) {
            toast(data._data.description);
          } else if (shouldShowDefaultErrorMessage(data)) {
            toast(UPDATE_PROJECT_ERROR);
          }
        }
      };

      return dispatch(updateRoadmapUserRoles(roadmapId, formData)).catch(_onError);
    };

    const _updateFieldData = field => {
      return value => {
        // A user should only have one role
        const userIDs = value.map(u => u.user_id);
        const newFormData = {
          [field]: value,
        };

        // Iterate through old data and remove duplicates
        Object.keys(formData)
          .filter(k => parseInt(k, 10) !== field)
          .forEach(key => {
            const keyRoles = formData[key].filter(r => !userIDs.includes(r.user_id));

            userIDs.push(...keyRoles.map(r => r.user_id));
            newFormData[key] = keyRoles;
          });

        setFormData(newFormData);
      };
    };

    return (
      <Component
        {...props}
        loading={loading}
        isOpen={isOpen}
        onClose={_onSave}
        getSystemFieldName={getSystemFieldName}
        formData={formData}
        updateFieldData={_updateFieldData}
      />
    );
  };
};

export default componentHOC;
