import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/HelpOutline';
import SavingLoader from 'design-system/atoms/SavingLoader/index';
import FluidItem from 'design-system/atoms/FluidItem/index';
import FluidFieldLabel from 'design-system/molecules/FluidFieldLabel/index';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';


import { NONE_PLACEHOLDER } from 'constants/common';
import useStateRef from 'design-system/useStateRef';
import useDeepEffect from 'design-system/useDeepEffect';
import { getFluidFieldValidation } from 'design-system/utils';

const MetadataFluidField = props => {
  const anchorEl = useRef(null);
  const [originalValue, setOriginalValue] = useState(props.value);
  const [editValue, setEditValue] = useState(props.value);
  const [isEditing, setIsEditing] = useStateRef(props.alwaysEditing);
  const [isSaving, setIsSaving] = useState(false);

  const { validation, name, placeholder, readingPlaceholder } = props;
  const { invalid, required } = getFluidFieldValidation(name, editValue, validation);

  useDeepEffect(() => {
    if ((props.disableEdit || !isEditing) && originalValue !== props.value) {
      setEditValue(props.value);
      setOriginalValue(props.value);
    }
  }, [props.value]);

  const cancel = () => {
    setIsEditing(props.alwaysEditing);
  };

  const save = (value, cancelEdit = true) => {
    const valueToCheck = value === undefined ? editValue : value;

    if (props.onChange) {
      const response = props.onChange(valueToCheck);

      if (response instanceof Promise) {
        setIsSaving(true);
        response.finally(() => setIsSaving(false));
      }
    }

    setIsEditing(false);
    if (cancelEdit && !props.alwaysEditing) setIsEditing(false);
    setEditValue(valueToCheck);
  };

  const _onChange = value => {
    setEditValue(value.title);
    if (props.onEditingValue) props.onEditingValue(value.title);
  };

  const _onFocus = e => {
    const { relatedTarget, currentTarget, target } = e;

    if (currentTarget !== target || currentTarget.contains(relatedTarget)) {
      return;
    }

    const divs = currentTarget.querySelectorAll('div');

    divs.forEach(d => d.click());

    const input = currentTarget.querySelector('input');

    if (input) input.focus();

    setIsEditing(true);
    e.preventDefault();
    e.stopPropagation();
  };

  const placeholderToRender = isEditing && !props.disableEdit ? placeholder : readingPlaceholder;
  // eslint-disable-next-line no-nested-ternary
  const innerValue = (
    <>
      {props.renderer &&
        (props.forceInputRenderingOnEdit || !isEditing) &&
        props.renderer(props.value, {
          placeholder: placeholderToRender,
        })}
      {!props.disableEdit &&
        isEditing &&
        props.editorRenderer({
          anchorEl: anchorEl?.current || null,
          label: props.label,
          value: editValue,
          onChange: _onChange,
          isEditing,
          save,
          cancel,
          disableEdit: props.disableEdit,
          placeholder: placeholderToRender,
        })}
    </>
  );

  const label = props.label && (
    <FluidFieldLabel
      disabled={props.disableEdit}
      format={props.format}
      label={props.label}
      onLabelClick={props.onLabelClick}
      labelTooltip={props.labelTooltip}
      required={required}
      invalid={invalid}
    />
  );

  // eslint-disable-next-line no-nested-ternary
  const valueOrPlaceholder = (
    <ValueOrPlaceholder
      ref={anchorEl}
      {...(props.disableEdit ? {} : props.editingTriggers(() => !isEditing && setIsEditing(true)))}
      hover={!props.disableEdit && !isEditing}
      invalid={invalid}
    >
      {!innerValue ? (
        ''
      ) : isString(innerValue) ? (
        <Typography variant="body1" style={{ lineHeight: props.format !== 'column' && '8px' }}>
          {innerValue}
        </Typography>
      ) : (
        innerValue
      )}
    </ValueOrPlaceholder>
  );

  const helpIcon = (
    <StyledHelpIcon
      className="helpIcon"
      title={props.helpTitle ? props.helpTitle : 'Help'}
      href={props.helpLink ? props.helpLink : null}
      target="_blank"
      rel="noopener noreferrer"
    >
      <HelpIcon disableShrink fontSize="small" thickness={4} />
    </StyledHelpIcon>
  );

  const fieldNameWithHelpLinkWrapper = (
    <>
      <Grid style={{ ...(props?.labelStyle || {}) }} item xs={12} md={3}>
        {label}
      </Grid>
      <Grid item xs={12} md={1}>
        {helpIcon}
      </Grid>
    </>
  );

  const fieldNameWrapper = (
    <>
      {props.helpLinkNextToFieldName ? (
        fieldNameWithHelpLinkWrapper
      ) : (
        <Grid style={{ ...(props?.labelStyle || {}) }} item xs={12} md={4}>
          {label}
        </Grid>
      )}
    </>
  );

  const components =
    props.format === 'column' ? (
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Grid>{label}</Grid>
        </Grid>
        <Grid item xs={12} onFocus={_onFocus}>
          {valueOrPlaceholder}
        </Grid>
      </Grid>
    ) : (
      <Grid container xs={12} direction="row" alignItems={props?.alignItems || 'flex-start'}>
        {fieldNameWrapper}
        <Grid item xs={12} md={8} onFocus={_onFocus}>
          {valueOrPlaceholder}
        </Grid>
      </Grid>
    );

  return (
    <StyledFluidItem
      style={props.style || {}}
      width={props.width}
      isEditing={isEditing}
      component={props.component}
      tabIndex={isEditing ? -1 : 0}
    >
      {components}
      {isSaving && <SavingLoader />}
      {props.extraButton ? (
        <StyledExtraButton id={props.extraButtonId} className="extraButton">
          {props.extraButton}
        </StyledExtraButton>
      ) : null}
      {!props.helpLinkNextToFieldName && props.helpLink && helpIcon}
    </StyledFluidItem>
  );
};

MetadataFluidField.propTypes = {
  key: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disableEdit: PropTypes.bool,
  editingTriggers: PropTypes.func,
  renderer: PropTypes.func,
  ignoreBlurOn: PropTypes.func,
  format: PropTypes.oneOf(['column', 'row']),
  alwaysEditing: PropTypes.bool,
  style: PropTypes.object,
  readingPlaceholder: PropTypes.string,
};
MetadataFluidField.defaultProps = {
  disableEdit: false,
  format: 'column',
  ignoreBlurOn: () => {},
  editingTriggers: edit => ({ onClick: edit }),
  alwaysEditing: false,
  readingPlaceholder: NONE_PLACEHOLDER,
};

export default MetadataFluidField;

export const StyledFluidItem = styled(FluidItem)`
  &&&&:hover .helpIcon,
  &&&&:hover .extraButton {
    visibility: visible;
  }
`;

const ValueOrPlaceholder = styled.div`
  transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
  flex-grow: 1;
  min-height: 32px;
  border-color: transparent;
  border-radius: 3px;
  border-style: solid;
  padding: 0 4px 0 8px;

  > * {
    width: 100%;
  }

  ${({ invalid }) =>
    invalid &&
    `
    border-bottom: 1px solid red;
  `}

  ${({ hover }) =>
    hover &&
    `
    &:hover {
      background-color: rgb(235, 236, 240);
      & input {
        cursor: pointer;
      }
    }
  `}
`;

const StyledHelpIcon = styled(ButtonIcon)`
  &&&& {
    visibility: hidden;
    width: fit-content;
    padding: 0 2px;
    cursor: pointer;
  }
`;

const StyledExtraButton = styled.div`
  &&&& {
    visibility: hidden;
    position: absolute;
    right: 30px;
    top: calc(50% - 9px);
    width: fit-content;
    padding: 0;
    cursor: pointer;
  }
`;
