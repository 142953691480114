import { UPDATE_ROADMAP_STATE } from './types';

const initialState = {};

export default function roadmapReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ROADMAP_STATE:
      const { payload } = action;

      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
