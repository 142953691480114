import React from 'react';
import styled from 'styled-components';

import Loading from 'design-system/atoms/Loading/Loading';
import { spacing } from 'design-system/theme';
import { getRowId } from 'design-system/molecules/AgGridReact-New/helpers';

import { BaseGrid } from 'containers/Grids';

import { BASE_ROW_HEIGHT } from 'constants/grid';

import useLinkedPortfolioItemsGrid from '../hooks/useLinkedPortfolioItemsGrid';

const Grid = ({ items, isLoadingItems, isLoadingGrid, linkProjectToEntity, unlinkProjectFromEntity, removeUnsavedRows }) => {
  const { columnDefs, defaultColumnDef, gridContext, commonGridProps, commonGridEvents, gridStyles, tableHeight } =
    useLinkedPortfolioItemsGrid({
      items,
      linkProjectToEntity,
      unlinkProjectFromEntity,
      removeUnsavedRows,
    });

  return isLoadingItems ? (
    <StyledLoading />
  ) : (
    <GridWrapper>
      {isLoadingGrid ? (
        <GridLoadingWrapper>
          <Loading />
        </GridLoadingWrapper>
      ) : null}
      <BaseGrid
        {...commonGridProps}
        {...commonGridEvents}
        getRowId={getRowId}
        rowData={items}
        columnDefs={columnDefs}
        defaultColDef={defaultColumnDef}
        headerHeight={BASE_ROW_HEIGHT}
        context={gridContext}
        height={tableHeight}
        cssStyles={gridStyles}
      />
    </GridWrapper>
  );
};

export default Grid;

const StyledLoading = styled(Loading)`
  text-align: center;
  margin-top: ${spacing(7)}px;
`;

const GridWrapper = styled.div`
  position: relative;
`;

const GridLoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  padding-top: ${spacing(8)}px;
`;
