import { calculateGridWidth } from 'components/ganttCommon';
import renderBusinessValueLine from './helpers/renderBusinessValueLine';

const setGanttTimelineBusinessValue =
  ({ selectedTimelineInterval, selectedRounding }) =>
  ganttInstance => {
    const onBeforeParse = () => {
      if (ganttInstance.$businessValuesStore) {
        ganttInstance.$businessValuesStore.parse([
          {
            id: Math.floor(Math.random() * 1000000),
          },
        ]);
      }
    };

    let timeout;

    ganttInstance.getDatastore('task').attachEvent('onStoreUpdated', (id, item, mode) => {
      const later = () => {
        if (ganttInstance.$businessValuesStore) ganttInstance.$businessValuesStore.refresh();
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, 200);
    });
    onBeforeParse();
    ganttInstance.attachEvent('onBeforeParse', onBeforeParse);

    const bussinessValueLayers = [
      (businessValue, timeline) =>
        renderBusinessValueLine({
          businessValue,
          timeline,
          gantt: ganttInstance,
          selectedTimelineInterval,
          selectedRounding,
        }),
    ];

    ganttInstance.config.layout.rows.unshift({
      css: 'no-scale',
      cols: [
        {
          view: 'grid',
          id: 'businessValueGrid',
          width: calculateGridWidth(ganttInstance),
          bind: 'businessValue',
          config: {
            columns: [
              {
                name: 'name',
                align: 'center',
                label: 'Benefit',
                width: 574,
                template: () => 'Quarterly business benefits',
              },
            ],
          },
          scrollY: 'businessValueVScroll',
        },
        { resizer: true, width: 1 },
        {
          view: 'timeline',
          id: 'businessValueTimeline',
          scrollX: 'scrollHor',
          bind: 'businessValue',
          bindLinks: null,
          layers: bussinessValueLayers,
          scrollY: 'businessValueVScroll',
        },
        { view: 'scrollbar', scroll: 'y', id: 'businessValueVScroll' },
      ],
      height: 30,
      gravity: 2,
    });

    ganttInstance.$businessValuesStore = ganttInstance.createDatastore({
      name: 'businessValue',
    });
  };

export default setGanttTimelineBusinessValue;
