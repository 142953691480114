import { addListener } from '@reduxjs/toolkit';
import { includes } from 'ramda';

import { UPDATE_OBJECTIVE_FROM_DRAWER_FULFILLED, UPDATE_KEY_RESULT_FROM_DRAWER_FULFILLED } from 'store/objectives';
import { refreshMissionControlOkrs, refreshMissionControlMetrics } from './thunks';
import { selectMetricById, selectMissionControlHomeOkrs } from 'features/MissionControlHome/store/selectors';
import { CREATE_METRIC_VALUE_FULFILLED, UPDATE_METRIC_VALUE_FULFILLED, UPDATE_METRIC_BY_ID_FULFILLED } from 'store/metrics/types';

const OKR_CHANGE_FIELDS_TO_VERIFY = ['title', 'owner_id', 'objective_id', 'parent_id'];

const checkIfAnyFieldChanged = (storeEntity, updatedEntity, fields = []) =>
  fields.some(fieldKey => storeEntity?.[fieldKey] !== updatedEntity?.[fieldKey]);

const flattenOkrs = (okrs = []) =>
  okrs.reduce((acc, okr) => {
    const flattenKeyResults = okr?.keyResults?.length ? flattenOkrs(okr?.keyResults) : [];

    return [...acc, okr, ...flattenKeyResults];
  }, []);

const findOkrById = (okrs = [], okrId) => {
  const allOkrs = flattenOkrs(okrs);

  return allOkrs.find(okr => okr?.id === okrId);
};

/**
 * Subscribes to some actions to refresh okr data on mission control my okrs
 *
 * @returns {Object} the addListener action creator
 */
export const subscribeToRefreshMissionControlOrksChanged = () => {
  return addListener({
    matcher: action => includes(action.type, [UPDATE_OBJECTIVE_FROM_DRAWER_FULFILLED, UPDATE_KEY_RESULT_FROM_DRAWER_FULFILLED]),
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners();

      const { dispatch, getState } = listenerApi;

      const updatedOkr = action?.payload;
      const storeOkrs = selectMissionControlHomeOkrs(getState());
      const storeOkr = findOkrById(storeOkrs, action?.payload?.id);

      const hasChangesThatForcesRefresh = storeOkr && checkIfAnyFieldChanged(storeOkr, updatedOkr, OKR_CHANGE_FIELDS_TO_VERIFY);

      if (hasChangesThatForcesRefresh) {
        dispatch(refreshMissionControlOkrs());
      }
    },
  });
};

/**
 * Subscribes to some actions to refresh okr data on mission control my okrs
 *
 * @returns {Object} the addListener action creator
 */
export const subscribeToRefreshMissionControlMetricsChanged = () => {
  return addListener({
    matcher: action =>
      includes(action.type, [CREATE_METRIC_VALUE_FULFILLED, UPDATE_METRIC_VALUE_FULFILLED, UPDATE_METRIC_BY_ID_FULFILLED]),
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners();

      const { dispatch, getState } = listenerApi;

      const metricId = action?.payload?.metric_id || action?.payload?.id;
      const storeMetric = selectMetricById(getState(), metricId);

      if (storeMetric) {
        dispatch(refreshMissionControlMetrics());
      }
    },
  });
};
