import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getOrganization,
  getOrgHasMetadataRoadmaps,
  updateSystemFieldsName as updateSystemFieldsNameAction,
} from 'store/organization';
import { PDLC } from 'store/grids';

import { useSettingsActions, useSettingsGridSelection } from 'containers/Grids/SettingsGrid/hooks';
import usePDLC from './hooks/usePDLC';
import usePDLCGridColumns from './hooks/usePDLCGridColumns';
import usePDLCGridProps from './hooks/usePDLCGridProps';
import usePDLCGridEventHandlers from './hooks/usePDLCGridEventHandlers';

import { BaseGrid } from 'containers/Grids';
import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SettingsDialogs from 'components/SettingsDialogs';
import { CYCLE_DELIVERABLE_LEVEL } from 'constants/common';
import { CYCLE_KEY, DELIVERABLE_KEY } from 'constants/pdlc';
import useUpsellPage from 'hooks/useUpsellPage';
import { IDEAS_PDLC_PAGE } from 'constants/filters';
import { PERMISSION_RESOURCES, SYSTEM_FIELD } from '@dragonboat/permissions';
import usePermissions from 'hooks/permissions/usePermissions';

// todo: next stories will create this actions
const createCycleDeliverableRoadmap = () => {
  console.log('to be implemented: createCycleDeliverableRoadmap ');
};
const deleteCycleDeliverableRoadmap = () => {
  console.log('to be implemented: deleteCycleDeliverableRoadmap ');
};
const bulkDeleteCycleDeliverableRoadmaps = () => {
  console.log('to be implemented: bulkDeleteCycleDeliverableRoadmaps ');
};

const getSettingsKeyBasedOnLevel = cycleDeliverable =>
  cycleDeliverable?.level === CYCLE_DELIVERABLE_LEVEL.cycle ? CYCLE_KEY : DELIVERABLE_KEY;
const getPermissionResourceByLevel = cycleDeliverable =>
  cycleDeliverable?.level === CYCLE_DELIVERABLE_LEVEL.cycle ? PERMISSION_RESOURCES.cycle : PERMISSION_RESOURCES.deliverable;

const PDCLSettings = ({
  showActionBar = true,
  forceVisibleFields,
  filterByCycleDeliverableId,
  customHeight,
  cssStyles,
  onTitleClick,
}) => {
  const dispatch = useDispatch();

  const UpsellPdlc = useUpsellPage(IDEAS_PDLC_PAGE);

  const organization = useSelector(getOrganization);
  const hideMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);

  const updateSystemFieldsName = useCallback(
    fieldRename => dispatch(updateSystemFieldsNameAction(fieldRename)),
    [updateSystemFieldsNameAction],
  );

  const {
    cycleDeliverables,
    childrenSettingKeys,
    createCycleDeliverable,
    addCycleDeliverableWithoutSave,
    removeUnsavedCycleDeliverable,
    deleteCycleDeliverable: deleteCycleDeliverableById,
    updateCycleDeliverable,
    switchCycleDeliverableRowOrder,
  } = usePDLC({ filterByCycleDeliverableId });
  const { selectionMode, selectedItems, enableSelectionMode, disableSelectionMode, updateSelectedOnAgGridInit } =
    useSettingsGridSelection(PDLC, {
      actions: {
        remove: removeUnsavedCycleDeliverable,
      },
    });

  const { setItemToDelete, itemToDelete, deleteItem, deleteProgress } = useSettingsActions({
    settingType: PDLC,
    selectedItems,
    disableSelectionMode,
    deleteById: deleteCycleDeliverableById,
  });

  const { canUpdate, canCreate, canView } = usePermissions();
  const allowActions = canUpdate(PERMISSION_RESOURCES.cycle) || canCreate(PERMISSION_RESOURCES.cycle);

  const permissions = {
    allowActions,
    allowCreateDeliverable: canCreate(PERMISSION_RESOURCES.deliverable),
    canUpdate: useCallback(data => canUpdate(getPermissionResourceByLevel(data), { data }), [canUpdate]),
    canDragRows: allowActions,
    headerCheckboxSelection: allowActions,
    rename: {
      canUpdate: showActionBar && canUpdate(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.pdlc }),
      canView: showActionBar && canView(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.pdlc }),
    },
  };

  const pdlcGridColumnDefs = usePDLCGridColumns({
    cycleDeliverables,
    allowActions: permissions.allowActions,
    allowCreateDeliverable: permissions.allowCreateDeliverable,
    canUpdate: permissions.canUpdate,
    canDragRows: permissions.canDragRows,
    headerCheckboxSelection: permissions.headerCheckboxSelection,
    selectionMode,
    setItemToDelete,
    addCycleDeliverableWithoutSave,
    roadmapColumn: {
      createCycleDeliverableRoadmap,
      deleteCycleDeliverableRoadmap,
      bulkDeleteCycleDeliverableRoadmaps,
      hideMetadataRoadmaps,
    },
    forceVisibleFields,
    onTitleClick,
  });

  const pdlcGridProps = usePDLCGridProps({
    createCycleDeliverable,
    updateCycleDeliverable,
  });

  const pdlcGridEventHandlers = usePDLCGridEventHandlers({
    cycleDeliverables,
    update: updateCycleDeliverable,
    createCycleDeliverable,
    removeUnsavedCycleDeliverable,
    switchCycleDeliverableRowOrder,
  });

  const settingToBeDeletedKey = getSettingsKeyBasedOnLevel(itemToDelete);

  if (UpsellPdlc) {
    return <UpsellPdlc />;
  }

  return (
    <div>
      {showActionBar && permissions.allowActions && (
        <SettingsActionsToolbar
          settingKey={PDLC}
          childrenSettingKeys={childrenSettingKeys}
          settingType={PDLC}
          systemFields={organization.system_fields_name}
          updateSystemFieldsName={updateSystemFieldsName}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          onEnableSelection={enableSelectionMode}
          onCancelSelection={disableSelectionMode}
          add={addCycleDeliverableWithoutSave}
          renamePermissions={permissions.rename}
        />
      )}
      <BaseGrid
        rowData={cycleDeliverables}
        {...pdlcGridProps}
        {...pdlcGridColumnDefs}
        {...pdlcGridEventHandlers}
        onGridReady={updateSelectedOnAgGridInit}
        height={customHeight ?? pdlcGridProps.height}
        cssStyles={cssStyles}
      />
      <SettingsDialogs
        settingKey={settingToBeDeletedKey}
        settingType={settingToBeDeletedKey}
        deleteProgress={deleteProgress}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        deleteItem={deleteItem}
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
      />
    </div>
  );
};

export default PDCLSettings;
