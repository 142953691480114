import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getTimeframes, getTimeframesLevelCorp } from 'store/timeframes/selectors';
import useDeepMemo from 'hooks/useDeepMemo';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import getIdsFromDndList from 'utils/getIdsFromDndList';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import { mergeTimeframes } from '../utils';

const UNDEFINED_OPTION = {
  id: 'undefined',
  title: 'Undefined',
};

const useTimeframesForAllocationReport = ({ updateLsState, availableTimeframes }) => {
  const timeframes = useSelector(getTimeframes);
  const timeframesLevelCorp = useSelector(getTimeframesLevelCorp);

  const { hasMultiLevelPortfolioMetadata } = useOrganizationsAccessControl();

  const { canView } = usePermissions();

  const hasTimeframeCorpLevel = canView(PERMISSION_RESOURCES.timeframeCorp);

  const useDndList = useMemo(
    () => hasTimeframeCorpLevel || hasMultiLevelPortfolioMetadata,
    [hasTimeframeCorpLevel, hasMultiLevelPortfolioMetadata],
  );

  const allTimeframes = useMemo(
    () => (hasTimeframeCorpLevel ? mergeTimeframes(timeframesLevelCorp, timeframes) : timeframes),
    [hasTimeframeCorpLevel, timeframesLevelCorp, timeframes],
  );

  const filteredTimeframes = useDeepMemo(() => {
    const filtered = allTimeframes.filter(timeframe => availableTimeframes.includes(timeframe.id));

    const includeUndefinedOption = availableTimeframes.includes(null);

    return [...(includeUndefinedOption ? [UNDEFINED_OPTION] : []), ...filtered];
  }, [allTimeframes, availableTimeframes]);

  const onTimeframesChange = timeframes => {
    const selectedTimeframes = useDndList ? getIdsFromDndList(timeframes) : timeframes;

    updateLsState({ selectedTimeframes });
  };

  useEffect(() => {
    updateLsState({ selectedTimeframes: filteredTimeframes.map(t => t.id?.toString()) });
  }, [filteredTimeframes]);

  return {
    timeframes: filteredTimeframes || allTimeframes,
    onTimeframesChange,
    useDndList,
  };
};

export default useTimeframesForAllocationReport;
