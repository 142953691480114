import { defaultTo, isEmpty, not, pipe, omit } from 'ramda';

import { getEstimateColIdByTeamSkill } from 'utils/estimates';

import { EstimateCellRenderer } from '../renderers';
import { EstimateInputWithLinkEditor } from '../editors';
import getEstimateCellCssStyle from './getEstimateCellCssStyle';
import getEstimateCellValueFormatter from './getEstimateCellValueFormatter';
import checkIsEstimateColEditable from './checkIsEstimateColEditable';
import {
  applyCheckIsCreatingOrViewingScenario,
  makeCellClassForCreatingOrViewingScenario,
  makeCellStyleForCreatingOrViewingScenario,
} from 'containers/IdeasList/IdeasList/New/hooks/useIdeasGridColumnsDefinitions/helpers';
import clickableHeaderWithBackgroundHOC from 'design-system/molecules/AgGridReact-New/helpers/clickableHeaderWithBackgroundHOC';

import { ESTIMATE_BY_POINTS } from 'constants/teams';
import { numberComparator } from 'design-system/utils/agGridComparators';

const defaultAsEmptyArray = defaultTo([]);
const defaultAsEmptyObject = defaultTo({});
const isNotNaN = pipe(Number.isNaN, not);

const isTeamEstimatingByPoints = ({ estimate_by: estimateBy }) => estimateBy === ESTIMATE_BY_POINTS;

const getEstimateColumnDef = (team, skill, resourcesCount, { openEstimatesLightbox, canWorkWithEstimationByPoints }) => {
  const isEstimatingByPoints = canWorkWithEstimationByPoints && isTeamEstimatingByPoints(team);
  const colId = getEstimateColIdByTeamSkill(team.id, skill.id);
  const estimateCellCssStyleGetter = getEstimateCellCssStyle(resourcesCount, isEstimatingByPoints);

  return {
    headerName: `${skill.title} (${resourcesCount})`,
    field: colId,
    isEstimateColumn: true,
    width: 80,
    editable: applyCheckIsCreatingOrViewingScenario(checkIsEstimateColEditable),
    enableValue: true,
    enableColResize: true,
    menuTabs: [],
    cellEditor: EstimateInputWithLinkEditor,
    cellRenderer: EstimateCellRenderer,
    headerClass: 'ag-header-cell-small-padding',
    headerComponent: clickableHeaderWithBackgroundHOC(skill.color),
    cellClass: makeCellClassForCreatingOrViewingScenario(),
    cellStyle: params => makeCellStyleForCreatingOrViewingScenario(estimateCellCssStyleGetter(params))(params),
    valueFormatter: getEstimateCellValueFormatter(resourcesCount, isEstimatingByPoints),
    valueSetter: params => {
      /*
       * IMPORTANT:
       * This valueSetter is to handle with values on release capacity allocation
       * because we have aggValue on groups and the oldValue that comes from aggrid
       * params is the agg value, for that reason should look into data row to get
       * the old Value
       *
       * Otherwise the newValue that comes from params has the most recent value
       */
      const oldValue = params.data[colId];

      if (String(oldValue?.value) !== String(params.newValue?.value)) {
        params.data[colId] = params.newValue;

        // get grid to refresh the cell
        return true;
      }

      // no change, so no refresh needed
      return false;
    },
    cellEditorParams: {
      isEstimatingByPoints,
      onClickFactory: (data, value) => {
        const { skillId, teamId } = defaultAsEmptyObject(value);
        const { estimates } = defaultAsEmptyObject(data);
        const estimate = defaultAsEmptyArray(estimates).find(est => est.team.id === teamId && est.skill.id === skillId);

        if (estimate) {
          return () => openEstimatesLightbox(estimate);
        }
      },
      isValid: v => {
        if (isEmpty(v)) {
          return true;
        }

        return isNotNaN(parseInt(v));
      },
    },
    parent: {
      headerName: team.title,
      field: `estimate_team_${team.id}`,
    },
    valueGetter: params => {
      /*
       * We want to always render the estimates value, but we need to store conditionally each one so the group sum
       * respects the above/below the line toggle
       *
       * value has other properties besides the value (alert, hideInput, showLink, showLinkOnMount, skillId, teamId)
       * and we must keep them and only map the value property into toString  + conditionalValue
       */
      const {
        data,
        colDef: { field },
      } = params;

      if (params.data.group) {
        return data[field];
      }

      if (!params.node.group) {
        const workingValueKey = isEstimatingByPoints ? 'estimatePoints' : 'value';

        return {
          conditionalValue: data[field]?.[workingValueKey],
          ...omit([workingValueKey], data[field]),
          toString: () => data[field]?.[workingValueKey],
        };
      }
    },
    comparator: numberComparator,
  };
};

export default getEstimateColumnDef;
