import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import MultiFilterClearRow from 'design-system/atoms/MultiFilterClearRow/index';
import MultiFilterSearchRow from 'design-system/atoms/MultiFilterSearchRow/index';
import DndList from 'design-system/organisms/DndList/index';

import { SearchRow } from 'design-system/atoms/MultiFilterSearchRow/styled';
import { spacing } from 'design-system/theme';
import { searchMenuItems as baseSearchMenuItems } from 'design-system/utils';

const DEFAULT_WIDTH = 400;

const SearchableDndList = ({
  items = [],
  selectedItems = {},
  checkable = false,
  avoidVirtualization = false,
  handleClear,
  onSelectItem,
  onClickItem,
  renderCustomItem,
  renderCustomEmptyOptions,
  renderCustomFooter,
  renderCustomHeaderOptions,
  itemRightActions,
  showActionsOnHover,
  hideSearchIfNoItems = false,
  width = DEFAULT_WIDTH,
  searchPlaceholder,
  searchMenuItems = baseSearchMenuItems,
}) => {
  const [search, setSearch] = useState('');

  const [expandAllCallback, setExpandAllCallback] = useState();
  const [collapseAllCallback, setCollapseAllCallback] = useState();

  const shouldHideSearch = hideSearchIfNoItems && isEmpty(items);

  const handleSearchChange = e => {
    const searchString = e?.target?.value;

    if (e.target.value) {
      expandAllCallback && expandAllCallback();
    } else {
      collapseAllCallback && collapseAllCallback();
    }

    setSearch(searchString);
  };

  const registerExpandAllCallback = callback => setExpandAllCallback(() => callback);
  const registerCollapseAllCallback = callback => setCollapseAllCallback(() => callback);

  const _renderHeader = () => {
    return (
      <HeaderContainer>
        {renderCustomHeaderOptions && renderCustomHeaderOptions()}
        {shouldHideSearch ? null : (
          <MultiFilterSearchRow handleChange={handleSearchChange} search={search} placeholder={searchPlaceholder} />
        )}
      </HeaderContainer>
    );
  };

  const filteredItems = useMemo(() => {
    return searchMenuItems(search, items);
  }, [items, search]);

  const handleClearItems = item => {
    if (handleClear) handleClear(item);
  };

  const _renderClear = () => {
    return <MultiFilterClearRow handleClear={handleClearItems} />;
  };

  return (
    <DndList
      items={filteredItems}
      checkable={checkable}
      avoidVirtualization={avoidVirtualization}
      selectedItems={selectedItems}
      onSelectItem={onSelectItem}
      onClickItem={onClickItem}
      renderClear={renderCustomFooter || _renderClear}
      renderSearch={_renderHeader}
      renderCustomItem={renderCustomItem}
      renderCustomEmptyOptions={renderCustomEmptyOptions}
      itemRightActions={itemRightActions}
      showActionsOnHover={showActionsOnHover}
      width={width}
      registerExpandAllCallback={registerExpandAllCallback}
      registerCollapseAllCallback={registerCollapseAllCallback}
      showTooltip
    />
  );
};

export default SearchableDndList;

const HeaderContainer = styled.div`
  box-shadow: 0px 2px 12px 0px rgba(204, 204, 204, 0.3);

  ${SearchRow} {
    border: none;
    padding: 0 ${spacing(1.875)}px;

    input {
      font-size: ${({ theme }) => theme.typography.fontSize}px;

      &::placeholder {
        color: ${({ theme }) => theme.palette.text.quickSilver};
      }
    }
  }
`;
