import compileFiltersBody from 'utils/filters/compileFiltersBody';
import { IDEA_LAYER, INITIATIVE_LAYER } from '@dragonboat/permissions';
import { CHILDREN_FILTERS } from 'constants/filters';

function buildProjectsFiltersForInitiativeChildren(parentId) {
  const baseFilter = {
    fields: {
      parent_id: parentId,
    },
  };

  return compileFiltersBody(baseFilter, {}, false, null, IDEA_LAYER);
}

function buildProjectsFiltersForBetChildren(parentId) {
  const baseFilter = {
    children: CHILDREN_FILTERS.allChildren,
    fields: {
      parent_id: parentId,
    },
    layer: INITIATIVE_LAYER,
  };

  return compileFiltersBody(baseFilter, {}, true, null, INITIATIVE_LAYER);
}

export { buildProjectsFiltersForInitiativeChildren, buildProjectsFiltersForBetChildren };
