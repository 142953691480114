import styled, { css } from 'styled-components';

import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';

import MuiDragIndicatorIcon from '@material-ui/icons/DragIndicator';
import MuiArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import MuiArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import DSCheckbox from 'design-system/atoms/Checkbox/index';

import theme from 'design-system/theme';

export const DragIndicator = styled(MuiDragIndicatorIcon)`
  &&&& {
    display: none;
  }
`;

export const ArrowRight = styled(MuiArrowRightIcon)`
  &&&& {
    width: 12px;
  }
`;

export const ArrowDown = styled(MuiArrowDownIcon)`
  &&&& {
    height: 11px;
    width: 7px;
  }
`;

export const Checkbox = styled(DSCheckbox)`
  &&&& {
    opacity: ${({ disabled }) => {
      return disabled ? 0.5 : 1;
    }};
  }
`;

export const MenuIcon = styled(MuiListItemIcon)`
  &&&& {
    width: 16px;
    display: flex;
    justify-content: center;
    span {
      padding: 0;
      color: ${theme.palette.newLayout.checkbox.checked};
    }
  }
`;

export const MenuText = styled(MuiListItemText)`
  &&&& {
    height: 100%;
    margin: 0;
    padding: 0;
    span {
      font-size: 16px;
      font-weight: normal;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const MenuItem = styled.div`
  &&&& {
  }
`;

export const MenuItemContainer = styled.div`
  &&&& {
    display: grid;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.unit * 1.25}px;
    cursor: pointer;
    padding: 5px 15px;
    box-sizing: border-box;
    height: 30px;

    svg {
      width: 16px;
      height: 16px;
    }
    &:hover {
      background-color: #00000014;
      ${MenuText} {
        color: #2ea8e1;
      }
      ${DragIndicator} {
        display: block;
      }
    }

    &:focus {
      background-color: #00000014;
      outline: none;
    }

    ${({ active }) =>
      active &&
      css`
        background-color: ${({ theme }) => theme.palette.newLayout.background.lighterBlack};
      `}
    
    ${({ showActionsOnHover }) =>
      showActionsOnHover &&
      css`
        .right-action-icon {
          opacity: 0;
        }
        &:hover {
          .right-action-icon {
            opacity: 1;
          }
        }
      `}
`;

export const MenuBodyContainer = styled.div`
  &&&& {
  }
`;

export const MenuContainer = styled.div`
  &&&& {
    ${({ rootList }) =>
      rootList &&
      `
      min-width: 180px;
      height: 100%

      overflow: hidden;
    `}
  }
`;

export const MenuList = styled.div`
  &&&& {
    position: relative;
    ${({ hasFooter, $styleAsAutocomplete }) => {
      const value = $styleAsAutocomplete ? 0 : 8;

      return hasFooter ? `padding: ${value}px 0 0;` : `padding: ${value}px 0;`;
    }}

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      font-size: ${props => props.fontSize || props.theme.typography.fontSize}px;
      line-height: ${props => props.theme.typography.lineHeightRegular}px;
      color: ${props => props.color || props.theme.palette.text.lightGrey};
    }
  }
`;

export const InnerWrapper = styled.div`
  &&&& {
    max-height: 326px; // this is to have a total of 400px
    overflow-y: auto;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  cursor: pointer;
`;

export default {
  DragIndicator,
  ArrowRight,
  ArrowDown,
  Checkbox,
  MenuIcon,
  MenuText,
  MenuItem,
  MenuItemContainer,
  MenuBodyContainer,
  MenuContainer,
  MenuList,
  InnerWrapper,
  ActionWrapper,
};
