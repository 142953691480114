import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';
/**
 * Detail view has key DETAIL and is rendered by the <List> component
 * List view has the key LIST but is rendered by the <Grid> component
 * Insights view has the key INSIGHTS and is rendered by the <Insights> component
 */
export const AVAILABLE_VIEWS = {
  DETAIL: 'DETAIL',
  LIST: 'LIST',
  INSIGHTS: 'INSIGHTS',
};

export const CUSTOMER_REQUESTS_DEFAULT_PAGINATION_LIMIT = 100;

export const CUSTOMER_REQUESTS_DEFAULT_FILTER_PAGINATION_LIMIT = 20;

export const CUSTOMER_REQUESTS_AUTOCOMPLETE_PAGINATION_OPTIONS = {
  limit: CUSTOMER_REQUESTS_DEFAULT_FILTER_PAGINATION_LIMIT,
  page: 1,
};

export const CUSTOM_FILTERS = ['title', 'details', 'key', 'custom_fields'];

export const DEFAULT_FILTER = 'customer_ids';

export const CUSTOM_FIELDS = 'custom_fields';
export const MULTI_SELECT_DROPDOWN_CUSTOM_FIELD_TYPE = 'Multi-select Dropdown';
export const CUSTOM_FIELD_TYPES = {
  [TYPES_OF_CUSTOM_FIELDS.NUMBER]: 'number',
  [TYPES_OF_CUSTOM_FIELDS.YES_NO]: 'checkbox',
  [TYPES_OF_CUSTOM_FIELDS.SHORT_TEXT]: 'text',
  [TYPES_OF_CUSTOM_FIELDS.RICH_TEXT]: 'text',
  [TYPES_OF_CUSTOM_FIELDS.DROPDOWN]: 'option',
  [TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN]: 'option',
};

export const CUSTOM_FIELD_TYPES_INITIAL_VALUES = {
  'Yes/No': true,
};

export const ITEM_LIMIT = 20;

export const OPEN_CUSTOMER_REQUEST_QUERY_PARAM = 'openCr';
