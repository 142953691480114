import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const FiltersHeader = styled(({ children, onClickName, breadcrumbs = [], ...otherProps }) => {
  const _renderBreadcrumb = breadcrumb => <Typography variant="filtersTitle">{breadcrumb} / &nbsp;</Typography>;

  return (
    <div {...otherProps}>
      {breadcrumbs.map(_renderBreadcrumb)}
      <StyledTypography onClick={onClickName} isLink={Boolean(onClickName)} variant="filtersTitle">
        {children}
      </StyledTypography>
    </div>
  );
})`
  &&&& {
    display: flex;
    justify-content: left;
    flex-shrink: 0;
    padding: 20px 18px 10px;
    white-space: nowrap;

    span {
      font-size: 1.1rem;
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    }

    span:first-child {
      color: ${({ theme }) => theme.palette.newLayout.text.labelDisabled};
    }
  }
`;

FiltersHeader.propTypes = {
  children: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.string),
  onClickName: PropTypes.func,
};

const StyledTypography = styled(Typography)`
  &&&& {
    ${props =>
      props.isLink &&
      `
        &:hover {
          cursor: pointer;
        }
     `}
  }
`;

export default FiltersHeader;
