import { useSelector } from 'react-redux';
import { isApplyFiltersSuccess } from 'store/projects/selectors';
import {
  getBaseCompareVersionSelected,
  getRoadmapVersionProjectsState,
  getSelectedRoadmapVersionsIdsToCompare,
} from 'store/roadmapVersions/selectors';
import usePageFilters from 'hooks/filters/usePageFilters';
import { COMPARE_SCENARIOS_FILTER } from 'constants/filters/pages';
import { useEffect, useMemo, useRef } from 'react';
import useRoadmapVersions from 'hooks/useRoadmapVersions';
import { keys, not, without } from 'ramda';
import useLoadProjectsForTransactionPages from 'hooks/projects/useLoadProjectsForTransactionPages';
import { getOrgHasJiraIntegrated, getOrgHasMultipleIntegrations } from 'store/organization';
import { TRANSACTION_PAGES_PROJECTS_LIMIT } from 'constants/index';

const exist = Boolean;

const isNotPlanOfRecord = baseVersion => exist(baseVersion) && not(baseVersion.isPoRBase);

const useLoadProjectsForScenarioVersionComparison = () => {
  const isApplyFiltersFinished = useSelector(isApplyFiltersSuccess);
  const selectedRoadmapVersionsIds = useSelector(getSelectedRoadmapVersionsIdsToCompare);
  const baseCompareVersionSelected = useSelector(getBaseCompareVersionSelected);
  const hasJiraIntegration = useSelector(getOrgHasJiraIntegrated);
  const orgHasMultipleIntegrations = useSelector(getOrgHasMultipleIntegrations);

  useLoadProjectsForTransactionPages(
    COMPARE_SCENARIOS_FILTER,
    {},
    { withIntegrations: !hasJiraIntegration || orgHasMultipleIntegrations },
  );

  const { filtersForApiByLevels } = usePageFilters(COMPARE_SCENARIOS_FILTER);

  const filtersWithLimit = {
    ...filtersForApiByLevels,
    from: 0,
    to: TRANSACTION_PAGES_PROJECTS_LIMIT,
  };

  const needFullLoad = useRef(true);

  const prevIsApplyFiltersFinished = useRef(isApplyFiltersFinished);

  const { isRoadmapVersionsInitialized, fetchRoadmapVersions, fetchMultipleRoadmapVersionsProjects } = useRoadmapVersions();
  const roadmapVersionsProjects = useSelector(getRoadmapVersionProjectsState);

  useEffect(() => {
    if (not(isRoadmapVersionsInitialized)) {
      fetchRoadmapVersions();
    }
  }, [isRoadmapVersionsInitialized]);

  const allSelectedVersions = useMemo(() => {
    const baseSelectedIsRoadmapVersion = isNotPlanOfRecord(baseCompareVersionSelected);

    const baseCompareVersion = baseSelectedIsRoadmapVersion ? [String(baseCompareVersionSelected?.id)] : [];

    return [...selectedRoadmapVersionsIds, ...baseCompareVersion];
  }, [selectedRoadmapVersionsIds, baseCompareVersionSelected?.id]);

  const unloadedVersionIds = useMemo(
    () => without(keys(roadmapVersionsProjects), selectedRoadmapVersionsIds),
    [selectedRoadmapVersionsIds, roadmapVersionsProjects],
  );

  // fetch project for newly selected version for comparison
  useEffect(() => {
    if (!needFullLoad.current && unloadedVersionIds.length) {
      fetchMultipleRoadmapVersionsProjects(baseCompareVersionSelected?.id, unloadedVersionIds, filtersWithLimit);
    }
  }, [unloadedVersionIds]);

  // If the base version is changed, filters are applied, and we need to re-fetch all version projects for all versions selected
  // Filters can change which projects retrieved by the api
  useEffect(() => {
    if (isApplyFiltersFinished && isApplyFiltersFinished !== prevIsApplyFiltersFinished.current) {
      needFullLoad.current = false;
      fetchMultipleRoadmapVersionsProjects(baseCompareVersionSelected?.id, allSelectedVersions, filtersWithLimit, true);
    } else {
      needFullLoad.current = true;
    }

    prevIsApplyFiltersFinished.current = isApplyFiltersFinished;
  }, [isApplyFiltersFinished]);
};

export default useLoadProjectsForScenarioVersionComparison;
