import { isUninitialized, isLoading, getData } from 'utils/store/thunk';

import { FETCH_GOALS_ALLOCATION_DATA } from './types';

const emptyArray = [];
const emptyObject = {};

const DEFAULT_TIME_UNIT = 'month';

// Slice state
export const getState = state => state.goalsAllocation;

// Operations
export const selectOperationsState = state => getState(state).operations || emptyObject;

export const selectGoalsAllocationData = state =>
  getData(selectOperationsState(state), FETCH_GOALS_ALLOCATION_DATA) || emptyArray;

export const selectGoalsAllocationTimePeriod = state => getState(state).timePeriod || emptyObject;

export const selectGoalsAllocationTimeUnit = state => getState(state).timeUnit || DEFAULT_TIME_UNIT;

export const selectGoalsAllocationGridPreferences = state => getState(state).gridPreferences || emptyObject;

export const selectIsLoadingGoalsAllocationData = state => isLoading(selectOperationsState(state), FETCH_GOALS_ALLOCATION_DATA);

export const selectIsUnitializedGoalsAllocationData = state =>
  isUninitialized(selectOperationsState(state), FETCH_GOALS_ALLOCATION_DATA);
