import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

export default React.memo(({ rowId, children }) => {
  return <TableRow key={rowId}>{children}</TableRow>;
});

const TableRow = withTheme()(styled.tr`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.mercury};

  th {
    /* border-right: 1px solid ${({ theme }) => theme.palette.border.mercury}; */
    box-shadow: inset -1px 0 0 ${({ theme }) => theme.palette.border.mercury};
  }
`);
