import { fromJS, List, Map } from 'immutable';
import { BASE_ROW_HEIGHT, SHOW_UNCOMMITTED_PROJECTS } from 'constants/grid';
import { INCLUDE_ALL_OPTION } from 'constants/projects';

import upsertImmutableListItem from '../utils/upsertImmutableListItem';

import {
  SAVE_GRID_STATE,
  ADD_SELECTED_ITEM,
  REMOVE_SELECTED_ITEM,
  SET_SELECTED_ITEMS,
  SHOW_MERGE_LIGHTBOX,
  HIDE_MERGE_LIGHTBOX,
  ENABLE_SELECTION_MODE,
  DISABLE_SELECTION_MODE,
  CLEAR_SELECTED_ITEMS,
  FETCH_USER_GRID_VIEW_FULFILLED,
  SAVE_USER_GRID_VIEW_FULFILLED,
  SAVE_GRID_SELECTED_VIEW,
  SAVE_GRID_CONFIG,
} from './types';
import {
  CATEGORIES,
  CUSTOMER_REQUESTS,
  ESTIMATES,
  IDEAS,
  OBJECTIVES,
  PHASES,
  ROADMAPS,
  TEAMS,
  THEMES,
  TIMEFRAMES,
  USERS,
  GOAL_MODE,
  ERROR_LOGS,
  PDLC,
  SNAPSHOT,
} from './constants';
import { MERGE_CUSTOMER_REQUESTS_FULFILLED } from '../customerRequests';

const initialState = fromJS({
  [CATEGORIES]: {},
  [CUSTOMER_REQUESTS]: {},
  [ESTIMATES]: {
    [SHOW_UNCOMMITTED_PROJECTS]: INCLUDE_ALL_OPTION,
  },
  [IDEAS]: {
    rowHeight: BASE_ROW_HEIGHT,
  },
  [OBJECTIVES]: {},
  [PHASES]: {},
  [ROADMAPS]: {},
  [TEAMS]: {},
  [THEMES]: {},
  [TIMEFRAMES]: {},
  [USERS]: {},
  [GOAL_MODE]: {},
  [ERROR_LOGS]: {},
  [PDLC]: {},
  [SNAPSHOT]: {},
});

function validateGrid(grid) {
  if (!grid || typeof grid !== 'string') {
    throw new Error('action.grid should be a string');
  }
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SAVE_GRID_CONFIG:
      const { config } = action.payload;

      validateGrid(action.payload.grid);

      const currentState = state.get(action.payload.grid) || fromJS({});

      return state.set(
        action.payload.grid,
        currentState.merge({
          [config.key]: config.value,
        }),
      );
    case SAVE_GRID_SELECTED_VIEW:
      const { selectedView } = action.payload;

      validateGrid(action.payload.grid);

      return state.setIn([action.payload.grid, 'selectedView'], fromJS(selectedView));
    case SAVE_GRID_STATE:
      validateGrid(action.grid);

      const { grid, gridState } = action;
      let grouped = false;

      if (gridState && gridState.columnState && gridState.columnState instanceof Array) {
        const columnGrouped = gridState.columnState.find(
          col => col && col.rowGroupIndex !== null && col.rowGroupIndex !== undefined,
        );

        if (columnGrouped) {
          grouped = true;
        }
      }

      const cuurentGridState = state.get(grid) || fromJS({});

      return state.set(
        grid,
        cuurentGridState.merge({
          gridState,
          grouped,
        }),
      );
    case ADD_SELECTED_ITEM: {
      validateGrid(action.grid);
      let selectedItems = state.getIn([action.grid, 'selectedItems']) || new List();

      if (Array.isArray(action.item)) {
        action.item
          .filter(i => !!i)
          .forEach(item => {
            selectedItems = upsertImmutableListItem(fromJS(item), selectedItems);
          });
        return state.setIn([action.grid, 'selectedItems'], selectedItems);
      }

      if (!action.item) {
        console.trace('action.item should have an id');
        return state;
      }

      selectedItems = upsertImmutableListItem(fromJS(action.item), selectedItems);
      return state.setIn([action.grid, 'selectedItems'], selectedItems);
    }
    case REMOVE_SELECTED_ITEM: {
      let selectedItems = state.getIn([action.grid, 'selectedItems']) || new List();

      if (Array.isArray(action.item)) {
        action.item
          .filter(i => !!i)
          .forEach(item => {
            selectedItems = selectedItems.filter(sel => sel.get('id') !== item.id);
          });

        return state.setIn([action.grid, 'selectedItems'], selectedItems);
      }

      if (!action.item) {
        // console.trace('action.itemId should be a number');
        return state;
      }
      selectedItems = selectedItems.filter(item => item.get('id') !== action.item);

      return state.setIn([action.grid, 'selectedItems'], selectedItems);
    }
    case SHOW_MERGE_LIGHTBOX:
      return state.setIn([action.grid, 'mergeLightboxVisible'], true);
    case HIDE_MERGE_LIGHTBOX: {
      validateGrid(action.grid);

      let gridState = state.get(action.grid) || new Map();

      gridState = gridState.merge(
        Map({
          mergeLightboxVisible: false,
        }),
      );

      return state.set(action.grid, gridState);
    }
    case ENABLE_SELECTION_MODE:
      return state.setIn([action.grid, 'selectionMode'], action.value);
    case DISABLE_SELECTION_MODE: {
      let gridState = state.get(action.grid) || new Map();

      gridState = gridState.merge(
        Map({
          selectedItems: new List(),
          selectionMode: false,
        }),
      );
      return state.set(action.grid, gridState);
    }
    case SAVE_USER_GRID_VIEW_FULFILLED:
      const savedUserView = action.payload.data;

      validateGrid(savedUserView.page);
      const savedView = fromJS({ ...savedUserView.state, id: savedUserView.id });

      return state.setIn([savedUserView.page, 'userView'], savedView);
    case FETCH_USER_GRID_VIEW_FULFILLED:
      const userView = action.payload;

      validateGrid(userView.page);
      const fecthedView = fromJS({ ...userView.state, id: userView.id });
      let newState = state.setIn([userView.page, 'userView'], fecthedView);

      if (userView.state.expandedGroups) {
        newState = newState.setIn([userView.page, 'expandedGroups'], fromJS(userView.state.expandedGroups));
      }

      return newState;
    case CLEAR_SELECTED_ITEMS:
      return state.setIn([action.grid, 'selectedItems'], new List());
    case SET_SELECTED_ITEMS: {
      return state.setIn([action.grid, 'selectedItems'], fromJS(action.items));
    }
    case MERGE_CUSTOMER_REQUESTS_FULFILLED: {
      return state.setIn([CUSTOMER_REQUESTS, 'selectedItems'], new List());
    }
    default:
      return state;
  }
};
