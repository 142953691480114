import React from 'react';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import ProjectFieldsLayoutConfigurator from 'design-system/organisms/ProjectFieldsLayoutConfigurator/index';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const SAVE_BUTTON_ID = 'save-project-field-layout-configuration';
const CLOSE_BUTTON_ID = 'close-project-field-layout-configuration';

export default ({
  isOpen,
  onClose,
  anchorEl,
  fieldsLayout,
  fieldsInherit,
  fieldsRequired,
  handleChangeConfiguration,
  handleSaveConfiguration,
  allowFieldToInherit,
  editable,
  enableRequiredFields,
  allowFieldToBeRequired,
}) => {
  const onSave = () => {
    onClose();
    handleSaveConfiguration();
  };

  return (
    <StyledPopover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl?.current?.parentElement}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Wrapper>
        <Header>
          <Typography variant="body2" color="textSecondary">
            {editable && 'Drag and drop to organize/hide fields'}
          </Typography>
          <IconButton>
            <CloseIcon onClick={onClose} fontSize="small" />
          </IconButton>
        </Header>
        <ProjectFieldsLayoutConfigurator
          onChange={handleChangeConfiguration}
          fieldsLayout={fieldsLayout}
          fieldsInherit={fieldsInherit}
          fieldsRequired={fieldsRequired}
          draggable
          allowToInherit={allowFieldToInherit}
          editable={editable}
          enableRequiredFields={enableRequiredFields}
          allowToBeRequired={allowFieldToBeRequired}
        />
        <ButtonsContainer>
          {editable && (
            <Button id={SAVE_BUTTON_ID} onClick={onSave} color="primary">
              save
            </Button>
          )}
          {!editable && (
            <Button id={CLOSE_BUTTON_ID} onClick={onClose} color="primary">
              close
            </Button>
          )}
        </ButtonsContainer>
      </Wrapper>
    </StyledPopover>
  );
};

// We need to force the transform=none since inside the popover we have a drag and drop component that can't be affected by it
const StyledPopover = styled(Popover)`
  &&&& {
    div[role='document'] {
      transform: none !important;
    }
  }
`;

const Header = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 0 25px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 16px 10px;
`;

const Wrapper = styled.div`
  width: calc(46vw - 48px);
  min-width: 385px;
  max-width: 590px;
`;
