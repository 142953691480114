import { useCallback, useMemo } from 'react';

const useFilteredMetrics = (metrics, { searchText, isHidingArchivedMetrics }) => {
  const processedSearchText = useMemo(() => searchText?.toLowerCase(), [searchText]);

  const isNewRow = metric => !metric.id;
  const hasNewRow = useMemo(() => metrics.some(isNewRow), [metrics]);

  const searchTextFilter = useCallback(
    ({ name, details }) => {
      return !processedSearchText || [name, details].some(field => field?.toLowerCase().includes(processedSearchText));
    },
    [processedSearchText],
  );

  const hideArchivedFilter = useCallback(
    ({ status }) => {
      return !isHidingArchivedMetrics || status !== 'Archived';
    },
    [isHidingArchivedMetrics],
  );

  const filters = useMemo(() => {
    const activeFilters = [];

    if (searchText) {
      activeFilters.push(searchTextFilter);
    }

    if (isHidingArchivedMetrics) {
      activeFilters.push(hideArchivedFilter);
    }

    return activeFilters;
  }, [searchText, searchTextFilter, isHidingArchivedMetrics, hideArchivedFilter]);

  return useMemo(() => {
    const filteredMetrics = filters.length ? metrics.filter(metric => filters.every(filter => filter(metric))) : metrics;

    if (hasNewRow) {
      const newRow = metrics.find(isNewRow);

      return [newRow, ...filteredMetrics.filter(metric => metric.id)];
    }

    return filteredMetrics;
  }, [metrics, filters]);
};

export default useFilteredMetrics;
