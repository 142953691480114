import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrganization, getOrgJiraIntegrations } from 'store/organization/selectors';
import { updateState } from 'store/staffing';
import { getPageFilters } from 'store/filters/selectors';
import { DEFAULT_GROUP_OPTION, GROUP_OPTIONS_FOR_TASKS, DEFAULT_ZOOM_OPTION, ZOOM_OPTIONS } from 'constants/staffing';
import { STAFFING_PAGE } from 'constants/filters';
import useColorLegend from 'hooks/timeline/useColorLegend';
import useSystemFields from 'hooks/useSystemFields';
import { JIRA_PROJECT_RENAMED_WARNING } from 'constants/integrations';
import getJiraIntegrationWarnings from 'utils/getJiraIntegrationWarnings';
import { setAppGlobalConfig } from 'store/app';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [openPreferences, setOpenPreferences] = useState(null);
    const lsState = useSelector(state => state.staffing);
    const organization = useSelector(getOrganization);
    const systemFields = organization.system_fields_name;
    const _updateLsState = state => dispatch(updateState(state));
    const [getLegendGroups, updateGroupColor] = useColorLegend(STAFFING_PAGE);
    const [getSystemFieldName] = useSystemFields();

    const pageFilters = useSelector(state => getPageFilters(state, STAFFING_PAGE));

    const orgJiraIntegrations = useSelector(getOrgJiraIntegrations);
    const { has_rename_jira_project_enabled: hasRenameJiraProjectEnabled } = organization;
    const warning = hasRenameJiraProjectEnabled && getJiraIntegrationWarnings(orgJiraIntegrations, JIRA_PROJECT_RENAMED_WARNING);

    const filtersActive =
      (pageFilters &&
        pageFilters.quickFilters &&
        Object.keys(pageFilters.quickFilters) &&
        !!pageFilters.quickFilters[Object.keys(pageFilters.quickFilters)[0]].length) ||
      (pageFilters.op && !!Object.keys(pageFilters.op).length);

    const groupByOptions = GROUP_OPTIONS_FOR_TASKS({ systemFields });
    const groupByGroup2Options = groupByOptions.filter(o => lsState.selectedGroup1 && lsState.selectedGroup1.key !== o.key);
    const _onGroupLevelChange = level => option => {
      const nullOption = groupByOptions.find(o => o.key === null);
      let selectedGroup1 = level === 'selectedGroup1' ? option : lsState.selectedGroup1;
      let selectedGroup2 = level === 'selectedGroup2' ? option : lsState.selectedGroup2;

      if (!selectedGroup1) {
        selectedGroup1 = nullOption;
        selectedGroup2 = nullOption;
      }
      if (!selectedGroup2) {
        selectedGroup2 = nullOption;
      }
      if (selectedGroup1 && selectedGroup1.key === 'initiative' && selectedGroup2 && selectedGroup2.key === 'bet') {
        selectedGroup2 = nullOption;
      }

      _updateLsState({ selectedGroup1, selectedGroup2 });
    };
    const _onCreateNewAssignment = () => _updateLsState({ selectedTask: { $new: true, title: 'New Assignment', duration: 14 } });
    const _changeSelectedZoom = () => {
      if (lsState.selectedZoom.id === 'week') {
        _updateLsState({ selectedZoom: ZOOM_OPTIONS.find(g => g.id === 'month') });
      } else if (lsState.selectedZoom.id === 'month') {
        _updateLsState({ selectedZoom: ZOOM_OPTIONS.find(g => g.id === 'quarter') });
      } else if (lsState.selectedZoom.id === 'day') {
        _updateLsState({ selectedZoom: ZOOM_OPTIONS.find(g => g.id === 'week') });
      } else if (lsState.selectedZoom.id === 'quarter') {
        _updateLsState({ selectedZoom: ZOOM_OPTIONS.find(g => g.id === 'day') });
      }
    };

    const _onWarningClick = () => {
      dispatch(setAppGlobalConfig({ showUpdateJiraKeyDialog: true }));
    };

    React.useEffect(() => {
      const defaultState = {};

      if (!lsState.selectedGroup1) defaultState.selectedGroup1 = DEFAULT_GROUP_OPTION(groupByOptions).selectedGroup1;
      if (!lsState.selectedGroup2) defaultState.selectedGroup2 = DEFAULT_GROUP_OPTION(groupByOptions).selectedGroup2;
      if (!lsState.selectedZoom) defaultState.selectedZoom = DEFAULT_ZOOM_OPTION;

      if (Object.keys(defaultState).length) _updateLsState(defaultState);
    }, []);

    return (
      <Component
        {...props}
        lsState={lsState}
        groupByOptions={groupByOptions}
        groupByGroup2Options={groupByGroup2Options}
        onGroupLevelChange={_onGroupLevelChange}
        onCreateNewAssignment={_onCreateNewAssignment}
        getLegendGroups={getLegendGroups}
        updateGroupColor={updateGroupColor}
        setOpenPreferences={setOpenPreferences}
        openPreferences={openPreferences}
        changeSelectedZoom={_changeSelectedZoom}
        getSystemFieldName={getSystemFieldName}
        updateState={_updateLsState}
        filtersActive={filtersActive}
        warning={warning}
        onWarningClick={_onWarningClick}
      />
    );
  };
};

export default componentHOC;
