import { head } from 'ramda';

import { ConditionBuilder } from '@dragonboat/dbql';
import {
  EQUAL,
  AND,
  IS_EMPTY,
  IS_NOT_EMPTY,
  NOT_IN,
  IN,
  CONTAINS,
  GREATER_THAN_OR_EQUAL,
  LESS_THAN_OR_EQUAL,
} from '@dragonboat/dbql/src/operators';

import { FILTERS_FUNCTIONS } from 'design-system/constants';

import createConditionFromContainsAnyOfFilter from 'features/DBQL/helpers/CreateConditionFromContainsAnyOfFilter';
import createConditionFromDateFilter from 'features/DBQL/helpers/CreateConditionFromDateFilter';
import { PLANNING_STAGES } from 'constants/common';
import createConditionFromNotContainsFilter from 'features/DBQL/helpers/CreateConditionFromNotContainsFilter';

const mapOperators = {
  [FILTERS_FUNCTIONS.in.key]: IN,
  [FILTERS_FUNCTIONS.notIn.key]: NOT_IN,
  [FILTERS_FUNCTIONS.contains.key]: CONTAINS,
  [FILTERS_FUNCTIONS.equals.key]: EQUAL,
  [FILTERS_FUNCTIONS.isEmpty.key]: IS_EMPTY,
  [FILTERS_FUNCTIONS.isEmptyText.key]: IS_EMPTY,
  [FILTERS_FUNCTIONS.isNotEmpty.key]: IS_NOT_EMPTY,
  [FILTERS_FUNCTIONS.isNotEmptyText.key]: IS_NOT_EMPTY,
  [FILTERS_FUNCTIONS.gte.key]: GREATER_THAN_OR_EQUAL,
  [FILTERS_FUNCTIONS.lte.key]: LESS_THAN_OR_EQUAL,
};

const customOperatorsBuilders = {
  [FILTERS_FUNCTIONS.containsAnyOf.key]: createConditionFromContainsAnyOfFilter,
  [FILTERS_FUNCTIONS.notContains.key]: createConditionFromNotContainsFilter,
  [FILTERS_FUNCTIONS.lastWeek.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.lastMonth.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.lastQuarter.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.lastYear.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.thisWeek.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.thisMonth.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.thisQuarter.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.thisYear.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.nextYear.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.nextQuarter.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.nextMonth.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.nextWeek.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.today.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.before.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.after.key]: createConditionFromDateFilter,
  [FILTERS_FUNCTIONS.between.key]: createConditionFromDateFilter,
};

const NULL_VALUE_OPTIONS = 'null';

const buildSingleCondition = filterCondition => {
  const operator = filterCondition?.op;
  const mappedOperator = mapOperators[operator];

  const containsUndefinedValue = Array.isArray(filterCondition?.value)
    ? filterCondition.value.some(v => v === NULL_VALUE_OPTIONS)
    : false;
  const valueToUse = Array.isArray(filterCondition?.value)
    ? filterCondition.value.filter(v => v !== NULL_VALUE_OPTIONS)
    : filterCondition.value;

  let condition;

  if (!mappedOperator && operator) {
    const customBuilder = customOperatorsBuilders[operator];

    condition = customBuilder({ ...filterCondition, value: valueToUse });
  } else {
    condition = ConditionBuilder.createCondition(filterCondition.field.id, mappedOperator, valueToUse);
  }

  // should also consider value to be null/undefined/empty
  if (filterCondition?.field?.id === PLANNING_STAGES || containsUndefinedValue) {
    const nullCondition = ConditionBuilder.createCondition(filterCondition.field.id, IS_EMPTY);

    return condition.or(nullCondition);
  }
  return condition;
};

const buildConditionGroup = group => {
  const { conditions: groupConditions } = group;

  const firstFilterCondition = head(groupConditions);

  if (!firstFilterCondition?.field?.id) {
    return;
  }

  let condition = buildSingleCondition(firstFilterCondition);

  if (!condition) {
    return;
  }

  for (let i = 1; i < groupConditions.length; i++) {
    const curFilterCondition = groupConditions[i];
    const { groupOperator } = curFilterCondition;

    if (!curFilterCondition?.field?.id) {
      continue;
    }
    const nextCondition = buildSingleCondition(curFilterCondition);

    if (!nextCondition) {
      continue;
    }

    // Apply the groupOperator to combine conditions
    condition = groupOperator === AND ? condition.and(nextCondition) : condition.or(nextCondition);
  }

  return condition;
};

const combineConditions = (layerCondition, filterCondition, includeItemsWithoutParentCondition) => {
  if (filterCondition && includeItemsWithoutParentCondition) {
    return layerCondition.and(filterCondition).or(includeItemsWithoutParentCondition.and(filterCondition)).toString();
  } else if (filterCondition && !includeItemsWithoutParentCondition) {
    return layerCondition.and(filterCondition).toString();
  } else if (!filterCondition && includeItemsWithoutParentCondition) {
    return layerCondition.or(includeItemsWithoutParentCondition).toString();
  }
  return layerCondition.toString();
};

const createIncludeItemsWithoutParentCondition = (layer, secondLayer) => {
  const layerCondition = ConditionBuilder.createCondition('layer', IN, [secondLayer.filter(l => l !== layer)]);
  const parentCondition = ConditionBuilder.createCondition('parent', IS_EMPTY);

  return layerCondition.and(parentCondition);
};

const buildConditionsFromInput = (layer, secondLayer = [], conditions) => {
  // Create the layer condition
  const layerCondition = ConditionBuilder.createCondition('layer', IN, [layer]);

  const shouldIncludeItemsWithoutParents = !!secondLayer?.length;

  const includeItemsWithoutParentCondition = shouldIncludeItemsWithoutParents
    ? createIncludeItemsWithoutParentCondition(layer, secondLayer)
    : undefined;

  let resultCondition;

  if (conditions?.length) {
    const firstFilterCondition = head(conditions);

    // Check if the first condition is a group
    resultCondition = firstFilterCondition?.group
      ? buildConditionGroup(firstFilterCondition) // If it's a group, handle it as a group
      : buildSingleCondition(firstFilterCondition); // Otherwise, treat it as a single condition

    if (!resultCondition) {
      return combineConditions(layerCondition, null, includeItemsWithoutParentCondition);
    }

    for (let i = 1; i < conditions.length; i++) {
      const current = conditions[i];

      // Handle grouped conditions
      const nextCondition = current.group ? buildConditionGroup(current) : buildSingleCondition(current);

      resultCondition = current.groupOperator === AND ? resultCondition.and(nextCondition) : resultCondition.or(nextCondition);
    }
  }
  return combineConditions(layerCondition, resultCondition, includeItemsWithoutParentCondition);
};

const getDbqlFromPortfolioFilters = (filters = {}) => {
  const { dbql: { layer, secondLayer, conditions } = {} } = filters;

  return buildConditionsFromInput(layer, secondLayer, conditions);
};

export default getDbqlFromPortfolioFilters;
