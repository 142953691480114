import React from 'react';

import GroupWithMultiSelect from './GroupWithMultiSelect';
import GroupWithCheckboxes from './GroupWithCheckboxes';
import { GROUP_CHECKBOXES, GROUP_MULTISELECT } from '../helpers';

const makeIsFieldVisible = visibleColumns => (f, children) => {
  if (Array.isArray(f) && !children) return f.every(col => visibleColumns.includes(col));
  if (!children) return visibleColumns.includes(f);

  return children.some(c => visibleColumns.includes(c.field));
};

const FieldsGroup = ({ group, visibleColumns, changeColumnVisible, hideColumns, className }) => {
  switch (group.type) {
    case GROUP_MULTISELECT:
      return (
        <GroupWithMultiSelect
          key={group.key}
          group={group}
          visibleColumns={visibleColumns}
          checkFieldVisible={makeIsFieldVisible(visibleColumns)}
          changeColumnVisible={changeColumnVisible}
          hideColumns={hideColumns}
          className={className}
        />
      );
    case GROUP_CHECKBOXES:
      return (
        <GroupWithCheckboxes
          key={group.key}
          group={group}
          groupKey={group.key}
          visibleColumns={visibleColumns}
          changeColumnVisible={changeColumnVisible}
          checkFieldVisible={makeIsFieldVisible(visibleColumns)}
          className={className}
        />
      );
    default:
      return '';
  }
};

export default FieldsGroup;
