import styled from 'styled-components';

import IconAndTextButton from 'design-system/molecules/IconAndTextButton/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


export const IntegrationWrapper = styled.div`
  padding: 15px;
  padding-top: 0px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  min-height: 150px;
  flex: 1;
`;

export const IntegrationTitle = styled(TextDeprecated)`
  &&&& {
    font-size: 16px !important;
  }
`;

export const IntegrationFieldsWrapper = styled.div`
  &&&& {
    // width: 300px;
  }
`;

export const IntegrationCreateFormWrapper = styled.div`
  margin-top: 5px;
  margin-left: 177px;
`;

export const IntegrationLinkFormWrapper = styled.div`
  &&&& {
    width: 70%;
  }
`;

export const IntegrationCreateButton = styled(IconAndTextButton)`
  &&&& {
    // margin-left: 177px;
  }
`;
