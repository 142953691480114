import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { SuperloginFeatureFlags as featureFlags } from '@dragonboat/config';

import HelpIconTooltip from 'design-system/molecules/HelpIconTooltip/index';

import { updateOrganization, getOrganization } from 'store/organization';

export default () => {
  const dispatch = useDispatch();
  const organization = useSelector(getOrganization);

  const onChange = (key, val) => dispatch(updateOrganization({ [key]: val }));

  return (
    <Wrapper>
      {featureFlags.map(ff => {
        const { key, label, description } = ff;
        const checked = Boolean(organization[key]);

        return (
          <FeatureFlagContainer>
            <FormControlLabel
              id={key}
              label={label}
              control={<Switch checked={checked} onChange={() => onChange(key, !checked)} />}
            />
            {description ? <HelpIconTooltip label={description} /> : null}
          </FeatureFlagContainer>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const FeatureFlagContainer = styled.div`
  display: flex;
  align-items: center;
`;
