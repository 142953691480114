import React from 'react';
import styled from 'styled-components';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import getIconForUserView from 'utils/userViews/getIconForUserView';
import getIconColorForUserView from 'utils/userViews/getIconColorForUserView';

export default ({ item }) => (
  <ListItemAvatar>
    <AvatarContainer colorName={getIconColorForUserView(item.page)}>{getIconForUserView(item.page)}</AvatarContainer>
  </ListItemAvatar>
);

const AvatarContainer = styled(Avatar)`
  &&&&& {
    border-radius: 21%;
    cursor: pointer;
    width: 61px;
    height: 60px;
    background-color: ${({ theme, colorName }) => theme.palette.viewIconBackgroundColors[colorName]};
  }
`;
