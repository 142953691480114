import moment from 'moment-timezone';

import { DEFAULT_VIEWPORTS, VIEW_MODE_RELATIONS } from './constants';
import { isSnapToEnd } from './snapToGrid';

const durationInDays = (duration, mode) => {
  const diff = moment.duration(duration, `${mode}s`);

  return diff.asDays();
};

const getDateDiffInDays = (task, steps, zoomMode, snapToGridOn) => {
  if (!snapToGridOn) {
    return durationInDays(steps, zoomMode);
  }

  const clonedTargetDate = task[snapToGridOn].clone().add(steps, zoomMode);

  const withSnap = isSnapToEnd(snapToGridOn) ? clonedTargetDate.endOf(zoomMode) : clonedTargetDate.startOf(zoomMode);

  return durationInDays(moment.duration(withSnap.diff(task[snapToGridOn])), zoomMode);
};

const distanceBetweenDates = (fromDate, toDate, mode) => {
  const diff = moment.duration(toDate.diff(fromDate));

  switch (mode) {
    case 'year':
      return diff.asYears();
    case 'quarter':
      return diff.asQuarters();
    case 'month':
      return diff.asMonths();
    case 'week':
      return diff.asWeeks();
    default:
      return diff.asDays();
  }
};

const calculateFromDate = zoomMode => {
  const relationMode = VIEW_MODE_RELATIONS[zoomMode];

  const newDate = moment()
    .subtract(DEFAULT_VIEWPORTS[zoomMode] / 2, relationMode)
    .startOf(relationMode)
    .startOf(zoomMode);

  if (newDate.date() !== 1) {
    newDate.add(1, zoomMode);
  }

  return newDate;
};

const calculateToDate = zoomMode => {
  const relationMode = VIEW_MODE_RELATIONS[zoomMode];

  return moment()
    .add(DEFAULT_VIEWPORTS[zoomMode] / 2, relationMode)
    .endOf(relationMode);
};

export { durationInDays, distanceBetweenDates, calculateFromDate, calculateToDate, getDateDiffInDays };
