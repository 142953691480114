import React from 'react';
import styled from 'styled-components';
import SendToIntegrationChannelButton from 'design-system/molecules/SendToIntegrationChannelButton';
import useSendToIntegrationChannel from 'hooks/integrations/useSendToIntegrationChannel';
import useHasIntegrationsOfType from 'hooks/integrations/useHasIntegrationsOfType';

const SendCommentToIntegrationChannel = ({ integrationType, onSend, hasCommentText }) => {
  const { channels, selectedChannel, setSelectedChannel } = useSendToIntegrationChannel(integrationType);
  const hasIntegrationsOfType = useHasIntegrationsOfType(integrationType);

  const isDisabled = !selectedChannel || !hasCommentText;

  const handleOnSend = () => {
    onSend({ channel: selectedChannel, integrationType });
    setSelectedChannel(null);
  };

  return hasIntegrationsOfType ? (
    <Flex>
      <SendToIntegrationChannelButton
        integrationType={integrationType}
        onSend={handleOnSend}
        channels={channels}
        setSelectedChannel={setSelectedChannel}
        selectedChannel={selectedChannel}
        isDisabled={isDisabled}
      />
    </Flex>
  ) : null;
};

export default SendCommentToIntegrationChannel;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
