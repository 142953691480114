import { useMemo } from 'react';
import { path } from 'ramda';

import { numericColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import { FeatureFlags } from '@dragonboat/config';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { defaultExport } from 'features/GridExport/helpers/exportUtils';

const revenueValueGetter = path(['data', 'preCalculations', 'totalRevenue']);
const inactiveRevenueValueGetter = path(['data', 'preCalculations', 'totalChurnedRevenue']);
const netRevenueValueGetter = path(['data', 'preCalculations', 'totalNetRevenue']);
const customersCountValueGetter = path(['data', 'preCalculations', 'customers_count']);

const numericColCommonDefs = { ...numericColumnDef, exportFn: defaultExport };

/**
 * @function useCustomerRequestsPreCalculatedFieldsColumnDefs
 *
 * Uses pre calculated fiels column definitions
 *
 * @return {Array}
 */
const useCustomerRequestsPreCalculatedFieldsColumnDefs = () => {
  const shouldShowPreCalculations = useFeatureFlags([FeatureFlags.HAS_OUTCOME_DASHBOARD]);

  const netRevenueColumnDef = useMemo(() => {
    return {
      ...numericColCommonDefs,
      editable: false,
      field: 'cr_total_net_revenue',
      headerName: 'Total value',
      valueGetter: netRevenueValueGetter,
      width: 100,
      cellRendererParams: {
        withoutZeros: true,
      },
    };
  }, []);

  const totalRevenueColumnDef = useMemo(() => {
    return {
      ...numericColCommonDefs,
      editable: false,
      field: 'cr_total_revenue',
      headerName: 'Active value',
      valueGetter: revenueValueGetter,
      width: 100,
      cellRendererParams: {
        withoutZeros: true,
      },
    };
  }, []);

  const totalChurnedRevenueColumnDef = useMemo(() => {
    return {
      ...numericColCommonDefs,
      editable: false,
      field: 'cr_total_churned_revenue',
      headerName: 'Inactive value',
      valueGetter: inactiveRevenueValueGetter,
      width: 100,
      cellRendererParams: {
        withoutZeros: true,
      },
    };
  }, []);

  const customersCountColumnDef = useMemo(() => {
    return {
      ...numericColCommonDefs,
      editable: false,
      field: 'cr_customers_count',
      headerName: 'No. customers',
      valueGetter: customersCountValueGetter,
      width: 140,
      cellRendererParams: {
        withoutZeros: true,
      },
    };
  }, []);

  return useMemo(
    () => [
      ...(shouldShowPreCalculations ? [netRevenueColumnDef, totalRevenueColumnDef, totalChurnedRevenueColumnDef] : []),
      customersCountColumnDef,
    ],
    [
      shouldShowPreCalculations,
      netRevenueColumnDef,
      totalRevenueColumnDef,
      totalChurnedRevenueColumnDef,
      customersCountColumnDef,
    ],
  );
};

export default useCustomerRequestsPreCalculatedFieldsColumnDefs;
