import React from 'react';
import styled from 'styled-components';

import GridMenu from 'design-system/organisms/GridMenu/index';


const SelectChart = ({ charts, selectedChart, handleSelectChart }) => {
  return (
    <GridWrapper>
      <GridMenu items={charts} selectedItemId={selectedChart?.id} onItemClick={handleSelectChart} />
    </GridWrapper>
  );
};

export default SelectChart;

const GridWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
