/* eslint-disable */
import React from 'react';
import { withTheme } from 'styled-components';

export default withTheme(({ fill, height = 16, theme, width = 16, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346627 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 6.94942 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0ZM14.344 7.2H8.8V1.656C10.208 1.83455 11.5167 2.47608 12.5203 3.47967C13.5239 4.48326 14.1654 5.79198 14.344 7.2ZM1.6 8C1.60142 6.44216 2.17098 4.93833 3.20192 3.77042C4.23286 2.60252 5.65439 1.85073 7.2 1.656V14.344C5.65439 14.1493 4.23286 13.3975 3.20192 12.2296C2.17098 11.0617 1.60142 9.55783 1.6 8ZM8.8 14.344V8.8H14.344C14.1654 10.208 13.5239 11.5167 12.5203 12.5203C11.5167 13.5239 10.208 14.1654 8.8 14.344Z"
        fill={fill || theme.palette.newLayout.background.primary}
      />
    </svg>
  );
});
