import React from 'react';
import styled from 'styled-components';
import omit from 'lodash/omit';
import Typography from '@material-ui/core/Typography';
import { textColor } from 'design-system/themes/default';
import { withTheme } from '@material-ui/core';

export default withTheme()(styled(props => (
  <Typography {...omit(props, ['color', 'textColor', 'breakwords', 'isDropdown', 'textStyles'])} />
))`
  &&&& {
    ${({ textStyles }) => textStyles != null && textStyles};

    color: ${({ color, theme }) => theme?.palette?.text?.[color] || color || textColor};

    ${({ variant }) =>
      variant === 'bold' &&
      `
      font-weight: bold;
    `}

    ${({ size }) =>
      size === 'medium' &&
      `
      font-size: 16px;
    `}

    ${({ size }) =>
      size === 'small' &&
      `
      font-size: 12px;
    `}

    ${({ inline }) =>
      inline &&
      `
      display: inline;
    `}

    ${({ align }) =>
      align &&
      `
      text-align: ${align};
    `}

    ${({ breakwords }) =>
      !!breakwords &&
      `
      word-wrap: break-word !important;
      word-break: break-word !important;
      white-space: normal !important;
    `}

    ${({ breakwords }) =>
      !breakwords &&
      `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  }
`);
