import React from 'react';
import styled from 'styled-components';

import HeadingH1 from 'design-system/atoms/HeadingH1/index';

import SelectChart from './components/SelectChart';
import ChartConfigurations from './components/ChartConfiguration';
import { SELECT_CHART_SCREEN, CHART_CONFIG_SCREEN, SUMMARY_CHARTS_SCREEN } from './hooks/useChartWidgetWizard';
import SummaryChartsConfiguration from './components/SummaryChartsConfiguration';

const ChartWidgetWizard = ({
  title,
  charts,
  selectedChart,
  handleSelectChart,
  currentScreen,
  chartOptions,
  selectedChartOptions,
  handleSelectChartOption,
}) => {
  return (
    <Container>
      <StyledHeadingH1>{title}</StyledHeadingH1>
      {currentScreen === SELECT_CHART_SCREEN && (
        <SelectChart charts={charts} selectedChart={selectedChart} handleSelectChart={handleSelectChart} />
      )}
      {currentScreen === CHART_CONFIG_SCREEN && (
        <ChartConfigurations
          selectedChart={selectedChart}
          chartOptions={chartOptions}
          selectedChartOptions={selectedChartOptions}
          handleSelectChartOption={handleSelectChartOption}
        />
      )}
      {currentScreen === SUMMARY_CHARTS_SCREEN && (
        <SummaryChartsConfiguration
          selectedChartOptions={selectedChartOptions}
          handleSelectChartOption={handleSelectChartOption}
        />
      )}
    </Container>
  );
};

export default ChartWidgetWizard;

const Container = styled.div`
  min-width: 950px;
  min-height: 435px;
  display: flex;
  flex-direction: column;
`;

const StyledHeadingH1 = styled(HeadingH1)`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 3}px;
`;
