import React from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import { ASCENDING, DESCENDING } from 'hooks/useTableSort';
import { LIST_HEADERS } from './constants';

const { NAME, VISITS_COUNT, OWNER } = LIST_HEADERS;

const EDIT_VIEW = 'edit-view';
const SHARE_VIEW = 'share-view';

const UPDATE_VIEW_OPTIONS = [
  {
    icon: <EditOutlinedIcon />,
    key: EDIT_VIEW,
    label: 'Edit',
  },
  {
    icon: <ShareOutlinedIcon />,
    key: SHARE_VIEW,
    label: 'Share',
  },
];

const sortViewsByField = (viewsList, field, direction = ASCENDING) => {
  const views = [...viewsList];

  let sortFn;

  switch (field) {
    case NAME.key:
      sortFn = (a, b) => a?.name.localeCompare(b?.name);
      break;
    case OWNER.key:
      sortFn = (a, b) => a?.user?.name.localeCompare(b?.user?.name);
      break;
    case VISITS_COUNT.key:
    default:
      sortFn = (a, b) => a?.total_view_count - b?.total_view_count;
  }

  if (direction === DESCENDING) {
    return views.sort((a, b) => sortFn(b, a));
  }

  return views.sort(sortFn);
};

export { UPDATE_VIEW_OPTIONS, sortViewsByField };
