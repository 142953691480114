import { _isEditable } from '../state';

export default (rows = [], columnsProps = []) => {
  const editableMap = rows.reduce((acc, row, rowIndex) => {
    acc[rowIndex] = columnsProps.reduce((propsAcc, props, columnIndex) => {
      propsAcc[columnIndex + 2] = _isEditable(row, (props || {}).editable);
      return propsAcc;
    }, []);

    return acc;
  }, []);

  const firstEditableColumnIndex = columnsProps.findIndex(c => _isEditable({}, (c || {}).editable));

  return {
    editableMap,
    firstEditableColumnIndex,
  };
};
