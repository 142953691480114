import React from 'react';
import { withTheme } from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

function SvgComponent(props) {
  const fill = props.fill || props.theme.palette.text.primary;

  return (
    <SvgIcon fontSize="inherit" width="25.006" height="22" viewBox="0 0 25.006 22" {...props}>
      <g id="icon-board" transform="translate(-83.631 -616.76)" opacity="0.6">
        <path 
          id="Path_15570"
          data-name="Path 15570"
          d="M90.206,618.783H84.631a1,1,0,0,1,0-2h5.575a1,1,0,0,1,0,2Z"
          fill={fill}
        />
        <path 
          id="Path_15571"
          data-name="Path 15571"
          d="M98.921,618.783H93.347a1,1,0,0,1,0-2h5.574a1,1,0,0,1,0,2Z"
          fill={fill}
        />
        <path 
          id="Path_15572" 
          data-name="Path 15572"
          d="M88.347,638.76H86.489a2.935,2.935,0,0,1-2.858-3v-12a2.936,2.936,0,0,1,2.858-3h1.858a2.936,2.936,0,0,
          1,2.859,3v12A2.935,2.935,0,0,1,88.347,638.76Zm-1.858-16a.939.939,0,0,0-.858,1v12a.938.938,0,0,0,.858,
          1h1.858a.939.939,0,0,0,.859-1v-12a.939.939,0,0,0-.859-1Z"
          fill={fill}
        />
        <path 
          id="Path_15573" 
          data-name="Path 15573"
          d="M97.063,633.76H95.205a2.934,2.934,0,0,1-2.858-3v-7a2.935,2.935,0,0,1,2.858-3h1.858a2.935,2.935,0,0,1,
          2.858,3v7A2.934,2.934,0,0,1,97.063,633.76Zm-1.858-11a.939.939,0,0,0-.858,1v7a.938.938,0,0,0,.858,1h1.858a.938.938,0,0,0,
          .858-1v-7a.939.939,0,0,0-.858-1Z"
          fill={fill}
        />
        <path 
          id="Path_15574"
          data-name="Path 15574"
          d="M107.637,618.76h-5.575a1,1,0,0,1,0-2h5.575a1,1,0,0,1,0,2Z"
          fill={fill}
        />
        <path 
          id="Path_15575" 
          data-name="Path 15575"
          d="M105.779,628.76H103.92a2.935,2.935,0,0,1-2.858-3v-2a2.936,2.936,0,0,1,2.858-3h1.859a2.936,2.936,0,0,
          1,2.858,3v2A2.935,2.935,0,0,1,105.779,628.76Zm-1.859-6a.939.939,0,0,0-.858,1v2a.938.938,0,0,0,.858,
          1h1.859a.938.938,0,0,0,.858-1v-2a.939.939,0,0,0-.858-1Z"
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
}

export default withTheme(SvgComponent);
