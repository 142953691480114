import React, { memo } from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';

import Loading from 'design-system/atoms/Loading/Loading';

import useDashboards from 'hooks/useDashboards';
import useOrganizations from 'hooks/useOrganizations';
import useCustomFields from 'hooks/useCustomFields';
import { BaseGrid } from 'containers/Grids';
import { getGridHeight } from 'containers/Grids/ProjectsGrid/helpers';
import useProjectsGridProps from 'containers/Grids/ProjectsGrid/hooks/useProjectsGridProps';
import useProjectsGridColumns from 'containers/Grids/ProjectsGrid/hooks/useProjectsGridColumns';
import agGridStylesOverride from 'containers/Grids/ProjectsGrid/styles/agGridStylesOverride';
import { StyledObjectiveType as GridObjectiveType } from 'containers/Grids/OkrsGrid/components/styled';

import { getRowStyle } from './helpers';

const isGroupedBy = (...groupByOptions) => (groupByOptions ?? [])?.find(g => g);

const DetailsTable = memo(
  ({
    wrapper: Wrapper,
    projectsSubFilters,
    displayLayer,
    allLevels,
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    visibleColumns,
    renderTitleHeaderLabel,
  }) => {
    const { hasBet, systemFields, integrations, hasProducts2 } = useOrganizations();
    const { customFields } = useCustomFields();

    const { hierarchicalData, defaultVisibleColumns, isDashboardLoading, isDashboardUninitialized } = useDashboards({
      projectsSubFilters,
      gridProps: { displayLayer, allLevels, selectedGroup1, selectedGroup2, selectedGroup3 },
    });

    const hasGroupBy = isGroupedBy(selectedGroup1, selectedGroup2, selectedGroup3);

    const gridColumns = visibleColumns ?? defaultVisibleColumns;
    const renderOkrIcon = params => {
      const { okrType } = params.data;

      if (!okrType) {
        return;
      }

      return <GridObjectiveType type={okrType} active />;
    };

    const projectsGridProps = useProjectsGridProps(gridColumns);
    const projectsGridColumns = useProjectsGridColumns({
      systemFields,
      hasBet,
      hasProducts2,
      customFields,
      integrations,
      visibleColumns: gridColumns,
      renderHierarchicalTitleLabel: renderTitleHeaderLabel,
      renderHierarchicalTitleIcon: renderOkrIcon,
      showChildrenCount: false,
      hasGroupBy,
    });

    const height = getGridHeight();

    const isLoading = isDashboardLoading || isDashboardUninitialized;

    const _renderLoading = () => (
      <LoadingWrapper style={{ height }}>
        <Loading />
      </LoadingWrapper>
    );

    const _renderTable = () => (
      <BaseGrid
        cssStyles={agGridStylesOverride}
        rowData={hierarchicalData}
        height={height}
        getRowStyle={getRowStyle}
        {...projectsGridColumns}
        {...projectsGridProps}
      />
    );

    return (
      <Wrapper>
        <TableCard elevation={2}>{isLoading ? _renderLoading() : _renderTable()}</TableCard>
      </Wrapper>
    );
  },
);

export default DetailsTable;

const TableCard = styled(Card)`
  &&&& {
    width: 100%;
    margin: auto;
    position: relative;
    border: 0.5px solid #dfe2e6;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
