import { createSelector } from 'reselect';
import { defaultTo } from 'ramda';

import { getData, isError, isLoading } from 'utils/store/thunk';
import deserializeCustomerRequest from 'store/customerRequests/helpers/deserialize';

import { FETCH_REQUESTS_MERGED } from './types';

const defaultAsEmptyArray = defaultTo([]);

// Slice state
export const getState = state => state.customerRequestMergedGrid;

// Operations
export const selectOperationsState = state => getState(state).operations || {};

export const selectRequestsMerged = createSelector(selectOperationsState, state =>
  defaultAsEmptyArray(getData(state, FETCH_REQUESTS_MERGED)?.data?.data).map(deserializeCustomerRequest),
);

export const selectRequestsMergedIsLoading = createSelector(selectOperationsState, state =>
  isLoading(state, FETCH_REQUESTS_MERGED),
);

export const selectRequestsMergedIsError = createSelector(selectOperationsState, state => isError(state, FETCH_REQUESTS_MERGED));
