import isEmpty from 'lodash/isEmpty';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import { defaultTo, prop, propOr, pathOr, not, pipe, isNil, head, equals, propEq } from 'ramda';

import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import { getAllProjectsFromGroups } from 'store/projects/helpers/groupSelectors';
import { getKeyResult1Data, getKeyResult2Data, getObjectiveCorpData, getObjectiveData } from './getOkrDataForIdeasGrid';

import {
  makeAddDefaultMetadataRoadmapName,
  formatMetadataRoadmapsForDisplay,
} from 'routes/Settings/hooks/helpers/useRoadmapsMetadata';

import theme from 'design-system/theme';
import { BET_PLACEHOLDER_ID, INITIATIVE_PLACEHOLDER_ID } from 'containers/IdeasList/IdeasList/New/constants';

const OBJECTIVE_CORP = 'objectiveCorp';
const OBJECTIVE = 'objective';
const KEY_RESULT_1 = 'keyResult';
const KEY_RESULT_2 = 'keyResult2';
const METRICS = 'metrics';
const DEFAULT_ROADMAP_COLOR = theme.palette.newLayout.microChipBackground.lightBlue;

const defaultToEmptyArray = defaultTo([]);
const defaultToEmptyObject = defaultTo({});
const getOkrDefaultMetric = pipe(prop(METRICS), defaultToEmptyArray, head, defaultToEmptyObject);

const layerToLabel = {
  [IDEA_LAYER]: 'idea',
  [INITIATIVE_LAYER]: 'initiative',
  [BET_LAYER]: 'bet',
};

const EMPTY_DATA_OBJECT = {
  [IDEA_LAYER]: [],
  [INITIATIVE_LAYER]: [],
  [BET_LAYER]: [],
};

const isGroup = prop('group');
const isIdea = propEq('layer', IDEA_LAYER);
const isInitiative = propEq('layer', INITIATIVE_LAYER);
const isBet = propEq('layer', BET_LAYER);

const getGroupObjectId = group => group.groupData[group.groupOption.key].id;
const getGroupId = groups => {
  const suffix = `group-${groups.length}`;

  return groups.reduce((acc, group) => `${acc}-${getGroupObjectId(group)}`, suffix);
};

const groupsAdapterForGrid = (groups, addParent = true) => {
  if (isEmpty(groups)) {
    return [];
  }

  return groups.reduce((res, group) => {
    const groupLevel2Elements = group?.elements?.some(item => item?.group)
      ? group.elements.map(item => ({
          ...item,
          id: getGroupId([group, item]),
          path: [getGroupId([group]), getGroupId([group, item])],
          type: item.groupOption.key,
          ...(addParent && { parent: group }),
        }))
      : [];

    const groupLevel3Elements = groupLevel2Elements?.some(group => group?.elements?.some(item => item.group))
      ? flatten(
          groupLevel2Elements.map(groupLevel2 =>
            flatten(
              groupLevel2.elements.map(item => ({
                ...item,
                id: getGroupId([group, groupLevel2, item]),
                path: [getGroupId([group]), getGroupId([group, groupLevel2]), getGroupId([group, groupLevel2, item])],
                type: item.groupOption.key,
                ...(addParent && { parent: groupLevel2 }),
              })),
            ),
          ),
        )
      : [];

    const adaptedGroup = {
      ...group,
      id: getGroupId([group]),
      path: [getGroupId([group])],
      type: group.groupOption.key,
    };

    return [...res, adaptedGroup, ...groupLevel2Elements, ...groupLevel3Elements];
  }, []);
};

const getWrapGroupOnId = useBet => (path, p, bet, initiative) => {
  let entry = p;

  if (useBet && bet && p.layer === IDEA_LAYER) {
    entry = bet;
  } else if ((!useBet || !bet) && initiative && p.layer === IDEA_LAYER) {
    entry = initiative;
  } else if (useBet && bet && p.layer === INITIATIVE_LAYER) {
    entry = bet;
  } else if (!useBet && initiative && p.layer === INITIATIVE_LAYER) {
    entry = initiative;
  }

  const projectGroupPath = p?.parentGroups
    ? p.parentGroups.reduce((acc, _, i) => [...acc, getGroupId(p.parentGroups.slice(0, i + 1))], [])
    : null;

  const entryPath = isEmpty(projectGroupPath) ? entry?.path : projectGroupPath;

  return [...(entryPath || []), ...path];
};

const isOkr = p => {
  if (p.groupData) {
    return (
      p.groupData.objective_corp_id || p.groupData.objective_id || p.groupData.key_result_1_id || p.groupData.key_result_2_id
    );
  }

  return false;
};

const getOkrType = p => {
  let result;

  if (p.groupData && p.groupData.objective_corp_id) {
    result = OBJECTIVE_CORP;
  } else if (p.groupData && p.groupData.objective_id) {
    result = OBJECTIVE;
  } else if (p.groupData && p.groupData.key_result_1_id) {
    result = KEY_RESULT_1;
  } else if (p.groupData && p.groupData.key_result_2_id) {
    result = KEY_RESULT_2;
  }

  return result;
};

const getDataToBeUsed = (singleLayer, displayLayer, allData, projectGroups) => {
  if (!isEmpty(projectGroups)) {
    return {
      ...EMPTY_DATA_OBJECT,
      ...groupBy(getAllProjectsFromGroups(projectGroups), project => project.layer),
    };
  }

  return singleLayer
    ? {
        ...EMPTY_DATA_OBJECT,
        [displayLayer]: allData[displayLayer],
      }
    : allData;
};

const isNotNil = pipe(isNil, not);
const getProp = propOr('');
const getOwnerName = pathOr('', ['owner', 'first_name']);
const getRoadmapId = prop('roadmap_id');

const isRoadmapLevel = (roadmap = {}) => {
  const roadmapId = getRoadmapId(roadmap);

  return isNil(roadmapId);
};

const isProduct1Level = (product = {}) => {
  const roadmapId = getRoadmapId(product);

  return isNotNil(roadmapId);
};

// same logic as objective drawer:
// only show products that had a specific click. We know that because in that case
// the product1 is on roadmap is not null

/**
 * function to exctrat the okr properties to be shown on ideas grid
 * they are extrated from okr object to the row root level
 *
 * @param {Object} row - object with AgGrid row information
 * @params {Object} okrType - the type of OKR
 * @params {Boolean} hasMetadataRoadmaps - flag to know if Roadmap is enabled
 */
const extractOkrDataForRootLevel = (row, okrType, hasMetadataRoadmaps, getDefaultRoadmapTitleForMetadataItem) => {
  let okr;

  if (okrType === OBJECTIVE_CORP) {
    okr = getObjectiveCorpData(row);
  } else if (okrType === OBJECTIVE) {
    okr = getObjectiveData(row);
  } else if (okrType === KEY_RESULT_1) {
    okr = getKeyResult1Data(row);
  } else if (okrType === KEY_RESULT_2) {
    okr = getKeyResult2Data(row);
  }

  if (!okr) return;

  // in the future an OKR can have multiple metrics. That's why this prop is an array.
  // For now we can just get the first element
  const metricSelected = getOkrDefaultMetric(okr);

  if (metricSelected && metricSelected.id) row.metric_id = metricSelected.id;

  const props = [
    'status_color',
    'description',
    'summary',
    'start_date',
    'end_date',
    'metrics',
    'metric_name',
    'metric_current',
    'metric_target',
    'metric_baseline',
  ];

  props.forEach(prop => (row[prop] = getProp(prop)(okr)));

  row.ownerName = getOwnerName(okr);
  row.okr_progress = getProp('progress')(okr);

  if (hasMetadataRoadmaps && getDefaultRoadmapTitleForMetadataItem) {
    const roadmaps = okr?.key_result_roadmaps || okr?.objective_roadmaps || [];

    const dataToExtractRoadmaps = {
      value: formatMetadataRoadmapsForDisplay(roadmaps),
      data: okr,
    };

    const formattedRoadmaps = makeAddDefaultMetadataRoadmapName(getDefaultRoadmapTitleForMetadataItem)(dataToExtractRoadmaps);

    // these objects are under the title prop to be possible to use the same column than ideas
    row.roadmapTitle = formattedRoadmaps
      .filter(roadmap => isRoadmapLevel(roadmap))
      .map(roadmap => ({ title: roadmap.title, color: roadmap.color }));
    row.product1Title = formattedRoadmaps
      .filter(product => isProduct1Level(product))
      .map(product => ({ title: product.title, color: product.color }));

    if (isEmpty(row.roadmapTitle)) {
      const defaultRoadmap = getDefaultRoadmapTitleForMetadataItem(okr);

      row.roadmapTitle = [{ title: defaultRoadmap, color: DEFAULT_ROADMAP_COLOR }];
    }
  }
};

const buildHierarchyData = project => ({
  parent_id: project?.id,
  [layerToLabel[project.layer]]: project,
  isHierarchy: true,
  layer: project.layer,
});

const byId = data =>
  (data || []).reduce((dict, obj) => {
    dict[obj.id] = obj;

    return dict;
  }, {});

const tagDataWithType = data =>
  Object.entries(data || {}).reduce(
    (acc, [layer, values]) => ({
      ...acc,
      [layer]: values.map(obj => ({ ...obj, type: layerToLabel[layer] })),
    }),
    {},
  );

const groomProjectRow = (row, { wrapGroupOnIdPath, idPath, parentBet, parentInitiative }) => ({
  ...row,
  path: wrapGroupOnIdPath(idPath, row, parentBet, parentInitiative),
  groupData: buildHierarchyData(row),
});

const groomWithoutParentRow = (title, layer, path) => ({
  title,
  groupData: buildHierarchyData({ layer }),
  id: path.join('+'),
  path,
});

export default (
  allData,
  displayLayer,
  initiativesLabel,
  betsLabel,
  singleLayer = false,
  projectGroups,
  hasMetadataRoadmaps,
  getDefaultRoadmapTitleForMetadataItem,
  addGroupParent = true,
) => {
  const useBet = displayLayer === BET_LAYER;

  const {
    [IDEA_LAYER]: ideas,
    [INITIATIVE_LAYER]: initiatives,
    [BET_LAYER]: bets,
  } = tagDataWithType(getDataToBeUsed(singleLayer, displayLayer, allData, projectGroups));

  const initiativesDict = byId(initiatives);
  const betsDict = byId(bets);

  const wrapGroupOnIdPath = getWrapGroupOnId(useBet);

  const groupsData = groupsAdapterForGrid(projectGroups, addGroupParent);

  // on group rows we need to extract the fields we want to show to the obj root level
  (groupsData || []).forEach(data => {
    if (data.group && isOkr(data)) {
      const okrType = getOkrType(data);

      extractOkrDataForRootLevel(data, okrType, hasMetadataRoadmaps, getDefaultRoadmapTitleForMetadataItem);
    }
  });

  const dataAndGroups = [...ideas, ...initiatives, ...bets, ...groupsData];

  return dataAndGroups
    .reduce((acc, row) => {
      row.title = row.title || '';

      if (isGroup(row)) {
        if (isOkr(row)) {
          row.okrType = getOkrType(row);
        }

        acc.push(row);

        return acc;
      }

      const withoutInitiativeTitle = `Without ${initiativesLabel}`;
      const withoutBetTitle = `Without ${betsLabel}`;

      const firstOfHierarchy = isBet(row) || (!useBet && isInitiative(row));

      // No hierarchy OR bet OR initiative if highest layer
      if (singleLayer || firstOfHierarchy) {
        return [...acc, groomProjectRow(row, { wrapGroupOnIdPath, idPath: [row.id] })];
      }

      // If initiative it may be under Without Bet group
      // We need to build it if so
      if (isInitiative(row) && !firstOfHierarchy) {
        const parentBet = row.parent_id && betsDict[row.parent_id];
        const idPath = [parentBet?.id || BET_PLACEHOLDER_ID, row?.id];

        const isWithoutBet = !parentBet;

        const withoutBetIdPath = isWithoutBet && wrapGroupOnIdPath([BET_PLACEHOLDER_ID], row);
        const withoutBetDoesNotExistForGroup = isWithoutBet && !acc.find(({ path }) => equals(path, withoutBetIdPath));
        const withoutBet =
          isWithoutBet && withoutBetDoesNotExistForGroup
            ? [groomWithoutParentRow(withoutBetTitle, BET_LAYER, withoutBetIdPath)]
            : [];

        return [...acc, ...withoutBet, groomProjectRow(row, { wrapGroupOnIdPath, idPath, parentBet })];
      }

      // If idea it may be under Without Bet AND/OR Without Initiative group
      // We need to build those if so
      if (isIdea(row)) {
        const parentInitiative = row.parent_id && initiativesDict[row.parent_id];
        let parentBet = null;

        if (useBet) {
          const betId = parentInitiative ? parentInitiative.parent_id : row.parent_id;

          parentBet = betId && betsDict[betId];
        }

        const idPath = [
          ...(useBet ? [parentBet?.id || BET_PLACEHOLDER_ID] : []),
          parentInitiative?.id || INITIATIVE_PLACEHOLDER_ID,
          row?.id,
        ];

        const isWithoutBet = !parentBet && useBet;
        const withoutBetIdPath = isWithoutBet && wrapGroupOnIdPath([BET_PLACEHOLDER_ID], parentInitiative || row);
        const withoutBetDoesNotExistForGroup = isWithoutBet && !acc.find(({ path }) => equals(path, withoutBetIdPath));
        const withoutBet =
          isWithoutBet && withoutBetDoesNotExistForGroup
            ? [groomWithoutParentRow(withoutBetTitle, BET_LAYER, withoutBetIdPath)]
            : [];

        const isWithoutInitiative = !parentInitiative;
        const withoutInitiativeIdPath =
          isWithoutInitiative &&
          wrapGroupOnIdPath(
            [...(useBet ? [parentBet?.id || BET_PLACEHOLDER_ID] : []), INITIATIVE_PLACEHOLDER_ID],
            row,
            parentBet,
          );
        const withoutInitiativeDoesNotExistForGroup =
          isWithoutInitiative && !acc.find(({ path }) => equals(path, withoutInitiativeIdPath));
        const withoutInitiative =
          isWithoutInitiative && withoutInitiativeDoesNotExistForGroup
            ? [groomWithoutParentRow(withoutInitiativeTitle, INITIATIVE_LAYER, withoutInitiativeIdPath)]
            : [];

        return [
          ...acc,
          ...withoutBet,
          ...withoutInitiative,
          groomProjectRow(row, { wrapGroupOnIdPath, idPath, parentBet, parentInitiative }),
        ];
      }

      return acc;
    }, [])
    .filter(Boolean);
};
