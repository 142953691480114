import React, { useState, useCallback } from 'react';

import CommentsLightbox from 'containers/CommentLightbox';

import RicePanel from 'containers/RicePanel/RicePanel';

const ProjectRicePanel = ({
  formData,
  reachScore,
  impactScore,
  confidenceScore,
  effortScore,
  onReachScoreChange,
  onImpactScoreChange,
  onConfidenceScoreChange,
  onEffortScoreChange,
  userCanEdit,
  saveProject,
}) => {
  const [commentsLightbox, setCommentsLightbox] = useState(null);
  const projectId = formData?.id;
  const onCommentsLightboxClick = useCallback(
    field => {
      setCommentsLightbox({ projectId, field });
    },
    [projectId, setCommentsLightbox],
  );

  return (
    <div>
      <RicePanel
        onSave={saveProject}
        reachScore={reachScore}
        impactScore={impactScore}
        confidenceScore={confidenceScore}
        effortScore={effortScore}
        onReachScoreChange={onReachScoreChange}
        onImpactScoreChange={onImpactScoreChange}
        onConfidenceScoreChange={onConfidenceScoreChange}
        onEffortScoreChange={onEffortScoreChange}
        userCanEdit={userCanEdit}
        onCommentsLightboxClick={onCommentsLightboxClick}
      />
      <CommentsLightbox
        isOpen={!!commentsLightbox}
        onClose={() => setCommentsLightbox(null)}
        projectId={commentsLightbox && commentsLightbox.projectId}
        field={commentsLightbox && commentsLightbox.field}
      />
    </div>
  );
};

export default ProjectRicePanel;
