import React, { useCallback, useState } from 'react';

import { NextButton, URLTextField, Wrapper } from './styles';
import { INTEGRATIONS_KEYS } from 'constants/integrations';

const URI_REGEX = /^(https:\/\/[^/]+)(?:\/|$)/;
const SITE_NAME_REGEX = /\/site\/([^/]+)/;

export default ({ integration, onClickNext }) => {
  const { integrationType, title: integrationLabel, integrationUriExpression } = integration;

  const [orgUri, setOrgUri] = useState('');
  const [siteName, setSiteName] = useState('');

  const _handleOnClick = useCallback(
    e => {
      e.preventDefault();

      if (!onClickNext || !orgUri) {
        return;
      }

      const additionalProps = siteName ? { siteName } : {};

      onClickNext(orgUri, additionalProps);
    },
    [orgUri],
  );

  const _handleURIInputChange = urlInput => {
    const uriMatch = urlInput.match(URI_REGEX);
    let uri = uriMatch && uriMatch[1];

    if (integrationType === INTEGRATIONS_KEYS.tableau) {
      /**
       * TODO - Tableau - additional props like "siteName" should be part of the integration config
       *  in order to have a dynamic behaviour.
       */
      const siteNameMatch = urlInput.match(SITE_NAME_REGEX);
      const siteName = siteNameMatch && siteNameMatch[1];

      if (siteName) {
        setSiteName(siteName);
      }
    }

    if (integrationUriExpression) {
      const expression = new RegExp(integrationUriExpression);
      const integrationMatch = urlInput.match(expression);

      uri = integrationMatch?.length ? integrationMatch[0] : uri;
    }

    setOrgUri(uri || urlInput);
  };

  return (
    <Wrapper>
      <URLTextField
        id="orgUri"
        label={`Insert your ${integrationLabel} organization URL`}
        value={orgUri}
        onChange={e => _handleURIInputChange(e.target.value)}
        margin="normal"
      />
      <NextButton type="submit" color="primary" onClick={_handleOnClick}>
        Next
      </NextButton>
    </Wrapper>
  );
};
