import React, { useCallback, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { tryCatch, F, replace, not } from 'ramda';

import FluidContainer from 'design-system/atoms/FluidContainer/index';
import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';
import FluidTextField from 'design-system/organisms/FluidTextField/index';
import FluidIntegrationHealthField from 'design-system/organisms/FluidIntegrationHealthField/index';
import FluidFieldGroup from 'design-system/molecules/FluidFieldGroup/index';
import FluidMultiSelectField from 'design-system/organisms/FluidMultiSelectField/index';
import FluidRoadmapMetadataSelectField from 'design-system/organisms/FluidRoadmapMetadataSelectField/index';
import FluidRoadmapObjectiveField from 'design-system/organisms/FluidRoadmapObjectiveField/index';
import UploadedFileActions from 'design-system/organisms/UploadedFileActions/index';
import FluidNumberField from 'design-system/organisms/FluidNumberField/index';
import FluidDateField from 'design-system/organisms/FluidDateField/index';

import { draftBackgroundColor } from 'design-system/constants/draft';

import FluidIdeaParentField from 'containers/FluidIdeaParentField';
import FluidMetadataAutocompleteField from 'containers/FluidMetadataAutocompleteField';
import FluidAppliesToAutocompleteField from 'shared/components/inputs/Autocomplete/formFields/FluidAppliesToAutocompleteField';
// eslint-disable-next-line max-len
import FluidAppliesToMultiSelectAutocompleteField from 'shared/components/inputs/Autocomplete/formFields/FluidAppliesToMultiSelectAutocompleteField';

import FluidOkrAutocompleteField from 'shared/components/inputs/Autocomplete/formFields/FluidOkrAutocompleteField';
import FluidByTeamAutocompleteField from 'shared/components/inputs/Autocomplete/formFields/FluidByTeamAutocompleteField';
import FluidTypeField from 'containers/FluidTypeField';
import FluidIntegrationField from 'containers/FluidIntegrationField';
import FluidMetadataTreeDropdown from 'containers/FluidMetadataTreeDropdown';
import FluidTimelineField from 'containers/FluidTimelineField';
import ProjectsMetricsForm from './ProjectsMetricsForm/ProjectsMetricsForm';

import formatDateTime from 'utils/dates/formatDateTime';
import 'rc-slider/assets/index.css';
import { getUserName, planningStageColors } from 'utils';

import { IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';

import customFieldGenerator from 'utils/customFields/customFieldGenerator';
import hasProjectIntegrations from './Integrations/utils/hasProjectIntegrations';
import LoadableUsersAutocomplete from 'containers/LoadableUsersAutocomplete';
import LoadableUsersMultiSelect from 'containers/LoadableMultiSelect/LoadableUsersMultiSelect';
import FluidIntegrationProgressField from 'containers/FluidIntegrationProgressField';

import useObjectiveAutocompletes from 'hooks/useObjectiveAutocompletes';
import useAllowToCreateNewMetadata from 'hooks/useAllowToCreateNewMetadata';
import { metadataKeys } from 'utils/metadata/metadataKeys';
import useMetadataTab from 'hooks/useMetadataTab';
import useCustomUserFields from 'hooks/useCustomUserFields';
import usePermissions from 'hooks/permissions/usePermissions';
import useAdditionalMetadataDropdownOptions from 'hooks/metadata/useAdditionalMetadataDropdownOptions';
import { MILESTONE } from 'constants/projects';

import {
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_PRODUCTS_2,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
  ROADMAPS,
  PRODUCTS,
  PRODUCTS_2,
  TIMEFRAMES,
  TIMEFRAMES_2,
  OBJECTIVES,
  KEY_RESULTS,
  KEY_RESULTS_2,
  THEMES,
  CATEGORIES,
  TEAMS,
  TEAMS_2,
  PERSONAS,
  LIFECYCLES,
  TYPE_TO_SEARCH_PLACEHOLDER,
  RESOURCE_TEAM,
} from 'constants/common';

const METADATA_TO_DISPLAY_KEYS = [
  ROADMAPS,
  PRODUCTS,
  PRODUCTS_2,
  TIMEFRAMES,
  TIMEFRAMES_2,
  OBJECTIVES,
  KEY_RESULTS,
  KEY_RESULTS_2,
  THEMES,
  CATEGORIES,
  TEAMS,
  TEAMS_2,
  PERSONAS,
  LIFECYCLES,
];

import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import RoadmapsTreeDropdown from './Components/RoadmapsTreeDropdown';
import useMetadataForDisplay from 'hooks/metadata/useMetadataForDisplay';
import useUploadAttachmentImage from 'hooks/files/useUploadAttachmentImage';
import useFilterMetadataBasedOnRoadmap from 'hooks/metadata/useFilterMetadataBasedOnRoadmap';

import { IDEAS_PERSONAS_PATH, IDEAS_LIFECYCLES_PATH } from 'constants/filters/pages';

import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const asciiEscapeUnderscore = replace(/&amp;amp;#95;/g, '_');
const safeJsonParse = tryCatch(JSON.parse, F);

const exist = Boolean;

// Builds the field with its definition. Left fields can now be set as column and right as row display
const buildField = (field, format, name) => {
  if (field instanceof Function) {
    return field({ format, name });
  }
  return field;
};

const urlByField = {
  [PERSONAS]: `/${IDEAS_PERSONAS_PATH}`,
  [LIFECYCLES]: `/${IDEAS_LIFECYCLES_PATH}`,
};

export default React.memo(
  ({
    isNew,
    formData,
    selectedProject,
    updateFormData,
    typeDisabled,
    metadata,
    hasHierarchy,
    hasBet,
    hasMetadataRoadmaps,
    hasPersonas,
    hasLifecycles,
    hasExternalKey,
    isDodActive,
    isParentDragon,
    isDodShareProjectsEnabled,
    changeMultiselectValue,
    createMultiselectOption,
    projectIntegrations,
    getSystemFieldName,
    onSave,
    gettingDataFields,
    parent,
    openProjectLightbox,
    orgIntegrations,
    dateRangeMode,
    changeDateRangeMode,
    onDetailsFocus,
    onDetailsBlur,
    getFileLink,
    handleDeleteLink,
    userCanEdit,
    fieldsConfiguration,
    selectedRoadmapVersion,
    hasMultiLevelPortfolioMetadata,
    openMetadataDrawer,
    hasAdvancedMetricReporting,
    toggleMetricsDialog,
    selectedMetricId,
    currentUser,
    hasProjectMetrics,
    fieldValidation,
    navigateToUrlAndCloseLightbox,
    onUpdateProjectPersonas,
    onUpdateProjectLifecycles,
    disabledFields,
  }) => {
    const {
      type,
      details,
      // planningStage,
      timeframeCorpTitle,
      timeframeTitle,
      timeframe2Title,
      phaseTitle,
      objectiveCorpTitle,
      objectiveTitle,
      priorityTitle,
      roadmapTitle,
      product1Title,
      product2Title,
      additionalRoadmaps,
      additionalProducts,
      additionalProducts2,
      keyResult1Title,
      keyResult2Title,
      progress,
      progress_type: progressType,
      status_summary: statusSummary,
      themeTitle,
      links,
      categoryTitle,
      layer,
      parent_id: parentId,
      customers: projectCustomers,
      tags: projectTags,
      watchers: projectWatchers,
      status_color: statusColor,
      status_color_auto: statusColorAuto,
      files,
      user1Name,
      user2Name,
      user3Name,
      user4Name,
      user5Name,
      user6Name,
      user7Name,
      customersCount,
      totalRevenue,
      inactiveRevenue,
      activeRevenue,
      additionalObjectives,
      additionalKeyResults,
      additionalKeyResults2,
      additionalTimeframes,
      additionalTimeframes2,
      additionalThemes,
      additionalCategories,
      additionalTeams,
      additionalTeams2,
      personas: projectPersonas,
      lifecycles: projectLifecycles,
      completed_date: completedDate,
      resourceTeam,
      owner_id: ownerId,
    } = formData;

    const { canView, canCreate } = usePermissions();

    // This is needed to escape mardkdown valid chars coming from Jira for instance
    const asciiEscapedDetails = useMemo(() => asciiEscapeUnderscore(details || ''), [details]);

    const { createdBy, created_at: createdAt, updated_at: updatedAt, roadmap_id: roadmapId } = selectedProject || {};

    const {
      bets,
      categories,
      customerOptions,
      customers,
      customFields,
      initiatives,
      objectivesCorp,
      objectiveOptions,
      objectiveOptions2,
      phases,
      priorities,
      tagOptions,
      tags,
      themes,
      timeframes,
      timeframesOptions,
      users,
      usersOptions,
      roadmapsOptions,
      personas,
      personasOptions,
      lifecycles,
      lifecyclesOptions,
      teamsOptions,
    } = metadata;

    const ownerName = useMemo(() => ownerId && getUserName(users.find(u => u.id === ownerId)), [ownerId, users]);

    const hasNewMetadataAutocomplete = canView(PERMISSION_FEATURES.newRoadmapMetadataAutocomplete);

    const formatAdditionalMetadataByRoadmap = hasMetadataRoadmaps && hasNewMetadataAutocomplete;

    const {
      additionalRoadmapOptions,
      additionalProducts1Options,
      additionalProducts2Options,
      additionalObjectivesOptions,
      additionalKeyResults1Options,
      additionalKeyResults2Options,
      additionalTimeframesOptions,
      additionalTimeframes2Options,
      additionalThemesOptions,
      additionalCategoriesOptions,
      additionalTeamsOptions,
      additionalTeams2Options,
    } = useAdditionalMetadataDropdownOptions(formatAdditionalMetadataByRoadmap, roadmapId);

    const {
      roadmaps: allRoadmaps,
      products: allProducts,
      products2: allProducts2,
      objectives: allObjectives,
      keyResults: allKeyResults,
      keyResults2: allKeyResults2,
      timeframes: allTimeframes,
      timeframes2: allTimeframes2,
      themes: allThemes,
      categories: allCategories,
      teams: allTeams,
      teams2: allTeams2,
      personas: allPersonas,
      lifecycles: allLifecycles,
    } = useMetadataForDisplay(METADATA_TO_DISPLAY_KEYS);

    const { imageUploadHandler, getBaseURLForAttachments } = useUploadAttachmentImage();

    const allowToCreateNewMetadata = useAllowToCreateNewMetadata();

    const { getCustomUserFieldByFieldKey } = useCustomUserFields();

    const { filterOptions } = useFilterMetadataBasedOnRoadmap(selectedProject);

    const filterObjectiveOptionsBasedOnRoadmap = useCallback(
      options => {
        const objectiveOptions = filterOptions(options);

        return objectiveOptions.map(objective => {
          const children = filterOptions(objective.children, 'key_result');

          return {
            ...objective,
            children: children.map(child => ({
              ...child,
              children: filterOptions(child.children, 'key_result'),
            })),
          };
        });
      },
      [filterOptions],
    );

    const isProject = type !== 'milestone';
    const isMilestone = type === 'milestone';
    const shouldRenderRevenueFields = IDEA_LAYER === layer;

    const _checkFieldDisabled = field => {
      if (!userCanEdit) return true;

      if ((disabledFields || []).includes(field)) return true;

      if (gettingDataFields) return gettingDataFields.includes(field);

      return false;
    };

    const phaseColor = selectedProject?.phase && planningStageColors[selectedProject.phase.planning_stage];

    const _fieldStyle = () => {
      if (selectedRoadmapVersion) {
        return { backgroundColor: draftBackgroundColor };
      }

      return {};
    };

    const update = newData => {
      return isNew ? updateFormData(newData) : onSave(true, false, { id: selectedProject.id, ...newData }, false);
    };

    const generateCustomFieldsFor = customFieldGenerator(
      formData,
      customFields,
      _checkFieldDisabled,
      update,
      null,
      null,
      fieldValidation,
    );

    const { okrSelectedArchived } = useObjectiveAutocompletes({
      selectedObjectiveTitle: objectiveTitle,
      selectedKeyResult1Title: keyResult1Title,
      selectedKeyResult2Title: keyResult2Title,
    });

    const hasIntegrations = hasProjectIntegrations(selectedProject, orgIntegrations, projectIntegrations);

    const _onLabelClick = useCallback(metadataKey => {
      return () => openMetadataDrawer(metadataKey);
    }, []);

    const useJSONOutput = useMemo(() => {
      // We are only showing new editor for jira integrated OR if for some reason the content of details is a json
      // Example: When unlink from jira and linking again, it would break if we showed the json in the other editor
      return Boolean(selectedProject?.jira || safeJsonParse(asciiEscapedDetails));
    }, [asciiEscapedDetails, selectedProject?.jira]);

    const baseURLForEditorAttachments = getBaseURLForAttachments(selectedProject);

    // Either has org integrations or is DoD V2 share projects enabled
    const shouldRenderIntegrationsField = isProject && (exist(orgIntegrations.length) || isDodShareProjectsEnabled);

    const { getMetadataNote } = useMetadataTab();

    const commonFields = {
      type: isNew && (
        <FluidTypeField
          key="type"
          value={isMilestone ? 'milestone' : layer}
          label="Type"
          onChange={value => {
            update({
              parent_id: null,
              type: value === 'milestone' ? value : 'project',
              layer: value === 'milestone' ? IDEA_LAYER : value,
            });
          }}
          optionDisabled={value => {
            return value === MILESTONE ? false : !canCreate(PERMISSION_RESOURCES.project, { project: { layer: value } });
          }}
          milestoneDisabled={typeDisabled}
          hasHierarchy={hasHierarchy}
          hasBet={hasBet}
          labels={{
            milestone: getSystemFieldName('milestone'),
            idea: getSystemFieldName('idea'),
            initiative: getSystemFieldName('initiative'),
            bet: getSystemFieldName('bet'),
          }}
        />
      ),
      links: ({ name }) => (
        <FluidRemirrorWYSIWYGEditor
          name={name}
          validation={fieldValidation}
          key="links"
          value={links}
          content={links}
          placeholder="Add some links here..."
          label="Links"
          onChange={value => update({ links: value })}
          disableToolbar
          disableEdit={_checkFieldDisabled('links')}
          imageUploadHandler={imageUploadHandler}
          baseURLForAttachments={baseURLForEditorAttachments}
          smallInput
        />
      ),
      timeline: ({ name }) => (
        <FluidTimelineField
          name={name}
          validation={fieldValidation}
          key="timeline"
          value={formData}
          isProject={isProject}
          isMilestone={isMilestone}
          selectedProject={selectedProject}
          projectIntegrations={projectIntegrations}
          isIdea={layer === IDEA_LAYER}
          dateRangeMode={dateRangeMode}
          onUpdateDateRangeMode={changeDateRangeMode}
          onChange={update}
          disableEdit={_checkFieldDisabled('timeline')}
          style={_fieldStyle('timeline')}
        />
      ),
      statusSummary: ({ name }) => (
        <FluidRemirrorWYSIWYGEditor
          name={name}
          validation={fieldValidation}
          key="statusSummary"
          value={statusSummary}
          content={statusSummary}
          placeholder="Write something here..."
          label="Status summary"
          disableEdit={_checkFieldDisabled('status_summary')}
          onChange={value => update({ status_summary: value })}
          imageUploadHandler={imageUploadHandler}
          baseURLForAttachments={baseURLForEditorAttachments}
        />
      ),
      integrations: ({ name }) =>
        shouldRenderIntegrationsField && (
          <FluidIntegrationField
            name={name}
            validation={fieldValidation}
            key="integrations"
            label="Integration"
            disableEdit={!userCanEdit}
            selectedProject={selectedProject || {}}
            value={formData}
            orgIntegrations={orgIntegrations}
            projectIntegrations={projectIntegrations}
            updateProject={async (data = {}, sendAll = false) => {
              // TODO: This must be refactored when when we add syncing between lightbox fields and lightbox store
              // After that we convert this methods to actions that will be called by the Integration components
              // using redux dispatch()
              const dataToSave = { ...formData, ...data };

              const result = await onSave(false, false, dataToSave, sendAll);

              return result;
            }}
          />
        ),
      details: ({ name }) => (
        <FluidRemirrorWYSIWYGEditor
          name={name}
          validation={fieldValidation}
          key={`details-${asciiEscapedDetails}`}
          content={asciiEscapedDetails}
          value={asciiEscapedDetails}
          placeholder="Write some details here..."
          label="Details"
          disableEdit={_checkFieldDisabled('details')}
          onChange={value => update({ details: value })}
          onFocus={() => onDetailsFocus && onDetailsFocus()}
          onBlur={() => onDetailsBlur && onDetailsBlur()}
          baseURLForAttachments={baseURLForEditorAttachments}
          imageUploadHandler={imageUploadHandler}
          outputJSON={useJSONOutput}
        />
      ),
      files: ({ name }) =>
        files &&
        !!files.length &&
        files.map((f, idx) => (
          <UploadedFileActions
            name={name}
            validation={fieldValidation}
            key={`file${idx + 1}`}
            title={f.name}
            getLink={() => getFileLink(f.id)}
            handleDeleteLink={() => handleDeleteLink({ id: f.id, name: f.name })}
          />
        )),

      customers: ({ name, format }) =>
        !hasNewMetadataAutocomplete ? (
          <FluidMultiSelectField
            name={name}
            validation={fieldValidation}
            key="customers"
            value={projectCustomers}
            label={getSystemFieldName('customer')}
            options={customers}
            optionsMapper={{ label: 'name', value: 'id' }}
            onChange={changeMultiselectValue('customers', update)}
            disableEdit={_checkFieldDisabled('customers')}
            onCreateOption={createMultiselectOption('customers')}
            groupOptions={customerOptions}
            format={format}
            hasGroups
            onLabelClick={_onLabelClick(metadataKeys.customer)}
            labelTooltip={getMetadataNote(metadataKeys.customer)}
          />
        ) : (
          <FluidAppliesToMultiSelectAutocompleteField
            name={name}
            validation={fieldValidation}
            key="customers"
            roadmapsKey="customer"
            label={getSystemFieldName('customer')}
            options={customerOptions}
            selectedOptions={projectCustomers}
            onChange={value => {
              const updateFunc = changeMultiselectValue('customers', update);

              updateFunc(value.map(opt => opt.id));
            }}
            disableEdit={_checkFieldDisabled('customers')}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.customer)}
            labelTooltip={getMetadataNote(metadataKeys.customer)}
            canAddNew={allowToCreateNewMetadata('customers')}
            onCreateNew={createMultiselectOption('customers')}
            selectedEntity={selectedProject}
            getSystemFieldName={getSystemFieldName}
            getOptionLabel={option => option.name}
            formatByRoadmap={false}
          />
        ),
      ...(shouldRenderRevenueFields
        ? {
            customersCount: ({ name }) => (
              <FluidNumberField
                name={name}
                validation={fieldValidation}
                key="customersCount"
                value={customersCount}
                label="# of Customers"
                format="row"
                disableEdit
              />
            ),
            totalRevenue: ({ name }) => (
              <FluidNumberField
                name={name}
                validation={fieldValidation}
                key="totalRevenue"
                value={totalRevenue}
                label="Total value"
                format="row"
                disableEdit
              />
            ),
            activeRevenue: ({ name }) => (
              <FluidNumberField
                name={name}
                validation={fieldValidation}
                key="activeRevenue"
                value={activeRevenue}
                label="Active value"
                format="row"
                disableEdit
              />
            ),
            inactiveRevenue: ({ name }) => (
              <FluidNumberField
                name={name}
                validation={fieldValidation}
                key="inactiveRevenue"
                value={inactiveRevenue}
                label="Inactive value"
                format="row"
                disableEdit
              />
            ),
          }
        : {}),

      tags: ({ name, format }) =>
        !hasNewMetadataAutocomplete ? (
          <FluidMultiSelectField
            name={name}
            validation={fieldValidation}
            key="tags"
            value={projectTags}
            label={getSystemFieldName('tag')}
            options={tags}
            optionsMapper={{ label: 'title', value: 'id' }}
            onChange={changeMultiselectValue('tags', update)}
            disableEdit={_checkFieldDisabled('tags')}
            onCreateOption={createMultiselectOption('tags')}
            groupOptions={tagOptions}
            format={format}
            hasGroups
            onLabelClick={_onLabelClick(metadataKeys.tag)}
            labelTooltip={getMetadataNote(metadataKeys.tag)}
          />
        ) : (
          <FluidAppliesToMultiSelectAutocompleteField
            name={name}
            validation={fieldValidation}
            key="tags"
            roadmapsKey="tag"
            label={getSystemFieldName('tag')}
            options={tagOptions}
            selectedOptions={projectTags}
            onChange={value => {
              const updateFunc = changeMultiselectValue('tags', update);

              updateFunc(value.map(opt => opt.id));
            }}
            disableEdit={_checkFieldDisabled('tags')}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.tag)}
            labelTooltip={getMetadataNote(metadataKeys.tag)}
            canAddNew={allowToCreateNewMetadata('tags')}
            onCreateNew={createMultiselectOption('tags')}
            selectedEntity={selectedProject}
            getSystemFieldName={getSystemFieldName}
            getOptionLabel={option => option.title}
          />
        ),
      watchers: ({ name, format }) =>
        !isNew && (
          <FluidMultiSelectField
            name={name}
            validation={fieldValidation}
            key="watchers"
            value={(projectWatchers || []).map(u => ({ ...u, name: getUserName(u) }))}
            label="Watchers"
            optionsMapper={{ label: 'name', value: 'id' }}
            disableEdit={_checkFieldDisabled('watchers')}
            onChange={changeMultiselectValue('watchers', update)}
            onCreateOption={createMultiselectOption('watchers')}
            inputComponent={LoadableUsersMultiSelect}
            mapValueToSave={v => v}
            format={format}
          />
        ),
      owner: ({ name, format }) =>
        !hasNewMetadataAutocomplete ? (
          <FluidMetadataAutocompleteField
            name={name}
            validation={fieldValidation}
            key="users"
            value={ownerName}
            label="Owner"
            metadata="users"
            onChange={value => update({ owner_id: value })}
            disableEdit={_checkFieldDisabled('ownerName')}
            style={_fieldStyle('ownerName')}
            format={format}
            autocompleteComponent={LoadableUsersAutocomplete}
            autocompleteProps={{
              suggestions: users,
            }}
          />
        ) : (
          <FluidByTeamAutocompleteField
            key="users"
            name={name}
            label={getSystemFieldName('owner')}
            value={ownerName}
            validation={fieldValidation}
            options={usersOptions || []}
            onChange={user => update({ owner_id: user?.id || null })}
            disableEdit={_checkFieldDisabled('ownerName')}
            style={_fieldStyle('ownerName')}
            getSystemFieldName={getSystemFieldName}
            format="row"
            withNullOption
            focusOnInit
          />
        ),
      user1: ({ name, format }) =>
        getCustomUserFieldByFieldKey('user_1_id') && (
          <FluidMetadataAutocompleteField
            name={name}
            validation={fieldValidation}
            key="user1"
            value={user1Name}
            label={getSystemFieldName('user1')}
            metadata="user1"
            onChange={value => update({ user_1_id: value })}
            disableEdit={_checkFieldDisabled('user1Name')}
            style={_fieldStyle('user1Name')}
            format={format}
            autocompleteComponent={LoadableUsersAutocomplete}
            autocompleteProps={{
              suggestions: users,
            }}
          />
        ),
      user2: ({ name, format }) =>
        getCustomUserFieldByFieldKey('user_2_id') && (
          <FluidMetadataAutocompleteField
            name={name}
            validation={fieldValidation}
            key="user2"
            value={user2Name}
            label={getSystemFieldName('user2')}
            metadata="user2"
            onChange={value => update({ user_2_id: value })}
            disableEdit={_checkFieldDisabled('user2Name')}
            style={_fieldStyle('user2Name')}
            format={format}
            autocompleteComponent={LoadableUsersAutocomplete}
            autocompleteProps={{
              suggestions: users,
            }}
          />
        ),
      user3: ({ name, format }) =>
        getCustomUserFieldByFieldKey('user_3_id') && (
          <FluidMetadataAutocompleteField
            name={name}
            validation={fieldValidation}
            key="user3"
            value={user3Name}
            label={getSystemFieldName('user3')}
            metadata="user3"
            onChange={value => update({ user_3_id: value })}
            disableEdit={_checkFieldDisabled('user3Name')}
            style={_fieldStyle('user3Name')}
            format={format}
            autocompleteComponent={LoadableUsersAutocomplete}
            autocompleteProps={{
              suggestions: users,
              placeholder: TYPE_TO_SEARCH_PLACEHOLDER,
            }}
          />
        ),
      user4: ({ name, format }) =>
        getCustomUserFieldByFieldKey('user_4_id') && (
          <FluidMetadataAutocompleteField
            name={name}
            validation={fieldValidation}
            key="user4"
            value={user4Name}
            label={getSystemFieldName('user4')}
            metadata="user4"
            onChange={value => update({ user_4_id: value })}
            disableEdit={_checkFieldDisabled('user4Name')}
            style={_fieldStyle('user4Name')}
            format={format}
            autocompleteComponent={LoadableUsersAutocomplete}
            autocompleteProps={{
              suggestions: users,
              placeholder: TYPE_TO_SEARCH_PLACEHOLDER,
            }}
          />
        ),
      user5: ({ name, format }) =>
        getCustomUserFieldByFieldKey('user_5_id') && (
          <FluidMetadataAutocompleteField
            name={name}
            validation={fieldValidation}
            key="user5"
            value={user5Name}
            label={getSystemFieldName('user5')}
            metadata="user5"
            onChange={value => update({ user_5_id: value })}
            disableEdit={_checkFieldDisabled('user5Name')}
            style={_fieldStyle('user5Name')}
            format={format}
            autocompleteComponent={LoadableUsersAutocomplete}
            autocompleteProps={{
              suggestions: users,
              placeholder: TYPE_TO_SEARCH_PLACEHOLDER,
            }}
          />
        ),
      user6: ({ name, format }) =>
        getCustomUserFieldByFieldKey('user_6_id') && (
          <FluidMetadataAutocompleteField
            name={name}
            validation={fieldValidation}
            key="user6"
            value={user6Name}
            label={getSystemFieldName('user6')}
            metadata="user6"
            onChange={value => update({ user_6_id: value })}
            disableEdit={_checkFieldDisabled('user6Name')}
            style={_fieldStyle('user6Name')}
            format={format}
            autocompleteComponent={LoadableUsersAutocomplete}
            autocompleteProps={{
              suggestions: users,
            }}
          />
        ),
      user7: ({ name, format }) =>
        getCustomUserFieldByFieldKey('user_7_id') && (
          <FluidMetadataAutocompleteField
            name={name}
            validation={fieldValidation}
            key="user7"
            value={user7Name}
            label={getSystemFieldName('user7')}
            metadata="user7"
            onChange={value => update({ user_7_id: value })}
            disableEdit={_checkFieldDisabled('user7Name')}
            style={_fieldStyle('user7Name')}
            format={format}
            autocompleteComponent={LoadableUsersAutocomplete}
            autocompleteProps={{
              suggestions: users,
            }}
          />
        ),
      priority: ({ name, format }) => (
        <>
          {hasMetadataRoadmaps && !hasNewMetadataAutocomplete ? (
            <FluidRoadmapMetadataSelectField
              name={name}
              validation={fieldValidation}
              key="priorities"
              value={priorityTitle}
              label={getSystemFieldName('priority')}
              metadata="priorities"
              onChange={priority => update({ priorityTitle: priority })}
              options={priorities || []}
              disableEdit={_checkFieldDisabled('priorityTitle')}
              style={_fieldStyle('priorityTitle')}
              roadmapsKey="priority"
              format={format}
              addNew={allowToCreateNewMetadata('priorities')}
              onLabelClick={_onLabelClick(metadataKeys.priority)}
              labelTooltip={getMetadataNote(metadataKeys.priority)}
            />
          ) : null}
          {hasNewMetadataAutocomplete ? (
            <FluidAppliesToAutocompleteField
              key="priorities"
              name={name}
              label={getSystemFieldName('priority')}
              value={[priorityTitle]}
              validation={fieldValidation}
              selectedEntity={selectedProject}
              levelOneFieldName="priority"
              options={priorities || []}
              onChange={update}
              disableEdit={_checkFieldDisabled('priorityTitle')}
              roadmapsKey="priority"
              onLabelClick={_onLabelClick(metadataKeys.priority)}
              labelTooltip={getMetadataNote(metadataKeys.priority)}
              getSystemFieldName={getSystemFieldName}
              canAddNew={allowToCreateNewMetadata('priorities')}
              format="row"
              withNullOption
              focusOnInit
            />
          ) : null}
          {!hasMetadataRoadmaps && !hasNewMetadataAutocomplete ? (
            <FluidMetadataAutocompleteField
              name={name}
              validation={fieldValidation}
              key="priorities"
              value={priorityTitle}
              label={getSystemFieldName('priority')}
              metadata="priorities"
              onChange={priority => update({ priorityTitle: priority })}
              options={priorities || []}
              disableEdit={_checkFieldDisabled('priorityTitle')}
              style={_fieldStyle('priorityTitle')}
              format={format}
              onLabelClick={_onLabelClick(metadataKeys.priority)}
              labelTooltip={getMetadataNote(metadataKeys.priority)}
            />
          ) : null}
        </>
      ),

      timeframes: ({ name }) =>
        !hasNewMetadataAutocomplete ? (
          <FluidMetadataTreeDropdown
            name={name}
            validation={fieldValidation}
            key="timeframes"
            selectedProject={selectedProject}
            value={
              canView(PERMISSION_RESOURCES.timeframeCorp)
                ? [timeframeCorpTitle, timeframeTitle, timeframe2Title]
                : [timeframeTitle, timeframe2Title]
            }
            label={
              canView(PERMISSION_RESOURCES.timeframeCorp) ? getSystemFieldName('timeframeCorp') : getSystemFieldName('timeframe')
            }
            metadata="timeframes"
            onChange={update}
            options={timeframes || []}
            disableEdit={_checkFieldDisabled('timeframeTitle')}
            style={_fieldStyle('timeframeTitle')}
            roadmapsKey="timeframe"
            format="row"
            levelZeroFieldName={canView(PERMISSION_RESOURCES.timeframeCorp) ? 'timeframeCorp' : null}
            levelOneFieldName="timeframe"
            levelTwoFieldName="timeframe2"
            isMultilevel={hasMultiLevelPortfolioMetadata || canView(PERMISSION_RESOURCES.timeframeCorp)}
            onLabelClick={_onLabelClick(metadataKeys.timeframe)}
            labelTooltip={getMetadataNote(metadataKeys.timeframe)}
          />
        ) : (
          <FluidAppliesToAutocompleteField
            key="timeframes"
            name={name}
            label={
              canView(PERMISSION_RESOURCES.timeframeCorp) ? getSystemFieldName('timeframeCorp') : getSystemFieldName('timeframe')
            }
            value={
              canView(PERMISSION_RESOURCES.timeframeCorp)
                ? [timeframeCorpTitle, timeframeTitle, timeframe2Title]
                : [timeframeTitle, timeframe2Title]
            }
            validation={fieldValidation}
            onChange={update}
            disableEdit={_checkFieldDisabled('timeframeTitle')}
            style={_fieldStyle('timeframeTitle')}
            onLabelClick={_onLabelClick(metadataKeys.timeframe)}
            labelTooltip={getMetadataNote(metadataKeys.timeframe)}
            format="row"
            selectedEntity={selectedProject}
            levelZeroFieldName={canView(PERMISSION_RESOURCES.timeframeCorp) ? 'timeframeCorp' : null}
            levelOneFieldName="timeframe"
            levelTwoFieldName="timeframe2"
            options={timeframesOptions || []}
            roadmapsKey="timeframe"
            getSystemFieldName={getSystemFieldName}
            canAddNew={allowToCreateNewMetadata('timeframes')}
            withNullOption
            focusOnInit
          />
        ),
      additionalTimeframes: ({ name, format }) =>
        !hasNewMetadataAutocomplete ? (
          <FluidMultiSelectField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_TIMEFRAMES}
            value={additionalTimeframes}
            label={`Additional ${getSystemFieldName('timeframe', true)}`}
            options={additionalTimeframesOptions}
            allValues={allTimeframes}
            optionsMapper={{ label: 'title', value: 'id' }}
            onChange={changeMultiselectValue(ADDITIONAL_TIMEFRAMES, update)}
            disableEdit={_checkFieldDisabled(ADDITIONAL_TIMEFRAMES)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.timeframe)}
            labelTooltip={getMetadataNote(metadataKeys.timeframe)}
            hideCreateOption
          />
        ) : (
          <FluidAppliesToMultiSelectAutocompleteField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_TIMEFRAMES}
            roadmapsKey={ADDITIONAL_TIMEFRAMES}
            label={`Additional ${getSystemFieldName('timeframe', true)}`}
            options={additionalTimeframesOptions}
            selectedOptions={additionalTimeframes}
            onChange={value => {
              const updateFunc = changeMultiselectValue(ADDITIONAL_TIMEFRAMES, update);

              updateFunc(value.map(opt => opt.id));
            }}
            disableEdit={_checkFieldDisabled(ADDITIONAL_TIMEFRAMES)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.timeframe)}
            labelTooltip={getMetadataNote(metadataKeys.timeframe)}
            canAddNew={false}
            selectedEntity={selectedProject}
            getSystemFieldName={getSystemFieldName}
            getOptionLabel={option => option.title}
          />
        ),
      additionalTimeframes2: ({ name, format }) =>
        !hasNewMetadataAutocomplete ? (
          <FluidMultiSelectField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_TIMEFRAMES_2}
            value={additionalTimeframes2}
            label={`Additional ${getSystemFieldName('timeframe2', true)}`}
            options={additionalTimeframes2Options}
            allValues={allTimeframes2}
            optionsMapper={{ label: 'title', value: 'id' }}
            onChange={changeMultiselectValue(ADDITIONAL_TIMEFRAMES_2, update)}
            disableEdit={_checkFieldDisabled(ADDITIONAL_TIMEFRAMES_2)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.timeframe)}
            labelTooltip={getMetadataNote(metadataKeys.timeframe)}
            hideCreateOption
          />
        ) : (
          <FluidAppliesToMultiSelectAutocompleteField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_TIMEFRAMES_2}
            roadmapsKey={ADDITIONAL_TIMEFRAMES_2}
            label={`Additional ${getSystemFieldName('timeframe2', true)}`}
            options={additionalTimeframes2Options}
            selectedOptions={additionalTimeframes2}
            onChange={value => {
              const updateFunc = changeMultiselectValue(ADDITIONAL_TIMEFRAMES_2, update);

              updateFunc(value.map(opt => opt.id));
            }}
            disableEdit={_checkFieldDisabled(ADDITIONAL_TIMEFRAMES_2)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.timeframe)}
            labelTooltip={getMetadataNote(metadataKeys.timeframe)}
            canAddNew={false}
            selectedEntity={selectedProject}
            getSystemFieldName={getSystemFieldName}
            getOptionLabel={option => option.title}
          />
        ),
      health: ({ name, format }) => (
        <FluidIntegrationHealthField
          name={name}
          validation={fieldValidation}
          key="health"
          value={statusColor}
          label="Health"
          onChange={value => update({ status_color: value, status_color_auto: false })}
          selectedProject={selectedProject}
          statusColorAuto={statusColorAuto}
          changeStatusColorType={value => update({ status_color_auto: value === 'auto' })}
          format={format}
          disableEdit={_checkFieldDisabled('health')}
        />
      ),
      progress: ({ name, format }) =>
        isProject && (
          <FluidIntegrationProgressField
            name={name}
            validation={fieldValidation}
            key="progress"
            value={progress}
            label="Progress"
            onChange={value => update({ progress: value })}
            format={format}
            hasProjectIntegrations={hasIntegrations}
            projectIntegrations={projectIntegrations}
            canEditProgressText={selectedProject?.progress_type === 'manual'}
            changeProgressType={value => {
              let progressValue = (selectedProject || {}).progress || 0;

              if (!hasIntegrations) {
                update({ progress_type: value });
                return;
              }

              const jiraProgress = value === 'auto';

              if (jiraProgress && hasIntegrations && selectedProject.integrationProgress) {
                progressValue = selectedProject.integrationProgress.progress;
              }

              update({ jira_progress: jiraProgress, progress_type: value });
              setTimeout(() => updateFormData({ progress: progressValue }), 100);
            }}
            selectedProject={selectedProject}
            progressType={progressType}
            disableEdit={_checkFieldDisabled('progress')}
            disableEditableText={_checkFieldDisabled('progress')}
          />
        ),
      phases: ({ name }) => (
        <>
          {hasMetadataRoadmaps && !hasNewMetadataAutocomplete ? (
            <FluidRoadmapMetadataSelectField
              name={name}
              validation={fieldValidation}
              key="phases"
              value={phaseTitle}
              label={getSystemFieldName('phase')}
              metadata="phases"
              onChange={phase => update({ phaseTitle: phase })}
              options={phases || []}
              disableEdit={_checkFieldDisabled('phaseTitle')}
              color={phaseColor || null}
              roadmapsKey="phase"
              format="row"
              addNew={allowToCreateNewMetadata('phases')}
              onLabelClick={_onLabelClick(metadataKeys.phase)}
              labelTooltip={getMetadataNote(metadataKeys.phase)}
              disableSaveEmptyOption
            />
          ) : null}
          {hasNewMetadataAutocomplete ? (
            <FluidAppliesToAutocompleteField
              key="phases"
              name={name}
              label={getSystemFieldName('phase')}
              value={[phaseTitle]}
              validation={fieldValidation}
              selectedEntity={selectedProject}
              levelOneFieldName="phase"
              options={phases || []}
              onChange={update}
              disableEdit={_checkFieldDisabled('phaseTitle')}
              color={phaseColor || null}
              roadmapsKey="phase"
              onLabelClick={_onLabelClick(metadataKeys.phase)}
              labelTooltip={getMetadataNote(metadataKeys.phase)}
              getSystemFieldName={getSystemFieldName}
              canAddNew={allowToCreateNewMetadata('phases')}
              format="row"
              focusOnInit
            />
          ) : null}
          {!hasMetadataRoadmaps && !hasNewMetadataAutocomplete ? (
            <FluidMetadataAutocompleteField
              name={name}
              validation={fieldValidation}
              key="phases"
              value={phaseTitle}
              label={getSystemFieldName('phase')}
              metadata="phases"
              onChange={data => update({ phaseTitle: data })}
              options={phases || []}
              disableEdit={_checkFieldDisabled('phaseTitle')}
              color={phaseColor || null}
              format="row"
              onLabelClick={_onLabelClick(metadataKeys.phase)}
              labelTooltip={getMetadataNote(metadataKeys.phase)}
            />
          ) : null}
        </>
      ),

      parent: ({ name, format }) =>
        [IDEA_LAYER, ...(hasBet ? [INITIATIVE_LAYER] : [])].includes(layer) &&
        hasHierarchy && (
          <FluidIdeaParentField
            name={name}
            validation={fieldValidation}
            key="parent"
            label="Parent"
            value={parentId}
            parent={parent}
            selectedProject={selectedProject}
            hasHierarchy={hasHierarchy}
            layer={layer}
            initiatives={initiatives}
            bets={bets}
            onChange={update}
            openProjectLightbox={openProjectLightbox}
            format={format}
            disableEdit={_checkFieldDisabled('parent')}
          />
        ),
      allObjectives: ({ name, format }) => (
        <>
          {canView(PERMISSION_RESOURCES.objectiveCorp) && (
            <FluidRoadmapMetadataSelectField
              name={name}
              validation={fieldValidation}
              key="objectivesCorp"
              value={objectiveCorpTitle}
              label={getSystemFieldName('objectiveCorp')}
              metadata="objectivesCorp"
              onChange={data => update({ objectiveCorpTitle: data })}
              options={objectivesCorp || []}
              disableEdit={_checkFieldDisabled('objectivesTitle')}
              roadmapsKey="objectiveCorp"
              format="row"
              addNew={isParentDragon}
              onLabelClick={_onLabelClick(metadataKeys.objective)}
              labelTooltip={getMetadataNote(metadataKeys.objective)}
            />
          )}
          {!hasNewMetadataAutocomplete ? (
            <FluidRoadmapObjectiveField
              name={name}
              validation={fieldValidation}
              key="objectives"
              value={[objectiveTitle, keyResult1Title, keyResult2Title]}
              label={getSystemFieldName('objective')}
              metadata="objectives"
              onChange={update}
              options={objectiveOptions || []}
              disableEdit={_checkFieldDisabled('objectiveTitle')}
              roadmapsKey="objective"
              format="row"
              selectedProject={selectedProject}
              okrSelectedArchived={okrSelectedArchived}
              onLabelClick={_onLabelClick(metadataKeys.objective)}
              labelTooltip={getMetadataNote(metadataKeys.objective)}
              filterOptionsFunc={filterObjectiveOptionsBasedOnRoadmap}
            />
          ) : (
            <FluidOkrAutocompleteField
              key="objectives"
              name={name}
              value={[objectiveTitle, keyResult1Title, keyResult2Title]}
              label={getSystemFieldName('objective')}
              validation={fieldValidation}
              selectedEntity={selectedProject}
              options={objectiveOptions2 || []}
              onChange={update}
              disableEdit={_checkFieldDisabled('objectiveTitle')}
              roadmapsKey="objective"
              onLabelClick={_onLabelClick(metadataKeys.objective)}
              labelTooltip={getMetadataNote(metadataKeys.objective)}
              getSystemFieldName={getSystemFieldName}
              isArchived={okrSelectedArchived}
              canAddNew={allowToCreateNewMetadata('objectives')}
              format="row"
              withNullOption
              focusOnInit
            />
          )}
        </>
      ),
      additionalObjectives: ({ name, format }) =>
        !hasNewMetadataAutocomplete ? (
          <FluidMultiSelectField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_OBJECTIVES}
            value={additionalObjectives}
            label={`Additional ${getSystemFieldName('objective', true)}`}
            options={additionalObjectivesOptions}
            allValues={allObjectives}
            optionsMapper={{ label: 'title', value: 'id' }}
            onChange={changeMultiselectValue(ADDITIONAL_OBJECTIVES, update)}
            disableEdit={_checkFieldDisabled(ADDITIONAL_OBJECTIVES)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.objective)}
            labelTooltip={getMetadataNote(metadataKeys.objective)}
            hideCreateOption
          />
        ) : (
          <FluidAppliesToMultiSelectAutocompleteField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_OBJECTIVES}
            roadmapsKey={ADDITIONAL_OBJECTIVES}
            label={`Additional ${getSystemFieldName('objective', true)}`}
            options={additionalObjectivesOptions}
            selectedOptions={additionalObjectives}
            onChange={value => {
              const updateFunc = changeMultiselectValue(ADDITIONAL_OBJECTIVES, update);

              updateFunc(value.map(opt => opt.id));
            }}
            disableEdit={_checkFieldDisabled(ADDITIONAL_OBJECTIVES)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.objective)}
            labelTooltip={getMetadataNote(metadataKeys.objective)}
            canAddNew={false}
            selectedEntity={selectedProject}
            getSystemFieldName={getSystemFieldName}
            getOptionLabel={option => option.title}
          />
        ),
      additionalKeyResults: ({ name, format }) =>
        !hasNewMetadataAutocomplete ? (
          <FluidMultiSelectField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_KEY_RESULTS}
            value={additionalKeyResults}
            label={`Additional ${getSystemFieldName('keyResult1', true)}`}
            options={additionalKeyResults1Options}
            allValues={allKeyResults}
            optionsMapper={{ label: 'title', value: 'id' }}
            onChange={changeMultiselectValue(ADDITIONAL_KEY_RESULTS, update)}
            disableEdit={_checkFieldDisabled(ADDITIONAL_KEY_RESULTS)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.objective)}
            labelTooltip={getMetadataNote(metadataKeys.objective)}
            hideCreateOption
          />
        ) : (
          <FluidAppliesToMultiSelectAutocompleteField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_KEY_RESULTS}
            roadmapsKey={ADDITIONAL_KEY_RESULTS}
            label={`Additional ${getSystemFieldName('keyResult1', true)}`}
            options={additionalKeyResults1Options}
            selectedOptions={additionalKeyResults}
            onChange={value => {
              const updateFunc = changeMultiselectValue(ADDITIONAL_KEY_RESULTS, update);

              updateFunc(value.map(opt => opt.id));
            }}
            disableEdit={_checkFieldDisabled(ADDITIONAL_KEY_RESULTS)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.objective)}
            labelTooltip={getMetadataNote(metadataKeys.objective)}
            canAddNew={false}
            selectedEntity={selectedProject}
            getSystemFieldName={getSystemFieldName}
            getOptionLabel={option => option.title}
          />
        ),
      additionalKeyResults2: ({ name, format }) =>
        !hasNewMetadataAutocomplete ? (
          <FluidMultiSelectField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_KEY_RESULTS_2}
            value={additionalKeyResults2}
            label={`Additional ${getSystemFieldName('keyResult2', true)}`}
            options={additionalKeyResults2Options}
            allValues={allKeyResults2}
            optionsMapper={{ label: 'title', value: 'id' }}
            onChange={changeMultiselectValue(ADDITIONAL_KEY_RESULTS_2, update)}
            disableEdit={_checkFieldDisabled(ADDITIONAL_KEY_RESULTS_2)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.objective)}
            labelTooltip={getMetadataNote(metadataKeys.objective)}
            hideCreateOption
          />
        ) : (
          <FluidAppliesToMultiSelectAutocompleteField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_KEY_RESULTS_2}
            roadmapsKey={ADDITIONAL_KEY_RESULTS_2}
            label={`Additional ${getSystemFieldName('keyResult2', true)}`}
            options={additionalKeyResults2Options}
            selectedOptions={additionalKeyResults2}
            onChange={value => {
              const updateFunc = changeMultiselectValue(ADDITIONAL_KEY_RESULTS_2, update);

              updateFunc(value.map(opt => opt.id));
            }}
            disableEdit={_checkFieldDisabled(ADDITIONAL_KEY_RESULTS_2)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.objective)}
            labelTooltip={getMetadataNote(metadataKeys.objective)}
            canAddNew={false}
            selectedEntity={selectedProject}
            getSystemFieldName={getSystemFieldName}
            getOptionLabel={option => option.title}
          />
        ),
      roadmaps: ({ name }) => (
        <RoadmapsTreeDropdown
          name={name}
          validation={fieldValidation}
          key="roadmaps"
          selectedProject={selectedProject}
          value={[roadmapTitle, product1Title, product2Title]}
          label={getSystemFieldName('roadmap')}
          metadata="roadmaps"
          onChange={update}
          options={roadmapsOptions || []}
          disableEdit={_checkFieldDisabled('roadmapsTitle')}
          roadmapsKey="roadmaps"
          format="row"
          onLabelClick={_onLabelClick(metadataKeys.roadmap)}
          labelTooltip={getMetadataNote(metadataKeys.roadmap)}
        />
      ),
      additionalRoadmaps: ({ name, format }) => (
        <FluidMultiSelectField
          name={name}
          validation={fieldValidation}
          key={ADDITIONAL_ROADMAPS}
          value={additionalRoadmaps}
          label={`Additional ${getSystemFieldName('roadmap', true)}`}
          options={additionalRoadmapOptions}
          allValues={allRoadmaps}
          optionsMapper={{ label: 'title', value: 'id' }}
          onChange={changeMultiselectValue(ADDITIONAL_ROADMAPS, update)}
          disableEdit={_checkFieldDisabled(ADDITIONAL_ROADMAPS)}
          format={format}
          onLabelClick={_onLabelClick(metadataKeys.roadmap)}
          labelTooltip={getMetadataNote(metadataKeys.roadmap)}
          hideCreateOption
        />
      ),
      additionalProducts: ({ name, format }) => (
        <FluidMultiSelectField
          name={name}
          validation={fieldValidation}
          key={ADDITIONAL_PRODUCTS}
          value={additionalProducts}
          label={`Additional ${getSystemFieldName('product1', true)}`}
          options={additionalProducts1Options}
          allValues={allProducts}
          optionsMapper={{ label: 'title', value: 'id' }}
          onChange={changeMultiselectValue(ADDITIONAL_PRODUCTS, update)}
          disableEdit={_checkFieldDisabled(ADDITIONAL_PRODUCTS)}
          format={format}
          onLabelClick={_onLabelClick(metadataKeys.roadmap)}
          labelTooltip={getMetadataNote(metadataKeys.roadmap)}
          hideCreateOption
        />
      ),
      additionalProducts2: ({ name, format }) => (
        <FluidMultiSelectField
          name={name}
          validation={fieldValidation}
          key={ADDITIONAL_PRODUCTS_2}
          value={additionalProducts2}
          label={`Additional ${getSystemFieldName('product2', true)}`}
          options={additionalProducts2Options}
          allValues={allProducts2}
          optionsMapper={{ label: 'title', value: 'id' }}
          onChange={changeMultiselectValue(ADDITIONAL_PRODUCTS_2, update)}
          disableEdit={_checkFieldDisabled(ADDITIONAL_PRODUCTS_2)}
          format={format}
          onLabelClick={_onLabelClick(metadataKeys.roadmap)}
          labelTooltip={getMetadataNote(metadataKeys.roadmap)}
          hideCreateOption
        />
      ),
      themes: ({ name, format }) => (
        <>
          {hasMetadataRoadmaps && !hasNewMetadataAutocomplete ? (
            <FluidRoadmapMetadataSelectField
              name={name}
              validation={fieldValidation}
              key="themes"
              value={themeTitle}
              label={getSystemFieldName('theme')}
              metadata="themes"
              onChange={theme => update({ themeTitle: theme })}
              options={themes || []}
              disableEdit={_checkFieldDisabled('themeTitle')}
              roadmapsKey="theme"
              format={format}
              addNew={allowToCreateNewMetadata('themes')}
              onLabelClick={_onLabelClick(metadataKeys.theme)}
              labelTooltip={getMetadataNote(metadataKeys.theme)}
            />
          ) : null}
          {hasNewMetadataAutocomplete ? (
            <FluidAppliesToAutocompleteField
              key="themes"
              name={name}
              label={getSystemFieldName('theme')}
              value={[themeTitle]}
              validation={fieldValidation}
              selectedEntity={selectedProject}
              levelOneFieldName="theme"
              options={themes || []}
              onChange={update}
              disableEdit={_checkFieldDisabled('themeTitle')}
              roadmapsKey="theme"
              onLabelClick={_onLabelClick(metadataKeys.theme)}
              labelTooltip={getMetadataNote(metadataKeys.theme)}
              getSystemFieldName={getSystemFieldName}
              canAddNew={allowToCreateNewMetadata('themes')}
              format="row"
              withNullOption
              focusOnInit
            />
          ) : null}
          {!hasMetadataRoadmaps && !hasNewMetadataAutocomplete ? (
            <FluidMetadataAutocompleteField
              name={name}
              validation={fieldValidation}
              key="themes"
              value={themeTitle}
              label={getSystemFieldName('theme')}
              metadata="themes"
              onChange={data => update({ themeTitle: data })}
              options={themes || []}
              disableEdit={_checkFieldDisabled('themeTitle')}
              format={format}
              onLabelClick={_onLabelClick(metadataKeys.theme)}
              labelTooltip={getMetadataNote(metadataKeys.theme)}
            />
          ) : null}
        </>
      ),
      additionalThemes: ({ name, format }) =>
        !hasNewMetadataAutocomplete ? (
          <FluidMultiSelectField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_THEMES}
            value={additionalThemes}
            label={`Additional ${getSystemFieldName('theme', true)}`}
            options={additionalThemesOptions}
            allValues={allThemes}
            optionsMapper={{ label: 'title', value: 'id' }}
            onChange={changeMultiselectValue(ADDITIONAL_THEMES, update)}
            disableEdit={_checkFieldDisabled(ADDITIONAL_THEMES)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.theme)}
            labelTooltip={getMetadataNote(metadataKeys.theme)}
            hideCreateOption
          />
        ) : (
          <FluidAppliesToMultiSelectAutocompleteField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_THEMES}
            roadmapsKey={ADDITIONAL_THEMES}
            label={`Additional ${getSystemFieldName('theme', true)}`}
            options={additionalThemesOptions}
            selectedOptions={additionalThemes}
            onChange={value => {
              const updateFunc = changeMultiselectValue(ADDITIONAL_THEMES, update);

              updateFunc(value.map(opt => opt.id));
            }}
            disableEdit={_checkFieldDisabled(ADDITIONAL_THEMES)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.theme)}
            labelTooltip={getMetadataNote(metadataKeys.theme)}
            canAddNew={false}
            selectedEntity={selectedProject}
            getSystemFieldName={getSystemFieldName}
            getOptionLabel={option => option.title}
          />
        ),
      categories: ({ name, format }) => (
        <>
          {hasMetadataRoadmaps && !hasNewMetadataAutocomplete ? (
            <FluidRoadmapMetadataSelectField
              name={name}
              validation={fieldValidation}
              key="categories"
              value={categoryTitle}
              label={getSystemFieldName('category')}
              metadata="categories"
              onChange={category => update({ categoryTitle: category })}
              options={categories || []}
              disableEdit={_checkFieldDisabled('categoryTitle')}
              roadmapsKey="category"
              format={format}
              addNew={allowToCreateNewMetadata('categories')}
              onLabelClick={_onLabelClick(metadataKeys.category)}
              labelTooltip={getMetadataNote(metadataKeys.category)}
            />
          ) : null}
          {hasNewMetadataAutocomplete ? (
            <FluidAppliesToAutocompleteField
              key="categories"
              name={name}
              label={getSystemFieldName('category')}
              value={[categoryTitle]}
              validation={fieldValidation}
              selectedEntity={selectedProject}
              levelOneFieldName="category"
              options={categories || []}
              onChange={update}
              disableEdit={_checkFieldDisabled('categoryTitle')}
              roadmapsKey="category"
              onLabelClick={_onLabelClick(metadataKeys.category)}
              labelTooltip={getMetadataNote(metadataKeys.category)}
              getSystemFieldName={getSystemFieldName}
              canAddNew={allowToCreateNewMetadata('categories')}
              format="row"
              withNullOption
              focusOnInit
            />
          ) : null}
          {!hasMetadataRoadmaps && !hasNewMetadataAutocomplete ? (
            <FluidMetadataAutocompleteField
              name={name}
              validation={fieldValidation}
              key="categories"
              value={categoryTitle}
              label={getSystemFieldName('category')}
              metadata="categorys"
              onChange={data => update({ categoryTitle: data })}
              options={categories || []}
              disableEdit={_checkFieldDisabled('categoryTitle')}
              format={format}
              onLabelClick={_onLabelClick(metadataKeys.category)}
              labelTooltip={getMetadataNote(metadataKeys.category)}
            />
          ) : null}
        </>
      ),
      additionalCategories: ({ name, format }) =>
        !hasNewMetadataAutocomplete ? (
          <FluidMultiSelectField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_CATEGORIES}
            value={additionalCategories}
            label={`Additional ${getSystemFieldName('category', true)}`}
            options={additionalCategoriesOptions}
            allValues={allCategories}
            optionsMapper={{ label: 'title', value: 'id' }}
            onChange={changeMultiselectValue(ADDITIONAL_CATEGORIES, update)}
            disableEdit={_checkFieldDisabled(ADDITIONAL_CATEGORIES)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.category)}
            labelTooltip={getMetadataNote(metadataKeys.category)}
            hideCreateOption
          />
        ) : (
          <FluidAppliesToMultiSelectAutocompleteField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_CATEGORIES}
            roadmapsKey={ADDITIONAL_CATEGORIES}
            label={`Additional ${getSystemFieldName('category', true)}`}
            options={additionalCategoriesOptions}
            selectedOptions={additionalCategories}
            onChange={value => {
              const updateFunc = changeMultiselectValue(ADDITIONAL_CATEGORIES, update);

              updateFunc(value.map(opt => opt.id));
            }}
            disableEdit={_checkFieldDisabled(ADDITIONAL_CATEGORIES)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.category)}
            labelTooltip={getMetadataNote(metadataKeys.category)}
            canAddNew={false}
            selectedEntity={selectedProject}
            getSystemFieldName={getSystemFieldName}
            getOptionLabel={option => option.title}
          />
        ),
      metrics: ({ name }) =>
        !!hasProjectMetrics &&
        !isNew && (
          <ProjectsMetricsForm
            name={name}
            validation={fieldValidation}
            labelTooltip={getMetadataNote(metadataKeys.metric)}
            onLabelClick={_onLabelClick(metadataKeys.metric)}
            project={selectedProject}
            canAddOrEdit={not(_checkFieldDisabled('metrics'))}
            toggleMetricsDialog={hasAdvancedMetricReporting ? toggleMetricsDialog : null}
          />
        ),
      layerMetadata: (
        <FluidFieldGroup topBorder>
          <FluidTextField key="updatedAt" value={formatDateTime(updatedAt)} label="Updated at" format="row" disableEdit />
          <FluidTextField key="createdAt" value={formatDateTime(createdAt)} label="Created at" format="row" disableEdit />
          <FluidTextField key="createdBy" value={getUserName(createdBy)} label="Created by" format="row" disableEdit />
        </FluidFieldGroup>
      ),
      [RESOURCE_TEAM]: ({ name, format }) => (
        <FluidAppliesToAutocompleteField
          name={name}
          validation={fieldValidation}
          key={RESOURCE_TEAM}
          value={[resourceTeam?.title]}
          label={getSystemFieldName('team')}
          selectedEntity={selectedProject}
          levelOneFieldName="resourceTeamLv1"
          levelTwoFieldName="resourceTeamLv2"
          onChange={data => {
            const selectedTeamTitle = data.resourceTeamLv2Title || data.resourceTeamLv1Title;
            const teamsArr = data.resourceTeamLv2Title ? allTeams2 : allTeams;
            const selectedTeam = teamsArr.find(team => team.title === selectedTeamTitle);

            update({ resource_team_id: selectedTeam?.id ?? null });
          }}
          options={teamsOptions}
          disableEdit={_checkFieldDisabled(RESOURCE_TEAM)}
          getSystemFieldName={getSystemFieldName}
          format={format}
          onLabelClick={_onLabelClick(metadataKeys.team)}
          labelTooltip={getMetadataNote(metadataKeys.team)}
          formatByRoadmap={false}
          withNullOption
          focusOnInit
        />
      ),
      additionalTeams: ({ name, format }) =>
        !hasNewMetadataAutocomplete ? (
          <FluidMultiSelectField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_TEAMS}
            value={additionalTeams}
            label={`All ${getSystemFieldName('team', true)}`}
            options={additionalTeamsOptions}
            allValues={allTeams}
            optionsMapper={{ label: 'title', value: 'id' }}
            onChange={changeMultiselectValue(ADDITIONAL_TEAMS, update)}
            disableEdit={_checkFieldDisabled(ADDITIONAL_TEAMS)}
            onLabelClick={_onLabelClick(metadataKeys.team)}
            labelTooltip={getMetadataNote(metadataKeys.team)}
            format={format}
            hideCreateOption
          />
        ) : (
          <FluidAppliesToMultiSelectAutocompleteField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_TEAMS}
            roadmapsKey={ADDITIONAL_TEAMS}
            label={`All ${getSystemFieldName('team', true)}`}
            options={additionalTeamsOptions}
            selectedOptions={additionalTeams}
            onChange={value => {
              const updateFunc = changeMultiselectValue(ADDITIONAL_TEAMS, update);

              updateFunc(value.map(opt => opt.id));
            }}
            disableEdit={_checkFieldDisabled(ADDITIONAL_TEAMS)}
            format={format}
            canAddNew={false}
            selectedEntity={selectedProject}
            getSystemFieldName={getSystemFieldName}
            getOptionLabel={option => option.name}
            onLabelClick={_onLabelClick(metadataKeys.team)}
            labelTooltip={getMetadataNote(metadataKeys.team)}
            formatByRoadmap={false}
          />
        ),
      additionalTeams2: ({ name, format }) =>
        !hasNewMetadataAutocomplete ? (
          <FluidMultiSelectField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_TEAMS_2}
            value={additionalTeams2}
            label={`All ${getSystemFieldName('team2', true)}`}
            options={additionalTeams2Options}
            allValues={allTeams2}
            optionsMapper={{ label: 'title', value: 'id' }}
            onChange={changeMultiselectValue(ADDITIONAL_TEAMS_2, update)}
            disableEdit={_checkFieldDisabled(ADDITIONAL_TEAMS_2)}
            format={format}
            onLabelClick={_onLabelClick(metadataKeys.team)}
            labelTooltip={getMetadataNote(metadataKeys.team)}
            hideCreateOption
          />
        ) : (
          <FluidAppliesToMultiSelectAutocompleteField
            name={name}
            validation={fieldValidation}
            key={ADDITIONAL_TEAMS_2}
            roadmapsKey={ADDITIONAL_TEAMS_2}
            label={`All ${getSystemFieldName('team2', true)}`}
            options={additionalTeams2Options}
            selectedOptions={additionalTeams2}
            onChange={value => {
              const updateFunc = changeMultiselectValue(ADDITIONAL_TEAMS_2, update);

              updateFunc(value.map(opt => opt.id));
            }}
            disableEdit={_checkFieldDisabled(ADDITIONAL_TEAMS_2)}
            format={format}
            canAddNew={false}
            selectedEntity={selectedProject}
            getSystemFieldName={getSystemFieldName}
            getOptionLabel={option => option.name}
            onLabelClick={_onLabelClick(metadataKeys.team)}
            labelTooltip={getMetadataNote(metadataKeys.team)}
            formatByRoadmap={false}
          />
        ),
      completed_date: ({ name, format }) => (
        <FluidDateField
          key={name}
          name={name}
          value={completedDate}
          label="Completed Date"
          disableEdit={_checkFieldDisabled('completed_date')}
          format={format}
          onChange={value => update({ completed_date: value })}
        />
      ),
      ...(hasPersonas
        ? {
            personas: ({ name, format }) =>
              !hasNewMetadataAutocomplete ? (
                <FluidMultiSelectField
                  name={name}
                  validation={fieldValidation}
                  key={PERSONAS}
                  value={projectPersonas}
                  label={getSystemFieldName('persona', true)}
                  options={personas}
                  allValues={allPersonas}
                  optionsMapper={{ label: 'title', value: 'id' }}
                  onChange={onUpdateProjectPersonas}
                  disableEdit={_checkFieldDisabled(PERSONAS)}
                  format={format}
                  onLabelClick={() => navigateToUrlAndCloseLightbox(urlByField[PERSONAS])}
                  hideCreateOption
                />
              ) : (
                <FluidAppliesToMultiSelectAutocompleteField
                  name={name}
                  validation={fieldValidation}
                  key={PERSONAS}
                  roadmapsKey="personas"
                  label={getSystemFieldName('persona')}
                  options={personasOptions}
                  selectedOptions={projectPersonas.map(persona => allPersonas.find(p => p.id === persona?.id))}
                  onChange={value => onUpdateProjectPersonas(value.map(opt => opt.id))}
                  disableEdit={_checkFieldDisabled(PERSONAS)}
                  format={format}
                  onLabelClick={() => navigateToUrlAndCloseLightbox(urlByField[PERSONAS])}
                  labelTooltip={getMetadataNote(metadataKeys.persona)}
                  selectedEntity={selectedProject}
                  getSystemFieldName={getSystemFieldName}
                  getOptionLabel={option => option.title}
                />
              ),
          }
        : {}),
      ...(hasLifecycles
        ? {
            lifecycles: ({ name, format }) =>
              !hasNewMetadataAutocomplete ? (
                <FluidMultiSelectField
                  name={name}
                  validation={fieldValidation}
                  key={LIFECYCLES}
                  value={projectLifecycles}
                  label={getSystemFieldName('lifecycle', true)}
                  options={lifecycles}
                  allValues={allLifecycles}
                  optionsMapper={{ label: 'title', value: 'id' }}
                  onChange={onUpdateProjectLifecycles}
                  disableEdit={_checkFieldDisabled(LIFECYCLES)}
                  format={format}
                  onLabelClick={() => navigateToUrlAndCloseLightbox(urlByField[LIFECYCLES])}
                  hideCreateOption
                />
              ) : (
                <FluidAppliesToMultiSelectAutocompleteField
                  name={name}
                  validation={fieldValidation}
                  key={LIFECYCLES}
                  roadmapsKey="lifecycles"
                  label={getSystemFieldName('lifecycle')}
                  options={lifecyclesOptions}
                  selectedOptions={projectLifecycles.map(lifeCycle => allLifecycles.find(lc => lc.id === lifeCycle?.id))}
                  onChange={value => onUpdateProjectLifecycles(value.map(opt => opt.id))}
                  disableEdit={_checkFieldDisabled(LIFECYCLES)}
                  format={format}
                  onLabelClick={() => navigateToUrlAndCloseLightbox(urlByField[LIFECYCLES])}
                  labelTooltip={getMetadataNote(metadataKeys.lifecycle)}
                  selectedEntity={selectedProject}
                  getSystemFieldName={getSystemFieldName}
                  getOptionLabel={option => option.title}
                />
              ),
          }
        : {}),
      ...generateCustomFieldsFor(),
      ...(hasExternalKey
        ? {
            externalKey: ({ name, format }) => (
              <FluidTextField
                testId="field-project-externalkey"
                key="externalKey"
                value={selectedProject?.external_key}
                label="External key"
                name={name}
                format={format}
                disableEdit
              />
            ),
            externalParentKey: ({ name, format }) => (
              <FluidTextField
                testId="field-project-externalparentkey"
                key="externalParentKey"
                value={selectedProject?.external_parent_key}
                label="External Parent key"
                name={name}
                format={format}
                disableEdit
              />
            ),
          }
        : {}),
    };

    const newLeftConfiguration = [
      commonFields.type,
      ...fieldsConfiguration?.leftFields?.map(name => buildField(commonFields[name], 'row', name)),
    ];

    // LayerMetadata should always be on the right after all fields
    const newRightConfiguration = [
      ...fieldsConfiguration?.rightFields?.map(name => buildField(commonFields[name], 'row', name)),
      commonFields.layerMetadata,
    ];

    return (
      <>
        <form autoComplete="off">
          <StyledGridWrapper container xs={12} justify="space-between">
            <StyledLeftSide>{newLeftConfiguration}</StyledLeftSide>
            <StyledRightSide leftBorder>{newRightConfiguration}</StyledRightSide>
          </StyledGridWrapper>
        </form>
      </>
    );
  },
);

const StyledGridWrapper = styled(Grid)`
  &&&& {
    gap: 40px;
    max-width: 99%;
  }
`;

const StyledLeftSide = styled(FluidContainer)`
  &&&& {
    width: 53.5%;

    > * {
      padding-right: 0;
    }
  }
`;

const StyledRightSide = styled(FluidContainer)`
  &&&& {
    width: 41.5%;
  }
`;
