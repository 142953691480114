import shouldConsiderValueForAggregation from '../helpers/shouldConsiderValueForAggregation';

const sumNumericFieldAggColumnDef = {
  aggFunc: 'sumNumericFields',
  valueSetter: params => {
    const {
      newValue,
      data,
      colDef: { field },
    } = params;

    data[field] = newValue;

    return true;
  },
  valueGetter: params => {
    const {
      data = {},
      colDef: { field },
    } = params;

    if (params.node.group) {
      return data[field];
    }

    if (!params.node.group) {
      const considerValueForSum = shouldConsiderValueForAggregation(params);

      return considerValueForSum ? data[field] ?? 0 : 0;
    }
  },

  getDisplayValue: params => {
    const {
      data = {},
      colDef: { field },
    } = params;

    return data[field];
  },
};

export default sumNumericFieldAggColumnDef;
