import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { pipe, isEmpty, not, values } from 'ramda';

import FilterIcon from 'design-system/atoms/FilterIcon/index';
import DndList from 'design-system/organisms/DndList/index';
import MultiFilterSearchRow from 'design-system/atoms/MultiFilterSearchRow/index';
import UnfilterIcon from 'design-system/atoms/UnfilterIcon/index';

import { searchMenuItems } from 'design-system/utils';
import { usePopperPlacement } from 'design-system/utils/usePopperPlacement';

const isNotEmpty = pipe(isEmpty, not);
const getFilterId = id => `teams-filter-${id}`;
const VARIANT_GREEN = 'green';
const VARIANT_RED = 'red';

const TeamsFilter = ({ id, label, variant, teams = [], selectedTeams = {}, onSelectTeam }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = React.useState('');

  const handleSearch = event => {
    setSearch(event.target.value);
  };

  const renderSearch = () => {
    return <MultiFilterSearchRow handleChange={handleSearch} search={search} />;
  };

  const buttonRef = useRef();

  const onClickFilter = () => {
    setAnchorEl(buttonRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickItem = () => {};

  const filteredTeams = useMemo(() => {
    return searchMenuItems(search, teams);
  }, [teams, search]);

  const hasFilterActive = isNotEmpty(values(selectedTeams).filter(Boolean));
  const { placement } = usePopperPlacement(anchorEl);

  return (
    <Wrapper>
      <Label onClick={onClickFilter} variant={variant}>
        {label}
      </Label>
      {isNotEmpty(teams) && (
        <FilterUnfilterButton ref={buttonRef} onClick={onClickFilter}>
          {hasFilterActive ? (
            <UnfilterIcon width={16} height={16} color="primary" />
          ) : (
            <FilterIcon color="primary" width={16} height={16} />
          )}
        </FilterUnfilterButton>
      )}
      <PopperContainer
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        role={undefined}
        placement={placement}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose} disableReactTree="true">
                <div>
                  <DndList
                    id={getFilterId(id)}
                    items={filteredTeams}
                    selectedItems={selectedTeams}
                    renderSearch={renderSearch}
                    onSelectItem={onSelectTeam}
                    onClickItem={onClickItem}
                    isSubTree
                    checkable
                    checkedByDefault
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </PopperContainer>
    </Wrapper>
  );
};

TeamsFilter.propTypes = {
  id: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([VARIANT_GREEN, VARIANT_RED]).isRequired,
  label: PropTypes.string.isRequired,
  teams: PropTypes.array,
  selectedItems: PropTypes.object,
  onSelectTeam: PropTypes.func,
};

const Wrapper = styled.div`
  margin: 0 10px;
  display: flex;
  align-items: center;
`;

const FilterUnfilterButton = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

const Label = styled.label`
  ${({ variant, theme }) => variant === VARIANT_GREEN && `color: ${theme.palette.text.green};`}
  ${({ variant, theme }) => variant === VARIANT_RED && `color: ${theme.palette.text.error};`}
  cursor: pointer;
  font-size: ${props => props.theme.typography.fontSize}px;
`;

const PopperContainer = styled(Popper)`
  z-index: 1500;
`;

export default TeamsFilter;
