import React, { useMemo } from 'react';
import styled from 'styled-components';

import MicroChip from 'design-system/atoms/MicroChip/index';


const StyledMicroChip = styled(MicroChip)`
  &&&& {
    margin: 0;
    width: auto;
  }
`;

const ChipCellRenderer = ({ color, value, onClick }) => {
  const chipOtherProps = useMemo(
    () => ({
      ...(color ? { color } : { variant: 'outlined' }),
    }),
    [color],
  );

  return value ? <StyledMicroChip {...chipOtherProps} label={value} onClick={onClick} fullWidth /> : '';
};

export default ChipCellRenderer;
