import React, { useMemo } from 'react';
import styled from 'styled-components';

import UserViewsList from 'containers/UserViewsList';
import WidgetSegment from 'design-system/organisms/WidgetSegment';
import useUserFavoriteViews from 'hooks/userViews/useUserFavoriteViews';
import useUserViewsList from 'hooks/userViews/useUserViewsList';
import { VIEW_ITEM_VARIANTS } from 'constants/userViews';
import useViewsDialog from 'hooks/userViews/useViewsDialog';

const VISIBLE_VIEWS_LIMIT = 4;

export default () => {
  const { userViews } = useUserFavoriteViews();
  const { viewsList, ...userViewsListProps } = useUserViewsList(userViews, false);
  const { openDialog: openViewsDialog } = useViewsDialog();

  const initialUserViews = useMemo(() => viewsList.slice(0, VISIBLE_VIEWS_LIMIT), [viewsList]);
  const showMoreVisible = useMemo(() => viewsList.length > VISIBLE_VIEWS_LIMIT, [viewsList.length]);

  return (
    <StyledWidgetSegment
      title="My Favorite Views"
      initialContent={
        <UserViewsList {...userViewsListProps} viewsList={initialUserViews} variant={VIEW_ITEM_VARIANTS.widgetList} />
      }
      showMore={showMoreVisible}
      handleShowMore={openViewsDialog}
      overflowContent
    />
  );
};

const StyledWidgetSegment = styled(WidgetSegment)`
  min-height: 340px;
`;
