import omit from 'lodash/omit';
import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { AUTOCOMPLETE_FROM_SERVER_DELAY } from 'constants';
import { fetchProjects } from 'store/projects';
import { getUserFilters } from 'store/filters/selectors';
import useDeepEffect from 'hooks/useDeepEffect';
import { IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import { getUserViewsIsLoading, getUserViewsIsUninitialized } from 'store/userViews/selectors';
import usePageFilters from 'hooks/filters/usePageFilters';
import { GOAL_MODE_TIMELINE_FILTER } from 'constants/goalMode';
import useScenariosFilters from 'hooks/useScenariosFilters';

export default (
  pageId,
  projectsAssociations,
  forceAssociations,
  extraFilters = {},
  paginationAttributes = {},
  debounceFetchCall = true,
  isGoalMode = false,
) => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const { pageFilters: filters, displayLayer } = usePageFilters(pageId);
  const hasHierarchy = useSelector(state => state.organization.organization.has_hierarchy);
  const hasBet = useSelector(state => state.organization.organization.has_bet);
  const userViewsIsLoading = useSelector(getUserViewsIsLoading);
  const userViewsIsUninitialized = useSelector(getUserViewsIsUninitialized);
  const userFilters = useSelector(state => getUserFilters(state));

  useScenariosFilters();

  // goal mode filters don't account for projects
  const projectPageFilters = useMemo(() => (isGoalMode ? GOAL_MODE_TIMELINE_FILTER : filters), [filters, isGoalMode]);

  const _fetchProjects = useCallback(() => {
    const asyncFetch = async () => {
      setIsLoaded(false);

      const filtersForApiByLevels = compileFiltersBody(
        projectPageFilters,
        userFilters,
        hasBet,
        pageId,
        displayLayer,
        null,
        extraFilters,
      );

      // For now just load parents for ideas
      const withParents = hasHierarchy && [IDEA_LAYER, INITIATIVE_LAYER].includes(displayLayer);

      const associations = {
        withTasks: true,
        withEstimates: true,
        withIntegrations: true,
        withParents,
        withVotes: true,
        ...(projectsAssociations || {}),
      };

      await dispatch(
        fetchProjects(
          filtersForApiByLevels,
          associations,
          {},
          false,
          true,
          forceAssociations,
          extraFilters,
          paginationAttributes,
        ),
      );

      setIsLoaded(true);
    };

    if (!userViewsIsLoading && !userViewsIsUninitialized) {
      asyncFetch();
    }
  }, [userViewsIsLoading, userViewsIsUninitialized, filters, userFilters, displayLayer, extraFilters]);

  const [_debouncedFetchProjects] = useDebouncedCallback(_fetchProjects, AUTOCOMPLETE_FROM_SERVER_DELAY);

  useDeepEffect(() => {
    if (debounceFetchCall) {
      _debouncedFetchProjects();
      return;
    }

    _fetchProjects();
  }, [
    Object.keys(omit(filters, ['displayLayer', 'portfolioMode'])),
    Object.values(omit(filters, ['displayLayer', 'portfolioMode'])),
    extraFilters,
    debounceFetchCall,
  ]);

  return [isLoaded, _fetchProjects];
};
