import React from 'react';
import styled from 'styled-components';

import AddProjectButton from 'components/AddProjectButton';

export default () => {
  const gridTemplateColumns = '30px 1fr';

  return (
    <Wrapper gridTemplateColumns={gridTemplateColumns}>
      <AddProjectButton />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
`;
