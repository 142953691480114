import { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getEnrichedProject } from 'store/projects/selectors';
import { filterProjectsMilestones } from 'utils/projects/projectsUtils';
import { loadAutocompleteProjects, loadAutocompleteProjectsByTitle } from 'store/projects';

const useProjectsSearch = (layers = [], includeMilestones = false) => {
  const [isLoading, setIsLoading] = useState([]);
  const [projects, setProjects] = useState([]);

  const dispatch = useDispatch();

  // required for getEnrichedProject selector
  const state = useSelector(state => state);

  const filterMilestones = useCallback(
    (searchedProjects = []) => (!includeMilestones ? filterProjectsMilestones(searchedProjects) : searchedProjects),
    [includeMilestones],
  );

  const searchProjects = useCallback(
    async (value, searchOptions) => {
      const {
        showCompleted = false,
        overwriteLayers = null,
        loadTree = true,
        limit = 20,
        includeAllCorpItems = false,
      } = searchOptions;

      setIsLoading(true);

      const data = await dispatch(
        loadAutocompleteProjects(value, {
          showCompleted,
          layers: overwriteLayers || layers,
          loadTree,
          limit,
          includeAllCorpItems,
        }),
      );

      // TODO: maybe we could have here a "light" getEnrichedProject that gets only the metadata we need
      const enrichedProjects = filterMilestones(data.value.data.data).map(sp => getEnrichedProject(state, sp));

      setIsLoading(false);
      setProjects(enrichedProjects);

      return enrichedProjects;
    },
    [state, filterMilestones],
  );

  const searchProjectsByTitle = useCallback(
    async (value, searchOptions) => {
      const {
        showCompleted = false,
        overwriteLayers = null,
        loadTree = true,
        limit = 20,
        includeAllCorpItems = false,
      } = searchOptions;

      setIsLoading(true);

      const data = await dispatch(
        loadAutocompleteProjectsByTitle(value, {
          showCompleted,
          layers: overwriteLayers || layers,
          loadTree,
          limit,
          includeAllCorpItems,
        }),
      );

      const enrichedProjects = filterMilestones(data.value.data).map(sp => getEnrichedProject(state, sp));

      setIsLoading(false);
      setProjects(enrichedProjects);

      return enrichedProjects;
    },
    [dispatch, layers],
  );

  return useMemo(
    () => ({
      projects,
      isLoading,
      searchProjects,
      searchProjectsByTitle,
    }),
    [projects, searchProjects, isLoading, searchProjectsByTitle],
  );
};

export default useProjectsSearch;
