import React from 'react';
import styled from 'styled-components';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import ImportingScreen from './components/ImportingScreen';
import SearchAccounts from './components/SearchAccounts';

const STEPS = [
  { step: 0, label: 'Search Accounts', component: props => <SearchAccounts {...props} /> },
  { step: 1, label: 'Complete Import', component: props => <ImportingScreen {...props} /> },
];

export default ({
  activeStep = [],
  error,
  handleSearch,
  importUrl,
  parsedImportUrl,
  totalSalesforceCustomers,
  updateImportUrl,
  updateParsedImportUrl,
}) => {
  const _renderStepContent = () => {
    const stepMatch = STEPS.find(step => step.step === activeStep);

    return stepMatch.component({
      error,
      handleSearch,
      importUrl,
      parsedImportUrl,
      totalSalesforceCustomers,
      updateImportUrl,
      updateParsedImportUrl,
    });
  };

  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel>
        {STEPS.map(({ label }) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <StepperContent>{_renderStepContent()}</StepperContent>
    </>
  );
};

const StepperContent = styled.div``;
