import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { isEmpty, not, pipe } from 'ramda';
import normalizeArray from 'utils/normalizeArray';

import { getProjectsDeliverables } from 'store/cycleDeliverables/selectors';

import { getProjectParents, getProjectChildren } from './helpers/hierarchy';

const isNotEmpty = pipe(isEmpty, not);

const useProjectsLocalFilterByDeliverableStatus = (projects = [], selectedDeliverableStatus = {}) => {
  const projectsDeliverables = useSelector(getProjectsDeliverables);

  const _filterProjectsBydeliverableStatus = projectsById => {
    let projectsToShow = [];

    Object.entries(projectsDeliverables).forEach(([projectId, currentProjectDeliverables = []]) => {
      const matchedAlready = projectsToShow.includes(projectId);

      if (matchedAlready) {
        return;
      }

      const project = projectsById[projectId];

      if (!project) {
        return;
      }

      currentProjectDeliverables?.forEach(projectDeliverable => {
        const { cycle_deliverable_id: deliverableId, status } = projectDeliverable;
        const selectedStatusForDeliverable = selectedDeliverableStatus[deliverableId];

        if (!selectedStatusForDeliverable) {
          return;
        }
        if (selectedStatusForDeliverable?.includes(status)) {
          const parents = getProjectParents(project, projectsById);
          const children = getProjectChildren(project, projects);

          projectsToShow = [...projectsToShow, ...parents, ...children, project.id];
        }
      });
    });

    return projectsToShow;
  };

  const projectsIdsToShow = useMemo(() => {
    if (isEmpty(selectedDeliverableStatus)) {
      return [];
    }
    const projectsById = normalizeArray(projects, 'id');

    return _filterProjectsBydeliverableStatus(projectsById);
  }, [projects, projectsDeliverables, selectedDeliverableStatus]);

  const filterFunction = useMemo(
    () => (isNotEmpty(selectedDeliverableStatus) ? p => projectsIdsToShow.includes(p?.id) : null),
    [projectsIdsToShow],
  );

  return filterFunction;
};

export default useProjectsLocalFilterByDeliverableStatus;
