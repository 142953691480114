import React from 'react';
import styled from 'styled-components';

import UserViewsList from 'containers/UserViewsList';
import WidgetSegmentTitle from 'design-system/atoms/WidgetSegmentTitle';
import useUserViews from 'hooks/userViews/useUserViews';
import useUserViewsList from 'hooks/userViews/useUserViewsList';
import { VIEW_ITEM_VARIANTS } from 'constants/userViews';

export default () => {
  const { userDashboards } = useUserViews();
  const userViewsListProps = useUserViewsList(userDashboards, false);

  return (
    <ViewsContainer>
      <ViewsHeader>
        <WidgetSegmentTitle>All dashboards</WidgetSegmentTitle>
      </ViewsHeader>
      <ViewsList>
        <UserViewsList {...userViewsListProps} variant={VIEW_ITEM_VARIANTS.homepage} />
      </ViewsList>
    </ViewsContainer>
  );
};

const ViewsContainer = styled.div`
  margin: 0 auto;
`;

const ViewsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ViewsList = styled.div`
  max-height: 335px;
  overflow-y: scroll;
  margin: 21px 0;
`;
