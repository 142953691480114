import React from 'react';

import ToggleButton from 'design-system/molecules/ToggleButton/index';

import IntegrationIcon from '../IntegrationIcon/IntegrationIcon';

import useIntegrationOpenExternalUrlButton from './hooks/useIntegrationOpenExternalUrlButton';

const IntegrationOpenExternalUrlButton = ({ orgIntegrationId, externalUrl, disabled = false, className }) => {
  const { integrationType, buttonTitle, onButtonClick } = useIntegrationOpenExternalUrlButton(orgIntegrationId, externalUrl);

  return (
    <ToggleButton
      icon={<IntegrationIcon integrationType={integrationType} />}
      on={false}
      onChange={onButtonClick}
      title={buttonTitle}
      disabled={disabled}
      className={className}
    />
  );
};

export default IntegrationOpenExternalUrlButton;
