import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import GridLayout, { WidthProvider } from 'react-grid-layout';
import { withRouter } from 'react-router-dom';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import BaseLayout from 'design-system/organisms/BaseLayout/index';

import { spacing } from 'design-system/theme';
import { PAGE_HEADER_HEIGHT } from 'constants/common';

import ChartWidget from './ChartWidget';
import DescriptionEditor from './DescriptionEditor/DescriptionEditor';
import { getWidgetId } from './helpers/widgets';
import useWidgetLightboxContext from './WidgetLightbox/hooks/useWidgetLightboxContext';
import { CONFIGURABLE_CHART } from './helpers/templates';

const gridRowHeight = 320;
const FluidGridLayout = WidthProvider(GridLayout);

const isConfigurableChartWidget = w => w.id === CONFIGURABLE_CHART.id;

const DashboardsComponent = ({
  dashboardView,
  dashboardState,
  dashboardWidgets,
  gridLayout,
  updateDateRange,
  updateWidgets,
  inEditMode,
  match,
}) => {
  const { openEditWidgetLightbox } = useWidgetLightboxContext();
  const [widgets, setWidgets] = useState(dashboardWidgets);
  const [layout, setLayout] = useState(gridLayout);
  const [widgetsHeights, setWidgetsHeights] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  const turnDraggingOffOnDrop = () => {
    setTimeout(() => setIsDragging(false), 0);
  };

  const wrapperRef = useRef(null);

  const handleWidgetsUpdate = updatedWidgets => {
    setWidgets(updatedWidgets);
    updateWidgets(updatedWidgets);
  };

  const checkAllowEdit = widget => isConfigurableChartWidget(widget);
  const handleEditWidget = id => openEditWidgetLightbox(match?.params?.dashboardId, id);

  const handleDeleteWidget = id => {
    const updatedWidgets = widgets.filter(widget => getWidgetId(widget) !== id);

    handleWidgetsUpdate(updatedWidgets);
  };

  const processAndUpdateWidgets = items => {
    const updatedWidgets = widgets.map(widget => {
      const item = items.find(i => i.i === getWidgetId(widget));

      return item ? { ...widget, x: item.x, y: item.y, width: item.w, height: item.h } : widget;
    });

    handleWidgetsUpdate(updatedWidgets);
  };

  const handleOnDrop = items => {
    processAndUpdateWidgets(items);
    turnDraggingOffOnDrop();
  };

  const updateWidgetsLayout = () => {
    const updatedLayout = layout.map(item => {
      const widgetHeightObj = widgetsHeights.find(widget => widget.id === item.i);

      if (widgetHeightObj) {
        return { ...item, h: widgetHeightObj.height / gridRowHeight };
      }
      return item;
    });

    setLayout(updatedLayout);
  };

  const updateWidgetHeight = (id, height) =>
    setWidgetsHeights(prevState => {
      const newState = prevState.map(item => {
        if (item.id === id) {
          return { ...item, height };
        }
        return item;
      });

      if (!newState.find(item => item.id === id)) {
        newState.push({ id, height });
      }
      return newState;
    });

  useEffect(() => {
    if (widgetsHeights.length > 0 && widgetsHeights.length === widgets.length) {
      updateWidgetsLayout();
    }
  }, [widgetsHeights, widgets]);

  useEffect(() => {
    const updatedWidgetsHeights = widgetsHeights.filter(widgetHeight =>
      dashboardWidgets.some(widget => getWidgetId(widget) === widgetHeight.id),
    );

    setWidgets(dashboardWidgets);
    setWidgetsHeights(updatedWidgetsHeights);
  }, [dashboardWidgets]);

  useEffect(() => {
    setLayout(gridLayout);
  }, [gridLayout]);

  return (
    <Wrapper ref={wrapperRef}>
      <BaseLayout>
        <DescriptionEditor description={dashboardView?.description || ''} inEditMode={inEditMode} />
        <FluidGridLayout
          layout={layout}
          cols={2}
          isResizable={false}
          isDraggable={!!inEditMode}
          onDragStop={handleOnDrop}
          rowHeight={gridRowHeight}
          onDrag={() => setIsDragging(true)}
        >
          {widgets &&
            widgets.map(widget => (
              <div key={getWidgetId(widget)}>
                <ChartWidget
                  widget={widget}
                  inEditMode={inEditMode}
                  updateWidgetHeight={updateWidgetHeight}
                  handleDelete={handleDeleteWidget}
                  disableClick={isDragging}
                  dashboardState={dashboardState}
                  updateDateRange={updateDateRange}
                  handleEdit={handleEditWidget}
                  checkAllowEdit={checkAllowEdit}
                />
              </div>
            ))}
        </FluidGridLayout>
      </BaseLayout>
    </Wrapper>
  );
};

export default withRouter(DashboardsComponent);

const Wrapper = styled.div`
  position: relative;
  padding-top: ${spacing(1.5)}px;
  height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
  overflow: auto;
  background: ${({ theme }) => theme.palette.background.secondary};

  .react-grid-item.react-grid-placeholder {
    background: ${({ theme }) => theme.palette.newLayout.background.header};
    opacity: 1;
  }
`;
