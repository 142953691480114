/**
 * Returns list of with new item or updated item
 * @param {Immutable.Map} item
 * @param {Immutable.List} list
 * @return {Object[]}
 */
export default function (item, list) {
  const index = list.findIndex(i => i.get('id') === item.get('id'));

  if (index >= 0) {
    // update item if it exists in list
    list = list.update(index, actual => actual.merge(item));
  } else {
    // add item if it does not exist in list
    list = list.unshift(item || {});
  }

  return list;
}
