import { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isInteger from 'lodash/isInteger';

import { getPageFilters, usePortfolioMode } from 'store/filters/selectors';
import { getOrganizationIntegrations } from 'store/organization/selectors';
import { getProjectStories } from 'store/integrations';
import useDeepEffect from 'hooks/useDeepEffect';
import useDeepMemo from 'hooks/useDeepMemo';

export default (gantt, ganttTasks, lsState) => {
  const dispatch = useDispatch();
  const [waitForRequest, setWaitForRequest] = useState({});
  const filters = useSelector(state => getPageFilters(state));
  const orgIntegrations = useSelector(getOrganizationIntegrations);
  const portfolioMode = useSelector(usePortfolioMode);

  const integrationsLastSync = useMemo(() => orgIntegrations.map(oi => oi.data.last_sync), [orgIntegrations]);

  const _loadProjectStories = async ids => {
    if (waitForRequest.stories) return;

    setWaitForRequest({ stories: true });
    await dispatch(getProjectStories(ids));
    setWaitForRequest({ stories: false });
  };
  const _getOpenIdeasIds = () => {
    return ganttTasks.data
      .filter(t => t.open && isInteger(t.id) && !t.group)
      .filter(t => t.integrationProgress && t.integrationProgress.issuesTotal > 0)
      .map(({ id }) => id);
  };

  const openIdeas = useDeepMemo(_getOpenIdeasIds, [ganttTasks.data]);
  const openIdeasRef = useRef(openIdeas);
  // const openParentsRef = useRef(projectsParents);

  useEffect(() => {
    (ganttTasks || {}).data = [];
  }, [portfolioMode]);
  useDeepEffect(() => {
    if (!gantt) return;

    const wasRemoved = openIdeasRef.current.length > openIdeas.length;

    openIdeasRef.current = openIdeas;

    if (wasRemoved) return;
    if (lsState.showStories && openIdeas.length && !waitForRequest.children) {
      _loadProjectStories(openIdeas.join());
    }
  }, [gantt, openIdeas, portfolioMode, lsState.showStories, integrationsLastSync, filters]);

  return _loadProjectStories;
};
