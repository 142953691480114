const okrMatchesSearchText = (okr, search) => okr?.title.toLowerCase().includes(search.toLowerCase());

const filterOkrsBySearchText = (okrs, search) =>
  okrs.filter(okr => {
    if (okrMatchesSearchText(okr, search)) {
      return true;
    }

    if (okr.keyResults && okr.keyResults.length > 0) {
      const filteredKeyResults = filterOkrsBySearchText(okr.keyResults, search);

      if (filteredKeyResults.length > 0) {
        return true;
      }
    }

    return false;
  });

export default filterOkrsBySearchText;
