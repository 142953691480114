import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import TreeDropdown from 'design-system/molecules/TreeDropdown/index';

import { AG_GRID_POPUP_EDITOR_CLASS } from 'constants/grid';

// Distance between the bottom of the grid and the bottom of the viewport.
// This is the typical value for pages containing AgGrid as is to prevent
// the items list component to be overlapped.
const GRID_TO_WINDOW_Y_OFFSET = 14;

const MetadataTreeDropdown = props => {
  const [paperYOffset, setPaperYOffset] = useState(0);

  const paperRef = useRef(null);

  const calculatePaperYOffset = () => {
    if (paperRef.current && (props.inputRef || props.isAgGridCell)) {
      const paperBoundingClientRect = paperRef.current.getBoundingClientRect();

      // We need to find the popup editor element, because the Grid will automatically
      // adjust its positioning if it doesn't have space to fully display when it's opened.
      // The input element (`inputRef`) will not always work, because of this. It will
      // remain on the grid hierarchy. The calculation of the offset (performed below)
      // needs to use the correct element from where the editor is built as reference
      // for the calculation.
      const closestPopupEditorElement = paperRef.current.closest(AG_GRID_POPUP_EDITOR_CLASS);

      // If found the popup editor element, use it: Otherwise use the passed input element
      const elementForPositionReference = closestPopupEditorElement || props.inputRef;

      if (!elementForPositionReference) {
        return 0;
      }

      const elementForPositionReferenceBoundingClientRect = elementForPositionReference.getBoundingClientRect();

      const inputDistanceToTop = elementForPositionReferenceBoundingClientRect.top;

      const paperHeight = paperBoundingClientRect.height;

      const paperBottomPlacement = inputDistanceToTop + paperHeight;

      const windowInnerHeightWithOffset = window.innerHeight - GRID_TO_WINDOW_Y_OFFSET;

      const excessPlacement = paperBottomPlacement - windowInnerHeightWithOffset;

      return Math.max(excessPlacement, 0);
    }
  };

  const handleGroupOpenClose = () => {
    window.requestAnimationFrame(() => {
      const yOffset = calculatePaperYOffset();

      setPaperYOffset(yOffset);
    });
  };

  useEffect(() => {
    const yOffset = calculatePaperYOffset();

    setPaperYOffset(yOffset);
  }, [paperRef.current, props.inputRef]);

  return (
    <TreeDropdown
      usePopper={props.isAgGridCell}
      paperRef={paperRef}
      offsetPaperY={paperYOffset}
      onGroupOpenClose={handleGroupOpenClose}
      {...props}
    />
  );
};

MetadataTreeDropdown.propTypes = {
  hasEmptyOption: PropTypes.bool,
  hasClearSelection: PropTypes.bool,
  hasMultiLevel: PropTypes.bool,
  hasMultiSelection: PropTypes.bool,
  hasSearchBar: PropTypes.bool,
  hasShowArchived: PropTypes.bool,
  inputRef: PropTypes.any,
  isAgGridCell: PropTypes.bool,
  isOpen: PropTypes.bool,
  items: PropTypes.any,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  offsetLeft: PropTypes.number,
  placement: PropTypes.string,
  selectedItems: PropTypes.array,
};

MetadataTreeDropdown.defaultProps = {
  isAgGridCell: false,
  hasEmptyOption: false,
  hasClearSelection: false,
  hasMultiLevel: false,
  hasMultiSelection: false,
  hasSearchBar: true,
  hasShowArchived: false,
  offsetLeft: 0,
  placement: 'bottom-start',
};

export default MetadataTreeDropdown;
