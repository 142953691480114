import axios from 'axios';

import {
  FETCH_TEAMS,
  REMOVE_UNSAVED_TEAMS,
  ADD_TEAM_WITHOUT_SAVE,
  ADD_TEAM,
  CREATE_TEAM_FULFILLED,
  DELETE_TEAM_FULFILLED,
  UPDATE_TEAM_FULFILLED,
  BULK_DELETE_TEAMS_FULFILLED,
  CREATE_TEAM_ROADMAP,
  DELETE_TEAM_ROADMAP,
  BULK_DELETE_TEAM_ROADMAP,
} from './types';
import fetchMetadataAction from '../utils/factory/fetchMetadataAction';

import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';

export const fetchTeams = fetchMetadataAction(
  FETCH_TEAMS,
  '/api/teams',
  state => state.teams.teams,
  state => state.teams.lastCallsDate,
);

export const removeUnsavedTeams = () => ({
  type: REMOVE_UNSAVED_TEAMS,
});

export const addTeamWithoutSave = team => ({
  type: ADD_TEAM_WITHOUT_SAVE,
  team,
});

export const addTeam = team => ({
  type: ADD_TEAM,
  payload: team,
});

export const createTeamRoadmap = (teamId, teamRoadmap) => {
  return {
    payload: axios.post(`/api/teams/${teamId}/teamRoadmaps/`, teamRoadmap).then(response => response.data),
    type: CREATE_TEAM_ROADMAP,
  };
};

export const deleteTeamRoadmap = ({ id, roadmapId, subRoadmapId = null, product2Id = null }) => {
  let route = `/api/teams/${id}/teamRoadmaps/${roadmapId}`;

  route += subRoadmapId ? `/${subRoadmapId}` : '';
  route += subRoadmapId && product2Id ? `/${product2Id}` : '';

  return {
    payload: axios.delete(route).then(response => response.data),
    type: DELETE_TEAM_ROADMAP,
  };
};

export const bulkDeleteTeamRoadmaps = teamId => {
  return {
    payload: axios.delete(`/api/teams/${teamId}/teamRoadmaps/`).then(response => response.data),
    type: BULK_DELETE_TEAM_ROADMAP,
  };
};

export const gotTeamsRealtimeUpdate = generateRealtimeUpdateAction(
  CREATE_TEAM_FULFILLED,
  DELETE_TEAM_FULFILLED,
  UPDATE_TEAM_FULFILLED,
  BULK_DELETE_TEAMS_FULFILLED,
);

export const teamActions = {
  fetchTeams,
};
