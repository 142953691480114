export const FETCH_TEAMS = 'FETCH_TEAMS';
export const FETCH_TEAMS_FULFILLED = 'FETCH_TEAMS_FULFILLED';
export const FETCH_TEAMS_PENDING = 'FETCH_TEAMS_PENDING';

export const CREATE_TEAM = 'CREATE_TEAM';
export const CREATE_TEAM_FULFILLED = 'CREATE_TEAM_FULFILLED';

export const UPDATE_TEAM = 'UPDATE_TEAM';
export const UPDATE_TEAM_FULFILLED = 'UPDATE_TEAM_FULFILLED';

export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETE_TEAM_FULFILLED = 'DELETE_TEAM_FULFILLED';

export const MERGE_TEAMS = 'MERGE_TEAMS';
export const MERGE_TEAMS_FULFILLED = 'MERGE_TEAMS_FULFILLED';

export const REMOVE_UNSAVED_TEAMS = 'REMOVE_UNSAVED_TEAMS';
export const ADD_TEAM_WITHOUT_SAVE = 'ADD_TEAM_WITHOUT_SAVE';

export const ADD_TEAM = 'ADD_TEAM';

export const CREATE_TEAMS = 'CREATE_TEAMS';
export const CREATE_TEAMS_FULFILLED = 'CREATE_TEAMS_FULFILLED';
export const UPDATE_TEAMS = 'UPDATE_TEAMS';
export const UPDATE_TEAMS_FULFILLED = 'UPDATE_TEAMS_FULFILLED';
export const BULK_DELETE_TEAMS = 'BULK_DELETE_TEAMS';
export const BULK_DELETE_TEAMS_FULFILLED = 'BULK_DELETE_TEAMS_FULFILLED';

export const UNDO_CREATE_TEAMS = 'UNDO_CREATE_TEAMS';
export const UNDO_CREATE_TEAMS_FULFILLED = 'UNDO_CREATE_TEAMS_FULFILLED';
export const UNDO_UPDATE_TEAMS = 'UNDO_UPDATE_TEAMS';
export const UNDO_UPDATE_TEAMS_FULFILLED = 'UNDO_UPDATE_TEAMS_FULFILLED';
export const UNDO_BULK_DELETE_TEAMS = 'UNDO_BULK_DELETE_TEAMS';
export const UNDO_BULK_DELETE_TEAMS_FULFILLED = 'UNDO_BULK_DELETE_TEAMS_FULFILLED';

export const SWITCH_TEAMS_ROW_ORDER = 'SWITCH_TEAMS_ROW_ORDER';
export const SWITCH_TEAMS_ROW_ORDER_FULFILLED = 'SWITCH_TEAMS_ROW_ORDER_FULFILLED';
export const SWITCH_TEAMS_ROW_ORDER_PENDING = 'SWITCH_TEAMS_ROW_ORDER_PENDING';
export const SWITCH_TEAMS_ROW_ORDER_REJECTED = 'SWITCH_TEAMS_ROW_ORDER_REJECTED';

export const CREATE_TEAM_ROADMAP = 'CREATE_TEAM_ROADMAP';
export const CREATE_TEAM_ROADMAP_FULFILLED = 'CREATE_TEAM_ROADMAP_FULFILLED';
export const DELETE_TEAM_ROADMAP = 'DELETE_TEAM_ROADMAP';
export const DELETE_TEAM_ROADMAP_FULFILLED = 'DELETE_TEAM_ROADMAP_FULFILLED';
export const BULK_DELETE_TEAM_ROADMAP = 'BULK_DELETE_TEAM_ROADMAP';
export const BULK_DELETE_TEAM_ROADMAP_FULFILLED = 'BULK_DELETE_TEAM_ROADMAP_FULFILLED';
