import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { TokenContainer } from '../styles';

import ConnectButton from 'routes/Settings/Integration/components/ConnectButton';
import RequiredFieldsLabel from './RequiredFieldsLabel';
import { getIntegrationLabel } from 'features/OneStepIntegration/helpers/getIntegrationLabel';

const OAuthConfig = ({ integrationConfigs, onClickConnect, isConnecting }) => {
  const integrationName = integrationConfigs.title;
  const authClientIdLabel = getIntegrationLabel(integrationConfigs, 'authCientId');
  const authClientSecretLabel = getIntegrationLabel(integrationConfigs, 'authClientSecret');

  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  const _allValid = () => {
    const validClientId = !!clientId;
    const validClientSecret = !!clientSecret;

    return [validClientId, validClientSecret].every(v => v === true);
  };

  return (
    <Grid container>
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <RequiredFieldsLabel
            integrationName={integrationName}
            hasCliendId
            authClientIdLabel={authClientIdLabel}
            authClientSecretLabel={authClientSecretLabel}
          />
        </Grid>
        <TokenContainer item xs={12}>
          <TextField
            id="integration-client-id-input"
            label={`Enter ${authClientIdLabel}`}
            value={clientId}
            onChange={e => setClientId(e.target.value)}
            required
            fullWidth
          />
        </TokenContainer>
        <TokenContainer item xs={12}>
          <TextField
            id="integration-client-secret-input"
            label={`Enter ${authClientSecretLabel}`}
            value={clientSecret}
            onChange={e => setClientSecret(e.target.value)}
            required
            fullWidth
          />
        </TokenContainer>
      </Grid>
      <Grid item xs={12}>
        <ConnectButton
          id="connect-integration-button"
          color="primary"
          disabled={!_allValid() || isConnecting}
          onClick={() => {
            const userIntegrationData = { clientId, clientSecret };

            onClickConnect(userIntegrationData);
          }}
        >
          Connect
        </ConnectButton>
      </Grid>
    </Grid>
  );
};

export default OAuthConfig;
