import { useDispatch, useSelector } from 'react-redux';
import { propEq } from 'ramda';

import { fetchProjectsForTimelineLightbox } from '../store';
import { isGettingTimelineLightboxProjects, selectProjectForTimelineLightbox, selectTotalByLayer } from '../store/selectors';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from '@dragonboat/permissions';
import { clearTimelineLightboxProjects } from '../store/actions';

const filterByLayer = layer => propEq('layer', layer);

const useTimelineLightboxData = ({ displayLayer }) => {
  const dispatch = useDispatch();

  const loadProjects = filtersForApi => {
    dispatch(fetchProjectsForTimelineLightbox({ filtersForApiByLevels: filtersForApi }));
  };

  const clearProjects = () => dispatch(clearTimelineLightboxProjects());

  const projects = useSelector(selectProjectForTimelineLightbox);
  const isLoadingProjects = useSelector(isGettingTimelineLightboxProjects);
  const totalByLayer = useSelector(selectTotalByLayer);

  return {
    projects: projects.filter(filterByLayer(displayLayer)),
    totalByLayer,

    ideas: projects.filter(filterByLayer(IDEA_LAYER)),
    initiatives: projects.filter(filterByLayer(INITIATIVE_LAYER)),
    bets: projects.filter(filterByLayer(BET_LAYER)),
    isLoaded: !isLoadingProjects,

    loadProjects,
    clearProjects,
  };
};

export default useTimelineLightboxData;
