import React from 'react';
import IconButton from '@material-ui/core/IconButton';

import theme from 'design-system/theme';

import { Flex, HeaderWrapper, StyledButton, StyledCloseIcon, StyledStarsIcon, StyledText } from './styles';

const Header = ({
  onClose,
  canSaveAutoTags,
  autoTagsCount,
  setAutoTagsAsRequestTags,
  rerunAutoTag,
  showMerge,
  canMergeRequests,
  onClickMergeRequests,
  showBulkUpdate,
  canBulkUpdate,
  onClickBulkUpdateRequests,
}) => (
  <HeaderWrapper>
    <Flex>
      <StyledStarsIcon />
      <StyledText>
        Review AI Tagged Items: <b>{autoTagsCount}</b>
      </StyledText>
    </Flex>
    <Flex>
      {showMerge && (
        <StyledButton
          color={theme.palette.white}
          hover={{ background: theme.palette.white, color: theme.palette.text.brand }}
          fontSize={theme.typography.fontSizeRem}
          width="115px"
          disabled={!canMergeRequests}
          onClick={onClickMergeRequests}
        >
          Merge
        </StyledButton>
      )}
      {showBulkUpdate && (
        <StyledButton
          color={theme.palette.white}
          hover={{ background: theme.palette.white, color: theme.palette.text.brand }}
          fontSize={theme.typography.fontSizeRem}
          width="150px"
          disabled={!canBulkUpdate}
          onClick={onClickBulkUpdateRequests}
        >
          Bulk Update
        </StyledButton>
      )}
      <StyledButton
        color={theme.palette.white}
        hover={{ background: theme.palette.white, color: theme.palette.text.brand }}
        fontSize={theme.typography.fontSizeRem}
        width="235px"
        disabled={!canSaveAutoTags}
        onClick={setAutoTagsAsRequestTags}
      >
        Copy Tags for selected
      </StyledButton>
      <StyledButton
        color={theme.palette.white}
        hover={{ background: theme.palette.white, color: theme.palette.text.brand }}
        fontSize={theme.typography.fontSizeRem}
        width="200px"
        onClick={rerunAutoTag}
      >
        Re-run Analyzer
      </StyledButton>
      <IconButton onClick={onClose}>
        <StyledCloseIcon />
      </IconButton>
    </Flex>
  </HeaderWrapper>
);

export default Header;
