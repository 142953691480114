const UNDEFINED_LABEL = 'Undefined';

export default (metricLevels, withUndefinedOption = false) => {
  if (!metricLevels) return [];

  const options = Object.keys(metricLevels)
    .sort((a, b) => metricLevels[a].rank - metricLevels[b].rank)
    .map(key => ({ title: metricLevels[key].title, id: key }));

  if (withUndefinedOption) {
    options.unshift({ title: UNDEFINED_LABEL, id: '' });
  }

  return options;
};
