import { createSelector } from 'reselect';
import { isEmpty, isNil, either, defaultTo, has, not } from 'ramda';

import { DEFAULT_PAGE_FILTERS, GLOBAL_FILTER } from 'constants/filters';
import convertStateFromOldFiltersToNewFilters from 'utils/filters/convertStateFromOldFiltersToNewFilters';
import { getPageIdFromPath } from 'utils/userViews';
import { filterNotAvailableLayersFromFilters } from 'utils/filters/filterNotAvailableLayersFromFilters';
import { getActiveViewForPage } from 'store/userViews/selectors';
import { PAGES_WITH_PORTFOLIO_FILTERS, SHARED_FILTERS_BY_PAGE } from 'store/userViews/consts';

import { getProjectLayers } from './getProjectLayers';

const isNilOrEmpty = either(isNil, isEmpty);
const defaultToEmptyObject = defaultTo({});
const hasLayerProperty = has('layer');

/**
 * Checks SHARED_FILTERS_BY_PAGE const to see if pageId
 * provided should use a shared page filter from state, or
 * filter used on active view for that page
 *
 * @param {*} pageId id of page for view requesting filter
 * @returns id of filter (if not using view filter) or null
 */
export const getSharedFilterIdFromPageId = pageId => {
  let filter = null;

  Object.entries(SHARED_FILTERS_BY_PAGE).forEach(([key, pages]) => {
    const match = pages.some(p => p === pageId);

    if (match) {
      filter = key;
    }
  });

  return filter;
};

/**
 * Based on the given inputs choose the appropriate filters to use
 *
 * @param {Object} filtersState - The page filters from store
 * @param {Object} activeView - The currently selected active view object
 * @param {Object} pageId - The page id of current view used to select filter from store
 *
 * @returns {Object} The filters object to be used for the page
 */
const chooseFiltersForPage = (filtersState, activeView, pageId) => {
  const filterId = getSharedFilterIdFromPageId(pageId);
  const pageFilters = filtersState[filterId];

  if (isNilOrEmpty(activeView)) {
    // Active view does not exist, use stored portfolio filters
    return pageFilters || filtersState[GLOBAL_FILTER];
  }

  if (activeView.isPublic) {
    return activeView.state?.filter;
  }

  const activeViewHasEmptyFilters = isNilOrEmpty(activeView?.state?.filter);

  if (pageFilters || activeViewHasEmptyFilters) {
    return pageFilters;
  }

  return activeView.state.filter;
};

function getState(state) {
  return state._filters;
}

export const getPageFilters = createSelector(
  [getState, state => getActiveViewForPage(state, getPageIdFromPath(window.location.pathname)), state => getProjectLayers(state)],
  (filtersState, pageActiveView, projectLayers) => {
    const { layers, bottomLayer, hasIdea } = projectLayers;
    const pageId = getPageIdFromPath(window.location.pathname);

    const filters = defaultToEmptyObject(chooseFiltersForPage(filtersState, pageActiveView, pageId));

    if (!filters.fields) {
      const { fields, childrenFields, layer, children } = convertStateFromOldFiltersToNewFilters(filters);

      filters.fields = fields;
      filters.childrenFields = childrenFields;
      filters.layer = layer;
      filters.children = children;
    }

    if (hasLayerProperty(filters) && isEmpty(filters.fields) && PAGES_WITH_PORTFOLIO_FILTERS.includes(pageId)) {
      filters.fields = DEFAULT_PAGE_FILTERS[GLOBAL_FILTER];
    }

    if (not(hasIdea)) {
      filterNotAvailableLayersFromFilters(filters, bottomLayer, layers);
    }

    return filters;
  },
);
