import { getTooltipText } from 'components/ganttCommon';

export default (gantt, zoom, columns) => {
  gantt.templates.drag_link_class = () => 'gantt_tooltip_hidden';
  gantt.templates.task_text = (start, end, task) => task.shortText || task.text;
  gantt.templates.grid_row_class = (start, end, task) => {
    const isGrouping = true;
    let classes = '';
    const children = gantt.getChildren(task.id);

    if (task.$has_child > 0 || (children.length && !task.group)) {
      classes += 'boldedParent ';
    }
    if (task.dbType === 'estimate') {
      classes += 'noPlus ';
    }
    if (task.$level > (isGrouping ? 4 : 3)) {
      classes += 'subtask ';
    }
    if (task.type === 'milestone') {
      classes += 'milestone ';
    }
    if (task.status_color) {
      classes += `ganttStatus-${task.status_color} `;
    }
    if (task.group && task.$level === 0) {
      classes += 'ganttGroupGridRow--timeline ';
    }
    if (task.group && task.$level > 0) {
      classes += 'ganttGroup2GridRow ';
    }

    return classes;
  };
  gantt.attachEvent('onBeforeLightbox', () => false);
  gantt.attachEvent('onBeforeLinkAdd', () => false);
  gantt.attachEvent('onBeforeLinkDelete', () => false);
  gantt.attachEvent('onLinkDblClick', () => false);

  gantt.templates.tooltip_text = getTooltipText;
  gantt.templates.rightside_text = (start, end, task) => (task.dbType === 'story' ? task.text : '');
  gantt.config.drag_move = false;
  gantt.config.drag_links = false;
  // gantt.config.readonly = true;
  gantt.config.drag_mode = {};
  gantt.config.columns = columns;

  if (zoom) {
    gantt.config.min_column_width = 70;
    gantt.config.scale_unit = 'quarter';
    gantt.config.date_scale = '%Y';
    gantt.config.scale_height = 60;
    gantt.config.subscales = [{ unit: 'month', step: 1, date: '%n' }];
  } else {
    gantt.config.scale_unit = 'month';
    gantt.config.step = 1;
    gantt.config.scale_height = 60;
    gantt.config.min_column_width = 70;
    gantt.config.date_scale = '%M %Y';
    gantt.config.subscales = [{ unit: 'week', step: 1, date: '%d' }];
  }
};
