import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FeatureFlags } from '@dragonboat/config';

import useQuery from 'hooks/useQuery';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { removeQueryParamFromUrl } from 'utils/queryParamsUtils';

const FILTER_KEY = 'filter';

const usePageQueryParamFilters = (cb = () => {}) => {
  const query = useQuery();
  const param = query.get(FILTER_KEY);

  const history = useHistory();

  const hasApplyFiltersFromQueryParam = useFeatureFlags([FeatureFlags.HAS_APPLY_FILTER_FROM_QUERY_PARAM]);

  const navigateToPageWithFilter = (path, filter) => {
    history.push(`${path}?${FILTER_KEY}=${filter}`);
  };

  const removeFilterFromQueryParams = () => {
    if (!hasApplyFiltersFromQueryParam) return null;
    removeQueryParamFromUrl(FILTER_KEY);
  };

  useEffect(() => {
    if (param && cb && hasApplyFiltersFromQueryParam) {
      cb(param);
    }
  }, [param, cb]);

  return {
    navigateToPageWithFilter,
    removeFilterFromQueryParams,
  };
};

export default usePageQueryParamFilters;
