import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MAPPING_TYPES } from 'constants/integrations';

import { updateIntegrationFieldMapping as updateIntegrationFieldMappingAction } from 'store/organization';
import { getOrganizationSystemFieldsNames } from 'store/organization/selectors';

import { addIdPropertyToFieldMappingEntries } from './helpers';

import useIntegrationLayerNames from '../../routes/Settings/Integration/IntegrationLayerNames/useIntegrationLayerNames';

export default Component => {
  return props => {
    const { onClose, integrationType, orgIntegrationId, mapping, layer } = props;

    const dispatch = useDispatch();

    const [currentMapping, setCurrentMapping] = useState(addIdPropertyToFieldMappingEntries(mapping));

    const systemFields = useSelector(state => getOrganizationSystemFieldsNames(state));

    const { namesByLayer: integrationLayerNames } = useIntegrationLayerNames(orgIntegrationId);

    const onSave = useCallback(() => {
      const mappingToSave = currentMapping.reduce((mapping, entry) => {
        if (entry.key && entry.integration?.key) {
          // eslint-disable-next-line no-unused-vars
          const { id, isNew, ...valueToSave } = entry;

          return [...mapping, valueToSave];
        }

        return mapping;
      }, []);

      dispatch(
        updateIntegrationFieldMappingAction(
          integrationType,
          orgIntegrationId,
          MAPPING_TYPES.PROJECTS,
          undefined,
          mappingToSave,
          layer,
        ),
      );
      onClose();
    }, [integrationType, orgIntegrationId, currentMapping, onClose, layer]);

    return (
      <Component
        systemFields={systemFields}
        integrationType={integrationType}
        orgIntegrationId={orgIntegrationId}
        mapping={currentMapping}
        onChange={setCurrentMapping}
        onClose={onClose}
        onSave={onSave}
        layer={layer}
        integrationLayerNames={integrationLayerNames}
      />
    );
  };
};
