export default (projects, projectIndex, estimateIndex, updateCallback) => {
  if (!(estimateIndex >= 0) || !(projectIndex >= 0)) {
    return projects;
  }

  return projects.update(projectIndex, project => {
    if (!project.has('estimates')) {
      return project;
    }

    const estimates = project.get('estimates').update(estimateIndex, updateCallback);

    project = project.set('estimates', estimates);
    return project;
  });
};
