import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import ImportIcon from '@material-ui/icons/CloudUploadOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import { filter, pipe, length, gte, equals } from 'ramda';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import GroupByDescription from 'design-system/atoms/GroupByDescription/index';
import DependenciesTogglePopover from 'design-system/molecules/DependenciesTogglePopover/index';
import ToggleButton from 'design-system/molecules/ToggleButton/index';
import Loading from 'design-system/atoms/Loading/Loading';
import ShowFieldsIcon from 'design-system/atoms/ShowFieldsIcon/index';


import theme from 'design-system/theme';

import ShareView from 'src/containers/ShareView';
import { isGroupFieldADate } from 'utils/grouping';
import { checkRolePermission } from 'containers/UserPermission/utils';
import PreferencesDialog from './PreferencesDialog';
import EditOff from './images/EditOff';
import useSummaryDependencyLinesManagement from 'hooks/useSummaryDependencyLinesManagement';
import GroupByAutoCompleteWithLargeDropdown from 'design-system/atoms/GroupByAutoCompleteWithLargeDropdown';
import { getGroupOptionsSelector as getGroupOptionsSelectorDefault } from 'store/projects/groupSelectors';
import LocalSearchInput from 'containers/LocalSearchInput';

import { useSummaryTableData } from '../hooks/useSummaryTableData';
import useSummaryState from '../hooks/useSummaryState';
import downloadSummaryPNG from '../helpers/downloadSummaryPNG';
import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/userViews';
import useMetadataGroupByOptions from 'hooks/useMetadataGroupByOptions';
import ShowMyItemsToggle from 'containers/ShowMyItemsToggle';
import { CreateRoadmapSnapshotButton } from 'features/RoadmapHistory/components';

const READ_ONLY_MODE_ONLY_FIELDS = ['customers', 'dates', 'tags'];

const GROUP_BY_MIN_WIDTH = '50px';
const GROUP_BY_FONT_SIZE = `${theme.typography.fontSizeSmallLarge}px`;
const SMALL_GROUP_BY_MIN_WIDTH = '40px';
const SMALL_GROUP_BY_FONT_SIZE = `${theme.typography.fontSize}px`;

const maxOneIsDateGroupField = pipe(filter(isGroupFieldADate), length, gte(1));
const moreDetailsFieldsSet = ['title', 'progress', 'status_color'];
const lessDetailsFieldsSet = ['title'];

const ActionBarComponent = React.memo(
  ({
    hasProducts,
    hasProducts2,
    hasKeyResults,
    hasKeyResults2,
    hasHierarchy,
    currentUser,
    showImportIdeasLightbox,
    isRoadmapVersionsEnabled,
    isLoading,
    setIsLoading,
    isGoalMode,
    getGroupOptionsSelector = getGroupOptionsSelectorDefault,
    toggleDraftMode,
    isRoadmapVersionCommitted,
    isCreatingOrViewingScenario,
    slice,
    hasShowMyItemFilter = false,
    viewsDropdownTabs,
    hasRoadmapHistory,
  }) => {
    const [showMenu, setShowMenu] = React.useState();
    const [showDisplayPreferences, setShowDisplayPreferences] = React.useState();

    const isShareViewVisible = !isCreatingOrViewingScenario;
    const isImportVisible = !isCreatingOrViewingScenario;
    const path = getCurrentPath();
    const pageId = getPageIdFromPath(path);

    const {
      isReadOnly,
      selectedColOption,
      selectedRowOption,
      selectedGroupByOption,
      hideEmptyLanes,
      showMyItemsOnly,
      dateFieldToGroup,
      groupDatesBy,
      allCollapsed,
      updateState,
      updateDisplayFields,
      displayPreferences,
      userCanToggleLanesVisibility,
    } = useSummaryState(isGoalMode, slice);

    const hasShowDetailsOn = equals(moreDetailsFieldsSet, displayPreferences?.displayFields);

    const { toggleAllTableDataGroups } = useSummaryTableData();

    const _handleMenuItemClick = React.useCallback(() => {
      setShowDisplayPreferences(true);
      setShowMenu(null);
    });
    const _handleMenuBoxClose = React.useCallback(() => setShowMenu(null));
    const _handleMoreOptionsButtonClick = React.useCallback(e => setShowMenu(e.currentTarget));
    const _handleShowPreferencesClick = React.useCallback(() => setShowDisplayPreferences(false));
    const _handleReadOnlyClick = React.useCallback(() => {
      updateState({ isReadOnly: !isReadOnly });
      setShowMenu(null);
    }, [isReadOnly]);
    const _handleHideEmptyLanesClick = React.useCallback(() => {
      updateState({ hideEmptyLanes: !hideEmptyLanes });
      setShowMenu(null);
    }, [hideEmptyLanes]);
    const _handleShowDetailsClick = React.useCallback(() => {
      updateDisplayFields(hasShowDetailsOn ? lessDetailsFieldsSet : moreDetailsFieldsSet);
      setShowMenu(null);
    });

    const groupingOptions = useMemo(
      () => ({
        dynamicDates: { field: dateFieldToGroup?.key, groupBy: groupDatesBy?.key },
        withNullOption: true,
        withTags: true,
        withCustomers: true,
      }),
      [],
    );
    const _groupByOptionsFromStore = useSelector(state => getGroupOptionsSelector(state, groupingOptions));

    const rowColGroupingOptions = useMemo(
      () => ({
        dynamicDates: { field: dateFieldToGroup?.key, groupBy: groupDatesBy?.key },
        withTags: true,
        withCustomers: true,
      }),
      [],
    );
    const commonRowColOptionsFromStore = useSelector(state => getGroupOptionsSelector(state, rowColGroupingOptions));

    const { groupByOptions: _groupByOptions } = useMetadataGroupByOptions(_groupByOptionsFromStore);
    const { groupByOptions: commonRowColOptions } = useMetadataGroupByOptions(commonRowColOptionsFromStore);

    const _onChangeRowOrColData = (key, val) => {
      const stateToUpdate = { [key]: val };

      if (READ_ONLY_MODE_ONLY_FIELDS.includes(val.key) && !isReadOnly) stateToUpdate.isReadOnly = true;
      else stateToUpdate.isReadOnly = isReadOnly;

      updateState(stateToUpdate);
    };
    const _onChangeChipData = d => {
      return updateState({
        groupByData: d,
        isReadOnly: READ_ONLY_MODE_ONLY_FIELDS.includes(d.key) || isReadOnly,
      });
    };

    const {
      showDependencyLines,
      showAllDependencies,
      hideItemsWithoutDependencies,

      actions: { dependencyLinesToggle, allDependenciesToggle, itemsWithoutDependenciesToggle },
    } = useSummaryDependencyLinesManagement();

    const dependencyLinesOnNewSummaryVersionAreDisabled =
      localStorage.getItem('disableDependencyLinesOnNewSummaryVersion') === 'true';

    const _onDownloadPngClick = () => {
      setShowMenu(null);
      setIsLoading(true);
      downloadSummaryPNG().then(() => {
        setIsLoading(false);
      });
    };

    const shouldDisplayHideLanesToggle = isGoalMode || !userCanToggleLanesVisibility;

    return (
      <Wrapper data-html2canvas-ignore>
        {isLoading && <StyledLoading />}
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <FormGroup row>
              <StyledGroupByAutoCompleteWithLargeDropdown
                name="groupByData"
                suggestions={_groupByOptions.filter(o => maxOneIsDateGroupField([o, selectedRowOption, selectedColOption]))}
                value={selectedGroupByOption}
                onChange={_onChangeChipData}
                data-testid="dropdown-groupBy"
                inputStyle={{ fontSize: SMALL_GROUP_BY_FONT_SIZE }}
                inputContainerStyle={{ minWidth: SMALL_GROUP_BY_MIN_WIDTH }}
              />
            </FormGroup>
          </Grid>
          <ReadOnlyDropdowns item xs={4}>
            <GroupByAutoCompleteWithLargeDropdown
              label=""
              name="selectedColData"
              suggestions={commonRowColOptions.filter(o => maxOneIsDateGroupField([o, selectedRowOption, selectedGroupByOption]))}
              value={selectedColOption}
              onChange={option => {
                if (option)
                  _onChangeRowOrColData(
                    'selectedColData',
                    commonRowColOptions.find(o => o.key === option.key),
                  );
              }}
              data-testid="dropdown-column"
              inputStyle={{ fontSize: GROUP_BY_FONT_SIZE }}
              inputContainerStyle={{ minWidth: GROUP_BY_MIN_WIDTH }}
            />
            {selectedColOption?.key && (
              <>
                <GroupByDescription text="by" paddingTop={0} />
                <GroupByAutoCompleteWithLargeDropdown
                  label=""
                  name="selectedRowData"
                  suggestions={commonRowColOptions.filter(o =>
                    maxOneIsDateGroupField([o, selectedColOption, selectedGroupByOption]),
                  )}
                  value={selectedRowOption}
                  onChange={option => {
                    if (option)
                      _onChangeRowOrColData(
                        'selectedRowData',
                        commonRowColOptions.find(o => o.key === option.key),
                      );
                  }}
                  data-testid="dropdown-row"
                  inputStyle={{ fontSize: GROUP_BY_FONT_SIZE }}
                  inputContainerStyle={{ minWidth: GROUP_BY_MIN_WIDTH }}
                />
              </>
            )}
          </ReadOnlyDropdowns>

          <Grid item xs={4}>
            <FormGroup row style={{ justifyContent: 'flex-end' }}>
              <LocalSearchInput />
              {hasRoadmapHistory && <CreateRoadmapSnapshotButton WrapperComponent={ControlItem} />}
              {hasShowMyItemFilter && (
                <ControlItem>
                  <ShowMyItemsToggle
                    showMyItemsOnly={showMyItemsOnly}
                    updateShowMyItemsOnly={() => updateState({ showMyItemsOnly: !showMyItemsOnly })}
                  />
                </ControlItem>
              )}
              {!isGoalMode && (
                <ControlItem>
                  <DependenciesTogglePopover
                    withoutDependencies={dependencyLinesOnNewSummaryVersionAreDisabled}
                    showDependencyLines={showDependencyLines}
                    onDependencyLinesToggle={dependencyLinesToggle}
                    showAllDependencies={showAllDependencies}
                    onAllDependenciesToggle={allDependenciesToggle}
                    hideItemsWithoutDependencies={hideItemsWithoutDependencies}
                    onItemsWithoutDependenciesToggle={itemsWithoutDependenciesToggle}
                    hideShowAllDependencies
                  />
                </ControlItem>
              )}

              {selectedGroupByOption && selectedGroupByOption.key && (
                <ControlItem>
                  <ButtonIcon
                    title={allCollapsed ? 'Expand all' : 'Collapse all'}
                    onClick={toggleAllTableDataGroups}
                    id="expand-collapse"
                    data-testid="expand-collapse"
                  >
                    {allCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </ButtonIcon>
                </ControlItem>
              )}
              {isShareViewVisible && (
                <ControlItem>
                  <ShareView pageId={pageId} viewsDropdownTabs={viewsDropdownTabs} id="view-icon" />
                </ControlItem>
              )}
              {!isRoadmapVersionCommitted && (
                <ControlItem
                  id="port-3d-more-options"
                  data-testid="port-3d-more-options"
                  aria-controls="display-options"
                  aria-haspopup="true"
                  onClick={_handleMoreOptionsButtonClick}
                >
                  <ToggleButton icon={<MoreHorizIcon />} />
                </ControlItem>
              )}
              <MenuBox
                id="display-options"
                anchorEl={showMenu}
                keepMounted
                open={Boolean(showMenu)}
                onClose={_handleMenuBoxClose}
              >
                {!isGoalMode && (
                  <>
                    <StyledMenuItem onClick={_handleMenuItemClick}>
                      <MenuItemWrapper>
                        <SettingsIcon />
                        <MenuItemText>Display Preferences</MenuItemText>
                      </MenuItemWrapper>
                      <PreferencesDialog
                        open={showDisplayPreferences}
                        onCancel={_handleShowPreferencesClick}
                        hasProducts={hasProducts}
                        hasProducts2={hasProducts2}
                        hasKeyResults={hasKeyResults}
                        hasKeyResults2={hasKeyResults2}
                        hasHierarchy={hasHierarchy}
                        groupByOptions={_groupByOptions}
                        commonRowColOptions={commonRowColOptions}
                      />
                    </StyledMenuItem>
                    {/* TODO: PERMISSION  */}
                    {isImportVisible && checkRolePermission('IMPORT_IDEAS', currentUser) && (
                      <StyledMenuItem
                        onClick={() => {
                          showImportIdeasLightbox();
                          setShowMenu(null);
                        }}
                      >
                        <MenuItemWrapper>
                          <ImportIcon />
                          <MenuItemText>Import</MenuItemText>
                        </MenuItemWrapper>
                      </StyledMenuItem>
                    )}
                    {/* TODO: PERMISSION  */}
                    <StyledMenuItem
                      onClick={_handleReadOnlyClick}
                      disabled={
                        (selectedColOption && READ_ONLY_MODE_ONLY_FIELDS.includes(selectedColOption.key)) ||
                        (selectedRowOption && READ_ONLY_MODE_ONLY_FIELDS.includes(selectedRowOption.key)) ||
                        (selectedGroupByOption && READ_ONLY_MODE_ONLY_FIELDS.includes(selectedGroupByOption.key))
                      }
                    >
                      <MenuItemWrapper>
                        {isReadOnly ? <EditOff /> : <CreateOutlinedIcon />}
                        <MenuItemText>Read Only</MenuItemText>
                      </MenuItemWrapper>
                    </StyledMenuItem>
                  </>
                )}
                {shouldDisplayHideLanesToggle ? (
                  <StyledMenuItem onClick={_handleHideEmptyLanesClick}>
                    <MenuItemWrapper>
                      {!hideEmptyLanes ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      <MenuItemText>Hide empty lanes</MenuItemText>
                    </MenuItemWrapper>
                  </StyledMenuItem>
                ) : null}
                {isGoalMode && (
                  <StyledMenuItem
                    onClick={() => {
                      _handleShowDetailsClick();
                    }}
                  >
                    <MenuItemWrapper>
                      <ShowFieldsIcon style={{ fontSize: '24px' }} fill="#131C23" />
                      <MenuItemText>{hasShowDetailsOn ? 'Hide details' : 'Show details'}</MenuItemText>
                    </MenuItemWrapper>
                  </StyledMenuItem>
                )}
                {!isGoalMode && (
                  <StyledMenuItem onClick={_onDownloadPngClick}>
                    <MenuItemWrapper>
                      <PhotoCamera />
                      <MenuItemText>Download PNG</MenuItemText>
                    </MenuItemWrapper>
                  </StyledMenuItem>
                )}
              </MenuBox>
            </FormGroup>
          </Grid>
        </Grid>
      </Wrapper>
    );
  },
);

ActionBarComponent.displayName = 'ActionBar';

export default ActionBarComponent;

const Wrapper = styled.div`
  min-height: 50px;
  margin: 5px 0;
`;

const ControlItem = styled.div`
  width: 46px;
  display: flex;

  &:not(:last-of-type) {
    margin-right: ${({ theme }) => theme.spacing.unit * 0.65}px; // 5px
  }
`;

const MenuBox = styled(Menu)`
  &&&& {
    margin-top: 50px;
  }
`;

const MenuItemWrapper = styled.div`
  &&&& {
    display: flex;
    align-items: end;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &&&& {
    height: 14px;
    font-size: ${props => props.theme.typography.fontSize}px;
  }
`;

const MenuItemText = styled.span`
  &&&& {
    padding-left: 13px;
    font-size: ${props => props.theme.typography.fontSize}px;
  }
`;

const ReadOnlyDropdowns = styled(Grid)`
  &&&& {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 6px;
  }
`;

const StyledLoading = styled(Loading)`
  &&&& {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 1000;
  }
`;

const StyledGroupByAutoCompleteWithLargeDropdown = styled(GroupByAutoCompleteWithLargeDropdown)`
  padding-top: 10px;
`;
