import { getThunkActionTypes } from 'utils/store/thunk';

export const CREATE_GRID_PERSONA = 'CREATE_GRID_PERSONA';
export const REMOVE_GRID_UNSAVED_PERSONA = 'REMOVE_GRID_UNSAVED_PERSONA';
export const CHANGE_ROW_HEIGHT_PERSONA_GRID = 'CHANGE_ROW_HEIGHT_PERSONA_GRID';
export const CHANGE_HIDE_ARCHIVED_PERSONA_GRID = 'CHANGE_HIDE_ARCHIVED_PERSONA_GRID';

const actionTypes = {
  ...getThunkActionTypes('UPDATE_GRID_PERSONA'),
  ...getThunkActionTypes('SAVE_GRID_NEW_PERSONA'),
  ...getThunkActionTypes('DELETE_GRID_PERSONA'),
  ...getThunkActionTypes('FETCH_PERSONAS_GRID_DATA'),
  ...getThunkActionTypes('CREATE_PERSONA_ROADMAP'),
  ...getThunkActionTypes('DELETE_PERSONA_ROADMAP'),
  ...getThunkActionTypes('BULK_DELETE_PERSONA_ROADMAP'),
  ...getThunkActionTypes('SWITCH_PERSONAS_GRID_ROW_ORDER'),
};

export const {
  UPDATE_GRID_PERSONA,
  UPDATE_GRID_PERSONA_FULFILLED,
  UPDATE_GRID_PERSONA_PENDING,
  FETCH_PERSONAS_GRID_DATA,
  FETCH_PERSONAS_GRID_DATA_FULFILLED,
  SAVE_GRID_NEW_PERSONA,
  SAVE_GRID_NEW_PERSONA_FULFILLED,
  DELETE_GRID_PERSONA,
  DELETE_GRID_PERSONA_FULFILLED,
  CREATE_PERSONA_ROADMAP,
  CREATE_PERSONA_ROADMAP_FULFILLED,
  DELETE_PERSONA_ROADMAP,
  DELETE_PERSONA_ROADMAP_FULFILLED,
  BULK_DELETE_PERSONA_ROADMAP,
  BULK_DELETE_PERSONA_ROADMAP_FULFILLED,
  SWITCH_PERSONAS_GRID_ROW_ORDER,
  SWITCH_PERSONAS_GRID_ROW_ORDER_PENDING,
  SWITCH_PERSONAS_GRID_ROW_ORDER_REJECTED,
  SWITCH_PERSONAS_GRID_ROW_ORDER_FULFILLED,
} = actionTypes;
