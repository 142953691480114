import React, { useEffect } from 'react';
import styled from 'styled-components';

import GridControls from './components/GridControls';
import SnapshotTable from 'src/routes/Dashboard/Snapshot/SnapshotTable/index.js';
import BaseLayout from 'design-system/organisms/BaseLayout/index';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';
import MultiSelectDropdown2ColumnsPopover from 'design-system/organisms/MultiSelectDropdown2ColumnsPopover/index';

import { spacing } from 'design-system/theme';
import useGoalModeGridControls from './hooks/useGoalModeGridControls';
import useOpenMetricFromEntityWithMetrics from 'hooks/useOpenMetricFromEntityWithMetrics';
import useGoalModeGrid from './hooks/useGoalModeGrid';

import getSystemFieldName from 'utils/getSystemFieldName';
import { GOAL_MODE } from 'store/grids';
import { DEFAULT_TABLE_HEIGHT } from 'constants/grid';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { loadOKRsOnOutcomeModulePageLoad } from 'store/goalMode/thunks';
import { useDispatch } from 'react-redux';

const GoalsGrid = () => {
  const dispatch = useDispatch();

  const {
    isTreeView,
    expandItems,
    tableVisibleFields = [],
    moreButtonRef,
    pageSize,

    setShowTreeView,
    setPageSize,
    handleExpandClick,
    handleTableShowFieldsClicked,

    tableShowFieldsEl,
    setTableShowFieldsEl,
    leftItems,
    rightItems,
    handleShowAll,
    handleHideAll,
    handleChangeFields,
    handleExportToCsv,
  } = useGoalModeGridControls();

  const {
    isLoading,
    rowHeight,
    gridState,
    objectiveTopLevelKey,
    goalsRowsData,
    hasKeyResults,
    hasKeyResults2,
    currentUser,
    hasAdvancedMetricReporting,
    onUpdateGridConfig,
    onSetPageUserView,
    handleUpdateMetadataFieldById,
    saveGridState,
    createMetricOption,
    searchString,
    updateSearchString,
  } = useGoalModeGrid(isTreeView);

  useEffect(() => {
    dispatch(loadOKRsOnOutcomeModulePageLoad());
  }, []);

  const { handleOpenMetricView } = useOpenMetricFromEntityWithMetrics();

  const { canView } = usePermissions();

  const gridControlsBar = canView(PERMISSION_FEATURES.controlsBar) ? (
    <GridControls
      onUpdateGridConfig={onUpdateGridConfig}
      setShowTreeView={setShowTreeView}
      expandItems={expandItems}
      handleCollapseExpandClick={handleExpandClick}
      handleTableShowFieldsClicked={handleTableShowFieldsClicked}
      moreButtonRef={moreButtonRef}
      searchString={searchString}
      updateSearchString={updateSearchString}
      onSetPageUserView={onSetPageUserView}
      handleExportToCsv={handleExportToCsv}
    />
  ) : null;

  if (isLoading) {
    return (
      <BaseLayout>
        {gridControlsBar}
        <PageLoading />
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      {gridControlsBar}
      <GridWrapper>
        {/* Leveraging Snapshot table */}
        <SnapshotTable
          title={getSystemFieldName(objectiveTopLevelKey)}
          selectedSnapshotField={objectiveTopLevelKey}
          snapshotTableVisibleFields={tableVisibleFields}
          rows={goalsRowsData}
          openMetricView={handleOpenMetricView}
          onUpdateMetadataFieldById={handleUpdateMetadataFieldById}
          hasKeyResults={hasKeyResults}
          hasKeyResults2={hasKeyResults2}
          rowHeight={rowHeight}
          pageSize={pageSize}
          onUpdatePageSize={setPageSize}
          hasAdvancedMetricReporting={hasAdvancedMetricReporting}
          currentUser={currentUser}
          expandTableItems={expandItems}
          withPagination={false}
          overrideTableHeight={DEFAULT_TABLE_HEIGHT}
          showSnapshotTreeView
          gridState={gridState}
          saveGridState={saveGridState}
          createMetricOption={createMetricOption}
          isGoalMode
          gridId={GOAL_MODE}
          searchString={searchString}
        />
      </GridWrapper>
      <MultiSelectDropdown2ColumnsPopover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!tableShowFieldsEl}
        anchorEl={tableShowFieldsEl}
        onClose={() => {
          setTableShowFieldsEl(null);
        }}
        leftItems={leftItems}
        rightItems={rightItems}
        selected={tableVisibleFields}
        handleShowAll={handleShowAll}
        handleHideAll={handleHideAll}
        onChange={handleChangeFields}
      />
    </BaseLayout>
  );
};

const GridWrapper = styled.div`
  margin-top: ${spacing(3)}px;
`;

export default GoalsGrid;
