import React, { useCallback } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import ImportIcon from '@material-ui/icons/CloudUploadOutlined';
import isEmpty from 'lodash/isEmpty';
import { useDebouncedCallback } from 'use-debounce';

import { DEFAULT_SETTINGS_NAME } from 'config';
import SearchBar from 'components/SearchBar';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import EditSystemSettingName from 'design-system/organisms/EditSystemSettingName/index';
import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete/index';

import getSystemFieldName from 'utils/getSystemFieldName';

const SEARCH_BAR_STYLES = { width: 160 };

const defaultCheckShouldDisableBulkDelete = () => false;

const SettingsActionsToolbar = ({
  settingKey,
  childrenSettingKeys,
  settingType,
  renamePermissions = {
    canView: false,
    canEdit: false,
  },
  systemFields,
  updateSystemFieldsName,
  // Selection
  selectionMode: isDisplayingSelectionBoxes,
  selectedItems = [],
  onEnableSelection,
  onCancelSelection,
  // Control
  canBulkDelete,
  canMerge,
  hideAddButton,
  // Operations
  add,
  importCsv,
  // Search
  searchText = '',
  updateSearchText,
  // Grouping
  canGroupBy,
  groupByOptions = [],
  selectedGroupByOption,
  handleGroupByChange,
  // Misc
  additionalActions,
  showMergeLightbox,
  setShowBulkDeleteDialog,
  checkShouldDisableBulkDelete = defaultCheckShouldDisableBulkDelete,
}) => {
  const handleMerge = useCallback(() => {
    if (canMerge) {
      return !isDisplayingSelectionBoxes ? onEnableSelection() : showMergeLightbox(settingType);
    }
  }, [settingType, isDisplayingSelectionBoxes, onEnableSelection, showMergeLightbox]);

  const handleBulkDelete = useCallback(() => {
    if (canBulkDelete && setShowBulkDeleteDialog) {
      return !isDisplayingSelectionBoxes ? onEnableSelection() : setShowBulkDeleteDialog(true);
    }
  }, [settingType, isDisplayingSelectionBoxes, onEnableSelection, setShowBulkDeleteDialog]);

  const [debouncedUpdateSearchText] = useDebouncedCallback(updateSearchText, 500);

  return (
    <Wrapper>
      {canGroupBy && (
        <GroupByAutocomplete suggestions={groupByOptions} value={selectedGroupByOption} onChange={handleGroupByChange} />
      )}
      {isDisplayingSelectionBoxes && onCancelSelection && <CancelButton onClick={() => onCancelSelection()}>Cancel</CancelButton>}
      {!hideAddButton && !isDisplayingSelectionBoxes && (
        <AddNewButton id="setting-add-new-button" color="primary" onClick={() => add()}>
          + New
        </AddNewButton>
      )}
      <RightButtons>
        {renamePermissions.canView && (
          <EditSystemSettingName
            canEdit={renamePermissions.canUpdate}
            field={settingKey}
            defaultTitle={DEFAULT_SETTINGS_NAME[settingKey]}
            renamedTitle={getSystemFieldName(settingKey, systemFields)}
            onRenameField={value => updateSystemFieldsName(value)}
          />
        )}
        {renamePermissions.canView &&
          !isEmpty(childrenSettingKeys) &&
          childrenSettingKeys.map(key => (
            <EditSystemSettingName
              canEdit={renamePermissions.canUpdate}
              field={key}
              defaultTitle={DEFAULT_SETTINGS_NAME[key]}
              renamedTitle={getSystemFieldName(key, systemFields)}
              onRenameField={value => updateSystemFieldsName(value)}
            />
          ))}
        <OtherActionsContainer>
          {updateSearchText && (
            <SearchBarContainer>
              <SearchBar searchString={searchText} updateSearchString={debouncedUpdateSearchText} style={SEARCH_BAR_STYLES} />
            </SearchBarContainer>
          )}
          {additionalActions}
        </OtherActionsContainer>
        {canMerge && (
          <ButtonIcon onClick={handleMerge} title="Merge" disabled={isDisplayingSelectionBoxes && selectedItems?.length < 2}>
            <MergeTypeIcon />
          </ButtonIcon>
        )}
        {canBulkDelete && (
          <ButtonIcon
            onClick={handleBulkDelete}
            title="Bulk Delete"
            disabled={isDisplayingSelectionBoxes && (!selectedItems?.length || checkShouldDisableBulkDelete(selectedItems))}
          >
            <DeleteSweepIcon />
          </ButtonIcon>
        )}
        {importCsv && (
          <ButtonIcon onClick={importCsv} title="Import CSV">
            <ImportIcon />
          </ButtonIcon>
        )}
      </RightButtons>
    </Wrapper>
  );
};

export default SettingsActionsToolbar;

const Wrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 26px 5px 36px;
`;

const RightButtons = styled.div`
  display: inline-flex;
  margin-left: auto;
`;

const CancelButton = styled(Button)`
  &&&& {
    margin-left: 30px;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
`;

const AddNewButton = styled(Button)`
  &&&& {
    margin-top: 4px;
  }
`;

const OtherActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: 8px;
`;

const SearchBarContainer = styled.div`
  margin-right: 20px;
`;
