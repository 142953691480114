import React from 'react';
import styled from 'styled-components';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import { spacing } from 'design-system/theme';

import useHandleSortCustomHeader from 'design-system/molecules/AgGridReact-New/helpers/useHandleSortCustomHeader';

const SortableHeaderWithIconCellRenderer = ({ icon, hasIcon, onIconClick, ...params }) => {
  const { renderSortIcon, handleChangeSort, handleOnClick } = useHandleSortCustomHeader(params, onIconClick);

  return (
    <Container onClick={handleChangeSort}>
      <Title className="ag-header-cell-text">{params.column.colDef.headerName}</Title>
      {renderSortIcon()}
      {hasIcon ? <StyledButtonIcon onClick={handleOnClick}>{icon}</StyledButtonIcon> : null}
    </Container>
  );
};

export default SortableHeaderWithIconCellRenderer;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const Title = styled.span``;

const StyledButtonIcon = styled(ButtonIcon)`
  && {
    position: absolute;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    right: ${spacing()}px;
    padding: ${spacing(0.5)}px;

    svg {
      width: 1em;
      height: 1em;
      font-size: ${({ theme }) => theme.sizing.icons.small}rem;
      fill: ${({ theme }) => theme.palette.text.secondary};
    }
  }
`;
