// External dependencies
import React, { useState, useEffect, Fragment, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { defaultTo, not, omit } from 'ramda';

// Dragonboat dependencies
import Dialog from 'design-system/molecules/Dialog/index';
import Autocomplete from 'design-system/atoms/Autocomplete/index';
import Checkbox from 'design-system/atoms/Checkbox/index';

import { ConfirmDialog } from 'components/gridCommon';
import formatDateTime from 'utils/dates/formatDateTime';
import { getUserName } from 'utils';

import { estimateDefaults, parseEstimateToForm, parseForm } from './utils';
import EstimateDialogHeader from './EstimateDialogHeader';
import EstimateHistory from './EstimateHistory';
import { mapToAutocompleteSuggestion } from 'utils/mapToAutocompleteSuggestion';
import getUnroundedFixedDecimalValue from 'utils/getUnroundedFixedDecimalValue';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const defaultEmptyString = defaultTo('');
const WEEK_DAYS = 7;
const WORK_DAYS = 5;
const BUSINESS_DAY = 0.2;

const convertStoryPointsIntoDurationInWeeks = (storyPoints, workdaysPerPoint) => {
  const convertedDurationValue = BUSINESS_DAY * workdaysPerPoint * storyPoints;

  return convertedDurationValue;
};

const convertDurationWeeksIntoStoryPoints = (durationWeeks, workdaysPerPoint) => {
  const pointsFromDuration = durationWeeks / (BUSINESS_DAY * workdaysPerPoint);

  return pointsFromDuration;
};

const getUpdatedPointsAndDurationOnTeamChange = (
  newTeam,
  isNewTeamEstimatingByPoints,
  isOldCurrentTeamByPointsEnabled,
  formData,
) => {
  const { workdays_per_point: newWorkdaysPerPoint = 1 } = newTeam;
  const { durationWeeks, storyPoints } = formData;

  if (isOldCurrentTeamByPointsEnabled && isNewTeamEstimatingByPoints) {
    const durationWeeks = convertStoryPointsIntoDurationInWeeks(storyPoints, newWorkdaysPerPoint);
    const newDuration = durationWeeks * WEEK_DAYS;

    return {
      duration: newDuration,
      durationWeeks,
    };
  }
  if (!isOldCurrentTeamByPointsEnabled && isNewTeamEstimatingByPoints) {
    const newStoryPoints = convertDurationWeeksIntoStoryPoints(durationWeeks, newWorkdaysPerPoint);

    return {
      storyPoints: newStoryPoints,
    };
  }
};

const EstimateDialog = ({
  estimate,
  projectId,
  teams,
  skills,
  isLocked,
  open,
  onOpen,
  onClose,
  updateEstimate,
  createEstimate,
  deleteEstimate,
  getSystemFieldName,
  project,
}) => {
  const [currentTab, setCurrentTab] = useState('details');
  const [showDialog, setShowDialog] = useState(open);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const defaultData = estimate ? parseEstimateToForm(estimate) : estimateDefaults;
  const [formData, setFormData] = useState(defaultData);
  const [selectedEstimate, setSelectedEstimate] = useState(estimate);
  const [selectedProjectId, setSelectedProjectId] = useState(projectId);
  const [selectedProject, setSelectedProject] = useState(project);
  const [readOnly, setReadOnly] = useState(isLocked);
  const { canUpdate, canView } = usePermissions();

  const currentTeam = teams.find(t => t.id === formData?.team?.id);

  const workdaysPerPoint = currentTeam?.workdays_per_point;
  const canViewStoryPoints = canView(PERMISSION_FEATURES.usePointsToEstimate, {
    layer: (selectedProject || project)?.layer,
    team: currentTeam,
  });
  const renderStoryPointsField = canViewStoryPoints && !!currentTeam;
  const estimationByPointsEnabled = canUpdate(PERMISSION_FEATURES.projectEstimateByPoints, {
    project: selectedProject || project,
    team: currentTeam,
  });

  const teamsSuggestions = useMemo(() => (teams || []).map(mapToAutocompleteSuggestion), [teams]);
  const skillsSuggestions = useMemo(() => (skills || []).map(mapToAutocompleteSuggestion), [skills]);

  const openTrigger = (est, project, readOnly) => {
    setSelectedEstimate(est);
    setSelectedProjectId(project?.id);
    setReadOnly(readOnly);
    setShowDialog(true);
    setSelectedProject(project);
  };

  useEffect(() => onOpen(openTrigger), []);
  useEffect(() => setShowDialog(open), [open]);
  useEffect(() => {
    const data = selectedEstimate ? parseEstimateToForm(selectedEstimate) : estimateDefaults;

    setFormData(data);
  }, [showDialog]);
  useEffect(() => setSelectedEstimate(estimate), [estimate]);
  useEffect(() => setSelectedProjectId(projectId), [projectId]);
  useEffect(() => setReadOnly(isLocked), [isLocked]);

  const changeFormValue = (field, transformValue) => v => {
    setFormData({ ...formData, [field]: transformValue ? transformValue(v) : v });
  };

  const onStoryPointsChange = e => {
    if (!estimationByPointsEnabled) return;
    const { startDate } = formData;
    const { value } = e.target;
    const durationWeeks = convertStoryPointsIntoDurationInWeeks(value, workdaysPerPoint);
    const duration = durationWeeks * WEEK_DAYS;

    setFormData({
      ...formData,
      duration,
      durationWeeks,
      endDate: calcEndDate(startDate, durationWeeks),
      storyPoints: value,
    });
  };

  const onDurationChange = e => {
    const { startDate } = formData;
    const value = getUnroundedFixedDecimalValue(e.target.value, 2);
    const duration = value * WEEK_DAYS;

    setFormData({
      ...omit(['storyPoints'], formData),
      durationWeeks: value,
      duration,
      endDate: calcEndDate(startDate, value),
      ...(canViewStoryPoints ? { storyPoints: value / (workdaysPerPoint * WORK_DAYS) } : {}),
    });
  };

  const onTeamChange = val => {
    const newTeam = teams?.find(t => t?.id === val?.id) || {};
    const isNewTeamEstimatingByPoints = canUpdate(PERMISSION_FEATURES.projectEstimateByPoints, {
      project: selectedProject || project,
      team: newTeam,
    });

    const updatedDurationOrPoints = getUpdatedPointsAndDurationOnTeamChange(
      newTeam,
      isNewTeamEstimatingByPoints,
      estimationByPointsEnabled,
      formData,
    );

    setFormData({
      ...formData,
      team: val,
      ...updatedDurationOrPoints,
    });
  };

  const isNew = !selectedEstimate || !selectedEstimate.id;
  const title = !isNew ? defaultEmptyString((selectedProject || project)?.title) : 'New Estimate';
  const formValid = formData.team?.id && formData.skill?.id;
  const closeDialog = () => {
    setShowDialog(false);
    if (onClose) onClose(formData);
  };

  const handleSaveEstimate = useCallback(() => {
    if (not(isNew)) {
      updateEstimate(parseForm(formData)).then(closeDialog);
    } else {
      createEstimate(selectedProjectId, parseForm(formData)).then(() => {
        if (formData.createAnother) {
          setFormData(selectedEstimate ? parseEstimateToForm(selectedEstimate) : estimateDefaults);
        } else {
          closeDialog();
        }
      });
    }
  }, [selectedEstimate, isNew, formData, selectedProjectId, updateEstimate, createEstimate, estimateDefaults, closeDialog]);

  const calcEndDate = (startDate, duration) => moment(startDate, 'YYYY/MM/DD').addDuration(+(duration * WEEK_DAYS), 'days');

  const _renderUpdateDetails = () =>
    selectedEstimate && (
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="dense">
            <TextField label="Updated by" value={getUserName(selectedEstimate.updated_by)} disabled />
          </FormControl>
        </Grid>
      </Grid>
    );
  const _renderCreatedDetails = () =>
    selectedEstimate && (
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="dense">
            <TextField label="Created by" value={getUserName(selectedEstimate.created_by)} disabled />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="dense">
            <TextField label="Created at" value={formatDateTime(selectedEstimate.created_at)} disabled />
          </FormControl>
        </Grid>
      </Grid>
    );

  return (
    <Wrapper>
      <Dialog
        data-test="estimate-dialog"
        open={showDialog}
        onClose={handleSaveEstimate}
        maxWidth="sm"
        scroll="paper"
        fullWidth
        header={
          <EstimateDialogHeader
            isNew={isNew}
            title={title}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            onClose={closeDialog}
          />
        }
        actions={
          <Fragment>
            {!isNew && (
              <Button
                color="secondary"
                data-test="delete-estimate-button"
                onClick={() => setShowDeleteConfirm(true)}
                style={{ position: 'absolute', left: 20 }}
              >
                delete
              </Button>
            )}
            {isNew && (
              <FormControlLabel
                label="Create another"
                aria-label="createAnother"
                data-test="create-another"
                control={<Checkbox />}
                disabled={!formValid}
                checked={formData.createAnother}
                value="createAnother"
                onChange={changeFormValue('createAnother', e => e.target.checked)}
              />
            )}
            <Button color="primary" data-test="save-estimate-button" onClick={handleSaveEstimate} disabled={!formValid}>
              save
            </Button>
            <Button onClick={closeDialog} data-test="cancel-button">
              cancel
            </Button>
          </Fragment>
        }
      >
        <div style={{ minHeight: 308 }}>
          {currentTab === 'history' && <EstimateHistory estimateId={selectedEstimate && selectedEstimate.id} />}

          {currentTab === 'details' && (
            <React.Fragment>
              {renderStoryPointsField ? (
                <Grid container spacing={8}>
                  <Grid item xs={4}>
                    <FormControl fullWidth margin="dense">
                      <TextField
                        label="Story Points"
                        type="number"
                        value={getUnroundedFixedDecimalValue(formData.storyPoints, 2)}
                        onChange={onStoryPointsChange}
                        disabled={readOnly || !estimationByPointsEnabled}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
              <Grid container spacing={8}>
                <Grid item xs>
                  <FormControl fullWidth margin="dense">
                    <TextField
                      label="Number of Staff"
                      value={formData.numStaff}
                      onChange={changeFormValue('numStaff', e => e.target.value)}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs>
                  <FormControl required fullWidth margin="dense">
                    <Autocomplete
                      label={getSystemFieldName('team')}
                      data-test="estimate-team"
                      suggestions={teamsSuggestions}
                      value={formData.team?.title || ''}
                      onValueChange={(_, val) => onTeamChange(val)}
                      disabled={readOnly}
                      required
                    />
                  </FormControl>
                </Grid>

                <Grid item xs>
                  <FormControl fullWidth margin="dense">
                    <Autocomplete
                      label={getSystemFieldName('skill')}
                      data-test="estimate-skill"
                      suggestions={skillsSuggestions}
                      value={formData.skill?.title || ''}
                      onValueChange={(_, val) => changeFormValue('skill')(val)}
                      disabled={readOnly}
                      required
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <FormControl fullWidth margin="normal">
                <FormLabel component="legend">Timeline</FormLabel>
                <Grid container spacing={8}>
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <InlineDatePicker
                        clearable
                        keyboard
                        format="YYYY-MM-DD"
                        value={formData.startDate}
                        onChange={date => {
                          const startDate = moment(date);

                          if (!startDate.isValid()) return;
                          setFormData({
                            ...formData,
                            startDate,
                            endDate: moment(startDate, 'YYYY/MM/DD').addDuration(formData.duration, 'days'),
                          });
                        }}
                        disabled={readOnly}
                        label=" "
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      name="duration"
                      className="custom-light-box-number-field"
                      type="number"
                      inputProps={{ min: 1 }}
                      label="Duration (week)"
                      value={getUnroundedFixedDecimalValue(formData.durationWeeks, 2)}
                      onChange={onDurationChange}
                      disabled={readOnly || estimationByPointsEnabled}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <InlineDatePicker
                        clearable
                        keyboard
                        format="YYYY-MM-DD"
                        value={calcEndDate(formData.startDate, formData.durationWeeks)}
                        onChange={() => {}}
                        label=" "
                        disabled
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </FormControl>
              {_renderUpdateDetails()}
              {_renderCreatedDetails()}
            </React.Fragment>
          )}
        </div>
      </Dialog>

      <ConfirmDialog
        data-test="delete-estimate-confirm"
        isOpen={showDeleteConfirm}
        row={{}}
        onCancel={() => setShowDeleteConfirm(false)}
        onDelete={() => {
          setShowDeleteConfirm(false);
          deleteEstimate(selectedProjectId, selectedEstimate).then(closeDialog);
        }}
        title="Delete Estimate"
        text="Are you sure you want to delete this estimate?"
      />
    </Wrapper>
  );
};

EstimateDialog.propTypes = {
  teams: PropTypes.array,
  skills: PropTypes.array,
  estimate: PropTypes.object,
  projectId: PropTypes.number,
  isLocked: PropTypes.bool,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  updateEstimate: PropTypes.func.isRequired,
  createEstimate: PropTypes.func.isRequired,
  deleteEstimate: PropTypes.func.isRequired,
};

EstimateDialog.defaultProps = {
  onOpen: () => {},
  teams: [],
  skills: [],
  estimate: null,
  projectId: null,
  isLocked: false,
  open: false,
  onClose: null,
};

export default EstimateDialog;

const Wrapper = styled.div``;
