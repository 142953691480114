import React, { useCallback, useMemo } from 'react';

export default Component => {
  return props => {
    const { fieldData, suggestions, integrationSuggestions, onChange, onRemove, onTriggerWarningDialog } = props;

    const filteredIntegrationSuggestions = useMemo(() => {
      const hasFieldSelectedAndAllowedDataTypes = fieldData.key && Array.isArray(fieldData.allowedDataTypes);

      return hasFieldSelectedAndAllowedDataTypes
        ? integrationSuggestions.filter(suggestion => fieldData.allowedDataTypes.includes(suggestion?.value?.schema?.type))
        : integrationSuggestions;
    }, [integrationSuggestions, fieldData.key, fieldData.allowedDataTypes]);

    const onChangeDragonboatFieldHandler = useCallback(value => onChange({ ...fieldData, ...value }), [fieldData, onChange]);

    const onChangeIntegrationFieldHandler = useCallback(
      value => onChange({ ...fieldData, updateFromDragonboatToIntegration: false, integration: value }),
      [fieldData, onChange],
    );

    const onChangeUpdateFromIntegrationToDragonboatHandler = useCallback(
      ({ target }) => onChange({ ...fieldData, updateFromIntegrationToDragonboat: target?.checked || false }),
      [fieldData, onChange],
    );

    const onChangeUpdateFromDragonboatToIntegrationHandler = useCallback(
      ({ target }) => {
        onChange({ ...fieldData, updateFromDragonboatToIntegration: target?.checked || false });

        if (!target?.checked) {
          onTriggerWarningDialog();
        }
      },
      [fieldData, onChange],
    );

    const onDeleteHandler = useCallback(() => onRemove(fieldData), [fieldData, onRemove]);

    return (
      <Component
        fieldData={fieldData}
        suggestions={suggestions}
        integrationSuggestions={filteredIntegrationSuggestions}
        onChangeDragonboatFieldHandler={onChangeDragonboatFieldHandler}
        onChangeIntegrationFieldHandler={onChangeIntegrationFieldHandler}
        onChangeUpdateFromIntegrationToDragonboatHandler={onChangeUpdateFromIntegrationToDragonboatHandler}
        onChangeUpdateFromDragonboatToIntegrationHandler={onChangeUpdateFromDragonboatToIntegrationHandler}
        onDeleteHandler={onDeleteHandler}
      />
    );
  };
};
