import isEmpty from 'lodash/isEmpty';

import { GROUP_FIELD_TO_METADATA_MAPPING, GROUP_FIELD_TO_FILTER_MAPPING } from '../constants';

const isCustomField = key => key?.startsWith('custom_fields.');
const getKeyByValue = (object, value) => Object.keys(object).find(key => object[key] === value);

const getProjectFieldToUpdate = groupKey => (isCustomField(groupKey) ? groupKey.replace('custom_fields.', '') : groupKey);

const getMetadataKeyByGroupKey = groupKey =>
  isCustomField(groupKey) ? GROUP_FIELD_TO_METADATA_MAPPING.customField : GROUP_FIELD_TO_METADATA_MAPPING[groupKey];

const getFilterKeyFromMetadataKey = metadataKey => {
  if (isCustomField(metadataKey)) return metadataKey;
  const groupField = getKeyByValue(GROUP_FIELD_TO_METADATA_MAPPING, metadataKey);

  return GROUP_FIELD_TO_FILTER_MAPPING[groupField];
};

const getMetadataObjectByKey = (metadata, metadataKey, projectfieldValue) =>
  metadata[metadataKey]?.find(c => c.id === projectfieldValue);

const _getGroupingKey = key => (isCustomField(key) ? key : getMetadataKeyByGroupKey(key));
const getGroupDataObjects = (groupOption, groupingObjects) => {
  const groupMetadata = groupOption?.key && groupOption?.field && groupingObjects[_getGroupingKey(groupOption.key)];

  return !isEmpty(groupMetadata) ? groupMetadata : [];
};

export {
  getProjectFieldToUpdate,
  getFilterKeyFromMetadataKey,
  getMetadataKeyByGroupKey,
  getMetadataObjectByKey,
  getGroupDataObjects,
};
