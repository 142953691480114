import React from 'react';

import DeleteButton from '../DeleteButton';

export default (params) => {
  const {
    node: {
      group,
      data,
      rowIndex,
    },
    onClick,
    confirmDialogText,
    fieldLabel,
  } = params;

  function remove() {
    onClick(data.id || rowIndex);
  }

  if (!group && data) {
    return (<DeleteButton
      remove={remove}
      confirmDialogTitle={`Delete ${data[fieldLabel] || data.title ? `"${data[fieldLabel] || data.title}"` : ''}`}
      confirmDialogText={confirmDialogText}
      style={{ minWidth: 0, padding: 0, marginLeft: 0 }}
    />);
  }
  return <div />;
};
