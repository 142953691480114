import useNavigationToPathWithGoBackUrl from 'hooks/useNavigationToPathWithGoBackUrl';
import { useCallback } from 'react';

const COMPARE_VERSIONS_PATH = '/compare-scenarios';

export default () => {
  const { navigateToWithGoBackUrl } = useNavigationToPathWithGoBackUrl();

  const navigateToCompareVersions = useCallback(() => navigateToWithGoBackUrl(COMPARE_VERSIONS_PATH), [navigateToWithGoBackUrl]);

  return { navigateToCompareVersions };
};
