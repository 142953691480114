import React, { useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import DragonSelectButton from 'design-system/atoms/DragonSelectButton/index';
import DragonMenu from 'design-system/atoms/DragonMenu/index';

import { getHasAdvancedMetricReporting } from 'store/organization/selectors';
import { setLastGoalsView } from 'store/app';
import useSystemFields from 'hooks/useSystemFields';
import { OBJECTIVE } from 'constants/common';

const getGoalsPage = getSystemFieldName => ({
  key: 'goals',
  label: getSystemFieldName(OBJECTIVE),
  path: '/goals',
});

const METRICS_PAGE = {
  key: 'metrics',
  label: 'Metrics',
  path: '/metrics',
};

const selectButtonTextStyles = css`
  font-size: ${({ theme }) => theme.typography.fontSizeMediumSmall}px;
  line-height: ${({ theme }) => theme.typography.lineHeightRegularSmallRem}rem;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export default ({ history }) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);

  const [getSystemFieldName] = useSystemFields();

  const path = window.location.pathname;

  const pages = useMemo(
    () => [getGoalsPage(getSystemFieldName), ...(hasAdvancedMetricReporting ? [METRICS_PAGE] : [])],
    [hasAdvancedMetricReporting],
  );

  const activePage = useMemo(() => pages.find(page => path.includes(page.path)) || pages[0], [path, pages]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectPage = page => {
    dispatch(setLastGoalsView(page.key));
    history.push(page.path);
  };

  return (
    <Wrapper>
      <DragonSelectButton
        value={activePage?.label}
        onClick={handleClick}
        textStyles={selectButtonTextStyles}
        arrowIcon={<StyledKeyboardArrowDownIcon />}
        variant="secondary"
        noUnderline
        higherLabel
      />
      <DragonMenu items={pages} anchorEl={anchorEl} onSetAnchorEl={setAnchorEl} onSelectItem={handleSelectPage} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &&&& {
    > div,
    button {
      min-width: 75px;
    }
  }
`;

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
  &&&&& {
    width: 24px;
    height: 24px;
    path {
      fill: none;
    }
  }
`;
