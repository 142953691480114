import React from 'react';
import styled from 'styled-components';

import { spacing } from 'design-system/theme';
import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete/index';

import { DEFAULT_GROUP_OPTION } from 'store/projects/helpers/groupOptions';

const GroupByControls = ({
  hasGroupBy,
  shouldShowGroupLevel2,
  shouldShowGroupLevel3,
  selectedGroup1,
  selectedGroup2,
  selectedGroup3,
  groupByOptions,
  groupByGroup2Options,
  groupByGroup3Options,
  onGroupLevelChange,
  renderGroup1Label = true,
  disabled = false,
}) => {
  return (
    hasGroupBy && (
      <>
        <GroupByAutocomplete
          label={renderGroup1Label ? 'Group by' : ''}
          name="selectedGroup1"
          suggestions={groupByOptions}
          value={selectedGroup1 ?? DEFAULT_GROUP_OPTION}
          onChange={onGroupLevelChange('selectedGroup1')}
          disabled={disabled}
        />
        {shouldShowGroupLevel2 && (
          <>
            {selectedGroup1 && selectedGroup1.key && (
              <>
                <Spacing />
                <GroupByAutocomplete
                  name="selectedGroup2"
                  suggestions={groupByGroup2Options.filter(o => !o.key || o.key !== selectedGroup1.key)}
                  value={selectedGroup2 ?? DEFAULT_GROUP_OPTION}
                  onChange={onGroupLevelChange('selectedGroup2')}
                  label="then by"
                  disabled={disabled}
                />
              </>
            )}
          </>
        )}
        {shouldShowGroupLevel3 && (
          <>
            {selectedGroup1 && selectedGroup1.key && selectedGroup2 && selectedGroup2.key && (
              <>
                <Spacing />
                <GroupByAutocomplete
                  name="selectedGroup3"
                  suggestions={groupByGroup3Options.filter(
                    o => !o.key || (o.key !== selectedGroup1.key && o.key !== selectedGroup2.key),
                  )}
                  value={selectedGroup3 ?? DEFAULT_GROUP_OPTION}
                  onChange={onGroupLevelChange('selectedGroup3')}
                  label="then by"
                  disabled={disabled}
                />
              </>
            )}
          </>
        )}
      </>
    )
  );
};

const Spacing = styled.div`
  padding: ${spacing(0.625)}px;
`;

export default GroupByControls;
