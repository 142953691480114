import { gte, lt } from 'ramda';

import dragonboatTheme from 'design-system/dragonboatTheme';

/**
 * @function getPointsVarianceColor
 *
 * Gets the points variance color based on the provided value.
 * - More than 105%    ->  red
 * - Less than -50%    ->  orange
 * - Between -50% and 104.9%  ->  regular color (dark gray)
 *
 *
 * @param {Number} value
 * @returns {String}
 */
export const getPointsVarianceColor = value => {
  switch (true) {
    case gte(value, 1.05):
      return dragonboatTheme.palette.pointsVariance.danger;
    case lt(value, -0.5):
      return dragonboatTheme.palette.pointsVariance.alert;
    default:
      return '';
  }
};
