import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import { subtract } from 'ramda';

import {
  DELETE_DASHBOARD_COMMENT_FULFILLED,
  EDIT_DASHBOARD_COMMENT_FULFILLED,
  FETCH_DASHBOARD_COMMENTS_FULFILLED,
  FETCH_DASHBOARD_COMMENTS_PENDING,
  FETCH_NEXT_DASHBOARD_COMMENTS_FULFILLED,
  SAVE_DASHBOARD_COMMENT_FULFILLED,
} from './types';

const defaultMeta = {
  page: 1,
  next: null,
  previous: null,
  count: 1,
};

export const initialState = {
  byDashboard: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_COMMENTS_PENDING: {
      const { dashboardId } = action.meta || {};

      if (!dashboardId) {
        return state;
      }

      return {
        ...state,
        byDashboard: {
          ...state.byDashboard,
          [dashboardId]: {},
        },
      };
    }
    case FETCH_DASHBOARD_COMMENTS_FULFILLED: {
      const { dashboardId } = action.meta || {};
      const { data, _meta } = action.payload || {};

      if (!dashboardId || !data || !_meta) {
        return state;
      }

      return {
        ...state,
        byDashboard: {
          ...state.byDashboard,
          [dashboardId]: { data, _meta },
        },
      };
    }
    case FETCH_NEXT_DASHBOARD_COMMENTS_FULFILLED: {
      const { dashboardId } = action.meta || {};
      const { data, _meta } = action.payload || {};

      if (!dashboardId || !data || !_meta) {
        return state;
      }

      const currentCommentsData = get(state, `byDashboard[${dashboardId}].data`, []);

      return {
        ...state,
        byDashboard: {
          ...state.byDashboard,
          [dashboardId]: {
            _meta,
            data: uniqBy([...currentCommentsData, ...data], 'id'),
          },
        },
      };
    }
    case SAVE_DASHBOARD_COMMENT_FULFILLED: {
      const { dashboardId } = action.meta || {};

      if (!dashboardId || !action.payload) {
        return state;
      }

      const currentCommentsData = get(state, `byDashboard[${dashboardId}].data`, []);
      const currentCommentsMeta = get(state, `byDashboard[${dashboardId}]._meta`, defaultMeta);

      return {
        ...state,
        byDashboard: {
          ...state.byDashboard,
          [dashboardId]: {
            _meta: {
              ...currentCommentsMeta,
              count: currentCommentsMeta.count + 1,
            },
            data: [action.payload, ...currentCommentsData],
          },
        },
      };
    }
    case DELETE_DASHBOARD_COMMENT_FULFILLED: {
      if (!action.meta.dashboardId || !action.meta.id) {
        return state;
      }

      const { dashboardId, id } = action.meta;
      const currentCommentsData = get(state, `byDashboard[${dashboardId}].data`, []);
      const currentCommentsMeta = get(state, `byDashboard[${dashboardId}]._meta`, defaultMeta);

      return {
        ...state,
        byDashboard: {
          ...state.byDashboard,
          [dashboardId]: {
            _meta: {
              ...currentCommentsMeta,
              count: subtract(currentCommentsMeta.count, 1),
            },
            data: currentCommentsData.filter(comment => comment.id !== id),
          },
        },
      };
    }
    case EDIT_DASHBOARD_COMMENT_FULFILLED: {
      if (!action.meta.dashboardId || !action.meta.id) {
        return state;
      }

      const { dashboardId, id } = action.meta;
      const currentComments = get(state, `byDashboard[${dashboardId}]`, {});
      const currentCommentsData = get(state, `byDashboard[${dashboardId}].data`, []);

      return {
        ...state,
        byDashboard: {
          ...state.byDashboard,
          [dashboardId]: {
            ...currentComments,
            data: currentCommentsData.map(comment => {
              return comment.id === id ? action.payload : comment;
            }),
          },
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
