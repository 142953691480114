import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';

import { spacing } from 'design-system/theme';

const DndFooterAction = ({ onClick, icon, text }) => {
  return (
    <ActionRow onClick={onClick}>
      <ListItemIcon>{icon || <CloseIcon />}</ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </ActionRow>
  );
};

const ListItemIcon = styled(MuiListItemIcon)`
  &&&& {
    margin: auto;

    display: flex;
    justify-content: space-between;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const ListItemText = styled(MuiListItemText)`
  &&&& {
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;
    text-transform: uppercase;

    span {
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      font-size: ${props => props.theme.typography.fontSize}px;
      line-height: ${props => props.theme.typography.lineHeightRegular}px;
      color: ${props => props.theme.palette.text.lightGrey};
    }
  }
`;

const ActionRow = styled.div`
  cursor: pointer;

  width: 100%;

  display: grid;
  grid-template-columns: 14px 1fr;
  gap: ${spacing(1)}px;

  padding: 0 ${spacing(2)}px;
  box-sizing: border-box;
  height: 30px;

  span {
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    font-size: ${props => props.theme.typography.fontSize}px;
    line-height: ${props => props.theme.typography.lineHeightRegular}px;
    color: ${props => props.theme.palette.text.lightGrey};
  }
`;

export default DndFooterAction;
