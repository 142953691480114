import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';

import BaseLayout from 'design-system/organisms/BaseLayout/index';

import { spacing } from 'design-system/theme';

import AllocationReportSummaryTable from 'containers/AllocationReportSummaryTable';
import ShareView from 'containers/ShareView';

import useAllocationPieChart from 'hooks/allocation/useAllocationPieChart';
import useAllocationSummary from 'hooks/allocation/useAllocationSummary';
import usePlannedVsReportedChartData from 'hooks/allocation/usePlannedVsReportedChartData';
import useAllocationStackedChart from 'hooks/allocation/useAllocationStackedChart';
import useSystemFields from 'hooks/useSystemFields';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import ChartContent from 'routes/Dashboard/Dashboards/Charts/ChartContent';

import {
  PLANNED_LABEL,
  REPORTED_LABEL,
  COMPLETED_LABEL,
  getAllocationSummaryTableColumnPreferencesOptions,
  TARGET_LABEL,
  PLANNED_STACKED_LABEL,
  REPORTED_STACKED_LABEL,
  COMPLETED_STACKED_LABEL,
} from 'constants/allocation';
import { ALLOCATION_REPORT_PAGE } from 'constants/filters';

import AllocationTimelineLightbox from '../AllocationTimelineLightbox';
import PreferencesDialog from '../../PreferencesDialog';
import AllocationBySelector from '../AllocationBySelector';
// import { generateBarChartData } from '../utils';

const PLANNED_TYPE = 'planned';
const REPORTED_TYPE = 'reported';
const COMPLETED_TYPE = 'completed';

const pickCertainAllocationDataForStacked = (keyedData = {}, type) => {
  return Object.entries(keyedData).reduce((keyedData, [id, data]) => {
    return { ...keyedData, [id]: data[type] };
  }, {});
};

const AllocationReportComponent = ({
  lsState,
  projects,
  allocationData,
  groupByOptions,
  onChangeGroupBy,
  onTabChange,
  isGoalMode,
  showPageTitle = true,
  pageId = ALLOCATION_REPORT_PAGE,
  shouldDisplayControlsBar = false,
  // metadata,
  // updateGroupColor,
}) => {
  const [timelineEntity, setTimelineEntity] = React.useState();
  // const [colorPicker, _handleColorPickStart] = useGroupColorPicker(metadata, updateGroupColor, lsState.dataType);
  const {
    originalStackedData,
    planned,
    reported,
    completed,
    target,
    entities,
    stackEntities,
    overInvestedGoals = [],
    scopeVariance,
    totalScopeVariance,
  } = allocationData;

  const { canView } = usePermissions();

  const hasCompletedAllocation = canView(PERMISSION_FEATURES.completedAllocation);

  const [plannedChart] = useAllocationPieChart(planned, entities, lsState, PLANNED_LABEL, true);
  const [reportedChart] = useAllocationPieChart(reported, entities, lsState, REPORTED_LABEL, true);
  const [completedChart] = useAllocationPieChart(completed, entities, lsState, COMPLETED_LABEL, true);
  const [targetChart] = useAllocationPieChart(target?.amount, entities, lsState, TARGET_LABEL, false, { hideValueIfZero: true });

  const summaryData = useAllocationSummary(
    planned,
    reported,
    completed,
    scopeVariance,
    totalScopeVariance,
    overInvestedGoals,
    entities,
    lsState,
  );

  const [barChart] = usePlannedVsReportedChartData(planned, reported, completed, target?.amount, entities, lsState);

  const [stackedPlannedBarChart] = useAllocationStackedChart(
    pickCertainAllocationDataForStacked(originalStackedData, PLANNED_TYPE),
    entities,
    stackEntities,
    lsState,
  );
  const [stackedCompletedBarChart] = useAllocationStackedChart(
    pickCertainAllocationDataForStacked(originalStackedData, COMPLETED_TYPE),
    entities,
    stackEntities,
    lsState,
  );
  const [stackedReportedBarChart] = useAllocationStackedChart(
    pickCertainAllocationDataForStacked(originalStackedData, REPORTED_TYPE),
    entities,
    stackEntities,
    lsState,
  );

  // const [stackedBarPercentageChart] =
  /* TODO */ useAllocationStackedChart(originalStackedData, entities, stackEntities, lsState);

  // TODO: will use again when loading this data without dependency of projects
  // const teamBarData = generateBarChartData(metadata, 'team');
  // const skillBarData = generateBarChartData(metadata, 'skill');

  const showTable = useMemo(() => !isGoalMode, [isGoalMode]);

  const [getSystemFieldName] = useSystemFields();

  const pieCharts = useMemo(
    () =>
      [
        {
          title: PLANNED_LABEL,
          visible: lsState.showPlanned,
          render: () => plannedChart,
        },
        {
          title: REPORTED_LABEL,
          visible: lsState.showReported,
          render: () => reportedChart,
        },
        {
          title: TARGET_LABEL,
          visible: lsState.showTarget,
          render: () => targetChart, // sub out with target
        },
        {
          title: COMPLETED_LABEL,
          visible: lsState.showCompleted,
          render: () => completedChart,
        },
      ].filter(c => c.visible),
    [
      lsState.showPlanned,
      lsState.showReported,
      lsState.showCompleted,
      lsState.showTarget,
      plannedChart,
      reportedChart,
      completedChart,
      targetChart,
    ],
  );

  const barCharts = useMemo(
    () =>
      [
        {
          title: getSystemFieldName(lsState.selectedGroupBy.key, false),
          visible: lsState.showBarChart,
          render: () => barChart,
        },
        {
          title: [PLANNED_STACKED_LABEL, getSystemFieldName('timeframe', false)].join(' '),
          visible: lsState.showStackedByTimeframeChart && lsState.showPlanned,
          render: () => stackedPlannedBarChart,
        },
        {
          title: [REPORTED_STACKED_LABEL, getSystemFieldName('timeframe', false)].join(' '),
          visible: lsState.showStackedByTimeframeChart && lsState.showReported,
          render: () => stackedReportedBarChart,
        },
        {
          title: [COMPLETED_STACKED_LABEL, getSystemFieldName('timeframe', false)].join(' '),
          visible: lsState.showStackedByTimeframeChart && lsState.showCompleted,
          render: () => stackedCompletedBarChart,
        },
        // {
        //   title: [
        //     '%',
        //     getSystemFieldName(lsState.selectedGroupBy.key, false),
        //     'stacked by',
        //     getSystemFieldName('timeframe', false),
        //   ].join(' '),
        //   visible: lsState.showStackedByTimeframePercentageChart,
        //   render: () => stackedBarPercentageChart,
        // },
      ].filter(c => c.visible),
    [
      lsState.showBarChart,
      lsState.showStackedByTimeframeChart,
      lsState.showStackedByTimeframePercentageChart,
      lsState.showPlanned,
      lsState.showReported,
      lsState.showCompleted,
      barChart,
      lsState.selectedGroupBy.key,
    ],
  );

  const totalNumOfCharts = useMemo(() => {
    return pieCharts.length + barCharts.length;
  }, [pieCharts, barCharts]);

  const _handleSummaryTitleClick = useCallback(row => {
    setTimelineEntity(row.entity);
  }, []);
  const _cleanTimelineEntity = useCallback(() => setTimelineEntity(null), []);

  const _columnsOptions = useMemo(() => {
    return getAllocationSummaryTableColumnPreferencesOptions(hasCompletedAllocation);
  }, [hasCompletedAllocation]);

  useEffect(() => {
    if (lsState.showReported && lsState.currentTab !== 0) {
      onTabChange(null, 0);
    }
  }, [lsState.showReported]);

  // const _renderBarChart = data => (
  //   <ChartContainer>
  //     <BarChart data={data} options={barOptions} />
  //   </ChartContainer>
  // );

  const _renderControlsContainer = () => (
    <ControlsContainer>
      {showPageTitle && (
        <AllocationBySelector
          lsState={lsState}
          groupByOptions={groupByOptions}
          onChangeGroupBy={onChangeGroupBy}
          disabled={!shouldDisplayControlsBar}
        />
      )}
      {shouldDisplayControlsBar && (
        <FormGroup row style={{ justifyContent: 'flex-end' }}>
          <ControlItem>
            <ShareView pageId={pageId} />
          </ControlItem>
        </FormGroup>
      )}
    </ControlsContainer>
  );

  const _renderPlannedVsReportedCharts = () => {
    const pieChartColumnWidth = totalNumOfCharts < 3 ? 12 / totalNumOfCharts : 6;
    const barChartColumnWidth = 6;
    const alignChartColumns = !lsState.showPieChart ? 'center' : 'space-between';

    // if we have more than 2 showing we need to make sure pie chart doesn't grow with bar
    const controlPieChartHeight = pieCharts?.length > 2;

    return (
      <Grid container justify={alignChartColumns} alignItems={controlPieChartHeight && 'flex-start'} spacing={spacing(2)}>
        {lsState.showPieChart &&
          pieCharts.map(chart => (
            <Grid item xs={pieChartColumnWidth}>
              <ChartContainer bordered>
                <ChartContent title={chart.title}>{chart.render()}</ChartContent>
              </ChartContainer>
            </Grid>
          ))}
        {!!barCharts?.length &&
          barCharts.map(chart => (
            <Grid item xs={barChartColumnWidth}>
              <ChartContainer bordered>
                <ChartContent title={chart.title}>{chart.render()}</ChartContent>
              </ChartContainer>
            </Grid>
          ))}
      </Grid>
    );
  };

  return (
    <BaseLayout>
      <Wrapper id="allocation-page-container">
        <Grid container spacing={0}>
          <Grid item md={12}>
            {_renderControlsContainer()}
          </Grid>
        </Grid>
        <DataContainer flex>
          <ChartsColumn>
            {lsState.currentTab === 0 && _renderPlannedVsReportedCharts()}
            {/* {lsState.showPlanned && lsState.currentTab === 1 && _renderBarChart(teamBarData)}
          {lsState.showPlanned && lsState.currentTab === 2 && _renderBarChart(skillBarData)} */}
          </ChartsColumn>
          {showTable && (
            <TableColumn width="100%">
              <AllocationReportSummaryTable
                data={summaryData?.tableData || []}
                total={summaryData?.total || 0}
                totalReported={summaryData?.totalReported || 0}
                totalScopeVariance={summaryData?.totalScopeVariance || 0}
                totalCompleted={summaryData?.totalCompleted || 0}
                lsState={lsState}
                columnsOptions={_columnsOptions}
                // handleColorPickStart={_handleColorPickStart}
                projects={projects}
                handleTitleClick={_handleSummaryTitleClick}
              />
            </TableColumn>
          )}
        </DataContainer>

        {/* {colorPicker} */}
        {showTable && (
          <AllocationTimelineLightbox
            projects={projects}
            timelineEntity={timelineEntity}
            cleanTimelineEntity={_cleanTimelineEntity}
            dataType={lsState.dataType}
          />
        )}

        <PreferencesDialog hideIcon columnsOptions={_columnsOptions} />
      </Wrapper>
    </BaseLayout>
  );
};

export default AllocationReportComponent;

const Wrapper = styled.div`
  height: calc(100vh - 68px);
`;

export const ChartContainer = styled.div`
  ${({ theme, bordered }) => bordered && `border: 1px solid ${theme.palette.border.primary};`}
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;
  width: 100%;
  height: 100%;
  padding: 0px;
  min-height: 350px;
`;

const DataContainer = styled.div`
  width: 100%;
  ${props =>
    props.flex &&
    `
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  `}
`;

const ChartsColumn = styled.div`
  width: 100%;
`;

const TableColumn = styled.div`
  margin-top: 34px;
  width: 100%;
  ${props => props.width && `width: ${props.width};`}
`;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${spacing(0.5)}px auto;
`;

const ControlItem = styled.div``;
