import React from 'react';

import { FormControlLabel, Radio } from '@material-ui/core';

const ProgressTrackingTypeField = props => {
  const { value, onChange, type, label, disabled } = props;

  return (
    <FormControlLabel
      control={<Radio checked={value} color="primary" onChange={() => onChange(type)} />}
      label={label}
      disabled={disabled}
    />
  );
};

export default ProgressTrackingTypeField;
