import React, { useMemo } from 'react';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import FileUpload from '@material-ui/icons/CloudUploadOutlined';
import Download from '@material-ui/icons/CloudDownloadOutlined';

import SearchBar from 'components/SearchBar';
import UserTypeMapper from 'utils/UserTypeMapper';
import useRoleAvailability from 'hooks/users/useRoleAvailability';
import useSystemFields from 'hooks/useSystemFields';

import MoreOptionsMenu from './components/MoreOptionsMenu';
import OktaSettingsLink from './components/OktaSettingsLink';
import TeamsFilterDropdown from './components/TeamsFilterDropdown';
import { AddNewButton, CancelButton, RightButtons, RightButtonsContainer, Wrapper } from './components/styled';
import UsersFilterDropdown from './components/UsersFilterDropdown';

const mapToObject = o => ({ id: o, title: o });

const STATUS_OPTIONS = ['Active', 'Inactive'].map(mapToObject);

const SEARCH_BAR_STYLES = { width: 160 };

const UsersSettingsActionsToolbar = ({
  isSelectionMode,
  selectedItems,
  enableSelectionMode,
  onBulkDeleteUsersClick,
  onBulkUpdateUsersClick,
  allowMergeUsers = true,
  onMergeUsersClick,
  onCancelSelectionModeClick,
  onAddNewUserClick,
  searchText,
  updateUsersSearchText,
  filters = {},
  updateFilters,
  skills,
  allowActions,
  orgHasOkta,
  openImportLightbox,
  exportToCsv,
  allTeams,
  enableRightButtons = true,
}) => {
  const { isRoleAvailable } = useRoleAvailability();
  const [getSystemFieldName] = useSystemFields();

  const roles = UserTypeMapper.filter(u => isRoleAvailable(u.value)).map(r => ({ ...r, id: r.value }));

  const moreOptionsMenuOptions = useMemo(
    () => [
      {
        label: 'Bulk Update',
        action: () => {
          enableSelectionMode();
        },
        icon: <CreateIcon />,
      },
      {
        label: 'Import',
        action: () => {
          openImportLightbox();
        },
        icon: <FileUpload />,
      },
      {
        label: 'Download to .csv file',
        action: () => {
          exportToCsv();
        },
        icon: <Download />,
      },
    ],
    [enableSelectionMode, openImportLightbox],
  );

  return (
    <Wrapper>
      {isSelectionMode && (
        <>
          <Button color="secondary" onClick={onBulkDeleteUsersClick} disabled={!selectedItems.length}>
            Delete
          </Button>
          <Button color="primary" onClick={onBulkUpdateUsersClick} disabled={!selectedItems.length}>
            Update
          </Button>
          {allowMergeUsers && (
            <Button onClick={onMergeUsersClick} disabled={selectedItems.length < 2}>
              Merge
            </Button>
          )}
          <CancelButton onClick={onCancelSelectionModeClick}>Cancel</CancelButton>
        </>
      )}
      {!isSelectionMode && allowActions && (
        <AddNewButton id="setting-add-new-button" color="primary" onClick={onAddNewUserClick}>
          + New
        </AddNewButton>
      )}
      {enableRightButtons && (
        <RightButtons>
          <RightButtonsContainer $allowActions={allowActions} $isSelectionMode={isSelectionMode}>
            <SearchBar searchString={searchText} updateSearchString={updateUsersSearchText} style={SEARCH_BAR_STYLES} />
            <UsersFilterDropdown
              options={STATUS_OPTIONS}
              filters={filters}
              updateFilters={updateFilters}
              field="status"
              label="All Status"
              hasClearSelection={false}
              hasSearchBar={false}
            />
            <UsersFilterDropdown options={roles} filters={filters} updateFilters={updateFilters} field="role" label="All Roles" />
            <TeamsFilterDropdown teams={allTeams} filters={filters} onChange={updateFilters} />
            <UsersFilterDropdown
              options={skills}
              filters={filters}
              updateFilters={updateFilters}
              field="skills"
              label={`All ${getSystemFieldName('skill', true)}`}
            />
            <OktaSettingsLink orgHasOkta={orgHasOkta} />
            {allowActions && <MoreOptionsMenu options={moreOptionsMenuOptions} />}
          </RightButtonsContainer>
        </RightButtons>
      )}
    </Wrapper>
  );
};

export default UsersSettingsActionsToolbar;
