import { useMemo } from 'react';
import groomGenericAutocompleteOptions from '../helpers/groomGenericAutocompleteOptions';

const useByFieldAutocompleteOptions = ({ options, withNullOption = false }) => {
  const dropdownOptions = useMemo(() => groomGenericAutocompleteOptions(options, withNullOption), [options, withNullOption]);

  return dropdownOptions;
};

export default useByFieldAutocompleteOptions;
