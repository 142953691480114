import React from 'react';

import DisplayController from './presentation-strategies/DisplayController';

import { DND_BOUNDS_NAME } from '../helpers';

import { TimelineBodyContainer, TimelineDndPlayground } from './styled';
import TimelineItem from './TimelineItem';
import Today from './Today';

const TOP_ROW = 'topLane';

const isTopRowReserved = (enabled, enabledOn) => enabled && enabledOn === TOP_ROW;

const TimelineBody = ({
  // data
  data,
  orders,

  // options
  groupsWidths,
  groupOffsets,
  mode,
  displayMilestone,
  displayMilestoneOn,
  slotWidth,
  showTooltip,
  showTextOverflow,
  resizable,
  draggable,
  snapToGridOn,
  hideEmptyLane,

  // presentation
  selectedGroupsCount,
  shouldShowItem,
  fromDate,
  todayLine,
  groupTitleRenderer,
  isMilestoneItemChecker,
  tooltipLabels,

  // handlers
  onDoubleClick,
  onRowClick,
  onDrag,
  onResize,

  // loading
  isLoading,

  // group expand and collapse
  isGroupOpen,
  toogleGroup,
}) => {
  // Common item render
  const itemRenderer = (item, groupIndexes, rowIndex, isMilestoneTop) =>
    shouldShowItem(item) && (
      <TimelineItem
        key={item.id}
        {...item}
        isMilestoneGroupTopLane={isMilestoneTop}
        isLoading={isLoading}
        fromDate={fromDate}
        slotWidth={slotWidth}
        mode={mode}
        snapToGridOn={snapToGridOn}
        showTooltip={showTooltip}
        showTextOverflow={showTextOverflow}
        resizable={resizable}
        draggable={draggable}
        isMilestoneItemChecker={isMilestoneItemChecker}
        onDoubleClick={onDoubleClick}
        onDragStop={onDrag(item, groupIndexes, rowIndex)}
        onResizeStop={onResize(item, groupIndexes, rowIndex)}
        tooltipLabels={tooltipLabels}
      />
    );

  return (
    <TimelineBodyContainer>
      <DisplayController
        data={data}
        selectedGroupsCount={selectedGroupsCount}
        groupsWidths={groupsWidths}
        groupOffsets={groupOffsets}
        orders={orders}
        onRowClick={onRowClick}
        isGroupOpen={isGroupOpen}
        toogleGroup={toogleGroup}
        hideEmptyLane={hideEmptyLane}
        itemRenderer={itemRenderer}
        groupTitleRenderer={groupTitleRenderer}
      />
      <TimelineDndPlayground
        className={DND_BOUNDS_NAME}
        groupColumnsWidth={groupsWidths.reduce((acc, width) => acc + width, 0)}
        isTopRowReserved={isTopRowReserved(displayMilestone, displayMilestoneOn)}
      />
      <Today left={todayLine} />
    </TimelineBodyContainer>
  );
};

export default TimelineBody;
