import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import Button from '@material-ui/core/Button';

import NewDialog from 'design-system/organisms/NewDialog/index';
import PageLink from 'design-system/atoms/PageLink/index';
import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';

import MapImportDataFields from 'containers/MapImportDataFields';

import { DEFAULT_FIELDS_MAPPING } from '../../ConfigDetails/utils';

const JiraMappingLightbox = props => {
  const { getSystemFieldName, jiraFields, currentMapping, onClose, onSave, onChange, isLoadingJiraFields } = props;

  return (
    <NewDialog
      open
      title={
        <TitleWrapper>
          <Text as="h3">Map Jira EPIC Fields to dragonboat {getSystemFieldName('idea')} Fields </Text>
          <StyledPageLink
            href="https://dragonboat.zendesk.com/hc/en-us/articles/360025615233-Sync-Field-Values-with-Jira"
            target="_blank"
            size="medium"
          >
            (See how)
          </StyledPageLink>
        </TitleWrapper>
      }
      maxWidth="lg"
      scroll="paper"
      fullWidth
      closeButton
      onClose={onClose}
      actions={
        <>
          <Button color="primary" onClick={onSave}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </>
      }
    >
      <MapImportDataFields
        integrationType="jira-mapping"
        columns={sortBy(jiraFields, ['name'])}
        defaultMap={DEFAULT_FIELDS_MAPPING}
        dataMapping={currentMapping}
        updateDataMapping={onChange}
        isLoadingIntegrationFields={isLoadingJiraFields}
      />
    </NewDialog>
  );
};

JiraMappingLightbox.propTypes = {
  getSystemFieldName: PropTypes.func,
  jiraFields: PropTypes.array,
  currentMapping: PropTypes.array,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
};

export default JiraMappingLightbox;

const TitleWrapper = styled.div`
  display: flex;
`;

const StyledPageLink = styled(PageLink)`
  margin-left: ${spacing(0.5)}px;
`;
