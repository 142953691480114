import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import { spacing } from 'design-system/theme';
import { materialColorsAlt } from 'design-system/themes/default';
import ProjectsDropdown from 'containers/ProjectsDropdown';
import ProjectType from 'containers/ProjectType';
import useOrganizations from 'hooks/useOrganizations';
import { openProjectLightbox as openProjectLightboxAction } from 'store/projectLightbox';

export default ({ selectedProject, userCanEdit, onAddDependency, onRemoveDependency, dependencies }) => {
  const dispatch = useDispatch();

  const {
    filters: { topLayer, availableLayers },
  } = useOrganizations();

  const searchLayers = useMemo(() => Object.values(availableLayers), [availableLayers]);

  const addDependency = useCallback(
    (field, project) => {
      onAddDependency(project, field);
    },
    [onAddDependency],
  );

  const makeAddDependencyFor = useCallback(
    field => {
      return (project, { closeTree }) => {
        addDependency(field, project);

        closeTree();
      };
    },
    [addDependency],
  );

  const dataLoader = useCallback(
    async (field, searchValue, defaultDataLoader) => {
      const searchedProjects = await defaultDataLoader(searchValue);

      return searchedProjects.filter(p => !dependencies[field].some(d => d.id === p.id) && p?.id !== selectedProject?.id);
    },
    [dependencies],
  );

  const makeDataLoaderFor = useCallback(
    field => {
      return (searchValue, { defaultDataLoader }) => dataLoader(field, searchValue, defaultDataLoader);
    },
    [dataLoader],
  );

  const openProjectLightbox = useCallback(id => dispatch(openProjectLightboxAction(id)), [openProjectLightboxAction]);

  const _renderDependency = (entity, type) => {
    if (!entity) return '';
    let text = '';

    text = entity.roadmapTitle ? `${entity.roadmapTitle} | ` : '';
    text += `DB-${entity.id}${entity.jira ? ` (${entity.jira.key})` : ''} `;
    text += entity.title;
    text += entity.ownerName ? ` | ${entity.ownerName}` : '';

    let source = selectedProject;
    let target = entity;

    if (type === 'blocks') {
      source = entity;
      target = selectedProject;
    }
    const end = source.deadline
      ? moment(source.deadline)
      : moment(source.estimated_start_date).addDuration(source.duration || 0, 'days');

    return moment(target.estimated_start_date).add(0.5, 'days').isSameOrAfter(end) ? (
      <span style={{ color: materialColorsAlt.red }}>{text}</span>
    ) : (
      text
    );
  };
  const _renderDependencies = (data, remove, type) => (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <StyledList>
          {data?.map(dependency => (
            <Dependency key={dependency.id}>
              <ProjectType layer={dependency.layer} style={{ height: '18', width: '16' }} />
              <DependencyText primary={_renderDependency(dependency, type)} />
              <ButtonsContainer>
                <OpenButton title="Open Dependency" onClick={() => openProjectLightbox(dependency.id)}>
                  <OpenInNewIcon />
                </OpenButton>
                {userCanEdit && (
                  <StyledButtonIcon title="Remove Dependency" onClick={() => remove(dependency)}>
                    <CloseIcon />
                  </StyledButtonIcon>
                )}
              </ButtonsContainer>
            </Dependency>
          ))}
        </StyledList>
      </Grid>
    </Grid>
  );

  return (
    <Wrapper>
      <FlexWrapper>
        <FlexItem>
          <Label>Is blocked by</Label>
        </FlexItem>
        <FlexItem flex={1}>
          <FormControl fullWidth margin="dense">
            <StyledProjectsDropdown
              disabled={!userCanEdit}
              componentId="lightbox-dependencies-tab-is-blocked-by"
              displayLayer={topLayer}
              searchLayers={searchLayers}
              customDataLoader={makeDataLoaderFor('blockedBy')}
              onProjectSelect={makeAddDependencyFor('blockedBy')}
            />
          </FormControl>
        </FlexItem>
      </FlexWrapper>
      {_renderDependencies(dependencies.blockedBy, link => onRemoveDependency(link, 'blockedBy'), 'blockedBy')}
      <FlexWrapper>
        <FlexItem>
          <Label>Blocks</Label>
        </FlexItem>
        <FlexItem FlexItem flex={1}>
          <FormControl fullWidth margin="dense">
            <StyledProjectsDropdown
              disabled={!userCanEdit}
              componentId="lightbox-dependencies-tab-blocks"
              displayLayer={topLayer}
              searchLayers={searchLayers}
              customDataLoader={makeDataLoaderFor('blocks')}
              onProjectSelect={makeAddDependencyFor('blocks')}
            />
          </FormControl>
        </FlexItem>
      </FlexWrapper>
      {_renderDependencies(dependencies.blocks, link => onRemoveDependency(link, 'blocks'), 'blocks')}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 ${spacing(3)}px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(5)}px;
`;

const FlexItem = styled.div`
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
`;

const StyledProjectsDropdown = styled(ProjectsDropdown)`
  max-width: 75%;

  input {
    text-overflow: ellipsis;
  }
`;

const Label = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
`;

const StyledList = styled(List)`
  && {
    margin: ${spacing(2)}px 0 ${spacing(3)}px;
  }
`;

const Dependency = styled(ListItem)`
  &&&& {
    padding: 6px 0;
    margin-top: ${spacing()}px;
  }
`;

const DependencyText = styled(ListItemText)`
  &&&& {
    padding: 0;
    margin-left: ${spacing(2)}px;

    span {
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
      line-height: ${({ theme }) => theme.typography.lineHeightRegularSmallRem}rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(0.5)}px;
  margin-left: ${spacing(2)}px;
`;

const StyledButtonIcon = styled(ButtonIcon)`
  &&&& {
    padding: ${spacing(0.5)}px;
    color: ${({ theme }) => theme.palette.icons.primary};

    svg {
      font-size: ${({ theme }) => theme.typography.fontSizeMediumRem}rem;
    }
  }
`;

const OpenButton = styled(StyledButtonIcon)`
  &&&& svg {
    font-size: ${({ theme }) => theme.typography.fontSizeMediumSmallRem}rem;
  }
`;
