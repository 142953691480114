import html2canvas from 'html2canvas';

const _saveAs = (blob, fileName) => {
  const elem = window.document.createElement('a');

  elem.href = blob;
  elem.download = fileName;
  elem.style = 'display:none;';
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === 'function') {
    elem.click();
  } else {
    elem.target = '_blank';
    elem.dispatchEvent(
      new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      }),
    );
  }
  URL.revokeObjectURL(elem.href);
  elem.remove();
};

const exportEntirePage = async (onBeforeDownload, onAfterDownload) => {
  const rootElement = document.getElementById('root');

  if (onBeforeDownload) onBeforeDownload();

  const settings = {
    allowTaint: false,
    scrollY: -window.scrollY,
    windowWidth: rootElement.scrollWidth,
    windowHeight: rootElement.scrollHeight,
  };

  const canvas = await html2canvas(rootElement, settings);
  const image = canvas.toDataURL('image/png', 1.0);

  if (onAfterDownload) onAfterDownload();

  _saveAs(image, 'dragonboat.png');
};

export default exportEntirePage;
