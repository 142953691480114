import { READ_ONLY_USER } from '@dragonboat/permissions';

const MY_VIEW_COLUMNS = [
  'title',
  'objectiveTitle',
  'KeyResult1Title',
  'keyResult2Title',
  'priorityTitle',
  'themeTitle',
  'categoryTitle',
  'timeframeTitle',
  'planningStage',
  'ownerName',
  'roadmapTitle',
  'phaseTitle',
  'updatedByName',
  'actions',
];
const QUICK_ENTRY_VIEW_COLUMNS = ['title', 'roadmapTitle', 'ownerName', 'timeframeTitle', 'actions'];
const SCORE_VIEW_COLUMNS = [
  'title',
  'objectiveTitle',
  'KeyResult1Title',
  'keyResult2Title',
  'reach_score',
  'impact_score',
  'confidence_score',
  'effort_score',
  'riceScore',
  'roadmapTitle',
  'planningStage',
  'ownerName',
  'timeframeTitle',
  'business_value',
  'parent',
  'updatedByName',
  'actions',
];
const PROGRESS_VIEW_COLUMNS = [
  'status_color',
  'title',
  'parent',
  'status_summary',
  'objectiveTitle',
  'KeyResult1Title',
  'keyResult2Title',
  'planningStage',
  'roadmapTitle',
  'timeframeTitle',
  'Jiras',
  'progress',
  'phaseTitle',
  'themeTitle',
  'categoryTitle',
  'actions',
  'integrations',
];
const EMPTY_VIEW_COLUMNS = [
  'title',
  'roadmapTitle',
  'planningStage',
  'timeframeTitle',
  'ownerName',
  'status_summary',
  'objectiveTitle',
  'KeyResult1Title',
  'keyResult2Title',
  'themeTitle',
  'actions',
];
const ESTIMATES_VIEW_COLUMNS = [
  'title',
  'priorityTitle',
  'roadmapTitle',
  'objectiveTitle',
  'timeframeTitle',
  'phaseTitle',
  'planningStage',
  'business_value',
  'sum',
];

const PDLC_VIEW_COLUMNS = ['title', 'roadmapTitle', 'product1Title', 'timeframeTitle', 'integration', 'ownerName'];
const COMPARE_ROADMAP_VERSIONS_VIEW_COLUMNS = [
  'title',
  'roadmapTitle',
  'product1Title',
  'integration',
  'start_date_estimates',
  'end_date_estimates',
];

const GRID_VIEW_COLUMNS = {
  myView: MY_VIEW_COLUMNS,
  quickEntry: QUICK_ENTRY_VIEW_COLUMNS,
  score: SCORE_VIEW_COLUMNS,
  progress: PROGRESS_VIEW_COLUMNS,
  emptyView: EMPTY_VIEW_COLUMNS,
  estimates: ESTIMATES_VIEW_COLUMNS,
  pdlc: PDLC_VIEW_COLUMNS,
  compareRoadmapVersions: COMPARE_ROADMAP_VERSIONS_VIEW_COLUMNS,
};

/**
 * @function loadGridViewColumns
 *
 * Load the grid columns based on the specified view
 *
 * @param  {String} selectedView
 * @param  {Array} gridColumnDefs
 * @param  {Object} options
 * @param  {Boolean} options.has_hierarchy
 * @param  {Object} options.currentUser
 * @return {Array}
 */
const loadGridViewColumns = (selectedView, gridColumnDefs, { has_hierarchy: hasHierarchy, currentUser } = {}) => {
  let cols = GRID_VIEW_COLUMNS[selectedView];

  if (selectedView === 'estimates') {
    const estCols = gridColumnDefs.filter(column => column && column.isEstimateColumn).map(col => col.field);

    cols = [...cols, ...estCols];
  }

  if (!hasHierarchy) {
    cols = cols.filter(col => col !== 'parent');
  }

  // TODO: PERMISSION
  if (currentUser && currentUser.role_id === READ_ONLY_USER) cols = cols.filter(col => col !== 'actions');

  return cols;
};

export default loadGridViewColumns;
