import React, { useMemo, useCallback, useRef } from 'react';
import styled, { css } from 'styled-components';
import Highlighter from 'react-highlight-words';

import IdeasList from 'containers/IdeasList/IdeasList';
import IdeasListControlsBar from 'containers/IdeasList/IdeasListControlsBar';
import AllocationReport from 'containers/GanttAllocationReport';
import ProjectType from 'containers/ProjectType';
import ContentDrawer from 'components/ContentDrawer';
import { isNewForecastListEnabled } from 'constants/flags';
import IdeasForecastList from 'containers/IdeasForecastList';

import useNumberOfTextRowsToDisplayOnGridCell from 'hooks/useNumberOfTextRowsToDisplayOnGridCell';

import generateOkrIcon from 'utils/okrs/generateOkrIcon';
import { getGridTreeDataOptions, getRowStyle } from 'utils/projectsGrid';

import AgGridHierarchyTitle from 'design-system/organisms/AgGridHierarchyTitle/index';

import { draftBackgroundColor } from 'design-system/constants/draft';

const drawerContentStyles = css`
  margin-top: 0;
`;

export default props => {
  const {
    data,
    pageId,
    viewType,
    availableViews,
    onGridReady,
    getGridApi,
    showAllocationReport,
    onCloseAllocationReport,
    searchString,
    singleLayerGroupedBy,
    getSystemFieldName,
    onProjectLinkClick,
    onGroupsLinkClick,
    rowHeight,
    showPageOptionsReadOnly,
    allocationReportState,
    updateAllocationReportState,
    isCreatingOrViewingScenario,
    isRoadmapVersionsEnabled,
    customGroupBy2Options,
    dataForAllocation,
  } = props;

  const { getNumberOfTextRowsToDisplayOnGridCell } = useNumberOfTextRowsToDisplayOnGridCell(rowHeight);

  const searchRef = useRef();

  searchRef.current = searchString;

  const renderTitle = row => {
    if (!row) return '';
    if (!searchRef.current) return row.title;

    return (
      <>
        <Highlighter
          highlightClassName="hightlightText"
          searchWords={[searchRef.current]}
          autoEscape
          textToHighlight={row.title}
        />
      </>
    );
  };

  const rendererForForecast = useCallback(
    params => {
      if (!params.data || params?.data?.group) {
        return (
          <AgGridHierarchyTitle
            padChild
            params={params}
            showChildrenCount
            icon={params.data.okrType && generateOkrIcon(params.data.okrType)}
            link={(id, data) => onGroupsLinkClick(data)}
          />
        );
      }

      return (
        <AgGridHierarchyTitle
          renderTitle={renderTitle}
          icon={<ProjectType layer={params.data.layer} />}
          padChild
          params={params}
          link={id => () => onProjectLinkClick(id)}
          wrapText
          getMaxLineNumber={getNumberOfTextRowsToDisplayOnGridCell}
        />
      );
    },
    [onProjectLinkClick],
  );

  const gridOptions = useMemo(
    () => ({
      getRowStyle,
      ...(singleLayerGroupedBy
        ? getGridTreeDataOptions(false, singleLayerGroupedBy, 'Title', rendererForForecast, getSystemFieldName)
        : {}),
    }),
    [singleLayerGroupedBy, rendererForForecast, getSystemFieldName],
  );

  return (
    <Wrapper className="forecastList" useDraftStyle={isCreatingOrViewingScenario}>
      {showPageOptionsReadOnly ? (
        <IdeasListControlsBar
          viewType={viewType}
          availableViews={availableViews}
          pageId={pageId}
          numberOfRows={data.length}
          getGridApi={getGridApi}
          hasScenariosEnabled={isRoadmapVersionsEnabled}
          customGroupBy2Options={customGroupBy2Options}
          hasAllocationReport
          hasBulkUpdate
          hasBulkDelete
          hasImport
          hasViews
          hasGroupBy
          currentPageIsEstimates
        />
      ) : (
        <EmptyHeight />
      )}
      {isNewForecastListEnabled ? (
        <IdeasForecastList
          data={data}
          viewType={viewType}
          onGridReady={onGridReady}
          isCreatingOrViewingScenario={isCreatingOrViewingScenario}
        />
      ) : (
        <IdeasList
          data={data}
          viewType={viewType}
          gridOptions={gridOptions}
          treeData={!!singleLayerGroupedBy}
          onGridReady={onGridReady}
          renderTitle={renderTitle}
        />
      )}

      <ContentDrawer
        open={showAllocationReport}
        onClose={onCloseAllocationReport}
        contentStyles={drawerContentStyles}
        content={
          <AllocationReport
            viewType={viewType}
            lsState={allocationReportState}
            updateLsState={updateAllocationReportState}
            projects={showAllocationReport ? dataForAllocation : []}
          />
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  ${({ useDraftStyle }) => (useDraftStyle ? `background-color: ${draftBackgroundColor}` : '')};
`;

const EmptyHeight = styled.div`
  height: 48px;
`;
