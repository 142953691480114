import axios from 'axios';

import {
  CREATE_GRID_PERSONA,
  REMOVE_GRID_UNSAVED_PERSONA,
  CREATE_PERSONA_ROADMAP,
  DELETE_PERSONA_ROADMAP,
  BULK_DELETE_PERSONA_ROADMAP,
  CHANGE_ROW_HEIGHT_PERSONA_GRID,
  CHANGE_HIDE_ARCHIVED_PERSONA_GRID,
} from 'features/PersonasGrid/store/types';

const addNewPersona = () => ({
  type: CREATE_GRID_PERSONA,
});

const removeUnsavedPersona = () => ({
  type: REMOVE_GRID_UNSAVED_PERSONA,
});

const createPersonaRoadmap = (personaId, personaRoadmap) => {
  return {
    payload: axios.post(`/api/v1/personas/${personaId}/personaRoadmaps/`, personaRoadmap).then(response => response.data),
    type: CREATE_PERSONA_ROADMAP,
  };
};

const deletePersonaRoadmap = ({ id, roadmapId, subRoadmapId = null, product2Id = null }) => {
  let route = `/api/v1/personas/${id}/personaRoadmaps/${roadmapId}`;

  route += subRoadmapId ? `/${subRoadmapId}` : '';
  route += subRoadmapId && product2Id ? `/${product2Id}` : '';

  return {
    payload: axios.delete(route).then(response => response.data),
    type: DELETE_PERSONA_ROADMAP,
  };
};

const bulkDeletePersonaRoadmaps = personaId => {
  return {
    payload: axios.delete(`/api/v1/personas/${personaId}/personaRoadmaps/`).then(response => response.data),
    type: BULK_DELETE_PERSONA_ROADMAP,
  };
};

const changeRowHeight = height => ({
  type: CHANGE_ROW_HEIGHT_PERSONA_GRID,
  payload: height,
});

const changeHideArchived = (hideArchived = false) => ({
  type: CHANGE_HIDE_ARCHIVED_PERSONA_GRID,
  payload: hideArchived,
});

export {
  addNewPersona,
  removeUnsavedPersona,
  createPersonaRoadmap,
  deletePersonaRoadmap,
  bulkDeletePersonaRoadmaps,
  changeRowHeight,
  changeHideArchived,
};
