import React from 'react';
import styled from 'styled-components';
import DrawerView from 'design-system/templates/DrawerView/index';

import SettingsList from './Components/SettingsList';
import { Route, Switch, Redirect } from 'react-router-dom';
import getSettingRoutes from 'routes/Settings/Base/getSettingRoutes';

const MainView = styled(DrawerView)`
  &&&& {
    height: calc(100vh - 68px);
  }

  section {
    min-height: 0;
  }
`;

export default props => {
  const { visibleIntegrations, organization, user } = props;

  const routes = getSettingRoutes(props.getSystemFieldNames);

  const goTo = path => () => props.history.push(`/${path}`);
  const isActive = path => props.location.pathname.startsWith(`/${path}`);

  const filteredRoutes = Object.values(routes).filter(r => r.visible(props.user.role_id, props.user, props.organization));

  return (
    <MainView
      {...props}
      isDrawerOpen
      drawerPosition="left"
      drawerWidth="230px"
      drawerComponent={SettingsList}
      drawerProps={{
        routes,
        goTo,
        isActive,
        organization,
        user,
        subMenuArgs: { visibleIntegrations },
      }}
    >
      <Switch>
        <Route exact path="/settings" render={() => <Redirect to="/settings/account" />} />
        {filteredRoutes.map(r => {
          return <Route exact={r.exact !== false} path={`/${r.path}`} component={r.component} key={r.label} id={r.id} />;
        })}
      </Switch>
    </MainView>
  );
};
