// External dependencies
import React from 'react';
import styled from 'styled-components';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import { toast } from 'react-toastify';

// Dragonboat dependencies
import copyToClipboard from 'utils/copyToClipboard';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import ToastMessage from 'design-system/atoms/ToastMessage/index';

import { spacing } from 'design-system/theme';

export default ({ shareUrl, ...props }) => {
  const _selectAndCopy = async () => {
    await copyToClipboard(shareUrl);

    toast(<ToastMessage title="Link was copied to clipboard." />, {
      autoClose: 30000,
    });
  };

  if (!shareUrl) return '';

  return (
    <Wrapper {...props}>
      <CopyButton onClick={_selectAndCopy} title="Copy url to clipboard">
        <CopyIcon />
        Copy link
      </CopyButton>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const CopyButton = styled(ButtonIcon)`
  &&&& {
    font-size: ${({ theme }) => theme.typography.fontSize}px;
    color: ${({ theme }) => theme.palette.newLayout.text.siroccoGrey};
    border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;
    font-family: unset;
    padding: ${spacing()}px;

    svg {
      color: ${({ theme }) => theme.palette.newLayout.background.oldSilver};
      width: 18px;
      height: 18px;
      margin-right: ${spacing()}px;
    }
  }
`;
