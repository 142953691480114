import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import FluidField from 'design-system/molecules/FluidField/index';

import ProjectType from 'containers/ProjectType';

const RenderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 32px;

  span {
    margin-left: 10px;
  }
`;

const getTypeFromValue = value => {
  switch (value) {
    case 'milestone':
      return 'milestone';
    case '1':
      return 'initiative';
    case '2':
      return 'bet';
    case '0':
    default:
      return 'idea';
  }
};

const FluidTypeField = props => (
  <FluidField
    {...props}
    alwaysEditing="true"
    renderer={value => {
      const type = getTypeFromValue(value);
      const label = props.labels[type];

      return (
        <RenderContainer>
          <ProjectType type={type} />
          <span>{label}</span>
        </RenderContainer>
      );
    }}
    editorRenderer={({ value, save, cancel }) => (
      <ClickAwayListener onClickAway={cancel}>
        <FormControl fullWidth margin="dense">
          <RadioGroup name="layer" value={value} onChange={({ target }) => save(target.value)} row>
            <FormControlLabel
              value="milestone"
              control={<Radio />}
              label={props.labels.milestone}
              disabled={props.milestoneDisabled || (props.optionDisabled && props.optionDisabled('milestone'))}
            />
            <FormControlLabel
              value="0"
              control={<Radio />}
              label={props.labels.idea}
              disabled={props.optionDisabled && props.optionDisabled('0')}
            />
            {props.hasHierarchy && (
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={`${props.labels.initiative}${!props.hasBet ? ' (Highest)' : ''}`}
                disabled={props.optionDisabled && props.optionDisabled('1')}
              />
            )}
            {props.hasHierarchy && props.hasBet && (
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={`${props.labels.bet} (Highest)`}
                disabled={props.optionDisabled && props.optionDisabled('2')}
              />
            )}
          </RadioGroup>
        </FormControl>
      </ClickAwayListener>
    )}
  />
);

FluidTypeField.propTypes = {
  ...FluidField.propTypes,
  milestoneDisabled: PropTypes.bool,
  hasHierarchy: PropTypes.bool,
  labels: PropTypes.shape({
    milestone: PropTypes.string.isRequired,
    idea: PropTypes.string.isRequired,
    initiative: PropTypes.string.isRequired,
    bet: PropTypes.string.isRequired,
  }),
};

export default FluidTypeField;
