/**
 * ButtonIcon Molecule Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

export default class ButtonIcon extends PureComponent {
  render() {
    const { title, disabled, children } = this.props;
    const propsButton = { ...this.props };

    // need to remo title for child Button component
    delete propsButton.title;

    return title && !disabled ? (
      <Tooltip title={title}>
        <Button {...propsButton}>{children}</Button>
      </Tooltip>
    ) : (
      <Button title={title} {...propsButton}>
        {children}
      </Button>
    );
  }
}

const Button = styled(IconButton)`
  &&&& {
    color: #333;

    ${({ disabled }) =>
      disabled &&
      `
      color: #CCC;
    `}

    ${({ small }) =>
      small &&
      `
      padding: 4px;
    `}
  }
`;
