import React from 'react';
import styled from 'styled-components';

export default ({ children, childrenObjectName, noMargin, layer }) => {
  return (
    <Lower noMargin={noMargin}>
      <span>{children} {childrenObjectName}</span>
    </Lower>
  );
};

const Lower = styled.div`
  font-size: 11px;
  margin: ${({ noMargin }) => noMargin ? '0' : '8px 6px'};

  span{
    font-size: 12px;
  }
`;
