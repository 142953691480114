import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import RemirrorWYSIWYGEditor from 'design-system/molecules/RemirrorWYSIWYGEditor/index';

import { updatePageActiveView } from 'store/userViews';
import { DASHBOARDS_PAGE } from 'constants/filters/pages';

const DescriptionEditor = ({ description, inEditMode }) => {
  const dispatch = useDispatch();

  const descriptionIsEmpty = useMemo(() => !description || description === '<p></p>', [description]);

  const shouldDisplayDescriptionEditor = useMemo(() => !descriptionIsEmpty || inEditMode, [descriptionIsEmpty, inEditMode]);

  const updateDescription = updatedDescription =>
    dispatch(updatePageActiveView(DASHBOARDS_PAGE, { description: updatedDescription }));

  return shouldDisplayDescriptionEditor ? (
    <StyledRemirrorWYSIWYGEditor
      key="dashboard-description"
      value={description}
      content={description}
      placeholder="Add a description to this dashboard"
      disableEdit={!inEditMode}
      onChange={updateDescription}
      smallInput
    />
  ) : null;
};

export default DescriptionEditor;

const StyledRemirrorWYSIWYGEditor = styled(RemirrorWYSIWYGEditor)`
  &&&& {
    padding: ${({ disableEdit, theme }) => (disableEdit ? `0 ${theme.spacing.unit * 2}px` : `${theme.spacing.unit * 2}px`)};
    margin-left: 10px;
    margin-right: 10px;
    border: ${({ disableEdit, theme }) => `1px solid ${disableEdit ? 'transparent' : theme.palette.background.christmasSilver}`};
    border-radius: ${({ theme }) => theme.shape.borderRadiusLarge}px;

    .remirror-editor {
      padding: ${({ disableEdit, theme }) => (disableEdit ? 0 : `${theme.spacing.unit}px 0`)};
    }
  }
`;
