import { useCallback } from 'react';

import { TIMEFRAMES } from 'store/grids/constants';
import { useSettingsGridEventHandlers } from 'containers/Grids/SettingsGrid/hooks';

const TITLE_FIELD = 'title';

const useTimeframesGridEventHandlers = ({
  timeframes,
  update,
  createTimeframe,
  removeUnsavedTimeframes,
  switchTimeframesRowOrder,
}) => {
  const handleRowDrag = useCallback(
    (currentNode, lastOverNode, { position }) =>
      switchTimeframesRowOrder(currentNode.data.id, lastOverNode.data.id, null, position),
    [switchTimeframesRowOrder],
  );

  return useSettingsGridEventHandlers({
    settingType: TIMEFRAMES,
    items: timeframes,
    focusField: TITLE_FIELD,
    actions: {
      update,
      save: createTimeframe,
      remove: removeUnsavedTimeframes,
      drag: handleRowDrag,
    },
  });
};

export default useTimeframesGridEventHandlers;
