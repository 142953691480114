import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getColGroupOptions, getInlineGroupOptions, getRoadmapHistorySnapOption } from '../helpers/groupOptions';
import {
  getOrgCustomUserProjectFields,
  getOrgHasCustomUserProjectFields,
  hasMultiLevelPortfolioMetadata as hasMultiLevelPortfolioMetadataSelector,
  selectHasHierarchy,
  selectHasKeyResults,
  selectHasKeyResults2,
  selectHasProducts,
} from 'store/organization';
import useSystemFields from 'hooks/useSystemFields';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import { getDropdownCustomFields } from 'store/customFields/selectors';

const useGetOptionsForDropwdowns = ({ filters }) => {
  const hasMultiLevelPortfolioMetadata = useSelector(hasMultiLevelPortfolioMetadataSelector);
  const [getSystemFieldName] = useSystemFields();
  const { isDodActive } = useOrganizationsAccessControl();

  const hasHierarchy = useSelector(selectHasHierarchy);
  const hasKeyResults = useSelector(selectHasKeyResults);
  const hasKeyResults2 = useSelector(selectHasKeyResults2);
  const hasProducts = useSelector(selectHasProducts);

  const hasCustomUserFields = useSelector(getOrgHasCustomUserProjectFields);
  const customUserFields = useSelector(getOrgCustomUserProjectFields);

  const dropdownCustomFields = useSelector(state => getDropdownCustomFields(state));
  // const { portfolioMode, displayLayer } = getDisplayLayerAndPortfolioModeFromFilters(filters);
  const portfolioMode = true;
  const displayLayer = 1;

  const hasBet = useSelector(state => state.organization.organization.has_bet);

  const colGroupOptions = useMemo(
    () => getColGroupOptions({ getSystemFieldName, hasMultiLevelPortfolioMetadata }),
    [getSystemFieldName, hasMultiLevelPortfolioMetadata],
  );
  const rowGroupOptions = useMemo(() => [getRoadmapHistorySnapOption(getSystemFieldName)], [getSystemFieldName]);
  const inlineGroupOptions = useMemo(
    () =>
      getInlineGroupOptions({
        getSystemFieldName,
        isDodActive,
        hasKeyResults,
        hasKeyResults2,
        hasMultiLevelPortfolioMetadata,
        hasProducts,
        hasHierarchy,
        hasBet,
        portfolioMode,
        displayLayer,
        dropdownCustomFields,
        withTags: true,
        withCustomers: true,
        customUserFields: hasCustomUserFields ? customUserFields : {},
      }),
    [
      getSystemFieldName,
      isDodActive,
      hasKeyResults,
      hasKeyResults2,
      hasMultiLevelPortfolioMetadata,
      hasProducts,
      hasHierarchy,
      hasBet,
      portfolioMode,
      displayLayer,
      dropdownCustomFields,
      hasCustomUserFields,
      customUserFields,
    ],
  );

  return { colGroupOptions, rowGroupOptions, inlineGroupOptions };
};

export default useGetOptionsForDropwdowns;
