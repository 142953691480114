import React, { useEffect, useMemo, useState } from 'react';
import ChartWrapper from 'src/components/ReactChart/utils/ChartWrapper';
import Segment from 'design-system/atoms/Segment/index';

import { pluck, prop, reduceBy, uniq } from 'ramda';
import { BaseGrid } from 'containers/Grids';
import { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { getRoadmaps } from 'store/roadmaps/selectors';
import usePageFilters from 'hooks/filters/usePageFilters';
import { getUserFilters } from 'store/filters/selectors';
import { DASHBOARDS_PAGE } from 'constants/filters';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import axios from 'axios';
import useSystemFields from 'hooks/useSystemFields';
import { UNDEFINED_LABEL } from 'constants/common';
import TimelineLightbox from 'features/TimelineLightbox';
import { getOrganizationSystemFieldsNames } from 'store/organization';
import getSystemFieldName from 'utils/getSystemFieldName';

const gridStyles = css`
  .ag-layout-normal.ag-root {
    margin: 0;
  }
`;

export default ({ label, wrapper: Wrapper, disableClick, minHeight, actionButtons }) => {
  const [openTimeline, setOpenTimeline] = useState();
  const [roadmapForTimeline, setRoadmapForTimeline] = useState({});

  const systemFields = useSelector(state => getOrganizationSystemFieldsNames(state));
  const roadmaps = useSelector(getRoadmaps);
  const [getSystemField] = useSystemFields();
  const roadmapEntityName = getSystemField('roadmap');

  const { pageFilters: filters, displayLayer } = usePageFilters(DASHBOARDS_PAGE);
  const userFilters = useSelector(getUserFilters);
  const hasBet = useSelector(state => state.organization.organization.has_bet);

  const [reportData, setReportData] = useState([]);

  const fetchData = async () => {
    const filtersBody = compileFiltersBody(filters, userFilters, hasBet, DASHBOARDS_PAGE, displayLayer, null);

    const { data } = await axios.post('/api/v1/reports/portfolio/delivery-risk/dependencies', {
      projectsFilter: filtersBody,
      groupByField: 'roadmap_id',
    });

    setReportData(data);
  };

  useEffect(() => {
    fetchData();
  }, [filters, userFilters, hasBet, displayLayer]);

  const columnDefs = useMemo(() => {
    const blockingTeams = uniq(pluck('dependency_roadmap_id', reportData));

    return [
      { field: 'roadmap_id', headerName: roadmapEntityName },
      ...blockingTeams.map(blockedBy => {
        const roadmap = roadmaps.find(r => r.id === Number(blockedBy));

        return {
          field: `roadmap_${blockedBy}`,
          headerName: roadmap?.title || UNDEFINED_LABEL,
          onCellDoubleClicked: ({ data }) => {
            const roadmap = roadmaps.find(r => r.id === data.roadmap_original_id);

            setRoadmapForTimeline(roadmap || { id: null, title: UNDEFINED_LABEL });

            openTimeline();
          },
          cellStyle: ({ value }) => {
            if (!value) {
              return null;
            }

            return {
              'background-color': `rgba(112, 189, 215, ${value > 50 ? 1 : value / 50})`,
            };
          },
        };
      }),
    ];
  }, [roadmaps, roadmapEntityName, reportData]);

  const rowData = useMemo(() => {
    const reducedByRoadmap = reduceBy((acc, i) => acc.concat(i), [], prop('roadmap_id'), reportData);
    const rows = [];

    Object.entries(reducedByRoadmap).forEach(([roadmapId, blockedByInfo]) => {
      const roadmap = roadmaps.find(r => r.id === Number(roadmapId));

      rows.push({
        roadmap_id: roadmap?.title || UNDEFINED_LABEL,
        roadmap_original_id: roadmap?.id,
        ...blockedByInfo.reduce((carry, item) => {
          carry[`roadmap_${item.dependency_roadmap_id}`] = item.total;

          return carry;
        }, {}),
      });
    });

    return rows;
  }, [reportData, roadmaps]);

  const gridOptions = {
    rowData,
    columnDefs,
  };

  return (
    <Wrapper>
      <Segment bordered label={label} actionButtons={actionButtons}>
        <ChartWrapper height={minHeight}>
          <BaseGrid {...gridOptions} height={minHeight} cssStyles={gridStyles} />
        </ChartWrapper>
      </Segment>
      <TimelineLightbox
        onOpen={f => setOpenTimeline(() => f)}
        entityGroup={{ key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) }}
        groupBySelection={{ key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) }}
        groupedBy={roadmapForTimeline}
        lazyLoadProjects
        pageId={DASHBOARDS_PAGE}
      />
    </Wrapper>
  );
};
