import axios from 'axios';
import get from 'lodash/get';

import {
  DELETE_DASHBOARD_COMMENT,
  EDIT_DASHBOARD_COMMENT,
  FETCH_DASHBOARD_COMMENTS,
  FETCH_NEXT_DASHBOARD_COMMENTS,
  SAVE_DASHBOARD_COMMENT,
} from './types';

export const saveDashboardComment = (dashboardId, comment) => {
  return dispatch => {
    const payload = axios.post(`/api/userViews/${dashboardId}/comments`, comment).then(res => res.data);

    dispatch({
      type: SAVE_DASHBOARD_COMMENT,
      payload,
      meta: { dashboardId },
    });

    return payload;
  };
};

export const fetchDashboardComments = dashboardId => {
  return dispatch => {
    const payload = axios.get(`/api/userViews/${dashboardId}/comments`).then(res => res.data);

    dispatch({
      type: FETCH_DASHBOARD_COMMENTS,
      payload,
      meta: { dashboardId },
    });

    return payload;
  };
};

export const fetchNextDashboardComments = dashboardId => {
  return (dispatch, getState) => {
    const commentsState = getState().comments;
    const { next } = get(commentsState, `byDashboard[${dashboardId}]._meta`, {});

    const url = next || `/api/userViews/${dashboardId}/comments`;
    const payload = axios.get(url).then(res => res.data);

    dispatch({
      type: FETCH_NEXT_DASHBOARD_COMMENTS,
      payload,
      meta: { dashboardId },
    });

    return payload;
  };
};

export const deleteDashboardComment = (dashboardId, id) => {
  return {
    payload: axios.delete(`/api/userViews/${dashboardId}/comments/${id}`).then(response => response.data),
    type: DELETE_DASHBOARD_COMMENT,
    meta: { dashboardId, id },
  };
};

export const editDashboardComment = (dashboardId, id, data) => {
  return dispatch => {
    const payload = axios.put(`/api/userViews/${dashboardId}/comments/${id}`, data).then(response => response.data);

    dispatch({
      type: EDIT_DASHBOARD_COMMENT,
      payload,
      meta: { dashboardId, id },
    });

    return payload;
  };
};
