import { EDITOR_USER, PLANNER_USER } from '@dragonboat/permissions';
import { FeatureFlags } from '@dragonboat/config';
import useFeatureFlags from 'hooks/useFeatureFlags';

const useRoleAvailability = () => {
  const roleEditorIsAvailable = useFeatureFlags([FeatureFlags.HAS_ROLE_EDITOR]);
  const rolePlannerIsAvailable = useFeatureFlags([FeatureFlags.HAS_ROLE_PLANNER]);

  const isRoleAvailable = role => {
    if (role === EDITOR_USER) {
      return roleEditorIsAvailable;
    }
    if (role === PLANNER_USER) {
      return rolePlannerIsAvailable;
    }
    return true;
  };

  return {
    isRoleAvailable,
  };
};

export default useRoleAvailability;
