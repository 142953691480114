import React from 'react';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

import RoadmapsTreeDropdownDoD from './RoadmapsTreeDropdownDoD';
import RoadmapsTreeDropdownNoDoD from './RoadmapsTreeDropdown';
import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';

const RoadmapsTreeDropdown = props => {
  const { isDodActive } = useOrganizationsAccessControl();

  if (isDodActive) {
    return <RoadmapsTreeDropdownDoD placeholder={TYPE_TO_SEARCH_PLACEHOLDER} {...props} />;
  }

  return <RoadmapsTreeDropdownNoDoD placeholder={TYPE_TO_SEARCH_PLACEHOLDER} {...props} />;
};

export default RoadmapsTreeDropdown;
