import React from 'react';

import InviteYourTeamButton from 'containers/InviteYourTeamButton';
import withTheme from '@material-ui/core/styles/withTheme';

const HeaderOptions = props => {
  return <InviteYourTeamButton {...props} />;
};

export default withTheme()(HeaderOptions);
