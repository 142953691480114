/**
 * @param {Immutable.Map} row
 * @param {number} dependencyId
 * @param {string} dependenciesKey - "projectDependencies" | "estimateDependencies" | "taskDependencies"
 */
export default (row, dependencyId, dependenciesKey) => {
  if (row.has(dependenciesKey)) {
    const dependencyIndex = row
      .get(dependenciesKey)
      .findIndex(dependency => dependency.get('id') === dependencyId);

    if (dependencyIndex >= 0) {
      return row.set(dependenciesKey, row.get(dependenciesKey).delete(dependencyIndex));
    }
  }

  return row;
};
