const METRIC_HEALTH_COLORS_OPTIONS = [
  { label: 'Undefined', value: '' },
  { label: 'On track', value: 'Green' },
  { label: 'At risk', value: 'Yellow' },
  { label: 'Off track', value: 'Red' },
  { label: 'Completed', value: 'Blue' },
];

export {
  METRIC_HEALTH_COLORS_OPTIONS,
};