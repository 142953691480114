import React, { useState } from 'react';
import styled from 'styled-components';

import PageLoading from 'design-system/atoms/PageLoading/PageLoading';
import BaseLayout from 'design-system/organisms/BaseLayout/index';

import { draftBackgroundColor } from 'design-system/constants/draft';

import ImportIdeasDialog from 'containers/ImportIdeasDialog';
import PlanningStageWarningDialog from 'components/PlanningStageWarningDialog';

import { SummaryTableDataProvider } from './hooks/useSummaryTableData';
import ActionsBar from './ActionsBar';
import SummaryTable from './SummaryTable';

import { PAGE_HEADER_HEIGHT } from 'constants/common';

const Summary = ({
  currentUser,
  isLoading,
  isWorkingWithRoadmapVersion,
  pageFilters,
  metadata,
  showControlsForUser: showActionsBar,
  useGroupsHook,
  getGroupOptions,
  getGroupOptionByKey,
  isGoalMode = false,
  slice,
  ActionbarComponent = ActionsBar,
  hasShowMyItemFilter = false,
  viewsDropdownTabs,
  ...props
}) => {
  const [isPlanningStageWarningVisible, setIsPlanningStageWarningVisible] = useState(false);

  if (isLoading) return <PageLoading />;

  return (
    <Wrapper useDraftStyle={isWorkingWithRoadmapVersion}>
      <BaseLayout>
        <SummaryTableDataProvider
          metadata={metadata}
          pageFilters={pageFilters}
          useGroupsHook={useGroupsHook}
          getGroupOptions={getGroupOptions}
          getGroupOptionByKey={getGroupOptionByKey}
          isGoalMode={isGoalMode}
          slice={slice}
        >
          {showActionsBar && (
            <ActionbarComponent
              isGoalMode={isGoalMode}
              getGroupOptionsSelector={props.getGroupOptionsSelector}
              slice={slice}
              hasShowMyItemFilter={hasShowMyItemFilter}
              viewsDropdownTabs={viewsDropdownTabs}
            />
          )}
          <PageBody>
            <SummaryTable
              isLoading={isLoading}
              showActionsBar={showActionsBar}
              metadata={metadata}
              onChangingPlanningStage={setIsPlanningStageWarningVisible}
              isGoalMode={isGoalMode}
              slice={slice}
              {...props}
            />
          </PageBody>
          <ImportIdeasDialog />
          <PlanningStageWarningDialog
            isOpen={isPlanningStageWarningVisible}
            onConfirm={() => setIsPlanningStageWarningVisible(false)}
          />
        </SummaryTableDataProvider>
      </BaseLayout>
    </Wrapper>
  );
};

export default Summary;

const Wrapper = styled.div`
  height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
  overflow: auto;
  ${({ useDraftStyle }) => (useDraftStyle ? `background-color: ${draftBackgroundColor}` : '')};
`;

const PageBody = styled.div``;
