import { defaultTo } from 'ramda';

const UNDEFINED_VALUE = 'null';

const defaultToEmptyArray = defaultTo([]);
const mapArrayValuesToInt = array => {
  return array.map(v => +v);
};

const hasMatchingRoadmaps = (metricRoadmaps, filterRoadmaps, filterProducts) =>
  metricRoadmaps.some(
    roadmap =>
      mapArrayValuesToInt(filterRoadmaps).includes(roadmap?.roadmap_id) ||
      mapArrayValuesToInt(filterProducts).includes(roadmap?.product_1_id) ||
      mapArrayValuesToInt(filterProducts).includes(roadmap?.product_2_id),
  );

const hasSelectedUndefined = filterRoadmaps => filterRoadmaps.includes(UNDEFINED_VALUE);

const cleanMetricRoadmaps = metricRoadmaps => metricRoadmaps.filter(roadmap => !!roadmap.id);

export { cleanMetricRoadmaps, defaultToEmptyArray, hasMatchingRoadmaps, hasSelectedUndefined, mapArrayValuesToInt };
