import React from 'react';
import styled, { css } from 'styled-components';
import isFunction from 'lodash/isFunction';

import FluidField from 'design-system/molecules/FluidField/index';
import TextFieldNoBorder from 'design-system/atoms/TextFieldNoBorder/index';
import InlineDatePickerNoBorder from 'design-system/atoms/InlineDatePickerNoBorder/index';

import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

const FluidNumberAndDateField = props => {
  const showHoverStyle = !props.disableEdit && props.highlightSingleField;

  return (
    <FluidField
      {...props}
      editorRenderer={({ value, onChange, save, cancel, disableEdit, placeholder }) => (
        <Grid container spacing={8}>
          <Grid item lg={4} md={12}>
            <StyledTextFieldNoBorder
              value={value}
              onChange={e => {
                if (!isFunction(props.validateTextField)) return onChange(e.target.value);
                if (props.validateTextField(e.target.value)) {
                  onChange(e.target.value);
                }
              }}
              style={{ width: '100%' }}
              disabled={disableEdit}
              {...saveOnTabOrBlur(save, cancel)}
              hover={showHoverStyle}
            />
          </Grid>
          <Grid item lg={8} md={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <StyledInlineDatePickerNoBorder
                clearable
                keyboard
                open
                format="YYYY-MM-DD"
                onChange={props.onDateChange}
                value={props.dateValue}
                style={{ width: '100%' }}
                disabled={disableEdit}
                hover={showHoverStyle}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      )}
      ignoreBlurOn={() => !!document.querySelector('#picker-popover')}
    />
  );
};

const saveOnTabOrBlur = (save, cancelEdit) => ({
  onKeyDown: e => {
    e.stopPropagation();

    if (e.which === 9 || e.which === 13) save();
    if (e.which === 27) cancelEdit();
  },
  onBlur: _ => save(),
});

const hoverStyles = css`
  ${({ hover }) =>
    hover &&
    `
    &:hover {
      background-color: rgb(235, 236, 240);
    }
  `}
`;

const StyledInlineDatePickerNoBorder = styled(InlineDatePickerNoBorder)`
  ${hoverStyles};

  input {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    text-align: right;
  }

  button {
    svg {
      font-size: ${({ theme }) => theme.typography.fontSizeSmallLargeRem}rem;
    }
  }
`;

const StyledTextFieldNoBorder = styled(TextFieldNoBorder)`
  ${hoverStyles};
`;

FluidNumberAndDateField.propTypes = { ...FluidField.propTypes };

export default FluidNumberAndDateField;
