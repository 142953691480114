import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { defaultTo, not } from 'ramda';

import { getBaseCompareVersionSelected } from 'store/roadmapVersions/selectors';
import { getAllProjectsFiltered } from 'store/projects/groupSelectors';

import useRoadmapVersions from 'hooks/useRoadmapVersions';

import { COMPARE_SCENARIOS_FILTER } from 'constants/filters/pages';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';

const defaultToEmptyArray = defaultTo([]);

const exist = Boolean;

const baseIsPorObject = val => exist(val?.isPoRBase);

const useBaseProjectsForComparison = displayLayer => {
  const baseCompareVersionSelected = useSelector(getBaseCompareVersionSelected);
  const { roadmapVersionsProjects } = useRoadmapVersions();

  const allData = useSelector(state => getAllProjectsFiltered(state, COMPARE_SCENARIOS_FILTER, true, true));

  const projects = useMemo(() => {
    const shouldReturnAllData = not(baseCompareVersionSelected) || baseIsPorObject(baseCompareVersionSelected);

    if (shouldReturnAllData) return allData;

    const versionProjects = [...defaultToEmptyArray(roadmapVersionsProjects?.[baseCompareVersionSelected?.id])];

    return {
      [IDEA_LAYER]: [],
      [INITIATIVE_LAYER]: [],
      [BET_LAYER]: [],
      [displayLayer]: versionProjects,
    };
  }, [allData, displayLayer, roadmapVersionsProjects, baseCompareVersionSelected]);

  return { projects };
};

export default useBaseProjectsForComparison;
