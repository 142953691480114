import React from 'react';
import noop from 'lodash/noop';

import DndItem from 'design-system/molecules/DndItem/index';

const GroupDndItemComponent = props => {
  return <DndItem {...props} onClickItem={noop} onSelectItem={noop} />;
};

const groomGenericAutocompleteOptions = (options, withNullOption = false) => {
  const formatOptions = options => {
    return options.map(option => {
      if (option.isGroup) {
        return {
          ...option,
          dndItemComponent: GroupDndItemComponent,
          // Recursively process sub-options if they exist
          ...(option.children ? { children: formatOptions(option.children) } : {}),
        };
      }
      return option;
    });
  };

  const formattedOptions = formatOptions(options);

  return [...(withNullOption ? [{}] : []), ...formattedOptions];
};

export default groomGenericAutocompleteOptions;
