import axios from 'axios';
import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Router, Route, Redirect, Switch } from 'react-router-dom';

import CaptureUnmatchedRoute from 'routes/NotFound/CaptureUnmatchedRoute';
import Main from 'containers/Main';
import Login from 'routes/LogIn';
import AzureSSO from 'routes/SSO/AzureSSO';
import OktaSSO from 'routes/SSO/OktaSSO';
import SlackPopup from 'routes/Settings/Integration/Slack/SlackPopup';
import MiroRedirectAuth from 'routes/Settings/Integration/Miro/MiroRedirectAuth';
import SSOIFrame from 'routes/SSOIFrame';
import SuperLogin from 'routes/LogIn/SuperLogin';
import GoogleLoginButton from 'routes/LogIn/GoogleLoginButton';
import Signup from 'routes/Signup';
import Workspace from 'routes/Workspace';
import NewPassword from 'routes/NewPassword';
import ForgotPassword from 'routes/ForgotPassword';
import ForgotPasswordConfirmation from 'routes/ForgotPasswordConfirmation';
import ForgotWorkspaceConfirmation from 'routes/ForgotWorkspaceConfirmation';
import ForgotWorkspace from 'routes/ForgotWorkspace';
import JiraCallback from 'routes/JiraCallback/JiraCallback';
import SalesforceRedirectAuth from 'routes/Settings/Integration/Salesforce/SalesforceRedirectAuth';
import AzureDevopsCallback from 'routes/Settings/Integration/AzureDevops/components/AzureDevopsCallback';
import RallyCallback from 'routes/Settings/Integration/Rally/components/RallyCallback';
import SignupPending from 'routes/SignupPending';
import SignupExistingAccountConfirmation from 'routes/SignupExistingAccountConfirmation';
import GoogleSignup from 'routes/GoogleSignup';
import ApproveDeclineGoogleSignup from 'routes/ApproveDeclineGoogleSignup';
import Zendesk from 'components/Zendesk';
import ZendeskRedirect from 'routes/LogIn/ZendeskRedirect';
import Stripe from 'components/Stripe';
import history from 'store/utils/history';
import { getDefaultRoute } from 'utils';
import withTracker from 'utils/withTracker';
import RequestAccess from 'routes/RequestAccess';
import SignupConfirmation from 'routes/SignupConfirmation';
import { getCurrentUserLoginCount, getCurrentUserRoleId } from 'store/login/selectors';

import getSignupPathMatch from 'routes/helpers/getSignupPathMatch';

import Startup from './Startup';
import QueryString from 'query-string';
import PublicSharedView from './PublicSharedView';

import OneStepRedirectAuth from 'features/OneStepIntegration/components/OneStepRedirectAuth';

export default () => {
  const loginCount = useSelector(getCurrentUserLoginCount);
  const roleId = useSelector(getCurrentUserRoleId);

  const currentUser = {
    login_count: loginCount,
    role_id: roleId,
  };

  const REACT_APP_VERSION = process.env.REACT_APP_VERSION && process.env.REACT_APP_VERSION.replace(/"/g, '');
  const { REACT_APP_BUILD_DATE } = process.env;

  axios.defaults.headers.common['x-frontend-version'] = REACT_APP_VERSION;
  axios.defaults.headers.common['x-frontend-build-date'] = REACT_APP_BUILD_DATE;

  const workspacePath = '/workspace';
  const promo10millionhoursPath = '/10millionhours';
  const googleButtonPath = '/google_button';
  const loginPath = '/login';
  const superloginPath = '/superlogin';
  const requestAccessPath = '/request_access';
  const ssoPath = '/sso';
  const azureSSOPath = '/sso/azure';
  const ssoIFramePath = '/ssoiframe';
  const slackVerifyPath = '/slack-setup';
  const zendeskRedirect = '/zendesk-redirect';
  const jiraCallback = '/jira/callback';
  const azuredevopsCallback = '/azuredevops/callback';
  const globalPaths = [
    workspacePath,
    promo10millionhoursPath,
    googleButtonPath,
    loginPath,
    superloginPath,
    requestAccessPath,
    azureSSOPath,
    ssoPath,
    ssoIFramePath,
    slackVerifyPath,
    zendeskRedirect,
    azuredevopsCallback,
  ];
  const nonSlugPaths = [
    workspacePath,
    promo10millionhoursPath,
    googleButtonPath,
    ssoPath,
    slackVerifyPath,
    azureSSOPath,
    ssoIFramePath,
    azuredevopsCallback,
  ];
  const noStartupPaths = [jiraCallback, googleButtonPath, zendeskRedirect, azuredevopsCallback];

  useEffect(() => {
    if (
      window.location.origin === process.env.REACT_APP_PUBLIC_URL &&
      globalPaths.indexOf(window.location.pathname) === -1 &&
      !getSignupPathMatch(window.location.pathname)
    ) {
      const next =
        window.location.pathname === '/miro-redirect/'
          ? `?next=${window.location.pathname}&authorize=true&${QueryString.stringify(QueryString.parse(window.location.search))}`
          : '';

      window.location.href = `/login${next}`;
    } else if (
      (nonSlugPaths.indexOf(window.location.pathname) > -1 || getSignupPathMatch(window.location.pathname)) &&
      window.location.origin !== process.env.REACT_APP_PUBLIC_URL
    ) {
      window.location = `${process.env.REACT_APP_PUBLIC_URL}${window.location.pathname}`;
    }
  }, []);

  return (
    <Fragment>
      <Startup ignore={noStartupPaths.includes(window.location.pathname)}>
        <Router history={history}>
          <CaptureUnmatchedRoute>
            <Switch>
              <Route path="/reload" component={null} key="reload" />
              <Route
                path="/login"
                component={props => {
                  const component =
                    window.location.origin !== process.env.REACT_APP_PUBLIC_URL ? Login : withTracker(Workspace, {});

                  return React.createElement(withTracker(component), { ...props });
                }}
              />
              <Route path="/ssoiframe" component={withTracker(SSOIFrame, {})} />
              <Route path="/sso/azure" component={props => <AzureSSO {...props} />} />
              <Route path="/sso" component={withTracker(OktaSSO, {})} />
              <Route
                path="/superlogin"
                component={props => {
                  const component =
                    window.location.origin !== process.env.REACT_APP_PUBLIC_URL ? SuperLogin : withTracker(Workspace, {});

                  return React.createElement(withTracker(component), { ...props });
                }}
              />
              <Route path="/slack-setup" component={withTracker(SlackPopup, {})} />
              <Route path="/zendesk-redirect" component={props => <ZendeskRedirect {...props} />} />
              <Route path="/miro-redirect" component={withTracker(MiroRedirectAuth, {})} />
              <Route path="/signup" component={withTracker(Signup, {})} />
              <Route path="/signup_confirmation" component={withTracker(SignupConfirmation, {})} />
              <Route path="/new_password" component={withTracker(NewPassword, {})} />
              <Route path="/reset_password" component={withTracker(ForgotPassword, {})} />
              <Route path="/reset_password_confirmation" component={withTracker(ForgotPasswordConfirmation, {})} />
              <Route path="/forgot_workspace_confirmation" component={withTracker(ForgotWorkspaceConfirmation, {})} />
              <Route path="/forgot_workspace" component={withTracker(ForgotWorkspace, {})} />
              <Route path="/google_button" component={props => <GoogleLoginButton {...props} />} />
              <Route path="/request_access" component={withTracker(RequestAccess, {})} />
              <Route path="/google_signup" component={props => <GoogleSignup {...props} />} />
              <Route path="/signup_pending" component={withTracker(SignupPending, {})} />
              <Route
                path="/signup_existing_account_confirmation"
                component={withTracker(SignupExistingAccountConfirmation, {})}
              />

              <Route path="/workspace" component={withTracker(Workspace, {})} />
              <Route
                path="/user_signup"
                component={props => {
                  return React.createElement(withTracker(ApproveDeclineGoogleSignup), { ...props, isApproved: true });
                }}
              />
              <Route path="/user_signup/decline" component={withTracker(ApproveDeclineGoogleSignup, {})} />
              <Route path="/jira/callback" component={props => <JiraCallback {...props} />} />
              <Route path="/azuredevops/callback" component={props => <AzureDevopsCallback {...props} />} />
              <Route path="/rally/callback" component={props => <RallyCallback {...props} />} />
              <Route path="/salesforce/callback" component={props => <SalesforceRedirectAuth {...props} />} />
              <Route path="/integrations/callback" component={props => <OneStepRedirectAuth {...props} />} />
              <Redirect exact path="/10millionhours" to="/signup" />

              <Route path="/share/:shareLinkHash" component={withTracker(PublicSharedView, {})} />
              <Redirect path="/manage" to={`/ideas/timeline${window.location.search}`} />
              <Route path="/portal" component={withTracker(Main, {})} />
              <Route path="/:tab" component={withTracker(Main, {})} />
              <Redirect exact path="/" to={getDefaultRoute(currentUser)} />
            </Switch>
          </CaptureUnmatchedRoute>
        </Router>
      </Startup>
      <Zendesk />
      <Stripe />
    </Fragment>
  );
};
