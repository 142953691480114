/* eslint-disable */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default ({ size = 20, marginRight }) => {
  return (
    <SvgIcon role="img" aria-label="Miro" style={{ width: size, height: size, marginRight: marginRight }} 
    width="256px" height="256px" viewBox="0 0 256 256">
      <title>Miro</title>
      <path d="M 0 0 L 256 0 L 256 256 L 0 256 L 0 0 Z" fill="#ffd02f"/>
      <path d="M 174.194 31.656 L 146.827 31.656 L 171.123 69.309 L 119.759 31.656 L 92.393 31.656 L 116.688 80.995 L 65.325 31.656 L 37.958 31.656 L 61.853 95.352 L 37.958 219.656 L 65.325 219.656 L 116.688 86.374 L 92.393 219.656 L 119.759 219.656 L 171.123 74.693 L 146.827 219.656 L 174.194 219.656 L 225.958 60.417 L 174.194 31.656 Z" fill="#050038"/>
    </SvgIcon>);
};
