import React from 'react';
import styled, { css } from 'styled-components';

import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { pipe, isNil, not } from 'ramda';

const exist = Boolean;
const doesntExist = pipe(exist, not);
const isNotNil = pipe(isNil, not);

export default ({ onClose, onSettingsLinkClicked, oldLightboxActive }) => {
  const isDrawerVersionActive = isNotNil(oldLightboxActive) && doesntExist(oldLightboxActive);

  return (
    <Container container>
      <Grid item xs={6}>
        <SettingsLink row>
          <Button name="metadata-settings" color="primary" onClick={onSettingsLinkClicked}>
            Go to the settings page
          </Button>
        </SettingsLink>
      </Grid>
      <Grid item xs={6}>
        <SaveCancelActions row>
          <CloseButton name="close" color="primary" onClick={() => onClose()} isDrawerVersionActive={isDrawerVersionActive}>
            CLOSE
          </CloseButton>
        </SaveCancelActions>
      </Grid>
    </Container>
  );
};

const Container = styled(Grid)`
  &&&& {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const SettingsLink = styled(FormGroup)`
  &&&& {
    justify-content: flex-start;
  }
`;

const SaveCancelActions = styled(FormGroup)`
  &&&& {
    justify-content: flex-end;
  }
`;

const CloseButton = styled(Button)`
  &&&& {
    ${props =>
      props.isDrawerVersionActive &&
      css`
        margin-right: 60px;
      `}
  }
`;
