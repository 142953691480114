import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

export default ({ onClose }) => {
  return (
    <Wrapper>
      <StyledLink to="/dashboard/home" onClick={onClose}>
        <StyledButton color="primary">Go to the Views page</StyledButton>
      </StyledLink>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const StyledLink = styled(Link)`
  && {
    text-decoration: none;
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    margin-right: 60px;
  }
`;
