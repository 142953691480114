// Sorts user Views based on the following criteria:
// If a View is active it should be first
// Then should be the favorite views
// Then the remaining Views by the 'updated_at' date

export default userViews =>
  userViews.sort((a, b) => {
    if (a.active) return -1;
    if (b.active) return 1;

    const aIsFavorite = a.favorites?.length > 0;
    const bIsFavorite = b.favorites?.length > 0;

    if (aIsFavorite && !bIsFavorite) return -1;
    if (!aIsFavorite && bIsFavorite) return 1;

    const aDate = new Date(a.updated_at);
    const bDate = new Date(b.updated_at);

    return bDate - aDate;
  });
