import React from 'react';
import FluidField from 'design-system/molecules/FluidField/index';
import RemirrorWYSIWYGEditor from 'design-system/molecules/RemirrorWYSIWYGEditor/index';

export default ({
  content,
  onBlur,
  onChange,
  disableEdit,
  baseURLForAttachments,
  placeholder,
  outputJSON = false,
  disableToolbar = false,
  smallInput = false,
  noPadding = false,
  imageUploadHandler,
  onEditorStateChange,
  onInit,
  minHeight,
  maxHeight,
  ...props
}) => {
  return (
    <FluidField
      {...props}
      editorRenderer={() => {
        // TODO switch to RemirrorWYSIWYGEditor component instead - delete unused files after
        return (
          <RemirrorWYSIWYGEditor
            content={content}
            onBlur={onBlur}
            onChange={onChange}
            disableEdit={disableEdit}
            baseURLForAttachments={baseURLForAttachments}
            outputJSON={outputJSON}
            disableToolbar={disableToolbar}
            smallInput={smallInput}
            noPadding={noPadding}
            placeholder={placeholder}
            imageUploadHandler={imageUploadHandler}
            onEditorStateChange={onEditorStateChange}
            onInit={onInit}
            minHeight={minHeight}
            maxHeight={maxHeight}
          />
        );
      }}
    />
  );
};
