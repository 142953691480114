import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export default function AnnouncementEditor({ handleValueChange, value, isAdmin }) {
  return (
    <EditorContainer>
      <StyledTextField
        defaultValue={value}
        multiline
        onChange={handleValueChange}
        rows={3}
        variant="outlined"
        InputProps={{
          readOnly: !isAdmin,
        }}
      />
    </EditorContainer>
  );
}

const EditorContainer = styled.div``;

const StyledTextField = styled(TextField)`
  width: 100%;

  &&&& {
    margin-top: 5px;

    fieldset {
      border-color: ${({ theme }) => theme.palette.newLayout.background.mercuryGrey};
    }
  }
`;
