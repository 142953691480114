import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getActiveViewForPage } from '../../store/userViews/selectors';
import { getPageIdFromPath } from 'utils/userViews';

/**
 * @function useActiveViewChanged
 *
 * Returns a boolean indicating if active view changed and what was the new active view applied
 *
 * @return {Object}
 */
const useActiveViewChanged = () => {
  const activeView = useSelector(state => getActiveViewForPage(state, getPageIdFromPath(window.location.pathname)));
  const previousViewId = useRef(activeView?.id);

  useEffect(() => {
    if (!activeView || previousViewId.current === activeView.id) {
      return;
    }

    previousViewId.current = activeView.id;
  }, [previousViewId.current, activeView]);

  return {
    activeViewChanged: activeView && previousViewId.current !== activeView.id,
    switchedToDefaultView: activeView?.default_view,
  };
};

export default useActiveViewChanged;
