import { createSelector } from 'reselect';

// import { getEnrichedProject } from 'store/projects/selectors';

export function getTasks(state) {
  return state.tasks.tasks;
}

export const makeGetStaffingTasks = () => {
  return createSelector(getTasks, tasks => tasks);
};

export const getStaffingTasks = makeGetStaffingTasks();
