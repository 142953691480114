import { IDEAS_OVERVIEW_PATH } from 'constants/filters/pages';
import { METADATA_LEVELS } from 'constants/common';

import { PRODUCT_2_QUERY_PARM, PRODUCT_QUERY_PARM, ROADMAP_QUERY_PARM } from '../constants';

const getRodmapEntityUrl = (id, type) => {
  switch (type) {
    case METADATA_LEVELS.LEVEL_CORP:
    case METADATA_LEVELS.LEVEL_1:
      return `/${IDEAS_OVERVIEW_PATH}?${ROADMAP_QUERY_PARM}=${id}`;
    case METADATA_LEVELS.LEVEL_2:
      return `/${IDEAS_OVERVIEW_PATH}?${PRODUCT_QUERY_PARM}=${id}`;
    case METADATA_LEVELS.LEVEL_3:
      return `/${IDEAS_OVERVIEW_PATH}?${PRODUCT_2_QUERY_PARM}=${id}`;
    default:
      return `/${IDEAS_OVERVIEW_PATH}`;
  }
};

export default getRodmapEntityUrl;
