import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import useSystemFields from 'hooks/useSystemFields';
import usePermissions from 'hooks/permissions/usePermissions';
import useProjectsListLightboxContext from 'hooks/useProjectsListLightbox';
import useOpenMetricFromEntityWithMetrics from 'hooks/useOpenMetricFromEntityWithMetrics';
import history from 'store/utils/history';
import { getHasAdvancedMetricReporting } from 'store/organization/selectors';
import { METRIC } from 'constants/common';
import { PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';
import { getWidgetTitleForRoadmapLevel } from '../helpers';
import useSelectedRoadmapOnPortfolioOverview from '../../hooks/useSelectedRoadmapOnPortfolioOverview';
import { subscribeToRefreshPortfolioOverviewMetricsChanged } from '../../store/listenerMiddleware';
import {
  selectPortfolioOverviewMetricById,
  selectPortfolioOverviewMetricsData,
  selectIsLoadingFetchPortfolioOverviewMetrics,
} from '../../store';

const SETTINGS_METRICS_URL = '/settings/metrics';

const usePortfolioOverviewMetrics = () => {
  const dispatch = useDispatch();

  const { canView, canUpdate } = usePermissions();

  const [getSystemFieldName] = useSystemFields();

  const { activeRoadmapEntity } = useSelectedRoadmapOnPortfolioOverview();

  const { handleOpenMetricView } = useOpenMetricFromEntityWithMetrics();

  const { openProjectsListLightbox: handleOpenProjectsListLightbox } = useProjectsListLightboxContext();

  const [selectedMetric, setSelectedMetric] = useState(null);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const canUpdateMetric = canUpdate(PERMISSION_RESOURCES.metric);

  const data = useSelector(selectPortfolioOverviewMetricsData);

  const metricForPopover = useSelector(state => selectPortfolioOverviewMetricById(state, selectedMetric?.id));

  const isLoading = useSelector(selectIsLoadingFetchPortfolioOverviewMetrics);

  const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);

  const canGoToSettings = canView(PERMISSION_PAGES.settingsMetricsPage);

  const title = getWidgetTitleForRoadmapLevel(activeRoadmapEntity, METRIC, getSystemFieldName);

  const goToMetricsSettings = () => history.push(SETTINGS_METRICS_URL);

  const getRowId = ({ data }) => data.id;

  const openMetricPopover = (metric, e) => {
    setSelectedMetric(metric);
    setPopoverAnchorEl(e?.currentTarget);
  };

  useEffect(() => {
    const unsubscribeRefreshData = dispatch(subscribeToRefreshPortfolioOverviewMetricsChanged());

    return unsubscribeRefreshData;
  }, []);

  return {
    data,
    dataLength: data?.length || 0,
    isLoading,
    title,
    metricForPopover,
    popoverAnchorEl,
    canUpdateMetric,
    canGoToSettings,
    openMetricView: hasAdvancedMetricReporting && handleOpenMetricView,
    openProjectsListLightbox: metric => handleOpenProjectsListLightbox(metric, METRIC),
    goToMetricsSettings,
    getRowId,
    openMetricPopover,
    setPopoverAnchorEl,
  };
};

export default usePortfolioOverviewMetrics;
