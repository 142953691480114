import { pluck } from 'ramda';

import { CREATE_USERS_FULFILLED, UPDATE_USERS_FILTERS } from 'store/users/types';
import { CREATE_USER_ON_SETTINGS_FULFILLED } from './types';

const initialState = {
  createdUsersIds: [],
};

export default function usersSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_USER_ON_SETTINGS_FULFILLED: {
      if (!action?.payload) return state;
      return {
        ...state,
        createdUsersIds: [...state.createdUsersIds, action.payload.id],
      };
    }

    case CREATE_USERS_FULFILLED: {
      if (!action?.payload) return state;

      const createdIds = pluck('id', action.payload);

      return {
        ...state,
        createdUsersIds: [...state.createdUsersIds, ...createdIds],
      };
    }

    case UPDATE_USERS_FILTERS: {
      return { ...state, createdUsersIds: [] };
    }

    default:
      return state;
  }
}
