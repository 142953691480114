import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useSubscribeNewData from 'hooks/useSubscribeNewData';
import { getOrganization } from 'store/organization/selectors';
import { updateSystemFieldsName as updateSystemFieldsNameAction } from 'store/organization';
import { topics } from 'constants/topics';

const TOPICS_TO_SUBSCRIBE = [topics.CUSTOMERS, topics.USER_VIEWS, topics.USER_FILTERS, topics.APP];

const CustomersHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const organization = useSelector(getOrganization);

    useSubscribeNewData(TOPICS_TO_SUBSCRIBE);

    const updateSystemFieldsName = useCallback(
      fieldRename => dispatch(updateSystemFieldsNameAction(fieldRename)),
      [updateSystemFieldsNameAction],
    );

    return (
      <Component
        {...props}
        systemFields={organization.system_fields_name}
        updateSystemFieldsName={updateSystemFieldsName}
        hideMetadataRoadmaps={!organization.enable_metadata_roadmaps}
      />
    );
  };
};

export default CustomersHOC;
