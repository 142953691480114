import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

import agGridOnPaste from 'design-system/utils/agGridOnPaste';

export default class AgGridTextArea extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.getValue = this.getValue.bind(this);
    this.addRef = this.addRef.bind(this);
    this.onPaste = agGridOnPaste.bind(this);

    this.state = {
      value: props.value,
    };
  }

  getValue() {
    return this.state.value;
  }

  onChange(event) {
    this.setState({ value: event.target.value });
  }

  addRef(input) {
    const { charPress, autoFocus } = this.props;

    if (input && autoFocus) {
      setTimeout(() => {
        if (input && typeof input.focus === 'function') {
          input.focus();
          input.select();
          if (charPress) {
            // changing dom directly is quicker that waiting for the state updating
            input.value = charPress;
            // setting the state is still required for saving one character strings
            this.setState({
              value: charPress,
            });
          }
        }
      }, 0);
    }
  }

  render() {
    const { value } = this.state;
    const { placeholder } = this.props;

    return (
      <TextArea
        multiline
        rows={2}
        inputRef={this.addRef}
        placeholder={placeholder}
        onChange={this.onChange}
        onPaste={this.onPaste}
        value={value}
      />
    );
  }
}

AgGridTextArea.propTypes = {
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
};

AgGridTextArea.defaultProps = {
  placeholder: '',
  autoFocus: false,
};

const TextArea = styled(TextField)`
  &&&& {
    width: 100%;
    padding: 0px;
    background: #f5f5f5;
    border: 1px solid #3f51b5 !important;
    min-height: 120px !important;
  }
`;
