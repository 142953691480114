import React from 'react';
import moment from 'moment-timezone';

import formatDate from 'utils/dates/formatDate';

const setGanttPlannedEndDateDisplay = plannedEndDate => {
  const plannedEndDateRef = React.useRef(plannedEndDate);

  plannedEndDateRef.current = plannedEndDate;

  return gantt => {
    gantt.addTaskLayer({
      renderer: {
        render: task => {
          let { deadline } = task;

          if (deadline && plannedEndDateRef.current) {
            const el = document.createElement('div');

            deadline = moment(deadline);

            el.className = 'plannedDeadline';
            const sizes = gantt.getTaskPosition(task, deadline);

            el.style.left = `${sizes.left}px`;
            el.style.top = `${sizes.top}px`;

            el.setAttribute('title', `Planned end date: ${formatDate(deadline)}`);
            return el;
          }
          return false;
        },
        // define getRectangle in order to hook layer with the smart rendering
        getRectangle: (task, view) => {
          const { deadline } = task;

          if (deadline && plannedEndDateRef.current) {
            return gantt.getTaskPosition(task, moment(deadline));
          }
          return null;
        },
      },
    });

    // ================
    // GANTT WINDOW LIMITS
    //   // if the planned end date is far to the right, the gantt should extend its window to the right
    //   gantt.attachEvent('onParse', () => {
    //     if (plannedEndDateRef.current) {
    //       gantt.eachTask(task => {
    //         // let { deadline } = task;

    //         // if (deadline) {
    //         //   deadline = moment(deadline);
    //         //   const state = gantt.getState();

    //         //   // if (state.min_date > deadline) {
    //         //   //   gantt.config.start_date = subtractWeeks(moment(deadline), 2);
    //         //   //   gantt.render();
    //         //   // }
    //         //   // if (state.max_date < deadline) {
    //         //   //   gantt.config.end_date = addWeeks(moment(deadline), 2);
    //         //   //   gantt.render();
    //         //   // }
    //         // }
    //       });
    //     }
    //   });
  };
};

export default setGanttPlannedEndDateDisplay;
