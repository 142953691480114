import { loadDataFromWidgetToEdit, parseConfigurableChartDataToSave, updateChartWidgetOnCurrentDashboard } from '../helpers';
import { getWidgetId } from '../../helpers/widgets';
import useChartWidgetWizard, { STACKED_CHART } from './useChartWidgetWizard';
import { useCallback, useEffect } from 'react';

const exists = Boolean;

const useEditChartWidgetWizard = ({ dashboardId, widgetIdToEdit }) => {
  const {
    title,
    dashboard,
    charts,
    selectedChart,
    currentScreen,
    chartOptions,
    selectedChartOptions,
    canSaveWidget,

    handleSelectChart,
    goToPreviousScreen,
    handleUpdateDashboardState,
    handleSelectChartOption,
    resetWizard,
    fetchChartOptions,
  } = useChartWidgetWizard({ dashboardId });

  const saveWidget = useCallback(() => {
    const isStackedChart = selectedChart?.type === STACKED_CHART;
    const configurableChart = parseConfigurableChartDataToSave({ selectedChart, selectedChartOptions, isStackedChart });

    const dashboardStateWithUpdateWidget = updateChartWidgetOnCurrentDashboard(dashboard, widgetIdToEdit, configurableChart);

    return handleUpdateDashboardState(dashboardStateWithUpdateWidget);
  }, [handleUpdateDashboardState, dashboard, selectedChartOptions, selectedChart, widgetIdToEdit]);

  useEffect(() => {
    const editMode = exists(widgetIdToEdit);

    if (!editMode) {
      return;
    }

    const widgetToEdit = dashboard.widgets.find(w => getWidgetId(w) === widgetIdToEdit);
    const loadedStateFromWidget = loadDataFromWidgetToEdit(widgetToEdit, chartOptions.groupBy, chartOptions.stackBy);

    resetWizard(loadedStateFromWidget);
  }, [dashboardId, widgetIdToEdit]);

  return {
    title,
    dashboard,
    charts,
    selectedChart,
    currentScreen,
    chartOptions,
    selectedChartOptions,
    canSaveWidget,
    
    handleSelectChart,
    goToPreviousScreen,
    handleUpdateDashboardState,
    handleSelectChartOption,
    resetWizard,
    saveWidget,
    fetchChartOptions,
  };
};

export default useEditChartWidgetWizard;
