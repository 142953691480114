import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import usePermissions from 'hooks/permissions/usePermissions';
import useMetricValueTable from './hooks/useMetricValueTable';
import useMetricValueTableGridColumns from './hooks/useMetricValueTableGridColumns';
import useMetricValueActions from './hooks/useMetricValueActions';

import { useCommonGridEventHandlers, useCommonGridProps } from 'design-system/molecules/AgGridReact-New/hooks';
import { getRowId } from 'design-system/molecules/AgGridReact-New/helpers';
import { materialBackground } from 'design-system/themes/default';
import { spacing } from 'design-system/theme';

import { BASE_ROW_HEIGHT } from 'constants/grid';
import ActionsToolbar from './components/ActionsToolbar';
import DeleteConfirmDialog from './components/DeleteConfirmDialog';

import { BaseGrid } from 'containers/Grids';
import { FOCUS_FIELD } from './helpers/constants';

const getDefaultEmptyRow = type => ({ value: null, date: new Date(), type });

const MetricValueTable = ({ metric }) => {
  const gridApi = useRef();

  const { canUpdate, canCreate } = usePermissions();
  const allowCreate = canCreate(PERMISSION_RESOURCES.metricValue);
  const allowUpdate = canUpdate(PERMISSION_RESOURCES.metricValue);
  const allowActions = allowCreate || allowUpdate;

  const {
    metricValues,
    handleAddMetricValue,
    addMetricValueWithoutSave,
    handleUpdateMetricValue,
    deleteMetricValue,
    removeUnsavedMetricValues,
    metricName,
    selectedTab,
    handleTabChange,
  } = useMetricValueTable();

  const { itemToDelete, setItemToDelete, deleteItem } = useMetricValueActions({
    deleteById: deleteMetricValue,
  });

  const { afterColumnDefs, columnDefs, defaultColDef } = useMetricValueTableGridColumns(allowActions, setItemToDelete);

  const commonGridProps = useCommonGridProps({});

  const commonGridEventHandlers = useCommonGridEventHandlers({
    items: metricValues,
    idKey: 'id',
    focusField: FOCUS_FIELD,
    actions: {
      update: handleUpdateMetricValue,
      save: handleAddMetricValue,
      remove: removeUnsavedMetricValues,
    },
  });

  const onGridReady = params => {
    gridApi.current = params.api;
  };

  const handleAddUnsavedMetricValue = () => addMetricValueWithoutSave(getDefaultEmptyRow(selectedTab.toLowerCase()), metric.id);

  const metricColumnDefs = useMemo(() => [...columnDefs, ...afterColumnDefs], [columnDefs, afterColumnDefs]);

  return (
    <Wrapper $backgroundColor={materialBackground.whiteSmoke} $marginTop={!allowUpdate}>
      <ActionsToolbar
        metric={metric}
        addMetricValueWithoutSave={handleAddUnsavedMetricValue}
        handleTabChange={handleTabChange}
        selectedTab={selectedTab}
        allowCreate={allowCreate}
        allowUpdate={allowUpdate}
      />
      <BaseGrid
        {...commonGridProps}
        {...commonGridEventHandlers}
        getRowId={getRowId}
        rowData={metricValues}
        onGridReady={onGridReady}
        columnDefs={metricColumnDefs}
        defaultColDef={defaultColDef}
        headerHeight={BASE_ROW_HEIGHT}
        enableSorting
      />
      <DeleteConfirmDialog
        metricName={metricName}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        deleteItem={deleteItem}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  && {
    width: 100%;

    ${({ $marginTop }) => $marginTop && `margin-top: ${spacing(2.5)}px;`}
    ${({ $backgroundColor }) => `background-color: ${$backgroundColor};`}
        .ag-root-wrapper-body {
      ${({ $backgroundColor }) => `background-color: ${$backgroundColor};`}
    }

    .ag-root {
      background-color: ${materialBackground.white};
      margin: 0;
    }
  }
`;

export default MetricValueTable;
