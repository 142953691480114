import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';

import useCustomRequestStatusMappingConfiguration from 'hooks/configuration/useCustomRequestStatusMappingConfiguration';

import customerRequestStatus from 'store/customerRequests/constants/status';
import { getPhases } from 'store/phases/selectors';

export default Component => {
  return props => {
    const { enableAutomaticUpdate, isLoading, mapping, updateConfiguration } = useCustomRequestStatusMappingConfiguration();
    const phases = useSelector(state => getPhases(state, true));

    const handleSendEmailChange = key => {
      updateConfiguration({
        ...mapping,
        [key]: {
          ...mapping[key],
          sendEmail: !mapping[key].sendEmail,
        },
      });
    };

    const handleRemoveMapping = key => {
      const newMapping = { ...mapping };

      delete newMapping[+key];
      updateConfiguration(newMapping);
    };

    const handleAddNewMapping = useCallback(
      data => {
        const target = find(customerRequestStatus, el => el.label === data.customerRequestStatusLabel)?.value;
        const projectPhaseId = phases.find(el => el.title === data.projectPhaseLabel)?.id;

        if (target && projectPhaseId) {
          const newMapping = {
            ...mapping,
            [projectPhaseId]: {
              target,
              sendEmail: !!data.sendEmail,
            },
          };

          updateConfiguration(newMapping);
        }
      },
      [mapping, phases],
    );

    return (
      <Component
        enableAutomaticUpdate={enableAutomaticUpdate}
        isLoading={isLoading}
        mapping={mapping}
        handleSendEmailChange={handleSendEmailChange}
        handleRemoveMapping={handleRemoveMapping}
        handleAddNewMapping={handleAddNewMapping}
        phases={phases}
      />
    );
  };
};
