import React, { useState } from 'react';
import styled from 'styled-components';

import AddIcon from '@material-ui/icons/Add';
import LinkIcon from '@material-ui/icons/Link';
import IconAndTextButton from 'design-system/molecules/IconAndTextButton/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import TextFieldWithButton from 'design-system/organisms/TextFieldWithButton/index';


const getErrorMessage = error => error?.response?.data;

export default props => {
  const [epicId, setEpicId] = useState('');

  const { createEpic, linkEpic, linkError, createError, linking, creating } = props;

  return (
    <ClubhouseWrapper>
      <ClubhouseTitle>Shortcut</ClubhouseTitle>
      <ClubhouseFieldsWrapper>
        <ClubhouseLinkFormWrapper>
          <TextFieldWithButton
            button={() => (
              <IconAndTextButton
                id="link-clubhouse"
                text="Link Epic"
                disabled={!epicId || linking || creating}
                icon={LinkIcon}
                onClick={() => linkEpic(epicId)}
              />
            )}
            value={epicId}
            onChange={setEpicId}
            errorText={linkError}
            error={linkError}
            label="Epic ID"
            name="epicId"
            placeholder="Epic ID"
          />
          {linkError && <ErrorMessage>{getErrorMessage(linkError)}</ErrorMessage>}
        </ClubhouseLinkFormWrapper>
        <ClubhouseCreateFormWrapper>
          <CreateButton
            id="create-clubhouse"
            text="Create Epic"
            icon={AddIcon}
            disabled={creating || linking}
            onClick={() => createEpic()}
          />
          {getErrorMessage && <ErrorMessage>{getErrorMessage(createError)}</ErrorMessage>}
        </ClubhouseCreateFormWrapper>
      </ClubhouseFieldsWrapper>
    </ClubhouseWrapper>
  );
};

const ClubhouseWrapper = styled.div`
  padding: 15px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  // height: 150px;
  flex: 1;
`;

const ClubhouseTitle = styled(TextDeprecated)`
  &&&& {
    font-size: 16px !important;
  }
`;

const ClubhouseFieldsWrapper = styled.div`
  &&&& {
    // width: 300px;
  }
`;

const ClubhouseCreateFormWrapper = styled.div`
  margin-top: 5px;
  margin-left: 177px;
`;

const ClubhouseLinkFormWrapper = styled.div`
  &&&& {
    width: 50%;
  }
`;

const CreateButton = styled(IconAndTextButton)`
  &&&& {
    margin-left: -15px;
  }
`;

const ErrorMessage = styled.p`
  &&&& {
    color: red;
    font-size: 12px;
    margin-top: 12px;
  }
`;
