import React from 'react';

import DialogComponent from './DialogComponent';
import Component from './Component';

export default props => {
  const SelectedComponent = props.oldLightboxActive ? Component : DialogComponent;

  return <SelectedComponent {...props} />;
};
