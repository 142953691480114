/**
 * @function getHasSort
 *
 * Get has sort active on given column state
 *
 * @param  {Array} columnState
 * @return {Boolean}
 */
const getHasSort = columnState => {
  const localHasSort = !!columnState.filter(colState => {
    return colState.sort != null;
  }).length;

  return localHasSort;
};

export default getHasSort;
