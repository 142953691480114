import React from 'react';
import styled from 'styled-components';

import DropdownFilter from 'components/DropdownFilter';

export default ({ name, title, options, quickFilters, applyQuickFilter, disabled }) => {
  const quickFilterOptions = [
    { key: 'null', title: 'Undefined' },
    ...(options || []).map(r => ({ title: r.title || r.name, key: String(r.id) })),
  ];

  const quiclkFilterValue =
    quickFilters && quickFilters[name] ? quickFilterOptions.find(o => o.key === quickFilters[name][0]) : null;
  const _handleOnChange = value => {
    const val = value.key || value.key === null ? [String(value.key)] : [];

    applyQuickFilter({ [name]: val });
  };

  return (
    <Dropdown
      name={`quick-filter-select-${name}`}
      options={quickFilterOptions}
      selectedValue={quiclkFilterValue}
      onChange={_handleOnChange}
      allTitle={title}
      disabled={disabled}
      inline
    />
  );
};

const Dropdown = styled(DropdownFilter)`
  &&&& {
    margin-top: 8px !important;

    > div {
      min-width: 130px !important;
      max-width: 100%;
    }
  }
`;
