const parseArrayIntoObject = (row, columns, startColumn) => {
  if (!row || !(row instanceof Array) || !columns || !(columns instanceof Array)) {
    return;
  }

  let startIndex = 0;

  if (startColumn) {
    const columnIndex = columns.map(column => (column instanceof Object ? column.id : column)).indexOf(startColumn);

    if (columnIndex >= 0) {
      startIndex = columnIndex;
    }
  }

  return row.reduce((final, value, index) => {
    const columnIndex = index + startIndex;
    const column = columns[columnIndex];

    if (typeof column === 'string') {
      final[column] = value;
    } else if (column instanceof Object && column.id && column.editable !== false) {
      if (column.id.includes('.')) {
        const complexKey = column.id.split('.');

        final[complexKey[0]] = {
          [complexKey[1]]: value,
        };
      } else {
        final[column.id] = value;
      }
    }
    return final;
  }, {});
};

export default parseArrayIntoObject;
