import React, { useState, useRef, useMemo, useImperativeHandle, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { defaultTo, isNil, not, pipe, prop } from 'ramda';
import NumberFormat from 'react-number-format';

import RootRef from '@material-ui/core/RootRef';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TextField from '@material-ui/core/TextField';

import theme from 'design-system/theme';
import cellEditorHoc from 'design-system/molecules/AgGridReact-New/helpers/cellEditorHoc';

import { focusAndSelectText } from 'containers/Grids/utils/handleFieldFocus';
import isNaN from 'lodash/isNaN';

const DATA = 'data';

const defaultEmptyString = defaultTo('');
const defaultEmptyObject = defaultTo({});

const getData = prop(DATA);

const isNotNil = pipe(isNil, not);

const defaultTo2Decimals = defaultTo(2);

const EstimateInputWithLinkEditor = cellEditorHoc(
  ({
    api,
    forwardedRef,
    rowIndex,
    onClickFactory,
    stopEditing,
    isValid,
    column,
    node,
    handleInputFocus = focusAndSelectText,
    isEstimatingByPoints = false,
  }) => {
    const workingValueKey = isEstimatingByPoints ? 'estimatePoints' : 'value';
    const { colDef } = column;
    const { data } = node;
    const inputRef = useRef();

    const originalValue = data[colDef.field];

    const [internalValue, setInternalValue] = useState({ ...originalValue, value: originalValue?.[workingValueKey] });

    const setValue = useCallback(
      value => {
        if (isValid && !isValid(value)) {
          return;
        }

        setInternalValue(currInternalValue => ({
          ...defaultEmptyObject(currInternalValue),
          value,
          [workingValueKey]: value,
        }));
      },
      [setInternalValue, isValid],
    );

    const domRef = useRef(null);

    useImperativeHandle(forwardedRef, () => ({
      getValue() {
        return internalValue ?? originalValue;
      },
    }));

    const handleChange = event => {
      setValue(!event.value || isNaN(event.value) ? '' : event.value);
    };

    const onClick = useMemo(() => {
      if (api && originalValue?.showLink) {
        const displayedRow = api.getDisplayedRowAtIndex(rowIndex);

        if (isNotNil(displayedRow)) {
          return onClickFactory(getData(displayedRow), internalValue);
        }
      }

      return null;
    }, [api, rowIndex, originalValue, internalValue]);

    const link = useMemo(
      () =>
        onClick && (
          <RightPositionedIconButton onClick={onClick}>
            <OpenInNewIcon style={{ fontSize: theme.typography.fontSize }} />
          </RightPositionedIconButton>
        ),
      [onClick],
    );

    useEffect(() => {
      if (originalValue?.showLinkOnMount) {
        // open the link
        onClick();

        /*
         * cancel editing after
         *
         * its using the setTimeout to not clean the value on cell when the lightbox is open
         * without the this will put the cell enpty, maybe we can find a better solution
         * for this in the future
         */
        setTimeout(() => stopEditing(), 0);
      }
    }, [originalValue, onClick, stopEditing]);

    useEffect(() => {
      handleInputFocus(inputRef.current);
    }, []);

    return (
      <RootRef rootRef={domRef}>
        <NumberFormat
          type="text"
          className="ag-cell-edit-input"
          thousandSeparator
          decimalScale={defaultTo2Decimals(column.colDef.decimalScale)}
          customInput={TextField}
          inputRef={el => {
            inputRef.current = el;
          }}
          onValueChange={handleChange}
          value={defaultEmptyString(internalValue?.[workingValueKey])}
          allowNegative={false}
        />
        {link}
      </RootRef>
    );
  },
);

export default EstimateInputWithLinkEditor;

const RightPositionedIconButton = styled(props => <IconButton {...props}>{props.children}</IconButton>)`
  &&&& {
    position: absolute;
    top: 5px;
    right: 0;
    visibility: hidden;
    padding: 3px;
    font-size: 1.125rem;
  }
`;
