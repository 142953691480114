import omit from 'lodash/omit';

/**
 * @typedef {Object} EntitiesEntryState
 * @property {Object} byId Contains entities indexed by their ID.
 * @property {Array} allIds Array of all Entity IDs on this entry.
 */

/**
 * Creates an empty, initial state for an Entity entry.
 * @returns {EntitiesEntryState}
 */
const makeInitialState = () => ({
  byId: {},
  allIds: [],
});

/**
 * Adds the `dataToMerge` to the `currentState`, updating existing entries
 * if the ID is the same.
 * @param currentState {EntitiesEntryState}
 * @param dataToUpdate {Object}
 * @returns {EntitiesEntryState}
 */
const add = (currentState, dataToUpdate) => {
  const idsToUpdate = Object.keys(dataToUpdate || {});

  const mergedDataToUpdate = idsToUpdate.reduce((acc, currentId) => {
    return {
      ...acc,
      [currentId]: {
        ...currentState.byId[currentId],
        ...dataToUpdate[currentId],
      },
    };
  }, {});

  const updatedById = {
    ...currentState.byId,
    ...mergedDataToUpdate,
  };

  return {
    byId: updatedById,
    allIds: Object.keys(updatedById),
  };
};

/**
 * Removes the specified IDs from the state.
 * @param currentState {EntitiesEntryState}
 * @param idsToRemove {Array}
 * @returns {EntitiesEntryState}
 */
const remove = (currentState, idsToRemove) => {
  const updatedById = omit(currentState.byId, idsToRemove);

  return {
    byId: updatedById,
    allIds: Object.keys(updatedById),
  };
};

const makeDependencyEntityId = (sourceId, targetID) => `${sourceId}-${targetID}`;

export { makeInitialState, add, remove, makeDependencyEntityId };
