import getCurrentPath from 'utils/getCurrentPath';
import history from 'store/utils/history';

const removeScenarioIdFromUrl = () => {
  const path = getCurrentPath();
  const params = new URLSearchParams(window.location.search);

  params.delete('scenario');

  history.push(`/${path}?${params.toString()}`);
};

export default removeScenarioIdFromUrl;
