import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import 'remirror/styles/all.css';
import './editor.styles.css';

import {
  EditorComponent,
  OnChangeHTML,
  OnChangeJSON,
  Remirror,
  useActive,
  useEditorEvent,
  useHelpers,
  useRemirror,
} from '@remirror/react';
import { TableComponents } from '@remirror/extension-react-tables';

import EditorToolbar from './EditorToolbar';
import TableContextMenu from './TableContextMenu';
import LinkToolbar from './LinkToolbar';
import EditorExtensions from './extensions';
import styled from 'styled-components';
import { fillInEmptyTableRows } from './helpers';
import { isNil } from 'ramda';
import showdown from 'showdown';

const parseContentAsJson = stringContent => {
  try {
    return JSON.parse(stringContent);
  } catch (e) {
    return null;
  }
};

export default ({
  content,
  onBlur,
  onChange,
  disableEdit,
  baseURLForAttachments,
  placeholder,
  outputJSON = false,
  disableToolbar = false,
  defaultEditEnabled = false,
  smallInput = false,
  noPadding = false,
  callOnChangeWhenTyping = false,
  maxHeight = null,
  minHeight = null,
  className,
  imageUploadHandler,
  onEditorStateChange,
  onInit,
}) => {
  const editorWrapperElement = useRef(null);
  const [updatingInitialContent, setUpdatingInitialContent] = useState(true);
  const [editorEnabled, setEditorEnabled] = useState(true);

  const isEditorEditable = useMemo(() => {
    if (disableEdit) {
      return false;
    }

    return editorEnabled;
  }, [disableEdit, editorEnabled]);

  const handleEditorEnabledChange = enabled => {
    setEditorEnabled(enabled);

    if (onEditorStateChange) onEditorStateChange(enabled, manager);
  };

  const handleEditorClick = useCallback(
    event => {
      if (event.target.nodeName !== 'A') {
        handleEditorEnabledChange(true);
      }

      return false;
    },
    [setEditorEnabled],
  );

  const editorInitialContent = useMemo(() => {
    const jsonEditorContent = parseContentAsJson(content);

    if (jsonEditorContent) {
      return typeof jsonEditorContent === 'object' ? jsonEditorContent : String(jsonEditorContent);
    }

    if (isNil(content)) return '';

    const converter = new showdown.Converter();

    const convertedContent = converter.makeHtml(content);

    const cleanedHtmlContent = fillInEmptyTableRows(convertedContent);

    return isNil(cleanedHtmlContent) ? '' : String(cleanedHtmlContent);
  }, [content]);

  const onError = useCallback(({ json, invalidContent, transformers }) => {
    // Automatically remove all invalid nodes and marks.
    return transformers.remove(json, invalidContent);
  }, []);

  useEffect(() => {
    const editorButtons = editorWrapperElement.current.querySelectorAll('button');

    // TODO find a way to set this attribute in a react way, because this button without type=button will submit the form if
    // any form exists in the parent tree, making the page reload
    editorButtons.forEach(button => {
      button.setAttribute('type', 'button');
    });
  }, [isEditorEditable]);

  const extensions = useMemo(() => {
    const extensions = EditorExtensions({
      imageUploadHandler,
      onClick: handleEditorClick,
      extraAttributes: {
        baseURLForAttachments: { default: baseURLForAttachments || '' },
      },
    });

    return extensions;
  }, [baseURLForAttachments, handleEditorClick]);

  const { getContext, manager, state, setState } = useRemirror({
    extensions,
    // Set the initial content.
    content: editorInitialContent,

    // Place the cursor at the start of the document. This can also be set to
    // `end`, `all` or a numbered position.
    selection: 'start',

    // Set the string handler which means the content provided will be
    // automatically handled as html.
    // `markdown` is also available when the `MarkdownExtension`
    // is added to the editor.
    stringHandler: 'html',
    onError,
  });

  useEffect(() => {
    // When editorInitialContent changes setContent using the existing context
    if (updatingInitialContent && getContext) {
      getContext().setContent(editorInitialContent || '');
    }
  }, [updatingInitialContent, editorInitialContent, getContext]);

  useEffect(() => {
    if (onInit) {
      onInit(manager);
    }
  }, []);

  const hooks = [
    () => {
      const { getJSON, getHTML } = useHelpers();

      const active = useActive();

      const callOnChangeListeners = useCallback(() => {
        if (outputJSON) {
          onChange(JSON.stringify(getJSON(state)));
        } else {
          onChange(getHTML(state));
        }
      }, [state, onChange, outputJSON]);

      const callBlurListeners = useCallback(() => {
        callOnChangeListeners();

        if (onBlur) {
          onBlur();
        }
      }, [onBlur, callOnChangeListeners]);

      useEditorEvent('keydown', e => {
        const isListNodeActive = [active.listItem(), active.bulletList(), active.orderedList()].some(v => v);

        if (e.key === 'Tab') {
          e.preventDefault();

          if (!isListNodeActive) {
            callOnChangeListeners();
          }

          return false;
        }
      });

      useEditorEvent('blur', e => {
        e.preventDefault();

        const { relatedTarget } = e;

        if (!relatedTarget) {
          callBlurListeners();

          return false;
        }

        const blurSetsEditorInReadMode = [
          relatedTarget.className.includes('remirror-editor-wrapper'),
          relatedTarget.className.includes('remirror-editor'),
          relatedTarget.closest('.remirror-editor'),
          relatedTarget.closest('.link-toolbar'),
        ].every(check => !check);

        if (blurSetsEditorInReadMode) {
          callBlurListeners();

          handleEditorEnabledChange(false);
        }
      });
    },
  ];

  const showEditorToolbar = isEditorEditable && !disableToolbar;

  const realTimeOnChangeListeners = () => {
    if (!callOnChangeWhenTyping) {
      return null;
    }

    if (outputJSON) {
      return <OnChangeJSON onChange={jsonValue => onChange(JSON.stringify(jsonValue))} />;
    }

    return <OnChangeHTML onChange={onChange} />;
  };

  useEffect(() => {
    setEditorEnabled(defaultEditEnabled);
  }, [defaultEditEnabled]);

  return (
    <Container
      aria-hidden="true"
      onClick={handleEditorClick}
      className={`${className} ${isEditorEditable ? 'remirror-theme' : 'remirror-theme disabled'}`}
      ref={el => {
        editorWrapperElement.current = el;
      }}
      noPadding={noPadding}
      smallInput={smallInput}
      maxHeight={maxHeight}
      minHeight={minHeight}
    >
      {/* the className is used to define css variables necessary for the editor */}
      <Remirror
        editable={isEditorEditable}
        hooks={hooks}
        manager={manager}
        initialContent={state}
        placeholder={placeholder}
        onChange={params => {
          if (!params.internalUpdate && updatingInitialContent) {
            setUpdatingInitialContent(false);
          }

          setState(params.state);
        }}
      >
        {showEditorToolbar && <EditorToolbar />}
        <EditorComponent />
        <TableComponents
          enableTableCellMenu={isEditorEditable}
          enableTableDeleteButton={isEditorEditable}
          enableTableDeleteRowColumnButton={isEditorEditable}
          tableCellMenuProps={{ Component: TableContextMenu }}
        />
        {isEditorEditable && <LinkToolbar />}
        {realTimeOnChangeListeners()}
      </Remirror>
    </Container>
  );
};

const Container = styled.div`
  div.ProseMirror {
    font-family: ${props => props.theme.typography.fontFamily};
    min-height: ${({ smallInput }) => (smallInput ? '32px' : '128px')};

    ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight};`}

    ${({ noPadding }) => noPadding && 'padding: 0;'}
  }

  && {
    .remirror-is-empty::before {
      color: ${({ theme }) => theme.palette.text.disabled};
      font-style: normal;
    }
  }
`;
