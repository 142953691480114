// External dependencies
import React, { Fragment } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import styled from 'styled-components';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Dialog from '@material-ui/core/Dialog';

// Dragonboat dependencies
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import Checkbox from 'design-system/atoms/Checkbox/index';
import MultiSelect from 'design-system/atoms/MultiSelect/index';
import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';

import { ROUNDING_OPTIONS } from 'constants/timeline';
import { FORECAST_PAGE } from 'constants/filters';
import { ucFirst } from 'utils';
import DateRange from 'components/DateRange';
import getDisplayLayerLabel from 'store/projects/helpers/getDisplayLayerLabel';

export default ({
  lsState,
  displayColumnOptions,
  onChangeDisplayColumns,
  showColorLegend,
  setShowDialog,
  showDialog,
  onChangeSetting,
  colorByOptions,
  updateState,
  onApply,
  triggerOpenEvent,
  displayLayer,
  getSystemFieldName,
  pageId,
  showResourceDisplayOpt,
  showsPredictedEndDateOption,
}) => {
  const {
    selectedTimeWindow,
    selectedDisplayColumns,
    checkedColorLegend,
    selectedColorBy,
    showBusinessValue,
    showHCTable,
    selectedRounding,
    selectedHighlight,
    editConfirmedTasks,
    useTaskOnConfirmedIdeas,
    showResourceUsers,
    resourceUsersGroup,
  } = lsState;

  const overlayOnGanttOptions = [
    { key: 'showGroupedTimeline', name: 'Group timeline' },
    { key: 'coloredGroups', name: 'Group color' },
    { key: 'showTooltip', name: 'Tooltip' },
    { key: 'plannedStartDate', name: 'Target start date' },
    { key: 'plannedEndDate', name: 'Target end date' },
    ...(showsPredictedEndDateOption ? [{ key: 'displayStoryProgEndDate', name: 'Predicted end date' }] : []),
    { key: 'showTimeframes', name: 'Timeframe' },
    { key: 'displayAlerts', name: 'Alerts' },
    { key: 'showEstimates', name: 'Estimates' },
    { key: 'showTasks', name: 'Assignments' },
    { key: 'showOwnerAllocation', name: 'Owner Allocation' },
  ];
  const activeOverlayOptions = overlayOnGanttOptions.filter(o => !!lsState[o.key]);

  return (
    <Fragment>
      {!triggerOpenEvent && (
        <ButtonIcon data-cy="display-preferences-button" title="Display preferences" onClick={() => setShowDialog(true)}>
          <SettingsIcon />
        </ButtonIcon>
      )}
      <Dialog open={showDialog} maxWidth="md" fullWidth>
        <DialogTitle>Display Preferences</DialogTitle>
        <DialogContent>
          <PreferenceGroup>
            <FormControl fullWidth margin="dense">
              <MultiSelect
                options={displayColumnOptions}
                optionsMapper={{ label: 'name', value: 'id' }}
                label="Display Columns"
                placeholder="Select multiple grid columns"
                onChange={onChangeDisplayColumns}
                value={selectedDisplayColumns}
                hideCreateOption
                draggable
              />
            </FormControl>
          </PreferenceGroup>
          <PreferenceGroup>
            <FormControl fullWidth margin="dense">
              <MultiSelect
                options={overlayOnGanttOptions.filter(o => !activeOverlayOptions.includes(o.name))}
                optionsMapper={{ label: 'name', value: 'key' }}
                label="Overlay on Gantt chart"
                placeholder="Select multiple options"
                onChange={selectedOptions => {
                  const configs = {};

                  overlayOnGanttOptions.forEach(o => {
                    configs[o.key] = selectedOptions.includes(o.key);
                  });
                  updateState(configs);
                }}
                value={activeOverlayOptions}
                hideCreateOption
              />
            </FormControl>
          </PreferenceGroup>
          <PreferenceGroup>
            <FormLabel control="legend">Color by</FormLabel>
            <FormControl fullWidth margin="dense">
              <Select
                value={selectedColorBy?.key}
                label="Color by"
                onChange={e => {
                  const newColorBy = colorByOptions.find(o => o.key === e.target.value);
                  const stateToUpdate = { selectedColorBy: newColorBy };

                  if (newColorBy.key === 'idea') stateToUpdate.checkedColorLegend = false;

                  updateState(stateToUpdate);
                }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                }}
              >
                {colorByOptions &&
                  colorByOptions.map(colorBy => (
                    <StyledMenuItemDropdown key={colorBy.key} value={colorBy.key}>
                      {ucFirst(colorBy.title)}
                    </StyledMenuItemDropdown>
                  ))}
              </Select>
            </FormControl>
          </PreferenceGroup>
          <PreferenceGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checkedColorLegend}
                  onChange={e => onChangeSetting('checkedColorLegend', e.target.checked)}
                  disabled={selectedColorBy?.key === 'idea'}
                />
              }
              label="Show color legend"
            />
            <ColorLegendContainer>{checkedColorLegend && showColorLegend(selectedColorBy)}</ColorLegendContainer>
          </PreferenceGroup>
          <PreferenceGroup>
            <FormLabel control="legend">Time window</FormLabel>
            <Preference row>
              <FormControlLabel
                control={
                  <Radio
                    checked={selectedTimeWindow.key === 'auto'}
                    color="primary"
                    onChange={() =>
                      onChangeSetting('selectedTimeWindow', {
                        key: 'auto',
                        from: selectedTimeWindow.from,
                        to: selectedTimeWindow.to,
                      })
                    }
                  />
                }
                label="Automatic"
                key="auto"
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={selectedTimeWindow.key === 'manual'}
                    color="primary"
                    onChange={() =>
                      onChangeSetting('selectedTimeWindow', {
                        key: 'manual',
                        from: selectedTimeWindow.from,
                        to: selectedTimeWindow.to,
                      })
                    }
                  />
                }
                label={
                  <DateRange
                    startDate={selectedTimeWindow.from}
                    endDate={selectedTimeWindow.to}
                    onStartDateChange={fromTime =>
                      onChangeSetting('selectedTimeWindow', {
                        key: 'manual',
                        from: moment(fromTime),
                        to: moment(selectedTimeWindow.to),
                      })
                    }
                    onEndDateChange={toTime =>
                      onChangeSetting('selectedTimeWindow', {
                        key: 'manual',
                        from: moment(selectedTimeWindow.from),
                        to: moment(toTime),
                      })
                    }
                    showDuration={false}
                    showEndDate
                    disableDuration
                    disableEndDate={false}
                    startLabel="From"
                    endLabel="To"
                  />
                }
                key="manual"
              />
            </Preference>
          </PreferenceGroup>
          {showResourceDisplayOpt && (
            <PreferenceGroup>
              <FormLabel control="legend">Resource Display Options</FormLabel>
              <Preference row>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={showHCTable}
                      onChange={e => onChangeSetting('showHCTable', e.target.checked)}
                    />
                  }
                  label="Display Resources Table"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={showBusinessValue}
                      onChange={e => onChangeSetting('showBusinessValue', e.target.checked)}
                    />
                  }
                  label="Display business benefits"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedHighlight && selectedHighlight.key === 'overUnder'}
                      color="primary"
                      onChange={e => onChangeSetting('selectedHighlight', e.target.checked ? { key: 'overUnder' } : { key: '' })}
                    />
                  }
                  label="Highlight under utilization"
                />
              </Preference>
              {pageId === FORECAST_PAGE && (
                <Preference row>
                  <FormControlLabel
                    style={{ width: 407 }}
                    control={
                      <Checkbox
                        checked={useTaskOnConfirmedIdeas}
                        color="primary"
                        onChange={e => onChangeSetting('useTaskOnConfirmedIdeas', !useTaskOnConfirmedIdeas)}
                      />
                    }
                    label={`Use Assignments for confirmed ${getDisplayLayerLabel(displayLayer, getSystemFieldName)}s`}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!editConfirmedTasks}
                        color="primary"
                        onChange={e => onChangeSetting('editConfirmedTasks', !e.target.checked)}
                      />
                    }
                    label={`Lock Confirmed ${getDisplayLayerLabel(displayLayer, getSystemFieldName)}s`}
                  />
                </Preference>
              )}
              {showHCTable && (
                <Fragment>
                  <Preference row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={showResourceUsers}
                          onChange={e => onChangeSetting('showResourceUsers', e.target.checked)}
                        />
                      }
                      label="Display Resource users"
                    />
                  </Preference>
                  {showResourceUsers && (
                    <Preference row>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={resourceUsersGroup === 'teamAndSkill'}
                            color="primary"
                            onChange={() => onChangeSetting('resourceUsersGroup', 'teamAndSkill')}
                          />
                        }
                        label="Group by Team & Skill"
                        key="teamAndSkill"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            checked={resourceUsersGroup === 'skillAndTeam'}
                            color="primary"
                            onChange={() => onChangeSetting('resourceUsersGroup', 'skillAndTeam')}
                          />
                        }
                        label="Group by Skill & Team"
                        key="auskillAndTeamto"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            checked={!resourceUsersGroup}
                            color="primary"
                            onChange={() => onChangeSetting('resourceUsersGroup', null)}
                          />
                        }
                        label="No Group"
                        key="no-group"
                      />
                    </Preference>
                  )}
                </Fragment>
              )}
            </PreferenceGroup>
          )}
          <PreferenceGroup>
            <FormLabel control="legend">Decimal points</FormLabel>
            <Preference row>
              {ROUNDING_OPTIONS.map(rounding => (
                <FormControlLabel
                  control={
                    <Radio
                      checked={selectedRounding && selectedRounding.key === rounding.key}
                      color="primary"
                      onChange={() => onChangeSetting('selectedRounding', rounding)}
                    />
                  }
                  label={ucFirst(rounding.title)}
                  key={rounding.key}
                />
              ))}
            </Preference>
          </PreferenceGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onApply} color="primary">
            Apply
          </Button>
          <Button onClick={() => setShowDialog(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const PreferenceGroup = styled.div`
  margin: 12px 0;
`;

const Preference = styled(FormGroup)`
  &&&& {
    margin: 0 6px;
  }
`;

const ColorLegendContainer = styled.div`
  width: 100%;
  display: block;
`;
