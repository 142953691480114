import React from 'react';

import Description from 'routes/Settings/Integration/components/Description';

const RequiredFieldsLabel = ({ integrationName, hasCliendId, authClientIdLabel, authClientSecretLabel }) => {
  const fields = [];

  if (hasCliendId) {
    fields.push(<em>{authClientIdLabel}</em>);
    fields.push(<> and </>);
  }
  fields.push(<em>{authClientSecretLabel}</em>);

  return (
    <Description>
      You need {fields} to connect dragonboat with {integrationName}.
    </Description>
  );
};

export default RequiredFieldsLabel;
