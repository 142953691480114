import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_PROJECT_DELIVERABLE_HISTORY'),
  ...getThunkActionTypes('FETCH_PROJECT_DELIVERABLE_HISTORY_NEXT'),
  ...getThunkActionTypes('FETCH_PROJECT_DELIVERABLE_NEXT_COMMENTS'),
  ...getThunkActionTypes('CREATE_PROJECT_DELIVERABLE_COMMENT'),
  ...getThunkActionTypes('DELETE_PROJECT_DELIVERABLE_COMMENT'),
  ...getThunkActionTypes('UPDATE_PROJECT_DELIVERABLE_COMMENT'),
};

export const {
  FETCH_PROJECT_DELIVERABLE_NEXT_COMMENTS,
  FETCH_PROJECT_DELIVERABLE_NEXT_COMMENTS_FULFILLED,
  CREATE_PROJECT_DELIVERABLE_COMMENT,
  CREATE_PROJECT_DELIVERABLE_COMMENT_FULFILLED,
  DELETE_PROJECT_DELIVERABLE_COMMENT,
  DELETE_PROJECT_DELIVERABLE_COMMENT_FULFILLED,
  UPDATE_PROJECT_DELIVERABLE_COMMENT,
  UPDATE_PROJECT_DELIVERABLE_COMMENT_FULFILLED,
  FETCH_PROJECT_DELIVERABLE_HISTORY,
  FETCH_PROJECT_DELIVERABLE_HISTORY_PENDING,
  FETCH_PROJECT_DELIVERABLE_HISTORY_FULFILLED,
  FETCH_PROJECT_DELIVERABLE_HISTORY_NEXT,
  FETCH_PROJECT_DELIVERABLE_HISTORY_NEXT_FULFILLED,
} = actionTypes;
