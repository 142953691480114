import React from 'react';

import ConfirmDialog from 'components/ConfirmDialog';

import { PLANNING_STAGE_DIALOG_MESSAGE } from 'constants/common';

import useSystemFields from 'hooks/useSystemFields';

const PlanningStageWarningDialog = ({ isOpen, onConfirm }) => {
  const [getSystemFieldName] = useSystemFields();

  const phaseTitle = getSystemFieldName('phase', false);

  return (
    <ConfirmDialog isOpen={isOpen} title={PLANNING_STAGE_DIALOG_MESSAGE(phaseTitle)} onConfirm={onConfirm} showCancel={false} />
  );
};

export default PlanningStageWarningDialog;