import { isNil, not, pipe, values } from 'ramda';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { materialColorsAlt } from 'design-system/themes/default';
import invertedTextColor from 'design-system/utils/invertedTextColor';

import { formatDate } from 'routes/Dashboard/Snapshot/helpers';
import roundDecimalPlaces from 'utils/roundDecimalPlaces';

import { fetchMetricAllocationChartData } from '../store/thunks';
import { selectIsLoadingMetricChart, selectMetricChartData } from '../store/selectors';

const PLANNED_DATA_SET_KEY = 'planned_data_set_key';
const COMPLETED_DATA_SET_KEY = 'completed_data_set_key';
const REPORTED_DATA_SET_KEY = 'reported_data_set_key';
const DEFAULT_CHART_DATA = {
  labels: [],
  datasets: [],
};

const isNotNil = pipe(isNil, not);

const mapMetricAllocationDataToChartData = (metricAllocationData, undefinedDate) => {
  if (isNil(metricAllocationData)) {
    return DEFAULT_CHART_DATA;
  }

  const keyedDatasets = {
    [PLANNED_DATA_SET_KEY]: {
      id: PLANNED_DATA_SET_KEY,
      label: 'Planned',
      data: [],
      backgroundColor: materialColorsAlt.lightBlue,
      hoverBackgroundColor: materialColorsAlt.lightBlue,
      datalabels: {
        labels: {
          value: {
            color: invertedTextColor(materialColorsAlt.lightBlue, true),
          },
        },
      },
      order: 1,
      yAxisID: 'yStacked',
      stack: '1',
      type: 'bar',
      isAllocationDataset: true,
    },
    [COMPLETED_DATA_SET_KEY]: {
      id: COMPLETED_DATA_SET_KEY,
      label: 'Completed',
      data: [],
      backgroundColor: materialColorsAlt.lightGray,
      hoverBackgroundColor: materialColorsAlt.lightGray,
      datalabels: {
        labels: {
          value: {
            color: invertedTextColor(materialColorsAlt.lightGray, true),
          },
        },
      },
      order: 1,
      yAxisID: 'yStacked',
      stack: '2',
      type: 'bar',
      isAllocationDataset: true,
    },
    [REPORTED_DATA_SET_KEY]: {
      id: REPORTED_DATA_SET_KEY,
      label: 'Reported',
      data: [],
      backgroundColor: materialColorsAlt.lightPurple,
      hoverBackgroundColor: materialColorsAlt.lightPurple,
      datalabels: {
        labels: {
          value: {
            color: invertedTextColor(materialColorsAlt.lightGray, true),
          },
        },
      },
      order: 2,
      yAxisID: 'yStacked',
      stack: '2',
      type: 'bar',
      isAllocationDataset: true,
    },
  };

  const mappedData = metricAllocationData.reduce(
    (acc, timeframeData) => {
      const { planned, completed, reported, date } = timeframeData;
      const formattedDate = formatDate(date || undefinedDate);

      let reportedNotCompleted = reported;

      if (Number.isFinite(reported) && Number.isFinite(completed)) {
        reportedNotCompleted = reported - completed;
      }

      const formattedPlanned = roundDecimalPlaces(planned, 1);
      const formattedCompleted = roundDecimalPlaces(completed, 1);
      const formattedReported = roundDecimalPlaces(reported, 1);

      return {
        planned: [...acc.planned, { x: formattedDate, y: formattedPlanned, label: formattedDate, formatted: formattedPlanned }],
        completed: [
          ...acc.completed,
          { x: formattedDate, y: formattedCompleted, label: formattedDate, formatted: formattedCompleted },
        ],
        reported: [
          ...acc.reported,
          { x: formattedDate, y: reportedNotCompleted, label: formattedDate, formatted: formattedReported },
        ],
        hasUndefinedData: acc.hasUndefinedData,
      };
    },
    {
      dates: [],
      ids: [],
      labels: [],
      planned: [],
      completed: [],
      reported: [],
      hasUndefinedData: false,
    },
  );

  keyedDatasets[PLANNED_DATA_SET_KEY].data = mappedData.planned;
  keyedDatasets[COMPLETED_DATA_SET_KEY].data = mappedData.completed;
  keyedDatasets[REPORTED_DATA_SET_KEY].data = mappedData.reported;

  const data = {
    ids: mappedData.ids,
    labels: mappedData.labels,
    datasets: values(keyedDatasets),
    hasUndefinedData: mappedData.hasUndefinedData,
  };

  return data;
};

const useMetricAllocationForChart = (metricId, inView, startDate, endDate, timeUnit, dateToShowUndefinedBar, preferences) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => selectIsLoadingMetricChart(state, metricId));
  const metricAllocationData = useSelector(state => selectMetricChartData(state, metricId));

  const hasMetricAllocationData = isNotNil(metricAllocationData) && metricAllocationData.length > 0;
  const showLoadingIndicator = isLoading && !hasMetricAllocationData;

  const chartData = useMemo(() => {
    if (!showLoadingIndicator) {
      return mapMetricAllocationDataToChartData(metricAllocationData, dateToShowUndefinedBar.toDate().getTime());
    }

    return DEFAULT_CHART_DATA;
  }, [showLoadingIndicator, metricAllocationData, dateToShowUndefinedBar]);

  const filteredChartData = useMemo(() => {
    return {
      ...chartData,
      datasets: chartData.datasets.filter(dataset => {
        return (
          (dataset.id === PLANNED_DATA_SET_KEY && preferences?.showPlanned !== false) ||
          (dataset.id === COMPLETED_DATA_SET_KEY && preferences?.showCompleted !== false) ||
          (dataset.id === REPORTED_DATA_SET_KEY && preferences?.showReported !== false)
        );
      }),
    };
  }, [chartData, preferences?.showPlanned, preferences?.showCompleted, preferences?.showReported]);

  // Load chart data when component is visible
  useEffect(() => {
    if (isNotNil(metricId) && inView) {
      dispatch(fetchMetricAllocationChartData(metricId, startDate, endDate, timeUnit, preferences?.sumBy?.key));
    }
  }, [metricId, inView, startDate, endDate, timeUnit, preferences?.sumBy?.key]);

  return { isLoading: showLoadingIndicator, chartData: filteredChartData };
};

export default useMetricAllocationForChart;
