import { connect } from 'react-redux';

import {
  fetchNotifications,
  markNotificationAsRead,
  fetchNextNotifications,
  markAllNotificationsAsRead,
  markNotificationAsArchived,
  markAllNotificationsAsArchived,
} from 'store/notifications';
import { setAllowReceiveNotificationsEmails } from 'store/login';

import NotificationsList from './NotificationsList';

export default connect(
  state => {
    const { notifications, login } = state;

    return {
      notifications: notifications.data.filter(n => !n.archived) || [],
      notificationsMeta: notifications._meta,
      notificationsNotRead: notifications._meta && notifications._meta.notRead,
      allowNotificationsEmails: login && login.currentUser && login.currentUser.allow_receiving_notifications_emails,
    };
  },
  {
    fetchNotifications,
    markNotificationAsRead,
    markNotificationAsArchived,
    fetchNextNotifications,
    markAllNotificationsAsRead,
    markAllNotificationsAsArchived,
    enableEmailNotifications: setAllowReceiveNotificationsEmails,
  },
)(NotificationsList);
