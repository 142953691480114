import DependenciesIcon from '@material-ui/icons/LowPriority';
import {
  TAB_COMMENTS,
  TAB_CUSTOMER_REQUESTS,
  TAB_DEPENDENCIES,
  TAB_DETAILS,
  TAB_ESTIMATES,
  TAB_HISTORY,
  TAB_IDEAS,
  TAB_PROGRESS,
  TAB_SHARE,
} from 'constants/projectLightbox';
import HistoryIcon from '@material-ui/icons/History';
import ShareIcon from '@material-ui/icons/Share';

import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import React, { Fragment } from 'react';
import FlipIcon from '@material-ui/icons/Flip';
import ShowFieldsIcon from 'design-system/atoms/ShowFieldsIcon';
import theme, { spacing } from 'design-system/theme';
import styled from 'styled-components';
import NotesIcon from '@material-ui/icons/Assignment';
import AccountTreeIcon from 'design-system/atoms/AccountTreeIcon';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Bulb from 'design-system/atoms/DragonIcons/Bulb';
import MuiBadge from '@material-ui/core/Badge';
import ScheduleIcon from '@material-ui/icons/Straighten';
import InsightsIcon from 'design-system/atoms/InsightsIcon';
import CommentIcon from 'design-system/atoms/CommentIcon';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VoteIcon from '@material-ui/icons/ThumbUp';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { removeElemProps } from 'design-system/utils';
import ButtonIcon from 'design-system/molecules/ButtonIcon';
// import OpenInFull from 'design-system/atoms/DragonIcons/OpenInFull';
import useProjectNavigationItems from 'containers/ProjectLightBox/NavigationItems/useProjectNavigationItems';
import ChangeTypeDialog from 'containers/ProjectLightBox/ChangeTypeDialog/ChangeTypeDialog';
import CloneDialog from 'containers/ProjectLightBox/CloneDialog';
import SwapHorizOutlined from '@material-ui/icons/SwapHorizOutlined';
import ConfigureLayout from 'containers/ProjectLightBox/Header/ConfigureLayout';

const ICON_SIZE = `${theme.sizing.icons.small}rem`;

const ACTIVE_TAB_COLOR = theme.palette.primary.main;
const DEFAULT_TAB_COLOR = theme.palette.newLayout.text.grey;

const getTabTestId = name => `project-lightbox-tab-${(name || '').toLowerCase()}`;

const NavigationItems = ({
  isNew,
  type,
  currentTab,
  userCanEdit,
  files,
  customerRequestsCount,
  lightboxRef,
  creatingNewSelectedLayer,

  // callbacks
  onTabChange,
  uploadFile,
  onSave,
  onCloneProject,
  onSwitchLayout,
}) => {
  const {
    hiddenFileInput,
    userCanAttachFiles,
    userCanChangeType,
    shouldShowProgressTab,
    showChangeTypeDialog,
    selectedProject,
    hasBet,
    userCanClone,
    canViewChildren,
    showCloneDialog,
    userIsWatching,
    hadVote,
    showConfigureFieldsDialog,
    oldLightboxActive,
    showDetailsTab,
    showRequestsTab,
    showEstimatesTab,
    showDependenciesTab,
    showShareTab,
    showCommentsTab,
    showHistoryTab,
    showChangeLayoutTab,

    // callbacks
    getSystemFieldName,
    handleFileInputClick,
    handleFileInputChange,
    fetchProjectCustomerRequests,
    loadEstimatesTab,
    openChangeTypeDialog,
    closeChangeTypeDialog,
    reload,
    openCloneDialog,
    closeCloneDialog,
    watchProject,
    unwatchProject,
    voteOnProject,
    unvoteOnProject,
    openConfigureFieldsDialog,
    closeConfigureFieldsDialog,
  } = useProjectNavigationItems({
    isNew,
    type,
    userCanEdit,
    uploadFile,
  });

  const _renderTab = (name, icon, active, visible, value, onClick, onHover) =>
    visible && (
      <HeaderTab
        component="div"
        disableTouchRipple
        disableRipple
        onMouseEnter={onHover}
        onClick={onClick}
        value={value}
        label={
          <TabIcon active={active} title={name}>
            {icon}
          </TabIcon>
        }
        data-testid={getTabTestId(name)}
      />
    );

  const _renderAttachFile = () =>
    _renderTab(
      'Attach File',
      <ImgUpload>
        <AttachFileIcon fontSize="small" />
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileInputChange}
          accept=".pdf, .png, .jpg, .jpeg, .doc, .docx, .xls, .xlsx, .ppt, .csv, .mov, .txt, .rtf"
        />
      </ImgUpload>,
      files && !!files.length,
      userCanAttachFiles,
      TAB_DETAILS,
      handleFileInputClick,
    );

  return (
    <StyledTabs
      value={isNew ? 1000 : currentTab || 0}
      TabIndicatorProps={{
        style: {
          display: 'none',
        },
      }}
      onChange={onTabChange}
      textColor="primary"
      centered
    >
      {_renderTab('Details', <NotesIcon fontSize={ICON_SIZE} />, currentTab === TAB_DETAILS, showDetailsTab, TAB_DETAILS)}
      {_renderAttachFile()}
      {_renderTab('Children', <AccountTreeIcon fontSize={ICON_SIZE} />, currentTab === TAB_IDEAS, canViewChildren, TAB_IDEAS)}
      {_renderTab(
        getSystemFieldName('request', true),
        <Fragment>
          <Badge badgeContent={customerRequestsCount}>
            <Bulb
              fill={currentTab === TAB_CUSTOMER_REQUESTS ? ACTIVE_TAB_COLOR : DEFAULT_TAB_COLOR}
              width="1em"
              height="1em"
              fontSize={ICON_SIZE}
            />
          </Badge>
        </Fragment>,
        currentTab === TAB_CUSTOMER_REQUESTS,
        showRequestsTab,
        TAB_CUSTOMER_REQUESTS,
        fetchProjectCustomerRequests,
      )}
      {_renderTab('Estimates', <ScheduleIcon />, currentTab === TAB_ESTIMATES, showEstimatesTab, TAB_ESTIMATES, loadEstimatesTab)}
      {_renderTab('Progress', <InsightsIcon />, currentTab === TAB_PROGRESS, shouldShowProgressTab, TAB_PROGRESS)}
      {_renderTab('Dependencies', <DependenciesIcon />, currentTab === TAB_DEPENDENCIES, showDependenciesTab, TAB_DEPENDENCIES)}
      {_renderTab('Change type', <FlipIcon />, false, userCanChangeType, currentTab, openChangeTypeDialog)}
      {_renderTab('Clone', <CopyIcon />, false, userCanClone, currentTab, openCloneDialog)}
      {_renderTab('Share', <ShareIcon />, currentTab === TAB_SHARE, showShareTab, TAB_SHARE)}
      {_renderTab(
        'Comments',
        <CommentIcon width="1em" height="1em" />,
        currentTab === TAB_COMMENTS,
        showCommentsTab,
        TAB_COMMENTS,
      )}
      {_renderTab('Watch', <Visibility />, userIsWatching, true, TAB_DETAILS, userIsWatching ? unwatchProject : watchProject)}
      {_renderTab('Vote', <VoteIcon />, hadVote, !isNew, TAB_DETAILS, hadVote ? unvoteOnProject : voteOnProject)}
      {_renderTab('History', <HistoryIcon />, currentTab === TAB_HISTORY, showHistoryTab, TAB_HISTORY)}
      {_renderTab('Configure layout', <ShowFieldsIcon fill="#6b6b6b" />, false, true, currentTab, openConfigureFieldsDialog)}
      {_renderTab('Change layout', <SwapHorizOutlined />, false, showChangeLayoutTab, currentTab, onSwitchLayout)}

      {!isNew && selectedProject ? (
        <ChangeTypeDialog
          selectedProject={selectedProject}
          hasBet={hasBet}
          reload={reload}
          onSave={onSave}
          onTabChange={onTabChange}
          getSystemFieldName={getSystemFieldName}
          showChangeTypeDialog={showChangeTypeDialog}
          closeChangeTypeDialog={closeChangeTypeDialog}
        />
      ) : null}
      {!isNew && selectedProject ? (
        <CloneDialog isOpen={showCloneDialog} onClone={onCloneProject} onClose={closeCloneDialog} project={selectedProject} />
      ) : null}
      <ConfigureLayout
        isOpen={showConfigureFieldsDialog}
        layer={isNew ? creatingNewSelectedLayer : selectedProject?.layer}
        anchorEl={lightboxRef}
        oldLightboxActive={oldLightboxActive}
        onClose={closeConfigureFieldsDialog}
      />
    </StyledTabs>
  );
};

const StyledTabs = styled(Tabs)`
  &&&& {
    min-height: 41px;
    background-color: ${theme.palette.background.secondary};
    padding: 0 ${spacing(3)}px;
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }
`;

const Badge = styled(MuiBadge)`
  &&&& {
    span {
      top: -1px;
      right: -5px;
      font-size: ${({ theme }) => theme.typography.fontSizeExtraSmallRem}rem;
    }
  }
`;

const HeaderTab = styled(Tab)`
  &&&& {
    min-height: 0;
    font-weight: 400;
    font-size: 0.875rem;
    min-width: 0;

    > span > span {
      padding: 0 2px;
    }

    svg {
      font-size: ${ICON_SIZE};
    }
  }
`;

const TabIcon = styled(removeElemProps(ButtonIcon, ['active']))`
  &&&&& {
    margin-right: ${spacing()}px;
    padding: ${spacing(0.5)}px;
    color: ${DEFAULT_TAB_COLOR};

    ${({ active }) => active && `color: ${ACTIVE_TAB_COLOR};`}
    svg {
      fill: ${DEFAULT_TAB_COLOR};

      ${({ active }) => active && `fill: ${ACTIVE_TAB_COLOR};`}
    }
  }
`;

const ImgUpload = styled.div`
  &&&& {
    display: flex;
    align-items: center;

    > input {
      display: none;
    }
  }
`;

export default NavigationItems;
