import { useDispatch, useSelector } from 'react-redux';

import { setCompareVersionEditScenario, updateRoadmapVersionDialogProperties } from 'store/roadmapVersions';

import { getHasPendingChanges, getCompareVersionEditScenario } from 'store/roadmapVersions/selectors';

const useCompareScenariosToolbarControls = () => {
  const dispatch = useDispatch();
  const compareVersionEditScenario = useSelector(getCompareVersionEditScenario);
  const hasPendingChanges = useSelector(getHasPendingChanges);

  const isEditingScenarioOnComparePage =
    compareVersionEditScenario && !compareVersionEditScenario?.committed && hasPendingChanges;

  const onClickExitDraftMode = () => {
    dispatch(setCompareVersionEditScenario(null));
  };

  const onClickSaveScenario = () => {
    dispatch(updateRoadmapVersionDialogProperties({ showSaveDialog: true }));
  };

  return {
    isEditingScenarioOnComparePage,
    onClickExitDraftMode,
    onClickSaveScenario,
  };
};

export default useCompareScenariosToolbarControls;
