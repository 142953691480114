import { defaultTo } from 'ramda';

import { OBJECTIVE_LEVEL } from 'constants/objectives';

const defaultToEmptyArray = defaultTo([]);

const projectHasKeyResult1 = (project, keyResult) =>
  project?.key_result_1_id === keyResult?.id || project?.keyResult1Id === keyResult?.id;
const projectHasKeyResult2 = (project, keyResult) =>
  project?.key_result_2_id === keyResult?.id || project?.keyResult2Id === keyResult?.id;

const filterProjectsObjectives = (objectives = [], projects = []) =>
  objectives.filter(
    objective =>
      objective?.level === OBJECTIVE_LEVEL &&
      projects.some(project => project?.objective_id === objective?.id || project?.objectiveId === objective?.id),
  );

const filterProjectsKeyResults = (keyResults = [], projects = []) =>
  keyResults.filter(keyResult =>
    projects.some(project => projectHasKeyResult1(project, keyResult) || projectHasKeyResult2(project, keyResult)),
  );

const filterProjectsTimeframes = (timeframes = [], projects = []) =>
  timeframes.filter(timeframe =>
    projects.some(project => project?.timeframe_id === timeframe?.id || project?.timeframeId === timeframe?.id),
  );

const projectHasMetric = (projectMetrics, metricId) =>
  defaultToEmptyArray(projectMetrics).some(projectMetric => projectMetric?.id === metricId || projectMetric === metricId);

const filterProjectsMetrics = (metrics = [], projects = []) =>
  metrics.filter(metric => projects.some(project => projectHasMetric(project?.metrics, metric?.id)));

export { filterProjectsObjectives, filterProjectsKeyResults, filterProjectsTimeframes, filterProjectsMetrics, projectHasMetric };
