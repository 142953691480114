import { dateComparator } from '../comparators';
import { DateCellRenderer } from '../cellRenderers';

import textCenterAlignedColumnDef from './textCenterAlignedColumnDef';
import DatePickerCellEditor from '../cellEditors/DatePickerCellEditor';

const dateColumnDef = {
  ...textCenterAlignedColumnDef,
  field: 'date',
  headerName: 'Date',
  cellRenderer: DateCellRenderer,
  cellEditorPopup: true,
  cellEditor: DatePickerCellEditor,
  cellEditorParams: {
    autoFocus: true,
  },
  comparator: dateComparator,
  editableForNewRow: false,
};

export default dateColumnDef;
