import sample from 'lodash/sample';

import isValidColor from 'utils/isValidColor';
import { PLAN_OF_RECORD_COMMITTED_ID, PLAN_OF_RECORD_ID } from 'constants/common';

import { POR_DEFAULT_COLOR, UNCOMITTED_SCENARIO_DEFAULT_COLOR, allColorsAvailable } from '../constants/colors';

export const getColorForScenario = scenario => {
  if (scenario.committed === false) return UNCOMITTED_SCENARIO_DEFAULT_COLOR;

  return isValidColor(scenario.color) ? scenario.color : sample(allColorsAvailable);
};

export const getScenariosColors = allRoadmapVersions => ({
  ...allRoadmapVersions.reduce((colors, version) => {
    colors[version?.id] = getColorForScenario(version);
    return colors;
  }, {}),
  [PLAN_OF_RECORD_ID]: POR_DEFAULT_COLOR,
  [PLAN_OF_RECORD_COMMITTED_ID]: POR_DEFAULT_COLOR,
});
