import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { pipe, prop, isNil, not, defaultTo, any } from 'ramda';

import {
  getAllProjectsFiltered,
  getDefaultGroupingObjects,
  getGroupingObjectsForLoadedOnDemandMetadata,
} from 'store/projects/groupSelectors';
import { getPageFilters } from 'src/store/filters/selectors/getPageFilters';

import useTagMetadataBasedOnRoadmapQuickfilter from 'hooks/useTagMetadataBasedOnRoadmapQuickfilter';
import { GROUP_FIELD_TO_FILTER_MAPPING, GROUP_FILTER_FUNCTIONS } from 'store/projects/constants';
import { getDisplayLayer } from 'store/filters/selectors';
import {
  getDateGroup,
  getGroupingDates,
  getGroupingObjects,
  getNestedProjectGroups,
  getProjects,
} from 'store/projects/helpers/groupSelectors';
import { getObjectivesCorpWithoutChildrenAccounts } from 'store/objectives/selectors';

import { shouldHideEmptyBasedOnFilter } from 'utils/projects/grouping';

import useOrganizationsAccessControl from './useOrganizationsAccessControl';

const emptyArray = [];
const KEY = 'key';
const defaultToEmptyObject = defaultTo({});

const keyExists = pipe(defaultToEmptyObject, prop(KEY), isNil, not);

const filtersHasConditionForGroupingObjects = (groupKey, filterFields) => {
  if (!filterFields) return false;

  return any(filterKey => filterKey === GROUP_FIELD_TO_FILTER_MAPPING[groupKey], Object.keys(filterFields));
};

const useProjectGroups = ({
  selectedGroup1,
  selectedGroup2,
  selectedGroup3,
  page,
  onlyProjects,
  withHierarchy = false,
  projectsFilters = emptyArray,
  projectMapper = null,
  customMetadata = null,
  customAllProjectsByLayer = null,
  applyPageFiltersToMetadataGroup = true,
  groupFilterFunctions = GROUP_FILTER_FUNCTIONS,
  overrideShowArchived = true,
  hideEmptyHandler = shouldHideEmptyBasedOnFilter,
  hideEmptyUserPref = true,
  isMetricMode = false,
  layer,
  shouldHideGroup = null,
  defaultGroupingObjectsSelector = getDefaultGroupingObjects,
}) => {
  // filter metadata based on selected roadmap on quickfilter
  const tagMetadataBasedOnRoadmapQuickfilter = useTagMetadataBasedOnRoadmapQuickfilter();
  const filters = isMetricMode ? {} : useSelector(getPageFilters);

  const hideEmptyWith = useCallback(
    (groupKey, groupObject) => {
      const hasAFilterByGroupedKey = filtersHasConditionForGroupingObjects(groupKey, filters.fields);

      return hideEmptyHandler({ groupObject, hasAFilterByGroupedKey, hideEmptyUserPref, groupKey });
    },
    [filters?.fields, hideEmptyHandler, hideEmptyUserPref],
  );

  const allProjectsByLayer = useSelector(state => customAllProjectsByLayer || getAllProjectsFiltered(state, page, onlyProjects));
  const displayLayer = useSelector(state => layer || getDisplayLayer(state));
  const hasBets = useSelector(state => state.organization.organization.hasBet);
  const pageFilters = useSelector(state => getPageFilters(state));
  const { childrenDragonsCorpObjectives, isParentDragon } = useOrganizationsAccessControl();
  const portfolioObjectivesCorp = useSelector(state =>
    getObjectivesCorpWithoutChildrenAccounts(state, true, childrenDragonsCorpObjectives),
  );

  const defaultGroupingObjects = isParentDragon
    ? useSelector(state => getGroupingObjectsForLoadedOnDemandMetadata(state))
    : useSelector(state => defaultGroupingObjectsSelector(state, overrideShowArchived));

  const defaultPortfolioGroupingObjects = {
    ...defaultGroupingObjects,
    objectivesCorp: portfolioObjectivesCorp,
  };

  const hasAtLeastOneSelectedGroup = keyExists(selectedGroup1);
  const onlyConsiderDisplayLayer = hasAtLeastOneSelectedGroup && !withHierarchy;

  const nestedProjectGroups = useMemo(() => {
    const groups = [selectedGroup1, selectedGroup2, selectedGroup3];
    const selectedGroupByDates = getDateGroup(groups);
    const hasDateGrouping = !!selectedGroupByDates;

    const projectsForGrouping = isMetricMode
      ? allProjectsByLayer
      : getProjects(allProjectsByLayer, {
          hasBets,
          onlyConsiderDisplayLayer,
          displayLayer,
          projectsFilters,
        });

    const groupedDates = hasDateGrouping ? getGroupingDates(projectsForGrouping, selectedGroupByDates) : null;

    const groupingObjects = getGroupingObjects({
      customMetadata,
      defaultGroupingObjects: defaultPortfolioGroupingObjects,
      groupedDates,
      applyPageFiltersToMetadataGroup,
      tagMetadataBasedOnRoadmapQuickfilter,
      ...(isMetricMode ? { pageFilters: {} } : { pageFilters }),
      projects: projectsForGrouping,
      groupFilterFunctions,
    });

    return getNestedProjectGroups(projectsForGrouping, groupingObjects, groups, {
      groupFilterFunctions,
      hideEmptyWith,
      projectMapper,
      withHierarchy,
      shouldHideGroup,
    });
  }, [
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    customMetadata,
    isMetricMode,
    allProjectsByLayer,
    hasBets,
    onlyConsiderDisplayLayer,
    displayLayer,
    projectsFilters,
    defaultGroupingObjects,
    applyPageFiltersToMetadataGroup,
    tagMetadataBasedOnRoadmapQuickfilter,
    pageFilters,
    groupFilterFunctions,
    hideEmptyWith,
    projectMapper,
    withHierarchy,
  ]);

  return nestedProjectGroups;
};

export default useProjectGroups;
