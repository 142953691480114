/* eslint-disable */
/**
 * UnfilterIcon Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function jiraIcon(props) {
  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...{ 'xmlns:xlink': 'http://www.w3.org/1999/xlink', 'xml:space': 'preserve', 'xmlns:serif': 'http://www.serif.com/' }}
      {...props}
      style={{
        'fill-rule': 'evenodd',
        'clip-rule': 'evenodd',
        'stroke-linejoin': 'round',
        'stroke-miterlimit': 2,
        // height: 16,
        ...props.style,
      }}
      width="100%"
      height="100%"
      viewBox="0 0 51.561 51.561"
    >
      <g transform="translate(-350.553 -288.465)">
        <path
          class="a"
          d="M376.322,306.184h0a8.062,8.062,0,1,0,8.074,8.062c0-.117-.01-.234-.019-.353l-4.2,4.2a5.387,5.387,0,0,1-3.84,1.594h0a5.436,5.436,0,0,1-3.841-9.282l4.2-4.2C376.568,306.194,376.445,306.188,376.322,306.184Z"
        />
        <path
          class="a"
          d="M379.416,300.334c-.221-.484-.428-.978-.616-1.476l-.008-.022a7.071,7.071,0,0,1-.272-1.311,17.036,17.036,0,0,0-2.2-.2h-.013a16.925,16.925,0,0,0,0,33.85h.012a16.936,16.936,0,0,0,16.938-16.9,16.709,16.709,0,0,0-.2-2.234,5.85,5.85,0,0,1-1.311-.249,8.659,8.659,0,0,1-.974-.414l-.528-.236a14.078,14.078,0,0,1,.391,3.1,14.3,14.3,0,1,1-14.3-14.294A14.124,14.124,0,0,1,379.416,300.334Z"
        />
        <path
          class="a"
          d="M350.553,314.246a25.781,25.781,0,1,0,50.54-7.193l-2.172,2.172a23.249,23.249,0,1,1-17.567-17.566l2.16-2.16a30.941,30.941,0,0,0-7.184-1.034A25.809,25.809,0,0,0,350.553,314.246Z"
        />
        <path
          class="a"
          d="M400.886,297.755l-6.047-2.015-2.017-6.048a1.777,1.777,0,0,0-1.693-1.227h-.012a1.783,1.783,0,0,0-1.26.524l-6.713,6.717a2.109,2.109,0,0,0-.526,1.289,2.209,2.209,0,0,0,.086.535l2.622,5.867-9.921,9.921a1.313,1.313,0,0,0,.928,2.241,1.389,1.389,0,0,0,.928-.385l9.921-9.92,5.89,2.631a2.309,2.309,0,0,0,.545.08,2.12,2.12,0,0,0,1.271-.539l6.7-6.7a1.8,1.8,0,0,0,0-2.537A1.805,1.805,0,0,0,400.886,297.755Zm-7.455,7.417-5.543-2.476-2.477-5.544,5.35-5.351,2.006,6.015,6.015,2.005Z"
        />
      </g>
    </SvgIcon>
  );
}
