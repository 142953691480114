import React, { useState, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';
import isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';
import MuiBadge from '@material-ui/core/Badge';
import MenuListItem from '@material-ui/core/MenuItem';
import MuiListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { defaultTo, isNil, not, pipe, prop } from 'ramda';

import SaveFilterDialog from 'design-system/organisms/SaveFilterDialog/index';
import DragonSelectButton from 'design-system/atoms/DragonSelectButton/index';
import FilterIcon from 'design-system/atoms/FilterIcon/index';
import FilterList from 'design-system/molecules/FilterList/index';
import MultiFilterSelector from 'design-system/molecules/MultiFilterSelector/index';
import SubNavigationTabs from 'design-system/organisms/SubNavigationTabs/index';
import ViewName from 'design-system/molecules/ViewName/index';

import usePageNavigation from 'hooks/usePagesNavigation/usePageNavigation';
import isPlainObject from 'utils/isPlainObject';
import { getIsPortalRoute } from 'routes/CustomerRequests/helpers';

import useEditFilterParams from './hooks/useEditFilterParams';
import TotalCounter from '../Header/TotalCounter';
import AdvancedSearchPopover from '../AdvancedSearchPopover';
import { GLOBAL_FILTER } from 'constants/filters';
import theme from 'design-system/theme';
import PortfolioModeChange from 'containers/PortfolioModeChange';
import Bulb from 'design-system/atoms/DragonIcons/Bulb';
import { CREATED_AFTER_LIMIT } from './constants';


const selectButtonTextStyles = css`
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  line-height: ${({ theme }) => theme.typography.lineHeightRegularLargeRem}rem;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const SELECT_VIEW_NAME = 'name';

const defaultToEmptyObject = defaultTo({});
const hasViewName = pipe(defaultToEmptyObject, prop(SELECT_VIEW_NAME), isNil, not);

export default props => {
  const [multiFilterAnchorEl, setMultiFilterAnchorEl] = useState(null);
  const [advancedSearchAnchorEl, setAdvancedSearchAnchorEl] = useState(null);
  const [filterListAnchorEl, setFilterListAnchorEl] = useState(null);
  const [portfolioModeAnchorEl, setPortfolioModeAnchorEl] = useState(null);
  const [editableItem, setEditableItem] = useState(null);
  const [showSaveFilterDialog, setShowSaveFilterDialog] = useState(false);
  const [editingFilterName, setEditingFilterName] = useState('');

  const filterIconRef = useRef(null);

  const {
    originalMultiFilterItems,
    multiFilterItems,
    setMultiFilterItems,
    multiFilterActive,
    multiFilterActiveName,
    history,
    defaultFilters,
    selectedView,
    viewOwner,
    isFavoriteView,
    handleFavoriteUserView,
    onFilterListClick,
  } = props;

  const { pages, selectedPage, setSelectedPage } = usePageNavigation('/requests', history);

  const multiFilterData = props.multiFilterData || [];
  const multiFilterList = props.multiFilterList || [];

  const { stopEditingFilter, startEditFilter } = useEditFilterParams({
    setEditingFilterName,
    setMultiFilterAnchorEl,
    multiFilterList,
    setFilterListAnchorEl,
    filterIconRef,
    multiFilterActive,
    onFilterListClick,
  });

  const _handleSelectItem = (parentKey, childId, selected) => {
    setMultiFilterItems(multiFilterItems => ({
      ...(multiFilterItems || {}),
      [parentKey]: {
        ...((multiFilterItems && multiFilterItems[parentKey]) || {}),
        [childId]: selected,
      },
    }));
  };

  const _handleClearFilter = (item = null) => {
    if (item) {
      setMultiFilterItems(multiFilterItems => ({
        ...(multiFilterItems || {}),
        [item.key]: {},
      }));
    } else {
      setMultiFilterItems({});
      props.clearAllFilters();
    }
  };

  const mapMultiLevelKeys = {
    roadmap_id: 'product_1_id',
  };

  const _handleApplyFilters = () => {
    stopEditingFilter();
    const finalMultiFilters = [];

    if (multiFilterItems.created_after) {
      let createdAfter;
      let limit;

      if (multiFilterItems.created_after[3]) {
        createdAfter = moment().subtract(30, 'd').toDate();
      } else if (multiFilterItems.created_after[2]) {
        createdAfter = moment().subtract(7, 'd').toDate();
      } else if (multiFilterItems.created_after[1]) {
        createdAfter = moment().subtract(1, 'd').toDate();
      } else if (multiFilterItems.created_after[4]) {
        limit = CREATED_AFTER_LIMIT;
      }

      const fields = {
        key: 'created_after',
        values: [],
        createdAfter,
        limit,
      };

      Object.keys(multiFilterItems.created_after).forEach(item => {
        if (multiFilterItems.created_after[item]) {
          fields.values.push(item);
        }
      });

      if (fields.values.length) {
        finalMultiFilters.push(fields);
      }
    }

    const _addFilterDataFn = (filterItems, filterData, parentId, parentKey) => {
      Object.keys(filterItems).forEach(itemKey => {
        if (itemKey === 'created_after') return;

        const data = filterData.find(item => item.key === itemKey);

        if (!data) return;

        const fields = {
          key: data.key,
          values: [],
          parentId,
          parentKey,
        };

        Object.keys(filterItems[itemKey]).forEach(item => {
          if (isPlainObject(filterItems[itemKey][item])) {
            fields.values.push(item);

            if (data.children && data.children.length) {
              const newData = (data.children.find(c => c.id === +item) || {}).children || [];
              const newItemKey = mapMultiLevelKeys[itemKey];

              _addFilterDataFn({ [newItemKey]: filterItems[itemKey][item] }, newData, item, itemKey);
            }
          } else if (filterItems[itemKey][item] === true) {
            fields.values.push(item);
          }
        });

        if (fields.values.length) {
          finalMultiFilters.push(fields);
        }
      });
    };

    _addFilterDataFn(multiFilterItems, multiFilterData);

    if (props.onApplyMultiFilter) props.onApplyMultiFilter(finalMultiFilters);

    return finalMultiFilters;
  };

  const _handleClickAwayApplyFilters = () => {
    stopEditingFilter();
    if (isEqual(originalMultiFilterItems, multiFilterItems)) return;

    _handleApplyFilters();
  };

  const _handleSaveFilter = name => {
    const appliedMultiFilters = _handleApplyFilters();

    props.onSaveFilter(name, appliedMultiFilters);
  };

  const _handleFilterListClicked = savedFilterClicked => {
    stopEditingFilter();
    props.onFilterListClick(savedFilterClicked);
  };

  const _handleAddNewFilter = () => {
    stopEditingFilter();
    setMultiFilterItems({});
    setFilterListAnchorEl(null);
    setMultiFilterAnchorEl(filterIconRef.current);
  };

  const _multiFilterHeader = () => {
    const _getBadgeContent = () => {
      let activeFields = 0;

      Object.keys(multiFilterItems).forEach(mFilterKey => {
        let addField = false;

        Object.keys(multiFilterItems[mFilterKey]).forEach(childKey => {
          addField = multiFilterItems[mFilterKey][childKey] === true ? true : addField;
        });

        if (addField) activeFields++;
      });

      return activeFields;
    };

    return (
      <div ref={filterIconRef}>
        <StyledButton
          id="multi-filters-button"
          onClick={event => {
            if (multiFilterAnchorEl === null) setMultiFilterAnchorEl(event.currentTarget);
            else _handleApplyFilters();
          }}
        >
          <Badge badgeContent={_getBadgeContent()}>
            <FilterIcon width={14} height={14} color={theme.palette.text.primary} stroke={theme.palette.text.primary} />
          </Badge>
        </StyledButton>
      </div>
    );
  };

  const _renderSubMenu = item => {
    return (
      <>
        <ListItem onClick={() => setEditableItem(item)}>
          <ListItemText>Rename</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText onClick={() => startEditFilter(item)}>Edit</ListItemText>
        </ListItem>
        <ListItem onClick={() => props.onDeleteFilter(item)}>
          <ListItemText>Delete</ListItemText>
        </ListItem>
      </>
    );
  };

  const _handleChangeMode = useCallback(event => {
    setPortfolioModeAnchorEl(event.currentTarget);
  }, []);

  if (getIsPortalRoute()) {
    return null;
  }

  const shouldDisplayViewName = selectedView.id && hasViewName(selectedView);

  return (
    <>
      <Wrapper>
        <IconContainer onClick={_handleChangeMode} id="module_icon">
          <Bulb />
        </IconContainer>
        <PortfolioModeChange history={history} anchorEl={portfolioModeAnchorEl} handleClose={setPortfolioModeAnchorEl} />
        <FlexColumnContainer>
          <FlexContainer>
            <SelectButtonContainer>
              <DragonSelectButton
                id="filters-list-dropdown"
                data-testid="multifilter-name"
                value={multiFilterActiveName}
                variant="secondary"
                noUnderline
                higherLabel
                onClick={event => setFilterListAnchorEl(event.currentTarget)}
                placeholder="Select saved filter"
                textStyles={selectButtonTextStyles}
                arrowIcon={<StyledKeyboardArrowDownIcon />}
              />
            </SelectButtonContainer>
            <MultiFilterContainer>
              <MultiFilterSelector
                hasHeader
                renderHeader={_multiFilterHeader}
                anchorEl={multiFilterAnchorEl}
                menu={multiFilterData}
                selectedItems={multiFilterItems}
                onSetAnchorEl={setMultiFilterAnchorEl}
                onSelectItem={_handleSelectItem}
                onClearFilter={_handleClearFilter}
                onApplyFilters={_handleClickAwayApplyFilters}
                onSaveFilter={() => setShowSaveFilterDialog(true)}
                showSaveFilterDialog={showSaveFilterDialog}
                customHeaderStyle={{ borderRadius: '50%', width: 38, margin: '0', minWidth: 'unset' }}
                onOpenAdvancedSearchPopup={() => {
                  setAdvancedSearchAnchorEl(multiFilterAnchorEl);
                  setMultiFilterAnchorEl(null);
                }}
              />
            </MultiFilterContainer>
            <TotalCounter />
            {shouldDisplayViewName && (
              <ViewName
                selectedView={selectedView}
                viewOwner={viewOwner}
                isFavoriteView={isFavoriteView}
                handleFavoriteUserView={handleFavoriteUserView}
                maxWidth="35vw"
              />
            )}
          </FlexContainer>
          <SubNavigationContainer>
            <SubNavigationTabs items={pages} selected={selectedPage} handleNavigationChange={setSelectedPage} />
          </SubNavigationContainer>
        </FlexColumnContainer>
      </Wrapper>

      <FilterList
        hasHeader={false}
        label="Saved Filter"
        anchorEl={filterListAnchorEl}
        onSetAnchorEl={setFilterListAnchorEl}
        menu={multiFilterList}
        renderSubMenu={_renderSubMenu}
        onUpdateMenu={props.onRenameFilter}
        onClick={_handleFilterListClicked}
        onAddNewFilter={_handleAddNewFilter}
        editableItem={editableItem}
        onSetEditableItem={setEditableItem}
        defaultFilters={defaultFilters}
      />

      <SaveFilterDialog
        filterName={editingFilterName}
        showDialog={showSaveFilterDialog}
        onClose={() => setShowSaveFilterDialog(false)}
        onSave={name => _handleSaveFilter(name)}
      />
      <AdvancedSearchPopover
        anchorEl={advancedSearchAnchorEl}
        setAnchorEl={el => setAdvancedSearchAnchorEl(el)}
        page={GLOBAL_FILTER}
        onClearFilter={_handleClearFilter}
        includeCustomFields
      />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  transform: translateY(5px);
`;

const SubNavigationContainer = styled.div`
  height: 100%;

  > div {
    min-height: unset;
    display: flex;
    height: 100%;
  }
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.newLayout.background.turquoise};
  border-radius: ${({ theme }) => theme.shape.borderRadiusLarge}px;
  cursor: pointer;

  svg path {
    fill: ${({ theme }) => theme.palette.white};
  }
`;

const SelectButtonContainer = styled.div`
  &&&& button {
    padding: 0;
  }
`;

const MultiFilterContainer = styled.div`
  margin-left: 10px;
`;

const ListItemText = styled(MuiListItemText)`
  &&&& {
    margin: 0;
    ${({ uppercase }) =>
      uppercase &&
      `
      text-transform: uppercase;
    `}

    span {
      font-size: ${props => props.theme.typography.fontSize}px;
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      color: ${props => props.theme.palette.text.lightGrey};
    }
  }
`;

const ListItem = styled(MenuListItem)`
  &&&& {
    height: 18px;

    &:hover {
      background-color: ${props => props.theme.palette.newLayout.background.lighterBlack};

      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      ${ListItemText} {
        color: #2ea8e1;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  &&&& {
    margin: 0;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    min-width: unset;
  }
`;

const Badge = styled(MuiBadge)`
  &&&& {
    span {
      color: ${props => props.theme.palette.text.primary};
      font-weight: ${props => props.theme.typography.fontWeightBold};
      background: transparent;
      top: -1px;
      right: -5px;
    }
  }
`;

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
  &&&&& {
    width: 24px;
    height: 24px;

    path {
      fill: none;
    }
  }
`;
