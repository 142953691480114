import { UPDATE_RELEASE_NOTES_VISIBILITY, ADD_CHUNK_TO_RELEASE_NOTES } from './types';
import { UPDATE } from 'store/constants/realtimeUpdateTypes';

export const updateReleaseNotesVisibility = (page, visible, makesActiveViewDirty = true) => ({
  type: UPDATE_RELEASE_NOTES_VISIBILITY,
  payload: { page, visible },
  meta: makesActiveViewDirty ? { makesActiveViewDirty: true } : {},
});

export const handleStreamReleaseNotes = (type, data) => (dispatch, getState) => {
  switch (type) {
    case UPDATE:
      dispatch({
        type: ADD_CHUNK_TO_RELEASE_NOTES,
        payload: data,
      });
      break;
    default:
      break;
  }
};
