import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCustomerRequestsCustomFields } from 'store/customFields/selectors';
import { getGridConfigValue } from 'store/grids/selectors';
import { getCurrentUser } from 'store/login/selectors';
import {
  startCreatingCustomerRequest as startCreatingCustomerRequestAction,
  setCustomerRequestsGridVisibleFields as setCustomerRequestsGridVisibleFieldsAction,
  setSelectedView as setSelectedViewAction,
  toggleBulkUpdateCustomerRequests as toggleBulkUpdateCustomerRequestsAction,
  toggleCustomFieldsForCustomerRequest as toggleCustomFieldsForCustomerRequestAction,
  togglePortalSettingsForCustomerRequest as togglePortalSettingsForCustomerRequestAction,
  toggleForwardEmailsForCustomerRequest as toggleForwardEmailsForCustomerRequestAction,
} from 'store/customerRequests/actions';
import { getOrganization } from 'store/organization/selectors';
import { CUSTOMER_REQUESTS } from 'store/grids/constants';
import { saveGridConfig } from 'store/grids/actions';
import useGridApiContext from 'hooks/grid/useGridApiContext';
import useGridShowFields from 'hooks/grid/useGridShowFields';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import useExportCsvFromGrid from 'features/GridExport/hooks/useExportCsvFromGrid';

const componentHOC = Component => {
  return props => {
    const { getColumnApi } = useGridApiContext();
    const columnApi = useMemo(() => getColumnApi(CUSTOMER_REQUESTS), [getColumnApi]);

    const { handleChangeColumnsVisibility } = useGridShowFields(CUSTOMER_REQUESTS, columnApi);
    const { handleExportToCsv } = useExportCsvFromGrid(CUSTOMER_REQUESTS, 'requests');

    const dispatch = useDispatch();

    const currentUser = useSelector(getCurrentUser);
    const customFields = useSelector(getCustomerRequestsCustomFields);
    const gridState = useSelector(state => getGridConfigValue(state, CUSTOMER_REQUESTS, 'gridState'));
    const rowHeight = useSelector(state => getGridConfigValue(state, CUSTOMER_REQUESTS, 'rowHeight'));
    const organization = useSelector(getOrganization);
    const notificationsNotRead = useSelector(({ notifications }) => notifications._meta && notifications._meta.notRead);

    const { canCreate } = usePermissions();

    const canCreateRequest = canCreate(PERMISSION_RESOURCES.request);

    const _onClickNew = () => dispatch(startCreatingCustomerRequestAction());
    const setSelectedView = view => dispatch(setSelectedViewAction(view));
    const toggleCustomFields = () => dispatch(toggleCustomFieldsForCustomerRequestAction());
    const togglePortalSettings = () => dispatch(togglePortalSettingsForCustomerRequestAction());
    const toggleBulkActions = () => dispatch(toggleBulkUpdateCustomerRequestsAction());
    const toggleForwardEmails = () => dispatch(toggleForwardEmailsForCustomerRequestAction());
    const _saveGridRowHeight = val => dispatch(saveGridConfig(CUSTOMER_REQUESTS, 'rowHeight', val));

    const setVisibleFields = useCallback(
      async visibleFields => {
        if (!visibleFields.includes('details') && rowHeight !== 40) {
          await _saveGridRowHeight(40);
        }
        dispatch(setCustomerRequestsGridVisibleFieldsAction(visibleFields));

        handleChangeColumnsVisibility(visibleFields);
      },
      [handleChangeColumnsVisibility, _saveGridRowHeight, setCustomerRequestsGridVisibleFieldsAction, rowHeight],
    );

    return (
      <Component
        {...props}
        currentUser={currentUser}
        customFields={customFields}
        gridColumnsState={gridState?.columnState || []}
        setSelectedView={setSelectedView}
        toggleBulkActions={toggleBulkActions}
        toggleCustomFields={toggleCustomFields}
        togglePortalSettings={togglePortalSettings}
        toggleForwardEmails={toggleForwardEmails}
        onClickNew={_onClickNew}
        handleExportToCsv={handleExportToCsv}
        onChangeVisibleFields={setVisibleFields}
        organization={organization}
        rowHeight={rowHeight}
        saveGridRowHeight={_saveGridRowHeight}
        notificationsNotRead={notificationsNotRead}
        canCreateRequest={canCreateRequest}
      />
    );
  };
};

export default componentHOC;
