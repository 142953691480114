import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { labelMatchesQuery } from './utils';

const emptyArray = [];

const filterTree = (value, items) =>
  items.reduce((acc, option) => {
    const currentItemMatches = labelMatchesQuery(option.label, value);

    const childrenFiltered = option?.children?.length ? filterTree(value, option?.children) : [];

    const anyChildMatchedFilter = childrenFiltered.length;

    if (currentItemMatches || anyChildMatchedFilter) {
      const children = currentItemMatches ? option.children : childrenFiltered;

      return [...acc, { ...option, openByDefault: anyChildMatchedFilter, children }];
    }
    return acc;
  }, []);

const filterOptionsCallback = (value, options) => {
  const filteredOptions = value ? filterTree(value, options) : options;

  return filteredOptions;
};

const useTreeAutocompleteSearch = (options = emptyArray) => {
  const [expandAllCallback, setExpandAllCallback] = useState();
  const [collapseAllCallback, setCollapseAllCallback] = useState();
  const [searchValue, setSearchValue] = useState();
  const [filteredOptions, setFilteredOptions] = useState(options);

  const onSearchChange = (newValue, config = {}) => {
    const { avoidCollapse } = config;

    setSearchValue(newValue);
    setFilteredOptions(filterOptionsCallback(newValue, options));
    if (newValue) {
      expandAllCallback && expandAllCallback();
    } else if (!avoidCollapse) {
      collapseAllCallback && collapseAllCallback();
    }
  };

  const [debouncedOnSearchChange] = useDebouncedCallback(onSearchChange, 300);

  useEffect(() => {
    setFilteredOptions(filterOptionsCallback(searchValue, options));
  }, [options]);

  const registerExpandAllCallback = callback => setExpandAllCallback(() => callback);
  const registerCollapseAllCallback = callback => setCollapseAllCallback(() => callback);

  return {
    searchValue,
    filteredOptions,
    onSearchChange: debouncedOnSearchChange,
    registerExpandAllCallback,
    registerCollapseAllCallback,
  };
};

export default useTreeAutocompleteSearch;
