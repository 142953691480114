import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { keys, values, uniq, defaultTo, isNil } from 'ramda';

import { getNormalizedRoadmaps } from 'store/roadmaps/selectors';
import { UNDEFINED_LABEL } from 'constants/common';
import { getUserFilters } from 'store/filters/selectors';
import { DASHBOARDS_PAGE } from 'constants/filters/pages';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import { getNormalizedObjectives } from 'store/objectives/selectors';
import theme from 'design-system/theme';
import invertedTextColor from 'design-system/utils/invertedTextColor';
import { getGroupOptions } from 'store/projects/helpers/groupOptions';
import useSystemFields from 'hooks/useSystemFields';
import { OBJECTIVE_KEY } from 'src/constants/objectives';
import clampLabelSize from 'utils/charts/clampLabelSize';
import usePageFilters from 'hooks/filters/usePageFilters';
import { getOrgCustomUserProjectFields, getOrgHasCustomUserProjectFields } from 'store/organization';

const MAX_NUM_CHARS_ROADMAP = 15;
const MAX_NUM_CHARS_OBJECTIVE = 25;
const DEFAULT_CHART_DATA = {
  labels: [],
  datasets: [],
};
const CHART_API_URL = '/api/v1/reports/dashboard/goals-shared-across-roadmaps';

const checkHasChartData = (chartData = {}) => Boolean(keys(chartData.datasets).length);
const defaultUndefinedColor = defaultTo(theme.palette.background.cultured);
const defaultUndefinedLabel = defaultTo(UNDEFINED_LABEL);
const defaultZero = defaultTo(0);
const defaultToEmptyObject = defaultTo({});

const ROADMAP_KEY = 'roadmap';

const requestChartData = (filters = {}, userFilters = {}, hasBet, displayLayer) => {
  const projectsFilter = compileFiltersBody(filters, userFilters, hasBet, DASHBOARDS_PAGE, displayLayer, null);

  return axios.post(CHART_API_URL, projectsFilter);
};

const getLabelForRoadmap = (roadmapId, roadmaps) => {
  const label = defaultUndefinedLabel(defaultToEmptyObject(roadmaps)[roadmapId]?.title);

  return clampLabelSize(label, MAX_NUM_CHARS_ROADMAP);
};

const getLabelForObjective = (objectiveId, objectives) => {
  const label = defaultUndefinedLabel(defaultToEmptyObject(objectives)[objectiveId]?.title);

  return clampLabelSize(label, MAX_NUM_CHARS_OBJECTIVE);
};

const getColorForRoadmap = (roadmapId, roadmaps) => {
  return defaultUndefinedColor(defaultToEmptyObject(roadmaps)[roadmapId]?.color);
};

/**
 * @function useRoadmapByOkrStackedChartData
 *
 * Use chart data for Roadmap by OKR stacked chart
 *
 * @return {Object}
 */
const useRoadmapByOkrStackedChartData = () => {
  const [state, setState] = useState({
    countersByRoadmapAndOkr: null,
    loading: false,
  });
  const { pageFilters: filters, displayLayer } = usePageFilters(DASHBOARDS_PAGE);
  const userFilters = useSelector(getUserFilters);
  const hasBet = useSelector(state => state.organization.organization.has_bet);
  const roadmaps = useSelector(getNormalizedRoadmaps);
  const objectives = useSelector(getNormalizedObjectives);

  const chartData = useMemo(() => {
    if (isNil(state.countersByRoadmapAndOkr)) {
      return DEFAULT_CHART_DATA;
    }

    const ids = keys(state.countersByRoadmapAndOkr);
    const labels = ids.map(id => getLabelForObjective(id, objectives));
    const allRoadmaps = uniq(values(state.countersByRoadmapAndOkr).reduce((acc, v) => [...acc, ...keys(v)], []));

    const datasets = allRoadmaps.sort().reduce((acc, roadmapId) => {
      const entryData = values(state.countersByRoadmapAndOkr).map(counter => defaultZero(+counter[roadmapId]));
      const roadmapColor = getColorForRoadmap(roadmapId, roadmaps);

      acc.push({
        label: getLabelForRoadmap(roadmapId, roadmaps),
        data: entryData,
        id: roadmapId,
        backgroundColor: roadmapColor,
        datalabels: {
          color: invertedTextColor(roadmapColor, true),
        },
      });

      return acc;
    }, []);

    const data = {
      ids,
      labels,
      datasets,
    };

    return data;
  }, [state.countersByRoadmapAndOkr, roadmaps, objectives]);

  const makeChartData = useCallback(async () => {
    setState(state => ({ ...state, loading: true }));

    try {
      const { data: countersByRoadmapAndOkr } = await requestChartData(filters, userFilters, hasBet, displayLayer);

      setState({ countersByRoadmapAndOkr, loading: false });
    } catch {
      setState({ countersByRoadmapAndOkr: {}, loading: false });
    }
  }, [filters, userFilters, hasBet, displayLayer]);

  useEffect(() => {
    makeChartData();
  }, [makeChartData]);

  const hasData = useMemo(() => checkHasChartData(chartData), [chartData]);

  const hasKeyResults1 = useSelector(state => state.organization.organization.has_key_results);

  const [getSystemFieldName] = useSystemFields();

  const hasCustomUserFields = useSelector(getOrgHasCustomUserProjectFields);
  const customUserFields = useSelector(getOrgCustomUserProjectFields);

  const groupOptions = useMemo(
    () =>
      getGroupOptions({
        getSystemFieldName,
        hasKeyResults: true,
        withNullOption: true,
        customUserFields: hasCustomUserFields ? customUserFields : {},
      }),
    [getSystemFieldName],
  );

  const selectedGroup1 = useMemo(() => groupOptions.find(go => go.key === OBJECTIVE_KEY), [groupOptions]);
  const selectedGroup2 = useMemo(() => groupOptions.find(go => go.key === ROADMAP_KEY), [groupOptions]);

  return {
    chartData,
    displayLayer,
    hasData,
    selectedGroup1,
    selectedGroup2,
    hasKeyResults1,
  };
};

export default useRoadmapByOkrStackedChartData;
