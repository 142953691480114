import React from 'react';
import Grid from '@material-ui/core/Grid';

import Text from 'design-system/atoms/Text/index';


import ProgressChart from './ProgressChart';
import BurnDownChart from './BurnDownChart';
import useTimelineLightboxInProgressChart from './hooks/useTimelineLightboxInProgressChart';

const ProgressContent = ({ data, isLoading, startDate, endDate, targetAndPredictedLinesOptions, titleType, parentProjectId }) => {
  const hasNoData = !isLoading && !data.length;

  const timelineLightboxProps = useTimelineLightboxInProgressChart({ parentProjectId });

  return (
    <Grid container direction="column" justify="center">
      {!hasNoData && (
        <Grid container style={{ padding: 20 }}>
          <Grid item xs={6} style={{ paddingLeft: 15, paddingRight: 15 }}>
            <BurnDownChart
              loading={isLoading}
              data={data}
              startDate={startDate}
              endDate={endDate}
              titleType={titleType}
              {...timelineLightboxProps}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingRight: 15, paddingLeft: 15 }}>
            <ProgressChart
              loading={isLoading}
              data={data}
              startDate={startDate}
              endDate={endDate}
              targetAndPredictedLinesOptions={targetAndPredictedLinesOptions}
              titleType={titleType}
              {...timelineLightboxProps}
            />
          </Grid>
        </Grid>
      )}
      {hasNoData && (
        <Grid container style={{ padding: 20 }}>
          <Grid item xs={12} style={{ text: 15 }}>
            <Text style={{ textAlign: 'center' }}>No data available for the selected period</Text>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ProgressContent;
