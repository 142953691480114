/**
 * TextFieldWithButton Organism Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export default class TextFieldWithButton extends PureComponent {
  render() {
    const { button, onChange, value, required, placeholder, label, name, error, errorText, helperText } = this.props;

    const ButtonComponent = button;

    return (
      <Wrapper>
        <CustomTextField
          name={name}
          label={label}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          required={required}
          error={error}
          errorText={errorText}
          helperText={helperText}
        />
        <ButtonWrapper>
          <ButtonComponent />
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
`;

const CustomTextField = styled(TextField)`
  flex-grow: 1;
`;

const ButtonWrapper = styled.div`
  width: 180px;
  text-align: center;
  margin-bottom: -5px;
`;
