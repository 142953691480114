import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Autocomplete from 'design-system/atoms/Autocomplete/index';

import useCreateOnRally from './useCreateOnRally';
import { StyledGridItem } from '../../../utils/styles';

const CreateOnRally = props => {
  const {
    onCreate,
    orgIntegration,
    createErrorMsg,
    rallyWorkItemType,
    isCreating,
    disableActions,
    hasUserIntegration,
    setupUserIntegration,
  } = props;

  const { isLoadingRallyProjects, selectedProjectId, rallyProjects, fetchRallyProjects, setSelectedProjectId, onCreateHandler } =
    useCreateOnRally(orgIntegration, onCreate);

  useEffect(() => {
    setSelectedProjectId(null);
  }, [orgIntegration.id]);

  const onProjectAutocompleteFocus = useCallback(() => {
    if (!hasUserIntegration) {
      return setupUserIntegration();
    }

    fetchRallyProjects();
  }, [hasUserIntegration, setupUserIntegration, fetchRallyProjects]);

  return (
    <Grid container>
      <Grid item xs={8}>
        <Autocomplete
          hideBottomBorder
          suggestions={rallyProjects}
          value={rallyProjects.find(p => p.value === selectedProjectId)?.label || null}
          onValueChange={value => {
            setSelectedProjectId(value);
          }}
          placeholder="Select Rally project"
          isLoading={isLoadingRallyProjects}
          onFocus={onProjectAutocompleteFocus}
          disabled={isCreating || disableActions}
        />
      </Grid>
      <StyledGridItem item xs={4}>
        <Button
          id="create-rally"
          disabled={!selectedProjectId || isCreating || disableActions}
          color="primary"
          onClick={onCreateHandler}
        >
          {`Create ${rallyWorkItemType}`}
        </Button>
      </StyledGridItem>
      {createErrorMsg && (
        <Grid item xs={12} style={{ paddingTop: '5px' }}>
          <span style={{ color: 'red' }}>{createErrorMsg}</span>
        </Grid>
      )}
    </Grid>
  );
};

CreateOnRally.propTypes = {
  onCreate: PropTypes.func.isRequired,
  orgIntegration: PropTypes.object.isRequired,
};

export default CreateOnRally;
