import React from 'react';
import { useDispatch } from 'react-redux';
import Input from '@material-ui/core/Input';
import styled from 'styled-components';

import useHeaderSearchString from 'hooks/useHeaderSearchString';
import Search from 'design-system/atoms/DragonIcons/Search';
import theme from 'design-system/theme';

const exist = Boolean;

const LocalSearchInput = ({ placeholderStyle, inputStyle, iconStyle }) => {
  const dispatch = useDispatch();
  const path = window.location.pathname;

  const [search, setSearch] = useHeaderSearchString(path);

  const _onChangeSearch = e => dispatch(setSearch(e.target.value));

  return (
    <Wrapper placeholderStyle={placeholderStyle} inputStyle={inputStyle}>
      <Icon iconStyle={iconStyle}>
        <Search />
      </Icon>
      <Input id="local_search" placeholder="Search this page" type="text" value={search} onChange={_onChangeSearch} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  min-width: 130px;

  input {
    padding-left: 22px;

    ${({ inputStyle }) => exist(inputStyle?.color) && `color: ${inputStyle.color};`}

    &::placeholder {
      color: ${theme.palette.text.quickSilver};
      font-size: ${theme.typography.fontSize}px;

      ${({ placeholderStyle }) => exist(placeholderStyle?.color) && `color: ${placeholderStyle.color};`}
      ${({ placeholderStyle }) => exist(placeholderStyle?.opacity) && `opacity: ${placeholderStyle.opacity};`}
    }
  }
`;

const Icon = styled.div`
  &&&& {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    svg path {
      fill: ${theme.palette.text.primary};

      ${({ iconStyle }) => exist(iconStyle?.color) && `fill: ${iconStyle.color};`}
    }
  }
`;

export default LocalSearchInput;
