import React, { useCallback, useEffect, useState } from 'react';
import { convertToRaw, EditorState } from 'draft-js';
import {
  ADMIN_USER,
  EDITOR_USER,
  LEADER_USER,
  MANAGER_USER,
  OWNER_USER,
  READ_ONLY_USER,
  COLLABORATOR_USER,
} from '@dragonboat/permissions';
import { shareUserViewWithOtherUsers } from 'store/userViews/sharedViews.thunks';
import { useDispatch } from 'react-redux';
import { either, isEmpty, isNil } from 'ramda';
import queryString from 'query-string';

import { DETAILS_TAB, SHARE_PUBLIC_LINK_TAB, SHARE_WITH_USERS_TAB, RECURRING_NOTIFICATIONS_TAB } from './constants';

const PREVIEW_PARAM = 'preview';

const isNilOrEmpty = either(isNil, isEmpty);

const checkIfTabIsDisabled = (tab, view) => {
  return !view && tab !== DETAILS_TAB;
};

const componentHOC = Component => {
  return props => {
    const {
      initialViewTab,
      view,
      show,
      onSaveAndShare,
      onSave = () => {},
      onShare = () => {},
      onClose,
      shareUrl,
      tabs = [],
      userCanEditView = true,
    } = props;

    const [selectedTab, setSelectedTab] = useState(initialViewTab || DETAILS_TAB);
    const [shareOption, setShareOption] = useState('organization');
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [viewName, setViewName] = useState('');
    const [viewDescription, setViewDescription] = useState('');

    const dispatch = useDispatch();

    const nameHasNotChanged = view && viewName === view.name;
    const descriptionHasNotChanged = view && viewDescription === view.description;
    const isSaveDisabled = !viewName || (nameHasNotChanged && descriptionHasNotChanged);
    const isPreviewDisabled = selectedTab === SHARE_PUBLIC_LINK_TAB && isNilOrEmpty(view?.publicLinks);

    const myOrganizationRolesToShare = [
      ADMIN_USER,
      MANAGER_USER,
      EDITOR_USER,
      LEADER_USER,
      OWNER_USER,
      READ_ONLY_USER,
      COLLABORATOR_USER,
    ];

    useEffect(() => {
      if (show) setSelectedTab(initialViewTab || DETAILS_TAB);
    }, [show]);

    const handleSaveAndShare = useCallback(
      (name, description) => {
        setViewName('');
        setViewDescription('');

        if (onSaveAndShare) {
          onSaveAndShare(name, description);
          return;
        }

        if (view) {
          onSave(name, description);
          onShare(view);
        }
      },
      [onClose, onSave, onShare],
    );

    const handleTabChange = (e, label) => {
      if (selectedTab === DETAILS_TAB && !isSaveDisabled) {
        onSave(viewName, viewDescription);
      }
      setSelectedTab(label);
    };

    const _onShare = data => {
      if (view) {
        dispatch(shareUserViewWithOtherUsers(view.key, data));
      }
    };

    const handleOnShare = () => {
      if (shareOption === 'organization') {
        _onShare({ roleGroupsToShareWith: myOrganizationRolesToShare });
      } else {
        const currentState = editorState.getCurrentContent();
        const rawEditorState = convertToRaw(currentState);
        const mentions = Object.entries(rawEditorState.entityMap)
          .filter(([_, entity]) => entity.type === 'mention')
          .map(([_, entity]) => entity.data.mention);

        if (mentions.length > 0) {
          _onShare({ users: mentions.map(u => u.id) });
        }
      }
      if (userCanEditView) {
        handleSaveAndShare(viewName, viewDescription);
      }
      onClose();
    };

    const buildPublicLinkFromHash = hash => {
      return `${window.location.origin}/share/${hash}`;
    };

    const handleOnPreview = () => {
      let url;

      if (selectedTab === SHARE_PUBLIC_LINK_TAB) {
        const publicUrl = buildPublicLinkFromHash(view?.publicLinks?.[0]?.link_hash);

        url = queryString.parseUrl(publicUrl);
      } else {
        url = queryString.parseUrl(shareUrl);
      }

      url.query[PREVIEW_PARAM] = true;

      const parsedUrl = queryString.stringifyUrl(url);

      window.open(parsedUrl, '_blank');
    };

    const isDetailsTab = selectedTab === DETAILS_TAB;
    const isShareWithUsersTab = selectedTab === SHARE_WITH_USERS_TAB;
    const isSharePublicLinkTab = selectedTab === SHARE_PUBLIC_LINK_TAB;
    const isRecurringNotificationTab = selectedTab === RECURRING_NOTIFICATIONS_TAB;

    return (
      <Component
        {...props}
        selectedTab={selectedTab}
        isDetailsTab={isDetailsTab}
        isShareWithUsersTab={isShareWithUsersTab}
        isSharePublicLinkTab={isSharePublicLinkTab}
        isRecurringNotificationTab={isRecurringNotificationTab}
        tabs={tabs}
        disabledTab={tab => checkIfTabIsDisabled(tab, view)}
        handleTabChange={handleTabChange}
        handleOnPreview={handleOnPreview}
        handleOnShare={handleOnShare}
        shareOption={shareOption}
        setShareOption={setShareOption}
        editorState={editorState}
        setEditorState={setEditorState}
        viewName={viewName}
        setViewName={setViewName}
        viewDescription={viewDescription}
        setViewDescription={setViewDescription}
        isSaveDisabled={isSaveDisabled}
        userCanEditView={userCanEditView}
        isPreviewDisabled={isPreviewDisabled}
      />
    );
  };
};

export default componentHOC;
