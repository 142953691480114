import AddIcon from '@material-ui/icons/Add';

import createActionColumnDefGetter from './createActionColumnDefGetter';

const ADD_COLUMN_ID = 'add';

const getAddColumnDef = createActionColumnDefGetter({
  field: ADD_COLUMN_ID,
  icon: AddIcon,
});

export { ADD_COLUMN_ID };

export default getAddColumnDef;
