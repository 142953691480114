
import React from 'react';

import Popover from '@material-ui/core/Popover';

const DEFAULT_ANCHOR_ORIGIN = { vertical: 'top', horizontal: 'left' };
const DEFAULT_TRANSFORM_ORIGIN = { vertical: 'top', horizontal: 'left' };

export default function OptionsPopover({
  anchorEl,
  anchorOrigin,
  children,
  className,
  isOpen,
  onClose,
  transformOrigin,
  updateActionRef,
}) {
  return (
    <Popover
      className={className}
      open={isOpen}
      anchorEl={anchorEl}
      action={ref => updateActionRef && updateActionRef(ref)}
      onClose={onClose}
      anchorOrigin={anchorOrigin || DEFAULT_ANCHOR_ORIGIN}
      transformOrigin={transformOrigin || DEFAULT_TRANSFORM_ORIGIN}
    >
      {children}
    </Popover>
  );
}
