/* eslint-disable max-len */
import React from 'react';

export default ({ width = '16px', height = '16px', ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25.8 23.8"
      fill={props.color || '#6b6b6b'}
      width={width}
      height={height}
      {...props}
    >
      <path d="M25.6 11.8c0 5.7-5.7 10.4-12.7 10.4-1.6 0-3.2-.3-4.6-.7-1.4 1-3.9 2.3-6.9 2.3-.5 0-.9-.3-1.1-.7-.2-.4-.1-.9.2-1.3 0 0 1.6-1.7 2.3-3.6C1.2 16.5.2 14.3.2 11.9.1 6 5.8 1.4 12.8 1.4S25.6 6 25.6 11.8zm-2.4 0c0-4.4-4.6-8-10.4-8s-10.4 3.6-10.4 8c0 2.1 1.1 3.7 2 4.7l1 1.1-.4 1.3c-.3.7-.6 1.4-1 2 1.2-.4 2.2-.9 2.9-1.4l1-.7 1.1.4c1.3.4 2.6.6 3.9.6 5.6-.1 10.3-3.6 10.3-8z" />
    </svg>
  );
};
