import React from 'react';

import PageHeaderFilters from 'containers/PageHeaderFilters';
import { METADATA_LEVELS } from 'constants/common';
import history from 'store/utils/history';

import { Link, PortfolioTreeContainer, Selector, Separator } from './styles';
import useSelectedRoadmapOnPortfolioOverview from '../../hooks/useSelectedRoadmapOnPortfolioOverview';
import { getRoadmapLevelByMetaType, getRodmapEntityUrl } from '../../helpers';
import { either, propEq } from 'ramda';

const PORTFOLIO_LABEL = 'Portfolio';
const PORTFOLIO_OPTION_KEY = 'null';

const isLevel2 = propEq('type', METADATA_LEVELS.LEVEL_2);
const isLevel3 = propEq('type', METADATA_LEVELS.LEVEL_3);
const isLevel2OrLevel3 = either(isLevel2, isLevel3);

const PortfolioOverviewHeaderFilters = props => {
  const { activeRoadmapEntity, handleSelectPortfolioRoadmap, handleSelectRoadmap, handleSelectProduct } =
    useSelectedRoadmapOnPortfolioOverview();

  const onApplyQuickFilter = item => {
    if (item?.key === PORTFOLIO_OPTION_KEY) {
      history.push(getRodmapEntityUrl(null, null));

      return;
    }

    const roadmapLevel = getRoadmapLevelByMetaType(item.metaType);

    history.push(getRodmapEntityUrl(item?.key, roadmapLevel));
  };

  const onApplyPortfolio = () => {
    handleSelectPortfolioRoadmap();
  };

  const onApplyRoadmap = item => {
    handleSelectRoadmap(item);
  };

  const onApplyProduct = item => {
    handleSelectProduct(item);
  };

  const multiFilterHeaderRender = dropdownProps => (
    <>
      <PortfolioTreeContainer>
        {activeRoadmapEntity && (
          <>
            <Link isLink onClick={onApplyPortfolio}>
              {PORTFOLIO_LABEL}
            </Link>
            <Separator>\</Separator>
          </>
        )}
        {isLevel2OrLevel3(activeRoadmapEntity) && (
          <>
            <Link isLink onClick={() => onApplyRoadmap(activeRoadmapEntity.parentRoadmap)}>
              {activeRoadmapEntity.parentRoadmap.title}
            </Link>
            <Separator>\</Separator>
          </>
        )}
        {isLevel3(activeRoadmapEntity) && (
          <>
            <Link isLink onClick={() => onApplyProduct(activeRoadmapEntity.parentProduct)}>
              {activeRoadmapEntity.parentProduct.title}
            </Link>
            <Separator>\</Separator>
          </>
        )}
        <Selector {...dropdownProps} placeholder={PORTFOLIO_LABEL} />
      </PortfolioTreeContainer>
    </>
  );

  return (
    <PageHeaderFilters
      {...props}
      showDisplayLayer={false}
      showFilterIcon={false}
      showResultsCounter={false}
      multiFilterHeaderRender={multiFilterHeaderRender}
      onApplyQuickFilter={onApplyQuickFilter}
    />
  );
};

export default PortfolioOverviewHeaderFilters;
