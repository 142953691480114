import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { diff } from 'deep-object-diff';

import { getOrganizationSystemFieldsNames } from 'store/organization';

import {
  fetchPortfolioOverviewHistory,
  fetchPortfolioOverviewHistoryNextPage,
  selectIsLoadingHistory,
  selectPortfolioOverviewHistory,
  selectPortfolioOverviewHistoryNextPage,
} from '../../../store';

const DESCRIPTION_FIELD = 'description';

const PortfolioOverviewHistory = ({ portfolioOverviewId }) => {
  const dispatch = useDispatch();

  const portfolioOverviewHistory = useSelector(state => selectPortfolioOverviewHistory(state, portfolioOverviewId));
  const portfolioOverviewHistoryNextPage = useSelector(state =>
    selectPortfolioOverviewHistoryNextPage(state, portfolioOverviewId),
  );
  const systemFields = useSelector(getOrganizationSystemFieldsNames);
  const isLoadingHistory = useSelector(selectIsLoadingHistory);
  const hasNext = !!portfolioOverviewHistoryNextPage;

  const fetchNextPage = () => {
    if (hasNext) {
      dispatch(fetchPortfolioOverviewHistoryNextPage(portfolioOverviewId, portfolioOverviewHistoryNextPage));
    }
  };

  useEffect(() => {
    if (portfolioOverviewId) {
      dispatch(fetchPortfolioOverviewHistory(portfolioOverviewId));
    }
  }, [portfolioOverviewId]);

  // any change, either layout config or description widget, will result on a revision with the state (JSON) field changed
  // we need to distinguish when the change was originated by a description change or not
  const diffResult = useMemo(() => {
    return portfolioOverviewHistory.reduce((acc, changes) => {
      const realChange = diff(changes?.prevValue, changes?.newValue);

      if (realChange?.[DESCRIPTION_FIELD]) {
        return [
          ...acc,
          {
            ...changes,
            changeField: DESCRIPTION_FIELD,
            newValue: realChange?.[DESCRIPTION_FIELD],
            prevValue: changes?.prevValue?.[DESCRIPTION_FIELD],
          },
        ];
      }
      return acc;
    }, []);
  }, [portfolioOverviewHistory]);

  return {
    portfolioOverviewHistory: diffResult,
    hasNext,
    systemFields,
    fetchNextPage,
    isLoadingHistory,
  };
};

export default PortfolioOverviewHistory;
