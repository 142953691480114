import React from 'react';
import { toast } from 'react-toastify';

import IdeaCreatedSuccessMessage from 'components/IdeaCreatedSuccessMessage';

import { makeSelectFilteredProjectsByRoadmapForPage } from 'store/filters/selectors';
import { openProjectLightbox } from 'store/projectLightbox';
import { CREATE_PROJECT_FULFILLED } from 'store/projects';
import { GLOBAL_FILTER } from 'constants/filters';

const GOALS_URL = '/goals/';
const METRICS_URL = '/metrics/';

const isNotPortfolioPage = path => [GOALS_URL, METRICS_URL].find(nonPortfolioPath => path.includes(nonPortfolioPath));

const selectFilteredProjectsForPage = makeSelectFilteredProjectsByRoadmapForPage();

export default ({ dispatch, getState }) =>
  next =>
  action => {
    next(action);

    if (action.type === CREATE_PROJECT_FULFILLED && !action.realtime) {
      const projects = selectFilteredProjectsForPage(getState(), GLOBAL_FILTER, true, action.payload.layer);
      const projectIsNotVisible = !projects.map(p => p.id).includes(action.payload.id);
      const showToast = projectIsNotVisible || isNotPortfolioPage(window.location.pathname);

      if (showToast) {
        toast(
          <IdeaCreatedSuccessMessage idea={action.payload} onClick={() => dispatch(openProjectLightbox(+action.payload.id))} />,
        );
      }
    }
  };
