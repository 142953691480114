import { UPDATE_PORTFOLIO_REPORT_STATE } from './types';

export const updateState = state => {
  return {
    payload: state,
    type: UPDATE_PORTFOLIO_REPORT_STATE,
  };
};

export const summaryActions = {
  updateState,
};
