import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defaultTo, isNil } from 'ramda';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import FluidField from 'design-system/molecules/FluidField/index';
import HealthSelect from 'design-system/molecules/HealthSelect/index';

import { getProjectHealthLabel } from 'utils/projects/healthUtils';
import { AUTO_TYPE, MANUAL_TYPE } from 'constants/projects';

const defaultToUndefined = defaultTo('undefined');

const FluidHealthField = props => {
  const { selectedProject, value, statusColorAuto } = props;

  const currentStatusColor = useMemo(() => {
    if (isNil(selectedProject)) {
      return defaultToUndefined(value);
    }

    return defaultToUndefined(selectedProject.status_color);
  }, [selectedProject, value]);

  const currentStatusColorAuto = useMemo(() => {
    if (isNil(selectedProject)) {
      return statusColorAuto === false ? MANUAL_TYPE : AUTO_TYPE;
    }

    return selectedProject.status_color_auto === false ? MANUAL_TYPE : AUTO_TYPE;
  }, [statusColorAuto, selectedProject?.status_color_auto, statusColorAuto]);

  return (
    <FluidField
      {...props}
      disableEdit={currentStatusColorAuto === AUTO_TYPE || props.disableEdit}
      editorRenderer={({ value, save, cancel, disableEdit: inheritedDisabledEdit }) => {
        return (
          <HealthContainer>
            <StyledHealthSelect
              cancel={cancel}
              disableEdit={inheritedDisabledEdit}
              getHealthLabel={getProjectHealthLabel}
              save={save}
              value={currentStatusColor}
            />
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  disabled={props.disableEdit}
                  checked={currentStatusColorAuto === MANUAL_TYPE}
                  onChange={() => props.changeStatusColorType(currentStatusColorAuto === AUTO_TYPE ? MANUAL_TYPE : AUTO_TYPE)}
                />
              }
              onClick={e => e.stopPropagation()}
              label="Manual"
              labelPlacement="start"
              style={{ maxHeight: 22 }}
            />
          </HealthContainer>
        );
      }}
    />
  );
};

const HealthContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHealthSelect = styled(HealthSelect)`
  div[role='button'] {
    padding-right: 0;
  }
`;

FluidHealthField.propTypes = {
  ...FluidField.propTypes,
  milestoneDisabled: PropTypes.bool,
  hasHierarchy: PropTypes.bool,
};

export default FluidHealthField;
