import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from '@material-ui/core/Input';

import { isNil, equals } from 'ramda';

import FluidField from 'design-system/molecules/FluidField/index';
import MetadataFluidField from 'design-system/molecules/MetadataFluidField/index';
import RoadmapMetadataSelect from 'design-system/atoms/RoadmapMetadataSelect/index';


const FluidRoadmapMetadataSelectField = props => {
  const { color, disableEdit, label, onChange, options = [], addNew, disableSaveEmptyOption, withEllipsis } = props;

  const handleClose = (e, cancel) => {
    cancel();
  };

  const handleSave = (e, save) => {
    if (e.defaultPrevented) {
      return;
    }

    const value = isNil(e.target.value) ? '' : e.target.value;

    const cantBeSaved = equals(value, '') && !!disableSaveEmptyOption;

    if (cantBeSaved) return;

    save(value);
  };

  const handleInputChange = (e, save) => {
    const { value } = e.target;

    if (value === '') {
      handleSave(e, onChange);
    } else {
      e.preventDefault();
    }
  };

  return (
    <MetadataFluidField
      {...props}
      renderer={(value, { placeholder } = {}) => (
        <ColorCodedInput
          disableUnderline
          type="text"
          value={value}
          onChange={handleInputChange}
          readOnly={disableEdit}
          color={color}
          withEllipsis={withEllipsis}
          placeholder={placeholder}
        />
      )}
      editorRenderer={({ isEditing, value, save, cancel, disableEdit, placeholder }) => (
        <RoadmapMetadataSelect
          disableEdit={disableEdit}
          handleClose={e => handleClose(e, cancel)}
          handleSave={e => handleSave(e, save)}
          isEditing={isEditing}
          label={label}
          options={options}
          value={value}
          addNew={addNew}
          withEllipsis={withEllipsis}
          placeholder={placeholder}
        />
      )}
    />
  );
};

const ColorCodedInput = styled(Input)`
  &&&&& {
    ${({ color }) => color && `color: ${color};`}
  }

  ${({ withEllipsis }) =>
    withEllipsis &&
    `
    input {
      max-width: 85%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`;

FluidRoadmapMetadataSelectField.propTypes = {
  ...FluidField.propTypes,
  metadata: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export default FluidRoadmapMetadataSelectField;
