// Dragonboat dependnecies
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import getSystemFieldName from 'utils/getSystemFieldName';

export const generateDataMapping = (organization, systemFields, hasExternalKeyFields, customFields, selectedImportLayer) => {
  const customFieldsEntries = customFields.map(cf => ({
    field: cf.key,
    displayTitle: cf.titleWithSuffix,
    displayHelpText: false,
    required: false,
    col: null,
  }));

  const parentFields = {
    [INITIATIVE_LAYER]: 'betTitle',
    [IDEA_LAYER]: 'initiativeTitle',
  };

  const parentField = parentFields[selectedImportLayer];

  const topLayer = organization.has_bet ? BET_LAYER : INITIATIVE_LAYER;

  const hasParent = organization?.has_hierarchy && parentField && topLayer !== selectedImportLayer;

  return [
    {
      field: 'title',
      displayTitle: 'Title',
      displayHelpText: 'required',
      required: true,
      col: null,
    },
    ...(hasParent
      ? [
          {
            field: parentField,
            displayTitle: 'Parent',
            col: null,
          },
        ]
      : []),
    {
      field: 'ownerName',
      displayTitle: 'Owner',
      col: null,
    },
    {
      field: 'phaseTitle',
      displayTitle: getSystemFieldName('phase', systemFields),
      col: null,
    },
    {
      field: 'roadmapTitle',
      displayTitle: getSystemFieldName('roadmap', systemFields),
      col: null,
    },
    ...(organization && organization.has_products
      ? [
          {
            field: 'product1Title',
            displayTitle: getSystemFieldName('product1', systemFields),
            title: dataMapping =>
              !dataMapping.some(col => col.field === 'roadmapTitle' && col.col) ? 'Must map roadmap first' : '',
            disabled: dataMapping => !dataMapping.some(col => col.field === 'roadmapTitle' && col.col),
            col: null,
          },
        ]
      : []),
    {
      field: 'timeframeTitle',
      displayTitle: getSystemFieldName('timeframe', systemFields),
      col: null,
    },
    {
      field: 'objectiveTitle',
      displayTitle: getSystemFieldName('objective', systemFields),
      col: null,
    },
    ...(organization && organization.has_key_results
      ? [
          {
            field: 'keyResult1Title',
            displayTitle: getSystemFieldName('keyResult1', systemFields),
            title: dataMapping =>
              !dataMapping.some(col => col.field === 'objectiveTitle' && col.col) ? 'Must map objective first' : '',
            disabled: dataMapping => !dataMapping.some(col => col.field === 'objectiveTitle' && col.col),
            col: null,
          },
        ]
      : []),
    {
      field: 'themeTitle',
      displayTitle: getSystemFieldName('theme', systemFields),
      col: null,
    },
    {
      field: 'categoryTitle',
      displayTitle: getSystemFieldName('category', systemFields),
      col: null,
    },
    {
      field: 'priorityTitle',
      displayTitle: getSystemFieldName('priority', systemFields),
      col: null,
    },
    {
      field: 'details',
      displayTitle: 'Details',
      displayHelpText: 'multi-line text',
      col: null,
    },
    {
      field: 'links',
      displayTitle: 'Links',
      displayHelpText: 'multi-line text',
      col: null,
    },
    // TODO: Dragonboat project notes field is deprecated and should be removed from project field mapping
    {
      field: 'notes',
      displayTitle: 'Notes',
      displayHelpText: 'multi-line text',
      col: null,
    },
    {
      field: 'estimated_start_date',
      displayTitle: 'Target start date',
      displayHelpText: 'timestamp',
      col: null,
      parseToCorrectType: value => new Date(value).getTime() || null,
    },
    {
      field: 'deadline',
      displayTitle: 'Target end date',
      displayHelpText: 'timestamp',
      col: null,
      parseToCorrectType: value => new Date(value).getTime() || null,
    },
    {
      field: 'resourceTeamTitle',
      displayTitle: getSystemFieldName('team', systemFields),
      col: null,
    },
    {
      field: 'benefit1',
      displayTitle: 'Strategic Benefit (0-5)',
      col: null,
    },
    {
      field: 'benefit2',
      displayTitle: 'Urgency (0-5)',
      col: null,
    },
    {
      field: 'benefit3',
      displayTitle: 'Frequency (0-5)',
      col: null,
    },
    {
      field: 'cost1',
      displayTitle: 'Cost (0-5)',
      col: null,
    },
    {
      field: 'reach_score',
      displayTitle: 'Reach Score',
      col: null,
    },
    {
      field: 'impact_score',
      displayTitle: 'Impact Score',
      col: null,
    },
    {
      field: 'confidence_score',
      displayTitle: 'Confidence Score',
      col: null,
    },
    {
      field: 'effort_score',
      displayTitle: 'Effort Score',
      col: null,
    },
    {
      field: 'status_color',
      displayTitle: 'Health',
      col: null,
    },
    {
      field: 'tags',
      displayTitle: getSystemFieldName('tag', systemFields),
      col: null,
      parseToCorrectType: value => (value ? value.split(', ').map(val => ({ title: val.trim() })) : undefined),
    },
    ...customFieldsEntries,
    ...(hasExternalKeyFields
      ? [
          {
            field: 'external_key',
            displayTitle: 'External Key',
            col: null,
          },
        ]
      : []),
    ...(hasExternalKeyFields && hasParent
      ? [
          {
            field: 'external_parent_key',
            displayTitle: 'External Parent Key',
            col: null,
          },
        ]
      : []),
    ...(hasExternalKeyFields
      ? [
          {
            field: 'createdByEmail',
            displayTitle: 'Created by',
            col: null,
            displayHelpText: 'email',
          },
        ]
      : []),
  ];
};
