import React, { Component } from 'react';
import { func, bool } from 'prop-types';
import { equals, not } from 'ramda';
import csv from 'csv-js';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import FileUpload from '@material-ui/icons/CloudUploadOutlined';
import CloseButton from 'components/DialogTitle/CloseButton';

import LightBox from 'components/LightBox';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import Autocomplete from 'design-system/atoms/Autocomplete/index';

import { materialColorsAlt } from 'design-system/themes/default';
import useSinglePurposeSocket from 'hooks/useSinglePurposeSocket';

import 'styles/fileUpload.css';

import { toMb } from 'utils/fileSizeUtils';
import { MAX_FILE_SIZE_BYTES } from 'constants/common';

const IMPORT_IN_PROCESS_MESSAGE = 'Importing now. Please check back in a few minutes';

csv.RELAXED = true;

const hoc = Component => {
  return React.forwardRef((props, ref) => {
    const socket = useSinglePurposeSocket();

    return <Component {...props} ref={ref} socket={socket} />;
  });
};

class ImportWidget extends Component {
  static propTypes = {
    import: func.isRequired,
    isAsyncImport: bool,
  };

  static defaultPropTypes = { isAsyncImport: false };

  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      showLightboxClose: true,
      inputValue: '',
      columns: [],
      rows: [],
      dataMapping: props.dataMapping,
      fieldSelections: {},
    };

    this.readFile = this.readFile.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.import = this.import.bind(this);
    this.showLightbox = this.showLightbox.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (not(equals(prevProps.dataMapping, this.props.dataMapping))) {
      this.setState({
        dataMapping: this.props.dataMapping.map(data => ({
          ...data,
          col: this.state.fieldSelections[data.field] || null,
        })),
      });
    }
  }

  handleClose() {
    this.setState({ dialogOpen: false });
  }

  readFile() {
    const reader = new FileReader();
    const file = this.fileInput.files[0];

    if (!file || !/.*.csv$/i.exec(file.name)) {
      return this.showLightbox('Please upload only .csv file');
    }

    if (file?.size > MAX_FILE_SIZE_BYTES) {
      return this.showLightbox(`File size exceeded. Only ${toMb(MAX_FILE_SIZE_BYTES)} size allowed.`);
    }

    reader.onerror = err => {
      this.showLightbox('The file import failed. Please check your data or file format (csv only please).');
    };
    reader.onload = (
      csv => e =>
        this.handleFileRead(e.target.result)
    )(file);
    reader.readAsText(file);
  }

  showLightboxImportInProcess = () => {
    this.showLightbox(IMPORT_IN_PROCESS_MESSAGE);
  };

  showLightbox(content, hideLightboxClose) {
    this.setState({
      infoDialogOpened: true,
      showLightboxClose: !hideLightboxClose,
      content,
    });
  }

  closeLightbox() {
    this.setState({ infoDialogOpened: false });
  }

  handleFileRead(text) {
    try {
      const parsed = csv.parse(text);
      const columns = parsed[0].map(columnTitle => String(columnTitle));
      const rows = parsed.slice(1);

      this.setState({ columns, rows, dialogOpen: true, inputValue: '' });
    } catch (e) {
      this.showLightbox('The file import failed. Please check your data or file format (csv only please).');
    }
  }

  async import() {
    if (!this.state.rows) {
      return;
    }

    const realRows = this.state.rows
      .map((row, index) => {
        const finalRow = {};

        for (let i = 0; i < this.state.dataMapping.length; i++) {
          const column = this.state.dataMapping[i];

          const keyMapped = this.getColIndexByMappedField(column.field);

          if (keyMapped >= 0) {
            finalRow[column.field] = column.parseToCorrectType ? column.parseToCorrectType(row[keyMapped]) : row[keyMapped];
          }

          // do not import rows that do not have required columns filled
          if (column.required && !finalRow[column.field]) {
            return null;
          }
        }

        return finalRow;
      })
      .filter(r => r);

    const roomId = this.props.socket.join();

    this.props.socket.subscribe(
      ({ progress, error }) => {
        if (error) {
          return this.showLightbox('Please try to import again. If this issue persists, please contact help@dragonboat.io');
        }

        if (progress === 100) {
          this.showLightbox('Import was completed successfully.');
        } else {
          this.showLightbox(`${progress}%`, true);
        }
      },
      { messageType: `message-${roomId}` },
    );

    this.props.import(realRows, roomId);
    if (this.props.isAsyncImport) this.showLightboxImportInProcess();
    this.handleClose();
  }

  getColIndexByMappedField(field) {
    const data = this.state.dataMapping.find(d => d.field === field);

    if (data) {
      return data.col ? this.state.columns.findIndex(col => col === data.col) : null;
    }
    return null;
  }

  getMapValue(field) {
    const mapping = this.state.dataMapping.find(d => d.field === field);

    if (mapping) {
      return mapping.col || '';
    }
    return '';
  }

  setMapValue(col, field) {
    if (!col) {
      const dataMapping = this.state.dataMapping.slice();
      const mapping = dataMapping.find(d => d.field === field);

      if (mapping) {
        mapping.col = null;
      }
      return this.setState({ dataMapping, fieldSelections: { ...this.state.fieldSelections, [mapping.field]: null } });
    }
    const dataMapping = this.state.dataMapping.slice();
    const mapping = dataMapping.find(d => d.field === field);

    mapping.col = col;

    this.setState({ dataMapping, fieldSelections: { ...this.state.fieldSelections, [mapping.field]: col } });
  }

  renderColOptions(field, disabled, title) {
    return (
      // <Select
      //   value={this.getMapValue(field)}
      //   onChange={e => this.setMapValue(e.target.value, field)}
      //   fullWidth
      // >
      //   <MenuItem key={0} value={null} />
      //   {this.state.columns && this.state.columns.map(col => <MenuItem key={col} value={col}>{col}</MenuItem>)}
      // </Select>
      <Autocomplete
        title={title}
        suggestions={this.state.columns}
        value={this.getMapValue(field)}
        onValueChange={value => this.setMapValue(value, field)}
        disabled={disabled}
      />
    );
  }

  _checkCanImport = () => {
    let canImport = true;

    this.state.dataMapping.forEach(entry => {
      if (entry.required && !entry.col) canImport = false;
    });

    return canImport;
  };

  render() {
    return (
      <div>
        {!this.props.hideButton && (
          <ButtonIcon
            className="fileUpload"
            size="small"
            color="default"
            disableRipple
            title="Import"
            onClick={() => this.fileInput.click()}
          >
            <FileUpload />
          </ButtonIcon>
        )}
        <input
          title="Import from .csv file"
          type="file"
          className="upload"
          accept={this.props.accept}
          ref={input => {
            this.fileInput = input;
          }}
          value={this.state.inputValue}
          onChange={this.readFile}
          style={{ display: 'none' }}
        />

        <LightBox
          isOpen={this.state.infoDialogOpened}
          onClose={this.closeLightbox}
          content={this.state.content}
          dataCy="completed-import-dialog"
          actions={
            this.state.showLightboxClose && (
              <React.Fragment>
                <Button onClick={this.closeLightbox}>close</Button>
              </React.Fragment>
            )
          }
        />

        <Dialog open={this.state.dialogOpen} onClose={this.handleClose} maxWidth="md" fullWidth>
          <CloseButton onClick={this.handleClose} />
          <DialogTitle>
            <span style={{ color: materialColorsAlt.darkerGray }}>{this.props.title || 'Data field mapping'} </span>
            {this.props.titleUrl && (
              <span style={{ fontSize: '0.8rem' }}>
                <a href={this.props.titleUrl} target="_blank" rel="noopener noreferrer">
                  {this.props.titleUrlMessage || '(help here)'}
                </a>
              </span>
            )}
          </DialogTitle>
          <DialogContent>
            <div style={{ minHeight: '40vh' }}>
              {this.props.instructions}
              {this.state.dataMapping &&
                this.state.dataMapping.map(data => (
                  <Grid container spacing={8} key={data.field} style={{ alignItems: 'center' }}>
                    <Grid item xs={1} />
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                      {`${data.displayTitle} `}
                      {data.displayHelpText && (
                        <span>
                          (
                          <em
                            style={{
                              fontStyle: 'italic',
                              opacity: 0.8,
                              color: data.required ? materialColorsAlt.red : 'initial',
                            }}
                          >
                            {data.displayHelpText}
                          </em>
                          )
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      {this.renderColOptions(
                        data.field,
                        data.disabled ? data.disabled(this.state.dataMapping) : false,
                        data.title ? data.title(this.state.dataMapping) : '',
                      )}
                    </Grid>
                  </Grid>
                ))}
            </div>
          </DialogContent>
          <DialogActions>
            <Button disabled={!this._checkCanImport()} onClick={this.import} color="primary">
              Import
            </Button>
            <Button onClick={this.handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default hoc(ImportWidget);
