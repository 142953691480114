import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateState } from 'store/portfolioReport';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const lsState = useSelector(state => state.portfolioReport);
    const _udpateState = data => dispatch(updateState(data));
    const systemFields = useSelector(state => state.organization.organization.system_fields_name);
    const hasHierarchy = useSelector(state => state.organization.organization.has_hierarchy);
    const hasBet = useSelector(state => state.organization.organization.has_bet);
    const hasKeyResults = useSelector(state => state.organization.organization.has_key_results);
    const hasProducts = useSelector(state => state.organization.organization.has_products);

    return (
      <Component
        {...props}
        lsState={lsState}
        updateState={_udpateState}
        systemFields={systemFields}
        hasHierarchy={hasHierarchy}
        hasBet={hasBet}
        hasKeyResults={hasKeyResults}
        hasProducts={hasProducts}
      />
    );
  };
};

export default componentHOC;
