import { createCachedSelector } from 're-reselect';

import { filterActiveItens } from 'utils/index';
import sortByRank from 'utils/sortByRank';

import { BASE_ROW_HEIGHT } from '../helpers/constants';

const emptyArray = [];

export const getPersonasGridData = state => state?.personasGrid?.data || emptyArray;

export const selectGridRowHeight = state => state?.personasGrid?.rowHeight || BASE_ROW_HEIGHT;

export const selectHideArchived = state => state?.personasGrid?.hideArchived || false;

export const selectPersonasGridData = createCachedSelector(
  getPersonasGridData,
  selectHideArchived,
  (personasGridData, hideArchived) => {
    return hideArchived ? personasGridData.filter(filterActiveItens).sort(sortByRank) : personasGridData;
  },
)((state, hideArchived) => `personasGrid-${hideArchived}`);
