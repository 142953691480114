import { createSelector } from 'reselect';
import { defaultTo } from 'ramda';

import { getUsers } from 'store/users/selectors';
import { commentsAdapter } from './helpers/adapters';
import { getUpdatedByDisplayName } from 'utils/index';

const findUser = (users, id) => users.find(user => user.id === id);

const getState = state => state.metricsDialog;

const getEnrichedMetric = (metric, users) => ({
  ...metric,
  ...(metric.owner_id ? { owner: findUser(users, metric.owner_id) } : {}),
  ...(metric.updated_by_id ? { updatedBy: findUser(users, metric.updated_by_id) } : {}),
  metricValues: (metric.metricValues || []).map(metricValue => ({
    ...metricValue,
    ...(metricValue.updated_by_id
      ? { updatedBy: findUser(users, metricValue.updated_by_id) }
      : { updatedBy: { first_name: getUpdatedByDisplayName() } }),
  })),
});

export const selectMetric = createSelector(
  state => getState(state).metric,
  state => getUsers(state),
  (metric, users) => getEnrichedMetric(metric, users),
);

const defaultToCommentsInitialState = defaultTo(commentsAdapter.getInitialState());

export const commentsSelector = commentsAdapter.getSelectors(state => {
  return defaultToCommentsInitialState(state.comments);
});

export const selectMetricComments = state => commentsSelector.selectAll(getState(state));

export const selectMetricCommentsMetadata = state => getState(state)?.commentsMetadata;
