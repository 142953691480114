import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MoreHoriz from '@material-ui/icons/MoreHoriz';
import AddCircle from '@material-ui/icons/AddCircleOutline';

import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Button from '@material-ui/core/Button';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiMenuList from '@material-ui/core/MenuList';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import EditableTextNewLayoutComponent from 'design-system/organisms/EditableTextNewLayout/EditableTextNewLayoutComponent';
import { Divider } from '@material-ui/core';

const DEFAULT_TITLE = 'Add New Filter';
const MENU_LIST_MAX_HEIGHT = 360;

const exist = Boolean;

const FilterList = ({
  label,
  anchorEl,
  menu,
  editableItem,
  hasHeader,
  title = DEFAULT_TITLE,
  renderHeader,
  renderSubMenu,
  onClick,
  onSetAnchorEl,
  onAddNewFilter,
  onUpdateMenu,
  onSetEditableItem,
  defaultFilters = [],
}) => {
  const [position, setPosition] = useState({ top: 0 });

  const open = exist(anchorEl);

  const hasMenu = menu && exist(menu.length);
  const hasDefaultFilters = exist(defaultFilters?.length);

  const isEditingItem = item => editableItem && editableItem.id === item.id;

  const _handleClick = event => {
    onSetAnchorEl(event.currentTarget);
  };

  const _handleClickItem = item => {
    if (!editableItem) {
      onClick(item);
    } else if (editableItem.id !== item.id) {
      onSetEditableItem(null);
    }
  };

  const _handleClose = () => {
    onSetAnchorEl(null);
  };

  const _handleChangeItem = (item, newLabel) => {
    onSetEditableItem(null);

    if (!newLabel) return;
    onUpdateMenu({
      ...item,
      label: newLabel,
    });
  };

  const _handleHover = useCallback(
    event => {
      const targetElement = event.target;
      const targetRect = targetElement.getBoundingClientRect();

      const menuItemTop = targetRect.top;
      const menuItemHeight = targetRect.height;

      setPosition({
        top: menuItemTop - menuItemHeight,
      });
    },
    [setPosition],
  );

  React.useEffect(() => {
    if (!open && editableItem) {
      onSetEditableItem(null);
    }
  }, [open]);

  return (
    <Wrapper>
      {hasHeader && (
        <Header>
          {label ? (
            <Button
              aria-controls={open ? 'composition-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={_handleClick}
            >
              {label}
            </Button>
          ) : (
            renderHeader()
          )}
        </Header>
      )}
      <Popper open={open} anchorEl={anchorEl} role={undefined} placement="bottom-start" transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={_handleClose}>
                <MenuListWithScroll>
                  {menu &&
                    menu.map(item => (
                      <MenuItem key={item.id} onMouseEnter={_handleHover}>
                        <MenuItemContainer onClick={() => _handleClickItem(item)}>
                          {isEditingItem(item) && (
                            <EditableTextNewLayoutComponent
                              editable
                              value={item.label}
                              forbidenValues={menu.map(m => m.label)}
                              onChange={label => _handleChangeItem(item, label)}
                            />
                          )}
                          {!isEditingItem(item) && <ListItemText>{item.label}</ListItemText>}
                          {renderSubMenu && (
                            <ListItemIcon hover>
                              <MoreHoriz fontSize="small" />
                            </ListItemIcon>
                          )}
                        </MenuItemContainer>
                        {renderSubMenu && (
                          <MenuChildContainer style={{ top: `${position.top}px` }}>
                            <Paper>
                              <ChildrenContainer>{renderSubMenu(item)}</ChildrenContainer>
                            </Paper>
                          </MenuChildContainer>
                        )}
                      </MenuItem>
                    ))}
                </MenuListWithScroll>
                {hasDefaultFilters && hasMenu && <Divider />}
                {hasDefaultFilters && (
                  <MenuList>
                    {defaultFilters.map(filter => (
                      <MenuItem key={filter.id} onClick={() => _handleClickItem(filter)}>
                        <MenuItemContainer>
                          <ListItemText>{filter.label}</ListItemText>
                        </MenuItemContainer>
                      </MenuItem>
                    ))}
                  </MenuList>
                )}
                <SaveFilterRow onClick={onAddNewFilter}>
                  <ListItemIcon>
                    <AddCircle fontSize="small" />
                  </ListItemIcon>
                  <ListItemText uppercase small>
                    {title}
                  </ListItemText>
                </SaveFilterRow>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &&&& {
  }
`;

const Header = styled.div`
  &&&& {
  }
`;

const MenuList = styled(MuiMenuList)`
  &&&& {
    border-radius: 8px;
    overflow: unset;
    max-width: 300px;
  }
`;

const MenuListWithScroll = styled(MenuList)`
  &&&& {
    max-height: ${MENU_LIST_MAX_HEIGHT}px;
    overflow-y: auto;
  }
`;

const ListItemText = styled(MuiListItemText)`
  &&&& {
    margin: 0;
    padding: 0;

    ${({ uppercase }) =>
      uppercase &&
      `
      text-transform: uppercase;
    `}

    span {
      ${({ small, theme }) =>
        small ? `font-size: ${theme.typography.fontSizeSmall}px;` : `font-size: ${theme.typography.fontSize}px;`}

      line-height: unset;
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      color: ${props => props.theme.palette.text.lightGrey};
      overflow: hidden;
      word-break: break-word;
      text-overflow: ellipsis;
      padding-right: 4px;
    }
  }
`;

const ListItemIcon = styled(MuiListItemIcon)`
  &&&& {
    display: flex;
    justify-content: space-between;

    ${({ hover }) =>
      hover &&
      `
      display: none;
    `}

    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
`;

const MenuItemContainer = styled.div`
  &&&& {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr 24px;
  }
`;

const MenuChildContainer = styled.div`
  &&&& {
    position: fixed;
    left: 90%;

    padding-left: 8px;
  }
`;

const TextFieldWrap = styled.div`
  input {
    font-size: ${({ theme }) => theme.typography.fontSize}px;
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    color: ${props => props.theme.palette.newLayout.background.disabled};

    /* &:disabled {
      color: ${props => props.theme.palette.newLayout.background.disabled};
    } */
  }
`;

const MenuItem = styled(MuiMenuItem)`
  &&&& {
    position: relative;
    overflow: unset;

    height: 18px;

    padding: 10px 5px 10px 16px;

    ${MenuChildContainer} {
      display: none;
    }

    &:hover {
      ${MenuChildContainer} {
        display: block;
      }

      ${MenuItemContainer} {
        ${TextFieldWrap} {
          input[disabled] {
            color: ${props => props.theme.palette.newLayout.background.primary};
          }
        }

        ${ListItemIcon} {
          display: block;

          svg {
            fill: ${props => props.theme.palette.newLayout.background.primary};
          }
        }
      }
    }
  }
`;

const ChildrenContainer = styled.div`
  &&&& {
    min-width: 200px;
    max-width: 250px;
  }
`;

const SaveFilterRow = styled.div`
  &&&& {
    display: flex;
    align-items: center;

    width: 100%;
    min-width: 200px;
    height: 100%;

    box-sizing: border-box;

    padding: 10px 16px;

    cursor: pointer;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    border-top: 1px solid #fafafa;
    box-shadow: 0px -2px 12px 0px rgb(204 204 204 / 30%);

    svg {
      path {
        &:nth-child(2) {
          fill: #131c23;
        }
      }
    }
  }
`;

FilterList.propTypes = {
  label: PropTypes.string,
  hasHeader: PropTypes.bool,
  anchorEl: PropTypes.any.isRequired,
  menu: PropTypes.arrayOf(
    PropTypes.objectOf({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.objectOf({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          label: PropTypes.string,
        }),
      ),
    }),
  ),
  renderHeader: PropTypes.func,
  renderSubMenu: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  onSetAnchorEl: PropTypes.func.isRequired,
  onAddNewFilter: PropTypes.func.isRequired,
  onUpdateMenu: PropTypes.func.isRequired,
  onSetEditableItem: PropTypes.func.isRequired,
};

export default FilterList;
