import { propOr } from 'ramda';
import { toast } from 'react-toastify';
import { DEFAULT_REQUEST_FAILED_ERROR_MESSAGE } from 'store/utils/throwRequestError';

export const handleSocketResponse = data => {
  const success = propOr(false, 'success')(data);
  const payload = data?.data;

  if (!success) {
    const errorMessage = payload || DEFAULT_REQUEST_FAILED_ERROR_MESSAGE;

    toast(errorMessage);
    throw new Error(errorMessage);
  }

  return { data: payload };
};
