import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FilterIcon from 'design-system/atoms/FilterIcon/index';
import UnfilterIcon from 'design-system/atoms/UnfilterIcon/index';
import MultiFilterSelector from 'design-system/molecules/MultiFilterSelector/index';


import useFilterOptionsForPlanBasedOn from './useFilterOptionsForPlanBasedOn';

const LABEL = 'Plan based on';

const PlanBasedOnFilterSelector = ({ pageId }) => {
  const buttonRef = useRef();

  const [multiFilterAnchorEl, setMultiFilterAnchorEl] = useState(null);

  const handleOpenPopper = () => {
    setMultiFilterAnchorEl(buttonRef.current);
  };

  const { menu, handleSelectOption, selectedOptions, hasFilterActive, handleClearFilter } = useFilterOptionsForPlanBasedOn(
    pageId,
  );

  return (
    <Wrapper>
      <Label onClick={handleOpenPopper}>{LABEL}</Label>
      <FilterUnfilterButton ref={buttonRef} onClick={handleOpenPopper}>
        {hasFilterActive ? (
          <UnfilterIcon width={16} height={16} color="primary" />
        ) : (
          <FilterIcon width={16} height={16} color="primary" />
        )}
      </FilterUnfilterButton>

      <MultiFilterSelector
        anchorEl={multiFilterAnchorEl}
        onSetAnchorEl={setMultiFilterAnchorEl}
        menu={menu}
        selectedItems={selectedOptions}
        onSelectItem={handleSelectOption}
        onClearFilter={handleClearFilter}
        ignoreMultiFilterClearButton
      />
    </Wrapper>
  );
};

PlanBasedOnFilterSelector.propTypes = {
  pageId: PropTypes.string.isRequired,
};

const Wrapper = styled.div`
  margin: 0 10px;
  display: flex;
  align-items: center;
`;

const FilterUnfilterButton = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

const Label = styled.label`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text.grey};
  font-size: ${props => props.theme.typography.fontSize}px;
`;

export default PlanBasedOnFilterSelector;
