import { useCallback } from 'react';
import { either, equals, isNil } from 'ramda';
import { ROADMAP, SUB_ROADMAP, DUPLICATED_ERROR_CODE } from 'config';

import useSystemFields from 'hooks/useSystemFields';
import useGenericErrorDialogContext from 'hooks/useGenericErrorDialog';
import { focusOnCell } from 'design-system/molecules/AgGridReact-New/helpers';
import { useCommonGridEventHandlers } from 'design-system/molecules/AgGridReact-New/hooks';
import { CUSTOM_FIELDS } from 'store/grids/constants';
import { getDuplicatedToastErrorMessage } from 'utils';

import { TITLE_FIELD, FIELD_TYPE } from '../helpers/constants';

const isNull = either(isNil, equals('null'));

const CUSTOM_FIELDS_SETTINGS_PAGE = `/settings/${CUSTOM_FIELDS}`;

const useCustomFieldsGridEventHandlers = ({
  customFields,
  updateCustomFieldById,
  createCustomField,
  removeUnsavedCustomFields,
}) => {
  const [getSystemFieldName] = useSystemFields();

  const { openGenericErrorDialog } = useGenericErrorDialogContext();

  const createDuplicatedErrorNotification = newValue => {
    const message = getDuplicatedToastErrorMessage(
      getSystemFieldName(CUSTOM_FIELDS),
      newValue,
      getSystemFieldName(ROADMAP),
      getSystemFieldName(SUB_ROADMAP),
      CUSTOM_FIELDS_SETTINGS_PAGE,
    );

    openGenericErrorDialog({ content: message });
  };

  const onCreateNewItemError = ({ error, newValue }) => {
    const { data } = error?.response || {};

    if (data && data.error_code === DUPLICATED_ERROR_CODE) {
      createDuplicatedErrorNotification(newValue);
    }
  };

  const {
    onCellEditingStopped,
    onCellValueChanged,
    onColumnRowGroupChanged,
    onSortChanged,
    onFilterChanged,
    onRowDragEnter,
    onRowDragMove,
    onRowDragEnd,
    onRowDragLeave,
  } = useCommonGridEventHandlers({
    settingType: CUSTOM_FIELDS,
    items: customFields,
    focusField: TITLE_FIELD,
    onCreateNewItemError,
    actions: {
      update: (id, update) => updateCustomFieldById(id, update, Boolean('data' in update)),
      save: createCustomField,
      remove: removeUnsavedCustomFields,
    },
  });

  const onRowDataUpdated = useCallback(({ api, columnApi }) => {
    focusOnCell({ api, columnApi }, FIELD_TYPE);
  }, []);

  const customOnCellValueChanged = useCallback(
    async cell => {
      const {
        data,
        colDef: { field },
        api,
        columnApi,
      } = cell;

      const { id } = data;
      const idIsNull = isNull(id);

      if (idIsNull && field === FIELD_TYPE) {
        /*
         * With auto focus field in first time is focusing on auto focus field
         * and after change that field will focus on focus field (when exists)
         */
        focusOnCell({ api, columnApi }, TITLE_FIELD);
      }

      onCellValueChanged(cell);
    },
    [onCellValueChanged],
  );

  return {
    onCellEditingStopped,
    onCellValueChanged: customOnCellValueChanged,
    onRowDataUpdated,
    onColumnRowGroupChanged,
    onSortChanged,
    onFilterChanged,
    onRowDragEnter,
    onRowDragMove,
    onRowDragEnd,
    onRowDragLeave,
  };
};

export default useCustomFieldsGridEventHandlers;
