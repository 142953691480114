import { head } from 'ramda';

import { ConditionBuilder } from '@dragonboat/dbql';

import { FILTERS_FUNCTIONS } from 'design-system/constants';
import { NOT_CONTAINS } from '@dragonboat/dbql/src/operators';

const createConditionFromNotContainsFilter = filterCondition => {
  const { field, value, op } = filterCondition;

  if (op !== FILTERS_FUNCTIONS.notContains.key) {
    throw new Error(`Operator type does not match ${FILTERS_FUNCTIONS.notContains.key}. Received: ${op}`);
  }

  // Split the value by commas and trim whitespace
  const values = value.split(',').map(val => val.trim());

  // Create a condition for each value with 'not contains' operator
  let conditionGroup = ConditionBuilder.createCondition(field.id, NOT_CONTAINS, head(values));

  for (let i = 1; i < values.length; i++) {
    const nextCondition = ConditionBuilder.createCondition(field.id, NOT_CONTAINS, values[i]);

    // Combine conditions with AND
    conditionGroup = conditionGroup.and(nextCondition);
  }

  return conditionGroup;
};

export default createConditionFromNotContainsFilter;
