import React, { useCallback, useRef } from 'react';
import { head, not, pipe, prop } from 'ramda';

import CreateOnRally from './components/CreateOnRally';
import LinkWithRally from './components/LinkWithRally';
import ProjectIntegrationDetails from './components/ProjectIntegrationDetails';
import { getRallyPortfolioItemTypeName } from 'utils/integrations/getRallyPortfolioItemTypesFromOrgIntegrationData';
import useUserIntegrations from 'hooks/useUserIntegrations';
import useAppNotifications from 'hooks/useAppNotifications';

import useIntegrations from 'hooks/useIntegrations';

import { INTEGRATIONS_KEYS, FIELD_MAPPING_DIRECTION_OPTIONS } from 'constants/integrations';

import { IntegrationWrapper } from '../utils/styles';

const ID = 'id';
const LAYER = 'layer';

const getId = prop(ID);
const getFirstProjectIntegrationId = pipe(head, getId);

const RallyIntegration = props => {
  const {
    integration,
    projectIntegrations,
    userIntegrations,
    createProjectIntegration,
    linkProjectIntegration,
    linkProjectIntegrationOutbound,
    unlinkProjectIntegration,
    createErrorMsg,
    linkErrorMsg,
    formData,
    projectId,
    isLinking,
    isCreating,
    disableActions,
  } = props;
  const hasUserIntegration = userIntegrations && userIntegrations.length > 0;
  const hasProjectIntegration = projectIntegrations && projectIntegrations.length > 0;

  const { fetchProjectIntegrations } = useIntegrations(INTEGRATIONS_KEYS.rally, integration.id);
  const { addUserIntegrationByAuthType, fetchUserIntegrations } = useUserIntegrations(INTEGRATIONS_KEYS.rally, integration.id);
  const { addNotification } = useAppNotifications();

  const layer = prop(LAYER, formData);
  const rallyWorkItemType = getRallyPortfolioItemTypeName(integration, layer);

  const settingUpUserIntegration = useRef(false);
  const setupUserIntegration = useCallback(() => {
    if (!integration || settingUpUserIntegration.current) return;

    const handleOauth2UserIntegration = async () => {
      settingUpUserIntegration.current = true;
      try {
        await addUserIntegrationByAuthType('oauth2');
        addNotification({ id: 'rally-successfully-integrated', message: 'Successfully integrated' });
      } catch (error) {
        addNotification({ id: 'rally-integration-failed', message: 'Integration failed' });
      }
      settingUpUserIntegration.current = false;

      await fetchProjectIntegrations(projectId);
      await fetchUserIntegrations();
    };

    return handleOauth2UserIntegration();
  }, [
    integration?.id,
    projectId,
    addUserIntegrationByAuthType,
    fetchUserIntegrations,
    fetchProjectIntegrations,
    addNotification,
  ]);

  const onCreateHandler = useCallback(
    selectedProjectId => {
      if (!hasUserIntegration) {
        return setupUserIntegration();
      }

      createProjectIntegration({ org_integration_id: integration.id, rallyProjectId: selectedProjectId });
    },
    [integration.id, createProjectIntegration, hasUserIntegration],
  );

  const onLinkHandler = useCallback(
    (id, ref, direction) => {
      if (!hasUserIntegration) {
        return setupUserIntegration();
      }

      if (direction === FIELD_MAPPING_DIRECTION_OPTIONS.outbound) {
        linkProjectIntegrationOutbound(id, { org_integration_id: integration.id, _ref: ref });
      } else {
        linkProjectIntegration(id, { org_integration_id: integration.id, _ref: ref });
      }
    },
    [integration.id, linkProjectIntegration, linkProjectIntegrationOutbound, hasUserIntegration],
  );

  const onUnlinkHandler = useCallback(() => {
    if (hasProjectIntegration) {
      if (!hasUserIntegration) {
        return setupUserIntegration();
      }

      unlinkProjectIntegration(getFirstProjectIntegrationId(projectIntegrations), { org_integration_id: integration.id });
    }
  }, [integration.id, hasProjectIntegration, projectIntegrations, unlinkProjectIntegration, hasUserIntegration]);

  return (
    <>
      <IntegrationWrapper>
        {not(hasProjectIntegration) && (
          <>
            <CreateOnRally
              onCreate={onCreateHandler}
              orgIntegration={integration}
              createErrorMsg={createErrorMsg}
              rallyWorkItemType={rallyWorkItemType}
              isCreating={isCreating}
              disableActions={disableActions}
              hasUserIntegration={hasUserIntegration}
              setupUserIntegration={setupUserIntegration}
            />
            <LinkWithRally
              onLink={onLinkHandler}
              linkErrorMsg={linkErrorMsg}
              rallyWorkItemType={rallyWorkItemType}
              isLinking={isLinking}
              disableActions={disableActions}
            />
          </>
        )}
        {hasProjectIntegration && (
          <ProjectIntegrationDetails
            orgIntegration={integration}
            projectIntegrations={projectIntegrations}
            onUnlink={onUnlinkHandler}
            disableActions={disableActions}
          />
        )}
      </IntegrationWrapper>
      {not(hasProjectIntegration) && <></>}
    </>
  );
};

export default RallyIntegration;
