/* eslint-disable */
/**
 * UnfilterIcon Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => {
  return (
    <SvgIcon
      style={{ width: 20, height: 20, fill: props.fill || '#007fff', ...(props.style || {}) }}
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        id="a91f0ca4-8fb7-4019-9c09-0a52e2c05754"
        d="M17,4v9.74l-4,3.28-6.2-2.26V17L3.29,12.41l10.23.8V4.44Zm-3.41.49L7.85,1V3.29L2.58,4.84,1,6.87v4.61l2.26,1V6.57Z"
      />
    </SvgIcon>
  );
};
