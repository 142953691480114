import { createSelector } from 'reselect';
import { prop } from 'ramda';

import { isLoading } from 'utils/store/thunk';

import { FETCH_SNAPSHOT_DATA } from './types';

export function getSnapshotState(state) {
  return state.snapshot;
}

export const getSnapshotTableProps = createSelector(
  state => prop('snapshotTableVisibleFields', getSnapshotState(state)),
  state => prop('showSnapshotTable', getSnapshotState(state)),
  state => prop('showSnapshotTreeView', getSnapshotState(state)),
  state => prop('expandTableItems', getSnapshotState(state)),
  (snapshotTableVisibleFields, showSnapshotTable, showSnapshotTreeView, expandTableItems) => ({
    snapshotTableVisibleFields,
    showSnapshotTable,
    showSnapshotTreeView,
    expandTableItems,
  }),
);

export const getSnapshotPageProps = createSelector(
  state => prop('selectedSnapshotField', getSnapshotState(state)),
  state => prop('hideEmptyCards', getSnapshotState(state)),
  state => prop('hideCards', getSnapshotState(state)),
  state => prop('pageSize', getSnapshotState(state)),
  state => prop('showColorLegend', getSnapshotState(state)),
  state => prop('cardsPerRow', getSnapshotState(state)),
  state => prop('visibleCardElements', getSnapshotState(state)),
  (selectedSnapshotField, hideEmptyCards, hideCards, pageSize, showColorLegend, cardsPerRow, visibleCardElements) => ({
    selectedSnapshotField,
    hideEmptyCards,
    hideCards,
    pageSize,
    showColorLegend,
    cardsPerRow,
    visibleCardElements,
  }),
);

export const getSnapshotData = createSelector(getSnapshotState, snapshotState => {
  // Fallback needed due to frontend persistence.
  return snapshotState.snapshotData || [];
});

export const getFetchSnapshotDataIsLoading = createSelector(getSnapshotState, snapshotState => {
  // Check needed due to frontend persistence
  return !snapshotState?.operations || isLoading(snapshotState?.operations, FETCH_SNAPSHOT_DATA);
});

export const getSnapshotIsMetricViewModeOn = createSelector(getSnapshotState, state => prop('metricViewModeOn', state));
export const getSnapshotAreMetricClustersVisible = createSelector(getSnapshotState, state =>
  prop('metricClustersVisible', state),
);
export const getSnapshotMetricViewModeTimePeriod = createSelector(
  getSnapshotState,
  state => prop('metricViewModeTimePeriod', state) ?? {},
);
