import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { projectActions } from 'store/projects';
import { objectiveActions } from 'store/objectives';
import { roadmapActions } from 'store/roadmaps';
import { themeActions } from 'store/themes';
import { timeframeActions } from 'store/timeframes';
import { teamActions } from 'store/teams';
import { skillActions } from 'store/skills';
import { categoryActions } from 'store/categories';
import { fetchTags } from 'store/tags';
import { fetchCustomers } from 'store/customers';
import { phaseActions } from 'store/phases';
import {
  groupByOptions,
  sumByOptions,
  durationOptions,
  allocationReportActions,
  defaultTableColumns,
} from 'store/allocationReport';
import { ALLOCATION_REPORT_PAGE } from 'constants/filters';

import AllocationReport from './AllocationReport';
import { getObjectives, selectKeyResults1 } from 'store/objectives/selectors';
import { getRoadmaps, getCorpRoadmaps, getProducts } from 'store/roadmaps/selectors';
import { getTimeframes } from 'store/timeframes/selectors';
import { getTeams } from 'store/teams/selectors';
import { getSkills } from 'store/skills/selectors';
import { getPhases } from 'store/phases/selectors';
import { getCategories } from 'store/categories/selectors';
import { getCustomersFilteredByStatus } from 'store/customers/selectors';
import { getTags } from 'store/tags/selectors';
import { getThemes } from 'store/themes/selectors';
import { getDisplayLayer, makeSelectFilteredProjectsByRoadmapForPage } from 'store/filters/selectors';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import useColorLegend from 'hooks/timeline/useColorLegend';
import useLoadProjects from 'hooks/projects/useLoadProjects';
import useBookADemoLocker from 'hooks/useBookADemoLocker';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';

import { METADATA_LEVELS } from 'constants/common';

const styles = theme => ({
  root: {
    padding: '5px 26px 5px 36px',
    outline: 'none',
  },
  mainContainer: {
    width: '100%',
    textAlign: 'center',
  },
  pieContainer: {
    width: 700,
    marginRight: 30,
    marginTop: 30,
    marginBottom: 30,
    verticalAlign: 'top',
    display: 'inline-block',
  },
  table: {
    width: 600,
    display: 'inline-block',
  },
  colorPickerContainer: {
    position: 'absolute',
    zIndex: '5',
    marginTop: '15px',
    border: '1px solid lightgrey',
    borderRadius: '6px',
  },
});

const withHooksHOC = Component => {
  return props => {
    const BookADemoLockerComponent = useBookADemoLocker(ALLOCATION_REPORT_PAGE);

    if (BookADemoLockerComponent) return <BookADemoLockerComponent />;

    const [, updateGroupColor] = useColorLegend(ALLOCATION_REPORT_PAGE);
    const [loadedProjects] = useLoadProjects(ALLOCATION_REPORT_PAGE);

    if (!loadedProjects) return <PageLoading />;

    return <Component {...props} updateGroupColor={updateGroupColor} />;
  };
};

const makeMapStateToProps = () => {
  const selectFilteredProjectsForPage = makeSelectFilteredProjectsByRoadmapForPage();

  return state => {
    const { allocationReport } = state;
    const displayLayer = getDisplayLayer(state);

    const hasKeyResults = state.organization.organization.has_key_results;
    const hasProducts = state.organization.organization.has_products;
    const systemFields = state.organization.organization.system_fields_name;

    const groupOptions = groupByOptions({ systemFields, hasProducts, hasKeyResults });
    const selectedGroupBy = allocationReport.selectedGroupBy || groupOptions.find(o => o.key === 'roadmap');
    const selectedDuration = allocationReport.selectedDuration || durationOptions[0];
    const selectedSumBy = allocationReport.selectedSumBy || sumByOptions()[0];
    const selectedDisplayOptions = allocationReport.selectedDisplayOptions || defaultTableColumns;
    const showArchivedMetadata = !allocationReport.hideArchivedData;

    const lsState = {
      ...allocationReport,
      dataType: selectedGroupBy,
      sumBy: selectedSumBy,
      duration: selectedDuration,
      selectedDisplayOptions,
    };

    return {
      projects: selectFilteredProjectsForPage(state, ALLOCATION_REPORT_PAGE, false, displayLayer),
      timeframes: getStateDataForPage(state, getTimeframes, 'timeframes', showArchivedMetadata),
      roadmaps: getStateDataForPage(state, getRoadmaps, 'roadmaps', showArchivedMetadata),
      roadmapCorps: getStateDataForPage(state, state => getCorpRoadmaps(state, false), 'roadmapsCorp', showArchivedMetadata),
      themes: getStateDataForPage(state, getThemes, 'themes', showArchivedMetadata),
      categories: getStateDataForPage(state, getCategories, 'categories', showArchivedMetadata),
      phases: getStateDataForPage(state, getPhases, 'phases'),
      objectives: getStateDataForPage(state, getObjectives, 'objectives', showArchivedMetadata),
      objectiveCorps: getStateDataForPage(
        state,
        state => getObjectives(state, false, METADATA_LEVELS.LEVEL_CORP),
        'objectivesCorp',
        showArchivedMetadata,
      ),
      teams: getStateDataForPage(state, getTeams, 'teams'),
      skills: getStateDataForPage(state, getSkills, 'skills'),
      customers: getStateDataForPage(state, getCustomersFilteredByStatus, 'customers'),
      tags: getStateDataForPage(state, getTags, 'tags'),
      categorys: getCategories(state),
      keyResult1s: selectKeyResults1(state, showArchivedMetadata),
      product1s: getProducts(state, showArchivedMetadata),
      lsState,
      filterPageId: ALLOCATION_REPORT_PAGE,
      hasKeyResults,
      hasProducts,
      systemFields,
    };
  };
};

export default compose(
  connect(makeMapStateToProps, {
    ...projectActions,
    ...objectiveActions,
    ...roadmapActions,
    ...themeActions,
    ...timeframeActions,
    ...teamActions,
    ...skillActions,
    ...categoryActions,
    ...allocationReportActions,
    ...phaseActions,
    fetchTags,
    fetchCustomers,
  }),
  withStyles(styles),
  withHooksHOC,
)(AllocationReport);
