import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import { METRICS } from 'store/grids/constants';
import { getHasAdvancedMetricReporting } from 'store/organization';

import { BaseGrid } from 'containers/Grids';
import { useSettingsGridProps, useSettingsGridEventHandlers, useSettingsGridColumns } from 'containers/Grids/SettingsGrid/hooks';
import MetricsSettingsActionsToolbar from './components/MetricsSettingsActionsToolbar';

import useMetrics from 'hooks/grid/metrics/useMetrics';
import useMetricsGrid from 'hooks/grid/metrics/useMetricsGrid';
import useMetricsGridColumns from 'hooks/grid/metrics/useMetricsGridColumns';
import useFilteredMetrics from 'hooks/useFilteredMetrics';
import useForceVisibleColumns from 'hooks/useForceVisibleColumns';
import useProjectsListLightboxContext from 'hooks/useProjectsListLightbox';
import usePermissions from 'hooks/permissions/usePermissions';
import useOpenMetricFromEntityWithMetrics from 'hooks/useOpenMetricFromEntityWithMetrics';

const NAME_FIELD = 'name';
const OWNER_FIELD = 'owner_id';
const DETAILS_FIELD = 'details';
const DATA_SOURCE = 'data_source';

const DEFAULT_VISIBLE_FIELDS = [OWNER_FIELD, DETAILS_FIELD, DATA_SOURCE];

const Metrics = ({
  users,
  hideMetadataRoadmaps,
  forceVisibleFields,
  showActionBar = true,
  showSearchBar = true,
  showSettingsToggle = true,
  customHeight,
}) => {
  const { handleOpenMetricView } = useOpenMetricFromEntityWithMetrics();

  const { openProjectsListLightbox: openProjectsListLightboxAction } = useProjectsListLightboxContext();

  const openProjectsListLightbox = metric => openProjectsListLightboxAction(metric, 'metric');

  const {
    metrics,
    addMetricWithoutSave,
    createMetric,
    updateMetricById,
    removeUnsavedMetrics,
    switchMetricsRowOrder,
    searchText,
    updateSearchText,
    isHidingArchivedMetrics,
    toggleIsHidingArchivedMetrics,
    createMetricRoadmap,
    deleteMetricRoadmap,
    bulkDeleteMetricRoadmaps,
    addMetricValue,
    updateMetricValue,
    deleteMetricValue,
    canViewMetricIntegration,
    canUpdateMetricIntegration,
    metricOrgIntegrations,
    updateMetricIntegration,
  } = useMetrics();

  const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);

  const { handleRowDrag, handleUpdateMetricById } = useMetricsGrid({
    addMetricValue,
    deleteMetricValue,
    switchMetricsRowOrder,
    updateMetricById,
    updateMetricValue,
    metricOrgIntegrations,
  });

  const { canUpdate, canCreate } = usePermissions();
  const allowActions = canUpdate(PERMISSION_RESOURCES.metric) || canCreate(PERMISSION_RESOURCES.metric);

  const permissions = {
    allowActions,
    canUpdate: useCallback(data => canUpdate(PERMISSION_RESOURCES.metric, { data }), [canUpdate]),
    canDragRows: allowActions,
  };

  const { columnDefs: metricsGridColumnDefs } = useMetricsGridColumns({
    allowActions: permissions.allowActions,
    users,
    roadmapColumn: {
      createMetricRoadmap,
      deleteMetricRoadmap,
      bulkDeleteMetricRoadmaps,
      metrics,
      hideMetadataRoadmaps,
    },
    openMetricView: hasAdvancedMetricReporting && handleOpenMetricView,
    openProjectsListLightbox,
    canViewMetricIntegration,
    canUpdateMetricIntegration,
    updateMetricIntegration,
  });

  const filteredMetrics = useFilteredMetrics(metrics, { searchText, isHidingArchivedMetrics });

  // Settings common agGrid configuration
  const settingsGridProps = useSettingsGridProps();
  const settingsGridColumns = useSettingsGridColumns({
    allowActions: permissions.allowActions,
    canUpdate: permissions.canUpdate,
    items: filteredMetrics,
    canDragRows: permissions.canDragRows,
  });
  const settingsGridEventHandlers = useSettingsGridEventHandlers({
    settingType: METRICS,
    items: metrics,
    focusField: NAME_FIELD,
    actions: {
      update: handleUpdateMetricById,
      save: createMetric,
      remove: removeUnsavedMetrics,
      drag: handleRowDrag,
    },
    forceUpdate: true,
  });

  const visibleFields = forceVisibleFields && [...DEFAULT_VISIBLE_FIELDS, ...forceVisibleFields];
  const visibleColumns = useForceVisibleColumns(metricsGridColumnDefs, visibleFields);

  // Join settings and metrics column definitions
  const columnDefs = useMemo(
    () => [...settingsGridColumns.beforeColumnDefs, ...visibleColumns],
    [settingsGridColumns.beforeColumnDefs, visibleColumns],
  );

  return (
    <RelativeDiv>
      {permissions.allowActions && (
        <MetricsSettingsActionsToolbar
          allowActions={permissions.allowActions}
          addMetricWithoutSave={addMetricWithoutSave}
          searchText={searchText}
          updateSearchText={updateSearchText}
          isHidingArchivedMetrics={isHidingArchivedMetrics}
          toggleIsHidingArchivedMetrics={toggleIsHidingArchivedMetrics}
          showActionBar={showActionBar}
          showSearchBar={showSearchBar}
          showSettingsToggle={showSettingsToggle}
        />
      )}
      <BaseGrid
        rowData={filteredMetrics}
        columnDefs={columnDefs}
        defaultColDef={settingsGridColumns.defaultColDef}
        {...settingsGridProps}
        {...settingsGridEventHandlers}
        height={customHeight ?? settingsGridProps.height}
      />
    </RelativeDiv>
  );
};

export default Metrics;

const RelativeDiv = styled.div`
  position: relative;
`;
