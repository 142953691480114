// LAYERS
export const IDEA_LAYER = '0';
export const INITIATIVE_LAYER = '1';
export const BET_LAYER = '2';

export const layers = [IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER];

// PROGRESS

export const PROGRESS_AUTO = 'auto';
export const PROGRESS_MANUAL = 'manual';

// METADATA GROUPING

export const GROUP_FIELD_TO_METADATA_MAPPING = {
  objectiveCorp: 'objectivesCorp',
  objective: 'objectives',
  keyResult1: 'keyResults1',
  keyResult2: 'keyResults2',
  product1: 'products',
  product2: 'products2',
  theme: 'themes',
  timeframeCorp: 'timeframesCorp',
  timeframe: 'timeframes',
  timeframe2: 'timeframes2',
  roadmapCorp: 'roadmapsCorp',
  roadmap: 'roadmaps',
  phase: 'phases',
  // categoryCorp: 'categoriesCorp',
  category: 'categories',
  status_color: 'statusColors',
  priority: 'priorities',
  planningStage: 'planningStages',
  owner: 'users',
  tags: 'tags',
  customers: 'customers',
  dates: 'dates',
  'start-quarter': 'dates',
  'start-year': 'dates',
  'end-quarter': 'dates',
  'end-year': 'dates',
  'estimated-start-month': 'dates',
  'estimated-start-quarter': 'dates',
  'estimated-start-year': 'dates',
  'estimated-end-month': 'dates',
  'estimated-end-quarter': 'dates',
  'estimated-end-year': 'dates',
  initiative: 'initiatives',
  bet: 'bets',
  customField: 'customFields',
  scenarioVersions: 'scenarioVersions',
  metrics: 'metrics',
  roadmapHistorySnaps: 'roadmapHistorySnaps',
  team: 'teams',
  user1: 'users',
  user2: 'users',
  user3: 'users',
  user4: 'users',
  user5: 'users',
  user6: 'users',
  user7: 'users',
};

export const GROUP_FIELD_TO_FILTER_MAPPING = {
  ...GROUP_FIELD_TO_METADATA_MAPPING,
  keyResult1: 'keyResults',
};

export const OBJECTIVE_KEYS = ['keyResult1', 'keyResult2', 'objective', 'objectiveCorp'];

const PARENT_ID = 'parent_id';
const ROADMAP_ID = 'roadmap_id';
const OBJECTIVE_ID = 'objective_id';
const PLANNING_STAGE = 'planning_stage';

const parentEquals =
  (parentAttribute = PARENT_ID) =>
  (entity, parent) =>
    entity[parentAttribute] === parent.id;
const grandParentEquals = parentAttribute => (entity, grandparent) =>
  (grandparent.children || []).find(eachChild => eachChild.id === entity[parentAttribute]);

export const GROUP_FILTER_FUNCTIONS = {
  objective: {
    objectiveCorp: parentEquals(),
  },
  keyResult1: {
    objectiveCorp: grandParentEquals(OBJECTIVE_ID),
    objective: parentEquals(OBJECTIVE_ID),
  },
  roadmap: {
    roadmapCorp: parentEquals(),
  },
  product1: {
    roadmapCorp: grandParentEquals(ROADMAP_ID),
    roadmap: parentEquals(ROADMAP_ID),
  },
  phase: {
    planningStage: parentEquals(PLANNING_STAGE),
  },
  timeframe: {
    timeframeCorp: parentEquals(),
  },
  timeframe2: {
    timeframeCorp: grandParentEquals('parent_id'),
    timeframe: parentEquals(),
  },
};
