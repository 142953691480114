import { OBJECT_KEY_RESULT, OBJECT_OBJECTIVE } from 'store/objectives';
import { METADATA_LEVELS } from 'constants/common';
import { getUserName } from 'utils/index';
import { defaultTo } from 'ramda';

/**
 * Based on the objective level get the path key prefix to use in list hierarchy
 * @param {Object} objective 
 * @returns {String}
 */
const getPathKeyPrefixForObjective = objective => {
  const isCorpLevel = objective.level === METADATA_LEVELS.LEVEL_CORP;

  return isCorpLevel ? `_${METADATA_LEVELS.LEVEL_CORP}_` : 'OBJECTIVE';
};

/**
 * @function buildPathFromParentId recursively build the path array for the given parent
 * @param  {Array} items list of goals
 * @param  {Integer} parentId id of the parent goal
 * @return {Array} List of path segments
 */
const buildPathFromParentId = (items, parentId, keyPrefixFn = getPathKeyPrefixForObjective) => {
  let previousPath = [];

  if (!parentId) {
    return previousPath;
  }
  const parent = items.find(parentItem => parentItem.id === parentId);

  if (!parent) return previousPath;

  if (parent.parent_id) {
    previousPath = buildPathFromParentId(items, parent?.parent_id).flat();
  }

  return [...previousPath, `${keyPrefixFn(parent)}${parent.id}`];
};

/**
 * For the given list of objectives and key results return a flat array with the rows for goals grid
 *
 * @param {Array} objectives
 * @param {Array} keyResults
 * @returns {Array}
 */
const buildGoalsGridRows = (objectives, keyResults) => {
  const objectiveRows = objectives.reduce((acc, objective) => {
    const keyPrefix = getPathKeyPrefixForObjective(objective);

    const parentPath = buildPathFromParentId(objectives, objective?.parent_id);

    const objectiveUniqueId = `${keyPrefix}${objective.id}`;
    const path = [...parentPath, objectiveUniqueId];

    const processedObjective = {
      ...objective,
      ownerName: getUserName(objective.owner),
      updatedBy: getUserName(objective.updatedBy),
      path,
      type: OBJECT_OBJECTIVE,
      uniqueId: objectiveUniqueId,
    };

    return [...acc, processedObjective];
  }, []);

  const keyResultRows = keyResults.reduce((acc, keyResult) => {
    const keyResultUniqueId = `KEY_RESULT${keyResult.id}`;

    let parentPath = [];

    if (keyResult.objective_id) {
      const objective = objectiveRows.find(o => o.id === keyResult.objective_id);

      if (objective) {
        parentPath = defaultTo([], objective.path);
      }
    }

    if (keyResult.parent_id) {
      parentPath = [...parentPath, ...buildPathFromParentId(keyResults, keyResult.parent_id, () => 'KEY_RESULT')];
    }

    const keyResultPath = [...parentPath, keyResultUniqueId];

    const processedKeyResult = {
      ...keyResult,
      ownerName: getUserName(keyResult.owner),
      updatedBy: getUserName(keyResult.updatedBy),
      type: OBJECT_KEY_RESULT,
      path: keyResultPath,
      uniqueId: keyResultUniqueId,
    };

    return [...acc, processedKeyResult];
  }, []);

  return [...objectiveRows, ...keyResultRows];
};

export default buildGoalsGridRows;
