import { useMemo } from 'react';
import { WeeksCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { numberComparator } from 'utils/agGridComparators';
import {
  pointsVarianceColumnDef,
  sumPointsVarianceFieldAggColumnDef,
  sumNumericFieldAggColumnDef,
  textCenterAlignedColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';
import { estimateRounder } from 'utils/estimates/unitConversion';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const plannedPointsColumnDef = {
  ...textCenterAlignedColumnDef,
  ...sumNumericFieldAggColumnDef,
  rounder: estimateRounder,
  field: 'plannedPoints',
  headerName: 'Planned Points',
  comparator: numberComparator,
  width: 160,
  enableValue: true,
  editable: false,
};
const completedPointsColumnDef = {
  ...textCenterAlignedColumnDef,
  ...sumNumericFieldAggColumnDef,
  rounder: estimateRounder,
  field: 'completedPoints',
  headerName: 'Completed Points',
  comparator: numberComparator,
  width: 160,
  enableValue: true,
  editable: false,
};

/**
 * @function useEstimationFieldsColumnsDefinitions
 *
 * Estimation fields columns to be used on ideas grid
 *
 * @return {Object}
 */
const useEstimationFieldsColumnsDefinitions = ({ displayLayer }) => {
  const { canView } = usePermissions();
  const estimateWithPointsEnabled = canView(PERMISSION_FEATURES.usePointsToEstimate);

  const totalEstimateColumnDef = useMemo(
    () => ({
      ...textCenterAlignedColumnDef,
      ...sumNumericFieldAggColumnDef,
      rounder: estimateRounder,
      field: 'sum',
      headerName: 'Total Estimate',
      comparator: numberComparator,
      width: 80,
      cellRenderer: WeeksCellRenderer,
      enableValue: true,
      editable: false,
    }),
    [],
  );

  const pointsVarianceDef = useMemo(() => {
    return {
      ...pointsVarianceColumnDef,
      ...sumPointsVarianceFieldAggColumnDef,
      field: 'pointsVariance',
      headerName: 'Points Variance',
      cellRendererParams: {
        displayLayer,
      },
      editable: false,
      width: 120,
    };
  }, [displayLayer]);

  return useMemo(
    () => [
      totalEstimateColumnDef,
      ...(estimateWithPointsEnabled ? [plannedPointsColumnDef, pointsVarianceDef, completedPointsColumnDef] : []),
    ],
    [totalEstimateColumnDef, estimateWithPointsEnabled, pointsVarianceDef],
  );
};

export default useEstimationFieldsColumnsDefinitions;
