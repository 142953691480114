import axios from 'axios';

import {
  FETCH_CATEGORIES,
  UPDATE_CATEGORY,
  CREATE_CATEGORY,
  UPDATE_CATEGORY_BY_ID,
  ADD_CATEGORY_WITHOUT_SAVE,
  REMOVE_UNSAVED_CATEGORIES,
  CREATE_CATEGORY_FULFILLED,
  DELETE_CATEGORY_FULFILLED,
  UPDATE_CATEGORY_FULFILLED,
  BULK_DELETE_CATEGORIES_FULFILLED,
  BULK_DELETE_CATEGORY_ROADMAP,
  CREATE_CATEGORY_ROADMAP,
  DELETE_CATEGORY_ROADMAP,
} from './types';

import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';
import fetchMetadataAction from 'store/utils/factory/fetchMetadataAction';

export const fetchCategories = fetchMetadataAction(
  FETCH_CATEGORIES,
  '/api/categories',
  state => state.categories.categories,
  state => state.categories.lastCallsDate,
);

export const updateCategory = category => {
  return {
    payload: axios.post(`/api/categories/update/${category.id}`, category).then(res => res.data),
    type: UPDATE_CATEGORY,
  };
};

export const updateCategoryById = (id, category) => {
  return {
    payload: axios.put(`/api/categories/${id}`, category).then(res => res.data),
    type: UPDATE_CATEGORY_BY_ID,
  };
};

export const createCategory = category => {
  return {
    payload: axios.post('/api/categories/', category).then(res => res.data),
    type: CREATE_CATEGORY,
  };
};

export const createCategoryRoadmap = (categoryId, categoryRoadmap) => {
  return {
    payload: axios.post(`/api/categories/${categoryId}/categoryRoadmaps/`, categoryRoadmap).then(response => response.data),
    type: CREATE_CATEGORY_ROADMAP,
  };
};

export const deleteCategoryRoadmap = ({ id, roadmapId, subRoadmapId = null, product2Id = null }) => {
  let route = `/api/categories/${id}/categoryRoadmaps/${roadmapId}`;

  route += subRoadmapId ? `/${subRoadmapId}` : '';
  route += subRoadmapId && product2Id ? `/${product2Id}` : '';

  return {
    payload: axios.delete(route).then(response => response.data),
    type: DELETE_CATEGORY_ROADMAP,
  };
};

export const bulkDeleteCategoryRoadmaps = categoryId => {
  return {
    payload: axios.delete(`/api/categories/${categoryId}/categoryRoadmaps/`).then(response => response.data),
    type: BULK_DELETE_CATEGORY_ROADMAP,
  };
};

export const addCategoryWithoutSave = category => ({
  type: ADD_CATEGORY_WITHOUT_SAVE,
  category,
});

export const removeUnsavedCategories = _ => ({
  type: REMOVE_UNSAVED_CATEGORIES,
});

export const gotCategoriesRealtimeUpdate = generateRealtimeUpdateAction(
  CREATE_CATEGORY_FULFILLED,
  DELETE_CATEGORY_FULFILLED,
  UPDATE_CATEGORY_FULFILLED,
  BULK_DELETE_CATEGORIES_FULFILLED,
);

export const categoryActions = {
  fetchCategories,
  updateCategory,
  updateCategoryById,
  createCategory,
};
