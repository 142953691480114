import React from 'react';
import styled from 'styled-components';

const StyledAbbr = styled.abbr`
  text-decoration: none;
`;

const TextContent = ({ showTooltip, text }) => {
  return showTooltip ? <StyledAbbr title={text}>{text}</StyledAbbr> : text;
};

export default TextContent;
