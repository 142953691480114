import React, { useCallback } from 'react';

import ConfirmDialog from 'components/ConfirmDialog';

const DeleteConfirmDialog = ({ metricName, itemToDelete, setItemToDelete, deleteItem }) => {
  const handleCancelDeleteItem = useCallback(() => setItemToDelete(null), [setItemToDelete]);

  return (
    <ConfirmDialog
      isOpen={!!itemToDelete}
      onCancel={handleCancelDeleteItem}
      onConfirm={deleteItem}
      title={`Delete selected ${metricName}`}
      text={`Are you sure you want to delete selected ${metricName}?`}
    />
  );
};

export default DeleteConfirmDialog;
