import { useCallback, useState } from 'react';
import { either, isEmpty, isNil, last, pipe, split } from 'ramda';

import useUrlUtils from 'hooks/useUrlUtils';

const getRefLastElement = pipe(split('/'), last);
const isNilOrEmpty = either(isNil, isEmpty);

const useLinkWithRally = onLink => {
  const [link, setLink] = useState('');
  const [invalidUrlMsg, setInvalidUrlMsg] = useState(null);

  const { getQueryParam, isUrlValid } = useUrlUtils();

  const onChangeHandler = useCallback(({ target }) => {
    setInvalidUrlMsg(null);

    setLink(target.value);
  }, []);

  const onLinkHandler = useCallback(
    direction => {
      if (isNilOrEmpty(link) || !isUrlValid(link)) {
        setInvalidUrlMsg('Invalid url');

        return;
      }

      const extractPortfolioItemTypeRefFromUrl = url => {
        url = decodeURIComponent(url);

        const regex = /\/portfolioitem\/(.*?)\/(\d+)/;
        const match = url.match(regex);

        if (match) {
          return match[0];
        }
        return null;
      };

      const ref = extractPortfolioItemTypeRefFromUrl(link);

      if (isNilOrEmpty(ref)) {
        setInvalidUrlMsg('Link must contain a Rally portfolio item type and ID');

        return;
      }

      const id = getRefLastElement(ref);

      onLink(id, ref, direction);
    },
    [link, onLink, isUrlValid, getQueryParam],
  );

  return {
    link,
    onChangeHandler,
    onLinkHandler,
    invalidUrlMsg,
  };
};

export default useLinkWithRally;
