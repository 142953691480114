import isEmpty from 'lodash/isEmpty';

import { GROUP_HEADERS_ROW } from './constants';
import { createRowOrderKey, hasGroups, isLevel } from './basic';

/**
 * Generate the orders list for a group rows.
 *
 * @param {Object} group The group that contrains the rows to be used
 * @param {Function} isGroupOpen The helper to check if the group is open
 * @param {String} lastKey The previous key generated to be used as prefix for the new orders
 *
 * @return {Array} list of orders based on the group rows
 * */
const getOrdersFromGroupRows = (group, isGroupOpen, lastKey) => {
  const reducedOrders =
    group?.rows?.reduce((accRows, _, rowIndex) => {
      const lastRowOrder = lastKey ? createRowOrderKey(lastKey, group.id, rowIndex) : createRowOrderKey(group.id, rowIndex);

      if (group.level === 3) {
        const lastRowOrderWithoutRowIndex = lastKey ? createRowOrderKey(lastKey, group.id) : createRowOrderKey(group.id);

        const isGroupClosed = !isGroupOpen(lastRowOrderWithoutRowIndex);

        // if closed and row index is not the first, should not add more
        if (isGroupClosed) {
          return rowIndex === 0 ? [...accRows, lastRowOrder] : accRows;
        }

        // if group is open add an additional row 0 correspondent to the group header
        return rowIndex === 0 ? [...accRows, lastRowOrder, lastRowOrder] : [...accRows, lastRowOrder];
      }

      return [...accRows, lastRowOrder];
    }, []) || [];

  if (!group.rows || isEmpty(group.rows)) {
    const lastRowOrder = lastKey ? createRowOrderKey(lastKey, group.id, '0') : createRowOrderKey(group.id, '0');

    reducedOrders.push(lastRowOrder);
  }

  return reducedOrders;
};

/**
 * Recursive generation orders from the data when there is 1 or 2 group levels selected.
 *
 * @param {Array} timelineData The list of root groups
 * @param {Function} isGroupOpen The helper to check if the group is open
 * @param {Boolean} hideEmptyLane is this option active
 * @param {String} lastKey The previous key generated to be used as prefix for the new orders
 *
 * @return {Array} list of orders for 1 or 2 group levels selected
 * */
const getTimelineOrdersFor1Or2Groups = (timelineData = [], isGroupOpen, hideEmptyLane, lastKey = '') => {
  const isLastGroupLevel = !timelineData.some(hasGroups) || (!hideEmptyLane && timelineData.some(isLevel(2)));

  if (isLastGroupLevel) {
    return [
      ...timelineData
        .map(lastLevel => {
          // if level 2 and is not final and should display empty lanes the orders logic change
          if (!hideEmptyLane && isLevel(2) && lastLevel.groups) {
            const leafGroups = lastLevel.groups.filter(leafGroup => !isEmpty(leafGroup.rows));

            if (isEmpty(leafGroups)) {
              return createRowOrderKey(lastKey, lastLevel.id, null, '0');
            }

            return leafGroups.reduce((accRows, leafGroup) => {
              if (!isEmpty(leafGroup.rows)) {
                return [...accRows, ...getOrdersFromGroupRows(leafGroup, isGroupOpen, createRowOrderKey(lastKey, lastLevel.id))];
              }

              return [...accRows, createRowOrderKey(lastKey, null, '0')];
            }, []);
          }

          return getOrdersFromGroupRows(lastLevel, isGroupOpen, lastKey);
        })
        .flat(),
    ];
  }

  return timelineData
    .map(group => {
      const newKey = lastKey ? createRowOrderKey(lastKey, group.id) : createRowOrderKey(group.id);

      return getTimelineOrdersFor1Or2Groups(group.groups, isGroupOpen, hideEmptyLane, newKey);
    })
    .flat();
};

/**
 * Creates the reducer function for the 3rd level group for orders generation with 3 groups selected.
 *
 * @param {Object} groupLevel1 The group object from level 1
 * @param {Object} groupLevel2 The group object from level 2
 *
 * @return {Array} list of orders for the 3rd level group
 * */
const makeLevel3Reducer = (groupLevel1, groupLevel2) => (acc3, grouping, groupingIndex) => {
  if (grouping?.groupRow) {
    acc3 = [...acc3, createRowOrderKey(groupLevel1.id, groupLevel2.id, GROUP_HEADERS_ROW, groupingIndex)];
  }

  if (isEmpty(grouping.rows)) {
    return acc3;
  }

  return [...acc3, ...grouping.rows.map((_, rowIndex) => createRowOrderKey(groupLevel1.id, groupLevel2.id, rowIndex)).flat()];
};

/**
 * Creates the reducer function for the 2nd level group for orders generation with 3 groups selected.
 *
 * @param {Object} groupLevel1 The group object from level 1
 *
 * @return {Array} list of orders for the 2nd level group
 * */
const makeLevel2Reducer = groupLevel1 => (acc2, groupLevel2) => {
  if (!isEmpty(groupLevel2?.groupings)) {
    return [...acc2, ...groupLevel2?.groupings.reduce(makeLevel3Reducer(groupLevel1, groupLevel2), [])];
  }

  return [...acc2, createRowOrderKey(groupLevel1.id, groupLevel2.id, 0)];
};

/**
 * Generates the list of orders when there are 3 group levels selected.
 *
 * @param {Array} timelineData The list of root groups
 *
 * @return {Array} list of orders when 3 group levels are selected
 * */
const getTimelineOrdersFor3Groups = timelineData =>
  timelineData.reduce((acc, groupLevel1) => [...acc, ...groupLevel1.groups.reduce(makeLevel2Reducer(groupLevel1), [])], []);

/**
 * Generates the list of orders based on the groups information to be used by drag and drop operation.
 *
 * @param {Array} timelineData The list of root groups
 * @param {Function} isGroupOpen The helper to check if the group is open
 * @param {Boolean} hideEmptyLane is this option active
 * @param {Number} groupsCount The number of group levels selected
 *
 * @return {Array} list of orders
 * */
const getTimelineOrders = (timelineData = [], isGroupOpen, hideEmptyLane, groupsCount) => {
  return groupsCount === 3
    ? getTimelineOrdersFor3Groups(timelineData)
    : getTimelineOrdersFor1Or2Groups(timelineData, isGroupOpen, hideEmptyLane);
};

export { getTimelineOrders };
