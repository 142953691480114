import { SET_CUSTOMER_REQUESTS_ANALYSE_BUBBLE_SIZE_BY, SET_CUSTOMER_REQUESTS_ANALYSE_CLUSTER_BY } from './types';

export const setSelectedAnalizeClusterBy = clusterBy => {
  return {
    type: SET_CUSTOMER_REQUESTS_ANALYSE_CLUSTER_BY,
    clusterBy,
  };
};

export const setSelectedAnalizeBubbleSizeBy = bubbleSizeBy => {
  return {
    type: SET_CUSTOMER_REQUESTS_ANALYSE_BUBBLE_SIZE_BY,
    bubbleSizeBy,
  };
};
