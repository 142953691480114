import { LIFECYCLES, PERSONAS } from 'constants/common';

// TODO: move to generic place
const getCustomerRequestFieldLabel = (fieldId, getSystemFieldName, customFields = []) => {
  const fieldMapper = {
    comments: 'Comments',
    ideas: 'Portfolio Items',
    watchers: 'Watchers',
    links: 'Links',
    details: 'Details',
    integrations: 'Integrations',
    files: 'Files',
    timeline: 'Target Timeline',
    customers: getSystemFieldName('customer'),
    tags: getSystemFieldName('tag'),
    customFields: 'Custom Fields',
    statusSummary: 'Status summary',
    owner: 'Owner',
    status: 'Status',
    priority: getSystemFieldName('priority'),
    parent: 'Merged Under',
    roadmap: getSystemFieldName('roadmap'),
    products: getSystemFieldName('product1'),
    products2: getSystemFieldName('product2'),
    themes: getSystemFieldName('theme'),
    categories: getSystemFieldName('category'),
    timeframes: getSystemFieldName('timeframe'),
    ...customFields.reduce((items, field) => ({ ...items, [field.key]: field.title }), {}),
    cr_customers_count: '# of Customers',
    totalNetRevenue: 'Total value',
    cr_total_revenue: 'Active value',
    cr_total_churned_revenue: 'Inactive value',
    external_key: 'External Key',
    external_portfolio_key: 'External Portfolio Key',
    [PERSONAS]: getSystemFieldName('persona', true),
    [LIFECYCLES]: getSystemFieldName('lifecycle', true),
    created_at: 'Created at',
    created_by: 'Created by',
    desired_time: 'Desired Time',
    updated_at: 'Updated at',
    votes: 'Votes',
  };

  return fieldMapper[fieldId] || fieldId;
};

export default getCustomerRequestFieldLabel;
