import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Iframe from 'react-iframe';

import Button from 'design-system/atoms/Button/index';

import PublicPageTemplateComponent from 'src/design-system/templates/PublicPageTemplate/PublicPageTemplateComponent';
import AuthErrorMessage from 'components/AuthErrorMessage';
import { SIGNUP_MESSAGE_TRIAL } from './constants';

export default ({ googleErrorMessage, signupMessage = SIGNUP_MESSAGE_TRIAL }) => {
  const [hasLoaded, setHasLoaded] = React.useState(false);

  const login = (
    <LoginCorner>
      <p>Already have an account?</p>
      <LoginBtn to="/login">
        <Button>Log In</Button>
      </LoginBtn>
    </LoginCorner>
  );

  return (
    <PublicPageTemplateComponent headerRightContent={login}>
      <Wrapper>
        <Grid container direction="column" alignItems="center" spacing={0}>
          <Grid item xs={12}>
            <StyledTitle>Check your mailbox</StyledTitle>
          </Grid>
          <Grid item xs={12}>
            <StyledSubTitle data-testid="text-signup-confirmation">{signupMessage}</StyledSubTitle>
          </Grid>
          <Grid item xs={12} style={{ visibility: hasLoaded ? 'visible' : 'hidden' }}>
            <StyledSeparator>Or</StyledSeparator>
            <SSOLoginGoogle
              onLoad={() => setHasLoaded(true)}
              title="Google Button"
              url={`${process.env.REACT_APP_PUBLIC_URL}/google_button`}
              height="46px"
              width="100%"
              id="myId"
              display="initial"
              position="relative"
              allowFullScreen
            />
            {googleErrorMessage && <AuthErrorMessage errorMessage={googleErrorMessage} />}
          </Grid>
        </Grid>

        <FloatedFooter>
          <p>
            Having trouble?{' '}
            <a href="https://dragonboat.zendesk.com/hc/en-us/requests/new" target="_blank" rel="noreferrer">
              <b>Reach out to us</b>
            </a>
          </p>
        </FloatedFooter>
      </Wrapper>
    </PublicPageTemplateComponent>
  );
};

const Wrapper = styled.div`
  max-width: 478px;

  @media screen and (min-height: 700px) {
    margin-top: -5rem;
  }
`;

const StyledTitle = styled.h1`
  margin: 0 0 ${props => props.theme.spacing.unit * 1.25} 0;

  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-weight: ${props => props.theme.typography.fontWeightBold};
  font-size: ${props => props.theme.typography.fontSizeH4}px;
  line-height: ${props => props.theme.typography.lineHeightLarger}px;

  color: ${props => props.theme.palette.text.black};
`;

const StyledSubTitle = styled.h5`
  margin: 0 0 1.813rem 0;
  text-align: center;

  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-weight: ${props => props.theme.typography.fontWeightRegular};
  font-size: ${props => props.theme.typography.fontSizeSmallLarge}px;
  line-height: ${props => props.theme.typography.lineHeightRegularLarge}px;

  color: ${props => props.theme.palette.text.midnightSun};
`;

const StyledSeparator = styled(StyledSubTitle)`
  &&& {
    color: ${props => props.theme.palette.text.black};

    font-size: ${props => props.theme.typography.fontSize}px;
    line-height: ${props => props.theme.typography.lineHeightSmallLarge}px;
  }
`;

const LoginBtn = styled(Link)`
  &&&& {
    text-decoration: none;

    button {
      width: 84px;
      height: 43px;

      margin-left: ${props => props.theme.spacing.unit * 2.5}px;
      border-radius: ${props => props.theme.shape.borderRadiusRegular}px;
      background-color: ${props => props.theme.palette.background.white};
      color: ${props => props.theme.palette.black};
      font-size: ${props => props.theme.typography.fontSize}px;
      border-color: ${props => props.theme.palette.black};

      &:hover {
        color: ${props => props.theme.palette.background.white};
        background-color: ${props => props.theme.palette.black};
      }
    }
  }
`;

const LoginCorner = styled.div`
  float: right;
  display: flex;
  position: relative;
  margin-right: ${props => props.theme.spacing.unit * 2.5}px;
  margin-top: ${props => props.theme.spacing.unit * 1.5}px;
  font-size: ${props => props.theme.typography.fontSize}px;
`;

const SSOLoginGoogle = styled(Iframe)`
  &&&& {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.09);
    border-radius: ${props => props.theme.shape.borderRadiusSmall}px;
    overflow: hidden;
    width: 270px;

    html {
      overflow: hidden;
    }
  }
`;

const FloatedFooter = styled.div`
  position: absolute;
  bottom: 34px;
  left: 50%;
  transform: translateX(-50%);

  p,
  a {
    font-family: ${props => props.theme.typography.fontFamilyInter};
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    font-size: ${props => props.theme.typography.fontSize}px;
    line-height: ${props => props.theme.typography.lineHeightSmallLarge}px;

    color: ${props => props.theme.palette.text.black};
  }

  b {
    font-weight: ${props => props.theme.typography.fontWeightBold};
  }
`;
