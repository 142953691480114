import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { findGroupsLevel2ByGroupIndexes, hasGroups } from './basic';

/**
 * Format the items dates to facilitate the comparison of similar objects.
 *
 * @param {Object} data The initial object to be transfomed
 *
 * @return {Object} initial objects with dates converted
 * */
const dataFormatter = data => {
  const isLastGroup = !data.some(hasGroups);

  if (isLastGroup) {
    return (
      data?.map(lastLevel => ({
        ...lastLevel,
        rows: (lastLevel?.rows || []).map(row =>
          row.map(project => ({ ...project, startDate: project.startDate.format(), endDate: project.endDate.format() })),
        ),
      })) ?? []
    );
  }

  return (
    data?.map(groupLevel => ({
      ...groupLevel,
      groups: dataFormatter(groupLevel.groups),
    })) ?? []
  );
};

/**
 * Extract the local state item.
 *
 * @param {Object} item The external item
 * @param {Array} internalData The complete internal data structure
 * @param {Array} groupIndexes The index of the groups to search the right row
 * @param {Number} rowIndex The index of the row to search the right item
 *
 * @return {Object} The local item
 *
 * */
const getLocalItem = (item, internalData, groupIndexes, rowIndex) => {
  if (isEmpty(groupIndexes)) {
    return null;
  }

  const findItemById = ({ id }) => id === item.id;
  const findItemOnRow = row => row?.find(findItemById);

  const groupPath = groupIndexes.reduce(
    (acc, groupIndex, index) => (acc ? [acc, groupIndex].join(index === 2 ? '.groupings.' : '.groups.') : `${groupIndex}`),
    '',
  );

  const rowPath = `${groupPath}.rows.${rowIndex}`;

  return findItemOnRow(get(internalData, rowPath));
};

/**
 * Get all items from the last group using group indexes for path.
 *
 * @param {Array} internalData The complete internal data structure
 * @param {Array} groupIndexes The index of the groups to search the right last group
 *
 * @return {Array} all the group items
 * */
const getLastGroupItems = (internalData, groupIndexes, item) => {
  if (isEmpty(groupIndexes)) {
    return null;
  }

  if (groupIndexes.length === 3) {
    const currentGroupsLevel3 = findGroupsLevel2ByGroupIndexes(internalData, groupIndexes);

    const currentGroup = currentGroupsLevel3?.groups.find(group => group?.items?.some(groupItem => groupItem.id === item.id));

    return currentGroup?.items;
  }

  const groupPath = groupIndexes.reduce((acc, groupIndex) => (acc ? [acc, groupIndex].join('.groups.') : groupIndex), '');

  const lastGroup = get(internalData, groupPath);

  return lastGroup?.items ?? [];
};

export { dataFormatter, getLocalItem, getLastGroupItems };
