import React from 'react';
import { prop, equals, pipe, defaultTo, gt, __, not } from 'ramda';

import Actions from './Actions';
import { TextWrapper, Wrapper } from './styled';
import { METADATA_LEVELS } from 'constants/common';

const LEVEL = 'level';
const ALL_CHILDREN_COUNT = 'allChildrenCount';

const defaultToEmptyObject = defaultTo({});
const defaultToZero = defaultTo(0);
const isGreaterThanZero = gt(__, 0);
const getAllChildrenCount = prop(ALL_CHILDREN_COUNT);

const isMetadataLevel1 = equals(METADATA_LEVELS.LEVEL_1);
const isMetadataLevelCorp = equals(METADATA_LEVELS.LEVEL_CORP);
const getLevelProp = prop(LEVEL);
const isLevelMetadataLevel1 = pipe(defaultToEmptyObject, getLevelProp, isMetadataLevel1);
const isLevelMetadataLevelCorp = pipe(defaultToEmptyObject, getLevelProp, isMetadataLevelCorp);

const isLevel2 = pipe(prop(LEVEL), equals(METADATA_LEVELS.LEVEL_2));
const isLastChild = pipe(defaultToEmptyObject, isLevel2);
const doesNotHaveChildrenNodes = pipe(defaultToEmptyObject, getAllChildrenCount, defaultToZero, isGreaterThanZero, not);

const GroupHeaderInnerRenderer = params => {
  const { data, value, node, context: { treeData } = {} } = params;

  if (!value) {
    return null;
  }

  const isParentLevelWithoutChildren =
    treeData && (isLevelMetadataLevelCorp(data) || isLevelMetadataLevel1(data)) && doesNotHaveChildrenNodes(node);
  const shouldRenderWithIndentation = isParentLevelWithoutChildren || isLastChild(data);

  return (
    <Wrapper $indent={shouldRenderWithIndentation}>
      <TextWrapper>{params.value}</TextWrapper>
      <Actions {...params} />
    </Wrapper>
  );
};

export default GroupHeaderInnerRenderer;
