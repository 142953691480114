import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';

import checkIsGreen from './checkIsGreen';
import generateHCTableResources from 'containers/GanttTimeline/TimelineHCTable/helpers/generateHCTableResources';

export default (lsState, gantt, ganttTasks, teams, skills, users) => {
  const { selectedZoom, selectedRounding } = lsState;

  const step = selectedZoom.id;

  let stepLength;

  switch (step) {
    case 'month':
      stepLength = 30;
      break;
    case 'quarter':
      stepLength = 91.25;
      break;
    default:
      stepLength = 7;
      break;
  }

  ganttTasks = {
    links: ganttTasks.links,
    data: ganttTasks.data.map((task, i) => ({ ...task, pos: i, isConfirmed: task.planningStage === 'Confirmed' ? 0 : 1 })),
  };

  let planTasks = ganttTasks;

  if (gantt) {
    const resources = generateHCTableResources({
      skills,
      teams,
      tasks: ganttTasks,
      users,
      showResourceUsers: true,
      resourceUsersGroup: 'skillAndTeam',
    });

    const _getPlan = tasks => {
      return {
        data: tasks.data
          // .filter(({ id }) => +id === 21395)
          .filter(({ dbType }) => !['task', 'estimate'].includes(dbType))
          .reduce((planTasks, p) => {
            const newP = cloneDeep(p);

            if (!['task', 'estimate', 'project'].includes(newP.dbType)) {
              return [...planTasks, newP];
            }

            const children = cloneDeep(tasks.data.filter(({ project_id: pid }) => pid === p.id));

            children.forEach(c => {
              // eslint-disable-next-line no-mixed-operators
              c.end_date = new Date(new Date(c.start_date).valueOf() + c.duration * 24 * 3600 * 1000);
            });

            if (
              !checkIsGreen({
                resources,
                tasks: [...children, newP],
                selectedTimelineInterval: selectedZoom,
                selectedRounding,
                gantt,
              })
            ) {
              newP.ignore = true;
              children.forEach(c => {
                c.ignore = true;
              });
            }

            if ((newP.dbType === 'project' && newP.planningStage === 'Confirmed') || newP.ignore) {
              return [...planTasks, ...children, newP];
            }

            let refDate = new Date();

            let tmpPlan = [];

            while (
              !tmpPlan.length ||
              !checkIsGreen({
                resources,
                tasks: tmpPlan.filter(p => !p.ignore),
                selectedTimelineInterval: selectedZoom,
                selectedRounding,
                gantt,
              })
            ) {
              const children = cloneDeep(tasks.data.filter(({ project_id: pid }) => pid === p.id));

              tmpPlan = [...planTasks];

              // if (iteration === 100) {
              //   // eslint-disable-next-line no-loop-func
              //   if (children.length) {
              //     // eslint-disable-next-line no-loop-func
              //     children.forEach(c => {
              //       // eslint-disable-next-line no-mixed-operators
              //       c.end_date = new Date(new Date(c.start_date).valueOf() + c.duration * 24 * 3600 * 1000);
              //       c.ignore = true;
              //       planTasks = [...planTasks, c];
              //     });
              //   }

              //   newP = cloneDeep(p);
              //   newP.ignore = true;

              //   return [...planTasks, newP];
              // }

              if (children.length) {
                const firstChild = sortBy(children, ['start_date'])[0];

                const diff = refDate - firstChild.start_date;

                // eslint-disable-next-line no-loop-func
                children.forEach(c => {
                  c.start_date = new Date(new Date(c.start_date).valueOf() + diff);
                  // eslint-disable-next-line no-mixed-operators
                  c.end_date = new Date(new Date(c.start_date).valueOf() + c.duration * 24 * 3600 * 1000);
                  tmpPlan.push(c);
                });
              } else {
                const diff = refDate - newP.start_date;

                newP.start_date = refDate;
                newP.end_date = new Date(newP.end_date.valueOf() + diff);
              }
              tmpPlan.push(newP);

              // eslint-disable-next-line no-mixed-operators
              refDate = new Date(refDate.valueOf() + stepLength * 24 * 3600 * 1000);
            }

            return tmpPlan;
          }, []),
        links: [],
      };
    };

    const confirmed = _getPlan({
      links: ganttTasks.links,
      data: ganttTasks.data.filter(t => t.dbType !== 'project' || t.planningStage === 'Confirmed'),
    });

    if (
      !checkIsGreen({
        resources,
        tasks: confirmed.data,
        selectedTimelineInterval: selectedZoom,
        selectedRounding,
        gantt,
      })
    ) {
      return planTasks;
    }

    planTasks = _getPlan({
      links: ganttTasks.links,
      data: sortBy(ganttTasks.data, ['isConfirmed', 'pos']),
    });
  }

  return {
    links: planTasks.links,
    data: sortBy(planTasks.data, ['pos']),
  };
};
