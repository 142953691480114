import reduceReducers from 'reduce-reducers';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import { CREATE_PROJECT_ESTIMATE, UPDATE_PROJECT_ESTIMATE, DELETE_PROJECT_ESTIMATE, UPDATE_ESTIMATES } from '../types';

const { initialState, reducers } = getThunksInitialStateAndReducers([
  CREATE_PROJECT_ESTIMATE,
  UPDATE_PROJECT_ESTIMATE,
  DELETE_PROJECT_ESTIMATE,
  UPDATE_ESTIMATES,
]);

const reducer = reduceReducers(initialState, ...reducers);

export { initialState };

export default reducer;
