import { defaultTo } from 'ramda';

const defaultToZero = defaultTo(0);

export const getPaginationInformation = (limit, offset) => {
  // eslint-disable-next-line
  const current = offset > 0 ? Math.ceil(offset / limit + 1) : 1;
  const next = current + 1;
  const previous = current - 1 >= 1 ? current - 1 : null;

  return { current, next, previous };
};

export const determinePaginationOffset = (page, limit) => {
  const pageLimit = parseInt(limit);

  return defaultToZero((page - 1) * pageLimit);
};
