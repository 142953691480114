import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ChartWrapper from 'src/components/ReactChart/utils/ChartWrapper';
import Segment from 'design-system/atoms/Segment/index';

import { BaseGrid } from 'containers/Grids';
import { css } from 'styled-components';
import usePageFilters from 'hooks/filters/usePageFilters';
import { DASHBOARDS_PAGE } from 'constants/filters/pages';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import { useSelector } from 'react-redux';
import { getUserFilters } from 'store/filters/selectors';
import axios from 'axios';
import { getAllRoadmaps } from 'store/roadmaps/selectors';
import { flatten, pluck } from 'ramda';
import { UNDEFINED_LABEL } from 'constants/common';
import TimelineLightbox from 'features/TimelineLightbox';
import { getOrganizationSystemFieldsNames } from 'store/organization';
import getSystemFieldName from 'utils/getSystemFieldName';

const gridStyles = css`
  .ag-layout-normal.ag-root {
    margin: 0;
  }
`;

export default ({ label, wrapper: Wrapper, disableClick, minHeight, actionButtons }) => {
  const [openTimeline, setOpenTimeline] = useState();
  const [roadmapForTimeline, setRoadmapForTimeline] = useState({});
  const [groupForTimeline, setGroupForTimeline] = useState({});

  const { pageFilters: filters, displayLayer } = usePageFilters(DASHBOARDS_PAGE);
  const systemFields = useSelector(state => getOrganizationSystemFieldsNames(state));
  const userFilters = useSelector(getUserFilters);
  const hasBet = useSelector(state => state.organization.organization.has_bet);
  const roadmaps = useSelector(state => getAllRoadmaps(state));

  const products = useMemo(() => {
    return flatten(pluck('products', roadmaps));
  }, [roadmaps]);

  const getRoadmapName = useCallback(
    roadmapId => {
      return roadmaps.find(r => r.id === Number(roadmapId))?.title || UNDEFINED_LABEL;
    },
    [roadmaps],
  );

  const getRoadmapL2Name = useCallback(
    productId => {
      const l2Products = flatten(pluck('products', products));
      const l1ProductName = products.find(r => r.id === Number(productId))?.title;

      if (l1ProductName) {
        return l1ProductName;
      }

      return l2Products.find(r => r.id === Number(productId))?.title || UNDEFINED_LABEL;
    },
    [products],
  );

  const [reportData, setReportData] = useState([]);

  const fetchData = async () => {
    const filtersBody = compileFiltersBody(filters, userFilters, hasBet, DASHBOARDS_PAGE, displayLayer, null);

    const { data } = await axios.post('/api/v1/reports/portfolio/delivery-risk/delayed-items', { projectsFilter: filtersBody });

    setReportData(
      data.map(row => ({
        ...row,
        roadmap: getRoadmapName(row.roadmap_id),
        product_1: getRoadmapL2Name(row.product_1_id),
        product_2: getRoadmapL2Name(row.product_2_id),
      })),
    );
  };

  useEffect(() => {
    fetchData();
  }, [filters, userFilters, hasBet, displayLayer]);

  const valueFormatter = useCallback(({ data, colDef }) => {
    if (!data) {
      return;
    }

    const { field } = colDef;

    return `${data[field]} (${data[`${field}_with_dependency`]})`;
  }, []);

  const onCellDoubleClicked = useCallback(
    ({ data }) => {
      if (!data) {
        return;
      }

      const roadmap = roadmaps.find(r => r.id === data.roadmap_id) || { id: null, title: UNDEFINED_LABEL };

      setRoadmapForTimeline(roadmap);

      const groupByKey = data.product_1_id ? 'product1' : 'roadmap';

      setGroupForTimeline({ key: groupByKey, title: getSystemFieldName(groupByKey, systemFields) });

      openTimeline();
    },
    [openTimeline, roadmaps, setRoadmapForTimeline, setGroupForTimeline],
  );

  const columnDefs = useMemo(
    () => [
      {
        field: 'delay_less_1_week',
        headerName: 'Delay < 1 week',
        valueFormatter,
        onCellDoubleClicked,
      },
      {
        field: 'delay_1_2_week',
        headerName: 'Delay 1-2 weeks',
        valueFormatter,
        onCellDoubleClicked,
      },
      {
        field: 'delay_less_1_month',
        headerName: 'Delay < 1 month',
        valueFormatter,
        onCellDoubleClicked,
      },
      {
        field: 'delay_1_3_month',
        headerName: 'Delay 1-3 months',
        valueFormatter,
        onCellDoubleClicked,
      },
      {
        field: 'delay_3_plus_month',
        headerName: 'Delay 3+ months',
        valueFormatter,
        onCellDoubleClicked,
      },
    ],
    [valueFormatter, onCellDoubleClicked],
  );

  const columnTypes = useMemo(() => {
    return {
      dimension: {
        enableRowGroup: true,
        enablePivot: true,
      },
    };
  }, []);

  const getDataPath = useMemo(() => {
    return data => {
      return [data.roadmap, data.product_1, data.product_2].filter(d => d);
    };
  }, []);

  const gridOptions = {
    autoGroupColumnDef: {
      headerName: 'Roadmap',
      cellRendererParams: {
        suppressCount: true,
      },
    },
    treeData: true,
    groupDefaultExpanded: -1,
    rowData: reportData,
    columnDefs,
    columnTypes,
    getDataPath,
  };

  return (
    <Wrapper>
      <Segment bordered label={label} actionButtons={actionButtons}>
        <ChartWrapper height={minHeight}>
          <BaseGrid {...gridOptions} height={minHeight} cssStyles={gridStyles} />
        </ChartWrapper>
      </Segment>
      <TimelineLightbox
        onOpen={f => setOpenTimeline(() => f)}
        entityGroup={{ key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) }}
        groupedBy={roadmapForTimeline}
        lazyLoadProjects
        pageId={DASHBOARDS_PAGE}
        groupBySelection={groupForTimeline}
      />
    </Wrapper>
  );
};
