import React, { useMemo } from 'react';
import { SYSTEM_FIELD } from '@dragonboat/permissions';

import { METADATA_LEVELS } from 'constants/common';
import { getObjectiveKeyByLevel } from 'utils/objectives';

import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SettingsDialogs from 'components/SettingsDialogs';

import { BaseGrid } from 'containers/Grids';
import {
  useSettingsActions,
  useSettingsGridSelection,
  useSettingsGridProps,
  useSettingsGridEventHandlers,
  useSettingsGridColumns,
} from 'containers/Grids/SettingsGrid/hooks';

import { OBJECTIVES } from 'store/grids/constants';

import useObjectivesSettings from './hooks/useObjectivesSettings';
import useObjectivesSettingsGrid from './hooks/useObjectivesSettingsGrid';
import useObjectivesGridColumns from './hooks/useObjectivesGridColumns';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useForceVisibleColumns from 'hooks/useForceVisibleColumns';
import useProjectsListLightboxContext from 'hooks/useProjectsListLightbox';

import { getRowId, getDataPath, checkDeleteVisibility, checkDeleteDisabled, makeCheckCheckboxSelection } from './helpers';

import { genericStyles } from './styled';
import useObjectivesSettingsPermissions from 'routes/Settings/Objectives/New/hooks/useObjectivesSettingsPermissions';

const TITLE_FIELD = 'title';

const makeRowClassRules = (isGoalMode = false) => ({
  'metadata-child-row': params => {
    if (!params.data) return false;

    return params.data.status !== 'Active';
  },

  'objective-corp-row': params => {
    return isGoalMode ? params?.data?.level === METADATA_LEVELS.LEVEL_CORP : false;
  },
});

const ObjectivesSettings = ({
  showActionBar = true,
  systemFields,
  organization,
  updateSystemFieldsName,
  users,
  orgIntegrations,
  showIntegrationColumn,
  hideMetadataRoadmaps,
  hasKeyResults,
  hasKeyResults2,
  allowAddKeyResult1,
  allowAddKeyResult2,
  hideArchivedItems,
  isGoalMode = false,
  customHeight,
  forceVisibleFields,
}) => {
  const { openProjectsListLightbox: openProjectsListLightboxAction } = useProjectsListLightboxContext();

  const openProjectsListLightbox = okr => openProjectsListLightboxAction(okr, getObjectiveKeyByLevel(okr));

  // Should be relied on DoDv2 or feature flag? has_metadata_l0
  // Should be relied on DoDv2 or feature flag? has_metadata_corp_level
  const {
    isDodActive: hasCorpLevel,
    isChildDragon,
    organizationAccessControlData,
    isParentDragon,
  } = useOrganizationsAccessControl();

  const {
    objectivesAndKeyResultsWithHierarchy,
    allowedLevel,
    childrenSettingKeys,

    create,
    addObjectiveWithoutSave,
    addKeyResultWithoutSave,
    updateById,
    bulkUpdate,
    bulkDelete,
    merge,
    removeUnsaved,

    switchObjectivesRowOrder,
    switchKeyResultsRowOrder,
    moveKeyResultToObjective,
    moveObjectiveToObjective,

    fetchObjectives,
    openObjectiveDrawer,
  } = useObjectivesSettings({
    hasKeyResults,
    hasKeyResults2,
    hasCorpLevel,
    hideArchivedItems,
    isGoalMode,
  });

  const { handleAddOKRWithoutSave, handleRowDrag } = useObjectivesSettingsGrid({
    addKeyResultWithoutSave,
    addObjectiveWithoutSave,
    switchObjectivesRowOrder,
    switchKeyResultsRowOrder,
    moveKeyResultToObjective,
    fetchObjectives,
    hasCorpLevel,
    moveObjectiveToObjective,
  });

  const { selectionMode, selectedItems, enableSelectionMode, disableSelectionMode, updateSelectedOnAgGridInit } =
    useSettingsGridSelection(OBJECTIVES, { actions: { remove: removeUnsaved } });

  const { permissions, checkAddVisibility, systemFieldKey, canUpdate } = useObjectivesSettingsPermissions({
    organization,
    hasKeyResults,
    hasCorpLevel,
    isGoalMode,
    isChildDragon,
    isParentDragon,
    showActionBar,
    allowedLevel,
  });

  const { columnDefs: objectivesGridColumnDefs, groupColumnDef } = useObjectivesGridColumns(
    canUpdate,
    objectivesAndKeyResultsWithHierarchy,
    {
      openObjectiveDrawer,
      openProjectsLightbox: openProjectsListLightbox,
      allowActions: permissions.allowActions,
      users,
      orgIntegrations,
      showIntegrationColumn,
      hideMetadataRoadmaps,
      hasKeyResults,
      hasKeyResults2,
      allowAddKeyResult1,
      allowAddKeyResult2,
      hasCorpLevel,
      isGoalMode,
      checkAddVisibility,
      handleAddOKRWithoutSave,
    },
  );

  const {
    itemToDelete,
    setItemToDelete,
    showBulkDeleteDialog,
    setShowBulkDeleteDialog,
    deleteProgress,
    bulkDeleteItems,
    mergeLightboxVisible,
    showMergeLightbox,
    hideMergeLightbox,
  } = useSettingsActions({
    settingType: OBJECTIVES,
    selectedItems,
    disableSelectionMode,
    bulkDelete,
  });

  const settingsGridProps = useSettingsGridProps({
    bulkUpdate,
    treeData: true,
  });

  const checkCheckboxSelection = useMemo(() => {
    return makeCheckCheckboxSelection(organization?.id, organizationAccessControlData);
  }, [organization?.id, organizationAccessControlData]);

  const settingsGridColumns = useSettingsGridColumns({
    allowActions: permissions.allowActions,
    selectionMode,
    setItemToDelete,
    items: objectivesAndKeyResultsWithHierarchy,
    deleteDisabled: checkDeleteDisabled,
    deleteVisibility: checkDeleteVisibility,
    checkboxSelection: checkCheckboxSelection,
    headerCheckboxSelection: false,
    canDragRows: permissions.canDragRows,
    checkRowDrag: permissions.checkRowDrag,
  });

  const settingsGridEventHandlers = useSettingsGridEventHandlers({
    settingType: OBJECTIVES,
    items: objectivesAndKeyResultsWithHierarchy,
    focusField: TITLE_FIELD,
    idKey: 'uniqueId',
    actions: {
      update: updateById,
      save: create,
      remove: removeUnsaved,
      drag: handleRowDrag,
    },
  });

  const visibleColumns = useForceVisibleColumns(objectivesGridColumnDefs, forceVisibleFields);

  const columnDefs = useMemo(
    () => [...settingsGridColumns.beforeColumnDefs, groupColumnDef, ...visibleColumns],
    [settingsGridColumns, objectivesGridColumnDefs, groupColumnDef, visibleColumns],
  );

  const gridContext = useMemo(
    () => ({
      selectedItems,
      isTreeView: true,
    }),
    [selectedItems],
  );

  const rowClassRules = useMemo(() => makeRowClassRules(isGoalMode), [isGoalMode]);

  return (
    <>
      {permissions.allowActions && (
        <SettingsActionsToolbar
          settingKey={systemFieldKey}
          childrenSettingKeys={childrenSettingKeys}
          settingType={OBJECTIVES}
          systemFields={systemFields}
          updateSystemFieldsName={updateSystemFieldsName}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          onEnableSelection={enableSelectionMode}
          onCancelSelection={disableSelectionMode}
          setShowBulkDeleteDialog={setShowBulkDeleteDialog}
          showMergeLightbox={showMergeLightbox}
          add={addObjectiveWithoutSave}
          renamePermissions={permissions.rename}
          canMerge={permissions.canMerge}
          canBulkDelete={permissions.canBulkDelete}
          hideAddButton={!permissions.canAddTopLevel}
        />
      )}
      <BaseGrid
        onGridReady={updateSelectedOnAgGridInit}
        rowData={objectivesAndKeyResultsWithHierarchy}
        getDataPath={getDataPath}
        columnDefs={columnDefs}
        defaultColDef={settingsGridColumns.defaultColDef}
        {...settingsGridProps}
        {...settingsGridEventHandlers}
        groupDisplayType="custom"
        getRowId={getRowId}
        context={gridContext}
        rowClassRules={rowClassRules}
        cssStyles={genericStyles}
        height={customHeight ?? settingsGridProps.height}
      />
      <SettingsDialogs
        settingKey={SYSTEM_FIELD.objective}
        settingType={OBJECTIVES}
        showBulkDeleteDialog={showBulkDeleteDialog}
        setShowBulkDeleteDialog={setShowBulkDeleteDialog}
        deleteProgress={deleteProgress}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        bulkDelete={bulkDeleteItems}
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
        mergeLightboxVisible={mergeLightboxVisible}
        hideMergeLightbox={hideMergeLightbox}
        merge={merge}
      />
    </>
  );
};

export default ObjectivesSettings;
