import { useState, useCallback, useMemo, useRef } from 'react';
import { not } from 'ramda';

/**
 * @function useDetailsTableLightboxOnDashboardChart
 *
 * Use configurations to show DetailsTableLightbox based on click slice / bar on the chart
 *
 * @return {Object}
 */
const useDetailsTableLightboxOnDashboardChart = ({ getFilterForLightboxData, getRoadmapTitleForLightboxData } = {}) => {
  const chartRef = useRef();
  const [detailsTableOpen, setDetailsTableOpen] = useState(false);
  const [subFilter, setSubFilter] = useState({});
  const [detailsTableRoadmapTitle, setDetailsTableRoadmapTitle] = useState('');

  const handleCloseDetailsTableLightbox = useCallback(() => {
    setDetailsTableOpen(false);
    setDetailsTableRoadmapTitle('');
  }, [setDetailsTableOpen]);

  const openDetailsTableLightbox = useCallback(
    subFilter => {
      setSubFilter(subFilter);
      setDetailsTableOpen(true);
    },
    [subFilter, setDetailsTableOpen],
  );

  const handleClickOnChartSlice = useCallback(
    evt => {
      if (not(chartRef?.current)) {
        return;
      }

      const activePoints = chartRef.current.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

      if (activePoints.length) {
        const { index, datasetIndex } = activePoints[0];

        const filter = getFilterForLightboxData(index, datasetIndex);

        if (getRoadmapTitleForLightboxData) {
          const roadmapTitle = getRoadmapTitleForLightboxData(index);

          setDetailsTableRoadmapTitle(roadmapTitle);
        }

        openDetailsTableLightbox(filter);
      }
    },
    [openDetailsTableLightbox, getFilterForLightboxData, getRoadmapTitleForLightboxData],
  );

  const projectsSubFilters = useMemo(
    () => ({
      fields: {
        ...subFilter,
      },
    }),
    [subFilter],
  );

  return {
    chartRef,
    detailsTableOpen,
    detailsTableRoadmapTitle,
    handleCloseDetailsTableLightbox,
    openDetailsTableLightbox,
    handleClickOnChartSlice,
    projectsSubFilters,
  };
};

export default useDetailsTableLightboxOnDashboardChart;
