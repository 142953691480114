import React from 'react';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';

import PageSidebarOption from 'design-system/atoms/PageSidebarOption/index';


export default function DividedList({ topOptions = [], bottomOptions = [], className }) {
  return (
    <OptionsList className={className}>
      {topOptions.map(opt => (
        <PageSidebarOption
          key={`top-${opt.label}`}
          id={opt.id}
          active={opt.active}
          href={opt.href}
          label={opt.label}
          onClick={opt.onClick}
        />
      ))}
      <StyledDivider />
      {bottomOptions.map(opt => (
        <PageSidebarOption
          key={`bottom-${opt.label}`}
          id={opt.id}
          active={opt.active}
          href={opt.href}
          label={opt.label}
          onClick={opt.onClick}
        />
      ))}
    </OptionsList>
  );
}

const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 10px 0;
  }
`;
