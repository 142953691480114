import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isFunction from 'lodash/isFunction';
import { defaultTo, prop } from 'ramda';

import MultiSelect from '../MultiSelect';

const defaultToEmptyObject = defaultTo({});
const getId = (idField, object) => prop(idField, defaultToEmptyObject(object))?.toString();
const isIdInValues = (values, id) => Boolean(values.find(v => String(v) === String(id)));

export default class AgGridInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || [],
    };
  }

  onChange = value => {
    let { options } = this.props;
    const { idField = 'id' } = this.props;

    if (isFunction(options)) options = options();

    const rows = options
      .filter(row => isIdInValues(value, getId(idField, row)))
      .sort((row1, row2) => {
        const row1Id = getId(idField, row1);
        const row2Id = getId(idField, row2);

        return value.indexOf(row1Id) - value.indexOf(row2Id);
      });

    this.setState({
      value: rows,
    });
  };

  createOption = value => {
    const {
      createOption,
      data,
      node: {
        data: { id },
      },
    } = this.props;
    
    return createOption(value, id, this.state.value, data);
  };

  getValue = () => {
    return this.state.value;
  };

  isPopup() {
    return true;
  }

  render() {
    const { value } = this.state;
    const { optionsMapper } = this.props;
    const options = isFunction(this.props.options) ? this.props.options() : this.props.options;

    return (
      <MultiSelectContainer>
        <MultiSelect
          options={options}
          optionsMapper={optionsMapper}
          onChange={this.onChange}
          value={value}
          onCreateOption={this.createOption}
          autoFocus
        />
      </MultiSelectContainer>
    );
  }
}

AgGridInput.propTypes = {
  value: PropTypes.array.isRequired,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  optionsMapper: PropTypes.object,
  createOption: PropTypes.func,
};

AgGridInput.defaultProps = {
  createOption: () => Promise.resolve,
  options: [],
  optionsMapper: {
    label: 'title',
    value: 'id',
  },
};

const MultiSelectContainer = styled.div`
  width: 200px;
`;
