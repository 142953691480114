import { useMemo, useRef, useEffect, useCallback } from 'react';

/**
 * @param methods {{ [string]: function }} A method name mapping to the method.
 * Should be memoized.
 */
const useInitAgGrid = (methods = {}) => {
  const gridIsReadyRef = useRef(false);

  const gridApiRef = useRef(null);

  const gridColumnApiRef = useRef(null);

  const initAgGrid = (gridApi, gridColumnApi) => {
    gridIsReadyRef.current = true;

    gridApiRef.current = gridApi;

    gridColumnApiRef.current = gridColumnApi;
  };

  const isGridReady = useCallback(() => gridIsReadyRef.current, []);

  const getGridApi = useCallback(() => gridApiRef.current, []);

  const getGridColumnApi = useCallback(() => gridColumnApiRef.current, []);

  /**
   * Inserts the APIs and the ready flag as the first argument to the helper methods
   * passed as the hook's argument.
   * Exposes the same methods, without the need for the caller to pass the Grid APIs, etc..
   */
  const decoratedMethods = useMemo(() => {
    return Object.entries(methods).reduce((decoratedMethods, [methodName, method]) => {
      return {
        ...decoratedMethods,
        [methodName]: (...args) => {
          const gridOptions = {
            gridIsReady: isGridReady(),
            gridApi: getGridApi(),
            gridColumnApi: getGridColumnApi(),
          };

          return method(gridOptions, ...args);
        },
      };
    }, {});
  }, [methods]);

  useEffect(() => {
    /*
     * On unmount will force to exit editing state if it is editing
     *
     * This avoid crash when move to other route or if hide the current
     * grid component and the grid still with editing state active
     */
    return () => {
      if (isGridReady() && getGridApi()?.getEditingCells()?.length > 0) {
        getGridApi().stopEditing();
      }
    };
  }, []);

  return {
    isGridReady,
    getGridApi,
    getGridColumnApi,

    initAgGrid,

    methods: decoratedMethods,
  };
};

export default useInitAgGrid;
