import React from 'react';
import styled from 'styled-components';

import { spacing } from 'design-system/theme';

export default function PageSidebarOption({ active, href, id, label, onClick }) {
  return (
    <Option id={!!id && id} active={active} href={!!href && href} onClick={!!onClick && onClick} target={!!href && '_blank'}>
      {label}
    </Option>
  );
}

const Option = styled.a`
  padding: ${spacing(1.5)}px 0;
  cursor: pointer;
  text-decoration: none;
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  color: ${({ theme }) => theme.palette.newLayout.background.disable};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};

  ${({ active, theme }) =>
    active &&
    `
    color: ${theme.palette.primary.main};
  `}

  &:visited {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.newLayout.background.disable};
  }

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: none;
  }
`;
