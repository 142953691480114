export default (projects, tasks) => {
  return projects.map(project => {
    const projectTasks = tasks.filter(task => project.id === task.project_id);

    return {
      ...project,
      tasks: projectTasks.sort((a, b) => a && b && a.sort_order - b.sort_order),
    };
  });
};
