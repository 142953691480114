import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getDefaultPhaseByPlanningStage, getPhases } from 'store/phases/selectors';

import { getDefaultFormData, DEFAULT_PLANNING_STAGE } from 'constants/projectLightbox';
import useSystemFields from 'hooks/useSystemFields';

export default function (layer, isMilestone, hasMetadataRoadmaps = false) {
  const [getSystemFieldName] = useSystemFields();
  const defaultData = getDefaultFormData(getSystemFieldName, layer, isMilestone, hasMetadataRoadmaps);
  const defaultPhase = useSelector(state => getDefaultPhaseByPlanningStage(state, DEFAULT_PLANNING_STAGE));
  const phases = useSelector(getPhases);

  const highestRoadmapPhase = useMemo(() => {
    if (hasMetadataRoadmaps) {
      const filtered = phases.filter(phase => !phase.phase_roadmaps?.length);

      if (filtered.some(phase => phase.planning_stage === DEFAULT_PLANNING_STAGE)) {
        return filtered.find(phase => phase.planning_stage === DEFAULT_PLANNING_STAGE);
      }
      return filtered[0];
    }

    return null;
  }, [hasMetadataRoadmaps, phases]);

  const defaultPhaseTitle = useMemo(() => {
    if (hasMetadataRoadmaps && highestRoadmapPhase) {
      return highestRoadmapPhase.title;
    }

    return defaultPhase?.title || DEFAULT_PLANNING_STAGE;
  }, [defaultPhase, highestRoadmapPhase]);

  return {
    ...defaultData,
    phaseTitle: defaultPhaseTitle,
  };
}
