import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import IdeasListControlsBar from 'containers/IdeasList/IdeasListControlsBar';
import { CommentsLightboxProvider } from 'containers/IdeasList/IdeasList/New/hooks/useCommentsLightbox';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import { ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PAGE } from 'constants/filters/pages';

import CompareRoadmapHistorySnapsGrid from './CompareRoadmapHistorySnapsGrid';
import ShowColumnsDialogRoadmapHistory from './components/ShowColumnsDialogRoadmapHistory';

import { selectCurrentProjects } from 'features/RoadmapHistory/store/selectors';
import useLoadProjectsForSnapComparison from '../../hooks/useLoadProjectsForSnapComparison';

import { COMPARE_ROADMAP_HISTORY } from '../../constants';
import RoadmapHistoryLeftControls from './components/RoadmapHistoryLeftControls';

const viewType = COMPARE_ROADMAP_HISTORY;
const pageId = ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PAGE;

const CompareRoadmapHistorySnapsList = () => {
  const { canView } = usePermissions();

  const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

  useLoadProjectsForSnapComparison();

  const currentProjects = useSelector(selectCurrentProjects);

  const [grid, setGrid] = useState(null);
  const _onGridReady = useCallback(params => setGrid({ gridApi: params.api, columnApi: params.columnApi }), []);
  const _getGridApi = () => grid;

  return (
    <div>
      {shouldDisplayControlsBar ? (
        <>
          <IdeasListControlsBar
            viewType={viewType}
            pageId={pageId}
            numberOfRows={currentProjects.length}
            getGridApi={_getGridApi}
            showEstimatesFields={false}
            customShowFieldsDialogComponent={ShowColumnsDialogRoadmapHistory}
            GroupByControlsComponent={RoadmapHistoryLeftControls}
            showAdditionalFields
            showCustomFields
          />
        </>
      ) : (
        <EmptyHeight />
      )}
      <CommentsLightboxProvider>
        <CompareRoadmapHistorySnapsGrid viewType={viewType} data={currentProjects} onGridReady={_onGridReady} />
      </CommentsLightboxProvider>
    </div>
  );
};

const EmptyHeight = styled.div`
  height: 48px;
`;

export default CompareRoadmapHistorySnapsList;
