import React from 'react';
import styled from 'styled-components';

import History from 'components/History';

export default ({ history, systemFields, fetchNextPage, hasNext }) => {
  return (
    <Wrapper>
      <History objects={history} hasNext={hasNext} fetchNextPage={fetchNextPage} systemFields={systemFields} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-height: 560px;
`;
