import { pathOr } from 'ramda';
import { useSelector } from 'react-redux';
import { FeatureFlags } from '@dragonboat/config';

import { METADATA_LEVELS } from 'constants/common';
import { PORTFOLIO_GOALS_WIDGET_ID, PORTFOLIO_METRICS_WIDGET_ID, PORTFOLIO_SUB_ROADMAPS_WIDGET_ID } from '../constants/widgetIds';

import useFeatureFlags from 'hooks/useFeatureFlags';
import {
  selectIsPortfolioOverviewOkrsDataEmpty,
  selectPortfolioOverviewSubRoadmapsDataEmpty,
  selectIsPortfolioOverviewMetricsDataEmpty,
} from '../store';

/**
 * Whether to hide widgets based on organization / business rules
 * @param {Object} portfolioOverview
 * @returns
 */
const useWidgetsOverrideVisibilityConditions = portfolioOverview => {
  const hasProducts = useFeatureFlags([FeatureFlags.HAS_PRODUCTS]);
  const hasProducts2 = useFeatureFlags([FeatureFlags.HAS_PRODUCTS_2]);

  const shouldHideProducts = [METADATA_LEVELS.LEVEL_1].includes(portfolioOverview.roadmap_type) && !hasProducts;
  const shouldHideProducts2 = [METADATA_LEVELS.LEVEL_2].includes(portfolioOverview.roadmap_type) && !hasProducts2;

  const roadmapsDataIsEmpty = useSelector(selectPortfolioOverviewSubRoadmapsDataEmpty);
  const okrsDataIsEmpty = useSelector(selectIsPortfolioOverviewOkrsDataEmpty);
  const metricsDataIsEmpty = useSelector(selectIsPortfolioOverviewMetricsDataEmpty);

  const conditions = {
    [PORTFOLIO_SUB_ROADMAPS_WIDGET_ID]: {
      shouldHide: shouldHideProducts || shouldHideProducts2 || roadmapsDataIsEmpty,
    },
    [PORTFOLIO_METRICS_WIDGET_ID]: {
      shouldHide: metricsDataIsEmpty,
    },
    [PORTFOLIO_GOALS_WIDGET_ID]: {
      shouldHide: okrsDataIsEmpty,
    },
  };

  const shouldHide = widgetId => pathOr(false, [widgetId, 'shouldHide'])(conditions);

  return {
    shouldHide,
  };
};

export default useWidgetsOverrideVisibilityConditions;
