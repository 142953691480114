import React from 'react';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@material-ui/core/Popover';
import ViewIcon from '@material-ui/icons/ViewCompactOutlined';

import SearchableDndList from 'design-system/molecules/SearchableDndList/index';
import SearchableDndListWithTabs from 'design-system/molecules/SearchableDndListWithTabs/index';
import ToggleButton from 'design-system/molecules/ToggleButton/index';

import useViewDropdownTabs from './hooks/useViewDropdownTabs';
import theme, { spacing } from 'design-system/theme';

const DROPDOWN_POPOVER_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    horizontal: 'right',
  },
};

const EXTERNAL_DROPDOWN_POPOVER_PROPS = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

const POPOVER_WIDTH_WITH_TABS = 390;
const POPOVER_REGULAR_WIDTH = 280;

export default ({
  anchorEl,
  setAnchorEl,
  isDropdownOpen,
  closeDropdown,
  buttonTitle,
  isToggled,
  viewsOptions,
  id,
  renderListItem,
  renderListHeaderOptions,
  listItemsRightActions,
  shouldRenderToggleButton,
  viewsDropdownTabs,
}) => {
  const isPopoverOpen = shouldRenderToggleButton ? !!anchorEl : isDropdownOpen;
  const popoverProps = shouldRenderToggleButton ? DROPDOWN_POPOVER_PROPS : EXTERNAL_DROPDOWN_POPOVER_PROPS;
  const { tabs, checkIfTabIsDisabled } = useViewDropdownTabs({ viewsDropdownTabs });

  const showPopoverWithTabs = tabs.length > 1;

  const PopoverComponent = showPopoverWithTabs ? SearchableDndListWithTabs : SearchableDndList;
  const popoverWidth = showPopoverWithTabs ? POPOVER_WIDTH_WITH_TABS : POPOVER_REGULAR_WIDTH;

  const renderCustomEmptyOptions = () => {
    return <EmptyMessage>No views</EmptyMessage>;
  };

  const renderCustomListItem = view => renderListItem(view, showPopoverWithTabs);

  return (
    <>
      {shouldRenderToggleButton ? (
        <ToggleButton
          title={buttonTitle}
          on={isToggled}
          data-test="share-button"
          data-cy="saved-views-dropdown"
          icon={<ViewIcon />}
          onChange={e => setAnchorEl(e.currentTarget)}
          id={id}
        />
      ) : null}
      <StyledPopover anchorEl={anchorEl} open={isPopoverOpen} {...popoverProps}>
        <ClickAwayListener onClickAway={closeDropdown}>
          <PopoverComponent
            items={viewsOptions.map(view => ({ ...view, label: view.name }))}
            renderCustomItem={renderCustomListItem}
            renderCustomHeaderOptions={renderListHeaderOptions}
            renderCustomFooter={() => null}
            itemRightActions={listItemsRightActions}
            checkable={false}
            width={popoverWidth}
            hideSearchIfNoItems
            searchPlaceholder="Search views"
            renderCustomEmptyOptions={renderCustomEmptyOptions}
            tabs={tabs}
            checkIfTabIsDisabled={checkIfTabIsDisabled}
          />
        </ClickAwayListener>
      </StyledPopover>
    </>
  );
};

const StyledPopover = styled(Popover)``;

const EmptyMessage = styled.div`
  margin: ${spacing(2)}px ${spacing(5)}px;
  font-size: ${theme.typography.fontSize}px;
  font-style: italic;
  color: ${theme.palette.text.secondary};
  text-align: center;
`;
