import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { head } from 'ramda';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useProjectAccessControl from 'hooks/useProjectAccessControl';
import { INTEGRATIONS_KEYS } from 'constants/integrations';
import MultiOrganizationAccessDropdown from '../components/MultiOrganizationAccessDropdown';
import DragonboatLogo from 'design-system/atoms/DragonLogo/DragonboatIntegrationLogo';
import DragonboatV2 from '../DragonboatV2';

const _renderDoDv2Tab =
  (selectedProject, orgsNotSharedWith, selectedOrganizationAccess, onSelectOrganizationAccess, Tab, TabIcon) => active =>
    (
      <Tab
        component="div"
        disableTouchRipple
        disableRipple
        value={INTEGRATIONS_KEYS.dodV2}
        label={
          <>
            <TabIcon active={active} visible>
              <DragonboatLogo />
            </TabIcon>
            {orgsNotSharedWith.length ? (
              <MultiOrganizationAccessDropdown
                selectedProject={selectedProject}
                selectedOrgAccess={selectedOrganizationAccess}
                onChange={onSelectOrganizationAccess}
                childrenDragons={orgsNotSharedWith}
              />
            ) : null}
          </>
        }
      />
    );

const useDoDV2asIntegration = (selectedProject, isCurrentProjectBetOrInitiative, Tab, TabIcon) => {
  const { isParentDragon, childrenDragons } = useOrganizationsAccessControl();
  const { currentProjectReadersOrgIds } = useProjectAccessControl(selectedProject.id);
  const shouldShowDoDv2 = isParentDragon && isCurrentProjectBetOrInitiative;

  const orgsNotSharedWith = useMemo(
    () => childrenDragons.filter(childAccess => !currentProjectReadersOrgIds.includes(childAccess.child_organization_id)),
    [currentProjectReadersOrgIds, childrenDragons],
  );
  const [selectedOrganizationAccess, setSelectedOrganizationAccess] = useState(null);

  useEffect(() => {
    setSelectedOrganizationAccess(head(orgsNotSharedWith));
  }, [orgsNotSharedWith]);

  const renderDoDv2Tab = _renderDoDv2Tab(
    selectedProject,
    orgsNotSharedWith,
    selectedOrganizationAccess,
    setSelectedOrganizationAccess,
    Tab,
    TabIcon,
  );

  const renderDoDv2asIntegration = useCallback(
    () =>
      shouldShowDoDv2 ? (
        <DragonboatV2 selectedProject={selectedProject} selectedOrganizationAccess={selectedOrganizationAccess} />
      ) : null,
    [selectedProject, selectedOrganizationAccess, shouldShowDoDv2],
  );

  return {
    shouldShowDoDv2,

    // Renders
    renderDoDv2Tab,
    renderDoDv2asIntegration,
  };
};

export default useDoDV2asIntegration;
