import React from 'react';
import styled from 'styled-components';
import isNumber from 'lodash/isNumber';

import { materialColors } from 'design-system/themes/default';


export default ({ total, inProgress, closed, noMargin, noColor, ...props }) => {
  if (!isNumber(total) || !isNumber(inProgress) || !isNumber(closed)) return '';

  return (
    <Lower noMargin={noMargin} {...props}>
      Total: <span>{total}</span>,&nbsp;
      In Progress: <span className={noColor ? '' : 'material--blue'}>{inProgress}</span>,&nbsp;
      Closed: <span className={noColor ? '' : 'material--green'}>{closed}</span>
    </Lower>
  );
};

const Lower = styled.div`
  font-size: 11px;
  margin: ${({ noMargin }) => noMargin ? '0' : '8px 6px'};
  color: ${materialColors.darkerGray};

  span {
    font-size: 12px;
  }
`;
