import React, { useMemo, useCallback } from 'react';
import { css } from 'styled-components';

import theme from 'design-system/theme';
import { useCommonGridProps } from 'design-system/molecules/AgGridReact-New/hooks';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';

import { BaseGrid } from 'containers/Grids';
import ProjectType from 'containers/ProjectType';
import useSystemFields from 'hooks/useSystemFields';
import useProjectGroups from 'hooks/useProjectGroups';
import useOrganizations from 'hooks/useOrganizations';
import { INITIATIVE_LAYER, BET_LAYER, IDEA_LAYER } from 'store/projects/constants';
import { DEFAULT_GROUP_OPTION, getGroupOptions } from 'store/projects/helpers/groupOptions';
import setIdeasHierarchyPath from 'utils/legacy/setIdeasHierarchyPath';
import { hideAllEmpty } from 'utils/projects/grouping';
import ProjectCellRenderer from './ProjectCellRenderer';
import TitleCellInnerRenderer from './TitleCellInnerRenderer';
import { useSelector } from 'react-redux';
import { getOrgCustomUserProjectFields, getOrgHasCustomUserProjectFields } from 'store/organization';

const NULL_ID = 'null-id';
const NEW_ID = 'new-id';
const N_OF_ROWS_TO_DISPLAY = 5;
const WIDTH_SCROLLBAR_OFFSET = 16;

const DEFAULT_GROUPS = {
  selectedGroup1Key: 'roadmap',
  selectedGroup2Key: 'product1',
};

const GRID_OPTIONS = {
  suppressAggFuncInHeader: true,
  toolPanelSuppressSideButtons: true,
  groupSelectsChildren: false,
  enableRowGroup: false,
  groupDefaultExpanded: -1,
  rowClassRules: {
    'metadata-parent-row': params => {
      return !params.data || [INITIATIVE_LAYER, BET_LAYER].includes(params.data.layer);
    },
  },
};

const boldTextStyle = css`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

const createNewProjectTextStyles = css`
  ${boldTextStyle};
  color: ${theme.palette?.background.primary};
`;

const ListComponent = ({
  data,
  displayLayer,
  width,
  height,
  rowHeight,
  inputValue,
  onProjectCreation = null,
  handleProjectClick,
  indentation,
  nullOption,
  groups = DEFAULT_GROUPS,
  renderTitle,
  treeView = true,
}) => {
  const createProjectIsEnabled = useMemo(() => !!onProjectCreation, [onProjectCreation]);

  const [getSystemFieldName] = useSystemFields();

  const { hasProducts } = useOrganizations();

  const commonGridProps = useCommonGridProps({
    isTreeView: treeView,
  });

  const hasCustomUserFields = useSelector(getOrgHasCustomUserProjectFields);
  const customUserFields = useSelector(getOrgCustomUserProjectFields);

  const groupOptions = useMemo(
    () =>
      getGroupOptions({
        getSystemFieldName,
        hasProducts,
        withNullOption: true,
        customUserFields: hasCustomUserFields ? customUserFields : {},
      }),
    [getSystemFieldName],
  );

  const selectedGroup1 = useMemo(() => groupOptions.find(go => go.key === groups.selectedGroup1Key), [groupOptions]);

  const selectedGroup2 = useMemo(() => groupOptions.find(go => go.key === groups.selectedGroup2Key), [groupOptions]);

  const selectedGroup3 = DEFAULT_GROUP_OPTION;

  const projectGroups = useProjectGroups({
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    withHierarchy: true,
    customAllProjectsByLayer: data,
    applyPageFiltersToMetadataGroup: false,
    hideEmptyHandler: hideAllEmpty,
  });

  const hierarchicalData = useMemo(() => {
    // if tree view is not active should get flat list of entities
    if (!treeView) {
      return data;
    }

    return setIdeasHierarchyPath(
      data,
      displayLayer,
      getSystemFieldName('initiative', false),
      getSystemFieldName('bet', false),
      false,
      projectGroups,
    );
  }, [treeView, data, projectGroups]);

  const rowData = useMemo(() => {
    const dataWithNullOption = nullOption
      ? [
          {
            id: NULL_ID,
            title: '',
            path: ['  '],
          },
          ...hierarchicalData,
        ]
      : hierarchicalData;

    return createProjectIsEnabled && inputValue
      ? [
          {
            id: NEW_ID,
            title: inputValue,
            path: [`Create "${inputValue}"`],
          },
          ...dataWithNullOption,
        ]
      : dataWithNullOption;
  }, [nullOption, hierarchicalData, createProjectIsEnabled, inputValue]);

  const columnDefs = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Title',
        suppressMovable: true,
        comparator: stringComparator,
        headerClass: 'first-field',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: params => ({
          innerRenderer: TitleCellInnerRenderer,
          suppressCount: true,
          suppressDoubleClickExpand: true,
          getNumberOfTextRowsToDisplayOnGridCell: () => 1,
        }),
        onCellClicked: handleProjectClick,
        pinned: 'left',
        lockPinned: true,
        width: rowData?.length > N_OF_ROWS_TO_DISPLAY ? width - WIDTH_SCROLLBAR_OFFSET : width,
      },
    ],
    [rowData, handleProjectClick],
  );

  const autoGroupColumnDef = useMemo(
    () => ({
      field: 'title',
      width,
      rowDragManaged: false,
      pinned: 'left',
      lockPinned: true,
      editable: false,
      onCellClicked: handleProjectClick,
      valueFormatter: row => (row?.data?.id === NEW_ID ? `Create "${row.value}"` : row.value),
      cellRendererParams: {
        autoHeight: true,
        suppressCount: true,
        cellClass: 'group-cell',
        innerRenderer: params => {
          if (!params.data) {
            return <ProjectCellRenderer params={params} indentation={indentation} />;
          }

          switch (params.data && +params.data.layer) {
            case +BET_LAYER:
            case +INITIATIVE_LAYER:
            case +IDEA_LAYER: {
              return (
                <ProjectCellRenderer
                  icon={<ProjectType layer={params.data.layer} />}
                  params={params}
                  rowHeight={rowHeight}
                  indentation={indentation}
                  showTooltip
                  renderTitle={renderTitle}
                />
              );
            }
            default: {
              const isNewProjectData = NEW_ID === params.data.id;

              return (
                <ProjectCellRenderer
                  params={params}
                  rowHeight={rowHeight}
                  textStyles={isNewProjectData ? createNewProjectTextStyles : boldTextStyle}
                  indentation={indentation}
                  renderTitle={renderTitle}
                />
              );
            }
          }
        },
      },
    }),
    [getSystemFieldName, rowHeight, indentation, handleProjectClick],
  );

  const getDataPath = useCallback(d => d.path || [], []);

  return (
    <BaseGrid
      {...commonGridProps}
      rowData={rowData}
      treeData={treeView}
      rowHeight={rowHeight}
      height={height}
      getDataPath={getDataPath}
      getRowId={({ data }) => data.uniqueId || data.id}
      gridOptions={GRID_OPTIONS}
      autoGroupColumnDef={autoGroupColumnDef}
      columnDefs={columnDefs}
      suppressGroupRowsSticky
    />
  );
};

export default ListComponent;
