import React, { useCallback } from 'react';
import styled from 'styled-components';

import Header from './components/Header';
import Toolbar from './components/Toolbar';
import BenefitCharts from './components/BenefitCharts';
import Swimlane from './components/Swimlane';
import useOneClickPreview, { BENEFIT_CHARTS, GANTT, SWIMLANE } from './useOneClickPreview';
import useOneClickPlan from 'hooks/useOneClickPlan';
import ForecastTimeline from 'routes/Forecast/Timeline';
import { Wrapper as GanttControlsBar } from 'containers/GanttControlsBar/Component';
import { GanttDiv } from 'containers/GanttTimeline/GanttContainer/Component';

const PreviewScreen = ({ onClose, selectedPlanningLabel }) => {
  const {
    pageTabs,
    currentPage,
    onChangePage,
    oneClickRoadmapVersion,
    dateInterval,
    onDateIntervalChange,
    stackedByOption,
    onStackedByOptionChange,
    stackedByOptions,
  } = useOneClickPreview();

  const { toggleOneClickPlanMode, saveOneClickPlanScenario } = useOneClickPlan();

  const saveGeneratedPlan = useCallback(() => {
    saveOneClickPlanScenario(false);
  }, [saveOneClickPlanScenario]);

  const isBenefitChartsTab = currentPage === BENEFIT_CHARTS;
  const isSwimlaneTab = currentPage === SWIMLANE;
  const isGanttTab = currentPage === GANTT;

  return (
    <Container>
      <Header
        onClose={onClose}
        onSavePlan={saveGeneratedPlan}
        generateNewPlan={toggleOneClickPlanMode}
        selectedPlanningLabel={selectedPlanningLabel}
      />
      <Toolbar
        pageTabs={pageTabs}
        currentPage={currentPage}
        onChangePage={onChangePage}
        oneClickRoadmapVersion={oneClickRoadmapVersion}
        dateInterval={isBenefitChartsTab ? dateInterval : null}
        onDateIntervalChange={onDateIntervalChange}
      />
      <Content>
        {isGanttTab && (
          <ForecastTimeline
            showAllDependencies
            hideDependencyLines={false}
            showDependenciesButton={false}
            hideAllocationReportButton
            hideMoreOptionsMenu
            ganttHeight="calc(100vh - 250px)"
          />
        )}
        {isSwimlaneTab && <Swimlane oneClickRoadmapVersion={oneClickRoadmapVersion} />}
        {isBenefitChartsTab && (
          <BenefitCharts
            oneClickRoadmapVersion={oneClickRoadmapVersion}
            dateInterval={dateInterval}
            stackedByOption={stackedByOption}
            onStackedByOptionChange={onStackedByOptionChange}
            stackedByOptions={stackedByOptions}
          />
        )}
      </Content>
    </Container>
  );
};

export default PreviewScreen;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  flex: 1;
  padding: ${({ theme }) => `${theme.spacing.unit * 10}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`};

  &&&& {
    ${GanttControlsBar} {
      padding: 0;

      > div {
        padding-top: 0;
      }
    }
  }

  ${GanttDiv} {
    margin: 0;
  }
`;
