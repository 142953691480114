import { useEffect, useCallback } from 'react';
import pluralize from 'pluralize';
import { useDispatch, useSelector } from 'react-redux';

import useSystemFields from 'hooks/useSystemFields';
import usePageQueryParamFilters from 'hooks/usePageQueryParamFilters';
import { REQUESTS_LIST_PATH } from 'constants/filters/pages';
import { addQueryParamToUrl } from 'utils/queryParamsUtils';
import { OPEN_CUSTOMER_REQUEST_QUERY_PARAM } from 'constants/customerRequests';
import { RECENTLY_CREATED_BY_ME_FILTER_KEY } from 'routes/CustomerRequests/HeaderFilters/constants';

import {
  fetchMissionControlCreatedRequests,
  selectMissionControlHomeCreatedRequests,
  selectIsLoadingMissionControlHomeCreatedRequests,
} from 'features/MissionControlHome/store';

const getTitle = getSystemFieldName => {
  const titleSuffix = 'I created';
  const entityTitle = pluralize(getSystemFieldName('request'));

  return `${entityTitle} ${titleSuffix}`;
};

const useMissionControlCreatedRequests = () => {
  const dispatch = useDispatch();

  const [getSystemFieldName] = useSystemFields();

  const { navigateToPageWithFilter } = usePageQueryParamFilters();

  const requests = useSelector(selectMissionControlHomeCreatedRequests);

  const isLoading = useSelector(selectIsLoadingMissionControlHomeCreatedRequests);

  const fetchRequests = () => dispatch(fetchMissionControlCreatedRequests());

  const openCustomerRequestsDrawer = useCallback(
    row => row && addQueryParamToUrl(OPEN_CUSTOMER_REQUEST_QUERY_PARAM, row.id),
    [addQueryParamToUrl],
  );

  const getRowId = ({ data }) => data.id;

  const goToRequestsPage = () => navigateToPageWithFilter(`/${REQUESTS_LIST_PATH}`, RECENTLY_CREATED_BY_ME_FILTER_KEY);

  useEffect(() => {
    fetchRequests();
  }, []);

  return {
    requests,
    dataLength: requests?.length || 0,
    isLoading,
    title: getTitle(getSystemFieldName),
    entitySystemFieldName: getSystemFieldName('request'),
    openCustomerRequestsDrawer,
    goToRequestsPage,
    getRowId,
  };
};

export default useMissionControlCreatedRequests;
