import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { pluck } from 'ramda';

import {
  updateCustomerRequest as updateCustomerRequestAction,
  addCustomerToCustomerRequest as addCustomerToCustomerRequestAction,
  removeCustomerFromCustomerRequest as removeCustomerFromCustomerRequestAction,
  addTagToCustomerRequest as addTagToCustomerRequestAction,
  removeTagFromCustomerRequest as removeTagFromCustomerRequestAction,
  updateCustomerRequestFormData as updateCustomerRequestFormDataAction,
} from 'store/customerRequests/actions';
import { CUSTOMER_REQUEST_LAYERS } from 'store/customerRequests/constants/layers';
import { getCustomerRequestFormData, getIsCreatingCustomerRequest } from 'store/customerRequests/selectors';
import { updateCustomerRequestPersonas, updateCustomerRequestLifecycles } from 'store/customerRequests';

import { REQUEST_FORM_FIELDS } from 'features/RequestsDetailFieldsLayout/constants';

import useEditRequestPermissions from '../../hooks/useEditRequestPermissions';
import useRequestsDrawerMetadataOptions from '../../hooks/useRequestsDrawerMetadataOptions';
import useDeepEffect from 'hooks/useDeepEffect';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

import { LIFECYCLES, PERSONAS } from 'constants/common';
import { PRODUCT_1_LEVEL, PRODUCT_2_LEVEL, ROADMAP_LEVEL } from 'constants/roadmaps';

const getIds = pluck('id');

export default Component => {
  return props => {
    const dispatch = useDispatch();
    const updateCustomerRequestFormData = data => dispatch(updateCustomerRequestFormDataAction(data));
    const updateCustomerRequest = (id, data) => dispatch(updateCustomerRequestAction(id, data));
    const addCustomerToCustomerRequest = (id, customerId) => dispatch(addCustomerToCustomerRequestAction(id, customerId));
    const removeCustomerFromCustomerRequest = (id, customerId) =>
      dispatch(removeCustomerFromCustomerRequestAction(id, customerId));
    const addTagToCustomerRequest = (id, customerId) => dispatch(addTagToCustomerRequestAction(id, customerId));
    const removeTagFromCustomerRequest = (id, customerId) => dispatch(removeTagFromCustomerRequestAction(id, customerId));
    // we do have a createFormContainer. We should double check why is not being used anymore
    const isCreating = useSelector(getIsCreatingCustomerRequest);

    const formData = useSelector(getCustomerRequestFormData);

    const [changedData, setChangedData] = useState({});
    const [metaNeedsSave, setMetaNeedsSave] = useState(false);
    const [isEmailNotificationDialogOpen, setIsEmailNotificationDialogOpen] = useState(false);

    const originalData = useRef(formData);

    const { isDodActive } = useOrganizationsAccessControl();

    useDeepEffect(() => {
      if (originalData.current.id !== formData.id) setChangedData({ customers: formData.customers, tags: formData.tags });
      else {
        const newChangedData = Object.entries(formData).reduce((newChangedData, [field, value]) => {
          if (!isEqual(originalData.current[field], value)) {
            return { ...newChangedData, [field]: value };
          }
          return newChangedData;
        }, changedData);

        setChangedData(newChangedData);
      }
      originalData.current = formData;
    }, [formData]);

    useEffect(() => {
      if (!metaNeedsSave || !originalData.current.id || (!changedData.customers.length && !changedData.tags.length)) return;

      // if user adds customer/tag prior to creation, this covers edge case
      _updateRequestMetadata(changedData);
    }, [changedData]);

    const data = useMemo(() => ({ ...originalData.current, ...changedData }), [originalData.current, changedData]);

    const {
      allTags,
      tagsOptions,
      allCustomers,
      customersOptions,
      roadmapsOptions,
      allPersonas,
      personasOptions,
      allLifecycles,
      lifecyclesOptions,
      formattedMetadataOptions,
    } = useRequestsDrawerMetadataOptions(data);

    const _onChangeValue = (fieldName, value) => {
      return setChangedData({ ...changedData, [fieldName]: value });
    };

    const _handleUpdateRoadmapField = async item => {
      const isUndefined = item.key === undefined;
      const isRoadmap = item.level === ROADMAP_LEVEL;
      const isProduct1 = item.level === PRODUCT_1_LEVEL;
      const isProduct2 = item.level === PRODUCT_2_LEVEL;

      let dataToUpdate = {};

      switch (true) {
        case isUndefined:
          dataToUpdate = {
            roadmap_id: null,
            product_1_id: null,
            product_2_id: null,
          };
          break;
        case isRoadmap:
          dataToUpdate = {
            roadmap_id: item.id,
            product_1_id: null,
            product_2_id: null,
          };
          break;
        case isProduct1:
          dataToUpdate = {
            roadmap_id: item.roadmap_id,
            product_1_id: item.id,
            product_2_id: null,
          };
          break;
        case isProduct2:
          dataToUpdate = {
            roadmap_id: item.roadmap_id,
            product_1_id: item.parent_id,
            product_2_id: item.id,
          };
          break;
        default:
          break;
      }

      if (isCreating) {
        return updateCustomerRequestFormData({ ...dataToUpdate });
      }

      return updateCustomerRequest(originalData.current.id, dataToUpdate);
    };

    const onUpdateRequestPersonas = updatedPersonas => {
      isCreating
        ? _updateValue(PERSONAS, updatedPersonas)
        : dispatch(
            updateCustomerRequestPersonas(originalData.current.id, updatedPersonas, getIds(originalData.current?.personas)),
          );
    };

    const onUpdateRequestLifecycles = updatedLifecycles => {
      isCreating
        ? _updateValue(LIFECYCLES, updatedLifecycles)
        : dispatch(
            updateCustomerRequestLifecycles(originalData.current.id, updatedLifecycles, getIds(originalData.current?.lifecycles)),
          );
    };

    const _makeOnChangeMultiselect = field => {
      const actionsByField = {
        [REQUEST_FORM_FIELDS.customers]: { add: _addCustomer, remove: _removeCustomer },
        [REQUEST_FORM_FIELDS.tags]: { add: _addTag, remove: _removeTag },
      };

      return ids => {
        const promises = [];

        if (!actionsByField[field]) {
          return;
        }

        const { add, remove } = actionsByField[field];
        const currentIds = data[field].map(({ id }) => id);

        ids.forEach(id => {
          if (!currentIds.includes(id)) promises.push(add(id));
        });
        currentIds.forEach(id => {
          if (!ids.includes(id)) promises.push(remove(id));
        });
        return Promise.all(promises);
      };
    };

    const _updateValue = async (fieldName, value) => {
      if (fieldName === 'title' && !value) return;

      if (fieldName === 'parent_id') {
        const update = {
          parent_id: value,
          layer: value ? CUSTOMER_REQUEST_LAYERS.SUB_REQUEST : CUSTOMER_REQUEST_LAYERS.REQUEST,
        };

        return updateCustomerRequest(originalData.current.id, update);
      }

      if (!originalData.current.id) {
        return updateCustomerRequestFormData({ [fieldName]: value });
      }

      if ([REQUEST_FORM_FIELDS.customers, REQUEST_FORM_FIELDS.tags].includes(fieldName)) {
        return _makeOnChangeMultiselect(fieldName)(value);
      }

      if (fieldName === REQUEST_FORM_FIELDS.roadmap) {
        return _handleUpdateRoadmapField(value);
      }

      if (fieldName === REQUEST_FORM_FIELDS.personas) {
        return onUpdateRequestPersonas(value);
      }

      if (fieldName === REQUEST_FORM_FIELDS.lifecycles) {
        return onUpdateRequestLifecycles(value);
      }

      if (value !== originalData.current[fieldName]) {
        return updateCustomerRequest(originalData.current.id, { [fieldName]: value }).then(() => {
          if (fieldName === 'status') {
            setIsEmailNotificationDialogOpen(true);
          }
        });
      }
    };

    const _updateRequestMetadata = data => {
      const promises = [];

      data.customers.forEach(customer => promises.push(_addCustomer(customer.id)));
      data.tags.forEach(tag => promises.push(_addTag(tag.id)));
      setMetaNeedsSave(false);
      Promise.all(promises);
    };

    const _addCustomer = id => {
      if (originalData.current.id) {
        setMetaNeedsSave(false);
        return addCustomerToCustomerRequest(originalData.current.id, id);
      }
      const customerIds = originalData.current.customer_ids || [];

      customerIds.push(id);
      setMetaNeedsSave(true);
      return updateCustomerRequestFormData({ customer_ids: customerIds });
    };

    const _removeCustomer = id => {
      if (originalData.current.id) {
        return removeCustomerFromCustomerRequest(originalData.current.id, id);
      }
    };

    const _addTag = id => {
      if (originalData.current.id) {
        setMetaNeedsSave(false);
        return addTagToCustomerRequest(originalData.current.id, id);
      }
      const tagIds = originalData.current.tag_ids || [];

      tagIds.push(id);
      setMetaNeedsSave(true);
      return updateCustomerRequestFormData({ tag_ids: tagIds });
    };

    const _removeTag = id => {
      if (originalData.current.id) {
        return removeTagFromCustomerRequest(originalData.current.id, id);
      }
    };

    const _handleCloseEmailNotificationDialog = () => setIsEmailNotificationDialogOpen(false);

    const [canComplexEditRequest, canRoadmapEditRequest, canSimpleEditRequest, canEditCustomRequestFields] =
      useEditRequestPermissions(formData);

    return (
      <Component
        data={data}
        isEditing
        updateValue={_updateValue}
        onChangeValue={_onChangeValue}
        addCustomer={_addCustomer}
        removeCustomer={_removeCustomer}
        addTag={_addTag}
        removeTag={_removeTag}
        canSimpleEditRequest={canSimpleEditRequest}
        canRoadmapEditRequest={canRoadmapEditRequest}
        canComplexEditRequest={canComplexEditRequest}
        canEditCustomRequestFields={canEditCustomRequestFields}
        isEmailNotificationDialogOpen={isEmailNotificationDialogOpen}
        onCloseEmailNotificationDialog={_handleCloseEmailNotificationDialog}
        allTags={allTags}
        tagsOptions={tagsOptions}
        allCustomers={allCustomers}
        customersOptions={customersOptions}
        roadmapsOptions={roadmapsOptions}
        allPersonas={allPersonas}
        personasOptions={personasOptions}
        allLifecycles={allLifecycles}
        lifecyclesOptions={lifecyclesOptions}
        onUpdateRequestPersonas={onUpdateRequestPersonas}
        onUpdateRequestLifecycles={onUpdateRequestLifecycles}
        handleUpdateRoadmapField={_handleUpdateRoadmapField}
        formattedMetadataOptions={formattedMetadataOptions}
        isDodActive={isDodActive}
        {...props}
      />
    );
  };
};
