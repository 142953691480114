import React, { useMemo } from 'react';

import { ActionsWrapper, ActionButton, StyledAddIcon } from './styled';

const renderAddButton = (params, addChildVisible, onClick, disabled = false) => (
  <ActionButton hideButton={!addChildVisible} onClick={() => onClick(params.data, params)} disabled={disabled}>
    <StyledAddIcon />
  </ActionButton>
);

const Actions = params => {
  const { checkAddVisibility, handleAddWithoutSave, disabled = false } = params;

  const addChildVisible = checkAddVisibility && checkAddVisibility(params);

  const addButton = useMemo(
    () => renderAddButton(params, addChildVisible, handleAddWithoutSave, disabled),
    [params, addChildVisible, handleAddWithoutSave, disabled],
  );

  return <>{addButton ? <ActionsWrapper className="actions">{addButton}</ActionsWrapper> : null}</>;
};

export default Actions;
