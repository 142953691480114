import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { head, isNil, not, pipe } from 'ramda';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

import useIntegrations from 'hooks/useIntegrations';
import useUserIntegrations from 'hooks/useUserIntegrations';

import { selectSelectedOrgIntegrationId, setSelectedOrgIntegrationId } from 'store/organization';
import { getOrgHasMultipleIntegrations as getOrgHasMultipleIntegrationsSelector } from 'store/organization/selectors';

const isNotNil = pipe(isNil, not);

const useRallyIntegrationSettings = () => {
  const dispatch = useDispatch();

  const [isConfirmDialogDisplayed, setIsConfirmDialogDisplayed] = useState(false);
  const [isAddingOrgIntegration, setIsAddingOrgIntegration] = useState(false);

  const hasMultipleIntegrations = useSelector(getOrgHasMultipleIntegrationsSelector);
  const selectedOrgIntegrationId = useSelector(selectSelectedOrgIntegrationId);

  const { organizationIntegrations, removeOrgIntegration } = useIntegrations(INTEGRATIONS_KEYS.rally);

  const { clearLoadedUserIntegrations } = useUserIntegrations(INTEGRATIONS_KEYS.rally, selectedOrgIntegrationId);

  const selectedOrgIntegration = useMemo(
    () => organizationIntegrations.find(integration => integration.id === selectedOrgIntegrationId),
    [organizationIntegrations, selectedOrgIntegrationId],
  );

  const onIntegrationSwitchChangeHandler = useCallback(
    ({ target }) => {
      if (target?.checked) {
        setIsAddingOrgIntegration(true);
      } else if (!selectedOrgIntegrationId) {
        setIsAddingOrgIntegration(false);
      } else {
        setIsConfirmDialogDisplayed(true);
      }
    },
    [selectedOrgIntegrationId],
  );

  const onClickDisconnect = useCallback(() => {
    setIsConfirmDialogDisplayed(true);
  }, []);

  const onRemoveOrgIntegrationHandler = useCallback(() => {
    if (selectedOrgIntegrationId) {
      removeOrgIntegration(selectedOrgIntegrationId).then(() => {
        clearLoadedUserIntegrations();

        dispatch(setSelectedOrgIntegrationId(null));
      });
    }

    setIsConfirmDialogDisplayed(false);
    setIsAddingOrgIntegration(false);
  }, [selectedOrgIntegrationId, removeOrgIntegration]);

  const onCloseRemoveOrgIntegrationConfirmationHandler = useCallback(() => {
    setIsConfirmDialogDisplayed(false);
  }, []);

  const onSelectOrgIntegrationHandler = useCallback(
    orgIntegrationId => dispatch(setSelectedOrgIntegrationId(orgIntegrationId)),
    [],
  );
  const onCreateNewIntegrationHandler = useCallback(() => setIsAddingOrgIntegration(true), []);

  useEffect(() => {
    if (hasMultipleIntegrations) return;

    const existingOrgIntegrationId = head(organizationIntegrations)?.id;
    const existingOrgIntegrationNotSelected = isNil(selectedOrgIntegrationId) && isNotNil(existingOrgIntegrationId);

    if (existingOrgIntegrationNotSelected) {
      dispatch(setSelectedOrgIntegrationId(existingOrgIntegrationId));
    }
  }, [hasMultipleIntegrations, organizationIntegrations, selectedOrgIntegrationId]);

  return {
    selectedOrgIntegration,
    selectedOrgIntegrationId,
    isConfirmDialogDisplayed,
    isAddingOrgIntegration,
    setIsAddingOrgIntegration,
    onClickDisconnect,
    onIntegrationSwitchChangeHandler,
    onRemoveOrgIntegrationHandler,
    onCloseRemoveOrgIntegrationConfirmationHandler,
    onSelectOrgIntegrationHandler,
    onCreateNewIntegrationHandler,
  };
};

export default useRallyIntegrationSettings;
