import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty, match, pipe, trim, isNil, either, head, not, defaultTo } from 'ramda';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { INTEGRATIONS_KEYS, INTEGRATIONS_NAMES } from 'constants/integrations';

const WORKSPACE_REF_REGEX = /\/workspace\/\d+/;

const isNilOrEmpty = either(isNil, isEmpty);
const isNotNil = pipe(isNil, not);
const defaultToNull = defaultTo(null);

const getWorkspaceRefFromIntegrationUri = pipe(match(WORKSPACE_REF_REGEX), head, defaultToNull);

const CreateOrgIntegration = ({ onClickNext }) => {
  const [uri, setUri] = useState('');
  const [workspaceRef, setWorkspaceRef] = useState('');

  const isDisabled = useMemo(() => {
    if (isNilOrEmpty(workspaceRef) || isNilOrEmpty(uri)) {
      return true;
    }

    return false;
  }, [uri]);

  const onChangeHandler = useCallback(({ target }) => {
    const inputValue = trim(target.value);

    const uriWorkspaceRef = getWorkspaceRefFromIntegrationUri(inputValue);

    if (isNotNil(uriWorkspaceRef)) {
      setWorkspaceRef(uriWorkspaceRef);
      setUri(inputValue);
      return;
    }

    setUri(inputValue);
  }, []);

  const onClickNextHandler = useCallback(
    ev => {
      ev.preventDefault();

      const parsedUrl = new URL(uri);
      const newUri = parsedUrl?.origin;

      onClickNext(newUri, workspaceRef);
    },
    [uri],
  );

  return (
    <Wrapper>
      <URLTextField
        id="uri"
        label={`Insert your ${INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.rally]} workspace URL`}
        value={uri}
        onChange={onChangeHandler}
        margin="normal"
        helperText={
          `Copy the URL from the details page of your selected Workspace. It must contain the Workspace ID, ex. '/workspace/12345`
        }
      />
      <NextButton type="submit" color="primary" disabled={isDisabled} onClick={onClickNextHandler}>
        Next
      </NextButton>
    </Wrapper>
  );
};

CreateOrgIntegration.propTypes = {
  onClickNext: PropTypes.func.isRequired,
};

const URLTextField = styled(TextField)``;

const NextButton = styled(Button)`
  &&&& {
    margin-left: 30px;
  }
`;

const Wrapper = styled.form`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;

  ${NextButton} {
    width: 100px;
    height: 40px;
  }

  ${URLTextField} {
    flex-grow: 1;
    margin-top: 0px;
  }
`;

export default CreateOrgIntegration;
