import { GroupCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import statusMap from 'store/customerRequests/constants/status';

const requestStatusColumnDef = {
  cellRenderer: GroupCellRenderer('Status'),
  enableRowGroup: true,
  openByDefault: true,
  field: 'status',
  headerName: 'Status',
  editable: false,
  cellRendererParams: params => ({
    entity: statusMap[params.data?.status],
  }),
  valueGetter: params => (statusMap[params.data?.status] || {}).label,
  width: 140,
  resizable: true,
};

export { requestStatusColumnDef };
