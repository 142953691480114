import { schema, normalize } from 'normalizr';

import { add } from 'utils/store/entities';

import {
  CREATE_CUSTOMER_REQUEST_FROM_PROJECT_FULFILLED,
  ADD_CUSTOMER_REQUEST_FROM_PROJECT_FULFILLED,
  UPDATE_CUSTOMER_REQUESTS_PERSONAS_FULFILLED,
  UPDATE_CUSTOMER_REQUESTS_LIFECYCLES_FULFILLED,
} from '../types';

// TODO: when adding projects to entities, describe projects on this schema.
const customerRequestsEntity = new schema.Entity('customerRequests');

const reducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CUSTOMER_REQUEST_FROM_PROJECT_FULFILLED:
    case ADD_CUSTOMER_REQUEST_FROM_PROJECT_FULFILLED: {
      const { entities } = normalize(action.payload.data, customerRequestsEntity);

      return {
        ...state,
        customerRequests: add(state.customerRequests, entities.customerRequests),
      };
    }
    case UPDATE_CUSTOMER_REQUESTS_LIFECYCLES_FULFILLED:
    case UPDATE_CUSTOMER_REQUESTS_PERSONAS_FULFILLED: {
      const { entities } = normalize(action.payload, customerRequestsEntity);

      return {
        ...state,
        customerRequests: add(state.customerRequests, entities.customerRequests),
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
