import React from 'react';
import styled from 'styled-components';

import useProjectsClustersForMetric from 'hooks/charts/useProjectsClustersForMetric';

export default ({ metricId, datesForChart, timeUnit, chartInstance, onClusterClick }) => {
  const clusters = useProjectsClustersForMetric(metricId, datesForChart, timeUnit, chartInstance);

  return clusters.map(cluster =>
    cluster.projectsIds?.length > 0 ? (
      <Cluster onClick={() => onClusterClick(cluster.projectsIds)} top={cluster.y} left={cluster.x}>
        {cluster.projectsIds.length}
      </Cluster>
    ) : null,
  );
};

export const Cluster = styled.div`
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  background: ${({ theme }) => theme.palette.primary.main};
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;
