import { defaultTo } from 'ramda';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const defaultToEmptyObject = defaultTo(EMPTY_OBJECT);
const defaultToEmptyArray = defaultTo(EMPTY_ARRAY);

export const getState = state => state?.requestsHistory;

export const selectRequestHistory = state => defaultToEmptyArray(getState(state)?.history);

export const selectRequestHistoryMeta = state => defaultToEmptyObject(getState(state)?.meta);
