import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { isNil } from 'ramda';

import useMetrics from 'hooks/grid/metrics/useMetrics';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import {
  addExistingMetricToKeyResult,
  addExistingMetricToObjective,
  removeMetricFromKeyResult,
  removeMetricFromObjective,
} from 'store/objectives';
import { updateMetricById } from 'store/metrics';
import { isObjective } from 'store/objectives/helpers';

import useMetricOptions from 'src/containers/MetricsForm/hooks/useMetricOptions';

/**
 * @function useObjectivesMultipleMetricsForm
 *
 * Use actions to deal with multiple metrics form
 * add, edit, update actions
 *
 * @param  {Object} okr
 * @param  {String} selectedType
 * @return {Object}
 */
export default function useObjectivesMultipleMetricsForm(okr, selectedType) {
  const dispatch = useDispatch();

  const { canView } = usePermissions();
  const hasNewMetadataAutocompleteForMetrics = canView(PERMISSION_FEATURES.newRoadmapMetadataAutocompleteOnMetricsForm);

  const { activeMetrics: metrics } = useMetrics();
  const metricOptions = useMetricOptions(okr, metrics, hasNewMetadataAutocompleteForMetrics);

  // For the new metadata autocomplete we need the whole Metric, while for the 'old' dropdown we only need the name
  const okrMetricOptions = useMemo(
    () => (hasNewMetadataAutocompleteForMetrics ? metricOptions : metricOptions?.map(m => m.name)),
    [metricOptions, hasNewMetadataAutocompleteForMetrics],
  );

  const okrId = okr?.id;

  const onAddMetric = async metricId => {
    if (isNil(metricId)) {
      return;
    }

    if (isObjective(selectedType)) {
      dispatch(addExistingMetricToObjective(okrId, metricId));
      return;
    }

    dispatch(addExistingMetricToKeyResult(okrId, metricId));
  };

  const onRemoveMetric = async id => {
    if (isNil(id)) {
      return;
    }

    if (isObjective(selectedType)) {
      dispatch(removeMetricFromObjective(okrId, id));
      return;
    }

    dispatch(removeMetricFromKeyResult(okrId, id));
  };

  const onUpdateMetric = (metricId, update) => {
    if (isNil(metricId)) {
      return;
    }

    dispatch(updateMetricById(metricId, update));
  };

  return {
    metrics,
    metricsOptions: okrMetricOptions || [],
    onAddMetric,
    onRemoveMetric,
    onUpdateMetric,
  };
}
