import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { keys, defaultTo, values, isNil } from 'ramda';

import { UNDEFINED_LABEL } from 'constants/common';
import { getUserFilters } from 'store/filters/selectors';
import { DASHBOARDS_PAGE } from 'constants/filters/pages';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import calculateTextLines from 'utils/calculateTextLines';
import theme from 'design-system/theme';
import { materialColorsAlt } from 'design-system/themes/default';
import useSystemFields from 'hooks/useSystemFields';
import { getAllNormalizedTimeframes } from 'store/timeframes/selectors';
import { getGroupOptions } from 'store/projects/helpers/groupOptions';
import invertedTextColor from 'design-system/utils/invertedTextColor';
import usePageFilters from 'hooks/filters/usePageFilters';

import { getOrgCustomUserProjectFields, getOrgHasCustomUserProjectFields } from 'store/organization';

const MAX_NUM_LINES = 1;
const MAX_NUM_CHARS_OBJECTIVE = 25;
const FONT_SIZE = 11;
const MAX_WIDTH_FOR_LABEL = 120;
const HAS_PROGRESS_DATA_SET_KEY = 'has_progress_count';
const HAS_NO_PROGRESS_DATA_SET_KEY = 'has_no_progress_count';
const PROGRESS_DATA_SET_KEY = 'progress';
const DEFAULT_CHART_DATA = {
  labels: [],
  datasets: [],
};
const CHART_API_URL = '/api/v1/reports/dashboard/projects-with-goals-progress-by-timeframe';
const TIMEFRAME_KEY = 'timeframe';

const checkHasChartData = (chartData = {}) => Boolean(keys(chartData.datasets).length);
const defaultUndefinedLabel = defaultTo(UNDEFINED_LABEL);
const defaultZero = defaultTo(0);
const defaultToEmptyObject = defaultTo({});

const requestChartData = (filters = {}, userFilters = {}, hasBet, displayLayer) => {
  const projectsFilter = compileFiltersBody(filters, userFilters, hasBet, DASHBOARDS_PAGE, displayLayer, null);

  return axios.post(CHART_API_URL, projectsFilter);
};

const getLabelForTimeframe = (timeframeId, timeframes) => {
  const label = defaultUndefinedLabel(defaultToEmptyObject(timeframes)[timeframeId]?.title);
  const numLines = calculateTextLines(label, MAX_WIDTH_FOR_LABEL, FONT_SIZE);

  if (numLines > MAX_NUM_LINES) {
    return `${label.slice(0, MAX_NUM_CHARS_OBJECTIVE)}...`;
  }

  return label;
};

/**
 * @function useIdeasWithGoalsProgressByTimeframeChartData
 *
 * Use chart data for Ideas with Goals Progress by Timeframe stacked chart
 *
 * @return {Object}
 */
const useIdeasWithGoalsProgressByTimeframeChartData = () => {
  const [state, setState] = useState({
    progressCountersByTimeframe: null,
    loading: false,
  });

  const { pageFilters: filters, displayLayer } = usePageFilters(DASHBOARDS_PAGE);
  const userFilters = useSelector(getUserFilters);
  const hasBet = useSelector(state => state.organization.organization.has_bet);
  const timeframes = useSelector(getAllNormalizedTimeframes);

  const chartData = useMemo(() => {
    if (isNil(state.progressCountersByTimeframe)) {
      return DEFAULT_CHART_DATA;
    }

    const ids = state.progressCountersByTimeframe.map(record => record.id);
    const labels = ids.map(id => getLabelForTimeframe(id, timeframes));

    const percentageFormatter = value => `${value}%`;

    const keyedDatasets = {
      [HAS_PROGRESS_DATA_SET_KEY]: {
        id: HAS_PROGRESS_DATA_SET_KEY,
        label: 'Items in progress',
        data: [],
        backgroundColor: materialColorsAlt.lightBlue,
        hoverBackgroundColor: materialColorsAlt.lightBlue,
        datalabels: {
          labels: {
            value: {
              color: invertedTextColor(materialColorsAlt.lightBlue, true),
            },
          },
        },
        order: 1,
        yAxisID: 'y',
      },
      [HAS_NO_PROGRESS_DATA_SET_KEY]: {
        id: HAS_NO_PROGRESS_DATA_SET_KEY,
        label: 'Items not in progress',
        data: [],
        backgroundColor: materialColorsAlt.lightGray,
        hoverBackgroundColor: materialColorsAlt.lightGray,
        datalabels: {
          labels: {
            value: {
              color: invertedTextColor(materialColorsAlt.lightGray, true),
            },
          },
        },
        order: 1,
        yAxisID: 'y',
      },
      [PROGRESS_DATA_SET_KEY]: {
        id: PROGRESS_DATA_SET_KEY,
        label: '% of items in progress with goals',
        data: [],
        backgroundColor: theme.palette.black,
        hoverBackgroundColor: theme.palette.black,
        datalabels: {
          color: theme.palette.black,
          font: {
            size: theme.typography.fontSizeSmall,
            weight: 'bold',
          },
          formatter: percentageFormatter,
        },
        type: 'line',
        order: 0,
        yAxisID: 'yProgress',
      },
    };

    const [hasProgressData, hasNoProgressData, progressData] = [
      keyedDatasets[HAS_PROGRESS_DATA_SET_KEY].data,
      keyedDatasets[HAS_NO_PROGRESS_DATA_SET_KEY].data,
      keyedDatasets[PROGRESS_DATA_SET_KEY].data,
    ];

    state.progressCountersByTimeframe.forEach((timeframProgressCounters, index) => {
      const hasProgressCount = defaultZero(+timeframProgressCounters.has_progress_count);
      const hasProgressAndGoalCount = defaultZero(+timeframProgressCounters.has_progress_and_goal_count);
      let roundedPercentage = 0;

      if (hasProgressCount > 0) {
        roundedPercentage = Math.round(defaultZero(hasProgressAndGoalCount / hasProgressCount) * 100);
      }

      const count = defaultZero(+timeframProgressCounters.count);
      const hasNoProgressCount = count - hasProgressCount;

      hasProgressData.push(hasProgressCount);
      hasNoProgressData.push(hasNoProgressCount);
      progressData.push(roundedPercentage);
    });

    const data = {
      ids,
      labels,
      datasets: values(keyedDatasets),
    };

    return data;
  }, [state.progressCountersByTimeframe, timeframes]);

  const makeChartData = useCallback(async () => {
    setState(state => ({ ...state, loading: true }));

    try {
      const { data: progressCountersByTimeframe } = await requestChartData(filters, userFilters, hasBet, displayLayer);

      setState({ progressCountersByTimeframe, loading: false });
    } catch {
      setState({ progressCountersByTimeframe: [], loading: false });
    }
  }, [filters, userFilters, hasBet, displayLayer]);

  useEffect(() => {
    makeChartData();
  }, [makeChartData]);

  const hasData = useMemo(() => checkHasChartData(chartData), [chartData]);

  const [getSystemFieldName] = useSystemFields();

  const hasCustomUserFields = useSelector(getOrgHasCustomUserProjectFields);
  const customUserFields = useSelector(getOrgCustomUserProjectFields);

  const groupOptions = useMemo(
    () =>
      getGroupOptions({
        getSystemFieldName,
        hasKeyResults: true,
        withNullOption: true,
        customUserFields: hasCustomUserFields ? customUserFields : {},
      }),
    [getSystemFieldName],
  );

  const selectedGroup1 = useMemo(() => groupOptions.find(go => go.key === TIMEFRAME_KEY), [groupOptions]);
  const hasKeyResults1 = useSelector(state => state.organization.organization.has_key_results);
  const getTimeframeId = useCallback(index => chartData.ids[index], [chartData]);

  return {
    chartData,
    displayLayer,
    hasData,
    loading: state.loading,
    selectedGroup1,
    hasKeyResults1,
    getTimeframeId,
  };
};

export default useIdeasWithGoalsProgressByTimeframeChartData;
export { PROGRESS_DATA_SET_KEY };
