import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTo, not, pipe, prop } from 'ramda';

import MultiSelect from 'design-system/atoms/MultiSelect/index';


import { fetchADOProjects, getADOProjects, isFetchADOProjectLoading, isFetchADOUninitialized } from 'store/integrations';

const getData = pipe(defaultTo({}), prop('data'), defaultTo([]));

const transformToMultiSelectOption = ({ id, name }) => ({ label: name, value: id });

const AzureDevopsProjectsDropdown = props => {
  const { disabled, isSelected, orgIntegrationId, forwardedRef, value, onChange } = props;

  const dispatch = useDispatch();

  const [selectedProjects, setSelectedProjects] = useState(value);

  const isLoading = useSelector(isFetchADOProjectLoading);
  const adoProjectsData = useSelector(getADOProjects);
  const isAdoProjectUninitialized = useSelector(isFetchADOUninitialized);

  useImperativeHandle(forwardedRef, () => ({
    resetSelectedProjects() {
      setSelectedProjects(value);
    },
  }));

  const azureProjects = useMemo(() => {
    return getData(adoProjectsData).map(transformToMultiSelectOption);
  }, [adoProjectsData]);

  const handleOnChange = useCallback(() => {
    return onChange(selectedProjects);
  }, [onChange, selectedProjects]);

  useEffect(() => {
    const isDisabledWithProjectsSelected = disabled && selectedProjects?.length;
    const shouldFetchProjects = not(isLoading) && isAdoProjectUninitialized && (not(disabled) || isDisabledWithProjectsSelected);

    if (shouldFetchProjects) {
      dispatch(fetchADOProjects(orgIntegrationId));
    }
  }, [isLoading, isAdoProjectUninitialized, disabled, selectedProjects?.length]);

  useEffect(() => {
    if (not(isSelected)) {
      setSelectedProjects([]);
    }
  }, [isSelected, setSelectedProjects]);

  return (
    <MultiSelect
      placeholder=""
      isDisabled={disabled}
      isLoading={isLoading}
      options={azureProjects}
      value={selectedProjects}
      onChange={setSelectedProjects}
      onBlur={handleOnChange}
    />
  );
};

AzureDevopsProjectsDropdown.propTypes = {
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  orgIntegrationId: PropTypes.number.isRequired,
  forwardedRef: PropTypes.shape({ current: PropTypes.any }),
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AzureDevopsProjectsDropdown;
