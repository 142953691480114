import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { defaultTo, prop, and } from 'ramda';

import { GLOBAL_FILTER } from 'constants/filters';
import { makeSelectFilteredProjectsByRoadmapForPage } from 'store/filters/selectors';
import { makefilterProjectBasedOnSelectedTeams, makeFilterProjectBasedOnPlanOption } from './helpers';
import { getUnsavedProject } from '../projects';
import { COMMITTED_KEY, INCLUDE_ALL_OPTION } from 'constants/projects';
import { ensureOptionsRetroCompatibility } from 'store/forecastHeadcount/utils';

const defaultEmptyObject = defaultTo({});

const getFilteredProjectsForPage = makeSelectFilteredProjectsByRoadmapForPage();

function getState(state) {
  return state.forecastHeadcount;
}

/**
 * @function getSelectedOverbookedTeams
 *
 * get selected overbooked teams for specific page from the store
 *
 * @param {Object} state
 * @param {String} pageId
 * @return {Object}
 */
const getUnselectedOverbookedTeams = createSelector(
  (state, pageId) => {
    const forecastHeadcountState = getState(state);
    const pageConfig = prop(pageId, forecastHeadcountState);

    return pageConfig?.unselectedOverbookedTeams;
  },
  unselectedOverbookedTeams => {
    return defaultEmptyObject(unselectedOverbookedTeams);
  },
);

/**
 * @function getSelectedUnderbookedTeams
 *
 * get selected underbooked teams for specific page from the store
 *
 * @param {Object} state
 * @param {String} pageId
 * @return {Object}
 */
const getUnselectedUnderbookedTeams = createSelector(
  (state, pageId) => {
    const forecastHeadcountState = getState(state);
    const pageConfig = prop(pageId, forecastHeadcountState);

    return pageConfig?.unselectedUnderbookedTeams;
  },
  unselectedUnderbookedTeams => {
    return defaultEmptyObject(unselectedUnderbookedTeams);
  },
);

/**
 * @function getSelectedOptionsOnFilterToPlan
 *
 * get selected options on filter to plan
 *
 * @param {Object} state
 * @param {String} pageId
 * @return {Object}
 */
const getSelectedOptionsOnFilterToPlan = createSelector(
  (state, pageId) => {
    const forecastHeadcountState = getState(state);
    const pageConfig = prop(pageId, forecastHeadcountState);

    return pageConfig?.selectedOptionsOnFilter;
  },
  selectedOptionsOnFilter => {
    return ensureOptionsRetroCompatibility(defaultEmptyObject(selectedOptionsOnFilter));
  },
);

/**
 * Enrich the project list with the property includeInSums if the filter checkbox selection and the project
 * committed status match
 * @param projects
 * @param selectedOptionsOnFilterToPlan
 * @return {*}
 */
const mapProjectWithAboveTheLineOptions = (projects, selectedOptionsOnFilterToPlan) => {
  const selectedCommittedOption = selectedOptionsOnFilterToPlan[COMMITTED_KEY] ?? INCLUDE_ALL_OPTION;

  // We should only consider uncommitted projects if include all is selected for above the line
  const includeUncommitted = selectedCommittedOption === INCLUDE_ALL_OPTION;

  return projects.map(project => {
    const projectCommitted = project?.committed;

    return { ...project, includeInSums: projectCommitted || and(includeUncommitted, !projectCommitted) };
  });
};

/**
 * @function getFilteredProjectsWithForecastHeadcountFilter
 *
 * gets projects filtered with the forecast headcount filters
 *
 * @return {Array}
 */
const getFilteredProjectsWithForecastHeadcountFilter = createCachedSelector(
  (state, page = GLOBAL_FILTER, onlyProjects = true, layer) => getFilteredProjectsForPage(state, page, onlyProjects, layer),
  (state, page) => getUnselectedOverbookedTeams(state, page),
  (state, page) => getUnselectedUnderbookedTeams(state, page),
  (state, page) => getSelectedOptionsOnFilterToPlan(state, page),
  getUnsavedProject,
  (projects, selectedOverbookedTeams, selectedUnderbookedTeams, selectedOptionsOnFilterToPlan, unsavedProject) => {
    const allTeamsSelected = { ...selectedOverbookedTeams, ...selectedUnderbookedTeams };

    const filterProjectBySelectedTeams = makefilterProjectBasedOnSelectedTeams(allTeamsSelected);

    const filterProjectByPlan = makeFilterProjectBasedOnPlanOption(selectedOptionsOnFilterToPlan);

    const allProjects = projects.filter(filterProjectBySelectedTeams).filter(filterProjectByPlan);
    const enrichedProjectsForAboveTheLineFilter = mapProjectWithAboveTheLineOptions(allProjects, selectedOptionsOnFilterToPlan);

    if (unsavedProject) {
      return [unsavedProject, ...enrichedProjectsForAboveTheLineFilter];
    }

    return enrichedProjectsForAboveTheLineFilter;
  },
)(
  (_, page = GLOBAL_FILTER, onlyProjects = true, layer) =>
    `getFilteredProjectsWithForecastHeadcountFilter-${page}-${onlyProjects}-${layer}`,
);

export {
  getUnselectedOverbookedTeams,
  getUnselectedUnderbookedTeams,
  getFilteredProjectsWithForecastHeadcountFilter,
  getSelectedOptionsOnFilterToPlan,
};
