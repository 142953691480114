import React from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import BarChart from 'components/ReactChart/BarChart';
import {
  STACKED_CHART_ROW_HEIGHT,
  STACKED_CHART_MIN_HEIGHT,
  LIMIT_TO_HIDE_DATALABEL,
  HIDE_LEGEND_OPTION,
  HIDE_GRID_OPTION,
} from 'constants/charts';
import useBarStackedCharDatalabelsConfig from './useBarStackedCharDatalabelsConfig';
import ChartWrapper from 'src/components/ReactChart/utils/ChartWrapper';
import { tickWithPercentageSmallStyles, tickStyles } from 'routes/Dashboard/Dashboards/Charts/utils/scales';

const defaultOptions = {
  hover: {
    animationDuration: 0,
  },
  animation: {
    duration: 0,
  },
  scales: {
    x: {
      min: 0,
      max: 100,
      ticks: {
        ...tickWithPercentageSmallStyles,
        beginAtZero: true,
      },
      scaleLabel: {
        display: false,
      },
      stacked: true,
      grid: {
        ...HIDE_GRID_OPTION,
      },
    },
    y: {
      ticks: {
        ...tickStyles,
      },
      stacked: true,
      afterFit: scaleInstance => {
        scaleInstance.width = 150;
      },
      maxBarThickness: 30,
      grid: {
        ...HIDE_GRID_OPTION,
      },
    },
  },
  plugins: {
    legend: {
      ...HIDE_LEGEND_OPTION,
    },
    tooltip: {
      enabled: true,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

const useProgressStackedChart = ({ generateData, options = {}, setRef, plugins = [], minHeight, maxHeight }) => {
  const chartData = React.useMemo(() => generateData(), [generateData]);

  const datalabelsConfig = useBarStackedCharDatalabelsConfig(chartData.datasets, LIMIT_TO_HIDE_DATALABEL.horizontalStackedChart);

  const chartOptions = React.useMemo(
    () => ({
      ...defaultOptions,
      ...options,
      plugins: {
        ...defaultOptions.plugins,
        ...options.plugins,
        datalabels: datalabelsConfig,
      },
    }),
    [options, datalabelsConfig],
  );

  const height = (chartData?.labels?.length || 0) * STACKED_CHART_ROW_HEIGHT;

  if (height === 0) return '';

  const Chart = (
    <ChartWrapper
      minHeight={minHeight}
      height={height > STACKED_CHART_MIN_HEIGHT ? height : STACKED_CHART_MIN_HEIGHT}
      maxHeight={maxHeight}
    >
      <BarChart
        setRef={setRef}
        plugins={[...plugins, ChartDataLabels]}
        options={chartOptions}
        horizontal
        data={{
          labels: chartData.labels,
          datasets: chartData.datasets,
        }}
      />
    </ChartWrapper>
  );

  return Chart;
};

export default useProgressStackedChart;
