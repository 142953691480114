const exist = Boolean;

const getJiraIntegrationWarnings = (orgJiraIntegrations, warningType) => {
  const jiraIntegrationWithWarnings = orgJiraIntegrations.find(
    jiraIntegration => jiraIntegration.data && jiraIntegration.data.warnings && exist(jiraIntegration.data.warnings.length),
  );

  const warning = jiraIntegrationWithWarnings?.data?.warnings?.find(warning => warning.type === warningType);

  return warning;
};

export default getJiraIntegrationWarnings;
