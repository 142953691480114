import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { selectRoadmapHistorySnapsForCurrentView } from 'features/RoadmapHistory/store/selectors';
import useQueryParam from 'hooks/useQueryParam';
import { EDIT_SNAP_QUERY_PARAM } from 'features/RoadmapHistory/constants';
import { updateRoadmapHistorySnapshot, deleteRoadmapHistorySnap } from 'features/RoadmapHistory/store';
import { closeEditSnap } from 'features/RoadmapHistory/utils/editSnap';

const useUpdateRoadmapSnapDialog = ({ onDeleteCallback }) => {
  const [snapId, setSnapId] = useState();
  const dispatch = useDispatch();
  const viewSnaps = useSelector(selectRoadmapHistorySnapsForCurrentView);
  const snap = viewSnaps.find(snap => snap?.id === snapId);
  const [snapshotName, setSnapshotName] = useState();
  const [snapshotDescription, setSnapshotDescription] = useState();
  const [snapshotColor, setSnapshotColor] = useState();

  const { canDelete, canUpdate } = usePermissions();

  const handleParamChange = param => {
    setSnapId(+param);
    setSnapshotName(undefined);
    setSnapshotDescription(undefined);
    setSnapshotColor(undefined);
  };

  useQueryParam(EDIT_SNAP_QUERY_PARAM, handleParamChange, { defaultTo: 0 });

  const userCanUpdate = canUpdate(PERMISSION_RESOURCES.roadmapHistorySnapshot);
  const userCanDelete = canDelete(PERMISSION_RESOURCES.roadmapHistorySnapshot);

  const onSaveSnap = () => {
    const hasUpdates = snapshotName || snapshotDescription || snapshotColor;

    userCanUpdate &&
      hasUpdates &&
      dispatch(
        updateRoadmapHistorySnapshot(snapId, {
          ...(snapshotName ? { name: snapshotName } : {}),
          ...(snapshotDescription ? { description: snapshotDescription } : {}),
          ...(snapshotColor ? { color: snapshotColor } : {}),
        }),
      );
    closeEditSnap();
  };

  const onDeleteSnap = () => {
    if (userCanDelete) {
      dispatch(deleteRoadmapHistorySnap(snapId, { viewId: snap?.user_view_id }));
      onDeleteCallback && onDeleteCallback(snapId);
    }

    closeEditSnap();
  };

  return {
    snapId,
    isOpen: !!snapId,
    onClose: closeEditSnap,
    snapshotDescription: snapshotDescription || snap?.description,
    snapshotName: snapshotName || snap?.name,
    snapshotColor: snapshotColor || snap?.color,
    setSnapshotDescription,
    setSnapshotColor,
    setSnapshotName,
    onSaveSnap,
    onDeleteSnap,
    userCanUpdate,
    userCanDelete,
  };
};

export default useUpdateRoadmapSnapDialog;
