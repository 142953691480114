import { returnsTrueIfKeyIsNotEscOrTab } from 'utils/agGrid';

import { stringComparator } from '../comparators';
import { CustomFieldDropdownCellEditor } from '../cellEditors';
import { applyCheckIsEditable } from '../helpers';

const valueFormatter = () => 'EDIT OPTIONS';

// TODO: adapt this later for the custom fields settings
const getCustomFieldColumnDropdownDef = ({ customField, editable, ...otherProps }) => {
  return {
    editable: applyCheckIsEditable(editable),
    cellEditor: CustomFieldDropdownCellEditor,
    cellEditorParams: {
      customField,
    },
    suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
    comparator: stringComparator,
    width: 100,
    cellEditorPopup: true,
    sortable: false,
    valueFormatter,
    ...otherProps,
  };
};

export default getCustomFieldColumnDropdownDef;
