import { useSelector } from 'react-redux';

import { getScenarioVersionsColors } from 'store/roadmapVersions/selectors';

const useScenarioVersionColors = () => {
  const scenarioColors = useSelector(getScenarioVersionsColors);

  return { scenarioColors };
};

export default useScenarioVersionColors;
