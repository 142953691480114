import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useLoadPageMetadata from 'hooks/useLoadPageMetadata';

import { setAppGlobalConfig } from 'store/app/actions';
import { showImportIdeasLightbox } from 'store/ideas';

import useApplyTemplate from '../hooks/useApplyTemplate';
import useParseDataFromTemplate from './hooks/useParseDataFromTemplate';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const selectedTemplate = useSelector(state => state.app.selectedTemplate);
    const showPreviewTemplate = useSelector(state => state.app.showPreviewTemplate);
    const applyTemplate = useApplyTemplate(selectedTemplate && selectedTemplate.id);
    const tableData = useParseDataFromTemplate(selectedTemplate);
    const loadMetadata = useLoadPageMetadata();
    const _onClose = () =>
      dispatch(
        setAppGlobalConfig({
          showPreviewTemplate: false,
          selectedTemplate: null,
        }),
      );
    const _takeProductTour = () =>
      dispatch(
        setAppGlobalConfig({
          showStartGuideLightbox: true,
        }),
      );
    const _importIdeas = async () => {
      _onClose();
      await dispatch(showImportIdeasLightbox());
      await applyTemplate();
      loadMetadata();
    };
    const _goBack = () =>
      dispatch(
        setAppGlobalConfig({
          showPreviewTemplate: false,
          selectedTemplate: null,
          showTemplatesLightbox: true,
        }),
      );

    return (
      <Component
        open={showPreviewTemplate}
        onClose={_onClose}
        takeProductTour={_takeProductTour}
        importIdeas={_importIdeas}
        goBack={_goBack}
        tableData={tableData}
        selectedTemplate={selectedTemplate}
        {...props}
      />
    );
  };
};

export default componentHOC;
