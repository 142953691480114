import React from 'react';
import Grid from '@material-ui/core/Grid';

import Text from 'design-system/atoms/Text/index';
import PageTemplate from 'design-system/templates/PageTemplate/index';

import styled from 'styled-components';
import ProgressHeader from 'containers/Progress/ProgressHeader';
import ProgressContent from 'containers/Progress/ProgressContent';
import useProgressBurnChartData from 'containers/Progress/hooks/useProgressBurnChartData';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const Title = styled.div`
  display: flex;
  padding-top: ${({ theme }) => `${theme.spacing.unit * 2}px`};
  align-items: center;
  justify-content: center;
`;

const ProgressAndDates = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => `${theme.spacing.unit * 5}px`};
`;

const ProgressComponent = props => {
  const { lsState, onDateChange, onShowTargetAndPredictedLinesChange } = props;
  const { startDate, endDate, showTargetAndPredictedLines } = lsState;
  const { data, maxEndDate, maxPredictedEndDate, formattedEndDate, formattedPredictedDate, isLoading } = useProgressBurnChartData(
    {
      startDate,
      endDate,
    },
  );

  const { canView } = usePermissions();

  const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

  return (
    <PageTemplate
      header={
        <>
          <Title item xs={12}>
            <Text variant="h2" color="primary">
              Portfolio Delivery Progress
            </Text>
            <ProgressAndDates />
          </Title>
          <ProgressHeader
            startDate={startDate}
            endDate={endDate}
            onDateChange={onDateChange}
            targetAndPredictedLinesOptions={{
              checked: showTargetAndPredictedLines,
              formattedEndDate,
              formattedPredictedDate,
            }}
            onShowTargetAndPredictedLinesChange={onShowTargetAndPredictedLinesChange}
            showShareView={shouldDisplayControlsBar}
          />
        </>
      }
    >
      <ProgressContent
        data={data}
        isLoading={isLoading}
        startDate={startDate}
        endDate={endDate}
        targetAndPredictedLinesOptions={{
          checked: showTargetAndPredictedLines,
          maxEndDate,
          maxPredictedEndDate,
        }}
      />
    </PageTemplate>
  );
};

export default ProgressComponent;
