import React, { useMemo, useCallback } from 'react';
import { PERMISSION_RESOURCES, SYSTEM_FIELD } from '@dragonboat/permissions';

import { CUSTOMERS } from 'store/grids/constants';
import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SettingsDialogs from 'components/SettingsDialogs';
import { BaseGrid } from 'containers/Grids';
import {
  useSettingsActions,
  useSettingsGridSelection,
  useSettingsGridProps,
  useSettingsGridEventHandlers,
  useSettingsGridColumns,
} from 'containers/Grids/SettingsGrid/hooks';
import SettingsToggle from 'design-system/atoms/SettingsToggle';
import useForceVisibleColumns from 'hooks/useForceVisibleColumns';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import useCustomers from './hooks/useCustomers';
import useCustomersGridColumns from './hooks/useCustomersGridColumns';
import ImportCustomers from './ImportCustomers';
import { GROUP_BY_OPTIONS, getDataPath, makeCheckDeleteVisibility, makeCheckCheckboxSelection } from './helpers';
import { INTEGRATIONS_NAMES } from 'constants/integrations';
import CustomFieldOptionsCustomizer from './components/CustomFieldOptionsCustomizer/CustomFieldOptionsCustomizer';
import useCustomFieldOptionsCustomizer from './components/CustomFieldOptionsCustomizer/useCustomFieldOptionsCustomizer';

const CUSTOMER_KEY = 'customer';
const NAME_FIELD = 'name';

const getRowId = ({ data }) => data?.id;

const Customers = ({
  updateSystemFieldsName,
  systemFields,
  hideMetadataRoadmaps,
  showActionBar = true,
  forceVisibleFields,
  customHeight,
  hideArchivedItems,
}) => {
  const {
    customers,
    filteredCustomersWithHierarchy,
    customFields,
    createCustomer,
    createCustomers,
    updateCustomers,
    bulkDeleteCustomers,
    addCustomerWithoutSave,
    createCustomerRoadmap,
    deleteCustomerRoadmap,
    bulkDeleteCustomerRoadmaps,
    removeUnsavedCustomers,
    updateCustomerById,
    deleteCustomer,
    mergeCustomers,
    isImportLightboxOpen,
    openImportLightbox,
    closeImportLightbox,
    searchText,
    updateSearchText,
    isHidingInactiveCustomers,
    toggleHidingInactiveCustomers,
    selectedGroupByOption,
    updateSettingsGroupByOption,
    isGrouping,
  } = useCustomers(hideArchivedItems);

  const customerTransformLabel = customer =>
    `${customer.name} (${customer.status}) ${
      customer.integrations?.length
        ? `- ${customer.integrations?.map(integration => INTEGRATIONS_NAMES[integration.integrationType]).join(', ')}`
        : ''
    }`;

  // Settings common agGrid configuration

  const { selectionMode, selectedItems, enableSelectionMode, disableSelectionMode, updateSelectedOnAgGridInit } =
    useSettingsGridSelection(CUSTOMERS, {
      actions: {
        remove: removeUnsavedCustomers,
      },
    });

  const {
    itemToDelete,
    setItemToDelete,
    deleteItem,
    showBulkDeleteDialog,
    setShowBulkDeleteDialog,
    deleteProgress,
    bulkDeleteItems,
    mergeLightboxVisible,
    showMergeLightbox,
    hideMergeLightbox,
  } = useSettingsActions({
    settingType: CUSTOMERS,
    selectedItems,
    disableSelectionMode,
    deleteById: deleteCustomer,
    bulkDelete: bulkDeleteCustomers,
  });

  const settingsGridProps = useSettingsGridProps({
    bulkCreate: createCustomers,
    bulkUpdate: updateCustomers,
    treeData: true,
  });

  const { canUpdate, canCreate, canMerge, canView } = usePermissions();
  const canUpdateOrCreate = canUpdate(PERMISSION_RESOURCES.customer) || canCreate(PERMISSION_RESOURCES.customer);
  const userCanViewCustomFieldsCustomizer = canView(PERMISSION_FEATURES.customersCustomFieldsCustomizer);

  const checkDeleteVisibility = useMemo(() => {
    return makeCheckDeleteVisibility(isGrouping);
  }, [isGrouping]);

  const checkCheckboxSelection = useMemo(() => {
    return makeCheckCheckboxSelection(isGrouping);
  }, [isGrouping]);

  const {
    isCustomizerOpen,
    selectedCustomField,
    openCustomizer,
    closeCustomizer,
    updateCustomFieldOptions,
    userCanEditCustomFieldOptions,
  } = useCustomFieldOptionsCustomizer();

  const permissions = {
    allowActions: canUpdateOrCreate,
    canAdd: canCreate(PERMISSION_RESOURCES.customer),
    rename: {
      canUpdate: showActionBar && canUpdate(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.customer }),
      canView: showActionBar && canView(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.customer }),
    },
    canMerge: showActionBar && canMerge(PERMISSION_RESOURCES.customer),
    canBulkDelete: showActionBar && canUpdateOrCreate,
    canDragRows: showActionBar && canUpdateOrCreate,
    canUpdate: useCallback(data => canUpdate(PERMISSION_RESOURCES.customer, { data }), [canUpdate]),
    deleteVisibility: checkDeleteVisibility,
    checkboxSelection: checkCheckboxSelection,
  };

  const settingsGridColumns = useSettingsGridColumns({
    allowActions: permissions.allowActions,
    selectionMode,
    setItemToDelete,
    deleteVisibility: permissions.deleteVisibility,
    checkboxSelection: permissions.checkboxSelection,
    showDisabledCheckboxes: false,
  });

  const settingsGridEventHandlers = useSettingsGridEventHandlers({
    settingType: CUSTOMERS,
    items: filteredCustomersWithHierarchy,
    focusField: NAME_FIELD,
    actions: {
      update: updateCustomerById,
      save: createCustomer,
      remove: removeUnsavedCustomers,
    },
  });

  // Specific column definition for customers
  const customersGridColumnDefs = useCustomersGridColumns({
    allowActions: permissions.allowActions,
    customFields,
    roadmapColumn: {
      createCustomerRoadmap,
      deleteCustomerRoadmap,
      customers,
      bulkDeleteCustomerRoadmaps,
      hideMetadataRoadmaps,
    },
    isGrouping,
    openCustomFieldCustomizer: openCustomizer,
    displayCustomFieldCustomizerIcon: userCanViewCustomFieldsCustomizer,
  });

  const visibleColumns = useForceVisibleColumns(customersGridColumnDefs, forceVisibleFields);

  // Join settings and customers column definitions
  const columnDefs = useMemo(
    () => [...settingsGridColumns.beforeColumnDefs, ...visibleColumns, ...settingsGridColumns.afterColumnDefs],
    [settingsGridColumns, visibleColumns],
  );

  const additionalActions = useMemo(() => {
    return (
      <SettingsToggle isChecked={isHidingInactiveCustomers} onToggle={toggleHidingInactiveCustomers} label="Hide inactive" />
    );
  }, [isHidingInactiveCustomers, toggleHidingInactiveCustomers]);

  return (
    <>
      {permissions.allowActions && (
        <SettingsActionsToolbar
          settingKey={CUSTOMER_KEY}
          settingType={CUSTOMERS}
          systemFields={systemFields}
          updateSystemFieldsName={updateSystemFieldsName}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          onEnableSelection={enableSelectionMode}
          onCancelSelection={disableSelectionMode}
          showMergeLightbox={showMergeLightbox}
          setShowBulkDeleteDialog={setShowBulkDeleteDialog}
          add={addCustomerWithoutSave}
          renamePermissions={permissions.rename}
          canMerge={permissions.canMerge}
          canBulkDelete={permissions.canBulkDelete}
          hideAddButton={!permissions.canAdd}
          importCsv={showActionBar && openImportLightbox}
          searchText={searchText}
          updateSearchText={updateSearchText}
          additionalActions={additionalActions}
          canGroupBy
          groupByOptions={GROUP_BY_OPTIONS}
          selectedGroupByOption={selectedGroupByOption}
          handleGroupByChange={updateSettingsGroupByOption}
        />
      )}
      <BaseGrid
        onGridReady={updateSelectedOnAgGridInit}
        rowData={filteredCustomersWithHierarchy}
        getDataPath={getDataPath}
        columnDefs={columnDefs}
        defaultColDef={settingsGridColumns.defaultColDef}
        {...settingsGridProps}
        {...settingsGridEventHandlers}
        groupDisplayType="custom"
        isRowSelectable={checkCheckboxSelection}
        height={customHeight ?? settingsGridProps.height}
        getRowId={getRowId}
      />
      <SettingsDialogs
        settingKey={CUSTOMER_KEY}
        settingType={CUSTOMERS}
        showBulkDeleteDialog={showBulkDeleteDialog}
        setShowBulkDeleteDialog={setShowBulkDeleteDialog}
        deleteProgress={deleteProgress}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        deleteItem={deleteItem}
        bulkDelete={bulkDeleteItems}
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
        mergeLightboxVisible={mergeLightboxVisible}
        hideMergeLightbox={hideMergeLightbox}
        merge={mergeCustomers}
        transformLabel={customerTransformLabel}
      />
      <ImportCustomers isOpen={isImportLightboxOpen} close={closeImportLightbox} />
      {userCanViewCustomFieldsCustomizer ? (
        <CustomFieldOptionsCustomizer
          isOpen={isCustomizerOpen}
          customField={selectedCustomField}
          handleOptionsChange={updateCustomFieldOptions}
          handleClose={closeCustomizer}
          canEdit={userCanEditCustomFieldOptions}
        />
      ) : null}
    </>
  );
};

export default Customers;
