import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openObjectiveDrawer as openObjectiveDrawerAction } from 'store/objectives';

import { getObjectiveTypeByLevel } from 'utils/objectives';

const useGoalsAllocationGrid = () => {
  const dispatch = useDispatch();

  const openObjectiveDrawer = useCallback(
    okr => {
      const okrType = getObjectiveTypeByLevel(okr);

      return dispatch(openObjectiveDrawerAction(okr.id, okrType));
    },
    [dispatch],
  );

  return {
    openObjectiveDrawer,
  };
};

export default useGoalsAllocationGrid;
