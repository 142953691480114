import React, { useEffect } from 'react';

import Dialog from 'design-system/molecules/Dialog/index';


export default ({ open, onClose }) => {
  useEffect(() => {
    if (open) {
      /* global Calendly */
      setTimeout(() =>
        Calendly.initInlineWidget({
          url: 'https://meetings.hubspot.com/dragonboat/demo',
          parentElement: document.getElementById('calendlyWidget'),
          prefill: {},
          utm: {},
        }),
      );
    }
  }, [open]);

  return (
    <Dialog open={!!open} maxWidth="md" onClose={onClose} closeButton fullWidth closeButtonColor="#1F8F9E">
      <div id="calendlyWidget" style={{ marginTop: 26, height: 600 }} />
    </Dialog>
  );
};
