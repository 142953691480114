import React from 'react';
import styled from 'styled-components';

import ProgressBar from 'design-system/atoms/ProgressBar';
import { materialColors } from 'design-system/themes/default';

const colorFromStatusColor = (value = '') => {
  switch (value) {
    case 'Green':
      return materialColors.green;
    case 'Yellow':
      return materialColors.yellow;
    case 'Red':
      return materialColors.red;
    case 'Blue':
      return materialColors.blue;
    default:
      return materialColors.darkGray;
  }
};

const PercentageCellRenderer = params => (
  <Wrapper>
    <ProgressBar
      value={params.value}
      color={params.colDef.color || colorFromStatusColor(params.data && params.data.status_color)}
    />
  </Wrapper>
);

export default PercentageCellRenderer;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: block;
`;
