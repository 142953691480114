import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

import formatPercent from 'design-system/utils/formatPercent';
import { PROGRESS_PERCENTAGE_DECIMAL_PLACES } from 'constants/common';

const ProgressBarContainer = styled.div`
  position: relative;
  border-radius: 8px;
  flex-grow: 1;
  height: 8px;

  overflow: hidden;

  background: #eaeaea;
`;
const ProgressBar = styled.div`
  border-radius: 8px;
  height: 8px;
  width: ${props => props.value};
  max-width: 100%;

  background: ${props => props.color || '#00b1f3'};
`;

const ProgressLabel = styled(Typography)`
  &&&& {
    width: 48px;
    text-align: right;
    font-size: 12px;
  }
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default ({ value, ...props }) => (
  <ProgressContainer className={props.className}>
    <ProgressBarContainer {...props}>
      <ProgressBar value={formatPercent(value, PROGRESS_PERCENTAGE_DECIMAL_PLACES)} {...props} />
    </ProgressBarContainer>
    <ProgressLabel {...props}>{formatPercent(value, PROGRESS_PERCENTAGE_DECIMAL_PLACES)}</ProgressLabel>
  </ProgressContainer>
);
