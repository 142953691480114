import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from 'styled-components';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';

import useLoginInfo from 'hooks/useLoginInfo';
import useOrganizations from 'hooks/useOrganizations';
import usePageFilters from 'hooks/filters/usePageFilters';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useAdditionalMetadataDropdownOptions from 'hooks/metadata/useAdditionalMetadataDropdownOptions';
import {
  sumNumericFields,
  sumScopeVarianceField,
  sumPointsVarianceField,
} from 'design-system/molecules/AgGridReact-New/aggFuncs';
import { useCommonGridProps, useCommonGridEventHandlers } from 'design-system/molecules/AgGridReact-New/hooks';
import { INITIATIVE_LAYER, IDEA_LAYER, BET_LAYER } from 'src/store/projects/constants';

import {
  createProject,
  updateProjectById,
  isSomeUpdateOnProjectsOcurring,
  switchProjectRowOrder as switchProjectRowOrderAction,
} from 'store/projects';
import { useMetadata } from 'containers/IdeasList/IdeasList/New/hooks';
import useIdeasGridRowDrag from 'containers/IdeasList/IdeasList/New/hooks/useIdeasGridRowDrag';
import useProcessUpdateProjectData from 'containers/IdeasList/IdeasList/New/hooks/useProcessUpdateProjectData';
import sortByRank from 'utils/sortByRank';
import useProjectLightboxChildrenColumns from './useProjectLightboxChildrenColumns';

const OFFSET = 350;
const TABLE_HEIGHT = `calc(100vh - ${OFFSET}px)`;

const gridStyles = css`
  .ag-layout-normal.ag-root {
    margin: 0;
  }
`;

const rowsAreEqual = (currentRows, updatedRows) => {
  if (currentRows.length !== updatedRows.length) {
    return false;
  }
  const differences = differenceWith(currentRows, updatedRows, (obj1, obj2) => isEqual(obj1, obj2));

  return !differences.length;
};

const useProjectLightboxChildrenGrid = (selectedProject, isDrawerView) => {
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);

  const { currentUser } = useLoginInfo();
  const { displayLayer, portfolioMode } = usePageFilters();
  const { isParentDragon, isChildDragon } = useOrganizationsAccessControl();
  const additionalMetadataOptions = useAdditionalMetadataDropdownOptions();

  const isStoreUpdating = useSelector(isSomeUpdateOnProjectsOcurring);

  const addNewRow = (isLink = false) => setRows(prevRows => [{ isLink }, ...prevRows]);

  const removeUnsavedRows = () => setRows(prevRows => prevRows.filter(r => r.id));

  const inheritedParentData = pick(selectedProject, 'roadmapTitle', 'product1Title', 'objectiveTitle', 'keyResult1Title');

  const updateIdea = useCallback((id, update) => dispatch(updateProjectById(id, update)), [updateProjectById]);

  const childLayer = selectedProject?.layer === BET_LAYER ? INITIATIVE_LAYER : IDEA_LAYER;

  const createChildProject = useCallback(
    data =>
      dispatch(
        createProject({
          ...inheritedParentData,
          ...data,
          parent_id: selectedProject.id,
          layer: childLayer,
        }),
      ),
    [createProject, selectedProject],
  );

  const switchProjectRowOrder = useCallback(
    (currentNodeDataId, lastOverNodeDataId, updateData, position) =>
      dispatch(switchProjectRowOrderAction(currentNodeDataId, lastOverNodeDataId, updateData, position)),
    [switchProjectRowOrderAction],
  );

  const {
    organization,
    integrations,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    filters: { topLayer, availableLayers },
    hasMetadataMultiSelect,
  } = useOrganizations();

  const { metadata, filteredMetadata, checkUserCanCreateNewMetadata } = useMetadata(rows, {
    currentUser,
    organization,
  });

  const { columnDefs, defaultColumnDef } = useProjectLightboxChildrenColumns({
    updateIdea,
    createChildProject,
    isDrawerView,
    integrations,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    topLayer,
    availableLayers,
    hasMetadataMultiSelect,
    displayLayer,
    currentUser,
    portfolioMode,
    checkUserCanCreateNewMetadata,
  });

  const commonGridProps = useCommonGridProps({
    isTreeView: false,
    aggFuncs: {
      sumNumericFields,
      sumScopeVarianceField,
      sumPointsVarianceField,
    },
  });

  const { handleRowDrag } = useIdeasGridRowDrag({
    switchProjectRowOrder,
    updateProjectById: updateIdea,
  });

  const { processUpdateData: processUpdateDataForProjectUpdate } = useProcessUpdateProjectData([], hasMultiLevelMetadata);

  const updateProjectByIdOnCellValueChange = (id, updatedData, previousValue, params) => {
    const updatedField = params.colDef.field;

    return updateIdea(params.data.id, processUpdateDataForProjectUpdate(updatedData, updatedField));
  };

  const commonGridEvents = useCommonGridEventHandlers({
    items: rows,
    idKey: 'id',
    actions: {
      update: updateProjectByIdOnCellValueChange,
      save: createChildProject,
      remove: removeUnsavedRows,
      drag: handleRowDrag,
    },
  });

  const gridContext = useMemo(
    () => ({
      selectedProject,
      isParentDragon,
      portfolioMode,
      processedData: rows,
      parents: metadata.parents,
      filteredMetadata,
      metadata,
      additionalMetadataOptions,
    }),
    [selectedProject, isParentDragon, portfolioMode, rows, metadata, filteredMetadata, additionalMetadataOptions],
  );

  useEffect(() => {
    const childrenRows = rows.filter(r => r.id);

    if (isStoreUpdating) {
      return;
    }

    if (rowsAreEqual(childrenRows, selectedProject?.children)) {
      return;
    }

    setRows([...(selectedProject?.children || [])].sort(sortByRank));
  }, [selectedProject]);

  return {
    rows,
    commonGridProps,
    commonGridEvents,
    columnDefs,
    defaultColumnDef,
    gridContext,
    gridStyles,
    tableHeight: TABLE_HEIGHT,
    isChildDragon,
    addNewRow,
  };
};

export default useProjectLightboxChildrenGrid;
