import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import Dialog from 'design-system/molecules/Dialog/index';

export default props => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.close}
      closeButton
      title={`Import Customer ${props.getSystemFieldName('request', true)}`}
      subtitle="(csv files only)"
      width="500px"
    >
      <ImportCsvContainer>
        <InputFileContainer onClick={props.onClickBrowseCSVFile}>
          <BrowseButton size="small" variant="contained">
            Browse
          </BrowseButton>
        </InputFileContainer>
        <ImportInstructions>You may then map the columns to dragonboat fields</ImportInstructions>
      </ImportCsvContainer>
    </Dialog>
  );
};


const BrowseButton = styled(Button)`
  &&& {
    text-Transform: none;
    box-shadow: none;
    border-radius: 0px;
  }
`;

const ImportCsvContainer = styled.div`
  margin-left:68px;
  padding-top:5px;
  padding-bottom: 5px;

  visibility: ${props => props.hidden ? 'hidden' : 'visible'};
`;

const InputFileContainer = styled.div`
  text-align:right;
  width: 300px;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  cursor: pointer;

  &&&:hover{
    border-color: rgba(0, 0, 0, 0.87);
  }
`;

const ImportInstructions = styled.p`
  margin-top:15px;
  font-size:0.875rem;
`;
