import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { pluck, defaultTo, pipe, prop } from 'ramda';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';

import { updateRoadmapVersion, saveRoadmapVersion, openDeleteScenarioDialog } from 'store/roadmapVersions';
import useSystemFields from 'hooks/useSystemFields';
import useNavigationToPathWithGoBackUrl from 'hooks/useNavigationToPathWithGoBackUrl';
import useOneClickDialog from 'containers/OneClickDialog/hooks/useOneClickDialog';
import useScenarioVersionColors from 'routes/RoadmapVersions/hooks/useScenarioVersionColors';
import Dialog from 'design-system/molecules/Dialog/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import UserMentionTextArea from 'design-system/organisms/UserMentionTextArea/index';
import WYSIWYGEditorField from 'design-system/molecules/WYSIWYGEditorField/index';
import SelectColor from 'design-system/molecules/SelectColor/index';

import theme, { spacing } from 'design-system/theme';
import addQueryParamToUrl from 'utils/roadmapVersions/addQueryParamToUrl';

const extractIds = pluck('id');
const getRoadmapVersionUsers = pipe(prop('roadmapVersionUsers'), defaultTo([]), pluck('user'));

const INITIAL_SCENARIO_COLOR = theme.palette.newLayout.background.primary;

export default ({ onClose, onSave, show, selectedRoadmapVersion }) => {
  const dispatch = useDispatch();

  const { scenarioColors } = useScenarioVersionColors();

  const [versionName, setVersionName] = useState('');
  const [versionColor, setVersionColor] = useState(INITIAL_SCENARIO_COLOR);
  const [roadmapVersionUsers, setRoadmapVersionUsers] = useState([]);
  const [scenarioDescription, setScenarioDescription] = useState('');

  const [getSystemFieldName] = useSystemFields();

  const { navigateBack } = useNavigationToPathWithGoBackUrl();

  const { isOpen: isOneClickDialogOpen } = useOneClickDialog();

  useEffect(() => {
    if (!selectedRoadmapVersion) {
      setVersionName('');
      setVersionColor(INITIAL_SCENARIO_COLOR);
      setRoadmapVersionUsers([]);
      setScenarioDescription('');
      return;
    }

    const roadmapVersionUsersForSelectedVersion = getRoadmapVersionUsers(selectedRoadmapVersion);

    setRoadmapVersionUsers(roadmapVersionUsersForSelectedVersion);
    setVersionName(selectedRoadmapVersion?.name);
    setScenarioDescription(selectedRoadmapVersion?.description || '');
    setVersionColor(scenarioColors?.[selectedRoadmapVersion?.id] || INITIAL_SCENARIO_COLOR);
  }, [selectedRoadmapVersion]);

  const title = !selectedRoadmapVersion?.committed
    ? `Create ${getSystemFieldName('scenario')}`
    : `Update ${getSystemFieldName('scenario')}`;

  const _onDialogSaveClicked = async () => {
    const saveVersionCallback = !selectedRoadmapVersion?.committed ? saveRoadmapVersion : updateRoadmapVersion;

    await dispatch(
      saveVersionCallback(selectedRoadmapVersion, {
        name: versionName,
        color: versionColor,
        sharedUserIds: extractIds(roadmapVersionUsers),
        description: scenarioDescription,
      }),
    );

    if (isOneClickDialogOpen) {
      navigateBack();
    } else {
      addQueryParamToUrl(selectedRoadmapVersion);
    }

    onSave();
  };

  const _onDeleteClicked = () => {
    dispatch(openDeleteScenarioDialog(selectedRoadmapVersion));

    onClose();
  };

  const _sharedUsersChanged = users => {
    setRoadmapVersionUsers(users);
  };

  return (
    <Dialog
      id="save-filter-dialog"
      data-test="save-filter-dialog"
      open={show}
      onClose={onClose}
      maxWidth="lg"
      scroll="paper"
      closeButton
      contentCustomStyles={dialogContentStyles}
      actions={
        <Fragment>
          {selectedRoadmapVersion?.id && (
            <DeleteButton data-test="delete-scenario" onClick={() => _onDeleteClicked()} color="secondary">
              Delete
            </DeleteButton>
          )}
          <Button
            data-test="save-scenario"
            onClick={() => {
              _onDialogSaveClicked();
              onClose();
            }}
            color="primary"
          >
            Save
          </Button>
          <Button
            data-test="cancel-save-scenario"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </Fragment>
      }
    >
      <DialogWrapper>
        <Grid container>
          <Grid item xs={12}>
            <Flex>
              <TextDeprecated>{title}</TextDeprecated>
              <ColorInputWrapper>
                <TextDeprecated>Color</TextDeprecated>
                <SelectColor color={versionColor} onChange={color => setVersionColor(color)} />
              </ColorInputWrapper>
            </Flex>
            <TextInput
              data-test="filter-name-input"
              placeholder={`Enter the name for the roadmap ${getSystemFieldName('scenario')}`}
              fullWidth
              margin="normal"
              value={versionName}
              onChange={({ target: { value } }) => setVersionName(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledWYSIWYGEditorField
              key="scenario-dialog-description"
              value={scenarioDescription}
              content={scenarioDescription}
              placeholder="Add a brief description of this scenario"
              onChange={setScenarioDescription}
              width="600px"
              height="165px"
            />
          </Grid>
          <Grid item xs={12}>
            <StyledUserMentionTextArea onUsersToShareChanged={_sharedUsersChanged} storedUsers={roadmapVersionUsers} smallInput />
          </Grid>
        </Grid>
      </DialogWrapper>
    </Dialog>
  );
};

const dialogContentStyles = css`
  && {
    padding-top: 22px;
  }
`;

const DialogWrapper = styled.div`
  width: 600px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: ${spacing(7)}px;
`;

const ColorInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2)}px;
`;

const TextInput = styled(TextField)`
  &&&& {
    margin-bottom: 0;
  }
`;

const DeleteButton = styled(Button)`
  &&&& {
    position: absolute;
    left: 20px;
  }
`;

const StyledWYSIWYGEditorField = styled(WYSIWYGEditorField)`
  && {
    margin-top: ${spacing(2)}px;
  }
`;

const StyledUserMentionTextArea = styled(UserMentionTextArea)`
  min-height: 79px;
`;
