import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';

import upsertListItem from '../utils/upsertListItem';
import {
  CREATE_CATEGORY_FULFILLED,
  FETCH_CATEGORIES_FULFILLED,
  UPDATE_CATEGORY_FULFILLED,
  UPDATE_CATEGORY_BY_ID_FULFILLED,
  DELETE_CATEGORY_FULFILLED,
  ADD_CATEGORY_WITHOUT_SAVE,
  REMOVE_UNSAVED_CATEGORIES,
  MERGE_CATEGORIES_FULLFILLED,
  CREATE_CATEGORIES_FULFILLED,
  UPDATE_CATEGORIES_FULFILLED,
  UNDO_ADD_CATEGORIES_FULFILLED,
  UNDO_UPDATE_CATEGORIES_FULFILLED,
  BULK_DELETE_CATEGORIES_FULFILLED,
  UNDO_BULK_DELETE_CATEGORIES_FULFILLED,
  SWITCH_CATEGORIES_ROW_ORDER_PENDING,
  SWITCH_CATEGORIES_ROW_ORDER_FULFILLED,
  SWITCH_CATEGORIES_ROW_ORDER_REJECTED,
  CREATE_CATEGORY_ROADMAP_FULFILLED,
  DELETE_CATEGORY_ROADMAP_FULFILLED,
  BULK_DELETE_CATEGORY_ROADMAP_FULFILLED,
} from './types';
import addRows from 'store/utils/addRows';
import updateRows from 'store/utils/updateRows';
import sortByRank from 'utils/sortByRank';

const initialState = {
  categories: [],
  isLoaded: false,
};

function removeUnsaved(list) {
  return list.filter(el => el.id);
}

function updateCategory(categories, category) {
  return categories.map(item => (item.id === category.id ? category : item));
}

export default function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case SWITCH_CATEGORIES_ROW_ORDER_REJECTED: {
      if (!action.meta || !action.meta.prev) return state;

      let categories = state.categories ? state.categories.slice(0) : [];

      categories = upsertListItem(action.meta.prev, categories);

      return {
        ...state,
        categories: categories.sort(sortByRank),
      };
    }
    case SWITCH_CATEGORIES_ROW_ORDER_PENDING: {
      if (!action.meta || !action.meta.row) {
        return state;
      }

      let categories = state.categories ? state.categories.slice(0) : [];

      categories = upsertListItem(action.meta.row, categories);

      return {
        ...state,
        categories: categories.sort(sortByRank),
      };
    }
    case SWITCH_CATEGORIES_ROW_ORDER_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        console.error('SWITCH_CATEGORIES_ROW_ORDER_FULFILLED::Category passed does not have id');
        return state;
      }

      let categories = state.categories ? state.categories.slice(0) : [];

      categories = upsertListItem(action.payload, categories);

      return {
        ...state,
        categories: categories.sort(sortByRank),
      };
    }
    case CREATE_CATEGORY_FULFILLED:
      if (!action.payload || !action.payload.id) {
        return state;
      }

      let categories = state.categories || [];

      categories = removeUnsaved(categories);
      categories = upsertListItem(action.payload, categories);

      return {
        ...state,
        categories,
        isLoaded: true,
      };
    case FETCH_CATEGORIES_FULFILLED:
      return Object.assign({}, state, {
        categories: action.payload.data,
        isLoaded: true,
        lastCallsDate: moment().valueOf(),
      });
    case UPDATE_CATEGORY_BY_ID_FULFILLED:
    case UPDATE_CATEGORY_FULFILLED:
      return {
        ...state,
        categories: updateCategory(state.categories, action.payload),
        isLoaded: true,
      };
    case DELETE_CATEGORY_FULFILLED:
      return {
        ...state,
        categories: state.categories.filter(c => c.id !== action.payload),
      };
    case ADD_CATEGORY_WITHOUT_SAVE: {
      const categories = state.categories ? removeUnsaved(state.categories) : [];

      categories.unshift(action.category || {});

      return {
        ...state,
        categories,
      };
    }
    case REMOVE_UNSAVED_CATEGORIES:
      return {
        ...state,
        categories: removeUnsaved(state.categories),
      };
    case MERGE_CATEGORIES_FULLFILLED:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        categories: state.categories.filter(category => !action.payload.includes(category.id)),
      };
    case CREATE_CATEGORIES_FULFILLED: {
      const categories = addRows(state.categories, action.payload);

      return {
        ...state,
        categories,
        lastActionIds: action.payload.map(obj => obj.id),
      };
    }
    case UPDATE_CATEGORIES_FULFILLED: {
      const categories = updateRows(state.categories, action.payload);

      return {
        ...state,
        categories,
        lastActionIds: action.payload.map(obj => obj.id),
      };
    }
    case BULK_DELETE_CATEGORIES_FULFILLED: {
      const categories = state.categories.filter(r => !action.payload.includes(String(r.id)));

      return {
        ...state,
        categories,
        lastActionIds: action.payload,
      };
    }
    case UNDO_UPDATE_CATEGORIES_FULFILLED: {
      const categories = updateRows(state.categories, action.payload);

      return {
        ...state,
        categories,
        lastActionIds: null,
      };
    }
    case UNDO_ADD_CATEGORIES_FULFILLED: {
      return {
        ...state,
        categories: state.categories.filter(c => state.lastActionIds.indexOf(c.id) === -1),
        lastActionIds: null,
      };
    }
    case UNDO_BULK_DELETE_CATEGORIES_FULFILLED: {
      const categories = updateRows(state.categories, action.payload);

      return {
        ...state,
        categories,
        lastActionIds: null,
      };
    }
    case CREATE_CATEGORY_ROADMAP_FULFILLED:
      const cats = cloneDeep(state.categories);
      const category = cats.find(obj => obj.id === action.payload.category_id);

      category.category_roadmaps = [...(category.category_roadmaps || []), action.payload];
      category.updated_at = action.payload.updated_at;

      const updatedCategories = upsertListItem(category, cats);

      return {
        ...state,
        categories: updatedCategories,
      };
    case DELETE_CATEGORY_ROADMAP_FULFILLED:
      const existingCats = cloneDeep(state.categories);
      const { id: categoryId, categoryRoadmaps } = action.payload;

      const parent = existingCats.find(obj => obj.id === parseInt(categoryId));

      parent.category_roadmaps = categoryRoadmaps || [];

      const updated = upsertListItem(parent, existingCats);

      return {
        ...state,
        categories: updated,
      };
    case BULK_DELETE_CATEGORY_ROADMAP_FULFILLED:
      const existingCategories = cloneDeep(state.categories);
      const updatedCats = existingCategories.map(category => {
        if (category.id === action.payload) {
          return {
            ...category,
            category_roadmaps: [],
          };
        }

        return category;
      });

      return {
        ...state,
        categories: updatedCats,
      };
    default:
      return state;
  }
}
