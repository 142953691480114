export const SAVE_GRID_STATE = 'SAVE_GRID_STATE';

export const ADD_SELECTED_ITEM = 'ADD_SELECTED_ITEM';
export const REMOVE_SELECTED_ITEM = 'REMOVE_SELECTED_ITEM';
export const SET_SELECTED_ITEMS = 'SET_SELECTED_ITEMS';

export const SHOW_MERGE_LIGHTBOX = 'SHOW_MERGE_LIGHTBOX';
export const HIDE_MERGE_LIGHTBOX = 'HIDE_MERGE_LIGHTBOX';

export const ENABLE_SELECTION_MODE = 'ENABLE_SELECTION_MODE';
export const DISABLE_SELECTION_MODE = 'DISABLE_SELECTION_MODE';
export const CLEAR_SELECTED_ITEMS = 'CLEAR_SELECTED_ITEMS';

export const SAVE_GRID_FILTER = 'SAVE_GRID_FILTER';
export const SAVE_GRID_VIEW = 'SAVE_GRID_VIEW';
export const FETCH_GRID_VIEW = 'FETCH_GRID_VIEW';
export const SAVE_USER_GRID_VIEW = 'SAVE_USER_GRID_VIEW';
export const SAVE_USER_GRID_VIEW_FULFILLED = 'SAVE_USER_GRID_VIEW_FULFILLED';
export const FETCH_USER_GRID_VIEW = 'FETCH_USER_GRID_VIEW';
export const FETCH_USER_GRID_VIEW_FULFILLED = 'FETCH_USER_GRID_VIEW_FULFILLED';
export const SAVE_GRID_SELECTED_VIEW = 'SAVE_GRID_SELECTED_VIEW';
export const SAVE_GRID_CONFIG = 'SAVE_GRID_CONFIG';
