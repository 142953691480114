import React from 'react';

import ProjectLink from 'design-system/atoms/ProjectLink';

const TextLinkCellRenderer = ({ handleClick, data, value, withEllipsis }) => {
  if (!handleClick) {
    return '';
  }

  const decoratedHandleClick = e => {
    e.preventDefault();

    e.stopPropagation();

    handleClick(data);
  };

  return (
    <ProjectLink clean onClick={decoratedHandleClick} withEllipsis={withEllipsis}>
      {value}
    </ProjectLink>
  );
};

export default TextLinkCellRenderer;
