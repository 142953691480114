import { schema, normalize } from 'normalizr';

import { add, remove } from 'utils/store/entities';

import {
  CREATE_TASK_FULFILLED,
  FETCH_TASKS_FULFILLED,
  UPDATE_TASK_FULFILLED,
  DELETE_TASK_FULFILLED,
  BULK_UPDATE_TASKS_FULFILLED,
} from '../types';

const tasksEntity = new schema.Entity('tasks');

const mapTaskEntitiesToState = (state, entities) => ({
  ...state,
  tasks: add(state.tasks, entities.tasks),
});

const addTaskToState = (state, task) => {
  const { entities } = normalize(task, tasksEntity);

  return mapTaskEntitiesToState(state, entities);
};

const bulkAddTasksToState = (state, tasks) => {
  const { entities } = normalize(tasks, [tasksEntity]);

  return mapTaskEntitiesToState(state, entities);
};

const removeTaskFromState = (state, taskId) => {
  return {
    ...state,
    tasks: remove(state.tasks, [taskId]),
  };
};

const tasksEntitiesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TASKS_FULFILLED: {
      return bulkAddTasksToState(state, action.payload.data);
    }

    case CREATE_TASK_FULFILLED: {
      return addTaskToState(state, action.payload.data);
    }

    case UPDATE_TASK_FULFILLED: {
      return addTaskToState(state, action.meta.updatedTask);
    }

    case DELETE_TASK_FULFILLED: {
      return removeTaskFromState(state, action.payload);
    }

    case BULK_UPDATE_TASKS_FULFILLED: {
      return bulkAddTasksToState(state, action.payload);
    }

    default: {
      return state;
    }
  }
};

export { tasksEntitiesReducer };
