import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';

import Component from './Component';
import container from './container';

const styles = theme => ({
  slider: {
    margin: '10px 5px',
    width: 'calc(100% - 5px)',
  },
  colorPickerContainer: {
    position: 'absolute',
    zIndex: '5',
    marginTop: 44,
    border: '1px solid lightgrey',
    borderRadius: '6px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  planningStageRadio: {
    width: '30px',
    marginLeft: '10px',
  },
  planningStageLabel: {
    marginRight: '13px',
    flex: 'auto',
  },
  tabs: {
    minHeight: 0,
    fontWeight: 400,
    fontSize: '0.875rem',
    minWidth: 0,
  },
  label: {
    paddingBottom: 10,
  },
  estimatedTimeline: {
    border: '1px solid rgba(0, 0, 0, 0.54)',
    borderRadius: 5,
    padding: 7,
  },
});

export default compose(
  withStyles(styles),
  container,
)(Component);
