import React from 'react';
import styled from 'styled-components';

import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow';

const ProjectCellRenderer = ({ params, icon, onClick, renderTitle, showChildrenCount, textStyles }) => {
  const handleClick = event => {
    if (onClick) onClick(params, event);
  };

  const renderTitleContent = () => {
    if (renderTitle) {
      return renderTitle(params.data);
    }

    return params.valueFormatted || params.value;
  };

  return (
    <Wrapper onClick={handleClick}>
      {icon ? <Icon>{icon}</Icon> : null}
      <TooltipOnOverflow text={renderTitleContent()} elementWithOverflow="span">
        <StyledText textStyles={textStyles} withEllipsis>
          {renderTitleContent()}
        </StyledText>
      </TooltipOnOverflow>
      {showChildrenCount ? <ChildrenCount>({params?.node?.allChildrenCount || 0})</ChildrenCount> : null}
    </Wrapper>
  );
};

export default ProjectCellRenderer;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(0.5)}px;
  height: 100%;
`;

const Icon = styled.div`
  display: flex;
`;

const StyledText = styled(Text)`
  &&&& {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    color: ${({ theme }) => theme.palette.text.secondary};

    ${({ textStyles }) => textStyles};
  }
`;

const ChildrenCount = styled.span`
  &&&& {
    font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;
