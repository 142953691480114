import moment from 'moment-timezone';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';

import { DAY_UNIT_TIME, DISPLAY_MILESTONE_TOP_LANE, GROUP_PADDING } from './constants';
import { hasGroups, isItemIncludedInAnother, isLevel, isSelfTimelineGroup } from './basic';
import { isMilestoneTopGroup, makeMilestoneFilter, buildMilestoneTopGroups, getMilestonesFromData } from './milestones';
import { calculateItemPositionAndWidth } from './positionsAndWidths';
import { generateRows, getOpenedGroupsRowsCount } from './rows';

/**
 * Creates a mapper function to enrich the item with visual information (position and width).
 *
 * @param {String} zoomMode The calendar zoom mode (daily, monthly, weekly or quarterly)
 * @param {Number} slotWidth The width for each timeline slot
 * @param {Date} fromDate The initial date of the date range
 * @param {snapToGridOn} snapToGridOn The snap to grid options selected by the user if any
 *
 * @return {Function} the mapper function to be applied to each item
 * */
const makeItemWithLeftAndWidthMapper = (zoomMode, slotWidth, fromDate, snapToGridOn) => item => ({
  ...item,
  info: calculateItemPositionAndWidth(item, zoomMode, slotWidth, fromDate, snapToGridOn),
});

/**
 * Creates the reducer function to calculate the minium left coordinate for a group.
 *
 * @param {Number} accGroup The previous iteration result
 * @param {Array} row The list of row items
 *
 * @return {Function} the function to calculate the minium left for a group
 * */
const groupLeftReducer = (accGroup, row) =>
  Math.min(
    accGroup,
    row.reduce((accRow, item) => Math.min(accRow, item.info.left), row[0].info.left),
  );

/**
 * Creates the reducer function to calculate the maxium right coordinate for a group.
 *
 * @param {Number} accGroup The previous iteration result
 * @param {Array} row The list of row items
 *
 * @return {Function} the function to calculate the maxium right for a group
 * */
const groupRightReducer = (accGroup, row) =>
  Math.max(
    accGroup,
    row.reduce((accRow, item) => Math.max(accRow, item.info.right), row[0].info.right),
  );

/**
 * Adds the visual information to a group.
 *
 * @param {Array} rows The list of rows from a group
 *
 * @return {Object} the visual information for a group
 * */
const getGroupInfo = rows => {
  if (isEmpty(rows)) {
    return {};
  }

  const groupLeft = rows.reduce(groupLeftReducer, rows[0][0].info.left);
  const groupRight = rows.reduce(groupRightReducer, rows[0][0].info.right);

  const groupWidth = groupRight - groupLeft;

  return {
    left: groupLeft,
    right: groupRight,
    width: groupWidth,
  };
};

/**
 * Get all the groups items from the root level (recursively).
 *
 * @param {Object} group The group that contains the items
 * @param {Array} accumulator The result of the last iteration
 *
 * @return {Array} the list of all items from root level
 * */
const getGroupItems = (group, accumulator = []) => {
  const hasItems = !!group.items;

  if (hasItems) {
    return [...accumulator, ...group.items];
  }

  return [
    ...accumulator,
    ...(group?.groups?.reduce((accGroup, childGroup) => getGroupItems(childGroup, accGroup) ?? [], []) ?? []),
  ];
};

/**
 * Recursively get the groups when 3 group levels are selected.
 * */
const recursiveGetGroupsFor3Levels = (
  {
    data = [],
    zoomMode,
    slotWidth,
    fromDate,
    snapToGridOn,
    isGroupOpen,
    displayMilestone,
    displayMilestoneOn,
    isMilestoneItemChecker,
  },
  itemFilter,
  itemMapper,
) => {
  return data.map(groupLevel => {
    // Deal with level 1 of grouping
    if (isLevel(1)(groupLevel)) {
      return {
        ...groupLevel,
        groups: recursiveGetGroupsFor3Levels(
          {
            data: groupLevel?.groups,
            zoomMode,
            slotWidth,
            fromDate,
            snapToGridOn,
            isGroupOpen,
          },
          itemFilter,
          itemMapper,
        ),
      };
    }

    // Deal with groups inside the level 2 when there are 3 levels of grouping
    const groupLevel3AllItems = groupLevel?.groups?.map(group => getGroupItems(group).filter(itemFilter).map(itemMapper));

    if (isMilestoneTopGroup(groupLevel) || isEmpty(groupLevel3AllItems.flat())) {
      return {
        ...groupLevel,
        rowsCount: 1,
        groupings: [],
      };
    }

    const groupLevel3Rows = groupLevel3AllItems.map(items => generateRows(items));
    const groupLevel3Infos = groupLevel3Rows.map((rows, index) => {
      const group = groupLevel.groups[index];

      const itemsInfo = getGroupInfo(rows);

      const groupDetails = {
        ...group,
        info: itemsInfo,
        rows: groupLevel3Rows[index],
        items: groupLevel3AllItems[index],
        isSelfTimelineGroup: isSelfTimelineGroup(group),
      };

      if (isSelfTimelineGroup(group)) {
        if (!group.id) {
          return {
            ...groupDetails,
            hasOutsideItems: true,
          };
        }

        const groupData = group?.meta?.groupData;

        const groupStartDate = groupData.estimated_start_date ?? groupData.start_date;
        const groupEndDate = groupData?.deadline ?? groupData.end_date;

        const hasInvalidTimelineRange = !groupStartDate || !groupEndDate;

        if (hasInvalidTimelineRange) {
          return {
            ...groupDetails,
            isSelfTimelineGroup: isSelfTimelineGroup(group),
            hasOutsideItems: true,
          };
        }

        const startDate = moment(groupStartDate).startOf(DAY_UNIT_TIME);
        const endDate = moment(groupEndDate).endOf(DAY_UNIT_TIME);

        const selfTimelineInfo = calculateItemPositionAndWidth({ startDate, endDate }, zoomMode, slotWidth, fromDate);
        const isIncluded = isItemIncludedInAnother(itemsInfo, selfTimelineInfo);

        const unionInfo = {
          left: Math.min(selfTimelineInfo.left, itemsInfo.left),
          right: Math.max(selfTimelineInfo.right, itemsInfo.right),
          width: Math.max(selfTimelineInfo.right, itemsInfo.right) - Math.min(selfTimelineInfo.left, itemsInfo.left),
        };

        return {
          ...groupDetails,
          isSelfTimelineGroup: true,
          itemsTimelineInfo: itemsInfo,
          info: unionInfo,
          selfTimelineInfo,
          hasOutsideItems: !isIncluded,
        };
      }

      return groupDetails;
    });

    const groupRows = generateRows(groupLevel3Infos, GROUP_PADDING * 2);
    const groupings = groupRows.map(groupRow => {
      const allGroupRowItems = groupRow
        .map(group => (isGroupOpen(group.key) || isMilestoneTopGroup(group) ? group.items : []))
        .flat();

      return {
        groupRow: isMilestoneTopGroup(groupRow) ? null : groupRow.filter(group => !isEmpty(group.items)),
        rows: generateRows(allGroupRowItems),
      };
    });

    return {
      ...groupLevel,
      rowsCount: getOpenedGroupsRowsCount(groupRows, isGroupOpen),
      groupings,
    };
  });
};

/**
 * Get all the groups when 3 group levels are selected with the number of rows for each.
 * */
const getTimelineDataFor3Groups = (options, itemFilter, itemMapper) => {
  const determinedLevel2 = recursiveGetGroupsFor3Levels(options, itemFilter, itemMapper);

  return determinedLevel2.map(groupLevel1 => ({
    ...groupLevel1,
    rowsCount: groupLevel1.groups.reduce((accLevel1, groupLevel2) => accLevel1 + groupLevel2.rowsCount, 0),
  }));
};

/**
 * Recursively get the groups when 1 or 2 group levels are selected.
 * */
const recursiveGetTimelineDataFor1Or2Groups = ({ data = [], hideEmptyLane }, itemFilter, itemMapper) => {
  const isLastGroup = !data.some(hasGroups);

  if (isLastGroup) {
    return compact(
      data.map(lastLevel => {
        const transformedItems = lastLevel.items?.filter(itemFilter)?.map(itemMapper) ?? [];

        if (hideEmptyLane && isEmpty(transformedItems)) {
          return null;
        }

        const rows = generateRows(transformedItems);

        const groupDetails = {
          ...lastLevel,
          rows,
          rowsCount: rows.length || 1,
          items: lastLevel.items ?? [],
        };

        if (isEmpty(rows)) {
          return groupDetails;
        }

        return {
          ...groupDetails,
          info: getGroupInfo(rows),
        };
      }),
    );
  }

  return compact(
    data.map(groupLevel => {
      const nextGroups = recursiveGetTimelineDataFor1Or2Groups(
        {
          data: groupLevel?.groups,
          hideEmptyLane,
        },
        itemFilter,
        itemMapper,
      );

      if (hideEmptyLane && isEmpty(nextGroups)) {
        return null;
      }

      return {
        ...groupLevel,
        groups: nextGroups,
      };
    }),
  );
};

/**
 * Get all the groups when 1 or 2 group levels are selected with the number of rows for each.
 * */
const getTimelineDataFor1Or2Groups = ({ groupsCount, ...options }, itemFilter, itemMapper) => {
  const determinedLastLevel = recursiveGetTimelineDataFor1Or2Groups(options, itemFilter, itemMapper);

  if (groupsCount === 1) {
    return determinedLastLevel;
  }

  return determinedLastLevel.map(groupLevel1 => ({
    ...groupLevel1,
    rowsCount: groupLevel1.groups?.reduce((accLevel1, groupLevel2) => accLevel1 + groupLevel2.rowsCount, 0) ?? 0,
  }));
};

/**
 * Generate all the data in a format that can be displayed by the timeline based on the different options/preferences selected.
 *
 * */
const generateTimelineData = options => {
  const { zoomMode, slotWidth, fromDate, snapToGridOn, displayMilestone, displayMilestoneOn, isMilestoneItemChecker } = options;

  const milestoneFilter = makeMilestoneFilter(displayMilestone, displayMilestoneOn, isMilestoneItemChecker);
  const itemWithDimensionsMapper = makeItemWithLeftAndWidthMapper(zoomMode, slotWidth, fromDate, snapToGridOn);

  const itemsGroups =
    options?.groupsCount === 3
      ? getTimelineDataFor3Groups(options, milestoneFilter, itemWithDimensionsMapper)
      : getTimelineDataFor1Or2Groups(options, milestoneFilter, itemWithDimensionsMapper);

  if (displayMilestoneOn !== DISPLAY_MILESTONE_TOP_LANE || isMilestoneTopGroup(head(itemsGroups))) {
    return itemsGroups;
  }

  const { data } = options;

  // get all milestones from data
  const milestones = getMilestonesFromData(data, isMilestoneItemChecker);
  const milestonesWithDimensions = milestones.map(itemWithDimensionsMapper);

  // // Add the top milestone groups
  const milestoneTopGroups = buildMilestoneTopGroups(options?.groupsCount, milestonesWithDimensions);

  return [milestoneTopGroups, ...itemsGroups];
};

export { generateTimelineData };
