import { createSelector } from 'reselect';
import { defaultTo, path, pipe } from 'ramda';

import { getUsers } from 'store/users/selectors';

import { isLoading } from 'utils/store/thunk';

import { ADD_USER_INTEGRATION, DISABLE_INTEGRATION_WEBHOOKS, ENABLE_INTEGRATION_WEBHOOKS } from './types';

const defaultToEmptyArray = defaultTo([]);
const emptyObject = {};

export const getOrganizationIntegrations = createSelector(
  state => (state.organization || {}).integrations,
  integrations => integrations || [],
);

export const selectOrganizationIntegration = orgIntegrationId =>
  createSelector(getOrganizationIntegrations, orgIntegrations =>
    orgIntegrations.find(orgIntegration => orgIntegration.id === orgIntegrationId),
  );

export const getUserIntegrations = createSelector(
  state => state.login,
  state => state.integrations || [],
);

export const getAllUserIntegrationsForOrgIntegration = orgIntegrationId =>
  createSelector(getUsers, allOrgUsers =>
    allOrgUsers.filter(user =>
      (user.userIntegrations || []).some(userIntegration => userIntegration.org_integration_id === orgIntegrationId),
    ),
  );

// Slice state
export const getState = state => state.oneStepIntegration;

// Operations
export const selectOperationsState = state => getState(state).operations || {};

export const selectIsAddingUserIntegration = state => isLoading(selectOperationsState(state), ADD_USER_INTEGRATION);

export const selectIsEnablingIntegrationWebhooks = state => isLoading(selectOperationsState(state), ENABLE_INTEGRATION_WEBHOOKS);

export const selectIsDisablingIntegrationWebhooks = state =>
  isLoading(selectOperationsState(state), DISABLE_INTEGRATION_WEBHOOKS);

export const getFieldMappingForOrgIntegration = createSelector(
  getState,
  (_, orgIntegrationId) => orgIntegrationId,
  (state, orgIntegrationId) => {
    return defaultToEmptyArray(state.integrationFieldMappings?.[orgIntegrationId]);
  },
);

export const getAvailableIntegrations = createSelector(
  getState,
  oneStepIntegration => oneStepIntegration?.availableIntegrations || emptyObject,
);

export const getFeaturesByIntegrationType = createSelector(
  state => getAvailableIntegrations(state),
  (_, integrationType) => integrationType,
  (availableIntegrations, integrationType) => {
    return pipe(path([integrationType, 'features']), defaultTo([]))(availableIntegrations);
  },
);

export const getDragonboatFieldsForOrgIntegration = createSelector(
  getState,
  (_, orgIntegrationId) => orgIntegrationId,
  (state, orgIntegrationId) => {
    return defaultToEmptyArray(state.dragonboatFields?.[orgIntegrationId]);
  },
);
