import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { switchCustomerRequestRowOrder } from 'store/customerRequests/actions';
import { getGridCustomerRequests } from 'store/customerRequests/selectors';
import { addQueryParamToUrl } from 'utils/queryParamsUtils';
import { OPEN_CUSTOMER_REQUEST_QUERY_PARAM } from 'constants/customerRequests';

/**
 * @function useCustomerRequests
 *
 * Hook that handles all customer requests related info/events
 *
 * @return {Object}
 */
const useCustomerRequests = () => {
  const dispatch = useDispatch();

  const customerRequests = useSelector(getGridCustomerRequests);

  const onOpenCustomerRequestInfo = useCallback(row => {
    if (row) addQueryParamToUrl(OPEN_CUSTOMER_REQUEST_QUERY_PARAM, row?.key);
  }, []);

  const onSwitchRowOrder = useCallback((currentNode, lastOverNode, { position }) => {
    if (!currentNode) {
      return;
    }

    if (!lastOverNode) {
      return;
    }

    const currentNodeData = currentNode.data;
    const lastOverNodeData = lastOverNode.data;

    const currentNodeDataId = currentNodeData?.id;
    const lastOverNodeDataId = lastOverNodeData?.id;

    dispatch(switchCustomerRequestRowOrder(currentNodeDataId, lastOverNodeDataId, position));
  }, []);

  return {
    customerRequests,

    onOpenCustomerRequestInfo,
    onSwitchRowOrder,
  };
};

export default useCustomerRequests;
