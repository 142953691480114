import TableBody from './TableBody';
import TableCell from './TableCell';
import TableHead from './TableHead';
import TableHeader from './TableHeader';
import TableHeaderTitle from './TableHeaderTitle';
import TableRow from './TableRow';
import TableHeaderCell from './TableHeaderCell';

export default {
  Body: TableBody,
  Cell: TableCell,
  Head: TableHead,
  Header: TableHeader,
  HeaderTitle: TableHeaderTitle,
  HeaderCell: TableHeaderCell,
  Row: TableRow,
};
