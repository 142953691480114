import React, { useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from 'styled-components';

import theme from 'design-system/theme';

const CellText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ color }) => color && `color: ${color}`}
`;

const TextWithLinkContainer = styled.div`
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
`;

const RightPositionedIconButton = styled(props => <IconButton {...props}>{props.children}</IconButton>)`
  &&&& {
    position: absolute;
    top: 5px;
    right: 0;
    visibility: hidden;
    padding: 3px;
    font-size: 1.125rem;
  }
`;

const EstimateCellRenderer = params => {
  const { value, valueFormatted, data, colDef } = params;
  const { onClickFactory } = colDef.cellEditorParams;
  const { cellStyle } = colDef;

  const handleLinkClick = value?.showLink && onClickFactory(data, value);
  const icon = useMemo(() => {
    return (
      Boolean(valueFormatted) &&
      handleLinkClick && (
        <RightPositionedIconButton onClick={handleLinkClick}>
          <OpenInNewIcon style={{ fontSize: theme.typography.fontSize }} />
        </RightPositionedIconButton>
      )
    );
  }, [valueFormatted, handleLinkClick]);

  const styles = useMemo(() => (cellStyle ? cellStyle(params) : {}), [cellStyle]);

  return (
    <TextWithLinkContainer>
      <CellText color={styles.color}>{valueFormatted}</CellText>
      {icon}
    </TextWithLinkContainer>
  );
};

export default EstimateCellRenderer;
