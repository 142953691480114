import React, { Component } from 'react';
import { bool } from 'prop-types';
import styled from 'styled-components';

import TextAreaEditor from 'design-system/organisms/TextAreaEditor/index';


export default class AgGridTextArea extends Component {
  static propTypes = {
    autoFocus: bool,
  };

  static defaultProps = {
    autoFocus: false,
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.getValue = this.getValue.bind(this);
    this.addRef = this.addRef.bind(this);

    this.state = {
      value: props.value,
    };
  }

  getValue() {
    return this.state.value;
  }

  onChange(value) {
    this.setState({ value });
  }

  addRef(input) {
    if (input && this.props.autoFocus) {
      setTimeout(() => {
        if (input && typeof input.focus === 'function') {
          input.focus();
        }
      }, 0);
    }
  }

  render() {
    const { value } = this.state;

    return <Text value={value} name="details" onChange={this.onChange} ref={this.addRef} defaultActive disableToolbox />;
  }
}

const Text = styled(TextAreaEditor)`
  &&&&&& {
    width: 100%;
    padding: 0px;
    margin-top: 0;
    background: #f5f5f5;
    border: 1px solid #3f51b5 !important;

    .DraftEditor-root {
      max-height: 120px !important;
    }
  }
`;
