import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useInitAgGrid } from 'design-system/molecules/AgGridReact-New/hooks';
import { CUSTOMER_REQUESTS, setSelectedItems } from 'store/grids';
import useInitGridWithColumnsState from 'hooks/grid/useInitGridWithColumnsState';
import useGridApiContext from 'hooks/grid/useGridApiContext';

import useUpdateCustomerRequestOnGrid from './useUpdateCustomerRequestOnGrid';

/**
 * @function useCustomerRequestsGrid
 *
 * Hook responsable to collect all the info and events that are
 * needed to enrich the customer requests grid
 *
 * @return {Object}
 */
const useCustomerRequestsGrid = ({ columnsState, saveColumnsState }) => {
  const dispatch = useDispatch();
  const { registerGridApi: registerGridApiOnContext } = useGridApiContext();
  const { isGridReady: getGridIsReady, getGridApi, initAgGrid } = useInitAgGrid();

  const handleSelectionChanged = useCallback(({ api }) => {
    const currentlySelectedItems = api.getSelectedRows();

    dispatch(setSelectedItems(CUSTOMER_REQUESTS, currentlySelectedItems));
  }, []);

  const {
    handleGridReady: handleGridReadyForColumnsState,
    handleDisplayedColumnsChanged,
    handleColumnResized,
  } = useInitGridWithColumnsState({
    columnsState,

    getGridIsReady,
    saveColumnsState,
  });

  const { updateCustomerRequestByIdOnCellValueChange } = useUpdateCustomerRequestOnGrid();

  const handleGridReady = useCallback(
    params => {
      initAgGrid(params.api, params.columnApi);
      registerGridApiOnContext(CUSTOMER_REQUESTS, params.api, params.columnApi);

      handleGridReadyForColumnsState(params);
    },
    [initAgGrid, handleGridReadyForColumnsState, registerGridApiOnContext],
  );

  return {
    handleGridReady,
    getGridApi,
    getGridIsReady,
    handleSelectionChanged,
    updateCustomerRequestByIdOnCellValueChange,
    handleDisplayedColumnsChanged,
    handleColumnResized,
  };
};

export default useCustomerRequestsGrid;
