import moment from 'moment-timezone';
import { pipe, not, prop, both, propEq, either, tryCatch } from 'ramda';

import { PAGES_WITH_PORTFOLIO_MODE } from 'constants/filters';

import { DASHBOARDS_PAGE } from 'constants/filters/pages';
import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { SHARED_VIEW_PARAM, TEMPLATE_PARAM } from 'constants/userViews';

import { getPageIdFromPath as importedGetPageIdFromPath } from 'utils/pages';

const EXPLICIT = 'explicit';
const TYPE_OF_SUBSCRIPTION = 'typeOfSubscription';
const IS_STATIC = 'is_static';
const IS_OWNER = 'isOwner';

export const aintStatic = pipe(prop(IS_STATIC), not);
export const isOwner = propEq(IS_OWNER, true);
export const isNotOwner = pipe(isOwner, not);
const parseURL = url => new URL(url);
const safeURLParse = tryCatch(parseURL, () => null);

// !view.is_static && !view.isOwner
const notStaticNorTheOwner = both(aintStatic, isNotOwner);

const noSubscription = pipe(prop(TYPE_OF_SUBSCRIPTION), not);
const isExplicit = propEq(TYPE_OF_SUBSCRIPTION, EXPLICIT);

// (view.typeOfSubscription === 'explicit' || !view.typeOfSubscription)
const isValidSubscription = either(noSubscription, isExplicit);

export const isExplicitSubscribedView = both(notStaticNorTheOwner, isValidSubscription);

export const isDashboardView = v => v.page === DASHBOARDS_PAGE;

export const isNotDashboardView = v => !isDashboardView(v);

export const sortByUpdatedDate = (a, b) => moment(b.updated_at) - moment(a.updated_at);

export const userOwnsView = (view, user) => view?.user_id === user.id;

/**
 * @function getUserSavedViews
 *
 * Returns the views array only with the user's saved views
 *
 * @return {Array} views - Array with views
 */
export const getUserSavedViews = views => views.filter(view => view.favorites?.length);

/**
 * @function getUserSharedViews
 *
 * Returns the views array only with the user's shared views
 *
 * @return {Array} views - Array with views
 */
export const getUserSharedViews = views => views.filter(view => isNotDashboardView(view) && !view.favorites?.length);

/**
 * @function getUserDashboardsViews
 *
 * Returns the views array only with the user's dashboard views
 *
 * @return {Array} views - Array with views
 */
export const getUserDashboardsViews = views => views.filter(view => isDashboardView(view) && !view.favorites?.length);

/**
 * @function getViewName
 *
 * Returns the view name if the view has one otherwise it will return the view page + the path
 *
 * @param {Object} View
 * @return {String}
 */
export const getViewName = view => view.name || `${view.page} - ${view.path}`;

export const getPageIdFromPath = importedGetPageIdFromPath;

export const ensureViewStateCompatibility = (viewState, storeState) => {
  const usableViewState = Object.assign({}, viewState);
  const hasBets = storeState.organization?.organization?.has_bet;

  // We could have views with portfolioMode active but the page does not support
  const viewPageHasPorfolioMode = PAGES_WITH_PORTFOLIO_MODE.includes(usableViewState.page);

  if (viewPageHasPorfolioMode && usableViewState.portfolioMode && usableViewState.displayLayer === INITIATIVE_LAYER && hasBets) {
    // If org has bets and portfolioMode is true we need to show the entire tree which now starts on bet and not initiative
    // This is for the scenarios where the filter was stored before has_bet been enabled
    usableViewState.displayLayer = BET_LAYER;
  }

  return usableViewState;
};

/**
 * Get view id from the given URL
 * If the URL is not valid or doesn't match any of the view Id conditions this method
 * will return null
 *
 * @param {String} url
 * @returns {String|null}
 */
export const extractViewIdFromURL = url => {
  const pageUrl = safeURLParse(url);

  if (!pageUrl) {
    return null;
  }

  const pageId = getPageIdFromPath(pageUrl.pathname);

  if (!pageId) {
    return null;
  }

  const sharedViewKey = pageUrl.searchParams.get(SHARED_VIEW_PARAM);

  if (sharedViewKey) {
    return sharedViewKey;
  }

  // Fetch numeric identifier from path e.g. 1234 from -> /dashboard/dashboards/1234
  const matchInPath = /.+\/(\d+)$/.exec(pageUrl.pathname);

  // If match was found return the first regex group which is the ID
  return matchInPath ? matchInPath[1] : null;
};

export const extractTemplateIdFromURL = url => {
  const pageUrl = safeURLParse(url);

  if (!pageUrl) {
    return null;
  }

  const templateId = pageUrl.searchParams.get(TEMPLATE_PARAM);

  if (templateId) {
    return parseInt(templateId);
  }

  return null;
};

export const getUserViewTypeForComment = userView => {
  switch (true) {
    case userView.page === 'dashboards':
      return 'Dashboard';

    default:
      return 'User View';
  }
};
