import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { defaultTo, not } from 'ramda';

import { NEW_ROW_ID } from 'design-system/molecules/AgGridReact-New/helpers';
import { useGridRowDataWithUpdateDataLoading, useInitAgGrid } from 'design-system/molecules/AgGridReact-New/hooks';
import getEstimateWeekDur from 'utils/getEstimateWeekDur';
import { getHasSort } from 'utils/grids/helpers';

import useProcessUpdateProjectData from './useProcessUpdateProjectData';
import useIdeasGridExpandedGroups from './useIdeasGridExpandedGroups';

import { createChildFromProject } from 'utils/childProject';
import { getGroupDataOkrIdAndLevel, generateRowUniqueId } from 'utils/grid';
import { isSomeUpdateOnProjectsOcurring } from 'store/projects/selectors';

import { EDITABLE_METADATA_FIELDS } from '../constants';
import { checkDataIsProject, checkDataIsOkr } from '../helpers';
import useUpdateProjectMetrics from './useUpdateProjectMetrics';
import useUpdateProjectPersonas from './useUpdateProjectPersonas';
import useUpdateProjectLifecycles from './useUpdateProjectLifecycles';
import { estimateRounder } from 'utils/estimates/unitConversion';
import formatTwoDecimalPlaces from 'utils/estimates/formatToTwoDecimals';

const METRICS = 'metrics';
const PERSONAS = 'personas';
const LIFECYCLES = 'lifecycles';
const emptyArray = []; // To always keep the same reference. But attention: do not mutate it!

const defaultToEmptyArray = defaultTo(emptyArray);

const openGroupNodesTree = node => {
  node.setExpanded(true);

  const childUndefinedGroupNode = node.childrenAfterGroup.find(n => n.key === 'Undefined');

  if (childUndefinedGroupNode) {
    return openGroupNodesTree(childUndefinedGroupNode);
  }
};

const calcProjectTotalEstimates = item =>
  defaultToEmptyArray(item.estimates)
    .map(est => getEstimateWeekDur(formatTwoDecimalPlaces(est.duration), est.numStaff))
    .reduce((a, b) => estimateRounder.rounder(a + b), 0);

/**
 * Hook to abstract data and actions decoration to integrate them with AgGrid.
 */
const useIdeasGrid = (
  data = emptyArray,
  {
    parentHandleGridReady,
    displayLayer,
    portfolioMode,
    currentUser,
    hasBet,
    defaultPhase,
    expandedGroups = emptyArray,
    columnsState,
    viewType,
    hasOtherStoreUpdates = false,
    hasMultiLevelMetadata,
    shouldGridBeUnmounted,

    saveGridConfig,
    getSystemFieldName,
    createUnsavedProject,
    openProjectInfo,
    openOkrInfo,
    setSelectedItems,
    updateProjectById,
    loadParentProjects,
    saveColumnsState,
  },
) => {
  const [applyBasedOnSavedStateWasDone, setApplyBasedOnSavedStateWasDone] = useState(false);

  const processedData = useMemo(() => {
    return data.map(item => ({
      ...item,
      path: item.path || [item.id],
      uniqueId: item.title == null ? NEW_ROW_ID : generateRowUniqueId(item),

      // calc sum field
      sum: calcProjectTotalEstimates(item),

      // time estimate
      timeEstimate: item?.integrationProgress?.timeEstimated,
    }));
  }, [data]);
  const [autocompleteParents, setAutocompleteParents] = useState([]);

  const storeUpdating = useSelector(isSomeUpdateOnProjectsOcurring);

  const { processUpdateData: processUpdateDataForProjectUpdate } = useProcessUpdateProjectData(
    autocompleteParents,
    hasMultiLevelMetadata,
  );

  useEffect(() => {
    if (applyBasedOnSavedStateWasDone && shouldGridBeUnmounted) {
      setApplyBasedOnSavedStateWasDone(false);
    }
  }, [shouldGridBeUnmounted]);

  const { isGridReady: getGridIsReady, getGridApi, getGridColumnApi, initAgGrid } = useInitAgGrid();

  const gridApi = getGridApi();

  const { isGroupExpandedByDefault } = useIdeasGridExpandedGroups(viewType, gridApi);

  const { rowData } = useGridRowDataWithUpdateDataLoading(processedData, storeUpdating || hasOtherStoreUpdates, { getGridApi });

  const storeExpandedGroups = useCallback(
    node => {
      const expandedNodesIdsSet = new Set(expandedGroups);

      const groupIsNowOpen = node.expanded;

      if (groupIsNowOpen) {
        expandedNodesIdsSet.add(node.id);
      } else {
        expandedNodesIdsSet.delete(node.id);
      }

      saveGridConfig('expandedGroups', [...expandedNodesIdsSet]);
    },
    [expandedGroups, saveGridConfig],
  );

  const handleRowGroupOpened = useCallback(
    ({ node }) => {
      storeExpandedGroups(node);
    },
    [storeExpandedGroups],
  );

  const handleGridReady = useCallback(
    params => {
      initAgGrid(params.api, params.columnApi);

      const columnState = params.columnApi.getColumnState();

      /*
       * Should Apply initial columnsState on grid ready if column state does not exists
       */
      if (!columnsState) {
        saveColumnsState(columnState);
      }

      setApplyBasedOnSavedStateWasDone(true);

      if (getHasSort(columnState)) {
        // if view has sort, disable row drag
        params.api.setSuppressRowDrag(true);
      }

      if (parentHandleGridReady) {
        parentHandleGridReady(params);
      }
    },
    [parentHandleGridReady, columnsState, setApplyBasedOnSavedStateWasDone],
  );

  const addNewInlineProject = useCallback(
    params => {
      // This call only opens available nodes. If other Undefined group
      // nodes will exist after the new item row has been passed to the grid,
      // they will be open through `checkIsGroupOpenByDefault`.
      openGroupNodesTree(params.node);

      const newProject = createChildFromProject(
        params,
        currentUser,
        hasBet,
        getSystemFieldName,
        displayLayer,
        portfolioMode,
        defaultPhase,
      );

      createUnsavedProject(newProject);
    },
    [currentUser, hasBet, displayLayer, portfolioMode, defaultPhase, getSystemFieldName, createUnsavedProject],
  );

  const openRowInfo = useCallback(
    ({ data }) => {
      if (checkDataIsProject(data)) {
        return openProjectInfo(data);
      }

      if (checkDataIsOkr(data)) {
        const { okrId, level } = getGroupDataOkrIdAndLevel(data.groupData);

        return openOkrInfo(okrId, level);
      }
    },
    [openProjectInfo, openOkrInfo],
  );

  const handleSelectionChanged = useCallback(
    ({ api }) => {
      const currentlySelectedItems = api.getSelectedRows();

      return setSelectedItems(currentlySelectedItems);
    },
    [setSelectedItems],
  );

  const { updateProjectMetrics } = useUpdateProjectMetrics();
  const { updateProjectPersonas } = useUpdateProjectPersonas();
  const { updateProjectLifecycles } = useUpdateProjectLifecycles();

  const updateProjectByIdOnCellValueChange = useCallback(
    (id, updatedData, previousValue, params) => {
      const updatedField = params.colDef.field;

      /*
       * On metrics update will execute a specifi udpate method
       *
       * Dont need to update project on this case
       */
      if (updatedField === METRICS) {
        return updateProjectMetrics(id, params.newValue, params.oldValue);
      }

      if (updatedField === PERSONAS) {
        return updateProjectPersonas(id, params.newValue, params.oldValue);
      }

      if (updatedField === LIFECYCLES) {
        return updateProjectLifecycles(id, params.newValue, params.oldValue);
      }

      const processedUpdatedData = processUpdateDataForProjectUpdate(updatedData, updatedField);

      return updateProjectById(id, processedUpdatedData);
    },
    [updateProjectById, processUpdateDataForProjectUpdate, updateProjectMetrics],
  );

  const loadParentProjectsOptions = useCallback(
    async (valueToSearch, { data }) => {
      const parentProjects = await loadParentProjects(valueToSearch, data?.layer);

      setAutocompleteParents(parentProjects);

      return parentProjects.map(p => ({
        entity: p,
        label: p.title,
        value: p.title,
      }));
    },
    [loadParentProjects],
  );

  const handleMetadataCellDoubleClick = useCallback(
    ({ colDef, context, data }) => {
      if (!data || data.group) {
        return;
      }

      const fieldToCheck = colDef.field;

      const isEditableMetadata = EDITABLE_METADATA_FIELDS.includes(fieldToCheck);

      if (context.isParentDragon && isEditableMetadata) {
        openProjectInfo(data);
      }
    },
    [openProjectInfo],
  );

  const handleDisplayedColumnsChanged = useCallback(
    ({ columnApi }) => {
      /*
       * Ingore change if the grid is not ready
       */
      const gridIsNotReady = not(getGridIsReady());

      if (gridIsNotReady || !applyBasedOnSavedStateWasDone) {
        return;
      }

      const newColumnsState = columnApi.getColumnState();

      return saveColumnsState(newColumnsState);
    },
    [saveColumnsState, getGridIsReady, applyBasedOnSavedStateWasDone],
  );

  const handleColumnResized = useCallback(
    ({ finished, ...props }) => {
      if (finished) handleDisplayedColumnsChanged(props);
    },
    [handleDisplayedColumnsChanged],
  );

  return {
    processedData: rowData,

    getGridIsReady,
    getGridApi,
    getGridColumnApi,

    handleGridReady,
    handleRowGroupOpened,
    handleSelectionChanged,
    handleDisplayedColumnsChanged,
    handleColumnResized,
    checkIsGroupOpenByDefault: isGroupExpandedByDefault,
    addNewInlineProject,
    openRowInfo,
    updateProjectByIdOnCellValueChange,
    loadParentProjectsOptions,
    handleMetadataCellDoubleClick,
  };
};

export default useIdeasGrid;
