import axios from 'axios';
import { saveObjective, fetchObjectives } from './thunks';

import { METADATA_LEVELS } from 'constants/common';

import {
  UPDATE_OBJECTIVE,
  ADD_OBJECTIVE_WITHOUT_SAVE,
  REMOVE_OBJECTIVE_NOT_SAVED,
  ADD_OBJECTIVE,
  REMOVE_OBJECTIVE_BY_ID,
  SWAP_OBJECTIVES_ROW_ORDER,
  UPDATE_OBJECTIVE_ROW_ORDER,
  CREATE_OBJECTIVE,
  UPDATE_KEY_RESULT_ROW_ORDER,
  ADD_KEY_RESULT_WITHOUT_SAVE,
  REMOVE_UNSAVED_OBJECTIVES_AND_KEY_RESULTS,
  CREATE_KEY_RESULT,
  CREATE_OBJECTIVE_FULFILLED,
  UPDATE_OBJECTIVE_FULFILLED,
  BULK_DELETE_OBJECTIVES_FULFILLED,
  UPDATE_KEY_RESULT_ID_FULFILLED,
  CREATE_KEY_RESULT_FULFILLED,
  DELETE_KEY_RESULT_FULFILLED,
  OPEN_OBJECTIVE_DRAWER,
  CLOSE_OBJECTIVE_DRAWER,
  OBJECT_OBJECTIVE,
  BULK_DELETE_OBJECTIVE_ROADMAP,
  CREATE_OBJECTIVE_ROADMAP,
  DELETE_OBJECTIVE_ROADMAP,
  BULK_DELETE_KEY_RESULT_ROADMAP,
  CREATE_KEY_RESULT_ROADMAP,
  DELETE_KEY_RESULT_ROADMAP,
  UPDATE_OBJECTIVES_FULFILLED,
  UPDATE_KEY_RESULTS_FULFILLED,
  CREATE_OBJECTIVE_FROM_DRAWER,
  UPDATE_OBJECTIVE_FROM_DRAWER,
  CREATE_KEY_RESULT_FROM_DRAWER,
  UPDATE_KEY_RESULT_FROM_DRAWER,
} from './types';

import { CREATE, UPDATE, DELETE, BULK_DELETE, BULK_UPDATE } from 'store/constants/realtimeUpdateTypes';
import groomUpdateObject from './helpers/groomUpdateObject';

export const updateObjective = objective => {
  return dispatch => {
    const payload = axios.post(`/api/objectives/update/${objective.id}`, objective).then(res => res.data);

    dispatch({
      payload,
      type: UPDATE_OBJECTIVE,
    });

    return payload;
  };
};

export function addObjectiveWithoutSave(objective, level = METADATA_LEVELS.LEVEL_1) {
  return {
    type: ADD_OBJECTIVE_WITHOUT_SAVE,
    objective,
    level,
  };
}

export function removeObjectiveNotSaved() {
  return {
    type: REMOVE_OBJECTIVE_NOT_SAVED,
  };
}

export function addObjective(payload) {
  return {
    type: ADD_OBJECTIVE,
    payload,
  };
}

export function removeObjectiveById(id) {
  return {
    type: REMOVE_OBJECTIVE_BY_ID,
    id,
  };
}

export function swapObjectivesRowOrder(objective1, objective2) {
  return {
    type: SWAP_OBJECTIVES_ROW_ORDER,
    objective1,
    objective2,
  };
}

export function updateObjectiveRowOrder(objective) {
  return {
    type: UPDATE_OBJECTIVE_ROW_ORDER,
    objective,
  };
}

export const createObjective = objective => {
  return dispatch => {
    const payload = axios.post('/api/objectives/', objective).then(response => response.data);

    dispatch({
      payload,
      type: CREATE_OBJECTIVE,
    });

    return payload;
  };
};

export const createObjectiveFromDrawer = objective => {
  return dispatch => {
    const payload = axios.post('/api/objectives/', objective).then(response => response.data);

    dispatch({
      payload,
      type: CREATE_OBJECTIVE_FROM_DRAWER,
    });

    return payload;
  };
};

export function updateObjectiveFromDrawer(id, update) {
  return (dispatch, getState) => {
    const groomedUpdate = groomUpdateObject(update);

    const payload = axios.put(`/api/objectives/${id}`, groomedUpdate).then(response => response.data);

    dispatch({
      payload,
      type: UPDATE_OBJECTIVE_FROM_DRAWER,
    });

    return payload;
  };
}

export const createKeyResultFromDrawer = keyResult => {
  return dispatch => {
    const payload = axios.post('/api/keyResults/', keyResult).then(response => response.data);

    dispatch({
      payload,
      type: CREATE_KEY_RESULT_FROM_DRAWER,
    });

    return payload;
  };
};

export function updateKeyResultFromDrawer(id, update) {
  return (dispatch, getState) => {
    const groomedUpdate = groomUpdateObject(update);

    const payload = axios.put(`/api/keyResults/${id}`, groomedUpdate).then(response => response.data);

    dispatch({
      payload,
      type: UPDATE_KEY_RESULT_FROM_DRAWER,
    });

    return payload;
  };
}

export const addKeyResultWithoutSave = (parent, isKeyResult = false, level = 0) => ({
  type: ADD_KEY_RESULT_WITHOUT_SAVE,
  parent,
  isKeyResult,
  level,
});

export const removeUnsavedObjectivesAndKeyResults = () => ({
  type: REMOVE_UNSAVED_OBJECTIVES_AND_KEY_RESULTS,
});

export function updateKeyResultRowOrder(keyResult) {
  return {
    type: UPDATE_KEY_RESULT_ROW_ORDER,
    keyResult,
  };
}

export const createKeyResult = keyResult => {
  return dispatch => {
    const payload = axios.post('/api/keyResults/', keyResult).then(response => response.data);

    dispatch({
      payload,
      type: CREATE_KEY_RESULT,
    });

    return payload;
  };
};

export const createObjectiveRoadmap = (objectiveId, objectiveRoadmap, roadmapType = 'objective') => {
  const type = roadmapType === 'objective' ? CREATE_OBJECTIVE_ROADMAP : CREATE_KEY_RESULT_ROADMAP;
  const table = roadmapType === 'objective' ? 'objective' : 'keyResult';

  return {
    payload: axios.post(`/api/${table}s/${objectiveId}/${table}Roadmaps/`, objectiveRoadmap).then(response => response.data),
    type,
  };
};

export const deleteObjectiveRoadmap = ({ id, roadmapId, roadmapType = 'objective', subRoadmapId = null, product2Id = null }) => {
  const table = roadmapType === 'objective' ? 'objective' : 'keyResult';
  let route = `/api/${table}s/${id}/${table}Roadmaps/${roadmapId}`;

  route += subRoadmapId ? `/${subRoadmapId}` : '';
  route += subRoadmapId && product2Id ? `/${product2Id}` : '';

  const type = roadmapType === 'objective' ? DELETE_OBJECTIVE_ROADMAP : DELETE_KEY_RESULT_ROADMAP;

  return {
    payload: axios.delete(route).then(response => response.data),
    type,
  };
};

export const bulkDeleteObjectiveRoadmaps = (id, roadmapType = 'objective') => {
  const type = roadmapType === 'objective' ? BULK_DELETE_OBJECTIVE_ROADMAP : BULK_DELETE_KEY_RESULT_ROADMAP;
  const table = roadmapType === 'objective' ? 'objective' : 'keyResult';

  return {
    payload: axios.delete(`/api/${table}s/${id}/${table}Roadmaps/`).then(response => response.data),
    type,
  };
};

export const gotObjectivesRealtimeUpdate = (type, data) => {
  return dispatch => {
    switch (type) {
      case CREATE:
        return dispatch({
          type: CREATE_OBJECTIVE_FULFILLED,
          payload: data,
        });
      case DELETE:
        return dispatch({
          type: REMOVE_OBJECTIVE_BY_ID,
          id: data?.id,
        });
      case UPDATE:
        return dispatch({
          type: UPDATE_OBJECTIVE_FULFILLED,
          payload: { data },
        });
      case BULK_UPDATE:
        return dispatch({
          type: UPDATE_OBJECTIVES_FULFILLED,
          payload: data,
        });
      case BULK_DELETE:
        return dispatch({
          type: BULK_DELETE_OBJECTIVES_FULFILLED,
          payload: data.map(id => String(id)),
        });
      default:
    }
  };
};

export const gotKeyResultsRealtimeUpdate = (type, data) => {
  return dispatch => {
    switch (type) {
      case CREATE:
        return dispatch({
          type: CREATE_KEY_RESULT_FULFILLED,
          payload: data,
        });
      case DELETE:
        return dispatch({
          type: DELETE_KEY_RESULT_FULFILLED,
          payload: data.id,
        });
      case UPDATE:
        return dispatch({
          type: UPDATE_KEY_RESULT_ID_FULFILLED,
          payload: data,
        });
      case BULK_UPDATE:
        return dispatch({
          type: UPDATE_KEY_RESULTS_FULFILLED,
          payload: data,
        });
      default:
    }
  };
};

export const openObjectiveDrawer = (id, type = OBJECT_OBJECTIVE) => {
  return {
    type: OPEN_OBJECTIVE_DRAWER,
    payload: { id, type },
  };
};

export const closeObjectiveDrawer = () => {
  return { type: CLOSE_OBJECTIVE_DRAWER };
};

export const objectiveActions = {
  updateObjective,
  saveObjective,
  fetchObjectives,
  createObjective,
  removeObjectiveById,
  createKeyResult,
};
