import { defaultTo, has, isEmpty } from 'ramda';

import { KEY_SEPARATOR } from './constants';
import { configureDistance } from './positionsAndDistances';

const hasGroups = has('groups');

const defaultToNullString = defaultTo('null');

const createRowOrderKey = (...args) => args.map(defaultToNullString).join(KEY_SEPARATOR);
const convertKeyIntoArray = key => key?.split(KEY_SEPARATOR) || [];

const getItemWithLeftAndWidthMapper = (zoomMode, slotWidth, fromDate, minBarWidth, snapToGridOn) => item => ({
  ...item,
  info: configureDistance(item, zoomMode, slotWidth, fromDate, minBarWidth, snapToGridOn),
});

const generateRows = (filteredTasks = []) => {
  const rows = [];
  const taskIds = [];

  filteredTasks.forEach((task, taskIndex) => {
    if (!taskIds.includes(task.id)) {
      const row = [];

      row.push(task);
      taskIds.push(task.id);

      for (let i = taskIndex + 1; i < filteredTasks.length; i++) {
        if (!taskIds.includes(filteredTasks[i].id)) {
          let isOverlapped = false;

          row.forEach(item => {
            const obj1 = filteredTasks[i];
            const obj2 = item;

            const obj1Info = obj1.info;
            const obj2Info = obj2.info;

            const isIncluded =
              obj1Info.left >= obj2Info.left && obj1Info.left <= obj2Info.right && obj1Info.right <= obj2Info.right;

            const rightSideOverlaps =
              obj1Info.left > obj2Info.left && obj1Info.left < obj2Info.right && obj1Info.right >= obj2Info.right;

            const leftSideOverlaps = obj1Info.right > obj2Info.left && obj1Info.left <= obj2Info.left;

            if (isIncluded || rightSideOverlaps || leftSideOverlaps) {
              isOverlapped = true;
            }
          });

          if (!isOverlapped) {
            row.push(filteredTasks[i]);
            taskIds.push(filteredTasks[i].id);
          }
        }
      }

      if (row.length) {
        rows.push(row);
        taskIds.concat(row.map(task => task.id));
      }
    }
  });

  return rows;
};

const getTimelineItemsFromData = (data = [], zoomMode, slotWidth, fromDate, minBarWidth, snapToGridOn) => {
  const itemWithDimensionsMapper = getItemWithLeftAndWidthMapper(zoomMode, slotWidth, fromDate, minBarWidth, snapToGridOn);
  const isMultiGrouping = data.some(hasGroups);

  if (isMultiGrouping) {
    return data.map(firstLevel => ({
      ...firstLevel,
      groups: (firstLevel?.groups || []).map(secondLevel => ({
        ...secondLevel,
        items: secondLevel?.items ?? [],
        rows: generateRows(secondLevel.items?.map(itemWithDimensionsMapper) ?? []),
      })),
    }));
  }

  return data.map(firstLevel => ({
    ...firstLevel,
    items: firstLevel.items ?? [],
    rows: generateRows(firstLevel.items?.map(itemWithDimensionsMapper) ?? []),
  }));
};

const getTimelineOrders = (timelineData = []) => {
  const isMultiGrouping = timelineData.some(hasGroups);

  if (isMultiGrouping) {
    return timelineData.reduce((acc, group) => {
      const groupId = group.id;

      if (isEmpty(group.groups)) {
        return [...acc, createRowOrderKey(groupId, 'null', '0')];
      }

      const groups = group.groups.reduce((subGroupsAcc, subGroup) => {
        if (isEmpty(subGroup.rows)) {
          return [...subGroupsAcc, createRowOrderKey(groupId, subGroup.id, '0')];
        }

        const reducedRows = subGroup.rows.reduce(
          (rowsAcc, row, rowIndex) => [...rowsAcc, createRowOrderKey(groupId, subGroup.id, rowIndex)],
          [],
        );

        return [...subGroupsAcc, ...reducedRows];
      }, []);

      return [...acc, ...groups];
    }, []);
  }

  return (
    timelineData.reduce((acc, firstLevel) => {
      const reducedOrders =
        firstLevel.rows?.reduce((accRows, _, rowIndex) => [...accRows, createRowOrderKey(firstLevel.id, rowIndex)], []) || [];

      if (isEmpty(firstLevel.rows)) {
        reducedOrders.push(createRowOrderKey(firstLevel.id, '0'));
      }

      return [...acc, ...reducedOrders];
    }, []) || []
  );
};

export { getTimelineItemsFromData, getTimelineOrders, createRowOrderKey, convertKeyIntoArray };
