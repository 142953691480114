import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { pluck, flatten, groupBy, keys, sum, toString, uniq, pipe, isNil, reject } from 'ramda';

import { getTeams } from 'store/teams/selectors';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import { getAllProjectsFiltered } from 'store/projects/groupSelectors';
import { getDisplayLayer } from 'store/filters/selectors';
import { getUsers } from 'store/users/selectors';
import { getResourcesCountForTeamSkill } from 'utils/estimates';

const TEAMS_KEY = 'teams';
const ESTIMATES_KEY = 'estimates';
const NUM_STAFF_KEY = 'numStaff';

const getEstimates = pluck(ESTIMATES_KEY);
const mapToNumStaff = pluck(NUM_STAFF_KEY);
const filterNilValues = reject(isNil);
const groupByTeam = pipe(
  filterNilValues,
  groupBy(e => e.team_id),
);
const groupBySkill = pipe(
  filterNilValues,
  groupBy(e => e.skill_id),
);

const DEFAULT_OVERBOOKED_UNDERBOOKED_TEAMS = {
  overbooked: [],
  underbooked: [],
};

/**
 * @function getOverbookedAndUnderbookedForProjectsOnGroup
 *
 * Get overbooked and underbooked teams for a specific group of projects
 *
 * @param  {Array} projects
 * @param  {Array} users
 * @return {type} {description}
 */
export const getOverbookedAndUnderbookedForProjectsOnGroup = (projects, users) => {
  const allEstimates = flatten(getEstimates(projects));

  // group all estimates by team id
  const groupedEstimatesByTeam = groupByTeam(allEstimates);

  const appendTeamToUnderbooked = (acc, teamId) => ({
    ...acc,
    underbooked: [...acc.underbooked, teamId],
  });
  const appendTeamToOverbooked = (acc, teamId) => ({
    ...acc,
    overbooked: [...acc.overbooked, teamId],
  });

  /*
   * Will get all estimates for each team
   *
   * If at least one skill of the team is overbooked the whole team is overbooked
   */
  return keys(groupedEstimatesByTeam).reduce((acc, teamId) => {
    const teamEstimates = groupedEstimatesByTeam[teamId];

    // group team estimates by skill id
    const groupedEstimatesBySkill = groupBySkill(teamEstimates);

    // Check if all skills are underbooked to include the team on underbooked teams
    const allTeamSkillsAreUnderbooked = keys(groupedEstimatesBySkill).every(skillId => {
      const teamSkillAvailableStaffCount = getResourcesCountForTeamSkill(+teamId, +skillId, users);
      const teamSkillEstimatedStaffCount = sum(mapToNumStaff(teamEstimates.filter(e => e.skill_id === +skillId)));

      return teamSkillAvailableStaffCount > teamSkillEstimatedStaffCount;
    });

    if (allTeamSkillsAreUnderbooked) {
      return appendTeamToUnderbooked(acc, teamId);
    }

    // Check if has some skill overbooked on the team
    const hasSomeTeamSkillOverbooked = keys(groupedEstimatesBySkill).some(skillId => {
      const teamSkillAvailableStaffCount = getResourcesCountForTeamSkill(+teamId, +skillId, users);
      const teamSkillEstimatedStaffCount = sum(mapToNumStaff(teamEstimates.filter(e => e.skill_id === +skillId)));

      return teamSkillAvailableStaffCount < teamSkillEstimatedStaffCount;
    });

    if (hasSomeTeamSkillOverbooked) {
      return appendTeamToOverbooked(acc, teamId);
    }

    // does not add the team to any group if its not overbooked or underbooked
    return acc;
  }, DEFAULT_OVERBOOKED_UNDERBOOKED_TEAMS);
};

/**
 * @function validateOverbookedAndUnderbookedTeamsResult
 *
 * Will validate the result of the Overbooked and Underbooked teams
 *
 * If some overbooked team is presetn on underbooked teams should be removed
 * from underbooked teams list
 *
 * @param  {Object} result
 * @return {Object}
 */
const validateOverbookedAndUnderbookedTeamsResult = result => {
  const allOverbooked = uniq(result.overbooked);
  const allUnderbooked = uniq(result.underbooked).filter(tId => !allOverbooked.includes(tId));

  return {
    underbooked: allUnderbooked,
    overbooked: allOverbooked,
  };
};

/**
 * @function useOverbookedAndUnderbookedTeams
 *
 * Hook that is responsible for calculation the overbooked teams and the underbooked teams
 * based on the current projects that are currently showing on the list
 *
 * The steps to organize the overbooked teams and the underbooked teams are:
 *
 * 1. Group all projects by selectedGroup
 * 2. For each group will
 *      2.1 get all estimates from all projects groups and group estimates by team
 *      2.2 for each gropuped team will group only team estimates by skill
 *      2.3 for each skill will calculate the headcount value based on
 *          the availabe staffing and the estimated staffing
 *      2.4 Check if all skills are underbooked to include the team on underbooked teams
 * 3. Will validate if some overbooked team is included on underbooked teams, on that case
 *   team should be only present on the overbooked teams list
 *
 * @param {Object} selectedGroup
 * @return {type}
 */
const useOverbookedAndUnderbookedTeams = selectedGroup => {
  const teams = useSelector(state => getStateDataForPage(state, getTeams, TEAMS_KEY));
  const users = useSelector(getUsers);

  const allProjects = useSelector(getAllProjectsFiltered);
  const displayLayer = useSelector(getDisplayLayer);

  const groupedProjects = useMemo(() => {
    const groupProjectsBySelectedGroup = groupBy(p => p[selectedGroup?.field]);

    return groupProjectsBySelectedGroup(allProjects[displayLayer]);
  }, [allProjects, displayLayer, selectedGroup?.field]);

  const teamsHeadcount = useMemo(() => {
    const allOverbookedAndUnderbookedTeams = keys(groupedProjects).reduce((acc, groupId) => {
      const projectsOnGroup = groupedProjects[groupId];
      const overbookedAndUnderbooked = getOverbookedAndUnderbookedForProjectsOnGroup(projectsOnGroup, users);

      return {
        overbooked: [...acc.overbooked, ...overbookedAndUnderbooked.overbooked],
        underbooked: [...acc.underbooked, ...overbookedAndUnderbooked.underbooked],
      };
    }, DEFAULT_OVERBOOKED_UNDERBOOKED_TEAMS);

    return validateOverbookedAndUnderbookedTeamsResult(allOverbookedAndUnderbookedTeams);
  }, [groupedProjects, users]);

  const teamsWithLabel = useMemo(
    () =>
      teams.map(t => ({
        ...t,
        label: t.title,
      })),
    [teams],
  );

  const overbookedTeams = useMemo(() => {
    return teamsWithLabel.filter(t => teamsHeadcount?.overbooked.includes(toString(t.id)));
  }, [teamsWithLabel, teamsHeadcount?.overbooked]);

  const underbookedTeams = useMemo(() => {
    return teamsWithLabel.filter(t => teamsHeadcount?.underbooked.includes(toString(t.id)));
  }, [teamsWithLabel, teamsHeadcount?.underbooked]);

  return {
    overbookedTeams,
    underbookedTeams,
  };
};

export default useOverbookedAndUnderbookedTeams;
