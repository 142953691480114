import React from 'react';
import { useSelector } from 'react-redux';
import { values } from 'ramda';

import { makeSelectorToMetadataByKey, filterActiveMetadata } from 'utils/metadata';

import {
  ROADMAPS,
  PRODUCTS,
  THEMES,
  OBJECTIVES,
  CATEGORIES,
  KEY_RESULTS,
  KEY_RESULTS_2,
  ROADMAPS_CORP,
  OBJECTIVES_CORP,
  PRODUCTS_2,
  METRICS,
  TIMEFRAMES,
} from 'constants/common';

const KEYS = [
  ROADMAPS,
  ROADMAPS_CORP,
  PRODUCTS,
  PRODUCTS_2,
  OBJECTIVES_CORP,
  OBJECTIVES,
  KEY_RESULTS,
  KEY_RESULTS_2,
  THEMES,
  CATEGORIES,
  METRICS,
  TIMEFRAMES,
];

const getSelectorsByKey = makeSelectorToMetadataByKey({ keysToPick: KEYS });

export default ({ showArchived } = {}) => {
  const metadataFromStore = useSelector(state => getSelectorsByKey(state));

  const metadata = React.useMemo(() => {
    if (showArchived) return metadataFromStore;

    return filterActiveMetadata(metadataFromStore);
  }, [...values(metadataFromStore), showArchived]);

  return metadata;
};
