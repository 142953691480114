import { pathOr } from 'ramda';

import { isLoading } from 'utils/store/thunk';

import { FETCH_FIELDS_LAYOUT } from './types';

const getState = state => state?.fieldsLayout;

const selectOperationsState = state => getState(state).operations || {};

const selectFieldsLayout = (state, type, level) => {
  const fieldsLayoutState = getState(state);

  return pathOr(undefined, [type, level])(fieldsLayoutState);
};

const selectIsFetchingFieldsLayout = state => isLoading(selectOperationsState(state), FETCH_FIELDS_LAYOUT);

const selectFormLayoutIsDirty = (state, type) => {
  const fieldsLayoutState = getState(state);

  return pathOr(false, ['forms', type, 'isDirty'])(fieldsLayoutState);
};

const selectFormLayoutDirtyWarningIsOpen = (state, type) => {
  const fieldsLayoutState = getState(state);

  return pathOr(false, ['forms', type, 'showFormLayoutDirtyWarning'])(fieldsLayoutState);
};

export { selectFieldsLayout, selectFormLayoutIsDirty, selectFormLayoutDirtyWarningIsOpen, selectIsFetchingFieldsLayout };
