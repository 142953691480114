import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import axios from 'axios';
import LaunchIcon from '@material-ui/icons/Launch';

import PageTitle from 'design-system/atoms/PageTitle/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import InfoToast from 'components/InfoToast';
import formatDate from 'utils/dates/formatDate';

import { getPlan, getNumEditors, getRecurrency, numberWithCommas } from './billingUtils';

class SubscriptionInfo extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {};

    this.getPlan = getPlan.bind(this);
    this.getNumEditors = getNumEditors.bind(this);
    this.getRecurrency = getRecurrency.bind(this);
    this.numberWithCommas = numberWithCommas.bind(this);
  }

  updateEditors = async newValue => {
    this.setState({ loading: true });
    await axios.post('/api/billing/editors', {
      quantity: newValue,
    });

    toast(<InfoToast>Your subscription was successfully update</InfoToast>);
    await this.props.updateBillingInfo();
    this.setState({ loading: false });
  };

  // state = {};
  render() {
    return (
      <Fragment>
        {!this.props.loading && (
          <BillingPaper>
            <Wrapper>
              <PageTitle>Receipts</PageTitle>
              {this.props.billingInfo.invoices && (
                <React.Fragment>
                  {this.props.billingInfo.invoices.map(invoice => (
                    <InvoicesLine>
                      <InvoicesCell variant="subtitle2">{formatDate(invoice.created)}</InvoicesCell>
                      <InvoicesAmountCell>$ {this.numberWithCommas(invoice.amount.toFixed(2))}</InvoicesAmountCell>
                      <InvoicesIconCell>
                        <ButtonIcon title="Get receipt" onClick={() => window.open(invoice.link)}>
                          <SaveInvoiceIcon />
                        </ButtonIcon>
                      </InvoicesIconCell>
                    </InvoicesLine>
                  ))}
                </React.Fragment>
              )}
            </Wrapper>
          </BillingPaper>
        )}
        {this.props.loading && <div />}
      </Fragment>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
`;

const BillingPaper = styled.div`
  max-width: 70%;
  // flex-grow: 1;
  padding: 40px 40px 30px 40px;
`;

const InvoicesLine = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
`;

const InvoicesCell = styled(TextDeprecated)`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

const InvoicesAmountCell = styled(InvoicesCell)`
  width: 100px;
  justify-content: flex-end;
`;

const InvoicesIconCell = styled(InvoicesCell)`
  &&&& {
    justify-content: flex-end;

    button {
      padding: 0;
    }
  }
`;

const SaveInvoiceIcon = styled(LaunchIcon)`
  &&&& {
    width: 18px;
    height: 18px;
  }
`;

export default SubscriptionInfo;
