import { equals } from 'ramda';

import getSystemFieldName from 'utils/getSystemFieldName';
import { createSelector } from 'reselect';
import { getAllRoadmaps } from '../roadmaps/selectors';
import { getOrganizationAccessControlData } from '../accessControl/selectors';
import { getOrganizationSystemFields } from '../organization';
import { getUserName } from 'utils/index';

const CORP_PLACEHOLDER_TITLE = 'CORP_PLACEHOLDER_TITLE';
const OBJECTIVE_CORP = 'objectiveCorp';
const TIMEFRAME_CORP = 'timeframeCorp';
// const CATEGORY_CORP = 'categoryCorp';
const OWNER = 'owner';

const isCorpPlaceholderTitle = equals(CORP_PLACEHOLDER_TITLE);
const isOwnerField = equals(OWNER);
const getRoadmapTitle = (roadmaps, roadmapId) => roadmaps.find(roadmap => roadmap.id === roadmapId)?.title;

const metadataIdByMetadataKey = {
  [OBJECTIVE_CORP]: 'portfolio_placeholder_objective_id',
  [TIMEFRAME_CORP]: 'portfolio_placeholder_timeframe_id',
  // [CATEGORY_CORP]: 'portfolio_placeholder_category_id',
};

const getMetadataCorpTitleBasedOnRoadmap = (
  objective,
  metadataKey,
  { roadmaps = [], organizationAccessControl = [], systemFieldNames } = {},
) => {
  const portfolioRoadmapId = organizationAccessControl.find(
    access => access?.[metadataIdByMetadataKey[metadataKey]] === objective?.id,
  )?.portfolio_roadmap_id;
  const roadmapTitle = getRoadmapTitle(roadmaps, portfolioRoadmapId);
  const fieldName = getSystemFieldName(metadataKey, systemFieldNames, false);

  return `${roadmapTitle} ${fieldName}`;
};

const getMetadataTitle = (data, metadataKey, orgMetadataForCorpLevelTitle) => {
  if (isCorpPlaceholderTitle(data?.title)) {
    return getMetadataCorpTitleBasedOnRoadmap(data, metadataKey, orgMetadataForCorpLevelTitle);
  }

  if (isOwnerField(metadataKey) && data) {
    return getUserName(data);
  }


  return data?.title;
};

const emptyArray = [];

const getOrgMetadataForCorpLevelTitles = createSelector(
  state => getAllRoadmaps(state),
  state => getOrganizationAccessControlData(state) || emptyArray,
  state => getOrganizationSystemFields(state) || emptyArray,
  (roadmaps, organizationAccessControl, systemFieldNames) => ({ roadmaps, organizationAccessControl, systemFieldNames }),
);

export default getMetadataTitle;

export { getOrgMetadataForCorpLevelTitles, isCorpPlaceholderTitle, OBJECTIVE_CORP, TIMEFRAME_CORP };
