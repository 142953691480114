import React, { useCallback, useMemo, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import DOMPurify from 'dompurify';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Text from 'design-system/atoms/Text/index';
import Card from 'design-system/molecules/Card/index';

import theme from 'design-system/theme';
import ConfirmDialog from 'components/ConfirmDialog';
import formatDate from 'utils/dates/formatDate';
import { getUserName } from 'utils/index';
import AnnouncementLightbox from './AnnouncementLightbox';
import useOrgAnnouncements from './useOrgAnnouncements';
import ShowMoreButton from '../../components/ShowMoreButton';

const Announcements = () => {
  const [isAnnouncementLightboxOpen, setIsAnnouncementLightboxOpen] = useState(false);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState(null);

  const {
    addAnnouncementsVisible,
    announcementCardTitle,
    announcements,
    handleArchiveAnnouncement,
    handleDeleteAnnouncement,
    isAdmin,
    canArchiveAnnouncements,
    saveAnnouncement,
    selectedAnnouncement,
  } = useOrgAnnouncements(selectedAnnouncementId);

  const toggleAnnouncementLightbox = useCallback(() => {
    const currentlyOpen = !!isAnnouncementLightboxOpen;

    setIsAnnouncementLightboxOpen(!isAnnouncementLightboxOpen);

    if (currentlyOpen) setSelectedAnnouncementId(null);
  }, [isAnnouncementLightboxOpen, setIsAnnouncementLightboxOpen]);

  const handleSaveAnnouncement = useCallback(
    (value, channel, integrationType) => {
      saveAnnouncement(value, channel, integrationType);
      toggleAnnouncementLightbox();
    },
    [saveAnnouncement, toggleAnnouncementLightbox],
  );

  const handleUpdateAnnouncement = useCallback(
    announcementId => {
      setSelectedAnnouncementId(announcementId);
      toggleAnnouncementLightbox();
    },
    [setSelectedAnnouncementId, toggleAnnouncementLightbox],
  );

  const toggleDeleteConfirmationDialog = useCallback(() => {
    setIsDeleteConfirmationDialogOpen(!isDeleteConfirmationDialogOpen);
  }, [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen]);

  const handleClickDeleteAnnouncement = useCallback(() => {
    handleDeleteAnnouncement(selectedAnnouncementId);
    toggleDeleteConfirmationDialog();
    toggleAnnouncementLightbox();
  }, [handleDeleteAnnouncement, selectedAnnouncementId, toggleAnnouncementLightbox, toggleDeleteConfirmationDialog]);

  const emptyAnnouncements = useMemo(() => !announcements?.length, [announcements]);

  const listItems = useMemo(() => {
    return announcements.map((announcement, index) => {
      const isLast = index >= announcements.length - 1;

      const updateAnnouncement = () => handleUpdateAnnouncement(announcement.id);
      const parsedAnnouncementMessage = ReactHtmlParser(DOMPurify.sanitize(announcement.message), { decodeEntities: true });

      return (
        <StyledListItem key={`announcement-${announcement.id}`} disableGutters disablePadding isLast={isLast}>
          <LeftListItemContainer>
            <StyledListItemText $isAdmin={isAdmin} onClick={isAdmin && updateAnnouncement} primary={parsedAnnouncementMessage} />
            {canArchiveAnnouncements && (
              <EditorContainer>
                <Text color={theme.palette.newLayout.background.darkGray} size="small">
                  {`${getUserName(announcement.updatedBy)}${announcement.updatedBy ? ',' : ''} ${formatDate(
                    announcement.updated_at,
                    true,
                  )}`}
                </Text>
              </EditorContainer>
            )}
          </LeftListItemContainer>
          <RightListItemContainer>
            {canArchiveAnnouncements && (
              <>
                <StyledIconButton onClick={() => handleArchiveAnnouncement(announcement.id)}>
                  <CloseIcon />
                </StyledIconButton>
                <StyledIconButton onClick={updateAnnouncement}>
                  <OpenInNewIcon />
                </StyledIconButton>
              </>
            )}
            {isAdmin && (
              <StyledIconButton onClick={updateAnnouncement}>
                <EditOutlinedIcon />
              </StyledIconButton>
            )}
          </RightListItemContainer>
        </StyledListItem>
      );
    });
  }, [announcements, handleArchiveAnnouncement, handleUpdateAnnouncement, isAdmin, canArchiveAnnouncements]);

  const emptyListText = useMemo(() => {
    return (
      <TextContainer>
        <Text>No Announcements</Text>
      </TextContainer>
    );
  }, []);

  return (
    <>
      <Card title={announcementCardTitle}>
        {emptyAnnouncements ? emptyListText : listItems}
        {addAnnouncementsVisible ? <ShowMoreButton onClick={toggleAnnouncementLightbox} label="+ Add New Announcement" /> : null}
      </Card>
      <AnnouncementLightbox
        isOpen={isAnnouncementLightboxOpen}
        onClose={toggleAnnouncementLightbox}
        onDeleteAnnouncement={toggleDeleteConfirmationDialog}
        onSaveAnnouncement={handleSaveAnnouncement}
        selectedAnnouncement={selectedAnnouncement}
        isAdmin={isAdmin}
      />
      <ConfirmDialog
        isOpen={isDeleteConfirmationDialogOpen}
        onCancel={toggleDeleteConfirmationDialog}
        onConfirm={handleClickDeleteAnnouncement}
        title="Delete selected announcement"
        text="Are you sure you want to delete the seleced announcement?"
      />
    </>
  );
};

export default Announcements;

const StyledIconButton = styled(IconButton)`
  && {
    padding: 6px;
    margin-right: 10px;
    opacity: 0;
    transition: opacity 0.2s;
  }
  svg {
    font-size: ${({ theme }) => theme.typography.fontSizeMediumSmall}px;
  }
`;

const StyledListItem = styled(ListItem)`
  && {
    min-height: 50px;
    justify-content: space-between;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: background-color 0.2s;
    }
    &:hover {
      :before {
        background-color: ${({ theme }) => theme.palette.newLayout.background.transparentBlack};
      }
      ${StyledIconButton} {
        opacity: 1;
      }
    }
    ${({ isLast, theme }) =>
      !isLast &&
      `
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: ${theme.palette.newLayout.background.lighterBlack}
        }
    `}
  }
`;

const LeftListItemContainer = styled.div`
  padding-left: ${({ theme }) => theme.spacing.unit * 2}px;
`;

const StyledListItemText = styled(ListItemText)`
  &&& {
    position: relative;
    > span {
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
      line-height: ${({ theme }) => theme.typography.lineHeightSmallRem}rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  ${({ $isAdmin }) => $isAdmin && 'cursor: pointer;'}
`;

const RightListItemContainer = styled.div`
  display: flex;
`;

const TextContainer = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditorContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
