import { indexBy, isEmpty, last, length, not, pick, pipe, uniqWith } from 'ramda';
import buildGroupingHelperDataStructure from './buildGroupingHelperDataStructure';
import { getGroupDataObjects } from '../../groupMetadata';

const UNDEFINED_TITLE = 'Undefined';
const CELL_GROUP_LEVEL = 3;

const indexById = indexBy(project => project.id);

/**
 * Returns true if the all the leafs of the grouping tree are empty
 * @param {Object} group
 * @returns {Boolean}
 */
const isTreeEmpty = group => {
  if (!group || !group.elements) return false;

  if (isEmpty(group.elements)) return true;

  const isGroupEmpty = group.elements.reduce((isGroupEmpty, eachElement) => {
    return isGroupEmpty && isTreeEmpty(eachElement);
  }, true);

  return isGroupEmpty;
};

const generateProject = (parentGroups, groupOptions) => project => ({
  ...project,
  parentGroups: parentGroups.map((eachGroup, index) => generateGroup(eachGroup, groupOptions?.[index], index + 1)),
});

const getGroupTitle = group => {
  if (group?.title) {
    return group.title;
  }

  if (group?.first_name) {
    return group.last_name ? `${group.first_name} ${group.last_name}` : group.first_name;
  }

  if (group?.name) {
    return group.name;
  }

  return UNDEFINED_TITLE;
};

const generateGroup = (group, selectedGroup, level = 1) => {
  const groupData = {};

  if (selectedGroup?.field) {
    groupData[selectedGroup?.field] = group.id;
  }

  if (selectedGroup?.key) {
    groupData[selectedGroup?.key] = group;
  }

  return {
    group: true,
    title: getGroupTitle(group),
    rank: group?.rank || '',
    row_order: group?.row_order,
    date: group?.date,
    groupData,
    groupOption: selectedGroup,
    level,
  };
};

const getThisLevelElements = (groupsTree, nextLevelGroups, projectMapper = a => a, groupOptions, groupedIds) => {
  // projectMapper ||= a => a; // this expression breaks the storybook
  if (!projectMapper) projectMapper = a => a;

  let groupProjects = groupsTree.reduce((ids, eachGroup) => ids?.[eachGroup.id] || null, groupedIds) || [];

  if (!Array.isArray(groupProjects)) groupProjects = [];

  return isEmpty(nextLevelGroups)
    ? groupProjects.map(pipe(generateProject(groupsTree, groupOptions), projectMapper))
    : nextLevelGroups;
};

const isNotLastLevel = (groupsTree, groupOptions) => length(groupsTree) !== length(groupOptions);

const getGroupsTreeDepth = length;

const isNotDuplicate = (a, b) => a.id === b.id && not(a.group);

/**
 * Checks for duplicates based on not being a group and id match -
 * ex. if goal mode and OKR has more than one roadmap/sub-roadmap applied, it is
 * counted multiple times for the elements for a group
 * @param {Array} elements
 * @returns array of elements without duplicates
 */
const processElements = elements => uniqWith(isNotDuplicate, elements);

const getLevelObject = (
  groupsTree,
  projectMapper,
  groupOptions,
  groupsDataObjects,
  hideEmptyWith,
  groupedIds,
  groupFilterFunctions,
  shouldHideGroup,
) => {
  const currentObject = last(groupsTree);
  const depth = getGroupsTreeDepth(groupsTree);
  let nextLevelGroups = [];

  if (isNotLastLevel(groupsTree, groupOptions)) {
    nextLevelGroups = getNextLevel(
      groupsTree,
      groupsDataObjects,
      projectMapper,
      groupOptions,
      hideEmptyWith,
      groupedIds,
      groupFilterFunctions,
      shouldHideGroup,
    );
  }

  const elements = getThisLevelElements(groupsTree, nextLevelGroups, projectMapper, groupOptions, groupedIds);

  return {
    ...generateGroup(currentObject, groupOptions?.[depth - 1], depth),
    elements: processElements(elements),
  };
};

const filterGroupsByParentFactory = (groupsTree, groupFilterFunctions, groupOptions) => group => {
  const depth = getGroupsTreeDepth(groupsTree);
  const isFirstLevel = !depth;
  const isUndefinedGroup = !group?.id;
  const currentGroupOptionKey = groupOptions[depth]?.key;
  const parentGroupOptionKey = groupOptions[depth - 1]?.key;
  const topLevelGroupOptionKey = groupOptions[depth - 2]?.key;
  const hasTopLevelGroup = !!topLevelGroupOptionKey;

  if (isFirstLevel || isUndefinedGroup) return true;

  // If group above is on same hierarchy of current group (ie. group filter function for parent level exists) it should filter
  // current group results based on parent group
  const parentGroup = last(groupsTree);
  const parentGroupFilterFunction = groupFilterFunctions?.[currentGroupOptionKey]?.[parentGroupOptionKey];
  const shouldFilterCurrentGroupByGroupAbove = !!parentGroupFilterFunction;

  if (shouldFilterCurrentGroupByGroupAbove) return parentGroupFilterFunction(group, parentGroup);

  // If group above is not on same hierarchy of current group and top level group is, it should filter
  // current group results based on parent group
  if (hasTopLevelGroup) {
    const topLevelGroup = groupsTree[depth - 2];
    const topLevelFilterFunction = groupFilterFunctions?.[currentGroupOptionKey]?.[topLevelGroupOptionKey];
    const shouldFilterByTopLevelGroup = !!topLevelFilterFunction;

    if (shouldFilterByTopLevelGroup) return topLevelFilterFunction(group, topLevelGroup);
  }

  return true;
};

/**
 * @function getNextLevel
 *
 * Gets next level of grouping objects (1, 2 or 3) according to group selectors
 *
 * @param  {Array} groupsTree
 * @param  {Array} groupsDataObjects
 * @param  {Function} projectMapper
 * @param  {Array} groupOptions
 * @param  {Boolean} hideEmpty
 * @param  {Function} hideEmptyWith
 * @param  {Object} groupedIds
 * @param  {Object} groupFilterFunctions
 * @param  {Function} shouldHideGroup
 */
const getNextLevel = (
  groupsTree = [],
  groupsDataObjects,
  projectMapper,
  groupOptions,
  hideEmptyWith,
  groupedIds,
  groupFilterFunctions,
  shouldHideGroup,
) => {
  const depth = getGroupsTreeDepth(groupsTree);

  const filterGroupsByParent = filterGroupsByParentFactory(groupsTree, groupFilterFunctions, groupOptions);

  return groupsDataObjects[depth]
    .filter(filterGroupsByParent) // eg. L2 OKRs must not be repeated under multiple L1 OKRs. must respect hierarchy
    .reduce((nextLevel, eachObject) => {
      const group = getLevelObject(
        [...groupsTree, eachObject],
        projectMapper,
        groupOptions,
        groupsDataObjects,
        hideEmptyWith,
        groupedIds,
        groupFilterFunctions,
        shouldHideGroup,
      );

      if (shouldHideGroup) {
        return shouldHideGroup(eachObject, groupOptions[depth]?.key, group?.level === CELL_GROUP_LEVEL, isTreeEmpty(group))
          ? nextLevel
          : [...nextLevel, group];
      }

      /*
       * true if should exclude empty groups on current grouping level
       * empty group means there's no project under that group tree
       * eg. if the group in analysis has other two levels of grouping under it and
       * shouldExcludeEmpty is true it searchs for projects on the maximium depth and, if all empty, is considered empty group
       */
      const shouldExcludeEmpty = hideEmptyWith(groupOptions[depth]?.key, eachObject);

      return shouldExcludeEmpty && isTreeEmpty(group) ? nextLevel : [...nextLevel, group];
    }, []);
};

/**
 * @function getNestedProjectGroups
 *
 * Returns a nested structure of groups on top and middle levels and projects on lower level
 *
 * @param  {Array}    projectsForGrouping
 * @param  {Array}    groupingObjects - Array of arrays of objects (n Arrays for n Groups)
 * @param  {Array}    groupOptions - selected groups
 * @param  {Object}   config
 * @param  {Object}   config.groupFilterFunctions - nested structure that represents for a given object how to
 * find the parent and parent of parent
 * @param  {Function} config.hideEmptyWith - returns if should hide empty objects or not
 * @param  {Function} config.projectMapper - method that formats a project for a given page
 * @param  {Boolean}  config.withHierarchy
 * @param  {Function}  config.shouldHideGroup
 * @returns {Object}
 */
const getNestedProjectGroups = (projectsForGrouping = [], groupingObjects = [], groupOptions = [], config = {}) => {
  if (isEmpty(groupingObjects) || isEmpty(groupOptions)) {
    return projectsForGrouping;
  }

  const { groupFilterFunctions, hideEmptyWith, projectMapper, withHierarchy, shouldHideGroup } = config;

  const group1DataObjects = getGroupDataObjects(groupOptions?.[0], groupingObjects);
  const group2DataObjects = getGroupDataObjects(groupOptions?.[1], groupingObjects);
  const group3DataObjects = getGroupDataObjects(groupOptions?.[2], groupingObjects);

  const projectsById = indexById(projectsForGrouping);
  const parentsIds = projectsForGrouping.filter(p => !isEmpty(p.children)).map(p => p.id);
  const parentsById = pick(parentsIds, projectsById);

  // Create a data structure that will be used to create the groups and avoid iterating all the projects with O^2 complexity
  const groupedIds = buildGroupingHelperDataStructure(
    projectsForGrouping,
    parentsById,
    [group1DataObjects, group2DataObjects, group3DataObjects],
    groupOptions,
    withHierarchy,
  );

  return getNextLevel(
    [],
    [group1DataObjects, group2DataObjects, group3DataObjects],
    projectMapper,
    groupOptions,
    hideEmptyWith,
    groupedIds,
    groupFilterFunctions,
    shouldHideGroup,
  );
};

export { getNestedProjectGroups };
