import React, { useMemo } from 'react';
import styled from 'styled-components';

import BaseLayout from 'design-system/organisms/BaseLayout/index';

import ScenariosChartsOptions from 'containers/ScenariosChartsOptions/ScenariosChartsOptions';
import {
  SCENARIOS_ACCUMULATIVE_BENEFITS_COMPARISON_CHART,
  SCENARIO_STACKED_BENEFIT_CHART,
} from 'routes/Dashboard/Dashboards/helpers/templates';
import useScenariosChartsOptions from 'containers/ScenariosChartsOptions/useScenariosChartsOptions';
import ChartWidget from 'routes/Dashboard/Dashboards/ChartWidget';
import { FORECAST_CHART_PAGE } from 'constants/filters/pages';
import useUpsellPage from 'hooks/useUpsellPage';
import { PLAN_OF_RECORD_ID, PLAN_OF_RECORD_TITLE } from 'constants/common';
import { spacing } from 'design-system/theme';

const POR_LABEL = { por: PLAN_OF_RECORD_TITLE };

/**
 * Creates widgets based on the selected roadmap versions.
 *
 * @returns
 */
const createWidgets = (projectedField, stackedByField, timeBucketMode, dateInterval, labelsMap) => {
  const comparisonChart = {
    ...SCENARIOS_ACCUMULATIVE_BENEFITS_COMPARISON_CHART,
    props: {
      roadmapVersionIds: [PLAN_OF_RECORD_ID],
      projectedField,
      timeBucketMode,
      dateInterval,
      labelsMap,
      defaultChartTitle: 'Accumulative Benefits',
    },
  };

  const planOfRecordStackedChartWidget = {
    ...SCENARIO_STACKED_BENEFIT_CHART,
    props: {
      roadmapVersionId: PLAN_OF_RECORD_ID,
      chartTitle: 'Benefit Breakdown',
      dateInterval,
      projectedField,
      timeBucketMode,
      stackedByField,
    },
  };

  return [comparisonChart, planOfRecordStackedChartWidget];
};

const ForecastChart = () => {
  const { projectedOptionSelected, stackedByOptionSelected, timeBucketOptionSelected, dateInterval } =
    useScenariosChartsOptions();

  const UpsellForecastChart = useUpsellPage(FORECAST_CHART_PAGE);

  const widgets = useMemo(() => {
    return createWidgets(
      projectedOptionSelected?.key,
      stackedByOptionSelected?.key,
      timeBucketOptionSelected?.key,
      dateInterval,
      POR_LABEL,
    );
  }, [projectedOptionSelected, stackedByOptionSelected, timeBucketOptionSelected, dateInterval]);

  if (UpsellForecastChart) return <UpsellForecastChart />;

  return (
    <Wrapper>
      <BaseLayout>
        <ScenariosChartsOptions showSelectedVersionsBadge={false} />
        <ChartsContainer>
          {widgets.map(widget => (
            <ChartWidget widget={widget} inEditMode={false} />
          ))}
        </ChartsContainer>
      </BaseLayout>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  // padding bottom needed while this page has only 1 chart to doesn't cut the module menu dropdown
  padding-bottom: ${spacing(6.25)}px;
`;

const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${spacing(2.25)}px;
`;

export default ForecastChart;
