import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import theme, { spacing } from 'design-system/theme';
import HeaderBreadcrumbTitles from 'design-system/organisms/HeaderBreadcrumbTitles/index';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import SettingsIcon from '@material-ui/icons/Settings';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import useCycleDeliverableLightbox from 'containers/IdeasCycleDeliverableList/hooks/useCycleDeliverableLightbox';

import { getCycleDeliverableById } from 'store/cycleDeliverables/selectors';

const SEPARATOR = '\\';
const BACK = 'Back';

const CycleDeliverableLightboxHeader = ({
  cycleDeliverable,
  onChangeTitle,
  onClose,
  showSettingsContent,
  setShowSettingsContent,
  canUpdateCycleDeliverable,
}) => {
  const parentCycleDeliverable = useSelector(state => getCycleDeliverableById(state, cycleDeliverable?.parent_id));
  const { openCycleDeliverableLightbox } = useCycleDeliverableLightbox();

  const openParentCycleDeliverable = useCallback(
    () => openCycleDeliverableLightbox(parentCycleDeliverable?.id),
    [parentCycleDeliverable?.id],
  );

  const titleItems = useMemo(
    () => [
      ...(parentCycleDeliverable
        ? [
            {
              title: parentCycleDeliverable?.title,
              onClick: openParentCycleDeliverable,
              fontSize: theme.typography.fontSizeSmallLarge,
              showTooltip: true,
            },
          ]
        : []),
      {
        title: cycleDeliverable?.title,
        ...(parentCycleDeliverable ? { icon: SEPARATOR } : {}),
        fontSize: theme.typography.fontSizeSmallLarge,
        showTooltip: true,
        onChange: onChangeTitle,
        isEditable: canUpdateCycleDeliverable,
      },
    ],
    [cycleDeliverable, parentCycleDeliverable, openParentCycleDeliverable],
  );

  const renderLeftWrapper = () => {
    return showSettingsContent ? (
      <BackWrapper onClick={() => setShowSettingsContent(false)}>
        <ChevronLeftIcon fontSize="small" />
        <BackText>{BACK}</BackText>
      </BackWrapper>
    ) : (
      <StyledHeaderBreadcrumbTitles items={titleItems} showTooltip />
    );
  };

  const renderRightWrapper = () => {
    return showSettingsContent ? null : (
      <StyledButtonIcon onClick={() => setShowSettingsContent(true)}>
        <SettingsIcon fontSize="small" />
      </StyledButtonIcon>
    );
  };

  return (
    <HeaderWrapper>
      <LeftWrapper>{renderLeftWrapper()}</LeftWrapper>
      <RightWrapper onClose={onClose}>{renderRightWrapper()}</RightWrapper>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-height: 32px;
  padding-right: ${spacing(4)}px;
`;

const LeftWrapper = styled.div`
  flex: 1 1 auto;
`;

const RightWrapper = styled.div`
  flex: 0 0 auto;
`;

const StyledHeaderBreadcrumbTitles = styled(HeaderBreadcrumbTitles)`
  &&&& > div > div {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const BackWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BackText = styled.div`
  &:hover {
    text-decoration: underline;
  }
`;

const StyledButtonIcon = styled(ButtonIcon)`
  && {
    padding: ${spacing()}px;
  }
`;

export default CycleDeliverableLightboxHeader;
