import { OBJECTIVE_KEY, OBJECTIVE_LEVEL } from 'constants/objectives';

const computeParentId = okr => {
  return okr.parent_id != null ? okr.parent_id : `${OBJECTIVE_KEY}-${okr.objective_id}`;
};

const processOkrsToMetadataTreeDropdownItems = (okrs = []) => {
  return okrs.reduce((acc, okr) => {
    const isObjective = okr.level === OBJECTIVE_LEVEL;

    const okrUniqueId = isObjective ? `${OBJECTIVE_KEY}-${okr.id}` : okr.id;

    const uniqueParentId = isObjective ? null : computeParentId(okr);

    const processedOkr = {
      data: okr,
      parent_id: uniqueParentId,
      id: okrUniqueId,
      title: okr.title,
    };

    return [...acc, processedOkr, ...processOkrsToMetadataTreeDropdownItems(okr.keyResults)];
  }, []);
};

const makeGetItemsForProductsMetadataTreeDropdownCellEditor = (filteredObjectives, selectedLevel) => params => {
  const dataObjectiveId = params.data.objective?.id;

  const dataKeyResult1Id = params.data.keyResult1?.id;

  const dataObjective = dataObjectiveId ? filteredObjectives.find(o => o.id === dataObjectiveId) : null;
  const dataKeyResult1 = dataKeyResult1Id ? dataObjective?.keyResults?.find(kr => kr.id === dataKeyResult1Id) : null;

  // For cases where an L1 is selected
  const objectiveToProcess = dataObjective ? [dataObjective] : filteredObjectives;

  const processedOkrs = processOkrsToMetadataTreeDropdownItems(
    dataKeyResult1 && selectedLevel > dataKeyResult1.level
      ? [
          {
            ...dataObjective,
            keyResults: [dataKeyResult1],
          },
        ]
      : objectiveToProcess,
  );

  return processedOkrs.filter(okr => okr.data.level <= selectedLevel);
};

export default makeGetItemsForProductsMetadataTreeDropdownCellEditor;
