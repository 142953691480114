// This file is for Swimlane

import getSystemFieldName from 'utils/getSystemFieldName';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

export const COLOR_BY_OPTIONS = (systemFields, hasKeyResults, hasProducts, canView, hasKeyResults2) => [
  { key: 'health', title: 'Health' },
  ...(canView && canView(PERMISSION_RESOURCES.roadmapCorp)
    ? [{ key: 'roadmapCorp', title: getSystemFieldName('roadmapCorp', systemFields) }]
    : []),
  { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
  ...(hasProducts ? [{ key: 'product1', title: getSystemFieldName('product1', systemFields) }] : []),
  ...(canView && canView(PERMISSION_RESOURCES.objectiveCorp)
    ? [{ key: 'objectiveCorp', title: getSystemFieldName('objectiveCorp', systemFields) }]
    : []),
  { key: 'objective', title: getSystemFieldName('objective', systemFields) },
  ...(hasKeyResults ? [{ key: 'keyResult1', title: getSystemFieldName('keyResult1', systemFields) }] : []),
  ...(hasKeyResults2 ? [{ key: 'keyResult2', title: getSystemFieldName('keyResult2', systemFields) }] : []),
  { key: 'idea', title: getSystemFieldName('idea', systemFields) },
  { key: 'theme', title: getSystemFieldName('theme', systemFields) },
  { key: 'category', title: getSystemFieldName('category', systemFields) },
];

export const DEFAULT_COLOR_BY_OPTION = systemFields => COLOR_BY_OPTIONS(systemFields).find(g => g.key === 'roadmap');

export const ZOOM_OPTIONS = [
  { id: 'day', title: 'Daily' },
  { id: 'week', title: 'Weekly' },
  { id: 'month', title: 'Monthly' },
  { id: 'quarter', title: 'Quarter' },
];

export const DEFAULT_ZOOM_OPTION = ZOOM_OPTIONS.find(g => g.id === 'week');

export const SWIMLANE_TYPE_OPTIONS = (systemFields, hasKeyResults, hasProducts) => [
  { key: 'objective', title: getSystemFieldName('objective', systemFields) },
  ...(hasKeyResults ? [{ key: 'keyResult1', title: getSystemFieldName('keyResult1', systemFields) }] : []),
  { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
  ...(hasProducts ? [{ key: 'product1', title: getSystemFieldName('product1', systemFields) }] : []),
  { key: 'theme', title: getSystemFieldName('theme', systemFields) },
  { key: 'category', title: getSystemFieldName('category', systemFields) },
  { key: 'timeframe', title: getSystemFieldName('timeframe', systemFields) },
  { key: 'priority', title: getSystemFieldName('priority', systemFields) },
];

export const GROUPLANE_TYPE_OPTIONS = (systemFields, hasKeyResults, hasProducts) => [
  { key: 'objective', field: 'objective_id', title: getSystemFieldName('objective', systemFields) },
  ...(hasKeyResults ? [{ key: 'keyResult1', title: getSystemFieldName('keyResult1', systemFields) }] : []),
  { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
  ...(hasProducts ? [{ key: 'product1', title: getSystemFieldName('product1', systemFields) }] : []),
  { key: 'theme', title: getSystemFieldName('theme', systemFields) },
  { key: 'category', title: getSystemFieldName('category', systemFields) },
  { key: 'timeframe', title: getSystemFieldName('timeframe', systemFields) },
  { key: 'priority', title: getSystemFieldName('priority', systemFields) },
];

export const DEFAULT_SWIMLANE_TYPE = systemFields => SWIMLANE_TYPE_OPTIONS(systemFields).find(o => o.key === 'roadmap');
export const DEFAULT_GROUPLANE_TYPE = (systemFields, defaultKey = 'objective') =>
  GROUPLANE_TYPE_OPTIONS(systemFields).find(o => o.key === defaultKey);
