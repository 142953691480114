import React, { useMemo } from 'react';
import { OWNER_USER, LEADER_USER } from '@dragonboat/permissions';

const componentHOC = Component => {
  return props => {
    const { currentUser } = props;

    const isAdmin = useMemo(() => [OWNER_USER, LEADER_USER].includes(currentUser.role_id), [currentUser.role_id]);

    return <Component {...props} isAdmin={isAdmin} />;
  };
};

export default componentHOC;
