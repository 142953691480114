/**
 * Parses the updated data based on the provided fields and changes.
 * @param {Object} fields - The fields configuration object.
 * @param {Array} changes - The array of changes to be parsed.
 * @returns {Array} - The parsed array of changes.
 */
const parseUpdatedData = (fields, changes) => {
  const result = changes.map(row => {
    const parsedRow = Object.entries(row).reduce((acc, [field, value]) => {
      const fieldConfig = fields[field];

      if (!fieldConfig) {
        return { ...acc, [field]: value };
      }

      const { getFieldId, getFieldKey } = fieldConfig;

      const fieldKey = getFieldKey ? getFieldKey(field) : field;

      return { ...acc, [fieldKey]: getFieldId ? getFieldId(value) : value };
    }, {});

    return parsedRow;
  });

  return result;
};

export { parseUpdatedData };
