import React from 'react';
import styled, { css } from 'styled-components';
import GridLayout, { WidthProvider } from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';

import useDeviceType from 'design-system/hooks/useDeviceType';
import { spacing } from 'design-system/theme';
import isNilOrEmpty from 'utils/isNilOrEmpty';
import { mapEntitiesToLayout } from 'utils/reactGridLayout';

const DEFAULT_COLS = 2;
const DEFAULT_ROW_HEIGHT = 100;

const FluidGridLayout = WidthProvider(GridLayout);

export default ({ items, layout, isDraggable = true, rowHeight = DEFAULT_ROW_HEIGHT, cols = DEFAULT_COLS, ...props }) => {
  const { isMobile } = useDeviceType();

  const itemsLayout = isNilOrEmpty(layout) ? mapEntitiesToLayout(items, cols) : layout.filter(Boolean);

  return (
    <Container>
      {isMobile ? (
        <MobileChartsWrapper>
          {/* On mobile we're just rendering the items statically */}
          {items.map(item => (
            <ItemWrapper key={item.i || item.id}>{item.content}</ItemWrapper>
          ))}
        </MobileChartsWrapper>
      ) : (
        <FluidGridLayout
          layout={itemsLayout}
          cols={cols}
          isResizable={false}
          isDraggable={isDraggable}
          rowHeight={rowHeight}
          margin={[spacing(2), spacing(2)]}
          containerPadding={[0, 0]}
          useCSSTransforms={false}
          {...props}
        >
          {items.map(item => (
            <ItemWrapper key={item.i || item.id} isDraggable={isDraggable}>
              {item.content}
            </ItemWrapper>
          ))}
        </FluidGridLayout>
      )}
    </Container>
  );
};

const Container = styled.div`
  .react-grid-item.react-grid-placeholder {
    background: ${({ theme }) => theme.palette.background.secondary};
    opacity: 1;
  }
`;

const ItemWrapper = styled.div`
  ${({ isDraggable }) =>
    isDraggable &&
    css`
      > div {
        cursor: grab;
      }
    `}
`;

const MobileChartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing(2)}px;
  margin-top: ${spacing(2)}px;
`;
