import { useMemo, useCallback } from 'react';

import GroupHeaderInnerRenderer from '../components/GroupHeaderInnerRenderer';

import { useSettingsGridColumns } from 'containers/Grids/SettingsGrid/hooks';
import { updatedColumnDef, colorColumnDef } from 'design-system/molecules/AgGridReact-New/columns';

import { CYCLE_KEY, DELIVERABLE_KEY } from 'constants/pdlc';
import useSystemFields from 'hooks/useSystemFields';
import getCycleDeliverableStatusColumnDef from 'routes/Settings/pdlc/helpers/getCycleDeliverableStatusColumnDef';
import useForceVisibleColumns from 'hooks/useForceVisibleColumns';

const TITLE_FIELD = 'title';
const CYCLE_LEVEL = 0;

const usePDLCGridColumns = ({
  cycleDeliverables = [],
  allowActions,
  allowCreateDeliverable,
  canUpdate,
  canDragRows,
  headerCheckboxSelection,
  addCycleDeliverableWithoutSave,
  selectionMode,
  setItemToDelete,
  forceVisibleFields = [],
  onTitleClick,
}) => {
  const [getSystemFieldName] = useSystemFields();
  const checkAddVisibility = useCallback(
    ({ data }) => {
      return allowCreateDeliverable && data?.level === CYCLE_LEVEL;
    },
    [allowCreateDeliverable],
  );

  const levelOneTitle = getSystemFieldName(CYCLE_KEY);
  const levelTwoTitle = getSystemFieldName(DELIVERABLE_KEY);

  const titleHeaderName = useMemo(
    () => [levelOneTitle, levelTwoTitle].filter(Boolean).join(' > '),
    [levelOneTitle, levelTwoTitle],
  );

  const groupColumnDef = useMemo(
    () => ({
      headerName: titleHeaderName,
      field: TITLE_FIELD,
      suppressMovable: true,
      editable: params => canUpdate(params.data),
      headerClass: 'first-field',
      cellClass: 'ag-title-cell',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: params => ({
        suppressCount: true,
        suppressDoubleClickExpand: true,
        innerRenderer: GroupHeaderInnerRenderer,
        handleAddWithoutSave: addCycleDeliverableWithoutSave,
        checkAddVisibility,
        ...(onTitleClick ? { onTitleClick: () => onTitleClick(params) } : {}),
      }),
      minWidth: 200,
      flex: 5,
    }),
    [titleHeaderName, canUpdate],
  );

  const settingsGridColumns = useSettingsGridColumns({
    allowActions,
    canUpdate,
    selectionMode,
    setItemToDelete,
    items: cycleDeliverables,
    canDragRows,
    headerCheckboxSelection,
  });

  const remainingPDLCColumnDefs = useMemo(
    () => [getCycleDeliverableStatusColumnDef(allowActions), colorColumnDef, updatedColumnDef],
    [allowActions],
  );

  const columnDefs = useMemo(
    () =>
      useForceVisibleColumns(
        [
          ...settingsGridColumns.beforeColumnDefs,
          ...[groupColumnDef],
          ...remainingPDLCColumnDefs,
          ...settingsGridColumns.afterColumnDefs,
        ],
        forceVisibleFields,
      ),
    [settingsGridColumns, groupColumnDef, remainingPDLCColumnDefs, forceVisibleFields],
  );

  const defaultColDef = useMemo(
    () => ({
      ...settingsGridColumns.defaultColDef,
      editable: params => canUpdate(params.data) && settingsGridColumns.defaultColDef.editable(params),
    }),
    [canUpdate, settingsGridColumns.defaultColDef],
  );

  return {
    columnDefs,
    defaultColDef,
  };
};

export default usePDLCGridColumns;
