import React from 'react';
import PropTypes, { string, bool, func } from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import theme from 'design-system/theme';

const ConfirmDialog = ({
  id,
  isOpen,
  onCancel,
  onConfirm,
  text,
  title,
  confirmText,
  maxWidth,
  width,
  disableActions,
  extraContent,
  contentComponent = 'p',
  showCancel = true,
  disableConfirm,
  showCloseIcon = false,
  showActions = true,
  showContent = true,
  showTitle = true,
  customActions = undefined,
}) => {
  return (
    <DialogContainer open={!!isOpen} id={id || 'confirm-dialog'} maxWidth={maxWidth} width={width}>
      {showTitle && (
        <DialogTitle>
          {showCloseIcon ? (
            <TitleContent>
              <StyledTitle>{title || 'Are you sure?'}</StyledTitle>
              <IconButton onClick={onCancel}>
                <CloseIcon color="inherit" fontSize="small" />
              </IconButton>
            </TitleContent>
          ) : (
            <div>{title || 'Are you sure?'}</div>
          )}
        </DialogTitle>
      )}
      {showContent && (
        <DialogContent>
          <DialogContentText
            style={{
              whiteSpace: 'pre-wrap',
            }}
            component={contentComponent}
          >
            {text || ''}
          </DialogContentText>
          {extraContent}
        </DialogContent>
      )}
      {!showActions && customActions ? customActions : null}
      {showActions && (
        <StyledDialogActions>
          <Grid container>
            <Grid item xs={9}>
              {showCancel && (
                <Button id="cancel-button" disabled={disableActions} onClick={onCancel}>
                  Cancel
                </Button>
              )}
            </Grid>
            <Grid item xs={3}>
              <div style={{ justifyContent: 'flex-end', textAlign: 'right' }}>
                <Button id="confirm-button" disabled={disableActions || disableConfirm} onClick={onConfirm} color="primary">
                  {confirmText || 'Confirm'}
                </Button>
              </div>
            </Grid>
          </Grid>
        </StyledDialogActions>
      )}
    </DialogContainer>
  );
};

ConfirmDialog.propTypes = {
  title: string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isOpen: bool,
  onConfirm: func,
  onCancel: func,
};

export default ConfirmDialog;

const DialogContainer = styled(Dialog)`
  &&&& {
    z-index: ${theme.zIndex.confirmDialog};
  }
`;

const TitleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTitle = styled.div`
  margin-right: 10px;
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 0 8px;
`;
