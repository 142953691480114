import React from 'react';
import styled, { css } from 'styled-components';

import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';

const Heading = ({ title, subTitle, ...props }) => (
  <Container {...props}>
    {title ? <StyledText variant="h2">{title}</StyledText> : null}
    {subTitle ? <StyledText withMarginTop>{subTitle}</StyledText> : null}
  </Container>
);

const Container = styled.div``;

const StyledText = styled(Text)`
  line-height: ${({ theme }) => theme.typography.lineHeightSmallLargeRem}rem;

  ${({ withMarginTop }) =>
    withMarginTop &&
    css`
      margin-top: ${spacing(0.5)}px;
    `}
`;

export default Heading;
