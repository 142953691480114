import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

import invertedTextColor from 'design-system/utils/invertedTextColor';

import TableHeaderTitle from './TableHeaderTitle';

export default React.memo(({ children, ...otherProps }) => {
  return (
    <Wrapper {...otherProps}>
      <HeadItem
        style={{
          color: otherProps.color ? invertedTextColor(otherProps.color, true) : null,
          minHeight: otherProps.minHeight,
        }}
        vertical={otherProps.vertical}
      >
        {children}
      </HeadItem>
    </Wrapper>
  );
});

const Wrapper = withTheme()(styled.th`
  &&&& {
    padding: 0 10px 10px 15px;
    justify-content: center;
    align-items: center;
    line-height: 24px;
    color: #6b6b6b;
    background: ${({ theme }) => theme.palette.background.alabaster};

    ${({ verticalRowHeader, vertical }) => {
      if (!vertical)
        return `
      ${TableHeaderTitle} {
        line-height: 1.3;
      }
    `;
      // if (verticalRowHeader) {
      //   return `background: #666666!important;`;
      // }
      return `
        background: #fff !important;
      `;
    }}

    ${props =>
      props.color
        ? `
      box-shadow: inset 0px 4px 0px 0px ${props.color};
    } `
        : ''}

    ${({ left }) =>
      left &&
      `
      min-height: 20px;
      z-index: 111;
    `}

    ${({ verticalRowHeader, vertical }) =>
      !verticalRowHeader &&
      vertical &&
      `
      min-width: 180px;
      max-width: 25vw;
      padding: 16px 10px 10px 16px;
      p {
        white-space: break-spaces;
      }
    `};

    ${({ verticalRowHeader, topHeader }) =>
      verticalRowHeader &&
      !topHeader &&
      `
      padding: 15px 10px 10px 15px;

      > div {
        transform: rotate(180deg);
        min-height: 100px;
        min-width: 45px;
        max-width: 45px;
        writing-mode: vertical-rl;
        display: flex;
        flex-direction: column;
        
        ${TableHeaderTitle} {
          // color: #fff;
          font-weight: 400;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: right;
          max-height: 100px;
        }
      }
    `}

    ${({ borderLeftColor, theme }) =>
      borderLeftColor &&
      `
      box-shadow: inset 4px 0px 0px 0px ${borderLeftColor}, inset -1px 0 0 ${theme.palette.border.mercury};
    `}
  }
`);

const HeadItem = styled.div`
  padding: 0 8px;
  width: auto;
  text-align: left;
  color: #6b6b6b;
  font-size: 16px;
  border-radius: 2px;
  min-height: 40px;
  ${({ vertical }) =>
    vertical &&
    `
    min-width: 150px;
    background: transparent;
  `}
`;
