import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import { PERMISSION_RESOURCES } from '@dragonboat/permissions/lib/constants';

import Text from 'design-system/atoms/Text/index';


import CollapsePanel from 'components/CollapsePanel';
import FieldsMappingDisplay from 'components/FieldsMappingDisplay';
import UpdateFieldMappingDialog from './UpdateFieldMappingDialog';

import useSystemFields from 'hooks/useSystemFields';
import usePermissions from 'hooks/permissions/usePermissions';

import { getAvailableFieldsFromIntegration, updateFieldMapping } from 'features/OneStepIntegration/store';
import useOrgIntegrations from 'features/OneStepIntegration/hooks/useOrgIntegrations';

const mapFieldMappingAgainstDragonboatFields = (dragonboatFields, fieldMapping) => {
  return dragonboatFields.map(field => {
    return {
      ...field,
      ...(fieldMapping.find(data => data.key === field.key) || {}),
    };
  });
};

const FieldMapping = ({ integrationType, orgIntegration, oneStepAvailableIntegrations }) => {
  const dispatch = useDispatch();

  const { canUpdate } = usePermissions();

  const { fieldMapping: storedFieldMapping, dragonboatFields } = useOrgIntegrations(integrationType);

  const { mapping, entity } = storedFieldMapping || {};

  const canUpdateFieldMapping = canUpdate(PERMISSION_RESOURCES.integrationFieldMapping, {
    integrationConfig: oneStepAvailableIntegrations[integrationType],
  });

  const [getSystemFieldName] = useSystemFields();

  const [isFieldMappingDialogOpen, setIsFieldMappingDialogOpen] = useState(false);
  const [integrationFields, setIntegrationFields] = useState([]);
  const [fieldMapping, setFieldMapping] = useState([]);

  const shouldRenderFieldsMapping = fieldMapping && !!fieldMapping.length;

  const integrationNameLabel = oneStepAvailableIntegrations[integrationType]?.title;

  const integrationAvailableFields = useMemo(() => {
    if (fieldMapping) {
      return (integrationFields || []).filter(
        integrationField => !fieldMapping.find(mappedField => integrationField.key === mappedField?.integration?.key),
      );
    }

    return integrationFields;
  }, [integrationFields, fieldMapping]);

  const getIntegrationFields = async () => {
    const fields = await dispatch(getAvailableFieldsFromIntegration(integrationType, orgIntegration.id));

    setIntegrationFields(fields);
  };

  const handleUpdateMapping = async () => {
    await dispatch(updateFieldMapping(integrationType, orgIntegration.id, { entity, mapping: fieldMapping }));

    setIsFieldMappingDialogOpen(false);
  };

  const handleMappingChange = changedField => {
    const existingField = fieldMapping.some(field => field.key === changedField.key);

    let updatedMapping;

    if (existingField) {
      updatedMapping = fieldMapping.map(field => {
        if (field.key === changedField.key) {
          return changedField;
        }

        return field;
      });
    } else {
      updatedMapping = [...fieldMapping, changedField];
    }

    setFieldMapping(updatedMapping);
  };

  const handleCloseMappingDialog = () => {
    setIsFieldMappingDialogOpen(false);
    setFieldMapping(mapping);
  };

  useEffect(() => {
    if (orgIntegration) {
      getIntegrationFields();
    }
  }, [orgIntegration]);

  useEffect(() => {
    if (mapping && dragonboatFields) {
      const mappingToUse = mapFieldMappingAgainstDragonboatFields(dragonboatFields, mapping);

      setFieldMapping(mappingToUse);
    }
  }, [mapping, dragonboatFields]);

  return (
    <Wrapper>
      <CollapsePanel title={<Text variant="h4">Field Mapping Settings</Text>}>
        <PanelContent>
          {shouldRenderFieldsMapping && (
            <>
              <Text variant="h4">
                Mapping between fields in {integrationNameLabel} Account and Dragonboat {getSystemFieldName('customer')}:
              </Text>

              <FieldsMappingDisplay
                integrationType={integrationType}
                integrationName={integrationNameLabel}
                mapping={fieldMapping}
              />
            </>
          )}

          <ActionsContainer>
            <ActionItem>
              <Button color="primary" onClick={() => setIsFieldMappingDialogOpen(true)} disabled={!canUpdateFieldMapping}>
                {shouldRenderFieldsMapping ? 'Edit field mapping' : 'Add field mapping'}
              </Button>
            </ActionItem>
            {/* <ActionItem>
              <UpdateProjectsMappedFields
                integrationType={integrationType}
                orgIntegrationId={orgIntegration?.id}
                disabled={!userCanUpdateOrgIntegration} // TODO: USE PERMISSIONS
              />
            </ActionItem> */}
          </ActionsContainer>
        </PanelContent>
      </CollapsePanel>
      {isFieldMappingDialogOpen && (
        <UpdateFieldMappingDialog
          integrationNameLabel={integrationNameLabel}
          integrationAvailableFields={integrationAvailableFields}
          dragonboatAvailableFields={dragonboatFields}
          mapping={fieldMapping}
          onChange={handleMappingChange}
          onClose={handleCloseMappingDialog}
          onSave={handleUpdateMapping}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const PanelContent = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.text.lightGrey};
  padding-bottom: 16px;
`;

const ActionsContainer = styled.div`
  margin-top: 16px;
`;

const ActionItem = styled.div`
  margin-left: -8px;
`;

export default FieldMapping;
