import { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { omitBy } from 'lodash';
import { defaultTo, pipe, sum, values, keys, uniq } from 'ramda';

import sortEntities from 'utils/sortEntities';
import { DEFAULT_TYPE } from 'constants/allocation';
import { ALLOCATION_REPORT_PAGE } from 'src/constants/filters';
import { getUserFilters } from 'src/store/filters/selectors';
import compileFiltersBody from 'src/utils/filters/compileFiltersBody';
import useDeepEffect from 'hooks/useDeepEffect';
import usePageFilters from 'hooks/filters/usePageFilters';

import { calcScopeVariance } from 'store/projects/helpers/getProjectAllocationValues';
import { ENTITY_TO_COLLECTION, PRODUCT, KEY_RESULT, PRODUCT_2, KEY_RESULT_2, TIMEFRAMES } from 'constants/common';

const DURATION_IN_WEEK = 'week';
const SUM_BY_DURATION = 'duration';
const UNDEFINED_COLOR = '#eee';

const UNDEFINED_ENTITY = { id: null, title: 'Undefined', color: UNDEFINED_COLOR };

const sumValues = pipe(values, sum);
const defaultAsEmptyArray = defaultTo([]);
const defaultToZero = defaultTo(0);

export default (metadata, lsState = {}) => {
  const { pageFilters: filters, displayLayer } = usePageFilters(ALLOCATION_REPORT_PAGE);
  const userFilters = useSelector(state => getUserFilters(state));
  const hasBet = useSelector(state => state.organization.organization.has_bet);
  const filtersForApiSearch = compileFiltersBody(filters, userFilters, hasBet, ALLOCATION_REPORT_PAGE, displayLayer);

  const { dataType, sumBy, duration, hideArchivedData, highlightOverInvestedGoals } = lsState;

  const groupByApiOptions = {
    roadmap: 'roadmap_id',
    product1: 'product_1_id',
    product2: 'product_2_id',
    objective: 'objective_id',
    keyResult1: 'key_result_1_id',
    keyResult2: 'key_result_2_id',
    theme: 'theme_id',
    category: 'category_id',
    roadmapCorp: 'roadmap_corp_id',
    objectiveCorp: 'objective_corp_id',
  };

  const normalizeTypeKey = typeKey => {
    switch (typeKey) {
      case 'product1':
        return PRODUCT;
      case 'product2':
        return PRODUCT_2;
      case 'keyResult1':
        return KEY_RESULT;
      case 'keyResult2':
        return KEY_RESULT_2;
      default:
        return typeKey;
    }
  };

  const typeKey = dataType?.key || DEFAULT_TYPE;
  const sumByKey = sumBy?.key || SUM_BY_DURATION;
  const durationKey = duration?.key || DURATION_IN_WEEK;

  const fetchDataRequestBody = {
    projectsFilter: filtersForApiSearch,
    allocationQuery: {
      sumBy: sumByKey,
      allocationBy: groupByApiOptions[typeKey],
      displayLayer,
      durationIn: durationKey,
      hideArchived: hideArchivedData,
    },
  };
  const fetchDataStackedByTimeframeBody = {
    ...fetchDataRequestBody,
    allocationQuery: {
      ...fetchDataRequestBody.allocationQuery,
      stackBy: 'timeframe_id',
    },
  };

  const [loaded, setLoaded] = useState(false);

  const [data, setData] = useState(() => ({
    entities: [],
    originalData: {},
    planned: {},
    reported: {},
    completed: {},
    scopeVariance: {},
    overInvestedGoals: [],
  }));

  const _fetchData = async () => {
    try {
      const normalizedTypeKey = normalizeTypeKey(typeKey);
      const collectionName = ENTITY_TO_COLLECTION[normalizedTypeKey];
      const entitiesFromStore = defaultAsEmptyArray(metadata[collectionName]).sort(sortEntities);
      const entities = [
        // append undefined entity
        UNDEFINED_ENTITY,
        ...entitiesFromStore,
      ];
      const timeframesFromStore = defaultAsEmptyArray(metadata[TIMEFRAMES]).sort(sortEntities);
      const stackEntities = [UNDEFINED_ENTITY, ...timeframesFromStore];

      const requests = [
        axios.post(`/api/projects/allocation-report`, fetchDataRequestBody),
        axios.post(`/api/projects/allocation-report`, fetchDataStackedByTimeframeBody),
      ];

      if (highlightOverInvestedGoals) {
        requests.push(axios.post(`/api/v1/reports/outcome/allocation/over-invested`, fetchDataRequestBody));
      }

      const [allocationReportData, stackedAllocationReportData, overInvestedGoalsResponse = { data: [] }] = await Promise.all(
        requests,
      );

      const { data: responseBody } = allocationReportData;
      const { data: stackedAllocationResponseBody } = stackedAllocationReportData;
      const { data: overInvestedGoalsResponseBody } = overInvestedGoalsResponse;

      const shouldOmitItem = (val, key) => {
        if (key === 'null') {
          // Don't omit the undefined category values
          return false;
        }

        return !val || !entities.find(entity => Number(entity.id) === Number(key));
      };

      const planned = omitBy(responseBody.planned || {}, shouldOmitItem);
      const reported = omitBy(responseBody.reported || {}, shouldOmitItem);
      const completed = omitBy(responseBody.completed || {}, shouldOmitItem);
      const scopeVariance = omitBy(responseBody.scopeVariance || {}, shouldOmitItem);

      const target = uniq([...keys(planned), ...keys(reported), ...keys(completed)]).reduce(
        (values, entityId) => {
          const entity = entities.find(e => String(e.id) === entityId);

          return {
            amount: {
              ...values.amount,
              [entityId]: defaultToZero(entity?.target_allocation_amount),
            },
            percentage: {
              ...values.percentage,
              [entityId]: defaultToZero(entity?.target_allocation_percentage),
            },
          };
        },
        { amount: {}, percentage: {} },
      );

      return {
        originalData: responseBody,
        originalStackedData: stackedAllocationResponseBody,
        entities,
        stackEntities,
        planned,
        reported,
        completed,
        target,
        scopeVariance,
        totalScopeVariance: calcScopeVariance(sumValues(planned), sumValues(reported)),
        overInvestedGoals: defaultTo([], overInvestedGoalsResponseBody?.overInvested),
      };
    } catch (e) {
      console.error(e);
      return data;
    }
  };

  useDeepEffect(() => {
    setLoaded(false);

    _fetchData().then(allocationData => {
      setData(data => ({
        ...data,
        ...allocationData,
      }));
      setLoaded(true);
    });
  }, [dataType, sumBy, duration, displayLayer, userFilters, filters, hideArchivedData]);

  return [loaded, data];
};
