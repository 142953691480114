// External dependencies
import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';

import Checkbox from 'design-system/atoms/Checkbox/index';
import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';


import { ucFirst } from 'utils';
import DateRange from 'components/DateRange';

export default ({ isOpen, onCancel, onApply, lsState, updateLsState, colorByOptions, showColorLegend }) => {
  const {
    showLegend,
    showAllocation,
    showInactiveUsers,
    selectedColorBy,
    singleTimeScale,
    selectedTimeWindow,
    timeWindowStart,
    timeWindowEnd,
    showTooltip,
  } = lsState;

  return (
    <React.Fragment>
      <Dialog open={isOpen} maxWidth="md" fullWidth>
        <DialogTitle>Display Preferences</DialogTitle>
        <DialogContent>
          <PreferenceGroup>
            <MyFormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={singleTimeScale}
                  onChange={() => updateLsState({ singleTimeScale: !singleTimeScale })}
                />
              }
              label="Single time scale"
            />
          </PreferenceGroup>
          <PreferenceGroup>
            <FormLabel control="legend">Time window</FormLabel>
            <Preference row>
              <MyFormControlLabel
                control={
                  <Radio
                    checked={!selectedTimeWindow}
                    color="primary"
                    onChange={() => updateLsState({ selectedTimeWindow: null })}
                  />
                }
                label="Automatic"
                key="auto"
              />
              <MyFormControlLabel
                control={
                  <Radio
                    checked={selectedTimeWindow === 'manual'}
                    color="primary"
                    onChange={() => updateLsState({ selectedTimeWindow: 'manual' })}
                  />
                }
                label={
                  <DateRange
                    startDate={timeWindowStart}
                    endDate={timeWindowEnd}
                    onStartDateChange={value => updateLsState({ timeWindowStart: moment(value) })}
                    onEndDateChange={value => updateLsState({ timeWindowEnd: moment(value) })}
                    showDuration={false}
                    showEndDate
                    disableDuration
                    disableStartDate={!selectedTimeWindow}
                    disableEndDate={!selectedTimeWindow}
                    startLabel="From"
                    endLabel="To"
                  />
                }
                key="manual"
              />
            </Preference>
          </PreferenceGroup>
          <PreferenceGroup>
            <FormLabel control="legend">Color by</FormLabel>
            <FormControl fullWidth margin="dense">
              <Select
                value={selectedColorBy && selectedColorBy.key}
                label="Color by"
                onChange={e => {
                  const newColorBy = colorByOptions.find(o => o.key === e.target.value);
                  const stateToUpdate = { selectedColorBy: newColorBy };

                  if (newColorBy.key === 'idea') stateToUpdate.showLegend = false;

                  updateLsState(stateToUpdate);
                }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                  },
                }}
              >
                {colorByOptions &&
                  colorByOptions.map(colorBy => (
                    <StyledMenuItemDropdown key={colorBy.key} value={colorBy.key}>
                      {ucFirst(colorBy.title)}
                    </StyledMenuItemDropdown>
                  ))}
              </Select>
            </FormControl>
          </PreferenceGroup>
          <PreferenceGroup>
            <MyFormControlLabel
              control={
                <Checkbox color="primary" checked={showLegend} onChange={() => updateLsState({ showLegend: !showLegend })} />
              }
              label="Show color legend"
            />
          </PreferenceGroup>
          <ColorLegendContainer>{showLegend && showColorLegend(selectedColorBy)}</ColorLegendContainer>
          <PreferenceGroup>
            <MyFormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={showAllocation}
                  onChange={() => updateLsState({ showAllocation: !showAllocation })}
                />
              }
              label="Show owner allocation on gantt bar"
            />
          </PreferenceGroup>
          <PreferenceGroup>
            <MyFormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={showInactiveUsers}
                  onChange={() => updateLsState({ showInactiveUsers: !showInactiveUsers })}
                />
              }
              label="Show inactive users"
            />
          </PreferenceGroup>
          <PreferenceGroup>
            <MyFormControlLabel
              control={
                <Checkbox color="primary" checked={showTooltip} onChange={() => updateLsState({ showTooltip: !showTooltip })} />
              }
              label="Show Tooltip"
            />
          </PreferenceGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onApply} color="primary">
            Apply
          </Button>
          <Button onClick={onCancel}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const PreferenceGroup = styled.div`
  margin: 12px 0;
`;

const ColorLegendContainer = styled.div`
  width: 100%;
  display: block;
`;

const Preference = styled(FormGroup)`
  &&&& {
    margin: 0 6px;
  }
`;

const MyFormControlLabel = styled(FormControlLabel)`
  &&& {
    min-width: 250px;
  }
`;
