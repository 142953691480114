import React, { Fragment } from 'react';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import uniq from 'lodash/uniq';
import xor from 'lodash/xor';
import { flatten } from 'ramda';

import MultiSelect from 'design-system/atoms/MultiSelect/index';
import Autocomplete from 'design-system/atoms/Autocomplete/index';

import { ucFirst } from 'utils';
import { DEFAULT_SETTINGS_NAME } from 'config';
import getSystemFieldName from 'utils/getSystemFieldName';
import normalizeArray from 'utils/normalizeArray';

import {
  getAdditionalObjectivesPreferences,
  getAdditionalRoadmapsPreferences,
  getAdditionalTimeframesPreferences,
  getAdditionalCategoriesPreferences,
  getAdditionalThemesPreferences,
  getTeamsPreferences,
  getPersonasPreferences,
  getLifecyclesPreferences,
} from 'utils/metadata';

const additionalMetadataCommonProps = {
  hide: () => false,
};

const PreferencesDialog = ({
  isOpen,
  onOpen,
  onApply,
  onCancel,
  displayPreferences,
  onChangeDisplayPref,
  systemFields,
  organizationIntegrations,
  selectedTags,
  updateState,
  hasInitiatives,
  hasBets,
  customFields,
  hasKeyResults,
  hasKeyResults2,
  hasProducts,
  hasProducts2,
  hasTeams2,
  hasPersonas,
  hasLifecycles,
  shouldShowPreCalculations,
  hasMetadataMultiSelect = true,
  hasMultiLevelPortfolioMetadata = false,
}) => {
  const additionalMetadataPreferences = flatten([
    getAdditionalRoadmapsPreferences(hasProducts, hasProducts2, getSystemFieldName, systemFields, additionalMetadataCommonProps),
    getAdditionalObjectivesPreferences(
      hasKeyResults,
      hasKeyResults2,
      getSystemFieldName,
      systemFields,
      additionalMetadataCommonProps,
    ),
    getAdditionalTimeframesPreferences(
      hasMultiLevelPortfolioMetadata,
      getSystemFieldName,
      systemFields,
      additionalMetadataCommonProps,
    ),
    getAdditionalCategoriesPreferences(getSystemFieldName, systemFields, additionalMetadataCommonProps),
    getAdditionalThemesPreferences(getSystemFieldName, systemFields, additionalMetadataCommonProps),
    getTeamsPreferences(hasTeams2, getSystemFieldName, systemFields, additionalMetadataCommonProps),
  ]);
  let preferencesToRender = [
    { key: 'planningStage', label: 'Planning Stage', fitContent: true, hide: () => false, default: false },
    { key: 'showBet', label: getSystemFieldName('bet', systemFields), fitContent: true, hide: () => !hasBets },
    {
      key: 'showInitiative',
      label: getSystemFieldName('initiative', systemFields),
      fitContent: true,
      hide: () => !hasInitiatives,
    },
    { key: 'votes', label: 'Votes', fitContent: true, hide: () => false, default: false },
    { key: 'progressCount', label: 'Progress issue count', fitContent: true, hide: () => false },
    {
      key: 'integrationKey',
      label: 'Integration Key',
      fitContent: true,
      hide: () => !organizationIntegrations || organizationIntegrations.length === 0,
    },
    { key: 'planned_moar', label: 'Planned MoAR', fitContent: true, hide: () => false },
    { key: 'riceScore', label: 'RICE', fitContent: true, hide: () => false },
    { key: 'effort_score', label: 'Effort', fitContent: true, hide: () => false },
    { key: 'tags', label: 'Tags', fitContent: true, hide: () => false },
    { key: 'customers', label: 'Customers', fitContent: true, hide: () => false },
    { key: 'estimated_start_date', label: 'Target start date', fitContent: true, hide: () => false },
    { key: 'deadline', label: 'Target end date', fitContent: true, hide: () => false },
    { key: 'product1', label: getSystemFieldName('product1', systemFields), fitContent: true, hide: () => false },
    ...(hasPersonas ? getPersonasPreferences(getSystemFieldName, systemFields, { hide: () => false }) : []),
    ...(hasLifecycles ? getLifecyclesPreferences(getSystemFieldName, systemFields, { hide: () => false }) : []),
    { key: 'customersCount', label: '# of Customers', fitContent: true, hide: () => false },
    { key: 'customerRequestsCount', label: 'Total requests', fitContent: false, hide: () => false },
    ...(customFields || []).map(cf => ({
      key: cf.key,
      label: `(cf) ${cf.title}`,
      fitContent: true,
      hide: () => false,
    })),
  ];

  if (hasKeyResults) {
    preferencesToRender = [
      ...preferencesToRender,
      { key: 'keyResult1', label: getSystemFieldName('keyResult1', systemFields), fitContent: true, hide: () => false },
    ];
  }

  if (hasKeyResults2) {
    preferencesToRender = [
      ...preferencesToRender,
      { key: 'keyResult2', label: getSystemFieldName('keyResult2', systemFields), fitContent: true, hide: () => false },
    ];
  }

  if (shouldShowPreCalculations) {
    preferencesToRender = [
      ...preferencesToRender,
      { key: 'totalRevenue', label: 'Total value', fitContent: true, hide: () => false },
      { key: 'activeRevenue', label: 'Active value', fitContent: true, hide: () => false },
      { key: 'inactiveRevenue', label: 'Inactive value', fitContent: true, hide: () => false },
    ];
  }

  if (hasMetadataMultiSelect) {
    preferencesToRender = [...preferencesToRender, ...additionalMetadataPreferences];
  }

  const numberCustomFields = React.useMemo(() => customFields.filter(cf => cf.field_type === 'Number'), [customFields]);
  const sumUpSuggestions = [
    { value: null, label: 'None' },
    { value: 'effort_score', label: 'Effort' },
    { value: 'business_value', label: 'Benefit' },
    ...numberCustomFields.map(cf => ({ value: cf.key, label: cf.title })),
  ];
  const sumUpValueSuggestion = sumUpSuggestions.find(f => f.value === displayPreferences.sumField);

  let optionsToDisplayOnCard = [
    'health',
    'roadmap',
    'timeframe',
    'phase',
    'objective',
    'theme',
    'category',
    'priority',
    'owner',
    'progress',
    'team',
  ]
    .map(o => ({
      key: o,
      label: Object.keys(DEFAULT_SETTINGS_NAME).includes(o) ? getSystemFieldName(o, systemFields) : ucFirst(o),
    }))
    .concat(...preferencesToRender.filter(o => !o.hide()));

  const normalizedOptionsToDisplayOnCard = {
    byKey: normalizeArray(optionsToDisplayOnCard, 'key'),
    byLabel: normalizeArray(optionsToDisplayOnCard, 'label'),
  };
  const optionsToDisplayOnCardValue = [
    ...selectedTags.map(o => normalizedOptionsToDisplayOnCard.byKey[o]?.label),
    ...preferencesToRender
      .map(p => p.key)
      .filter(o => !!displayPreferences[o])
      .map(o => preferencesToRender.find(p => p.key === o).label),
  ];

  optionsToDisplayOnCard = optionsToDisplayOnCard.filter(o => !optionsToDisplayOnCardValue.includes(o.label));

  const _makeOnChangeDisplayPref = key => {
    return () => {
      let value;

      if (Object.keys(displayPreferences).includes(key)) value = !displayPreferences[key];
      else value = !preferencesToRender.find(obj => obj.key === key).default;
      onChangeDisplayPref(key, value);
    };
  };

  return (
    <Fragment>
      <Dialog open={isOpen} maxWidth="md" fullWidth>
        <DialogTitle>Display Preferences</DialogTitle>
        <DialogContent>
          <PreferenceGroup>
            <FormControl fullWidth margin="dense">
              <MultiSelect
                options={optionsToDisplayOnCard}
                optionsMapper={{ label: 'label', value: 'key' }}
                label="Display these values on card"
                placeholder="Select fields to display"
                onChange={newOptions => {
                  const newSelectedTags = newOptions
                    .map(t => (normalizedOptionsToDisplayOnCard.byLabel[t] ? normalizedOptionsToDisplayOnCard.byLabel[t].key : t))
                    .filter(t => !preferencesToRender.find(obj => obj.label === t || obj.key === t));

                  const [changedOption] = xor(newOptions, optionsToDisplayOnCardValue);
                  const preference = preferencesToRender.find(obj => obj.label === changedOption || obj.key === changedOption);

                  if (changedOption && preference) {
                    _makeOnChangeDisplayPref(preference.key)();
                    return;
                  }

                  updateState({ selectedTags: uniq(newSelectedTags) });
                }}
                value={uniq(optionsToDisplayOnCardValue)}
                hideCreateOption
                hideIcon
              />
            </FormControl>
          </PreferenceGroup>
          <PreferenceGroup>
            <FormControl fullWidth margin="dense">
              <Autocomplete
                label="Sum up the value of"
                name="sumUpValueOf"
                suggestions={sumUpSuggestions}
                value={sumUpValueSuggestion ? sumUpValueSuggestion.label : sumUpSuggestions[0].label}
                onValueChange={option => {
                  updateState({ displayPreferences: { ...displayPreferences, sumField: option } });
                }}
                hideIcon
              />
            </FormControl>
          </PreferenceGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={onApply} color="primary">
            Apply
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const styles = theme => ({
  checkboxGroupRow: {
    margin: '11px 6px',
  },
  checkbox: {
    width: 36,
    height: 36,
  },
  checkboxLabel: {
    marginRight: 32,
  },
});

export default withStyles(styles, { name: 'PreferencesDialog' })(PreferencesDialog);

const PreferenceGroup = styled.div`
  margin: 0 0 26px 0;
`;
