import React, { useCallback } from 'react';
import HistoryIcon from '@material-ui/icons/History';
import Tooltip from '@material-ui/core/Tooltip';

import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';
import { useDispatch, useSelector } from 'react-redux';
import { openViewHistorySnapsDialog } from '../store/actions';
import { fetchRoadmapHistorySnapshotsForUserView } from '../store/thunks';
import { selectRoadmapHistorySnapshotsByUserView } from '../store/selectors';

const useViewRoadmapHistorySnaps = afterClickFn => {
  const dispatch = useDispatch();
  const permissions = usePermissions();

  const roadmapHistorySnapshotsByUserView = useSelector(selectRoadmapHistorySnapshotsByUserView);

  const viewHasRoadmapHistorySnaps = useCallback(
    view => {
      const hasViewLoadedRoadmapHistorySnapshots = !!view?.roadmapHistorySnapshots?.length;

      const hasHistorySnapshotsForView = !!(roadmapHistorySnapshotsByUserView[view.id] || []).length;

      const hasHistorySnapshots = hasViewLoadedRoadmapHistorySnapshots || hasHistorySnapshotsForView;

      return permissions.canView(PERMISSION_FEATURES.roadmapHistory) && hasHistorySnapshots;
    },
    [permissions, roadmapHistorySnapshotsByUserView],
  );

  return {
    viewHasRoadmapHistorySnaps,
    historySnapsButtonProps: {
      renderIcon: () => (
        <Tooltip title="View history snapshots">
          <HistoryIcon />
        </Tooltip>
      ),
      onClick: view => {
        dispatch(fetchRoadmapHistorySnapshotsForUserView(view.id));
        dispatch(openViewHistorySnapsDialog(view.id));

        if (afterClickFn) {
          afterClickFn();
        }
      },
      visible: viewHasRoadmapHistorySnaps,
    },
  };
};

export default useViewRoadmapHistorySnaps;
