import React from 'react';
import { useSelector } from 'react-redux';

import { getProjectsGroupedBy } from 'store/dashboards/selectors';
import { getNormalizedRoadmaps } from 'store/roadmaps/selectors';
import { UNDEFINED_LABEL } from 'constants/common';
import { DEFAULT_UNDEFINED_COLOR } from 'constants/charts';
import { INITIATIVE_LAYER } from 'src/store/projects/constants';
import usePieChart from 'hooks/charts/usePieChart';

const GROUP_BY_KEY = 'roadmap';

const componentHOC = Component => {
  return props => {
    const selectedGroup = { key: GROUP_BY_KEY };
    const { roadmaps, data } = useSelector(state => ({
      roadmaps: getNormalizedRoadmaps(state),
      data: getProjectsGroupedBy(state, props.pageId, { selectedGroupByLvl1: selectedGroup }, INITIATIVE_LAYER),
    }));

    const _makeDataToChart = React.useCallback(() => {
      const _sumPlanned = (sum, p) => {
        sum += p.plannedAllocation || 0;
        return sum;
      };

      const sumData = Object.values(data).reduce((acc, cur) => {
        const _val = cur.reduce(_sumPlanned, 0);

        return [...acc, _val];
      }, []);
      const labels = Object.keys(data)
        .map(r => roadmaps[r]?.title || UNDEFINED_LABEL)
        .filter((_, index) => sumData[index] > 0);
      const colors = Object.keys(data)
        .map(r => roadmaps[r]?.color || DEFAULT_UNDEFINED_COLOR)
        .filter((_, index) => sumData[index] > 0);
      const datasets = [
        {
          label: '',
          data: sumData.filter(v => v > 0),
          backgroundColor: colors,
          hoverOffset: 4,
        },
      ];

      return { labels, datasets };
    }, [data, roadmaps]);

    const chart = usePieChart({ generateData: _makeDataToChart });

    return <Component {...props} chart={chart} />;
  };
};

export default componentHOC;
