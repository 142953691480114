import axios from 'axios';

import { createThunk } from 'utils/store/thunk';
import { FETCH_REQUEST_HISTORY, FETCH_REQUEST_HISTORY_NEXT } from './types';

export const fetchRequestHistory = requestId =>
  createThunk(
    FETCH_REQUEST_HISTORY,
    axios.get(`/api/customer-requests/${requestId}/history`).then(res => res.data),
    { requestId },
  );

export const fetchRequestHistoryNextPage = (requestId, nextPageUrl) =>
  createThunk(
    FETCH_REQUEST_HISTORY_NEXT,
    axios.get(nextPageUrl).then(res => res.data),
    { requestId },
  );
