import React from 'react';
import styled from 'styled-components';

import FluidTextField from 'design-system/organisms/FluidTextField/index';
import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';

import FluidMetadataAutocompleteField from 'containers/FluidMetadataAutocompleteField';
import LoadableUsersAutocomplete from 'containers/LoadableUsersAutocomplete';
import RoadmapsTreeDropdown from 'containers/ProjectLightBox/Form/Components/RoadmapsTreeDropdown';
import useSystemFields from 'hooks/useSystemFields';
import { ENTER_TITLE_PLACEHOLDER } from 'constants/common';

const ProjectForm = ({ formValues, objectTitle, roadmapOptions, ownerOptions, handleFormValueChange }) => {
  const [getSystemFieldName] = useSystemFields();

  const selectedOwnerName = ownerOptions?.find(opt => opt.id === formValues?.owner)?.name;

  const selectedRoadmap =
    [formValues?.roadmap?.roadmapTitle, formValues?.roadmap?.product1Title, formValues?.roadmap?.product2Title] || [];

  return (
    <>
      <FluidTextField
        key="title"
        label="Title"
        value={formValues?.title || objectTitle}
        onChange={val => handleFormValueChange('title', val)}
        placeholder={ENTER_TITLE_PLACEHOLDER}
        readingPlaceholder={ENTER_TITLE_PLACEHOLDER}
        format="row"
      />
      <RoadmapsTreeDropdown
        name="roadmap"
        key="roadmaps"
        selectedProject={{}}
        value={selectedRoadmap}
        label={getSystemFieldName('roadmap')}
        metadata="roadmaps"
        onChange={val => handleFormValueChange('roadmap', val)}
        options={roadmapOptions}
        roadmapsKey="roadmaps"
        placeholder={`Select a ${getSystemFieldName('roadmap')}`}
        format="row"
      />
      <FluidMetadataAutocompleteField
        name="Owner"
        key="users"
        value={selectedOwnerName}
        label={getSystemFieldName('owner')}
        metadata="users"
        onChange={val => handleFormValueChange('owner', val)}
        autocompleteComponent={LoadableUsersAutocomplete}
        autocompleteProps={{
          suggestions: ownerOptions,
          placeholder: `Select an ${getSystemFieldName('owner')}`,
        }}
        format="row"
      />
      <StyledFluidRemirrorWYSIWYGEditor
        name="description"
        key="description"
        label="Description"
        value={formValues?.details}
        content={formValues?.details}
        onChange={val => handleFormValueChange('details', val)}
        placeholder="Write a description..."
        disableToolbar
        smallInput
        format="row"
      />
    </>
  );
};

export default ProjectForm;

const StyledFluidRemirrorWYSIWYGEditor = styled(FluidRemirrorWYSIWYGEditor)`
  && .remirror-is-empty:before {
    font-style: normal;
  }
`;
