import Axios from 'axios';
import isFunction from 'lodash/isFunction';
import throwRequestError from '../throwRequestError';

export default (ACTION_TYPE, page, stateKey, viewState) => {
  return () => {
    return async (dispatch, getState) => {
      let stateToSave = viewState;
      const state = getState();
      const { grids, login } = state;
      const userView = grids.get(page) && grids.get(page).get('userView') ? grids.get(page).get('userView').toJS() : null;

      if (!stateToSave) {
        if (isFunction(stateKey)) {
          stateToSave = { ...stateKey(state) };
        } else {
          stateToSave = { [stateKey]: state[stateKey] };
        }
      }
      const user = login.currentUser;
      const data = {
        state: stateToSave,
        page,
        user_id: user.id,
        path: window.location.pathname.substring(1),
      };
      const url = !userView ? '/api/userViewsOld/' : `/api/userViewsOld/update/${userView.id}`;

      const payload = Axios.post(url, data).catch(throwRequestError);

      dispatch({
        type: ACTION_TYPE,
        payload,
      });

      return payload;
    };
  };
};
