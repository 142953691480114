import { GLOBAL_FILTER } from 'constants/filters';
import { getPageFilters } from 'store/filters/selectors';

export default (state, selector, dataKey, overrideShowArchived, page = GLOBAL_FILTER) => {
  const filters = getPageFilters(state, page);
  let showArchived = filters && filters.showArchived && filters.showArchived.includes(dataKey);

  if (overrideShowArchived !== undefined) {
    showArchived = overrideShowArchived;
  }

  return selector(state, showArchived);
};
