import axios from 'axios';
import isObject from 'lodash/isObject';
import isNaN from 'lodash/isNaN';
import { difference, isNil, propOr, without, pipe } from 'ramda';

import throwRequestError from 'store/utils/throwRequestError';
import { CREATE_TAG_FULFILLED } from 'store/tags/types';
import { CREATE_CUSTOMER_FULFILLED } from 'store/customers/types';
import { createThunk } from 'utils/store/thunk';

import { updateProjectById } from './actions';
import {
  UPDATE_PROJECTS,
  IMPORT_PROJECTS,
  UPDATE_PROJECT_FULFILLED,
  UPDATE_PROJECT_ROW_ORDER,
  ADD_PROJECT_METRIC,
  REMOVE_PROJECT_METRIC,
  CREATE_AND_ADD_METRIC_TO_PROJECT,
  BULK_ADD_PROJECT_METRICS,
  BULK_REMOVE_PROJECT_METRICS,
  LOAD_PROJECTS_FROM_AUTOCOMPLETE,
  LOAD_PROJECTS_FROM_AUTOCOMPLETE_TITLE,
  TRANSFER_PROJECT_TO_OTHER_ACCOUNT,
  UPDATE_PROJECT_PERSONAS,
  UPDATE_PROJECT_LIFECYCLES,
  FETCH_PROJECT_WITH_DBQL,
  APPLY_FILTERS_FULFILLED,
} from './types';
import serializeProject from './helpers/serializeProject';
import { callAddMetricToProject, callCreateMetric } from './helpers/projectMetrics';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import { CHILDREN_FILTERS, GLOBAL_FILTER } from 'constants/filters';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from './constants';
import { getFiltersForProjectsSearch } from 'utils/projects/projectsUtils';
import { getSelectedRoadmapVersion } from 'store/roadmapVersions/selectors';
import getDisplayLayerAndPortfolioModeFromFilters from 'utils/filters/getDisplayLayerAndPortfolioModeFromFilters';
import { getPageFilters } from 'store/filters/selectors';

/**
 *
 * @param {} projectId1
 * @param {*} projectId2
 * @param {*} dataToUpdateMovingProject
 * @param {top|bottom} position - used to put projectId1 with an higher(top) or lower(bottom ) row_order than projectId2
 */
function switchProjectRowOrder(projectId1, projectId2, dataToUpdateMovingProject, position) {
  return async (dispatch, getState) => {
    if (!projectId1 || !projectId2) return Promise.resolve();
    const hasDataToBeUpdated = isObject(dataToUpdateMovingProject) && Object.keys(dataToUpdateMovingProject).length;
    const hasNoDataToBeUpdated = !hasDataToBeUpdated;

    // should validate ids to avoid to send strings as project ids when is switching row order
    if (isNaN(+projectId1) || isNaN(+projectId2)) return Promise.resolve();

    const selectedRoadmapVersionId = propOr(null, 'id')(getSelectedRoadmapVersion(getState()));
    const isRoadmapVersionSelected = !isNil(selectedRoadmapVersionId);

    let urlPath = `/api/projects/rowOrder/${projectId1}/${projectId2}`;

    if (isRoadmapVersionSelected) {
      urlPath = `/api/v1/roadmap-versions/${selectedRoadmapVersionId}/rowOrder/${projectId1}/${projectId2}`;
    }

    const switchRowOrderPromise = axios.put(urlPath, { position }).then(({ data }) => data);

    if (hasNoDataToBeUpdated) {
      return dispatch({
        type: UPDATE_PROJECT_ROW_ORDER,
        payload: {
          promise: switchRowOrderPromise,
        },
      });
    }

    await switchRowOrderPromise;

    if (hasDataToBeUpdated) {
      return dispatch(updateProjectById(projectId1, dataToUpdateMovingProject));
    }
  };
}

const updateProjects = projects => {
  return createThunk(
    UPDATE_PROJECTS,
    axios.post('/api/projects/update', projects.map(serializeProject)).then(res => res.data),
  );
};

/**
 * @function importProjects calls project import endpoint with a list of project data to be imported
 * @param  {Array} projects Projects to be imported
 * @return The action creator for import projects
 */
const importProjects = (projects, layer) => {
  return createThunk(
    IMPORT_PROJECTS,
    axios.post('/api/projects/import', {
      projects: projects.map(pipe(serializeProject, project => ({ ...project, layer }))),
    }),
  );
};

const createTagAndUpdateProject = (tag, id, update) => {
  return dispatch => {
    const payload = axios
      .post('/api/tags', tag)
      .then(res => res.data)
      .then(newTag => {
        update.tags = [...update.tags, newTag];
        return axios
          .put(`/api/projects/${id}`, serializeProject(update))
          .then(res => res.data)
          .then(projectUpdated => {
            dispatch({
              payload: projectUpdated,
              type: UPDATE_PROJECT_FULFILLED,
            });

            dispatch({
              payload: newTag,
              type: CREATE_TAG_FULFILLED,
            });

            return newTag;
          });
      });

    return payload;
  };
};

const createCustomerAndUpdateProject = (customer, id, update) => {
  return dispatch => {
    const payload = axios
      .post('/api/customers', customer)
      .then(res => res.data)
      .then(newCustomer => {
        update.customers = [...update.customers, newCustomer];
        return axios
          .put(`/api/projects/${id}`, serializeProject(update))
          .then(res => res.data)
          .then(projectUpdated => {
            dispatch({
              payload: projectUpdated,
              type: UPDATE_PROJECT_FULFILLED,
            });

            dispatch({
              payload: newCustomer,
              type: CREATE_CUSTOMER_FULFILLED,
            });

            return newCustomer;
          });
      });

    return payload;
  };
};

const addExistingMetricToProject = (projectId, metricId) =>
  createThunk(
    ADD_PROJECT_METRIC,
    axios
      .post(`/api/projects/${projectId}/metrics/${metricId}`)
      .then(res => res.data)
      .catch(throwRequestError),
    {},
  );

const bulkAddMetricsToProject = (projectId, metricIds = []) =>
  createThunk(
    BULK_ADD_PROJECT_METRICS,
    axios
      .post(`/api/projects/${projectId}/metrics`, { metricIds })
      .then(res => res.data)
      .catch(throwRequestError),
    {},
  );

const removeMetricFromProject = (projectId, metricId) =>
  createThunk(
    REMOVE_PROJECT_METRIC,
    axios
      .delete(`/api/projects/${projectId}/metrics/${metricId}`)
      .then(res => res.data)
      .catch(throwRequestError),
    {},
  );

const bulkRemoveMetricsFromProject = (projectId, metricIds = []) =>
  createThunk(
    BULK_REMOVE_PROJECT_METRICS,
    axios
      .delete(`/api/projects/${projectId}/metrics?metricIds=${metricIds.join(',')}`)
      .then(res => res.data)
      .catch(throwRequestError),
    {},
  );

const createMetricAndAddToProject = (projectId, data) => {
  return createThunk(
    CREATE_AND_ADD_METRIC_TO_PROJECT,
    async () => {
      const newMetric = await callCreateMetric(data);

      return callAddMetricToProject(projectId, newMetric.id);
    },
    {},
  );
};

const loadAutocompleteProjects = (
  value,
  { showCompleted = false, layers = null, loadTree = true, limit = 20, includeAllCorpItems = false },
) => {
  return createThunk(LOAD_PROJECTS_FROM_AUTOCOMPLETE, async () => {
    const params = {
      ...getFiltersForProjectsSearch({
        layers,
        limit,
        search: value,
        order: '-created_at',
        showCompleted,
      }),
      withParents: loadTree,
      secondLayer: layers,
      children: CHILDREN_FILTERS.noChildren,
      includeAllCorpItems,
    };
    const compiledParams = compileFiltersBody(params, [], true, GLOBAL_FILTER, IDEA_LAYER);

    return axios.post('/api/projects/search', compiledParams);
  });
};

const loadAutocompleteProjectsByTitle = (
  searchText,
  {
    showCompleted = false,
    layers = [IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER],
    loadTree = true,
    limit = 20,
    includeAllCorpItems = false,
  },
) => {
  const searchParams = {
    withParents: loadTree,
    layersToSearch: layers,
    includeAllCorpItems,
    showCompleted,
    limit,
    searchText,
  };

  return createThunk(
    LOAD_PROJECTS_FROM_AUTOCOMPLETE_TITLE,
    async () => {
      return axios.post('/api/projects/search/title', searchParams);
    },
    {},
  );
};

const transferProjectToOtherAccount = (projectId, destinationAccountOrgId, roadmapData) => {
  return createThunk(
    TRANSFER_PROJECT_TO_OTHER_ACCOUNT,
    axios
      .put(`/api/projects/${projectId}/transfer/${destinationAccountOrgId}`, roadmapData)
      .then(res => res.data)
      .catch(throwRequestError),
  );
};

export const createSummaryOfCurrentProjects = (summaryFormat, summarizeBy) => async (dispatch, getState) => {
  const state = getState();

  const filters = getPageFilters(state);
  const { displayLayer } = getDisplayLayerAndPortfolioModeFromFilters(filters);

  const hasBet = state.organization.organization.has_bet;
  const compiledFilters = compileFiltersBody(filters, [], hasBet, GLOBAL_FILTER, displayLayer);

  return dispatch(
    createThunk('summarize_projects', async () => {
      const { data } = await axios.post(`/api/projects/ai/summarize`, {
        filters: compiledFilters,
        summaryFormat,
        summarizeBy,
      });

      return data;
    }),
  );
};

const updateProjectPersonas = (projectId, updatePersonasIds, oldPersonasIds) =>
  createThunk(
    UPDATE_PROJECT_PERSONAS,
    async () => {
      const newPersonasIds = without(oldPersonasIds, updatePersonasIds);
      const removedPersonasIds = difference(oldPersonasIds, updatePersonasIds);
      let result = {};

      if (newPersonasIds.length) {
        result = await axios.post(`/api/projects/${projectId}/personas`, { personaIds: newPersonasIds }).then(res => res.data);
      }

      if (removedPersonasIds.length) {
        result = await axios
          .delete(`/api/projects/${projectId}/personas?personaIds=${removedPersonasIds.join(',')}`)
          .then(res => res.data);
      }

      return result;
    },
    {},
  );

const updateProjectLifecycles = (projectId, updatedLifecyclesIds, oldLifecyclesIds) =>
  createThunk(
    UPDATE_PROJECT_LIFECYCLES,
    async () => {
      const newLifecyclesIds = without(oldLifecyclesIds, updatedLifecyclesIds);
      const removedLifecyclesIds = difference(oldLifecyclesIds, updatedLifecyclesIds);
      let result = {};

      if (newLifecyclesIds.length) {
        result = await axios
          .post(`/api/projects/${projectId}/lifecycles`, { lifecycleIds: newLifecyclesIds })
          .then(res => res.data);
      }

      if (removedLifecyclesIds.length) {
        result = await axios
          .delete(`/api/projects/${projectId}/lifecycles?lifecycleIds=${removedLifecyclesIds.join(',')}`)
          .then(res => res.data);
      }

      return result;
    },
    {},
  );

const fetchProjectsWithDBQL = query => (dispatch, getState) => {
  dispatch(
    createThunk(FETCH_PROJECT_WITH_DBQL, async () => {
      const result = await axios.get(`/api/projects/search/dbql`, { params: { q: query } }).then(res => res.data);

      // todo: this is just for PoC
      dispatch({
        type: APPLY_FILTERS_FULFILLED,
        payload: { projects: result.data },
        meta: {
          layers: ['0', '1', '2'],
          ...result.metadata,
          addToIds: true,
        },
      });
    }),
  );
};

export {
  importProjects,
  updateProjects,
  switchProjectRowOrder,
  bulkAddMetricsToProject,
  bulkRemoveMetricsFromProject,
  createTagAndUpdateProject,
  createCustomerAndUpdateProject,
  createMetricAndAddToProject,
  addExistingMetricToProject,
  removeMetricFromProject,
  loadAutocompleteProjects,
  loadAutocompleteProjectsByTitle,
  transferProjectToOtherAccount,
  updateProjectLifecycles,
  updateProjectPersonas,
  fetchProjectsWithDBQL,
};
