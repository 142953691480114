/* eslint-disable */
import React from 'react';
import { withTheme } from 'styled-components';

export default withTheme(({ fill, height = 16, theme, width = 16, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.4444 4.44444L11.1911 5.69778L12.5956 7.11111H5.33333V8.88889H12.5956L11.1911 10.2933L12.4444 11.5556L16 8L12.4444 4.44444ZM1.77778 1.77778H8V0H1.77778C1.30671 0.00140687 0.855347 0.18916 0.522253 0.522253C0.18916 0.855347 0.00140687 1.30671 0 1.77778V14.2222C0.00140687 14.6933 0.18916 15.1447 0.522253 15.4777C0.855347 15.8108 1.30671 15.9986 1.77778 16H8V14.2222H1.77778V1.77778Z"
        fill={fill || theme.palette.newLayout.background.primary}
      />
    </svg>
  );
});
