import React, { useState, useEffect, useMemo, useCallback, useImperativeHandle, memo } from 'react';
import styled from 'styled-components';

import cellEditorHoc from 'design-system/molecules/AgGridReact-New/helpers/cellEditorHoc';
import SearchableDndListField from 'design-system/molecules/SearchableDndListField';

const SHOW_ALL_TEXT = 'Show All';

const handleOnClickAway = (clickEvent, { stopEditing }) => {
  const clickedEventTargetIsRenderedAsPortal = clickEvent.target.closest('#react-autowhatever-1');

  if (!clickedEventTargetIsRenderedAsPortal) {
    stopEditing();
  }
};

export default memo(
  cellEditorHoc(
    ({
      value: originalValue = '',
      autoFocus,
      options,
      allOptions,
      disabledSelections,
      showOptionsSubsetText,
      addNew,
      stopEditing,
      forwardedRef,
    }) => {
      const [value, setValue] = useState(originalValue);
      const [isShowAll, setIsShowAll] = useState(false);

      const handleOnChange = useCallback(
        newValue => {
          if (newValue === undefined) {
            setValue(newValue);
            return;
          }

          const value = disabledSelections.includes(newValue.title) ? originalValue : newValue.title;

          setValue(value);
        },
        [disabledSelections],
      );

      const handleShowAllClick = useCallback(() => setIsShowAll(prevState => !prevState), [isShowAll]);

      const showAllText = useMemo(() => (isShowAll ? showOptionsSubsetText : SHOW_ALL_TEXT), [isShowAll, showOptionsSubsetText]);

      const optionsToRender = useMemo(() => (isShowAll ? allOptions : options), [isShowAll, allOptions, options]);

      const createNewOption = searchQuery => ({ title: searchQuery, label: `Create "${searchQuery}"`, new: true });

      useImperativeHandle(forwardedRef, () => ({
        getValue() {
          return value;
        },
      }));

      useEffect(() => {
        // Calling stopEditing here to close the editor on the Grid when clicking on an item.
        // Needs to be in a useEffect and not on the handleOnChange so that it is not
        // called with a value that hasn't been updated yet
        if (value != null && value !== originalValue) {
          stopEditing();
        }
      }, [value]);

      return (
        <StyledSearchableDndListField
          options={optionsToRender}
          onSelectOption={handleOnChange}
          focusOnInit={autoFocus}
          getOptionWhenSearchHasNoResults={addNew ? createNewOption : null}
          onShowAllClick={allOptions ? handleShowAllClick : null}
          showAllText={showAllText}
          value={value}
          usePopper
        />
      );
    },
    handleOnClickAway,
  ),
);

const StyledSearchableDndListField = styled(props => <SearchableDndListField {...props} />)`
  min-width: 250px;
  max-width: 300px;
`;
