import moment from 'moment-timezone';
import { keys } from 'ramda';

import { FILTERS_FUNCTIONS } from 'design-system/constants';
import formatDate from 'utils/dates/formatDate';
import formatDateTime from 'utils/dates/formatDateTime';

const makeNextFilter = (unit, amount = 1) => ({
  start: formatDateTime(moment().add(amount, unit).startOf(unit), false),
  end: formatDateTime(moment().add(amount, unit).endOf(unit), false),
});

const makeThisFilter = unit => ({
  start: formatDateTime(moment().startOf(unit), false),
  end: formatDateTime(moment().endOf(unit), false),
});

const makeLastFilter = (unit, amount = 1) => ({
  start: formatDateTime(moment().subtract(amount, unit).startOf(unit), false),
  end: formatDateTime(moment().subtract(amount, unit).endOf(unit), false),
});

const WEEK = 'week';
const MONTH = 'month';
const QUARTER = 'quarter';
const YEAR = 'year';
const DAY = 'day';

const FILTER_GTE = 'gte';
const FILTER_LTE = 'lte';
const FILTER_EQUALS = 'equals';

/**
 * @function isDateBetween
 *
 * Check if one date is between two other given dates
 *
 * @param  {String} date
 * @param  {Object} start
 * @param  {Object} end
 * @return {Boolean}
 */
const isDateBetween = (date, start, end) => {
  return moment(date).isSameOrAfter(start) && moment(date).isSameOrBefore(end);
};

/**
 * @function makeFilterByDate
 *
 * Builds a filter function to filter by date in one array
 *
 * @param  {Object} filterValue
 * @param  {String} objectFieldKey
 * @return {Function}
 */
const makeFilterByDate = (filterValue, objectFieldKey) => object => {
  const objectValue = object[objectFieldKey];
  const objectValueDate = formatDate(objectValue);

  if (filterValue === null) {
    return objectValue === null;
  }

  return keys(filterValue).every(key => {
    const keyValue = filterValue[key];
    const dateValue = formatDate(keyValue);

    if (key === FILTER_GTE) {
      return moment(objectValueDate).isSameOrAfter(moment(dateValue));
    }

    if (key === FILTER_LTE) {
      return moment(objectValueDate).isSameOrBefore(moment(dateValue));
    }

    if (key === FILTER_EQUALS) {
      const lastWeek = makeLastFilter(WEEK);
      const lastMonth = makeLastFilter(MONTH);
      const lastQuarter = makeLastFilter(QUARTER);
      const lastYear = makeLastFilter(YEAR);
      const thisWeek = makeThisFilter(WEEK);
      const thisMonth = makeThisFilter(MONTH);
      const thisQuarter = makeThisFilter(QUARTER);
      const thisYear = makeThisFilter(YEAR);
      const nextYear = makeNextFilter(YEAR);
      const nextQuarter = makeNextFilter(QUARTER);
      const nextMonth = makeNextFilter(MONTH);
      const nextWeek = makeNextFilter(WEEK);
      const today = makeThisFilter(DAY);

      const mapFilterFuncToFilter = {
        [FILTERS_FUNCTIONS.lastWeek.key]: () => isDateBetween(objectValueDate, lastWeek.start, lastWeek.end),
        [FILTERS_FUNCTIONS.lastMonth.key]: () => isDateBetween(objectValueDate, lastMonth.start, lastMonth.end),
        [FILTERS_FUNCTIONS.lastQuarter.key]: () => isDateBetween(objectValueDate, lastQuarter.start, lastQuarter.end),
        [FILTERS_FUNCTIONS.lastYear.key]: () => isDateBetween(objectValueDate, lastYear.start, lastYear.end),
        [FILTERS_FUNCTIONS.thisWeek.key]: () => isDateBetween(objectValueDate, thisWeek.start, thisWeek.end),
        [FILTERS_FUNCTIONS.thisMonth.key]: () => isDateBetween(objectValueDate, thisMonth.start, thisMonth.end),
        [FILTERS_FUNCTIONS.thisQuarter.key]: () => isDateBetween(objectValueDate, thisQuarter.start, thisQuarter.end),
        [FILTERS_FUNCTIONS.thisYear.key]: () => isDateBetween(objectValueDate, thisYear.start, thisYear.end),
        [FILTERS_FUNCTIONS.nextWeek.key]: () => isDateBetween(objectValueDate, nextWeek.start, nextWeek.end),
        [FILTERS_FUNCTIONS.nextMonth.key]: () => isDateBetween(objectValueDate, nextMonth.start, nextMonth.end),
        [FILTERS_FUNCTIONS.nextQuarter.key]: () => isDateBetween(objectValueDate, nextQuarter.start, nextQuarter.end),
        [FILTERS_FUNCTIONS.nextYear.key]: () => isDateBetween(objectValueDate, nextYear.start, nextYear.end),
        [FILTERS_FUNCTIONS.today.key]: () => isDateBetween(objectValueDate, today.start, today.end),
      };

      const filterFn = mapFilterFuncToFilter[keyValue];

      if (!filterFn) return false;

      return filterFn();
    }

    return false;
  });
};

export { isDateBetween, makeFilterByDate };
