import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGridConfigValue } from 'store/grids/selectors';
import { saveGridConfig } from 'store/grids';
import useActiveViewChanged from 'hooks/userViews/useActiveViewChanged';

const GRID_EVENT_ROW_OPENED = 'rowGroupOpened';
const EXPANDED_ITEMS_STORE_KEY = 'expandedItems';

const getAllExpandedItems = (gridApi, checkExpandedFlag) => {
  const expandedGroups = [];

  gridApi.forEachNode(node => {
    const nodeId = node?.id || node?.data?.id;

    if (!nodeId) {
      return;
    }

    if (!node?.allChildrenCount || (checkExpandedFlag && !node?.expanded)) {
      // Only store expanded items if has children and flag for expanded is true
      // checkExpandedFlag will disable the need to check node?.expanded
      return;
    }

    expandedGroups.push(nodeId);
  });

  return [...new Set(expandedGroups)];
};

/**
 * @function useGridExpandedItems hook to load / store expanded groups
 * @param  {String} gridId The grid that we are working with
 * @param  {String} gridApi Grid api instance for the grid we are using

 * @return {Object} All data and callbacks generated by the hook
 */
const useGridExpandedItems = (gridId, gridApi) => {
  const dispatch = useDispatch();
  const expandedItemsRef = useRef([]);
  const { activeViewChanged } = useActiveViewChanged();

  const expandedItems = useSelector(state => getGridConfigValue(state, gridId, EXPANDED_ITEMS_STORE_KEY, []));

  expandedItemsRef.current = expandedItems;

  useEffect(() => {
    if (!gridApi || !activeViewChanged) {
      return;
    }

    const expandedNodesBatchUpdateData = [];

    // View was changed, lets force expanded groups are up to date on the grid
    gridApi.forEachNode(node => {
      node.expanded = isItemExpandedByDefault(node);
      expandedNodesBatchUpdateData.push(node);
    });

    gridApi.applyTransactionAsync({ update: expandedNodesBatchUpdateData });
  }, [gridApi, activeViewChanged]);

  const updateExpandedGroupsOnStore = (gridApi, checkExpandedFlag) => {
    const newExpandedItems = getAllExpandedItems(gridApi, checkExpandedFlag);

    if (gridId) {
      dispatch(saveGridConfig(gridId, EXPANDED_ITEMS_STORE_KEY, newExpandedItems));
    }
  };

  const clearExpandedItems = useCallback(() => {
    if (gridId) {
      dispatch(saveGridConfig(gridId, EXPANDED_ITEMS_STORE_KEY, []));
    }
  }, [gridId]);

  const setAllItemsExpanded = useCallback(() => {
    if (!gridApi) {
      return;
    }

    updateExpandedGroupsOnStore(gridApi, false);
  }, [gridId, gridApi]);

  const isItemExpandedByDefault = useCallback(
    ({ key }) => {
      return expandedItemsRef.current.includes(key);
    },
    [expandedItemsRef.current],
  );

  useEffect(() => {
    if (!gridId || !gridApi) {
      return;
    }

    gridApi.addEventListener(GRID_EVENT_ROW_OPENED, event => updateExpandedGroupsOnStore(event.api, true));
  }, [gridId, gridApi]);

  return {
    hasInvidualExpandedItems: expandedItems.length,

    clearExpandedItems,
    setAllItemsExpanded,
    isItemExpandedByDefault,
  };
};

export default useGridExpandedItems;
