import { isEmpty } from 'ramda';

/**
 * @function assignIntegrationsToProjects creates a new list of project with the respective integrations data
 * @param  {Array} projects List of projects
 * @param  {Array} integrations  List of integrations
 * @return {Array} List of projects with new integrations field
 */
const assignIntegrationsToProjects = (projects, integrations = []) => {
  if (isEmpty(integrations)) {
    return projects;
  }
  return projects.map(project => {
    const integrationsForProject = integrations.filter(data => project.id === data.project_id);

    return {
      ...project,
      integrations: integrationsForProject,
    };
  });
};

export default assignIntegrationsToProjects;
