import React from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import theme from 'design-system/theme';

import Text from 'design-system/atoms/Text/index';
import InlineDateRange from 'design-system/organisms/InlineDateRange/InlineDateRange';
import SwitchWithLabel from 'design-system/molecules/SwitchWithLabel/SwitchWithLabel';

import styled, { css } from 'styled-components';
import ShareView from 'containers/ShareView';
import { PROGRESS_PAGE } from 'constants/filters';

const ProgressHeader = ({
  startDate,
  endDate,
  onDateChange,
  targetAndPredictedLinesOptions,
  onShowTargetAndPredictedLinesChange,
  showShareView,
}) => {
  return (
    <ControlsContainer container>
      <div>
        <StyledDates
          label="Time Period:"
          startDate={moment(startDate)}
          endDate={moment(endDate)}
          onChange={onDateChange}
          textAlign="left"
          labelProps={{ md: 3 }}
          wrapperCss={dateFieldWrapperCss}
        />
      </div>
      <div style={{ paddingRight: 8 }}>
        <DatesSwitchAndValue container alignItems="center" justify="flex-end">
          <SwitchWithLabel
            id="progress_show_predicted"
            switchProps={{
              color: 'primary',
              checked: targetAndPredictedLinesOptions.checked,
              onChange: () => onShowTargetAndPredictedLinesChange(!targetAndPredictedLinesOptions.checked),
            }}
            labelProps={{
              color: 'textPrimary',
            }}
            label="Show target vs predicted dates"
          />
          {targetAndPredictedLinesOptions.checked && (
            <TargetAndPredictedDate>
              <Text color={theme.palette.text.primary}>Target end date: {targetAndPredictedLinesOptions.formattedEndDate}</Text>
              <Text color={theme.palette.text.primary}>
                Predicted end date: {targetAndPredictedLinesOptions.formattedPredictedDate}
              </Text>
            </TargetAndPredictedDate>
          )}
          {showShareView && <ShareView pageId={PROGRESS_PAGE} />}
        </DatesSwitchAndValue>
      </div>
    </ControlsContainer>
  );
};

const TargetAndPredictedDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: ${({ theme }) => `${theme.spacing.unit * 1}px`};
`;

const DatesSwitchAndValue = styled(Grid)`
  display: flex;
  gap: ${({ theme }) => `${theme.spacing.unit * 2}px`};
`;

// Hacked styling so we can use the same component
const dateFieldWrapperCss = css`
  &&&&& {
    input {
      padding-top: 7px;
    }
  }

  &&&&& {
    span {
      font-size: 0.875rem;
      font-weight: ${theme.typography.fontWeightMedium};
      color: ${theme.palette.text.primary};
    }
  }
`;

const ControlsContainer = styled(Grid)`
  padding: ${({ theme }) => `${theme.spacing.unit}px ${theme.spacing.unit * 4}px 0`};
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const StyledDates = styled(({ ...props }) => (
  <div {...props}>
    <InlineDateRange {...props} />
  </div>
))`
  &&&& {
    width: 417px;
  }
`;

export default ProgressHeader;
