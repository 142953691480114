import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import RootRef from '@material-ui/core/RootRef';
import Button from '@material-ui/core/Button';

import Dialog from 'design-system/molecules/Dialog/index';
import AutocompleteWithCursorPosition from 'design-system/atoms/AutocompleteWithCursorPosition/index';


const CommentsLightbox = ({ fieldTitle, isOpen, currentValue, options, onClose, onSave, editable = true }) => {
  const [value, setValue] = useState(currentValue);

  const domRef = useRef();

  const onChange = value => {
    setValue(value);
  };

  useEffect(() => setValue(currentValue), [currentValue]);

  const _onSave = () => {
    onSave(value);
    onClose();
  };

  return (
    <Dialog open={isOpen} closeButton title={fieldTitle} onClose={onClose} fullWidth>
      <RootRef rootRef={domRef.current}>
        <FieldContainer>
          <AutocompleteWithCursorPosition
            associateContainerRef={domRef.current}
            suggestions={options}
            value={value}
            disabled={!editable}
            inputProps={{
              style: {
                width: '100%',
              },
            }}
            onValueChange={onChange}
          />
        </FieldContainer>
        <Footer>
          <Button color="secondary" onClick={() => onClose(true)}>
            cancel
          </Button>
          <Button color="primary" sty disabled={!editable} onClick={_onSave}>
            Save
          </Button>
        </Footer>
      </RootRef>
    </Dialog>
  );
};

const FieldContainer = styled.div`
  &&&& {
    margin: 20px 0 60px 0;
  }
`;

const Footer = styled.div`
  &&&& {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default CommentsLightbox;
