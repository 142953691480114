import React from 'react';
import styled from 'styled-components';

import NewCard from 'design-system/organisms/NewCard/index';

import { NewColumnField } from 'constants/projects';

const isCustomField = option => option?.startsWith('custom_fields.');

const FIELDS_TO_IGNORE_ADD_COLUMN_ROW = ['product1', 'keyResult1', 'dates', 'priority', 'planningStage', 'owner'];

const cellType = {
  ROW: 'row',
  COL: 'col',
};

const AddNewColumnOrRowBox = ({
  selectedOption,
  type,
  isCardOpen,
  handleAdd,
  handleSave,
  handleClose,
  fieldsToIgnoreAddColumnRow = FIELDS_TO_IGNORE_ADD_COLUMN_ROW,
}) =>
  !isCustomField(selectedOption.key) &&
  !fieldsToIgnoreAddColumnRow.includes(selectedOption.key) && (
    <AddNewCellBoxWrapper data-testid={`add-${type}-${selectedOption.key}`}>
      <AddNewCellButton id={`add-${type}-${selectedOption.key}`} onClick={handleAdd} role="button">
        + Add {selectedOption.title}
      </AddNewCellButton>
      {isCardOpen && (
        <AddNewCellWrapper>
          <NewCard fields={NewColumnField} onAdd={v => handleSave(v, type)} onCancel={() => handleClose(type)} />
        </AddNewCellWrapper>
      )}
    </AddNewCellBoxWrapper>
  );

export default AddNewColumnOrRowBox;

export { cellType };

const AddNewCellBoxWrapper = styled.div`
  &&&& {
    min-height: 52px;
    min-width: 170px;
    width: 170px;
    height: 52px;
    padding: 10px;
  }
`;

const AddNewCellWrapper = styled.div`
  &&&& {
    width: 300px;
    padding: 10px;
  }
`;

const AddNewCellButton = styled.div`
  &&&& {
    cursor: pointer;
    height: 32px;
    width: 100%;
    display: block;
    background: #00b1f3;
    border: none;
    color: #fff;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    min-height: 32px;
    padding: 9px 16px;
    vertical-align: top;
    border-radius: 4px;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 12px;
  }
`;
