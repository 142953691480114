import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { head, defaultTo, pipe } from 'ramda';

import { getActiveMetrics } from 'store/metrics/selectors';
import { getOrganization } from 'store/organization';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import makeMetadataRoadmapsOptionsFormatter from 'utils/makeMetadataRoadmapsOptionsFormatter';

const defaultToEmptyArray = defaultTo([]);
const getFirstRoadmap = pipe(defaultToEmptyArray, head);
// Todo: check with Corp level how should we fetch the roadmap
const getFirstRoadmapFromOKR = okr => getFirstRoadmap(okr?.objective_roadmaps) ?? getFirstRoadmap(okr?.key_result_roadmaps) ?? {};

export default function (hasMetadataRoadmaps, roadmaps = [], corpRoadmaps = [], selectedObjective) {
  const organization = useSelector(state => getOrganization(state));
  const organizationsAccessControl = useOrganizationsAccessControl();

  const metrics = useSelector(getActiveMetrics);

  const { roadmap_id: roadmapId, product_1_id: product1Id } = getFirstRoadmapFromOKR(selectedObjective);

  const [options, setOptions] = useState({
    metrics: [],
  });

  useEffect(() => {
    const formatMetadataOptions = makeMetadataRoadmapsOptionsFormatter(
      organization,
      roadmapId,
      organizationsAccessControl,
      roadmaps,
      corpRoadmaps,
    );

    setOptions({
      metrics: formatMetadataOptions('metric_roadmaps', metrics, {
        needsObject: organizationsAccessControl?.isDodActive,
        includeCorpRoadmap: true,
      }),
    });
  }, [roadmapId, product1Id, metrics]);

  if (!hasMetadataRoadmaps) return [];

  return options;
}
