import { is } from 'immutable';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import uniq from 'lodash/uniq';

import { getEnrichedProject } from 'store/projects/selectors';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import getRelevantStateKeysForProjects from 'store/utils/getRelevantStateKeysForProjects';
import {
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_PRODUCTS_2,
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
  PERSONAS,
  LIFECYCLES,
  PLAN_OF_RECORD_ID,
} from 'constants/common';

const emptyArray = [];

const createImmutableSelector = createSelectorCreator(defaultMemoize, is);

export function getState(state) {
  return state.projectLightbox;
}

/**
 * Returns if the project lightbox is open
 */
export const getProjectLightboxOpen = createSelector(getState, state => state.lightboxOpen);

export const getProjectData = createSelector(getState, projectLightboxState => projectLightboxState.projectData);
export const getParentProjectData = createSelector(getState, projectLightboxState => projectLightboxState.parentProjectData);
export const getProjectLightboxProjectIntegrations = createSelector(getProjectData, projectData => projectData?.integrations);

export const getSelectedProjectOnLigthbox = createImmutableSelector(
  getRelevantStateKeysForProjects,
  getProjectData,
  (state, projectData) => {
    if (!projectData || !projectData.id) return null;

    return getEnrichedProject(state, projectData);
  },
);

export const getVisibleFields = createSelector(
  getState,
  state => getProjectsCustomFields(state),
  (projectLightboxState, customFields) => {
    const defaultFields = [
      'title',
      'integrations',
      'layerMetadata',
      'type',
      'links',
      'timeline',
      'customFields',
      'statusSummary',
      'integrations',
      'details',
      'files',
      'customers',
      'tags',
      'watchers',
      'owner',
      'priority',
      'timeframes',
      ADDITIONAL_TIMEFRAMES,
      ADDITIONAL_TIMEFRAMES_2,
      'health',
      'progress',
      'stages',
      'phases',
      'parent',
      'allObjectives',
      'objectives',
      'keyResult1',
      'keyResult2',
      ADDITIONAL_OBJECTIVES,
      ADDITIONAL_KEY_RESULTS,
      ADDITIONAL_KEY_RESULTS_2,
      'roadmaps',
      ADDITIONAL_ROADMAPS,
      ADDITIONAL_PRODUCTS,
      ADDITIONAL_PRODUCTS_2,
      'products',
      'themes',
      ADDITIONAL_THEMES,
      'categories',
      ADDITIONAL_CATEGORIES,
      ADDITIONAL_TEAMS,
      ADDITIONAL_TEAMS_2,
      PERSONAS,
      LIFECYCLES,
      ...customFields.map(({ key }) => key),
    ];

    return uniq(['title', 'layerMetadata', ...(projectLightboxState.visibleFields || defaultFields)]);
  },
);

export const selectOpenFromEntity = createSelector(getState, projectLightboxState => projectLightboxState.openFromEntity);

export const selectIsLoadingEstimatesTab = state => getState(state)?.operations?.loadEstimatesTab?.isLoading;

export const selectScenarioSelected = createSelector(getState, projectLightboxState => {
  const scenarioSelected = projectLightboxState?.scenarioSelected;

  if (!scenarioSelected || scenarioSelected?.id === PLAN_OF_RECORD_ID) return null;

  return scenarioSelected;
});

export const selectDisabledFields = createSelector(
  getState,
  projectLightboxState => projectLightboxState?.disabledFields || emptyArray,
);
