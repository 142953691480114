import moment from 'moment-timezone';

const snapToGridOptions = {
  TARGET_START_DATE: 'startDate',
  TARGET_END_DATE: 'endDate',
  PREDICTED_END_DATE: 'predictedEndDate',
  START_DATE_ESTIMATES: 'startDateEstimates',
  END_DATE_ESTIMATES: 'endDateEstimates',
};

const isSnapToEnd = option => [snapToGridOptions.TARGET_END_DATE, snapToGridOptions.PREDICTED_END_DATE].includes(option);
const isSnapToPredictedEnd = option => option === snapToGridOptions.PREDICTED_END_DATE;

/**
 * Get which date should be used for the snap to grid feature.
 *
 * @param {Object} item The source item
 * @param {String} zoomMode The calendar zoom mode (daily, monthly, weekly or quarterly)
 * @param {snapToGridOn} snapToGridOn The snap to grid options selected by the user if any
 *
 * @return {Moment} the target date for the snap to grid
 * */
const getTargetDateForSnap = (item, zoomMode, snapToGridOn = snapToGridOptions.TARGET_START_DATE) => {
  if (!item[snapToGridOn]) {
    return null;
  }

  const isMoment = moment.isMoment(item[snapToGridOn]);
  const targetDate = isMoment ? item[snapToGridOn].clone() : moment(item[snapToGridOn]);

  return isSnapToEnd(snapToGridOn) ? targetDate.endOf(zoomMode) : targetDate.startOf(zoomMode);
};

export { snapToGridOptions, isSnapToEnd, isSnapToPredictedEnd, getTargetDateForSnap };
