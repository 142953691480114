const getSelectedCellFactory = gridApi => {
  return () => {
    const rangeSelections = gridApi.getCellRanges();

    if (rangeSelections && rangeSelections.length) {
      const nodes = [];

      gridApi.forEachNode(node => nodes.push(node));
      return rangeSelections[0].start;
    }

    return null;
  };
};

export { getSelectedCellFactory };
