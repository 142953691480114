import React from 'react';
import { withTheme } from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

function SvgComponent(props) {
  const fill = props.fill || props.theme.palette.text.primary;

  return (
    <SvgIcon fontSize="inherit" width="25" height="20.01" viewBox="0 0 25 20.01" {...props}>
      <g id="icon-gantt" transform="translate(-84.403 -909.442)" opacity="0.6">
        <path 
          id="Path_15595" 
          data-name="Path 15595"
          d="M101.53,929.452a2,2,0,0,1-2-2v-2a2,2,0,0,1,2-2l5.873-.01a2,2,0,0,1,2,2v2a2,2,0,0,1-2,
          2Zm5.873-4.01-5.873.01,0,2,5.873-.011Z"
          fill={fill}
        />
        <path 
          id="Path_15596" 
          data-name="Path 15596"
          d="M99.512,922.442H93.248a2,2,0,0,1-2-2v-2a2,2,0,0,1,2-2h6.264a2,2,0,0,1,2,2v2A2,2,0,0,1,99.512,
          922.442Zm-7.268-2h0Zm1,0,6.268,0,0-2H93.248Z"
          fill={fill}
        />
        <path 
          id="Path_15597"
          data-name="Path 15597"
          d="M91.254,915.442H86.4a2,2,0,0,1-2-2v-2a2,2,0,0,1,2-2h4.851a2,2,0,0,1,2,2v2A2,2,
          0,0,1,91.254,915.442Zm-4.851-4v2h4.851v-2Z"
          fill={fill}
        />
        <path 
          id="Path_15598" 
          data-name="Path 15598"
          d="M92.247,920.286a1,1,0,0,1-1-1v-6.835a1,1,0,0,1,2,0v6.835A1,1,0,0,1,92.247,920.286Z"
          fill={fill}
        />
        <path 
          id="Path_15599" 
          data-name="Path 15599"
          d="M100.53,927.737a1,1,0,0,1-1-1V919.9a1,1,0,1,1,2,0v6.835A1,1,0,0,1,100.53,927.737Z"
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
}

export default withTheme(SvgComponent);
