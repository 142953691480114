import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { reject, isNil, pipe, not, isEmpty, path, defaultTo } from 'ramda';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import { INTEGRATIONS_KEYS } from 'constants/integrations';

import { enableIntegrationWebhooks, disableIntegrationWebhooks } from 'store/organization';

import useAppNotifications from 'hooks/useAppNotifications';

import CollapsePanel from 'components/CollapsePanel';

import WebhookSwitcher from 'routes/Settings/Integration/components/WebhookSwitcher';

const getOrgIntegrationWebhooks = pipe(path(['data', 'webhooks']), defaultTo([]));
const isNotEmpty = pipe(reject(isNil), isEmpty, not);

const RallyWebhooks = props => {
  const { orgIntegration, userCanUpdateOrgIntegration } = props;

  const dispatch = useDispatch();

  const organizationId = orgIntegration?.organization_id;
  const orgIntegrationId = orgIntegration?.id;
  const webhooks = getOrgIntegrationWebhooks(orgIntegration);

  const { addNotification } = useAppNotifications();

  const createDefaultRallyWebhooks = useCallback(() => {
    return dispatch(enableIntegrationWebhooks(INTEGRATIONS_KEYS.rally, organizationId, orgIntegrationId)).then(() => {
      addNotification({ id: 'rally-webhooks-enabled-successfully', message: 'Webhooks enabled successfully' });
    });
  }, [addNotification, organizationId, orgIntegrationId]);

  const deleteAllRallyWebhooks = useCallback(() => {
    return dispatch(disableIntegrationWebhooks(INTEGRATIONS_KEYS.rally, organizationId, orgIntegrationId));
  }, [organizationId, orgIntegrationId]);

  return (
    <Wrapper>
      <CollapsePanel title={<TextDeprecated size="medium">Configure Webhook</TextDeprecated>}>
        <PanelContent>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <FormControlLabelContainer
                control={
                  <WebhookSwitcher
                    register={createDefaultRallyWebhooks}
                    unregister={deleteAllRallyWebhooks}
                    webhooks={webhooks}
                    orgIntegrationId={orgIntegrationId}
                  />
                }
                disabled={!userCanUpdateOrgIntegration}
                label={isNotEmpty(webhooks) ? 'Disable Webhook' : 'Enable Webhook'}
              />
              <WarningText breakwords>Rally system admin permission is required to turn on / off webhook</WarningText>
            </Grid>
          </Grid>
        </PanelContent>
      </CollapsePanel>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const FormControlLabelContainer = styled(FormControlLabel)`
  margin-top: -10px;
  padding-left: 5px;
`;

const PanelContent = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.text.lightGrey};
  padding-bottom: 16px;
`;

const WarningText = styled(TextDeprecated)`
  &&&& {
    margin-top: 3px;
    font-size: ${props => props.theme.typography.fontSizeSmall}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    ${props => `color: ${props.theme.palette.text.error}`};
  }
`;

export default RallyWebhooks;
