import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { not } from 'ramda';

import {
  getAllRoadmapVersions,
  getAllRoadmapVersionsProjects as getAllRoadmapVersionsProjectsSelector,
  getCompareVersionEditScenario,
} from 'store/roadmapVersions/selectors';
import { setCompareVersionEditScenario } from 'store/roadmapVersions';
import { updateProjectOnCompareScenario } from 'store/projects';
import { COMPARE_SCENARIOS_SUMMARY_PAGE } from 'constants/filters';
import { getDisplayLayer, makeSelectFilteredProjectsByRoadmapForPage } from 'store/filters/selectors';

import normalizeArray from 'utils/normalizeArray';

const GROUP_SEPARATOR = '*';

const getScenarioDragAndDropControls = (result, selectedScenario) => {
  const { source, destination } = result;
  const sourceInfo = source.droppableId.split(GROUP_SEPARATOR);
  const destInfo = destination.droppableId.split(GROUP_SEPARATOR);

  const sourceScenario = sourceInfo[1];
  const destScenario = destInfo[1];
  const isDifferentScenarioDrop = sourceScenario !== destScenario;
  const isEditingDifferentScenario = selectedScenario && String(selectedScenario?.id) !== sourceScenario;

  return { isDifferentScenarioDrop, isEditingDifferentScenario, sourceScenario };
};

const getFilteredProjectsForPage = makeSelectFilteredProjectsByRoadmapForPage();

const useEditRoadmapVersionsProjectsActions = () => {
  const dispatch = useDispatch();
  const displayLayer = useSelector(getDisplayLayer);

  const selectedScenario = useSelector(getCompareVersionEditScenario);
  const scenarios = useSelector(getAllRoadmapVersions);
  const scenarioVersionsProjects = useSelector(state => getAllRoadmapVersionsProjectsSelector(state));
  const planOfRecordProjects = useSelector(state =>
    getFilteredProjectsForPage(state, COMPARE_SCENARIOS_SUMMARY_PAGE, true, displayLayer),
  );

  const isValidDropUpdate = result => {
    const { isDifferentScenarioDrop, isEditingDifferentScenario } = getScenarioDragAndDropControls(result, selectedScenario);

    return not(isDifferentScenarioDrop || isEditingDifferentScenario);
  };

  const onDragStartCallback = result => {
    const { source } = result;
    const sourceInfo = source.droppableId.split(GROUP_SEPARATOR);

    const sourceScenario = sourceInfo[1];
    const isEditingDifferentScenario =
      selectedScenario && String(selectedScenario?.id) !== sourceScenario && not(sourceScenario?.committed);

    if (isEditingDifferentScenario) {
      return;
    }

    const currentScenario = scenarios.find(scenario => scenario.id === +sourceScenario);

    if (!selectedScenario && currentScenario) {
      // we don't want to apply filters
      dispatch(setCompareVersionEditScenario(currentScenario));
    }
  };

  const normalizedProjects = useMemo(() => {
    const projectsToNormalize = selectedScenario ? scenarioVersionsProjects[selectedScenario?.id] : planOfRecordProjects;

    return normalizeArray(projectsToNormalize || [], 'id');
  }, [scenarioVersionsProjects, selectedScenario, planOfRecordProjects]);

  const updateProjectLocal = project => {
    dispatch(updateProjectOnCompareScenario(project));
  };

  return {
    isValidDropUpdate,
    normalizedProjects,
    onDragStartCallback,
    updateProject: updateProjectLocal,
  };
};

export default useEditRoadmapVersionsProjectsActions;
