import React from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';

import { INTEGRATIONS_KEYS, INTEGRATIONS_NAMES } from 'constants/integrations';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import CollapsePanel from 'components/CollapsePanel';
import TestRallyConnection from '../TestRallyConnection';

const RallyConnection = props => {
  const { orgIntegrationId, onShowDisconnectConfirmation, handleTestConnection, userCanUpdateOrgIntegration } = props;

  const integrationNameLabel = INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.rally];

  return (
    <Wrapper>
      <CollapsePanel title={<TextDeprecated size="medium">{integrationNameLabel} Connection</TextDeprecated>}>
        <PanelContent>
          <Info>Your dragonboat workspace has been linked to your {integrationNameLabel} instance.</Info>
          <ButtonRow>
            <TestRallyConnection orgIntegrationId={orgIntegrationId} handleTestConnection={handleTestConnection} />
          </ButtonRow>
          <ButtonRow>
            <Button color="secondary" onClick={onShowDisconnectConfirmation} disabled={!userCanUpdateOrgIntegration}>
              Disconnect From {integrationNameLabel}
            </Button>
            <Note>(This will disconnect the connection between {integrationNameLabel} and your entire workspace)</Note>
          </ButtonRow>
        </PanelContent>
      </CollapsePanel>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const PanelContent = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.text.lightGrey};
  padding-bottom: 16px;
`;

const Info = styled.div`
  margin: 0 8px 30px;
`;

const Note = styled.p`
  font-size: 12px;
  margin-left: 8px;
  margin-top: 3px;
  color: #b3b3b3;
`;

const ButtonRow = styled.div`
  padding-bottom: 5px;
`;

export default RallyConnection;
