import { stringComparator } from '../comparators';
import { TextCellRenderer } from '../cellRenderers';

const getTitleColumnDef = (name, field, editable = true) => ({
  field: field ?? 'title',
  headerName: name ?? 'Title',
  suppressMovable: true,
  cellRenderer: TextCellRenderer,
  comparator: stringComparator,
  editable,
});

export default getTitleColumnDef;
