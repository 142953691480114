

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setAppGlobalConfig } from 'store/app/actions';
import { showImportIdeasLightbox } from 'store/ideas';

import useApplyTemplate from '../hooks/useApplyTemplate';

import Image1 from './images/00.png';
import Image2 from './images/02.png';
import Image3 from './images/03.png';
import Image4 from './images/04.png';
import Image5 from './images/05.png';
import Image6 from './images/01.png';

const sliders = [
  {
    title: 'Create portfolio dimensions',
    image: Image1,
  },
  {
    title: 'Create or Import Ideas/ Initiatives',
    image: Image2,
  },
  {
    title: 'Manage Timeline - Drag and drop to visualize',
    image: Image3,
  },
  {
    title: 'Forecast List - Allocate to Success',
    image: Image4,
  },
  {
    title: 'Automagic track and alert via Integrations',
    image: Image5,
  },
  {
    title: 'Build your own deck for Stakeholder Communication and Sharing',
    image: Image6,
  },
];

const componentHOC = (Component) => {
  return (props) => {
    const dispatch = useDispatch();
    const showLightbox = useSelector(state => state.app.showStartGuideLightbox);
    const selectedTemplate = useSelector(state => state.app.selectedTemplate);
    const applyTemplate = useApplyTemplate(selectedTemplate && selectedTemplate.id);
    const [currSliderIndex, setCurrSliderIndex] = useState(0);
    const currentSlider = sliders[currSliderIndex];

    useEffect(() => {
      setCurrSliderIndex(0);
    }, [showLightbox]);

    const _onClose = () => {
      dispatch(setAppGlobalConfig({
        showStartGuideLightbox: false,
        selectedTemplate: null,
      }));
    };
    const _nextSlider = () => {
      if (currSliderIndex < (sliders.length - 1)) {
        setCurrSliderIndex(currSliderIndex + 1);
      } else {
        _onClose();
        if (selectedTemplate) {
          dispatch(showImportIdeasLightbox());
          applyTemplate();
        }
      }
    };
    const _prevSlider = () => {
      if (currSliderIndex > 0) {
        setCurrSliderIndex(currSliderIndex - 1);
      } else {
        dispatch(setAppGlobalConfig({
          showPreviewTemplate: true,
          showStartGuideLightbox: false,
        }));
      }
    };

    return (<Component
      {...props}
      open={showLightbox}
      onClose={_onClose}
      sliders={sliders}
      currentSlider={currentSlider}
      currSliderIndex={currSliderIndex}
      nextSlider={_nextSlider}
      prevSlider={_prevSlider}
      showSlider={setCurrSliderIndex}
      selectedTemplate={selectedTemplate}
    />);
  };
};

export default componentHOC;
