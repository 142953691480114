import WYSIWYGEditorField from 'design-system/molecules/WYSIWYGEditorField/index';
import { MAX_HEIGHT, MIN_HEIGHT } from 'design-system/molecules/RemirrorWYSIWYGEditor/constants';
import Button from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';

import { spacing } from 'design-system/theme';

const RecurringNotificationCommentEditor = ({
  comment,
  channelType,
  onCommentUpdated,
  onCancelCommentChanges,
  onSaveCommentUpdates,
}) => {
  return (
    <Wrapper>
      <WYSIWYGEditorField
        key={`view-${channelType}-recurring-message`}
        value={comment}
        content={comment}
        placeholder="Optional: Add a message"
        onChange={onCommentUpdated}
        minHeight={MIN_HEIGHT}
        maxHeight={MAX_HEIGHT}
      />
      <ButtonsWrapper>
        <Button data-test="save-recurring-message" onClick={onSaveCommentUpdates} color="primary">
          Save
        </Button>
        <Button data-test="cancel-recurring-message" onClick={onCancelCommentChanges}>
          Cancel
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: ${spacing()}px;
`;

export default RecurringNotificationCommentEditor;
