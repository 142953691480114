import {
  AutocompleteCellRenderer,
  NumericCellRenderer,
  TextCellRenderer,
} from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { numericColumnDef } from 'design-system/molecules/AgGridReact-New/columns';

import customFieldColumnFactory from './customFieldColumnFactory';
import autocompleteFields from './autocompleteFields';
import numericFields from './numericFields';
import textFields from './textFields';

const generateCustomFields = customFields => customFields.map(customFieldColumnFactory({ editable: false }));

const generateAutocompleteFields = systemFields =>
  Object.entries(autocompleteFields).map(([key, getHeaderName]) => ({
    editable: false,
    field: key,
    headerName: getHeaderName(systemFields),
    width: 120,
    cellRenderer: AutocompleteCellRenderer,
  }));

const generateNumericFields = () =>
  Object.entries(numericFields).map(([key, { label, width, valueGetter, aggFunc, withoutZeros = true }]) => ({
    ...numericColumnDef,
    editable: false,
    field: key,
    headerName: label,
    width,
    valueGetter,
    aggFunc,
    cellRenderer: NumericCellRenderer,
    cellRendererParams: {
      withoutZeros,
    },
  }));

const generateTextFields = () =>
  Object.entries(textFields).map(([key, { label, width, valueGetter }]) => ({
    editable: false,
    field: key,
    headerName: label,
    width,
    valueGetter,
    cellRenderer: TextCellRenderer,
  }));

export { generateCustomFields, generateAutocompleteFields, generateNumericFields, generateTextFields };
