import { DeliverableStatusDropdownCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import { returnsTrueIfKeyIsNotEscOrTab } from 'utils/agGrid';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { isParentItem } from 'routes/Settings/pdlc/helpers/index';
import { isNewRow } from 'design-system/molecules/AgGridReact-New/helpers';

const getCycleDeliverableStatusColumnDef = allowActions => ({
  field: 'status',
  headerName: 'Status',
  cellEditor: DeliverableStatusDropdownCellEditor,
  suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
  comparator: stringComparator,
  cellEditorPopup: true,
  sortable: false,
  // for cycle level, status is not a thing. Only deliverables have status options
  valueFormatter: params => (isParentItem(params?.data) ? '' : 'Edit options'),
  enableRowGroup: false,
  editable: params => allowActions && !isNewRow(params) && !isParentItem(params?.data),
});

export default getCycleDeliverableStatusColumnDef;
