import React from 'react';
import { useSelector } from 'react-redux';

import { LEAD_LINES_PLUGIN } from 'components/ReactChart/utils/plugins';

import { getProjectsGroupedBy } from 'store/dashboards/selectors';
import { getNormalizedObjectives } from 'store/objectives/selectors';
import { INITIATIVE_LAYER } from 'src/store/projects/constants';
import { DEFAULT_PIE_CHART_LAYOUT_OPTIONS, HIDE_LEGEND_OPTION } from 'constants/charts';
import { formatDataByMetadataForPieChart } from '../../helpers';
import usePieChart from 'hooks/charts/usePieChart';

const GROUP_BY_KEY = 'objective';

const componentHOC = Component => {
  return props => {
    const selectedGroup = { key: GROUP_BY_KEY };
    const { objectives, data } = useSelector(state => ({
      objectives: getNormalizedObjectives(state),
      data: getProjectsGroupedBy(state, props.pageId, { selectedGroupByLvl1: selectedGroup }, INITIATIVE_LAYER),
    }));

    const _makeDataToChart = React.useCallback(() => {
      const { values, colors, labels } = formatDataByMetadataForPieChart(data, objectives);

      const datasets = [
        {
          label: '',
          data: values,
          backgroundColor: colors,
          hoverOffset: 4,
        },
      ];

      return { labels, datasets };
    }, [data, objectives]);

    const chart = usePieChart({
      generateData: _makeDataToChart,
      options: {
        ...DEFAULT_PIE_CHART_LAYOUT_OPTIONS,
        plugins: {
          legend: { ...HIDE_LEGEND_OPTION },
        },
        radius: '80%',
      },
      plugins: [LEAD_LINES_PLUGIN],
    });

    return <Component {...props} chart={chart} />;
  };
};

export default componentHOC;
