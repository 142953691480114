import React, { Component } from 'react';
import { string, func, object, bool } from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

export default class DateRangeMode extends Component {
  static propTypes = {
    label: string,
    mode: string,
    onChange: func.isRequired,
    classes: object,
    hideModes: bool,
    disabled: bool,
  };

  static defaultProps = {
    label: 'Timeline',
    classes: {},
    hideModes: false,
    disabled: false,
  };

  render() {
    const { mode, onChange, label, hideModes, classes, disabled } = this.props;

    return (
      <FormControl disabled={disabled}>
        <FormGroup row>
          {label && (
            <FormLabel className={classes.label} control="legend">
              {label}
            </FormLabel>
          )}
          {!hideModes && (
            <RadioGroup
              disabled={disabled}
              style={{ marginTop: '-3px' }}
              aria-label="timeline"
              name="timeline"
              value={mode}
              onChange={onChange}
              row
            >
              <FormControlLabel
                value="duration"
                control={<Radio className={classes.radio} color="primary" />}
                label="Use duration"
              />
              <FormControlLabel
                value="endDate"
                control={<Radio className={classes.radio} color="primary" />}
                label="Use End date"
              />
            </RadioGroup>
          )}
        </FormGroup>
      </FormControl>
    );
  }
}
