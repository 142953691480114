import { MAP_FILTERS_WITH_PROJECT } from 'constants/filters';

export default (data, dataType, filters) => {
  if (!['initiative', 'bet'].includes(dataType)) return data;

  const { quickFilters } = filters;

  const _filterProject = p => {
    let shouldShowProject = true;

    if (!quickFilters) return true;

    Object.keys(quickFilters).forEach(field => {
      const filterVal = quickFilters[field].map(f => String(f)); // need to be consistent with string/int 
      const mappedField = MAP_FILTERS_WITH_PROJECT[field];

      if (filterVal.length && !filterVal.includes(String(p[mappedField]))) shouldShowProject = false;
    });

    return shouldShowProject;
  };

  return data.filter(project => _filterProject(project));
};
