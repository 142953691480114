import React, { Component, Fragment } from 'react';
import { EditorState, RichUtils } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import styled from 'styled-components';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import isEqual from 'lodash/isEqual';
import DOMPurify from 'dompurify';

import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from 'draft-js-buttons';
import HeadlinesButton from './HeadlinesButton';
import WYSIWYGRender from '../TextEditorWYSIWYGRender/WYSIWYGRender';

import 'draft-js-static-toolbar-plugin/lib/plugin.css';
import 'draft-js-linkify-plugin/lib/plugin.css';

const toolbarPlugin = createToolbarPlugin();
const linkifyPlugin = createLinkifyPlugin({
  component: props => (
    // eslint-disable-next-line no-alert, jsx-a11y/anchor-has-content, jsx-a11y/no-static-element-interactions
    (<a {...props} onClick={() => window.open(props.href, '_blank').focus()} />)
  ),
});

const fromHtml = convertFromHTML({
  htmlToStyle: (nodeName, node, currentStyle) => {
    if (nodeName === 'a') {
      return currentStyle.add('BLUE');
    }
    return currentStyle;
  },
  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName === 'a') {
      return createEntity('LINK', 'MUTABLE', { url: node.href });
    }
  },
});

export default class TextEditorWYSIWYG extends Component {
  /*
    TODO/FIXME/Warning: This component will probably be deprecated, we're keeping it
      mostly because AgGrid. For most cases we should use TextAreaEditor.
   */

  state = {
    active: false,
  };

  constructor(props) {
    super(props);

    let editorState;

    if (props.value && typeof props.value === 'string' && props.value.trim() !== '') {
      editorState = EditorState.createWithContent(fromHtml(props.value));
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {
      editorState,
      html: props.value,
      active: props.defaultActive,
    };
  }

  componentWillReceiveProps(newProps) {
    if (!isEqual(newProps.value, this.state.html)) {
      const editorState =
        newProps.value && typeof newProps.value === 'string' && newProps.value.trim() !== ''
          ? EditorState.createWithContent(fromHtml(newProps.value))
          : EditorState.createEmpty();

      this.setState({ editorState, html: newProps.value });
    }
  }

  onChange = editorState => {
    const html = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return <a href={entity.data.url}>{originalText}</a>;
        }
        return originalText;
      },
    })(editorState.getCurrentContent());

    this.setState({ editorState, html }, () => {
      if (this.props.onChange) {
        this.props.onChange(html);
      }
    });
  };

  focus = () => {
    setTimeout(() => this.editor && this.editor.focus(), 100);
  };

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  render() {
    const { label, ref, className, disableToolbox, defaultActive } = this.props;
    const { Toolbar } = toolbarPlugin;
    const plugins = [linkifyPlugin];
    const { editorState, active, html } = this.state;

    if (!disableToolbox) {
      plugins.push(toolbarPlugin);
    }

    if (!active) {
      return (
        <div>
          {label && <Label html>{label}</Label>}
          <EditorContainer read onClick={() => this.setState({ active: true }, () => this.focus())}>
            <div className="DraftEditor-root" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} />
          </EditorContainer>
        </div>
      );
    }

    return (
      <EditorContainer className={className} onClick={this.focus}>
        <Editor
          editorState={editorState}
          onChange={this.onChange}
          onBlur={!defaultActive ? () => this.setState({ active: false }) : undefined}
          handleKeyCommand={this.handleKeyCommand}
          plugins={plugins}
          ref={element => {
            this.editor = element;
            if (ref) ref(element);
          }}
        />
        {label && <Label>{label}</Label>}
        {!disableToolbox && (
          <Toolbar>
            {externalProps => (
              <Fragment>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <CodeButton {...externalProps} />
                <HeadlinesButton {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
                <BlockquoteButton {...externalProps} />
              </Fragment>
            )}
          </Toolbar>
        )}
      </EditorContainer>
    );
  }
}

const EditorContainer = styled(WYSIWYGRender)`
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  min-height: 50px;
  position: relative;
  font-weight: 400;
  padding: 6px 0 7px;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 24px;
  background: transparent;
  line-height: 22px;
  box-sizing: border-box;
  overflow: hidden;

  &:before {
    left: 0;
    right: 0;
    bottom: 5px;
    content: '';
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid #e6e6e6;
    pointer-events: none;
  }
  &:hover:before {
    border-bottom: 2px solid rgba(0, 0, 0, 0.87);
  }

  &:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid rgb(12, 101, 132);
    pointer-events: none;
  }

  &.focused:after {
    transform: scaleX(1);
  }

  &&&:global(.public-DraftEditor-content) {
    min-height: 100px;
  }

  > div:not(.DraftEditor-root) {
    background: #fbfbfb;
  }

  .DraftEditor-root {
    overflow-y: auto;
    height: ${({ read }) => (read ? '120px' : '350px')};
  }
`;

const Label = styled.label`
  ${({ html }) => (!html ? 'top: -24px' : 'top: 0')};
  left: 0;
  position: absolute;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
`;
