import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { toast } from 'react-toastify';
import { defaultTo, has, isNil, pipe, prop, propEq } from 'ramda';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import useSystemFields from 'hooks/useSystemFields';
import useGetInitiativesAndBets from 'hooks/useGetInitiativesAndBets';
import { getObjectives, selectKeyResults1 } from 'store/objectives/selectors';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import { getAllTags } from 'store/tags/selectors';
import { getPhases } from 'store/phases/selectors';
import { getThemes } from 'store/themes/selectors';
import { getPriorities } from 'store/priorities/selectors';
import { getCategories } from 'store/categories/selectors';
import { getAllCustomers } from 'store/customers/selectors';
import { getAllTimeframes as getAllTimeframesSelector, getTimeframesLevelCorp } from 'store/timeframes/selectors';
import { selectPersonasData } from 'features/Personas/store/selectors';
import { selectLifecyclesData } from 'features/Lifecycles/store/selectors';
import { closeProjectLightbox, openProjectLightbox, setCurrentProjectParent } from 'store/projectLightbox';
import { addFileToProject, removeFileFromProject, deleteProjects, cloneProject } from 'store/projects';
import {
  getSelectedProjectOnLigthbox,
  getParentProjectData,
  selectScenarioSelected,
  selectDisabledFields,
} from 'store/projectLightbox/selectors';
import { CLONING_PROJECT_MESSAGE, getCloneProjectError } from 'constants/projectLightbox';
import { METADATA_LEVELS, ROADMAPS_CORP, ROADMAPS, TIMEFRAMES, TEAMS } from 'constants/common';
import { getOrganization } from 'store/organization/selectors';
import { getProducts, getProductsLevelTwo } from 'store/roadmaps/selectors';
import useDeepEffect from 'hooks/useDeepEffect';
import { IDEA_LAYER } from 'store/projects/constants';

import useUploadFileAction from 'hooks/files/useUploadFileAction';
import getFailedFileUploadErrorMessage from 'hooks/files/utils/getFailedFileUploadErrorMessage.js';
import useUploadedFileGetLink from 'hooks/files/useUploadedFileGetLink';
import { getPageFilters } from 'store/filters/selectors';
import { getUsers } from 'store/users/selectors';
import { openMetadataPopover, PROJECT_METADATA_POPOVER } from 'store/metadataPopover';

import useSaveProjectOnLightbox from './hooks/useSaveProjectOnLightbox';
import useDefaultFormData from './hooks/useDefaultFormData';
import useOpenProjectFromUrl from './hooks/useOpenProjectFromUrl';
import useRoadmapMetadataProjectLightbox from './hooks/useRoadmapMetadataProjectLightbox';
import CancelContinueToast from 'components/CancelContinueToast';
import usePermissions from 'hooks/permissions/usePermissions';

import useProjectFieldsConfiguration from 'hooks/configuration/useProjectFieldsConfiguration';
import useMetadataForOptions from 'hooks/metadata/useMetadataForOptions';
import isOldLightboxActive from './utils/isOldLightboxActive';
import { getSelectedRoadmapVersion } from 'store/roadmapVersions/selectors';
import useLightboxProjectIntegrations from 'containers/ProjectLightBox/hooks/useLightboxProjectIntegrations';
import { MILESTONE } from 'constants/projects';
import { getPhaseByMetadataRoadmap } from 'utils/metadataRoadmaps';
import makeGetParentProject from 'utils/makeGetParentProject';
import useFormatRoadmapsForDropdown from './hooks/useFormatRoadmapsForDropdown';
import useOrganizationsAccessControl from 'src/hooks/useOrganizationsAccessControl';
import useLightboxRequiredFields from './hooks/useLightboxRequiredFields';
import useProjectLightboxTabs from './hooks/useProjectLightboxTabs';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { getDefaultAutoHealth } from 'utils/projects/healthUtils';
import { groupByTeamHierarchy } from 'shared/helpers/entities/users';
import { cleanTimeframesL1ParentId, cleanTimeframesL1ParentIdHierarchy } from 'utils/timeframes';

const defaultToNull = defaultTo(null);

/**
 * @function getDefaultLayer get a default layer based on selectedProject or projectDefaultData with a fallback of IDEA_LAYER
 * @param  {Object} selectedProject    Data if an existing project is selected
 * @param  {Object} projectDefaultData Default data for a new un-existing project
 * @return {String} Default project layer
 */
const getDefaultLayer = (selectedProject = {}, projectDefaultData = {}) =>
  selectedProject?.layer ?? projectDefaultData?.layer ?? IDEA_LAYER;

/**
 * @function shouldUpdateFormData verify if it should update the form data of a given project opened on the Lightbox
 * @param  {Object} formDataRef Ref of the form data
 * @param  {Object} data        Data with possible changes
 * @param  {Boolean} isOpen     If lightbox is open or not
 * @return {Boolean} Return true if it should update the form data
 */
const shouldUpdateFormData = (formDataRef, data, isOpen) => !isEqual(formDataRef.current, data) && isOpen;

/**
 * @function hasLayerChanges verify if there was a change on the layer value of the data
 * @param  {Object} data  Data with possible layer change
 * @param  {Object} layerRef Ref of the layer
 * @return {Boolean} Return true if the layer value has been changed
 */
const hasLayerChanges = (data, layerRef) => data.layer && !isEqual(layerRef.current, data.layer);

/**
 * @function timeframesCorpFilter
 *
 * Filter timeframes corp level
 *
 * @param  {Array} timeframes
 * @return {Array}
 */
const timeframesCorpFilter = timeframes => timeframes.filter(t => t.level !== METADATA_LEVELS.LEVEL_CORP);

const ROADMAP_TITLE_KEY = 'roadmapTitle';
const SUB_ROADMAP_TITLE_KEY = 'product1Title';

const PARENT_ID = 'parent_id';
const TITLE = 'title';

const hasParentIdChanges = has(PARENT_ID);
const hasTitleChanges = has(TITLE);

const defaultToEmptyObject = defaultTo({});
const defaultToZero = defaultTo(0);

const getCustomerRequestsCount = pipe(defaultToEmptyObject, prop('customerRequestsCount'), defaultToZero);

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [oldLightboxActive, setOldLightboxActive] = useState(isOldLightboxActive());

    const { canView, canUpdate, canCreate } = usePermissions();

    const hasTimeframeCorpLevel = canView(PERMISSION_RESOURCES.timeframeCorp);
    const { isChildDragon } = useOrganizationsAccessControl();

    const selectedProject = useSelector(getSelectedProjectOnLigthbox);
    const parentProject = useSelector(getParentProjectData);
    const activeTabOnOpen = useSelector(state => state.projectLightbox.activeTabOnOpen);
    const projectDefaultData = useSelector(state => state.projectLightbox.defaultData);
    const organization = useSelector(state => getOrganization(state));
    const recentlyUsedData = useSelector(state => state.projects.recentlyUsedData);
    const currentUser = useSelector(state => state.login.currentUser);
    const loading = useSelector(state => state.projectLightbox.loadingData);
    const isOpen = useSelector(state => state.projectLightbox.lightboxOpen);
    const [initiatives, bets, hasHierarchy, hasBet] = useGetInitiativesAndBets();
    const objectives = useSelector(state => getObjectives(state));
    const phases = useSelector(state => getPhases(state));
    const themes = useSelector(state => getThemes(state));
    const priorities = useSelector(state => getPriorities(state));
    const categories = useSelector(state => getCategories(state));
    const personas = useSelector(state => selectPersonasData(state));
    const lifecycles = useSelector(state => selectLifecyclesData(state));
    const customers = useSelector(state => getAllCustomers(state));
    const tags = useSelector(state => getAllTags(state));
    const timeframesCorp = useSelector(state => getTimeframesLevelCorp(state));
    const timeframesFromStore = useSelector(getAllTimeframesSelector);
    const products = useSelector(state => getProducts(state, false, false));
    const products2 = useSelector(state => getProductsLevelTwo(state, false));
    const keyResults1 = useSelector(selectKeyResults1);
    const customFields = useSelector(state => getProjectsCustomFields(state));
    const users = useSelector(getUsers);
    const [getSystemFieldName] = useSystemFields();
    const selectedRoadmapVersion = useSelector(state => getSelectedRoadmapVersion(state));
    const selectedScenario = useSelector(selectScenarioSelected);
    // in the future we will need only the selectedScenario
    const scenario = selectedRoadmapVersion || selectedScenario;
    const filters = useSelector(state => getPageFilters(state));
    const isMilestone = useRef(false);
    const layer = useRef(getDefaultLayer(selectedProject, projectDefaultData));
    const hasMetadataRoadmaps = organization.enable_metadata_roadmaps;
    const hasPersonas = canView(PERMISSION_FEATURES.personas);
    const hasLifecycles = canView(PERMISSION_FEATURES.lifecycles);
    const disabledFields = useSelector(selectDisabledFields);

    const cloneProjectToast = useRef(null);

    const getGrandParentProject = makeGetParentProject(initiatives, bets);
    const grandParentProject = getGrandParentProject(parentProject?.parent_id, parentProject?.layer);

    const { activeTab, changeTab } = useProjectLightboxTabs(activeTabOnOpen);

    const {
      filteredOptions: { roadmaps, roadmapCorps: roadmapsCorp },
    } = useMetadataForOptions({ metadataKeys: [ROADMAPS_CORP, ROADMAPS] });

    const [formDirtyFields, setFormDirtyFields] = useState({});

    const timeframes = useMemo(
      () => (hasTimeframeCorpLevel ? timeframesFromStore : timeframesCorpFilter(cleanTimeframesL1ParentId(timeframesFromStore))),
      [timeframesFromStore],
    );

    const autoFillData = useMemo(() => {
      const recentlyUsedRoadmapTitle = prop(ROADMAP_TITLE_KEY, recentlyUsedData);

      const recentlyUsedRoadmap = recentlyUsedRoadmapTitle && roadmaps.find(propEq(TITLE, recentlyUsedRoadmapTitle));

      return {
        [ROADMAP_TITLE_KEY]: recentlyUsedRoadmap?.title,
        owner_id: projectDefaultData?.owner_id ?? currentUser.id,
        ...(projectDefaultData || {}),
      };
    }, [recentlyUsedData, currentUser, projectDefaultData, roadmaps]);

    const defaultFormData = useDefaultFormData(layer.current, isMilestone.current, hasMetadataRoadmaps);

    const _getDefaultData = () => ({
      ...defaultFormData,
      ...customFields.reduce((obj, cf) => {
        obj[cf.key] = cf.default_value;
        return obj;
      }, {}),
      ...autoFillData,
      ...getDefaultAutoHealth(layer.current, organization),
    });

    const defaultDataRef = useRef(_getDefaultData());

    const [formData, setFormData] = useState(_getDefaultData());
    // phaseDirty tracks if we need to adjust the phase based on roadmap change (used when hasMetadataRoadmaps turned on)
    const [phaseDirty, setPhaseDirty] = useState(false);
    const formDataRef = useRef(formData);
    // Updates on Component
    const pickedProjectKeys = useMemo(() => pick(selectedProject, Object.keys(_getDefaultData())), [selectedProject]);

    formDataRef.current = formData;

    const isNew = useMemo(() => !selectedProject, [selectedProject]);

    const isAllowedToCreateProject = canCreate(PERMISSION_RESOURCES.project, { project: { layer: formData.layer } });
    const isAllowedToEditProject = canUpdate(PERMISSION_RESOURCES.project, { project: selectedProject });

    const userCanEdit = useMemo(() => {
      if (isNew) {
        return isAllowedToCreateProject;
      }

      return isAllowedToEditProject;
    }, [isNew, isAllowedToCreateProject, isAllowedToEditProject]);

    const _updateFormData = data => {
      if (data.phaseTitle) {
        setPhaseDirty(true);
      }

      if (shouldUpdateFormData(formDataRef, data, isOpen)) {
        const needsPhaseUpdate =
          !data.phaseTitle && (ROADMAP_TITLE_KEY in data || SUB_ROADMAP_TITLE_KEY in data) && hasMetadataRoadmaps;

        if (needsPhaseUpdate && !phaseDirty) {
          let phase;

          if (data.roadmapTitle || data.product1Title) {
            const roadmap = roadmaps.find(r => r.title === data.roadmapTitle);
            const subroadmap = data.product1Title ? products.find(p => p.title === data.product1Title) : {};

            if (roadmap) {
              phase = getPhaseByMetadataRoadmap(phases, roadmap.id, subroadmap?.id);
            }
          }

          data.phaseTitle = phase ? phase.title : defaultDataRef.current?.phaseTitle;
        }

        setFormData({ ...formDataRef.current, ...data });
      }

      if (hasLayerChanges(data, layer)) {
        layer.current = data.layer;
      }

      if (isNil(selectedProject) && hasTitleChanges(data)) {
        setFormDirtyFields(prevValue => ({ ...prevValue, title: true }));
      }

      /**
       * Make parent selection visible on the lightbox when the project is
       * not created yet.
       *
       * Dispatch as null by default in order to support the unset parent user action.
       */
      if (isNil(selectedProject) && hasParentIdChanges(data)) {
        dispatch(setCurrentProjectParent(defaultToNull(data.parent)));
      }

      isMilestone.current = data.type === MILESTONE;
    };

    const jiraFieldSyncCallback = useCallback(
      (res, fieldsToSync) => {
        if (formDataRef.current.id) {
          _updateFormData(pick(res, fieldsToSync));
        }
      },
      [formDataRef?.current?.id, _updateFormData],
    );

    const { projectIntegrations, gettingDataFields } = useLightboxProjectIntegrations(
      selectedProject,
      true,
      jiraFieldSyncCallback,
    );

    const _onSwitchLayout = useCallback(() => {
      const actualLocalStorageValue = isOldLightboxActive();

      localStorage.setItem('isOldLightboxActive', !actualLocalStorageValue);
      setOldLightboxActive(!actualLocalStorageValue);
    });

    const filterFieldName = Object.keys(filters.quickFilters || [''])[0];
    const filterValue = filters.quickFilters && filters.quickFilters[filterFieldName];
    const metadataRoadmapOptions = useRef({});

    let fieldsConcat = {
      roadmaps,
      products,
      products2,
      objectives,
      keyResults: keyResults1,
      themes,
      timeframes,
      phases,
      categories,
      priorities,
      tags,
      customers,
    };

    if (filterFieldName === 'planningStages') {
      fieldsConcat = { ...fieldsConcat, planningStages: filterValue };
    }

    if (fieldsConcat[filterFieldName] && filterValue.length) {
      let value;

      if (filterFieldName === 'planningStages') {
        [value] = filterValue;
      } else {
        value = fieldsConcat[filterFieldName].find(f => f.id === +filterValue[0]);
      }

      if (value) {
        switch (filterFieldName) {
          case 'roadmaps':
            autoFillData.roadmapTitle = value.title;

            if (hasMetadataRoadmaps) {
              const { id: roadmapId } = value;
              const phase = getPhaseByMetadataRoadmap(phases, roadmapId);

              if (phase) {
                autoFillData.phaseTitle = phase.title;
              }
            }
            break;
          case 'products':
            const roadmap = roadmaps.find(roadmap => {
              return (roadmap.products || []).findIndex(p => p.title === value.title) > -1;
            });

            autoFillData.roadmapTitle = roadmap?.title;
            autoFillData.product1Title = value.title;

            if (hasMetadataRoadmaps) {
              const { id: subRoadmapId } = value;
              const phase = getPhaseByMetadataRoadmap(phases, roadmap.id, subRoadmapId);

              if (phase) {
                autoFillData.phaseTitle = phase.title;
              }
            }
            break;
          case 'products2':
            const product2 = products2.find(p2 => p2.title === value.title);
            const product2Roadmap = roadmaps.find(roadmap => roadmap.id === product2.roadmap_id);
            const product1 = products.find(p1 => p1.id === product2.parent_id);

            autoFillData.roadmapTitle = product2Roadmap?.title;
            autoFillData.product1Title = product1?.title;

            if (hasMetadataRoadmaps && product2) {
              const phase = getPhaseByMetadataRoadmap(phases, product2Roadmap.id, null, product2.id);

              if (phase) {
                autoFillData.phaseTitle = phase.title;
              }
            }
            break;
          default:
            return;
        }
      }
    }

    const hasKeyResults = organization.has_key_results;
    const hasKeyResults2 = organization.has_key_results && organization.has_key_results_2;
    const hasProducts = organization.has_products;
    const hasProducts2 = organization.has_products_2;
    const hasTeams2 = organization.has_teams_2;

    const _onClose = async () => {
      await dispatch(closeProjectLightbox());
      defaultDataRef.current = _getDefaultData();
      setFormData(_getDefaultData());
      layer.current = IDEA_LAYER;
      isMilestone.current = false;
    };

    useOpenProjectFromUrl(isOpen, selectedProject);

    metadataRoadmapOptions.current = useRoadmapMetadataProjectLightbox(
      hasMetadataRoadmaps,
      hasKeyResults2,
      selectedProject || {},
      roadmaps,
      roadmapsCorp,
      { roadmapTitle: formDataRef.current.roadmapTitle, subRoadmapTitle: formDataRef.current.product1Title },
    );

    const { filteredOptions } = useMetadataForOptions({ metadataKeys: [TIMEFRAMES, TEAMS] });

    const usersOptions = useMemo(
      () => groupByTeamHierarchy(users, { hasTeams2, getSystemFieldName }),
      [users, hasTeams2, getSystemFieldName],
    );

    const timeframesOptionsCleaned = useMemo(() => {
      const optionsToWorkWith = hasMetadataRoadmaps ? metadataRoadmapOptions.current.timeframes : filteredOptions.timeframes;

      return cleanTimeframesL1ParentIdHierarchy(optionsToWorkWith);
    }, [hasMetadataRoadmaps, metadataRoadmapOptions?.current?.timeframes, filteredOptions.timeframes]);

    const metadata = {
      timeframesCorp,
      timeframeTitles: timeframes.map(({ title }) => title),
      phaseTitles: phases.map(({ title }) => title),
      themeTitles: themes.map(({ title }) => title),
      priorities: hasMetadataRoadmaps ? metadataRoadmapOptions.current.priorities : priorities,
      priorityTitles: priorities.map(({ title }) => title),
      categoryTitles: categories.map(({ title }) => title),
      customerOptions: metadataRoadmapOptions.current.customers,
      customers,
      tags,
      tagOptions: metadataRoadmapOptions.current.tags,
      customFields,
      initiatives,
      bets,
      objectivesCorp: metadataRoadmapOptions.current.objectivesCorp,
      objectiveOptions: metadataRoadmapOptions.current.objectives,
      objectiveOptions2: hasMetadataRoadmaps
        ? metadataRoadmapOptions.current.objectiveOptions2
        : metadataRoadmapOptions.current.objectives,
      objectiveTitles: objectives.map(({ title }) => title),
      timeframes,
      timeframesOptions: timeframesOptionsCleaned,
      phases: hasMetadataRoadmaps ? metadataRoadmapOptions.current.phases : phases,
      themes: hasMetadataRoadmaps ? metadataRoadmapOptions.current.themes : themes,
      categories: hasMetadataRoadmaps ? metadataRoadmapOptions.current.categories : categories,
      users,
      usersOptions,
      roadmapsCorp,
      roadmapsOptions: useFormatRoadmapsForDropdown(roadmaps, roadmapsCorp, hasProducts, hasProducts2),
      personas,
      personasOptions: metadataRoadmapOptions.current.personas,
      lifecycles,
      lifecyclesOptions: metadataRoadmapOptions.current.lifecycles,
      teamsOptions: filteredOptions.teams,
    };

    const { projectsFieldsLayout: fieldsConfiguration } = useProjectFieldsConfiguration(
      selectedProject?.layer ?? formData?.layer,
      oldLightboxActive,
    );

    const customerRequestsCount = useMemo(() => getCustomerRequestsCount(formData), [formData]);

    const _onDeleteProject = () => {
      dispatch(deleteProjects([formData.id]));
      _onClose();
    };
    const _onArchiveProject = () => _onSave(false, true, { id: formData.id, phaseTitle: 'Archived' }, false, true);
    const [saveProject] = useSaveProjectOnLightbox(formData, selectedProject, _onClose, _updateFormData);

    const {
      onSaveWithValidation: _onSave,
      fieldValidation,
      isRequiredFieldsLightboxVisible,
      closeRequiredFieldsLightbox,
    } = useLightboxRequiredFields(selectedProject || formData, selectedProject?.layer ?? formData?.layer, saveProject);

    const _saveOrClose = (...args) => {
      return userCanEdit && selectedProject && selectedProject.id ? _onSave(...args) : _onClose(...args);
    };
    const continueAndSave = useCallback(() => {
      saveProject(true, true, formDataRef.current);
    }, [formData, saveProject]);

    const _onCloneProject = async (includes = {}) => {
      try {
        cloneProjectToast.current = toast(CLONING_PROJECT_MESSAGE);

        const {
          action: { payload: clonedProject },
        } = await dispatch(cloneProject(selectedProject.id, includes));

        await _onClose();

        await dispatch(openProjectLightbox(clonedProject.id));
      } catch (e) {
        toast(getCloneProjectError(selectedProject?.layer, getSystemFieldName));
      } finally {
        toast.dismiss(cloneProjectToast?.current);
      }
    };

    const _uploadFile = async file => {
      const upload = async () => {
        if (file.size / 1024 / 1024 > 5) {
          toast('Exceeded file size of 5MB');
          return;
        }

        try {
          let newProject;

          if (!selectedProject) {
            newProject = await _onSave(false, false);
          } else {
            newProject = selectedProject;
          }

          const result = await useUploadFileAction(file, 'projects', newProject);

          if (result) dispatch(addFileToProject(newProject, result));
        } catch (err) {
          toast(getFailedFileUploadErrorMessage(err));
        }
      };

      if (selectedProject && selectedProject.files && selectedProject.files.length >= 3) {
        toast(
          <CancelContinueToast
            title="You may attach 3 file (up to 5MB) per item.
              If you upload a new file, you’ll lose access to the currently attached file."
            handleContinue={upload}
            handleCancel={() => toast.dismiss()}
          />,
          {
            autoClose: 10000,
          },
        );
      } else {
        upload();
      }
    };

    const _getFileLink = async fileId => {
      const url = await useUploadedFileGetLink(fileId, 'projects', selectedProject.id);

      return url;
    };

    const _handleDeleteLink = async file => {
      dispatch(removeFileFromProject(file, selectedProject));
    };

    function _handlePasteEvt(event) {
      event.preventDefault();
      if (!event.clipboardData.files.length) {
        return;
      }
      const file = event.clipboardData.files[0];

      _uploadFile(file);
    }

    const _pasteListener = action => {
      switch (action) {
        case 'add':
          document.addEventListener('paste', _handlePasteEvt);
          break;
        case 'remove':
          document.removeEventListener('paste', _handlePasteEvt);
          break;
        default:
          break;
      }
    };

    const _openMetadataDrawer = useCallback(metadataSelected => {
      dispatch(openMetadataPopover(PROJECT_METADATA_POPOVER, metadataSelected));
    }, []);

    useEffect(() => {
      return () => {
        document.removeEventListener('paste', _handlePasteEvt);
      };
    });

    useDeepEffect(() => {
      // if selectedProject already exists and has a phase title,
      // we should set field to dirty to prevent override when roadmap is selected
      if (selectedProject?.id && selectedProject?.phaseTitle) {
        setPhaseDirty(true);
      }

      const newData =
        selectedProject && formData.id !== selectedProject.id
          ? pickedProjectKeys
          : { ...(formData && formData.id ? formData : {}), ...pickedProjectKeys };

      _updateFormData(newData);
    }, [pickedProjectKeys]);

    useEffect(() => {
      if (isOpen && !selectedProject) {
        layer.current = projectDefaultData?.layer || IDEA_LAYER;
        isMilestone.current = false;

        setFormData(_getDefaultData());
        setFormDirtyFields(autoFillData?.title ? { title: true } : {});
      }
    }, [isOpen]);

    return (
      <Component
        {...props}
        formDirtyFields={formDirtyFields}
        loading={loading}
        gettingDataFields={gettingDataFields}
        selectedProject={selectedProject}
        parentProject={parentProject}
        grandParentProject={grandParentProject}
        isOpen={isOpen}
        userCanEdit={userCanEdit}
        onClose={_saveOrClose}
        hasHierarchy={hasHierarchy}
        hasBet={hasBet}
        projectIntegrations={projectIntegrations}
        getSystemFieldName={getSystemFieldName}
        hasKeyResults={hasKeyResults}
        hasKeyResults2={hasKeyResults2}
        hasMetadataRoadmaps={hasMetadataRoadmaps}
        hasPersonas={hasPersonas}
        hasLifecycles={hasLifecycles}
        metadata={metadata}
        formData={formData}
        updateFormData={_updateFormData}
        currentTab={!loading ? activeTab : null}
        setCurrentTab={changeTab}
        onSave={_onSave}
        onCloneProject={_onCloneProject}
        onArchiveProject={_onArchiveProject}
        onDeleteProject={_onDeleteProject}
        pasteListener={_pasteListener}
        getFileLink={_getFileLink}
        handleDeleteLink={_handleDeleteLink}
        uploadFile={_uploadFile}
        files={selectedProject?.files || []}
        fieldsConfiguration={fieldsConfiguration}
        oldLightboxActive={oldLightboxActive}
        onSwitchLayout={_onSwitchLayout}
        selectedRoadmapVersion={scenario}
        openMetadataDrawer={_openMetadataDrawer}
        fieldValidation={fieldValidation}
        isRequiredFieldsLightboxVisible={isRequiredFieldsLightboxVisible}
        closeRequiredFieldsLightbox={closeRequiredFieldsLightbox}
        continueAndSave={continueAndSave}
        isChildDragon={isChildDragon}
        customerRequestsCount={customerRequestsCount}
        closeLightbox={_onClose}
        disabledFields={disabledFields}
      />
    );
  };
};

export default componentHOC;
