import React from 'react';

import { Button, TextField } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import useAddNewProject from './hooks/useAddNewProject';

const AddNewProject = props => {
  const { onAddNewProjectKey, disabled } = props;

  const { isAddingNewProject, onChangeHandler, onKeyDownHandler, onCancel, onClickAddNewProject } =
    useAddNewProject(onAddNewProjectKey);

  return isAddingNewProject ? (
    <TextField label="Project key" autoFocus onChange={onChangeHandler} onKeyDown={onKeyDownHandler} onBlur={onCancel} />
  ) : (
    <Button id="add-mapping-button" color="primary" onClick={onClickAddNewProject} disabled={disabled}>
      <AddIcon />
    </Button>
  );
};

export default AddNewProject;
