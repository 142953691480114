import React from 'react';
import styled from 'styled-components';
import Chatbot from 'react-chatbot-kit';

import Text from 'design-system/atoms/Text/index';

import ActionProvider from './ActionProvider';
import MessageParser from './MessageParser';
import AsyncMessageLoader from './AsyncMessageLoader';
import chatStyles from './chatStyles';

const config = {
  initialMessages: [],
  botName: null,
  customMessages: {
    // Forces a loader in messages for async bot calls, remove when message is returned
    asyncMessageLoader: props => <AsyncMessageLoader {...props} />,
  },
  customComponents: {
    header: () => (
      <HeaderContainer className="react-chatbot-kit-header">
        <StyledText as="h2">I can help you get insights and reports of your product portfolio.</StyledText>
      </HeaderContainer>
    ),
  },
};

const Chat = () => {
  return (
    <Container>
      <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
        placeholderText="Type your question here"
      />
    </Container>
  );
};

export default Chat;

const Container = styled.div`
  ${chatStyles}
`;

const HeaderContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.palette.white};
  font-size: ${({ theme }) => theme.typography.fontSizeLargeRem}rem;
  line-height: ${({ theme }) => theme.typography.lineHeightRegularSmallRem};
  max-width: 400px;
`;
