import { useSelector } from 'react-redux';

import { getHasPDLC } from 'store/organization';

import UpsellPdlc from 'containers/UpsellFeatures/UpsellPdlc';
import UpsellScenarioChart from 'containers/UpsellFeatures/UpsellScenarioChart';

import { FORECAST_CHART_PAGE, IDEAS_PDLC_PAGE, SCENARIO_CHART_PAGE } from 'constants/filters';
import usePermissions from './permissions/usePermissions';
import { PERMISSION_PAGES } from './permissions/usePermissions/constants';

const useUpsellPage = pageId => {
  const { canView } = usePermissions();

  const hasPdlc = useSelector(getHasPDLC);
  const hasBenefitChart = canView(PERMISSION_PAGES[SCENARIO_CHART_PAGE]);

  switch (pageId) {
    case IDEAS_PDLC_PAGE: {
      if (hasPdlc) return null;
      return UpsellPdlc;
    }
    case SCENARIO_CHART_PAGE:
    case FORECAST_CHART_PAGE: {
      if (hasBenefitChart) return null;
      return UpsellScenarioChart;
    }

    default:
      return null;
  }
};

export default useUpsellPage;
