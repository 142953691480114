import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getCustomerRequestFormData } from 'store/customerRequests/selectors';
import { getCustomerRequestComments } from 'store/comments/selectors';

import {
  saveCustomerRequestComment as saveCustomerRequestCommentAction,
  fetchCustomerRequestComments as fetchCustomerRequestCommentsAction,
  fetchNextCustomerRequestComments as fetchNextCustomerRequestCommentsAction,
  deleteCustomerRequestComment as deleteCustomerRequestCommentAction,
  editCustomerRequestComment as editCustomerRequestCommentAction,
} from 'store/comments/actions';

import { addUserToCustomerRequestWatchers as addUserToCustomerRequestWatchersAction } from 'store/customerRequests/actions';

export default Component => {
  return props => {
    const dispatch = useDispatch();
    const formData = useSelector(getCustomerRequestFormData);
    const commentsData = useSelector(state => getCustomerRequestComments(state, formData.id));

    const hasMoreComments = commentsData && commentsData._meta && !!commentsData._meta.next;
    const comments = commentsData && (commentsData.data || []);

    const addCommentToCustomerRequest = async data => {
      await dispatch(saveCustomerRequestCommentAction(formData.id, data));
      dispatch(addUserToCustomerRequestWatchersAction(formData.id, props.currentUser));
    };

    const onFetchNextPageHandler = () => dispatch(fetchNextCustomerRequestCommentsAction(formData.id));

    const onDeleteCommentHandler = useCallback(
      commentId => {
        dispatch(deleteCustomerRequestCommentAction(formData.id, commentId));
      },
      [formData.id],
    );

    const onEditCommentHandler = useCallback(
      (commentId, data) => {
        return dispatch(editCustomerRequestCommentAction(formData.id, commentId, data));
      },
      [formData.id],
    );

    useEffect(() => {
      if (formData?.id) {
        dispatch(fetchCustomerRequestCommentsAction(formData.id));
      }
    }, [formData?.id]);

    return (
      <Component
        {...props}
        comments={comments}
        addComment={addCommentToCustomerRequest}
        hasMoreComments={hasMoreComments}
        onFetchNextPage={onFetchNextPageHandler}
        onDeleteComment={onDeleteCommentHandler}
        onEditComment={onEditCommentHandler}
      />
    );
  };
};
