import { sort, toPairs } from 'ramda';

import sortByIntegerRank from 'utils/sortByIntegerRank';

const exist = Boolean;

export default (options, showArchived = false) => {
  const optionsArray = toPairs(options);

  const sortPairsByValues = ([, firstValue], [, secondValue]) => sortByIntegerRank(firstValue, secondValue);

  const sortedArray = sort(sortPairsByValues, optionsArray);

  const result = sortedArray
    .map(([key, value]) => ({ ...value, key }))
    .filter(option => (exist(showArchived) ? true : !option.archived));

  return result;
};
