import React from 'react';
import styled from 'styled-components';

import secondaryColors from './secondaryColors';
import ChromePicker from '../ReactColor/ChromePicker';

export default (props) => (
  <Popover>
    <Wrapper onClick={() => { props.handleClose(); }} />
    <Picker
      color={props.color}
      colors={secondaryColors}
      onChange={props.onChange}
    />

  </Popover>
);

const Popover = styled.div`
  position: absolute;
  z-index: 100000;
`;


const Wrapper = styled.div`
  // .chrome-picker > div:nth-child(1) {
  //   display: none!important;
  // }
      position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
`;

const Picker = styled(ChromePicker)`
  position: fixed;
`;
