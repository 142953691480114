import { useSelector } from 'react-redux';
import { useCallback } from 'react';

import shouldAllowAddingNewMetadata from 'utils/shouldAllowAddingNewMetadata';

const useAllowToCreateNewMetadata = () => {
  const { currentUser } = useSelector(state => state.login);
  const { organization } = useSelector(state => state.organization);

  const shouldAllowToCreateMetadata = useCallback(
    (metadata, addNew = true) => shouldAllowAddingNewMetadata(currentUser, organization, addNew)(metadata),
    [currentUser, organization],
  );

  return shouldAllowToCreateMetadata;
};

export default useAllowToCreateNewMetadata;
