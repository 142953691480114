import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import {  syncJiraStoriesAllTime, unlinkWithJiraTicket } from 'store/integrations';
import { getProjectIntegrationProgress } from 'store/projectLightbox';

export default Component => {
  return props => {
    const dispatch = useDispatch();

    const [isUpdatingJiraProgress, setIsUpdatingJiraProgress] = useState(false);

    const syncJiraStoriesAllTimeAction = (...args) => dispatch(syncJiraStoriesAllTime(...args));
    const unlinkWithJiraTicketAction = (...args) => dispatch(unlinkWithJiraTicket(...args));
    const getProjectIntegrationProgressAction = (...args) => dispatch(getProjectIntegrationProgress(...args));

    const unlinkJira = async epicKey => {
      const { updateProject } = props;

      await updateProject();

      return unlinkWithJiraTicketAction(epicKey, props.integration.id);
    };

    const syncStoriesAllTime = async () => {
      setIsUpdatingJiraProgress(true);
      await syncJiraStoriesAllTimeAction(props.integration.id, [props.epic.key]);
      getProjectIntegrationProgressAction(props.selectedProject.id);
    };

    useEffect(() => {
      setIsUpdatingJiraProgress(false);
    }, [props.selectedProject.integrationProgress]);

    return (
      <Component
        unlinkJira={unlinkJira}
        syncStoriesAllTime={syncStoriesAllTime}
        isUpdatingJiraProgress={isUpdatingJiraProgress}
        {...props}
      />
    );
  };
};
