import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const RallyIcon = props => {
  const { size = 20, marginRight } = props;

  return (
    <SvgIcon style={{ width: size, height: size, marginRight }} viewBox="0 0 256 256">
      <g transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)" fill="#e53e4b" stroke="none">
        <path
          d="M1095 2526 c-491 -82 -872 -410 -1019 -876 -87 -275 -71 -596 44
            -863 203 -472 650 -767 1160 -767 584 0 1078 387 1225 960 35 139 45 349 21
            492 -87 526 -477 933 -1001 1043 -107 23 -327 28 -430 11z m322 -267 c327 -45
            619 -265 764 -573 63 -135 83 -235 83 -411 0 -161 -12 -230 -60 -354 -49 -123
            -113 -219 -219 -327 -144 -147 -284 -230 -470 -280 -100 -27 -368 -27 -475 0
            -98 25 -212 73 -286 121 -93 60 -271 247 -325 340 -102 175 -133 293 -133 500
            0 176 20 276 83 411 189 402 607 633 1038 573z"
        />
        <path
          d="M1181 2140 c-305 -43 -566 -247 -680 -530 -49 -123 -64 -223 -58
            -374 6 -155 28 -240 92 -366 46 -87 159 -230 213 -269 l31 -21 -29 57 c-39 80
            -54 153 -53 255 3 264 194 497 463 564 92 23 231 15 329 -19 329 -115 493
            -493 348 -797 l-24 -49 28 20 c47 33 142 144 182 210 148 251 165 549 47 833
            -81 195 -202 321 -393 412 -140 66 -339 96 -496 74z"
        />
        <path
          d="M1186 1349 c-21 -5 -70 -26 -107 -45 -150 -79 -249 -243 -249 -414 0
            -124 40 -227 124 -318 36 -39 126 -110 126 -99 0 3 -7 21 -15 40 -50 120 26
            268 160 314 129 43 276 -44 305 -182 11 -55 7 -90 -17 -138 -19 -38 -11 -44
            30 -18 51 31 132 121 162 181 124 245 11 566 -232 660 -65 26 -219 36 -287 19z"
        />
      </g>
    </SvgIcon>
  );
};

export default RallyIcon;
