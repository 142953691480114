import React, { createContext, useState, useContext } from 'react';
import CommentsLightbox from 'containers/CommentLightbox';

const CommentsLightboxContext = createContext({});

const CommentsLightboxProvider = ({ children }) => {
  const [commentsLightbox, setCommentsLightbox] = useState();

  const openCommentsLightbox = data => setCommentsLightbox(data);
  const closeCommentsLightbox = () => setCommentsLightbox(null);

  return (
    <CommentsLightboxContext.Provider value={{ openCommentsLightbox }}>
      {children}
      <CommentsLightbox
        isOpen={!!commentsLightbox}
        onClose={closeCommentsLightbox}
        projectId={commentsLightbox && commentsLightbox.projectId}
        field={commentsLightbox && commentsLightbox.field}
      />
    </CommentsLightboxContext.Provider>
  );
};

/**
 * @function useCommentsLightboxContext
 *
 * hook to use the comments lightbox context
 * needs to be wrapped on CommentsLightboxProvider
 *
 * @return {Object}
 */
const useCommentsLightboxContext = () => useContext(CommentsLightboxContext);

export { CommentsLightboxProvider };

export default useCommentsLightboxContext;
