const afterProcessClipboardDataFactory = (bulkCreate, bulkUpdate) => {
  return async (changesToDo, socketRoom) => {
    if (
      !changesToDo ||
      ((!changesToDo.create || !changesToDo.create.length) && (!changesToDo.update || !changesToDo.update.length))
    ) {
      return;
    }

    const bulks = [];

    if (changesToDo.create && changesToDo.create.length) {
      bulks.push(bulkCreate(changesToDo.create, socketRoom));
    } else if (changesToDo.update && changesToDo.update.length) {
      bulks.push(bulkUpdate(changesToDo.update[0], socketRoom));
    }

    return Promise.all(bulks);
  };
};

export { afterProcessClipboardDataFactory };
