import {
  ADD_METRIC_VALUE_WITHOUT_SAVE_LIGHTBOX,
  REMOVE_UNSAVED_METRIC_VALUES_LIGHTBOX,
  RESET_DATA,
} from 'features/MetricsDialog/store/types';

export const addMetricValueWithoutSave = (metricValue, metricId) => ({
  type: ADD_METRIC_VALUE_WITHOUT_SAVE_LIGHTBOX,
  payload: metricValue,
  metricId,
});

export const removeUnsavedMetricValues = () => ({
  type: REMOVE_UNSAVED_METRIC_VALUES_LIGHTBOX,
});

export const resetData = () => ({
  type: RESET_DATA,
});
