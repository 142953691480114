import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { not, prop } from 'ramda';

import { CHILDREN_FILTERS, FILTER_CHILDREN_FIELDS_PROPERTY, GLOBAL_FILTER, QUICK_FILTER_OPTIONS } from 'constants/filters';
import {
  getRoadmaps,
  getCorpRoadmaps,
  getProducts,
  getCorpAccountRoadmap,
  getCrossAccountRoadmaps,
  getProductsLevelTwo,
} from 'store/roadmaps/selectors';
import { getThemes } from 'store/themes/selectors';
import { getObjectives, selectKeyResults1, selectKeyResults2 } from 'store/objectives/selectors';
import { getPriorities } from 'store/priorities/selectors';
import { getCategories } from 'store/categories/selectors';
import { getTimeframes, getTimeframesLevel2 } from 'store/timeframes/selectors';
import { getPhases } from 'store/phases/selectors';
import { getTags } from 'store/tags/selectors';
import { getAllCustomers } from 'store/customers/selectors';
import { getTeams, getTeamsLevel2 } from 'store/teams/selectors';
import { getSkills } from 'store/skills/selectors';
import { getMetrics } from 'store/metrics/selectors';
import useSystemFields from 'hooks/useSystemFields';
import { getUserFilters, getPageFilters, getProjectLayers } from 'store/filters/selectors';

import { getUserName, planningStages, filterActiveItens } from 'utils';
import { createUserFilter, deleteUserFilter, updateUserFilter } from 'store/filters';
import { getCurrentUser } from 'store/login/selectors';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import { setGlobalSearch } from 'store/app';
import usePermissions from 'hooks/permissions/usePermissions';

import parseFilters from 'utils/filters/parseFilters';
import { filterNotAvailableLayersFromFilters } from 'utils/filters/filterNotAvailableLayersFromFilters';

import getFields from './helpers/getFields';
import { METADATA_LEVELS } from 'constants/common';
import { getHasProjectMetrics } from 'store/organization';
import useScenarioToolbarControls from 'containers/ScenarioToolbarControls/hooks/useScenarioToolbarControls';

import getNormalizedOptionsCustomFields from './helpers/getNormalizedOptionsCustomFields';
import useApplyFiltersOnAdvancedSearch from './hooks/useApplyFiltersOnAdvancedSearch';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { HEALTH_FILTERS_LABEL_OPTIONS } from 'constants/projects';
import useCustomUserFields from 'hooks/useCustomUserFields';
import { customUserFieldIds } from 'utils/customUserFields/customUserFields';
import useDbqlAdvancedSearch from 'containers/AdvancedSearchPopover/hooks/useDbqlAdvancedSearch';

import DbqlAdvancedSearchPopoverComponent from 'containers/AdvancedSearchPopover/DbqlAdvancedSearchPopoverComponent';

const exist = Boolean;

const componentHOC = DefaultComponent => {
  return props => {
    const dispatch = useDispatch();
    const [filterToEdit, setFilterToEdit] = React.useState(null);
    const [getSystemFieldName] = useSystemFields();
    const { canView } = usePermissions();

    const { isCreatingOrViewingScenario } = useScenarioToolbarControls();

    const { bottomLayer, topLayer, layers, layerOptions, hasIdea } = useSelector(state => getProjectLayers(state));

    const groomPageFilters = pageFilters => {
      pageFilters = filterToEdit ? filterToEdit.state : pageFilters;
      pageFilters = {
        layer: bottomLayer,
        secondLayer: [],
        children: CHILDREN_FILTERS.noChildren,
        fields: {},
        childrenFields: {},
        ...pageFilters,
        from: null,
        to: null,
        order: null,
      };

      if (not(hasIdea)) {
        filterNotAvailableLayersFromFilters(pageFilters, bottomLayer, layers);
      }

      return pageFilters;
    };

    const { onApplyFilter } = useApplyFiltersOnAdvancedSearch(props.page);

    const corpAccountName = useSelector(state => getCorpAccountRoadmap(state)?.title);

    const crossAccountRoadmaps = useSelector(state => getCrossAccountRoadmaps(state));

    const hasCrossAccountFiltering = canView(PERMISSION_FEATURES.crossBabyRoadmapSupport) && crossAccountRoadmaps.length > 1;

    const {
      customFields,
      hasKeyResults,
      hasKeyResults2,
      hasProducts,
      hasMultiLevelPortfolioMetadata,
      hasTeams2,
      hasProjectMetrics,
      currentUser,
      metadata,
      savedFilters,
      pageFilters,
      globalSearch,
    } = useSelector(state => {
      const categories = getCategories(state, true);
      const keyResults2 = selectKeyResults2(state, true);
      const keyResults = selectKeyResults1(state, true);
      const objectives = getObjectives(state, true);
      const products = getProducts(state, true);
      const products2 = getProductsLevelTwo(state, true);
      const roadmaps = getRoadmaps(state, true);
      const teams = getTeams(state, true);
      const teams2 = getTeamsLevel2(state, true);
      const themes = getThemes(state, true);
      const timeframes = getTimeframes(state, true);
      const timeframes2 = getTimeframesLevel2(state, true);

      return {
        customFields: getProjectsCustomFields(state, true),
        hasKeyResults: state.organization.organization.has_key_results,
        hasKeyResults2: state.organization.organization.has_key_results_2,
        hasProducts: state.organization.organization.has_products,
        hasMultiLevelPortfolioMetadata: state.organization.organization.has_multi_level_portfolio_metadata,
        hasTeams2: state.organization.organization.has_teams_2,
        hasProjectMetrics: getHasProjectMetrics(state),
        currentUser: getCurrentUser(state),
        metadata: {
          roadmapsCorp: getCorpRoadmaps(state, true),
          roadmaps,
          additionalRoadmaps: roadmaps,
          themes,
          additionalThemes: themes,
          objectivesCorp: getObjectives(state, true, METADATA_LEVELS.LEVEL_CORP),
          objectives,
          additionalObjectives: objectives,
          priorities: getPriorities(state, true).map(p => ({
            id: p.id,
            title: p.title,
          })),
          categories,
          additionalCategories: categories,
          timeframes,
          additionalTimeframes: timeframes,
          timeframes2,
          additionalTimeframes2: timeframes2,
          phases: getPhases(state, true),
          tags: getTags(state, true),
          customers: getAllCustomers(state).map(c => ({
            ...c,
            title: c.name,
          })),
          teams,
          additionalTeams: teams,
          teams2,
          additionalTeams2: teams2,
          skills: getSkills(state, true),
          planningStages: planningStages.map(p => ({ id: p, title: p })),
          products,
          products2,
          additionalProducts: products,
          keyResults,
          additionalKeyResults: keyResults,
          keyResults2,
          additionalKeyResults2: keyResults2,
          status_colors: HEALTH_FILTERS_LABEL_OPTIONS.map(p => ({ id: p.value, title: p.label })),
          metrics: getMetrics(state).map(m => {
            return { ...m, title: m.name };
          }),
          resource_team_id: teams,
        },
        savedFilters: getUserFilters(state, props.page).map(f => ({ ...f, title: f.name })),
        pageFilters: groomPageFilters(getPageFilters(state, props.page)),
        globalSearch: state.app.globalSearch,
      };
    });

    const { normalizedOptionCustomFields, filterCustomFields } = useMemo(
      () => ({
        normalizedOptionCustomFields: getNormalizedOptionsCustomFields(customFields),
        filterCustomFields: customFields.filter(f => f.field_type !== 'Formula'),
      }),
      [customFields],
    );

    const childrenFieldsOptions = {
      ...metadata,
      roadmaps: hasCrossAccountFiltering ? crossAccountRoadmaps : metadata.roadmaps,
    };

    const { getCustomUserFieldByFieldKey } = useCustomUserFields();

    const fields = React.useMemo(
      () =>
        getFields(
          getSystemFieldName,
          props.availableFields,
          props.includeCustomFields,
          filterCustomFields,
          hasKeyResults,
          hasKeyResults2,
          hasProducts,
          hasMultiLevelPortfolioMetadata,
          hasTeams2,
          hasProjectMetrics,
          { canView, getCustomUserFieldByFieldKey },
        ),
      [
        props.availableFields,
        customFields,
        hasKeyResults,
        hasKeyResults2,
        hasProducts,
        hasMultiLevelPortfolioMetadata,
        hasTeams2,
        hasProjectMetrics,
        canView,
        getCustomUserFieldByFieldKey,
      ],
    );
    const quickFilters = React.useMemo(
      () => (props.quickFilterOptions ? props.quickFilterOptions : QUICK_FILTER_OPTIONS(getSystemFieldName)),
      [props.quickFilterOptions],
    );

    const { userFilters, staticFilters } = React.useMemo(() => ({
      userFilters: savedFilters.filter(f => !f.is_static),
      staticFilters: savedFilters.filter(f => !!f.is_static),
    }));

    const undefinedOption = {
      id: null,
      title: 'Undefined',
    };

    const doSearch = value => {
      dispatch(setGlobalSearch(value));
    };

    const getMetadataOptionsForField = (field, filterProperty) => {
      if (filterProperty === FILTER_CHILDREN_FIELDS_PROPERTY) {
        return childrenFieldsOptions[field];
      }
      return metadata[field];
    };

    const _getFieldOptions = (field, showArchived, filterProperty) => {
      // lazy load fields
      if (['users', 'created_by', ...customUserFieldIds].includes(field)) return [];

      const normalizedOptions = prop(field, normalizedOptionCustomFields);

      if (exist(normalizedOptions)) {
        const options = showArchived ? normalizedOptions : normalizedOptions.filter(option => !exist(option.archived));

        return [undefinedOption, ...options];
      }

      const fieldOptions = getMetadataOptionsForField(field, filterProperty) || [];

      const metadataOptions = fieldOptions.filter(i => (!showArchived ? filterActiveItens(i) : true));

      return [...(['planningStages'].includes(field) ? [] : [undefinedOption]), ...metadataOptions];
    };

    const _applyFilters = (filters, filterId) => {
      onApplyFilter(parseFilters(filters), filterId);
    };

    const _saveFilterById = (id, name, filters) => {
      const { newFilterData } = props.onSaveFilter(id, name, filters);

      return dispatch(updateUserFilter(id, newFilterData));
    };

    const _deleteFilterById = id => {
      return dispatch(deleteUserFilter(id));
    };

    const _createFilter = async (name, filters) => {
      const { data: addedFilter } = await dispatch(
        createUserFilter({
          name,
          page: props.page || GLOBAL_FILTER,
          state: parseFilters(filters),
          user_id: currentUser.id,
        }),
      );

      setFilterToEdit(addedFilter);
    };
    const _loadUsers = async usersIds => {
      const data = [];
      const params = { id: usersIds.join() };

      if (usersIds.includes('null')) data.push(undefinedOption);

      if (!params.id) return data;

      const { data: resData } = await axios.get('/api/users', { params });

      return data.concat(
        resData.data.map(u => ({
          ...u,
          title: getUserName(u),
        })),
      );
    };
    const _onSavedFilterClick = f => {
      _applyFilters(f.state, f.id);
      if (!f.is_static) setFilterToEdit(f);
    };

    React.useEffect(() => {
      if (!props.anchorEl) {
        setFilterToEdit(null);
        return;
      }
      setFilterToEdit(props.filterToEdit);
    }, [props.filterToEdit, !props.anchorEl]);

    const { isUsingDbqlAdvancedSearch, canViewDbqlAdvancedSearch, setIsUsingDbqlAdvancedSearch } = useDbqlAdvancedSearch({
      pageFilters,
    });

    const Component = isUsingDbqlAdvancedSearch ? DbqlAdvancedSearchPopoverComponent : DefaultComponent;

    return (
      <Component
        {...props}
        fields={fields}
        getFieldOptions={_getFieldOptions}
        pageFilters={pageFilters}
        applyFilters={_applyFilters}
        userFilters={userFilters}
        staticFilters={staticFilters}
        onSaveFilter={_saveFilterById}
        onDeleteFilter={_deleteFilterById}
        onCreateFilter={_createFilter}
        loadUsers={_loadUsers}
        filterToEdit={filterToEdit}
        quickFilters={quickFilters}
        onSavedFilterClick={_onSavedFilterClick}
        globalSearch={globalSearch}
        doSearch={doSearch}
        bottomLayer={bottomLayer}
        topLayer={topLayer}
        layerOptions={layerOptions}
        layers={layers}
        setFilterToEdit={setFilterToEdit}
        hasCopyFilterLink={false}
        isCreatingOrViewingScenario={isCreatingOrViewingScenario}
        hasCrossAccountFiltering={hasCrossAccountFiltering}
        corpAccountName={corpAccountName}
        canViewDbqlAdvancedSearch={canViewDbqlAdvancedSearch}
        setIsUsingDbqlAdvancedSearch={setIsUsingDbqlAdvancedSearch}
      />
    );
  };
};

export default componentHOC;
