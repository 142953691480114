import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCustomerRequests, setCustomerRequestPageLimit } from 'store/customerRequests/actions';
import { getPageLimit, getResultsPage, getTotalResults } from 'store/customerRequests/selectors';

import usePagination from 'hooks/usePagination';

export default function () {
  const dispatch = useDispatch();

  const resultsCount = useSelector(getTotalResults);
  const { current: currentPage, next: nextPage, previous: previousPage } = useSelector(getResultsPage);
  const pageLimit = useSelector(getPageLimit);

  const handleFetchCustomerRequests = useCallback(options => dispatch(fetchCustomerRequests(options)), [dispatch]);

  const handleUpdateCustomerRequestPageLimit = useCallback(limit => dispatch(setCustomerRequestPageLimit(limit)), [dispatch]);

  const { handleChangePage, handleChangePageLimit, indexedCurrentPage, lastPage } = usePagination(
    handleFetchCustomerRequests,
    currentPage,
    nextPage,
    previousPage,
    resultsCount,
    pageLimit,
    handleUpdateCustomerRequestPageLimit,
  );

  return {
    handleChangePage,
    handleChangePageLimit,
    indexedCurrentPage,
    lastPage,
    pageLimit,
    resultsCount,
  };
}
