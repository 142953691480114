import { addListener } from '@reduxjs/toolkit';
import { includes } from 'ramda';

import { FETCH_METRICS_FULFILLED } from 'store/metrics/types';
import { fetchMetricById } from './thunks';

/**
 * Subscribes to some actions to refresh the metric on the Metrics Dialog
 *
 * @returns {Object} the addListener action creator
 */
export const subscribeToRefreshMetricsDialogMetric = () => {
  return addListener({
    matcher: action => includes(action.type, [FETCH_METRICS_FULFILLED]),
    effect: async (action, listenerApi) => {
      // Can cancel other running instances
      listenerApi.cancelActiveListeners();

      const { dispatch, getState } = listenerApi;

      const metricId = getState().metricsDialog.metric?.id;

      if (metricId) {
        dispatch(fetchMetricById(metricId));
      }
    },
  });
};
