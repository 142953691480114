import { isLoading } from 'utils/store/thunk';
import {
  FETCH_MISSION_CONTROL_REQUESTS,
  FETCH_MISSION_CONTROL_OKRS,
  FETCH_MISSION_CONTROL_METRICS,
  FETCH_MISSION_CONTROL_CREATED_REQUESTS,
} from './types';

const EMPTY_ARRAY = [];

const getState = state => state.missionControlHome;

const selectOperationsState = state => getState(state).operations || {};

export const selectMissionControlHomeRequests = state => getState(state).requests || EMPTY_ARRAY;

export const selectIsLoadingMissionControlHomeRequests = state =>
  isLoading(selectOperationsState(state), FETCH_MISSION_CONTROL_REQUESTS);

export const selectMissionControlHomeCreatedRequests = state => getState(state).requestsCreatedByMe || EMPTY_ARRAY;

export const selectIsLoadingMissionControlHomeCreatedRequests = state =>
  isLoading(selectOperationsState(state), FETCH_MISSION_CONTROL_CREATED_REQUESTS);

export const selectMissionControlHomeOkrs = state => getState(state).okrs || EMPTY_ARRAY;

export const selectIsLoadingMissionControlHomeOkrs = state => isLoading(selectOperationsState(state), FETCH_MISSION_CONTROL_OKRS);

export const selectMissionControlHomeMetrics = state => getState(state).metrics || EMPTY_ARRAY;

export const selectMetricById = (state, metricId) =>
  selectMissionControlHomeMetrics(state)?.find(metric => metric?.id === metricId);

export const selectIsLoadingMissionControlHomeMetrics = state =>
  isLoading(selectOperationsState(state), FETCH_MISSION_CONTROL_METRICS);
