// External dependencies
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/HelpOutline';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import styled from 'styled-components';

import Loading from 'design-system/atoms/Loading';
import SmallText from 'design-system/atoms/SmallText';
import { spacing } from 'design-system/theme';
import ShareUrlText from '../../ShareUrlText';
import SendViewToIntegrationChannel from '../../ShareWithUsers/SendViewToIntegrationChannel';
import useHasIntegrationsOfType from 'hooks/integrations/useHasIntegrationsOfType';
import ShareViaIntegrationButton from 'features/Integrations/ShareViaIntegrationButton/ShareViaIntegrationButton';
import { INTEGRATIONS_KEYS } from 'constants/integrations';

const ENTER_KEY_CODE = 13;
const ENTER_KEY_NAME = 'Enter';

export default ({
  publicLinks: links,
  onAddNewLink,
  onUpdateLink,
  onDeleteLink,
  userCanManagePublicLinks,
  handleCloseDialog,
  view,
}) => {
  const [publicLinks, setPublicLinks] = useState([]);
  const [linksWithSlackExpanded, setLinksWithSlackExpanded] = useState([]);
  const [linksWithMicrosoftTeamsExpanded, setLinksWithMicrosoftTeamsExpanded] = useState([]);

  const hasSlackIntegration = useHasIntegrationsOfType(INTEGRATIONS_KEYS.slack);
  const hasMicrosoftTeamsIntegration = useHasIntegrationsOfType(INTEGRATIONS_KEYS.microsoftTeams);

  const toggleSlackExpand = publicLinkId => {
    if (linksWithSlackExpanded.includes(publicLinkId)) {
      setLinksWithSlackExpanded([...linksWithSlackExpanded.filter(linkId => linkId !== publicLinkId)]);
      return;
    }

    setLinksWithSlackExpanded([...linksWithSlackExpanded, publicLinkId]);
  };

  const toggleMicrosoftTeamsExpand = publicLinkId => {
    if (linksWithMicrosoftTeamsExpanded.includes(publicLinkId)) {
      setLinksWithMicrosoftTeamsExpanded([...linksWithMicrosoftTeamsExpanded.filter(linkId => linkId !== publicLinkId)]);
      return;
    }

    setLinksWithMicrosoftTeamsExpanded([...linksWithMicrosoftTeamsExpanded, publicLinkId]);
  };

  const buildPublicLinkFromHash = hash => {
    return `${window.location.origin}/share/${hash}`;
  };

  const changePublicLinkName = (linkId, name) => {
    const newPublickLinks = publicLinks.map(publicLink => {
      if (publicLink.id === linkId) {
        return { ...publicLink, name };
      }
      return publicLink;
    });

    setPublicLinks(newPublickLinks);
  };

  const toggleNameEditMode = linkId => {
    const newPublickLinks = publicLinks.map(publicLink => {
      if (publicLink.id === linkId) {
        return { ...publicLink, inEditMode: !publicLink.inEditMode };
      }
      return publicLink;
    });

    setPublicLinks(newPublickLinks);
  };

  const handleLinkNameKeyPress = (e, linkId) => {
    if (e.charCode === ENTER_KEY_CODE || e.key === ENTER_KEY_NAME) submitUpdatePublicLinkName(linkId);
  };

  const submitUpdatePublicLinkName = linkId => {
    const publicLink = publicLinks.find(link => link.id === linkId);
    const hasNameChanged = publicLink && publicLink.name !== links.find(link => link.id === linkId).name;

    if (hasNameChanged) {
      onUpdateLink(linkId, publicLink);
    }

    toggleNameEditMode(linkId);
  };

  const isLinkInEditMode = linkId => publicLinks.find(link => link.id === linkId).inEditMode;

  useEffect(() => {
    if (links) setPublicLinks(links);
  }, [links]);

  return (
    <Wrapper>
      <FlexWrapper>
        <PublicLinkText>Public links do not require a log in</PublicLinkText>
        <StyledHelpIcon id="public-link" />
      </FlexWrapper>

      <LinksContainer>
        {publicLinks.map((publicLink, index) => {
          const linkHasSlackExpanded = linksWithSlackExpanded.includes(publicLink.id);
          const linkHasMicrosoftTeamsExpanded = linksWithMicrosoftTeamsExpanded.includes(publicLink.id);

          if (publicLink.loading) {
            return (
              <Grid item xs={12} key={publicLink.id}>
                <Loading />
              </Grid>
            );
          }

          return (
            <Grid item xs={12} key={publicLink.id} style={{ marginTop: spacing(2) }}>
              <VerticallyAlignedFlex>
                {isLinkInEditMode(publicLink.id) ? (
                  <>
                    <LinkNameTextField
                      type="text"
                      size="small"
                      value={publicLink.name}
                      onChange={e => changePublicLinkName(publicLink.id, e.target.value)}
                      onKeyPress={e => handleLinkNameKeyPress(e, publicLink.id)}
                      autoFocus
                    />
                    <ButtonWithIcon onClick={() => submitUpdatePublicLinkName(publicLink.id)} data-testid="update-name-button">
                      <CheckIcon />
                    </ButtonWithIcon>
                  </>
                ) : (
                  <>
                    <LinkName>{publicLink.name}</LinkName>
                    {userCanManagePublicLinks && (
                      <>
                        <ButtonWithIcon onClick={() => toggleNameEditMode(publicLink.id)} data-testid="edit-name-button">
                          <EditIcon />
                        </ButtonWithIcon>
                        <ButtonWithIcon onClick={() => onDeleteLink(publicLink.id)} data-testid="delete-link-button">
                          <DeleteIcon />
                        </ButtonWithIcon>
                      </>
                    )}
                  </>
                )}
              </VerticallyAlignedFlex>
              <ShareVerticallyAlignedFlex>
                <ShareUrlText shareUrl={buildPublicLinkFromHash(publicLink.link_hash)} />
                {hasSlackIntegration ? (
                  <ShareViaIntegrationButton
                    integrationType={INTEGRATIONS_KEYS.slack}
                    onClick={() => toggleSlackExpand(publicLink.id)}
                    active={linkHasSlackExpanded}
                  />
                ) : null}
                {hasMicrosoftTeamsIntegration ? (
                  <ShareViaIntegrationButton
                    integrationType={INTEGRATIONS_KEYS.microsoftTeams}
                    onClick={() => toggleMicrosoftTeamsExpand(publicLink.id)}
                    active={linkHasMicrosoftTeamsExpanded}
                  />
                ) : null}
              </ShareVerticallyAlignedFlex>
              {linkHasSlackExpanded ? (
                <SendViewToIntegrationChannel
                  integrationType={INTEGRATIONS_KEYS.slack}
                  handleCloseDialog={handleCloseDialog}
                  viewUrl={buildPublicLinkFromHash(publicLink.link_hash)}
                  view={view}
                />
              ) : null}
              {linkHasMicrosoftTeamsExpanded ? (
                <SendViewToIntegrationChannel
                  integrationType={INTEGRATIONS_KEYS.microsoftTeams}
                  handleCloseDialog={handleCloseDialog}
                  viewUrl={buildPublicLinkFromHash(publicLink.link_hash)}
                  view={view}
                />
              ) : null}
            </Grid>
          );
        })}

        {userCanManagePublicLinks && (
          <AddNewLinkButton onClick={onAddNewLink} data-testid="add-link-button">
            + Add new public link
          </AddNewLinkButton>
        )}
      </LinksContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PublicLinkText = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeSmall}px;
  color: ${({ theme }) => theme.palette.newLayout.text.label};
`;

const StyledHelpIcon = styled(HelpIcon)`
  color: ${({ theme }) => theme.palette.text.grey};
  margin-left: ${spacing(0.5)}px;

  && {
    font-size: 15px;
  }
`;

const LinksContainer = styled.div``;

const VerticallyAlignedFlex = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const ShareVerticallyAlignedFlex = styled(VerticallyAlignedFlex)`
  gap: ${spacing(2)}px;
  //Transform needed to offset the padding on the buttons
  transform: translateX(-${spacing()}px);
`;

const LinkNameTextField = styled(TextField)`
  && {
    margin-right: 10px;
    margin-left: 10px;
  }

  input {
    min-width: 200px;
    font-size: 12px;
    color: ${({ theme }) => theme.palette.newLayout.text.grey};
    padding: 5px 10px;
  }
`;

const LinkName = styled(SmallText)`
  color: ${({ theme }) => theme.palette.newLayout.background.darkestGray};
  font-size: ${({ theme }) => theme.typography.fontSize}px;
  margin-right: 10px;
`;

const ButtonWithIcon = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.newLayout.background.darkestGray};
    min-width: unset;
    padding: ${spacing(0.75)}px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const AddNewLinkButton = styled(Button)`
  && {
    margin-top: ${spacing(2)}px;
    color: ${({ theme }) => theme.palette.newLayout.text.blue};
  }
`;
