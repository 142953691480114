import isNaN from 'lodash/isNaN';
import cloneDeep from 'lodash/cloneDeep';
import reduceReducers from 'reduce-reducers';

import {
  FETCH_OBJECTIVES_FULFILLED,
  UPDATE_OBJECTIVE_FULFILLED,
  ADD_OBJECTIVE_WITHOUT_SAVE,
  REMOVE_OBJECTIVE_NOT_SAVED,
  ADD_OBJECTIVE,
  REMOVE_OBJECTIVE_BY_ID,
  SWAP_OBJECTIVES_ROW_ORDER,
  MERGE_OBJECTIVES_FULFILLED,
  FETCH_OBJECTIVES_PENDING,
  CREATE_OBJECTIVE,
  CREATE_OBJECTIVE_FULFILLED,
  CREATE_OBJECTIVES_FULFILLED,
  UPDATE_OBJECTIVES_FULFILLED,
  UNDO_CREATE_OBJECTIVES_FULFILLED,
  UNDO_UPDATE_OBJECTIVES_FULFILLED,
  BULK_DELETE_OBJECTIVES_FULFILLED,
  UNDO_BULK_DELETE_OBJECTIVES_FULFILLED,
  UPDATE_KEY_RESULT_ID_FULFILLED,
  UPDATE_KEY_RESULT_ROW_ORDER,
  MOVE_KEY_RESULT_TO_OBJECTIVE,
  MOVE_KEY_RESULT_TO_KEY_RESULT,
  ADD_KEY_RESULT_WITHOUT_SAVE,
  REMOVE_UNSAVED_OBJECTIVES_AND_KEY_RESULTS,
  CREATE_KEY_RESULT_FULFILLED,
  DELETE_KEY_RESULT_FULFILLED,
  MERGE_KEY_RESULTS_FULFILLED,
  BULK_DELETE_KEY_RESULTS_FULFILLED,
  UNDO_BULK_DELETE_KEY_RESULTS_FULFILLED,
  OPEN_OBJECTIVE_DRAWER,
  CLOSE_OBJECTIVE_DRAWER,
  UPDATE_OBJECTIVE_ROW_ORDER_PENDING,
  UPDATE_OBJECTIVE_ROW_ORDER_FULFILLED,
  UPDATE_OBJECTIVE_ROW_ORDER_REJECTED,
  BULK_DELETE_OBJECTIVE_ROADMAP_FULFILLED,
  CREATE_OBJECTIVE_ROADMAP_FULFILLED,
  DELETE_OBJECTIVE_ROADMAP_FULFILLED,
  BULK_DELETE_KEY_RESULT_ROADMAP_FULFILLED,
  CREATE_KEY_RESULT_ROADMAP_FULFILLED,
  DELETE_KEY_RESULT_ROADMAP_FULFILLED,
  REFRESH_KEY_RESULT_BY_ID,
  UPDATE_KEY_RESULTS_FULFILLED,
  ADD_OBJECTIVE_METRIC,
  ADD_OBJECTIVE_METRIC_FULFILLED,
  BULK_ADD_KEY_RESULT_METRICS,
  BULK_ADD_KEY_RESULT_METRICS_FULFILLED,
  BULK_ADD_OBJECTIVE_METRICS,
  BULK_ADD_OBJECTIVE_METRICS_FULFILLED,
  BULK_REMOVE_KEY_RESULT_METRICS,
  BULK_REMOVE_KEY_RESULT_METRICS_FULFILLED,
  BULK_REMOVE_OBJECTIVE_METRICS,
  BULK_REMOVE_OBJECTIVE_METRICS_FULFILLED,
  ADD_KEY_RESULT_METRIC,
  ADD_KEY_RESULT_METRIC_FULFILLED,
  REMOVE_OBJECTIVE_METRIC,
  REMOVE_OBJECTIVE_METRIC_FULFILLED,
  REMOVE_KEY_RESULT_METRIC,
  REMOVE_KEY_RESULT_METRIC_FULFILLED,
  CREATE_AND_ADD_METRIC_TO_KEY_RESULT,
  CREATE_AND_ADD_METRIC_TO_KEY_RESULT_FULFILLED,
  CREATE_AND_ADD_METRIC_TO_OBJECTIVE,
  CREATE_AND_ADD_METRIC_TO_OBJECTIVE_FULFILLED,
  CREATE_OBJECTIVE_FROM_DRAWER_FULFILLED,
  UPDATE_OBJECTIVE_FROM_DRAWER_FULFILLED,
  UPDATE_KEY_RESULT_FROM_DRAWER_FULFILLED,
  CREATE_KEY_RESULT_FROM_DRAWER_FULFILLED,
} from './types';

import upsertListItem from '../utils/upsertListItem';
import addRows from 'store/utils/addRows';
import updateRows from 'store/utils/updateRows';
import sortByRank from 'utils/sortByRank';

import addObjectiveToParentCorp from './helpers/addObjectiveToParentCorp';
import {
  addKeyResult,
  buildKeyResultsFromObjectives,
  buildObjectivesWithKeyResults,
  deleteKeyResults,
  getKeyResultFromMetadataRoadmap,
  removeUnsaved,
  removeUnsavedKeyResults,
  updateKeyResult,
} from './helpers';
import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';

const exist = Boolean;

const initialState = {
  selectedId: null,
  objectives: [],
  keyResults: {},
  isLoaded: false,
  operations: bulkThunkInitialState([
    ADD_KEY_RESULT_METRIC,
    ADD_OBJECTIVE_METRIC,
    BULK_ADD_KEY_RESULT_METRICS,
    BULK_ADD_OBJECTIVE_METRICS,
    BULK_REMOVE_KEY_RESULT_METRICS,
    BULK_REMOVE_OBJECTIVE_METRICS,
    CREATE_AND_ADD_METRIC_TO_KEY_RESULT,
    CREATE_AND_ADD_METRIC_TO_OBJECTIVE,
    REMOVE_KEY_RESULT_METRIC,
    REMOVE_OBJECTIVE_METRIC,
  ]),
};

function objectivesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_OBJECTIVES_PENDING:
      return Object.assign({}, state, { isLoaded: false });
    case FETCH_OBJECTIVES_FULFILLED: {
      if (!action.payload) {
        return state;
      }

      const orderedObjectives = action.payload.data.sort(sortByRank);
      const keyResults = buildKeyResultsFromObjectives(orderedObjectives);

      return Object.assign({}, state, {
        objectives: orderedObjectives,
        keyResults,
        isLoaded: true,
        // lastCallsDate: moment().valueOf(),
      });
    }
    case UPDATE_OBJECTIVE_FULFILLED: {
      if (!action.payload) {
        return state;
      }

      const data = action.payload.id ? action.payload : action.payload.data;

      const objectives = upsertListItem(data, state.objectives);

      return Object.assign({}, state, {
        objectives,
        keyResults: buildKeyResultsFromObjectives(objectives),
        isLoaded: true,
      });
    }
    case REFRESH_KEY_RESULT_BY_ID:
    case UPDATE_KEY_RESULT_FROM_DRAWER_FULFILLED:
    case UPDATE_KEY_RESULT_ID_FULFILLED: {
      const keyResults = updateKeyResult(state.keyResults, action.payload);

      return Object.assign({}, state, {
        keyResults,
        objectives: buildObjectivesWithKeyResults(state.objectives, keyResults),
        isLoaded: true,
      });
    }
    case UPDATE_KEY_RESULTS_FULFILLED: {
      const keyResults = updateKeyResult(state.keyResults, action.payload);

      return Object.assign({}, state, {
        keyResults,
        objectives: buildObjectivesWithKeyResults(state.objectives, keyResults),
        isLoaded: true,
      });
    }
    case ADD_OBJECTIVE_WITHOUT_SAVE: {
      const objectives = state.objectives ? removeUnsaved(state.objectives) : [];
      const { level, objective: parentObjective } = action;

      objectives.unshift({ level, parent_id: parentObjective?.id } || {});

      return {
        ...state,
        objectives,
      };
    }
    case CREATE_OBJECTIVES_FULFILLED: {
      const objectives = addRows(state.objectives, action.payload);

      return {
        ...state,
        objectives,
        lastActionIds: action.payload.map(objective => objective.id),
      };
    }
    case CREATE_OBJECTIVE_FULFILLED: {
      const objective = action.payload;

      let objectives = addRows(state.objectives, [objective]);

      const parentId = objective.parent_id;

      if (exist(parentId)) {
        objectives = addObjectiveToParentCorp(objectives, objective, parentId);
      }

      return {
        ...state,
        objectives,
        lastActionIds: [action.payload].map(objective => objective.id),
      };
    }
    case UPDATE_OBJECTIVES_FULFILLED: {
      const objectives = updateRows(state.objectives, action.payload);

      return {
        ...state,
        objectives,
        keyResults: buildKeyResultsFromObjectives(objectives),
        lastActionIds: action.payload.map(objective => objective.id),
      };
    }
    case BULK_DELETE_OBJECTIVES_FULFILLED: {
      const objectives = state.objectives.filter(
        r => !action.payload.includes(String(r.id)) && !action.payload.includes(String(r.parent_id)),
      );

      return {
        ...state,
        objectives,
        keyResults: buildKeyResultsFromObjectives(objectives),
        lastActionIds: action.payload,
      };
    }
    case UNDO_UPDATE_OBJECTIVES_FULFILLED: {
      const objectives = updateRows(state.objectives, action.payload);

      return {
        ...state,
        objectives,
        keyResults: buildKeyResultsFromObjectives(objectives),
        lastActionIds: null,
      };
    }
    case UNDO_CREATE_OBJECTIVES_FULFILLED: {
      const objectives = state.objectives.filter(c => state.lastActionIds.indexOf(c.id) === -1);

      return {
        ...state,
        objectives,
        keyResults: buildKeyResultsFromObjectives(objectives),
        lastActionIds: null,
      };
    }
    case UNDO_BULK_DELETE_OBJECTIVES_FULFILLED: {
      const objectives = updateRows(state.objectives, action.payload);

      return {
        ...state,
        objectives,
        keyResults: buildKeyResultsFromObjectives(objectives),
        lastActionIds: null,
      };
    }
    case REMOVE_OBJECTIVE_NOT_SAVED:
      if (state.objectives && state.objectives.length && !state.objectives[0].id) {
        const objectives = state.objectives.slice(1);

        return {
          ...state,
          objectives,
        };
      }
      return state;
    case ADD_OBJECTIVE:
    case UPDATE_OBJECTIVE_FROM_DRAWER_FULFILLED:
    case CREATE_OBJECTIVE: {
      if (!action.payload || !action.payload.id) {
        console.error('Objective passed does not have id');
        return state;
      }

      let objectives = state.objectives || [];

      objectives = removeUnsaved(objectives);
      objectives = upsertListItem(action.payload, objectives);

      return {
        ...state,
        objectives,
      };
    }
    case CREATE_OBJECTIVE_FROM_DRAWER_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        console.error('Objective passed does not have id');
        return state;
      }

      let objectives = state.objectives || [];

      objectives = removeUnsaved(objectives);
      objectives = upsertListItem(action.payload, objectives);

      const parentId = action.payload?.parent_id;

      if (exist(parentId)) {
        objectives = addObjectiveToParentCorp(objectives, action.payload, parentId);
      }

      return {
        ...state,
        objectives,
      };
    }
    case REMOVE_OBJECTIVE_BY_ID: {
      if (isNaN(action.id) || action.id < 0 || !state.objectives) {
        return state;
      }
      const objectives = state.objectives.filter(objective => objective.id !== action.id && objective.parent_id !== action.id);

      return {
        ...state,
        objectives,
        keyResults: buildKeyResultsFromObjectives(objectives),
      };
    }
    case SWAP_OBJECTIVES_ROW_ORDER: {
      const { objective1, objective2 } = action;

      // two objectives must be passed
      if (!objective1 || !objective1.id || !objective2 || !objective2.id) {
        return state;
      }

      const objectivesIdsList = state.objectives.map(objective => objective.id);
      const objective1Index = objectivesIdsList.indexOf(objective1.id);
      const objective2Index = objectivesIdsList.indexOf(objective2.id);
      const objectives = state.objectives.slice(0);

      objectives[objective1Index] = objective2;
      objectives[objective2Index] = objective1;

      return {
        ...state,
        objectives,
      };
    }
    case UPDATE_OBJECTIVE_ROW_ORDER_REJECTED: {
      if (!action.meta || !action.meta.prev) return state;

      const {
        meta: { prev },
      } = action;

      let objectives = state.objectives || [];

      objectives = upsertListItem(prev, objectives);
      objectives = objectives
        .map(obj => {
          if (obj.id === prev.id) return { ...obj, rank: prev.rank };
          return obj;
        })
        .sort(sortByRank);

      return {
        ...state,
        objectives,
      };
    }
    case UPDATE_OBJECTIVE_ROW_ORDER_PENDING:
    case UPDATE_OBJECTIVE_ROW_ORDER_FULFILLED:
      if (!action.payload || !action.payload.id) {
        console.error('UPDATE_OBJECTIVE_ROW_ORDER::Objective passed does not have id');
        return state;
      }

      let objectives = state.objectives || [];

      objectives = upsertListItem(action.payload, objectives);
      objectives = objectives
        .map(obj => {
          if (obj.id === action.payload.id) return { ...obj, rank: action.payload.rank };
          return obj;
        })
        .sort(sortByRank);

      return {
        ...state,
        objectives,
      };
    case UPDATE_KEY_RESULT_ROW_ORDER: {
      if (!action.keyResult || !action.keyResult.id) {
        console.error('UPDATE_KEY_RESULT_ROW_ORDER::Key Result passed does not have id');
        return state;
      }

      const keyResults = updateKeyResult(state.keyResults, action.keyResult);

      return {
        ...state,
        objectives: buildObjectivesWithKeyResults(state.objectives, keyResults),
        keyResults,
      };
    }
    case MOVE_KEY_RESULT_TO_OBJECTIVE: {
      if (!action.keyResult || !action.keyResult.id) {
        console.error('UPDATE_KEY_RESULT_ROW_ORDER::KeyResult passed does not have id');
        return state;
      }

      let objectives = state.objectives || [];
      const previousObjective = { ...objectives.find(o => o.id === action.previousObjectiveId) };
      const previousObjectiveKeyResults = previousObjective.keyResults || [];
      const previousObjectiveNewKeyResults = previousObjectiveKeyResults.filter(i => {
        return i.id !== action.keyResult.id;
      });

      const keyResult = previousObjectiveKeyResults.find(i => {
        return i.id === action.keyResult.id;
      });

      const objective = { ...objectives.find(o => o.id === action.keyResult.objective_id) };

      let keyResults = [];

      if (objective) {
        keyResults = [
          ...(objective.keyResults || []),
          {
            ...action.keyResult,
            keyResults: (keyResult?.keyResults || []).map(kr => ({ ...kr, objective_id: action.keyResult.objective_id })),
          },
        ];
        keyResults.sort(sortByRank);
      }

      objectives = objectives.map(o => {
        if (o.id === action.keyResult.objective_id) {
          return {
            ...o,
            keyResults,
          };
        } else if (o.id === action.previousObjectiveId) {
          return {
            ...o,
            keyResults: previousObjectiveNewKeyResults,
          };
        }
        return o;
      });

      return {
        ...state,
        objectives,
        keyResults: buildKeyResultsFromObjectives(objectives),
      };
    }
    case MOVE_KEY_RESULT_TO_KEY_RESULT: {
      if (!action.keyResult || !action.keyResult.id) {
        console.error('UPDATE_KEY_RESULT_ROW_ORDER::KeyResult passed does not have id');
        return state;
      }

      let objectives = state.objectives || [];
      const previousObjective = { ...objectives.find(objective => objective.id === action.previousObjectiveId) };
      const previousObjectiveKeyResults = previousObjective.keyResults || [];
      const previousObjectiveNewKeyResults = previousObjectiveKeyResults.map(i => {
        if (+i.id !== +action.previousParentId) return i;
        return {
          ...i,
          keyResults: i.keyResults.filter(kr => +kr.id !== +action.keyResult.id),
        };
      });

      previousObjective.keyResults = previousObjectiveNewKeyResults;

      let newObjective;

      if (action.keyResult.objective_id === action.previousObjectiveId) {
        newObjective = previousObjective;
      } else {
        newObjective = { ...objectives.find(objective => objective.id === action.keyResult.objective_id) };
      }
      const parentKeyResult = {
        ...(newObjective.keyResults || []).find(keyResult => keyResult.id === action.keyResult.parent_id),
      };

      let keyResults = [];

      if (parentKeyResult) {
        keyResults = [
          ...parentKeyResult.keyResults,
          {
            ...action.keyResult,
          },
        ];
        keyResults.sort(sortByRank);
        newObjective.keyResults = newObjective.keyResults.map(kr => {
          if (kr.id === action.keyResult.parent_id) {
            return { ...kr, keyResults };
          }
          return kr;
        });
      }

      objectives = objectives.map(objective => {
        if (objective.id === action.keyResult.objective_id) {
          return newObjective;
        } else if (objective.id === action.previousObjectiveId) {
          return previousObjective;
        }
        return objective;
      });

      return {
        ...state,
        objectives,
        keyResults: buildKeyResultsFromObjectives(objectives),
      };
    }
    case ADD_KEY_RESULT_WITHOUT_SAVE: {
      const objectives = state.objectives ? removeUnsaved(state.objectives) : [];

      const parentId = action.isKeyResult ? action.parent.id : null;
      const objectiveId = action.isKeyResult ? action.parent.objective_id : action.parent.id;
      const keyResult = { objective_id: objectiveId, parent_id: parentId, level: action.level };
      const keyResults = addKeyResult(state.keyResults, keyResult);

      return {
        ...state,
        objectives: buildObjectivesWithKeyResults(objectives, keyResults),
        keyResults,
      };
    }
    case CREATE_KEY_RESULT_FROM_DRAWER_FULFILLED:
    case CREATE_KEY_RESULT_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        return state;
      }

      let objectives = state.objectives ? removeUnsaved(state.objectives) : [];
      const keyResults = removeUnsavedKeyResults(state.keyResults);
      const objective = objectives.find(r => r.id === action.payload.objective_id);

      if (!objective && action.payload.objective) {
        const newObjective = { ...action.payload.objective };

        delete action.payload.objective;

        objectives = [...objectives, newObjective];
      }

      const updatedKeyResults = addKeyResult(keyResults, action.payload);

      return {
        ...state,
        objectives: buildObjectivesWithKeyResults(objectives, updatedKeyResults),
        keyResults: updatedKeyResults,
        isLoaded: true,
      };
    }
    case REMOVE_UNSAVED_OBJECTIVES_AND_KEY_RESULTS: {
      const keyResults = deleteKeyResults(state.keyResults, [action.payload]);
      const objectives = removeUnsaved(state.objectives || []);

      return {
        ...state,
        objectives: buildObjectivesWithKeyResults(objectives, keyResults),
        keyResults,
      };
    }
    case DELETE_KEY_RESULT_FULFILLED: {
      if (!action.payload) {
        return state;
      }

      const keyResults = deleteKeyResults(state.keyResults, [action.payload]);

      return {
        ...state,
        objectives: buildObjectivesWithKeyResults(state.objectives, keyResults),
        keyResults,
      };
    }
    case BULK_DELETE_KEY_RESULTS_FULFILLED: {
      if (!action.payload) return state;

      const deletedKeyResults = action.payload.map(id => +id);
      const keyResults = deleteKeyResults(state.keyResults, deletedKeyResults);

      return {
        ...state,
        objectives: buildObjectivesWithKeyResults(state.objectives, keyResults),
        keyResults,
        lastActionIds: action.payload,
      };
    }
    case UNDO_BULK_DELETE_KEY_RESULTS_FULFILLED: {
      const keyResults = action.payload.reduce((acc, keyResult) => {
        return addKeyResult(acc, keyResult);
      }, state.keyResults);

      return {
        ...state,
        keyResults,
        objectives: buildObjectivesWithKeyResults(state.objectives, keyResults),
        lastActionIds: null,
      };
    }
    case MERGE_OBJECTIVES_FULFILLED:
      return {
        ...state,
        objectives: state.objectives.filter(objective => !action.payload.includes(objective.id)),
      };
    case MERGE_KEY_RESULTS_FULFILLED:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
      };
    case CREATE_OBJECTIVE_ROADMAP_FULFILLED:
      const objs = cloneDeep(state.objectives);
      const objective = objs.find(obj => obj.id === action.payload.objective_id);

      objective.objective_roadmaps = [...(objective.objective_roadmaps || []), action.payload];

      const updatedObjectives = upsertListItem(objective, objs);

      return {
        ...state,
        objectives: updatedObjectives,
      };
    case DELETE_OBJECTIVE_ROADMAP_FULFILLED:
      const existingObjs = cloneDeep(state.objectives);
      const { id: objectiveId, objectiveRoadmaps } = action.payload;
      const parent = existingObjs.find(obj => obj.id === parseInt(objectiveId));

      parent.objective_roadmaps = objectiveRoadmaps || [];

      const updated = upsertListItem(parent, existingObjs);

      return {
        ...state,
        objectives: updated,
      };
    case BULK_DELETE_OBJECTIVE_ROADMAP_FULFILLED:
      const existingObjectives = cloneDeep(state.objectives);
      const updatedObjs = existingObjectives.map(objective => {
        if (objective.id === action.payload) {
          return {
            ...objective,
            objective_roadmaps: [],
          };
        }

        return objective;
      });

      return {
        ...state,
        objectives: updatedObjs,
      };
    case CREATE_KEY_RESULT_ROADMAP_FULFILLED:
      const clonedKeyResults = cloneDeep(state.keyResults);
      const currentKeyResult = getKeyResultFromMetadataRoadmap(clonedKeyResults, action.payload, 'key_result_id');

      currentKeyResult.key_result_roadmaps = [...(currentKeyResult.key_result_roadmaps || []), action.payload];

      const updatedKeyResults = updateKeyResult(clonedKeyResults, currentKeyResult);

      return {
        ...state,
        keyResults: updatedKeyResults,
        objectives: buildObjectivesWithKeyResults(cloneDeep(state.objectives), updatedKeyResults),
      };
    case DELETE_KEY_RESULT_ROADMAP_FULFILLED:
      const { keyResultRoadmaps } = action.payload;

      const existingKeyResults = cloneDeep(state.keyResults);

      const parentKeyResult = getKeyResultFromMetadataRoadmap(existingKeyResults, action.payload, 'id');

      parentKeyResult.key_result_roadmaps = keyResultRoadmaps;

      const update = updateKeyResult(existingKeyResults, parentKeyResult);

      return {
        ...state,
        keyResults: update,
        objectives: buildObjectivesWithKeyResults(cloneDeep(state.objectives), update),
      };
    case BULK_DELETE_KEY_RESULT_ROADMAP_FULFILLED:
      const mappedKeyResults = {};
      const clonedKRs = cloneDeep(state.keyResults);

      Object.keys(clonedKRs).forEach(level => {
        mappedKeyResults[level] = clonedKRs[level].map(keyResult => {
          if (keyResult.id === action.payload) {
            return {
              ...keyResult,
              key_result_roadmaps: [],
            };
          }

          return keyResult;
        });
      });

      return {
        ...state,
        keyResults: mappedKeyResults,
        objectives: buildObjectivesWithKeyResults(cloneDeep(state.objectives), mappedKeyResults),
      };
    case ADD_OBJECTIVE_METRIC_FULFILLED:
    case BULK_ADD_OBJECTIVE_METRICS_FULFILLED:
    case BULK_REMOVE_OBJECTIVE_METRICS_FULFILLED:
    case REMOVE_OBJECTIVE_METRIC_FULFILLED:
    case CREATE_AND_ADD_METRIC_TO_OBJECTIVE_FULFILLED:
      const allObjectives = upsertListItem(action.payload, state.objectives);

      return {
        ...state,
        keyResults: buildKeyResultsFromObjectives(allObjectives),
        objectives: allObjectives,
      };
    case ADD_KEY_RESULT_METRIC_FULFILLED:
    case BULK_ADD_KEY_RESULT_METRICS_FULFILLED:
    case BULK_REMOVE_KEY_RESULT_METRICS_FULFILLED:
    case REMOVE_KEY_RESULT_METRIC_FULFILLED:
    case CREATE_AND_ADD_METRIC_TO_KEY_RESULT_FULFILLED:
      const keyResults = updateKeyResult(state.keyResults, action.payload);

      return {
        ...state,
        keyResults,
        objectives: buildObjectivesWithKeyResults(state.objectives, keyResults),
      };
    case OPEN_OBJECTIVE_DRAWER:
      return {
        ...state,
        drawerOpen: true,
        selectedId: action.payload.id,
        selectedType: action.payload.type,
      };
    case CLOSE_OBJECTIVE_DRAWER:
      return {
        ...state,
        drawerOpen: false,
      };
    default:
      return state;
  }
}

const operationsReducer = getThunksReducers([
  ADD_KEY_RESULT_METRIC,
  ADD_OBJECTIVE_METRIC,
  BULK_ADD_KEY_RESULT_METRICS,
  BULK_ADD_OBJECTIVE_METRICS,
  BULK_REMOVE_KEY_RESULT_METRICS,
  BULK_REMOVE_OBJECTIVE_METRICS,
  CREATE_AND_ADD_METRIC_TO_KEY_RESULT,
  CREATE_AND_ADD_METRIC_TO_OBJECTIVE,
  REMOVE_KEY_RESULT_METRIC,
  REMOVE_OBJECTIVE_METRIC,
]);

const reducer = reduceReducers(initialState, objectivesReducer, ...operationsReducer);

export default reducer;
