/* eslint jsx-a11y/anchor-is-valid: 0 */
// External dependencies
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';

// Dragonboat dependencies
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import LightBox from 'components/LightBox';
import CloseButton from 'components/DialogTitle/CloseButton';

class ImportWizardLightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      importType: 'copy-paste',
    };
  }

  showCopyPasteLightbox = () => {
    const { setCopyPasteData, showCopyPasteLightbox, onClose, onSelectCopyPaste } = this.props;
    const newArray = [];

    if (onSelectCopyPaste) {
      return onSelectCopyPaste();
    }

    for (let i = 0; i < 100; i++) newArray.push({});

    setCopyPasteData(newArray);
    onClose();
    showCopyPasteLightbox();
  };

  importClick = () => {
    const { onClose, importClick } = this.props;

    onClose();
    importClick();
  };

  onChange = e => {
    this.setState({
      importType: e.target.value,
    });
  };

  goToNextStep = () => {
    const { importType } = this.state;

    if (importType === 'copy-paste') {
      this.showCopyPasteLightbox();
    }
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <LightBox
        isOpen={isOpen}
        onClose={onClose}
        maxWidth="lg"
        dataCy="import-ideas-dialog"
        content={
          <Wrapper>
            <CloseButton onClick={onClose} />
            <DialogContent>
              <DialogTitle>Import users</DialogTitle>
              <RadioGroup value={this.state.importType} onChange={this.onChange}>
                <RadioControlLabel
                  value="copy-paste"
                  control={<Radio />}
                  // eslint-disable-next-line
                  label={
                    <TextDeprecated breakwords size="medium">
                      Copy and paste your users from Google sheet, Excel or Number
                    </TextDeprecated>
                  }
                />
                <RadioControlLabel
                  value="csv"
                  control={<Radio />}
                  // eslint-disable-next-line
                  label={
                    <TextDeprecated breakwords size="medium">
                      Import from a csv file
                    </TextDeprecated>
                  }
                />
                <ImportCsvContainer>
                  <InputFileContainer onClick={this.importClick}>
                    <BrowseButton size="small" variant="contained">
                      Browse
                    </BrowseButton>
                  </InputFileContainer>
                  <ImportInstructions>You may then map the columns to dragonboat fields</ImportInstructions>
                </ImportCsvContainer>
                <ActionsContainer>
                  <NextButton color="primary" onClick={this.goToNextStep} disabled={this.state.importType === 'csv'}>
                    Next
                  </NextButton>
                </ActionsContainer>
              </RadioGroup>
            </DialogContent>
          </Wrapper>
        }
      />
    );
  }
}

const Wrapper = styled.div`
  max-width: 700px;
`;

// const DialogSubheader = styled.h5`
//   text-align: center;
//   margin-bottom: 2rem;
// `;

const ImportCsvContainer = styled.div`
  margin-left: 34px;
  padding-top: 5px;
  padding-bottom: 5px;

  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`;

const InputFileContainer = styled.div`
  text-align: right;
  width: 300px;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.23);
  cursor: pointer;

  &&&:hover {
    border-color: rgba(0, 0, 0, 0.87);
  }
`;

const ImportInstructions = styled.p`
  margin-top: 15px;
  font-size: 0.875rem;
`;

const DialogContent = styled.div``;

const DialogTitle = styled.h4`
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 1rem;
`;

const ActionsContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row-reverse;
`;

const NextButton = styled(Button)`
  &&&& {
  }
`;

const BrowseButton = styled(Button)`
  &&& {
    text-transform: none;
    box-shadow: none;
    border-radius: 0px;
  }
`;

const RadioControlLabel = styled(FormControlLabel)`
  &&&& {
    margin: 8px 0;
  }
`;

ImportWizardLightbox.propTypes = {
  setCopyPasteData: PropTypes.func.isRequired,
  showCopyPasteLightbox: PropTypes.func.isRequired,
  importClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSelectCopyPaste: PropTypes.func.isRequired,
};

export default ImportWizardLightbox;
