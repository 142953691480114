/**
 * @function sortColumnsByColumnState
 *
 * Sorts an array of columns based on column state index.
 *
 * @param  {Array} columnsState
 * @return {Function}
 */
const sortColumnsByColumnState = columnsState => (a, b) => {
  if (!columnsState) {
    return 0;
  }

  const aIndex = columnsState.findIndex(col => col.colId === a.field);
  const bIndex = columnsState.findIndex(col => col.colId === b.field);

  return aIndex - bIndex;
};

export default sortColumnsByColumnState;
