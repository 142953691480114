import reduceReducers from 'reduce-reducers';

import {
  SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_PIE_CHART_GROUP_BY,
  SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_GROUP_BY,
  SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_STACKED_BY,
} from 'store/customerRequests/types';

import { ON_COUNTER_STACK_CHART_ENTITY_CLICK, ON_COUNTER_PIE_CHART_ENTITY_CLICK, SET_INSIGHTS_FILTERS } from './types';
import { buildItemFilter } from './helpers';

const initialState = {
  filters: [],
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_INSIGHTS_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case ON_COUNTER_PIE_CHART_ENTITY_CLICK: {
      const { groupItem, selectedGroupBy } = action.payload;
      const { filters } = state;

      const { newFilter, currentFilter } = buildItemFilter(filters, selectedGroupBy, groupItem);

      const newFilters = !currentFilter ? [newFilter] : [];

      return {
        ...state,
        filters: newFilters,
      };
    }
    case ON_COUNTER_STACK_CHART_ENTITY_CLICK: {
      const { groupItem, stackItem, selectedGroupBy, selectedStackedBy } = action.payload;
      const { filters } = state;

      const { newFilter: newFilterGroupBy, currentFilter: currentFilterGroupBy } = buildItemFilter(
        filters,
        selectedGroupBy,
        groupItem,
      );
      const { newFilter: newFilterStackBy, currentFilter: currentFilterStackBy } = buildItemFilter(
        filters,
        selectedStackedBy,
        stackItem,
      );

      const newFilters = currentFilterGroupBy && currentFilterStackBy ? [] : [newFilterGroupBy, newFilterStackBy];

      return {
        ...state,
        filters: newFilters,
      };
    }
    case SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_PIE_CHART_GROUP_BY:
    case SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_GROUP_BY:
    case SET_CUSTOMER_REQUESTS_INSIGHTS_COUNTER_STACKED_CHART_STACKED_BY: {
      return {
        ...state,
        filters: [],
      };
    }
    default: {
      return state;
    }
  }
};

export { initialState };

export default reduceReducers(initialState, reducer);
