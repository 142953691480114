import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'ramda';

import { openProjectLightboxToCreateFromRequest } from 'store/projectLightbox';
import { getCustomerRequestFormData } from 'store/customerRequests/selectors';
import {
  addProjectToCustomerRequest as addProjectToCustomerRequestAction,
  removeProjectFromCustomerRequest as removeProjectFromCustomerRequestAction,
} from 'store/customerRequests';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';
import useRequestsDetailFieldsLayout from 'features/RequestsDetailFieldsLayout/hooks/useRequestsDetailFieldsLayout';
import { REQUEST_FORM_FIELDS } from 'features/RequestsDetailFieldsLayout/constants';

/**
 * This hook exposes all request portfolio item actions. Promote, link and unlink project from a request
 * @return {{promoteToProject: ((function(): void)|*)}}
 */
const usePortfolioItems = () => {
  const dispatch = useDispatch();
  const formData = useSelector(getCustomerRequestFormData);
  const { canView } = usePermissions();
  const hasRequestDetailsFieldsLayout = canView(PERMISSION_FEATURES.requestDetailsFieldsLayout);

  const { fieldsToPromote } = useRequestsDetailFieldsLayout();

  const promoteToProject = useCallback(() => {
    const staticFields = ['title', 'business_value', 'reach_score', 'impact_score', 'confidence_score', 'effort_score'];

    const defaultFields = [
      ...staticFields,
      'details',
      'tags',
      'customers',
      'personas',
      'lifecycles',
      'links',
      REQUEST_FORM_FIELDS.roadmap,
      REQUEST_FORM_FIELDS.owner,
    ];

    const fieldsFromLayoutConfig = [...staticFields, ...fieldsToPromote.map(f => f.id)];

    const allFieldsToPromote = hasRequestDetailsFieldsLayout ? fieldsFromLayoutConfig : defaultFields;

    const data = {
      ...pick(allFieldsToPromote, formData),
      ...(allFieldsToPromote.includes(REQUEST_FORM_FIELDS.roadmap)
        ? {
            roadmapTitle: formData?.roadmap?.title,
            product1Title: formData?.product1?.title,
            product2Title: formData?.product2?.title,
          }
        : {}),
      ...(allFieldsToPromote.includes(REQUEST_FORM_FIELDS.owner) ? { owner: undefined, owner_id: formData?.owner?.id } : {}),
    };

    dispatch(openProjectLightboxToCreateFromRequest(formData?.id, data));
  }, [formData, fieldsToPromote, hasRequestDetailsFieldsLayout]);

  const addProjectToCustomerRequest = useCallback(
    id => dispatch(addProjectToCustomerRequestAction(formData.id, id)),
    [formData.id],
  );
  const removeProjectFromCustomerRequest = useCallback(
    id => {
      return dispatch(removeProjectFromCustomerRequestAction(formData.id, id));
    },
    [formData.id],
  );

  return { promoteToProject, addProjectToCustomerRequest, removeProjectFromCustomerRequest };
};

export default usePortfolioItems;
