import { ACTUAL, TARGET } from 'features/MetricsDialog/constants';

export const METRIC_KEY = 'metric';
export const TITLE_FIELD = 'title';

export const ACTIONS_FIELD = 'actions';
export const ACTIONS_WIDTH = 60;

export const FOCUS_FIELD = 'value';

export const METRIC_VALUE_TYPES = [
  { label: ACTUAL.type, value: ACTUAL.type.toLowerCase() },
  { label: TARGET.type, value: TARGET.type.toLowerCase() },
];
