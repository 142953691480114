import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import flatten from 'lodash/flatten';

import useProjectGroups from 'hooks/useProjectGroups';
import useProjectsLocalSearch from 'hooks/projects/useProjectsLocalSearch';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useProjectsFrontendFilters from 'hooks/filters/useProjectsFontendFilters';
import useProjectsLocalFilterByOwner from 'hooks/projects/useProjectsLocalFilterByOwner';

import { getGridConfigValue } from 'store/grids/selectors';
import { getAllProjectsFiltered } from 'store/projects/groupSelectors';
import { getOrgHasMetadataRoadmaps as getOrgHasMetadataRoadmapsSelector } from 'store/organization';

import setIdeasHierarchyPath from 'utils/setIdeasHierarchyPath';
import { filterAllEntries, makeItSelectable } from 'utils/grid';
import { GLOBAL_FILTER } from 'constants/filters';
import useOrganizations from 'hooks/useOrganizations';

import useTreeViewGridData from './useTreeViewGridData';

const useIdeasGridData = ({
  viewType,
  displayLayer,
  portfolioMode,
  isSingleLayerGroupedBy,
  getSystemFieldName,
  selectedGroup1,
  selectedGroup2,
  selectedGroup3,
  pageId,
  showMyItemsOnly = false,
  isTreeView = false,
}) => {
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmapsSelector);
  const {
    filters: { topLayer },
  } = useOrganizations();

  const searchText = useSelector(state => getGridConfigValue(state, viewType, 'searchText'));
  const bulkDelete = useSelector(state => getGridConfigValue(state, viewType, 'bulkDelete'));
  const allData = useSelector(state => getAllProjectsFiltered(state, GLOBAL_FILTER, true, true));

  const { data: dataWithParents } = useTreeViewGridData({
    isTreeView,
    allData,
    displayLayer,
    portfolioMode,
  });

  const { getDefaultRoadmapTitleForMetadataItem } = useOrganizationsAccessControl();
  const projectsInAllLayers = useMemo(() => flatten(Object.values(dataWithParents)), [dataWithParents]);

  const [filterBySearchString] = useProjectsLocalSearch(projectsInAllLayers, searchText);
  const filterMyItems = useProjectsLocalFilterByOwner(projectsInAllLayers, showMyItemsOnly);

  const { applyFrontendFiltersOnProject, applyFrontendFilters } = useProjectsFrontendFilters(filterMyItems, filterBySearchString);

  const projectsFilters = useMemo(() => [applyFrontendFiltersOnProject], [applyFrontendFiltersOnProject]);

  const projectGroups = useProjectGroups({
    projectsFilters,
    selectedGroup1: isTreeView ? null : selectedGroup1,
    selectedGroup2: isTreeView ? null : selectedGroup2,
    selectedGroup3: isTreeView ? null : selectedGroup3,
    withHierarchy: portfolioMode,
    customAllProjectsByLayer: allData,
    page: pageId,
  });

  const data = useMemo(() => {
    let data;

    if (isTreeView) {
      const filteredData = filterAllEntries(applyFrontendFilters, dataWithParents);

      data = setIdeasHierarchyPath(
        filteredData,
        topLayer,
        getSystemFieldName('initiative', false),
        getSystemFieldName('bet', false),
        false,
        projectGroups,
        hasMetadataRoadmaps,
        getDefaultRoadmapTitleForMetadataItem,
        false,
      );
    } else if (portfolioMode || isSingleLayerGroupedBy) {
      const filteredData = filterAllEntries(applyFrontendFilters, allData);

      data = setIdeasHierarchyPath(
        filteredData,
        displayLayer,
        getSystemFieldName('initiative', false),
        getSystemFieldName('bet', false),
        isSingleLayerGroupedBy,
        projectGroups,
        hasMetadataRoadmaps,
        getDefaultRoadmapTitleForMetadataItem,
        false,
      );
    } else {
      data = applyFrontendFilters(allData[displayLayer]);
    }

    if (bulkDelete) data = makeItSelectable(data);

    return data;
  }, [
    allData,
    dataWithParents,
    displayLayer,
    bulkDelete,
    isSingleLayerGroupedBy,
    portfolioMode,
    projectGroups,
    applyFrontendFilters,
    isTreeView,
    topLayer,
  ]);

  return { data, projectGroups };
};

export default useIdeasGridData;
