import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTo } from 'ramda';

import { getRoadmaps, getProducts, getProductsLevelTwo } from 'store/roadmaps/selectors';
import useAdvancedSearchPopover from 'design-system/organisms/AdvancedSearchPopover/hooks/useAdvancedSearchPopover';
import { PORTFOLIO_LABEL } from 'constants/roadmaps';
import { getOrgHasMetadataRoadmaps } from 'store/organization';
import { applySavedFilterOnOutcomeModule, updateOutcomeModuleFilters } from 'store/goalMode/thunks';
import { selectOutcomeModuleFilter } from 'store/filters/selectors';
import useSystemFields from 'hooks/useSystemFields';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions/lib/constants';

const defaultEmptyObject = defaultTo({});

const ACTIVE = { id: 'Active', title: 'Active' };
const INACTIVE = { id: 'Archived', title: 'Inactive' };

const undefinedOption = {
  id: null,
  title: 'Undefined',
};

const undefinedOptionForRoadmaps = {
  id: null,
  title: PORTFOLIO_LABEL,
};

const statusOptions = [ACTIVE, INACTIVE];

/**
 * @function useGoalsAdvancedSearch
 *
 * hook to use advancedd search data and actions for goals
 *
 * @param  {Boolean} isOpen
 * @return {Object}
 */
const useGoalsAdvancedSearch = () => {
  const dispatch = useDispatch();
  const outcomeModuleFilters = useSelector(selectOutcomeModuleFilter);
  const roadmaps = useSelector(state => getRoadmaps(state, true));
  const products = useSelector(state => getProducts(state, true));
  const products2 = useSelector(state => getProductsLevelTwo(state, true));
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);
  const [getSystemFieldName] = useSystemFields();
  const { canView } = usePermissions();

  const levelFilterOptions = [
    { id: '0', title: getSystemFieldName('objectiveCorp'), visible: canView(PERMISSION_RESOURCES.objectiveCorp) },
    { id: '1', title: getSystemFieldName('objective'), visible: canView(PERMISSION_RESOURCES.objective) },
    { id: '2', title: getSystemFieldName('keyResult1'), visible: canView(PERMISSION_RESOURCES.keyResult) },
    { id: '3', title: getSystemFieldName('keyResult2'), visible: canView(PERMISSION_RESOURCES.keyResult2) },
    { id: 'all', title: 'All levels', visible: true },
  ];

  const options = {
    ...(hasMetadataRoadmaps
      ? {
          roadmaps: [undefinedOptionForRoadmaps, ...roadmaps],
          products: [undefinedOption, ...products],
          products2: [undefinedOption, ...products2],
        }
      : {}),
    owners: [],
    status: statusOptions,
    level: levelFilterOptions.filter(opt => opt.visible),
  };

  const { filters, setFilters, removeFilter, changeFilter, addNewFilter, getFieldValue, getFieldOptions, getFieldFunction } =
    useAdvancedSearchPopover(options);

  useEffect(() => {
    setFilters({
      ...outcomeModuleFilters?.fields,
      op: defaultEmptyObject(outcomeModuleFilters?.op),
    });
  }, [outcomeModuleFilters]);

  const applyOutcomeModuleFilters = useCallback((filters, filterId) => {
    if (filterId) {
      dispatch(applySavedFilterOnOutcomeModule(filterId));

      return;
    }

    dispatch(updateOutcomeModuleFilters(filters));
  }, []);

  return {
    applyFilters: applyOutcomeModuleFilters,
    filters,
    removeFilter,
    changeFilter,
    addNewFilter,
    getFieldValue,
    getFieldOptions,
    getFieldFunction,
    hasMetadataRoadmaps,
  };
};

export default useGoalsAdvancedSearch;
