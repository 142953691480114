import React from 'react';
import styled from 'styled-components';

import History from 'components/History';
import useRequestHistory from './hooks/useRequestHistory';

const RequestsHistory = ({ customerRequest }) => {
  const { history, hasNext, fetchNextPage, systemFields, customFormat } = useRequestHistory(customerRequest?.id);

  if (!customerRequest?.id) return null;

  return (
    <Container>
      <History
        objects={history}
        hasNext={hasNext}
        fetchNextPage={fetchNextPage}
        systemFields={systemFields}
        customFormat={customFormat}
      />
    </Container>
  );
};

export default RequestsHistory;

const Container = styled.div``;
