import Axios from 'axios';
import throwRequestError from '../throwRequestError';

export default (ACTION_TYPE, page) => () => {
  return async (dispatch, getState) => {
    dispatch({ type: ACTION_TYPE });

    const payload = Axios.get('/api/userViewsOld/user')
      .then(response => {
        const savedViews = response.data;
        const views = savedViews.filter(v => v.page === page);

        if (views.length) {
          const { state, id } = views.pop();

          dispatch({
            type: `${ACTION_TYPE}_FULFILLED`,
            payload: {
              state,
              page,
              id,
            },
          });
        }
      })
      .catch(throwRequestError);

    return payload;
  };
};
