import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import formatDate from 'design-system/utils/formatDate';
import { getUsers } from 'store/users/selectors';
import { ColorCellRenderer, WYSIWYGCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { ColorCellEditor, RemirrorWYSIWYGCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { createMultipleActionsColumnDefGetter } from 'design-system/molecules/AgGridReact-New/columns';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { materialColors } from 'design-system/themes/default';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import usePermissions from 'hooks/permissions/usePermissions';

import { MAX_SNAPS_TO_COMPARE } from '../../../constants';

const checkCheckboxSelectionWithMax = ({ context, node: { selected = false } = {} }) => {
  const { totalSelectedSnaps = 0 } = context;

  return selected || totalSelectedSnaps < MAX_SNAPS_TO_COMPARE;
};

export default ({ onEditRoadmapHistorySnap, onDeleteRoadmapHistorySnap }) => {
  const users = useSelector(getUsers);

  const { canDelete } = usePermissions();

  const userCanDelete = canDelete(PERMISSION_RESOURCES.roadmapHistorySnapshot);

  const ownerValueFormatter = useCallback(
    ({ data }) => {
      const user = users.find(user => user?.id === data?.user_id);

      return user?.name || '';
    },
    [users],
  );

  const actionsColumnDef = useMemo(() => {
    return createMultipleActionsColumnDefGetter({
      field: 'actions',
      getVisibility: userCanDelete,
      actions: [
        {
          icon: DeleteIcon,
          color: materialColors.darkerGray,
          getVisibility: () => true,
          onClick: params => onDeleteRoadmapHistorySnap(params?.id, params?.user_view_id),
          disabled: () => !userCanDelete,
        },
      ],
    });
  }, [userCanDelete, onDeleteRoadmapHistorySnap]);

  const columnDefs = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Title',
        editable: true,
        sortable: true,
        showDisabledCheckboxes: true,
        onCellValueChanged: params => {
          onEditRoadmapHistorySnap(params.data.id, { [params.colDef.field]: params.newValue });
        },
        checkboxSelection: checkCheckboxSelectionWithMax,
      },
      {
        field: 'description',
        headerName: 'Description',
        sortable: true,
        editable: true,
        cellRenderer: WYSIWYGCellRenderer,
        cellEditor: RemirrorWYSIWYGCellEditor,
        cellEditorParams: {
          maxHeight: 150,
          autoFocus: true,
        },
        minWidth: 300,
        cellEditorPopup: true,
        comparator: stringComparator,
        onCellValueChanged: params => {
          onEditRoadmapHistorySnap(params.data.id, { [params.colDef.field]: params.newValue });
        },
      },
      {
        field: 'color',
        headerName: 'Color',
        sortable: false,
        maxWidth: 100,
        editable: true,
        cellEditorPopup: true,
        cellRenderer: ColorCellRenderer,
        cellEditor: ColorCellEditor,
        onCellValueChanged: params => {
          onEditRoadmapHistorySnap(params.data.id, { [params.colDef.field]: params.newValue });
        },
      },
      {
        field: 'user_id',
        headerName: 'Owner',
        sortable: true,
        valueFormatter: ownerValueFormatter,
        maxWidth: 120,
      },
      {
        field: 'created_at',
        headerName: 'Created at',
        sortable: true,
        valueFormatter: ({ data }) => formatDate(data?.created_at),
        maxWidth: 120,
      },
      actionsColumnDef,
    ],
    [ownerValueFormatter, actionsColumnDef],
  );

  return columnDefs;
};
