import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FeatureFlags } from '@dragonboat/config';

import { updateState } from 'store/summary';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import { getIdeasIntegrations, getOrganization } from 'store/organization/selectors';
import { getSlice as getSummarySlice } from 'store/summary/selectors';

import useFeatureFlags from 'hooks/useFeatureFlags';
import usePermissions from 'hooks/permissions/usePermissions';
import useCustomUserFields from 'hooks/useCustomUserFields';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const componentHOC = Component => {
  return props => {
    const { hasKeyResults, hasProducts, hasHierarchy, updateStateAction, slice } = props;

    const dispatch = useDispatch();
    const { canView } = usePermissions();

    const systemFields = useSelector(state => state.organization.organization.system_fields_name);
    const organization = useSelector(state => getOrganization(state));
    const lsState = useSelector(state => getSummarySlice(state, slice));
    const customFields = useSelector(state => getProjectsCustomFields(state));
    const udpateSummaryState = updateStateAction || (data => dispatch(updateState(data)));
    const integrations = useSelector(getIdeasIntegrations);
    const [tempState, _setTempState] = useState(lsState);
    const shouldShowPreCalculations = useFeatureFlags([FeatureFlags.HAS_OUTCOME_DASHBOARD]);
    const hasMetadataMultiSelect = useFeatureFlags([FeatureFlags.HAS_METADATA_MULTI_SELECT]);
    const hasMultiLevelPortfolioMetadata = useFeatureFlags([FeatureFlags.HAS_MULTI_LEVEL_PORTFOLIO_METADATA]);
    const hasTeams2 = useFeatureFlags([FeatureFlags.HAS_TEAMS_2]);
    const hasCompletedAllocation = useFeatureFlags([FeatureFlags.HAS_COMPLETED_ALLOCATION]);
    const hasPersonas = canView(PERMISSION_FEATURES.personas);
    const hasLifecycles = canView(PERMISSION_FEATURES.lifecycles);

    const { getAllCustomUserFields } = useCustomUserFields();

    const [errors, setFieldErrors] = useState({});
    const setTempState = data => {
      _setTempState({ ...tempState, ...data });
    };
    const { open, onCancel, groupByOptions, commonRowColOptions = [], colGroupOptions, rowGroupOptions } = props;
    // const [open, setOpen] = useState(false);
    // const onCancel = () => setOpen(false);
    // const onOpen = () => setOpen(true);
    const onApply = () => {
      if (!tempState.displayPreferences.displayFields.length) {
        setFieldErrors({ ...errors, displayFields: 'Please select at least one field' });
        return;
      }

      onCancel();
      setTimeout(() => udpateSummaryState(tempState), 500);
    };
    const _onChangeRowOrColData = (key, val) => {
      const stateToUpdate = { [key]: val };

      if (['dates', 'customers'].includes(val.key) && !lsState.isReadOnly) stateToUpdate.isReadOnly = true;
      else stateToUpdate.isReadOnly = lsState.isReadOnly;

      setTempState(stateToUpdate);
    };

    useEffect(() => {
      _setTempState(lsState);
      setFieldErrors({});
    }, [open]);

    return (
      <Component
        {...props}
        errors={errors}
        open={!!open}
        // onOpen={onOpen}
        onCancel={onCancel}
        onApply={onApply}
        rowDataOptions={rowGroupOptions || commonRowColOptions}
        colDataOptions={colGroupOptions || commonRowColOptions}
        groupByOptions={groupByOptions}
        lsState={tempState}
        updateState={setTempState}
        selectedColData={tempState.selectedColData}
        selectedRowData={tempState.selectedRowData}
        groupByData={tempState.groupByData}
        headerColor={tempState.headerColor}
        displayPreferences={tempState.displayPreferences}
        systemFields={systemFields}
        hasProducts={hasProducts}
        hasKeyResults={hasKeyResults}
        hasKeyResults2={organization.has_key_results_2}
        hasTeams2={hasTeams2}
        hasHierarchy={hasHierarchy}
        customFields={customFields}
        customUserFields={getAllCustomUserFields()}
        onChangeHeaderColor={(val, checked) => setTempState({ headerColor: { ...tempState.headerColor, [val]: checked } })}
        onChangeColData={col => _onChangeRowOrColData('selectedColData', col)}
        onChangeRowData={row => _onChangeRowOrColData('selectedRowData', row)}
        onChangeChipData={d => setTempState({ groupByData: d })}
        onChangeDisplayPref={(key, value) =>
          setTempState({ displayPreferences: { ...tempState.displayPreferences, [key]: value } })
        }
        orgIntegrations={integrations}
        shouldShowPreCalculations={shouldShowPreCalculations}
        hasMetadataMultiSelect={hasMetadataMultiSelect}
        hasMultiLevelPortfolioMetadata={hasMultiLevelPortfolioMetadata}
        hasCompletedAllocation={hasCompletedAllocation}
        hasPersonas={hasPersonas}
        hasLifecycles={hasLifecycles}
      />
    );
  };
};

export default componentHOC;
