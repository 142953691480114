import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import OktaCircleIcon from 'design-system/atoms/OktaCircleIcon/index';


import { redirectToOkta as redirectToOktaAction } from 'store/login';

import AuthMainButton from 'components/AuthMainButton';

export default props => {
  const dispatch = useDispatch();
  const redirectToOkta = orgId => dispatch(redirectToOktaAction(orgId));
  const [onOkta, setOnOkta] = useState(false);

  const zendeskLoaded = useSelector(state => state.app.zendeskLoaded);

  const _onClick = () => {
    redirectToOkta(queryString.parse(props.location.search).orgId);
    setOnOkta(true);
  };

  useEffect(() => {
    if (zendeskLoaded && window.zE) {
      /* eslint-disable */
      zE(() => {
        zE.hide();
      });
      /* eslint-enable */
    }
  }, [zendeskLoaded]);

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    if (queryString.parse(props.location.search).autologin) _onClick();
    return () => window.removeEventListener('message', receiveMessage);
  }, []);

  const receiveMessage = event => {
    let obj;

    try {
      obj = JSON.parse(event.data);
      if (obj.error_description) setOnOkta(false);
    } catch (err) {
      return;
    }
    window.parent.postMessage(event.data, '*');
  };

  return (
    <div style={{ paddingTop: 3, textAlign: 'center' }}>
      <Button variant="contained" color="primary" disabled={onOkta} onClick={_onClick}>
        <OktaCircleIcon /> Login with Okta
      </Button>
    </div>
  );
};

const Button = styled(AuthMainButton)`
  &&&& {
    margin: 0;
    width: 100%;
    height: 58px;
    svg {
      margin-right: 15px;
    }
  }
`;
