import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { indexBy, keys, prop } from 'ramda';

import { getNormalizedRoadmaps, getNormalizedProducts, getProductsLevelTwo } from 'store/roadmaps/selectors';
import { getAllCustomers } from 'store/customers/selectors';
import { getNormalizedTags } from 'store/tags/selectors';
import { getNormalizedUsers } from 'store/users/selectors';
import statusMap from 'store/customerRequests/constants/status';
import priorityMap from 'store/customerRequests/constants/priority';
import { getRandomColor } from 'utils';
import { UNDEFINED_LABEL } from 'constants/common';
import { getCustomerCustomFields, getCustomerRequestsCustomFields } from 'store/customFields/selectors';

import { GROUP_BY_CUSTOMER_SEGMENT, GROUP_BY_CUSTOMER_TYPE } from '../constants';

const byId = indexBy(prop('id'));

const addEachObjKeyAsId = dataObject => {
  if (!dataObject) {
    return {};
  }

  return keys(dataObject).reduce((acc, key) => {
    return {
      ...acc,
      [key]: { ...dataObject[key], id: key },
    };
  }, {});
};

const useGroupsData = () => {
  const allCustomers = useSelector(getAllCustomers);
  const customerCustomFields = useSelector(getCustomerCustomFields);

  const roadmaps = useSelector(getNormalizedRoadmaps);
  const tags = useSelector(getNormalizedTags);
  const products = useSelector(getNormalizedProducts);
  const products2 = useSelector(getProductsLevelTwo);
  const users = useSelector(getNormalizedUsers);
  const customFields = useSelector(getCustomerRequestsCustomFields);

  const customers = useMemo(() => byId(allCustomers), [allCustomers]);

  const statuses = useMemo(
    () => Object.entries(statusMap).map(([key, value]) => ({ ...value, title: value.label, key: +key })),
    [statusMap],
  );

  const priorities = useMemo(
    () => Object.entries(priorityMap).map(([key, value]) => ({ ...value, title: value.label, key: +key })),
    [priorityMap],
  );
  const owners = useMemo(
    () => Object.keys(users).reduce((acc, uId) => ({ ...acc, [uId]: { ...users[uId], color: getRandomColor() } }), {}),
    [users],
  );

  const customerSegmentCustomField = customerCustomFields.find(cf => cf.key.startsWith('segment_2_'));
  const customerTypeCustomField = customerCustomFields.find(cf => cf.key.startsWith('type_2_'));

  const mapCustomFieldsToEntities = customFields.reduce((acc, cf) => {
    return {
      ...acc,
      [cf.key]: addEachObjKeyAsId(cf.data_enhanced),
    };
  }, {});

  const mapSelectedGroupToEntities = {
    customer_id: customers,
    status: statuses,
    priority: priorities,
    tag_id: tags,
    roadmap_id: roadmaps,
    product_1_id: products,
    product_2_id: byId(products2),
    owner_id: owners,
    [GROUP_BY_CUSTOMER_SEGMENT]: addEachObjKeyAsId(customerSegmentCustomField?.data_enhanced),
    [GROUP_BY_CUSTOMER_TYPE]: addEachObjKeyAsId(customerTypeCustomField?.data_enhanced),
    ...mapCustomFieldsToEntities,
  };

  return groupKey => {
    const result = mapSelectedGroupToEntities[groupKey] || {};

    return {
      ...result,
      [null]: { id: null, title: UNDEFINED_LABEL },
    };
  };
};

export default useGroupsData;
