import React from 'react';
import Loadable from 'react-loadable';

import PageLoading from 'design-system/atoms/PageLoading/PageLoading';


Promise.all([
  import('./dhtmlx-scheduler/codebase/dhtmlxscheduler.css'),
  import('./dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css'),
]).then(() => {
  import('./scheduler.css');
});

export default Component =>
  Loadable({
    loading: PageLoading,
    loader: () => import('./Scheduler'),
    render: (_, props) => <Component {...props} />,
  });
