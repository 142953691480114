import { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { not, defaultTo, F } from 'ramda';

import { useReleaseCapacityAllocation } from 'hooks/releaseCapacityAllocation/useReleaseCapacityAllocation';
import usePageFilters from 'hooks/filters/usePageFilters';
import { fetchAllReleaseCapacityAllocation } from 'store/releaseCapacityAllocation';
import { getReleaseCapacityAllocationMetadataByGroupRow } from 'utils/releaseCapacityAllocation';
import { getEstimateColIdByTeamSkill } from 'utils/estimates';
import { getTimeframeOption } from '../../store/projects/helpers/groupOptions';

const defaultAsEmptyArray = defaultTo([]);

const CUSTOM_FIELD_PREFIX = 'custom_fields.';

/**
 * @function useProjectsGridDataWithReleaseCapacityAllocation
 *
 * Hook that appends the release capacity allocation on the projects array
 *
 * @param  {Array} projects
 * @param  {Object} selectedGroup1
 * @param  {Object} selectedGroup2
 * @param  {Boolean} releaseCapacityAllocationActive
 * @return {Array}
 */
const useProjectsGridDataWithReleaseCapacityAllocation = (
  projects,
  selectedGroup1,
  selectedGroup2,
  releaseCapacityAllocationActive = true,
) => {
  const dispatch = useDispatch();

  const { releaseCapacityAllocation } = useReleaseCapacityAllocation();
  const { filtersForApiByLevels, pageFilters } = usePageFilters();

  let metadataGroup = selectedGroup2;
  const timeframeGroupKey = getTimeframeOption(F).key;

  /*
   * On case the selected group 1 is not the timeframe group
   * we should trust on the selected group 1 as metadata group
   *
   * Otherwise we should trust on the selected group 2 as metadata group
   */
  if (selectedGroup1?.key !== timeframeGroupKey) {
    metadataGroup = selectedGroup1;
  }

  const metadataField = useMemo(() => {
    /*
     * Will trust always on the value of the key on the group
     * If key does not have value it means the group is not selected
     */
    if (not(metadataGroup?.key)) {
      return null;
    }

    /**
     * Custom fields are not a valid metadata for this
     */
    if (metadataGroup?.key.includes(CUSTOM_FIELD_PREFIX)) {
      return null;
    }

    return metadataGroup?.field;
  }, [metadataGroup]);

  useEffect(() => {
    dispatch(fetchAllReleaseCapacityAllocation(metadataField, filtersForApiByLevels));
  }, [metadataField, filtersForApiByLevels]);

  const data = useMemo(() => {
    // release capacity is not action dont do anything
    if (not(releaseCapacityAllocationActive)) {
      return projects;
    }

    return projects.map(row => {
      if (not(row.group)) {
        return row;
      }

      const { key } = getReleaseCapacityAllocationMetadataByGroupRow(row, pageFilters);

      const releaseCapacityAllocationOnRow = releaseCapacityAllocation[key];

      defaultAsEmptyArray(releaseCapacityAllocationOnRow?.resourcing).forEach(resource => {
        const colId = getEstimateColIdByTeamSkill(resource.teamId, resource.skillId);

        row[colId] = { ...row[colId], ...resource };
      });

      return row;
    });
  }, [projects, releaseCapacityAllocation, releaseCapacityAllocationActive, pageFilters]);

  return data;
};

export default useProjectsGridDataWithReleaseCapacityAllocation;
