import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { defaultTo, equals, isNil, not, pipe, prop, isEmpty, either } from 'ramda';

import { isEstimateValid } from '../helpers/validations';
import { useDebouncedCallback } from 'use-debounce';
import { createProjectEstimate, deleteProjectEstimate, updateProjectEstimate } from 'store/estimates';
import { v4 as uuidv4 } from 'uuid';
import { getCreateEstimateOperationData } from 'store/estimates/selectors';
import { selectIsLoadingEstimatesTab } from 'store/projectLightbox/selectors';

const ID = 'id';
const TEAM = 'team';
const SKILL = 'skill';
const NUMSTAFF = 'numStaff';

const getId = prop(ID);

const isFieldTeam = equals(TEAM);
const isFieldSkill = equals(SKILL);
const isNumStaff = equals(NUMSTAFF);
const isNotNil = pipe(isNil, not);
const isNilOrEmpty = either(isNil, isEmpty);

const hasValidId = pipe(getId, isNotNil);

export default function useProjectEstimatesLightboxTabCrud(selectedProject, formData = {}) {
  const dispatch = useDispatch();
  const createEstimateOperationData = useSelector(getCreateEstimateOperationData);
  const isLoadingEstimatesTab = useSelector(selectIsLoadingEstimatesTab);

  const projectEstimates = defaultTo([], selectedProject?.estimates || []);
  const [estimates, setEstimates] = useState(projectEstimates);

  const [estimatesBeingCreated, setEstimatesBeingCreated] = useState([]);

  useEffect(() => {
    if (!isLoadingEstimatesTab) {
      setEstimates(projectEstimates);
    }
  }, [isLoadingEstimatesTab]);

  useEffect(() => {
    if (!createEstimateOperationData) {
      return;
    }

    const { data } = createEstimateOperationData;

    if (!data.estimate || !estimatesBeingCreated.includes(data.estimate.uuid)) {
      return;
    }

    setEstimates(
      estimates.map(estimate => {
        if (estimate.uuid === data.estimate.uuid) {
          return {
            ...estimate,
            id: data.estimate.id,
          };
        }

        return estimate;
      }),
    );

    setEstimatesBeingCreated(estimatesBeingCreated.filter(creationUuid => creationUuid !== data.estimate.uuid));
  }, [createEstimateOperationData, estimates, estimatesBeingCreated]);

  const [debouncedEstimateUpdate] = useDebouncedCallback(estimate => dispatch(updateProjectEstimate(estimate)), 500);

  const onUpdateEstimate = (updateIndex, field, value) => {
    const updatedEstimate = { ...estimates[updateIndex] };

    if (!updatedEstimate) {
      return;
    }

    if (isFieldTeam(field)) {
      updatedEstimate.team = isNil(value) ? null : { id: value.id, title: value.title };
      updatedEstimate.team2 = null;
    } else if (isFieldSkill(field)) {
      updatedEstimate.skill = isNil(value) ? null : { id: value.id, title: value.title };
    } else if (isNumStaff(field)) {
      updatedEstimate.numStaff = isNilOrEmpty(value) ? 0 : value;
    } else {
      updatedEstimate[field] = value;
    }

    setEstimates(estimates.map((estimate, index) => (index === updateIndex ? updatedEstimate : estimate)));

    const shouldDebounce = ['numStaff', 'duration'].includes(field);

    if (hasValidId(updatedEstimate)) {
      if (shouldDebounce) {
        debouncedEstimateUpdate(updatedEstimate);
        return;
      }

      dispatch(updateProjectEstimate(updatedEstimate, selectedProject?.id));
      return;
    }

    const isAlreadyBeingCreated = estimatesBeingCreated.includes(updatedEstimate.uuid);

    if (isEstimateValid(updatedEstimate) && !isAlreadyBeingCreated) {
      dispatch(createProjectEstimate(selectedProject.id, updatedEstimate));

      setEstimatesBeingCreated([...estimatesBeingCreated, updatedEstimate.uuid]);
    }
  };

  const onDeleteEstimate = index => {
    const deletedEstimate = { ...estimates[index] };
    const newEstimatesList = estimates.filter((_, i) => i !== index);

    setEstimates(newEstimatesList);

    if (hasValidId(deletedEstimate)) {
      dispatch(deleteProjectEstimate(selectedProject.id, deletedEstimate));
    }
  };

  const onAddEstimate = () => {
    const blankEstimate = {
      numStaff: 1,
      duration: 14,
      team: '',
      skill: '',
      start_date: moment(formData.estimated_start_date || moment()).formatAsDate(),
      uuid: uuidv4(),
    };

    setEstimates([...estimates, blankEstimate]);
  };

  return {
    estimates,
    estimatesBeingCreated,
    onAddEstimate,
    onUpdateEstimate,
    onDeleteEstimate,
  };
}
