import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setPageFilters } from 'store/filters';
import { getPageFilters } from 'store/filters/selectors';
import { getSelectedRoadmapVersion } from 'store/roadmapVersions/selectors';

import parseFilters from 'utils/filters/parseFilters';
import { GLOBAL_FILTER } from 'constants/filters';

/**
 * hook responsible to manage filters when applying scenarios or going back to the PoR
 */
const useScenariosFilters = () => {
  const dispatch = useDispatch();

  const selectedRoadmapVersion = useSelector(getSelectedRoadmapVersion);
  const pageFilters = useSelector(getPageFilters);

  const previousPageFilters = useRef(pageFilters);
  const lastScenarioLoaded = useRef(selectedRoadmapVersion?.id);

  // this first useEffect aims to save page filters before selecting a scenario
  useEffect(() => {
    if (selectedRoadmapVersion || lastScenarioLoaded.current) return;

    previousPageFilters.current = pageFilters;
  }, [selectedRoadmapVersion, pageFilters]);

  // in this useEffect we check if we are selecting a scenario or not:
  // yes - apply the scenario filters
  // no - apply the previous page filters
  useEffect(() => {
    if (lastScenarioLoaded.current === selectedRoadmapVersion?.id) return;

    // todo: check the flow and if the fix was a proper fix
    // also check if we need to parse the filters every time. scenario filters are stored parsed already
    if (selectedRoadmapVersion) {
      const filters = selectedRoadmapVersion.page_filters;

      dispatch(setPageFilters(GLOBAL_FILTER, parseFilters(filters), false, null, false));

      lastScenarioLoaded.current = selectedRoadmapVersion?.id;
    } else if (!selectedRoadmapVersion) {
      dispatch(setPageFilters(GLOBAL_FILTER, parseFilters(previousPageFilters.current), false, null, false));

      lastScenarioLoaded.current = undefined;
    }
  }, [selectedRoadmapVersion?.id]);
};

export default useScenariosFilters;
