import { REQUEST_FORM_FIELDS } from '../constants';

const getFieldsAvailableToBeRequired = ({ customFields = [] }) => {
  const customFieldsKeys = customFields.map(field => field.key);

  return [
    REQUEST_FORM_FIELDS.details,
    REQUEST_FORM_FIELDS.desiredTime,
    REQUEST_FORM_FIELDS.links,
    REQUEST_FORM_FIELDS.owner,
    REQUEST_FORM_FIELDS.priority,
    REQUEST_FORM_FIELDS.status,
    REQUEST_FORM_FIELDS.watchers,
    REQUEST_FORM_FIELDS.externalKey,
    REQUEST_FORM_FIELDS.externalPortfolioKey,
    REQUEST_FORM_FIELDS.roadmap,
    REQUEST_FORM_FIELDS.tags,
    REQUEST_FORM_FIELDS.customers,
    REQUEST_FORM_FIELDS.personas,
    REQUEST_FORM_FIELDS.lifecycles,
    ...customFieldsKeys,
  ];
};

export default getFieldsAvailableToBeRequired;
