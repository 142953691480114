import axios from 'axios';

import {
  CREATE_GRID_LIFECYCLE,
  REMOVE_GRID_UNSAVED_LIFECYCLE,
  CREATE_LIFECYCLE_ROADMAP,
  DELETE_LIFECYCLE_ROADMAP,
  BULK_DELETE_LIFECYCLE_ROADMAP,
  CHANGE_ROW_HEIGHT_LIFECYCLE_GRID,
  CHANGE_HIDE_ARCHIVED_LIFECYCLE_GRID,
} from 'features/LifecyclesGrid/store/types';

const addNewLifecycle = () => ({
  type: CREATE_GRID_LIFECYCLE,
});

const removeUnsavedLifecycle = () => ({
  type: REMOVE_GRID_UNSAVED_LIFECYCLE,
});

const createLifecycleRoadmap = (lifecycleId, lifecycleRoadmap) => {
  return {
    payload: axios.post(`/api/v1/lifecycles/${lifecycleId}/lifecycleRoadmaps/`, lifecycleRoadmap).then(response => response.data),
    type: CREATE_LIFECYCLE_ROADMAP,
  };
};

const deleteLifecycleRoadmap = ({ id, roadmapId, subRoadmapId = null, product2Id = null }) => {
  let route = `/api/v1/lifecycles/${id}/lifecycleRoadmaps/${roadmapId}`;

  route += subRoadmapId ? `/${subRoadmapId}` : '';
  route += subRoadmapId && product2Id ? `/${product2Id}` : '';

  return {
    payload: axios.delete(route).then(response => response.data),
    type: DELETE_LIFECYCLE_ROADMAP,
  };
};

const bulkDeleteLifecycleRoadmaps = lifecycleId => {
  return {
    payload: axios.delete(`/api/v1/lifecycles/${lifecycleId}/lifecycleRoadmaps/`).then(response => response.data),
    type: BULK_DELETE_LIFECYCLE_ROADMAP,
  };
};

const changeRowHeight = height => ({
  type: CHANGE_ROW_HEIGHT_LIFECYCLE_GRID,
  payload: height,
});

const changeHideArchived = (hideArchived = false) => ({
  type: CHANGE_HIDE_ARCHIVED_LIFECYCLE_GRID,
  payload: hideArchived,
});

export {
  addNewLifecycle,
  removeUnsavedLifecycle,
  createLifecycleRoadmap,
  deleteLifecycleRoadmap,
  bulkDeleteLifecycleRoadmaps,
  changeRowHeight,
  changeHideArchived,
};
