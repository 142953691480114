import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pluck } from 'ramda';

import { createCustomer } from 'store/customers';
import { createTag } from 'store/tags';

import history from 'store/utils/history';
import { openProjectLightbox, setDateRangeMode } from 'store/projectLightbox';
import { createUser } from 'store/users';
import { selectKeyResults1, selectKeyResults2 } from 'store/objectives/selectors';
import { getCurrentUser } from 'store/login/selectors';
import {
  getHasAdvancedMetricReporting,
  getHasProjectMetrics,
  getIdeasIntegrations as getIdeasIntegrationsSelector,
  hasMultiLevelPortfolioMetadata as hasMultiLevelPortfolioMetadataSelector,
} from 'store/organization/selectors';
import { updateProjectPersonas, updateProjectLifecycles } from 'store/projects/thunks';

import { getVisibleFields } from 'store/projectLightbox/selectors';
import useLightboxesControlContext from 'hooks/lightboxes/useLightboxesControl';

import {
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_PRODUCTS_2,
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
  PERSONAS,
  LIFECYCLES,
} from 'constants/common';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import useOrganizationsAccessControl from '../../../hooks/useOrganizationsAccessControl';

const rawMultiselectUpdateFields = [
  'watchers',
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_PRODUCTS_2,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
];

const getIds = pluck('id');

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const { metadata, closeLightbox, isNew, updateFormData, selectedProject } = props;

    const metadataRef = React.useRef(metadata);

    metadataRef.current = metadata;

    const orgIntegrations = useSelector(getIdeasIntegrationsSelector);
    const { dateRangeMode } = useSelector(state => state.projectLightbox);
    const currentUser = useSelector(getCurrentUser);
    const keyResults1 = useSelector(selectKeyResults1);
    const keyResults2 = useSelector(state => selectKeyResults2(state, false));

    const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);
    const hasProjectMetrics = useSelector(getHasProjectMetrics);
    const hasMultiLevelPortfolioMetadata = useSelector(hasMultiLevelPortfolioMetadataSelector);
    const { isDodActive, isParentDragon, isDodShareProjectsEnabled } = useOrganizationsAccessControl();
    const { openMetricLightbox } = useLightboxesControlContext();

    const { canView } = usePermissions();
    const hasExternalKey = canView(PERMISSION_FEATURES.importProjectsWithExternalKey);

    const toggleMetricsDialog = (_, metricId) => {
      if (!metricId) {
        openMetricLightbox(null);
        return;
      }

      openMetricLightbox(metricId);
    };

    const changeMultiselectValue = (field, update) => {
      return value => {
        let rows = value;

        if (rawMultiselectUpdateFields.includes(field)) {
          return update({ [field]: rows });
        }

        value = value.map(val => parseInt(val));
        rows = metadataRef.current[field]
          .filter(row => value.indexOf(row.id) >= 0)
          .sort((row1, row2) => {
            return value.indexOf(row1.id) - value.indexOf(row2.id);
          });

        update({ [field]: rows });
      };
    };

    const createMultiselectOption = field => {
      let createAction;

      switch (field) {
        case 'customers':
          createAction = value => dispatch(createCustomer({ name: value }));
          break;
        case 'tags':
          createAction = value => dispatch(createTag({ title: value }));
          break;
        case 'watchers':
          createAction = value => {
            const [firstName, lastName] = value.trim().split(' ');

            return dispatch(createUser({ first_name: firstName, last_name: lastName }));
          };
          break;
        default:
          throw new Error(`Please add action handler to ${field}`);
      }

      return value => createAction(value);
    };

    const visibleFields = useSelector(state => getVisibleFields(state));

    const navigateToUrlAndCloseLightbox = url => {
      if (url) {
        history.push(url);
        closeLightbox();
      }
    };

    const onUpdateProjectPersonas = updatedPersonasIds => {
      if (isNew) {
        const updatedPersonas = metadataRef?.current?.[PERSONAS]?.filter(row => updatedPersonasIds.includes(row.id));

        return updateFormData({ personas: updatedPersonas });
      }

      return updateProjectPersonas(selectedProject?.id, updatedPersonasIds, getIds(selectedProject?.personas));
    };

    const onUpdateProjectLifecycles = updatedLifecyclesIds => {
      if (isNew) {
        const updatedLifecycles = metadataRef?.current?.[LIFECYCLES]?.filter(row => updatedLifecyclesIds.includes(row.id));

        return updateFormData({ lifecycles: updatedLifecycles });
      }

      return dispatch(updateProjectLifecycles(selectedProject?.id, updatedLifecyclesIds, getIds(selectedProject?.lifecycles)));
    };

    return (
      <Component
        {...props}
        parent={props.parent}
        orgIntegrations={orgIntegrations}
        openProjectLightbox={id => dispatch(openProjectLightbox(id))}
        changeMultiselectValue={changeMultiselectValue}
        createMultiselectOption={createMultiselectOption}
        dateRangeMode={dateRangeMode || 'duration'}
        changeDateRangeMode={val => dispatch(setDateRangeMode(val))}
        visibleFields={visibleFields}
        keyResults1={keyResults1}
        keyResults2={keyResults2}
        hasMultiLevelPortfolioMetadata={hasMultiLevelPortfolioMetadata}
        isDodActive={isDodActive}
        isParentDragon={isParentDragon}
        isDodShareProjectsEnabled={isDodShareProjectsEnabled}
        hasAdvancedMetricReporting={hasAdvancedMetricReporting}
        toggleMetricsDialog={toggleMetricsDialog}
        currentUser={currentUser}
        hasProjectMetrics={hasProjectMetrics}
        navigateToUrlAndCloseLightbox={navigateToUrlAndCloseLightbox}
        onUpdateProjectPersonas={onUpdateProjectPersonas}
        onUpdateProjectLifecycles={onUpdateProjectLifecycles}
        hasExternalKey={hasExternalKey}
      />
    );
  };
};

export default componentHOC;
