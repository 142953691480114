/* eslint-disable max-len */
import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={51.845}
      height={49.694}
      viewBox="0 0 51.845 49.694"
      {...props}
    >
      <defs>
        <style>
          {
            '.prefix__a,.prefix__b{fill:#5accc6;stroke:#5accc6}.prefix__a{stroke-width:.8px}'
          }
        </style>
      </defs>
      <path
        className="prefix__a"
        d="M35.624 43.104h1.81c.527 2.816-.329 5.128-3.113 6.019a3.991 3.991 0 01-1.2.133c-9.37.008-18.741-.033-28.11.037A4.669 4.669 0 01.4 44.649C.471 32.94.435 21.231.42 9.521A1.479 1.479 0 011 8.265C3.882 5.793 6.735 3.287 9.62.818a1.794 1.794 0 011.07-.405Q21.914.379 33.138.402a4.3 4.3 0 014.415 4.434c.009 2.54 0 5.079 0 7.619v.714h-1.928v-.808-7.349c0-1.9-.745-2.674-2.645-2.678-5.955-.01-11.91 0-17.864-.018-.6 0-.767.184-.762.77.025 2.989.011 5.978.011 8.967v.851H7.4v-1.851c.209-.017.442-.051.676-.052 1.258-.005 2.517-.016 3.775.006.436.008.592-.138.589-.579q-.021-3.742 0-7.484c0-.469-.155-.613-.622-.626a2.205 2.205 0 00-1.644.575A659.792 659.792 0 012.9 9.219a1.381 1.381 0 00-.547 1.147q.02 17.193.012 34.387c0 1.762.779 2.568 2.549 2.571q14.123.017 28.245 0a2.195 2.195 0 002.465-2.456c.011-.577 0-1.154 0-1.766"
      />
      <path
        className="prefix__a"
        d="M51.445 28.236a12.206 12.206 0 11-12.159-12.267 12.414 12.414 0 0112.158 12.272m-22.435-.064a10.25 10.25 0 1010.324-10.233 10.09 10.09 0 00-10.324 10.225M13.859 37.016h14.062v1.872H13.859zM24.932 32.345H13.868v-1.858a4.16 4.16 0 01.566-.072c3.277-.006 6.555.007 9.832-.019.554 0 .713.19.672.7-.032.4-.006.8-.006 1.248M13.88 23.932h10.936v1.88H13.88zM7.656 23.573h2.566v2.58H7.656zM7.654 30.133h2.571v2.56H7.654zM7.656 36.631h2.568v2.565H7.656z"
      />
      <path
        className="prefix__b"
        d="M39.197 31.097c.453-.535.879-1.021 1.283-1.526a.827.827 0 01.779-.337 4.581 4.581 0 011.829.478 2.43 2.43 0 011.384 2.015c.059.749.012 1.506.012 2.283h-1.043c0-.568-.018-1.128 0-1.687.063-1.619-.939-1.921-1.982-2a.567.567 0 00-.379.208c-.46.521-.9 1.059-1.352 1.59-.367.432-.646.435-1.034.01-.486-.531-.973-1.061-1.448-1.6a.528.528 0 00-.569-.2c-.093.023-.189.029-.284.043a1.5 1.5 0 00-1.4 1.587c-.009.552 0 1.1 0 1.656v.4h-1.028a2.39 2.39 0 01-.031-.271V32.27a2.724 2.724 0 012.413-2.95c.153-.03.308-.047.462-.071a1.082 1.082 0 011.128.441c.372.482.813.91 1.26 1.407zM41.94 25.269a2.855 2.855 0 01-.539 1.966 2.658 2.658 0 01-2.88 1.06 2.853 2.853 0 01-2.032-2.645 5.791 5.791 0 01.116-1.534 2.775 2.775 0 012.849-2.066 2.874 2.874 0 012.485 2.747c.005.143.001.288.001.472zm-1.077-.07c0-.215.016-.432 0-.646a1.591 1.591 0 00-1.6-1.452 1.571 1.571 0 00-1.672 1.36 6.314 6.314 0 000 1.5 1.66 1.66 0 002.628 1.071 1.867 1.867 0 00.645-1.838z"
      />
    </svg>
  );
}

export default SvgComponent;
