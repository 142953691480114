import { List, Map, fromJS } from 'immutable';
import { prop, indexBy, pipe, propOr, defaultTo, uniqBy } from 'ramda';

import {
  getCollectionNameFromProject,
  updateProjectOnCollectionsUsingMethod,
  findProjectInAllCollections,
} from 'store/projects/helpers/collections';

import {
  CREATE_PROJECT_ESTIMATE_FULFILLED,
  UPDATE_PROJECT_ESTIMATE_FULFILLED,
  DELETE_PROJECT_ESTIMATE_FULFILLED,
  UPDATE_ESTIMATES_FULFILLED,
  BULK_UPDATED_PROJECT_ESTIMATES_FULFILLED,
} from '../types';

const indexById = indexBy(prop('id'));
const defaultToEmptyArray = defaultTo([]);

const projectEstimatesReducer = (state, action) => {
  switch (action.type) {
    case BULK_UPDATED_PROJECT_ESTIMATES_FULFILLED:
      const estimates = action.payload;

      if (!estimates) {
        return state;
      }

      const updatedProjects = estimates.reduce((updatedProjects, estimate) => {
        const projectId = estimate.project_id;

        const projectInCurrentUpdateList = updatedProjects.find(p => +p.id === +projectId);

        const project =
          projectInCurrentUpdateList ||
          findProjectInAllCollections(
            state,
            p => +p.id === +projectId || (p.estimates && !!p.estimates.find(est => est.id === estimate.id)),
          );

        if (!project) {
          return updatedProjects;
        }

        if (project.estimates) {
          project.estimates = project.estimates.map(projectEstimate => {
            return projectEstimate.id === estimate.id ? { ...projectEstimate, ...estimate } : projectEstimate;
          });
        }

        return uniqBy(prop('id'), [...updatedProjects, project]);
      }, []);

      const updatedState = updatedProjects.reduce((newState, project) => {
        const collectionName = getCollectionNameFromProject(project);
        const collectionAsFilterName = getCollectionNameFromProject(project, true);

        const collection = state[collectionName].toJS().map(row => (row.id === project.id ? project : row));
        const collectionAsFilter = state[collectionAsFilterName].toJS().map(row => (row.id === project.id ? project : row));

        return {
          ...newState,
          [collectionName]: fromJS(collection),
          [collectionAsFilterName]: fromJS(collectionAsFilter),
        };
      }, {});

      return {
        ...state,
        ...updatedState,
      };
    case UPDATE_PROJECT_ESTIMATE_FULFILLED:
    case CREATE_PROJECT_ESTIMATE_FULFILLED: {
      let {
        payload: { data },
      } = action;

      if (!data) data = action.payload;
      const addedTask = data.task;
      const addedEstimate = data.estimate ? data.estimate : data;

      const projectId = addedEstimate.project_id;

      // find project by projectId or current estimate id

      const project = findProjectInAllCollections(
        state,
        p => +p.id === +projectId || (p.estimates && !!p.estimates.find(est => est.id === data.id)),
      );

      if (!project) return state;

      project.estimates = project.estimates
        ? [...project.estimates.filter(est => est.id !== addedEstimate.id), addedEstimate]
        : [addedEstimate];

      if (addedTask) {
        project.tasks = project.tasks ? [...project.tasks.filter(task => task.id !== addedTask.id), addedTask] : [addedTask];
      }

      const collectionName = getCollectionNameFromProject(project);
      const collectionAsFilterName = getCollectionNameFromProject(project, true);

      const collection = state[collectionName].toJS().map(row => (row.id === project.id ? project : row));
      const collectionAsFilter = state[collectionAsFilterName].toJS().map(row => (row.id === project.id ? project : row));

      return {
        ...state,
        [collectionName]: fromJS(collection),
        [collectionAsFilterName]: fromJS(collectionAsFilter),
      };
    }
    case DELETE_PROJECT_ESTIMATE_FULFILLED: {
      if (!action.payload) return state;

      const { estimate } = action.payload;

      if (!estimate) return state;
      const projectId = estimate.project_id;
      const project = findProjectInAllCollections(
        state,
        p => +p.id === +projectId || (p.estimates && !!p.estimates.find(est => est.id === estimate.id)),
      );

      if (!project) return state;

      project.estimates = defaultToEmptyArray(project.estimates).filter(est => est.id !== estimate.id);

      const collectionName = getCollectionNameFromProject(project);
      const collectionAsFilterName = getCollectionNameFromProject(project, true);

      const collection = state[collectionName].toJS().map(row => (row.id === project.id ? project : row));
      const collectionAsFilter = state[collectionAsFilterName].toJS().map(row => (row.id === project.id ? project : row));

      return {
        ...state,
        [collectionName]: fromJS(collection),
        [collectionAsFilterName]: fromJS(collectionAsFilter),
      };
    }
    case UPDATE_ESTIMATES_FULFILLED: {
      if (!action.payload || !action.payload.estimates) {
        return state;
      }

      const affectedProjectsById = pipe(propOr([], 'projects'), indexById)(action.payload);

      const _updateEstimates = collection => {
        return action.payload.estimates.reduce((currentCollection, estimate) => {
          const projectIndex = currentCollection.findIndex(project => +project.get('id') === +estimate.project_id);

          if (projectIndex === -1) {
            return currentCollection;
          }

          const collectionWithProjectsTargetTimelineUpdated = currentCollection.updateIn([projectIndex], project => {
            const updatedProject = affectedProjectsById[project.get('id')];

            if (updatedProject) {
              return project.merge({
                deadline: updatedProject.deadline,
                estimated_start_date: updatedProject.estimated_start_date,
              });
            }

            return project;
          });

          return collectionWithProjectsTargetTimelineUpdated.updateIn([projectIndex, 'estimates'], estimates => {
            if (!estimates) {
              return List.of(fromJS(estimate));
            }

            const estimateIndex = estimates.findIndex(e => {
              if (Map.isMap(e)) {
                return e.get('id') === estimate.id;
              }
              return e.id === estimate.id;
            });

            if (estimateIndex === -1) {
              return estimates.push(fromJS(estimate));
            }

            return estimates.update(estimateIndex, estimateImmutable =>
              fromJS({
                ...estimateImmutable.toJS(),
                ...estimate,
              }),
            );
          });
        }, collection);
      };

      return {
        ...state,
        ...updateProjectOnCollectionsUsingMethod(state, _updateEstimates),
      };
    }
    default: {
      return state;
    }
  }
};

export { projectEstimatesReducer };
