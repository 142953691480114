import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';

const mapFields = {
  key_result_1: 'keyResult1',
  key_result_2: 'keyResult2',
  product_1: 'product1',
  product_2: 'product2',
};

const mapLayers = {
  bet: BET_LAYER,
  initiative: INITIATIVE_LAYER,
};

const updateProjectGroupData = (project, groupType, groupData, targetId) => {
  const isCustomField = groupType?.field?.startsWith('custom_fields.');
  const isStringGrouping = ['status_color', 'planningStage'].includes(groupType?.key) || isCustomField;
  const initialTargetId = isStringGrouping ? targetId : +targetId;
  const id = targetId === 'N/A' || targetId === 'null' ? null : initialTargetId;

  if (['bet', 'initiative'].includes(groupType?.key)) {
    project.parent_id = id;
    project.parentLayer = mapLayers[groupType?.key];
  } else if (isCustomField) {
    const customGroupField = groupType?.field?.replace('custom_fields.', '');

    project[customGroupField] = id;
  } else {
    project[groupType?.key] = isStringGrouping ? groupData.find(l => l.id === id)?.id : groupData.find(l => l.id === id);

    if (!isStringGrouping) {
      project[groupType?.field] = id;
    }
  }
};

export { mapFields, updateProjectGroupData };
