import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { last, dropLast } from 'ramda';

import { getGoBackUrls, setGoBackUrls } from 'store/app';
import history from 'store/utils/history';

/**
 * useNavigationToPathWithGoBackUrl allows to navigate to a new page while also saving
 * the current page url on a go back url stack (LIFO)
 * Navigate to go back will push the page to the most recent url added and removed it form the list
 */

const useNavigationToPathWithGoBackUrl = () => {
  const dispatch = useDispatch();
  const goBackUrls = useSelector(getGoBackUrls);

  const navigateToWithGoBackUrl = useCallback(
    path => {
      dispatch(setGoBackUrls([...goBackUrls, `${window?.location?.pathname}${window?.location?.search}`]));

      history?.push(path);
    },
    [dispatch, history, goBackUrls],
  );

  const navigateBack = useCallback(
    (defaultUrl = '/') => {
      const currentGoBackUrl = last(goBackUrls) || defaultUrl;

      dispatch(setGoBackUrls(dropLast(1, goBackUrls)));

      history?.push(currentGoBackUrl);
    },
    [dispatch, history, goBackUrls],
  );

  return {
    navigateToWithGoBackUrl,
    navigateBack,
  };
};

export default useNavigationToPathWithGoBackUrl;
