import axios from 'axios';
import moment from 'moment-timezone';
import { isEmpty, not, pipe, defaultTo } from 'ramda';

import throwRequestError from '../throwRequestError';

import { getOrganizationAccessControlData } from '../../accessControl/selectors';

const hasAccessesConfigured = pipe(defaultTo([]), isEmpty, not);

export default (ACTION_TYPE, endpoint, getData, getLasCallsDate) => (options = {}, ignoreCache = false) => (
  dispatch,
  getState,
) => {
  const lastCallsDate = getLasCallsDate(getState());

  // TODO: evaluate other solution to skip cache when we have DoDv2 recently integrated
  // For now we should ignore cache since we need to fetch other org metadata that can have been modified in different dates
  const orgAccessControl = getOrganizationAccessControlData(getState());
  const forceIgnoreCache = ignoreCache || hasAccessesConfigured(orgAccessControl);

  if (lastCallsDate && !forceIgnoreCache) {
    options.headers = { 'If-Modified-Since': `${moment.utc(lastCallsDate).format('ddd, DD MMM YYYY HH:mm:ss')} GMT` };
    // return new Promise((res, rej) => {
    //   const currentData = getData(getState());

    //   res({ data: currentData, cached: true });
    // });
  }

  const payload = axios.get(endpoint, options).catch(err => {
    if (err?.response?.status === 304) {
      const currentData = getData(getState());

      return { data: currentData, cached: true };
    }

    return throwRequestError(err);
  });

  dispatch({ payload, type: ACTION_TYPE, meta: { ignoreCache } });

  return payload;
};
