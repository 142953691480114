import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { isLoading, getData, getError, isUninitialized } from 'utils/store/thunk';
import { FETCH_ADO_PROJECTS } from './types/adoTypes';
import { FETCH_RALLY_WORKSPACES, FETCH_RALLY_PROJECTS } from './types/rallyTypes';
import {
  ADD_PROJECT_INTEGRATION,
  SYNC_PROJECTS_JIRA_STORIES,
  UPDATE_PROJECT_STORIES,
  CREATE_PROJECT_INTEGRATION,
  TEST_CONNECTION,
  GET_JIRA_PERMISSIONS,
  FETCH_JIRA_FIELDS,
} from './types';
import {
  FETCH_PROJECT_INTEGRATIONS,
  UPDATE_PROJECT_INTEGRATION,
  REMOVE_PROJECT_INTEGRATION,
  UPDATE_INTEGRATION_PROJECT_FROM_PROJECT,
  REVALIDATE_USER_INTEGRATION_AUTH,
  FETCH_INTEGRATION_FIELDS,
  ENABLE_INTEGRATION_WEBHOOKS,
  DISABLE_INTEGRATION_WEBHOOKS,
  UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS,
  IMPORT_COUNT_INTEGRATION_ITEMS,
  IMPORT_INSERT_INTEGRATION_ITEMS,
  IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC,
  UPDATE_PROJECTS_MAPPED_FIELDS,
} from './types/types';
import isNull from 'lodash/isNull';

const isUndefined = data => data === undefined;
const isNullOrUndefined = data => isNull(data) || isUndefined(data);

const getOperations = state => {
  return state.integrations.operations;
};

// Ado
const isFetchADOProjectLoading = createSelector(getOperations, state => isLoading(state, FETCH_ADO_PROJECTS));
const getADOProjects = createSelector(getOperations, state => getData(state, FETCH_ADO_PROJECTS));
const getADOError = createSelector(getOperations, state => getError(state, FETCH_ADO_PROJECTS));
const isFetchADOUninitialized = createSelector(getOperations, state => isUninitialized(state, FETCH_ADO_PROJECTS));
// Rally
const getRallyWorkspaces = createSelector(getOperations, state => getData(state, FETCH_RALLY_WORKSPACES));
const isFetchRallyWorkspacesLoading = createSelector(getOperations, state => isLoading(state, FETCH_RALLY_WORKSPACES));
const isFetchRallyWorkspacesUninitialized = createSelector(getOperations, state =>
  isUninitialized(state, FETCH_RALLY_WORKSPACES),
);
const getFetchRallyWorkspacesError = createSelector(getOperations, state => getError(state, FETCH_RALLY_WORKSPACES));
const getRallyProjects = createSelector(getOperations, state => getData(state, FETCH_RALLY_PROJECTS));
const isFetchRallyProjectsLoading = createSelector(getOperations, state => isLoading(state, FETCH_RALLY_PROJECTS));
const getRallyProjectsError = createSelector(getOperations, state => getError(state, FETCH_RALLY_PROJECTS));
const isFetchRallyProjectsUninitialized = createSelector(getOperations, state => isUninitialized(state, FETCH_RALLY_PROJECTS));
// Project Integrations
const getProjectIntegrations = createSelector(getOperations, state => getData(state, FETCH_PROJECT_INTEGRATIONS));
const isLoadingProjectIntegrations = createSelector(getOperations, state => isLoading(state, FETCH_PROJECT_INTEGRATIONS));
const getProjectIntegrationsError = createSelector(getOperations, state => getError(state, FETCH_PROJECT_INTEGRATIONS));
const getProjectIntegrationsData = createSelector(getOperations, state => getData(state, FETCH_PROJECT_INTEGRATIONS));
const isLoadingAddProjectIntegration = createSelector(getOperations, state => isLoading(state, ADD_PROJECT_INTEGRATION));
const getAddProjectIntegrationError = createSelector(getOperations, state => getError(state, ADD_PROJECT_INTEGRATION));
const isLoadingRemoveProjectIntegration = createSelector(getOperations, state => isLoading(state, REMOVE_PROJECT_INTEGRATION));
const getRemoveProjectIntegrationError = createSelector(getOperations, state => getError(state, REMOVE_PROJECT_INTEGRATION));
const isLoadingCreateProjectIntegration = createSelector(getOperations, state => isLoading(state, CREATE_PROJECT_INTEGRATION));
const getCreateProjectIntegrationError = createSelector(getOperations, state => getError(state, CREATE_PROJECT_INTEGRATION));
const getTestConnectionError = createSelector(getOperations, state => getError(state, TEST_CONNECTION));
const getUpdateIntegrationProjectFromProjectError = createSelector(getOperations, state =>
  getError(state, UPDATE_INTEGRATION_PROJECT_FROM_PROJECT),
);
const isLoadingRevalidateUserIntegration = createSelector(getOperations, state =>
  isLoading(state, REVALIDATE_USER_INTEGRATION_AUTH),
);
const getFetchIntegrationFieldsError = createSelector(getOperations, state => getError(state, FETCH_INTEGRATION_FIELDS));

// Webhooks
const getEnableIntegrationWebhooksError = createSelector(getOperations, state => getError(state, ENABLE_INTEGRATION_WEBHOOKS));
const getDisableIntegrationWebhooksError = createSelector(getOperations, state => getError(state, DISABLE_INTEGRATION_WEBHOOKS));
const getUpdateIntegrationWebhooksProjectRestrictionsError = createSelector(getOperations, state =>
  getError(state, UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS),
);

// Import
const getImportCountIntegrationItemsError = createSelector(getOperations, state =>
  getError(state, IMPORT_COUNT_INTEGRATION_ITEMS),
);
const getImportInsertIntegrationItemsError = createSelector(getOperations, state =>
  getError(state, IMPORT_INSERT_INTEGRATION_ITEMS),
);
const getImportInsertIntegrationItemsAsyncError = createSelector(getOperations, state =>
  getError(state, IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC),
);

// Permissions
const getJiraPermissionsData = createSelector(getOperations, state => getData(state, GET_JIRA_PERMISSIONS));
const getJiraPermissionsError = createSelector(getOperations, state => getError(state, GET_JIRA_PERMISSIONS));
const isLoadingGetJiraPermissions = createSelector(getOperations, state => isLoading(state, GET_JIRA_PERMISSIONS));

// Verifies if current project has been initialized or not
const isProjectIntegrationsUninitializedById = createSelector([getProjectIntegrationsData, (_, id) => id], (state, id) => {
  const integrations = state?.data;

  // If project id changes, lightbox navigation, we should assume that it wasn't initialized
  return isNullOrUndefined(integrations) || (!isEmpty(integrations) && integrations[0]?.project_id !== id);
});

const emptyArray = [];
const selectHasSurnameFirstOnJira = createSelector(
  state => state.organization?.integrations || emptyArray,
  (_, orgIntegrationId) => orgIntegrationId,
  (integrations, orgIntegrationId) =>
    integrations.find(eachOrgInt => +eachOrgInt?.id === orgIntegrationId)?.has_surname_first_on_jira,
);

const isFetchingJiraStories = createSelector(getOperations, state => isLoading(state, SYNC_PROJECTS_JIRA_STORIES));
const isUpdatingProjectStories = createSelector(getOperations, state => isLoading(state, UPDATE_PROJECT_STORIES));
const getUpdatingProjectStoriesError = createSelector(getOperations, state => getError(state, UPDATE_PROJECT_STORIES));

const isUpdateProjectIntegrationLoading = createSelector(getOperations, state => isLoading(state, UPDATE_PROJECT_INTEGRATION));
const getUpdateProjectIntegrationData = createSelector(getOperations, state => getData(state, UPDATE_PROJECT_INTEGRATION));
const getUpdateProjectIntegrationError = createSelector(getOperations, state => getError(state, UPDATE_PROJECT_INTEGRATION));
const isUpdateProjectIntegrationUninitialized = createSelector(getOperations, state =>
  isUninitialized(state, UPDATE_PROJECT_INTEGRATION),
);

const isUpdateProjectsMappedFieldsLoading = createSelector(getOperations, state =>
  isLoading(state, UPDATE_PROJECTS_MAPPED_FIELDS),
);

const isLoadingJiraFields = createSelector(getOperations, state => isLoading(state, FETCH_JIRA_FIELDS));

export {
  // Jira
  isFetchingJiraStories,
  isUpdatingProjectStories,
  getUpdatingProjectStoriesError,
  selectHasSurnameFirstOnJira,
  isLoadingJiraFields,
  // ADO
  isFetchADOProjectLoading,
  getADOProjects,
  getADOError,
  isFetchADOUninitialized,
  // Rally
  getRallyWorkspaces,
  isFetchRallyWorkspacesLoading,
  isFetchRallyWorkspacesUninitialized,
  getFetchRallyWorkspacesError,
  getRallyProjects,
  isFetchRallyProjectsLoading,
  isFetchRallyProjectsUninitialized,
  getRallyProjectsError,
  // Project Integrations
  getProjectIntegrations,
  isLoadingProjectIntegrations,
  getProjectIntegrationsError,
  isProjectIntegrationsUninitializedById,
  isUpdateProjectIntegrationLoading,
  getUpdateProjectIntegrationData,
  getUpdateProjectIntegrationError,
  isUpdateProjectIntegrationUninitialized,
  isLoadingAddProjectIntegration,
  getAddProjectIntegrationError,
  isLoadingRemoveProjectIntegration,
  getRemoveProjectIntegrationError,
  isLoadingCreateProjectIntegration,
  getCreateProjectIntegrationError,
  getTestConnectionError,
  getUpdateIntegrationProjectFromProjectError,
  isLoadingRevalidateUserIntegration,
  getFetchIntegrationFieldsError,
  // Webhooks
  getEnableIntegrationWebhooksError,
  getDisableIntegrationWebhooksError,
  getUpdateIntegrationWebhooksProjectRestrictionsError,
  // Import
  getImportCountIntegrationItemsError,
  getImportInsertIntegrationItemsError,
  getImportInsertIntegrationItemsAsyncError,
  // Permissions
  getJiraPermissionsData,
  getJiraPermissionsError,
  isLoadingGetJiraPermissions,
  isUpdateProjectsMappedFieldsLoading,
};
