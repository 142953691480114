import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { pluck } from 'ramda';

import {
  updateCustomerRequestOnGrid,
  updateCustomerRequestPersonas,
  updateCustomerRequestLifecycles,
} from 'store/customerRequests';
import useRoadmapAutocompletes from 'hooks/useRoadmapAutocompletes';

import { PERSONAS, LIFECYCLES } from 'constants/common';

const OWNER_NAME = 'owner_name';
const ROADMAP_TITLE = 'roadmap_title';
const PRODUCT_TITLE = 'product1_title';
const PRODUCT_2_TITLE = 'product2_title';
const CUSTOMERS = 'customers';
const TAGS = 'tags';

const FIELD_UPDATE_KEY_MAPPER = {
  [OWNER_NAME]: 'ownerName',
  [ROADMAP_TITLE]: 'roadmap_id',
  [PRODUCT_TITLE]: 'product_1_id',
  [PRODUCT_2_TITLE]: 'product_2_id',
  [CUSTOMERS]: 'customer_ids',
  [TAGS]: 'tag_ids',
};

const getIds = pluck('id');
/**
 * @function useUpdateCustomerRequestOnGrid
 *
 * Hook to handle with grid updates
 *
 * @return {Object}
 */
const useUpdateCustomerRequestOnGrid = () => {
  const dispatch = useDispatch();

  const { roadmaps, products, products2 } = useRoadmapAutocompletes({
    showArchived: true,
  });

  const allRoadmapsByTitle = useMemo(
    () =>
      roadmaps.reduce((roadmapsByTitle, roadmap) => {
        return {
          ...roadmapsByTitle,
          [roadmap.title]: roadmap,
        };
      }, {}),
    [roadmaps],
  );
  const allProductsByTitle = useMemo(
    () =>
      products.reduce((productsByTitle, product) => {
        return {
          ...productsByTitle,
          [product.title]: product,
        };
      }, {}),
    [products],
  );
  const allProducts2ByTitle = useMemo(
    () =>
      products2.reduce((products2ByTitle, product) => {
        return {
          ...products2ByTitle,
          [product.title]: product,
        };
      }, {}),
    [products],
  );

  const processUpdateData = useCallback(
    updatedData => {
      return Object.entries(updatedData).reduce((data, [key, value]) => {
        let newValue = value;
        const newKey = FIELD_UPDATE_KEY_MAPPER[key] || key;

        switch (key) {
          case ROADMAP_TITLE:
            newValue = allRoadmapsByTitle[value]?.id || null;
            data = {
              ...data,
              product_1_id: null,
              product_2_id: null,
            };

            break;
          case PRODUCT_TITLE:
            const product = allProductsByTitle[value];
            const roadmap = product && roadmaps.find(r => r.products.some(p => p.id === product.id));

            newValue = product?.id || null;
            data = {
              ...data,
              ...(roadmap ? { roadmap_id: roadmap.id } : {}),
              product_2_id: null,
            };

            break;
          case PRODUCT_2_TITLE:
            const product2 = allProducts2ByTitle[value];

            newValue = product2?.id || null;
            data = {
              ...data,
              ...(product2 ? { roadmap_id: product2.roadmap_id, product_1_id: product2.parent_id } : {}),
            };

            break;
          case CUSTOMERS:
          case TAGS:
            newValue = value.map(c => c.id);
            break;
          case LIFECYCLES:
          case PERSONAS:
            newValue = getIds(value);
            break;
          default:
            break;
        }

        return {
          ...data,
          [newKey]: newValue,
        };
      }, {});
    },
    [roadmaps, allRoadmapsByTitle, allProductsByTitle],
  );

  const updateCustomerRequestByIdOnCellValueChange = useCallback(
    (id, updatedData, previousValue, params) => {
      const newData = processUpdateData(updatedData);

      if (newData?.[PERSONAS]) {
        return dispatch(updateCustomerRequestPersonas(id, newData?.[PERSONAS], getIds(params?.oldValue)));
      }
      if (newData?.[LIFECYCLES]) {
        return dispatch(updateCustomerRequestLifecycles(id, newData?.[LIFECYCLES], getIds(params?.oldValue)));
      }

      return dispatch(updateCustomerRequestOnGrid(id, newData));
    },
    [dispatch, processUpdateData],
  );

  return { updateCustomerRequestByIdOnCellValueChange };
};

export default useUpdateCustomerRequestOnGrid;
