export const FETCH_THEMES = 'FETCH_THEMES';
export const FETCH_THEMES_FULFILLED = 'FETCH_THEMES_FULFILLED';

export const CREATE_THEME = 'CREATE_THEME';
export const CREATE_THEME_FULFILLED = 'CREATE_THEME_FULFILLED';

export const UPDATE_THEME = 'UPDATE_THEME';
export const UPDATE_THEME_FULFILLED = 'UPDATE_THEME_FULFILLED';

export const DELETE_THEME = 'DELETE_THEME';
export const DELETE_THEME_FULFILLED = 'DELETE_THEME_FULFILLED';

export const UPDATE_THEME_BY_ID = 'UPDATE_THEME_BY_ID';
export const UPDATE_THEME_BY_ID_FULFILLED = 'UPDATE_THEME_BY_ID_FULFILLED';

export const ADD_THEME_WITHOUT_SAVE = 'ADD_THEME_WITHOUT_SAVE';
export const REMOVE_UNSAVED_THEMES = 'REMOVE_UNSAVED_THEMES';

export const MERGE_THEMES = 'MERGE_THEMES';
export const MERGE_THEMES_FULFILLED = 'MERGE_THEMES_FULFILLED';

export const CREATE_THEMES = 'CREATE_THEMES';
export const CREATE_THEMES_FULFILLED = 'CREATE_THEMES_FULFILLED';
export const UPDATE_THEMES = 'UPDATE_THEMES';
export const UPDATE_THEMES_FULFILLED = 'UPDATE_THEMES_FULFILLED';
export const BULK_DELETE_THEMES = 'BULK_DELETE_THEMES';
export const BULK_DELETE_THEMES_FULFILLED = 'BULK_DELETE_THEMES_FULFILLED';

export const UNDO_CREATE_THEMES = 'UNDO_CREATE_THEMES';
export const UNDO_CREATE_THEMES_FULFILLED = 'UNDO_CREATE_THEMES_FULFILLED';
export const UNDO_UPDATE_THEMES = 'UNDO_UPDATE_THEMES';
export const UNDO_UPDATE_THEMES_FULFILLED = 'UNDO_UPDATE_THEMES_FULFILLED';
export const UNDO_BULK_DELETE_THEMES = 'UNDO_BULK_DELETE_THEMES';
export const UNDO_BULK_DELETE_THEMES_FULFILLED = 'UNDO_BULK_DELETE_THEMES_FULFILLED';

export const SWITCH_THEMES_ROW_ORDER = 'SWITCH_THEMES_ROW_ORDER';
export const SWITCH_THEMES_ROW_ORDER_FULFILLED = 'SWITCH_THEMES_ROW_ORDER_FULFILLED';
export const SWITCH_THEMES_ROW_ORDER_PENDING = 'SWITCH_THEMES_ROW_ORDER_PENDING';
export const SWITCH_THEMES_ROW_ORDER_REJECTED = 'SWITCH_THEMES_ROW_ORDER_REJECTED';

export const CREATE_THEME_ROADMAP = 'CREATE_THEME_ROADMAP';
export const CREATE_THEME_ROADMAP_FULFILLED = 'CREATE_THEME_ROADMAP_FULFILLED';
export const DELETE_THEME_ROADMAP = 'DELETE_THEME_ROADMAP';
export const DELETE_THEME_ROADMAP_FULFILLED = 'DELETE_THEME_ROADMAP_FULFILLED';
export const BULK_DELETE_THEME_ROADMAP = 'BULK_DELETE_THEME_ROADMAP';
export const BULK_DELETE_THEME_ROADMAP_FULFILLED = 'BULK_DELETE_THEME_ROADMAP_FULFILLED';
