import sortByRank from 'utils/sortByRank';
import sortRowOrder from 'utils/sortRowOrder';

const sortByTitle = (a, b) => (a.title?.toLowerCase() < b.title?.toLowerCase() ? -1 : 1);

const sortByRankAndTitle = (a, b) => {
  if (!!a.rank && !!b.rank) {
    const resultByRank = sortByRank(a, b);

    return resultByRank !== 0 ? resultByRank : sortByTitle(a, b);
  }

  return sortByTitle(a, b);
};

const sortGroups = (a, b) => {
  if (!!a.row_order && !!b.row_order) {
    return sortRowOrder(a, b);
  }

  if (!!a.date && !!b.date) {
    return a.date - b.date;
  }

  return sortByRankAndTitle(a, b);
};

export { sortByTitle, sortByRankAndTitle, sortGroups };
