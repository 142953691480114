import { useDispatch, useSelector } from 'react-redux';
import { pick } from 'ramda';

import {
  createMetricComment,
  deleteMetricComment,
  fetchNextMetricComments,
  updateMetricComment,
} from 'features/MetricsDialog/store/thunks';
import { selectMetricComments, selectMetricCommentsMetadata } from 'features/MetricsDialog/store/selectors';

const useMetricDetailsComments = ({ metricId }) => {
  const dispatch = useDispatch();
  const comments = useSelector(selectMetricComments);
  const commentsMetadata = useSelector(selectMetricCommentsMetadata);

  const hasMoreComments = Boolean(commentsMetadata?.next);

  const addCommentToMetric = async data => {
    const otherFieldsAllowed = ['mentions', 'editorState', 'integrationType', 'channel'];

    // will use plainText to maintain consistency with edit action
    return dispatch(createMetricComment(metricId, { plainText: data.text, ...pick(otherFieldsAllowed, data) }));
  };

  const fetchNextPage = () => {
    dispatch(fetchNextMetricComments(metricId, commentsMetadata?.next));
  };

  const deleteComment = commentId => {
    dispatch(deleteMetricComment(metricId, commentId));
  };

  const editComment = (commentId, data) => {
    return dispatch(updateMetricComment(metricId, commentId, data));
  };

  return {
    comments,
    hasMoreComments,

    addCommentToMetric,
    fetchNextPage,
    deleteComment,
    editComment,
  };
};

export default useMetricDetailsComments;
