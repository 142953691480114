import React from 'react';
import PropTypes from 'prop-types';

import makeTooltipLabel from 'utils/charts/makeTooltipLabel';
import theme from 'design-system/theme';

import ChartWrapper from 'components/ReactChart/utils/ChartWrapper';
import useScenarioVersionColors from 'routes/RoadmapVersions/hooks/useScenarioVersionColors';
import LineChart from 'components/ReactChart/LineChart';
import getLegendConfigForStackedChart from 'utils/charts/getLegendConfigForStackedChart';
import useScenariosAccumulativeBenefitsComparisonChartData from './useScenariosAccumulativeBenefitsComparisonChartData';
import { scaleForType } from '../utils/scales';
import ChartContent from '../ChartContent';

const ScenariosAccumulativeBenefitsComparisonChart = ({
  wrapper: Wrapper,
  minHeight,
  roadmapVersionIds,
  projectedField,
  timeBucketMode,
  dateInterval,
  labelsMap,
  actionButtons,
  defaultChartTitle,
}) => {
  const { scenarioColors } = useScenarioVersionColors();

  const { chartData, chartScaleType, chartTitle } = useScenariosAccumulativeBenefitsComparisonChartData({
    roadmapVersionIds,
    projectedField,
    timeBucketMode,
    dateInterval,
    scenarioColors,
    scenarioLabels: labelsMap,
    chartTitle: defaultChartTitle,
  });

  const xScale = scaleForType(chartScaleType);

  return (
    <Wrapper>
      <ChartContent title={chartTitle} actionButtons={actionButtons}>
        <ChartWrapper height={minHeight}>
          <LineChart
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom',
                  ...getLegendConfigForStackedChart(),
                },
                tooltip: {
                  callbacks: {
                    label: makeTooltipLabel,
                  },
                },
              },
              animation: {
                duration: 0,
              },
              scales: {
                x: xScale({ dateInterval }),
                y: {
                  type: 'linear',
                  display: true,
                  ticks: {
                    fontSize: theme.typography.fontSize,
                  },
                  grid: {
                    drawOnChartArea: false,
                  },
                },
              },
            }}
          />
        </ChartWrapper>
      </ChartContent>
    </Wrapper>
  );
};

ScenariosAccumulativeBenefitsComparisonChart.propTypes = {
  wrapper: PropTypes.func,
  roadmapVersionIds: PropTypes.arrayOf(PropTypes.number),
};

export default ScenariosAccumulativeBenefitsComparisonChart;
