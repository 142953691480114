import React from 'react';
import PropTypes from 'prop-types';

export const errorTypes = {
  INVALID_QUERY: 'INVALID_QUERY',
  INVALID_BASE_URL: 'INVALID_BASE_URL',
};

const ErrorMessage = ({ errorType, integratedURLs = [] }) => {
  switch (errorType) {
    case errorTypes.INVALID_QUERY:
      return <span>Please save your Azure query first and then copy and paste the URL</span>;

    case errorTypes.INVALID_BASE_URL:
      return <span>Please import Work Items from the same Organization ({integratedURLs.join(', ')})</span>;
    default:
      return null;
  }
};

ErrorMessage.propTypes = {
  errorType: PropTypes.string.isRequired,
  integratedURLs: PropTypes.array,
};

export default ErrorMessage;
