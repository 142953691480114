import axios from 'axios';

import { createThunk } from 'utils/store/thunk';

import { 
  FETCH_RALLY_WORKSPACES,
  UPDATE_RALLY_INTEGRATION_WORKSPACE,
  FETCH_RALLY_PROJECTS,
  FETCH_RALLY_PROJECTS_RESET,
} from '../types';

const fetchRallyWorkspaces = orgIntegrationId =>
  createThunk(
    FETCH_RALLY_WORKSPACES,
    () => axios.get(`/api/integrations/rally/${orgIntegrationId}/workspaces`).then(({ data }) => data),
    {
      orgIntegrationId,
    },
    {},
  );

const updateRallyIntegrationWorkspace = (orgIntegrationId, workspaceId) =>
  createThunk(
    UPDATE_RALLY_INTEGRATION_WORKSPACE,
    () => axios.put(`/api/integrations/rally/${orgIntegrationId}/workspace`, { workspaceId }).then(({ data }) => data),
    {
      orgIntegrationId,
      integrationType: 'rally',
    },
    {},
  );

/**
 * @function fetchRallyProjects
 * 
 * Fetches projects from Rally for a given orgIntegrationId.
 * Projects will be scoped to the orgIntegration Rally workspace.
 * 
 * @param {*} orgIntegrationId 
 * @returns {Array}
 */
const fetchRallyProjects = orgIntegrationId =>
  createThunk(
    FETCH_RALLY_PROJECTS,
    () => axios.get(`/api/integrations/rally/${orgIntegrationId}/projects`),
    {
      orgIntegrationId,
    },
    { includeRetryAction: true },
  );

const resetRallyProjects = () => ({ type: FETCH_RALLY_PROJECTS_RESET });

export {
  fetchRallyWorkspaces,
  updateRallyIntegrationWorkspace,
  fetchRallyProjects,
  resetRallyProjects,
};
