import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getRandom5DigitNum } from 'utils';

import {
  isIntegrationDragonboatFieldsLoading as isIntegrationDragonboatFieldsLoadingSelector,
  getIntegrationDragonboatFields as getIntegrationDragonboatFieldsSelector,
  getIntegrationFields as getIntegrationFieldsSelector,
  isIntegrationFieldsLoading as isIntegrationFieldsLoadingSelector,
} from 'store/organization/selectors';

import {
  fetchIntegrationDragonboatFields as fetchIntegrationDragonboatFieldsAction,
  fetchIntegrationFields as fetchIntegrationFieldsAction,
} from 'store/organization';

export default Component => {
  return props => {
    const { integrationType, orgIntegrationId, mapping, onChange, layer } = props;

    const dispatch = useDispatch();

    const [isDisableSyncWarningDialogOpen, setIsDisableSyncWarningDialogOpen] = useState(false);

    const integrationDragonboatFields = useSelector(state => getIntegrationDragonboatFieldsSelector(state));
    const isIntegrationDragonboatFieldsLoading = useSelector(state => isIntegrationDragonboatFieldsLoadingSelector(state));
    const integrationFields = useSelector(getIntegrationFieldsSelector(orgIntegrationId));
    const isIntegrationFieldsLoading = useSelector(isIntegrationFieldsLoadingSelector(orgIntegrationId));

    const dragonboatAvailableFields = useMemo(
      () =>
        integrationDragonboatFields
          .filter(dragonboatField => !mapping.find(mappedField => dragonboatField.key === mappedField.key))
          .map(dragonboatField => ({ label: dragonboatField.title, value: dragonboatField })),
      [integrationDragonboatFields, mapping],
    );

    const integrationAvailableFields = useMemo(
      () =>
        integrationFields
          .filter(integrationField => !mapping.find(mappedField => integrationField.key === mappedField?.integration?.key))
          .map(integrationField => ({ label: integrationField.title, value: integrationField })),
      [integrationFields, mapping],
    );

    const onAddNewField = useCallback(
      () => onChange([...mapping, { isNew: true, id: getRandom5DigitNum() }]),
      [mapping, onChange],
    );

    const onChangeField = useCallback(
      fieldData => {
        const findByIdOrFieldKey = fieldEntry => {
          const shouldUseIdFieldToCompare = fieldEntry.isNew || fieldEntry.id;
          const areIdsEquals = fieldEntry.id === fieldData.id;
          const areKeysEquals = fieldEntry.key === fieldData.key;

          return shouldUseIdFieldToCompare ? areIdsEquals : areKeysEquals;
        };

        const index = mapping.findIndex(findByIdOrFieldKey) || 0;

        /**
         * field not found - avoid splice using -1 as index.
         */
        if (index < 0) return;

        const newDataMapping = [...mapping.slice(0, index), fieldData, ...mapping.slice(index + 1)];

        onChange(newDataMapping);
      },
      [mapping, onChange],
    );

    const onRemoveField = useCallback(
      fieldData => {
        const excludeByIdOrFieldKey = f => (f.isNew ? f.id !== fieldData.id : f.key !== fieldData.key);

        const newDataMapping = mapping.filter(excludeByIdOrFieldKey);

        onChange(newDataMapping);
      },
      [mapping, onChange],
    );

    const onOpenDisableSyncWarningDialogHandler = useCallback(() => setIsDisableSyncWarningDialogOpen(true), []);

    const onCloseDisableSyncWarningDialogHandler = useCallback(() => setIsDisableSyncWarningDialogOpen(false), []);

    useEffect(() => {
      if (!isIntegrationFieldsLoading) {
        dispatch(fetchIntegrationFieldsAction(integrationType, orgIntegrationId, layer));
      }
      if (!isIntegrationDragonboatFieldsLoading) {
        dispatch(fetchIntegrationDragonboatFieldsAction(integrationType, orgIntegrationId, layer));
      }
    }, []);

    return (
      <Component
        isLoading={isIntegrationFieldsLoading || isIntegrationDragonboatFieldsLoading}
        integrationType={integrationType}
        mapping={mapping}
        dragonboatAvailableFields={dragonboatAvailableFields}
        integrationAvailableFields={integrationAvailableFields}
        onAddNewField={onAddNewField}
        onChangeField={onChangeField}
        onRemoveField={onRemoveField}
        integrationFields={integrationFields}
        isDisableSyncWarningDialogOpen={isDisableSyncWarningDialogOpen}
        onOpenDisableSyncWarningDialogHandler={onOpenDisableSyncWarningDialogHandler}
        onCloseDisableSyncWarningDialogHandler={onCloseDisableSyncWarningDialogHandler}
      />
    );
  };
};
