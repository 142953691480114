import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_USER, EDITOR_USER, LEADER_USER, MANAGER_USER, OWNER_USER } from '@dragonboat/permissions';

import { createUserViewPublicLink, updateUserViewPublicLink, deleteUserViewPublicLink } from 'store/userViews/actions';
import { getCurrentUser } from 'store/login/selectors';

const componentHOC = Component => {
  return props => {
    const { userView, closeDialog } = props;

    const dispatch = useDispatch();
    const [publicLinks, setPublicLinks] = useState([]);

    const currentUser = useSelector(getCurrentUser);
    const userCanManagePublicLinks = [ADMIN_USER, MANAGER_USER, EDITOR_USER, LEADER_USER, OWNER_USER].includes(
      currentUser.role_id,
    );

    useEffect(() => {
      setPublicLinks(props.userView.publicLinks || []);
    }, [props.userView]);

    const onAddNewLink = () => {
      setPublicLinks([
        ...publicLinks,
        {
          loading: true,
          link_hash: '',
          name: '',
        },
      ]);

      dispatch(createUserViewPublicLink(props.userView.id));
    };

    const onUpdateLink = (linkId, data) => {
      // TODO
      dispatch(updateUserViewPublicLink(props.userView.id, linkId, data));
    };

    const onDeleteLink = publicLinkId => {
      const linkIndex = publicLinks.findIndex(link => link.id === publicLinkId);

      if (linkIndex >= 0) {
        publicLinks.splice(linkIndex, 1);
        setPublicLinks([...publicLinks]);
        dispatch(deleteUserViewPublicLink(userView.id, publicLinkId));
      }
    };

    return (
      <Component
        {...props}
        view={userView}
        publicLinks={publicLinks}
        onAddNewLink={onAddNewLink}
        onUpdateLink={onUpdateLink}
        onDeleteLink={onDeleteLink}
        userCanManagePublicLinks={userCanManagePublicLinks}
        handleCloseDialog={closeDialog}
      />
    );
  };
};

export default componentHOC;
