import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  // Project => Task Dependencies:
  ...getThunkActionTypes('ADD_PROJECT_TASK_DEPENDENCY'),
  ...getThunkActionTypes('REMOVE_PROJECT_TASK_DEPENDENCY'),

  // Project => Estimate Dependencies:
  ...getThunkActionTypes('ADD_PROJECT_ESTIMATE_DEPENDENCY'),
  ...getThunkActionTypes('REMOVE_PROJECT_ESTIMATE_DEPENDENCY'),

  // Project => Project Dependencies:
  ...getThunkActionTypes('ADD_PROJECT_PROJECT_DEPENDENCY'),
  ...getThunkActionTypes('REMOVE_PROJECT_PROJECT_DEPENDENCY'),

  // Estimate => Estimate Dependencies:
  ...getThunkActionTypes('ADD_ESTIMATE_ESTIMATE_DEPENDENCY'),
  ...getThunkActionTypes('REMOVE_ESTIMATE_ESTIMATE_DEPENDENCY'),

  // Estimate => Project Dependencies:
  ...getThunkActionTypes('ADD_ESTIMATE_PROJECT_DEPENDENCY'),
  ...getThunkActionTypes('REMOVE_ESTIMATE_PROJECT_DEPENDENCY'),

  // Task => Task Dependencies:
  ...getThunkActionTypes('ADD_TASK_TASK_DEPENDENCY'),
  ...getThunkActionTypes('REMOVE_TASK_TASK_DEPENDENCY'),

  // Task => Project Dependencies:
  ...getThunkActionTypes('ADD_TASK_PROJECT_DEPENDENCY'),
  ...getThunkActionTypes('REMOVE_TASK_PROJECT_DEPENDENCY'),

  // Other:
  ...getThunkActionTypes('FETCH_PROJECT_DEPENDENCIES'),
  ...getThunkActionTypes('SET_PROJECTS_DEPENDENCIES'),
  ...getThunkActionTypes('SYNC_INTEGRATION_PROJECT_DEPENDENCIES'),
};

export const {
  // Project => Task Dependencies:
  ADD_PROJECT_TASK_DEPENDENCY,
  ADD_PROJECT_TASK_DEPENDENCY_FULFILLED,
  REMOVE_PROJECT_TASK_DEPENDENCY,
  REMOVE_PROJECT_TASK_DEPENDENCY_FULFILLED,

  // Project => Estimate Dependencies:
  ADD_PROJECT_ESTIMATE_DEPENDENCY,
  ADD_PROJECT_ESTIMATE_DEPENDENCY_FULFILLED,
  REMOVE_PROJECT_ESTIMATE_DEPENDENCY,
  REMOVE_PROJECT_ESTIMATE_DEPENDENCY_FULFILLED,

  // Project => Project Dependencies:
  ADD_PROJECT_PROJECT_DEPENDENCY,
  ADD_PROJECT_PROJECT_DEPENDENCY_FULFILLED,
  REMOVE_PROJECT_PROJECT_DEPENDENCY,
  REMOVE_PROJECT_PROJECT_DEPENDENCY_FULFILLED,

  // Estimate => Estimate Dependencies:
  ADD_ESTIMATE_ESTIMATE_DEPENDENCY,
  ADD_ESTIMATE_ESTIMATE_DEPENDENCY_FULFILLED,
  REMOVE_ESTIMATE_ESTIMATE_DEPENDENCY,
  REMOVE_ESTIMATE_ESTIMATE_DEPENDENCY_FULFILLED,

  // Estimate => Project Dependencies:
  ADD_ESTIMATE_PROJECT_DEPENDENCY,
  ADD_ESTIMATE_PROJECT_DEPENDENCY_FULFILLED,
  REMOVE_ESTIMATE_PROJECT_DEPENDENCY,
  REMOVE_ESTIMATE_PROJECT_DEPENDENCY_FULFILLED,

  // Task => Task Dependencies:
  ADD_TASK_TASK_DEPENDENCY,
  ADD_TASK_TASK_DEPENDENCY_FULFILLED,
  REMOVE_TASK_TASK_DEPENDENCY,
  REMOVE_TASK_TASK_DEPENDENCY_FULFILLED,

  // Task => Project Dependencies:
  ADD_TASK_PROJECT_DEPENDENCY,
  ADD_TASK_PROJECT_DEPENDENCY_FULFILLED,
  REMOVE_TASK_PROJECT_DEPENDENCY,
  REMOVE_TASK_PROJECT_DEPENDENCY_FULFILLED,

  // Other:
  FETCH_PROJECT_DEPENDENCIES,
  SET_PROJECTS_DEPENDENCIES,
  SET_PROJECTS_DEPENDENCIES_FULFILLED,
  SYNC_INTEGRATION_PROJECT_DEPENDENCIES,
} = actionTypes;

export default actionTypes;
