import React from 'react';

import { Switch } from '@material-ui/core';

export const getMoreDropdownOptions = (chartVisibleLayers, handleUpdateChartVisibleLayers) => {
  return [
    {
      id: 'showTargetMetric',
      key: 'showTargetMetric',
      onClick: () => handleUpdateChartVisibleLayers('showTargetMetric'),
      title: (
        <div>
          <Switch
            color="primary"
            checked={chartVisibleLayers.includes('showTargetMetric')}
            onChange={() => handleUpdateChartVisibleLayers('showTargetMetric')}
          />
          Show Target Metric
        </div>
      ),
    },
    {
      id: 'showActualMetric',
      key: 'showActualMetric',
      onClick: () => handleUpdateChartVisibleLayers('showActualMetric'),
      title: (
        <div>
          <Switch
            color="primary"
            checked={chartVisibleLayers.includes('showActualMetric')}
            onChange={() => handleUpdateChartVisibleLayers('showActualMetric')}
          />
          Show Actual Metric
        </div>
      ),
    },
    {
      id: 'showPlannedAllocation',
      key: 'showPlannedAllocation',
      onClick: () => handleUpdateChartVisibleLayers('showPlannedAllocation'),
      title: (
        <div>
          <Switch
            color="primary"
            checked={chartVisibleLayers.includes('showPlannedAllocation')}
            onChange={() => handleUpdateChartVisibleLayers('showPlannedAllocation')}
          />
          Show Planned Allocation
        </div>
      ),
    },
    {
      id: 'showReportedAllocation',
      key: 'showReportedAllocation',
      onClick: () => handleUpdateChartVisibleLayers('showReportedAllocation'),
      title: (
        <div>
          <Switch
            color="primary"
            checked={chartVisibleLayers.includes('showReportedAllocation')}
            onChange={() => handleUpdateChartVisibleLayers('showReportedAllocation')}
          />
          Show Reported Allocation
        </div>
      ),
    },
    {
      id: 'showCompletedAllocation',
      key: 'showCompletedAllocation',
      onClick: () => handleUpdateChartVisibleLayers('showCompletedAllocation'),
      title: (
        <div>
          <Switch
            color="primary"
            checked={chartVisibleLayers.includes('showCompletedAllocation')}
            onChange={() => handleUpdateChartVisibleLayers('showCompletedAllocation')}
          />
          Show Completed Allocation
        </div>
      ),
    },
  ];
};
