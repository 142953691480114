import React, { useEffect, useRef, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import Checkbox from './Components/Checkbox';
import DragonTableCell from './Components/DragonTableCell';
import DragHandle from './Components/DragHandle';
import { isEnter, isEscape } from 'design-system/utils/keyboard';
import { equals, pipe, not } from 'ramda';

import { AutoSizer, Column, Table, ArrowKeyStepper, defaultTableRowRenderer } from 'react-virtualized';
import { SortDirection } from './utils/sorting';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import useLocalReducer from './hooks/useLocalReducer';

import Columns from './Components/Columns';
import Header from './Components/Header';
import ColumnHeaderResizer from './Components/ColumnHeaderResizer';
import { materialColors } from 'design-system/themes/default';
import { actions, initialState, rowGetter } from './state';
import Portal from '@material-ui/core/Portal';
import generateTreeViewProps from './utils/generateTreeViewProps';

const isTrue = equals(true);
const isNotTrue = pipe(isTrue, not);

// DRAGONTABLE TODO
//  * keep internal state (ex. allow on Change and save on state)
//  * groups
//  * tree view
//  * resize columns
//  * column visibility toggle

const SortableTable = props => {
  return (
    <DragDropContext onDragEnd={props.onRowOrderChange}>
      <Droppable droppableId="dragontable-grid">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <Table {...props} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

function hasWidthOverflow(el) {
  if (!el) return 0;
  return el.clientWidth - el.scrollWidth;
}

const _reEnableArrowNavigation = gridClassName => () => {
  // so, so, so ugly :(
  // setTimeout prevents onBlur when cancelling edit cells
  setTimeout(() => document.querySelector(`div.${gridClassName}`).focus(), 50);
};

const sortableTableRowRenderer =
  ({ enableRowDragging, treeViewProperty }, { collapsedGroups }) =>
  props => {
    const row = defaultTableRowRenderer(props);

    return (
      <Draggable draggableId={props.index.toString()} index={props.index}>
        {(provided, snapshot) => {
          const newRow = React.cloneElement(row, {
            ref: provided.innerRef,
            ...provided.draggableProps,
            isDragDisabled: enableRowDragging,
            children: React.Children.map(row.props.children, (child, index) =>
              index === 0 ? React.cloneElement(child, provided.dragHandleProps) : child,
            ),
            style: {
              ...props.style,
              ...provided.draggableProps.style,
              top: 0,
              transform: `translate3d(0px,${props.style.top}px, 0px)`,
              willChange: 'transform,height',
              transition: '300ms transform,height',
            },
          });
          // this will allow to make the first column the drag handle for the row

          return snapshot.isDragging ? <Portal>{newRow}</Portal> : newRow;
        }}
      </Draggable>
    );
  };

function bindPropsToChildren(children, props) {
  return React.Children.map(children, child => React.cloneElement(child, props));
}

const styles = theme => ({
  table: {
    fontFamily: theme.typography.fontFamily,
    overflow: 'visible',
  },
  grid: {
    overflow: 'visible !important',
    outline: 'none',
    '&>div': {
      overflow: 'visible !important',
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    // overflow: 'visible !important',
  },
  tableRow: {
    cursor: 'pointer',
    overflow: 'hidden',
    transition: '0.15s',
    willChange: 'height, width',
  },
  tableRowHover: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: materialColors.lightGray,
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: 'initial',
  },
  dragHandleCursor: {
    cursor: 'grab',
  },
  dragHelperClass: {
    zIndex: 999999999999999,
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    cursor: 'grabbing',
  },
});

const DragonTable = props => {
  const [
    state,
    {
      selectCell,
      addNew,
      editCell,
      cancelEdit,
      recalculateOverflow,
      calculateOverflowAfter,
      setRows,
      setProps,
      buildTree,
      orderBy,
      updateOrder,
      toggleSelectRow,
      selectAllRows,
      toggleParent,
    },
  ] = useLocalReducer(actions, initialState);

  const { scrollToColumn, scrollToRow, editSelectedCell, overflow, height, page, rows } = state;
  const {
    classes,
    children,
    rowHeight,
    title,
    allowNewRow,
    onRowOrderChange,
    enableRowDragging,
    enableSelectionMode,
    noBorder,
    managedOverflow,
    overflowAfter,
    enablePagination,
    ...tableProps
  } = props;

  const headerContainer = useRef(null);
  const tableContainer = useRef(null);

  const headerRenderer = ({ columnIndex, header = '', style, width, flexGrow, dataMapper }) => {
    const { headerHeight, children, classes, disableSorting } = props;

    const enableSort = !disableSorting && columnIndex !== -1 && !children[columnIndex].props.disableSort && header;

    const inner = enableSort ? (
      <TableSortLabel
        active={header === state.orderBy}
        direction={state.order ? SortDirection[state.order].toLowerCase() : 'desc'}
      >
        {header}
      </TableSortLabel>
    ) : width === 0 ? (
      <span />
    ) : (
      header || <span />
    );

    return (
      <DragonTableCell
        className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        onClick={enableSort ? () => orderBy({ property: header, dataMapper }) : null}
        style={{
          ...style,
          height: headerHeight,
          minWidth: width,
          width: !flexGrow ? width : 'inherit',
          flexGrow,
          justifyContent: 'space-between',
          color: 'rgba(0, 0, 0, .54)',
          padding: width === 0 || columnIndex === -1 ? 0 : null,
          paddingRight: 0,
        }}
      >
        {inner}
        {width !== 0 && <ColumnHeaderResizer />}
      </DragonTableCell>
    );
  };
  const getRowClassName = ({ index }) => {
    const { classes } = props;

    return classNames(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1,
    });
  };
  const _scrollToRow = rowIndex => {
    const { headerHeight, rowHeight } = props;

    const rowTotalHeight = rowIndex * rowHeight;
    const rowTop = rowTotalHeight + headerHeight;
    const isRowNotVisibleTop = tableContainer.scrollTop > rowTop;

    if (isRowNotVisibleTop) {
      tableContainer.scrollTop = rowTop;
      return;
    }

    // eslint-disable-next-line no-mixed-operators
    const scrollBottom = rowTop - tableContainer.clientHeight + rowHeight; // /+ rowHeight to make the row visible
    const isRowNotVisibleBottom = tableContainer.clientHeight + tableContainer.scrollTop < rowTop + rowHeight;

    if (isRowNotVisibleBottom) {
      tableContainer.scrollTop = scrollBottom;
    }
  };

  const rowCount = useMemo(() => {
    const { addingNew, rows } = state;
    const { overflowAfter, enablePagination } = props;

    if (enablePagination) {
      return overflowAfter;
    }

    // const collapsedGroupCount = Object.keys(collapsedGroups).reduce((acc, group) => {
    //   return acc + tree.get(+group).size;
    // }, 0);

    return rows.length + Number(addingNew); // - collapsedGroupCount;
  }, [state.rows.length, state.addingNew, state.collapsedGroups]);

  const calculatedHeaderHeight = useMemo(() => {
    const marginBottom = 8;

    if (!headerContainer.current) return marginBottom;
    return headerContainer.current.scrollHeight + marginBottom;
  }, [headerContainer.current]);

  useEffect(() => {
    setProps(props);
  }, [props]);
  useEffect(() => {
    setRows(props.rows);
    if (props.treeView) buildTree(props.treeViewProperty);
  }, [props.rows, props.treeView, props.treeViewProperty, props.children]);
  useEffect(() => {
    setTimeout(() => {
      recalculateOverflow({ tableContainer: tableContainer.current });
      calculateOverflowAfter({ ...props, calculatedHeaderHeight });
    }, 1);
  }, [calculatedHeaderHeight, rowCount]);
  useEffect(() => {
    _scrollToRow(state.scrollToRow);
  }, [state.scrollToRow]);
  // hack due to tag mounting delay
  useEffect(() => {
    setTimeout(() => recalculateOverflow({ tableContainer: tableContainer.current }), 50);
  }, [hasWidthOverflow(tableContainer.current), overflowAfter]);
  useEffect(() => {
    _scrollToRow(state.scrollToRow);
    recalculateOverflow({ tableContainer: tableContainer.current });
  }, [state.scrollToRow, state.scrollToColumn]);
  useEffect(() => {
    if (state.height) props.onResize(state.height);
  }, [state.height]);
  useEffect(() => {
    if (state.order === null) setRows(props.rows);
    else updateOrder();
  }, [state.order, state.orderBy]);

  const reEnableArrowNavigation = _reEnableArrowNavigation(classes.grid);

  const calculatedHeight = height;
  const shouldHideTable = rowCount === 0 && props.hideTableIfEmpty;

  return (
    <React.Fragment>
      <Header
        forwardRef={headerContainer}
        title={title}
        allowNewRow={allowNewRow}
        addNew={addNew}
        newRowButtonTitle={props.newRowButtonTitle}
        renderNewRowButton={props.renderNewRowButton}
      />
      <AutoSizer
        disableHeight={props.disableAutosizerHeight}
        onResize={() => recalculateOverflow({ tableContainer: tableContainer.current })}
      >
        {({ height, width }) => (
          <ArrowKeyStepper
            columnCount={children.length + 1}
            rowCount={rowCount}
            scrollToColumn={scrollToColumn}
            scrollToRow={scrollToRow}
            onScrollToChange={selectCell}
            isControlled
            mode="cells"
          >
            {({ onSectionRendered, scrollToColumn, scrollToRow }) => (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                ref={tableContainer}
                style={{
                  // eslint-disable-next-line no-mixed-operators
                  height: (managedOverflow ? calculatedHeight : height) - calculatedHeaderHeight + (noBorder ? 0 : 2),
                  width,
                  border: noBorder ? 'none' : '1px solid #efefef',
                  borderRadius: 5,
                  overflow: 'auto',
                  display: shouldHideTable ? 'none' : 'block',
                }}
                onKeyDown={e => {
                  if (isEnter(e) || isEscape(e)) {
                    e.stopPropagation();
                    if (isEscape(e)) {
                      reEnableArrowNavigation();
                    } else {
                      editCell();
                    }
                  }
                }}
              >
                <SortableTable
                  {...tableProps}
                  className={classes.table}
                  height={managedOverflow ? overflow.containerHeight : height - calculatedHeaderHeight}
                  width={overflow.containerWidth}
                  rowGetter={rowGetter(state)}
                  rowClassName={getRowClassName}
                  rowHeight={rowHeight}
                  rowCount={rowCount}
                  // TODO: fix this
                  // at least on Fluid, some of the rows are not being detected as visible
                  overscanRowCount={50}
                  gridClassName={classes.grid}
                  onSectionRendered={onSectionRendered}
                  scrollToColumn={scrollToColumn}
                  scrollToRow={scrollToRow}
                  rowRenderer={sortableTableRowRenderer(props, state)}
                  onRowOrderChange={onRowOrderChange}
                >
                  <Column
                    dataKey="dragColumn"
                    width={enableRowDragging ? 30 : 0}
                    cellDataGetter={() => null}
                    cellRenderer={() => <DragHandle className={classes.dragHandleCursor} height={rowHeight} />}
                    headerRenderer={headerProps =>
                      headerRenderer({
                        ...headerProps,
                        header: '',
                        columnIndex: -1,
                        width: !enableRowDragging && 0,
                      })
                    }
                  />
                  <Column
                    dataKey="selectColumn"
                    width={enableSelectionMode ? 32 : 0}
                    cellDataGetter={() => null}
                    cellRenderer={({ rowData = {} }) => (
                      <Checkbox checked={state.selectedRows[rowData.id] || false} onChange={() => toggleSelectRow(rowData.id)} />
                    )}
                    headerRenderer={headerProps =>
                      headerRenderer({
                        ...headerProps,
                        header: (
                          <Checkbox
                            checked={state.totalSelectedRows !== 0 && state.totalSelectedRows === state.rows.length}
                            indeterminate={state.totalSelectedRows !== 0 && state.totalSelectedRows !== state.rows.length}
                            onChange={selectAllRows}
                          />
                        ),
                        columnIndex: -1,
                        width: !enableSelectionMode && 0,
                      })
                    }
                  />
                  {children
                    .filter(child => child)
                    .filter(({ props: { hidden } }) => isNotTrue(hidden))
                    .map((column, index) => {
                      const {
                        props: { header, map, width, flexGrow },
                        ...other
                      } = column;

                      return (
                        <Column
                          key={header}
                          headerRenderer={headerProps =>
                            headerRenderer({
                              ...headerProps,
                              header,
                              columnIndex: index,
                              dataMapper: map,
                              width,
                              flexGrow,
                            })
                          }
                          className={classNames(classes.flexContainer)}
                          cellDataGetter={({ rowData }) => (rowData ? map(rowData) : null)}
                          cellRenderer={cellProps => {
                            const isSelected = cellProps.columnIndex === scrollToColumn && cellProps.rowIndex === scrollToRow;
                            const forceEdit = isSelected && editSelectedCell;

                            if (forceEdit) {
                              cancelEdit();
                            }

                            return bindPropsToChildren(column, {
                              row: cellProps.rowData,
                              isSelected,
                              forceEdit,
                              cellProps: { rowHeight },
                              ...(cellProps.rowData && props.treeView
                                ? generateTreeViewProps(state.tree, props.treeView, column.props.main, cellProps.rowData.id, () =>
                                    toggleParent(cellProps.rowData.id),
                                  )
                                : {}),
                              onChange() {
                                if (!column.props.onChange) return;

                                const result = column.props.onChange.apply(null, arguments);

                                if (result instanceof Promise) {
                                  return result.finally(() => {
                                    cancelEdit();
                                    _reEnableArrowNavigation();
                                  });
                                }

                                cancelEdit();
                                _reEnableArrowNavigation();
                                return result;
                              },
                              onClick: () =>
                                selectCell({
                                  scrollToColumn: cellProps.columnIndex,
                                  scrollToRow: cellProps.rowIndex,
                                  isClick: true,
                                }),
                              onEditCancel: () => {
                                cancelEdit();
                                _reEnableArrowNavigation();
                              },
                            });
                          }}
                          dataKey={header + index}
                          width={width}
                          flexGrow={flexGrow}
                          {...other}
                        />
                      );
                    })}
                </SortableTable>
              </div>
            )}
          </ArrowKeyStepper>
        )}
      </AutoSizer>
      {enablePagination && (
        <TablePagination
          rowsPerPageOptions={[overflowAfter]}
          count={rows.length}
          rowsPerPage={overflowAfter}
          page={page}
          SelectProps={{ native: true }}
          onChangePage={(_, page) =>
            selectCell({ page, scrollToColumn: 'first-editable-column', scrollToRow: page * overflowAfter })
          }
        />
      )}
    </React.Fragment>
  );
};

DragonTable.propTypes = {
  classes: PropTypes.object.isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.object),
  rowClassName: PropTypes.string,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  sort: PropTypes.func,
  onResize: PropTypes.func,
  noBorder: PropTypes.bool,
  enableRowDragging: PropTypes.bool,
  enableSelectionMode: PropTypes.bool,
  title: PropTypes.string,
  allowNewRow: PropTypes.bool,
  onRowOrderChange: PropTypes.func,
  managedOverflow: PropTypes.bool,
  overflowAfter: PropTypes.number,
  minRows: PropTypes.number,
  enablePagination: PropTypes.bool,
  treeView: PropTypes.bool,
  treeViewProperty: PropTypes.string,
  disableSorting: PropTypes.bool,
  skipArrowStepOnNonEditable: PropTypes.bool,
};

DragonTable.defaultProps = {
  headerHeight: 40,
  rowHeight: 40,
  onResize: () => {},
  noBorder: false,
  minRows: 2,
  managedOverflow: false,
  enablePagination: false,
  enableSelectionMode: false,
  enableRowDragging: false,
  treeView: false,
  disableSorting: false,
  skipArrowStepOnNonEditable: true,
};

// export Columns inside DragonTable class
Object.keys(Columns).forEach(columnKey => (DragonTable[columnKey] = Columns[columnKey]));

DragonTable.Columns = Columns;

export default withStyles(styles)(DragonTable);
