import { useMemo } from 'react';

import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { CORP_OBJECTIVE_KEY } from 'constants/objectives';
import { TIMEFRAME_KEY_CORP } from 'constants/timeframes';
import { ROADMAP_CORP_KEY } from 'constants/roadmaps';
import { PRODUCT_2 } from 'constants/common';

import usePermissions from './permissions/usePermissions';

/**
 * @function useMetadataGroupByOptions
 *
 * hook to process and filter metadata group by options
 *
 * @param  {Array} groupByOptions
 * @return {Object}
 */
const useMetadataGroupByOptions = groupByOptions => {
  const { canView } = usePermissions();

  const filteredGroupByOptions = useMemo(
    () =>
      groupByOptions.filter(groupByOption => {
        if (groupByOption.key === CORP_OBJECTIVE_KEY) {
          return canView(PERMISSION_RESOURCES.objectiveCorp);
        }

        if (groupByOption.key === TIMEFRAME_KEY_CORP) {
          return canView(PERMISSION_RESOURCES.timeframeCorp);
        }

        if (groupByOption.key === ROADMAP_CORP_KEY) {
          return canView(PERMISSION_RESOURCES.roadmapCorp);
        }

        if (groupByOption.key === PRODUCT_2) {
          return canView(PERMISSION_RESOURCES.product2);
        }

        return true;
      }),
    [groupByOptions, canView],
  );

  return {
    groupByOptions: filteredGroupByOptions,
  };
};

export default useMetadataGroupByOptions;
