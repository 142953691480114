import withStyles from '@material-ui/core/styles/withStyles';

import DeleteButton from './DeleteButton';

const styles = {
  button: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
};

export default withStyles(styles)(DeleteButton);
