import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { not } from 'ramda';

import usePermissions from 'hooks/permissions/usePermissions';
import useSystemFields from 'hooks/useSystemFields';
import getDisplayLayerLabel from 'store/projects/helpers/getDisplayLayerLabel';
import { openProjectLightbox } from 'store/projectLightbox';
import { updateState as updateStaffingState } from 'store/staffing';
import DragonMenu from 'design-system/atoms/DragonMenu/index';
import DragonIconButton from 'design-system/atoms/DragonIconButton/index';

import Add from 'design-system/atoms/DragonIcons/Add';
import { getProductData } from 'utils/getPlusMenuProjectData';
import useLightboxesControlContext from 'hooks/lightboxes/useLightboxesControl';

const FORECAST_RESOURCES_PATH = '/forecast/resources';
const DEFAULT_STAFFING_STATE_TITLE = 'New Assignment';
const DEFAULT_STAFFING_STATE_DURATION = 14;

export default props => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const [getSystemFieldName] = useSystemFields();
  const permissions = usePermissions();

  const hasBet = useSelector(state => state.organization.organization.has_bet);
  const hasKeyResults = useSelector(state => state.organization.organization.has_key_results);

  const { createMetricOnLightbox: createMetric } = useLightboxesControlContext();

  const getLabelName = layer => getDisplayLayerLabel(layer, getSystemFieldName);

  const isStaffingPage = window.location.pathname === FORECAST_RESOURCES_PATH;

  const onClickNew = layer => {
    if (isStaffingPage) {
      dispatch(
        updateStaffingState({
          selectedTask: { $new: true, title: DEFAULT_STAFFING_STATE_TITLE, duration: DEFAULT_STAFFING_STATE_DURATION },
        }),
      );
    } else {
      dispatch(openProjectLightbox(null, null, { layer }));
    }
  };

  const projectsData = getProductData(
    getLabelName,
    onClickNew,
    getSystemFieldName,
    hasBet,
    hasKeyResults,
    dispatch,
    permissions,
    createMetric,
  );

  const _handleSelectProject = ({ onClick }) => onClick();
  const _handleChangeProject = event => setMenuAnchorEl(event.currentTarget);
  const haveOptionAvailable = projectsData.some(option => not(option.hide));

  return haveOptionAvailable ? (
    <Container {...props}>
      <DragonIconButton
        id="add-new-project"
        data-testid="add-new-project"
        type="fill"
        icon={<Add />}
        onClick={_handleChangeProject}
      />
      <DragonMenu
        items={projectsData}
        anchorEl={menuAnchorEl}
        onSetAnchorEl={setMenuAnchorEl}
        onSelectItem={_handleSelectProject}
        placement="middle"
      />
    </Container>
  ) : null;
};

const Container = styled.div``;
