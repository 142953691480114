import { propOr, path, prop } from 'ramda';

import generateColorForMetadata from './generateColorForMetadata';
import { UNDEFINED } from 'constants/common';
import clampLabelSize from 'utils/charts/clampLabelSize';
import { BAR_CHART_LABEL_MAX_NUM_CHARS } from 'constants/charts';

const parseLabel = label => clampLabelSize(label, BAR_CHART_LABEL_MAX_NUM_CHARS);

const sliceIfNeeded = (arr, max) => (max ? arr.slice(0, max) : arr);

/**
 * Generates data for a chart based on the provided chart data and groups metadata.
 *
 * @param {Array} chartData - The data for the chart.
 * @param {Object} groupsMetadataById - Metadata for groups, organized by group ID.
 * @param {number} maxItems - max items visible on dataset.
 * @returns {Object} An object containing labels and datasets for the chart.
 */
const generateDataForChart = (chartData, groupsMetadataById, maxItems, bucketInformation) => {
  const data = sliceIfNeeded(chartData, maxItems);

  const datasets = [
    {
      label: '',
      data: data.map(entry => +path(['stacks', 'no_group'])(entry)),
      backgroundColor: data.map(entry => generateColorForMetadata(groupsMetadataById[entry.t])),
      dataBucketIds: data.reduce(
        (acc, entry) => ({
          ...acc,
          [entry.t]: path([entry.t, 'no_group'], bucketInformation),
        }),
        {},
      ),
    },
  ];

  const clampedLabels = data.map(entry => parseLabel(propOr(UNDEFINED, 'title')(groupsMetadataById[entry.t])));

  const unclampedLabels = data.map(entry => propOr(UNDEFINED, 'title')(groupsMetadataById[entry.t]));

  const ids = data.map(entry => prop('id', groupsMetadataById[entry.t]));

  return {
    labels: clampedLabels,
    unclampedLabels,
    datasets,
    ids,
  };
};

export default generateDataForChart;
