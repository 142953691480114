import styled from 'styled-components';

import { spacing } from 'design-system/theme';

const CommentsWrapper = styled.div`
  width: 100%;
  padding-left: ${spacing()}px;
  margin: ${spacing(3)}px 0 ${spacing(2)}px;
`;

const HeaderComments = styled.div`
  display: flex;
  align-items: center;
`;

export { CommentsWrapper, HeaderComments };
