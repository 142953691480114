import React from 'react';
import AsyncSelect from 'react-select/async';

import MultiSelect from 'design-system/atoms/MultiSelect/index';

import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';

export default props => {
  return (
    <MultiSelect
      placeholder={TYPE_TO_SEARCH_PLACEHOLDER}
      {...props}
      components={{
        ...(props.components || {}),
        selectComponent: AsyncSelect,
      }}
      defaultOptions
      cacheOptions
    />
  );
};
