import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { equals, isNil, not, pipe, prop, defaultTo } from 'ramda';

import { revalidateUserIntegrationAuth as revalidateUserIntegrationAuthAction } from 'store/integrations';

import {
  getAddProjectIntegrationError as getAddProjectIntegrationErrorSelector,
  getCreateProjectIntegrationError as getCreateProjectIntegrationErrorSelector,
  getUpdatingProjectStoriesError as getUpdatingProjectStoriesErrorSelector,
  getTestConnectionError as getTestConnectionErrorSelector,
  getUpdateIntegrationProjectFromProjectError as getUpdateIntegrationProjectFromProjectErrorSelector,
  getADOError as getADOErrorSelector,
  getFetchIntegrationFieldsError as getFetchIntegrationFieldsErrorSelector,
  getEnableIntegrationWebhooksError as getEnableIntegrationWebhooksErrorSelector,
  getDisableIntegrationWebhooksError as getDisableIntegrationWebhooksErrorSelector,
  getUpdateIntegrationWebhooksProjectRestrictionsError as getUpdateIntegrationWebhooksProjectRestrictionsErrorSelector,
  getImportCountIntegrationItemsError as getImportCountIntegrationItemsErrorSelector,
  getImportInsertIntegrationItemsError as getImportInsertIntegrationItemsErrorSelector,
  getImportInsertIntegrationItemsAsyncError as getImportInsertIntegrationItemsAsyncErrorSelector,
  getJiraPermissionsError as getJiraPermissionsErrorSelector,
  isLoadingRevalidateUserIntegration as isLoadingRevalidateUserIntegrationSelector,
} from 'store/integrations/selectors';

import { getUpdateProjectError as getUpdateProjectErrorSelector } from 'store/projects/selectors';

import useIntegrations from 'hooks/useIntegrations';

const ID = 'id';
const TYPE = 'type';
const INTEGRATION_AUTHORIZATION_ERROR = 'INTEGRATION_AUTHORIZATION_ERROR';
const USER_INTEGRATION_NOT_FOUND = 'USER_INTEGRATION_NOT_FOUND';

const defaultToNull = defaultTo(null);

const getId = prop(ID);
const getIntegrationAuthType = pipe(prop(TYPE), defaultToNull);

const isNotIntegrationAuthorizationError = pipe(equals(INTEGRATION_AUTHORIZATION_ERROR), not);
const isNotUserIntegrationNotFoundError = pipe(equals(USER_INTEGRATION_NOT_FOUND), not);

/**
 * @function shouldSkipErrorHandling
 *
 * Check if there is any error to be handled and if the error code is an integration authorization error.
 *
 * @param {Object} error
 * @returns {Boolean}
 */
const shouldSkipErrorHandling = error => {
  if (isNil(error)) {
    return true;
  }

  const errorCode = error?.response?.data?.error_code;

  return isNotIntegrationAuthorizationError(errorCode) && isNotUserIntegrationNotFoundError(errorCode);
};

const useIntegrationsAuthenticationErrors = () => {
  const dispatch = useDispatch();

  const { orgIntegrations } = useIntegrations();

  const isPending = useSelector(isLoadingRevalidateUserIntegrationSelector);

  const addProjectIntegrationError = useSelector(getAddProjectIntegrationErrorSelector);
  const createProjectIntegrationError = useSelector(getCreateProjectIntegrationErrorSelector);
  const updatingProjectStoriesError = useSelector(getUpdatingProjectStoriesErrorSelector);
  const testConnectionError = useSelector(getTestConnectionErrorSelector);
  const updateIntegrationProjectFromProjectError = useSelector(getUpdateIntegrationProjectFromProjectErrorSelector);
  const updateProjectError = useSelector(getUpdateProjectErrorSelector);
  const ADOProjectsError = useSelector(getADOErrorSelector);
  const fetchIntegrationFieldsError = useSelector(getFetchIntegrationFieldsErrorSelector);
  const enableIntegrationWebhooksError = useSelector(getEnableIntegrationWebhooksErrorSelector);
  const disableIntegrationWebhooksError = useSelector(getDisableIntegrationWebhooksErrorSelector);
  const updateIntegrationWebhooksProjectRestrictionsError = useSelector(
    getUpdateIntegrationWebhooksProjectRestrictionsErrorSelector,
  );
  const importCountIntegrationItemsError = useSelector(getImportCountIntegrationItemsErrorSelector);
  const importInsertIntegrationItemsError = useSelector(getImportInsertIntegrationItemsErrorSelector);
  const importInsertIntegrationItemsAsyncError = useSelector(getImportInsertIntegrationItemsAsyncErrorSelector);
  const jiraPermissionsError = useSelector(getJiraPermissionsErrorSelector);

  const revalidateUserIntegrationToken = useCallback(
    async error => {
      if (shouldSkipErrorHandling(error)) {
        return;
      }

      const { orgIntegrationId, retryAction } = error.meta;
      const { integrationType, data } = orgIntegrations.find(orgIntegration => getId(orgIntegration) === orgIntegrationId);

      const authType = getIntegrationAuthType(data);

      dispatch(revalidateUserIntegrationAuthAction(integrationType, orgIntegrationId, retryAction, authType));
    },
    [orgIntegrations],
  );

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(addProjectIntegrationError);
    }
  }, [addProjectIntegrationError]);

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(createProjectIntegrationError);
    }
  }, [createProjectIntegrationError]);

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(updatingProjectStoriesError);
    }
  }, [updatingProjectStoriesError]);

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(testConnectionError);
    }
  }, [testConnectionError]);

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(updateProjectError);
    }
  }, [updateProjectError]);

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(ADOProjectsError);
    }
  }, [ADOProjectsError]);

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(updateIntegrationProjectFromProjectError);
    }
  }, [updateIntegrationProjectFromProjectError]);

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(fetchIntegrationFieldsError);
    }
  }, [fetchIntegrationFieldsError]);

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(enableIntegrationWebhooksError);
    }
  }, [enableIntegrationWebhooksError]);

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(disableIntegrationWebhooksError);
    }
  }, [disableIntegrationWebhooksError]);

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(updateIntegrationWebhooksProjectRestrictionsError);
    }
  }, [updateIntegrationWebhooksProjectRestrictionsError]);

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(importCountIntegrationItemsError);
    }
  }, [importCountIntegrationItemsError]);

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(importInsertIntegrationItemsError);
    }
  }, [importInsertIntegrationItemsError]);

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(importInsertIntegrationItemsAsyncError);
    }
  }, [importInsertIntegrationItemsAsyncError]);

  useEffect(() => {
    if (not(isPending)) {
      revalidateUserIntegrationToken(jiraPermissionsError);
    }
  }, [jiraPermissionsError]);
};

export default useIntegrationsAuthenticationErrors;
