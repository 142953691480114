import { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import usePermissions from 'hooks/permissions/usePermissions';
import { getCurrentUser } from 'store/login/selectors';
import { getPageUserViews, getActiveViewForPage } from 'store/userViews/selectors';
import { cloneView as cloneViewAction, updateSharedView as updateSharedViewAction } from 'store/userViews/sharedViews.thunks';
import { getPageIdFromPath } from 'utils/userViews';

const getReleaseNotesUpdatePayload = (releaseNotes, user) => ({
  content: releaseNotes,
  updated_by: user,
  updated_at: new Date(),
});

const useViewsForReleaseNotes = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);

  const permissions = usePermissions();

  const pageId = getPageIdFromPath(window.location.pathname);

  const userViews = useSelector(state => getPageUserViews(state, pageId));

  const activeView = useSelector(state => getActiveViewForPage(state, pageId));

  const editableUserViews = useMemo(
    () => userViews?.filter(view => permissions.canUpdate(PERMISSION_RESOURCES.userView, { view })),
    [userViews, permissions],
  );

  const cloneView = useCallback(
    (name, description, releaseNotes) => {
      dispatch(
        cloneViewAction(
          {
            ...activeView,
            state: { ...activeView?.state, releaseNotes: getReleaseNotesUpdatePayload(releaseNotes, currentUser) },
          },
          name,
          description,
          false,
        ),
      );
    },
    [activeView],
  );

  const updateView = useCallback(
    (releaseNotes, currentView = null) => {
      const baseView = currentView || activeView;

      dispatch(
        updateSharedViewAction({
          ...baseView,
          state: {
            ...baseView?.state,
            releaseNotes: getReleaseNotesUpdatePayload(releaseNotes, currentUser),
          },
        }),
      );
    },
    [activeView],
  );

  return {
    activeView,
    editableUserViews,
    pageId,
    cloneView,
    updateView,
  };
};

export default useViewsForReleaseNotes;
