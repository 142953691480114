import { isNil } from 'ramda';

const shouldFlagBeActiveIfTrue = flagKey => localStorage.getItem(flagKey) === 'true';

const shouldFlagBeActiveByDefaultAndIfTrue = flagKey =>
  isNil(localStorage.getItem(flagKey)) || localStorage.getItem(flagKey) === 'true';

const isNewHomepageEnabled = shouldFlagBeActiveIfTrue('enableNewHomepage');

const isNewProjectsGridOnDashboardsEnabled = shouldFlagBeActiveByDefaultAndIfTrue('newProjectsGrid');

const isNewForecastListEnabled = shouldFlagBeActiveByDefaultAndIfTrue('newForecastGrid');

const isOneClickPlanByEstimatesEnabled = shouldFlagBeActiveIfTrue('oneClickPlanByEstimates');

const isUnplannedCapacityChartEnabled = shouldFlagBeActiveIfTrue('unplannedCapacityChart');

export {
  shouldFlagBeActiveByDefaultAndIfTrue,
  isNewHomepageEnabled,
  isNewProjectsGridOnDashboardsEnabled,
  isNewForecastListEnabled,
  isOneClickPlanByEstimatesEnabled,
  isUnplannedCapacityChartEnabled,
};
