import React from 'react';

import theme from 'design-system/theme';
import formatDateTime from 'design-system/utils/formatDateTime';
import TextDeprecated from 'design-system/atoms/TextDeprecated';

const DateTimeCellRenderer = params => (
  <TextDeprecated textColor={theme.palette.gridColors.mineShaft} size="small" breakwords={params.colDef.breakwords}>
    {formatDateTime(params.value)}
  </TextDeprecated>
);

export default DateTimeCellRenderer;
