import { useMemo } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import { isNewRow } from 'design-system/molecules/AgGridReact-New/helpers';
import {
  getMetadataRoadmapColumnDef,
  getOwnerColumnDef,
  remirrorColumnDef,
  statusColumnDef,
  getTitleColumnDef,
  updatedColumnDef,
  createdByUserColumnDef,
  createMultipleActionsColumnDefGetter,
  getDragColumnDef,
  defaultColumnDefCommonProps,
} from 'design-system/molecules/AgGridReact-New/columns';

import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';
import { materialColors } from 'design-system/themes/default';

import theme from 'design-system/theme';

import { PERSONA_ROADMAP_FIELD, PERSONAS_SENTIMENT_COLORS_OPTIONS } from 'features/PersonasGrid/helpers/constants';
import { SENTIMENT_OPTION_LABELS } from 'constants/sentiment';
import { ChipsCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { DropdownCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';

import { stringComparator } from 'design-system/utils/agGridComparators';

const PERSONA_KEY = 'persona';

const usePersonasGridColumnDefinition = ({
  canUpdate = false,
  canDelete = false,
  users,
  roadmapColumn,
  hasUnsavedItem = false,
  checkRowDrag = true,
  deletePersona,
}) => {
  const { personas, createPersonaRoadmap, deletePersonaRoadmap, bulkDeletePersonaRoadmaps, hideMetadataRoadmaps } = roadmapColumn;

  const roadmapsMetadata = useRoadmapsMetadata(
    createPersonaRoadmap,
    deletePersonaRoadmap,
    personas,
    PERSONA_KEY,
    bulkDeletePersonaRoadmaps,
  );

  const personaTitleColumnDef = useMemo(() => {
    return {
      ...getTitleColumnDef(),
      field: 'title',
      width: 400,

      cellClass: 'align-center',
      editable: canUpdate,
      pinned: 'left',
    };
  }, [canUpdate]);

  const defaultColumnDef = useMemo(
    () => ({
      ...defaultColumnDefCommonProps,
      cellClass: params =>
        params.colDef.autoHeight ? 'ag-react-container--expandable' : 'ag-react-editable-container--expandable',
      sortable: true,
      resizable: true,
      filter: true,
      editable: params => canUpdate && !isNewRow(params),
    }),
    [canUpdate],
  );

  const personaDetailsColumnDef = useMemo(
    () => ({
      ...remirrorColumnDef,
      headerName: 'Details',
      suppressMovable: true,
      field: 'details',
      width: 300,
      cellClass: 'align-items-top',
    }),
    [canUpdate],
  );

  const personaSentimentColumnDef = useMemo(() => {
    return {
      field: 'sentiment',
      headerName: 'Sentiment',
      optionsLabel: SENTIMENT_OPTION_LABELS,
      width: 130,
      openByDefault: true,
      cellRenderer: ChipsCellRenderer,
      formatValue: sentiment => {
        const sentimentTheme = theme?.palette?.sentiments?.[sentiment];

        return [{ title: sentiment, color: sentimentTheme?.color }];
      },
      cellEditorPopup: true,
      cellEditor: DropdownCellEditor,
      cellEditorParams: {
        options: PERSONAS_SENTIMENT_COLORS_OPTIONS,
        sort: false,
      },
      comparator: stringComparator,
      labelField: 'title',
      editable: canUpdate,
    };
  }, [canUpdate]);

  const personaOwnerColumnDef = useMemo(
    () =>
      getOwnerColumnDef({
        editable: canUpdate,
        editableForNewRow: false,
        users,
        field: 'owner_id',
        cellClass: 'align-center',
      }),
    [users, canUpdate],
  );

  const actionsColumnDef = useMemo(() => {
    return createMultipleActionsColumnDefGetter({
      field: 'actions',
      headerName: '',
      actions: [
        {
          icon: DeleteIcon,
          color: materialColors.darkerGray,
          getVisibility: () => true,
          onClick: deletePersona,
          disabled: canDelete,
        },
      ],
    });
  }, [canDelete, deletePersona]);

  const dragColumnDef = useMemo(
    () => getDragColumnDef({ checkRowDrag, disableDrag: hasUnsavedItem, pinned: 'left' }),
    [checkRowDrag, hasUnsavedItem],
  );

  const columnDefs = useMemo(
    () => [
      dragColumnDef,
      personaTitleColumnDef,
      personaDetailsColumnDef,
      getMetadataRoadmapColumnDef({
        roadmapsMetadata: [...roadmapsMetadata, hideMetadataRoadmaps],
        field: PERSONA_ROADMAP_FIELD,
        editableForNewRow: false,
        editable: canUpdate,
      }),
      personaSentimentColumnDef,
      personaOwnerColumnDef,
      statusColumnDef,
      createdByUserColumnDef,
      updatedColumnDef,
      actionsColumnDef,
    ],
    [
      personaTitleColumnDef,
      personaDetailsColumnDef,
      personaSentimentColumnDef,
      personaOwnerColumnDef,
      actionsColumnDef,
      dragColumnDef,
      canUpdate,
    ],
  );

  return {
    columnDefs,
    defaultColumnDef,
  };
};

export default usePersonasGridColumnDefinition;
