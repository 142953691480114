import React, { useMemo } from 'react';
import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';
import ClearIcon from '@material-ui/icons/Clear';

export default function MetadataRoadmapChips({ values = [], handleDelete }) {
  const canDelete = useMemo(() => values && values.length && !!values[0].id, [values]);

  return (
    <Container>
      {values.map(val => (
        <StyledChip
          id={val.id}
          key={val.id}
          label={val.title}
          variant="outlined"
          deleteIcon={<ClearIcon style={{ fontSize: '15px' }} />}
          onDelete={canDelete && (() => handleDelete(val))}
        />
      ))}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: wrap;
`;

const StyledChip = styled(Chip)`
  &&&&& {
    font-size: 11px;
    height: 19px;
    margin: 2px;
    padding: 2px 0;
  }
`;
