import { useDispatch } from 'react-redux';
import pick from 'lodash/pick';
import { useMemo, useCallback } from 'react';
import get from 'lodash/get';
import { pickBy, isEmpty } from 'ramda';

import {
  updateIntegrationImportConfiguration as updateIntegrationImportConfigurationAction,
  runIntegrationRecurringImport as runIntegrationRecurringImportAction,
} from 'store/organization/thunks';
import useIntegrations from 'hooks/useIntegrations';

const IMPORT_CONFIGURATION_DEFAULT = {
  recurring_import_enabled: false,
  recurring_import_link: '',
  last_recurring_import: null,
};
const IMPORT_CONFIGURATION_KEYS = Object.keys(IMPORT_CONFIGURATION_DEFAULT);
const ALLOW_UPDATE_CONFIGURATION_KEYS = ['recurring_import_enabled', 'recurring_import_link'];

/**
 * Check if configuration key is valid to update
 *
 * @function isValidConfigurationKeyToUpdate
 * @param  {String} key
 * @return {Boolean}
 */
const isValidConfigurationKeyToUpdate = key => ALLOW_UPDATE_CONFIGURATION_KEYS.includes(key);

/**
 * Cleans the data to be sent to the server
 *
 * @function cleanDataConfigurationToUpdate
 * @param  {Object} object
 * @return {Object}
 */
const cleanDataConfigurationToUpdate = pickBy((_, key) => isValidConfigurationKeyToUpdate(key));

/**
 * Hook to use the configuration of some organization integration
 *
 * @function useImportConfiguration
 * @param  {Number} orgIntegrationId
 * @return {Array}
 */
const useImportConfiguration = (integrationType, orgIntegrationId) => {
  const dispatch = useDispatch();
  const { orgIntegration } = useIntegrations(integrationType, orgIntegrationId);

  const updateImportConfiguration = useCallback(
    data => {
      const cleanedData = cleanDataConfigurationToUpdate(data);

      if (!orgIntegration) return;
      if (isEmpty(cleanedData)) return;

      dispatch(updateIntegrationImportConfigurationAction(integrationType, orgIntegrationId, cleanedData));
    },
    [orgIntegration],
  );
  const runIntegrationRecurringImport = useCallback(() => {
    dispatch(runIntegrationRecurringImportAction(orgIntegration.integrationType, orgIntegration.id));
  }, [orgIntegration]);
  const importIntegrationData = useMemo(
    () => ({
      ...IMPORT_CONFIGURATION_DEFAULT,
      ...pick(get(orgIntegration, 'data', {}), IMPORT_CONFIGURATION_KEYS),
    }),
    [orgIntegration],
  );

  return [importIntegrationData, updateImportConfiguration, runIntegrationRecurringImport];
};

export default useImportConfiguration;
