import React from 'react';
import { useDispatch } from 'react-redux';

import { importCustomers as importCustomersAction } from 'store/customers/actions';

import useSystemFields from 'hooks/useSystemFields';

export default Component => {
  return props => {
    const dispatch = useDispatch();
    const [getSystemFieldName] = useSystemFields();

    const dataMapping = [
      {
        field: 'name',
        displayTitle: 'Name',
        displayHelpText: 'required',
        required: true,
        col: null,
        parseToCorrectType: name => String(name)?.substring(0, 255),
      },
      {
        field: 'crm_record_url',
        displayTitle: 'Record URL',
        required: false,
        col: null,
      },
      {
        field: 'value',
        displayTitle: 'Value',
        required: false,
        col: null,
      },
    ];

    const importCustomers = data => dispatch(importCustomersAction(data));

    return (
      <Component
        {...props}
        importCustomers={importCustomers}
        dataMapping={dataMapping}
        getSystemFieldName={getSystemFieldName}
      />
    );
  };
};
