import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setPageFilters } from 'store/filters';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';
import { MULTI_LAYER_FILTER, CHILDREN_FILTERS } from 'constants/filters';
import useProjectsFlatSearchPage from 'hooks/filters/useProjectsFlatSearchPage';

const isMultiLayer = l => Array.isArray(l);

/**
 * @function useApplyFiltersOnAdvancedSearch
 *
 * Hook to handle with apply search logic on the advanced search component
 *
 * @param  {String} page
 * @return {Objecy}
 */
const useApplyFiltersOnAdvancedSearch = page => {
  const dispatch = useDispatch();

  const hasMultiLayerSearch = useFeatureFlags([FeatureFlags.HAS_MULTI_LAYER_SEARCH]);

  const { openProjectsFlatSearchPage, closeProjectsFlatSearchPage, isProjectsFlatSearchPageActive } = useProjectsFlatSearchPage();

  const onApplyFilter = useCallback(
    (filters, filterId) => {
      if (hasMultiLayerSearch) {
        if (isMultiLayer(filters.layer)) {
          openProjectsFlatSearchPage(filters);

          /*
           * On multi layer filter should force to:
           * - not get children
           * - not ask for parents
           * - not have any second layer
           */
          const filtersWithoutChildren = {
            ...filters,
            children: CHILDREN_FILTERS.noChildren,
            withParents: false,
            secondLayer: [],
          };

          return dispatch(setPageFilters(MULTI_LAYER_FILTER, filtersWithoutChildren, false, null, false));
        }

        if (isProjectsFlatSearchPageActive) {
          closeProjectsFlatSearchPage();
        }
      }

      dispatch(setPageFilters(page, filters, false, filterId));
    },
    [
      page,
      hasMultiLayerSearch,
      dispatch,
      openProjectsFlatSearchPage,
      closeProjectsFlatSearchPage,
      isProjectsFlatSearchPageActive,
    ],
  );

  return {
    onApplyFilter,
  };
};

export default useApplyFiltersOnAdvancedSearch;
