import React from 'react';
import styled from 'styled-components';
import { isNil, isEmpty, not, either, pipe } from 'ramda';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { FeatureFlags } from '@dragonboat/config';

import { spacing } from 'design-system/theme';
import Autocomplete from 'design-system/atoms/Autocomplete/index';

import useSystemFields from 'hooks/useSystemFields';
import useFeatureFlags from 'hooks/useFeatureFlags';

import Link from 'components/Link';
import SetupIntegrationToken from 'routes/Settings/Integration/components/SetupIntegrationToken/SetupIntegrationToken';

import CreateWorkItemWarning from './components/CreateWorkItemWarning';
import { IntegrationWrapper, StyledTextField } from '../utils/styles';

const isNilOrEmpty = either(isNil, isEmpty);
const isNotNilOrEmpty = pipe(isNilOrEmpty, not);

const ErrorMessages = {
  'ADO-ERROR-1000': getSystemFieldName => `The creation of ADO work items from ${getSystemFieldName('bet')}s is not supported`,
  'ADO-ERROR-1010': getSystemFieldName => `For this ADO project, please use ${getSystemFieldName('idea')}s to create ADO Epics.`,
  'ADO-ERROR-1020': getSystemFieldName =>
    `For this ADO project, ${getSystemFieldName('idea', true)} can only be linked to ADO Features`,
  'ADO-ERROR-1030': getSystemFieldName =>
    `For this ADO project, ${getSystemFieldName('initiative', true)} can only be linked to ADO Epics`,
  'ADO-ERROR-1040': getSystemFieldName => `For this ADO project, ${getSystemFieldName('bet', true)} cannot be linked to ADO`,
  'ADO-ERROR-1050': getSystemFieldName => `For this ADO project, only ${getSystemFieldName('idea', true)} can be linked to ADO`,
  'ADO-ERROR-1060': getSystemFieldName =>
    `Please update to ${getSystemFieldName('idea', false)}. For ADO Basic projects, only Dragonboat ${getSystemFieldName(
      'idea',
      true,
    )} are mapped to ADO Epics`,
};

export default props => {
  const {
    integration,
    projectIntegrations,
    userIntegrations,
    taskLink,
    setTaskLink,
    isLinking,
    handleLinkTask,
    handleUnlinkTask,
    projectId,
    setProjectId,
    handleCreateTask,
    isCreating,
    azureProjects,
    linkErrorMsg,
    createErrorMsg,
    addUserIntegration,
    addUserIntegrationError,
    fetchAzureProjects,
    isLoading,
    createWorkItemWarning,
  } = props;

  let createError = createErrorMsg;
  let linkError = linkErrorMsg;

  const [getSystemFieldName] = useSystemFields();
  const hasAzureDevopsOAuth2 = useFeatureFlags([FeatureFlags.HAS_AZURE_DEVOPS_OAUTH2]);

  const shouldShowIntegrationData = isNotNilOrEmpty(userIntegrations) || hasAzureDevopsOAuth2;

  const suggestions = (azureProjects || []).map(pr => ({ value: pr.id, label: pr.name }));

  const isInvalidStateMessage =
    createErrorMsg &&
    createErrorMsg.includes("The field 'State'") &&
    createErrorMsg.includes('is not in the list of supported values');
  const phaseField = getSystemFieldName('phase');

  if (isInvalidStateMessage) createError = `${createError}. Please remove the value in ${phaseField} field and try again.`;

  if (Object.keys(ErrorMessages).includes(createErrorMsg)) {
    createError = ErrorMessages[createErrorMsg](getSystemFieldName);
  }

  if (Object.keys(ErrorMessages).includes(linkErrorMsg)) {
    linkError = ErrorMessages[linkErrorMsg](getSystemFieldName);
  }

  const handleClick = async e => {
    e.preventDefault();

    if (isNilOrEmpty(userIntegrations) && hasAzureDevopsOAuth2) {
      await addUserIntegration();
    }

    window.open(taskLink, '_blank');
  };

  return (
    <>
      {shouldShowIntegrationData && (
        <IntegrationWrapper>
          {(!projectIntegrations || projectIntegrations.length === 0) && (
            <Grid container>
              <Grid item xs={8}>
                <Autocomplete
                  hideBottomBorder
                  suggestions={suggestions}
                  onValueChange={value => {
                    setProjectId(value);
                  }}
                  placeholder="Select Azure Devops project"
                  isLoading={isLoading}
                  onFocus={fetchAzureProjects}
                />
              </Grid>
              <ButtonContainer item xs={4}>
                <Button id="create-ado" disabled={!projectId || isCreating} color="primary" onClick={handleCreateTask}>
                  Create WorkItem
                </Button>
              </ButtonContainer>
              {createError && (
                <ErrorDiv item xs={12}>
                  <ErrorSpan>{createError}</ErrorSpan>
                </ErrorDiv>
              )}
            </Grid>
          )}

          {projectIntegrations && projectIntegrations.length > 0 && (
            <Grid container>
              <StyledLink item>
                <Link onClick={handleClick} href={taskLink} target="_blank">
                  {taskLink}
                </Link>
              </StyledLink>
              <ButtonContainer item>
                <Button color="primary" onClick={handleUnlinkTask}>
                  Unlink
                </Button>
              </ButtonContainer>
              {createWorkItemWarning && (
                <Grid item xs={12}>
                  <CreateWorkItemWarning warning={createWorkItemWarning} />
                </Grid>
              )}
            </Grid>
          )}

          {(!projectIntegrations || projectIntegrations.length === 0) && (
            <Grid container>
              <Grid item xs={8}>
                <StyledTextField
                  label="Enter your WorkItem link"
                  value={taskLink}
                  onChange={event => setTaskLink(event.target.value)}
                  disabled={projectIntegrations && projectIntegrations.length > 0}
                  style={{ marginTop: `${spacing(2)}px` }}
                  fullWidth
                  useEllipsis
                />
              </Grid>
              <ButtonContainer item xs={4}>
                <Button id="link-ado" disabled={!taskLink || isLinking} color="primary" onClick={handleLinkTask}>
                  Link WorkItem
                </Button>
              </ButtonContainer>
              {linkError && linkError.length && (
                <ErrorDiv item xs={12}>
                  <ErrorSpan>{linkError}</ErrorSpan>
                </ErrorDiv>
              )}
            </Grid>
          )}
        </IntegrationWrapper>
      )}

      {not(shouldShowIntegrationData) && (
        <Grid container>
          <Grid item xs={12}>
            <SetupIntegrationToken
              integrationName="Azure Devops"
              integrationHelpLink={`${integration.data.uri}/_usersSettings/tokens`}
              connectButtonLabel="CONNECT"
              saveToken={token => addUserIntegration(token)}
              error={addUserIntegrationError}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const ButtonContainer = styled(Grid)`
  &&&& {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

const StyledLink = styled(Grid)`
  &&&& {
    display: flex;
    align-items: center;
    max-width: 80%;

    a {
      font-size: 0.875rem;
    }
  }
`;

const ErrorDiv = styled(Grid)`
  &&&& {
    padding-top: 5px;
  }
`;

const ErrorSpan = styled.span`
  &&&& {
    color: red;
  }
`;
