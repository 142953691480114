import React, { Component } from 'react';
import styled from 'styled-components';
import isString from 'lodash/isString';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import MicroChip from 'design-system/atoms/MicroChip/index';
import TextWrapper from 'design-system/atoms/TextWrapper/index';

import getSystemFieldName from 'utils/getSystemFieldName';

const defaultGetNOfTextRowsToDisplay = () => 1;

export default (label, systemFields, getOptions, useChip) =>
  (class extends Component {
    render() {
      const value = isString(this.props.value) ? this.props.value : this.props.value?.title ?? '';

      if (this.props.node.group && 'value' in this.props) {
        if (label) {
          if (this.props?.reactContainer) {
            this.props.reactContainer.style.display = 'inline-block';
          }

          return (
            <TextDeprecated breakwords={this.props.colDef && this.props.colDef.autoHeight} inline color="#303030" size="small">
              {label}: {String(value).trim() || 'Undefined'}
            </TextDeprecated>
          );
        } else if (systemFields) {
          let label = getSystemFieldName(this.props.node.field.replace('Title', ''), systemFields);

          if (label === this.props.node.field) {
            const { rowGroupColumn } = this.props.node;

            label = rowGroupColumn.colDef.headerName;
          }

          return (
            <TextDeprecated breakwords={this.props.colDef && this.props.colDef.autoHeight} inline color="#303030" size="small">
              {label}: {value || 'Undefined'}
            </TextDeprecated>
          );
        }
      }

      let colorEntity = (this.props.data || {})[this.props.colDef.field.split('Title')[0]];

      if (getOptions && !colorEntity) {
        const options = getOptions();

        colorEntity = (options || []).find(o => o.title === (this.props.data || {})[this.props.colDef.field]);
      }

      if (value && ((colorEntity && colorEntity.color) || useChip)) {
        return (
          <MicroChip
            {...(colorEntity && colorEntity.color ? { color: colorEntity.color } : { variant: 'outlined' })}
            label={value}
            fullWidth
            style={{ margin: 0, width: 'auto' }}
          >
            {value}
          </MicroChip>
        );
      }

      const { getNOfTextRowsToDisplay = defaultGetNOfTextRowsToDisplay } = this.props;

      const text = (
        <TextDeprecated
          breakwords={this.props.colDef && this.props.colDef.autoHeight}
          color="#303030"
          size="small"
          component="div"
        >
          <TextWrapper $nOfTextRowsToDisplay={getNOfTextRowsToDisplay()}>{value}</TextWrapper>
        </TextDeprecated>
      );

      if (this.props.colDef.onTextClick) {
        return (
          <TextWithLinkContainer>
            {text}
            <RightPositionedIconButton onClick={() => this.props.colDef.onTextClick(this.props)}>
              <OpenInNewIcon style={{ fontSize: 14 }} />
            </RightPositionedIconButton>
          </TextWithLinkContainer>
        );
      }

      return text;
    }
  });

const TextWithLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -16px;
  padding-left: 40px;
`;

const RightPositionedIconButton = styled(props => <IconButton {...props}>{props.children}</IconButton>)`
  &&&& {
    position: relative;
    visibility: hidden;
    padding: 3px;
    font-size: 1.125rem;
  }
`;
