import axios from 'axios';
import { dropLast, flatten, indexBy, isEmpty, last, pipe, pluck, prop, propOr, values } from 'ramda';

import history from 'store/utils/history';
import { createThunk } from 'utils/store/thunk';

import { getGoBackUrls, setGoBackUrls } from 'store/app';

import {
  FETCH_ROADMAP_VERSIONS,
  FETCH_ROADMAP_VERSION_PROJECTS,
  CREATE_NEW_ROADMAP_VERSION,
  UPDATE_ROADMAP_VERSION,
  RUN_ONE_CLICK_PLAN,
  FETCH_MULTIPLE_ROADMAP_VERSION_PROJECTS,
  SET_VERSION_AS_PLAN_OF_RECORD,
  DELETE_ROADMAP_VERSION,
  SET_PENDING_CHANGES,
  CLONE_ROADMAP_VERSION_FULFILLED,
  SET_ROADMAP_VERSIONS_PROJECTS,
  UPDATE_SCENARIO_PROJECT,
  LOAD_SCENARIO_FROM_QUERY_PARAM,
  CREATE_OR_LOAD_NEW_ROADMAP_VERSION_AFTER_DELETE,
} from './types';

import formatDateTime from 'design-system/utils/formatDateTime';

import { getPageFilters } from 'store/filters/selectors';
import { getCurrentUser } from 'store/login/selectors';
import { getUserName } from 'utils/index';

import throwRequestError, { DEFAULT_REQUEST_FAILED_ERROR_MESSAGE } from '../utils/throwRequestError';

import { handleSocketResponse } from './errorHandler';
import { isOneClickPlanByEstimatesEnabled } from 'constants/flags';
import { AiPlanner, SolverType } from 'constants/aiPlanner';

import { PLAN_OF_RECORD_ID } from 'constants/common';
import { populateProjectMetadataWithIdsForRoadmapVersionUpdate } from 'utils/roadmapVersions/supportedFieldsUtils';
import { UPDATE_ESTIMATES_FULFILLED } from 'store/estimates';
import { getAvailableRoadmapVersions, getSelectedRoadmapVersion } from './selectors';
import { closeDeleteScenarioDialog } from './actions';
import removeScenarioIdFromUrl from 'utils/roadmapVersions/removeScenarioIdFromUrl';
import { createNewRoadmapVersionApiCall } from './network';

const exist = Boolean;

const _getNewScenarioDefaultParameters = (state, pageId) => {
  const currentUser = getCurrentUser(state);
  const versionName = `${getUserName(currentUser)} - ${formatDateTime(new Date())}`;
  const committed = false;
  const description = '';
  const pageFilters = getPageFilters(state, pageId);

  return { pageFilters, versionName, description, committed };
};

export const createNewRoadmapVersion = pageId => {
  return async (dispatch, getState) => {
    const state = getState();
    const { pageFilters, versionName, description, committed } = _getNewScenarioDefaultParameters(state, pageId);

    return dispatch(
      createThunk(CREATE_NEW_ROADMAP_VERSION, createNewRoadmapVersionApiCall(pageFilters, versionName, description, committed)),
    );
  };
};

export const createOrLoadNewRoadmapVersionAfterDelete = pageId => {
  return async (dispatch, getState) => {
    const selectedRoadmapVersion = getSelectedRoadmapVersion(getState());

    if (selectedRoadmapVersion) {
      return dispatch(closeDeleteScenarioDialog());
    }

    const state = getState();
    const { pageFilters, versionName, description, committed } = _getNewScenarioDefaultParameters(state, pageId);

    return dispatch(
      createThunk(
        CREATE_OR_LOAD_NEW_ROADMAP_VERSION_AFTER_DELETE,
        createNewRoadmapVersionApiCall(pageFilters, versionName, description, committed),
      ),
    );
  };
};

export const loadScenarioFromQueryParam = (scenarioIdOnQueryParam, pageId) => {
  return async (dispatch, getState) => {
    const scenarios = getAvailableRoadmapVersions(getState());

    const scenarioById = id => (scenarios || []).find(s => s.id === id);

    const selectedScenario = getSelectedRoadmapVersion(getState());

    return dispatch(
      createThunk(LOAD_SCENARIO_FROM_QUERY_PARAM, async () => {
        if (exist(scenarioIdOnQueryParam)) {
          const scenario = scenarioById(+scenarioIdOnQueryParam);

          if (selectedScenario?.id === +scenarioIdOnQueryParam) {
            return { scenario: selectedScenario };
          }

          if (exist(scenario)) {
            return { scenario };
          }

          // if the id in the query param is not found in the list of scenarios, we exit scenario module
          const goBackUrls = getGoBackUrls(getState());
          const defaultUrl = '/';
          const currentGoBackUrl = last(goBackUrls) || defaultUrl;

          dispatch(setGoBackUrls(dropLast(1, goBackUrls)));

          history?.push(currentGoBackUrl);
          return;
        }

        const state = getState();
        const { pageFilters, versionName, description, committed } = _getNewScenarioDefaultParameters(state, pageId);

        const newScenario = await createNewRoadmapVersionApiCall(pageFilters, versionName, description, committed);

        return { scenario: newScenario };
      }),
    );
  };
};

export const updateRoadmapVersion = (roadmapVersion, updatedProperties) =>
  createThunk(
    UPDATE_ROADMAP_VERSION,
    axios.put(`/api/v1/roadmap-versions/${roadmapVersion.id}`, updatedProperties).then(res => res.data),
  );

export const saveRoadmapVersion = (roadmapVersion, updatedProperties) => {
  return async (dispatch, getState) => {
    // when saving an uncommitted version we need to update version pageFilters
    const pageFilters = getPageFilters(getState());

    updatedProperties.pageFilters = pageFilters;
    updatedProperties.committed = true;

    return dispatch(
      createThunk(
        UPDATE_ROADMAP_VERSION,
        axios.put(`/api/v1/roadmap-versions/${roadmapVersion.id}`, updatedProperties).then(res => res.data),
      ),
    );
  };
};

export const deleteRoadmapVersion = roadmapVersionId =>
  createThunk(
    DELETE_ROADMAP_VERSION,
    axios.delete(`/api/v1/roadmap-versions/${roadmapVersionId}`).then(res => {
      removeScenarioIdFromUrl();

      return res.data;
    }),
  );

export function fetchAvailableRoadmapVersions() {
  return async (dispatch, getState) => {
    const state = getState();
    const isAlreadyLoading = state.roadmapVersions?.operations?.fetchRoadmapVersions?.isLoading;

    if (isAlreadyLoading) {
      // TODO maybe we can consider this approach as a generalized thing in the thunk util?
      return;
    }
    return dispatch(
      createThunk(
        FETCH_ROADMAP_VERSIONS,
        axios.get('/api/v1/roadmap-versions').then(res => res.data),
      ),
    );
  };
}

export const fetchRoadmapVersionProjectsApiCall = (roadmapVersionId, filters) =>
  axios.post(`/api/v1/roadmap-versions/${roadmapVersionId}/projects`, { filters });

export const fetchRoadmapVersionProjects = (roadmapVersionId, filters) =>
  createThunk(
    FETCH_ROADMAP_VERSION_PROJECTS,
    fetchRoadmapVersionProjectsApiCall(roadmapVersionId, filters).catch(throwRequestError),
    {
      roadmapVersionId,
    },
  );

export const fetchMultipleRoadmapVersionProjects =
  (baseCompareVersionId = PLAN_OF_RECORD_ID, versionIds, filters, meta = {}) =>
  (dispatch, getState) => {
    return dispatch(
      createThunk(
        FETCH_MULTIPLE_ROADMAP_VERSION_PROJECTS,
        axios
          .post(`/api/v1/roadmap-versions/search/version/projects`, {
            baseCompareVersionId,
            roadmapVersionIds: versionIds,
            filters,
          })
          .then(({ data }) => ({
            baseCompareVersionId,
            roadmapVersionIds: versionIds,
            data,
          })),
        meta,
      ),
    );
  };

export const runOneClickPlan = (socket, solverType = SolverType.DETERMINISTIC, target, orderBy) => {
  return (dispatch, getState) => {
    const state = getState();
    const pageFilters = getPageFilters(state);
    const versionName = `${getUserName(getCurrentUser(state))} - ${formatDateTime(new Date())}`;

    const runOverUncommitedScenario = async (pageFilters, versionName) => {
      const uncommittedRoadmapVersion = await createNewRoadmapVersionApiCall(
        pageFilters,
        versionName,
        undefined,
        false,
        undefined,
      );

      const requestBody = {
        planByMode: isOneClickPlanByEstimatesEnabled ? AiPlanner.Unit : AiPlanner.PortfolioItems,
        solverType,
        target,
        orderBy,
      };

      return performRunOneClickPlanRequest(uncommittedRoadmapVersion.id, requestBody, socket);
    };

    return dispatch(
      createThunk(RUN_ONE_CLICK_PLAN, runOverUncommitedScenario(pageFilters, versionName), {
        toastErrorMessage: error => {
          return error.error_code || DEFAULT_REQUEST_FAILED_ERROR_MESSAGE;
        },
      }),
    );
  };
};

/**
 * Runs one click plan and either expects http response or through socket if it is supplied.
 * @param {*} body
 * @param {*} socket
 * @returns {Promise<RoadmapVersion>}
 */
const performRunOneClickPlanRequest = (versionId, body, socket = undefined) => {
  const urlPath = `/api/v1/roadmap-versions/${versionId}/one-click-plan`;

  if (socket) {
    return new Promise((resolve, reject) => {
      const socketRoom = socket.join();

      socket.subscribe(
        data => {
          try {
            resolve(handleSocketResponse(data));
          } catch (error) {
            reject(error);
          }
        },
        { messageType: `message-${socketRoom}` },
      );
      return axios
        .post(urlPath, {
          ...body,
          socketRoom,
        })
        .catch(reject);
    });
  }
  return axios.post(urlPath, body);
};

export const setVersionAsPlanOfRecord = (versionId, name) => {
  return createThunk(SET_VERSION_AS_PLAN_OF_RECORD, axios.put(`/api/v1/roadmap-versions/${versionId}/plan-of-record`, { name }));
};

export const cloneScenarioVersionApiCall = (versionId, pageFilters) => {
  return axios
    .post(`/api/v1/roadmap-versions/clone/${versionId}`, { page_filters: pageFilters })
    .then(res => res.data)
    .catch(throwRequestError);
};

const updateRoadmapVersionProjectApiCall = (roadmapVersionId, projectId, dataWithMetadataIds) =>
  axios
    .put(`/api/v1/roadmap-versions/${roadmapVersionId}/projects/${projectId}`, dataWithMetadataIds)
    .then(res => res.data)
    .catch(throwRequestError);

/**
 * Handles the project update when changing field in a scenario
 * @param {object} state store
 * @param {func} dispatch
 * @param {Object} project current project
 * @param {Object} dataToSave changed project data
 * @param {Object} selectedRoadmapVersion current selected version
 * @returns {Object} Project data after update
 */
export const updateScenarioProject = async (state, dispatch, projectId, dataToSave, selectedRoadmapVersion) => {
  const dataWithMetadataIds = populateProjectMetadataWithIdsForRoadmapVersionUpdate(state, dataToSave);
  let roadmapVersionId = selectedRoadmapVersion.id;
  let clonedVersion;

  // If changes are made on a committed version we should clone it into an uncommitted
  if (selectedRoadmapVersion.committed) {
    const pageFilters = getPageFilters(state);

    clonedVersion = await cloneScenarioVersionApiCall(selectedRoadmapVersion.id, pageFilters);

    roadmapVersionId = clonedVersion?.id;
  }

  const updatedProject = await updateRoadmapVersionProjectApiCall(roadmapVersionId, projectId, dataWithMetadataIds);

  if (clonedVersion) {
    dispatch({
      type: CLONE_ROADMAP_VERSION_FULFILLED,
      payload: clonedVersion,
      meta: { clonedFrom: selectedRoadmapVersion.id },
    });
  }

  dispatch({
    type: SET_PENDING_CHANGES,
    payload: true,
  });

  dispatch({
    type: UPDATE_SCENARIO_PROJECT,
    payload: {
      roadmapVersionId,
      projectData: updatedProject,
    },
  });

  return updatedProject;
};

/**
 * Handles estimates updates when changing in a scenario
 * @param {object} state store
 * @param {func} dispatch
 * @param {Estimate[]} estimates
 * @param {Object} selectedRoadmapVersion current selected version
 * @returns {Object} Estimates and Projects data after update
 */
export const updateScenarioEstimates = async (state, dispatch, estimates, selectedRoadmapVersion) => {
  let estimatesToPut = estimates;
  let roadmapVersionId = selectedRoadmapVersion.id;
  let clonedVersion;

  // If changes are made on a committed version we should clone it into an uncommitted
  if (selectedRoadmapVersion.committed) {
    const pageFilters = getPageFilters(state);

    clonedVersion = await cloneScenarioVersionApiCall(selectedRoadmapVersion.id, pageFilters);

    const clonedEstimates = await getEstimatesForRoadmapVersion(clonedVersion.id).then(propOr([], 'data'));
    const clonedEstimatesById = indexBy(prop('estimate_id'))(clonedEstimates);

    estimatesToPut = estimatesToPut.map(estimate => {
      const clonedEstimate = clonedEstimatesById[estimate.estimate_id];

      return {
        ...estimate,
        id: clonedEstimate.id,
      };
    });

    roadmapVersionId = clonedVersion?.id;
  }

  const updatedEstimatesAndProjects = await putEstimatesAndFetchAffectedProjects(estimatesToPut, roadmapVersionId);

  dispatch({
    payload: updatedEstimatesAndProjects,
    type: UPDATE_ESTIMATES_FULFILLED,
  });

  if (clonedVersion) {
    dispatch({
      type: CLONE_ROADMAP_VERSION_FULFILLED,
      payload: clonedVersion,
      meta: { clonedFrom: selectedRoadmapVersion.id },
    });
  }

  dispatch({
    type: SET_PENDING_CHANGES,
    payload: true,
  });

  return updatedEstimatesAndProjects;
};

const estimateToDto = estimate => ({
  id: estimate.id,
  duration: estimate.duration ? Number(estimate.duration) : undefined,
  num_staff: estimate.numStaff ? Number(estimate.numStaff) : undefined,
  start_date: estimate.start_date,
  progress: estimate.progress,
  sort_order: estimate.sort_order,
  skill_id: estimate.skill?.id,
  team_id: estimate.team?.id,
});

/**
 * Puts estimates changes and returns affected roadmap version projects.
 * @param {Estimate[]} estimates
 * @param {Number} selectedRoadmapVersionId
 * @returns Estimates and affected Projects
 */
const putEstimatesAndFetchAffectedProjects = async (estimates, selectedRoadmapVersionId) => {
  const estimatesToPut = estimates.map(estimateToDto);
  const updateEstimateUrlPath = `/api/v1/roadmap-versions/${selectedRoadmapVersionId}/estimates`;

  const updatedEstimates = await axios.put(updateEstimateUrlPath, estimatesToPut).then(propOr([], 'data'));
  const affectedProjectsIds = pluck('project_id')(updatedEstimates);
  let affectedProjects = [];

  if (!isEmpty(affectedProjectsIds)) {
    affectedProjects = await fetchVersionsProjectsById([selectedRoadmapVersionId], affectedProjectsIds);
  }

  return {
    estimates: updatedEstimates,
    projects: affectedProjects,
  };
};

/**
 * Fetch and flatten all projects from provided {@link versionIds} that match {@link projectIds} filter.
 * @param {number[]} versionIds
 * @param {number[]} projectIds
 * @returns Flattened array of all projects
 */
export const fetchVersionsProjectsById = async (versionIds, projectIds) => {
  const joinAllProjectsInResponse = pipe(propOr({}, 'data'), values, flatten);

  const response = await axios.post('/api/v1/roadmap-versions/search/version/projects-by-id', {
    projectIds,
    roadmapVersionIds: versionIds,
  });

  return joinAllProjectsInResponse(response);
};

export const getEstimatesForRoadmapVersion = roadmapVersionId =>
  axios.get(`/api/v1/roadmap-versions/${roadmapVersionId}/estimates`);

// todo: temp exposure for gantt workaround
export const cloneVersion = selectedRoadmapVersion => {
  return async (dispatch, getState) => {
    let clonedVersion;

    // If changes are made on a committed version we should clone it into an uncommitted
    if (selectedRoadmapVersion.committed) {
      const pageFilters = getPageFilters(getState());

      clonedVersion = await cloneScenarioVersionApiCall(selectedRoadmapVersion.id, pageFilters);

      dispatch({
        type: CLONE_ROADMAP_VERSION_FULFILLED,
        payload: clonedVersion,
        meta: { clonedFrom: selectedRoadmapVersion.id },
      });
      const urlParams = new URLSearchParams(window.location.search);

      urlParams.set('scenario', clonedVersion?.id);

      history.replace({ search: urlParams.toString() });

      // in case we have any go back pointing to the scenario that was cloned we need to update the url
      const goBackUrls = getGoBackUrls(getState());
      const updatedGoBackUrls = goBackUrls.map(url => {
        return url.replace(`?scenario=${selectedRoadmapVersion?.id}`, `?scenario=${clonedVersion?.id}`);
      });

      dispatch(setGoBackUrls(updatedGoBackUrls));
    }
  };
};

export const setRoadmapVersionProjectsApiCall = (roadmapVersionId, filters) =>
  axios.post(`/api/v1/roadmap-versions/${roadmapVersionId}/projects/set`, { filters });

export const setRoadmapVersionProjects = (roadmapVersionId, filters) =>
  createThunk(
    SET_ROADMAP_VERSIONS_PROJECTS,
    setRoadmapVersionProjectsApiCall(roadmapVersionId, filters).catch(throwRequestError),
    {
      roadmapVersionId,
    },
  );
