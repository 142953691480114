import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getCurrentUser } from 'store/login/selectors';
import history from 'store/utils/history';

import DragonAvatar from 'design-system/atoms/DragonAvatar/index';
import DragonBackButton from 'design-system/atoms/DragonBackButton/index';


import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

import { getUserName, getUserShortName } from 'utils/index';
import { getUserRoleLabel } from 'utils/UserTypeMapper';
import useNavigationToPathWithGoBackUrl from 'hooks/useNavigationToPathWithGoBackUrl';

export default () => {
  const user = useSelector(getCurrentUser);
  const { navigateBack } = useNavigationToPathWithGoBackUrl();

  const handleGoBack = () => navigateBack();

  return (
    <Wrapper>
      <BackContainer>
        <DragonBackButton onClick={handleGoBack} type="transparent" icon={<ArrowLeft />} />
      </BackContainer>
      <DragonAvatar
        id="user-avatar-settings"
        onClick={() => history.push('/user/preferences')}
        label={getUserName(user)}
        short={getUserShortName(user)}
        description={getUserRoleLabel(user.role_id)}
        showBorder={false}
        nameHighlighted
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 105px;
  width: 100%;
  height: 100%;
`;

const BackContainer = styled.div`
  width: 230px;
  font-size: ${({ theme }) => theme.typography.fontSizeMedium}px;
  margin-right: 5px;

  svg {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &:hover {
    text-decoration: underline;
  }
`;
