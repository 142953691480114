import moment from 'moment-timezone';

export const PROJECTED_OPTION_BUSINESS_VALUE_KEY = 'business_value';
export const PROJECTED_OPTION_CUSTOMER_VALUE_KEY = 'customer_value';
export const PROJECTED_OPTIONS = [
  { key: PROJECTED_OPTION_BUSINESS_VALUE_KEY, title: 'Benefit' },
  { key: PROJECTED_OPTION_CUSTOMER_VALUE_KEY, title: 'Customer Value' },
];
export const DEFAULT_PROJECTED_OPTION = PROJECTED_OPTIONS[0];

export const STACKED_BY_ROADMAP_KEY = 'roadmap_id';
export const getDefaultStackedByOption = options => options.find(o => o.key === STACKED_BY_ROADMAP_KEY);

export const TIME_BUCKET_TIMEFRAME_KEY = 'timeframe';
export const TIME_BUCKET_PROJECT_ESTIMATED_END_DATE_KEY = 'project_estimated_end_date';
export const TIME_BUCKET_PROJECT_TARGET_END_DATE_KEY = 'project_target_end_date';
export const getDefaultBucketByOption = options => options.find(o => o.key === TIME_BUCKET_PROJECT_ESTIMATED_END_DATE_KEY);

const startDate = moment().startOf('month').startOf('day');
const endDate = startDate.clone().add(1, 'year');

export const DEFAULT_DATE_INTERVAL = {
  startDate,
  endDate,
  unit: 'month',
};
