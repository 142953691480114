import isObject from 'lodash/isObject';

import { convertHoursToString } from 'utils/converTimeToString';
import { INTEGRATIONS_KEYS } from 'constants/integrations';

const JIRA_STATUS_CATEGORY_MAP = {
  DONE: 2,
  'IN PROGRESS': 1,
  'TO DO': 0,
};

/**
 * Generates story text for JIRA integration
 *
 * @function _generateJiraText
 * @param  {Object} story
 * @param  {Object} orgIntegration
 * @return {String}
 */
const _generateJiraText = (story, orgIntegration) => {
  const baseUri = orgIntegration.data.alternative_url ? orgIntegration.data.alternative_url : orgIntegration.data.base_url;
  const jiraUrl = `${baseUri}/browse/${story.data.key}`;

  return `<a href="${jiraUrl}" target="_blank">${story.data.key}</a> ${story.data.summary} (${story.data.issueType})`;
};

/**
 * Generates story text for clubehouse integration
 *
 * @function _generateClubehouseText
 * @param  {Object} story
 * @param  {Object} orgIntegration
 * @return {String}
 */
const _generateClubehouseText = (story, orgIntegration) => {
  return `<a href="https://app.clubhouse.io/${orgIntegration.data.workspace}/story/${story._integration_meta.story_id}"
    target="_blank"> ${orgIntegration.data.workspace}-${story._integration_meta.story_id}
    </a> ${story.title} (${story._integration_meta.story_type})`;
};

/**
 * Generates story text for github integration
 *
 * @function _generateGithubText
 * @param  {Object} story
 * @param  {Object} orgIntegration
 * @return {String}
 */
const _generateGithubText = (story, orgIntegration) => {
  const issueRepo = story && story._integration_meta && story._integration_meta.repo;

  return `<a href="
    https://github.com/${orgIntegration.data.githubOrganization}/${issueRepo}/issues/${story._integration_meta.story_id}"
    target="_blank"> ${issueRepo}-${story._integration_meta.story_id}
    </a> ${story.title}`;
};

/**
 * Generates story text for azure devops integration
 *
 * @function _generateAzureDevOpsText
 * @param  {Object} story
 * @param  {Object} orgIntegration
 * @return {String}
 */
const _generateAzureDevOpsText = (story, orgIntegration) => {
  return `<a href="${story._integration_meta._links.html.href}"
    target="_blank"> ${story._integration_meta.id}
    </a> ${story.title}`;
};

/**
 * Generates story text based on integration type
 *
 * @function _getStoryTextByOrgIntegration
 * @param  {Object} story
 * @param  {Object} orgIntegration
 * @return {String}
 */
const _getStoryTextByOrgIntegration = (story, orgIntegration) => {
  const mapperTextFunc = {
    [INTEGRATIONS_KEYS.JIRA]: _generateJiraText,
    [INTEGRATIONS_KEYS.clubhouse]: _generateClubehouseText,
    [INTEGRATIONS_KEYS.github]: _generateGithubText,
    [INTEGRATIONS_KEYS.azuredevops]: _generateAzureDevOpsText,
  };

  const getTextFunc = mapperTextFunc[orgIntegration.integrationType];

  if (!getTextFunc) return '';

  return getTextFunc(story, orgIntegration);
};

/**
 * Map story to a common format when comes from jira integration
 *
 * @function _mapJiraStory
 * @param  {Object} story
 * @param  {Object} orgIntegration
 * @return {Object}
 */
const _mapJiraStory = (story, orgIntegration) => {
  const statusCategory = +JIRA_STATUS_CATEGORY_MAP[story.data.statusCategory.toUpperCase()];
  const ownerName = isObject(story.data.assignee)
    ? story.data.assignee.displayName || story.data.assignee.name
    : story.data.assignee;

  return {
    id: story.id,
    key: story.data.key,
    project_id: story.parent_project_id,
    text: _getStoryTextByOrgIntegration(story, orgIntegration),
    ownerName,
    status_category: statusCategory,
    phaseTitle: story.data.statusCategory,
    status: story.data.status,
    issueType: story.data.issueType,
    planningStage: story.data.status,
    dateClosed: story.data.dateClosed,
    dateAdded: story.data.dateAdded,
    dateInProgress: story.data.dateInProgress,
    storyPoints: story.data.storyPoints,
    timeSpent: story.data.timeSpent,
    timeRemaining: story.data.timeRemaining,
    timeEstimated: story.data.timeEstimated,
    completedPoints: story.data.completedPoints,
  };
};

/**
 * Map story to a common format when comes from azure devops integration
 *
 * @function _mapAzureDevopsStory
 * @param  {Object} story
 * @param  {Object} orgIntegration
 * @return {Object}
 */
const _mapAzureDevopsStory = (story, orgIntegration) => ({
  id: story.id,
  project_id: story.project_id,
  text: _getStoryTextByOrgIntegration(story, orgIntegration),
  ownerName:
    story._integration_meta.fields['System.AssignedTo'] && story._integration_meta.fields['System.AssignedTo'].displayName
      ? story._integration_meta.fields['System.AssignedTo'].displayName
      : '',
  status: story._integration_meta.fields['System.State'],
  status_category: +story.status_category,
  issueType: story._integration_meta.fields['System.WorkItemType'],
  planningStage: story._integration_meta.fields['System.State'],
  dateClosed: story.date_closed,
  dateAdded: story.date_added,
  dateInProgress: story.date_in_progress,
  storyPoints: story.points_estimated,
  timeEstimated: story.time_estimated ? convertHoursToString(story.time_estimated) : story.time_estimated,
});

/**
 * Map story to a common format
 *
 * @function _mapStory
 * @param  {Object} story
 * @param  {Object} orgIntegration
 * @return {Object}
 */
const _mapStory = (story, orgIntegration) => ({
  id: story.id,
  project_id: story.project_id,
  text: _getStoryTextByOrgIntegration(story, orgIntegration),
  ownerName:
    story._integration_meta.owner_names && story._integration_meta.owner_names.length
      ? story._integration_meta.owner_names[0]
      : '',
  status: story._integration_meta.status,
  status_category: +story.status_category,
  issueType: story._integration_meta.story_type,
  planningStage: story._integration_meta.status,
  dateClosed: story.date_closed,
  dateAdded: story.date_added,
  dateInProgress: story.date_in_progress,
  storyPoints: story?.points_estimated,
});

/**
 * Parser that should convert the stories that comes from api to a common format to be used in the app
 *
 * @param {*} stories
 * @param {*} orgIntegration
 * @returns {*} parsed stories
 */
const parseStories = (stories, orgIntegrations) => {
  const notHasStories = !stories || !stories.length;
  const notHasOrgIntegrations = !orgIntegrations || !orgIntegrations.length;

  if (notHasStories || notHasOrgIntegrations) return null;

  return stories.reduce((acc, story) => {
    const orgIntegrationId = story._integration_meta ? story._integration_meta.org_integration_id : story.orgIntegration_id;
    const orgIntegration = orgIntegrations.find(i => i.id === orgIntegrationId);

    if (!orgIntegration) return acc;

    let data = {};

    switch (orgIntegration.integrationType) {
      case INTEGRATIONS_KEYS.JIRA: {
        data = _mapJiraStory(story, orgIntegration);
        break;
      }
      case INTEGRATIONS_KEYS.azuredevops: {
        data = _mapAzureDevopsStory(story, orgIntegration);
        break;
      }
      default: {
        data = _mapStory(story, orgIntegration);
        break;
      }
    }

    return [...acc, data];
  }, []);
};

export default parseStories;
