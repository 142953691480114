import React from 'react';
import { Backdrop } from '@material-ui/core';
import styled from 'styled-components';

import Loading from '../Loading';

const StyledBackdrop = styled(Backdrop)`
  && {
    position: absolute;
    background-color: ${({ theme }) => theme.palette.background.lightestGray};
    opacity: 0.5 !important;
    z-index: ${({ theme }) => theme.zIndex.highest};
  }
`;

const StyledLoading = styled(Loading)`
  &&&& {
    position: absolute;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    z-index: 1;
  }
`;

const LoadingOverlay = () => {
  return (
    <>
      <StyledBackdrop open />
      <StyledLoading />
    </>
  );
};

export default LoadingOverlay;
