import { useCallback, useMemo, useState } from 'react';

import { afterProcessClipboardDataFactory } from 'design-system/molecules/AgGridReact-New/helpers';

import { callApiUsersExisting, correctUser, getDefaultUsersRows, getWarningMessage, noop } from '../helpers';

const useUsersImport = ({
  createUsers,
  updateUsers,
  importUsers,
  afterImport,
  stateCopyPasteData,
  setStateCopyPasteData,
  setStateContentPasted,
  onClose,
  importWidgetRef,
}) => {
  const [stateShowImportWarningDialog, setStateShowImportWarningDialog] = useState(false);

  const [stateCopyPasteLightboxOpened, setStateCopyPasteLightboxOpened] = useState(false);

  const [stateDisableImport, setStateDisableImport] = useState(false);

  const [stateImportConfirm, setStateImportConfirm] = useState(noop);

  const [stateWarningDialogText, setStateWarningDialogText] = useState('');

  const copyPasteBulkCreateBulkUpdate = useMemo(
    () => afterProcessClipboardDataFactory(createUsers, updateUsers),
    [createUsers, updateUsers],
  );

  const saveCopyPasteData = useCallback(
    (users, sendInvite = false) => {
      const rows = users
        .filter(user => user.first_name)
        .map(user => ({
          ...user,
          sendInvite,
        }));

      return callApiUsersExisting(rows).then(res => {
        const { data: existingUsers } = res;

        if (!existingUsers?.length) {
          return copyPasteBulkCreateBulkUpdate({ create: rows }).then(() => {
            setStateCopyPasteLightboxOpened(false);

            if (afterImport) {
              afterImport();
            }
          });
        }

        setStateCopyPasteLightboxOpened(false);

        setStateShowImportWarningDialog(true);

        setStateImportConfirm(() => () => {
          copyPasteBulkCreateBulkUpdate({ create: rows }).then(() => {
            if (afterImport) {
              afterImport();
            }
          });

          setStateShowImportWarningDialog(false);
        });

        setStateWarningDialogText(getWarningMessage(rows, existingUsers));

        setStateDisableImport(users.length === existingUsers.length);
      });
    },
    [afterImport],
  );

  const clearCopyPasteData = useCallback(() => {
    setStateCopyPasteData(getDefaultUsersRows());

    setStateContentPasted(false);
  }, []);

  const selectCopyPaste = useCallback(() => {
    onClose();

    clearCopyPasteData();

    setStateCopyPasteLightboxOpened(true);
  }, [onClose, clearCopyPasteData]);

  const cancelCopyPaste = useCallback(() => {
    setStateCopyPasteLightboxOpened(false);
  }, []);

  const importAndSendEmailInvitation = useCallback(() => {
    saveCopyPasteData(stateCopyPasteData, true);
  }, [saveCopyPasteData, stateCopyPasteData]);

  const onUsersReadyToImportClick = useCallback(() => {
    return saveCopyPasteData(stateCopyPasteData);
  }, [saveCopyPasteData, stateCopyPasteData]);

  const onCancelImport = useCallback(() => {
    setStateShowImportWarningDialog(false);

    importWidgetRef.current.closeLightbox();
  }, []);

  const importClickHandler = useCallback(() => importWidgetRef.current?.fileInput.click(), []);

  const onImportCsvData = useCallback(
    (users, socketRoom) => {
      return callApiUsersExisting(users).then(res => {
        const { data: existingUsers } = res;

        const correctedUsers = users.map(correctUser);

        const importCsvData = async u => {
          await importUsers(u, socketRoom);

          if (afterImport) {
            afterImport();
          }
        };

        if (!existingUsers?.length) {
          importWidgetRef.current.showLightboxImportInProcess();

          return importCsvData(correctedUsers);
        }

        setStateShowImportWarningDialog(true);

        setStateImportConfirm(() => () => {
          setStateShowImportWarningDialog(false);

          importWidgetRef.current.showLightboxImportInProcess();

          importCsvData(correctedUsers);
        });

        setStateWarningDialogText(getWarningMessage(correctedUsers, existingUsers));

        setStateDisableImport(correctedUsers.length === existingUsers.length);
      });
    },
    [importUsers, afterImport],
  );

  return {
    stateShowImportWarningDialog,
    stateCopyPasteLightboxOpened,
    stateDisableImport,
    stateImportConfirm,
    stateWarningDialogText,

    saveCopyPasteData,
    clearCopyPasteData,
    selectCopyPaste,
    cancelCopyPaste,
    importAndSendEmailInvitation,
    onUsersReadyToImportClick,
    onCancelImport,
    importClickHandler,
    onImportCsvData,
  };
};

export default useUsersImport;
