import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { JIRA_SERVICE_NAME } from 'constants/common';
import PageLink from 'design-system/atoms/PageLink/index';


const ZENDESK_HELP_LINK = 'https://dragonboat.zendesk.com/hc/en-us/articles/360013260974';

export default class ConfigStepTwo extends Component {
  render() {
    const { jiraConfig, onCancel, onNext } = this.props;

    if (!jiraConfig) {
      return '';
    }

    return (
      <StepTwoContent>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <JiraLink
              href={`${jiraConfig.base_url}/plugins/servlet/applinks/listApplicationLinks`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Open Jira Application Links Page
            </JiraLink>
            <StepNotes>
              And paste the following fields in respective fields. Detailed help{' '}
              <PageLink href={ZENDESK_HELP_LINK} target="_blank">here.</PageLink>
            </StepNotes>
            <StepNotes>You need Jira system admin access to link to JIra</StepNotes>
            <StepNotes>Please disable your browser’s popup blocker to allow Jira authentication.</StepNotes>
          </Grid>
          <Grid item xs={6}>
            <TextControl fullWidth margin="dense">
              <TextField
                name="application_url"
                label="Application URL"
                value={window.location.origin}
                inputProps={{
                  onClick: e => e.target.select(),
                }}
              />
            </TextControl>
            <TextControl fullWidth margin="dense">
              <TextField
                name="service"
                label="Consumer Name"
                value={JIRA_SERVICE_NAME}
                inputProps={{
                  onClick: e => e.target.select(),
                }}
              />
            </TextControl>
            <TextControl fullWidth margin="dense">
              <TextField
                name="callback_url"
                label="Callback URL"
                value={`${window.location.origin}/jira/callback`}
                inputProps={{
                  onClick: e => e.target.select(),
                }}
              />
            </TextControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="dense">
              <TextField
                name="consumer_key"
                label="Consumer Key"
                value={jiraConfig.consumer_key}
                inputProps={{
                  onClick: e => e.target.select(),
                }}
              />
            </FormControl>
            <FormControl style={{ marginTop: '10px' }} fullWidth margin="dense">
              <TextField
                name="public_key"
                label="Public Key (Shared Secret)"
                value={jiraConfig.public_key}
                multiline
                inputProps={{
                  onClick: e => e.target.select(),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Actions>
          <Grid container spacing={16}>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <CancelButton id="cancel-button" onClick={onCancel}>
                Cancel
              </CancelButton>
              <NextButton id="next-button" color="primary" onClick={onNext}>
                Next
              </NextButton>
            </Grid>
          </Grid>
        </Actions>
      </StepTwoContent>
    );
  }
}

const StepTwoContent = styled.div`
  textarea,
  input {
    font-size: 12px !important;
  }
`;

const TextControl = styled(FormControl)`
  &&&& {
    margin-bottom: 12px;
    padding-right: 40px;
  }
`;

const StepNotes = styled.div`
  padding: 2px 0;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
`;

const NextButton = styled(Button)`
  &&&& {
    float: right;
  }
`;

const CancelButton = styled(Button)`
  &&&& {
    float: right;
  }
`;

const Actions = styled.div`
  margin-top: 22px;
`;

const JiraLink = styled.a`
  color: #009dff;
  margin-bottom: 12px;
  margin-top: 12px;
  display: block;
`;
