import React from 'react';
import styled from 'styled-components';
import Input from '@material-ui/core/Input';

import ViewsListWithTabs from 'containers/ViewsListWithTabs/ViewsListWithTabs';
import Search from 'design-system/atoms/DragonIcons/Search';
import { PAGE_HEADER_HEIGHT } from 'constants/common';
import useViewsFilters from 'hooks/userViews/useViewsFilters';
import { DASHBOARD_VIEWS } from 'hooks/userViews/useViewsTabs';

export default () => {
  const { searchQuery, setSearchQuery, applyFiltersOnViews } = useViewsFilters();

  return (
    <PageContent>
      <PageBody>
        <SearchContainer>
          <Icon>
            <Search />
          </Icon>
          <Input
            type="text"
            placeholder="Search for a dashboard..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </SearchContainer>
        <ViewsListWithTabs searchQuery={searchQuery} visibleTabs={[DASHBOARD_VIEWS]} applyFilters={applyFiltersOnViews} />
      </PageBody>
    </PageContent>
  );
};

const PageContent = styled.div`
  padding: 0 22px;
  background-color: ${props => props.theme.palette.background.white};
`;

const PageBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
  padding-top: 22px;
`;

const SearchContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 1;

  input {
    padding-left: 22px;

    &::placeholder {
      color: ${({ theme }) => theme.palette.text.quickSilver};
      font-size: ${({ theme }) => theme.typography.fontSize}px;
    }
  }
`;

const Icon = styled.div`
  &&&& {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    svg path {
      fill: ${({ theme }) => theme.palette.text.primary};
    }
  }
`;
