import { BET_LAYER } from 'store/projects/constants';

/**
 * @function getIdeasHiearchyNameByDisplayLayer
 *
 * Util function that returns a string representing a hierarchy of ideas
 *
 * @param  {String} displayLayer
 * @param  {Function} getSystemFieldName
 * @return {String}
 */
const getIdeasHiearchyNameByDisplayLayer = (displayLayer, getSystemFieldName, hasIdeas = true) => {
  const useBet = displayLayer === BET_LAYER;

  let path = useBet
    ? `${getSystemFieldName('bet')} > ${getSystemFieldName('initiative')}`
    : `${getSystemFieldName('initiative')}`;

  path = hasIdeas ? `${path} > ${getSystemFieldName('idea')}` : path;

  return path;
};

export { getIdeasHiearchyNameByDisplayLayer };
