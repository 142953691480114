import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchCustomerRequests as fetchCustomerRequestsAction,
  setCustomerRequestListPage,
  setCustomerRequestPageLimit,
} from 'store/customerRequests/actions';
import {
  getFilters,
  getMultiFilters,
  getPageLimit,
  getResultsPage,
  getSearch,
  getTotalResults,
} from 'store/customerRequests/selectors';

import { CUSTOMER_REQUESTS_DEFAULT_PAGINATION_LIMIT } from 'constants/customerRequests';

import useDeepEffect from 'hooks/useDeepEffect';

export default () => {
  const dispatch = useDispatch();

  const fetchCustomerRequests = (options = {}) => dispatch(fetchCustomerRequestsAction(options));

  const filters = useSelector(getFilters);
  const search = useSelector(getSearch);
  const multiFilters = useSelector(getMultiFilters);
  const pageLimit = useSelector(getPageLimit);
  const totalResults = useSelector(getTotalResults);
  const startingPageLimit = useRef(pageLimit);
  const { current: currentPage } = useSelector(getResultsPage);

  useEffect(() => {
    const options = {};
    const nextTotalPages = Math.ceil(totalResults / pageLimit);

    options.page = currentPage > nextTotalPages ? 1 : currentPage;

    // initial load causes extra fetch
    if (currentPage === options.page && pageLimit === startingPageLimit.current) {
      return;
    }

    fetchCustomerRequests(options);
  }, [pageLimit]);

  useDeepEffect(() => {
    dispatch(setCustomerRequestListPage());

    dispatch(setCustomerRequestPageLimit(CUSTOMER_REQUESTS_DEFAULT_PAGINATION_LIMIT));

    fetchCustomerRequests();
  }, [filters, search, multiFilters]);
};
