import React from 'react';
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';

import { spacing } from 'design-system/theme';
import Dropdown from 'design-system/molecules/Dropdown/index';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import SwitchWithLabel from 'design-system/molecules/SwitchWithLabel/SwitchWithLabel';


import useGridToolbarActions from 'features/LifecyclesGrid/hooks/useGridToolbarActions';

import { TOOLBAR_HEIGHT } from 'features/LifecyclesGrid/helpers/constants';
import { ROW_HEIGHT_OPTIONS } from 'constants/grid';

const GridToolbar = ({ canCreateLifecycle }) => {
  const { createNewLifecycle, changeRowHeight, changeHideArchived, hideArchived = false } = useGridToolbarActions();

  return (
    <ControlsContainer>
      <Flex justifyContent="space-between">
        <Flex flex="1" $marginLeft={10}>
          {canCreateLifecycle ? (
            <AddNewButton id="lifecycle-add-new-button" color="primary" onClick={createNewLifecycle}>
              + New
            </AddNewButton>
          ) : null}
        </Flex>

        <Flex justifyContent="flex-end" gap={`${spacing(0.5)}px`} flex="1">
          <ActionButtonsContainer>
            <StyledDropdown
              label="Row height"
              placeholder={
                <ButtonIcon title="Row height" data-cy="grid-row-height">
                  <FormatLineSpacingIcon />
                </ButtonIcon>
              }
              options={ROW_HEIGHT_OPTIONS}
              onChange={(e, { id }) => {
                changeRowHeight(id);
              }}
              isButton={false}
            />
            <div>
              <SwitchWithLabel
                switchProps={{
                  color: 'primary',
                  checked: hideArchived,
                  onChange: () => changeHideArchived(!hideArchived),
                }}
                labelProps={{
                  color: 'textPrimary',
                }}
                formProps={{
                  labelPlacement: 'start',
                }}
                label="Hide inactive"
              />
            </div>
          </ActionButtonsContainer>
        </Flex>
      </Flex>
    </ControlsContainer>
  );
};

export default GridToolbar;

const ControlsContainer = styled.div`
  height: ${TOOLBAR_HEIGHT}px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};

  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `}
  
  ${({ $paddingRight }) =>
    $paddingRight &&
    css`
      padding-right: ${$paddingRight}px;
    `}

  ${({ $marginLeft }) =>
    $marginLeft &&
    css`
      margin-left: ${$marginLeft}px;
    `}
`;

const AddNewButton = styled(Button)``;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDropdown = styled(Dropdown)`
  margin-right: 0;
`;
