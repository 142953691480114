import React from 'react';
import styled from 'styled-components';
import { isNil, not, path, pipe, defaultTo } from 'ramda';
import Button from '@material-ui/core/Button';
import CreateOrgIntegration from './components/CreateOrgIntegration';
import ConfirmDialog from 'components/ConfirmDialog';
import Content from '../components/Content';
import Title from '../components/Title';
import Body from '../components/Body';
import MicrosoftTeamsConnection from './components/MicrosoftTeamsConnection';
import useMicrosoftTeamsIntegrationSettings from './hooks/useMicrosoftTeamsIntegrationSettings';
import { INTEGRATIONS_KEYS, INTEGRATIONS_NAMES, INTEGRATIONS_ICON_MAPPER } from 'constants/integrations';

const Icon = INTEGRATIONS_ICON_MAPPER.microsoftTeams;
const MICROSOFT_TEAMS = INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.microsoftTeams];
const HELP_LINK = '';

const defaultToEmptyObject = defaultTo({});
const isNotNil = pipe(isNil, not);

const hasValidIntegration = pipe(defaultToEmptyObject, path(['data', 'webhookUrl']), isNotNil);

const MicrosoftTeams = props => {
  const { userCanUpdateOrgIntegration, userCanViewOrgIntegration } = props;

  const {
    selectedOrgIntegration,
    selectedOrgIntegrationId,
    isConfirmDialogDisplayed,
    isAddingOrgIntegration,
    onClickDisconnect,
    onClickConnect,
    onAddOrgIntegrationHandler,
    onRemoveOrgIntegrationHandler,
    onCloseRemoveOrgIntegrationConfirmationHandler,
  } = useMicrosoftTeamsIntegrationSettings();

  const shouldShowCreateIntegrationButton =
    isNil(selectedOrgIntegrationId) && userCanUpdateOrgIntegration && not(isAddingOrgIntegration);

  const shouldShowContactInfo = not(userCanViewOrgIntegration) || !userCanUpdateOrgIntegration;

  return (
    <Content>
      <Title logo={<Icon size={40} marginRight={16} />} title={`${MICROSOFT_TEAMS} Integration`} helpLink={HELP_LINK} />
      {shouldShowContactInfo ? (
        <Body>
          <p>Please contact your organization Admin to activate {MICROSOFT_TEAMS} integration.</p>
        </Body>
      ) : (
        <Body>
          {shouldShowCreateIntegrationButton ? (
            <CreateIntegrationButton>
              <Button id="integrate-button" color="primary" onClick={onClickConnect}>
                Integrate with {MICROSOFT_TEAMS}
              </Button>
            </CreateIntegrationButton>
          ) : (
            <IntegratedContainer>
              {userCanUpdateOrgIntegration && !hasValidIntegration(selectedOrgIntegration) && (
                <CreateOrgIntegration onClickNext={onAddOrgIntegrationHandler} />
              )}
              {hasValidIntegration(selectedOrgIntegration) && (
                <MicrosoftTeamsConnection
                  orgIntegrationId={selectedOrgIntegrationId}
                  onShowDisconnectConfirmation={onClickDisconnect}
                />
              )}
            </IntegratedContainer>
          )}
        </Body>
      )}
      <ConfirmDialog
        title={`Disconnect From ${MICROSOFT_TEAMS}`}
        text={
          <div>
            <p>
              Disconnecting {MICROSOFT_TEAMS} will disable {MICROSOFT_TEAMS} linking and update for your entire Workspace. Do you
              want to continue?
            </p>
            <br />
            <p>You may reconnect in the future.</p>
          </div>
        }
        isOpen={isConfirmDialogDisplayed}
        onCancel={onCloseRemoveOrgIntegrationConfirmationHandler}
        onConfirm={onRemoveOrgIntegrationHandler}
      />
    </Content>
  );
};

const CreateIntegrationButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  width: 100%;
`;

const IntegratedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
  width: 100%;
`;

export default MicrosoftTeams;
