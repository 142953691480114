import React from 'react';

import formatPercent from 'design-system/utils/formatPercent';
import { PROGRESS_PERCENTAGE_DECIMAL_PLACES } from 'constants/common';

const ProgressDisplayRenderer = props => {
  const { data, node, value, jiraIntegrated, valueFormatted } = props || {};

  // valueFormatted can be generated by calc of group progress
  const processedValue = value ?? valueFormatted;

  if (!props.node || !processedValue) {
    return '';
  }
  const progressAsPercentage = formatPercent(processedValue, PROGRESS_PERCENTAGE_DECIMAL_PLACES);

  if (jiraIntegrated && data?.jira_progress === 'auto' && data?.Jiras?.length) {
    return (
      <a href={`/dashboard/progress/?idea=${node.data.id}`} target="_blank" rel="noopener noreferrer">
        {progressAsPercentage}
      </a>
    );
  }

  return <span>{progressAsPercentage}</span>;
};

export default ProgressDisplayRenderer;
