import capitalize from 'lodash/capitalize';

import getValidFieldNames from './getValidFieldNames';
import getValidDateFieldNames from './getValidDateFieldNames';

const getCustomFormulaOptions = (customFields, type) => {
  const validFieldNames = {
    ...getValidFieldNames(customFields, type),
    ...getValidDateFieldNames(customFields, type),
  };

  return Object.keys(validFieldNames).map(field => { 
    return {
      key: validFieldNames[field], 
      title: capitalize(field), 
    };
  }).sort((a, b) => a.value < b.value);
};

export default getCustomFormulaOptions;