import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/HelpOutline';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import HelpLink from 'design-system/atoms/HelpLink/index';
import InlineIcon from 'design-system/atoms/InlineIcon/index';
import SmallText from 'design-system/atoms/SmallText/index';

import { materialColorsAlt } from 'design-system/themes/default';
import { ConfirmDialog } from 'components/gridCommon';
import UpdateConfirmDialog from 'components/ConfirmDialog';

import { getTypeNameBasedOnLayer } from 'constants/projectLightbox';

import formatDateTime from 'utils/dates/formatDateTime';
import getDateTimeFormat from 'utils/dates/getDateTimeFormat';

import getDateForFormatting from '../../utils/isoDateFormat';
import JiraMappingLightbox from '../JiraMappingLightbox';

const LAST_SYNC_DATE_FORMAT = 'YYYY/MM/DD HH:mm';
const UPDATE_IN_PROGRESS_TEXT = 'Update in progress. Please check back in a few minutes.';

const FieldMapping = props => {
  const {
    orgIntegrationId,
    data,
    integrationSettings,
    userCanUpdateOrgIntegration,
    isSyncing,
    isRemoveMappingButtonVisible,
    isConfirmSyncInProgressOpen,
    lastUpdateUserName,
    mappingToDisplay,
    getSystemFieldName,
    isConfirmSyncOpen,
    onOpenConfirmSync,
    onCloseConfirmSync,
    onConfirmSync,
    isJiraMappingLightboxOpen,
    onOpenJiraMappingLightbox,
    onCloseJiraMappingLightbox,
    onSaveJiraMappingLightbox,
    onRemove,
    toggleInProgressDialog,
    mappingLayer,
  } = props;

  const lastMappingEditDay = useMemo(
    () => formatDateTime(getDateForFormatting(integrationSettings?.last_edit_mapping_day, getDateTimeFormat())),
    [integrationSettings?.last_edit_mapping_day],
  );

  const lastSyncDay = useMemo(
    () => formatDateTime(getDateForFormatting(integrationSettings?.last_sync_day, LAST_SYNC_DATE_FORMAT)),
    [integrationSettings?.last_sync_day],
  );

  return (
    <Wrapper>
      {!!mappingToDisplay.length && (
        <>
          <TextDeprecated size="medium" breakwords>
            Mapping between fields in Jira Issue and dragonboat {getSystemFieldName(getTypeNameBasedOnLayer(mappingLayer), true)}:
            <HelpLink
              href="https://dragonboat.zendesk.com/hc/en-us/articles/360025615233"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InlineIcon>
                <HelpIcon />
              </InlineIcon>
            </HelpLink>
          </TextDeprecated>
          <Grid container style={{ margin: '14px 0' }}>
            <Grid item xs={4}>
              <TextDeprecated variant="bold" size="medium">
                dragonboat Field
              </TextDeprecated>
            </Grid>
            <Grid item xs={5}>
              <TextDeprecated variant="bold" size="medium">
                Jira Field
              </TextDeprecated>
            </Grid>
          </Grid>
        </>
      )}
      {mappingToDisplay.map(mappingEntry => (
        <Grid container key={mappingEntry.field} style={{ marginBottom: '6px' }}>
          <Grid item xs={3}>
            <TextDeprecated>{mappingEntry.displayTitle}</TextDeprecated>
          </Grid>
          <Grid item xs={1}>
            <ArrowsText>
              {mappingEntry.dbAutoSync && '←'} <br /> {mappingEntry.jiraAutoSync && '→'}
            </ArrowsText>
          </Grid>
          <Grid item xs={5}>
            <TextDeprecated>{mappingEntry.jiraField && mappingEntry.jiraField.name}</TextDeprecated>
          </Grid>
        </Grid>
      ))}
      <ActionsContainer>
        <ActionItem>
          <Button color="primary" onClick={onOpenJiraMappingLightbox} disabled={!userCanUpdateOrgIntegration}>
            {mappingToDisplay.length ? 'Edit field mapping' : 'Add field mapping'}
          </Button>
          {lastMappingEditDay && <SmallText>{`(Last Update: ${lastMappingEditDay} by ${lastUpdateUserName})`}</SmallText>}
        </ActionItem>
        <ActionItem>
          <Button
            id="update-mapped-fields-jira"
            color="primary"
            disabled={!mappingToDisplay.length || isSyncing || !userCanUpdateOrgIntegration}
            onClick={onOpenConfirmSync}
          >
            Update mapped fields with Jira values now
          </Button>
          {lastSyncDay && <SmallText>(Last Sync: {lastSyncDay})</SmallText>}
        </ActionItem>
      </ActionsContainer>
      {isRemoveMappingButtonVisible && (
        <ActionsContainer>
          <ActionItem>
            <Button
              id="delete-mapping-button"
              color="secondary"
              onClick={onRemove}
              disabled={(isSyncing && mappingToDisplay.length) || !userCanUpdateOrgIntegration}
            >
              <DeleteIcon />
              Remove custom mapping
            </Button>
          </ActionItem>
        </ActionsContainer>
      )}
      <ConfirmDialog
        isOpen={isConfirmSyncOpen}
        title="Are you sure you want to continue?"
        text={
          <div>
            <p style={{ color: materialColorsAlt.red, marginBottom: '1rem' }}>
              This action will update every {getSystemFieldName('idea')} and overwrite other users&apos; changes. Are you sure you
              want to continue?
            </p>
          </div>
        }
        onCancel={onCloseConfirmSync}
        onConfirm={onConfirmSync}
        blankBody
      />
      <UpdateConfirmDialog
        isOpen={isConfirmSyncInProgressOpen}
        onConfirm={() => toggleInProgressDialog(false)}
        text={UPDATE_IN_PROGRESS_TEXT}
        confirmText="Close"
        showCancel={false}
        showTitle={false}
      />
      {isJiraMappingLightboxOpen && (
        <JiraMappingLightbox
          orgIntegrationId={orgIntegrationId}
          isOpen={isJiraMappingLightboxOpen}
          mapping={data}
          onClose={onCloseJiraMappingLightbox}
          onSave={onSaveJiraMappingLightbox}
        />
      )}
    </Wrapper>
  );
};

FieldMapping.propTypes = {
  orgIntegrationId: PropTypes.number,
  data: PropTypes.array,
  integrationSettings: PropTypes.object,
  userCanUpdateOrgIntegration: PropTypes.bool,
  isSyncing: PropTypes.bool,
  isRemoveMappingButtonVisible: PropTypes.bool,
  lastUpdateUserName: PropTypes.string,
  mappingToDisplay: PropTypes.array,
  getSystemFieldName: PropTypes.func,
  isConfirmSyncOpen: PropTypes.bool,
  onOpenConfirmSync: PropTypes.func,
  onCloseConfirmSync: PropTypes.func,
  onConfirmSync: PropTypes.func,
  isJiraMappingLightboxOpen: PropTypes.bool,
  onOpenJiraMappingLightbox: PropTypes.func,
  onCloseJiraMappingLightbox: PropTypes.func,
  onSaveJiraMappingLightbox: PropTypes.func,
  onRemove: PropTypes.func,
};

const Wrapper = styled.div`
  position: relative;
`;

const ArrowsText = styled(TextDeprecated)`
  &&&& {
    line-height: 8px;
    display: inline-flex;
    align-items: center;
    height: 20px;
  }
`;

const ActionsContainer = styled.div`
  margin-top: 16px;
`;

const ActionItem = styled.div`
  margin-left: -8px;
`;

export default FieldMapping;
