import { fetchProjectAssociations } from 'store/projects';
import { fetchFromProjectsApi } from 'store/projects/helpers/fetchProjects';
import getStore from 'store/store';
import { TRANSACTION_PAGES_PROJECTS_LIMIT } from 'constants';
import { createThunk } from 'utils/store/thunk';

import { FETCH_TIMELINE_LIGHTBOX_PROJECTS } from './types';

const paginationAttributes = {
  from: 0,
  to: TRANSACTION_PAGES_PROJECTS_LIMIT,
};

export const fetchProjectsForTimelineLightbox =
  ({ filtersForApiByLevels }) =>
  (dispatch, getState) => {
    const result = new Promise(async (resolve, reject) => {
      const associations = {
        withTasks: true,
        withEstimates: true,
        withIntegrations: true,
        withParents: true,
        withVotes: true,
      };

      const associationsAsArray = Object.entries(associations)
        .filter(([_, val]) => val)
        .map(([key]) => key);

      try {
        const { projectsResult, projectsSearchMetadataResult } = await fetchFromProjectsApi({
          getStore,
          params: filtersForApiByLevels,
          paginationAttributes,
        });

        const { projects, tasks, parents, dependencies } = await fetchProjectAssociations(
          projectsResult,
          filtersForApiByLevels,
          associationsAsArray.reduce((obj, association) => ({ ...obj, [association]: true }), {}),
          {},
          {},
          null,
          paginationAttributes,
          projectsSearchMetadataResult,
        );

        resolve({ projects, tasks, parents, dependencies, projectsSearchMetadataResult });
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });

    dispatch(createThunk(FETCH_TIMELINE_LIGHTBOX_PROJECTS, result));
  };
