import styled from 'styled-components';

const TableBody = styled.div`
  background: #fff;
  position: relative;
  overflow: clip;
`;

TableBody.displayName = 'TableBody';

export default TableBody;
