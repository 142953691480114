/* eslint-disable */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function OktaIcon({ size = 25, marginRight, style }) {
  return (
    <SvgIcon style={{ width: size, height: size, marginRight, ...style }} viewBox="0 0 32 32">
      <path d="M16 0c-8.815 0-16 7.133-16 16s7.134 16 16 16 16-7.134 16-16-7.185-16-16-16zM16 24c-4.433 0-8-3.567-8-8s3.567-8 8-8 8 3.567 8 8-3.567 8-8 8z"></path>
    </SvgIcon>
  );
}
