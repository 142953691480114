import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepLinkContext } from 'src/deep-link/useDeepLinkContext';

import {
  getNotifications,
  getFieldIntegrationErrors,
  addNotifications as addNotificationsAction,
  addNotification as addNotificationAction,
  removeNotification as removeNotificationAction,
  resetNotifications as resetNotificationsAction,
  dismissNotification as dismissNotificationAction,
} from 'store/app';

const useAppNotifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifications) ?? [];
  const fieldIntegrationErrors = useSelector(getFieldIntegrationErrors) ?? [];
  const { processNotificationFromUrl } = useDeepLinkContext();

  useEffect(() => {
    processNotificationFromUrl();
  }, [processNotificationFromUrl]);

  const addNotifications = useCallback(notifications => dispatch(addNotificationsAction(notifications)), [dispatch]);
  const addNotification = useCallback(notification => dispatch(addNotificationAction(notification)), [dispatch]);
  const addError = useCallback(notification => dispatch(addNotificationAction({ ...notification, type: 'error' })), [dispatch]);
  const removeNotification = useCallback(id => dispatch(removeNotificationAction(id)), [dispatch]);
  const dismissNotification = useCallback(id => dispatch(dismissNotificationAction(id)), [dispatch]);
  const resetNotifications = useCallback(() => dispatch(resetNotificationsAction()), [dispatch]);

  return {
    notifications,
    fieldIntegrationErrors,
    addNotifications,
    addNotification,
    addError,
    dismissNotification,
    removeNotification,
    resetNotifications,
  };
};

export default useAppNotifications;
