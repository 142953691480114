import Config, { Configurator } from '@dragonboat/config';

/**
 * Get storage implementation based on browser's localStorage
 * @returns {ConfiguratorStorage} storage
 */
const getDefaultStorage = () => {
  return {
    get: key => localStorage.getItem(key),
    set: (key, value) => localStorage.setItem(key, value),
    clear: key => localStorage.removeItem(key),
  };
};

/**
 * Creates configurator ready to read and override flags
 * @param {ConfiguratorStorage} storage
 * @returns {Configurator} configurator
 */
const createConfigurator = (storage = getDefaultStorage()) => {
  return new Configurator(storage);
};

/**
 * Creates default Config.
 * @returns {Config} Config
 */
const createConfig = () => {
  return new Config(createConfigurator());
};

const Configuration = createConfig();

export { Configuration, createConfig, createConfigurator, getDefaultStorage };
