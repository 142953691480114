import { getAvailableIntegrations } from 'features/OneStepIntegration/store';

import { useSelector } from 'react-redux';

import { getIntegrationById as getIntegrationByIdSelector } from 'store/organization';

const useIntegrationOpenExternalUrlButton = (orgIntegrationId, externalUrl) => {
  const orgIntegration = useSelector(getIntegrationByIdSelector(orgIntegrationId));
  const integrationType = orgIntegration?.integrationType;

  const oneStepAvailableIntegrations = useSelector(getAvailableIntegrations);

  const buttonTitle = oneStepAvailableIntegrations[integrationType]?.title;

  const onButtonClick = () => {
    window.open(externalUrl, '_blank');
  };

  return {
    integrationType,
    buttonTitle,
    onButtonClick,
  };
};

export default useIntegrationOpenExternalUrlButton;
