import React from 'react';
import noop from 'lodash/noop';

import { BASE_Z_INDEX, isMilestoneTopGroup, MILESTONES_DND_BOUNDS_NAME } from '../../helpers';

import { TimelineCell, TimelineCellTitle, TimelineRow } from '../styled';

const defaultRenderTitle = lines => item => <TimelineCellTitle lines={lines}>{item.title}</TimelineCellTitle>;

const TimelineColumnGroupRow = ({ children, group, groupWidth, left = 0, onClick = noop, groupTitleRenderer }) => (
  <TimelineRow>
    <TimelineCell
      zIndex={BASE_Z_INDEX}
      left={left}
      sticky
      center
      width={`${groupWidth}px`}
      color={group.color}
      rowsCount={group.rowsCount}
      bordered
      bottomBorder
    >
      {groupTitleRenderer ? groupTitleRenderer(group, defaultRenderTitle(group.rowsCount)) : defaultRenderTitle(group.rowsCount)}
    </TimelineCell>
    <TimelineCell
      rowsCount={group.rowsCount}
      width={`calc(100% - ${groupWidth}px)`}
      onClick={onClick}
      bottomBorder
      className={isMilestoneTopGroup(group) ? MILESTONES_DND_BOUNDS_NAME : ''}
    >
      {children}
    </TimelineCell>
  </TimelineRow>
);

export default TimelineColumnGroupRow;
