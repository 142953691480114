/* eslint-disable react/no-array-index-key */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';

import { isMilestoneTopGroup } from '../../../helpers';

import { TimelineCellContent } from '../../styled';

import ItemsRow from './ItemsRow';
import TimelineInsideGroup from './TimelineInsideGroup';
import TimelineColumnGroupRow from '../TimelineColumnGroupRow';

const InsideGroupsTripleGrouping = ({
  itemRenderer,
  data,
  groupsWidths,
  onRowClick,
  isGroupOpen,
  toogleGroup,
  groupTitleRenderer,
}) =>
  data.map((groupLevel1, level1Index) => (
    <TimelineColumnGroupRow
      key={groupLevel1.key}
      group={groupLevel1}
      groupWidth={groupsWidths[0]}
      groupTitleRenderer={groupTitleRenderer}
    >
      {groupLevel1?.groups?.map((groupLevel2, level2Index) => (
        <TimelineColumnGroupRow
          key={`${groupLevel2.key}-${groupLevel2.rowsCount}`}
          group={groupLevel2}
          groupWidth={groupsWidths[1]}
          left={groupsWidths[0]}
          onClick={onRowClick([groupLevel1, groupLevel2])}
          groupTitleRenderer={groupTitleRenderer}
        >
          {isEmpty(groupLevel2.groupings) ? (
            <TimelineCellContent />
          ) : (
            groupLevel2?.groupings?.map((grouping, groupingIndex) => {
              // const newKey = createRowOrderKey(groupLevel1.key, groupLevel2.key, ...grouping.groupRow.map(group => group.key));
              // TODO: remove uuid

              return (
                <div key={uuidv4()}>
                  {/* GROUP HEADERS */}
                  {grouping?.groupRow ? (
                    <TimelineCellContent>
                      {grouping?.groupRow?.map(rowGroup => {
                        return (
                          <TimelineInsideGroup
                            key={`group-header-${rowGroup.key}`}
                            isOpen={isGroupOpen(rowGroup.key) || isMilestoneTopGroup(rowGroup)}
                            group={rowGroup}
                            groupsWidths={groupsWidths}
                            onClick={toogleGroup(rowGroup.key)}
                            isTop
                          />
                        );
                      })}
                    </TimelineCellContent>
                  ) : null}

                  {/* ITEM ROWS UNDER GROUPS */}
                  {/*   TODO: remove uuid and build a unique key based on the data */}
                  {grouping?.rows?.map((rowItems, rowIndex) => {
                    return (
                      <ItemsRow
                        key={uuidv4()}
                        itemRenderer={itemRenderer}
                        items={rowItems}
                        rowIndex={rowIndex}
                        groupIndexes={[level1Index, level2Index, groupingIndex]}
                        isTopMilestone={isMilestoneTopGroup(groupLevel1)}
                      />
                    );
                  })}
                </div>
              );
            })
          )}
        </TimelineColumnGroupRow>
      ))}
    </TimelineColumnGroupRow>
  ));

export default InsideGroupsTripleGrouping;
