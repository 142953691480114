import React from 'react';
import styled from 'styled-components';

import MuiButton from '@material-ui/core/Button';

import { spacing } from 'design-system/theme';
import Condition from 'features/DBQL/AdvancedQueryBuilder/components/Condition';
import ConditionGroup from 'features/DBQL/AdvancedQueryBuilder/components/ConditionGroup';

import useGroupConditionQueryBuilder from 'features/DBQL/AdvancedQueryBuilder/hooks/useGroupConditionQueryBuilder';

const emptyArray = [];

const AdvancedQueryBuilder = ({
  allFields = [],
  userCanEditFilter = true,
  getFieldOptions,
  conditions = emptyArray,
  onUpdateConditions,
  className,
}) => {
  const onChange = (_, changes) => {
    onUpdateConditions(changes);
  };
  const { onChangeCondition, addNewCondition, addNewConditionGroup, onRemoveCondition, onUpdateGroupOperator } =
    useGroupConditionQueryBuilder({
      allFields,
      onChange,
      conditions,
    });

  return (
    <Wrapper className={className}>
      <Conditions>
        {conditions?.map((condition, index) =>
          condition?.group ? (
            <ConditionGroup
              index={index}
              condition={condition}
              allFields={allFields}
              getFieldOptions={getFieldOptions}
              onChange={onChangeCondition}
              onRemove={onRemoveCondition}
              onUpdateGroupOperator={onUpdateGroupOperator}
            />
          ) : (
            <Condition
              index={index}
              condition={condition}
              allFields={allFields}
              getFieldOptions={getFieldOptions}
              onChange={onChangeCondition}
              onRemove={onRemoveCondition}
              onUpdateGroupOperator={onUpdateGroupOperator}
            />
          ),
        )}
      </Conditions>
      {userCanEditFilter ? (
        <ConditionActions>
          <AddConditionContainer>
            <Button id="new-advanced-search-add-condition" color="primary" onClick={addNewCondition}>
              + Add Condition
            </Button>
            <Button id="new-advanced-search-add-group-condition" onClick={addNewConditionGroup}>
              + Add Condition Group
            </Button>
          </AddConditionContainer>
        </ConditionActions>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const Conditions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spacing()}px;
`;
const ConditionActions = styled.div``;
const AddConditionContainer = styled.div`
  padding: ${spacing(2)}px 0 ${spacing()}px 0;
`;
const Button = styled(MuiButton)`
  &&&& {
    text-transform: initial;
  }
`;

export default AdvancedQueryBuilder;
