import React, { useMemo } from 'react';
import styled from 'styled-components';

import Dropdown from 'design-system/molecules/Dropdown/index';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import ZoomIcon from 'design-system/atoms/ZoomIcon/index';
import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';

import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormGroup from '@material-ui/core/FormGroup';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Roadmap from 'design-system/atoms/DragonIcons/Roadmap';

import InsightsIcon from 'design-system/atoms/InsightsIcon';
import DropdownFilter from 'components/DropdownFilter';
import { ROW_HEIGHT_OPTIONS } from 'constants/grid';
import { SNAPSHOT_PAGE, GOALS_SNAPSHOT_PAGE } from 'constants/filters';
import ShareView from 'containers/ShareView';
import { getMoreDropdownOptions, getNextNumberOfCardsPerRow } from '../helpers';
import ControlsToggle from './ControlsToggle';
import MetricChartViewTimePeriodInput from './MetricChartViewTimePeriodInput';

const DROPDOWN_MAX_WIDTH = '500px';

const SnapshotControls = ({
  expandTableItems,
  handleCollapseExpandClick,
  onUpdateGridConfig,
  updateState,
  handleTableShowFieldsClicked,
  isOKRLastLevel,
  showSnapshotTreeView,
  shouldRenderControls,
  showExpandMore,
  moreButtonRef,
  showSnapshotTable,
  selectedSnapshotField,
  hideCards,
  hideEmptyCards,
  onToggleMetricViewMode,
  shouldRenderMetricMode,
  canViewMetricModeToggle,
  areClustersVisible,
  onToggleClustersVisibility,
  metricDateRangeProps = {},
  hasPermissionForMetricMode = false,
  hasProjectMetrics,
  isGoalMode = false,
  cardsPerRow,
  visibleCardElements,
  snapshotFields,
  groupedBy,
  onDropdownChange,
  onSetPageUserView,
}) => {
  const pageId = isGoalMode ? GOALS_SNAPSHOT_PAGE : SNAPSHOT_PAGE;

  const shouldRenderPageTitle = !shouldRenderMetricMode && !isGoalMode;
  const shouldRenderCardsPerRowButton = useMemo(() => !shouldRenderMetricMode && !hideCards, [shouldRenderMetricMode, hideCards]);

  const changeCardsPerRow = () => {
    updateState({ cardsPerRow: getNextNumberOfCardsPerRow(cardsPerRow) });
  };

  const _renderPageTitle = () => (
    <PageTitleContainer>
      <StyledDropdownFilter
        name="selectField"
        options={snapshotFields}
        selectedValue={groupedBy}
        onChange={onDropdownChange}
        style={{ margin: `0 ${spacing()}px`, minWidth: '100px', maxWidth: DROPDOWN_MAX_WIDTH }}
        hideAll
      />
      <Text variant="h2">Snapshot</Text>
    </PageTitleContainer>
  );

  const _renderExpandMoreButton = () =>
    shouldRenderMetricMode ? null : (
      <ControlItem>
        <ButtonIcon
          title={expandTableItems ? 'Collapse all' : 'Expand all'}
          onClick={handleCollapseExpandClick}
          id="expand-collapse"
        >
          {expandTableItems ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ButtonIcon>
      </ControlItem>
    );

  const _renderRowHeightButton = () =>
    shouldRenderMetricMode ? null : (
      <ControlItem>
        <Dropdown
          label="Row height"
          placeholder={
            <ButtonIcon title="Row height" data-cy="grid-row-height">
              <FormatLineSpacingIcon />
            </ButtonIcon>
          }
          options={ROW_HEIGHT_OPTIONS}
          onChange={(e, { id }) => {
            onUpdateGridConfig('rowHeight', id);
          }}
          isButton={false}
        />
      </ControlItem>
    );

  const _renderShareViewButton = () => (
    <ControlItem>
      <ShareView pageId={pageId} onSetPageUserView={onSetPageUserView} />
    </ControlItem>
  );

  const _renderMoreOptions = () => (
    <ControlItem ref={moreButtonRef}>
      <Dropdown
        placeholder={
          <ButtonIcon>
            <MoreHorizIcon />
          </ButtonIcon>
        }
        options={getMoreDropdownOptions(
          updateState,
          handleTableShowFieldsClicked,
          isOKRLastLevel,
          showSnapshotTreeView,
          showSnapshotTable,
          selectedSnapshotField,
          hideCards,
          hideEmptyCards,
          isGoalMode,
          visibleCardElements,
        )}
        isButton={false}
      />
    </ControlItem>
  );

  const _renderMetricViewToggle = () => (
    <ControlsToggle
      id="snapshot__metric__view__toggle"
      icon={<InsightsIcon fontSize="inherit" />}
      on={shouldRenderMetricMode}
      onChange={onToggleMetricViewMode}
      title="Metric View Mode"
      disabled={!hasPermissionForMetricMode}
    />
  );

  const _renderClustersVisibilityToggle = () => (
    <ControlsToggle
      id="display_projects"
      icon={<Roadmap />}
      on={areClustersVisible}
      onChange={onToggleClustersVisibility}
      title="Display linked portfolio items"
      disabled={!hasPermissionForMetricMode}
    />
  );

  const _renderMetricTimePeriodInput = () => (
    <MetricChartViewTimePeriodInput {...metricDateRangeProps} disabled={!hasPermissionForMetricMode} />
  );

  const _renderCardsPerRowButton = () => (
    <ButtonIcon title={`${cardsPerRow} cards per row`} onClick={changeCardsPerRow} id="cards-per-row-button">
      {cardsPerRow === 2 && (
        <ZoomIcon size="small">
          <span />
        </ZoomIcon>
      )}
      {cardsPerRow === 3 && (
        <ZoomIcon size="medium">
          <span />
        </ZoomIcon>
      )}
      {cardsPerRow === 4 && (
        <ZoomIcon>
          <span />
        </ZoomIcon>
      )}
    </ButtonIcon>
  );

  return (
    <>
      {shouldRenderControls && (
        <ControlsContainer>
          <Flex justifyContent="space-between">
            {shouldRenderPageTitle && _renderPageTitle()}
            {shouldRenderMetricMode && _renderMetricTimePeriodInput()}
            <FormGroup row style={{ justifyContent: 'flex-end', alignItems: 'center', gap: `${spacing(0.5)}px`, flex: 1 }}>
              {showExpandMore && _renderExpandMoreButton()}
              {_renderShareViewButton()}
              {shouldRenderCardsPerRowButton && _renderCardsPerRowButton()}
              {!isGoalMode && _renderRowHeightButton()}
              {canViewMetricModeToggle && _renderMetricViewToggle()}
              {shouldRenderMetricMode && hasProjectMetrics && _renderClustersVisibilityToggle()}
              {!shouldRenderMetricMode && _renderMoreOptions()}
            </FormGroup>
          </Flex>
        </ControlsContainer>
      )}
    </>
  );
};

export default SnapshotControls;

const ControlsContainer = styled.div`
  margin: ${spacing(0.5)}px auto;
`;

const ControlItem = styled.div``;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
`;

const PageTitleContainer = styled(Flex)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledDropdownFilter = styled(DropdownFilter)`
  #select-selectField {
    font-size: ${({ theme }) => theme.typography.fontSizeMediumRem}rem;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    color: ${({ theme }) => theme.palette.text.primary};
    padding-bottom: 0;
  }
`;
