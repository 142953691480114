export const brandColor = '#58ccc6';
export const defaultGanttBlue = '#c0e9f5';
export const linkColor = '#009dff';
export const textColor = '#6b6b6b';
export const agGridColumnTextColor = '#303030';

export const materialColorsArr = [
  '#FF154B',
  '#03DEA3',
  '#FFBA00',
  '#F4CEFF',
  '#E1E5B0',
  '#D1EEF8',
  '#FFCCCC',
  '#E2E2E2',
  '#E9F098',
  '#C0E9F5',
  '#A4F2A1',
  '#93E3FF',
  '#F0F4D3',
];

export const materialColorsAlt = {
  '': '#f6f6f6',
  gray: '#f6f6f6',
  red: '#c62828',
  green: '#2e7632',
  lightGreen: '#03dea3',
  yellow: '#ff8f00',
  blue: '#0aa5dc',
  lightBlue: '#93e3ff',
  lightGray: '#eee',
  darkGray: '#9e9e9e',
  darkerGray: '#616161',
  darkestGray: '#333',
  lightPurple: '#F4CEFF',
};

export const materialColors = {
  '': '#f6f6f6',
  gray: '#f6f6f6',
  red: '#ff154b',
  green: '#59d146',
  yellow: '#fde000',
  blue: '#93e3ff',
  lightGray: '#eee',
  darkGray: '#9e9e9e',
  darkerGray: '#616161',
  // new colors: name based on https://chir.ag/projects/name-that-color/#3461AC
  azure: '#3461AC',
  silver: '#BCBCBC',
  mySin: '#FFB320',
  cerulean: '#00B1F3',
  turquoise: '#2BCFC8',
  jungleGreen: '#29C546',
  mediumPurple: '#934FEA',
  curiousBlue: '#37a5da',
  downy: '#58ccc6',
  // border colors
  borderGray: '#0002',
  shadowGray: '#a7a8a9',
  trainShadownGray: '#6f77821a',
  textPrimary: '#131c23',
};

export const materialBackgroundCore = {
  '': '#9E9E9E',
  gray: '#9E9E9E',
  red: '#ffcdd2ff',
  green: '#c8e6c9ff',
  yellow: '#fff9c4ff',
  blue: '#a2c5d8',
};

export const materialBackground = {
  ...materialBackgroundCore,
  polar: '#EAF5F6',
  hawkesBlue: '#d7e9fb',
  alabaster: '#F9F9F9', // gray
  defaultBackground: '#f6f7fb',
  trainGray: '#f5f3f3',
  white: '#fff',
  black: '#000',
  whiteSmoke: '#f5f5f5',
  roadmapVersionCellBackground: '#F3F7FB',
  lightBlue: '#DEF5FF',
};
