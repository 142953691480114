// External dependencies
import React, { useState, useEffect } from 'react';
import { pipe, not, isNil } from 'ramda';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { getTeams } from 'store/teams/selectors';
import { getSkills } from 'store/skills/selectors';

const notNil = pipe(isNil, not);

const MissingTeamsSkillsAlert = () => {
  const [isOpen, setIsOpen] = useState(null);

  const onClose = () => setIsOpen(false);

  const teams = useSelector(getTeams);
  const skills = useSelector(getSkills);

  useEffect(() => {
    if (notNil(isOpen)) {
      return;
    }

    const orgDoesNotHaveTeams = teams.length === 0;
    const orgDoesNotHaveSkills = skills.length === 0;

    if (orgDoesNotHaveTeams || orgDoesNotHaveSkills) {
      setIsOpen(true);
    }
  }, [teams, skills, isOpen]);

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <DialogContent>
        To enter estimates, please create <Link to="/settings/teams">Team</Link> and <Link to="/settings/skills">Skill</Link> on
        Settings page.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MissingTeamsSkillsAlert;
