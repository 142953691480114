import axios from 'axios';
import omit from 'lodash/omit';

import getStore from 'store/store';
import { fetchFromProjectsApi } from 'store/projects/helpers/fetchProjects';
import { getSelectedRoadmapVersion } from 'store/roadmapVersions/selectors';
import { fetchProjectAssociations } from 'store/projects/actions';
import { IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { APPLY_FILTERS } from 'store/projects/types';
import compileFiltersBody, { compileMainLayerFiltersBody } from 'utils/filters/compileFiltersBody';
import { createThunk } from 'utils/store/thunk';
import { getDropdownCustomFields } from 'store/customFields/selectors';
import { selectSummaryUserAddedMetadataIdsExcludingUndefined } from './selectors';
import { compileRequiredMetadataIdsForMetadataService } from './helpers';

export const applyFiltersOnSummary =
  ({ filters, userFilters, hasBet, hasHierarchy, pageId, displayLayer, paginationAttributes }) =>
  async (dispatch, getState) => {
    const state = getState();
    const userAddedMetadata = selectSummaryUserAddedMetadataIdsExcludingUndefined(state);

    // On summary page we only see main layer, so we need to compile in this way so we don't ask for more than that
    const filtersForApiMainLevel = compileMainLayerFiltersBody(filters, userFilters, hasBet, pageId, displayLayer);

    // We still need to compile all layers to dispatch to the store,
    // otherwise it will overwrite the portfolio filters and we don't want that.
    // Meaning, if the user moves to another page we don't want to lose the other layers
    const filtersForApiByLevels = compileFiltersBody(filters, userFilters, hasBet, pageId, displayLayer);

    const customFields = getDropdownCustomFields(state, true); // get only dropdown custom fields include archived ones

    let projects;
    let parents;
    let projectsResult;
    let projectsSearchMetadataResult;

    let layers = filters ? filters.layer : null;

    if (layers && !Array.isArray(layers)) layers = [layers];

    const result = new Promise(async (resolve, reject) => {
      const selectedRoadmapVersion = getSelectedRoadmapVersion(state);

      try {
        ({ projectsResult, projectsSearchMetadataResult } = await fetchFromProjectsApi({
          getStore,
          layers,
          params: filtersForApiMainLevel,
          paginationAttributes,
          selectedRoadmapVersion,
        }));

        ({ projects, parents } = await fetchProjectAssociations(
          projectsResult,
          filtersForApiMainLevel,
          {
            withParents: hasHierarchy && [IDEA_LAYER, INITIATIVE_LAYER].includes(displayLayer),
          },
          {},
          false,
          selectedRoadmapVersion?.id,
          paginationAttributes,
          projectsSearchMetadataResult,
        ));

        const { data: metadataOnDemand } = await axios.post(`/api/projects/search/metadata`, {
          requiredMetadataIds: compileRequiredMetadataIdsForMetadataService(userAddedMetadata),
          filters: {
            searchUuid: projectsSearchMetadataResult.searchUuid,
          },
        });

        resolve({ projects, parents, projectsSearchMetadataResult, metadataOnDemand });
      } catch (err) {
        console.log(err);
        reject(err);
      }
    });

    dispatch(
      createThunk(
        APPLY_FILTERS,
        result.then(data => data),
        {
          layers: ['0', '1', '2'],
          params: omit(['layer'], filtersForApiByLevels),
          projectsSearchMetadataResult,
          addToIds: true,
          customFields,
        },
      ),
    );

    return result;
  };
