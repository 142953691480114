import React from 'react';
import styled from 'styled-components';
import TextFieldNoBorder from 'design-system/atoms/TextFieldNoBorder';

export default ({ editable, value, onChange, forbidenValues }) => {
  const [text, setText] = React.useState(value);
  const inputRef = React.useRef();

  const _handleChange = event => {
    setText(event.target.value);
  };

  const _handleUpdate = () => {
    if (forbidenValues.some(f => f === text)) {
      setText(value);
      onChange();
      return;
    }
    onChange(text);
  };

  const _handleKeyDown = event => {
    if (event.key === 'Enter') {
      _handleUpdate();
    }
  };

  React.useEffect(() => {
    if (editable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editable]);

  return (
    <TextFieldWrap>
      <TextFieldNoBorder
        disabled={!editable}
        value={text}
        onChange={_handleChange}
        onBlur={_handleUpdate}
        onKeyDown={_handleKeyDown}
        inputRef={inputRef}
      />
    </TextFieldWrap>
  );
};

const TextFieldWrap = styled.div`
  &&&& {
    input {
      font-size: ${({ theme }) => theme.typography.fontSize}px;
      margin: 0;
      padding: 0;
      text-overflow: ellipsis;
      color: ${props => props.theme.palette.newLayout.background.disabled};
    }

    &:hover {
      input[disabled] {
        color: ${props => props.theme.palette.newLayout.background.primary};
      }
    }
  }
`;
