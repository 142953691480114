import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';

import FluidMultiSelectField from 'design-system/organisms/FluidMultiSelectField';

const createOption = val => Promise.resolve(val);

const getAllowList = (settingsState, defaultUserDomainName) => {
  if (settingsState?.allow?.length) {
    return settingsState.allow;
  }

  // use default values if the list is empty and listType isn't deny
  if (settingsState?.listType !== 'deny') {
    const defaultAllow = [{ title: 'zendesk.com', id: 'zendesk.com' }];

    if (defaultUserDomainName) {
      defaultAllow.push({ title: defaultUserDomainName, id: defaultUserDomainName });
    }
    return defaultAllow;
  }

  return [];
};

export default props => {
  const { updateConfig, accessConfig, allowMultiSelectLabel, defaultUserDomainName } = props;

  const LIST_TYPES = {
    ALLOW: 'allow',
    DENY: 'deny',
  };
  const [domains, setDomains] = useState({
    deny: {
      type: LIST_TYPES.DENY,
      names: undefined,
    },
    allow: {
      type: LIST_TYPES.ALLOW,
      names: undefined,
    },
    selected: LIST_TYPES.ALLOW,
  });

  useEffect( () => {
    if (!accessConfig){
      setDomains({
        ...domains,
        allow: {
          ...domains.allow,
          names: getAllowList(null, defaultUserDomainName),
        },
      });
    } else if (accessConfig?.listType === LIST_TYPES.ALLOW){
      if ( accessConfig?.allow && accessConfig?.allow.length > 0 ){
        setDomains({
          ...domains,
          allow: {
            ...domains.allow,
            names: accessConfig.allow,
          },
        });
      } else {
        setDomains({
          ...domains,
          allow: {
            ...domains.allow,
            names: getAllowList(null, defaultUserDomainName),
          },
        });
      }
    }
  }, []);

  const updateListType = listType => {
    updateConfig({
      ...(accessConfig || {}),
      deny: domains.deny.names ? domains.deny.names : [],
      allow: domains.allow.names ? domains.allow.names : [],
      listType,
    });

    if (listType === LIST_TYPES.ALLOW){
      setDomains({
        ...domains,
        allow: {
          ...domains.allow,
          name: domains.allow.names,
        },
      });
      return domains.allow.names;
    }
  };

  const multiSelectProps = {
    disableEdit: false,
    hideCreateOption: false,
    optionsMapper: { label: 'title', value: 'id', color: 'color' },
    onCreateOption: createOption,
  };

  const _getInputProps = listType => {
    return {
      placeholder: '',
      noOptionsMessage: () => 'Write a domain and press enter',
      onFocus: () => {
        updateListType(listType);
      },
    };
  };
  
  /**
   * 
   * @param {*} listType constant of List types
   * @param {*} domainValues array of domain values, e.g. ['name.com','foo.com']
   */
  const handleOnChange = (listType, domainValues) => {
    if (listType === LIST_TYPES.ALLOW){
      let newDomainNames = domainValues.map( (domain) => ({ title: domain, id: domain }));

      if (domainValues.length === 0){
        newDomainNames = getAllowList(null, defaultUserDomainName);
      }
      updateConfig({
        ...(accessConfig || {}),
        deny: domains.deny.names ? domains.deny.names : [],
        allow: newDomainNames,
        listType,
      });

      setDomains({
        ...domains,
        allow: {
          ...domains.allow,
          names: newDomainNames,
        },
      });
    }
  };

  return (
    <Grid container>
      {/* allow */}
      <Grid container direction="row" alignContent="flex-start" justify="flex-start" spacing={0}>
        <RadioCondensed
          disableRipple
          color="primary"
          checked={domains.selected === LIST_TYPES.ALLOW}
          onChange={() => (
            setDomains({
              ...domains,
              selected: LIST_TYPES.ALLOW,
            })
          )}
          value={LIST_TYPES.ALLOW}
          name="radio-button-allow"
        />
        <FluidMultiSelectField
          {...multiSelectProps}
          label={allowMultiSelectLabel}
          inputProps={_getInputProps(LIST_TYPES.ALLOW)}
          name={LIST_TYPES.ALLOW}
          onChange={domainValue => handleOnChange(LIST_TYPES.ALLOW, domainValue)}
          options={domains.allow.names ? domains.allow.names : []}
          value={domains.allow.names ? domains.allow.names : []}
        />
      </Grid>
      
      {/* deny */}
      {/* TODO: When you enable this code block: add defaultUserDomainName to the list of props above. */}
      {/* <Grid container direction="row" alignContent="flex-start" justify="flex-start" spacing={0}>
        <RadioCondensed
          disableRipple
          color="primary"
          checked={accessConfig?.listType === 'deny'}
          onChange={({ target }) => updateListType(target.value)}
          value="deny"
          name="radio-button-allow"
        />
        <FluidMultiSelectField
          {...multiSelectProps}
          label={denyMultiSelectLabel}
          inputProps={_getInputProps('deny')}
          name="deny"
          onChange={domains => updateDomains({ domains, listType: 'deny' })}
          options={accessConfig?.deny}
          value={accessConfig?.deny}
        />
      </Grid> */}
    
    </Grid>

  );
};

const RadioCondensed = styled(Radio)`
  &&&& {
    padding: 2px 0 0 0;
    align-items: flex-start;
  }
`;
