import { defaultTo } from 'ramda';

import { KEY_FIELD_MAP } from 'store/projects/helpers/groupOptions';
import { ALLOWED_GROUP_BY_2_OPTIONS_ON_FORECAST_BY_TIME_CAPACITY } from 'constants/forecast';
import { TEAM } from 'constants/common';

const defaultAsEmptyString = defaultTo('');

const checkFirstGroupIsTimeframeL1OrL2 = selectedGroup =>
  [KEY_FIELD_MAP.timeframe, KEY_FIELD_MAP.timeframe2].includes(selectedGroup?.field);

const checkIsGroupValidForReleaseCapacity = selectedGroup =>
  ALLOWED_GROUP_BY_2_OPTIONS_ON_FORECAST_BY_TIME_CAPACITY.includes(selectedGroup?.field);

/**
 * Check if should show timeframe capacity on grid based on current row group option
 *
 * - [OR] Grouping by timeframe on first group
 * - [OR] Timeframe exists on page filters and current group 1 is valid for release capacity
 * - [OR] Second group if capacity is purely based on valid group 1 - if is based on filters never allow capacity
 *
 * @param {Object} selectedGroup1 - The selected group option for group 1.
 * @param {Object} selectedGroup2 - The selected group option for group 2.
 * @param {Object} groupOption - current group object of the row
 * @returns {boolean} - The result of the check.
 */
const checkIfShouldShowTimeframeCapacityOnGrid = (selectedGroup1, selectedGroup2, groupOption) => {
  const currentRowGroupIsGroup2 = selectedGroup2?.field === groupOption?.field;

  if (currentRowGroupIsGroup2) {
    return checkIfShouldShowGroupLevel2ForEstimateByCapacity(selectedGroup1, selectedGroup2, groupOption);
  }
  const isGroupingByTimeframeOnFirstGroup = checkFirstGroupIsTimeframeL1OrL2(selectedGroup1);

  return isGroupingByTimeframeOnFirstGroup;
};

/**
 * Check if row group matches second group by and if is valid to have capacity
 *
 *
 *
 * @param {Object} selectedGroup1 - The selected group option for group 1.
 * @param {Object} selectedGroup2 - The selected group option for group 2.
 * @param {Object} groupOption - current group object of the row
 * @returns {boolean} - The result of the check.
 */
const checkIfShouldShowGroupLevel2ForEstimateByCapacity = (selectedGroup1, selectedGroup2, groupOption) => {
  const validGroup1 = checkFirstGroupIsTimeframeL1OrL2(selectedGroup1);

  const validGroup2 = checkIsGroupValidForReleaseCapacity(groupOption);

  return validGroup1 && validGroup2;
};

/**
 * Extract team id from column definition
 *
 * @param {Object} colDef - The column definition.
 * @returns {number} - The team id.
 */
const extractTeamIdFromColumnDef = colDef => {
  const [teamId] = defaultAsEmptyString(colDef?.field).replace('estimate_', '').split('_');

  return parseInt(teamId);
};

/**
 * Resource team group has special behavior, we only want to show the
 * capacity value if the group is the same as the column team parent
 *
 * @param {Object} selectedGroup1 - The selected group option for group 1.
 * @param {Object} selectedGroup2 - The selected group option for group 2.
 * @param {Object} groupOption - The group option.
 * @param {Object} groupData - The group data.
 * @param {Object} colDef - The column definition.
 * @returns {boolean} - The result of the check.
 */
const checkCapacityCellIsNotOnSameResourceTeamGroupRow = (selectedGroup1, selectedGroup2, groupOption, groupData, colDef) => {
  const isEitherGroupResourceTeam = selectedGroup1?.key === TEAM || selectedGroup2?.key === TEAM;
  const isSelectedGroup2ResourceTeam = isEitherGroupResourceTeam && groupOption?.key === TEAM;
  const isCellTeamColumn = colDef?.field && colDef.field.includes('estimate_');
  const teamId = extractTeamIdFromColumnDef(colDef);

  const isNotSameTeam = teamId !== +groupData[TEAM]?.id;

  return isSelectedGroup2ResourceTeam && isCellTeamColumn && isNotSameTeam;
};

export {
  checkIsGroupValidForReleaseCapacity,
  checkFirstGroupIsTimeframeL1OrL2,
  checkIfShouldShowTimeframeCapacityOnGrid,
  checkIfShouldShowGroupLevel2ForEstimateByCapacity,
  checkCapacityCellIsNotOnSameResourceTeamGroupRow,
  extractTeamIdFromColumnDef,
};
