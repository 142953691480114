import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { BASE_ROW_HEIGHT } from 'constants/grid';

import { BaseGrid } from 'containers/Grids';

import { useCommonGridProps, useCommonGridEventHandlers } from 'design-system/molecules/AgGridReact-New/hooks';
import {
  sumNumericFields,
  sumScopeVarianceField,
  sumPointsVarianceField,
} from 'design-system/molecules/AgGridReact-New/aggFuncs';

import useLoginInfo from 'hooks/useLoginInfo';
import usePageFilters from 'hooks/filters/usePageFilters';
import useOrganizations from 'hooks/useOrganizations';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

import { ID_KEY, GRID_HEIGHT } from '../IdeasList/IdeasList/New/constants';
import { getDataPath, getRowId, checkRowSelectable } from '../IdeasList/IdeasList/New/helpers';
import { useMetadata, useProjectsOnGrids, useGridState, useSystemFields } from '../IdeasList/IdeasList/New/hooks';
import { genericStyles } from '../IdeasList/IdeasList/New/styled';
import useIdeasGridRowDrag from '../IdeasList/IdeasList/New/hooks/useIdeasGridRowDrag';

import useCycleDeliverableColumnsDefinitions from './hooks/useCycleDeliverableColumnsDefinitions';
import useCycleDeliverablesGrid from './hooks/useCycleDeliverablesGrid';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';
import { getProjectLayers } from 'store/filters/selectors';
import useAdditionalMetadataDropdownOptions from 'hooks/metadata/useAdditionalMetadataDropdownOptions';

const IdeasCycleDeliverableList = ({ viewType, data, onGridReady: parentHandleGridReady }) => {
  const { getSystemFieldName } = useSystemFields();

  const { hasIdeas } = useSelector(getProjectLayers);

  const { displayLayer, portfolioMode, searchString: pageFiltersSearchString } = usePageFilters();

  const { currentUser } = useLoginInfo();

  const {
    organization,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    filters: { topLayer },
    customUserFields,
    integrations,
    customFields,
  } = useOrganizations();

  const { isParentDragon } = useOrganizationsAccessControl();

  const { metadata, filteredMetadata, checkUserCanCreateNewMetadata, createMetadataMultiSelectOption } = useMetadata(data, {
    currentUser,
    organization,
  });

  const additionalMetadataOptions = useAdditionalMetadataDropdownOptions();

  const {
    hasUnsavedProject,
    defaultPhase,

    updateProjectById,
    createUnsavedProject,
    removeUnsavedProjects,
    createNewProject,
    openProjectInfo,
    openOkrInfo,
    loadParentProjects,
    switchProjectRowOrder,
  } = useProjectsOnGrids();

  const {
    rowHeight,
    expandedGroups,
    isGrouping,
    searchString,
    isBulkDeleting,
    visibleColumnsIds,
    columnsState,
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    shouldGridBeUnmounted,

    saveGridConfig,
    setSelectedItems,
    saveColumnsState,
    removeGridRow,
    setDirtyViewMutex,
  } = useGridState(viewType);

  const {
    processedData,

    getGridIsReady,
    getGridApi,

    handleGridReady,
    handleRowGroupOpened,
    handleSelectionChanged,
    handleDisplayedColumnsChanged,
    handleColumnResized,
    checkIsGroupOpenByDefault,
    addNewInlineProject,
    openRowInfo,
    updateProjectByIdOnCellValueChange,
    loadParentProjectsOptions,
    handleMetadataCellDoubleClick,
  } = useCycleDeliverablesGrid(data, {
    parentHandleGridReady,
    displayLayer,
    portfolioMode,
    currentUser,
    hasBet,
    defaultPhase,
    expandedGroups,
    columnsState,
    viewType,
    shouldGridBeUnmounted,

    saveGridConfig,
    getSystemFieldName,
    createUnsavedProject,
    openProjectInfo,
    openOkrInfo,
    setSelectedItems,
    updateProjectById,
    loadParentProjects,
    saveColumnsState,
  });

  const { columnDefs, defaultColDef, groupColumnDef } = useCycleDeliverableColumnsDefinitions({
    portfolioMode,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    topLayer,
    displayLayer,
    rowHeight,
    metadata,
    filteredMetadata,
    createMetadataMultiSelectOption,
    visibleColumnsIds,
    titleSearchString: searchString || pageFiltersSearchString,
    customUserFields,
    columnsState,
    integrations,
    currentUser,
    customFields,
    organization,
    isBulkDeleting,

    getSystemFieldName,
    addNewInlineProject,
    openProjectInfo,
    openRowInfo,
    loadParentProjectsOptions,
    checkUserCanCreateNewMetadata,
    updateProjectById,
    handleMetadataCellDoubleClick,
    removeGridRow,
    hasIdeas,
  });

  const commonGridProps = useCommonGridProps({
    isTreeView: true,
    aggFuncs: { sumNumericFields, sumScopeVarianceField, sumPointsVarianceField },
  });

  const { handleRowDrag } = useIdeasGridRowDrag({
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    switchProjectRowOrder,
    updateProjectById,
  });

  const commonGridEvents = useCommonGridEventHandlers({
    items: processedData,
    idKey: ID_KEY,
    actions: {
      update: updateProjectByIdOnCellValueChange,
      save: createNewProject,
      remove: removeUnsavedProjects,
      drag: handleRowDrag,
      sortChanged: handleDisplayedColumnsChanged,
    },
  });

  const gridContext = useMemo(
    () => ({
      hasUnsavedProject,
      isParentDragon,
      portfolioMode,
      isGrouping,
      isBulkDeleting,
      processedData,
      parents: metadata.parents,
      metadata,
      filteredMetadata,
      additionalMetadataOptions,
    }),
    [
      hasUnsavedProject,
      isParentDragon,
      portfolioMode,
      isGrouping,
      isBulkDeleting,
      processedData,
      metadata,
      filteredMetadata,
      additionalMetadataOptions,
    ],
  );

  useEffect(() => {
    if (getGridIsReady()) {
      getGridApi().resetRowHeights();
    }
  }, [rowHeight]);

  useEffect(() => {
    // We need to refresh Header in order to see the checkbox when bulk updating
    if (getGridIsReady()) {
      getGridApi().refreshHeader();
    }

    // Cleanup selected items if stopped bulk deleting.
    if (!isBulkDeleting && getGridIsReady()) {
      getGridApi().deselectAll();
    }
  }, [isBulkDeleting]);

  if (shouldGridBeUnmounted) {
    return <PageLoading />;
  }

  return (
    <BaseGrid
      // Common props:
      {...commonGridProps}
      {...commonGridEvents}
      // General props:
      onGridReady={handleGridReady}
      onFirstDataRendered={setDirtyViewMutex}
      rowData={processedData}
      context={gridContext}
      isGroupOpenByDefault={checkIsGroupOpenByDefault}
      isRowSelectable={checkRowSelectable}
      rowSelection="multiple"
      suppressRowClickSelection
      // Getters/Accessors:
      getRowId={getRowId}
      getDataPath={getDataPath}
      // Styling:
      cssStyles={genericStyles}
      height={GRID_HEIGHT}
      rowHeight={rowHeight}
      headerHeight={BASE_ROW_HEIGHT}
      // Columns:
      defaultColDef={defaultColDef}
      autoGroupColumnDef={groupColumnDef}
      columnDefs={columnDefs}
      // Events:
      onRowGroupOpened={handleRowGroupOpened}
      onSelectionChanged={handleSelectionChanged}
      onDisplayedColumnsChanged={handleDisplayedColumnsChanged}
      onColumnResized={handleColumnResized}
    />
  );
};

export default IdeasCycleDeliverableList;
