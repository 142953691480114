import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import PieChartIcon from '@material-ui/icons/PieChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import SummaryWidgetsIcon from '@material-ui/icons/FormatListBulleted';
import { useDispatch } from 'react-redux';

import StackedChartIcon from 'design-system/atoms/DragonIcons/StackedChart';
import useDashboardInformation from '../../hooks/useDashboardInformation';
import { addNewChartWidgetToCurrentDashboard, parseConfigurableChartDataToSave } from '../helpers';
import { DELIVERY_LINKED_ISSUES_SUMMARY, DELIVERY_PROGRESS_SUMMARY } from '../../helpers/templates';
import { defaultTo, isNil, prop } from 'ramda';
import { updateActiveDashboard } from 'store/dashboards';

const RotatedBarChartIcon = styled(BarChartIcon)`
  transform: rotate(90deg);
`;

const RotatedStackedChartIcon = styled(StackedChartIcon)`
  transform: rotate(90deg);
`;

export const SELECT_CHART_SCREEN = 'SELECT_CHART_SCREEN';
export const CHART_CONFIG_SCREEN = 'CHART_CONFIG_SCREEN';
export const SUMMARY_CHARTS_SCREEN = 'SUMMARY_CHARTS_SCREEN';

export const PIE_CHART = 'PIE_CHART';
export const BAR_CHART = 'BAR_CHART';
export const STACKED_CHART = 'STACKED_CHART';
export const EXISTING_CHART = 'EXISTING_CHART';

export const CHARTS = [
  {
    id: 'pie-chart',
    label: 'Pie Chart',
    icon: <PieChartIcon />,
    type: PIE_CHART,
    chartType: 'pie',
  },
  {
    id: 'vertical-bar-chart',
    label: 'Vertical Bar Chart',
    icon: <BarChartIcon />,
    type: BAR_CHART,
    chartType: 'bar',
  },
  {
    id: 'horizontal-bar-chart',
    label: 'Horizontal Bar Chart',
    icon: <RotatedBarChartIcon />,
    type: BAR_CHART,
    horizontal: true,
    chartType: 'bar',
  },
  {
    id: 'vertical-stacked-chart',
    label: 'Vertical Stacked Chart',
    icon: <StackedChartIcon />,
    type: STACKED_CHART,
    chartType: 'bar',
  },
  {
    id: 'horizontal-stacked-chart',
    label: 'Horizontal Stacked Chart',
    icon: <RotatedStackedChartIcon />,
    type: STACKED_CHART,
    horizontal: true,
    chartType: 'bar',
  },
  {
    id: 'summary-widgets',
    label: 'Summary Widgets',
    icon: <SummaryWidgetsIcon />,
    type: EXISTING_CHART,
    screen: SUMMARY_CHARTS_SCREEN,
  },
];

const defaultToEmptyObject = defaultTo({});

const filterSeletectedSummaryWidgets = selectedChartOptions => {
  const selectedOptions = defaultToEmptyObject(selectedChartOptions);

  return [
    {
      widget: DELIVERY_PROGRESS_SUMMARY,
      selected: Boolean(selectedOptions[DELIVERY_PROGRESS_SUMMARY.id]),
    },
    {
      widget: DELIVERY_LINKED_ISSUES_SUMMARY,
      selected: Boolean(selectedOptions[DELIVERY_LINKED_ISSUES_SUMMARY.id]),
    },
  ]
    .filter(state => state.selected)
    .map(prop('widget'));
};

const canSaveWidget = (currentScreen, selectedChartOptions) => {
  const selectedOptions = defaultToEmptyObject(selectedChartOptions);

  if (currentScreen === SUMMARY_CHARTS_SCREEN) {
    return Boolean(selectedOptions[DELIVERY_PROGRESS_SUMMARY.id] || selectedOptions[DELIVERY_LINKED_ISSUES_SUMMARY.id]);
  }
  if (currentScreen === CHART_CONFIG_SCREEN) {
    return !isNil(selectedOptions.groupBy);
  }
  return false;
};

const useChartWidgetWizard = ({ dashboardId }) => {
  const dispatch = useDispatch();
  const [selectedChart, setSelectedChart] = useState(null);
  const [currentScreen, setCurrentScreen] = useState(SELECT_CHART_SCREEN);
  const [chartOptions, setChartOptions] = useState({ groupBy: [], stackBy: [] });
  const [selectedChartOptions, setSelectedChartOptions] = useState({});

  const title = currentScreen === SUMMARY_CHARTS_SCREEN ? 'Select Widgets' : 'Select a Widget';

  const handleSelectChart = chart => {
    let nextScreen = CHART_CONFIG_SCREEN;

    if (chart?.type === EXISTING_CHART) {
      nextScreen = chart.screen;
    }

    setCurrentScreen(nextScreen);
    setSelectedChart(chart);
  };

  const handleSelectChartOption = (option, selected) =>
    setSelectedChartOptions(prevOptions => ({ ...prevOptions, [option]: selected }));

  const handleUpdateDashboardState = useCallback(dashboardState => {
    dispatch(updateActiveDashboard(dashboardState));
  }, []);

  const goToPreviousScreen = () => setCurrentScreen(SELECT_CHART_SCREEN);

  const match = {
    params: { dashboardId },
  };

  const { activeDashboardView } = useDashboardInformation(match);

  const { dashboard } = activeDashboardView?.state || {};

  const saveWidget = useCallback(() => {
    if (currentScreen === SUMMARY_CHARTS_SCREEN) {
      const selectedWidgets = filterSeletectedSummaryWidgets(selectedChartOptions);

      if (selectedWidgets.length > 0) {
        const dashboardStateWithNewWidget = addNewChartWidgetToCurrentDashboard(dashboard, selectedWidgets);

        handleUpdateDashboardState(dashboardStateWithNewWidget);
      }

      return;
    }

    const isStackedChart = selectedChart?.type === STACKED_CHART;
    const configurableChart = parseConfigurableChartDataToSave({ selectedChart, selectedChartOptions, isStackedChart });

    const dashboardStateWithNewWidget = addNewChartWidgetToCurrentDashboard(dashboard, configurableChart);

    handleUpdateDashboardState(dashboardStateWithNewWidget);
  }, [handleUpdateDashboardState, dashboard, selectedChartOptions, selectedChart, currentScreen]);

  const fetchChartOptions = useCallback(() => {
    const alreadyHasData = chartOptions.groupBy.length > 0 && chartOptions.stackBy.length > 0;

    if (alreadyHasData) {
      return;
    }

    axios.get('/api/v1/reports/data/allowed-fields').then(res => {
      const { data } = res;

      const options = {
        groupBy: data?.groupBys || [],
        stackBy: data?.stackBys || [],
      };

      setChartOptions(options);
      setSelectedChartOptions({ groupBy: data?.groupBys[0], stackBy: data?.stackBys[0] });
    });
  }, [chartOptions]);

  const resetWizard = (defaultData = {}) => {
    const initialSelectedChartOptions = { groupBy: chartOptions.groupBy[0], stackBy: chartOptions.stackBy[0] };

    setCurrentScreen(defaultData.currentScreen || SELECT_CHART_SCREEN);
    setSelectedChart(defaultData.selectedChart);
    setSelectedChartOptions(defaultData.selectedChartOptions || initialSelectedChartOptions);
  };

  return {
    title,
    dashboard,
    charts: CHARTS,
    selectedChart,
    currentScreen,
    chartOptions,
    selectedChartOptions,
    canSaveWidget: canSaveWidget(currentScreen, selectedChartOptions),

    handleSelectChart,
    goToPreviousScreen,
    handleUpdateDashboardState,
    handleSelectChartOption,
    saveWidget,
    resetWizard,
    fetchChartOptions,
  };
};

export default useChartWidgetWizard;
