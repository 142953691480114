import moment from 'moment-timezone';

import { BET_LAYER } from 'store/projects/constants';
import { isGroupFieldADate } from 'utils/grouping';

const GROUP_1 = 0;
const GROUP_2 = 1;
const GROUP_3 = 2;

const _getProjectGroupField = (project, groupLevel, groupDataObjects) => {
  const groupLevelField = groupLevel?.field;
  const customFields = project.custom_fields || {};

  const isCustomField = groupLevelField?.startsWith('custom_fields.');
  const getCustomFieldValue = () => {
    const projectGroupValue = customFields[groupLevelField.split('.')[1]];
    const isValidCFOption = groupDataObjects.map(object => object.id).includes(projectGroupValue);

    return isValidCFOption ? projectGroupValue : null;
  };

  if (isCustomField) return getCustomFieldValue(project);

  return project[groupLevelField];
};

/**
 * Some projects may have multiple values for a given field, like customers and tags.
 * For that reason with this method we always return an array of values for a given field to be further processed
 * @param  {Object} project
 * @param  {String} groupLevel
 * @param  {Array} groupOptions
 * @param  {Array} groupDataObjects
 * @return {Array}
 */
const _getProjectFieldValueAsArray = (project, groupLevel, groupOptions, groupDataObjects) => {
  if (!groupOptions?.[groupLevel]?.field) return [];

  const _getGroupFieldValue = value => getGroupFieldValue(value, project, groupOptions, groupLevel, groupDataObjects);

  const projectGroupLevelField = _getProjectGroupField(project, groupOptions?.[groupLevel], groupDataObjects);

  let array = Array.isArray(projectGroupLevelField)
    ? projectGroupLevelField.map(_getGroupFieldValue)
    : [_getGroupFieldValue(projectGroupLevelField) || null];

  if (!array.length) array = [null];

  return array;
};

/**
 * Builds a factory that returns an iterator that can be used to iterate over the values of the grouped fields for a given level
 * eg: for
 * project = {group_1_field: [1,2], group_2_field: [3,4]}
 * calling the iterator with level = 1 with call
 * f(1,3), f(1,4), f(2,3), f(2,4)
 * @param  {Object} project
 * @param  {Array} groupOptions
 * @param  {Array} groupsDataObjects
 */
const _makeProjectGroupValuesIterator = (project, groupOptions, groupsDataObjects) => {
  const projectGroup1Values = _getProjectFieldValueAsArray(project, GROUP_1, groupOptions, groupsDataObjects[GROUP_1]);
  const projectGroup2Values = _getProjectFieldValueAsArray(project, GROUP_2, groupOptions, groupsDataObjects[GROUP_2]);
  const projectGroup3Values = _getProjectFieldValueAsArray(project, GROUP_3, groupOptions, groupsDataObjects[GROUP_3]);

  const runForEachGroup1Value = f => {
    projectGroup1Values.forEach(f);
  };

  const runForEachGroup2Value = f => {
    runForEachGroup1Value(v0 => {
      projectGroup2Values.forEach(v1 => f(v0, v1));
    });
  };

  const runForEachGroup3Value = f => {
    runForEachGroup2Value((v0, v1) => {
      projectGroup3Values.forEach(v2 => f(v0, v1, v2));
    });
  };

  return (level, f) => {
    switch (level) {
      case GROUP_1:
        return runForEachGroup1Value(f);
      case GROUP_2:
        return runForEachGroup2Value(f);
      case GROUP_3:
        return runForEachGroup3Value(f);
      default:
        return runForEachGroup1Value(f);
    }
  };
};

/**
 * Given a level of grouping returns if it is the last level of grouping
 * @param  {integer} level
 * @param  {Array} groupsDataObjects
 */
const isLastLevelOfGrouping = (level, [_, group2DataObjects, group3DataObjects]) => {
  switch (level) {
    case GROUP_1:
      return !group2DataObjects?.length;
    case GROUP_2:
      return !group3DataObjects?.length;
    case GROUP_3:
      return true;
    default:
      return !group2DataObjects?.length;
  }
};

const _addGroup1IdToDataStructureIfDoesntExist = (projectsByGroupId, iterator, groupsDataObjects) => {
  iterator(GROUP_1, v => {
    if (!projectsByGroupId[v]) projectsByGroupId[v] = isLastLevelOfGrouping(GROUP_1, groupsDataObjects) ? [] : {};
  });
};

const _addGroup2IdToDataStructureIfDoesntExist = (projectsByGroupId, iterator, groupsDataObjects) => {
  iterator(GROUP_2, (idGroup1, idGroup2) => {
    if (!projectsByGroupId[idGroup1][idGroup2])
      projectsByGroupId[idGroup1][idGroup2] = isLastLevelOfGrouping(GROUP_2, groupsDataObjects) ? [] : {};
  });
};

const _addGroup3IdToDataStructureIfDoesntExist = (projectsByGroupId, iterator) => {
  iterator(GROUP_3, (idGroup1, idGroup2, idGroup3) => {
    if (!projectsByGroupId[idGroup1][idGroup2][idGroup3]) projectsByGroupId[idGroup1][idGroup2][idGroup3] = [];
  });
};

const _getTopParentProject = (project, parents) => {
  const parent = parents[project.parent_id];

  return !parent || project?.layer === BET_LAYER ? project : _getTopParentProject(parent, parents);
};

/**
 * Builds an auxiliary data structure for grouping projects
 * @param  {Array} projectsForGrouping - an array of projects
 * @param  {Object} parentsById - top parents of those projects normalized by id
 * @param  {Array[3]} [groupDataObjects] - an array of objects for group1, 2, 3
 * @param  {Array} groupOptions - an array of objects with the fields to group by
 * @param  {bool} withHierarchy - if true, the data structure will include the hierarchy of the projects
 * @returns {Object} - An objects with the following structure:
 * {
 *  <group1Id1>: {
 *   <group2Id1>: {
 *    <group3Id1>: [<project1>, <project2>, ...],
 *   },
 *  },
 *  <group2Id1>: ...
 */
const buildGroupingHelperDataStructure = (
  projectsForGrouping,
  parentsById,
  [group1DataObjects, group2DataObjects, group3DataObjects],
  groupOptions,
  withHierarchy,
) => {
  const groupedIds = {};

  projectsForGrouping.forEach(p => {
    const refProject = withHierarchy ? _getTopParentProject(p, parentsById) : p;

    const iterator = _makeProjectGroupValuesIterator(refProject, groupOptions, [
      group1DataObjects,
      group2DataObjects,
      group3DataObjects,
    ]);

    if (group1DataObjects.length) {
      _addGroup1IdToDataStructureIfDoesntExist(groupedIds, iterator, [group1DataObjects, group2DataObjects, group3DataObjects]);
    } else {
      return;
    }

    if (group2DataObjects.length) {
      _addGroup2IdToDataStructureIfDoesntExist(groupedIds, iterator, [group1DataObjects, group2DataObjects, group3DataObjects]);
    } else {
      iterator(GROUP_1, v => {
        groupedIds[v].push(p);
      });
      return;
    }

    if (group3DataObjects.length) {
      _addGroup3IdToDataStructureIfDoesntExist(
        groupedIds,
        iterator,
        [group1DataObjects, group2DataObjects, group3DataObjects],
        p,
      );
    } else {
      iterator(GROUP_2, (v0, v1) => {
        groupedIds[v0][v1].push(p);
      });
      return;
    }

    iterator(GROUP_3, (v0, v1, v2) => {
      groupedIds[v0][v1][v2].push(p);
    });
  });

  return groupedIds;
};

const _matchObjectsOrIds = (g, v) => [g, g?.id].some(_g => [v, v?.id].includes(_g));

const getGroupFieldValue = (v, project, groupOptions, groupLevel, groupDataObjects) => {
  if (isGroupFieldADate(groupOptions?.[groupLevel])) {
    const groupObject = groupDataObjects.find(groupObj => {
      const momentFieldValue = moment.isMoment(v) ? v : moment(v);
      const groupStart = moment.isMoment(groupObj.start) ? groupObj.start : moment(groupObj.start);
      const groupEnd = moment.isMoment(groupObj.end) ? groupObj.end : moment(groupObj.end);

      return groupStart.isValid() && groupEnd.isValid() && momentFieldValue > groupObj.start && momentFieldValue < groupObj.end;
    });

    if (groupObject) return groupObject.id;
  } else if (groupDataObjects.find(g => _matchObjectsOrIds(g, v))) {
    return v?.id || v;
  }

  return null;
};

export default buildGroupingHelperDataStructure;
