import axios from 'axios';

import throwRequestError from 'store/utils/throwRequestError';
import { createThunk } from 'utils/store/thunk';
import {
  FETCH_MISSION_CONTROL_REQUESTS,
  FETCH_MISSION_CONTROL_OKRS,
  FETCH_MISSION_CONTROL_METRICS,
  REFRESH_MISSION_CONTROL_OKRS,
  REFRESH_MISSION_CONTROL_METRICS,
  FETCH_MISSION_CONTROL_CREATED_REQUESTS,
} from './types';

const fetchMissionControlRequests = () =>
  createThunk(FETCH_MISSION_CONTROL_REQUESTS, axios.get('/api/v1/mission-control/my-open-requests').catch(throwRequestError));

const fetchMissionControlCreatedRequests = () =>
  createThunk(
    FETCH_MISSION_CONTROL_CREATED_REQUESTS,
    axios.get('/api/v1/mission-control/my-created-requests').catch(throwRequestError),
  );

const fetchMissionControlOkrs = () =>
  createThunk(FETCH_MISSION_CONTROL_OKRS, axios.get('/api/v1/mission-control/my-objectives').catch(throwRequestError));

const fetchMissionControlMetrics = () =>
  createThunk(FETCH_MISSION_CONTROL_METRICS, axios.get('/api/v1/mission-control/my-metrics').catch(throwRequestError));

const refreshMissionControlOkrs = () =>
  createThunk(REFRESH_MISSION_CONTROL_OKRS, axios.get('/api/v1/mission-control/my-objectives').catch(throwRequestError));

const refreshMissionControlMetrics = () =>
  createThunk(REFRESH_MISSION_CONTROL_METRICS, axios.get('/api/v1/mission-control/my-metrics').catch(throwRequestError));

export {
  fetchMissionControlRequests,
  fetchMissionControlOkrs,
  fetchMissionControlMetrics,
  refreshMissionControlOkrs,
  refreshMissionControlMetrics,
  fetchMissionControlCreatedRequests,
};
