import { getUserName } from 'utils';

/**
 * Gets a flat list of subroadmaps and products processed to AG grid based on
 * a nested list of subroadmaps and products of each subroadmap.
 *
 * @param {Object} roadmap
 * @param {Array} parentPath
 * @param {Object} configs
 * @param {Boolean} configs.hasProducts
 * @param {Boolean} configs.hasProducts2
 * @param {Boolean} configs.selectionMode
 * @param {Number} configs.OBJECT_PRODUCT
 * @returns {Array}
 */
const getProcessedProductsList = (roadmap, parentPath = [], configs = {}) => {
  const { hasProducts, hasProducts2, OBJECT_PRODUCT } = configs;

  if (hasProducts && roadmap.products) {
    return roadmap.products.reduce((previousState, subroadmap) => {
      const processedSubroadmap = {
        ...subroadmap,
        title: subroadmap.title,
        ownerName: getUserName(subroadmap.owner),
        updatedBy: getUserName(subroadmap.updatedBy),
        type: OBJECT_PRODUCT,
        path: [...parentPath, subroadmap.title],
        uniqueId: `PRODUCT${subroadmap.id ? subroadmap.id : '-PENDING'}`,
        parent_id: subroadmap.parent_id,
        level: subroadmap.level,
      };

      let processedSubroadmapProducts = [];

      if (hasProducts2 && subroadmap.products) {
        processedSubroadmapProducts = subroadmap.products.map(product => ({
          ...product,
          title: product.title,
          ownerName: getUserName(product.owner),
          updatedBy: getUserName(product.updatedBy),
          type: OBJECT_PRODUCT,
          path: [...parentPath, subroadmap.title, product.title],
          uniqueId: `PRODUCT${product.id ? product.id : '-PENDING'}`,
          parent_id: product.parent_id,
          level: product.level,
        }));
      }

      return [...previousState, processedSubroadmap, ...processedSubroadmapProducts];
    }, []);
  }

  return [];
};

export default getProcessedProductsList;
