import { not, pipe, prop, propEq } from 'ramda';
import sumBy from 'lodash/sumBy';

import { calcScopeVariance } from 'store/projects/helpers/getProjectAllocationValues';

const DISPLAY_LAYER = 'displayLayer';
const GROUP = 'group';
const LAYER = 'layer';
const PLANNED_ALLOCATION_IN_WEEKS = 'plannedAllocation';
const REPORTED_ALLOCATION_IN_WEEKS = 'reportedAllocation';

const isGroup = propEq(GROUP, true);
const isNotGroup = pipe(isGroup, not);

const getDisplayLayer = prop(DISPLAY_LAYER);
const getLayer = prop(LAYER);
const getPlannedAllocation = prop(PLANNED_ALLOCATION_IN_WEEKS);
const getReportedAllocation = prop(REPORTED_ALLOCATION_IN_WEEKS);

/**
 * AG-Grid Multi column aggregation docs
 *  - https://www.ag-grid.com/angular-data-grid/aggregation-custom-functions/#multi-column-aggregation
 */
const sumScopeVarianceField = params => {
  const {
    data,
    values,
    colDef: { field, cellRendererParams },
  } = params;

  if (data && isNotGroup(data)) {
    const isSameLayer = getLayer(data) === getDisplayLayer(cellRendererParams);

    return {
      plannedAllocation: isSameLayer ? getPlannedAllocation(data) : 0,
      reportedAllocation: isSameLayer ? getReportedAllocation(data) : 0,
      toString: () => data[field],
    };
  }

  const totalPlannedAllocation = sumBy(values, PLANNED_ALLOCATION_IN_WEEKS);
  const totalReportedAllocation = sumBy(values, REPORTED_ALLOCATION_IN_WEEKS);

  return {
    plannedAllocation: totalPlannedAllocation,
    reportedAllocation: totalReportedAllocation,
    toString: () => calcScopeVariance(totalPlannedAllocation, totalReportedAllocation),
  };
};

export default sumScopeVarianceField;
