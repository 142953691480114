import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CloseButton from 'components/DialogTitle/CloseButton';
import { projectActions } from 'store/projects';
import { getProjectEndDate } from 'utils';
import ProjectLink from 'design-system/atoms/ProjectLink/index';
import Checkbox from 'design-system/atoms/Checkbox/index';

import getSystemFieldName from 'utils/getSystemFieldName';
import { getAllIdeas } from 'store/projects/selectors';
import { openProjectLightbox } from 'store/projectLightbox';
import formatDate from 'utils/dates/formatDate';

const lsKey = 'outdatedAlert';

class OutdatedAlert extends Component {
  state = {
    isOpen: false,
    outdatedProjects: [],
    projectsList: {},
  };

  componentWillReceiveProps(nextProps) {
    const { projects, currentUser } = nextProps;

    if (!localStorage.getItem(lsKey)) {
      localStorage.setItem(lsKey, moment());
    }

    const outdatedTime = moment(localStorage.getItem(lsKey));
    const shouldBeWarned = moment().isAfter(outdatedTime);
    const outdatedProjects = projects.length
      ? projects.filter(
          p =>
            ['Confirmed'].includes(p.planningStage) &&
            currentUser &&
            currentUser.id === p.owner_id &&
            moment().isAfter(moment(getProjectEndDate(p))),
        )
      : [];

    if (outdatedProjects.length && shouldBeWarned) {
      this.setState({
        outdatedProjects,
        isOpen: true,
        projectsList: outdatedProjects.reduce((acc, cur) => {
          acc[cur.id] = true;
          return acc;
        }, {}),
      });
    }
  }

  getCheckedProjs = () => {
    return this.state.outdatedProjects.filter(p => !!this.state.projectsList[p.id]);
  };

  onArchive = () => {
    this.props.updateProjects(
      this.getCheckedProjs().map(p => ({
        id: p.id,
        planningStage: 'Archived',
      })),
    );
    this.setState({ isOpen: false });
  };

  onComplete = () => {
    this.props.updateProjects(
      this.getCheckedProjs().map(p => ({
        id: p.id,
        planningStage: 'Completed',
      })),
    );
    this.setState({ isOpen: false });
  };

  areAllChecked = () => {
    return Object.keys(this.state.projectsList).every(key => !!this.state.projectsList[key]);
  };

  areAnyChecked = () => {
    return Object.keys(this.state.projectsList).some(key => !!this.state.projectsList[key]);
  };

  toggleAll = ({ target: { checked } }) => {
    this.setState({
      projectsList: Object.keys(this.state.projectsList).reduce((acc, cur) => {
        acc[cur] = checked;
        return acc;
      }, {}),
    });
  };

  toggleIndividual = p => {
    const { projectsList } = this.state;

    this.setState({ projectsList: { ...projectsList, ...{ [p.id]: !projectsList[p.id] } } });
  };

  closeDialog = () => {
    this.setState({ isOpen: false });
    localStorage.setItem(lsKey, moment().add(1, 'day'));
  };

  openLightbox = (event, project) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.openProjectLightbox(project.id);
  };

  render() {
    if (!this.state.outdatedProjects.length) return null;

    const { projectsList } = this.state;
    const allChecked = this.areAllChecked();
    const anyChecked = this.areAnyChecked();

    return (
      <div>
        <Dialog open={this.state.isOpen} maxWidth="md" fullWidth>
          <CloseButton onClick={this.closeDialog} />
          <DialogContent>
            <Typography component="p" paragraph style={{ marginTop: 35, fontSize: '1rem' }}>
              The following ideas have completion date before today.
            </Typography>
            <Typography component="p" paragraph style={{ fontSize: '1rem' }}>
              You may bulk update to Complete or Archived (completed ideas will be in reports), or update timeline on the &nbsp;
              <Link to="/manage/timeline/">Manage Timeline page</Link>
            </Typography>
            <TableWrapper>
              <TableHead>
                <TableRow>
                  <TableHeader padding="none">
                    <Checkbox onChange={this.toggleAll} checked={allChecked} color="primary" />
                  </TableHeader>
                  <TableHeader padding="dense">{getSystemFieldName('roadmap', this.props.systemFields)}</TableHeader>
                  <TableHeader padding="dense">Title</TableHeader>
                  <TableHeader padding="dense">Owner</TableHeader>
                  <TableHeader padding="dense">{getSystemFieldName('timeframe', this.props.systemFields)}</TableHeader>
                  <TableHeader padding="dense">Complete Date</TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.outdatedProjects.map(p => (
                  <TableRow key={p.id}>
                    <TableCell padding="none">
                      <Checkbox onChange={e => this.toggleIndividual(p)} checked={!!projectsList[p.id]} color="primary" />
                    </TableCell>
                    <TableCell padding="dense">{p.roadmapTitle}</TableCell>
                    <TableCell padding="dense">
                      <ProjectLink onClick={e => this.openLightbox(e, p)} style={{ cursor: 'pointer' }}>
                        DB-{p.id}
                      </ProjectLink>
                      &nbsp;{p.title}
                    </TableCell>
                    <TableCell padding="dense">{p.ownerName}</TableCell>
                    <TableCell padding="dense">{p.timeframeTitle}</TableCell>
                    <TableCell padding="dense">{formatDate(getProjectEndDate(p))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableWrapper>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={3}>
                <Button onClick={this.onArchive} color="secondary" disabled={!anyChecked}>
                  Archive
                </Button>
              </Grid>
              <Grid item xs={9}>
                <div style={{ justifyContent: 'flex-end', textAlign: 'right' }}>
                  <Button onClick={this.onComplete} color="primary" disabled={!anyChecked}>
                    Mark as Complete
                  </Button>
                </div>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const TableWrapper = styled(Table)`
  &&&& {
  }
`;

const TableHeader = styled(TableCell)`
  &&&& {
    color: rgba(0, 0, 0, 0.66);
    font-size: 0.9rem;
  }
`;

const mapStateToProps = state => {
  const {
    login,
    organization: { organization },
  } = state;

  return {
    projects: getAllIdeas(state),
    currentUser: login.currentUser,
    systemFields: organization.system_fields_name,
  };
};

export default connect(mapStateToProps, { ...projectActions, openProjectLightbox })(OutdatedAlert);
