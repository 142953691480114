export const FETCH_VOTES_PER_PROJECT = 'FETCH_VOTES_PER_PROJECT';
export const FETCH_VOTES_PER_PROJECT_FULFILLED = 'FETCH_VOTES_PER_PROJECT_FULFILLED';
export const FETCH_VOTES_PER_PROJECT_PENDING = 'FETCH_VOTES_PER_PROJECT_PENDING';
export const FETCH_VOTES_PER_PROJECT_REJECTED = 'FETCH_VOTES_PER_PROJECT_REJECTED';

export const VOTE_ON_PROJECT = 'VOTE_ON_PROJECT';
export const VOTE_ON_PROJECT_FULFILLED = 'VOTE_ON_PROJECT_FULFILLED';
export const VOTE_ON_PROJECT_PENDING = 'VOTE_ON_PROJECT_PENDING';
export const VOTE_ON_PROJECT_REJECTED = 'VOTE_ON_PROJECT_REJECTED';

export const UNVOTE_ON_PROJECT = 'UNVOTE_ON_PROJECT';
export const UNVOTE_ON_PROJECT_FULFILLED = 'UNVOTE_ON_PROJECT_FULFILLED';
export const UNVOTE_ON_PROJECT_PENDING = 'UNVOTE_ON_PROJECT_PENDING';
export const UNVOTE_ON_PROJECT_REJECTED = 'UNVOTE_ON_PROJECT_REJECTED';

export const FETCH_VOTES_PER_CUSTOMER_REQUEST = 'FETCH_VOTES_PER_CUSTOMER_REQUEST';
export const FETCH_VOTES_PER_CUSTOMER_REQUEST_FULFILLED = 'FETCH_VOTES_PER_CUSTOMER_REQUEST_FULFILLED';
export const FETCH_VOTES_PER_CUSTOMER_REQUEST_PENDING = 'FETCH_VOTES_PER_CUSTOMER_REQUEST_PENDING';
export const FETCH_VOTES_PER_CUSTOMER_REQUEST_REJECTED = 'FETCH_VOTES_PER_CUSTOMER_REQUEST_REJECTED';

export const VOTE_ON_CUSTOMER_REQUEST = 'VOTE_ON_CUSTOMER_REQUEST';
export const VOTE_ON_CUSTOMER_REQUEST_FULFILLED = 'VOTE_ON_CUSTOMER_REQUEST_FULFILLED';
export const VOTE_ON_CUSTOMER_REQUEST_PENDING = 'VOTE_ON_CUSTOMER_REQUEST_PENDING';
export const VOTE_ON_CUSTOMER_REQUEST_REJECTED = 'VOTE_ON_CUSTOMER_REQUEST_REJECTED';

export const UNVOTE_ON_CUSTOMER_REQUEST = 'UNVOTE_ON_CUSTOMER_REQUEST';
export const UNVOTE_ON_CUSTOMER_REQUEST_FULFILLED = 'UNVOTE_ON_CUSTOMER_REQUEST_FULFILLED';
export const UNVOTE_ON_CUSTOMER_REQUEST_PENDING = 'UNVOTE_ON_CUSTOMER_REQUEST_PENDING';
export const UNVOTE_ON_CUSTOMER_REQUEST_REJECTED = 'UNVOTE_ON_CUSTOMER_REQUEST_REJECTED';
