import React, { useRef } from 'react';
import { css } from 'styled-components';

import { BaseGrid } from 'containers/Grids';
import { BASE_ROW_HEIGHT } from 'constants/grid';
import { spacing } from 'design-system/theme';
import { getDataPath } from 'design-system/molecules/AgGridReact-New/helpers';
import { useCommonGridProps } from 'design-system/molecules/AgGridReact-New/hooks';

const DEFAULT_COLUMN_DEF = {
  sortable: true,
  resizable: false,
  filter: false,
};

const gridStyles = css`
  .ag-layout-normal.ag-root,
  .ag-layout-normal .ag-paging-row-summary-panel,
  .ag-layout-normal .ag-paging-page-summary-panel {
    margin: 0;
  }

  .ag-layout-normal .ag-header,
  .ag-row:last-child {
    border: none;
  }

  .ag-layout-normal .ag-paging-panel {
    height: unset;
    justify-content: space-between;
    padding: 0;
  }

  .ag-layout-normal .ag-cell p,
  .ag-layout-normal .ag-cell span {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  }

  .ag-layout-normal .ag-paging-panel {
    margin-top: ${spacing()}px;
  }
`;

const WidgetGrid = ({
  data,
  columnDefs,
  isTreeView,
  getRowId,
  setVisibleItems,
  gridHeight,
  hasPagination,
  pageSize,
  context,
}) => {
  const gridApi = useRef(null);

  const commonGridProps = useCommonGridProps({ isTreeView });

  const onGridReady = params => {
    gridApi.current = params.api;
  };

  const handleOnModelUpdated = () => {
    if (gridApi.current) setVisibleItems(gridApi.current.getDisplayedRowCount());
  };

  return (
    <BaseGrid
      {...commonGridProps}
      onGridReady={onGridReady}
      onModelUpdated={handleOnModelUpdated}
      rowData={data}
      getRowId={getRowId}
      getDataPath={getDataPath}
      defaultColDef={DEFAULT_COLUMN_DEF}
      columnDefs={columnDefs}
      headerHeight={BASE_ROW_HEIGHT}
      height={gridHeight}
      rowHeight={BASE_ROW_HEIGHT}
      cssStyles={gridStyles}
      paginationPageSize={hasPagination ? pageSize : undefined}
      suppressPaginationPanel={!hasPagination}
      pagination={hasPagination}
      groupDisplayType="custom"
      context={context}
    />
  );
};

export default WidgetGrid;
