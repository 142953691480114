import { PAGE_HEADER_HEIGHT } from 'constants/common';
import { SENTIMENT_OPTION_LABELS } from 'constants/sentiment';

export const TOOLBAR_HEIGHT = 50;
export const BASE_ROW_HEIGHT = 56;

export const PAGE_HEIGHT_OFFSET = PAGE_HEADER_HEIGHT + TOOLBAR_HEIGHT;
export const GRID_HEIGHT = `calc(100vh - ${PAGE_HEIGHT_OFFSET}px)`;

export const PERSONAS_SENTIMENT_COLORS_OPTIONS = [
  { label: SENTIMENT_OPTION_LABELS.Delighted, value: SENTIMENT_OPTION_LABELS.Delighted },
  { label: SENTIMENT_OPTION_LABELS.Neutral, value: SENTIMENT_OPTION_LABELS.Neutral },
  { label: SENTIMENT_OPTION_LABELS.Dissatisfied, value: SENTIMENT_OPTION_LABELS.Dissatisfied },
];

export const PERSONA_ROADMAP_FIELD = 'persona_roadmaps';
