import styled from 'styled-components';

export default styled.div`
  margin: 0 3px;

  ${props =>
    props.input &&
    `
    background: ${props.theme.palette.white};
  `}
  ${props =>
    props.autocomplete &&
    `
    input {
      font-size: ${props.theme.typography.fontSize}px;
      padding-left: 10px;
      padding-right: 32px;
    }
  `}
  ${props =>
    props.multiselect &&
    `
    max-width: 403px;
    min-width: 235px;
    div[aria-hidden="true"] {
      padding: 6px;
    }
  `}
  ${props =>
    props.text &&
    `
    > div {
      width: 100%;
      input {
        padding-left: 10px;
        font-size: 15px;
      }
    }
  `}
  ${props =>
    props.date &&
    `
    input {
      padding-left: 10px;
      font-size: ${props.theme.typography.fontSize}px;
    }
  `}
  ${props =>
    props.label &&
    `
    text-align: center;
  `}
`;
