import React from 'react';
import ReactDOMServer from 'react-dom/server';
import OpenInNew from '@material-ui/icons/OpenInNew';

import formatDate from 'utils/dates/formatDate';
import converTimeToString from 'utils/converTimeToString';

export default customFields => task => {
  // Should convert Date custom fields to a system specific date format
  customFields
    .filter(f => f.field_type === 'Date')
    .forEach(f => {
      if (task[f.key]) task[f.key] = formatDate(task[f.key]);
    });

  let view = '';

  if (['project', 'task', 'estimate'].includes(task.dbType)) {
    view = ReactDOMServer.renderToString(
      <div className="gantt__openLightbox">
        {' '}
        <OpenInNew />
      </div>,
    );
  }

  return {
    ...task,
    timeEstimated: converTimeToString(task.timeEstimated),
    view,
  };
};
