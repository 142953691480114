import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import {
  addPriorityWithoutSave,
  bulkDeletePriorityRoadmaps,
  createPriority,
  createPriorityRoadmap,
  deletePriority,
  deletePriorityRoadmap,
  fetchPriorities,
  mergePriorities,
  removeUnsavedPriorities,
  switchPrioritiesRowOrder,
  updatePriorityById,
} from 'store/priorities';
import { getPriorities } from 'store/priorities/selectors';

const usePriorities = hideArchivedItems => {
  const dispatch = useDispatch();

  const priorities = useSelector(state => getPriorities(state, !hideArchivedItems));

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          fetchPriorities,
          createPriority,
          addPriorityWithoutSave,
          removeUnsavedPriorities,
          updatePriorityById,
          deletePriority,
          mergePriorities,
          switchPrioritiesRowOrder,
        },
        dispatch,
      ),
    [dispatch],
  );

  return {
    priorities,
    createPriorityRoadmap,
    deletePriorityRoadmap,
    bulkDeletePriorityRoadmaps,
    ...boundActionCreators,
  };
};

export default usePriorities;
