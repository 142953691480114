import getSystemFieldName from 'utils/getSystemFieldName';

const generateGetSystemFieldName = field => systemFields => getSystemFieldName(field, systemFields);

const autocompleteFields = {
  categoryTitle: generateGetSystemFieldName('category'),
  themeTitle: generateGetSystemFieldName('theme'),
  timeframeTitle: generateGetSystemFieldName('timeframe'),
  timeframe2Title: generateGetSystemFieldName('timeframe2'),
  priorityTitle: generateGetSystemFieldName('priority'),
  phaseTitle: generateGetSystemFieldName('phase'),
};

export default autocompleteFields;
