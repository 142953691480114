import React from 'react';
import styled from 'styled-components';

import theme, { spacing } from 'design-system/theme';
import Loading from 'design-system/atoms/Loading';
import ButtonIcon from 'design-system/molecules/ButtonIcon';
import { INTEGRATIONS_NAMES, INTEGRATIONS_KEYS, INTEGRATIONS_ICON_MAPPER } from 'constants/integrations';

const ShareViaIntegrationButton = ({ integrationType, onClick, active, disabled, loading }) => {
  if (!integrationType || !INTEGRATIONS_KEYS[integrationType]) return null;

  const IntegrationIcon = INTEGRATIONS_ICON_MAPPER[integrationType];
  const label = `Share via ${INTEGRATIONS_NAMES[integrationType]}`;

  return (
    <StyledButtonIcon onClick={onClick} active={active} disabled={disabled || loading}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <IntegrationIcon size={`${theme.typography.fontSizeMediumSmallRem}rem`} marginRight={`${spacing()}px`} />
          {label}
        </>
      )}
    </StyledButtonIcon>
  );
};

export default ShareViaIntegrationButton;

const StyledButtonIcon = styled(ButtonIcon)`
  &&&& {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    color: ${({ active, theme }) => (active ? theme.palette.primary.main : theme.palette.newLayout.text.siroccoGrey)};
    border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;
    font-family: unset;
    padding: ${spacing()}px;
  }
`;
