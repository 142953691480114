import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ROADMAP_PAGE } from 'constants/filters';

import { getState, updateState as updateStateAction } from 'store/timelines';

import ToggleButton from 'design-system/molecules/ToggleButton/index';

import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';

const SHOW_ITEMS_WITHOUT_DATES = 'Show items without dates';
const HIDE_ITEMS_WITHOUT_DATES = 'Hide items without dates';

const ShowItemsWithoutDatesButton = ({ page = ROADMAP_PAGE }) => {
  const dispatch = useDispatch();
  const { showItemsWithoutTimeline } = useSelector(getState(page));

  const handleOnClick = useCallback(() => {
    return dispatch(updateStateAction(page, { showItemsWithoutTimeline: !showItemsWithoutTimeline }));
  }, [showItemsWithoutTimeline]);

  return (
    <ToggleButton
      on={!showItemsWithoutTimeline}
      icon={showItemsWithoutTimeline ? <VisibilityIcon /> : <VisibilityOffIcon />}
      title={showItemsWithoutTimeline ? HIDE_ITEMS_WITHOUT_DATES : SHOW_ITEMS_WITHOUT_DATES}
      onChange={() => handleOnClick()}
    />
  );
};

export default ShowItemsWithoutDatesButton;
