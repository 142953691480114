import { isNil, pipe, defaultTo, prop, sort } from 'ramda';
import moment from 'moment';

const isNewRow = pipe(defaultTo({}), prop('id'), isNil);
const userCreatedOnCurrentSession = (user, createdUsersIds) => createdUsersIds.includes(user?.id);

const sortByCreatedAt = sort((a, b) => moment(prop('created_at', b)) - moment(prop('created_at', a)));

export const filterUsers = ({ allUsers, filters, createdUsersIds = [] }) => {
  return filters.length
    ? allUsers.filter(user => {
        // for new row ignore filters
        if (isNewRow(user)) {
          return true;
        }

        if (userCreatedOnCurrentSession(user, createdUsersIds)) {
          return true;
        }

        // other rows apply filters
        return filters.every(filter => filter(user));
      })
    : allUsers;
};

export const sortUsersByDefault = users => {
  return sortByCreatedAt(users);
};
