import React from 'react';
import styled, { css } from 'styled-components';

import { spacing } from 'design-system/theme';
import Text from 'design-system/atoms/Text';
import InlineDateRange from 'design-system/organisms/InlineDateRange/InlineDateRange';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete/index';
import ShowFieldsIcon from 'design-system/atoms/ShowFieldsIcon/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';

const inlineDateRangeCss = css`
  width: 305px;
  transform: translateY(2px);
`;

export default ({
  title,
  groupByOptions,
  selectedGroupOption,
  updateSelectedGroupOption,
  earliestDate,
  latestDate,
  shouldDisplayDateRange,
  setTableShowFieldsEl,
}) => {
  return (
    <Container>
      <TooltipOnOverflow text={title}>
        <PageTitle variant="h2" withEllipsis>
          {title}
        </PageTitle>
      </TooltipOnOverflow>
      <ControlsContainer>
        <div>
          <GroupByAutocomplete
            name="selectedGroupBy"
            suggestions={groupByOptions}
            value={selectedGroupOption}
            onChange={updateSelectedGroupOption}
          />
        </div>
        <FlexContainer>
          <DateRangeContainer>
            {shouldDisplayDateRange ? (
              <InlineDateRange
                label="Time Period:"
                startDate={earliestDate}
                endDate={latestDate}
                wrapperCss={inlineDateRangeCss}
                disableEdit
              />
            ) : null}
          </DateRangeContainer>
          <ButtonIcon onClick={e => setTableShowFieldsEl(e.currentTarget)} data-testid="show-fields" small>
            <ShowFieldsIcon />
          </ButtonIcon>
        </FlexContainer>
      </ControlsContainer>
    </Container>
  );
};

const Container = styled.div``;

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${spacing(1.5)}px 0;
`;

const PageTitle = styled(Text)`
  margin: 0 ${spacing(4)}px;
  text-align: center;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DateRangeContainer = styled.div`
  justify-self: flex-end;
  pointer-events: none;
`;
