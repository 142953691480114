import { equals, isNil } from 'ramda';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

const CHECK_CLOSED_WINDOW_INTERVAL = 1000;
const ATTEMPTS_BEFORE_CLOSE = 60;

const shouldClosePopup = equals(ATTEMPTS_BEFORE_CLOSE);

const showAzureDevopsPopup = () => {
  return new Promise((resolve, reject) => {
    const tokenWindow = window.open(
      `${window.location.origin}/settings/integrations/${INTEGRATIONS_KEYS.azuredevops}`,
      'ADO Authentication',
      'height=700,width=1100',
    );

    let count = 0;

    const intervalId = setInterval(() => {
      if (isNil(tokenWindow) || tokenWindow.closed) {
        clearInterval(intervalId);
        return resolve();
      }

      if (shouldClosePopup(count)) {
        tokenWindow.close();

        clearInterval(intervalId);
        return resolve();
      }

      count += 1;
    }, CHECK_CLOSED_WINDOW_INTERVAL);
  });
};

export default showAzureDevopsPopup;
