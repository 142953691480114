import { useEffect } from 'react';
import { PORTFOLIO_CANVAS_PAGE } from 'constants/filters';
import { useDispatch, useSelector } from 'react-redux';
import usePageFilters from 'hooks/filters/usePageFilters';
import { applyFiltersOnWhiteboard } from 'features/whiteboard/store/thunks';
import { getDisplayLayer, getUserFilters } from 'store/filters/selectors';
import { getOrgHasBet } from 'store/organization';
import { getIsMetadataFetched } from 'store/app';

const useLoadProjectsForWhiteboard = ({ pageId = PORTFOLIO_CANVAS_PAGE } = {}) => {
  const dispatch = useDispatch();
  const { pageFilters: filters } = usePageFilters(pageId);
  const userFilters = useSelector(getUserFilters);
  const isMetadataFetched = useSelector(getIsMetadataFetched);
  const hasBet = useSelector(getOrgHasBet);
  const displayLayer = useSelector(getDisplayLayer);

  useEffect(() => {
    if (isMetadataFetched) {
      dispatch(applyFiltersOnWhiteboard({ filters, userFilters, hasBet, pageId, displayLayer }));
    }
  }, [filters, isMetadataFetched]);
};

export default useLoadProjectsForWhiteboard;
