import { useSelector } from 'react-redux';
import { makeSelectFilteredProjectsByRoadmapForPage } from 'store/filters/selectors';
import { IDEA_LAYER, layers } from 'store/projects/constants';

const selectIdeas = makeSelectFilteredProjectsByRoadmapForPage();

// const layerToSelectorMapper = {
//   [IDEA_LAYER]: selectIdeas,
//   [INITIATIVE_LAYER]: selectInitiatives,
// };

const _annotateCollection = (children, parents) => {
  const parentObjs = parents.reduce((acc, p) => {
    return { ...acc, [p.id]: 0 };
  }, {});

  children.forEach(p => {
    if (p.parent_id && (parentObjs[p.parent_id] || parentObjs[p.parent_id] === 0)) {
      parentObjs[p.parent_id] += 1;
    }
  });

  return parents.map(p => ({ ...p, childrenCount: parentObjs[p.id] }));
};

export default (parents, parentType) => {
  const children = useSelector((state, props) => selectIdeas(state, null, true, layers[layers.indexOf(parentType) - 1], false));
  const hasHierarchy = useSelector(state => state.organization.organization.has_hierarchy);

  if (parentType === IDEA_LAYER) return parents;

  if (!hasHierarchy) return parents;

  return _annotateCollection(children, parents);
};
