import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const AgGridHeaderWithIcon =
  ({ onIconClick, IconComponent, tooltipText }) =>
  ({ displayName }) => {
    return (
      <HeaderGroup>
        <span>{displayName}</span>
        <Tooltip title={tooltipText} placement="top">
          <StyledIconButton onClick={onIconClick}>
            <IconComponent />
          </StyledIconButton>
        </Tooltip>
      </HeaderGroup>
    );
  };

const HeaderGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;

  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const StyledIconButton = styled(IconButton)`
  &&&& {
    svg {
      font-size: ${({ theme }) => theme.typography.fontSize}px;
    }
  }
`;

export default AgGridHeaderWithIcon;
