import {
  dateColumnDef,
  getCustomFieldColumnDef,
  getTitleColumnDef,
  healthColumnDef,
  numericColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';
import { ChipsCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { multiSelectComparator, stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import {
  ChipCellRenderer,
  EmptyValueIfNotProjectRenderer,
  ParentCellRenderer,
  ProgressDisplayRenderer,
  RoadmapCellRenderer,
  TextCellRenderer,
} from 'containers/IdeasList/IdeasList/New/hooks/useIdeasGridColumnsDefinitions/renderers';
import { PRODUCT_1_LEVEL } from 'constants/roadmaps';
import { progressValueFormatter } from 'containers/IdeasList/IdeasList/New/hooks/useIdeasGridColumnsDefinitions/helpers';
import { getProductColumnDef } from 'utils/grids/columns';

import useSystemFields from 'hooks/useSystemFields';

function useBulkUpdateIdeasColumnsDefs({ teams }) {
  const [getSystemFieldName] = useSystemFields();

  const idColumnDef = {
    suppressMovable: true,
    cellRenderer: TextCellRenderer,
    comparator: stringComparator,
    field: 'id',
    headerName: 'ID',
    editable: false,
    width: 50,
  };

  const titleColumnDef = {
    ...getTitleColumnDef(),
    editable: false,
    width: 400,
  };

  const tagsColumnDef = {
    field: 'tags',
    headerName: getSystemFieldName('tag'),
    width: 120,
    labelField: 'title',
    cellRenderer: ChipsCellRenderer,
    comparator: multiSelectComparator,
  };

  const customersColumnDef = {
    field: 'customers',
    headerName: getSystemFieldName('customer'),
    width: 120,
    labelField: 'name',
    keyCreator: ({ value }) => (value?.length ? value.map(c => c.name) : [null]).join('-'),
    cellRenderer: ChipsCellRenderer,
    showTooltip: true,
    comparator: multiSelectComparator,
  };

  const roadmapColumnDef = {
    field: 'roadmapTitle',
    headerName: getSystemFieldName('roadmap'),
    cellRenderer: RoadmapCellRenderer,
    comparator: stringComparator,
    width: 120,
  };

  const product1ColumnDef = getProductColumnDef({
    field: 'product1Title',
    headerName: getSystemFieldName('product1'),
    selectedLevel: PRODUCT_1_LEVEL,
    getFilteredRoadmaps: params => params.context.filteredMetadata.roadmaps,
    cellRenderer: RoadmapCellRenderer,
  });

  const themeColumnDef = {
    field: 'themeTitle',
    headerName: getSystemFieldName('theme'),
    cellRenderer: EmptyValueIfNotProjectRenderer(ChipCellRenderer),
    comparator: stringComparator,
    width: 120,
  };

  const objectiveColumnDef = {
    field: 'objectiveTitle',
    headerName: getSystemFieldName('objective'),
    cellRenderer: EmptyValueIfNotProjectRenderer(ChipCellRenderer),
    comparator: stringComparator,
    width: 120,
  };

  const keyResult1ColumnDef = {
    field: 'keyResult1Title',
    headerName: getSystemFieldName('keyResult1'),
  };

  const keyResult2ColumnDef = {
    field: 'keyResult2Title',
    headerName: getSystemFieldName('keyResult2'),
  };

  const priorityColumnDef = {
    field: 'priorityTitle',
    headerName: getSystemFieldName('priority'),
    cellRenderer: EmptyValueIfNotProjectRenderer(ChipCellRenderer),
    comparator: stringComparator,
    width: 120,
  };

  const ownerColumnDef = {
    field: 'ownerName',
    headerName: 'Owner',
    cellRenderer: TextCellRenderer,
    comparator: stringComparator,
    width: 120,
  };

  const parentColumnDef = {
    field: 'parent_id',
    headerName: 'Parent',
    cellRenderer: EmptyValueIfNotProjectRenderer(ParentCellRenderer),
    comparator: stringComparator,
    width: 240,
  };

  const categoryColumnDef = {
    field: 'categoryTitle',
    headerName: getSystemFieldName('category'),
    cellRenderer: EmptyValueIfNotProjectRenderer(ChipCellRenderer),
    comparator: stringComparator,
    width: 120,
  };

  const timeframeColumnDef = {
    field: 'timeframeTitle',
    headerName: getSystemFieldName('timeframe'),
    cellRenderer: EmptyValueIfNotProjectRenderer(TextCellRenderer),
    comparator: stringComparator,
    width: 120,
  };

  const timeframe2ColumnDef = {
    field: 'timeframe2Title',
    headerName: getSystemFieldName('timeframe2'),
    cellRenderer: EmptyValueIfNotProjectRenderer(TextCellRenderer),
    comparator: stringComparator,
    width: 120,
  };

  const phaseColumnDef = {
    field: 'phaseTitle',
    headerName: getSystemFieldName('phase'),
    cellRenderer: ChipCellRenderer,
    comparator: stringComparator,
    width: 120,
  };

  const projectHealthColumnDef = {
    ...healthColumnDef,
    field: 'status_color',
    headerName: getSystemFieldName('health'),
    width: 110,
    minWidth: 110,
  };

  const deadlineColumnDef = {
    ...dateColumnDef,
    field: 'deadline',
    headerName: 'Target end date',
    width: 100,
  };
  const estimatedStartDateColumnDef = {
    ...dateColumnDef,
    field: 'estimated_start_date',
    headerName: 'Target start date',
    width: 100,
  };
  const completedDateColumnDef = {
    ...dateColumnDef,
    field: 'completed_date',
    headerName: 'Completed Date',
    width: 100,
  };
  const progressColumnDef = {
    ...numericColumnDef,
    field: 'progress',
    headerName: getSystemFieldName('progress'),
    cellRenderer: ProgressDisplayRenderer,
    cellClassRules: {
      'over-limit': node => node.value > 1,
    },
    valueFormatter: progressValueFormatter,
    width: 120,
  };

  const customFieldsColumnDefs = cf =>
    getCustomFieldColumnDef({
      ...cf,
      fieldType: cf.field_type,
      dataEnhanced: cf.data_enhanced,
    });

  const customUserFieldsColumnDefs = (customUserField, customUserHeader) => ({
    field: customUserField,
    headerName: getSystemFieldName(customUserHeader),
    cellRenderer: TextCellRenderer,
    comparator: stringComparator,
    width: 120,
  });

  const resourceTeamColumnDef = {
    field: 'resource_team_id',
    headerName: getSystemFieldName('team'),
    cellRenderer: TextCellRenderer,
    comparator: stringComparator,
    width: 120,
    valueGetter: params => {
      const curValue = params?.data?.resource_team_id;
      const foundTeam = teams.find(team => team.id === curValue);

      return foundTeam?.title;
    },
  };

  return {
    idColumnDef,
    titleColumnDef,
    tagsColumnDef,
    customersColumnDef,
    roadmapColumnDef,
    product1ColumnDef,
    themeColumnDef,
    objectiveColumnDef,
    keyResult1ColumnDef,
    keyResult2ColumnDef,
    priorityColumnDef,
    ownerColumnDef,
    parentColumnDef,
    categoryColumnDef,
    timeframeColumnDef,
    timeframe2ColumnDef,
    phaseColumnDef,
    projectHealthColumnDef,
    deadlineColumnDef,
    estimatedStartDateColumnDef,
    completedDateColumnDef,
    progressColumnDef,
    customFieldsColumnDefs,
    customUserFieldsColumnDefs,
    resourceTeamColumnDef,
  };
}

export default useBulkUpdateIdeasColumnsDefs;
