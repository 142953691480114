import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useUpdateSystemFields from 'hooks/useUpdateSystemFields';
import formatDate from 'utils/dates/formatDate';
import formatPercent from 'design-system/utils/formatPercent';
import { PROGRESS_PERCENTAGE_DECIMAL_PLACES } from 'constants/common';

import { getOrgHasJiraIntegrated } from 'store/organization/selectors';

const TIMEFRAME_KEY = 'timeframe';

const checkIfHeaderIsEditable = (isReadOnly, id, dataKey, supportedFields) =>
  !isReadOnly && id != null && supportedFields.includes(dataKey);

const isTimeframe = selectedColData => selectedColData?.key === TIMEFRAME_KEY;

export default ({ colId, colDate, colOverallProgress, isReadOnly, overallProgress, selectedColData }) => {
  const { supportedFields } = useUpdateSystemFields();

  const jiraIntegrated = useSelector(getOrgHasJiraIntegrated);

  const progress = useMemo(() => (
    overallProgress 
    ? formatPercent(colOverallProgress, PROGRESS_PERCENTAGE_DECIMAL_PLACES)
    : null), [
      overallProgress,
      colOverallProgress,
    ]);

  const isEditable = useMemo(() => checkIfHeaderIsEditable(isReadOnly, colId, selectedColData.key, supportedFields), [
    isReadOnly,
    colId,
    selectedColData.key,
    supportedFields,
  ]);

  const date = useMemo(() => (isTimeframe(selectedColData) && colDate ? formatDate(colDate) : null), [colDate]);

  return { isEditable, progress, date, hasDetailedProgress: jiraIntegrated };
};
