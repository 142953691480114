import moment from 'moment';
import { SET_DISCOVERY_BOT_HISTORY_CACHE_EXPIRY } from './types';
import { DISCOVERY_BOT_HISTORY_CACHE_STORAGE_KEY } from './constants';

export const storeDiscoveryBotConversationHistory = messages => {
  localStorage.setItem(DISCOVERY_BOT_HISTORY_CACHE_STORAGE_KEY, JSON.stringify(messages));

  return {
    type: SET_DISCOVERY_BOT_HISTORY_CACHE_EXPIRY,
    payload: {
      expiresIn: moment().add('1', 'day').toISOString(),
    },
  };
};
