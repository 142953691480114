import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { isNil, not, path, pipe } from 'ramda';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { getOrgHasMultipleIntegrations as getOrgHasMultipleIntegrationsSelector } from 'store/organization/selectors';

import { INTEGRATIONS_KEYS, INTEGRATIONS_NAMES, INTEGRATIONS_ICON_MAPPER } from 'constants/integrations';

import useIntegrations from 'hooks/useIntegrations';
import useUserIntegrations from 'hooks/useUserIntegrations';
import useRallyIntegrationSettings from './hooks/useRallyIntegrationSettings';

import ConfirmDialog from 'components/ConfirmDialog';
import Content from '../components/Content';
import Title from '../components/Title';
import Body from '../components/Body';
import IntegrationSteps from './components/IntegrationSteps';
import IntegrationDetails from './components/IntegrationDetails';
import MultiIntegrationsList from '../components/MultiIntegrationsList';

const Icon = INTEGRATIONS_ICON_MAPPER.rally;

const title = `${INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.rally]} Integration`;

const HELP_LINK = 'https://dragonboat.zendesk.com/hc/en-us/articles/15684176368151-Rally-Software-Integration';

const hasNotWorkspaceId = pipe(path(['data', 'workspaceId']), isNil);
const hasWorkspaceId = pipe(hasNotWorkspaceId, not);

const Rally = props => {
  const { handleTestConnection, userCanUpdateOrgIntegration, userCanViewOrgIntegration } = props;

  const hasMultipleIntegrations = useSelector(getOrgHasMultipleIntegrationsSelector);

  const {
    selectedOrgIntegration,
    selectedOrgIntegrationId,
    isConfirmDialogDisplayed,
    isAddingOrgIntegration,
    onClickDisconnect,
    onIntegrationSwitchChangeHandler,
    onRemoveOrgIntegrationHandler,
    onCloseRemoveOrgIntegrationConfirmationHandler,
    onCreateNewIntegrationHandler,
    onSelectOrgIntegrationHandler,
  } = useRallyIntegrationSettings();

  const { hasOrgIntegrations } = useIntegrations(INTEGRATIONS_KEYS.rally);
  const { userIntegration } = useUserIntegrations(INTEGRATIONS_KEYS.rally, selectedOrgIntegrationId);

  const shouldShowIntegrationSteps =
    not(hasOrgIntegrations) ||
    (selectedOrgIntegrationId && not(userIntegration)) ||
    (selectedOrgIntegration && hasNotWorkspaceId(selectedOrgIntegration)) ||
    isAddingOrgIntegration;

  const shouldShowIntegrationsList =
    hasMultipleIntegrations && hasOrgIntegrations && !selectedOrgIntegrationId && !isAddingOrgIntegration;

  const shouldShowIntegrationDetails = selectedOrgIntegrationId && userIntegration && hasWorkspaceId(selectedOrgIntegration);
  const shouldShowContactInfo = !userCanViewOrgIntegration || (!userCanUpdateOrgIntegration && !hasOrgIntegrations);

  return (
    <Content>
      <Title logo={<Icon size={40} marginRight={16} />} title={title} helpLink={HELP_LINK} />
      {shouldShowContactInfo && (
        <Body>
          <p>Please contact your organization Admin to activate {INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.rally]} integration.</p>
        </Body>
      )}
      {!shouldShowContactInfo && shouldShowIntegrationSteps && (
        <Body>
          <div>
            <StyledFormControl
              control={
                <Switch
                  id="rally-integration-switch"
                  color="secondary"
                  checked={!!selectedOrgIntegrationId || isAddingOrgIntegration}
                  onChange={onIntegrationSwitchChangeHandler}
                  disabled={!userCanUpdateOrgIntegration}
                />
              }
              label={`${INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.rally]} Integration`}
              labelPlacement="start"
            />
          </div>
          <IntegrationSteps
            orgIntegration={selectedOrgIntegration}
            userIntegration={userIntegration}
            isAddingOrgIntegration={isAddingOrgIntegration}
          />
        </Body>
      )}
      {!shouldShowContactInfo && shouldShowIntegrationsList && (
        <MultiIntegrationsList
          integrationType={INTEGRATIONS_KEYS.rally}
          onCreateNewIntegration={onCreateNewIntegrationHandler}
          onSelectIntegration={orgIntegration => onSelectOrgIntegrationHandler(orgIntegration?.id)}
          userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
          userCanViewOrgIntegration={userCanViewOrgIntegration}
        />
      )}
      {!shouldShowContactInfo && shouldShowIntegrationDetails && (
        <IntegrationDetails
          selectedOrgIntegration={selectedOrgIntegration}
          handleTestConnection={handleTestConnection}
          userCanViewOrgIntegration={userCanViewOrgIntegration}
          userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
          onShowDisconnectConfirmation={onClickDisconnect}
          goBack={() => onSelectOrgIntegrationHandler(null)}
        />
      )}
      <ConfirmDialog
        title={`Disconnect From ${INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.rally]}`}
        text={
          <div>
            <p>
              Disconnecting {INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.rally]} will disable{' '}
              {INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.rally]} linking and update for your entire Workspace. Do you want to continue?
            </p>
            <br />
            <p>You may reconnect in the future.</p>
          </div>
        }
        isOpen={isConfirmDialogDisplayed}
        onCancel={onCloseRemoveOrgIntegrationConfirmationHandler}
        onConfirm={onRemoveOrgIntegrationHandler}
      />
    </Content>
  );
};

const StyledFormControl = styled(FormControlLabel)`
  &&&& {
    margin-bottom: 1rem;
  }
`;

export default Rally;
