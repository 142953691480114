import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { propOr } from 'ramda';

import BaseLayout from 'design-system/organisms/BaseLayout/index';

import { spacing } from 'design-system/theme';
import { isUnplannedCapacityChartEnabled } from 'constants/flags';

import ChartWidget from 'routes/Dashboard/Dashboards/ChartWidget';
import {
  SCENARIOS_BENEFITS_COMPARISON_CHART,
  SCENARIO_STACKED_BENEFIT_CHART,
  SCENARIO_UNPLANNED_RESOURCES_CHART,
  SCENARIOS_ACCUMULATIVE_BENEFITS_COMPARISON_CHART,
} from 'routes/Dashboard/Dashboards/helpers/templates';

import { PLAN_OF_RECORD_ID, PLAN_OF_RECORD_TITLE_COMMITTED } from 'constants/common';

import {
  getSelectedRoadmapVersionsIdsToCompare,
  getAvailableRoadmapVersions,
  getBaseCompareVersionSelected,
} from 'store/roadmapVersions/selectors';

import createLabelsMap from '../../utils/createLabelsMap';
import ScenariosChartsOptions from 'containers/ScenariosChartsOptions/ScenariosChartsOptions';
import useScenariosChartsOptions from 'containers/ScenariosChartsOptions/useScenariosChartsOptions';

const UNPLANNED_RESOURCE_BY_SKILL_TITLE = 'Unplanned resource by Skill (in weeks)';
const UNPLANNED_RESOURCE_BY_TEAM_TITLE = 'Unplanned resource by Team (in weeks)';

/**
 * Creates widgets based on the selected roadmap versions.
 * @param {number[]} selectedRoadmapVersionsIds
 * @returns
 */
const createWidgets = (
  baseCompareVersionSelectedId,
  selectedRoadmapVersionsIds,
  projectedField,
  stackedByField,
  timeBucketMode,
  dateInterval,
  labelsMap,
) => {
  const comparisonAccumulativeChart = {
    ...SCENARIOS_ACCUMULATIVE_BENEFITS_COMPARISON_CHART,
    props: {
      roadmapVersionIds: [baseCompareVersionSelectedId || PLAN_OF_RECORD_ID, ...selectedRoadmapVersionsIds.map(Number)],
      projectedField,
      timeBucketMode,
      dateInterval,
      labelsMap,
    },
  };

  const comparisonChart = {
    ...SCENARIOS_BENEFITS_COMPARISON_CHART,
    props: {
      roadmapVersionIds: [baseCompareVersionSelectedId || PLAN_OF_RECORD_ID, ...selectedRoadmapVersionsIds.map(Number)],
      projectedField,
      timeBucketMode,
      dateInterval,
      labelsMap,
    },
  };

  const baseVersionTitle = baseCompareVersionSelectedId
    ? propOr('N/A', baseCompareVersionSelectedId)(labelsMap)
    : PLAN_OF_RECORD_TITLE_COMMITTED;

  const planOfRecordStackedChartWidget = {
    ...SCENARIO_STACKED_BENEFIT_CHART,
    props: {
      roadmapVersionId: baseCompareVersionSelectedId || PLAN_OF_RECORD_ID,
      chartTitle: baseVersionTitle,
      dateInterval,
      projectedField,
      timeBucketMode,
      stackedByField,
    },
  };

  const stackedChartWidgets = selectedRoadmapVersionsIds.map(id => {
    return {
      ...SCENARIO_STACKED_BENEFIT_CHART,
      props: {
        roadmapVersionId: Number(id),
        chartTitle: propOr('N/A', id)(labelsMap),
        dateInterval,
        projectedField,
        timeBucketMode,
        stackedByField,
      },
    };
  });

  const unplannedResourcesWidgets = isUnplannedCapacityChartEnabled
    ? createUnplannedResourcesWidgets(
        baseCompareVersionSelectedId,
        selectedRoadmapVersionsIds,
        dateInterval,
        labelsMap,
        baseVersionTitle,
      )
    : [];

  return [
    comparisonAccumulativeChart,
    comparisonChart,
    planOfRecordStackedChartWidget,
    ...stackedChartWidgets,
    ...unplannedResourcesWidgets,
  ];
};

const createUnplannedResourcesWidgets = (
  baseCompareVersionSelectedId,
  selectedRoadmapVersionsIds,
  dateInterval,
  labelsMap,
  baseVersionTitle,
) => {
  const baseVersionBySkillChartWidget = {
    ...SCENARIO_UNPLANNED_RESOURCES_CHART,
    props: {
      roadmapVersionId: baseCompareVersionSelectedId || PLAN_OF_RECORD_ID,
      chartTitle: [baseVersionTitle, UNPLANNED_RESOURCE_BY_SKILL_TITLE].join(' - '),
      dateInterval,
      stackedByField: 'skill_id',
    },
  };

  const bySkillChartWidgets = selectedRoadmapVersionsIds.map(id => {
    return {
      ...SCENARIO_UNPLANNED_RESOURCES_CHART,
      props: {
        roadmapVersionId: Number(id),
        chartTitle: [propOr('N/A', id)(labelsMap), UNPLANNED_RESOURCE_BY_SKILL_TITLE].join(' - '),
        dateInterval,
        stackedByField: 'skill_id',
      },
    };
  });

  const baseVersionByTeamChartWidget = {
    ...SCENARIO_UNPLANNED_RESOURCES_CHART,
    props: {
      roadmapVersionId: baseCompareVersionSelectedId || PLAN_OF_RECORD_ID,
      chartTitle: [baseVersionTitle, UNPLANNED_RESOURCE_BY_TEAM_TITLE].join(' - '),
      dateInterval,
      stackedByField: 'team_id',
    },
  };

  const byTeamChartWidgets = selectedRoadmapVersionsIds.map(id => {
    return {
      ...SCENARIO_UNPLANNED_RESOURCES_CHART,
      props: {
        roadmapVersionId: Number(id),
        chartTitle: [propOr('N/A', id)(labelsMap), UNPLANNED_RESOURCE_BY_TEAM_TITLE].join(' - '),
        dateInterval,
        stackedByField: 'team_id',
      },
    };
  });

  return [baseVersionBySkillChartWidget, ...bySkillChartWidgets, baseVersionByTeamChartWidget, ...byTeamChartWidgets];
};

const CompareScenariosCharts = () => {
  const roadmapVersions = useSelector(state => getAvailableRoadmapVersions(state));
  const baseCompareVersionSelected = useSelector(getBaseCompareVersionSelected);
  const selectedRoadmapVersionsIds = useSelector(state => getSelectedRoadmapVersionsIdsToCompare(state));

  const labelsMap = useMemo(() => createLabelsMap(roadmapVersions), [roadmapVersions]);

  const { projectedOptionSelected, stackedByOptionSelected, timeBucketOptionSelected, dateInterval } =
    useScenariosChartsOptions();

  const widgets = useMemo(
    () =>
      createWidgets(
        baseCompareVersionSelected?.id,
        selectedRoadmapVersionsIds,
        projectedOptionSelected?.key,
        stackedByOptionSelected?.key,
        timeBucketOptionSelected?.key,
        dateInterval,
        labelsMap,
      ),
    [
      selectedRoadmapVersionsIds,
      baseCompareVersionSelected?.id,
      projectedOptionSelected,
      stackedByOptionSelected,
      timeBucketOptionSelected,
      dateInterval,
      labelsMap,
    ],
  );

  return (
    <Wrapper>
      <BaseLayout>
        <ScenariosChartsOptions />
        <ChartsContainer>
          {widgets.map(widget => (
            <ChartWidget widget={widget} handleDelete={() => {}} inEditMode={false} updateWidgetHeight={() => {}} />
          ))}
        </ChartsContainer>
      </BaseLayout>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ChartsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${spacing(2.25)}px;
`;

export default CompareScenariosCharts;
