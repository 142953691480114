export const RECENTLY_CREATED_BY_ME_FILTER_KEY = 'recently-created-by-me';
export const RECENTLY_CREATED_BY_ME_FILTER_LABEL = 'Recently created by me';

export const RECENTLY_CREATED_FILTER_KEY = 'recently-created';
export const RECENTLY_CREATED_FILTER_LABEL = 'Recently created';

export const USER_IS_OWNER_FILTER_KEY = 'user-is-owner';
export const USER_IS_OWNER_FILTER_LABEL = `I'm the owner`;

export const OWNER_FIELD = 'owner_id';

export const CREATED_AFTER_FIELD = 'created_after';
export const CREATED_AFTER_LIMIT = 100;
export const CREATED_AFTER_VALUE = '4';

export const CREATED_BY_FILTER = 'created_by_id';

export const RECENTLY_CREATED_STATE = {
  key: CREATED_AFTER_FIELD,
  limit: CREATED_AFTER_LIMIT,
  values: [CREATED_AFTER_VALUE],
};
