import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';

import cellEditorHoc from '../helpers/cellEditorHoc';
import RemirrorWYSIWYGEditor from 'design-system/molecules/RemirrorWYSIWYGEditor';
import { F, tryCatch } from 'ramda';
import useUploadAttachmentImage from 'hooks/files/useUploadAttachmentImage';

const safeJsonParse = tryCatch(JSON.parse, F);

const GRID_MAX_HEIGHT = '300px';

const RemirrorWYSIWYGCellEditor = cellEditorHoc(({
  data: project,
  value: originalValue,
  disableToolbox,
  autoFocus,
  forwardedRef,
}) => {
  const [value, setValue] = useState(originalValue);

  useImperativeHandle(forwardedRef, () => ({
    getValue() {
      return value;
    },
  }), [value]);

  const handleOnChange = useCallback(v => {
    setValue(v);
  }, [setValue]);

  const addRef = useCallback(
    input => {
      if (autoFocus && typeof input?.focus === 'function') {
        input.focus();
      }
    },
    [autoFocus],
  );

  const useJSONOutput = useMemo(() => Boolean(project?.jira || safeJsonParse(originalValue)), [originalValue]);

  const { imageUploadHandler, getBaseURLForAttachments } =  useUploadAttachmentImage();

  const baseURLForEditorAttachments = getBaseURLForAttachments(project);

  return (
    <RemirrorWYSIWYGEditor
      ref={addRef}
      content={value}
      defaultEditEnabled
      disableToolbar={disableToolbox}
      onChange={handleOnChange}
      outputJSON={useJSONOutput}
      callOnChangeWhenTyping
      maxHeight={GRID_MAX_HEIGHT}
      imageUploadHandler={imageUploadHandler}
      baseURLForAttachments={baseURLForEditorAttachments}
    />
  );
});

RemirrorWYSIWYGCellEditor.name = 'RemirrorWYSIWYGCellEditor';

export default RemirrorWYSIWYGCellEditor;
