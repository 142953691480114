export default (startDateChange, endDateChange) => {
  return dates => {
    if (dates.end_date) {
      const toMidnight = new Date(dates.end_date).setUTCHours(23, 59, 59);

      endDateChange(toMidnight);
    } else if (dates.start_date) {
      const fromMidnight = new Date(dates.start_date).setUTCHours(0, 0, 0, 0);

      startDateChange(fromMidnight);
    }
  };
};
