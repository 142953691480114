// External dependencies
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { getOrgHasJiraIntegrated } from 'store/organization/selectors';

// Dragonboat dependencies
import { openProjectLightbox } from 'store/projectLightbox';
import ImportWizardLightbox from './ImportWizardLightbox';

class ImportWizardLightboxContainer extends Component {
  onUserWithoutJira = () => {
    this.props.history.push('/settings/integrations/jira');
  };

  onUserWithoutAzureDevops = () => {
    this.props.history.push('/settings/integrations/azuredevops');
  };

  onUserWithoutRally = () => {
    this.props.history.push('/settings/integrations/rally');
  };

  render() {
    return (
      <ImportWizardLightbox
        onUserWithoutJira={this.onUserWithoutJira}
        onUserWithoutAzureDevops={this.onUserWithoutAzureDevops}
        onUserWithoutRally={this.onUserWithoutRally}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = state => {
  const {
    organization: { organization },
  } = state;

  return {
    jiraIntegrated: getOrgHasJiraIntegrated(state),
    systemFields: organization.system_fields_name,
  };
};

export default compose(connect(mapStateToProps, { openProjectLightbox }), withRouter)(ImportWizardLightboxContainer);
