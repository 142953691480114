import React from 'react';

import MetricsForm from 'containers/MetricsForm/MetricsForm';

import useSystemFields from 'hooks/useSystemFields';
import useProjectsMultipleMetricsForm from './hooks/useProjectsMultipleMetricsForm';

const ProjectsMetricsForm = ({ canAddOrEdit, labelTooltip, onLabelClick, project, toggleMetricsDialog }) => {
  const [getSystemFieldName] = useSystemFields();
  const { metrics, metricsOptions, onAddMetric, onRemoveMetric, onUpdateMetric } = useProjectsMultipleMetricsForm(project);

  return (
    <MetricsForm
      selectedEntity={project}
      canAddOrEdit={canAddOrEdit}
      objectMetrics={project.metrics}
      onLabelClick={onLabelClick}
      labelTooltip={labelTooltip}
      toggleMetricsDialog={toggleMetricsDialog}
      metrics={metrics}
      metricsOptions={metricsOptions}
      onAddMetric={onAddMetric}
      onRemoveMetric={onRemoveMetric}
      onUpdateMetric={onUpdateMetric}
      getSystemFieldName={getSystemFieldName}
    />
  );
};

export default ProjectsMetricsForm;
