import { useEffect, useState } from 'react';

import {
  DETAILS_TAB,
  SHARE_WITH_USERS_TAB,
  SHARE_PUBLIC_LINK_TAB,
  RECURRING_NOTIFICATIONS_TAB,
} from 'containers/ViewDialog/constants';

import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const TABS = [DETAILS_TAB, SHARE_WITH_USERS_TAB, RECURRING_NOTIFICATIONS_TAB, SHARE_PUBLIC_LINK_TAB];

const getVisibleTabs = (tabs, view, permissions, excludedTabs) =>
  tabs.filter(tab => {
    return permissions.canView(PERMISSION_FEATURES.userViewDialogTabs, { tab, view, excludedTabs });
  });

const INITIAL_DIALOG_PROPS = { open: false, selectedTab: DETAILS_TAB, userCanEditView: true, visibleTabs: [] };

const useSaveViewDialog = (selectedView, permissions, excludedTabs = []) => {
  const [updateDialogProps, setDialogProps] = useState(INITIAL_DIALOG_PROPS);

  useEffect(() => {
    if (selectedView) {
      const visibleTabs = getVisibleTabs(TABS, selectedView, permissions, excludedTabs);
      const userCanEditView = permissions.canUpdate(PERMISSION_FEATURES.userViewDialogTabs, { view: selectedView });

      setDialogProps({ ...updateDialogProps, userCanEditView, visibleTabs });
    }
  }, [selectedView?.id, updateDialogProps.open]);

  const openUpdateDialog = () => setDialogProps({ ...updateDialogProps, open: true, selectedTab: DETAILS_TAB });

  const closeUpdateDialog = () => setDialogProps({ ...updateDialogProps, open: false });

  const openShareDialog = () => setDialogProps({ ...updateDialogProps, open: true, selectedTab: SHARE_WITH_USERS_TAB });

  return { updateDialogProps, openUpdateDialog, closeUpdateDialog, openShareDialog };
};

export default useSaveViewDialog;
