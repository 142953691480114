/**
 * Droppable Molecule Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */

import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';

import { isNumeric } from 'design-system/utils';

export default props => {
  const { id, height, children, key, index, allowFlexGrow, flexHeight, className, withHover = false } = props;

  const getHoverStyle = (snapshot, withHover) => {
    if (withHover && snapshot.isDraggingOver) {
      return {
        backgroundColor: '#f3f3f3',
        border: '1px dashed #dadbdc',
      };
    }

    return {
      backgroundColor: '#fff',
    };
  };

  return (
    <Droppable key={key} droppableId={`${id}`} index={index}>
      {(provided, snapshot) => {
        return (
          <Box
            className={className}
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ minHeight: isNumeric(height) ? height : 50, ...getHoverStyle(snapshot, withHover) }}
            allowFlexGrow={allowFlexGrow}
            flexHeight={flexHeight}
          >
            {children}
            <Placeholder ref={provided.innerRef} />
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </Box>
        );
      }}
    </Droppable>
  );
};

const Box = styled.div`
  &&&& {
    display: flex;
    flex-direction: column;
    ${({ flexHeight, allowFlexGrow }) =>
      flexHeight
        ? `
          flex-basis: auto;
          flex-shrink: 0;
          flex-grow: ${allowFlexGrow ? '1' : '0'};
        `
        : `
      height: 100%;
    `}
    padding-bottom: 0px;

    & > div {
      width: 100%;
    }
  }
`;

const Placeholder = styled.div`
  flex: 1 1 auto;
`;
