// External dependencies
import React, { useRef } from 'react';
import styled from 'styled-components';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';

// Dragonboat dependencies
import copyToClipboard from 'utils/copyToClipboard';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';


const useClipboard = text => {
  const refInput = useRef();
  const _selectAndCopy = async () => {
    const { current } = refInput;

    current.select();
    await copyToClipboard(text);
    current.select();
  };

  if (!text) return '';

  return {
    ClipboardText: () => (
      <Wrapper>
        <UrlLink text={text} ref={refInput} onClick={_selectAndCopy} type="text" readOnly value={text} />
      </Wrapper>
    ),
    ClipboardButton: () => (
      <CopyButton onClick={_selectAndCopy} title="Copy url to clipboard">
        <CopyIcon />
      </CopyButton>
    ),
  };
};

export default useClipboard;

const Wrapper = styled.div`
  margin-top: 10px;
  position: relative;
  display: flex;
`;

const UrlLink = styled.input`
  &&&& {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    font-size: inherit;
    color: #2191c6;
    cursor: pointer;
    outline: none;
  }
`;

const CopyButton = styled(ButtonIcon)`
  &&&& {
    white-space: nowrap;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
