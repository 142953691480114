import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import withTheme from '@material-ui/core/styles/withTheme';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

import history from 'store/utils/history';
import { getActiveTemplate } from 'store/templates';

import DragonIconButton from 'design-system/atoms/DragonIconButton/index';
import DragonBackButton from 'design-system/atoms/DragonBackButton/index';
import OutlinedPurpleButton from 'design-system/molecules/OutlinedPurpleButton/index';
import Button from 'design-system/atoms/Button/index';

import Stars from 'design-system/atoms/DragonIcons/Stars';
import Bell from 'design-system/atoms/DragonIcons/Bell';
import { spacing } from 'design-system/theme';

import Notifications from 'containers/Main/Notifications';

import useScenarioToolbarControls from 'containers/ScenarioToolbarControls/hooks/useScenarioToolbarControls';
import DragonTemplateHeader from 'containers/DragonTemplateHeader/DragonTemplateHeader';
import ScenarioToolbarControls from 'containers/ScenarioToolbarControls';
import DragonAvatarDropdown from './components/DragonAvatarDropdown';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useScenariosNavigation from 'hooks/useScenariosNavigation';
import useNavigationToPathWithGoBackUrl from 'hooks/useNavigationToPathWithGoBackUrl';
import useOneClickPlan from 'hooks/useOneClickPlan';
import useOnboardingDemo from 'hooks/onboarding/useOnboardingDemo';
import usePermissions from 'hooks/permissions/usePermissions';

import { getPageIdFromPath } from 'utils/userViews';
import { checkIfOrgIsUnpaid } from 'utils/organizations';
import isMobileScreen from 'utils/isMobileScreen';

import { PAGE_HEADER_HEIGHT } from 'constants/common';
import { getHideRowsWithSameData } from 'store/roadmapVersions/selectors';
import { toggleHideRowsWithSameData } from 'store/roadmapVersions';

import { homeBackgroundStyles } from 'design-system/atoms/HomeBackground';
import { PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';
import { MISSION_CONTROL_HOME_PAGE } from 'constants/filters';

const PAGES_WITH_WHITE_BACKGROUND = [
  '/goals',
  'metrics',
  '/ideas',
  '/dashboard',
  '/forecast',
  '/requests',
  '/home',
  '/user',
  '/welcome',
  '/settings',
  '/scenario',
];

const PAGES_WITH_IMAGE_BACKGROUND = ['/home'];

const getHeaderBackgroundColor = (useWhiteHeaderBackground, isParentDragon, theme) => {
  if (isParentDragon) return theme.palette.newLayout.background.parentHeader;
  if (useWhiteHeaderBackground) return theme.palette.white;

  return theme.palette.newLayout.background.header;
};

const NewDragonHeader = ({
  selectedTab,
  pageFilters,
  headerFiltersProps = {},
  pageOptions,
  headerLeftContent,
  leftMenuOpen,
  theme,
  headerWithGoBack,
  dragonbotComponent,
  showHideRowsWithSameDataToggle,
}) => {
  const dispatch = useDispatch();

  const [notificationsAnchor, setNotificationsAnchor] = useState(null);

  const { canView } = usePermissions();

  const notificationsNotRead = useSelector(({ notifications }) => notifications._meta && notifications._meta.notRead);
  const activeTemplateId = useSelector(getActiveTemplate);
  const hideRowsWithSameData = useSelector(getHideRowsWithSameData);
  const hasMissionControlHome = canView(PERMISSION_PAGES[MISSION_CONTROL_HOME_PAGE]);

  const { billingStatus, isParentDragon, hasOneClickPlan } = useOrganizationsAccessControl();
  const { isNewDraftScenario, isCreatingOrViewingScenario, isViewingScenario } = useScenarioToolbarControls();
  const { isDemoModeActive } = useOnboardingDemo();

  const { exitScenarioModule } = useScenariosNavigation();
  const { navigateBack } = useNavigationToPathWithGoBackUrl();

  const { toggleOneClickPlanMode } = useOneClickPlan();

  const path = window.location.pathname;
  const pageId = getPageIdFromPath(path);

  const orgIsUnpaid = useMemo(() => !!checkIfOrgIsUnpaid(billingStatus), [billingStatus]);

  const showBuyNowButton = useMemo(() => {
    const isSettings = path.includes('settings');
    const isHome = !pageId && path.includes('dashboard') ? false : path.includes('home');

    if (isSettings || isHome) {
      return false;
    }

    return orgIsUnpaid;
  }, [orgIsUnpaid, pageId, path]);

  // This will be removed once we have the confirmation that all pages can have a white Header background
  const useWhiteHeaderBackground = useMemo(() => PAGES_WITH_WHITE_BACKGROUND.some(page => path.includes(page)), [path]);

  const useHomeHeaderBackground = hasMissionControlHome && PAGES_WITH_IMAGE_BACKGROUND.some(page => path?.startsWith(page));

  const showTemplateHeader = useMemo(() => !!activeTemplateId && !isDemoModeActive, [activeTemplateId, isDemoModeActive]);

  const showAiPlannerButton = useMemo(() => hasOneClickPlan && path.includes('scenario'), [path, hasOneClickPlan]);

  const showAlertsBadge = notificationsNotRead > 0;

  const handleAlertClick = event => {
    setNotificationsAnchor(event.currentTarget);
  };

  const _helperContainer = useMemo(() => {
    return (
      <HelperContainer>
        <VerticalDivider />
        <StyledDragonIconButton
          id="alerts-icon"
          type="transparent"
          variant="disable"
          icon={<Bell />}
          secondaryIcon={showAlertsBadge && <Badge />}
          onClick={event => handleAlertClick(event)}
        />
        <DragonAvatarDropdown />
      </HelperContainer>
    );
  }, []);

  const _optionsContainer = useMemo(() => {
    return <OptionsContainer removeGap={['portal'].includes(selectedTab?.path)}>{pageOptions}</OptionsContainer>;
  }, [pageOptions, selectedTab?.path]);

  const onBackClick = useCallback(() => {
    if (isViewingScenario) {
      exitScenarioModule();
    } else {
      navigateBack();
    }
  }, [history, navigateBack, isViewingScenario]);

  return (
    <Wrapper
      headerBackgroundColor={getHeaderBackgroundColor(useWhiteHeaderBackground, isParentDragon, theme)}
      isCreatingOrViewingScenario={isCreatingOrViewingScenario}
      isMobileScreen={isMobileScreen()}
      useHomeHeaderBackground={useHomeHeaderBackground}
      data-html2canvas-ignore
    >
      {showTemplateHeader ? (
        <>
          <DragonTemplateHeader />
          {_helperContainer}
        </>
      ) : (
        <>
          <LeftContainer hide1stcol={leftMenuOpen}>
            <>
              {(headerWithGoBack || isViewingScenario) && (
                <IconContainer>
                  <DragonBackButton type="transparent" icon={<ArrowLeft />} onClick={onBackClick} />
                </IconContainer>
              )}
              {headerLeftContent || ''}
              <FilterContainer>
                {pageFilters && React.cloneElement(pageFilters, { ...(headerFiltersProps ?? {}) })}
              </FilterContainer>
            </>
          </LeftContainer>
          <MidContainer isNewDraftScenario={isNewDraftScenario} isCreatingOrViewingScenario={isCreatingOrViewingScenario}>
            <ScenarioToolbarControls />
          </MidContainer>
          <RightContainer>
            {dragonbotComponent}
            {!headerWithGoBack && !isViewingScenario && _optionsContainer}
            {showHideRowsWithSameDataToggle && (
              <Tooltip title="Hide rows with the same data">
                <ToggleSwitch
                  checked={!!hideRowsWithSameData}
                  color="primary"
                  onChange={() => dispatch(toggleHideRowsWithSameData())}
                />
              </Tooltip>
            )}
            {showAiPlannerButton && (
              <AiPlannerButton
                background={theme.palette.newLayout.background.dodgerBlue}
                color={theme.palette.newLayout.background.white}
                hover={{ background: theme.palette.newLayout.background.duskBlue }}
                fontSize={theme.typography.fontSize}
                width="200px"
                onClick={() => toggleOneClickPlanMode(true)}
              >
                <Stars /> AI Generate Scenario
              </AiPlannerButton>
            )}
            {showBuyNowButton && (
              <OutlinedPurpleButton
                id="buy_now"
                buttonText="Buy Now"
                extraStyles="width: 105px;"
                onClick={() => history.push('/settings/billing')}
              />
            )}
            {!isMobileScreen() && _helperContainer}
          </RightContainer>
          <Notifications
            anchorElem={notificationsAnchor}
            open={!!notificationsAnchor}
            onClose={() => setNotificationsAnchor(null)}
            placement="bottom"
          />
        </>
      )}
    </Wrapper>
  );
};

const VerticalDivider = styled.div`
  &&&& {
    width: 1px;
    height: 40px;
    background: #e6e6e6;
  }
`;

const StyledDragonIconButton = styled(DragonIconButton)`
  position: relative;

  &&&& {
    svg {
      width: 24px;
      height: 24px;
      margin-left: 1px;
    }
  }
`;

const FilterContainer = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

const LeftContainer = styled.div`
  &&&& {
    height: 100%;

    display: grid;
    grid-template-columns: ${({ hide1stcol }) => (hide1stcol ? '' : 'auto')} auto 1fr;
    align-items: center;
    gap: 14px;
  }
`;

const HelperContainer = styled.div`
  &&&& {
    display: flex;
    align-items: center;

    gap: 15px;
  }
`;

const OptionsContainer = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    gap: ${props => (props.removeGap ? '0' : '15px')};
  }
`;

const MidContainer = styled.div`
  &&&& {
    display: ${({ isCreatingOrViewingScenario }) => (isCreatingOrViewingScenario ? 'grid' : 'none')};
    width: ${({ isNewDraftScenario }) => (isNewDraftScenario ? 'unset' : '50%')};
    justify-self: ${({ isNewDraftScenario }) => (isNewDraftScenario ? 'center' : 'end')};
    justify-content: ${({ isNewDraftScenario }) => (isNewDraftScenario ? 'center' : 'end')};
    padding-right: ${({ isNewDraftScenario }) => (isNewDraftScenario ? '0' : '50px')};
  }
`;

const RightContainer = styled.div`
  &&&& {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    justify-content: flex-end;
    /* gap: 24px; */
  }
`;

const Wrapper = styled.div`
  &&&& {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1001;

    display: ${({ isCreatingOrViewingScenario }) => (isCreatingOrViewingScenario ? 'grid' : 'flex')};
    grid-template-columns: ${({ isCreatingOrViewingScenario }) => (isCreatingOrViewingScenario ? '1fr 14fr 1fr' : 'none')};
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: ${PAGE_HEADER_HEIGHT}px;

    box-sizing: border-box;

    padding: ${({ isMobileScreen }) => (isMobileScreen ? `0 ${spacing()}px` : `0 ${spacing(2.5)}px`)};

    background: ${({ headerBackgroundColor }) => headerBackgroundColor};
    ${({ useHomeHeaderBackground }) =>
      useHomeHeaderBackground &&
      css`
        ${homeBackgroundStyles}
      `}
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.newLayout.background.lightBlack}`};
  }
`;

const IconContainer = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizeMedium}px;
  margin-right: 5px;

  svg {
    color: ${({ theme }) => theme.palette.newLayout.background.primary};
  }
`;

const Badge = styled.div`
  &&&& {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.newLayout.background.danger};
  }
`;

const AiPlannerButton = styled(Button)`
  &&&& {
    height: 37px;
    margin-right: ${spacing()}px;

    svg {
      width: 22px;
      height: 22px;
      margin-right: ${spacing(1.5)}px;

      path {
        fill: ${({ theme }) => theme.palette.newLayout.background.white};
      }
    }
  }
`;

const ToggleSwitch = styled(Switch)`
  &&&&& {
    margin-right: ${spacing(2)}px;
  }
`;

NewDragonHeader.propTypes = {
  pageFilters: PropTypes.element,
  pageOptions: PropTypes.element,
};

export default withTheme()(NewDragonHeader);
