import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_PROJECTS_CHILDREN'),
  ...getThunkActionTypes('CREATE_PROJECT'),
  ...getThunkActionTypes('BULK_CREATE_PROJECTS'),
  ...getThunkActionTypes('IMPORT_PROJECTS'),
  ...getThunkActionTypes('UPDATE_PROJECT'),
  ...getThunkActionTypes('UPDATE_PROJECTS'),
  ...getThunkActionTypes('BULK_UPDATE_PROJECTS'),
  ...getThunkActionTypes('UNDO_BULK_UPDATE_PROJECTS'),
  ...getThunkActionTypes('DELETE_PROJECTS'),
  ...getThunkActionTypes('UNDO_DELETE_PROJECTS'),
  ...getThunkActionTypes('UPDATE_PROJECT_ROW_ORDER'),
  ...getThunkActionTypes('FETCH_PROJECT_HISTORY'),
  ...getThunkActionTypes('WATCH_PROJECT'),
  ...getThunkActionTypes('UNWATCH_PROJECT'),
  ...getThunkActionTypes('FETCH_PROJECT_CUSTOMER_REQUESTS'),
  ...getThunkActionTypes('GET_PROJECT_COUNTERS_BY_PHASE'),
  ...getThunkActionTypes('MERGE_PROJECTS'),
  ...getThunkActionTypes('CLONE_PROJECT'),
  ...getThunkActionTypes('APPLY_FILTERS'),
  ...getThunkActionTypes('ADD_PROJECT_METRIC'),
  ...getThunkActionTypes('BULK_ADD_PROJECT_METRICS'),
  ...getThunkActionTypes('REMOVE_PROJECT_METRIC'),
  ...getThunkActionTypes('BULK_REMOVE_PROJECT_METRICS'),
  ...getThunkActionTypes('CREATE_AND_ADD_METRIC_TO_PROJECT'),
  ...getThunkActionTypes('LOAD_PROJECTS_FROM_AUTOCOMPLETE'),
  ...getThunkActionTypes('LOAD_PROJECTS_FROM_AUTOCOMPLETE_TITLE'),
  ...getThunkActionTypes('TRANSFER_PROJECT_TO_OTHER_ACCOUNT'),
  ...getThunkActionTypes('UPDATE_PROJECT_PERSONAS'),
  ...getThunkActionTypes('UPDATE_PROJECT_LIFECYCLES'),
  ...getThunkActionTypes('FETCH_PROJECT_WITH_DBQL'),
  UPDATE_PROJECT_DEBOUNCED_FULFILLED: 'UPDATE_PROJECT_DEBOUNCED_FULFILLED',
  SET_TASKS: 'SET_TASKS',
  SET_PROJECTS_PARENTS: 'SET_PROJECTS_PARENTS',
  REALTIME_BULK_UPDATE_PROGRESS: 'REALTIME_BULK_UPDATE_PROGRESS',
  CREATE_UNSAVED_PROJECT: 'CREATE_UNSAVED_PROJECT',
  REMOVE_UNSAVED_PROJECT: 'REMOVE_UNSAVED_PROJECT',
  ADD_SELECTED_PARENT_TO_STORE: 'ADD_SELECTED_PARENT_TO_STORE',
};

export const {
  APPLY_FILTERS,
  APPLY_FILTERS_PENDING,
  APPLY_FILTERS_FULFILLED,
  APPLY_FILTERS_REJECTED,

  FETCH_PROJECTS_CHILDREN,
  FETCH_PROJECTS_CHILDREN_PENDING,
  FETCH_PROJECTS_CHILDREN_FULFILLED,
  FETCH_PROJECTS_CHILDREN_REJECTED,

  CREATE_PROJECT,
  CREATE_PROJECT_PENDING,
  CREATE_PROJECT_FULFILLED,
  CREATE_PROJECT_REJECTED,
  CREATE_PROJECT_RESET,

  BULK_CREATE_PROJECTS,
  BULK_CREATE_PROJECTS_PENDING,
  BULK_CREATE_PROJECTS_FULFILLED,
  BULK_CREATE_PROJECTS_REJECTED,

  IMPORT_PROJECTS,
  IMPORT_PROJECTS_PENDING,
  IMPORT_PROJECTS_FULFILLED,
  IMPORT_PROJECTS_REJECTED,

  UPDATE_PROJECT,
  UPDATE_PROJECT_PENDING,
  UPDATE_PROJECT_FULFILLED,
  UPDATE_PROJECT_DEBOUNCED_FULFILLED,
  UPDATE_PROJECT_REJECTED,
  UPDATE_PROJECT_RESET,

  UPDATE_PROJECTS,
  UPDATE_PROJECTS_PENDING,
  UPDATE_PROJECTS_FULFILLED,
  UPDATE_PROJECTS_REJECTED,

  BULK_UPDATE_PROJECTS,
  BULK_UPDATE_PROJECTS_PENDING,
  BULK_UPDATE_PROJECTS_FULFILLED,
  BULK_UPDATE_PROJECTS_REJECTED,

  UNDO_BULK_UPDATE_PROJECTS,
  UNDO_BULK_UPDATE_PROJECTS_PENDING,
  UNDO_BULK_UPDATE_PROJECTS_FULFILLED,
  UNDO_BULK_UPDATE_PROJECTS_REJECTED,

  DELETE_PROJECTS,
  DELETE_PROJECTS_PENDING,
  DELETE_PROJECTS_FULFILLED,
  DELETE_PROJECTS_REJECTED,

  UNDO_DELETE_PROJECTS,
  UNDO_DELETE_PROJECTS_PENDING,
  UNDO_DELETE_PROJECTS_FULFILLED,
  UNDO_DELETE_PROJECTS_REJECTED,

  UPDATE_PROJECT_ROW_ORDER,
  UPDATE_PROJECT_ROW_ORDER_PENDING,
  UPDATE_PROJECT_ROW_ORDER_FULFILLED,
  UPDATE_PROJECT_ROW_ORDER_REJECTED,
  UPDATE_PROJECT_ROW_ORDER_RESET,

  WATCH_PROJECT,
  WATCH_PROJECT_PENDING,
  WATCH_PROJECT_FULFILLED,
  WATCH_PROJECT_REJECTED,

  UNWATCH_PROJECT,
  UNWATCH_PROJECT_PENDING,
  UNWATCH_PROJECT_FULFILLED,
  UNWATCH_PROJECT_REJECTED,

  FETCH_PROJECT_CUSTOMER_REQUESTS,
  FETCH_PROJECT_CUSTOMER_REQUESTS_PENDING,
  FETCH_PROJECT_CUSTOMER_REQUESTS_FULFILLED,
  FETCH_PROJECT_CUSTOMER_REQUESTS_REJECTED,

  GET_PROJECT_COUNTERS_BY_PHASE,
  GET_PROJECT_COUNTERS_BY_PHASE_PENDING,
  GET_PROJECT_COUNTERS_BY_PHASE_FULFILLED,
  GET_PROJECT_COUNTERS_BY_PHASE_REJECTED,

  MERGE_PROJECTS,
  MERGE_PROJECTS_PENDING,
  MERGE_PROJECTS_FULFILLED,
  MERGE_PROJECTS_REJECTED,

  CLONE_PROJECT,
  CLONE_PROJECT_PENDING,
  CLONE_PROJECT_FULFILLED,
  CLONE_PROJECT_REJECTED,

  SET_TASKS,
  SET_PROJECTS_PARENTS,
  REALTIME_BULK_UPDATE_PROGRESS,
  CREATE_UNSAVED_PROJECT,
  REMOVE_UNSAVED_PROJECT,

  ADD_PROJECT_METRIC,
  ADD_PROJECT_METRIC_PENDING,
  ADD_PROJECT_METRIC_FULFILLED,
  ADD_PROJECT_METRIC_REJECTED,

  BULK_ADD_PROJECT_METRICS,
  BULK_ADD_PROJECT_METRICS_PENDING,
  BULK_ADD_PROJECT_METRICS_FULFILLED,
  BULK_ADD_PROJECT_METRICS_REJECTED,

  REMOVE_PROJECT_METRIC,
  REMOVE_PROJECT_METRIC_PENDING,
  REMOVE_PROJECT_METRIC_FULFILLED,
  REMOVE_PROJECT_METRIC_REJECTED,

  BULK_REMOVE_PROJECT_METRICS,
  BULK_REMOVE_PROJECT_METRICS_PENDING,
  BULK_REMOVE_PROJECT_METRICS_FULFILLED,
  BULK_REMOVE_PROJECT_METRICS_REJECTED,

  CREATE_AND_ADD_METRIC_TO_PROJECT,
  CREATE_AND_ADD_METRIC_TO_PROJECT_PENDING,
  CREATE_AND_ADD_METRIC_TO_PROJECT_FULFILLED,
  CREATE_AND_ADD_METRIC_TO_PROJECT_REJECTED,
  ADD_SELECTED_PARENT_TO_STORE,

  LOAD_PROJECTS_FROM_AUTOCOMPLETE,
  LOAD_PROJECTS_FROM_AUTOCOMPLETE_PENDING,
  LOAD_PROJECTS_FROM_AUTOCOMPLETE_FULFILLED,
  LOAD_PROJECTS_FROM_AUTOCOMPLETE_REJECTED,

  LOAD_PROJECTS_FROM_AUTOCOMPLETE_TITLE,
  LOAD_PROJECTS_FROM_AUTOCOMPLETE_TITLE_PENDING,
  LOAD_PROJECTS_FROM_AUTOCOMPLETE_TITLE_FULFILLED,
  LOAD_PROJECTS_FROM_AUTOCOMPLETE_TITLE_REJECTED,

  TRANSFER_PROJECT_TO_OTHER_ACCOUNT,
  TRANSFER_PROJECT_TO_OTHER_ACCOUNT_PENDING,
  TRANSFER_PROJECT_TO_OTHER_ACCOUNT_FULFILLED,
  TRANSFER_PROJECT_TO_OTHER_ACCOUNT_REJECTED,

  UPDATE_PROJECT_PERSONAS,
  UPDATE_PROJECT_PERSONAS_FULFILLED,

  UPDATE_PROJECT_LIFECYCLES,
  UPDATE_PROJECT_LIFECYCLES_FULFILLED,
  FETCH_PROJECT_WITH_DBQL,
  FETCH_PROJECT_WITH_DBQL_FULFILLED,
} = actionTypes;

export default actionTypes;
