import getSystemFieldName from 'utils/getSystemFieldName';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

export const getGroupOptions = (systemFields, hasHierarchy = false, hasMultiLevelPortfolioMetadata, other = {}) => {
  const timeframe = { key: 'timeframe', title: getSystemFieldName('timeframe', systemFields) };

  const options = [
    ...(other?.canView && other.canView(PERMISSION_RESOURCES.objectiveCorp)
      ? [{ key: 'objectiveCorp', title: getSystemFieldName('objectiveCorp', systemFields) }]
      : []),
    { key: 'objective', title: getSystemFieldName('objective', systemFields) },
    { key: 'keyResult', title: getSystemFieldName('keyResult1', systemFields) },
    { key: 'keyResult2', title: getSystemFieldName('keyResult2', systemFields) },
    ...(other?.canView && other.canView(PERMISSION_RESOURCES.roadmapCorp)
      ? [{ key: 'roadmapCorp', title: getSystemFieldName('roadmapCorp', systemFields) }]
      : []),
    { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
    { key: 'product', title: getSystemFieldName('product1', systemFields) },
    ...(other?.canView && other.canView(PERMISSION_RESOURCES.timeframeCorp)
      ? [{ key: 'timeframeCorp', title: getSystemFieldName('timeframeCorp', systemFields) }]
      : []),
    ...(hasMultiLevelPortfolioMetadata
      ? [
          timeframe,
          {
            key: 'timeframe2',
            field: 'timeframe_2_id',
            title: getSystemFieldName('timeframe2', systemFields),
          },
        ]
      : [timeframe]),
    { key: 'theme', title: getSystemFieldName('theme', systemFields) },
    { key: 'phase', title: getSystemFieldName('phase', systemFields) },
    { key: 'category', title: getSystemFieldName('category', systemFields) },
    { key: 'planningStage', title: 'Planning Stage' },
    { key: 'team', title: getSystemFieldName('team', systemFields) },
  ];

  if (hasHierarchy) {
    return [
      ...options,
      { key: 'initiative', title: getSystemFieldName('initiative', systemFields) },
      { key: 'bet', title: getSystemFieldName('bet', systemFields) },
    ];
  }

  return options;
};

export const getSettingsUrl = dataType => {
  switch (dataType) {
    case 'objective':
      return '/settings/objectives';
    case 'keyResult':
      return '/settings/objectives';
    case 'timeframe':
      return '/settings/timeframes';
    case 'roadmap':
      return '/settings/roadmaps';
    case 'product':
      return '/settings/roadmaps';
    case 'phase':
      return '/settings/phases';
    case 'theme':
      return '/settings/themes';
    case 'category':
      return '/settings/categories';
    default:
      return '#';
  }
};

export const getDataTypeTitle = (dataTypeKey, systemFields, hasHierarchy, hasMultiLevelPortfolioMetadata) => {
  const group = getGroupOptions(systemFields, hasHierarchy, hasMultiLevelPortfolioMetadata).find(g => g.key === dataTypeKey);

  return group ? group.title : '';
};

export const getRoadmapIdForProduct = (products, filterId) => {
  const product = products.find(p => p.id === filterId);

  return product?.roadmap_id || undefined;
};

export const dataTypeToPermissionResourceMapper = {
  roadmapCorp: PERMISSION_RESOURCES.roadmapCorp,
  roadmap: PERMISSION_RESOURCES.roadmap,
  product: PERMISSION_RESOURCES.product1,
  objectiveCorp: PERMISSION_RESOURCES.objectiveCorp,
  objective: PERMISSION_RESOURCES.objective,
  keyResult: PERMISSION_RESOURCES.keyResult,
  keyResult2: PERMISSION_RESOURCES.keyResult2,
  timeframeCorp: PERMISSION_RESOURCES.timeframeCorp,
  timeframe: PERMISSION_RESOURCES.timeframe,
  timeframe2: PERMISSION_RESOURCES.timeframe2,
  theme: PERMISSION_RESOURCES.theme,
  phase: PERMISSION_RESOURCES.phase,
  catgeory: PERMISSION_RESOURCES.catgeory,
};
