import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isNil } from 'ramda';

import {
  createJiraTicket,
  linkWithJiraTicket,
  linkWithJiraTicketOutbound,
  syncProjectFromJira,
  syncJiraStories,
} from 'store/integrations';
import { projectActions } from 'store/projects';
import { IDEA_LAYER } from 'store/projects/constants';

const containerHOC = Component => {
  return props => {
    const { integration } = props;
    const [isCreating, setIsCreating] = useState(false);
    const [isLinking, setIsLinking] = useState(false);
    const [errors, setErrors] = useState({ create: [], link: [] });

    /* Updates the project with the current lightbox fields values and creates new epic on JIRA */
    const createJiraEpic = async (jiraProjectKey, jiraIssueType) => {
      const { updateProject, createJiraTicket, showAddNewProject, formData } = props;

      setIsCreating(true);

      const data = await updateProject();

      if (showAddNewProject) showAddNewProject(data.id);

      const payload = {
        ...formData,
        ...data,
      };

      const newTicketData = await createJiraTicket(payload, jiraProjectKey, integration.id, jiraIssueType);

      if (newTicketData.error) {
        // check if it is a BE error - src/utils/promiseTimeout returns a Javascript Error
        if (isNil(newTicketData.error.response)) {
          setIsCreating(false);

          return {};
        }

        const { data } = newTicketData.error.response;

        setErrors({ create: data._data ? data._data.errorMessages : [data.message] });
        setIsCreating(false);

        return {};
      }

      setIsCreating(false);

      return newTicketData;
    };

    /**
     * @function linkJiraEpic
     *
     * Updates the project with the current lightbox fields values and links to existing
     * JIRA epic, if the `linkingIssueType` indicates that it's an epic.
     * Otherwise, it will link to an existing jira initiative.
     *
     * @param  {String} jiraEpicKey      The epic key we're linking to
     * @param  {String} linkingIssueType Whether we're linking an idea or an initiative
     * @return {Promise}
     */
    const linkJiraEpic = async (jiraEpicKey, linkingIssueType) => {
      const { updateProject, linkWithJiraTicket, syncJiraStories, syncProjectFromJira, showAddNewProject } = props;

      setIsLinking(true);

      const data = await updateProject();

      if (showAddNewProject) showAddNewProject(data.id);

      const epicData = {
        ...data,
        issueKey: jiraEpicKey.toUpperCase(),
        project_id: data.id,
        id: data.id,
      };

      let newEpicData = {};

      try {
        epicData.linkingIssueType = linkingIssueType;
        newEpicData = await linkWithJiraTicket(epicData, integration.id);

        if (newEpicData.error) {
          // check if it is a BE error - src/utils/promiseTimeout returns a Javascript Error
          if (isNil(newEpicData.error.response)) {
            setIsLinking(false);

            return {};
          }

          const { data } = newEpicData.error.response;

          setErrors({ link: [data.message] });
          setIsLinking(false);
          return {};
        }

        const syncedData = await syncProjectFromJira(data.id, integration.id);

        await updateProject(
          {
            ...syncedData,
            jira_progress: true,
            jira_auto_sync: true,
          },
          true,
        );

        if (data.layer === IDEA_LAYER) {
          await syncJiraStories(integration.id, [jiraEpicKey.toUpperCase()]);
        }
      } catch (err) {
        console.log(err);
        setIsLinking(false);
        throw err;
      }

      setIsLinking(false);

      return newEpicData;
    };

    const linkJiraEpicOutbound = async (jiraEpicKey, linkingIssueType) => {
      const { updateProject, linkWithJiraTicketOutbound, showAddNewProject } = props;

      setIsLinking(true);

      const data = await updateProject();

      if (showAddNewProject) showAddNewProject(data.id);

      const epicData = {
        ...data,
        issueKey: jiraEpicKey.toUpperCase(),
        project_id: data.id,
        id: data.id,
      };

      let newEpicData = {};

      try {
        epicData.linkingIssueType = linkingIssueType;
        newEpicData = await linkWithJiraTicketOutbound(epicData, integration.id);

        if (newEpicData.error) {
          if (isNil(newEpicData.error.response)) {
            setIsLinking(false);

            return {};
          }

          const { data } = newEpicData.error.response;

          setErrors({ link: [data.message] });
          setIsLinking(false);
          return {};
        }
      } catch (err) {
        console.log(err);
        setIsLinking(false);
        throw err;
      }

      setIsLinking(false);

      return newEpicData;
    };

    const clearJiraCreateErrors = () => {
      setErrors({ ...errors, create: [] });
    };

    const clearJiraLinkErrors = () => {
      setErrors({ ...errors, link: [] });
    };

    useEffect(() => {
      setIsCreating(false);
      setIsLinking(false);
      setErrors({ create: [], link: [] });
    }, [integration]);

    return (
      <Component
        createJiraEpic={createJiraEpic}
        isCreating={isCreating}
        linkJiraEpic={linkJiraEpic}
        linkJiraEpicOutbound={linkJiraEpicOutbound}
        isLinking={isLinking}
        errors={errors}
        clearJiraCreateErrors={clearJiraCreateErrors}
        clearJiraLinkErrors={clearJiraLinkErrors}
        {...props}
      />
    );
  };
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  createJiraTicket,
  linkWithJiraTicket,
  linkWithJiraTicketOutbound,
  syncJiraStories,
  syncProjectFromJira,
  showAddNewProject: projectActions && projectActions.showAddNewProject,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), containerHOC);
