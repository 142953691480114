import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import Step1 from './animationSteps/Step1';
import Step2 from './animationSteps/Step2';
import Step3 from './animationSteps/Step3';

// We'll assume that on average the AI Planner takes 10 seconds to run,
// so the animation steps will be equally split across this length
const DEFAULT_DURATION = 10000;

const ANIMATION_STEPS = [Step1, Step2, Step3];

const TRANSITION_CLASSNAMES = {
  enter: 'fade-enter',
  enterActive: 'fade-enter-active',
  exit: 'fade-exit',
  exitActive: 'fade-exit-active',
};

const LoadingScreen = () => {
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentStep < ANIMATION_STEPS.length) {
        setCurrentStep(prevStep => prevStep + 1);
      } else {
        clearInterval(interval);
      }
    }, DEFAULT_DURATION / ANIMATION_STEPS.length);

    return () => {
      clearInterval(interval);
    };
  }, [currentStep]);

  return (
    <ScreenContainer>
      {ANIMATION_STEPS.map((step, i) => (
        <CSSTransition in={currentStep === i + 1} timeout={500} classNames={TRANSITION_CLASSNAMES} unmountOnExit>
          {step}
        </CSSTransition>
      ))}
    </ScreenContainer>
  );
};

export default LoadingScreen;

const ScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .fade-enter {
    opacity: 0;
    transform: scale(0.85);
  }

  .fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 0.5s ease;
  }

  .fade-exit {
    opacity: 1;
    transform: scale(1);
    position: absolute;
  }

  .fade-exit-active {
    opacity: 0;
    transform: scale(0.85);
    transition: all 0.5s ease;
  }
`;
