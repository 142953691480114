import React from 'react';
import useSystemFields from 'hooks/useSystemFields';

export default Component => {
  return props => {
    const [getSystemFieldName] = useSystemFields();

    return <Component {...props} getSystemFieldName={getSystemFieldName} />;
  };
};
