import moment from 'moment-timezone';

export default (startDate, endDate, mode = 'days') => {
  return Math.abs(moment(endDate).diff(startDate, mode));
  // let diff;

  // if (startDate <= endDate) {
  //   diff = Math.abs(endDate - startDate);
  // } else {
  //   diff = Math.abs(startDate - endDate);
  // }

  // return Math.floor((diff / 1000) / 86400);
};
