/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { ConfirmDialog } from 'components/gridCommon';
import DateRange from 'components/DateRange';
import DateRangeMode from 'components/DateRangeMode';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import LightBox from 'components/LightBox';
import LoadableProjectsAutocomplete from 'containers/LoadableProjectsAutocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import NotesIcon from '@material-ui/icons/Assignment';
import HistoryIcon from '@material-ui/icons/History';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment-timezone';
import omit from 'lodash/omit';
import styled from 'styled-components';

import { endDateChange, startDateChange } from 'containers/ProjectTaskDialog/utils';
import TaskHistory from 'containers/TaskHistory';
import LoadableUsersAutocomplete from 'containers/LoadableUsersAutocomplete';
import ProjectType from 'containers/ProjectType';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import Checkbox from 'design-system/atoms/Checkbox/index';
import ClickableTitle from 'design-system/atoms/ClickableTitle/index';

import { materialColors } from 'design-system/themes/default';
import { removeElemProps } from 'design-system/utils';
import { getUserName } from 'utils';
import formatDateTime from 'utils/dates/formatDateTime';
import getUnroundedFixedDecimalValue from 'utils/getUnroundedFixedDecimalValue';

const taskDefaults = {
  start_date: moment(),
  end_date: moment().addDuration(14, 'days'),
  title: 'New assignment',
  customColor: materialColors.gray,
  details: '',
  type: 'task',
  ownerName: '',
  ownerAllocation: 100,
  priority: '',
  label: '',
  status: 'Open',
  status_color: '',
  progress: 0,
  duration: 14,
  jiraTicket: '',
  project_id: '',
  timeline: 'duration',
  searchLayer: null,
};

const localStateDefault = {
  currentTab: 0,
  isDeleting: false,
  isCloning: false,
  searchLayer: null,
};

export default class TaskDialog extends Component {
  constructor(props) {
    super(props);

    this.state = props.isNew
      ? Object.assign({ localState: localStateDefault }, taskDefaults, props.selectedTask)
      : Object.assign({ localState: localStateDefault }, taskDefaults, props.selectedTask, { $new: false });

    this.getSaveData = this.getSaveData.bind(this);
    this.onChangeTimelineMode = this.onChangeTimelineMode.bind(this);
    this.onChangeCreateAnother = this.onChangeCreateAnother.bind(this);
    this.state.ownerSelected = props.selectedTask && props.selectedTask.ownerName;
    this.state.projectSelected = props.selectedTask && props.selectedTask.project;
  }

  setDateState = data => {
    this.setState({
      // start_date: data.startDate || this.state.start_date,
      start_date: data.startDate,
      end_date: data.endDate || this.state.endDate,
      duration: data.duration || this.state.duration,
    });
  };

  getSaveData = () => {
    return omit(this.state, ['localState', 'projectSelected', 'searchLayer', 'createAnother']);
  };

  getDateState = () => {
    return { ...this.state, startDate: this.state.start_date, endDate: this.state.end_date, duration: this.state.duration };
  };

  startDateChange = value => {
    if (value === null) {
      this.setDateState({ startDate: null });
      return;
    }

    return startDateChange(this.getDateState(), this.setDateState)(value);
  };

  endDateChange = value => {
    return endDateChange(this.getDateState(), this.setDateState)(value);
  };

  durationChange = e => {
    const value = getUnroundedFixedDecimalValue(e.target.value);

    this.setState({
      duration: value,
      end_date: moment(this.state.start_date, 'YYYY/MM/DD').addDuration(value, 'days'),
    });
  };

  onChangeTimelineMode(e) {
    this.setState({ timeline: e.target.value });
  }

  onChangeCreateAnother(e) {
    this.setState({
      createAnother: e.target.checked,
    });
  }

  render() {
    const {
      onSave,
      onClose,
      onDelete,
      onClone,
      hasChildren,
      isNew,
      quickEntryEnabled,
      hasHierarchy,
      getSystemFieldName,
      selectedTask,
      displayLayer,
      hideTasksName,
      orgHasBet,
      openProjectLightbox,
    } = this.props;

    const {
      localState,
      title,
      details,
      duration,
      ownerAllocation,
      start_date, // eslint-disable-line
      end_date, // eslint-disable-line
      project_id, // eslint-disable-line
      timeline,
      projectSelected,
      ownerSelected,
      createAnother,
      searchLayer,
    } = this.state;

    const { project } = selectedTask;

    const projectLayer = searchLayer || (project && project.layer) || displayLayer;

    const _renderTitle = () => {
      if (projectSelected) {
        return (
          <AlignCenter>
            <AlignCenter>
              <ProjectType layer={projectSelected.layer} style={{ height: '14', width: '14', marginRight: '4px' }} />
              <ClickableTitle
                title={projectSelected.title}
                onClickTitle={() => openProjectLightbox(projectSelected.id)}
                maxPercentageWidth={30}
                showSeparator
              />
            </AlignCenter>

            <AlignCenter>
              <Title>{this.props.title}</Title>
            </AlignCenter>
          </AlignCenter>
        );
      }

      return this.props.title;
    };

    return (
      <LightBox
        {...this.props}
        title={_renderTitle()}
        headerTabs={
          <Tabs
            value={localState.currentTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, v) => this.setState({ localState: { ...localState, currentTab: v } })}
          >
            <HeaderTab
              component="div"
              disableTouchRipple
              disableRipple
              label={
                <TabIcon active={localState.currentTab === 0} title="Details">
                  <NotesIcon />
                </TabIcon>
              }
              style={{ alignSelf: 'center' }}
            />
            {/* <Tab label="Dependencies" /> */}
            {quickEntryEnabled && <Tab label="Create Sub-tasks" />}
            <HeaderTab
              component="div"
              disableTouchRipple
              disableRipple
              disabled={isNew}
              label={
                <TabIcon active={localState.currentTab === 1} title="History">
                  <HistoryIcon />
                </TabIcon>
              }
            />
            <HeaderTab
              component="div"
              disableTouchRipple
              disableRipple
              label={
                <TabIcon title="Cancel">
                  <CloseIcon />
                </TabIcon>
              }
              onClick={() => onClose(this.state.id)}
            />
          </Tabs>
        }
      >
        <DialogContent>
          {localState.currentTab === (quickEntryEnabled ? 2 : 1) && <TaskHistory taskId={selectedTask.id} />}

          {localState.currentTab === 0 && (
            <Grid container spacing={16}>
              {hasHierarchy && (
                <Grid item xs={12}>
                  <FormControl required fullWidth margin="dense" style={{ marginBottom: '-10px' }}>
                    <RadioGroup
                      aria-label="Type"
                      name="layer"
                      value={projectLayer}
                      onChange={({ target }) => this.setState({ searchLayer: target.value })}
                      style={{ flexDirection: 'row' }}
                    >
                      <FormControlLabel value="0" control={<Radio disabled={!isNew} />} label={getSystemFieldName('idea')} />
                      <FormControlLabel
                        value="1"
                        control={<Radio disabled={!isNew} />}
                        label={getSystemFieldName('initiative')}
                      />
                      {orgHasBet && (
                        <FormControlLabel value="2" control={<Radio disabled={!isNew} />} label={getSystemFieldName('bet')} />
                      )}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={6}>
                <FormControl required fullWidth margin="dense">
                  <LoadableProjectsAutocomplete
                    name="idea-parent"
                    label={
                      {
                        0: getSystemFieldName('idea'),
                        1: getSystemFieldName('initiative'),
                        2: getSystemFieldName('bet'),
                      }[projectLayer]
                    }
                    placeholder="Type to search"
                    value={projectSelected && projectSelected.title}
                    onValueChange={p => {
                      this.setState({
                        projectSelected: p,
                        project_id: p && p.id,
                      });
                    }}
                    filters={{ layer: projectLayer }}
                  />
                </FormControl>
              </Grid>
              {!hideTasksName && (
                <Grid item xs={6}>
                  <FormControl fullWidth margin="dense">
                    <TextField
                      name="title"
                      label="Assignment Name"
                      value={title}
                      onChange={e => this.setState({ title: e.target.value })}
                    />
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12}>
                <DateRangeMode mode={timeline} onChange={this.onChangeTimelineMode} />
              </Grid>

              <Grid item xs={6}>
                <DateRange
                  startDate={start_date} // eslint-disable-line
                  endDate={end_date} // eslint-disable-line
                  duration={duration}
                  onStartDateChange={this.startDateChange}
                  onDurationChange={this.durationChange}
                  onEndDateChange={this.endDateChange}
                  showDuration
                  showEndDate
                  startLabel="Start date"
                  endLabel="End date"
                  disableDuration={hasChildren || timeline === 'endDate'}
                  disableEndDate={hasChildren || timeline === 'duration'}
                />
              </Grid>

              <Grid item xs={6}>
                <Grid container spacing={8}>
                  <Grid item xs={8}>
                    <FormControl fullWidth margin="dense" style={{ marginTop: 0 }}>
                      <LoadableUsersAutocomplete
                        onValueChange={(v, t, owner) =>
                          owner &&
                          this.setState({
                            ownerSelected: owner?.entity ? getUserName(owner.entity) : null,
                            owner_id: owner?.entity?.id,
                          })
                        }
                        label="Owner"
                        value={ownerSelected}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth margin="dense" style={{ marginTop: 0 }}>
                      <TextField
                        type="number"
                        inputProps={{ min: 0, max: 100 }}
                        label="Allocation (%)"
                        value={ownerAllocation}
                        onChange={e => this.setState({ ownerAllocation: +e.target.value })}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={8} direction="row" alignItems="flex-end">
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="dense">
                      <TextField
                        multiline
                        rows="5"
                        label="Details"
                        value={details}
                        onChange={e => this.setState({ details: e.target.value })}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid container spacing={8}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="dense">
                          <TextField label="Updated by" value={getUserName(this.state.updatedBy)} disabled />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="dense">
                          <TextField label="Updated at" value={formatDateTime(this.state.updated_at)} disabled />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>

        <DialogActions style={{ position: 'relative' }}>
          {!isNew && (
            <div>
              <Button
                color="secondary"
                onClick={() => this.setState({ localState: { ...localState, isDeleting: true } })}
                style={{ position: 'absolute', bottom: 0, left: 5 }}
              >
                delete
              </Button>
              <Button
                color="primary"
                style={{ position: 'absolute', bottom: 0, left: 100 }}
                onClick={() => {
                  this.setState({ localState: { ...localState, isCloning: true } });
                  // need to delay to change the current state
                  setTimeout(() => onClone(this.getSaveData(), false), 100);
                }}
              >
                clone
              </Button>
            </div>
          )}
          {isNew && localState.isCloning && (
            <Button
              color="primary"
              style={{ position: 'absolute', bottom: 0, left: 5 }}
              onClick={() => {
                this.setState({ localState: { ...localState, isCloning: true } });
                // need to delay to change the current state
                setTimeout(() => onClone(this.getSaveData(), true), 100);
              }}
            >
              save &nbsp;<Small>and</Small>&nbsp; clone another
            </Button>
          )}
          {isNew && !localState.isCloning && (
            <FormControlLabel
              label="Create another"
              aria-label="createAnother"
              control={<Checkbox />}
              disabled={!project_id} // eslint-disable-line
              checked={createAnother}
              value="createAnother"
              onChange={this.onChangeCreateAnother}
            />
          )}
          <Button
            aria-label="save"
            color="primary"
            onClick={() => onSave(this.getSaveData(), createAnother, true)}
            disabled={!project_id || !start_date || !end_date} // eslint-disable-line
          >
            save
          </Button>
          <Button onClick={() => onClose(this.state.id)}>cancel</Button>
        </DialogActions>

        <ConfirmDialog
          isOpen={localState.isDeleting}
          row={omit(this.state, 'localState')}
          onCancel={() => this.setState({ localState: { ...localState, isDeleting: false } })}
          onDelete={() => {
            this.setState({ localState: { ...localState, isDeleting: false } }, () => onDelete(omit(this.state, 'localState')));
          }}
          title="Delete Assignment"
          text="Are you sure you want to delete this assignment?"
        />
      </LightBox>
    );
  }
}

const Small = styled.small`
  text-transform: lowercase;
`;

const TabIcon = styled(removeElemProps(ButtonIcon, ['active']))`
  &&&&& {
    color: #6b6b6b;

    ${({ active }) =>
      active &&
      `
      color: #009dff;
    `}

    svg {
      fill: #6b6b6b;

      ${({ active }) =>
        active &&
        `
          fill: #009dff;
        `}
    }
  }
`;

const HeaderTab = styled(Tab)`
  &&&& {
    min-height: 0;
    font-weight: 400;
    font-size: 0.875rem;
    min-width: 0;

    > span > span {
      padding: 0 2px;
    }

    svg {
      font-size: 20px;
    }
  }
`;

const AlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.2;
`;
