/* eslint-disable camelcase */
// External dependencies
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { List } from 'immutable';
import { isNil, not, pipe } from 'ramda';

// Dragonboat depencies
// DoD v2
import getMetadataTitle, { getOrgMetadataForCorpLevelTitles, TIMEFRAME_CORP } from '../utils/getMetadataCorpTitleBasedOnRoadmap';

import { filterActiveItens, makeFilterByLevel } from 'utils';

import { METADATA_LEVELS } from 'constants/common';

const isNotNil = pipe(isNil, not);

const filterArchivedTimeframesRecursively = timeframes =>
  timeframes.filter(filterActiveItens).map(timeframe => ({
    ...timeframe,
    ...(timeframe?.children ? { children: filterArchivedTimeframesRecursively(timeframe.children) } : {}),
  }));

export const getState = createSelector(
  state => {
    return state.timeframes.has('rows') ? state.timeframes.get('rows') : new List();
  },
  getOrgMetadataForCorpLevelTitles,
  (timeframes, orgMetadataForCorpLevelTitle) => {
    return timeframes.toJS().map(timeframe => ({
      ...timeframe,
      title: getMetadataTitle(timeframe, TIMEFRAME_CORP, orgMetadataForCorpLevelTitle),
    }));
  },
);

export const getAllTimeframes = createSelector(
  state => getState(state),
  timeframes => timeframes,
);

export const makeGetTimeframes = (defaultLevel = METADATA_LEVELS.LEVEL_1) =>
  createCachedSelector(
    getState,
    (_, showArchived) => showArchived,
    (_, __, level) => (isNotNil(level) ? level : defaultLevel),
    (state, showArchived, level) => {
      const filteredByLevel = state.filter(makeFilterByLevel(level));

      if (showArchived) {
        return filteredByLevel;
      }

      return filterArchivedTimeframesRecursively(filteredByLevel);
    },
  )((_, showArchived, level) => `timeframes-${showArchived}-${level}`);

export const getTimeframes = makeGetTimeframes();

export const getTimeframesLevel2 = makeGetTimeframes(METADATA_LEVELS.LEVEL_2);

export const getTimeframesLevelCorp = makeGetTimeframes(METADATA_LEVELS.LEVEL_CORP);

export const makeGetNormalizedTimeframes = allTimeframes => {
  const _localGetTimeframes = allTimeframes ? getAllTimeframes : makeGetTimeframes();

  return createSelector(
    state => _localGetTimeframes(state),
    state =>
      state.reduce((objs, obj) => {
        objs[obj.id] = obj;
        return objs;
      }, {}),
  );
};

export const getNormalizedTimeframesLevelCorp = createSelector(
  state => getTimeframesLevelCorp(state),
  state =>
    state.reduce((objs, obj) => {
      objs[obj.id] = obj;
      return objs;
    }, {}),
);

export const getNormalizedTimeframes = makeGetNormalizedTimeframes();

export const getAllNormalizedTimeframes = makeGetNormalizedTimeframes(true);
