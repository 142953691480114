import React from 'react';
import styled from 'styled-components';
import theme from 'design-system/theme';
import TextDeprecated from 'design-system/atoms/TextDeprecated';
import ButtonV2 from 'design-system/atoms/ButtonV2';
import lightenDarkenColor from 'design-system/utils/lightenDarkenColor';

const DEFAULT_TEXT = `View is a personalized layout with filtered data for you to use or share with others. \
  Favorite a view created by your colleagues.
`;
const DEFAULT_BUTTON_TEXT = 'Explore Views';

export default ({ text = DEFAULT_TEXT, buttonText = DEFAULT_BUTTON_TEXT, onClick, ...props }) => (
  <Wrapper {...props}>
    <StyledText color={theme.palette.newLayout.text.siroccoGrey}>{text}</StyledText>
    <StyledButton
      id="views-template-button"
      color={theme.palette.background.white}
      background={theme.palette.background.primary}
      hover={{
        background: lightenDarkenColor(theme.palette.background.primary, -20),
      }}
      onClick={onClick}
    >
      {buttonText}
    </StyledButton>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledText = styled(TextDeprecated)`
  max-width: 350px;
  text-align: center;

  &&&& {
    line-height: 22px;
    white-space: normal;
  }

  a {
    color: ${({ theme }) => theme.palette.newLayout.text.primary};
  }
`;

const StyledButton = styled(ButtonV2)`
  &&&&& {
    font-size: 13px;
    font-weight: normal;
    padding: 6px 20px;
    margin-top: 13px;
    border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;
  }
`;
