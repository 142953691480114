import React from 'react';
import styled, { css } from 'styled-components';

import useDeviceType from 'design-system/hooks/useDeviceType';
import { PAGE_SIDE_MARGIN, MOBILE_PAGE_SIDE_MARGIN } from 'constants/common';
import { spacing } from 'design-system/theme';

export default ({
  children,
  toolbarComponent,
  background,
  displayBorderOnToolbar,
  withoutContentMargin,
  contentStyles,
  wrapperAttributes,
}) => {
  const { isMobile } = useDeviceType();

  const sideMargin = isMobile ? MOBILE_PAGE_SIDE_MARGIN : PAGE_SIDE_MARGIN;

  return (
    <Wrapper {...wrapperAttributes}>
      {toolbarComponent ? <Toolbar withBorder={displayBorderOnToolbar}>{toolbarComponent}</Toolbar> : null}
      <Content background={background} withoutMargin={withoutContentMargin} sideMargin={sideMargin} contentStyles={contentStyles}>
        {children}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Toolbar = styled.div`
  padding: ${spacing(0.5)}px ${PAGE_SIDE_MARGIN}px;

  ${({ withBorder, theme }) =>
    withBorder &&
    css`
      border-bottom: 1px solid ${theme.palette.border.secondary};
    `}
`;

const Content = styled.div`
  ${({ withoutMargin, sideMargin }) =>
    !withoutMargin &&
    css`
      padding: 0 ${sideMargin}px;
    `}

  ${({ background }) =>
    background &&
    css`
      background: ${background};
    `}

  ${({ contentStyles }) => contentStyles};
`;
