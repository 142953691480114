import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import Autocomplete from 'design-system/atoms/Autocomplete/index';

import Text from 'design-system/atoms/Text';
import theme, { spacing } from 'design-system/theme';

const getCompatibleIntegrationFields = (fieldData, integrationFields) =>
  integrationFields.filter(field =>
    fieldData.allowedDataTypes ? !!fieldData.allowedDataTypes?.includes(field.dragonboatSchemaType) : true,
  );

const FieldRow = ({ fieldData, integrationSuggestions = [], onChange, disableDefaultFields = [] }) => {
  const isFieldRowReadOnly = disableDefaultFields.includes(fieldData.key);

  const compatibleIntegrationSuggestions = getCompatibleIntegrationFields(fieldData, integrationSuggestions);

  return (
    <Grid container key={fieldData.key} style={{ alignItems: 'center' }}>
      <Grid item xs={5} style={{ textAlign: 'right' }}>
        <div>
          <Text as="label" style={{ fontSize: '1rem', fontWeight: theme.typography.fontWeightRegular }}>
            {fieldData.title}
          </Text>
        </div>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={6}>
        <DropdownWrapper>
          <Autocomplete
            suggestions={compatibleIntegrationSuggestions}
            value={fieldData?.integration?.title}
            onValueChange={(e, value) =>
              onChange({ ...fieldData, updateFromIntegrationToDragonboat: Boolean(value), integration: value })
            }
            disabled={isFieldRowReadOnly}
          />
        </DropdownWrapper>
      </Grid>
    </Grid>
  );
};

const DropdownWrapper = styled.div`
  margin-right: ${spacing(2)}px;
`;

export default FieldRow;
