// Copied and adapted/simplified from utils/dates/formatDate

import moment from 'moment-timezone';

const formatDate = value => {
  if (value && moment(value).isValid()) {
    return moment.tz(value, 'UTC').format('YYYY/MM/DD');
  }

  return '';
};

export default formatDate;
