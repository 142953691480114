import { defaultTo, has, isEmpty, propEq } from 'ramda';

import theme from 'design-system/theme';
import { sortByRankAndTitle } from 'store/projects/helpers/groupSelectors';

const MILESTONE_TOP_LANE = 'topLane';
const isMilestone = propEq('type', 'milestone');
const isUndefinedGroup = propEq('title', 'Undefined');
const defaultToMercuyColor = defaultTo(theme.palette.border.mercury);
const isLeafItem = has('type');

const getMilestones = allProjects => allProjects?.filter(isMilestone) ?? [];

const getFilteredItems = (items, displayMilestone, displayMilestoneOn) =>
  items.reduce((filtered, item) => {
    const shouldAddItem = !displayMilestone || displayMilestoneOn !== MILESTONE_TOP_LANE || !isMilestone(item);

    if (shouldAddItem) {
      return [...filtered, item];
    }

    return [...filtered];
  }, []);

const getFilteredGroups = (
  laneId = null,
  childGroups = [],
  secondGroupTypeKey,
  hideEmptyLane,
  shouldAddUndefinedGroup,
  displayMilestone,
  displayMilestoneOn,
) => {
  return childGroups.reduce((acc, group) => {
    const items = getFilteredItems(group?.elements, displayMilestone, displayMilestoneOn);

    if (isEmpty(items) && hideEmptyLane) {
      return acc;
    }

    const isUndefined = isUndefinedGroup(group);

    if (isUndefined && !shouldAddUndefinedGroup) {
      return acc;
    }

    return [...acc, convertProjectGroupToTimelineGroup(isUndefined, group, items, secondGroupTypeKey, laneId)];
  }, []);
};

const convertProjectGroupsToTimelineGroups = (
  projectGroups,
  displayMilestone,
  displayMilestoneOn,
  hideEmptyLane,
  shouldAddUndefinedGroup,
  firstGroupTypeKey,
  secondGroupTypeKey,
) => {
  const hasMultiGrouping = !!secondGroupTypeKey;

  if (hasMultiGrouping) {
    return !isEmpty(projectGroups)
      ? projectGroups
          .reduce((acc, group) => {
            const leafGroups = getFilteredGroups(
              group.id,
              group?.elements ?? [],
              secondGroupTypeKey,
              hideEmptyLane,
              shouldAddUndefinedGroup,
              displayMilestone,
              displayMilestoneOn,
            );

            if (isEmpty(leafGroups) && hideEmptyLane) {
              return acc;
            }

            const isUndefined = isUndefinedGroup(group);

            if (isUndefined && (!shouldAddUndefinedGroup || ['planningStage'].includes(firstGroupTypeKey))) {
              return acc;
            }

            return [...acc, convertProjectGroupToTimelineGroup(isUndefined, group, leafGroups, firstGroupTypeKey)];
          }, [])
          .sort(sortByRankAndTitle)
      : [];
  }

  return !isEmpty(projectGroups)
    ? projectGroups
        .reduce((acc, group) => {
          const filteredItems = getFilteredItems(group?.elements ?? [], displayMilestone, displayMilestoneOn);

          if (isEmpty(filteredItems) && hideEmptyLane) {
            return acc;
          }

          const isUndefined = isUndefinedGroup(group);

          if (isUndefined && !shouldAddUndefinedGroup) {
            return acc;
          }

          return [...acc, convertProjectGroupToTimelineGroup(isUndefined, group, filteredItems, firstGroupTypeKey)];
        }, [])
        .sort(sortByRankAndTitle)
    : [];
};

const getKey = (isUndefined, laneId, groupTypeKey, groupData) => {
  if (isUndefined) {
    return laneId ? `${laneId}-null` : 'N/A';
  }

  return laneId ? `${laneId}-${groupData?.id ?? null}` : `${groupTypeKey}-${groupData.id}`;
};

const convertProjectGroupToTimelineGroup = (isUndefined, group, items, groupTypeKey, laneId) => {
  const groupData = group?.groupData?.[groupTypeKey];

  const id = isUndefined ? null : groupData.id;
  const key = getKey(isUndefined, laneId, groupTypeKey, groupData);
  const title = isUndefined ? 'Undefined' : group.title;

  const timelineGroup = {
    id,
    key,
    title,
    rank: group?.rank,
    color: defaultToMercuyColor(groupData.color),
    meta: {
      type: groupTypeKey,
    },
  };

  const areItems = items.some(isLeafItem);

  if (areItems) {
    return { ...timelineGroup, items };
  }

  if (!isEmpty(items)) {
    return { ...timelineGroup, groups: items };
  }

  return timelineGroup;
};

const getMilestoneTopGroup = (hasMultiGrouping, selectedGroupKeys, allGroupsProjects) =>
  hasMultiGrouping
    ? {
        id: 'milestoneTopLane-lane',
        key: 'milestoneTopLane',
        title: 'Milestones',
        color: defaultToMercuyColor(null),
        groups: [
          {
            id: 'milestoneTopLane-group',
            key: 'undefined-undefined',
            title: 'Milestone',
            color: defaultToMercuyColor(null),
            items: getMilestones(allGroupsProjects),
            meta: {
              type: selectedGroupKeys[1],
            },
          },
        ],
        meta: {
          type: selectedGroupKeys[0],
        },
      }
    : {
        id: 'milestoneTopLane-lane',
        key: 'milestoneTopLane',
        title: 'Milestones',
        color: defaultToMercuyColor(null),
        items: getMilestones(allGroupsProjects),
        meta: {
          type: selectedGroupKeys[0],
        },
      };

export {
  isMilestone,
  getFilteredItems,
  getFilteredGroups,
  convertProjectGroupsToTimelineGroups,
  convertProjectGroupToTimelineGroup,
  getMilestoneTopGroup,
};
