import moment from 'moment-timezone';
import reduceReducers from 'reduce-reducers';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  ADD_ORGANIZATION_ACCESS_CONTROL,
  REMOVE_ORGANIZATION_ACCESS_CONTROL,
  ADD_ORGANIZATION_ACCESS_CONTROL_FULFILLED,
  REMOVE_ORGANIZATION_ACCESS_CONTROL_FULFILLED,
  ADD_PROJECT_READERS,
  ADD_PROJECT_READERS_FULFILLED,
  FETCH_ORGANIZATION_ACCESS_CONTROL,
  FETCH_ORGANIZATION_ACCESS_CONTROL_FULFILLED,
  FETCH_PROJECT_READERS,
  FETCH_PROJECT_READERS_FULFILLED,
  REMOVE_PROJECT_READER,
  REMOVE_PROJECT_READER_FULFILLED,
  CREATE_ORGANIZATION_READER_TOKEN_FULFILLED,
} from './types';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  FETCH_ORGANIZATION_ACCESS_CONTROL,
  ADD_ORGANIZATION_ACCESS_CONTROL,
  REMOVE_ORGANIZATION_ACCESS_CONTROL,
  FETCH_PROJECT_READERS,
  ADD_PROJECT_READERS,
  REMOVE_PROJECT_READER,
]);

const initialState = {
  ...thunksInitialState,
  organizationAccessControl: [],
  projectReaders: {},
  readerTokens: {},
};

const accessControlReducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case FETCH_ORGANIZATION_ACCESS_CONTROL_FULFILLED:
      return {
        ...state,
        organizationAccessControl: payload.data,
      };
    case ADD_ORGANIZATION_ACCESS_CONTROL_FULFILLED:
      return {
        ...state,
        organizationAccessControl: [...state.organizationAccessControl, payload.data],
      };
    case REMOVE_ORGANIZATION_ACCESS_CONTROL_FULFILLED:
      return {
        ...state,
        organizationAccessControl: state.organizationAccessControl.filter(
          access => access.child_organization_id !== meta.childOrganizationId,
        ),
      };
    case FETCH_PROJECT_READERS_FULFILLED:
      return {
        ...state,
        projectReaders: {
          ...state.projectReaders,
          [meta.projectId]: payload.data,
        },
      };
    case ADD_PROJECT_READERS_FULFILLED:
      return {
        ...state,
        projectReaders: {
          ...state.projectReaders,
          [meta.projectId]: [...state.projectReaders[meta.projectId], ...payload.data],
        },
      };
    case REMOVE_PROJECT_READER_FULFILLED:
      return {
        ...state,
        projectReaders: {
          ...state.projectReaders,
          [meta.projectId]: state.projectReaders[meta.projectId].filter(
            reader => reader.organization_id !== meta.childOrganizationId,
          ),
        },
      };
    case CREATE_ORGANIZATION_READER_TOKEN_FULFILLED:
      return {
        ...state,
        readerTokens: {
          ...state.readerTokens,
          [meta.childOrganizationId]: { ...payload.data, expiresIn: moment().add(1, 'days') },
        },
      };
    default:
      return state;
  }
};

const reducer = reduceReducers(initialState, accessControlReducer, ...thunksReducers);

export { initialState };

export default reducer;
