import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty, trim, isNil, either } from 'ramda';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { INTEGRATIONS_KEYS, INTEGRATIONS_NAMES } from 'constants/integrations';

const isNilOrEmpty = either(isNil, isEmpty);

const CreateOrgIntegration = ({ onClickNext }) => {
  const [channelName, setChannelName] = useState('');
  const [webhookUrl, setWebhookUrl] = useState('');

  const isDisabled = useMemo(() => {
    if (isNilOrEmpty(channelName) || isNilOrEmpty(webhookUrl)) {
      return true;
    }
  }, [channelName, webhookUrl]);

  const onChangeWebhookUrlHandler = useCallback(({ target }) => {
    const inputValue = trim(target?.value);

    setWebhookUrl(inputValue);
  }, []);

  const onChangeChannelNameHandler = useCallback(({ target }) => {
    const inputValue = target?.value;

    setChannelName(inputValue);
  }, []);

  const onClickNextHandler = useCallback(
    async (ev) => {
      ev.preventDefault();

      onClickNext({ channelName: trim(channelName), webhookUrl });
    },
    [channelName, webhookUrl],
  );

  return (
    <Wrapper>
      <IntegrationTextField
        label={`Insert your ${INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.microsoftTeams]} Channel name`}
        value={channelName}
        onChange={onChangeChannelNameHandler}
        margin="normal"
        id="channel-name"
        fullwidth
      />
      <IntegrationTextField
        label="Insert your incoming webhook URL"
        value={webhookUrl}
        onChange={onChangeWebhookUrlHandler}
        margin="normal"
        id="webhook"
        fullwidth
      />
      <NextButton type="submit" color="primary" disabled={isDisabled} onClick={onClickNextHandler}>
        Next
      </NextButton>
    </Wrapper>
  );
};

CreateOrgIntegration.propTypes = {
  onClickNext: PropTypes.func.isRequired,
};

const IntegrationTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
`;

const NextButton = styled(Button)`
  &&&& {
    margin-left: 10px;
  }
`;

const Wrapper = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  ${NextButton} {
    width: 100px;
    height: 40px;
    align-self: flex-end;
  }
`;

export default CreateOrgIntegration;
