import { pluck, pick, keys } from 'ramda';
import moment from 'moment-timezone';

import { FILTERS_FUNCTIONS } from 'design-system/constants';
import LoadableUsersMultiSelect from 'containers/LoadableMultiSelect/LoadableUsersMultiSelect';

const mapAllToId = pluck('id');

const START_DATE_FIELD = { id: 'startDate', type: 'date', label: 'Start Date' };
const END_DATE_FIELD = { id: 'endDate', type: 'date', label: 'End Date' };
const OWNER_FIELD = { id: 'owners', type: 'option', label: 'Owner', inputComponent: LoadableUsersMultiSelect };
const ROADMAP_FIELD = { id: 'roadmaps', type: 'option', labelKey: 'roadmap' };
const PRODUCT_FIELD = { id: 'products', type: 'option', labelKey: 'product1' };
const PRODUCT_2_FIELD = { id: 'products2', type: 'option', labelKey: 'product2' };
const STATUS_FIELD = { id: 'status', type: 'option', label: 'Status', hideRemoveButton: true };
const TITLE_FIELD = { id: 'title', type: 'text', label: 'Title' };
const LEVEL_FIELD = { id: 'level', type: 'singleOption', label: 'Level', hideRemoveButton: true };

const AVAILABLE_FIELDS = [
  TITLE_FIELD,
  START_DATE_FIELD,
  END_DATE_FIELD,
  OWNER_FIELD,
  ROADMAP_FIELD,
  PRODUCT_FIELD,
  PRODUCT_2_FIELD,
  STATUS_FIELD,
  LEVEL_FIELD,
];

const FUNCTIONS_BY_FIELD_TYPE = {
  option: [FILTERS_FUNCTIONS.in, FILTERS_FUNCTIONS.notIn, FILTERS_FUNCTIONS.isEmpty, FILTERS_FUNCTIONS.isNotEmpty],
  date: [
    FILTERS_FUNCTIONS.after,
    FILTERS_FUNCTIONS.before,
    FILTERS_FUNCTIONS.between,
    FILTERS_FUNCTIONS.lastWeek,
    FILTERS_FUNCTIONS.lastMonth,
    FILTERS_FUNCTIONS.lastQuarter,
    FILTERS_FUNCTIONS.lastYear,
    FILTERS_FUNCTIONS.thisWeek,
    FILTERS_FUNCTIONS.thisMonth,
    FILTERS_FUNCTIONS.thisQuarter,
    FILTERS_FUNCTIONS.thisYear,
    FILTERS_FUNCTIONS.nextWeek,
    FILTERS_FUNCTIONS.nextMonth,
    FILTERS_FUNCTIONS.nextQuarter,
    FILTERS_FUNCTIONS.nextYear,
    FILTERS_FUNCTIONS.today,
    FILTERS_FUNCTIONS.isEmpty,
  ],
  checkbox: [FILTERS_FUNCTIONS.equals],
  text: [FILTERS_FUNCTIONS.in, FILTERS_FUNCTIONS.notIn],
};

const FUNCTIONS_BY_FIELD_ID = {
  [TITLE_FIELD.id]: [FILTERS_FUNCTIONS.in, FILTERS_FUNCTIONS.notIn],
  [ROADMAP_FIELD.id]: [FILTERS_FUNCTIONS.in],
  [PRODUCT_FIELD.id]: [FILTERS_FUNCTIONS.in],
  [PRODUCT_2_FIELD.id]: [FILTERS_FUNCTIONS.in],
  [STATUS_FIELD.id]: [FILTERS_FUNCTIONS.in],
  [LEVEL_FIELD.id]: [FILTERS_FUNCTIONS.equals],
};

/**
 * @function getAvailableFields
 *
 * Returns all available fields to be used on the goals advances search
 *
 * @return {Array}
 */
const getAvailableFields = (getSystemFieldName, hasMetadataRoadmaps) => {
  const roadmapFields = [ROADMAP_FIELD, PRODUCT_FIELD];

  return AVAILABLE_FIELDS.reduce((fields, field) => {
    if (!hasMetadataRoadmaps && roadmapFields.some(f => f.id === field.id)) {
      return fields;
    }

    let { label } = field;

    if (field.labelKey && getSystemFieldName) {
      label = getSystemFieldName(field.labelKey);
    }

    fields.push({
      ...field,
      label,
    });

    return fields;
  }, []);
};

/**
 * @function getFieldFunctions
 *
 * Returns the list of function available on some field
 *
 * @param  {Object} field
 * @return {Array}
 */
const getFieldFunctions = field => {
  if (!field?.type) {
    return [];
  }

  if (keys(FUNCTIONS_BY_FIELD_ID).includes(field?.id)) {
    return FUNCTIONS_BY_FIELD_ID[field?.id];
  }

  return FUNCTIONS_BY_FIELD_TYPE[field.type];
};

/**
 * @function parseGoalModeFilters
 *
 * Function to parse the goal mode filters to global format
 *
 * @param  {Object} filters
 * @return {Object}
 */
const parseGoalModeFilters = filters => {
  const availableFieldsKeys = mapAllToId(AVAILABLE_FIELDS);

  return {
    fields: {
      ...pick(availableFieldsKeys, filters),
    },
    op: filters.op,
    aplliedAt: moment().format(),
    quickFilters: null,
  };
};

export {
  AVAILABLE_FIELDS,
  FUNCTIONS_BY_FIELD_ID,
  FUNCTIONS_BY_FIELD_TYPE,
  getAvailableFields,
  getFieldFunctions,
  parseGoalModeFilters,
};
