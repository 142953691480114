import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  isUpdateProjectsMappedFieldsLoading as isUpdateProjectsMappedFieldsLoadingSelector,
  updateProjectsMappedFields as updateProjectsMappedFieldsAction,
} from 'store/integrations';

const useUpdateProjectsMappedFields = (integrationType, orgIntegrationId) => {
  const dispatch = useDispatch();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isUpdateInProgressDialogOpen, setIsUpdateInProgressDialogOpen] = useState(false);

  const onOpenConfirmDialog = useCallback(() => setIsConfirmDialogOpen(true), []);
  const onCloseConfirmDialog = useCallback(() => setIsConfirmDialogOpen(false), []);

  const onCloseUpdateInProgressDialog = useCallback(() => setIsUpdateInProgressDialogOpen(false), []);

  const isLoading = useSelector(isUpdateProjectsMappedFieldsLoadingSelector);

  const onConfirmHandler = useCallback(() => {
    if (isLoading) {
      return;
    }

    setIsConfirmDialogOpen(false);
    setIsUpdateInProgressDialogOpen(true);

    dispatch(updateProjectsMappedFieldsAction(integrationType, orgIntegrationId));
  }, [integrationType, orgIntegrationId]);

  return {
    isLoading,
    isConfirmDialogOpen,
    isUpdateInProgressDialogOpen,
    onOpenConfirmDialog,
    onCloseConfirmDialog,
    onCloseUpdateInProgressDialog,
    onConfirmHandler,
  };
};

export default useUpdateProjectsMappedFields;
