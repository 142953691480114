import React, { useState } from 'react';
import styled from 'styled-components';
import Iframe from 'react-iframe';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ArrowForward from '@material-ui/icons/ArrowForward';
import InputTextField from 'design-system/atoms/InputTextField/index';
import Button from 'design-system/atoms/Button/index';

import { materialColors } from 'design-system/themes/default';
import AuthErrorMessage from 'components/AuthErrorMessage';
import AzureLoginButton from '../AzureLoginButton';
import OktaLoginButton from '../OktaLoginButton';

export default ({
  email,
  password,
  useOkta,
  useAzure,
  useGoogle,
  useEmailPassword,
  organization,
  autologin,
  handleEmailValue,
  handlePasswordValue,
  handleLogin,
  googleErrorMessage,
  errorMessage,
  oktaErrorMessage,
  azureButtonClicked,
  oktaButtonClicked,
}) => {
  const usingSSO = useGoogle || useOkta || useAzure;
  const showSeparator = usingSSO && useEmailPassword;

  const [googleSSOHasLoaded, setGoogleSSOHasLoaded] = useState(false);
  const isLoadingGoogle = useGoogle && !googleSSOHasLoaded;

  const showInactiveScreen = [errorMessage, googleErrorMessage, oktaErrorMessage].some(e => {
    return e === 'Account inactive';
  });

  return (
    <Wrapper style={{ visibility: isLoadingGoogle ? 'hidden' : 'visible' }}>
      <Grid container direction="column" alignItems="center" spacing={0}>
        <Grid item xs={12}>
          <StyledTitle>
            Log in to {organization.title && organization.title}
            {!organization.title && organization.slug}
          </StyledTitle>
        </Grid>
        <Grid item xs={12}>
          <StyledSubTitle>{organization.host}</StyledSubTitle>
        </Grid>
        {showInactiveScreen && (
          <Inactive>
            <Typography variant="h5">Your account is no longer active.</Typography>
            <br />
            <Typography variant="h5" component="body1">
              Please <a href="mailto:help@dragonboat.io">contact us</a> if you&apos;d like to re-activate it.
            </Typography>
          </Inactive>
        )}
        {!showInactiveScreen && (
          <FormWrapper item xs={12} container spacing={16}>
            {useOkta && (
              <Grid item xs={12}>
                <OktaLoginButton handleClick={oktaButtonClicked} autologin={autologin} />
              </Grid>
            )}
            {useAzure && (
              <Grid item xs={12}>
                <AzureLoginButton handleClick={azureButtonClicked} />
              </Grid>
            )}
            {useGoogle && (
              <Grid item xs={12} data-testid="google-sso">
                <SSOLoginGoogle
                  onLoad={() => setGoogleSSOHasLoaded(true)}
                  title="Google Button"
                  url={`${process.env.REACT_APP_PUBLIC_URL}/google_button`}
                  height="46px"
                  width="100%"
                  id="myId"
                  display="initial"
                  position="relative"
                  allowFullScreen
                />
                {googleErrorMessage && <AuthErrorMessage errorMessage={googleErrorMessage} />}
              </Grid>
            )}
            {showSeparator && (
              <Grid item xs>
                <StyledSeparator data-testid="options-separator">
                  <div />
                  <div>or</div>
                  <div />
                </StyledSeparator>
              </Grid>
            )}
            {useEmailPassword && (
              <>
                <Grid item xs={12}>
                  <InputTextField
                    value={email}
                    multiline
                    variant="outlined"
                    label="Username or Email"
                    placeholder="Username or Email"
                    handleValue={handleEmailValue}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputTextField
                    value={password}
                    multiline
                    variant="outlined"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    handleValue={handlePasswordValue}
                  />
                </Grid>
                <Grid item xs={12}>
                  {errorMessage && <AuthErrorMessage errorMessage={errorMessage} />}
                </Grid>
                <Grid item xs={12}>
                  <StyledLoginButton background={materialColors.mediumPurple} variant="contained" onClick={handleLogin}>
                    Log in
                  </StyledLoginButton>
                </Grid>
                <Grid item xs={12}>
                  <StyledRequestButton to="/request_access">
                    <Button background="#DEF3F4" variant="contained">
                      <span>Request access to this workspace</span>
                      <StyledArrowForward />
                    </Button>
                  </StyledRequestButton>
                </Grid>
                <StyledFooter item xs={12} container spacing={8}>
                  <Grid item xs={12}>
                    <StyledFooterSpan>
                      Create or recover your password? <Link to="/reset_password">Click here</Link>
                    </StyledFooterSpan>
                  </Grid>
                  <Grid item xs={12}>
                    <StyledFooterSpan>
                      Don’t have a dragonboat account? <a href={`${process.env.REACT_APP_PUBLIC_URL}/signup`}>Signup here</a>
                    </StyledFooterSpan>
                  </Grid>
                </StyledFooter>
              </>
            )}
          </FormWrapper>
        )}
      </Grid>
    </Wrapper>
  );
};

const Inactive = styled.div`
  &&&& {
    text-align: center;

    a {
      color: #3461ac;
    }
  }
`;

const FormWrapper = styled(Grid)`
  &&&& {
    max-width: 360px;
  }
`;

const Wrapper = styled.div`
  max-width: 600px;

  @media screen and (min-height: 700px) {
    margin-top: -5rem;
  }
`;

const StyledTitle = styled.h1`
  color: #3461ac;
  font-size: 2.25rem; // -> 36px -> with :root font-size of 16px;
  font-weight: bold;
  margin: 0 0 1.813rem 0;
`;

const StyledSubTitle = styled.h5`
  color: #131c23;
  font-size: 1.125rem;
  line-height: 1.2rem;
  font-weight: 400;
  margin: 0 0 1.813rem 0;
`;

const SSOLoginGoogle = styled(Iframe)`
  &&&& {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.09);
    border-radius: ${props => props.theme.shape.borderRadiusSmall}px;
    overflow: hidden;

    html {
      overflow: hidden;
    }
  }
`;

const StyledSeparator = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;

    div:first-child,
    div:last-child {
      width: 40%;
      height: 0;
      border: #dddddd solid 1px;
    }

    div:nth-child(2) {
      width: 20%;
      text-align: center;
    }
  }
`;

const StyledLoginButton = styled(Button)`
  &&&& {
    color: #ffffff;
    width: 100%;
  }
`;

const StyledRequestButton = styled(Link)`
  &&&& {
    text-decoration: none;

    button {
      text-decoration: none;
      border-radius: 2px;
      width: 100%;
      > span {
        color: #131c23;
        font-size: 0.875rem;
        font-weight: 400;
        text-align: left;
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

const StyledArrowForward = styled(ArrowForward)`
  &&&& {
    fill: #2ca6ae;
  }
`;

const StyledFooter = styled(Grid)`
  &&&& {
    margin-top: 0.8rem;
  }
`;

const StyledFooterSpan = styled.span`
  &&&& {
    color: #131c23;
    font-weight: 400;
    font-size: 0.875rem;

    a {
      color: #2ca6ae;
      text-decoration: none;
    }
  }
`;
