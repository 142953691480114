import React, { useState } from 'react';
import styled from 'styled-components';

import Content from '../components/Content';
import Title from '../components/Title';
import Body from '../components/Body';
import SetupIntegrationToken from '../components/SetupIntegrationToken';
import ConfirmDialog from 'components/ConfirmDialog';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import AsanaIcon from 'design-system/atoms/AsanaIcon/index';


const Component = props => {
  const {
    userIntegration,
    orgIntegration,
    addOrgIntegration,
    addUserIntegration,
    removeIntegration,
    removeUserIntegration,
    handleTestConnection,
    addUserIntegrationError,
    userCanUpdateOrgIntegration,
    userCanViewOrgIntegration,
  } = props;

  const shouldShowContactInfo = !userCanViewOrgIntegration || (!userCanUpdateOrgIntegration && !orgIntegration);

  const [confirmDialogDisplay, setConfirmDialogDisplay] = useState(false);
  const [testingAsana, setTestingAsana] = useState(false);

  const handleChange = event => {
    event.preventDefault();

    if (event.target.checked) {
      addOrgIntegration();
    } else {
      setConfirmDialogDisplay(true);
    }
  };

  const handleRemoveOrgIntegration = () => {
    removeIntegration();
    setConfirmDialogDisplay(false);
  };

  const handleTestAsanaConnection = () => {
    setTestingAsana(true);

    handleTestConnection('asana', orgIntegration.id)
      .then(({ value }) => {
        if (!value?.data) {
          removeUserIntegration();
        }
      })
      .catch(err => {
        console.error(err);
        removeUserIntegration();
      })
      .finally(() => {
        setTestingAsana(false);
      });
  };

  return (
    <Content>
      <Title
        title="Asana Integration"
        helpLink="https://dragonboat.zendesk.com/hc/en-us/articles/1500010405101-Asana-Integration"
        logo={<AsanaIcon size={30} marginRight={20} />}
      />
      <Body>
        {userCanViewOrgIntegration && (
          <div>
            <StyledFormControl
              control={
                <Switch
                  id="turn-on-asana-integration-switch"
                  color="secondary"
                  checked={!!orgIntegration}
                  onChange={event => handleChange(event)}
                  disabled={!userCanUpdateOrgIntegration}
                />
              }
              label="Asana Integration"
              labelPlacement="start"
            />
            <ConfirmDialog
              title="Disconnect From Asana"
              text={
                <div>
                  <p>
                    Disconnecting Asana will disable Asana linking and update for your entire Workspace. Do you want to continue?
                  </p>
                  <br />
                  <p>You may reconnect in the future.</p>
                </div>
              }
              isOpen={confirmDialogDisplay}
              onCancel={() => setConfirmDialogDisplay(false)}
              onConfirm={() => handleRemoveOrgIntegration()}
            />
          </div>
        )}
        {userCanUpdateOrgIntegration && orgIntegration && !userIntegration && (
          <StyledSetupIntegrationToken>
            <SetupIntegrationToken
              integrationName="Asana"
              integrationHelpLink="https://app.asana.com/0/developer-console"
              connectButtonLabel="CONNECT"
              saveToken={token => addUserIntegration(token)}
              error={addUserIntegrationError}
            />
          </StyledSetupIntegrationToken>
        )}
        {(userCanViewOrgIntegration || userCanUpdateOrgIntegration) && orgIntegration && userIntegration && (
          <StyledDisconnectIntegration>
            {/* <DisconnectIntegration integrationName="Asana" disconnect={removeUserIntegration} /> */}
            <Button
              id="test-asana-connection"
              color="primary"
              onClick={() => handleTestAsanaConnection()}
              disabled={testingAsana}
            >
              {testingAsana && 'TESTING...'}
              {!testingAsana && 'TEST MY ASANA CONNECTION'}
            </Button>
            {testingAsana && <CircularProgress size={25} />}
          </StyledDisconnectIntegration>
        )}
        {shouldShowContactInfo && (
          <p>Please contact your organization Admin to activate Asana integration.</p>
        )}
      </Body>
    </Content>
  );
};

export default Component;

const StyledFormControl = styled(FormControlLabel)`
  &&&& {
    margin-bottom: 1rem;
  }
`;

const StyledSetupIntegrationToken = styled.div`
  &&&& {
    margin-left: 1rem;
  }
`;

const StyledDisconnectIntegration = styled.div`
  &&&& {
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
  }
`;
