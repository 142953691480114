import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrganization } from 'store/organization/selectors';
import { getPageFilters, getSearchOnPageFilters, getUserFilters, getProjectLayers } from 'store/filters/selectors';
import { setPageFilters } from 'store/filters';
import getDisplayLayerAndPortfolioModeFromFilters from 'utils/filters/getDisplayLayerAndPortfolioModeFromFilters';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import { GLOBAL_FILTER } from 'constants/filters';

/**
 * @function usePageFilters
 *
 * use page filters data and actions
 *
 * @return {Object}
 */
const usePageFilters = (pageId = GLOBAL_FILTER) => {
  const dispatch = useDispatch();

  const hasBet = useSelector(state => getOrganization(state).has_bet);
  const pageFilters = useSelector(state => getPageFilters(state, pageId));
  const searchString = useSelector(getSearchOnPageFilters);
  const { layers: availableLayers } = useSelector(state => getProjectLayers(state));
  const { displayLayer, portfolioMode } = getDisplayLayerAndPortfolioModeFromFilters(pageFilters, hasBet);
  const userFilters = useSelector(getUserFilters);

  const applyFilters = useCallback(
    (filters, filterId) => {
      dispatch(setPageFilters(pageId, filters, false, filterId));
    },
    [pageId, dispatch, setPageFilters],
  );

  const filtersForApiByLevels = useMemo(
    () => compileFiltersBody(pageFilters, userFilters, hasBet, pageId, displayLayer),
    [pageFilters, userFilters, hasBet, pageId, displayLayer],
  );

  return {
    pageFilters,
    displayLayer,
    availableLayers,
    searchString,
    applyFilters,
    portfolioMode,
    filtersForApiByLevels,
  };
};

export default usePageFilters;
