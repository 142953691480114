import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    background: '#fff',
    maxWidth: 250,
    maxHeight: 130,
    borderRadius: 0,
    border: `1px solid ${theme.palette.border.mercury}`,
    padding: 14,
    opacity: 1,
  },
}))(Tooltip);

export { HtmlTooltip };
