/**
 * Adds transparency to a given color.
 *
 * @param {string} color - The color to which transparency is added.
 * @param {number} transparency - The transparency value in the range [0, 1].
 * @returns {string} - The color in RGBA format with the specified transparency.
 */
function addColorTransparency(color, transparency) {
  const t = Math.max(0, Math.min(1, transparency));

  let r;
  let g;
  let b;

  if (color.charAt(0) === '#') {
    // Convert hex color (#RRGGBB) to RGB
    const hex = color.slice(1);

    [r, g, b] = [0, 2, 4].map(start => parseInt(hex.slice(start, start + 2), 16));
  } else if (color.startsWith('rgb')) {
    // Extract RGB values from format rgb(r, g, b)
    const match = color.match(/\d+/g);

    if (match && match.length === 3) {
      [r, g, b] = match.map(value => parseInt(value, 10));
    } else {
      console.error('Invalid RGB color format');
      return color;
    }
  } else {
    console.error('Unsupported color format');
    return color;
  }

  // Return the rgba color with the specified transparency
  return `rgba(${r}, ${g}, ${b}, ${t})`;
}

export default addColorTransparency;
