import isEmpty from 'lodash/isEmpty';

import { materialColorsAlt } from 'design-system/themes/default';
import { reversedPlanningStages, statusColors } from 'utils';
import { getProjectHealthLabel } from 'utils/projects/healthUtils';

import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from '../constants';

const DEFAULT_GROUP_OPTION = { key: null, title: 'No group' };

const DEFAULT_DATE_FIELD_KEY = 'estimatedStartDate';

const KEY_FIELD_MAP = {
  objectiveCorp: 'objective_corp_id',
  objective: 'objective_id',
  timeframeCorp: 'timeframe_corp_id',
  timeframe: 'timeframe_id',
  timeframe2: 'timeframe_2_id',
  keyResult1: 'key_result_1_id',
  keyResult2: 'key_result_2_id',
  roadmapCorp: 'roadmap_corp_id',
  roadmap: 'roadmap_id',
  product1: 'product_1_id',
  product2: 'product_2_id',
  theme: 'theme_id',
  phase: 'phase_id',
  // categoryCorp: 'category_corp_id',
  category: 'category_id',
  priority: 'priority_id',
  owner: 'owner_id',
  status_color: 'status_color',
  planningStage: 'planningStage',
  tags: 'tags',
  customers: 'customers',
  initiative: 'parent_id',
  bet: 'parent_id',
  metrics: 'metrics',
  resourceTeam: 'resource_team_id',
  user1: 'user_1_id',
  user2: 'user_2_id',
  user3: 'user_3_id',
  user4: 'user_4_id',
  user5: 'user_5_id',
  user6: 'user_6_id',
  user7: 'user_7_id',
};

const mapDateFieldToGroup = {
  [DEFAULT_DATE_FIELD_KEY]: 'estimated_start_date',
  deadline: 'deadline',
  predictedEndDate: 'predictedEndDate',
};

const getObjectiveGroupOption = getSystemFieldName => ({
  key: 'objective',
  field: KEY_FIELD_MAP.objective,
  title: getSystemFieldName('objective'),
});

const getObjectiveLevel0GroupOption = getSystemFieldName => ({
  key: 'objectiveCorp',
  field: KEY_FIELD_MAP.objectiveCorp,
  title: getSystemFieldName('objectiveCorp'),
});

const getTimeframeOption = getSystemFieldName => ({
  key: 'timeframe',
  field: KEY_FIELD_MAP.timeframe,
  title: getSystemFieldName('timeframe'),
});

const getTimeframeLevel0Option = getSystemFieldName => ({
  key: 'timeframeCorp',
  field: KEY_FIELD_MAP.timeframeCorp,
  title: getSystemFieldName('timeframeCorp'),
});

const getRoadmapOption = getSystemFieldName => ({
  key: 'roadmap',
  field: KEY_FIELD_MAP.roadmap,
  title: getSystemFieldName('roadmap'),
});

const getRoadmapLevel0Option = getSystemFieldName => ({
  key: 'roadmapCorp',
  field: KEY_FIELD_MAP.roadmapCorp,
  title: getSystemFieldName('roadmapCorp'),
});

const getTimeframesOptions = getSystemFieldName => [
  getTimeframeOption(getSystemFieldName),
  {
    key: 'timeframe2',
    field: KEY_FIELD_MAP.timeframe2,
    title: getSystemFieldName('timeframe2'),
  },
];

const getResourceTeamOption = getSystemFieldName => ({
  key: 'team',
  field: KEY_FIELD_MAP.resourceTeam,
  title: getSystemFieldName('team'),
});

const getCustomUserFieldOptionOrNothing = (getSystemFieldName, field, customUserfields) => {
  if (customUserfields?.[KEY_FIELD_MAP[field]]) {
    return [
      {
        key: field,
        field: KEY_FIELD_MAP[field],
        title: getSystemFieldName(`${field}`),
      },
    ];
  }

  return [];
};

// const getCategoryLevel0Option = getSystemFieldName => ({
//   key: 'categoryCorp',
//   field: KEY_FIELD_MAP.categoryCorp,
//   title: getSystemFieldName('categoryCorp'),
// });

const getStatusColors = () =>
  statusColors
    .filter(color => !!color.label)
    .map(color => ({
      id: color.label,
      title: getProjectHealthLabel(color.label),
      color: materialColorsAlt[color.label.toLowerCase()],
      rank: color.rank,
    }));

const getPlanningStages = () => reversedPlanningStages;

const getGroupOptions = ({
  getSystemFieldName,
  hasKeyResults,
  hasKeyResults2,
  hasMultiLevelPortfolioMetadata,
  hasProducts,
  hasHierarchy,
  hasBet,
  portfolioMode,
  displayLayer,
  dropdownCustomFields = [],
  withNullOption = false,
  withTags = false,
  withCustomers = false,
  dynamicDates = {},
  isDodActive,
  withMetrics = false,
  customUserFields = {},
  allowGroupByBetInPortfolioMode = false,
}) => {
  const mappedCustomFields = dropdownCustomFields.map(customField => ({
    key: `custom_fields.${customField?.key}`,
    field: `custom_fields.${customField?.key}`,
    title: `${customField?.title} (cf)`,
  }));

  const mappedDateFieldToGroup = mapDateFieldToGroup[dynamicDates?.field ? dynamicDates?.field : DEFAULT_DATE_FIELD_KEY];
  const dateFieldToGroup = !mappedDateFieldToGroup ? dynamicDates.field : mappedDateFieldToGroup;

  const removeBetsInPortfolioMode = allowGroupByBetInPortfolioMode ? false : portfolioMode;

  return [
    ...(withNullOption ? [DEFAULT_GROUP_OPTION] : []),
    ...(isDodActive ? [getObjectiveLevel0GroupOption(getSystemFieldName)] : []),
    getObjectiveGroupOption(getSystemFieldName),
    ...(hasKeyResults
      ? [
          {
            key: 'keyResult1',
            field: KEY_FIELD_MAP.keyResult1,
            title: getSystemFieldName('keyResult1'),
          },
        ]
      : []),
    ...(hasKeyResults2
      ? [
          {
            key: 'keyResult2',
            field: KEY_FIELD_MAP.keyResult2,
            title: getSystemFieldName('keyResult2'),
          },
        ]
      : []),
    ...(isDodActive ? [getTimeframeLevel0Option(getSystemFieldName)] : []),
    ...(hasMultiLevelPortfolioMetadata ? getTimeframesOptions(getSystemFieldName) : [getTimeframeOption(getSystemFieldName)]),
    ...(isDodActive ? [getRoadmapLevel0Option(getSystemFieldName)] : []),
    getRoadmapOption(getSystemFieldName),
    ...(hasProducts ? [{ key: 'product1', field: KEY_FIELD_MAP.product1, title: getSystemFieldName('product1') }] : []),
    { key: 'product2', field: KEY_FIELD_MAP.product2, title: getSystemFieldName('product2') },
    { key: 'theme', field: KEY_FIELD_MAP.theme, title: getSystemFieldName('theme') },
    { key: 'phase', field: KEY_FIELD_MAP.phase, title: getSystemFieldName('phase') },
    // ...(isDodActive ? [getCategoryLevel0Option(getSystemFieldName)] : []),
    { key: 'category', field: KEY_FIELD_MAP.category, title: getSystemFieldName('category') },
    { key: 'priority', field: KEY_FIELD_MAP.priority, title: getSystemFieldName('priority') },
    { key: 'owner', field: KEY_FIELD_MAP.owner, title: 'Owner' },
    { key: 'status_color', field: KEY_FIELD_MAP.status_color, title: 'Health' },
    { key: 'planningStage', field: KEY_FIELD_MAP.planningStage, title: 'Planning Stage' },
    ...(withMetrics ? [{ key: 'metrics', field: KEY_FIELD_MAP.metrics, title: 'Metrics' }] : []),
    ...(withTags ? [{ key: 'tags', field: KEY_FIELD_MAP.tags, title: getSystemFieldName('tag') }] : []),
    ...(withCustomers
      ? [
          {
            key: 'customers',
            field: KEY_FIELD_MAP.customers,
            title: getSystemFieldName('customer'),
          },
        ]
      : []),
    ...(!isEmpty(dynamicDates)
      ? [
          {
            key: 'dates',
            title: 'Dynamic dates',
            ...dynamicDates,
            field: dateFieldToGroup,
          },
        ]
      : []),
    ...(!portfolioMode && displayLayer === IDEA_LAYER && hasHierarchy
      ? [
          {
            key: 'initiative',
            field: KEY_FIELD_MAP.initiative,
            title: getSystemFieldName('initiative'),
          },
        ]
      : []),
    ...(!removeBetsInPortfolioMode && [IDEA_LAYER, INITIATIVE_LAYER].includes(displayLayer) && hasBet
      ? [
          {
            key: 'bet',
            field: KEY_FIELD_MAP.bet,
            title: getSystemFieldName('bet'),
          },
        ]
      : []),
    ...(!isEmpty(mappedCustomFields) ? mappedCustomFields : []),
    getResourceTeamOption(getSystemFieldName),
    ...getCustomUserFieldOptionOrNothing(getSystemFieldName, 'user1', customUserFields),
    ...getCustomUserFieldOptionOrNothing(getSystemFieldName, 'user2', customUserFields),
    ...getCustomUserFieldOptionOrNothing(getSystemFieldName, 'user3', customUserFields),
    ...getCustomUserFieldOptionOrNothing(getSystemFieldName, 'user4', customUserFields),
    ...getCustomUserFieldOptionOrNothing(getSystemFieldName, 'user5', customUserFields),
    ...getCustomUserFieldOptionOrNothing(getSystemFieldName, 'user6', customUserFields),
    ...getCustomUserFieldOptionOrNothing(getSystemFieldName, 'user7', customUserFields),
  ]
    .filter(o => (displayLayer !== IDEA_LAYER || (portfolioMode && !hasBet) || !hasHierarchy ? o.key !== 'initiative' : true))
    .filter(o => (displayLayer === BET_LAYER || removeBetsInPortfolioMode || !hasHierarchy || !hasBet ? o.key !== 'bet' : true));
};

const getGroupOptionByKey = (key, groupOptions, defaultValue = null) =>
  groupOptions?.find(option => option?.key === key) ?? defaultValue;

const mapGroupKeyToField = key => KEY_FIELD_MAP[key];

export {
  KEY_FIELD_MAP,
  DEFAULT_GROUP_OPTION,
  mapDateFieldToGroup,
  getStatusColors,
  getPlanningStages,
  getGroupOptions,
  getTimeframeOption,
  getTimeframesOptions,
  getObjectiveGroupOption,
  getRoadmapOption,
  getGroupOptionByKey,
  mapGroupKeyToField,
};
