import withStyles from '@material-ui/core/styles/withStyles';

import DateRangeMode from './DateRangeMode';

const styles = () => ({
  radio: {
    height: '1rem',
    lineHeight: '1rem',
  },
  label: {
    marginRight: '15px',
  },
});

export default withStyles(styles)(DateRangeMode);
