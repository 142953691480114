import React, { useMemo } from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

import Segment from 'design-system/atoms/Segment/index';
import Text from 'design-system/atoms/Text/index';

import ChartWrapper from 'components/ReactChart/utils/ChartWrapper';

import useGetProgressDateRange from './hooks/useGetProgressDateRange';

import formatPercent from 'design-system/utils/formatPercent';
import formatDate from 'design-system/utils/formatDate';
import { spacing } from 'design-system/theme';

const PROGRESS_SUMMARY_URL = '/api/v1/reports/dashboard/progress-summary';
const DEFAULT_WIDGET_HEIGHT = 100;
const DEFAULT_REPORT_DATA = { overallProgress: 0, overallTargetEndDate: null };

function ProgressSummaryWidget({ wrapper: Wrapper, dashboardState, actionButtons, theme }) {
  const { reportData } = useGetProgressDateRange({
    dashboardState,
    defaultReportData: DEFAULT_REPORT_DATA,
    fetchUrl: PROGRESS_SUMMARY_URL,
  });

  const overallProgress = useMemo(() => formatPercent(reportData.overallProgress), [reportData]);
  const overallTargetEndDate = useMemo(() => formatDate(reportData.overallTargetEndDate), [reportData]);

  return (
    <Wrapper>
      <Segment actionButtons={actionButtons}>
        <StyledChartWrapper height={DEFAULT_WIDGET_HEIGHT}>
          <ProgressText color={theme.palette.text.primary}>
            Overall Progress:{' '}
            <Text variant="h4" inline>
              {overallProgress}
            </Text>
          </ProgressText>
          <Text color={theme.palette.text.primary}>
            Overall Target End Date:{' '}
            <Text variant="h4" color={theme.palette.text.primary} inline>
              {overallTargetEndDate}
            </Text>
          </Text>
        </StyledChartWrapper>
      </Segment>
    </Wrapper>
  );
}

const StyledChartWrapper = styled(ChartWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${0}px ${spacing(1)}px;
`;

const ProgressText = styled(Text)`
  padding-bottom: ${({ theme }) => theme.spacing.unit * 0.75}px;
`;

export default withTheme()(ProgressSummaryWidget);
