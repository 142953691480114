import { css } from 'styled-components';

import isNumber from 'lodash/isNumber';

const withRightBorder = css`
  ${({ bordered, theme }) =>
    bordered &&
    `
      border-right: 1px solid ${theme.palette.border.mercury};
    `}
`;

const withBottomBorder = css`
  ${({ bottomBorder, theme }) =>
    bottomBorder &&
    `
      border-bottom: 1px solid ${theme.palette.border.mercury};
    `}
`;

const withStickyPosition = css`
  ${({ sticky }) =>
    sticky &&
    `
    position: sticky;
  `}
`;

const withLeftCoordinate = css`
  ${({ left }) =>
    isNumber(left) &&
    `
    left: ${left}px;
    z-index: 100;
  `}
`;

const withWidth = css`
  ${({ width }) => {
    if (width) {
      if (isNumber(width)) {
        return `
                width: ${width}px;
                max-width: ${width}px;
              `;
      }
      return `
              width: ${width};
              max-width: ${width};
            `;
    }
  }}
`;

const withHeight = css`
  ${({ maxHeight }) =>
    maxHeight &&
    `
      height: ${maxHeight}px;
      max-height: ${maxHeight}px;
    `}
`;

const withZIndex = css`
  ${({ zIndex }) =>
    zIndex &&
    `
    z-index: ${zIndex};
  `}
`;

const withSplit = css`
  ${({ split, bordered }) =>
    split &&
    !bordered &&
    `
    border-image: linear-gradient(270deg, #0E275200 0%, #0E27522C 100%) 30;
    border-right: 3px solid;
  `}
`;

const withColor = css`
  ${({ color }) =>
    color &&
    `
      border-left: 2px solid ${color};
    `}
`;

const withIndent = css`
  ${({ indent }) => indent && ' padding-left: 20px;'}
`;

const withTopAlignment = css`
  ${({ top }) =>
    top &&
    `
      top: 0;
    `}
`;

const withLeftAlignment = css`
  ${({ left }) => {
    if (left) {
      return !isNumber(left)
        ? 'left: 0;'
        : `
      left: ${left - 1}px;
    `;
    }
  }}
`;

export {
  withBottomBorder,
  withRightBorder,
  withStickyPosition,
  withLeftCoordinate,
  withWidth,
  withHeight,
  withZIndex,
  withSplit,
  withColor,
  withIndent,
  withTopAlignment,
  withLeftAlignment,
};
