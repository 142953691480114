import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import AddIcon from '@material-ui/icons/Add';

import { BaseGrid } from 'containers/Grids';
import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';
import { getRowId } from 'design-system/molecules/AgGridReact-New/helpers';
import { BASE_ROW_HEIGHT } from 'constants/grid';
import { fetchProjectsChildren } from 'store/projects';
import useSystemFields from 'hooks/useSystemFields';
import useProjectLightboxChildrenGrid from './useProjectLightboxChildrenGrid';
import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';

export default ({ selectedProject, isDrawerView }) => {
  const dispatch = useDispatch();

  const [getSystemFieldName] = useSystemFields();

  const getChildLayer = ({ layer }) => {
    switch (layer) {
      case BET_LAYER:
        return 'initiative';
      case INITIATIVE_LAYER:
      default:
        return 'idea';
    }
  };

  const {
    rows,
    commonGridProps,
    commonGridEvents,
    columnDefs,
    defaultColumnDef,
    gridContext,
    gridStyles,
    tableHeight,
    isChildDragon,
    addNewRow,
  } = useProjectLightboxChildrenGrid(selectedProject, isDrawerView);

  const hasEmptyRow = rows.some(row => !row.id);
  const childLayer = getChildLayer(selectedProject);

  useEffect(() => {
    dispatch(
      fetchProjectsChildren(selectedProject.id, undefined, undefined, false, isChildDragon ? { includeAllCorpItems: true } : {}),
    );
  }, []);

  return (
    <>
      <TopBar>
        <Text variant="h3">Children</Text>
        <FlexWrapper>
          <StyledButton onClick={() => addNewRow()} disabled={hasEmptyRow} size="small">
            <AddIcon />
            Create {getSystemFieldName(childLayer)}
          </StyledButton>

          <StyledButton onClick={() => addNewRow(true)} disabled={hasEmptyRow} size="small">
            <LinkIcon />
            Link Children
          </StyledButton>
        </FlexWrapper>
      </TopBar>

      <BaseGrid
        {...commonGridProps}
        {...commonGridEvents}
        getRowId={getRowId}
        rowData={rows}
        columnDefs={columnDefs}
        defaultColDef={defaultColumnDef}
        headerHeight={BASE_ROW_HEIGHT}
        context={gridContext}
        height={tableHeight}
        cssStyles={gridStyles}
      />
    </>
  );
};

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing()}px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${spacing(2)}px;
`;

const StyledButton = styled(Button)`
  span {
    gap: ${spacing()}px;
  }
`;
