import React, { useState } from 'react';
import pluralize from 'pluralize';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Dialog from 'design-system/molecules/Dialog/index';

import styled, { css, ThemeProvider } from 'styled-components';
import LightBox from 'components/LightBox';
import theme from 'design-system/theme';

import FieldsList from './FieldsList';
import useFieldsSelectionHook from './hooks/useFieldsSelectionHook';
import useStepsHandlerHook from './hooks/useStepsHandlerHook';
import useGridHook from './hooks/useGridHook';
import useConfirmHook from './hooks/useConfirmHook';

import BulkUpdateList from './BulkUpdateList/BulkUpdateList';

export const NewFieldButton = props => (
  <Button {...props} color="primary">
    <AddIcon /> New Field
  </Button>
);

const pluralizeIfMoreThanOne = (itemsLength, resource) => `${itemsLength} ${itemsLength > 1 ? pluralize(resource) : resource}`;

const transformFieldsIntoOptions = (fields, config) => {
  return fields
    .map(key => ({
      label: config[key].label || key,
      value: key,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};

const exist = Boolean;

const SELECT_FIELDS_STEP = 1;
const REVIEW_STEP = 2;
const CONFIRMATION_STEP = 3;

export default ({
  selectedItems = [],
  resource,
  onClose,
  onConfirmed,
  fields = {},
  mandatoryGridColumns = [],
  customLightbox,
}) => {
  const [showConfirmationDialog, changeConfirmationDialogVisibility] = useState(false);

  const { availableFields, fieldsToUpdate, removeField, changeField, changeFieldValue, appendField } =
    useFieldsSelectionHook(fields);

  const { updatedRows, gridColumns, updateSelectedItems, updateGridColumns, needToFillEmailRows, setNeedToFillEmailRows } =
    useGridHook(fields, selectedItems, mandatoryGridColumns, fieldsToUpdate);

  const { confirm } = useConfirmHook(onConfirmed, updatedRows, fieldsToUpdate);

  const { step, setStep, goToReviewStep, goToConfirmationStep, confirmationPending, confirmationError } = useStepsHandlerHook(
    updateSelectedItems,
    updateGridColumns,
    confirm,
    changeConfirmationDialogVisibility,
  );

  const _updateEmail = (id, fields) => {
    const item = needToFillEmailRows.find(item => item.id === id);
    const updatedItem = { ...item, email: fields.email };

    const itemsLeftWithoutEmail = needToFillEmailRows.filter(item => item.id !== id);

    const finalItems = [...updatedRows, updatedItem, ...itemsLeftWithoutEmail];

    updateSelectedItems(finalItems);
  };

  const _resetToFirstStep = () => {
    setStep(SELECT_FIELDS_STEP);
    setNeedToFillEmailRows([]);
  };

  const renderBulkUpdateList = () => (
    <ThemeProvider theme={theme}>
      <BulkUpdateList items={updatedRows} columnDefs={gridColumns} multiTables={exist(needToFillEmailRows.length)} />
    </ThemeProvider>
  );

  const renderSecondBulkUpdateList = () => (
    <BulkUpdateList items={needToFillEmailRows} columnDefs={gridColumns} updateField={_updateEmail} multiTables />
  );

  const Content = (
    <>
      <Stepper activeStep={step - 1} style={{ marginBottom: 15 }}>
        <Step>
          <StepButton
            className="step-1-button"
            onClick={_resetToFirstStep}
            completed={step > SELECT_FIELDS_STEP}
            disabled={step === CONFIRMATION_STEP}
          >
            Select Fields
          </StepButton>
        </Step>
        <Step>
          <StepLabel>Review</StepLabel>
        </Step>
        <Step>
          <StepLabel>Confirmation</StepLabel>
        </Step>
      </Stepper>

      <ContentWrapper customLightbox={customLightbox}>
        {step === SELECT_FIELDS_STEP && (
          <StepOneContent className="step-1">
            <p style={{ marginBottom: 30 }}>
              Update the fields for the selected {pluralizeIfMoreThanOne(selectedItems.length, resource)}.
            </p>
            <FieldsList
              fieldsAvailable={transformFieldsIntoOptions(availableFields, fields)}
              fields={fieldsToUpdate}
              allFields={fields}
              onRemove={removeField}
              onFieldChange={changeField}
              onValueChange={changeFieldValue}
            />
            <NewFieldButton
              style={{ marginTop: 15 }}
              className="new-field-button"
              onClick={appendField}
              disabled={fieldsToUpdate.length >= Object.keys(fields).length}
            />
          </StepOneContent>
        )}

        {step === REVIEW_STEP && (
          <StepTwoContent className="step-2">
            <p style={{ marginBottom: 30 }}>
              The following {pluralizeIfMoreThanOne(updatedRows.length, resource)} will be updated.
            </p>
            {renderBulkUpdateList()}
            {exist(needToFillEmailRows.length) && (
              <SecondTableContent>
                <p style={{ marginBottom: 30 }}>
                  The following {pluralizeIfMoreThanOne(needToFillEmailRows.length, resource)} will not be updated without email.
                </p>
                {renderSecondBulkUpdateList()}
              </SecondTableContent>
            )}
          </StepTwoContent>
        )}

        {step === CONFIRMATION_STEP && (
          <div className="step-3">
            <p>The {pluralizeIfMoreThanOne(updatedRows.length, resource)} have been updated successfully.</p>
          </div>
        )}

        {confirmationError && <p>{confirmationError}</p>}
      </ContentWrapper>

      {showConfirmationDialog && (
        <Dialog open>
          <div>
            You are about to update {pluralizeIfMoreThanOne(updatedRows.length, resource)}. This action cannot be reversed.
          </div>
          <Grid container justify="flex-end" direction="row" style={{ marginTop: '15px' }}>
            <Grid item>
              <Button
                color="primary"
                className="final-confirm-button"
                onClick={goToConfirmationStep}
                disabled={confirmationPending}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item>
              <Button className="close-button" onClick={onClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      )}

      <Grid container style={{ marginTop: '15px', paddingLeft: '24px', paddingRight: '24px' }}>
        <Grid item xs={6}>
          {step === REVIEW_STEP && (
            <Button className="back-button" onClick={_resetToFirstStep}>
              Back
            </Button>
          )}
        </Grid>
        <Grid item xs={6} container justify="flex-end" direction="row">
          {step === SELECT_FIELDS_STEP && (
            <Grid item>
              <Button
                color="primary"
                className="next-button"
                disabled={
                  !fieldsToUpdate.length
                  // || fieldsToUpdate.some(updateField => !updateField.field)
                }
                onClick={goToReviewStep}
              >
                Next
              </Button>
            </Grid>
          )}
          {step === REVIEW_STEP && (
            <Grid item>
              <Button
                color="primary"
                className="confirm-button"
                onClick={() => {
                  changeConfirmationDialogVisibility(true);
                }}
                disabled={confirmationPending}
              >
                Confirm
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button className="cancel-button" onClick={onClose}>
              {step < CONFIRMATION_STEP ? 'Cancel' : 'Close'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  if (customLightbox) return <CustomLightbox isOpen onClose={onClose} maxWidth="lg" big content={<Wrapper>{Content}</Wrapper>} />;

  return (
    <Dialog open fullWidth maxWidth="md" scroll="paper" onClose={onClose}>
      {Content}
    </Dialog>
  );
};

const CustomLightbox = styled(LightBox)`
  &&&& {
    > div > div {
      height: 60%;
      width: 980px;
      overflow: hidden;
      transition: width 0.6s, height 0.6s;

      ${({ big }) =>
        big &&
        css`
          width: 1128px;
          height: 100%;
        `}
    }

    > div > div > div {
      height: 100%;
      overflow: hidden;
    }
  }
`;

const Wrapper = styled.div`
  height: 100%;
  position: relative;

  > button {
    top: -18px;
    right: -16px;
  }
`;

const ContentWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 450px);

  ${({ customLightbox }) =>
    customLightbox &&
    css`
      height: calc(100vh - 272px);
    `}
`;

const StepOneContent = styled.div`
  padding: 0 24px;
`;

const StepTwoContent = styled(StepOneContent)`
  margin-bottom: 30px;
`;

const SecondTableContent = styled.div`
  margin-top: 30px;
`;
