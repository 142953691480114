import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getCustomerRequestFormData } from 'store/customerRequests/selectors';
import { BaseGrid } from 'containers/Grids';
import {
  useCommonGridEventHandlers,
  useCommonGridProps,
  useDynamicGridHeight,
} from 'design-system/molecules/AgGridReact-New/hooks';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';
import useCustomerRequestsGrid from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsGrid';
import useCustomerRequestsGridMetadata from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsGridMetadata';
import { HEADER_HEIGHT, ID_KEY } from 'routes/CustomerRequests/Grid/New/constants';
import { useCustomerRequestsColumnsDefs } from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsColumnsDefs';
import Text from 'design-system/atoms/Text';
import useCustomerRequests from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequests';

import { GridWrapper } from './styles';
import useRequestsMergedGridState from './hooks/useRequestsMergedGridState';
import useRequestsMerged from './hooks/useRequestsMerged';
import { PAGE_SIZE } from './constants';

const GRID_HEIGHT_OFFSET = 250;

const getRowId = ({ data }) => data?.id || null;

export default () => {
  const formData = useSelector(getCustomerRequestFormData);

  const { onOpenCustomerRequestInfo } = useCustomerRequests();

  const { requests, isFetching } = useRequestsMerged({ id: formData?.id });

  const {
    rowHeight,
    searchString,
    isBulkUpdate,
    visibleColumnsIds,
    columnsState,
    shouldGridBeUnmounted,

    setDirtyViewMutex,
    saveColumnsState,
  } = useRequestsMergedGridState();

  const { handleGridReady } = useCustomerRequestsGrid({ saveColumnsState });

  const { metadata, createMultiSelectOption } = useCustomerRequestsGridMetadata();

  const { columnDefs, defaultColDef, groupColumnDef } = useCustomerRequestsColumnsDefs({
    searchString,
    isBulkUpdate,
    visibleColumnsIds,
    columnsState,
    rowHeight,

    createMultiSelectOption,
    onOpenCustomerRequestInfo,
  });

  const commonGridProps = useCommonGridProps({});

  const commonGridEvents = useCommonGridEventHandlers({
    items: requests,
    idKey: ID_KEY,
    actions: {},
  });

  const gridContext = useMemo(() => ({ metadata, allowEdit: false }), [metadata]);

  const { gridHeight } = useDynamicGridHeight({
    rowHeight,
    headerHeight: HEADER_HEIGHT,
    rowCount: requests.length,
    maxRowsHeight: (window.innerHeight - GRID_HEIGHT_OFFSET) / rowHeight,
  });

  if (shouldGridBeUnmounted || isFetching) {
    return <PageLoading />;
  }

  if (!requests?.length & !isFetching) {
    return (
      <GridWrapper>
        <Text>Nothing here yet.</Text>
      </GridWrapper>
    );
  }

  return (
    <GridWrapper>
      <BaseGrid
        // Common props:
        {...commonGridProps}
        {...commonGridEvents}
        // General props:
        getRowId={getRowId}
        onGridReady={handleGridReady}
        rowData={requests}
        context={gridContext}
        onFirstDataRendered={setDirtyViewMutex}
        suppressRowClickSelection
        // Styling:
        height={gridHeight}
        headerHeight={HEADER_HEIGHT}
        rowHeight={rowHeight}
        // Columns:
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        autoGroupColumnDef={groupColumnDef}
        // Events:
        paginationPageSize={PAGE_SIZE}
        pagination
      />
    </GridWrapper>
  );
};
