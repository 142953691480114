import React, { Component } from 'react';
import { array, func, bool, object } from 'prop-types';
import axios from 'axios';
import { equals } from 'ramda';
import { EditorState, ContentState, convertToRaw, RichUtils } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin from 'draft-js-mention-plugin';
import 'draft-js-mention-plugin/lib/plugin.css';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';

import { spacing } from 'design-system/theme';
import { FeatureFlags } from '@dragonboat/config';
import { checkOrganizationFlags } from 'hooks/useFeatureFlags';
import ConfirmDialog from 'components/ConfirmDialog';
import ErrorMessage from 'design-system/atoms/ErrorMessage';
import { INTEGRATIONS_KEYS } from 'constants/integrations';
import { getUserName } from 'utils';

import { getEditorMentions } from './helpers/getEditorMentions';
import SendCommentToIntegrationChannel from './components/SendCommentToIntegrationChannel';
import EditorContainer from './components/EditorContainer';
import UserMention from './components/UserMention';
import CommentItem from './components/CommentItem';

const ENABLE_SEND_COMMENTS_TO_SLACK_CHANNELS = process.env.REACT_APP_ENABLE_SEND_COMMENTS_TO_SLACK_CHANNELS || false;

const EditorActionsContainer = styled.div``;

export const CommentsContainer = styled.ul`
  margin-top: 45px;
  margin-left: 17px;
`;

const StyledErrorWrapper = styled.div`
  &&&& {
    margin-top: 15px;
    margin-left: 17px;
  }
`;

export const ButtonsGrid = styled(Grid)`
  margin-top: ${spacing()}px;
`;

class Comments extends Component {
  static propTypes = {
    comments: array,
    onSave: func.isRequired,
    hasMoreComments: bool,
    onDelete: func,
    showMore: func.isRequired,
    slackErrorNotification: object,
    slackAuthenticationError: object,
    organization: object,
    removeNotification: func.isRequired,
  };

  static defaultProps = {
    comments: [],
    hasMoreComments: true,
  };

  state = {
    editorState: EditorState.createEmpty(),
    suggestions: [],
    selectedCommentId: null,
    isDeleteConfirmationDialogOpen: false,
    selectedCommentsToEdit: [],
    isSaving: false,
  };

  constructor(props) {
    super(props);

    this.mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.comments !== this.props.comments) {
      this.clearNewComment();
    }
  }

  componentWillUnmount() {
    const { slackErrorNotification, slackAuthenticationError, removeNotification } = this.props;

    if (slackErrorNotification) removeNotification(slackErrorNotification?.id);
    if (slackAuthenticationError) removeNotification(slackAuthenticationError?.id);
  }

  onChange = editorState => {
    const { slackErrorNotification, removeNotification } = this.props;

    if (slackErrorNotification && editorState.getCurrentContent().getPlainText().length)
      removeNotification(slackErrorNotification?.id);

    this.setState({
      editorState,
    });
  };

  onSearchChange = ({ value }) => {
    const params = {
      search: value,
      status: 'Active',
      from: 0,
      to: 10,
    };

    axios.get('/api/users', { params }).then(({ data: resData }) => {
      const usersData = resData.data.map(user => ({
        ...user,
        name: getUserName(user),
      }));

      this.setState({
        suggestions: usersData,
      });
    });
  };

  onAddMention = () => {
    // get the mention object selected
  };

  focus = () => {
    this.editor.focus();
  };

  save = ({ integrationType, channel } = {}) => {
    const currentState = this.state.editorState.getCurrentContent();
    const rawEditorState = convertToRaw(currentState);
    const plainText = currentState.getPlainText();

    const mentions = getEditorMentions(rawEditorState);

    this.props.onSave({ text: plainText, mentions, editorState: rawEditorState, integrationType, channel });
  };

  clearNewComment = () => {
    const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));

    this.setState({ editorState });
  };

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  onOpenDeleteConfirmationDialog = commentId => {
    this.setState({ selectedCommentId: commentId, isDeleteConfirmationDialogOpen: true });
  };

  onCloseDeleteConfirmationDialog = () => {
    this.setState({ selectedCommentId: null, isDeleteConfirmationDialogOpen: false });
  };

  onConfirmDeleteComment = () => {
    const { selectedCommentId } = this.state;
    const { onDelete } = this.props;

    if (selectedCommentId && onDelete) {
      onDelete(selectedCommentId);
    }

    this.onCloseDeleteConfirmationDialog();
  };

  onStartEditComment = comment => {
    this.setState(prevState => ({ selectedCommentsToEdit: [...prevState.selectedCommentsToEdit, comment.id] }));
  };

  onCloseEditComment = commentId => {
    this.setState(prevState => ({ selectedCommentsToEdit: prevState.selectedCommentsToEdit.filter(c => c !== commentId) }));
  };

  onEditComment = async (commentId, data) => {
    const { onEdit } = this.props;

    this.setState({ isSaving: true });

    try {
      if (commentId && onEdit) {
        await onEdit(commentId, data);
      }

      this.onCloseEditComment(commentId);
    } finally {
      this.setState({ isSaving: false });
    }
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin];

    const { editorState, suggestions, isDeleteConfirmationDialogOpen, selectedCommentsToEdit } = this.state;

    const { isDialog, hasMoreComments, comments, showMore, classes, slackErrorNotification, organization, readOnly } = this.props;

    const hasCommentText = editorState && editorState.getCurrentContent().hasText();
    const hasMicrosoftTeamsIntegration = checkOrganizationFlags(organization, [FeatureFlags.HAS_MICROSOFT_TEAMS_INTEGRATION]);
    const hasSendCommentsToSlackChannelsEnabled = equals(ENABLE_SEND_COMMENTS_TO_SLACK_CHANNELS, 'true');

    return (
      <div className={classes.container}>
        {!readOnly && (
          <>
            <EditorContainer onClick={this.focus} isDialog={isDialog}>
              <Editor
                editorState={editorState}
                onChange={this.onChange}
                handleKeyCommand={this.handleKeyCommand}
                plugins={plugins}
                ref={element => {
                  this.editor = element;
                }}
                spellCheck
              />
              <MentionSuggestions
                onSearchChange={this.onSearchChange}
                suggestions={suggestions}
                onAddMention={this.onAddMention}
                entryComponent={UserMention}
              />
            </EditorContainer>
            <EditorActionsContainer>
              <ButtonsGrid container justify="flex-end" alignItems="center">
                {hasSendCommentsToSlackChannelsEnabled && (
                  <SendCommentToIntegrationChannel
                    onSend={this.save}
                    integrationType={INTEGRATIONS_KEYS.slack}
                    hasCommentText={hasCommentText}
                  />
                )}
                {hasMicrosoftTeamsIntegration && (
                  <SendCommentToIntegrationChannel
                    onSend={this.save}
                    integrationType={INTEGRATIONS_KEYS.microsoftTeams}
                    hasCommentText={hasCommentText}
                  />
                )}
                <Button color="primary" onClick={() => this.save()} disabled={!hasCommentText}>
                  Comment
                </Button>
                <Button onClick={this.clearNewComment} disabled={!hasCommentText}>
                  Cancel
                </Button>
              </ButtonsGrid>
            </EditorActionsContainer>
          </>
        )}
        {slackErrorNotification ? (
          <StyledErrorWrapper>
            <ErrorMessage>{slackErrorNotification.message}</ErrorMessage>
          </StyledErrorWrapper>
        ) : null}
        {comments.length > 0 && (
          <CommentsContainer>
            {comments.map(comment => (
              <CommentItem
                comment={comment}
                editable={selectedCommentsToEdit.includes(comment.id)}
                key={comment.id}
                onDelete={this.onOpenDeleteConfirmationDialog}
                onEdit={this.onStartEditComment}
                onSave={this.onEditComment}
                onCancelEdit={() => this.onCloseEditComment(comment.id)}
                disabledEdit={this.state.isSaving}
              />
            ))}
          </CommentsContainer>
        )}
        {hasMoreComments && (
          <Grid container justify="center">
            <Button size="small" className={classes.showMoreButton} onClick={() => showMore()}>
              Show more
            </Button>
          </Grid>
        )}
        <ConfirmDialog
          isOpen={isDeleteConfirmationDialogOpen}
          onCancel={this.onCloseDeleteConfirmationDialog}
          onConfirm={this.onConfirmDeleteComment}
          title="Delete selected comment"
          text="Are you sure you want to delete selected comment?"
        />
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignContent: 'flex-start',
    marginTop: spacing(4),
  },
  showMoreButton: {
    flexBasis: 'auto',
    width: '150',
    alignSelf: 'center',
    fontSize: 11,
  },
});

export default withStyles(styles)(Comments);
