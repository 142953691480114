import { useSelector } from 'react-redux';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

import { getIntegrationById } from 'store/organization';
// eslint-disable-next-line max-len
import { getRallyPortfolioItemTypesFromOrgIntegrationData } from 'utils/integrations/getRallyPortfolioItemTypesFromOrgIntegrationData';
import { mapObjIndexed, prop } from 'ramda';

const useIntegrationLayerNames = orgIntegrationId => {
  const orgIntegration = useSelector(getIntegrationById(orgIntegrationId));

  if (orgIntegration?.integrationType === INTEGRATIONS_KEYS.rally) {
    const namesByLayer = getRallyPortfolioItemTypesFromOrgIntegrationData(orgIntegration.data);

    return {
      namesByLayer: mapObjIndexed(prop('Name'))(namesByLayer),
    };
  }

  return {
    namesByLayer: {},
  };
};

export default useIntegrationLayerNames;
