import reduceReducers from 'reduce-reducers';

import operationsReducers, { initialState as operationsInitialState } from './operationsReducer';
import projectsEntitiesReducer from './entitiesReducer';
import projectsReducer, { initialState as projectsInitialState } from './reducer';

const initialState = {
  ...operationsInitialState,
  ...projectsInitialState,
};

const reducer = reduceReducers(initialState, projectsReducer, ...operationsReducers);

export {
  projectsInitialState,
  projectsEntitiesReducer,
};

export default reducer;
