/* eslint-disable */
/**
 * UnfilterIcon Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';

export default ({ size = 20, marginRight, style, ...other }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{ width: size, height: size, marginRight: marginRight, ...style }}
      {...other}
    >
      <ellipse cx={5.22} cy={17.824} fill="#EF5350" rx={5.22} ry={5.176} />
      <ellipse cx={12} cy={6.176} fill="#EF5350" rx={5.22} ry={5.176} />
      <ellipse cx={18.78} cy={17.824} fill="#EF5350" rx={5.22} ry={5.176} />
    </svg>
  );
};
