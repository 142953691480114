import { useRef } from 'react';

import { MAX_VISIBLE_ITEMS, ROW_HEIGHT, HEADER_HEIGHT, tableFullHeight } from '../helpers/constants';

const exist = Boolean;

const useBulkUpdateList = (items, multiTables, updateField) => {
  const gridApi = useRef();

  const onGridReady = params => {
    gridApi.current = params.api;
  };

  const tableVisibleRows = exist(items?.length) && items.length < MAX_VISIBLE_ITEMS ? items.length : MAX_VISIBLE_ITEMS;
  const tableContentHeight = tableVisibleRows * ROW_HEIGHT;
  const tableHalfHeight = tableContentHeight + HEADER_HEIGHT;

	const handleUpdateField = (id, field) => {
		if (updateField) updateField(id, field);
	};

  return { onGridReady, tableHeight: exist(multiTables) ? tableHalfHeight : tableFullHeight, handleUpdateField };
};

export default useBulkUpdateList;
