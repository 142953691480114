import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import getSystemFieldNameUtil from 'utils/getSystemFieldName';

/**
 * Features a memoized function to get the system field name, based on the organization.
 * The returned function can be safely passed as argument to the dependencies array of
 * memoizing functions.
 * TODO: This is a new and improved version of `src/hooks/useSystemFields.js`.
 *  The older version usages should be replaced by this one.
 *  Then the older should be deleted and this should be moved to its place.
 *  There was a need for this improved version to avoid flickering on the grid,
 *  as the function returned by the older hook was being passed as a dependency
 *  for the memoizing functions.
 */
const useSystemFields = () => {
  const systemFields = useSelector(state => state.organization.organization.system_fields_name);

  const getSystemFieldName = useCallback(
    (name, plural) => {
      return getSystemFieldNameUtil(name, systemFields, plural);
    },
    [systemFields],
  );

  return {
    systemFields,
    getSystemFieldName,
  };
};

export default useSystemFields;
