import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function SvgComponent(props) {
  const d =
    'M19 3H5a2.006 2.006 0 00-2 2v14a2.006 2.006 0 002 2h14a2.006 2.006 0 002-2V5a2.006 ' +
    '2.006 0 00-2-2zm-8.695 11v-3h3.325v3zm3.325 2v3h-3.325v-3zm-3.325-7V6h3.325v3zM5 6h3.305v3H5zm0 ' +
    '5h3.305v3H5zm0 5h3.305v3H5zm14 3h-3.37v-3H19zm0-5h-3.37v-3H19zm0-5h-3.37V6H19z';

  return (
    <SvgIcon width={24} height={24} {...props}>
      <path data-name="Ret\xE2ngulo 4636" fill="none" d="M0 0h24v24H0z" />
      <path data-name="Caminho 15533" d={d} />
    </SvgIcon>
  );
}

export default SvgComponent;
