import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getPortalSettings } from 'store/organization/selectors';
import { getIsPortalRoute } from '../routes/CustomerRequests/helpers';

export default () => {
  const portalSettings = useSelector(getPortalSettings);
  const hiddenFields = useMemo(() => {
    return portalSettings?.hiddenFields || [];
  }, [portalSettings?.hiddenFields?.length]);

  return {
    hiddenFields,
    checkFieldDisplay: fieldId => {
      if (!getIsPortalRoute()) {
        return true;
      }

      return !hiddenFields.find(field => field.id === fieldId);
    },
  };
};
