import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import { getProjectParent as getProjectGroupParent } from '../parsers/parseGanttProject';

import buildGanttSeparator from './buildGanttSeparator';

export default (
  project,
  betLevelSeparators,
  initiativeLevelSeparators,
  hasBet,
  projectGroups,
  getSystemFieldName,
  displayLayer,
) => {
  const useBet = displayLayer === BET_LAYER;

  if (+project.layer === +INITIATIVE_LAYER) {
    if (hasBet && useBet) {
      const groupId = getProjectGroupParent(project);
      let sep = betLevelSeparators.find(sep => sep.parent_id === groupId);
      let isNewSep = false;

      if (!sep) {
        sep = buildGanttSeparator({ id: groupId }, INITIATIVE_LAYER, getSystemFieldName);
        isNewSep = true;
      }

      return {
        betLevelSeparator: {
          object: sep,
          isNew: isNewSep,
        },
      };
    }
  } else if (+project.layer === +IDEA_LAYER) {
    let sep0;
    let isNewSep0 = false;
    const groupId = getProjectGroupParent(project);

    if (hasBet && useBet) {
      sep0 = betLevelSeparators.find(sep => sep.parent_id === groupId);
      if (!sep0) {
        isNewSep0 = true;
        sep0 = buildGanttSeparator({ id: groupId }, INITIATIVE_LAYER, getSystemFieldName);
      }
    }

    const sep1ParentId = sep0 ? sep0.id : groupId;
    let sep1 = initiativeLevelSeparators.find(sep => sep.parent_id === sep1ParentId);
    let isNewSep1 = false;

    if (!sep1) {
      isNewSep1 = true;
      sep1 = buildGanttSeparator({ id: sep1ParentId }, IDEA_LAYER, getSystemFieldName);
    }

    return {
      betLevelSeparator: {
        object: sep0,
        isNew: isNewSep0,
      },
      initiativeLevelSeparator: {
        object: sep1,
        isNew: isNewSep1,
      },
    };
  }
};
