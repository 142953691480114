import React, { useRef } from 'react';
import styled from 'styled-components';
import { pipe, isNil, not } from 'ramda';

import { spacing } from 'design-system/theme';

const isNotNil = pipe(isNil, not);

const EditorContainer = props => {
  const { children, onClick, isDialog, minHeight } = props;

  const editorContainer = useRef(null);

  const { top = 0, left = 0 } =
    isDialog && isNotNil(editorContainer.current) ? editorContainer.current.getBoundingClientRect() : {};

  return (
    <Wrapper
      onClick={onClick}
      ref={el => {
        editorContainer.current = el;
      }}
      top={top}
      left={left}
      calcPosisition={isDialog}
      minHeight={minHeight}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #eee;
  cursor: text;
  padding: ${spacing(2)}px;
  border-radius: 2px;
  background: #fefefe;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : '100px')};
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  line-height: ${({ theme }) => theme.typography.lineHeightSmallLargeRem}rem;

  &&& :global(.public-DraftEditor-content) {
    min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : '100px')};
  }

  ${props =>
    props.calcPosisition &&
    `
      div[role='listbox'] {
        position: fixed;
        transform: translate(${props.left - 20}px, ${props.top - 120}px) !important;
      }
    `}
`;

export default EditorContainer;
