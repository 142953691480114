import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import useUserFavoriteViews from 'hooks/userViews/useUserFavoriteViews';
import useSelectView from 'hooks/userViews/useSelectView';
import useViewsDialog from 'hooks/userViews/useViewsDialog';

import { getActiveViewForPage } from 'store/userViews/selectors';

import getIconForUserView from 'utils/userViews/getIconForUserView';
import { getPageIdFromPath, getViewName } from 'utils/userViews';
import getCurrentPath from 'utils/getCurrentPath';

const VISIBLE_VIEWS_LIMIT = 5;

/*
 *
 * Sub menu component to render the favorite views on left navigation bar
 */
const NavBarSubMenu = () => {
  const { userViews } = useUserFavoriteViews();
  const { handleSelectView } = useSelectView();
  const { openDialog: openViewsDialog } = useViewsDialog();
  const path = getCurrentPath();
  const pageId = getPageIdFromPath(path);
  const activeView = useSelector(state => getActiveViewForPage(state, pageId));

  const visibleViews = useMemo(() => userViews.slice(0, VISIBLE_VIEWS_LIMIT), [userViews]);
  const showMoreVisible = useMemo(() => userViews.length >= VISIBLE_VIEWS_LIMIT, [userViews.length]);

  const renderUserView = userView => {
    const icon = getIconForUserView(userView.page);
    const isActive = userView?.id === activeView?.id;

    return (
      <SubMenuItem onClick={() => handleSelectView(userView)}>
        {icon && <IconWrapper activeView={isActive}>{icon}</IconWrapper>}
        <LabelWrapper activeView={isActive}>{getViewName(userView)}</LabelWrapper>
      </SubMenuItem>
    );
  };

  return (
    <Wrapper>
      {visibleViews.map(renderUserView)}
      {showMoreVisible && <ShowMoreItem onClick={openViewsDialog}>Show more</ShowMoreItem>}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ShowMoreItem = styled.div`
  position: relative;
  height: 33px;
  margin: 0;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  font-size: ${({ theme }) => theme.typography.fontSizeSmall}px;

  display: flex;
  align-items: center;
  padding-left: 6px;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    justify-content: right;

    position: relative;

    svg {
      width: 14px;
    }

    svg path:not([fill='none']) {
      fill: ${({ theme, activeView }) =>
        activeView ? theme.palette.newLayout.background.primary : theme.palette.newLayout.background.disable};
    }

    svg circle,
    svg path:not([fill='none']) {
      color: ${({ theme, activeView }) =>
        activeView ? theme.palette.newLayout.background.primary : theme.palette.newLayout.background.disable};
    }
  }
`;

const LabelWrapper = styled.div`
  &&&& {
    color: ${({ theme, activeView }) =>
      activeView ? theme.palette.newLayout.background.primary : theme.palette.newLayout.background.disable};
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    font-size: ${({ theme }) => theme.typography.fontSizeSmall}px;
    line-height: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
  }
`;

const SubMenuItem = styled.div`
  &&&& {
    display: grid;
    grid-template-columns: 20px 1fr 0;
    align-items: center;
    gap: 10px;
    position: relative;
    height: 33px;
    margin: 0;
    cursor: pointer;

    &:hover {
      ${LabelWrapper} {
        color: ${({ theme }) => theme.palette.newLayout.background.primary};
      }

      ${IconWrapper} {
        svg path:not([fill='none']) {
          fill: ${({ theme }) => theme.palette.newLayout.background.primary};
        }
        svg path:not([fill='none']),
        svg circle {
          color: ${({ theme }) => theme.palette.newLayout.background.primary};
        }
      }
    }
  }
`;

NavBarSubMenu.propTypes = {};

export default NavBarSubMenu;
