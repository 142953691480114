import reduceReducers from 'reduce-reducers';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  FETCH_PROJECT_INTEGRATIONS,
  TEST_CONNECTION,
  TEST_SLACK_CONNECTION,
  CREATE_PROJECT_INTEGRATION,
  ADD_PROJECT_INTEGRATION,
  REMOVE_PROJECT_INTEGRATION,
  UPDATE_PROJECT_STORIES,
  GET_PROJECT_STORIES,
  SYNC_STORIES,
  CREATE_PROJECTS_JIRAS,
  SYNC_PROJECT_FROM_JIRA,
  UPDATE_JIRA_ISSUE_FROM_PROJECT,
  UPDATE_INTEGRATION_PROJECT_FROM_PROJECT,
  SYNC_PROJECTS_JIRA_STORIES,
  TEST_JIRA_CONNECTION,
  JIRA_DISCONNECT,
  JIRA_TICKET_DETAIL,
  JIRA_UNLINK,
  FETCH_JIRA_PROJECTS,
  CREATE_JIRA_CONFIG,
  UPDATE_JIRA_CONFIG,
  FETCH_JIRA_FIELDS,
  SYNC_ORG_PROJECTS_FROM_JIRA,
  FETCH_ADO_PROJECTS,
  REVALIDATE_USER_INTEGRATION_AUTH,
  FETCH_INTEGRATION_FIELDS,
  ENABLE_INTEGRATION_WEBHOOKS,
  DISABLE_INTEGRATION_WEBHOOKS,
  UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS,
  IMPORT_COUNT_INTEGRATION_ITEMS,
  IMPORT_INSERT_INTEGRATION_ITEMS,
  IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC,
  GET_JIRA_PERMISSIONS,
  FETCH_RALLY_WORKSPACES,
  UPDATE_RALLY_INTEGRATION_WORKSPACE,
  FETCH_RALLY_PROJECTS,
  UPDATE_PROJECT_INTEGRATION,
} from '../types';

const { initialState, reducers } = getThunksInitialStateAndReducers([
  FETCH_PROJECT_INTEGRATIONS,
  TEST_CONNECTION,
  TEST_SLACK_CONNECTION,
  CREATE_PROJECT_INTEGRATION,
  ADD_PROJECT_INTEGRATION,
  REMOVE_PROJECT_INTEGRATION,
  UPDATE_PROJECT_STORIES,
  GET_PROJECT_STORIES,
  SYNC_STORIES,
  CREATE_PROJECTS_JIRAS,
  SYNC_PROJECT_FROM_JIRA,
  UPDATE_JIRA_ISSUE_FROM_PROJECT,
  UPDATE_INTEGRATION_PROJECT_FROM_PROJECT,
  UPDATE_PROJECT_INTEGRATION,
  SYNC_PROJECTS_JIRA_STORIES,
  TEST_JIRA_CONNECTION,
  JIRA_DISCONNECT,
  JIRA_TICKET_DETAIL,
  JIRA_UNLINK,
  FETCH_JIRA_PROJECTS,
  CREATE_JIRA_CONFIG,
  UPDATE_JIRA_CONFIG,
  FETCH_JIRA_FIELDS,
  SYNC_ORG_PROJECTS_FROM_JIRA,
  FETCH_ADO_PROJECTS,
  REVALIDATE_USER_INTEGRATION_AUTH,
  FETCH_INTEGRATION_FIELDS,
  ENABLE_INTEGRATION_WEBHOOKS,
  DISABLE_INTEGRATION_WEBHOOKS,
  UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS,
  IMPORT_COUNT_INTEGRATION_ITEMS,
  IMPORT_INSERT_INTEGRATION_ITEMS,
  IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC,
  GET_JIRA_PERMISSIONS,
  FETCH_RALLY_WORKSPACES,
  UPDATE_RALLY_INTEGRATION_WORKSPACE,
  FETCH_RALLY_PROJECTS,
]);

const reducer = reduceReducers(initialState, ...reducers);

export { initialState };

export default reducer;
