import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MuiIconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import Button from '@material-ui/core/Button';

import { spacing } from 'design-system/theme';

import useActionsPermissions from './hooks/useActionsPermissions';

const CommentActions = props => {
  const { classes, commentCreatorId, onEdit, onDelete, editMode, onSave, onCancelEdit, disabledState = {} } = props;

  const { isDeleteAllowed, isEditAllowed } = useActionsPermissions(commentCreatorId);

  if (editMode) {
    return (
      <EditModeWrapper>
        <Button data-testid="save-comment-button" color="primary" onClick={onSave} disabled={disabledState.save}>
          Save
        </Button>
        <Button data-testid="cancel-edit-comment-button" onClick={onCancelEdit} disabled={disabledState.cancel}>
          Cancel
        </Button>
      </EditModeWrapper>
    );
  }

  return (
    <Wrapper>
      {isEditAllowed && (
        <IconButton data-testid="edit-comment-button" onClick={onEdit}>
          <EditIcon className={classes.icon} />
        </IconButton>
      )}
      {isDeleteAllowed && (
        <IconButton data-testid="delete-comment-button" onClick={onDelete}>
          <DeleteIcon className={classes.icon} />
        </IconButton>
      )}
    </Wrapper>
  );
};

CommentActions.propTypes = {
  classes: PropTypes.object,
  commentCreatorId: PropTypes.number,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

const EditModeWrapper = styled.div`
  padding: ${spacing()}px 0;
  justify-content: end;
  display: flex;
`;

const Wrapper = styled.div`
  margin-left: -${spacing()}px;
`;

const IconButton = styled(MuiIconButton)`
  &&&& {
    padding: ${spacing()}px;
  }
`;

const styles = theme => ({
  icon: {
    color: theme.typography.color,
    fontSize: '16px',
  },
});

export default withStyles(styles)(CommentActions);
