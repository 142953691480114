import React from 'react';

import SalesforceIcon from 'design-system/atoms/SalesforceIcon';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

const INTEGRATION_LOGO_MAPPER = {
  [INTEGRATIONS_KEYS.salesforce]: <SalesforceIcon style={{ width: 40, height: 40, marginRight: 16 }} />,
};

const getIntegrationLogo = integrationType => {
  return INTEGRATION_LOGO_MAPPER[integrationType] || '';
};

export default getIntegrationLogo;
