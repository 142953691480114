import React, { Component } from 'react';
import { pipe, isNil, isEmpty, not, either } from 'ramda';

import Content from '../components/Content';
import Title from '../components/Title';
import Body from '../components/Body';
import SetupIntegrationToken from '../components/SetupIntegrationToken';
import DisconnectIntegration from '../components/DisconnectIntegration/DisconnectIntegration';
import ClubHouseIcon from 'design-system/atoms/ClubHouseIcon/index';


const isNilOrEmpty = either(isNil, isEmpty);
const isNotNilOrEmpty = pipe(isNilOrEmpty, not);

export default class Clubhouse extends Component {
  saveUserToken = () => {};

  render() {
    const {
      removeIntegration,
      addUserIntegration,
      userIntegration,
      orgIntegration,
      userCanUpdateOrgIntegration,
      userCanViewOrgIntegration,
     } = this.props;

    const canCreateUserIntegration =
      (isNotNilOrEmpty(orgIntegration) && userCanViewOrgIntegration) || userCanUpdateOrgIntegration;

    const shouldShowSetupTokenForm = isNilOrEmpty(userIntegration) && canCreateUserIntegration;

    return (
      <Content>
        <Title
          title="Shortcut Integration"
          logo={<ClubHouseIcon size={30} marginRight={20} />}
          helpLink="https://dragonboat.zendesk.com/hc/en-us/articles/360036414254"
        />
        <Body>
          {!orgIntegration && !userCanUpdateOrgIntegration && (
            <p>Please contact your organization Admin to activate Shortcut integration.</p>
          )}
          {shouldShowSetupTokenForm && (
            <SetupIntegrationToken
              integrationName="Shortcut"
              integrationHelpLink="https://app.shortcut.com/settings/account/api-tokens"
              saveToken={token => addUserIntegration(token)}
            />
          )}
          {userIntegration && (
            <DisconnectIntegration
              integrationName="Shortcut"
              disconnect={removeIntegration}
              disabled={!userCanUpdateOrgIntegration}
            />
          )}
        </Body>
      </Content>
    );
  }
}
