import React from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

const componentHOC = Component => {
  return props => {
    const { taskId } = props;
    const [history, setHistory] = React.useState([]);
    const [historyMeta, setHistoryMeta] = React.useState({});
    const systemFields = useSelector(state => state.organization.organization.system_fields_name);
    const fetchNextPage = () => axios.get(historyMeta.next).then(handleData);

    React.useEffect(() => {
      axios.get(`/api/tasks/${taskId}/history`).then(({ data }) => {
        setHistory(data.data);
        setHistoryMeta(data._meta);
      });
    }, []);

    const handleData = ({ data }) => {
      setHistory([...history, ...data.data]);
      setHistoryMeta(data._meta);
    };

    return (
      <Component
        {...props}
        systemFields={systemFields}
        history={history}
        hasNext={historyMeta.next}
        fetchNextPage={fetchNextPage}
      />
    );
  };
};

export default componentHOC;
