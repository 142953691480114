import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { css } from 'styled-components';

import { genericStyles } from 'containers/IdeasList/IdeasList/New/styled';
import theme from 'design-system/theme';

import { selectRoadmapHistorySnapsForCurrentView } from 'features/RoadmapHistory/store/selectors';
import { getSnapBackgroundColorCssKey } from '../helpers/getSnapBackgroundColorCssKey';

const opacity = 20;

const headerStyles = css`
  &&&&& {
    .no-uppercase .ag-header-cell-text {
      text-transform: unset;
    }

    .with-uppercase {
      text-transform: uppercase;
    }

    .border-left {
      border-left: solid 1px;
      border-left-color: #e2e2e2; // default used by ag grid
    }
  }
`;

const cellHighlightedClass = `.cellHighlighted { background-color: ${theme.palette.background.highlight}; }; `;

const useGridStylesWithSnapsHeaderColors = () => {
  const viewSnaps = useSelector(selectRoadmapHistorySnapsForCurrentView);

  return useMemo(() => {
    const classColors = viewSnaps.reduce((acc, snap) => {
      const classKey = getSnapBackgroundColorCssKey(snap?.id);
      const snapShotClass = `.${classKey} { background-color: ${snap?.color}${opacity}; }`;

      return `${acc} ${snapShotClass}`;
    }, '');

    return `
      ${genericStyles} 
      ${classColors}
      ${headerStyles}
      ${cellHighlightedClass}
    `;
  }, [viewSnaps]);
};

export default useGridStylesWithSnapsHeaderColors;
