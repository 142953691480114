import { INTEGRATIONS_KEYS } from 'constants/integrations';

import authenticateOnAzureDevops from './authenticateOnAzureDevops';

/**
 * @function openAuthenticationHandler
 *
 * Open the authentication mechanism for a given integration type.
 *
 * @param {String} integrationType
 * @returns {Promise}
 */
const openAuthenticationHandler = integrationType => {
  switch (integrationType) {
    case INTEGRATIONS_KEYS.azuredevops:
      return authenticateOnAzureDevops();
    default:
  }
};

export default openAuthenticationHandler;
