import numericColumnDef from './numericColumnDef';

const targetAllocationAmountColumnDef = {
  ...numericColumnDef,
  field: 'target_allocation_amount',
  headerName: 'Target Allocation Amount',
  width: 150,
  minWidth: 100,
};

export default targetAllocationAmountColumnDef;
