import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  FETCH_PROJECTS_CHILDREN,
  CREATE_PROJECT,
  BULK_CREATE_PROJECTS,
  IMPORT_PROJECTS,
  UPDATE_PROJECT,
  UPDATE_PROJECTS,
  BULK_UPDATE_PROJECTS,
  UNDO_BULK_UPDATE_PROJECTS,
  DELETE_PROJECTS,
  UNDO_DELETE_PROJECTS,
  UPDATE_PROJECT_ROW_ORDER,
  WATCH_PROJECT,
  UNWATCH_PROJECT,
  FETCH_PROJECT_CUSTOMER_REQUESTS,
  GET_PROJECT_COUNTERS_BY_PHASE,
  MERGE_PROJECTS,
  CLONE_PROJECT,
  APPLY_FILTERS,
  LOAD_PROJECTS_FROM_AUTOCOMPLETE,
  LOAD_PROJECTS_FROM_AUTOCOMPLETE_TITLE,
} from '../types';

const { initialState, reducers } = getThunksInitialStateAndReducers([
  FETCH_PROJECTS_CHILDREN,
  CREATE_PROJECT,
  BULK_CREATE_PROJECTS,
  IMPORT_PROJECTS,
  UPDATE_PROJECT,
  UPDATE_PROJECTS,
  BULK_UPDATE_PROJECTS,
  UNDO_BULK_UPDATE_PROJECTS,
  DELETE_PROJECTS,
  UNDO_DELETE_PROJECTS,
  UPDATE_PROJECT_ROW_ORDER,
  WATCH_PROJECT,
  UNWATCH_PROJECT,
  FETCH_PROJECT_CUSTOMER_REQUESTS,
  GET_PROJECT_COUNTERS_BY_PHASE,
  MERGE_PROJECTS,
  CLONE_PROJECT,
  APPLY_FILTERS,
  LOAD_PROJECTS_FROM_AUTOCOMPLETE,
  LOAD_PROJECTS_FROM_AUTOCOMPLETE_TITLE,
  'summarize_projects',
]);

export { initialState };

export default reducers;
