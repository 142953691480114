import parseGanttEstimate from '../parsers/parseGanttEstimate';

export default (teams, collapseGanttBars, sortOrder) => p => {
  if (!p.estimates) return [];
  return p.estimates
    .map(est => {
      if (est.team) teams.push(est.team_id);
      return parseGanttEstimate(est, p, collapseGanttBars);
    })
    .sort(sortOrder);
};
