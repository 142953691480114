// Project Field Layout Configuration
import axios from 'axios';
import { createThunk } from 'utils/store/thunk';
import throwRequestError from 'store/utils/throwRequestError';

import {
  FETCH_CYCLE_DELIVERABLES,
  FETCH_PROJECTS_DELIVERABLES,
  UPSERT_PROJECT_DELIVERABLE,
  DELETE_PROJECT_DELIVERABLE,
  CREATE_CYCLE,
  CREATE_DELIVERABLE,
  DELETE_CYCLE_DELIVERABLE,
  UPDATE_CYCLE,
  UPDATE_DELIVERABLE,
  UPDATE_CYCLE_DELIVERABLE_ROW_ORDER,
  FETCH_PROJECT_DELIVERABLE,
} from './types';
import { CYCLE_DELIVERABLE_LEVEL } from 'constants/common';
import { getAllCycleDeliverables } from 'store/cycleDeliverables/selectors';

import updateCycleDeliverableApiCall from 'store/cycleDeliverables/helpers/updateCycleDeliverableApiCall';

const isCycle = cycleDeliverable => cycleDeliverable?.level === CYCLE_DELIVERABLE_LEVEL.cycle;
const isDeliverable = cycleDeliverable => cycleDeliverable?.level === CYCLE_DELIVERABLE_LEVEL.deliverable;

const fetchCycleDeliverables = () =>
  createThunk(FETCH_CYCLE_DELIVERABLES, axios.get(`/api/cycle-deliverables`).catch(throwRequestError));

const fetchProjectsDeliverables = search =>
  createThunk(FETCH_PROJECTS_DELIVERABLES, axios.post('/api/projects/search/deliverables', search).catch(throwRequestError));

const upsertProjectDeliverable = (projectId, deliverableId, data = {}) =>
  createThunk(
    UPSERT_PROJECT_DELIVERABLE,
    axios
      .post(`/api/projects/${projectId}/deliverables`, { cycle_deliverable_id: deliverableId, ...data })
      .catch(throwRequestError),
  );

const deleteProjectDeliverable = (projectId, cycleDeliverableId) =>
  createThunk(
    DELETE_PROJECT_DELIVERABLE,
    axios.delete(`/api/projects/${projectId}/deliverables/${cycleDeliverableId}`).catch(throwRequestError),
    { projectId, cycleDeliverableId },
  );

const fetchProjectDeliverableDetails = (projectId, cycleDeliverableId) => dispatch =>
  dispatch(
    createThunk(
      FETCH_PROJECT_DELIVERABLE,
      async () => {
        const projectDeliverableResponse = await axios
          .get(`/api/projects/${projectId}/deliverables/${cycleDeliverableId}`)
          .catch(throwRequestError);
        const projectDeliverableId = projectDeliverableResponse.data?.id;

        if (!projectDeliverableId) {
          return { projectDeliverable: projectDeliverableResponse.data, comments: {} };
        }

        const commentsResponse = await axios
          .get(`/api/projects/deliverables/${projectDeliverableId}/comments`)
          .catch(throwRequestError);

        return {
          projectDeliverable: projectDeliverableResponse.data,
          comments: commentsResponse.data,
        };
      },
      { projectId, cycleDeliverableId },
    ),
  );

const createCycle = cycleData =>
  createThunk(
    CREATE_CYCLE,
    axios
      .post('/api/cycle-deliverables', { ...cycleData, level: CYCLE_DELIVERABLE_LEVEL.cycle })
      .then(({ data }) => data)
      .catch(throwRequestError),
  );

const createDeliverable = cycleData =>
  createThunk(
    CREATE_DELIVERABLE,
    axios
      .post('/api/cycle-deliverables', { ...cycleData, level: CYCLE_DELIVERABLE_LEVEL.deliverable })
      .then(({ data }) => data)
      .catch(throwRequestError),
  );

const deleteCycleDeliverable = (id, itemToDelete) =>
  createThunk(
    DELETE_CYCLE_DELIVERABLE,
    axios
      .delete(`/api/cycle-deliverables/${id}`)
      .then(({ data }) => data)
      .catch(throwRequestError),
    { id, level: itemToDelete?.level, parent_id: itemToDelete.parent_id },
  );

const updateCycle = (cycleId, cycleData) => createThunk(UPDATE_CYCLE, updateCycleDeliverableApiCall(cycleId, cycleData));

const updateDeliverable = (deliverableId, deliverableData, onUpdateError) =>
  createThunk(UPDATE_DELIVERABLE, updateCycleDeliverableApiCall(deliverableId, deliverableData, onUpdateError));

const switchCycleDeliverableRowOrder = (cycleDeliverableId1, cycleDeliverableId2, position) => {
  return async (dispatch, getState) => {
    if (!cycleDeliverableId1 || !cycleDeliverableId2 || !position) return;

    const cycleDeliverables = getAllCycleDeliverables(getState());

    const selectedCycleDeliverable = cycleDeliverables.find(({ id }) => id === cycleDeliverableId1);
    const moveToCloseDeliverable = cycleDeliverables.find(({ id }) => id === cycleDeliverableId2);

    if (!selectedCycleDeliverable || !moveToCloseDeliverable) return;

    const movingCycleIntoDelivery = isCycle(selectedCycleDeliverable) && isDeliverable(moveToCloseDeliverable);
    const haveDifferentLevels = selectedCycleDeliverable?.level !== moveToCloseDeliverable?.level;
    const movedToParentWithoutChildren = selectedCycleDeliverable.parent_id && !moveToCloseDeliverable.parent_id;
    const movedToDifferentParent = selectedCycleDeliverable.parent_id !== moveToCloseDeliverable.parent_id;

    const updateAndOrderPromise = async () => {
      if ((movedToParentWithoutChildren || haveDifferentLevels) && !movingCycleIntoDelivery) {
        await updateCycleDeliverableApiCall(selectedCycleDeliverable.id, { parent_id: moveToCloseDeliverable.id });
      } else if (movedToDifferentParent && !movingCycleIntoDelivery) {
        await updateCycleDeliverableApiCall(selectedCycleDeliverable.id, { parent_id: moveToCloseDeliverable.parent_id });
      }

      return axios
        .put(`/api/cycle-deliverables/rowOrder/${cycleDeliverableId1}/${cycleDeliverableId2}`, { position })
        .then(({ data }) => data)
        .catch(throwRequestError);
    };

    return dispatch(
      createThunk(UPDATE_CYCLE_DELIVERABLE_ROW_ORDER, updateAndOrderPromise(), {
        prev: { parent_id: selectedCycleDeliverable.parent_id },
      }),
    );
  };
};

export {
  fetchCycleDeliverables,
  fetchProjectsDeliverables,
  upsertProjectDeliverable,
  deleteProjectDeliverable,
  fetchProjectDeliverableDetails,
  createCycle,
  createDeliverable,
  deleteCycleDeliverable,
  updateCycle,
  updateDeliverable,
  switchCycleDeliverableRowOrder,
};
