import React from 'react';
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';
import isEmpty from 'lodash/isEmpty';
import { not, pipe, defaultTo, ifElse, equals, both, type, isNil } from 'ramda';

import { FORECAST_BY_HEADCOUNT, FORECAST_BY_TIME_CAPACITY } from 'constants/forecast';
import theme from 'design-system/theme';
import formatTwoDecimalPlaces from 'utils/estimates/formatToTwoDecimals';
import {
  checkIfShouldShowTimeframeCapacityOnGrid,
  checkCapacityCellIsNotOnSameResourceTeamGroupRow,
} from 'utils/releaseCapacityAllocation';

const isObject = v => equals(type(v), 'Object');
const isEmptyObject = both(isObject, isEmpty);
const defaultAsZero = defaultTo(0);
const defaultEmptyObject = defaultTo({});
const isNotNil = pipe(isNil, not);

const isForecastByTimeCapacity = equals(FORECAST_BY_TIME_CAPACITY);

/**
 * We want for the group cell sum to be conditional.
 * Only consider the value of child items if they are committed or if include uncommitted toggle is on.
 *
 * If below are not include we want to show the value on grid the but cell should not have it as value.
 * Therefore, we need to handle the cell value on the formatter with the real value by using the toString property that
 * will retrieve its value (https://www.ag-grid.com/angular-data-grid/aggregation-custom-functions/#multi-column-aggregation)
 * The sum func will consider the value on conditionalValue.
 * @param val
 * @return {{value: string}|*}
 */
const getValueFromGridValue = val => {
  const hasConditionalValueToSum = isNotNil(val?.conditionalValue);
  const hasToStringValueGen = Boolean(val.toString);

  if (isObject(val) && hasConditionalValueToSum && hasToStringValueGen) {
    return { value: val.toString() };
  }
  return val;
};

/**
 * @function getEstimateCellValueFormatter
 *
 * returns the function to format the value for estimates columns
 *
 * @param  {Number} resourcesCount
 * @param  {Boolean} isEstimatingByPoints
 * @return {Function}
 */
const getEstimateCellValueFormatter =
  (resourcesCount, isEstimatingByPoints = false) =>
  cellParams => {
    const { value: gridValue, colDef, data } = cellParams;

    const { forecastBy, selectedGroup1, selectedGroup2 } = cellParams.context;

    const returnHeadcountValue = () => {
      const headcountValue = parseFloat(resourcesCount - defaultAsZero(gridValue?.sum));

      if (gridValue?.sum <= 0) return '';

      return formatTwoDecimalPlaces(headcountValue);
    };
    const returnTimeValue = () => {
      const keySumToConsider = isEstimatingByPoints ? 'sumEstimatePoints' : 'sum';

      const showTimeframeCapacityOnGrid = checkIfShouldShowTimeframeCapacityOnGrid(
        selectedGroup1,
        selectedGroup2,
        data.groupOption,
      );

      const defaultTimeValue =
        gridValue?.[keySumToConsider] > 0 ? (
          <span
            style={{
              color: isEstimatingByPoints ? theme.palette.text.accent : 'inherit',
            }}
          >
            {`${formatTwoDecimalPlaces(gridValue?.[keySumToConsider])} ${isEstimatingByPoints ? 'points' : 'wks'}`}
          </span>
        ) : (
          ''
        );

      if (isForecastByTimeCapacity(forecastBy) && showTimeframeCapacityOnGrid) {
        const { field } = defaultEmptyObject(colDef);

        const dataFieldValue = defaultAsZero(defaultEmptyObject(data[field]).value);

        // all empty, not show anything
        if (defaultAsZero(gridValue?.[keySumToConsider]) === 0 && dataFieldValue === 0) {
          return '';
        }

        if (
          checkCapacityCellIsNotOnSameResourceTeamGroupRow(
            selectedGroup1,
            selectedGroup2,
            data.groupOption,
            data.groupData,
            colDef,
          )
        ) {
          return defaultTimeValue;
        }

        return (
          <b style={{ fontWeight: theme.typography.fontWeightBold }}>
            {formatTwoDecimalPlaces(dataFieldValue)} (
            <span
              style={{
                color: dataFieldValue >= gridValue?.[keySumToConsider] ? theme.palette.text.green : theme.palette.text.error,
              }}
            >
              {formatTwoDecimalPlaces(gridValue?.[keySumToConsider])}
            </span>
            )
          </b>
        );
      }

      return defaultTimeValue;
    };
    const returnCellValue = () => {
      const value = getValueFromGridValue(gridValue);

      return value?.value && !isNaN(+value.value) && isNumber(+value.value) ? formatTwoDecimalPlaces(+value.value) : '';
    };
    const returnGroupValue = ifElse(equals(FORECAST_BY_HEADCOUNT), returnHeadcountValue, returnTimeValue);

    switch (true) {
      case isEmptyObject(gridValue):
        return '';
      case data?.group:
        return returnGroupValue(forecastBy);
      default:
        return returnCellValue();
    }
  };

export default getEstimateCellValueFormatter;
