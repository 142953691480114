const getMultiChipCellRendererParams = params => {
  const values = Array.isArray(params.value) ? params.value : [];

  return {
    items: values.map(item => ({
      id: item.title,
      label: item.title,
      color: item.color,
    })),
  };
};

export default getMultiChipCellRendererParams;
