import { useMemo } from 'react';
import { pluck, flatten, prop, defaultTo, pipe } from 'ramda';
import isString from 'lodash/isString';

const CHILDREN_KEY = 'children';
const NAME = 'name';

const defaultToEmptyObject = defaultTo({});
const mapChildren = pluck(CHILDREN_KEY);
const getName = pipe(defaultToEmptyObject, prop(NAME));

/**
 * @function useRoadmapMetadataOptionsForDropdown
 *
 * Use roadmap metadata options for dropdown select
 *
 * @param  {String} props.inputValue
 * @param  {String} props.value
 * @param  {Array} props.options
 * @return {Object}
 */
const useRoadmapMetadataOptionsForDropdown = ({ inputValue, value, options }) => {
  const filteredOptions = useMemo(() => {
    const filterOptionByName = o => {
      if (inputValue === value) return true;

      const optionValue = isString(o) ? o : getName(o);

      return optionValue?.toLowerCase().includes(inputValue?.toLowerCase().replace(/ +/gi, ' '));
    };

    return options.map(opt => {
      return {
        ...opt,
        children: opt.children.filter(filterOptionByName),
      };
    }, []);
  }, [options, inputValue, value]);

  // get all options inside of options[].children
  const allOptions = useMemo(() => flatten(mapChildren(filteredOptions)), [filteredOptions]);

  return {
    allOptions,
    filteredOptions,
  };
};

export default useRoadmapMetadataOptionsForDropdown;
