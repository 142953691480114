import React, { useEffect, useState, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { path as ramdaPath } from 'ramda';

import DndDragLayout from 'design-system/molecules/DndDragLayout/index';
import DndItem from 'design-system/molecules/DndItem/index';
import DndMenuList from 'design-system/molecules/DndMenuList/index';
import DndAtoms from 'design-system/atoms/DndAtoms/index';

import { checkedObject } from 'design-system/utils';
import filterDndItemActions from 'design-system/utils/filterDndItemActions';

const { MenuIcon, ArrowDown, ArrowRight } = DndAtoms;

const exist = Boolean;

const DefaultMenuIcon = ({ item, open, onClick }) => (
  <MenuIcon onClick={onClick}>{!!item.children && !!item.children.length && (open ? <ArrowDown /> : <ArrowRight />)}</MenuIcon>
);

export default ({
  index,
  item,
  selectedItems,
  openItems,
  parentId,
  rootList,
  draggable,
  checkable,
  colorable,
  isSubTree,
  onSelectItem,
  onClickItem,
  onChangeColor,
  onGroupOpenClose,
  onSeparatorOpenClose,
  showIndeterminate,
  checkedByDefault,
  showTooltip,
  itemRightActions = [],
  renderCustomItem,
  useHierarchy = false,
  showActionsOnHover,
  onOpen,
  path = [],
  itemHeight,
  avoidVirtualization,
  onItemKeyDown,
  onSelectItemWithKeyboard,
  parentListRef,
}) => {
  const { dndItemComponent, menuIconComponent } = item;
  const currentPath = useMemo(() => [...path, item?.id], [path, item?.id]);
  const [checkedItems, setCheckedItems] = useState(selectedItems);
  const open = ramdaPath(currentPath, openItems)?.open || false;

  const _getItemStyle = useMemo(() => {
    if (item?.avoidRender) {
      return { display: 'none' };
    }

    const paddingLeft = (`${parentId}`.split('-').length - 1 || 0) * 10;

    let rightActionsTemplateColumns = '';

    filterDndItemActions(itemRightActions, item).forEach(() => {
      rightActionsTemplateColumns = `${rightActionsTemplateColumns} 12px`;
    });

    const treeViewColumn = exist(useHierarchy) ? '12px' : '';

    if (draggable && checkable && colorable) {
      return {
        gridTemplateColumns: `12px 12px 12px ${treeViewColumn} 1fr${rightActionsTemplateColumns}`,
        paddingLeft: paddingLeft + 15,
        ...item?.style,
      };
    }

    if ((checkable && colorable) || (checkable && draggable) || (colorable && draggable)) {
      return {
        gridTemplateColumns: `12px 12px ${treeViewColumn} 1fr${rightActionsTemplateColumns}`,
        paddingLeft: paddingLeft + 15,
        ...item?.style,
      };
    }

    if (checkable || colorable || draggable) {
      return {
        gridTemplateColumns: `12px ${treeViewColumn} 1fr${rightActionsTemplateColumns}`,
        paddingLeft: paddingLeft + 15,
        ...item?.style,
      };
    }

    return {
      gridTemplateColumns: `${treeViewColumn} 1fr${rightActionsTemplateColumns}`,
      paddingLeft: paddingLeft + 15,
      ...item?.style,
    };
  }, [item, parentId, draggable, checkable, colorable, useHierarchy]);

  const _getSelectedItems = items => {
    if (typeof checkedItems === 'boolean') return checkedItems;
    if (!checkedItems) return false;

    return checkedByDefault ? checkedItems[item.id] : checkedObject(items, checkedItems) || checkedItems[item.id];
  };

  useEffect(() => {
    setCheckedItems(selectedItems);
  }, [selectedItems, setCheckedItems]);

  const _handleSelectItem = (itemId, selected, item) => {
    setCheckedItems(selected);
    onSelectItem(itemId, selected, item);
  };

  const _handleClickItem = item => {
    if (onClickItem) onClickItem(item);
  };

  const _handleSelectChildItem = (itemId, value, changedItem) => {
    const i = _getSelectedItems(item.children);

    let newCheckedItems;

    if (i) {
      newCheckedItems = item.children.reduce(
        (v, it) => ({
          ...v,
          [it.id]: true,
        }),
        {},
      );
    } else if (typeof checkedItems === 'object') {
      newCheckedItems = cloneDeep(checkedItems);
    } else {
      newCheckedItems = {};
    }
    newCheckedItems[itemId] = value;

    setCheckedItems(newCheckedItems);
    onSelectItem(item.id, newCheckedItems, changedItem);
  };

  const _getNeedsIndeterminate = checkedItems => {
    if (typeof checkedItems === 'boolean') return false;

    return (
      checkedItems &&
      Object.values(checkedItems).some(checked => {
        if (typeof checked === 'object') {
          return _getNeedsIndeterminate(checked);
        }
        return checked;
      })
    );
  };

  const _renderParent = () => {
    const _handleChangeParentColor = color => {
      onChangeColor([item.id], color);
    };

    const _handleGroupOpenClose = e => {
      if (onGroupOpenClose) {
        onGroupOpenClose(e, item, currentPath);
      }

      onOpen(currentPath, !open);
    };

    const DndMenuIconComponent = menuIconComponent || DefaultMenuIcon;
    const DndItemComponent = dndItemComponent || DndItem;

    return (
      <>
        {useHierarchy && <DndMenuIconComponent item={item} open={open} onClick={_handleGroupOpenClose} />}
        <DndItemComponent
          rootList={rootList}
          item={item}
          selected={_getSelectedItems(item.children)}
          checkable={checkable}
          colorable={colorable}
          onSelectItem={_handleSelectItem}
          onClickItem={_handleClickItem}
          onChangeColor={_handleChangeParentColor}
          showIndeterminate={showIndeterminate && _getNeedsIndeterminate(checkedItems)}
          checkedByDefault={checkedByDefault}
          showTooltip={showTooltip}
          itemRightActions={itemRightActions}
          renderCustomItem={renderCustomItem}
        />
      </>
    );
  };

  const _renderChildren = () => {
    if (item.children && item.children.length && open) {
      const onChangeChildColor = (itemIds, color) => {
        onChangeColor([item.id, ...itemIds], color);
      };

      parentListRef?.current?.resetAfterIndex(0);

      return (
        <DndMenuList
          items={item.children}
          parentId={`${parentId}-${item.id}`}
          selectedItems={checkedItems}
          openItems={openItems}
          draggable={draggable}
          checkable={checkable}
          colorable={colorable}
          isSubTree={isSubTree}
          onChangeColor={onChangeChildColor}
          onSelectItem={_handleSelectChildItem}
          onClickItem={_handleClickItem}
          onGroupOpenClose={onGroupOpenClose}
          onSeparatorOpenClose={onSeparatorOpenClose}
          checkedByDefault={checkedByDefault}
          showIndeterminate={showIndeterminate && _getNeedsIndeterminate(checkedItems)}
          showTooltip={showTooltip}
          renderCustomItem={renderCustomItem}
          onOpen={onOpen}
          path={currentPath}
          itemHeight={itemHeight}
          avoidVirtualization={avoidVirtualization}
          onSelectItemWithKeyboard={onSelectItemWithKeyboard}
          parentListRef={parentListRef}
          useHierarchy
        />
      );
    }

    return <></>;
  };

  return (
    <DndDragLayout
      id={`${parentId}-${item.id}`}
      index={index}
      draggable={draggable}
      isSubTree={isSubTree}
      style={_getItemStyle}
      active={item.active}
      renderParent={_renderParent}
      renderChildren={_renderChildren}
      showActionsOnHover={showActionsOnHover}
      onItemKeyDown={onItemKeyDown}
    />
  );
};
