import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

export default class NewLaneTemplate extends Component {
  updateField = (field, value) => {
    this.setState({ [field]: value });
  };

  handleAdd = () => {
    this.props.onAdd(this.state);
  };

  render() {
    const { onCancel } = this.props;

    return (
      <Section>
        <LaneTitle>
          <Text
            placeholder="title"
            onChange={e => this.updateField('title', e.target.value)}
            onKeyDown={(e) => e.keyCode === 13 ? this.handleAdd() : undefined}
            autoFocus
          />
        </LaneTitle>
        <NewLaneButtons>
          <AddButton onClick={this.handleAdd}>Add</AddButton>
          <CancelButton onClick={onCancel}>Cancel</CancelButton>
        </NewLaneButtons>
      </Section>
    );
  }
}

const Section = styled.div`
  width: 200px;
  background-color: rgb(227, 227, 227);
  position: relative;
  display: inline-flex;
  height: auto;
  max-height: 90%;
  flex-direction: column;
  border-radius: 3px;
  margin: 5px;
  padding: 10px;
`;

const LaneTitle = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const Text = styled(TextField)`
  width: 100%;

  > div:before, > div:after {
    border-bottom: none;
  }

  input{
    width: 100%;
    padding: 6px;
    border: 1px solid #d8d5d5;
    border-radius: 2px;
  }
`;

const NewLaneButtons = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddButton = styled(Button)`
  &&&& {
    background: #00c8dd;
    width: 100%;
    color: rgb(255, 255, 255);
    min-height: 32px;
    vertical-align: top;
    margin-top: 0px;
    margin-right: 8px;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 0px;
    background: rgb(90, 172, 68);
    transition: background 0.3s ease 0s;
    padding: 4px 16px;
    border-radius: 3px;
    text-transform: initial;
  }
`;

const CancelButton = styled(Button)`
  &&&& {
    background: rgb(153, 153, 153);
    width: 100%;
    color: rgb(255, 255, 255);
    min-height: 32px;
    vertical-align: top;
    margin-top: 0px;
    margin-right: 8px;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 0px;
    transition: background 0.3s ease 0s;
    padding: 4px 16px;
    border-radius: 3px;
    text-transform: initial;
    margin-top: 3px;
  }
`;
