import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { trim } from 'ramda';

const CreateOrgIntegration = ({ onClickNext }) => {
  const [orgUri, setOrgUri] = useState('');

  const _handleURIInput = urlInput => {
    setOrgUri(trim(urlInput));
  };

  const _handleOnClick = useCallback(
    e => {
      e.preventDefault();
      if (onClickNext) onClickNext(orgUri);
    },
    [orgUri],
  );

  return (
    <Wrapper>
      <URLTextField
        id="fileName"
        label="Insert your Zendesk organization URL"
        placeholder="yourorganization.zendesk.com"
        value={orgUri}
        onChange={e => _handleURIInput(e.target.value)}
        margin="normal"
      />
      <NextButton type="submit" color="primary" onClick={_handleOnClick}>
        Next
      </NextButton>
    </Wrapper>
  );
};

CreateOrgIntegration.propTypes = {
  onClickNext: PropTypes.func,
};

const URLTextField = styled(TextField)`
  &&&& {
    max-width: 600px;
  }
`;

const NextButton = styled(Button)`
  &&&& {
    margin-left: 5px;
  }
`;

const Wrapper = styled.form`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  ${NextButton} {
    width: 100px;
    height: 40px;
  }

  ${URLTextField} {
    flex-grow: 1;
    margin-top: 0px;
  }
`;

export default CreateOrgIntegration;
