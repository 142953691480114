import React, { useCallback, useMemo, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import { useSelector } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import getSystemFieldName from 'utils/getSystemFieldName';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import SearchBar from 'components/SearchBar';
import { getObjectives } from 'store/objectives/selectors';
import { getTimeframes } from 'store/timeframes/selectors';
import theme from 'design-system/theme';

export default ({ onClose }) => {
  const { isDodActive } = useOrganizationsAccessControl();
  const [systemFieldSearchString, setSystemFieldSearchString] = useState('');

  const {
    system_fields_name: systemFields,
    has_key_results: hasKeyResults,
    has_key_results_2: hasKeyResults2,
    has_products: hasProducts,
    has_products_2: hasProducts2,
  } = useSelector(state => state.organization.organization);

  const { objectives, timeframes } = useSelector(state => {
    const objectives = getObjectives(state);
    const timeframes = getTimeframes(state);

    return { objectives, timeframes };
  });

  const exampleMetadata = useMemo(() => {
    return {
      objective: objectives[Math.floor(Math.random() * objectives.length)]?.title || '',
      timeframe: timeframes[Math.floor(Math.random() * timeframes.length)]?.title || '',
    };
  }, []);

  const translateSystemFieldName = useCallback(key => getSystemFieldName(key, systemFields), [systemFields]);

  const sortedSystemFields = useMemo(() => {
    const selectedSystemFields = [
      'objective',
      'roadmap',
      'category',
      'team',
      'team2',
      'theme',
      'timeframe',
      'tag',
      'customer',
      'initiative',
      'bet',
      'idea',
      ...(isDodActive ? ['objectiveCorp', 'roadmapCorp', 'timeframeCorp'] : []),
      ...(hasKeyResults ? ['keyResult1'] : []),
      ...(hasKeyResults2 ? ['keyResult2'] : []),
      ...(hasProducts ? ['product1'] : []),
      ...(hasProducts2 ? ['product2'] : []),
    ];

    return selectedSystemFields
      .map(key => translateSystemFieldName(key))
      .filter(label => label.toLowerCase().includes(systemFieldSearchString.toLowerCase()))
      .sort();
  }, [translateSystemFieldName, isDodActive, hasKeyResults, hasKeyResults2, hasProducts, hasProducts2, systemFieldSearchString]);

  const examplePrompts = useMemo(() => {
    const ideaName = getSystemFieldName('idea');
    const initiativeName = getSystemFieldName('initiative');

    return [
      `What is our overall goal ${exampleMetadata.objective} progress`,
      `Progress on ${exampleMetadata.objective}`,
      'What is my portfolio risk?',
      `What projects do we have in progress to achieve ${exampleMetadata.timeframe}?`,
      `What are we working on to reach ${exampleMetadata.timeframe}?`,
      `What projects are being developed to achieve ${exampleMetadata.timeframe}?`,
      `What ${ideaName} are being developed to achieve ${exampleMetadata.timeframe}?`,
      `What ${initiativeName} are being developed to achieve ${exampleMetadata.timeframe}?`,
    ];
  }, [exampleMetadata]);

  return (
    <ThemeProvider theme={theme}>
      <DragonbotHelpWindow>
        <FlexEnd>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </FlexEnd>
        <TextDeprecated variant="h6" gutterBottom>
          Example prompts
        </TextDeprecated>
        {examplePrompts.map(prompt => (
          <TextDeprecated style={{ fontWeight: 500 }} gutterBottom>
            {prompt}
          </TextDeprecated>
        ))}
        <StyledText style={{ marginTop: '1.5em' }} color={theme.palette.newLayout.text.siroccoGrey}>
          Besides this example prompts you can combine any of the system fields below with the item you want to search, example:
        </StyledText>
        <StyledText style={{ marginLeft: '1em', fontWeight: 500 }} gutterBottom>
          Work done on
          <span> {getSystemFieldName('objective')} </span>
          <span>{exampleMetadata.objective}</span> and
          <span> {getSystemFieldName('timeframe')} </span>
          {exampleMetadata.timeframe}
        </StyledText>
        <StyledText style={{ marginBottom: '1.5em' }}>You can search the valid system field names below</StyledText>
        <TextDeprecated variant="h6" gutterBottom>
          List of system field names
        </TextDeprecated>
        <SearchBar
          searchString={systemFieldSearchString}
          style={{ marginRight: 15, marginTop: 5, marginBottom: 10 }}
          updateSearchString={value => setSystemFieldSearchString(value)}
        />
        <StyledList component="div" disablePadding>
          {sortedSystemFields.map(field => (
            <ListItem>
              <TextDeprecated>{field}</TextDeprecated>
            </ListItem>
          ))}
        </StyledList>
      </DragonbotHelpWindow>
    </ThemeProvider>
  );
};

const FlexEnd = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const DragonbotHelpWindow = styled.div`
  background: ${({ theme }) => theme.palette.background.white};
  position: fixed;
  bottom: 32px;
  top: initial;
  right: 32px;
  left: initial;
  width: 480px;
  height: calc(85% - 50px);
  z-index: 999;
  padding: 1em;
  overflow-y: auto;
`;

const StyledList = styled(List)`
  width: 100%;
  position: relative;
  overflow: auto;
  max-height: 45%;
`;

const StyledText = styled(TextDeprecated)`
  &&&& {
    line-height: 22px;
    white-space: normal;
  }
`;
