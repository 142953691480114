import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';

import ProjectCycleDeliverableLightbox from '../components/ProjectCycleDeliverableLightbox';

const ProjectCycleDeliverableLightboxContext = createContext({});

const ProjectCycleDeliverableLightboxProvider = ({ children }) => {
  const [selectedProjectCycleDeliverable, setSelectedProjectCycleDeliverable] = useState();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (selectedProjectCycleDeliverable) setIsOpen(true);
  }, [setIsOpen, selectedProjectCycleDeliverable]);

  const openProjectCycleDeliverableLightbox = useCallback(
    data => {
      setSelectedProjectCycleDeliverable(data);
      setIsOpen(true);
    },
    [setIsOpen, setSelectedProjectCycleDeliverable],
  );
  const closeProjectCycleDeliverableLightbox = useCallback(() => setIsOpen(false), [setIsOpen]);

  return (
    <ProjectCycleDeliverableLightboxContext.Provider value={{ openProjectCycleDeliverableLightbox }}>
      {children}
      <ProjectCycleDeliverableLightbox
        selectedProjectCycleDeliverable={selectedProjectCycleDeliverable}
        isOpen={isOpen}
        onClose={closeProjectCycleDeliverableLightbox}
        onOpen={openProjectCycleDeliverableLightbox}
      />
    </ProjectCycleDeliverableLightboxContext.Provider>
  );
};

/**
 * @function useCycleDeliverableStatusLightboxContext
 *
 * hook to use the project cycle deliverable lightbox context
 * needs to be wrapped on ProjectCycleDeliverableLightboxProvider
 *
 * @return {Object}
 */
const useProjectCycleDeliverableLightboxContext = () => useContext(ProjectCycleDeliverableLightboxContext);

export { ProjectCycleDeliverableLightboxProvider };

export default useProjectCycleDeliverableLightboxContext;
