import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchUsers } from 'store/users';
import { getNotificationById, removeNotification } from 'store/app';

import Comments from './Comments';

const SLACK_NOTIFICATION_ID = 'SLACK_MESSAGING_ERROR';
const SLACK_AUTHENTICATION_ERROR = 'SLACK_AUTHENTICATION_ERROR';

class CommentsContainer extends Component {
  componentDidMount() {
    // if (!this.props.mentions || !this.props.mentions.length) {
    //   this.props.fetchUsers();
    // }
  }

  render() {
    return <Comments {...this.props} />;
  }
}

export default connect(
  state => {
    const { users, organization } = state;

    return {
      users: users.rows,
      slackErrorNotification: getNotificationById(state, SLACK_NOTIFICATION_ID),
      slackAuthenticationError: getNotificationById(state, SLACK_AUTHENTICATION_ERROR),
      organization,
    };
  },
  {
    fetchUsers,
    removeNotification,
  },
)(CommentsContainer);
