import {
  RESET_ALLOCATION_DATA,
  UPDATE_GOAL_MODE_ALLOCATION_STATE,
  UPDATE_GOAL_MODE_STATE,
  UPDATE_GOAL_MODE_SNAPSHOT_STATE,
} from './types';

export const updateState = state => {
  return {
    payload: state,
    type: UPDATE_GOAL_MODE_STATE,
    meta: { makesActiveViewDirty: true },
  };
};

export const updateSnapshotState = (state, makesActiveViewDirty = true) => {
  return {
    payload: state,
    type: UPDATE_GOAL_MODE_SNAPSHOT_STATE,
    meta: { makesActiveViewDirty },
  };
};

export const updateAllocationState = (state, makesActiveViewDirty = true) => {
  return {
    payload: state,
    type: UPDATE_GOAL_MODE_ALLOCATION_STATE,
    meta: { makesActiveViewDirty },
  };
};

export const resetAllocationData = () => ({ type: RESET_ALLOCATION_DATA });
