import { isNil } from 'ramda';

import { getMetadataFilteredByActiveItems, getUserName } from './index';
import sortByRank from './sortByRank';
import { OBJECT_KEY_RESULT, OBJECT_OBJECTIVE } from 'store/objectives';
import { METADATA_LEVELS } from 'constants/common';

import { KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL } from '../constants/objectives';

/**
 * @function verifyIfIsCorpLevel check if the metadata level corresponst to a CORP level
 * @param  {*} level  Objectives have level as string and KeyResults as integer.
 * @return {Boolean} If the level corresponds to an CORP level
 */
const verifyIfIsCorpLevel = level => level === METADATA_LEVELS.LEVEL_CORP;

/**
 * @function getObjectiveUniqueidByLevel generates a unique id considering the level of the objective
 * @param  {Object} objective
 * @return {String} Unique id
 */
const getObjectiveUniqueidByLevel = objective => {
  return `OBJECTIVE${verifyIfIsCorpLevel(objective.level) ? `_${METADATA_LEVELS.LEVEL_CORP}_` : ''}${objective.id}`;
};

/**
 * @function buildObjectivePathFromAncestry recursively creates the ancestry path for each OKR metadata
 * @param  {Array} items   list of metadata to process
 * @param  {Integer} parentId id of the parent metadata
 * @return {Array} List of path segments
 */
const buildObjectivePathFromAncestry = (items, parentId) => {
  let previousPath = [];

  if (!parentId) {
    return previousPath;
  }
  const parent = items.find(parentItem => parentItem.id === parentId);

  if (!parent) return previousPath;

  if (parent?.parent_id) {
    previousPath = buildObjectivePathFromAncestry(items, parent?.parent_id).flat();
  }
  return [...previousPath, getObjectiveUniqueidByLevel(parent)];
};

/**
 * @function processKeyResults process each key result to prepare the data for further usage
 * @param  {Array} { keyResults }   List of key results
 * @param  {String} allowedLevel    Key result level allowed in the organization
 * @param  {Array} parentsUniqueIds List of unique ids of all direct ancestry
 * @param  {Integer} currentLevel   Current level that is being process (Key results can have 2)
 * @param  {Array} baseKeyResults   List of all key results to be processed. This can be a filtered list of key results.
 * Only these are considered valid for processing
 * @return {Array} List of processed Key results
 */
const processKeyResults = ({ keyResults = [] }, { allowedLevel, parentsUniqueIds, currentLevel, baseKeyResults = [] }) => {
  return currentLevel > allowedLevel || !keyResults?.length
    ? []
    : [...keyResults].sort(sortByRank).reduce((keyResultsForLevel, keyResult) => {
        // if a child key result is not present in the base key result list, ignore (it was filtered)
        const currentKeyResultFromBase = baseKeyResults.find(kr => kr?.id === keyResult?.id);

        if (isNil(currentKeyResultFromBase)) {
          return keyResultsForLevel;
        }
        const keyResultUniqueId = `KEY_RESULT${keyResult.id}`;

        const keyResultPath = [...parentsUniqueIds, keyResultUniqueId];

        const processedKeyResult = {
          ...keyResult,
          ...currentKeyResultFromBase,
          ownerName: getUserName(keyResult.owner),
          updatedBy: getUserName(keyResult.updatedBy),
          type: OBJECT_KEY_RESULT,
          path: keyResultPath,
          uniqueId: keyResultUniqueId,
        };

        const processedKeyResults = processKeyResults(keyResult, {
          allowedLevel,
          parentsUniqueIds: keyResultPath,
          currentLevel: keyResult.level,
          baseKeyResults,
        });

        return [...keyResultsForLevel, processedKeyResult, ...processedKeyResults];
      }, []);
};
/**
 * @function getActiveOKRWithHierarchy creates the OKR tree for a given set of objectives and keyResults
 * @return {Array} List of OKRS that are active with hierarchy calculated correctly
 */
const getActiveOKRWithHierarchy = (
  objectives = [],
  hasKeyResults = false,
  hasKeyResults2 = false,
  baseKeyResults = [],
  hideArchivedItems = false,
) => {
  const allowedLevel = hasKeyResults2 ? KEY_RESULT_2_LEVEL : KEY_RESULT_1_LEVEL;

  return getMetadataFilteredByActiveItems(objectives, hideArchivedItems)
    .sort(sortByRank)
    .reduce((acc, objective) => {
      const objectiveUniqueId = getObjectiveUniqueidByLevel(objective);
      const parentPath = buildObjectivePathFromAncestry(objectives, objective?.parent_id);
      const path = [...parentPath, objectiveUniqueId];

      const processedObjective = {
        ...objective,
        ownerName: getUserName(objective.owner),
        updatedBy: getUserName(objective.updatedBy),
        path,
        type: OBJECT_OBJECTIVE,
        uniqueId: objectiveUniqueId,
      };

      if (!hasKeyResults) {
        return [...acc, processedObjective];
      }

      const processedKeyResults = processKeyResults(objective, {
        allowedLevel,
        parentsUniqueIds: path,
        currentLevel: 0,
        baseKeyResults,
      });

      return [...acc, processedObjective, ...processedKeyResults];
    }, []);
};

export default getActiveOKRWithHierarchy;
