import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Axios from 'axios';
import debounce from 'lodash/debounce';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import ConfirmDialog from 'components/ConfirmDialog';
import InfoToast from 'components/InfoToast';
import { showGithubImportProjectsDialog, hideGithubImportProjectsDialog } from 'store/app';
import Content from '../components/Content';
import Title from '../components/Title';
import Body from '../components/Body';
import Description from '../components/Description';
import GithubIcon from 'design-system/atoms/GithubIcon/index';
import ToastMessage from 'design-system/atoms/ToastMessage/index';


import { pipe, last, equals, take, init, when, trim, split, defaultTo, not } from 'ramda';
import { INTEGRATIONS_KEYS, INTEGRATIONS_NAMES } from 'constants/integrations';

const SLASH = '/';
const GITHUB_DOMAIN = 'https://github.com/';

const GITHUB_FAILED_TEST_MESSAGE =
  'Something went wrong with your Github integration. Please contact us if the problem persists.';

const firstCharacters = take(19);
const isGithubDomain = equals(GITHUB_DOMAIN);
const aintAGithubUrl = pipe(firstCharacters, isGithubDomain, not);
const defaultToEmptyString = defaultTo('');
const splitByPath = split(SLASH);
const slashAtTheEnd = pipe(last, equals(SLASH));
const removeSlashAtTheEnd = when(slashAtTheEnd, init);
const trimAndCleanUrl = pipe(defaultToEmptyString, trim, removeSlashAtTheEnd);
const getOrganizationSlugFromUrl = pipe(splitByPath, last);

const ButtonContainer = styled.div`
  padding-bottom: 10px;
`;

class Github extends Component {
  state = {
    currentStep: 0,
    githubConfig: {},
    step1Completed: false,
    step2Completed: false,
    authorizationURL: '',
    validateOrganizationStatus: '',
    importProjectsLightboxOpened: false,
  };

  constructor(props) {
    super(props);

    this.validateOrganization = debounce(this.validateOrganization, 1000);
  }

  handleOAuthMessage = event => {
    if (event.data === 'githubAuth') {
      this.props.loadOrgAndUserIntegrations();
    }
  };

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const error = params.get('error');
    const loginSuccess = params.get('login_success');

    if (error === 'NOT_BELONG_GITHUB_ORG') {
      toast.error('Your GitHub account must be part of the GitHub organization you want to connect.');
    }

    if (loginSuccess === 'true' && window.opener) {
      window.opener.postMessage('githubAuth', '*');
      window.close();
    }

    window.addEventListener('message', this.handleOAuthMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleOAuthMessage);
  }

  authorizeGithub = () => {
    return new Promise(async (accept, reject) => {
      let organization;
      const { orgIntegration } = this.props;

      if (orgIntegration) {
        organization = orgIntegration.data.githubOrganization;
      } else {
        ({ organization } = this.state.githubConfig);
      }

      if (organization || (orgIntegration && orgIntegration.data && orgIntegration.data.organization)) {
        const checkPopup = () => {
          this.waitNumber += 1;

          if (this.waitNumber === 10) {
            if (this.state.waitForAPI) {
              toast('If you have a popup blocker please verify and try to disable it.');
              this.setState({ waitForAPI: false });
            }
            clearInterval(this.checkPopupInterval);
            reject();
          }
        };

        const clearPopupEventsAndUpdateIntegrations = () => {
          clearInterval(this.checkPopupInterval);
          this.props.loadOrgAndUserIntegrations();
          window.removeEventListener('message', clearPopupEventsAndUpdateIntegrations);
          accept();
        };

        if (this.props.userIntegration) {
          return accept();
        }

        const { data } = await Axios.get('/api/integrations/github/authorize', {
          params: {
            organization: organization || orgIntegration.data.organization,
          },
        });

        this.checkPopupInterval = setInterval(checkPopup, 1000);

        window.open(data, 'Github Authentication', 'height=600,width=600');
        window.addEventListener('message', clearPopupEventsAndUpdateIntegrations, false);

        return;
      }

      reject();
    });
  };

  validateOrganization = organization => {
    this.setState({
      validateOrganizationStatus: 'pending',
    });
    Axios.get('/api/integrations/github/organization', { params: { organization } })
      .then(({ data }) => {
        this.setState({
          validateOrganizationStatus: 'valid',
        });
      })
      .catch(err => {
        this.setState({
          validateOrganizationStatus: 'invalid',
        });
      });
  };

  /**
   * We need to fetch and parse the URL the user inputs
   * and look fort the organization which usually is the last portion
   */
  changeOrganization = e => {
    const { githubConfig } = this.state;
    const organizationUrl = trimAndCleanUrl(e.target.value);
    const organization = getOrganizationSlugFromUrl(organizationUrl);

    this.setState({
      githubConfig: { ...githubConfig, organizationUrl, organization },
      validateOrganizationStatus: '',
    });

    if (aintAGithubUrl(organizationUrl)) return;

    if (organization) {
      this.validateOrganization(organization);
    }
  };

  disconnectGithub = () => {
    this.props
      .removeIntegration()
      .then(() => {
        return this.props.loadOrgAndUserIntegrations();
      })
      .then(() => {
        this.setState({
          disconnectConfirmDialog: false,
        });
      });
  };

  githubOrganizationLink = () => {
    const { orgIntegration } = this.props;

    return (
      orgIntegration && (
        <a href={`https://github.com/${orgIntegration.data.githubOrganization}`} target="_blank" rel="noopener noreferrer">
          {orgIntegration.data.githubOrganization}
        </a>
      )
    );
  };

  testGithubConnection = () => {
    this.authorizeGithub()
      .then(() => {
        return toast(<InfoToast>Github is configured correctly.</InfoToast>, { toastId: 'GITHUB_CONNECTION' });
      })
      .catch(err => toast(<ToastMessage title={GITHUB_FAILED_TEST_MESSAGE} type="error" />, { toastId: 'GITHUB_CONNECTION' }));
  };

  render() {
    const { githubConfig, validateOrganizationStatus } = this.state;
    const { orgIntegration, userIntegration, userCanUpdateOrgIntegration, userCanViewOrgIntegration } = this.props;

    const shouldShowContactInfo = !userCanViewOrgIntegration || (!userCanUpdateOrgIntegration && !orgIntegration);

    return (
      <Content>
        <Title
          title="Github Integration"
          helpLink="https://dragonboat.zendesk.com/hc/en-us/articles/360038958174"
          logo={<GithubIcon size={30} marginRight={25} />}
        />
        {shouldShowContactInfo && (
          <Body>
            <p>Please contact your organization Admin to activate {INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.github]} integration.</p>
          </Body>
        )}
        {!shouldShowContactInfo && (
          <Body>
            <Grid container direction="column">
              {!orgIntegration && (
                <Grid item>
                  <Grid item xs={6}>
                    <TextField
                      id="github-organization-url-input"
                      label="Enter your Github organization’s URL"
                      placeholder="https://github.com/yourcompany"
                      value={githubConfig.organizationUrl}
                      onChange={this.changeOrganization}
                      required
                      fullWidth
                      disabled={!userCanUpdateOrgIntegration}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" style={{ minWidth: 24, maxWidth: 24 }}>
                            {(() => {
                              switch (validateOrganizationStatus) {
                                case 'pending':
                                  return (
                                    <div>
                                      <CircularProgress size={24} />
                                    </div>
                                  );
                                case 'valid':
                                  return <CheckIcon style={{ color: 'green' }} />;
                                case 'invalid':
                                  return <CloseIcon />;
                                default:
                                  return '';
                              }
                            })()}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid container justify="flex-end">
                    <Button
                      id="github-connect-button"
                      color="primary"
                      disabled={!userCanUpdateOrgIntegration || validateOrganizationStatus !== 'valid'}
                      onClick={() => this.authorizeGithub()}
                    >
                      Next
                    </Button>
                  </Grid>
                  <Description>Please disable your browser popup blocker before you hit Next.</Description>
                </Grid>
              )}
              {userCanViewOrgIntegration && orgIntegration && (
                <Grid item style={{ paddingLeft: 8, marginTop: 40 }}>
                  Your dragonboat workspace has been linked to your GitHub instance: {this.githubOrganizationLink()}.
                </Grid>
              )}
              {(userCanViewOrgIntegration || userCanUpdateOrgIntegration) && orgIntegration && userIntegration && (
                <Fragment>
                  <Grid item style={{ marginTop: 30 }}>
                    <ButtonContainer>
                      <Button id="test-github-button" color="primary" onClick={this.testGithubConnection}>
                        Test GitHub connection
                      </Button>
                    </ButtonContainer>
                    <ButtonContainer>
                      <Button
                        id="disconnect-github-button"
                        color="secondary"
                        onClick={() => this.setState({ disconnectConfirmDialog: true })}
                        disabled={!userCanUpdateOrgIntegration}
                      >
                        Disconnect From GitHub
                      </Button>
                    </ButtonContainer>
                  </Grid>
                </Fragment>
              )}
              {userCanUpdateOrgIntegration && orgIntegration && !userIntegration && (
                <ButtonContainer style={{ marginTop: 30 }}>
                  <Button id="github-connect-button" color="primary" onClick={() => this.authorizeGithub()}>
                    Connect
                  </Button>
                </ButtonContainer>
              )}
            </Grid>
            <ConfirmDialog
              title="Disconnect From GitHub"
              text={
                <div>
                  <p>
                    Disconnecting GitHub will disable GitHub linking and update for your entire Workspace. Do you want to
                    continue?
                  </p>
                  <br />
                  <p>You may reconnect following the GitHub integration step in the future.</p>
                </div>
              }
              isOpen={this.state.disconnectConfirmDialog}
              onCancel={() => this.setState({ disconnectConfirmDialog: false })}
              onConfirm={this.disconnectGithub}
            />
          </Body>
        )}
      </Content>
    );
  }
}

const mapStateToProps = state => {
  const {
    app: { githubImportProjectsDialogVisible },
  } = state;

  return {
    githubImportProjectsDialogVisible,
  };
};

const mapDispatchToProps = {
  showGithubImportProjectsDialog,
  hideGithubImportProjectsDialog,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Github);
