import React from 'react';
import { defaultTo, equals, pipe, prop } from 'ramda';

import Actions from './Actions';
import { TextWrapper, Wrapper } from './styled';
import { TEAM_LEVEL } from 'routes/Settings/Teams/helpers/constants';

const LEVEL = 'level';

const defaultToEmptyObject = defaultTo({});

const isLevel2 = pipe(prop(LEVEL), equals(TEAM_LEVEL));
const isChild = pipe(defaultToEmptyObject, isLevel2);

const GroupHeaderInnerRenderer = params => {
  const { data, value } = params;

  if (!value) {
    return null;
  }

  return (
    <Wrapper $indent={!isChild(data)}>
      <TextWrapper>{params.value}</TextWrapper>
      <Actions {...params} />
    </Wrapper>
  );
};

export default GroupHeaderInnerRenderer;
