import React, { useEffect, useState } from 'react';

import NewDialog from 'design-system/organisms/NewDialog/index';
import DialogActions from 'design-system/molecules/DialogActions/index';

import AnnouncementEditor from './AnnouncementEditor';
import ShareOnSlack from './ShareOnSlack';

const LIGHTBOX_TITLE = 'Announcements';

export default function AnnouncementLightbox({
  isOpen,
  onClose,
  onDeleteAnnouncement,
  onSaveAnnouncement,
  selectedAnnouncement,
  isAdmin,
}) {
  const [value, setValue] = useState('');

  const onSendAnnouncementToIntegration = (channel, integrationType) => {
    onSaveAnnouncement(value, channel, integrationType);
  };

  useEffect(() => {
    if (!isOpen) setValue('');
  }, [isOpen]);

  return (
    <>
      <NewDialog
        open={isOpen}
        title={LIGHTBOX_TITLE}
        closeButton
        headerProps={onClose}
        actions={
          isAdmin && <DialogActions onCancel={onClose} onDelete={onDeleteAnnouncement} onSave={() => onSaveAnnouncement(value)} />
        }
        onClose={onClose}
        fullWidth
      >
        <AnnouncementEditor
          handleValueChange={value => setValue(value)}
          value={selectedAnnouncement ? selectedAnnouncement?.message : value}
          isAdmin={isAdmin}
        />
        {isAdmin ? <ShareOnSlack announcement={value} onSend={onSendAnnouncementToIntegration} /> : null}
      </NewDialog>
    </>
  );
}
