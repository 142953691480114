import { pickOkrFields } from 'features/Canvas/helpers/fieldPickers';
import { CREATE_KEY_RESULT_FROM_CANVAS_FULFILLED } from 'features/Canvas/store/types';

import { keyResultsAdapter } from '../helpers/adapters';
import { APPLY_FILTERS_WHITEBOARD_FULFILLED, UPDATE_METADATA_ENTITIES_WHITEBOARD } from '../types';
import { KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL } from 'constants/objectives';
import {
  CREATE_KEY_RESULT_FROM_DRAWER_FULFILLED,
  DELETE_KEY_RESULT_FULFILLED,
  UPDATE_KEY_RESULT_FROM_DRAWER_FULFILLED,
  UPDATE_KEY_RESULT_ID_FULFILLED,
  UPDATE_KEY_RESULTS_FULFILLED,
  ADD_KEY_RESULT_METRIC_FULFILLED,
  REMOVE_KEY_RESULT_METRIC_FULFILLED,
} from 'store/objectives';
import { filterProjectsKeyResults } from 'features/whiteboard/store/helpers/entitiesFilters';
import { projectsSelector } from 'features/whiteboard/store/selectors';
import { initialState } from '../reducer';

const keyResultLevels = [KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL];

const getKeyResults = objectives =>
  objectives.reduce((keyResults, okr) => {
    let keyResultsToAdd = [];
    const okrDescendents = okr?.keyResults || okr?.children;

    if (okrDescendents?.length) {
      keyResultsToAdd = getKeyResults(okrDescendents);
    }
    if (keyResultLevels.includes(okr?.level)) {
      keyResultsToAdd = [...keyResultsToAdd, okr];
    }
    return [...keyResults, ...keyResultsToAdd];
  }, []);

const processOkrs = (okrs, projects) => filterProjectsKeyResults(getKeyResults(okrs), projects).map(pickOkrFields);

const keyResultsReducer = (state = initialState, action) => {
  const { payload } = action;
  const storeProjects = projectsSelector.selectAll(state);

  switch (action.type) {
    case APPLY_FILTERS_WHITEBOARD_FULFILLED: {
      const { projects, keyResults } = payload;

      if (!keyResults?.length) return state;

      return {
        ...state,
        keyResults: keyResultsAdapter.setAll(state?.keyResults, processOkrs(keyResults, projects)),
      };
    }
    case UPDATE_METADATA_ENTITIES_WHITEBOARD:
      const { keyResults } = payload;

      return {
        ...state,
        keyResults: keyResultsAdapter.setAll(state?.keyResults, processOkrs(keyResults, storeProjects)),
      };

    case UPDATE_KEY_RESULT_ID_FULFILLED:
    case CREATE_KEY_RESULT_FROM_DRAWER_FULFILLED:
    case UPDATE_KEY_RESULT_FROM_DRAWER_FULFILLED:
    case UPDATE_KEY_RESULTS_FULFILLED:
    case ADD_KEY_RESULT_METRIC_FULFILLED:
    case REMOVE_KEY_RESULT_METRIC_FULFILLED:
      if (!payload?.id) return state;

      const shouldAddToStore = storeProjects.some(
        project => project?.keyResult1Id === payload?.id || project?.keyResult2Id === payload?.id,
      );

      if (!shouldAddToStore) {
        return state;
      }
      return {
        ...state,
        keyResults: keyResultsAdapter.upsertOne(state?.keyResults, pickOkrFields(payload)),
      };
    case CREATE_KEY_RESULT_FROM_CANVAS_FULFILLED:
      const { meta } = action;

      if (!payload?.id) return state;

      return {
        ...state,
        keyResults: keyResultsAdapter.upsertOne(state?.keyResults, pickOkrFields(payload)),
        userActionsMeta: meta?.keyResultCreatedFrom
          ? {
              entityCreatedFrom: meta.keyResultCreatedFrom,
              entityId: payload?.id,
              type: 'keyResult',
            }
          : {},
      };
    case DELETE_KEY_RESULT_FULFILLED:
      return {
        ...state,
        keyResults: keyResultsAdapter.removeOne(state?.keyResults, payload),
      };
    default: {
      return state;
    }
  }
};

export { keyResultsReducer };
