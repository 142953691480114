import React from 'react';
import styled from 'styled-components';

import LoadingOverlay from 'design-system/atoms/LoadingOverlay';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

import useUserViewRecurringNotification from '../../hooks/useUserViewRecurringNotification';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import UserViewRecurringSlackNotification from '../UserViewRecurringSlackNotification/UserViewRecurringSlackNotification';
import ShareViewViaSlack from './ShareViewViaSlack';

const ItemByChannelType = ({
  userView,
  userViewRecurringNotification,
  onUpdate,
  onDelete,
  hasAnyDisabledRecurringNotification,
}) => {
  const permissions = usePermissions();

  const {
    recurringNotification: { notification_channel_type: channelType },
  } = userViewRecurringNotification;

  const canUpdate = permissions.canUpdate(PERMISSION_FEATURES.userViewRecurringNotifications, {
    view: userView,
    viewRecurringNotification: userViewRecurringNotification,
  });
  const handleUpdate = changes => onUpdate(userViewRecurringNotification, changes);

  const canDelete = permissions.canDelete(PERMISSION_FEATURES.userViewRecurringNotifications, {
    view: userView,
    viewRecurringNotification: userViewRecurringNotification,
  });
  const handleDelete = () => onDelete(userViewRecurringNotification);

  switch (channelType) {
    case INTEGRATIONS_KEYS.slack:
      return (
        <UserViewRecurringSlackNotification
          userViewRecurringNotification={userViewRecurringNotification}
          onUpdate={handleUpdate}
          canUpdate={canUpdate}
          onDelete={handleDelete}
          canDelete={canDelete}
          hasAnyDisabledRecurringNotification={hasAnyDisabledRecurringNotification}
        />
      );
    default:
      return null;
  }
};
const UserViewRecurringNotifications = ({ view, shareUrl }) => {
  const {
    viewRecurringNotifications,
    isLoadingRecurringNotifications,
    updateViewRecurringNotification,
    deleteViewRecurringNotification,
  } = useUserViewRecurringNotification(view);

  const hasAnyDisabledRecurringNotification = viewRecurringNotifications.some(
    notification => notification.recurringNotification.disabled_state === true,
  );

  return (
    <Wrapper>
      {isLoadingRecurringNotifications && <LoadingOverlay />}
      {viewRecurringNotifications?.map(userViewRecurringNotification => {
        return (
          <ItemByChannelType
            key={userViewRecurringNotification.id}
            userView={view}
            userViewRecurringNotification={userViewRecurringNotification}
            onUpdate={updateViewRecurringNotification}
            onDelete={deleteViewRecurringNotification}
            hasAnyDisabledRecurringNotification={hasAnyDisabledRecurringNotification}
          />
        );
      })}
      <ShareViewViaSlack view={view} shareUrl={shareUrl} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 170px;
`;

export default UserViewRecurringNotifications;
