import React from 'react';
import {
  BasicFormattingButtonGroup,
  HeadingLevelButtonGroup,
  ListButtonGroup,
  CreateTableButton,
  Toolbar,
  VerticalDivider,
  ToggleBlockquoteButton,
  ToggleStrikeButton,
} from '@remirror/react-components';

export default props => {
  return (
    <Toolbar>
      <HeadingLevelButtonGroup />
      <VerticalDivider />
      <BasicFormattingButtonGroup>
        <ToggleStrikeButton />
      </BasicFormattingButtonGroup>
      <ToggleBlockquoteButton />
      <VerticalDivider />
      <ListButtonGroup />
      <CreateTableButton />
    </Toolbar>
  );
};
