/**
 * Unique ids for each option of the "Start building your Roadmap" dialog
 */
export const IMPORT_WIZARD_OPTION_IDS = {
  COPY_AND_PASTE: 'copy-paste',
  IMPORT_JQL: 'import-jql',
  IMPORT_ADO_QUERY: 'import-ado-query',
  IMPORT_RALLY_VIEW: 'import-rally-view',
  IMPORT_DRAGONBOAT_USER_FILTER: 'import-dragonboat-user-filter',
  START_FRESH: 'start',
  IMPORT_CSV: 'csv',
};

/**
 * Gets the default selected option for the radio buttons group based
 * on the visible items for the current organization.
 *
 * @param {Object} options
 * @param {Boolean} options.showJiraOption
 * @param {Boolean} options.dragonboatOnDragonboatIntegrated
 * @returns {String}
 */
export const getDefaultSelectedOption = ({ showJiraOption, dragonboatOnDragonboatIntegrated }) => {
  switch (true) {
    case dragonboatOnDragonboatIntegrated:
      return IMPORT_WIZARD_OPTION_IDS.IMPORT_DRAGONBOAT_USER_FILTER;
    case showJiraOption:
      return IMPORT_WIZARD_OPTION_IDS.IMPORT_JQL;
    default:
      return IMPORT_WIZARD_OPTION_IDS.COPY_AND_PASTE;
  }
};
