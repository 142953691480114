const getJiraAccountId = user => {
  const userIntegrations = (user && user.userIntegrations) || [];

  return userIntegrations.reduce((accountId, userIntegration) => {
    if (+userIntegration.integrationType === 0 && userIntegration.user_metadata) {
      return userIntegration.user_metadata.accountId || userIntegration.user_metadata.username;
    }

    return accountId;
  }, null);
};

export { getJiraAccountId };
