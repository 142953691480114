import React from 'react';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import NotificationsList from '../Header/NotificationsList';

export default ({ anchorElem, open, onClose, placement = 'right-start' }) => {
  return (
    <Popper placement={placement} anchorEl={anchorElem} open={open} onClose={onClose} style={{ zIndex: 1000 }}>
      <Paper style={{ width: 400, overflow: 'auto', marginLeft: 10 }}>
        <ClickAwayListener
          onClickAway={e => {
            if (!anchorElem.contains(e.target)) {
              onClose();
            }
          }}
        >
          <NotificationsList onClose={onClose} />
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
};
