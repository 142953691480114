import { F } from 'ramda';
import React, { useMemo } from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloseIcon from '@material-ui/icons/Close';

import theme from 'design-system/theme';
import { ActionsCellRenderer, TitleCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { useCustomerRequestsColumnsDefs } from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsColumnsDefs';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { isNewRow } from 'design-system/molecules/AgGridReact-New/helpers';
import useRequestsMergedGridState from 'features/RequestsMergedGrid/hooks/useRequestsMergedGridState';
import Text from 'design-system/atoms/Text';
import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';

import SearchDuplicateRequestCellEditor from '../components/SearchDuplicateRequestCellEditor';

/**
 * Custom hook that defines the column definitions for the potential duplicates grid.
 * @param {Object} options - The options for the hook.
 * @param {Function} options.addPotentialDuplicateRequest - The function to add a potential duplicate request.
 * @param {Function} options.removePotentialDuplicateRequest - The function to remove a potential duplicate request.
 * @param {Function} options.onOpenCustomerRequestInfo - The function to open the customer request info.
 * @returns {Object} - The column definitions for the potential duplicates grid.
 */
const usePotentialDuplicatesGridColumnsDefs = ({
  addPotentialDuplicateRequest,
  removePotentialDuplicateRequest,
  onOpenCustomerRequestInfo,
}) => {
  const { rowHeight, searchString, isBulkUpdate, visibleColumnsIds, columnsState } = useRequestsMergedGridState();

  const { columnDefs, defaultColDef } = useCustomerRequestsColumnsDefs({
    searchString,
    isBulkUpdate,
    visibleColumnsIds,
    columnsState,
    rowHeight,

    onOpenCustomerRequestInfo,
    createMultiSelectOption: F,
  });

  const titleColumnDef = useMemo(
    () => ({
      field: 'title',
      headerName: 'Title',
      suppressMovable: true,
      comparator: stringComparator,
      headerClass: 'first-field',
      cellRenderer: params => {
        if (params.data?.id) {
          return <TitleCellRenderer {...params} />;
        }

        return (
          <div>
            <Text variant="body1" style={{ color: theme.palette.text.disabled }}>
              {TYPE_TO_SEARCH_PLACEHOLDER}
            </Text>
          </div>
        );
      },
      cellRendererParams: params => ({
        innerRenderer: TitleCellRenderer,
        suppressCount: true,
        suppressDoubleClickExpand: true,
        icon: OpenInNewIcon,
        openButtonProps: {
          hasOpenButton: !!params.data,
          onClick: () => onOpenCustomerRequestInfo(params.data),
        },
      }),
      cellEditorSelector: params => {
        return {
          component: SearchDuplicateRequestCellEditor,
          params: {
            onSave: data => {
              addPotentialDuplicateRequest(data?.entity);
            },
            autoFocus: true,
            isSelected: true,
          },
        };
      },
      initialHide: false,
      pinned: 'left',
      lockPinned: true,
      editable: isNewRow,
      rowDrag: false,
      width: 400,
    }),
    [],
  );

  const unlinkColumnDef = useMemo(
    () => ({
      headerName: '',
      field: 'unlink',
      editable: false,
      suppressMovable: true,
      suppressMenu: true,
      filter: false,
      resizable: false,
      cellRenderer: ActionsCellRenderer,
      headerClass: 'centered-header',
      cellRendererParams: {
        actions: [
          {
            icon: CloseIcon,
            color: theme.palette.icons.primary,
            onClick: removePotentialDuplicateRequest,
            getVisibility: () => true,
          },
        ],
      },
      width: 38,
      initialHide: false,
    }),
    [],
  );

  const potentialDuplicatesDefaultColDef = useMemo(
    () => ({
      ...defaultColDef,
      editable: false,
    }),
    [defaultColDef],
  );

  return useMemo(
    () => ({
      columnDefs: [titleColumnDef, ...columnDefs.filter(col => col.field !== 'title'), unlinkColumnDef],
      defaultColDef: potentialDuplicatesDefaultColDef,
    }),
    [columnDefs, potentialDuplicatesDefaultColDef, titleColumnDef, unlinkColumnDef],
  );
};

export default usePotentialDuplicatesGridColumnsDefs;
