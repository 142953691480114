import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import Tooltip from '@material-ui/core/Tooltip';

export default function LinkButtons({
  disableEdit,
  handleLink,
  metricIntegration,
  orgIntegration,
  originalValue,
  save,
  setShowConfirmDialog,
  value,
}) {
  return metricIntegration?.id ? (
    <Tooltip title="Unlink Integration">
      <StyledButton
        color="primary"
        onClick={() => setShowConfirmDialog(true)}
        disabled={disableEdit}
        data-testid="metric-integration-unlink-btn"
      >
        <LinkOffIcon />
      </StyledButton>
    </Tooltip>
  ) : (
    <Tooltip title="Link Integration">
      <StyledButton
        color="primary"
        onClick={() => handleLink(save, value)}
        disabled={disableEdit || !orgIntegration?.id || value === originalValue}
        data-testid="metric-integration-link-btn"
      >
        <LinkIcon />
      </StyledButton>
    </Tooltip>
  );
}

const StyledButton = styled(Button)`
  && {
    margin-left: auto;
    min-width: unset;
  }
`;
