import { useCallback, useMemo } from 'react';
import { pipe, defaultTo, pluck } from 'ramda';

import { processColumnsGroup as processColumnsGroupUtil, processGroomedColumnDefs } from 'utils/grids/helpers';
import { useIdeasGridColumnsDefinitions } from 'containers/IdeasList/IdeasList/New/hooks';
import { useSelector } from 'react-redux';
import {
  getBaseCompareVersionSelected,
  getSelectedRoadmapVersionsIdsToCompare,
  getSelectedRoadmapVersionsToCompare,
} from 'store/roadmapVersions/selectors';
import generateRoadmapVersionsFieldsColumns from '../helpers/generateRoadmapVersionsFieldsColumns';
import useRoadmapVersionsSupportedFields from 'routes/RoadmapVersions/hooks/useRoadmapVersionsSupportedFields';
import getRoadmapVisibleFieldsIds from '../helpers/getRoadmapVisibleFieldsIds';
import checkIfUserIsAdmin from 'utils/checkIfUserIsAdmin';

import { PLAN_OF_RECORD_TITLE } from 'constants/common';
import { PLAN_OF_RECORD_COL_ID } from '../helpers/contants';

const KEY = 'key';
const defaultToEmptyArray = defaultTo([]);

const getKeys = pluck(KEY);
const getFieldKeys = pipe(defaultToEmptyArray, getKeys);

const filterOutColumnsThatAreChildrenOfPlanOfRecord = (ideasGridColumnDefs, roadmapVersionsFields) =>
  ideasGridColumnDefs.filter(column => !getFieldKeys(roadmapVersionsFields).includes(column.field));

const useCompareRoadmapVersionsColumnsDefinitions = ({
  portfolioMode,
  hasHierarchy,
  hasBet,
  hasKeyResults,
  hasKeyResults2,
  hasProducts,
  hasProducts2,
  hasMultiLevelMetadata,
  hasTeams2,
  topLayer,
  displayLayer,
  rowHeight,
  visibleColumnsIds,
  titleSearchString,
  customUserFields,
  currentUser,
  integrations,
  columnsState,
  customFields,
  hasMetadataMultiSelect,

  getSystemFieldName,
  addNewInlineProject,
  openProjectInfo,
  openRowInfo,
  loadParentProjectsOptions,
  checkUserCanCreateNewMetadata,
  updateProjectById,
  handleMetadataCellDoubleClick,
  createMetadataMultiSelectOption,
  removeGridRow,
  onConvertToPlanOfRecordHeaderClicked,
}) => {
  const { roadmapVersionsFields } = useRoadmapVersionsSupportedFields();
  const selectedRoadmapVersionsIds = useSelector(state => getSelectedRoadmapVersionsIdsToCompare(state));
  const selectedRoadmapVersions = useSelector(state => getSelectedRoadmapVersionsToCompare(state));
  const userIsAdmin = checkIfUserIsAdmin(currentUser);
  const baseCompareVersionSelected = useSelector(getBaseCompareVersionSelected);

  const hasColumnState = Boolean(columnsState);
  const tooltipText = `Set ${getSystemFieldName('scenario')} as plan of record`;

  const visibleColumnsIdsWithRoadmapVersionCols = useMemo(() => {
    if (hasColumnState) {
      return visibleColumnsIds;
    }
    const { roadmapFieldsVisible } = getRoadmapVisibleFieldsIds(
      visibleColumnsIds,
      selectedRoadmapVersions,
      roadmapVersionsFields,
    );

    return [PLAN_OF_RECORD_COL_ID, ...visibleColumnsIds, ...roadmapFieldsVisible];
  }, [hasColumnState, selectedRoadmapVersions, selectedRoadmapVersionsIds, roadmapVersionsFields]);

  const processColumnsGroup = useCallback(
    columns => processColumnsGroupUtil(columns, visibleColumnsIdsWithRoadmapVersionCols, columnsState),
    [visibleColumnsIdsWithRoadmapVersionCols, columnsState],
  );

  const {
    columnDefs: ideasGridColumnDefs,
    defaultColDef,
    groupColumnDef,
  } = useIdeasGridColumnsDefinitions({
    portfolioMode,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    topLayer,
    displayLayer,
    rowHeight,
    visibleColumnsIds: visibleColumnsIdsWithRoadmapVersionCols,
    titleSearchString,
    customUserFields,
    currentUser,
    integrations,
    customFields,
    columnsState,
    hasMetadataMultiSelect,

    getSystemFieldName,
    addNewInlineProject,
    openProjectInfo,
    openRowInfo,
    loadParentProjectsOptions,
    updateProjectById,
    handleMetadataCellDoubleClick,
    checkUserCanCreateNewMetadata,
    createMetadataMultiSelectOption,
    removeGridRow,
  });

  const columnDefs = useMemo(() => {
    const roadmapVersionsColumnDef = generateRoadmapVersionsFieldsColumns(
      ideasGridColumnDefs,
      roadmapVersionsFields,
      selectedRoadmapVersions,
      userIsAdmin ? onConvertToPlanOfRecordHeaderClicked : null,
      baseCompareVersionSelected ? baseCompareVersionSelected?.name : PLAN_OF_RECORD_TITLE,
      tooltipText,
    );

    return processGroomedColumnDefs(
      [
        ...filterOutColumnsThatAreChildrenOfPlanOfRecord(ideasGridColumnDefs, roadmapVersionsFields),

        // Roadmap version columns
        ...processColumnsGroup(roadmapVersionsColumnDef),
      ],
      columnsState,
    );
  }, [
    ideasGridColumnDefs,
    roadmapVersionsFields,
    processColumnsGroup,
    columnsState,
    selectedRoadmapVersions,
    userIsAdmin,
    onConvertToPlanOfRecordHeaderClicked,
    baseCompareVersionSelected,
  ]);

  return {
    columnDefs,
    defaultColDef,
    groupColumnDef,
  };
};

export default useCompareRoadmapVersionsColumnsDefinitions;
