import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';

import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';

import { materialColorsAlt } from 'design-system/themes/default';
import { getOkrHealthLabel } from 'utils/okrs/healthUtils';

const UNDEFINED = 'Undefined';
const GREEN = 'Green';
const YELLOW = 'Yellow';
const RED = 'Red';
const BLUE = 'Blue';

export default function HealthSelect({
  cancel,
  save,
  value,
  autoFocus,
  getHealthLabel = getOkrHealthLabel,
  CircleComponent = Circle,
  renderTextOnValue = true,
  renderTextOnOptions = true,
  disableEdit = false,
  horizontalAnchorOrigin = 'center',
  anchorEl = null,
  className,
}) {
  const Health = ({ color, text, showText }) => (
    <div style={{ height: 22, flexGrow: 1, display: 'flex', alignItems: 'center' }}>
      <CircleComponent style={{ backgroundColor: color }} />
      {showText && (
        <>
          &nbsp;
          <Typography variant="body2" component="span" style={{ color, display: 'inline-block' }}>
            {text}
          </Typography>
        </>
      )}
    </div>
  );

  const healthRenderer = (color = '', showText = true) => {
    switch (color) {
      case GREEN:
        return <Health color={materialColorsAlt.green} showText={showText} text={getHealthLabel(GREEN)} />;
      case YELLOW:
        return <Health color={materialColorsAlt.yellow} showText={showText} text={getHealthLabel(YELLOW)} />;
      case RED:
        return <Health color={materialColorsAlt.red} showText={showText} text={getHealthLabel(RED)} />;
      case BLUE:
        return <Health color={materialColorsAlt.blue} showText={showText} text={getHealthLabel(BLUE)} />;
      default:
        return <Health color={materialColorsAlt.darkGray} showText={showText} text={getHealthLabel(UNDEFINED)} />;
    }
  };

  return (
    <NoBorderSelect
      onClose={cancel}
      open={autoFocus}
      value={value || UNDEFINED}
      centered={!renderTextOnValue}
      renderValue={selected => healthRenderer(selected, renderTextOnValue)}
      MenuProps={{
        ...(anchorEl && { anchorEl }),
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: horizontalAnchorOrigin,
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: horizontalAnchorOrigin,
        },
      }}
      onChange={e => save(e.target.value === UNDEFINED ? '' : e.target.value)}
      disabled={disableEdit}
      className={className}
    >
      {[UNDEFINED, GREEN, YELLOW, RED, BLUE].map(color => (
        <StyledMenuItemDropdown key={color} value={color}>
          {healthRenderer(color, renderTextOnOptions)}
        </StyledMenuItemDropdown>
      ))}
    </NoBorderSelect>
  );
}

const Circle = styled.span`
  width: 12px;
  height: 12px;
  display: inline-block;
  background-color: #eee;
  border-radius: 50%;
  vertical-align: middle;
`;

const NoBorderSelect = styled(Select)`
  &&&& {
    width: 100%;
    ::before {
      border-bottom: 0 !important;
    }
    ::after {
      border-bottom: 0 !important;
    }
    svg {
      display: none;
    }

    div[role='button']:focus {
      background-color: transparent;
    }

    ${({ centered }) =>
      centered &&
      `
      div {
        text-align: center;
        div {
          padding-right: 0;
        }
      }
    `}
  }
`;
