import axios from 'axios';
import { omit } from 'ramda';

import throwRequestError from 'store/utils/throwRequestError';
import {
  SAVE_USER_VIEW,
  FETCH_USER_VIEWS,
  FETCH_USER_TEAM_VIEWS,
  DELETE_USER_VIEW,
  SET_PAGE_ACTIVE_VIEW,
  SAVE_USER_VIEW_FULFILLED,
  DELETE_USER_VIEW_FULFILLED,
  UPDATE_USER_VIEW_BY_REALTIME,
  ADD_USER_VIEW_FAVORITE,
  DELETE_USER_VIEW_FAVORITE,
  CREATE_USER_VIEW_PUBLIC_LINK,
  UPDATE_USER_VIEW_PUBLIC_LINK,
  DELETE_USER_VIEW_PUBLIC_LINK,
  FETCH_PUBLIC_USER_VIEW,
  UPDATE_USER_VIEW_DIALOG_PROPERTIES,
  RESET_USER_VIEW_DIALOG_PROPERTIES,
  UPDATE_PAGE_ACTIVE_VIEW_DATA,
  CLEAR_PAGE_ACTIVE_VIEW,
  CREATE_USER_VIEW,
  UPDATE_USER_VIEW,
  TOGGLE_VIEWS_DIALOG,
  UPDATE_USER_VIEW_OWNER,
} from './types';
import { getSharedFilterIdFromPageId } from 'store/filters/selectors/getPageFilters';
import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';
import { createThunk } from 'utils/store/thunk';
import { ensureViewStateCompatibility } from 'utils/userViews';
import { FILTER_FIELDS_TO_OMIT_FROM_VIEW_STATE, READ_ONLY_VIEW_PROPS } from 'store/userViews/consts';

import { initSaveDialogState } from './reducer';

import { setPageFilters } from 'store/filters';
import { getActiveViewForPage, getDefaultUserViewForPage } from './selectors';

import { getViewStateFromStoreState } from './helpers';
import { apiUpdateView, logUserViewVisit } from './network';

const isNotDefaultView = view => view?.default_view !== true;

export const createOrUpdateUserView = userView => dispatch => {
  const { id, ...rest } = userView;

  const url = !id ? '/api/userViews/' : `/api/userViews/update/${id}`;

  const payload = axios.post(url, omit(READ_ONLY_VIEW_PROPS, rest)).catch(throwRequestError);

  dispatch({
    type: SAVE_USER_VIEW,
    meta: { id },
    payload,
  });

  return payload;
};

export const fetchUserViews = () => ({
  payload: axios.get('/api/userViews/'),
  type: FETCH_USER_VIEWS,
});

export const fetchUserTeamViews = () => {
  return {
    payload: axios.get('/api/userViews/user-team-views'),
    type: FETCH_USER_TEAM_VIEWS,
  };
};

export const deleteUserView = id => {
  return {
    payload: axios.delete(`/api/userViews/${id}`),
    type: DELETE_USER_VIEW,
  };
};

export const updateUserViewOwner = (viewId, ownerId) => ({
  type: UPDATE_USER_VIEW_OWNER,
  payload: axios.put(`/api/userViews/${viewId}/owner`, { user_id: ownerId }),
  meta: { id: viewId },
});

export const setPageActiveView = (page, view) => {
  return async (dispatch, getState) => {
    const state = getState();

    const isDefaultViewForUser = view.isOwner && view.default_view;
    const sharedFilterId = getSharedFilterIdFromPageId(page);
    const viewStateFromStore = getViewStateFromStoreState(state, page);

    const omitFilterFromViewState = sharedFilterId && isDefaultViewForUser;

    // Default views for users that have shared filter ID like Portfolio, Outcome, etc
    // Should not rely on filter prop from view state but rely on userFilter that is the source of truth for that info
    // userFilters are the filters that are stored on redux state _filters
    // So when one of these views is being set as active we ignore that property

    const storedViewState = omit(
      omitFilterFromViewState ? FILTER_FIELDS_TO_OMIT_FROM_VIEW_STATE : [],
      ensureViewStateCompatibility(view.state, state),
    );

    view.state = {
      ...viewStateFromStore,
      ...storedViewState,
    };

    const defaultUserView = getDefaultUserViewForPage(state, page);

    const viewFilter = view.state.filter;

    // When view being set is from a portfolio page we need to update the global filter (portfolio filter)
    // We only do this is the view being set is not ours or not a default one and the view has any filter
    // This is to support navigating between our own default views in portfolio without changing filters
    const needsFiltersSet = sharedFilterId && viewFilter && !isDefaultViewForUser;

    if (needsFiltersSet) {
      dispatch(setPageFilters(sharedFilterId, viewFilter, false, null, false));
    }

    dispatch({
      type: SET_PAGE_ACTIVE_VIEW,
      payload: { page, view },
    });

    if (view?.id && isNotDefaultView(view)) await logUserViewVisit(view.id);

    if (defaultUserView && defaultUserView.id !== view.id) {
      // If we are jumping between views and there is a default view for that page we need to keep it up to date

      const newDefaultViewState = {
        ...defaultUserView,
        state: view.state,
      };

      // When a shared view becomes active we must also apply the default view for the page
      // so when the user changes filters, or lands on the page with no shared view active
      // the page preferences become the same as the last view that was active
      return dispatch({
        type: UPDATE_USER_VIEW,
        payload: apiUpdateView(defaultUserView.id, newDefaultViewState),
      });
    }
  };
};

export const setDefaultPageActiveView = page => {
  return (dispatch, getState) => {
    const defaultUserView = getDefaultUserViewForPage(getState(), page);

    dispatch(setPageActiveView(page, defaultUserView));
  };
};

export const clearPageActiveView = page => {
  return {
    type: CLEAR_PAGE_ACTIVE_VIEW,
    payload: { page },
  };
};

export const updatePageActiveView = (page, viewData, markViewAsHavingChanges = true) => {
  return (dispatch, getState) => {
    const defaultViewForPage = getDefaultUserViewForPage(getState(), page);

    let updatedDefaultView = {};

    if (defaultViewForPage) {
      // Keeps the default view updated on the backend with every view update to ensure it has the latest changes

      updatedDefaultView = { ...defaultViewForPage, ...omit(['id'], viewData) };

      apiUpdateView(defaultViewForPage.id, updatedDefaultView);
    }

    dispatch({
      type: UPDATE_PAGE_ACTIVE_VIEW_DATA,
      payload: { page, viewData, updatedDefaultView },
      meta: { markViewAsHavingChanges },
    });
  };
};

export const savePageActiveView = page => {
  return (dispatch, getState) => {
    const state = getState();

    const activeViewForPage = getActiveViewForPage(state, page);

    if (!activeViewForPage) {
      return;
    }

    if (!activeViewForPage.id) {
      return dispatch({
        type: CREATE_USER_VIEW,
        payload: axios.post('/api/userViews/', activeViewForPage),
        meta: { activeViewForPage },
      });
    }

    return dispatch({
      type: UPDATE_USER_VIEW,
      payload: axios.post(`/api/userViews/update/${activeViewForPage.id}`, omit(READ_ONLY_VIEW_PROPS, activeViewForPage)),
      meta: { activeViewForPage },
    });
  };
};

export const addUserViewFavorite = id => {
  return {
    payload: axios.post(`/api/userViews/${id}/favorite/`),
    meta: { id },
    type: ADD_USER_VIEW_FAVORITE,
  };
};

export const removeUserViewFavorite = id => {
  return {
    payload: axios.delete(`/api/userViews/${id}/favorite/`),
    meta: { id },
    type: DELETE_USER_VIEW_FAVORITE,
  };
};

export const updateUserViewsDialogProperties = (updatedProperties = initSaveDialogState) => {
  return {
    type: UPDATE_USER_VIEW_DIALOG_PROPERTIES,
    payload: updatedProperties,
  };
};

export const resetUserViewsDialogProperties = () => ({ type: RESET_USER_VIEW_DIALOG_PROPERTIES });

export const createUserViewPublicLink = (userViewId, linkData = {}) =>
  createThunk(CREATE_USER_VIEW_PUBLIC_LINK, axios.post(`/api/userViews/${userViewId}/public-links`, linkData), { userViewId });

export const updateUserViewPublicLink = (userViewId, linkId, newData) =>
  createThunk(UPDATE_USER_VIEW_PUBLIC_LINK, axios.put(`/api/userViews/${userViewId}/public-links/${linkId}`, newData), {
    userViewId,
    linkId,
  });

export const deleteUserViewPublicLink = (userViewId, linkId) =>
  createThunk(DELETE_USER_VIEW_PUBLIC_LINK, axios.delete(`/api/userViews/${userViewId}/public-links/${linkId}`), {
    userViewId,
    linkId,
  });

export const loadPublicSharedView = linkHash =>
  createThunk(FETCH_PUBLIC_USER_VIEW, axios.get(`/api/userViews/public-links/${linkHash}`));

export const gotUserViewsRealtimeUpdate = generateRealtimeUpdateAction(
  SAVE_USER_VIEW_FULFILLED,
  DELETE_USER_VIEW_FULFILLED,
  UPDATE_USER_VIEW_BY_REALTIME,
  DELETE_USER_VIEW_FULFILLED,
  SAVE_USER_VIEW_FULFILLED,
  UPDATE_USER_VIEW_BY_REALTIME,
);

export const toggleViewsDialog = open => ({
  type: TOGGLE_VIEWS_DIALOG,
  payload: open,
});
