import axios from 'axios';

import { DEFAULT_MAPPING_KEY } from 'constants/integrations';

import { createThunk } from 'utils/store/thunk';

import {
  DELETE_ORGANIZATION_DATA,
  FETCH_INTEGRATION_DRAGONBOAT_FIELDS,
  FETCH_INTEGRATION_FIELDS,
  FETCH_INTEGRATION_PROJECTS,
  GET_SLACK_INTEGRATION_CHANNELS,
  IMPORT_COUNT_INTEGRATION_ITEMS,
  IMPORT_INSERT_INTEGRATION_ITEMS,
  IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC,
  STORE_INTEGRATION_OAUTH_CLIENT_CREDENTIALS,
  UPDATE_INTEGRATION_FIELD_MAPPING,
  UPDATE_INTEGRATION_IMPORT_CONFIGURATION,
  UPDATE_INTEGRATION_PROGRESS_TRACKING,
  UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS,
} from './types';
import { isNil } from 'ramda';
import {
  selectGetSlackChannelsError,
  selectGetSlackChannelsIsLoading,
  selectGetSlackChannelsIsUndefined,
} from 'store/organization/selectors';

export function fetchIntegrationProjects(integrationType, orgIntegrationId) {
  return dispatch => {
    const payload = axios.get(`/api/integrations/${integrationType}/${orgIntegrationId}/projects`).then(res => res.data);

    dispatch({
      type: FETCH_INTEGRATION_PROJECTS,
      payload,
      meta: { integrationType, orgIntegrationId },
    });

    return payload;
  };
}

export function fetchIntegrationDragonboatFields(integrationType, orgIntegrationId, layer) {
  return dispatch => {
    let urlPath = `/api/integrations/${integrationType}/${orgIntegrationId}/dragonboat-fields`;

    if (!isNil(layer)) {
      urlPath = `${urlPath}/${layer}`;
    }

    const payload = axios.get(urlPath).then(res => res.data);

    dispatch({
      type: FETCH_INTEGRATION_DRAGONBOAT_FIELDS,
      payload,
    });

    return payload;
  };
}

export const fetchIntegrationFields = (integrationType, orgIntegrationId, layer) => {
  let urlPath = `/api/integrations/${integrationType}/${orgIntegrationId}/fields`;

  if (!isNil(layer)) {
    urlPath = `${urlPath}/${layer}`;
  }

  return createThunk(
    FETCH_INTEGRATION_FIELDS,
    () => axios.get(urlPath).then(res => res.data),
    { integrationType, orgIntegrationId },
    { includeRetryAction: true },
  );
};

export function updateIntegrationFieldMapping(
  integrationType,
  orgIntegrationId,
  entityKey,
  mappingKey = DEFAULT_MAPPING_KEY,
  data,
  layer,
) {
  return dispatch => {
    let urlPath = `/api/integrations/${integrationType}/${orgIntegrationId}/mapping/${entityKey}/${mappingKey}`;

    if (!isNil(layer)) {
      urlPath = `${urlPath}/${layer}`;
    }

    const payload = axios.put(urlPath, data).then(res => res.data);

    dispatch({
      type: UPDATE_INTEGRATION_FIELD_MAPPING,
      payload,
      meta: { integrationType, orgIntegrationId, layer },
    });

    return payload;
  };
}

export const updateIntegrationWebhooksProjectRestrictions = (integrationType, orgIntegrationId, data) =>
  createThunk(
    UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS,
    () =>
      axios
        .put(`/api/integrations/${integrationType}/${orgIntegrationId}/webhooks-project-restrictions`, data)
        .then(res => res.data),
    { integrationType, orgIntegrationId },
    { includeRetryAction: true },
  );

export const updateIntegrationProgressTracking = (integrationType, orgIntegrationId, data) =>
  createThunk(
    UPDATE_INTEGRATION_PROGRESS_TRACKING,
    () => axios.put(`/api/integrations/${integrationType}/${orgIntegrationId}/progress-tracking`, data).then(res => res.data),
    { integrationType, orgIntegrationId },
    { includeRetryAction: true },
  );

/**
 * Action to update the import configuration for a given organization integration.
 *
 * @function updateIntegrationImportConfiguration
 * @param  {String} integrationType                        the type of integration
 * @param  {Number} orgIntegrationId                       the ID of the organization integration
 * @param  {Boolean} data.recurring_import_enabled         flag to enable or disable recurring imports
 * @param  {String} data.recurring_import_link             recurring import link
 * @param  {Date} data.last_recurring_import      last time the recurring import was run
 * @return {Object}
 */
export function updateIntegrationImportConfiguration(integrationType, orgIntegrationId, data) {
  return dispatch => {
    const payload = axios
      .put(`/api/integrations/${integrationType}/${orgIntegrationId}/import/config`, data)
      .then(res => res.data);

    dispatch({
      type: UPDATE_INTEGRATION_IMPORT_CONFIGURATION,
      payload,
      meta: { integrationType, orgIntegrationId },
    });

    return payload;
  };
}

/**
 * Save client id, secret, etc. for integrations that require OAuth2 client credentials stored in the DB
 *
 * @param {String} integrationType
 * @param {Number} orgIntegrationId
 * @param {Object} clientCredentials
 * @returns
 */
export const storeOrgIntegrationOauthCredentials = (integrationType, orgIntegrationId, data) =>
  createThunk(
    STORE_INTEGRATION_OAUTH_CLIENT_CREDENTIALS,
    () => axios.post(`/api/integrations/${integrationType}/${orgIntegrationId}/auth/credentials`, { data }).then(res => res.data),
    { integrationType, orgIntegrationId },
    {},
  );

/**
 * Action to run the recurring import for a given organization integration.
 *
 * @function runIntegrationRecurringImport
 * @param  {type} integrationType             the type of integration
 * @param  {type} orgIntegrationId            the ID of the organization integration
 * @return {Object}
 */
export function runIntegrationRecurringImport(integrationType, orgIntegrationId) {
  return () => {
    const payload = axios.post(`/api/integrations/${integrationType}/${orgIntegrationId}/import/recurring`).then(res => res.data);

    return payload;
  };
}

export const importCountIntegrationItems = (integrationType, orgIntegrationId, queryId) =>
  createThunk(
    IMPORT_COUNT_INTEGRATION_ITEMS,
    () => axios.post(`/api/integrations/${integrationType}/${orgIntegrationId}/import/count`, { queryId }).then(res => res.data),
    { integrationType, orgIntegrationId },
    { includeRetryAction: true },
  );

export const importInsertIntegrationItems = (integrationType, orgIntegrationId, queryId) =>
  createThunk(
    IMPORT_INSERT_INTEGRATION_ITEMS,
    () => axios.post(`/api/integrations/${integrationType}/${orgIntegrationId}/import/insert`, { queryId }).then(res => res.data),
    { integrationType, orgIntegrationId, queryId },
    { includeRetryAction: true },
  );

export const importInsertIntegrationItemsAsync = (integrationType, orgIntegrationId, queryId) =>
  createThunk(
    IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC,
    () => axios.post(`/api/integrations/${integrationType}/${orgIntegrationId}/import/insert`, { queryId }).then(res => res.data),
    { integrationType, orgIntegrationId, queryId },
    { includeRetryAction: true },
  );

export const deleteOrganizationData = id =>
  createThunk(DELETE_ORGANIZATION_DATA, () => axios.delete(`/api/organizations/${id}`).then(({ data }) => data));

export const getSlackIntegrationChannels = () => {
  return (dispatch, getState) => {
    const isLoading = selectGetSlackChannelsIsLoading(getState());
    const isUndefined = selectGetSlackChannelsIsUndefined(getState());
    const error = selectGetSlackChannelsError(getState());

    // avoid loading if it is already loaded
    if (isLoading || (!isUndefined && !error)) {
      return;
    }

    return dispatch(
      createThunk(GET_SLACK_INTEGRATION_CHANNELS, () => axios.get('/api/integrations/slack/channels').then(({ data }) => data)),
    );
  };
};
