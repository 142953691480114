import PropTypes from 'prop-types';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import styled from 'styled-components';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';

import Checkbox from 'design-system/atoms/Checkbox/index';
import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';

import { IDEA_LAYER } from 'store/projects/constants';

const AddLayerToSearchComponent = ({
  onSelectLayer = () => {},
  onSelectSecondLayer = () => {},
  layer = null,
  secondLayer = null,
  options = [
    { key: '0', value: 'Ideas' },
    { key: '1', value: 'Initiatives' },
    { key: '2', value: 'Bets' },
  ],
  secondLayerOptions = [
    { key: '0', value: 'Ideas' },
    { key: '1', value: 'Initiatives' },
  ],
  disabled = false,
  bottomLayer = IDEA_LAYER,
  ...props
}) => {
  const [currentLayer, setCurrentLayer] = useState(layer);
  const [currentSecondLayer, setCurrentSecondLayer] = useState(secondLayer);

  useEffect(() => {
    // always apply the visible layer as the truth to ensure no other layers are being applied (not visible)
    onSelectLayer(layer);
    setCurrentLayer(layer);
  }, [layer]);

  const optionsLabels = useMemo(() => options.map(obj => ({ label: obj.value, value: obj.key })), [options]);

  const _onChangeLayer = useCallback(
    e => {
      onSelectLayer(e.target.value);
      setCurrentLayer(e.target.value);
    },
    [onSelectLayer],
  );

  const _onChangeSecondLayers = useCallback(
    e => {
      let values;

      if (e.target.checked) {
        values = (secondLayerOptions || []).map(o => o.key);
      } else {
        values = [];
      }

      onSelectSecondLayer(values);
      setCurrentSecondLayer(values);
    },
    [onSelectSecondLayer],
  );

  return (
    <div className={props.className}>
      <Line>
        <StyledTypography>Search </StyledTypography>
        <SelectLayer
          data-testid="advanced-search-select-layer"
          disabled={disabled}
          value={currentLayer}
          onChange={_onChangeLayer}
          SelectDisplayProps={{
            id: 'search_guide',
          }}
        >
          {optionsLabels.map(option => (
            <StyledMenuItemDropdown value={option.value}>{option.label}</StyledMenuItemDropdown>
          ))}
        </SelectLayer>
        {!!currentLayer && currentLayer !== bottomLayer && (
          <>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={!!(currentSecondLayer || []).length} onChange={_onChangeSecondLayers} />
              }
              label="include items without parent"
            />
            {/* <SelectSecondLayers
              options={secondOptionsLabels}
              value={(currentSecondLayer || []).map(key => secondLayerOptions.find(obj => obj.key === key).value)}
              onChange={_onChangeSecondLayers}
            />
            <StyledTypography>with no parent </StyledTypography> */}
          </>
        )}
      </Line>
    </div>
  );
};

AddLayerToSearchComponent.propTypes = {
  layer: PropTypes.any,
  onSelectLayer: PropTypes.func,
  style: PropTypes.any,
};

const Line = styled.div`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const StyledTypography = styled(props => <Typography {...props} variant="body2" />)`
  margin-top: 10px;
`;

const SelectLayer = styled(Select)`
  &&&& {
    margin: 0 10px 0 10px;
    max-width: 200px;
  }
`;

export default AddLayerToSearchComponent;
