// Comparator for sorting the 'Available In' column
// Items with only one association type should be first - sorted by the association type
// Items with multiple association types should be next
// Items with no association types should be last
export default (a, b, _1, _2, isInverted) => {
  if (a?.length === 1 && b?.length === 1) {
    return a[0]?.association_type?.localeCompare(b[0]?.association_type);
  }

  if (a?.length === 1 && b?.length > 1) return -1;
  if (a?.length > 1 && b?.length === 1) return 1;

  if (a?.length === 1 && b?.length === 0) return isInverted ? 1 : -1;
  if (a?.length === 0 && b?.length === 1) return isInverted ? -1 : 1;

  if (a?.length > 1 && b?.length === 0) return isInverted ? 1 : -1;
  if (a?.length === 0 && b?.length > 1) return isInverted ? -1 : 1;

  return 0;
};
