const VIEW_ITEM_VARIANTS = {
  dashboard: 'dashboard',
  homepage: 'homepage',
  widgetList: 'widget-list',
  widgetTile: 'widget-tile',
};

const EDIT_LABEL = 'Edit';
const SHARE_LABEL = 'Share';

const SHARED_VIEW_PARAM = 'sharedView';

const TEMPLATE_PARAM = 'template';

const HAS_HISTORY_TAB = 'historyTab';
const VIEWS_TAB = 'views';

const VIEWS_DROPDOWN_TABS = [VIEWS_TAB, HAS_HISTORY_TAB];

const TOTAL_VIEWS_UNIT = {
  MONTH: 'This month',
  ALL_TIME: 'All time',
};

export {
  VIEW_ITEM_VARIANTS,
  EDIT_LABEL,
  SHARE_LABEL,
  SHARED_VIEW_PARAM,
  TEMPLATE_PARAM,
  VIEWS_DROPDOWN_TABS,
  HAS_HISTORY_TAB,
  VIEWS_TAB,
  TOTAL_VIEWS_UNIT,
};
