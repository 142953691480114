import { APPLY_FILTERS_FULFILLED, CREATE_PROJECT_FULFILLED, UPDATE_PROJECT_FULFILLED } from 'store/projects';
import { orderedPlanningStages } from 'utils';
import reduceReducers from 'reduce-reducers';

import { ADD_SUMMARY_GROUP, REMOVE_SUMMARY_GROUP } from './types';
import {
  mergeMetadata,
  getCustomFieldOptions,
  filterPlanningStagesByAvailablePhases,
  parseGroupingDataFromMetadata,
  moveUserAddedMetadataToTheEnd,
} from './helpers';

import operationsReducers, { initialState as operationsInitialState } from './operationsReducer';
import { SET_PAGE_FILTERS } from 'store/filters';
import { defaultTo, path } from 'ramda';
import { UNDEFINED } from 'constants/common';

const initialState = {
  metadata: {},
  userAddedMetadata: {},
  userRemovedMetadata: {},
  ...operationsInitialState,
};

const defaultToEmptyArray = defaultTo([]);
const getOnLoad = path(['meta', 'onLoad']);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_FILTERS: {
      const onLoad = getOnLoad(action); // action not manually dispatched by the user manually applying a filter

      // if the action has not been manually dispatched by the user we don't want to clean the hidden metadata
      if (onLoad) {
        return state;
      }

      return {
        ...state,
        // when new filters are applied by the user clean the hidden metadata so user doesn't get
        userRemovedMetadata: {},
      };
    }
    case APPLY_FILTERS_FULFILLED: {
      const projects = action.payload?.projects;

      const parentsProjects = action.payload?.parents;
      const metadataOnDemand = action.payload?.metadataOnDemand;

      const allCustomFields = action.meta?.customFields || [];

      const customFieldsOptions = getCustomFieldOptions(projects, state.userAddedMetadata, allCustomFields);

      const parsedMetadata = parseGroupingDataFromMetadata(metadataOnDemand, projects, parentsProjects);

      const filteredUserAddedMetadata = Object.entries(state.userAddedMetadata).reduce((acc, [metadataType, metadataIds]) => {
        acc[metadataType] = (metadataIds || []).filter(id => id !== UNDEFINED);
        return acc;
      }, {});

      const sortedMetadata = moveUserAddedMetadataToTheEnd(parsedMetadata, filteredUserAddedMetadata);

      return {
        ...state,
        metadata: {
          ...sortedMetadata,
          ...customFieldsOptions,
        },
      };
    }
    case ADD_SUMMARY_GROUP: {
      const { metadataEntity, metadataType } = action.payload;

      const metadataEntityId = metadataEntity?.id;

      if (!metadataEntityId || !metadataType) return state;

      let updatedUserAddedMetadata = state.userAddedMetadata;

      updatedUserAddedMetadata = {
        ...state.userAddedMetadata,
        [metadataType]: [
          ...(state.userAddedMetadata?.[metadataType]?.filter(id => id !== metadataEntityId) ?? []),
          metadataEntityId,
        ],
      };

      const updatedUserRemovedMetadata = {
        ...state.userRemovedMetadata,
        [metadataType]: state.userRemovedMetadata?.[metadataType]?.filter(id => id !== metadataEntityId),
      };

      let updatedMetadata = state.metadata;

      if (metadataEntityId !== UNDEFINED) {
        updatedMetadata = {
          ...state.metadata,
          [metadataType]: [
            ...defaultToEmptyArray(state.metadata?.[metadataType]).filter(({ id }) => id !== metadataEntityId),
            metadataEntity,
          ],
        };
      }

      return {
        ...state,
        metadata: updatedMetadata,
        userAddedMetadata: updatedUserAddedMetadata,
        userRemovedMetadata: updatedUserRemovedMetadata,
      };
    }

    case REMOVE_SUMMARY_GROUP: {
      const { metadataEntityId, metadataType } = action.payload;

      if (!metadataEntityId || !metadataType) return state;

      return {
        ...state,
        userRemovedMetadata: {
          ...state.userRemovedMetadata,
          [metadataType]: [
            ...(state.userRemovedMetadata?.[metadataType]?.filter(id => id !== metadataEntityId) ?? []),
            metadataEntityId,
          ],
        },
        userAddedMetadata: {
          ...state.userAddedMetadata,
          [metadataType]: state.userAddedMetadata?.[metadataType]?.filter(id => id !== metadataEntityId),
        },
      };
    }

    case CREATE_PROJECT_FULFILLED:
    case UPDATE_PROJECT_FULFILLED: {
      const projectData = action.payload?.projectData;
      const metadataOnDemand = action.payload?.metadataOnDemand;
      const allCustomFields = action.meta?.customFields || [];
      const { userAddedMetadata } = state;

      const customFieldsOptions = getCustomFieldOptions([projectData], userAddedMetadata, allCustomFields);

      const newMetadata = {
        ...metadataOnDemand,
        planningStages: filterPlanningStagesByAvailablePhases(orderedPlanningStages, metadataOnDemand?.phases),
      };

      return {
        ...state,
        metadata: mergeMetadata(state.metadata, { ...newMetadata, ...customFieldsOptions }),
      };
    }

    default: {
      return state;
    }
  }
};

export default reduceReducers(initialState, reducer, ...operationsReducers);
