import { createSelector } from 'reselect';

import { getData, isLoading } from 'utils/store/thunk';

import { UPDATE_ESTIMATES, CREATE_PROJECT_ESTIMATE, DELETE_PROJECT_ESTIMATE } from './types';

const getEstimatesOperations = state => {
  return state.estimates.operations;
};

const isUpdateEstimateLoading = createSelector(getEstimatesOperations, state => isLoading(state, UPDATE_ESTIMATES));

const isCreateEstimateLoading = createSelector(getEstimatesOperations, state => isLoading(state, CREATE_PROJECT_ESTIMATE));

const getCreateEstimateOperationData = createSelector(getEstimatesOperations, state => getData(state, CREATE_PROJECT_ESTIMATE));

const isDeleteEstimateLoading = createSelector(getEstimatesOperations, state => isLoading(state, DELETE_PROJECT_ESTIMATE));

const isSomeUpdateOnProjectsEstimatesOcurring = createSelector(
  isUpdateEstimateLoading,
  isCreateEstimateLoading,
  isDeleteEstimateLoading,
  (updateEstimateLoading, createEstimateLoading, deleteEstimateLoading) => {
    return updateEstimateLoading || createEstimateLoading || deleteEstimateLoading;
  },
);

export {
  isUpdateEstimateLoading,
  isCreateEstimateLoading,
  isDeleteEstimateLoading,
  isSomeUpdateOnProjectsEstimatesOcurring,
  getCreateEstimateOperationData,
};
