import { schema, normalize } from 'normalizr';

import { add, remove, makeDependencyEntityId } from 'utils/store/entities';

import {
  FETCH_PROJECTS_CHILDREN_FULFILLED,
  CREATE_PROJECT_FULFILLED,
  BULK_CREATE_PROJECTS_FULFILLED,
  UPDATE_PROJECT_FULFILLED,
  UPDATE_PROJECTS_FULFILLED,
  BULK_UPDATE_PROJECTS_FULFILLED,
  // UNDO_BULK_UPDATE_PROJECTS_FULFILLED, // TODO: not used?
  DELETE_PROJECTS_FULFILLED,
  // UNDO_DELETE_PROJECTS_FULFILLED, // TODO: not used?
  UPDATE_PROJECT_ROW_ORDER_FULFILLED,
  FETCH_PROJECT_CUSTOMER_REQUESTS_FULFILLED,
  // GET_PROJECT_COUNTERS_BY_PHASE_FULFILLED, // TODO: not used?
  MERGE_PROJECTS_FULFILLED,
  CLONE_PROJECT_FULFILLED,
  APPLY_FILTERS_FULFILLED,
  ADD_PROJECT_METRIC_FULFILLED,
  BULK_ADD_PROJECT_METRICS_FULFILLED,
  BULK_REMOVE_PROJECT_METRICS_FULFILLED,
  REMOVE_PROJECT_METRIC_FULFILLED,
  CREATE_AND_ADD_METRIC_TO_PROJECT_FULFILLED,
  LOAD_PROJECTS_FROM_AUTOCOMPLETE_FULFILLED,
  LOAD_PROJECTS_FROM_AUTOCOMPLETE_TITLE_FULFILLED,
  UPDATE_PROJECT_PERSONAS_FULFILLED,
  UPDATE_PROJECT_LIFECYCLES_FULFILLED,
} from '../types';
import { ADD_PROJECT_INTEGRATION_FULFILLED } from 'store/integrations';

const dependenciesEntityOptions = {
  idAttribute: (value, parent) => makeDependencyEntityId(parent.id, value.id),
};

// TODO: split user schemata?
const userEntity = new schema.Entity('user');
const projectProjectDependenciesEntity = new schema.Entity('projectProjectDependencies', undefined, dependenciesEntityOptions);
const projectEstimateDependenciesEntity = new schema.Entity('projectEstimateDependencies', undefined, dependenciesEntityOptions);
const projectTaskDependenciesEntity = new schema.Entity('projectTaskDependencies', undefined, dependenciesEntityOptions);
const customersEntity = new schema.Entity('customers');
const filesEntity = new schema.Entity('files');
const tagsEntity = new schema.Entity('tags');
const roadmapEntity = new schema.Entity('roadmap');
const metricsEntity = new schema.Entity('metrics');
const projectsEntity = new schema.Entity('projects', {
  owner: userEntity,
  createdBy: userEntity,
  updatedBy: userEntity,
  projectDependencies: [projectProjectDependenciesEntity],
  estimateDependencies: [projectEstimateDependenciesEntity],
  taskDependencies: [projectTaskDependenciesEntity],
  watchers: [userEntity],
  customers: [customersEntity],
  files: [filesEntity],
  tags: [tagsEntity],
  roadmap: roadmapEntity,
  metrics: [metricsEntity],
});

projectsEntity.define({ children: [projectsEntity] });

const mapProjectEntitiesToState = (state, entities) => ({
  ...state,
  projects: add(state.projects, entities.projects),
  roadmaps: add(state.roadmaps, entities.roadmap),
  users: add(state.users, entities.user),
  files: add(state.files, entities.files),
  customers: add(state.customers, entities.customers),
  tags: add(state.tags, entities.tags),
  metrics: add(state.metrics, entities.metrics),
  dependencies: {
    ...state.dependencies,
    projectTask: add(state.dependencies.projectTask, entities.projectTaskDependencies),
    projectEstimate: add(state.dependencies.projectEstimate, entities.projectEstimateDependencies),
    projectProject: add(state.dependencies.projectProject, entities.projectProjectDependencies),
  },
});

const addProjectToState = (state, project) => {
  const { entities } = normalize(project, projectsEntity);

  return mapProjectEntitiesToState(state, entities);
};

const bulkAddProjectsToState = (state, projects) => {
  const { entities } = normalize(projects, [projectsEntity]);

  return mapProjectEntitiesToState(state, entities);
};

const removeProjectsFromState = (state, projectsIds) => {
  return {
    ...state,
    projects: remove(state.projects, projectsIds),
    // TODO: also remove associated entities
  };
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROJECTS_CHILDREN_FULFILLED:
    case BULK_CREATE_PROJECTS_FULFILLED:
    case BULK_UPDATE_PROJECTS_FULFILLED: {
      return bulkAddProjectsToState(state, action.payload);
    }
    case APPLY_FILTERS_FULFILLED:
      return bulkAddProjectsToState(state, action.payload.projects);

    case UPDATE_PROJECTS_FULFILLED: {
      return bulkAddProjectsToState(state, action.payload.data);
    }

    case DELETE_PROJECTS_FULFILLED: {
      return removeProjectsFromState(state, action.payload);
    }

    case LOAD_PROJECTS_FROM_AUTOCOMPLETE_FULFILLED: {
      return bulkAddProjectsToState(state, action.payload.data.data);
    }

    case LOAD_PROJECTS_FROM_AUTOCOMPLETE_TITLE_FULFILLED: {
      return bulkAddProjectsToState(state, action.payload.data);
    }

    case MERGE_PROJECTS_FULFILLED: {
      // TODO: the projects need to be manually merged on the entities when
      //  removing the ID of the merged projects, check if it doesn't simply
      //  remove references to it, but also updates to the remaining project
      return removeProjectsFromState(state, action.payload);
    }

    case FETCH_PROJECT_CUSTOMER_REQUESTS_FULFILLED: {
      // TODO: this one should be handled by the customer requests entities reducer
      return state;
    }

    case ADD_PROJECT_METRIC_FULFILLED:
    case ADD_PROJECT_INTEGRATION_FULFILLED:
    case BULK_ADD_PROJECT_METRICS_FULFILLED:
    case BULK_REMOVE_PROJECT_METRICS_FULFILLED:
    case CLONE_PROJECT_FULFILLED:
    case CREATE_PROJECT_FULFILLED:
    case CREATE_AND_ADD_METRIC_TO_PROJECT_FULFILLED:
    case REMOVE_PROJECT_METRIC_FULFILLED:
    case UPDATE_PROJECT_FULFILLED:
    case UPDATE_PROJECT_LIFECYCLES_FULFILLED:
    case UPDATE_PROJECT_PERSONAS_FULFILLED:
    case UPDATE_PROJECT_ROW_ORDER_FULFILLED: {
      const project = action.payload.projectData || action.payload;
      const parentProject = action.payload.parentData;
      let currentState = state;

      if (parentProject) {
        currentState = addProjectToState(currentState, parentProject);
      }

      return addProjectToState(currentState, project);
    }

    default: {
      return state;
    }
  }
};

export default reducer;
