import isPlainObject from 'utils/isPlainObject';

export default filters => {
  const _parse = attr => {
    return Object.keys((filters || {})[attr] || {}).reduce((acc, key) => {
      if (['users', 'created_by'].includes(key) && Array.isArray(filters[attr][key])) {
        acc[key] = filters[attr][key].map(f => (isPlainObject(f) ? String(f.id) : f));
      } else {
        acc[key] = filters[attr][key];
      }

      return acc;
    }, {});
  };

  return {
    ...filters,
    fields: _parse('fields'),
    childrenFields: _parse('childrenFields'),
    quickFilters: null,
  };
};
