import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import styled from 'styled-components';

import { FeatureFlags } from '@dragonboat/config';

import useFeatureFlags from 'hooks/useFeatureFlags';
import useSystemFields from 'hooks/useSystemFields';

import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';

const ProjectLayerRadioButton = ({ selectedLayer, onChangeLayer, className }) => {
  const [getSystemFieldName] = useSystemFields();

  const hasBet = useFeatureFlags([FeatureFlags.HAS_BET]);

  return (
    <div className={className}>
      <RadioGroup row aria-label="Type" name="layer" value={selectedLayer} onChange={({ target }) => onChangeLayer(target.value)}>
        <StyledFormControlLabel value={IDEA_LAYER} control={<Radio />} label={`Import ${getSystemFieldName('idea')}`} />
        <StyledFormControlLabel
          value={INITIATIVE_LAYER}
          control={<Radio />}
          label={`Import ${getSystemFieldName('initiative')}`}
        />
        {hasBet && (
          <StyledFormControlLabel value={BET_LAYER} control={<Radio />} label={`${getSystemFieldName('bet')} (Highest)`} />
        )}
      </RadioGroup>
    </div>
  );
};

const StyledFormControlLabel = styled(FormControlLabel)`
  span:nth-child(2) {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    color: ${({ theme }) => theme.typography.color};
  }
`;

export default ProjectLayerRadioButton;
