/**
 * Dropdown Molecule Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';

import { spacing } from 'design-system/theme';

class Dropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      menuOpen: false,
      // selectedValue: this.props.selectedValue,
    };

    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.selectValue = this.selectValue.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.visibleOnReceiveProps) {
      this.setState({ menuOpen: false });
    }
  }

  openMenu(event) {
    const { menuOpen } = this.state;

    this.setState({ menuOpen: !menuOpen, anchorEl: event.currentTarget });
  }

  closeMenu(event) {
    this.setState({ menuOpen: false, anchorEl: null });
  }

  selectValue(e, value) {
    const { onChange, closeMenuOnSelect } = this.props;

    if (value.onClick) {
      value.onClick();
    }
    if (onChange) {
      onChange(e, value);
    }
    if (closeMenuOnSelect) this.closeMenu();
  }

  getSelectedTitle(value, allTitle) {
    if (!value || !value.title) {
      return allTitle || 'All';
    }
    return value.title.length < 20 ? value.title : value.title.slice(0, 19).concat('...');
  }

  render() {
    const {
      className,
      placeholder,
      options,
      inline,
      isButton,
      getRef,
      placement,
      MenuProps = {},
      MenuItemProps = {},
      MenuListProps = {},
      label,
    } = this.props;

    const { menuOpen, anchorEl } = this.state;

    const menuId = this.props.dataCy || 'more-options-button';

    return (
      <Wrapper className={className} inline={inline}>
        {isButton && (
          <Button aria-owns={menuOpen ? menuId : null} aria-haspopup="true" onClick={this.openMenu} size="small" data-cy={menuId}>
            {placeholder}
          </Button>
        )}
        {!isButton && (
          <div role="button" tabIndex="0" onClick={this.openMenu} data-cy={menuId} style={{ outline: 'none' }}>
            {placeholder}
          </div>
        )}
        <Menu
          {...MenuProps}
          id={menuId}
          anchorEl={anchorEl}
          open={menuOpen}
          ref={ref => (getRef ? getRef(ref) : undefined)}
          onClose={this.closeMenu}
          style={{ zIndex: 1000, ...(MenuProps.style || {}) }}
          placement={placement}
        >
          <Paper>
            {label && <Label>{label}</Label>}
            <ClickAwayListener
              onClickAway={e => {
                if (!anchorEl.contains(e.target)) {
                  this.closeMenu();
                }
              }}
            >
              <MenuList {...MenuListProps}>
                {options &&
                  options
                    .filter(option => !!option)
                    .map(option => (
                      <MenuOption
                        key={option.key || option.id}
                        onClick={e => {
                          this.selectValue(e, option);
                        }}
                        data-cy={option.key}
                        active={option.active}
                        {...MenuItemProps}
                      >
                        {option.title}
                      </MenuOption>
                    ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Menu>
      </Wrapper>
    );
  }
}

Dropdown.propTypes = {
  isButton: PropTypes.bool,
  className: PropTypes.string,
  placement: PropTypes.string,
};

Dropdown.defaultProps = {
  isButton: true,
  placement: 'bottom-end',
};

export default Dropdown;

const Wrapper = styled.div`
  margin-right: ${spacing(0.625)}px;

  ${({ inline }) =>
    inline &&
    css`
      display: inline-block;
    `}
`;

const Label = styled(Typography)`
  &&&& {
    font-weight: 500;
    padding: 10px 15px 0 15px;
  }
`;

const Menu = styled(Popper)`
  &&&&& {
    min-width: 120px;

    ul li {
      height: 14px !important;
      font-size: 14px !important;
    }
  }
`;

const MenuOption = styled(MenuItem)`
  &&&& {
    ${({ active }) =>
      active &&
      `
      background-color: #ebebeb;
    `}
  }
`;
