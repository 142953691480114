import axios from 'axios';
import { difference, isEmpty, without } from 'ramda';

import { createThunk } from 'utils/store/thunk';

import {
  ADD_CUSTOMER_REQUEST_FROM_PROJECT,
  CREATE_CUSTOMER_REQUEST_FROM_PROJECT,
  FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_PIE_CHART,
  FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_STACKED_CHART,
  SET_CUSTOMER_REQUESTS_MULTI_FILTERS,
  UPDATE_CUSTOMER_REQUESTS_PERSONAS,
  UPDATE_CUSTOMER_REQUESTS_LIFECYCLES,
  MERGE_CUSTOMER_REQUESTS,
} from './types';
import { getCustomerRequestsDefaultUserFilter } from './selectors';
import { getCurrentUser } from 'store/login/selectors';
import { REQUESTS_FILTERS } from 'constants/filters';
import { updateRequestLifecyclesApiCall, updateRequestPersonasApiCall } from 'store/customerRequests/api';

const createCustomerRequestFromProject = (id, title) => {
  return createThunk(CREATE_CUSTOMER_REQUEST_FROM_PROJECT, axios.post(`/api/projects/${id}/customer-requests`, { title }), {
    id,
  });
};

const addCustomerRequestFromProject = (id, customerRequestId) => {
  return createThunk(
    ADD_CUSTOMER_REQUEST_FROM_PROJECT,
    axios.post(`/api/projects/${id}/customer-requests/${customerRequestId}`),
    { id },
  );
};

const fetchCustomerRequestsInsightsForCounterPieChart = (groupBy, filters = {}) => {
  return createThunk(FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_PIE_CHART, () => {
    return axios
      .get('/api/customer-requests/insights-data/', {
        params: {
          group_by: groupBy,
          ...filters,
        },
      })
      .then(({ data }) => data);
  });
};

const fetchCustomerRequestsInsightsForCounterStackedChart = (groupBy, stackBy, filters = {}) => {
  return createThunk(FETCH_CUSTOMER_REQUEST_INSIGHTS_FOR_COUNTER_STACKED_CHART, () => {
    return axios
      .get('/api/customer-requests/insights-data/', {
        params: {
          group_by: groupBy,
          stack_by: stackBy,
          ...filters,
        },
      })
      .then(({ data }) => data);
  });
};

const setCustomerRequestsMultiFilters =
  (filters, multiFilterActive = null) =>
  (dispatch, getState) => {
    const state = getState();

    const currentDefaultUserFilter = getCustomerRequestsDefaultUserFilter(state);

    const currentUser = getCurrentUser(state);

    const payload = async () => {
      // TODO UserFilters API doesn't allow [] to be stored, should it be changed to allow?
      const filtersToSave = isEmpty(filters) ? [{ key: 'empty' }] : filters;

      const newState = {
        multiFilters: filtersToSave,
        multiFilterActive,
      };

      if (currentDefaultUserFilter) {
        await axios.put(`/api/userFilters/${currentDefaultUserFilter.id}`, { state: newState });

        return {
          ...currentDefaultUserFilter,
          state: newState,
        };
      }

      const { data } = await axios.post('/api/userFilters/', {
        name: `Default Filter (${currentUser.id})`,
        page: REQUESTS_FILTERS,
        state: newState,
        user_id: currentUser.id,
        default_filter: true,
      });

      return data;
    };

    return dispatch(
      createThunk(SET_CUSTOMER_REQUESTS_MULTI_FILTERS, payload, () => ({
        makesActiveViewDirty: true,
        filters,
        multiFilterActive,
      })),
    );
  };

const updateCustomerRequestPersonas = (requestId, updatePersonasIds, oldPersonasIds) =>
  createThunk(
    UPDATE_CUSTOMER_REQUESTS_PERSONAS,
    async () => {
      const newPersonasIds = without(oldPersonasIds, updatePersonasIds);
      const removedPersonasIds = difference(oldPersonasIds, updatePersonasIds);
      let result = {};

      if (newPersonasIds.length) {
        result = await updateRequestPersonasApiCall(requestId, newPersonasIds);
      }

      if (removedPersonasIds.length) {
        result = await axios
          .delete(`/api/customer-requests/${requestId}/personas?personaIds=${removedPersonasIds.join(',')}`)
          .then(res => res.data);
      }

      return result;
    },
    {},
  );

const updateCustomerRequestLifecycles = (requestId, updatedLifecyclesIds, oldLifecyclesIds) =>
  createThunk(
    UPDATE_CUSTOMER_REQUESTS_LIFECYCLES,
    async () => {
      const newLifecyclesIds = without(oldLifecyclesIds, updatedLifecyclesIds);
      const removedLifecyclesIds = difference(oldLifecyclesIds, updatedLifecyclesIds);
      let result = {};

      if (newLifecyclesIds.length) {
        result = await updateRequestLifecyclesApiCall(requestId, newLifecyclesIds);
      }

      if (removedLifecyclesIds.length) {
        result = await axios
          .delete(`/api/customer-requests/${requestId}/lifecycles?lifecycleIds=${removedLifecyclesIds.join(',')}`)
          .then(res => res.data);
      }

      return result;
    },
    {},
  );

const mergeCustomerRequests = (requestId, idsToMerge = []) =>
  createThunk(MERGE_CUSTOMER_REQUESTS, () => {
    return axios
      .post(`/api/customer-requests/${requestId}/merge`, {
        idsToMerge,
      })
      .then(response => {
        return response.data;
      });
  });

export {
  createCustomerRequestFromProject,
  addCustomerRequestFromProject,
  fetchCustomerRequestsInsightsForCounterPieChart,
  fetchCustomerRequestsInsightsForCounterStackedChart,
  setCustomerRequestsMultiFilters,
  updateCustomerRequestPersonas,
  updateCustomerRequestLifecycles,
  mergeCustomerRequests,
};
