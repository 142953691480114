import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';
import isObject from 'lodash/isObject';

/**
 * @function sumAggSkills
 *
 * func to aggregate all estimates values on ag grid
 *
 * @param  {Object} params
 * @param  {array}  params.values
 * @return {Number}
 */
const sumAggSkills = ({ values }) => {
  let result = 0;

  if (!values) {
    return '';
  }

  values.forEach(val => {
    if (isNumber(val)) {
      result += val;
    } else if (isObject(val) && val?.conditionalValue && isNumber(val?.conditionalValue)) {
      result += +val.conditionalValue;
    } else if (isObject(val) && val.value) {
      result += +val.value;
    }
  });

  return !isNaN(result) && isNumber(result) ? +result.toFixed(2) : '';
};

export default sumAggSkills;
