import React from 'react';
import FatalError from 'containers/FatalError';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  componentWillReceiveProps = nextProps => {
    this.setState({
      hasError: this.state.hasError || !!nextProps.appError,
      error: this.state.error || nextProps.appError,
    });

    if (nextProps.appError) {
      this.props.setAppError(null);
    }
  };

  componentDidCatch(error, info) {
    if (window.Rollbar) {
      window.Rollbar.error(error); // Send it to Rollbar!
    }
    this.setState({
      hasError: true,
      error: 'Unexpected error occurred when try to load the page',
    });
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      return <FatalError error={error} userLogout={this.props.userLogout} />;
    }

    return this.props.children;
  }
}
