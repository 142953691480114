import {
  UPDATE_METRICS_CANVAS_LAYOUT_CONFIG,
  UPDATE_METRICS_CANVAS_NODES_LAYOUT,
  UPDATE_METRICS_CANVAS_EDGES_LAYOUT,
  SET_METRICS_CANVAS_DATA,
} from './types';

export const updateLayoutConfig = layoutConfig => ({
  type: UPDATE_METRICS_CANVAS_LAYOUT_CONFIG,
  payload: layoutConfig,
  meta: {
    makesActiveViewDirty: true,
  },
});

export const updateNodesLayout = nodesLayout => ({
  type: UPDATE_METRICS_CANVAS_NODES_LAYOUT,
  payload: nodesLayout,
  meta: {
    makesActiveViewDirty: true,
  },
});

export const updateEdgesLayout = edgesLayout => ({
  type: UPDATE_METRICS_CANVAS_EDGES_LAYOUT,
  payload: edgesLayout,
  meta: {
    makesActiveViewDirty: true,
  },
});

export const setMetricsCanvasData = ({ objectives = [], keyResults = [], metrics = [] }) => ({
  type: SET_METRICS_CANVAS_DATA,
  payload: {
    objectives,
    keyResults,
    metrics,
  },
});
