import React from 'react';
import styled from 'styled-components';

import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete';

import { getSuggestionsForGroupByDropdown, hasNextGroupByDropdown } from 'utils/swimlane/new/groupingBy';
import useMetadataGroupByOptions from 'hooks/useMetadataGroupByOptions';

const GroupByList = ({ selectedGroupOptions, groupByOptions, changeSelectedGroupOption }) =>
  selectedGroupOptions.map((selectedGroupOption, selectedGroupIndex) => {
    const shouldAddCurrentThen = hasNextGroupByDropdown(selectedGroupOptions, selectedGroupIndex);
    const shouldAddCurrentGroupBy = selectedGroupIndex === 0 || shouldAddCurrentThen;

    const suggestions = getSuggestionsForGroupByDropdown(selectedGroupOptions, selectedGroupIndex, groupByOptions);

    const { groupByOptions } = useMetadataGroupByOptions(suggestions);

    return (
      // eslint-disable-next-line react/no-array-index-key
      <DropdownWrapper key={`${selectedGroupOption.key}${selectedGroupIndex}`}>
        {shouldAddCurrentThen && <Spacing />}
        {shouldAddCurrentGroupBy && (
          <GroupByAutocomplete
            name={`groupByData-${selectedGroupIndex}`}
            suggestions={groupByOptions}
            value={selectedGroupOption}
            onChange={changeSelectedGroupOption(selectedGroupIndex)}
            label="then by"
          />
        )}
      </DropdownWrapper>
    );
  });

export default GroupByList;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Spacing = styled.div`
  padding: 5px;
`;
