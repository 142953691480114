import React, { useState } from 'react';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';

import ChromePicker from 'components/ReactColor/ChromePicker';
import theme, { spacing } from 'design-system/theme';
import isValidColor from 'utils/isValidColor';

const DEFAULT_COLOR = theme.palette.newLayout.background.primary;

const SelectColor = ({ color: initialColor, onChange }) => {
  const [color, setColor] = useState(isValidColor(initialColor) ? initialColor : DEFAULT_COLOR);
  const [anchorEl, setAnchorEl] = useState(null);

  const openColorPicker = e => setAnchorEl(e.currentTarget);

  const closeColorPicker = e => {
    setAnchorEl(null);
    if (initialColor !== color) onChange(color);
  };

  return (
    <>
      <ColorIndicator backgroundColor={color} onClick={openColorPicker} />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeColorPicker}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ChromePicker color={color} onChange={value => setColor(value.hex)} />
      </Popover>
    </>
  );
};

export default SelectColor;

const ColorIndicator = styled.div`
  width: ${spacing(8)}px;
  height: ${spacing(2)}px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ theme }) => theme.shape.borderRadiusExtraLarge}px;
  cursor: pointer;
`;
