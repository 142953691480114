import { defaultTo } from 'ramda';

const defaultAsEmptyArray = defaultTo([]);

/**
 * Retrieves an array of column IDs for visible columns from the provided Ag-Grid column state.
 *
 * @param {Array<object>} columnState - An array representing the Ag-Grid column state.
 * @returns {Array<string>} An array of column IDs for the visible columns.
 */
const getVisibleColumnsFromAgGridColumnState = columnState => {
  const visibleColumnsOnGrid = defaultAsEmptyArray(columnState)
    .filter(c => !c.hide)
    .map(c => c.colId);

  return visibleColumnsOnGrid;
};

export default getVisibleColumnsFromAgGridColumnState;
