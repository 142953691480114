const useTableBarActionHandlers = (
  id,
  left,
  top,
  setOpen,
  isClickEventNoDragEnd,
  onDragStop,
  onDrag,
  onResizeStop,
  onResize,
  openBody,
  openLightBox,
  onDoubleClick,
) => {
  const onOpenTooltip = () => {
    setOpen(true);
  };

  const onCloseTooltip = () => {
    setOpen(false);
  };

  const handleDragStop = (event, delta) => {
    // should not trigger the drag event on double click
    // prevent the project update when we only want to open the lightbox
    if (isClickEventNoDragEnd(delta, left, top)) {
      return;
    }

    if (onDragStop) onDragStop(delta);
    event.stopPropagation();
  };

  const handleDrag = (_, delta) => {
    if (onDrag) onDrag(delta);
  };

  const handleResizeStop = (_, direction, __, delta, position) => {
    if (onResizeStop) onResizeStop(direction, delta, position);
  };

  const handleResize = (_, direction, __, delta, position) => {
    if (onResize) onResize(direction, delta, position);
  };

  const handleClick = event => {
    if (openBody) openBody(id);
    event.stopPropagation();
  };

  const handleDoubleClick = event => {
    if (openLightBox) openLightBox(id);
    if (onDoubleClick) onDoubleClick(id);
    event.stopPropagation();
  };

  return {
    onOpenTooltip,
    onCloseTooltip,
    handleDragStop,
    handleDrag,
    handleResizeStop,
    handleResize,
    handleClick,
    handleDoubleClick,
  };
};

export default useTableBarActionHandlers;
