import { useMemo } from 'react';
import { equals } from 'ramda';

import useLoginInfo from 'hooks/useLoginInfo';
import useUserPermissions from 'hooks/useUserPermissions';

const useActionsPermissions = commentCreatorId => {
  const { isLeaderUser, isOwner } = useUserPermissions();
  const { currentUser } = useLoginInfo();

  const isCurrentRoleAllowedToDelete = useMemo(() => {
    return isLeaderUser || isOwner;
  }, [isLeaderUser, isOwner]);

  const isCurrentUserTheCommentCreator = useMemo(() => {
    return equals(currentUser?.id, commentCreatorId);
  }, [currentUser?.id, commentCreatorId]);

  const isDeleteAllowed = isCurrentUserTheCommentCreator || isCurrentRoleAllowedToDelete;
  const isEditAllowed = isCurrentUserTheCommentCreator;

  return {
    isDeleteAllowed,
    isEditAllowed,
  };
};

export default useActionsPermissions;
