import {
  UPDATE_ALLOCATION_REPORT_STATE,
  UPDATE_FORECAST_STATE,
} from './types';

export const updateAllocationReportState = (state) => {
  return {
    payload: state,
    type: UPDATE_ALLOCATION_REPORT_STATE,
    meta: { makesActiveViewDirty: true },
  };
};

export const updateState = (state, makesActiveViewDirty = true) => {
  return {
    payload: state,
    type: UPDATE_FORECAST_STATE,
    meta: { makesActiveViewDirty },
  };
};


export const forecastActions = {
  updateState,
  updateAllocationReportState,
};
