import React, { createContext, useState, useContext, useCallback } from 'react';

import CommentsLightbox from 'containers/CommentLightbox/CustomerRequests/CommentsLightbox';

const CommentsLightboxContext = createContext({});

const CustomerRequestsCommentsLightboxProvider = ({ children }) => {
  const [commentsLightbox, setCommentsLightbox] = useState();

  const openCommentsLightbox = useCallback(data => setCommentsLightbox(data), []);
  const closeCommentsLightbox = () => setCommentsLightbox(null);

  return (
    <CommentsLightboxContext.Provider value={{ openCommentsLightbox }}>
      {children}
      <CommentsLightbox
        isOpen={!!commentsLightbox}
        onClose={closeCommentsLightbox}
        item={commentsLightbox ? { id: commentsLightbox.requestId } : {}}
        field={commentsLightbox && commentsLightbox.field}
      />
    </CommentsLightboxContext.Provider>
  );
};

/**
 * @function useCustomerRequestsCommentsLightbox
 *
 * hook to use the comments lightbox context
 * needs to be wrapped on CustomerRequestsCommentsLightboxProvider
 *
 * @return {Object}
 */
const useCustomerRequestsCommentsLightbox = () => useContext(CommentsLightboxContext);

export { CustomerRequestsCommentsLightboxProvider };

export default useCustomerRequestsCommentsLightbox;
