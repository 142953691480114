import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTemplate, getTemplates, setActiveTemplate } from 'store/templates';
import { getActiveViewForPage } from 'store/userViews/selectors';
import { getPageIdFromPath } from 'utils/userViews';

/**
 * Tracks change in location. When an active template is applied, the useEffect
 * validates if the activeTemplate is valid for the current path. If the template
 * is no longer relevant (the user has navigated away or applied a view), this should
 * clear the activeTemplate from the store. An activeTemplate indicates which
 * header should be present on a page, and when to save the template_id on a User View
 * @param {*} location 
 */
export default function useTemplateChanges(location) {
  const dispatch = useDispatch();
  const currentPageIdFromPath = getPageIdFromPath(location.pathname.replace('/', ''));
  const activeTemplateId = useSelector(getActiveTemplate);
  const templates = useSelector(getTemplates);
  const pageActiveView = useSelector(state => getActiveViewForPage(state, currentPageIdFromPath));

  const selectedTemplate = useMemo(() => {
    if (activeTemplateId) {
      return templates.find(template => template.id === activeTemplateId);
    }
  }, [activeTemplateId]);

  useEffect(() => {
    if (activeTemplateId) {
      const newPath = location.pathname.replace(/^\/+/, '');
      const pathChangedFromTemplate = newPath !== selectedTemplate?.path;

      // Path changed, or view changed or was already persisted lets force template to be set as null
      if (pathChangedFromTemplate || pageActiveView?.id) {
        dispatch(setActiveTemplate(null));
      }
    }
  }, [activeTemplateId, location?.pathname, pageActiveView]);
}
