import React from 'react';
import styled, { css } from 'styled-components';

import BaseLayout from 'design-system/organisms/BaseLayout/index';
import PostList from 'design-system/organisms/PostList/index';
import { PAGE_HEADER_HEIGHT } from 'constants/common';
import { spacing } from 'design-system/theme';

import ListItems from './ListItems';
import RequestsPagination from '../Pagination/RequestsPaginationComponent';
import LocalSearch from '../ControlsBar/LocalSearch';

export const TOOLBAR_HEIGHT = 64;
export const PAGE_HEIGHT_OFFSET = PAGE_HEADER_HEIGHT + TOOLBAR_HEIGHT;

const ListWrapper = styled.section`
  &&&& {
    ${props =>
      props.isDrawerOpen &&
      `
      max-width: 900px;

      @media only screen and (min-width: 1200px) {
        min-width: 600px;
      }

      @media only screen and (min-width: 2030px) {
        max-width: 1200px;
      }

      @media only screen and (min-width: 2230px) {
        margin-left: 200px;
      }
    `}

    ${props =>
      !props.isDrawerOpen &&
      `
      max-width: 900px;

      @media only screen and (min-width: 1200px) {
        margin-left: 150px;
      }
      @media only screen and (min-width: 1750px) {
        max-width: 1200px;
        margin-left: 300px;
      }
    `}
  }
`;

const layoutContentStyles = css`
  max-height: calc(100vh - ${PAGE_HEIGHT_OFFSET}px);
  overflow-y: auto;
`;

const LocalSearchWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  padding-bottom: ${spacing(0.75)}px;
`;

export default React.memo(props => {
  return (
    <BaseLayout
      contentStyles={layoutContentStyles}
      toolbarComponent={
        <RequestsPagination
          isDisabled={props.isLoading}
          customContentComponent={
            <LocalSearchWrapper>
              <LocalSearch />
            </LocalSearchWrapper>
          }
        />
      }
    >
      {!props.isLoading && (
        <>
          <ListWrapper {...props}>
            <PostList>
              <ListItems {...props} />
            </PostList>
          </ListWrapper>
          <RequestsPagination hideIfNoResult isDisabled={props.isLoading} />
        </>
      )}
    </BaseLayout>
  );
});
