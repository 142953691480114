import React, { useState } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import Button from 'design-system/atoms/Button/index';
import InputTextField from 'design-system/atoms/InputTextField/index';

import { materialColors } from 'design-system/themes/default';
import { spacing } from 'design-system/theme';
import InputWithFixedText from 'src/design-system/atoms/InputWithFixedText';

const SignupFormComponent = ({ handleSubmitProp, roles, signUpServerError }) => {
  const [workspace, setWorkspace] = useState(queryString.parse(window.location.search).workspace || '');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [functionRole, setFunctionRole] = useState('');
  const [errors, setErrors] = useState({
    workspace: '',
    name: '',
    email: '',
    function_role: '',
  });
  const [triedToSubmit, setTriedToSubmit] = useState(false);

  const handleWorkspaceValue = value => {
    if (value.length === 0) {
      errors.workspace = '*Required';
    } else if (!/^[a-zA-Z0-9-]+$/.test(value)) {
      errors.workspace = '*Only alphanumeric characters and - are allowed in the workspace';
    } else {
      errors.workspace = '';
    }
    setWorkspace(value);
  };

  const handleNameValue = value => {
    if (value.length === 0) {
      errors.name = '*Required';
    } else {
      errors.name = '';
    }
    setName(value);
  };

  const handleEmailValue = value => {
    if (value.length === 0) {
      errors.email = '*Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value)) {
      errors.email = '*Invalid email address';
    } else {
      errors.email = '';
    }
    setEmail(value);
  };

  const handleFunctionRole = value => {
    setFunctionRole(value);
  };

  const validator = values => {
    const errors = {};

    if (!values.workspace) {
      errors.workspace = '*Required';
    } else if (!/^[a-zA-Z0-9-]+$/.test(values.workspace)) {
      errors.workspace = '*Only alphanumeric characters and - are allowed in the workspace';
    }
    if (!values.name) {
      errors.name = '*Required';
    }
    if (!values.email) {
      errors.email = '*Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(values.email)) {
      errors.email = '*Invalid email address';
    }

    return errors;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setTriedToSubmit(true);
    const userSignUpObj = {
      workspace,
      name,
      company_name: workspace,
      email,
      function_role: functionRole,
    };
    const myErrors = validator(userSignUpObj);

    setErrors(myErrors);

    if (isEmpty(myErrors)) {
      handleSubmitProp(userSignUpObj);
    }
  };

  return (
    <Wrapper>
      <Grid container direction="column" alignItems="center" spacing={0}>
        <Grid item xs={12}>
          <StyledTitle>Create a Workspace</StyledTitle>
        </Grid>
        <Grid item xs={12}>
          <StyledSubTitle>
            Join thousands of teams that use dragonboat to <br /> create better products and accelerate outcomes
          </StyledSubTitle>
        </Grid>
        <Grid item xs={12} container spacing={16}>
          <Grid item xs={12}>
            <InputWithFixedText
              value={workspace}
              placeholder="workspace-name"
              fixedText=".dragonboat.io"
              error={
                triedToSubmit &&
                ((errors.workspace && errors.workspace.length > 0) ||
                  (signUpServerError && signUpServerError._data && signUpServerError._data.field === 'workspace'))
              }
              handleValue={handleWorkspaceValue}
            />
            {triedToSubmit && errors.workspace && errors.workspace.length > 0 && (
              <StyledError>
                <p>{errors.workspace}</p>
              </StyledError>
            )}
            {triedToSubmit && signUpServerError && signUpServerError._data && signUpServerError._data.field === 'workspace' && (
              <StyledError>
                <p>{signUpServerError.message}</p>
              </StyledError>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputTextField
              multiline
              variant="outlined"
              label="Name"
              placeholder="Name"
              type="text"
              value={name}
              handleValue={handleNameValue}
              error={!!(triedToSubmit && errors.name && errors.name.length > 0)}
            />
            {triedToSubmit && errors.name && errors.name.length > 0 && (
              <StyledError>
                <p>{errors.name}</p>
              </StyledError>
            )}
          </Grid>
          <Grid item xs={12}>
            <InputTextField
              multiline
              label="Work email"
              placeholder="Work email"
              type="email"
              value={email}
              handleValue={handleEmailValue}
              error={
                triedToSubmit &&
                (!!(errors.email && errors.email.length > 0) ||
                  (signUpServerError && signUpServerError._data && signUpServerError._data.field === 'email'))
              }
            />
            {triedToSubmit && errors.email && errors.email.length > 0 && (
              <StyledError>
                <p>{errors.email}</p>
              </StyledError>
            )}

            {triedToSubmit && signUpServerError && signUpServerError._data && signUpServerError._data.field === 'email' && (
              <StyledError>
                <p>{signUpServerError.message}</p>
              </StyledError>
            )}
          </Grid>
          <Grid item xs={12}>
            <StyledFormControl
              variant="outlined"
              error={!!(triedToSubmit && errors.function_role && errors.function_role.length > 0)}
            >
              <InputLabel>Your role</InputLabel>
              <Select
                input={<OutlinedInput labelWidth={67} name="Your role" id="outlined-age-simple" />}
                value={functionRole}
                onChange={event => handleFunctionRole(event.target.value)}
                autoWidth
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  transformOrigin: { vertical: 'top', horizontal: 'left' },
                  MenuListProps: { style: { width: 335 } },
                }}
              >
                {roles &&
                  roles.map(role => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.title}
                    </MenuItem>
                  ))}
              </Select>
            </StyledFormControl>
            {triedToSubmit && errors.function_role && errors.function_role.length > 0 && (
              <StyledError>
                <p>{errors.function_role}</p>
              </StyledError>
            )}
          </Grid>
          <Grid item xs={12}>
            <StyledStartButton background={materialColors.mediumPurple} variant="contained" onClick={handleSubmit}>
              Start free Trial
            </StyledStartButton>
          </Grid>
          <StyledFooter item xs={12} container spacing={8}>
            <Grid item xs={12}>
              <StyledFooterSpan>
                By continuing, you’re agreeing to our <br />
                <a href="https://dragonboat.io/privacy-policy/" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>{' '}
                and{' '}
                <a href="https://dragonboat.io/terms-of-services/" target="_blank" rel="noreferrer">
                  Terms of Services
                </a>
              </StyledFooterSpan>
            </Grid>
          </StyledFooter>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default SignupFormComponent;

const StyledFormControl = styled(FormControl)`
  &&&& {
    width: 100%;
    border-radius: 2px;

    > div {
      background-color: white;
    }
  }
`;

const StyledError = styled.div`
  width: 360px;
  p {
    margin: ${spacing(1)}px ${spacing(1)}px;
    color: #f44336;
    font-size: 0.75rem;
    line-height: 1em;
    min-height: 1em;
    text-align: left;
  }
`;

const Wrapper = styled.div`
  max-width: 360px;
  @media screen and (min-height: 700px) {
    margin-top: -5rem;
  }
`;

const StyledTitle = styled.h1`
  color: #3461ac;
  font-size: 2.25rem; // -> 36px -> with :root font-size of 16px;
  font-weight: bold;
  margin: 0 0 1.813rem 0;
`;

const StyledSubTitle = styled.h5`
  color: #131c23;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 400;
  margin: 0 0 1.813rem 0;
  text-align: center;
  white-space: nowrap;
`;

const StyledStartButton = styled(Button)`
  &&&& {
    color: #ffffff;
    width: 100%;
  }
`;

const StyledFooter = styled(Grid)`
  &&&& {
    margin-top: 0.438rem;
    text-align: center;
  }
`;

const StyledFooterSpan = styled.span`
  &&&& {
    color: #777777;
    font-weight: 400;
    font-size: 0.875rem;

    a {
      color: #3c4348;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
