import { isLoading, getData, getError, isUninitialized } from 'utils/store/thunk';
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

import {
  GET_PROJECT_FIELDS_CONFIGURATION,
  UPDATE_PROJECT_FIELDS_CONFIGURATION,
  GET_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION,
  UPDATE_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION,
} from './types';

// Operations
const getConfigurationOperations = state => {
  return state.configuration.operations;
};

// project Fields Configuration operations
const isProjectFieldsConfigurationLoading = createSelector(getConfigurationOperations, state =>
  isLoading(state, GET_PROJECT_FIELDS_CONFIGURATION),
);

const getProjectFieldsConfigurationData = createSelector(getConfigurationOperations, state =>
  getData(state, GET_PROJECT_FIELDS_CONFIGURATION),
);

const getProjectFieldsConfigurationError = createSelector(getConfigurationOperations, state =>
  getError(state, GET_PROJECT_FIELDS_CONFIGURATION),
);

const updateProjectFieldsConfigurationError = createSelector(getConfigurationOperations, state =>
  getError(state, UPDATE_PROJECT_FIELDS_CONFIGURATION),
);

const isProjectFieldsConfigurationUninitialized = createSelector(getConfigurationOperations, state =>
  isUninitialized(state, GET_PROJECT_FIELDS_CONFIGURATION),
);

// Project Fields Layout Selectors

const getProjectFieldsLayout = state => {
  return state.configuration.projectFieldsLayout;
};

const projectFieldsLayoutConfiguration = createCachedSelector(
  [getProjectFieldsLayout, (state, layer) => layer],
  (state, layer) => {
    return state[layer];
  },
)((state, layer) => `projectFieldsLayoutConfiguration-${layer}`);

// Project Fields Inherit Selectors

const getProjectFieldsInherit = state => {
  return state.configuration.projectFieldsInherit;
};

const projectFieldsInheritConfiguration = createCachedSelector(
  [getProjectFieldsInherit, (state, layer) => layer],
  (state, layer) => {
    return state[layer];
  },
)((state, layer) => `projectFieldsInheritConfiguration-${layer}`);

// Project Fields Required Selectors

const getProjectFieldsRequired = state => {
  return state.configuration.projectFieldsRequired;
};

const projectFieldsRequiredConfiguration = createCachedSelector(
  [getProjectFieldsRequired, (state, layer) => layer],
  (state, layer) => {
    return state[layer];
  },
)((state, layer) => `projectFieldsRequiredConfiguration-${layer}`);

// Customer Request status mapping operations
const isCustomRequestStatusMappingLoading = createSelector(
  getConfigurationOperations,
  state =>
    isLoading(state, GET_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION) ||
    isLoading(state, UPDATE_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION),
);

const getCustomRequestStatusMappingData = createSelector(getConfigurationOperations, state =>
  getData(state, GET_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION),
);

const getCustomRequestStatusMappingError = createSelector(getConfigurationOperations, state =>
  getError(state, GET_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION),
);

const updateCustomRequestStatusMappingError = createSelector(getConfigurationOperations, state =>
  getError(state, UPDATE_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION),
);

const isCustomRequestStatusMappingUninitialized = createSelector(getConfigurationOperations, state =>
  isUninitialized(state, GET_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION),
);

// Customer Request status mapping Selectors

const customerRequestStatusMapping = state => {
  return state.configuration.customerRequestStatusMapping;
};

export {
  isProjectFieldsConfigurationLoading,
  isProjectFieldsConfigurationUninitialized,
  getProjectFieldsConfigurationData,
  getProjectFieldsConfigurationError,
  updateProjectFieldsConfigurationError,
  // Project Fields Layout
  projectFieldsLayoutConfiguration,
  // Project Fields Inherit
  projectFieldsInheritConfiguration,
  // Project Fields Required
  projectFieldsRequiredConfiguration,
  // Customer Request Status Mapping
  isCustomRequestStatusMappingLoading,
  getCustomRequestStatusMappingData,
  getCustomRequestStatusMappingError,
  updateCustomRequestStatusMappingError,
  isCustomRequestStatusMappingUninitialized,
  customerRequestStatusMapping,
};
