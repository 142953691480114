import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('CREATE_DISCOVERY_BOT_THREAD'),
  ...getThunkActionTypes('PROCESS_USER_CHAT_MESSAGE'),

  LOAD_DISCOVERY_BOT_HISTORY_CACHE: 'LOAD_DISCOVERY_BOT_HISTORY_CACHE',
  SET_DISCOVERY_BOT_HISTORY_CACHE_EXPIRY: 'SET_DISCOVERY_BOT_HISTORY_CACHE_EXPIRY',
};

export const {
  CREATE_DISCOVERY_BOT_THREAD,
  CREATE_DISCOVERY_BOT_THREAD_PENDING,
  CREATE_DISCOVERY_BOT_THREAD_FULFILLED,
  CREATE_DISCOVERY_BOT_THREAD_REJECTED,
  PROCESS_USER_CHAT_MESSAGE,
  PROCESS_USER_CHAT_MESSAGE_PENDING,
  PROCESS_USER_CHAT_MESSAGE_FULFILLED,
  PROCESS_USER_CHAT_MESSAGE_REJECTED,
  LOAD_DISCOVERY_BOT_HISTORY_CACHE,
  SET_DISCOVERY_BOT_HISTORY_CACHE_EXPIRY,
} = actionTypes;

export default actionTypes;
