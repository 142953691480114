import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/LinkOff';
import FormControl from '@material-ui/core/FormControl';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Tooltip from '@material-ui/core/Tooltip';

import DragonTable from 'design-system/organisms/DragonTable/index';
import FormLabel from 'design-system/atoms/FormLabel/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import ProjectsDropdown from 'containers/ProjectsDropdown';
import useOrganizations from 'hooks/useOrganizations';
import { planningStageColors } from 'utils/index';

const Table = styled.div`
  &&&& {
    margin: 20px 0;
    padding: 0 8px;
    // table {
    //   // display: none !important;
    //   min-width: 0px;
    // }

    // > div:nth-child(1) {
    //   height: 0;
    //   min-height: 0;
    //   margin-top: 5px;
    //   padding-left: 5px;
    // }

    h5 {
      color: #111;
      font-size: 1.1rem;
    }
  }
`;

const TitleEditorComponent = ({ onSave }) => {
  const {
    filters: { topLayer, availableLayers },
  } = useOrganizations();

  const searchLayers = useMemo(() => Object.values(availableLayers), [availableLayers]);

  const handleProjectSelection = useCallback(
    (project, { closeTree }) => {
      onSave({ entity: project });

      closeTree();
    },
    [onSave],
  );

  return (
    <FormControl fullWidth>
      <ProjectsDropdown
        componentId="requests-drawer-ideas-panel"
        displayLayer={topLayer}
        searchLayers={searchLayers}
        onProjectSelect={handleProjectSelection}
        smallInput
      />
    </FormControl>
  );
};

// Gets the phase color from the planningStageColors
const getPhaseColor = phase => {
  return phase && planningStageColors[phase.planning_stage];
};

export default props => {
  const isLoadingChildren = false;
  const { canAutomaticUpdate, hasAutomaticUpdateConfigured, getSystemFieldName } = props;

  const _updateTitle = (_, value) => {
    if (!value.new && value.entity) {
      return props.addIdea(value.entity.id);
    }
  };

  const _removeIdea = row => {
    return props.removeIdea(row.id);
  };
  const rowCount = (props.ideas || []).length;

  const renderNewRowButton = addNew => (
    <>
      <Tooltip title="Promote to Portfolio roadmap" placement="top-start">
        <Button color="primary" onClick={props.promoteToProject} size="small" disabled={props.disableEdit}>
          Promote
        </Button>
      </Tooltip>
      <Tooltip title="Link to one or more Portfolio Items" placement="top-start">
        <Button color="primary" onClick={addNew} size="small" disabled={props.disableEdit}>
          Link
        </Button>
      </Tooltip>
    </>
  );

  const _handleClickOpenProject = row => {
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set('openIdea', row.id);

    window.open(`${window.location.pathname}?${searchParams.toString()}`, '_blank');
  };

  return (
    <>
      <Table style={{ height: rowCount > 0 ? '250px' : '150px' }}>
        <DragonTable
          title={<FormLabel>Portfolio Items</FormLabel>}
          newRowButtonTitle={`+ Create ${getSystemFieldName('idea')} or Link Portfolio Items`}
          renderNewRowButton={renderNewRowButton}
          allowNewRow={props.canLinkProjects}
          rows={props.ideas || []}
          rowCount={rowCount}
          hideTableIfEmpty
          loading={isLoadingChildren}
        >
          <DragonTable.TextColumn
            editorComponent={TitleEditorComponent}
            main
            header="Title"
            map={row => row.title}
            onChange={_updateTitle}
            width={150}
            flexGrow={1.0}
            buttons={[...(props.canViewProjects ? [{ icon: OpenInNewIcon, onClick: _handleClickOpenProject }] : [])]}
          />
          <DragonTable.TextColumn
            editable={false}
            header={getSystemFieldName('phase')}
            map={row => row?.phase?.title}
            width={100}
            color={row => getPhaseColor(row?.phase)}
          />
          {props.canLinkProjects && (
            <DragonTable.ButtonColumn map={row => row} onClickButton={_removeIdea} icon={RemoveIcon} width={32} />
          )}
        </DragonTable>
      </Table>
      {props.ideas?.length > 0 && hasAutomaticUpdateConfigured && !canAutomaticUpdate && (
        <StyledText>Request status will not be automatically updated with more than 1 linked items</StyledText>
      )}
    </>
  );
};

const StyledText = styled(TextDeprecated)`
  &&&& {
    padding: 0 8px;
    margin-bottom: 20px;
  }
`;
