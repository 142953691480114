import moment from 'moment-timezone';
import getDateFormat from './getDateFormat';

export default (value, useLocal = false, descriptive = false) => {
  if (value && moment(value).isValid()) {
    return moment.tz(value, useLocal ? moment.tz.guess() : 'UTC').format(getDateFormat(descriptive));
  }

  return '';
};
