import React from 'react';
import styled from 'styled-components';
import MuiListItemText from '@material-ui/core/ListItemText';

const SavedFiltersRow = ({
  handleClick,
  text = 'Saved filters',
}) => {
  return (
    <Row onClick={handleClick}>
      <ListItemText uppercase small>
        {text}
      </ListItemText>
    </Row>
  );
};

export default SavedFiltersRow;

const ListItemText = styled(MuiListItemText)`
  &&&& {
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;

    ${({ uppercase }) =>
    uppercase &&
    `
      text-transform: uppercase;
    `}

    span {
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      font-size: ${props => props.theme.typography.fontSize}px;
      line-height: ${props => props.theme.typography.lineHeightRegular}px;
      color: ${props => props.theme.palette.text.lightGrey};
    }
  }
`;


const Row = styled.div`
  &&&& {
    display: flex;
    align-items: center;

    width: 100%;
    min-width: 200px;
    height: 100%;

    box-sizing: border-box;

    padding: 10px 16px;

    cursor: pointer;

    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    border-top: 1px solid #fafafa;
    box-shadow: 0px -2px 12px 0px rgb(204 204 204 / 30%);

    ${ListItemText} {
      span {
        color: #2ea8e1;
      }
    }
  }
`;