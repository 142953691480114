import { LexoRank } from 'lexorank';
import LexorankLegacy from 'utils/lexorank';

import head from 'lodash/head';

import sortByRank from 'utils/sortByRank';
import { getNewLexorank } from 'utils/lexorankUtils';

import localActionTypes from './actionTypes';

const localProjectsReducer = (state, action) => {
  switch (action.type) {
    case localActionTypes.RESET_LOCAL_PROJECTS:
      return {
        ...action.payload,
      };
    case localActionTypes.ADD_LOCAL_PROJECT: {
      const newProject = action.payload;

      if (newProject?.layer) {
        let newRank;

        const highestRankProject = head([...state[newProject.layer]].sort(sortByRank));

        if (highestRankProject && highestRankProject.rank) {
          const highestRank = highestRankProject.rank;

          const hasLexorank = state?.organization?.organization?.has_lexorank;

          if (hasLexorank) {
            const prevLex = highestRank ? LexoRank.parse(highestRank) : null;

            newRank = getNewLexorank(prevLex, null);
          } else {
            const lexorank = new LexorankLegacy();

            [newRank] = lexorank.insert(highestRank, 'z');
          }
        } else {
          newRank = getNewLexorank(null, null);
        }

        const enhancedNewProject = {
          estimated_start_date: null,
          deadline: null,
          custom_fields: {},
          ...newProject,
          rank: newRank,
        };

        return {
          ...state,
          [newProject.layer]: [...state[newProject.layer], enhancedNewProject],
        };
      }

      return state;
    }
    case localActionTypes.UPDATE_LOCAL_PROJECT: {
      const updatedProject = action.payload;

      if (updatedProject?.layer) {
        const layerProjects = state[updatedProject.layer];

        const projectIndex = layerProjects.findIndex(p => p.id === updatedProject.id);
        const project = layerProjects[projectIndex];

        const updatedProjects = layerProjects.filter(p => p.id !== updatedProject.id);

        updatedProjects.splice(projectIndex, 0, { ...project, ...updatedProject });

        return { ...state, [updatedProject.layer]: [...updatedProjects] };
      }

      return state;
    }
    case localActionTypes.UPDATE_ROW_ORDER_LOCAL_PROJECT: {
      const { sourceProject, prev, next } = action.payload;

      if (sourceProject?.layer) {
        const layerProjects = state[sourceProject.layer];

        const projectIndex = layerProjects.findIndex(p => p.id === sourceProject.id);
        const updatedProjects = layerProjects.filter(p => p.id !== sourceProject.id);

        const rankField = 'rank';

        let newRank = null;

        try {
          LexoRank.parse(sourceProject[rankField]);

          const prevLex = prev?.[rankField] ? LexoRank.parse(prev[rankField]) : null;
          const nextLex = next?.[rankField] ? LexoRank.parse(next[rankField]) : null;

          newRank = getNewLexorank(prevLex, nextLex);
        } catch (err) {
          const lexorank = new LexorankLegacy();

          [newRank] = lexorank.insert(prev?.[rankField], next?.[rankField]);
        }

        updatedProjects.splice(projectIndex, 0, {
          ...sourceProject,
          rank: newRank,
        });

        return { ...state, [sourceProject.layer]: [...updatedProjects] };
      }

      return state;
    }
    default:
      throw new Error('This is an unknow action type for the useProjectsLocalState reducer');
  }
};

export default localProjectsReducer;
