import React, { useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'ramda';

import UserViewsList from 'containers/UserViewsList';
import Tabs from 'design-system/organisms/Tabs/index';
import { spacing } from 'design-system/theme';

import useViewsTabs, { ALL_VIEWS, FAVORITE_VIEWS } from 'hooks/userViews/useViewsTabs';
import useUserViewsList from 'hooks/userViews/useUserViewsList';
import { VIEW_ITEM_VARIANTS } from 'constants/userViews';
import { FAVORITE_VIEWS_EMPTY_TEXT, FAVORITE_VIEWS_EMPTY_BUTTON_TEXT } from './consts';

const tabStyles = css`
  font-size: ${({ theme }) => theme.typography.fontSizeMediumRem}rem;
`;

export default ({ searchQuery, visibleTabs, applyFilters }) => {
  const { selectedTab, handleTabChange, viewsForSelectedTab, tabs } = useViewsTabs(false, null, visibleTabs);

  const filteredViews = useMemo(
    () => (applyFilters ? applyFilters(viewsForSelectedTab) : viewsForSelectedTab),
    [viewsForSelectedTab, applyFilters],
  );

  const userViewsListProps = useUserViewsList(filteredViews, !isEmpty(searchQuery));

  const isShowingAllViews = !visibleTabs || visibleTabs.includes(ALL_VIEWS);
  const isFavoriteViewsTab = selectedTab === FAVORITE_VIEWS;

  const handleFavoriteViewsEmptyButtonClick = () => handleTabChange(null, ALL_VIEWS);

  const viewsListProps = useMemo(
    () => ({
      ...userViewsListProps,
      ...(isFavoriteViewsTab
        ? {
            emptyText: FAVORITE_VIEWS_EMPTY_TEXT,
            emptyButtonText: FAVORITE_VIEWS_EMPTY_BUTTON_TEXT,
            onClickEmptyButton: handleFavoriteViewsEmptyButtonClick,
          }
        : {}),
    }),
    [selectedTab, userViewsListProps],
  );

  useEffect(() => {
    if (searchQuery && isShowingAllViews && selectedTab !== ALL_VIEWS) {
      handleTabChange(null, ALL_VIEWS);
    }
  }, [searchQuery]);

  return (
    <Wrapper>
      <StyledTabs tabs={tabs} selectedTab={selectedTab} handleTabChange={handleTabChange} tabStyles={tabStyles} />
      <ViewsContainer>
        <UserViewsList {...viewsListProps} variant={VIEW_ITEM_VARIANTS.homepage} />
      </ViewsContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const StyledTabs = styled(Tabs)`
  margin-left: ${spacing(3)}px;
`;

const ViewsContainer = styled.div`
  overflow-y: auto;
  padding: ${spacing(1.5)}px ${spacing()}px;
`;
