import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { getUserName } from 'utils/index';
import { INTEGRATIONS_KEYS } from 'constants/integrations';
import { DEFAULT_VALUE } from '../helpers/constants';

const useMultiIntegrationsList = (integrationType, organizationIntegrations, updateConfigDetails) => {
  const dispatch = useDispatch();

  /**
   * @function baseUrlByIntegrationTypeHandler
   *
   * Gets the base URL for a given integration based on the integrationType.
   * Its required to have an handler of each integration because the data field
   * is a JSON and the content can be dynamic.
   *
   * @param {Object} integration
   * @returns {String} the integration base URL
   */
  const baseUrlByIntegrationTypeHandler = integration => {
    switch (integration.integrationType) {
      case INTEGRATIONS_KEYS.azuredevops:
        return integration.data?.uri || null;
      case INTEGRATIONS_KEYS.JIRA:
        return integration.data?.base_url || null;
      case INTEGRATIONS_KEYS.rally:
        return `${integration.data?.uri}/#/detail/workspace/${integration.data.workspaceId}` || null;
      default:
        return null;
    }
  };

  const labelByIntegrationTypeHandler = integration => {
    switch (integration.integrationType) {
      default:
        return null;
    }
  };

  const rows = useMemo(() => {
    return organizationIntegrations.map(integration => ({
      ...integration,
      code: integration.code || DEFAULT_VALUE,
      updatedAt: integration.updated_at,
      updatedBy: integration.updatedBy ? getUserName(integration.updatedBy) : DEFAULT_VALUE,
      baseUrl: baseUrlByIntegrationTypeHandler(integration),
      label: labelByIntegrationTypeHandler(integration),
    }));
  }, [integrationType, organizationIntegrations]);

  const openRowBaseUrlById = row => {
    window.open(row?.baseUrl, '_blank');
  };

  const handleUpdateConfigDetails = (id, field) => {
    dispatch(updateConfigDetails(id, field));
  };

  return { rows, openRowBaseUrlById, handleUpdateConfigDetails };
};

export default useMultiIntegrationsList;
