import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { defaultTo, isEmpty, not, pipe, prop } from 'ramda';

import MultiSelect from 'design-system/atoms/MultiSelect/index';


import { getJiraProjects as getJiraProjectsAction } from 'store/integrations';

const defaultToEmptyArray = defaultTo([]);
const defaultToEmptyObject = defaultTo({});

const getData = pipe(defaultToEmptyObject, prop('data'), defaultToEmptyArray);

const transformToMultiSelectOption = ({ key, name }) => ({ label: name, value: key });

const JiraProjectsDropdown = props => {
  const { disabled, isSelected, orgIntegrationId, forwardedRef, value, onChange } = props;

  const dispatch = useDispatch();

  const [selectedProjects, setSelectedProjects] = useState(value);
  const [jiraProjects, setJiraProjects] = useState([]);
  const [isLoadingJiraProjects, setIsLoadingJiraProjects] = useState(false);

  useImperativeHandle(forwardedRef, () => ({
    resetSelectedProjects() {
      setSelectedProjects(value);
    },
  }));

  const fetchJiraProjects = useCallback(async () => {
    setIsLoadingJiraProjects(true);

    const result = await dispatch(getJiraProjectsAction(orgIntegrationId));

    const projects = getData(result).map(transformToMultiSelectOption);

    setJiraProjects(projects);
    setIsLoadingJiraProjects(false);
  }, [orgIntegrationId, dispatch]);

  const handleOnChange = useCallback(() => {
    return onChange(selectedProjects);
  }, [onChange, selectedProjects]);

  useEffect(() => {
    if (isEmpty(jiraProjects) && !disabled) {
      fetchJiraProjects();
    }
  }, [disabled, jiraProjects]);

  useEffect(() => {
    if (not(isSelected)) {
      setSelectedProjects([]);
    }
  }, [isSelected, setSelectedProjects]);

  return (
    <MultiSelect
      placeholder=""
      isDisabled={disabled}
      isLoading={isLoadingJiraProjects}
      options={jiraProjects}
      value={selectedProjects}
      onChange={setSelectedProjects}
      onBlur={handleOnChange}
    />
  );
};

JiraProjectsDropdown.propTypes = {
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  orgIntegrationId: PropTypes.number.isRequired,
  forwardedRef: PropTypes.shape({ current: PropTypes.any }),
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default JiraProjectsDropdown;
