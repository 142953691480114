import axios from 'axios';
import React, { useEffect, useState, useCallback } from 'react';
import { append } from 'ramda';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { getCurrentUser } from 'store/login/selectors';
import TokenRow from './tokenRow.js';
import { useSelector } from 'react-redux';

function useAsync(asyncFn, onSuccess) {
  useEffect(() => {
    let isActive = true;

    asyncFn().then(data => {
      if (isActive) onSuccess(data);
    });
    return () => {
      isActive = false;
    };
  }, [asyncFn, onSuccess]);
}

function fetchApiTokens() {
  return new Promise((resolve, reject) =>
    axios
      .get('/api/api-tokens')
      .then(tokens => resolve(tokens?.data))
      .catch(reject),
  );
}

function ApiTokens(props) {
  const [currentTokens, setTokens] = useState([]);
  const [currentLabel, setLabel] = useState('');
  const currentUser = useSelector(getCurrentUser);

  useAsync(fetchApiTokens, setTokens);

  const captureLabel = useCallback(e => {
    setLabel(e.target.value);
  }, []);

  const generateNewToken = e => {
    e.preventDefault();

    return axios.post('/api/api-tokens', { label: currentLabel }).then(response => {
      const { apiKey, jwtToken } = response.data;

      apiKey.jwtToken = jwtToken;
      apiKey.owner = currentUser;
      const tokens = append(apiKey, currentTokens);

      setTokens(tokens);
      setLabel('');
    });
  };

  return (
    <>
      <div style={{ marginBottom: 40 }}>
        {currentTokens.map(each => (
          <TokenRow owner={each.owner} jwtToken={each.jwtToken} id={each.id} label={each.label} created_at={each.created_at} />
        ))}
        <Grid container style={{ marginBottom: '12px' }}>
          <AddNewTokenRow item xs={12}>
            <TokenLabelField onChange={captureLabel} placeholder="New API key label" value={currentLabel} />
            <AddNewLinkButton onClick={generateNewToken} data-testid="add-link-button">
              + Add new API key
            </AddNewLinkButton>
          </AddNewTokenRow>
        </Grid>
      </div>
    </>
  );
}

const AddNewTokenRow = styled(Grid)`
  &&&& {
    display: grid;
    grid-template-columns: 1fr auto;
  }
`;

const AddNewLinkButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.newLayout.text.blue};
    margin-left: 20px;
  }
`;

const TokenLabelField = styled(TextField)`
  &&&& {
    :first-child {
      margin-right: 15px;
    }

    width: 100%;
  }
`;

export default ApiTokens;
