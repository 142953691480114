import React, { useRef } from 'react';
import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import ColorToolbar from 'components/ColorToolbar';
import GeneralTable from './components/GeneralTable';

import { spacing } from 'design-system/theme';
import useColorLegend from 'hooks/timeline/useColorLegend';

import useSnapshotTableComponent from './hooks/useSnapshotTableComponent';
import { ROW_HEIGHT_DEFAULT } from './helpers/constants';
import OKRTableOnSnapshot from './components/OKRTableOnSnapshot';

export default props => {
  const {
    rows,
    title,
    selectedSnapshotField,
    showSnapshotTreeView,
    openMetricView,
    onUpdateMetadataFieldById,
    displayLayer,
    shouldShowColorLegend,
    snapshotTableVisibleFields,
    rowHeight = ROW_HEIGHT_DEFAULT,
    pageSize,
    onUpdatePageSize,
    expandTableItems,
    hasAdvancedMetricReporting,
    hasKeyResults,
    hasKeyResults2,
    isGoalMode = false,
    withPagination = true,
    overrideTableHeight,
    saveGridState,
    gridState,
    createMetricOption,
    gridId,
    searchString,
  } = props;
  const gridApi = useRef();

  const { displayOkr, tableHeight } = useSnapshotTableComponent(
    selectedSnapshotField,
    rows,
    showSnapshotTreeView,
    pageSize,
    rowHeight,
  );

  const [getLegendGroups] = useColorLegend();

  const _changeRowsPerPage = value => {
    onUpdatePageSize(value);
    gridApi.current.paginationSetPageSize(Number(value));
  };

  const onGridReady = params => {
    gridApi.current = params.api;
  };

  return (
    <Wrapper>
      {shouldShowColorLegend && <ColorLegend groups={getLegendGroups({ key: 'phase' })} showPicker={false} />}
      {displayOkr && (
        <OKRTableOnSnapshot
          rows={rows}
          title={title}
          selectedSnapshotField={selectedSnapshotField}
          showSnapshotTreeView={showSnapshotTreeView}
          openMetricView={openMetricView}
          onUpdateMetadataFieldById={onUpdateMetadataFieldById}
          displayLayer={displayLayer}
          snapshotTableVisibleFields={snapshotTableVisibleFields}
          rowHeight={rowHeight}
          pageSize={pageSize}
          expandTableItems={expandTableItems}
          hasAdvancedMetricReporting={hasAdvancedMetricReporting}
          hasKeyResults={hasKeyResults}
          hasKeyResults2={hasKeyResults2}
          isGoalMode={isGoalMode}
          withPagination={withPagination}
          overrideTableHeight={overrideTableHeight}
          saveGridState={saveGridState}
          gridState={gridState}
          createMetricOption={createMetricOption}
          gridId={gridId}
          tableHeight={tableHeight}
          onGridReady={onGridReady}
          searchString={searchString}
        />
      )}
      {!displayOkr && (
        <GeneralTable
          rowData={rows}
          tableHeight={tableHeight}
          rowHeight={rowHeight}
          withPagination={withPagination}
          pageSize={pageSize}
          title={title}
          displayLayer={displayLayer}
          onUpdateMetadataFieldById={onUpdateMetadataFieldById}
          onGridReady={onGridReady}
        />
      )}
      {withPagination && (
        <PageSizeDiv>
          <TextDeprecated size="small">Page Size</TextDeprecated>
          <FormControl width="100">
            <Select value={pageSize} onChange={e => _changeRowsPerPage(e.target.value)}>
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="50">50</MenuItem>
            </Select>
          </FormControl>
        </PageSizeDiv>
      )}
    </Wrapper>
  );
};

const ColorLegend = styled(props => (
  <div {...props}>
    <ColorToolbar {...props} />
  </div>
))`
  &&&& {
    padding: 10px 40px;
  }
`;

const Wrapper = styled.div`
  position: relative;

  .ag-grid-30 .ag-theme-material .ag-paging-panel > * {
    margin: 0 ${spacing()}px 0 ${spacing(3)}px;
  }

  .ag-theme-material .ag-paging-panel {
    padding: 0;
  }
`;

const PageSizeDiv = styled.div`
  position: absolute;
  width: 200px;
  left: ${spacing()}px;
  bottom: 12px;
  display: flex;
  align-items: center;

  div[role='button'] {
    font-size: 12px;
  }

  p {
    margin-right: 10px;
  }
`;
