import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export default styled(props => (
  <Typography variant="caption" {...props}>
    {props.label || props.children}
  </Typography>
))`
  &&&& {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.newLayout.text.label};
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    font-size: ${({ theme }) => theme.typography.fontSize}px;
    line-height: 1.5;
  }
`;
