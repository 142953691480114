import getCurrentPath from 'utils/getCurrentPath';
import history from 'store/utils/history';

const addQueryParamToUrl = scenario => {
  const path = getCurrentPath();
  const params = new URLSearchParams(window.location.search);

  params.set('scenario', scenario?.id);

  history.push(`/${path}?${params.toString()}`);
};

export default addQueryParamToUrl;
