import { INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';

/**
 * Filters a given list of projects based on groupedBy projects
 * @param {Array} projects
 * @param {Array} groupedByProjects
 * @returns a new array with the projects intersection
 */
const filterGroupedByProjects = (projects, groupedByProjects) =>
  projects.filter(project => groupedByProjects.some(groupedByProject => groupedByProject.id === project.id)) || [];

/**
 * Filters all layers based on groupBy, display layer and portfolio mode.
 *
 * @param {String} displayLayer
 * @param {Boolean} portfolioMode
 * @param {Object} groupedBy
 * @param {Array} ideas
 * @param {Array} initiatives
 * @param {Array} bets
 *
 * @returns An object with visibleIdeas, visibleInitiatives and visibleBets.
 */
export const filterGroupedByAll = (displayLayer, portfolioMode, groupedBy, ideas, initiatives, bets, hasBets = true) => {
  const visibleIdeas = filterGroupedByProjects(ideas, groupedBy);

  let visibleInitiatives = [];
  let visibleBets = [];

  if (displayLayer === INITIATIVE_LAYER) {
    visibleInitiatives = filterGroupedByProjects(initiatives, groupedBy);

    if (portfolioMode && !hasBets) {
      // If all levels is selected and the organization does not have bets we should
      // also show the children for the filtered initiatives
      const allInitiativesChildren = visibleInitiatives.reduce(
        (acc, initiative) => {
          const visibleIdeas = [...acc.visibleIdeas, ...ideas.filter(idea => idea.parent_id === initiative.id)];

          return {
            visibleIdeas,
          };
        },
        { visibleIdeas },
      );

      return { visibleInitiatives, ...allInitiativesChildren };
    }
  } else {
    visibleInitiatives = initiatives.filter(initiative => visibleIdeas.some(idea => idea.parent_id === initiative.id));
  }

  if (displayLayer === BET_LAYER) {
    visibleBets = filterGroupedByProjects(bets, groupedBy);

    if (portfolioMode) {
      // If all levels is selected we should also show the children for the filtered bets
      const allBetsChildren = visibleBets.reduce(
        (acc, bet) => {
          const visibleInitiatives = [
            ...acc.visibleInitiatives,
            ...initiatives.filter(initiative => initiative.parent_id === bet.id),
          ];

          return {
            visibleIdeas: [
              ...acc.visibleIdeas,
              ...ideas.filter(
                idea => idea.parent_id === bet.id || visibleInitiatives.some(initiative => initiative.id === idea.parent_id),
              ),
            ],
            visibleInitiatives,
          };
        },
        { visibleIdeas, visibleInitiatives },
      );

      return { visibleBets, ...allBetsChildren };
    }
  } else {
    visibleBets = bets.filter(
      bet =>
        visibleIdeas.some(idea => idea.parent_id === bet.id) ||
        visibleInitiatives.some(initiative => initiative.parent_id === bet.id),
    );
  }

  return {
    visibleIdeas,
    visibleInitiatives,
    visibleBets,
  };
};
