import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import useSystemFields from 'hooks/useSystemFields';
import { QUICK_FILTER_OPTIONS } from 'constants/filters';
import { getRoadmaps, getProducts } from 'store/roadmaps/selectors';
import { getThemes } from 'store/themes/selectors';
import { selectKeyResults1, getObjectives } from 'store/objectives/selectors';
import { getPriorities } from 'store/priorities/selectors';
import { getCategories } from 'store/categories/selectors';
import { getTimeframes } from 'store/timeframes/selectors';
import { getPhases } from 'store/phases/selectors';
import { getTags } from 'store/tags/selectors';
import { getActiveCustomers } from 'store/customers/selectors';
import { getTeams } from 'store/teams/selectors';
import { getSkills } from 'store/skills/selectors';
import { planningStages } from 'utils';
import { getPageFilters } from 'store/filters/selectors';
import { setPageFilters } from 'store/filters';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [getSystemFieldName] = useSystemFields();
    const quickFilterOptions = React.useMemo(
      () => (props.quickFilterOptions ? props.quickFilterOptions : QUICK_FILTER_OPTIONS(getSystemFieldName)),
      [props.quickFilterOptions],
    );

    const metadata = {
      roadmaps: useSelector(state => getRoadmaps(state, true)),
      themes: useSelector(state => getThemes(state, true)),
      objectives: useSelector(state => getObjectives(state, true)),
      priorities: useSelector(state => getPriorities(state, true)),
      categories: useSelector(state => getCategories(state, true)),
      timeframes: useSelector(state => getTimeframes(state, true)),
      phases: useSelector(state => getPhases(state, true)),
      tags: useSelector(state => getTags(state, true)),
      customers: useSelector(state => getActiveCustomers(state)).map(c => ({
        ...c,
        title: c.name,
      })),
      teams: useSelector(state => getTeams(state, true)),
      skills: useSelector(state => getSkills(state, true)),
      planningStages: planningStages.map(p => ({ id: p, title: p })),
      products: useSelector(state => getProducts(state, true)),
      keyResults: useSelector(state => selectKeyResults1(state, true)),
    };

    const pageFilters = useSelector(state => getPageFilters(state, props.page));

    const selectedQuickFilter = pageFilters.quickFilters
      ? quickFilterOptions.length &&
        pageFilters.quickFilters &&
        quickFilterOptions.find(o => o.id === Object.keys(pageFilters.quickFilters)[0])
      : quickFilterOptions.length && quickFilterOptions[0];
    const _applyQuickFilter = quickFilters => {
      dispatch(setPageFilters(props.page, { ...pageFilters, quickFilters }, false));
    };

    return selectedQuickFilter ? (
      <Component
        {...props}
        name={selectedQuickFilter.id}
        title={`Select ${selectedQuickFilter.label}`}
        options={metadata[selectedQuickFilter.id]}
        quickFilters={pageFilters.quickFilters}
        applyQuickFilter={_applyQuickFilter}
      />
    ) : null;
  };
};

export default componentHOC;
