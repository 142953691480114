import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('CREATE_TASK'),
  ...getThunkActionTypes('UPDATE_TASK'),
  ...getThunkActionTypes('BULK_UPDATE_TASKS'),
  ...getThunkActionTypes('DELETE_TASK'),
  ...getThunkActionTypes('FETCH_TASKS'),
  ...getThunkActionTypes('UPDATE_PROJECT_TASKS'),
  ...getThunkActionTypes('ADD_PROJECT_TASKS'),
};

export const {
  CREATE_TASK,
  CREATE_TASK_FULFILLED,
  UPDATE_TASK,
  UPDATE_TASK_PENDING,
  UPDATE_TASK_FULFILLED,
  BULK_UPDATE_TASKS,
  BULK_UPDATE_TASKS_FULFILLED,
  DELETE_TASK,
  DELETE_TASK_FULFILLED,
  FETCH_TASKS,
  FETCH_TASKS_FULFILLED,
  UPDATE_PROJECT_TASKS,
  UPDATE_PROJECT_TASKS_FULFILLED,
  ADD_PROJECT_TASKS,
  ADD_PROJECT_TASKS_FULFILLED,
} = actionTypes;
