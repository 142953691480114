export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAGS_FULFILLED = 'FETCH_TAGS_FULFILLED';

export const CREATE_TAG = 'CREATE_TAG';
export const CREATE_TAG_FULFILLED = 'CREATE_TAG_FULFILLED';

export const UPDATE_TAG = 'UPDATE_TAG';
export const UPDATE_TAG_FULFILLED = 'UPDATE_TAG_FULFILLED';


export const DELETE_TAG = 'DELETE_TAG';
export const DELETE_TAG_FULFILLED = 'DELETE_TAG_FULFILLED';

export const UPDATE_TAG_BY_ID = 'UPDATE_TAG_BY_ID';
export const UPDATE_TAG_BY_ID_FULFILLED = 'UPDATE_TAG_BY_ID_FULFILLED';

export const ADD_TAG_WITHOUT_SAVE = 'ADD_TAG_WITHOUT_SAVE';
export const REMOVE_UNSAVED_TAGS = 'REMOVE_UNSAVED_TAGS';

export const MERGE_TAGS = 'MERGE_TAGS';
export const MERGE_TAGS_FULFILLED = 'MERGE_TAGS_FULFILLED';

export const CREATE_TAGS = 'CREATE_TAGS';
export const CREATE_TAGS_FULFILLED = 'CREATE_TAGS_FULFILLED';
export const UPDATE_TAGS = 'UPDATE_TAGS';
export const UPDATE_TAGS_FULFILLED = 'UPDATE_TAGS_FULFILLED';
export const BULK_DELETE_TAGS = 'BULK_DELETE_TAGS';
export const BULK_DELETE_TAGS_FULFILLED = 'BULK_DELETE_TAGS_FULFILLED';

export const UNDO_CREATE_TAGS = 'UNDO_CREATE_TAGS';
export const UNDO_CREATE_TAGS_FULFILLED = 'UNDO_CREATE_TAGS_FULFILLED';
export const UNDO_UPDATE_TAGS = 'UNDO_UPDATE_TAGS';
export const UNDO_UPDATE_TAGS_FULFILLED = 'UNDO_UPDATE_TAGS_FULFILLED';
export const UNDO_BULK_DELETE_TAGS = 'UNDO_BULK_DELETE_TAGS';
export const UNDO_BULK_DELETE_TAGS_FULFILLED = 'UNDO_BULK_DELETE_TAGS_FULFILLED';

export const CREATE_TAG_ROADMAP = 'CREATE_TAG_ROADMAP';
export const CREATE_TAG_ROADMAP_FULFILLED = 'CREATE_TAG_ROADMAP_FULFILLED';
export const DELETE_TAG_ROADMAP = 'DELETE_TAG_ROADMAP';
export const DELETE_TAG_ROADMAP_FULFILLED = 'DELETE_TAG_ROADMAP_FULFILLED';
export const BULK_DELETE_TAG_ROADMAP = 'BULK_DELETE_TAG_ROADMAP';
export const BULK_DELETE_TAG_ROADMAP_FULFILLED = 'BULK_DELETE_TAG_ROADMAP_FULFILLED';
