import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { propOr } from 'ramda';

import { getMultiFilters, getNonDefaultMultiFiltersSaved, getMultiFilterActive } from 'store/customerRequests/selectors';
import { getCurrentUser } from 'store/login/selectors';
import {
  deleteCustomerRequestsMultiFilters,
  loadCustomerRequestsMultiFilters,
  saveCustomerRequestsMultiFilters,
  updateCustomerRequestsMultiFilters,
} from 'store/customerRequests/actions';
import usePageQueryParamFilters from 'hooks/usePageQueryParamFilters';

import useMultiFilterData from './hooks/useMultiFilterData';

import { CUSTOM_FILTERS } from 'constants/customerRequests';
import { getDefaultfilters } from './utils';
import { setCustomerRequestsMultiFilters } from 'store/customerRequests/thunks';
import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/userViews';
import { getActiveViewForPage } from 'store/userViews/selectors';
import { getUserById } from 'store/users/selectors';
import useUserFavoriteViews from 'hooks/userViews/useUserFavoriteViews';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [multiFilterItems, setMultiFilterItems] = useState({});
    const [originalMultiFilterItems, setOriginalMultiFilterItems] = useState({});
    const [multiFilterActiveName, setMultiFilterActiveName] = useState('');

    const multiFilters = useSelector(getMultiFilters);

    const multiFiltersSaved = useSelector(getNonDefaultMultiFiltersSaved);
    const multiFilterActive = useSelector(getMultiFilterActive);
    const currentUser = useSelector(getCurrentUser);

    const defaultFilters = useMemo(() => getDefaultfilters(currentUser), [currentUser]);

    const [multiFilterData] = useMultiFilterData();

    const path = getCurrentPath();
    const pageId = getPageIdFromPath(path);
    const activeView = useSelector(state => getActiveViewForPage(state, pageId));
    const viewOwner = useSelector(state => getUserById(state, activeView?.user_id));
    const { isFavoriteView, handleFavoriteUserView } = useUserFavoriteViews();

    const multiFilterActiveObj = useMemo(() => {
      if (!multiFilterActive) return undefined;

      return multiFiltersSaved[multiFilterActive] || defaultFilters.find(df => df.id === multiFilterActive);
    }, [multiFilterActive, multiFiltersSaved, defaultFilters]);

    const multiFilterList = useMemo(
      () =>
        Object.keys(multiFiltersSaved).map(key => ({
          ...multiFiltersSaved[key],
          label: multiFiltersSaved[key].name,
        })),
      [multiFiltersSaved],
    );

    const _applyFilterFromQueryParams = useCallback(
      filterId => {
        if (!filterId) return;

        const defaultFilter = defaultFilters.find(filter => filter.id === filterId);

        if (defaultFilter && multiFilterActive !== defaultFilter.id) {
          dispatch(setCustomerRequestsMultiFilters(defaultFilter.state || {}, defaultFilter.id));
        }
      },
      [defaultFilters, multiFilterActive],
    );

    const { removeFilterFromQueryParams } = usePageQueryParamFilters(_applyFilterFromQueryParams);

    const _handleApplyMultiFilter = multiFilterItems => {
      removeFilterFromQueryParams();
      dispatch(setCustomerRequestsMultiFilters(multiFilterItems));
    };

    const _handleClearAllFilters = () => {
      removeFilterFromQueryParams();
      dispatch(setCustomerRequestsMultiFilters([]));
    };

    const _handleSaveFilter = (name, multiFilterItems) => {
      const id = Object.keys(multiFiltersSaved).find(id => multiFiltersSaved[id].name === name);

      if (id) {
        dispatch(updateCustomerRequestsMultiFilters(id, multiFilterItems));
      } else {
        dispatch(saveCustomerRequestsMultiFilters(name, multiFilterItems));
        setMultiFilterActiveName(name);
      }
    };

    const _handleOnFilterListClick = savedFilterClicked => {
      removeFilterFromQueryParams();
      dispatch(setCustomerRequestsMultiFilters(savedFilterClicked.state || {}, savedFilterClicked.id));
    };

    const _handleDeleteSavedFilter = filter => {
      dispatch(deleteCustomerRequestsMultiFilters(filter.id));
    };

    const _handleRenameFilter = item => {
      dispatch(updateCustomerRequestsMultiFilters(item.id, item.state, item.label));
    };

    useEffect(() => {
      dispatch(loadCustomerRequestsMultiFilters());
    }, []);

    useEffect(() => {
      if (!multiFilterActiveObj) return setMultiFilterActiveName('');
      if (multiFilterActiveObj.name === multiFilterActiveName) return;

      setMultiFilterActiveName(multiFilterActiveObj.name);
    }, [multiFilterActiveObj]);

    useEffect(() => {
      setMultiFilterItems({});
      setOriginalMultiFilterItems({});

      const myMultiFilterItems = {};

      // Removes 'title', 'details', 'key' and 'custom_fields' from array. MultiFilter doesn't have these fields.
      const filteredMultiFilters = multiFilters.filter(item => {
        return !CUSTOM_FILTERS.includes(item.key) && !CUSTOM_FILTERS.includes(item.customKey);
      });

      const firstLvlFilters = (filteredMultiFilters || []).filter(mf => !mf.parentId);
      const secondLvlFilters = (filteredMultiFilters || []).filter(mf => mf.parentId && mf.parentKey);

      (firstLvlFilters || []).forEach(mf => {
        const values = {};

        const mfValues = propOr([], 'values')(mf);

        mfValues.forEach(val => {
          values[val] = true;
        });

        myMultiFilterItems[mf.key] = values;
      });

      (secondLvlFilters || []).forEach(mf => {
        const values = {};

        const mfValues = propOr([], 'values')(mf);

        mfValues.forEach(val => {
          values[val] = true;
        });

        myMultiFilterItems[mf.parentKey][mf.parentId] = values;
      });

      setMultiFilterItems(multiFilterItems => ({
        ...multiFilterItems,
        ...myMultiFilterItems,
      }));

      setOriginalMultiFilterItems(multiFilterItems => ({
        ...multiFilterItems,
        ...myMultiFilterItems,
      }));
    }, [multiFilters]);

    return (
      <Component
        onApplyMultiFilter={_handleApplyMultiFilter}
        multiFilterData={multiFilterData}
        multiFilterList={multiFilterList}
        originalMultiFilterItems={originalMultiFilterItems}
        multiFilterItems={multiFilterItems}
        setMultiFilterItems={setMultiFilterItems}
        clearAllFilters={_handleClearAllFilters}
        onSaveFilter={_handleSaveFilter}
        onFilterListClick={_handleOnFilterListClick}
        multiFilterActive={multiFilterActive}
        multiFilterActiveName={multiFilterActiveName}
        onDeleteFilter={_handleDeleteSavedFilter}
        onRenameFilter={_handleRenameFilter}
        currentUser={currentUser}
        defaultFilters={defaultFilters}
        selectedView={activeView || {}}
        viewOwner={viewOwner}
        isFavoriteView={isFavoriteView}
        handleFavoriteUserView={handleFavoriteUserView}
        {...props}
      />
    );
  };
};

export default componentHOC;
