import downloadPNG from 'utils/downloadPNG';

const VISIBLE_ELEMENT = 'visible';
const AUTO_SCROLL_ELEMENT = 'auto';
const HEIGHT_FULL = '100%';

export default async () => {
  // we need to set all content visible to download the image
  const onBeforeDownload = () => {
    const element = document.getElementById('allocation-page-container');
    const elementStyle = element.style;

    const chipElements = document.getElementsByClassName('microchip');

    for (const chip of chipElements) { // eslint-disable-line
      const text = chip.firstChild.innerHTML;
      const span = document.createElement('span');

      span.innerHTML = text;
      chip.firstChild.replaceWith(span);
    }

    elementStyle.overflow = VISIBLE_ELEMENT;
    elementStyle.height = HEIGHT_FULL;
  };

  // then we should reset the styles after download
  const onAfterDownload = () => {
    const element = document.getElementById('allocation-page-container');
    const elementStyle = element.style;

    elementStyle.overflow = AUTO_SCROLL_ELEMENT;
    elementStyle.height = 'calc(100vh - 68px)';
  };

  await downloadPNG(onBeforeDownload, onAfterDownload);
};
