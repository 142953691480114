const dataFormatter = (isMulti, data) =>
  isMulti
    ? data?.map(lane => ({
        ...lane,
        groups: (lane?.groups || []).map(group => ({
          ...group,
          rows: group.rows.map(row =>
            row.map(project => ({ ...project, startDate: project.startDate.format(), endDate: project.endDate.format() })),
          ),
        })),
      })) ?? []
    : data?.map(lane => ({
        ...lane,
        rows: (lane?.rows || []).map(row =>
          row.map(project => ({ ...project, startDate: project.startDate.format(), endDate: project.endDate.format() })),
        ),
      })) ?? [];

export { dataFormatter };
