import theme from 'design-system/theme';

const materialColorsSnapshot = {
  '': theme.palette.newLayout.progressBarSquared.background.gray,
  gray: theme.palette.newLayout.progressBarSquared.background.gray,
  red: theme.palette.newLayout.progressBarSquared.background.red,
  green: theme.palette.newLayout.progressBarSquared.background.green,
  yellow: theme.palette.newLayout.progressBarSquared.background.yellow,
  lightGray: theme.palette.newLayout.background.lightGray,
  darkGray: theme.palette.newLayout.background.darkGray,
  darkestGray: theme.palette.newLayout.background.darkestGray,
};

export default materialColorsSnapshot;
