import React from 'react';
import styled from 'styled-components';

import ButtonV2 from 'design-system/atoms/ButtonV2/index';

import theme from 'design-system/theme';
import UpsellPageTemplate from 'design-system/templates/UpsellPageTemplate';

import { Container, SideSection, Title, StyledText, ButtonWrapper } from './styledComponents';
import oPdlc from './oPDLC.png';
import heatmap from './heatmap.png';

const LEARN_MORE_BUTTON_ID = 'pdlc-upsell-button';

const UpsellPdlc = () => {
  return (
    <UpsellPageTemplate>
      <Container>
        <SideSection>
          <Image src={oPdlc} alt="oPDLC" />
          <Image src={heatmap} alt="pdlc heatmap" />
        </SideSection>
        <SideSection withPaddingTop>
          <Title>Enable Product Best Practices with oPDLC</Title>
          <StyledText>
            Want to onboard new PMs faster? Instantly roll out product best practices, templates and checklist? Quickly pinpoint
            and resolve portfolio bottlenecks?
          </StyledText>
          <StyledText>
            Add Dragonboat PDLC Engine to have a single source of truth - to design, roll out, and improve product and portfolio
            processes, templates, checklist, contacts and more.
          </StyledText>
          <StyledText>Run PDLC where product development and product to market happens.</StyledText>
          <ButtonWrapper>
            <ButtonV2
              id={LEARN_MORE_BUTTON_ID}
              color={theme.palette.background.white}
              background={theme.palette.background.primary}
              hover={{ background: theme.palette.background.primary }}
            >
              Learn more
            </ButtonV2>
          </ButtonWrapper>
        </SideSection>
      </Container>
    </UpsellPageTemplate>
  );
};

const Image = styled.img`
  width: 80%;
  margin-bottom: 30px;
`;

export default UpsellPdlc;
