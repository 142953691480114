import React from 'react';
import styled from 'styled-components';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';

import { spacing } from 'design-system/theme';
import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete/index';
import ToggleButton from 'design-system/molecules/ToggleButton/index';

import { DEFAULT_GROUP_OPTION } from 'store/projects/helpers/groupOptions';

import useRoadmapHistoryLeftControls from './hooks/useRoadmapHistoryLeftControls';

const SHOW_ALL_ITEMS = 'Show all items';
const HIDE_ITEMS_WITHOUT_CHANGES = 'Hide items with no change between snaps';

const DEFAULT_HIGHLIGHT_OPTION = { key: null, title: 'No highlight' };

const RoadmapHistoryLeftControls = ({
  selectedGroup1,
  groupByOptions,

  onGroupLevelChange,
}) => {
  const {
    roadmapHistoryGroupByOptions,
    showItemsWithDifferences,
    onToggleItemsWithDifferences,
    highlightByOptions,
    highlightField,
    onHighlightFieldChange,
  } = useRoadmapHistoryLeftControls({ groupByOptions });

  return (
    <>
      <GroupByAutocomplete
        name="selectedGroup1"
        suggestions={roadmapHistoryGroupByOptions}
        value={selectedGroup1 ?? DEFAULT_GROUP_OPTION}
        onChange={onGroupLevelChange('selectedGroup1')}
      />
      <Spacing />
      <GroupByAutocomplete
        name="highlightChangesColumn"
        suggestions={highlightByOptions}
        value={highlightField ?? DEFAULT_HIGHLIGHT_OPTION}
        onChange={onHighlightFieldChange}
        label="Highlight changes in"
      />
      <Spacing />
      <ToggleButton
        on={showItemsWithDifferences}
        icon={showItemsWithDifferences ? <VisibilityOffIcon /> : <VisibilityIcon />}
        title={showItemsWithDifferences ? SHOW_ALL_ITEMS : HIDE_ITEMS_WITHOUT_CHANGES}
        onChange={onToggleItemsWithDifferences}
      />
    </>
  );
};

const Spacing = styled.div`
  padding: ${spacing(0.625)}px;
`;

export default RoadmapHistoryLeftControls;
