import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/HelpOutline';
import LaunchIcon from '@material-ui/icons/Launch';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import FluidFieldLabel from 'design-system/molecules/FluidFieldLabel/index';
import FluidItem from 'design-system/atoms/FluidItem/index';
import SavingLoader from 'design-system/atoms/SavingLoader/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';


import useStateRef from 'design-system/useStateRef';
import useDeepEffect from 'design-system/useDeepEffect';
import { getFluidFieldValidation } from 'design-system/utils';
import { NONE_PLACEHOLDER } from 'constants/common';

const FluidField = props => {
  const [originalValue, setOriginalValue] = useStateRef(props.value);
  const [editValue, setEditValue] = useStateRef(props.value);
  const [isEditing, setIsEditing] = useStateRef(props.alwaysEditing);
  const [isSaving, setIsSaving] = React.useState(false);

  const {
    displayBorder,
    editingHeight,
    InputProps = {},
    validation,
    name,
    showLabel = true,
    labelProps = {},
    placeholder,
    readingPlaceholder,
  } = props;
  const { invalid, required } = getFluidFieldValidation(name, editValue, validation);

  useDeepEffect(() => {
    if ((props.disableEdit || !isEditing) && originalValue !== props.value) {
      setEditValue(props.value);
      setOriginalValue(props.value);
    }
  }, [props.value]);

  const cancel = () => {
    setIsEditing(props.alwaysEditing);
    // setEditValue(props.value);
  };

  const save = (value, cancelEdit = true) => {
    const valueToCheck = value === undefined ? editValue : value;

    if (props.onChange && valueToCheck !== props.value) {
      const response = props.onChange(valueToCheck);

      if (response instanceof Promise) {
        setIsSaving(true);
        response.finally(() => setIsSaving(false));
      }
    }

    if (cancelEdit && !props.alwaysEditing) setIsEditing(false);
    setEditValue(valueToCheck);
  };

  const _onChange = value => {
    setEditValue(value);
    if (props.onEditingValue) props.onEditingValue(value);
  };

  const placeholderToRender = isEditing && !props.disableEdit ? placeholder : readingPlaceholder;
  // eslint-disable-next-line no-nested-ternary
  const innerValue =
    (!props.disableEdit && isEditing) || !props.renderer
      ? props.editorRenderer({
          label: props.label,
          value: editValue,
          onChange: _onChange,
          isEditing,
          save,
          cancel,
          disableEdit: props.disableEdit,
          invalid,
          required,
          placeholder: placeholderToRender,
        })
      : props.renderer(editValue, { invalid, required, placeholder: placeholderToRender });

  const label = props.label && (
    <FluidFieldLabel
      disabled={props.disableEdit}
      format={props.format}
      label={props.label}
      onLabelClick={props.onLabelClick}
      labelTooltip={props.labelTooltip}
      required={required}
      invalid={invalid}
    />
  );

  // eslint-disable-next-line no-nested-ternary
  const valueOrPlaceholder = (
    <ValueOrPlaceholder
      $isEditing={isEditing}
      {...(props.disableEdit ? {} : props.editingTriggers(() => !isEditing && setIsEditing(true)))}
      hover={!props.highlightSingleField && !props.disableEdit && !isEditing}
      $displayBorder={displayBorder}
      $editingHeight={editingHeight}
      $multiline={InputProps.multiline}
      $valueOrPlaceholderStyles={props.valueOrPlaceholderStyles}
      invalid={invalid}
    >
      {!innerValue ? (
        ''
      ) : isString(innerValue) ? (
        <Typography variant="body1" style={{ lineHeight: props.format !== 'column' && '8px' }}>
          {innerValue}
        </Typography>
      ) : (
        innerValue
      )}
    </ValueOrPlaceholder>
  );

  const helpIcon = (
    <StyledIcon
      id={props.pendoId && props.pendoId}
      className="helpIcon"
      title={props.helpTitle ? props.helpTitle : 'Help'}
      href={props.helpLink ? props.helpLink : null}
      target={props.helpLink && '_blank'}
      rel="noopener noreferrer"
    >
      <HelpIcon disableShrink fontSize="small" thickness={4} />
    </StyledIcon>
  );

  const fieldNameWithHelpLinkWrapper = (
    <>
      <FieldWrapperGrid item xs={12} md={3} {...labelProps}>
        {label}
      </FieldWrapperGrid>
      <FieldWrapperGrid item xs={12} md={1}>
        {helpIcon}
      </FieldWrapperGrid>
    </>
  );

  const renderNameWrapper = showLabel && (
    <FieldWrapperGrid item xs={12} md={4} {...labelProps}>
      {label}
    </FieldWrapperGrid>
  );

  const fieldNameWrapper = (
    <>
      {props.helpLinkNextToFieldName ? (
        fieldNameWithHelpLinkWrapper
      ) : props.showTooltipOnOverflow ? (
        <TooltipOnOverflow text={label?.props?.label} vertical>
          {renderNameWrapper}
        </TooltipOnOverflow>
      ) : (
        renderNameWrapper
      )}
    </>
  );

  const components =
    props.format === 'column' ? (
      <React.Fragment>
        <FieldWrapperGrid {...labelProps}>
          {label}
          {valueOrPlaceholder}
        </FieldWrapperGrid>
      </React.Fragment>
    ) : (
      <Grid container xs={12} direction="row" alignItems={props.alignItems || 'flex-start'}>
        {fieldNameWrapper}
        <Grid item xs={12} md={8}>
          {valueOrPlaceholder}
        </Grid>
      </Grid>
    );

  return (
    <StyledFluidItem
      style={props.style || {}}
      className={props.className}
      width={props.width}
      isEditing={isEditing}
      component={props.component}
      tabIndex={isEditing ? -1 : 0}
      data-testid={props.testId}
      onFocus={e => {
        const { relatedTarget, currentTarget, target } = e;

        if (currentTarget !== target || currentTarget.contains(relatedTarget) || props.ignoreMouseEvents) {
          return;
        }

        if (!props.disableEdit) {
          const divs = currentTarget.querySelectorAll('div');

          divs.forEach(d => d.click());
        }

        const input = currentTarget.querySelector('input');

        if (input) input.focus();

        setIsEditing(true);
      }}
      onBlur={e => {
        const { relatedTarget, currentTarget } = e;

        if (props.ignoreBlurOn(e) || currentTarget.contains(relatedTarget) || props.alwaysEditing || props.ignoreMouseEvents) {
          return;
        }

        cancel();
      }}
    >
      <FluidFieldContent needsFlex={props.showLaunch || props.helpLink || props.pendoId}>
        {components}
        {isSaving && <SavingLoader className="saving-loader" />}
        <ExtraButtonsRow>
          {props.showLaunch && props.handleLaunchClick && (
            <StyledIcon
              id={props.launchIconId}
              className="launchIcon"
              onClick={props.handleLaunchClick}
              title="Show Metric Chart"
            >
              <LaunchIcon disableShrink fontSize="small" thickness={4} />
            </StyledIcon>
          )}
          {!props.helpLinkNextToFieldName && (props.helpLink || props.pendoId) && helpIcon}
        </ExtraButtonsRow>
      </FluidFieldContent>
    </StyledFluidItem>
  );
};

FluidField.propTypes = {
  key: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  disableEdit: PropTypes.bool,
  editingTriggers: PropTypes.func,
  renderer: PropTypes.func,
  ignoreBlurOn: PropTypes.func,
  format: PropTypes.oneOf(['column', 'row']),
  alwaysEditing: PropTypes.bool,
  ignoreMouseEvents: PropTypes.bool,
  readingPlaceholder: PropTypes.string,
};
FluidField.defaultProps = {
  disableEdit: false,
  format: 'column',
  ignoreBlurOn: () => {},
  editingTriggers: edit => ({ onClick: edit }),
  alwaysEditing: false,
  ignoreMouseEvents: false,
  readingPlaceholder: NONE_PLACEHOLDER,
};

export default FluidField;

export const StyledFluidItem = styled(FluidItem)`
  display: flex;

  &&&&:hover .helpIcon {
    visibility: visible;
  }

  &&&&:hover .launchIcon {
    visibility: visible;
  }
`;

const multilineStyles = css`
  ${({ $isEditing, $editingHeight }) =>
    $isEditing &&
    $editingHeight != null &&
    `
      max-height: ${$editingHeight}px;
      & textarea {
        min-height: 20px;
        max-height: ${$editingHeight - 17}px !important;
        /* 17 = 2 * border + v-padding-of-containing-div = 2 * 2 + (6 + 7) */
      }
    `};

  & textarea {
    ${({ $isEditing }) => !$isEditing && 'overflow: hidden;'}
  }
`;

const ValueOrPlaceholder = styled.div`
  transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
  flex-grow: 1;
  min-height: 32px;
  border-color: transparent;
  border-radius: 3px;
  border-style: solid;
  padding: 0 4px 0 8px;

  > * {
    width: 100%;
  }

  ${({ $isEditing, $displayBorder, theme }) =>
    $isEditing && $displayBorder && `border: 2px solid ${theme.palette.newLayout.border.textarea};`}

  ${({ $multiline }) => $multiline && multilineStyles};

  ${({ $valueOrPlaceholderStyles }) => $valueOrPlaceholderStyles};

  ${({ invalid }) =>
    invalid &&
    `
    border-bottom: 1px solid red;
  `}

  ${({ hover }) =>
    hover &&
    `
    &:hover {
      background-color: rgb(235, 236, 240);
    }
  `}
`;

const StyledIcon = styled(ButtonIcon)`
  &&&& {
    visibility: hidden;
    width: fit-content;
    padding: 0 2px;
    cursor: pointer;
  }
`;

export const ExtraButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FluidFieldContent = styled.div`
  ${({ needsFlex }) => needsFlex && 'display: flex;'}
`;

export const FieldWrapperGrid = styled(Grid)``;
