import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { pluck } from 'ramda';
import Typography from '@material-ui/core/Typography';
import noop from 'lodash/noop';

import { getOrgHasMetadataRoadmaps as getOrgHasMetadataRoadmapsSelector } from 'store/organization/selectors';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import defaultExpandFirstGroup from 'shared/components/inputs/Autocomplete/helpers/defaultExpandFirstGroup';
import useFilterMetadataBasedOnRoadmap from 'hooks/metadata/useFilterMetadataBasedOnRoadmap';

import DndItem from 'design-system/molecules/DndItem/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';

export const updateItemsLabel = (items = []) =>
  items.map(item => ({
    ...item,
    label: item?.label || item?.title || item?.name,
    children: updateItemsLabel(item.children),
  }));

const SeparatorDndItemComponent = props => {
  return (
    <DndItem
      {...props}
      renderCustomItem={item => (
        <TooltipOnOverflow text={item.label}>
          <Typography variant="body1">{item.label}</Typography>
        </TooltipOnOverflow>
      )}
    />
  );
};

const RoadmapDndItemComponent = props => {
  return <DndItem {...props} onClickItem={noop} onSelectItem={noop} />;
};

const getShowOtherRoadmapsItem = (children = [], getSystemFieldName) => ({
  id: 'otherRoadmaps',
  label: `Show other ${getSystemFieldName('roadmap', true)}`,
  dndItemComponent: SeparatorDndItemComponent,
  children,
});

// warning: for roadmap metadata this is expecting to receive the options on the format
// returned by makeMetadataRoadmapsOptionsFormatter
const useCreateMetadataAutocompleteOptions = ({
  options,
  selectedEntity,
  getSystemFieldName,
  withNullOption = false,
  formatByRoadmap = true,
}) => {
  const filteringConfiguration = useMemo(() => ({ roadmap_id: selectedEntity?.roadmap_id }), [selectedEntity]);
  const { filterOptions } = useFilterMetadataBasedOnRoadmap(filteringConfiguration);

  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmapsSelector);
  const formatOptionsByRoadmap = hasMetadataRoadmaps && formatByRoadmap;

  const { getDefaultRoadmapTitleForMetadataItem } = useOrganizationsAccessControl();

  const formatOptionsList = useCallback(
    optionsToFormat => {
      const optionsTree = optionsToFormat.map(option => ({
        ...option,
        label: `Applied to ${option.label}`,
        dndItemComponent: RoadmapDndItemComponent,
      }));

      return optionsTree;
    },
    [getDefaultRoadmapTitleForMetadataItem],
  );

  const dropdownOptions = useMemo(() => {
    const normalizedOptions = defaultExpandFirstGroup(updateItemsLabel(options));

    if (!formatOptionsByRoadmap) return [...(withNullOption ? [{}] : []), ...normalizedOptions];

    const matchedOptions = normalizedOptions.filter(
      option => !selectedEntity?.roadmap_id || option.id === selectedEntity?.roadmap_id || !option?.id,
    );
    const matchedOptionsTree = formatOptionsList(matchedOptions);

    // no need to add show other roadmaps separator
    if (matchedOptions.length === normalizedOptions.length) {
      return [...(withNullOption ? [{}] : []), ...matchedOptionsTree];
    }

    const matchedIds = pluck('id', matchedOptions);
    const otherOptions = normalizedOptions.filter(option => !matchedIds.includes(option?.id));

    const children = formatOptionsList(otherOptions);

    const separatorElement = getShowOtherRoadmapsItem(children, getSystemFieldName);

    return [...(withNullOption ? [{}] : []), ...matchedOptionsTree, separatorElement];
  }, [formatOptionsByRoadmap, options, formatOptionsList, filterOptions]);

  return dropdownOptions;
};

export default useCreateMetadataAutocompleteOptions;
