import { schema, normalize } from 'normalizr';

import { add } from 'utils/store/entities';
import { buildReleaseCapacityAllocationKey } from 'utils/releaseCapacityAllocation';
import { FETCH_ALL_RELEASE_CAPACITY_ALLOCATION_FULFILLED, UPSERT_RELEASE_CAPACITY_ALLOCATION_FULFILLED } from '../types';

const releaseCapacityAllocationEntity = new schema.Entity(
  'releaseCapacityAllocation',
  {},
  {
    idAttribute: (entity, parent) => {
      return buildReleaseCapacityAllocationKey(entity.timeframe_id, entity.metadata_field, entity.metadata_id);
    },
  },
);

const mapEntitiesToState = (state, entities) => ({
  ...state,
  releaseCapacityAllocation: add(state.releaseCapacityAllocation, entities.releaseCapacityAllocation),
});

const addToState = (state, releaseCapacityAllocation) => {
  const { entities } = normalize(releaseCapacityAllocation, releaseCapacityAllocationEntity);

  return mapEntitiesToState(state, entities);
};

const bulkAddToState = (state, releaseCapacityAllocations) => {
  const { entities } = normalize(releaseCapacityAllocations, [releaseCapacityAllocationEntity]);

  return mapEntitiesToState(state, entities);
};

function releaseCapacityAllocationReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_ALL_RELEASE_CAPACITY_ALLOCATION_FULFILLED: {
      return bulkAddToState(state, action.payload.data);
    }
    case UPSERT_RELEASE_CAPACITY_ALLOCATION_FULFILLED: {
      return addToState(state, action.payload.data);
    }
    default:
      return state;
  }
}

export default releaseCapacityAllocationReducer;
