import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { makeSelectorToMetadataByKey } from 'utils/metadata';

const useMetadataForDisplay = (metadataKeys = []) => {
  const getSelectorsByKey = useMemo(() => makeSelectorToMetadataByKey({ keysToPick: metadataKeys }), metadataKeys);

  return useSelector(state => getSelectorsByKey(state));
};

export default useMetadataForDisplay;
