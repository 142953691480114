import React from 'react';
import styled from 'styled-components';
import { either, isEmpty, isNil } from 'ramda';

const isNilOrEmpty = either(isNil, isEmpty);

const ReqestIntegrationKeyCellRenderer = params => {
  const {
    data,
    colDef: { orgIntegrations },
  } = params;

  const [integrationData] = data.integrations || [];

  if (isNilOrEmpty(orgIntegrations) || isNilOrEmpty(integrationData)) {
    return null;
  }

  const { external_url: externalUrl, external_id: externalId } = integrationData;

  return (
    <Wrapper>
      <IntegrationKeyWrapper>
        <Link
          onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          if (externalUrl) {
            window.open(externalUrl, '_blank');
          }
        }}
          target="_blank"
          rel="noreferrer"
        >
          {externalId}
        </Link>
      </IntegrationKeyWrapper>
    </Wrapper>
  );
};

export default ReqestIntegrationKeyCellRenderer;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const IntegrationKeyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
`;

const Link = styled.a`
  opacity: 1;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.5s ease-in-out;
  }
`;
