import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Highlighter from 'react-highlight-words';
import DOMPurify from 'dompurify';
import { defaultTo, equals, indexBy, not, prop } from 'ramda';

import withStyles from '@material-ui/core/styles/withStyles';
import MuiCard from '@material-ui/core/Card';
import MuiCardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import VoteIcon from '@material-ui/icons/ThumbUp';

import MicroChip from 'design-system/atoms/MicroChip/index';
import SmallText from 'design-system/atoms/SmallText/index';

import { materialColors, materialBackground, linkColor } from 'design-system/themes/default';
import theme from 'design-system/theme';

import ProjectProgressCounters from 'containers/ProjectProgressCounters';
import ProjectChildrenCounter from 'containers/ProjectChildrenCounter';
import ProjectIntegrationKey from 'containers/ProjectIntegrationKey';
import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { getProjectsCustomFields } from 'store/customFields/selectors';

import { planningStageColors, getUserName } from 'utils';
import invertedTextColor from 'design-system/utils/invertedTextColor';
import formatDate from 'utils/dates/formatDate';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';
import ProjectInfoCardDetailsLine from 'containers/ProjectInfoCardDetailsLine';

import {
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_PRODUCTS_2,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_TEAMS,
  TIMEFRAME_LEVEL,
  KEY_RESULT_LEVEL,
  PRODUCT_LEVEL,
  ADDITIONAL_TEAMS_2,
  PERSONAS,
  LIFECYCLES,
} from 'constants/common';
import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';
import { selectPersonasData } from 'features/Personas/store/selectors';
import { selectLifecyclesData } from 'features/Lifecycles/store/selectors';

const defaultToEmptyArray = defaultTo([]);

const isMultiSelectCustomField = fieldType => equals(fieldType, TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN);
const isRichTextCustomField = fieldType => equals(fieldType, TYPES_OF_CUSTOM_FIELDS.RICH_TEXT);

const byId = indexBy(prop('id'));

const renderAdditionalMetadataItem = (metadata = []) => metadata.map(item => <CardChip label={item.title} variant="outlined" />);

const renderAdditionalMetadataByLevel = (metadata = [], level) =>
  renderAdditionalMetadataItem(metadata.filter(item => item.level === level));

const makeRenderAdditionalMetadata = (props, displayPreferences) => (field, level) => {
  if (not(props?.[field]) || not(displayPreferences?.[field])) {
    return null;
  }

  if (level) {
    return renderAdditionalMetadataByLevel(props?.[field], level);
  }

  return renderAdditionalMetadataItem(props?.[field]);
};

const CardTemplate = React.memo(props => {
  const {
    classes,
    canDelete,
    custom_fields: customFieldValues,
    displayPreferences,
    orgIntegrations,
    integrations,
    progress,
    fakeCard,
    title,
    votes,
    searchString,
  } = props;

  const customFields = useSelector(state => getProjectsCustomFields(state));
  const personasData = useSelector(state => selectPersonasData(state, true));
  const personasById = byId(personasData);
  const lifecyclesData = useSelector(state => selectLifecyclesData(state, true));
  const lifecyclesById = byId(lifecyclesData);

  if (fakeCard) return '';

  const showHealth = props.status_color && props.selectedTags.includes('health');

  const shouldShowPreCalculations = useFeatureFlags([FeatureFlags.HAS_OUTCOME_DASHBOARD]);

  const _renderTitle = () => {
    if (!searchString) return title;

    return <Highlighter highlightClassName="hightlightText" searchWords={[searchString]} autoEscape textToHighlight={title} />;
  };

  const _getCustomFieldValue = (customFields, field) => {
    if (field.field_type === 'Date') return formatDate(customFields[field.key]);
    if (field.field_type === 'Dropdown') return field.data[customFields[field.key]];
    if (field.field_type === 'Yes/No') return customFields[field.key] ? 'Yes' : 'No';

    return customFields[field.key];
  };

  const renderMultiCustomFields = useCallback(() => {
    const multiCustomFields = customFields.filter(cf => isMultiSelectCustomField(cf.field_type));

    return multiCustomFields.reduce((chips, field) => {
      if (!!customFieldValues && !!customFieldValues[field.key] && displayPreferences[field.key]) {
        const values = defaultToEmptyArray(customFieldValues[field.key]);
        const displayValues = values.map(value => {
          const label = field.data_enhanced[value]?.title;

          return <CardChip label={label} variant="outlined" />;
        });

        return [...chips, ...displayValues];
      }

      return chips;
    }, []);
  }, [customFields, customFieldValues, displayPreferences]);

  const renderAdditionalMetadata = useMemo(
    () => makeRenderAdditionalMetadata(props, displayPreferences),
    [props, displayPreferences],
  );

  const _renderMetadata = () => (
    <VoteAndChipsContainer>
      {(displayPreferences.votes || !Object.keys(displayPreferences).includes('votes')) && (
        <VoteButtonContainer>
          <VoteButton
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (votes && votes.had_vote) props.unvoteOnProject(props.id);
              else props.voteOnProject(props.id);
            }}
            title="Vote"
            color="primary"
            hadVote={votes && votes.had_vote}
          >
            <VoteIcon fontSize="small" />
          </VoteButton>
          <VoteCounter hidden={votes && votes.count === 0}>{(votes || {}).count}</VoteCounter>
        </VoteButtonContainer>
      )}
      {props.planningStage && props.displayPreferences.planningStage && (
        <CardChip
          label={props.planningStage}
          textColor={invertedTextColor(planningStageColors[props.planningStage], true)}
          color={planningStageColors[props.planningStage]}
          variant="outlined"
        />
      )}
      {props.roadmap && props.dataType !== 'roadmap' && props.selectedTags.includes('roadmap') && (
        <CardChip
          label={props.roadmap.title}
          textColor={props.roadmap.color && invertedTextColor(props.roadmap.color, true)}
          color={props.roadmap.color}
        />
      )}
      {props.product1 && props.dataType !== 'product' && props.displayPreferences.product1 && (
        <CardChip
          label={props.product1.title}
          textColor={props.product1.color && invertedTextColor(props.product1.color, true)}
          color={props.product1.color}
        />
      )}
      {props.phase && props.dataType !== 'phase' && props.selectedTags.includes('phase') && (
        <CardChip
          label={props.phase.title}
          textColor={props.phase.color && invertedTextColor(props.phase.color, true)}
          color={props.phase.color}
        />
      )}
      {props.timeframe && props.dataType !== 'timeframe' && props.selectedTags.includes('timeframe') && (
        <CardChip
          label={props.timeframe.title}
          textColor={props.timeframe.color && invertedTextColor(props.timeframe.color, true)}
          color={props.timeframe.color}
          variant="outlined"
        />
      )}
      {props.objective && props.dataType !== 'objective' && props.selectedTags.includes('objective') && (
        <CardChip
          label={props.objective.title}
          textColor={props.objective.color && invertedTextColor(props.objective.color, true)}
          color={props.objective.color}
        />
      )}
      {props.theme && props.dataType !== 'theme' && props.selectedTags.includes('theme') && (
        <CardChip
          label={props.theme.title}
          textColor={props.theme.color && invertedTextColor(props.theme.color, true)}
          color={props.theme.color}
        />
      )}
      {props.category && props.dataType !== 'category' && props.selectedTags.includes('category') && (
        <CardChip
          label={props.category.title}
          textColor={props.category.color && invertedTextColor(props.category.color, true)}
          color={props.category.color}
        />
      )}
      {props.priority && props.dataType !== 'priority' && props.selectedTags.includes('priority') && (
        <CardChip
          label={props.priority.title}
          textColor={props.priority.color && invertedTextColor(props.priority.color, true)}
          color={props.priority.color}
        />
      )}
      {props.resourceTeam && props.dataType !== 'team' && props.selectedTags.includes('team') && (
        <CardChip
          label={props.resourceTeam.title}
          textColor={props.resourceTeam.color && invertedTextColor(props.resourceTeam.color, true)}
          color={props.resourceTeam.color}
        />
      )}
      {props.tags &&
        props.displayPreferences.tags &&
        props.tags.map(t => <CardChip label={t.title} color={t.color} textColor={t.color && invertedTextColor(t.color, true)} />)}
      {props.customers &&
        props.displayPreferences.customers &&
        props.customers.map(c => (
          <CardChip label={c.name} color={c.color} textColor={c.color && invertedTextColor(c.color, true)} />
        ))}
      {props.keyResult1 && props.dataType !== 'keyResult' && props.displayPreferences.keyResult1 && (
        <CardChip
          label={props.keyResult1.title}
          color={props.keyResult1.color}
          textColor={props.keyResult1.color && invertedTextColor(props.keyResult1.color, true)}
        />
      )}
      {props.keyResult2 && props.dataType !== 'keyResult2' && props.displayPreferences.keyResult2 && (
        <CardChip
          label={props.keyResult2.title}
          color={props.keyResult2.color}
          textColor={props.keyResult2.color && invertedTextColor(props.keyResult2.color, true)}
        />
      )}
      {props.owner && props.selectedTags.includes('owner') && (
        <CardChip
          label={getUserName(props.owner)}
          color={theme.palette.newLayout.background.lightBlue}
          textColor={theme.palette.newLayout.background.white}
        />
      )}
      {props.parent && props.parentLayer === BET_LAYER && displayPreferences.showBet && (
        <CardChip label={props.parent} color="#6b808c" variant="outlined" />
      )}
      {props.parent && props.parentLayer === INITIATIVE_LAYER && displayPreferences.showInitiative && (
        <CardChip label={props.parent} color="#6b808c" variant="outlined" />
      )}
      {renderAdditionalMetadata(ADDITIONAL_ROADMAPS)}
      {renderAdditionalMetadata(ADDITIONAL_PRODUCTS, PRODUCT_LEVEL.product)}
      {renderAdditionalMetadata(ADDITIONAL_PRODUCTS_2, PRODUCT_LEVEL.product2)}
      {renderAdditionalMetadata(ADDITIONAL_OBJECTIVES)}
      {renderAdditionalMetadata(ADDITIONAL_KEY_RESULTS, KEY_RESULT_LEVEL.keyResult)}
      {renderAdditionalMetadata(ADDITIONAL_KEY_RESULTS_2, KEY_RESULT_LEVEL.keyResult2)}
      {renderAdditionalMetadata(ADDITIONAL_TIMEFRAMES, TIMEFRAME_LEVEL.timeframe)}
      {renderAdditionalMetadata(ADDITIONAL_TIMEFRAMES_2, TIMEFRAME_LEVEL.timeframe2)}
      {renderAdditionalMetadata(ADDITIONAL_THEMES)}
      {renderAdditionalMetadata(ADDITIONAL_CATEGORIES)}
      {renderAdditionalMetadata(ADDITIONAL_TEAMS)}
      {renderAdditionalMetadata(ADDITIONAL_TEAMS_2)}
      {props[PERSONAS] &&
        props.displayPreferences[PERSONAS] &&
        props[PERSONAS].map(p => {
          const personaData = personasById[p?.id];

          if (!personaData) return null;

          return <CardChip label={personaData.title} variant="outlined" />;
        })}
      {props[LIFECYCLES] &&
        props.displayPreferences[LIFECYCLES] &&
        props[LIFECYCLES].map(l => {
          const lifecycleData = lifecyclesById[l?.id];

          if (!lifecycleData) return null;

          return <CardChip label={lifecycleData.title} variant="outlined" />;
        })}
      {renderMultiCustomFields()}
    </VoteAndChipsContainer>
  );

  const _renderDetails = () => (
    <DetailsWrapper>
      {!!props.estimated_start_date && props.displayPreferences.estimated_start_date && (
        <ProjectInfoCardDetailsLine value={formatDate(props.estimated_start_date)} label="Target start date" />
      )}
      {!!props.deadline && props.displayPreferences.deadline && (
        <ProjectInfoCardDetailsLine value={formatDate(props.deadline)} label="Target end date" />
      )}
      {!!props.riceScore && props.displayPreferences.riceScore && (
        <ProjectInfoCardDetailsLine value={props.riceScore} label="RICE" />
      )}
      {!!props.planned_moar && (props.displayPreferences.planned_moar || props.displayPreferences.calculatedMoar) && (
        <ProjectInfoCardDetailsLine value={parseFloat(props.planned_moar)} label="MoAR" />
      )}
      {!!props.effort_score && props.displayPreferences.effort_score && (
        <ProjectInfoCardDetailsLine value={props.effort_score} label="Effort" />
      )}
      {shouldShowPreCalculations && !!props.totalRevenue && props.displayPreferences.totalRevenue && (
        <ProjectInfoCardDetailsLine value={props.totalRevenue} label="Total value" />
      )}
      {shouldShowPreCalculations && !!props.activeRevenue && props.displayPreferences.activeRevenue && (
        <ProjectInfoCardDetailsLine value={props.activeRevenue} label="Active value" />
      )}
      {shouldShowPreCalculations && !!props.inactiveRevenue && props.displayPreferences.inactiveRevenue && (
        <ProjectInfoCardDetailsLine value={props.inactiveRevenue} label="Inactive value" />
      )}
      {!!props.customersCount && props.displayPreferences.customersCount && (
        <ProjectInfoCardDetailsLine value={props.customersCount} label="# of Customers" />
      )}
      {!!props.customerRequestsCount && props.displayPreferences.customerRequestsCount && (
        <ProjectInfoCardDetailsLine value={props.customerRequestsCount} label="Total requests" />
      )}
      {(customFields || [])
        .filter(
          cf =>
            not(isMultiSelectCustomField(cf.field_type)) &&
            !!customFieldValues &&
            !!customFieldValues[cf.key] &&
            displayPreferences[cf.key],
        )
        .map(cf => {
          const customFieldValue = _getCustomFieldValue(customFieldValues, cf);
          const value = isRichTextCustomField(cf.field_type) ? (
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(customFieldValue) }} />
          ) : (
            customFieldValue
          );

          return <ProjectInfoCardDetailsLine value={value} label={cf.title} />;
        })}
    </DetailsWrapper>
  );

  return (
    <Card className={classes.card}>
      <CardContent health={showHealth ? materialColors[props.status_color.toLowerCase()] : ''} className={classes.cardContent}>
        <TitleContainer>
          {displayPreferences.integrationKey && props.integration && (
            <IntegrationWrapper>
              <ProjectIntegrationKey project={props} orgIntegrations={orgIntegrations} />
            </IntegrationWrapper>
          )}
          <Typography className={classes.title} color="textSecondary">
            {_renderTitle()}
          </Typography>
        </TitleContainer>
        {canDelete && (
          <IconButton
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              props.onDelete(props.id, props.laneId);
            }}
            title="Delete"
            color="secondary"
            className={classes.deleteIcon}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {_renderMetadata()}
        {_renderDetails()}
        {((props.Jiras && props.Jiras.length > 0) || (integrations && integrations.length > 0)) &&
          props.selectedTags.includes('progress') && (
            <ProgressContainer>{progress ? `${Math.floor(progress * 100)}%` : '0%'}</ProgressContainer>
          )}
        {displayPreferences.progressCount && <ProjectProgressCounters project={props} />}
        {displayPreferences.progressCount && props.checkCanBeParent(props) && <ProjectChildrenCounter project={props} />}
      </CardContent>
    </Card>
  );
});

const styles = theme => ({
  card: {
    width: 250,
    maxWidth: 250,
    margin: 'auto',
    boxShadow: '0px 2px 3px #6F77821A !important',
    border: '1px solid #E8ECEE !important',
    borderRadius: '4px !important',
  },
  cardContent: {
    paddingTop: theme.spacing.unit,
    '&:last-child': {
      paddingBottom: theme.spacing.unit,
    },
  },
  title: {
    // marginBottom: theme.spacing.unit,
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.8)',
    whiteSpace: 'initial',
  },
  deleteIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    opacity: 0,
    '&:hover': {
      opacity: 1,
    },
  },
});

export default withStyles(styles)(CardTemplate);

const CardChip = props => <MicroChip {...props} />;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing.unit}px;
`;

const Card = styled(MuiCard)``;

const CardContent = styled(MuiCardContent)`
  ${props => props.health && `border-left: 4px solid ${props.health}`};
`;

const ProgressContainer = styled('span')`
  &&&& {
    margin: 4px;
    font-size: 11px;
    height: auto;
    max-width: 200px;
    padding: 0px;

    span {
      vertical-align: middle;
      display: flex;
      padding-left: 4px;
      padding-right: 4px;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
    }
  }
`;

const IntegrationWrapper = styled.div`
  display: inline-flex;
  margin: 0;
  /* border: 1px solid rgba(0, 0, 0, 0.23); */
  border-radius: 16px;
  padding: 0;
  align-items: center;
  justify-content: center;
  padding: 0 ${props => props.theme.spacing.unit}px 0 0;

  a {
    margin: 0 !important;
    font-size: ${({ theme }) => theme.typography.fontSizeSmall}px!important;

    svg {
      width: 14px !important;
      height: 14px !important;
    }
  }
`;

const VoteButtonContainer = styled.div`
  &&&& {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }
`;

const VoteButton = styled(IconButton)`
  &&&& {
    width: 10px;
    height: 10px;
    color: ${props => (props.hadVote ? linkColor : materialBackground.hawkesBlue)};

    svg {
      font-size: 14px;
    }

    &:hover {
      color: ${linkColor};
    }
  }
`;

const VoteCounter = styled(SmallText)`
  &&&& {
    margin-right: 5px;
    display: inline-flex;
    display: ${props => (props.hidden ? 'none' : 'auto')};
  }
`;

const VoteAndChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -2px;
`;

const DetailsWrapper = styled.div`
  line-height: ${props => props.theme.typography.lineHeightSmaller}px;
`;
