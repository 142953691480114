import React from 'react';
import styled from 'styled-components';
import InputAdornment from '@material-ui/core/InputAdornment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import SearchableList from '../SearchableList';
import InlineAutoCompleteInput from 'design-system/atoms/InlineAutoCompleteInput/InlineAutoCompleteInput';

const Wrapper = styled.div`
  &&&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-right: -25px;
  }
`;

const StyledInput = styled(InlineAutoCompleteInput)`
  &&&& {
    input {
      text-overflow: ellipsis;
    }
  }
`;

const SearchableListSelect = ({ onChange, value, ...other }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const selectedItem = other.myFilters.find(f => f.id === value);

  return (
    <Wrapper>
      <StyledInput
        readOnly
        value={selectedItem?.title || ''}
        onClick={e => setAnchorEl(!anchorEl ? e.currentTarget : null)}
        containerStyles={{
          maxWidth: '500px',
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <StyledArrowDownIcon />
            </InputAdornment>
          ),
        }}
      />

      <SearchableList
        onFilterClick={item => {
          onChange(item.id);
          setAnchorEl(null);
        }}
        onClickAway={() => setAnchorEl(null)}
        {...other}
        anchorEl={anchorEl}
      />
    </Wrapper>
  );
};

const StyledArrowDownIcon = styled(KeyboardArrowDownIcon)`
  cursor: pointer;

  && {
    fill: ${({ theme }) => theme.palette.newLayout.text.siroccoGrey};
    font-size: 20px;
    margin-right: -25px;
  }
`;

export default SearchableListSelect;
