/**
 * Returns list of with new item or updated item
 * @param {Object} item
 * @param {Object[]} list
 * @return {Object[]}
 */
export default function (item, list) {
  const index = list.findIndex(i => i.id === item.id);
  const newList = list.slice(0);

  // update objective if it exists in list
  if (index >= 0) {
    newList[index] = item;
  } else {
  // add objective if it does not exist in list
    newList.unshift(item || {});
  }

  return newList;
}
