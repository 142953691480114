export const BILLING_STATUSES = {
  SELFSERVE_TRIAL: 'selfserve_trial',
  TRIAL: 'trial',
  FREE: 'free',
};

export const SIGNUP_FLOWS = {
  default: 'default',
  targeted: 'targeted',
};

export const DEFAULT_ONBOARDING_GUIDE = `
<p>Refer to Dragonboat <a
baseurlforattachments=""
rel="noreferrer nofollow"
target="_blank"
href="https://dragonboat.zendesk.com/hc/en-us/articles/4404615229591">quick start guide</a> or add your own.</p>`;
