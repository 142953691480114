import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { defaultTo, pluck } from 'ramda';
import { useSelector } from 'react-redux';

import Dialog from 'design-system/molecules/Dialog/index';
import Pagination from 'design-system/organisms/Pagination/index';
import MultiSelectDropdown2Columns from 'design-system/organisms/MultiSelectDropdown2Columns/index';
import MultiSelect from 'design-system/atoms/MultiSelect/index';

import Header from './components/Header';
import ProjectsListTable from './components/ProjectsListTable';
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';


import useLoadProjectsByEntity from 'hooks/useLoadProjectsByEntity';
import useProjectGroups from 'hooks/useProjectGroups';
import usePagination from 'hooks/usePagination';
import useProjectsListTable from 'hooks/useProjectsListTable/useProjectsListTable';

import { getOrgHasMetadataRoadmaps } from 'store/organization/selectors';
import { DEFAULT_GROUP_OPTION } from 'store/projects/helpers/groupOptions';

import setIdeasHierarchyPath from 'utils/setIdeasHierarchyPath';
import getEarliestAndLatesDates from 'utils/getEarliestAndLatesDates';
import { getPageIdFromPath } from 'utils/userViews';
import { determinePaginationOffset } from 'utils/pagination';

import { METRICS_GRID_PAGE, METRICS_CHART_PAGE } from 'constants/filters';
import { DEFAULT_PAGINATION_INFORMATION } from 'constants/pagination';
import { hideAllEmpty } from 'utils/projects/grouping';
import { PAGE_LIMIT_OPTIONS, DEFAULT_PAGINATION_LIMIT } from 'constants/projects';

const defaultToZero = defaultTo(0);
const getLabels = pluck('label');

const getMoreFieldsSelected = (fields, moreFields) =>
  fields.reduce((acc, field) => {
    const match = moreFields.find(({ label }) => label === field);

    if (match) return [...acc, match];

    return acc;
  }, []);

const ProjectsListLightbox = ({
  isOpen,
  selectedEntity,
  entityType,
  selectedProjects,
  onClose,
  tableState,
  updateTableState,
}) => {
  const [projectsData, setProjectsData] = useState([]);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [selectedGroupOption, setSelectedGroupOption] = useState(DEFAULT_GROUP_OPTION);
  const [paginationInformation, setPaginationInformation] = useState(DEFAULT_PAGINATION_INFORMATION);
  const [pageLimit, setPageLimit] = useState(DEFAULT_PAGINATION_LIMIT);

  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);

  const updatColumnState = newColumnState => updateTableState('columnState', newColumnState);

  const updateVisibleFields = newVisibleFields => updateTableState('visibleFields', newVisibleFields);

  const path = window.location.pathname;
  const pageId = getPageIdFromPath(path);

  const isOnMetricsPage = [METRICS_GRID_PAGE, METRICS_CHART_PAGE].includes(pageId);

  const title = useMemo(() => {
    const entityTitle = selectedEntity?.title || selectedEntity?.name || '';

    return `${entityTitle} Portfolio Items`;
  }, [selectedEntity]);

  const paginationOffset = useMemo(
    () => determinePaginationOffset(paginationInformation.current, pageLimit),
    [pageLimit, paginationInformation.current],
  );

  const { projects, handleSearchProjects, isLoadingProjects, totalProjects } = useLoadProjectsByEntity(setPaginationInformation);

  const {
    tableShowFieldsEl,
    setTableShowFieldsEl,
    tableVisibleFields,
    leftColumnFields,
    rightColumnFields,
    handleChangeFields,
    handleUncheckAllFields,
    handleCheckAllFields,
    autoGroupColumn,
    defaultColumnDef,
    getDefaultRoadmapTitleForMetadataItem,
    columnsDefs,
    gridProps,
    groupByOptions,
    hasSelectedGroupBy,
    handleGridReady,
    handleDisplayedColumnsChanged,
    moreFields,
    baseFields,
  } = useProjectsListTable(
    selectedGroupOption,
    tableState?.columnState,
    updatColumnState,
    tableState?.visibleFields,
    updateVisibleFields,
  );

  const projectGroups = useProjectGroups({
    selectedGroup1: selectedGroupOption,
    customAllProjectsByLayer: projectsData,
    hideEmptyHandler: hideAllEmpty,
    applyPageFiltersToMetadataGroup: false,
    isMetricMode: true,
  });

  const projectRowData = useMemo(
    () =>
      hasSelectedGroupBy
        ? setIdeasHierarchyPath(
            { all: projectsData },
            'all',
            null,
            null,
            true,
            projectGroups,
            hasMetadataRoadmaps,
            getDefaultRoadmapTitleForMetadataItem,
            false,
          )
        : projectsData,
    [
      getDefaultRoadmapTitleForMetadataItem,
      hasMetadataRoadmaps,
      hasSelectedGroupBy,
      projectsData,
      projectGroups,
      selectedGroupOption,
    ],
  );

  const onCloseLightbox = useCallback(() => {
    setProjectsData([]);
    setPaginationInformation(DEFAULT_PAGINATION_INFORMATION);

    onClose();

    if (hasSelectedGroupBy) {
      updateSelectedGroupOption(DEFAULT_GROUP_OPTION);
    }
  }, [hasSelectedGroupBy, setSelectedGroupOption]);

  const updateSelectedGroupOption = useCallback(
    option => {
      setSelectedGroupOption(option);
      setIsLoadingTable(true);
    },
    [setSelectedGroupOption, setIsLoadingTable],
  );

  const searchParams = useMemo(() => (selectedProjects.length > 0 ? { id: selectedProjects } : {}), [selectedProjects]);

  const shouldDisplayDateRange = isOnMetricsPage && Boolean(selectedProjects.length);

  const { earliestDate, latestDate } = useMemo(
    () => getEarliestAndLatesDates(projectsData.map(project => project.completed_date || project.deadline)),
    [projectsData],
  );

  const searchProjects = useCallback(
    (limit, offset) => handleSearchProjects({ entity: selectedEntity?.id, entityType }, searchParams, true, limit, offset),
    [selectedEntity, entityType, searchParams],
  );

  const triggerSearchFromPagination = useCallback(
    ({ limit, page }) => searchProjects(limit, limit * (page - 1)),
    [searchProjects, selectedEntity, entityType, searchParams],
  );

  const { handleChangePage, handleChangePageLimit, indexedCurrentPage, lastPage } = usePagination(
    triggerSearchFromPagination,
    paginationInformation.current,
    paginationInformation.next,
    paginationInformation.previous,
    totalProjects,
    pageLimit,
    setPageLimit,
  );

  const onPageLimitChange = limit => {
    handleChangePageLimit(limit);
    setPaginationInformation(DEFAULT_PAGINATION_INFORMATION);
    searchProjects(limit, paginationOffset);
  };

  useEffect(() => setProjectsData(projects), [projects]);

  useEffect(() => {
    if (isOpen) searchProjects(pageLimit, paginationOffset);
  }, [isOpen, searchProjects]);

  // Required to prevent the table from crashing when changing group options
  useEffect(() => {
    if (!isLoadingTable) return;
    setTimeout(() => setIsLoadingTable(false), 500);
  }, [isLoadingTable]);

  const handleMoreFieldsChange = useCallback(
    fields => {
      const updatedFields = [
        ...baseFields.filter(({ label }) => tableVisibleFields.includes(label)),
        ...getMoreFieldsSelected(fields, moreFields),
      ];

      handleChangeFields(getLabels(updatedFields));
    },
    [handleChangeFields, baseFields, moreFields, tableVisibleFields],
  );

  const selectedMoreFields = useMemo(() => {
    return getMoreFieldsSelected(tableVisibleFields, moreFields);
  }, [tableVisibleFields]);

  return (
    <StyledDialog
      data-test="projects-list-dialog"
      open={!!isOpen}
      onClose={onCloseLightbox}
      maxWidth="lg"
      scroll="paper"
      fullWidth
      fullHeight
      closeButton
      title=""
    >
      <Wrapper>
        <Header
          title={title}
          groupByOptions={groupByOptions}
          selectedGroupOption={selectedGroupOption}
          updateSelectedGroupOption={updateSelectedGroupOption}
          earliestDate={earliestDate}
          latestDate={latestDate}
          shouldDisplayDateRange={shouldDisplayDateRange}
          setTableShowFieldsEl={setTableShowFieldsEl}
        />
        <TableContainer>
          <ProjectsListTable
            projects={projectRowData}
            isLoading={isLoadingProjects || isLoadingTable}
            columnsDefs={columnsDefs}
            gridProps={gridProps}
            hasSelectedGroupBy={hasSelectedGroupBy}
            autoGroupColumn={autoGroupColumn}
            defaultColumnDef={defaultColumnDef}
            handleGridReady={handleGridReady}
            handleDisplayedColumnsChanged={handleDisplayedColumnsChanged}
          />
        </TableContainer>
        <PaginationContainer>
          <Pagination
            currentPage={indexedCurrentPage}
            handleChangePage={handleChangePage}
            handleChangePageLimit={onPageLimitChange}
            includePagination={projectsData.length > 0 && totalProjects > PAGE_LIMIT_OPTIONS[0]}
            lastPage={lastPage}
            pageLimit={pageLimit}
            resultsCount={defaultToZero(totalProjects)}
            rowsPerPageOptions={[DEFAULT_PAGINATION_LIMIT]}
            isDisabled={isLoadingProjects}
            pageLimitOptions={PAGE_LIMIT_OPTIONS}
          />
        </PaginationContainer>
      </Wrapper>
      <StyledPopover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!tableShowFieldsEl}
        anchorEl={tableShowFieldsEl}
        onClose={() => setTableShowFieldsEl(null)}
      >
        <MultiSelectDropdown2Columns
          leftItems={leftColumnFields}
          rightItems={rightColumnFields}
          selected={tableVisibleFields}
          handleShowAll={handleCheckAllFields}
          handleHideAll={handleUncheckAllFields}
          onChange={handleChangeFields}
          onClose={() => setTableShowFieldsEl(null)}
        />
        <MoreFields>
          <FormControl fullWidth margin="dense" data-testid="goals-project-list-lightbox-more-fields">
            <StyledMultiSelect
              options={moreFields}
              optionsMapper={{ label: 'label', value: 'label' }}
              label="More fields"
              placeholder="Select fields to display"
              onChange={handleMoreFieldsChange}
              value={selectedMoreFields}
              hideCreateOption
              draggable
              hideIcon
            />
          </FormControl>
        </MoreFields>
      </StyledPopover>
    </StyledDialog>
  );
};

export default ProjectsListLightbox;

const Wrapper = styled.div``;

const PaginationContainer = styled.div``;

const TableContainer = styled.div``;

const MoreFields = styled.div`
  margin: 1rem 1rem 1rem 1.6rem;
`;

const StyledMultiSelect = styled(MultiSelect)`
  width: 340px;
`;

// We need to force the transform=none since inside the popover and dialog since
// we have a drag and drop component (more field chips) that can't be affected by it
const StyledPopover = styled(Popover)`
  &&&& {
    div[role='document'] {
      transform: none !important;
    }
  }
`;

const StyledDialog = styled(Dialog)`
  &&&& {
    div[role='document'] {
      transform: none !important;
    }
  }
`;
