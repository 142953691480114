import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getState } from 'store/timelines/selectors';
import { QUICK_FILTER_OPTIONS, ROADMAP_PAGE } from 'constants/filters';
import useDeepEffect from 'hooks/useDeepEffect';
import useSystemFields from 'hooks/useSystemFields';
import { getPageFilters } from 'store/filters/selectors';
import { getMetadataKeyByGroupKey } from 'store/projects/helpers/groupMetadata';
import { convertProjectGroupsToTimelineGroups, getMilestoneTopGroup } from '../helpers/prepareDataToTimeline';

import useSwimlaneGroups from './useSwimlaneGroups';
import useSwimlaneMetadata from './useSwimlaneMetadata';

const MILESTONE_TOP_LANE = 'topLane';

const useSwimlaneDataTree = projects => {
  const {
    displayMilestone,
    displayMilestoneOn,
    grouplaneType: firstGroupType,
    swimlaneType: secondGroupType,
    hideEmptyLane = true,
  } = useSelector(getState(ROADMAP_PAGE));

  const [isDeselect, setDeselect] = useState(false);

  const [getSystemFieldName] = useSystemFields();

  const pageFilters = useSelector(getPageFilters);

  // Get applicable metadata
  const filteredMetadata = useSwimlaneMetadata(projects, pageFilters);

  // Grouping
  const { projectGroups, allGroupsProjects } = useSwimlaneGroups(filteredMetadata, projects);

  const firstGroupTypeKey = firstGroupType ? firstGroupType.key : 'objective';
  const firstGroupData = filteredMetadata[getMetadataKeyByGroupKey(firstGroupTypeKey)];

  const secondGroupTypeKey = secondGroupType ? secondGroupType.key : null;
  const secondGroupTypeData = secondGroupTypeKey ? filteredMetadata[getMetadataKeyByGroupKey(secondGroupTypeKey)] : [];

  // Filtering
  const quickFilterOptions = QUICK_FILTER_OPTIONS(getSystemFieldName);

  const selectedQuickFilter = pageFilters.quickFilters
    ? quickFilterOptions.length &&
      pageFilters.quickFilters &&
      quickFilterOptions.find(o => o.id === Object.keys(pageFilters.quickFilters)[0])
    : quickFilterOptions.length && quickFilterOptions[0];

  const filter = useMemo(() => {
    const { quickFilters } = pageFilters;

    if (
      quickFilters &&
      selectedQuickFilter &&
      quickFilters[selectedQuickFilter.id] &&
      quickFilters[selectedQuickFilter.id].length
    ) {
      return {
        key: selectedQuickFilter.id,
        value: quickFilters[selectedQuickFilter.id][0],
      };
    }

    return {
      key: null,
      value: null,
    };
  }, [selectedQuickFilter, quickFilterOptions, pageFilters]);

  const filterData = useMemo(() => {
    if (filter.key) {
      return filteredMetadata[filter.key];
    }

    return [];
  }, [filteredMetadata, filter]);

  // Should add Undefined group
  const shouldAddUndefinedGroup = useMemo(
    () => !(isDeselect && pageFilters[`${firstGroupTypeKey}s`] && !pageFilters[`${firstGroupTypeKey}s`].includes('null')),
    [],
  );

  // Extract data from project groups for the Timeline component
  const data = useMemo(() => {
    const hasMultiGrouping = !!secondGroupTypeKey;

    // Convert project groups to timeline groups
    const groups = convertProjectGroupsToTimelineGroups(
      projectGroups,
      displayMilestone,
      displayMilestoneOn,
      hideEmptyLane,
      true,
      firstGroupTypeKey,
      secondGroupTypeKey,
    );

    // Add TopLane Milestone
    const hasMilestoneTopGroup = displayMilestone && displayMilestoneOn === MILESTONE_TOP_LANE;

    if (hasMilestoneTopGroup) {
      const milestoneTopGroup = getMilestoneTopGroup(
        hasMultiGrouping,
        [firstGroupTypeKey, secondGroupTypeKey],
        allGroupsProjects,
      );

      return [milestoneTopGroup, ...groups];
    }

    return groups;
  }, [
    projectGroups,
    allGroupsProjects,
    isDeselect,
    pageFilters,
    shouldAddUndefinedGroup,
    firstGroupTypeKey,
    secondGroupTypeKey,
    displayMilestone,
    displayMilestoneOn,
  ]);

  useDeepEffect(() => {
    // show undefined row
    setDeselect(pageFilters.deselect && pageFilters.deselect.includes(`${firstGroupTypeKey}s`));
  }, [firstGroupTypeKey, pageFilters]);

  return [data, firstGroupData, secondGroupTypeData, filterData, filter];
};

export default useSwimlaneDataTree;
