import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import useDeepEffect from 'hooks/useDeepEffect';
import getSystemFieldName from 'utils/getSystemFieldName';
import {
  DISPLAY_COLUMNS_OPTIONS,
  DEFAULT_DISPLAY_COLUMNS,
  DEFAULT_ZOOM_OPTION,
  DEFAULT_HIGHLIHT_OPTION,
  DEFAULT_ROUNDING_OPTION,
  ZOOM_OPTIONS,
  getDefaultGroupOptions,
} from 'constants/timeline';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import { getOrganization, getIdeasIntegrations } from 'store/organization/selectors';
import { getRoadmaps, getProducts } from 'store/roadmaps/selectors';
import { getObjectives, selectKeyResults1, selectKeyResults2 } from 'store/objectives/selectors';
import { getPhases } from 'store/phases/selectors';
import { getThemes } from 'store/themes/selectors';
import { getCategories } from 'store/categories/selectors';
import { getPriorities } from 'store/priorities/selectors';
import { getTimeframes, getTimeframesLevel2 } from 'store/timeframes/selectors';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import { getDisplayLayer, usePortfolioMode } from 'store/filters/selectors';
import { INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import { getGroupOptionByKey } from 'store/projects/helpers/groupOptions';
import { getGroupOptionsSelector } from 'store/projects/groupSelectors';
import { getIsDodActive } from 'src/store/accessControl/selectors';
import usePageFilters from 'hooks/filters/usePageFilters';
import { FeatureFlags } from '@dragonboat/config';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useCustomUserFields from 'hooks/useCustomUserFields';

/**
 * Hook that returns the lsState for timeline pages and set the default values if are not defined
 *
 * @returns lsState
 */
const useTimelineLsState = (lsState, updateLsState, pageId, defaultValues = {}) => {
  const orgIntegrations = useSelector(getIdeasIntegrations);
  const organization = useSelector(state => getOrganization(state));
  const customFields = useSelector(state => getProjectsCustomFields(state, false));
  const displayLayer = useSelector(state => getDisplayLayer(state));
  const portfolioMode = useSelector(state => usePortfolioMode(state));
  const isDodActive = useSelector(state => getIsDodActive(state));
  const { searchString: globalSearchString } = usePageFilters();
  const systemFields = organization.system_fields_name;
  const hasKeyResults = organization.has_key_results;
  const hasKeyResults2 = organization.has_key_results_2;
  const hasProducts = organization.has_products;
  const hasHierarchy = organization.has_hierarchy;
  const hasIntegration = orgIntegrations && orgIntegrations.length > 0;

  const objectives = useSelector(state => getStateDataForPage(state, getObjectives, 'objectives'));
  const roadmaps = useSelector(state => getStateDataForPage(state, getRoadmaps, 'roadmaps'));
  const phases = useSelector(state => getStateDataForPage(state, getPhases, 'phases'));
  const themes = useSelector(state => getStateDataForPage(state, getThemes, 'themes'));
  const categories = useSelector(state => getStateDataForPage(state, getCategories, 'categories'));
  const priorities = useSelector(state => getStateDataForPage(state, getPriorities, 'priorities'));
  const keyResults = useSelector(state => getStateDataForPage(state, selectKeyResults1, 'keyResults'));
  const products = useSelector(state => getStateDataForPage(state, getProducts, 'products'));
  const timeframes = useSelector(state => getStateDataForPage(state, getTimeframes, 'timeframes'));
  const timeframesLevel2 = useSelector(state => getStateDataForPage(state, getTimeframesLevel2, 'timeframes2'));

  const { getAllCustomUserFields } = useCustomUserFields();

  const keyResults2 = useSelector(state =>
    getStateDataForPage(state, (state, showArchived) => selectKeyResults2(state, showArchived), 'keyResults2'),
  );

  const [displayColumns, setDisplayColumns] = useState(lsState.selectedDisplayColumns || []);
  const metadata = {
    roadmapTitles: roadmaps.map(({ title }) => title),
    objectiveTitles: objectives.map(({ title }) => title),
    phaseTitles: phases.map(({ title }) => title),
    themeTitles: themes.map(({ title }) => title),
    categoryTitles: categories.map(({ title }) => title),
    priorityTitles: priorities.map(({ title }) => title),
    keyResult1Titles: keyResults.map(({ title }) => title),
    keyResult2Titles: keyResults2.map(({ title }) => title),
    product1Titles: products.map(({ title }) => title),
    timeframeTitles: timeframes.map(({ title }) => title),
    timeframe2Titles: timeframesLevel2.map(({ title }) => title),
  };

  const shouldShowPreCalculations = useFeatureFlags([FeatureFlags.HAS_OUTCOME_DASHBOARD]);
  const hasCompletedAllocation = useFeatureFlags([FeatureFlags.HAS_COMPLETED_ALLOCATION]);

  const displayColumnOptions = DISPLAY_COLUMNS_OPTIONS({
    systemFields,
    hasKeyResults,
    hasProducts,
    customFields,
    customUserFields: getAllCustomUserFields(),
    hasHierarchy,
    isDodActive,
    hasIntegration,
    orgIntegrations,
    hasKeyResults2,
    shouldShowPreCalculations,
    hasCompletedAllocation,
  });

  const defaultDisplayColumns = [...displayColumnOptions.filter(c => DEFAULT_DISPLAY_COLUMNS.includes(c.id))];

  const groupingOptions = useMemo(() => ({ withNullOption: true, allowGroupByBetInPortfolioMode: true }), []);
  const groupByOptions = useSelector(state => getGroupOptionsSelector(state, groupingOptions), isEqual);

  const selectedGroup1 = useMemo(
    () =>
      lsState.selectedGroup1 && lsState.selectedGroup1.title
        ? getGroupOptionByKey(lsState.selectedGroup1.key, groupByOptions)
        : getDefaultGroupOptions(getSystemFieldName)[0],
    [lsState.selectedGroup1, groupByOptions, getSystemFieldName],
  );

  const selectedGroup2 = useMemo(
    () =>
      lsState.selectedGroup2 && lsState.selectedGroup2.title
        ? getGroupOptionByKey(lsState.selectedGroup2.key, groupByOptions)
        : getDefaultGroupOptions(getSystemFieldName)[1],
    [lsState.selectedGroup2, groupByOptions, getSystemFieldName],
  );

  useEffect(() => {
    const cols = lsState.selectedDisplayColumns || defaultDisplayColumns;

    const _validateGroup = selectedGroup => {
      if (['initiative', 'bet'].includes(selectedGroup?.key) && displayLayer === BET_LAYER) return null;
      if (['initiative'].includes(selectedGroup?.key) && displayLayer === INITIATIVE_LAYER) return null;

      return selectedGroup;
    };

    const defaultState = {
      selectedDisplayColumns: cols,
      selectedColorBy:
        lsState.selectedColorBy && lsState.selectedColorBy.key
          ? lsState.selectedColorBy
          : { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
      open: lsState.open || {},
      selectedGroup1: _validateGroup(selectedGroup1),
      selectedGroup2: _validateGroup(selectedGroup2),
      showTooltip: !!lsState.showTooltip,
      selectedZoom:
        lsState.selectedZoom && lsState.selectedZoom.id && ZOOM_OPTIONS.some(o => o.id === lsState.selectedZoom.id)
          ? lsState.selectedZoom
          : DEFAULT_ZOOM_OPTION,
      selectedHighlight: lsState.selectedHighlight ? lsState.selectedHighlight : DEFAULT_HIGHLIHT_OPTION,
      selectedRounding: lsState.selectedRounding ? lsState.selectedRounding : DEFAULT_ROUNDING_OPTION,
      searchStr: '',
      portfolioMode,
      localMode: false,
      showStories: orgIntegrations.length > 0 ? lsState.showStories : null,
      ...defaultValues,
    };

    updateLsState(defaultState, false);
  }, [selectedGroup1, selectedGroup2, displayLayer]);

  useDeepEffect(() => {
    const cols = (lsState.selectedDisplayColumns || defaultDisplayColumns).map(c => {
      const col = displayColumnOptions.find(o => o.id === c.id);

      if (!col) return col;

      return {
        ...col,
        ...(col.editor && col.editor.field === 'select' && metadata[`${col.id}s`]
          ? { editor: { ...col.editor, options: metadata[`${col.id}s`] } }
          : {}),
      };
    });

    setDisplayColumns(cols);
  }, [lsState.selectedDisplayColumns, metadata]);

  return {
    ...lsState,
    searchStr: lsState.searchStr || globalSearchString,
    selectedDisplayColumns: displayColumns.filter(Boolean),
  };
};

export default useTimelineLsState;
