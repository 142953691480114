import checkIfUserIsAdmin from 'utils/checkIfUserIsAdmin.js';

import permissions from './permissions.js';
import { EDITOR_USER, MANAGER_USER, EXTERNAL_REQUESTOR } from '@dragonboat/permissions';

/**
 *
 * @param {*} permission
 * @param {*} role
 */
const checkRolePermission = (permission, currentUser, organization, metadata) => {
  const permissionRoles = permissions[permission];

  let result = false;

  if (permissionRoles && currentUser) {
    result = permissionRoles.includes(currentUser.role_id);
  }

  if (!result) return false;

  const roleIsManagerOrEditor = [EDITOR_USER, MANAGER_USER].includes(currentUser.role_id);

  if (metadata && roleIsManagerOrEditor && organization.editor_can_create_metadata) {
    return Boolean(organization.editor_can_create_metadata[metadata]);
  }

  return true;
};

const checkIfUserIsExternalRequestor = user => user?.role_id === EXTERNAL_REQUESTOR;

export { checkRolePermission, checkIfUserIsAdmin, checkIfUserIsExternalRequestor };
