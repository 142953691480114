export const CHART_LAYER_PLANNED_ALLOCATION = 'showPlannedAllocation';
export const CHART_LAYER_REPORTED_ALLOCATION = 'showReportedAllocation';
export const CHART_LAYER_COMPLETED_ALLOCATION = 'showCompletedAllocation';
export const CHART_LAYER_ACTUAL_METRIC = 'showActualMetric';
export const CHART_LAYER_TARGET_METRIC = 'showTargetMetric';

export const mapToObject = chartVisibleLayers => ({
  showPlanned: chartVisibleLayers.some(layer => layer === CHART_LAYER_PLANNED_ALLOCATION),
  showCompleted: chartVisibleLayers.some(layer => layer === CHART_LAYER_COMPLETED_ALLOCATION),
  showReported: chartVisibleLayers.some(layer => layer === CHART_LAYER_REPORTED_ALLOCATION),
  showActual: chartVisibleLayers.some(layer => layer === CHART_LAYER_ACTUAL_METRIC),
  showTarget: chartVisibleLayers.some(layer => layer === CHART_LAYER_TARGET_METRIC),
});
