import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'rc-slider';
import { defaultTo, isNil } from 'ramda';
import Switch from '@material-ui/core/Switch';
import RefreshIcon from '@material-ui/icons/Cached';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { IDEA_LAYER, BET_LAYER } from 'store/projects/constants';
import { materialColors } from 'design-system/themes/default';
import formatPercent from 'design-system/utils/formatPercent';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import FluidField from 'design-system/molecules/FluidField/index';
import Loading from 'design-system/atoms/Loading/Loading';
import ProgressValueField from 'design-system/molecules/ProgressValueField/index';

import { syncIntegration } from 'store/integrations';
import { getIdeasIntegrations } from 'store/organization/selectors';
import useSinglePurposeSocket from 'hooks/useSinglePurposeSocket';
import formatDateTime from 'utils/dates/formatDateTime';
import { PROGRESS_PERCENTAGE_DECIMAL_PLACES } from 'constants/common';
import { AUTO_TYPE, MANUAL_TYPE } from 'constants/projects';

const defaultToAutoType = defaultTo(AUTO_TYPE);

const SyncJiraIntegrationsProgressButton = ({ selectedProject, projectIntegrations }) => {
  const dispatch = useDispatch();
  const orgIntegrations = useSelector(getIdeasIntegrations);
  const hasJira = !!selectedProject.jira;
  const hasIntegration = projectIntegrations && projectIntegrations.length > 0;
  const socket = useSinglePurposeSocket();

  if (!hasJira && !hasIntegration) return <span />;

  let orgIntegration;

  if (hasJira) orgIntegration = orgIntegrations.find(i => i.id === selectedProject.jira.orgIntegration_id);
  if (hasIntegration) orgIntegration = orgIntegrations.find(i => i.id === projectIntegrations[0].org_integration_id);

  const [isWaitingForRefresh, setIsWaitingForRefresh] = React.useState(false);
  const getArgs = () => ({
    ...(orgIntegration.integrationType === 'JIRA' ? { epicKey: selectedProject.jira.key } : {}),
    projectId: selectedProject.id,
  });

  return isWaitingForRefresh ? (
    <Loading />
  ) : (
    <ButtonIcon
      variant="primary"
      onClick={() => {
        const p = dispatch(syncIntegration(orgIntegration.id, orgIntegration.integrationType, getArgs(), socket));

        if (p instanceof Promise) {
          setIsWaitingForRefresh(true);
          p.finally(() => {
            setIsWaitingForRefresh(false);
            // if (socket) socket.destructor();
          });
        }
      }}
      small
    >
      <RefreshIcon style={{ fontSize: 20 }} />
    </ButtonIcon>
  );
};

const Progress = styled(({ value, customSlider, props, currentValue, progressType, disabled }) => {
  const { integrationProgress, layer } = props.selectedProject || {};
  const progressValue = progressType === AUTO_TYPE ? currentValue || value : value;

  const innerValue = useMemo(() => {
    return formatPercent(progressValue, PROGRESS_PERCENTAGE_DECIMAL_PLACES);
  }, [progressValue]);

  return (
    <ProgressContainer>
      <div className="progressbar-container">
        <ProgressBarContainer progressType={progressType} style={{ background: progressType === MANUAL_TYPE && 'transparent' }}>
          {progressType === MANUAL_TYPE && customSlider}
          {progressType === AUTO_TYPE && <ProgressBar value={innerValue} />}
        </ProgressBarContainer>
        <ProgressValueField
          canEditProgressText={props.canEditProgressText}
          disabledEditableText={props.disabledEditableText}
          onChange={props.onChange}
          progressValue={progressValue}
          updateIsEditing={props.updateIsEditingText}
        />
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={progressType === MANUAL_TYPE}
              onChange={() => props.changeProgressType(progressType === AUTO_TYPE ? MANUAL_TYPE : AUTO_TYPE)}
              disabled={disabled}
            />
          }
          label="Manual"
          labelPlacement="start"
          style={{ maxHeight: 22 }}
        />
      </div>
      {[IDEA_LAYER, BET_LAYER].includes(layer) && props.hasProjectIntegrations && progressType === AUTO_TYPE && (
        <>
          <div className="auto-container">
            {integrationProgress && (
              <Lower>
                Total: <span>{integrationProgress.issuesTotal}</span>,&nbsp; In Progress:{' '}
                <span className="material--blue">{integrationProgress.issuesInProgress}</span>,&nbsp; Closed:{' '}
                <span className="material--green">{integrationProgress.issuesClosed}</span>
              </Lower>
            )}
            &nbsp;
            <SyncJiraIntegrationsProgressButton
              selectedProject={props.selectedProject}
              projectIntegrations={props.projectIntegrations}
            />
          </div>
          <div className="auto-container">
            {integrationProgress?.date ? (
              <Lower>
                Updated at: <span>{formatDateTime(integrationProgress.date)}</span>
              </Lower>
            ) : null}
          </div>
        </>
      )}
    </ProgressContainer>
  );
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressBarContainer = styled.div`
  position: relative;
  border-radius: 8px;
  flex-grow: 1;
  height: ${props => (props.progressType === MANUAL_TYPE ? 24 : 8)}px;
  margin-right: 8px;
  ${props => props.progressType === MANUAL_TYPE && 'margin-top: 5px;'}
  overflow: visible;
  background: #eaeaea;
`;
const ProgressBar = styled.div`
  border-radius: 8px;
  height: 8px;
  width: ${props => props.value};
  max-width: 100%;

  background: #00b1f3;
`;

const ProgressContainer = styled.div`
  flex-direction: column;
  align-items: center;
  padding-top: 4px;

  .manual-auto-switch {
    display: flex;
    justify-content: center;
  }

  .progressbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 22px;

    .rc-slider-disabled {
      background-color: unset;
    }
  }

  .auto-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
`;

const Lower = styled.div`
  font-size: 11px;
  margin: 8px 0;
  color: ${materialColors.darkerGray};

  span {
    font-size: 12px;
  }
`;

const FluidProgressField = props => {
  const [isEditingProgressText, setIsEditingText] = useState(false);

  const { selectedProject, progressType } = props;

  const currentProgressType = useMemo(() => {
    if (isNil(selectedProject)) {
      return defaultToAutoType(progressType);
    }

    return selectedProject?.progress_type === MANUAL_TYPE ? MANUAL_TYPE : AUTO_TYPE;
  }, [selectedProject, progressType]);

  return (
    <FluidField
      {...props}
      editorRenderer={({ value, save, onChange, cancel, currentValue, disableEdit }) => {
        if (props.value !== value && currentProgressType !== MANUAL_TYPE) onChange(props.value);

        // if user updates manual progress text, need to update slider
        if (props.value !== value && props.canEditProgressText && isEditingProgressText) {
          onChange(props.value);
          setIsEditingText(false);
        }

        return (
          <ClickAwayListener onClickAway={() => currentProgressType === MANUAL_TYPE && save()}>
            <Progress
              value={value}
              currentValue={currentValue || 0}
              disabled={disableEdit}
              customSlider={
                <Slider
                  min={0}
                  max={1}
                  step={0.01}
                  value={value}
                  onChange={onChange}
                  onAfterChange={_ => save()}
                  onKeyDown={e => {
                    e.stopPropagation();

                    if (e.which === 9 || e.which === 13) save();
                    if (e.which === 27) cancel();
                  }}
                  onBlur={_ => save()}
                  trackStyle={{ backgroundColor: '#00b1f3', height: 8 }}
                  handleStyle={{ borderColor: '#00b1f3', top: 7 }}
                  railStyle={{ backgroundColor: '#eaeaea', height: 8 }}
                  disabled={disableEdit}
                />
              }
              props={{
                ...props,
                updateIsEditingText: setIsEditingText,
              }}
              save={save}
              progressType={currentProgressType}
            />
          </ClickAwayListener>
        );
      }}
    />
  );
};

FluidProgressField.propTypes = {
  ...FluidField.propTypes,
  changeProgressType: PropTypes.func,
};

export default FluidProgressField;
