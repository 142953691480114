import styled from 'styled-components';

import Text from 'design-system/atoms/Text/index';

import theme from 'design-system/theme';

const DialogWrapper = styled.div`
  min-width: 600px;
`;

const ErrorText = styled(Text)`
  color: ${theme.palette.text.error};
`;

export { DialogWrapper, ErrorText };
