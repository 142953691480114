import { defaultTo, propOr } from 'ramda';

import { FIELD_KEY_TO_FILTER_KEY_MAPPER } from '../constants';

const defaultAsEmptyArray = defaultTo([]);

const buildItemFilter = (filters, item, entity) => {
  const entityId = String(entity?.id || entity?.value || 'null');
  const filterKey = propOr(item.key, item.key)(FIELD_KEY_TO_FILTER_KEY_MAPPER);

  const newFilter = {
    key: filterKey,
    values: [entityId],
  };

  if (item.filterCustomKey) {
    newFilter.customKey = item.filterCustomKey;
  }

  const currentFilter = defaultAsEmptyArray(filters).find(f => f.key === filterKey && f.values.includes(entityId));

  return { newFilter, currentFilter };
};

export { buildItemFilter };
