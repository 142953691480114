import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';

import IdeaIcon from 'design-system/atoms/IdeaIcon/index';
import InitiativeIcon from 'design-system/atoms/InitiativeIcon/index';
import BetIcon from 'design-system/atoms/BetIcon/index';

import { materialColors } from 'design-system/themes/default';

const Milestone = styled.div`
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: #934fea;
  margin-right: 9px;
  margin-left: 3px;
  position: relative;
  top: -10px;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    left: -9px;
    top: 9px;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-top-color: #934fea;
  }
`;

const ProjectType = ({ type, layer, isMilestone, style, color, className }) => {
  const mapTypeToComp = {
    idea: IdeaIcon,
    initiative: InitiativeIcon,
    bet: BetIcon,
  };

  const mapTypeToColor = {
    milestone: materialColors.mediumPurple,
    idea: materialColors.downy,
    initiative: materialColors.mediumPurple,
    bet: materialColors.curiousBlue,
  };

  let Icon = null;

  if ((type && type === 'milestone') || isMilestone) {
    return <Milestone style={style} />;
  } else if (type) {
    Icon = mapTypeToComp[type];
  } else {
    switch (layer) {
      case INITIATIVE_LAYER:
        Icon = InitiativeIcon;
        type = 'initiative';
        break;
      case BET_LAYER:
        Icon = BetIcon;
        type = 'bet';
        break;
      case IDEA_LAYER:
        Icon = IdeaIcon;
        type = 'idea';
        break;
      default:
        break;
    }
  }

  if (!Icon && type !== 'milestone') {
    return null;
  }

  return (
    <StyledWrapper className={className} data-testid={`${type}-icon`}>
      {type === 'milestone' ? <Milestone style={style} /> : <Icon style={{ color: color || mapTypeToColor[type], ...style }} />}
    </StyledWrapper>
  );
};

ProjectType.propTypes = {
  type: PropTypes.oneOf(['idea', 'initiative']),
};

export default ProjectType;

const StyledWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  max-height: 20px;
`;
