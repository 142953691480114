import React, { Fragment, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import theme, { spacing } from 'design-system/theme';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';

import StackedBarChartIcon from 'design-system/atoms/StackedBarChartIcon';

import IdeasCycleDeliverableList from 'containers/IdeasCycleDeliverableList';

import IdeasListControlsBar from 'containers/IdeasList/IdeasListControlsBar';
import useIdeasPDLCData from 'routes/Ideas/IdeasPDLCView/hooks/useIdeasPDLCData';
import HeatmapLightbox from 'routes/Ideas/IdeasPDLCView/components/HeatmapLightbox';
import useHeatmapLightbox from 'routes/Ideas/IdeasPDLCView/hooks/useHeatmapLightbox';

import useSystemFields from 'hooks/useSystemFields';
import useEnrichedSelectedGroupsFromState from 'hooks/grid/useGridSelectedGroups';
import useLoadProjectsForTransactionPages from 'hooks/projects/useLoadProjectsForTransactionPages';

import useUpsellPage from 'hooks/useUpsellPage';
import useSubscribeNewData from 'hooks/useSubscribeNewData';

import { usePortfolioMode } from 'store/filters/selectors';
import { getGridConfigValue } from 'store/grids/selectors';
import { getOrgHasJiraIntegrated, getOrgHasMultipleIntegrations } from 'store/organization/selectors';

import { PDLC } from 'store/grids/constants';
import { topics } from 'constants/topics';
import { PAGE_SIDE_MARGIN } from 'constants/common';
import { IDEAS_PDLC_PAGE } from 'constants/filters/pages';
import { IDEAS_GRID_VIEWS, isEstimatesView } from 'constants/ideas';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import LocalFilterDropdown from './components/LocalFilterDropdown/LocalFilterDropdown';

import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';
import useGenerateAIRelaseNotes from 'features/AIReleaseNotes/hooks/useGenerateAIRelaseNotes';

const pageId = IDEAS_PDLC_PAGE;
const viewType = PDLC;

const IdeasPDLCView = ({ viewsDropdownTabs }) => {
  const [grid, setGrid] = useState(null);
  const portfolioMode = useSelector(state => usePortfolioMode(state));
  const showMyItemsOnly = useSelector(state => getGridConfigValue(state, viewType, 'showMyItemsOnly'));
  const { selectedGroup1, selectedGroup2, selectedGroup3 } = useEnrichedSelectedGroupsFromState(viewType);
  const [getSystemFieldName] = useSystemFields();
  const { isHeatmapLightboxOpen, openHeatmapLightbox, closeHeatmapLightbox } = useHeatmapLightbox();
  const UpsellPdlc = useUpsellPage(IDEAS_PDLC_PAGE);

  const { canView } = usePermissions();

  const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);
  const hasLocalFilterByDeliverables = useFeatureFlags([FeatureFlags.HAS_PDLC_FILTER_BY_DELIVERABLE]);

  const { hasReleaseNotesFeature, releaseNotes } = useGenerateAIRelaseNotes();

  useSubscribeNewData(
    [
      'app',
      'projects',
      'tasks',
      'estimates',
      'phases',
      'roadmaps',
      topics.OBJECTIVES,
      topics.KEY_RESULTS,
      topics.METRICS,
      'users',
      'customFields',
      'timeframes',
      'priorities',
    ],
    false,
  );

  // In the ideas grid the withIntegrations must be forced in order to display the integration key in the grid
  const hasJiraIntegration = useSelector(getOrgHasJiraIntegrated);
  const orgHasMultipleIntegrations = useSelector(getOrgHasMultipleIntegrations);

  useLoadProjectsForTransactionPages(pageId, {}, { withIntegrations: !hasJiraIntegration || orgHasMultipleIntegrations });

  const data = useIdeasPDLCData({
    viewType,
    portfolioMode,
    pageId,
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    getSystemFieldName,
    showMyItemsOnly,
  });

  const _onGridReady = params => setGrid({ gridApi: params.api, columnApi: params.columnApi });
  const _getGridApi = () => grid;

  const availableViews = portfolioMode ? IDEAS_GRID_VIEWS.filter(v => !isEstimatesView(v)) : IDEAS_GRID_VIEWS;

  const renderAfterViewAdditionalButtons = useCallback(
    () => (
      <ButtonIcon title={`${getSystemFieldName('pdlc')} heatmap`} onClick={openHeatmapLightbox} data-cy="pdlc-heatmap-chart">
        <StackedBarChartIcon fill={theme.palette.text.lightGrey} />
      </ButtonIcon>
    ),
    [getSystemFieldName, openHeatmapLightbox],
  );

  const renderBeforeViewAdditionalButtons = (Wrapper = Fragment) => (
    <Wrapper>
      <LocalFilterDropdown />
    </Wrapper>
  );

  if (UpsellPdlc) {
    return <UpsellPdlc />;
  }

  return (
    <>
      {hasReleaseNotesFeature && releaseNotes && (
        <EditorWrapper>
          <StyledFluidRemirrorWYSIWYGEditor
            key="release-notes"
            value={releaseNotes}
            content={releaseNotes}
            placeholder=""
            disableEdit={false}
            onChange={() => {}}
            smallInput
          />
        </EditorWrapper>
      )}
      {shouldDisplayControlsBar ? (
        <IdeasListControlsBar
          viewType={viewType}
          availableViews={availableViews}
          pageId={pageId}
          numberOfRows={data.length}
          getGridApi={_getGridApi}
          renderBeforeViewAdditionalButtons={hasLocalFilterByDeliverables && renderBeforeViewAdditionalButtons}
          renderAfterViewAdditionalButtons={renderAfterViewAdditionalButtons}
          showMyItemsOnly={showMyItemsOnly}
          showMyItemsTitle="Show My Deliverables"
          hasShowMyItemFilter={!hasLocalFilterByDeliverables}
          showEstimatesFields={false}
          viewsDropdownTabs={viewsDropdownTabs}
          usePortolioMode
          showPdlcFields
          showAdditionalFields // to remove once new grid has additional fields implemented
          showCustomFields
          hasViews
          hasImport
          hasBulkUpdate
          hasBulkDelete
          hasGroupBy
        />
      ) : (
        <EmptyHeight />
      )}
      <IdeasCycleDeliverableList viewType={viewType} data={data} onGridReady={_onGridReady} />
      <HeatmapLightbox open={isHeatmapLightboxOpen} onClose={closeHeatmapLightbox} />
    </>
  );
};

const EmptyHeight = styled.div`
  height: 48px;
`;

const EditorWrapper = styled.div`
  margin: ${spacing(3)}px ${PAGE_SIDE_MARGIN}px;
`;

const StyledFluidRemirrorWYSIWYGEditor = styled(FluidRemirrorWYSIWYGEditor)`
  &&&& {
    padding-left: 0;
    padding-right: 0;

    .remirror-editor-wrapper {
      max-height: 40vh;
      overflow-y: auto;
    }

    .remirror-editor {
      color: ${({ theme }) => theme.palette.text.primary};
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;

      h1,
      h2,
      h3 {
        color: inherit;
      }
    }
  }
`;

export default IdeasPDLCView;
