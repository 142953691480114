import React, { createContext, useState, useContext } from 'react';

import AddWidgetLightbox from '../AddWidgetLightbox';
import EditWidgetLightbox from '../EditWidgetLightbox';

const WidgetLightboxContext = createContext({});

const WidgetLightboxProvider = ({ children }) => {
  const [currentDashboardId, setCurrentDashboardId] = useState(null);
  const [addWidgetLightboxOpen, setAddWidgetLightboxOpen] = useState(false);
  const [widgetIdToEdit, setWidgetIdToEdit] = useState(null);

  const openAddWidgetLightbox = dashboardId => {
    setCurrentDashboardId(dashboardId);
    setAddWidgetLightboxOpen(true);
  };
  const closeAddWidgetLightbox = () => {
    setCurrentDashboardId(null);
    setAddWidgetLightboxOpen(false);
  };

  const openEditWidgetLightbox = (dashboardId, widgetId) => {
    setCurrentDashboardId(dashboardId);
    setWidgetIdToEdit(widgetId);
  };
  const closeEditWidgetLightbox = () => {
    setCurrentDashboardId(null);
    setWidgetIdToEdit(null);
  };

  return (
    <WidgetLightboxContext.Provider
      value={{ openAddWidgetLightbox, openEditWidgetLightbox, widgetIdToEdit, dashboardId: currentDashboardId }}
    >
      {children}
      <AddWidgetLightbox isOpen={addWidgetLightboxOpen} onClose={closeAddWidgetLightbox} dashboardId={currentDashboardId} />
      <EditWidgetLightbox
        isOpen={!!widgetIdToEdit}
        onClose={closeEditWidgetLightbox}
        widgetIdToEdit={widgetIdToEdit}
        dashboardId={currentDashboardId}
      />
    </WidgetLightboxContext.Provider>
  );
};

const useWidgetLightboxContext = () => useContext(WidgetLightboxContext);

export { WidgetLightboxProvider };

export default useWidgetLightboxContext;
