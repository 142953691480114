import { fromJS } from 'immutable';

import deserializeProject from './deserializeProject';
import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER, layers } from '../constants';

const collectionsTypes = ['rows', 'initiatives', 'bets'];

export const getCollectionName = (name, storeAsFilters) => {
  const collectionSuffix = storeAsFilters ? 'Filters' : '';

  return `${name}${collectionSuffix}`;
};

export const getCollectionNameFromLayer = (layer, getAsFilters = false) => {
  switch (layer) {
    case IDEA_LAYER:
      return getCollectionName('rows', getAsFilters);
    case INITIATIVE_LAYER:
      return getCollectionName('initiatives', getAsFilters);
    case BET_LAYER:
      return getCollectionName('bets', getAsFilters);
    default:
      return getCollectionName('rows', getAsFilters);
  }
};

export const getCollectionNameFromProject = (project, storeAsFilters) => {
  switch (project.layer) {
    case '0':
      return getCollectionName('rows', storeAsFilters);
    case '1':
      return getCollectionName('initiatives', storeAsFilters);
    case '2':
      return getCollectionName('bets', storeAsFilters);
    default:
      return getCollectionName('rows', storeAsFilters);
  }
};

export const processProjectsCollectionToStore = collection => {
  return fromJS(collection.map(deserializeProject));
};

/*
Gets the payload from fetching/creating/updating multiple or single
project/s and returns the payload ready to be stored on redux store
*/
export const processPayloadToStore = (
  payload,
  state,
  storeAsFilters = false,
  selectedLayers = [IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER],
) => {
  const [currentRows, currentInitiatives, currentBets] = [
    getCollectionName('rows', storeAsFilters),
    getCollectionName('initiatives', storeAsFilters),
    getCollectionName('bets', storeAsFilters),
  ].map(key => (state && state[key] ? state[key].toJS() : []));

  let data = [];

  // Array of projects
  if (payload && payload instanceof Array) {
    data = payload.reduce(
      (acc, proj) => {
        const collectionName = getCollectionNameFromProject(proj, storeAsFilters);

        return {
          ...acc,
          [collectionName]: [...acc[collectionName].filter(p => p.id !== proj.id), proj],
        };
      },
      {
        [getCollectionName('rows', storeAsFilters)]: currentRows,
        [getCollectionName('initiatives', storeAsFilters)]: currentInitiatives,
        [getCollectionName('bets', storeAsFilters)]: currentBets,
      },
    );
    // Single Project
  } else if (payload && payload instanceof Object) {
    const collectionName = getCollectionNameFromProject(payload);

    data = {
      ...state,
      [collectionName]: [...state[collectionName], payload],
    };
  }

  if (!selectedLayers) selectedLayers = [IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER];

  return layers.reduce((acc, key, i) => {
    if (selectedLayers.filter(l => +l === i).length) {
      const cName = getCollectionNameFromLayer(key, storeAsFilters);
      const collectionData = data[cName];

      return {
        ...acc,
        [cName]: processProjectsCollectionToStore(collectionData),
      };
    }
    return acc;
  }, {});
};

export const findProjectInAllCollections = (state, queryFunction) => {
  const allProjects = [
    ...state[getCollectionName('rows', false)].toJS(),
    ...state[getCollectionName('initiatives', false)].toJS(),
    ...state[getCollectionName('bets', false)].toJS(),
  ];

  let project = allProjects.find(queryFunction);

  if (!project) {
    // Can't find project in all projects
    // Let's search on the 'forFilters' collections which include all projects with no filters
    const allProjectsWithFilters = [
      ...state[getCollectionName('rows', true)].toJS(),
      ...state[getCollectionName('initiatives', true)].toJS(),
      ...state[getCollectionName('bets', true)].toJS(),
    ];

    project = allProjectsWithFilters.find(queryFunction);
  }

  return project;
};

export const updateProjectOnCollectionsUsingMethod = (state, method) => {
  return collectionsTypes
    .filter(coll => state[coll])
    .reduce((curr, coll) => {
      const collection = getCollectionName(coll, false);
      const collectionForFilters = getCollectionName(coll, true);

      return {
        ...curr,
        ...{
          [collection]: method(state[collection], collection),
          [collectionForFilters]: method(state[collectionForFilters], collectionForFilters),
        },
      };
    }, {});
};

/**
 * @function processTasksToStore updates store tasks based on the fetch tasks response
 * @param  {Array} currentTasks List of current tasks
 * @param  {Array} newTasks  List of new tasks
 * @return {Array} New task list updated
 */
export const processTasksToStore = (currentTasks = [], newTasks = []) => [
  ...currentTasks.filter(t => !newTasks.some(({ id }) => id === t.id)),
  ...newTasks,
];
