import { useSelector } from 'react-redux';

import { getProjectsCustomFields } from 'store/customFields/selectors';

const useCustomFields = () => {
  const customFields = useSelector(getProjectsCustomFields);

  return {
    customFields,
  };
};

export default useCustomFields;
