import React from 'react';

import AllocationViewIcon from 'design-system/atoms/AllocationViewIcon/index';
import BoardViewIcon from 'design-system/atoms/BoardViewIcon/index';
import GanttViewIcon from 'design-system/atoms/GanttViewIcon/index';
import ListViewIcon from 'design-system/atoms/ListViewIcon/index';
import ProgressViewIcon from 'design-system/atoms/ProgressViewIcon/index';
import SnapshotViewIcon from 'design-system/atoms/SnapshotViewIcon/index';
import StaffingViewIcon from 'design-system/atoms/StaffingViewIcon/index';
import SummaryViewIcon from 'design-system/atoms/SummaryViewIcon/index';
import SwimlaneViewIcon from 'design-system/atoms/SwimlaneViewIcon/index';
import PDLCIcon from 'design-system/atoms/PDLCIcon/index';

import CircleChart from 'design-system/atoms/DragonIcons/CircleChart';
import theme from 'design-system/theme';
import { IDEAS_PDLC_PAGE } from 'constants/filters';

export default (viewType, fill = theme.palette.text.primary, height = 16, width = 16) => {
  switch (viewType) {
    case 'allocationReport':
    case 'goals:allocation':
      return <AllocationViewIcon fill={fill} />;
    case 'ideasBoard':
      return <BoardViewIcon fill={fill} />;
    case 'ideasGrid:flat':
    case 'ideasGrid:ideas':
    case 'ideasGrid:estimates':
    case 'goals:grid':
    case 'metrics:grid':
      return <ListViewIcon fill={fill} />;
    case IDEAS_PDLC_PAGE:
      return <PDLCIcon fill={fill} />;
    case 'manage':
    case 'forecast':
      return <GanttViewIcon fill={fill} />;
    case 'progress':
      return <ProgressViewIcon fill={fill} />;
    case 'roadmap':
      return <SwimlaneViewIcon fill={fill} />;
    case 'snapshot':
    case 'goals:snapshot':
      return <SnapshotViewIcon fill={fill} />;
    case 'staffing':
      return <StaffingViewIcon fill={fill} />;
    case 'summary':
    case 'goals:summary':
      return <SummaryViewIcon fill={fill} />;
    default:
      return <CircleChart fill={fill} width={width} height={height} />;
  }
};
