import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';

import usePageFilters from 'hooks/filters/usePageFilters';

import makeChangeHandlerForDateRange from 'utils/makeChangeHandlerForDateRange';
import { DASHBOARDS_PAGE } from 'constants/filters/pages';
import { getExpectedProgressForDateRange } from '../utils';

export default function useGetProgressDateRange({
  dashboardState = {},
  defaultReportData = {},
  fetchUrl = '',
  updateDateRange,
}) {
  const [reportData, setReportData] = useState(defaultReportData);

  const { filtersForApiByLevels: projectsFilter } = usePageFilters(DASHBOARDS_PAGE);

  const { progressStartDate: startDate, progressEndDate: endDate } = dashboardState;

  const formatDateForFetch = date => new Date(date);

  const updateStartDate = useCallback(val => updateDateRange && updateDateRange(val, endDate), [endDate, updateDateRange]);
  const updateEndDate = useCallback(val => updateDateRange && updateDateRange(startDate, val), [startDate, updateDateRange]);

  const handleDateRangeChange = useMemo(
    () => makeChangeHandlerForDateRange(updateStartDate, updateEndDate),
    [updateStartDate, updateEndDate],
  );

  const fetchData = useCallback(async () => {
    const { data } = await axios.post(fetchUrl, { projectsFilter });

    setReportData(data);
  }, [fetchUrl, projectsFilter, setReportData]);

  const expectedProgress = useMemo(() => {
    return getExpectedProgressForDateRange(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if (fetchUrl) {
      fetchData();
    }
  }, [projectsFilter]);

  return {
    endDate,
    expectedProgress,
    formattedEndDate: formatDateForFetch(endDate),
    formattedStartDate: formatDateForFetch(startDate),
    handleDateRangeChange,
    reportData,
    startDate,
  };
}
