import React, { useReducer, useEffect } from 'react';
import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import OptionsFormRow from 'design-system/molecules/OptionsFormRow/index';


const AddOptionRow = ({ options, onChange }) => {
  const initialState = options || {};

  const reducer = (state, action) => {
    switch (action.type) {
      case 'ADD_OPTION':
        return { ...state, [`option#${uuidv4()}`]: '' };
      case 'REMOVE_OPTION': {
        return omit(state, action.key);
      }
      case 'CHANGE_OPTION':
        return { ...state, [action.key]: action.text };
      case 'CLEAN_EMPTY': {
        return pickBy(state, val => val);
      }
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (onChange) {
      onChange(pickBy(state, option => option));
    }
  }, [state]);

  const makeOnChange = key => e => dispatch({ type: 'CHANGE_OPTION', key, text: e.target.value });
  const makeOnRemoveOption = key => () => dispatch({ type: 'REMOVE_OPTION', key });
  const _addOption = () => dispatch({ type: 'ADD_OPTION' });
  const [_onBlur] = useDebouncedCallback(() => {
    dispatch({ type: 'CLEAN_EMPTY' });
  }, 100);

  return (
    <Wrapper>
      <Label>Options</Label>
      {Object.entries(state).map(([key, value], index) => (
        <>
          <Row
            value={value}
            onChange={makeOnChange(key)}
            onKeyPress={e => (e.key === 'Enter' ? _addOption() : null)}
            focused={!value && index === Object.keys(state).length - 1}
            onBlur={_onBlur}
            onRemoveOption={makeOnRemoveOption(key)}
          />
        </>
      ))}
      <AddOptionButton color="primary" onClick={_addOption}>
        + Add Option
      </AddOptionButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &&&& {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
`;

const Row = styled(OptionsFormRow)`
  &&&& {
    margin-left: 10px;
  }
`;

const AddOptionButton = styled(Button)`
  &&&& {
    width: 100px;
    margin-top: 10px;
    text-transform: initial;
  }
`;

const Label = styled(Typography)`
  &&&& {
    font-weight: 500;
    padding: 10px;
  }
`;

export default AddOptionRow;
