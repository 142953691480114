import axios from 'axios';

import throwRequestError from '../utils/throwRequestError';
import {
  UPDATE_ROADMAP_ID,
  DELETE_ROADMAP,
  MERGE_ROADMAPS,
  CREATE_ROADMAPS,
  UPDATE_ROADMAPS,
  UNDO_CREATE_ROADMAPS,
  UNDO_UPDATE_ROADMAPS,
  BULK_DELETE_ROADMAPS,
  UNDO_BULK_DELETE_ROADMAPS,
  UPDATE_PRODUCT_ID,
  MOVE_PRODUCT_TO_ROADMAP,
  DELETE_PRODUCT,
  MERGE_PRODUCTS,
  BULK_DELETE_PRODUCTS,
  UNDO_BULK_DELETE_PRODUCTS,
  MOVE_PRODUCT_TO_PRODUCT,
  UPDATE_PRODUCTS,
} from './types';

import { clearSelectedItems } from 'store/grids';
import { ROADMAPS } from 'store/grids/constants';

import bulkCreateAction from 'store/utils/factory/bulkCreateAction';
import bulkUpdateAction from 'store/utils/factory/bulkUpdateAction';
import bulkDeleteAction from 'store/utils/factory/bulkDeleteAction';

import { updateRoadmapRowOrder, updateProductRowOrder, fetchRoadmaps } from './actions';
import { METADATA_LEVELS } from 'constants/common';

export const mergeProducts = (productsIdsToMerge, productId) => {
  return async dispatch => {
    const payload = await axios
      .post(`/api/products/merge/${productId}`, {
        productsIdsToMerge,
      })
      .then(response => {
        return response.data;
      })
      .catch(throwRequestError);

    await dispatch({
      type: MERGE_PRODUCTS,
      payload,
    });

    dispatch(clearSelectedItems(ROADMAPS));

    return dispatch(fetchRoadmaps());
  };
};

export const updateProductById = (id, update) => {
  return dispatch => {
    const payload = axios
      .put(`/api/products/${id}`, update)
      .then(response => response.data)
      .catch(throwRequestError);

    dispatch({
      type: UPDATE_PRODUCT_ID,
      payload,
    });

    return payload;
  };
};

export function switchProductsRowOrder(productId1, productId2, _, position) {
  return dispatch => {
    return axios
      .put(`/api/products/rowOrder/${productId1}/${productId2}`, { position })
      .then(response => {
        const product = response.data;

        dispatch(updateProductRowOrder(product));
      })
      .catch(throwRequestError);
  };
}

export function moveRoadmapToRoadmap(roadmap, overRoadmap, position) {
  return async dispatch => {
    const { id: roadmapId, level: roadmapLevel, parent_id: roadmapParentId } = roadmap;
    const { level: overRoadmapLevel } = overRoadmap;

    const isOverRoadmapCorplevel = overRoadmapLevel === METADATA_LEVELS.LEVEL_CORP;
    const overRoadmapParentId = isOverRoadmapCorplevel ? overRoadmap.id : overRoadmap.parent_id;
    const isRoadmap1Level = roadmapLevel === METADATA_LEVELS.LEVEL_1;
    const isTargetNodeValidParent =
      (isOverRoadmapCorplevel && isRoadmap1Level) || (!isOverRoadmapCorplevel && !!overRoadmapParentId);

    if (isTargetNodeValidParent && roadmapParentId !== overRoadmapParentId) {
      const updateData = { parent_id: overRoadmapParentId };

      await updateRoadmapById(roadmapId, updateData)(dispatch);
    }
  };
}

export function moveProductToRoadmap(product, roadmap, overProduct, position) {
  return async dispatch => {
    let roadmapId;
    let parentId;
    let productId2;

    if (overProduct) {
      if (overProduct.level === 0 && product.level === 1) {
        parentId = overProduct.id;
      } else if (overProduct.level === 1 && product.level === 1) {
        parentId = overProduct.parent_id;
      }
      roadmapId = overProduct.roadmap_id;
      productId2 = overProduct.id;
    } else {
      roadmapId = roadmap.id;
      productId2 = roadmap.products && roadmap.products.length ? roadmap.products[0].id : null;
      position = 'top';
    }

    let firstPayload;
    let secondPayload;

    try {
      if (!(roadmapId === product.roadmap_id && (!parentId || parentId === product.parent_id))) {
        firstPayload = await axios.put(`/api/products/${product.id}`, {
          ...product,
          roadmap_id: roadmapId,
          parent_id: parentId,
        });
      }
      if (productId2) {
        secondPayload = await axios.put(`/api/products/rowOrder/${product.id}/${productId2}`, { position });
      }
    } catch (err) {
      throwRequestError(err);
    }

    let action = MOVE_PRODUCT_TO_ROADMAP;

    if (parentId) {
      action = MOVE_PRODUCT_TO_PRODUCT;
    }

    if (firstPayload) {
      await dispatch({
        type: action,
        product: firstPayload.data,
        previousRoadmapId: product.roadmap_id,
        previousParentId: product.parent_id,
      });
    }

    if (secondPayload) {
      dispatch(updateProductRowOrder(secondPayload.data));
    }
  };
}

export const deleteProductById = id => {
  return dispatch => {
    const payload = axios
      .delete(`/api/products/${id}`)
      .then(response => response.data)
      .catch(err => throwRequestError(err, true));

    dispatch({
      type: DELETE_PRODUCT,
      payload,
    });

    return payload;
  };
};

export const updateRoadmapById = (id, update) => {
  return dispatch => {
    const payload = axios
      .put(`/api/roadmaps/${id}`, update)
      .then(response => response.data)
      .catch(throwRequestError);

    dispatch({
      type: UPDATE_ROADMAP_ID,
      payload,
    });

    return payload;
  };
};

export const deleteRoadmapById = id => {
  return dispatch => {
    const payload = axios
      .delete(`/api/roadmaps/${id}`)
      .then(response => response.data)
      .catch(err => throwRequestError(err, true));

    dispatch({
      type: DELETE_ROADMAP,
      payload,
    });

    return payload;
  };
};

export function mergeRoadmaps(roadmapsIdsToMerge, roadmapId) {
  return dispatch => {
    const payload = axios
      .post(`/api/roadmaps/merge/${roadmapId}`, {
        roadmapsIdsToMerge,
      })
      .then(response => {
        dispatch(clearSelectedItems(ROADMAPS));
        dispatch(fetchRoadmaps());

        return response.data;
      })
      .catch(throwRequestError);

    dispatch({
      type: MERGE_ROADMAPS,
      payload,
    });

    return payload;
  };
}

export function switchRoadmapsRowOrder(roadmapId1, roadmapId2, _, position) {
  return dispatch => {
    return axios
      .put(`/api/roadmaps/rowOrder/${roadmapId1}/${roadmapId2}`, { position })
      .then(response => {
        const roadmap = response.data;

        dispatch(updateRoadmapRowOrder(roadmap));
      })
      .catch(throwRequestError);
  };
}

export const createRoadmaps = bulkCreateAction(CREATE_ROADMAPS, '/api/roadmaps', {
  toastText: 'Roadmaps have been created',
  ACTION_TYPE: UNDO_CREATE_ROADMAPS,
  endpoint: '/api/roadmaps/versions/last',
  store: 'roadmaps',
});
export const updateRoadmaps = bulkUpdateAction(UPDATE_ROADMAPS, '/api/roadmaps', {
  toastText: 'Roadmaps have been changed',
  ACTION_TYPE: UNDO_UPDATE_ROADMAPS,
  endpoint: '/api/roadmaps/versions/last',
  store: 'roadmaps',
});
export const bulkDeleteRoadmaps = bulkDeleteAction(BULK_DELETE_ROADMAPS, '/api/roadmaps/', {
  toastText: 'Roadmaps have been deleted',
  ACTION_TYPE: UNDO_BULK_DELETE_ROADMAPS,
  endpoint: '/api/roadmaps/versions/last',
  store: 'roadmaps',
});
export const bulkDeleteProducts = bulkDeleteAction(BULK_DELETE_PRODUCTS, '/api/products/', {
  toastText: 'Roadmaps have been deleted',
  ACTION_TYPE: UNDO_BULK_DELETE_PRODUCTS,
  endpoint: '/api/products/versions/last',
  store: 'roadmaps',
});

export const updateProducts = bulkUpdateAction(UPDATE_PRODUCTS, '/api/products');
