import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { values, filter, keys } from 'ramda';

import { makeSelectorToMetadataByKey, filterActiveMetadata } from 'utils/metadata';
import { KEY_RESULTS, OBJECTIVES, PRODUCTS, ROADMAPS } from 'constants/common';

const useMetadataForOptions = ({ metadataKeys, corpRoadmapId, roadmapId, corpObjectiveId, objectiveId } = {}) => {
  const getSelectorsByKey = useMemo(() => makeSelectorToMetadataByKey({ keysToPick: metadataKeys }), metadataKeys);

  const metadataFromStore = useSelector(state => getSelectorsByKey(state));
  const allMetadata = useMemo(() => metadataFromStore, values(metadataFromStore));
  const filteredMetadata = useMemo(() => filterActiveMetadata(metadataFromStore), values(metadataFromStore));

  const filteredRoadmaps = useMemo(() => {
    if (!keys(filteredMetadata).includes(ROADMAPS)) return [];
    if (corpRoadmapId) return filter(roadmap => roadmap.parent_id === corpRoadmapId, filteredMetadata[ROADMAPS]);
    return filteredMetadata[ROADMAPS];
  }, [filteredMetadata, corpRoadmapId]);

  const filteredProducts = useMemo(() => {
    if (!keys(filteredMetadata).includes(PRODUCTS)) return [];
    if (roadmapId) return filter(product => product.roadmap_id === roadmapId, filteredMetadata[PRODUCTS]);
    return filter(product => filteredRoadmaps.map(({ id }) => id).includes(product.roadmap_id), filteredMetadata[PRODUCTS]);
  }, [filteredRoadmaps, roadmapId]);

  const filteredObjectives = useMemo(() => {
    if (!keys(filteredMetadata).includes(OBJECTIVES)) return [];
    if (corpObjectiveId) return filter(objective => objective.parent_id === corpObjectiveId, filteredMetadata[OBJECTIVES]);
    return filteredMetadata[OBJECTIVES];
  }, [filteredMetadata, corpObjectiveId]);

  const filteredKeyResults = useMemo(() => {
    if (!keys(filteredMetadata).includes(KEY_RESULTS)) return [];
    if (objectiveId) return filter(objective => objective.objective_id === objectiveId, filteredMetadata[KEY_RESULTS]);
    return filter(
      keyResult => filteredObjectives.map(({ id }) => id).includes(keyResult.objective_id),
      filteredMetadata[KEY_RESULTS],
    );
  }, [filteredObjectives, objectiveId]);

  const filteredOptions = useMemo(
    () => ({
      ...filteredMetadata,
      ...(keys(filteredMetadata).includes(ROADMAPS) ? { [ROADMAPS]: filteredRoadmaps } : {}),
      ...(keys(filteredMetadata).includes(PRODUCTS) ? { [PRODUCTS]: filteredProducts } : {}),
      ...(keys(filteredMetadata).includes(OBJECTIVES) ? { [OBJECTIVES]: filteredObjectives } : {}),
      ...(keys(filteredMetadata).includes(KEY_RESULTS) ? { [KEY_RESULTS]: filteredKeyResults } : {}),
    }),
    [filteredMetadata, filteredRoadmaps, filteredProducts, filteredObjectives, filteredKeyResults],
  );

  return {
    filteredOptions,
    allMetadata,
  };
};

export default useMetadataForOptions;
