import React from 'react';

import { Wrapper } from './styles';

import { INTEGRATION_AUTH_TYPES } from 'constants/integrations';
import TokenAuthConfig from './auth-types/TokenAuthConfig';
import OAuthConfig from './auth-types/OAuthConfig';

const getAuthComponent = authType => {
  switch (authType) {
    case INTEGRATION_AUTH_TYPES.token:
      return TokenAuthConfig;
    case INTEGRATION_AUTH_TYPES.oauth:
      return OAuthConfig;
    default:
      throw new Error(`AuthType not supported: ${authType}`);
  }
};

const CreateUserIntegration = ({ integrationConfigs, onClickConnect, isConnecting }) => {
  const AuthComponent = getAuthComponent(integrationConfigs.auth.type);

  return (
    <Wrapper>
      <AuthComponent integrationConfigs={integrationConfigs} onClickConnect={onClickConnect} isConnecting={isConnecting} />
    </Wrapper>
  );
};

export default CreateUserIntegration;
