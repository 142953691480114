import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_EXISTING_RECURRING_NOTIFICATIONS_FOR_USER_VIEW'),
  ...getThunkActionTypes('CREATE_USER_VIEW_RECURRING_NOTIFICATION'),
  ...getThunkActionTypes('UPDATE_USER_VIEW_RECURRING_NOTIFICATION'),
  ...getThunkActionTypes('DELETE_USER_VIEW_RECURRING_NOTIFICATION'),
};

export const {
  FETCH_EXISTING_RECURRING_NOTIFICATIONS_FOR_USER_VIEW,
  FETCH_EXISTING_RECURRING_NOTIFICATIONS_FOR_USER_VIEW_PENDING,
  FETCH_EXISTING_RECURRING_NOTIFICATIONS_FOR_USER_VIEW_FULFILLED,

  CREATE_USER_VIEW_RECURRING_NOTIFICATION,
  CREATE_USER_VIEW_RECURRING_NOTIFICATION_PENDING,
  CREATE_USER_VIEW_RECURRING_NOTIFICATION_FULFILLED,

  UPDATE_USER_VIEW_RECURRING_NOTIFICATION,
  UPDATE_USER_VIEW_RECURRING_NOTIFICATION_PENDING,
  UPDATE_USER_VIEW_RECURRING_NOTIFICATION_FULFILLED,

  DELETE_USER_VIEW_RECURRING_NOTIFICATION,
  DELETE_USER_VIEW_RECURRING_NOTIFICATION_PENDING,
  DELETE_USER_VIEW_RECURRING_NOTIFICATION_FULFILLED,
} = actionTypes;
