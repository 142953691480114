import { useCallback, useRef, useState } from 'react';

import { generateGroupOffsets } from '../helpers';

/**
 * Custom hook that manages the inside groups open state.
 * */
const useTimelineGroups = groupWidths => {
  // Calculate the groups offsets
  const previousGroupWidths = useRef(groupWidths);
  const groupOffsets = generateGroupOffsets(previousGroupWidths.current, groupWidths);

  const [openGroups, setOpenGroups] = useState({});

  const toogleGroup = useCallback(
    groupId => event => {
      event.stopPropagation();

      setOpenGroups({
        ...openGroups,
        [groupId]: !openGroups[groupId],
      });
    },
    [openGroups],
  );

  const isGroupOpen = useCallback(groupKey => !!openGroups[groupKey], [openGroups]);

  return { isGroupOpen, toogleGroup, groupOffsets };
};

export default useTimelineGroups;
