import React from 'react';

import Component from './Component';
import container from './container';

const DefaultGrid = container(Component);

export default ({ viewsDropdownTabs }) => {
  return <DefaultGrid viewsDropdownTabs={viewsDropdownTabs} />;
};
