import { createSelector } from 'reselect';
import { getData, isLoading } from 'utils/store/thunk';
import { FETCH_SELF_GUIDED_DEMO_DATA } from './types';

export const getState = state => {
  return state.onboardingDemo;
};

const getOperations = state => {
  return state.onboardingDemo.operations;
};

export const getIsDemoSplashPageOpen = createSelector(getState, state => state.demoSplashPageOpen || false);

export const getDemoSplashPageShown = createSelector(getState, state => state.demoSplashPageShown || false);

export const isSelfGuidedDemoLoading = createSelector(getOperations, state => isLoading(state, FETCH_SELF_GUIDED_DEMO_DATA));

export const getSelfGuidedDemoData = createSelector(getOperations, state => getData(state, FETCH_SELF_GUIDED_DEMO_DATA));
