import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import {
  makeGetItemsForProductsMetadataTreeDropdownCellEditor,
  returnsTrueIfKeyIsNotEscOrTab,
} from 'design-system/molecules/AgGridReact-New/helpers';
import { getMetadataTreeEditorColumnDef } from 'design-system/molecules/AgGridReact-New/columns';

const getProductColumnDef = ({
  field,
  headerName,
  selectedLevel,

  getFilteredRoadmaps,

  update,
  cellRenderer,
  cellRendererParams,

  ...otherProps
}) => {
  const metadataTreeEditorColumnDef = getMetadataTreeEditorColumnDef({
    shouldAcceptSelection: selectedOption => !selectedOption || selectedOption.data?.level === selectedLevel,
    getItems: params => makeGetItemsForProductsMetadataTreeDropdownCellEditor(getFilteredRoadmaps(params), selectedLevel)(params),
    handleUpdate: (dataId, selectedOption) => {
      const updatedData = {
        [field]: selectedOption?.title ?? null,
      };

      return update(dataId, updatedData);
    },
  });

  return {
    field,
    headerName,
    cellRenderer,
    cellRendererParams,
    ...metadataTreeEditorColumnDef,
    comparator: stringComparator,
    suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
    width: 120,
    ...otherProps,
  };
};

export default getProductColumnDef;
