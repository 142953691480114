import styled from 'styled-components';

import { spacing } from 'design-system/theme';
import CloseButton from 'components/DialogTitle/CloseButton';
import Text from 'design-system/atoms/Text';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding-right: ${spacing(4)}px;
`;

const TableWrapper = styled.div`
  .ag-layout-normal.ag-root {
    margin: 0 ${spacing(1)}px;
  }
`;

const StyledCloseButton = styled(CloseButton)`
  svg {
    color: ${({ theme }) => theme.palette.buttonIcon.primary};
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  margin: 0 0 0 ${spacing(5)}px;
`;

const Title = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: ${({ theme }) => theme.typography.lineHeight.heading}rem;
`;

export { HeaderWrapper, TableWrapper, StyledCloseButton, ActionsWrapper, Title };
