import React from 'react';
import { not } from 'ramda';
import theme from 'design-system/theme';
import TrendingUp from '@material-ui/icons/TrendingUp';
import BetIcon from 'design-system/atoms/BetIcon/index';
import IdeaIcon from 'design-system/atoms/IdeaIcon/index';
import InitiativeIcon from 'design-system/atoms/InitiativeIcon/index';
import ObjectiveType from 'design-system/atoms/ObjectiveType/index';

import UserPlus from 'design-system/atoms/DragonIcons/UserPlus';

import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import {
  OBJECT_KEY_RESULT,
  OBJECT_KEY_RESULT_2,
  OBJECT_OBJECTIVE,
  OBJECT_OBJECTIVE_CORP_STRING,
  openObjectiveDrawer,
} from 'store/objectives';
import { setAppGlobalConfig } from 'store/app';

import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const INVITE_USER = 'Invite';
const ICON_HEIGHT = theme.sizing.icon.height;

export const getProductData = (
  getLabelName,
  onClickNew,
  getSystemFieldName,
  hasBet,
  hasKeyResults,
  dispatch,
  { canCreate, canView } = {},
  createMetric,
) => {
  return [
    {
      icon: <IdeaIcon style={{ height: ICON_HEIGHT }} />,
      label: getLabelName(0),
      layer: IDEA_LAYER,
      onClick: () => onClickNew(IDEA_LAYER),
      hide: not(canCreate(PERMISSION_RESOURCES.project, { project: { layer: IDEA_LAYER } })),
    },
    {
      icon: <InitiativeIcon style={{ height: ICON_HEIGHT }} />,
      label: getLabelName(1),
      layer: INITIATIVE_LAYER,
      onClick: () => onClickNew(INITIATIVE_LAYER),
      hide: not(canCreate(PERMISSION_RESOURCES.project, { project: { layer: INITIATIVE_LAYER } })),
    },
    hasBet
      ? {
          icon: <BetIcon style={{ height: ICON_HEIGHT }} />,
          label: getLabelName(2),
          layer: BET_LAYER,
          onClick: () => onClickNew(BET_LAYER),
          hide: not(canCreate(PERMISSION_RESOURCES.project, { project: { layer: BET_LAYER } })),
        }
      : { hide: true },
    {
      icon: <ObjectiveType style={{ height: ICON_HEIGHT, color: 'inherit' }} type="objectiveCorp" />,
      label: getSystemFieldName('objectiveCorp'),
      onClick: () => dispatch(openObjectiveDrawer(null, OBJECT_OBJECTIVE_CORP_STRING)),
      hide: not(canCreate(PERMISSION_RESOURCES.objectiveCorp)),
    },
    {
      icon: <ObjectiveType style={{ height: ICON_HEIGHT, color: 'inherit' }} type="objective" />,
      label: getSystemFieldName('objective'),
      onClick: () => dispatch(openObjectiveDrawer(null, OBJECT_OBJECTIVE)),
      hide: not(canCreate(PERMISSION_RESOURCES.objective)),
    },
    hasKeyResults
      ? {
          icon: <ObjectiveType style={{ height: ICON_HEIGHT, color: 'inherit' }} type="keyResult" />,
          label: getSystemFieldName('keyResult1'),
          onClick: () => dispatch(openObjectiveDrawer(null, OBJECT_KEY_RESULT)),
          hide: not(canCreate(PERMISSION_RESOURCES.keyResult)),
        }
      : { hide: true },
    {
      icon: <ObjectiveType style={{ height: ICON_HEIGHT, color: 'inherit' }} type="keyResult2" />,
      label: getSystemFieldName('keyResult2'),
      onClick: () => dispatch(openObjectiveDrawer(null, OBJECT_KEY_RESULT_2)),
      hide: not(canCreate(PERMISSION_RESOURCES.keyResult2)),
    },
    {
      icon: <TrendingUp style={{ height: ICON_HEIGHT, color: 'inherit' }} type="metric" />,
      label: getSystemFieldName('metric'),
      onClick: () => createMetric(),
      hide: not(canView(PERMISSION_FEATURES.metricLightboxDetails)) || not(canCreate(PERMISSION_RESOURCES.metric)),
    },
    {
      icon: <UserPlus style={{ height: ICON_HEIGHT, color: 'inherit' }} type="objective" />,
      label: INVITE_USER,
      onClick: () => dispatch(setAppGlobalConfig({ showInviteUsersDialog: true })),
      hide: not(canView(PERMISSION_FEATURES.inviteUsers)),
    },
  ];
};
