import { ZENDESK_LOADED } from 'store/app/types';
import { getUserName } from 'utils';

export default ({ dispatch, getState }) => next => action => {
  if (action.type === ZENDESK_LOADED && window.zE) {
    const state = getState();
    const user = state.login ? state.login.user : null;

    if (user) {
      window.zE('webWidget', 'identify', {
        name: getUserName(user),
        email: user.email,
        organization: user.organization ? user.organization.slug : `id: ${user.organization_id}`,
      });
      window.zE('webWidget', 'prefill', {
        name: {
          value: getUserName(user),
          readOnly: true,
        },
        email: {
          value: user.email,
          readOnly: true,
        },
      });
    }
  }

  return next(action);
};
