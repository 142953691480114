import React from 'react';
import { Redirect } from 'react-router-dom';

export default function NotFoundRedirect({ history, location }) {
  return (
    <Redirect
      to={{
        pathname: '/not-found',
        state: { from: location },
      }}
    />
  );
}
