import { equals, isNil, not, pick, pipe } from 'ramda';

import { UPDATE_ALLOCATION_REPORT_STATE } from './types';
import { groupByOptions, sumByOptions, durationOptions } from './consts';
import { getDefaultAllocationDates } from 'utils/allocation/calcDefaultDates';
import { hasActualAllocationOption } from './helpers';

const isNotNil = pipe(isNil, not);

const { startDate: defaultStartDate, endDate: defaultEndDate } = getDefaultAllocationDates();

const initialState = {
  dataType: groupByOptions().find(o => o.key === 'objective'),
  sumBy: sumByOptions()[0],
  duration: durationOptions.find(o => o.key === 'week'),
  includeEmptyProjects: true,
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  showColorPicker: false,
  isPreferencing: false,
  currentTab: 0,
  alertOpen: false,
  explicitlySetDate: false,
  hideArchivedData: false,
};

const ALLOCATION_TOGGLE_KEYS = ['showPlanned', 'showReported', 'showCompleted', 'showTarget', 'showActual'];

export default function allocationReportReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ALLOCATION_REPORT_STATE:
      let newState = Object.assign({}, initialState, state, action.payload);

      if (!newState.explicitlySetDate) {
        newState.startDate = defaultStartDate;
        newState.endDate = defaultEndDate;
      }

      const { showPlanned, showReported, showCompleted, showTarget, showActual } = pick(ALLOCATION_TOGGLE_KEYS)(action.payload);
      const updatingAnyNonActualToggle = [showPlanned, showReported, showCompleted, showTarget].some(equals(true));
      const updatingActualToggle = isNotNil(showActual) && updatingAnyNonActualToggle === false;
      const updatingGroupBy = 'selectedGroupBy' in action.payload;
      const movedAwayFromActualAllocationOption =
        updatingGroupBy && !hasActualAllocationOption(action.payload.selectedGroupBy.key);

      // Disable other allocations when enabling Actual
      if (updatingActualToggle && showActual === true) {
        newState = {
          ...newState,
          ...ALLOCATION_TOGGLE_KEYS.reduce((acc, key) => ({ ...acc, [key]: false }), {}),
          showActual: true,
        };
      }

      if (updatingAnyNonActualToggle || movedAwayFromActualAllocationOption) {
        newState = {
          ...newState,
          showActual: false,
        };
      }

      return newState;
    default:
      return state;
  }
}
