import axios from 'axios';

import { FETCH_CUSTOMER_REQUESTS } from 'store/customerRequests/types';
import compileFilters from 'store/customerRequests/helpers/compileFilters';
import { fetchVotesPerCustomerRequest } from 'store/votes';
import getPage from 'store/customerRequests/helpers/getPage';
import { setCustomerRequestListPage, setCustomerRequestResultCount } from 'store/customerRequests';
import {
  getSelectedCounterPieChartGroupBy,
  getSelectedCounterStackedChartGroupBy,
  getSelectedCounterStackedChartStackedBy,
} from 'store/customerRequests/selectors';

import { ON_COUNTER_PIE_CHART_ENTITY_CLICK, ON_COUNTER_STACK_CHART_ENTITY_CLICK, SET_INSIGHTS_FILTERS } from './types';
import { selectInsightsFiltersWithMultiFilters } from './selectors';

export const fetchCustomerRequestsOnInsights = (options = {}) => {
  return (dispatch, getState) => {
    const state = getState();
    const { customerRequests: customerRequestsState } = state;

    const { pageLimit: limit, paginationInformation, search } = customerRequestsState;
    const { current, next } = paginationInformation;

    const filtersToUse = selectInsightsFiltersWithMultiFilters(state);
    const filters = compileFilters(search, filtersToUse);

    dispatch(fetchVotesPerCustomerRequest());

    const page = getPage(options, next, current);

    const params = { limit, ...filters, page };

    const promise = axios.get('/api/customer-requests', { params }).then(({ data: responseData }) => {
      const { data, _meta: paginationData } = responseData;

      const { count, next, previous } = paginationData;

      dispatch(setCustomerRequestListPage({ next, previous, current: page }));

      if (Number.isInteger(count)) {
        dispatch(setCustomerRequestResultCount(count));
      }

      return data;
    });

    return dispatch({
      type: FETCH_CUSTOMER_REQUESTS,
      payload: promise,
    });
  };
};

export const setInsightsFilters = filters => {
  return {
    type: SET_INSIGHTS_FILTERS,
    payload: filters,
  };
};

export const onCounterPieChartEntityClick = groupItem => {
  return (dispatch, getState) => {
    const state = getState();

    const selectedGroupBy = getSelectedCounterPieChartGroupBy(state);

    return dispatch({
      type: ON_COUNTER_PIE_CHART_ENTITY_CLICK,
      payload: {
        groupItem,
        selectedGroupBy,
      },
    });
  };
};

export const onCounterStackChartEntityClick = (groupItem, stackItem) => {
  return (dispatch, getState) => {
    const state = getState();

    const selectedGroupBy = getSelectedCounterStackedChartGroupBy(state);
    const selectedStackedBy = getSelectedCounterStackedChartStackedBy(state);

    return dispatch({
      type: ON_COUNTER_STACK_CHART_ENTITY_CLICK,
      payload: {
        groupItem,
        stackItem,
        selectedGroupBy,
        selectedStackedBy,
      },
    });
  };
};
