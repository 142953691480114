import React from 'react';

import ForecastTimeline from 'routes/Forecast/Timeline';
import usePreCloneScenario from 'routes/Scenario/hooks/usePreCloneScenario';

const ScenarioGantt = props => {
  usePreCloneScenario();

  return <ForecastTimeline {...props} />;
};

export default ScenarioGantt;
