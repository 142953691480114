import { useMemo } from 'react';
import { METADATA_LEVELS, ACTIVE_STATUS } from 'constants/common';
import { allPass } from 'ramda';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { useSelector } from 'react-redux';
import { getCrossAccountRoadmaps } from 'store/roadmaps/selectors';

const PRODUCT_1_LEVEL = 0;

const isActive = obj => obj.status === ACTIVE_STATUS;

export default (roadmaps = [], roadmapsCorp = [], hasProducts, hasProducts2) => {
  const { canView } = usePermissions();
  const filterSelectableRoadmaps = allPass([isActive]);

  const getChildrenBasedOnType = roadmap =>
    roadmap?.level === METADATA_LEVELS.LEVEL_CORP
      ? formatChildrenRoadmaps(roadmap)
      : getProductsIfOrgHasProducts(roadmap.products);

  const getProductsIfOrgHasProducts = products => (hasProducts ? formatChildrenProducts(products) : []);

  const getProductsL2IfOrgHasProductsL2 = (products, level) => {
    const isProduct1Level = level === PRODUCT_1_LEVEL;
    const shouldRenderProducts2 = isProduct1Level && hasProducts2;

    return shouldRenderProducts2 ? formatChildrenProducts(products) : [];
  };

  const formatProduct = product => ({
    ...product,
    children: getProductsL2IfOrgHasProductsL2(product.products, product.level),
    key: product.id,
    label: product.title,
    type: 'click',
  });

  const formatRoadmap = roadmap => ({
    ...roadmap,
    key: roadmap.id,
    label: roadmap.title,
    type: 'click',
    children: getProductsIfOrgHasProducts(roadmap.products),
  });

  const formatChildrenProducts = (products = []) => {
    return products.filter(filterSelectableRoadmaps).map(formatProduct);
  };

  const formatChildrenRoadmaps = roadmap => {
    return (roadmap.children || []).filter(filterSelectableRoadmaps).map(formatRoadmap);
  };

  const showRoadmapCorpLevel = canView(PERMISSION_RESOURCES.roadmapCorp) || canView(PERMISSION_FEATURES.crossBabyRoadmapSupport);

  const canViewRoadmapsCorp = canView(PERMISSION_RESOURCES.roadmapCorp);

  const crossAccountRoadmaps = useSelector(state => getCrossAccountRoadmaps(state));
  const hasCrossAccountRoadmapFeature = canView(PERMISSION_FEATURES.crossBabyRoadmapSupport) && crossAccountRoadmaps.length > 1;

  let roadmapsToFormat = roadmaps;

  if (canViewRoadmapsCorp) {
    roadmapsToFormat = roadmapsCorp;
  }

  if (hasCrossAccountRoadmapFeature) {
    roadmapsToFormat = crossAccountRoadmaps;
  }

  const options = useMemo(
    () =>
      [{}, ...roadmapsToFormat].map(obj => {
        return {
          ...obj,
          key: obj.id,
          label: showRoadmapCorpLevel ? obj.title?.toUpperCase() : obj.title,
          type: 'click',
          children: getChildrenBasedOnType(obj),
        };
      }),
    [roadmapsToFormat, showRoadmapCorpLevel],
  );

  return options;
};
