import { useEffect } from 'react';

const exist = Boolean;
const notExists = value => !exist(value);
const isNotNumber = value => Number.isNaN(Number(value));

/**
 * Verifies if we should to load the new url with dashboard id
 *
 * @param {Object} activeDashboard - dashboard object
 */
const useVerifyIfNeedToRefresh = (activeDashboard, history) => {
  const _verifyIfNeedToRefresh = () => {
    const id = activeDashboard && activeDashboard.id;
    const path = window.location.pathname;
    const isShareLink = path.startsWith('/share');

    if (notExists(id) || isShareLink) return false;

    const subPathId = path.replace('/dashboard/dashboards', '');

    if (isNotNumber(subPathId)) return true;

    return id !== Number(subPathId);
  };

  useEffect(() => {
    const needToRefresh = _verifyIfNeedToRefresh(activeDashboard);

    if (needToRefresh) history.push(`/dashboard/dashboards/${activeDashboard.id}`);
  }, [activeDashboard]);
};

export default useVerifyIfNeedToRefresh;
