import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { defaultTo, path, pipe } from 'ramda';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import { isURL } from 'utils';
import {
  testJira,
  disconnectJira,
  updateJiraConfigMisc,
  createJiraConfigDetails,
  updateJiraConfigDetails,
} from 'store/integrations';

import ConfigDetails from '../ConfigDetails';
import Form from './component';

const getJiraTestedFlag = pipe(path(['data', 'jira_tested']), defaultTo(false));

class JiraConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      completed: false,
      baseUrl: props.jiraConfig ? props.jiraConfig.base_url : '',
      isNew: props.jiraConfig && props.jiraConfig.isNew,
      formData: {
        email: props.currentUser.email,
        api_token: '',
      },
      waitForAPI: false,
    };
    this.setJiraConfiguration = this.setJiraConfiguration.bind(this);
  }

  setJiraConfiguration = async () => {
    const { setSelectedIntegrationId, orgIntegration, updateJiraConfigDetails, createJiraConfigDetails } = this.props;
    const { baseUrl, formData } = this.state;

    this.setState({ waitForAPI: true });

    const jiraUrl = baseUrl.indexOf('://') === -1 ? `https://${baseUrl}` : baseUrl;
    const dataToSend = { base_url: jiraUrl, type: 'token', ...formData };
    const jiraActionRes = orgIntegration.id
      ? await updateJiraConfigDetails(orgIntegration.id, dataToSend)
      : await createJiraConfigDetails(dataToSend);

    const integrationId = jiraActionRes?.value?.data?.id;

    this.props.testJira(integrationId, getJiraTestedFlag(orgIntegration)).then(r => {
      if (r && !r.error) {
        this.setState({
          completed: true,
          waitForAPI: false,
        });
        setSelectedIntegrationId(integrationId);
      } else {
        this.setState({ waitForAPI: false });
      }
    });
  };

  cleanInsertedUrl = e => {
    let url = e.target.value;
    const { allowFullBaseUrl } = this.props;

    if (isURL(url) && !allowFullBaseUrl) {
      let protocol = '';
      const splitedProtocol = url.split('//');

      if (splitedProtocol.length > 1) {
        protocol = `${splitedProtocol[0]}//`;
        url = splitedProtocol[1]; // eslint-disable-line
      } else {
        url = splitedProtocol[0]; // eslint-disable-line
      }
      const splitedOrigin = url.split('/');

      url = `${protocol}${splitedOrigin[0]}`;
    }

    return url?.trim();
  };

  changeForm = field => e => {
    this.setState({ formData: { ...this.state.formData, [field]: e.target.value } });
  };

  render() {
    const { goBack, jiraConfig, orgIntegration } = this.props;
    const { completed, baseUrl, waitForAPI, formData } = this.state;

    if (completed) {
      return (
        <ConfigDetails
          orgIntegration={orgIntegration}
          jiraConfig={jiraConfig || {}}
          justIntegrated
          disconnet={id => {
            this.props.disconnectJira(id);
            this.setState({ completed: false });
          }}
        />
      );
    }

    return (
      <Card style={{ minHeight: '400px' }}>
        <CardContent>
          <ConfigContent>
            <Progress>{waitForAPI && <LinearProgress />}</Progress>
            <Form
              baseUrl={baseUrl}
              formData={formData}
              changeForm={this.changeForm}
              baseUrlChange={e => this.setState({ baseUrl: this.cleanInsertedUrl(e) })}
              onNextClick={this.setJiraConfiguration}
              goBack={goBack}
            />
          </ConfigContent>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { login } = state;

  return {
    currentUser: login.currentUser,
  };
};

const mapDispatchToProps = {
  updateJiraConfigDetails,
  createJiraConfigDetails,
  disconnectJira,
  updateJiraConfigMisc,
  testJira,
};

export default connect(mapStateToProps, mapDispatchToProps)(JiraConfig);

const ConfigContent = styled.div`
  min-height: 200px;
  padding: 0 16px 16px;
`;

const Progress = styled.div`
  height: 6px;
  width: 100%;
`;
