import React, { useMemo } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/HelpOutline';

const MOAR_HELP_ICON = 'MoAR_help';

export default ({
  businessValue,
  calculatedMoar,
  effortScore,
  onBusinessValueChange,
  saveProject,
  updateEffortScore,
  userCanEdit,
}) => {
  const plannedMoar = useMemo(() => `${calculatedMoar ? parseFloat(calculatedMoar) : ''}%`, [calculatedMoar]);

  return (
    <Container container spacing={24}>
      <Grid item style={{ flexBasis: '15%' }}>
        <StyledFormControl fullWidth margin="dense">
          <NumberFormat
            customInput={TextField}
            thousandSeparator
            InputLabelProps={{ shrink: true }}
            label="Benefit"
            value={businessValue}
            onValueChange={e => onBusinessValueChange(+e.value)}
            disabled={!userCanEdit}
            onBlur={() => saveProject({ business_value: businessValue })}
          />
        </StyledFormControl>
      </Grid>
      <Grid item style={{ flexBasis: '29%' }}>
        <StyledFormControl fullWidth margin="dense">
          <NumberFormat
            customInput={TextField}
            thousandSeparator
            InputLabelProps={{ shrink: true }}
            label="Effort"
            value={effortScore}
            onValueChange={e => updateEffortScore(e.value)}
            disabled={!userCanEdit}
            onBlur={() => saveProject({ effort_score: effortScore })}
          />
        </StyledFormControl>
      </Grid>
      <Grid item style={{ flexBasis: '30%' }}>
        <StyledFormControl fullWidth margin="dense">
          <TextField
            InputLabelProps={{ shrink: true }}
            label={
              <span>
                Planned MoAR
                <HelpIcon
                  id={MOAR_HELP_ICON}
                  style={{ color: '#6b6b6b', marginLeft: 3, verticalAlign: 'middle', marginTop: -2 }}
                />
              </span>
            }
            value={plannedMoar}
            disabled
          />
        </StyledFormControl>
      </Grid>
    </Container>
  );
};

const Container = styled(Grid)`
  &&&& {
    margin: 0;
    padding: 0 8px;
  }
`;

const StyledFormControl = styled(FormControl)`
  input {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  }
`;
