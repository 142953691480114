import { INITIATIVE_LAYER, PROGRESS_AUTO } from '../constants';

/**
 * @function projectAutoProgressHandler
 *
 * Gets the progress and manual progress for a given Project.
 *
 * @param {Object} project
 * @returns {Object} progress and manual progress values
 */
const projectAutoProgressHandler = (project = {}) => {
  const { integrationProgress, Jiras = [], integrations = [], progress_calculated: progressCalculated } = project;
  const hasIntegrationDataProgress = integrationProgress && (Jiras.length || integrations.length);

  const progress = hasIntegrationDataProgress ? integrationProgress.progress : progressCalculated;

  return { progress: progress || 0, manualProgress: progressCalculated || 0 };
};

/**
 * @function initiativeAutoProgressHandler
 *
 * Gets the progress and manual progress for a given Initiative.
 *
 * @param {Object} project
 * @returns {Object} progress and manual progress values
 */
const initiativeAutoProgressHandler = (project = {}) => {
  const { progress_calculated: progressCalculated } = project;

  return { progress: progressCalculated || 0, manualProgress: progressCalculated || 0 };
};

/**
 * @function getProjectProgress
 *
 * Gets the progress and manual progress for a given Project.
 *
 * @param {Object} project
 * @returns {Object} progress and manual progress values
 */
export const getProjectProgress = (project = {}) => {
  const { progress_type: progressType, progress = 0 } = project;
  const manualProgress = progress || 0;

  if (progressType === PROGRESS_AUTO) {
    switch (project.layer) {
      case INITIATIVE_LAYER:
        return initiativeAutoProgressHandler(project);
      default:
        return projectAutoProgressHandler(project);
    }
  }

  return { progress, manualProgress };
};
