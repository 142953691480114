import React, { useRef, useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { either, isEmpty, isNil } from 'ramda';

import theme from 'design-system/theme';

const isNilOrEmpty = either(isNil, isEmpty);

export default ({
  children,
  text,
  containerWidth,
  elementWithOverflow,
  vertical,
  disableFocusListener = false,
  tooltipPlacement = 'bottom',
}) => {
  const [showTooltip, setShowTooltip] = useState(true);

  const wrapperRef = useRef();

  const checkIfTooltipIsNeeded = () => {
    if (isNilOrEmpty(text)) {
      return setShowTooltip(false);
    }

    const element = elementWithOverflow ? wrapperRef?.current?.querySelector(elementWithOverflow) : wrapperRef?.current;

    if (element) {
      const elementWidthIsZero = element.scrollWidth === 0 && element.clientWidth === 0;
      const elementIsOverflowing = vertical
        ? element.scrollHeight > element.clientHeight
        : element.scrollWidth > element.clientWidth;

      setShowTooltip(elementIsOverflowing || elementWidthIsZero);
    }
  };

  useEffect(() => checkIfTooltipIsNeeded(), [containerWidth, text]);

  return (
    <Tooltip
      title={text}
      disableHoverListener={!showTooltip}
      placement={tooltipPlacement}
      disableFocusListener={disableFocusListener}
      innerRef={el => (wrapperRef.current = el?.childrenRef)}
      PopperProps={{ style: { zIndex: theme.zIndex.highest2 } }}
    >
      {children}
    </Tooltip>
  );
};
