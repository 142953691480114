import moment from 'moment-timezone';
import divide from 'lodash/divide';
import subtract from 'lodash/subtract';

import { PROJECT_INTEGRATION_STATUSES } from 'constants/integrations';

import { getProjectProgress } from './getProjectProgress';
import { HEALTH_STATUS } from 'src/constants/projects';
import { either, isEmpty, isNil, not, pipe, defaultTo } from 'ramda';

const PROGRESS_LIMITS = {
  COMPLETED: 1,
  OFF_TRACK: 0.2,
  AT_RISK: 0.01,
};

const UNIT_OF_TYPE_DAYS = 'days';
const isNotEmpty = pipe(either(isNil, isEmpty), not);

const defaultToEmptyString = defaultTo('');

/**
 * @function isProjectCompleted
 *
 * Returns true or false based on the project progress and status.
 * Handles both projects with integration data and without integration data.
 *
 * @param {Object} project
 * @returns the true if the project progress is completed
 */
const isProjectCompleted = (project = {}) => {
  const { integrationProgress, progress_calculated: progressCalculated } = project;

  if (isNotEmpty(integrationProgress)) {
    return (
      integrationProgress.status === PROJECT_INTEGRATION_STATUSES.DONE ||
      integrationProgress.progress === PROGRESS_LIMITS.COMPLETED
    );
  }

  return progressCalculated === PROGRESS_LIMITS.COMPLETED;
};

/**
 * @function getProgressDiffInPercentage
 *
 * Gets the difference between target start date progress and current progress in percentage.
 *
 * @param {Moment} targetStartDate
 * @param {Moment} targetEndDate
 * @param {Number} currentProgress
 * @returns {Number}
 */
const getProgressDiffInPercentage = (targetStartDate, targetEndDate, currentProgress = 0) => {
  const targetDurationInDays = targetEndDate.diffDuration(targetStartDate, UNIT_OF_TYPE_DAYS);
  const targetProgressInDays = moment().diffDuration(targetStartDate, UNIT_OF_TYPE_DAYS);

  if (targetProgressInDays < 0) return null;

  const targetProgress = divide(targetProgressInDays, targetDurationInDays);

  return subtract(targetProgress, currentProgress);
};

/**
 * @function getProjectStatusColor
 *
 * Gets the Health status color for a given project.
 *
 * If status color auto is disabled return the stored status color
 *
 * If no target dates -> return empty string
 * If the linked issue is done or the progress is 100% -> return Blue
 * If current progress is 20% less than target progress -> return Red
 * If current progress is between 1% to 19.9% less than target progress -> return Yellow
 *
 *
 * @param {Object} project
 * @returns {String} the Health status color
 */
const getProjectStatusColor = (project = {}) => {
  if (project.health_calculated) {
    return project.health_calculated;
  }

  const {
    deadline,
    estimated_start_date: estimatedStartDate,
    status_color: statusColor,
    status_color_auto: statusColorAuto,
  } = project;
  const noTargetDatesAvailable = !deadline || !estimatedStartDate;

  if (!statusColorAuto) return defaultToEmptyString(statusColor);

  if (noTargetDatesAvailable) return HEALTH_STATUS.UNDEFINED;

  if (isProjectCompleted(project)) return HEALTH_STATUS.CLOSED_OR_PAUSED;

  const targetStartDate = moment(estimatedStartDate);
  const targetEndDate = moment(deadline);
  const { progress: projectProgress } = getProjectProgress(project);

  const progressDiffInPercentage = getProgressDiffInPercentage(targetStartDate, targetEndDate, projectProgress);

  const isProjectProgressOffTrack = progressDiffInPercentage != null && progressDiffInPercentage >= PROGRESS_LIMITS.OFF_TRACK;
  const isProjectProgressAtRisk =
    progressDiffInPercentage != null &&
    progressDiffInPercentage < PROGRESS_LIMITS.OFF_TRACK &&
    progressDiffInPercentage >= PROGRESS_LIMITS.AT_RISK;

  switch (true) {
    case isProjectProgressOffTrack:
      return HEALTH_STATUS.OFF_TRACK;
    case isProjectProgressAtRisk:
      return HEALTH_STATUS.AT_RISK;
    default:
      return HEALTH_STATUS.ON_TRACK;
  }
};

export default getProjectStatusColor;
