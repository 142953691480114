import React, { Component } from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';

import { getOrganization } from 'store/organization/selectors';

import IdeasBoard from './IdeasBoard';
import IdeasGrid from './IdeasGrid';
import Swimlane from 'routes/Swimlane';
import Manage from 'routes/Manage';
import Summary from 'routes/Dashboard/Summary';
import { setLastIdeasView } from 'store/app';

class Ideas extends Component {
  static propTypes = {
    displayView: string,
    hasShowMyItemFilter: Boolean,
  };

  static defaultProps = {
    displayView: 'board',
    hasShowMyItemFilter: true,
  };

  availableViews = {
    grid: IdeasGrid,
    board: IdeasBoard,
    roadmap: Swimlane,
    timeline: Manage,
    summary: Summary,
  };

  componentDidMount() {
    if (this.props.displayView === 'board') {
      IdeasBoard.preload();
    }

    this.props.setLastIdeasView(this.getViewKey());
  }

  getViewKey() {
    const { location } = this.props;

    const page = location.pathname.split('/')[2];
    const queryString = location.search || '';

    if (!page) {
      this.props.history.push(`/ideas/${this.props.lastIdeasView || 'board'}${queryString}`);
    }

    return !page ? this.props.lastIdeasView || 'board' : page;
  }

  render() {
    const { props } = this;

    const View = this.availableViews[this.getViewKey()];

    return <View {...props} />;
  }
}

export default connect(
  state => {
    return {
      organization: getOrganization(state),
      lastIdeasView: state.app.lastIdeasView,
    };
  },
  {
    setLastIdeasView,
  },
)(Ideas);
