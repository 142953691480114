import React, { useState } from 'react';
import styled from 'styled-components';
import pick from 'lodash/pick';
import { useDebouncedCallback } from 'use-debounce';

import FluidColorField from 'design-system/molecules/FluidColorField/index';

import useUpdateCycleDeliverable from 'containers/IdeasCycleDeliverableList/hooks/useUpdateCycleDeliverable';
import useOptionsForm from 'hooks/useOptionsForm';
import { DELIVERABLE_STATUS_FIELDS } from 'constants/pdlc';
import { DEBOUNCE_UPDATE_SERVER_DELAY } from 'constants';

const OPTIONS_TITLE_LABEL = 'Status';
const OPTIONS_ADD_NEW_LABEL = '+ Add Status';

const DeliverableSettingsContent = ({ cycleDeliverable }) => {
  const { onChangeColor, onChangeStatus, canUpdateCycleDeliverable } = useUpdateCycleDeliverable(cycleDeliverable);
  // Since the options form is being used directly in a lightbox we only need to have them resetted on mount and not on change
  // probably we need to evaluate the real need of RESET_STATE on useOptionsForm in the future
  const [options] = useState(cycleDeliverable?.status);
  const [deboundedOnChangeStatus] = useDebouncedCallback(onChangeStatus, DEBOUNCE_UPDATE_SERVER_DELAY);

  const OptionsComponent = useOptionsForm({
    options,
    handleUpdateOptions: value => {
      const newValue = Object.entries(value).reduce((acc, [key, val]) => {
        return {
          ...acc,
          [key]: pick(val, DELIVERABLE_STATUS_FIELDS),
        };
      }, {});

      deboundedOnChangeStatus(newValue);
    },
    titleLabel: OPTIONS_TITLE_LABEL,
    addOptionLabel: OPTIONS_ADD_NEW_LABEL,
    optionIdPrefix: '',
    disableEdit: !canUpdateCycleDeliverable,
    showStatusButton: false,
    wrapperDndListStyle: {
      boxShadow: 'none',
    },
  });

  return (
    <>
      <Title>{cycleDeliverable?.title}</Title>
      <FluidColorField
        key="color"
        value={cycleDeliverable?.color}
        label="Color"
        onChange={onChangeColor}
        format="row"
        disableEdit={!canUpdateCycleDeliverable}
      />
      <StatusWrapper>{OptionsComponent}</StatusWrapper>
    </>
  );
};

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 6px 8px;
`;

const StatusWrapper = styled.div`
  width: 49%;
`;

export default DeliverableSettingsContent;
