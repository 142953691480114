/**
 * AgGridColumnNumberAndPercentage Molecule Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';

export const AgGridColumnNumberAndPercentageRenderer = params => {
  return (
    <div className={+params.value < 0 ? 'red' : ''}>
      {!params.value ? (
        <span> </span>
      ) : (
        <span>{params.colDef.formatter ? params.colDef.formatter(params.data) : params.value}</span>
      )}
    </div>
  );
};
