import React, { useCallback, useEffect, useRef, useState } from 'react';
import { head } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { getTimeframes } from 'store/timeframes/selectors';
import DragonbotOptions from './components/DragonbotOptions';
import DragonbotPromptResult from './components/DragonbotPromptResult';
import history from 'store/utils/history';
import { isDragonbotOpen } from 'store/dragonbot/selectors';

import useSystemFields from 'hooks/useSystemFields';
import useOrganizations from 'hooks/useOrganizations';

import {
  WELCOME_CHAT_STEP_ID,
  SHOW_OPTIONS_STEP_ID,
  USER_PROMPT_CHAT_STEP_ID,
  PROMPT_RESULT_CHAT_STEP_ID,
  SLOT_FILL_CHAT_STEP_ID,
} from 'store/dragonbot/constants';
import { hideDragonbot, openDragonbot, storeDragonbotConversationHistory } from 'store/dragonbot/actions';
import { debounce } from 'lodash';

const DragonbotHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const chatbotRef = useRef(null);
    const [chatCacheEnabled, setChatCacheEnabled] = useState(true);
    const [helpWindowOpen, setHelpWindowOpen] = useState(false);

    const onToggleHelpWindow = useCallback(() => setHelpWindowOpen(!helpWindowOpen), [helpWindowOpen, setHelpWindowOpen]);

    const firstTimeframe = useSelector(state => head(getTimeframes(state)));
    const isOpen = useSelector(state => isDragonbotOpen(state));

    const toggleDragonbot = () => {
      if (isOpen) {
        if (helpWindowOpen) {
          setHelpWindowOpen(false);
        }

        dispatch(hideDragonbot());
        return;
      }

      dispatch(openDragonbot());
    };

    const [getSystemFieldName] = useSystemFields();
    const { hasKeyResults } = useOrganizations();

    const timeframesTitle = getSystemFieldName('timeframe', true);
    const objectivesTitle = getSystemFieldName('objective', true);
    const productTitle = getSystemFieldName('product1', true);
    const customersTitle = getSystemFieldName('customer', true);
    const keyResultsTitle = getSystemFieldName('keyResult1', true);

    useEffect(() => {
      if (chatbotRef.current && chatCacheEnabled) {
        // Disable cache after bot has loaded
        // We only want the component to read from cache not write as it will fail
        // because of a cross-origin stringify
        setChatCacheEnabled(false);
      }
    }, [chatbotRef.current, chatCacheEnabled]);


    const onHeaderOptionClicked = useCallback(option => {
      if (option === 'training-data') {
        history.push('/settings/dragonbot/training-manager');
      }
    }, [history]);

    const debouncedStoreDragonbotConversationHistory = useCallback(debounce(() => {
      if (chatbotRef.current) {
        dispatch(storeDragonbotConversationHistory({
          currentStep: { ...chatbotRef.current.state.currentStep },
          previousStep: { ...chatbotRef.current.state.previousStep },
          previousSteps: [...chatbotRef.current.state.previousSteps],
          renderedSteps: [...chatbotRef.current.state.renderedSteps],
        }));
      }
    }, 250), [dispatch, chatbotRef.current]);

    const chatTrigger = useCallback(nextStepId => () => {
      debouncedStoreDragonbotConversationHistory();

      return nextStepId;
    }, [chatbotRef.current]);

    const onChatbotRef = el => {
      chatbotRef.current = el;
    };

    const options = [
      {
        value: `How is release ${firstTimeframe?.title} progressing?`,
        label: `How is release ${firstTimeframe?.title} progressing?`,
        trigger: PROMPT_RESULT_CHAT_STEP_ID,
      },
      {
        value: 'What is our overall goal progress?',
        label: 'What is our overall goal progress?',
        trigger: PROMPT_RESULT_CHAT_STEP_ID,
      },
    ];

    const chatbotSteps = [
      {
        id: WELCOME_CHAT_STEP_ID,
        message: `Hi there, I’m your AI copilot.
          I can provide information about ${timeframesTitle}, ${productTitle} roadmap, 
          roadmap for ${customersTitle}, roadmap for ${objectivesTitle} or 
          ${hasKeyResults ? keyResultsTitle : objectivesTitle} progress. Here are some example questions:`,
        trigger: SHOW_OPTIONS_STEP_ID,
      },
      {
        id: SHOW_OPTIONS_STEP_ID,
        component: <DragonbotOptions {...props} chatbotRef={chatbotRef} options={options} />,
        trigger: chatTrigger(USER_PROMPT_CHAT_STEP_ID),
      },
      {
        id: USER_PROMPT_CHAT_STEP_ID,
        user: true,
        trigger: chatTrigger(PROMPT_RESULT_CHAT_STEP_ID),
      },
      {
        id: PROMPT_RESULT_CHAT_STEP_ID,
        component: <DragonbotPromptResult />,
        waitAction: true,
        asMessage: true,
        delay: 0,
        trigger: chatTrigger(USER_PROMPT_CHAT_STEP_ID),
      },
      {
        id: SLOT_FILL_CHAT_STEP_ID,
        component: <DragonbotOptions {...props} chatbotRef={chatbotRef} />,
        trigger: chatTrigger(USER_PROMPT_CHAT_STEP_ID),
      },
    ];

    return (
      <Component
        {...props}
        dragonbotOpen={isOpen}
        toggleDragonbot={toggleDragonbot}
        dragonbotSteps={chatbotSteps}
        onHeaderOptionClicked={onHeaderOptionClicked}
        onChatbotRef={onChatbotRef}
        enableCache={chatCacheEnabled}
        helpWindowOpen={helpWindowOpen}
        onToggleHelpWindow={onToggleHelpWindow}
      />
    );
  };
};

export default DragonbotHOC;
