import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import Dialog from 'design-system/molecules/Dialog/index';


const TITLE_TEXT = 'Title';
const ALERT_MESSAGE_TEXT = 'The Title Field in Dragonboat needs to map to the Summary field in Jira';
const CANCEL_TEXT = 'Cancel';
const CONFIRM_TEXT = 'Confirm';

const ChangedSummaryFieldAlert = ({ isOpen, onConfirm, onCancel }) => {
  return (
    <Dialog
      open={isOpen}
      title={TITLE_TEXT}
      actions={
        <>
          <Button color="primary" onClick={onConfirm}>
            {CONFIRM_TEXT}
          </Button>
          <Button onClick={onCancel}>{CANCEL_TEXT}</Button>
        </>
      }
    >
      <TextDeprecated breakwords>{ALERT_MESSAGE_TEXT}</TextDeprecated>
    </Dialog>
  );
};

ChangedSummaryFieldAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ChangedSummaryFieldAlert;
