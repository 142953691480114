import { useDispatch, useSelector } from 'react-redux';

import { getIsPermissionsDialogVisible } from 'store/app/selectors';
import { hidePermissionsDialog } from 'store/app/actions';

export default function () {
  const dispatch = useDispatch();
  
  const isDialogVisible = useSelector(getIsPermissionsDialogVisible);

  const closeDialog = () => dispatch(hidePermissionsDialog());

  return { closeDialog, isDialogVisible };
}