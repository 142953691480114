import { useState } from 'react';

import { BASE_ROW_HEIGHT } from 'constants/grid';
import { spacing } from 'design-system/theme';
import { GRID_MIN_ROWS } from 'features/MissionControlHome/constants/widgetsConfig';

const useUpdateGridWidgetHeight = ({ widgetId, getWidgetHeight, updateHeightOnLayout, hasPagination, maxItems }) => {
  const [gridHeight, setGridHeight] = useState(0);

  const updateWidgetHeight = visibleItemsLength => {
    if (updateHeightOnLayout) {
      updateHeightOnLayout(widgetId, getWidgetHeight(visibleItemsLength));
    }

    const baseTableHeight = hasPagination
      ? BASE_ROW_HEIGHT * maxItems
      : BASE_ROW_HEIGHT * Math.max(Math.min(visibleItemsLength, maxItems), GRID_MIN_ROWS);

    const offset = hasPagination ? spacing(8) : spacing(7);

    const tableHeight = baseTableHeight + offset;

    setGridHeight(tableHeight);
  };

  return { updateWidgetHeight, gridHeight };
};

export default useUpdateGridWidgetHeight;
