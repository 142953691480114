import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateState } from 'store/portfolioReport';
import useSystemFields from 'hooks/useSystemFields';

export default () => {
  const dispatch = useDispatch();
  const lsState = useSelector(state => state.portfolioReport);
  const _udpateState = data => dispatch(updateState(data));
  const [getSystemFieldName] = useSystemFields();

  React.useEffect(() => {
    const stateToUpdate = {};
    const _cleanGroupLabel = (group, groupKey) => {
      if (group && getSystemFieldName(group.key) !== group.title) {
        stateToUpdate[groupKey] = { key: group.key, title: getSystemFieldName(group.key) };
      }
    };

    _cleanGroupLabel(lsState.group1, 'group1');
    _cleanGroupLabel(lsState.group2, 'group2');
    _cleanGroupLabel(lsState.group3, 'group3');

    if (Object.keys(stateToUpdate).length) _udpateState(stateToUpdate);
  }, []);
};
