import TableBody from './TableBody';
import TableCell from './TableCell';
import TableHead from './TableHead';
import TableHeader from './TableHeader';
import TableHeaderTitle from './TableHeaderTitle';
import TableHeaderTitleWrapper from './TableHeaderTitleWrapper';
import TableRow from './TableRow';
import TableHeaderCell from './TableHeaderCell';
import TableCellTitle from './TableCellTitle';
import TableRowContent from './TableRowContent';
import TableBar from './TableBar';
import TableGroupBar from './TableGroupBar';
import TableMilestone from './TableMilestone';
import TableCellContainer from './TableCellContainer';

export default {
  Body: TableBody,
  Cell: TableCell,
  CellTitle: TableCellTitle,
  Head: TableHead,
  Header: TableHeader,
  HeaderTitle: TableHeaderTitle,
  HeaderTitleWrapper: TableHeaderTitleWrapper,
  HeaderCell: TableHeaderCell,
  Row: TableRow,
  RowContent: TableRowContent,
  Bar: TableBar,
  GroupBar: TableGroupBar,
  Milestone: TableMilestone,
  CellContainer: TableCellContainer,
};
