import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { both, not, pipe, equals, ifElse, isEmpty, isNil, map, trim, split, join, values, uniqBy, toLower } from 'ramda';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import theme from 'design-system/theme';

import { FeatureFlags } from '@dragonboat/config';
import { UPDATE_JIRA_HIERARCHY_MAPPING } from '../../../constants/validationDialogActionTypes';

import useSystemFields from 'hooks/useSystemFields';
import useAppNotifications from 'hooks/useAppNotifications';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { defaultIssueTypeMapping } from '../../contants/issueTypes';
import { issueTypeNames } from '../../utils/issueTypeNameManager';
import IntegrationPermissionsDialog from 'routes/Settings/Integration/components/IntegrationPermissionsDialog';

const BET_INDEX = 2;

const isNotEmpty = pipe(isEmpty, not);
const isNotNil = pipe(isNil, not);

const getDisplayFormInitialState = both(isNotNil, isNotEmpty);

const mergeValuesWithCommas = pipe(values, join(','));
const trimArrayOfStrings = map(trim);
const splitByComma = pipe(split(','), trimArrayOfStrings);

/**
 * @function hasDuplicatedIssueTypes
 *
 * Check if the provided issue types hierarchy configuration has duplicated
 * issue types or not.
 *
 * Compares in lower case.
 *
 * @param {Object} tempHierarchyData
 * @returns {Boolean}
 */
const hasDuplicatedIssueTypes = tempHierarchyData => {
  const allIssueTypes = mergeValuesWithCommas(tempHierarchyData);
  const allIssueTypesInArray = splitByComma(allIssueTypes);

  return allIssueTypesInArray.length !== uniqBy(toLower, allIssueTypesInArray).length;
};

export default ({ hierarchyData, updateHierarchyMap, orgIntegrationId, isReadOnly }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempHierarchyData, setTempHierarchyData] = useState(hierarchyData);
  const [shouldDisplayForm, setShouldDisplayForm] = useState(getDisplayFormInitialState(hierarchyData));
  const [validatePermissionsDialogOpen, setValidatePermissionsDialogOpen] = useState(false);
  const [actionPayload, setActionPayload] = useState({});

  const hasBet = useSelector(state => state.organization.organization.has_bet);

  const [getSystemFieldName] = useSystemFields();
  const { addError } = useAppNotifications();
  const hasEpicRenameEnabled = useFeatureFlags([FeatureFlags.HAS_EPIC_RENAME_IN_ISSUE_TYPE_MAPPING]);

  const shouldShowEditMode = useCallback(
    index => {
      const editModeRestrictionsByIndex = {
        0: hasEpicRenameEnabled,
        1: true,
        2: true,
      };

      return isEditing && editModeRestrictionsByIndex[index];
    },
    [isEditing, hasEpicRenameEnabled],
  );

  const itemChanged = (newValue, index) => {
    const newTempHierarchyData = {
      ...tempHierarchyData,
      [index]: newValue,
    };

    setTempHierarchyData(newTempHierarchyData);
  };

  const validatePermissionsBeforeUpdate = payload => {
    setActionPayload(payload);
    setValidatePermissionsDialogOpen(true);
  };

  const dispatchUpdateHierarchyMap = () => {
    setShouldDisplayForm(isNotEmpty(actionPayload));

    return updateHierarchyMap(actionPayload).then(() => {
      setIsEditing(prevValue => !prevValue);
    });
  };

  const actionButtonClicked = () => {
    if (isEditing) {
      if (hasDuplicatedIssueTypes(tempHierarchyData)) {
        return addError({ id: 'update-issue-type-hierarchy-failed', message: 'Please remove the duplicated Issue Types' });
      }

      return validatePermissionsBeforeUpdate(tempHierarchyData);
    }

    setIsEditing(prevValue => !prevValue);
  };

  const resetValidationPermissionsDialog = () => {
    setValidatePermissionsDialogOpen(false);
    setActionPayload({});
  };

  const cancelButtonClicked = () => {
    setTempHierarchyData(hierarchyData);
    setShouldDisplayForm(isNotEmpty(hierarchyData));
    setIsEditing(false);

    resetValidationPermissionsDialog();
  };

  const shouldRenderLine = useCallback(
    index =>
      ifElse(
        equals(BET_INDEX),
        () => hasBet,
        () => true,
      )(index),
    [hasBet],
  );

  const onChangeMapHierarchyState = useCallback(
    ({ target }) => {
      const isEnabled = !!target.checked;

      if (isEnabled) {
        setTempHierarchyData(defaultIssueTypeMapping);
        return validatePermissionsBeforeUpdate(defaultIssueTypeMapping);
      }

      return validatePermissionsBeforeUpdate({});
    },
    [setShouldDisplayForm, setTempHierarchyData],
  );

  function displayHierarchyMapForm() {
    return (
      <>
        <Grid container style={{ margin: '14px 0' }}>
          <Grid item xs={4}>
            <TextDeprecated variant="bold" size="medium">
              Dragonboat Item
            </TextDeprecated>
          </Grid>
          <Grid item xs={5}>
            <TextDeprecated variant="bold" size="medium">
              Jira Issue Type
            </TextDeprecated>
          </Grid>
        </Grid>

        {issueTypeNames.map(
          (item, index) =>
            shouldRenderLine(index) && (
              <Grid container style={{ marginBottom: '6px' }}>
                <Grid item xs={3}>
                  <TextDeprecated>{getSystemFieldName(item, false)}</TextDeprecated>
                </Grid>
                <Grid item xs={1}>
                  <ArrowsText>
                    ← <br /> →
                  </ArrowsText>
                </Grid>
                <Grid item xs={5}>
                  {!shouldShowEditMode(index) && <TextDeprecated>{tempHierarchyData?.[index]}</TextDeprecated>}
                  {shouldShowEditMode(index) && (
                    <InlineTextField
                      value={tempHierarchyData[index]}
                      onChange={({ target }) => itemChanged(target.value, index)}
                    />
                  )}
                </Grid>
              </Grid>
            ),
        )}

        <ActionsContainer>
          <EditSaveButton>
            <Button color="primary" onClick={actionButtonClicked} disabled={isReadOnly}>
              {isEditing ? 'Save' : 'Edit'}
            </Button>
          </EditSaveButton>
          {isEditing && (
            <CancelButton>
              <Button color="secondary" onClick={cancelButtonClicked} disabled={isReadOnly}>
                Cancel
              </Button>
            </CancelButton>
          )}
        </ActionsContainer>
      </>
    );
  }

  return (
    <>
      <FormControlLabel
        label="Map hierarchy with Jira advanced roadmap issue hierarchy"
        control={<Switch checked={shouldDisplayForm} onChange={onChangeMapHierarchyState} disabled={isReadOnly} />}
      />
      {shouldDisplayForm && displayHierarchyMapForm()}
      <IntegrationPermissionsDialog
        isDialogVisible={validatePermissionsDialogOpen}
        integrationType="Jira"
        messageActionName={UPDATE_JIRA_HIERARCHY_MAPPING}
        onCancel={cancelButtonClicked}
        onConfirm={dispatchUpdateHierarchyMap}
        onClose={resetValidationPermissionsDialog}
        orgIntegrationId={orgIntegrationId}
      />
    </>
  );
};

const ArrowsText = styled(TextDeprecated)`
  &&&& {
    line-height: 8px;
    display: inline-flex;
    align-items: center;
    height: 20px;
  }
`;

const ActionsContainer = styled.div`
  margin-top: 16px;
  display: flex;
`;

const EditSaveButton = styled.div`
  margin-left: -16px;
`;

const CancelButton = styled(EditSaveButton)`
  margin-left: 0;
`;

const InlineTextField = styled(TextField)`
  input {
    padding: 4px 0;
    font-size: ${theme.typography.fontSize}px;
  }
`;
