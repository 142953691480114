import React, { useMemo } from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import {
  getTitleColumnDef,
  statusColumnDef,
  updatedColumnDef,
  colorColumnDef,
  defaultColumnDefCommonProps,
} from 'design-system/molecules/AgGridReact-New/columns';
import { AgGridColumnCustomFieldTypeSelectRenderer } from 'design-system/molecules/AgGridColumnCustomFieldTypeSelect';
import { AgGridColumnCustomFieldDescriptionRenderer } from 'design-system/molecules/AgGridColumnCustomFieldDescription';
import { ActionsCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { ACTIONS_FIELD, ACTIONS_WIDTH, CUSTOM_FIELD_TITLE, FORMULA } from '../helpers/constants';
import { getDescriptionValue, checkEditablePermission } from 'utils/customFields';
import { checkIfCustomFieldIsDropdown } from 'utils/customFields/customFieldsUtils';
import { materialColors } from 'design-system/themes/default';

import { AVAILABLE_CUSTOMER_REQUEST_CUSTOM_FIELDS } from 'store/customFields/constants';
import { apiUpdateCustomFieldById } from 'store/customFields/network';

const typeColumnDef = {
  field: 'field_type',
  headerName: 'Type',
  openByDefault: true,
  enableRowGroup: true,
  cellRenderer: AgGridColumnCustomFieldTypeSelectRenderer,
  options: AVAILABLE_CUSTOMER_REQUEST_CUSTOM_FIELDS,
  cellEditorParams: {
    options: AVAILABLE_CUSTOMER_REQUEST_CUSTOM_FIELDS,
    showEmptyOption: false,
    sort: false,
  },
  editable: false,
  width: 150,
  maxWidth: 150,
  pinned: 'left',
  lockPinned: true,
  lockPosition: 'left',
};

const getCustomFieldsStatusColumnDef = canUpdate => ({
  ...statusColumnDef,
  editable: ({ data }) => canUpdate(data),
});

const getCustomFieldsColorColumnDef = canUpdate => ({
  ...colorColumnDef,
  width: 80,
  minWidth: 80,
  editable: ({ data }) => canUpdate(data),
});

const defaultColDef = {
  ...defaultColumnDefCommonProps,
  cellClass: params => (params.colDef.autoHeight ? 'ag-react-container--expandable' : 'ag-react-editable-container--expandable'),
  sortable: true,
  resizable: true,
  filter: true,
};

const defaultDeleteVisibility = () => true;

const useCustomFieldsGridColumns = ({
  canCreate,
  canUpdate,
  onLinkClickFormula,
  removeUnsavedCustomFields,
  canDelete,
  setItemToDelete,
  updateCustomFieldById,
  hasColorColumn,
}) => {
  const typeActionsColumnDef = useMemo(
    () => ({
      ...typeColumnDef,
      onRemove: removeUnsavedCustomFields,
    }),
    [removeUnsavedCustomFields],
  );

  const titleColumnDef = {
    ...getTitleColumnDef(CUSTOM_FIELD_TITLE, 'title', ({ data }) => (data?.id ? canUpdate(data) : canCreate)),
    pinned: 'left',
    lockPinned: true,
    lockPosition: 'left',
  };

  const descriptionColumnDefs = useMemo(
    () => ({
      field: 'description',
      headerName: 'Description',
      openByDefault: true,
      enableRowGroup: true,
      suppressMovable: true,
      editable: params => {
        return params.data.field_type !== FORMULA && checkEditablePermission(params, canUpdate);
      },
      valueGetter: params => getDescriptionValue(params, canUpdate),
      cellRenderer: AgGridColumnCustomFieldDescriptionRenderer,
      updateOptions: (id, options) => updateCustomFieldById(id, { data: options }, true),
      apiUpdateCustomFieldById: (id, options) => apiUpdateCustomFieldById(id, { data: options }, true),
      updateFormula: (id, formula) => updateCustomFieldById(id, { description: formula }),
      isDropdown: params => checkIfCustomFieldIsDropdown(params.data.field_type),
      hasLink: params => params.data.field_type === FORMULA,
      linkIcon: <OpenInNewIcon />,
      onLinkClick: onLinkClickFormula,
      width: 400,
    }),
    [canUpdate],
  );

  const afterColumnDefs = useMemo(() => {
    if (canDelete) {
      return [
        {
          field: ACTIONS_FIELD,
          headerName: '',
          editable: false,
          suppressMovable: true,
          filter: false,
          resizable: false,
          cellRenderer: ActionsCellRenderer,
          headerClass: 'centered-header',
          cellRendererParams: {
            actions: [
              {
                icon: DeleteIcon,
                color: materialColors.darkerGray,
                getVisibility: defaultDeleteVisibility,
                onClick: setItemToDelete,
              },
            ],
          },
          width: ACTIONS_WIDTH,
          suppressMenu: true,
        },
      ];
    }

    return [];
  }, [canDelete, setItemToDelete]);

  const columnDefs = useMemo(
    () => [
      typeActionsColumnDef,
      titleColumnDef,
      descriptionColumnDefs,
      getCustomFieldsStatusColumnDef(canUpdate),
      ...(hasColorColumn ? [getCustomFieldsColorColumnDef(canUpdate)] : []),
      updatedColumnDef,
    ],
    [typeActionsColumnDef, canUpdate],
  );

  return {
    columnDefs,
    defaultColDef,
    afterColumnDefs,
  };
};

export default useCustomFieldsGridColumns;
