import { notificationSystems } from './NotificationSystems';

export class BaseNotification {
  constructor(message, type, system = notificationSystems.TOAST) {
    this.message = message;
    this.type = type;
    this.system = system;
  }

  getMessage() {
    return this.message ?? 'Notification without message';
  }

  getType() {
    return this.type ?? '';
  }

  getSystem() {
    return this.system;
  }
}
