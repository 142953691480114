import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_METRIC_BY_ID_LIGHTBOX'),
  ...getThunkActionTypes('OPEN_METRIC_LIGHTBOX'),
  ...getThunkActionTypes('UPDATE_METRIC_BY_ID_LIGHTBOX'),
  ...getThunkActionTypes('CREATE_METRIC_LIGHTBOX'),
  ...getThunkActionTypes('CREATE_METRIC_VALUE_LIGHTBOX'),
  ...getThunkActionTypes('UPDATE_METRIC_VALUE_LIGHTBOX'),
  ...getThunkActionTypes('DELETE_METRIC_VALUE_LIGHTBOX'),
  ...getThunkActionTypes('CREATE_METRIC_ROADMAP_LIGHTBOX'),
  ...getThunkActionTypes('DELETE_METRIC_ROADMAP_LIGHTBOX'),
  ...getThunkActionTypes('BULK_DELETE_METRIC_ROADMAP_LIGHTBOX'),
  ...getThunkActionTypes('FETCH_METRIC_NEXT_COMMENTS'),
  ...getThunkActionTypes('CREATE_METRIC_COMMENT'),
  ...getThunkActionTypes('DELETE_METRIC_COMMENT'),
  ...getThunkActionTypes('UPDATE_METRIC_COMMENT'),
};

export const ADD_METRIC_VALUE_WITHOUT_SAVE_LIGHTBOX = 'ADD_METRIC_VALUE_WITHOUT_SAVE_LIGHTBOX';
export const REMOVE_UNSAVED_METRIC_VALUES_LIGHTBOX = 'REMOVE_UNSAVED_METRIC_VALUES_LIGHTBOX';
export const RESET_DATA = 'RESET_DATA';

export const {
  OPEN_METRIC_LIGHTBOX,
  OPEN_METRIC_LIGHTBOX_FULFILLED,
  FETCH_METRIC_BY_ID_LIGHTBOX,
  FETCH_METRIC_BY_ID_LIGHTBOX_FULFILLED,
  UPDATE_METRIC_BY_ID_LIGHTBOX,
  UPDATE_METRIC_BY_ID_LIGHTBOX_FULFILLED,
  CREATE_METRIC_VALUE_LIGHTBOX,
  CREATE_METRIC_VALUE_LIGHTBOX_FULFILLED,
  UPDATE_METRIC_VALUE_LIGHTBOX,
  UPDATE_METRIC_VALUE_LIGHTBOX_FULFILLED,
  DELETE_METRIC_VALUE_LIGHTBOX,
  DELETE_METRIC_VALUE_LIGHTBOX_FULFILLED,
  CREATE_METRIC_LIGHTBOX,
  CREATE_METRIC_LIGHTBOX_FULFILLED,
  CREATE_METRIC_ROADMAP_LIGHTBOX,
  CREATE_METRIC_ROADMAP_LIGHTBOX_FULFILLED,
  DELETE_METRIC_ROADMAP_LIGHTBOX,
  DELETE_METRIC_ROADMAP_LIGHTBOX_FULFILLED,
  BULK_DELETE_METRIC_ROADMAP_LIGHTBOX,
  BULK_DELETE_METRIC_ROADMAP_LIGHTBOX_FULFILLED,
  FETCH_METRIC_NEXT_COMMENTS,
  FETCH_METRIC_NEXT_COMMENTS_FULFILLED,
  CREATE_METRIC_COMMENT,
  CREATE_METRIC_COMMENT_FULFILLED,
  DELETE_METRIC_COMMENT,
  DELETE_METRIC_COMMENT_FULFILLED,
  UPDATE_METRIC_COMMENT,
  UPDATE_METRIC_COMMENT_FULFILLED,
} = actionTypes;
