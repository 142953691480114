import React from 'react';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import isString from 'lodash/isString';

import theme, { spacing } from '../../theme';

export default ({ tabs, selectedTab, handleTabChange, tabStyles, checkIfTabIsDisabled, ...props }) => {
  return (
    <Tabs
      value={selectedTab}
      onChange={handleTabChange}
      textColor="primary"
      TabIndicatorProps={{ style: { background: theme.palette.primary.main } }}
      {...props}
    >
      {tabs.map(tab => {
        const tabLabel = isString(tab) ? tab : tab.label;
        const tabTestId = isString(tab) ? null : tab.testId;

        return (
          <StyledTab
            id={tab?.id}
            label={tabLabel}
            value={tabLabel}
            tabStyles={tabStyles}
            isSelectedTab={tabLabel === selectedTab}
            disabled={checkIfTabIsDisabled ? checkIfTabIsDisabled(tabLabel) : false}
            data-testid={tabTestId}
          />
        );
      })}
    </Tabs>
  );
};

const StyledTab = styled(Tab)`
  && {
    font-size: ${({ theme }) => theme.typography.fontSizeMediumSmall}px;
    color: ${({ theme }) => theme.palette.text.primary};
    max-width: max-content;
    min-width: fit-content;
    margin-right: ${spacing(3)}px;
    text-transform: none;

    ${({ tabStyles }) => tabStyles && tabStyles}

    & > span > span {
      padding: 0;
    }
  }
`;
