import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import head from 'lodash/head';
import { isEmpty } from 'ramda';
import { useDebouncedCallback } from 'use-debounce';

import RoadmapTable from 'design-system/organisms/RoadmapTable/index';


import {
  convertKeyIntoArray,
  createRowOrderKey,
  DAY,
  DEFAULT_SIDEBAR_WIDTH,
  distanceBetweenDates,
  durationInDays,
  generateSidebarDifference,
  generateTodayLeft,
  getDateDiffInDays,
  getTimelineItemsFromData,
  getTimelineOrders,
  LEFT_DIRECTION,
  MIN_DELTA_TO_SNAP,
  ROW_HEIGHT,
  SCROLL_LEFT_TIMEOUT,
  VIEW_MODE_RELATIONS,
} from './helpers';
import useTimelineDateRange from './hooks/useTimelineDateRange';
import useTimelineLocalState from './hooks/useTimelineLocalState';
import TimelineBody from './components/TimelineBody';
import TimelineHeader from './components/TimelineHeader';
import LanesLoadingOverlay from './components/LanesLoadingOverlay';

const MIN_BAR_WIDTH = 22;

/**
 * This component should be independent from the page where is being used.
 *
 * */
const Timeline = ({
  // data
  data,
  isLoading,
  firstGroupTitle,
  secondGroupTitle,
  shouldResetData,

  // options
  contentStyle,
  zoomMode,
  groupbarWidth,
  sidebarWidth,
  slotWidth,
  displayMilestone,
  displayMilestoneOn,
  showTooltip,
  showTextOverflow,
  snapToGridOn,
  resizable,
  draggable,
  customRenderTitle,
  barRenderer,
  minBarWidth = MIN_BAR_WIDTH,

  // handlers
  onResizeFirstGroupHeader,
  onResizeSecondGroupHeader,
  onDoubleClick,
  onRowClick,
  onDrag,
  onResize,
}) => {
  const contentRef = useRef(null);

  const isMultiGroup = !!secondGroupTitle;
  const hasScroll = contentRef?.current && contentRef.current.scrollHeight > contentRef.current.clientHeight;

  const [scrollCoordinates, setScrollCoordinates] = useState({
    clientWidth: 0,
    scrollWidth: 0,
    scrollLeft: 0,
  });

  // Calculate the groups offsets
  const previousGroupbarWidth = useRef(groupbarWidth ?? DEFAULT_SIDEBAR_WIDTH);
  const previousSidebarWidthWidth = useRef(sidebarWidth ?? DEFAULT_SIDEBAR_WIDTH);

  const groupbarOffset = generateSidebarDifference(previousGroupbarWidth.current, groupbarWidth);
  const sidebarOffset = generateSidebarDifference(previousSidebarWidthWidth.current, sidebarWidth);

  // Generate date range and slots for Timeline
  const [fromDate, toDate, updateDateRange, slots] = useTimelineDateRange(zoomMode);

  // Convert data into Timeline items
  const { timelineData, timelineOrders } = useMemo(() => {
    const timelineData = getTimelineItemsFromData(data, zoomMode, slotWidth, fromDate, minBarWidth, snapToGridOn);
    const timelineOrders = getTimelineOrders(timelineData);

    return {
      timelineData,
      timelineOrders,
    };
  }, [data, groupbarOffset, sidebarOffset, zoomMode, slotWidth, fromDate, snapToGridOn]);

  // Internal state hook manager
  const { internalData, internalOrders, refreshInternalStateAfterDrag, refreshInternalState } = useTimelineLocalState(
    timelineData,
    timelineOrders,
    isMultiGroup,
    isLoading,
    shouldResetData,
  );

  const todayLine = useMemo(() => {
    const today = moment();
    const distance = distanceBetweenDates(fromDate, today, zoomMode) * slotWidth;
    const distanceRelation = distanceBetweenDates(fromDate, today.endOf('day'), VIEW_MODE_RELATIONS[zoomMode]);

    return (
      distance +
      (groupbarWidth || DEFAULT_SIDEBAR_WIDTH) +
      (secondGroupTitle ? sidebarWidth || DEFAULT_SIDEBAR_WIDTH : 0) +
      // add 1px for each relation slot before this item
      Math.round(distanceRelation)
    );
  }, [fromDate, zoomMode, groupbarWidth, sidebarWidth, secondGroupTitle, slotWidth]);

  // Handlers

  const handleResizeFirstGroupHeader = (_, width) => {
    onResizeFirstGroupHeader(+width);
  };

  const handleResizeSecondGroupHeader = (_, width) => {
    onResizeSecondGroupHeader(+width);
  };

  const handleRowClick = useCallback(
    (lane, group) => () => onRowClick(lane.key, lane.id, group && group.id ? group.id : null),
    [onRowClick],
  );

  const handleDrag =
    (task, laneIndex, rowIndex, groupIndex) =>
    (...params) => {
      // horizontal / date changes
      const delta = head(params);
      const horizontalSteps =
        snapToGridOn || zoomMode === DAY
          ? Math.round((delta.x - task.info.left) / slotWidth)
          : (delta.x - task.info.left) / slotWidth;

      const dateDiff = getDateDiffInDays(task, horizontalSteps, zoomMode, snapToGridOn);

      // vertical / lane changes
      let skipVertical = delta.deltaY === delta.y;

      // if should only update when the user drag to a different slot
      if (snapToGridOn && skipVertical && Math.abs(horizontalSteps) < MIN_DELTA_TO_SNAP) {
        return;
      }

      const oldOrderId = isMultiGroup
        ? createRowOrderKey(internalData?.[laneIndex]?.id, internalData?.[laneIndex]?.groups?.[groupIndex]?.id, rowIndex)
        : createRowOrderKey(internalData?.[laneIndex]?.id, rowIndex);
      const oldOrderIndex = internalOrders.findIndex(o => o === oldOrderId);
      const oldOrder = convertKeyIntoArray(oldOrderId);

      const newBaseOrderIndex = Math.round(Math.abs(delta.lastY + (hasScroll ? ROW_HEIGHT : 0)) / ROW_HEIGHT);
      const newOrderIndex = hasScroll ? Math.max(newBaseOrderIndex - 1, 0) : newBaseOrderIndex;
      const newOrder = convertKeyIntoArray(internalOrders[newOrderIndex]);

      if (!internalOrders[newOrderIndex]) {
        // update date diff, but not lane
        skipVertical = true;
      }

      // have all the information to pass the drag callback
      onDrag(...params, task, dateDiff, skipVertical, oldOrder, newOrder);

      refreshInternalStateAfterDrag({
        task,
        laneIndex,
        groupIndex,
        rowIndex,
        delta,
        skipVertical,
        dateDiff,
        oldOrderIndex,
        oldOrder,
        newOrder,
        zoomMode,
        slotWidth,
        fromDate,
        snapToGridOn,
        minBarWidth,
      });
    };

  const handleResize =
    (task, laneIndex, rowIndex, groupIndex) =>
    (...params) => {
      const [direction, delta, position] = params;

      const internalTask = isMultiGroup
        ? internalData?.[laneIndex]?.groups?.[groupIndex]?.rows?.[rowIndex]?.find(({ id }) => id === task.id)
        : internalData?.[laneIndex]?.rows?.[rowIndex]?.find(({ id }) => id === task.id);

      if (internalTask) {
        if (direction === LEFT_DIRECTION) {
          const steps =
            snapToGridOn || zoomMode === DAY
              ? Math.round((position.x - internalTask.info.left) / slotWidth)
              : (position.x - internalTask.info.left) / slotWidth;

          const dateDiff = durationInDays(steps, zoomMode);

          onResize(...params, task, laneIndex, groupIndex, dateDiff);

          internalTask.info.left = position.x;
          internalTask.info.width += delta.width;
        } else {
          const steps = snapToGridOn ? Math.round(delta.width / slotWidth) : delta.width / slotWidth;

          const dateDiff = durationInDays(steps, zoomMode);

          onResize(...params, task, laneIndex, groupIndex, dateDiff);

          internalTask.info.width += delta.width;
        }
      }

      refreshInternalState([...internalData]);
    };

  const handleScroll = event => {
    const relationMode = VIEW_MODE_RELATIONS[zoomMode];

    const left = event.target.scrollLeft;
    const { scrollWidth, clientWidth, scrollTop } = contentRef.current;

    setScrollCoordinates({
      clientWidth,
      scrollWidth,
      scrollLeft: left,
      scrollTop,
    });

    // Update only fromDate or toDate

    const isLeftScroll = left === 0;

    if (isLeftScroll) {
      const newDate = fromDate.clone().subtract(1, relationMode).startOf(relationMode).startOf(zoomMode);

      if (newDate.date() !== 1) {
        newDate.add(1, zoomMode);
      }

      const distance = distanceBetweenDates(newDate, fromDate);

      updateDateRange(newDate, undefined);

      contentRef.current.scrollLeft = distance;

      return;
    }

    const isRightScroll = left + clientWidth === scrollWidth;

    if (isRightScroll) {
      const newDate = toDate.clone().add(1, relationMode).endOf(relationMode);

      updateDateRange(undefined, newDate);
    }
  };

  const shouldShowBar = useCallback(
    task => {
      if (task.info.left > scrollCoordinates.scrollLeft + scrollCoordinates.clientWidth) {
        return false;
      }

      return task.info.left + task.info.width >= scrollCoordinates.scrollLeft;
    },
    [scrollCoordinates],
  );

  const [debouncedSetScrollLeft] = useDebouncedCallback(left => {
    if (contentRef.current) {
      contentRef.current.scrollLeft = left;
    }
  }, SCROLL_LEFT_TIMEOUT);

  // Effects

  useEffect(() => {
    if (contentRef?.current) {
      setScrollCoordinates({
        clientWidth: contentRef.current.clientWidth,
        scrollLeft: contentRef.current.scrollLeft,
        scrollWidth: contentRef.current.scrollWidth,
      });
    }
  }, [contentRef?.current]);

  useEffect(() => {
    if (contentRef?.current) {
      contentRef.current.scrollTop = scrollCoordinates.scrollTop;
    }
  }, [contentRef?.current, timelineOrders?.length]);

  useEffect(() => {
    if (todayLine && contentRef?.current) {
      const left = Math.round(todayLine) - groupbarWidth - sidebarWidth;

      debouncedSetScrollLeft(generateTodayLeft(left));
    }
  }, [todayLine, debouncedSetScrollLeft]);

  if (isEmpty(timelineData)) {
    return null;
  }

  return (
    <Content ref={contentRef} style={contentStyle} onScroll={handleScroll}>
      <RoadmapTable>
        <TimelineHeader
          slots={slots}
          fromDate={fromDate}
          toDate={toDate}
          groupbarWidth={groupbarWidth}
          sidebarWidth={sidebarWidth}
          firstGroupTitle={firstGroupTitle}
          secondGroupTitle={secondGroupTitle}
          zoomMode={zoomMode}
          slotWidth={slotWidth}
          onResizeFirstGroupHeader={handleResizeFirstGroupHeader}
          onResizeSecondGroupHeader={handleResizeSecondGroupHeader}
        />
        <TimelineBody
          isLoading={isLoading}
          isMultiGroup={isMultiGroup}
          data={internalData}
          orders={internalOrders}
          fromDate={fromDate}
          groupbarWidth={groupbarWidth}
          sidebarWidth={sidebarWidth}
          groupbarOffset={groupbarOffset}
          sidebarOffset={sidebarOffset}
          mode={zoomMode}
          displayMilestone={displayMilestone}
          displayMilestoneOn={displayMilestoneOn}
          slotWidth={slotWidth}
          showTooltip={showTooltip}
          showTextOverflow={showTextOverflow}
          todayLine={todayLine}
          snapToGridOn={snapToGridOn}
          shouldShowBar={shouldShowBar}
          resizable={resizable}
          draggable={draggable}
          customRenderTitle={customRenderTitle}
          onDoubleClick={onDoubleClick}
          onRowClick={handleRowClick}
          onDrag={handleDrag}
          onResize={handleResize}
          barRenderer={barRenderer}
        />
        {isLoading && (
          <LanesLoadingOverlay
            hasScroll={hasScroll}
            clientWidth={contentRef?.current?.clientWidth}
            clientHeight={contentRef?.current?.clientHeight}
            scrollLeft={contentRef?.current?.scrollLeft}
            scrollTop={contentRef?.current?.scrollTop}
            groupbarWidth={groupbarWidth}
            sidebarWidth={isMultiGroup ? sidebarWidth : 0}
          />
        )}
      </RoadmapTable>
    </Content>
  );
};

export default Timeline;

const Content = styled.div`
  &&&& {
    width: calc(100% - 88px);
    box-sizing: border-box;
    border: 1px solid ${({ theme }) => theme.palette.border.mercury};
    overflow: scroll;
    position: relative;
  }
`;
