import React from 'react';
import styled from 'styled-components';

import ListItem from '@material-ui/core/ListItem';

import { spacing } from 'design-system/theme';
import Text from 'design-system/atoms/Text/index';
import SortIcon from 'design-system/atoms/SortIcon';
import { LIST_HEADERS } from 'containers/UserViewsList/constants';

const { PAGE, NAME, VISITS_COUNT, OWNER } = LIST_HEADERS;

export default ({ showUserViewCounts, totalViewsUnit, setTotalViewsUnit, sortBy, sortDirection, handleSort }) => {
  const renderSortIcon = field => {
    if (sortBy === field) return <StyledSortIcon direction={sortDirection} />;
  };

  return (
    <StyledListItem>
      <ListItemContainer>
        <PageHeader variant="h6">{PAGE.label}</PageHeader>
        <ViewTitleHeader variant="h6" onClick={() => handleSort(NAME.key)}>
          {NAME.label}
          {renderSortIcon(NAME.key)}
        </ViewTitleHeader>
        {showUserViewCounts && (
          <ViewCounterHeader variant="h6" onClick={() => handleSort(VISITS_COUNT.key)}>
            {VISITS_COUNT.label}
            {renderSortIcon(VISITS_COUNT.key)}
          </ViewCounterHeader>
        )}
      </ListItemContainer>
      <OwnerHeader>
        <BaseHeaderText variant="h6" onClick={() => handleSort(OWNER.key)}>
          {OWNER.label}
          {renderSortIcon(OWNER.key)}
        </BaseHeaderText>
      </OwnerHeader>
    </StyledListItem>
  );
};

const StyledListItem = styled(ListItem)`
  && {
    justify-content: space-between;
  }
`;

const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const BaseHeaderText = styled(Text)`
  position: relative;
  cursor: pointer;
`;

const PageHeader = styled(BaseHeaderText)`
  width: 61px;
  text-align: center;
  cursor: default;
`;

const ViewTitleHeader = styled(BaseHeaderText)`
  margin-left: ${spacing(2)}px;
`;

const ViewCounterHeader = styled(BaseHeaderText)`
  margin-left: auto;
  text-align: right;
`;

const OwnerHeader = styled.div`
  min-width: 150px;
  display: flex;
  justify-content: center;
`;

const StyledSortIcon = styled(SortIcon)`
  position: absolute;
  left: calc(100% + ${spacing()}px);
`;
