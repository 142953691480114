
export const getProjectIntegration = (projectIntegrations, orgIntegration) => {
  if (!projectIntegrations || !orgIntegration) return null;

  const projIntegrationsFromOrgIntegration = projectIntegrations.filter((pi) => {
    return pi.org_integration_id === orgIntegration.id;
  });

  if (projIntegrationsFromOrgIntegration && projIntegrationsFromOrgIntegration.length === 1) {
    return projIntegrationsFromOrgIntegration[0];
  } else if (projIntegrationsFromOrgIntegration && projIntegrationsFromOrgIntegration.length) {
    return projIntegrationsFromOrgIntegration;
  }

  return null;
};
