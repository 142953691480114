import React, { createContext, useState, useContext, useCallback } from 'react';

const UserRoutesContext = createContext({});

const UserRoutesContextProvider = ({ children }) => {
  const [routes, setRoutes] = useState([]);

  const registerUserRoutes = useCallback(data => setRoutes(data), []);

  return <UserRoutesContext.Provider value={{ registerUserRoutes, routes }}>{children}</UserRoutesContext.Provider>;
};

/**
 * Custom hook for accessing the UserRoutesContext.
 *
 * @returns {Object} - The UserRoutesContext object.
 */
const useUserRoutesContext = () => useContext(UserRoutesContext);

export { UserRoutesContextProvider };

export default useUserRoutesContext;
