import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Typography from '@material-ui/core/Typography';

import DndAtoms from 'design-system/atoms/DndAtoms/index';

import { spacing } from 'design-system/theme';

const { MenuIcon, Checkbox, MenuText } = DndAtoms;

const ShowArchivedRow = props => {
  const { show, onClick, title } = props;

  return (
    <Wrapper>
      <StyledMenuIcon>
        <SmallCheckbox checked={show} onClick={onClick} />
      </StyledMenuIcon>
      <MenuText onClick={onClick}>
        <Typography variant="body1">{title}</Typography>
      </MenuText>
    </Wrapper>
  );
};

ShowArchivedRow.propTypes = {
  show: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

ShowArchivedRow.defaultProps = {
  show: false,
  title: 'Show Archived',
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  background: white;
  border-top: 1px solid #fafafa;
  box-shadow: 0px -2px 12px 0px rgb(204 204 204 / 30%);
`;

const StyledMenuIcon = styled(MenuIcon)`
  &&&& {
    margin-right: ${spacing(1)}px;
  }
`;

const SmallCheckbox = styled(Checkbox)`
  &&&& {
    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
`;

export default ShowArchivedRow;
