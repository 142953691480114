// External dependencies
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

// Dragonboat dependencies
import { filterActiveItens } from 'utils';
import sortByRank from 'utils/sortByRank';

export function getState(state) {
  return state.themes;
}

export const makeGetThemes = () =>
  createCachedSelector([getState, (_, showArchived) => showArchived], (state, showArchived) =>
    (showArchived ? state.themes : state.themes.filter(filterActiveItens)).sort(sortByRank),
  )((_, showArchived) => `themes:${showArchived}`);

export const getThemes = makeGetThemes();

export const makeGetNormalizedThemes = () => {
  const _localGetThemes = makeGetThemes();

  return createSelector(
    state => _localGetThemes(state, true),
    state =>
      state.reduce((objs, obj) => {
        objs[obj.id] = obj;
        return objs;
      }, {}),
  );
};

export const getNormalizedThemes = makeGetNormalizedThemes();
