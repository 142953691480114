import React from 'react';
import styled, { css } from 'styled-components';

import Card from '@material-ui/core/Card';

const SnapshotItemWrapper = ({ children, child = false, className }) => {
  return (
    <Wrapper child={child} className={className}>
      <ContentContainer child={child} elevation={2}>
        {children}
      </ContentContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 600px;
  min-width: 400px;
  margin: 0 28px;

  ${({ child }) =>
    child &&
    css`
      width: 400px;
      margin: 0 14px;
    `}

  span, div {
    font-family: ${props => props.theme.typography.fontFamily} !important;
  }

  @media only screen and (max-width: 1500px) {
    width: 500px;
    margin: 0 14px;
  }
`;

const ContentContainer = styled(Card)`
  margin: 28px 0;

  &&&& {
    border: ${({ theme }) => `1px solid ${theme.palette.border.primary}`};
    border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;
    box-shadow: none;

    overflow: hidden;

    cursor: pointer;

    width: 100%;

    min-height: 312px;

    ${({ child }) =>
      child &&
      css`
        width: 100%;

        min-height: 269px;

        margin: 14px 0;
      `}

    @media only screen and (max-width: 1500px) {
      margin: 14px 0;
    }
  }
`;

export default SnapshotItemWrapper;
