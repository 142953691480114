/* eslint-disable */
import React from 'react';
import styled, { withTheme } from 'styled-components';

export default withTheme(({ fill, theme, height = 18, width = 18, ...props }) => {
  return (
    <SVG width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7 16C7.9625 16 8.75 15.2615 8.75 14.359H5.25C5.25 15.2615 6.0375 16 7 16ZM12.25 11.0769V6.97436C12.25 4.45538 10.8237 2.34667 8.3125 1.78872V1.23077C8.3125 0.549744 7.72625 0 7 0C6.27375 0 5.6875 0.549744 5.6875 1.23077V1.78872C3.185 2.34667 1.75 4.44718 1.75 6.97436V11.0769L0 12.7179V13.5385H14V12.7179L12.25 11.0769ZM10.5 11.8974H3.5V6.97436C3.5 4.93949 4.82125 3.28205 7 3.28205C9.17875 3.28205 10.5 4.93949 10.5 6.97436V11.8974Z"
        fill={fill || theme.palette.newLayout.background.disabled}
      />
    </SVG>
  );
});

const SVG = styled.svg`
  path {
    color: ${props => props.color};
  }
`;
