import { createSelector } from 'reselect';
import { defaultTo } from 'ramda';
import { getData } from 'utils/store/thunk';
import { UPDATE_DRAGONBOT_CORPUS_VERSION } from './types';

const defaultToEmptyObject = defaultTo({});

export const getState = state => {
  return state.dragonbot;
};

export const getOperations = createSelector(getState, state => {
  return defaultToEmptyObject(state.operations);
});

export const getLastDragonbotPromptResult = createSelector(
  [getState],
  state => state?.lastPromptResult || [],
);

export const getDragonbotCorpusVersions = createSelector(
  [getState],
  state => state?.corpusVersions || [],
);

export const getCurrentDragonbotCorpusVersion = createSelector(
  [getState, getDragonbotCorpusVersions],
  (state, corpusVersions) => corpusVersions.find(corpus => corpus.current),
);

export const updateDragonbotCorpusFulfilled = createSelector(
  [getOperations],
  (state) => getData(state, UPDATE_DRAGONBOT_CORPUS_VERSION),
);

export const isDragonbotOpen = createSelector(
  [getState],
  state => state.botWindowOpen,
);
