import { defaultTo, filter, last, map, pipe, prop, sort } from 'ramda';

const getMaxEndDateFromIntegratedCurrentProgresses = pipe(
  defaultTo([]),
  filter(progressEntry => !!progressEntry.org_integration_id),
  map(prop('end_date')),
  sort((a, b) => a.localeCompare(b)),
  last,
  defaultTo(''),
);

export default project => {
  // If item has reported date from integration use that as predicted end date

  // TODO: Integration progress is legacy as its essencially the same data source as currentProgresses
  // is deprecated and could be removed
  if (project.integrationProgress?.endDate) {
    return project.integrationProgress.endDate;
  }

  // Use the max end date for the item based on rolled up data integration (if any)
  // Usually an item has only one current progress but in the event of multiple integrations
  // to exist we account for the max end date one
  const endDateFromIntegratedCurrentProgress = getMaxEndDateFromIntegratedCurrentProgresses(project.currentProgresses);

  if (endDateFromIntegratedCurrentProgress) {
    return endDateFromIntegratedCurrentProgress;
  }

  // If no integration reported dates exist use the rolled up end date
  // as this date already uses the defined criteria to account for all the children
  // and item target dates
  return project.end_date_calculated;
};
