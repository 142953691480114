import React from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import useTestRallyConnection from './hooks/useTestRallyConnection';

const TestRallyConnection = ({ orgIntegrationId, handleTestConnection }) => {
  const { isTestingRallyConnection, handleTestRallyConnection } = useTestRallyConnection(orgIntegrationId, handleTestConnection);

  return (
    <Wrapper>
      <Button
        id="test-rally-connection-button"
        color="primary"
        onClick={handleTestRallyConnection}
        disabled={isTestingRallyConnection}
      >
        {isTestingRallyConnection ? 'TESTING' : 'TEST MY RALLY CONNECTION'}
      </Button>
      {isTestingRallyConnection && <CircularProgress size={25} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &&&& {
    display: flex;
    align-items: center;
  }
`;

export default TestRallyConnection;
