/**
 * @function getHighestLayer
 *
 * returns the highest layer from an array of layers ([0,1,2] for example)
 *
 * @param  {Array} layers
 * @return {Number}
 */
const getHighestLayer = (layers = []) => {
  const highest = Math.max(...layers.map(l => +l));

  return String(highest);
};

export default getHighestLayer;
