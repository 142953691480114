import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { defaultTo } from 'ramda';

import {
  removeProjectIntegration,
  addProjectIntegration,
  createProjectIntegration,
  addProjectIntegrationOutboundLink,
} from 'store/integrations';
import { getAddUserIntegrationError } from 'store/login/selectors';

import getRenderIntegrationMethod from './utils/getRenderIntegrationMethod';
import useIntegrationsTabs from './hooks/useIntegrationsTabs';
import useLightboxProjectIntegrations from 'containers/ProjectLightBox/hooks/useLightboxProjectIntegrations';
import { getStoriesByProjectId } from 'store/projects/selectors';

const defaultToEmptyString = defaultTo('');

const integrationsHOC = () => {
  return props => {
    const selectedProject = useRef();
    const dispatch = useDispatch();
    const [updatedProject, setUpdatedProject] = useState(props.selectedProject);

    const organizationHasWebhooks = useSelector(
      state => state.organization.organization && state.organization.organization.has_webhooks,
    );
    const userIntegrations = useSelector(state => state.login.integrations || []);

    const { projectIntegrations, isProjectIntegrationsLoading } = useLightboxProjectIntegrations(props.selectedProject);

    const [isLinking, setIsLinking] = useState(false);
    const [linkErrorMsg, setLinkErrorMsg] = useState('');
    const [isCreating, setIsCreating] = useState(false);
    const [createErrorMsg, setCreateErrorMsg] = useState('');

    const stories = useSelector(state => getStoriesByProjectId(state, props.selectedProject?.id));
    const addUserIntegrationError = useSelector(getAddUserIntegrationError);
    const _cleanErrors = () => {
      setLinkErrorMsg('');
      setCreateErrorMsg('');
    };

    // change updated projects state when selected project id changes
    useEffect(() => {
      selectedProject.current = props.selectedProject;
      setUpdatedProject(props.selectedProject);
    }, [props.selectedProject.id]);

    // send back new project info to lightbox when project is synced
    useEffect(() => {
      props.onLink(null, updatedProject);
    }, [JSON.stringify(updatedProject)]);

    const _createProjectIntegration = async data => {
      _cleanErrors();

      setIsCreating(true);

      let selectedProjectId;

      if (!props.selectedProject.id) {
        try {
          const newProject = await _updateProject();

          selectedProjectId = newProject.id;
        } catch (error) {
          setIsCreating(false);
          throw error;
        }
      } else {
        selectedProjectId = props.selectedProject.id;
      }

      try {
        await dispatch(createProjectIntegration(selectedProjectId, data));
      } catch (err) {
        const errorMessage = err?.response?.data?.message || err?.response?.data;

        setCreateErrorMsg(defaultToEmptyString(errorMessage));
      }

      setIsCreating(false);
    };

    const _linkProjectIntegration = async (epicId, data) => {
      _cleanErrors();

      setIsLinking(true);

      let selectedProjectId;

      if (!props.selectedProject.id) {
        let newProject;

        try {
          newProject = await _updateProject();
        } catch (error) {
          setIsLinking(false);
          throw error;
        }

        selectedProjectId = newProject.id;
      } else {
        selectedProjectId = props.selectedProject.id;
      }

      try {
        await dispatch(addProjectIntegration(selectedProjectId, epicId, data));
      } catch (err) {
        const errorMessage = err?.response?.data?.message || err?.response?.data;

        setLinkErrorMsg(errorMessage);
      }

      setIsLinking(false);
    };

    const _linkProjectIntegrationOutbound = async (epicId, data) => {
      _cleanErrors();

      setIsLinking(true);

      let selectedProjectId;

      if (!props.selectedProject.id) {
        let newProject;

        try {
          newProject = await _updateProject();
        } catch (error) {
          setIsLinking(false);
          throw error;
        }

        selectedProjectId = newProject.id;
      } else {
        selectedProjectId = props.selectedProject.id;
      }

      try {
        await dispatch(addProjectIntegrationOutboundLink(selectedProjectId, epicId, data));
      } catch (err) {
        const errorMessage = err?.response?.data?.message || err?.response?.data;

        setLinkErrorMsg(errorMessage);
      }

      setIsLinking(false);
    };

    const _unlinkProjectIntegration = async (projectIntegrationId, data) => {
      if (!projectIntegrationId) return;

      await dispatch(removeProjectIntegration(props.selectedProject.id, projectIntegrationId, data));
    };

    const _updateProject = async (data, sendAll) => {
      const result = await props.updateProject(data, sendAll);

      setUpdatedProject(result);
      return result;
    };

    const _renderIntegration = integration => {
      if (!integration) return '';

      return getRenderIntegrationMethod(integration, {
        disableActions: props.disableEdit,
        formData: props.formData,
        selectedProject: props.selectedProject,
        stories,
        updateProject: _updateProject,
        onLink: props.onLink,
        integration,
        userIntegrations: userIntegrations.filter(int => int.org_integration_id === integration.id),
        projectIntegrations,
        projectId: props.selectedProject ? props.selectedProject.id : null,
        loadingProjectIntegrations: isProjectIntegrationsLoading,
        createProjectIntegration: _createProjectIntegration,
        unlinkProjectIntegration: _unlinkProjectIntegration,
        linkProjectIntegration: _linkProjectIntegration,
        linkProjectIntegrationOutbound: _linkProjectIntegrationOutbound,
        addUserIntegrationError,
        // handleUseJiraProgress: props.handleUseJiraProgress,
        organizationHasWebhooks,
        linkErrorMsg,
        isLinking,
        createErrorMsg,
        isCreating,
      });
    };

    const [integrationsTabs] = useIntegrationsTabs(
      props.selectedProject,
      _renderIntegration,
      projectIntegrations,
      props.formData?.layer,
    );

    if (isProjectIntegrationsLoading) return '';

    return integrationsTabs;
  };
};

// Apply HOCs
export default compose(integrationsHOC);
