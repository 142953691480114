import AgGridGroupRenderer from 'components/AgGridGroupRenderer';
import getSystemFieldName from 'utils/getSystemFieldName';
import HierarchyAutocomplete, { cellParams as HierarchyAutocompleteCellParams } from 'components/AgGridHierarchyAutocomplete';

import { editOptions, returnsTrueIfKeyIsNotEscOrTab } from './agGrid';

export default (organization, roadmaps, wrapTextOptions, systemFields) => {
  if (organization && organization.has_products) {
    return [
      {
        ...editOptions,
        ...HierarchyAutocompleteCellParams(
          'product1Title',
          [{ parentAttribute: 'roadmapTitle', optionsAttribute: 'products' }],
          roadmaps,
        ),
        ...wrapTextOptions,
        suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
        headerName: getSystemFieldName('product1', systemFields),
        field: 'product1Title',
        enableRowGroup: true,
        cellRenderer: AgGridGroupRenderer(getSystemFieldName('product1', systemFields)),
        cellEditor: HierarchyAutocomplete,
        width: 120,
      },
      // {
      //   ...editOptions,
      //   ...HierarchyAutocompleteCellParams('product2Title', [
      //     { parentAttribute: 'roadmapTitle', optionsAttribute: 'products' },
      //     { parentAttribute: 'product1Title', optionsAttribute: 'products' },
      //   ], roadmaps, () => toast((<InfoToast>Please add or select a parent roadmap.</InfoToast>))),
      //   ...wrapTextOptions,
      //   suppressKeyboardEvent: returnsTrueIfKeyIsNotEnterOrTab,
      //   headerName: 'Product (Level 2)',
      //   field: 'product2Title',
      //   enableRowGroup: true,
      //   cellRenderer: AgGridGroupRenderer('Products (Level 2)'),
      //   cellEditor: HierarchyAutocomplete,
      //   width: 120,
      // },
    ];
  }

  return [];
};
