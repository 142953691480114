import React from 'react';
import styled from 'styled-components';

import { Button, IconButton, Tooltip } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

import AddNewProject from './components/AddNewProject';

const ProjectsVerticalTabs = props => {
  const {
    projects,
    selectedProjectKey,
    onChangeSelectedProjectKey,
    onAddNewProjectKey,
    onDeleteProjectKey,
    userCanUpdateOrgIntegration,
  } = props;

  return (
    <Wrapper>
      <ProjectRow>
        <LeftAlignedButton
          color={selectedProjectKey === 'DEFAULT' && 'primary'}
          onClick={() => onChangeSelectedProjectKey('DEFAULT')}
        >
          Default
        </LeftAlignedButton>
      </ProjectRow>
      {projects.map(projectKey => (
        <ProjectRow>
          <Tooltip title={projectKey} enterDelay={250}>
            <LeftAlignedButton
              color={selectedProjectKey === projectKey && 'primary'}
              onClick={() => onChangeSelectedProjectKey(projectKey)}
            >
              {projectKey}
            </LeftAlignedButton>
          </Tooltip>
          {selectedProjectKey === projectKey && (
            <DeleteIconButton
              id="delete-mapping-button"
              size="small"
              onClick={() => onDeleteProjectKey(projectKey)}
              disabled={!userCanUpdateOrgIntegration}
            >
              <DeleteIcon fontSize="small" />
            </DeleteIconButton>
          )}
        </ProjectRow>
      ))}
      <AddNewProject onAddNewProjectKey={onAddNewProjectKey} disabled={!userCanUpdateOrgIntegration} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid #ccc;
`;

const ProjectRow = styled.div`
  display: flex;
  width: 100%;
`;

const LeftAlignedButton = styled(Button)`
  flex-grow: 1;
  && {
    color: ${props => !props.color && '#6b6b6b'};
  }
  & > span {
    text-align: left;
    justify-content: normal;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const DeleteIconButton = styled(IconButton)`
  width: 36px;
  height: 36px;
`;

export default ProjectsVerticalTabs;
