import React from 'react';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';

const ShowMoreButton = ({ onClick, label = 'Show more', className }) => {
  return (
    <Button onClick={onClick} className={className}>
      {label}
    </Button>
  );
};

export default ShowMoreButton;

const Button = styled(MuiButton)`
  && {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    text-transform: none;
  }
`;
