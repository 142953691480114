import { ProgressCellRenderer } from '../cellRenderers';
import { ProgressCellEditor } from '../cellEditors';
import { applyCheckIsEditable } from '../helpers';

const getProgressColumnDef = editable => ({
  width: 60,
  editable: applyCheckIsEditable(editable),
  field: 'progress',
  headerName: 'Progress',
  breakwords: true,
  cellRenderer: ProgressCellRenderer,
  cellEditor: ProgressCellEditor,
});

export default getProgressColumnDef;
