import React from 'react';

import { BaseGrid } from 'containers/Grids';

import { useCommonGridEventHandlers, useCommonGridProps } from 'design-system/molecules/AgGridReact-New/hooks';
import useBulkUpdateList from './hooks/useBulkUpdateList';

import { BASE_ROW_HEIGHT } from 'constants/grid';
import { defaultColumnDefCommonProps } from 'design-system/molecules/AgGridReact-New/columns';
import { ID_KEY } from 'routes/Settings/Integration/components/MultiIntegrationsList/helpers/constants';
import {
  sumNumericFields,
  sumScopeVarianceField,
  sumPointsVarianceField,
} from 'design-system/molecules/AgGridReact-New/aggFuncs';
import { getRowId } from 'design-system/molecules/AgGridReact-New/helpers';

const defaultColDef = {
  ...defaultColumnDefCommonProps,
  cellClass: params => (params.colDef.autoHeight ? 'ag-react-container--expandable' : 'ag-react-editable-container--expandable'),
  sortable: true,
  resizable: true,
  filter: true,
};

const BulkUpdateList = ({ items, columnDefs, multiTables, updateField }) => {
  const { onGridReady, tableHeight, handleUpdateField } = useBulkUpdateList(items, multiTables, updateField);

  const commonGridProps = useCommonGridProps({
    aggFuncs: {
      sumNumericFields,
      sumScopeVarianceField,
      sumPointsVarianceField,
    },
  });
  const commonGridEventHandlers = useCommonGridEventHandlers({
    items,
    idKey: ID_KEY,
    actions: {
      update: handleUpdateField,
    },
  });

  return (
    <BaseGrid
      {...commonGridProps}
      {...commonGridEventHandlers}
      onGridReady={onGridReady}
      rowData={items}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      headerHeight={BASE_ROW_HEIGHT}
      height={tableHeight}
      getRowId={getRowId}
    />
  );
};

export default BulkUpdateList;
