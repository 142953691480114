/* eslint-disable react/no-array-index-key */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';

import { isMilestoneTopGroup } from '../../helpers';

import { TimelineCellContent } from '../styled';

import TimelineColumnGroupRow from './TimelineColumnGroupRow';

const NormalDoubleGrouping = ({ itemRenderer, data, groupsWidths, onRowClick, orders, groupTitleRenderer }) =>
  data.map((groupLevel1, level1Index) => (
    <TimelineColumnGroupRow
      key={groupLevel1.key}
      group={groupLevel1}
      groupWidth={groupsWidths[0]}
      groupTitleRenderer={groupTitleRenderer}
    >
      {isEmpty(groupLevel1.groups) ? (
        <TimelineCellContent />
      ) : (
        groupLevel1?.groups?.map((groupLevel2, level2Index) => (
          <TimelineColumnGroupRow
            key={`group-${(orders || []).length}-${(groupLevel2.rows || []).length}-${level2Index}`}
            group={groupLevel2}
            groupWidth={groupsWidths[1]}
            left={groupsWidths[0]}
            onClick={onRowClick([groupLevel1, groupLevel2])}
            groupTitleRenderer={groupTitleRenderer}
          >
            {isEmpty(groupLevel2.rows) ? (
              <TimelineCellContent />
            ) : (
              groupLevel2.rows.map((row, rowIndex) => {
                // TODO remove uuid
                // const key = generateMultiGroupRowKey(orders, [groupLevel1, groupLevel2],
                // rowIndex, groupOffsets, hideEmptyLane);

                return (
                  <TimelineCellContent key={uuidv4()}>
                    {row.map(item => itemRenderer(item, [level1Index, level2Index], rowIndex, isMilestoneTopGroup(groupLevel1)))}
                  </TimelineCellContent>
                );
              })
            )}
          </TimelineColumnGroupRow>
        ))
      )}
    </TimelineColumnGroupRow>
  ));

export default NormalDoubleGrouping;
