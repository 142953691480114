import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useSearchString from 'hooks/useHeaderSearchString';
import usePermissionsDialog from 'hooks/usePermissionsDialog';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import { getOrganization } from 'store/organization/selectors';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();

    const { isParentDragon } = useOrganizationsAccessControl();

    const path = window.location.pathname;

    const allocationState = useSelector(state => state.allocationReport);
    const organization = useSelector(getOrganization);

    const { isDialogVisible: isAccessDenied } = usePermissionsDialog();

    const { canUpdate } = usePermissions();

    const allowUpdate = canUpdate(PERMISSION_FEATURES.userDashbboard);

    const [search, setSearch] = useSearchString(path);

    const _setSearch = setSearch ? value => dispatch(setSearch(value)) : null;

    const _setSelectedView = path => {
      props.history.push(path);
    };

    return (
      <Component
        allocationState={allocationState}
        isReadOnlyUser={!allowUpdate}
        selectedView={path}
        setSelectedView={_setSelectedView}
        search={search}
        setSearch={_setSearch}
        organization={organization}
        isAccessDenied={isAccessDenied}
        isParentDragon={isParentDragon}
        {...props}
      />
    );
  };
};

export default componentHOC;
