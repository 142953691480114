import { PAGE_LOAD_OUTCOME_MODULE_GOALS_FULFILLED, UPDATE_OUTCOME_MODULE_FILTERS_FULFILLED } from 'store/goalMode';
import { objectivesAdapter, keyResultsAdapter } from './adapters';
import { defaultTo, values } from 'ramda';
import {
  ADD_KEY_RESULT_WITHOUT_SAVE,
  ADD_OBJECTIVE,
  ADD_OBJECTIVE_WITHOUT_SAVE,
  BULK_DELETE_KEY_RESULT_ROADMAP_FULFILLED,
  BULK_DELETE_OBJECTIVE_ROADMAP_FULFILLED,
  CREATE_KEY_RESULT_FROM_DRAWER_FULFILLED,
  CREATE_KEY_RESULT_FULFILLED,
  CREATE_KEY_RESULT_ROADMAP_FULFILLED,
  CREATE_OBJECTIVE,
  CREATE_OBJECTIVE_FROM_DRAWER_FULFILLED,
  CREATE_OBJECTIVE_ROADMAP_FULFILLED,
  DELETE_KEY_RESULT_ROADMAP_FULFILLED,
  DELETE_OBJECTIVE_ROADMAP_FULFILLED,
  REMOVE_UNSAVED_OBJECTIVES_AND_KEY_RESULTS,
  UPDATE_KEY_RESULT_ID_FULFILLED,
  UPDATE_OBJECTIVE_FROM_DRAWER_FULFILLED,
} from 'store/objectives';

export const initialState = {
  objectives: objectivesAdapter.getInitialState(),
  keyResults: keyResultsAdapter.getInitialState(),
};

const goalsGridReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAGE_LOAD_OUTCOME_MODULE_GOALS_FULFILLED:
      return {
        ...state,
        objectives: objectivesAdapter.setAll(state?.objectives, defaultTo([], action.payload?.objectives?.data)),
        keyResults: keyResultsAdapter.setAll(state?.keyResults, defaultTo([], action.payload?.keyResults?.data)),
      };
    case UPDATE_OUTCOME_MODULE_FILTERS_FULFILLED:
      const { fetchOkrsResult } = action.payload;

      return {
        ...state,
        objectives: objectivesAdapter.setAll(state?.objectives, defaultTo([], fetchOkrsResult?.objectives?.data)),
        keyResults: keyResultsAdapter.setAll(state?.keyResults, defaultTo([], fetchOkrsResult?.keyResults?.data)),
      };
    case ADD_KEY_RESULT_WITHOUT_SAVE: {
      const parentId = action.isKeyResult ? action.parent.id : null;
      const objectiveId = action.isKeyResult ? action.parent.objective_id : action.parent.id;

      const keyResult = { id: null, objective_id: objectiveId, parent_id: parentId, level: action.level };

      return {
        ...state,
        keyResults: keyResultsAdapter.addOne(state?.keyResults, keyResult),
      };
    }
    case ADD_OBJECTIVE_WITHOUT_SAVE: {
      const { level, objective: parentObjective } = action;

      const newObjective = { id: null, level, parent_id: parentObjective?.id };

      return {
        ...state,
        objectives: objectivesAdapter.addOne(state?.objectives, newObjective),
      };
    }
    case CREATE_KEY_RESULT_FROM_DRAWER_FULFILLED:
    case CREATE_KEY_RESULT_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        return state;
      }

      const withNewKeyResultRemoved = keyResultsAdapter.removeOne(state.keyResults, null);
      const withNewKeyResultAdded = keyResultsAdapter.upsertOne(withNewKeyResultRemoved, action.payload);

      return {
        ...state,
        objectives: action.payload.objective
          ? objectivesAdapter.upsertOne(state.objectives, action.payload.objective)
          : state.objectives,
        keyResults: withNewKeyResultAdded,
      };
    }
    case ADD_OBJECTIVE:
    case CREATE_OBJECTIVE_FROM_DRAWER_FULFILLED:
    case UPDATE_OBJECTIVE_FROM_DRAWER_FULFILLED:
    case CREATE_OBJECTIVE: {
      if (!action.payload || !action.payload.id) {
        return state;
      }

      const alreadyExists = state.objectives.entities[action.payload.id];

      if (alreadyExists) {
        return {
          ...state,
          objectives: objectivesAdapter.upsertOne(state.objectives, action.payload),
        };
      }

      return {
        ...state,
        objectives: objectivesAdapter.setAll(state.objectives, [action.payload, ...values(state.objectives.entities)]),
      };
    }
    case CREATE_OBJECTIVE_ROADMAP_FULFILLED: {
      const objective = state.objectives.entities[action.payload.objective_id];

      if (!objective) {
        return state;
      }

      const currentRoadmaps = defaultTo([], objective.objective_roadmaps);

      const objectiveRoadmaps = [...currentRoadmaps, action.payload];

      return {
        ...state,
        objectives: objectivesAdapter.updateOne(state.objectives, {
          id: objective.id,
          changes: { objective_roadmaps: objectiveRoadmaps },
        }),
      };
    }

    case DELETE_OBJECTIVE_ROADMAP_FULFILLED: {
      const { id: objectiveId, objectiveRoadmaps } = action.payload;

      return {
        ...state,
        objectives: objectivesAdapter.updateOne(state.objectives, {
          id: objectiveId,
          changes: { objective_roadmaps: objectiveRoadmaps || [] },
        }),
      };
    }
    case BULK_DELETE_OBJECTIVE_ROADMAP_FULFILLED: {
      return {
        ...state,
        objectives: objectivesAdapter.updateOne(state.objectives, {
          id: action.payload,
          changes: { objective_roadmaps: [] },
        }),
      };
    }
    case CREATE_KEY_RESULT_ROADMAP_FULFILLED: {
      const keyResult = state.keyResults.entities[action.payload.key_result_id];

      if (!keyResult) {
        return state;
      }

      const currentRoadmaps = defaultTo([], keyResult.key_result_roadmaps);

      const keyResultRoadmaps = [...currentRoadmaps, action.payload];

      return {
        ...state,
        keyResults: keyResultsAdapter.updateOne(state.keyResults, {
          id: keyResult.id,
          changes: { key_result_roadmaps: keyResultRoadmaps },
        }),
      };
    }

    case DELETE_KEY_RESULT_ROADMAP_FULFILLED: {
      const { id: keyResultId, keyResultRoadmaps } = action.payload;

      return {
        ...state,
        keyResults: keyResultsAdapter.updateOne(state.keyResults, {
          id: keyResultId,
          changes: { key_result_roadmaps: keyResultRoadmaps },
        }),
      };
    }

    case BULK_DELETE_KEY_RESULT_ROADMAP_FULFILLED: {
      return {
        ...state,
        keyResults: keyResultsAdapter.updateOne(state.keyResults, {
          id: action.payload,
          changes: { key_result_roadmaps: [] },
        }),
      };
    }
    case REMOVE_UNSAVED_OBJECTIVES_AND_KEY_RESULTS: {
      return {
        ...state,
        keyResults: keyResultsAdapter.removeOne(state.keyResults, null),
        objectives: objectivesAdapter.removeOne(state.objectives, null),
      };
    }
    case UPDATE_KEY_RESULT_ID_FULFILLED: {
      return {
        ...state,
        keyResults: keyResultsAdapter.setOne(state.keyResults, action.payload),
      };
    }

    default: {
      return state;
    }
  }
};

export default goalsGridReducer;
