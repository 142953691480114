import React from 'react';
import { Resizable } from 're-resizable';
import PropTypes from 'prop-types';

// This value is an estimation between what can appear inside the cell and how much it can be squeezed.
// The busiest content will be when the dependencies are enabled and when on edit mode.
// The dependencies' linker container wraps the content to be displayed for each project. This container
// has a gap of 1px between each of its 5 elements (two connection points, two drop points and the
// project info card). Totaling in 4 gaps; thus, 4px worth of gaps in total.
// The dependencies' connection points have a width of 14px. The drop points have 0 width.
// The project info card has a border of 1px (on both sides), a padding of 5px (on both sides), It also has
// the project icon with 24px and the project name and info with 5px of left margin and a font size of 14px.
// Summing everything: 4 + 14 * 2 + 1 * 2 + 5 * 2 + 24 + 5 + 14 = 87
// Accounting some offset to avoid very weird looking text (e.g., one letter per line),
// I believe 100px as a minimum width is a decent value for the cell content.
// Note that there are also some padding values on the wrappers for this table header cell component,
// but they will nullify with the paddings from the table content cells.

// Change the minium to width to 200px due to PROD-7196
// I believe that this minium works better because the content was being squeezed more thant the min width of the column
// causing a strange UI behavior
const MIN_WIDTH = '200px';

const TableHeaderCell = React.memo(({ colId, width, onResizeColumnStop, children, resizable, handlerClass }) => {
  const resizableProps = React.useMemo(
    () => ({
      defaultSize: {
        width,
        height: 'auto',
      },
      style: {
        position: 'initial',
      },
      enable: {
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      },
      handleClasses: {
        right: `.${handlerClass}`,
      },
      handleWrapperClass: `.${handlerClass}`,
    }),
    [],
  );
  const _handleOnResizeStart = e => {
    e.preventDefault();
  };

  const _handleOnResizeColumnStop = React.useCallback(
    (e, direction, ref, d) => {
      onResizeColumnStop(colId, width + d.width);
    },
    [width, colId],
  );

  if (!resizable) return children;

  if (colId === 'auto') {
    return (
      <Resizable
        {...resizableProps}
        width={width ? `${width}px` : '50px'}
        onResizeStart={_handleOnResizeStart}
        onResizeStop={_handleOnResizeColumnStop}
      >
        {children}
      </Resizable>
    );
  }

  return (
    <Resizable
      {...resizableProps}
      width={width ? `${width}px` : '250px'}
      minWidth={MIN_WIDTH}
      onResizeStart={_handleOnResizeStart}
      onResizeStop={_handleOnResizeColumnStop}
    >
      {children}
    </Resizable>
  );
});

TableHeaderCell.propTypes = {
  colId: PropTypes.number,
  width: PropTypes.number,
  onResizeColumnStop: PropTypes.func,
  resizable: PropTypes.bool,
};
TableHeaderCell.defaultProps = {
  resizable: true,
};

TableHeaderCell.displayName = 'GridTableHeaderCell';

export default TableHeaderCell;
