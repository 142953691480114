const returnsTrueIfKeyIsNotEnterOrTab = event => {
  const key = event.event.which || event.event.keyCode;

  // prevent navigation actions while editing
  if (
    event.editing &&
    key !== 9 && // tab
    key !== 13 && // enter
    key !== 27 // Escape
  ) {
    return true;
  }
};

const returnsTrueIfKeyIsNotEscOrTab = event => {
  const key = event.event.which || event.event.keyCode;

  // prevent navigation actions while editing
  if (
    event.editing &&
    key !== 9 && // tab
    key !== 27 // Escape
  ) {
    return true;
  }
};

const returnsTrueIfKeyIsNotEscOrTabOrEnter = event => {
  const key = event.event.which || event.event.keyCode;

  // prevent navigation actions while editing
  if (
    event.editing &&
    key !== 9 && // tab
    key !== 27 && // Escape
    key !== 13 // Enter
  ) {
    return true;
  }
};

export { returnsTrueIfKeyIsNotEnterOrTab, returnsTrueIfKeyIsNotEscOrTab, returnsTrueIfKeyIsNotEscOrTabOrEnter };
