const REQUEST_FORM_FIELDS = {
  details: 'details',
  comments: 'comments',
  createdAt: 'created_at',
  updatedAt: 'updated_at',
  createdBy: 'created_by',
  customers: 'customers',
  desiredTime: 'desired_time',
  files: 'files',
  ideas: 'ideas',
  links: 'links',
  owner: 'owner',
  // parent: 'parent',
  priority: 'priority',
  roadmap: 'roadmap',
  status: 'status',
  tags: 'tags',
  watchers: 'watchers',
  integrations: 'integrations',
  customersCount: 'cr_customers_count',
  totalNetRevenue: 'totalNetRevenue',
  totalRevenue: 'cr_total_revenue',
  totalChurnedRevenue: 'cr_total_churned_revenue',

  personas: 'personas',
  lifecycles: 'lifecycles',
  externalKey: 'external_key',
  externalPortfolioKey: 'external_portfolio_key',
};

const FORM_FIELDS_MAPPER = {
  [REQUEST_FORM_FIELDS.owner]: 'owner_id',
};

export { REQUEST_FORM_FIELDS, FORM_FIELDS_MAPPER };
