import React from 'react';

import Text from 'design-system/atoms/Text/index';


const isEqualOrGreaterThanZero = value => value !== null && value >= 0;

const getValue = params => params?.colDef?.getDisplayValue?.(params) || params.value;

const WeeksCellRenderer = params => (
  <Text withEllipsis>{isEqualOrGreaterThanZero(getValue(params)) ? `${getValue(params)} wks` : ''}</Text>
);

export default WeeksCellRenderer;
