import { DEFAULT_PAGE_FILTERS, METRICS_FILTER, OBJECTIVES_FILTER } from 'constants/filters';

/**
 * This util will initialize the default user filter state based on the page.
 * For now Objectives and Metrics filter should always have field status=active everytime the user lands on the page
 * regardless if in previous session he removed the status filter.
 *
 * Note: this does not affect saved filters on user views.
 *
 * @param filter
 * @return {(*&{fields: (*)})|*}
 */
const initializeDefaultPageFilterState = filter => {
  const { page, state } = filter;

  switch (page) {
    case OBJECTIVES_FILTER:
      return {
        ...state,
        fields: {
          ...DEFAULT_PAGE_FILTERS[OBJECTIVES_FILTER],
          ...state?.fields,
        },
      };
    case METRICS_FILTER:
      return {
        ...state,
        fields: {
          ...DEFAULT_PAGE_FILTERS[METRICS_FILTER],
          ...state?.fields,
        },
      };
    default:
      return state;
  }
};

export default initializeDefaultPageFilterState;
