import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';

import Input from '@material-ui/core/Input';

import MetadataFluidField from 'design-system/molecules/MetadataFluidField/index';
import SavedFiltersRow from 'design-system/atoms/SavedFiltersRow/index';

import MetadataTreeDropdown from 'containers/MetadataTreeDropdown';

import useFluidMetadataTreeDropdown from './useFluidMetadataTreeDropdown';

const DEFAULT_OFFSET_LEFT = 174;

// To be aligned with the text on the input fields we must adjust its position and padding
const ALIGN_ITEMS_TOP = 'flex-start';

const FluidMetadataTreeDropdown = props => {
  const {
    label,
    options,
    value,
    levelZeroFieldName,
    levelOneFieldName,
    levelTwoFieldName,
    roadmapsKey,
    selectedProject,
    isMultilevel,
  } = props;
  const popoverRef = useRef(null);

  const {
    dropdownOptions,
    formattedValue,
    inputTotalRows,
    hasMetadataRoadmaps,
    showAllOptions,
    onChangeHandler,
    onToggleShowAllOptions,
  } = useFluidMetadataTreeDropdown({
    value,
    options,
    selectedProject,
    roadmapsKey,
    levelZeroFieldName,
    levelOneFieldName,
    levelTwoFieldName,
  });

  const showAllButtonText = useMemo(
    () =>
      showAllOptions && selectedProject?.roadmapTitle ? `Show ${selectedProject.roadmapTitle} ${label}` : `Show All ${label}`,
    [showAllOptions, selectedProject, label],
  );

  const renderShowAll = () => {
    const hasMoreOptions = showAllOptions || options.length > dropdownOptions.length;

    if (!hasMetadataRoadmaps || !hasMoreOptions) {
      return null;
    }

    return <SavedFiltersRow handleClick={onToggleShowAllOptions} text={showAllButtonText} />;
  };

  return (
    <div ref={popoverRef}>
      <MetadataFluidField
        {...props}
        renderer={(value, { placeholder } = {}) => (
          <StyledInput
            type="text"
            value={formattedValue}
            minRows={inputTotalRows}
            archivedValue={false}
            placeholder={placeholder}
            multiline
            disableUnderline
            readOnly
          />
        )}
        editorRenderer={({ anchorEl, isEditing, save, cancel, disableEdit, placeholder }) => (
          <MetadataTreeDropdown
            disablePortal={false}
            inputRef={disableEdit ? null : popoverRef?.current}
            isOpen={isEditing}
            items={dropdownOptions}
            onChange={selected => onChangeHandler(selected, save)}
            onClose={cancel}
            offsetLeft={anchorEl.offsetLeft || DEFAULT_OFFSET_LEFT}
            hasEmptyOption
            hasMultiLevel={isMultilevel}
            hasSearchBar={false}
            renderClear={() => renderShowAll()}
            placeholder={placeholder}
          />
        )}
        alignItems={ALIGN_ITEMS_TOP}
        forceInputRenderingOnEdit
      />
    </div>
  );
};

const StyledInput = styled(Input)`
  &&&& {
    ${({ archivedValue, theme }) =>
      archivedValue
        ? `
    color: ${theme.palette.text.lightGrey}`
        : ''}
  }
`;

export default FluidMetadataTreeDropdown;
