const planningStages = {
  Backlog: {
    color: true,
    border: true,
  },
  Planning: {
    color: false,
    border: true,
  },
  Confirmed: {
    color: false,
    border: false,
  },
  Completed: {
    color: false,
    border: false,
  },
};

export { planningStages };
