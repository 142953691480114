import React from 'react';
import styled from 'styled-components';

import { withTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const initializeState = fields => {
  return (
    fields &&
    fields.reduce(
      (obj, field) => ({
        ...obj,
        [field.key]: '',
      }),
      {},
    )
  );
};

const NewCard = props => {
  const {
    elevation = 1,
    fields,
    handleOutsideClick,
    position,
  } = props;
  const { onAdd, onCancel } = props;

  const [form, setForm] = React.useState(initializeState(fields));

  const handleChange = event => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleCancel = event => {
    event.stopPropagation();

    onCancel && onCancel();
  };

  const handleAdd = event => {
    event.stopPropagation();

    for (let i = 0; i < fields.length; i++) {
      if (fields[i].required && !form[fields[i].key]) return;
    }

    onAdd && onAdd(form);
  };

  const handleKeyDown = field => e => {
    if (e.keyCode === 13 && field.saveOnKeyEnter) handleAdd(e);
  };

  const handleClickAway = (e) => {
    e.stopPropagation();
    handleOutsideClick && handleOutsideClick(form);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Wrapper elevation={elevation} position={position}>
        <Container>
          {fields &&
            fields.map(field => (
              <ActionText
                key={field.key}
                name={field.key}
                label={field.name}
                value={form[field.key]}
                multiline={field.multiline}
                rows={field.rows}
                rowsMax={field.rows || 1}
                onChange={handleChange}
                onKeyDown={handleKeyDown(field)}
                required={field.required}
                autoFocus={field.autoFocus || false}
              />
            ))}
          <ActionControls>
            <ActionButtonControl onClick={handleCancel}>Cancel</ActionButtonControl>
            <ActionButtonControl color="primary" onClick={handleAdd}>
              Save
            </ActionButtonControl>
          </ActionControls>
        </Container>
      </Wrapper>
    </ClickAwayListener>
  );
};

const Wrapper = withTheme()(styled(Card)`
  &&&& {
    z-index: 5;
    position: relative;
    ${({ position }) => !!position && position}
  }
`);

const Container = withTheme()(styled(CardContent)`
  &&&& {
    display: flex;
    flex-direction: column;
  }
`);

const ActionText = withTheme()(styled(TextField)`
  &&&& {
    margin-bottom: 12px;
  }
`);

const ActionControls = styled.div`
  &&&& {
    display: flex;
    margin-top: 12px;
  }
`;

const ActionButtonControl = withTheme()(styled(Button)`
  &&&& {
  }
`);

export default withTheme()(NewCard);
