import { getCreateProjectPromise, getUpdateProjectPromise } from 'store/projects';
import { loadProjectsByEntity } from 'store/projects/network';
import throwRequestError from 'store/utils/throwRequestError';
import { createThunk } from 'utils/store/thunk';
import { getObjectiveKeyByLevel, getObjectiveProjectFieldByLevel } from 'utils/objectives';
import { CREATE_PROJECT_FROM_OKR, FETCH_OBJECTIVE_LINKED_ITEMS, LINK_PROJECT_TO_OKR, UNLINK_PROJECT_FROM_OKR } from './types';

const isMilestone = type => type === 'milestone';

export const fetchLinkedItems = (okr, params, showCompleted = false) => {
  return createThunk(
    FETCH_OBJECTIVE_LINKED_ITEMS,
    loadProjectsByEntity(okr?.id, getObjectiveKeyByLevel(okr), params, showCompleted)
      .then(res => res?.data?.data)
      .catch(throwRequestError),
    {},
  );
};

export const unlinkProjectFromOkr = (project, okr) => async dispatch => {
  const updateData = {
    [getObjectiveKeyByLevel(okr)]: null,
  };

  const promise = getUpdateProjectPromise(dispatch, updateData, { id: project?.id });

  return dispatch(createThunk(UNLINK_PROJECT_FROM_OKR, promise, {}));
};

export const linkProjectToOkr = (project, okr) => async dispatch => {
  const updateData = {
    [getObjectiveProjectFieldByLevel(okr)]: okr?.title,
  };

  const promise = getUpdateProjectPromise(dispatch, updateData, { id: project?.id });

  return dispatch(
    createThunk(LINK_PROJECT_TO_OKR, promise, {
      okrId: okr?.id,
      okrType: getObjectiveKeyByLevel(okr),
    }),
  );
};

export const createProjectFromOkr = (project, okrId, okrType) => {
  return async dispatch => {
    const createAndLinkPromise = async () => {
      delete project.id;
      project.isNew = true;

      const createdProject = await getCreateProjectPromise(dispatch, project);

      if (isMilestone(createdProject.type)) {
        return {};
      }

      return {
        projectData: createdProject,
      };
    };

    return dispatch(createThunk(CREATE_PROJECT_FROM_OKR, createAndLinkPromise, { okrId, okrType }));
  };
};
