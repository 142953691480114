import { useCallback, useMemo } from 'react';

import {
  getAvailableFields,
  getFieldFunctions as getFieldFunctionsUtil,
  getModuleBadgeContent,
  parseModuleFilters,
} from 'utils/filters/fieldFilters';
import usePermissions from './permissions/usePermissions';

export default function useModuleAdvancedSearch(
  filters,
  getSystemFieldName,
  hasMetadataRoadmaps,
  availableFilterFields = [],
  functionsByFieldId = {},
  functionsByFieldType = {},
) {
  const permissions = usePermissions();

  const allFields = useMemo(
    () => getAvailableFields(availableFilterFields, getSystemFieldName, hasMetadataRoadmaps, permissions),
    [availableFilterFields, getSystemFieldName, hasMetadataRoadmaps, permissions],
  );

  const availableFields = useMemo(() => {
    const filterKeys = Object.keys(filters);

    return allFields.filter(f => !filterKeys.includes(f.id));
  }, [allFields, filters]);

  const badgeContent = useMemo(() => getModuleBadgeContent(allFields, filters), [allFields, filters]);

  const parsedFilters = useMemo(() => parseModuleFilters(availableFilterFields, filters), [availableFilterFields, filters]);

  const getFieldFunctions = useCallback(
    field => {
      return getFieldFunctionsUtil(functionsByFieldId, functionsByFieldType, field);
    },
    [functionsByFieldId, functionsByFieldType],
  );

  return {
    allFields,
    availableFields,
    badgeContent,
    getFieldFunctions,
    parsedFilters,
  };
}
