import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('GENERATE_AUTO_TAGS'),
  ...getThunkActionTypes('SAVE_AUTO_TAGS'),
  ...getThunkActionTypes('SET_AUTO_TAGS_AS_TAGS'),
};

export const {
  GENERATE_AUTO_TAGS,
  GENERATE_AUTO_TAGS_PENDING,
  GENERATE_AUTO_TAGS_FULFILLED,

  SAVE_AUTO_TAGS,
  SAVE_AUTO_TAGS_PENDING,
  SAVE_AUTO_TAGS_FULFILLED,

  SET_AUTO_TAGS_AS_TAGS,
  SET_AUTO_TAGS_AS_TAGS_PENDING,
  SET_AUTO_TAGS_AS_TAGS_FULFILLED,
} = actionTypes;

export const RESET_AUTO_TAGS = 'RESET_AUTO_TAGS';
