import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { path } from 'ramda';

import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import { getOrganization, getOrgHasMetadataRoadmaps, selectHasProducts, selectHasProducts2 } from 'store/organization';

import useMetadataForOptions from 'hooks/metadata/useMetadataForOptions';
import usePermissions from 'hooks/permissions/usePermissions';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import useFormatRoadmapsForDropdown from 'containers/ProjectLightBox/hooks/useFormatRoadmapsForDropdown';
import { metadataKeys } from 'utils/metadata/metadataKeys';
import makeMetadataRoadmapsOptionsFormatter from 'utils/makeMetadataRoadmapsOptionsFormatter';

import { CUSTOMERS, PRODUCTS, ROADMAPS, TAGS, PERSONAS, LIFECYCLES, ROADMAPS_CORP } from 'constants/common';

const KEYS = [CUSTOMERS, TAGS, ROADMAPS_CORP, ROADMAPS, PRODUCTS, PERSONAS, LIFECYCLES];
const ALL_METADATA = 'allMetadata';
const FILTERED_OPTIONS = 'filteredOptions';

// Formats metadata options by roadmap. Also checks if the user has permission to view the resource.
const formatMetadataOptionsByRoadmap = (
  filteredOptions,
  roadmapKey,
  shouldGroupMetadataByRoadmap,
  formatMetadataOptions,
  hasCorpRoadmap,
) => {
  if (shouldGroupMetadataByRoadmap) {
    return formatMetadataOptions(`${roadmapKey}_roadmaps`, filteredOptions, {
      needsObject: true,
      includeCorpRoadmap: hasCorpRoadmap,
    });
  }

  return filteredOptions;
};

export default customerRequest => {
  const { canView } = usePermissions();
  const hasNewMetadataAutocomplete = canView(PERMISSION_FEATURES.newRoadmapMetadataAutocomplete);

  const organization = useSelector(state => getOrganization(state));
  const organizationsAccessControl = useOrganizationsAccessControl();
  const { isDodActive } = organizationsAccessControl;
  const hasProducts = useSelector(selectHasProducts);
  const hasProducts2 = useSelector(selectHasProducts2);
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);

  const shouldGroupMetadataByRoadmap = !isDodActive && hasNewMetadataAutocomplete && hasMetadataRoadmaps;
  const hasCorpRoadmap = canView(PERMISSION_RESOURCES.roadmapCorp);

  const metadataOptionsObj = useMetadataForOptions({
    metadataKeys: KEYS,
    roadmapId: customerRequest.roadmap_id,
  });

  const roadmapsOptions = useFormatRoadmapsForDropdown(
    path([FILTERED_OPTIONS, ROADMAPS], metadataOptionsObj),
    path([FILTERED_OPTIONS, ROADMAPS_CORP], metadataOptionsObj),
    hasProducts,
    hasProducts2,
  );

  // Formats/groups the metadata options into its linked roadmap
  const formatMetadataOptions = makeMetadataRoadmapsOptionsFormatter(
    organization,
    customerRequest.roadmap_id,
    organizationsAccessControl,
    path([ALL_METADATA, ROADMAPS], metadataOptionsObj),
    path([ALL_METADATA, ROADMAPS_CORP], metadataOptionsObj),
  );

  return useMemo(() => {
    const customerOptions = formatMetadataOptionsByRoadmap(
      path([FILTERED_OPTIONS, CUSTOMERS], metadataOptionsObj),
      metadataKeys.customer,
      shouldGroupMetadataByRoadmap,
      formatMetadataOptions,
      hasCorpRoadmap,
    );

    const tagsOptions = formatMetadataOptionsByRoadmap(
      path([FILTERED_OPTIONS, TAGS], metadataOptionsObj),
      metadataKeys.tag,
      shouldGroupMetadataByRoadmap,
      formatMetadataOptions,
      hasCorpRoadmap,
    );

    const personasOptions = formatMetadataOptionsByRoadmap(
      path([FILTERED_OPTIONS, PERSONAS], metadataOptionsObj),
      metadataKeys.persona,
      shouldGroupMetadataByRoadmap,
      formatMetadataOptions,
      hasCorpRoadmap,
    );

    const lifecyclesOptions = formatMetadataOptionsByRoadmap(
      path([FILTERED_OPTIONS, LIFECYCLES], metadataOptionsObj),
      metadataKeys.lifecycle,
      shouldGroupMetadataByRoadmap,
      formatMetadataOptions,
      hasCorpRoadmap,
    );

    return {
      allCustomers: path([ALL_METADATA, CUSTOMERS], metadataOptionsObj),
      customersOptions: customerOptions,
      allTags: path([ALL_METADATA, TAGS], metadataOptionsObj),
      tagsOptions,
      roadmapsOptions,
      productsOptions: path([FILTERED_OPTIONS, PRODUCTS], metadataOptionsObj),
      allPersonas: path([ALL_METADATA, PERSONAS], metadataOptionsObj),
      personasOptions,
      allLifecycles: path([ALL_METADATA, LIFECYCLES], metadataOptionsObj),
      lifecyclesOptions,
    };
  }, [metadataOptionsObj, roadmapsOptions, formatMetadataOptions, shouldGroupMetadataByRoadmap]);
};
