import React from 'react';
import { isNil } from 'ramda';

import { InlineSetting, InlineTextField, PreferenceGroup } from '../../ProgressTrackingConfig.styles';

const IssueCountDetails = props => {
  const { value, onChange, disabled } = props;

  return (
    <PreferenceGroup>
      <InlineSetting>
        <span>Display</span>
        &nbsp;
        <InlineTextField value={isNil(value) ? 1 : value} onChange={onChange} disabled={disabled} />
        &nbsp;
        <span>day per issue on the Timeline page</span>
      </InlineSetting>
    </PreferenceGroup>
  );
};

export default IssueCountDetails;
