import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProjectHistoryNextPage, fetchProjectHistory } from 'store/history';

import {
  selectProjectsHistory,
  selectProjectsHistoryNextPageLink,
  isFetchProjectNextPageLoading,
} from 'src/store/history/selectors';
import { getHasIntegrationLog as getHasIntegrationLogSelector } from 'src/store/organization/selectors';
import { getOrgMetadataForCorpLevelTitles } from 'store/utils/getMetadataCorpTitleBasedOnRoadmap';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();

    const [showErrors, setShowErrors] = useState(false);

    const fetchNextPage = () => dispatch(fetchProjectHistoryNextPage({ errors: showErrors }));

    const ideaHistory = useSelector(state => selectProjectsHistory(state));
    const hasNext = useSelector(state => !!selectProjectsHistoryNextPageLink(state));
    const isLoading = useSelector(state => isFetchProjectNextPageLoading(state));
    const systemFields = useSelector(state => state.organization.organization.system_fields_name);
    const orgMetadataForCorpLevels = useSelector(state => getOrgMetadataForCorpLevelTitles(state));
    const hasIntegrationLog = useSelector(state => getHasIntegrationLogSelector(state));

    const onToggleShowErrors = useCallback(() => {
      setShowErrors(currentValue => !currentValue);
    }, [setShowErrors]);

    useEffect(() => {
      dispatch(fetchProjectHistory(props.id, { errors: showErrors }));
    }, [showErrors]);

    const ideaHistoryWithCreated = useMemo(
      () => [
        ...ideaHistory,
        {
          id: '0',
          date: props.createdAt,
          changeType: 'created',
          user: props.createdBy,
          type: props.ideaType,
          layer: props.ideaLayer,
        },
      ],
      [ideaHistory],
    );

    return (
      <Component
        {...props}
        ideaHistory={ideaHistoryWithCreated}
        isLoading={isLoading}
        hasNext={hasNext}
        fetchNextPage={fetchNextPage}
        systemFields={systemFields}
        orgMetadataForCorpLevels={orgMetadataForCorpLevels}
        hasIntegrationLog={hasIntegrationLog}
        showErrors={showErrors}
        onToggleShowErrors={onToggleShowErrors}
      />
    );
  };
};

export default componentHOC;
