const getFieldIntegrationErrorMessage = error => {
  if (error) {
    switch (error.integrationType) {
      case 'JIRA':
        return `"${error.fieldDisplayName}" cannot update "${error.integrationFieldDisplayName}" in ${error.integrationType}.
            Please contact your JIRA project admin to fix the mapping for the following error message: 
            "${error.message}"`;
      default:
        return 'Unknown integration type for the update field integration error';
    }
  }
};

export class FieldIntegrationError {
  constructor(error) {
    this.error = error;
  }

  getMessage() {
    return getFieldIntegrationErrorMessage(this.error);
  }

  getType() {
    return 'error';
  }

  getSystem() {
    return 'toast';
  }
}
