import { pipe, not, propEq } from 'ramda';
import { openObjectiveDrawer } from 'store/objectives';
import { getGroupDataOkrIdAndLevel } from 'utils/grid';

export default (data, dispatch) => {
  if (!data) return null;

  const isNotGroup = pipe(propEq('group', true), not)(data);

  if (isNotGroup) return null;

  // we only want link click option for OKR
  const { okrId, level } = getGroupDataOkrIdAndLevel(data.groupData);

  if (!okrId && !level) return null;

  return () => dispatch(openObjectiveDrawer(okrId, level));
};
