// External dependencies
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

import { isNil, not, pipe } from 'ramda';

// Dragonboat dependencies
// DoD v2
// import getMetadataTitle, { getOrgMetadataForCorpLevelTitles } from '../utils/getMetadataCorpTitleBasedOnRoadmap';

import { filterActiveItens } from 'utils';
import sortByRank from 'utils/sortByRank';

import { METADATA_LEVELS } from 'constants/common';

const isNotNil = pipe(isNil, not);

export function getState(state) {
  return state.categories;
}

// With levels - next DoD release
// export const getState = createSelector(
//   state => {
//     return state.categories;
//   },
//   getOrgMetadataForCorpLevelTitles,
//   (state, getOrgMetadataForCorpLevelTitles) => {
//     const categories = state?.categories?.map(category => ({
//       ...category,
//       title: getMetadataTitle(category, CATEGORY_CORP, getOrgMetadataForCorpLevelTitles),
//     }));

//     return { categories };
//   },
// );

export const makeGetCategories = (defaultLevel = METADATA_LEVELS.LEVEL_1) =>
  createCachedSelector(
    getState,
    (_, showArchived) => showArchived,
    (_, __, level) => (isNotNil(level) ? level : defaultLevel),
    (state, showArchived, level) => {
      // With levels add makeFilterByLevel again
      return (showArchived ? state.categories : state.categories.filter(filterActiveItens)).sort(sortByRank);
    },
  )((state, showArchived, level) => `categories-${showArchived}-${level}`);

export const getCategories = makeGetCategories();

export const getCategoriesLevelCorp = makeGetCategories(METADATA_LEVELS.LEVEL_CORP);

export const makeGetNormalizedCategories = () => {
  const _localGetCategories = makeGetCategories();

  return createSelector(
    state => _localGetCategories(state, true),
    state =>
      state.reduce((objs, obj) => {
        objs[obj.id] = obj;
        return objs;
      }, {}),
  );
};

export const getNormalizedCategories = makeGetNormalizedCategories();
