export const SEND_ONCE = 'once';
export const EVERY_MONDAY = '1';
export const EVERY_TUESDAY = '2';
export const EVERY_WEDNESDAY = '3';
export const EVERY_THURSDAY = '4';
export const EVERY_FRIDAY = '5';
export const EVERY_SATURDAY = '6';
export const EVERY_SUNDAY = '7';

const sendOnceOption = {
  label: 'Send once',
  value: SEND_ONCE,
};

const everyMondayOption = {
  label: 'Every Monday',
  value: EVERY_MONDAY,
};

const everyTuesdayOption = {
  label: 'Every Tuesday',
  value: EVERY_TUESDAY,
};

const everyWednesdayOption = {
  label: 'Every Wednesday',
  value: EVERY_WEDNESDAY,
};

const everyThursdayOption = {
  label: 'Every Thursday',
  value: EVERY_THURSDAY,
};

const everyFridayOption = {
  label: 'Every Friday',
  value: EVERY_FRIDAY,
};

const everySaturdayOption = {
  label: 'Every Saturday',
  value: EVERY_SATURDAY,
};

const everySundayOption = {
  label: 'Every Sunday',
  value: EVERY_SUNDAY,
};

export const RECURRING_OPTIONS = {
  [SEND_ONCE]: sendOnceOption,
  [EVERY_MONDAY]: everyMondayOption,
  [EVERY_TUESDAY]: everyTuesdayOption,
  [EVERY_WEDNESDAY]: everyWednesdayOption,
  [EVERY_THURSDAY]: everyThursdayOption,
  [EVERY_FRIDAY]: everyFridayOption,
  [EVERY_SATURDAY]: everySaturdayOption,
  [EVERY_SUNDAY]: everySundayOption,
};

export const RECURRING_OPTIONS_LIST = [
  sendOnceOption,
  everyMondayOption,
  everyTuesdayOption,
  everyWednesdayOption,
  everyThursdayOption,
  everyFridayOption,
  everySaturdayOption,
  everySundayOption,
];
