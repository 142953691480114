import React from 'react';
import styled from 'styled-components';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import Typography from '@material-ui/core/Typography';

import DrawerHeader from 'design-system/molecules/DrawerHeader/index';
import FluidContainer from 'design-system/atoms/FluidContainer/index';
import FluidMultiSelectField from 'design-system/organisms/FluidMultiSelectField/index';
import NewDialog from 'design-system/organisms/NewDialog/index';
import UploadedFileActions from 'design-system/organisms/UploadedFileActions/index';

import { HeaderIconButton } from 'design-system/organisms/NewDialog';
import FluidAccessMultiSelectFields from '../../../design-system/organisms/FluidAccessMultiSelect';
import FluidFieldLabel from 'design-system/molecules/FluidFieldLabel';

export default ({
  uploadLogo,
  getLogoLink,
  handleDeleteLink,
  accessConfig,
  hideableOptions,
  hiddenFields,
  updateConfig,
  updateHiddenField,
  ...other
}) => {
  // img upload
  const hiddenFileInput = React.useRef(null);
  const _handleFileInputChange = event => {
    const { files } = event.target;

    if (files[0]) {
      uploadLogo(files[0]);
    }

    event.target.value = null;
  };

  // hidden fields
  const _makeOnChangeHiddenFields = values => {
    const newHiddenFields = hideableOptions.filter(opt => values.includes(opt.id));

    updateHiddenField(newHiddenFields);
  };

  return (
    <StyledDialog
      type="DRAWER"
      withoutBackdrop
      headerComponent={DrawerHeader}
      headerProps={{
        onClose: other.onClose,
        title: 'Configure external facing portal',
        leftIcon: <ChevronLeftIcon />,
      }}
      paddingOverride="0px"
      closeButton
      {...other}
    >
      <Form autoComplete="off">
        <FieldWrapper container xs={12} justify="flex-start">
          <FluidContainer size={2}>
            <Typography>Upload your logo</Typography>
            <Typography>(Max 200px x 100px)</Typography>
          </FluidContainer>
          <FluidContainer size={2}>
            <ImgUpload>
              <IconButton onClick={() => hiddenFileInput.current.click()}>
                <CloudUploadOutlinedIcon fontSize="small" color={accessConfig.logo ? 'primary' : ''} />
              </IconButton>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={_handleFileInputChange}
                accept=".pdf, .png, .jpg, .jpeg, .doc, .docx, .xls, .xlsx, .ppt, .csv, .mov, .txt, .rtf"
              />
            </ImgUpload>
          </FluidContainer>
        </FieldWrapper>
        <Grid container xs={12} justify="flex-start">
          <AccessLabel>Access: </AccessLabel>
          {accessConfig.logo && (
            <UploadedFileActions
              title={accessConfig.logo.name}
              getLink={() => getLogoLink()}
              handleDeleteLink={() => handleDeleteLink()}
            />
          )}
        </Grid>
        <FieldWrapper container xs={12} justify="flex-start">
          <FluidContainer size={10}>
            <FluidAccessMultiSelectFields
              accessConfig={accessConfig}
              allowMultiSelectLabel="Only allow emails from these domains"
              denyMultiSelectLabel="Allow submission from any domain, except the following"
              updateConfig={updateConfig}
            />
          </FluidContainer>
        </FieldWrapper>
        <Grid container xs={12} justify="flex-start">
          <FluidContainer size={10}>
            <FluidMultiSelectField
              key="hiddenFields"
              value={hiddenFields}
              label="Hide these fields"
              inputProps={{
                placeholder: '',
              }}
              options={hideableOptions}
              optionsMapper={{ label: 'name', value: 'id', color: 'color' }}
              onChange={value => _makeOnChangeHiddenFields(value)}
              format="column"
              hideCreateOption
            />
          </FluidContainer>
        </Grid>
      </Form>
    </StyledDialog>
  );
};

const StyledDialog = styled(NewDialog)`
  &&&& ${HeaderIconButton} {
    top: 18px;
  }
`;

const Form = styled.form`
  &&&& {
    padding: 20px 48px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const AccessLabel = styled(FluidFieldLabel)`
  &&&& {
    margin-bottom: 12px;
  }
`;

const FieldWrapper = styled(Grid)`
  padding: 6px 8px;
`;

const ImgUpload = styled.div`
  &&&& {
    display: flex;
    align-items: center;

    > input {
      display: none;
    }
  }
`;
