import React, { useState, useCallback, useMemo } from 'react';
import { METADATA_LEVELS } from 'constants/common';
import { pluck, flatten, pipe } from 'ramda';

import FluidTreeDropdownFieldWithShowAll from 'design-system/organisms/FluidTreeDropdownFieldWithShowAll/index';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import { handleProductSelection } from './helpers';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const SHOW_ALL_ROADMAPS_TEXT = 'Show All';

const getAllChildrenRoadmaps = pipe(pluck('children'), flatten);
const isCorpLevelRoadmap = roadmap => roadmap?.level === METADATA_LEVELS.LEVEL_CORP;

const RoadmapsTreeDropdown = props => {
  const { options = [], okrSelectedArchived, label } = props;

  const { canView } = usePermissions();

  const canViewCorpLevelRoadmaps =
    canView(PERMISSION_RESOURCES.roadmapCorp) || canView(PERMISSION_FEATURES.crossBabyRoadmapSupport);

  const [isShowAll, setIsShowAll] = useState(false);

  const { getCurrentOrgCorpRoadmap } = useOrganizationsAccessControl();

  const corpRoadmap = useMemo(() => getCurrentOrgCorpRoadmap(), [getCurrentOrgCorpRoadmap]);

  const optionsToRender = useMemo(() => {
    // By default baby accounts see their own roadmaps with the possibility to toggle using show all
    if (canViewCorpLevelRoadmaps && !isShowAll) {
      return [options.find(option => !option.key), ...(options.find(roadmap => roadmap.id === corpRoadmap?.id)?.children || [])];
    }

    return options;
  }, [corpRoadmap, options, isShowAll, canViewCorpLevelRoadmaps]);

  const hasOtherOptions = useMemo(() => {
    if (canViewCorpLevelRoadmaps) {
      return options.filter(roadmap => roadmap.id !== corpRoadmap?.id).length > 1;
    }

    return false;
  }, [canViewCorpLevelRoadmaps, corpRoadmap, options]);

  const handleSelectOption = (selectedValue, save) => {
    if (isCorpLevelRoadmap(selectedValue)) {
      return;
    }
    // User selected a Product level 1 or 2 (roadmap_id exists for those cases)
    if ('roadmap_id' in selectedValue) {
      const optionsForProduct = canViewCorpLevelRoadmaps ? getAllChildrenRoadmaps(options) : options;

      handleProductSelection(optionsForProduct, selectedValue, save);
    } else {
      // User selected roadmap
      save({ roadmapTitle: selectedValue.title, product1Title: null, product2Title: null });
    }
  };

  const showAllText = isShowAll && canViewCorpLevelRoadmaps ? `Show ${corpRoadmap?.title} ${label}` : SHOW_ALL_ROADMAPS_TEXT;
  const handleShowAllClick = useCallback(() => setIsShowAll(prevState => !prevState), [isShowAll]);

  return (
    <FluidTreeDropdownFieldWithShowAll
      {...props}
      options={optionsToRender}
      isArchived={okrSelectedArchived}
      toggleIsShowingAll={handleShowAllClick}
      showAllText={showAllText}
      handleChange={handleSelectOption}
      hasOtherOptions={hasOtherOptions}
    />
  );
};

export default RoadmapsTreeDropdown;
