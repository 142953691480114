import axios from 'axios';

export default async (fileId, parentType, parentId) => {
  if (!(parentType === 'projects' || parentType === 'customer-requests')) return;

  if (!fileId || !parentId) return;

  const response = await axios.get(`/api/${parentType}/${parentId}/files/${fileId}`);

  return response.data.url;
};
