import React from 'react';
import { isNil } from 'ramda';

import { InlineSetting, InlineTextField, PreferenceGroup } from '../../ProgressTrackingConfig.styles';

const StoryPointsDetails = props => {
  const { storyPoints, storyPointPerDay, onChangeStoryPoints, onChangeStoryPointPerDay, disabled } = props;

  return (
    <PreferenceGroup>
      <InlineSetting>
        <span>Default Story Points</span>
        &nbsp;
        <InlineTextField value={isNil(storyPoints) ? 2 : storyPoints} onChange={onChangeStoryPoints} disabled={disabled} />
        &nbsp;
        <span>day per issue on the Timeline page</span>
      </InlineSetting>
      <InlineSetting>
        <span>Display</span>
        &nbsp;
        <InlineTextField
          value={isNil(storyPointPerDay) ? 1 : storyPointPerDay}
          onChange={onChangeStoryPointPerDay}
          disabled={disabled}
        />
        &nbsp;
        <span>day per Story Point on the Timeline page </span>
      </InlineSetting>
    </PreferenceGroup>
  );
};

export default StoryPointsDetails;
