/**
 * Gets a comparator function to evaluate the disabled status for each row.
 * A given row is disabled when:
 *  - the level is different comparing with the selected ones
 *  - the level is equal but the parent_id is different comparing with the selected ones.
 * @param {Object} data
 * @returns {Function} grid row items comparator function
 */
const buildGridCheckboxDisabledComparator = data => selectedItem => {
  const isLevelNotEqual = selectedItem.level !== data.level;
  const isLevelEqualAndParentNotEqual = selectedItem.level === data.level && selectedItem.parent_id !== data.parent_id;

  return isLevelNotEqual || isLevelEqualAndParentNotEqual;
};

export { buildGridCheckboxDisabledComparator };
