import { omit, propOr } from 'ramda';

import { makeProjectMapper } from 'routes/Swimlane/New/helpers';

import filterDuplicateProjectValuesByFieldIds from './filterDuplicateProjectValuesByFieldIds';
import { compareDatesByUnit } from './hideSameRowComparisonUtils';

const FIELD_IDS = ['startDate', 'endDate', 'startDateEstimates', 'endDateEstimates'];

const FIELD_ID_COMPARISON = FIELD_IDS.reduce(
  (fieldIdCompare, fieldId) => ({ ...fieldIdCompare, [fieldId]: compareDatesByUnit }),
  {},
);

const getName = propOr('', 'name');

// to be improved
const prepareDataForSwimlaneComparison = (
  timelineData,
  selectedRoadmapVersionsIds,
  colorsByVersion,
  roadmapVersionsProjects,
  roadmapVersions,
  baseCompareVersionSelected,
  hideRowsWithSameData,
) => {
  const porProjectMapper = makeProjectMapper();
  const projectProps = { title: getName(baseCompareVersionSelected), showTitle: false };

  const mappedVersionProjects = selectedRoadmapVersionsIds.reduce((mapped, versionId) => {
    const versionProjects = roadmapVersionsProjects[versionId] || [];
    const roadmapVersionColor = colorsByVersion[versionId];
    const projectMapper = makeProjectMapper(roadmapVersionColor);

    const versionProjectsWithMapper = versionProjects.map(p => ({
      id: p.id,
      milestoneDiamondColor: roadmapVersionColor,
      ...projectMapper(p),
    }));

    return {
      ...mapped,
      [versionId]: versionProjectsWithMapper,
    };
  }, {});

  const getItemsDefaultWithPor = project => {
    const showOnlyAboveTheLine = baseCompareVersionSelected?.isPoRBase && !baseCompareVersionSelected?.includeBelowTheLine;
    const shouldIncludeProject = showOnlyAboveTheLine && !project?.committed;

    return shouldIncludeProject ? [] : [{ ...porProjectMapper(project), ...projectProps }];
  };

  const getProjectsFromSelectedScenariosForGroup = project => {
    return selectedRoadmapVersionsIds?.reduce((acc, roadmapVersionId) => {
      const projectVersions = mappedVersionProjects?.[roadmapVersionId] ?? [];
      const roadmapVersion = roadmapVersions.find(version => version?.id === +roadmapVersionId);
      const roadmapVersionProject = projectVersions?.find(proj => proj?.id === project?.id);

      if (roadmapVersionProject) {
        return [
          ...acc,
          {
            ...roadmapVersionProject,
            id: `${project.id}_version_${roadmapVersionId}`,
            title: roadmapVersion?.name,
            showTitle: false,
          },
        ];
      }

      return acc;
    }, getItemsDefaultWithPor(project));
  };

  const processGroupLevel2 = projectsToConsider => {
    return projectsToConsider.map(project => {
      return {
        meta: { type: 'project', groupData: project },
        title: project?.title,
        level: 2,
        items: getProjectsFromSelectedScenariosForGroup(project),
      };
    });
  };

  const processGroupLevel1 = timelineData => {
    return timelineData.map(firstGroup => {
      const projects = firstGroup.items ?? [];

      const projectsToConsider = hideRowsWithSameData
        ? filterDuplicateProjectValuesByFieldIds(
            projects,
            selectedRoadmapVersionsIds,
            mappedVersionProjects,
            FIELD_IDS,
            FIELD_ID_COMPARISON,
          )
        : projects;

      const newSubGroups = processGroupLevel2(projectsToConsider);

      const newGroup = { ...omit(['items'], firstGroup), groups: newSubGroups };

      return newGroup;
    });
  };

  return processGroupLevel1(timelineData);
};

export default prepareDataForSwimlaneComparison;
