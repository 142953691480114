import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Rnd } from 'react-rnd';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import theme from 'design-system/theme';

import isClickEventNoDragEnd from 'utils/timeline/isClickEventNoDragEnd';

import TooltipContent from './TooltipContent';

import useTableBarActionHandlers from 'design-system/organisms/RoadmapTable/hooks/useTableBarActionHandlers';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    background: '#fff',
    maxWidth: 350,
    maxHeight: '100%',
    borderRadius: 0,
    border: `1px solid ${theme.palette.border.mercury}`,
    padding: 14,
    opacity: 1,
  },
}))(Tooltip);

const TableBar = ({
  id,
  title,
  name,
  color,
  textColor,
  borderColor,
  startDate,
  endDate,
  predictedEndDate,
  progress,
  top,
  left,
  width,
  height,
  noMargin,
  disableDragX = false,
  disableDragY = false,
  boundsname,
  openBody,
  openLightBox,
  onDoubleClick,
  onDrag,
  onDragStop,
  onResizeStop,
  onResize,
  isLoading,
  showTooltip,
  showTextOverflow,
  resizable = true,
  resizeGrid,
  dragGrid,
}) => {
  const [open, setOpen] = useState(false);

  const {
    onOpenTooltip,
    onCloseTooltip,
    handleDragStop,
    handleDrag,
    handleResizeStop,
    handleResize,
    handleClick,
    handleDoubleClick,
  } = useTableBarActionHandlers(
    id,
    left,
    top,
    setOpen,
    isClickEventNoDragEnd,
    onDragStop,
    onDrag,
    onResizeStop,
    onResize,
    openBody,
    openLightBox,
    onDoubleClick,
  );

  const style = {
    zIndex: '50',
    // overflow: 'hidden',
  };

  const dragAxis = useMemo(() => {
    if (isLoading) {
      return 'none';
    }

    if (!disableDragX && !disableDragY) return 'both';
    if (!disableDragX) return 'x';
    if (!disableDragY) return 'y';

    return 'none';
  }, [disableDragX, disableDragY, isLoading]);

  return (
    <Rnd
      style={style}
      default={{
        x: left,
        y: top,
        width,
        height,
      }}
      position={{
        x: left,
        y: top,
      }}
      size={{
        width,
        height,
      }}
      enableResizing={{
        left: resizable,
        right: resizable,
      }}
      disableDragging={isLoading || (disableDragX && disableDragY)}
      dragGrid={dragGrid}
      resizeGrid={resizeGrid}
      bounds={boundsname ? `.${boundsname}` : ''}
      dragAxis={dragAxis}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      onDragStart={onCloseTooltip}
      onDragStop={handleDragStop}
      onDrag={handleDrag}
      onResizeStart={onCloseTooltip}
      onResizeStop={handleResizeStop}
      onResize={handleResize}
    >
      <HtmlTooltip
        open={showTooltip && open}
        onOpen={onOpenTooltip}
        onClose={onCloseTooltip}
        title={
          <TooltipContent
            name={name}
            title={title}
            startDate={startDate}
            endDate={endDate}
            predictedEndDate={predictedEndDate}
            progress={progress}
          />
        }
      >
        <BarWrapper style={{ backgroundColor: color, color: textColor, marginTop: noMargin ? 0 : 6 }} borderColor={borderColor}>
          <BarProgress progress={Math.round(progress * 100)} />
          <BarContent showTextOverflow={showTextOverflow}>{title}</BarContent>
        </BarWrapper>
      </HtmlTooltip>
    </Rnd>
  );
};

TableBar.displayName = 'TableBar';

export default TableBar;

const BarWrapper = styled.div`
  &&&& {
    height: 21px;
    width: 100%;
    box-sizing: border-box;

    margin-top: 6px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 10px;
    border-radius: 14px;

    cursor: pointer;

    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);

    ${({ borderColor }) =>
      borderColor &&
      `
      border: 1px dashed ${borderColor};
    `}
  }
`;

const BarProgress = styled.div`
  position: absolute;
  left: 0;
  width: ${({ progress }) => `${progress ?? 0}%`};
  height: 21px;
  background-color: ${theme.palette.background.jet};
  border-radius: ${({ progress }) => (progress === 100 ? '14px' : '14px 0 0 14px')};
  opacity: 0.2;
`;

const BarContent = styled.p`
  &&&& {
    max-width: 100%;

    overflow: ${props => (props.showTextOverflow ? 'unset' : 'hidden')};
    white-space: nowrap;
    text-overflow: ellipsis;

    user-select: none;

    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    font-stretch: 100%;
    font-weight: normal;
  }
`;
