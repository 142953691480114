import moment from 'moment';

/**
 * Default allocation date range = first date of current quarter => last date of upcoming quarter
 * @returns default start and end dates for allocation query
 */
function getDefaultAllocationDates() {
  const startDate = moment().startOf('quarter');
  const nextQuarterDate = moment().endOf('quarter').add(1, 'days').startOf('day').format();
  const endDate = moment(nextQuarterDate).endOf('quarter').startOf('day').format();

  return { startDate, endDate };
}

export { getDefaultAllocationDates };
