const isRowDraggable = params => {
  const { node, data: nodeData } = params;

  // only allow non-groups to be dragged
  const isGroup = node?.group || nodeData?.group;

  return !isGroup;
};

export { isRowDraggable };
