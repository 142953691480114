import axios from 'axios';

import {
  TIMEFRAME_STATE_CHANGE_ACTION,
  FETCH_TIMEFRAMES,
  CREATE_TIMEFRAME,
  UPDATE_TIMEFRAME,
  DELETE_TIMEFRAMES,
  ADD_SELECTED_GRID_TIMEFRAME,
  REMOVE_SELECTED_GRID_TIMEFRAME,
  UPDATE_TIMEFRAME_BY_ID,
  ADD_TIMEFRAME_WITHOUT_SAVE,
  REMOVE_UNSAVED_TIMEFRAME,
  CREATE_TIMEFRAME_FULFILLED,
  DELETE_TIMEFRAMES_FULFILLED,
  UPDATE_TIMEFRAME_FULFILLED,
  BULK_DELETE_TIMEFRAMES_FULFILLED,
  BULK_DELETE_TIMEFRAME_ROADMAP,
  CREATE_TIMEFRAME_ROADMAP,
  DELETE_TIMEFRAME_ROADMAP,
} from './types';

import { convertedDateTime } from 'utils';
import throwRequestError from '../utils/throwRequestError';
import fetchMetadataAction from '../utils/factory/fetchMetadataAction';

import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';

export const createTimeframeAction = (partialStateName, partialStateValue) => ({
  type: TIMEFRAME_STATE_CHANGE_ACTION,
  partialStateName,
  partialStateValue,
});

export const fetchTimeframes = fetchMetadataAction(
  FETCH_TIMEFRAMES,
  '/api/timeframes',
  state => state.timeframes.toJS().rows,
  state => state.timeframes.toJS().lastCallsDate,
);

export const createTimeframe = timeframe => {
  return {
    payload: axios
      .post(
        '/api/timeframes',
        Object.assign({}, timeframe, {
          date: timeframe.date ? new Date(timeframe.date) : undefined,
          freeze_1: timeframe.freeze_1 ? new Date(timeframe.freeze1) : undefined,
          freeze_2: timeframe.freeze_2 ? new Date(timeframe.freeze2) : undefined,
          updated_at: timeframe.updated_at ? convertedDateTime(timeframe.updated_at) : undefined,
        }),
      )
      .then(res => res.data),
    type: CREATE_TIMEFRAME,
  };
};

export const updateTimeframe = timeframe => {
  return {
    payload: axios
      .post(`/api/timeframes/update/${timeframe.id}`, timeframe)
      .then(res => res.data)
      .catch(throwRequestError),
    type: UPDATE_TIMEFRAME,
  };
};

export const deleteTimeframes = ids => {
  return {
    payload: axios.post('/api/timeframes/delete', ids),
    type: DELETE_TIMEFRAMES,
  };
};

export const addSelectedGridTimeframe = timeframe => {
  return {
    type: ADD_SELECTED_GRID_TIMEFRAME,
    timeframe,
  };
};

export const removeSelectedGridTimeframe = timeframeId => {
  return {
    type: REMOVE_SELECTED_GRID_TIMEFRAME,
    timeframeId,
  };
};

export const updateTimeframeById = (timeframeId, update) => {
  return {
    payload: axios
      .put(`/api/timeframes/${timeframeId}`, update)
      .then(res => res.data)
      .catch(throwRequestError),
    type: UPDATE_TIMEFRAME_BY_ID,
  };
};

export const createTimeframeRoadmap = (timeframeId, timeframeRoadmap) => {
  return {
    payload: axios.post(`/api/timeframes/${timeframeId}/timeframeRoadmaps/`, timeframeRoadmap).then(response => response.data),
    type: CREATE_TIMEFRAME_ROADMAP,
  };
};

export const deleteTimeframeRoadmap = ({ id, roadmapId, subRoadmapId = null, product2Id = null }) => {
  let route = `/api/timeframes/${id}/timeframeRoadmaps/${roadmapId}`;

  route += subRoadmapId ? `/${subRoadmapId}` : '';
  route += subRoadmapId && product2Id ? `/${product2Id}` : '';

  return {
    payload: axios.delete(route).then(response => response.data),
    type: DELETE_TIMEFRAME_ROADMAP,
  };
};

export const bulkDeleteTimeframeRoadmaps = timeframeId => {
  return {
    payload: axios.delete(`/api/timeframes/${timeframeId}/timeframeRoadmaps/`).then(response => response.data),
    type: BULK_DELETE_TIMEFRAME_ROADMAP,
  };
};

export const addTimeframeWithoutSave = timeframe => ({
  type: ADD_TIMEFRAME_WITHOUT_SAVE,
  timeframe,
});

export const removeUnsavedTimeframes = () => ({
  type: REMOVE_UNSAVED_TIMEFRAME,
});

export const gotTimeframesRealtimeUpdate = generateRealtimeUpdateAction(
  CREATE_TIMEFRAME_FULFILLED,
  DELETE_TIMEFRAMES_FULFILLED,
  UPDATE_TIMEFRAME_FULFILLED,
  BULK_DELETE_TIMEFRAMES_FULFILLED,
);

export const timeframeActions = {
  createTimeframeAction,
  fetchTimeframes,
  createTimeframe,
  updateTimeframe,
  deleteTimeframes,
  updateTimeframeById,
};
