import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

import { isNumeric } from 'design-system/utils';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;

export default class NumericEditor extends Component {
  constructor(props) {
    super(props);

    this.cancelBeforeStart = this.props.charPress && '1234567890'.indexOf(this.props.charPress) < 0;

    this.state = this.createInitialState(props);

    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.inputRef = null;
  }

  createInitialState(props) {
    let startValue;
    let highlightAllOnFocus = true;

    if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
      // if backspace or delete pressed, we clear the cell
      startValue = '';
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = isNumeric(props.value) ? props.value : '';
      if (props.keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    return {
      value: startValue,
      highlightAllOnFocus,
    };
  }

  componentDidMount() {
    this.inputRef.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    this.inputRef.removeEventListener('keydown', this.onKeyDown);
  }

  afterGuiAttached() {
    // get ref from React component
    const eInput = this.inputRef;

    eInput.focus();
    if (this.state.highlightAllOnFocus) {
      eInput.select();

      this.setState({
        highlightAllOnFocus: false,
      });
    } else {
      // when we started editing, we want the carot at the end, not the start.
      // this comes into play in two scenarios: a) when user hits F2 and b)
      // when user hits a printable character, then on IE (and only IE) the carot
      // was placed after the first character, thus 'apply' would end up as 'pplea'
      const length = eInput.value ? eInput.value.length : 0;

      if (length > 0) {
        eInput.setSelectionRange(length, length);
      }
    }
  }

  getValue() {
    return this.state.value !== '' ? this.state.value : null;
  }

  isCancelBeforeStart() {
    return this.cancelBeforeStart;
  }

  onKeyDown(event) {
    if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
      event.stopPropagation();
      return;
    }

    if (!this.isKeyPressedNumeric(event)) {
      if (event.preventDefault) event.preventDefault();
    }
  }

  isLeftOrRight(event) {
    return [37, 39].indexOf(event.keyCode) > -1;
  }

  handleChange(event) {
    this.setState({ value: this.props.column.colDef.integer ? Math.round(+event.value) : event.value });
  }

  getCharCodeFromEvent(event) {
    event = event || window.event;
    return typeof event.which === 'undefined' ? event.keyCode : event.which;
  }

  isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
  }

  isCharDot(charStr) {
    return charStr === '.';
  }

  isCharE(charStr) {
    return charStr === 'e';
  }

  isKeyPressedNumeric(event) {
    const charCode = this.getCharCodeFromEvent(event);
    const charStr = event.key ? event.key : String.fromCharCode(charCode);

    return this.isCharNumeric(charStr) || this.isCharDot(charStr) || this.isCharE(charStr);
  }

  render() {
    return (
      <div>
        <NumberFormat
          customInput={TextField}
          thousandSeparator
          onValueChange={this.handleChange}
          className="ag-cell-edit-input"
          type="text"
          style={{ textAlign: 'center' }}
          inputRef={el => {
            this.inputRef = el;
          }}
          decimalScale={this.props.column.colDef.decimalScale || 2}
          value={this.state.value}
        />
      </div>
    );
  }

  deleteOrBackspace(event) {
    return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;
  }
}
