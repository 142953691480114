import { useEffect, useState } from 'react';

import usePageFilters from 'hooks/filters/usePageFilters';

/**
 * @function useInputSearchValue
 *
 * use input search value with logic to get the current serach string from
 * page filters
 *
 * @return {Object}
 */
const useInputSearchValue = () => {
  const { searchString: searchValue } = usePageFilters();
  const [value, setValue] = useState(searchValue);

  useEffect(() => {
    setValue(searchValue);
  }, [searchValue]);

  return {
    value,
    setValue,
    searchValue,
  };
};

export default useInputSearchValue;
