import React, { useMemo } from 'react';
import styled from 'styled-components';

import DividedList from 'design-system/organisms/DividedList/index';


import history from 'store/utils/history';

import useViewsDialog from 'hooks/userViews/useViewsDialog';

export default function HomeNavOptions() {
  const { openDialog } = useViewsDialog();

  const navOptions = useMemo(() => {
    return [
      { label: 'Home', id: 'home_link', onClick: () => history.push('/home'), active: true },
      // Best of Webinars will have a url in the future, just a pendo_id for now
      { label: 'Best of Webinars', id: 'webinars' },
      { label: 'Product Tour', id: 'welcome_link', onClick: () => history.push('/welcome') },
      { label: 'Select a Template', onClick: () => history.push('/dashboard/templates') },
      { label: 'Explore Views', onClick: () => openDialog() },
    ];
  }, [openDialog]);

  const linkOptions = useMemo(() => {
    return [
      {
        label: 'Join Slack Community',
        href: 'https://join.slack.com/t/ourdragonboat/shared_invite/zt-7dwylil3-j9hp8BXfl8rBQ0bD8k160Q',
      },
      { label: 'Responsive PPM', href: 'http://responsiveppm.org/' },
    ];
  }, []);

  return <SidebarOptions bottomOptions={linkOptions} topOptions={navOptions} />;
}

const SidebarOptions = styled(DividedList)`
  padding: 28px 15px 28px 26px;
`;
