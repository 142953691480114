import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getSliceProp as getSummarySliceProp } from 'store/summary/selectors';
import { COMPARE_ROADMAP_HISTORY_SUMMARY } from 'features/RoadmapHistory/constants';
import {
  selectCurrentProjects,
  selectCurrentProjectsIds,
  selectProjectsOfSelectedSnapsToCompare,
  selectSelectedSnapshotsIdsToCompare,
} from 'features/RoadmapHistory/store/selectors';

const useProjectsForSummaryCompare = () => {
  const searchStr = useSelector(state => getSummarySliceProp('searchStr')(state, COMPARE_ROADMAP_HISTORY_SUMMARY));

  const selectedSnapIdsToCompare = useSelector(selectSelectedSnapshotsIdsToCompare);
  const selectedProjectsOfSnapsToCompare = useSelector(selectProjectsOfSelectedSnapsToCompare);
  const currentProjectsIds = useSelector(selectCurrentProjectsIds);
  const currentProjects = useSelector(selectCurrentProjects);

  const projectsRef = useRef([]);
  const projectsSnapsRef = useRef({});

  projectsRef.current = currentProjects;
  projectsSnapsRef.current = selectedProjectsOfSnapsToCompare;

  const filteredProjects = useMemo(() => {
    const localSearchFilter = ({ title }) => (searchStr ? title.toLowerCase().includes(searchStr.toLowerCase()) : true);

    const snapProjectsFiltered = Object.values(projectsSnapsRef.current).reduce((acc, snap) => {
      const snapProjects = Object.values(snap?.projects) || [];
      const currentSnapProjects =
        snapProjects.filter?.(localSearchFilter)?.map(project => ({ ...project, roadmap_history_snap_id: snap?.id })) || [];

      return [...acc, ...currentSnapProjects];
    }, []);

    const filteredProjects = projectsRef.current.filter(localSearchFilter);

    return [...filteredProjects, ...snapProjectsFiltered];
  }, [currentProjectsIds, selectedSnapIdsToCompare, searchStr]);

  return filteredProjects;
};

export default useProjectsForSummaryCompare;
