import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import AuthForm from '../AuthForm';
import Logo from './logo.png';
import BackgroundImage from 'design-system/atoms/Background/images/Grupo5812.png';

const styles = theme => ({
  content: {
    textAlign: 'center',
    height: '100vh',
    width: '100%',
    minHeight: 650,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    backgroundSize: 'cover',
    overflowY: 'scroll',
  },
});

export default withStyles(styles)(props => {
  const loadLogoImage = () => {
    return (
      <a href="/">
        <img src={Logo} alt="Logo" height="50px" width="200px" />
      </a>
    );
  };

  return (
    <div className={props.classes.content}>
      <AuthForm>
        {loadLogoImage()}
        {props.children}
      </AuthForm>
    </div>
  );
});
