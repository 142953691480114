import { applyMiddleware, compose } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { createListenerMiddleware } from '@reduxjs/toolkit';

import errorHandler from './errorHandler';
import updateAutocompleteOptions from './updateAutocompleteOptions';
import processNotifications from 'store/notifications/processNotifications';
import onZendeskLoaded from './onZendeskLoaded';
import updateCurrentUser from './updateCurrentUser';
import login from './login';
import projectCreated from './projectCreated';
import debounceUpdateProject from './debounceUpdateProject';
import activeViewUpdateMiddleware from './userViews/activeViewUpdateMiddleware';
import debounceUpdateRequest from './debounceUpdateRequest';
import portfolioOverview from 'features/PortfolioOverview/store/middleware';

// Create redux toolkit listener middleware
const listenerMiddleware = createListenerMiddleware();

const isDevCompose =
  process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
const composeEnhancers = isDevCompose ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true }) : compose;

const logger = process.env.NODE_ENV === 'development' && createLogger({ collapsed: true });
const dragonboatMiddleware = [
  thunk,
  promiseMiddleware(),
  updateAutocompleteOptions,
  processNotifications,
  updateCurrentUser,
  onZendeskLoaded,
  errorHandler,
  login,
  projectCreated,
  debounceUpdateProject,
  activeViewUpdateMiddleware,
  portfolioOverview,
  debounceUpdateRequest,
];

const middleware = [listenerMiddleware.middleware, ...dragonboatMiddleware, logger].filter(Boolean);
const middlewareEnhancer = applyMiddleware(...middleware);

export default composeEnhancers(middlewareEnhancer);
