import { useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import remove from 'lodash/remove';

import usePrevious from 'hooks/usePrevious';

import {
  dataFormatter,
  DAYS_UNIT_TIME,
  generateTimelineData,
  getLastGroupItems,
  getLocalItem,
  getTimelineOrders,
  LEFT_DIRECTION,
  findGroupsLevel2ByGroupIds,
  findGroupsLevel1ByGroupIds,
  findGroupsLevel3ByGroupIds,
} from '../helpers';

/**
 * Custom hook that manages the internal timeline state.
 * It manually updates the visual information of items without waiting for the operation result.
 * This change is effective when the result of the operation takes place.
 * */
const useTimelineLocalState = ({
  timelineData,
  timelineOrders,
  isLoading,
  shouldResetData,
  isGroupOpen,
  hideEmptyLane,
  groupsCount,
  zoomMode,
  slotWidth,
  fromDate,
  snapToGridOn,
  displayMilestone,
  displayMilestoneOn,
  isMilestoneItemChecker,
}) => {
  const [internalData, setInternalData] = useState([...timelineData]);
  const [internalOrders, setInternalOrders] = useState(timelineOrders);

  const externalDataFormatted = useMemo(() => dataFormatter(timelineData), [timelineData]);
  const internalDataFormatted = useMemo(() => dataFormatter(internalData), [internalData]);

  const previousExternalDataFormatted = usePrevious(externalDataFormatted);

  const refreshInternalState = newData => {
    setInternalData(newData);
    setInternalOrders(getTimelineOrders(newData, isGroupOpen, hideEmptyLane, groupsCount));
  };

  const updateItemAfterResize = ({ item, groupIndexes, rowIndex, direction, position, delta }) => {
    const internalItem = getLocalItem(item, internalData, groupIndexes, rowIndex);

    if (internalItem) {
      // updates the internal state item
      if (direction === LEFT_DIRECTION) {
        internalItem.info.left = position.x;
        internalItem.info.width += delta.width;
      } else {
        internalItem.info.width += delta.width;
      }

      refreshInternalState([...internalData]);
    }
  };

  const updateItemAfterDrag = ({ item, groupIndexes, rowIndex, dateDiff, newOrderGroupIds, anyGroupChanged }) => {
    const internalITem = getLocalItem(item, internalData, groupIndexes, rowIndex);

    if (internalITem) {
      if (dateDiff) {
        internalITem.startDate.add(dateDiff, DAYS_UNIT_TIME);
        internalITem.endDate.add(dateDiff, DAYS_UNIT_TIME);
      }

      // updates internal data
      if (anyGroupChanged) {
        const items = getLastGroupItems(internalData, groupIndexes, item);

        // remove item from the current groups
        remove(items, ({ id }) => id === item.id);

        if (groupIndexes.length === 3) {
          // find the group level 3 to add
          const groupLevel3 = findGroupsLevel3ByGroupIds(internalData, newOrderGroupIds);

          if (groupLevel3) {
            groupLevel3.items = [...(groupLevel3.items ?? []), internalITem];
          }
        } else if (newOrderGroupIds.length === 2) {
          const groupLevel2 = findGroupsLevel2ByGroupIds(internalData, newOrderGroupIds);

          if (groupLevel2) {
            groupLevel2.items = [...(groupLevel2.items ?? []), internalITem];
          }
        } else {
          const groupLevel1 = findGroupsLevel1ByGroupIds(internalData, newOrderGroupIds);

          if (groupLevel1) {
            groupLevel1.items = [...(groupLevel1?.items ?? []), internalITem];
          }
        }
      }

      if (dateDiff || anyGroupChanged) {
        const refreshedData = generateTimelineData({
          data: internalData,
          zoomMode,
          slotWidth,
          fromDate,
          snapToGridOn,
          isGroupOpen,
          groupsCount,
          displayMilestone,
          displayMilestoneOn,
          isMilestoneItemChecker,
          hideEmptyLane,
        });

        refreshInternalState(refreshedData);
      }
    }
  };

  useEffect(() => {
    if (
      shouldResetData ||
      (!isLoading &&
        !isEqual(externalDataFormatted, previousExternalDataFormatted) &&
        !isEqual(externalDataFormatted, internalDataFormatted))
    ) {
      setInternalData(timelineData);
      setInternalOrders(timelineOrders);
    }
  }, [
    timelineData,
    timelineOrders,
    externalDataFormatted,
    previousExternalDataFormatted,
    internalDataFormatted,
    setInternalData,
    isLoading,
    shouldResetData,
  ]);

  useEffect(() => {
    if (!isEqual(timelineOrders, internalOrders)) {
      setInternalOrders(timelineOrders);
    }
  }, [timelineOrders, internalOrders]);

  return {
    internalData,
    setInternalData,
    internalOrders,
    setInternalOrders,
    refreshInternalState,
    updateItemAfterDrag,
    updateItemAfterResize,
  };
};

export default useTimelineLocalState;
