import React from 'react';

import { CORP_OBJECTIVE_KEY, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY, OBJECTIVE_KEY } from 'constants/objectives';

import { openObjectiveDrawer } from 'store/objectives/actions';
import { OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2, OBJECT_OBJECTIVE, OBJECT_OBJECTIVE_CORP_STRING } from 'store/objectives';

import TitleWithActions from 'design-system/organisms/TitleWithActions';

import { isMilestoneTopGroup } from 'routes/Swimlane/New/helpers/milestones';

const isObjectiveOrKeyResult = itemType =>
  [CORP_OBJECTIVE_KEY, OBJECTIVE_KEY, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY].includes(itemType);

// Gets the type as a string and returns the okr key
const mapLevelIntoObject = level => {
  switch (level) {
    case CORP_OBJECTIVE_KEY:
      return OBJECT_OBJECTIVE_CORP_STRING;
    case OBJECTIVE_KEY:
      return OBJECT_OBJECTIVE;
    case KEY_RESULT_1_KEY:
      return OBJECT_KEY_RESULT;
    case KEY_RESULT_2_KEY:
      return OBJECT_KEY_RESULT_2;
    default:
      return OBJECT_OBJECTIVE;
  }
};

/**
 * Creates the group title render for the Timeline component.
 * We may extend the group title cell to include more elements besides the title text.
 *
 * */
const makeCustomGroupTitleRenderer = dispatch => (group, defaultRenderTitle) => {
  if (group?.id) {
    const itemType = group.meta?.type || OBJECTIVE_KEY;

    const isMilestoneGroup = isMilestoneTopGroup(group);
    const isOkr = isObjectiveOrKeyResult(itemType);
    const type = mapLevelIntoObject(group.meta.type);

    const openButtonProps = { onClick: () => dispatch(openObjectiveDrawer(group.id, type)) };

    if (group?.id && !isMilestoneGroup && isOkr) {
      return (
        <TitleWithActions openButtonProps={openButtonProps} hasOpenButton>
          {defaultRenderTitle(group)}
        </TitleWithActions>
      );
    }
  }

  return defaultRenderTitle(group);
};

export { makeCustomGroupTitleRenderer };
