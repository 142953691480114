import { METADATA_LEVELS, PRODUCTS_2 } from 'constants/common';

import { map, defaultTo, pipe, propEq, ifElse, prop, either, isNil, isEmpty, all, any, allPass } from 'ramda';

const PRODUCTS_KEY_TYPE = 'products';
const PRODUCTS_2_KEY_TYPE = 'products2';
const LEVEL = 'level';
const CHILDREN = 'children';
const PRODUCTS = 'products';
const ROADMAP_CORP = 'roadmapCorp';
const ROADMAP = 'roadmap';
const ID = 'id';
const DEPTH_CORP = 0;
const DEPTH_ROADMAP = 1;
const DEPTH_PRODUCT = 2;
const DEPTH_PRODUCT_2 = 3;
const MAX_DEPTH = DEPTH_PRODUCT_2;
const ROADMAP_CORP_FIELD = 'roadmapCorp';
const ROADMAP_FIELD = 'roadmap';
const PRODUCT_FIELD = 'product1';
const PRODUCT_2_FIELD = 'product2';
const TYPE = 'type';

const depthToType = {
  [DEPTH_CORP]: ROADMAP_FIELD,
  [DEPTH_ROADMAP]: ROADMAP_FIELD,
  [DEPTH_PRODUCT]: PRODUCT_FIELD,
  [DEPTH_PRODUCT_2]: PRODUCT_2_FIELD,
};
const depthToTypeWithCorpRelation = {
  [DEPTH_CORP]: ROADMAP_CORP_FIELD,
  [DEPTH_ROADMAP]: ROADMAP_FIELD,
  [DEPTH_PRODUCT]: PRODUCT_FIELD,
  [DEPTH_PRODUCT_2]: PRODUCT_2_FIELD,
};

const PRODUCT_KEY_BY_LEVEL = {
  0: PRODUCTS_KEY_TYPE,
  1: PRODUCTS_2_KEY_TYPE,
};

const isTypeRoadmapCorp = propEq(TYPE, ROADMAP_CORP);
const isTypeRoadmap = propEq(TYPE, ROADMAP);

const getRoadmapObject = (depth, hasRoadmapCorpRelation = false) =>
  pipe(prop((hasRoadmapCorpRelation ? depthToTypeWithCorpRelation : depthToType)[depth]), defaultToEmptyObject);

const defaultToEmptyObject = defaultTo({});
const defaultToEmptyArray = defaultTo([]);

const isCorpLevel = pipe(defaultToEmptyObject, propEq(LEVEL, METADATA_LEVELS.LEVEL_CORP));
const getRoadmapChildren = ifElse(isCorpLevel, prop(CHILDREN), prop(PRODUCTS));

const formatRoadmap = roadmap => {
  return {
    id: roadmap.id,
    key: 'roadmaps',
    label: roadmap.title,
    type: getRoadmapType(roadmap),
    children: getChildrenFormatter(roadmap),
  };
};

const getRoadmapType = ifElse(
  isCorpLevel,
  () => ROADMAP_CORP,
  () => ROADMAP,
);

const formatChildrenRoadmaps = pipe(defaultToEmptyArray, map(formatRoadmap));

const formatChildrenProducts = (products = [], level = 0) =>
  products.map(child => {
    return {
      id: child.id,
      label: child.title,
      key: PRODUCT_KEY_BY_LEVEL[level],
      children: formatChildrenProducts(child.products, level + 1),
    };
  });

const getChildrenFormatter = ifElse(
  isCorpLevel,
  pipe(getRoadmapChildren, formatChildrenRoadmaps),
  pipe(getRoadmapChildren, formatChildrenProducts),
);

/**
 * @function getRoadmapsTreeForDropdown
 * Formats a roadmap tree to be displayed on a Hierarchy Tree Checkbox component
 *
 * @param  {Array} roadmaps
 * @returns  {Array} formatted roadmaps
 */
const getRoadmapsTreeForDropdown = map(formatRoadmap);

const makeAddDefaultMetadataRoadmapName =
  getDefaultRoadmapTitleForMetadataItem =>
  ({ value: metadataRoadmaps, data }) => {
    if (!metadataRoadmaps.length) {
      const defaultRoadmapTitle = getDefaultRoadmapTitleForMetadataItem(data);

      return [{ title: defaultRoadmapTitle }];
    }

    return metadataRoadmaps;
  };

const isNilOrEmpty = either(isNil, isEmpty);
const hasOnlyDefaultMetadataRoadmap = pipe(defaultToEmptyArray, all(pipe(prop('id'), isNil)));

/**
 * @function formatMetadataRoadmapsForDisplay
 * Returns an Array of roadmaps ready for being display on a chip component
 *
 * @param  {Array} metadataRoadmaps
 * @returns  {Array} Roadmaps/Products
 */
const formatMetadataRoadmapsForDisplay = metadataRoadmaps => {
  if (isNilOrEmpty(metadataRoadmaps) || hasOnlyDefaultMetadataRoadmap(metadataRoadmaps)) {
    return metadataRoadmaps;
  }

  const roadmaps = new Set([]);

  const filtered = (metadataRoadmaps || []).filter(
    value => !!value.roadmapCorp || !!value.roadmap || !!value.product1 || !!value.product2,
  );

  filtered.forEach(mr => {
    roadmaps.add(mr.product2 || mr.product1 || mr.roadmap || mr.roadmapCorp);
  });

  return Array.from(roadmaps);
};

const makeIsParentSelected = (parentId, depth, hasRoadmapCorpRelation) => {
  const doestNotHaveProduct2 = pipe(prop(PRODUCT_2_FIELD), isNil);
  const doestNotHaveProduct = pipe(prop(PRODUCT_FIELD), isNil);
  const doestNotHaveRoadmap = pipe(prop(ROADMAP_FIELD), isNil);

  const checkIfHasRoadmapMatch = pipe(getRoadmapObject(depth, hasRoadmapCorpRelation), propEq(ID, parentId));
  const checkWhenRoadmapCorp = allPass([...(hasRoadmapCorpRelation ? [doestNotHaveRoadmap] : []), checkIfHasRoadmapMatch]);
  const checkWhenRoadmap = allPass([doestNotHaveProduct, checkIfHasRoadmapMatch]);
  const checkWhenProduct = allPass([doestNotHaveProduct2, checkIfHasRoadmapMatch]);
  const checkWhenProduct2 = checkIfHasRoadmapMatch;

  switch (depth) {
    case DEPTH_CORP:
      return checkWhenRoadmapCorp;
    case DEPTH_ROADMAP:
      return checkWhenRoadmap;
    case DEPTH_PRODUCT:
      return checkWhenProduct;
    default:
      return checkWhenProduct2;
  }
};

/**
 * @function recursivelySetBooleanTree
 * Recursive functions that sets each level of the boolean tree. Used by formatSelectedItemsBooleanTree
 *
 * @param  {Object} parent - roadmap or product
 * @param  {Array} metadataRoadmaps - metadataRoadmap objects for a specific metadata
 * @param  {Integer} depth - depth of recursion
 * @returns  {Object} Boolean tree slice
 */
const recursivelySetBooleanTree = (parent, metadataRoadmaps, depth, hasRoadmapCorpRelation) => {
  let selected = {};

  const children = getRoadmapChildren(parent);

  const parentId = prop(ID, parent);

  const hasMetadataRoadmapMatchingThisParent = pipe(
    defaultToEmptyArray,
    any(makeIsParentSelected(parentId, depth, hasRoadmapCorpRelation)),
  );

  if (hasMetadataRoadmapMatchingThisParent(metadataRoadmaps)) {
    return true;
  }

  if (children?.length && depth < MAX_DEPTH) {
    children.forEach(eachChild => {
      selected = {
        ...selected,
        [eachChild.id]: recursivelySetBooleanTree(eachChild, metadataRoadmaps, depth + 1, hasRoadmapCorpRelation),
      };
    });
  } else {
    return false;
  }

  return selected;
};

/**
 * @function formatSelectedItemsBooleanTree
 * Returns a tree of booleans represensing which roadmaps/products are connected to each roadmap/roadmapCorp metadata
 *
 * @param  {Object} options
 * @param  {Boolean} options.shouldUseCorpRoadmap
 * @param  {Array} options.roadmapsCorp
 * @param  {Array} options.roadmaps
 * @param  {Array} options.metadataRoadmaps
 * @returns  {Object}
 */
const formatSelectedItemsBooleanTree = ({
  shouldUseCorpRoadmap,
  roadmapsCorp,
  roadmaps,
  metadataRoadmaps,
  hasRoadmapCorpRelation = false,
}) => {
  const objects = shouldUseCorpRoadmap ? roadmapsCorp : roadmaps;
  const depth = shouldUseCorpRoadmap ? DEPTH_CORP : DEPTH_ROADMAP;

  return objects.reduce(
    (booleanTree, eachParent) => ({
      ...booleanTree,
      [eachParent.id]: recursivelySetBooleanTree(eachParent, metadataRoadmaps, depth, hasRoadmapCorpRelation),
    }),
    {},
  );
};

const checkIsUpdatedItemChecked = (checkValue, updatedItem) => {
  return Object.entries(checkValue).some(([key, value]) => {
    if (typeof value === 'object') {
      return checkIsUpdatedItemChecked(value, updatedItem);
    }

    return key === String(updatedItem.id) && value === true;
  });
};

const getRoadmapMetadataToUpdate = (
  updatedItem,
  topLevelRoadmapId,
  hasRoadmapCorpRelation,
  shouldUseCorpRoadmap,
  { roadmaps, products1, products2 },
) => {
  let roadmapCorpId = null;
  let roadmapId = null;
  let productId = null;
  let product2Id = null;

  switch (true) {
    case isTypeRoadmapCorp(updatedItem):
      roadmapCorpId = topLevelRoadmapId;

      /*
       * When roadmap entity does not have corp relation
       *
       * Will use also the roadmapID as corp roadmap id
       * It means roadmap is level = CORP
       * */
      if (!hasRoadmapCorpRelation) {
        roadmapId = updatedItem.id;
      }

      break;
    case isTypeRoadmap(updatedItem):
      const roadmap = roadmaps.find(r => r.id === updatedItem.id);

      roadmapCorpId = roadmap?.parent_id;
      roadmapId = updatedItem.id;

      break;
    case updatedItem.key === PRODUCTS:
      const product1Selected = products1.find(p1 => p1.id === updatedItem.id);
      const roadmapOnProduct1 = roadmaps.find(r => r.id === product1Selected?.roadmap_id);

      roadmapCorpId = roadmapOnProduct1?.parent_id;
      roadmapId = product1Selected?.roadmap_id;
      productId = product1Selected?.id;

      break;
    case updatedItem.key === PRODUCTS_2:
      const product2Selected = products2.find(p2 => p2.id === updatedItem.id);
      const roadmapOnProduct2 = roadmaps.find(r => r.id === product2Selected?.roadmap_id);

      roadmapCorpId = roadmapOnProduct2?.parent_id;
      roadmapId = product2Selected?.roadmap_id;
      productId = product2Selected?.parent_id;
      product2Id = product2Selected?.id;

      break;

    default:
      break;
  }

  return { roadmapCorpId: shouldUseCorpRoadmap ? roadmapCorpId : null, roadmapId, productId, product2Id };
};

export {
  getRoadmapMetadataToUpdate,
  getRoadmapsTreeForDropdown,
  makeAddDefaultMetadataRoadmapName,
  formatMetadataRoadmapsForDisplay,
  formatSelectedItemsBooleanTree,
  checkIsUpdatedItemChecked,
};
