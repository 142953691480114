import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil, not } from 'ramda';

import {
  fetchAvailableRoadmapVersions as fetchAvailableRoadmapVersionsAction,
  fetchMultipleRoadmapVersionProjects,
  setRoadmapVersionSelected,
} from 'store/roadmapVersions';
import {
  getAvailableRoadmapVersions as getAvailableRoadmapVersionsSelector,
  selectIsRoadmapVersionsLoaded as isRoadmapVersionsLoadedSelector,
  isRoadmapVersionsInitialized as isRoadmapVersionsInitializedSelector,
  getAllRoadmapVersionsProjects as getAllRoadmapVersionsProjectsSelector,
  getSelectedRoadmapVersion as getSelectedRoadmapVersionSelector,
  getFetchMultipleRoadmapVersionsProjectIsLoading,
  isRoadmapVersionsLoading as isRoadmapVersionsLoadingSelector,
  getHasPendingChanges,
  isRecentlyCloned,
  getAllRoadmapVersions,
  getSelectedRoadmapVersionsIdsToCompare,
  getCompareVersionEditScenario,
} from 'store/roadmapVersions/selectors';
import { selectOrganization as getOrganizationSelector } from 'store/organization/selectors';

import useNavigationToPathWithGoBackUrl from 'hooks/useNavigationToPathWithGoBackUrl';
import { getLogin } from 'store/login/selectors';

const exist = Boolean;

const useRoadmapVersions = () => {
  const dispatch = useDispatch();

  const roadmapVersions = useSelector(state => getAvailableRoadmapVersionsSelector(state));
  const allRoadmapVersions = useSelector(state => getAllRoadmapVersions(state));
  const isRoadmapVersionsLoaded = useSelector(state => isRoadmapVersionsLoadedSelector(state));
  const isRoadmapVersionsLoading = useSelector(isRoadmapVersionsLoadingSelector);
  const isRoadmapVersionsInitialized = useSelector(state => isRoadmapVersionsInitializedSelector(state));
  const roadmapVersionsProjects = useSelector(state => getAllRoadmapVersionsProjectsSelector(state));
  const hasAnyUpdateToBeSaved = useSelector(getHasPendingChanges);
  const selectedRoadmapVersion = useSelector(getSelectedRoadmapVersionSelector);
  const compareVersionEditScenario = useSelector(getCompareVersionEditScenario);
  const selectedRoadmapVersionIds = useSelector(getSelectedRoadmapVersionsIdsToCompare);
  const isRoadmapVersionsEnabled = useSelector(state => getOrganizationSelector(state).has_roadmap_versions_enabled);
  const isFetchMultipleRoadmapVersionsProjectsLoading = useSelector(getFetchMultipleRoadmapVersionsProjectIsLoading);
  const isRoadmapVersionSelected = not(isNil(selectedRoadmapVersion));
  const { isAuthenticated } = useSelector(getLogin);
  const { navigateToWithGoBackUrl } = useNavigationToPathWithGoBackUrl();
  const isVersionCloned = useSelector(isRecentlyCloned);

  const fetchRoadmapVersions = useCallback(() => dispatch(fetchAvailableRoadmapVersionsAction()), []);

  const fetchMultipleRoadmapVersionsProjects = useCallback(
    (baseCompareVersionId, roadmapVersionsIds, filters, resetData = false) =>
      dispatch(fetchMultipleRoadmapVersionProjects(baseCompareVersionId, roadmapVersionsIds, filters, { resetData })),
    [],
  );

  const openCompareSelectedRoadmapVersion = useCallback(() => {
    if (!selectedRoadmapVersion) {
      navigateToWithGoBackUrl('/compare-scenarios');

      return;
    }

    dispatch(setRoadmapVersionSelected(null));

    const params = new URLSearchParams({
      selectedVersion: selectedRoadmapVersion.id,
    });

    navigateToWithGoBackUrl(`/compare-scenarios?${params.toString()}`);
  }, [selectedRoadmapVersion, navigateToWithGoBackUrl]);

  const ganttCloneCommittedUnchanged = selectedRoadmapVersion && isVersionCloned && !hasAnyUpdateToBeSaved;

  const isRoadmapVersionCommitted = exist(selectedRoadmapVersion?.committed);

  useEffect(() => {
    if (not(isAuthenticated)) return;
    if (not(isRoadmapVersionsLoaded) && not(isRoadmapVersionsLoading)) fetchRoadmapVersions();
  }, [isRoadmapVersionsLoaded, isRoadmapVersionsLoading, isAuthenticated]);

  return {
    isRoadmapVersionsEnabled,
    selectedRoadmapVersion,
    isRoadmapVersionSelected,
    roadmapVersions,
    allRoadmapVersions,
    isRoadmapVersionsLoaded,
    isRoadmapVersionsInitialized,
    roadmapVersionsProjects,
    fetchRoadmapVersions,
    fetchMultipleRoadmapVersionsProjects,
    isFetchMultipleRoadmapVersionsProjectsLoading,
    openCompareSelectedRoadmapVersion,
    hasAnyUpdateToBeSaved,
    isRoadmapVersionCommitted,
    isRoadmapVersionCommittedWithGanttFlow: isRoadmapVersionCommitted || ganttCloneCommittedUnchanged,
    selectedRoadmapVersionIds,
    compareVersionEditScenario,
  };
};

export default useRoadmapVersions;
