import { createSelector } from 'reselect';
import { defaultTo } from 'ramda';

import { isLoading } from 'utils/store/thunk';
import { selectMetadata } from 'store/projects/metadataSelectors';
import { getEnrichedProject } from 'store/projects/helpers/enrichProjects';

import { parentsAdapter, projectsAdapter } from './helpers/adapters';
import { FETCH_TIMELINE_LIGHTBOX_PROJECTS } from './types';

const getState = state => state?.timelineLightbox;

const getOperations = state => {
  return state.timelineLightbox.operations;
};

const defaultToProjectsInitialState = defaultTo(projectsAdapter.getInitialState());

export const projectsSelector = projectsAdapter.getSelectors(state => {
  return defaultToProjectsInitialState(state.projects);
});

const parentProjectsSelector = parentsAdapter.getSelectors(state => {
  return defaultTo(parentsAdapter.getInitialState(), state.parents);
});

export const selectProjectForTimelineLightbox = createSelector(
  state => selectMetadata(state),
  state => projectsSelector.selectAll(getState(state)),
  state => parentProjectsSelector.selectEntities(getState(state)),
  state => getState(state).parents || [],
  (metadata, projects, parents) => {
    const childrenByParentId = projects.reduce((carry, project) => {
      return {
        ...carry,
        [project.id]: projects.filter(p => p.parent_id === project.id),
      };
    }, {});

    return projects.map(project => {
      const parentProject = parents[project.parent_id];

      if (!parentProject) {
        return getEnrichedProject(project, metadata, childrenByParentId);
      }

      return {
        ...getEnrichedProject(project, metadata, childrenByParentId),
        parent: parentProject.title,
        parentLayer: parentProject.layer,
      };
    });
  },
);

export const isGettingTimelineLightboxProjects = createSelector(getOperations, state =>
  isLoading(state, FETCH_TIMELINE_LIGHTBOX_PROJECTS),
);

export const selectTotalByLayer = createSelector(getState, state => state.totalByLayer);
