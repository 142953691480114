import { convertEstimateToCalendarWeeks, estimateRounder } from './estimates/unitConversion';

function calcBaseEstimate(duration, numStaff) {
  if (parseFloat(numStaff) >= 0) {
    return convertEstimateToCalendarWeeks(duration, numStaff);
  }

  return convertEstimateToCalendarWeeks(duration, 1);
}

/**
 * Calculates and returns an estimated value based on the given duration, number of staff, and current value.
 *
 * @param {number} duration - The duration for which the estimation is being calculated.
 * @param {number} numStaff - The number of staff members involved. Should be a non-negative number.
 * @param {Object} curValue - The current value object. If provided, the estimated value will be added to its value property.
 * @param {number} curValue.value - The current value to which the estimated value will be added.
 * @returns {number} The estimated value based on the given parameters.
 */
export default (duration, numStaff, curValue) => {
  const baseEstimate = calcBaseEstimate(duration, numStaff);

  if (curValue) {
    return estimateRounder.rounder(curValue.value + baseEstimate);
  }

  return baseEstimate;
};
