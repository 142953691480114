import downloadPNG from 'utils/downloadPNG';

const VISIBLE_ELEMENT = 'visible';
const AUTO_SCROLL_ELEMENT = 'auto';
const HEIGHT_FULL = '100%';

export default async () => {
  // we need to set all content visible to download the image
  const onBeforeDownload = () => {
    const element = document.getElementById('summary-board-container');
    const elementStyle = element.style;

    const firstParent = element.parentNode;
    const firstParentStyle = firstParent.style;

    const secondParent = firstParent.parentNode.parentNode;
    const secondParentStyle = secondParent.style;

    elementStyle.overflow = VISIBLE_ELEMENT;
    elementStyle.maxHeight = HEIGHT_FULL;

    firstParentStyle.overflow = VISIBLE_ELEMENT;
    firstParentStyle.maxHeight = HEIGHT_FULL;

    secondParentStyle.overflow = VISIBLE_ELEMENT;
  };

  // then we should reset the styles after download
  const onAfterDownload = () => {
    const element = document.getElementById('summary-board-container');
    const elementStyle = element.style;

    const firstParent = element.parentNode;
    const firstParentStyle = element.parentNode.style;

    const secondParent = firstParent.parentNode.parentNode;
    const secondParentStyle = secondParent.style;

    elementStyle.overflow = AUTO_SCROLL_ELEMENT;
    elementStyle.maxHeight = 'calc(100vh - 128px)';

    firstParentStyle.overflow = AUTO_SCROLL_ELEMENT;
    firstParentStyle.maxHeight = 'calc(100vh - 68px)';

    secondParentStyle.overflow = AUTO_SCROLL_ELEMENT;
  };

  await downloadPNG(onBeforeDownload, onAfterDownload);
};
