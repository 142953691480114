import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { SCENARIO_GANTT_PAGE, SCENARIO_LIST_PAGE, SCENARIO_SUMMARY_PAGE, SCENARIO_SWIMLANE_PAGE } from 'constants/filters';
import { SCENARIO_AI_PLANNER_PAGE } from 'constants/filters/pages';

import useNavigationToPathWithGoBackUrl from './useNavigationToPathWithGoBackUrl';

const SCENARIO_PATH = '/scenario';

export const MAP_SCENARIO_PATH_PAGES = {
  [SCENARIO_LIST_PAGE]: '/scenario/list',
  [SCENARIO_SWIMLANE_PAGE]: '/scenario/swimlane',
  [SCENARIO_GANTT_PAGE]: '/scenario/gantt',
  [SCENARIO_SUMMARY_PAGE]: '/scenario/summary',
  [SCENARIO_AI_PLANNER_PAGE]: '/scenario/aiplanner',
};
const useScenariosNavigation = () => {
  const currentUser = useSelector(state => state.login.currentUser);

  const { navigateToWithGoBackUrl, navigateBack } = useNavigationToPathWithGoBackUrl();

  const navigateToScenarioModule = useCallback(
    (goToPage = SCENARIO_LIST_PAGE, scenarioQueryParam = '') => {
      let navigateToPath = MAP_SCENARIO_PATH_PAGES[goToPage] ? MAP_SCENARIO_PATH_PAGES[goToPage] : SCENARIO_PATH;

      if (scenarioQueryParam.length) navigateToPath = `${navigateToPath}?${scenarioQueryParam}`;

      navigateToWithGoBackUrl(navigateToPath);
    },
    [currentUser],
  );

  const exitScenarioModule = () => {
    navigateBack();
  };

  return { navigateToScenarioModule, exitScenarioModule };
};

export default useScenariosNavigation;
