import useQueryParam from 'hooks/useQueryParam';
import { addQueryParamToUrl, removeQueryParamFromUrl } from 'utils/queryParamsUtils';

const EDIT_FILTER_QUERY_PARAM = 'editFilter';

/* now that the view is properly being invalidated and loaded once the filters change the header will re-mount.
We need to have a mechanism to handle the edit state of filters after the remount. This approach will user a query param
to know that is currently editing and opens the filters list automatically
 */
const useEditFilterParams = ({
  setEditingFilterName,
  setMultiFilterAnchorEl,
  multiFilterList,
  setFilterListAnchorEl,
  filterIconRef,
  multiFilterActive,
  onFilterListClick,
}) => {
  const closePopups = () => {
    setEditingFilterName('');
    setMultiFilterAnchorEl(null);
  };
  const stopEditingFilter = () => {
    removeQueryParamFromUrl(EDIT_FILTER_QUERY_PARAM);
  };

  const startEditFilter = item => {
    if (multiFilterActive !== item.id) {
      onFilterListClick(item);
    }
    addQueryParamToUrl(EDIT_FILTER_QUERY_PARAM, item?.id);
  };

  const handleEditParamChange = param => {
    if (!param) {
      closePopups();
      return;
    }
    const filterToEdit = multiFilterList.find(filter => filter?.id === +param);

    if (!filterToEdit) {
      closePopups();
      return;
    }

    setEditingFilterName(filterToEdit?.label);
    setFilterListAnchorEl(null);
    setMultiFilterAnchorEl(filterIconRef.current);
  };

  useQueryParam(EDIT_FILTER_QUERY_PARAM, handleEditParamChange);

  return { stopEditingFilter, startEditFilter };
};

export default useEditFilterParams;
