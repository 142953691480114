export const OBJECTIVES = 'objectives';
export const IDEAS = 'ideas';
export const ROADMAPS = 'roadmaps';
export const PHASES = 'phases';
export const TEAMS = 'teams';
export const THEMES = 'themes';
export const USERS = 'users';
export const CATEGORIES = 'categories';
export const TIMEFRAMES = 'timeframes';
export const SKILLS = 'skills';
export const CUSTOM_FIELDS = 'customFields';
export const PORTAL_SETTINGS = 'portalSettings';
export const INITIATIVES = 'products';
export const KEY_RESULTS = 'keyResults';
export const TAGS = 'tags';
export const CUSTOMERS = 'customers';
export const ESTIMATES = 'estimates';
export const METRICS = 'metrics';
export const TOKENS = 'apiTokens';
export const PRIORITIES = 'priorities';
export const CUSTOMER_REQUESTS = 'customerRequests';
export const CUSTOM_USER_FIELDS = 'customUserFields';
export const GOAL_MODE = 'goalMode';
export const ERROR_LOGS = 'errorLogs';
export const PDLC = 'pdlc';
export const METRIC_MODE = 'metricMode';
export const COMPARE_ROADMAP_VERSIONS = 'compareRoadmapVersions';
export const SNAPSHOT = 'snapshot';
