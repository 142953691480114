import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import { INTEGRATIONS_NAMES } from 'constants/integrations';

const FieldsMappingDisplay = ({ integrationType, mapping, integrationName }) => {
  const mappingWithUpdatesEnabled = useMemo(
    () =>
      mapping.filter(
        mappingEntry => !!mappingEntry.updateFromIntegrationToDragonboat || !!mappingEntry.updateFromDragonboatToIntegration,
      ),
    [mapping],
  );

  const integrationNameLabel = integrationName || INTEGRATIONS_NAMES[integrationType];

  return (
    <Wrapper>
      <Grid container style={{ margin: '14px 0' }}>
        <Grid item xs={4}>
          <TextDeprecated variant="bold" size="medium">
            Dragonboat Field
          </TextDeprecated>
        </Grid>
        <Grid item xs={5}>
          <TextDeprecated variant="bold" size="medium">
            {integrationNameLabel} Field
          </TextDeprecated>
        </Grid>
      </Grid>
      {mappingWithUpdatesEnabled.map(currentField => (
        <Grid container key={currentField.key} style={{ marginBottom: '6px' }}>
          <Grid item xs={3}>
            <TextDeprecated>{currentField.title}</TextDeprecated>
          </Grid>
          <Grid item xs={1}>
            <ArrowsText>
              {currentField.updateFromIntegrationToDragonboat && '←'} <br />{' '}
              {currentField.updateFromDragonboatToIntegration && '→'}
            </ArrowsText>
          </Grid>
          <Grid item xs={5}>
            <TextDeprecated>{currentField.integration?.title}</TextDeprecated>
          </Grid>
        </Grid>
      ))}
    </Wrapper>
  );
};

FieldsMappingDisplay.propTypes = {
  integrationType: PropTypes.string.isRequired,
  mapping: PropTypes.array.isRequired,
};

const Wrapper = styled.div`
  margin: 20px 0;
`;

const ArrowsText = styled(TextDeprecated)`
  &&&& {
    line-height: 8px;
    display: inline-flex;
    align-items: center;
    height: 20px;
  }
`;

export default FieldsMappingDisplay;
