import { numberComparator } from 'utils/agGridComparators';

import { NumericCellRenderer } from '../cellRenderers';
import { NumericCellEditor } from '../cellEditors';

const targetAllocationPercentageColumnDef = {
  field: 'target_allocation_percentage',
  headerName: 'Target Allocation %',
  cellRenderer: NumericCellRenderer,
  cellEditor: NumericCellEditor,
  comparator: numberComparator,
  width: 150,
  minWidth: 100,
  headerClass: 'ag-header-cell-text-centered',
  cellClass: 'text-center',
  suffix: '%',
};

export default targetAllocationPercentageColumnDef;
