import reduceReducers from 'reduce-reducers';
import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import { UPDATE_METADATA_POPOVER_OPEN_STATE } from './types';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([]);

const metadataDrawerInitialState = {
  OBJECTIVE_METADATA_POPOVER: {
    isPopoverOpen: false,
  },
  PROJECT_METADATA_POPOVER: {
    isPopoverOpen: false,
  },
};

const initialState = {
  ...metadataDrawerInitialState,
  ...thunksInitialState,
};

const metadataDrawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_METADATA_POPOVER_OPEN_STATE:
      const { isPopoverOpen, metadataSelected, popoverType } = action.payload;

      if (!popoverType) return state;

      return {
        ...state,
        [popoverType]: {
          isPopoverOpen,
          metadataSelected,
        },
      };
    default:
      return state;
  }
};

const reducer = reduceReducers(initialState, metadataDrawerReducer, ...thunksReducers);

export default reducer;
