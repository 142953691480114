import { useMemo } from 'react';
import theme from '../theme';

// we should revisit this hook. Has several issues with the calculation that are not visible.
// some fixes were made on the following util for canvas dragonboat-client/src/utils/metrics/calculateMetricProgress.js
export default ({ actualValue, actualValueLabel, minValue, minValueLabel, maxValue, maxValueLabel, formatValues, color }) => {
  const convertStringToNumber = number => {
    number = number.replaceAll('.', ',');
    return Number(number);
  };

  const formatNumberWithCommas = x => {
    if (!x && x !== 0) return '';

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const _getBarColor = value => {
    if (value < 50) {
      return theme.palette.newLayout.progressBarSquared.background.red;
    } else if (value < 80) {
      return theme.palette.newLayout.progressBarSquared.background.orange;
    }
    return theme.palette.newLayout.progressBarSquared.background.green;
  };

  return useMemo(() => {
    let percentageValue = actualValue;
    let formattedActualValue = actualValue;
    let formattedMinValue = minValue;
    let formattedMaxValue = maxValue;

    if (formatValues) {
      if (typeof formattedActualValue === 'string') formattedActualValue = convertStringToNumber(formattedActualValue);
      if (typeof formattedMinValue === 'string') formattedMinValue = convertStringToNumber(formattedMinValue);
      if (typeof formattedMaxValue === 'string') formattedMaxValue = convertStringToNumber(formattedMaxValue);

      if (formattedActualValue > formattedMaxValue) {
        formattedMaxValue = formattedActualValue;
      }

      const rangePercentage = maxValue - formattedMinValue;
      const value = formattedActualValue - formattedMinValue;

      percentageValue = (value * 100) / rangePercentage;
    }

    const barColor = color ?? _getBarColor(percentageValue);

    return {
      percentageValue,
      formattedActualValue: formatNumberWithCommas(actualValueLabel) || formatNumberWithCommas(formattedActualValue),
      formattedMinValue: formatNumberWithCommas(minValueLabel) || formatNumberWithCommas(formattedMinValue),
      formattedMaxValue: formatNumberWithCommas(maxValueLabel) || formatNumberWithCommas(formattedMaxValue),
      color: barColor,
    };
  }, [actualValue, actualValueLabel, minValue, minValueLabel, maxValue, maxValueLabel, formatValues]);
};
