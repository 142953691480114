import React from 'react';
import styled from 'styled-components';

import Logo from './dragon.png';

const DEFAULT_SIZE = '40';

export default props => <Image src={Logo} alt="Dragonboat Logo" $width={props.width} $height={props.height} {...props} />;

const Image = styled.img`
  &&& {
    width: ${({ $width }) => $width || DEFAULT_SIZE}px;
    height: ${({ $height }) => $height || DEFAULT_SIZE}px;
  }
`;
