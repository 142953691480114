import reduceReducers from 'reduce-reducers';

import operationsReducers, { initialState as operationsInitialState } from './operationsReducer';
import snapshotReducer, { initialState as snapshotInitialState } from './reducer';

const initialState = {
  ...operationsInitialState,
  ...snapshotInitialState,
};

const reducer = reduceReducers(initialState, snapshotReducer, ...operationsReducers);

export { operationsInitialState, snapshotInitialState, initialState };

export default reducer;
