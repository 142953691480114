import { defaultTo } from 'ramda';

import { keyResultsAdapter, objectivesAdapter } from './adapters';
import { createSelector } from 'reselect';
import { _getEnrichedKeyResult, _getEnrichedObjective } from 'store/objectives/selectors';
import { getOrgMetadataForCorpLevelTitles } from 'store/utils/getMetadataCorpTitleBasedOnRoadmap';

const emptyArray = [];
const defaultToObjectivesInitialState = defaultTo(objectivesAdapter.getInitialState());
const defaultToKeyResultsInitialState = defaultTo(keyResultsAdapter.getInitialState());

const getState = state => state?.goalsGrid;

const objectivesAdapterSelector = objectivesAdapter.getSelectors(state => {
  return defaultToObjectivesInitialState(state.objectives);
});

const keyResultsAdapterSelector = keyResultsAdapter.getSelectors(state => {
  return defaultToKeyResultsInitialState(state.keyResults);
});

export const selectGoalsGridObjectives = createSelector(
  state => objectivesAdapterSelector.selectAll(getState(state)),
  state => state?.metrics?.metrics || emptyArray,
  state => state?.users?.rows || emptyArray,
  getOrgMetadataForCorpLevelTitles,
  (objectives, metrics, users, orgMetadataForCorpLevelTitle) =>
    objectives.map(objective => _getEnrichedObjective(objective, metrics, users, orgMetadataForCorpLevelTitle)),
);

export const selectGoalsGridKeyResults = createSelector(
  state => keyResultsAdapterSelector.selectAll(getState(state)),
  state => objectivesAdapterSelector.selectEntities(getState(state)),
  state => state?.metrics?.metrics || emptyArray,
  state => state?.users?.rows || emptyArray,
  (keyResults, normalizedObjectives, metrics, users) =>
    keyResults.map(keyResult => _getEnrichedKeyResult(keyResult, normalizedObjectives, metrics, users)),
);
