// External dependencies
import React from 'react';
import { useSelector } from 'react-redux';

import { getIsDodActive } from 'src/store/accessControl/selectors';

// Dragonboat imports
import { getProjectsCustomFields } from 'store/customFields/selectors';
import { getGridConfigValue } from 'store/grids/selectors';
import { getOrganization, getIdeasIntegrations } from 'store/organization/selectors';
import useSystemFields from 'hooks/useSystemFields';
import useGridShowFields from 'hooks/grid/useGridShowFields';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { getVisibleColumnsFromAgGridColumnState } from 'utils/grids/helpers';
import { FeatureFlags } from '@dragonboat/config';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';

import { getGridColumnsForDialog } from './helpers';

const defaultEnhanceVisibleColumns = visibleColumns => visibleColumns;

const componentHOC = Component => {
  return props => {
    const {
      viewType,
      getGridApi,
      showPdlcFields,
      showEstimatesFields,
      showAdditionalFields,
      showCustomFields,
      isOpen,
      onClose,
      enhanceOnChangeVisibleColumns = defaultEnhanceVisibleColumns,
      currentPageIsEstimates,
    } = props;
    const [getSystemFieldName] = useSystemFields();

    const grid = getGridApi && getGridApi();
    const columnApi = grid && grid.columnApi;
    const organization = useSelector(getOrganization);
    const integrations = useSelector(getIdeasIntegrations);
    const customFields = useSelector(getProjectsCustomFields);
    const isDodActive = useSelector(getIsDodActive);

    const { canView } = usePermissions();

    const hasMetadataMultiSelect = useFeatureFlags([FeatureFlags.HAS_METADATA_MULTI_SELECT]);
    const hasCompletedAllocation = useFeatureFlags([FeatureFlags.HAS_COMPLETED_ALLOCATION]);
    const gridState = useSelector(state => getGridConfigValue(state, viewType, 'gridState'));
    let gridColumnDefs = [];
    const hasExternalKey = canView(PERMISSION_FEATURES.importProjectsWithExternalKey);

    if (columnApi) {
      const allColumns = columnApi.getColumns();

      if (allColumns) {
        gridColumnDefs = allColumns.map(col => col.colDef);
      }
    }

    const gridColumns = getGridColumnsForDialog(
      gridColumnDefs,
      gridState,
      customFields,
      integrations,
      organization,
      isDodActive,
      getSystemFieldName,
      showPdlcFields,
      showEstimatesFields,
      showAdditionalFields,
      showCustomFields,
      hasMetadataMultiSelect,
      currentPageIsEstimates,
      hasCompletedAllocation,
      hasExternalKey,
    );

    const { handleChangeColumnsVisibility } = useGridShowFields(viewType, columnApi, enhanceOnChangeVisibleColumns);
    const { columnState } = gridState || {};

    const visibleColumnsOnGrid = getVisibleColumnsFromAgGridColumnState(columnState);

    return (
      <Component
        {...props}
        gridColumns={gridColumns}
        visibleColumnsOnGrid={visibleColumnsOnGrid}
        isOpen={isOpen}
        onClose={onClose}
        onChangeColumnVisibility={handleChangeColumnsVisibility}
      />
    );
  };
};

export default componentHOC;
