import { not, keys } from 'ramda';

/**
 * Remove invalid fields from the updated configuration fields based on the provided valid keys.
 * @param {Object} updatedConfigurationFieldsRequired - The updated configuration fields with required validation.
 * @param {string[]} allValidKeys - An array of valid keys.
 * @returns {Object} - A new object containing only the valid fields from the updated configuration fields.
 */
const cleanRequiredInvalidFields = (updatedConfigurationFieldsRequired, allValidKeys) =>
  keys(updatedConfigurationFieldsRequired).reduce((carry, fieldKey) => {
    const fieldKeyDoesNotExists = not(allValidKeys.includes(fieldKey));

    if (fieldKeyDoesNotExists) {
      return carry;
    }

    return {
      ...carry,
      [fieldKey]: updatedConfigurationFieldsRequired[fieldKey],
    };
  }, {});

export { cleanRequiredInvalidFields };
