export const selectedScenarioVersion = 'selectedVersion';
export const DIALOG = 'dialog';
export const OPEN_IDEA = 'openIdea';
export const OPEN_METRIC = 'openMetric';
export const OPEN_PROGRESS_FOR = 'openProgressFor';
export const METRIC_TAB_QUERY_PARAM = 'tab';
export const PROJECT_LIGHTBOX_TAB_QUERY_PARAM = 'projectTab';

// Advanced Search on Portfolio
export const OPEN_ADVANCED_SEARCH = 'openAdvancedSearch';
export const ADD_FILTER_TITLE = 'addFilterTitle';
