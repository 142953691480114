import styled from 'styled-components';

import { spacing } from 'design-system/theme';

const Wrapper = styled.span`
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: ${spacing()}px;
  margin-left: ${({ $indent }) => ($indent ? 25 : 0)}px;
`;

const TextWrapper = styled.span`
  word-break: break-word;
  white-space: break-spaces;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ $nOfTextRowsToDisplay = 0 }) => $nOfTextRowsToDisplay};
  -webkit-box-orient: vertical;
  flex: 1;
  gap: 4px;
  font-weight: ${({ theme, $bold }) => ($bold ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular)};
  ${({ $textColor }) => $textColor && `color: ${$textColor} !important;`}
  ${({ $fontSize }) => $fontSize && `font-size: ${$fontSize}px !important;`}
`;

export { Wrapper, TextWrapper };
