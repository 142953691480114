import reduceReducers from 'reduce-reducers';
import { defaultTo, pluck, prop } from 'ramda';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  DELETE_ERROR_LOGS,
  BULK_DELETE_ERROR_LOGS,
  BULK_DELETE_ERROR_LOGS_FULFILLED,
  FETCH_ERROR_LOGS,
  FETCH_ERROR_LOGS_FULFILLED,
} from './types';

const COUNT = 'count';
const ID = 'id';

const defaultToEmptyObject = defaultTo({});

const extractIds = pluck(ID);

const getCount = prop(COUNT);
const getId = prop(ID);

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  DELETE_ERROR_LOGS,
  BULK_DELETE_ERROR_LOGS,
  FETCH_ERROR_LOGS,
]);

const initialState = {
  allIds: [],
  byId: {},
  totalResultsCount: 0,
  ...thunksInitialState,
};

const errorLogsReducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case FETCH_ERROR_LOGS_FULFILLED: {
      const { data = [], _meta } = defaultToEmptyObject(payload?.data);

      return {
        ...state,
        totalResultsCount: getCount(_meta),
        allIds: extractIds(data),
        byId: data.reduce((currentById, errorLog) => {
          return { ...currentById, [getId(errorLog)]: errorLog };
        }, state.byId || {}),
      };
    }
    case BULK_DELETE_ERROR_LOGS_FULFILLED: {
      const { ids } = meta;

      return {
        ...state,
        allIds: state.allIds.filter(id => !ids.includes(id)),
        byId: Object.keys(state.byId).reduce((currentById, errorLogId) => {
          if (ids.includes(Number(errorLogId))) {
            return currentById;
          }

          return { ...currentById, [errorLogId]: state.byId[errorLogId] };
        }, {}),
      };
    }
    default:
      return state;
  }
};

export { initialState };

const reducer = reduceReducers(initialState, errorLogsReducer, ...thunksReducers);

export default reducer;
