import { useMemo } from 'react';

import useSystemFields from '../../../containers/IdeasList/IdeasList/New/hooks/useSystemFields';
import { SUPPORTED_FIELDS } from 'utils/roadmapVersions/supportedFieldsUtils';

const useRoadmapVersionsSupportedFields = () => {
  const { getSystemFieldName } = useSystemFields();

  const roadmapVersionsFields = useMemo(() => {
    return SUPPORTED_FIELDS.map(field => ({ ...field, title: getSystemFieldName(field.title, false) }));
  }, [getSystemFieldName]);

  return { roadmapVersionsFields };
};

export default useRoadmapVersionsSupportedFields;
