import { useMemo } from 'react';

import {
  IntegrationKeyCellRenderer,
  ParentCellRenderer,
  TextCellRenderer,
} from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { defaultColumnDefCommonProps } from 'design-system/molecules/AgGridReact-New/columns';

import useIntegrations from 'hooks/useIntegrations';

import { getUserName } from 'utils';
import formatDateTime from 'utils/dates/formatDateTime';
import { useSettingsGridColumns } from 'containers/Grids/SettingsGrid/hooks';

const useErrorLogsGridColumns = ({ allowActions, selectionMode } = {}) => {
  const { orgIntegrations } = useIntegrations();

  const settingsGridColumns = useSettingsGridColumns({ allowActions, selectionMode });

  const defaultColDef = useMemo(
    () => ({
      ...defaultColumnDefCommonProps,
      editable: false,
      sortable: false,
      resizable: true,
      filter: false,
    }),
    [],
  );

  const columnDefs = useMemo(() => {
    return [
      ...settingsGridColumns.beforeColumnDefs,
      {
        width: 120,
        field: 'app_name',
        headerName: 'Integrated App',
        suppressMovable: true,
        cellRenderer: TextCellRenderer,
      },
      {
        width: 240,
        field: 'failure',
        headerName: 'Failure',
        suppressMovable: true,
        cellRenderer: TextCellRenderer,
      },
      {
        width: 300,
        field: 'error_message',
        headerName: 'Error Message from the App',
        suppressMovable: true,
        cellRenderer: TextCellRenderer,
      },
      {
        width: 120,
        headerName: 'Integration Key',
        field: 'integration',
        suppressMovable: true,
        orgIntegrations,
        cellRenderer: IntegrationKeyCellRenderer,
      },
      {
        width: 240,
        headerName: 'Portfolio Item',
        field: 'project',
        suppressMovable: true,
        cellRenderer: ParentCellRenderer,
      },
      {
        width: 100,
        field: 'user',
        headerName: 'Updated By',
        suppressMovable: true,
        valueFormatter: ({ value }) => getUserName(value),
      },
      {
        width: 150,
        field: 'created_at',
        headerName: 'Timestamp',
        suppressMovable: true,
        valueFormatter: ({ value }) => formatDateTime(value),
      },
    ];
  }, [orgIntegrations, settingsGridColumns.beforeColumnDefs]);

  return {
    defaultColDef,
    columnDefs,
  };
};

export default useErrorLogsGridColumns;
