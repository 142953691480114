function getParentAndHisChildren(parent, tasks) {
  const children = tasks.filter(task => task.parent_task_id && task.parent_task_id === parent.id);

  return {
    ...parent,
    subtasks: children.map(child => getParentAndHisChildren(child, tasks)),
  };
}

export default tasks => {
  if (!tasks || !(tasks instanceof Array)) {
    throw new Error('Tasks must be in an array');
  }

  const tasksIds = tasks.map(task => task.id);
  const finalTasks = [];

  for (let i = 0; i < tasks.length; i++) {
    const actualTask = { ...tasks[i] };

    // if a task does not have a parent task or if his parent task does not exist in tasks
    // (which are the ones that must be in the first level of projects tasks)
    // then it is inserted with his children in finalTasks array
    if (!actualTask.parent_task_id || tasksIds.indexOf(actualTask.parent_task_id) < 0) {
      finalTasks.push(getParentAndHisChildren(actualTask, tasks));
    }
  }

  return finalTasks;
};
