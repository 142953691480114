import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FeatureFlags } from '@dragonboat/config';

import { getCustomerRequestsCustomFields } from 'store/customFields/selectors';

import useFeatureFlags from 'hooks/useFeatureFlags';

import {
  CLUSTER_BY_AUTO_TAGS_OPTION,
  CLUSTER_BY_CUSTOMER_SEGMENT_OPTION,
  CLUSTER_BY_CUSTOMER_TYPE_OPTION,
  CLUSTER_BY_CUSTOM_FIELDS_TYPES_ALLOWED,
  CLUSTER_BY_TAGS_OPTION,
} from '../utils';
import sortAlphaNumericallyByTitle from 'utils/sortAlphaNumericallyByTitle';

const CLUSTER_BY_OPTIONS = [CLUSTER_BY_TAGS_OPTION, CLUSTER_BY_CUSTOMER_SEGMENT_OPTION, CLUSTER_BY_CUSTOMER_TYPE_OPTION];

const useClustersByOptions = () => {
  const hasEnabledAutoTag = useFeatureFlags([FeatureFlags.HAS_CUSTOMER_REQUESTS_AUTO_TAG]);

  const customFields = useSelector(getCustomerRequestsCustomFields);

  const optionsCustomFields = customFields
    .filter(cf => CLUSTER_BY_CUSTOM_FIELDS_TYPES_ALLOWED.includes(cf.field_type))
    .map(cf => ({
      key: cf.key,
      title: cf.titleWithSuffix,
    }));

  const clusterByOptions = useMemo(() => {
    const options = [...CLUSTER_BY_OPTIONS, ...(hasEnabledAutoTag ? [CLUSTER_BY_AUTO_TAGS_OPTION] : []), ...optionsCustomFields];

    return sortAlphaNumericallyByTitle(options);
  }, [hasEnabledAutoTag, optionsCustomFields]);

  return { clusterByOptions };
};

export default useClustersByOptions;
