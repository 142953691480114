import { useCallback } from 'react';
import { not, defaultTo, isEmpty, is, equals } from 'ramda';
import moment from 'moment-timezone';

import useUpdateReleaseCapacityAllocationOnForecastList from 'hooks/forecast/useUpdateReleaseCapacityAllocationOnForecastList';
import { FORECAST_BY_HEADCOUNT, FORECAST_BY_TIME_CAPACITY } from 'constants/forecast';

import { getEstimateDurationAndStaffBasedOnForecastBy, getEstimateInfoByGridField } from '../helpers';
import { DEFAULT_STAFF_ON_CREATE_ESTIMATE } from 'constants/estimates';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';

const isString = is(String);
const defaultAsEmptyArray = defaultTo([]);
const defaultAsEmptyObject = defaultTo({});
const isNotNumber = value => Number.isNaN(Number(value));
const GROUP_KEY = 'group';
const isForecastByTimeCapacity = equals(FORECAST_BY_TIME_CAPACITY);
const isForecastByHeadcount = equals(FORECAST_BY_HEADCOUNT);

const parseValueFromInputObject = inputObject => {
  const { value } = defaultAsEmptyObject(inputObject);

  // if is empty should return original value
  if (isEmpty(value)) {
    return value;
  }

  // convert to Number
  return parseFloat(value);
};

/**
 * @function useUpdateEstimatesOnForecastList
 *
 * hook that contains the update event of each estimate cell, this parses and validate the value that comes from the ag grid cell
 *
 * @param  {Object}   options
 * @param  {Array}    options.teams
 * @param  {Array}    options.skills
 * @param  {Object}   options.forecastBy
 * @param  {Function} options.deleteProjectEstimate
 * @param  {Function} options.createProjectEstimate
 * @param  {Function} options.updateProjectEstimate
 * @return {Object}
 */
const useUpdateEstimatesOnForecastList = ({
  teams,
  skills,

  deleteProjectEstimate,
  updateProjectEstimate,
}) => {
  const { updateReleaseCapacityAllocation } = useUpdateReleaseCapacityAllocationOnForecastList();
  const { canUpdate } = usePermissions();
  const onEstimateChangeHandler = useCallback(
    (id, updatedData, previousValue, params) => {
      const {
        // newValue,
        colDef: { field: updatedField },
        data: row,
        context,
      } = params;

      const { forecastBy } = context;
      const newValue = updatedData[updatedField];

      const { teamId, skillId } = getEstimateInfoByGridField(updatedField);
      const value = parseValueFromInputObject(newValue);
      const isGroupRow = isString(id) && id.includes(GROUP_KEY);

      /*
       * this is a group update
       * for now is only use for release capacity allocation
       */
      if (isGroupRow && isForecastByTimeCapacity(forecastBy)) {
        return updateReleaseCapacityAllocation(row, { teamId, skillId, value });
      }

      const estimate = defaultAsEmptyArray(row?.estimates).find(est => est.team?.id === teamId && est.skill?.id === skillId);
      const isValueNotNumber = isNotNumber(value);
      const isValueEmpty = isEmpty(value);

      // Value is empty and estimates exists so will delete the current estimate object
      if (isValueEmpty && estimate) {
        return deleteProjectEstimate(id, estimate);
      }

      // Not a number value should not proceed
      if (isValueNotNumber) {
        return;
      }

      const team = teams.find(t => t.id === teamId);
      const skill = skills.find(d => d.id === skillId);
      const isNew = not(estimate);

      const canUpdatePointsEstimation = canUpdate(PERMISSION_FEATURES.projectEstimateByPoints, {
        project: row,
        team,
      });
      const isNotForecastByHeadcount = !isForecastByHeadcount(forecastBy);
      const isUpdatingEstimateByPoints = canUpdatePointsEstimation && isNotForecastByHeadcount;

      if (isUpdatingEstimateByPoints) {
        const storyPoints = value;

        if (isNew) {
          const startDate = row.estimated_start_date || moment().formatAsDate();

          const newEstimate = {
            numStaff: DEFAULT_STAFF_ON_CREATE_ESTIMATE,
            parent: row.id,
            skill_id: skillId,
            team_id: teamId,
            team,
            skill,
            start_date: startDate,
            storyPoints,
          };

          return updateProjectEstimate(row.id, teamId, skillId, newEstimate);
        }

        const update = {
          ...estimate,
          team,
          skill,
          storyPoints,
        };

        return updateProjectEstimate(row.id, teamId, skillId, update);
      }

      const { duration, numStaff } = getEstimateDurationAndStaffBasedOnForecastBy(value, forecastBy, estimate);

      if (isNew) {
        const startDate = row.estimated_start_date || moment().formatAsDate();

        const newEstimate = {
          duration,
          numStaff,
          parent: row.id,
          skill_id: skillId,
          team_id: teamId,
          team,
          skill,
          start_date: startDate,
          durationWeeks: duration,
        };

        return updateProjectEstimate(row.id, teamId, skillId, newEstimate);
      }

      const update = {
        ...estimate,
        duration,
        numStaff,
        team,
        skill,
      };

      return updateProjectEstimate(row.id, teamId, skillId, update);
    },
    [teams, skills, deleteProjectEstimate, updateProjectEstimate, updateReleaseCapacityAllocation, canUpdate],
  );

  return {
    onEstimateChangeHandler,
  };
};

export default useUpdateEstimatesOnForecastList;
