import { getThunkActionTypes } from 'utils/store/thunk';

export const ADD_ORG_INTEGRATION = 'ADD_ORG_INTEGRATION';

export const UPDATE_ORG_INTEGRATION = 'UPDATE_ORG_INTEGRATION';

export const REMOVE_ONE_STEP_ORG_INTEGRATION = 'REMOVE_ONE_STEP_ORG_INTEGRATION';

export const ADD_USER_INTEGRATION = 'ADD_USER_INTEGRATION';

export const ENABLE_INTEGRATION_WEBHOOKS = 'ENABLE_INTEGRATION_WEBHOOKS';

export const DISABLE_INTEGRATION_WEBHOOKS = 'DISABLE_INTEGRATION_WEBHOOKS';

export const RESTORE_ORG_INTEGRATION_DATA = 'RESTORE_ORG_INTEGRATION_DATA';

export const UPDATE_ORG_INTEGRATION_FIELD_MAPPING = 'UPDATE_ORG_INTEGRATION_FIELD_MAPPING';
export const UPDATE_ORG_INTEGRATION_FIELD_MAPPING_FULFILLED = 'UPDATE_ORG_INTEGRATION_FIELD_MAPPING_FULFILLED';

export const FETCH_AVAILABLE_INTEGRATION_FIELDS = 'FETCH_AVAILABLE_INTEGRATION_FIELDS';

export const FETCH_ORG_INTEGRATION_FIELD_MAPPING = 'FETCH_ORG_INTEGRATION_FIELD_MAPPING';
export const FETCH_ORG_INTEGRATION_FIELD_MAPPING_FULFILLED = 'FETCH_ORG_INTEGRATION_FIELD_MAPPING_FULFILLED';

export const FETCH_AVAILABLE_INTEGRATIONS = 'FETCH_AVAILABLE_INTEGRATIONS';
export const FETCH_AVAILABLE_INTEGRATIONS_FULFILLED = 'FETCH_AVAILABLE_INTEGRATIONS_FULFILLED';

export const FETCH_ORG_INTEGRATION_DRAGONBOAT_FIELDS = 'FETCH_ORG_INTEGRATION_DRAGONBOAT_FIELDS';

export const ONE_STEP_OAUTH2 = 'ONE_STEP_OAUTH2';

const actionTypes = {
  ...getThunkActionTypes(REMOVE_ONE_STEP_ORG_INTEGRATION),
  ...getThunkActionTypes(FETCH_ORG_INTEGRATION_DRAGONBOAT_FIELDS),
};

export const {
  REMOVE_ONE_STEP_ORG_INTEGRATION_PENDING,
  REMOVE_ONE_STEP_ORG_INTEGRATION_FULFILLED,
  REMOVE_ONE_STEP_ORG_INTEGRATION_REJECTED,
  FETCH_ORG_INTEGRATION_DRAGONBOAT_FIELDS_FULFILLED,
} = actionTypes;
