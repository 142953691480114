import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import isNumber from 'lodash/isNumber';
import isNil from 'lodash/isNil';
import IconButton from '@material-ui/core/IconButton';

import renderFormattedNumberText from 'design-system/utils/renderFormattedNumberText';

import { isGroupRow } from '../helpers';

const NumericCellRenderer = params => {
  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();

    if (params.data?.id && isNumber(params.data.id)) {
      params.colDef.onLinkClick(params.data.id);
    }
  };

  const value = params?.colDef?.getDisplayValue?.(params) || params.value;
  const hasLink = !!params.colDef.onLinkClick;
  const withoutZeros = !!params.colDef?.cellRendererParams?.withoutZeros;
  const withBubble = !!params.colDef?.cellRendererParams?.withBubble;
  const isZeroToRemove = withoutZeros && value === 0;

  const numberFormatComponent = (
    <NumberFormat
      value={value}
      displayType="text"
      thousandSeparator
      renderText={value => <StyledP hasLink={hasLink}>{renderFormattedNumberText(value)}</StyledP>}
      decimalScale={params.colDef.decimalScale ?? 2}
      suffix={params.colDef.suffix}
    />
  );

  const numberComponent = withBubble ? <Bubble>{numberFormatComponent}</Bubble> : numberFormatComponent;

  const isGroup = isGroupRow(params);

  return (
    <Wrapper className={+value < 0 ? 'red' : ''} hasLink={hasLink} isGroup={isGroup}>
      {isNil(value) || isZeroToRemove ? <span /> : numberComponent}
      {params.data?.id && isNumber(params.data.id) && hasLink && <OpenLink onClick={onClick}>{params.colDef.linkIcon}</OpenLink>}
    </Wrapper>
  );
};

export default NumericCellRenderer;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ hasLink }) => (hasLink ? 'space-between' : 'center')};

  ${({ hasLink, isGroup }) =>
    hasLink &&
    `
    display: inline-flex;    

    p {
      ${!isGroup && `padding-left: 20px;`} // this is needed for the content be centered with header title
    }
  `}
`;

const OpenLink = styled(props => <IconButton {...props}>{props.children}</IconButton>)`
  &&&& {
    position: relative;
    visibility: hidden;
    padding: 3px;
    font-size: 1.125rem;
    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

const StyledP = styled.p`
  ${({ hasLink }) =>
    hasLink &&
    `
    flex: 1 0;
  `}
`;

const Bubble = styled.span`
  color: white;
  font-weight: bold;
  font-size: 11px;
  line-height: 22px;
  border-radius: 6px;
  border: 1px solid #00c8dd;
  width: auto !important;
  padding: 0 8px;
`;
