import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectIsLoadingSnapshotsByUserView,
  selectRoadmapHistorySnapshotsByUserView,
  selectViewRoadmapHistorySnapsDialogProperties,
} from 'features/RoadmapHistory/store/selectors';
import { closeViewHistorySnapsDialog, openViewHistorySnapsDialog } from 'features/RoadmapHistory/store/actions';
import { deleteRoadmapHistorySnap, updateRoadmapHistorySnapshot } from 'features/RoadmapHistory/store/thunks';
import useNavigationToPathWithGoBackUrl from 'hooks/useNavigationToPathWithGoBackUrl';
import { pluck } from 'ramda';
import { COMPARE_SNAPS_SELECTED_SNAPS_QUERY_PARAM, COMPARE_SNAPS_VIEW_QUERY_PARAM } from 'features/RoadmapHistory/constants';

const useViewRoadmapHistorySnapsDialog = () => {
  const dispatch = useDispatch();

  const { navigateToWithGoBackUrl } = useNavigationToPathWithGoBackUrl();

  const isLoadingRoadmapHistorySnapshots = useSelector(selectIsLoadingSnapshotsByUserView);

  const roadmapHistorySnapshotsByUserView = useSelector(selectRoadmapHistorySnapshotsByUserView);

  const { open: isDialogOpen, selectedUserViewId } = useSelector(selectViewRoadmapHistorySnapsDialogProperties);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedSnapsForComparison, setSelectedSnapsForComparison] = useState([]);

  const historySnapsList = useMemo(() => {
    const historySnapshots = roadmapHistorySnapshotsByUserView[selectedUserViewId] || [];

    if (searchQuery) {
      return historySnapshots.filter(snapshot => snapshot.name.toLowerCase().includes(searchQuery));
    }

    return historySnapshots;
  }, [roadmapHistorySnapshotsByUserView, selectedUserViewId, searchQuery]);

  const onClickOpenCompareRoadmapHistorySnap = () => {
    const selectedViewQueryParam = `${COMPARE_SNAPS_VIEW_QUERY_PARAM}=${selectedUserViewId}`;

    const snapIdsQueryParam = selectedSnapsForComparison.join();
    const selectedSnapsForComparisonQueryParam = `${COMPARE_SNAPS_SELECTED_SNAPS_QUERY_PARAM}=${snapIdsQueryParam}`;

    navigateToWithGoBackUrl(`/compare-snaps?${selectedViewQueryParam}&${selectedSnapsForComparisonQueryParam}`);

    dispatch(closeViewHistorySnapsDialog());
  };

  const onEditRoadmapHistorySnap = (id, fieldsToUpdate) => {
    dispatch(updateRoadmapHistorySnapshot(id, fieldsToUpdate));
  };

  const openViewRoadmapHistorySnapsDialog = selectedUserViewId => {
    dispatch(openViewHistorySnapsDialog(selectedUserViewId));
  };

  const closeViewRoadmapHistorySnapsDialog = () => {
    setSelectedSnapsForComparison([]);
    dispatch(closeViewHistorySnapsDialog());
  };

  const onDeleteRoadmapHistorySnap = (snapId, viewId) => {
    dispatch(deleteRoadmapHistorySnap(snapId, { viewId }));
  };

  const onSnapsSelectionChanged = ({ api: gridApi }) => {
    const selectedRows = gridApi.getSelectedRows() || [];

    const selectedSnapIds = pluck('id', selectedRows);

    setSelectedSnapsForComparison(selectedSnapIds);
  };

  return {
    searchQuery,
    historySnapsList,
    isDialogOpen,
    isLoading: isLoadingRoadmapHistorySnapshots,
    selectedSnapsForComparison,

    setSearchQuery,
    openViewRoadmapHistorySnapsDialog,
    closeViewRoadmapHistorySnapsDialog,
    onClickOpenCompareRoadmapHistorySnap,
    onEditRoadmapHistorySnap,
    onSnapsSelectionChanged,
    onDeleteRoadmapHistorySnap,
  };
};

export default useViewRoadmapHistorySnapsDialog;
