// import legacyContainer from './Legacy/container';
// import legacyComponent from './Legacy/Component';

import newContainer from './New/container';
import newComponent from './New/Component';

// const shouldUseNewVersion = localStorage.getItem('newPhasesSettingsTable');

// const component = shouldUseNewVersion ? newContainer(newComponent) : legacyContainer(legacyComponent);

// export default component;
export default newContainer(newComponent);
