import React, { useEffect, useMemo, useRef } from 'react';
import { prop, or, equals } from 'ramda';
import styled, { css } from 'styled-components';

import getSystemFieldName from 'utils/getSystemFieldName';
import { ACTIVE_STATUS, PAGE_SIDE_MARGIN, PAGE_HEADER_HEIGHT } from 'constants/common';
import getDisplayLayerLabel from 'store/projects/helpers/getDisplayLayerLabel';
import useMetricViewSnapshot from './hooks/useMetricViewSnapshot';

import BaseLayout from 'design-system/organisms/BaseLayout/index';
import MultiSelectDropdown2ColumnsPopover from 'design-system/organisms/MultiSelectDropdown2ColumnsPopover/index';

import { spacing } from 'design-system/theme';
import SnapshotItem from './SnapshotItem';
import SnapshotMetricItem from './SnapshotMetricItem';
import SnapshotTable from './SnapshotTable';
import SnapshotControls from './SnapshotControls';
import useGoalTableColumnOptions from 'hooks/objectives/useGoalTableColumnOptions';
import { ProjectsListLightboxProvider } from 'hooks/useProjectsListLightbox';
import { getGapForCardsContainer } from './helpers';
import SnapshotGoalItem from 'routes/Dashboard/Snapshot/SnapshotGoalItem';
import { InView } from 'react-intersection-observer';

const isOKR = value => ['objectiveCorp', 'objective', 'keyResult1', 'keyResult2'].includes(value);
const isActive = item => equals(prop('status', item), ACTIVE_STATUS);

const DEFAULT_CARDS_PER_ROW = 2;

const TOOLBAR_HEIGHT = 56;
const PAGE_HEIGHT_OFFSET = PAGE_HEADER_HEIGHT + TOOLBAR_HEIGHT;

const DEFAULT_VISIBLE_CARD_ELEMENTS = {
  metrics: true,
  portfolioProgress: true,
  portfolioItems: true,
  ownerAvatar: true,
};

const MIN_CARD_WITH = 400;

export default props => {
  const {
    shouldRenderControls,
    openObjectiveDrawer,
    addKeyResultWithoutSave,
    onUpdateMetadataFieldById,
    entities,
    groupedBy,
    tableShowFieldsEl,
    setTableShowFieldsEl,
    hasKeyResults,
    hasKeyResults2,
    tableEntities,
    menuIsClosed,
    treeData,
    onUpdateGridConfig,
    rowHeight,
    isOKRLastLevel,
    onUpdatePageSize,
    showSnapshotTreeView,
    snapshotTableVisibleFields,
    showSnapshotTable,
    expandTableItems,
    selectedSnapshotField,
    hideEmptyCards,
    hideCards,
    pageSize,
    currentUser,
    hasAdvancedMetricReporting,
    handleOpenMetricView,
    createMetricOption,
    gridState,
    saveGridState,
    hideExpandMore = false,
    isGoalMode = false,
    cardsPerRow = DEFAULT_CARDS_PER_ROW,
    visibleCardElements = DEFAULT_VISIBLE_CARD_ELEMENTS,
    onSetPageUserView,
  } = props;

  const {
    hasPermissionForMetricMode,
    hasProjectMetrics,
    areClustersVisible,
    shouldRenderMetricMode,
    canViewMetricModeToggle,
    onToggleMetricViewMode,
    onToggleClustersVisibility,
    associatedMetrics,
    startDate,
    endDate,
    handleDateRangeChange,
  } = useMetricViewSnapshot(selectedSnapshotField, entities, isGoalMode);

  const isAnonymousUser = currentUser.is_anonymous;
  const shouldShowBranchItem = branchItem => or(isGoalMode, isActive(branchItem));

  useEffect(() => {
    if (expandTableItems) {
      props.updateState({ expandTableItems: false }, false);
    }
  }, [selectedSnapshotField]);

  const displayLayerLabel = useMemo(
    () => getDisplayLayerLabel(props.displayLayer, (key, plural) => getSystemFieldName(key, props.systemFields, plural)),
    [props.displayLayer, props.systemFields],
  );

  const moreButtonRef = useRef();
  const isTreeLevel2 = useMemo(() => treeData?.roots?.length, [treeData]);

  const snapshotTableDefaultVisibleFields = useMemo(
    () => [
      'Summary',
      `${getSystemFieldName(selectedSnapshotField, props.systemFields)} Progress`,
      `${displayLayerLabel} Progress`,
      ...(isOKR(selectedSnapshotField) ? ['Metric', 'Owner', 'Health'] : []),
    ],
    [selectedSnapshotField],
  );

  // const snapshotTableHasNoVisibleFields = !snapshotTableVisibleFields || snapshotTableVisibleFields === [];

  const branchItems = useMemo(() => treeData.branches.filter(entity => shouldShowBranchItem(entity)), [treeData]);

  const showExpandMore = useMemo(() => {
    return hideExpandMore ? false : showSnapshotTreeView && isOKR(selectedSnapshotField) && !isOKRLastLevel;
  }, [showSnapshotTreeView, selectedSnapshotField]);

  const changedSelectedSnapshotField = field => {
    props.updateState({ selectedSnapshotField: field.key });
  };

  const leftColumns = [
    `${getSystemFieldName(selectedSnapshotField, props.systemFields)} Progress`,
    `${displayLayerLabel} Progress`,
    'Summary',
    'Metric',
    'Actual Value',
    'Target Value',
  ];
  const rightColumns = ['Owner', 'Health', 'Description', 'Links', 'Display Color'];

  const { leftItems, rightItems } = useGoalTableColumnOptions(leftColumns, rightColumns);

  const _handleTableShowFieldsClicked = () => {
    if (moreButtonRef.current) setTableShowFieldsEl(moreButtonRef.current);
  };

  const _handleCollapseExpandClick = () => {
    props.updateState({ expandTableItems: !expandTableItems });
  };

  const _renderSnapshotTable = () => (
    <Section>
      <GridContainer>
        <SnapshotTable
          title={getSystemFieldName(selectedSnapshotField, props.systemFields)}
          selectedSnapshotField={selectedSnapshotField}
          showSnapshotTreeView={showSnapshotTreeView}
          snapshotTableVisibleFields={snapshotTableVisibleFields || snapshotTableDefaultVisibleFields}
          rows={tableEntities}
          openMetricView={handleOpenMetricView}
          addKeyResultWithoutSave={addKeyResultWithoutSave}
          onUpdateMetadataFieldById={onUpdateMetadataFieldById}
          displayLayer={displayLayerLabel}
          shouldShowColorLegend={false}
          hasKeyResults={hasKeyResults}
          hasKeyResults2={hasKeyResults2}
          rowHeight={rowHeight}
          pageSize={pageSize}
          onUpdatePageSize={onUpdatePageSize}
          expandTableItems={expandTableItems}
          hasAdvancedMetricReporting={hasAdvancedMetricReporting}
          currentUser={currentUser}
          createMetricOption={createMetricOption}
          gridState={gridState}
          saveGridState={saveGridState}
        />
      </GridContainer>
    </Section>
  );

  const _renderShowFieldsPopover = () => (
    <MultiSelectDropdown2ColumnsPopover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={!!tableShowFieldsEl}
      anchorEl={tableShowFieldsEl}
      onClose={() => {
        setTableShowFieldsEl(null);
      }}
      leftItems={leftItems}
      rightItems={rightItems}
      selected={snapshotTableVisibleFields || snapshotTableDefaultVisibleFields}
      handleShowAll={() => {
        props.updateState({ snapshotTableVisibleFields: [...leftItems, ...rightItems] });
      }}
      handleHideAll={() => {
        props.updateState({ snapshotTableVisibleFields: [] });
      }}
      onChange={selectedFields => {
        props.updateState({ snapshotTableVisibleFields: selectedFields });
      }}
    />
  );
  const SnapshotItemComponent = isGoalMode ? SnapshotGoalItem : SnapshotItem;

  const _renderCard = (entity, isChild = false) => {
    return (
      <SnapshotItemComponent
        key={isChild ? `tree-branch-${entity.uniqueId || entity.id}` : entity.uniqueId || entity.id}
        isChild={isChild}
        entity={entity}
        ideas={entity.ideas}
        groupedBy={groupedBy}
        openObjectiveDrawer={openObjectiveDrawer}
        displayLayer={props.displayLayer}
        displayLayerLabel={getDisplayLayerLabel(props.displayLayer, (key, plural) =>
          getSystemFieldName(key, props.systemFields, plural),
        )}
        selectEntity={props.updateTreeData}
        selectedSnapshotField={selectedSnapshotField}
        selectedFieldSystemName={getSystemFieldName(selectedSnapshotField, props.systemFields)}
        selectedField={getSystemFieldName(selectedSnapshotField, props.systemFields)}
        toggleMetricInformation={handleOpenMetricView}
        hasAdvancedMetricReporting={hasAdvancedMetricReporting}
        visibleCardElements={visibleCardElements}
      />
    );
  };

  const _renderMetricChartCard = metric => (
    <InView threshold={0.2} triggerOnce>
      {({ inView, ref }) => {
        return (
          <SnapshotMetricItem
            ref={ref}
            key={metric.id}
            inView={inView}
            metric={metric}
            startDate={startDate}
            endDate={endDate}
            handleOpenMetricView={handleOpenMetricView}
            displayClusters={areClustersVisible}
            isAnonymousUser={isAnonymousUser}
            moarPreferences={{ show: false }}
          />
        );
      }}
    </InView>
  );

  const _renderTreeParents = () => (
    <CardsContainer menuIsClosed={menuIsClosed} cardsPerRow={cardsPerRow} totalCards={entities?.length}>
      {entities.map(entity => _renderCard(entity))}
    </CardsContainer>
  );

  const _renderTreeParentAndChildren = () => (
    <>
      {treeData.roots.map((entity, index) => (
        <TreeContainer key={`tree-root-${entity.uniqueId || entity.id}`}>
          {index === 0 && <GoBackLink onClick={() => props.updateTreeData(entity)}>Go Back</GoBackLink>}
          {_renderCard(entity)}
        </TreeContainer>
      ))}
      <TreeContainer menuIsClosed={menuIsClosed} isChild>
        {branchItems.map(entity => _renderCard(entity, true))}
      </TreeContainer>
    </>
  );

  const _renderMetricOnlyView = () => (
    <TreeContainer menuIsClosed={menuIsClosed} justifyContent="flex-start" gap={`${spacing(2)}px`}>
      {associatedMetrics.map(_renderMetricChartCard)}
    </TreeContainer>
  );

  const shouldRenderTable = !shouldRenderMetricMode && showSnapshotTable;
  const shouldRenderCards = !shouldRenderMetricMode && !hideCards;

  return (
    <ProjectsListLightboxProvider>
      <BaseLayout>
        <SnapshotControls
          expandTableItems={expandTableItems}
          handleCollapseExpandClick={_handleCollapseExpandClick}
          onUpdateGridConfig={onUpdateGridConfig}
          updateState={props.updateState}
          handleTableShowFieldsClicked={_handleTableShowFieldsClicked}
          isOKRLastLevel={isOKRLastLevel}
          showSnapshotTreeView={showSnapshotTreeView}
          shouldRenderControls={shouldRenderControls}
          showExpandMore={showExpandMore}
          moreButtonRef={moreButtonRef}
          showSnapshotTable={showSnapshotTable}
          selectedSnapshotField={selectedSnapshotField}
          hideCards={hideCards}
          hideEmptyCards={hideEmptyCards}
          canViewMetricModeToggle={canViewMetricModeToggle}
          onToggleMetricViewMode={onToggleMetricViewMode}
          shouldRenderMetricMode={shouldRenderMetricMode}
          areClustersVisible={areClustersVisible}
          onToggleClustersVisibility={onToggleClustersVisibility}
          metricDateRangeProps={{
            startDate,
            endDate,
            handleDateRangeChange,
          }}
          hasPermissionForMetricMode={hasPermissionForMetricMode}
          hasProjectMetrics={hasProjectMetrics}
          isGoalMode={isGoalMode}
          cardsPerRow={cardsPerRow}
          visibleCardElements={visibleCardElements}
          snapshotFields={props.snapshotFields}
          groupedBy={groupedBy}
          onDropdownChange={changedSelectedSnapshotField}
          onSetPageUserView={onSetPageUserView}
        />

        <PageBody>
          {shouldRenderMetricMode ? <Section>{_renderMetricOnlyView()}</Section> : null}
          {shouldRenderTable && _renderSnapshotTable()}
          {shouldRenderCards && (
            <Section scrollable={!isTreeLevel2}>{!isTreeLevel2 ? _renderTreeParents() : _renderTreeParentAndChildren()}</Section>
          )}
          {_renderShowFieldsPopover()}
        </PageBody>
      </BaseLayout>
    </ProjectsListLightboxProvider>
  );
};

/**
 * Returns the calc value to
 * @param cardsPerRow
 * @return {`max(100%, ${number}px)`}
 */
const getContainerMaxSize = cardsPerRow => {
  const gap = getGapForCardsContainer(cardsPerRow);
  const allGapsWidth = gap * (cardsPerRow - 1);
  // eslint-disable-next-line no-mixed-operators
  const containerSize = cardsPerRow * MIN_CARD_WITH + allGapsWidth;

  return `max(100%, ${containerSize}px)`;
};

/**
 * Return the calc to be used when defining the grid columns
 * @param cardsPerRow
 * @param totalCards
 * @return {`calc(${string} / ${string})`}
 */
const getColumnSize = (cardsPerRow, totalCards = 0) => {
  const gridSpaceOccupiedByGap = (cardsPerRow - 1) * getGapForCardsContainer(cardsPerRow);
  const cardsAvailableSpace = `(100% - ${gridSpaceOccupiedByGap}px)`;
  const growProp = totalCards < cardsPerRow ? 1 : 0;

  return `${growProp} 1 calc(${cardsAvailableSpace} / ${cardsPerRow})`;
};

const PageBody = styled.div`
  background: ${({ theme }) => theme.palette.background.secondary};
  height: calc(100vh - ${PAGE_HEIGHT_OFFSET}px);
  overflow: auto;
  padding: ${spacing(2)}px ${PAGE_SIDE_MARGIN}px;
  margin: 0 -${PAGE_SIDE_MARGIN}px;
  border-top: ${({ theme }) => `1px solid ${theme.palette.border.secondary}`};
`;

const Section = styled.div`
  width: 100%;
  min-height: 300px;
  ${({ scrollable = false }) => (scrollable ? 'overflow-x: auto;' : '')}
`;

const GridContainer = styled.div`
  margin: ${spacing()}px 0 ${spacing(3)}px;
`;

const CardsContainer = styled.div`
  &&&& {
    // we need this specification to make sure we can override rules on SnapshotItemWrapper
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: ${({ cardsPerRow }) => getContainerMaxSize(cardsPerRow)};
    gap: ${({ cardsPerRow, totalCards }) => `${getGapForCardsContainer(Math.min(cardsPerRow, totalCards))}px`};

    > div {
      flex: ${({ cardsPerRow, totalCards }) => getColumnSize(cardsPerRow, totalCards)};
      max-width: 50%;
      margin: 0;

      > div {
        margin: 0;
      }
    }
  }
`;

const TreeContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  position: relative;

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `}

  ${({ isChild }) =>
    isChild &&
    css`
      margin: 0 -14px;
      margin-top: -6.5px;
    `}
`;

const GoBackLink = styled.div`
  &&&& {
    cursor: pointer;
    font-size: 16px;
    color: #009dff;

    position: absolute;
    top: 0;
    left: 0;

    &:hover {
      text-decoration: underline;
    }
  }
`;
