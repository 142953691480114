import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Delete from '@material-ui/icons/DeleteOutlined';

import FluidField from 'design-system/molecules/FluidField/index';
import FluidFieldLabel from 'design-system/molecules/FluidFieldLabel/index';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import Timeline from 'containers/ProjectLightBox/Form/Timeline';
import { materialColors } from 'design-system/themes/default';
import formatDate from 'utils/dates/formatDate';
import itemsTimespan from 'utils/itemsTimespan';

const getIntegrationTimeSpan = integrationProgress => {
  if (!integrationProgress) return null;

  const hasStories = integrationProgress && integrationProgress.issuesTotal > 0;

  return hasStories ? itemsTimespan([moment(integrationProgress.startDate), moment(integrationProgress.endDate)]) : null;
};

const hasTargetTimeline = value => value.estimated_start_date || value.deadline;

const RenderContainer = styled.div`
  span {
    color: ${materialColors.darkGray};
  }
`;

const StyledFluidField = styled(FluidField)`
  &&& {
    margin-left: -8px;
  }
`;

const FluidTimelineField = props => (
  <StyledFluidField
    {...props}
    renderer={(value, { invalid, required }) => {
      let integrationTimespan;

      if (props.selectedProject) {
        const {
          selectedProject: { integrationProgress },
        } = props;

        integrationTimespan = getIntegrationTimeSpan(integrationProgress);
      }

      return (
        <RenderContainer>
          <Grid container xs={12} direction="row" alignItems="center">
            <Grid item xs={12} md={4}>
              <FluidFieldLabel format="row" label="Target Timeline" invalid={invalid} required={required} />
            </Grid>
            <TargetGrid item xs={12} md={8}>
              <Typography>
                {formatDate(value.estimated_start_date)}
                {!props.isMilestone && value.deadline && ` - ${formatDate(value.deadline)}`}
              </Typography>
              {hasTargetTimeline(value) && (
                <DeleteButtonIcon
                  aria-label="Delete"
                  disabled={props.disableEdit}
                  small
                  onClick={event => {
                    event.stopPropagation();
                    props.onChange({ ...value, deadline: null, estimated_start_date: null });
                  }}
                >
                  <Delete />
                </DeleteButtonIcon>
              )}
            </TargetGrid>
          </Grid>
          {integrationTimespan ? (
            <React.Fragment>
              <Grid container xs={12} direction="row" alignItems="center" style={{ marginTop: 8 }}>
                <Grid item xs={12} md={4}>
                  <FluidFieldLabel format="row" label="Predicted Timeline" />
                </Grid>
                <PredictedGrid item xs={12} md={8} hasTargetTimeline={hasTargetTimeline(value)}>
                  <Typography>
                    {formatDate(integrationTimespan.startDate)} - {formatDate(integrationTimespan.endDate)}
                  </Typography>
                </PredictedGrid>
              </Grid>
            </React.Fragment>
          ) : null}
        </RenderContainer>
      );
    }}
    editorRenderer={({ value, onChange, save, cancel, invalid, required }) => (
      <ClickAwayListener
        onClickAway={e => {
          const path = e.composedPath();

          if (path && path.some(elem => elem.id === 'picker-popover')) return;
          save();
        }}
      >
        <div>
          <FluidFieldLabel format="row" label="Timeline" invalid={invalid} required={required} />
          <Timeline
            isProject={props.isProject}
            isMilestone={props.isMilestone}
            formData={value}
            updateForm={newValue => {
              save({ ...value, ...newValue }, false);
            }}
            projectIntegrations={props.projectIntegrations}
            isIdea={props.isIdea}
            dateRangeMode={props.dateRangeMode}
            onUpdateDateRangeMode={props.onUpdateDateRangeMode}
          />
        </div>
      </ClickAwayListener>
    )}
    ignoreBlurOn={() => !!document.querySelector('#picker-popover')}
  />
);

FluidTimelineField.propTypes = { ...FluidField.propTypes };

export default FluidTimelineField;

const TargetGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const DeleteButtonIcon = styled(ButtonIcon)`
  &&&& {
    margin-left: 8px;
  }
`;

const PredictedGrid = styled(Grid)`
  &&&& {
    text-align: end;
    padding-right: ${props => (props.hasTargetTimeline ? '40px' : 0)};
  }
`;
