import {
  COLLAPSE_ALL,
  EXPAND_ALL,
  TOGGLE_SECTION,
  SET_SEARCH_STRING,
  UPDATE_STAFFING_STATE,
} from './types';

export const collapseAll = (payload) => ({
  type: COLLAPSE_ALL,
  payload,
});

export const expandAll = () => ({
  type: EXPAND_ALL,
});

export const toggleSection = (payload) => ({
  type: TOGGLE_SECTION,
  payload,
});

export const setSearchString = (searchString, emptyRows) => ({
  type: SET_SEARCH_STRING,
  payload: {
    searchString,
    emptyRows,
  },
});

export const updateState = (state) => {
  return {
    payload: state,
    type: UPDATE_STAFFING_STATE,
  };
};

export const staffingActions = {
  collapseAll,
  expandAll,
  toggleSection,
  setSearchString,
  updateState,
};
