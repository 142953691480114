import moment from 'moment-timezone';

export default function (filters, filterId, includeCompleted = false) {
  const pageFilters = {
    ...filters,
    activeFilter: filterId,
    aplliedAt: moment().format(),
  };

  if (!pageFilters?.fields?.planningStages) {
    pageFilters.fields = { ...(pageFilters.fields || {}) };
    pageFilters.fields.planningStages = ['Backlog', 'Planning', 'Confirmed', ...(includeCompleted ? ['Completed'] : [])];
    pageFilters.op = { ...(pageFilters.op || {}), planningStages: 'in' };
  }

  return pageFilters;
}
