import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';

import { REQUEST_FORM_FIELDS } from '../constants';

const LEFT_SIDE_CUSTOM_FIELDS = [TYPES_OF_CUSTOM_FIELDS.SHORT_TEXT, TYPES_OF_CUSTOM_FIELDS.RICH_TEXT];

const getDefaultLeftFields = ({ customFields }) => {
  const leftSideCustomFields = customFields.filter(cf => LEFT_SIDE_CUSTOM_FIELDS.includes(cf.field_type));

  return [
    REQUEST_FORM_FIELDS.details,
    ...leftSideCustomFields.map(field => field.key),
    REQUEST_FORM_FIELDS.files,
    REQUEST_FORM_FIELDS.links,
    REQUEST_FORM_FIELDS.ideas,
    REQUEST_FORM_FIELDS.watchers,
    REQUEST_FORM_FIELDS.comments,
  ];
};

const getDefaultRightFields = ({ customFields, hasPersonas, hasLifecycles, hasExternalKeys, shouldShowPreCalculations }) => {
  const rightSideCustomFields = customFields.filter(cf => !LEFT_SIDE_CUSTOM_FIELDS.includes(cf.field_type));

  return [
    REQUEST_FORM_FIELDS.owner,
    REQUEST_FORM_FIELDS.priority,
    REQUEST_FORM_FIELDS.status,
    ...(hasPersonas ? [REQUEST_FORM_FIELDS.personas] : []),
    ...(hasLifecycles ? [REQUEST_FORM_FIELDS.lifecycles] : []),
    REQUEST_FORM_FIELDS.customersCount,
    ...(shouldShowPreCalculations
      ? [REQUEST_FORM_FIELDS.totalNetRevenue, REQUEST_FORM_FIELDS.totalRevenue, REQUEST_FORM_FIELDS.totalChurnedRevenue]
      : []),
    REQUEST_FORM_FIELDS.customers,
    REQUEST_FORM_FIELDS.tags,
    REQUEST_FORM_FIELDS.desiredTime,
    REQUEST_FORM_FIELDS.roadmap,
    ...rightSideCustomFields.map(field => field.key),
    ...(hasExternalKeys ? [REQUEST_FORM_FIELDS.externalKey, REQUEST_FORM_FIELDS.externalPortfolioKey] : []),
    REQUEST_FORM_FIELDS.updatedAt,
    REQUEST_FORM_FIELDS.createdAt,
    REQUEST_FORM_FIELDS.createdBy,
  ];
};

const getDefaultHiddenFields = () => [];

const getDefaultPromoteFields = ({ customFields, hasPersonas, hasLifecycles }) => {
  const customFieldsKeys = customFields.map(field => field.key);

  return [
    REQUEST_FORM_FIELDS.details,
    REQUEST_FORM_FIELDS.tags,
    REQUEST_FORM_FIELDS.customers,
    REQUEST_FORM_FIELDS.owner,
    REQUEST_FORM_FIELDS.roadmap,
    ...(hasPersonas ? [REQUEST_FORM_FIELDS.personas] : []),
    ...(hasLifecycles ? [REQUEST_FORM_FIELDS.lifecycles] : []),
    REQUEST_FORM_FIELDS.links,
    ...customFieldsKeys,
  ];
};

export { getDefaultRightFields, getDefaultLeftFields, getDefaultHiddenFields, getDefaultPromoteFields };
