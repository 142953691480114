import React from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const BodyContainer = styled(Card)`
  min-height: 150px;
`;

const Body = styled(CardContent)`
  min-height: 100px;
`;

export default ({ children }) => (
  <BodyContainer>
    <Body>
      {children}
    </Body>
  </BodyContainer>
);
