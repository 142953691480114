import React from 'react';
import styled from 'styled-components';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';

import Dropdown from 'design-system/molecules/Dropdown/index';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import ShowFieldsIcon from 'design-system/atoms/ShowFieldsIcon/index';

import { spacing } from 'design-system/theme';
import ShareView from 'containers/ShareView';
import SearchBar from 'components/SearchBar';
import { ROW_HEIGHT_OPTIONS } from 'constants/grid';
import { GOALS_GRID_PAGE } from 'constants/filters/pages';
import { PAGE_SIDE_MARGIN } from 'constants/common';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const GridControls = ({
  expandItems,
  handleCollapseExpandClick,
  onUpdateGridConfig,
  handleTableShowFieldsClicked,
  moreButtonRef,
  searchString,
  updateSearchString,
  onSetPageUserView,
  handleExportToCsv,
}) => {
  const { canView } = usePermissions();

  const canExport = canView(PERMISSION_FEATURES.exportMetricOkrsFromGrid);

  const _renderExpandMoreButton = () => (
    <ControlItem>
      <ButtonIcon title={expandItems ? 'Collapse all' : 'Expand all'} onClick={handleCollapseExpandClick} id="expand-collapse">
        {expandItems ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ButtonIcon>
    </ControlItem>
  );

  const _renderRowHeightButton = () => (
    <ControlItem>
      <StyledDropdown
        label="Row height"
        placeholder={
          <ButtonIcon title="Row height" data-cy="grid-row-height">
            <FormatLineSpacingIcon />
          </ButtonIcon>
        }
        options={ROW_HEIGHT_OPTIONS}
        onChange={(e, { id }) => {
          onUpdateGridConfig('rowHeight', id);
        }}
        isButton={false}
      />
    </ControlItem>
  );

  const _renderShareViewButton = () => (
    <ControlItem>
      <ShareView pageId={GOALS_GRID_PAGE} onSetPageUserView={onSetPageUserView} />
    </ControlItem>
  );

  const _renderSearch = () => (
    <SearchContainer>
      <SearchBar
        searchString={searchString}
        updateSearchString={updateSearchString}
        placeholder="Search this page"
        style={{ width: 160 }}
      />
    </SearchContainer>
  );

  const dropdownOptions = [
    {
      id: 'selectTableFields',
      key: 'selectTableFields',
      onClick: handleTableShowFieldsClicked,
      title: (
        <span>
          <ShowFieldsIcon style={{ fontSize: '24px', verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Show fields</span>
        </span>
      ),
    },
    ...(canExport
      ? [
          {
            id: 'export',
            key: 'export',
            onClick: handleExportToCsv,
            title: (
              <span>
                <DownloadIcon style={{ fontSize: '24px', verticalAlign: 'bottom' }} />
                <span style={{ paddingLeft: 13 }}>Export</span>
              </span>
            ),
          },
        ]
      : []),
  ];

  return (
    <ControlsContainer>
      <Grid container>
        <Grid item xs={12}>
          <StyledFormGroup row>
            {_renderSearch()}
            {_renderExpandMoreButton()}
            {_renderShareViewButton()}
            {_renderRowHeightButton()}
            <MoreDropdownContainer ref={moreButtonRef}>
              <Dropdown
                placeholder={
                  <ButtonIcon>
                    <MoreHorizIcon />
                  </ButtonIcon>
                }
                options={dropdownOptions}
                isButton={false}
              />
            </MoreDropdownContainer>
          </StyledFormGroup>
        </Grid>
      </Grid>
    </ControlsContainer>
  );
};

export default GridControls;

const ControlsContainer = styled.div`
  margin: 0 -${PAGE_SIDE_MARGIN}px;
  padding: ${spacing(0.5)}px ${PAGE_SIDE_MARGIN}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.secondary};
`;

const ControlItem = styled.div``;

const StyledDropdown = styled(Dropdown)`
  margin: 0;
`;

const StyledFormGroup = styled(FormGroup)`
  align-items: center;
  justify-content: flex-end;
  gap: ${spacing(0.5)}px;
`;

const SearchContainer = styled.div`
  input::placeholder {
    color: ${({ theme }) => theme.palette.text.quickSilver};
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  }
`;

const MoreDropdownContainer = styled.div``;
