import React from 'react';
import { withTheme } from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

function SvgComponent(props) {
  const fill = props.fill || props.theme.palette.text.primary;

  return (
    <SvgIcon fontSize="inherit" width="24.125" height="24.058" viewBox="0 0 24.125 24.058" {...props}>
      <g id="icon-summary" transform="translate(-120.087 -655.991)" opacity="0.6">
        <path 
          id="Path_15621"
          data-name="Path 15621"
          d="M129.441,667.539H122.28a2.2,2.2,0,0,1-2.193-2.194v-5.384a3.975,3.975,0,0,1,3.971-3.97h5.383a2.2,
          2.2,0,0,1,2.194,2.193v7.161A2.2,2.2,0,0,1,129.441,667.539Zm-5.383-9.548a1.973,1.973,0,0,0-1.971,
          1.97v5.384a.194.194,0,0,0,.193.194h7.161a.194.194,0,0,0,.194-.194v-7.161a.194.194,0,0,0-.194-.193Z"
          fill={fill}
        />
        <path 
          id="Path_15622"
          data-name="Path 15622"
          d="M142.019,667.539h-7.161a2.2,2.2,0,0,1-2.193-2.194v-7.161a2.2,2.2,0,0,1,2.193-2.193h5.384a3.975,
          3.975,0,0,1,3.97,3.97v5.384A2.2,2.2,0,0,1,142.019,667.539Zm-7.161-9.548a.193.193,0,0,0-.193.193v7.161a.193.193,
          0,0,0,.193.194h7.161a.194.194,0,0,0,.193-.194v-5.384a1.973,1.973,0,0,0-1.97-1.97Z"
          fill={fill}
        />
        <path 
          id="Path_15623"
          data-name="Path 15623"
          d="M129.441,680.049h-5.383a3.975,3.975,0,0,1-3.971-3.97V670.7a2.2,2.2,0,0,1,2.193-2.194h7.161a2.2,2.2,
          0,0,1,2.194,2.194v7.16A2.2,2.2,0,0,1,129.441,680.049ZM122.28,670.5a.194.194,0,0,0-.193.194v5.384a1.973,1.973,
          0,0,0,1.971,1.97h5.383a.194.194,0,0,0,.194-.194V670.7a.194.194,0,0,0-.194-.194Z"
          fill={fill}
        />
        <path 
          id="Path_15624"
          data-name="Path 15624"
          d="M140.242,680.049h-5.384a2.2,2.2,0,0,1-2.193-2.194V670.7a2.2,2.2,0,0,1,2.193-2.194h7.161a2.2,2.2,
          0,0,1,2.193,2.194v5.384A3.975,3.975,0,0,1,140.242,680.049Zm-5.384-9.548a.193.193,0,0,0-.193.194v7.16a.193.193,0,0,0,
          .193.194h5.384a1.973,1.973,0,0,0,1.97-1.97V670.7a.194.194,0,0,0-.193-.194Z"
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
}

export default withTheme(SvgComponent);
