import { defaultTo, pathOr } from 'ramda';
import { commentsAdapter } from './helpers/adapters';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const defaultToEmptyObject = defaultTo(EMPTY_OBJECT);

export const getState = state => state?.pdlc;

export const getProjectDeliverableHistoryState = state => defaultToEmptyObject(getState(state)?.projectDeliverablesHistory);

export const selectProjectDeliverableHistory = (state, id) => {
  const projectDeliverablesHistory = getProjectDeliverableHistoryState(state);

  return pathOr(EMPTY_ARRAY, [id, 'history'])(projectDeliverablesHistory);
};

export const selectProjectDeliverableHistoryNextPage = (state, id) => {
  const projectDeliverablesHistory = getProjectDeliverableHistoryState(state);

  return pathOr(null, [id, '_meta', 'next'])(projectDeliverablesHistory);
};

const defaultToCommentsInitialState = defaultTo(commentsAdapter.getInitialState());

export const commentsSelector = commentsAdapter.getSelectors(state => {
  return defaultToCommentsInitialState(state.comments);
});

export const selectProjectDeliverableComments = state => commentsSelector.selectAll(getState(state));

export const selectProjectDeliverableCommentsMetadata = state => getState(state)?.commentsMetadata;
