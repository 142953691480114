import { COMMITTED_KEY, INCLUDE_ALL_OPTION } from 'constants/projects';
import isObject from 'lodash/isObject';

/**
 * Due to changes on the plan by 'above the line' options we need to ensure retro compatibility of older views
 * Here we will set to the default old values for the above the line options to the new format
 * @param selectedOptionsOnFilterToPlan
 * @return {{}}
 */
export const ensureOptionsRetroCompatibility = (selectedOptionsOnFilterToPlan = {}) => {
  return Object.entries(selectedOptionsOnFilterToPlan).reduce((acc, [key, value]) => {
    if (key === COMMITTED_KEY && isObject(value)) {
      return {
        ...acc,
        [key]: INCLUDE_ALL_OPTION,
      };
    }
    return { ...acc, [key]: value };
  }, {});
};
