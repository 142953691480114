import React, { useRef } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { getElementAtEvent } from 'react-chartjs-2';

import BarChart from 'components/ReactChart/BarChart';
import Segment from 'design-system/atoms/Segment/index';
import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete/index';

import getLegendConfigForStackedChart from 'utils/charts/getLegendConfigForStackedChart';
import showCursorPointerOnHover from 'utils/charts/showCursorPointerOnHover';
import { spacing } from 'design-system/theme';

import { checkForChartData } from '../helpers';
import useGroupByOptions from '../hooks/useGroupByOptions';

export default ({ groupBy, stackedBy, chartData, handleGroupedByChange, handleStackedByChange, handleChartItemClick }) => {
  const barChartRef = useRef(null);

  const optionsForGroupAndStackBy = useGroupByOptions();

  const hasData = checkForChartData(chartData);

  return (
    <Wrapper bordered>
      <Grid container>
        <Grid item xs={12} md={12}>
          <ToolbarContainer>
            <GroupByAutocomplete
              label="Group by"
              name="selectedGroup"
              suggestions={optionsForGroupAndStackBy}
              value={groupBy}
              onChange={handleGroupedByChange}
            />
            <GroupByAutocomplete
              label="Stack by"
              name="selectedStack"
              suggestions={optionsForGroupAndStackBy}
              value={stackedBy}
              onChange={handleStackedByChange}
            />
          </ToolbarContainer>
        </Grid>
        <Grid item xs={12} md={12}>
          <BarChartContainer>
            {hasData && (
              <BarChart
                setRef={ref => (barChartRef.current = ref)}
                data={chartData}
                options={{
                  plugins: {
                    legend: {
                      position: 'bottom',
                      ...getLegendConfigForStackedChart(),
                    },
                  },
                  animation: {
                    duration: 0,
                  },
                  scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true,
                    },
                  },
                  onHover: showCursorPointerOnHover,
                }}
                onClick={event => {
                  const elm = getElementAtEvent(barChartRef.current, event);

                  elm.length > 0 &&
                    handleChartItemClick(chartData.labels[[elm[0].index]], chartData.datasets[[elm[0].datasetIndex]].label);
                }}
              />
            )}
          </BarChartContainer>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled(Segment)`
  &&&& {
    overflow: hidden;
  }
`;

const BarChartContainer = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2)}px;
`;
