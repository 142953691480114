import { defaultTo, pipe, propEq } from 'ramda';
import { RoundMode } from 'utils/unitConversion/roundMode';
import { Unit, convertDaysTo } from 'utils/unitConversion/unitConversion';

export const DURATION_DAY = {
  key: 'day',
};

export const DURATION_WEEK = {
  key: 'week',
};

const isDurationTypeDay = pipe(defaultTo(DURATION_DAY), propEq('key', 'day'));

const durationToUnit = (duration, useWorkdays = true) => {
  if (isDurationTypeDay(duration)) {
    return Unit.DAYS;
  }

  if (useWorkdays) {
    return Unit.WORK_WEEK;
  }

  return Unit.CALENDAR_WEEK;
};

/**
 * Ceil reported allocation to nearest decimal number with two decimal places.
 */
const allocationRounder = new RoundMode(value => {
  return Math.round(value * 100) / 100;
});

export const convertPlannedAllocationToDuration = (days, duration) => {
  const unit = durationToUnit(duration, false);

  return convertDaysTo(days, unit, allocationRounder);
};

export const convertReportedAllocationToDuration = (days, duration) => {
  const unit = durationToUnit(duration, true);

  return convertDaysTo(days, unit, allocationRounder);
};
