import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import NumberFormat from 'react-number-format';
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';

import IconButton from '@material-ui/core/IconButton';

import Text from 'design-system/atoms/Text/index';

import renderFormatedNumberText from 'design-system/utils/renderFormattedNumberText';

const TextCellRenderer = params => {
  const onClick = e => {
    if (!params.colDef.onLinkClick) return;
    e.preventDefault();
    e.stopPropagation();

    params.colDef.onLinkClick(params.data);
  };

  const onSecondaryLinkClick = e => {
    if (!params.colDef.onSecondaryLinkClick) return;
    e.preventDefault();
    e.stopPropagation();

    params.colDef.onSecondaryLinkClick(params.data);
  };

  const hasLink = (() => {
    if (params.colDef.hasLink) {
      if (typeof params.colDef.hasLink === 'function') return params.colDef.hasLink(params);
      return params.colDef.hasLink;
    }

    return false;
  })();

  const hasSecondaryLink = (() => {
    if (params.colDef.hasSecondaryLink) {
      if (typeof params.colDef.hasSecondaryLink === 'function') return params.colDef.hasSecondaryLink(params);
      return params.colDef.hasSecondaryLink;
    }

    return false;
  })();

  const textIsClickable = useMemo(() => !!params?.isLinkDisplay, [params?.isLinkDisplay]);

  const getTextColor = () => {
    if (isNumber(+params.value) && +params.value < 0) return 'red!important';

    return params.colDef.cellClass ? '' : '#303030';
  };

  const defaultFormatter = value => {
    if (value && !isNaN(+value)) {
      return (
        <NumberFormat value={value} renderText={renderFormatedNumberText} displayType="text" thousandSeparator decimalScale={2} />
      );
    }

    return value;
  };

  const getTextStyles = () => params.textStyles || '';

  return (
    <Wrapper hasLink={hasLink || hasSecondaryLink} onClick={textIsClickable && onClick}>
      <StyledText
        withEllipsis={!params.colDef.breakwords && !params.colDef.autoHeight}
        textStyles={getTextStyles()}
        color={getTextColor()}
      >
        {params.colDef.valueFormatter ? params.colDef.valueFormatter(params.data) : defaultFormatter(params.value)}
      </StyledText>
      {hasLink || hasSecondaryLink ? (
        <LinksContainer>
          {!!hasLink && <OpenLink onClick={onClick}>{params.colDef.linkIcon}</OpenLink>}
          {!!hasSecondaryLink && <OpenLink onClick={onSecondaryLinkClick}>{params.colDef.secondaryLinkIcon}</OpenLink>}
        </LinksContainer>
      ) : null}
    </Wrapper>
  );
};

export default TextCellRenderer;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ hasLink }) =>
    hasLink &&
    `
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  `}
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const OpenLink = styled(props => <IconButton {...props}>{props.children}</IconButton>)`
  &&&& {
    position: relative;
    visibility: hidden;
    padding: 3px;
    font-size: 1.125rem;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

const StyledText = styled(Text)`
  &&&& {
    ${({ textStyles }) => textStyles};

    ${({ color }) =>
      color &&
      css`
        color: ${color};
      `}
  }
`;
