import React from 'react';

import usePageNavigation from 'hooks/usePagesNavigation/usePageNavigation';
import { setLastIdeasView } from 'store/app';
import PageHeaderFilters from 'containers/PageHeaderFilters';

import PortfolioOverviewHeaderFilters from 'features/PortfolioOverview/components/PageHeaderFilters';
import WhiteboardOverviewHeaderFilters from 'features/whiteboard/components/WhiteboardPageHeaderFilters';
import { IDEAS_OVERVIEW_PATH, IDEAS_CANVAS_PATH } from 'constants/filters/pages';

const componentByPageUrlMapper = {
  [IDEAS_OVERVIEW_PATH]: PortfolioOverviewHeaderFilters,
  [IDEAS_CANVAS_PATH]: WhiteboardOverviewHeaderFilters,
};

const IdeasPageHeaderFilters = props => {
  const { pages, selectedPage, setSelectedPage } = usePageNavigation('/ideas', props.history, 'lastIdeasView', setLastIdeasView);

  const FiltersComponent = componentByPageUrlMapper[selectedPage] || PageHeaderFilters;

  return (
    <FiltersComponent
      {...props}
      subNavigationTabs={pages}
      selectedSubNavigationTab={selectedPage}
      handleSubNavigationChange={setSelectedPage}
      enableMultiLayerSearch
    />
  );
};

export default IdeasPageHeaderFilters;
