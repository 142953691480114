import React, { useImperativeHandle, useState, useEffect } from 'react';

import cellEditorHoc from 'design-system/molecules/AgGridReact-New/helpers/cellEditorHoc';
import GroupAutocomplete from '../ByTeamAutocomplete';

const GroupAutocompleteCellEditor = cellEditorHoc(({ forwardedRef, stopEditing, ...params }) => {
  const { value } = params;

  const [internalValue, setInternalValue] = useState(undefined);

  useImperativeHandle(forwardedRef, () => ({
    getValue() {
      return internalValue !== undefined ? internalValue : value;
    },
  }));

  const handleChange = selectedItem => {
    setInternalValue(selectedItem?.title || '');
  };

  useEffect(() => {
    if (internalValue != null) {
      stopEditing();
    }
  }, [internalValue]);

  return <GroupAutocomplete {...params} onSelectOptionCallback={handleChange} />;
});

export default GroupAutocompleteCellEditor;
