import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';

import { getDropdownCustomFieldOptionSelectionColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import { SortableHeaderWithIconCellRenderer } from 'design-system/molecules/AgGridReact-New/headerCellRenderers';

const getCustomizableCustomFieldColumnDef = ({ editable, customField, onCustomizeIconClick }) => {
  const getCustomFieldValue = params => {
    const value = params.data?.[customField.key];

    return customField?.data?.[value] || '';
  };

  return {
    ...getDropdownCustomFieldOptionSelectionColumnDef({ editable, customField }),
    headerComponent: SortableHeaderWithIconCellRenderer,
    valueFormatter: getCustomFieldValue,
    valueGetter: getCustomFieldValue,
    headerComponentParams: {
      icon: <SettingsIcon />,
      hasIcon: true,
      onIconClick: () => onCustomizeIconClick(customField),
    },
    width: 130,
  };
};

export default getCustomizableCustomFieldColumnDef;
