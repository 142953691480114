import React, { useEffect, useMemo, useCallback } from 'react';

import { BaseGrid } from 'containers/Grids';
import {
  useSettingsActions,
  useSettingsGridColumns,
  useSettingsGridEventHandlers,
  useSettingsGridProps,
  useSettingsGridSelection,
} from 'containers/Grids/SettingsGrid/hooks';
import { USERS } from 'store/grids/constants';
import useSubscribeNewData from 'hooks/useSubscribeNewData';

import UsersSettingsActionsToolbar from './components/UsersSettingsActionsToolbar';
import UsersSettingsDialogs from './components/UsersSettingsDialogs';
import exportToCsvHelper from './helpers/exportToCsv';
import useUsers from './hooks/useUsers';
import useUsersGridColumns from './hooks/useUsersGridColumns';
import useFilteredUsers from './hooks/useFilteredUsers';
import useUsersSettings from './hooks/useUsersSettings';
import { topics } from 'constants/topics';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import usePermissions from 'hooks/permissions/usePermissions';
import { sortUsersByDefault } from './helpers/listUsers';

const FIRST_NAME_FIELD = 'first_name';

const TOPICS_TO_SUBSCRIBE = [topics.USERS, topics.USER_VIEWS, topics.USER_FILTERS, topics.APP];

const methodsToDecorate = {
  exportToCsv: exportToCsvHelper,
};

const Users = ({ actionsPadding }) => {
  useSubscribeNewData(TOPICS_TO_SUBSCRIBE);

  const { canUpdate, canCreate, canMerge, canDelete } = usePermissions();
  const allowActions = canUpdate(PERMISSION_RESOURCES.user) || canCreate(PERMISSION_RESOURCES.user);

  const {
    users,
    currentUser,
    allowAddNewSettings,
    fetchUsers,
    deleteUserById,
    bulkDeleteUsers,
    createUsers,
    updateUsers,
    updateUserById,
    mergeUsers,
    createUser,
    addUserWithoutSave,
    removeUnsavedUsers,
    systemFields,
    allTeams,
    teamsTitles,
    hasTeams2,
    skillsTitles,
    skills,
    isPortalSettingsEnabled,
    hasJiraIntegration,
    hideUserIntegrationColumn,
    orgIntegrations,
    searchText,
    updateUsersSearch,
    filters,
    updateFilters,
    orgHasOkta,
  } = useUsers();

  const usersGridColumnDefs = useUsersGridColumns({
    allowActions,
    currentUser,
    systemFields,
    allTeams,
    teamsTitles,
    hasTeams2,
    updateUserById,
    skillsTitles,
    isPortalSettingsEnabled,
    hasJiraIntegration,
    hideUserIntegrationColumn,
    orgIntegrations,
  });

  // Settings common agGrid configuration

  const {
    selectionMode,
    selectedItems,
    enableSelectionMode,
    disableSelectionMode,
    updateSelectedOnAgGridInit,

    replaceCellContent,
    methods: { exportToCsv },
  } = useSettingsGridSelection(USERS, {
    extraMethods: methodsToDecorate,
    actions: {
      remove: removeUnsavedUsers,
    },
  });

  const {
    setItemToDelete,
    deleteItem,
    showBulkDeleteDialog: usersBulkDeleteDialogIsOpen,
    setShowBulkDeleteDialog: setUsersBulkDeleteDialogIsOpen,
    deleteProgress,
    deleteIsLoading,
    bulkDeleteItems,
    mergeLightboxVisible: usersMergeDialogIsVisible,
    showMergeLightbox,
    hideMergeLightbox: hideUsersMergeDialog,
  } = useSettingsActions({
    settingType: USERS,
    selectedItems,
    disableSelectionMode,
    deleteById: deleteUserById,
    bulkDelete: bulkDeleteUsers,
  });

  const settingsGridProps = useSettingsGridProps({
    bulkCreate: createUsers,
    bulkUpdate: updateUsers,
  });

  const {
    usersBulkUpdateDialogIsOpen,
    usersImportDialogIsOpen,
    userDeleteDialogIsOpen,
    userAccessEmailDialogIsVisible,

    userAccessData,

    handleBulkDeleteUsersClick,
    handleBulkUpdateUsersClick,
    handleMergeUsersClick,
    handleAddNewUserClick,
    handleUserDeleteClick,
    openImportLightbox,
    handleSaveUserAccessEmail,
    handleCancelUserAccessEmail,
    hideUserAccessEmailDialog,
    handleUserDeleteConfirm,
    handleUserDeleteCancel,
    handleUsersBulkDeleteCancel,
    handleUsersImportCancel,
    handleUsersBulkUpdateCancel,
    handleUsersBulkUpdateConfirm,

    updateUser,
    bulkDelete,
  } = useUsersSettings({
    users,
    selectedItems,
    selectionMode,
    enableSelectionMode,
    disableSelectionMode,

    showMergeLightbox,
    setUsersBulkDeleteDialogIsOpen,

    addUserWithoutSave,
    updateUserById,
    updateUsers,
    setItemToDelete,
    deleteItem,
    bulkDeleteItems,

    replaceCellContent,
  });

  const permissions = {
    allowActions,
    canUpdate: useCallback(data => canUpdate(PERMISSION_RESOURCES.user, { data }), [canUpdate]),
    deleteVisibility: useCallback(params => canDelete(PERMISSION_RESOURCES.user, { data: params.data }), [canDelete]),

    allowMergeUsers: canMerge(PERMISSION_RESOURCES.user),
    enableRightButtons: allowActions,
    allowAddNewMetadata: allowAddNewSettings,
  };

  const settingsGridColumns = useSettingsGridColumns({
    allowActions: permissions.allowActions,
    canUpdate: permissions.canUpdate,
    selectionMode,
    setItemToDelete: handleUserDeleteClick,
    deleteVisibility: permissions.deleteVisibility,
  });

  const settingsGridEventHandlers = useSettingsGridEventHandlers({
    settingType: USERS,
    items: users,
    focusField: FIRST_NAME_FIELD,
    actions: {
      update: updateUser,
      save: createUser,
      remove: removeUnsavedUsers,
    },
  });

  // Join settings and metrics column definitions
  const columnDefs = useMemo(
    () => [...settingsGridColumns.beforeColumnDefs, ...usersGridColumnDefs, ...settingsGridColumns.afterColumnDefs],
    [settingsGridColumns.beforeColumnDefs, settingsGridColumns.afterColumnDefs, usersGridColumnDefs],
  );

  const filteredUsers = useFilteredUsers(users, { filtersValues: filters, searchText });
  const sortedUsers = sortUsersByDefault(filteredUsers);

  useEffect(() => {
    // This will reload the data when we enter the page.
    // Useful to update associated entities of the user entity
    // (e.g., teams, skills) that have been changed in the meantime.
    fetchUsers();
  }, []);

  return (
    <>
      <UsersSettingsActionsToolbar
        isSelectionMode={selectionMode}
        selectedItems={selectedItems}
        enableSelectionMode={enableSelectionMode}
        onBulkDeleteUsersClick={handleBulkDeleteUsersClick}
        onBulkUpdateUsersClick={handleBulkUpdateUsersClick}
        allowMergeUsers={permissions.allowMergeUsers}
        onMergeUsersClick={handleMergeUsersClick}
        onCancelSelectionModeClick={disableSelectionMode}
        onAddNewUserClick={handleAddNewUserClick}
        searchText={searchText}
        updateUsersSearchText={updateUsersSearch}
        filters={filters}
        updateFilters={updateFilters}
        skillsTitles={skillsTitles}
        allowActions={permissions.allowActions}
        orgHasOkta={orgHasOkta}
        openImportLightbox={openImportLightbox}
        exportToCsv={exportToCsv}
        allTeams={allTeams}
        hasTeams2={hasTeams2}
        skills={skills}
        teamsTitles={teamsTitles}
        enableRightButtons={permissions.enableRightButtons}
        actionsPadding={actionsPadding}
      />
      <BaseGrid
        onGridReady={updateSelectedOnAgGridInit}
        rowData={sortedUsers}
        columnDefs={columnDefs}
        defaultColDef={settingsGridColumns.defaultColDef}
        {...settingsGridProps}
        {...settingsGridEventHandlers}
        height="calc(100vh - 150px)"
      />
      <UsersSettingsDialogs
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
        // Props for Users Merge Dialog:
        usersMergeDialogIsVisible={usersMergeDialogIsVisible}
        onMergeUsers={mergeUsers}
        hideUsersMergeDialog={hideUsersMergeDialog}
        // Props for User Access E-mail Dialog:
        userAccessEmailDialogIsVisible={userAccessEmailDialogIsVisible}
        onSaveUserAccessEmail={handleSaveUserAccessEmail}
        onCancelUserAccessEmail={handleCancelUserAccessEmail}
        hideUserAccessEmailDialog={hideUserAccessEmailDialog}
        userAccessName={userAccessData?.name}
        userAccessEmail={userAccessData?.email}
        userAccessRoleId={userAccessData?.roleId}
        // Props for User Delete Dialog:
        userDeleteDialogIsOpen={userDeleteDialogIsOpen}
        onUserDeleteConfirm={handleUserDeleteConfirm}
        onUserDeleteCancel={handleUserDeleteCancel}
        // Props for Users Bulk Delete Dialog:
        usersBulkDeleteDialogIsOpen={usersBulkDeleteDialogIsOpen}
        onUsersBulkDeleteConfirm={bulkDelete}
        onUsersBulkDeleteCancel={handleUsersBulkDeleteCancel}
        disableUsersBulkDeleteActions={deleteIsLoading}
        // Props for Bulk Delete Progress Dialog:
        bulkDeleteProgress={deleteProgress}
        // Props for Import Users Dialog:
        usersImportDialogIsOpen={usersImportDialogIsOpen}
        onUsersImportCancel={handleUsersImportCancel}
        // Props for Users Bulk Update Dialog:
        usersBulkUpdateDialogIsOpen={usersBulkUpdateDialogIsOpen}
        onUsersBulkUpdateCancel={handleUsersBulkUpdateCancel}
        onUsersBulkUpdateConfirm={handleUsersBulkUpdateConfirm}
        systemFields={systemFields}
        teamsTitles={teamsTitles}
        skillsTitles={skillsTitles}
        allowAddNewSettings={permissions.allowAddNewMetadata}
      />
    </>
  );
};

export default Users;
