import { createThunk } from '../../utils/store/thunk';
import throwRequestError from '../utils/throwRequestError';

import {
  CREATE_DISCOVERY_BOT_THREAD,
  PROCESS_USER_CHAT_MESSAGE,
} from './types';

import axios from 'axios';

export const createDiscoveryBotThread = () =>
  createThunk(
    CREATE_DISCOVERY_BOT_THREAD,
    axios.post('/api/v1/discoverybot/threads').then(res => res.data).catch(throwRequestError),
  );

export const processUserChatMessage = ({ threadId, text }) =>
  createThunk(
    PROCESS_USER_CHAT_MESSAGE,
    axios
      .post(`/api/v1/discoverybot/${threadId}/messages`, { text })
      .then(res => res.data)
      .catch(throwRequestError),
  );
