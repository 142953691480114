import { useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
// import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';

import useDeepEffect from 'hooks/useDeepEffect';
import { getUserName, ucFirst } from 'utils';
import { STAFFING_PAGE } from 'constants/filters';
import { getSkills } from 'store/skills/selectors';
import { getTeams } from 'store/teams/selectors';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import { getPageFilters } from 'store/filters/selectors';
import paginateArray from 'utils/paginateArray';
import normalizeArray from 'utils/normalizeArray';
import { collapseAll, expandAll, toggleSection } from 'store/staffing';

export default (tasks, users) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataTree, setDataTree] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const dispatch = useDispatch();

  const storeData = useSelector(state => ({
    users,
    teams: getStateDataForPage(state, getTeams, 'teams', STAFFING_PAGE),
    skills: getStateDataForPage(state, getSkills, 'skills', STAFFING_PAGE),
    lsState: state.staffing,
    pageFilters: getPageFilters(state, STAFFING_PAGE),
  }));

  const { pageFilters, teams, skills, lsState } = storeData;
  const { searchString, collapsedSections, selectedGroup1, selectedGroup2, hideTasksName } = lsState;
  const usersRef = useRef(users);
  const collapsedSectionsRef = useRef(collapsedSections);
  const allGroupsRef = useRef(allGroups);

  usersRef.current = users;
  collapsedSectionsRef.current = collapsedSections;
  allGroupsRef.current = allGroups;

  const resultsPerPage = 50;
  const _setCurrentPage = page => {
    if ((paginatedData?.length || 0) <= (usersRef?.current?.length || 0)) setCurrentPage(page);
  };
  const _collapseAll = () => {
    dispatch(collapseAll(allGroups));
    setCurrentPage(1);
  };
  const _expandAll = () => {
    dispatch(expandAll());
    setCurrentPage(1);
  };
  const _toggleGroup = groupKey => {
    // if (collapsedSectionsRef.current.includes(groupKey)) {
    //   setCurrentPage(1);
    //   dispatch(
    //     collapseAll(
    //       collapsedSectionsRef.current.slice(
    //         0,
    //         collapsedSectionsRef.current.findIndex(g => g === groupKey),
    //       ),
    //     ),
    //   );

    //   return;
    // }
    dispatch(toggleSection(groupKey));
  };

  const paginatedData = useMemo(() => paginateArray(users, resultsPerPage, currentPage), [users, resultsPerPage, currentPage]);

  const _generateTree = () => {
    const _isOpen = key => !collapsedSections.includes(key);
    const _getTitle = title => (title && title.length > 15 ? `${title.slice(0, 15)}...` : title);
    const _groupKey = (key, id, parent) => `${parent ? `${parent}:` : ''}${key}:${id}`;
    const _configureGroupLevel = (option, key, parent) => ({
      id: option.id,
      key,
      label: _getTitle(option.title),
      open: _isOpen(key),
      children: [],
      parent,
    });

    let data = users;
    const group1Data = orderBy(storeData[`${selectedGroup1 && selectedGroup1.key}s`], ['title'], ['asc']);
    const group2Data = orderBy(storeData[`${selectedGroup2 && selectedGroup2.key}s`], ['title'], ['asc']);
    const normalizedGroup1Data = normalizeArray(group1Data, 'id');
    const normalizedGroup2Data = normalizeArray(group2Data, 'id');
    const hasGroup1 = selectedGroup1 && selectedGroup1.key;
    const hasGroup2 = selectedGroup2 && selectedGroup2.key;
    let closedGroup1s = [];
    let closedGroup2s = [];
    let allGroupsKeys = [];

    if (hasGroup1) {
      allGroupsKeys = group1Data.map(g => _groupKey(selectedGroup1.key, g.id));
      closedGroup1s = group1Data.filter(g => collapsedSections.includes(_groupKey(selectedGroup1.key, g.id))).map(g => g.id);
    }
    if (hasGroup2) {
      const allDataGrouped = groupBy(data, `${selectedGroup1.key}_id`);

      closedGroup2s = group1Data.reduce((acc, entry) => {
        let allDataGroup2 = groupBy(allDataGrouped[entry.id], `${selectedGroup2.key}_id`);

        allDataGroup2 = orderBy(
          Object.keys(allDataGroup2)
            .map(g => normalizedGroup2Data[g])
            .filter(Boolean),
          ['title'],
          ['asc'],
        );

        allGroupsKeys = [...allGroupsKeys, ...allDataGroup2.map(d => _groupKey(selectedGroup2.key, d.id, entry.id))];

        return [
          ...acc,
          ...group2Data
            .filter(g => collapsedSections.includes(_groupKey(selectedGroup2.key, g.id, entry.id)))
            .map(g => `${entry.id}:${g.id}`),
        ];
      }, []);
    }

    const _removeEntriesWithEmptyChildren = entries => {
      return entries.reduce((acc, entry) => {
        if (!entry.children) return acc;
        if (closedGroup1s.includes(entry.id)) return acc;
        if (entry.parent && closedGroup2s.includes(`${entry.parent}:${entry.id}`)) return acc;

        entry.children = _removeEntriesWithEmptyChildren(entry.children);

        if (!entry.children.length) {
          acc = acc.filter(o => o.id !== entry.id);
        }
        return acc;
      }, entries);
    };

    data = orderBy(data, ['teamTitle', 'skillTitle', 'name'], ['asc']);

    if (hasGroup1) {
      data = [
        ...data.filter(u => u[`${selectedGroup1.key}_id`] === null),
        ...data.filter(u => u[`${selectedGroup1.key}_id`] !== null),
      ];
      data = data.filter(u => !closedGroup1s.includes(u[`${selectedGroup1.key}_id`]));
    }
    if (hasGroup2) {
      data = [
        ...data.filter(u => u[`${selectedGroup2.key}_id`] === null),
        ...data.filter(u => u[`${selectedGroup2.key}_id`] !== null),
      ];
      data = data.filter(u => !closedGroup2s.includes(`${u[`${selectedGroup1.key}_id`]}:${u[`${selectedGroup2.key}_id`]}`));
    }

    data = (paginatedData || []).map(u => ({
      ...u,
      title: `${getUserName(u)}${u.status === 'Inactive' ? ' (Inactive)' : ''}`,
    }));

    const undefinedData = {
      id: null,
      key: 'N/A',
      label: 'Undefined',
    };
    let result = [];
    const base = [undefinedData];

    // Build Group 1
    if (hasGroup1 && group1Data) {
      const undefinedGroup1 = {
        id: null,
        key: _groupKey(selectedGroup1.key, 'undefined'),
        label: `${ucFirst(selectedGroup1.key)} Undefined`,
        open: _isOpen(_groupKey(selectedGroup1.key, 'undefined')),
        children: [],
      };

      allGroupsKeys.unshift(undefinedGroup1.key);

      const visibleGroup1s = [
        ...closedGroup1s,
        ...closedGroup2s.map(g => +g.split(':')[0]),
        ...data.map(u => u[`${selectedGroup1.key}_id`]),
      ];

      const visibleGroup1Data = orderBy(
        uniq(visibleGroup1s)
          .map(t => normalizedGroup1Data[t])
          .filter(Boolean),
        ['title'],
        ['asc'],
      );

      result = [undefinedGroup1, ...visibleGroup1Data.map(o => _configureGroupLevel(o, _groupKey(selectedGroup1.key, o.id)))];
    }

    // Build Group 2
    if (hasGroup2 && group2Data) {
      const undefinedGroup2 = cur => ({
        id: null,
        key: _groupKey(selectedGroup2.key, 'undefined', cur.id),
        label: `${ucFirst(selectedGroup2.key)} Undefined`,
        open: _isOpen(_groupKey(selectedGroup2.key, 'undefined', cur.id)),
        children: [],
      });

      result = result.map(cur => {
        // allGroupsKeys.push(undefinedGroup2(cur).key);

        const closedGroups = closedGroup2s.filter(g => g.includes(`${cur.id}:`)).map(g => +g.split(':')[1]);

        const visibleGroup2Data = orderBy(
          uniq([...closedGroups, ...data.map(u => u[`${selectedGroup2.key}_id`])])
            .map(t => normalizedGroup2Data[t])
            .filter(Boolean),
          ['title'],
          ['asc'],
        );

        cur.children = [
          undefinedGroup2(cur),
          ...visibleGroup2Data.map(o => _configureGroupLevel(o, _groupKey(selectedGroup2.key, o.id, cur.id), cur.id)),
        ];

        return cur;
      });
    }

    // Build the data tree
    result = data.reduce((acc, entry) => {
      const entryToAdd = { key: entry.id, label: entry.title };

      if (searchString && !tasks.filter(t => t.owner_id === entry.id).length) return acc;

      if (hasGroup1 && hasGroup2) {
        const group1Entry = acc.find(o => o.id === entry[`${selectedGroup1.key}_id`]);
        const group2Entry = group1Entry && group1Entry.children.find(o => o.id === entry[`${selectedGroup2.key}_id`]);

        if (group2Entry) group2Entry.children.push(entryToAdd);
      } else if (hasGroup1) {
        const group1Entry = acc.find(o => o.id === entry[`${selectedGroup1.key}_id`]);

        if (group1Entry) group1Entry.children.push(entryToAdd);
      } else {
        acc.push(entryToAdd);
      }

      return acc;
    }, result);

    result = [...base, ..._removeEntriesWithEmptyChildren(result)];

    setDataTree(result);

    setAllGroups(allGroupsKeys);
  };

  useDeepEffect(() => {
    _generateTree();
  }, [
    pageFilters,
    teams,
    skills,
    selectedGroup1,
    selectedGroup2,
    collapsedSections.length,
    searchString,
    paginatedData,
    hideTasksName,
  ]);

  useDeepEffect(() => {
    _setCurrentPage(1);
  }, [selectedGroup1, selectedGroup2, searchString]);

  return [dataTree, currentPage, _setCurrentPage, _collapseAll, _expandAll, _toggleGroup];
};
