import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

import theme, { spacing } from 'design-system/theme';
import Bulb from 'design-system/atoms/DragonIcons/Bulb';
import Roadmap from 'design-system/atoms/DragonIcons/Roadmap';
import { PROJECT_CUSTOM_FIELD, CUSTOMER_REQUEST_CUSTOM_FIELD } from 'store/customFields/constants';

const BulbIcon = styled(Bulb)`
  path {
    fill: currentColor;
  }
`;

const ASSOCIATION_COLORS = {
  [PROJECT_CUSTOM_FIELD]: theme.palette.background.primary,
  [CUSTOMER_REQUEST_CUSTOM_FIELD]: theme.palette.newLayout.background.turquoise,
};

const ASSOCIATION_ICONS = {
  [PROJECT_CUSTOM_FIELD]: <Roadmap width="16px" height="16px" />,
  [CUSTOMER_REQUEST_CUSTOM_FIELD]: <BulbIcon width="18px" height="18px" />,
};

const AssociationsCellRenderer = params => {
  const { data, labels = {} } = params || {};
  const { associations = [] } = data || {};

  return (
    <Container>
      {associations
        .sort((a, b) => a.association_type.localeCompare(b.association_type))
        .map(association => (
          <Tooltip title={labels?.[association.association_type]} key={association.association_type}>
            <IconWrapper background={ASSOCIATION_COLORS[association.association_type]}>
              {ASSOCIATION_ICONS[association.association_type]}
            </IconWrapper>
          </Tooltip>
        ))}
    </Container>
  );
};

export default AssociationsCellRenderer;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${spacing()}px;
  padding-left: ${spacing()}px;
`;

const IconWrapper = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, background }) => background || theme.palette.background.primary};
  border-radius: ${({ theme }) => theme.shape.borderRadiusHigh}px;

  svg {
    color: ${({ theme }) => theme.palette.white};
  }
`;
