import React from 'react';

import { Bar } from 'react-chartjs-2';
import BaseChart from './BaseChart';

const DEFAULT_HORIZONTAL_BAR_OPTIONS = {
  indexAxis: 'y',
};

export default function BarChart(props) {
  const options = {
    ...props.options,
    ...(props.horizontal ? DEFAULT_HORIZONTAL_BAR_OPTIONS : {}),
  };

  return <BaseChart {...props} chart={Bar} options={options} />;
}
