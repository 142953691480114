// External dependencies
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

// Dragonboat depencies
import { filterActiveItens } from 'utils';

export function getState(state) {
  return state.skills;
}

export const getSkills = createCachedSelector(
  getState,
  (_, showArchived) => showArchived,
  (state, showArchived) => (showArchived ? (state || {}).skills : ((state || {}).skills || []).filter(filterActiveItens)),
)((_, showArchived) => `getSkills_${+showArchived}`);

export const getNormalizedSkills = createSelector(
  state => getSkills(state, true),
  state =>
    (state || []).reduce((objs, obj) => {
      objs[obj.id] = obj;
      return objs;
    }, {}),
);
