import io from 'socket.io-client';
import getRandomString from './getRandomString';

const DEFAULT_MESSAGE_TYPE = 'message';

export default class Socket {
  constructor() {
    this.socket = io(null);

    this.socket.on('reconnect_attempt', () => {});

    this.rooms = [];
  }

  onConnect(callback) {
    if (this.socket.connected) return callback();

    this.socket.on('connect', () => {
      setTimeout(callback, 250);
    });
  }

  onReconnect(callback) {
    this.socket.on('reconnect', () => {
      callback();
    });
  }

  join(roomId) {
    roomId = roomId || getRandomString();

    if (this.rooms.includes(roomId)) return roomId;

    this.socket.emit('join', {
      roomId,
    });
    return roomId;
  }

  joinRealtimeChangesTopic(group) { 
    if (this.rooms.includes(group)) return;

    this.socket.emit('join', {
      group,
    });

    this.rooms.push(group);
  }

  leaveRealtimeChangesTopic(group) {
    if (this.rooms.includes(group)) this.rooms = this.rooms.filter(entry => entry !== group);

    // Do not call socket leave because other components may still need this topic

    /* this.socket.emit('leave', {
      jwt: this.token,
      group,
    }); */
  }

  subscribe(
    cb = () => {},
    { messageType, isRealtimeChangesTopicHandler } = { messageType: DEFAULT_MESSAGE_TYPE, isRealtimeChangesTopicHandler: false },
  ) {
    if (isRealtimeChangesTopicHandler && this.hasSubscribe) return;
    this.socket.on(messageType || DEFAULT_MESSAGE_TYPE, cb);
    if (isRealtimeChangesTopicHandler) this.hasSubscribe = true;
  }

  unsubscribe(roomId) {
    this.socket.emit('leave', {
      roomId,
    });
  }

  destructor() {
    this.socket.off('message');
    this.socket.off('reconnect');

    this.hasSubscribe = false;
  }
}
