import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { groupByLvl1Options, groupByLvl2Options } from 'store/progress/consts';
import {
  setGroupByLevel1,
  setGroupByLevel2,
  setLevel1ClickedId,
  setLevel2ClickedId,
  setTableLevel,
  setProjectActiveId,
  setStartDate,
  setEndDate,
  setShowTargetAndPredictedLines,
} from 'store/progress';
import { makeGetProgressTableData, getProgressPagePreferences } from 'store/progress/selectors';

import useLoadProjects from 'hooks/projects/useLoadProjects';
import { PROGRESS_PAGE } from 'constants/filters';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';


const makeTableData = makeGetProgressTableData();

// const MAX_DATE_RANGE = 90;

const progressContainer = Component => {
  return props => {
    const dispatch = useDispatch();

    // hook to fetch projects
    const [loadedProjects] = useLoadProjects(PROGRESS_PAGE);

    const lsState = useSelector(state => state.progress);
    const systemFields = useSelector(state => state.organization.organization.system_fields_name);
    const hasHierarchy = useSelector(state => state.organization.organization.has_hierarchy);
    const hasBet = useSelector(state => state.organization.organization.has_bet);
    const hasProducts = useSelector(state => state.organization.organization.has_products);
    
    const progressPagePreferences = useSelector(getProgressPagePreferences);
    const tableData = useSelector(state => makeTableData(state, progressPagePreferences));

    const _groupByLvl1Options = groupByLvl1Options(systemFields, hasHierarchy, hasBet);
    const _groupByLvl2Options = groupByLvl2Options(systemFields, hasProducts);

    const _onChangeGroupBy = useCallback((key, val) => {
      if (!key || !val) return;

      const actions = {
        selectedGroupByLvl1: value => dispatch(setGroupByLevel1(value)),
        selectedGroupByLvl2: value => dispatch(setGroupByLevel2(value)),
      };

      if (!Object.keys(actions).includes(key)) return;

      actions[key](val);

      dispatch(setTableLevel(1));
      dispatch(setLevel1ClickedId(null));
      dispatch(setLevel2ClickedId(null));
      dispatch(setProjectActiveId(null));
    }, []);

    const onDateChange = useCallback(
      ({ start_date: startDate, end_date: endDate }) => {
        if (startDate) {
          dispatch(setStartDate(startDate.format()));
        }

        if (endDate) {
          dispatch(setEndDate(endDate.format()));
        }
      },
      [dispatch],
    );

    const onShowTargetAndPredictedLinesChange = value => dispatch(setShowTargetAndPredictedLines(value));

    if (!loadedProjects) return <PageLoading />;

    return (
      <Component
        {...props}
        groupByLvl1Options={_groupByLvl1Options}
        groupByLvl2Options={_groupByLvl2Options}
        onChangeGroupBy={_onChangeGroupBy}
        tableData={tableData}
        lsState={lsState}
        onDateChange={onDateChange}
        onShowTargetAndPredictedLinesChange={onShowTargetAndPredictedLinesChange}
      />
    );
  };
};

export default progressContainer;
