import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { prop, propOr } from 'ramda';

import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useScenariosListColumnsDefinitions from './useScenariosListColumnsDefinitions';
import filterScenariosList from './filterScenariosList';

import {
  setRoadmapVersionSelected,
  setScenariosDialogClose,
  setScenariosDialogOpen,
  updateRoadmapVersionDialogProperties,
} from 'store/roadmapVersions';

import useScenariosNavigation from 'hooks/useScenariosNavigation';
import { SCENARIO_SWIMLANE_PAGE } from 'constants/filters';
import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/userViews';
import { MAP_SCENARIO_PATH_PAGES } from 'constants/filters/pages';
import { getRowId } from 'design-system/molecules/AgGridReact-New/helpers';
import { selectIsScenariosDialogOpen } from 'store/roadmapVersions/selectors';

const getScenario = prop('data');

const getScenarioPageWithDefault = pageId => propOr(SCENARIO_SWIMLANE_PAGE, pageId)(MAP_SCENARIO_PATH_PAGES);

const useScenariosListDialog = () => {
  const dispatch = useDispatch();

  const path = getCurrentPath();
  const pageId = getPageIdFromPath(path);

  const isOpen = useSelector(selectIsScenariosDialogOpen);

  const openScenariosDialog = () => dispatch(setScenariosDialogOpen());
  const closeScenariosDialog = () => dispatch(setScenariosDialogClose());

  const [chartData, setChartData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const gridApi = useRef();

  const { roadmapVersions: scenarios } = useRoadmapVersions();

  const { navigateToScenarioModule } = useScenariosNavigation();

  const onSelectScenarioClick = useCallback(
    params => {
      const scenario = getScenario(params);

      if (!scenario) return;

      const scenarioPage = getScenarioPageWithDefault(pageId);

      dispatch(setRoadmapVersionSelected(scenario));

      const scenarioQueryParam = `scenario=${scenario.id}`;

      navigateToScenarioModule(scenarioPage, scenarioQueryParam);

      closeScenariosDialog();
    },
    [pageId],
  );

  const onEditScenarioClick = useCallback(scenario => {
    if (!scenario) return;

    dispatch(updateRoadmapVersionDialogProperties({ showSaveDialog: true, editRoadmapVersion: scenario }));
  }, []);

  const { columnDefs } = useScenariosListColumnsDefinitions({ onSelectScenarioClick, onEditScenarioClick });

  const gridOptions = {
    autoGroupColumnDef: {
      headerName: 'Title',
      cellRendererParams: {
        suppressCount: true,
      },
    },
    treeData: false,
    rowData: chartData,
    columnDefs,
    getRowId,
  };

  const onGridReady = useCallback(params => {
    gridApi.current = params.api;

    gridApi.current.sizeColumnsToFit();
  }, []);

  useEffect(() => {
    if (!scenarios) return [];

    setChartData(filterScenariosList(scenarios, searchQuery));
  }, [scenarios, searchQuery]);

  return {
    isOpen,
    openScenariosDialog,
    closeScenariosDialog,
    gridOptions,
    onGridReady,
    searchQuery,
    setSearchQuery,
    openSaveScenarioDialog: onEditScenarioClick,
  };
};

export default useScenariosListDialog;
