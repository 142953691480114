import React, { useCallback, useMemo, useState } from 'react';
import Xarrow from 'react-xarrows';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import NewDialog from 'design-system/organisms/NewDialog/index';

import useSummaryDependencyLinesManagement from 'hooks/useSummaryDependencyLinesManagement';

const XarrowContainer = styled.div`
  svg g path {
    ${({ $rotate }) => $rotate
      ? 'transform: rotate(180deg) translate(-1.25px, -1px);'
      : ''
    }
  }
`;

const ProjectDependencyLine = ({
  rotate,
  startId,
  endId,
  color,
  cpxOffset,
  description,
  sourceProjectId,
  targetProjectId,
}) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const { actions: { deleteProjectDependency } } = useSummaryDependencyLinesManagement();

  const onClickHandler = useCallback(
    () => setDialogIsOpen(true),
    [],
  );

  const onCloseDialogHandler = useCallback(
    () => setDialogIsOpen(false),
    [],
  );

  const onDeleteLinkHandler = useCallback(
    () => {
      deleteProjectDependency(sourceProjectId, targetProjectId);

      setDialogIsOpen(false);
    },
    [sourceProjectId, targetProjectId],
  );

  const divContainerProps = useMemo(
    () => ({
      onClick: onClickHandler,
    }),
    [onClickHandler],
  );

  return (
    <>
      <XarrowContainer $rotate={rotate}>
        <Xarrow
          start={startId}
          end={endId}
          color={color}
          strokeWidth={1}
          zIndex={1}
          _cpx1Offset={cpxOffset}
          _cpx2Offset={cpxOffset}
          divContainerProps={divContainerProps}
        />
      </XarrowContainer>
      <NewDialog
        open={dialogIsOpen}
        onClose={onCloseDialogHandler}
        title="Delete link?"
        actions={
          <>
            <Button onClick={onCloseDialogHandler}>
              Cancel
            </Button>
            <Button color="secondary" onClick={onDeleteLinkHandler}>
              Delete
            </Button>
          </>
        }
      >
        {description}
      </NewDialog>
    </>
  );
};

export default ProjectDependencyLine;