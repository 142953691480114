import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SaveIcon from '@material-ui/icons/Save';
import CompareIcon from '@material-ui/icons/Compare';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';


import { openDeleteScenarioDialog } from 'store/roadmapVersions';

import useScenarioToolbarControls from 'containers/ScenarioToolbarControls/hooks/useScenarioToolbarControls';

import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useSystemFields from 'hooks/useSystemFields';
import useScenariosNavigation from 'hooks/useScenariosNavigation';

const ScenarioToolbarControls = () => {
  const dispatch = useDispatch();

  const {
    openCompareSelectedRoadmapVersion,
    hasAnyUpdateToBeSaved,
    isRoadmapVersionCommittedWithGanttFlow,
    selectedRoadmapVersion,
  } = useRoadmapVersions();
  const { isNewDraftScenario, onClickSaveScenario, isCreatingOrViewingScenario } = useScenarioToolbarControls();
  const [getSystemFieldName] = useSystemFields();
  const { exitScenarioModule } = useScenariosNavigation();

  const openDeleteDialog = () => dispatch(openDeleteScenarioDialog(selectedRoadmapVersion));

  const renderDraftModeButtons = (
    <>
      <StyledButtonIcon
        id="save-draft-mode"
        title={`Save ${getSystemFieldName('scenario')}`}
        onClick={onClickSaveScenario}
        disabled={!hasAnyUpdateToBeSaved}
      >
        <SaveIcon />
      </StyledButtonIcon>

      <StyledButtonIcon title={`Exit ${getSystemFieldName('scenario')} Mode`} onClick={exitScenarioModule} id="exit-draft-mode">
        <ArrowBackIcon />
      </StyledButtonIcon>
    </>
  );

  const renderViewScenarioButtons = (
    <>
      <StyledButtonIcon
        title={`Compare ${getSystemFieldName('scenario', true)}`}
        onClick={openCompareSelectedRoadmapVersion}
        id="compare-scenario"
      >
        <CompareIcon />
      </StyledButtonIcon>
      {(!isRoadmapVersionCommittedWithGanttFlow || hasAnyUpdateToBeSaved) && (
        <StyledButtonIcon
          title={`Save ${getSystemFieldName('scenario')}`}
          onClick={onClickSaveScenario}
          id="save-scenario"
          disabled={!hasAnyUpdateToBeSaved}
        >
          <SaveIcon />
        </StyledButtonIcon>
      )}
      <StyledButtonIcon title={`Delete ${getSystemFieldName('scenario')}`} onClick={openDeleteDialog} id="delete-scenario">
        <DeleteIcon />
      </StyledButtonIcon>
    </>
  );

  if (!isRoadmapVersionCommittedWithGanttFlow && !isNewDraftScenario) return null;

  return (
    <Wrapper>
      {isNewDraftScenario && renderDraftModeButtons}
      {isCreatingOrViewingScenario && isRoadmapVersionCommittedWithGanttFlow && renderViewScenarioButtons}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledButtonIcon = styled(ButtonIcon)`
  &&&& {
    width: 38px;
    height: 38px;
  }
`;

export default ScenarioToolbarControls;
