import React from 'react';

import { AUTOCOMPLETE_FROM_SERVER_DELAY } from 'constants';
import LoadableAutocomplete from 'design-system/molecules/LoadableAutocomplete/index';

import ProjectType from 'containers/ProjectType';
import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';

function prependProjectIcon(suggestion) {
  return <ProjectType layer={suggestion.entity.layer} />;
}

export default ({ placeholder, options, loadProjects, ...other }) => {
  return (
    <LoadableAutocomplete
      placeholder={placeholder || TYPE_TO_SEARCH_PLACEHOLDER}
      suggestions={options}
      loadData={loadProjects}
      prependSuggestion={prependProjectIcon}
      loadDelay={AUTOCOMPLETE_FROM_SERVER_DELAY}
      {...other}
    />
  );
};
