import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'immutable';

import { ROADMAP, SUB_ROADMAP, DUPLICATED_ERROR_CODE } from 'config';
import useAppNotifications from 'hooks/useAppNotifications';
import useSystemFields from 'hooks/useSystemFields';
import { addSelectedItem, removeSelectedItem, saveGridConfig } from 'store/grids/actions';
import useOnGridRowSelected from 'design-system/molecules/AgGridReact-New/hooks/useOnGridRowSelected';
import { getDuplicatedToastErrorMessage } from 'utils';
import { stateKeys } from 'design-system/molecules/AgGridReact-New/helpers';
import useCommonGridEventHandlers from 'design-system/molecules/AgGridReact-New/hooks/useCommonGridEventHandlers';

import useGenericErrorDialogContext from 'hooks/useGenericErrorDialog';
import { VALIDATION_ERROR_CODE } from 'src/config';
import { path } from 'ramda';

const useSettingsGridEventHandlers = ({ settingType, items, focusField, actions, settingKey, idKey, forceUpdate = false }) => {
  const { addError } = useAppNotifications();
  const [getSystemFieldName] = useSystemFields();

  const dispatch = useDispatch();

  const collapsedGroups = useSelector(state =>
    (state.grids.getIn([settingType, stateKeys.COLLAPSED_GROUPS]) || new List()).toJS(),
  );

  const { openGenericErrorDialog } = useGenericErrorDialogContext();

  const createDuplicatedErrorNotification = newValue => {
    const message = getDuplicatedToastErrorMessage(
      getSystemFieldName(settingKey),
      newValue,
      getSystemFieldName(ROADMAP),
      getSystemFieldName(SUB_ROADMAP),
      settingType,
    );

    openGenericErrorDialog({ content: message });
  };

  const handleValidationError = (errorData, defaultMessage) => {
    const titleFailed = path(['_data', 'errors', 'title'], errorData);

    if (titleFailed) {
      addError({ id: VALIDATION_ERROR_CODE, message: `Failed to create, given title is too long` });
      return;
    }

    addError({ id: VALIDATION_ERROR_CODE, message: defaultMessage });
  };

  const handleError = (error, newValue, defaultMessage) => {
    const { data } = error.response;

    const errorHandlers = {
      [DUPLICATED_ERROR_CODE]: () => createDuplicatedErrorNotification(newValue),
      [VALIDATION_ERROR_CODE]: () => handleValidationError(data, defaultMessage),
    };

    const errorHandler = errorHandlers[data?.error_code];

    if (!errorHandler) {
      addError({ id: 'settings-item-creation', message: defaultMessage });

      return;
    }

    errorHandler();
  };

  // Cell

  const onCreateNewItemError = ({ error, newValue }) => {
    handleError(error, newValue, 'Creation failed');
  };

  const onUpdateItemError = ({ error, newValue }) => {
    handleError(error, newValue, 'Update failed');
  };

  const {
    onCellEditingStopped,
    onCellValueChanged,
    onRowDataUpdated,
    onColumnRowGroupChanged,
    onSortChanged,
    onFilterChanged,
    onRowDragEnter,
    onRowDragMove,
    onRowDragEnd,
    onRowDragLeave,
  } = useCommonGridEventHandlers({
    items,
    focusField,
    idKey,
    actions,
    forceUpdate,
    onCreateNewItemError,
    onUpdateItemError,
  });

  const onRowGroupOpened = useCallback(
    ({ node }) => {
      let updatedCollapsed = collapsedGroups || [];

      if (!node.expanded) {
        if (!updatedCollapsed.includes(node.key)) updatedCollapsed.push(node.key);
      } else {
        updatedCollapsed = updatedCollapsed.filter(i => i !== node.key);
      }

      dispatch(saveGridConfig(settingType, stateKeys.COLLAPSED_GROUPS, updatedCollapsed));
    },
    [collapsedGroups, saveGridConfig],
  );

  // Selection

  const select = item => dispatch(addSelectedItem(settingType, item));
  const unselect = itemId => dispatch(removeSelectedItem(settingType, itemId));

  const [onRowSelected] = useOnGridRowSelected(select, unselect);

  return {
    onCellEditingStopped,
    onCellValueChanged,
    onRowDataUpdated,
    onRowGroupOpened,
    onRowSelected,
    onColumnRowGroupChanged,
    onSortChanged,
    onFilterChanged,
    onRowDragEnter,
    onRowDragMove,
    onRowDragEnd,
    onRowDragLeave,
  };
};

export default useSettingsGridEventHandlers;
