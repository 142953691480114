import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { __, defaultTo, either, gt, length, isEmpty, isNil, map, pipe, split, trim } from 'ramda';

import { getJiraIssuesTypeMapping } from 'store/organization/selectors';

const defaultToEmptyArray = defaultTo([]);
const defaultToEmptyObject = defaultTo({});

const isNilOrEmpty = either(isNil, isEmpty);

const hasMoreThanOneType = pipe(defaultToEmptyArray, length, gt(__, 1));

const trimArrayOfStrings = map(trim);
const splitByComma = pipe(split(','), trimArrayOfStrings);

const useJiraIssueTypeMapping = (orgIntegrationId, layer) => {
  const issueTypeMapping = useSelector(getJiraIssuesTypeMapping(orgIntegrationId));

  const issueTypeMappingByLayer = useMemo(() => {
    if (isNil(layer)) {
      return [];
    }

    const currentLayerMapping = defaultToEmptyObject(issueTypeMapping)[layer];

    if (isNilOrEmpty(currentLayerMapping)) {
      return [];
    }

    return splitByComma(currentLayerMapping);
  }, [issueTypeMapping, layer]);

  const hasMultipleIssueTypesByLayer = useMemo(() => {
    return hasMoreThanOneType(issueTypeMappingByLayer);
  }, [issueTypeMappingByLayer]);

  return {
    issueTypeMapping,
    issueTypeMappingByLayer,
    hasMultipleIssueTypesByLayer,
  };
};

export default useJiraIssueTypeMapping;
