import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import priorities from 'store/customerRequests/constants/priority';
import statuses from 'store/customerRequests/constants/status';
import { getOrganizationSystemFieldsNames } from 'store/organization';
import { fetchRequestHistory, fetchRequestHistoryNextPage } from '../store';
import { selectRequestHistory, selectRequestHistoryMeta } from '../store/selectors';

const useRequestHistory = requestId => {
  const dispatch = useDispatch();

  const history = useSelector(selectRequestHistory);
  const historyMeta = useSelector(selectRequestHistoryMeta);
  const systemFields = useSelector(getOrganizationSystemFieldsNames);

  const customFormat = {
    status: value => statuses[value]?.label ?? value,
    priority: value => priorities[value]?.label ?? value,
  };

  const fetchNextPage = () => {
    if (historyMeta?.next) {
      dispatch(fetchRequestHistoryNextPage(requestId, historyMeta?.next));
    }
  };

  useEffect(() => {
    dispatch(fetchRequestHistory(requestId));
  }, []);

  return {
    history,
    hasNext: historyMeta?.next,
    fetchNextPage,
    systemFields,
    customFormat,
  };
};

export default useRequestHistory;
