import makeMetadataRoadmapsOptionsFormatter from 'utils/makeMetadataRoadmapsOptionsFormatter';

const getFormattedMetadataFieldOptions = (
  columnParams,
  fieldName,
  roadmapsKey,
  organization,
  organizationsAccessControl,
  includeCorpRoadmap = false,
  hasMetadataRoadmaps = false,
) => {
  const filteredOptions = columnParams?.context?.filteredMetadata?.[fieldName];

  if (!filteredOptions) return [];

  if (!hasMetadataRoadmaps) return filteredOptions;

  const roadmapId = columnParams?.data?.roadmap_id;
  const { roadmaps = [], roadmapsCorp = [] } = columnParams?.context?.filteredMetadata;

  const formatMetadataOptions = makeMetadataRoadmapsOptionsFormatter(
    organization,
    roadmapId,
    organizationsAccessControl,
    roadmaps,
    roadmapsCorp,
  );

  return formatMetadataOptions(`${roadmapsKey}_roadmaps`, filteredOptions, {
    needsObject: true,
    includeCorpRoadmap,
  });
};

export default getFormattedMetadataFieldOptions;
