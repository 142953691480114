import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isNull from 'lodash/isNull';
import noop from 'lodash/noop';
import { head, propEq } from 'ramda';

import { getProjectLightboxProjectIntegrations } from 'store/projectLightbox/selectors';
import { isProjectIntegrationsUninitializedById, syncProjectFromJira, updateProjectFromEpic } from 'store/integrations';
import useIntegrations from 'hooks/useIntegrations';
import useUserPermissions from 'hooks/useUserPermissions';
import { INTEGRATIONS_UPDATE_IDEA_FROM_INTEGRATION_OBJECT } from 'constants/integrations';
import { isUpdateProjectLoading as getIsUpdateProjectLoading } from 'store/projects/selectors';
import { getMappingByJiraTicketId } from 'utils/mapping';
import { getOrganization, getOrgJiraIntegrations } from 'store/organization';

const isExistingProject = project => project?.id && !project.$new;
const isMissingProjectIntegrations = (areProjectIntegrationsUninitializedById, projectIntegrations) =>
  areProjectIntegrationsUninitializedById || isNull(projectIntegrations);
const shouldFetchForProjectIntegrations = (project, isUninitialized, isLoading, projectIntegrations) =>
  isExistingProject(project) && isMissingProjectIntegrations(isUninitialized, projectIntegrations) && !isLoading;

// Constants
const INITIATIVE_TITLE_FIELD = 'initiativeTitle';
const BET_TITLE_FIELD = 'betTitle';
const PARENT_ID_FIELD = 'parent_id';

/**
 * @function useLightboxProjectIntegrations Hook that manages project integrations
 * @param  {Object} project                     Current project opened on the lightbox
 * @param  {Boolean} checkUpdateFromIntegration Controls if it should run updates from integrations or not
 * @param  {Function} jiraFieldSyncCallback     Callback for jira sync fields
 * @return {Object} Returns object containing list of integrations, it's state and orgIntegrations for the project
 */
const useLightboxProjectIntegrations = (project, checkUpdateFromIntegration = false, jiraFieldSyncCallback = noop) => {
  const dispatch = useDispatch();
  // internal state
  const [gettingDataFields, setGettingDataFields] = useState(null);
  // Selectors
  const projectIntegrations = useSelector(state => getProjectLightboxProjectIntegrations(state));
  const areProjectIntegrationsUninitializedById = useSelector(state =>
    isProjectIntegrationsUninitializedById(state, project?.id),
  );
  const isUpdateProjectLoading = useSelector(getIsUpdateProjectLoading);
  const jiraIntegrations = useSelector(getOrgJiraIntegrations);
  const organization = useSelector(state => getOrganization(state));
  // Hooks
  const { isSuperAdmin } = useUserPermissions();
  const { ideasOrgIntegrations: orgIntegrations, isProjectIntegrationsLoading, fetchProjectIntegrations } = useIntegrations();

  const projectIntegration = projectIntegrations && head(projectIntegrations);

  // fetch org integrations and project integrations if anything changes
  useEffect(() => {
    if (
      shouldFetchForProjectIntegrations(
        project,
        areProjectIntegrationsUninitializedById,
        isProjectIntegrationsLoading,
        projectIntegrations,
      )
    ) {
      fetchProjectIntegrations(project.id);
    }
  }, [areProjectIntegrationsUninitializedById, project, isProjectIntegrationsLoading, projectIntegrations]);

  const syncWithIntegrationObject = useCallback(() => {
    if (!project?.id || !checkUpdateFromIntegration || isUpdateProjectLoading) {
      return;
    }
    const orgIntegration = orgIntegrations?.find(propEq('id', projectIntegration.org_integration_id));

    if (INTEGRATIONS_UPDATE_IDEA_FROM_INTEGRATION_OBJECT.includes(orgIntegration?.integrationType)) {
      dispatch(updateProjectFromEpic(project.id, orgIntegration?.integrationType, orgIntegration?.id));
    }
  }, [project?.id, projectIntegration, dispatch, orgIntegrations, checkUpdateFromIntegration, isUpdateProjectLoading]);

  const _syncProjectFromJiraIntegration = useCallback(
    (integration, mapping = [], autoSave = false) => {
      if (isSuperAdmin) return;

      const fieldsToSync = mapping
        .filter(c => !!c.dbAutoSync)
        .map(c => {
          if (c.field === INITIATIVE_TITLE_FIELD || c.field === BET_TITLE_FIELD) {
            return PARENT_ID_FIELD;
          }

          return c.field;
        });

      setGettingDataFields(fieldsToSync);
      dispatch(syncProjectFromJira(project.id, integration.id, autoSave)).then(res => {
        jiraFieldSyncCallback(res, fieldsToSync);
        setGettingDataFields(null);
      });
    },
    [isSuperAdmin, jiraFieldSyncCallback, project?.id],
  );

  const shouldSyncWithJira = useMemo(() => {
    return project && project.jira && checkUpdateFromIntegration;
  }, [project, checkUpdateFromIntegration]);

  const projectJiras = useMemo(() => project?.Jiras, [project]);

  useEffect(() => {
    // It will sync data from jira when lightbox is opened
    if (shouldSyncWithJira) {
      // Get the Jira integration configuration
      const integration = jiraIntegrations?.find(propEq('id', project.jira.orgIntegration_id));
      const jiraConfig = integration && integration.data;

      // Get corresponding mapping from the config based on the jira ticket id
      const mapping = getMappingByJiraTicketId(jiraConfig, project.jira.key, project.layer);
      // Verify if jira configuration is valid and has any auto sync fields to be updated
      const hasJiraMapping = !!mapping.length && mapping.some(c => !!c.jiraAutoSync);
      const hasValidJira = jiraConfig && jiraConfig.jira_tested;
      // Validate if jira is missing webhook configuration or if organization has webhooks disabled
      const webhookDisabled = (jiraConfig && !jiraConfig.webhooks) || !organization.has_webhooks;

      // Should only update if there is a valid configuration, has fields to map and webhooks are disabled
      if (hasValidJira && hasJiraMapping && webhookDisabled) {
        _syncProjectFromJiraIntegration(integration, mapping, true);
      }
    }
  }, [projectJiras, shouldSyncWithJira]);

  useEffect(() => {
    syncWithIntegrationObject();
  }, [project?.id, projectIntegration?.id]);

  useEffect(() => {
    syncWithIntegrationObject();
  }, []);

  return {
    projectIntegration,
    projectIntegrations,
    isProjectIntegrationsLoading,
    orgIntegrations,
    gettingDataFields,
  };
};

export default useLightboxProjectIntegrations;
