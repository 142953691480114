import { useMemo } from 'react';
import { defaultTo } from 'ramda';
import { useSelector } from 'react-redux';
import flatten from 'lodash/flatten';

import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useRoadmapVersionsSupportedFields from 'routes/RoadmapVersions/hooks/useRoadmapVersionsSupportedFields';
import useSystemFields from 'hooks/useSystemFields';
import useProjectGroups from 'hooks/useProjectGroups';
import useProjectsLocalSearch from 'hooks/projects/useProjectsLocalSearch';
import useBaseProjectsForComparison from 'routes/RoadmapVersions/hooks/useBaseProjectsForComparison';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useEnrichedSelectedGroupsFromState from 'hooks/grid/useGridSelectedGroups';

// eslint-disable-next-line max-len
import makeFieldKeyForRoadmapVersionProjectField from '../helpers/makeFieldKeyForRoadmapVersionProjectField';
import { compareDatesByUnit } from 'routes/RoadmapVersions/utils/hideSameRowComparisonUtils';

import { COMPARE_ROADMAP_VERSIONS } from 'store/grids';
import { getGridConfigValue } from 'store/grids/selectors';
import { getDisplayLayer, usePortfolioMode } from 'store/filters/selectors';
import { getOrgHasMetadataRoadmaps as getOrgHasMetadataRoadmapsSelector } from 'store/organization';

import filterDuplicateProjectValuesByFieldIds from 'routes/RoadmapVersions/utils/filterDuplicateProjectValuesByFieldIds';
import { DATA_TYPES } from 'utils/roadmapVersions/supportedFieldsUtils';
import setIdeasHierarchyPath from 'utils/setIdeasHierarchyPath';

import { COMPARE_SCENARIOS_LIST_PAGE } from 'constants/filters';

const defaultToEmptyArray = defaultTo([]);
const defaultToEmptyObject = defaultTo({});
const defaultToEmptyString = defaultTo('');

const viewType = COMPARE_ROADMAP_VERSIONS;
const pageId = COMPARE_SCENARIOS_LIST_PAGE;

const getFieldKeysAndOperators = (fieldIds, roadmapVersionFields) => {
  let fieldKeys = [];
  let fieldKeyComparison = {};

  roadmapVersionFields.forEach(({ key, type }) => {
    if (fieldIds.includes(key)) {
      fieldKeys = [...fieldKeys, key];

      const comparator = type === DATA_TYPES.DATE ? { [key]: compareDatesByUnit } : {};

      fieldKeyComparison = { ...fieldKeyComparison, ...comparator };
    }
  });

  return { fieldKeys, fieldKeyComparison };
};

const useProjectsWithRoadmapVersions = (fieldIds = [], hideRowsWithSameData = false) => {
  const displayLayer = useSelector(getDisplayLayer);
  const bulkDelete = useSelector(state => getGridConfigValue(state, viewType, 'bulkDelete'));
  const searchText = useSelector(state => getGridConfigValue(state, viewType, 'searchText'));
  const portfolioMode = useSelector(state => usePortfolioMode(state));
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmapsSelector);

  const [getSystemFieldName] = useSystemFields();
  const { projects } = useBaseProjectsForComparison(displayLayer);
  const { roadmapVersionsFields } = useRoadmapVersionsSupportedFields();
  const { getDefaultRoadmapTitleForMetadataItem } = useOrganizationsAccessControl();
  const { roadmapVersions, roadmapVersionsProjects, selectedRoadmapVersionIds } = useRoadmapVersions();
  const { selectedGroup1, selectedGroup2, selectedGroup3 } = useEnrichedSelectedGroupsFromState(viewType);

  const projectsInAllLayers = flatten(Object.values(projects));
  const [filterBySearchString] = useProjectsLocalSearch(projectsInAllLayers, searchText);
  const projectsFiltersMemoized = useMemo(() => [filterBySearchString], [filterBySearchString]);

  const isSingleLayerGroupedBy = !portfolioMode && !!selectedGroup1?.key;

  // adds the version values to the projects and ignores/filters out the projects that have the same values if toggle is on
  const projectsWithVersionDataFiltered = useMemo(() => {
    const { fieldKeys, fieldKeyComparison } = getFieldKeysAndOperators(fieldIds, roadmapVersionsFields);

    return Object.entries(projects).reduce((acc, [key, projects]) => {
      const projectsToProcess = hideRowsWithSameData
        ? filterDuplicateProjectValuesByFieldIds(
            projects,
            selectedRoadmapVersionIds,
            roadmapVersionsProjects,
            fieldKeys,
            fieldKeyComparison,
          )
        : projects;

      const projectsWithVersionData = projectsToProcess.map(project => {
        const projectVersionFields = roadmapVersions.reduce((acc, roadmapVersion) => {
          const currentVersionProjects = defaultToEmptyArray(roadmapVersionsProjects[roadmapVersion.id]);
          const projectVersion = defaultToEmptyObject(currentVersionProjects.find(x => x.id === project.id));

          return {
            ...acc,
            ...roadmapVersionsFields.reduce(
              (acc, roadmapVersionField) => ({
                ...acc,
                [makeFieldKeyForRoadmapVersionProjectField(roadmapVersion.id, roadmapVersionField.key)]: defaultToEmptyString(
                  projectVersion[roadmapVersionField.key],
                ),
              }),
              {},
            ),
          };
        }, {});

        return { ...project, ...projectVersionFields };
      });

      return {
        ...acc,
        [key]: projectsWithVersionData,
      };
    }, {});
  }, [
    projects,
    fieldIds,
    hideRowsWithSameData,
    roadmapVersionsFields,
    roadmapVersionsProjects,
    selectedRoadmapVersionIds,
    roadmapVersions,
  ]);

  // creates the groups with the projects that needs (if toggle is on projects with same data are filtered out)
  const projectGroups = useProjectGroups({
    projectsFilters: projectsFiltersMemoized,
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    withHierarchy: portfolioMode,
    customAllProjectsByLayer: projectsWithVersionDataFiltered,
    id: pageId,
  });

  // set the path so the grid can render groups properly
  const processedData = useMemo(() => {
    let data = projectsWithVersionDataFiltered?.[displayLayer] || [];

    if (portfolioMode || isSingleLayerGroupedBy) {
      data = setIdeasHierarchyPath(
        projectsWithVersionDataFiltered,
        displayLayer,
        getSystemFieldName('initiative', false),
        getSystemFieldName('bet', false),
        isSingleLayerGroupedBy,
        projectGroups,
        hasMetadataRoadmaps,
        getDefaultRoadmapTitleForMetadataItem,
      );
    }
    data = (data || []).map(d => ({ ...d, selectable: bulkDelete }));

    return data;
  }, [projectsWithVersionDataFiltered, projectGroups, hasMetadataRoadmaps, portfolioMode, isSingleLayerGroupedBy]);

  return processedData;
};

export default useProjectsWithRoadmapVersions;
