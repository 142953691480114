import { createSelector } from 'reselect';

// Slice state
export const getState = state => state.metricsAllocation;

const EMPTY_OBJECT = {};

const selectAllocationByMetricId = createSelector(getState, state => state.allocationByMetricId || EMPTY_OBJECT);

export const selectIsLoadingMetricChart = createSelector(
  selectAllocationByMetricId,
  (_, metricId) => metricId,
  (allocationByMetricId, metricId) => {
    if (metricId in allocationByMetricId) {
      return allocationByMetricId[metricId].loading;
    }
    return true;
  },
);

export const selectMetricChartData = createSelector(
  selectAllocationByMetricId,
  (_, metricId) => metricId,
  (allocationByMetricId, metricId) => {
    const metricAllocation = allocationByMetricId[metricId];

    return metricAllocation?.data;
  },
);

export const selectMetricAllocationTimePeriod = createSelector(
  getState,
  metricsState => metricsState?.metricAllocationTimePeriod,
);
