import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setIsScenarioRoute } from 'store/app/actions';

const SetScenarioRouteContext = createContext({});

// By now we only use the context to store if the user is in a scenario route
// In the future we should use this context provide data that is needed in scenario routes
const SetScenarioRouteProvider = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsScenarioRoute(true));
    return () => dispatch(setIsScenarioRoute(false));
  }, []);

  return <SetScenarioRouteContext.Provider>{children}</SetScenarioRouteContext.Provider>;
};

/**
 * @function useSetScenarioRoute
 *
 * hook to use the set scenario route context
 * needs to be wrapped on SetScenarioRouteProvider
 *
 * @returns {Object}
 */
const useSetScenarioRoute = () => useContext(SetScenarioRouteContext);

export { SetScenarioRouteProvider };

export default useSetScenarioRoute;
