// External dependencies
import React, { useRef } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import ShowFieldsIcon from 'design-system/atoms/ShowFieldsIcon';
import ImportIcon from '@material-ui/icons/CloudUploadOutlined';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import CreateIcon from '@material-ui/icons/Create';
import PieChartOutlinedIcon from '@material-ui/icons/PieChartOutlined';
import CompareIcon from '@material-ui/icons/Compare';
import { isFunction } from 'lodash';

// Dragonboat dependencies
import TabButton from 'design-system/atoms/TabButton';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import Dropdown from 'design-system/molecules/Dropdown/index';
import CopyAllIcon from 'design-system/atoms/CopyAllIcon/index';

import { spacing } from 'design-system/theme';
import { ADMIN_USER, OWNER_USER, LEADER_USER, EDITOR_USER, MANAGER_USER } from '@dragonboat/permissions';

import ImportIdeasDialog from 'containers/ImportIdeasDialog';
import { checkRolePermission } from 'containers/UserPermission/utils';
import ShareView from 'containers/ShareView';
import ForecastByHeadcount from 'containers/ForecastByHeadcount';
import { ROW_HEIGHT_OPTIONS } from 'constants/grid';
import LocalSearchInput from 'containers/LocalSearchInput';
import AboveTheLineViewingSelector from 'containers/AboveTheLineViewingSelector/AboveTheLineViewingSelector';

import useSelectedTeamsOnForecastHeadcount from 'hooks/forecast/useSelectedTeamsOnForecastHeadcount';
import useOrganizations from 'hooks/useOrganizations';
import useSystemFields from 'hooks/useSystemFields';

import useGoToCompareVersions from 'hooks/useGoToCompareVersions';

import { DEFAULT_GROUP_OPTION } from 'store/projects/helpers/groupOptions';

import { FORECAST_BY_HEADCOUNT } from 'constants/forecast';
import { isForecastPage } from 'utils/pages';

import GridShowColumns from './GridShowColumns';
import ConfirmBulkDialog from './ConfirmBulkDialog';
import ForecastByAutocomplete from './ForecastByAutocomplete';
import GroupByControls from './GroupByControls';
import ShowMyItemsToggle from 'containers/ShowMyItemsToggle';

import { DRAFT_MODE_BUTTON_PENDO_ID } from 'constants/common';
import EditIcon from 'design-system/atoms/EditIcon';
import getCurrentPath from 'utils/getCurrentPath';
import useRoadmapVersions from 'hooks/useRoadmapVersions';
import { CreateRoadmapSnapshotButton } from 'features/RoadmapHistory/components';
import TreeViewToggle from 'containers/IdeasList/IdeasListControlsBar/TreeViewToggle/TreeViewToggle';

const SCENARIO_BASE_PATH = 'scenario';
const isScenarioModule = path => path.includes(SCENARIO_BASE_PATH);

export default props => {
  const {
    currentUser,
    pageId,
    lsState,
    onMoreDropdownClick,
    onUpdateGridConfig,
    viewType,
    getGridApi,
    onConfirmBulkDelete,
    onConfirmBulkArchive,
    onBulkUpdate,
    onMerge,
    onCancelBulkDelete,
    groupByOptions,
    groupByGroup2Options,
    groupByGroup3Options,
    onGroupLevelChange,
    portfolioMode,
    warning,
    onWarningClick,
    showPdlcFields,
    showEstimatesFields,
    showAdditionalFields,
    showCustomFields,
    showColumnsDialogVisible,
    onCloseShowColumnsDialog,
    enhanceOnChangeVisibleColumns,
    hasViews = false,
    hasImport = false,
    hasBulkUpdate = false,
    hasAllocationReport = false,
    hasBulkDelete = false,
    toggleLocalMode,
    isCreatingOrViewingScenario,
    hasScenariosEnabled,
    renderAfterViewAdditionalButtons,
    openScenariosDialog,
    middleControlsRenderer,
    handleToggleUncommittedProjects,
    hasGroupBy = false,
    hasGroupByLevel2 = true,
    hasGroupByLevel3 = true,
    showMyItemsOnly = false,
    hasShowMyItemFilter = false,
    hasRoadmapHistory = false,
    renderBeforeViewAdditionalButtons,
    showMyItemsTitle,
    currentPageIsEstimates,
    customShowFieldsDialogComponent,
    GroupByControlsComponent = GroupByControls,
    viewsDropdownTabs,
    hasTreeView = false,
    isTreeView = false,
  } = props;

  const { bulkDelete, selectedItems, selectedGroup1, selectedGroup2, selectedGroup3, forecastBy, showUncommittedProjects } =
    lsState;

  const [getSystemFieldName] = useSystemFields();
  const { unselectedOverbookedTeams, unselectedUnderbookedTeams, onSelectOverbookedTeam, onSelectUnderbookedTeam } =
    useSelectedTeamsOnForecastHeadcount(pageId);
  const { hasForecastByHeadcount } = useOrganizations();

  const path = getCurrentPath();

  const { isRoadmapVersionSelected } = useRoadmapVersions();

  const moreOptionsRef = useRef(null);

  const headcountTeamsFilterProps = {
    unselectedOverbookedTeams,
    unselectedUnderbookedTeams,
    onSelectOverbookedTeam,
    onSelectUnderbookedTeam,
    selectedGroup: selectedGroup1 ?? DEFAULT_GROUP_OPTION,
  };

  const isForecastList = isForecastPage(viewType);
  const isNotForecastList = !isForecastPage(viewType);
  const showForecastByAutocomplete = isForecastPage(viewType) && hasForecastByHeadcount;
  const isForecastByHeadcountSelected = forecastBy === FORECAST_BY_HEADCOUNT;
  const shouldShowCommittedToggle = isForecastPage(viewType) && !isForecastByHeadcountSelected;

  const shouldShowGroupLevel2 = hasGroupByLevel2 && (isNotForecastList || (isForecastList && !isForecastByHeadcountSelected));
  const shouldShowGroupLevel3 = hasGroupByLevel3 && isNotForecastList;

  const { navigateToCompareVersions } = useGoToCompareVersions();

  const isGridLoaded = isFunction(getGridApi) && getGridApi();

  const moreOptions = [
    {
      id: 'show-columns',
      key: 'show-columns',
      title: (
        <span>
          <ShowFieldsIcon style={{ fontSize: '24px', verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Show Fields</span>
        </span>
      ),
    },
    hasImport && {
      id: 'import',
      key: 'import',
      title: (
        <span>
          <ImportIcon style={{ verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Import</span>
        </span>
      ),
      // TODO: PERMISSION
      hide: !checkRolePermission('IMPORT_IDEAS', currentUser),
    },
    {
      id: 'export',
      key: 'export',
      title: (
        <span>
          <DownloadIcon style={{ verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Export</span>
        </span>
      ),
      hide: !isGridLoaded,
    },
    hasBulkUpdate &&
      !isCreatingOrViewingScenario && {
        id: 'bulk-update',
        key: 'bulk-update',
        title: (
          <span>
            <CreateIcon style={{ verticalAlign: 'bottom' }} />
            <span style={{ paddingLeft: 13 }}>Bulk Update</span>
          </span>
        ),
        // TODO: PERMISSION
        hide: ![ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER].includes(currentUser.role_id),
      },
    hasAllocationReport && {
      id: 'allocation-report',
      key: 'allocation-report',
      title: (
        <span>
          <PieChartOutlinedIcon style={{ verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Allocation Report</span>
        </span>
      ),
    },
    ...(hasScenariosEnabled && !isScenarioModule(path)
      ? [
          {
            id: 'draft-mode',
            key: 'draft-mode',
            onClick: toggleLocalMode,
            title: (
              <span id={DRAFT_MODE_BUTTON_PENDO_ID}>
                <EditIcon style={{ verticalAlign: 'bottom' }} />
                <span style={{ paddingLeft: 13 }}>Create {getSystemFieldName('scenario')}</span>
              </span>
            ),
            // TODO: PERMISSION
            hide: ![ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER].includes(currentUser.role_id),
          },
        ]
      : []),
    ...(hasScenariosEnabled
      ? [
          {
            id: 'compareRoadmapVersions',
            key: 'compareRoadmapVersions',
            onClick: navigateToCompareVersions,
            title: (
              <span>
                <CompareIcon style={{ verticalAlign: 'bottom' }} />
                <span style={{ paddingLeft: 13 }}>Compare {getSystemFieldName('scenario', true)}</span>
              </span>
            ),
          },
          {
            id: 'show-scenarios-list-dialog',
            key: 'show-scenarios-list-dialog',
            onClick: openScenariosDialog,
            title: (
              <span>
                <CopyAllIcon style={{ verticalAlign: 'bottom' }} />
                <span style={{ paddingLeft: 13 }}>View {getSystemFieldName('scenario', true)}</span>
              </span>
            ),
          },
        ]
      : []),
  ].filter(o => o && !o.hide);

  const renderGroupByControls = (renderGroupByLabel = true) => (
    <GroupByControlsComponent
      hasGroupBy={hasGroupBy}
      shouldShowGroupLevel2={shouldShowGroupLevel2}
      shouldShowGroupLevel3={shouldShowGroupLevel3}
      selectedGroup1={selectedGroup1}
      selectedGroup2={selectedGroup2}
      selectedGroup3={selectedGroup3}
      groupByOptions={groupByOptions}
      groupByGroup2Options={groupByGroup2Options}
      groupByGroup3Options={groupByGroup3Options}
      onGroupLevelChange={onGroupLevelChange}
      renderGroup1Label={renderGroupByLabel}
    />
  );

  const renderTreeViewControls = () => (
    <>
      <StyledTreeViewToggle isTreeView={isTreeView} onChange={() => onUpdateGridConfig('isTreeView', true)} />
      <StyledTabButton onClick={() => onUpdateGridConfig('isTreeView', false)} active={hasTreeView && !isTreeView}>
        Group by
      </StyledTabButton>
      {!isTreeView && renderGroupByControls(false)}
    </>
  );

  return (
    <Wrapper>
      <ControlsContainer>
        <LeftControls>
          <>
            {hasTreeView ? renderTreeViewControls() : renderGroupByControls()}
            {showForecastByAutocomplete && (
              <>
                <ForecastByAutocomplete pageId={pageId} viewType={viewType} />
                {isForecastByHeadcountSelected && (
                  <ForecastByHeadcount pageId={pageId} teamsFilterProps={headcountTeamsFilterProps} />
                )}
              </>
            )}
            {shouldShowCommittedToggle && (
              <AboveTheLineViewingSelector
                onValueChange={handleToggleUncommittedProjects}
                selectedValue={showUncommittedProjects}
              />
            )}
          </>
        </LeftControls>
        {warning && <WarningMsg onClick={() => onWarningClick()}>{warning.message}</WarningMsg>}
        {middleControlsRenderer && middleControlsRenderer()}
        <RightControls>
          {hasBulkDelete && bulkDelete && (
            <BulkActions>
              <ConfirmBulkDialog
                // TODO: PERMISSION
                hideBulkDelete={[ADMIN_USER, MANAGER_USER, EDITOR_USER].includes(currentUser.role_id)}
                selectedItems={selectedItems}
                onConfirmBulkDelete={onConfirmBulkDelete}
                onConfirmBulkArchive={onConfirmBulkArchive}
                bulkUpdate={onBulkUpdate}
                onMerge={onMerge}
                portfolioMode={portfolioMode}
                getSystemFieldName={getSystemFieldName}
              />
              <Button onClick={onCancelBulkDelete}>Cancel</Button>
            </BulkActions>
          )}

          <Controls>
            <LocalSearchInput />
            {hasRoadmapHistory && <CreateRoadmapSnapshotButton WrapperComponent={ControlItem} />}
            {renderBeforeViewAdditionalButtons && renderBeforeViewAdditionalButtons(ControlItem)}
            {hasShowMyItemFilter && (
              <ControlItem>
                <ShowMyItemsToggle
                  showMyItemsOnly={showMyItemsOnly}
                  updateShowMyItemsOnly={() => onUpdateGridConfig('showMyItemsOnly', !showMyItemsOnly)}
                  showMyItemsTitle={showMyItemsTitle}
                />
              </ControlItem>
            )}
            {hasViews && !isCreatingOrViewingScenario && (
              <ControlItem>
                <ShareView pageId={pageId} viewsDropdownTabs={viewsDropdownTabs} id="view-icon" />
              </ControlItem>
            )}
            {renderAfterViewAdditionalButtons && renderAfterViewAdditionalButtons()}
            <ControlItem>
              <Dropdown
                label="Row height"
                placeholder={
                  <ButtonIcon title="Row height" data-cy="grid-row-height">
                    <FormatLineSpacingIcon />
                  </ButtonIcon>
                }
                options={ROW_HEIGHT_OPTIONS}
                onChange={(e, { id }) => {
                  onUpdateGridConfig('rowHeight', id);
                }}
                isButton={false}
              />
            </ControlItem>
            {isRoadmapVersionSelected && (
              <ControlItem>
                <ButtonIcon
                  title="Show fields"
                  data-cy="grid-show-fields"
                  onClick={e => onMoreDropdownClick(e, { id: 'show-columns' })}
                >
                  <ShowFieldsIcon />
                </ButtonIcon>
              </ControlItem>
            )}
            {!isRoadmapVersionSelected && (
              <ControlItem ref={moreOptionsRef}>
                <Dropdown
                  placeholder={
                    <ButtonIcon data-cy="ideas-more-options">
                      <MoreHorizIcon />
                    </ButtonIcon>
                  }
                  options={moreOptions}
                  onChange={onMoreDropdownClick}
                  isButton={false}
                  MenuListProps={{
                    style: { maxHeight: '500px', overflowY: 'auto' },
                  }}
                />
              </ControlItem>
            )}
          </Controls>
          {showColumnsDialogVisible && (
            <GridShowColumns
              viewType={viewType}
              getGridApi={getGridApi}
              showPdlcFields={showPdlcFields}
              showEstimatesFields={showEstimatesFields}
              showAdditionalFields={showAdditionalFields}
              showCustomFields={showCustomFields}
              isOpen={showColumnsDialogVisible}
              onClose={onCloseShowColumnsDialog}
              enhanceOnChangeVisibleColumns={enhanceOnChangeVisibleColumns}
              currentPageIsEstimates={currentPageIsEstimates}
              DialogComponent={customShowFieldsDialogComponent}
            />
          )}
        </RightControls>
      </ControlsContainer>
      <ImportIdeasDialog />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ControlsContainer = styled.div`
  margin: 0 ${spacing(3.25)}px 0 ${spacing(5.5)}px;
  min-height: ${props => props.theme.sizing.toolbar.height};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing(1.25)}px;
`;

const Controls = styled.div`
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const LeftControls = styled.div`
  &&&& {
    display: flex;
    align-items: center;
  }
`;
const RightControls = styled.div`
  &&&& {
    display: flex;
  }
`;

const StyledTreeViewToggle = styled(TreeViewToggle)`
  margin-right: ${spacing()}px;
`;

const StyledTabButton = styled(TabButton)`
  margin-right: ${spacing()}px;
`;

const ControlItem = styled.div`
  width: 46px;
  display: flex;

  &:not(:last-of-type) {
    margin-right: ${spacing(0.65)}px;
  }
`;

const BulkActions = styled.div`
  &&&& {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: ${spacing(2.5)}px;
    margin-top: ${spacing(0.625)}px;
  }
`;

const WarningMsg = styled.a`
  color: red;
`;
