import pluralize from 'pluralize';

import { BASE_ROW_HEIGHT } from 'constants/grid';
import { PRODUCT_1_KEY } from 'constants/roadmaps';
import { ROADMAP, METADATA_LEVELS } from 'constants/common';
import { GRID_LAYOUT_ROW_HEIGHT, GRID_MAX_ROWS, GRID_MIN_ROWS, WIDGET_HEIGHT_OFFSET } from '../constants/widgetsConfig';

const PORTFOLIO_LABEL = 'Portfolio';

const ROADMAP_ENTITY_TYPE_LABEL_MAPPER = {
  [METADATA_LEVELS.LEVEL_1]: ROADMAP,
  [METADATA_LEVELS.LEVEL_2]: PRODUCT_1_KEY,
};

// Using the length of 'path' to check if row is parent since there isn't a valid property for that
export const countParentRowsForGrids = rows => rows.filter(r => r.path.length === 1).length;

export const getWidgetHeight = (dataLength = 0) => {
  if (!dataLength) return 0;

  const numOfGridItems = Math.max(Math.min(dataLength, GRID_MAX_ROWS), GRID_MIN_ROWS);
  const gridItemsHeight = BASE_ROW_HEIGHT * numOfGridItems;

  const widgetHeight = gridItemsHeight / GRID_LAYOUT_ROW_HEIGHT;

  return widgetHeight + WIDGET_HEIGHT_OFFSET;
};

export const getWidgetTitleForRoadmapLevel = (activeRoadmap, entity, getSystemFieldName) => {
  const roadmapType = ROADMAP_ENTITY_TYPE_LABEL_MAPPER[activeRoadmap?.type];
  const titleSuffix = roadmapType ? getSystemFieldName(roadmapType) : PORTFOLIO_LABEL;
  const entityTitle = pluralize(getSystemFieldName(entity));

  return `${titleSuffix} ${entityTitle}`;
};
