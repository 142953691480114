import { useMemo, useCallback, useEffect } from 'react';
import { pipe, prop, defaultTo, equals, isEmpty, isNil, not, pluck, propEq } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  getOrganizationAccessControlData,
  isOrganizationAccessControlUninitialized,
  isOrganizationAccessControlLoading,
  getIsDodActive,
  getAllReaderTokens,
  getIsParentDragon,
} from 'store/accessControl/selectors';
import {
  getOrganization,
  getOrganizationHasDodShareProjects,
  hasMultiLevelPortfolioMetadata as getHasMultiLevelPortfolioMetadata,
  getOrgBillingStatus,
  getOrgHasOneClickPlan,
} from 'store/organization';
import {
  addOrganizationAccessControl,
  fetchOrganizationAccessControl,
  removeOrganizationAccessControl,
  createOrganizationReaderToken,
} from 'store/accessControl';
import { getAllRoadmaps, getCorpAccountRoadmap } from 'store/roadmaps/selectors';

const DEFAULT_METADATA_ROADMAP_TITLE = 'Portfolio';
const PARENT_ORGANIZATION_ID = 'parent_organization_id';
const CHILD_ORGANIZATION_ID = 'child_organization_id';
const PORTFOLIO_PLACEHOLDER_OBJECTIVE_ID = 'portfolio_placeholder_objective_id';
const defaultToEmptyArray = defaultTo([]);
const defaultToEmptyObject = defaultTo([]);
const getParentOrganizationIdProp = prop(PARENT_ORGANIZATION_ID);
const isNotEmpty = pipe(defaultToEmptyArray, isEmpty, not);
const isNotNil = pipe(isNil, not);
const extractPortfolioObjectiveIds = pluck(PORTFOLIO_PLACEHOLDER_OBJECTIVE_ID);

const useOrganizationsAccessControl = () => {
  const dispatch = useDispatch();

  const organizationAccessControlData = defaultToEmptyArray(useSelector(state => getOrganizationAccessControlData(state)));
  const isOrgAccessControlUninitialized = useSelector(state => isOrganizationAccessControlUninitialized(state));
  const isOrgAccessControlLoading = useSelector(state => isOrganizationAccessControlLoading(state));
  const isDodActive = useSelector(state => getIsDodActive(state));
  const isParentDragon = useSelector(getIsParentDragon);
  const organization = useSelector(state => getOrganization(state));
  const hasDodShareProjects = useSelector(state => getOrganizationHasDodShareProjects(state));
  const childOrganizationsReaderTokens = useSelector(state => getAllReaderTokens(state));
  const hasMultiLevelPortfolioMetadata = useSelector(state => getHasMultiLevelPortfolioMetadata(state));
  const hasOneClickPlan = useSelector(state => getOrgHasOneClickPlan(state));
  const billingStatus = useSelector(getOrgBillingStatus);
  const corpAccountRoadmap = useSelector(getCorpAccountRoadmap);
  const roadmaps = useSelector(getAllRoadmaps);

  useEffect(() => {
    if (isOrgAccessControlUninitialized && !isOrgAccessControlLoading) {
      dispatch(fetchOrganizationAccessControl());
    }
  }, []);

  const hasOrganizationAccessConfigured = useMemo(() => {
    return isNotEmpty(organizationAccessControlData);
  }, [organizationAccessControlData]);

  const isChildDragon = !isParentDragon && isNotEmpty(organizationAccessControlData);

  const isDodShareProjectsEnabled = isParentDragon && hasDodShareProjects;

  const removeChildDragonAccess = useCallback(
    childOrgId => dispatch(removeOrganizationAccessControl(organization.id, childOrgId)),
    [organization.id],
  );
  const addChildDragonAccess = useCallback(
    body => dispatch(addOrganizationAccessControl(organization.id, body)),
    [organization.id],
  );

  const createChildOrganizationsReaderTokens = useCallback(() => {
    if (isParentDragon) {
      organizationAccessControlData.forEach(entry => {
        const { child_organization_id: childOrganizationId } = entry;
        const { expiresIn } = defaultToEmptyObject(childOrganizationsReaderTokens[childOrganizationId]);

        if (isNil(expiresIn) || (isNotNil(expiresIn) && moment().isAfter(expiresIn))) {
          dispatch(createOrganizationReaderToken(organization.id, childOrganizationId));
        }
      });
    }
  }, [isParentDragon, organizationAccessControlData, childOrganizationsReaderTokens, organization.id]);

  const fetchChildOrganizationReaderToken = useCallback(
    childOrganizationId => dispatch(createOrganizationReaderToken(organization.id, childOrganizationId)),
    [organization.id],
  );

  const hasFetchedOrganizationAccessControl = !isOrgAccessControlUninitialized && !isOrgAccessControlLoading;

  const getCorpRoadmapForOrg = useCallback(
    orgId => {
      const parentOrg = organizationAccessControlData.find(propEq(PARENT_ORGANIZATION_ID, orgId));

      if (parentOrg) {
        return corpAccountRoadmap;
      }

      const childAccount = organizationAccessControlData.find(propEq(CHILD_ORGANIZATION_ID, orgId));

      return roadmaps.find(propEq('id', prop('portfolio_roadmap_id', childAccount)));
    },
    [organizationAccessControlData, corpAccountRoadmap, roadmaps],
  );

  const getDefaultRoadmapForMetadataItem = useCallback(
    metadataItem => {
      if (!isDodActive || !metadataItem) {
        return undefined;
      }

      return getCorpRoadmapForOrg(metadataItem?.organization_id);
    },
    [isDodActive, getCorpRoadmapForOrg],
  );

  const getDefaultRoadmapTitleForMetadataItem = useCallback(
    metadataItem => {
      const corpRoadmap = getDefaultRoadmapForMetadataItem(metadataItem);

      // is_corp_roadmap is true for the Corp Roadmap that belongs to the Corp account
      // For portfolio (baby) accounts we want to show "Portfolio" for own items and not the corp roadmap name
      if (!corpRoadmap || (!corpRoadmap.is_corp_roadmap && !isParentDragon)) {
        return DEFAULT_METADATA_ROADMAP_TITLE;
      }

      return corpRoadmap?.title ?? DEFAULT_METADATA_ROADMAP_TITLE;
    },
    [organizationAccessControlData, isParentDragon, getDefaultRoadmapForMetadataItem],
  );

  const getCurrentOrgCorpRoadmap = useCallback(
    () => getCorpRoadmapForOrg(organization?.id),
    [organization?.id, getCorpRoadmapForOrg],
  );

  const getAccountNameAndUrlForOrganizationId = useCallback(
    organizationId => {
      const parentOrgAccess = organizationAccessControlData.find(propEq(PARENT_ORGANIZATION_ID, organizationId));

      if (parentOrgAccess) {
        return {
          accountName: corpAccountRoadmap?.title,
          accountUrl: '',
        };
      }

      const childAccount = organizationAccessControlData.find(propEq(CHILD_ORGANIZATION_ID, organizationId));
      const corpRoadmap = getCorpRoadmapForOrg(organizationId);

      return {
        accountName: corpRoadmap?.title,
        accountUrl: childAccount?.child_organization_url,
      };
    },
    [organizationAccessControlData, corpAccountRoadmap],
  );

  const childrenDragons = useMemo(() => {
    const sameOrganizationId = equals(organization.id);
    const isParentOrgIdSameAsCurrent = pipe(getParentOrganizationIdProp, sameOrganizationId);

    return organizationAccessControlData.filter(isParentOrgIdSameAsCurrent).map(account => {
      const { accountName } = getAccountNameAndUrlForOrganizationId(account.child_organization_id);

      return {
        ...account,
        accountName,
      };
    });
  }, [organization.id, organizationAccessControlData, getAccountNameAndUrlForOrganizationId]);

  const childrenDragonsCorpObjectives = useMemo(() => extractPortfolioObjectiveIds(childrenDragons), [childrenDragons]);

  const getReaderTokenByOrganizationId = useCallback(
    childOrganizationId => {
      return childOrganizationsReaderTokens[childOrganizationId]?.token;
    },
    [childOrganizationsReaderTokens],
  );

  return {
    isDodActive,
    isParentDragon,
    isChildDragon,
    isDodShareProjectsEnabled,
    organizationAccessControlData,
    childrenDragons,
    childrenDragonsCorpObjectives,
    hasOrganizationAccessConfigured,
    hasFetchedOrganizationAccessControl,
    hasMultiLevelPortfolioMetadata,
    hasOneClickPlan,
    billingStatus,
    corpAccountRoadmap,

    // Actions
    addChildDragonAccess,
    removeChildDragonAccess,
    createChildOrganizationsReaderTokens,
    fetchChildOrganizationReaderToken,

    // Methods
    getCurrentOrgCorpRoadmap,
    getDefaultRoadmapForMetadataItem,
    getDefaultRoadmapTitleForMetadataItem,
    getAccountNameAndUrlForOrganizationId,
    getReaderTokenByOrganizationId,
  };
};

export default useOrganizationsAccessControl;
