import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import { defaultTo, has } from 'ramda';

import { getWidgetDefaultCoordinatesBasedOnIndex } from 'utils/reactGridLayout';

import { ALL_DASHBOARDS_WIDGETS, getTemplateByKey } from '../helpers/templates';
import { updateActiveDashboard } from 'store/dashboards/actions';

const defaultToEmptyArray = defaultTo([]);
const hasX = has('x');
const hasY = has('y');

/**
 * @function useDashboardWidgets
 *
 * Hook that has all the specific logic to load dashboard widgets
 * and also actions like update / delete / edit widgets
 *
 * @return {Object}
 */
const useDashboardWidgets = ({ dashboard, dashboardId }) => {
  const dispatch = useDispatch();

  const enrichedWidgets = useMemo(() => {
    const template = getTemplateByKey(dashboard?.template);

    const widgets = isEmpty(dashboard?.widgets) ? defaultToEmptyArray(template?.charts) : defaultToEmptyArray(dashboard?.widgets);

    const mapWidget = (widget, i) => ({
      uuid: widget.uuid || uuidv4(),
      ...ALL_DASHBOARDS_WIDGETS.find(w => w.id === widget.id),
      ...widget,
      width: widget.width || 1,
      height: widget.height || 1,
      ...(hasX(widget) && hasY(widget) ? {} : getWidgetDefaultCoordinatesBasedOnIndex(i)),
    });

    return widgets.map(mapWidget);
  }, [dashboard?.template, dashboard?.widgets, dashboardId]);

  const updateDashboardWidgets = useCallback(
    widgets => {
      dispatch(
        updateActiveDashboard({
          ...dashboard,
          widgets,
        }),
      );
    },
    [dispatch],
  );

  return {
    widgets: enrichedWidgets,

    updateDashboardWidgets,
  };
};

export default useDashboardWidgets;
