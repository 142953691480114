import { getThunkActionTypes } from 'utils/store/thunk';

const SET_PROJECT_STORIES = 'SET_PROJECT_STORIES';
const SET_PROJECT_INTEGRATIONS = 'SET_PROJECT_INTEGRATIONS';

const actionTypes = {
  ...getThunkActionTypes('FETCH_PROJECT_INTEGRATIONS'),
  ...getThunkActionTypes('TEST_CONNECTION'),
  ...getThunkActionTypes('TEST_SLACK_CONNECTION'),
  ...getThunkActionTypes('CREATE_PROJECT_INTEGRATION'),
  ...getThunkActionTypes('ADD_PROJECT_INTEGRATION'),
  ...getThunkActionTypes('REMOVE_PROJECT_INTEGRATION'),
  ...getThunkActionTypes('UPDATE_PROJECT_STORIES'),
  ...getThunkActionTypes('GET_PROJECT_STORIES'),
  ...getThunkActionTypes('SYNC_STORIES'),
  ...getThunkActionTypes('UPDATE_PROJECT_INTEGRATION'),
  ...getThunkActionTypes('UPDATE_INTEGRATION_PROJECT_FROM_PROJECT'),
  ...getThunkActionTypes('REVALIDATE_USER_INTEGRATION_AUTH'),
  ...getThunkActionTypes('FETCH_INTEGRATION_FIELDS'),
  ...getThunkActionTypes('ENABLE_INTEGRATION_WEBHOOKS'),
  ...getThunkActionTypes('DISABLE_INTEGRATION_WEBHOOKS'),
  ...getThunkActionTypes('UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS'),
  ...getThunkActionTypes('IMPORT_COUNT_INTEGRATION_ITEMS'),
  ...getThunkActionTypes('IMPORT_INSERT_INTEGRATION_ITEMS'),
  ...getThunkActionTypes('IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC'),
  ...getThunkActionTypes('UPDATE_INTEGRATION_PROGRESS_TRACKING'),
  ...getThunkActionTypes('UPDATE_PROJECTS_MAPPED_FIELDS'),
};

export { SET_PROJECT_STORIES, SET_PROJECT_INTEGRATIONS };

export const {
  FETCH_PROJECT_INTEGRATIONS,
  FETCH_PROJECT_INTEGRATIONS_FULFILLED,
  TEST_CONNECTION,
  TEST_CONNECTION_RESET,
  TEST_SLACK_CONNECTION,
  CREATE_PROJECT_INTEGRATION,
  CREATE_PROJECT_INTEGRATION_FULFILLED,
  CREATE_PROJECT_INTEGRATION_RESET,
  ADD_PROJECT_INTEGRATION,
  ADD_PROJECT_INTEGRATION_FULFILLED,
  ADD_PROJECT_INTEGRATION_RESET,
  REMOVE_PROJECT_INTEGRATION,
  REMOVE_PROJECT_INTEGRATION_FULFILLED,
  UPDATE_PROJECT_STORIES,
  UPDATE_PROJECT_STORIES_FULFILLED,
  UPDATE_PROJECT_STORIES_RESET,
  GET_PROJECT_STORIES,
  GET_PROJECT_STORIES_PENDING,
  GET_PROJECT_STORIES_FULFILLED,
  GET_PROJECT_STORIES_REJECTED,
  SYNC_STORIES,
  SYNC_STORIES_FULFILLED,
  UPDATE_PROJECT_INTEGRATION,
  UPDATE_PROJECT_INTEGRATION_RESET,
  UPDATE_PROJECT_INTEGRATION_FULFILLED,
  UPDATE_PROJECT_INTEGREATION_REJECTED,
  UPDATE_INTEGRATION_PROJECT_FROM_PROJECT,
  UPDATE_INTEGRATION_PROJECT_FROM_PROJECT_PENDING,
  UPDATE_INTEGRATION_PROJECT_FROM_PROJECT_FULFILLED,
  UPDATE_INTEGRATION_PROJECT_FROM_PROJECT_REJECTED,
  REVALIDATE_USER_INTEGRATION_AUTH,
  REVALIDATE_USER_INTEGRATION_AUTH_PENDING,
  REVALIDATE_USER_INTEGRATION_AUTH_FULFILLED,
  FETCH_INTEGRATION_FIELDS,
  ENABLE_INTEGRATION_WEBHOOKS,
  DISABLE_INTEGRATION_WEBHOOKS,
  UPDATE_INTEGRATION_WEBHOOKS_PROJECT_RESTRICTIONS,
  IMPORT_COUNT_INTEGRATION_ITEMS,
  IMPORT_INSERT_INTEGRATION_ITEMS,
  IMPORT_INSERT_INTEGRATION_ITEMS_ASYNC,
  UPDATE_INTEGRATION_PROGRESS_TRACKING,
  UPDATE_INTEGRATION_PROGRESS_TRACKING_PENDING,
  UPDATE_INTEGRATION_PROGRESS_TRACKING_FULFILLED,
  UPDATE_INTEGRATION_PROGRESS_TRACKING_REJECTED,
  UPDATE_PROJECTS_MAPPED_FIELDS,
  UPDATE_PROJECTS_MAPPED_FIELDS_PENDING,
  UPDATE_PROJECTS_MAPPED_FIELDS_FULFILLED,
} = actionTypes;
