import styled, { css } from 'styled-components';

import theme, { spacing } from 'design-system/theme';
import { isAllocationPercentageCol } from './helpers';

export const getCellStyle = (row, columnKey, highlightOverInvestedGoals) => {
  if (highlightOverInvestedGoals && row.isOverInvested && isAllocationPercentageCol(columnKey)) {
    return { backgroundColor: theme.palette.newLayout.background.highlightRed };
  }

  return {};
};

export const gridStyles = css`
  .ag-layout-normal.ag-root {
    margin: 0;
  }

  .ag-cell strong {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }

  .ag-cell span > span {
    color: inherit;
  }
`;

export const Circle = styled.div`
  &&&& {
    width: ${spacing(2)}px;
    height: ${spacing(2)}px;
    display: inline-block;
    background-color: ${({ theme }) => theme.palette.border.secondary};
    border-radius: 50%;
    margin: 0 0 0 ${spacing()}px;
    vertical-align: middle;
  }
`;
