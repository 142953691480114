const BUSINESS_DRIVER_RESPONSES = {
  1: 'Having a new product executive e.g. new CPO',
  2: 'Having a new leader in Product Operations',
  // 3: 'Seeking a boost in transformation',
  // 4: 'Struggling with the scale or complexity of product development',
  5: 'Navigating recent acquisitions or mergers',
  // 6: 'Had new private equity ownership, new investment, or preparing for IPO',
  7: 'Current roadmap tool no longer meet our needs',
  8: 'Other',
};

const BOT_BUSINESS_DRIVER_ANSWERS = {
  1: `Dragonboat has helped new product executives to accelerate early wins and achieve sustained success for their teams.
      Here are some common areas Dragonboat has helped`,
  2: 'Dragonboat helped product operation leaders to',
  3: `Dragonboat has helped product and digital transformation leaders accelerate impacts and achieve
      sustained best practices. Here are some common areas Dragonboat has helped`,
  4: 'To streamline and achieve product development at scale amid complexity',
  5: 'To accelerate the success and impact of head of Product Operations',
  6: `To immediately achieve the benefit of mergers and acquisition and pave the road for
      continued market share gain and reduction in cost and time to market`,
  7: `To build scale, efficiency and improved product outcome, and effectively communicate
      product strategy and roadmap stories for your new investor or IPO readiness`,
  8: 'Dragonboat helps you to effectively Orchestrate a responsive product portfolio to accelerate outcomes',
  'other': 'Please share a bit of what you are looking to solve with Dragonboat',
};

const GUIDE_OPTIONS_BY_BUSINESS_DRIVER = {
  1: [
    {
      id: 10,
      parent_id: null,
      title: 'Enable a source of truth across the company',
      guides: [
        {
          id: 28,
          parent_id: 10,
          title: 'Create and share evergreen reports of the right content for the right audience',
          pendo_guide_id: '',
        },
        {
          id: 29,
          parent_id: 10,
          title: 'Enable a dynamic product portfolio view across products and goals',
          pendo_guide_id: '',
        },
        {
          id: 30,
          parent_id: 10,
          title: `Have a centralized place to collect, collaborate and prioritize feedback
            and requests and inform GTM teams on progresses`,
          pendo_guide_id: '',
        },
      ],
      pendo_guide_id: '',
    },
    {
      id: 11,
      title: 'Establish an outcome focused product practice',
      guides: [
        {
          id: 31,
          parent_id: 11,
          title: 'Set and align portfolio and product team goals in goal list',
          pendo_guide_id: 'bvcgy9RJLOMeRsSIDgXYBviCBtk',
        },
        { id: 32, parent_id: 11, title: 'Visualize view outcome and roadmap progress in goal snapshot' },
        { id: 33, parent_id: 11, title: 'Correlate feature release and impacts' },
      ],
      pendo_guide_id: '',
    },
    {
      id: 12,
      title: 'Enable strategic alignment to guide prioritization',
      guides: [
        { title: 'Single pane prioritization with goal and initiatives for strategic alignment and effective prioritization' },
        { title: 'Connect features with revenue' },
        { title: 'Build and communicate portfolio roadmaps' },
      ],
      pendo_guide_id: '',
    },
    {
      id: 13,
      title: 'Gain instant visibility on portfolio delivery and allocation via seamless 2 ways integration with Jira, ADO',
      guides: [
        { title: 'Delivery dashboard, drill down to view detailed lists and stories' },
        { title: 'Instant update from Dragonboat to Jira and vice versa' },
        { title: 'Allocation report compare planned vs reported' },
      ],
      pendo_guide_id: '',
    },
    {
      id: 14,
      title: 'Centralize and collaborate on requests, insights and roadmap intakes',
      guides: [
        { title: 'Centralize requests and collaborate GTM teams - using portal or zendesk or slack' },
        { title: 'Auto populate customer info from salesforce to connect to revenue and segment of accounts' },
        { title: 'Leverage insights and themes to make data informed decisions' },
        { title: 'Seamlessly close the loop via auto notification between requests and feature progresses' },
      ],
      pendo_guide_id: '',
    },
  ],
  2: [
    { id: 15, title: 'Enable an outcome focused product practice', pendo_guide_id: '' },
    { id: 16, title: 'Enable a source of truth for the company', pendo_guide_id: '' },
    { id: 17, title: 'Collect and collaborate requests with and stakeholders', pendo_guide_id: '' },
    { id: 18, title: 'Ensure delivery success', pendo_guide_id: '' },
    { id: 19, title: 'Streamline and automate product management workflow', pendo_guide_id: '' },
    { id: 20, title: 'Reduce business and investment risk', pendo_guide_id: '' },
    {
      id: 21,
      title: 'Consolidate customer feedback so it can be prioritized and balanced with strategic initiatives',
      pendo_guide_id: '',
    },
  ],
  3: [
    { id: 22, title: 'Enable an outcome focused product practice', pendo_guide_id: '' },
    { title: 'Source of truth for effective decisions', pendo_guide_id: '' },
  ],
  4: [],
  5: [],
  6: [
    {
      id: 23,
      title: 'Instant portfolio wide visibility without engineering system migration or process change',
      pendo_guide_id: '',
    },
    { id: 24, title: 'Instant collaboration across teams via a source of truth product portfolio', pendo_guide_id: '' },
  ],
  7: [],
  8: [
    {
      id: 25,
      title: 'Orchestrate, allocate and deliver across multiple product lines and business areas',
      pendo_guide_id: '',
    },
    { id: 26, title: 'End to end product portfolio platform that can be modularly adopted', pendo_guide_id: '' },
    { id: 27, title: 'Engage all key', pendo_guide_id: '' },
  ],
  9: [],
};

const CPO_OPTIONS = [
  {
    header: 'Create a source of truth for products',
    options: [
      { title: 'Enable dynamic portfolio hierarchies', pendo_guide_id: 'xqUDedKN0sZObThRjcxYjAafJ98' },
      {
        title: 'Automate evergreen stakeholder reports',
        pendo_guide_id: 'D7xS3cMqJepivFXqXOOmtvcMAo4',
      },
      {
        title: 'Enable a source of truth for requests',
        pendo_guide_id: 'ZQsNRfbXckCSdbYV5VgKxf9Zqro',
      },
    ],
  },
  {
    header: 'Enable strategic alignment to guide prioritization',
    options: [
      { title: 'Single pane goals + product prioritization', pendo_guide_id: 'ia9IzXxWB2XFrmfSjRpUB-ZweQU' },
      { title: 'Connect customer and revenue outcomes', pendo_guide_id: '5RJz6ugMzBYDaPUcQTeKyAe-7xo' },
      {
        title: 'Seamless 2-way sync with Jira, ADO, etc',
        pendo_guide_id: 'tHKKvvdiSDP3rlGdH2KcoUqmVcg',
      },
    ],
  },
  {
    header: 'Strengthen product management best practices',
    options: [
      {
        title: 'Set and align portfolio and product team goals',
        pendo_guide_id: 'bvcgy9RJLOMeRsSIDgXYBviCBtk',
      },
      { title: 'Incorporate customer insights and requests', pendo_guide_id: 'bvBYzhi6a76zTkCXdga034BNt_8' },
      { title: 'View outcome and roadmap progress', pendo_guide_id: 'xQDINzAsozBhM05ci2JvQd6TApo' },
    ],
  },
  {
    header: 'Gain instant visibility on portfolio delivery and allocation',
    options: [
      { title: 'Automated delivery dashboard', pendo_guide_id: 'wL-aSWqOL31gJM22ZdFKIWxnOpQ' },
      { title: 'Instant access to planned vs reported allocation', pendo_guide_id: 'G7uuhm-vG6eujqmwAAUxFdDZTEU' },
    ],
  },
];
const PRODUCT_OPS_OPTIONS = [
  {
    header: 'Enable visibility at all levels via a source of truth',
    options: [
      { title: 'Portfolio roadmap from strategy to execution', pendo_guide_id: 'xqUDedKN0sZObThRjcxYjAafJ98' },
      {
        title: 'Request module for revenue and product collaboration',
        pendo_guide_id: 'ZQsNRfbXckCSdbYV5VgKxf9Zqro',
      },
      {
        title: 'Customize and share evergreen decks for stakeholders',
        pendo_guide_id: 'D7xS3cMqJepivFXqXOOmtvcMAo4',
      },
    ],
  },
  {
    header: 'Strengthen product management best practice',
    options: [
      { title: 'Prioritize with goals and roadmaps in a single pane', pendo_guide_id: 'ia9IzXxWB2XFrmfSjRpUB-ZweQU' },
      { title: 'Connect customer insights and product features', pendo_guide_id: '5RJz6ugMzBYDaPUcQTeKyAe-7xo' },
      {
        title: 'Orchestrate across teams with outcome focused PDLC',
        pendo_guide_id: '0V5i6ea48pvGFYa-aBQMzsleTvk',
      },
      {
        title: 'Connect outcome and product progress',
        pendo_guide_id: 'xQDINzAsozBhM05ci2JvQd6TApo',
      },
    ],
  },
  {
    header: 'Automate monitoring for delivery success',
    options: [
      {
        title: 'Seamless 2 way integration reduce manual effort',
        pendo_guide_id: 'x9sBKejPvSPiELELHNTA9oBxUqk',
      },
      { title: 'Automate tracking scope and schedule risk', pendo_guide_id: 'nvpzWecjbfF8zjHpUY16xH1IEF8' },
      { title: 'Portfolio Delivery dashboard with visibility at all levels', pendo_guide_id: 'wL-aSWqOL31gJM22ZdFKIWxnOpQ' },
    ],
  },
  {
    header: 'Enable effective cross team/ quarterly planning',
    options: [
      { title: 'Enable real time allocation during planning', pendo_guide_id: 'kns7AC4-SRj53oqC2PhhZ4nK5xs' },
      { title: 'Plan that accounts for cross team dependencies', pendo_guide_id: 'RFfUJ2l-J3B4I5nMAFJCiYrb7RU' },
    ],
  },
];
const MA_OPTIONS = [
  {
    header: 'Achieve Instant Visibility Across Siloed Portfolios',
    options: [
      { title: 'Seamless integration of multiple Jira’s, ADO etc', pendo_guide_id: 'seWYyWL6s-lZ5NDDdBiiifRDw5k' },
      {
        title: 'Instant Visibility without disruption',
        pendo_guide_id: 'xqUDedKN0sZObThRjcxYjAafJ98',
      },
    ],
  },
  {
    header: 'Accelerate Synergies and Prevent Duplication',
    options: [
      { title: 'Set and communicate combined strategic objectives', pendo_guide_id: 'bvcgy9RJLOMeRsSIDgXYBviCBtk' },
      { title: 'Portfolio view connecting strategy with execution', pendo_guide_id: 'xqUDedKN0sZObThRjcxYjAafJ98' },
      {
        title: 'Request module for revenue and product collaboration',
        pendo_guide_id: 'ZQsNRfbXckCSdbYV5VgKxf9Zqro',
      },
      { title: 'Customize and share evergreen decks for stakeholders', pendo_guide_id: 'D7xS3cMqJepivFXqXOOmtvcMAo4' },
    ],
  },
  {
    header: 'Strengthen product management best practice',
    options: [
      {
        title: 'Prioritize with goals and roadmaps in a single pane',
        pendo_guide_id: 'ia9IzXxWB2XFrmfSjRpUB-ZweQU',
      },
      { title: 'Connect customer insights and product features', pendo_guide_id: '5RJz6ugMzBYDaPUcQTeKyAe-7xo' },
      { title: 'Orchestrate across teams with outcome focused PDLC ', pendo_guide_id: '0V5i6ea48pvGFYa-aBQMzsleTvk' },
      { title: 'Connect outcome and product progress', pendo_guide_id: 'xQDINzAsozBhM05ci2JvQd6TApo' },
    ],
  },
  {
    header: 'Automate monitoring for delivery success',
    options: [
      { title: 'Connect Product and Delivery via 2 way integration', pendo_guide_id: 'x9sBKejPvSPiELELHNTA9oBxUqk' },
      { title: 'Automate tracking scope and schedule risk', pendo_guide_id: 'nvpzWecjbfF8zjHpUY16xH1IEF8' },
      { title: 'Portfolio Delivery dashboard with visibility at all levels', pendo_guide_id: 'wL-aSWqOL31gJM22ZdFKIWxnOpQ' },
    ],
  },
  {
    header: 'Enable effective cross team/ quarterly planning',
    options: [
      { title: 'Enable real time allocation during planning', pendo_guide_id: 'o8R6zRthEPhpcuRhtbTIhi-tZdQ' },
      { title: 'Plan that accounts for cross team dependencies', pendo_guide_id: 'RFfUJ2l-J3B4I5nMAFJCiYrb7RU' },
    ],
  },
];

const LEVEL_1_NAV_OPTIONS = [
  {
    id: 1,
    label: 'New product executive / CPO',
    level_2_header: 'Accelerate results for new product execs',
    options: CPO_OPTIONS,
  },
  {
    id: 2,
    label: 'New product ops',
    level_2_header: 'Operationalize Product Best Practices Effectively',
    options: PRODUCT_OPS_OPTIONS,
  },
  { id: 3, label: 'M&A / Product complexity', level_2_header: 'Accelerate M&A Portfolio Outcomes', options: MA_OPTIONS },
];

const SALES_BUTTON = {
  label: 'Talk to Sales',
  href: 'https://meetings.hubspot.com/dragonboat/demo?embed=true',
};

export {
  BOT_BUSINESS_DRIVER_ANSWERS,
  BUSINESS_DRIVER_RESPONSES,
  GUIDE_OPTIONS_BY_BUSINESS_DRIVER,
  CPO_OPTIONS,
  PRODUCT_OPS_OPTIONS,
  MA_OPTIONS,
  LEVEL_1_NAV_OPTIONS,
  SALES_BUTTON,
};
