import AgGridAutocomplete from 'components/AgGridAutocomplete/new';
import { AutocompleteCellRenderer, ChipsCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { MultiSelectCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import { multiSelectComparator, stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { applyCheckIsEditable, returnsTrueIfKeyIsNotEscOrTab } from 'design-system/molecules/AgGridReact-New/helpers';

const getSingleMetricAttributes = (editable, metrics) => ({
  field: 'metric_name',
  editable: applyCheckIsEditable(editable),
  cellRenderer: AutocompleteCellRenderer,
  cellEditor: AgGridAutocomplete,
  cellEditorParams: {
    autoFocus: true,
    options: (metrics || []).map(metric => ({ value: metric.name, label: metric.name, id: metric.id })),
  },
  comparator: stringComparator,
});

const getMultipleMetricsAttributes = (editable, metrics, createMetricOption) => ({
  cellEditor: MultiSelectCellEditor,
  cellEditorParams: {
    options: () => metrics || [],
    optionsMapper: { label: 'name', value: 'id' },
    createOption: createMetricOption,
  },
  cellRenderer: ChipsCellRenderer,
  chipVariant: 'outlined',
  editable: applyCheckIsEditable(editable),
  field: 'metrics',
  labelField: 'name',
  openByDefault: true,
  showTooltip: true,
  comparator: multiSelectComparator,
});

const getMetricsColumnDef = ({ metrics, editable, hasMultipleMetrics, createMetricOption = () => {} }) => ({
  cellEditorPopup: true,
  enableRowGroup: true,
  headerName: 'Metric',
  suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
  width: 100,
  ...(hasMultipleMetrics
    ? getMultipleMetricsAttributes(editable, metrics, createMetricOption)
    : getSingleMetricAttributes(editable, metrics)),
});

export default getMetricsColumnDef;
