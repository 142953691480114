import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLogin } from 'store/login/selectors';

import { setPageActiveView } from 'store/userViews';
import { subscribeUserToTheUserView } from 'store/userViews/sharedViews.thunks';
import {
  getActiveViewForPage,
  getUserViews,
  getUserViewSubscribeFailed,
  getUserViewSubscribedFulfilled,
} from 'store/userViews/selectors';
import { extractViewIdFromURL } from 'utils/userViews';

const useLoadActiveViewFromPrivateLink = pageId => {
  const dispatch = useDispatch();
  const [loadingViewFromURL, setLoadingViewFromURL] = useState(true);
  const [subscribingView, setSubscribingView] = useState(false);
  const viewSubscribeFailed = useSelector(state => getUserViewSubscribeFailed(state));
  const viewSubscribed = useSelector(state => getUserViewSubscribedFulfilled(state));
  const userViews = useSelector(state => getUserViews(state));
  const activePageView = useSelector(state => getActiveViewForPage(state, pageId));
  const { isAuthenticated } = useSelector(getLogin);

  const viewIdFromURL = extractViewIdFromURL(window.location.href);

  const viewFromURL = useMemo(() => {
    return userViews.find(view => view.key === viewIdFromURL || Number(view.id) === Number(viewIdFromURL));
  }, [userViews, viewIdFromURL]);

  useEffect(() => {
    if (!subscribingView) {
      return;
    }

    if (viewSubscribed) {
      dispatch(setPageActiveView(pageId, viewSubscribed));

      setLoadingViewFromURL(false);
      setSubscribingView(false);
    }

    if (viewSubscribeFailed) {
      setLoadingViewFromURL(false);
      setSubscribingView(false);
    }
  }, [subscribingView, viewSubscribed, viewSubscribeFailed]);

  useEffect(() => {
    if (!loadingViewFromURL) {
      return;
    }

    const ignoreLoadFromURL = !pageId || !viewIdFromURL || !isAuthenticated;

    if (loadingViewFromURL && ignoreLoadFromURL) {
      setLoadingViewFromURL(false);

      return;
    }

    if (!viewFromURL && !subscribingView) {
      setSubscribingView(true);
      // User doesn't have the view on state for the given view ID lets try to implicitly subscribe him
      dispatch(subscribeUserToTheUserView(viewIdFromURL));
      return;
    }

    if (subscribingView) {
      // View is still being subscribed ignore the remaining logic
      return;
    }

    if (viewFromURL && viewFromURL.id !== activePageView?.id) {
      dispatch(setPageActiveView(pageId, viewFromURL));
    }

    setLoadingViewFromURL(false);
  }, [pageId, isAuthenticated, viewIdFromURL, loadingViewFromURL, subscribingView, viewFromURL, activePageView]);

  return loadingViewFromURL || subscribingView;
};

export default useLoadActiveViewFromPrivateLink;
