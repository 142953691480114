import { Map, fromJS } from 'immutable';

import {
  SHOW_DELETING_IDEA_CONFIRMATION,
  HIDE_DELETING_IDEA_CONFIRMATION,
  SHOW_LIGHTBOX,
  HIDE_LIGHTBOX,
  SET_IDEAS_COPY_PASTE_LIGHTBOX_DATA,
  SHOW_IDEAS_COPY_PASTE_LIGHTBOX,
  HIDE_IDEAS_COPY_PASTE_LIGHTBOX,
  SHOW_IMPORT_IDEAS_LIGHTBOX,
  HIDE_IMPORT_IDEAS_LIGHTBOX,
  SHOW_IMPORT_JQL_LIGHTBOX,
  HIDE_IMPORT_JQL_LIGHTBOX,
  SHOW_IMPORT_TIP,
  HIDE_IMPORT_TIP,
  SELECT_PROJECT_LIGHTBOX_TAB,
  SHOW_IMPORT_ADO_QUERY_LIGHTBOX,
  HIDE_IMPORT_ADO_QUERY_LIGHTBOX,
  SHOW_IMPORT_DRAGONBOAT_FILTER_LIGHTBOX,
  HIDE_IMPORT_DRAGONBOAT_FILTER_LIGHTBOX,
  SHOW_IMPORT_RALLY_VIEW_LIGHTBOX,
  HIDE_IMPORT_RALLY_VIEW_LIGHTBOX,
} from './types';

const initialState = new Map({});

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SHOW_DELETING_IDEA_CONFIRMATION:
      if (!action.id) {
        if (process.env.NODE_ENV === 'development') {
          console.warn('SHOW_DELETING_IDEA_CONFIRMATION::Id of deleting idea must be passed');
        }
        return state;
      }

      return state.merge(
        new Map({
          showDeletingConfirmation: true,
          deletingId: action.id,
        }),
      );
    case HIDE_DELETING_IDEA_CONFIRMATION:
      return state.merge(
        new Map({
          showDeletingConfirmation: false,
          deletingId: undefined,
        }),
      );
    case SHOW_LIGHTBOX:
      return state.merge(
        new Map({
          lightboxVisible: true,
          lightboxIdeaId: action.ideaId,
          projectLightboxTab: '',
        }),
      );
    case HIDE_LIGHTBOX:
      return state.merge(
        new Map({
          lightboxVisible: false,
          lightboxIdeaId: undefined,
          lightboxIdea: undefined,
        }),
      );
    case SELECT_PROJECT_LIGHTBOX_TAB: {
      return state.merge(
        new Map({
          projectLightboxTab: action.tab,
        }),
      );
    }
    case SHOW_IDEAS_COPY_PASTE_LIGHTBOX:
      return state.set('ideasCopyPasteLightboxVisible', true);
    case HIDE_IDEAS_COPY_PASTE_LIGHTBOX:
      state = state.set('ideasCopyPasteLightboxVisible', false);
      return state.set('ideasCopyPasteLightboxData', null);
    case SET_IDEAS_COPY_PASTE_LIGHTBOX_DATA:
      return state.set('ideasCopyPasteLightboxData', fromJS(action.data));
    case SHOW_IMPORT_IDEAS_LIGHTBOX:
      return state.set('importIdeasLightboxVisible', true);
    case HIDE_IMPORT_IDEAS_LIGHTBOX:
      return state.set('importIdeasLightboxVisible', false);
    case SHOW_IMPORT_JQL_LIGHTBOX:
      return state.set('importJQLLightboxVisible', true);
    case HIDE_IMPORT_JQL_LIGHTBOX:
      return state.set('importJQLLightboxVisible', false);
    case SHOW_IMPORT_TIP:
      return state.set('importTipVisible', true);
    case HIDE_IMPORT_TIP:
      return state.set('importTipVisible', false);
    case SHOW_IMPORT_ADO_QUERY_LIGHTBOX:
      return state.set('importADOQueryLightboxVisible', true);
    case HIDE_IMPORT_ADO_QUERY_LIGHTBOX:
      return state.set('importADOQueryLightboxVisible', false);
    case SHOW_IMPORT_RALLY_VIEW_LIGHTBOX:
      return state.set('importRallyViewLightboxVisible', true);
    case HIDE_IMPORT_RALLY_VIEW_LIGHTBOX:
      return state.set('importRallyViewLightboxVisible', false);
    case SHOW_IMPORT_DRAGONBOAT_FILTER_LIGHTBOX:
      return state.merge(
        new Map({
          importDragonboatFilterLightboxVisible: true,
          importDragonboatFilterModalVariant: action.payload?.variant,
          importDragonboatFilterOrgIntegrationId: action.payload?.orgIntegrationId,
        }),
      );
    case HIDE_IMPORT_DRAGONBOAT_FILTER_LIGHTBOX:
      return state.merge(
        new Map({
          importDragonboatFilterLightboxVisible: false,
          importDragonboatFilterModalVariant: null,
          importDragonboatFilterOrgIntegrationId: null,
        }),
      );
    default:
      return state;
  }
};
