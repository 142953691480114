import reduceReducers from 'reduce-reducers';
import { propOr, pipe, pathOr, concat, defaultTo } from 'ramda';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import { FETCH_PROJECT_DELIVERABLE_FULFILLED } from 'store/cycleDeliverables/types';

import {
  CREATE_PROJECT_DELIVERABLE_COMMENT_FULFILLED,
  DELETE_PROJECT_DELIVERABLE_COMMENT_FULFILLED,
  FETCH_PROJECT_DELIVERABLE_HISTORY,
  FETCH_PROJECT_DELIVERABLE_HISTORY_FULFILLED,
  FETCH_PROJECT_DELIVERABLE_HISTORY_NEXT_FULFILLED,
  FETCH_PROJECT_DELIVERABLE_NEXT_COMMENTS_FULFILLED,
  UPDATE_PROJECT_DELIVERABLE_COMMENT_FULFILLED,
} from './types';
import { commentsAdapter } from './helpers/adapters';

const getPayloadData = pathOr([], ['payload']);
const getHistoryData = pipe(getPayloadData, propOr({}, 'data'));
const getMeta = pipe(getPayloadData, propOr({}, '_meta'));
const defaultToEmptyArray = defaultTo([]);

const { initialState: operationsInitialState, reducers: operationsReducers } = getThunksInitialStateAndReducers([
  FETCH_PROJECT_DELIVERABLE_HISTORY,
]);

const initialState = {
  ...operationsInitialState,
  projectDeliverablesHistory: {},
  comments: commentsAdapter.getInitialState(),
  commentsMetadata: {},
};

const ProjectCycleDeliverableReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROJECT_DELIVERABLE_HISTORY_FULFILLED: {
      return {
        ...state,
        projectDeliverablesHistory: {
          ...state.projectDeliverablesHistory,
          [action.meta.projectDeliverableId]: {
            _meta: getMeta(action),
            history: getHistoryData(action),
          },
        },
      };
    }
    case FETCH_PROJECT_DELIVERABLE_HISTORY_NEXT_FULFILLED: {
      return {
        ...state,
        projectDeliverablesHistory: {
          ...state.projectDeliverablesHistory,
          [action.meta.projectDeliverableId]: {
            _meta: getMeta(action),
            history: concat(
              pathOr([], [action.meta.projectDeliverableId, 'history'])(state.projectDeliverablesHistory),
              getHistoryData(action),
            ),
          },
        },
      };
    }
    case FETCH_PROJECT_DELIVERABLE_FULFILLED: {
      const { comments } = action.payload || {};

      return {
        ...state,
        commentsMetadata: comments?._meta,
        comments: commentsAdapter.setAll(state?.comments, defaultToEmptyArray(comments?.data)),
      };
    }
    case FETCH_PROJECT_DELIVERABLE_NEXT_COMMENTS_FULFILLED:
      const { data: nextCommentsBody } = action.payload;

      return {
        ...state,
        commentsMetadata: nextCommentsBody?._meta,
        comments: commentsAdapter.addMany(state?.comments, nextCommentsBody?.data),
      };
    case CREATE_PROJECT_DELIVERABLE_COMMENT_FULFILLED:
      const { data: newComment } = action.payload;

      return {
        ...state,
        comments: commentsAdapter.setOne(state?.comments, newComment),
      };
    case DELETE_PROJECT_DELIVERABLE_COMMENT_FULFILLED:
      const { commentId } = action.meta;

      return {
        ...state,
        comments: commentsAdapter.removeOne(state?.comments, commentId),
      };
    case UPDATE_PROJECT_DELIVERABLE_COMMENT_FULFILLED:
      const { data: updatedComment } = action.payload;

      return {
        ...state,
        comments: commentsAdapter.upsertOne(state?.comments, updatedComment),
      };
    default:
      return state;
  }
};

const reducer = reduceReducers(initialState, ProjectCycleDeliverableReducer, ...operationsReducers);

export default reducer;
