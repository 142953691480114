import React from 'react';
import { withTheme } from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

function SvgComponent(props) {
  const fill = props.fill || props.theme.palette.text.primary;
  
  return (
    <SvgIcon fontSize="inherit" width="25.075" height="25.043" viewBox="0 0 25.075 25.043" {...props}>
      <g id="icon-snapshot" transform="translate(-138.467 -705.983)" opacity="0.6">
        <path 
          id="Path_15625" 
          data-name="Path 15625"
          d="M157.454,721.44a3,3,0,1,1,3-3A3,3,0,0,1,157.454,721.44Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,157.454,717.44Z" 
          fill={fill}
        />
        <path 
          id="Path_15626" 
          data-name="Path 15626"
          d="M151.728,721.138h-2.572a1,1,0,0,1,0-2h2.572a1,1,0,0,1,0,2Z"
          fill={fill}
        />
        <g id="Group_8942" data-name="Group 8942">
          <path 
            id="Path_15627" 
            data-name="Path 15627"
            d="M144.564,713.809H140.2a1.73,1.73,0,0,1-1.728-1.728v-4.37a1.73,1.73,0,0,1,1.728-1.728h4.369a1.73,
            1.73,0,0,1,1.729,1.728v4.37A1.73,1.73,0,0,1,144.564,713.809Zm-4.1-2h3.826v-3.826h-3.826Z"
            fill={fill}
          />
        </g>
        <g id="Group_8943" data-name="Group 8943">
          <path 
            id="Path_15628"
            data-name="Path 15628"
            d="M144.564,722.417H140.2a1.73,1.73,0,0,1-1.728-1.728V716.32a1.731,1.731,0,0,1,1.728-1.729h4.369a1.731,
            1.731,0,0,1,1.729,1.729v4.369A1.73,1.73,0,0,1,144.564,722.417Zm-4.1-2h3.826v-3.826h-3.826Z"
            fill={fill}
          />
        </g>
        <g id="Group_8944" data-name="Group 8944">
          <path 
            id="Path_15629" 
            data-name="Path 15629"
            d="M144.564,731.026H140.2a1.73,1.73,0,0,1-1.728-1.728v-4.37A1.73,1.73,0,0,1,140.2,723.2h4.369a1.73,1.73,0,0,
            1,1.729,1.728v4.37A1.731,1.731,0,0,1,144.564,731.026Zm-4.1-2h3.826V725.2h-3.826Z"
            fill={fill}
          />
        </g>
        <g id="Group_8945" data-name="Group 8945">
          <path 
            id="Path_15630"
            data-name="Path 15630"
            d="M161.461,724.446H149.8a2.088,2.088,0,0,1-2.08-2.092v-8.048a2.089,2.089,0,0,1,2.08-2.092h11.658a2.09,
            2.09,0,0,1,2.081,2.092v8.048A2.089,2.089,0,0,1,161.461,724.446ZM149.8,714.214a.087.087,0,0,
            0-.08.092v8.048a.087.087,0,0,0,.08.092h11.658a.087.087,0,0,0,.081-.092v-8.048a.087.087,0,0,0-.081-.092Z"
            fill={fill}
          />
        </g>
        <path 
          id="Path_15631"
          data-name="Path 15631"
          d="M150.576,717.677h-1.42a1,1,0,0,1,0-2h1.42a1,1,0,0,1,0,2Z"
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
}

export default withTheme(SvgComponent);
