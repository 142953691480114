import React, { useEffect, useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import useSummaryDependencyLinesManagement from 'hooks/useSummaryDependencyLinesManagement';
import { computeDependencyType, isDependencyLinesError } from 'utils/dependencyLines';

const POINT_DIAMETER = 14;

const POINT_RADIUS = POINT_DIAMETER / 2;

const ProjectDependenciesMovablePoint = ({ id, projectInfo, drawArrow, show, isRight }) => {
  const [beingDragged, setBeingDragged] = useState(false);

  const [position, setPosition] = useState({});

  const {
    actions: { addProjectDependency },
  } = useSummaryDependencyLinesManagement();

  const projectId = useMemo(() => projectInfo.data.id, [projectInfo]);

  useEffect(
    () => () => {
      setBeingDragged(false);
    },
    [],
  );

  const onDragStartHandler = useCallback(
    event => {
      event.stopPropagation();

      event.dataTransfer.setData('isArrow', 't');

      event.dataTransfer.setData('isRight', isRight?.toString() ?? '');

      event.dataTransfer.setData('projectId', projectId.toString());

      const projectDependenciesIds = (projectInfo.data.projectDependencies || []).map(pd => pd.id);

      event.dataTransfer.setData('projectDependenciesIds', JSON.stringify(projectDependenciesIds));

      setBeingDragged(true);
    },
    [id],
  );

  const onDragHandler = useCallback(event => {
    event.stopPropagation();

    setPosition({
      position: 'fixed',
      left: event.clientX - POINT_RADIUS,
      top: event.clientY - POINT_RADIUS,
      transform: 'none',
      opacity: 0,
    });
  }, []);

  const onDragEndHandler = useCallback(event => {
    event.stopPropagation();

    setPosition({});

    setBeingDragged(false);
  }, []);

  const onDragOverHandler = useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const onDropHandler = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();

      const sourceIsArrow = !!event.dataTransfer.getData('isArrow');

      const sourceIsRight = !!event.dataTransfer.getData('isRight');

      const sourceProjectId = Number(event.dataTransfer.getData('projectId'));

      const sourceProjectDependenciesIds = JSON.parse(event.dataTransfer.getData('projectDependenciesIds'));

      if (!sourceIsArrow || sourceProjectId === projectId || sourceProjectDependenciesIds.includes(projectId)) {
        return;
      }

      try {
        const dependencyType = computeDependencyType(sourceIsRight ? 'right' : 'left', isRight ? 'right' : 'left');

        addProjectDependency(sourceProjectId, projectId, dependencyType);
      } catch (e) {
        if (isDependencyLinesError(e)) {
          console.warn('Error creating dependency:', e);

          return;
        }

        throw e;
      }
    },
    [addProjectDependency],
  );

  return (
    <>
      <SourcePoint
        show={show}
        draggable
        onDragStart={onDragStartHandler}
        onDrag={onDragHandler}
        onDragEnd={onDragEndHandler}
        onDragOver={onDragOverHandler}
        onDrop={onDropHandler}
        $beingDragged={beingDragged}
      >
        <Circle id={id} style={position} />
      </SourcePoint>
      {beingDragged && drawArrow()}
    </>
  );
};

const SourcePoint = styled.div`
  width: ${POINT_DIAMETER}px;
  border-radius: 5%;
  align-self: stretch;
  align-items: center;
  cursor: grab;
  opacity: 0.2;
  z-index: ${({ $beingDragged }) => ($beingDragged ? 0 : 1000)};
  display: ${({ show }) => (show ? 'flex' : 'none')};

  :hover {
    opacity: 0.8;
  }
`;

const Circle = styled.div`
  width: ${POINT_DIAMETER}px;
  background-color: lightgrey;
  margin: 14px 0;
  border-right: 14px solid darkgrey;
  border-top: 14px solid transparent;
  border-radius: 50%;
`;

export default ProjectDependenciesMovablePoint;
