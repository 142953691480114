import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { getAllKeyResults } from 'store/objectives/selectors';
import {
  createKeyResult,
  addKeyResultWithoutSave,
  updateKeyResultById,
  mergeKeyResults,
  deleteKeyResultById,
  bulkDeleteKeyResults,
  switchKeyResultsRowOrder,
  moveKeyResultToObjective,
} from 'store/objectives';
import usePortfolioOKRs from 'hooks/usePortfolioOKRs';

const useKeyResults = (isGoalMode = false) => {
  const dispatch = useDispatch();

  const keyResults = useSelector(state => getAllKeyResults(state));
  const processedKeyResults = usePortfolioOKRs(isGoalMode, keyResults);

  const boundActionCreators = useMemo(() => {
    return bindActionCreators(
      {
        createKeyResult,
        addKeyResultWithoutSave,
        updateKeyResultById,
        mergeKeyResults,
        deleteKeyResultById,
        bulkDeleteKeyResults,
        switchKeyResultsRowOrder,
        moveKeyResultToObjective,
      },
      dispatch,
    );
  }, [dispatch]);

  return {
    keyResults: processedKeyResults,

    ...boundActionCreators,
  };
};

export default useKeyResults;
