import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectPortfolioOverviewOkrsData,
  isLoadingFetchPortfolioOverviewOkrs,
  selectPortfolioOverviewOkrsWithHierarchy,
} from '../../store';
import { PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';
import useOrganizations from 'hooks/useOrganizations';
import useSystemFields from 'hooks/useSystemFields';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import usePermissions from 'hooks/permissions/usePermissions';
import history from 'store/utils/history';
import { getOKRGroupColumnTitle } from 'routes/Settings/Objectives/New/helpers';
import { getObjectiveTypeByLevel } from 'utils/objectives';
import { openObjectiveDrawer as openObjectiveDrawerAction } from 'store/objectives/actions';
import { OBJECTIVE } from 'constants/common';
import { getWidgetTitleForRoadmapLevel } from '../helpers';
import useSelectedRoadmapOnPortfolioOverview from '../../hooks/useSelectedRoadmapOnPortfolioOverview';

const SETTINGS_OKRS_URL = '/settings/objectives';

const usePortfolioOverviewOkrs = () => {
  const data = useSelector(selectPortfolioOverviewOkrsData);
  const objectivesAndKeyResultsWithHierarchy = useSelector(state => selectPortfolioOverviewOkrsWithHierarchy(state));

  const isLoading = useSelector(isLoadingFetchPortfolioOverviewOkrs);

  const dispatch = useDispatch();

  const { hasKeyResults, hasKeyResults2 } = useOrganizations();

  const [getSystemFieldName] = useSystemFields();

  const { isDodActive: hasCorpLevel } = useOrganizationsAccessControl();

  const { canView } = usePermissions();

  const { activeRoadmapEntity } = useSelectedRoadmapOnPortfolioOverview();

  const goToOkrsSettings = () => history.push(SETTINGS_OKRS_URL);
  const canGoToSettings = canView(PERMISSION_PAGES.settingsOkrsPage);

  const headerName = getOKRGroupColumnTitle(hasKeyResults, hasKeyResults2, hasCorpLevel, getSystemFieldName);

  const openObjectiveDrawer = useCallback(okr => {
    const okrType = getObjectiveTypeByLevel(okr);

    dispatch(openObjectiveDrawerAction(okr.id, okrType));
  }, []);

  const title = getWidgetTitleForRoadmapLevel(activeRoadmapEntity, OBJECTIVE, getSystemFieldName);

  const getRowId = ({ data }) => data.uniqueId;

  return {
    // data
    objectivesAndKeyResultsWithHierarchy,
    dataLength: data?.length || 0,
    isLoading,

    // actions
    openObjectiveDrawer,
    goToOkrsSettings,

    // labels
    title,
    headerName,

    // permissions
    canGoToSettings,

    // utils
    getRowId,
  };
};

export default usePortfolioOverviewOkrs;
