import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import flatten from 'lodash/flatten';
import { pluck, defaultTo, isEmpty, not } from 'ramda';

import { PDLC } from 'store/grids';
import { getGridConfigValue } from 'store/grids/selectors';
import { getAllDeliverables, getProjectsDeliverables } from 'store/cycleDeliverables/selectors';

import invertedTextColor from 'design-system/utils/invertedTextColor';
import { materialColors } from 'design-system/themes/default';

const DEFAULT_COLOR = materialColors.gray;

const getTitles = pluck('title');
const getIds = pluck('id');
const defaultToDefaulColor = defaultTo(DEFAULT_COLOR);
const defaultToZero = defaultTo(0);
const defaultToEmptyObject = defaultTo({});
const generateUniqueId = (deliverableId, statusId) => `${deliverableId}_${statusId}`;

const deliverableIdFromColumnIdRegex = /^deliverable_\d+_(\d+)/;

const getStatusColor = status => {
  const color = defaultToDefaulColor(status.color);

  if (isEmpty(color)) {
    return DEFAULT_COLOR;
  }
  return color;
};

/*
 * By mistake when generating the default deliverable status it was used the same generated set of options
 * and each status have the same id. This is not a problem since each project deliverable status association is done
 * by pairing deliverable_id and status_id.
 * To be able to distinguish the different ones to generate the data we need to create a unique id with the concat
 * of deliverable_id + status_id
 * */
const makeMapStatusWithUniqueId =
  deliverableId =>
  ([key, status]) =>
    [generateUniqueId(deliverableId, key), status];

const extractAllAvailableDeliverableStatus = deliverables => {
  return deliverables.reduce((acc, deliverable) => {
    const deliverableStatus = Object.entries(defaultToEmptyObject(deliverable.status));
    const uniqueIdMapper = makeMapStatusWithUniqueId(deliverable.id);

    return [...acc, ...deliverableStatus.map(uniqueIdMapper)];
  }, []);
};

/*
 * based on the list of projectCycleDeliverable data create a structure with the total of projects for
 * each unique status id (deliverable_id + status) to be used later when generating the data
 */
const createTotalsPerStatus = projectsDeliverables => {
  const projectDeliverablesValues = flatten(Object.values(projectsDeliverables));

  return projectDeliverablesValues.reduce((acc, projectDeliverable) => {
    const statusUniqueId = generateUniqueId(projectDeliverable.cycle_deliverable_id, projectDeliverable.status);

    return {
      ...acc,
      [statusUniqueId]: defaultToZero(acc[statusUniqueId]) + 1,
    };
  }, {});
};

const makeStatusDatasetDataForDeliverables = (statusUniqueId, totalProjectsByStatusId) => deliverable => {
  const deliverableStatusIds = Object.keys(defaultToEmptyObject(deliverable.status)) ?? [];

  const statusId = deliverableStatusIds.find(
    deliverableStatusId => generateUniqueId(deliverable.id, deliverableStatusId) === statusUniqueId,
  );
  const total = statusId && totalProjectsByStatusId[statusUniqueId];

  return total ?? 0;
};

const makeDatasetGeneratorForStatus =
  (allDeliverables, totalProjectsByStatusId) =>
  ([statusUniqueId, status]) => {
    const statusColor = getStatusColor(status);

    const statusDatasetDataCreator = makeStatusDatasetDataForDeliverables(statusUniqueId, totalProjectsByStatusId);

    return {
      id: statusUniqueId,
      label: status.title,
      backgroundColor: statusColor,
      datalabels: {
        color: invertedTextColor(statusColor, true),
      },
      data: allDeliverables.map(statusDatasetDataCreator),
    };
  };

const getDeliverableIdFromColumn = column => {
  return column?.colId?.match(deliverableIdFromColumnIdRegex)?.[1];
};

const getGridVisibleDeliverableIds = ({ columnState = [] }) => {
  return columnState.reduce((acc, column) => {
    const deliverableId = getDeliverableIdFromColumn(column);

    if (deliverableId && not(column?.hide)) {
      return [...acc, +deliverableId];
    }
    return acc;
  }, []);
};

const usePdlcHeatmapDataGenerator = () => {
  const allDeliverables = useSelector(getAllDeliverables);
  const projectsDeliverables = useSelector(getProjectsDeliverables);
  const gridState = useSelector(state => getGridConfigValue(state, PDLC, 'gridState'));

  const visibleDeliverables = useMemo(() => {
    const gridVisibleDeliverableIds = getGridVisibleDeliverableIds(gridState);

    // show graph respecting order from grid
    return gridVisibleDeliverableIds.reduce((acc, deliverableId) => {
      const foundDeliverable = allDeliverables.find(deliverable => deliverable?.id === deliverableId);

      if (foundDeliverable) {
        return [...acc, foundDeliverable];
      }

      return acc;
    }, []);
  }, [allDeliverables, gridState?.columnState]);

  const allAvailableStatus = useMemo(() => extractAllAvailableDeliverableStatus(visibleDeliverables), [visibleDeliverables]);

  const totalProjectsByStatusId = useMemo(() => createTotalsPerStatus(projectsDeliverables), [projectsDeliverables]);

  const data = useMemo(() => {
    const statusDatasetGenerator = makeDatasetGeneratorForStatus(visibleDeliverables, totalProjectsByStatusId);

    const datasets = allAvailableStatus.map(statusDatasetGenerator);

    return {
      labels: getTitles(visibleDeliverables),
      ids: getIds(visibleDeliverables),
      datasets,
    };
  }, [visibleDeliverables, totalProjectsByStatusId, allAvailableStatus]);

  return { data };
};

export default usePdlcHeatmapDataGenerator;
