import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_CYCLE_DELIVERABLES'),
  ...getThunkActionTypes('FETCH_PROJECTS_DELIVERABLES'),
  ...getThunkActionTypes('UPSERT_PROJECT_DELIVERABLE'),
  ...getThunkActionTypes('DELETE_PROJECT_DELIVERABLE'),
  ...getThunkActionTypes('FETCH_PROJECT_DELIVERABLE'),
  ...getThunkActionTypes('CREATE_CYCLE'),
  ...getThunkActionTypes('CREATE_DELIVERABLE'),
  ...getThunkActionTypes('DELETE_CYCLE_DELIVERABLE'),
  ...getThunkActionTypes('UPDATE_CYCLE'),
  ...getThunkActionTypes('UPDATE_DELIVERABLE'),
  ...getThunkActionTypes('UPDATE_CYCLE_DELIVERABLE_ROW_ORDER'),
};

export const ADD_CYCLE_WITHOUT_SAVE = 'ADD_CYCLE_WITHOUT_SAVE';
export const ADD_DELIVERABLE_WITHOUT_SAVE = 'ADD_DELIVERABLE_WITHOUT_SAVE';
export const REMOVE_UNSAVED_CYCLE_DELIVERABLE = 'REMOVE_UNSAVED_CYCLE_DELIVERABLE';

export const {
  FETCH_CYCLE_DELIVERABLES,
  FETCH_CYCLE_DELIVERABLES_FULFILLED,
  FETCH_CYCLE_DELIVERABLES_PENDING,
  FETCH_CYCLE_DELIVERABLES_REJECTED,

  FETCH_PROJECTS_DELIVERABLES,
  FETCH_PROJECTS_DELIVERABLES_FULFILLED,
  FETCH_PROJECTS_DELIVERABLES_PENDING,
  FETCH_PROJECTS_DELIVERABLES_REJECTED,

  UPSERT_PROJECT_DELIVERABLE,
  UPSERT_PROJECT_DELIVERABLE_FULFILLED,
  UPSERT_PROJECT_DELIVERABLE_PENDING,
  UPSERT_PROJECT_DELIVERABLE_REJECTED,

  DELETE_PROJECT_DELIVERABLE,
  DELETE_PROJECT_DELIVERABLE_FULFILLED,
  DELETE_PROJECT_DELIVERABLE_PENDING,
  DELETE_PROJECT_DELIVERABLE_REJECTED,

  FETCH_PROJECT_DELIVERABLE,
  FETCH_PROJECT_DELIVERABLE_FULFILLED,
  FETCH_PROJECT_DELIVERABLE_PENDING,
  FETCH_PROJECT_DELIVERABLE_REJECTED,

  CREATE_CYCLE,
  CREATE_CYCLE_FULFILLED,
  CREATE_CYCLE_PENDING,
  CREATE_CYCLE_REJECTED,

  CREATE_DELIVERABLE,
  CREATE_DELIVERABLE_FULFILLED,
  CREATE_DELIVERABLE_PENDING,
  CREATE_DELIVERABLE_REJECTED,

  DELETE_CYCLE_DELIVERABLE,
  DELETE_CYCLE_DELIVERABLE_FULFILLED,
  DELETE_CYCLE_DELIVERABLE_PENDING,
  DELETE_CYCLE_DELIVERABLE_REJECTED,

  UPDATE_CYCLE,
  UPDATE_CYCLE_FULFILLED,
  UPDATE_CYCLE_PENDING,
  UPDATE_CYCLE_REJECTED,

  UPDATE_DELIVERABLE,
  UPDATE_DELIVERABLE_FULFILLED,
  UPDATE_DELIVERABLE_PENDING,
  UPDATE_DELIVERABLE_REJECTED,

  UPDATE_CYCLE_DELIVERABLE_ROW_ORDER,
  UPDATE_CYCLE_DELIVERABLE_ROW_ORDER_FULFILLED,
  UPDATE_CYCLE_DELIVERABLE_ROW_ORDER_PENDING,
  UPDATE_CYCLE_DELIVERABLE_ROW_ORDER_REJECTED,
} = actionTypes;
