import React from 'react';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import SearchBar from './SearchBar';

export default ({ anchorEl, title, items, onItemClick, onClickAway, setRef }) => {
  const [search, setSearch] = React.useState('');
  const searchInput = React.useRef(null);

  React.useEffect(() => {
    setSearch('');
  }, [!anchorEl]);

  const searchedItems = items.filter(i => i.label.toLowerCase().includes(search.toLowerCase()));

  const handleOnItemClick = React.useCallback(
    item => () => {
      if (onItemClick) {
        onItemClick(item);
      }
    },
    [onItemClick],
  );

  return (
    <Popover
      ref={r => setRef && setRef(r)}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      style={{ zIndex: 1001 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Wrapper>
          <Header>{title}</Header>
          <Search>
            <SearchBar ref={searchInput} value={search} onChange={v => setSearch(v)} onCancelSearch={() => setSearch('')} />
          </Search>
          <ResultsList>
            {searchedItems.map(i => (
              <ListItem key={i.key} onClick={handleOnItemClick(i)}>
                {i.label}
              </ListItem>
            ))}
          </ResultsList>
        </Wrapper>
      </ClickAwayListener>
    </Popover>
  );
};

const Wrapper = styled(Paper)`
  &&& {
    display: flex;
    flex-direction: column;
    width: 195px;
    min-height: 150px;
    overflow: hidden;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 12px 12px 10px;
  text-transform: uppercase;
  color: rgba(34, 34, 34, 0.3);
  white-space: nowrap;
  font-size: 14px;
`;

const Search = styled.div`
  padding: 0 12px;
`;

const ResultsList = styled.div`
  max-height: 285px;
  flex: 0 1 auto;
  padding: 4px 0 0;
  overflow: hidden;
  overflow-y: auto;
`;

const ListItem = styled.div`
  font-size: 13px;
  line-height: 18px;
  transition: background-color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 15px;
  cursor: pointer;
  color: #343434;

  &:hover {
    background-color: #fafbfc;
  }
`;
