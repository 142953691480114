import { useDispatch } from 'react-redux';

import { deleteRoadmapVersion } from 'store/roadmapVersions';

const useDeleteScenario = () => {
  const dispatch = useDispatch();

  const onDeleteScenario = scenarioId => {
    if (!scenarioId) return;

    dispatch(deleteRoadmapVersion(scenarioId));
  };

  return { onDeleteScenario };
};

export default useDeleteScenario;
