import axios from 'axios';

import throwRequestError from '../utils/throwRequestError';
import {
  UPDATE_PHASE_BY_ID,
  DELETE_PHASE,
  MERGE_PHASES,
  CREATE_PHASES,
  UPDATE_PHASES,
  UNDO_CREATE_PHASES,
  UNDO_UPDATE_PHASES,
  BULK_DELETE_PHASES,
  UNDO_BULK_DELETE_PHASES,
  UPDATE_PHASE_ROW_ORDER,
} from './types';
import bulkCreateAction from 'store/utils/factory/bulkCreateAction';
import bulkUpdateAction from 'store/utils/factory/bulkUpdateAction';
import bulkDeleteAction from 'store/utils/factory/bulkDeleteAction';
import moveRowToPosition from 'utils/moveRowToPosition';

export const updatePhaseById = (id, update, { prevData } = {}) => {
  return dispatch => {
    const payload = axios
      .put(`/api/phases/${id}`, update)
      .then(response => response.data)
      .catch(throwRequestError);

    dispatch({
      type: UPDATE_PHASE_BY_ID,
      payload,
      meta: { prevData },
    });

    return payload;
  };
};

export function switchPhasesRowOrder(phaseId1, phaseId2, update, position) {
  return (dispatch, getState) => {
    const state = getState().phases.phases || [];
    const prevData = state.find(({ id }) => id === +phaseId1);
    const movedRow = moveRowToPosition(state, phaseId1, phaseId2, position);

    const promise = axios.put(`/api/phases/rowOrder/${phaseId1}/${phaseId2}`, { position }).then(response => {
      if (update) {
        dispatch(
          updatePhaseById(phaseId1, update, {
            prevData,
          }),
        );
      }

      return response.data;
    });

    return dispatch({
      type: UPDATE_PHASE_ROW_ORDER,
      payload: {
        promise,
        data: { ...movedRow, ...(update || {}) },
      },
      meta: { prev: prevData },
    });
  };
}

export const deletePhaseById = id => {
  return dispatch => {
    const payload = axios
      .delete(`/api/phases/${id}`)
      .then(response => response.data)
      .catch(throwRequestError);

    dispatch({
      type: DELETE_PHASE,
      payload,
    });

    return payload;
  };
};

export function mergePhases(phasesIdsToMerge, phaseId) {
  return dispatch => {
    const payload = axios
      .post(`/api/phases/merge/${phaseId}`, {
        phasesIdsToMerge,
      })
      .then(response => {
        return response.data;
      })
      .catch(throwRequestError);

    dispatch({
      type: MERGE_PHASES,
      payload,
    });

    return payload;
  };
}

export const createPhases = bulkCreateAction(CREATE_PHASES, '/api/phases', {
  toastText: 'Phases have been created',
  ACTION_TYPE: UNDO_CREATE_PHASES,
  endpoint: '/api/phases/versions/last',
  store: 'phases',
});
export const updatePhases = bulkUpdateAction(UPDATE_PHASES, '/api/phases', {
  toastText: 'Phases have been changed',
  ACTION_TYPE: UNDO_UPDATE_PHASES,
  endpoint: '/api/phases/versions/last',
  store: 'phases',
});
export const bulkDeletePhases = bulkDeleteAction(BULK_DELETE_PHASES, '/api/phases/', {
  toastText: 'Phases have been deleted',
  ACTION_TYPE: UNDO_BULK_DELETE_PHASES,
  endpoint: '/api/phases/versions/last',
  store: 'phases',
});
