import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getIdeasIntegrations, getOrgHasJiraIntegrated } from 'store/organization/selectors';
import { syncJiraStories, syncStories } from 'store/integrations';
import { fetchOrgIntegrations } from 'store/organization/actions';
import messagesToaster from 'utils/messagesToaster';
import useSinglePurposeSocket from 'hooks/useSinglePurposeSocket';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [isSyncing, setIsSyncing] = useState(false);
    const socket = useSinglePurposeSocket();
    const orgIntegrations = useSelector(getIdeasIntegrations);
    const hasJiraIntegration = useSelector(getOrgHasJiraIntegrated);

    const _syncJira = () => {
      setIsSyncing(true);
      const epicKeys = props.projects.reduce((acc, task) => {
        return task.jira && task.jira.key ? [...acc, task.jira.key] : [...acc];
      }, []);

      dispatch(syncJiraStories(epicKeys, socket))
        .then(() => {
          setIsSyncing(false);
          messagesToaster.syncStories();
          props.syncStories();
        })
        .catch(_ => {
          setIsSyncing(false);
        });
    };

    const _onSyncStories = integrationType => {
      setIsSyncing(true);

      dispatch(syncStories())
        .then(() => {
          setIsSyncing(false);
          messagesToaster.syncStories(integrationType);
          // props.syncStories();
        })
        .catch(_ => {
          setIsSyncing(false);
        });
    };

    useEffect(() => {
      dispatch(fetchOrgIntegrations());
    }, []);

    return (
      <Component
        {...props}
        orgIntegrations={orgIntegrations}
        hasJiraIntegration={hasJiraIntegration}
        syncJira={_syncJira}
        onSyncStories={_onSyncStories}
        isSyncing={isSyncing}
      />
    );
  };
};

export default componentHOC;
