import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import TimelineIcon from '@material-ui/icons/Timeline';
import ViewWeekIcon from '@material-ui/icons/ViewWeekOutlined';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

const isZero = x => x === 0;

const FinishContainer = styled.div`
  padding: 18px;
`;

const FinishButton = styled(Button)`
  &&&& {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export default ({ imported, onClose, onCloseSuccess, getSystemFieldName, existing, existInAccountName = 'Dragonboat' }) => {
  let feedbackMessage = '';
  const existingIdeasMessage = existing ? `${existing} were already on ${existInAccountName}.` : '';

  if (isZero(imported)) {
    feedbackMessage = `All epics are already in ${existInAccountName}.`;
  } else {
    feedbackMessage = `${imported} ${getSystemFieldName('idea', imported !== 1)} imported. ${existingIdeasMessage}`;
  }

  return (
    <FinishContainer>
      <br />
      <Typography style={{ fontSize: '18px' }}>{feedbackMessage}</Typography>
      <br />
      <Fragment>
        <Typography style={{ fontSize: '18px' }}>Next, you may</Typography>
        <br />
        <Typography style={{ fontSize: '18px' }}>
          <Link onClick={() => onClose('ideas-board')} to="/ideas/board" style={{ color: 'initial' }}>
            <ViewWeekIcon style={{ verticalAlign: 'bottom', marginRight: 10 }} />
          </Link>
          Align and prioritize {getSystemFieldName('idea', true)} on the{' '}
          <Link onClick={onClose} to="/ideas/board">
            {getSystemFieldName('idea', true)} Board
          </Link>
        </Typography>
        <br />
        <br />
        <Typography style={{ fontSize: '18px' }}>
          <Link onClick={() => onClose('manage')} to="/ideas/timeline" style={{ color: 'initial' }}>
            <TimelineIcon style={{ verticalAlign: 'bottom', marginRight: 10 }} />
          </Link>
          {'Adjust timelines and assign resources on the '}
          <Link onClick={() => onClose('manage')} to="/ideas/timeline">
            Manage
          </Link>{' '}
          page
        </Typography>
      </Fragment>
      <br />
      <FinishButton color="primary" onClick={onCloseSuccess || onClose}>
        Close
      </FinishButton>
    </FinishContainer>
  );
};
