import { defaultTo, isNil, not, path, pipe, prop } from 'ramda';

import { _parseProjectIntegration, parseJiraIntegrationData } from 'store/projects/helpers/enrichProjects';

const DATA = 'data';
const INTEGRATION_DATA = 'integrationData';
const PROJECT_INTEGRATION = 'projectIntegration';

const defaultToNull = defaultTo(null);
const isNotNil = pipe(isNil, not);

const getIntegrationData = prop(INTEGRATION_DATA);
const getProjectIntegration = prop(PROJECT_INTEGRATION);

const getDataFromIntegrationData = pipe(path([INTEGRATION_DATA, DATA]), defaultToNull);

const hasIntegrationData = pipe(getIntegrationData, isNotNil);
const hasProjectIntegration = pipe(getProjectIntegration, isNotNil);

/**
 * @function parseIntegration
 *
 * Parse the integrationData or projectIntegration of a given error log.
 * If none of them is defined return null.
 *
 * @param {Object} errorLog
 * @param {Array} orgIntegrations
 * @returns {Object}
 */
export const parseIntegration = (errorLog, orgIntegrations) => {
  switch (true) {
    case hasProjectIntegration(errorLog):
      return _parseProjectIntegration(getProjectIntegration(errorLog), orgIntegrations);
    case hasIntegrationData(errorLog):
      return parseJiraIntegrationData(getDataFromIntegrationData(errorLog));
    default:
      return null;
  }
};
