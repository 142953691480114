import { useCallback } from 'react';

import { PDLC } from 'store/grids/constants';
import { useSettingsGridEventHandlers } from 'containers/Grids/SettingsGrid/hooks';

const TITLE_FIELD = 'title';

const getDataId = ({ data }) => data.id;
const getDataLevel = ({ data }) => data.level;
const getDataParentId = ({ data }) => data.parent_id;

const usePDLCGridEventHandlers = ({
  cycleDeliverables,
  update,
  createCycleDeliverable,
  removeUnsavedCycleDeliverable,
  switchCycleDeliverableRowOrder,
}) => {
  const handleRowDrag = useCallback(
    (currentNode, lastOverNode, { position }) => {
      const movedCycleDeliverableId = getDataId(currentNode);
      const targetCycleDeliverableId = getDataId(lastOverNode);

      const movedCycleDeliverableLevel = getDataLevel(currentNode);
      const targetCycleDeliverableLevel = getDataLevel(lastOverNode);

      if (movedCycleDeliverableId && targetCycleDeliverableId && position) {
        const selectedLevelHigherThanMoveToLevel = movedCycleDeliverableLevel < targetCycleDeliverableLevel;

        const targetCycleDeliverableId = selectedLevelHigherThanMoveToLevel
          ? getDataParentId(lastOverNode)
          : getDataId(lastOverNode);

        switchCycleDeliverableRowOrder(movedCycleDeliverableId, targetCycleDeliverableId, position);
      }
    },
    [switchCycleDeliverableRowOrder],
  );

  return useSettingsGridEventHandlers({
    settingType: PDLC,
    items: cycleDeliverables,
    focusField: TITLE_FIELD,
    actions: {
      update,
      save: createCycleDeliverable,
      remove: removeUnsavedCycleDeliverable,
      drag: handleRowDrag,
    },
  });
};

export default usePDLCGridEventHandlers;
