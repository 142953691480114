import React, { PureComponent, Fragment } from 'react';
import { toast } from 'react-toastify';
import { not } from 'ramda';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ImportIcon from '@material-ui/icons/CloudUploadOutlined';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import Board from 'react-trello';
import pick from 'lodash/pick';
import arrayMove from 'array-move';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SettingsIcon from '@material-ui/icons/Settings';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import Dropdown from 'design-system/molecules/Dropdown/index';
import ToggleButton from 'design-system/molecules/ToggleButton/index';
import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete/index';

import FormGroup from '@material-ui/core/FormGroup';
import { ConfirmDialog } from 'components/gridCommon';
import ImportIdeasDialog from 'containers/ImportIdeasDialog';
import MergeLightbox from 'components/MergeLightbox';
import { IDEAS_BOARD_PAGE } from 'constants/filters';
import ShareView from 'containers/ShareView';
import { materialBackground, materialColors } from 'design-system/themes/default';
import theme from 'design-system/theme';
import { ucFirst, getUserName, getDuplicatedToastErrorMessage } from 'utils';
import sortRowOrder from 'utils/sortRowOrder';
import { checkRolePermission } from 'containers/UserPermission/utils';
import getSystemFieldName from 'utils/getSystemFieldName';
import { ROADMAP, SUB_ROADMAP, DUPLICATED_ERROR_CODE } from 'config';
import LocalSearchInput from 'containers/LocalSearchInput';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import './IdeasBoard.css';
import PreferencesDialog from './PreferencesDialog/index';
import CardTemplate from './CardTemplate';
import NewCardTemplate from './NewCardTemplate';
import LaneHeaderTemplate from './LaneHeaderTemplate';
import NewLaneTemplate from './NewLaneTemplate';
import {
  getSettingsUrl,
  getGroupOptions,
  getDataTypeTitle,
  getRoadmapIdForProduct,
  dataTypeToPermissionResourceMapper,
} from './utils';
import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import sortByRank from 'utils/sortByRank';
import { LANE_WIDTH, METADATA_ROADMAP_DATA_TYPES, SECOND_LAYER_GROUPS, SECOND_LAYER_METADATA_GROUPS } from './constants';
import { KEY_RESULT_LEVEL, TIMEFRAME_LEVEL } from 'constants/common';
import ShowMyItemsToggle from 'containers/ShowMyItemsToggle';
import { CreateRoadmapSnapshotButton } from 'features/RoadmapHistory/components';

let currentLaneOrder = [];

const getLaneBorderColor = displayLayer => {
  switch (displayLayer) {
    case IDEA_LAYER:
      return theme.palette.newLayout.background.header;
    case INITIATIVE_LAYER:
      return theme.palette.newLayout.background.header;
    case BET_LAYER:
      return materialColors.curiousBlue;
    default:
      return '#f9f4ff';
  }
};

const BOARD_READ_ONLY_DATA_TYPES = ['planningStage'];

const KEY_FIELD_MAP = {
  objectiveCorp: 'objective_corp_id',
  timeframeCorp: 'timeframe_corp_id',
  timeframe2: 'timeframe_2_id',
  roadmapCorp: 'roadmap_corp_id',
  team: 'resource_team_id',
  // categoryCorp: 'category_corp_id',
};

/* eslint-disable react/no-redundant-should-component-update */
class IdeasBoard extends PureComponent {
  boardData = { lanes: [] };

  state = {
    laneToEdit: null,
    laneToDelete: null,
    toSelectLanes: false,
    mergeLightboxVisible: false,
    waitForSwitchOrder: false,
    selectedLanes: [],
    selectionMode: false,
    selectedItems: [],
    lanesPages: {},
    preferencesDialogOpen: false,
    readOnly: BOARD_READ_ONLY_DATA_TYPES.includes(this.props.lsState.dataType),
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !nextState.laneToEdit && !nextState.waitForSwitchOrder;
  }

  getLaneCollection = () => {
    let collection;

    if (this.props.lsState.dataType === 'initiative') {
      collection = this.props.initiativesForGrouping;
    } else if (this.props.lsState.dataType === 'bet') {
      collection = this.props.betsForGrouping;
    }

    return collection;
  };

  getCollectionFromProject = project => {
    switch (project.layer) {
      case IDEA_LAYER:
        return this.props.projects;
      case INITIATIVE_LAYER:
        return this.props.initiativesForGrouping;
      case BET_LAYER:
        return this.props.betsForGrouping;
      default:
        return this.props.projects;
    }
  };

  getBackgroundColor = () => {
    return '#fff';
  };

  getLaneStyle = () => {
    return {
      width: LANE_WIDTH,
      backgroundColor: materialBackground.alabaster,
      borderRadius: '4px',
      borderTop: `3px solid ${getLaneBorderColor(this.props.displayLayer)}`,
    };
  };

  generateBoardData = () => {
    let lanes = [];
    const { lsState, emptyLaneVisibility, pageFilters } = this.props;
    const { dataType } = lsState;

    let { projects } = this.props;

    const processDataType = (type, data) => {
      let fieldName = KEY_FIELD_MAP[type] ? KEY_FIELD_MAP[type] : `${type}_id`;
      let laneData = data;

      const mapfieldToFilter = {
        timeframeCorp: 'timeframesCorp',
        timeframe: 'timeframes',
        roadmapCorp: 'roadmapsCorp',
        roadmap: 'roadmaps',
        objectiveCorp: 'objectivesCorp',
        objective: 'objectives',
        theme: 'themes',
        // categoryCorp: 'categoriesCorp',
        category: 'categories',
        phase: 'phases',
        product: 'products',
        keyResult: 'keyResults',
        keyResult2: 'keyResult2s',
        timeframe2: 'timeframes2',
      };
      const filterField = mapfieldToFilter[type];
      const filter = pageFilters[filterField] || [];
      const op = pageFilters.op && pageFilters.op[filterField];
      const { quickFilters } = pageFilters;
      const fieldTypeHasQuickFilter = quickFilters && quickFilters[filterField] && quickFilters[filterField].length;

      if (op === 'notIn' && !fieldTypeHasQuickFilter) {
        laneData = laneData.filter(i => !filter.some(f => String(f) === String(i.id)));
      } else if (fieldTypeHasQuickFilter && quickFilters && quickFilters[filterField]) {
        laneData = laneData.filter(i => quickFilters[filterField].some(f => String(f) === String(i.id)));
      } else if (filter && filter.length) {
        laneData = laneData.filter(i => filter.some(f => String(f) === String(i.id)));
      }

      // TODO: deal with this in a proper way included on the generic grouping logic
      // Archived Level 2 metadata and parent are not being filtered on selector
      // So here we are excluding them manually on board page
      // Also, for Level 2 metadata when we use Select <L1> on top of board page we must exclude
      // results that are not in the selected L1
      const typesToDealWithArchived = {
        product: { fieldName: 'product_1_id', parentFieldName: 'roadmap_id' },
        keyResult: { fieldName: 'key_result_1_id', parentFieldName: 'objective_id' },
        keyResult2: {
          fieldName: 'key_result_2_id',
          parentFieldName: 'objective_id',
        },
        bet: { fieldName: 'parent_id' },
        initiative: { fieldName: 'parent_id' },
      };

      if (Object.keys(typesToDealWithArchived).find(k => k === type)) {
        ({ fieldName } = typesToDealWithArchived[type]);
        const { parentFieldName } = typesToDealWithArchived[type];

        const shouldFilterOutProjectsFromOtherL1 = !!parentFieldName;

        if (shouldFilterOutProjectsFromOtherL1) {
          const isFieldSetOnProject = p => !!p[fieldName];
          const isSecondLayerFilterSet = !!this.props.lsState.secondLayerFilterId;
          const doesSecondFilterMatchProjectL1 = p =>
            String(this.props.lsState.secondLayerFilterId) === String(p[parentFieldName]);

          const shouldShowProject = project =>
            !isFieldSetOnProject(project) || !isSecondLayerFilterSet || doesSecondFilterMatchProjectL1(project);

          projects = projects.filter(shouldShowProject);
        }
        laneData = laneData.filter(item => item.status !== 'Archived');
      }

      return this.genericSerializer(type, laneData, projects, fieldName);
    };

    let dataTypeProp = this.props[`${dataType}s`];

    if (dataType === 'initiative') dataTypeProp = this.props.initiativesForGrouping;
    if (dataType === 'bet') dataTypeProp = this.props.betsForGrouping;
    if (dataType === 'timeframe2') dataTypeProp = this.props.timeframes2;
    if (dataType === 'timeframeCorp') dataTypeProp = this.props.timeframesCorp;
    if (dataType === 'objectiveCorp') dataTypeProp = this.props.objectivesCorp;
    if (dataType === 'roadmapCorp') dataTypeProp = this.props.roadmapsCorp;
    // if (dataType === 'categoryCorp') dataTypeProp = this.props.categoriesCorp;

    lanes = processDataType(dataType, dataTypeProp);
    if (!emptyLaneVisibility) lanes = lanes.filter(lane => lane.cards && lane.cards.length);

    lanes = lanes.map(lane => {
      const paginateArray = (array, pageSize, pageNumber) => {
        return array.slice(0, pageNumber * pageSize);
      };

      return {
        ...lane,
        cards: paginateArray(lane.cards, 15, this.state.lanesPages[lane.id] || 1),
      };
    });

    currentLaneOrder = lanes.map(l => l.id);

    this.boardData = {
      lanes: this.addLanesActions(lanes),
    };

    return this.boardData;
  };

  addLanesActions = data => {
    const editLane = lane => {
      const laneCollection = this.getLaneCollection();

      if (laneCollection) {
        // this.props.updateState({ lightboxProject: laneCollection.find(p => +p.id === +lane.id) });
        this.props.openProjectLightbox(lane.id);
        this.setState({ laneToEdit: null });
      } else {
        this.setState({ laneToEdit: lane });
      }
    };

    const updateMap = {
      objective: this.props.updateObjectiveById,
      roadmap: this.props.updateRoadmapById,
      theme: this.props.updateThemeById,
      category: this.props.updateCategoryById,
      phase: this.props.updatePhaseById,
      timeframe: this.props.updateTimeframeById,
      keyResult: this.props.updateKeyResultById,
      product: this.props.updateProductById,
      initiative: this.props.updateProject,
      bet: this.props.updateProject,
    };
    const { dataType } = this.props.lsState;
    const updateFunc = updateMap[dataType];
    // TODO: PERMISSION
    const userHasPerm = checkRolePermission('BOARD_EDIT_LANE', this.props.currentUser);

    let layer;

    if (dataType === 'initiative') layer = INITIATIVE_LAYER;
    else if (dataType === 'bet') layer = BET_LAYER;

    return data.map(lane => ({
      ...lane,
      group: dataType,
      toSelect: this.state.toSelectLanes,
      editDisabled: this.state.toSelectLanes || parseInt(lane.id) === -1 || !userHasPerm || !updateFunc,
      editLane: () => editLane(lane),
      cancelEditLane: () => this.setState({ laneToEdit: null }),
      onChangeTitle: title => updateFunc(lane.id, { title, layer }),
      isProject: ['initiative', 'bet'].includes(dataType),
      onArchive: () => updateFunc(lane.id, { status: 'Archived' }),
      onDelete: () => this.setState({ laneToDelete: lane }),
      onMerge: () => this.setState({ toSelectLanes: true, selectedLanes: [] }),
      onMergeSelect: () => {
        if (!this.state.selectedLanes.some(l => l.id === lane.id)) {
          this.setState({ selectedLanes: [...this.state.selectedLanes, pick(lane, ['id', 'title'])] });
        } else {
          this.setState({ selectedLanes: this.state.selectedLanes.filter(l => l.id !== lane.id) });
        }
      },
      checked: this.state.selectedLanes.some(l => l.id === lane.id),
    }));
  };

  mergeLanes = (idsToMerge, id) => {
    const { dataType } = this.props.lsState;
    const mergeMap = {
      objective: this.props.mergeObjectives,
      keyResult: this.props.mergeKeyResults,
      roadmap: this.props.mergeRoadmaps,
      product: this.props.mergeProducts,
      theme: this.props.mergeThemes,
      category: this.props.mergeCategories,
      phase: this.props.mergePhases,
      timeframe: this.props.mergeTimeframes,
    };
    const mergeFunc = mergeMap[dataType];

    return mergeFunc(idsToMerge, id).then(r => this.props.loadProjects());
  };

  deleteLane = () => {
    const { dataType } = this.props.lsState;
    const deleteMap = {
      objective: this.props.requestRemoveObjectiveById,
      roadmap: this.props.deleteRoadmapById,
      theme: this.props.deleteTheme,
      category: this.props.deleteCategory,
      phase: this.props.deletePhaseById,
      timeframe: this.props.deleteTimeframeById,
      keyResult: this.props.deleteKeyResultById,
      product: this.props.deleteProductById,
    };
    const deleteFunc = deleteMap[dataType];

    deleteFunc(this.state.laneToDelete.id).then(r => {
      this.setState({ laneToDelete: null });
      // we need to refresh the data on the lanes after delete lane
      this.props.loadProjects();
    });
  };

  cancelMergeLanes = () => {
    this.setState({ toSelectLanes: false, selectedLanes: [] });
  };

  genericSerializer = (itemName, items = [], projects, fieldName = null) => {
    const laneOrderObj = this.props.lsState.laneOrderObj || {};
    const itemLaneOrder = laneOrderObj[itemName];

    const countInProgressProjects = projects => {
      return projects.filter(p => p.jira && (p.jira.in_progress_tickets > 0 || p.jira.closed_tickets > 0)).length;
    };

    const getUnassignedProjects = projects => {
      return projects.filter(p => this.passesFilters(p) && (!p[fieldName] || !items.find(i => i.id === p[fieldName])));
    };

    if (!fieldName) fieldName = `${itemName}_id`;
    if (itemName === 'planningStage') fieldName = itemName;

    const unassignedProjects = getUnassignedProjects(projects);
    const unassigned = [
      {
        id: '-1',
        title: 'Undefined',
        totalCount: unassignedProjects.length,
        inProgressCount: countInProgressProjects(unassignedProjects),
        jiraIntegrated: this.props.jiraIntegrated,
        style: this.getLaneStyle(),
        cards: unassignedProjects.map(this.serializeProject) || [].sort(sortRowOrder),
      },
    ];

    let lanes = items
      .map(item => {
        const itemProjects = projects.filter(p => this.passesFilters(p) && p[fieldName] === item.id).map(this.serializeProject);

        let description;

        if ((itemName === 'keyResult2' || itemName === 'keyResult') && !this.props.lsState.secondLayerFilterId) {
          const objective = this.props.objectives.find(obj => obj.id === item.objective_id);

          description = objective ? objective.title : '';
        } else if (itemName === 'product' && !this.props.lsState.secondLayerFilterId) {
          const roadmap = this.props.roadmaps.find(obj => obj.id === item.roadmap_id);

          description = roadmap ? roadmap.title : '';
        } else if (itemName === 'timeframe2' && !this.props.lsState.secondLayerFilterId) {
          const timeframe = this.props.timeframes.find(obj => obj.id === item.parent_id);

          description = timeframe ? timeframe.title : '';
        }

        const result = {
          id: item.id,
          title: item.title,
          votes: item.votes,
          description,
          rank: item.rank,
          totalCount: itemProjects.length,
          inProgressCount: countInProgressProjects(itemProjects),
          jiraIntegrated: this.props.jiraIntegrated,
          style: this.getLaneStyle(),
          cards: itemProjects.sort(sortRowOrder),
        };

        if (['initiative', 'bet'].includes(itemName)) {
          result._metadata = {
            roadmap: item.roadmap,
            product1: item.product1,
            objective: item.objective,
            keyResult1: item.keyResult1,
          };
        }

        return result;
      })
      .filter(i => !!i.title);

    if (itemLaneOrder && itemLaneOrder.length) {
      // we need to add always the new ones on the end
      const newLanes = lanes.filter(l => !itemLaneOrder.includes(l.id));
      const order = [...itemLaneOrder, ...newLanes.map(l => l.id)];

      lanes = lanes.sort((a, b) => (order.indexOf(a.id) < order.indexOf(b.id) ? -1 : 1));
    } else if (itemName !== 'planningStage') {
      lanes.sort((a, b) => {
        if (a.rank && b.rank) return sortByRank(a, b);

        return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
      });
    }

    if (itemName !== 'planningStage') lanes = unassigned.concat(lanes);

    return lanes;
  };

  serializeProject = project => Object.assign(project, { id: `${project.id}` });

  passesFilters = project => {
    const { applyFrontendFiltersOnProject } = this.props;

    return applyFrontendFiltersOnProject ? applyFrontendFiltersOnProject(project) : true;
  };

  updateSearchString = searchString => {
    this.props.updateState({ searchString });
    this.setState({ lanesPages: {} });
  };

  handleDataTypeChange = e => {
    if (this.state.toSelectLanes) {
      this.cancelMergeLanes();
    }

    const newState = { dataType: e.key, laneOrderObj: {} };

    const readOnly = BOARD_READ_ONLY_DATA_TYPES.includes(e.key);

    if (SECOND_LAYER_GROUPS.indexOf(e.key) > -1) {
      newState.secondLayerFilterId = this.getSecondLayerGroupingOptions(e.key)[0].key;
    }

    this.props.updateState(newState);
    this.setState({ lanesPages: {}, readOnly });
  };

  handleSecondLayerGroupingObject = e => {
    if (this.state.toSelectLanes) {
      this.cancelMergeLanes();
    }
    this.props.updateState({ secondLayerFilterId: e ? e.key : null });
    this.setState({ lanesPages: {} });
  };

  handleDisplayFilter = e => {
    if (this.state.toSelectLanes) {
      this.cancelMergeLanes();
    }
    this.props.updateState({ displayFilter: e ? e.key : null });
    this.setState({ lanesPages: {} });
  };

  updateProjectData = (project, laneId, isCreate = false) => {
    if (this.props.lsState.dataType === 'objective') {
      const objective = laneId !== '-1' ? this.props.objectives.find(bg => bg.id === +laneId) : '';

      project.objectiveTitle = objective ? objective.title : '';
    } else if (this.props.lsState.dataType === 'roadmap') {
      const roadmap = laneId !== '-1' ? this.props.roadmaps.find(r => r.id === +laneId) : '';

      project.roadmapTitle = roadmap ? roadmap.title : '';
    } else if (this.props.lsState.dataType === 'theme') {
      const theme = laneId !== '-1' ? this.props.themes.find(t => t.id === +laneId) : '';

      project.themeTitle = theme ? theme.title : '';
    } else if (this.props.lsState.dataType === 'category') {
      const category = laneId !== '-1' ? this.props.categorys.find(c => c.id === +laneId) : '';

      project.categoryTitle = category ? category.title : '';
    } else if (this.props.lsState.dataType === 'phase') {
      const phase = laneId !== '-1' ? this.props.phases.find(p => p.id === +laneId) : '';

      project.phaseTitle = phase ? phase.title : '';
    } else if (this.props.lsState.dataType === 'timeframe') {
      const timeframe = laneId !== '-1' ? this.props.timeframes.find(r => r.id === +laneId) : '';

      project.timeframeTitle = timeframe ? timeframe.title : '';
    } else if (this.props.lsState.dataType === 'timeframe2') {
      const timeframe2 = laneId !== '-1' ? this.props.timeframes2.find(r => r.id === +laneId) : '';

      if (timeframe2) {
        project.timeframeTitle = this.props.timeframes.find(r => r.id === timeframe2.parent_id)?.title;
      }

      project.timeframe2Title = timeframe2 ? timeframe2.title : '';
    } else if (this.props.lsState.dataType === 'keyResult') {
      const keyResult = laneId !== '-1' ? this.props.keyResults.find(r => r.id === +laneId) : '';

      project.objectiveTitle = keyResult ? this.props.objectives.find(obj => obj.id === keyResult.objective_id)?.title : null;
      if (!project.objectiveTitle) delete project.objectiveTitle;
      project.keyResult1Title = keyResult ? keyResult.title : null;
    } else if (this.props.lsState.dataType === 'keyResult2') {
      const keyResult2 = laneId !== '-1' ? this.props.keyResult2s.find(r => r.id === +laneId) : '';

      project.keyResult2Title = keyResult2 ? keyResult2.title : null;
    } else if (this.props.lsState.dataType === 'product') {
      const product = laneId !== '-1' ? this.props.products.find(r => r.id === +laneId) : '';

      project.roadmapTitle = product ? this.props.roadmaps.find(obj => obj.id === product.roadmap_id)?.title : null;
      if (!project.roadmapTitle) delete project.roadmapTitle;
      project.product1Title = product ? product.title : '';
    } else if (this.props.lsState.dataType === 'initiative' || this.props.lsState.dataType === 'bet') {
      project.parent_id = laneId !== '-1' ? laneId : null;
      const parent = this.boardData.lanes.find(({ id }) => id === laneId);

      if (isCreate && parent._metadata) {
        if (parent._metadata.roadmap) project.roadmapTitle = parent._metadata.roadmap.title;
        if (parent._metadata.product1) project.product1Title = parent._metadata.product1.title;
        if (parent._metadata.objective) project.objectiveTitle = parent._metadata.objective.title;
        if (parent._metadata.keyResult1) project.keyResult1Title = parent._metadata.keyResult1.title;
      }
    } else if (this.props.lsState.dataType === 'planningStage') {
      project.planningStage = laneId;
    } else if (this.props.lsState.dataType === 'team') {
      const team = laneId !== '-1' ? this.props.teams.find(t => t.id === +laneId) : '';

      project.resource_team_id = team ? team.id : null;
    }
    return project;
  };

  handleCardAdd = (card, laneId, autoFillData = {}) => {
    const project = {
      layer: this.props.displayLayer,
      roadmapTitle: this.props.recentlyUsedData ? this.props.recentlyUsedData.roadmapTitle : null,
      timeframeTitle: this.props.recentlyUsedData ? this.props.recentlyUsedData.timeframeTitle : null,
      ownerName: getUserName(this.props.currentUser),
      ...autoFillData,
    };

    this.updateProjectData(project, laneId, true);
    this.props.createProject(Object.assign(card, project));
  };

  handleDragEnd = (cardId, sourceLaneId, targetLaneId, position) => {
    const project = this.props.projects.find(p => +p.id === +cardId);

    if (!project) {
      return;
    }

    if (sourceLaneId === targetLaneId) {
      // eslint-disable-next-line eqeqeq
      const laneCards = this.boardData.lanes.find(l => l.id == sourceLaneId).cards;
      const otherProject = laneCards[position] || {};
      const laneIds = laneCards.map(p => +p.id);
      const projectPos = laneIds.findIndex(id => id === +project.id);
      const otherProjectPos = laneIds.findIndex(id => id === +otherProject.id);
      const positionLabel = projectPos > otherProjectPos ? 'top' : 'bottom';

      this.props.switchProjectRowOrder(project.id, otherProject.id, null, positionLabel);
      return;
    }

    if (this.state.readOnly) {
      this.props.toggleDialog(true);
      project[this.props.lsState.dataType] = sourceLaneId;
      return;
    }

    const lane = this.boardData.lanes.find(
      l =>
        // eslint-disable-next-line eqeqeq
        l.id == targetLaneId,
    );

    const otherProject = lane.cards.length > 1 ? lane.cards[position] || lane.cards[position - 1] : lane.cards[0] || {};

    // update project to new lane
    const waitForSwitchOrder = wait => this.setState({ waitForSwitchOrder: wait });

    waitForSwitchOrder(true);
    this.updateProjectData(project, targetLaneId);
    this.props.updateProject(project).then(r => {
      if (!lane.cards.length) {
        waitForSwitchOrder(false);
        return;
      }
      const laneIds = lane.cards.map(p => +p.id);
      const otherProjectPos = laneIds.findIndex(id => id === +otherProject.id);
      const positionLabel = position === otherProjectPos ? 'top' : 'bottom';

      this.props.switchProjectRowOrder(project.id, otherProject.id, null, positionLabel).then(r => {
        waitForSwitchOrder(false);
      });
    });
  };

  handleLaneDragEnd = (oldPosition, newPosition) => {
    const laneOrderObj = this.props.lsState.laneOrderObj || {};

    // TODO: PERMISSION
    if (!checkRolePermission('BOARD_EDIT_LANE', this.props.currentUser)) {
      this.setState({});
      return;
    }

    currentLaneOrder = arrayMove(currentLaneOrder, oldPosition, newPosition);
    laneOrderObj[this.props.lsState.dataType] = currentLaneOrder.slice();

    this.props.updateState({ laneOrderObj });
  };

  deleteProject = id => {
    this.props.deleteProjects([id]);
    this.props.updateState({ deletingProject: null });
  };

  onDataChange = data => {
    if (this.boardData.lanes.length < data.lanes.length) {
      const { filterFieldName, filterValue, hasMetadataRoadmaps, lsState, allProducts, hasMultiLevelPortfolioMetadata } =
        this.props;
      const { dataType, secondLayerFilterId } = lsState;

      const laneIds = this.boardData.lanes.map(l => l.id);
      const newLane = data.lanes.find(l => !laneIds.includes(l.id));
      let createMethod = this.props[`create${ucFirst(dataType)}`];

      if (dataType === 'keyResult' || dataType === 'keyResult2') {
        newLane.objective_id = secondLayerFilterId;
        newLane.keyResults = [];
        newLane.level = KEY_RESULT_LEVEL[dataType];
      }
      if (dataType === 'product') {
        newLane.roadmap_id = secondLayerFilterId;
        newLane.products = [];
      }

      if (hasMultiLevelPortfolioMetadata && dataType === 'timeframe2') {
        createMethod = this.props.createTimeframe;
        newLane.parent_id = secondLayerFilterId;
        newLane.level = TIMEFRAME_LEVEL[dataType];
      }

      if (
        hasMetadataRoadmaps &&
        METADATA_ROADMAP_DATA_TYPES.includes(dataType) &&
        (filterFieldName === 'roadmaps' || filterFieldName === 'products')
      ) {
        const [filterId] = filterValue;

        newLane.roadmap_id = filterFieldName === 'roadmaps' ? filterId : getRoadmapIdForProduct(allProducts, filterId);
        newLane.product_1_id = filterFieldName === 'roadmaps' ? undefined : filterId;
      }

      if (typeof createMethod === 'function') {
        createMethod(newLane)
          .then(res => {
            if (!this.props.emptyLaneVisibility) {
              toast('Please click the hide empty lane icon to see the newly created lane.');
            }
          })
          .catch(err => {
            if (!err.response) {
              this.setState({ forceRefresh: true });
              return;
            }
            const { data } = err.response;

            if (data && data.error_code === DUPLICATED_ERROR_CODE) {
              toast(
                getDuplicatedToastErrorMessage(
                  getSystemFieldName(dataType),
                  newLane.title,
                  getSystemFieldName(ROADMAP),
                  getSystemFieldName(SUB_ROADMAP),
                  getSettingsUrl(dataType),
                ),
              );
            }

            this.setState({ forceRefresh: true });
          });
      }
    }
  };

  getSecondLayerGroupingOptions = dataType => {
    const { lsState } = this.props;

    dataType = dataType || lsState.dataType;

    const empty = {
      key: null,
      title: 'Select',
    };

    const metadata = SECOND_LAYER_METADATA_GROUPS[dataType];

    if (metadata && this.props[metadata]) {
      return [empty].concat(this.props[metadata].map(obj => ({ key: obj.id, title: obj.title })));
    }

    return null;
  };

  getSecondLayerSelectedFilter = () => {
    const { lsState } = this.props;

    return this.getSecondLayerGroupingOptions().find(o => o.key === lsState.secondLayerFilterId);
  };

  getParentTitle = () => {
    const { lsState, systemFields } = this.props;
    const { dataType } = lsState;

    if (dataType === 'product') {
      return getSystemFieldName('roadmap', systemFields);
    }

    if (dataType === 'timeframe2') {
      return getSystemFieldName('timeframe', systemFields);
    }

    return getSystemFieldName('objective', systemFields);
  };

  _getGroupOptions = () => {
    const {
      hasKeyResults,
      hasKeyResults2,
      hasProducts,
      systemFields,
      hasHierarchy,
      hasBet,
      hasMultiLevelPortfolioMetadata,
      isDodActive,
      canView,
    } = this.props;

    let options = getGroupOptions(systemFields, hasHierarchy, hasMultiLevelPortfolioMetadata, isDodActive, { canView });

    if (!hasKeyResults) {
      options = options.filter(obj => obj.key !== 'keyResult');
    }

    if (!hasKeyResults2) {
      options = options.filter(obj => obj.key !== 'keyResult2');
    }

    if (!hasProducts) {
      options = options.filter(obj => obj.key !== 'product');
    }

    if (!hasBet) {
      options = options.filter(obj => obj.key !== 'bet');
    }

    return options;
  };

  _getDataTypeTitle = dataTypeKey => {
    const { systemFields, hasHierarchy, hasMultiLevelPortfolioMetadata } = this.props;

    return getDataTypeTitle(dataTypeKey, systemFields, hasHierarchy, hasMultiLevelPortfolioMetadata);
  };

  componentDidUpdate = prevProps => {
    if (this.props.displayLayer !== prevProps.displayLayer) this.cancelMergeLanes();
  };

  _canUpdateLane = (isAdd = false) => {
    if (['initiative', 'bet'].includes(this.props.lsState.dataType)) {
      const canAddProject = this.props.canCreate(PERMISSION_RESOURCES.project, {
        project: { label: this.props.lsState.dataType },
      });

      return isAdd ? false : canAddProject;
    }

    if (['keyResult', 'product', 'timeframe2'].includes(this.props.lsState.dataType) && !this.props.lsState.secondLayerFilterId) {
      return false;
    }
    if (['keyResult2'].includes(this.props.lsState.dataType)) return false;

    const permissionResource = dataTypeToPermissionResourceMapper[this.props.lsState.dataType];
    const canAddMetadata = this.props.canCreate(permissionResource);
    const canUpdateMetadata = this.props.canUpdate(permissionResource);
    const isUpdateAction = not(isAdd);

    if (isAdd && !canAddMetadata) {
      return false;
    }

    if (isUpdateAction && !canUpdateMetadata) {
      return false;
    }

    return true;
  };

  enableSelectionMode = () => {};

  disableSelectionMode = () => {
    this.setState({ selectionMode: false });
  };

  _openDisplaySettings = () => {
    this.setState({ preferencesDialogOpen: true });
  };

  _getMoreOptions = () =>
    [
      {
        id: 'preferences',
        key: 'preferences',
        title: (
          <span onClick={() => this._openDisplaySettings()} role="button" tabIndex={0}>
            <SettingsIcon style={{ verticalAlign: 'bottom' }} />
            <span style={{ paddingLeft: 13 }}>Display preferences</span>
          </span>
        ),
      },
      {
        id: 'import',
        key: 'import',
        title: (
          <span>
            <ImportIcon style={{ verticalAlign: 'bottom' }} />
            <span style={{ paddingLeft: 13 }}>Import</span>
          </span>
        ),
        // TODO: PERMISSION
        hide: !checkRolePermission('IMPORT_IDEAS', this.props.currentUser),
      },
    ].filter(o => !o.hide);

  _onMoreDropdownClick = (e, { id }) => {
    const mapIdToAction = {
      import: () => this.props.showImportIdeasLightbox(),
    };
    const action = mapIdToAction[id];

    if (action) action();
  };

  render() {
    const {
      currentUser,
      lsState,
      emptyLaneVisibility,
      updateEmptyLaneVisibility,
      warning,
      onWarningClick,
      showPageConfigReadOnly,
      showMyItemsOnly,
      hasRoadmapHistory,
    } = this.props;
    const { selectionMode, selectedItems, preferencesDialogOpen } = this.state;
    const data = this.generateBoardData();
    const groupOptions = this._getGroupOptions();

    const canUpdateLane = this._canUpdateLane();
    const canAddLane = this._canUpdateLane(true);

    const moreOptions = this._getMoreOptions();
    const onMoreDropdownClick = this._onMoreDropdownClick;

    const isAnonymousUser = currentUser.is_anonymous;

    const isEditableAndDraggable = not(isAnonymousUser);
    const shouldRenderAddNewProject = this.props.canCreate(PERMISSION_RESOURCES.project, {
      project: { layer: this.props.displayLayer },
    });

    return (
      <div className="IdeasBoard">
        {showPageConfigReadOnly && (
          <div style={{ margin: '0px 20px 5px 20px', paddingLeft: 14, height: '50px' }}>
            <Grid container>
              <Grid item md={12} style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <FormGroup row>
                  <GroupByContainer>
                    <StyledGroupByAutocomplete
                      name="groupBy"
                      suggestions={groupOptions}
                      value={groupOptions.find(o => o.key === lsState.dataType)}
                      onChange={this.handleDataTypeChange}
                    />
                    {this.getSecondLayerGroupingOptions() && (
                      <>
                        <Spacing />
                        <StyledGroupByAutocomplete
                          label={`Select ${this.getParentTitle()}`}
                          name="groupBy"
                          suggestions={this.getSecondLayerGroupingOptions()}
                          value={this.getSecondLayerSelectedFilter()}
                          onChange={this.handleSecondLayerGroupingObject}
                        />
                      </>
                    )}

                    {selectionMode && (
                      <Fragment>
                        <Button
                          title="Bulk Delete"
                          color="secondary"
                          disabled={!selectedItems.length}
                          onClick={() => this.setState({ showBulkDeleteDialog: true })}
                        >
                          Delete
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => this.setState({ showBulkUpdateDialog: true })}
                          disabled={!selectedItems.length}
                        >
                          Update
                        </Button>
                        <Button onClick={() => this.setState({ selectionMode: false })}>Cancel</Button>
                      </Fragment>
                    )}

                    {this.state.toSelectLanes && (
                      <Fragment>
                        <ButtonIcon
                          onClick={() => this.setState({ mergeLightboxVisible: true })}
                          title="Merge"
                          disabled={this.state.selectedLanes.length < 2}
                        >
                          <MergeTypeIcon />
                        </ButtonIcon>
                        <Button onClick={this.cancelMergeLanes}>Cancel</Button>
                      </Fragment>
                    )}
                    {localStorage.getItem('showBulkDelete') && (
                      <Button color="secondary" title="Delete all projects" onClick={this.props.deleteAllProjects} size="small">
                        Bulk Delete
                      </Button>
                    )}
                  </GroupByContainer>
                </FormGroup>
                {warning && (
                  <WarningMsg>
                    <a onClick={() => onWarningClick()}>{warning.message}</a>
                  </WarningMsg>
                )}
                <FormGroup row>
                  <LocalSearchInput />
                  {hasRoadmapHistory && <CreateRoadmapSnapshotButton WrapperComponent={IconWrapper} />}
                  <IconWrapper>
                    <ShowMyItemsToggle
                      showMyItemsOnly={showMyItemsOnly}
                      updateShowMyItemsOnly={() => this.props.updateState({ showMyItemsOnly: !showMyItemsOnly })}
                    />
                  </IconWrapper>
                  <IconWrapper>
                    <ToggleButton
                      on={!emptyLaneVisibility}
                      icon={emptyLaneVisibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      title={emptyLaneVisibility ? 'Hide empty lanes' : 'Show empty lanes'}
                      onChange={() => updateEmptyLaneVisibility(!emptyLaneVisibility)}
                    />
                  </IconWrapper>
                  <IconWrapper>
                    <ShareView pageId={IDEAS_BOARD_PAGE} viewsDropdownTabs={this.props.viewsDropdownTabs} id="view-icon" />
                  </IconWrapper>
                  {/* {checkRolePermission('IMPORT_IDEAS', currentUser) && (
                    <ButtonIcon data-cy="import-ideas" onClick={this.props.showImportIdeasLightbox} title="Import">
                    <ImportIcon />
                    </ButtonIcon>
                  )} */}
                  <IconWrapper>
                    <Dropdown
                      placeholder={
                        <ButtonIcon data-cy="ideas-more-options">
                          <MoreHorizIcon />
                        </ButtonIcon>
                      }
                      options={moreOptions}
                      onChange={onMoreDropdownClick}
                      isButton={false}
                    />
                  </IconWrapper>
                  <PreferencesDialog
                    isOpen={preferencesDialogOpen}
                    closeDialog={() => this.setState({ preferencesDialogOpen: false })}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </div>
        )}

        <BoardDiv
          editable={isEditableAndDraggable}
          draggable={isEditableAndDraggable}
          customCardLayout
          hideCardDeleteIcon
          canAddLanes={canAddLane && this.props.lsState.dataType !== 'planningStage'}
          onCardAdd={(card, laneId) => this.handleCardAdd(card, laneId, this.props.autoFillData || {})}
          data={data}
          handleDragEnd={this.handleDragEnd}
          handleLaneDragEnd={this.handleLaneDragEnd}
          newCardTemplate={<NewCardTemplate />}
          displayLayer={this.props.displayLayer}
          onLaneScroll={(requestedPage, laneId) => {
            return new Promise(resolve => {
              this.setState({
                lanesPages: {
                  ...this.state.lanesPages,
                  [laneId]: (this.state.lanesPages[laneId] || 1) + 1,
                },
              });

              resolve([{ fakeCard: true }]);
            });
          }}
          customLaneHeader={
            <LaneHeaderTemplate
              canUpdateLane={canUpdateLane}
              progressCount={
                this.props.displayLayer === IDEA_LAYER &&
                this.props.lsState.displayPreferences &&
                !!this.props.lsState.displayPreferences.progressCount
              }
              displayPreferences={this.props.lsState.displayPreferences}
              customFields={this.props.customFields}
            />
          }
          onDataChange={this.onDataChange}
          newLaneTemplate={<NewLaneTemplate />}
          onCardClick={id => this.props.openProjectLightbox(id)}
          addCardLink={shouldRenderAddNewProject ? <AddNewButton>+ Add new</AddNewButton> : <div />}
          addLaneTitle={
            <span id={`add-${this.props.lsState.dataType}-button`}>
              + Add {this._getDataTypeTitle(this.props.lsState.dataType)}
            </span>
          }
          style={{
            height: `calc(100vh - 128px)`,
            padding: 0,
            backgroundColor: this.getBackgroundColor(),
            margin: '0px 44px 0 30px',
          }}
        >
          <CardTemplate
            cardDataType={this.props.displayLayer}
            dataType={this.props.lsState.dataType}
            selectedTags={this.props.lsState.selectedTags}
            displayPreferences={lsState.displayPreferences || {}}
            searchString={lsState.searchString}
            jiraIntegrated={this.props.jiraIntegrated}
            canDelete={false}
            orgIntegrations={this.props.integrations}
            checkCanBeParent={p => p.layer !== IDEA_LAYER}
            voteOnProject={this.props.voteOnProject}
            unvoteOnProject={this.props.unvoteOnProject}
          />
        </BoardDiv>

        <ConfirmDialog
          isOpen={!!this.props.lsState.deletingProject}
          row={this.props.lsState.deletingProject}
          /* eslint-disable */
          text={
            <p>
              Deleting an idea permanently removes all of its data, including change history, its tasks and assignments.
              <br />
              <br />
              We recommend Archiving it if you may need to access it later
            </p>
          }
          /* eslint-enable */
          onCancel={() => this.props.updateState({ deletingProject: null })}
          onDelete={() => this.deleteProject(+this.props.lsState.deletingProject.id)}
          onArchive={() => {
            this.props.updateProject({ id: this.props.lsState.deletingProject.id, planningStage: 'Archived' });
            this.props.updateState({ deletingProject: null });
          }}
          blankBody
        />

        <ConfirmDialog
          isOpen={this.state.laneToDelete}
          onCancel={() => this.setState({ laneToDelete: null })}
          onDelete={this.deleteLane}
          blankBody
        />

        <MergeLightbox
          items={this.state.selectedLanes}
          dialogVisible={this.state.mergeLightboxVisible}
          hideDialog={() => this.setState({ mergeLightboxVisible: false })}
          merge={this.mergeLanes}
          transformLabel={item => item.title}
          disableSelectionMode={this.cancelMergeLanes}
        />

        <ImportIdeasDialog />
      </div>
    );
  }
}

export default IdeasBoard;

const AddNewButton = styled.a`
  margin-bottom: 8px !important;
`;

const GroupByContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledGroupByAutocomplete = styled(GroupByAutocomplete)`
  padding-top: 10px;
`;

const BoardDiv = styled(Board)`
  &&&& {
    article {
      border: 0;
    }

    span {
      vertical-align: text-top;
    }

    .react-trello-lane:hover {
      box-shadow: 0px 0px 1px #0000004f;
    }
  }
`;

const WarningMsg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: red;
  }
`;

const Spacing = styled.div`
  padding: 5px;
`;

const IconWrapper = styled.div`
  width: 46px;
  display: flex;

  &:not(:last-of-type) {
    margin-right: ${({ theme }) => theme.spacing.unit * 0.65}px; // 5px
  }
`;
