import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';

import upsertListItem from '../utils/upsertListItem';
import {
  CREATE_THEME_FULFILLED,
  FETCH_THEMES_FULFILLED,
  UPDATE_THEME_FULFILLED,
  UPDATE_THEME_BY_ID_FULFILLED,
  ADD_THEME_WITHOUT_SAVE,
  REMOVE_UNSAVED_THEMES,
  MERGE_THEMES_FULFILLED,
  DELETE_THEME_FULFILLED,
  CREATE_THEMES_FULFILLED,
  UPDATE_THEMES_FULFILLED,
  UNDO_CREATE_THEMES_FULFILLED,
  UNDO_UPDATE_THEMES_FULFILLED,
  BULK_DELETE_THEMES_FULFILLED,
  UNDO_BULK_DELETE_THEMES_FULFILLED,
  SWITCH_THEMES_ROW_ORDER_FULFILLED,
  SWITCH_THEMES_ROW_ORDER_PENDING,
  SWITCH_THEMES_ROW_ORDER_REJECTED,
  BULK_DELETE_THEME_ROADMAP_FULFILLED,
  CREATE_THEME_ROADMAP_FULFILLED,
  DELETE_THEME_ROADMAP_FULFILLED,
} from './types';
import addRows from 'store/utils/addRows';
import updateRows from 'store/utils/updateRows';
import sortByRank from 'utils/sortByRank';

const initialState = {
  themes: [],
  isLoaded: false,
};

function removeUnsaved(list) {
  return list.filter(el => el.id);
}

function updateTheme(themes, theme) {
  return themes.map(bg => (bg.id === theme.id ? theme : bg));
}

export default function themesReducer(state = initialState, action) {
  switch (action.type) {
    case SWITCH_THEMES_ROW_ORDER_REJECTED: {
      if (!action.meta || !action.meta.prev) return state;

      let themes = state.themes ? state.themes.slice(0) : [];

      themes = upsertListItem(action.meta.prev, themes);

      return {
        ...state,
        themes: themes.sort(sortByRank),
      };
    }
    case SWITCH_THEMES_ROW_ORDER_PENDING: {
      if (!action.meta || !action.meta.row) {
        return state;
      }

      let themes = state.themes ? state.themes.slice(0) : [];

      themes = upsertListItem(action.meta.row, themes);

      return {
        ...state,
        themes: themes.sort(sortByRank),
      };
    }
    case SWITCH_THEMES_ROW_ORDER_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        console.error('SWITCH_THEMES_ROW_ORDER_FULFILLED::Theme passed does not have id');
        return state;
      }

      let themes = state.themes ? state.themes.slice(0) : [];

      themes = upsertListItem(action.payload, themes);

      return {
        ...state,
        themes: themes.sort(sortByRank),
      };
    }
    case CREATE_THEME_FULFILLED:
      if (!action.payload || !action.payload.id) {
        return state;
      }

      let themes = state.themes || [];

      themes = removeUnsaved(themes);
      themes = upsertListItem(action.payload, themes);

      return {
        ...state,
        themes,
        isLoaded: true,
      };
    case FETCH_THEMES_FULFILLED:
      return Object.assign({}, state, {
        themes: action.payload.data,
        isLoaded: true,
        lastCallsDate: moment().valueOf(),
      });
    case UPDATE_THEME_BY_ID_FULFILLED:
    case UPDATE_THEME_FULFILLED: {
      return {
        ...state,
        themes: updateTheme(state.themes, action.payload),
        isLoaded: true,
      };
    }
    case DELETE_THEME_FULFILLED:
      return {
        ...state,
        themes: state.themes.filter(theme => theme.id !== action.payload),
      };
    case ADD_THEME_WITHOUT_SAVE: {
      const themes = state.themes ? removeUnsaved(state.themes) : [];

      themes.unshift(action.theme || {});

      return {
        ...state,
        themes,
      };
    }
    case REMOVE_UNSAVED_THEMES:
      return {
        ...state,
        themes: state.themes.filter(theme => theme.id),
      };
    case MERGE_THEMES_FULFILLED:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        themes: state.themes.filter(theme => !action.payload.includes(theme.id)),
      };
    case CREATE_THEMES_FULFILLED: {
      const themes = addRows(state.themes, action.payload);

      return {
        ...state,
        themes,
        lastActionIds: action.payload.map(theme => theme.id),
      };
    }
    case UPDATE_THEMES_FULFILLED: {
      const themes = updateRows(state.themes, action.payload);

      return {
        ...state,
        themes,
        lastActionIds: action.payload.map(theme => theme.id),
      };
    }
    case BULK_DELETE_THEMES_FULFILLED: {
      const themes = state.themes.filter(r => !action.payload.includes(String(r.id)));

      return {
        ...state,
        themes,
        lastActionIds: action.payload,
      };
    }
    case UNDO_UPDATE_THEMES_FULFILLED: {
      const themes = updateRows(state.themes, action.payload);

      return {
        ...state,
        themes,
        lastActionIds: null,
      };
    }
    case UNDO_CREATE_THEMES_FULFILLED: {
      return {
        ...state,
        themes: state.themes.filter(c => state.lastActionIds.indexOf(c.id) === -1),
        lastActionIds: null,
      };
    }
    case UNDO_BULK_DELETE_THEMES_FULFILLED: {
      const themes = updateRows(state.themes, action.payload);

      return {
        ...state,
        themes,
        lastActionIds: null,
      };
    }
    case CREATE_THEME_ROADMAP_FULFILLED:
      const existingThemes = cloneDeep(state.themes);
      const theme = existingThemes.find(obj => obj.id === action.payload.theme_id);

      theme.theme_roadmaps = [...(theme.theme_roadmaps || []), action.payload];
      theme.updated_at = action.payload.updated_at;

      const updatedThemes = upsertListItem(theme, existingThemes);

      return {
        ...state,
        themes: updatedThemes,
      };
    case DELETE_THEME_ROADMAP_FULFILLED:
      const existingThms = cloneDeep(state.themes);
      const { id: themeId, themeRoadmaps } = action.payload;

      const parent = existingThms.find(obj => obj.id === parseInt(themeId));

      parent.theme_roadmaps = themeRoadmaps || [];

      const updated = upsertListItem(parent, existingThms);

      return {
        ...state,
        themes: updated,
      };
    case BULK_DELETE_THEME_ROADMAP_FULFILLED:
      const thms = cloneDeep(state.themes);
      const updatedThms = thms.map(theme => {
        if (theme.id === action.payload) {
          return {
            ...theme,
            theme_roadmaps: [],
          };
        }

        return theme;
      });

      return {
        ...state,
        themes: updatedThms,
      };
    default:
      return state;
  }
}
