import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import ArchiveIcon from '@material-ui/icons/Archive';

import ToggleButton from 'design-system/molecules/ToggleButton/index';

import { spacing } from 'design-system/theme';
import { materialColors } from 'design-system/themes/default';

const ActionsToolbar = ({ hideAddButton, add, showArchivedItems, toggleArchivedItems }) => {
  return (
    <Wrapper>
      {!hideAddButton && (
        <AddNewButton id="custom-fields-add-new-button" color="primary" onClick={() => add()}>
          + New
        </AddNewButton>
      )}
      <RightButtons>
        <ToggleButton
          on={showArchivedItems}
          title="Show Archived"
          icon={<ArchiveIcon fontSize="small" fill={materialColors.downy} />}
          onChange={toggleArchivedItems}
          style={{ color: showArchivedItems ? materialColors.downy : materialColors.darkGray }}
        />
      </RightButtons>
    </Wrapper>
  );
};

export default ActionsToolbar;

const Wrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${spacing(0.5)}px ${spacing(3)}px ${spacing(0.5)}px ${spacing(4.5)}px;
`;

const RightButtons = styled.div`
  display: inline-flex;
  margin-left: auto;
`;

const AddNewButton = styled(Button)`
  &&&& {
    margin-top: ${spacing(0.5)}px;
  }
`;
