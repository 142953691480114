import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

import { GLOBAL_FILTER } from 'constants/filters';
import { getAllInitiatives, getAllBets, selectFilteredProjectsForPage } from 'store/projects/selectors';
import { getDisplayLayer, usePortfolioMode, makeSelectFilteredProjectsByRoadmapForPage } from 'store/filters/selectors';
import { getObjectives, selectKeyResults1, selectKeyResults2 } from 'store/objectives/selectors';
import { getRoadmaps, getCorpRoadmaps, getProducts, getProductsLevelTwo } from 'store/roadmaps/selectors';
import { getTimeframes, getTimeframesLevel2, getTimeframesLevelCorp } from 'store/timeframes/selectors';
import { getCategories, getCategoriesLevelCorp } from 'store/categories/selectors';
import { getPriorities } from 'store/priorities/selectors';
import { getThemes } from 'store/themes/selectors';
import { getPhases } from 'store/phases/selectors';
import { getUsers } from 'store/users/selectors';
import { getTags } from 'store/tags/selectors';
import { getCustomersFilteredByStatus } from 'store/customers/selectors';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import { getDropdownCustomFields } from 'store/customFields/selectors';
import { getGroupOptions } from 'store/projects/helpers/groupOptions';
import { getIsDodActive } from 'store/accessControl/selectors';
import {
  selectHasHierarchy,
  getOrgHasBet,
  selectHasKeyResults,
  selectHasKeyResults2,
  selectHasProducts,
  hasMultiLevelPortfolioMetadata as hasMultiLevelPortfolioMetadataSelector,
  getOrganizationSystemFieldsNames,
  getHasProjectMetrics,
  getOrgHasCustomUserProjectFields,
  getOrgCustomUserProjectFields,
} from 'store/organization/selectors';
import { getAllTeams } from 'store/teams/selectors';

import getSystemFieldName from 'utils/getSystemFieldName';
import { getMetrics } from 'store/metrics/selectors';

import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from './constants';
import { METADATA_LEVELS } from 'constants/common';
import { getUnsavedProject } from './selectors';
import { getStatusColors, getPlanningStages } from './helpers/groupOptions';
import { selectLoadedMetadata } from 'features/MetadataOnDemand/store/selectors';
import { equals, filter, pipe, prop } from 'ramda';

const castToString = v => String(v);

const isCorpLevel = pipe(prop('level'), castToString, equals(METADATA_LEVELS.LEVEL_CORP));
const isLevel1 = pipe(prop('level'), castToString, equals(METADATA_LEVELS.LEVEL_1));
const isLevel2 = pipe(prop('level'), castToString, equals(METADATA_LEVELS.LEVEL_2));

/*
 * Created selector variations for each layer and o case that has portfolio option or not
 *
 * On the case of selector function be the same for all layers or the same for portfolio option
 * variation the selector cache will not work and will return a new object reference on every
 * render
 */
const getFilteredProjectsForPageIdeasWithoutPortfolioOption = makeSelectFilteredProjectsByRoadmapForPage();
const getFilteredProjectsForPageInitiativesWithoutPortfolioOption = makeSelectFilteredProjectsByRoadmapForPage();
const getFilteredProjectsForPageBetsWithoutPortfolioOption = makeSelectFilteredProjectsByRoadmapForPage();

const getFilteredProjectsForPageIdeasWithPortfolioOption = makeSelectFilteredProjectsByRoadmapForPage(
  selectFilteredProjectsForPage,
  null,
  true,
);
const getFilteredProjectsForPageInitiativesWithPortfolioOption = makeSelectFilteredProjectsByRoadmapForPage(
  selectFilteredProjectsForPage,
  null,
  true,
);
const getFilteredProjectsForPageBetsWithPortfolioOption = makeSelectFilteredProjectsByRoadmapForPage(
  selectFilteredProjectsForPage,
  null,
  true,
);

/**
 * Get all filtered projects regardless of the layer including the temporary unsaved project.
 *
 * @param {String} page The page filter
 * @param {Boolean} onlyProjects The flag to includes only projects
 * @returns All filtered projects by layer
 */
const getAllProjectsFiltered = createCachedSelector(
  (state, page = GLOBAL_FILTER, onlyProjects = true, hasPortfolioOption = false) => {
    return hasPortfolioOption
      ? getFilteredProjectsForPageIdeasWithPortfolioOption(state, page, onlyProjects, IDEA_LAYER)
      : getFilteredProjectsForPageIdeasWithoutPortfolioOption(state, page, onlyProjects, IDEA_LAYER);
  },
  (state, page = GLOBAL_FILTER, onlyProjects = true, hasPortfolioOption = false) => {
    return hasPortfolioOption
      ? getFilteredProjectsForPageInitiativesWithPortfolioOption(state, page, onlyProjects, INITIATIVE_LAYER)
      : getFilteredProjectsForPageInitiativesWithoutPortfolioOption(state, page, onlyProjects, INITIATIVE_LAYER);
  },
  (state, page = GLOBAL_FILTER, onlyProjects = true, hasPortfolioOption = false) => {
    return hasPortfolioOption
      ? getFilteredProjectsForPageBetsWithPortfolioOption(state, page, onlyProjects, BET_LAYER)
      : getFilteredProjectsForPageBetsWithoutPortfolioOption(state, page, onlyProjects, BET_LAYER);
  },
  getUnsavedProject,
  state => state.organization.organization.has_bet,
  (ideas, initiatives, bets, unsavedProject, hasBets) => {
    const betToAdd = unsavedProject && unsavedProject.layer === BET_LAYER ? [unsavedProject, ...bets] : bets;

    return {
      [IDEA_LAYER]: unsavedProject && unsavedProject.layer === IDEA_LAYER ? [unsavedProject, ...ideas] : ideas,
      [INITIATIVE_LAYER]:
        unsavedProject && unsavedProject.layer === INITIATIVE_LAYER ? [unsavedProject, ...initiatives] : initiatives,
      [BET_LAYER]: hasBets ? betToAdd : [],
    };
  },
)((_, page = GLOBAL_FILTER, onlyProjects = true) => `getAllProjectsFiltered-${page}-${onlyProjects}`);

/**
 * Creates a selector that retrieves all the data from the store needed for grouping.
 *
 * @returns A selector with all the data objects needed for grouping.
 */
export const getDefaultGroupingObjects = createSelector(
  state =>
    getStateDataForPage(
      state,
      (state, showArchived) => getObjectives(state, showArchived, METADATA_LEVELS.LEVEL_CORP),
      'objectivesCorp',
    ),
  (state, overrideShowArchived) => getStateDataForPage(state, getObjectives, 'objectives', overrideShowArchived),
  (state, overrideShowArchived) =>
    getStateDataForPage(
      state,
      (state, showArchived) => selectKeyResults1(state, showArchived),
      'keyResults',
      overrideShowArchived,
    ),
  (state, overrideShowArchived) =>
    getStateDataForPage(
      state,
      (state, showArchived) => selectKeyResults2(state, showArchived),
      'keyResult2s',
      overrideShowArchived,
    ),
  (state, overrideShowArchived) =>
    getStateDataForPage(
      state,
      (state, showArchived) => getCorpRoadmaps(state, showArchived),
      'roadmapsCorp',
      overrideShowArchived,
    ),
  (state, overrideShowArchived) => getStateDataForPage(state, getRoadmaps, 'roadmaps', overrideShowArchived),
  (state, overrideShowArchived) => getStateDataForPage(state, getProducts, 'products', overrideShowArchived),
  (state, overrideShowArchived) => getStateDataForPage(state, getProductsLevelTwo, 'products2', overrideShowArchived),
  (state, overrideShowArchived) => getStateDataForPage(state, getTimeframesLevelCorp, 'timeframesCorp', overrideShowArchived),
  (state, overrideShowArchived) => getStateDataForPage(state, getTimeframes, 'timeframes', overrideShowArchived),
  (state, overrideShowArchived) => getStateDataForPage(state, getTimeframesLevel2, 'timeframes', overrideShowArchived),
  (state, overrideShowArchived) => getStateDataForPage(state, getCategoriesLevelCorp, 'categoriesCorp', overrideShowArchived),
  (state, overrideShowArchived) => getStateDataForPage(state, getCategories, 'categories', overrideShowArchived),
  (state, overrideShowArchived) => getStateDataForPage(state, getThemes, 'themes', overrideShowArchived),
  (state, overrideShowArchived) => getPriorities(state, overrideShowArchived),
  (state, overrideShowArchived) => getStateDataForPage(state, getPhases, 'phases', overrideShowArchived),
  state => getUsers(state),
  (state, overrideShowArchived) => getTags(state, overrideShowArchived),
  (state, overrideShowArchived) => getStateDataForPage(state, getCustomersFilteredByStatus, 'customers', overrideShowArchived),
  state => getAllInitiatives(state),
  state => getAllBets(state),
  state => getDropdownCustomFields(state),
  state => getMetrics(state),
  state => getAllTeams(state),
  (
    objectivesCorp,
    objectives,
    keyResults1,
    keyResults2,
    roadmapsCorp,
    roadmaps,
    products,
    products2,
    timeframesCorp,
    timeframes,
    timeframes2,
    categoriesCorp,
    categories,
    themes,
    priorities,
    phases,
    users,
    tags,
    customers,
    initiatives,
    bets,
    customFields,
    metrics,
    teams,
  ) => {
    return {
      objectivesCorp,
      objectives,
      keyResults1,
      keyResults2,
      roadmapsCorp,
      roadmaps,
      products,
      products2,
      timeframesCorp,
      timeframes,
      timeframes2,
      categoriesCorp,
      categories,
      themes,
      priorities,
      phases,
      users,
      tags,
      customers,
      initiatives,
      bets,
      statusColors: getStatusColors(),
      planningStages: getPlanningStages(),
      customFields,
      metrics,
      teams,
    };
  },
);

/**
 * Creates a selector that retrieves the metadata loaded from the last projects search performed
 *
 * @returns A selector with all the available data objects needed for grouping.
 */
export const getGroupingObjectsForLoadedOnDemandMetadata = createSelector(
  state => selectLoadedMetadata(state),
  state => getUsers(state),
  state => getAllInitiatives(state),
  state => getAllBets(state),
  state => getDropdownCustomFields(state),
  state => getMetrics(state),
  state => getAllTeams(state),
  (loadedMetadata, users, initiatives, bets, customFields, metrics, teams) => {
    const { objectives, keyResults, roadmaps, products, timeframes, categories, themes, priorities, phases, tags, customers } =
      loadedMetadata;

    return {
      objectivesCorp: filter(isCorpLevel, objectives),
      objectives: filter(isLevel1, objectives),
      keyResults1: filter(isLevel1, keyResults),
      keyResults2: filter(isLevel2, keyResults),
      roadmapsCorp: filter(isCorpLevel, roadmaps),
      roadmaps: filter(isLevel1, roadmaps),
      products: filter(isLevel1, products),
      products2: filter(isLevel2, products),
      timeframesCorp: filter(isCorpLevel, timeframes),
      timeframes: filter(isLevel1, timeframes),
      timeframes2: filter(isLevel2, timeframes),
      categoriesCorp: filter(isCorpLevel, categories),
      categories: filter(isLevel1, categories),
      themes,
      priorities,
      phases,
      users,
      tags,
      customers,
      initiatives,
      bets,
      statusColors: getStatusColors(),
      planningStages: getPlanningStages(),
      customFields,
      metrics,
      teams,
    };
  },
);

const EMPTY_OBJECT = {};

const getGroupOptionsSelector = createSelector(
  usePortfolioMode,
  getDisplayLayer,
  getDropdownCustomFields,
  selectHasHierarchy,
  getOrgHasBet,
  selectHasKeyResults,
  selectHasKeyResults2,
  selectHasProducts,
  hasMultiLevelPortfolioMetadataSelector,
  getIsDodActive,
  getOrganizationSystemFieldsNames,
  getHasProjectMetrics,
  state => (getOrgHasCustomUserProjectFields(state) ? getOrgCustomUserProjectFields(state) : EMPTY_OBJECT),
  (_, options) => options,
  (
    portfolioMode,
    displayLayer,
    dropdownCustomFields,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasMultiLevelPortfolioMetadata,
    isDodActive,
    systemFieldsNames,
    hasProjectMetrics,
    customUserFields,
    options,
  ) => {
    const customGetSystemFieldName = name => getSystemFieldName(name, systemFieldsNames, false);

    return getGroupOptions({
      getSystemFieldName: customGetSystemFieldName,
      hasKeyResults,
      hasKeyResults2,
      hasProducts,
      hasHierarchy,
      hasBet,
      hasMultiLevelPortfolioMetadata,
      isDodActive,
      portfolioMode,
      displayLayer,
      dropdownCustomFields,
      withMetrics: options.withMetrics && hasProjectMetrics,
      customUserFields,
      ...options,
    });
  },
);

export { getAllProjectsFiltered, getGroupOptionsSelector };
