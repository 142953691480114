import { defaultTo, path } from 'ramda';

const getSelectedQuickFilterFromOutcomeDefaultFilters = (defaultFilters, currentFilters) => {
  const filters = defaultTo({}, currentFilters);

  if (!Array.isArray(defaultFilters)) {
    return '';
  }

  const defaultFilterToSelect = defaultFilters.find(({ key, metaType }) => {
    const metaField = path(['quickFilters', metaType], filters);

    return Array.isArray(metaField) ? metaField.includes(key) : false;
  });

  return defaultFilterToSelect?.label || '';
};

export default getSelectedQuickFilterFromOutcomeDefaultFilters;
