import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Dialog from 'design-system/molecules/Dialog/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import DetailsTable from '../DetailsTable';

const getTableWrapper = () => {
  const componentRef = useRef(({ children }) => <div>{children}</div>);

  return componentRef.current;
};

const DetailsTableLightbox = ({
  open,
  onClose,
  projectsSubFilters,
  title,
  displayLayer,
  allLevels,
  selectedGroup1,
  selectedGroup2,
  selectedGroup3,
  renderTitleHeaderLabel,
  visibleColumns,
}) => {
  const _renderTitle = () => (
    <PageTitle>
      <TextDeprecated>{title}</TextDeprecated>
    </PageTitle>
  );

  const _renderTable = () => (
    <DetailsTable
      wrapper={getTableWrapper()}
      projectsSubFilters={projectsSubFilters}
      displayLayer={displayLayer}
      allLevels={allLevels}
      selectedGroup1={selectedGroup1}
      selectedGroup2={selectedGroup2}
      selectedGroup3={selectedGroup3}
      renderTitleHeaderLabel={renderTitleHeaderLabel}
      visibleColumns={visibleColumns}
    />
  );

  return (
    <Dialog
      data-test="timeline-dialog"
      open={Boolean(open)}
      onClose={onClose}
      maxWidth="lg"
      scroll="paper"
      fullWidth
      closeButton
      title=""
    >
      <Wrapper titled={Boolean(title)}>
        {title && _renderTitle()}
        {_renderTable()}
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled.div`
  ${({ titled }) => !titled && `padding-top: 30px;`}
`;

const PageTitle = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 8px;

  ${TextDeprecated} {
    font-size: 22px !important;
  }
`;

DetailsTableLightbox.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  projectsSubFilters: PropTypes.object,
  title: PropTypes.string,
  displayLayer: PropTypes.string,
  allLevels: PropTypes.bool,
  selectedGroup1: PropTypes.object,
  selectedGroup2: PropTypes.object,
  renderTitleHeaderLabel: PropTypes.func,
  visibleColumns: PropTypes.array,
};

export default DetailsTableLightbox;
