import React from 'react';
import TimeIcon from '@material-ui/icons/AccessTime';
import BuildIcon from '@material-ui/icons/Build';
import SettingsIcon from '@material-ui/icons/Settings';
import ImportIcon from '@material-ui/icons/CloudUploadOutlined';
import VerticalAlignCenter from '@material-ui/icons/VerticalAlignCenter';
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CompareIcon from '@material-ui/icons/Compare';

import CopyAllIcon from 'design-system/atoms/CopyAllIcon/index';
import BoltIcon from 'design-system/atoms/BoltIcon/index';

import { checkRolePermission } from 'containers/UserPermission/utils';
import downloadPNG from 'utils/downloadPNG';
import { FORECAST_PAGE } from 'constants/filters';
import { checkOrganizationFlags } from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';
import { DRAFT_MODE_BUTTON_PENDO_ID } from 'constants/common';
import useSystemFields from 'hooks/useSystemFields';
import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useGoToCompareVersions from 'hooks/useGoToCompareVersions';
import EditIcon from 'design-system/atoms/EditIcon';
import getCurrentPath from 'utils/getCurrentPath';

const SCENARIO_BASE_PATH = 'scenario';
const isScenarioModule = path => path.includes(SCENARIO_BASE_PATH);

/**
 * Returns the dropdown options to use on controls bar
 *
 * @param {*} options
 */
export const getMoreDropdownOptions = ({
  toggleLocalMode,
  currentUser,
  organization,
  collapseGanttBars,
  onClickImportJsonDialog,
  onClickAutoSchedule,
  toggleOneClickPlanMode,
  pageId,
  openScenariosDialog,
}) => {
  const [getSystemFieldName] = useSystemFields();
  const { isRoadmapVersionsEnabled } = useRoadmapVersions();
  const isForecastPage = pageId === FORECAST_PAGE;
  const hasScenariosEnabled = isForecastPage && isRoadmapVersionsEnabled;

  const { navigateToCompareVersions } = useGoToCompareVersions();

  const path = getCurrentPath();

  return [
    {
      id: 'preferences',
      key: 'preferences',
      title: (
        <span>
          <SettingsIcon style={{ verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Display preferences</span>
        </span>
      ),
    },
    {
      id: 'collapseGanttBars',
      key: 'collapseGanttBars',
      title: (
        <span>
          {collapseGanttBars && <VerticalAlignBottom style={{ verticalAlign: 'bottom' }} />}
          {!collapseGanttBars && <VerticalAlignCenter style={{ verticalAlign: 'bottom' }} />}
          <span style={{ paddingLeft: 13 }}>{collapseGanttBars ? 'Uncollapse' : 'Collapse'} gantt bars</span>
        </span>
      ),
    },
    ...(checkRolePermission('IMPORT_IDEAS', currentUser)
      ? [
          {
            id: 'import',
            key: 'import',
            title: (
              <span>
                <ImportIcon style={{ verticalAlign: 'bottom' }} />
                <span style={{ paddingLeft: 13 }}>Import</span>
              </span>
            ),
          },
        ]
      : []),
    {
      id: 'download-png',
      key: 'download-png',
      onClick: downloadPNG,
      title: (
        <span>
          <PhotoCamera style={{ verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Download PNG</span>
        </span>
      ),
    },
    ...(checkOrganizationFlags(organization, [FeatureFlags.HAS_ONE_CLICK_PLAN]) && pageId === FORECAST_PAGE
      ? [
          {
            id: 'autoScheduleBackend',
            key: 'autoScheduleBackend',
            onClick: toggleOneClickPlanMode,
            title: (
              <span>
                <BoltIcon style={{ verticalAlign: 'bottom' }} />
                <span style={{ paddingLeft: 13 }}>1-Click AI Planner</span>
              </span>
            ),
          },
        ]
      : []),
    ...(hasScenariosEnabled && !isScenarioModule(path)
      ? [
          {
            id: 'draft-mode',
            key: 'draft-mode',
            onClick: toggleLocalMode,
            title: (
              <span id={DRAFT_MODE_BUTTON_PENDO_ID}>
                <EditIcon style={{ verticalAlign: 'bottom' }} />
                <span style={{ paddingLeft: 13 }}>Create {getSystemFieldName('scenario')}</span>
              </span>
            ),
          },
        ]
      : []),
    ...(localStorage.getItem('autoSchedule') === 'true'
      ? [
          {
            id: 'autoSchedule',
            key: 'autoSchedule',
            onClick: onClickAutoSchedule,
            title: (
              <span>
                <TimeIcon style={{ verticalAlign: 'bottom' }} />
                <span style={{ paddingLeft: 13 }}>1-Click Plan</span>
              </span>
            ),
          },
        ]
      : []),
    ...(localStorage.getItem('importJson') === 'true'
      ? [
          {
            id: 'importJson',
            key: 'importJson',
            onClick: onClickImportJsonDialog,
            title: (
              <span>
                <ImportExportIcon style={{ verticalAlign: 'bottom' }} />
                <span style={{ paddingLeft: 13 }}>Export/Import Projects</span>
              </span>
            ),
          },
        ]
      : []),
    ...(localStorage.getItem('showTimelinDevTools') === 'true'
      ? [
          {
            id: 'dev-tools',
            key: 'dev-tools',
            title: (
              <span>
                <BuildIcon style={{ verticalAlign: 'bottom' }} />
                <span style={{ paddingLeft: 13 }}>Dev Tools</span>
              </span>
            ),
          },
        ]
      : []),
    ...(hasScenariosEnabled
      ? [
          {
            id: 'compareRoadmapVersions',
            key: 'compareRoadmapVersions',
            onClick: navigateToCompareVersions,
            title: (
              <span>
                <CompareIcon style={{ verticalAlign: 'bottom' }} />
                <span style={{ paddingLeft: 13 }}>Compare {getSystemFieldName('scenario', true)}</span>
              </span>
            ),
          },
          {
            id: 'show-scenarios-list-dialog',
            key: 'show-scenarios-list-dialog',
            onClick: openScenariosDialog,
            title: (
              <span>
                <CopyAllIcon style={{ verticalAlign: 'bottom' }} />
                <span style={{ paddingLeft: 13 }}>View {getSystemFieldName('scenario', true)}</span>
              </span>
            ),
          },
        ]
      : []),
  ];
};
