// External dependencies
import { connect } from 'react-redux';
import { compose } from 'redux';

// Dragonboat imports
import { updateTask, createTask, deleteTask } from 'store/tasks';
import { getOrgHasJiraIntegrated } from 'store/organization/selectors';
import { getUsers } from 'store/users/selectors';

// Redux connect function state to props configuration
const mapStateToProps = (state, props) => ({
  jiraDisabled: getOrgHasJiraIntegrated(state),
  users: getUsers(state),
});

// Redux connect function dispatch configuration
const mapDispatchToProps = (dispatch, props) => ({
  updateTask: task => dispatch(updateTask(task)),
  createTask: task => dispatch(createTask(task)),
  deleteTask: id => dispatch(deleteTask(id)),
});

// Apply HOCs
export default compose(connect(mapStateToProps, mapDispatchToProps));
