import { useMemo } from 'react';
import { not } from 'ramda';
import { useSelector } from 'react-redux';

import { getHasMultipleMetrics, getOrgHasMetadataRoadmaps } from 'store/organization';

const METRIC_ACTUAL_VALUE = 'Actual Value';
const METRIC_TARGET_VALUE = 'Target Value';
const APPLIES_TO = 'Applies To';

const itemsToIgnoreIfMultipleMetricsActive = [METRIC_ACTUAL_VALUE, METRIC_TARGET_VALUE];
const itemsToIgnoreIfMetadataRoadmapsInactive = [APPLIES_TO];

const isSingleMetricsColumn = col => itemsToIgnoreIfMultipleMetricsActive.includes(col);
const isMetadataRoadmapsColumn = col => itemsToIgnoreIfMetadataRoadmapsInactive.includes(col);

const filterItemsForOrgWithMultipleMetrics = i => not(itemsToIgnoreIfMultipleMetricsActive.includes(i));
const filterItemsForOrgWithoutMetadataRoadmaps = i => not(itemsToIgnoreIfMetadataRoadmapsInactive.includes(i));

const leftItemsDefault = [
  'Goal Progress',
  'Summary',
  'Metric',
  METRIC_ACTUAL_VALUE,
  METRIC_TARGET_VALUE,
  'Owner',
  APPLIES_TO,
  'Target Allocation Amount',
  'Target Allocation %',
];
const rightItemsDefault = ['Health', 'Description', 'Links', 'Start Date', 'End Date', 'Display Color'];

/**
 * @function useGoalTableColumnOptions
 *
 * Hook to use the table columns for goal table
 *
 * @param  {Array} leftColumns
 * @param  {Array} rightColumns
 * @return {Object}
 */
const useGoalTableColumnOptions = (leftColumns = leftItemsDefault, rightColumns = rightItemsDefault) => {
  const hasMultipleMetrics = useSelector(getHasMultipleMetrics);
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);

  const filterVisibleFieldsForOrgPreferences = columns => {
    return columns.filter(col => {
      if (hasMultipleMetrics && isSingleMetricsColumn(col)) {
        return filterItemsForOrgWithMultipleMetrics(col);
      }

      if (not(hasMetadataRoadmaps) && isMetadataRoadmapsColumn(col)) {
        return filterItemsForOrgWithoutMetadataRoadmaps(col);
      }

      return true;
    });
  };

  const leftItems = useMemo(
    () => filterVisibleFieldsForOrgPreferences(leftColumns),
    [hasMetadataRoadmaps, hasMultipleMetrics, leftColumns],
  );

  const rightItems = useMemo(
    () => filterVisibleFieldsForOrgPreferences(rightColumns),
    [hasMetadataRoadmaps, hasMultipleMetrics, rightColumns],
  );

  return {
    leftItems,
    rightItems,
  };
};

export default useGoalTableColumnOptions;
