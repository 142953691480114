import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TRANSACTION_PAGES_PROJECTS_LIMIT } from 'constants';

import { getRoadmaps, getCorpRoadmaps, getProductsLevelTwo } from 'store/roadmaps/selectors';
import { getOrganization } from 'store/organization/selectors';
import { getUserFilters, usePortfolioMode, getProjectsTotal } from 'store/filters/selectors';
import { setPageFilters, deleteUserFilter, updateUserFilter } from 'store/filters';
import { getCurrentUser } from 'store/login/selectors';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import { getActiveViewForPage } from 'store/userViews/selectors';
import { getUserById } from 'store/users/selectors';

import { GLOBAL_FILTER } from 'constants/filters';
import { FILTERS_FIELDS } from 'constants/filters/fields';

import getFields from 'containers/AdvancedSearchPopover/helpers/getFields';

import { getSelectedQuickFilterFromDefaultFilters as getSelectedQuickFilterFromDefaultFiltersUtil } from './helpers';

import getSystemFieldName from 'utils/getSystemFieldName';
import parseFilters from 'utils/filters/parseFilters';
import getCurrentPath from 'utils/getCurrentPath';
import isTruthyOrZero from 'utils/isTruthyOrZero';

import { getPageIdFromPath } from 'utils/userViews';

import useCustomUserFields from 'hooks/useCustomUserFields';
import useSubscribeNewData from 'hooks/useSubscribeNewData';
import usePageFilters from 'hooks/filters/usePageFilters';
import useUserFavoriteViews from 'hooks/userViews/useUserFavoriteViews';
import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useScenarioToolbarControls from 'containers/ScenarioToolbarControls/hooks/useScenarioToolbarControls';
import usePermissions from 'hooks/permissions/usePermissions';
import { FeatureFlags } from '@dragonboat/config';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useProjectsFlatSearchPage from 'hooks/filters/useProjectsFlatSearchPage';
import useRoadmapQuickFilter from 'hooks/filters/useRoadmapQuickFilter';
import { UNDEFINED_LABEL } from 'constants/common';
import { PORTFOLIO_LABEL } from 'constants/roadmaps';
import { isApplyFiltersLoading, isApplyFiltersUninitialized } from 'store/projects';
import { isNil } from 'ramda';

const componentHOC = Component => {
  return ({
    page = GLOBAL_FILTER,
    hasPortfolioOption,
    customNumberOfResults,
    customTotalByLayer,
    customShouldDisplayTotalCount,
    customIsLoading,
    quickFilterUndefinedLabel: quickFilterUndefinedLabelProp,
    getSelectedQuickFilterFromDefaultFilters = getSelectedQuickFilterFromDefaultFiltersUtil,
    ...otherProps
  }) => {
    const hasPortfolioOverview = useFeatureFlags([FeatureFlags.HAS_PORTFOLIO_OVERVIEW]);

    const defaultQuickFilterUndefinedLabel = hasPortfolioOverview ? PORTFOLIO_LABEL : UNDEFINED_LABEL;
    const quickFilterUndefinedLabel = quickFilterUndefinedLabelProp || defaultQuickFilterUndefinedLabel;
    const displayUndefinedLabel = !!quickFilterUndefinedLabelProp || !hasPortfolioOverview;

    const dispatch = useDispatch();
    const [selectedQuickFilter, setSelectedQuickFilter] = useState('');
    const path = getCurrentPath();
    const pageId = getPageIdFromPath(path);
    const activeView = useSelector(state => getActiveViewForPage(state, pageId));
    const viewOwner = useSelector(state => getUserById(state, activeView?.user_id));
    const currentUser = useSelector(getCurrentUser);

    useSubscribeNewData(['userFilters', 'userViews']);

    const { isFavoriteView, handleFavoriteUserView } = useUserFavoriteViews();
    const { pageFilters, displayLayer } = usePageFilters(page, hasPortfolioOption);
    const { isCreatingOrViewingScenario } = useScenarioToolbarControls();

    const {
      customFields,
      hasKeyResults,
      hasKeyResults2,
      hasProducts,
      hasMultiLevelPortfolioMetadata,
      hasTeams2,
      roadmaps,
      products2,
      roadmapsCorp,
      savedFilters,
      isPortfolioMode,
    } = useSelector(state => ({
      roadmaps: getRoadmaps(state, true),
      products2: getProductsLevelTwo(state, true),
      roadmapsCorp: getCorpRoadmaps(state, true),
      savedFilters: getUserFilters(state, page).map(f => ({ ...f, title: f.name, label: f.name })),
      isPortfolioMode: usePortfolioMode(state),
      customFields: getProjectsCustomFields(state, true),
      hasKeyResults: getOrganization(state).has_key_results,
      hasKeyResults2: getOrganization(state).has_key_results2,
      hasProducts: getOrganization(state).has_products,
      hasMultiLevelPortfolioMetadata: getOrganization(state).has_multi_level_portfolio_metadata,
      hasTeams2: getOrganization(state).has_teams_2,
    }));

    const hasMultiLayerSearch = useFeatureFlags([FeatureFlags.HAS_MULTI_LAYER_SEARCH]);
    const { isProjectsFlatSearchPageActive } = useProjectsFlatSearchPage();
    const flatSearchActive = hasMultiLayerSearch && isProjectsFlatSearchPageActive;

    const storeTotal = useSelector(state => {
      // PROD-5318 If the display layer is bet or initiative the list should be filtered
      // to only display the ones that belong to the selected roadmap
      return getProjectsTotal(state, hasPortfolioOption, undefined, undefined, undefined, undefined, flatSearchActive);
    });

    const totalAllLayers = useSelector(state => state.projects.totalAllLayers);

    const totalByLayer = useSelector(state => state.projects.totalByLayer);

    const areProjectsFiltersUnitializedOrLoading = useSelector(
      state => isApplyFiltersLoading(state) || isApplyFiltersUninitialized(state),
    );

    const isLoading = isNil(customIsLoading) ? areProjectsFiltersUnitializedOrLoading : customIsLoading;

    const [total, setTotal] = useState('');

    const { selectedRoadmapVersion } = useRoadmapVersions();
    const selectedRoadmapVersionOwner = useSelector(state => getUserById(state, selectedRoadmapVersion?.created_by_id));

    const selectedVersionForHeader = useMemo(() => {
      if (!selectedRoadmapVersion) {
        return null;
      }

      return {
        ...selectedRoadmapVersion,
        createdByName: selectedRoadmapVersionOwner?.name,
      };
    }, [selectedRoadmapVersion, selectedRoadmapVersionOwner]);

    useEffect(() => {
      if (!isLoading) setTotal(storeTotal);
    }, [storeTotal, isLoading]);

    const displayLayerIsAllLevels = useMemo(() => isPortfolioMode && hasPortfolioOption, [isPortfolioMode, hasPortfolioOption]);

    const totalCountForDisplayLayer = useMemo(() => {
      if (flatSearchActive) return totalAllLayers;
      const totalByLayerToUse = customTotalByLayer || totalByLayer;

      return displayLayerIsAllLevels ? totalAllLayers : totalByLayerToUse[displayLayer];
    }, [displayLayerIsAllLevels, totalAllLayers, totalByLayer[displayLayer], displayLayer, flatSearchActive, customTotalByLayer]);

    const isTransactionPage = () => window.location.pathname?.match(/^\/(?:ideas|forecast(?!\/resources))/m);

    const isSummaryPage = () => window.location.pathname?.match(/^\/dashboard\/summary/m);

    const totalToValidate = customNumberOfResults || total;
    const pageSize = flatSearchActive ? totalToValidate : TRANSACTION_PAGES_PROJECTS_LIMIT;

    const showsTotalCountBasedOnPageConditions =
      !!totalToValidate &&
      (isTransactionPage() || isSummaryPage()) &&
      totalCountForDisplayLayer &&
      totalCountForDisplayLayer > totalToValidate &&
      totalToValidate >= pageSize;

    const shouldDisplayTotalCount = isNil(customShouldDisplayTotalCount)
      ? showsTotalCountBasedOnPageConditions
      : customShouldDisplayTotalCount;

    const { canView } = usePermissions();

    const { getCustomUserFieldByFieldKey } = useCustomUserFields();

    const fields = React.useMemo(
      () =>
        getFields(
          getSystemFieldName,
          FILTERS_FIELDS,
          true,
          customFields,
          hasKeyResults,
          hasKeyResults2,
          hasProducts,
          hasMultiLevelPortfolioMetadata,
          hasTeams2,
          false,
          { canView, getCustomUserFieldByFieldKey },
        ),
      [
        FILTERS_FIELDS,
        customFields,
        hasKeyResults,
        hasProducts,
        hasMultiLevelPortfolioMetadata,
        hasTeams2,
        canView,
        getCustomUserFieldByFieldKey,
      ],
    );

    const userFilters = useMemo(() => savedFilters.filter(f => !f.is_static), [savedFilters]);
    const staticFilters = useMemo(() => savedFilters.filter(f => !!f.is_static), [savedFilters]);
    const allUserFilters = useMemo(() => [...userFilters, ...staticFilters], [userFilters, staticFilters]);

    // The 'total' is calculating the number of projects based on current filters. 'customNumberOfResults' can be passed
    // to have a different calculation for the results counter (i.e: Outcome and Metrics modules)
    const resultsTotal = useMemo(
      () => (isTruthyOrZero(customNumberOfResults) ? customNumberOfResults : total),
      [customNumberOfResults, total],
    );

    const hasLoadedResults = useMemo(() => {
      if (isLoading) return false;

      return Number.isInteger(customNumberOfResults) || Number.isInteger(total);
    }, [isLoading, customNumberOfResults, total]);

    const { setRoadmapQuickFilter, roadmapsFilterTree } = useRoadmapQuickFilter({
      filterType: page,
      undefinedLabel: quickFilterUndefinedLabel,
      displayUndefinedLabel,
    });

    const _setQuickFilter = item => {
      setRoadmapQuickFilter(item);
      setSelectedQuickFilter(item.label);
    };

    const _onSavedFilterClick = f => {
      dispatch(setPageFilters(page, parseFilters(f.state), false, f.id));
    };

    const _saveFilterById = (id, name, filters) => {
      const { activeFilter } = pageFilters;
      const activeFilterObj = activeFilter && allUserFilters.find(f => f.id === activeFilter);

      if (activeFilterObj && activeFilterObj.id === id) {
        setSelectedQuickFilter(name);
      }

      const newFilterData = { name, ...(filters ? { state: parseFilters(filters) } : {}) };

      return { newFilterData, dispatchPromise: dispatch(updateUserFilter(id, newFilterData)) };
    };

    const _deleteFilterById = id => {
      return dispatch(deleteUserFilter(id));
    };

    useEffect(() => {
      const { quickFilters, activeFilter: activeFilterId } = pageFilters;
      const activeFilterObj = activeFilterId && allUserFilters.find(f => f.id === activeFilterId);

      if (activeFilterObj) {
        setSelectedQuickFilter(activeFilterObj.name);

        return;
      }

      if (!quickFilters) {
        setSelectedQuickFilter('');

        return;
      }

      if (quickFilters?.roadmaps?.length) {
        const activeRoadmap = roadmaps.find(r => r.id === quickFilters?.roadmaps[0]);

        if (activeRoadmap) {
          setSelectedQuickFilter(activeRoadmap.title);
        } else {
          setSelectedQuickFilter(quickFilterUndefinedLabel || 'Undefined');
        }

        return;
      }

      if (quickFilters?.products?.length) {
        roadmaps.forEach(r => {
          if (r.products && !!r.products.length) {
            r.products.forEach(child => {
              if (child.id === quickFilters?.products[0]) {
                setSelectedQuickFilter(child.title);
              }
            });
          }
        });

        // Adding condition last to not impact other situations where roadmaps should have priority
        return;
      }

      if (quickFilters?.products2?.length) {
        products2.forEach(p => {
          if (p.id === quickFilters?.products2[0]) {
            setSelectedQuickFilter(p.title);
          }
        });

        // Adding condition last to not impact other situations where roadmaps should have priority
        return;
      }

      if (quickFilters?.roadmapsCorp?.length) {
        const activeRoadmap = roadmapsCorp.find(r => r.id === quickFilters?.roadmapsCorp[0]);

        setSelectedQuickFilter(activeRoadmap && activeRoadmap.title);

        return;
      }

      // Try to match quick filters with default filters
      const quickFilterFromDefaultFilters = getSelectedQuickFilterFromDefaultFilters(otherProps.defaultFilters, pageFilters);

      if (quickFilterFromDefaultFilters) {
        setSelectedQuickFilter(quickFilterFromDefaultFilters);
      }
    }, [roadmaps, roadmapsCorp, pageFilters, otherProps.defaultFilters]);

    return (
      <Component
        setQuickFilter={_setQuickFilter}
        pageFilters={pageFilters}
        fields={fields}
        userFilters={userFilters}
        staticFilters={staticFilters}
        onSavedFilterClick={_onSavedFilterClick}
        selectedQuickFilter={selectedQuickFilter}
        setSelectedQuickFilter={setSelectedQuickFilter}
        roadmaps={roadmapsFilterTree}
        onSaveFilter={_saveFilterById}
        onDeleteFilter={_deleteFilterById}
        total={resultsTotal}
        totalAllLayers={totalAllLayers}
        totalByLayer={totalByLayer}
        totalCountForDisplayLayer={totalCountForDisplayLayer}
        shouldDisplayTotalCount={shouldDisplayTotalCount}
        hasLoaded={hasLoadedResults}
        page={page}
        hasPortfolioOption={hasPortfolioOption}
        selectedView={activeView || {}}
        viewOwner={viewOwner}
        isFavoriteView={isFavoriteView}
        selectedRoadmapVersion={selectedVersionForHeader}
        handleFavoriteUserView={handleFavoriteUserView}
        isCreatingOrViewingScenario={isCreatingOrViewingScenario}
        currentUser={currentUser}
        {...otherProps}
      />
    );
  };
};

export default componentHOC;
