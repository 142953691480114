import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import isEqual from 'lodash/isEqual';

import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { FeatureFlags } from '@dragonboat/config';

import { updateState, groupByOptions, sumByOptions, durationOptions } from 'store/allocationReport';

import { getOrganization } from 'store/organization/selectors';

import useBookADemoLocker from 'hooks/useBookADemoLocker';
import usePermissions from 'hooks/permissions/usePermissions';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import { ALLOCATION_REPORT_PAGE } from 'constants/filters';
import { DEFAULT_TYPE, PLANNED_REPORTED_COLUMNS } from 'constants/allocation';
import { hasActualAllocationOption } from 'store/allocationReport/helpers';

import AllocationReportComponent from './AllocationReportComponent';
import ActualAllocationReportComponent from './ActualAllocationReportComponent';

const DEFAULT_TABLE_COLUMNS = PLANNED_REPORTED_COLUMNS;

const AllocationV2 = props => {
  const dispatch = useDispatch();
  const allocationReport = useSelector(state => state.allocationReport);
  const organization = useSelector(getOrganization);
  const { canView } = usePermissions();
  const hasKeyResults = organization.has_key_results;
  const hasProducts = organization.has_products;
  const systemFields = organization.system_fields_name;

  const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);
  const hasCorpRoadmap = canView(PERMISSION_RESOURCES.roadmapCorp);
  const hasCorpObjective = canView(PERMISSION_RESOURCES.objectiveCorp);

  const hasCompletedAllocation = useFeatureFlags([FeatureFlags.HAS_COMPLETED_ALLOCATION]);

  const groupOptions = groupByOptions({ systemFields, hasCorpRoadmap, hasProducts, hasCorpObjective, hasKeyResults, canView });
  const selectedGroupBy = allocationReport.selectedGroupBy || groupOptions.find(o => o.key === DEFAULT_TYPE);
  const selectedDuration = allocationReport.selectedDuration || durationOptions[0];
  const selectedSumBy = allocationReport.selectedSumBy || sumByOptions()[0];
  const selectedDisplayOptions = allocationReport.selectedDisplayOptions || DEFAULT_TABLE_COLUMNS;

  const BookADemoLockerComponent = useBookADemoLocker(ALLOCATION_REPORT_PAGE);

  const isAllocationByObjective = selectedGroupBy?.key === 'objective';
  const hasOverInvestedGoalsParam = new URL(window.location.href).searchParams.has('overInvestedGoals');

  const lsState = {
    ...allocationReport,
    selectedGroupBy,
    currentTab: 0, // TODO: temporary while planned team / skill allocation are hidden
    dataType: selectedGroupBy,
    sumBy: selectedSumBy,
    duration: selectedDuration,
    startDate: moment(allocationReport.startDate),
    endDate: moment(allocationReport.endDate),
    selectedDisplayOptions,
    showPlanned: allocationReport.showPlanned !== undefined ? allocationReport.showPlanned : true,
    showReported: allocationReport.showReported !== undefined ? allocationReport.showReported : true,
    showCompleted: hasCompletedAllocation ? Boolean(allocationReport.showCompleted) : false,
    showTarget: allocationReport.showTarget !== undefined ? allocationReport.showTarget : false,
    highlightOverInvestedGoals: isAllocationByObjective && hasOverInvestedGoalsParam,
  };

  const isActualAllocationByTimeFeatureEnabled = canView(PERMISSION_FEATURES.actualAllocationByTime);

  const shouldRenderActualAllocation = useMemo(
    () => isActualAllocationByTimeFeatureEnabled && hasActualAllocationOption(lsState.selectedGroupBy.key) && lsState.showActual,
    [isActualAllocationByTimeFeatureEnabled, lsState.selectedGroupBy.key, lsState.showActual],
  );

  React.useEffect(() => {
    // default allocation report preferences
    const lsStateWithDefaultItems = {
      showPlanned: lsState.showPlanned,
      showReported: lsState.showReported,
      showCompleted: lsState.showCompleted,
      showTarget: lsState.showTarget,
      showActual: lsState.showActual,
      selectedGroupBy,
      selectedDuration,
      selectedSumBy,
      selectedDisplayOptions,
    };

    const hasSameStateValues = Object.keys(lsStateWithDefaultItems).every(key =>
      isEqual(allocationReport[key], lsStateWithDefaultItems[key]),
    );

    if (!hasSameStateValues) dispatch(updateState(lsStateWithDefaultItems, false));
  }, []);

  const _onChangeGroupBy = React.useCallback(
    selectedGroupBy => {
      dispatch(updateState({ selectedGroupBy }));
    },
    [dispatch, updateState],
  );

  if (BookADemoLockerComponent) return <BookADemoLockerComponent />;

  if (shouldRenderActualAllocation)
    return (
      <ActualAllocationReportComponent
        lsState={lsState}
        groupOptions={groupOptions}
        onChangeGroupBy={_onChangeGroupBy}
        shouldDisplayControlsBar={shouldDisplayControlsBar}
      />
    );

  return (
    <AllocationReportComponent
      {...props}
      lsState={lsState}
      onChangeGroupBy={_onChangeGroupBy}
      groupByOptions={groupOptions}
      shouldDisplayControlsBar={shouldDisplayControlsBar}
    />
  );
};

export default AllocationV2;
