import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProducts, getProductsLevelTwo } from 'store/roadmaps/selectors';

import useRoadmapAutocompletes from 'hooks/useRoadmapAutocompletes';
import useMetadataIdAndRoadmapFields from './useMetadataIdAndRoadmapFields';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

import {
  getRoadmapsTreeForDropdown,
  makeAddDefaultMetadataRoadmapName,
  formatMetadataRoadmapsForDisplay,
  formatSelectedItemsBooleanTree,
  checkIsUpdatedItemChecked,
  getRoadmapMetadataToUpdate,
} from './helpers/useRoadmapsMetadata';
import { defaultTo, pipe, prop } from 'ramda';

const METADATA_THAT_SUPPORTS_CORP_RELATION = ['custom_field'];

const doesRoadmapExistsOnMetadataRoadmap = (data, roadmapId, roadmapType) => {
  const metadataRoadmaps = data?.[`${roadmapType}_roadmaps`];

  return metadataRoadmaps && metadataRoadmaps.some(roadmap => roadmap?.roadmap_id === roadmapId);
};

const doesRoadmapCorpExistsOnMetadataRoadmap = (data, roadmapCorpId, roadmapType) => {
  if (!roadmapCorpId) {
    return false;
  }
  const metadataRoadmaps = data?.[`${roadmapType}_roadmaps`];

  return metadataRoadmaps && metadataRoadmaps.some(roadmap => roadmap?.roadmap_corp_id === roadmapCorpId);
};

const toString = pipe(defaultTo(''), String);

const emptyArray = [];

const makeGetSelectedItems =
  ({ items, shouldUseCorpRoadmap, roadmaps, roadmapsCorp, getRoadmapField, hasRoadmapCorpRelation }) =>
  uniqueId => {
    const item = items.find(
      ({ id: itemId, uniqueId: itemUniqueId }) =>
        toString(itemUniqueId) === toString(uniqueId) || toString(itemId) === toString(uniqueId),
    );

    return formatSelectedItemsBooleanTree({
      shouldUseCorpRoadmap,
      roadmaps,
      roadmapsCorp,
      metadataRoadmaps: prop(getRoadmapField(item), item),
      hasRoadmapCorpRelation,
    });
  };

export default (
  addMetadataRoadmap,
  deleteMetadataRoadmap,
  items = emptyArray,
  metadataType,
  removeAllMetadataRoadmaps,
  secondaryMetadataType = null,
) => {
  const dispatch = useDispatch();
  const anchorEl = useRef(null);

  const { roadmaps, roadmapsCorp, shouldUseCorpRoadmap } = useRoadmapAutocompletes({});

  const { getDefaultRoadmapTitleForMetadataItem } = useOrganizationsAccessControl();

  const products1 = useSelector(getProducts);
  const products2 = useSelector(getProductsLevelTwo);

  const { metadataIdField, getIdField, getRoadmapField } = useMetadataIdAndRoadmapFields(metadataType, secondaryMetadataType);

  const hasRoadmapCorpRelation = shouldUseCorpRoadmap && METADATA_THAT_SUPPORTS_CORP_RELATION.includes(metadataType);

  const updateAnchorEl = value => {
    anchorEl.current = value;
  };

  // this is required to be a ref so that the function is always up to date when used on aggrid editor props
  const getSelectedItemsRef = useRef(
    makeGetSelectedItems({
      items,
      shouldUseCorpRoadmap,
      roadmaps,
      roadmapsCorp,
      getRoadmapField,
      hasRoadmapCorpRelation,
    }),
  );

  useEffect(() => {
    getSelectedItemsRef.current = makeGetSelectedItems({
      items,
      shouldUseCorpRoadmap,
      roadmaps,
      roadmapsCorp,
      getRoadmapField,
      hasRoadmapCorpRelation,
    });
  }, [items, shouldUseCorpRoadmap, roadmaps, roadmapsCorp, getRoadmapField, hasRoadmapCorpRelation]);

  const handleClearAllItems = metadata => {
    const roadmapType = secondaryMetadataType ? getRoadmapField(metadata) : metadataType;
    const hasCorpLevelType = shouldUseCorpRoadmap ? metadataType : roadmapType;

    dispatch(removeAllMetadataRoadmaps(metadata.id, hasCorpLevelType));
    updateAnchorEl(null);
  };

  const updateSelectedItems = (updateParams, data) => {
    const [topLevelRoadmapId, checkValue, updatedItem] = updateParams;

    const idField = secondaryMetadataType ? getIdField(data) : metadataIdField;
    const roadmapType = idField === metadataIdField ? metadataType : secondaryMetadataType;
    let checked = typeof checkValue === 'boolean' && checkValue;

    if (typeof checkValue === 'object') {
      checked = checkIsUpdatedItemChecked(checkValue, updatedItem);
    }

    const { roadmapCorpId, roadmapId, productId, product2Id } = getRoadmapMetadataToUpdate(
      updatedItem,
      topLevelRoadmapId,
      hasRoadmapCorpRelation,
      shouldUseCorpRoadmap,
      {
        roadmaps,
        products1,
        products2,
      },
    );

    const roadmapExists = doesRoadmapExistsOnMetadataRoadmap(data, roadmapId, roadmapType);

    /*
     * When entity roadmap does not have corp relation will use check on
     * roadmaps of is already cereated because corp roadmap is a roadmap
     */
    const corpRoadmapExists = hasRoadmapCorpRelation
      ? doesRoadmapCorpExistsOnMetadataRoadmap(data, roadmapCorpId, roadmapType)
      : doesRoadmapExistsOnMetadataRoadmap(data, roadmapCorpId, roadmapType);

    if (checked) {
      const body = {
        [idField]: data.id,
        roadmap_id: roadmapId,
        product_1_id: productId,
        product_2_id: product2Id,

        /*
         * Should not send whem roadmap entity not has corp relation
         * It means roadmap is level = CORP
         */
        ...(hasRoadmapCorpRelation ? { roadmap_corp_id: roadmapCorpId } : {}),
      };

      dispatch(addMetadataRoadmap(data.id, body, roadmapType));
    } else if (roadmapExists || corpRoadmapExists) {
      /* Todo: new approach for roadmap metadata
       * If a parent Corp level is added to the roadmap metadata, all children are rendered as checked.
       * If we try to delete one of the children it will crash/error since it is not present in the DB.
       *
       * For now we can avoid the deletion of roadmaps that were inferred from parent and design a more robust solution
       */

      dispatch(
        deleteMetadataRoadmap({ id: data.id, roadmapCorpId, roadmapId, roadmapType, subRoadmapId: productId, product2Id }),
      );
    }

    updateAnchorEl(null);
  };

  const removeMetadataRoadmap = (item, data) => {
    // TODO: find and test where this method is called. probably won't work with L0 hierarchy
    const roadmapType = secondaryMetadataType ? getRoadmapField(data) : metadataType;
    const isSubRoadmap = !!item.roadmap_id;
    const roadmapId = isSubRoadmap ? item.roadmap_id : item.id;
    const subRoadmapId = isSubRoadmap ? item.id : null;
    const product2Id = null;

    dispatch(deleteMetadataRoadmap({ id: data.id, roadmapId, roadmapType, subRoadmapId, product2Id }));
  };

  return [
    anchorEl,
    formatMetadataRoadmapsForDisplay,
    getRoadmapsTreeForDropdown,
    handleClearAllItems,
    shouldUseCorpRoadmap ? roadmapsCorp : roadmaps,
    updateAnchorEl,
    updateSelectedItems,
    makeAddDefaultMetadataRoadmapName(getDefaultRoadmapTitleForMetadataItem),
    removeMetadataRoadmap,
    getSelectedItemsRef,
  ];
};
