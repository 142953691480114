import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';

import { FeatureFlags } from '@dragonboat/config';

import TokenForm from './TokenForm';
import OAuthForm from './OAuthForm';
import OAuth2Form from './OAuth2Form';
import { useSelector } from 'react-redux';
import useFeatureFlags from 'hooks/useFeatureFlags';

const SelectAuthType = ({ onCancel, orgIntegration, setSelectedIntegrationId }) => {
  const [authType, setAuthType] = useState(null);
  const jiraConfig = orgIntegration && orgIntegration.data;
  const isJiraOauth2Enabled = useSelector(state => state.organization.organization.has_jira_oauth2);
  const allowFullBaseUrl = useFeatureFlags([FeatureFlags.ALLOW_FULL_INTEGRATION_URL_PATH]);

  if (authType === 'token') {
    return (
      <TokenForm
        orgIntegration={orgIntegration}
        setSelectedIntegrationId={setSelectedIntegrationId}
        jiraConfig={jiraConfig}
        allowFullBaseUrl={allowFullBaseUrl}
        goBack={() => setAuthType(null)}
      />
    );
  }

  if (authType === 'oauth') {
    return (
      <OAuthForm
        orgIntegration={orgIntegration}
        setSelectedIntegrationId={setSelectedIntegrationId}
        jiraConfig={jiraConfig}
        allowFullBaseUrl={allowFullBaseUrl}
        goBack={() => setAuthType(null)}
      />
    );
  }

  if (authType === 'oauth2') {
    return (
      <OAuth2Form
        orgIntegration={orgIntegration}
        setSelectedIntegrationId={setSelectedIntegrationId}
        jiraConfig={jiraConfig}
        allowFullBaseUrl={allowFullBaseUrl}
        goBack={() => setAuthType(null)}
      />
    );
  }

  return (
    <Card>
      <CardContent>
        <CenteredRow>
          <Title>Select Integration Type</Title>
          <Row>
            <Button id="jira-oauth" color="primary" onClick={() => setAuthType('oauth')}>
              OAuth 1
            </Button>
            {isJiraOauth2Enabled &&
              <Button id="jira-oauth-2" color="primary" onClick={() => setAuthType('oauth2')}>
                Oauth 2
              </Button>
            }
            <Button id="jira-token" color="primary" onClick={() => setAuthType('token')}>
              Jira API Token
            </Button>
          </Row>
        </CenteredRow>

        {onCancel && (
          <BackButton color="primary" onClick={onCancel}>
            Back
          </BackButton>
        )}
      </CardContent>
    </Card>
  );
};

const Title = styled.div`
  font-size: 16px;
`;

const CenteredRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  width: 25%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const BackButton = styled(Button)`
  &&&& {
    margin-top: 38px;
    margin-bottom: 20px;
    float: left;
  }
`;

export default SelectAuthType;
