import reduceReducers from 'reduce-reducers';
import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  SET_DRAGONBOT_LAST_PROMPT_RESULT,
  REQUEST_DRAGONBOT_PROMPT_RESULT,
  GET_DRAGONBOT_CORPUS_VERSIONS_FULFILLED,
  UPDATE_DRAGONBOT_CORPUS_VERSION_FULFILLED,
  GET_DRAGONBOT_CORPUS_VERSIONS,
  MAKE_DRAGONBOT_CORPUS_VERSION_CURRENT_FULFILLED,
  UPDATE_DRAGONBOT_CORPUS_VERSION,
  MAKE_DRAGONBOT_CORPUS_VERSION_CURRENT,
  TOGGLE_DRAGONBOT_VISIBILITY,
  SET_DRAGONBOT_HISTORY_CACHE_EXPIRY,
} from './types';
import updateRows from 'store/utils/updateRows';
import { REHYDRATE } from 'redux-persist';
import moment from 'moment';
import { DRAGONBOT_HISTORY_CACHE_STORAGE_KEY } from './constants';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  REQUEST_DRAGONBOT_PROMPT_RESULT,
  GET_DRAGONBOT_CORPUS_VERSIONS,
  UPDATE_DRAGONBOT_CORPUS_VERSION,
  MAKE_DRAGONBOT_CORPUS_VERSION_CURRENT,
]);

const initialState = {
  lastPromptResult: null,
  corpusVersionBeingEdited: null,
  corpusVersions: [],
  botWindowOpen: false,
  cacheChatHistory: {
    expiresIn: null,
  },
  ...thunksInitialState,
};

const dragonbotReducer = (state = initialState, { type, payload, meta, key }) => {
  switch (type) {
    case TOGGLE_DRAGONBOT_VISIBILITY:
      return {
        ...state,
        botWindowOpen: payload,
      };
    case SET_DRAGONBOT_LAST_PROMPT_RESULT:
      return {
        ...state,
        lastPromptResult: payload,
      };
    case GET_DRAGONBOT_CORPUS_VERSIONS_FULFILLED:
      return {
        ...state,
        corpusVersions: payload,
      };
    case UPDATE_DRAGONBOT_CORPUS_VERSION_FULFILLED:
      return {
        ...state,
        corpusVersions: updateRows(state.corpusVersions, payload),
      };
    case MAKE_DRAGONBOT_CORPUS_VERSION_CURRENT_FULFILLED:
      return {
        ...state,
        corpusVersions: state.corpusVersions.map(version => ({
          ...version,
          current: version.id === payload.id,
        })),
      };
    case SET_DRAGONBOT_HISTORY_CACHE_EXPIRY:
      return {
        ...state,
        cacheChatHistory: { ...payload },
      };
    case REHYDRATE:
      if (key !== 'root') {
        return state;
      }

      const currentState = payload?.dragonbot || {};

      const botHistoryExpiresIn = currentState?.cacheChatHistory?.expiresIn;

      if (!botHistoryExpiresIn || moment(botHistoryExpiresIn).isBefore(moment())) {
        localStorage.removeItem(DRAGONBOT_HISTORY_CACHE_STORAGE_KEY);
      }

      return {
        ...currentState,
        botWindowOpen: initialState.botWindowOpen,
      };
    default:
      return state;
  }
};

export { initialState };

const reducer = reduceReducers(initialState, dragonbotReducer, ...thunksReducers);

export default reducer;
