import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

const DragonAvatar = ({
  label,
  short,
  onClick,
  showBorder = true,
  styledWrapper = true,
  tooltipTitle,
  description,
  nameHighlighted = false,
  noLabel = false,
  ...props
}) => {
  const renderAvatar = () => {
    if (tooltipTitle) {
      return (
        <Tooltip title={tooltipTitle}>
          <Avatar>
            <Label>{short}</Label>
          </Avatar>
        </Tooltip>
      );
    }

    return (
      <Avatar>
        <Label>{short}</Label>
      </Avatar>
    );
  };

  return (
    <Wrapper onClick={onClick} showBorder={showBorder} styledWrapper={styledWrapper} {...props}>
      {renderAvatar()}
      {!noLabel && (
        <UserInfo>
          <Name highlighted={nameHighlighted}>{label}</Name>
          {description && <Description>{description}</Description>}
        </UserInfo>
      )}
    </Wrapper>
  );
};

export const Label = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  cursor: pointer;

  color: ${({ theme }) => theme.palette.newLayout.text.white};
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme }) => theme.palette.newLayout.background.dark};
  border-radius: 50%;

  width: 32px;
  height: 32px;
`;

const Name = styled.p`
  &&&& {
    padding: 0;
    margin: 0;

    font-family: Inter;
    font-style: normal;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    font-size: ${({ theme }) => theme.typography.fontSize}px;

    color: ${({ theme }) => theme.palette.newLayout.text.black};

    ${({ theme, highlighted }) =>
      highlighted &&
      `
      font-size: ${theme.typography.fontSizeSmallLarge}px;
      color: ${theme.palette.primary.main};
    `}
  }
`;

const UserInfo = styled.div``;

const Description = styled.div`
  text-align: left;
  font-size: ${({ theme }) => theme.typography.fontSize}px;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.palette.background.dimGray};
  margin-top: 4px;
`;

const Wrapper = styled.div`
  ${({ styledWrapper }) =>
    styledWrapper &&
    `
      width: calc(100% - 18px);
      display: flex;
      align-items: center;

      gap: 14px;

      padding: 12px 14px;
      box-sizing: border-box;
      margin: 0 9px;
    `}

  cursor: pointer;

  ${({ showBorder, theme }) => {
    if (showBorder) {
      return `border-top: 1px solid ${theme.palette.newLayout.background.lightBlack};`;
    }
  }}

  &:hover {
    ${Avatar} {
      background: ${({ theme }) => theme.palette.newLayout.background.primary};
    }
    ${Label} {
      color: ${({ theme }) => theme.palette.newLayout.text.white};
    }
    ${Name} {
      color: ${({ theme }) => theme.palette.newLayout.text.primary};
    }
  }
`;

DragonAvatar.propTypes = {
  label: PropTypes.string,
  short: PropTypes.string,
  onClick: PropTypes.func,
};

export default DragonAvatar;
