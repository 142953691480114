import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import {
  createTeam,
  updateTeamById,
  mergeTeams,
  fetchTeams,
  removeUnsavedTeams,
  addTeamWithoutSave,
  bulkCreateTeams,
  bulkUpdateTeams,
  bulkDeleteTeams,
  switchTeamsRowOrder,
  deleteTeamById,
  bulkDeleteTeamRoadmaps,
  createTeamRoadmap,
  deleteTeamRoadmap,
} from 'store/teams';
import { getOrganization, getOrgHasTeamsLevelTwo as getOrgHasTeamsLevelTwoSelector } from 'store/organization';
import { getAllTeams } from 'store/teams/selectors';
import { TEAMS } from 'store/grids/constants';
import useGridRowsFormat from 'hooks/grid/useGridRowsFormat';

import { TEAM_KEY } from '../helpers/constants';

const useTeams = () => {
  const dispatch = useDispatch();

  const organization = useSelector(getOrganization);
  const teams = useSelector(getAllTeams);
  const hasTeamsLevelTwo = useSelector(getOrgHasTeamsLevelTwoSelector);

  const { gridRows } = useGridRowsFormat(teams, {
    settingKey: TEAM_KEY,
    gridStorePath: TEAMS,
    multiLevelEnabled: hasTeamsLevelTwo,
  });

  const childrenSettingKeys = useMemo(() => (hasTeamsLevelTwo ? ['team2'] : []), [hasTeamsLevelTwo]);

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          fetchTeams,
          createTeam,
          bulkCreateTeams,
          bulkUpdateTeams,
          bulkDeleteTeams,
          addTeamWithoutSave,
          removeUnsavedTeams,
          updateTeamById,
          deleteTeamById,
          mergeTeams,
          switchTeamsRowOrder,
        },
        dispatch,
      ),
    [dispatch],
  );

  return {
    teams: gridRows,
    childrenSettingKeys,
    systemFields: organization.system_fields_name,
    bulkDeleteTeamRoadmaps,
    createTeamRoadmap,
    deleteTeamRoadmap,
    hideMetadataRoadmaps: !organization.enable_metadata_roadmaps,
    hasTeamsLevelTwo,
    ...boundActionCreators,
  };
};

export default useTeams;
