export default viewType => {
  switch (viewType) {
    case 'allocationReport':
      return 'hospitalGreen';
    case 'ideasBoard':
      return 'wish';
    case 'ideasGrid:flat':
    case 'ideasGrid:ideas':
    case 'ideasGrid:estimates':
      return 'kendallRose';
    case 'manage':
    case 'forecast':
      return 'veilingWaterfalls';
    case 'progress':
      return 'sunkissedYellow';
    case 'roadmap':
      return 'marchYellow';
    case 'snapshot':
      return 'beyondTheClouds';
    case 'staffing':
      return 'pruneMousse';
    case 'summary':
      return 'dingyStickyNote';
    default:
      return 'wish';
  }
};
