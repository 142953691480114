import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import getCookie from 'src/utils/getCookie';
import axios from 'axios';

import { AUTH_HOST } from 'src/constants/browserCookies.js';

const ZENDESK_REDIRECT_ROUTE = '/zendesk-redirect';

const createDomainFromURL = url => ['', ...new URL(url).host.split('.').slice(1)].join('.');

export default props => {
  let orgHost = getCookie(AUTH_HOST);
  const [error, setError] = useState(null);
  const { isAuthenticated, currentUser } = useSelector(state => state.login);

  const zendeskHostName = process.env.REACT_APP_ZENDESK_ACCOUNT_HOST;

  // The zendesk link the user is trying to access
  const params = new URL(window.location.href)?.searchParams;
  const returnToLink = params.get('return_to');

  /**
    Auth_Host cookie is not available until user is authenticated but it is needed
    to redirect to the correct org login after obtaining the workspace value
    If the user organization already exists in the store we can get the value from the organization slug
  */
  if (!orgHost && isAuthenticated && currentUser?.organization) {
    const cookieDomain = createDomainFromURL(process.env.REACT_APP_PUBLIC_URL);
    const cookieValue = `${currentUser.organization.slug}${cookieDomain}`;

    document.cookie = `${AUTH_HOST}=${cookieValue}; Domain=${cookieDomain}; Path=/; SameSite=lax`;
    orgHost = cookieValue;
  }

  const redirectTo = link => {
    const baseUrl = orgHost ? `${window.location.protocol}//${orgHost}` : process.env.REACT_APP_PUBLIC_URL;
    const url = new URL(link, baseUrl);
    const params = url.searchParams;

    if (returnToLink) {
      params.append('return_to', returnToLink);
    }

    window.location.href = url.href;
  };

  // User is authenticated on the current org, generate SSO token and redirect to zendesk
  const returnToZendeskWithToken = async () => {
    try {
      const { data: token } = await axios.post(`/api/integrations/zendesk/sso-token`);
      const zendeskAuthLink = `https://${zendeskHostName}.zendesk.com/access/jwt?jwt=${token}`;

      redirectTo(zendeskAuthLink);
    } catch (err) {
      setError(err);
    }
  };

  // If Auth_Host cookie does not exist or user is not authenticated
  const redirectToLogin = () => redirectTo(`/login?next=${ZENDESK_REDIRECT_ROUTE}`);

  // If the current location is the public url, redirect to this page with the org slug
  const redirectWithOrgSlug = () => redirectTo(ZENDESK_REDIRECT_ROUTE);

  useEffect(() => {
    if (!orgHost) {
      redirectToLogin();
      return;
    }

    if (window.location.host === orgHost) {
      isAuthenticated ? returnToZendeskWithToken() : redirectToLogin();
      return;
    }

    redirectWithOrgSlug();
  }, [orgHost, error, isAuthenticated, currentUser]);

  return error ? <Redirect to="/" /> : <div />;
};
