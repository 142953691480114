import { useMemo } from 'react';

import { getMetricValueFromMetricObject, TARGET } from 'utils/metricValues';

export default function useMetricValues(metric, updateMetric, updateMetricValue, addMetricValue) {
  const actualMetricValue = useMemo(() => {
    return getMetricValueFromMetricObject(metric);
  }, [metric]);

  const targetMetricValue = useMemo(() => {
    return getMetricValueFromMetricObject(metric, TARGET);
  }, [metric]);

  const baselineMetricValue = useMemo(() => {
    if (metric) {
      return {
        value: metric.baseline_value || null,
        date: metric.baseline_date || null,
      };
    }

    return { value: null, date: null };
  }, [metric]);

  const handleUpdateMetricValue = (type, value, field, metricValue) => {
    if (!metric || !type || !field || !metricValue) return;

    const selectedMetric = metric;

    if (value && selectedMetric && selectedMetric.id) {
      const metricId = selectedMetric.id;
      // update metric value with proper info - need existing metric value + metric
      const data = {
        metric_id: metricId,
        type,
      };

      if (field === 'value') {
        data.value = value;
        data.id = metricValue?.id || undefined;
        data.date = metricValue?.date || new Date();
      }

      if (field === 'date') {
        data.date = value;
        data.value = metricValue?.value || null;
      }

      if (data.id) {
        updateMetricValue(data.id, data);
      } else {
        addMetricValue(data);
      }
    }
  };

  const handleUpdateMetricBaseline = (metricId, update) => {
    updateMetric(metricId, update);
  };

  return {
    actualMetricValue,
    targetMetricValue,
    baselineMetricValue,
    handleUpdateMetricValue,
    handleUpdateMetricBaseline,
  };
}
