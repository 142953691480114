import { prop } from 'ramda';
import { useMemo } from 'react';

import theme from 'design-system/theme';

import { DEFAULT_LIMIT_TO_HIDE_DATALABEL } from 'constants/charts';
import getMaxValueOnBarStackedChart from 'utils/charts/getMaxValueOnBarStackedChart';

const DEFAULT_EXTRACT_PROP = prop('formatted');

export const useBarStackedCharDatalabelsConfig = (
  datasets,
  limitPercentToHide = DEFAULT_LIMIT_TO_HIDE_DATALABEL,
  dataValueExtractor = DEFAULT_EXTRACT_PROP,
) => {
  const maxValueOnChartData = useMemo(() => getMaxValueOnBarStackedChart(datasets), [datasets]);

  return {
    color: theme.palette.text.primary,
    display: context => {
      const maxValueToHide = maxValueOnChartData * limitPercentToHide;
      const value = dataValueExtractor(context.dataset.data[context.dataIndex]);

      return value > maxValueToHide && !!context.dataset.isAllocationDataset;
    },
    labels: {
      value: {
        align: ({ dataset }) => {
          if (dataset.stack === '2' && dataset.order === 1) {
            return 'start';
          }
          return 'end';
        },
        anchor: 'end',
        clamp: true,
        font: {
          size: theme.typography.fontSize,
        },
      },
    },
    formatter: dataValueExtractor,
  };
};
