import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, path, pipe, equals } from 'ramda';

import { updateCycle, updateDeliverable } from 'store/cycleDeliverables/thunks';
import { CYCLE_DELIVERABLE_LEVEL } from 'constants/common';
import { DELIVERABLE_KEY } from 'constants/pdlc';
import useSystemFields from 'hooks/useSystemFields';
import useAppNotifications from 'hooks/useAppNotifications';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { getDuplicatedToastErrorMessage } from 'utils';
import { ROADMAP, SUB_ROADMAP, DUPLICATED_ERROR_CODE } from 'config';

const isErrorCodeDuplicatedRecord = pipe(path(['response', 'data', 'error_code']), equals(DUPLICATED_ERROR_CODE));

const useUpdateCycleDeliverable = cycleDeliverable => {
  const dispatch = useDispatch();

  const { canUpdate } = usePermissions();
  const { addNotification } = useAppNotifications();
  const [getSystemFieldName] = useSystemFields();

  const permissionResource =
    cycleDeliverable?.level === CYCLE_DELIVERABLE_LEVEL.deliverable
      ? PERMISSION_RESOURCES.deliverable
      : PERMISSION_RESOURCES.cycle;

  const allowEdit = canUpdate(permissionResource);

  const onUpdateError = (error, data) => {
    if (isErrorCodeDuplicatedRecord(error)) {
      addNotification({
        id: 'deliverable-creation-duplicate',
        message: getDuplicatedToastErrorMessage(
          getSystemFieldName(DELIVERABLE_KEY),
          data.title,
          getSystemFieldName(ROADMAP),
          getSystemFieldName(SUB_ROADMAP),
          '/settings/pdlc',
        ),
      });
    }
  };

  const onUpdate = useCallback(
    (updatedData = {}) => {
      if (!allowEdit) return;
      if (isEmpty(updatedData)) return;

      if (cycleDeliverable.level === CYCLE_DELIVERABLE_LEVEL.deliverable) {
        return dispatch(updateDeliverable(cycleDeliverable?.id, updatedData, onUpdateError));
      }

      return dispatch(updateCycle(cycleDeliverable?.id, updatedData));
    },
    [cycleDeliverable, allowEdit],
  );

  const onChangeDescription = useCallback(
    newDescription => {
      if (cycleDeliverable?.description !== newDescription) {
        onUpdate({ description: newDescription });
      }
    },
    [onUpdate, cycleDeliverable?.description],
  );

  const onChangeTitle = useCallback(
    value => {
      if (cycleDeliverable?.title !== value) {
        onUpdate({ title: value });
      }
    },
    [onUpdate, cycleDeliverable?.title],
  );

  const onChangeContact = useCallback(
    value => {
      if (cycleDeliverable?.contact_id !== value) {
        onUpdate({ contact_id: value });
      }
    },
    [onUpdate, cycleDeliverable?.contact_id],
  );

  const onChangeColor = useCallback(
    value => {
      if (cycleDeliverable?.color !== value.hex) {
        onUpdate({ color: value.hex });
      }
    },
    [onUpdate, cycleDeliverable?.color],
  );

  const onChangeStatus = useCallback(
    value => {
      if (cycleDeliverable?.status !== value) {
        onUpdate({ status: value });
      }
    },
    [onUpdate, cycleDeliverable?.status],
  );

  return {
    canUpdateCycleDeliverable: allowEdit,
    onChangeDescription,
    onChangeTitle,
    onChangeContact,
    onChangeColor,
    onChangeStatus,
    onUpdate,
  };
};

export default useUpdateCycleDeliverable;
