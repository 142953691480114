import { not, omit, path } from 'ramda';
import { OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2, OBJECT_OBJECTIVE, OBJECT_OBJECTIVE_CORP_STRING } from 'store/objectives/types';
import { isEqual } from 'lodash';

import { IDEAS_GRID_FLAT_PATH } from 'constants/filters/pages';

const prepareGridStateForComparison = gridState => {
  if (!gridState?.columnState) {
    return gridState;
  }

  const ignoreColumnProps = ['width'];
  const ignoreColumns = ['drag', 'select', 'row_order', 'rank'];

  const columnState = gridState.columnState
    .filter(({ colId }) => !ignoreColumns.includes(colId)) // Ignore some columns
    .filter(({ hide }) => !hide) // Only compare columns that are visible
    .map(columnSpec => omit(ignoreColumnProps, columnSpec)); // Remove ignored column props

  return {
    ...gridState,
    columnState,
  };
};

/**
 * @param groupData AgGrid `params.data.groupData` as formatted by `utils/setIdeasHierarchyPath`
 * @returns The OKR ID, if any. The OKR, if the group represents an OKR.
 */
const getGroupDataOkrIdAndLevel = groupData => {
  const objectiveCorp = path(['objectiveCorp', 'id'], groupData);
  const objectiveId = path(['objective', 'id'], groupData);
  const keyResult1Id = path(['keyResult1', 'id'], groupData);
  const keyResult2Id = path(['keyResult2', 'id'], groupData);

  const okrId = objectiveCorp || objectiveId || keyResult1Id || keyResult2Id;

  let level;

  if (objectiveCorp) {
    level = OBJECT_OBJECTIVE_CORP_STRING;
  } else if (objectiveId) {
    level = OBJECT_OBJECTIVE;
  } else if (keyResult1Id) {
    level = OBJECT_KEY_RESULT;
  } else if (keyResult2Id) {
    level = OBJECT_KEY_RESULT_2;
  }

  return { okrId, level };
};

const isGridStateDirty = (previousState, newState) => {
  const previousStateToCompare = prepareGridStateForComparison(previousState);
  const newStateToCompare = prepareGridStateForComparison(newState);

  return not(isEqual(previousStateToCompare, newStateToCompare));
};

const isProjectsFlatSearchActive = () => {
  return window.location.pathname.includes(IDEAS_GRID_FLAT_PATH);
};

const filterAllEntries = (f, data) => Object.entries(data).reduce((acc, [key, values]) => ({ ...acc, [key]: f(values) }), {});
const makeItSelectable = arr => arr.map(item => ({ ...item, selectable: true }));

const UNIQUE_KEY_SEPARATOR = `|»|`;

const generateRowUniqueId = object => {
  const { id, group, level } = object;

  let suffix = object.type;

  if (group) {
    suffix = `group-${level}`;
  }

  return `${suffix}${UNIQUE_KEY_SEPARATOR}${id}`;
};

const getObjectIdFromUniqueId = uniqueId => uniqueId?.split(UNIQUE_KEY_SEPARATOR)[1];

export {
  getGroupDataOkrIdAndLevel,
  isGridStateDirty,
  isProjectsFlatSearchActive,
  generateRowUniqueId,
  getObjectIdFromUniqueId,
  filterAllEntries,
  makeItSelectable,
};
