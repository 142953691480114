import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { css } from 'styled-components';

import {
  createCustomerRequestFromProject as createCustomerRequestFromProjectAction,
  addCustomerRequestFromProject as addCustomerRequestFromProjectAction,
} from 'store/customerRequests/thunks';
import { getCustomerRequestsByIds, getGridCustomerRequest, getMetadata } from 'store/customerRequests/selectors';
import { getSelectedProjectOnLigthbox } from 'store/projectLightbox/selectors';
import { removeProjectFromCustomerRequest as removeProjectFromCustomerRequestAction } from 'store/customerRequests/actions';
import { useCommonGridEventHandlers, useCommonGridProps } from 'design-system/molecules/AgGridReact-New/hooks';
import { OPEN_CUSTOMER_REQUEST_QUERY_PARAM } from 'constants/customerRequests';
import useCustomerRequests from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequests';
import useUpdateCustomerRequestOnGrid from 'routes/CustomerRequests/Grid/New/hooks/useUpdateCustomerRequestOnGrid';
import useCustomerRequestsGridMetadata from 'routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsGridMetadata';
import sortByRank from 'utils/sortByRank';
import useProjectLightboxRequestsColumns from './useProjectLightboxRequestsColumns';

const OFFSET = 350;
const TABLE_HEIGHT = `calc(100vh - ${OFFSET}px)`;

const gridStyles = css`
  .ag-layout-normal.ag-root {
    margin: 0;
  }
`;

const useProjectLightboxRequestsGrid = () => {
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);

  const metadata = useSelector(getMetadata);

  const { metadata: requestsGridMetadata, createMultiSelectOption } = useCustomerRequestsGridMetadata();

  const { updateCustomerRequestByIdOnCellValueChange } = useUpdateCustomerRequestOnGrid();

  const project = useSelector(getSelectedProjectOnLigthbox);

  const customerRequests = useSelector(state => getCustomerRequestsByIds(state, (project || {}).customer_requests_ids || []));

  const sortedCustomerRequests = (customerRequests || []).map(req => getGridCustomerRequest(metadata, req)).sort(sortByRank);

  const addNewRow = (isLink = false) => setRows(prevRows => [{ isLink }, ...prevRows]);

  const removeUnsavedRows = () => setRows(prevRows => prevRows.filter(r => r.id));

  const createCustomerRequest = data => dispatch(createCustomerRequestFromProjectAction((project || {}).id, data?.title));

  const addCustomerRequest = id => dispatch(addCustomerRequestFromProjectAction((project || {}).id, id));

  const onOpenCustomerRequestInfo = useCallback(
    row => window?.open(`/requests?${OPEN_CUSTOMER_REQUEST_QUERY_PARAM}=${row.key}`, '_blank'),
    [],
  );

  const unlinkCustomerRequest = useCallback(
    row => dispatch(removeProjectFromCustomerRequestAction(row.id, (project || {}).id)),
    [removeProjectFromCustomerRequestAction],
  );

  const { columnDefs, defaultColDef } = useProjectLightboxRequestsColumns({
    onOpenCustomerRequestInfo,
    createMultiSelectOption,
    updateCustomerRequestByIdOnCellValueChange,
    addCustomerRequest,
    unlinkCustomerRequest,
  });

  const { onSwitchRowOrder } = useCustomerRequests();

  const commonGridProps = useCommonGridProps({});

  const commonGridEvents = useCommonGridEventHandlers({
    items: rows,
    idKey: 'id',
    actions: {
      update: updateCustomerRequestByIdOnCellValueChange,
      save: createCustomerRequest,
      remove: removeUnsavedRows,
      drag: onSwitchRowOrder,
    },
  });

  useEffect(() => {
    const requestsRows = rows.filter(r => r.id);

    if (!isEqual(requestsRows, sortedCustomerRequests)) {
      setRows(sortedCustomerRequests);
    }
  }, [sortedCustomerRequests]);

  return {
    rows,
    addNewRow,
    columnDefs,
    defaultColDef,
    commonGridProps,
    commonGridEvents,
    gridContext: { metadata: requestsGridMetadata },
    gridStyles,
    tableHeight: TABLE_HEIGHT,
  };
};

export default useProjectLightboxRequestsGrid;
