import {
  calculatePiePercentage,
  calculateShowValueOnPie,
  DEFAULT_PIE_LABEL_COLOR,
  DEFAULT_UNDEFINED_COLOR,
} from 'constants/charts';
import { pipe, defaultTo, split, isNil, not, equals, either } from 'ramda';

const OUTSIDE_LINE_EXTENSION_X = 10;
const OUTSIDE_LINE_EXTENSION_Y = 15;
const OVERLAPPING_CORRECTION = 42;
const LABEL_ALIGN_ADJUSTMENT_X = 14;
const LABEL_ALIGN_ADJUSTMENT_Y = 5;
const LABEL_FONT_SIZE = 12;
const SMALL_LABEL_FONT_SIZE = 10;

const isNilOrZero = either(isNil, equals(0));

const isNotNil = pipe(isNil, not);

const splitWords = pipe(defaultTo(''), split(' '));

const getSuitableY = (y, coordinates = [], direction) => {
  let result = y;

  coordinates.forEach(existingY => {
    if (existingY - OVERLAPPING_CORRECTION < result && existingY + OVERLAPPING_CORRECTION > result) {
      if (direction === 'right') {
        result = existingY + OVERLAPPING_CORRECTION;
      } else {
        result = existingY - OVERLAPPING_CORRECTION;
      }
    }
  });
  return result;
};

/**
 * @function wrapText
 *
 * Function to build labels / and wrap text
 *
 * @param context : <any> - canvas context
 * @param textvalues : <any> - array of text values (top is label, bottom is value/number)
 * @param x : <number> - x coordinate of label start
 * @param y : <number> - y coordinate of label start
 * @param maxWidth : <number> - max width that text on canvas should be
 * @param lineHeight : <number> - lineheight for each line in label
 * */
const wrapText = (context, textValues = [], x, y, maxWidth = 87, lineHeight = 12) => {
  const defaultFontAtrributes = context.font.split(' ');

  textValues.forEach((val, index) => {
    const isTextLabel = index === 0;

    // labels can only have a max # of 2 lines
    let lineCount = 0;
    let line = '';

    if (isTextLabel) {
      context.font = `${LABEL_FONT_SIZE}px ${defaultFontAtrributes[1]}`;
    } else {
      context.font = `${SMALL_LABEL_FONT_SIZE}px ${defaultFontAtrributes[1]}`;
    }

    const words = splitWords(val);

    for (let n = 0; n < words.length; n++) {
      if (lineCount > 1) return;

      const testLine = `${line + words[n]} `;
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > maxWidth) {
        lineCount++;
        const lineAdd = lineCount > 1 ? `${line}...` : line;

        context.fillText(lineAdd, x, y);
        line = `${words[n]} `;
        y += lineHeight;
      } else {
        line = testLine;
      }
    }

    context.fillText(line, x, y);
    y += lineHeight;
  });
};

/**
 *
 * @param {Function} piePercentageFormatter
 * @param {Object} leadLinesPluginOptions
 * @param {Boolean} leadLinesPluginOptions.hideValueIfZero
 * @returns
 */
export const makeLeadLinesPlugin = (piePercentageFormatter, leadLinesPluginOptions = {}) => {
  const { hideValueIfZero = false } = leadLinesPluginOptions;

  return {
    id: 'pieChartLeadLines',
    afterDraw: chart => {
      const { ctx } = chart;

      ctx.save();

      const leftLabelCoordinates = [];
      const rightLabelCoordinates = [];
      // prettier-ignore
      const chartCenterPoint = {
      x: ((chart.chartArea.right - chart.chartArea.left) / 2) + chart.chartArea.left,
      y: ((chart.chartArea.bottom - chart.chartArea.top) / 2) + chart.chartArea.top,
    };

      chart.config.data.labels.forEach((label, i) => {
        const dataset = chart.config.data.datasets[0];
        const dataPercentage = piePercentageFormatter({ dataset, dataIndex: i });
        const dataValue = dataset.data[i];

        const shouldNotDisplayValue = hideValueIfZero && isNilOrZero(dataValue);
        const hideData = (isNotNil(dataPercentage) && !calculateShowValueOnPie(dataPercentage)) || shouldNotDisplayValue;

        if (hideData) return;

        const meta = chart.getDatasetMeta(0);
        const arc = meta.data[i];
        // Prepare data to draw (get center point of slice)
        const centerPoint = arc.getCenterPoint();

        const { options, outerRadius } = arc.getProps();

        const { polyline } = dataset;

        const labelColor = polyline?.labelColor || DEFAULT_PIE_LABEL_COLOR;
        const color = options.backgroundColor || DEFAULT_UNDEFINED_COLOR;

        const angle = Math.atan2(centerPoint.y - chartCenterPoint.y, centerPoint.x - chartCenterPoint.x);

        // get the edge center of each slice for starting point of lead line
        // prettier-ignore
        const sliceEdgeX = chartCenterPoint.x + (outerRadius * Math.cos(angle));
        // prettier-ignore
        const sliceEdgeY = chartCenterPoint.y + (outerRadius * Math.sin(angle));

        // important point 2, this point overlapped with existed points
        // so we will reduce y by 14 if it's on the right
        // or add by 14 if it's on the left
        // prettier-ignore
        const point2X = chartCenterPoint.x + (Math.cos(angle) * (outerRadius + OUTSIDE_LINE_EXTENSION_X));
        // prettier-ignore
        let point2Y = chartCenterPoint.y + (Math.sin(angle) * (outerRadius + OUTSIDE_LINE_EXTENSION_Y));

        if (point2X < chartCenterPoint.x) {
          point2Y = getSuitableY(point2Y, leftLabelCoordinates, 'left');
        } else {
          point2Y = getSuitableY(point2Y, rightLabelCoordinates, 'right');
        }

        let value = label;

        if (polyline && polyline.formatter) {
          value = polyline.formatter(value);
        }

        if (point2X < chartCenterPoint.x) {
          leftLabelCoordinates.push(point2Y);
        } else {
          rightLabelCoordinates.push(point2Y);
        }

        const labelY = point2Y > centerPoint.y ? point2Y + LABEL_ALIGN_ADJUSTMENT_Y : point2Y - LABEL_ALIGN_ADJUSTMENT_Y;
        const labelX = point2X < centerPoint.x ? point2X - LABEL_ALIGN_ADJUSTMENT_X : point2X + LABEL_ALIGN_ADJUSTMENT_X;

        // DRAW CODE
        // connect between arc's edge center point and outside point
        ctx.strokeStyle = color;
        ctx.beginPath();
        ctx.moveTo(sliceEdgeX, sliceEdgeY);
        ctx.lineTo(point2X, point2Y);
        ctx.stroke();
        // second line: connect between outside point and label's edge
        ctx.beginPath();
        ctx.moveTo(point2X, point2Y);
        ctx.lineTo(labelX, point2Y);
        ctx.stroke();

        // add custom label
        const labelAlignStyle = point2X < chartCenterPoint.x ? 'right' : 'left';

        ctx.textAlign = labelAlignStyle;
        ctx.textBaseline = point2Y > centerPoint.y ? 'bottom' : 'middle';
        ctx.fillStyle = labelColor;

        const labelComponents = [dataValue.toLocaleString()];

        if (isNotNil(dataPercentage)) {
          labelComponents.unshift(`${dataPercentage}%`);
        }
        const labelValues = [value, `(${labelComponents.join(', ')})`];

        wrapText(ctx, labelValues, labelX, labelY, dataset.labelMaxWidth);

        ctx.restore();
      });
    },
  };
};

export const LEAD_LINES_PLUGIN = makeLeadLinesPlugin(calculatePiePercentage);
