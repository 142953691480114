import styled from 'styled-components';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import Button from 'design-system/atoms/Button/index';


export const ScreenContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IllustrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.unit * 6}px;
`;

export const StyledText = styled(TextDeprecated)`
  &&&& {
    max-width: ${({ maxWidth }) => maxWidth || '95%'};
    font-size: ${({ theme }) => theme.typography.fontSizeH4}px;
    color: ${({ theme }) => theme.palette.text.black};
    line-height: normal;
    text-align: center;
    margin: ${({ theme, withMarginTop }) => (withMarginTop ? `${theme.spacing.unit * 2}px auto 0 auto` : '0 auto')};

    b {
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    }
  }
`;

export const StyledButton = styled(Button)`
  &&&& {
    margin-top: ${props => props.theme.spacing.unit * 3}px;
    text-transform: uppercase;
  }
`;
