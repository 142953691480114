import React from 'react';
import styled from 'styled-components';

import Button from 'design-system/atoms/Button/index';

import { materialColors } from 'design-system/themes/default';

const AzureLoginButton = ({ handleClick }) => {
  return (
    <AzureButton background={materialColors.mediumPurple} variant="contained" onClick={handleClick}>
      Login with Azure
    </AzureButton>
  );
};

export default AzureLoginButton;

const AzureButton = styled(Button)`
  &&&& {
    width: 100%;
    color: #ffffff;
  }
`;
