import React from 'react';

import { CommentsLightboxProvider } from '../IdeasList/IdeasList/New/hooks/useCommentsLightbox';
import { EstimatesLightboxProvider } from './hooks/useEstimatesLightbox';
import { TeamLightboxProvider } from './hooks/useTeamLightbox';

import IdeasForecastList from './IdeasForecastList';
import MissingTeamsSkillsAlert from './MissingTeamsSkillsAlert/MissingTeamsSkillsAlert';

export default ({ ...props }) => {
  return (
    <TeamLightboxProvider>
      <EstimatesLightboxProvider>
        <CommentsLightboxProvider>
          <IdeasForecastList {...props} />
          <MissingTeamsSkillsAlert />
        </CommentsLightboxProvider>
      </EstimatesLightboxProvider>
    </TeamLightboxProvider>
  );
};
