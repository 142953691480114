import useProjectsWithRoadmapVersions from './useProjectsWithRoadmapVersions';
import { useIdeasGrid } from 'containers/IdeasList/IdeasList/New/hooks';

const emptyArray = [];

const useCompareRoadmapVersionsGrid = ({
  parentHandleGridReady,
  displayLayer,
  portfolioMode,
  currentUser,
  hasBet,
  defaultPhase,
  expandedGroups = emptyArray,
  visibleColumnsIds,
  columnsState,
  viewType,
  hasOtherStoreUpdates,
  shouldGridBeUnmounted,
  hideRowsWithSameData,

  saveGridConfig,
  getSystemFieldName,
  createUnsavedProject,
  openProjectInfo,
  openOkrInfo,
  setSelectedItems,
  updateProjectById,
  loadParentProjects,
  saveColumnsState,
}) => {
  const projectsData = useProjectsWithRoadmapVersions(visibleColumnsIds, hideRowsWithSameData);

  const {
    processedData,

    getGridIsReady,
    getGridApi,

    handleGridReady,
    handleRowGroupOpened,
    handleSelectionChanged,
    handleDisplayedColumnsChanged,
    handleOnColumnResized,
    checkIsGroupOpenByDefault,
    addNewInlineProject,
    openRowInfo,
    updateProjectByIdOnCellValueChange,
    loadParentProjectsOptions,
    handleMetadataCellDoubleClick,
  } = useIdeasGrid(projectsData, {
    parentHandleGridReady,
    displayLayer,
    portfolioMode,
    currentUser,
    hasBet,
    defaultPhase,
    expandedGroups,
    columnsState,
    viewType,
    hasOtherStoreUpdates,
    shouldGridBeUnmounted,

    saveGridConfig,
    getSystemFieldName,
    createUnsavedProject,
    openProjectInfo,
    openOkrInfo,
    setSelectedItems,
    updateProjectById,
    loadParentProjects,
    saveColumnsState,
  });


  return {
    processedData,

    getGridIsReady,
    getGridApi,

    handleGridReady,
    handleRowGroupOpened,
    handleSelectionChanged,
    handleDisplayedColumnsChanged,
    handleOnColumnResized,
    checkIsGroupOpenByDefault,
    addNewInlineProject,
    openRowInfo,
    updateProjectByIdOnCellValueChange,
    loadParentProjectsOptions,
    handleMetadataCellDoubleClick,
  };
};

export default useCompareRoadmapVersionsGrid;
