import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { zendeskLoaded } from 'store/app';
import inIframe from 'utils/inIframe';

const Zendesk = ({ zendeskLoaded }) => {
  if (!window.Cypress && !inIframe()) {
    const s = document.createElement('script');

    s.id = 'ze-snippet';
    s.type = 'text/javascript';
    s.src = 'https://static.zdassets.com/ekr/snippet.js?key=81d2c32e-6f06-467e-9be5-73529bb4fcc3';
    s.onload = zendeskLoaded;
    document.body.appendChild(s);
  }

  return (
    <Fragment>
      <span data-cy="zendesk-plugin" />
    </Fragment>
  );
};

export default connect(null, {
  zendeskLoaded,
})(Zendesk);
