import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { filter, pipe, gt, prop, __, equals, defaultTo, map, indexOf, not } from 'ramda';

import { getRoadmaps, getCorpRoadmaps, getProducts, getProductsLevelTwo } from 'store/roadmaps/selectors';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

const PARENT_ID = 'parent_id';
const TITLE = 'title';
const ID = 'id';
const ROADMAP_ID = 'roadmap_id';

const defaultToEmptyArray = defaultTo([]);
const titleEquals = selectedTitle => pipe(prop(TITLE), equals(selectedTitle));
const filterByTitle = selectedTitle => filter(titleEquals(selectedTitle));
const filterBySelectedTitleIfExists = selectedTitle =>
  pipe(defaultToEmptyArray, selectedTitle ? filterByTitle(selectedTitle) : v => v);
const getIds = f => map(prop(ID), f);
const includedIn = array => pipe(indexOf(__, array), gt(__, -1));

const filterByParent = (parentIdAttribute, parentIds) => filter(pipe(prop(parentIdAttribute), includedIn(parentIds)));

export default ({ selectedCorpRoadmapTitle, selectedRoadmapTitle, selectedSubRoadmapTitle, showArchived = false }) => {
  const { canView } = usePermissions();

  const shouldUseCorpRoadmap = canView(PERMISSION_RESOURCES.roadmapCorp);
  const shouldNotUseCorpRoadmap = not(shouldUseCorpRoadmap);

  const roadmapsCorp = useSelector(state => getCorpRoadmaps(state, showArchived));

  const storeRoadmaps = useSelector(state => getRoadmaps(state, showArchived));
  const storeLevelOneProducts = useSelector(state => getProducts(state, showArchived));
  const storeLevelTwoProducts = useSelector(state => getProductsLevelTwo(state, showArchived));

  const hasProducts = useSelector(state => state.organization.organization.has_products);
  const hasProducts2 = useSelector(state => state.organization.organization.has_products_2);

  const roadmaps = useMemo(() => {
    if (shouldNotUseCorpRoadmap) {
      return storeRoadmaps;
    }

    const relevantCorpRoadmaps = filterBySelectedTitleIfExists(selectedCorpRoadmapTitle)(roadmapsCorp);

    return filterByParent(PARENT_ID, getIds(relevantCorpRoadmaps))(storeRoadmaps);
  }, [selectedCorpRoadmapTitle, shouldNotUseCorpRoadmap, storeRoadmaps, roadmapsCorp]);

  // if a roadmap is selected, filter the products to just that roadmap
  const products = useMemo(() => {
    const relevantRoadmaps = filterBySelectedTitleIfExists(selectedRoadmapTitle)(roadmaps);

    return filterByParent(ROADMAP_ID, getIds(relevantRoadmaps))(storeLevelOneProducts);
  }, [selectedRoadmapTitle, roadmaps, storeLevelOneProducts]);

  const products2 = useMemo(() => {
    const relevantLevelOneProducts = filterBySelectedTitleIfExists(selectedSubRoadmapTitle)(products);

    return filterByParent(PARENT_ID, getIds(relevantLevelOneProducts))(storeLevelTwoProducts);
  }, [selectedSubRoadmapTitle, storeLevelOneProducts, storeLevelTwoProducts]);

  return { roadmapsCorp, roadmaps, hasProducts, hasProducts2, products, products2, shouldUseCorpRoadmap };
};
