import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import FluidContainer from 'design-system/atoms/FluidContainer/index';
import FluidDateField from 'design-system/organisms/FluidDateField/index';
import FluidMultiSelectField from 'design-system/organisms/FluidMultiSelectField/index';
import FluidTextField from 'design-system/organisms/FluidTextField/index';
import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';
import UploadedFileActions from 'design-system/organisms/UploadedFileActions/index';
import FluidNumberField from 'design-system/organisms/FluidNumberField/index';

import { ExtraButtonsRow } from 'design-system/molecules/FluidField';
import FluidMetadataAutocompleteField from 'containers/FluidMetadataAutocompleteField';
import LoadableUsersMultiSelect from 'containers/LoadableMultiSelect/LoadableUsersMultiSelect';
import LoadableUsersAutocomplete from 'containers/LoadableUsersAutocomplete';
import customFieldGenerator from 'utils/customFields/customFieldGenerator';
import { getUserName } from 'utils';
import { READ_ONLY_USER } from '@dragonboat/permissions';
import useSubscribeNewData from 'hooks/useSubscribeNewData';
import { topics } from 'constants/topics';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';
import { LIFECYCLES, PERSONAS, TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';
import { REQUESTS_PERSONAS_PATH, REQUESTS_LIFECYCLES_PATH } from 'constants/filters/pages';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import FluidTreeDropdownFieldWithShowAll from 'design-system/organisms/FluidTreeDropdownFieldWithShowAll';
import useUploadAttachmentImage from 'hooks/files/useUploadAttachmentImage';
import useRequestsDetailFieldsLayout from 'features/RequestsDetailFieldsLayout/hooks/useRequestsDetailFieldsLayout';
import { REQUEST_FORM_FIELDS } from 'features/RequestsDetailFieldsLayout/constants';
import useRequestsDetailRequiredFields from 'features/RequestsDetailFieldsLayout/hooks/useRequestsDetailRequiredFields';
import RequiredFieldsWarningDialog from 'features/FieldsLayout/components/RequiredFieldsWarningDialog';
import { FIELDS_LAYOUT_TYPES } from 'features/FieldsLayout/constants';
// eslint-disable-next-line max-len
import FluidAppliesToMultiSelectAutocompleteField from 'shared/components/inputs/Autocomplete/formFields/FluidAppliesToMultiSelectAutocompleteField';

import Ideas from './fieldsComponents/Ideas';
import Comments from './fieldsComponents/Comments';
import FluidRequestsIntegrationField from './Integrations/FluidRequestsIntegrationField';
import EmailRequestWatchers from '../EmailRequestWatchers';
// PROD-6004 - hide merge requests until it can be fully implemented:
// import FluidCustomerRequestParentField from './FluidCustomerRequestParentField';

const PENDO_HELP_STATUS_ICON = 'custom-request-automatic-status';
const AUTOMATIC_STATUS_UPDATE_ZENDESK_URL =
  'https://dragonboat.zendesk.com/hc/en-us/articles/6504323566359-Automate-Request-Status';
const AUTOMATIC_STATUS_UPDATE_HELP_TOOLTIP = 'Status updated by automation rule';

const exist = Boolean;

const urlByField = {
  [PERSONAS]: `/${REQUESTS_PERSONAS_PATH}`,
  [LIFECYCLES]: `/${REQUESTS_LIFECYCLES_PATH}`,
};

const Form = styled.form`
  &&&& {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  ${ExtraButtonsRow} {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
`;

const buildField = (field, format, name) => {
  if (field instanceof Function) {
    return field({ format, name });
  }
  return field;
};

export default props => {
  const {
    checkFieldDisplay,
    users,
    canAutomaticUpdate,
    hasAutomaticUpdateConfigured,
    currentUser,
    allCustomers,
    customersOptions,
    allTags,
    tagsOptions,
    roadmapsOptions,
    allPersonas,
    personasOptions,
    lifecyclesOptions,
    allLifecycles,
    navigateToUrlAndCloseDrawer,
    hasExternalKeys,
    oneStepAvailableIntegrations,
    isDodActive,
  } = props;

  const {
    // children = [],
    created_at: createdAt,
    created_by: createdBy,
    customers,
    desired_time: desiredTime,
    details,
    links,
    owner,
    // parent,
    // parent_id: parentId,
    priority,
    status,
    tags,
    updated_at: updatedAt,
    watchers,
    integrations,
    preCalculations,
    personas: requestPersonas,
    lifecycles: requestLifecycles,
    external_key: externalKey,
    external_portfolio_key: externalPortfolioKey,
  } = props.data;
  const { canView } = usePermissions();
  const hasPersonas = canView(PERMISSION_FEATURES.personas);
  const hasLifecycles = canView(PERMISSION_FEATURES.lifecycles);

  useSubscribeNewData([topics.CUSTOMER_REQUESTS]);

  const editorCanCreateCustomer = props.allowNewMetadata('customers');
  const canCreateCustomer = [READ_ONLY_USER].includes(currentUser.role_id) || editorCanCreateCustomer;

  const requestWatchers = useMemo(() => (watchers || []).map(u => ({ ...u, name: getUserName(u) })), [watchers]);

  const { fieldValidation, onSaveWithValidation } = useRequestsDetailRequiredFields({
    formData: props.data,
    onSave: props.updateValue,
  });

  const _makeOnBlurField = fieldName => {
    return value => {
      return onSaveWithValidation(fieldName, value);
    };
  };

  const roadmapTitle = props.data.roadmap?.title;
  const product1Title = props.data.product1?.title;
  const product2Title = props.data.product2?.title;

  const _saveCustomField = (updatedValues = {}) => {
    if (!props.updateValue) return;

    Object.keys(updatedValues).forEach(key => {
      return onSaveWithValidation(key, updatedValues[key]);
    });
  };

  const _checkFieldDisabled = () => {
    return !props.canComplexEditRequest;
  };

  const _getStatusLabel = () => {
    if (!exist(status)) return;

    const myStatus = props.statuses.find(s => s.value === status);

    return exist(myStatus) ? myStatus.label : null;
  };

  const shouldShowPreCalculations = useFeatureFlags([FeatureFlags.HAS_OUTCOME_DASHBOARD]);

  const { imageUploadHandler } = useUploadAttachmentImage();

  const generateCustomFieldsFor = customFieldGenerator(
    props.data,
    props.customFields,
    _checkFieldDisabled,
    _saveCustomField,
    null,
    null,
    fieldValidation,
  );

  const hasNewMetadataAutocomplete = canView(PERMISSION_FEATURES.newRoadmapMetadataAutocomplete);
  const showMetadataDropdown = isDodActive || !hasNewMetadataAutocomplete;

  const commonFields = {
    [REQUEST_FORM_FIELDS.details]: ({ name, format }) => (
      <FluidRemirrorWYSIWYGEditor
        key="details"
        value={details}
        content={details}
        placeholder="Write something here..."
        label="Details"
        disableEdit={!props.canSimpleEditRequest}
        onChange={_makeOnBlurField('details')}
        onFocus={() => props.onDetailsFocus && props.onDetailsFocus()}
        onBlur={() => props.onDetailsBlur && props.onDetailsBlur()}
        imageUploadHandler={imageUploadHandler}
        format={format}
        name={name}
        validation={fieldValidation}
      />
    ),
    [REQUEST_FORM_FIELDS.files]: ({ name, format }) =>
      checkFieldDisplay('files') &&
      props.files &&
      !!props.files.length &&
      props.files.map(f => (
        <UploadedFileActions
          format={format}
          name={name}
          title={f.name}
          getLink={() => props.getFileLink(f.id)}
          handleDeleteLink={() => props.handleDeleteLink({ id: f.id, name: f.name })}
        />
      )),
    [REQUEST_FORM_FIELDS.links]: ({ name, format }) =>
      checkFieldDisplay('links') && (
        <FluidRemirrorWYSIWYGEditor
          key="links"
          value={links}
          content={links}
          placeholder="Add some links here..."
          label="Links"
          onChange={_makeOnBlurField('links')}
          disableToolbar
          disableEdit={!props.canRoadmapEditRequest}
          smallInput
          imageUploadHandler={imageUploadHandler}
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ),
    [REQUEST_FORM_FIELDS.integrations]: ({ name, format }) =>
      checkFieldDisplay('integrations') &&
      !!integrations?.length && (
        <FluidRequestsIntegrationField
          key="integrations"
          value={integrations}
          label="Integration"
          orgIntegrations={props.orgIntegrations}
          oneStepAvailableIntegrations={oneStepAvailableIntegrations}
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ),
    [REQUEST_FORM_FIELDS.ideas]: ({ name, format }) =>
      !props.isCreating &&
      checkFieldDisplay('ideas') && (
        <Ideas
          data={props.data}
          canAutomaticUpdate={canAutomaticUpdate}
          hasAutomaticUpdateConfigured={hasAutomaticUpdateConfigured}
          format={format}
          name={name}
          disableEdit={!props.canRoadmapEditRequest}
        />
      ),
    [REQUEST_FORM_FIELDS.watchers]: ({ name, format }) =>
      !props.isCreating &&
      checkFieldDisplay('watchers') && (
        <FluidMultiSelectField
          key="watchers"
          value={requestWatchers}
          label="Watchers"
          optionsMapper={{ label: 'name', value: 'id' }}
          onChange={_makeOnBlurField('watchers')}
          inputComponent={LoadableUsersMultiSelect}
          mapValueToSave={v => v.id}
          format={format}
          name={name}
          validation={fieldValidation}
          disableEdit={!props.canRoadmapEditRequest}
        />
      ),
    [REQUEST_FORM_FIELDS.comments]: ({ name, format }) =>
      !props.isCreating &&
      checkFieldDisplay('comments') && <Comments readOnly={props.isMergedRequest} currentUser={currentUser} />,
    [REQUEST_FORM_FIELDS.owner]: ({ name, format }) =>
      checkFieldDisplay('owner') && (
        <FluidMetadataAutocompleteField
          key="owner"
          value={owner && owner.name}
          label="Owner"
          metadata="users"
          onChange={_makeOnBlurField('owner_id')}
          disableEdit={!props.canRoadmapEditRequest}
          autocompleteComponent={LoadableUsersAutocomplete}
          autocompleteProps={{
            suggestions: users,
          }}
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ),
    [REQUEST_FORM_FIELDS.priority]: ({ name, format }) =>
      checkFieldDisplay('priority') && (
        <FluidMetadataAutocompleteField
          key="priority"
          value={priority && props.priorities[priority] && props.priorities[priority].label}
          label={props.getSystemFieldName('priority')}
          metadata="priorities"
          onChange={_makeOnBlurField('priority')}
          options={props.priorities}
          disableEdit={!props.canRoadmapEditRequest}
          addNew={false}
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ),
    [REQUEST_FORM_FIELDS.status]: ({ name, format }) =>
      checkFieldDisplay('status') && (
        <FluidMetadataAutocompleteField
          key="status"
          value={_getStatusLabel()}
          label="Status"
          metadata="statuses"
          onChange={_makeOnBlurField('status')}
          options={props.statuses}
          disableEdit={!props.canComplexEditRequest || canAutomaticUpdate}
          addNew={false}
          pendoId={canAutomaticUpdate && PENDO_HELP_STATUS_ICON}
          helpLink={canAutomaticUpdate && AUTOMATIC_STATUS_UPDATE_ZENDESK_URL}
          helpTitle={canAutomaticUpdate && AUTOMATIC_STATUS_UPDATE_HELP_TOOLTIP}
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ),
    [REQUEST_FORM_FIELDS.personas]: ({ name, format }) =>
      hasPersonas &&
      checkFieldDisplay(PERSONAS) &&
      (showMetadataDropdown ? (
        <FluidMultiSelectField
          key={PERSONAS}
          value={requestPersonas}
          label={props.getSystemFieldName('persona', true)}
          options={personasOptions}
          allValues={allPersonas}
          optionsMapper={{ label: 'title', value: 'id' }}
          onChange={_makeOnBlurField(REQUEST_FORM_FIELDS.personas)}
          disableEdit={!props.canRoadmapEditRequest}
          onLabelClick={() => navigateToUrlAndCloseDrawer(urlByField[PERSONAS])}
          hideCreateOption
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ) : (
        <FluidAppliesToMultiSelectAutocompleteField
          name={name}
          validation={fieldValidation}
          key={PERSONAS}
          roadmapsKey={PERSONAS}
          label={props.getSystemFieldName('persona', true)}
          onLabelClick={() => navigateToUrlAndCloseDrawer(urlByField[PERSONAS])}
          options={personasOptions}
          selectedOptions={requestPersonas?.map(persona => allPersonas?.find(p => p.id === persona?.id))}
          onChange={value => {
            const ids = value.map(opt => opt.id);

            _makeOnBlurField(REQUEST_FORM_FIELDS.personas)(ids);
          }}
          disableEdit={!props.canRoadmapEditRequest || !props.data?.id}
          format={format}
          selectedEntity={props.data}
          getSystemFieldName={props.getSystemFieldName}
          getOptionLabel={option => option.title}
        />
      )),
    [REQUEST_FORM_FIELDS.lifecycles]: ({ name, format }) =>
      hasLifecycles &&
      checkFieldDisplay(LIFECYCLES) &&
      (showMetadataDropdown ? (
        <FluidMultiSelectField
          key={LIFECYCLES}
          value={requestLifecycles}
          label={props.getSystemFieldName('lifecycle', true)}
          options={lifecyclesOptions}
          allValues={allLifecycles}
          optionsMapper={{ label: 'title', value: 'id' }}
          onChange={_makeOnBlurField(REQUEST_FORM_FIELDS.lifecycles)}
          disableEdit={!props.canRoadmapEditRequest}
          onLabelClick={() => navigateToUrlAndCloseDrawer(urlByField[LIFECYCLES])}
          hideCreateOption
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ) : (
        <FluidAppliesToMultiSelectAutocompleteField
          name={name}
          validation={fieldValidation}
          key={LIFECYCLES}
          roadmapsKey={LIFECYCLES}
          label={props.getSystemFieldName('lifecycle', true)}
          onLabelClick={() => navigateToUrlAndCloseDrawer(urlByField[LIFECYCLES])}
          options={lifecyclesOptions}
          selectedOptions={requestLifecycles?.map(lifeCycle => allLifecycles?.find(lc => lc.id === lifeCycle?.id))}
          onChange={value => {
            const ids = value.map(opt => opt.id);

            _makeOnBlurField(REQUEST_FORM_FIELDS.lifecycles)(ids);
          }}
          disableEdit={!props.canRoadmapEditRequest || !props.data?.id}
          format={format}
          selectedEntity={props.data}
          getSystemFieldName={props.getSystemFieldName}
          getOptionLabel={option => option.title}
        />
      )),
    [REQUEST_FORM_FIELDS.customersCount]: ({ name, format }) =>
      checkFieldDisplay('cr_customers_count') && (
        <FluidTextField
          key="customersCount"
          value={preCalculations && preCalculations.customers_count}
          label="# of Customers"
          disableEdit
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ),
    [REQUEST_FORM_FIELDS.customers]: ({ name, format }) =>
      checkFieldDisplay('customers') &&
      (showMetadataDropdown ? (
        <FluidMultiSelectField
          name={name}
          validation={fieldValidation}
          key="customers"
          value={customers}
          label={props.getSystemFieldName('customer')}
          inputProps={{
            placeholder: '',
          }}
          allValues={allCustomers}
          options={customersOptions}
          optionsMapper={{ label: 'name', value: 'id', color: 'color' }}
          onChange={_makeOnBlurField(REQUEST_FORM_FIELDS.customers)}
          disableEdit={!props.canRoadmapEditRequest}
          onCreateOption={props.createMultiselectOption('customers')}
          hideCreateOption={!canCreateCustomer}
          format={format}
        />
      ) : (
        <FluidAppliesToMultiSelectAutocompleteField
          name={name}
          validation={fieldValidation}
          key="customers"
          roadmapsKey="customer"
          label={props.getSystemFieldName('customer')}
          options={customersOptions}
          selectedOptions={customers}
          onChange={value => {
            const ids = value.map(opt => opt.id);

            _makeOnBlurField(REQUEST_FORM_FIELDS.customers)(ids);
          }}
          disableEdit={!props.canRoadmapEditRequest || !props.data?.id}
          format={format}
          canAddNew={canCreateCustomer}
          onCreateNew={props.createMultiselectOption('customers')}
          selectedEntity={props.data}
          getSystemFieldName={props.getSystemFieldName}
          getOptionLabel={option => option.name}
        />
      )),
    [REQUEST_FORM_FIELDS.totalNetRevenue]: ({ name, format }) =>
      checkFieldDisplay('cr_total_revenue') &&
      checkFieldDisplay('cr_total_churned_revenue') &&
      shouldShowPreCalculations && (
        <FluidNumberField
          key="totalNetRevenue"
          value={preCalculations?.totalNetRevenue}
          label="Total value"
          pendoId="total_value"
          disableEdit
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ),
    [REQUEST_FORM_FIELDS.totalRevenue]: ({ name, format }) =>
      checkFieldDisplay('cr_total_revenue') &&
      shouldShowPreCalculations && (
        <FluidNumberField
          key="totalRevenue"
          value={preCalculations?.totalRevenue}
          label="Active value"
          disableEdit
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ),
    [REQUEST_FORM_FIELDS.totalChurnedRevenue]: ({ name, format }) =>
      checkFieldDisplay('cr_total_churned_revenue') &&
      shouldShowPreCalculations && (
        <FluidNumberField
          key="totalChurnedRevenue"
          value={preCalculations?.totalChurnedRevenue}
          label="Inactive value"
          disableEdit
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ),
    [REQUEST_FORM_FIELDS.tags]: ({ name, format }) =>
      checkFieldDisplay('tags') &&
      (showMetadataDropdown ? (
        <FluidMultiSelectField
          key="tags"
          value={tags}
          label={props.getSystemFieldName('tag')}
          inputProps={{
            placeholder: '',
          }}
          options={tagsOptions}
          allValues={allTags}
          optionsMapper={{ label: 'title', value: 'id', color: 'color' }}
          onChange={_makeOnBlurField(REQUEST_FORM_FIELDS.tags)}
          disableEdit={!props.canRoadmapEditRequest}
          onCreateOption={props.createMultiselectOption('tags')}
          hideCreateOption={!props.allowNewMetadata('tags')}
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ) : (
        <FluidAppliesToMultiSelectAutocompleteField
          name={name}
          validation={fieldValidation}
          key="tags"
          roadmapsKey="tag"
          label={props.getSystemFieldName('tag')}
          options={tagsOptions}
          selectedOptions={tags}
          onChange={value => {
            const ids = value.map(opt => opt.id);

            _makeOnBlurField(REQUEST_FORM_FIELDS.tags)(ids);
          }}
          disableEdit={!props.canRoadmapEditRequest || !props.data?.id}
          format={format}
          canAddNew={props.allowNewMetadata('tags')}
          onCreateNew={props.createMultiselectOption('tags')}
          selectedEntity={props.data}
          getSystemFieldName={props.getSystemFieldName}
          getOptionLabel={option => option.name}
        />
      )),
    [REQUEST_FORM_FIELDS.desiredTime]: ({ name, format }) =>
      checkFieldDisplay('desired_time') && (
        <FluidDateField
          key="desired_time"
          value={desiredTime}
          label="Desired time"
          disableEdit={!props.canRoadmapEditRequest}
          onChange={_makeOnBlurField('desired_time')}
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ),
    [REQUEST_FORM_FIELDS.roadmap]: ({ name, format }) =>
      checkFieldDisplay('roadmap') && (
        <FluidTreeDropdownFieldWithShowAll
          name={name}
          key="roadmaps"
          value={[roadmapTitle, product1Title, product2Title]}
          label={props.getSystemFieldName('roadmap')}
          metadata="roadmaps"
          handleChange={_makeOnBlurField(REQUEST_FORM_FIELDS.roadmap)}
          options={roadmapsOptions || []}
          disableEdit={_checkFieldDisabled('roadmapsTitle')}
          roadmapsKey="roadmaps"
          format={format}
          validation={fieldValidation}
          placeholder={TYPE_TO_SEARCH_PLACEHOLDER}
        />
      ),
    [REQUEST_FORM_FIELDS.externalKey]: ({ name, format }) =>
      checkFieldDisplay('external_key') &&
      hasExternalKeys && (
        <FluidTextField
          key="externalKey"
          value={externalKey}
          disableEdit
          label="External Key"
          testId="field-request-externalkey"
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ),
    [REQUEST_FORM_FIELDS.externalPortfolioKey]: ({ name, format }) =>
      checkFieldDisplay('external_portfolio_key') &&
      hasExternalKeys && (
        <FluidTextField
          key="externalPortfolioKey"
          value={externalPortfolioKey}
          disableEdit
          label="External Portfolio Key"
          testId="field-request-externalportfoliokey"
          format={format}
          name={name}
          validation={fieldValidation}
        />
      ),
    [REQUEST_FORM_FIELDS.updatedAt]: ({ name, format }) =>
      checkFieldDisplay('updated_at') && (
        <FluidDateField
          key="updatedAt"
          value={updatedAt}
          label="Updated at"
          disableEdit
          format={format}
          name={name}
          readingPlaceholder={null}
        />
      ),
    [REQUEST_FORM_FIELDS.createdAt]: ({ name, format }) =>
      checkFieldDisplay('created_at') && (
        <FluidDateField
          key="createdAt"
          value={createdAt}
          label="Created at"
          disableEdit
          format={format}
          name={name}
          readingPlaceholder={null}
        />
      ),
    [REQUEST_FORM_FIELDS.createdBy]: ({ name, format }) =>
      checkFieldDisplay('created_by') && (
        <FluidTextField
          key="createdBy"
          value={createdBy && createdBy.name}
          label="Created by"
          disableEdit
          format={format}
          name={name}
        />
      ),
    ...generateCustomFieldsFor(),
  };

  const { leftFields, rightFields, fieldsLayoutIsLoading, fetchFieldsLayout } = useRequestsDetailFieldsLayout();

  useEffect(() => {
    const requestDetailsFieldsLayoutEnabled = canView(PERMISSION_FEATURES.requestDetailsFieldsLayout);

    if (!requestDetailsFieldsLayoutEnabled) {
      return;
    }

    fetchFieldsLayout();
  }, []);

  const staticFields = [{ id: REQUEST_FORM_FIELDS.integrations }];

  const leftConfiguration = [
    ...[...staticFields, ...leftFields].map(field => buildField(commonFields[field.id], 'column', field.id)),
  ];
  const rightConfiguration = [...rightFields?.map(field => buildField(commonFields[field.id], 'row', field.id))];

  if (fieldsLayoutIsLoading) {
    return null;
  }

  return (
    <Form autoComplete="off">
      <Grid container xs={12} justify="space-between">
        <FluidContainer size={7}>{leftConfiguration}</FluidContainer>
        <RightSection size={4} leftBorder>
          {rightConfiguration}
        </RightSection>
      </Grid>
      <EmailRequestWatchers isOpen={props.isEmailNotificationDialogOpen} onClose={props.onCloseEmailNotificationDialog} />
      <RequiredFieldsWarningDialog type={FIELDS_LAYOUT_TYPES.requests} onCloseForm={props.onClose} />
    </Form>
  );
};

// todo: we should revisit how the request drawer is building both left and right sections
// The right side is way too narrow for most of the elements there and with the placeholders now being visible it gets cutoff
// The left border is an ::after rule that is absolute and if we make the sections to occupy 100% width of the container
// the border overlaps the left elements.
// we should probably build this using gap property of flexbox
const RightSection = styled(FluidContainer)`
  && {
    max-width: 38%;
    flex-basis: 38%;
  }
`;
