import React from 'react';

import FormLabel from 'design-system/atoms/FormLabel/index';

import Comments from 'components/Comments';

import { CommentsWrapper, HeaderComments } from './styles';
import useProjectDeliverableComments from './hooks/useProjectDeliverableComments';

export default ({ projectDeliverableId }) => {
  const {
    comments,
    hasMoreComments,

    addComment,
    fetchNextPage,
    deleteComment,
    editComment,
  } = useProjectDeliverableComments({ projectDeliverableId });

  return (
    <CommentsWrapper>
      <HeaderComments>
        <FormLabel>Comments</FormLabel>
      </HeaderComments>
      <Comments
        onSave={addComment}
        comments={comments}
        hasMoreComments={hasMoreComments}
        showMore={fetchNextPage}
        onDelete={deleteComment}
        onEdit={editComment}
      />
    </CommentsWrapper>
  );
};
