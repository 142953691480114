import React from 'react';
import styled from 'styled-components';

import DrawerView from 'design-system/templates/DrawerView/index';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';

import { AVAILABLE_VIEWS } from 'constants/customerRequests';
import useOpenRequestFromUrl from 'hooks/customerRequests/useOpenRequestFromUrl';

import PersonasGrid from 'features/PersonasGrid';
import LifecyclesGrid from 'features/LifecyclesGrid';

import List from './List';
import DetailedView from './DetailedView';
import CustomFields from './CustomFields';
import PortalSettings from './PortalSettings';
import ForwardEmails from './ForwardEmails';
import BulkActions from './BulkActions';
import { getIsPortalRoute } from './helpers';
import Insights from './Insights';
import Analyze from './Analyze';
import { PAGE_HEADER_HEIGHT } from 'constants/common';
import GridView from './GridView';

const MainView = styled(DrawerView)`
  &&&& {
    height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
  }
`;

const HEADER_HEIGHT = '68px';

export default ({
  bulkDeleteRequests,
  isBulkActionsVisible,
  isCreatingOrEditingCustomerRequest,
  isCustomFieldsVisible,
  isPortalSettingsVisible,
  isForwardEmailsVisible,
  loading,
  mergeRequests,
  selectedItems,
  selectedView,
  stopCreatingOrEditingCustomerRequest,
  toggleBulkUpdate,
  listView,
  detailView,
  insightsView,
  analyzeView,
  personasView,
  lifecyclesView,
}) => {
  useOpenRequestFromUrl();

  const isGridViewOn = selectedView === AVAILABLE_VIEWS.LIST;

  const _renderView = () => {
    if (getIsPortalRoute()) return <List isDrawerOpen={isCreatingOrEditingCustomerRequest} />;
    if (detailView) return <List isDrawerOpen={isCreatingOrEditingCustomerRequest} />;
    if (listView) return <GridView isBulkActionsVisible={isBulkActionsVisible} selectedItems={selectedItems} />;
    if (insightsView) return <Insights />;
    if (analyzeView) return <Analyze />;
    if (personasView) return <PersonasGrid />;
    if (lifecyclesView) return <LifecyclesGrid />;
  };

  const _getDrawerComponent = () => {
    if (isCustomFieldsVisible) return CustomFields;
    if (isPortalSettingsVisible) return PortalSettings;
    if (isForwardEmailsVisible) return ForwardEmails;
    return DetailedView;
  };

  const _getIsDrawerOpen = () => {
    return isCreatingOrEditingCustomerRequest || isCustomFieldsVisible || isForwardEmailsVisible || isPortalSettingsVisible;
  };

  return loading ? (
    <PageLoading />
  ) : (
    <MainView
      scrollable={!isGridViewOn}
      isDrawerOpen={_getIsDrawerOpen()}
      closeDrawer={stopCreatingOrEditingCustomerRequest}
      drawerWidth="1000px"
      drawerProps={{ headerHeight: HEADER_HEIGHT }}
      drawerComponent={_getDrawerComponent()}
    >
      {isGridViewOn && isBulkActionsVisible ? (
        <BulkActions
          bulkDeleteRequests={bulkDeleteRequests}
          mergeRequests={mergeRequests}
          selectedItems={selectedItems}
          toggleBulkUpdate={toggleBulkUpdate}
        />
      ) : null}
      {_renderView()}
    </MainView>
  );
};
