import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchOrgIntegrations, addOrgIntegration, removeOrgIntegration } from 'store/organization';
import { addUserIntegration, removeUserIntegration, fetchUserIntegrations } from 'store/login';
import { fetchUsers } from 'store/users';
import { testConnection } from 'store/integrations';
import { getAllowedOrgIntegrationsTypes, getOrganizationIntegrations, getOrganization } from 'store/organization/selectors';
import { getUserIntegrations, getAddUserIntegrationError, getCurrentUser } from 'store/login/selectors';
import getVisibleIntegrations from 'routes/Settings/Integration/getVisibleIntegrations';
import useSubscribeNewData from 'hooks/useSubscribeNewData';
import { getAvailableIntegrations } from 'features/OneStepIntegration/store';

export default Component => {
  return props => {
    const dispatch = useDispatch();
    const orgIntegrations = useSelector(state => getOrganizationIntegrations(state));
    const organization = useSelector(getOrganization);
    const user = useSelector(getCurrentUser);

    useSubscribeNewData(['orgIntegrations']);

    const allowedIntegrationTypes = useSelector(getAllowedOrgIntegrationsTypes);
    const userIntegrations = useSelector(getUserIntegrations);
    const addUserIntegrationError = useSelector(getAddUserIntegrationError);
    const oneStepAvailableIntegrations = useSelector(getAvailableIntegrations);

    const visibleIntegrations = getVisibleIntegrations(
      orgIntegrations,
      allowedIntegrationTypes,
      organization,
      user,
      oneStepAvailableIntegrations,
    );

    const handleTestConnection = (integrationType, orgIntegrationId) =>
      dispatch(testConnection(integrationType, orgIntegrationId));

    return (
      <Component
        {...props}
        visibleIntegrations={visibleIntegrations}
        orgIntegrations={orgIntegrations}
        userIntegrations={userIntegrations}
        fetchOrgIntegrations={() => dispatch(fetchOrgIntegrations())}
        fetchUserIntegrations={() => dispatch(fetchUserIntegrations())}
        removeOrgIntegration={(integrationType, orgIntegrationId) =>
          dispatch(removeOrgIntegration(integrationType, orgIntegrationId))
        }
        removeUserIntegration={(integrationType, orgIntegrationId) =>
          dispatch(removeUserIntegration(integrationType, orgIntegrationId))
        }
        addUserIntegration={(integrationType, orgIntegrationId, token) =>
          dispatch(addUserIntegration(integrationType, orgIntegrationId, token))
        }
        addOrgIntegration={(integrationType, opts) => dispatch(addOrgIntegration(integrationType, opts))}
        addUserIntegrationError={addUserIntegrationError}
        fetchUsers={() => dispatch(fetchUsers)}
        handleTestConnection={handleTestConnection}
      />
    );
  };
};
