import { SUPPORTED_FIELDS } from 'utils/roadmapVersions/supportedFieldsUtils';

/**
 * @function makeCellClassForCreatingOrViewingScenario
 *
 * make function to get cell style for cell when the grid is
 * creating or viewing some scenario
 *
 * Is allowed tobe used on headerClass and cellClass properties
 *
 * @param  {String} enhancedValue
 * @return {String}
 */
const makeCellClassForCreatingOrViewingScenario = (enhancedValue = '') => params => {
  const { colDef, context } = params;
  const { isCreatingOrViewingScenario } = context;

  if (!isCreatingOrViewingScenario) {
    return enhancedValue;
  }

  if (SUPPORTED_FIELDS.find(f => f.key === colDef.field)) {
    return `${enhancedValue} draft-mode-editable`;
  }

  return enhancedValue;
};

export default makeCellClassForCreatingOrViewingScenario;
