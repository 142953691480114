import { stringComparator } from 'utils/agGridComparators';
import { HEALTH_LABEL_OPTIONS, HEALTH_LABEL_OPTIONS_LABELS } from 'constants/projects';
import { DropdownCellEditor } from '../cellEditors';
import { HealthCellRenderer } from '../cellRenderers';

const healthColumnDef = {
  field: 'status_color',
  headerName: 'Health',
  openByDefault: true,
  cellRenderer: HealthCellRenderer,
  cellEditorPopup: true,
  cellEditor: DropdownCellEditor,
  cellEditorParams: {
    options: HEALTH_LABEL_OPTIONS,
    sort: false,
  },
  optionsLabel: HEALTH_LABEL_OPTIONS_LABELS,
  comparator: stringComparator,
  width: 100,
  minWidth: 50,
};

export default healthColumnDef;
