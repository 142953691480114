import { useDispatch, useSelector } from 'react-redux';
import {
  addNewPersona,
  changeRowHeight as changeRowHeightAction,
  changeHideArchived as changeHideArchivedAction,
} from '../store/actions';
import { selectHideArchived } from '../store/selectors';

const useGridToolbarActions = () => {
  const dispatch = useDispatch();
  const hideArchived = useSelector(selectHideArchived);
  const createNewPersona = () => dispatch(addNewPersona());

  const changeRowHeight = newHeight => dispatch(changeRowHeightAction(newHeight));
  const changeHideArchived = hideArchived => dispatch(changeHideArchivedAction(hideArchived));

  return {
    createNewPersona,
    changeRowHeight,
    changeHideArchived,
    hideArchived,
  };
};

export default useGridToolbarActions;
