import { spacing } from 'design-system/theme';

export const CUSTOM_FIELD_TYPE = 'custom_field';

export const CONTAINER_MAX_HEIGHT = 400;

export const CREATE_POPOVER_DEFAULT_WIDTH = 250;
export const CREATE_POPOVER_PADDING_OFFSET = 16;

export const FIELDS_TO_IGNORE_ADD_COLUMN_ROW = [
  'product1',
  'product2',
  'keyResult1',
  'keyResult2',
  'dates',
  'priority',
  'planningStage',
  'owner',
  'timeframe2',
  'status_color',
];

export const COL_CREATE_POPOVER_PROPS = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  PaperProps: {
    style: { marginTop: `${spacing()}px` },
  },
};

export const ROW_CREATE_POPOVER_PROPS = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
};

export const cellType = {
  ROW: 'row',
  COL: 'col',
};
