import { propEq } from 'ramda';

const GRID_ROW_ROOT_LEVEL = 0;
const TIMEFRAME_0_LEVEL = 'CORP';
const TIMEFRAME_LEVEL = '0';

const getDataPath = data => data.path || [data.title];

// const isParentItem = data => data?.level === GRID_ROW_ROOT_LEVEL && isNil(data?.parent_id);
const isParentItem = (data, hasCorpLevel, hasMultiLevelPortfolioMetadata) =>
  [...(hasCorpLevel ? [TIMEFRAME_0_LEVEL] : []), ...(hasMultiLevelPortfolioMetadata ? [TIMEFRAME_LEVEL] : [])].includes(
    data?.level,
  );

const isActive = data => data.status === 'Active';

const makeCheckAddVisibility =
  (hasCorpLevel, hasMultiLevelPortfolioMetadata, organization, orgAccessControlData) =>
  ({ data }) => {
    if (hasCorpLevel) {
      const childOrgAccessEntry = orgAccessControlData.find(ac => ac.child_organization_id === organization.id);

      const isCorpTimeframe = childOrgAccessEntry && data.id === childOrgAccessEntry.portfolio_placeholder_timeframe_id;

      if (!isCorpTimeframe && data.organization_id !== organization.id) {
        return false;
      }
    }
    

    const isActive = propEq('status', 'Active')(data);

    return isActive && isParentItem(data, hasCorpLevel, hasMultiLevelPortfolioMetadata);
  };

const NEW_ROW_ID = 'new';

const getRowId = ({ data }) => {
  if (!data.uniqueId) {
    return NEW_ROW_ID;
  }

  return data.uniqueId;
};

export { GRID_ROW_ROOT_LEVEL, getDataPath, isParentItem, makeCheckAddVisibility, isActive, getRowId };
