import { useMemo, useRef, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

import useGridRowsFormat from 'design-system/molecules/AgGridReact-New/hooks/useGridRowsFormat';

import {
  createCycle,
  addCycleWithoutSave,
  removeUnsavedCycleDeliverable,
  addDeliverableWithoutSave,
  createDeliverable,
  deleteCycleDeliverable,
  updateCycle,
  updateDeliverable,
  switchCycleDeliverableRowOrder,
} from 'store/cycleDeliverables';
import useOrganizationCycleDeliverables from 'hooks/useOrganizationCycleDeliverables';
import { CYCLE_KEY, DELIVERABLE_KEY } from 'constants/pdlc';
import { CYCLE_DELIVERABLE_LEVEL } from 'constants/common';

import { PDLC } from 'store/grids';

const getCycleDeliverablesFiltered = (cycleDeliverables, idToFilter) =>
  cycleDeliverables.filter(cd => cd.id === idToFilter || cd.parent_id === idToFilter);

const usePDLC = ({ filterByCycleDeliverableId }) => {
  const dispatch = useDispatch();
  const { allCycleDeliverables } = useOrganizationCycleDeliverables();

  const filteredCycleDeliverables = useMemo(
    () =>
      filterByCycleDeliverableId
        ? getCycleDeliverablesFiltered(allCycleDeliverables, filterByCycleDeliverableId)
        : allCycleDeliverables,
    [allCycleDeliverables, filterByCycleDeliverableId],
  );

  const { gridRows: cycleDeliverables } = useGridRowsFormat(filteredCycleDeliverables, {
    settingKey: CYCLE_KEY,
    gridStorePath: PDLC,
    multiLevelEnabled: true,
  });

  const cyclesDeliverablesRef = useRef(null);

  cyclesDeliverablesRef.current = cycleDeliverables;

  const childrenSettingKeys = useMemo(() => [CYCLE_KEY, DELIVERABLE_KEY], []);

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          updateCycle,
          updateDeliverable,
          createCycle,
          addCycleWithoutSave,
          addDeliverableWithoutSave,
          removeUnsavedCycleDeliverable,
          createDeliverable,
          deleteCycleDeliverable,
          switchCycleDeliverableRowOrder,
        },
        dispatch,
      ),
    [dispatch],
  );

  const {
    addCycleWithoutSave: addCycleWithoutSaveAction,
    createCycle: createCycleAction,
    addDeliverableWithoutSave: addDeliverableWithoutSaveAction,
    createDeliverable: createDeliverableAction,
    updateCycle: updateCycleAction,
    updateDeliverable: updateDeliverableAction,
  } = boundActionCreators;

  const createCycleDeliverable = useCallback(
    data => {
      if (data.level === CYCLE_DELIVERABLE_LEVEL.deliverable) {
        return createDeliverableAction(data);
      }
      return createCycleAction(data);
    },
    [createCycleAction],
  );

  const addCycleDeliverableWithoutSave = useCallback(
    parent => {
      if (parent && parent?.level === CYCLE_DELIVERABLE_LEVEL.cycle) {
        return addDeliverableWithoutSaveAction({
          parent_id: parent.id,
        });
      }

      return addCycleWithoutSaveAction();
    },
    [addCycleWithoutSaveAction, addDeliverableWithoutSaveAction],
  );

  const updateCycleDeliverableById = useCallback(
    (id, updatedData, _, params) => {
      const { data } = params;

      if (data.level === CYCLE_DELIVERABLE_LEVEL.deliverable) {
        return updateDeliverableAction(id, updatedData);
      }

      return updateCycleAction(id, updatedData);
    },
    [updateCycleAction],
  );

  return {
    cycleDeliverables,
    childrenSettingKeys,
    ...boundActionCreators,
    createCycleDeliverable,
    addCycleDeliverableWithoutSave,
    updateCycleDeliverable: updateCycleDeliverableById,
  };
};

export default usePDLC;
