import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

import { isUninitialized, isLoading } from 'utils/store/thunk';

import { getGridCustomerRequests } from 'store/customerRequests/selectors';

import { GENERATE_AUTO_TAGS } from './types';

// Slice state
export const getState = state => state.customerRequestsAutoTag;

// Operations
export const selectOperationsState = state => getState(state).operations || {};

export const selectGeneratedAutoTags = createSelector(getState, state => state.generatedAutoTags || []);

export const selectWizardStep = createSelector(getState, state => state.wizardStep);

export const selectIsGeneratingAutoTags = createSelector(selectOperationsState, state => isLoading(state, GENERATE_AUTO_TAGS));

export const selectIsGeneratedAutoTagsUnintialized = createSelector(selectOperationsState, state =>
  isUninitialized(state, GENERATE_AUTO_TAGS),
);

export const getAIAnalyzerCustomerRequests = createSelector(getGridCustomerRequests, customerRequests =>
  customerRequests.filter(cr => isEmpty(cr.tags)),
);
