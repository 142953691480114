import React from 'react';
import { defaultTo, either, path, pipe } from 'ramda';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import { agGridColumnTextColor } from 'design-system/themes/default';

const defaultToEmptyString = defaultTo('');
const getTeamTitle = pipe(path(['team', 'title']), defaultToEmptyString);
const getTeam2Id = pipe(path('team_2_id'), defaultToEmptyString);
const getTeam2Title = pipe(path(['team2', 'title']), defaultToEmptyString);
const getTitle = either(getTeam2Title, getTeamTitle);

const TeamCellRenderer = props => {
  const { colDef, data } = props;

  const hasBreakwords = colDef?.breakwords || colDef?.autoHeight;

  return (
    <TextDeprecated breakwords={hasBreakwords} color={agGridColumnTextColor} size="small">
      {colDef?.hasTeams2 && getTeam2Id(data) ? getTitle(data) : getTeamTitle(data)}
    </TextDeprecated>
  );
};

export default TeamCellRenderer;
