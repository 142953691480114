import React from 'react';
import styled from 'styled-components';

import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';

import { spacing } from 'design-system/theme';
import Header from './components/Header';
import Footer from './components/Footer';

const PreviewScreen = ({ releaseNotes, updateReleaseNotes, onSave, onClose }) => {
  return (
    <Container tabIndex={-1}>
      <Header onClose={onClose} />
      <Content>
        <StyledFluidRemirrorWYSIWYGEditor
          key="release-notes"
          value={releaseNotes}
          content={releaseNotes}
          onChange={updateReleaseNotes}
          smallInput
        />
      </Content>
      <Footer onClose={onClose} onSave={onSave} />
    </Container>
  );
};

export default PreviewScreen;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  height: 100%;
`;

const Content = styled.div`
  height: 100%;
  padding: ${spacing(5)}px ${spacing(3)}px ${spacing(3)}px;
`;

const StyledFluidRemirrorWYSIWYGEditor = styled(FluidRemirrorWYSIWYGEditor)`
  .remirror-editor-wrapper {
    max-height: 55vh;
    overflow-y: auto;

    @media screen and (min-height: 850px) {
      max-height: 65vh;
    }

    @media screen and (min-height: 1000px) {
      max-height: 70vh;
    }
  }
`;
