import React, { useState } from 'react';
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import theme from 'design-system/theme';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';

import { getViewName } from 'utils/userViews';
import ViewIcon from './ViewIcon';
import UpdateDate from './UpdateDate';
import ActionMenu from './ActionMenu';

export default ({ item, handleFavoriteUserView, handleActionClick, getActionIcon, isFavorite, onSelectView, isLast }) => {
  const [showUpdateDate, setShowUpdateDate] = useState(false);

  const viewName = getViewName(item);

  return (
    <StyledListItem isLast={isLast}>
      <ListItemContainer
        onClick={() => onSelectView(item)}
        onMouseOver={() => setShowUpdateDate(true)}
        onMouseOut={() => setShowUpdateDate(false)}
      >
        <StyledViewIcon item={item} />
        <TooltipOnOverflow text={viewName} elementWithOverflow="span" vertical>
          <StyledListItemText
            primary={viewName}
            primaryTypographyProps={{
              style: {
                color: theme.palette.text.primary,
                cursor: 'pointer',
              },
            }}
            truncateText
          />
        </TooltipOnOverflow>
      </ListItemContainer>
      <StyledUpdateDate item={item} isVisible={showUpdateDate} />
      {!item.is_static && (
        <ActionMenuContainer isVisible={!showUpdateDate}>
          <ActionMenu
            item={item}
            handleFavoriteUserView={handleFavoriteUserView}
            handleActionClick={handleActionClick}
            getActionIcon={getActionIcon}
            isFavorite={isFavorite}
            shareButtonId="widget-share-view-button"
            small
          />
        </ActionMenuContainer>
      )}
    </StyledListItem>
  );
};

const StyledListItem = styled(ListItem)`
  height: 50px;

  && {
    padding-left: 25px;
    padding-right: 25px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: background-color 0.2s;
  }

  &:hover:before {
    background-color: ${theme.palette.newLayout.background.desertStormGrey};
  }

  ${({ isLast, theme }) =>
    !isLast &&
    `
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 25px;
            right: 25px;
            height: 1px;
            background: ${theme.palette.newLayout.background.lighterBlack}
        }
    `}
`;

const StyledListItemText = styled(ListItemText)`
  &&&&& {
    padding: 0 14px;

    span {
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
      line-height: ${({ theme }) => theme.typography.lineHeightSmallRem}rem;
    }
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }
`;

const ListItemContainer = styled.div`
  position: relative;

  &&&&& {
    display: flex;
    align-items: center;
    flex: 1;
  }
`;

const StyledViewIcon = styled(ViewIcon)`
  width: 1em;
  height: 1em;
  font-size: inherit;
  flex-shrink: 0;
`;

const StyledUpdateDate = styled(UpdateDate)`
  position: absolute;
  right: 20px;
`;

const ActionMenuContainer = styled.div`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.1s;
`;
