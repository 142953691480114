import React from 'react';

import { Draggable } from 'react-beautiful-dnd';

import DndAtoms from 'design-system/atoms/DndAtoms/index';


const { MenuItem, MenuItemContainer, MenuIcon, DragIndicator, MenuBodyContainer } = DndAtoms;

export default ({
  id,
  index,
  draggable,
  active,
  isSubTree,
  style,
  renderParent,
  renderChildren,
  showActionsOnHover,
  onItemKeyDown,
}) => {
  const _getListStyle = (isDragging, customStyle) => {
    return {
      ...customStyle,
      top: isDragging && isSubTree ? customStyle.top - 44 : customStyle.top,
      left: isDragging && isSubTree ? customStyle.left - 20 : customStyle.left,
    };
  };

  if (draggable) {
    return (
      <Draggable draggableId={`draggable-${id}`} index={index}>
        {(provided, snapshot) => (
          <MenuItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={_getListStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            <MenuItemContainer style={style} active={active}>
              <MenuIcon {...provided.dragHandleProps}>
                <DragIndicator />
              </MenuIcon>
              {renderParent()}
            </MenuItemContainer>
            <MenuBodyContainer>{renderChildren()}</MenuBodyContainer>
          </MenuItem>
        )}
      </Draggable>
    );
  }

  return (
    <MenuItem>
      <MenuItemContainer
        id={id}
        key={id}
        style={style}
        showActionsOnHover={showActionsOnHover}
        active={active}
        tabIndex={0}
        onKeyDown={e => onItemKeyDown(e, id)}
        data-item="true"
      >
        {renderParent()}
      </MenuItemContainer>
      <MenuBodyContainer>{renderChildren()}</MenuBodyContainer>
    </MenuItem>
  );
};
