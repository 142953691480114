import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import isNumber from 'lodash/isNumber';
import { last, defaultTo } from 'ramda';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import Text from 'design-system/atoms/Text/index';
import InlineDateRange from 'design-system/organisms/InlineDateRange/InlineDateRange';

import theme, { spacing } from 'design-system/theme';

import getTooltipOptions from 'utils/charts/getTooltipOptions';
import { getActualMetricValue, ACTUAL } from 'utils/metricValues';
import { xScaleOptionsForDates, yScaleOptionsForNumbers } from 'utils/chartsDateTicks';

import ScatterChart from 'components/ReactChart/ScatterChart';
import { tickSmallStyles } from 'routes/Dashboard/Dashboards/Charts/utils/scales';

import { getMetricChartTooltipTitle, getMetricChartTooltipLabel } from './utils';
import ChartClusters from './ChartClusters';
import MetricValueChartLegend from './MetricValueChartLegend';
import { useBarStackedCharDatalabelsConfig } from './hooks/useBarStackedCharDatalabelsConfig';

const defaultToEmptyArray = defaultTo([]);

const DAY = 'day';

const CHART_HEIGHT = 90;
const MAX_LEGEND_ITEMS = 6;

const getChartOptions = (
  minValue,
  maxValue,
  usePercentages,
  timeUnit,
  datesForChart = [],
  extraChartOptions = {},
  datalabelsConfig,
  showMoar,
) => ({
  layout: {
    padding: {
      top: 10,
    },
  },
  plugins: {
    tooltip: getTooltipOptions(getMetricChartTooltipTitle(usePercentages), getMetricChartTooltipLabel),
    legend: { display: false },
    datalabels: datalabelsConfig,
  },
  scales: {
    y: yScaleOptionsForNumbers(minValue, maxValue, usePercentages),
    x: xScaleOptionsForDates(timeUnit, datesForChart, extraChartOptions.dateDisplayFormats),
    yStacked: {
      stacked: true,
      ticks: {
        ...tickSmallStyles,
        precision: 1,
        stepSize: 0.1,
        display: showMoar,
      },
      position: 'right',
      grid: {
        display: false,
      },
    },
  },
  animation: {
    duration: 0,
  },
  ...extraChartOptions,
});

const getChartPlugins = (drawLastValue, usePercentages) => [
  {
    id: 'legendStyling',
    // this is a workaround to add padding to the legend until chart.js upgrade (0602)
    beforeInit(chart) {
      const originalFit = chart.legend.fit;

      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 10;
      };
    },
  },
  ...(drawLastValue
    ? [
        {
          id: 'drawLastActualValuePlugin',
          // Draws the last Actual value on the chart, next to it's data point
          afterDraw(chart) {
            const { ctx, data, scales } = chart;

            const actualValues = data?.datasets?.find(dataset => dataset?.label?.toLowerCase() === ACTUAL);
            const lastActualValue = last(defaultToEmptyArray(actualValues?.data));

            if (lastActualValue) {
              const xPosition = scales?.x?.getPixelForValue(new Date(lastActualValue.x).getTime());
              const yPosition = scales?.y?.getPixelForValue(lastActualValue.y);

              const isValidPosition = position => isNumber(position) && position > 0;

              if (isValidPosition(xPosition) && isValidPosition(yPosition)) {
                const formattedValue = `${lastActualValue.y?.toLocaleString('en-US')}${usePercentages ? '%' : ''}`;

                const { fontWeightMedium, fontSizeRem, fontFamily } = theme.typography;

                ctx.fillStyle = theme.palette.text.primary;
                ctx.font = `${fontWeightMedium} ${fontSizeRem}rem ${fontFamily}`;

                ctx.fillText(`${formattedValue}`, xPosition + spacing(1.5), yPosition + spacing(1.5));
              }
            }
          },
        },
      ]
    : []),
  ChartDataLabels,
];

const MetricValueChartComponent = ({
  metric,
  chartData,
  datesForChart,
  endDate,
  handleDateChange,
  startDate,
  useValuesAsPercentages,
  showDatePicker = true,
  timeUnit = DAY,
  extraChartOptions = {},
  chartStyle = {},
  openLightbox,
  isAnonymousUser,
  displayClusters,
  drawLastValueOnChart = false,
}) => {
  const [chartInstance, setChartInstance] = useState(null);

  const setChartInstanceRef = useCallback(instance => {
    setChartInstance(instance);
  }, []);

  const latestActualValue = getActualMetricValue(metric)?.value;

  const renderLatestActualValue = () => {
    const suffix = latestActualValue?.includes('%') ? '%' : null;

    return <NumberFormat value={latestActualValue} displayType="text" thousandSeparator suffix={suffix} />;
  };

  const valuedAllocationDatasets = chartData.datasets.filter(
    dataset => dataset.isAllocationDataset === true && dataset.data.length > 0,
  );
  const datalabelsConfig = useBarStackedCharDatalabelsConfig(chartData.datasets);
  const { lowestValue, highestValue } = useMemo(() => {
    const values = chartData?.datasets?.flatMap(item => item.data.map(obj => obj.y));

    return {
      lowestValue: Math.min(...values),
      highestValue: Math.max(...values),
    };
  }, [chartData]);

  const showMoar = valuedAllocationDatasets.length > 0;
  const chartOptions = getChartOptions(
    lowestValue,
    highestValue,
    useValuesAsPercentages,
    timeUnit,
    datesForChart,
    extraChartOptions,
    datalabelsConfig,
    showMoar,
  );

  const shouldDisplayClusters = useMemo(() => displayClusters && !isAnonymousUser, [displayClusters, isAnonymousUser]);

  return (
    <ChartWrapper>
      {showDatePicker ? (
        <HeaderContainer>
          {latestActualValue ? <Text as="h3">Value: {renderLatestActualValue()}</Text> : null}
          <DateWrapper>
            <InlineDateRange startDate={startDate} endDate={endDate} onChange={handleDateChange} disableEdit={isAnonymousUser} />
          </DateWrapper>
        </HeaderContainer>
      ) : null}
      <ChartContainer>
        <ScatterChart
          data={chartData}
          height={CHART_HEIGHT}
          options={chartOptions}
          plugins={getChartPlugins(drawLastValueOnChart, useValuesAsPercentages)}
          style={chartStyle}
          setRef={setChartInstanceRef}
        />
        {shouldDisplayClusters ? (
          <ChartClusters
            metricId={metric?.id}
            datesForChart={datesForChart}
            chartInstance={chartInstance}
            onClusterClick={openLightbox}
            timeUnit={timeUnit}
          />
        ) : null}
      </ChartContainer>
      <StyledMetricValueChartLegend moarLegends={valuedAllocationDatasets} maxLegendItems={MAX_LEGEND_ITEMS} />
    </ChartWrapper>
  );
};

export default MetricValueChartComponent;

export const ChartWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  position: absolute;
  top: -${spacing()}px;
  left: 0;
  right: 0;
  padding: 0 ${spacing(4)}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DateWrapper = styled.div`
  margin-left: auto;
  z-index: 1;
`;

const ChartContainer = styled.div`
  position: relative;
  margin-top: ${spacing()}px;
`;

const StyledMetricValueChartLegend = styled(MetricValueChartLegend)`
  margin: ${spacing(2)}px 0 0 ${spacing(5)}px;
`;
