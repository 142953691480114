import intersectionBy from 'lodash/intersectionBy';
import { PORTFOLIO_MODE } from 'constants/common';

import {
  SET_GLOBAL_SEARCH,
  SET_SEARCH_BAR_OPEN,
  SHOW_ADD_NEW_PROJECT,
  CLOSE_ADD_NEW_PROJECT,
  SET_APP_ERROR,
  STRIPE_LOADED,
  ZENDESK_LOADED,
  SHOW_GITHUB_IMPORT_PROJECTS_DIALOG,
  HIDE_GITHUB_IMPORT_PROJECTS_DIALOG,
  SHOW_WELCOME_DIALOG,
  CLOSE_WELCOME_DIALOG,
  SET_GLOBAL_MESSAGE,
  SET_APP_GLOBAL_CONFIG,
  SAVE_LAST_IDEAS_VIEW,
  SAVE_LAST_GOALS_VIEW,
  ADD_NOTIFICATIONS,
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  DISMISS_NOTIFICATION,
  RESET_NOTIFICATIONS,
  SHOW_PERMISSIONS_DIALOG,
  HIDE_PERMISSIONS_DIALOG,
  SET_LEFT_NAV_EXPANDED_ITEM,
  SET_PAGE_MODE,
  SET_IS_METADATA_FETCHED,
  SET_GO_BACK_URL,
  ENABLE_ONE_CLICK_PLAN_MODE,
  DISABLE_ONE_CLICK_PLAN_MODE,
  UPDATE_IS_SCENARIO_ROUTE,
} from './types';
import { RUN_ONE_CLICK_PLAN_FULFILLED, UPDATE_ROADMAP_VERSION_FULFILLED } from 'store/roadmapVersions';

const initialState = {
  globalSearch: null,
  addNewProject: false,
  appError: null,
  welcomeDialog: true,
  globalMessage: null,
  lastIdeasView: null,
  notifications: [],
  showPermissionsDialog: false,
  leftNavigationExpandedItems: [],
  pageMode: PORTFOLIO_MODE,
  isMetadataFetched: false,
  oneClickPlanMode: {
    didRun: false,
    returnToVersion: null,
  },
  goBackUrls: [],
  lastPagesView: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_GLOBAL_SEARCH:
      return {
        ...state,
        ...{ globalSearch: action.search },
      };
    case SET_SEARCH_BAR_OPEN:
      return {
        ...state,
        ...{ searchBarOpen: action.open },
      };
    case SHOW_ADD_NEW_PROJECT:
      return {
        ...state,
        ...{ addNewProject: true, lightboxIdeaId: action.lightboxIdeaId },
      };
    case CLOSE_ADD_NEW_PROJECT:
      return {
        ...state,
        ...{ addNewProject: false },
      };
    case SHOW_WELCOME_DIALOG:
      return {
        ...state,
        ...{ welcomeDialog: true },
      };
    case CLOSE_WELCOME_DIALOG:
      return {
        ...state,
        ...{ welcomeDialog: false },
      };
    case SET_APP_ERROR:
      return {
        ...state,
        ...{ appError: action.error },
      };
    case STRIPE_LOADED:
      return {
        ...state,
        stripeLoaded: true,
      };
    case ZENDESK_LOADED:
      return {
        ...state,
        zendeskLoaded: true,
      };
    case SHOW_GITHUB_IMPORT_PROJECTS_DIALOG:
      return {
        ...state,
        githubImportProjectsDialogVisible: true,
      };
    case HIDE_GITHUB_IMPORT_PROJECTS_DIALOG:
      return {
        ...state,
        githubImportProjectsDialogVisible: false,
      };
    case SAVE_LAST_IDEAS_VIEW:
      return {
        ...state,
        lastIdeasView: action.view,
      };
    case SAVE_LAST_GOALS_VIEW:
      return {
        ...state,
        lastGoalsView: action.view,
      };
    case SET_GLOBAL_MESSAGE:
      return {
        ...state,
        globalMessage: action.message,
      };
    case SET_APP_GLOBAL_CONFIG:
      return {
        ...state,
        ...(action.config || {}),
      };
    case ADD_NOTIFICATIONS:
      // This is a workaround in order to not display the same notifications twice
      // Is not ideal, but in some cases the same API request is being performed more than once
      if (intersectionBy(state.notifications, action.notifications, 'id').length === action.notifications.length) {
        return state;
      }

      return {
        ...state,
        notifications: [
          ...state.notifications,
          ...action.notifications.map(notification => ({ ...notification, isDismissed: false })),
        ],
      };
    case ADD_NOTIFICATION:
      if (state.notifications.some(notification => notification.id === action.notification?.id)) {
        return state;
      }

      return {
        ...state,
        notifications: [...state.notifications, action.notification],
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications.filter(notification => notification.id !== action.id)],
      };
    case DISMISS_NOTIFICATION:
      const foundNotification = state.notifications.find(notification => notification.id === action.id);

      return {
        ...state,
        notifications: foundNotification
          ? [
              ...state.notifications.filter(notification => notification.id !== action.id),
              { ...foundNotification, isDismissed: true },
            ]
          : [...state.notifications],
      };
    case RESET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };
    case SHOW_PERMISSIONS_DIALOG:
      return {
        ...state,
        showPermissionsDialog: true,
      };
    case HIDE_PERMISSIONS_DIALOG:
      return {
        ...state,
        showPermissionsDialog: false,
      };
    case SET_LEFT_NAV_EXPANDED_ITEM:
      return {
        ...state,
        leftNavigationExpandedItems: action.payload,
      };
    case SET_PAGE_MODE:
      return {
        ...state,
        pageMode: action.payload,
      };
    case SET_IS_METADATA_FETCHED:
      return {
        ...state,
        isMetadataFetched: action.payload,
      };
    case SET_GO_BACK_URL:
      return {
        ...state,
        goBackUrls: action.goBackUrls,
      };
    case ENABLE_ONE_CLICK_PLAN_MODE:
      const { shouldReturnToVersion } = action.payload;

      return {
        ...state,
        oneClickPlanMode: {
          didRun: false,
          returnToVersion: shouldReturnToVersion,
        },
      };
    case DISABLE_ONE_CLICK_PLAN_MODE:
      return {
        ...state,
        oneClickPlanMode: {
          didRun: false,
          returnToVersion: null,
        },
      };
    case RUN_ONE_CLICK_PLAN_FULFILLED:
      return {
        ...state,
        oneClickPlanMode: {
          ...state.oneClickPlanMode,
          didRun: true,
        },
      };
    // after save new scenario we should turn one click plan mode off
    case UPDATE_ROADMAP_VERSION_FULFILLED:
      return {
        ...state,
        oneClickPlanMode: {
          didRun: false,
        },
      };
    case UPDATE_IS_SCENARIO_ROUTE:
      return {
        ...state,
        isScenarioRoute: action.payload,
      };
    default:
      return state;
  }
};
