import React from 'react';
import omit from 'lodash/omit';

import getFluidFieldValidation from './getFluidFieldValidation';
import { not } from 'ramda';

const removeElemProps =
  (Tag, filter = []) =>
  props =>
    <Tag {...omit(props, filter)} />;

const searchMenuItems = (searchString, items) => {
  const search = searchString || '';

  return items.filter(item => (item.label || '').toLowerCase().includes(search.toLowerCase()));
};

const searchMenuItemsWithHierarchy = (searchString, items = []) => {
  const search = searchString || '';

  return items.reduce((acc, item) => {
    const isMatch = (item.label || '').toLowerCase().includes(search.toLowerCase());

    if (isMatch) {
      return [...acc, item];
    }

    const noChildren = not(item?.children?.length);

    if (noChildren) {
      return acc;
    }

    const childrenFiltered = searchMenuItems(searchString, item?.children);

    if (childrenFiltered?.length) {
      return [...acc, { ...item, children: childrenFiltered }];
    }

    return acc;
  }, []);
};

const checkedObject = (items, obj) => {
  if (!obj || (typeof obj === 'object' && Object.keys(obj).length === 0)) return false;
  if (typeof obj === 'boolean') return obj;

  return items.reduce((val, item) => {
    if (typeof obj[item.id] === 'boolean') {
      return val && obj[item.id];
    }

    return val && checkedObject(item.children, obj[item.id]);
  }, true);
};

// eslint-disable-next-line no-restricted-globals
const isNumeric = value => !isNaN(value); // Duplicated from project utils

const isEmail = str => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(str); // Duplicated from project utils

export {
  removeElemProps,
  searchMenuItems,
  checkedObject,
  isNumeric,
  isEmail,
  getFluidFieldValidation,
  searchMenuItemsWithHierarchy,
};
