import axios from 'axios';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import { hasPath, propOr } from 'ramda';

import { createThunk } from 'utils/store/thunk';

import { CREATE, BULK_CREATE, UPDATE, BULK_UPDATE, DELETE, BULK_DELETE } from 'store/constants/realtimeUpdateTypes';
import throwRequestError from 'store/utils/throwRequestError';

import { BULK_CREATE_PROJECTS_FULFILLED, DELETE_PROJECTS_FULFILLED } from 'store/projects/types';
import {
  CREATE_PROJECT_ESTIMATE,
  CREATE_PROJECT_ESTIMATE_FULFILLED,
  UPDATE_PROJECT_ESTIMATE,
  UPDATE_PROJECT_ESTIMATE_FULFILLED,
  DELETE_PROJECT_ESTIMATE,
  DELETE_PROJECT_ESTIMATE_FULFILLED,
  UPDATE_ESTIMATES,
  BULK_UPDATED_PROJECT_ESTIMATES_FULFILLED,
} from './types';
import { getSelectedRoadmapVersion } from 'store/roadmapVersions/selectors';
import { updateScenarioEstimates } from 'store/roadmapVersions';

export const createProjectEstimate = (projectId, estimate) =>
  createThunk(
    CREATE_PROJECT_ESTIMATE,
    axios
      .post(`/api/projects/${projectId}/estimates`, estimate)
      .then(response => {
        if (hasPath(['data', 'estimate'], response)) {
          response.data.estimate.uuid = estimate.uuid;
        }

        return response;
      })
      .catch(throwRequestError),
  );

export const updateProjectEstimateOnGrid = (projectId, teamId, skillId, estimate) =>
  createThunk(
    UPDATE_PROJECT_ESTIMATE,
    axios
      .put(`/api/projects/${projectId}/estimates/${teamId}/${skillId}`, estimate)
      .then(response => {
        if (hasPath(['data', 'estimate'], response)) {
          response.data.estimate.uuid = estimate.uuid;
        }

        return { data: response?.data?.estimate };
      })
      .catch(e => throwRequestError(e, true)),
  );

export const updateProjectEstimate = estimate => {
  return updateEstimates([estimate]);
};

export const deleteProjectEstimate = (projectId, estimate) =>
  createThunk(
    DELETE_PROJECT_ESTIMATE,
    axios
      .delete(`/api/estimates/${estimate.id}`)
      .then(() => ({ estimate, projectId }))
      .catch(throwRequestError),
  );

export const secureUpdateProjectEstimates = (projectId, currentEstimates, newEstimates) => {
  return async dispatch => {
    const requests = [];

    if (!currentEstimates || !(currentEstimates instanceof Array) || !newEstimates || !(newEstimates instanceof Array)) {
      return Promise.resolve();
    }

    // update estimates
    const estimatesToUpdate = currentEstimates
      .filter(estimate => estimate.id)
      .reduce((toUpdate, estimate) => {
        const estimateToUpdate = newEstimates.find(est => est.id === estimate.id);

        if (estimateToUpdate && !isEqual(estimateToUpdate, estimate)) {
          return [...toUpdate, estimateToUpdate];
        }

        return toUpdate;
      }, []);

    if (estimatesToUpdate.length > 0) {
      requests.push(dispatch(updateEstimates(estimatesToUpdate)));
    }

    // delete estimates
    const currentEstimatesIds = currentEstimates.map(estimate => estimate.id);
    const updatedEstimatesIds = newEstimates.map(estimate => estimate.id);
    const estimatesToRemove = difference(currentEstimatesIds, updatedEstimatesIds);

    if (estimatesToRemove && estimatesToRemove.length) {
      estimatesToRemove.forEach(estimateId => {
        requests.push(dispatch(deleteProjectEstimate(projectId, { id: estimateId })));
      });
    }

    // create estimates
    const estimatesToCreate = newEstimates.filter(estimate => !estimate.id);

    if (estimatesToCreate && estimatesToCreate.length) {
      estimatesToCreate.forEach(estimate => {
        requests.push(dispatch(createProjectEstimate(projectId, estimate)));
      });
    }

    if (!requests.length) {
      return Promise.resolve();
    }

    return Promise.all(requests);
  };
};

/**
 * This action is only used in forecast page to update estimates sort_order.
 * It is not changing projects in state because it would force forecast gantt to re-render,
 * what is causing performance problems in the page.
 * Before using this method, change forecast gantt to don't be rendered entirely when projects data in state is updated
 * @param {Array.Object} estimates
 */
export const updateEstimates = estimates => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedRoadmapVersion = getSelectedRoadmapVersion(state);

    if (selectedRoadmapVersion) {
      return updateScenarioEstimates(state, dispatch, estimates, selectedRoadmapVersion).catch(throwRequestError);
    }

    return dispatch(createThunk(UPDATE_ESTIMATES, putEstimates(estimates).catch(throwRequestError)));
  };
};

/**
 * Puts estimates changes.
 * @param {Estimate[]} estimates
 * @returns Updated estimates
 */
const putEstimates = async estimates => {
  const updatedEstimates = await axios.put('/api/estimates', estimates).then(propOr([], 'data'));

  return {
    estimates: updatedEstimates,
    projects: [],
  };
};

export function gotEstimatesRealtimeUpdate(type, data) {
  return (dispatch, getState) => {
    switch (type) {
      case CREATE:
        return dispatch({
          type: CREATE_PROJECT_ESTIMATE_FULFILLED,
          payload: { data },
          meta: { realtime: true },
        });
      case UPDATE:
        const lastEstimatesUpdated = getState()?.estimates?.operations?.updateEstimates?.data?.estimates || [];
        const realTimeEventEstimates = Array.isArray(data) ? data : [];

        // If we dispatched the estimate update there is no need for a double commit from the real time event
        const lastUpdateMatchesRealTimeUpdate = realTimeEventEstimates.every(({ id, updated_at: updatedAt }) =>
          lastEstimatesUpdated.find(e => e.id === id && e.updated_at === updatedAt),
        );

        if (lastUpdateMatchesRealTimeUpdate) {
          return;
        }

        return dispatch({
          type: UPDATE_PROJECT_ESTIMATE_FULFILLED,
          payload: { data },
          meta: { realtime: true },
        });
      case DELETE:
        return dispatch({
          type: DELETE_PROJECT_ESTIMATE_FULFILLED,
          payload: {
            estimate: data,
            projectId: data.project_id,
          },
          meta: { realtime: true },
        });
      // TODO: I believe these ones should be in the projects slice instead
      case BULK_CREATE:
        return dispatch({
          type: BULK_CREATE_PROJECTS_FULFILLED,
          payload: [data],
          meta: { realtime: true },
        });
      case BULK_UPDATE:
        return dispatch({
          type: BULK_UPDATED_PROJECT_ESTIMATES_FULFILLED,
          payload: data,
          meta: { realtime: true },
        });
      case BULK_DELETE:
        return dispatch({
          type: DELETE_PROJECTS_FULFILLED,
          payload: data,
          meta: { realtime: true },
        });
      default:
    }
  };
}
