/* eslint-disable prefer-destructuring */
import axios from 'axios';
import queryString from 'query-string';

import {
  SET_AUTH_TOKEN,
  CURRENT_USER,
  CHANGE_PASSWORD,
  LOGIN_FAILED,
  GOOGLE_LOGIN_FAILED,
  RESET_PASSWORD_FAILED,
  SENT_RESET_PASSWORD_INSTRUCTIONS,
  CLEAR_RESET_PASSWORD,
  NEW_PASSWORD_SET,
  SET_NEW_PASSWORD_FAILED,
  GOOGLE_LOGIN_NO_ACCOUNT,
  GOOGLE_SIGNUP_APPROVED,
  GOOGLE_SIGNUP_DECLINED,
  GOOGLE_SIGNUP_APPROVAL_FAILED,
  ACCOUNT_SIGNUP_ERROR,
  SET_ORGANIZATION,
  SET_ALLOW_RECEIVE_NOTIFICATIONS_EMAILS,
  FETCH_USER_INTEGRATIONS,
  ON_USER_LOGOUT,
  ADD_USER_INTEGRATION,
  REMOVE_USER_INTEGRATION,
  UPDATE_CURRENT_USER,
  REQUEST_ACCESS,
  CLEAR_LOADED_USER_INTEGRATIONS_FOR_ORG_INTEGRATION,
} from './types';

import { getDefaultRoute, getUserName } from 'utils';
import { fetchCustomFields } from 'store/customFields';
import { integrationsTypesMapper } from 'constants/integrationTypes';
import { INTEGRATION_GATEWAY_INTEGRATION_TYPES, DOD_LOGIN_POPOP_WINDOW_NAME } from 'constants/integrations';

const _isLoginPopupWindow = () => window.name === DOD_LOGIN_POPOP_WINDOW_NAME;

const _wrapLoginBody = body => ({ ...body, isLoginPopup: _isLoginPopupWindow() });

/*
 * With DoD feature we can have a login which only purpose is to authenticate an user on child instance.
 * In those cases the url is opened on a window popop with name 'Dragonboat_Authentication'.
 * When that happens we want to send back the message of login success to the initial/parent instance listener
 * and close the current window.
 * */
const _onUserLogin = (dispatch, data) => {
  if (_isLoginPopupWindow()) {
    window.opener.postMessage(
      JSON.stringify({
        target: 'dragonboat-login',
        data,
      }),
      '*',
    );
    window.close();

    return;
  }
  const { user } = data;

  dispatch(setTokenValue(null, user));
};

export const addUserIntegration = (integrationType, orgIntegrationId, token, metadata) => {
  return dispatch => {
    const requestUrl = INTEGRATION_GATEWAY_INTEGRATION_TYPES.includes(integrationType)
      ? `/api/user-integrations/${integrationType}/${orgIntegrationId}`
      : `/api/user-integrations/${integrationType}`;

    const payload = axios.post(requestUrl, { dtoToken: token, metadata }).then(({ data }) => {
      dispatch(fetchCustomFields());
      return data;
    });

    dispatch({
      type: ADD_USER_INTEGRATION,
      payload,
    });

    return payload;
  };
};

export const removeUserIntegration = (integrationType, orgIntegrationId) => {
  return dispatch => {
    const requestUrl = INTEGRATION_GATEWAY_INTEGRATION_TYPES.includes(integrationType)
      ? `/api/user-integrations/${integrationType}/${orgIntegrationId}`
      : `/api/user-integrations/${integrationType}`;

    const payload = axios.delete(requestUrl);

    dispatch({
      type: REMOVE_USER_INTEGRATION,
      payload,
      meta: { integrationType: integrationsTypesMapper[integrationType], orgIntegrationId },
    });

    return payload;
  };
};

export const clearLoadedUserIntegrationsForOrgIntegration = orgIntegrationId => {
  return dispatch =>
    dispatch({
      type: CLEAR_LOADED_USER_INTEGRATIONS_FOR_ORG_INTEGRATION,
      meta: { orgIntegrationId },
    });
};

export function setTokenValue(token, loginUser) {
  return {
    type: SET_AUTH_TOKEN,
    payload: {
      token,
      isAuthenticated: true,
      loginUser,
    },
  };
}

export function setNewPasswordErrorMessage(errorMessage) {
  return {
    type: SET_NEW_PASSWORD_FAILED,
    payload: {
      errorMessage,
    },
  };
}

export function setResetPasswordErrorMessage(errorMessage) {
  return {
    type: RESET_PASSWORD_FAILED,
    payload: {
      errorMessage,
    },
  };
}

export function setErrorMessage(errorMessage) {
  return {
    type: LOGIN_FAILED,
    payload: {
      errorMessage,
    },
  };
}

export function setGoogleErrorMessage(errorMessage) {
  return {
    type: GOOGLE_LOGIN_FAILED,
    payload: {
      errorMessage,
    },
  };
}

export function setCurrentUser(user) {
  /* global zE */
  if (window.zE) {
    zE('webWidget', 'updateSettings', {
      webWidget: {
        authenticate: {
          async jwtFn(callback) {
            const { data: token } = await axios.post(`/api/integrations/zendesk/widget-token`);

            if (token) {
              callback(token);
            }
          },
        },
      },
    });
    zE('webWidget', 'identify', {
      name: getUserName(user),
      email: user.email,
      organization: user.organization ? user.organization.slug : `id: ${user.organization_id}`,
    });
    zE('webWidget', 'prefill', {
      name: {
        value: getUserName(user),
        readOnly: true,
      },
      email: {
        value: user.email,
        readOnly: true,
      },
    });
  }
  return {
    type: CURRENT_USER,
    payload: {
      isAuthenticated: !!user,
      loginUser: user,
    },
  };
}

export function logout(flag) {
  // This action type is used on the root reducer so it is able to reset the whole store
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
  return {
    type: ON_USER_LOGOUT,
  };
}

export const setNewPassword = (password, token) => {
  return async dispatch => {
    try {
      await axios.post('/api/users/me/password', { token, password });

      return dispatch({
        type: NEW_PASSWORD_SET,
      });
    } catch ({ response }) {
      dispatch(setNewPasswordErrorMessage('This link has already expired.'));
    }
  };
};

export const signupWithGoogle = (token, props) => {
  return async (dispatch, getState) => {
    const res = await axios.post('/api/users/google_signups', _wrapLoginBody({ googleToken: token }));
    const { data } = res;

    if (data.user && data.token) {
      _onUserLogin(dispatch, data);

      const { next, ...otherQueryParams } = queryString.parse(props.location.search);

      if (next) props.history.push(`${next}?${queryString.stringify(otherQueryParams)}`);
      else props.history.push(getDefaultRoute(data.user));
    } else {
      props.history.push('/signup_pending');
    }
  };
};

export const signup = (values, props) => {
  return async (dispatch, getState) => {
    try {
      const payload = await axios.post('/api/users/signup', values);
      const host = window.location.host.slice(window.location.host.indexOf('.'));

      window.location = `http://${payload.data.slug}${host}/signup_confirmation`;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      const { data } = error.response;

      return dispatch({
        type: ACCOUNT_SIGNUP_ERROR,
        payload: data,
      });
    }
  };
};

export const approveGoogleSignup = (token, readonly, liteEditor) => {
  return async dispatch => {
    try {
      const res = await axios.put(`/api/users/google_signups/${token}`, { readonly, liteEditor });

      return dispatch({
        type: GOOGLE_SIGNUP_APPROVED,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GOOGLE_SIGNUP_APPROVAL_FAILED,
      });
    }
  };
};

export const declineGoogleSignup = token => {
  return async dispatch => {
    try {
      const res = await axios.delete(`/api/users/google_signups/${token}`);

      return dispatch({
        type: GOOGLE_SIGNUP_DECLINED,
        payload: res.data,
      });
    } catch ({ response }) {
      dispatch({
        type: GOOGLE_SIGNUP_APPROVAL_FAILED,
      });
    }
  };
};

export const changePassword = (oldPassword, newPassword) => {
  return {
    payload: axios.post('/api/users/change-password', { oldPassword, newPassword }),
    type: CHANGE_PASSWORD,
  };
};

export function login(values, props) {
  return dispatch => {
    return axios
      .post('/api/users/login', _wrapLoginBody(values))
      .then(res => {
        _onUserLogin(dispatch, res.data);
        const { next, ...otherQueryParams } = queryString.parse(props.location.search);

        if (next) {
          const { url: nextUrl, query: nextParams } = queryString.parseUrl(next);

          props.history.push(`${nextUrl}?${queryString.stringify({ ...otherQueryParams, ...nextParams })}`);
        } else {
          props.history.push(getDefaultRoute(res.data.user));
        }
      })
      .catch(err => {
        const errorMessage = err?.response?.data?.message || 'Authentication failed';

        dispatch(setErrorMessage(errorMessage));
      });
  };
}

export function loginWithGoogle(googleToken, props) {
  return dispatch => {
    return axios
      .post('/api/users/google_login', _wrapLoginBody({ googleToken }))
      .then(res => {
        if (res.status === 200) {
          _onUserLogin(dispatch, res.data);
          const { next, ...otherQueryParams } = queryString.parse(props.location.search);

          if (next) {
            const origin = window.location.origin;

            const url = new URL(origin + next);

            const queryParams = new URLSearchParams(url.search);

            Object.entries(otherQueryParams).forEach(([key, val]) => {
              queryParams.append(key, val);
            });

            const newUrl = `${url.pathname}?${queryParams.toString()}`;

            props.history.push(newUrl);
          } else {
            props.history.push(getDefaultRoute(res.data.user));
          }
        } else {
          return null;
        }
      })
      .catch(err => {
        const { response } = err;

        if (!response) throw err;
        let message = '';

        if (response.data.error_code === 'INVALID_EMAIL_DOMAIN_ERROR') {
          message = 'Please use your business email to signup.';
        } else if (response.data.error_code === 'COMPANY_DOMAIN_DOES_NOT_EXIST_ERROR') {
          props.history.push('/signup?noaccount=true');
          return;
        } else if (response.data.error_code === 'GOOGLE_SIGNUP_EMAIL_SENT') {
          dispatch({
            type: GOOGLE_LOGIN_NO_ACCOUNT,
            payload: { googleToken },
          });
          props.history.push('/signup_pending');
          return;
        } else if (response.data.error_code === 'GOOGLE_LOGIN_NOT_FOUND_ON_ORGANIZATION') {
          dispatch({
            type: GOOGLE_LOGIN_NO_ACCOUNT,
            payload: { googleToken },
          });
          props.history.push('/google_signup', { googleToken });
          return;
        } else if (response.data.message === 'Account inactive') {
          message = 'Account inactive';
        } else {
          message = 'Authentication failed';
        }
        dispatch(setGoogleErrorMessage(message));
      });
  };
}

export function superlogin(values, props) {
  return dispatch => {
    return axios
      .post('/api/users/superlogin', values)
      .then(res => {
        _onUserLogin(dispatch, { ...res.data, user: { ...res.data.user, isSuperAdmin: true } });
        const { next, ...otherQueryParams } = queryString.parse(props.location.search);

        if (next) props.history.push(`${next}?${queryString.stringify(otherQueryParams)}`);
        else props.history.push(getDefaultRoute(res.data.user));
      })
      .catch(() => {
        dispatch(setErrorMessage('Authentication failed'));
      });
  };
}

export const loginWithOkta = (code, state, props) => {
  return async dispatch => {
    const res = await axios.post('/api/integrations/okta/authorization/sso', _wrapLoginBody({ code, state }));

    if (res.status === 200) {
      _onUserLogin(dispatch, res.data);
      const { next, ...otherQueryParams } = queryString.parse(props.location.search);

      if (next) props.history.push(`${next}?${queryString.stringify(otherQueryParams)}`);
      else props.history.push(getDefaultRoute(res.data.user));
    } else {
      return null;
    }
  };
};

export const loginWithAzure = (code, state, props) => {
  return async dispatch => {
    const res = await axios.post('/api/integrations/azure/authorization/sso', _wrapLoginBody({ code, state }));

    if (res.status === 200) {
      _onUserLogin(dispatch, res.data);
      const { next, ...otherQueryParams } = queryString.parse(props.location.search);

      if (next) props.history.push(`${next}?${queryString.stringify(otherQueryParams)}`);
      else props.history.push(getDefaultRoute(res.data.user));
    } else {
      return null;
    }
  };
};

export function loginWithGoogleLegacy(googleToken, props) {
  return dispatch => {
    return axios
      .post('/api/users/google_login_legacy', _wrapLoginBody({ googleToken }))
      .then(res => {
        if (res.status === 200) {
          _onUserLogin(dispatch, res.data);

          const next = queryString.parse(props.location.search).next;
          const subdomain = window.location.origin.match(/^https*:\/\/([^.]+)\./)[1];
          const url = window.location.origin.replace(subdomain, res.data.user.organization.slug);

          if (next) window.location = `${url}${next}`;
          else window.location = `${url}`;
        } else {
          return null;
        }
      })
      .catch(({ response }) => {
        props.history.push('/workspace');
      });
  };
}

export const redirectToOkta = orgId => {
  return async dispatch => {
    const opened = window.open('', '_blank');
    const res = await axios.get(`/api/integrations/okta/authorization-uri?organizationId=${orgId}`);

    opened.location.href = res.data.uri;
  };
};

export const updateMe = data => {
  return async dispatch => {
    return dispatch({
      type: UPDATE_CURRENT_USER,
      payload: axios.patch('/api/users/me', data).then(({ data }) => data),
    });
  };
};

export function sendResetPasswordInstructions(email, props) {
  return async dispatch => {
    try {
      await axios.delete('/api/users/me/password', { data: { email } });

      // return dispatch({
      //   type: SENT_RESET_PASSWORD_INSTRUCTIONS,
      //   payload: {
      //     email,
      //   },
      // });
    } finally {
      await dispatch({
        type: SENT_RESET_PASSWORD_INSTRUCTIONS,
        payload: {
          email,
        },
      });

      props.history.push(`/reset_password_confirmation?email=${email}`);
    }
  };
}

export function sendWorkspacesInstructions(email, props) {
  return async dispatch => {
    try {
      await axios.post(`/api/users/organizations/email?email=${email}`);

      // return dispatch({
      //   type: SENT_RESET_PASSWORD_INSTRUCTIONS,
      //   payload: {
      //     email,
      //   },
      // });
    } finally {
      dispatch({
        type: SENT_RESET_PASSWORD_INSTRUCTIONS,
        payload: {
          email,
        },
      });

      props.history.push(`/forgot_workspace_confirmation?email=${email}`);
    }
  };
}

export function clearResetPassword() {
  return async dispatch => {
    dispatch({
      type: CLEAR_RESET_PASSWORD,
    });
  };
}

export function getOrganization() {
  return async dispatch => {
    let [slug] = window.location.hostname.split('.');

    if (slug === process.env.REACT_APP_PUBLIC_URL.match(/^https*:\/\/([^.]+)\./)[1]) {
      slug = '';
    }

    try {
      const organization = await axios.get(`/api/organizations/${slug}`);

      await dispatch({
        type: SET_ORGANIZATION,
        payload: organization,
      });
    } catch (err) {
      window.location = `${process.env.REACT_APP_PUBLIC_URL}/workspace?workspace=${slug}`;
    }
  };
}

export function goToWorkspace(workspace, props) {
  return async dispatch => {
    try {
      const organization = await axios.get(`/api/organizations/${workspace}`);
      const params = queryString.parse(props.location.search);

      window.location = `${organization.data.host}${props.location.pathname}${
        params.next ? `?${queryString.stringify(params)}` : ''
      }`;
    } catch (err) {
      props.history.push(`/signup?workspace=${workspace}`);
    }
  };
}

export function getCurrentUser() {
  return async dispatch => {
    try {
      const user = await axios.get('/api/users/me');

      return dispatch({
        type: CURRENT_USER,
        payload: {
          loginUser: user.data,
          isAuthenticated: true,
        },
      });
    } catch (e) {
      return dispatch({
        type: CURRENT_USER,
        payload: {
          loginUser: {},
          isAuthenticated: false,
        },
      });
    }
  };
}

export function getJiraResponseData(data, history) {
  return axios
    .post('/api/jira/auth/callback', data)
    .then(res => {
      if (res.data.success) {
        window.opener.postMessage('jiraAuth', '*');
      }
      window.close();
    })
    .catch(err => {
      console.error(err);
      window.close();
    });
}

export function clearAuthTokenAndStore() {
  return logout();
}

export const setAllowReceiveNotificationsEmails = value => dispatch => {
  const payload = axios.post('/api/users/me/allow_receive_notifications_emails', { value }).then(res => res.data);

  dispatch({
    type: SET_ALLOW_RECEIVE_NOTIFICATIONS_EMAILS,
    payload,
  });

  return payload;
};

export const fetchUserIntegrations = () => dispatch => {
  const payload = axios.get('/api/integrations').then(({ data }) => data);

  dispatch({
    type: FETCH_USER_INTEGRATIONS,
    payload,
  });

  return payload;
};

export const requestAccess = email => dispatch => {
  return dispatch({
    type: REQUEST_ACCESS,
    payload: axios.post('/api/users/request_access', { email }),
  });
};

export const loginActions = {
  setTokenValue,
  setCurrentUser,
  logout,
  login,
  loginWithGoogle,
  signupWithGoogle,
  getJiraResponseData,
  clearAuthTokenAndStore,
  changePassword,
  sendResetPasswordInstructions,
  clearResetPassword,
};
