import { pathOr } from 'ramda';

const DEFAULT_LABELS = {
  authCientId: 'Client ID',
  authClientSecret: 'Client Secret',
};

/**
 * Get integration label for `labelKey`.
 *
 * @param {Object} integrationsConfig
 * @param {String} labelKey - See DEFAULT_LABELS for a list of label keys
 * @returns {String|undefined}
 */
export const getIntegrationLabel = (integrationsConfig, labelKey) => {
  const integrationLabels = pathOr({}, ['labels'], integrationsConfig);
  const labelsOrDefault = { ...DEFAULT_LABELS, ...integrationLabels };

  return labelsOrDefault[labelKey];
};
