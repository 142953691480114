import React, { useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setDashboardInEditMode } from 'store/dashboards/actions';
import { DASHBOARDS_PAGE } from 'constants/filters/pages';
import { DEFAULT_TEMPLATE } from '../helpers/templates';
import useDashboardInformation from '../hooks/useDashboardInformation';
import {
  clearPageActiveView,
  savePageActiveView,
  setPageActiveView,
  deleteUserView,
  addUserViewFavorite,
  removeUserViewFavorite,
} from 'store/userViews/actions';
import { cloneView } from 'store/userViews/sharedViews.thunks';
import useNavigationToPathWithGoBackUrl from 'hooks/useNavigationToPathWithGoBackUrl';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();

    const { activeDashboardView, dashboardId, isReadOnlyUser, isInEditMode, isDashboardOwner, getNewDashboardData } =
      useDashboardInformation(props.match);

    const { navigateBack } = useNavigationToPathWithGoBackUrl();

    const { dashboard } = activeDashboardView?.state || {};
    const isViewFavorite = useMemo(() => !!activeDashboardView?.favorites?.length, [activeDashboardView]);

    const _onDashboardTemplateChanged = useCallback(
      templateKey => dispatch(setPageActiveView(DASHBOARDS_PAGE, getNewDashboardData(templateKey))),
      [dispatch],
    );

    const _createOrUpdateDashboard = useCallback(() => dispatch(savePageActiveView(DASHBOARDS_PAGE)), [dispatch]);

    const _deleteDashboard = useCallback(() => {
      dispatch(deleteUserView(dashboardId));
      dispatch(clearPageActiveView(DASHBOARDS_PAGE));
      navigateBack('/dashboard/user-dashboards');
    }, [dashboardId, dispatch, navigateBack]);

    const _duplicateDashboard = useCallback(() => {
      const duplicatedTitle = `${activeDashboardView.name} (copy)`;

      dispatch(cloneView(activeDashboardView, duplicatedTitle));
    }, [activeDashboardView, dispatch]);

    const _handleFavoriteUserView = useCallback(() => {
      if (!isViewFavorite) {
        dispatch(addUserViewFavorite(activeDashboardView.id));
      } else {
        dispatch(removeUserViewFavorite(activeDashboardView.id));
      }
    }, [isViewFavorite, activeDashboardView, dispatch]);

    const _checkCurrentDashboardChanged = useCallback(() => !!activeDashboardView?.hasUnsavedChanges, [activeDashboardView]);

    const selectedTemplateKey = dashboard?.template || DEFAULT_TEMPLATE;

    const _toggleEditMode = useCallback(toggle => dispatch(setDashboardInEditMode(toggle)), [dispatch]);

    if (!activeDashboardView) {
      return '';
    }

    return (
      <Component
        {...props}
        dashboardId={dashboardId}
        activeDashboard={activeDashboardView}
        isReadOnlyUser={isReadOnlyUser}
        onDashboardTemplateChanged={_onDashboardTemplateChanged}
        createOrUpdateDashboard={_createOrUpdateDashboard}
        deleteDashboard={_deleteDashboard}
        duplicateDashboard={_duplicateDashboard}
        handleFavoriteUserView={_handleFavoriteUserView}
        isFavorite={isViewFavorite}
        checkCurrentDashboardChanged={_checkCurrentDashboardChanged}
        selectedTemplateKey={selectedTemplateKey}
        inEditMode={isInEditMode}
        toggleEditMode={_toggleEditMode}
        isDashboardOwner={isDashboardOwner}
      />
    );
  };
};

export default compose(withRouter, componentHOC);
