import React from 'react';

import ConfirmDialog from 'components/ConfirmDialog';
import UserAccessEmailLightbox from 'components/UserAccessEmailLightbox';
import ImportUsersDialog from 'containers/ImportUsersDialog';
import ProgressLightbox from 'design-system/molecules/ProgressLightbox/index';

import { flatten, length, defaultTo, pipe, pluck, isEmpty, not, append } from 'ramda';
import MergeUsersDialog from './components/MergeUsersDialog';
import BulkUpdateUsersModal from './components/BulkUpdateUsersModal';
import pluralize from 'pluralize';
import useRoleAvailability from 'hooks/users/useRoleAvailability';

const isNotEmpty = pipe(isEmpty, not);

const UsersSettingsDialogs = ({
  selectedItems = [],
  disableSelectionMode,
  // Props for Users Merge Dialog:
  usersMergeDialogIsVisible,
  onMergeUsers,
  hideUsersMergeDialog,
  // Props for User Access E-mail Dialog:
  userAccessEmailDialogIsVisible,
  onSaveUserAccessEmail,
  onCancelUserAccessEmail,
  hideUserAccessEmailDialog,
  userAccessEmail,
  userAccessName,
  userAccessRoleId,
  // Props for User Delete Dialog:
  userDeleteDialogIsOpen,
  onUserDeleteConfirm,
  onUserDeleteCancel,
  // Props for Users Bulk Delete Dialog:
  usersBulkDeleteDialogIsOpen,
  onUsersBulkDeleteConfirm,
  onUsersBulkDeleteCancel,
  disableUsersBulkDeleteActions,
  // Props for Bulk Delete Progress Dialog:
  bulkDeleteProgress,
  // Props for Import Users Dialog:
  usersImportDialogIsOpen,
  onUsersImportCancel,
  // Props for Users Bulk Update Dialog:
  usersBulkUpdateDialogIsOpen,
  onUsersBulkUpdateCancel,
  onUsersBulkUpdateConfirm,
  systemFields,
  teamsTitles,
  skillsTitles,
  allowAddNewSettings,
}) => {
  const generateDeleteConfirmationMessage = (numberOfUsers = 0) =>
    `Are you sure you want to delete selected ${pluralize('user', numberOfUsers)}?`;
  let singleDeleteConfirmationMessage = generateDeleteConfirmationMessage(1);
  let bulkDeleteConfirmationMessage = generateDeleteConfirmationMessage(2);

  const userData = userDeleteDialogIsOpen;

  const { isRoleAvailable } = useRoleAvailability();

  const singleUserApiKeys = defaultTo([], userData?.apiTokens);

  if (isNotEmpty(singleUserApiKeys)) {
    singleDeleteConfirmationMessage = append(
      singleDeleteConfirmationMessage,
      `This user currently owns ${userData?.apiTokens?.length} API ${pluralize(
        'key',
        userData?.apiTokens.length,
      )} that will be automatically revoked. `,
    );
  }

  const bulkUserApiKeys = pipe(pluck('apiTokens'), flatten, length)(selectedItems);

  if (bulkUserApiKeys > 0) {
    bulkDeleteConfirmationMessage = append(
      bulkDeleteConfirmationMessage,
      `These ${selectedItems.length} users currently own ${bulkUserApiKeys} API ${pluralize(
        'key',
        bulkUserApiKeys,
      )} that will be automatically revoked. `,
    );
  }

  return (
    <>
      <MergeUsersDialog
        selectedItems={selectedItems}
        isVisible={usersMergeDialogIsVisible}
        hideDialog={hideUsersMergeDialog}
        onMergeUsers={onMergeUsers}
        disableSelectionMode={disableSelectionMode}
      />

      {userAccessEmailDialogIsVisible && (
        <UserAccessEmailLightbox
          dialogVisible={userAccessEmailDialogIsVisible}
          onSave={onSaveUserAccessEmail}
          onCancel={onCancelUserAccessEmail}
          name={userAccessName}
          currentEmail={userAccessEmail}
          roleId={userAccessRoleId}
          hideDialog={hideUserAccessEmailDialog}
        />
      )}

      <ConfirmDialog
        isOpen={Boolean(userDeleteDialogIsOpen)}
        onConfirm={onUserDeleteConfirm}
        onCancel={onUserDeleteCancel}
        title="Delete selected user"
        text={singleDeleteConfirmationMessage}
      />

      <ConfirmDialog
        isOpen={usersBulkDeleteDialogIsOpen}
        onConfirm={onUsersBulkDeleteConfirm}
        onCancel={onUsersBulkDeleteCancel}
        title="Delete selected Users"
        text={bulkDeleteConfirmationMessage}
        disableActions={disableUsersBulkDeleteActions}
      />

      <ProgressLightbox progress={bulkDeleteProgress} />

      <ImportUsersDialog opened={usersImportDialogIsOpen} close={onUsersImportCancel} isRoleAvailable={isRoleAvailable} />

      {usersBulkUpdateDialogIsOpen && (
        <BulkUpdateUsersModal
          selectedItems={selectedItems}
          onClose={onUsersBulkUpdateCancel}
          bulkUpdate={onUsersBulkUpdateConfirm}
          systemFields={systemFields}
          teams={teamsTitles}
          skills={skillsTitles}
          allowAddNewSettings={allowAddNewSettings}
        />
      )}
    </>
  );
};

export default UsersSettingsDialogs;
