import Axios from 'axios';
import undoAction from './undoAction';
import throwRequestError from '../throwRequestError';

import { is, isEmpty, not, pipe, path, equals, head } from 'ramda';

const VALIDATION_ERROR = 'VALIDATION_ERROR';

const isArray = is(Array);
const exist = Boolean;
const isNotEmpty = pipe(isEmpty, not);

const getErrorResponse = path(['response', 'data']);
const getErrorCode = path(['error_code']);
const getErrorsData = path(['_data', 'errors']);
const getFirstMessageFromErrorList = pipe(head, path(['message']));
const hasErrorsList = pipe(exist, isArray, isNotEmpty);

const isValidationError = pipe(getErrorCode, equals(VALIDATION_ERROR));

export default (ACTION_TYPE, endpoint, undoOptions, transform) => {
  return rows => {
    return async dispatch => {
      if (!rows || typeof rows !== 'object') {
        return Promise.reject();
      } else if (!(rows instanceof Array)) {
        rows = [rows];
      }

      if (typeof transform === 'function') {
        rows = rows.map(transform);
      }

      const payload = Axios.put(endpoint, rows)
        .then(res => {
          if (undoOptions) {
            dispatch(
              undoAction(
                `${rows.length} ${undoOptions.toastText}`,
                undoOptions.ACTION_TYPE,
                undoOptions.store,
                undoOptions.endpoint,
              ),
            );
          }
          return res.data;
        })
        .catch(error => {
          const errorResponse = getErrorResponse(error);
          const errorsData = getErrorsData(errorResponse);

          const shouldGetErrorMessageFromErrorsData = isValidationError(errorResponse) && hasErrorsList(errorsData);

          // confirm if there is sequelize error -> more detailed error information
          // if yes - get the message of the first error
          if (shouldGetErrorMessageFromErrorsData) error.message = getFirstMessageFromErrorList(errorsData);

          throwRequestError(error);
        });

      dispatch({
        type: ACTION_TYPE,
        payload,
      });

      return payload;
    };
  };
};
