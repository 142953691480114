import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { defaultTo, equals, isEmpty, isNil } from 'ramda';

import { FeatureFlags } from '@dragonboat/config';
import { DEFAULT_MAPPING_KEY } from 'constants/integrations';

import { IMPORT_COLUMNS } from 'containers/MapImportDataFields/utils';

import useFeatureFlags from 'hooks/useFeatureFlags';

import { getProjectsCustomFields as getProjectsCustomFieldsSelector } from 'store/customFields/selectors';
import {
  getOrganization as getOrganizationSelector,
  getOrganizationSystemFieldsNames as getOrganizationSystemFieldsNamesSelector,
} from 'store/organization/selectors';

const DEFAULT_MAPPING_LABEL = 'Default Field Mapping';
const FIELD_MAPPING_LABEL = 'Field Mapping';
const PROJECTS = 'PROJECTS';

const defaultToNull = defaultTo(null);

const isEntityProjects = equals(PROJECTS);

const useLoadProjectsFieldMapping = props => {
  const { integrationMapping, progressTracking, selectedMappingLayer } = props;

  const organization = useSelector(state => getOrganizationSelector(state));
  const customFields = useSelector(state => getProjectsCustomFieldsSelector(state));
  const systemFields = useSelector(state => getOrganizationSystemFieldsNamesSelector(state));

  const hasMappingByProject = useFeatureFlags([FeatureFlags.HAS_JIRA_FIELD_MAPPING_BY_PROJECT]);

  const dragonboatColumns = useMemo(() => {
    return IMPORT_COLUMNS(organization, customFields, systemFields);
  }, [organization, customFields, systemFields]);

  const projectsFieldMapping = useMemo(() => {
    const filteredMapping = integrationMapping.filter(
      ({ entity, layer }) => isEntityProjects(entity) && layer === selectedMappingLayer,
    );

    if (isEmpty(filteredMapping)) {
      return [{ entity: PROJECTS, layer: selectedMappingLayer, project: DEFAULT_MAPPING_KEY, mapping: [] }];
    }

    return filteredMapping;
  }, [integrationMapping, selectedMappingLayer]);

  const initFieldMapping = useCallback(
    mapping => {
      if (isNil(mapping)) {
        return [];
      }

      return mapping
        .filter(f => dragonboatColumns.some(c => c.field === f.field))
        .map(f => ({
          ...f,
          displayTitle: dragonboatColumns.find(c => c.field === f.field)?.displayTitle,
          col: f.jiraField ? f.jiraField.name : null,
        }));
    },
    [dragonboatColumns],
  );

  const mappings = useMemo(() => {
    const defaultMappingLabel = hasMappingByProject ? DEFAULT_MAPPING_LABEL : FIELD_MAPPING_LABEL;

    return projectsFieldMapping.map(mappingEntry => {
      const { project, mapping } = mappingEntry;

      return {
        key: project,
        title: project === DEFAULT_MAPPING_KEY ? defaultMappingLabel : project,
        fieldsMapping: initFieldMapping(mapping),
        progressTracking: defaultToNull(progressTracking[project]),
      };
    });
  }, [progressTracking, projectsFieldMapping, hasMappingByProject, initFieldMapping]);

  return [mappings];
};

export default useLoadProjectsFieldMapping;
