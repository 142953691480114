/**
 * ProjectLink Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import styled from 'styled-components';
import { linkColor } from 'design-system/themes/default';

export default styled.a`
  cursor: pointer;
  outline: none;
  text-decoration: ${props => (props.clean ? 'none' : 'underlined')};
  display: inline !important;
  color: ${linkColor};

  ${({ withEllipsis }) =>
    withEllipsis &&
    `  
      word-wrap: break-word;
      word-break: break-word;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
  `}
`;
