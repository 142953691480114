import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const IconButton = withStyles(theme => ({
  root: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
  },
}))(MuiIconButton);

export default ({ onClick, className }) => (
  <IconButton aria-label="Close" onClick={onClick} className={className}>
    <CloseIcon />
  </IconButton>
);
