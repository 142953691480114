import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SettingsIcon from '@material-ui/icons/Settings';
import ImportIcon from '@material-ui/icons/CloudUploadOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import { ROADMAP_PAGE } from 'constants/filters';

import { checkRolePermission } from 'containers/UserPermission/utils';

import { getCurrentUser } from 'store/login/selectors';
import { showImportIdeasLightbox } from 'store/ideas';

import useTimelines from 'hooks/useTimelines';

import downloadPNG from 'utils/downloadPNG';

const useMoreDropdownOptions = ({ handleOpenPreferences, isCreatingOrViewingScenario, toggleDraftMode }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);

  const handleShowImportIdeasLightbox = () => dispatch(showImportIdeasLightbox());

  const { lsState, updateState } = useTimelines(ROADMAP_PAGE);

  const { hideEmptyLane } = {
    hideEmptyLane: true,
    ...(lsState || {}),
  };

  return [
    {
      id: 'preferences',
      key: 'preferences',
      onClick: handleOpenPreferences,
      title: (
        <span>
          <SettingsIcon style={{ verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Display preferences</span>
        </span>
      ),
    },
    {
      id: 'import',
      key: 'import',
      title: (
        <span>
          <ImportIcon style={{ verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Import</span>
        </span>
      ),
      hide: !checkRolePermission('IMPORT_IDEAS', currentUser),
      onClick: handleShowImportIdeasLightbox,
    },
    {
      id: 'hide-empty-lane',
      key: 'hide-empty-lane',
      onClick: () => updateState({ hideEmptyLane: !hideEmptyLane }),
      title: (
        <span>
          {hideEmptyLane ? (
            <VisibilityOffIcon style={{ verticalAlign: 'bottom' }} />
          ) : (
            <VisibilityIcon style={{ verticalAlign: 'bottom' }} />
          )}
          <span style={{ paddingLeft: 13 }}>Hide Empty Lane</span>
        </span>
      ),
    },
    {
      id: 'download-png',
      key: 'download-png',
      onClick: downloadPNG,
      title: (
        <span>
          <PhotoCamera style={{ verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Download PNG</span>
        </span>
      ),
    },
  ];
};

export default useMoreDropdownOptions;
