import { path } from 'ramda';

export default (error) => {
  const errorCode = path(['response', 'data', 'error_code'], error);

  if (errorCode && errorCode.includes('not safe')) {
    return "The requested upload didn't pass our security checks. Please try again with a different file.";
  }

  return 'Unable to upload the requested file. Please try again.';
};
