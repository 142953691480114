const DEFAULT_LEVEL = 0;
const CORP_LEVEL = 'CORP';

const getNewMetadataLevel = ({ level: parentLevel, hasCorpLevel = false } = {}) => {
  if (!parentLevel) {
    return hasCorpLevel ? CORP_LEVEL : DEFAULT_LEVEL;
  }
  if (parentLevel === CORP_LEVEL) return DEFAULT_LEVEL;

  return ++parentLevel;
};

export default getNewMetadataLevel;
