import React from 'react';

import Component from './MetricsGridComponent';
import { ProjectsListLightboxProvider } from 'hooks/useProjectsListLightbox';

export default props => (
  <ProjectsListLightboxProvider>
    <Component {...props} />
  </ProjectsListLightboxProvider>
);
