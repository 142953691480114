import React, { useCallback, useEffect, useMemo, useRef, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import ImportIcon from '@material-ui/icons/CloudUploadOutlined';
import isEqual from 'lodash/isEqual';

import DragDrop from 'design-system/organisms/DragDrop/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import GridTable from 'design-system/organisms/GridTable/index';
import ReactTable from 'design-system/organisms/ReactTable/index';

import Cell from './components/Cell';
import ColumnHeader from './components/ColumnHeader';
import RowHeader from './components/RowHeader';
import AddNewColumnOrRowBox, { cellType } from './components/AddNewColumnOrRowBox';
import { getCurrentUser } from 'store/login/selectors';

import useSummaryState from '../../../hooks/useSummaryState';
import { useSummaryTableData } from '../../../hooks/useSummaryTableData';
import { calculateHeadersHeights, convertToTableColumns, convertToTableRows, getCellProjectsByRowAndCol } from '../../../helpers';
import { METADATA_KEY_MAPPER } from '../../../helpers/summaryMetadataKeyMapper';

import { PAGE_HEADER_HEIGHT } from 'constants/common';
import { GROUPS_WITHOUT_LANES_VISIBILITY_TOGGLE } from 'features/PortfolioSummary/Grouping/consts';

const COLUMN_HEIGHT_WITH_ISSUES_COUNT = 80;
const DEFAULT_COLUMN_HEIGHT = 40;
const DEFAULT_WIDTH = 250;

const SCROLL_THRESHOLD_TO_DISPLAY_BORDER = 10;

const Table = memo(
  ({
    rows,
    cols,
    addImportHeader,
    showActionsBar,
    onProjectClick,
    disableAddNewProject,
    onDragEnd,
    onDragStart,
    onCreateProject,
    onAddColumnOrRow,
    onResizeColumn,
    onOpenImport,
    onChangeColumnOrder,
    onChangeRowOrder,
    onToggleGroup,
    slice,
    getInlineGroupTitle,
    fieldsToIgnoreAddColumnRow,
    metadata,
  }) => {
    const summaryTableRef = useRef();

    const [newRow, setNewRow] = useState(false);
    const [newCol, setNewCol] = useState(false);
    const [isScrollingRight, setIsScrollingRight] = useState(false);
    const [isScrollingBottom, setIsScrollingBottom] = useState(false);

    const [headersHeights, setHeadersHeights] = useState({});

    const currentUser = useSelector(getCurrentUser);
    const isAnonymousUser = currentUser.is_anonymous;

    const { isGoalMode } = useSummaryTableData();

    const {
      isReadOnly,
      displayPreferences,
      colsWidth,
      colsOrder,
      rowsOrder,
      headerColor,
      selectedColOption,
      selectedRowOption,
      addNewGrouping,
      removeGrouping,
      userCanToggleLanesVisibility,
      userCanToggleGroupLanesVisibility,
    } = useSummaryState(isGoalMode, slice);

    const pageAllowsAddNewColumnOrRow = userCanToggleLanesVisibility ? !isGoalMode : !isReadOnly;
    const showAddNewColumnOrRow = pageAllowsAddNewColumnOrRow && !isAnonymousUser;

    const { verticalRowHeader } = displayPreferences;

    const tableColumns = useMemo(convertToTableColumns(cols), [cols]);

    const tableRows = useMemo(convertToTableRows(rows, cols, rowsOrder), [rows, cols, rowsOrder]);

    const projectsByRowAndCol = useMemo(() => getCellProjectsByRowAndCol(rows), [rows]);

    const columnHeight = useMemo(() => {
      const showTimeframe = displayPreferences.timeframe && selectedColOption.key === 'timeframe';

      if (showTimeframe && displayPreferences.issueCount) {
        return COLUMN_HEIGHT_WITH_ISSUES_COUNT;
      }

      return DEFAULT_COLUMN_HEIGHT;
    }, [displayPreferences.timeframe, displayPreferences.issueCount, selectedColOption]);

    const updateHeadersHeights = useCallback(() => {
      const newHeadersHeights = calculateHeadersHeights(rows, summaryTableRef);

      if (!isEqual(newHeadersHeights, headersHeights)) {
        setHeadersHeights(newHeadersHeights);
      }
    }, [rows, summaryTableRef.current, headersHeights, setHeadersHeights]);

    const _handleOpenNewCol = useCallback(() => {
      setNewCol(true);
    }, [selectedColOption]);

    const _handleOpenNewRow = useCallback(() => {
      setNewRow(true);
    }, [selectedRowOption]);

    const _handleCloseAddColumnOrRow = useCallback(type => {
      if (type === cellType.COL) {
        setNewCol(false);
      } else {
        setNewRow(false);
      }
    }, []);

    const _handleAddColumnAndRow = useCallback(
      async (form, type) => {
        await onAddColumnOrRow(form, type);
        _handleCloseAddColumnOrRow(type);
      },
      [onAddColumnOrRow],
    );

    const _handleAddGrouping = (metadataEntityId, type) => addNewGrouping(metadataEntityId, METADATA_KEY_MAPPER[type] || type);

    const _handleRemoveGrouping = (metadataEntityId, type) => removeGrouping(metadataEntityId, METADATA_KEY_MAPPER[type] || type);

    const allProjectsInfo = useMemo(() => {
      return rows
        ? rows.flatMap(row => {
            return row.values.map(v => ({
              data: v.project,
              metadata: {
                row,
                col: v.col,
                group: v.group?.[0] ?? null,
              },
            }));
          })
        : [];
    }, [rows]);

    const _getCellWidth = (colId, defaultWidth = DEFAULT_WIDTH) =>
      colsWidth && colsWidth[colId] ? parseInt(colsWidth[colId], 10) || defaultWidth : defaultWidth;

    const _getColumnWidth = colId => {
      if (colId === 'auto')
        return colsWidth && colsWidth[colId] ? parseInt(colsWidth[colId], 10) || DEFAULT_WIDTH : DEFAULT_WIDTH;

      return colsWidth && colsWidth[colId] ? parseInt(colsWidth[colId], 10) || DEFAULT_WIDTH : DEFAULT_WIDTH;
    };

    const _renderTableColHeaderCell = params => {
      if (!params) return '';

      const { col, isHover } = params;

      if (!col && addImportHeader) {
        return (
          <GridTable.HeaderTitle key="null" component="div">
            <ImportHeader id="port-3d-import" onClick={onOpenImport}>
              <ImportIcon />
              <ImportHeaderTitle>From spreadsheet, Jira or Azure</ImportHeaderTitle>
            </ImportHeader>
          </GridTable.HeaderTitle>
        );
      }

      return col ? (
        <ColumnHeader
          key={col.id}
          col={col}
          isReadOnly={isReadOnly}
          selectedColData={selectedColOption}
          overallProgress={displayPreferences.overallProgress}
          shouldDisplayIssueCount={displayPreferences.issueCount}
          addBorderBottom={isScrollingBottom}
          onVisilityIconClick={userCanToggleGroupLanesVisibility(selectedColOption?.key) ? _handleRemoveGrouping : null}
          isHover={isHover}
        />
      ) : (
        ''
      );
    };

    const _renderTableRowHeaderCell = row => {
      if (!row) return '';

      const calculatedMaxHeight = headersHeights[row.id];

      return (
        <RowHeader
          row={row}
          isReadOnly={isReadOnly}
          selectedRowData={selectedRowOption}
          maxHeight={calculatedMaxHeight}
          slice={slice}
          addBorderRight={isScrollingRight}
          onVisilityIconClick={userCanToggleGroupLanesVisibility(selectedRowOption?.key) ? _handleRemoveGrouping : null}
          absoluted
        />
      );
    };

    const _renderTableCell = (params, _) => {
      if (!params) return '';

      const { row, col } = params;

      const cellProjects = projectsByRowAndCol[row.id]?.[col?.id] ?? [];

      return row && col ? (
        <GridTable.Cell
          key={`${row.id}-${col.id}`}
          rowId={row.id}
          colId={col && col.id}
          verticalRowHeader={verticalRowHeader}
          width={_getCellWidth(col.id)}
          verticalAlign="middle"
        >
          <Cell
            row={row}
            col={col}
            cellProjects={cellProjects}
            onToggleGroup={onToggleGroup}
            disableAdd={disableAddNewProject}
            onCreateProject={onCreateProject}
            onProjectClick={onProjectClick}
            allProjectsInfo={allProjectsInfo}
            slice={slice}
            getInlineGroupTitle={getInlineGroupTitle}
          />
        </GridTable.Cell>
      ) : (
        _renderTableRowHeaderCell(row)
      );
    };

    useEffect(() => {
      if (summaryTableRef.current) {
        summaryTableRef.current.addEventListener('transitionend', updateHeadersHeights);

        updateHeadersHeights();
      }

      return () => {
        summaryTableRef.current?.removeEventListener('transitionend', updateHeadersHeights);
      };
    }, [summaryTableRef.current, calculateHeadersHeights, isReadOnly, headersHeights]);

    if (!rows || !cols) return <TextDeprecated>There is no data to show</TextDeprecated>;

    const handleScroll = event => {
      const { scrollLeft, scrollTop } = event.target;

      if (scrollLeft > SCROLL_THRESHOLD_TO_DISPLAY_BORDER) {
        setIsScrollingRight(true);
      } else {
        setIsScrollingRight(false);
      }

      if (scrollTop > SCROLL_THRESHOLD_TO_DISPLAY_BORDER) {
        setIsScrollingBottom(true);
      } else {
        setIsScrollingBottom(false);
      }
    };

    return (
      <SummaryTableContainer onScroll={handleScroll} id="summary-board-container" showActionsBar={showActionsBar}>
        <SummaryTableWrapper>
          <DragDrop onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <ReactTable
              ref={summaryTableRef}
              columns={tableColumns}
              data={tableRows}
              colsOrder={colsOrder}
              columnColor={headerColor.column}
              columnHeight={columnHeight}
              getColumnWidth={_getColumnWidth}
              renderCell={_renderTableCell}
              renderHeader={_renderTableColHeaderCell}
              resizeColumnStop={onResizeColumn}
              changeColumnOrder={onChangeColumnOrder}
              changeRowOrder={onChangeRowOrder}
              isScrollingRight={isScrollingRight}
              isScrollingBottom={isScrollingBottom}
              managedHeight
            />
          </DragDrop>
          {showAddNewColumnOrRow && (
            <AddNewColumnOrRowBox
              selectedOption={selectedColOption}
              type={cellType.COL}
              isCardOpen={newCol}
              fieldsToIgnoreAddColumnRow={fieldsToIgnoreAddColumnRow}
              fieldsToIgnoreLinkColumnRow={GROUPS_WITHOUT_LANES_VISIBILITY_TOGGLE}
              handleAdd={_handleOpenNewCol}
              handleSave={_handleAddColumnAndRow}
              handleClose={_handleCloseAddColumnOrRow}
              handleLinkGroup={_handleAddGrouping}
              currentlyVisibleMetadata={cols}
              metadata={metadata}
            />
          )}
        </SummaryTableWrapper>
        {showAddNewColumnOrRow && (
          <AddNewColumnOrRowBox
            selectedOption={selectedRowOption}
            type={cellType.ROW}
            isCardOpen={newRow}
            fieldsToIgnoreAddColumnRow={fieldsToIgnoreAddColumnRow}
            fieldsToIgnoreLinkColumnRow={GROUPS_WITHOUT_LANES_VISIBILITY_TOGGLE}
            handleAdd={_handleOpenNewRow}
            handleSave={_handleAddColumnAndRow}
            handleClose={_handleCloseAddColumnOrRow}
            handleLinkGroup={_handleAddGrouping}
            currentlyVisibleMetadata={rows}
            metadata={metadata}
          />
        )}
      </SummaryTableContainer>
    );
  },
);

Table.displayName = 'SummaryTable';

export default Table;

const SummaryTableWrapper = styled.div`
  &&&& {
    display: flex;
  }
`;

const SummaryTableContainer = styled.div`
  background: ${({ theme }) => theme.palette.white};
  overflow: auto;
  max-height: calc(100vh - ${PAGE_HEADER_HEIGHT}px - 60px);
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 800;

  ${({ showActionsBar }) => {
    return !showActionsBar && 'margin-top: 48px;';
  }}
`;

const ImportHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ImportHeaderTitle = styled.div`
  padding: 6px 4px 7px;
  font-weight: normal;
  color: ${({ theme }) => theme.palette.background.primary};
`;
