import React from 'react';

import GoalsAllocation from 'features/GoalsAllocation';
import AllocationBySelector from './AllocationBySelector';

import { ALLOCATION_REPORT_PAGE } from 'constants/filters';

import useLoadProjects from 'hooks/projects/useLoadProjects';

const ActualAllocationReportComponent = ({ lsState, groupOptions, onChangeGroupBy, shouldDisplayControlsBar }) => {
  // Load projects for applied filter - hidden side effect: show results count on header
  useLoadProjects(ALLOCATION_REPORT_PAGE, undefined, undefined, {}, {}, false);

  return (
    <GoalsAllocation
      lsState={lsState}
      pageTitleRenderer={() => (
        <AllocationBySelector
          lsState={lsState}
          groupByOptions={groupOptions}
          onChangeGroupBy={onChangeGroupBy}
          disabled={!shouldDisplayControlsBar}
        />
      )}
    />
  );
};

export default ActualAllocationReportComponent;
