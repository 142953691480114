import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getCategories } from 'store/categories/selectors';
import { getActiveCustomers } from 'store/customers/selectors';
import { getPageFilters } from 'store/filters/selectors';
import { selectKeyResults1, getObjectives, selectKeyResults2 } from 'store/objectives/selectors';
import { getMetrics } from 'store/metrics/selectors';
import { getPhases } from 'store/phases/selectors';
import { getPriorities } from 'store/priorities/selectors';
import { getAllInitiatives } from 'store/projects/selectors';
import { getRoadmaps, getCorpRoadmaps } from 'store/roadmaps/selectors';
import { getTags } from 'store/tags/selectors';
import { getTeams } from 'store/teams/selectors';
import { getThemes } from 'store/themes/selectors';
import { getTimeframes, getTimeframesLevel2, getTimeframesLevelCorp } from 'store/timeframes/selectors';
import { getUsers, getUsersWithName } from 'store/users/selectors';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

import { filterMetadataOptions } from 'utils/metadataRoadmaps';

import { METADATA_LEVELS } from 'constants/common';
import { selectPersonasData } from 'features/Personas/store/selectors';
import { selectLifecyclesData } from 'features/Lifecycles/store/selectors';

export default (data, organization) => {
  const pageFilters = useSelector(getPageFilters);
  const { quickFilters } = pageFilters;
  const { enable_metadata_roadmaps: hasMetadataRoadmaps } = organization;
  const { isDodActive, getDefaultRoadmapForMetadataItem } = useOrganizationsAccessControl();

  const roadmapsCorp = useSelector(state => getCorpRoadmaps(state, false));
  const roadmaps = useSelector(getRoadmaps) ?? [];

  const categories = useSelector(state => getCategories(state, true));
  const priorities = useSelector(state => getPriorities(state, true));
  const themes = useSelector(state => getThemes(state, true));
  const objectives = useSelector(state => getObjectives(state, true));
  const keyResults1 = useSelector(state => selectKeyResults1(state, true));
  const keyResults2 = useSelector(state => selectKeyResults2(state, true));
  const objectivesCorp = useSelector(state => getObjectives(state, true, METADATA_LEVELS.LEVEL_CORP));
  const timeframesCorp = useSelector(state => getTimeframesLevelCorp(state, true));
  const customers = useSelector(getActiveCustomers);
  const owners = useSelector(state => getUsersWithName(state));
  const tags = useSelector(getTags);
  const teams = useSelector(state => getTeams(state, false));
  const timeframes = useSelector(getTimeframes);
  const timeframes2 = useSelector(getTimeframesLevel2);
  const users = useSelector(getUsers);
  const phases = useSelector(getPhases);
  const parents = useSelector(getAllInitiatives);
  const metrics = useSelector(getMetrics);
  const personas = useSelector(selectPersonasData);
  const lifecycles = useSelector(selectLifecyclesData);

  const metadata = useMemo(
    () => ({
      categories,
      priorities,
      themes,
      objectives,
      keyResults1,
      keyResults2,
      objectivesCorp,
      roadmaps,
      roadmapsCorp,
      timeframesCorp,
      customers,
      owners,
      tags,
      teams,
      timeframes,
      timeframes2,
      users,
      phases,
      parents,
      metrics,
      personas,
      lifecycles,
    }),
    [
      categories,
      priorities,
      themes,
      objectives,
      keyResults1,
      keyResults2,
      objectivesCorp,
      roadmaps,
      roadmapsCorp,
      timeframesCorp,
      customers,
      owners,
      tags,
      teams,
      timeframes,
      timeframes2,
      users,
      phases,
      parents,
      metrics,
      personas,
      lifecycles,
    ],
  );

  const filteredMetadata = useMemo(() => {
    const dataRows = data.filter(item => !item.group);

    // todo: address this idDoDActive when working on OKRs
    return filterMetadataOptions(hasMetadataRoadmaps, dataRows, metadata, quickFilters, {
      isDodActive,
      getDefaultRoadmapForMetadataItem,
    });
  }, [data, hasMetadataRoadmaps, metadata, quickFilters, isDodActive, getDefaultRoadmapForMetadataItem]);

  if (!Object.keys(quickFilters || {}).length || !hasMetadataRoadmaps) {
    return metadata;
  }

  return filteredMetadata;
};
