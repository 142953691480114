import React from 'react';

import DoughnutChart from 'components/ReactChart/DoughnutChart';
import getLegendBoxConfig from 'utils/charts/getLegendBoxConfig';
import ChartWrapper from 'src/components/ReactChart/utils/ChartWrapper';

const defaultOptions = {
  plugins: {
    legend: {
      position: 'bottom',
      display: true,
      labels: {
        ...getLegendBoxConfig(),
      },
    },
  },
  animation: {
    duration: 0,
  },
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 2,
};

const ITEM_SIZE = 10;
const MIN_HEIGHT = 230;

const usePieChart = ({ generateData, options = {}, plugins = [], setRef, minHeight = MIN_HEIGHT }) => {
  const chartOptions = React.useMemo(
    () => ({
      ...defaultOptions,
      ...options,
    }),
    [options],
  );

  const chartData = React.useMemo(() => generateData(), [generateData]);

  if (!Array.isArray(chartData?.datasets)) return '';

  const height = (chartData?.labels?.length || 0) * ITEM_SIZE;

  if (height === 0) return '';

  const Chart = (
    <ChartWrapper height={minHeight}>
      <DoughnutChart
        setRef={setRef}
        data={{
          labels: chartData.labels,
          datasets: chartData.datasets,
        }}
        options={chartOptions}
        plugins={plugins}
      />
    </ChartWrapper>
  );

  return Chart;
};

export default usePieChart;
