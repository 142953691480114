/**
 * Autocomplete Molecule Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { PureComponent, Fragment } from 'react';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import isEqual from 'lodash/isEqual';
import Autosuggest from 'react-autosuggest';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import isFunction from 'lodash/isFunction';
import deburr from 'lodash/deburr';

import Loading from '../Loading';

import themeGeneral from '../../theme';

const exist = Boolean;

const SUGGESTION_LINE_MIN_HEIGHT = 24;

const SUGGESTIONS_CONTAINER_MAX_HEIGHT = 264;

const LOADING_CONTAINER_HEIGHT = 200;

const SUGGESTION_LINE_Y_PADDING = 5;

const CONTAINER_Y_PADDING = 8;

/*
 * Autosuggest lib call 'onChange' when the user navigates in the list with the Up/Down arrows
 * Since we want to have the same UX as other software (such as Jira) and keep the arrow navigation just as navigation we
 * will should only execute the update value if the user action was either typing (to search/filter options),
 * click with mouse or press enter on an option
 * ref: https://www.npmjs.com/package/react-autosuggest#getsuggestionvalue-required
 */
const methodsThatShouldUpdateValue = ['type', 'click', 'enter'];

function renderInput(inputProps) {
  const { ref, autoFocus, value, setRefInput, onIconClick, placeholder, classes, icon, color, ...other } = inputProps;

  const Component = this.props.inputComponent ? this.props.inputComponent : AutocompleteTextField;

  const hasMultiline = exist(this.props.multilineRows);

  return (
    <Fragment>
      <Component
        labelPosition={this.props.labelPosition}
        value={value}
        label={this.props.label}
        disabled={this.props.disabled}
        required={this.props.required}
        margin={this.props.margin || 'none'}
        onKeyPress={this.props.onKeyPress}
        onBlur={this.props.onBlur}
        placeholder={placeholder}
        variant={this.props.variant}
        inputRef={input => {
          ref(input);
          setRefInput(input);
        }}
        fullWidth={!!this.props.fullWidth}
        style={this.props.style || {}}
        isOnGrid={this.props.isOnGrid}
        hideBottomBorder={this.props.hideBottomBorder}
        InputProps={{
          autoFocus,
          readOnly: this.props.readonly,
          classes,
          ...other,
        }}
        inputProps={{
          autoComplete: 'off',
          color,
        }}
        InputLabelProps={{
          shrink: other.shrink,
        }}
        {...other}
        multiline={hasMultiline}
        rowsMax={hasMultiline && this.props.multilineRows}
        onKeyDown={this.props.onKeyDown}
      />
      <InputIcon
        className="autocomplete-input-icon"
        hasLabel={!!this.props.label}
        onClick={onIconClick}
        style={{ display: this.props.hideIcon && 'none', ...(this.props.iconStyle ?? {}) }}
      >
        {icon || <ExpandMoreIcon />}
      </InputIcon>
    </Fragment>
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const { highlightOnlyFullMatch = false, checkSuggestionDisabled, suggestionStyles } = this.props;
  const label = getSuggestionValue(suggestion, true);
  const matches = match(label, query);
  const parts = parse(label, matches);

  const renderSuggestionContent = () => {
    if (highlightOnlyFullMatch) {
      if (label === query) {
        return <strong>{label}</strong>;
      }
      return <span>{label}</span>;
    }

    return parts.map((part, index) => {
      return part.highlight || (suggestion && suggestion.new) ? (
        <strong key={part.text}>{part.text}</strong>
      ) : (
        <span key={part.text}>{part.text}</span>
      );
    });
  };

  return (
    <StyledMenuItem
      theme={themeGeneral} // Theme is being overwritten with a new object on AGgrid
      selected={isHighlighted}
      isCurrentValue={query === label}
      component="div"
      disabled={checkSuggestionDisabled && checkSuggestionDisabled(suggestion)}
      style={{
        whiteSpace: 'normal',
        minHeight: `${SUGGESTION_LINE_MIN_HEIGHT}px`,
        height: 'initial',
        fontSize: this.props.isOnGrid && '0.8125rem',
        ...(suggestionStyles ? suggestionStyles(suggestion) : {}),
      }}
    >
      {this.props.prependSuggestion !== undefined && this.props.prependSuggestion(suggestion)}
      <div>{renderSuggestionContent()}</div>
      {this.props.suffixSuggestion !== undefined && this.props.suffixSuggestion(suggestion)}
    </StyledMenuItem>
  );
}

function getSuggestionValue(suggestion, onRender) {
  const _getResult = () => {
    if (suggestion && suggestion.new && !onRender) {
      return suggestion.value || '';
    }

    if (typeof suggestion === 'string') {
      return suggestion;
    }

    if (!suggestion || typeof suggestion !== 'object') {
      return '';
    }

    return suggestion.label || suggestion.title || '';
  };
  const result = _getResult();

  return result || '';
}

export default class Autocomplete extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      selected: false,
      value: props.value || '',
    };
    this.renderInput = renderInput.bind(this);
    this.renderSuggestion = renderSuggestion.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.setRefInput = this.setRefInput.bind(this);
    this.focusInput = this.focusInput.bind(this);
  }

  componentDidUpdate = prevProps => {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    } else if (!isEqual(prevProps.suggestions, this.props.suggestions)) {
      this.setState({
        suggestions: this.getSuggestions(),
      });
    }
  };

  focusInput() {
    if (this.input) {
      this.input.focus();
    }
  }

  setRefInput(input) {
    const { autoFocus, onBlur, charPress } = this.props;

    this.input = input;

    if (input && autoFocus) {
      setTimeout(() => {
        input.focus();
        input.select();

        if (charPress) {
          this.setState({ value: charPress });
        }
      }, 0);
    }

    if (onBlur && input) {
      input.addEventListener('blur', this.props.onBlur);
    }
  }

  getSuggestions = value => {
    const inputValue = value ? value.trim().toLowerCase() : '';
    const inputLength = inputValue.length;
    const suggestions = isFunction(this.props.suggestions) ? this.props.suggestions() : this.props.suggestions;
    let count = 0;

    if (this.props.readonly) {
      return [];
    }

    if (inputLength === 0) {
      return suggestions;
    }

    const result =
      inputLength === 0 || !suggestions
        ? []
        : suggestions.filter(suggestion => {
            const label = getSuggestionValue(suggestion);

            const keep =
              count < 5 && inputLength >= 3
                ? deburr(label.toLowerCase()).includes(deburr(inputValue.replace(/ +/gi, ' ')))
                : count < 5 && deburr(label.toLowerCase().slice(0, inputLength)) === deburr(inputValue.replace(/ +/gi, ' '));

            if (keep) {
              count += 1;
            }

            return keep;
          });

    return !result.length ? [] : result;
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    // this.setState({
    //   suggestions: [],
    // });
  };

  handleChange = (event, { newValue, method }) => {
    if (!methodsThatShouldUpdateValue.includes(method)) return;
    this.props.handleChange && this.props.handleChange(newValue);
    this.setState({ value: newValue });

    if (!newValue) {
      this.props.onValueChange && this.props.onValueChange(null);
    }
  };

  shouldRenderSuggestions = () => {
    return true;
  };

  onSuggestionSelected(event, { suggestion }) {
    event.stopPropagation();
    const { onSuggestionSelected, onValueChange, checkSuggestionDisabled } = this.props;

    if (checkSuggestionDisabled && checkSuggestionDisabled(suggestion)) {
      this.setState({ value: this.props.value });
      return;
    }

    this.input.focus();
    this.setState({ selected: true });

    if (onSuggestionSelected && typeof onSuggestionSelected === 'function') {
      onSuggestionSelected(suggestion);
    }

    onValueChange &&
      onValueChange(
        suggestion && typeof suggestion === 'object' ? suggestion.value || suggestion.inputVal : suggestion,
        suggestion,
      );

    this.focusInput();
  }

  onFocus = event => {
    setTimeout(() => this.setState({ suggestions: this.getSuggestions() }));

    if (this.props.onFocus) this.props.onFocus();
  };

  onBlur = event => {
    setTimeout(() => {
      const value = this.state.selected ? this.state.value : this.props.value;

      this.setState({ value, suggestions: this.getSuggestions(value), selected: false });
    });
  };

  getInputValue = () => {
    const { value } = this.state;

    return value || '';
  };

  shouldAddEmptyOption = () => {
    const { addNew } = this.props;

    const { suggestions } = this.state;

    const inputVal = this.getInputValue();

    return (inputVal &&
    typeof inputVal === 'string' &&
    addNew && !suggestions.some(
      s =>
        getSuggestionValue(s).trim().replace(/ +/gi, ' ').toLowerCase() === inputVal.trim().replace(/ +/gi, ' ').toLowerCase(),
    ));
  };

  calculateTop = inputCoords => {
    const { suggestionsTopOffset = 0 } = this.props;

    const { suggestions } = this.state;

    const numberOfSuggestions = suggestions.length + (this.shouldAddEmptyOption() ? 1 : 0);

    // prettier-ignore
    const suggestionEntryHeight = SUGGESTION_LINE_MIN_HEIGHT + (2 * SUGGESTION_LINE_Y_PADDING);

    // prettier-ignore
    const suggestionsEntriesTotalHeight = (numberOfSuggestions * suggestionEntryHeight) + (2 * CONTAINER_Y_PADDING);

    const preCalculatedContainerHeight = this.props.isLoading
      ? LOADING_CONTAINER_HEIGHT
      : Math.min(suggestionsEntriesTotalHeight, SUGGESTIONS_CONTAINER_MAX_HEIGHT);

    const defaultContainerTopPosition = inputCoords.top + inputCoords.height + suggestionsTopOffset + window.scrollY;

    const containerWouldBeOutOfBounds = defaultContainerTopPosition + preCalculatedContainerHeight > window.innerHeight;

    const upwardsTopPosition = inputCoords.top - suggestionsTopOffset - preCalculatedContainerHeight;

    return containerWouldBeOutOfBounds ? upwardsTopPosition : defaultContainerTopPosition;
  };

  renderSuggestionsContainer = ({ containerProps, children }) => {
    if (this.input) {
      // this.input is the input ref as received from Autosuggest
      const inputCoords = this.input.getBoundingClientRect();

      const top = this.calculateTop(inputCoords);

      const style = {
        position: 'absolute',
        left: inputCoords.left + window.scrollX, // adding scrollX and scrollY to get the coords wrt document instead of viewport
        top,
        maxHeight: `${SUGGESTIONS_CONTAINER_MAX_HEIGHT}px`,
        overflow: 'auto',
        zIndex: 1000000,
        backgroundColor: '#ffffff',
        borderRadius: '4px',
        boxShadow: '0 4px 16px 0 rgba(0,0,0,.15), 0 1px 2px 0 rgba(0,0,0,.07), 0 0 1px 0 rgba(0,0,0,.2)',
        minHeight: this.props.isLoading ? `${LOADING_CONTAINER_HEIGHT}px` : 0,
        width: 'auto',
        maxWidth: '500px',
        minWidth: inputCoords.width,
        ...(this.props.suggestionsContainerStyle ?? {}),
      };

      const toRender = (
        <StyledOptionsList {...containerProps} className={`${containerProps.className} autocomplete-portal`} style={style}>
          {this.props.isLoading ? <StyledLoading /> : children}
        </StyledOptionsList>
      );

      return ReactDOM.createPortal(toRender, document.body);
    }

    return null;
  };

  render() {
    const { classes, placeholder, endAdornment, shrink, isOnGrid, size, className, color, startAdornment } = this.props;
    const { suggestions } = this.state;

    const theme = {
      container: {
        flexGrow: 1,
        position: 'relative',
      },
      suggestionsContainerOpen: {
        position: 'absolute',
        marginBottom: '15px',
        left: 0,
        right: 0,
        maxHeight: '200px',
        zIndex: 10000,
        overflow: 'auto',
        backgroundColor: 'white',
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        width: isOnGrid ? 240 : 'auto',
      },
      suggestion: {
        display: 'block',
      },
      suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
      },
    };

    const inputVal = this.getInputValue();
    const inputSuggestions = [...suggestions];

    if (this.shouldAddEmptyOption()) {
      const newSuggestion = {
        title: `Create "${inputVal}"`,
        inputVal,
        new: true,
      };

      inputSuggestions.push(newSuggestion);
    }

    return (
      <div className={className} ref={this.associateContainerRef}>
        <Autosuggest
          theme={theme}
          renderInputComponent={this.renderInput}
          suggestions={inputSuggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          renderSuggestion={this.renderSuggestion}
          onSuggestionSelected={this.onSuggestionSelected}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          inputProps={{
            onBlur: this.onBlur,
            onFocus: this.onFocus,
            classes,
            value: inputVal,
            onChange: this.handleChange,
            setRefInput: this.setRefInput,
            name: this.props.name,
            onIconClick: () => this.input.focus(),
            placeholder,
            endAdornment,
            shrink,
            autoComplete: 'off',
            size,
            color,
            icon: this.props.icon,
            startAdornment,
            ...this.props.inputProps,
          }}
        />
      </div>
    );
  }
}

const InputIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding-top: ${({ hasLabel }) => (hasLabel ? '20px' : '4px')};
  padding-bottom: 2px;
  background: transparent;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: hsl(0, 0%, 40%);

  &:hover {
    cursor: text;
  }
`;

const AutocompleteTextField = styled(TextField)`
  width: 100%;
  color: red;

  ${props =>
    props.hideBottomBorder &&
    css`
      > div::before {
        border-bottom: 0 !important;
      }

      > div::after {
        border-bottom: 0 !important;
      }

      > div > div::focus {
        background-color: transparent;
      }
    `}
  ${props =>
    props.multiline &&
    css`
      &&& textarea {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: ${props.rowsMax};
        overflow: hidden;
        word-wrap: break-word;
      }
    `}
    &&& input {
    ${props =>
      props.size === '2x' &&
      css`
        min-width: calc(160px * 2);
      `}
    font-size: ${props => props.isOnGrid && '0.8125rem'};
    padding: ${props => props.isOnGrid && '0.8125rem 0.4rem'};

    ${props => props.inputProps?.color && `color: ${props.inputProps.color};`}
    ${props =>
      props.useEllipsis &&
      css`
        margin-right: 30px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      `}
        &::placeholder {
      opacity: 1;
      color: ${({ theme }) => theme?.palette?.text?.disabled};
    }
  }
`;

const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: prop => prop !== 'isCurrentValue',
})`
  &&&&& {
    padding: ${SUGGESTION_LINE_Y_PADDING}px 15px;
    font-family: ${({ theme }) => theme?.typography?.fontFamily};
    font-size: ${({ theme }) => theme?.typography?.fontSize}px;
    color: ${({ theme }) => theme?.palette?.text?.lightGrey};
    ${({ isCurrentValue, theme }) =>
      isCurrentValue ? `background-color: ${theme?.palette?.newLayout?.background?.lighterBlack};` : ''};

    &:hover {
      background-color: ${({ theme, disabled }) =>
        !disabled ? theme?.palette?.newLayout?.background?.lighterBlack : 'transparent'};
    }

    ${({ disabled, theme }) => disabled && `color: ${theme.palette.text.disabled};`}
    & strong {
      font-weight: bold;
    }
  }
`;

const StyledOptionsList = styled.div`
  &&&&& {
    & > * {
      padding: ${CONTAINER_Y_PADDING}px 0 !important;
    }
  }
`;

const StyledLoading = styled(Loading)`
  &&&& {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
  }
`;
