import pluralize from 'pluralize';

import EpicGoalDoughnutChart from '../Charts/EpicGoalDoughnutChart';
import DetailsTable from '../DetailsTable';
import InitiativeOkrPieChart from '../Charts/InitiativeOkrPieChart';
import InitiativeRoadmapPieChart from '../Charts/InitiativeRoadmapPieChart';
import InitiativeProgressByRoadmapStackedChart from '../Charts/InitiativeProgressByRoadmapStackedChart';
import InitiativeHealthByRoadmapStackedChart from '../Charts/InitiativeHealthByRoadmapStackedChart';
import PlannedAllocationByRoadmap from '../Charts/PlannedAllocationByRoadmap';
import ReportedAllocationByRoadmap from '../Charts/ReportedAllocationByRoadmap';
import { INITIATIVE_LAYER } from 'src/store/projects/constants';
import GoalsProgressStackedChart from '../Charts/GoalsProgressStackedChart/GoalsProgressStackedChart';
import PortfolioDependenciesDelayTotals from '../Charts/PortfolioDependenciesDelayTotals';
import PortfolioDependenciesByTeamTableChart from '../Charts/PortfolioDependenciesByTeamTableChart';
import PortfolioDependenciesBetweenTeams from '../Charts/PortfolioDependenciesBetweenTeams';
import ConfigurableChart from '../Charts/ConfigurableChart';

// eslint-disable-next-line max-len
import IdeasWithGoalsProgressByTimeframeChart from '../Charts/IdeasWithGoalsProgressByTimeframeChart/IdeasWithGoalsProgressByTimeframeChart';
import RoadmapByOkrStackedChart from '../Charts/RoadmapByOkrStackedChart/RoadmapByOkrStackedChart';
import ScenariosBenefitsComparisonChart from '../Charts/ScenariosBenefitsComparisonChart/ScenariosBenefitsComparisonChart';
// eslint-disable-next-line max-len
import ScenariosAccumulativeBenefitsComparisonChart from '../Charts/ScenariosAccumulativeBenefitsComparisonChart/ScenariosAccumulativeBenefitsComparisonChart';
import ScenarioStackedBenefitChart from '../Charts/ScenarioStackedBenefitChart/ScenarioStackedBenefitChart';
import ScenarioUnplannedResourcesChart from '../Charts/ScenarioUnplannedResourcesChart/ScenarioUnplannedResourcesChart';
import ProgressSummaryWidget from '../Charts/ProgressSummaryWidget';
import LinkedIssuesSummaryWidget from '../Charts/LinkedIssuesSummaryWidget';
import ProgressByRoadmapExpectedChart from '../Charts/ProgressByRoadmapExpectedChart/ProgressByRoadmapExpectedChart';

const DETAILS_LIST = {
  key: 'detailsList',
  type: 'table',
  component: DetailsTable,
  size: 12,
};

const DETAILS_LIST_FOR_INITIATIVES = {
  ...DETAILS_LIST,
  props: {
    layer: INITIATIVE_LAYER,
  },
};

export const DELIVERY_PROGRESS_SUMMARY = {
  id: 'progressSummaryWidget',
  type: 'widget',
  component: ProgressSummaryWidget,
};

export const DELIVERY_LINKED_ISSUES_SUMMARY = {
  id: 'linkedIssuesSummaryWidget',
  type: 'widget',
  component: LinkedIssuesSummaryWidget,
};

const EPIC_ROADMAP_CHART = {
  id: 'epicRoadmapPieChart',
  type: 'chart',
  component: ConfigurableChart,
  title: getSystemFieldName => layer => `${pluralize(getSystemFieldName(layer))}`,
  subtitle: getSystemFieldName => `by ${getSystemFieldName('roadmap')}`,
  props: {
    selectedChart: 'pie-chart',
    groupBy: 'roadmap',
    stackBy: null,
    chartType: 'pie',
  },
};

const EPIC_GOAL_CHART = {
  id: 'epicGoalPieChart',
  type: 'chart',
  component: EpicGoalDoughnutChart,
  title: getSystemFieldName => layer => `${pluralize(getSystemFieldName(layer))}`,
  subtitle: getSystemFieldName => `by ${getSystemFieldName('objective')}`,
};

const PROGRESS_ROADMAP_CHART = {
  id: 'progressByRoadmapStackedChart',
  type: 'chart',
  component: ConfigurableChart,
  props: {
    selectedChart: 'horizontal-stacked-chart',
    groupBy: 'roadmap',
    stackBy: 'planningStage',
    chartType: 'bar',
    horizontal: true,
  },
  title: `Planning stage`,
  subtitle: getSystemFieldName => `by ${getSystemFieldName('roadmap')}`,
};

const PROGRESS_BY_ROADMAP_WITH_EXPECTED_CHART = {
  id: 'progressByRoadmapWithExpectedChart',
  type: 'chart',
  component: ProgressByRoadmapExpectedChart,
  title: `Progress`,
  subtitle: getSystemFieldName => `by ${getSystemFieldName('roadmap')}`,
};

const HEALTH_ROADMAP_CHART = {
  id: 'healthByRoadmapStackedChart',
  type: 'chart',
  component: ConfigurableChart,
  props: {
    selectedChart: 'horizontal-stacked-chart',
    groupBy: 'roadmap',
    stackBy: 'health',
    chartType: 'bar',
    horizontal: true,
  },
  title: `Delivery Health`,
  subtitle: getSystemFieldName => `by ${getSystemFieldName('roadmap')}`,
};

const OKR_BY_ROADMAP_STACKED_CHART = {
  id: 'OkrByRoadmapStackedChart',
  type: 'chart',
  component: ConfigurableChart,
  props: {
    selectedChart: 'vertical-stacked-chart',
    groupBy: 'roadmap',
    stackBy: 'objective',
    chartType: 'bar',
  },
  title: getSystemFieldName => `${pluralize(getSystemFieldName('objective'))}`,
  subtitle: getSystemFieldName => `by ${getSystemFieldName('roadmap')}`,
};

const INITIATIVE_OKR_CHART = {
  id: 'initiativeOkrPieChart',
  type: 'chart',
  component: InitiativeOkrPieChart,
  title: getSystemFieldName => `${getSystemFieldName('initiative')}`,
  subtitle: getSystemFieldName => `by ${getSystemFieldName('objective')}`,
};

const INITIATIVE_ROADMAP_CHART = {
  id: 'initiativeRoadmapPieChart',
  type: 'chart',
  component: InitiativeRoadmapPieChart,
  title: getSystemFieldName => `${getSystemFieldName('initiative')}`,
  subtitle: getSystemFieldName => `by ${getSystemFieldName('roadmap')}`,
};

const INITIATIVE_HEALTH_BY_ROADMAP_CHART = {
  id: 'initiativeHealthByRoadmapStackedChart',
  type: 'chart',
  component: InitiativeHealthByRoadmapStackedChart,
  title: getSystemFieldName => `${getSystemFieldName('initiative')} Health`,
  subtitle: getSystemFieldName => `by ${getSystemFieldName('roadmap')}`,
};

const INITIATIVE_PROGRESS_BY_ROADMAP_CHART = {
  id: 'initiativeProgressByRoadmapStackedChart',
  type: 'chart',
  component: InitiativeProgressByRoadmapStackedChart,
  title: getSystemFieldName => `${getSystemFieldName('initiative')} Progress`,
  subtitle: getSystemFieldName => `by ${getSystemFieldName('roadmap')}`,
};

const PLANNED_ALLOCATION_BY_ROADMAP_CHART = {
  id: 'plannedAllocationByRoadmap',
  type: 'chart',
  component: PlannedAllocationByRoadmap,
  title: `Planned Allocation`,
  subtitle: getSystemFieldName => `by ${getSystemFieldName('roadmap')}`,
};

const REPORTED_ALLOCATION_BY_ROADMAP_CHART = {
  id: 'reportedAllocationByRoadmap',
  type: 'chart',
  component: ReportedAllocationByRoadmap,
  title: `Reported Allocation`,
  subtitle: getSystemFieldName => `by ${getSystemFieldName('roadmap')}`,
};

/* eslint-disable-next-line */
const GOALS_PROGRESS_CHART = {
  id: 'goalsProgressStackedChart',
  type: 'chart',
  component: GoalsProgressStackedChart,
  title: `Progress and Health`,
  subtitle: getSystemFieldName => `by ${getSystemFieldName('objective')}`,
};

const IDEAS_WITH_GOALS_PROGRESS_BY_TIMEFRAME_CHART = {
  id: 'ideasWithGoalsProgressByTimeframeChart',
  type: 'chart',
  component: IdeasWithGoalsProgressByTimeframeChart,
  title: getSystemFieldName => layer => `% of active ${pluralize(getSystemFieldName(layer))}`,
  subtitle: getSystemFieldName => `linked to ${pluralize(getSystemFieldName('objective'))}`,
};

const GOALS_ACROSS_ROADMAPS_CHART = {
  id: 'goalsAcrossRoadmapsChartChart',
  type: 'chart',
  component: RoadmapByOkrStackedChart,
  title: getSystemFieldName => `${pluralize(getSystemFieldName('objective'))}`,
  subtitle: getSystemFieldName => `shared amongst ${pluralize(getSystemFieldName('roadmap'))}`,
};

const PORTFOLIO_DEPENDENCIES_TOTALS_CHART = {
  id: 'portfolioDependenciesTotalChart',
  type: 'chart',
  component: PortfolioDependenciesDelayTotals,
  title: 'Portfolio delayed items with/without dependencies',
};

const DEPENDENCIES_DELAY_BY_TEAM_CHART = {
  id: 'portfolioDependenciesDelayByTeamChart',
  type: 'chart',
  component: PortfolioDependenciesByTeamTableChart,
  title: 'Delayed items by team without (with) dependencies',
  width: 2,
};

const DEPENDENCIES_BETWEEN_TEAMS_CHART = {
  id: 'portfolioDependenciesBetweenTeams',
  type: 'chart',
  component: PortfolioDependenciesBetweenTeams,
  title: 'Dependencies between teams',
  width: 2,
};

export const SCENARIOS_BENEFITS_COMPARISON_CHART = {
  id: 'scenariosBenefitsComparisonChart',
  type: 'chart',
  component: ScenariosBenefitsComparisonChart,
};

export const SCENARIOS_ACCUMULATIVE_BENEFITS_COMPARISON_CHART = {
  id: 'scenariosAccumulativeBenefitsComparisonChart',
  type: 'chart',
  component: ScenariosAccumulativeBenefitsComparisonChart,
};

export const SCENARIO_STACKED_BENEFIT_CHART = {
  id: 'scenarioStackedBenefitChart',
  type: 'chart',
  component: ScenarioStackedBenefitChart,
};

export const SCENARIO_UNPLANNED_RESOURCES_CHART = {
  id: 'scenarioUnplannedResourcesChart',
  type: 'chart',
  component: ScenarioUnplannedResourcesChart,
};

export const CONFIGURABLE_CHART = {
  id: 'configurableChart',
  type: 'chart',
  component: ConfigurableChart,
  props: {},
};

export const CONFIGURABLE_PIE_CHART = {
  id: 'configurablePieChart',
  type: 'chart',
  title: 'Custom Pie Chart',
  props: {
    chartType: 'pie',
    groupBy: 'roadmap',
    stackBy: null,
  },
};

export const CONFIGURABLE_HORIZONTAL_BAR_CHART = {
  id: 'configurableHorizontalBarChart',
  type: 'chart',
  component: ConfigurableChart,
  title: 'Custom Horizontal Bar Chart',
  props: {
    chartType: 'bar',
    groupBy: 'roadmap',
    horizontal: true,
  },
};

export const CONFIGURABLE_HORIZONTAL_STACKED_BAR_CHART = {
  id: 'configurableHorizontalStackedBarChart',
  type: 'chart',
  component: ConfigurableChart,
  title: 'Custom Horizontal Stacked Bar Chart',
  props: {
    chartType: 'bar',
    groupBy: 'roadmap',
    stackBy: 'objective',
    horizontal: true,
  },
};

export const CONFIGURABLE_VERTICAL_BAR_CHART = {
  id: 'configurableVerticalBarChart',
  type: 'chart',
  component: ConfigurableChart,
  title: 'Custom Vertical Bar Chart',
  props: {
    chartType: 'bar',
    groupBy: 'roadmap',
  },
};

export const CONFIGURABLE_VERTICAL_STACKED_BAR_CHART = {
  id: 'configurableVerticalStackedBarChart',
  type: 'chart',
  component: ConfigurableChart,
  title: 'Custom Vertical Stacked Bar Chart',
  props: {
    chartType: 'bar',
    groupBy: 'roadmap',
    stackBy: 'objective',
  },
};

export const PROJECT_HEALTH_PIE_CHART = {
  id: 'projectHealthPieChart',
  type: 'chart',
  component: ConfigurableChart,
  props: {
    chartType: 'pie',
    groupBy: 'health',
  },
};

export const PROJECT_BY_INTEGRATED_PROJECT_CHART = {
  id: 'projectByIntegratedProjectChart',
  type: 'chart',
  component: ConfigurableChart,
  props: {
    chartType: 'bar',
    horizontal: true,
    groupBy: 'integratedProject',
  },
};

export const PREDICTED_COMPLETE_MONTH_STACKED_BY_ROADMAP_CHART = {
  id: 'predictedCompleteMonthStackedByRoadmapChart',
  type: 'chart',
  component: ConfigurableChart,
  props: {
    chartType: 'bar',
    groupBy: 'predictedCompleteMonth',
    stackBy: 'roadmap',
  },
};

export const TIMEFRAME_DASHBOARD_KEY = 'timeframe-dashboard';
export const PORTFOLIO_OUTCOME_DASHBOARD_KEY = 'portfolio-outcome-dashboard';
export const DELIVERY_RISK_DELAY_DASHBOARD = 'delivery-risk-delay-dashboard';
export const INITIATIVE_DASHBOARD_KEY = 'initiative-dashboard';
export const ROADMAP_DASHBOARD_KEY = 'roadmap-dashboard';
export const CUSTOM_DASHBOARD_KEY = 'custom-dashboard';

const TIMEFRAME_DASHBOARD_TEMPLATE = {
  key: TIMEFRAME_DASHBOARD_KEY,
  charts: [
    DELIVERY_PROGRESS_SUMMARY,
    DELIVERY_LINKED_ISSUES_SUMMARY,
    EPIC_ROADMAP_CHART,
    PROJECT_HEALTH_PIE_CHART,
    PROJECT_BY_INTEGRATED_PROJECT_CHART,
    HEALTH_ROADMAP_CHART,
    PREDICTED_COMPLETE_MONTH_STACKED_BY_ROADMAP_CHART,
  ],
};

const PORTFOLIO_OUTCOME_DASHBOARD_TEMPLATE = {
  key: PORTFOLIO_OUTCOME_DASHBOARD_KEY,
  charts: [
    EPIC_GOAL_CHART,
    IDEAS_WITH_GOALS_PROGRESS_BY_TIMEFRAME_CHART,
    OKR_BY_ROADMAP_STACKED_CHART,
    GOALS_ACROSS_ROADMAPS_CHART,
  ],
};

const DELIVERY_RISK_DELAY_DASHBOARD_TEMPLATE = {
  key: DELIVERY_RISK_DELAY_DASHBOARD,
  charts: [PORTFOLIO_DEPENDENCIES_TOTALS_CHART, DEPENDENCIES_DELAY_BY_TEAM_CHART, DEPENDENCIES_BETWEEN_TEAMS_CHART],
};

const INITIATIVE_DASHBOARD_TEMPLATE = {
  key: INITIATIVE_DASHBOARD_KEY,
  charts: [
    INITIATIVE_OKR_CHART,
    INITIATIVE_ROADMAP_CHART,
    INITIATIVE_PROGRESS_BY_ROADMAP_CHART,
    INITIATIVE_HEALTH_BY_ROADMAP_CHART,
    DETAILS_LIST_FOR_INITIATIVES,
  ],
};

const PORTFOLIO_DASHBOARD_TEMPLATE = {
  key: ROADMAP_DASHBOARD_KEY,
  charts: [
    PLANNED_ALLOCATION_BY_ROADMAP_CHART,
    REPORTED_ALLOCATION_BY_ROADMAP_CHART,
    INITIATIVE_PROGRESS_BY_ROADMAP_CHART,
    INITIATIVE_HEALTH_BY_ROADMAP_CHART,
    DETAILS_LIST,
  ],
};

const CUSTOM_DASHBOARD_TEMPLATE = {
  key: CUSTOM_DASHBOARD_KEY,
  charts: [
    CONFIGURABLE_PIE_CHART,
    CONFIGURABLE_HORIZONTAL_BAR_CHART,
    CONFIGURABLE_HORIZONTAL_STACKED_BAR_CHART,
    CONFIGURABLE_VERTICAL_BAR_CHART,
    CONFIGURABLE_VERTICAL_STACKED_BAR_CHART,
  ],
};

export const TEMPLATES = [
  TIMEFRAME_DASHBOARD_TEMPLATE,
  INITIATIVE_DASHBOARD_TEMPLATE,
  PORTFOLIO_DASHBOARD_TEMPLATE,
  PORTFOLIO_OUTCOME_DASHBOARD_TEMPLATE,
  DELIVERY_RISK_DELAY_DASHBOARD_TEMPLATE,
  CUSTOM_DASHBOARD_TEMPLATE,
];

export const getTemplateByKey = key => {
  return TEMPLATES.find(t => t.key === key);
};

export const DEFAULT_TEMPLATE = TIMEFRAME_DASHBOARD_KEY;

export const getDefaultTemplate = (organization = {}) => {
  if (organization.has_delivery_dashboard) {
    return TIMEFRAME_DASHBOARD_KEY;
  }
  if (organization.has_outcome_dashboard) {
    return PORTFOLIO_OUTCOME_DASHBOARD_KEY;
  }
  return DEFAULT_TEMPLATE;
};

export const ALL_DASHBOARDS_WIDGETS = [
  CONFIGURABLE_CHART,
  CONFIGURABLE_PIE_CHART,
  CONFIGURABLE_HORIZONTAL_BAR_CHART,
  CONFIGURABLE_HORIZONTAL_STACKED_BAR_CHART,
  CONFIGURABLE_VERTICAL_BAR_CHART,
  CONFIGURABLE_VERTICAL_STACKED_BAR_CHART,

  SCENARIO_UNPLANNED_RESOURCES_CHART,
  SCENARIO_STACKED_BENEFIT_CHART,
  SCENARIOS_ACCUMULATIVE_BENEFITS_COMPARISON_CHART,
  SCENARIOS_BENEFITS_COMPARISON_CHART,
  DEPENDENCIES_BETWEEN_TEAMS_CHART,
  DEPENDENCIES_DELAY_BY_TEAM_CHART,
  PORTFOLIO_DEPENDENCIES_TOTALS_CHART,
  GOALS_ACROSS_ROADMAPS_CHART,
  IDEAS_WITH_GOALS_PROGRESS_BY_TIMEFRAME_CHART,
  GOALS_PROGRESS_CHART,
  REPORTED_ALLOCATION_BY_ROADMAP_CHART,
  PLANNED_ALLOCATION_BY_ROADMAP_CHART,
  INITIATIVE_PROGRESS_BY_ROADMAP_CHART,
  INITIATIVE_HEALTH_BY_ROADMAP_CHART,
  INITIATIVE_ROADMAP_CHART,
  INITIATIVE_OKR_CHART,
  OKR_BY_ROADMAP_STACKED_CHART,
  HEALTH_ROADMAP_CHART,
  PROGRESS_BY_ROADMAP_WITH_EXPECTED_CHART,
  PROGRESS_ROADMAP_CHART,
  EPIC_GOAL_CHART,
  EPIC_ROADMAP_CHART,
  DELIVERY_LINKED_ISSUES_SUMMARY,
  DELIVERY_PROGRESS_SUMMARY,
  DETAILS_LIST_FOR_INITIATIVES,
  DETAILS_LIST,
  PROJECT_HEALTH_PIE_CHART,
  PROJECT_BY_INTEGRATED_PROJECT_CHART,
  PREDICTED_COMPLETE_MONTH_STACKED_BY_ROADMAP_CHART,
];
