import React, { useMemo } from 'react';
import { PERMISSION_RESOURCES, SYSTEM_FIELD } from '@dragonboat/permissions';

import RoadmapLightbox from './RoadmapLightbox';

import { ROADMAPS } from 'store/grids/constants';
import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SettingsDialogs from 'components/SettingsDialogs';
import { BaseGrid } from 'containers/Grids';
import { useSettingsActions, useSettingsGridSelection } from 'containers/Grids/SettingsGrid/hooks';

import useRoadmaps from './hooks/useRoadmaps';
import useRoadmapsGridColumns from './hooks/useRoadmapsGridColumns';
import useRoadmapsGridProps from './hooks/useRoadmapsGridProps';
import useRoadmapsGridEventHandlers from './hooks/useRoadmapsGridEventHandlers';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useForceVisibleColumns from 'hooks/useForceVisibleColumns';
import usePermissions from 'hooks/permissions/usePermissions';

const Roadmaps = ({
  showActionBar = true,
  updateSystemFieldsName,
  systemFields,
  users,
  hasProducts,
  hasProducts2,
  organizationId,
  entityPermissionsEnabled,
  hideArchivedItems,
  customHeight,
  forceVisibleFields,
}) => {
  const { canUpdate, canCreate, canView } = usePermissions();
  const { organizationAccessControlData, isDodActive } = useOrganizationsAccessControl();
  // todo: check how we should handle conditions with isDodActive accross the app
  const hasCorpLevel = isDodActive && canView(PERMISSION_RESOURCES.roadmapCorp);

  const permissionResource = hasCorpLevel ? PERMISSION_RESOURCES.roadmapCorp : PERMISSION_RESOURCES.roadmap;
  const systemFieldKey = hasCorpLevel ? SYSTEM_FIELD.roadmapCorp : SYSTEM_FIELD.roadmap;

  const canUpdateOrCreate = canUpdate(permissionResource) || canCreate(permissionResource);
  const permissions = {
    allowActions: canUpdateOrCreate,
    canAddNew: canCreate(permissionResource),
    rename: {
      canUpdate: showActionBar && canUpdate(PERMISSION_RESOURCES.systemFieldName, { field: systemFieldKey }),
      canView: showActionBar && canView(PERMISSION_RESOURCES.systemFieldName, { field: systemFieldKey }),
    },
    canMerge: showActionBar && canUpdateOrCreate,
    canBulkDelete: showActionBar && canUpdateOrCreate,
    canDragRows: showActionBar && canUpdateOrCreate,
  };

  const {
    roadmaps,
    childrenSettingKeys,
    deleteById,
    bulkDelete,
    merge,
    addRoadmapWithoutSave,
    addProductWithoutSave,
    handleOpenRoadmapLightbox,
    save,
    update,
    removeUnsavedRoadmapsAndProducts,
    switchRoadmapsRowOrder,
    switchProductsRowOrder,
    moveProductToRoadmap,
    fetchRoadmapsWithProducts,
    moveRoadmapToRoadmap,
  } = useRoadmaps({
    hasProducts,
    hasProducts2,
    hasCorpLevel,
    hideArchivedItems,
  });

  // Common settings selection configuration
  const { selectionMode, selectedItems, enableSelectionMode, disableSelectionMode, updateSelectedOnAgGridInit } =
    useSettingsGridSelection(ROADMAPS, { actions: { remove: removeUnsavedRoadmapsAndProducts } });

  // Common settings actions configuration
  const {
    itemToDelete,
    setItemToDelete,
    deleteItem,
    showBulkDeleteDialog,
    setShowBulkDeleteDialog,
    bulkDeleteItems,
    deleteProgress,
    mergeLightboxVisible,
    showMergeLightbox,
    hideMergeLightbox,
  } = useSettingsActions({
    settingType: ROADMAPS,
    selectedItems,
    disableSelectionMode,
    deleteById,
    bulkDelete,
  });

  // Specific Roadmaps agGrid configuration

  const roadmapsGridProps = useRoadmapsGridProps();

  const roadmapsGridColumns = useRoadmapsGridColumns({
    allowActions: permissions.allowActions,
    users,
    hasProducts,
    hasProducts2,
    hasCorpLevel,
    systemFields,
    selectionMode,
    setItemToDelete,
    entityPermissionsEnabled,
    actions: {
      addProductWithoutSave,
      handleOpenRoadmapLightbox,
      addRoadmapWithoutSave,
    },
    items: roadmaps,
  });

  const roadmapsEventHandlers = useRoadmapsGridEventHandlers({
    roadmaps,
    actions: {
      save,
      update,
      removeUnsavedRoadmapsAndProducts,
      switchRoadmapsRowOrder,
      switchProductsRowOrder,
      moveProductToRoadmap,
      fetchRoadmapsWithProducts,
      moveRoadmapToRoadmap,
    },
  });

  const gridContext = useMemo(
    () => ({
      selectionMode,
      selectedItems,
      organizationId,
      organizationAccessControlData,
      treeData: hasProducts || hasProducts2 || hasCorpLevel,
    }),
    [selectionMode, selectedItems, organizationId, organizationAccessControlData],
  );

  const visibleColumns = useForceVisibleColumns(roadmapsGridColumns?.columnDefs || [], forceVisibleFields);

  const columnDefs = useMemo(
    () => ({
      ...roadmapsGridColumns,
      columnDefs: visibleColumns,
    }),
    [roadmapsGridColumns, visibleColumns],
  );

  return (
    <>
      {permissions.allowActions && (
        <SettingsActionsToolbar
          settingKey={systemFieldKey}
          childrenSettingKeys={childrenSettingKeys}
          settingType={ROADMAPS}
          systemFields={systemFields}
          updateSystemFieldsName={updateSystemFieldsName}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          onEnableSelection={enableSelectionMode}
          onCancelSelection={disableSelectionMode}
          setShowBulkDeleteDialog={setShowBulkDeleteDialog}
          showMergeLightbox={showMergeLightbox}
          add={addRoadmapWithoutSave}
          renamePermissions={permissions.rename}
          canMerge={permissions.canMerge}
          canBulkDelete={permissions.canBulkDelete}
          hideAddButton={!permissions.canAddNew}
        />
      )}
      <BaseGrid
        onGridReady={updateSelectedOnAgGridInit}
        rowData={roadmaps}
        {...columnDefs}
        {...roadmapsGridProps}
        {...roadmapsEventHandlers}
        context={gridContext}
        height={customHeight ?? roadmapsGridProps.height}
      />
      <SettingsDialogs
        settingKey={SYSTEM_FIELD.roadmap}
        settingType={ROADMAPS}
        showBulkDeleteDialog={showBulkDeleteDialog}
        setShowBulkDeleteDialog={setShowBulkDeleteDialog}
        deleteProgress={deleteProgress}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        deleteItem={deleteItem}
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
        mergeLightboxVisible={mergeLightboxVisible}
        hideMergeLightbox={hideMergeLightbox}
        merge={merge}
        bulkDelete={bulkDeleteItems}
      />
      <RoadmapLightbox />
    </>
  );
};

export default Roadmaps;
