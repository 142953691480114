export const DISCOVERY_BOT_HISTORY_CACHE_STORAGE_KEY = 'discoveryBotHistoryCache';
export const WELCOME_CHAT_STEP_ID = 'welcome-step';
export const GET_TARGET_TEAM_CHAT_STEP_ID = 'get-target-team-step';
export const GET_BUSINESS_DRIVER_CHAT_STEP_ID = 'get-business-driver-step';
export const SHOW_TARGET_TEAM_OPTIONS_CHAT_STEP_ID = 'show-target-team-options-step';
export const SHOW_BUSINESS_DRIVER_OPTIONS_CHAT_STEP_ID = 'show-business-driver-options-step';
export const USER_PROMPT_CHAT_STEP_ID = 'user-prompt';
export const OPENAI_PROMPT_CHAT_STEP_ID = 'openai-prompt';
export const OPENAI_RESPONSE_CHAT_STEP_ID = 'openai-prompt-response';
export const BUSINESS_DRIVER_RESPONSE_CHAT_STEP_ID = 'business-driver-response-chat-step';
