import {
  TAB_DETAILS,
  TAB_ESTIMATES,
  TAB_CREATE_TASKS,
  TAB_DEPENDENCIES,
  TAB_HISTORY,
  TAB_COMMENTS,
  TAB_SHARE,
  TAB_IDEAS,
  TAB_CUSTOMER_REQUESTS,
  TAB_PROGRESS,
} from 'constants/projectLightbox';
import { PROJECT_LIGHTBOX_TAB_QUERY_PARAM } from 'constants/queryParams';
import { addQueryParamToUrl, getQueryParamFromUrl } from 'utils/queryParamsUtils';

const TABS = [
  TAB_DETAILS,
  TAB_ESTIMATES,
  TAB_CREATE_TASKS,
  TAB_DEPENDENCIES,
  TAB_HISTORY,
  TAB_COMMENTS,
  TAB_SHARE,
  TAB_IDEAS,
  TAB_CUSTOMER_REQUESTS,
  TAB_PROGRESS,
];

const isValidTab = tab => TABS.includes(tab);

const useProjectLightboxTabs = initialTab => {
  const defaultTab = isValidTab(initialTab) ? initialTab : TAB_DETAILS;

  const changeTab = tab => {
    if (isValidTab(tab)) {
      addQueryParamToUrl(PROJECT_LIGHTBOX_TAB_QUERY_PARAM, tab);
    }
  };

  const currentTab = getQueryParamFromUrl(PROJECT_LIGHTBOX_TAB_QUERY_PARAM);

  return {
    activeTab: isValidTab(currentTab) ? currentTab : defaultTab,
    changeTab,
  };
};

export default useProjectLightboxTabs;
