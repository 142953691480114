import { useState, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import useUserViews from 'hooks/userViews/useUserViews';
import useUserFavoriteViews from 'hooks/userViews/useUserFavoriteViews';

import history from 'store/utils/history';
import { DASHBOARDS_PAGE, TEMPLATES_PAGE } from 'constants/filters';
import { PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';

export const FAVORITE_VIEWS = 'My Favorite Views';
export const DASHBOARD_VIEWS = 'Dashboards';
export const ALL_VIEWS = 'All Views';

const TEMPLATES = 'Templates';
const TEMPLATES_ROUTE = '/dashboard/templates';

// By default we want to show the Favorite Views tab (if that tab is visible)
// But if the user has no favorite views we should show the All Views tab
const getInitialTab = (visibleTabs, favoriteViews) => {
  if (isEmpty(visibleTabs)) {
    return isEmpty(favoriteViews) ? ALL_VIEWS : FAVORITE_VIEWS;
  }

  if (visibleTabs[0] === FAVORITE_VIEWS && isEmpty(favoriteViews)) {
    return visibleTabs[1] || FAVORITE_VIEWS;
  }

  return visibleTabs[0];
};

const useViewsTabs = (isDialog = false, toggleDialog, visibleTabs) => {
  const { userViews: allViews, userDashboards: dashboardViews } = useUserViews();
  const { userViews: favoriteViews } = useUserFavoriteViews();

  const { canView } = usePermissions();

  const [selectedTab, setSelectedTab] = useState(getInitialTab(visibleTabs, favoriteViews));

  const canViewDashboards = canView(PERMISSION_PAGES[DASHBOARDS_PAGE]);
  const userCanViewTemplates = isDialog && canView(PERMISSION_PAGES[TEMPLATES_PAGE]);

  const viewsTabs = useMemo(
    () =>
      [
        FAVORITE_VIEWS,
        ...(canViewDashboards ? [DASHBOARD_VIEWS] : []),
        ALL_VIEWS,
        ...(userCanViewTemplates ? [TEMPLATES] : []),
      ].filter(tab => (visibleTabs ? visibleTabs.includes(tab) : true)),
    [visibleTabs, canViewDashboards, isDialog, userCanViewTemplates],
  );

  const viewsForSelectedTab = useMemo(() => {
    switch (selectedTab) {
      case FAVORITE_VIEWS:
        return favoriteViews;
      case DASHBOARD_VIEWS:
        return dashboardViews;
      case ALL_VIEWS:
        return allViews;
      case TEMPLATES:
        return [];
      default:
        return allViews;
    }
  }, [selectedTab, allViews, favoriteViews, dashboardViews]);

  const handleSelectTemplatesTab = () => {
    history.push(TEMPLATES_ROUTE);
    if (toggleDialog) toggleDialog();
  };

  const handleTabChange = (e, val) => {
    if (val === TEMPLATES) {
      handleSelectTemplatesTab();
      return;
    }

    setSelectedTab(val);
  };

  return {
    selectedTab,
    handleTabChange,
    viewsForSelectedTab,
    tabs: viewsTabs,
  };
};

export default useViewsTabs;
