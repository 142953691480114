/**
 * InlineTextField Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import styled from 'styled-components';

export default styled.textarea`
  background: #FFF;
  border: none;
  box-shadow: inset 0 0 0 2px ${({ theme }) => theme.palette.newLayout.border.textarea};
  border-radius: 3px;
  min-height: 28px;
  padding: 4px 8px;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  resize: none;
  overflow: hidden;

  ${({ disabled }) => disabled && `
    background: transparent;
    box-shadow: none;
    cursor: pointer;
  `}
`;
