import React, { useRef, useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';

import { materialColorsAlt } from 'design-system/themes/default';
import HealthSelect from 'design-system/molecules/HealthSelect';

import Circle from '../Circle';
import DragonTableCell from '../DragonTableCell';
import { _isEditable } from '../../state';

const StyledHealthSelect = styled(HealthSelect)`
  &&&& {
    padding: 0;
    display: flex;
    justify-content: center;
  }
`;

const CircleColumnEditor = ({ anchorEl, onSave, onCancel, value }) => {
  return (
    <StyledHealthSelect
      anchorEl={anchorEl.current}
      value={value}
      cancel={onCancel}
      save={onSave}
      autoFocus
      CircleComponent={Circle}
      renderTextOnValue={false}
      horizontalAnchorOrigin="left"
    />
  );
};

export default props => {
  const { map, row, width, editable, onEdit = () => {}, onEditCancel = () => {}, onChange, forceEdit } = props;
  const anchorEl = useRef(null);
  const result = row && map(row);
  const [editingValue, setEditingValue] = useState(null);

  const circle = row ? (
    <Circle
      style={{
        background: result ? materialColorsAlt[result.toLowerCase()] : null,
      }}
    />
  ) : (
    <Circle
      style={{
        background: 'transparent',
        border: '1px solid black',
      }}
    />
  );

  const isEditable = _isEditable(row, editable);
  const save = value => {
    if (onChange) {
      if (
        (!row && value !== '' && value) || // if it's a creation and the value is valid
        (row && !isEqual(value, map(row)))
      ) {
        onChange(row || {}, value);
        setEditingValue(null);
      }

      onEditCancel();
    }
  };

  useEffect(() => {
    if (!forceEdit) return;

    if (!editingValue && isEditable) {
      onEdit();
      setEditingValue(result);
    }
  }, [forceEdit]);

  const Value =
    editingValue !== null ? (
      <CircleColumnEditor
        anchorEl={anchorEl}
        autoFocus
        onSave={save}
        value={editingValue}
        onCancel={() => {
          onEditCancel();
          setEditingValue(null);
          anchorEl.current = null;
        }}
      />
    ) : (
      circle
    );

  return (
    <DragonTableCell
      {...props}
      editable={isEditable}
      editing={editingValue !== null}
      centered
      style={{ height: props.cellProps.rowHeight, width }}
      onClick={e => {
        if (isEditable && editingValue === null) {
          anchorEl.current = e.currentTarget;
          onEdit();
          setEditingValue(result);
        }
      }}
    >
      {Value}
    </DragonTableCell>
  );
};
