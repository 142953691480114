import { PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';
import { SUMMARY_PAGE, IDEAS_OVERVIEW_PAGE } from 'constants/filters';

export const DEFAULT_PAGES = canView => ({
  ideas: canView(PERMISSION_PAGES[IDEAS_OVERVIEW_PAGE]) ? 'overview' : 'grid',
  forecast: 'list',
  dashboard: canView(PERMISSION_PAGES[SUMMARY_PAGE]) ? 'summary' : 'home',
  requests: 'detail',
  goals: 'grid',
  metrics: 'grid',
  'compare-scenarios': 'list',
  'compare-snaps': 'list',
  scenario: 'list',
});
