import React, { Component } from 'react';

import TextWrapper from 'design-system/atoms/TextWrapper';

const defaultGetNumberOfTextRowsToDisplayOnGridCell = () => 1;

export default (jiraIntegrated, title, { mainKey = 'timeframe', titleKey = 'timeframeTitle' } = {}) =>
  (class extends Component {
    render() {
      const { getNOfTextRowsToDisplay = defaultGetNumberOfTextRowsToDisplayOnGridCell } = this.props;

      if (this.props.node.group && 'value' in this.props) {
        this.props.reactContainer.style.display = 'inline-block';
        return `${title}: ${this.props.value || 'Undefined'}`;
      }

      if (this.props.node && this.props.node.data) {
        return (
          <TextWrapper $nOfTextRowsToDisplay={getNOfTextRowsToDisplay()}>
            <span>{this.props.node.data[titleKey]}</span>
          </TextWrapper>
        );
      }

      return '';
    }
  });
