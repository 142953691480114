import React, { useCallback, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { debounce } from 'lodash';

import Paper from '@material-ui/core/Paper';

import Dialog from 'design-system/molecules/Dialog/index';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';

import { spacing } from 'design-system/theme';

import { BaseGrid } from 'containers/Grids';

import { stylesOverride } from './styled';

import DialogHeaderWithSearch from 'design-system/molecules/DialogHeaderWithSearch';

import useSystemFields from 'hooks/useSystemFields';
import useSubscribeNewData from 'hooks/useSubscribeNewData';

import useViewRoadmapHistorySnapsDialog from './hooks/useViewRoadmapHistorySnapsDialog';
import useColDefsForViewRoadmapHistorySnapsDialog from './hooks/useColDefsForViewRoadmapHistorySnapsDialog';

import { REALTIME_TOPIC_NAME } from '../../constants';
import { Button } from '@material-ui/core';
import { getRowId } from 'design-system/molecules/AgGridReact-New/helpers';

const GRID_HEIGHT = '550px';

const ViewRoadmapHistorySnapsDialog = () => {
  const [getSystemFieldName] = useSystemFields();

  const {
    searchQuery,
    historySnapsList,
    isDialogOpen,
    isLoading,
    selectedSnapsForComparison,
    setSearchQuery,
    closeViewRoadmapHistorySnapsDialog,
    onClickOpenCompareRoadmapHistorySnap,
    onEditRoadmapHistorySnap,
    onSnapsSelectionChanged,
    onDeleteRoadmapHistorySnap,
  } = useViewRoadmapHistorySnapsDialog();

  useSubscribeNewData([REALTIME_TOPIC_NAME]);

  const gridApi = useRef();

  const columnDefs = useColDefsForViewRoadmapHistorySnapsDialog({
    onEditRoadmapHistorySnap,
    onDeleteRoadmapHistorySnap,
  });

  const gridOptions = {
    autoGroupColumnDef: {
      headerName: 'Title',
      cellRendererParams: {
        suppressCount: true,
      },
    },
    treeData: false,
    rowData: historySnapsList,
    columnDefs,
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    onSelectionChanged: onSnapsSelectionChanged,
    getRowId,
  };

  const gridContext = useMemo(
    () => ({
      totalSelectedSnaps: selectedSnapsForComparison?.length || 0,
    }),
    [selectedSnapsForComparison?.length],
  );

  const onGridReady = useCallback(params => {
    gridApi.current = params.api;

    gridApi.current.sizeColumnsToFit();
  }, []);

  const debouncedSetQuery = useMemo(() => debounce(setSearchQuery, 200), []);

  const _handleSearchViews = useCallback(search => {
    debouncedSetQuery(search);
  }, []);

  return (
    <Dialog
      id="snap-list-dialog"
      open={isDialogOpen}
      onClose={closeViewRoadmapHistorySnapsDialog}
      maxWidth="lg"
      scroll="paper"
      fullWidth
      contentCustomStyles={dialogContentStyles}
      PaperComponent={StyledPaper}
      header={
        <DialogHeaderWithSearch
          searchQuery={searchQuery}
          searchPlaceholder={`Search for a ${getSystemFieldName('snap')}...`}
          handleSearch={_handleSearchViews}
          closeDialog={closeViewRoadmapHistorySnapsDialog}
        />
      }
      actions={
        <Button onClick={onClickOpenCompareRoadmapHistorySnap} disabled={!selectedSnapsForComparison.length} color="primary">
          Compare selected snaps
        </Button>
      }
    >
      <Wrapper>
        {isLoading ? (
          <PageLoading />
        ) : (
          <BaseGrid
            {...gridOptions}
            onGridReady={onGridReady}
            height={GRID_HEIGHT}
            cssStyles={stylesOverride}
            context={gridContext}
          />
        )}
      </Wrapper>
    </Dialog>
  );
};

export default ViewRoadmapHistorySnapsDialog;

const dialogContentStyles = css`
  display: flex;
  padding: ${spacing(3)}px ${spacing(3)}px 0;
`;

const StyledPaper = styled(Paper)`
  height: 900px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
