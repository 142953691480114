import { useMemo } from 'react';

import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';
import {
  statusColumnDef,
  updatedColumnDef,
  getMetadataRoadmapColumnDef,
  getDropdownCustomFieldOptionSelectionColumnDef,
  colorColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';
import { TextCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { applyCheckIsEditable } from 'design-system/molecules/AgGridReact-New/helpers';

import GroupHeaderInnerRenderer from '../components/GroupHeaderInnerRenderer';
import getCustomizableCustomFieldColumnDef from '../utils/getCustomizableCustomFieldColumnDef';
import { makeCheckIsEditable } from '../helpers';

const CUSTOMER_KEY = 'customer';
const CUSTOMER_ROADMAP_FIELD = 'customer_roadmaps';

const CUSTOMIZABLE_CUSTOM_FIELDS = ['Segment', 'Type'];

const openCrmRecordUrlById = data => {
  const url = data?.crm_record_url || null;
  const validUrl = url && url.indexOf('://') === -1 ? `https://${url}` : url;

  if (validUrl) {
    window.open(validUrl, '_blank').focus();
  }
};

const mapCustomFieldColumnDefs = (
  isEditable = false,
  customFields,
  openCustomFieldCustomizer,
  displayCustomFieldCustomizerIcon,
) => {
  return customFields
    .sort((a, b) => (a.title || '').localeCompare(b.title))
    .map(customField => {
      const customizable = displayCustomFieldCustomizerIcon && CUSTOMIZABLE_CUSTOM_FIELDS.includes(customField.title);
      const editable = params => !!params.data.id && isEditable;

      return customizable
        ? getCustomizableCustomFieldColumnDef({ customField, editable, onCustomizeIconClick: openCustomFieldCustomizer })
        : getDropdownCustomFieldOptionSelectionColumnDef({ customField, editable });
    });
};

const useCustomersGridColumns = ({
  allowActions,
  customFields,
  roadmapColumn,
  isGrouping,
  openCustomFieldCustomizer,
  displayCustomFieldCustomizerIcon,
}) => {
  const checkIsEditable = useMemo(() => makeCheckIsEditable(isGrouping), [isGrouping]);

  const { createCustomerRoadmap, deleteCustomerRoadmap, customers, bulkDeleteCustomerRoadmaps, hideMetadataRoadmaps } =
    roadmapColumn;

  const roadmapsMetadata = useRoadmapsMetadata(
    createCustomerRoadmap,
    deleteCustomerRoadmap,
    customers,
    CUSTOMER_KEY,
    bulkDeleteCustomerRoadmaps,
  );

  const customFieldColumns = useMemo(
    () =>
      mapCustomFieldColumnDefs(
        allowActions && checkIsEditable,
        customFields,
        openCustomFieldCustomizer,
        displayCustomFieldCustomizerIcon,
      ),
    [customFields, allowActions, checkIsEditable, openCustomFieldCustomizer, displayCustomFieldCustomizerIcon],
  );

  const groupColumnDef = useMemo(
    () => ({
      headerName: 'Name',
      field: 'name',
      suppressMovable: true,
      comparator: stringComparator,
      editable: checkIsEditable,
      cellClass: 'ag-react-editable-container--expandable ag-title-cell',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: GroupHeaderInnerRenderer,
        suppressCount: true,
        isGrouping,
      },
    }),
    [isGrouping, checkIsEditable],
  );

  const checkIsNewCustomerRow = params => !!params.data?.id;

  const roadmapColumnDef = useMemo(() => {
    return getMetadataRoadmapColumnDef({
      roadmapsMetadata: [...roadmapsMetadata, hideMetadataRoadmaps],
      field: CUSTOMER_ROADMAP_FIELD,
      editable: params => checkIsNewCustomerRow(params) && applyCheckIsEditable(checkIsEditable),
    });
  }, [hideMetadataRoadmaps, checkIsEditable]);

  const customersStatusColumnDef = useMemo(() => {
    return {
      ...statusColumnDef,
      enableRowGroup: false,
      editable: params => checkIsNewCustomerRow(params) && applyCheckIsEditable(checkIsEditable),
    };
  }, [checkIsEditable]);

  const customersColorColumnDef = useMemo(() => {
    return {
      ...colorColumnDef,
      editable: params => checkIsNewCustomerRow(params) && applyCheckIsEditable(checkIsEditable),
    };
  }, [checkIsEditable]);

  const CRMRecordUrlColumnDef = useMemo(() => {
    return {
      field: 'crm_record_url',
      headerName: 'CRM Record Url',
      suppressMovable: true,
      cellRenderer: TextCellRenderer,
      cellRendererParams: {
        isLinkDisplay: true,
      },
      comparator: stringComparator,
      cellClass: 'cell-link',
      onLinkClick: openCrmRecordUrlById,
      editable: applyCheckIsEditable(checkIsEditable),
      editableForNewRow: false,
    };
  }, [checkIsEditable]);

  const valueColumnDef = useMemo(() => {
    return {
      field: 'value',
      headerName: 'Value',
      suppressMovable: true,
      cellRenderer: TextCellRenderer,
      comparator: stringComparator,
      editable: applyCheckIsEditable(checkIsEditable),
    };
  }, [checkIsEditable]);

  return useMemo(
    () => [
      groupColumnDef,
      roadmapColumnDef,
      customersStatusColumnDef,
      customersColorColumnDef,
      CRMRecordUrlColumnDef,
      valueColumnDef,
      ...customFieldColumns,
      updatedColumnDef,
    ],
    [groupColumnDef, roadmapColumnDef, customersStatusColumnDef, customFieldColumns],
  );
};

export default useCustomersGridColumns;

export { CUSTOMER_ROADMAP_FIELD };
