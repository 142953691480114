import { defaultTo, ifElse, equals, isNil } from 'ramda';

// import AgGridInputLink from 'design-system/atoms/AgGridInputLink/index';

// import AgGridInputLinkRenderer from 'design-system/atoms/AgGridInputLinkRenderer';

import { FORECAST_BY_HEADCOUNT } from 'constants/forecast';
import theme from 'design-system/theme';

const defaultAsZero = defaultTo(0);

/**
 * @function getHeadcountAggValueBackground
 *
 * Gets the color to put on headcount group value
 *
 * @param  {Number} value
 * @return {String}
 */
export const getHeadcountAggValueBackground = value => {
  if (value === 0) {
    return '';
  }

  return value < 0 ? theme.palette.background.heaven : theme.palette.background.veyra;
};

/**
 * @function getEstimateCellCssStyle
 *
 * returns function to get the estimated cell css style
 *
 * @param  {Number} resourcesCount
 * @param  {Boolean} isEstimatingByPoints
 * @return {Function}
 */
const getEstimateCellCssStyle = (resourcesCount, isEstimatingByPoints) => cellParams => {
  const { data, value: gridValue, column } = cellParams;
  const { forecastBy } = cellParams.context;
  const style = { textAlign: 'center', ...(isEstimatingByPoints ? { color: `${theme.palette.text.accent}!important` } : {}) };
  const value = !isNil(gridValue) ? gridValue : data[column.colId];

  const returnDefaultStyle = () => style;
  const returnHeadcountStyle = () => {
    const headcountValue = resourcesCount - defaultAsZero(value?.sum);
    const isGroupAndHaveValidValue = data?.group && value?.sum;

    return {
      ...style,
      fontWeight: theme.typography.fontWeightBold,
      color: `${theme.palette.text.grey}!important`,
      ...(isGroupAndHaveValidValue ? { backgroundColor: getHeadcountAggValueBackground(headcountValue) } : {}),
    };
  };
  const returnStyleWithColor = ifElse(equals(FORECAST_BY_HEADCOUNT), returnHeadcountStyle, returnDefaultStyle);

  switch (true) {
    case data.group:
      return returnStyleWithColor(forecastBy);
    default:
      return style;
  }
};

export default getEstimateCellCssStyle;
