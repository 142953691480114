import axios from 'axios';

import {
  FETCH_THEMES,
  UPDATE_THEME,
  UPDATE_THEME_BY_ID,
  ADD_THEME_WITHOUT_SAVE,
  REMOVE_UNSAVED_THEMES,
  CREATE_THEME,
  CREATE_THEME_FULFILLED,
  DELETE_THEME_FULFILLED,
  UPDATE_THEME_FULFILLED,
  BULK_DELETE_THEMES_FULFILLED,
  BULK_DELETE_THEME_ROADMAP,
  CREATE_THEME_ROADMAP,
  DELETE_THEME_ROADMAP,
} from './types';
import throwRequestError from '../utils/throwRequestError';
import fetchMetadataAction from '../utils/factory/fetchMetadataAction';
import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';

export const fetchThemes = fetchMetadataAction(
  FETCH_THEMES,
  '/api/themes',
  state => state.themes.themes,
  state => state.themes.lastCallsDate,
);

export const updateTheme = theme => {
  return {
    payload: axios
      .post(`/api/themes/update/${theme.id}`, theme)
      .then(res => res.data)
      .catch(throwRequestError),
    type: UPDATE_THEME,
  };
};

export const updateThemeById = (themeId, update) => {
  return {
    payload: axios
      .put(`/api/themes/${themeId}`, update)
      .then(res => res.data)
      .catch(throwRequestError),
    type: UPDATE_THEME_BY_ID,
  };
};

export const createTheme = theme => {
  return {
    payload: axios
      .post('/api/themes/', theme)
      .then(res => res.data)
      .catch(throwRequestError),
    type: CREATE_THEME,
  };
};

export const createThemeRoadmap = (themeId, themeRoadmap) => {
  return {
    payload: axios.post(`/api/themes/${themeId}/themeRoadmaps/`, themeRoadmap).then(response => response.data),
    type: CREATE_THEME_ROADMAP,
  };
};

export const deleteThemeRoadmap = ({ id, roadmapId, subRoadmapId = null, product2Id = null }) => {
  let route = `/api/themes/${id}/themeRoadmaps/${roadmapId}`;

  route += subRoadmapId ? `/${subRoadmapId}` : '';
  route += subRoadmapId && product2Id ? `/${product2Id}` : '';

  return {
    payload: axios.delete(route).then(response => response.data),
    type: DELETE_THEME_ROADMAP,
  };
};

export const bulkDeleteThemeRoadmaps = themeId => {
  return {
    payload: axios.delete(`/api/themes/${themeId}/themeRoadmaps/`).then(response => response.data),
    type: BULK_DELETE_THEME_ROADMAP,
  };
};

export const addThemeWithoutSave = theme => ({
  type: ADD_THEME_WITHOUT_SAVE,
  theme,
});

export const removeUnsavedThemes = _ => ({
  type: REMOVE_UNSAVED_THEMES,
});

export const gotThemesRealtimeUpdate = generateRealtimeUpdateAction(
  CREATE_THEME_FULFILLED,
  DELETE_THEME_FULFILLED,
  UPDATE_THEME_FULFILLED,
  BULK_DELETE_THEMES_FULFILLED,
);

export const themeActions = {
  fetchThemes,
  updateTheme,
  updateThemeById,
  createTheme,
};
