export default (treeViewData, treeView, main, rowId, toggleParent = () => ({})) => {
  if (!treeView || !main) return {};

  const isParent = treeViewData.has(rowId);

  return {
    isChild: !isParent,
    isParent,
    hasChildren: isParent ? treeViewData.get(rowId).size > 0 : false,
    toggleParent: isParent ? toggleParent : null,
  };
};
