import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

import HelpLink from 'design-system/atoms/HelpLink/index';
import InlineIcon from 'design-system/atoms/InlineIcon/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import useAppNotifications from 'hooks/useAppNotifications';
import useIntegrations from 'hooks/useIntegrations';
import { INTEGRATIONS_KEYS } from 'constants/integrations';

import validateURL from 'utils/validateURL';

import ErrorMessage, { errorTypes } from './ErrorMessage';
import { ErrorMessageContainer, ImportInstructions, Wrapper, InputContainer, QueryInput } from './ADOSearchStep.styles';

const isQueryIdValid = queryId =>
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(queryId);

const ADOSearchStep = ({ parsedImportUrl, orgIntegrationId, onOrgIntegrationIdChange, onSelectedValueChange }) => {
  const [error, setError] = useState(null);
  const [importUrl, setImportUrl] = useState('');

  const { addError } = useAppNotifications();
  const {
    organizationIntegrations,
    getImportCountIntegrationItems,
    isImportCountIntegrationItemsLoading,
    importCountIntegrationItems,
    importCountIntegrationItemsError,
  } = useIntegrations(INTEGRATIONS_KEYS.azuredevops, orgIntegrationId);

  const integratedURLs = useMemo(
    () => organizationIntegrations.map(integration => integration.data?.uri),
    [organizationIntegrations],
  );

  const handleUrlChange = useCallback(
    e => {
      const value = e?.target?.value;

      const finalQueryId = validateURL(value) ? value.match(/([^/]*)\/*$/)[1] : value;

      setImportUrl(value);
      onSelectedValueChange(finalQueryId);
    },
    [setImportUrl, onSelectedValueChange],
  );

  const handleSearch = useCallback(() => {
    setError(null);

    const selectedOrgIntegration = organizationIntegrations.find(integration =>
      importUrl.startsWith(`${integration?.data?.uri}/`),
    );

    if (!selectedOrgIntegration) {
      return setError(errorTypes.INVALID_BASE_URL);
    }

    const invalidQuery = !isQueryIdValid(parsedImportUrl);

    if (invalidQuery) {
      return setError(errorTypes.INVALID_QUERY);
    }

    onOrgIntegrationIdChange(selectedOrgIntegration.id);
    getImportCountIntegrationItems(selectedOrgIntegration.id, parsedImportUrl);
  }, [organizationIntegrations, importUrl, parsedImportUrl, getImportCountIntegrationItems, setError]);

  useEffect(() => {
    if (importCountIntegrationItemsError) {
      addError({ id: 'azure-query-error', message: 'Error fetching the Azure Devops work items' });
    }
  }, [importCountIntegrationItemsError]);

  return (
    <Wrapper>
      <ImportInstructions>
        <span>
          Create a query on your Azure Devops instance, then <strong>paste the url or id</strong> below
        </span>
        <Tooltip enterDelay={200} title="Azure DevOps (ADO) integration overview">
          <TextDeprecated size="medium">
            <HelpLink
              href="https://dragonboat.zendesk.com/hc/en-us/articles/360037378353-Azure-DevOps-ADO-integration-overview"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InlineIcon>
                <HelpIcon />
              </InlineIcon>
            </HelpLink>
          </TextDeprecated>
        </Tooltip>
      </ImportInstructions>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <InputContainer hasError={!!error}>
            <QueryInput value={importUrl} placeholder="Paste your Azure Devops query url or id here" onChange={handleUrlChange} />
            <Button onClick={handleSearch} disabled={!parsedImportUrl || isImportCountIntegrationItemsLoading}>
              Search
            </Button>
          </InputContainer>
        </Grid>
      </Grid>
      {isImportCountIntegrationItemsLoading && <CircularProgress variant="indeterminate" disableShrink size={24} thickness={4} />}
      {!isImportCountIntegrationItemsLoading && importCountIntegrationItems && !error && (
        <ImportInstructions>
          {!!importCountIntegrationItems[parsedImportUrl] && (
            <span>
              <strong>{importCountIntegrationItems[parsedImportUrl]}</strong> Work Items found.
            </span>
          )}
          {importCountIntegrationItems[parsedImportUrl] === 0 && <span>There are no work items in the search result.</span>}
        </ImportInstructions>
      )}
      {error && (
        <ErrorMessageContainer>
          <ErrorMessage errorType={error} integratedURLs={integratedURLs} />
        </ErrorMessageContainer>
      )}
    </Wrapper>
  );
};

ADOSearchStep.propTypes = {
  parsedImportUrl: PropTypes.string,
  orgIntegrationId: PropTypes.number,
  onOrgIntegrationIdChange: PropTypes.func.isRequired,
  onSelectedValueChange: PropTypes.func.isRequired,
};

export default ADOSearchStep;
