import React from 'react';
import styled from 'styled-components';

const TableCellComponent = ({ style, children, center, ...otherProps }) => {
  return (
    <TableCell {...otherProps}>
      <TableCellInner style={style} center={center}>
        {children}
      </TableCellInner>
    </TableCell>
  );
};

TableCellComponent.displayName = 'TableCell';

export default TableCellComponent;

const TableCell = styled.div`
  background: ${({ theme }) => theme.palette.background.alabaster};

  ${({ bordered, theme }) =>
    bordered &&
    `
    border-right: 1px solid ${theme.palette.border.mercury};
  `}

  ${({ sticky }) =>
    sticky &&
    `
    position: sticky;
  `}

  ${({ left }) =>
    left &&
    `
    left: ${left - 1}px;
    z-index: 100;
  `}

  ${({ width }) =>
    width &&
    `
    width: ${width};
    max-width: ${width};
  `}

  ${({ zIndex }) =>
    zIndex &&
    `
    z-index: ${zIndex};
  `}


  ${({ split }) =>
    split &&
    `
    border-image: linear-gradient(270deg, #0E275200 0%, #0E27522C 100%) 30;
    border-right: 3px solid;
  `}
`;

const TableCellInner = styled.div`
  &&&& {
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.background.main};
    vertical-align: top;

    ${({ center }) =>
      center &&
      `
      display: flex;
      align-items: center;
    `}
  }
`;
