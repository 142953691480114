import React from 'react';
import Switch from '@material-ui/core/Switch';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import History from 'components/History';
import styled from 'styled-components';

export default ({
  ideaHistory,
  hasNext,
  fetchNextPage,
  orgMetadataForCorpLevels,
  systemFields,
  isLoading,
  hasIntegrationLog,
  showErrors,
  onToggleShowErrors,
}) => {
  return (
    <>
      {hasIntegrationLog && (
        <Actions>
          <FormControlLabel
            label="Show Errors"
            labelPlacement="start"
            control={<Switch checked={showErrors} onChange={onToggleShowErrors} />}
          />
        </Actions>
      )}
      <History
        objects={ideaHistory}
        hasNext={hasNext}
        isLoading={isLoading}
        fetchNextPage={fetchNextPage}
        systemFields={systemFields}
        orgMetadataForCorpLevels={orgMetadataForCorpLevels}
      />
    </>
  );
};

const Actions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 16px;
  margin-right: 50px;
`;
