/**
 * @function getResourcesCountForTeamSkill
 *
 * get the resources count for team and skill
 *
 * @param  {Number} teamId
 * @param  {Number} skillId
 * @param  {Array}  users
 * @return {Number}
 */
const getResourcesCountForTeamSkill = (teamId, skillId, users) => {
  const capacity = users.reduce((counter, user) => {
    const matchesTeam = user.team?.id === teamId;
    const matchesSkill = user.skill?.id === skillId;
    const hasCapacity = !!user.capacity;

    if (matchesTeam && matchesSkill && hasCapacity) {
      return counter + 1;
    }

    return counter;
  }, 0);

  return capacity;
};

export default getResourcesCountForTeamSkill;
