import omit from 'lodash/omit';
import moment from 'moment';
import formatDate from 'utils/dates/formatDate';


export default customerRequest => {
  let deserializedCustomerRequest = { ...customerRequest };

  if (customerRequest.customers) {
    deserializedCustomerRequest.customer_ids = customerRequest.customers.map(({ id }) => id);
    deserializedCustomerRequest = omit(deserializedCustomerRequest, ['customers']);
  } else {
    deserializedCustomerRequest.customer_ids = [];
  }

  if (customerRequest.tags) {
    deserializedCustomerRequest.tag_ids = customerRequest.tags.map(({ id }) => id);
    deserializedCustomerRequest = omit(deserializedCustomerRequest, ['tags']);
  } else {
    deserializedCustomerRequest.tag_ids = [];
  }

  // delete deserializedCustomerRequest.comments;

  if (customerRequest.desired_time && moment(customerRequest.desired_time).isValid()) {
    deserializedCustomerRequest.desired_time = formatDate(moment(customerRequest.desired_time));
  } else {
    deserializedCustomerRequest.desired_time = null;
  }

  return deserializedCustomerRequest;
};
