import Lexorank from './lexorank';

export default (data, id1, id2, position) => {
  const closeRow = data.find(({ id }) => id === +id2);
  const movingRow = data.find(({ id }) => id === +id1);

  const closeRowIndex = data.findIndex(r => r.id === closeRow.id);

  const prevRow = position === 'bottom' ? data[closeRowIndex + 1] : closeRow;
  const nextRow = position === 'bottom' ? closeRow : data[closeRowIndex - 1];

  const lexorank = new Lexorank();
  const prevLex = prevRow && prevRow.rank;
  const nextLex = nextRow && nextRow.rank;
  const [rank, ok] = lexorank.insert(prevLex, nextLex);

  if (!ok) return movingRow;

  return {
    ...movingRow,
    rank,
  };
};
