import { getThunkActionTypes } from 'utils/store/thunk';

const CLEAR_TIMELINE_LIGHTBOX_PROJECTS = 'CLEAR_TIMELINE_LIGHTBOX_PROJECTS';

const { FETCH_TIMELINE_LIGHTBOX_PROJECTS, FETCH_TIMELINE_LIGHTBOX_PROJECTS_FULFILLED, FETCH_TIMELINE_LIGHTBOX_PROJECTS_PENDING } =
  getThunkActionTypes('FETCH_TIMELINE_LIGHTBOX_PROJECTS');

export {
  FETCH_TIMELINE_LIGHTBOX_PROJECTS,
  FETCH_TIMELINE_LIGHTBOX_PROJECTS_FULFILLED,
  FETCH_TIMELINE_LIGHTBOX_PROJECTS_PENDING,
  // actions
  CLEAR_TIMELINE_LIGHTBOX_PROJECTS,
};
