import moment from 'moment';
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';
import { isNil } from 'ramda';

import formatDate from 'utils/dates/formatDate';
import roundDecimalPlaces from 'utils/roundDecimalPlaces';
import { exportHealth, exportMultiSelectMetadata, removeMarkdown } from 'features/GridExport/helpers/exportUtils';
import { HEALTH_LABEL_OPTIONS_LABELS } from 'constants/projects';
import { ADDITIONAL_METADATA_FIELDS } from 'constants/projectLightbox';
import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';
import { progressValueFormatter } from '../IdeasList/New/hooks/useIdeasGridColumnsDefinitions/helpers';

export const processCellOnExport = params => {
  if (params && params.column && params.column.colId) {
    if (params?.column?.colDef?.isEstimateColumn) {
      return params?.value?.toString ? params.value.toString() : '';
    }

    if (
      params?.column?.colDef?.field_type &&
      params?.column?.colDef?.field_type === TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN
    ) {
      return exportMultiSelectMetadata(params);
    }

    if (params?.column?.colDef?.field_type && params?.column?.colDef?.field_type === TYPES_OF_CUSTOM_FIELDS.RICH_TEXT) {
      return removeMarkdown(params?.value);
    }

    if (ADDITIONAL_METADATA_FIELDS.includes(params.column.colId)) {
      return exportMultiSelectMetadata(params);
    }

    const mapProcessColumn = {
      Jiras: ({ value }) => (value instanceof Array && value.length ? value[0].key : ''),
      progress: params => {
        const { value, node } = params;

        const { data } = node || {};

        let valueToUse = value;

        if (data.group) {
          valueToUse = progressValueFormatter({ data, value });
        }

        return valueToUse && valueToUse >= 0 && valueToUse <= 1 ? `${Math.round(valueToUse * 100)}%` : '0%';
      },
      scopeVariance: ({ value }) => {
        const parsedValue = isNil(value) ? null : value.toString();

        return isNumber(parsedValue) && !isNaN(parsedValue) ? `${roundDecimalPlaces(parsedValue * 100, 2)}%` : '0%';
      },
      customers: ({ value }) => (value instanceof Array && value.length ? value.map(option => option.name).join(', ') : ''),
      tags: ({ value }) => (value instanceof Array && value.length ? value.map(option => option.title).join(', ') : ''),
      personas: params => exportMultiSelectMetadata(params),
      lifecycles: params => exportMultiSelectMetadata(params),
      integration: ({ value }) => (value instanceof Object && value.key ? value.key : ''),
      integrationIssueType: ({ node }) => node.data?.integration?.issueType || '',
      status_summary: ({ value }) => removeMarkdown(value),
      details: ({ value }) => removeMarkdown(value),
      links: ({ value }) => removeMarkdown(value),
      status_color: params => exportHealth(params, HEALTH_LABEL_OPTIONS_LABELS),
    };
    const mapFunc = mapProcessColumn[params.column.colId];

    if (mapFunc) return mapFunc(params);
  }

  if (
    params.value &&
    (['start_date', 'end_date', 'estimated_start_date', 'deadline', 'completed_date'].includes(params.column.colId) ||
      ((params.node || {}).data || {}).field_type === 'Date')
  ) {
    return formatDate(moment(params.value));
  }

  return params.value;
};
