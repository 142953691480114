import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import theme from 'design-system/theme';

import FluidField from 'design-system/molecules/FluidField/index';
import DragonTable from 'design-system/organisms/DragonTable/index';


import AddIcon from '@material-ui/icons/Add';

const valueOrPlaceholderStyles = css`
  padding: 0;
`;

const FluidDragonTableField = props => (
  <StyledFluidField
    {...props}
    valueOrPlaceholderStyles={valueOrPlaceholderStyles}
    renderer={value => {
      const [childHeight, setChildHeight] = React.useState({ height: 'auto' });

      return (
        <TableContainer style={{ height: childHeight.height + 16 }} ref={props.tableRef}>
          <DragonTable
            title={props.title}
            hideTableIfEmpty={props.hideTableIfEmpty}
            disableAutosizerHeight
            rows={value}
            allowNewRow={props.allowNewRow}
            newRowButtonTitle={
              <React.Fragment>
                <AddIcon />
                <StyledRowButtonTitle>&nbsp;{props.newRowButtonTitle}</StyledRowButtonTitle>
              </React.Fragment>
            }
            onResize={setChildHeight}
            managedOverflow
            overflowAfter={props.overflowAfter}
            rowHeight={32}
          >
            {props.children}
          </DragonTable>
        </TableContainer>
      );
    }}
    disableEdit
  />
);

const TableContainer = styled.div`
  position: relative;
`;

const StyledFluidField = styled(FluidField)`
  button {
    font-size: ${theme.typography.fontSizeSmall}px;
  }

  svg {
    width: ${theme.typography.fontSize}px;
    height: ${theme.typography.fontSize}px;
    font-size: ${theme.typography.fontSize}px;
  }
`;

const StyledRowButtonTitle = styled.span`
  line-height: normal;
`;

FluidDragonTableField.propTypes = {
  ...FluidField.propTypes,
  newRowButtonTitle: PropTypes.string,
  allowNewRow: PropTypes.bool,
  overflowAfter: PropTypes.number.isRequired,
};

// export columns to prevent also import DragonTable
Object.keys(DragonTable.Columns).forEach(k => (FluidDragonTableField[k] = DragonTable[k]));

export default FluidDragonTableField;
