import React, { useEffect, useMemo, useRef, useState } from 'react';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';

import { useDispatch, useSelector } from 'react-redux';
import { getDragonbotCorpusVersions, updateDragonbotCorpusFulfilled } from 'store/dragonbot/selectors';
import {
  loadDragonbotCorpusVersions,
  updateDragonbotCorpusVersion,
  makeDragonbotCorpusVersionTheCurrent,
} from 'store/dragonbot';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import theme from 'design-system/theme';
import styled from 'styled-components';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
});

const DragonbotTrainingManagerHOC = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const [corpusVersionData, setCorpusVersionData] = useState(null);
  const [expandedIntents, setExpandedIntents] = useState({});
  const [saving, setSaving] = useState(false);
  const [editingFieldValue, setEditingFieldValue] = useState({});

  const fieldsRefs = useRef({});
  const [versionIdBeingEdited, setVersionIdBeingEdited] = useState(null);

  const updateCorpusFulfilled = useSelector(state => updateDragonbotCorpusFulfilled(state));
  const availableCorpusVersions = useSelector(state => getDragonbotCorpusVersions(state));

  const corpusVersionBeingEdited = useMemo(
    () => {
      const corpusVersions = [...availableCorpusVersions]
        .sort((a, b) => moment(b.updated_at).valueOf() - moment(a.updated_at).valueOf());

      if (!versionIdBeingEdited) {
        return corpusVersions[0];
      }

      return corpusVersions.find(corpusVersion => corpusVersion.id === versionIdBeingEdited);
    },
    [availableCorpusVersions, versionIdBeingEdited],
  );

  useEffect(() => {
    if (!updateCorpusFulfilled) {
      return;
    }

    setSaving(false);

    if (updateCorpusFulfilled.id !== versionIdBeingEdited) {
      setVersionIdBeingEdited(updateCorpusFulfilled.id);
    };
  }, [versionIdBeingEdited, updateCorpusFulfilled]);

  useEffect(() => {
    dispatch(loadDragonbotCorpusVersions());
  }, []);

  useEffect(() => {
    if (corpusVersionBeingEdited) {
      setCorpusVersionData(corpusVersionBeingEdited.data.data);
    }
  }, [corpusVersionBeingEdited]);

  const requestCorpusUpdate = () => {
    setSaving(true);
    dispatch(updateDragonbotCorpusVersion(corpusVersionBeingEdited.id, {
      ...corpusVersionBeingEdited.data,
      data: corpusVersionData,
    }));
  };

  const [debouncedCorpusUpdate] = useDebouncedCallback(requestCorpusUpdate, 500);

  const handleChange = (index, field) => event => {
    const corpusVersionRows = [...corpusVersionData];
    const rowToEdit = { ...corpusVersionRows[index] };

    rowToEdit[field] = event.target.value;

    corpusVersionRows[index] = rowToEdit;

    setCorpusVersionData(corpusVersionRows);

    debouncedCorpusUpdate();
  };

  const addIntent = () => {
    setCorpusVersionData(corpusVersionData.concat({
      intent: '',
      utterances: [],
      answers: [],
    }));
  };

  const removeIntent = (index) => {
    const corpusVersionRows = [...corpusVersionData];

    corpusVersionRows.splice(index, 1);

    setCorpusVersionData(corpusVersionRows);

    requestCorpusUpdate();
  };

  const removeUtterance = (intentIndex, utteranceIndex) => {
    const corpusVersionRows = [...corpusVersionData];

    corpusVersionRows[intentIndex].utterances.splice(utteranceIndex, 1);

    setCorpusVersionData(corpusVersionRows);

    requestCorpusUpdate();
  };

  const saveEditedValue = (field, intentIndex, itemIndex) => event => {
    const newEditingValueState = {
      ...editingFieldValue,
      [intentIndex]: {
        ...editingFieldValue?.[intentIndex],
        [field]: {
          ...editingFieldValue?.[intentIndex]?.[field],
          [itemIndex]: event.target.value,
        },
      },
    };

    setEditingFieldValue(newEditingValueState);
  };

  const handleInputBlur = ev => {
    if (ev.relatedTarget && ev.relatedTarget.tagName === 'INPUT') {
      return;
    }

    const corpusVersionRows = [...corpusVersionData];

    Object.entries(editingFieldValue).forEach(([intentIndex, value]) => {
      const utterancesToSave = value?.utterances || {};
      const answersToSave = value?.answers || {};

      Object.entries(utterancesToSave).forEach(([utteranceIndex, value]) => {
        corpusVersionRows[intentIndex].utterances[utteranceIndex] = value;
      });
      Object.entries(answersToSave).forEach(([answerIndex, value]) => {
        corpusVersionRows[intentIndex].answers[answerIndex] = value;
      });
    });

    setCorpusVersionData(corpusVersionRows);

    requestCorpusUpdate();
  };

  const addUtterance = (intentIndex) => {
    const corpusVersionRows = [...corpusVersionData];

    corpusVersionRows[intentIndex].utterances.push('');

    setCorpusVersionData(corpusVersionRows);
  };

  const removeAnswer = (intentIndex, answerIndex) => {
    const corpusVersionRows = [...corpusVersionData];

    corpusVersionRows[intentIndex].answers.splice(answerIndex, 1);

    setCorpusVersionData(corpusVersionRows);

    requestCorpusUpdate();
  };

  const addAnswer = (intentIndex) => {
    const corpusVersionRows = [...corpusVersionData];

    corpusVersionRows[intentIndex].answers.push('');

    setCorpusVersionData(corpusVersionRows);
  };

  const expandIntent = (intentIndex) => {
    const newState = { ...expandedIntents };

    newState[intentIndex] = Boolean(!newState[intentIndex]);

    setExpandedIntents(newState);
  };

  const handleInputRef = (field, index) => el => {
    const currentValue = fieldsRefs.current[field] || [];
    const items = [...currentValue];

    items[index] = el;

    fieldsRefs.current = {
      ...fieldsRefs.current,
      [field]: items,
    };
  };

  const makeThisVersionTheCurrentVersion = () => {
    dispatch(makeDragonbotCorpusVersionTheCurrent(corpusVersionBeingEdited.id));
  };

  const changeCorpusVersion = ev => {
    setVersionIdBeingEdited(Number(ev.target.value));
  };

  const UtterancesList = (intentIndex, corpusIntentData) => {
    return (
      <div style={{ marginBottom: '10px' }}>
        <Typography variant="h6">Utterances</Typography>
        {corpusIntentData.utterances.map((utterance, index) => (
          <RowFlex>
            <TextField
              disabled={saving}
              key={`${corpusIntentData.intent}-${utterance}`}
              value={editingFieldValue?.[intentIndex]?.utterances?.[index] || utterance}
              onChange={saveEditedValue('utterances', intentIndex, index)}
              onBlur={handleInputBlur}
              margin="normal"
              variant="outlined"
              fullWidth
              inputRef={handleInputRef('utterances', index)}
            />
            <IconButton onClick={() => removeUtterance(intentIndex, index)}>
              <Clear />
            </IconButton>
          </RowFlex>
        ))}
        <Button onClick={() => addUtterance(intentIndex)}>Add utterance</Button>
      </div>
    );
  };

  const AnswersList = (intentIndex, corpusIntentData) => {
    return (
      <div>
        <Typography variant="h6">Answers</Typography>
        {corpusIntentData.answers.map((answer, index) => (
          <RowFlex>
            <TextField
              disabled={saving}
              key={`${corpusIntentData.intent}-${answer}`}
              value={editingFieldValue?.[intentIndex]?.answers?.[index] || answer}
              onChange={saveEditedValue('answers', intentIndex, index)}
              onBlur={handleInputBlur}
              margin="normal"
              variant="outlined"
              fullWidth
              inputRef={handleInputRef('answers', index)}
            />
            <IconButton onClick={() => removeAnswer(intentIndex, index)}>
              <Clear />
            </IconButton>
          </RowFlex>
        ))}
        <Button onClick={() => addAnswer(intentIndex)}>Add answer</Button>
      </div>
    );
  };

  const IntentEditorComponent = (index, corpusIntentData) => {
    return (
      <ExpansionPanel
        expanded={Boolean(expandedIntents[index])}
        style={{ marginBottom: '10px' }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            onClick: event => {
              event.stopPropagation();
              expandIntent(index);
              return false;
            },
          }}
        >
          <RowFlex>
            <TextField
              disabled={saving}
              id={`${corpusIntentData.intent}-name`}
              label="Intent name"
              value={corpusIntentData.intent}
              onChange={handleChange(index, 'intent')}
              margin="normal"
              fullWidth
              style={{ marginRight: '10px' }}
              variant="outlined"
            />
            <TextField
              disabled={saving}
              id={`${corpusIntentData.intent}-viewLink`}
              label="Shared View link"
              value={corpusIntentData.viewLink || ''}
              onChange={handleChange(index, 'viewLink')}
              margin="normal"
              variant="outlined"
              fullWidth
            />
            <IconButton onClick={() => removeIntent(index)}>
              <Clear />
            </IconButton>
          </RowFlex>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ display: 'block', width: '100%' }}>
          {UtterancesList(index, corpusIntentData)}
          {AnswersList(index, corpusIntentData)}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  const getCorpusVersionName = corpusVersion => {
    const date = moment(corpusVersion.updated_at).format('YYYY-MM-DD HH:mm:ss');
    const current = !corpusVersion.current ? '' : ' (In use by the bot)';

    return `#${corpusVersion.id} ${date}${current}`;
  };

  if (!corpusVersionData) {
    return <PageLoading />;
  }

  return (
    <div
      className={classes.root}
      style={{
        padding: '15px',
        backgroundColor: theme.palette.background.lightGrey,
      }}
    >
      <Typography style={{ marginBottom: '20px' }} variant="h6">Dragonbot Training data</Typography>
      <RowFlex justifyContent="end" style={{ padding: '1em' }}>
        <Button
          disabled={saving || corpusVersionBeingEdited.current}
          onClick={makeThisVersionTheCurrentVersion}
          variant='primary'
        >
          Make bot use this selected version
        </Button>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="selected-version">
            Selected training data version
          </InputLabel>
          <Select
            autoWidth
            disabled={saving}
            value={corpusVersionBeingEdited.id}
            onChange={changeCorpusVersion}
            input={
              <OutlinedInput
                name="selected-version"
                id="selected-version"
              />
            }
          >
            {availableCorpusVersions.map(corpusVersion => (
              <MenuItem value={corpusVersion.id}>
                <em>{getCorpusVersionName(corpusVersion)}</em>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </RowFlex>
      {corpusVersionData.map((corpusIntent, index) => IntentEditorComponent(index, corpusIntent))}
      <Button onClick={addIntent}>Add new intent</Button>
    </div>
  );
};

const RowFlex = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${({ justifyContent = 'space-between' }) => justifyContent};
  align-items: center;
`;

export default withStyles(styles)(DragonbotTrainingManagerHOC);
