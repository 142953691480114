import React, { useMemo } from 'react';

import UserViewsList from 'containers/UserViewsList';
import Card from 'design-system/molecules/Card/index';

import useUserFavoriteViews from 'hooks/userViews/useUserFavoriteViews';
import useUserViewsList from 'hooks/userViews/useUserViewsList';
import { VIEW_ITEM_VARIANTS } from 'constants/userViews';
import useViewsDialog from 'hooks/userViews/useViewsDialog';
import ShowMoreButton from '../../components/ShowMoreButton';

const VISIBLE_VIEWS_LIMIT = 4;

export default () => {
  const { userViews } = useUserFavoriteViews();
  const { viewsList, ...userViewsListProps } = useUserViewsList(userViews, false);
  const { openDialog: openViewsDialog } = useViewsDialog();

  const initialUserViews = useMemo(() => viewsList.slice(0, VISIBLE_VIEWS_LIMIT), [viewsList]);
  const displayShowMoreButton = viewsList.length > VISIBLE_VIEWS_LIMIT;

  return (
    <Card title="My Favorite Views">
      <UserViewsList {...userViewsListProps} viewsList={initialUserViews} variant={VIEW_ITEM_VARIANTS.widgetList} />
      {displayShowMoreButton ? <ShowMoreButton onClick={openViewsDialog} /> : null}
    </Card>
  );
};
