import axios from 'axios';

import { createThunk } from 'utils/store/thunk';

import { FETCH_SNAPSHOT_DATA } from './types';

const fetchSnapshotDataAction = requestBody => {
  const request = axios.post('/api/v1/reports/snapshot', requestBody);

  return createThunk(FETCH_SNAPSHOT_DATA, request);
};

export { fetchSnapshotDataAction };
