import React from 'react';

import FormLabel from 'design-system/atoms/FormLabel/index';

import Comments from 'components/Comments';

import useMetricDetailsComments from '../hooks/useMetricDetailsComments';
import { CommentsWrapper, HeaderComments } from './styles';

export default ({ metricId }) => {
  const {
    comments,
    hasMoreComments,

    addCommentToMetric,
    fetchNextPage,
    deleteComment,
    editComment,
  } = useMetricDetailsComments({ metricId });

  return (
    <CommentsWrapper>
      <HeaderComments>
        <FormLabel>Comments</FormLabel>
      </HeaderComments>
      <Comments
        onSave={addCommentToMetric}
        comments={comments}
        hasMoreComments={hasMoreComments}
        showMore={fetchNextPage}
        onDelete={deleteComment}
        onEdit={editComment}
      />
    </CommentsWrapper>
  );
};
