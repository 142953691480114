import React from 'react';
import styled from 'styled-components';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

import PageLink from 'design-system/atoms/PageLink/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';


export default function RequestIntegrationInformation({ integrationLabel = '', integrationItemLink = '' }) {
  return (
    <Wrapper>
      <FormControl fullWidth margin="dense">
        <FormLabel component="legend">{integrationLabel}</FormLabel>
        <LinkContainer>
          <TooltipOnOverflow text={integrationItemLink}>
            <PageLink href={integrationItemLink} ellipsis target="_blank" rel="noopener noreferrer">
              {integrationItemLink}
            </PageLink>
          </TooltipOnOverflow>
        </LinkContainer>
      </FormControl>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 5px;
  padding: 15px;
`;

const LinkContainer = styled.div`
  margin-top: 10px;
`;
