import React from 'react';
import styled from 'styled-components';

import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';
import Card from 'design-system/molecules/Card/index';

import useOnboardingGuide from './useOnboardingGuide';

const EDITOR_MAX_HEIGHT = 210;

const OnboardingGuide = () => {
  const { isEditor, onboardingGuide, saveOnboardingGuide } = useOnboardingGuide();

  return (
    <Card title="Get Started Guide">
      <StyledFluidRemirrorWYSIWYGEditor
        key="onboarding_guide"
        value={onboardingGuide}
        content={onboardingGuide}
        disableEdit={!!isEditor}
        onChange={saveOnboardingGuide}
        onBlur={saveOnboardingGuide}
        style={{ minHeight: '80px', maxHeight: '300px', padding: '0' }}
        placeholder="Write your Get Started Guide here"
        smallInput
      />
    </Card>
  );
};

export default OnboardingGuide;

const StyledFluidRemirrorWYSIWYGEditor = styled(FluidRemirrorWYSIWYGEditor)`
  .remirror-editor {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    cursor: text;

    .remirror-is-empty:before {
      font-style: normal;
    }
  }

  .remirror-editor-wrapper {
    max-height: ${EDITOR_MAX_HEIGHT}px;
    overflow-y: auto;
  }
`;
