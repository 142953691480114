import React from 'react';

import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';

import Text from 'design-system/atoms/Text/index';

import CommentIcon from 'design-system/atoms/CommentIcon';
import { calculateRiceScore } from 'utils/index';

const RicePanel = ({
  classes,
  reachScore,
  impactScore,
  confidenceScore,
  effortScore,
  onReachScoreChange,
  onImpactScoreChange,
  onConfidenceScoreChange,
  onEffortScoreChange,
  userCanEdit,
  onSave,
  onCommentsLightboxClick,
}) => {
  const calculatedScore = calculateRiceScore(reachScore, impactScore, confidenceScore, effortScore);

  return (
    <div className={classes.root}>
      <StyledHeader>
        <Text variant="h3">
          RICE Score <span className={classes.riceScore}>{calculatedScore}</span>{' '}
        </Text>
        {onCommentsLightboxClick && <StyledCommentIcon onClick={() => onCommentsLightboxClick('riceScore')} />}
      </StyledHeader>

      <StyledFormControl className={classes.formControl} fullWidth margin="normal">
        <NumberFormat
          customInput={TextField}
          InputLabelProps={{ shrink: true }}
          label="Reach"
          value={reachScore}
          onValueChange={e => onReachScoreChange(e.value ? +e.value : '')}
          onBlur={() => onSave({ reach_score: reachScore })}
          disabled={!userCanEdit}
        />
        {onCommentsLightboxClick && <StyledCommentIcon onClick={() => onCommentsLightboxClick('reach_score')} />}
      </StyledFormControl>

      <StyledFormControl className={classes.formControl} fullWidth margin="normal">
        <NumberFormat
          customInput={TextField}
          InputLabelProps={{ shrink: true }}
          label="Impact"
          value={impactScore}
          onValueChange={e => onImpactScoreChange(e.value ? +e.value : '')}
          onBlur={() => onSave({ impact_score: impactScore })}
          disabled={!userCanEdit}
        />
        {onCommentsLightboxClick && <StyledCommentIcon onClick={() => onCommentsLightboxClick('impact_score')} />}
      </StyledFormControl>

      <StyledFormControl className={classes.formControl} fullWidth margin="normal">
        <NumberFormat
          customInput={TextField}
          InputLabelProps={{ shrink: true }}
          suffix="%"
          label="Confidence"
          value={confidenceScore}
          onValueChange={e => onConfidenceScoreChange(e.value ? +e.value : '')}
          onBlur={() => onSave({ confidence_score: confidenceScore })}
          disabled={!userCanEdit}
        />
        {onCommentsLightboxClick && <StyledCommentIcon onClick={() => onCommentsLightboxClick('confidence_score')} />}
      </StyledFormControl>

      <StyledFormControl className={classes.formControl} fullWidth margin="normal">
        <NumberFormat
          customInput={TextField}
          InputLabelProps={{ shrink: true }}
          label="Effort"
          value={effortScore}
          onValueChange={e => onEffortScoreChange(e.value ? +e.value : '')}
          disabled={!userCanEdit}
          onBlur={() => onSave({ effort_score: effortScore })}
        />
        {onCommentsLightboxClick && <StyledCommentIcon onClick={() => onCommentsLightboxClick('effort_score')} />}
      </StyledFormControl>
    </div>
  );
};

const StyledCommentIcon = styled(CommentIcon)`
  &&&&:hover {
    cursor: pointer;
  }
`;

const StyledHeader = styled.div`
  &&&& {
    display: flex;
    justify-content: space-between;
  }
`;

const styles = theme => ({
  root: {
    backgroundColor: 'rgb(249, 244, 255)',
    padding: 12,
  },
  riceScore: {
    fontWeight: 'normal',
    paddingLeft: 10,
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const StyledFormControl = styled(FormControl)`
  input {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  }
`;

export default withStyles(styles, { name: 'RicePanel' })(RicePanel);
