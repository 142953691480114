import {
  UPDATE_IDEAS_BOARD_STATE,
  UPDATE_EMPTY_LANE_VISIBILITY,
} from './types';

export const updateState = (state, makesActiveViewDirty = true) => ({
  payload: state,
  type: UPDATE_IDEAS_BOARD_STATE,
  meta: { makesActiveViewDirty },
});

export const updateEmptyLaneVisibility = value => ({
  payload: value,
  type: UPDATE_EMPTY_LANE_VISIBILITY,
  meta: { makesActiveViewDirty: true },
});

export const ideasBoardActions = {
  updateState,
  updateEmptyLaneVisibility,
};
