import React, { memo } from 'react';
import styled from 'styled-components';

import { PROGRESS_PERCENTAGE_DECIMAL_PLACES } from 'constants/common';
import formatPercent from 'design-system/utils/formatPercent';
import Text from 'design-system/atoms/Text/index';


const TooltipContent = memo(({ name, title, startDate, endDate, predictedEndDate, progress }) => {
  return (
    <>
      <TooltipLabel>
        <TooltipStrongLabel>Title:</TooltipStrongLabel> {title}
      </TooltipLabel>
      {name && (
        <TooltipLabel>
          <TooltipStrongLabel>Owner Name:</TooltipStrongLabel> {name}
        </TooltipLabel>
      )}
      <TooltipLabel>
        <TooltipStrongLabel>StartDate:</TooltipStrongLabel> {startDate}
      </TooltipLabel>
      <TooltipLabel>
        <TooltipStrongLabel>EndDate:</TooltipStrongLabel> {endDate}
      </TooltipLabel>
      {predictedEndDate && (
        <TooltipLabel>
          <TooltipStrongLabel>PredictedEndDate:</TooltipStrongLabel> {predictedEndDate}
        </TooltipLabel>
      )}
      {progress && (
        <TooltipLabel>
          <TooltipStrongLabel>Progress:</TooltipStrongLabel> {formatPercent(progress, PROGRESS_PERCENTAGE_DECIMAL_PLACES)}
        </TooltipLabel>
      )}
    </>
  );
});

export default TooltipContent;

const TooltipLabel = styled(Text)`
  &&&& {
    padding: 0;
    margin-bottom: 0.5rem;
    word-wrap: break-word;
  }
`;

const TooltipStrongLabel = styled.span`
  &&&& {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }
`;
