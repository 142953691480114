import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/EditOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { spacing } from 'design-system/theme';
import Roadmap from 'design-system/atoms/DragonIcons/Roadmap';
import MonitoringIcon from 'design-system/atoms/DragonIcons/Monitoring';
import { brandColor } from 'design-system/themes/default';

import SearchBar from 'components/SearchBar';
import ShareView from 'containers/ShareView';
import InlineDateRange from 'design-system/organisms/InlineDateRange/InlineDateRange';
import SettingsToggle from 'design-system/atoms/SettingsToggle/index';
import Dropdown from 'design-system/molecules/Dropdown/index';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import ToggleButton from 'design-system/molecules/ToggleButton/index';


const SEARCH_BAR_STYLES = { width: 160 };

const getEmptyMetricRow = () => ({ name: '' });

const GridControls = React.memo(
  ({
    allowActions,
    addMetricWithoutSave = () => {},
    isHidingArchivedMetrics = true,
    searchText = '',
    toggleIsHidingArchivedMetrics,
    updateSearchText,
    moreButtonRef,
    showArchivedToggle = true,
    showMoreOptions = true,
    showDateField = false,
    showClusters = false,
    pageId,
    inEditMode,
    toggleEditMode,
    showMoarToggle = false,
    dropdownOptions = [],
    startDate,
    endDate,
    handleDateRangeChange,
    areMetricClustersVisible,
    hasProjectMetrics,
    userCanEditChartView,
    userCanViewChartToggle,
    handleChartClustersToggle,
    isMetricMoarVisible,
    toggleShowMoar,
  }) => {
    const shouldShowEditModeIcon = !!toggleEditMode;

    const showDateRangeField = useMemo(() => showDateField && userCanViewChartToggle, [showDateField, userCanViewChartToggle]);

    const showClustersToggle = useMemo(
      () => showClusters && userCanViewChartToggle && hasProjectMetrics,
      [showClusters, userCanViewChartToggle, hasProjectMetrics],
    );

    const renderEditModeToggle = () =>
      shouldShowEditModeIcon ? (
        <ToggleButton
          id="metrics_chart_edit_mode"
          icon={<EditIcon fontSize="inherit" />}
          on={inEditMode}
          onChange={toggleEditMode}
          title="Edit Layout"
          disabled={!userCanEditChartView}
        />
      ) : null;

    return (
      <ControlsContainer>
        <Flex justifyContent="space-between">
          <Flex flex="1">
            {allowActions ? (
              <AddNewButton id="metric-add-new-button" color="primary" onClick={() => addMetricWithoutSave(getEmptyMetricRow())}>
                + New
              </AddNewButton>
            ) : null}

            {showDateRangeField ? (
              <InlineDateRange
                label="Time Period:"
                startDate={startDate}
                endDate={endDate}
                onChange={handleDateRangeChange}
                disableEdit={!userCanEditChartView}
              />
            ) : null}
          </Flex>

          <Flex justifyContent="flex-end" gap={`${spacing(0.5)}px`} flex="1">
            <SearchContainer>
              <SearchBar
                searchString={searchText}
                updateSearchString={updateSearchText}
                placeholder="Search this page"
                style={SEARCH_BAR_STYLES}
              />
            </SearchContainer>
            {showArchivedToggle && (
              <SettingsToggle
                isChecked={isHidingArchivedMetrics}
                onToggle={toggleIsHidingArchivedMetrics}
                label="Hide archived"
              />
            )}
            <ActionButtonsContainer>
              <ShareView pageId={pageId} />
              {showClustersToggle ? (
                <ToggleButton
                  id="display_projects"
                  icon={<Roadmap />}
                  on={areMetricClustersVisible}
                  onChange={handleChartClustersToggle}
                  title="Display linked portfolio items"
                  disabled={!userCanEditChartView}
                />
              ) : null}
              {showMoarToggle && (
                <ToggleButton
                  id="display_metrics_moar"
                  icon={<MonitoringIcon fill={isMetricMoarVisible && brandColor} />}
                  on={isMetricMoarVisible}
                  onChange={toggleShowMoar}
                  title="Show MoAR"
                  disabled={!userCanEditChartView}
                />
              )}
              {!isMetricMoarVisible && renderEditModeToggle()}
              {showMoreOptions ? (
                <MoreDropdownContainer ref={moreButtonRef}>
                  <Dropdown
                    placeholder={
                      <ButtonIcon>
                        <MoreHorizIcon />
                      </ButtonIcon>
                    }
                    options={dropdownOptions}
                    isButton={false}
                  />
                </MoreDropdownContainer>
              ) : null}
            </ActionButtonsContainer>
          </Flex>
        </Flex>
      </ControlsContainer>
    );
  },
);

export default GridControls;

const ControlsContainer = styled.div`
  margin: ${spacing(0.5)}px auto;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};

  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `}
`;

const AddNewButton = styled(Button)``;

const MoreDropdownContainer = styled.div``;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(0.5)}px;
`;

const SearchContainer = styled.div`
  input::placeholder {
    color: ${({ theme }) => theme.palette.text.quickSilver};
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  }
`;
