import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flatten } from 'ramda';

import { getGridConfigValue } from 'store/grids/selectors';
import {
  COMPARE_ROADMAP_HISTORY,
  GRID_COMPARE_FIELDS_CONFIG,
  ROADMAP_HISTORY_GRID_AVAILABLE_COLUMNS,
} from 'features/RoadmapHistory/constants';

import { saveGridConfig } from 'store/grids';

// temp
import {
  GROUP_CHECKBOXES,
  GROUP_MULTISELECT,
  CUSTOM_FIELDS_KEY,
} from 'containers/IdeasList/IdeasListControlsBar/GridShowColumns/helpers';

const filterOnlyCheckboxesGroups = g => g.type === GROUP_CHECKBOXES;
const filterOnlyMultiselectGroups = g => g.type === GROUP_MULTISELECT;
const excludeCustomFieldsGroup = g => g.key !== CUSTOM_FIELDS_KEY;

export const defaultColumnsToCompareVisible = ['timeframeTitle', 'progress', 'status_color'];

const filterByDuplicatedFields = (acc, field) => {
  if (acc.some(f => f.field === field.field) || !ROADMAP_HISTORY_GRID_AVAILABLE_COLUMNS.includes(field.field)) {
    return acc;
  }

  return [...acc, field];
};

const ignoreComparableFieldsOnVisibleFields = (visibleColumnsOnGrid, columnsToCompareIds) =>
  visibleColumnsOnGrid.filter(id => !columnsToCompareIds.includes(id));

const checkIfFieldDoesNotExist = (field, fieldList) =>
  Array.isArray(field) ? !field.some(field => fieldList.includes(field)) : !fieldList.includes(field);

const useShowColumnsRoadmapHistory = ({ visibleColumnsOnGrid, isOpen, gridColumns }) => {
  const columnsToCompareIds = useSelector(state =>
    getGridConfigValue(state, COMPARE_ROADMAP_HISTORY, GRID_COMPARE_FIELDS_CONFIG, defaultColumnsToCompareVisible),
  );
  const selectedGroup1 = useSelector(state => getGridConfigValue(state, COMPARE_ROADMAP_HISTORY, 'selectedGroup1'));
  const dispatch = useDispatch();
  const [visibleColumns, setVisibleColumns] = useState(
    ignoreComparableFieldsOnVisibleFields(visibleColumnsOnGrid, columnsToCompareIds),
  );
  const [columnsToCompare, setColumnsToCompare] = useState(columnsToCompareIds);

  useEffect(() => setVisibleColumns(ignoreComparableFieldsOnVisibleFields(visibleColumnsOnGrid, columnsToCompareIds)), [isOpen]);

  const handleChangeColumnVisible = (field, visible) => {
    if (visible) {
      setVisibleColumns([...visibleColumns, ...(Array.isArray(field) ? field : [field])]);

      return;
    }

    setVisibleColumns(visibleColumns.filter(c => (Array.isArray(field) ? !field.includes(c) : c !== field)));
  };

  const hideColumns = columnsKeysToHide => {
    setVisibleColumns(visibleColumns.filter(c => !columnsKeysToHide.includes(c)));
  };

  const handleChangeComparableColumnVisible = (field, visible) => {
    if (visible) {
      setColumnsToCompare([...columnsToCompare, ...(Array.isArray(field) ? field : [field])]);

      return;
    }

    setColumnsToCompare(columnsToCompare.filter(c => (Array.isArray(field) ? !field.includes(c) : c !== field)));
  };

  const hideComparableColumns = columnsKeysToHide => {
    setColumnsToCompare(columnsToCompare.filter(c => !columnsKeysToHide.includes(c)));
  };

  const checkboxesGroups = gridColumns.filter(filterOnlyCheckboxesGroups).filter(excludeCustomFieldsGroup);

  /*
   * Will create a custom group fields to have all multiselect groups
   */
  const { genericMultiselectGroup, compareMultiselectGroup } = useMemo(() => {
    const allMultiSelectGroups = gridColumns.filter(filterOnlyMultiselectGroups);
    const allFields = flatten(allMultiSelectGroups.map(g => g.fields));
    const fields = allFields.reduce(filterByDuplicatedFields, []);

    const visibleFields = fields.filter(({ field }) => checkIfFieldDoesNotExist(field, columnsToCompare));
    const comparableFields = fields.filter(
      ({ field, headerName }) => checkIfFieldDoesNotExist(field, visibleColumns) && headerName !== selectedGroup1?.title,
    );

    return {
      genericMultiselectGroup: {
        type: GROUP_MULTISELECT,
        title: '',
        fields: visibleFields,
      },
      compareMultiselectGroup: {
        type: GROUP_MULTISELECT,
        title: 'Fields to compare',
        fields: comparableFields,
      },
    };
  }, [gridColumns, columnsToCompare, visibleColumns]);

  const changeCompareColumns = () => {
    dispatch(saveGridConfig(COMPARE_ROADMAP_HISTORY, GRID_COMPARE_FIELDS_CONFIG, columnsToCompare));
  };

  return {
    visibleColumns,
    columnsToCompare,
    checkboxesGroups,
    genericMultiselectGroup,
    compareMultiselectGroup,

    handleChangeColumnVisible,
    hideColumns,
    handleChangeComparableColumnVisible,
    hideComparableColumns,
    changeCompareColumns,
  };
};

export default useShowColumnsRoadmapHistory;
