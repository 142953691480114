import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import withTheme from '@material-ui/core/styles/withTheme';
import useResizeObserver from 'use-resize-observer';

import { toUpper } from 'ramda';

import DndList from 'design-system/organisms/DndList/index';
import MetadataFluidField from 'design-system/molecules/MetadataFluidField/index';
import MultiFilterClearRow from 'design-system/atoms/MultiFilterClearRow/index';


import MetadataRoadmapChips from './components/MetadataRoadmapChipsComponent';

const FluidMetadataRoadmapsField = props => {
  const {
    data,
    formatRoadmaps,
    handleClear,
    objectType,
    removeMetadataRoadmap,
    roadmaps,
    selected,
    getSelectedItems,
    updateSelectedItems,
    disableEdit,
  } = props;

  const popoverRef = useRef(null);
  const [popoverActionCallback, setPopoverActionCallback] = useState();
  const { ref: listRef, height: listHeight } = useResizeObserver();
  const updateCallback = callback => {
    setPopoverActionCallback(callback);
  };

  useEffect(() => {
    if (popoverActionCallback?.updatePosition instanceof Function) popoverActionCallback.updatePosition();
  }, [listHeight, popoverActionCallback]);

  // const [items, setItems] = useState(roadmaps);

  // TODO: FOR SEARCH FUNC
  // const [search, setSearch] = useState('');

  const renderClear = () => {
    return <MultiFilterClearRow handleClear={handleClear} item={selected} text="Clear All" />;
  };

  const selectedRoadmaps = getSelectedItems ? getSelectedItems(`${toUpper(objectType)}${selected?.id}`) : {};

  // TODO: RE-INTRODUCE SEARCH
  // const renderSearch = () => {
  //   return <MultiFilterSearchRow handleChange={handleSearchChange} search={search} />;
  // };

  // const handleSearchChange = e => {
  //   const searchString = e.target.value;

  //   setSearch(searchString);
  //   setItems(searchString ? searchMenuItems(searchString, roadmaps) : roadmaps);
  // };

  const handleRemoveRoadmap = roadmap => {
    if (disableEdit) {
      return;
    }

    removeMetadataRoadmap(roadmap, selected);
  };

  return (
    <div ref={popoverRef}>
      <MetadataFluidField
        {...props}
        renderer={() => <MetadataRoadmapChips values={data} handleDelete={handleRemoveRoadmap} />}
        editorRenderer={({ anchorEl, cancel, isEditing, disableEdit }) => (
          <PopoverContainer
            action={updateCallback}
            anchorEl={disableEdit ? null : popoverRef.current}
            open={isEditing}
            parent={anchorEl}
          >
            <ClickAwayListener onClickAway={cancel}>
              <div ref={listRef}>
                <DndList
                  checkable
                  items={formatRoadmaps(roadmaps) || []}
                  onSelectItem={(...params) => updateSelectedItems([...params], selected)}
                  renderClear={renderClear}
                  // renderSearch={renderSearch}
                  selectedItems={selectedRoadmaps}
                  showIndeterminate
                  width={anchorEl?.offsetWidth}
                  openByDefault
                />
              </div>
            </ClickAwayListener>
          </PopoverContainer>
        )}
        forceInputRenderingOnEdit
      />
    </div>
  );
};

const PopoverContainer = styled(Popover)`
  &&&&& {
    left: ${({ parent }) => parent?.offsetLeft}px;
    z-index: ${({ theme }) => theme.zIndex.okrDrawerDialogs};
  }
`;

export default withTheme()(FluidMetadataRoadmapsField);
