const snapToGridOptions = {
  TARGET_START_DATE: 'startDate',
  TARGET_END_DATE: 'endDate',
  PREDICTED_END_DATE: 'predictedEndDate',
};

const isSnapToEnd = option => [snapToGridOptions.TARGET_END_DATE, snapToGridOptions.PREDICTED_END_DATE].includes(option);
const isSnapToPredictedEnd = option => option === snapToGridOptions.PREDICTED_END_DATE;

const getTargetDateForSnap = (item, zoomMode, snapToGridOn = snapToGridOptions.TARGET_START_DATE) => {
  if (!item[snapToGridOn]) {
    return null;
  }

  const targetDate = item[snapToGridOn].clone();

  return isSnapToEnd(snapToGridOn) ? targetDate.endOf(zoomMode) : targetDate.startOf(zoomMode);
};

export { snapToGridOptions, isSnapToEnd, isSnapToPredictedEnd, getTargetDateForSnap };
