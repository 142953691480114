import isNumber from 'lodash/isNumber';

import { GRID_LAYOUT_ROW_HEIGHT, WIDGET_HEIGHT_OFFSET } from '../../constants/widgetsConfig';

export const MAX_WIDGET_HEIGHT = 3;
const MIN_WIDGET_HEIGHT = 0.75;

const clampWidgetHeight = height => Math.min(Math.max(height, MIN_WIDGET_HEIGHT), MAX_WIDGET_HEIGHT);

export const getWidgetHeight = editorHeight => {
  const contentHeight = isNumber(editorHeight) ? editorHeight : 0;
  const widgetHeight = contentHeight / GRID_LAYOUT_ROW_HEIGHT;

  return clampWidgetHeight(widgetHeight + WIDGET_HEIGHT_OFFSET);
};
