import React, { Component } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';

import { LEAD_LINES_PLUGIN } from 'components/ReactChart/utils/plugins';

import DoughnutChart from 'components/ReactChart/DoughnutChart';
import BarChart from 'components/ReactChart/BarChart';
import SecondaryColorPicker from 'design-system/atoms/SecondaryColorPicker/index';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';

import { materialColorsArr } from 'design-system/themes/default';
import AllocationReportSummaryTable from 'containers/AllocationReportSummaryTable';

import PreferencesDialog from '../PreferencesDialog/index';
import Alert from './Alert';
import { generatePieChartData, generateBarChartData, getSummaryDataFromPieData } from '../utils';
import getLegendBoxConfig from 'utils/charts/getLegendBoxConfig';

export default class AllocationReport extends Component {
  state = {
    isLoaded: false,
  };

  constructor(props) {
    super(props);

    this.props.updateState({});
  }

  async componentDidMount() {
    Promise.all([
      this.props.fetchObjectives(),
      this.props.fetchRoadmaps(),
      this.props.fetchThemes(),
      this.props.fetchTimeframes(),
      this.props.fetchTeams(),
      this.props.fetchSkills(),
      this.props.fetchCategories(),
      this.props.fetchTags(),
      this.props.fetchCustomers(),
      this.props.fetchPhases(),
    ]);

    this.setState({ isLoaded: true });
  }

  handleColorPickStart = (e, data) => {
    const rect = e.currentTarget.getBoundingClientRect();

    if (!data.id) {
      return;
    }
    this.props.updateState({
      showColorPicker: data,
      pickerLeft: rect.left + 40,
      pickerTop: rect.top - 120,
    });
  };

  handleColorPickComplete = color => {
    const { lsState, updateGroupColor } = this.props;
    const entity = this.props[`${lsState.dataType.key}s`].find(e => e.id === lsState.showColorPicker.id);

    updateGroupColor(lsState.dataType)({ id: entity.id }, color.hex);

    this.props.updateState({ showColorPicker: { ...entity, color } });
  };

  onColorPickerOut = () => {
    this.props.updateState({ showColorPicker: false });
  };

  onTabChange = (e, v) => {
    this.props.updateState({ currentTab: v });
  };

  render() {
    if (!this.state.isLoaded) {
      return <PageLoading />;
    }
    const { classes, lsState, projects } = this.props;
    // const projects = this.props.projects;
    const pieData = generatePieChartData({
      ...this.props,
      projects,
    });

    const teamBarData = generateBarChartData({ ...this.props, projects }, 'team');
    const skillBarData = generateBarChartData({ ...this.props, projects }, 'skill');
    const { total, tableData } = getSummaryDataFromPieData(pieData, lsState.duration, this.props, lsState.selectedGroupBy);
    const pieOptions = {
      plugins: {
        legend: {
          display: false,
        },
      },
      radius: '67%',
    };

    const barOptions = {
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            ...getLegendBoxConfig(),
          },
        },
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };

    return (
      <div className={classes.root} id="allocation-page-container">
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <Tabs value={lsState.currentTab} indicatorColor="primary" textColor="primary" onChange={this.onTabChange} centered>
              <Tab label="Allocation Overview" />
              <Tab label="By Team" />
              <Tab label="By Skill" />
            </Tabs>
          </Grid>
          <Grid item xs={3}>
            <PreferencesDialog hideIcon />
          </Grid>
        </Grid>
        <div className={classes.mainContainer}>
          <div className={classes.pieContainer}>
            {lsState.currentTab === 0 && <DoughnutChart data={pieData} options={pieOptions} plugins={[LEAD_LINES_PLUGIN]} />}
            {lsState.currentTab === 1 && <BarChart data={teamBarData} options={barOptions} />}
            {lsState.currentTab === 2 && <BarChart data={skillBarData} options={barOptions} />}
          </div>
          <div className={classes.table}>
            <AllocationReportSummaryTable
              data={tableData}
              total={total}
              lsState={lsState}
              handleColorPickStart={this.handleColorPickStart}
              projects={projects}
            />
          </div>
        </div>

        {lsState.showColorPicker && (
          <ColorPickerContainer
            style={{
              top: lsState.pickerTop,
              left: lsState.pickerLeft,
            }}
          >
            <SecondaryColorPicker
              colors={materialColorsArr}
              color={lsState.showColorPicker.color}
              onChange={this.handleColorPickComplete}
              handleClose={this.onColorPickerOut}
            />
          </ColorPickerContainer>
        )}

        <Alert isOpen={lsState.alertOpen} onCancel={() => this.props.updateState({ alertOpen: false })} />
      </div>
    );
  }
}

const ColorPickerContainer = styled.div`
  position: fixed;
`;

const Tab = styled(MuiTab)`
  &&&& {
    text-transform: initial;
    font-size: ${({ theme }) => theme.typography.fontSize}px;
  }
`;
