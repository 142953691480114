import reduceReducers from 'reduce-reducers';
import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  SET_DEMO_SPLASH_PAGE_SHOWN,
  TOGGLE_DEMO_SPLASH_PAGE_VISIBILIY,
  FETCH_SELF_GUIDED_DEMO_DATA,
  FETCH_SELF_GUIDED_DEMO_DATA_FULFILLED,
} from './types';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  FETCH_SELF_GUIDED_DEMO_DATA,
]);

const initialState = {
  selfGuidedDemoData: null,
  demoSplashPageShown: false,
  demoSplashPageOpen: false,
  ...thunksInitialState,
};

const onboardingDemoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEMO_SPLASH_PAGE_SHOWN:
      return {
        ...state,
        demoSplashPageShown: true,
      };
    case TOGGLE_DEMO_SPLASH_PAGE_VISIBILIY:
      return {
        ...state,
        demoSplashPageOpen: action.payload,
      };
    case FETCH_SELF_GUIDED_DEMO_DATA_FULFILLED:
      return {
        ...state,
        selfGuidedDemoData: action.payload,
      };
    default:
      return state;
  }
};

export { initialState };

const reducer = reduceReducers(initialState, onboardingDemoReducer, ...thunksReducers);

export default reducer;
