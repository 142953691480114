import { useDispatch, useSelector } from 'react-redux';
import { selectWhiteboardLayoutConfig } from 'features/whiteboard/store/selectors';
import { updateLayoutConfig } from 'features/whiteboard/store';
import {
  BOARD,
  HIGHLIGHT_HEALTH,
  METRICS,
  OKRS,
  OKRS_METRICS,
  OKRS_PROJECTS,
  OWNER_AVATAR,
  PROGRESS,
  STATUS,
  PROJECTS,
  TIMEFRAMES,
} from 'features/Canvas/constants';

const useHandleConfigChanges = () => {
  const dispatch = useDispatch();
  const whiteboardLayoutConfig = useSelector(selectWhiteboardLayoutConfig);
  const { relationsConfig, displayPreferences } = whiteboardLayoutConfig;

  const makeToggleRelations = key => () =>
    dispatch(
      updateLayoutConfig({
        ...whiteboardLayoutConfig,
        relationsConfig: {
          ...relationsConfig,
          [key]: !relationsConfig?.[key],
        },
      }),
    );

  const makeTogglePreferences = key => () =>
    dispatch(
      updateLayoutConfig({
        ...whiteboardLayoutConfig,
        displayPreferences: {
          ...displayPreferences,
          [key]: !displayPreferences?.[key],
        },
      }),
    );

  const handleDisplayNodesChange = values =>
    dispatch(
      updateLayoutConfig({
        ...whiteboardLayoutConfig,
        displayNodes: values,
      }),
    );

  return {
    onToggleOkrRelations: makeToggleRelations(OKRS),
    onToggleOkrMetricsRelations: makeToggleRelations(OKRS_METRICS),
    onTogglePortfolioItemsRelations: makeToggleRelations(PROJECTS),
    onToggleTimeframeRelations: makeToggleRelations(TIMEFRAMES),
    onToggleBoardRelations: makeToggleRelations(BOARD),
    onToggleMetricRelations: makeToggleRelations(METRICS),
    onToggleOkrProjectsRelations: makeToggleRelations(OKRS_PROJECTS),
    onToggleHighlightHealth: makeTogglePreferences(HIGHLIGHT_HEALTH),
    onToggleOwnerAvatar: makeTogglePreferences(OWNER_AVATAR),
    onToggleProgress: makeTogglePreferences(PROGRESS),
    onToggleStatus: makeTogglePreferences(STATUS),
    handleDisplayNodesChange,
  };
};

export default useHandleConfigChanges;
