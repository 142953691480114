import styled from 'styled-components';

import { spacing } from 'design-system/theme';
import Loading from 'design-system/atoms/Loading/Loading';


const CenteredLoading = styled(Loading)`
  margin: ${spacing(5)}px auto;
  text-align: center;
`;

export default CenteredLoading;
