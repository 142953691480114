import { isNil, keys } from 'ramda';
import { useMemo } from 'react';

import useConfigurableChartData from './useConfigurableChartData';
import { generateDataForChart } from '../helpers';

const checkHasChartData = (chartData = {}) => Boolean(keys(chartData.datasets).length);

const DEFAULT_CHART_DATA = {
  datasets: [],
  labels: [],
};

/**
 * A custom hook for fetching and preparing configurable chart data for a bar chart.
 *
 * @param {Object} options - Configuration options for fetching chart data.
 * @param {string} options.groupBy - The grouping parameter for the chart data.
 * @param {string} options.stackBy - The stacking parameter for the chart data.
 * @returns {Object} An object containing prepared chart data and a flag indicating data presence.
 */
const useConfigurableChartDataForPieChart = ({ groupBy, label }) => {
  const { chartData, bucketInformation, groupsMetadata, title, subtitle } = useConfigurableChartData({ groupBy, label });

  const data = useMemo(() => {
    if (isNil(chartData)) {
      return DEFAULT_CHART_DATA;
    }

    return generateDataForChart(chartData, groupsMetadata, null, bucketInformation);
  }, [chartData, groupsMetadata]);

  const hasData = useMemo(() => checkHasChartData(data), [data]);

  return {
    data,
    hasData,
    title,
    subtitle,
  };
};

export default useConfigurableChartDataForPieChart;
