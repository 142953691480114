import { equals, isNil } from 'ramda';
import { INCLUDE_ALL_OPTION } from 'constants/projects';

import isBoolean from 'lodash/isBoolean';

import { FORECAST_BY_HEADCOUNT } from 'constants/forecast';

const checkIsForecastByHeadCount = equals(FORECAST_BY_HEADCOUNT);
// if the prop was saved with true/false the default should be 'Include All' option
const getShowUncommittedProjectsWithRetroCompatibility = showUncommittedProjects =>
  isBoolean(showUncommittedProjects) ? INCLUDE_ALL_OPTION : showUncommittedProjects;

const shouldConsiderValueForAggregation = params => {
  const { data, context: { showUncommittedProjects, forecastBy } = {} } = params;

  // Grids that does not support filter by above the line
  if (isNil(showUncommittedProjects) && isNil(forecastBy)) {
    return true;
  }
  const selectedOption = getShowUncommittedProjectsWithRetroCompatibility(showUncommittedProjects);
  const isForecastByHeadCount = checkIsForecastByHeadCount(forecastBy);
  const timeAndCapacityProjectIncluded = !isForecastByHeadCount && (selectedOption === INCLUDE_ALL_OPTION || data?.committed);
  const headcountProjectIncluded = isForecastByHeadCount && data?.includeInSums;

  return timeAndCapacityProjectIncluded || headcountProjectIncluded;
};

export default shouldConsiderValueForAggregation;
