import { useEffect } from 'react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import { openObjectiveDrawer } from 'store/objectives';

export default function (isOpen, selectedObjective, selectedType) {
  const dispatch = useDispatch();

  useEffect(() => {
    const params = queryString.parse(window.location.search.substring(1));

    if (
      !isOpen &&
      params.objectiveType &&
      params.openObjective &&
      !Number.isNaN(Number(params.objectiveType)) &&
      !Number.isNaN(Number(params.openObjective))
    ) {
      dispatch(openObjectiveDrawer(+params.openObjective, +params.objectiveType));
    } else if (selectedObjective && selectedObjective.id) {
      const queryParameters = `?objectiveType=${selectedType}&openObjective=${selectedObjective.id}`;
      const objectiveUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${queryParameters}`;

      window.history.pushState('', '', objectiveUrl);
    }
  }, [(selectedObjective || {}).id]);
}
