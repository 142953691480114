import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import { addObjective, removeObjectiveById, updateKeyResultRowOrder } from './actions';

import { clearSelectedItems } from 'store/grids';
import { OBJECTIVES } from 'store/grids/constants';
import {
  MERGE_OBJECTIVES,
  FETCH_OBJECTIVES,
  CREATE_OBJECTIVES,
  UPDATE_OBJECTIVES,
  UNDO_CREATE_OBJECTIVES,
  UNDO_UPDATE_OBJECTIVES,
  BULK_DELETE_OBJECTIVES,
  UNDO_BULK_DELETE_OBJECTIVES,
  UPDATE_KEY_RESULT_ID,
  MOVE_KEY_RESULT_TO_OBJECTIVE,
  MOVE_KEY_RESULT_TO_KEY_RESULT,
  DELETE_KEY_RESULT,
  MERGE_KEY_RESULTS,
  BULK_DELETE_KEY_RESULTS,
  UNDO_BULK_DELETE_KEY_RESULTS,
  UPDATE_OBJECTIVE_ROW_ORDER,
  REFRESH_KEY_RESULT_BY_ID,
  ADD_OBJECTIVE_METRIC,
  ADD_KEY_RESULT_METRIC,
  CREATE_AND_ADD_METRIC_TO_OBJECTIVE,
  CREATE_AND_ADD_METRIC_TO_KEY_RESULT,
  REMOVE_KEY_RESULT_METRIC,
  REMOVE_OBJECTIVE_METRIC,
  BULK_ADD_KEY_RESULT_METRICS,
  BULK_REMOVE_KEY_RESULT_METRICS,
  BULK_ADD_OBJECTIVE_METRICS,
  BULK_REMOVE_OBJECTIVE_METRICS,
} from './types';
import moveRowToPosition from 'utils/moveRowToPosition';

import { getSelected } from './selectors';

import throwRequestError from '../utils/throwRequestError';
import bulkCreateAction from 'store/utils/factory/bulkCreateAction';
import bulkUpdateAction from 'store/utils/factory/bulkUpdateAction';
import bulkDeleteAction from 'store/utils/factory/bulkDeleteAction';
import fetchMetadataAction from 'store/utils/factory/fetchMetadataAction';

import groomUpdateObject from './helpers/groomUpdateObject';
import { callAddMetricToOkr, callCreateMetric } from './helpers/okrMetrics';
import { METADATA_LEVELS } from 'constants/common';
import { createThunk } from 'utils/store/thunk';
import { isRowIdUndefined } from './helpers';

export const mergeKeyResults = (keyResultsIdsToMerge, keyResultId) => {
  return async dispatch => {
    const payload = await axios
      .post(`/api/keyResults/merge/${keyResultId}`, {
        keyResultsIdsToMerge,
      })
      .then(response => {
        return response.data;
      })
      .catch(throwRequestError);

    await dispatch({
      type: MERGE_KEY_RESULTS,
      payload,
    });

    dispatch(clearSelectedItems(OBJECTIVES));

    return dispatch(fetchObjectives());
  };
};

export const updateKeyResultById = (id, update) => {
  return (dispatch, getState) => {
    const groomedUpdate = groomUpdateObject(update);

    if (isEmpty(groomedUpdate)) {
      const state = getState();
      const { selected } = getSelected(state);

      dispatch({
        type: REFRESH_KEY_RESULT_BY_ID,
        payload: selected,
      });
      return;
    }

    const payload = axios
      .put(`/api/keyResults/${id}`, groomedUpdate)
      .then(response => response.data)
      .catch(throwRequestError);

    dispatch({
      type: UPDATE_KEY_RESULT_ID,
      payload,
    });

    return payload;
  };
};

export function switchKeyResultsRowOrder(keyResultId1, keyResultId2, _, position) {
  return dispatch => {
    if (isRowIdUndefined(keyResultId1) || isRowIdUndefined(keyResultId2)) {
      return;
    }

    return axios
      .put(`/api/keyResults/rowOrder/${keyResultId1}/${keyResultId2}`, { position })
      .then(response => {
        const keyResult = response.data;

        dispatch(updateKeyResultRowOrder(keyResult));
      })
      .catch(throwRequestError);
  };
}

export function moveKeyResultToObjective(keyResult, objective, overKeyResult, position) {
  return async dispatch => {
    let objectiveId;
    let parentId;
    let keyResultId2;

    if (overKeyResult) {
      if (overKeyResult.level === 0 && keyResult.level === 1) {
        parentId = overKeyResult.id;
      } else if (overKeyResult.level === 1 && keyResult.level === 1) {
        parentId = overKeyResult.parent_id;
      }
      objectiveId = overKeyResult.objective_id;
      keyResultId2 = overKeyResult.id;
    } else {
      objectiveId = objective.id;
      keyResultId2 = objective.keyResults && objective.keyResults.length ? objective.keyResults[0].id : null;
      position = 'top';
    }

    if (objectiveId === keyResult.objective_id && (!parentId || parentId === keyResult.parent_id)) return;

    let firstPayload;
    let secondPayload;

    try {
      firstPayload = await axios.put(`/api/keyResults/${keyResult.id}`, {
        ...keyResult,
        objective_id: objectiveId,
        parent_id: parentId,
      });
      if (keyResultId2) {
        secondPayload = await axios.put(`/api/keyResults/rowOrder/${keyResult.id}/${keyResultId2}`, { position });
      }
    } catch (err) {
      throwRequestError(err);
    }

    let action = MOVE_KEY_RESULT_TO_OBJECTIVE;

    if (parentId) {
      action = MOVE_KEY_RESULT_TO_KEY_RESULT;
    }

    await dispatch({
      type: action,
      keyResult: firstPayload.data,
      previousObjectiveId: keyResult.objective_id,
      previousParentId: keyResult.parent_id,
    });

    if (secondPayload) {
      dispatch(updateKeyResultRowOrder(secondPayload.data));
    }
  };
}

export const deleteKeyResultById = id => {
  return dispatch => {
    const payload = axios
      .delete(`/api/keyResults/${id}`)
      .then(response => response.data)
      .catch(throwRequestError);

    dispatch({
      type: DELETE_KEY_RESULT,
      payload,
    });

    return payload;
  };
};

export function saveObjective(objective) {
  return dispatch => {
    return axios
      .post('/api/objectives', objective)
      .then(response => {
        const objective = response.data;

        dispatch(addObjective(objective));
      })
      .catch(throwRequestError);
  };
}

export const createObjectives = bulkCreateAction(CREATE_OBJECTIVES, '/api/objectives', {
  toastText: 'Objectives have been created',
  ACTION_TYPE: UNDO_CREATE_OBJECTIVES,
  endpoint: '/api/objectives/versions/last',
  store: 'objectives',
});
export const updateObjectives = bulkUpdateAction(UPDATE_OBJECTIVES, '/api/objectives', {
  toastText: 'Objectives have been changed',
  ACTION_TYPE: UNDO_UPDATE_OBJECTIVES,
  endpoint: '/api/objectives/versions/last',
  store: 'objectives',
});
export const bulkDeleteObjectives = bulkDeleteAction(BULK_DELETE_OBJECTIVES, '/api/objectives/', {
  toastText: 'Objectives have been deleted',
  ACTION_TYPE: UNDO_BULK_DELETE_OBJECTIVES,
  endpoint: '/api/objectives/versions/last',
  store: 'objectives',
});
export const bulkDeleteKeyResults = bulkDeleteAction(BULK_DELETE_KEY_RESULTS, '/api/keyResults/', {
  toastText: 'Key results have been deleted',
  ACTION_TYPE: UNDO_BULK_DELETE_KEY_RESULTS,
  endpoint: '/api/keyResults/versions/last',
  store: 'objectives',
});

export function updateObjectiveById(id, update) {
  return (dispatch, getState) => {
    if (!id) {
      throw new Error('updateObjectiveById::id must passed');
    }

    const groomedUpdate = groomUpdateObject(update);

    if (isEmpty(groomedUpdate)) {
      const state = getState();
      const { selected } = getSelected(state);

      dispatch(addObjective(selected));
      return;
    }

    return axios
      .put(`/api/objectives/${id}`, groomedUpdate)
      .then(response => {
        const objective = response.data;

        // if (Object.keys(update).indexOf('ownerName') > -1) dispatch(fetchUsers());
        dispatch(addObjective(objective));
      })
      .catch(throwRequestError);
  };
}

export function requestRemoveObjectiveById(id) {
  return dispatch => {
    if (!id) {
      throw new Error('updateObjectiveById::id must passed');
    }

    return axios
      .delete(`/api/objectives/${id}`)
      .then(response => {
        const objective = response.data;

        dispatch(removeObjectiveById(objective));
      })
      .catch(throwRequestError);
  };
}

export function switchObjectivesRowOrder(objectiveId1, objectiveId2, _, position) {
  return (dispatch, getState) => {
    if (isRowIdUndefined(objectiveId1) || isRowIdUndefined(objectiveId2)) {
      return;
    }

    const state = getState().objectives.objectives;
    const prevData = state.find(({ id }) => id === +objectiveId1);
    const movedRow = moveRowToPosition(state, objectiveId1, objectiveId2, position);

    return dispatch({
      type: UPDATE_OBJECTIVE_ROW_ORDER,
      payload: {
        promise: axios.put(`/api/objectives/rowOrder/${objectiveId1}/${objectiveId2}`, { position }).then(response => {
          return response.data;
        }),
        data: movedRow,
      },
      meta: {
        prev: prevData,
        row: movedRow,
      },
    });
  };
}

export function mergeObjectives(objectivesIdsToMerge, objectiveId) {
  return async dispatch => {
    try {
      const payload = await axios.post(`/api/objectives/merge/${objectiveId}`, {
        objectivesIdsToMerge,
      });

      await dispatch({
        type: MERGE_OBJECTIVES,
        payload,
      });

      dispatch(clearSelectedItems(OBJECTIVES));

      return dispatch(fetchObjectives());
    } catch (err) {
      throwRequestError(err);
    }
  };
}

export const fetchObjectives = fetchMetadataAction(
  FETCH_OBJECTIVES,
  '/api/objectives',
  state => state.objectives.objectives,
  state => null, // state.objectives.lastCallsDate,
);

export const moveObjectiveToObjective = (objective, overObjective) => {
  return async dispatch => {
    const { id: objectiveId, level: objectiveLevel, parent_id: objectiveParentId } = objective;
    const { level: overObjectiveLevel } = overObjective;

    const isOverObjectiveCorplevel = overObjectiveLevel === METADATA_LEVELS.LEVEL_CORP;
    const isCurrentObjectiveNotCorplevel = objectiveLevel !== METADATA_LEVELS.LEVEL_CORP;

    const overObjectiveParentId = isOverObjectiveCorplevel ? overObjective.id : overObjective.parent_id;
    const isRoadmap1Level = objectiveLevel === METADATA_LEVELS.LEVEL_1;
    const isTargetNodeValidParent =
      (isOverObjectiveCorplevel && isRoadmap1Level) || (!isOverObjectiveCorplevel && !!overObjectiveParentId);

    if (isTargetNodeValidParent && objectiveParentId !== overObjectiveParentId && isCurrentObjectiveNotCorplevel) {
      const updateData = { parent_id: overObjectiveParentId };

      await updateObjectiveById(objectiveId, updateData)(dispatch);
    }
  };
};

export const addExistingMetricToObjective = (objectiveId, metricId) =>
  createThunk(
    ADD_OBJECTIVE_METRIC,
    axios
      .post(`/api/objectives/${objectiveId}/metrics/${metricId}`)
      .then(res => res.data)
      .catch(throwRequestError),
    {},
  );

export const addExistingMetricToKeyResult = (keyResultId, metricId) =>
  createThunk(
    ADD_KEY_RESULT_METRIC,
    axios
      .post(`/api/keyResults/${keyResultId}/metrics/${metricId}`)
      .then(res => res.data)
      .catch(throwRequestError),
    {},
  );

export const bulkAddMetricsToObjective = (objectiveId, metricIds = []) =>
  createThunk(
    BULK_ADD_OBJECTIVE_METRICS,
    axios
      .post(`/api/objectives/${objectiveId}/metrics`, { metricIds })
      .then(res => res.data)
      .catch(throwRequestError),
    {},
  );

export const bulkAddMetricsToKeyResult = (keyResultId, metricIds = []) =>
  createThunk(
    BULK_ADD_KEY_RESULT_METRICS,
    axios
      .post(`/api/keyResults/${keyResultId}/metrics`, { metricIds })
      .then(res => res.data)
      .catch(throwRequestError),
    {},
  );

export const removeMetricFromObjective = (objectiveId, metricId) =>
  createThunk(
    REMOVE_OBJECTIVE_METRIC,
    axios
      .delete(`/api/objectives/${objectiveId}/metrics/${metricId}`)
      .then(res => res.data)
      .catch(throwRequestError),
    {},
  );

export const removeMetricFromKeyResult = (keyResultId, metricId) =>
  createThunk(
    REMOVE_KEY_RESULT_METRIC,
    axios
      .delete(`/api/keyResults/${keyResultId}/metrics/${metricId}`)
      .then(res => res.data)
      .catch(throwRequestError),
    {},
  );

export const bulkRemoveMetricsFromObjective = (objectiveId, metricIds = []) =>
  createThunk(
    BULK_REMOVE_OBJECTIVE_METRICS,
    axios
      .delete(`/api/objectives/${objectiveId}/metrics?metricIds=${metricIds.join(',')}`)
      .then(res => res.data)
      .catch(throwRequestError),
    {},
  );

export const bulkRemoveMetricsFromKeyResult = (keyResultId, metricIds = []) =>
  createThunk(
    BULK_REMOVE_KEY_RESULT_METRICS,
    axios
      .delete(`/api/keyResults/${keyResultId}/metrics?metricIds=${metricIds.join(',')}`)
      .then(res => res.data)
      .catch(throwRequestError),
    {},
  );

export const createMetricAndAddToOkr = (okr, data, type = 'objectives') => {
  const action = type === 'objectives' ? CREATE_AND_ADD_METRIC_TO_OBJECTIVE : CREATE_AND_ADD_METRIC_TO_KEY_RESULT;

  return createThunk(
    action,
    async () => {
      const newMetric = await callCreateMetric(data);

      return callAddMetricToOkr(okr.id, newMetric.id, type);
    },
    {},
  );
};
