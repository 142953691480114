import moment from 'moment-timezone';

import upsertListItem from '../utils/upsertListItem';
import {
  CREATE_SKILL_FULFILLED,
  FETCH_SKILLS_FULFILLED,
  FETCH_SKILLS_PENDING,
  UPDATE_SKILL_FULFILLED,
  UPDATE_SKILL_BY_ID_FULFILLED,
  DELETE_SKILL_FULFILLED,
  ADD_SKILL_WITHOUT_SAVE,
  REMOVE_UNSAVED_SKILLS,
  MERGE_SKILLS_FULFILLED,
  CREATE_SKILLS_FULFILLED,
  UPDATE_SKILLS_FULFILLED,
  UNDO_CREATE_SKILLS_FULFILLED,
  UNDO_UPDATE_SKILLS_FULFILLED,
  BULK_DELETE_SKILLS_FULFILLED,
  UNDO_BULK_DELETE_SKILLS_FULFILLED,
  SWITCH_SKILLS_ROW_ORDER_FULFILLED,
  SWITCH_SKILLS_ROW_ORDER_PENDING,
  SWITCH_SKILLS_ROW_ORDER_REJECTED,
} from './types';
import addRows from 'store/utils/addRows';
import updateRows from 'store/utils/updateRows';
import sortByRank from 'utils/sortByRank';

const initialState = {
  skills: [],
  isLoaded: false,
};

const removeUnsaved = list => list.filter(el => el.id);
const updateSkill = (skills, skill) => skills.map(item => (item.id === skill.id ? skill : item));

export default function skillReducer(state = initialState, action) {
  switch (action.type) {
    case SWITCH_SKILLS_ROW_ORDER_REJECTED: {
      if (!action.meta || !action.meta.prev) return state;

      let skills = state.skills ? state.skills.slice(0) : [];

      skills = upsertListItem(action.meta.prev, skills);

      return {
        ...state,
        skills: skills.sort(sortByRank),
      };
    }
    case SWITCH_SKILLS_ROW_ORDER_PENDING:
    case SWITCH_SKILLS_ROW_ORDER_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        console.error('SWITCH_SKILLS_ROW_ORDER_FULFILLED::SKILL passed does not have id');
        return state;
      }

      let skills = state.skills ? state.skills.slice(0) : [];

      skills = upsertListItem(action.payload, skills);

      return {
        ...state,
        skills: skills.sort(sortByRank),
      };
    }
    case FETCH_SKILLS_PENDING:
      return Object.assign({}, state, { isLoaded: false });
    case FETCH_SKILLS_FULFILLED:
      return Object.assign({}, state, {
        skills: action.payload.data.sort(sortByRank),
        isLoaded: true,
        lastCallsDate: moment().valueOf(),
      });
    case CREATE_SKILL_FULFILLED:
      if (!action.payload || !action.payload.id) {
        return state;
      }

      let skills = state.skills || [];

      skills = removeUnsaved(skills);
      skills = upsertListItem(action.payload, skills);

      return {
        ...state,
        skills,
        isLoaded: true,
      };
    case UPDATE_SKILL_BY_ID_FULFILLED:
    case UPDATE_SKILL_FULFILLED:
      return {
        ...state,
        skills: updateSkill(state.skills, action.payload),
        isLoaded: true,
      };
    case DELETE_SKILL_FULFILLED:
      return {
        ...state,
        skills: state.skills.filter(c => c.id !== action.payload),
      };
    case ADD_SKILL_WITHOUT_SAVE: {
      const skills = state.skills ? removeUnsaved(state.skills) : [];

      skills.unshift(action.skill || {});

      return {
        ...state,
        skills,
      };
    }
    case REMOVE_UNSAVED_SKILLS:
      return {
        ...state,
        skills: removeUnsaved(state.skills),
      };
    case MERGE_SKILLS_FULFILLED:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        skills: state.skills.filter(skill => !action.payload.includes(skill.id)),
      };
    case CREATE_SKILLS_FULFILLED: {
      const skills = addRows(state.skills, action.payload);

      return {
        ...state,
        skills,
        lastActionIds: action.payload.map(obj => obj.id),
      };
    }
    case UPDATE_SKILLS_FULFILLED: {
      const skills = updateRows(state.skills, action.payload);

      return {
        ...state,
        skills,
        lastActionIds: action.payload.map(obj => obj.id),
      };
    }
    case BULK_DELETE_SKILLS_FULFILLED: {
      const skills = state.skills.filter(r => !action.payload.includes(String(r.id)));

      return {
        ...state,
        skills,
        lastActionIds: action.payload,
      };
    }
    case UNDO_UPDATE_SKILLS_FULFILLED: {
      const skills = updateRows(state.skills, action.payload);

      return {
        ...state,
        skills,
        lastActionIds: null,
      };
    }
    case UNDO_CREATE_SKILLS_FULFILLED: {
      return {
        ...state,
        skills: state.skills.filter(c => state.lastActionIds.indexOf(c.id) === -1),
        lastActionIds: null,
      };
    }
    case UNDO_BULK_DELETE_SKILLS_FULFILLED: {
      const skills = updateRows(state.skills, action.payload);

      return {
        ...state,
        skills,
        lastActionIds: null,
      };
    }
    default:
      return state;
  }
}
