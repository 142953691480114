import React from 'react';
import useSystemFields from 'hooks/useSystemFields';
import styled from 'styled-components';

import MultiSelect from 'design-system/atoms/MultiSelect/index';

import {
  defaultAvailableNodes,
  BET_NODE,
  IDEA_NODE,
  INITIATIVE_NODE,
  KEYRESULT1_NODE,
  KEYRESULT2_NODE,
  METRIC_NODE,
  OBJECTIVE_NODE,
  TIMEFRAME_NODE,
} from 'features/Canvas/constants';

const getOptions = (getSystemFieldName, hasBet = false, availableNodes = defaultAvailableNodes) => [
  ...(hasBet && availableNodes[BET_NODE]
    ? [
        {
          value: 'bet',
          label: `${getSystemFieldName('bet')}`,
        },
      ]
    : []),
  ...(availableNodes[INITIATIVE_NODE]
    ? [
        {
          value: 'initiative',
          label: `${getSystemFieldName('initiative')}`,
        },
      ]
    : []),
  ...(availableNodes[IDEA_NODE]
    ? [
        {
          value: 'idea',
          label: `${getSystemFieldName('idea')}`,
        },
      ]
    : []),
  ...(availableNodes[OBJECTIVE_NODE] ? [{ value: 'objective', label: getSystemFieldName('objective') }] : []),
  ...(availableNodes[KEYRESULT1_NODE] ? [{ value: 'keyResult1', label: getSystemFieldName('keyResult1') }] : []),
  ...(availableNodes[KEYRESULT2_NODE] ? [{ value: 'keyResult2', label: getSystemFieldName('keyResult2') }] : []),
  ...(availableNodes[TIMEFRAME_NODE] ? [{ value: 'timeframe', label: getSystemFieldName('timeframe') }] : []),
  ...(availableNodes[METRIC_NODE] ? [{ value: 'metric', label: getSystemFieldName('metric') }] : []),
];

const DisplayNodesMultiSelect = ({ displayNodes = [], onChange, hasBet = false, availableNodes = defaultAvailableNodes }) => {
  const [getSystemFieldName] = useSystemFields();

  const options = getOptions(getSystemFieldName, hasBet, availableNodes);

  return (
    <div>
      <StyledMultiSelect placeholder="Select data to display" options={options} value={displayNodes} onChange={onChange} />
    </div>
  );
};

export default DisplayNodesMultiSelect;

const StyledMultiSelect = styled(MultiSelect)`
  min-width: 215px;
`;
