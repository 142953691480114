import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import take from 'lodash/take';
import isString from 'lodash/isString';
import debounce from 'lodash/debounce';

import { createUnsavedProject } from 'store/projects';
import { BET_LAYER } from 'store/projects/constants';
import { getUnsavedProject } from 'store/projects/selectors';
import { getCurrentUser } from 'store/login/selectors';
import { getDefaultPhaseByPlanningStage } from 'store/phases/selectors';
import { getDisplayLayer, usePortfolioMode } from 'store/filters/selectors';
import useSystemFields from 'hooks/useSystemFields';
import { createChildFromProject } from 'utils/childProject';
import { getAllProjectsFromGroups } from 'store/projects/helpers/groupSelectors';
import { DEFAULT_PLANNING_STAGE } from 'constants/projectLightbox';

const manageWithoutGroups = (nodes, projectLayer, getSystemFieldName) => {
  const withoutBetGroupKey = `Without ${getSystemFieldName('bet', false)}`;
  const withouInitiativetGroupKey = `Without ${getSystemFieldName('initiative', false)}`;

  const withoutBetGroupNode = nodes.find(node => isString(node.key) && node.key === withoutBetGroupKey);

  if (+projectLayer === +BET_LAYER && withoutBetGroupNode && withoutBetGroupNode.expanded) {
    if (withoutBetGroupNode?.childrenAfterGroup?.length) {
      const withoutInitiativeGroupNode = withoutBetGroupNode.childrenAfterGroup.find(
        node => isString(node.key) && node.key === withouInitiativetGroupKey,
      );

      if (withoutInitiativeGroupNode && withoutInitiativeGroupNode.expanded) {
        setTimeout(() => withoutInitiativeGroupNode.setExpanded(false), 100);
      }
    }

    setTimeout(() => withoutBetGroupNode.setExpanded(false), 100);
  }
};

const useAddNewInlineProject = (grid, groups) => {
  const dispatch = useDispatch();

  const [getSystemFieldName] = useSystemFields();

  const currentUser = useSelector(getCurrentUser);
  const displayLayer = useSelector(getDisplayLayer);
  const hasBet = useSelector(state => state.organization.organization.has_bet);
  const portfolioMode = useSelector(state => usePortfolioMode(state));
  const unsavedProject = useSelector(state => getUnsavedProject(state));
  const defaultPhase = useSelector(state => getDefaultPhaseByPlanningStage(state, DEFAULT_PLANNING_STAGE));

  const addNewInlineProject = useCallback(
    params => {
      const { api: gridApi } = params;

      if (gridApi) {
        const newProject = createChildFromProject(
          params,
          currentUser,
          hasBet,
          getSystemFieldName,
          displayLayer,
          portfolioMode,
          defaultPhase,
        );

        dispatch(createUnsavedProject(newProject));
      }
    },
    [dispatch, createUnsavedProject, getSystemFieldName, displayLayer],
  );

  useEffect(() => {
    const debouncedManageGroups = debounce(() => {
      if (grid?.gridApi && unsavedProject) {
        const allProjects = getAllProjectsFromGroups(groups);
        const unsavedProject = allProjects.find(p => !p.id);

        if (unsavedProject?.parentGroups?.length > 1) {
          const groupLevel2 = take(unsavedProject?.parentGroups, 2);
          const groupLevel2Id = `group-${groupLevel2.length}-${groupLevel2.map(group => group.title).join('-')}`;

          const renderedNodes = grid.gridApi.getRenderedNodes();

          const parentGroup2Node = renderedNodes.find(node => node.id === groupLevel2Id);

          if (parentGroup2Node?.childrenAfterGroup?.length && getSystemFieldName) {
            manageWithoutGroups(parentGroup2Node?.childrenAfterGroup, unsavedProject.layer, getSystemFieldName);
          }

          if (parentGroup2Node && !parentGroup2Node.expanded) {
            setTimeout(() => parentGroup2Node.setExpanded(true));
          }

          const groupLevel3Id = `group-${unsavedProject?.parentGroups.length}-${unsavedProject.parentGroups
            .map(group => group.title)
            .join('-')}`;

          const parentGroup3Node = renderedNodes.find(child => child.id === groupLevel3Id);

          if (parentGroup3Node?.childrenAfterGroup?.length && getSystemFieldName) {
            manageWithoutGroups(parentGroup3Node?.childrenAfterGroup, unsavedProject.layer, getSystemFieldName);
          }

          if (parentGroup3Node && !parentGroup3Node.expanded) {
            setTimeout(() => parentGroup3Node.setExpanded(true), 100);
          }
        }
      }
    }, 100);

    grid?.gridApi?.addEventListener('rowDataUpdated', debouncedManageGroups);

    return () => grid?.gridApi?.removeEventListener('rowDataUpdated', debouncedManageGroups);
  }, [unsavedProject, getSystemFieldName, grid]);

  return addNewInlineProject;
};

export default useAddNewInlineProject;
