import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

import ConfirmDialog from 'components/ConfirmDialog';

const Preferences = ({
  data: { first_name: receivedFirstName, last_name: receivedLastName, email: receivedEmail },
  onChange,
  ...props
}) => {
  const [state, setState] = useState({
    first_name: receivedFirstName,
    last_name: receivedLastName,
    email: receivedEmail,
    errors: {},
  });
  const [confirmUpdateEmail, setConfirmUpdateEmail] = useState(false);

  const handleChange = name => e => setState({ ...state, [name]: e.target.value });
  const handleBlur = name => () => {
    const _setFieldError = error => setState({ ...state, errors: { ...state.errors, [name]: error } });
    const _clearFielErrors = () => {
      if (state.errors[name]) _setFieldError('');
    };

    if (name === 'first_name') {
      if (!state[name]) return _setFieldError('First name is required');
      _clearFielErrors(name);

      if (state[name] === receivedFirstName) return;
    } else if (name === 'email') {
      if (!state[name]) return _setFieldError('Email is required');
      _clearFielErrors(name);

      if (state[name] === receivedEmail) return;
      return setConfirmUpdateEmail(true);
    } else if (name === 'last_name') {
      if (state[name] === receivedLastName) return;
    }

    return onChange ? onChange({ [name]: state[name] }) : null;
  };

  return (
    <Form {...props}>
      <FormRow>
        <NameField
          value={state.first_name}
          onBlur={handleBlur('first_name')}
          onChange={handleChange('first_name')}
          label="First name"
          error={state.errors.first_name}
          helperText={state.errors.first_name}
        />
        <NameField
          value={state.last_name}
          onBlur={handleBlur('last_name')}
          onChange={handleChange('last_name')}
          label="Last name"
        />
      </FormRow>
      <FormRow>
        <EmailField
          value={state.email}
          onBlur={handleBlur('email')}
          onChange={handleChange('email')}
          label="Email"
          error={state.errors.email}
          helperText={state.errors.email}
        />
      </FormRow>

      <ConfirmDialog
        isOpen={confirmUpdateEmail}
        onCancel={() => {
          setState({ ...state, email: receivedEmail });
          setConfirmUpdateEmail(false);
        }}
        onConfirm={() => {
          setConfirmUpdateEmail(false);
          onChange({ email: state.email });
        }}
        title="Update email"
        text={`Are you sure you want to update email to "${state.email}" ?`}
      />
    </Form>
  );
};

Preferences.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

const NameField = styled(TextField)`
  &&&& {
    :first-child {
      margin-right: 15px;
    }

    flex-grow: 1;
  }
`;

const EmailField = styled(TextField)`
  &&&& {
    flex-grow: 1;
  }
`;

const Form = styled.form`
  &&&& {
    width: 100%;
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 10px 0;
`;

export default Preferences;
