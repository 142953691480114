import { useSelector } from 'react-redux';
import { equals, pipe, prop } from 'ramda';

import { READ_ONLY_USER } from '@dragonboat/permissions';

import { getCurrentUser } from 'store/login/selectors';

const isReadOnlyUser = pipe(prop('role_id'), equals(READ_ONLY_USER));

/**
 * Custome hook responsible to retrieve information regarding the user to the Swimlane page.
 * */
const useSwimlaneAuth = () => {
  const currentUser = useSelector(getCurrentUser);

  return {
    // TODO: PERMISSION
    isReadOnly: isReadOnlyUser(currentUser),
    isAnonymousUser: currentUser.is_anonymous,
  };
};

export default useSwimlaneAuth;
