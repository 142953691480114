/* eslint-disable react/jsx-no-target-blank */
/**
 * AgGridIntegrationIconsColumn Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { USER_INTEGRATIONS_ICON_MAPPER } from 'src/constants/integrations';

const ICON_STYLE = {
  width: 20,
  height: 20,
};

class AgGridUserIntegrationIconsColumn extends PureComponent {
  render() {
    const {
      data,
      colDef: { allowedIntegrationTypes = [], getTooltip },
    } = this.props;
    const integrations = data.userIntegrations || [];

    // Filter unwanted integrations
    const filteredIntegrations = integrations.filter(integration =>
      allowedIntegrationTypes.includes(integration?.integrationType),
    );

    if (!filteredIntegrations || filteredIntegrations.length === 0) return <span />;

    const icons = filteredIntegrations.map(integration => {
      const { id, integrationType } = integration;
      const IntegrationIcon = USER_INTEGRATIONS_ICON_MAPPER[integrationType];

      return IntegrationIcon ? (
        <abbr key={id} title={getTooltip && getTooltip(integration)}>
          <IntegrationIcon style={ICON_STYLE} />
        </abbr>
      ) : null;
    });

    return <Wrapper>{icons}</Wrapper>;
  }
}

export default AgGridUserIntegrationIconsColumn;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 10px;
`;
