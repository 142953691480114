import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import styled from 'styled-components';

import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';

import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import ConfirmDialog from 'components/ConfirmDialog';

import CollapsePanel from 'components/CollapsePanel';
import useOrgIntegrations from 'features/OneStepIntegration/hooks/useOrgIntegrations';
import usePermissions from 'hooks/permissions/usePermissions';

const ConnectionSettings = props => {
  const { orgIntegration } = props;

  const { canDelete } = usePermissions();

  const [confirmDialogDisplay, setConfirmDialogDisplay] = useState(false);

  const { removeOrgIntegration, oneStepAvailableIntegrations } = useOrgIntegrations(orgIntegration.integrationType);

  const integrationNameLabel = oneStepAvailableIntegrations[orgIntegration.integrationType].title;

  const canDisconnectIntegration = canDelete(PERMISSION_RESOURCES.orgIntegration);

  const handleRemoveOrgIntegration = () => {
    setConfirmDialogDisplay(false);
    removeOrgIntegration(orgIntegration.id);
  };

  return (
    <Wrapper>
      <CollapsePanel defaultExpanded title={<Text variant="h4">{integrationNameLabel} Connection</Text>}>
        <PanelContent>
          <Info>Your Dragonboat workspace has been linked to your {integrationNameLabel} instance.</Info>
          <ButtonRow>
            <Button color="secondary" onClick={() => setConfirmDialogDisplay(true)} disabled={!canDisconnectIntegration}>
              Disconnect From {integrationNameLabel}
            </Button>
            <Note>(This will disconnect the connection between {integrationNameLabel} and your entire workspace)</Note>
          </ButtonRow>
        </PanelContent>
      </CollapsePanel>
      <ConfirmDialog
        title={`Disconnect From ${integrationNameLabel}`}
        text={
          <div>
            <p>
              Disconnecting {integrationNameLabel} will disable {integrationNameLabel} linking and update for your entire
              Workspace. Do you want to continue?
            </p>
            <br />
            <p>You may reconnect in the future.</p>
          </div>
        }
        isOpen={confirmDialogDisplay}
        onCancel={() => setConfirmDialogDisplay(false)}
        onConfirm={handleRemoveOrgIntegration}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const PanelContent = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.text.secondary};
  padding-bottom: ${spacing(2)}px;
`;

const Info = styled.div`
  margin: 0 ${spacing()}px ${spacing(3)}px;
`;

const Note = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
  margin-left: ${spacing()}px;
  margin-top: ${spacing(0.5)}px;
  color: ${({ theme }) => theme.palette.text.disabled};
`;

const ButtonRow = styled.div`
  padding-bottom: ${spacing(0.5)}px;
`;

export default ConnectionSettings;
