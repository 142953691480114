import React, { useMemo } from 'react';
import { not } from 'ramda';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import BarChart from 'components/ReactChart/BarChart';
import ChartWrapper from 'components/ReactChart/utils/ChartWrapper';

import useProgressByRoadmapExpectedChart from './useProgressByRoadmapExpectedChart';

import { STACKED_CHART_ROW_HEIGHT } from 'constants/charts';
import { DASHBOARDS_PAGE } from 'constants/filters';
import TimelineLightbox from 'features/TimelineLightbox';
import ChartContent from '../ChartContent';
import ChartLegend from '../ChartLegend';

export default function ProgressByRoadmapExpectedChart({
  dashboardState,
  maxHeight,
  minHeight,
  wrapper: Wrapper,
  title,
  subtitle,
  actionButtons,
}) {
  const maxItems = useMemo(() => maxHeight && Math.round(maxHeight / STACKED_CHART_ROW_HEIGHT), [maxHeight]);

  const {
    chartData,
    chartOptions,
    hasData,
    registerOpenTimelineCallback,
    timelineProjectsGrouping,
    groupByEntity,
    timelineLightboxOpenItems,
    timelineLightboxDisplayLayer,
  } = useProgressByRoadmapExpectedChart(maxItems, dashboardState);

  if (not(hasData)) {
    return '';
  }

  return (
    <Wrapper>
      <ChartContent title={title} subtitle={subtitle} actionButtons={actionButtons}>
        <ChartWrapper height={minHeight}>
          <BarChart
            plugins={[ChartDataLabels]}
            options={chartOptions}
            horizontal
            data={{
              labels: chartData.labels,
              datasets: chartData.datasets,
            }}
          />
        </ChartWrapper>
        <ChartLegend legends={chartData?.datasets} />
      </ChartContent>
      <TimelineLightbox
        onOpen={registerOpenTimelineCallback}
        groupedBy={timelineProjectsGrouping}
        groupBySelection={groupByEntity}
        pageId={DASHBOARDS_PAGE}
        openItems={timelineLightboxOpenItems}
        entityGroup={groupByEntity}
        lazyLoadProjects
        displayLayer={timelineLightboxDisplayLayer}
      />
    </Wrapper>
  );
}
