import { stringComparator } from '../comparators';
import { TextCellRenderer } from '../cellRenderers';

const rankColumnDef = {
  field: 'rank',
  headerName: 'Rank',
  suppressMovable: true,
  cellRenderer: TextCellRenderer,
  comparator: stringComparator,
  editable: true,
};

export default rankColumnDef;
