// External dependencies
import pick from 'lodash/pick';

// Dragonboat depencies
import {
  SAVE_GRID_STATE,
  ADD_SELECTED_ITEM,
  REMOVE_SELECTED_ITEM,
  SET_SELECTED_ITEMS,
  SHOW_MERGE_LIGHTBOX,
  HIDE_MERGE_LIGHTBOX,
  ENABLE_SELECTION_MODE,
  DISABLE_SELECTION_MODE,
  CLEAR_SELECTED_ITEMS,
  SAVE_GRID_FILTER,
  SAVE_GRID_VIEW,
  SAVE_USER_GRID_VIEW,
  FETCH_USER_GRID_VIEW,
  SAVE_GRID_SELECTED_VIEW,
  SAVE_GRID_CONFIG,
} from './types';
import saveCurrentView from 'store/utils/factory/saveCurrentView';
import saveUserView from 'store/utils/factory/saveUserView';
import fetchUserView from 'store/utils/factory/fetchUserView';

export const saveGridState = (grid, gridState, makesActiveViewDirty = true) => ({
  type: SAVE_GRID_STATE,
  grid,
  gridState,
  meta: { makesActiveViewDirty },
});

export const addSelectedItem = (grid, item) => ({
  type: ADD_SELECTED_ITEM,
  item,
  grid,
});

export const removeSelectedItem = (grid, item) => ({
  type: REMOVE_SELECTED_ITEM,
  item,
  grid,
});

export const setSelectedItems = (grid, items = []) => ({
  type: SET_SELECTED_ITEMS,
  items,
  grid,
});

export const clearSelectedItems = grid => ({
  type: CLEAR_SELECTED_ITEMS,
  grid,
});

export const enableSelectionMode = (grid, value = true) => ({
  type: ENABLE_SELECTION_MODE,
  grid,
  value,
});

export const disableSelectionMode = grid => ({
  type: DISABLE_SELECTION_MODE,
  grid,
});

export const showMergeLightbox = grid => ({
  type: SHOW_MERGE_LIGHTBOX,
  grid,
});

export const hideMergeLightbox = grid => ({
  type: HIDE_MERGE_LIGHTBOX,
  grid,
});

export const saveGridFilter = (grid, filter, data) => ({
  type: SAVE_GRID_FILTER,
  payload: { grid, filter, data },
});

export const saveGridSelectedView = (grid, selectedView) => ({
  type: SAVE_GRID_SELECTED_VIEW,
  payload: { grid, selectedView },
});

export const saveGridConfig = (grid, key, value, makesActiveViewDirty = true) => {
  const ignoredKeysForDirtyState = ['bulkDelete', 'showColumnsDialog'];
  const makesViewDirty = ignoredKeysForDirtyState.includes(key) ? false : makesActiveViewDirty;

  return {
    type: SAVE_GRID_CONFIG,
    payload: { grid, config: { key, value } },
    meta: { makesActiveViewDirty: makesViewDirty },
  };
};

export const saveGridView = (dispatch, grid, url) => {
  const func = saveCurrentView(
    SAVE_GRID_VIEW,
    'grid',
    state => ({
      [grid]: pick(state.grids.get(grid).toJS(), ['gridState', 'grouped']),
      filter: state._filters.global,
    }),
    url,
  );

  return dispatch(func());
};

export const saveUserGridView = (dispatch, grid) => {
  const func = saveUserView(SAVE_USER_GRID_VIEW, grid, state => ({
    ...state.grids.get(grid).get('gridState').toJS(),
    ...(state.grids.get(grid).has('expandedGroups')
      ? { expandedGroups: state.grids.get(grid).get('expandedGroups').toJS() }
      : {}),
  }));

  return dispatch(func());
};

export const fetchUserGridView = (dispatch, grid) => {
  const func = fetchUserView(FETCH_USER_GRID_VIEW, grid);

  return dispatch(func());
};
