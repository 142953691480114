import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { defaultTo } from 'ramda';
import { SYSTEM_FIELD } from '@dragonboat/permissions';

import { spacing } from 'design-system/theme';
import { materialColorsAlt } from 'design-system/themes/default';

import { BaseGrid } from 'containers/Grids';

import useSystemFields from 'hooks/useSystemFields';
import useLightboxesControlContext from 'hooks/lightboxes/useLightboxesControl';

import { sumByOptions } from 'store/allocationReport/consts';
import { OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2, openObjectiveDrawer } from 'store/objectives';

import exportProjectsToCsv from 'utils/exportProjectsToCsv';
import { getScopeVarianceColor } from 'utils/allocation/getScopeVarianceColor';
import { SUMMARY_TABLE_COLUMNS_PREFERENCES_OPTIONS_PLANNED } from 'constants/allocation';

import useAllocationReportSummaryGrid from './useAllocationReportSummaryGrid';
import { formatNumber } from './helpers';
import { getRowId } from 'design-system/molecules/AgGridReact-New/helpers';

const defaultToZero = defaultTo(0);

export default props => {
  const {
    lsState,
    data,
    total,
    totalReported,
    totalScopeVariance,
    totalCompleted,
    handleColorPickStart,
    projects,
    columnsOptions = SUMMARY_TABLE_COLUMNS_PREFERENCES_OPTIONS_PLANNED,
    handleTitleClick,
    isDrawerView,
  } = props;

  const dispatch = useDispatch();

  const [getSystemFieldName] = useSystemFields();
  const sumBy = sumByOptions(getSystemFieldName('idea', true)).find(o => lsState.sumBy.key === o.key);
  const selectedGroupBy = lsState.selectedGroupBy || {};
  const displayOptions = lsState.selectedDisplayOptions || [];
  const { openMetricLightbox } = useLightboxesControlContext();

  const _displayAllocationPercentageFactory = (totalCount, rowCountField) => row => {
    const rowCount = row?.[rowCountField];
    const value = totalCount && rowCount ? `${((rowCount / totalCount) * 100).toFixed(2)}` : 0;
    const targetPercentage = defaultToZero(row?.targetAllocationPercentage);
    const isGteAsValueTarget = targetPercentage > 0 && parseFloat(value) > targetPercentage;
    let color = targetPercentage > 0 ? materialColorsAlt.green : '';

    color = isGteAsValueTarget ? materialColorsAlt.red : color;

    if (rowCountField === 'countReported') return formatNumber(value, '%');

    return <span style={{ color }}>{formatNumber(value, '%')}</span>;
  };

  const _displayDifferenceFactory = (subtractFromValue, subtractValue) => row => {
    const minuend = row[subtractFromValue];
    const subtrahend = row[subtractValue];
    const difference = (minuend || 0) - (subtrahend || 0);

    const differenceIsPositive = difference > 0;

    const color = differenceIsPositive ? materialColorsAlt.red : '';

    return <span style={{ color }}>{formatNumber(difference)}</span>;
  };

  const _displayScopeVariance = value => {
    const enhancedValue = value || 0;
    const color = getScopeVarianceColor(enhancedValue);

    return <span style={{ color }}>{formatNumber(enhancedValue * 100, '%', 2)}</span>;
  };

  const _displayCurrentAllocationPercentage = _displayAllocationPercentageFactory(total, 'count');
  const _displayReportedAllocationPercentage = _displayAllocationPercentageFactory(totalReported, 'countReported');
  const _displayCompletedAllocationPercentage = _displayAllocationPercentageFactory(totalCompleted, 'countCompleted');
  const _displayReportedVsPlannedDifference = _displayDifferenceFactory('countReported', 'count');

  const currentAllocationAmountTitle =
    sumBy && sumBy.key === 'duration' ? `${sumBy.shortTitle} (${lsState.duration.key}s)` : (sumBy || {}).shortTitle;

  const isAllocationByObjective = selectedGroupBy?.key === SYSTEM_FIELD.objective;
  const isAllocationByKeyResult1 = selectedGroupBy?.key === SYSTEM_FIELD.keyResult1;
  const isAllocationByKeyResult2 = selectedGroupBy?.key === SYSTEM_FIELD.keyResult2;
  const isAllocationByMetrics = selectedGroupBy.key === SYSTEM_FIELD.metrics;
  const clickableMetadata =
    isAllocationByObjective || isAllocationByKeyResult1 || isAllocationByKeyResult2 || isAllocationByMetrics;

  const openDialog = row => {
    if (!row.id) {
      return;
    }
    switch (true) {
      case isAllocationByObjective:
        return dispatch(openObjectiveDrawer(+row.id));

      case isAllocationByKeyResult1:
        return dispatch(openObjectiveDrawer(+row.id, OBJECT_KEY_RESULT));

      case isAllocationByKeyResult2:
        return dispatch(openObjectiveDrawer(+row.id, OBJECT_KEY_RESULT_2));

      case isAllocationByMetrics:
        return openMetricLightbox(+row.id);

      default:
        break;
    }
  };

  const onTitleClick = row => e => {
    if (handleTitleClick) return handleTitleClick(row, e);

    openDialog(row);

    if (handleColorPickStart) handleColorPickStart(e, row);
  };

  const { gridData, defaultColDef, columnDefs, rowHeight, gridHeight, gridStyles, commonGridProps } =
    useAllocationReportSummaryGrid({
      data,
      total,
      totalReported,
      totalCompleted,
      totalScopeVariance,
      lsState,
      displayOptions,
      columnsOptions,
      selectedGroupBy,
      currentAllocationAmountTitle,
      clickableMetadata,
      isDrawerView,
      onTitleClick,
      handleTitleClick,
      handleColorPickStart,
      displayCurrentAllocationPercentage: _displayCurrentAllocationPercentage,
      displayCompletedAllocationPercentage: _displayCompletedAllocationPercentage,
      displayReportedAllocationPercentage: _displayReportedAllocationPercentage,
      displayReportedVsPlannedDifference: _displayReportedVsPlannedDifference,
      displayScopeVariance: _displayScopeVariance,
    });

  return (
    <GridWrapper>
      <BaseGrid
        {...commonGridProps}
        rowData={gridData}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowHeight={rowHeight}
        headerHeight={rowHeight}
        cssStyles={gridStyles}
        height={gridHeight}
        getRowId={getRowId}
      />
      {projects && localStorage.getItem('exportAllocationReportData') && (
        <a onClick={() => exportProjectsToCsv(projects)}>Export data to csv</a>
      )}
    </GridWrapper>
  );
};

const GridWrapper = styled.div`
  margin-bottom: ${spacing(2)}px;
`;
