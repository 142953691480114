import React from 'react';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';

const componentHOC = Component => {
  return props => {
    const handleActionClick = (e, isOwner) => {
      if (isOwner && !props.item.default_view) {
        props.handleViewOptions(e, props.item);
      } else {
        props.handleShareView(props.item);
      }
    };

    const getActionIcon = isOwner => {
      if (isOwner && !props.item.default_view) {
        return <MoreHorizIcon />;
      }

      return <ShareOutlinedIcon />;
    };

    return <Component handleActionClick={handleActionClick} getActionIcon={getActionIcon} {...props} />;
  };
};

export default componentHOC;
