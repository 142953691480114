import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import { not } from 'ramda';

const sumNumericFields = params => {
  const {
    data,
    values,
    colDef: { field, rounder },
  } = params;

  /*
   * In the case row data is not a group should not aggregate the values
   *
   * That means the row is a parent project row that have their own
   * values for the correspondent column, for that reason should not
   * aggregate, otherwise will show wrong data on the table.
   */
  if (data && not(data?.group)) {
    return data[field];
  }

  let result = 0;

  values.forEach(val => {
    if (val) {
      result += +val;
    }
  });

  // TODO: re-evaluate this condition
  if (isNaN(result) || !isNumber(result)) {
    return 0;
  }

  return rounder ? rounder.rounder(result) : +result.toFixed(2);
};

export default sumNumericFields;
