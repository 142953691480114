import React from 'react';
import pluralize from 'pluralize';

import theme from 'design-system/theme';

import Stars from 'design-system/atoms/DragonIcons/Stars';
import useSystemFields from 'hooks/useSystemFields';

import panelBackground from './background.svg';
import { Background, Content, Flex, FormContainer, Left, Right, StyledButton, StyledText, Title } from './styles';

const DEFAULT_SIMILARITY_THRESHOLD = 'high';

const FormScreen = ({ generateAutoTags }) => {
  const [getSystemFieldName] = useSystemFields();

  return (
    <Flex>
      <Left>
        <Background src={panelBackground} />
      </Left>
      <Right>
        <Content>
          <Stars />
          <Title component="h2" color={theme.palette.text.black}>
            Auto tag {pluralize(getSystemFieldName('request'))} with AI
          </Title>
          <StyledText variant="p" color={theme.palette.text.black} breakwords>
            Only untagged items will be tagged by AI
            <br />
            AI tag will use existing tags.
            <br />
            Tagging is based on similarity of currently tagged {pluralize(getSystemFieldName('request'))}.
          </StyledText>
          <FormContainer>
            <StyledText variant="p" color={theme.palette.text.black} breakwords>
              If no match, the item will not be tagged.
            </StyledText>
            <StyledButton
              background={theme.palette.newLayout.background.duskBlue}
              color={theme.palette.white}
              hover={{ background: theme.palette.text.brand }}
              fontSize={theme.typography.fontSizeRem}
              onClick={() => generateAutoTags(DEFAULT_SIMILARITY_THRESHOLD)}
              width="200px"
            >
              Tag {pluralize(getSystemFieldName('request'))} Now
            </StyledButton>
          </FormContainer>
        </Content>
      </Right>
    </Flex>
  );
};

export default FormScreen;
