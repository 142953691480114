import { always, complement, defaultTo, either, identity, isEmpty, isNil, not, pipe, pluck, when } from 'ramda';

import {
  ChipsCellRenderer,
  CustomFieldDropdownCellRenderer,
  DateCellRenderer,
  NumericCellRenderer,
  WYSIWYGCellRenderer,
} from 'design-system/molecules/AgGridReact-New/cellRenderers';
import {
  TextCellRenderer,
  EmptyValueIfNotProjectRenderer,
} from 'containers/IdeasList/IdeasList/New/hooks/useIdeasGridColumnsDefinitions/renderers';
import dateColumnDef from 'design-system/molecules/AgGridReact-New/columns/dateColumnDef';
import shortTextColumnDef from 'design-system/molecules/AgGridReact-New/columns/shortTextColumnDef';
import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';

import AgGridAutocomplete from 'components/AgGridAutocomplete/new';
import {
  numericColumnDef,
  sumNumericFieldAggColumnDef,
  checkboxColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';

import { customFieldDropdownComparator, stringComparator } from '../comparators';

import { RemirrorWYSIWYGCellEditor, MultiSelectCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import { multiSelectComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { returnsTrueIfKeyIsNotEscOrTab } from 'design-system/molecules/AgGridReact-New/helpers';
import { exportMultiLineText } from 'features/GridExport/helpers/exportUtils';

import { throwWithProductionFallback } from 'utils/errorHandling';

const defaultToNull = defaultTo(null);
const defaultToEmptyArray = when(complement(identity), always([]));

const isNilOrEmpty = either(isNil, isEmpty);
const isNotNilOrEmpty = pipe(isNilOrEmpty, not);
const getKeys = pluck('key');

const getCustomFieldColumnDef = ({
  dataEnhanced,
  data,
  enableRowGroup,
  fieldType,
  getNumberOfTextRowsToDisplayOnGridCell,
  key,
  handleMetadataCellDoubleClick,
  sortedValidOptions,
  title,
  ...other
}) => {
  const common = {
    ...other,
    key,
    width: 100,
    field: key,
    headerName: title,
    enableRowGroup,
  };

  switch (fieldType) {
    case TYPES_OF_CUSTOM_FIELDS.NUMBER:
      return {
        ...numericColumnDef,
        ...sumNumericFieldAggColumnDef,
        cellRenderer: NumericCellRenderer,
        ...common,
      };
    case TYPES_OF_CUSTOM_FIELDS.DATE:
      return {
        ...dateColumnDef,
        cellRenderer: DateCellRenderer,
        ...common,
      };
    case TYPES_OF_CUSTOM_FIELDS.YES_NO:
      return {
        ...checkboxColumnDef,
        ...common,
      };
    case TYPES_OF_CUSTOM_FIELDS.DROPDOWN:
      return {
        valueGetter: params => {
          const { colDef, data: rowData } = params;

          /*
           * (Without Bet / Without Initiative) rows does not have data object
           * will ingore it on that case
           */
          if (not(rowData)) {
            return null;
          }

          const value = rowData[colDef.field];

          if (isNotNilOrEmpty(dataEnhanced)) {
            return defaultToNull(dataEnhanced[value]?.title);
          }

          if (isNotNilOrEmpty(data)) {
            return defaultToNull(data[value]);
          }

          return null;
        },
        enableRowGroup: true,
        fullWidth: true,
        cellRenderer: CustomFieldDropdownCellRenderer,
        cellEditor: AgGridAutocomplete,
        cellEditorParams: {
          dataEnhanced,
          data,
          sortedValidOptions,
          autoFocus: true,
          options: sortedValidOptions.map(opt => {
            return {
              value: opt.key,
              label: opt.title || '',
            };
          }),
          sort: false,
          showEmptyOption: true,
        },
        comparator: customFieldDropdownComparator(sortedValidOptions),
        width: 75,
        ...common,
      };
    case TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN:
      return {
        valueSetter: params => {
          // sets the new value as a list of selected option keys/ids
          params.data[key] = getKeys(params.newValue);

          return true;
        },
        valueGetter: params => {
          const { data: rowData } = params;
          const valueKeys = defaultToEmptyArray(rowData[key]);

          return Array.isArray(valueKeys)
            ? valueKeys.map(optionKey => sortedValidOptions.find(opt => opt.key === optionKey))
            : [];
        },
        cellRenderer: EmptyValueIfNotProjectRenderer(ChipsCellRenderer),
        cellEditor: MultiSelectCellEditor,
        cellEditorParams: {
          idField: 'key',
          options: sortedValidOptions,
          optionsMapper: { label: 'title', value: 'key' },
          showEmptyOption: false,
          hideCreateOption: true,
        },
        cellEditorPopup: true,
        cellRendererParams: {
          getNumberOfTextRowsToDisplayOnGridCell,
        },
        comparator: multiSelectComparator,
        suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
        onCellDoubleClicked: handleMetadataCellDoubleClick,
        width: 120,
        showTooltip: true,
        labelField: 'title',
        chipVariant: 'outlined',
        colorField: '',
        ...common,
      };
    case TYPES_OF_CUSTOM_FIELDS.FORMULA:
      return {
        ...numericColumnDef,
        cellRenderer: TextCellRenderer,
        editable: false,
        ...common,
      };
    case TYPES_OF_CUSTOM_FIELDS.SHORT_TEXT:
      return {
        ...shortTextColumnDef,
        ...common,
      };
    case TYPES_OF_CUSTOM_FIELDS.RICH_TEXT:
      return {
        suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
        cellRenderer: WYSIWYGCellRenderer,
        cellEditor: RemirrorWYSIWYGCellEditor,
        cellEditorParams: {
          maxHeight: 150,
          autoFocus: true,
        },
        comparator: stringComparator,
        cellEditorPopup: true,
        ...common,
        exportFn: exportMultiLineText,
      };
    default:
      return throwWithProductionFallback(new Error(`Column def missing for custom field type: ${fieldType}`), () => ({
        ...shortTextColumnDef,
        ...common,
      }));
  }
};

export default getCustomFieldColumnDef;
