import React from 'react';
import useCreateRoadmapSnapshot from './hooks/useCreateRoadmapSnapshot';
import ToggleButton from 'design-system/molecules/ToggleButton/index';

import HistoryIcon from '@material-ui/icons/History';
import CreateRoadmapSnapshotDialog from '../CreateRoadmapSnapshotDialog/CreateRoadmapSnapshotDialog';

const CreateRoadmapSnapshotButton = ({ WrapperComponent = <div /> }) => {
  const { canCreateSnapshot, viewId, onClick, onClose } = useCreateRoadmapSnapshot();

  return canCreateSnapshot ? (
    <WrapperComponent>
      <ToggleButton title="Record a snap" icon={<HistoryIcon />} onChange={onClick} />
      <div key={viewId}>
        <CreateRoadmapSnapshotDialog isOpen={!!viewId} onClose={onClose} viewId={viewId} />
      </div>
    </WrapperComponent>
  ) : null;
};

export default CreateRoadmapSnapshotButton;
