import { APPLY_FILTERS } from 'store/projects';
import { removeUserRemovedMetadata } from './helpers';
import { isUninitialized } from 'utils/store/thunk';
import { UNDEFINED } from 'constants/common';
import { createSelector } from 'reselect';
import getMetadataTitle, { getOrgMetadataForCorpLevelTitles } from 'store/utils/getMetadataCorpTitleBasedOnRoadmap';
import { GROUP_FIELD_TO_METADATA_MAPPING } from 'store/projects/constants';

const EMPTY_OBJECT = {};

const getState = state => state.portfolioSummaryGrouping;

const getOperations = state => {
  return getState(state).operations;
};

const selectSummaryVisibleMetadata = state => {
  const metadata = getState(state)?.metadata || EMPTY_OBJECT;
  const userRemovedMetadataIds = getState(state)?.userRemovedMetadata || EMPTY_OBJECT;

  const visibleMetadata = removeUserRemovedMetadata(metadata, userRemovedMetadataIds);

  return visibleMetadata;
};

export const selectSummaryVisibleMetadataIds = state => {
  return Object.entries(selectSummaryVisibleMetadata(state)).reduce(
    (acc, [metadataType, eachMetadataArray]) => ({
      ...acc,
      [metadataType]: eachMetadataArray.filter(Boolean).map(item => item.id),
    }),
    {},
  );
};

export const selectSummaryUserRemovedMetadataIds = state => {
  const userRemovedMetadata = getState(state)?.userRemovedMetadata || EMPTY_OBJECT;

  return userRemovedMetadata;
};

export const selectSummaryUserAddedMetadataIds = state => {
  return getState(state)?.userAddedMetadata || EMPTY_OBJECT;
};

export const selectSummaryUserAddedMetadataIdsExcludingUndefined = createSelector(
  state => {
    return getState(state)?.userAddedMetadata || EMPTY_OBJECT;
  },
  userAddedMetadata =>
    Object.entries(userAddedMetadata).reduce((acc, [metadataType, metadataIds]) => {
      acc[metadataType] = (metadataIds || []).filter(id => id !== UNDEFINED);
      return acc;
    }, {}),
);

export const selectSummaryMetadata = createSelector(
  state => getState(state),
  state => getOrgMetadataForCorpLevelTitles(state),
  (state, orgMetadataForCorpLevelTitles) => {
    const getMetadataType = key => Object.entries(GROUP_FIELD_TO_METADATA_MAPPING).find(([_, value]) => value === key)?.[0];

    const metadata =
      Object.entries(state?.metadata || {}).reduce(
        (metadata, [key, value]) => ({
          ...metadata,
          [key]: value.map(item => ({
            ...item,
            title: getMetadataTitle(item, getMetadataType(key), orgMetadataForCorpLevelTitles),
          })),
        }),
        {},
      ) || EMPTY_OBJECT;

    return metadata;
  },
);

export const selectHasLoadedProjectsData = state => !isUninitialized(getOperations(state), APPLY_FILTERS);
