// External dependencies
import React from 'react';

import Dialog from 'design-system/molecules/Dialog/index';

import { PROJECTS_LIGHTBOX_Z_INDEX } from 'constants/projectLightbox';

import Timeline from './Timeline';

// Should be less than the projects lightbox z-index
const timelineLightboxZIndex = PROJECTS_LIGHTBOX_Z_INDEX - 100;

export default ({
  open,
  closeDialog,
  projects,
  groupedBy,
  entityGroup,
  pageId,
  lazyLoadProjects,
  getLazyLoadProjectsSubFilter,
  hideMaxItemDisplayMessage = false,
  isGoalMode,
  groupBySelection,
  showAllocationColumns = false,
  openItems = [],
}) => (
  <Dialog
    data-test="timeline-dialog"
    open={!!open}
    onClose={closeDialog}
    maxWidth="lg"
    scroll="paper"
    fullWidth
    closeButton
    title=""
    zIndex={timelineLightboxZIndex}
  >
    {open && (
      <Timeline
        projects={projects}
        groupedBy={groupedBy}
        entityGroup={entityGroup}
        lazyLoadProjects={lazyLoadProjects}
        getLazyLoadProjectsSubFilter={getLazyLoadProjectsSubFilter}
        pageId={pageId}
        hideMaxItemDisplayMessage={hideMaxItemDisplayMessage}
        isGoalMode={isGoalMode}
        groupBySelection={groupBySelection}
        showAllocationColumns={showAllocationColumns}
        openItems={openItems}
      />
    )}
  </Dialog>
);
