/* eslint jsx-a11y/anchor-is-valid: 0 */
// External dependencies
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

// Dragonboat dependencies
import { searchForTasksByJiraUrl, previewTasksByJiraUrl } from 'store/ideas';
import { removeDuplicates as uniq, getUserName } from 'utils';
import { bulkCreateProjects, bulkUpdateProjects } from 'store/projects';
import { createAllProjectsJiras, syncJiraStories, updateJiraConfigMisc } from 'store/integrations';
import { updateJiraIntegration } from 'store/organization';
import getSystemFieldName from 'utils/getSystemFieldName';
import {
  getOrgHasJiraIntegrated,
  getOrgJiraIntegrations,
  getOrgHasJiraFieldMappingByProject,
} from 'store/organization/selectors';
import { getProjectsCustomFields } from 'store/customFields/selectors';

import RecurringJQLReviewLightbox from './RecurringJQLReviewLightbox';

function mapStateToProps(state) {
  const {
    organization: { organization },
    projects,
    users: usersState,
    objectives,
    phases,
    themes,
    categories,
    roadmaps: roadmapsState,
    timeframes: timeframesState,
  } = state;

  const rowData = projects.rows ? projects.rows.toJS() : [];
  const users = uniq(usersState.rows || []);

  const productOwners = users ? users.map(getUserName) : [];
  const objectiveTitles = objectives.objectives ? objectives.objectives.map(objective => objective.title) : [];
  const priorities = uniq(rowData.map(project => project.priority));
  const jiraIntegrated = getOrgHasJiraIntegrated(state);
  const jiraOrgIntegrations = getOrgJiraIntegrations(state);
  const hasJiraFieldMappingByProject = getOrgHasJiraFieldMappingByProject(state);

  const timeframes = timeframesState.has('rows')
    ? timeframesState
        .get('rows')
        .toJS()
        .map(({ title, id, status }) => ({ title, id, status }))
    : [];
  const roadmaps = roadmapsState.roadmaps ? roadmapsState.roadmaps.map(({ title, id }) => ({ title, id })) : [];
  const timeframesTitles = timeframes ? timeframes.map(timeframe => timeframe.title) : [];
  const roadmapsTitles = roadmaps ? roadmaps.map(roadmap => roadmap.title) : [];

  return {
    productOwners,
    priorities,
    objectiveTitles,
    phaseTitles: phases.phases.map(p => p.title),
    themeTitles: themes.themes.map(t => t.title),
    categoryTitles: categories.categories.map(c => c.title),
    timeframesTitles,
    roadmapsTitles,
    jiraIntegrated,
    orgIntegrations: jiraOrgIntegrations,
    roadmaps,
    objectives: objectives.objectives ? objectives.objectives : [],
    organization,
    customFields: getProjectsCustomFields(state),
    getSystemFieldName: (name, plural) => getSystemFieldName(name, organization.system_fields_name, plural),
    hasJiraFieldMappingByProject,
  };
}

const mapDispatchToProps = {
  bulkCreateProjects,
  bulkUpdateProjects,
  createAllProjectsJiras,
  previewTasksByJiraUrl,
  updateJiraConfigMisc,
  updateJiraIntegration,
  searchForTasksByJiraUrl,
  syncJiraStories,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(RecurringJQLReviewLightbox);
