import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TimelineIcon from '@material-ui/icons/Timeline';
import ViewWeekIcon from '@material-ui/icons/ViewWeekOutlined';
import Typography from '@material-ui/core/Typography';

import useAppNotifications from 'hooks/useAppNotifications';
import useIntegrations from 'hooks/useIntegrations';
import getSystemFieldName from 'utils/getSystemFieldName';
import { pipe, prop, defaultTo } from 'ramda';

import { Wrapper } from './ResultStep.styles';
import { INTEGRATIONS_KEYS } from 'constants/integrations';

const HIDE_COUNTERS_INTEGRATIONS = [INTEGRATIONS_KEYS.azuredevops, INTEGRATIONS_KEYS.rally];

const shouldShowCounters = integrationType => !HIDE_COUNTERS_INTEGRATIONS.includes(integrationType);

const ResultStep = ({ integrationType, orgIntegrationId, onFinish }) => {
  const { addError } = useAppNotifications();
  const { isImportInsertIntegrationItemsLoading, importInsertIntegrationItemsData, importInsertIntegrationItemsError } =
    useIntegrations(integrationType, orgIntegrationId);

  useEffect(() => {
    if (importInsertIntegrationItemsError) {
      addError({ id: 'azure-import-error', message: 'Error importing the Azure Devops work items' });
    }
  }, [importInsertIntegrationItemsError]);

  const getIdeasFeedbackMessage = importData => {
    const ideasImported = pipe(defaultTo({}), prop('ideasImported'), defaultTo(0))(importData);

    return `${ideasImported} ${getSystemFieldName('idea', ideasImported !== 1)} imported.`;
  };

  const getInitiativesFeedbackMessage = importData => {
    const initiativesImported = pipe(defaultTo({}), prop('initiativesImported'), defaultTo(0))(importData);

    return `${initiativesImported} ${getSystemFieldName('initiative', initiativesImported !== 1)} imported.`;
  };

  const showCounters = shouldShowCounters(integrationType);

  return (
    <Wrapper>
      {isImportInsertIntegrationItemsLoading && (
        <Fragment>
          <div>
            <Typography style={{ fontSize: '18px' }}>
              {showCounters ? (
                <>
                  {getIdeasFeedbackMessage(importInsertIntegrationItemsData)}
                  <br />
                  {getInitiativesFeedbackMessage(importInsertIntegrationItemsData)}
                  <br />
                  <br />
                </>
              ) : null}
              Mapping data in progress...
              <br />
              <br />
              In the meantime you may
            </Typography>
            <br />
            <Typography style={{ fontSize: '18px' }}>
              <Link onClick={() => onFinish('manage')} to="/ideas/timeline" style={{ color: 'initial' }}>
                <TimelineIcon style={{ verticalAlign: 'bottom', marginRight: 10 }} />
              </Link>
              Adjust timelines and assign resources on the&nbsp;
              <Link onClick={() => this.close('manage')} to="/ideas/timeline">
                Manage
              </Link>{' '}
              page
            </Typography>
            <br />
            <Typography style={{ fontSize: '18px' }}>
              <Link onClick={() => onFinish('ideas-board')} to="/ideas/board" style={{ color: 'initial' }}>
                <ViewWeekIcon style={{ verticalAlign: 'bottom', marginRight: 10 }} />
              </Link>
              Align and prioritize {getSystemFieldName('idea', true)}s and {getSystemFieldName('initiative', true)}s on the&nbsp;
              <Link onClick={() => onFinish('manage')} to="/ideas/board">
                {getSystemFieldName('idea', true)} Board
              </Link>
            </Typography>
            <br />
            <br />
            The mapping will continue even if you close this screen or go to other pages.
          </div>
        </Fragment>
      )}

      {!isImportInsertIntegrationItemsLoading && (
        <>
          {showCounters ? (
            <>
              <Typography style={{ fontSize: '18px' }}>
                {getIdeasFeedbackMessage(importInsertIntegrationItemsData)}
                <br />
                {getInitiativesFeedbackMessage(importInsertIntegrationItemsData)}
              </Typography>
              {!isImportInsertIntegrationItemsLoading && importInsertIntegrationItemsData?.alreadyLinked > 0 && (
                <Typography>
                  <br />
                  This list excludes {importInsertIntegrationItemsData?.alreadyLinked} Work Items that are already in dragonboat.
                </Typography>
              )}
            </>
          ) : (
            <Typography style={{ fontSize: '18px' }}>Import completed.</Typography>
          )}
          {!importInsertIntegrationItemsError && (
            <>
              <br />
              <Typography style={{ fontSize: '18px' }}>Next, you may</Typography>
              <br />
              <Typography style={{ fontSize: '18px' }}>
                <Link onClick={() => onFinish('ideas-board')} to="/ideas/board" style={{ color: 'initial' }}>
                  <ViewWeekIcon style={{ verticalAlign: 'bottom', marginRight: 10 }} />
                </Link>
                Align and prioritize {getSystemFieldName('idea', true)}s and {getSystemFieldName('initiative', true)}s on the{' '}
                <Link onClick={onFinish} to="/ideas/board">
                  {getSystemFieldName('idea', true)} Board
                </Link>
              </Typography>
              <br />
              <br />
              <Typography style={{ fontSize: '18px' }}>
                <Link onClick={() => onFinish('manage')} to="/ideas/timeline" style={{ color: 'initial' }}>
                  <TimelineIcon style={{ verticalAlign: 'bottom', marginRight: 10 }} />
                </Link>
                {'Adjust timelines and assign resources on the '}
                <Link onClick={() => onFinish('manage')} to="/ideas/timeline">
                  Manage
                </Link>{' '}
                page
              </Typography>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

ResultStep.propTypes = {
  integrationType: PropTypes.string.isRequired,
  orgIntegrationId: PropTypes.number,
  onFinish: PropTypes.func.isRequired,
};

export default ResultStep;
