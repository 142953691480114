import axios from 'axios';

import { createThunk } from 'utils/store/thunk';
import { FETCH_PERSONAS } from 'features/Personas/store/types';

const fetchPersonasData = () =>
  createThunk(
    FETCH_PERSONAS,
    axios.get('/api/v1/personas').then(({ data }) => data),
  );

export { fetchPersonasData };
