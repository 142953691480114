import { useCallback } from 'react';
import { isEmpty } from 'lodash';

import {
  calculateDateDiffBySteps,
  convertKeyIntoArray,
  DAY,
  durationInDays,
  getRowOrderKey,
  GROUP_HEADERS_ROW,
  itemDirection,
  MIN_DELTA_TO_SNAP,
  ROW_HEIGHT,
  defaultToNullString,
  findGroupsLevel3ByGroupIdsAndGroupingIndex,
  isItemCenterInsideGroup,
} from '../helpers';
import dropRight from 'lodash/dropRight';

/**
 * Custom hooks responsible for the handlers decoration in order to add internal needed logic to the handlers.
 * */
const useTimelineDecorateHandlers = ({
  handlers: { onRowClick, onDrag, onResize },
  options: {
    isLoading,
    snapToGridOn,
    zoomMode,
    slotWidth,
    internalData,
    hasVerticalScroll,
    internalOrders,
    updateItemAfterDrag,
    updateItemAfterResize,
  },
}) => {
  const handleRowClick = useCallback(
    groups => event => {
      event.stopPropagation();

      if (!isLoading && onRowClick) {
        onRowClick(groups);
      }
    },
    [isLoading, onRowClick],
  );

  const handleDrag = (item, groupIndexes, rowIndex) => delta => {
    // horizontal / date changes
    const horizontalSteps =
      snapToGridOn || zoomMode === DAY
        ? Math.round((delta.x - item.info.left) / slotWidth)
        : (delta.x - item.info.left) / slotWidth;

    const dateDiff = calculateDateDiffBySteps(item, horizontalSteps, zoomMode, snapToGridOn);

    // vertical / lane changes
    const skipVertical = delta.deltaY === delta.y;

    // if should only update when the user drag to a different slot
    if (snapToGridOn && skipVertical && Math.abs(horizontalSteps) < MIN_DELTA_TO_SNAP) {
      return;
    }

    const oldOrderKey = getRowOrderKey(internalData, groupIndexes, rowIndex);

    const newBaseOrderIndex = Math.round(Math.abs(delta.lastY + (hasVerticalScroll ? ROW_HEIGHT : 0)) / ROW_HEIGHT);
    const newOrderIndex = hasVerticalScroll ? Math.max(newBaseOrderIndex - 1, 0) : newBaseOrderIndex;
    const newOrderKey = internalOrders[newOrderIndex];

    let newOrderGroupIds = convertKeyIntoArray(newOrderKey);

    const anyGroupChanged = oldOrderKey !== newOrderKey;

    const isGroupHeaderRow = groupIndexes.length === 3 && newOrderGroupIds.includes(GROUP_HEADERS_ROW);

    if (isGroupHeaderRow) {
      // remove grouping part
      newOrderGroupIds = newOrderGroupIds.filter(part => part !== GROUP_HEADERS_ROW);

      // Find the group 3 level id
      const groupsLevel3 = findGroupsLevel3ByGroupIdsAndGroupingIndex(internalData, newOrderGroupIds);

      if (!isEmpty(groupsLevel3)) {
        const itemHalfWidth = item.info.width / 2;
        const itemCenter = delta.x + itemHalfWidth;

        const foundLevel3Group = groupsLevel3.find(isItemCenterInsideGroup(itemCenter));

        // remove the groupIndex
        newOrderGroupIds = dropRight(newOrderGroupIds);

        if (foundLevel3Group) {
          newOrderGroupIds.push(defaultToNullString(foundLevel3Group.id));
        }
      }
    } else {
      // remove the rowIndex
      newOrderGroupIds = dropRight(newOrderGroupIds);
    }

    // Update the item and local state
    if (anyGroupChanged || dateDiff) {
      if (onDrag) {
        const wasUpdated = onDrag(item, dateDiff, newOrderGroupIds);

        if (wasUpdated) {
          updateItemAfterDrag({
            item,
            groupIndexes,
            rowIndex,
            dateDiff,
            newOrderGroupIds,
            anyGroupChanged,
          });
        }
      }
    }
  };

  const handleResize =
    (item, groupIndexes, rowIndex) =>
    (...params) => {
      const [direction, delta, position] = params;

      // updates externally

      if (direction === itemDirection.LEFT) {
        const steps =
          snapToGridOn || zoomMode === DAY
            ? Math.round((position.x - item.info.left) / slotWidth)
            : (position.x - item.info.left) / slotWidth;

        const dateDiff = durationInDays(steps, zoomMode);

        if (onResize) {
          onResize(item, dateDiff, 0);
        }
      }

      if (direction === itemDirection.RIGHT) {
        const steps = snapToGridOn ? Math.round(delta.width / slotWidth) : delta.width / slotWidth;
        const dateDiff = durationInDays(steps, zoomMode);

        if (onResize) {
          onResize(item, 0, dateDiff);
        }
      }

      // updates internally
      updateItemAfterResize({ item, groupIndexes, rowIndex, direction, position, delta });
    };

  return { handleRowClick, handleDrag, handleResize };
};

export default useTimelineDecorateHandlers;
