import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { defaultTo, equals, pipe, prop } from 'ramda';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Link from 'components/Link';

import { getRallyObjectUrl } from 'utils/integrations/getRallyObjectUrl';

const DATA = 'data';

const defaultToEmptyArray = defaultTo([]);
const defaultToEmptyObject = defaultTo({});

const getOrgIntegrationData = pipe(defaultToEmptyObject, prop(DATA));

const ProjectIntegrationDetails = props => {
  const { orgIntegration, projectIntegrations, onUnlink, disableActions } = props;

  const { link, key } = useMemo(() => {
    const currentProjectIntegration = defaultToEmptyArray(projectIntegrations).find(projectIntegration =>
      equals(projectIntegration.org_integration_id, orgIntegration.id),
    );

    return getRallyObjectUrl(getOrgIntegrationData(orgIntegration), currentProjectIntegration) || {};
  }, [orgIntegration.id, projectIntegrations]);

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid item xs>
        <Link href={link} target="_blank">
          {key}
        </Link>
      </Grid>
      <Button item color="primary" onClick={onUnlink} disabled={disableActions}>
        Unlink
      </Button>
    </Grid>
  );
};

ProjectIntegrationDetails.propTypes = {
  orgIntegration: PropTypes.object.isRequired,
  projectIntegrations: PropTypes.array.isRequired,
  onUnlink: PropTypes.func.isRequired,
};

export default ProjectIntegrationDetails;
