import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTo } from 'ramda';
import { useDebouncedCallback } from 'use-debounce';

import {
  getClustersDataForAnalyseChart,
  getSelectedAnalyseClusterBy,
  getSelectedAnalyzeBubbleSizeBy,
  isUninitializedClustersDataForAnalyseChart,
} from '../store/selectors';
import { fetchCustomerRequestsClustersForAnalyseChart } from '../store/thunks';
import addColorTransparency from 'utils/addColorTransparency';
import { UNDEFINED_LABEL } from 'constants/common';
import { getFiltersCompiled } from 'store/customerRequests/selectors';

import { CLUSTER_TYPE, CUSTOMER_REQUEST_TYPE, BUBBLE_SIZE_NUMBER_OF_REQUESTS } from '../utils';
import { subscribeToRefreshBubbleChartClustersOnTagsChanged } from '../store/listenerMiddleware';

const defaultAsUndefinedTitle = defaultTo(UNDEFINED_LABEL);
const defaultBubbleSizeToNumberOfRequests = defaultTo(BUBBLE_SIZE_NUMBER_OF_REQUESTS);
const defaultToEmptyObject = defaultTo({});

const CUSTOMER_REQUESTS_CLUSTER_LIMIT = 100;
const LOAD_DATA_DEBOUNCE_TIME = 400;

/**
 * Custom React hook for retrieving and processing clusters data for the analysis chart.
 *
 * @returns {object} An object containing chart data, initialization status, and selected parameters.
 */
const useClustersDataForAnalyzeChart = () => {
  const dispatch = useDispatch();
  const data = useSelector(getClustersDataForAnalyseChart);
  const isUninitialized = useSelector(isUninitializedClustersDataForAnalyseChart);

  const selectedClusterBy = useSelector(getSelectedAnalyseClusterBy);
  const selectedBubbleSizeBy = useSelector(getSelectedAnalyzeBubbleSizeBy);

  const filters = useSelector(getFiltersCompiled);

  const fetchAnalyzeChartData = () => dispatch(fetchCustomerRequestsClustersForAnalyseChart(selectedClusterBy?.key, filters));
  const [debouncedFetchAnalyzeChartData] = useDebouncedCallback(fetchAnalyzeChartData, LOAD_DATA_DEBOUNCE_TIME);

  useEffect(() => {
    // should be debounced to prevent multiple requests on local search filter change
    debouncedFetchAnalyzeChartData();
  }, [selectedClusterBy?.key, filters]);

  useEffect(() => {
    const unsubscribeRefreshData = dispatch(subscribeToRefreshBubbleChartClustersOnTagsChanged());

    return unsubscribeRefreshData;
  }, []);

  const getBubbleWeightForRequest = useCallback(
    customerRequest => {
      const bubbleSizeBy = defaultBubbleSizeToNumberOfRequests(selectedBubbleSizeBy?.key);
      const requestCounters = defaultToEmptyObject(customerRequest?.counters);

      if (bubbleSizeBy === BUBBLE_SIZE_NUMBER_OF_REQUESTS) {
        return 1;
      }

      return requestCounters[bubbleSizeBy] || 1;
    },
    [selectedBubbleSizeBy?.key],
  );

  const chartData = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.map(d => {
      let children = d.customer_requests.map((cr, index) => ({
        id: cr.id,
        key: cr.key,
        type: CUSTOMER_REQUEST_TYPE,
        _id: defaultAsUndefinedTitle(cr?.title),
        title: cr?.title,
        text: defaultAsUndefinedTitle(cr?.title),
        value: getBubbleWeightForRequest(cr),
        color: d.color,
        bgColor: d.color,
      }));

      if (children.length > CUSTOMER_REQUESTS_CLUSTER_LIMIT) {
        const sum = children.reduce((acc, d) => acc + d.value, 0);

        children = children.slice(0, CUSTOMER_REQUESTS_CLUSTER_LIMIT).map(c => ({
          ...c,
          value: sum / CUSTOMER_REQUESTS_CLUSTER_LIMIT,
        }));
      }

      return {
        _id: defaultAsUndefinedTitle(d.title),
        id: d.id,
        type: CLUSTER_TYPE,
        title: d.title,
        text: defaultAsUndefinedTitle(d.title),
        color: d.color,
        bgColor: addColorTransparency(d.color, 0.1),
        children,
      };
    });
  }, [data, getBubbleWeightForRequest]);

  return { data: chartData, isUninitialized, selectedClusterBy, selectedBubbleSizeBy };
};

export default useClustersDataForAnalyzeChart;
