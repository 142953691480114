import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import LaunchIcon from '@material-ui/icons/Launch';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import { equals, isEmpty, not, pipe } from 'ramda';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Dialog from 'design-system/molecules/Dialog/index';
import FluidColorField from 'design-system/molecules/FluidColorField/index';
import FluidContainer from 'design-system/atoms/FluidContainer/index';
import FluidFieldGroup from 'design-system/molecules/FluidFieldGroup/index';
import FluidDragonTableField from 'design-system/organisms/FluidDragonTableField/index';
import FluidHealthField from 'design-system/organisms/FluidHealthField/index';
import FluidMetadataRoadmapsField from 'design-system/organisms/FluidMetadataRoadmapsField/index';
import FluidNumberField from 'design-system/organisms/FluidNumberField/index';
import FluidProgressField from 'design-system/organisms/FluidProgressField/index';
import FluidRoadmapMetadataSelectField from 'design-system/organisms/FluidRoadmapMetadataSelectField/index';
import FluidTextAndDateField from 'design-system/organisms/FluidTextAndDateField/index';
import FluidTextField from 'design-system/organisms/FluidTextField/index';
import FluidTimePeriodField from 'design-system/organisms/FluidTimePeriodField/index';
import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import ObjectiveType from 'design-system/atoms/ObjectiveType/index';
import FluidFieldLabel from 'design-system/molecules/FluidFieldLabel/index';

import ObjectivesHistory from './ObjectivesHistory';
import formatDateTime from 'utils/dates/formatDateTime';
import formatDate from 'utils/dates/formatDate';
import { getUserName } from 'utils';
import Header from './Header';
import Footer from './Footer';
import pluralize from 'pluralize';
import { OBJECT_OBJECTIVE, OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2, OBJECT_OBJECTIVE_CORP_STRING } from 'store/objectives';
import Autocomplete from 'containers/Autocomplete';
import LoadableUsersAutocomplete from 'containers/LoadableUsersAutocomplete';
import ConfirmDialog from 'components/ConfirmDialog';
import FluidMetadataAutocompleteField from 'containers/FluidMetadataAutocompleteField';
import FluidAutocompleteField from 'design-system/organisms/FluidAutocompleteField';
import Comments from './Comments';
import { ACTUAL, TARGET, formatMetricValueNumber, getMetricValue } from 'utils/metricValues';
import { getObjectiveKeyByLevel } from 'utils/objectives';
import theme, { spacing } from 'design-system/theme';
import useRoadmapMetadataObjectiveLightbox from './hooks/useRoadmapMetadataObjectiveLightbox';
import { getOrgHasMetadataRoadmaps as getOrgHasMetadataRoadmapsSelector } from 'store/organization';
import { getCorpRoadmaps, getRoadmaps } from 'store/roadmaps/selectors';
import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';
import useProjectsListLightboxContext from 'hooks/useProjectsListLightbox';
import { METADATA_LEVELS, NONE_PLACEHOLDER, PAGE_HEADER_HEIGHT, TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';
import {
  OKR_PROGRESS_TYPE_AUTO,
  OKR_PROGRESS_TYPE_MANUAL,
  OKR_PROGRESS_TYPE_AUTO_ROLLUP_CHILDREN,
  KEY_RESULT_1_KEY,
  KEY_RESULT_2_KEY,
  CORP_OBJECTIVE_KEY,
} from 'constants/objectives';
import { metadataKeys } from 'utils/metadata/metadataKeys';
import useMetadataTab from 'hooks/useMetadataTab';
import MetadataPopover from 'containers/Metadata';
import { OBJECTIVE_METADATA_POPOVER } from 'store/metadataPopover';
import makeCanObjectiveMetadataRoadmapBeUpdated from 'utils/okrs/makeCanObjectiveMetadataRoadmapBeUpdated';
import ObjectivesMetricsForm from './ObjectivesMetricsForm/ObjectivesMetricsForm';
import FluidByTeamAutocompleteField from 'shared/components/inputs/Autocomplete/formFields/FluidByTeamAutocompleteField';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';
import { OBJECTIVES_DRAWER_TABS } from './constants';

import LinkedPortfolioItems from 'features/OKR/Drawer/LinkedPortfolioItems';

const CHILD_OF_OBJECTIVE_TYPE = {
  [OBJECT_OBJECTIVE_CORP_STRING]: OBJECT_OBJECTIVE,
  [OBJECT_OBJECTIVE]: OBJECT_KEY_RESULT,
  [OBJECT_KEY_RESULT]: OBJECT_KEY_RESULT_2,
};

const OBJECTIVE_KEY = 'objective';
const KEY_RESULT_KEY = 'key_result';

const METRICS_NAME_MULTILINE_ROWS_NUMBER = 2;
const ENTER_KEY_CODE = 13;
const LAUNCH_ICON_ID = 'okr__drawer__metric__lightbox';

const PROGRESS_HELP_LINK = `https://dragonboat.zendesk.com/hc/en-us/articles/360035869833-Integrate-OKRs-and-Portfolio-Items-OKR-
Roadmapping&sa=D&source=editors&ust=1673625550835787&usg=AOvVaw1CBL_yO6R9HQCcv9034osj`;
const METRICS_HELP_LINK = `https://dragonboat.zendesk.com/hc/en-us/articles/1500000664321-Outcome-Tracking-OKR-Metrics-`;

const HEADER_CUSTOM_STYLES = {
  'min-height': `${PAGE_HEADER_HEIGHT}px`,
  height: `${PAGE_HEADER_HEIGHT}px`,
  'align-items': 'center',
  padding: `0 ${spacing(2)}px 0 ${spacing(3)}px`,
  'border-bottom': `1px solid ${theme.palette.newLayout.background.borderGray}`,
  'box-shadow': 'none',
};

const verifyIfisCorpLevel = level => level === METADATA_LEVELS.LEVEL_CORP;

const getObjectTypeAndByLevel = (objectType, objective) => {
  return `${objectType}${verifyIfisCorpLevel(objective.level) ? `_${METADATA_LEVELS.LEVEL_CORP}_` : ''}`;
};

const getUniqueIdByLevel = (objectType, objective) => {
  return `${getObjectTypeAndByLevel(objectType, objective)}${objective.id ? objective.id : '-PENDING'}`;
};

const isProgressUpdateTypeAuto = progressType => progressType === OKR_PROGRESS_TYPE_AUTO;

const ObjectivesDetails = ({
  isOpen,
  onClose,
  onSave,
  onSaveAndOpen,
  update,
  updateTitle,
  selectedType,
  selected = {},
  parent,
  grandParent,
  onClickTitle,
  selectChild,
  getSystemFieldName,
  upsertKR,
  onDelete,
  activeTab,
  changeTab,
  metrics,
  keyResultsSelected,
  hasKeyResults2,
  users,
  usersOptions,
  allowAddOrUpdateObjective,
  isTitleDialogOpen,
  toggleTitleDialog,
  updateMetricValue,
  hasAdvancedMetricReporting,
  toggleMetricsDialog,
  matchOldLightboxHeight,
  createObjectiveRoadmap,
  deleteObjectiveRoadmap,
  bulkDeleteObjectiveRoadmaps,
  objectivesAndKeyResultsWithHierarchy,
  isDodActive,
  parentOptions = [],
  isNew,
  openMetadataDrawer,
  organizationId,
  hasMultipleMetrics,
  updateMetricBaseline,
  createMetricLinkToOkr,
  addMetricToOkr,
  removeMetricFromOkr,
  shouldShowAddNewRowOnTable,
  canUpdate,
  hideChildProgressOption,
  isObjectObjective,
  isObjectObjectiveCorp,
  isObjectKeyResult,
  isFormValid,
}) => {
  const { canView } = usePermissions();

  const roadmaps = useSelector(state => getRoadmaps(state));
  const corpRoadmaps = useSelector(state => getCorpRoadmaps(state, false));
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmapsSelector);
  const metadataRoadmapOptions = useRoadmapMetadataObjectiveLightbox(hasMetadataRoadmaps, roadmaps, corpRoadmaps, selected || {});

  const objectiveSystemFieldAlias = getSystemFieldName('objective');
  const keyResultSystemFieldAlias = getSystemFieldName('keyResult1');
  const keyResult2SystemFieldAlias = getSystemFieldName('keyResult2');

  const mapOkrTypesWithSystemFieldKey = {
    [OBJECT_OBJECTIVE_CORP_STRING]: 'objectiveCorp',
    [OBJECT_OBJECTIVE]: 'objective',
    [OBJECT_KEY_RESULT]: 'keyResult1',
    [OBJECT_KEY_RESULT_2]: 'keyResult2',
  };

  const systemFieldKey = mapOkrTypesWithSystemFieldKey[selectedType];

  const shouldRenderOkrTable = hasKeyResults2 && isObjectKeyResult && selected?.level === 0;

  const { openProjectsListLightbox: openProjectsListLightboxAction } = useProjectsListLightboxContext();

  const openProjectsListLightbox = okr => openProjectsListLightboxAction(okr, getObjectiveKeyByLevel(okr));

  const buildAlignsToErrorMessage = () => {
    const parentSystemFieldFromKey = key => {
      switch (key) {
        case KEY_RESULT_1_KEY:
          return OBJECTIVE_KEY;
        case KEY_RESULT_2_KEY:
          return KEY_RESULT_1_KEY;
        case OBJECTIVE_KEY:
          return CORP_OBJECTIVE_KEY;
        default:
          return null;
      }
    };

    const parentKey = parentSystemFieldFromKey(systemFieldKey);

    if (!systemFieldKey) {
      return '';
    }

    return `Please select which ${getSystemFieldName(parentKey)} this ${getSystemFieldName(systemFieldKey)} aligns to`;
  };

  const hasNewMetadataAutocomplete = canView(PERMISSION_FEATURES.newRoadmapMetadataAutocomplete);
  const ownerName = selected.owner && getUserName(selected.owner);

  const objectType = useMemo(() => {
    if (isDodActive) {
      return OBJECT_OBJECTIVE.toString() === selectedType.toString() ||
        OBJECT_OBJECTIVE_CORP_STRING.toString() === selectedType.toString()
        ? 'OBJECTIVE'
        : 'KEY_RESULT';
    }

    return isObjectObjective ? 'OBJECTIVE' : 'KEY_RESULT';
  }, [selected]);

  const isActive = useMemo(() => selected?.status === 'Active' || isNew, [selected]);

  const [
    ,
    formatMetadataRoadmaps,
    formatRoadmaps,
    handleClearAllItems,
    ,
    ,
    updateSelectedItems,
    addDefaultMetadataRoadmapName,
    removeMetadataRoadmap,
    getSelectedItemsRef,
  ] = useRoadmapsMetadata(
    createObjectiveRoadmap,
    deleteObjectiveRoadmap,
    objectivesAndKeyResultsWithHierarchy,
    OBJECTIVE_KEY,
    bulkDeleteObjectiveRoadmaps,
    objectType === 'KEY_RESULT' ? KEY_RESULT_KEY : null,
  );

  const resourceLabel = getSystemFieldName(systemFieldKey);

  let iconType = 'objective';

  const actualMetricValue = useMemo(() => {
    if (selected?.metrics?.length) {
      return getMetricValue(selected, metrics);
    }

    return {};
  }, [selected, metrics]);

  const targetMetricValue = useMemo(() => {
    if (selected?.metrics?.length) {
      return getMetricValue(selected, metrics, TARGET);
    }

    return {};
  }, [selected, metrics]);

  if (isObjectKeyResult) {
    if (selected.level === 0) {
      iconType = 'keyResult';
    } else {
      iconType = 'keyResult2';
    }
  }

  const baselineMetricValue = useMemo(() => {
    const metric = selected?.metrics?.[0];

    if (metric) {
      return {
        value: metric.baseline_value || null,
        date: metric.baseline_date || null,
      };
    }

    return { value: null, date: null };
  }, [selected]);

  const parentIconType = useMemo(() => {
    if (selectedType === OBJECT_KEY_RESULT_2) {
      return 'keyResult';
    }

    if (selectedType === OBJECT_OBJECTIVE) {
      return 'objectiveCorp';
    }

    return 'objective';
  }, [selectedType, selected]);

  const parentTitle = useMemo(() => {
    const isKeyResult2 = equals(selectedType, OBJECT_KEY_RESULT_2);
    const hasKeyResultsSelected = pipe(isEmpty, not)(keyResultsSelected);

    if (isKeyResult2 && hasKeyResultsSelected) {
      const keyResultParent = keyResultsSelected[0];

      return keyResultParent?.title;
    }

    return parent?.title;
  }, [keyResultsSelected, parent, selectedType]);

  const canAddOrEdit = useMemo(
    () => allowAddOrUpdateObjective(OBJECT_OBJECTIVE) || allowAddOrUpdateObjective(selectedType),
    [allowAddOrUpdateObjective, selectedType],
  );

  const _shouldShowAddNewRowOnTable = shouldShowAddNewRowOnTable(CHILD_OF_OBJECTIVE_TYPE[selectedType]);

  const hasParent = useMemo(() => {
    const alwaysHasParent = [OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2];

    if (isDodActive) {
      return [...alwaysHasParent, OBJECT_OBJECTIVE].includes(selectedType);
    }

    return alwaysHasParent.includes(selectedType);
  }, [selectedType]);

  // const disableEditAppliesTo = useMemo(() => {
  //   return [OBJECT_OBJECTIVE, OBJECT_OBJECTIVE_CORP].includes(selectedType);
  // }, [selected, selectedType]);

  const _metricNameKeyDown = event => {
    if (event.keyCode === ENTER_KEY_CODE) {
      event.preventDefault();
    }
  };

  const extraButton = useMemo(
    () =>
      hasAdvancedMetricReporting && !!(selected.metrics || []).length ? (
        <StyledIcon className="launchIcon" onClick={toggleMetricsDialog} title="Show Metric Chart">
          <LaunchIcon disableShrink fontSize="small" thickness={4} />
        </StyledIcon>
      ) : null,
    [hasAdvancedMetricReporting, selected?.metrics, toggleMetricsDialog],
  );

  const getSavedDateRange = selected => {
    if (selected) {
      const { start_date: startDate, end_date: endDate } = selected;

      const formattedStartDate = formatDate(startDate) || '';
      const formattedEndDate = formatDate(endDate) || '';

      return `${formattedStartDate} ${formattedEndDate && '-'} ${formattedEndDate}`;
    }

    return '';
  };

  const handleParentChange = parentTitle => {
    const parent = parentOptions.find(opt => opt.title === parentTitle);
    const parentKey = [OBJECT_KEY_RESULT_2, OBJECT_OBJECTIVE].includes(selectedType) ? 'parent_id' : 'objective_id';

    if (parent) {
      update({ [parentKey]: parent.id });
    }
  };

  const handleMetricChange = metricName => {
    const existingMetricId = selected.metrics?.length ? selected.metrics[0].id : null;

    if (!metricName && !existingMetricId) return;

    if (!metricName) return removeMetricFromOkr(existingMetricId);

    const metric = metrics.find(m => m.name === metricName);

    if (metric) {
      return addMetricToOkr(metric.id);
    }

    return createMetricLinkToOkr({ name: metricName });
  };

  const getLabel = () => {
    if (isObjectObjectiveCorp) return pluralize(objectiveSystemFieldAlias);
    if (isObjectObjective) return pluralize(keyResultSystemFieldAlias);
    return pluralize(keyResult2SystemFieldAlias);
  };

  const getButtonTitle = () => {
    if (isObjectObjectiveCorp) return pluralize(objectiveSystemFieldAlias);
    if (isObjectObjective) return `Add ${pluralize(keyResultSystemFieldAlias)}`;
    return `Add ${pluralize(keyResult2SystemFieldAlias)}`;
  };

  const getSubGoalType = useCallback(() => {
    if (isObjectObjectiveCorp) {
      return pluralize(objectiveSystemFieldAlias);
    }

    if (isObjectObjective) {
      return pluralize(keyResultSystemFieldAlias);
    }

    if (isObjectKeyResult) {
      return pluralize(keyResult2SystemFieldAlias);
    }

    return 'sub-goals';
  }, [selected, selectedType]);

  const progressTypeOptions = useMemo(() => {
    const children = selected.children || [];
    const keyResults = selected.keyResults || [];
    const hasChildren = children.length || keyResults.length;

    return [
      {
        key: OKR_PROGRESS_TYPE_MANUAL,
        title: 'Manual',
      },
      {
        key: OKR_PROGRESS_TYPE_AUTO_ROLLUP_CHILDREN,
        disabled: !hasChildren,
        hidden: hideChildProgressOption,
        title: `By ${getSubGoalType()}`,
      },
      {
        key: OKR_PROGRESS_TYPE_AUTO,
        title: 'By metrics',
      },
    ];
  }, [selected.progress_type, selected.children, selected.keyResults, selectedType]);

  const _onLabelClick = useCallback(metadataKey => {
    return () => openMetadataDrawer(metadataKey);
  }, []);

  const { getMetadataNote } = useMetadataTab();

  const hasParentSet = selected?.parent_id || selected?.objective_id;
  const historyModelName = isObjectObjective || isObjectObjectiveCorp ? 'objectives' : 'keyResults';

  const canObjectiveMetadataRoadmapBeUpdated = makeCanObjectiveMetadataRoadmapBeUpdated(
    isDodActive,
    organizationId,
    true,
    canUpdate,
  );
  const isAppliesToEditDisabled = !canObjectiveMetadataRoadmapBeUpdated({ data: { ...selected, ...{ type: selectedType } } });

  const _handleClickAway = () => {
    if (isNew && isFormValid) onSave();
    onClose();
  };

  return (
    <>
      <StyledDialog
        id="objectives-lightbox"
        type="DRAWER"
        maxWidth="lg"
        scroll="paper"
        fullWidth
        fullHeight
        matchOldLightboxHeight={matchOldLightboxHeight}
        open={!!isOpen}
        onClose={_handleClickAway}
        header={
          <Header
            isEditable={canAddOrEdit}
            selected={selected}
            selectedType={selectedType}
            iconType={iconType}
            parent={parent}
            grandParent={grandParent}
            onClickTitle={(id, objType) => onClickTitle(id, objType)}
            updateTitle={updateTitle}
            active={isActive}
            toggleActive={() => update({ status: isActive ? 'Archived' : 'Active' })}
            onClose={onClose}
            activeTab={activeTab}
            changeTab={changeTab}
            keyResultsSelected={keyResultsSelected}
            openProjectsListLightbox={openProjectsListLightbox}
          />
        }
        headerCustomStyles={HEADER_CUSTOM_STYLES}
        actions={
          <Footer
            selectedType={selectedType}
            selected={selected}
            resourceLabel={resourceLabel}
            onDelete={onDelete}
            onClose={onClose}
            onSave={onSaveAndOpen}
            disableButton={!isFormValid}
            isNew={isNew}
            isObjectKeyResult={isObjectKeyResult}
          />
        }
      >
        {activeTab === OBJECTIVES_DRAWER_TABS.DETAILS && (
          <form key={`okr-drawer-${selected?.id}-${selectedType}`} autoComplete="off">
            <Grid container xs={12} justify="space-around">
              <FluidContainer size={6}>
                {hasParent && (
                  <>
                    <FluidMetadataAutocompleteField
                      key="parent"
                      value={parentTitle}
                      label="Aligned to"
                      onChange={handleParentChange}
                      options={parentOptions}
                      disableEdit={!canAddOrEdit}
                      format="row"
                      addNew={false}
                      autocompleteProps={{
                        startAdornment: parentIconType && (
                          <InputAdornment position="start">
                            <ObjectiveTypeIcon type={parentIconType} active={isActive} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {hasParent && !hasParentSet && <ErrorMessageContainer>{buildAlignsToErrorMessage()}</ErrorMessageContainer>}
                  </>
                )}
                <FluidHealthField
                  key="health"
                  value={selected.status_color}
                  label={`${resourceLabel} health`}
                  onChange={v => update({ status_color: v })}
                  format="row"
                  disableEdit={!canAddOrEdit}
                />
                {/* <FluidProgressField
                  key="activity-progress"
                  label="Activity progress"
                  value={selected.activity_progress}
                  onChange={progress => update({ activity_progress: progress })}
                  progressType="manual"
                  disableSwitch
                  format="row"
                /> */}
                {!isNew && (
                  <FluidProgressField
                    key="progress"
                    label="Progress"
                    value={selected.progress}
                    disableSwitch={isNew}
                    canEditProgressText={selected.progress_type === 'manual'}
                    disableEdit={!canAddOrEdit}
                    onChange={progress => {
                      update({
                        progress,
                        progress_type: selected.progress_type,
                      });
                    }}
                    progressType={selected.progress_type}
                    progressTypeOptions={progressTypeOptions}
                    changeProgressType={v => {
                      update({
                        progress_type: v,
                        ...(isProgressUpdateTypeAuto(v) && isEmpty(selected.metrics || []) ? { progress: 0 } : {}),
                      });
                    }}
                    format="row"
                    helpLink={PROGRESS_HELP_LINK}
                    helpLinkNextToFieldName
                  />
                )}
                <div style={{ height: 12 }} />
                <FluidRemirrorWYSIWYGEditor
                  key="links"
                  value={selected.links}
                  content={selected.links}
                  label="Links"
                  onChange={value => update({ links: value })}
                  disableEdit={!canAddOrEdit}
                  placeholder="Add some links here..."
                  disableToolbar
                  smallInput
                />
                <FluidRemirrorWYSIWYGEditor
                  key="description"
                  value={selected.description}
                  content={selected.description}
                  label={`${resourceLabel} description`}
                  onChange={value => update({ description: value })}
                  disableEdit={!canAddOrEdit}
                  placeholder="Write something here..."
                />
                <FluidRemirrorWYSIWYGEditor
                  key="summary"
                  value={selected.summary}
                  content={selected.summary}
                  label={`${resourceLabel} Status summary`}
                  onChange={value => update({ summary: value })}
                  disableEdit={!canAddOrEdit}
                  placeholder="Write something here..."
                />
                {!isNew && (isObjectObjective || isObjectObjectiveCorp || shouldRenderOkrTable) && (
                  <FluidDragonTableField
                    title={<FluidFieldLabel label={getLabel()} />}
                    value={(isObjectObjectiveCorp ? selected.children : selected.keyResults) || []}
                    newRowButtonTitle={getButtonTitle()}
                    allowNewRow={_shouldShowAddNewRowOnTable}
                    overflowAfter={6}
                    hideTableIfEmpty
                  >
                    <FluidDragonTableField.CircleColumn map={r => r.status_color} width={40} />
                    <FluidDragonTableField.TextColumn
                      editable={canAddOrEdit}
                      main
                      map={r => r.title}
                      header="Title"
                      onChange={(row, value) => upsertKR(row.id, { title: value })}
                      flexGrow={1.0}
                      width={80}
                      buttons={[{ icon: OpenInNewIcon, onClick: row => selectChild(row.id) }]}
                    />
                    <FluidDragonTableField.TextColumn
                      editable={canAddOrEdit}
                      map={r => r.progress}
                      displayAs={v => `${(v || 0) * 100}%`}
                      width={60}
                      onChange={(row, value) => upsertKR(row.id, { progress: +value / 100 })}
                    />
                    <FluidDragonTableField.TextColumn
                      editable={canAddOrEdit}
                      header="Owner"
                      map={r => getUserName(r.owner)}
                      onChange={(row, value) =>
                        !value ? upsertKR(row.id, { owner_id: null }) : upsertKR(row.id, { ownerName: value })
                      }
                      width={100}
                      dataMapper={u => ({ value: getUserName(u), label: getUserName(u) })}
                      editorComponent={LoadableUsersAutocomplete}
                      suggestions={users}
                    />
                  </FluidDragonTableField>
                )}
              </FluidContainer>
              <FluidContainer size={5} leftBorder>
                <FluidColorField
                  key="color"
                  value={selected.color}
                  label="Display color"
                  onChange={v => update({ color: v.hex })}
                  format="row"
                  disableEdit={!canAddOrEdit}
                />
                {!hasNewMetadataAutocomplete ? (
                  <FluidMetadataAutocompleteField
                    key="users"
                    value={ownerName}
                    label="Owner"
                    metadata="users"
                    onChange={value => update({ owner_id: value })}
                    format="row"
                    autocompleteComponent={LoadableUsersAutocomplete}
                    autocompleteProps={{
                      suggestions: users,
                    }}
                    disableEdit={!canAddOrEdit}
                  />
                ) : (
                  <FluidByTeamAutocompleteField
                    key="users"
                    name="users"
                    label={getSystemFieldName('owner')}
                    value={[ownerName]}
                    options={usersOptions || []}
                    onChange={user => update({ owner_id: user?.id || null })}
                    disableEdit={!canAddOrEdit}
                    getSystemFieldName={getSystemFieldName}
                    format="row"
                    withNullOption
                    focusOnInit
                  />
                )}
                {!isNew && hasMetadataRoadmaps && (
                  <FluidMetadataRoadmapsField
                    key="applies-to"
                    label="Applies to"
                    format="row"
                    roadmaps={isDodActive ? corpRoadmaps : roadmaps}
                    selected={{ ...selected, uniqueId: getUniqueIdByLevel(objectType, selected) }}
                    getSelectedItems={getSelectedItemsRef.current}
                    data={addDefaultMetadataRoadmapName({
                      value: formatMetadataRoadmaps(selected?.key_result_roadmaps || selected?.objective_roadmaps || []),
                      data: selected,
                    })}
                    updateSelectedItems={updateSelectedItems}
                    handleClear={handleClearAllItems}
                    objectType={getObjectTypeAndByLevel(objectType, selected)}
                    disableEdit={!canAddOrEdit || isAppliesToEditDisabled}
                    formatRoadmaps={formatRoadmaps}
                    removeMetadataRoadmap={removeMetadataRoadmap}
                  />
                )}
                <FluidTimePeriodField
                  key="time-period"
                  label="Time Period"
                  format="row"
                  value={getSavedDateRange(selected)}
                  startDate={selected?.start_date}
                  endDate={selected?.end_date}
                  onDateChange={update}
                  disableEdit={!canAddOrEdit}
                />
                <FluidNumberField
                  key="targetAllocationAmount"
                  value={selected.target_allocation_amount}
                  label="Target Allocation Amount"
                  onChange={v => update({ target_allocation_amount: v !== '' ? v : null })}
                  format="row"
                  disableEdit={!canAddOrEdit}
                />
                <FluidTextField
                  key="targetAllocationPercentage"
                  value={selected?.target_allocation_percentage ? selected?.target_allocation_percentage : null}
                  label="Target Allocation %"
                  onChange={v => update({ target_allocation_percentage: v !== '' ? v : null })}
                  suffix="%"
                  format="row"
                  disableEdit={!canAddOrEdit}
                />
                {!isNew && hasMultipleMetrics && (
                  <ObjectivesMetricsForm
                    labelTooltip={getMetadataNote(metadataKeys.metric)}
                    onLabelClick={_onLabelClick(metadataKeys.metric)}
                    okr={selected}
                    selectedType={selectedType}
                    canAddOrEdit={canAddOrEdit}
                    toggleMetricsDialog={hasAdvancedMetricReporting ? toggleMetricsDialog : null}
                  />
                )}
                {!isNew && !hasMultipleMetrics && (
                  <>
                    {hasMetadataRoadmaps ? (
                      <FluidRoadmapMetadataSelectField
                        key="metrics"
                        value={(selected.metrics || []).length ? selected.metrics[0].name : ''}
                        label="Metric name"
                        metadata="metrics"
                        onChange={v => handleMetricChange(v)}
                        options={metadataRoadmapOptions.metrics}
                        disableEdit={false}
                        roadmapsKey="metric"
                        format="row"
                        helpLink={METRICS_HELP_LINK}
                        autocompleteComponent={Autocomplete}
                        extraButton={extraButton}
                        onLabelClick={_onLabelClick(metadataKeys.metric)}
                        labelTooltip={getMetadataNote(metadataKeys.metric)}
                        extraButtonId={LAUNCH_ICON_ID}
                        addNew
                        withEllipsis
                        helpLinkNextToFieldName
                      />
                    ) : (
                      <FluidAutocompleteField
                        key="metricName"
                        value={(selected.metrics || []).length ? selected.metrics[0].name : null}
                        label="Metric name"
                        onChange={v => handleMetricChange(v)}
                        format="row"
                        options={(metrics || []).map(metric => metric.name)}
                        addNew
                        showLaunch={hasAdvancedMetricReporting && !!(selected.metrics || []).length}
                        handleLaunchClick={toggleMetricsDialog}
                        helpLink={METRICS_HELP_LINK}
                        autocompleteComponent={Autocomplete}
                        multilineRows={METRICS_NAME_MULTILINE_ROWS_NUMBER}
                        onKeyDown={_metricNameKeyDown}
                        disableEdit={!canAddOrEdit}
                        onLabelClick={_onLabelClick(metadataKeys.metric)}
                        labelTooltip={getMetadataNote(metadataKeys.metric)}
                        launchIconId={LAUNCH_ICON_ID}
                        placeholder={TYPE_TO_SEARCH_PLACEHOLDER}
                        readingPlaceholder={NONE_PLACEHOLDER}
                        helpLinkNextToFieldName
                      />
                    )}
                    <FluidTextAndDateField
                      key="metricCurrent"
                      label="Actual"
                      value={formatMetricValueNumber(actualMetricValue.value || null)}
                      onChange={v => updateMetricValue(ACTUAL, v, 'value', actualMetricValue)}
                      dateValue={actualMetricValue.date || null}
                      onDateChange={v => updateMetricValue(ACTUAL, v, 'date', actualMetricValue)}
                      format="row"
                      disableEdit={!canAddOrEdit}
                    />
                    <FluidTextAndDateField
                      key="metricTarget"
                      label="Target"
                      value={formatMetricValueNumber(targetMetricValue.value || null)}
                      onChange={v => updateMetricValue(TARGET, v, 'value', targetMetricValue)}
                      dateValue={targetMetricValue.date || null}
                      onDateChange={v => updateMetricValue(TARGET, v, 'date', targetMetricValue)}
                      format="row"
                      disableEdit={!canAddOrEdit}
                    />
                    <FluidTextAndDateField
                      key="metricBaseline"
                      label="Baseline"
                      value={formatMetricValueNumber(baselineMetricValue.value)}
                      onChange={v => updateMetricBaseline(selected.metrics[0]?.id, { baseline_value: v })}
                      dateValue={baselineMetricValue.date}
                      onDateChange={v => updateMetricBaseline(selected.metrics[0]?.id, { baseline_date: v })}
                      format="row"
                      disableEdit={!canAddOrEdit || !selected?.metrics?.length}
                    />
                  </>
                )}
                {!isNew && (
                  <FluidFieldGroup topBorder>
                    <Comments okr={selected} />
                    <FluidTextField
                      key="updatedBy"
                      value={getUserName(selected.updatedBy)}
                      label="Updated by"
                      format="row"
                      disableEdit
                    />
                    <FluidTextField
                      key="updatedAt"
                      value={formatDateTime(selected.updated_at)}
                      label="Updated at"
                      format="row"
                      disableEdit
                    />
                    {/* <FluidTextField
                    key="createdBy"
                    value={getUserName(objective.createdBy)}
                    label="Created by"
                    format="row"
                    disableEdit
                  /> */}
                    <FluidTextField
                      key="createdAt"
                      value={formatDateTime(selected.created_at)}
                      label="Created at"
                      format="row"
                      disableEdit
                    />
                  </FluidFieldGroup>
                )}
              </FluidContainer>
            </Grid>
          </form>
        )}
        {activeTab === OBJECTIVES_DRAWER_TABS.LINKED_ITEMS && (
          <LinkedPortfolioItems entity={selected} openProjectsListLightbox={openProjectsListLightbox} />
        )}
        {activeTab === OBJECTIVES_DRAWER_TABS.HISTORY && <ObjectivesHistory id={selected.id} resource={historyModelName} />}
      </StyledDialog>
      <ConfirmDialog
        isOpen={isTitleDialogOpen}
        onConfirm={toggleTitleDialog}
        onCancel={toggleTitleDialog}
        showActions={false}
        showCloseIcon
        showContent={false}
        title="Please enter a title before saving"
      />

      {isOpen ? <MetadataPopover forceDrawerComponent metadataPopoverType={OBJECTIVE_METADATA_POPOVER} /> : null}
    </>
  );
};

const StyledDialog = styled(Dialog)`
  &&&& > div[role='document'] {
    height: 100%;
    margin: 0;

    @media only screen and (max-width: 1100px) {
      width: 100%;
    }
  }
`;

const StyledIcon = styled(ButtonIcon)`
  &&&& {
    width: fit-content;
    cursor: pointer;
    padding: 0;
  }
`;

const ObjectiveTypeIcon = styled(ObjectiveType)`
  &&&& {
    font-size: ${theme.typography.fontSize}px;
  }
`;

const ErrorMessageContainer = styled.p`
  padding: 2px 8px;
  color: ${props => props.theme.palette.text.error};
  font-size: ${props => props.theme.typography.fontSizeSmall}px;
  line-height: 1em;
`;

export default ObjectivesDetails;
