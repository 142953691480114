import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IDEAS } from 'store/grids/constants';
import { getDisplayLayer, getProjectLayers, usePortfolioMode } from 'store/filters/selectors';
import { getOrgHasJiraIntegrated, getOrgHasMultipleIntegrations } from 'store/organization/selectors';
import { getGridConfigValue } from 'store/grids/selectors';
import { openProjectLightbox } from 'store/projectLightbox';
import { saveGridConfig } from 'store/grids';
import { getCurrentUser } from 'store/login/selectors';

import useSubscribeNewData from 'hooks/useSubscribeNewData';
import useSystemFields from 'hooks/useSystemFields';
import useOrganizations from 'hooks/useOrganizations';
import useLoadProjectsForTransactionPages from 'hooks/projects/useLoadProjectsForTransactionPages';
import useAddNewInlineProject from './hooks/useAddNewInlineProject';

import handleGroupsLinkClick from 'utils/okrs/handleGroupsLinkClick';
import { DEFAULT_SELECTED_VIEW, IDEAS_GRID_VIEWS, isEstimatesView } from 'constants/ideas';
import usePageFilters from 'hooks/filters/usePageFilters';
import { topics } from 'constants/topics';
import useEnrichedSelectedGroupsFromState from 'hooks/grid/useGridSelectedGroups';
import useIdeasGridData from 'routes/Ideas/IdeasGrid/hooks/useIdeasGridData';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const viewType = IDEAS;
const pageId = `ideasGrid:${viewType}`;

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [grid, setGrid] = React.useState(null);
    const [getSystemFieldName] = useSystemFields();
    const displayLayer = useSelector(getDisplayLayer);
    const currentUser = useSelector(getCurrentUser);
    const portfolioMode = useSelector(state => usePortfolioMode(state));
    const showMyItemsOnly = useSelector(state => getGridConfigValue(state, viewType, 'showMyItemsOnly'));
    const isTreeView = useSelector(state => getGridConfigValue(state, viewType, 'isTreeView'));
    const showAllocationReport = useSelector(state => getGridConfigValue(state, viewType, 'showAllocationReport'));
    const rowHeight = useSelector(state => getGridConfigValue(state, viewType, 'rowHeight'));
    const localSearchString = useSelector(state => getGridConfigValue(state, viewType, 'searchText'));
    const selectedView = useSelector(state =>
      getGridConfigValue(state, viewType, 'selectedView', DEFAULT_SELECTED_VIEW(viewType)),
    );
    const { selectedGroup1, selectedGroup2, selectedGroup3 } = useEnrichedSelectedGroupsFromState(viewType);
    const hasBet = useSelector(state => state.organization.organization.has_bet);
    const isSingleLayerGroupedBy = !portfolioMode && !!selectedGroup1?.key;
    const { hasIdeas } = useSelector(getProjectLayers);

    const {
      filters: { topLayer },
    } = useOrganizations();

    const { canView } = usePermissions();

    const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

    const shouldDisplayTreeViewToggle = canView(PERMISSION_FEATURES.ideasGridTreeView, { displayLayer, topLayer });
    const isTreeViewEnabled = shouldDisplayTreeViewToggle && isTreeView;

    const { searchString: globalSearchString } = usePageFilters();

    const searchString = localSearchString || globalSearchString;

    useSubscribeNewData(
      [
        'app',
        'projects',
        'tasks',
        'estimates',
        'phases',
        'roadmaps',
        'categories',
        topics.OBJECTIVES,
        topics.KEY_RESULTS,
        topics.METRICS,
        'users',
        'customFields',
        'timeframes',
        'priorities',
      ],
      false,
    );

    // In the ideas grid the withIntegrations must be forced in order to display the integration key in the grid
    const hasJiraIntegration = useSelector(getOrgHasJiraIntegrated);
    const orgHasMultipleIntegrations = useSelector(getOrgHasMultipleIntegrations);

    const [loadedProjects] = useLoadProjectsForTransactionPages(
      pageId,
      {},
      { withIntegrations: !hasJiraIntegration || orgHasMultipleIntegrations },
    );

    const { data, projectGroups } = useIdeasGridData({
      viewType,
      displayLayer,
      portfolioMode,
      isSingleLayerGroupedBy,
      getSystemFieldName,
      selectedGroup1,
      selectedGroup2,
      selectedGroup3,
      pageId,
      showMyItemsOnly,
      isTreeView: isTreeViewEnabled,
      hasBet,
    });

    const [loadChildrenSemaphore, setLoadChildrenSemaphore] = useState(true);

    useEffect(() => {
      if (!loadedProjects && !loadChildrenSemaphore) {
        setLoadChildrenSemaphore(true);
      }
    }, [loadedProjects]);

    const _onGridReady = params => setGrid({ gridApi: params.api, columnApi: params.columnApi });
    const _getGridApi = () => grid;
    const _onProjectLinkClick = id => () => dispatch(openProjectLightbox(id));
    const _onGroupsLinkClick = data => handleGroupsLinkClick(data, dispatch);

    const availableViews = portfolioMode ? IDEAS_GRID_VIEWS.filter(v => !isEstimatesView(v)) : IDEAS_GRID_VIEWS;
    const _onCloseAllocationReport = () => dispatch(saveGridConfig(viewType, 'showAllocationReport', false));

    const addUnsavedProject = useAddNewInlineProject(grid, projectGroups);

    return (
      <Component
        {...props}
        loaded
        data={data}
        viewType={viewType}
        pageId={pageId}
        availableViews={availableViews}
        portfolioMode={portfolioMode}
        selectedView={selectedView}
        onGridReady={_onGridReady}
        getGridApi={_getGridApi}
        onProjectLinkClick={_onProjectLinkClick}
        onGroupsLinkClick={_onGroupsLinkClick}
        showAllocationReport={showAllocationReport}
        onCloseAllocationReport={_onCloseAllocationReport}
        searchString={searchString}
        hasBet={hasBet}
        getSystemFieldName={getSystemFieldName}
        currentUser={currentUser}
        singleLayerGroupedBy={isSingleLayerGroupedBy ? displayLayer : undefined}
        displayLayer={displayLayer}
        rowHeight={rowHeight}
        addUnsavedProject={addUnsavedProject}
        showPageConfigForReadOnly={shouldDisplayControlsBar}
        hasIdeas={hasIdeas}
        showMyItemsOnly={showMyItemsOnly}
        hasTreeView={shouldDisplayTreeViewToggle}
        isTreeView={isTreeViewEnabled}
      />
    );
  };
};

export default componentHOC;
