import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { both, isEmpty, not, path, pipe } from 'ramda';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import CreateOrgIntegration from '../CreateOrgIntegration';
import CreateUserIntegration from '../CreateUserIntegration';

import useOrgIntegrations from 'features/OneStepIntegration/hooks/useOrgIntegrations';
import useUserIntegrations from 'features/OneStepIntegration/hooks/useUserIntegrations';
import useOrgIntegrationWebhooks from '../../hooks/useOrgIntegrationWebhooks';

import { authenticateWithOAuth2, getFeaturesByIntegrationType } from 'features/OneStepIntegration/store';
import { integrationSupportsWebhookTrigger } from 'features/OneStepIntegration/helpers/triggerTypes';
import { INTEGRATIONS_KEYS, INTEGRATION_AUTH_TYPES } from 'constants/integrations';
import OAuthConfig from '../CreateUserIntegration/auth-types/OAuthConfig';

import { spacing } from 'design-system/theme';
import Text from 'design-system/atoms/Text/index';


const hasNotWebhooksCreated = pipe(path(['data', 'webhooks']), both(Array.isArray, pipe(isEmpty, not)), not);
const exist = Boolean;

export default ({ integration, orgIntegration, userIntegration, userCanViewOrgIntegration, userCanUpdateOrgIntegration }) => {
  const dispatch = useDispatch();

  const [addingOrgIntegration, setAddingOrgIntegration] = useState(exist(orgIntegration));

  const {
    addOrgIntegration,
    updateOrgIntegration,
    removeOrgIntegration,
    oneStepAvailableIntegrations,
    restoreOrgIntegrationData,
  } = useOrgIntegrations(integration.integrationType);

  const { addUserIntegration, isAddingUserIntegration } = useUserIntegrations(integration.integrationType, orgIntegration?.id);
  const { enableWebhooks } = useOrgIntegrationWebhooks(integration.integrationType, orgIntegration?.id);

  const integrationFeatures = useSelector(state => getFeaturesByIntegrationType(state, orgIntegration?.integrationType));

  const shouldShowContactInfo = !userCanViewOrgIntegration || (!userCanUpdateOrgIntegration && !orgIntegration);

  const handleRemoveOrgIntegration = () => {
    if (orgIntegration) {
      removeOrgIntegration(orgIntegration?.id);
    }
    setAddingOrgIntegration(false);
  };

  const handleSwitchOrgIntegrationConfig = ({ target }) => {
    if (target?.checked) {
      setAddingOrgIntegration(true);
    } else if (!orgIntegration) {
      setAddingOrgIntegration(false);
    } else {
      handleRemoveOrgIntegration();
    }
  };

  const integrationConfigs = oneStepAvailableIntegrations[integration.integrationType];
  const isOAuth2Integration = integrationConfigs.auth.type === INTEGRATION_AUTH_TYPES.oauth2;
  const showOAuth2Config = !userIntegration && addingOrgIntegration && isOAuth2Integration;
  const shouldShowOrganizationIntegrationStep = userCanUpdateOrgIntegration && !orgIntegration && addingOrgIntegration;
  const shouldShowUserIntegrationStep = orgIntegration && !userIntegration && addingOrgIntegration;

  const handleConfigureOrgIntegration = async (uri, additionalProps) => {
    const uriKey = integrationConfigs.uriKey || 'uri';

    await addOrgIntegration({ [uriKey]: uri, ...additionalProps });
  };

  const handleConfigureUserIntegration = async data => {
    const needsWebhookEnabled = hasNotWebhooksCreated(orgIntegration) && integrationSupportsWebhookTrigger(integrationFeatures);
    const needsOrgIntegrationRestore = orgIntegration && orgIntegration.needs_restore;

    const showSuccessAfterUserIntegration = not(needsOrgIntegrationRestore) && not(needsWebhookEnabled);

    await addUserIntegration(data, showSuccessAfterUserIntegration);

    if (orgIntegration && orgIntegration.needs_restore) {
      const showSuccessAfterRestoreIntegrationData = not(needsWebhookEnabled);

      await restoreOrgIntegrationData(orgIntegration.id, showSuccessAfterRestoreIntegrationData);
    }

    if (hasNotWebhooksCreated(orgIntegration) && integrationSupportsWebhookTrigger(integrationFeatures)) {
      await enableWebhooks(true);
    }
  };

  const handleOAuth2Config = useCallback(
    async ({ clientId, clientSecret }) => {
      let newOrgIntegrationId;

      if (!orgIntegration) {
        const result = await addOrgIntegration({ clientId, clientSecret });

        newOrgIntegrationId = result.id;
      } else if (orgIntegration && orgIntegration.needs_restore) {
        await restoreOrgIntegrationData(orgIntegration.id, false);
      } else {
        await updateOrgIntegration(orgIntegration.id, { clientId, clientSecret });
      }

      dispatch(authenticateWithOAuth2(integration.integrationType, orgIntegration?.id || newOrgIntegrationId));
    },
    [addOrgIntegration, orgIntegration],
  );

  if (shouldShowContactInfo) {
    return <p>Please contact your organization Admin to activate {integration?.label || 'this'} integration.</p>;
  }
  const EnableIntegrationRenderer = () => (
    <>
      {userCanUpdateOrgIntegration && (
        <IntegrationToggleContainer>
          <StyledFormControl
            control={
              <Switch
                id="one-step-integration-switch"
                color="secondary"
                checked={!!orgIntegration || addingOrgIntegration}
                onChange={handleSwitchOrgIntegrationConfig}
              />
            }
            label={`${integration.label} Integration`}
            labelPlacement="start"
          />
          {integration?.integrationType === INTEGRATIONS_KEYS.pendo ? (
            <InfoText variant="label">You need Pendo admin and Dragonboat admin permissions to perform the integration.</InfoText>
          ) : null}
        </IntegrationToggleContainer>
      )}
    </>
  );

  if (showOAuth2Config) {
    return (
      <>
        {EnableIntegrationRenderer()}
        {shouldShowOrganizationIntegrationStep && (
          <CreateOrgIntegration integration={integrationConfigs} onClickNext={handleConfigureOrgIntegration} />
        )}
        {shouldShowUserIntegrationStep && (
          <OAuthConfig
            integrationConfigs={integrationConfigs}
            onClickConnect={handleOAuth2Config}
            isConnecting={isAddingUserIntegration}
          />
        )}
      </>
    );
  }
  return (
    <>
      {EnableIntegrationRenderer()}
      {shouldShowOrganizationIntegrationStep && (
        <CreateOrgIntegration integration={integrationConfigs} onClickNext={handleConfigureOrgIntegration} />
      )}
      {shouldShowUserIntegrationStep && (
        <CreateUserIntegration
          integrationConfigs={integrationConfigs}
          onClickConnect={handleConfigureUserIntegration}
          isConnecting={isAddingUserIntegration}
        />
      )}
    </>
  );
};

const StyledFormControl = styled(FormControlLabel)``;

const InfoText = styled(Text)`
  margin-left: ${spacing(2)}px;
  display: block;
`;

const IntegrationToggleContainer = styled.div`
  margin-bottom: ${spacing(2)}px;
`;
