import { createSelector } from 'reselect';

import { isLoading } from 'utils/store/thunk';

import { getOrganizationIntegrations } from 'store/organization/selectors';

import { FETCH_ERROR_LOGS } from './types';

import { parseIntegration } from './helpers/parseIntegration';

export const getState = state => {
  return state.errorLogs;
};

export const getOperations = state => {
  return state.integrations.operations;
};

export const isLoadingErrorLogs = createSelector(getOperations, state => isLoading(state, FETCH_ERROR_LOGS));

export const getErrorLogs = createSelector(
  state => getOrganizationIntegrations(state),
  state => getState(state).byId,
  state => getState(state).allIds,
  (orgIntegrations, byId, allIds) => {
    return allIds.map(id => {
      const errorLog = byId[id];

      return {
        ...errorLog,
        integration: parseIntegration(errorLog, orgIntegrations),
      };
    });
  },
);
