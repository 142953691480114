import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { addQueryParamToUrl } from 'utils/queryParamsUtils';
import { OPEN_CUSTOMER_REQUEST_QUERY_PARAM } from 'constants/customerRequests';

import { setSelectedAnalizeBubbleSizeBy, setSelectedAnalizeClusterBy } from '../store/actions';
import { CUSTOMER_REQUEST_TYPE } from '../utils';

/**
 * Custom hook for managing events related to clusters during analysis.
 *
 * @param {object} options - Options for the hook.
 * @param {function} options.fetchCustomerRequests - Function to fetch customer requests.
 * @returns {object} An object containing event handlers and related state.
 */
const useClustersEventsOnAnalyze = ({ fetchCustomerRequests }) => {
  const dispatch = useDispatch();
  const [selectedCluster, setSelectedCluster] = useState(null);

  const handleClusterByChange = value => dispatch(setSelectedAnalizeClusterBy(value));
  const handleBubbleSizeByChange = value => dispatch(setSelectedAnalizeBubbleSizeBy(value));

  const onOpenCustomerRequestInfo = key => {
    addQueryParamToUrl(OPEN_CUSTOMER_REQUEST_QUERY_PARAM, key);
  };

  const handleBubbleClick = ({ srcElement }) => {
    const { data: elData } = srcElement.__data__;
    const { id, key, type } = elData;

    if (type === CUSTOMER_REQUEST_TYPE) {
      onOpenCustomerRequestInfo(key);
      return;
    }

    fetchCustomerRequests(id);
    setSelectedCluster(elData);
  };

  const handleCloseGridLightbox = () => {
    setSelectedCluster(null);
  };

  const handleClusterItemClick = cluster => {
    fetchCustomerRequests(cluster.id);
    setSelectedCluster(cluster);
  };

  return {
    selectedCluster,
    showGridLightbox: selectedCluster !== null,

    handleCloseGridLightbox,
    handleClusterByChange,
    handleBubbleSizeByChange,
    handleBubbleClick,
    handleClusterItemClick,
  };
};

export default useClustersEventsOnAnalyze;
