import { getThunkActionTypes } from 'utils/store/thunk';

export const OBJECT_OBJECTIVE_CORP = -1;
export const OBJECT_OBJECTIVE_CORP_STRING = 'CORP';
export const OBJECT_OBJECTIVE = '0';
export const OBJECT_KEY_RESULT = 1;
export const OBJECT_KEY_RESULT_2 = 2;

export const FETCH_OBJECTIVES = 'FETCH_OBJECTIVES';
export const FETCH_OBJECTIVES_FULFILLED = 'FETCH_OBJECTIVES_FULFILLED';
export const FETCH_OBJECTIVES_PENDING = 'FETCH_OBJECTIVES_PENDING';

export const UPDATE_OBJECTIVE = 'UPDATE_OBJECTIVE';
export const UPDATE_OBJECTIVE_FULFILLED = 'UPDATE_OBJECTIVE_FULFILLED';
export const UPDATE_OBJECTIVE_REJECTED = 'UPDATE_OBJECTIVE_REJECTED';

export const ADD_OBJECTIVE_WITHOUT_SAVE = 'ADD_OBJECTIVE_WITHOUT_SAVE';
export const REMOVE_OBJECTIVE_NOT_SAVED = 'REMOVE_OBJECTIVE_NOT_SAVED';

export const CREATE_OBJECTIVES = 'CREATE_OBJECTIVES';
export const CREATE_OBJECTIVES_FULFILLED = 'CREATE_OBJECTIVES_FULFILLED';
export const UPDATE_OBJECTIVES = 'UPDATE_OBJECTIVES';
export const UPDATE_OBJECTIVES_FULFILLED = 'UPDATE_OBJECTIVES_FULFILLED';
export const BULK_DELETE_OBJECTIVES = 'BULK_DELETE_OBJECTIVES';
export const BULK_DELETE_OBJECTIVES_FULFILLED = 'BULK_DELETE_OBJECTIVES_FULFILLED';

export const UNDO_CREATE_OBJECTIVES = 'UNDO_CREATE_OBJECTIVES';
export const UNDO_CREATE_OBJECTIVES_FULFILLED = 'UNDO_CREATE_OBJECTIVES_FULFILLED';
export const UNDO_UPDATE_OBJECTIVES = 'UNDO_UPDATE_OBJECTIVES';
export const UNDO_UPDATE_OBJECTIVES_FULFILLED = 'UNDO_UPDATE_OBJECTIVES_FULFILLED';
export const UNDO_BULK_DELETE_OBJECTIVES = 'UNDO_BULK_DELETE_OBJECTIVES';
export const UNDO_BULK_DELETE_OBJECTIVES_FULFILLED = 'UNDO_BULK_DELETE_OBJECTIVES_FULFILLED';

export const ADD_OBJECTIVE = 'ADD_OBJECTIVE';

export const REMOVE_OBJECTIVE_BY_ID = 'REMOVE_BUSSINESS_OBJECTIVE_BY_ID';

export const SWAP_OBJECTIVES_ROW_ORDER = 'SWAP_OBJECTIVES_ROW_ORDER';

export const MERGE_OBJECTIVES = 'MERGE_OBJECTIVES';
export const MERGE_OBJECTIVES_FULFILLED = 'MERGE_OBJECTIVES_FULFILLED';

export const UPDATE_OBJECTIVE_ROW_ORDER = 'UPDATE_OBJECTIVE_ROW_ORDER';
export const UPDATE_OBJECTIVE_ROW_ORDER_FULFILLED = 'UPDATE_OBJECTIVE_ROW_ORDER_FULFILLED';
export const UPDATE_OBJECTIVE_ROW_ORDER_PENDING = 'UPDATE_OBJECTIVE_ROW_ORDER_PENDING';
export const UPDATE_OBJECTIVE_ROW_ORDER_REJECTED = 'UPDATE_OBJECTIVE_ROW_ORDER_REJECTED';

export const CREATE_OBJECTIVE = 'CREATE_OBJECTIVE';
export const CREATE_OBJECTIVE_FULFILLED = 'CREATE_OBJECTIVE_FULFILLED';
export const CREATE_OBJECTIVE_REJECTED = 'CREATE_OBJECTIVE_REJECTED';

export const UPDATE_KEY_RESULT_ID = 'UPDATE_KEY_RESULT_ID';
export const UPDATE_KEY_RESULT_ID_FULFILLED = 'UPDATE_KEY_RESULT_ID_FULFILLED';
export const UPDATE_KEY_RESULT_ID_REJECTED = 'UPDATE_KEY_RESULT_ID_REJECTED';
export const UPDATE_KEY_RESULTS = 'UPDATE_KEY_RESULTS';
export const UPDATE_KEY_RESULTS_FULFILLED = 'UPDATE_KEY_RESULTS_FULFILLED';
export const REFRESH_KEY_RESULT_BY_ID = 'REFRESH_KEY_RESULT_ID';

export const UPDATE_KEY_RESULT_ROW_ORDER = 'UPDATE_KEY_RESULT_ROW_ORDER';
export const MOVE_KEY_RESULT_TO_OBJECTIVE = 'MOVE_KEY_RESULT_TO_OBJECTIVE';
export const MOVE_KEY_RESULT_TO_KEY_RESULT = 'MOVE_KEY_RESULT_TO_KEY_RESULT';
export const ADD_KEY_RESULT_WITHOUT_SAVE = 'ADD_KEY_RESULT_WITHOUT_SAVE';
export const REMOVE_UNSAVED_OBJECTIVES_AND_KEY_RESULTS = 'REMOVE_UNSAVED_OBJECTIVES_AND_KEY_RESULTS';

export const CREATE_KEY_RESULT = 'CREATE_KEY_RESULT';
export const CREATE_KEY_RESULT_FULFILLED = 'CREATE_KEY_RESULT_FULFILLED';
export const CREATE_KEY_RESULT_REJECTED = 'CREATE_KEY_RESULT_REJECTED';

export const CREATE_OBJECTIVE_ROADMAP = 'CREATE_OBJECTIVE_ROADMAP';
export const CREATE_OBJECTIVE_ROADMAP_FULFILLED = 'CREATE_OBJECTIVE_ROADMAP_FULFILLED';
export const DELETE_OBJECTIVE_ROADMAP = 'DELETE_OBJECTIVE_ROADMAP';
export const DELETE_OBJECTIVE_ROADMAP_FULFILLED = 'DELETE_OBJECTIVE_ROADMAP_FULFILLED';
export const BULK_DELETE_OBJECTIVE_ROADMAP = 'BULK_DELETE_OBJECTIVE_ROADMAP';
export const BULK_DELETE_OBJECTIVE_ROADMAP_FULFILLED = 'BULK_DELETE_OBJECTIVE_ROADMAP_FULFILLED';

export const CREATE_KEY_RESULT_ROADMAP = 'CREATE_KEY_RESULT_ROADMAP';
export const CREATE_KEY_RESULT_ROADMAP_FULFILLED = 'CREATE_KEY_RESULT_ROADMAP_FULFILLED';
export const DELETE_KEY_RESULT_ROADMAP = 'DELETE_KEY_RESULT_ROADMAP';
export const DELETE_KEY_RESULT_ROADMAP_FULFILLED = 'DELETE_KEY_RESULT_ROADMAP_FULFILLED';
export const BULK_DELETE_KEY_RESULT_ROADMAP = 'BULK_DELETE_KEY_RESULT_ROADMAP';
export const BULK_DELETE_KEY_RESULT_ROADMAP_FULFILLED = 'BULK_DELETE_KEY_RESULT_ROADMAP_FULFILLED';

export const DELETE_KEY_RESULT = 'DELETE_KEY_RESULT';
export const DELETE_KEY_RESULT_FULFILLED = 'DELETE_KEY_RESULT_FULFILLED';
export const BULK_DELETE_KEY_RESULTS = 'BULK_DELETE_KEY_RESULTS';
export const BULK_DELETE_KEY_RESULTS_FULFILLED = 'BULK_DELETE_KEY_RESULTS_FULFILLED';
export const UNDO_BULK_DELETE_KEY_RESULTS = 'UNDO_BULK_DELETE_KEY_RESULTS';
export const UNDO_BULK_DELETE_KEY_RESULTS_FULFILLED = 'UNDO_BULK_DELETE_KEY_RESULTS_FULFILLED';

export const MERGE_KEY_RESULTS = 'MERGE_KEY_RESULTS';
export const MERGE_KEY_RESULTS_FULFILLED = 'MERGE_KEY_RESULTS_FULFILLED';

export const OPEN_OBJECTIVE_DRAWER = 'OPEN_OBJECTIVE_DRAWER';
export const CLOSE_OBJECTIVE_DRAWER = 'CLOSE_OBJECTIVE_DRAWER';

export const CREATE_OBJECTIVE_FROM_DRAWER = 'CREATE_OBJECTIVE_FROM_DRAWER';
export const CREATE_OBJECTIVE_FROM_DRAWER_FULFILLED = 'CREATE_OBJECTIVE_FROM_DRAWER_FULFILLED';
export const CREATE_OBJECTIVE_FROM_DRAWER_REJECTED = 'CREATE_OBJECTIVE_FROM_DRAWER_REJECTED';

export const UPDATE_OBJECTIVE_FROM_DRAWER = 'UPDATE_OBJECTIVE_FROM_DRAWER';
export const UPDATE_OBJECTIVE_FROM_DRAWER_FULFILLED = 'UPDATE_OBJECTIVE_FROM_DRAWER_FULFILLED';
export const UPDATE_OBJECTIVE_FROM_DRAWER_REJECTED = 'UPDATE_OBJECTIVE_FROM_DRAWER_REJECTED';

export const CREATE_KEY_RESULT_FROM_DRAWER = 'CREATE_KEY_RESULT_FROM_DRAWER';
export const CREATE_KEY_RESULT_FROM_DRAWER_FULFILLED = 'CREATE_KEY_RESULT_FROM_DRAWER_FULFILLED';
export const CREATE_KEY_RESULT_FROM_DRAWER_REJECTED = 'CREATE_KEY_RESULT_FROM_DRAWER_REJECTED';

export const UPDATE_KEY_RESULT_FROM_DRAWER = 'UPDATE_KEY_RESULT_FROM_DRAWER';
export const UPDATE_KEY_RESULT_FROM_DRAWER_FULFILLED = 'UPDATE_KEY_RESULT_FROM_DRAWER_FULFILLED';
export const UPDATE_KEY_RESULT_FROM_DRAWER_REJECTED = 'UPDATE_KEY_RESULT_FROM_DRAWER_REJECTED';

const actionTypes = {
  ...getThunkActionTypes('ADD_OBJECTIVE_METRIC'),
  ...getThunkActionTypes('ADD_KEY_RESULT_METRIC'),
  ...getThunkActionTypes('BULK_ADD_KEY_RESULT_METRICS'),
  ...getThunkActionTypes('BULK_ADD_OBJECTIVE_METRICS'),
  ...getThunkActionTypes('BULK_REMOVE_KEY_RESULT_METRICS'),
  ...getThunkActionTypes('BULK_REMOVE_OBJECTIVE_METRICS'),
  ...getThunkActionTypes('REMOVE_OBJECTIVE_METRIC'),
  ...getThunkActionTypes('REMOVE_KEY_RESULT_METRIC'),
  ...getThunkActionTypes('CREATE_AND_ADD_METRIC_TO_OBJECTIVE'),
  ...getThunkActionTypes('CREATE_AND_ADD_METRIC_TO_KEY_RESULT'),
};

export const {
  ADD_OBJECTIVE_METRIC,
  ADD_OBJECTIVE_METRIC_PENDING,
  ADD_OBJECTIVE_METRIC_FULFILLED,
  ADD_OBJECTIVE_METRIC_REJECTED,

  ADD_KEY_RESULT_METRIC,
  ADD_KEY_RESULT_METRIC_PENDING,
  ADD_KEY_RESULT_METRIC_FULFILLED,
  ADD_KEY_RESULT_METRIC_REJECTED,

  BULK_ADD_KEY_RESULT_METRICS,
  BULK_ADD_KEY_RESULT_METRICS_PENDING,
  BULK_ADD_KEY_RESULT_METRICS_FULFILLED,
  BULK_ADD_KEY_RESULT_METRICS_REJECTED,

  BULK_ADD_OBJECTIVE_METRICS,
  BULK_ADD_OBJECTIVE_METRICS_PENDING,
  BULK_ADD_OBJECTIVE_METRICS_FULFILLED,
  BULK_ADD_OBJECTIVE_METRICS_REJECTED,

  BULK_REMOVE_KEY_RESULT_METRICS,
  BULK_REMOVE_KEY_RESULT_METRICS_PENDING,
  BULK_REMOVE_KEY_RESULT_METRICS_FULFILLED,
  BULK_REMOVE_KEY_RESULT_METRICS_REJECTED,

  BULK_REMOVE_OBJECTIVE_METRICS,
  BULK_REMOVE_OBJECTIVE_METRICS_PENDING,
  BULK_REMOVE_OBJECTIVE_METRICS_FULFILLED,
  BULK_REMOVE_OBJECTIVE_METRICS_REJECTED,

  REMOVE_OBJECTIVE_METRIC,
  REMOVE_OBJECTIVE_METRIC_PENDING,
  REMOVE_OBJECTIVE_METRIC_FULFILLED,
  REMOVE_OBJECTIVE_METRIC_REJECTED,

  REMOVE_KEY_RESULT_METRIC,
  REMOVE_KEY_RESULT_METRIC_PENDING,
  REMOVE_KEY_RESULT_METRIC_FULFILLED,
  REMOVE_KEY_RESULT_METRIC_REJECTED,

  CREATE_AND_ADD_METRIC_TO_OBJECTIVE,
  CREATE_AND_ADD_METRIC_TO_OBJECTIVE_PENDING,
  CREATE_AND_ADD_METRIC_TO_OBJECTIVE_FULFILLED,
  CREATE_AND_ADD_METRIC_TO_OBJECTIVE_REJECTED,

  CREATE_AND_ADD_METRIC_TO_KEY_RESULT,
  CREATE_AND_ADD_METRIC_TO_KEY_RESULT_PENDING,
  CREATE_AND_ADD_METRIC_TO_KEY_RESULT_FULFILLED,
  CREATE_AND_ADD_METRIC_TO_KEY_RESULT_REJECTED,
} = actionTypes;

export default actionTypes;
