import { materialColors } from 'design-system/themes/default';

import { ActionsCellRenderer } from '../cellRenderers';

const defaultGetVisibility = () => true;

const defaultOtherActionDefs = {};

/**
 * Should be used to create standard action column definitions only. Should be used at
 * module level and not to dynamically generate column definitions inside React components.
 * @param field Optional field, to simplify identifying the column.
 * @param icon Action icon
 * @param otherActionDefs Other options to pass to the action definition. If need be, can
 * be changed to a function receiving the lower order function's arguments.
 * @returns {function} Function to get the action column definition.
 */
const createActionColumnDefGetter = ({ field = 'actions', icon, otherActionDefs = defaultOtherActionDefs }) => {
  return ({ getVisibility = defaultGetVisibility, handleClick, cellClass }) => {
    return {
      field,
      headerName: '',
      editable: false,
      suppressMovable: true,
      filter: false,
      resizable: false,
      cellRenderer: ActionsCellRenderer,
      cellRendererParams: {
        actions: [
          {
            icon,
            color: materialColors.darkerGray,
            getVisibility,
            onClick: handleClick,
            ...otherActionDefs,
          },
        ],
      },
      width: 20,
      suppressMenu: true,
      cellClass,
      hide: false,
    };
  };
};

export default createActionColumnDefGetter;
