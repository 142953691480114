import { UPDATE_METADATA_POPOVER_OPEN_STATE } from './types';

export const openMetadataPopover = (popoverType, metadataSelected) => ({
  payload: {
    popoverType,
    isPopoverOpen: true,
    metadataSelected,
  },
  type: UPDATE_METADATA_POPOVER_OPEN_STATE,
});

export const closeMetadataPopover = popoverType => ({
  payload: {
    popoverType,
    isPopoverOpen: false,
  },
  type: UPDATE_METADATA_POPOVER_OPEN_STATE,
});
