import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { makeSelectObjectivesGroups, getDefaultGroupingObjects } from 'store/objectives/groupSelectors';
import { getAllKeyResults, getObjectivesCorpWithoutChildrenAccounts } from 'store/objectives/selectors';

import usePortfolioOKRs from './usePortfolioOKRs';
import useOrganizationsAccessControl from './useOrganizationsAccessControl';

const useKeyResults2Groups = ({
  selectedGroup1,
  selectedGroup2,
  selectedGroup3,
  hideEmptyUserPref,
  keyResultLevel = 1,
}) => {
  const keyResults = useSelector(getAllKeyResults);
  const portfolioKeyResults = usePortfolioOKRs(true, keyResults, true);
  const { childrenDragonsCorpObjectives } = useOrganizationsAccessControl();
  const portfolioObjectivesCorp = useSelector(state =>
    getObjectivesCorpWithoutChildrenAccounts(state, true, childrenDragonsCorpObjectives),
  );
  const defaultGroupingObjects = useSelector(getDefaultGroupingObjects);

  const portfolioGroupingObjects = {
    ...defaultGroupingObjects,
    objectivesCorp: portfolioObjectivesCorp,
  };

  /*
   * TODO: Consider migrating the logic from makeSelectObjectivesGroups to this hook.
   *
   * This potential migration could eliminate the need for using overrideMetadata
   * on the selector, as all required data can be sourced directly from the Redux store.
   * Evaluate the feasibility and benefits of such a migration.
   */
  const getObjectiveGroupsSelector = useMemo(
    () =>
      makeSelectObjectivesGroups({
        selectedGroup1,
        selectedGroup2,
        selectedGroup3,
        objects: portfolioKeyResults,
        hideEmptyUserPref,
        keyResultLevel,
      }),
    [
      selectedGroup1,
      selectedGroup2,
      selectedGroup3,
      hideEmptyUserPref,
      keyResultLevel,
      portfolioKeyResults,
      portfolioGroupingObjects,
    ],
  );

  const objectiveGroups = useSelector(() => getObjectiveGroupsSelector(portfolioGroupingObjects));

  return objectiveGroups;
};

export default useKeyResults2Groups;
