import transformClipboardData from './transformClipboardData';
import mapEditableColumns from './mapEditableColumns';
import { getSelectedCellFactory } from './getSelectedCellFactory';
import transformClipboardDataToGridWithoutIds from './transformClipboardDataToGridWithoutIds';

const processDataFromClipboard = (
  data,
  gridApi,
  gridColumnApi,
  columnDefs,
  isGridWithoutIds,
  pasteToUnlimitedCells,
  autoGroupColumnDef,
) => {
  // remove trail data, if data is copied from the ag-grid
  // it is concatenated an unexpected empty string to the end of clipboard data
  const getSelectedCell = getSelectedCellFactory(gridApi);

  if (!data) {
    return;
  }

  if (
    (data[0] instanceof Array && data[0].length !== 1 && data[data.length - 1].length === 1) ||
    (data[0] instanceof Array && data[0].length === 1 && data[data.length - 1].length === 1 && !data[data.length - 1][0])
  ) {
    data.pop();
  }

  const selectedCell = gridApi.getFocusedCell() || getSelectedCell();

  if (!selectedCell || selectedCell.rowIndex < 0) {
    throw new Error('There is no selected cell');
  }

  const {
    rowIndex,
    column: { colId },
  } = selectedCell;
  const selectedCellRow = gridApi.getRenderedNodes().find(node => node.rowIndex === rowIndex);

  if (!selectedCellRow || !selectedCellRow.data) {
    throw new Error('There is no selected row');
  }

  // handle normal columns
  const columns = columnDefs
    ? mapEditableColumns(
        gridColumnApi
          .getColumnState()
          .filter(column => !column.hide)
          .map(column => column.colId),
        columnDefs,
      )
    : gridColumnApi
        .getColumnState()
        .filter(column => !column.hide)
        .map(column => ({ id: column.colId }));

  // handle group column
  if (autoGroupColumnDef) {
    if (columnDefs) {
      columns.unshift(mapEditableColumns([autoGroupColumnDef.field], autoGroupColumnDef)[0]);
    } else {
      columns.unshift({ id: autoGroupColumnDef.field });
    }
  }

  const rowData = [];

  gridApi.forEachNodeAfterFilterAndSort(({ data, rowIndex }) => rowData.push({ ...data, rowIndex }));

  // limit copy paste to one cell
  if (!pasteToUnlimitedCells) {
    data = data.slice(0, 1).map(a => [a[0]]);
  }

  const changesToDo = isGridWithoutIds
    ? transformClipboardDataToGridWithoutIds(data, colId, selectedCellRow.rowIndex, columns, rowData)
    : transformClipboardData(data, colId, selectedCellRow.data, columns, rowData);

  return Promise.resolve(changesToDo);
};

export default processDataFromClipboard;
