import { defaultTo, prop } from 'ramda';

const defaultToEmptyArray = defaultTo([]);

const deleteProjectDeliverableFromStore = (projectsDeliverables, meta = {}) => {
  const { projectId, cycleDeliverableId } = meta;

  const getProjectDeliverables = prop(projectId);
  const projectDeliverables = defaultToEmptyArray(getProjectDeliverables(projectsDeliverables));

  const projectDeliverablesWithoutDeletedItem = projectDeliverables.filter(p => p.cycle_deliverable_id !== +cycleDeliverableId);

  return projectDeliverablesWithoutDeletedItem;
};

export default deleteProjectDeliverableFromStore;
