export const COMPARE_SNAPS_VIEW_QUERY_PARAM = 'viewId';
export const COMPARE_SNAPS_SELECTED_SNAPS_QUERY_PARAM = 'selectedSnaps';

export const COMPARE_ROADMAP_HISTORY = 'compareRoadmapHistory';
export const COMPARE_ROADMAP_HISTORY_SUMMARY = 'compareRoadmapHistorySummary';

export const CURRENT_DATA_ID = 'current';
export const CURRENT_DATA_TITLE = 'Current';
export const CURRENT_TITLE = 'Current';

export const REALTIME_TOPIC_NAME = 'roadmapHistory';

export const EDIT_SNAP_QUERY_PARAM = 'editSnap';

export const GRID_COMPARE_FIELDS_CONFIG = 'columnsToCompare';
export const GRID_SHOW_ITEMS_DIFFERENCES_CONFIG = 'showOnlyItemsWithDifferences';
export const GRID_HIGHLIGHT_FIELD_CONFIG = 'highlightField';

// Until we don't have the proper handling of the metadata and data on the grids we need to disable some columns
export const ROADMAP_HISTORY_GRID_AVAILABLE_COLUMNS = [
  'id',
  // 'parent',
  'roadmapTitle',
  'product1Title',
  'product2Title',
  'additionalRoadmaps',
  'additionalProducts',
  'additionalProducts2',
  'timeframeTitle',
  'timeframe2Title',
  'additionalTimeframes',
  'additionalTimeframes2',
  'ownerName',
  'categoryTitle',
  'additionalCategories',
  'themeTitle',
  'additionalThemes',
  'priorityTitle',
  'objectiveTitle',
  'keyResult1Title',
  'keyResult2Title',
  'additionalObjectives',
  'additionalKeyResults',
  'additionalKeyResults2',
  'additionalTeams',
  'additionalTeams2',
  'business_value',
  // 'value_offset_in_months',
  'effort_score',
  // 'planned_moar',
  // 'benefit1',
  // 'benefit2',
  // 'benefit3',
  // 'cost1',
  // 'priorityScore',
  'reach_score',
  'impact_score',
  'confidence_score',
  'riceScore',
  'details',
  'links',
  'estimated_start_date',
  'deadline',
  'completed_date',
  'predictedEndDate',
  'status_color',
  'status_summary',
  'progress',
  'phaseTitle',
  'planningStage',
  'customers',
  'tags',
  'integration',
  // 'updatedByName',
  // 'issueCounts',
  // 'storyPoint',
  // 'timeEstimate',
  // 'okr_progress',
  'metrics',
  'personas',
  'lifecycles',
  // 'sum',
  // 'planned_scope_in_weeks',
  // 'reportedAllocation',
  //  'completedAllocation',
  // 'scopeVariance',
  // 'totalRevenue',
  // 'activeRevenue',
  // 'inactiveRevenue',
  // 'customersCount',
  // 'customerRequestsCount',
  'resourceTeamTitle',
  'user1Name',
  'user2Name',
  'user3Name',
  'user4Name',
  'user5Name',
  'user6Name',
  'user7Name',
];

export const MAX_SNAPS_TO_COMPARE = 3;
