import axios from 'axios';

import {
  SAVE_CUSTOMER_REQUEST_COMMENT,
  FETCH_CUSTOMER_REQUEST_COMMENTS,
  FETCH_NEXT_CUSTOMER_REQUEST_COMMENTS,
  DELETE_CUSTOMER_REQUEST_COMMENT,
  EDIT_CUSTOMER_REQUEST_COMMENT,
} from './types';

export const fetchCustomerRequestComments = (customerRequestId, field) => {
  return dispatch => {
    if (!customerRequestId) {
      return;
    }

    const payload = axios
      .get(`/api/customer-requests/${customerRequestId}/comments${field ? `?field=${field}` : ''}`)
      .then(res => res.data);

    dispatch({
      type: FETCH_CUSTOMER_REQUEST_COMMENTS,
      payload,
      meta: { customerRequestId },
    });

    return payload;
  };
};

export const fetchNextCustomerRequestComments = (customerRequestId, field) => {
  return (dispatch, getState) => {
    const commentsState = getState().comments;

    const customerRequestCommentsState = commentsState.byCustomerRequest[customerRequestId];

    const url =
      customerRequestCommentsState && customerRequestCommentsState._meta && customerRequestCommentsState._meta.next
        ? customerRequestCommentsState._meta.next
        : `/api/customer-requests/${customerRequestId}/comments`;

    const payload = axios.get(`${url}${field ? `?field=${field}` : ''}`).then(res => res.data);

    dispatch({
      type: FETCH_NEXT_CUSTOMER_REQUEST_COMMENTS,
      payload,
      meta: { customerRequestId },
    });

    return payload;
  };
};

export const saveCustomerRequestComment = (customerRequestId, comment) => {
  return dispatch => {
    const payload = axios.post(`/api/customer-requests/${customerRequestId}/comments`, comment).then(res => res.data);

    dispatch({
      type: SAVE_CUSTOMER_REQUEST_COMMENT,
      payload,
      meta: { customerRequestId },
    });

    return payload;
  };
};

export const deleteCustomerRequestComment = (customerRequestId, id) => {
  return {
    payload: axios.delete(`/api/customer-requests/${customerRequestId}/comments/${id}`).then(response => response.data),
    type: DELETE_CUSTOMER_REQUEST_COMMENT,
    meta: { customerRequestId, id },
  };
};

export const editCustomerRequestComment = (customerRequestId, id, data) => {
  return dispatch => {
    const payload = axios.put(`/api/customer-requests/${customerRequestId}/comments/${id}`, data).then(response => response.data);

    dispatch({
      type: EDIT_CUSTOMER_REQUEST_COMMENT,
      payload,
      meta: { customerRequestId, id },
    });

    return payload;
  };
};
