import { useState, useEffect } from 'react';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';

import { searchProjectsByMetric } from 'store/projects/actions';
import { DAY } from 'utils/chartsDateTicks';

const getDateIntervals = dates => {
  if (!dates) return [];

  const intervals = [];

  for (let i = 0; i < dates.length - 1; i++) {
    const startDate = dates[i];
    const endDate = dates[i + 1];
    const interval = { startDate, endDate };

    intervals.push(interval);
  }

  return intervals;
};

const addPositionsToClusters = (clusters, chartInstance) => {
  if (!chartInstance || isEmpty(clusters)) return [];

  const xScale = chartInstance.scales?.x;
  const yPosition = chartInstance.chartArea?.bottom || 0;

  return clusters.map(cluster => {
    const startDateTickPosition = xScale?.getPixelForValue(new Date(cluster.startDate));
    const endDateTickPosition = xScale?.getPixelForValue(new Date(cluster.endDate));

    return {
      ...cluster,
      y: yPosition,
      x: (startDateTickPosition + endDateTickPosition) / 2,
    };
  });
};

const useProjectsClustersForMetric = (metricId, datesForChart, timeUnit, chartInstance) => {
  const [clusters, setClusters] = useState([]);

  const createClusters = async () => {
    if (isEmpty(datesForChart)) return;

    const startDate = new Date(datesForChart[0]);
    const endDate = new Date(datesForChart[datesForChart.length - 1]);

    const isDayUnit = timeUnit === DAY;

    const { data } = await searchProjectsByMetric(metricId, {
      startDate,
      endDate,
      timeUnit,
    });

    const intervals = getDateIntervals(datesForChart);

    const clusters = intervals.map(interval => {
      const matchingBucketData = data.find(bucket =>
        isDayUnit
          ? moment(bucket.bucketStartDate).isSame(interval.startDate, 'day')
          : moment(bucket.bucketStartDate).isBetween(interval.startDate, interval.endDate, 'day', '[)'),
      );

      return {
        ...interval,
        projectsIds: matchingBucketData?.projectIds || [],
      };
    });

    const filteredClusters = clusters.filter(cluster => cluster.projectsIds.length > 0);
    const clusterswithPositions = addPositionsToClusters(filteredClusters, chartInstance);

    setClusters(clusterswithPositions);
  };

  useEffect(() => {
    if (isNumber(metricId) && chartInstance && datesForChart && datesForChart.length > 0) {
      createClusters();
    }
  }, [metricId, datesForChart, chartInstance]);

  return clusters;
};

export default useProjectsClustersForMetric;
