import React, { useEffect } from 'react';
import styled from 'styled-components';
import Popper from '@material-ui/core/Popper';
import { isFunction } from 'lodash';
import useResizeObserver from 'use-resize-observer';

import DndList from 'design-system/organisms/DndList/index';

const DEFAULT_LIST_WIDTH = 300;

const ListPopper = ({
  open,
  anchorEl,
  popperRef,
  items,
  onClickItem,
  onGroupOpenClose,
  width = DEFAULT_LIST_WIDTH,
  registerExpandAllCallback,
  registerCollapseAllCallback,
  renderCustomEmptyOptions,
  focusedRef,
  onSelectItemWithKeyboard,
  disablePortal = true,
}) => {
  const { height: listHeight } = useResizeObserver({ ref: popperRef?.current?.popper?.popper });
  const modifiers = [
    {
      name: 'offset',
      options: {
        offset: [0, 5],
      },
    },
  ];

  useEffect(() => {
    if (isFunction(popperRef?.current?.popper?.scheduleUpdate)) {
      popperRef?.current?.popper?.scheduleUpdate();
    }
  }, [listHeight]);

  return (
    <StyledPopper
      open={open}
      anchorEl={anchorEl}
      modifiers={modifiers}
      ref={popperRef}
      disablePortal={disablePortal}
      placement="bottom-start"
    >
      <DndList
        items={items}
        onClickItem={onClickItem}
        onGroupOpenClose={onGroupOpenClose}
        width={width}
        registerExpandAllCallback={registerExpandAllCallback}
        registerCollapseAllCallback={registerCollapseAllCallback}
        renderCustomEmptyOptions={renderCustomEmptyOptions}
        openByDefault
        focusedRef={focusedRef}
        onSelectItemWithKeyboard={onSelectItemWithKeyboard}
        showTooltip
      />
    </StyledPopper>
  );
};

export default ListPopper;

const StyledPopper = styled(Popper)`
  z-index: ${({ theme }) => theme.zIndex.highest2};
`;
