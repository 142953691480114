import React from 'react';
import styled from 'styled-components';

import { Grid } from '@material-ui/core';

import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import Text from 'design-system/atoms/Text/index';
import SwitchWithLabel from 'design-system/molecules/SwitchWithLabel/SwitchWithLabel';


import CollapsePanel from 'components/CollapsePanel';
import useOrgIntegrationWebhooks from '../../../../hooks/useOrgIntegrationWebhooks';
import usePermissions from 'hooks/permissions/usePermissions';

const WebhooksSettings = props => {
  const { orgIntegration } = props;

  const { canUpdate } = usePermissions();

  const canToggleWebhook = canUpdate(PERMISSION_RESOURCES.integrationWebhooks, {
    integrationType: orgIntegration.integrationType,
  });

  const { hasWebhooksEnabled, firstWebhookUri, isEnablingWebhooks, isDisablingWebhooks, enableWebhooks, disableWebhooks } =
    useOrgIntegrationWebhooks(orgIntegration.integrationType, orgIntegration.id);

  const onToggleWebhooks = ({ target }) => {
    if (target.checked) {
      enableWebhooks();
    } else {
      disableWebhooks();
    }
  };

  return (
    <Wrapper>
      <CollapsePanel title={<Text variant="h4">Configure Webhook</Text>}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <SwitchWithLabel
              id="disable-webhooks"
              switchProps={{
                color: 'secondary',
                checked: hasWebhooksEnabled,
                disabled: !canToggleWebhook || isEnablingWebhooks || isDisablingWebhooks,
                onChange: onToggleWebhooks,
              }}
              labelProps={{
                color: 'textPrimary',
              }}
              label="Disable Webhook"
            />
          </Grid>
          {firstWebhookUri ? (
            <Grid item xs={12}>
              <Text>Copy the following link to finish the webhooks setup: {firstWebhookUri}</Text>
            </Grid>
          ) : null}
        </Grid>
      </CollapsePanel>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default WebhooksSettings;
