import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';

import {
  FETCH_TEAMS_FULFILLED,
  CREATE_TEAM_FULFILLED,
  UPDATE_TEAM_FULFILLED,
  DELETE_TEAM_FULFILLED,
  MERGE_TEAMS_FULFILLED,
  ADD_TEAM_WITHOUT_SAVE,
  REMOVE_UNSAVED_TEAMS,
  ADD_TEAM,
  FETCH_TEAMS_PENDING,
  CREATE_TEAMS_FULFILLED,
  UPDATE_TEAMS_FULFILLED,
  UNDO_CREATE_TEAMS_FULFILLED,
  UNDO_UPDATE_TEAMS_FULFILLED,
  BULK_DELETE_TEAMS_FULFILLED,
  UNDO_BULK_DELETE_TEAMS_FULFILLED,
  SWITCH_TEAMS_ROW_ORDER_FULFILLED,
  SWITCH_TEAMS_ROW_ORDER_PENDING,
  SWITCH_TEAMS_ROW_ORDER_REJECTED,
  BULK_DELETE_TEAM_ROADMAP_FULFILLED,
  CREATE_TEAM_ROADMAP_FULFILLED,
  DELETE_TEAM_ROADMAP_FULFILLED,
} from './types';
import upsertListItem from '../utils/upsertListItem';
import addRows from 'store/utils/addRows';
import updateRows from 'store/utils/updateRows';
import sortByRank from 'utils/sortByRank';

const initialState = {
  teams: [],
  isLoaded: false,
};

function removeUnsaved(list) {
  return list.filter(el => el.id);
}

export default function teamReducer(state = initialState, action) {
  switch (action.type) {
    case SWITCH_TEAMS_ROW_ORDER_REJECTED: {
      if (!action.meta || !action.meta.prev) return state;

      let teams = state.teams ? state.teams.slice(0) : [];

      teams = upsertListItem(action.meta.prev, teams);

      return {
        ...state,
        teams: teams.sort(sortByRank),
      };
    }
    case SWITCH_TEAMS_ROW_ORDER_PENDING:
    case SWITCH_TEAMS_ROW_ORDER_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        console.error('SWITCH_TEAMS_ROW_ORDER_FULFILLED::Team passed does not have id');
        return state;
      }

      let teams = state.teams ? state.teams.slice(0) : [];

      teams = upsertListItem(action.payload, teams);

      return {
        ...state,
        teams: teams.sort(sortByRank),
      };
    }
    case FETCH_TEAMS_PENDING:
      return {
        ...state,
        isLoaded: false,
      };
    case FETCH_TEAMS_FULFILLED:
      return {
        ...state,
        teams: action.payload.data.sort(sortByRank),
        isLoaded: true,
        lastCallsDate: moment().valueOf(),
      };
    case MERGE_TEAMS_FULFILLED:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        teams: state.teams.filter(team => !action.payload.includes(team.id)),
      };
    case ADD_TEAM:
    case CREATE_TEAM_FULFILLED:
    case UPDATE_TEAM_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        return state;
      }

      let teams = state.teams || [];

      teams = removeUnsaved(teams);
      teams = upsertListItem(action.payload, teams);

      return {
        ...state,
        teams,
        isLoaded: true,
      };
    }
    case DELETE_TEAM_FULFILLED:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        teams: state.teams.filter(team => team.id !== action.payload),
      };
    case ADD_TEAM_WITHOUT_SAVE:
      const teams = state.teams ? removeUnsaved(state.teams) : [];
      const teamLevel = action.team ? action.team.level + 1 : 0;
      const newItem = action.team ? { parent_id: action.team.id, level: teamLevel } : {};

      teams.unshift(newItem);

      return {
        ...state,
        teams,
      };
    case REMOVE_UNSAVED_TEAMS:
      return {
        ...state,
        teams: state.teams.filter(team => team.id),
      };
    case CREATE_TEAMS_FULFILLED: {
      const teams = addRows(state.teams, action.payload);

      return {
        ...state,
        teams,
        lastActionIds: action.payload.map(obj => obj.id),
      };
    }
    case UPDATE_TEAMS_FULFILLED: {
      const teams = updateRows(state.teams, action.payload);

      return {
        ...state,
        teams,
        lastActionIds: action.payload.map(obj => obj.id),
      };
    }
    case BULK_DELETE_TEAMS_FULFILLED: {
      const teams = state.teams.filter(r => !action.payload.includes(String(r.id)));

      return {
        ...state,
        teams,
        lastActionIds: action.payload,
      };
    }
    case UNDO_UPDATE_TEAMS_FULFILLED: {
      const teams = updateRows(state.teams, action.payload);

      return {
        ...state,
        teams,
        lastActionIds: null,
      };
    }
    case UNDO_CREATE_TEAMS_FULFILLED: {
      return {
        ...state,
        teams: state.teams.filter(c => state.lastActionIds.indexOf(c.id) === -1),
        lastActionIds: null,
      };
    }
    case UNDO_BULK_DELETE_TEAMS_FULFILLED: {
      const teams = updateRows(state.teams, action.payload);

      return {
        ...state,
        teams,
        lastActionIds: null,
      };
    }
    case CREATE_TEAM_ROADMAP_FULFILLED:
      const existing = cloneDeep(state.teams);
      const team = existing.find(obj => obj.id === action.payload.team_id);

      team.team_roadmaps = [...(team.team_roadmaps || []), action.payload];

      const updatedTeams = upsertListItem(team, existing);

      return {
        ...state,
        teams: updatedTeams,
      };
    case DELETE_TEAM_ROADMAP_FULFILLED:
      const existingTeams = cloneDeep(state.teams);
      const { id: teamId, teamRoadmaps } = action.payload;
      const parent = existingTeams.find(obj => obj.id === parseInt(teamId));

      parent.team_roadmaps = teamRoadmaps || [];

      const updated = upsertListItem(parent, existingTeams);

      return {
        ...state,
        teams: updated,
      };
    case BULK_DELETE_TEAM_ROADMAP_FULFILLED:
      const objs = cloneDeep(state.teams);
      const updatedObjs = objs.map(team => {
        if (team.id === action.payload) {
          return {
            ...team,
            team_roadmaps: [],
          };
        }

        return team;
      });

      return {
        ...state,
        teams: updatedObjs,
      };
    default:
      return state;
  }
}
