import React, { memo } from 'react';
import { DateLower, ProgressLower, TotalLower } from './headerComponents';

const HeaderIssueCount = memo(({ col, hasDetailedProgress }) => {
  if (hasDetailedProgress) {
    return (
      <DateLower>
        Total: <ProgressLower>{col.issuesTotal}</ProgressLower>&nbsp; In Progress:{' '}
        <ProgressLower>{col.issuesInProgress}</ProgressLower>&nbsp; Closed: <TotalLower>{col.issuesClosed}</TotalLower>
      </DateLower>
    );
  }

  return (
    <DateLower>
      Total: <ProgressLower>{col.totalTasks}</ProgressLower>&nbsp; Complete: <TotalLower>{col.completedTasks}</TotalLower>
    </DateLower>
  );
});

HeaderIssueCount.displayName = 'SummaryTableHeaderIssueCount';

export default HeaderIssueCount;
