import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUnselectedOverbookedTeams, getUnselectedUnderbookedTeams } from 'store/forecastHeadcount/selectors';
import { setUnselectedOverbookedTeams, setUnselectedUnderbookedTeams } from 'store/forecastHeadcount';

/**
 * @function useSelectedTeamsOnForecastHeadcount
 *
 * Use selected teams on headcount that comes from the store and also
 * the events to be used on the headcount teams filters
 *
 * @return {Object}
 */
const useSelectedTeamsOnForecastHeadcount = pageId => {
  const dispatch = useDispatch();
  const unselectedOverbookedTeams = useSelector(state => getUnselectedOverbookedTeams(state, pageId));
  const unselectedUnderbookedTeams = useSelector(state => getUnselectedUnderbookedTeams(state, pageId));

  const onSelectOverbookedTeam = useCallback(
    selectedId => {
      return dispatch(
        setUnselectedOverbookedTeams(pageId, {
          ...unselectedOverbookedTeams,
          [selectedId]: !unselectedOverbookedTeams[selectedId],
        }),
      );
    },
    [dispatch, pageId, unselectedOverbookedTeams],
  );

  const onSelectUnderbookedTeam = useCallback(
    selectedId => {
      return dispatch(
        setUnselectedUnderbookedTeams(pageId, {
          ...unselectedUnderbookedTeams,
          [selectedId]: !unselectedUnderbookedTeams[selectedId],
        }),
      );
    },
    [dispatch, pageId, unselectedUnderbookedTeams],
  );

  return {
    unselectedOverbookedTeams,
    unselectedUnderbookedTeams,
    onSelectOverbookedTeam,
    onSelectUnderbookedTeam,
  };
};

export default useSelectedTeamsOnForecastHeadcount;
