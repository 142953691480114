import React, { useEffect, useMemo, useState } from 'react';
import Loading from 'design-system/atoms/Loading/Loading';

import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';
import { path } from 'ramda';
import { PROMPT_RESULT_CHAT_STEP_ID, SLOT_FILL_CHAT_STEP_ID } from 'store/dragonbot/constants';

const getEntityOptions = (promptResult) => path(['nlp_process_data', 'entityOptions'], promptResult);

export default (props) => {
  const {
    step,
    previousStep,
    triggerNextStep,
  } = props;
  const [loading, setLoading] = useState(true);
  const [promptResult, setPromptResult] = useState({});
  const [error, setError] = useState({});

  useEffect(() => {
    if (!promptResult?.dragonbotPrompt) {
      return;
    }

    const userOptions = getEntityOptions(promptResult.dragonbotPrompt);

    if (!userOptions) {
      return triggerNextStep({ value: promptResult.processedAnswer });
    }

    triggerNextStep({
      trigger: SLOT_FILL_CHAT_STEP_ID,
      value: {
        message: promptResult.processedAnswer,
        options: userOptions.map(({ label, value }) => ({
          trigger: PROMPT_RESULT_CHAT_STEP_ID,
          value,
          label,
        })),
      },
    });
  }, [promptResult]);

  useEffect(() => {
    const { metadata, value } = previousStep;
    const promptText = metadata?.prompt || value || '';

    if (step.rendered) {
      setLoading(false);
      return;
    }

    if (promptText) {
      setLoading(true);
  
      axios.post('/api/v1/dragonbot/prompt', { text: promptText }).then(({ data }) => {
        setPromptResult(data);
      }).catch(err => {
        console.error(err);
        setError({ code: err.response?.data?.code });
        triggerNextStep();
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [step.rendered, previousStep.value, previousStep.metadata]);

  const answer = useMemo(() => {
    if (step.rendered) {
      return ReactHtmlParser(step.value?.message || step.value);
    }

    if (error?.code) {
      return 'Something went wrong please try again';
    }

    if (!promptResult.processedAnswer || promptResult.intent === 'None') {
      return `Sorry I don't have an answer for it yet, can i help with something else?`;
    }

    return ReactHtmlParser(promptResult.processedAnswer);
  }, [error?.code, promptResult.processedAnswer, promptResult.intent, step, previousStep]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="rsc-ts rsc-ts-user">
      <div className="rsc-ts-bubble">
        {answer}
      </div>
    </div>
  );
};
