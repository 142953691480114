/* eslint-disable import/no-named-as-default */
import { combineReducers } from 'redux';
import immutableTransform from 'redux-persist-transform-immutable';
import { createMigrate, persistReducer } from 'redux-persist';
import { reducer as formReducer } from 'redux-form';
import getPersistStorageSetup from 'store/utils/getPersistStorageSetup';
import axios from 'axios';

import app from './app';
import projects from './projects';
import teams from './teams';
import skills from './skills';
import users from './users';
import roadmaps from './roadmaps';
import phases from './phases';
import themes from './themes';
import categories from './categories';
import timeframes from './timeframes';
import tasks from './tasks';
import progress from './progress';
import objectives from './objectives';
import ideas from './ideas';
import staffing from './staffing';
import customFields from './customFields';
import grids from './grids';
import history from './history';
import organization from './organization';
import allocationReport from './allocationReport';
import ideasBoard from './ideasBoard';
import functionRoles from './functionRoles';
import forecast from './forecast';
import customers from './customers';
import tags from './tags';
import comments from './comments';
import notifications from './notifications';
import summary from './summary';
import manage from './manage';
import filters from './filters';
import login from './login';
import snapshot from './snapshot';
import userViews, { SET_PAGE_ACTIVE_VIEW } from './userViews';
import projectLightbox from './projectLightbox';
import priorities from './priorities';
import votes from './votes';
import customerRequests from './customerRequests';
import roadmap from './roadmap';
import metrics from './metrics';
import portfolioReport from './portfolioReport';
import entities from './entities';
import estimates from './estimates';
import integrations from './integrations';
import dependencies from './dependencies';
import configuration from './configuration';
import dashboards from './dashboards';
import roadmapVersions from './roadmapVersions';
import migrations from './migrations';
import metadataPopover from './metadataPopover';
import forecastHeadcount from './forecastHeadcount';
import accessControl from './accessControl';
import timelines from './timelines';
import goalMode from './goalMode';
import templates from './templates';
import errorLogs from './errorLogs';
import cycleDeliverables from './cycleDeliverables';
import dragonbot from './dragonbot';
import discoveryBot from './discoveryBot';
import announcements from './announcements';
import { releaseCapacityAllocationOperationsReducers } from './releaseCapacityAllocation';
import { getUpdatedStoreStateFromActiveViewChange } from 'store/userViews/utils';
import usersSettings from './users-settings/reducer';
import whiteboard from 'features/whiteboard/store';
import onboardingDemo from './onboardingDemo';
import personasData from 'features/Personas/store';
import personasGrid from 'features/PersonasGrid/store';
import lifecyclesData from 'features/Lifecycles/store';
import lifecyclesGrid from 'features/LifecyclesGrid/store';
import goalsCanvas from 'features/GoalsCanvas/store';
import metricsCanvas from 'features/MetricsCanvas/store';
import metricsDialog from 'features/MetricsDialog/store';
import timelineLightbox from 'features/TimelineLightbox/store';
import fieldsLayout from 'features/FieldsLayout/store';
import metricsAllocation from 'features/MetricsAllocation/store/reducer';
import pdlc from 'features/Pdlc/store/reducer';
import portfolioSummaryGrouping from 'features/PortfolioSummary/Grouping/store';
import userViewRecurringNotifications from 'features/UserViewRecurringNotification/store/reducer';
import okrDrawer from 'features/OKR/Drawer/store/reducer';
import requestsGlobalSearch from 'features/RequestsGlobalSearch/store';
import requestsHistory from 'features/RequestsHistory/store';

// Individual slices by feature
// TODO we may want to change this "routes" naming to features or something, didn't want to commit with anything right now
// because the reusability should be by feature not "route/page" concept
import customerRequestsAnalyze from 'src/routes/CustomerRequests/Analyze/store';
import portfolioOverview from 'features/PortfolioOverview/store';
import customerRequestsAutoTag from 'features/CustomerRequestsAutoTag/store';
import customerRequestsInsights from 'src/routes/CustomerRequests/Insights/store';
import oneStepIntegration from 'features/OneStepIntegration/store';
import missionControlHome from 'features/MissionControlHome/store';
import metricLevels from 'features/MetricLevels/store';
import roadmapHistory from 'features/RoadmapHistory/store';
import aiReleaseNotes from 'features/AIReleaseNotes/store';
import customerRequestMergedGrid from 'features/RequestsMergedGrid/store';
import metadataOnDemand from 'features/MetadataOnDemand/store/reducer';
import goalsAllocation from 'features/GoalsAllocation/store/reducer';
import goalsGrid from 'routes/Goals/GoalsGrid/store';

export const ON_USER_LOGOUT = 'ON_USER_LOGOUT';

const { metadata: metadataStorage, prefix: storagePrefix } = getPersistStorageSetup();

const persistConfig = {
  transforms: [immutableTransform()],
  keyPrefix: storagePrefix,
  key: 'root',
  storage: metadataStorage,
  version: 0,
  migrate: createMigrate(migrations, { debug: false }),
  whitelist: ['dragonbot', 'discoveryBot'],
};

const appReducer = combineReducers({
  entities,
  projects,
  app,
  login,
  form: formReducer,
  _filters: filters,
  userViews,
  projectLightbox,
  teams,
  users,
  roadmaps,
  phases,
  themes,
  categories,
  timeframes,
  skills,
  tasks,
  progress,
  snapshot,
  objectives,
  ideas,
  staffing,
  grids,
  history,
  organization,
  allocationReport,
  ideasBoard,
  functionRoles,
  forecast,
  customers,
  tags,
  roadmapHistory,
  customFields,
  comments,
  notifications,
  summary,
  manage,
  priorities,
  votes,
  roadmap,
  customerRequests,
  customerRequestsAnalyze,
  customerRequestsInsights,
  customerRequestsAutoTag,
  portfolioOverview,
  oneStepIntegration,
  missionControlHome,
  metrics,
  metricLevels,
  portfolioReport,
  estimates,
  integrations,
  dependencies,
  configuration,
  dashboards,
  roadmapVersions,
  metadataPopover,
  forecastHeadcount,
  accessControl,
  timelines,
  goalMode,
  templates,
  errorLogs,
  cycleDeliverables,
  releaseCapacityAllocation: releaseCapacityAllocationOperationsReducers,
  dragonbot: persistReducer({ key: 'dragonbot', storage: metadataStorage, whitelist: ['cacheChatHistory'] }, dragonbot),
  discoveryBot: persistReducer(
    { key: 'discoveryBot', storage: metadataStorage, whitelist: ['cacheDiscoveryBotChatHistory', 'threadId'] },
    discoveryBot,
  ),
  announcements,
  usersSettings,
  whiteboard,
  onboardingDemo: persistReducer(
    { key: 'onboardingDemo', storage: metadataStorage, whitelist: ['demoSplashPageShown'] },
    onboardingDemo,
  ),
  personasData,
  personasGrid,
  lifecyclesData,
  lifecyclesGrid,
  goalsCanvas,
  metricsCanvas,
  metricsDialog,
  timelineLightbox,
  fieldsLayout,
  aiReleaseNotes,
  metricsAllocation,
  pdlc,
  customerRequestMergedGrid,
  metadataOnDemand,
  goalsAllocation,
  portfolioSummaryGrouping,
  goalsGrid,
  userViewRecurringNotifications,
  okrDrawer,
  requestsGlobalSearch,
  requestsHistory,
});

const reducer = persistReducer(persistConfig, appReducer);

let isLoggingOut = false;

export default (state, action) => {
  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
  if (action.type === ON_USER_LOGOUT) {
    state = undefined;
    localStorage.clear();

    if (!isLoggingOut) {
      isLoggingOut = true;
      axios.get('/api/users/logout').finally(() => window.location.reload());
    }
  }

  if (action.type === SET_PAGE_ACTIVE_VIEW) {
    const { page, view } = action.payload;
    const updatedStateBasedOnActiveView = getUpdatedStoreStateFromActiveViewChange(state, page, view);

    if (updatedStateBasedOnActiveView) {
      return updatedStateBasedOnActiveView;
    }
  }

  return reducer(state, action);
};
