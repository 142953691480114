import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAllRoadmapVersions,
  getBaseCompareVersionSelected,
  getClonedFrom,
  getCompareVersionEditScenario,
  getSelectedRoadmapVersionsIdsToCompare,
  isRecentlyCloned,
} from 'store/roadmapVersions/selectors';
import { setBaseCompareVersionSelected, setSelectedRoadmapVersionsToCompare } from 'store/roadmapVersions';

const updateElementInArray = (array, position, newElement) => {
  const temp = [...array];

  temp.splice(position, 1, newElement);

  return temp;
};

/**
 * Handles the logic to know when a project was cloned or not to replace the selected version to the new cloned.
 * If we discard changes it goes back to the original scenario
 */
const useComparePageEditScenarioCreateNewScenario = () => {
  const dispatch = useDispatch();
  const selectedScenario = useSelector(getCompareVersionEditScenario);
  const isVersionCloned = useSelector(isRecentlyCloned);
  const clonedFrom = useSelector(getClonedFrom);
  const baseCompareVersionSelected = useSelector(getBaseCompareVersionSelected);
  const selectedRoadmapVersionsIds = useSelector(getSelectedRoadmapVersionsIdsToCompare);
  const scenarios = useSelector(getAllRoadmapVersions);
  const unsavedClonedScenarioId = useRef();

  useEffect(() => {
    const shouldReplaceEditedScenarioWithCloned = isVersionCloned && selectedScenario && !unsavedClonedScenarioId.current;

    if (shouldReplaceEditedScenarioWithCloned) {
      unsavedClonedScenarioId.current = selectedScenario?.id;
      if (
        baseCompareVersionSelected &&
        baseCompareVersionSelected === clonedFrom &&
        selectedScenario?.id !== baseCompareVersionSelected
      ) {
        dispatch(setBaseCompareVersionSelected(selectedScenario));
      }

      const indexOfPreviousSelectedScenario = selectedRoadmapVersionsIds.indexOf(String(clonedFrom));

      if (indexOfPreviousSelectedScenario !== -1) {
        const newIds = updateElementInArray(
          selectedRoadmapVersionsIds,
          indexOfPreviousSelectedScenario,
          String(selectedScenario?.id),
        );

        dispatch(setSelectedRoadmapVersionsToCompare(newIds));
      }
    }

    const shouldHandleSaveOrDiscardClonedScenario = !selectedScenario && clonedFrom && unsavedClonedScenarioId.current;

    if (shouldHandleSaveOrDiscardClonedScenario) {
      const previousScenario = scenarios.find(scenario => scenario?.id === clonedFrom);
      const clonedScenario = scenarios.find(scenario => scenario?.id === unsavedClonedScenarioId.current);

      if (clonedScenario?.committed) {
        unsavedClonedScenarioId.current = null;
        return;
      }

      if (baseCompareVersionSelected?.id === unsavedClonedScenarioId.current) {
        dispatch(setBaseCompareVersionSelected(previousScenario));
      } else {
        const indexOfSelectedScenario = selectedRoadmapVersionsIds.indexOf(String(unsavedClonedScenarioId.current));

        if (indexOfSelectedScenario !== -1) {
          const newIds = updateElementInArray(selectedRoadmapVersionsIds, indexOfSelectedScenario, String(previousScenario?.id));

          dispatch(setSelectedRoadmapVersionsToCompare(newIds));
        }
      }
      unsavedClonedScenarioId.current = null;
    }
  }, [isVersionCloned, clonedFrom, baseCompareVersionSelected, selectedRoadmapVersionsIds, selectedScenario, scenarios]);
};

export default useComparePageEditScenarioCreateNewScenario;
