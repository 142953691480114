import React from 'react';
import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default ({ title, children, ...props }) => (
  <CollapsePanel {...props}>
    <CollapsePanelSummary
      expandIcon={<ExpandMoreIcon />}
    >
      {title}
    </CollapsePanelSummary>
    <Details>
      {children}
    </Details>
  </CollapsePanel>
);


const CollapsePanel = styled(ExpansionPanel)`
  &&&& {
    border-radius: 2px;
    margin-top: 0;
    margin-bottom: 24px;
  }
`;

const CollapsePanelSummary = styled(ExpansionPanelSummary)`
  &&&& {
    > div:nth-child(1) {
      padding-left: 30px;
      line-height: 22px;
    }
    > div:nth-child(2) {
      left: 8px;
      right: auto;

      span{
        color: #333;
      }
    }
  }
`;

const Details = styled(ExpansionPanelDetails)`
  &&&& {
    position: relative;
    padding: 8px 20px 8px;
  }
`;
