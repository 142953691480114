import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAppGlobalConfig } from 'store/app';
import { AVAILABLE_VIEWS } from 'constants/routes';
import { DEFAULT_PAGES } from 'constants/defaultPages';

import usePermissions from 'hooks/permissions/usePermissions';

const exist = Boolean;

const useGetOrSaveLastPageView = (pathname, search = '', history) => {
  const dispatch = useDispatch();
  const { canView } = usePermissions();

  const lastPagesView = useSelector(state => state.app.lastPagesView || {});
  const lastGoalsView = useSelector(state => state.app.lastGoalsView);

  /**
   * This object is used to handle special cases where we need to save and retrieve a view/page
   * that does not belong to another page. For example, we can save the 'metrics' page as the
   * last viewed page of the 'goals' page. To define such exceptions, add a new entry to this object.
   * (key - parent page, lastView - last view for parent, redirect - child page/view)
   */
  const routingExceptions = {
    goals: {
      lastView: lastGoalsView,
      redirect: 'metrics',
    },
  };

  const pathExtracted = pathname.split('/');

  // pathname start with '/' (e.g. /ideas/board) so the first array position is empty.
  const parent = pathExtracted[1];
  const child = pathExtracted[2];

  if (!exist(parent) || !exist(DEFAULT_PAGES(canView)[parent])) return;

  const [parentState, setParentState] = useState(parent);
  const [childState, setChildState] = useState(child);

  const checkForExceptions = url => {
    const exception = Object.keys(routingExceptions).find(page => url.includes(page));
    const exceptionPage = exception && routingExceptions[exception];

    if (exceptionPage && exceptionPage.lastView === exceptionPage.redirect) {
      return `/${exceptionPage.redirect}`;
    }

    return url;
  };

  const determineSavedChild = (lastPagesView, parent) => {
    if (parent === 'dashboard') {
      return 'home';
    }

    return lastPagesView[parent] || DEFAULT_PAGES(canView)[parent];
  };

  const _buildChildUrl = someChild => {
    // support to pass ids (/path/:id) on the url
    const otherParams = pathExtracted.slice(3).join('/');

    return `/${parent}/${someChild}${otherParams ? `/${otherParams}` : ''}${search}`;
  };

  const _getView = () => {
    if (exist(child)) {
      // save child as last view from this parent and return it
      const myLastPagesView = {
        ...lastPagesView,
        [parent]: child,
      };

      // on dashboards page, save the last view as dashboard home
      if (parent === 'dashboard' && child === 'dashboards') myLastPagesView.dashboard = 'home';

      dispatch(setAppGlobalConfig({ lastPagesView: myLastPagesView }));

      setParentState(parent);
      setChildState(child);
      const newUrl = _buildChildUrl(child);

      history.push(newUrl);
    } else {
      // get last view from this parent and return it
      const savedChild = determineSavedChild(lastPagesView, parent);

      setParentState(parent);
      setChildState(savedChild);
      const newUrl = checkForExceptions(_buildChildUrl(savedChild));

      history.push(newUrl);
    }
  };

  useEffect(() => {
    _getView();
  }, [pathname]);

  return parentState && childState ? AVAILABLE_VIEWS[parentState][childState] : '';
};

export default useGetOrSaveLastPageView;
