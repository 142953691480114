import getSignupPathMatch from './getSignupPathMatch';

export default (path, search) => {
  // eslint-disable-next-line security/detect-unsafe-regex
  const match = getSignupPathMatch(path);

  let signupQuerystring = search;

  if (match && match[1]) {
    signupQuerystring = match[1] + signupQuerystring;
  }

  return signupQuerystring;
};
