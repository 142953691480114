import React, { useCallback, useState, useEffect } from 'react';

import { DOD_LOGIN_POPOP_WINDOW_NAME } from 'constants/integrations';
import CreateChildDragonAccess from './CreateChildDragonAccess';
import receiveMessageFactory from 'utils/receiveMessage';

const LOGIN_MESSAGE_TARGET = 'dragonboat-login';

const ConnectionSteps = props => {
  const { onClickBack, onAddChildAccess, addChildDragonAccess } = props;
  const [hasError, setHasError] = useState(false);
  let _receiveMessage;

  const receiveMessageCallback = childUrl => {
    _receiveMessage && window.removeEventListener('message', _receiveMessage);

    return data => {
      addChildDragonAccess({
        authKey: data?.key,
        childUrl,
      })
        .then(() => onAddChildAccess())
        .catch(() => {
          setHasError(true);
        });
    };
  };

  const handleClickNext = useCallback(async childUrl => {
    setHasError(false);

    _receiveMessage = receiveMessageFactory(LOGIN_MESSAGE_TARGET, receiveMessageCallback(childUrl));
    window.addEventListener('message', _receiveMessage, false);
    window.open(`${childUrl}/login`, DOD_LOGIN_POPOP_WINDOW_NAME, 'height=760,width=600');
  }, []);

  // on unmount remove registered listener
  useEffect(() => {
    return () => {
      _receiveMessage && window.removeEventListener('message', _receiveMessage);
    };
  }, []);

  return (
    <React.Fragment>
      <CreateChildDragonAccess onClickNext={handleClickNext} onClickBack={onClickBack} hasError={hasError} />
    </React.Fragment>
  );
};

export default ConnectionSteps;
