import axios from 'axios';
import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';

import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';

const DeleteOrganizaton = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { isSuperAdmin } = user;

  const closeDialog = () => setIsOpen(false);
  const handleDeleteOrganization = () => setIsOpen(true);

  const updateProjectsHierarchyFromExternalKey = async () => {
    closeDialog();
    axios.put('/api/projects/superlogin-tools/hierarchy-from-external-keys').then(() => {
      toast('Projects Hierarchy Update started. Please wait a few minutes before validating the data');
    });
  };

  return (
    <Wrapper>
      <Button color="primary" onClick={handleDeleteOrganization}>
        Update Hierarchy From External Key
      </Button>
      <ConfirmDialog
        id="delete-organization-dialog"
        isOpen={isOpen}
        onCancel={closeDialog}
        onConfirm={updateProjectsHierarchyFromExternalKey}
        text="This use import External Keys to update the parent of all projects with no parent assigned. Are you sure you want to continue?" // eslint-disable-line max-len
        title="Update Hierarchy"
        disableConfirm={!isSuperAdmin}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export default DeleteOrganizaton;
