export const JIRA_TYPE = 0;
export const GITHUB_TYPE = 1;
export const CLUBHOUSE_TYPE = 2;
export const PIVOTAL_TYPE = 3;
export const OKTA_TYPE = 4;
export const ASANA_TYPE = 5;
export const MIRO_TYPE = 9;
export const SALESFORCE_TYPE = 12;
export const RALLY_TYPE = 14;
export const MICROSOFT_TEAMS_TYPE = 15;

export const integrationsTypesMapper = {
  JIRA: JIRA_TYPE,
  github: GITHUB_TYPE,
  clubhouse: CLUBHOUSE_TYPE,
  pivotal: PIVOTAL_TYPE,
  okta: OKTA_TYPE,
  asana: ASANA_TYPE,
  miro: MIRO_TYPE,
  salesforce: SALESFORCE_TYPE,
  rally: RALLY_TYPE,
  microsoftTeams: MICROSOFT_TEAMS_TYPE,
};
