import { OBJECTIVE_LEVEL, KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL } from 'constants/objectives';

// To set the OKR initial data in the lightbox we need to pass in the titles for all OKR levels
export const getInitialOkrDataForLightbox = (okr, objectives, keyResults) => {
  let objectiveTitle = null;
  let keyResult1Title = null;
  let keyResult2Title = null;

  if (okr?.level === OBJECTIVE_LEVEL) {
    objectiveTitle = okr?.title;
  }

  if (okr?.level === KEY_RESULT_1_LEVEL) {
    const matchingObjective = objectives?.[okr?.objective_id];

    objectiveTitle = matchingObjective?.title;
    keyResult1Title = okr?.title;
  }

  if (okr?.level === KEY_RESULT_2_LEVEL) {
    const matchingObjective = objectives?.[okr?.objective_id];
    const matchingKeyResult1 = keyResults?.[okr?.parent_id];

    objectiveTitle = matchingObjective?.title;
    keyResult1Title = matchingKeyResult1?.title;
    keyResult2Title = okr?.title;
  }

  return {
    objectiveTitle,
    keyResult1Title,
    keyResult2Title,
  };
};
