/**
 * @function customFieldDropdownComparator
 *
 * Comparator for custom field dropdown with sorted list of options
 *
 * @param  {String} a
 * @param  {String} b
 * @return {Number}
 */
const customFieldDropdownComparator = sortedOptions => (a, b, _1, _2, isInverted) => {
  const optionAIndex = sortedOptions.findIndex(opt => opt.title === a);
  const optionBIndex = sortedOptions.findIndex(opt => opt.title === b);

  // If a is not found, move it to the end
  if (optionAIndex === -1) {
    return isInverted ? -1 : 1;
  }
  // If b is not found, move it to the end
  if (optionBIndex === -1) {
    return isInverted ? 1 : -1;
  }
  return optionAIndex - optionBIndex;
};

export default customFieldDropdownComparator;
