import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { defaultTo, propEq, pipe, not, isEmpty } from 'ramda';

import {
  OBJECTIVE_TYPE_QUERY_PARAM,
  OPEN_OBJECTIVE_QUERY_PARAM,
  KEY_RESULT_1_LEVEL,
  KEY_RESULT_2_LEVEL,
} from 'constants/objectives';

import { OBJECT_KEY_RESULT, OBJECT_OBJECTIVE } from 'store/objectives/types';
import { removeUnsavedObjectivesAndKeyResults, openObjectiveDrawer as openObjectiveDrawerAction } from 'store/objectives/actions';

import { getObjectiveType } from 'utils/objectives';

import useObjectives from './useObjectives';
import useKeyResults from './useKeyResults';

import { METADATA_LEVELS } from 'constants/common';
import getActiveOKRWithHierarchy from 'utils/getActiveOKRWithHierarchy';

const defaultToEmptyObject = defaultTo({});
const isTypeObjective = pipe(defaultToEmptyObject, propEq('type', OBJECT_OBJECTIVE));
const START_DATE = 'start_date';
const END_DATE = 'end_date';

const convertDateToNullIfEmpty = (data, dateKey) => {
  if (dateKey in data && isEmpty(data[dateKey])) {
    data[dateKey] = null;
  }
};

const useObjectivesSettings = ({
  hasKeyResults,
  hasKeyResults2,
  hasCorpLevel = false,
  hideArchivedItems,
  isGoalMode = false,
}) => {
  const allowedLevel = useMemo(() => (hasKeyResults2 ? KEY_RESULT_2_LEVEL : KEY_RESULT_1_LEVEL), [hasKeyResults2]);

  const dispatch = useDispatch();

  const {
    objectives,

    createObjective,
    addObjectiveWithoutSave: addObjectiveWithoutSaveBoundAction,
    updateObjectiveById,
    fetchObjectives,
    mergeObjectives,
    requestRemoveObjectiveById,
    bulkDeleteObjectives,
    switchObjectivesRowOrder,
    moveObjectiveToObjective,
  } = useObjectives(isGoalMode);

  const {
    keyResults,

    createKeyResult,
    addKeyResultWithoutSave: addKeyResultWithoutSaveBoundAction,
    updateKeyResultById,
    mergeKeyResults,
    deleteKeyResultById,
    bulkDeleteKeyResults,
    switchKeyResultsRowOrder,
    moveKeyResultToObjective,
  } = useKeyResults(isGoalMode);

  const childrenSettingKeys = useMemo(() => {
    const keys = [];

    if (hasCorpLevel) {
      keys.push('objective');
    }

    if (hasKeyResults) {
      keys.push('keyResult1');

      if (hasKeyResults2) {
        keys.push('keyResult2');
      }
    }

    return keys;
  }, [hasKeyResults, hasKeyResults2, hasCorpLevel]);

  const objectivesAndKeyResultsWithHierarchy = useMemo(() => {
    return getActiveOKRWithHierarchy(objectives, hasKeyResults, hasKeyResults2, keyResults, hideArchivedItems);
  }, [hideArchivedItems, keyResults, objectives, hasKeyResults, hasKeyResults2]);

  const create = useCallback(
    data => {
      if (data.type === OBJECT_KEY_RESULT) {
        return createKeyResult(data);
      }

      return createObjective(data);
    },
    [createObjective, createKeyResult],
  );

  const addKeyResultWithoutSave = useCallback(
    parentData => {
      if (parentData.type === OBJECT_KEY_RESULT) {
        return addKeyResultWithoutSaveBoundAction(parentData, true, 1);
      }

      return addKeyResultWithoutSaveBoundAction(parentData);
    },
    [addKeyResultWithoutSaveBoundAction],
  );

  const addObjectiveWithoutSave = useCallback(
    parentData => {
      if (not(hasCorpLevel) || isTypeObjective(parentData)) {
        return addObjectiveWithoutSaveBoundAction(parentData, METADATA_LEVELS.LEVEL_1);
      }

      return addObjectiveWithoutSaveBoundAction(parentData, METADATA_LEVELS.LEVEL_CORP);
    },
    [addObjectiveWithoutSaveBoundAction, hasCorpLevel],
  );

  const updateById = useCallback(
    (id, data) => {
      // If we want to remove the date we cannot send the empty string but 'null' value
      convertDateToNullIfEmpty(data, START_DATE);
      convertDateToNullIfEmpty(data, END_DATE);

      if (data.type === OBJECT_KEY_RESULT) {
        const keyResult = keyResults.find(kr => kr.id === id);

        return updateKeyResultById(id, {
          ...data,
          level: keyResult?.level,
        });
      }

      return updateObjectiveById(id, data);
    },
    [keyResults, updateKeyResultById, updateObjectiveById],
  );

  const bulkUpdate = useCallback(
    data => {
      const objectiveOrKeyResult = objectivesAndKeyResultsWithHierarchy.find(r => r.uniqueId === data.uniqueId);

      if (objectiveOrKeyResult) {
        return updateById(data.id, { ...objectiveOrKeyResult, ...data });
      }

      return Promise.reject();
    },
    [objectivesAndKeyResultsWithHierarchy, updateById],
  );

  const deleteById = useCallback(
    (objectToDeleteId, objectToDelete) => {
      return objectToDelete.type === OBJECT_KEY_RESULT
        ? deleteKeyResultById(objectToDeleteId)
        : requestRemoveObjectiveById(objectToDeleteId);
    },
    [deleteKeyResultById, requestRemoveObjectiveById],
  );

  const bulkDelete = useCallback(
    (ids, roomId, objectivesOrKeyResults) => {
      if (objectivesOrKeyResults[0]?.type === OBJECT_OBJECTIVE) {
        return bulkDeleteObjectives(ids, roomId);
      }

      return bulkDeleteKeyResults(ids, roomId);
    },
    [bulkDeleteObjectives, bulkDeleteKeyResults],
  );

  const merge = useCallback(
    (ids, idToPersist, selectedItems) => {
      if (selectedItems.filter(obj => obj.type === OBJECT_KEY_RESULT).length > 0) {
        return mergeKeyResults(ids, idToPersist);
      }

      return mergeObjectives(ids, idToPersist);
    },
    [mergeKeyResults, mergeObjectives],
  );

  const removeUnsaved = useCallback(() => {
    return dispatch(removeUnsavedObjectivesAndKeyResults());
  }, [dispatch]);

  const openObjectiveDrawer = useCallback(
    objective => {
      if (!objective) {
        return;
      }

      const objectiveType = getObjectiveType(objective);

      dispatch(openObjectiveDrawerAction(objective.id, objectiveType));

      const url = new URL(window.location.href);

      const queryParams = queryString.parse(window.location.search);

      queryParams[OPEN_OBJECTIVE_QUERY_PARAM] = objective.id;

      queryParams[OBJECTIVE_TYPE_QUERY_PARAM] = objectiveType;

      url.search = queryString.stringify(queryParams);

      window.history.pushState('', '', url.toString());
    },
    [dispatch],
  );

  return {
    objectives,
    keyResults,
    objectivesAndKeyResultsWithHierarchy,
    allowedLevel,
    childrenSettingKeys,

    create,
    addObjectiveWithoutSave,
    addKeyResultWithoutSave,
    updateById,
    bulkUpdate,
    deleteById,
    bulkDelete,
    merge,
    removeUnsaved,

    switchObjectivesRowOrder,
    switchKeyResultsRowOrder,
    moveKeyResultToObjective,
    moveObjectiveToObjective,

    fetchObjectives,
    openObjectiveDrawer,
  };
};

export default useObjectivesSettings;
