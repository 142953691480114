import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Xarrow, { useXarrow } from 'react-xarrows';
import theme from 'design-system/theme';
import { computeConnectionPoints } from 'utils/dependencyLines';
import MovablePoint from './ProjectDependenciesMovablePoint';
import ProjectDependencyLine from './ProjectDependencyLine';

import { computeGroupId } from 'routes/Dashboard/Summary/helpers';

const CONTROL_POINTS_OFFSET = 25;

const makeIds = projectId => {
  const base = `project-${projectId}-dependency-linker`;

  return {
    linker: base,
    connection: {
      left: `${base}__connection-point__left`,
      right: `${base}__connection-point__right`,
    },
    movable: {
      left: `${base}__movable-point__left`,
      right: `${base}__movable-point__right`,
    },
    children: `${base}__children`,
  };
};

const calculateArrowColor = (projectDate, dependencyDate) => {
  if (!projectDate || !dependencyDate) {
    return theme.palette.dependencyArrows.unknown;
  }

  return new Date(projectDate) > new Date(dependencyDate)
    ? theme.palette.dependencyArrows.backward
    : theme.palette.dependencyArrows.forward;
};

const getComputedColId = p => p.metadata.col?.id ?? null;

const checkIfLinksToTheSameSide = connectionPoints => connectionPoints.target === connectionPoints.source;

const calculateControlPointsOffset = (isOnTheSameColumn, connectionPoints) => {
  if (!isOnTheSameColumn || !checkIfLinksToTheSameSide(connectionPoints)) {
    return 0;
  }

  return connectionPoints.source === 'left' ? -1 * CONTROL_POINTS_OFFSET : CONTROL_POINTS_OFFSET;
};

const keyToWordMapper = {
  left: 'start',
  right: 'end',
};

const makeDescription = (sourceTitle, sourceConnectionKey, targetTitle, targetConnectionKey) => {
  const sourceConnectionWord = keyToWordMapper[sourceConnectionKey];

  const targetConnectionWord = keyToWordMapper[targetConnectionKey];

  return (
    <div>
      Connects {sourceConnectionWord} of <StrongBold>{sourceTitle}</StrongBold> to {targetConnectionWord} of{' '}
      <StrongBold>{targetTitle}</StrongBold>
    </div>
  );
};

const ProjectDependenciesLinker = ({
  children,
  projectInfo,
  allProjectsInfo,
  disabled,
  isReadOnly,
  draggingProjectId,
  isGrouping,
  expandedGroups = [],
  hideItemsWithoutDependencies, // Needed to trigger the arrows' re-render when projects are hidden/revealed
}) => {
  const updateXarrows = useXarrow();

  const getProject = useCallback(id => allProjectsInfo.find(p => p.data.id === id), [allProjectsInfo]);

  const projectIsVisible = useCallback(
    dependentProject => {
      if (!isGrouping) {
        return true;
      }

      const rowId = dependentProject.metadata.row?.id ?? null;

      const colId = dependentProject.metadata.col?.id ?? null;

      const groupId = dependentProject.metadata.group?.id ?? null;

      const computedGroupId = computeGroupId(rowId, colId, groupId);

      return expandedGroups.includes(computedGroupId);
    },
    [isGrouping, expandedGroups],
  );

  const projectId = projectInfo.data.id;

  const ids = makeIds(projectId);

  const shouldDisplayMovablePoints = useMemo(() => !isReadOnly && !disabled, [isReadOnly, disabled]);

  const shouldRenderLines = useMemo(() => !(disabled || draggingProjectId === projectInfo.data.id), [
    disabled,
    draggingProjectId,
    projectInfo.data.id,
  ]);

  const createDrawArrowHandler = leftOrRight => () => (
    <Xarrow
      start={ids.connection[leftOrRight]}
      end={ids.movable[leftOrRight]}
      color={theme.palette.dependencyArrows.unknown}
      strokeWidth={1}
      startAnchor={[leftOrRight]}
    />
  );

  useEffect(() => {
    updateXarrows();
  }, [hideItemsWithoutDependencies]);

  return (
    <>
      <Wrapper id={ids.linker} displayMovablePoints={shouldDisplayMovablePoints}>
        <MovablePoint
          id={ids.movable.left}
          projectInfo={projectInfo}
          show={shouldDisplayMovablePoints}
          drawArrow={createDrawArrowHandler('left')}
        />
        <div id={ids.connection.left} />
        <ChildrenWrapper id={ids.children}>{children}</ChildrenWrapper>
        <div id={ids.connection.right} />
        <MovablePoint
          id={ids.movable.right}
          projectInfo={projectInfo}
          show={shouldDisplayMovablePoints}
          drawArrow={createDrawArrowHandler('right')}
          isRight
        />
      </Wrapper>
      {shouldRenderLines &&
        projectInfo.data.projectDependencies?.map(pd => {
          const dependentProject = getProject(pd.id);

          if (!dependentProject || pd.id === draggingProjectId || !projectIsVisible(dependentProject)) {
            return null;
          }

          const targetIds = makeIds(pd.id);

          const arrowColor = calculateArrowColor(
            projectInfo.data.estimated_start_date,
            dependentProject.data.estimated_start_date,
          );

          const isOnTheSameColumn = getComputedColId(projectInfo) === getComputedColId(dependentProject);

          const connectionPoints = computeConnectionPoints(pd.info.type);

          const cpxOffset = calculateControlPointsOffset(isOnTheSameColumn, connectionPoints);

          const shouldRotateArrowTip =
            isOnTheSameColumn && checkIfLinksToTheSameSide(connectionPoints) && connectionPoints.target === 'left';

          const startId = ids.connection[connectionPoints.source];

          const endId = targetIds.connection[connectionPoints.target];

          const description = makeDescription(
            projectInfo.data.title,
            connectionPoints.source,
            dependentProject.data.title,
            connectionPoints.target,
          );

          const X = () => (
            <ProjectDependencyLine
              rotate={shouldRotateArrowTip}
              startId={startId}
              endId={endId}
              color={arrowColor}
              cpxOffset={cpxOffset}
              description={description}
              sourceProjectId={projectInfo.data.id}
              targetProjectId={dependentProject.data.id}
            />
          );

          return <X key={`${startId}-${endId}-${pd.info.type}`} />;
        })}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ displayMovablePoints }) => (displayMovablePoints ? '1px' : '0')};
`;

const ChildrenWrapper = styled.div`
  flex: 1;
`;

const StrongBold = styled.strong`
  font-weight: bold;
`;

export default ProjectDependenciesLinker;
