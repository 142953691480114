import getSystemFieldName from 'utils/getSystemFieldName';
import { BET, INITIATIVE, IDEA } from 'constants/projects';
import { sumNumericFields } from 'design-system/molecules/AgGridReact-New/aggFuncs';

import customFieldColumnFactory from './customFieldColumnFactory';
import {
  generateCustomFields,
  generateAutocompleteFields,
  generateNumericFields,
  generateTextFields,
} from './columnDefsGenerators';

const ROW_HEIGHT_DEFAULT = 40;
const MAX_NUM_ROWS = 10;
const TABLE_HEADER_HEIGHT = 125;

const getDataPath = data => data.path || [];

const getRowId = ({ data }) => data?.id;

const tableHeight = MAX_NUM_ROWS * ROW_HEIGHT_DEFAULT;

const getGridHeight = () => `${tableHeight + TABLE_HEADER_HEIGHT}px`;

const generateTitleHeaderName = (hasBet, renderTitleLabel) => {
  if (renderTitleLabel) {
    return renderTitleLabel();
  }

  return hasBet
    ? [getSystemFieldName(BET), getSystemFieldName(INITIATIVE), getSystemFieldName(IDEA)].join(' > ')
    : [getSystemFieldName(INITIATIVE), getSystemFieldName(IDEA)].join(' > ');
};

const formatIssueType = row => (!row?.integration ? '' : row.integration.issueType);

const checkLinkButtonIsVisible = ({ data }) => data?.okrType || !data?.group;

export {
  customFieldColumnFactory,
  getDataPath,
  getGridHeight,
  getRowId,
  generateTitleHeaderName,
  sumNumericFields,
  formatIssueType,
  generateCustomFields,
  generateAutocompleteFields,
  generateNumericFields,
  generateTextFields,
  checkLinkButtonIsVisible,
};
