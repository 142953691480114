import { pluck } from 'ramda';

import { validateHtmlField } from 'features/FieldsLayout/helpers';
import useLayoutRequiredFields from 'features/FieldsLayout/hooks/useLayoutRequiredFields';
import { FIELDS_LAYOUT_TYPES } from 'features/FieldsLayout/constants';

import useRequestsDetailFieldsLayout from './useRequestsDetailFieldsLayout';
import { FORM_FIELDS_MAPPER, REQUEST_FORM_FIELDS } from '../constants';

const validateRoadmapField = item => Boolean(item?.id);

const validationByField = {
  [REQUEST_FORM_FIELDS.details]: validateHtmlField,
  [REQUEST_FORM_FIELDS.links]: validateHtmlField,
  [REQUEST_FORM_FIELDS.roadmap]: validateRoadmapField,
};

const getFormFieldKey = field => FORM_FIELDS_MAPPER[field] || field;

const extractIds = pluck('id');

const useRequestsDetailRequiredFields = ({ formData, onSave }) => {
  const { requiredFields } = useRequestsDetailFieldsLayout();

  const { fieldValidation, onSaveWithValidation } = useLayoutRequiredFields({
    type: FIELDS_LAYOUT_TYPES.requests,
    formData,
    onSave,
    requiredFieldsKeys: extractIds(requiredFields),
    validationByField,
    getFormFieldKey,
  });

  return {
    fieldValidation,

    onSaveWithValidation,
  };
};

export default useRequestsDetailRequiredFields;
