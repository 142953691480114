import React from 'react';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import { Switch, FormControlLabel } from '@material-ui/core';

const SwitchWithLabel = ({ id, label, formProps, switchProps, labelProps, ...props }) => (
  <FormControlLabel
    id={id}
    data-testid={id}
    control={<Switch {...switchProps} />}
    label={<TextDeprecated {...labelProps}>{label}</TextDeprecated>}
    {...props}
    {...formProps}
  />
);

export default SwitchWithLabel;
