import React, { useMemo } from 'react';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import BarChart from 'components/ReactChart/BarChart';
import ChartWrapper from 'components/ReactChart/utils/ChartWrapper';
import ConfigurableChartWrapper from '../components/ConfigurableChartWrapper';

import {
  DASHBOARD_CHART_MAX_HEIGHT,
  DASHBOARD_CHART_MIN_HEIGHT,
  STACKED_CHART_MIN_HEIGHT,
  STACKED_CHART_ROW_HEIGHT,
} from 'constants/charts';

import useConfigurableChartDataForBarChart from '../hooks/useConfigurableChartDataForBarChart';
import { getBarChartOptions } from '../helpers';
import { DASHBOARDS_PAGE } from 'constants/filters';
import TimelineLightbox from 'features/TimelineLightbox';
import useChartTimelineLightbox from 'routes/Dashboard/Dashboards/hooks/useChartTimelineLightbox';
import ChartLegend from '../../ChartLegend';
import useBarStackedCharDatalabelsConfig from 'hooks/charts/useBarStackedCharDatalabelsConfig';

const calcChartHeight = rowsLength => (rowsLength || 0) * STACKED_CHART_ROW_HEIGHT;

const calcMaxItemsVisible = maxHeight => maxHeight && Math.round(maxHeight / STACKED_CHART_ROW_HEIGHT);

export default ({
  minHeight = DASHBOARD_CHART_MIN_HEIGHT,
  maxHeight = DASHBOARD_CHART_MAX_HEIGHT,
  groupBy,
  stackBy,
  horizontal,
  label,
  actionButtons,
}) => {
  const maxItems = useMemo(() => calcMaxItemsVisible(maxHeight), [maxHeight]);

  const { data, title, subtitle } = useConfigurableChartDataForBarChart({ groupBy, stackBy, maxItems, label });

  const height = useMemo(() => calcChartHeight(data?.labels?.length), [data?.labels?.length]);

  const isStackedChart = Boolean(stackBy);

  const {
    handleOnChartClick,
    registerOpenTimelineCallback,
    timelineDisplayLayer,
    timelineProjectsGrouping,
    groupByEntity,
    timelineLightboxOpenItems,
  } = useChartTimelineLightbox(data, groupBy);

  const datalabelsConfig = useBarStackedCharDatalabelsConfig(data.datasets);

  const pluginConfigs = {
    datalabels: datalabelsConfig,
  };

  const plugins = [ChartDataLabels];

  const chartOptions = getBarChartOptions(isStackedChart, handleOnChartClick, horizontal, pluginConfigs);

  return (
    <ConfigurableChartWrapper title={title} subtitle={subtitle} actionButtons={actionButtons}>
      <ChartWrapper
        minHeight={minHeight}
        height={height > STACKED_CHART_MIN_HEIGHT ? height : STACKED_CHART_MIN_HEIGHT}
        maxHeight={maxHeight}
      >
        <BarChart horizontal={horizontal} data={data} plugins={plugins} options={chartOptions} />
        <TimelineLightbox
          onOpen={registerOpenTimelineCallback}
          groupedBy={timelineProjectsGrouping}
          groupBySelection={groupByEntity}
          pageId={DASHBOARDS_PAGE}
          openItems={timelineLightboxOpenItems}
          entityGroup={groupByEntity}
          lazyLoadProjects
          displayLayer={timelineDisplayLayer}
        />
      </ChartWrapper>
      <ChartLegend legends={data?.datasets} />
    </ConfigurableChartWrapper>
  );
};
