import { materialColors } from 'design-system/themes/default';

export const NICE_TO_HAVE = '0';
export const SHOULD_HAVE = '1';
export const MUST_HAVE = '2';
export const URGENT = '3';

export default {
  [NICE_TO_HAVE]: { label: 'Nice to have', color: materialColors.silver, value: NICE_TO_HAVE },
  [SHOULD_HAVE]: { label: 'Should have', color: materialColors.azure, value: SHOULD_HAVE },
  [MUST_HAVE]: { label: 'Must have', color: materialColors.mySin, value: MUST_HAVE },
  [URGENT]: { label: 'Urgent', color: materialColors.red, value: URGENT },
};
