import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import isNaN from 'lodash/isNaN';

import IconButton from '@material-ui/core/IconButton';

import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';
import renderFormatedNumberText from 'design-system/utils/renderFormattedNumberText';

const MetricNameCellRenderer = params => {
  const onActionClick = (e, onClick) => {
    if (!onClick) return;
    e.preventDefault();
    e.stopPropagation();

    onClick(params.data, e);
  };

  const defaultFormatter = value => {
    if (value && !isNaN(+value)) {
      return (
        <NumberFormat value={value} renderText={renderFormatedNumberText} displayType="text" thousandSeparator decimalScale={2} />
      );
    }

    return value;
  };

  return (
    <Wrapper hasLink={!!params.colDef?.actions?.length}>
      <Text withEllipsis>{defaultFormatter(params.value)}</Text>
      <ActionsContainer>
        {params.colDef?.actions?.map(action => (
          <StyledIconButton onClick={e => onActionClick(e, action.onClick)}>{action.icon}</StyledIconButton>
        ))}
      </ActionsContainer>
    </Wrapper>
  );
};

export default MetricNameCellRenderer;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  ${({ hasLink }) =>
    hasLink &&
    `
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  `}
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(0.5)}px;
`;

const StyledIconButton = styled(IconButton)`
  &&&& {
    position: relative;
    visibility: hidden;
    padding: ${spacing(0.5)}px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;
