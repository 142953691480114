import { useMemo } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';

import { materialColors } from 'design-system/themes/default';

import {
  nameColumnDef,
  updatedColumnDef,
  updatedByColumnDef,
  defaultColumnDefCommonProps,
} from 'design-system/molecules/AgGridReact-New/columns';
import { ActionsCellRenderer, TextLinkCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { ACTIONS_FIELD, ACTIONS_WIDTH } from 'features/MetricsDialog/components/MetricValueTable/helpers/constants';

const codeColumn = {
  ...nameColumnDef,
  field: 'code',
  headerName: 'Code',
  suppressMovable: true,
  editable: true,
  width: 200,
};

const updatedColumn = {
  ...updatedColumnDef,
  field: 'updatedAt',
  headerName: 'Updated At',
  suppressMovable: true,
  width: 200,
};

const updatedByColumn = {
  ...updatedByColumnDef,
  suppressMovable: true,
  width: 120,
};

const defaultColDef = {
  ...defaultColumnDefCommonProps,
  cellClass: params => (params.colDef.autoHeight ? 'ag-react-container--expandable' : 'ag-react-editable-container--expandable'),
  sortable: true,
  resizable: true,
  filter: true,
};

const useMultiIntegrationsListGridColumns = (
  openRowBaseUrlById,
  onSelectIntegration,
  userCanViewOrgIntegration,
  userCanUpdateOrgIntegration,
) => {
  const urlColumn = useMemo(
    () => ({
      field: 'baseUrl',
      headerName: 'URL',
      cellClass: 'cell-link',
      editable: false,
      cellRenderer: TextLinkCellRenderer,
      cellRendererParams: {
        handleClick: openRowBaseUrlById,
      },
      suppressMovable: true,
      width: 400,
    }),
    [openRowBaseUrlById],
  );

  const settingsActionColumn = useMemo(
    () => ({
      field: ACTIONS_FIELD,
      headerName: '',
      editable: false,
      suppressMovable: true,
      filter: false,
      resizable: false,
      cellRenderer: ActionsCellRenderer,
      headerClass: 'centered-header',
      cellRendererParams: {
        actions: [
          {
            icon: SettingsIcon,
            color: materialColors.darkerGray,
            getVisibility: () => true,
            onClick: onSelectIntegration,
            disabled: () => !userCanViewOrgIntegration && !userCanUpdateOrgIntegration,
          },
        ],
      },
      width: ACTIONS_WIDTH,
      suppressMenu: true,
    }),
    [onSelectIntegration, userCanViewOrgIntegration, userCanUpdateOrgIntegration],
  );

  const columnDefs = useMemo(
    () => [urlColumn, codeColumn, updatedColumn, updatedByColumn, settingsActionColumn],
    [urlColumn, settingsActionColumn],
  );

  return {
    columnDefs,
    defaultColDef,
  };
};

export default useMultiIntegrationsListGridColumns;
