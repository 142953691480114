import isPlainObject from 'lodash/isPlainObject';
import pick from 'lodash/pick';
import styled from 'styled-components';
import {
  defaultTo,
  equals,
  has,
  head,
  identity,
  ifElse,
  isEmpty,
  isNil,
  map,
  not,
  of,
  pipe,
  prop,
  propEq,
  reject,
  values,
} from 'ramda';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { snapshotActions } from 'store/snapshot';
import { projectActions } from 'store/projects';
import {
  objectiveActions,
  OBJECT_OBJECTIVE_CORP,
  OBJECT_OBJECTIVE,
  OBJECT_KEY_RESULT,
  OBJECT_KEY_RESULT_2,
} from 'store/objectives';
import { roadmapActions } from 'store/roadmaps';
import { themeActions } from 'store/themes';
import { timeframeActions } from 'store/timeframes';
import { categoryActions } from 'store/categories';
import { fetchTags } from 'store/tags';
import { saveGridConfig, saveGridState as saveGridStateAction } from 'store/grids';
import { getGridConfigValue, getGridState } from 'store/grids/selectors';
import { fetchCustomers } from 'store/customers';
import { userActions } from 'store/users';
import { phaseActions } from 'store/phases';
import { getObjectives, selectKeyResults1, selectKeyResults2 } from 'store/objectives/selectors';
import { getCategories } from 'store/categories/selectors';
import { getThemes } from 'store/themes/selectors';
import {
  getHasAdvancedMetricReporting,
  getOrgHasJiraIntegrated,
  getOrganization,
  getHasMultipleMetrics,
} from 'store/organization/selectors';
import { getTimeframes } from 'store/timeframes/selectors';
import { getSkills } from 'store/skills/selectors';
import { getRoadmaps, getCorpRoadmaps, getProducts } from 'store/roadmaps/selectors';
import { getTags } from 'store/tags/selectors';
import { getCustomersFilteredByStatus } from 'store/customers/selectors';
import { getPhases } from 'store/phases/selectors';
import { getTeams } from 'store/teams/selectors';
import { getCurrentUser } from 'store/login/selectors';
import { openObjectiveDrawer, addKeyResultWithoutSave } from 'store/objectives/actions';
import { getPriorities } from 'store/priorities/selectors';
import { getMetrics } from 'store/metrics/selectors';
import { SNAPSHOT_PAGE } from 'constants/filters';
import Snapshot from './Snapshot';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import { getSnapshotTableProps, getSnapshotPageProps } from 'store/snapshot/selectors';

import useSubscribeNewData from 'hooks/useSubscribeNewData';
import useSystemFields from 'hooks/useSystemFields';
import useBookADemoLocker from 'hooks/useBookADemoLocker';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useLoadProjects from 'hooks/projects/useLoadProjects';
import useLoadSnapshotReport from 'hooks/reports/useLoadSnapshotReport';
import useUpdateMetadataFieldById from 'hooks/useUpdateMetadataByFieldId';
import useOpenMetricFromEntityWithMetrics from 'hooks/useOpenMetricFromEntityWithMetrics';
import useMultipleMetricsGridActions from 'hooks/objectives/useMultipleMetricsGridActions';

import Loading from 'src/design-system/atoms/Loading';

import { METADATA_LEVELS } from 'constants/common';
import { TREE_BRANCH_FIELDS, TREE_BRANCHES } from 'constants';
import {
  KEY_RESULT_1_LEVEL,
  OBJECTIVE_KEY,
  CORP_OBJECTIVE_KEY,
  OBJECTIVE_CORP_LEVEL,
  KEY_RESULT_1_KEY,
  KEY_RESULT_2_KEY,
} from 'constants/objectives';

import { getUserName } from 'utils';
import sortByRank from 'utils/sortByRank';
import { filterMetadataOptions, verifyMetadataEntities } from 'utils/metadataRoadmaps';
import useSingleMetricsForm from 'hooks/objectives/useSingleMetricsForm';

import { getOrderForEntities } from './helpers';
import usePageFilters from 'hooks/filters/usePageFilters';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const okrLevels = {
  OBJECT_OBJECTIVE_CORP: 'objectiveCorp',
  OBJECT_OBJECTIVE: 'objective',
  OBJECT_KEY_RESULT: 'keyResult1',
  OBJECT_KEY_RESULT_2: 'keyResult2',
};

const defaultToEmptyArray = defaultTo([]);
const extractPropFromTopElement = property =>
  pipe(
    defaultToEmptyArray,
    reject(isNil),
    ifElse(isEmpty, () => null, pipe(head, prop(property))),
  );
const objectiveEntityKeys = [CORP_OBJECTIVE_KEY, OBJECTIVE_KEY];

const okrEntityKeys = [...objectiveEntityKeys, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY];

const nodeIsKeyResult = pipe(prop('objective_id'), isNil, not);
const nodeIsKeyResult1 = propEq('level', KEY_RESULT_1_LEVEL);

const getMetricToUpdate = (cellData, selectedSnapshotField, organization) => {
  if (objectiveEntityKeys.includes(selectedSnapshotField) && nodeIsKeyResult(cellData)) {
    return nodeIsKeyResult1(cellData) ? okrLevels.OBJECT_KEY_RESULT : okrLevels.OBJECT_KEY_RESULT_2;
  }

  const isObjectiveLevelCorp =
    cellData?.level === OBJECTIVE_CORP_LEVEL &&
    selectedSnapshotField === CORP_OBJECTIVE_KEY &&
    cellData?.organization_id === organization.id;

  // in this case should allow Mommy dragon to update the objective Level
  if (isObjectiveLevelCorp) {
    return OBJECTIVE_KEY;
  }

  return cellData.group || selectedSnapshotField;
};

const NAME = 'name';
const ACTUAL_VALUE = 'actual_value';
const TARGET_VALUE = 'target_value';
const UNDEFINED_ID = 'null';
const UNDEFINED_TITLE = 'Undefined';

const isUndefinedEntity = entity => isNil(entity.id) && equals(UNDEFINED_TITLE, entity.title);

function mapStateToProps(state) {
  const currentUser = getCurrentUser(state);

  return {
    currentUser,
    timeframes: getStateDataForPage(state, getTimeframes, 'timeframes'),
    roadmapsCorp: getStateDataForPage(state, getCorpRoadmaps, 'roadmapsCorp'),
    roadmaps: getStateDataForPage(state, (_, __) => getRoadmaps(_, __, METADATA_LEVELS.LEVEL_1), 'roadmaps'),
    themes: getStateDataForPage(state, getThemes, 'themes'),
    categorys: getStateDataForPage(state, getCategories, 'categories'),
    phases: getStateDataForPage(state, getPhases, 'phases'),
    objectivesCorp: getStateDataForPage(state, (_, __) => getObjectives(_, __, METADATA_LEVELS.LEVEL_CORP), 'objectivesCorp'),
    objectives: getStateDataForPage(state, (_, __) => getObjectives(_, __, METADATA_LEVELS.LEVEL_1), 'objectives'),
    teams: getStateDataForPage(state, getTeams, 'teams'),
    skills: getStateDataForPage(state, getSkills, 'skills'),
    customers: getStateDataForPage(state, getCustomersFilteredByStatus, 'customers'),
    keyResult1s: getStateDataForPage(state, selectKeyResults1, 'keyResult1'),
    keyResult2s: getStateDataForPage(state, (state, showArchived) => selectKeyResults2(state, showArchived), 'keyResult2'),
    product1s: getStateDataForPage(state, getProducts, 'product1'),
    tags: getStateDataForPage(state, getTags, 'tags'),
    priorities: getPriorities(state),
    jiraIntegrated: getOrgHasJiraIntegrated(state),
    systemFields: state.organization.organization.system_fields_name,
    filterPageId: SNAPSHOT_PAGE,
    hasBet: state.organization.organization.has_bet,
    hasKeyResults: state.organization.organization.has_key_results,
    hasKeyResults2: state.organization.organization.has_key_results_2,
    hasMetadataRoadmaps: state.organization.organization.enable_metadata_roadmaps,
  };
}

const GRID_NAME = 'snapshot';

const withHooksHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [getSystemFieldName] = useSystemFields();
    const BookADemoLockerComponent = useBookADemoLocker(SNAPSHOT_PAGE, false, props.isDashboardModule);
    const { isDodActive, getDefaultRoadmapForMetadataItem } = useOrganizationsAccessControl();
    const { pageFilters, displayLayer } = usePageFilters(SNAPSHOT_PAGE);
    const { canView } = usePermissions();

    const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

    // only search projects to update header total items
    useLoadProjects(SNAPSHOT_PAGE, {
      withTasks: false,
      withEstimates: false,
      withIntegrations: false,
      withParents: false,
      withVotes: false,
    });

    if (BookADemoLockerComponent) return <BookADemoLockerComponent />;

    const { hasMetadataRoadmaps } = props;
    const snapshotTableProps = useSelector(getSnapshotTableProps);
    const snapshotPageProps = useSelector(getSnapshotPageProps);
    const gridState = useSelector(state => getGridState(state, SNAPSHOT_PAGE));
    const metrics = useSelector(getMetrics);

    const saveGridState = useCallback(
      (state, makesActiveViewDirty = true) => dispatch(saveGridStateAction(SNAPSHOT_PAGE, state, makesActiveViewDirty)),
      [dispatch],
    );

    const snapshotFields = useMemo(() => {
      const groupOptions = [
        ...(canView(PERMISSION_RESOURCES.objectiveCorp) ? ['objectiveCorp'] : []),
        'objective',
        'keyResult1',
        ...(props.hasKeyResults2 ? ['keyResult2'] : []),
        ...(canView(PERMISSION_RESOURCES.roadmapCorp) ? ['roadmapCorp'] : []),
        'roadmap',
        'product1',
        ...(canView(PERMISSION_RESOURCES.product2) ? ['product2'] : []),
        'theme',
        'category',
        'phase',
        'timeframe',
      ];

      return groupOptions.map(key => ({
        key,
        title: getSystemFieldName(key),
      }));
    }, [displayLayer]);

    const { snapshotTableVisibleFields, showSnapshotTable, showSnapshotTreeView, expandTableItems = false } = snapshotTableProps;

    const {
      selectedSnapshotField = snapshotFields[0].key,
      hideEmptyCards,
      hideCards,
      pageSize = 10,
      cardsPerRow,
      visibleCardElements,
    } = snapshotPageProps;

    const usableMetadataFieldName = useMemo(() => `${selectedSnapshotField}s`, [selectedSnapshotField]);

    const metadataProps = okrEntityKeys.includes(selectedSnapshotField)
      ? [props.objectivesCorp, props.objectives, props.keyResult1s, props.keyResult2s]
      : [props[usableMetadataFieldName]];

    const usableMetadata = useMemo(() => {
      const metadata = pick(props, [
        'categorys',
        'customers',
        'keyResult1s',
        'keyResult2s',
        'objectives',
        'objectivesCorp',
        'phases',
        'priorities',
        'product1s',
        'roadmaps',
        'roadmapsCorp',
        'skills',
        'tags',
        'teams',
        'themes',
        'timeframes',
      ]);

      return filterMetadataOptions(hasMetadataRoadmaps, [], metadata, pageFilters?.quickFilters, {
        isDodActive,
        getDefaultRoadmapForMetadataItem,
      });
    }, [
      props.categorys,
      props.customers,
      props.keyResult1s,
      props.keyResult2s,
      props.objectives,
      props.phases,
      props.priorities,
      props.product1s,
      props.roadmaps,
      props.skills,
      props.tags,
      props.teams,
      props.themes,
      props.timeframes,
      isDodActive,
      getDefaultRoadmapForMetadataItem,
    ]);

    // PASS HOOK AS A PROP / CONDITIONALLY CALL THE HOOK BASED ON IF IT'S GOAL MODE OR NOT
    const { isLoadingReportData, reportData, fetchReportData } = useLoadSnapshotReport(selectedSnapshotField);

    const { isMetricsDialogVisible, handleOpenMetricView } = useOpenMetricFromEntityWithMetrics();

    // If the user inline edits some record or the metadata was refreshed (likely from drawer)
    // we should avoid displaying the spinner
    const [usedInlineEditing, setUsedInlineEditing] = useState(false);
    const [metadataReportRefresh, setMetadataReportRefresh] = useState(false);

    useEffect(() => {
      if (!isLoadingReportData) {
        setUsedInlineEditing(false);
        setMetadataReportRefresh(false);
      }
    }, [isLoadingReportData]);

    useEffect(() => {
      if (
        !usedInlineEditing &&
        !isLoadingReportData &&
        !isMetricsDialogVisible &&
        okrEntityKeys.includes(selectedSnapshotField)
      ) {
        setMetadataReportRefresh(true);
        fetchReportData();
      }
    }, [...metadataProps]);

    const shouldDisplaySpinner = useMemo(
      () => isLoadingReportData && !usedInlineEditing && !metadataReportRefresh,
      [isLoadingReportData, metadataReportRefresh, usedInlineEditing],
    );

    const processEntitiesToObjectivesTable = (
      entities,
      entityLevel = OBJECT_OBJECTIVE_CORP,
      iterationLevel = 1,
      parentPath = [],
    ) => {
      let entitiesProcessed = [];

      // Add the undefined entity
      const entitiesWithNull = [...entities, ...(iterationLevel === 1 ? [{ id: null }] : [])];

      entitiesWithNull.forEach(e => {
        const path = [...parentPath, e.title || UNDEFINED_TITLE];

        let actualValue;
        let targetValue;

        const reportEntityData = allReportEntities.find(reportEntity => reportEntity.id === e.id) || {};

        const hasIdeas = reportEntityData.nProjects > 0;
        const isNewRow = not(has('id', e));

        const showOnTable = !hideEmptyCards || hasIdeas;

        const curMetrics = pipe(defaultToEmptyArray, reject(isNil))(e.metrics);

        if (curMetrics && curMetrics.length) {
          const equalsTopMetricId = pipe(head, prop('id'), equals)(curMetrics);

          curMetrics[0] = metrics.find(m => equalsTopMetricId(m.id));
        }

        if (showOnTable || isNewRow) {
          entitiesProcessed.push({
            ...reportEntityData,
            ...e,
            path,
            uniqueId: `${iterationLevel}_${e.id}`,
            type: entityLevel,
            group: e.group || okrLevels[entityLevel],
            ownerName: e.owner ? getUserName(e.owner) : '',
            actualValue,
            targetValue,
            metrics: curMetrics,
          });
        }

        if ((e.keyResults || e.children) && showOnTable && !isNewRow) {
          const thereIsNextLevel = values(okrLevels).length >= entityLevel + 1;

          if (thereIsNextLevel) {
            const nextEntityLevel = entityLevel + 1;
            const subEntities = processEntitiesToObjectivesTable(
              e.children && e.children.length ? e.children : e.keyResults,
              nextEntityLevel,
              iterationLevel + 1,
              path,
            );

            entitiesProcessed = [...entitiesProcessed, ...subEntities];
          }
        }
      });

      return entitiesProcessed.map(e => {
        return {
          ...e,
          id: isUndefinedEntity(e) ? UNDEFINED_ID : e.id,
          metric_name: extractPropFromTopElement(NAME)(e.metrics),
          metric_current: extractPropFromTopElement(ACTUAL_VALUE)(e.metrics),
          metric_target: extractPropFromTopElement(TARGET_VALUE)(e.metrics),
        };
      });
    };

    useSubscribeNewData(
      [
        'app',
        'projects',
        'phases',
        'roadmaps',
        'objectives',
        'users',
        'customFields',
        'timeframes',
        'priorities',
        'metrics',
        'keyResults',
      ],
      false,
    );

    const menuIsClosed = useSelector(state => state.app.leftMenuClosed);
    const rowHeight = useSelector(state => getGridConfigValue(state, GRID_NAME, 'rowHeight'));
    const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);
    const hasMultipleMetrics = useSelector(getHasMultipleMetrics);

    const organization = useSelector(getOrganization);
    const hasKeyResult2 = organization.has_key_results_2;

    const isFieldOKR1 = selectedSnapshotField === 'keyResult1';
    const isFieldOKR2 = hasKeyResult2 && selectedSnapshotField === 'keyResult2';
    const isOKRLastLevel = isFieldOKR2 || (!hasKeyResult2 && isFieldOKR1);

    const myShowSnapshotTreeView = useMemo(() => showSnapshotTreeView && !isOKRLastLevel, [showSnapshotTreeView, isOKRLastLevel]);

    const [treeData, setTreeData] = useState({
      roots: [],
      branches: [],
    });
    const [selectedTreeEntity, setSelectedTreeEntity] = useState(null);
    const [tableShowFieldsEl, setTableShowFieldsEl] = useState(null);

    useEffect(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const snapshotBy = urlParams.get('snapshotBy');

      props.updateState(
        {
          selectedSnapshotField: snapshotBy || selectedSnapshotField || snapshotFields[0].key,
        },
        false,
      );
    }, []);

    useEffect(() => {
      const snapshotFieldExists = snapshotFields.find(field => field.key === selectedSnapshotField);

      if (!snapshotFieldExists) {
        // When switching between display layers some fields are hidden
        // If one of those hidden fields is still selected fallback to the first field again

        props.updateState(
          {
            selectedSnapshotField: snapshotFields[0].key,
          },
          false,
        );
      }
    }, [selectedSnapshotField, displayLayer]);

    // Resets tree data when selected snapshot field changes
    useEffect(() => {
      setTreeData({
        roots: [],
        branches: [],
      });
      setSelectedTreeEntity(null);
    }, [selectedSnapshotField]);

    const initiatives = [];
    const bets = [];

    const { updateMetadataByFieldId } = useUpdateMetadataFieldById();
    const { addMetricToOkr } = useSingleMetricsForm();
    const { createNewMetric, updateOkrMetrics } = useMultipleMetricsGridActions();

    const updateMetricOnOkr = useCallback(
      (okr, originalValue, field, metadataType) => {
        const okrType = metadataType === okrLevels.OBJECT_OBJECTIVE ? OBJECT_OBJECTIVE : OBJECT_KEY_RESULT;

        if (hasMultipleMetrics) {
          return updateOkrMetrics(okr.id, originalValue, okrType, field);
        }

        const metric = metrics.find(m => m.name === field.metric_name);

        if (!metric) {
          return;
        }

        return addMetricToOkr(okr, okrType, metric.id);
      },
      [addMetricToOkr, hasMultipleMetrics, metrics, updateOkrMetrics],
    );

    const createMetricOption = useCallback(
      name => {
        return createNewMetric(name);
      },
      [createNewMetric],
    );

    const _onUpdateMetadataFieldById = useCallback(
      async (id, field, oldValue, cellData) => {
        const metricToUpdate = getMetricToUpdate(cellData.data, selectedSnapshotField, organization);
        const shouldUpdateMetric = Boolean(field.metric_name) || Boolean(field.metrics);

        setUsedInlineEditing(true);
        if (shouldUpdateMetric) {
          await updateMetricOnOkr(cellData?.data, oldValue, field, metricToUpdate);
          fetchReportData();

          return;
        }

        await updateMetadataByFieldId(metricToUpdate, id, field, oldValue, cellData);
        fetchReportData();
      },
      [updateMetadataByFieldId, selectedSnapshotField, organization, setUsedInlineEditing, updateMetricOnOkr, fetchReportData],
    );

    const _onUpdateGridConfig = (key, value) => dispatch(saveGridConfig(GRID_NAME, key, value));
    const _onUpdatePageSize = pageSize => dispatch(snapshotActions.updateState({ pageSize }));
    const groupedBy = snapshotFields.find(field => field.key === selectedSnapshotField) || 'roadmap';

    const getEntityMetrics = pipe(
      defaultToEmptyArray,
      ifElse(isPlainObject, of, identity),
      reject(isNil),
      map(m => metrics.find(formatted => formatted.id === m.id)),
    );

    const allReportEntities = useMemo(() => {
      return reportData.map(e => {
        const entity = {
          ...e,
          metrics: getEntityMetrics(e.metrics),
          uniqueId: String(e.id),
          ideasProgress: e.projectsProgress,
          group: selectedSnapshotField,
          metric_name: extractPropFromTopElement('name')(e.metrics),
          metric_current: extractPropFromTopElement('actual_value')(e.metrics),
          metric_target: extractPropFromTopElement('target_value')(e.metrics),
          ownerName: e.owner ? getUserName(e.owner) : '',
        };

        return entity;
      });
    }, [reportData, metrics, selectedSnapshotField]);

    const { objectivesCorp, objectives, keyResult1s, keyResult2s } = props;

    const okrEntities = useMemo(() => {
      let okrEntities = [];

      if (showSnapshotTreeView && values(okrLevels).includes(selectedSnapshotField)) {
        const mapSelectedFieldToAction = {
          objectiveCorp: () => processEntitiesToObjectivesTable(objectivesCorp, OBJECT_OBJECTIVE_CORP),
          objective: () => processEntitiesToObjectivesTable(objectives, Number(OBJECT_OBJECTIVE)),
          keyResult1: () => processEntitiesToObjectivesTable(keyResult1s, OBJECT_KEY_RESULT),
          keyResult2: () => processEntitiesToObjectivesTable(keyResult2s, OBJECT_KEY_RESULT_2),
        };

        okrEntities = mapSelectedFieldToAction[selectedSnapshotField]();
      }

      if (hasMetadataRoadmaps && usableMetadata) {
        okrEntities = verifyMetadataEntities(okrEntities, usableMetadata);
      }

      return okrEntities.sort(sortByRank);
    }, [
      selectedSnapshotField,
      showSnapshotTreeView,
      allReportEntities,
      hideEmptyCards,
      objectivesCorp,
      objectives,
      keyResult1s,
      keyResult2s,
    ]);

    const selectedUsableMetadata = useMemo(
      () => usableMetadata[usableMetadataFieldName],
      [usableMetadata, usableMetadataFieldName],
    );

    const entities = useMemo(() => {
      let entities = allReportEntities.filter(e => {
        if (e.metadataName && e.metadataName !== selectedSnapshotField) {
          return false;
        }

        const hasIdeas = e.nProjects > 0;

        return !hideEmptyCards || hasIdeas;
      });

      if (Object.keys(TREE_BRANCH_FIELDS(isDodActive)).includes(selectedSnapshotField)) {
        entities = getOrderForEntities(selectedSnapshotField, entities, usableMetadata, isDodActive);
      }

      if (hasMetadataRoadmaps && (pageFilters.quickFilters?.roadmaps || pageFilters.quickFilters?.products)) {
        entities = verifyMetadataEntities(entities, usableMetadata);
      }

      entities = entities.map(e => {
        if (e.metadataName === 'objectiveCorp' && usableMetadata?.objectivesCorp) {
          return {
            ...e,
            title: usableMetadata.objectivesCorp.find(m => m.id === e.id)?.title || e.title,
          };
        }

        return e;
      });

      entities = selectedUsableMetadata
        ? entities.map(entity => {
            const entityInUsableMetadata = selectedUsableMetadata.find(metadataEntity => metadataEntity.id === entity.id) || {};

            return {
              ...entity,
              ...entityInUsableMetadata,
              id: entity.id || UNDEFINED_ID,
              metrics: getEntityMetrics(entity.metrics),
            };
          })
        : entities;

      return entities;
    }, [allReportEntities, selectedUsableMetadata, hideEmptyCards, selectedSnapshotField]);

    const _generateBranches = (entity, branchGroup) => {
      return allReportEntities
        .filter(entity => entity.metadataName === branchGroup)
        .map(e => ({
          ...e,
          group: branchGroup,
        }))
        .filter(e => e[TREE_BRANCH_FIELDS(isDodActive)[branchGroup]] === entity.id);
    };

    const _getSelectedEntityFromReport = (entity, entities) =>
      entities.find(e => {
        return e.id === entity.id && entity.metadataName === e.metadataName;
      });

    const _updateTreeData = (entity, refresh = false) => {
      const branchGroup = TREE_BRANCHES[entity.group || selectedSnapshotField];

      if (!branchGroup) {
        return;
      }

      let updatedTreeData = {
        roots: [],
        branches: [],
      };

      // if report data changes, we only need refresh the existing tree
      if (refresh) {
        updatedTreeData.roots = treeData.roots.map(root => {
          const updatedEntity = _getSelectedEntityFromReport(root, allReportEntities);

          return {
            ...updatedEntity,
            group: root.group,
          };
        });

        updatedTreeData.branches = treeData.branches.map(branch => {
          const updatedEntity = _getSelectedEntityFromReport(branch, allReportEntities);

          return {
            ...updatedEntity,
            group: branch.group,
          };
        });
      } else {
        setSelectedTreeEntity(entity);

        // Check is branch entity
        const branchIndex = treeData.branches.findIndex(branch => `${branch.id}` === `${entity.id}`);
        // Check is root entity
        const rootIndex = treeData.roots.findIndex(root => `${root.id}` === `${entity.id}`);

        let branches = [];

        if (rootIndex !== 0) {
          branches = _generateBranches(entity, branchGroup);
        }

        if (rootIndex === -1 && branchIndex === -1) {
          // First time to select card
          updatedTreeData = {
            roots: [
              {
                ...entity,
                group: selectedSnapshotField,
              },
            ],
            branches,
          };
        } else if (branchIndex > -1) {
          // Branch Entity selected
          updatedTreeData = {
            roots: [...treeData.roots, { ...entity }],
            branches,
          };
        } else if (rootIndex > -1) {
          // Root Entity selected
          if (rootIndex === 0) {
            // Head root is selected
            updatedTreeData = {
              roots: [],
              branches: [],
            };

            setSelectedTreeEntity(null);
          } else {
            updatedTreeData = {
              roots: [...treeData.roots],
              branches,
            };

            updatedTreeData.roots.splice(rootIndex, 1);

            if (!branches.length) {
              const root = updatedTreeData.roots[rootIndex - 1];
              const newBranches = _generateBranches(root, TREE_BRANCHES[root.group || selectedSnapshotField]);

              updatedTreeData.branches = [...newBranches];
            }
          }
        }
      }

      setTreeData(updatedTreeData);
    };

    useEffect(() => {
      if (selectedTreeEntity) {
        _updateTreeData(selectedTreeEntity, true);
      }
    }, [allReportEntities]);

    const tableEntities = useMemo(() => {
      return values(okrLevels).includes(selectedSnapshotField) && myShowSnapshotTreeView ? okrEntities : entities;
    }, [selectedSnapshotField, myShowSnapshotTreeView, okrEntities, entities]);

    if (shouldDisplaySpinner) {
      return (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      );
    }

    return (
      <Component
        {...props}
        displayLayer={displayLayer}
        menuIsClosed={menuIsClosed}
        initiatives={initiatives}
        bets={bets}
        onUpdateMetadataFieldById={_onUpdateMetadataFieldById}
        updateTreeData={_updateTreeData}
        snapshotFields={snapshotFields}
        treeData={treeData}
        tableShowFieldsEl={tableShowFieldsEl}
        setTableShowFieldsEl={setTableShowFieldsEl}
        entities={entities}
        isOKRLastLevel={isOKRLastLevel}
        showSnapshotTreeView={myShowSnapshotTreeView}
        tableEntities={tableEntities}
        groupedBy={groupedBy}
        hasKeyResults={Boolean(props.hasKeyResults)}
        hasKeyResults2={Boolean(props.hasKeyResults2)}
        onUpdateGridConfig={_onUpdateGridConfig}
        onUpdatePageSize={_onUpdatePageSize}
        rowHeight={rowHeight}
        snapshotTableVisibleFields={snapshotTableVisibleFields}
        showSnapshotTable={showSnapshotTable}
        expandTableItems={expandTableItems}
        selectedSnapshotField={selectedSnapshotField}
        hideEmptyCards={hideEmptyCards}
        hideCards={hideCards}
        pageSize={pageSize}
        shouldRenderControls={shouldDisplayControlsBar}
        hasAdvancedMetricReporting={hasAdvancedMetricReporting}
        updateSnapshotReport={fetchReportData}
        isMetricsDialogVisible={isMetricsDialogVisible}
        handleOpenMetricView={handleOpenMetricView}
        createMetricOption={createMetricOption}
        gridState={gridState}
        saveGridState={saveGridState}
        cardsPerRow={cardsPerRow}
        visibleCardElements={visibleCardElements}
      />
    );
  };
};

export default compose(
  connect(mapStateToProps, {
    ...snapshotActions,
    ...projectActions,
    ...objectiveActions,
    ...roadmapActions,
    ...themeActions,
    ...timeframeActions,
    ...categoryActions,
    ...userActions,
    ...phaseActions,
    fetchTags,
    fetchCustomers,
    openObjectiveDrawer,
    addKeyResultWithoutSave,
  }),
  withRouter,
  withHooksHOC,
)(Snapshot);

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 10px;
`;
