import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { COLOR_BY_OPTIONS, DEFAULT_COLOR_BY_OPTION } from 'constants/staffing';
import { getOrganization } from 'store/organization/selectors';
import { updateState } from 'store/staffing';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const lsState = useSelector(state => state.staffing);
    const [isOpen, setIsOpen] = useState(false);
    const [tempState, _setTempState] = useState(lsState);
    const setTempState = data => _setTempState({ ...tempState, ...data });
    const _onOpen = () => setIsOpen(true);
    const _onCancel = () => setIsOpen(false);
    const _onApply = () => {
      dispatch(updateState(tempState));
      _onCancel();
    };

    const organization = useSelector(getOrganization);
    const systemFields = organization.system_fields_name;
    const hasKeyResults = organization.has_key_results;
    const hasProducts = organization.has_products;
    const colorByOptions = COLOR_BY_OPTIONS(systemFields, hasKeyResults, hasProducts);

    React.useEffect(() => {
      const defaultState = {};

      if (!lsState.selectedColorBy) defaultState.selectedColorBy = DEFAULT_COLOR_BY_OPTION(systemFields);
      if (!lsState.timeWindowStart) defaultState.timeWindowStart = moment().subtract(1, 'month');
      if (!lsState.timeWindowEnd) defaultState.timeWindowEnd = moment().add(1, 'month');
      if (lsState.showTooltip === undefined) defaultState.showTooltip = true;
      if (Object.keys(defaultState).length) dispatch(updateState(defaultState));
    }, []);

    React.useEffect(() => {
      if (isOpen) _setTempState(lsState);
    }, [isOpen]);
    React.useEffect(() => {
      if (props.triggerOpenEvent) {
        props.triggerOpenEvent(() => () => setIsOpen(true));
      }
    }, []);

    return (
      <Component
        isOpen={isOpen}
        onOpen={_onOpen}
        onCancel={_onCancel}
        onApply={_onApply}
        lsState={tempState}
        updateLsState={setTempState}
        colorByOptions={colorByOptions}
        {...props}
      />
    );
  };
};

export default componentHOC;
