import React, { useEffect } from 'react';
import styled from 'styled-components';

import { BaseGrid } from 'containers/Grids';
import { getRowId } from 'design-system/molecules/AgGridReact-New/helpers';

import usePersonaList from '../hooks/usePersonaList';

import { GRID_HEIGHT } from '../helpers/constants';
import { BASE_ROW_HEIGHT } from 'constants/grid';

const PersonasList = ({ personas, canEditPersona, canDeletePersona }) => {
  const {
    data,
    commonGridProps,
    commonGridEvents,
    columnDefs,
    defaultColumnDef,
    rowHeight,
    getGridIsReady,
    getGridApi,
    handleGridReady,
  } = usePersonaList({
    personas,
    canUpdate: canEditPersona,
    canDelete: canDeletePersona,
  });

  useEffect(() => {
    if (getGridIsReady()) {
      getGridApi().resetRowHeights();
    }
  }, [rowHeight]);

  return (
    <SyledBaseGrid
      {...commonGridProps}
      {...commonGridEvents}
      onGridReady={handleGridReady}
      getRowId={getRowId}
      rowData={data}
      columnDefs={columnDefs}
      defaultColDef={defaultColumnDef}
      headerHeight={BASE_ROW_HEIGHT}
      height={GRID_HEIGHT}
      rowHeight={rowHeight}
    />
  );
};

const SyledBaseGrid = styled(BaseGrid)`
  && {
    .ag-root {
      margin: 0;
    }
  }
`;

export default PersonasList;
