import moment from 'moment-timezone';
import { defaultTo } from 'ramda';

import { getAllTasks, getUserName, getUpdatedByDisplayName, calculateRiceScore } from 'utils';
import addWeeks from 'utils/dates/addWeeks';

const defaultToZero = defaultTo(0);
const defaultToEmptyArray = defaultTo([]);

const getPrecalculationsValues = project => {
  const { preCalculations } = project;

  const { total_revenue: activeRevenue = 0, total_churned_revenue: inactiveRevenue = 0 } = preCalculations || {};

  const totalNetRevenue = activeRevenue + inactiveRevenue;

  return {
    activeRevenue,
    inactiveRevenue,
    totalRevenue: totalNetRevenue,
  };
};

export default project => {
  // we need to send empty values for ideas grid grouping
  if (project.objective) project.objectiveTitle = project.objective ? project.objective.title : '';
  if (project.keyResult1) project.keyResult1Title = project.keyResult1 ? project.keyResult1.title : '';
  if (project.keyResult2) project.keyResult2Title = project.keyResult2 ? project.keyResult2.title : '';
  if (project.roadmap) project.roadmapTitle = project.roadmap ? project.roadmap.title : '';
  if (project.product1) project.product1Title = project.product1 ? project.product1.title : '';
  if (project.product2) project.product2Title = project.product2 ? project.product2.title : '';
  if (project.owner) project.ownerName = project.owner ? getUserName(project.owner) : '';
  if (project.timeframe) project.timeframeTitle = project.timeframe ? project.timeframe.title : '';
  if (project.phase) project.phaseTitle = project.phase ? project.phase.title : '';
  if (project.theme) project.themeTitle = project.theme ? project.theme.title : '';
  if (project.category) project.categoryTitle = project.category ? project.category.title : '';
  if (project.priority) project.priorityTitle = project.priority ? project.priority.title : '';
  const riceScore = calculateRiceScore(project.reach_score, project.impact_score, project.confidence_score, project.effort_score);

  project.riceScore = String(riceScore);

  const isDeadlineBeforeStartDate =
    project.estimated_start_date &&
    project.deadline &&
    moment(project.deadline).startOf('day') < moment(project.estimated_start_date).startOf('day');

  if (isDeadlineBeforeStartDate) {
    project.deadline = addWeeks(moment(project.estimated_start_date), 2);
  }

  // for allocation report
  // we use deadline or end_date / estimated_start_date or start_date
  project.duration = moment(project.deadline || project.end_date).diffDuration(
    moment(project.estimated_start_date || project.start_date),
    'days',
  );
  project.estimated_duration = project.duration;

  if (project.planningStage) project.originalPlanningStage = project.planningStage;
  if (project.parent_id === '') project.parent_id = null;
  if (project.updated_by_id) project.updatedByName = getUpdatedByDisplayName(project.updatedBy);

  // TODO: backend should only send arrays of ids for these, not full models
  if (project.tasks) {
    project.tasks = getAllTasks(project);
  }

  if (project.estimates) {
    project.estimateSummary = project.estimates.reduce((acc, cur) => acc + (cur.numStaff * cur.duration || 0), 0);
  }

  const { totalRevenue, inactiveRevenue, activeRevenue } = getPrecalculationsValues(project);

  project.customersCount = defaultToZero(project?.preCalculations?.customers_count);
  project.customerRequestsCount = defaultToZero(project?.preCalculations?.customer_requests_count);
  project.totalRevenue = totalRevenue;
  project.inactiveRevenue = inactiveRevenue;
  project.activeRevenue = activeRevenue;

  project.blocksTotal = defaultToEmptyArray(project?.projectDependencies).filter(p => Boolean(p.id)).length;
  project.blockedByTotal = defaultToEmptyArray(project?.projectProjectDependants).filter(p =>
    Boolean(p.project_dependency_id),
  ).length;

  return project;
};
