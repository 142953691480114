import React from 'react';
import styled from 'styled-components';

const Today = ({ todayLine }) => {
  return (
    <>
      <TodayLine style={{ left: todayLine }} />
      <TodayFlag style={{ left: todayLine }} />
    </>
  );
};

export default Today;

const TodayLine = styled.div`
  &&&& {
    top: 0;
    position: absolute;
    height: 100%;
    border-left: 2px solid ${({ theme }) => theme.palette.text.purple};
    z-index: 50;
  }
`;

const TodayFlag = styled.div`
  &&&& {
    top: 0;
    position: absolute;
    z-index: 50;

    &:after {
      content: 'Today';
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0;
      color: white;
      background: ${({ theme }) => theme.palette.text.purple} 0 0 no-repeat padding-box;
      border-radius: 0 15px 15px 0;
      padding: 3px 10px;
    }
  }
`;
