import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { materialColorsAlt } from 'design-system/themes/default';
import { orderedPlanningStages, statusColors } from 'utils';

import { getAllTeams } from 'store/teams/selectors';
import { getProducts, getProductsLevelTwo } from 'store/roadmaps/selectors';
import { getAllInitiatives, getAllBets } from 'store/projects/selectors';
import { getTimeframesLevel2 } from 'store/timeframes/selectors';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import { getDropdownCustomFields } from 'store/customFields/selectors';

import useMetadataRoadmapQuickFilter from 'hooks/useMetadataRoadmapQuickFilter';
import { getProjectHealthLabel } from 'utils/projects/healthUtils';

const useMetadata = (organization, projects) => {
  const hasHierarchy = organization.has_hierarchy;
  const hasBet = organization.has_bet;
  const hasMultiLevelPortfolioMetadata = organization.has_multi_level_portfolio_metadata;

  const dropdownCustomFields = useSelector(getDropdownCustomFields);
  const teams = useSelector(state => getAllTeams(state, false));

  const filteredMetadata = useMetadataRoadmapQuickFilter(projects, organization);

  const metadataFromStore = useSelector(
    state => ({
      products: getStateDataForPage(state, getProducts, 'products'),
      products2: getStateDataForPage(state, getProductsLevelTwo, 'products2'),
      ...(hasHierarchy ? { initiatives: getAllInitiatives(state) } : {}),
      ...(hasBet ? { bets: getAllBets(state) } : {}),
      ...(hasMultiLevelPortfolioMetadata
        ? {
            timeframes2: getStateDataForPage(state, getTimeframesLevel2, 'timeframes'),
          }
        : {}),
      customFields: dropdownCustomFields,
      teams,
    }),
    isEqual,
  );

  return useMemo(() => {
    const allMetadata = {
      ...filteredMetadata,
      ...metadataFromStore,
    };

    return {
      ...allMetadata,
      // Adapt for the same structure being used for projects grouping
      stages: orderedPlanningStages,
      planningStages: orderedPlanningStages,
      statusColors: statusColors.map(c => ({
        id: c.label,
        title: getProjectHealthLabel(c.label),
        color: materialColorsAlt[c.label.toLowerCase()],
      })),
    };
  }, [filteredMetadata, metadataFromStore]);
};

export default useMetadata;
