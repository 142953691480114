/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';

import { generateGroupBarWidth, generateInfos } from '../helpers';

import Bar from './TableBar';

const TableGroupBar = ({ task, open, onUpdate }) => {
  const [showBody, setShowBody] = React.useState(open);
  const [infos, setInfos] = React.useState({});
  const [info, setInfo] = React.useState({ left: 0, width: 0, pLeft: 0 });

  const rows = React.useMemo(() => {
    if (!task || !task.subRows || !task.subRows.length) return 0;

    return task.subRows.length;
  }, [task]);

  const updateInfo = t => ({
    ...t,
    info: {
      ...((infos && infos[t.id]) || {}),
      left: (infos && infos[t.id] && infos[t.id].left) || 0,
    },
  });

  React.useEffect(() => {
    if (!infos) setInfo({ left: 0, width: 0, pLeft: 0 });
    else {
      setInfo(generateGroupBarWidth(infos));
    }
  }, [infos]);

  React.useEffect(() => {
    if (!task) {
      setInfos({});
    } else {
      const data = generateInfos([task, ...(task.subTasks || [])]);

      setInfos(data);
      setInfo(generateGroupBarWidth(data));
    }
  }, [task]);

  const _renderTask = (task, isParent, rowIndex, iIndex) => {
    const startDate = moment(task.startDate).format('YYYY-MM-DD');
    const endDate = moment(task.endDate).format('YYYY-MM-DD');

    const handleDrag = delta => {
      setInfos(i => ({
        ...i,
        [task.id]: {
          ...i[task.id],
          left: delta.x,
        },
      }));
    };

    const handleResize = (_, delta, position) => {
      setInfos(i => ({
        ...i,
        [task.id]: {
          ...i[task.id],
          left: position.x,
          width: i[task.id].pWidth + delta.width,
        },
      }));
    };

    const handleUpdate = () => {
      onUpdate && onUpdate(task.id, infos[task.id], isParent, rowIndex, iIndex);
    };

    return (
      <Bar
        key={task.id}
        id={task.id}
        name={task.ownerName}
        title={task.title}
        color={task.color}
        borderColor={task.borderColor}
        startDate={startDate}
        endDate={endDate}
        top={0}
        left={task.info.left}
        width={task.info.width}
        height={35}
        disableDragY
        noMargin={isParent}
        openBody={() => isParent && setShowBody(!showBody)}
        onDrag={handleDrag}
        onDragStop={handleUpdate}
        onResize={handleResize}
        onResizeStop={handleUpdate}
      />
    );
  };

  return (
    <GroupBarWrapper>
      <GroupBarHeader>{_renderTask(updateInfo(task), true)}</GroupBarHeader>
      {showBody && task.subRows && task.subRows.length && (
        <GroupBarContent>
          {task.subRows.map((row, rowIndex) => (
            <GroupBarRow key={rowIndex}>
              {row.map((item, iIndex) => _renderTask(updateInfo(item), false, rowIndex, iIndex))}
            </GroupBarRow>
          ))}
        </GroupBarContent>
      )}
      {showBody && (
        <>
          <HeaderWrapper style={{ left: info.left, width: info.width }} />
          <BodyWrapper style={{ left: info.left, width: info.width, height: 35 * rows }} />
        </>
      )}
    </GroupBarWrapper>
  );
};

export default TableGroupBar;

const GroupBarWrapper = styled.div`
  &&&& {
    position: relative;
  }
`;

const GroupBarHeader = styled.div`
  &&&& {
    height: 21px;
  }
`;

const GroupBarContent = styled.div`
  &&&& {
  }
`;

const GroupBarRow = styled.div`
  &&&& {
    height: 35px;
  }
`;

const HeaderWrapper = styled.div`
  &&&& {
    top: 0;
    position: absolute;
    border: 1px dashed red;
    height: 21px;
  }
`;

const BodyWrapper = styled.div`
  &&&& {
    top: 21px;
    position: absolute;
    border: 1px dashed red;
    border-top: none;
  }
`;
