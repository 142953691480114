import { F } from 'ramda';
import useCustomerRequestsGridState from 'src/routes/CustomerRequests/Grid/New/hooks/useCustomerRequestsGridState';

const useAIAnalyzerGridState = () => {
  const { rowHeight, columnsState, shouldGridBeUnmounted } = useCustomerRequestsGridState();

  const visibleColumnsIds = ['tags', 'autoTags'];

  return {
    rowHeight,
    searchString: '',
    isBulkUpdate: true,
    visibleColumnsIds,
    columnsState,
    shouldGridBeUnmounted,

    setDirtyViewMutex: F,
    saveColumnsState: F,
  };
};

export default useAIAnalyzerGridState;
