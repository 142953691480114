import React from 'react';
import { useSelector } from 'react-redux';

import { LEAD_LINES_PLUGIN } from 'components/ReactChart/utils/plugins';

import { getProjectsGroupedBy } from 'store/dashboards/selectors';
import { getNormalizedRoadmaps } from 'store/roadmaps/selectors';
import { INITIATIVE_LAYER } from 'src/store/projects/constants';

import usePieChart from 'hooks/charts/usePieChart';

import { formatDataByMetadataForPieChart } from '../../helpers';

const GROUP_BY_KEY = 'roadmap';

const componentHOC = Component => {
  return props => {
    const selectedGroup = { key: GROUP_BY_KEY };
    const { roadmaps, data } = useSelector(state => ({
      roadmaps: getNormalizedRoadmaps(state),
      data: getProjectsGroupedBy(state, props.pageId, { selectedGroupByLvl1: selectedGroup }, INITIATIVE_LAYER),
    }));

    const _makeDataToChart = React.useCallback(() => {
      const { values, colors, labels } = formatDataByMetadataForPieChart(data, roadmaps);

      const datasets = [
        {
          label: '',
          data: values,
          backgroundColor: colors,
          hoverOffset: 4,
        },
      ];

      return { labels, datasets };
    }, [data, roadmaps]);

    const chart = usePieChart({
      generateData: _makeDataToChart,
      options: {
        plugins: {
          legend: { display: false },
        },
        layout: {
          padding: 10,
        },
        radius: '80%',
      },
      plugins: [LEAD_LINES_PLUGIN],
    });

    return <Component {...props} chart={chart} />;
  };
};

export default componentHOC;
