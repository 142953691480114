import React from 'react';
import styled, { css } from 'styled-components';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import theme from 'design-system/theme';

const GridMenuItem = ({ item, selected, onClick }) => {
  return (
    <Container selected={selected} onClick={() => onClick(item)} clickable={!!onClick}>
      <IconWrapper>{item.icon}</IconWrapper>
      <StyledText color={theme.palette.newLayout.background.duskBlue}>{item.label}</StyledText>
    </Container>
  );
};

export default GridMenuItem;

const Container = styled.div`
  width: 305px;
  height: 125px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.unit * 2}px;
  padding: ${({ theme }) => `0 ${theme.spacing.unit * 3}px`};
  border: 2px solid transparent;
  border-radius: ${({ theme }) => theme.shape.borderRadiusLarge}px;
  box-sizing: border-box;

  ${({ selected }) =>
    selected &&
    css`
      border-color: ${({ theme }) => theme.palette.newLayout.background.turquoise};
    `}

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;

const StyledText = styled(TextDeprecated)`
  &&&& {
    font-size: ${({ theme }) => theme.typography.fontSizeMediumSmall}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    line-height: 1.15;
    white-space: unset;
    overflow: unset;
    max-width: 125px;
  }
`;

const IconWrapper = styled.div`
  svg {
    width: 1em;
    height: 1em;
    color: ${({ theme }) => theme.palette.newLayout.background.turquoise};
    font-size: ${({ theme }) => theme.spacing.unit * 9}px;
    fill: currentColor;
  }
`;
