import React from 'react';
import styled from 'styled-components';
import withTheme from '@material-ui/core/styles/withTheme';
import TablePagination from '@material-ui/core/TablePagination';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import PaginationActions from 'design-system/molecules/PaginationActions/PaginationActions';
import { spacing } from 'design-system/theme';
import { formatPageNumber } from 'design-system/utils/pagination';

export const PAGE_LIMIT_OPTIONS = [25, 50, 100];

function Pagination({
  currentPage,
  handleChangePage,
  handleChangePageLimit,
  includePagination = true,
  lastPage,
  pageLimit,
  resultsCount,
  rowsPerPageOptions,
  isDisabled = false,
  pageLimitOptions = PAGE_LIMIT_OPTIONS,
  customContentComponent,
}) {
  if (!includePagination) return null;

  return (
    <PaginationWrapper>
      <PageSelectContainer>
        <PageLabel>Page Size</PageLabel>
        <FormControl size="small">
          <StyledSelect
            label="Page Size"
            onChange={e => handleChangePageLimit(e.target.value)}
            value={pageLimit}
            disabled={isDisabled}
            data-testid="page-size"
            MenuProps={{ 'data-testid': 'page-size-menu' }}
          >
            {pageLimitOptions.map(option => (
              <StyledMenuItem key={option} value={option}>
                {option}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </PageSelectContainer>
      {customContentComponent}
      <TablePagination
        ActionsComponent={() => (
          <PaginationActions
            currentPage={currentPage}
            handleChangePage={handleChangePage}
            lastPage={lastPage}
            isDisabled={isDisabled}
          />
        )}
        component="div"
        count={resultsCount}
        labelDisplayedRows={({ from, to, count, page }) => {
          const fromPage = formatPageNumber(from);
          const toPage = formatPageNumber(to);
          const formattedCount = formatPageNumber(count);

          return `${fromPage} to ${toPage} of ${formattedCount}`;
        }}
        labelRowsPerPage="Page Size"
        onChangePage={handleChangePage}
        page={currentPage}
        rowsPerPage={pageLimit}
        rowsPerPageOptions={rowsPerPageOptions}
        showFirstButton
        showLastButton
        sx={{
          border: 'none',
        }}
      />
    </PaginationWrapper>
  );
}

export default withTheme()(Pagination);

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PageSelectContainer = styled.div`
  color: inherit;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.newLayout.text.grey};
`;

const PageLabel = styled.div`
  margin-right: 5px;
  color: inherit;
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
`;

const StyledSelect = styled(Select)`
  &&&&& {
    color: inherit;
    font-size: 0.75rem;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
    padding: ${spacing()}px ${spacing(2)}px;
  }
`;
