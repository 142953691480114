import React from 'react';
import styled from 'styled-components';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';

import ToggleButton from 'design-system/molecules/ToggleButton/index';


const Toolbar = ({ inEditMode, toggleEditMode, userCanEdit }) => {
  return (
    <Container>
      {userCanEdit ? (
        <ToggleButton
          id="edit_overview_page"
          icon={<DashboardIcon fontSize="inherit" />}
          on={inEditMode}
          onChange={toggleEditMode}
          title="Configure Layout"
          disabled={!userCanEdit}
        />
      ) : null}
    </Container>
  );
};

export default Toolbar;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 48px;
`;
