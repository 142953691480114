import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import AuthWrapper from 'components/AuthWrapper';

import { getDefaultRoute } from 'utils';

class Login extends React.Component {
  render() {
    const { isAuthenticated, currentUser } = this.props;

    const renderPage = () => {
      return (
        <AuthWrapper>
          <div className={this.props.classes.successText}>
            <Typography variant="h6">Waiting for confirmation</Typography>
            <Typography className={this.props.classes.successTextItem} variant="body2">
              {`We've sent an email to the administrator to confirm your user account. 
                You'll be informed when it gets approved.`}
            </Typography>
          </div>
        </AuthWrapper>
      );
    };

    if (isAuthenticated) {
      return <Redirect to={getDefaultRoute(currentUser)} />;
    }

    return renderPage();
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.login.currentUser,
  };
};

const mapDispatchToProps = (dispatch, props) => ({});

const styles = theme => ({
  textFields: {
    width: 250,
  },
  googleLoginButton: {
    marginTop: 30,
    width: 250,
  },
  resetPasswordHead: {
    marginTop: 30,
  },
  footNote: {
    marginTop: 30,
  },
  successText: {
    marginTop: 30,
  },
  successTextItem: {
    width: 270,
    marginTop: 30,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
