import React, { useState, memo } from 'react';
import styled from 'styled-components';
import withTheme from '@material-ui/core/styles/withTheme';

import GridTable from 'design-system/organisms/GridTable';

import { spacing } from 'design-system/theme';

import useRowHeader from '../hooks/useRowHeader';
import HeaderTitle, { IconsWrapper } from './HeaderTitle';
import { DateLower } from './headerComponents';
import { not } from 'ramda';
import { UNDEFINED } from 'constants/common';

const getTableBorder = theme => `1px solid ${theme.palette.border.secondary}`;

const RowHeader = withTheme()(
  memo(({ row, isReadOnly, selectedRowData, maxHeight, width, absoluted, slice, addBorderRight, theme, onVisilityIconClick }) => {
    const [isHoveringRowHeader, setIsHoveringRowHeader] = useState(false);

    const { isEditable, verticalRowHeader, headerColor, date } = useRowHeader({
      rowId: row.id,
      rowDate: row.date,
      isReadOnly,
      selectedRowData,
      slice,
    });

    const shouldDisplayDate = !!date;

    const hasRowHeaderColor = headerColor?.row && row.color;

    return (
      <StyledGridTableHeader
        first
        vertical
        borderLeftColor={hasRowHeaderColor ? row.color : null}
        style={{
          maxWidth: width ?? '100%',
          position: absoluted ? 'absolute' : 'unset',
          borderRight: addBorderRight ? getTableBorder(theme) : null,
          borderLeft: not(hasRowHeaderColor) ? getTableBorder(theme) : null,
          ...(width == null && { width: '100%' }),
        }}
        className="grid-table-header"
        data-row-id={`${row.id}`}
        onMouseEnter={() => setIsHoveringRowHeader(true)}
        onMouseLeave={() => setIsHoveringRowHeader(false)}
      >
        <GridTableHeaderContainer>
          <GridTable.HeaderTitle component="div">
            <Flex>
              <HeaderTitle
                id={`port-3d-row-${row.id}`}
                data-testid={`port-3d-row-${row.id}`}
                title={row.title}
                dataId={row.id}
                dataKey={selectedRowData.key}
                editable={isEditable}
                verticalTitle={verticalRowHeader}
                maxHeight={maxHeight}
                onVisilityIconClick={
                  onVisilityIconClick ? () => onVisilityIconClick(row?.id || UNDEFINED, selectedRowData?.key) : null
                }
                showIcons={isHoveringRowHeader}
              />
            </Flex>
          </GridTable.HeaderTitle>
          {shouldDisplayDate && <DateLower>{date}</DateLower>}
        </GridTableHeaderContainer>
      </StyledGridTableHeader>
    );
  }),
);

const StyledGridTableHeader = styled(GridTable.Header)`
  ${IconsWrapper} {
    padding-top: ${spacing(1.5)}px;
  }
`;

const GridTableHeaderContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing()}px;
`;

RowHeader.displayName = 'SummaryRowHeaderCell';

export default RowHeader;
