import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { defaultTo, either, head, isEmpty, isNil, or, pipe, prop } from 'ramda';

import {
  setDemoSplashPageShown as setDemoSplashPageShownAction,
  openDemoSplashPage as openDemoSplashPageAction,
  closeDemoSplashPage as closeDemoSplashPageAction,
} from 'store/onboardingDemo/actions';
import { fetchSelfGuidedDemoData as fetchSelfGuidedDemoDataAction } from 'store/onboardingDemo/thunks';
import {
  getIsDemoSplashPageOpen,
  getDemoSplashPageShown,
  getSelfGuidedDemoData,
  isSelfGuidedDemoLoading,
} from 'store/onboardingDemo/selectors';

import useOrganizations from 'hooks/useOrganizations';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';

import { addQueryParamToUrl, getQueryParamFromUrl, removeAllQueryParamsFromUrl } from 'utils/queryParamsUtils';

const PENDO_RESOURCE_CENTER_MESSAGE = 'open demo experience';

const isNilOrEmpty = either(isNil, isEmpty);
const getFirstOption = pipe(defaultTo([]), head);
const getFirstPendoOption = pipe(getFirstOption, prop('options'), defaultTo([]), head);

const getMenuOption = (navOptions, selectedMenuOptionParam) => {
  return navOptions.find(o => o.menu_option_query_param.toString() === selectedMenuOptionParam);
};

const getGuideOption = (navOptions, selectedMenuOptionParam, selectedGuideQueryParam) => {
  const option = getMenuOption(navOptions, selectedMenuOptionParam);
  const allOptions = option?.options.flatMap(option => option.options) || [];

  if (!selectedGuideQueryParam) return getFirstPendoOption(option?.options);

  return allOptions.find(subOption => subOption.guide_query_param === selectedGuideQueryParam);
};

const getNavOptions = (selfGuidedDemoData, handleSelectNavOption) => {
  if (isNilOrEmpty(selfGuidedDemoData)) {
    return [];
  }

  return selfGuidedDemoData.map(option => {
    return {
      ...option,
      botBusinessDriverResponse: option.bot_response,
      onClick: () => handleSelectNavOption(option),
    };
  });
};

// Select a Pendo Guide Option - L2
const handleSelectPendoGuide = (guideOption, isPendoReady, hasDemoQueryParam) => {
  if (!isPendoReady || !hasDemoQueryParam) return;

  const { pendo_guide_id: guideOptionId } = guideOption || {};

  const activeGuide = window.pendo.getActiveGuide();

  if (activeGuide?.guide?.id === guideOptionId) return;

  window.pendo.onGuideDismissed();

  const pendoGuideIsAvailable = Boolean(window.pendo.findGuideById(guideOptionId));

  if (pendoGuideIsAvailable) {
    window.pendo.showGuideById(guideOptionId);
  }
};

const useOnboardingDemo = () => {
  const dispatch = useDispatch();

  const { hasTargetedOnboarding, isOnboardingDemo: isOnboardingDemoOrganization } = useOrganizations();

  const isDemoSplashPageOpen = useSelector(getIsDemoSplashPageOpen);
  const demoSplashPageShown = useSelector(getDemoSplashPageShown);
  const selfGuidedDemoData = useSelector(getSelfGuidedDemoData);
  const isGuideDemoDataLoading = useSelector(isSelfGuidedDemoLoading);

  const hasDiscoveryBot = useFeatureFlags([FeatureFlags.HAS_DEMO_BOT_AI_ENABLED]);

  const [isBookADemoOpen, setIsBookADemoOpen] = useState(false);

  const [isPendoReady, setIsPendoReady] = useState(false);

  useEffect(() => {
    if (isPendoReady && !isGuideDemoDataLoading) {
      const option = getGuideOption(navigationOptions, menuOptionParam, guideQueryParam);

      handleSelectGuide(option);
    }
  }, [isPendoReady, isGuideDemoDataLoading]);

  const handleMenuOptionChangeOnURL = value => {
    if (!value) return;

    const selectedMenuOption = getMenuOption(navigationOptions, value);
    const selectedSubMenuOption = getFirstPendoOption(selectedMenuOption?.options);

    handleSelectPendoGuide(selectedSubMenuOption, isPendoReady, hasDemoQueryParam);
  };

  const handleGuideChangeOnURL = value => {
    const selectedSubMenuOption = getGuideOption(navigationOptions, menuOptionParam, value);

    handleSelectPendoGuide(selectedSubMenuOption, isPendoReady, hasDemoQueryParam);
  };

  const kickoffPendoFlow = () => {
    return window.pendo?.events?.guidesLoaded(() => setIsPendoReady(true));
  };

  const openDemoSplashPage = () => {
    dispatch(fetchSelfGuidedDemoDataAction());
    dispatch(openDemoSplashPageAction());
    // Adds '?demo=true' to the url when we enter the demo page
    addQueryParamToUrl('demo', true);
  };

  const closeDemoSplashPage = () => {
    dispatch(closeDemoSplashPageAction());
    window.pendo.onGuideDismissed();
  };

  const setDemoSplashPageShown = () => {
    dispatch(setDemoSplashPageShownAction());
  };

  const fetchSelfGuidedDemoData = () => {
    dispatch(fetchSelfGuidedDemoDataAction());
  };

  const receiveResourceCenterMessage = m => {
    if (m.origin === window.origin && m.data.message === PENDO_RESOURCE_CENTER_MESSAGE) {
      openDemoSplashPage();
    }
  };

  // Clears all the queryParams from the url
  const clearAllNavParams = () => {
    removeAllQueryParamsFromUrl();
  };

  // Removes all the queryParams from a list from the url (eg. Option & GuideId)
  const removeNavOptionsParams = () => {
    removeAllQueryParamsFromUrl();
    addQueryParamToUrl('demo', 'true');
  };

  const addNavOptionsParams = (queryParam, value) => {
    addQueryParamToUrl(queryParam, value);
  };

  const onCloseDialog = () => {
    clearAllNavParams();
    setTimeout(() => {
      closeDemoSplashPage();
    });
  };

  // Select a Menu Option - L1
  const handleSelectNavOption = menuOption => {
    // Adds '&option=${menu_option_query_param} to the url
    addNavOptionsParams('option', menuOption?.menu_option_query_param);

    const guideOption = getFirstPendoOption(menuOption?.options);

    handleSelectGuide(guideOption);

    setIsBookADemoOpen(false);
  };

  const handleSelectGuide = guideOption => {
    if (guideOption) {
      const { guide_query_param: guideOptionParam } = guideOption;

      addNavOptionsParams('guideId', guideOptionParam);

      handleSelectPendoGuide(guideOption, isPendoReady, hasDemoQueryParam);
      setIsBookADemoOpen(false);
    }
  };

  const guideQueryParam = getQueryParamFromUrl('guideId');
  const menuOptionParam = getQueryParamFromUrl('option');
  const demoQueryParam = getQueryParamFromUrl('demo');

  const hasDemoQueryParam = !!demoQueryParam;

  const navigationOptions = getNavOptions(selfGuidedDemoData, option => handleSelectNavOption(option, addNavOptionsParams));

  const selectedMenuOption = getMenuOption(navigationOptions, menuOptionParam);

  const selectedSubMenuOption = getGuideOption(navigationOptions, menuOptionParam, guideQueryParam);

  const shouldShowHeader = (!hasDiscoveryBot && selectedMenuOption) || (hasDiscoveryBot && selectedSubMenuOption?.pendo_guide_id);

  const isDemoModeActive = or(hasTargetedOnboarding, hasDiscoveryBot);

  return {
    isDemoModeActive,
    isDemoSplashPageOpen,
    isOnboardingDemoOrganization,
    demoSplashPageShown,
    selfGuidedDemoData,
    isGuideDemoDataLoading,
    openDemoSplashPage,
    closeDemoSplashPage,
    setDemoSplashPageShown,
    receiveResourceCenterMessage,
    fetchSelfGuidedDemoData,
    isBookADemoOpen,
    selectedMenuOption,
    selectedSubMenuOption,
    shouldShowHeader,
    removeNavOptionsParams,
    onCloseDialog,
    handleSelectGuide,
    navigationOptions,
    setIsBookADemoOpen,
    handleMenuOptionChangeOnURL,
    handleGuideChangeOnURL,
    kickoffPendoFlow,
    demoQueryParam,
    hasDemoQueryParam,
  };
};

export default useOnboardingDemo;
