import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_METRIC_LINKED_ITEMS'),
  ...getThunkActionTypes('LINK_PROJECT_TO_METRIC'),
  ...getThunkActionTypes('UNLINK_PROJECT_FROM_METRIC'),
  ...getThunkActionTypes('CREATE_PROJECT_FROM_METRIC'),
};

export const ADD_NEW_LINKED_ITEM_ROW = 'ADD_NEW_LINKED_ITEM_ROW';
export const REMOVE_UNSAVED_LINKED_ITEMS_ROWS = 'REMOVE_UNSAVED_LINKED_ITEMS_ROWS';

export const {
  FETCH_METRIC_LINKED_ITEMS,
  FETCH_METRIC_LINKED_ITEMS_FULFILLED,
  FETCH_METRIC_LINKED_ITEMS_PENDING,
  FETCH_METRIC_LINKED_ITEMS_REJECTED,
  LINK_PROJECT_TO_METRIC,
  LINK_PROJECT_TO_METRIC_FULFILLED,
  LINK_PROJECT_TO_METRIC_PENDING,
  LINK_PROJECT_TO_METRIC_REJECTED,
  UNLINK_PROJECT_FROM_METRIC,
  UNLINK_PROJECT_FROM_METRIC_FULFILLED,
  UNLINK_PROJECT_FROM_METRIC_PENDING,
  UNLINK_PROJECT_FROM_METRIC_REJECTED,
  CREATE_PROJECT_FROM_METRIC,
  CREATE_PROJECT_FROM_METRIC_FULFILLED,
} = actionTypes;
