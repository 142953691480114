import { defaultTo, not } from 'ramda';

import {
  SET_UNSELECTED_OVERBOOKED_TEAMS,
  SET_UNSELECTED_UNDERBOOKED_TEAMS,
  CLEAN_PAGE_HEADCOUNT_FILTER,
  SET_SELECTED_OPTIONS_ON_FILTER_TO_PLAN,
} from './types';
import { COMMITTED_KEY, INCLUDE_ALL_OPTION } from 'constants/projects';

const initialState = {};

const defaultPageHeadcountConfig = defaultTo({
  selectedOverbookedTeams: {},
  selectedUnderbookedTeams: {},
  selectedOptionToPlan: null,
  selectedOptionsOnFilter: {
    [COMMITTED_KEY]: INCLUDE_ALL_OPTION,
  },
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_UNSELECTED_OVERBOOKED_TEAMS: {
      const { pageId, teams } = action.payload;

      if (not(pageId)) {
        return state;
      }

      return {
        ...state,
        [pageId]: {
          ...defaultPageHeadcountConfig(state[pageId]),
          unselectedOverbookedTeams: teams,
        },
      };
    }
    case SET_UNSELECTED_UNDERBOOKED_TEAMS: {
      const { pageId, teams } = action.payload;

      if (not(pageId)) {
        return state;
      }

      return {
        ...state,
        [pageId]: {
          ...defaultPageHeadcountConfig(state[pageId]),
          unselectedUnderbookedTeams: teams,
        },
      };
    }
    case SET_SELECTED_OPTIONS_ON_FILTER_TO_PLAN: {
      const { pageId, selectedOptions } = action.payload;

      if (not(pageId)) {
        return state;
      }

      return {
        ...state,
        [pageId]: {
          ...defaultPageHeadcountConfig(state[pageId]),
          selectedOptionsOnFilter: selectedOptions,
        },
      };
    }
    case CLEAN_PAGE_HEADCOUNT_FILTER: {
      const { pageId } = action.payload;

      if (not(pageId)) {
        return state;
      }

      return {
        ...state,
        [pageId]: defaultPageHeadcountConfig(),
      };
    }
    default:
      return state;
  }
}
