/**
 * Custom hook to set the Today marker on the gantt
 *
 * @returns updateMarker function
 */
const useTodayMarker = () => {
  const updateMarker = (gantt) => {
    gantt.addMarker({
      start_date: new Date(),
      css: 'marker_line_today',
      text: 'Today',
      title: gantt.date.date_to_str(gantt.config.task_date)(new Date()),
    });
  };

  return updateMarker;
};

export default useTodayMarker;
