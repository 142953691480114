import { useCallback, useMemo } from 'react';
import { head } from 'ramda';

import { isSnapToPredictedEnd } from 'design-system/organisms/Timeline/New/helpers';

import useProjectGroups from 'hooks/useProjectGroups';

import { shouldHideEmptyBasedOnUserPref } from 'utils/projects/grouping';

import { COMPARE_SCENARIOS_SWIMLANE_PAGE } from 'constants/filters';
import { planningStages, getIntegrationTimeSpan, makeProjectMapper } from 'utils/swimlane/new';

const hasTimeline = project => {
  if (!project.start_date_estimates || !project.end_date_estimates) {
    return false;
  }

  return (project.integrationProgress && project.integrationProgress.endDate) || project.deadline;
};

/**
 * Custom hook responsible for the groups generation based on the filtered projects and metadata.
 * */
const useCompareScenariosSwimlaneGroups = (filteredMetadata, timelineConfig, customAllProjectsByLayer) => {
  const {
    lsState: { hideEmptyLane = true, selectedColorBy, snapToGridOn, showItemsWithoutTimeline, displayMilestone },
    selectedGroupOptions,
    hasTopMilestoneLane,
  } = timelineConfig;

  // Project filter by snap to grid for predicted end date, if active should only add the items with a integration end date
  const projectSnapPredictedEndFilter = useCallback(
    project => {
      const integrationTimespan = project?.integrationProgress ? getIntegrationTimeSpan(project.integrationProgress) : null;

      return !isSnapToPredictedEnd(snapToGridOn) || (isSnapToPredictedEnd(snapToGridOn) && integrationTimespan?.endDate);
    },
    [snapToGridOn],
  );

  // Project filter by show items without timeline
  const projectShowItemsWithoutTimelineFilter = useCallback(
    project => showItemsWithoutTimeline || hasTimeline(project),
    [showItemsWithoutTimeline],
  );

  // Join all project filters
  const projectsFilter = useCallback(
    project => {
      const hasValidPlanningStage = !!planningStages[project.planningStage];

      return hasValidPlanningStage && projectSnapPredictedEndFilter(project) && projectShowItemsWithoutTimelineFilter(project);
    },
    [projectSnapPredictedEndFilter, projectShowItemsWithoutTimelineFilter, displayMilestone, hasTopMilestoneLane],
  );

  const projectMapper = useMemo(() => makeProjectMapper(selectedColorBy), [selectedColorBy]);

  // on swimlane the second group is fixed and injected
  const selectGroups = { selectedGroup1: head(selectedGroupOptions) };

  const projectGroups = useProjectGroups({
    ...selectGroups,
    page: COMPARE_SCENARIOS_SWIMLANE_PAGE,
    onlyProjects: false,
    hideEmptyHandler: shouldHideEmptyBasedOnUserPref,
    hideEmptyUserPref: hideEmptyLane,
    projectsFilters: [projectsFilter],
    // projectsFilters: [projectsFilter, milestoneFilter],
    projectMapper,
    customMetadata: filteredMetadata,
    customAllProjectsByLayer,
  });

  return projectGroups;
};

export default useCompareScenariosSwimlaneGroups;
