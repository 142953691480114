import React from 'react';
import styled from 'styled-components';

import GridMenuItem from 'design-system/molecules/GridMenuItem';

const GridMenu = ({ items, selectedItemId, onItemClick }) => {
  return (
    <Container>
      {items.map((item, i) => (
        <GridMenuItem key={item.id} item={item} selected={item.id === selectedItemId} onClick={onItemClick} />
      ))}
    </Container>
  );
};

export default GridMenu;

const Container = styled.div`
  display: grid;
  grid-template-columns: min-content min-content min-content;
  gap: ${({ theme }) => theme.spacing.unit * 2}px;
`;
