import { pipe, prop, defaultTo, path } from 'ramda';

const WORKSPACE_PORTFOLIO_ITEM_TYPES = 'workspacePortfolioItemTypes';

const NAME = 'Name';
const WORK_ITEM = 'Work Item';

const defaultToEmptyObject = defaultTo({});

/**
 * @function getRallyPortfolioItemTypesFromOrgIntegrationData
 *
 * Gets Rally workspacePortfolioItemTypes from Org Integration Data
 * Returns Object where the key is the Idea (0) or Initiative (1) layer and the value is the Rally Portfolio Item Type:
 * {
 *   0: { ObjectUUID: {{ uuid }}, TypePath: '/portfolioitem/feature', IDPrefix: 'FE', Name: 'Feature', ... },
 *   1: { ObjectUUID:{{ uuid }}, TypePath: /portfolioitem/initiative'', IDPrefix: 'IN', Name: 'Initiative', ... },
 * }
 *
 * @param {Object} orgIntegrationData
 * @returns {Object}
 */
export const getRallyPortfolioItemTypesFromOrgIntegrationData = pipe(prop(WORKSPACE_PORTFOLIO_ITEM_TYPES), defaultToEmptyObject);

export const getRallyPortfolioItemTypeName = (orgIntegration, layer) => {
  const workspacePortfolioItemTypes = getRallyPortfolioItemTypesFromOrgIntegrationData(orgIntegration?.data);

  return pipe(path([layer, NAME]), defaultTo(WORK_ITEM))(workspacePortfolioItemTypes);
};
