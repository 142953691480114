import moment from 'moment';
import { createSelector } from 'reselect';
import head from 'lodash/head';

import { getOrganizationSystemFieldsNames } from 'store/organization';
import { getIsDodActive } from 'store/accessControl/selectors';
import { getNestedProjectGroups } from 'store/projects/helpers/groupSelectors';

import { getPlanningStages, getStatusColors } from 'store/projects/helpers/groupOptions';

import getSystemFieldName from 'utils/getSystemFieldName';
import { getGroupOptions } from './groupOptions';
import { getGroupingObjects } from './groupingObjects';

import { materialColors } from 'design-system/themes/default';
import { hideEmptyBasedOnPrefOrArchived } from 'utils/grouping';

import { selectSelectedSnapshotsToCompareWithoutProjects } from 'features/RoadmapHistory/store/selectors';
import { CURRENT_DATA_ID, CURRENT_DATA_TITLE } from 'features/RoadmapHistory/constants';

const getDefaultGroupDataForUndefined = ({ id = CURRENT_DATA_ID, name = CURRENT_DATA_TITLE } = {}, color) => ({
  roadmap_history_snap_id: id,
  roadmapHistorySnaps: { id, name, color: color ?? materialColors.mediumPurple },
});

const getGroupOptionsSelector = createSelector(
  getOrganizationSystemFieldsNames,
  getIsDodActive,
  (_, options) => options,
  (systemFieldsNames, isDodActive, options) => {
    const customGetSystemFiedName = name => getSystemFieldName(name, systemFieldsNames, false);

    return getGroupOptions({
      getSystemFieldName: customGetSystemFiedName,
      isDodActive,
      ...options,
    });
  },
);

/**
 * Creates a selector that retrieves all the data from the store needed for grouping.
 *
 * @returns A selector with all the data objects needed for grouping.
 */
const getDefaultGroupingObjects = createSelector(
  (state, metadataWithSnapMetadata = {}) => metadataWithSnapMetadata,
  state => selectSelectedSnapshotsToCompareWithoutProjects(state),
  (metadataWithSnapMetadata, roadmapHistorySnaps) => {
    return {
      ...metadataWithSnapMetadata,
      statusColors: getStatusColors(),
      planningStages: getPlanningStages(),
      roadmapHistorySnaps: roadmapHistorySnaps.map(snap => ({ ...snap, title: snap.name })),
    };
  },
);

const formatGroupedDate = (date, selectedGroupByDates) => {
  switch (selectedGroupByDates?.groupBy) {
    case 'month': {
      return {
        id: moment(date).format('YYYY-MM'),
        title: moment(date).format('YYYY-MM'),
        start: moment(date).startOf('month'),
        end: moment(date).endOf('month'),
      };
    }
    case 'year': {
      return {
        id: moment(date).format('YYYY'),
        title: moment(date).format('YYYY'),
        start: moment(date).startOf('year'),
        end: moment(date).endOf('year'),
      };
    }
    default: {
      return {
        id: `${moment(date).format('YYYY')}-Q${moment(date).format('Q')}`,
        title: `${moment(date).format('YYYY')}-Q${moment(date).format('Q')}`,
        start: moment(date).startOf('quarter'),
        end: moment(date).endOf('quarter'),
      };
    }
  }
};

/**
 * Get all groups based on the filtered projects and the selected fields for grouping.
 *
 * @param {Object} groupingOptions
 * @param {Object} groupingOptions.selectedGroup1 The info about the field to be used for groups of level 1
 * @param {Object} groupingOptions.selectedGroup2 The info about the field to be used for groups of level 2
 * @param {Object} groupingOptions.selectedGroup3 The info about the field to be used for groups of level 3
 * @param {String} groupingOptions.page The page filter
 * @param {Boolean} groupingOptions.onlyProjects The flag to includes only projects
 * @param {Boolean} groupingOptions.hideEmpty If active should hide groups without elements
 * @param {Function} groupingOptions.hideEmptyWith use function to evaluate active should hide groups without elements
 * @param {Boolean} groupingOptions.withHierarchy If the result should consider the project hierarchy, meaning that
 * the projects should not be duplicated as children of other projects and also exists in the specific layer
 * @param {Function} groupingOptions.projectsFilters An array of filter functions to apply to all the projects
 * @param {Function} groupingOptions.projectMapper A mapper function to apply to the projects
 * @param {Function} groupingOptions.customMetadata A custom metadata object to override the store defaults
 * @param {Function} groupingOptions.customAllProjectsByLayer A custom set of projects by layer to be considered for grouping
 * @param {Boolean} groupingOptions.applyPageFiltersToMetadataGroup Use page filters to determine relevant metadata
 * @param {Object} groupingOptions.groupFilterFunctions Object of functions that has keys referring to group keys that need
 * to be filtered, mainly for the purpose of filtering groups if they have a hierarchal relationship
 * @returns The groups hierarchy structure with the three level groups
 */
const selectRoadmapHistoryProjectGroups = createSelector(
  (state, { customAllProjectsByLayer } = {}) => {
    return customAllProjectsByLayer || [];
  },
  (state, { metadataWithSnapMetadata } = {}) => getDefaultGroupingObjects(state, metadataWithSnapMetadata),
  state => state.organization.organization.has_bet,
  (_, groupingOptions = {}) => groupingOptions,
  (
    allProjects,
    defaultGroupingObjects,
    hasBets,
    {
      selectedGroup1 = null,
      selectedGroup2 = null,
      selectedGroup3 = null,
      hideEmptyUserPref = true,
      withHierarchy = false,
      projectMapper = null,
      groupFilterFunctions = null,
    },
  ) => {
    const groups = [selectedGroup1, selectedGroup2, selectedGroup3];

    const selectedGroupByDates = head([selectedGroup1, selectedGroup2, selectedGroup3].filter(group => group?.type === 'dates'));

    const hasDateGrouping = !!selectedGroupByDates;

    const groupedDates = hasDateGrouping
      ? allProjects
          .map(p => p[selectedGroupByDates?.field])
          .sort((a, b) => moment(a) - moment(b))
          .filter(d => moment(d).isValid())
          .map(d => formatGroupedDate(d, selectedGroupByDates))
          .reduce((result, d) => {
            result[d.title] = d;
            return result;
          }, {})
      : null;

    const groupingObjects = getGroupingObjects({
      defaultGroupingObjects,
      groupedDates,
    });

    const hideEmptyWith = (_, groupObject) => hideEmptyBasedOnPrefOrArchived({ groupObject, hideEmptyUserPref });

    const nestedProjectGroups = getNestedProjectGroups(allProjects, groupingObjects, groups, {
      groupFilterFunctions,
      hideEmptyWith,
      projectMapper,
      withHierarchy,
    });

    // Add groupData to undefined group. It is actually current data group
    const defaultUndefinedGroup = nestedProjectGroups.find(group => group?.title === 'Undefined');

    if (defaultUndefinedGroup) {
      defaultUndefinedGroup.groupData = getDefaultGroupDataForUndefined();
    }

    return nestedProjectGroups;
  },
);

export { formatGroupedDate, getGroupOptionsSelector, selectRoadmapHistoryProjectGroups };
