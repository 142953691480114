import React, { useEffect } from 'react';
import { not } from 'ramda';
import isFunction from 'lodash/isFunction';

import Typography from '@material-ui/core/Typography';

import DndColorBox from 'design-system/molecules/DndColorBox/index';
import DndAtoms from 'design-system/atoms/DndAtoms/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';

import filterDndItemActions from 'design-system/utils/filterDndItemActions';
import theme from '../../theme';

const { MenuIcon, Checkbox, MenuText } = DndAtoms;

const exist = Boolean;

export default ({
  item,
  onChangeColor,
  checkable,
  onClickItem,
  colorable,
  onSelectItem,
  rootList,
  selected,
  showIndeterminate,
  checkedByDefault = false,
  showTooltip,
  itemRightActions = [],
  renderCustomItem,
}) => {
  const [checked, setChecked] = React.useState(selected);

  const _handleSelectItem = () => {
    setChecked(!checked);
    onSelectItem(item.id, !checked, item);
  };

  const _handleClickItem = () => {
    onClickItem(item);
  };

  useEffect(() => {
    setChecked(selected);
  }, [selected]);

  /*
   * Because the grid items is flex even if the checkable results is false should
   * include the icon space, if not the items with hidden checkbox will be
   * missaligned
   */
  const includeCheckableSpace = Boolean(checkable);
  const isItemCheckable = isFunction(checkable) ? checkable(item.id) : checkable;

  return (
    <>
      {includeCheckableSpace && (
        <MenuIcon>
          {isItemCheckable && (
            <Checkbox
              disabled={item.disabled}
              key={checked}
              checked={checkedByDefault ? not(checked) : checked}
              onClick={_handleSelectItem}
              showIndeterminate={showIndeterminate}
            />
          )}
        </MenuIcon>
      )}
      {colorable && (
        <DndColorBox
          initialColor={item.color || theme.palette.newLayout.background.lightDisable}
          onChangeColor={onChangeColor}
          disabled={item.disabled}
        />
      )}
      {!renderCustomItem && (
        <MenuText onClick={isItemCheckable ? _handleSelectItem : _handleClickItem}>
          {showTooltip ? (
            <TooltipOnOverflow text={item.tooltip ?? item.label}>
              <Typography variant={rootList ? 'body1' : 'body2'}>{item.label}</Typography>
            </TooltipOnOverflow>
          ) : (
            <Typography variant={rootList ? 'body1' : 'body2'}>{item.label}</Typography>
          )}
        </MenuText>
      )}
      {exist(renderCustomItem) && renderCustomItem(item)}
      {!!itemRightActions.length &&
        filterDndItemActions(itemRightActions, item).map(action => (
          <MenuIcon
            className="right-action-icon"
            onClick={() => !item.disabled && action.onClick(item)}
            {...(action.testId ? { 'data-testid': action.testId } : {})}
          >
            {action.renderIcon(item)}
          </MenuIcon>
        ))}
    </>
  );
};
