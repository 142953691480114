import React from 'react';

const componentHOC = Component => {
  return props => {
    const [currentTarget, setCurrentTarget] = React.useState();
    const [position, setPosition] = React.useState({});
    const [dependencies, setDependencies] = React.useState([]);

    const _clickOnDependeciesArrow = e => {
      if (!e.target || !e.target.classList.contains('gantt_task_line')) return;
      if (!e.target.clientHeight) return;

      let type;

      if (e.target.classList.contains('dependenciesBefore') && e.offsetX < 0) {
        type = 'blockedBy';
      } else if (e.target.classList.contains('dependenciesAfter') && e.offsetX > e.target.offsetWidth) {
        type = 'blocks';
      }

      setCurrentTarget({ type, element: e.target });
      setPosition({
        top: e.clientY,
        left: e.clientX + 288 > window.innerWidth && type === 'blocks' ? e.clientX - 288 : e.clientX,
      });
      e.stopPropagation();
    };

    React.useEffect(() => {
      document.body.addEventListener('click', _clickOnDependeciesArrow);

      return () => {
        window.removeEventListener('click', _clickOnDependeciesArrow);
      };
    }, []);

    const projectId = currentTarget && currentTarget.element && +currentTarget.element.getAttribute('task_id');

    React.useEffect(() => {
      if (!projectId) return;

      const { links, data } = props.data;
      const projectLinks =
        currentTarget.type === 'blockedBy'
          ? links.filter(l => l.target === projectId).map(l => l.source)
          : links.filter(l => l.source === projectId).map(l => l.target);
      const projectDependencies = projectLinks.map(id => data.find(p => p.id === id));

      setDependencies(projectDependencies);
    }, [projectId]);

    const onClickAway = () => setCurrentTarget(null);

    return (
      <Component
        {...props}
        currentTarget={currentTarget && currentTarget.element}
        position={position}
        onClickAway={onClickAway}
        dependencies={dependencies}
        type={currentTarget && currentTarget.type}
      />
    );
  };
};

export default componentHOC;
