// External dependencies
import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Slider from 'rc-slider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment-timezone';

import formatDateTime from 'utils/dates/formatDateTime';

// Dragonboat dependencies
import DateRangeMode from 'components/DateRangeMode';
import DateRange from 'components/DateRange';
import { getUserName, statusColors } from 'utils';
import Dialog from 'design-system/molecules/Dialog/index';
import SecondaryColorPicker from 'design-system/atoms/SecondaryColorPicker/index';
import Checkbox from 'design-system/atoms/Checkbox/index';

import { materialColorsArr, materialColorsAlt } from 'design-system/themes/default';
import { ConfirmDialog } from 'components/gridCommon';
import LoadableUsersAutocomplete from 'containers/LoadableUsersAutocomplete';

import { dafaultFormData, parseTaskToForm, parseForm, startDateChange, endDateChange } from './utils';
import TaskDialogHeader from './TaskDialogHeader';
import TaskHistory from '../TaskHistory';

const ProjectTaskDialog = ({
  task,
  open,
  onOpen,
  onClose,
  projectId,
  updateTask,
  createTask,
  deleteTask,
  onlyTask,
  meta,
  users,
}) => {
  const [showDialog, setShowDialog] = useState(open);
  const [isCloning, setIsCloning] = useState(false);
  const [metaData, setMetaData] = useState(meta);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const defaultData = task ? parseTaskToForm(task) : dafaultFormData;
  const [formData, setFormData] = useState(defaultData);
  const [selectedTask, setSelectedTask] = useState(task);
  const [selectedProjectId, setSelectedProjectId] = useState(projectId);
  const [currentTab, setCurrentTab] = useState('details');
  const [showColorPicker, setShowColorPicker] = useState(false);

  const openTrigger = (t, pId, _meta) => {
    setSelectedTask(t);
    setSelectedProjectId(pId);
    setMetaData(_meta);
    setShowDialog(true);
  };

  useEffect(() => onOpen(openTrigger), []);
  useEffect(() => {
    setShowDialog(open);
    setCurrentTab('details');
  }, [open]);
  useEffect(() => setMetaData(meta), [meta]);
  useEffect(() => setSelectedProjectId(projectId), [projectId]);
  useEffect(() => {
    const data = selectedTask ? parseTaskToForm(selectedTask) : dafaultFormData;

    setFormData(data);
  }, [showDialog]);
  useEffect(() => setSelectedTask(task), [task]);

  const { type, timeline } = formData;
  const isNew = !selectedTask || !selectedTask.id;
  const formValid = !!formData.title;
  const hasChildren = !!(selectedTask && selectedTask.subtasks && selectedTask.subtasks.length);
  const title = isNew ? 'New Assignment' : selectedTask.title;
  const changeFormValue = (field, transformValue) => v => {
    setFormData({ ...formData, [field]: transformValue ? transformValue(v) : v });
  };
  const closeDialog = () => {
    setShowDialog(false);
    setIsCloning(false);
    setCurrentTab('details');
    if (onClose) onClose(formData);
  };
  const _renderHealthAndTaskColor = () => (
    <div>
      <FormControl fullWidth margin="dense">
        <FormLabel component="legend">Health</FormLabel>
        <RadioGroup
          aria-label="health"
          name="health"
          value={formData.statusColor}
          onChange={changeFormValue('statusColor', e => e.target.value)}
          style={{ flexDirection: 'row' }}
        >
          {statusColors.map(color => (
            <FormControlLabelColor
              key={color.value}
              color={color.label.toLowerCase()}
              value={color.value}
              control={<Radio />}
              label={color.label || 'N/A'}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth margin="dense">
        <FormLabel component="legend" focused={showColorPicker}>
          Task Color
        </FormLabel>
        <ColorPickerRect style={{ backgroundColor: formData.customColor }} onClick={() => setShowColorPicker(!showColorPicker)} />
        {showColorPicker && (
          <SecondaryColorPicker
            colors={materialColorsArr}
            color={formData.customColor}
            onChange={changeFormValue('customColor', color => color.hex)}
            handleClose={() => setShowColorPicker(false)}
          />
        )}
      </FormControl>
    </div>
  );
  const _renderUpdateDetails = () =>
    selectedTask && (
      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="dense">
            <TextField label="Updated by" value={getUserName(selectedTask.updatedBy)} disabled />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="dense">
            <TextField label="Updated at" value={formatDateTime(selectedTask.updated_at)} disabled />
          </FormControl>
        </Grid>
      </Grid>
    );
  const _renderDependencies = () => {
    if (!metaData.taskDependencies) return '';

    const { dependentOn, dependedBy } = metaData.taskDependencies;
    const renderDependency = link => {
      let text = '';
      const { entity, parentProject } = link;

      if (!entity) {
        return text;
      }
      if (entity.type === 'task') {
        const parentTitle = parentProject ? parentProject.title : 'Unknown Idea';

        text = `(DB-${parentProject.id}) `;
        text += parentProject.roadmap ? `${parentProject.roadmap.title} | ` : '';
        text += `${parentTitle} | `;
      } else {
        text = `(DB-${entity.id}) `;
        text += entity.roadmap ? `${entity.roadmap.title} | ` : '';
      }
      text += entity.title;
      text += entity.ownerName ? ` | ${entity.ownerName}` : '';
      return link.color === materialColorsAlt.red ? <span style={{ color: materialColorsAlt.red }}>{text}</span> : text;
    };

    return (
      <div>
        <FormControl fullWidth margin="dense">
          <TextField label="Title" value={title} disabled />
        </FormControl>
        <br />
        <br />

        {!!dependentOn.length && (
          <Fragment>
            <h3 style={{ marginTop: 15, fontWeight: 'bold' }}>Is blocked by</h3>
            <List>
              {dependentOn.map(link => (
                <ListItem key={link.source}>
                  <ListItemText primary={renderDependency(link)} />
                </ListItem>
              ))}
            </List>
          </Fragment>
        )}

        {!!dependedBy.length && (
          <Fragment>
            <h3 style={{ fontWeight: 'bold' }}>Blocks</h3>
            <List>
              {dependedBy.map((link, index) => (
                <ListItem key={link.target}>
                  <ListItemText primary={renderDependency(link)} />
                </ListItem>
              ))}
            </List>
          </Fragment>
        )}
      </div>
    );
  };
  const _cloneTask = () => {
    setShowDialog(false);
    setTimeout(() => setShowDialog(true), 500);
    setIsCloning(true);
    setSelectedProjectId(selectedTask.project_id);
    setSelectedTask({
      ...selectedTask,
      id: null,
      title: `${selectedTask.title} [CLONE]`,
    });
  };

  return (
    <Wrapper>
      <Dialog
        data-test="project-task-dialog"
        open={showDialog}
        onClose={closeDialog}
        maxWidth="md"
        scroll="paper"
        fullWidth
        header={
          <TaskDialogHeader
            isNew={isNew}
            title={title}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            onClose={closeDialog}
          />
        }
        actions={
          <Fragment>
            {!isNew && (
              <Button
                color="secondary"
                data-test="delete-task-button"
                onClick={() => setShowDeleteConfirm(true)}
                style={{ position: 'absolute', left: 20 }}
              >
                delete
              </Button>
            )}
            {!isNew && !isCloning && (
              <Button
                color="primary"
                data-test="clone-task-button"
                style={{ position: 'absolute', left: 100 }}
                onClick={_cloneTask}
              >
                clone
              </Button>
            )}
            {isNew && isCloning && (
              <Button
                color="primary"
                data-test="save-clone-task-button"
                style={{ position: 'absolute', left: 20 }}
                onClick={() => {
                  const data = {
                    ...parseForm(formData),
                    project_id: selectedProjectId,
                  };

                  createTask(data).then(_cloneTask);
                }}
              >
                save &nbsp;<Small>and</Small>&nbsp; clone another
              </Button>
            )}
            {isNew && !isCloning && (
              <FormControlLabel
                label="Create another"
                aria-label="createAnother"
                data-test="create-another-task"
                control={<Checkbox />}
                checked={formData.createAnother}
                value="createAnother"
                onChange={changeFormValue('createAnother', e => e.target.checked)}
              />
            )}
            <Button
              color="primary"
              data-test="save-task-button"
              onClick={async () => {
                if (!isNew) {
                  updateTask(parseForm(formData)).then(closeDialog);
                } else {
                  const data = {
                    ...parseForm(formData),
                    project_id: selectedProjectId,
                  };

                  createTask(data).then(() => {
                    if (formData.createAnother) {
                      setFormData(dafaultFormData);
                    } else {
                      closeDialog();
                    }
                  });
                }
              }}
              disabled={!formValid}
            >
              save
            </Button>
            <Button onClick={closeDialog} data-test="cancel-button">
              cancel
            </Button>
          </Fragment>
        }
      >
        <DialogWrapper>
          {currentTab === 'subTasks' && (
            <Fragment>
              <FormControl fullWidth margin="dense">
                <TextField label="Title" value={formData.title} disabled />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <TextField
                  multiline
                  rows="15"
                  label="Put each sub-task on a new line"
                  value={formData.taskTitles}
                  onChange={changeFormValue('taskTitles', e => e.target.value)}
                />
              </FormControl>
            </Fragment>
          )}

          {currentTab === 'dependencies' && _renderDependencies()}

          {currentTab === 'history' && <TaskHistory taskId={selectedTask.id} />}

          {currentTab === 'details' && (
            <Grid container spacing={16}>
              {!onlyTask && isNew && (
                <Grid item xs={6}>
                  <FormControl required fullWidth margin="dense" style={{ marginBottom: '-10px' }}>
                    <FormLabel component="legend">Type</FormLabel>
                    <RadioGroup
                      aria-label="task type"
                      name="type"
                      value={type}
                      onChange={changeFormValue('type', e => e.target.value)}
                      style={{ flexDirection: 'row' }}
                    >
                      <FormControlLabel value="task" control={<Radio disabled={!isNew} color="primary" />} label="Task" />
                      <FormControlLabel
                        value="milestone"
                        control={<Radio disabled={!isNew} color="primary" />}
                        label="Milestone"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={6}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    name="title"
                    label="Assignment Name"
                    value={formData.title}
                    onChange={changeFormValue('title', e => e.target.value)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <DateRangeMode
                  // hideModes={type === 'milestone'}
                  mode={timeline}
                  onChange={changeFormValue('timeline', e => e.target.value)}
                />
              </Grid>

              <Grid item xs={6}>
                <DateRange
                  startDate={formData.startDate}
                  endDate={formData.endDate}
                  duration={formData.duration}
                  onStartDateChange={startDateChange(formData, setFormData)}
                  onDurationChange={e =>
                    setFormData({
                      ...formData,
                      duration: e.target.value,
                      endDate: moment(formData.startDate, 'YYYY/MM/DD').addDuration(e.target.value, 'days'),
                    })
                  }
                  onEndDateChange={endDateChange(formData, setFormData)}
                  showDuration
                  showEndDate
                  disableDuration={hasChildren || timeline === 'endDate'}
                  disableEndDate={hasChildren || timeline === 'duration'}
                />
              </Grid>

              <Grid item xs={6}>
                <Grid container spacing={8}>
                  <Grid item xs={8}>
                    <FormControl fullWidth margin="dense" style={{ marginTop: 3 }}>
                      <LoadableUsersAutocomplete
                        onValueChange={changeFormValue('owner_id')}
                        label="Owner"
                        value={formData.owner}
                        suggestions={users}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth margin="dense" style={{ marginTop: 0 }}>
                      <TextField
                        type="number"
                        inputProps={{ min: 0, max: 100 }}
                        label="Allocation (%)"
                        value={formData.ownerAllocation}
                        onChange={changeFormValue('ownerAllocation', e => +e.target.value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={8} direction="row" alignItems="flex-end">
                  <Grid item xs={6}>
                    <FormControl fullWidth margin="dense">
                      <TextField
                        multiline
                        rows="5"
                        label="Details"
                        value={formData.details}
                        onChange={changeFormValue('details', e => e.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    {_renderHealthAndTaskColor()}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={16}>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="dense">
                    <FormLabel component="legend">Progress ({Math.floor(formData.progress * 100)}%)</FormLabel>
                    <Slider
                      min={0}
                      max={1}
                      step={0.01}
                      defaultValue={5}
                      value={formData.progress}
                      onChange={changeFormValue('progress')}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  {_renderUpdateDetails()}
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogWrapper>
      </Dialog>

      <ConfirmDialog
        isOpen={showDeleteConfirm}
        row={{}}
        data-test="delete-task-confirm"
        onCancel={() => setShowDeleteConfirm(false)}
        onDelete={() => {
          setShowDeleteConfirm(false);
          deleteTask(selectedTask.id).then(closeDialog);
        }}
        title="Delete Assignment"
        text="Are you sure you want to delete this assignment?"
      />
    </Wrapper>
  );
};

ProjectTaskDialog.propTypes = {
  onOpen: PropTypes.func,
  onlyTask: PropTypes.bool,
  task: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  projectId: PropTypes.number,
  updateTask: PropTypes.func.isRequired,
  createTask: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  meta: PropTypes.object,
};

ProjectTaskDialog.defaultProps = {
  onOpen: () => {},
  onlyTask: false,
  projectId: null,
  open: false,
  task: null,
  onClose: null,
  meta: {},
};

export default ProjectTaskDialog;

const Wrapper = styled.div``;

const DialogWrapper = styled.div`
  min-height: 560px;
`;

const Small = styled.small`
  text-transform: lowercase;
`;

const ColorPickerRect = styled.div`
  height: 1rem;
  margin-top: 5px;
  cursor: pointer;
  border-radius: 2px;
`;

const FormControlLabelColor = styled(FormControlLabel)`
  &&&& {
    span {
      ${({ color }) =>
        color &&
        `
        color: ${materialColorsAlt[color]};
      `}
    }
  }
`;
