import { map, find } from 'ramda';

const JIRA = 'JIRA';

const idMatchesAndTypeIsJira = (integration, id) => integration.integrationType === JIRA && +integration.id === +id;

const mapIfIdMatches = (stateIntegrations, orgIntegrationId, f) =>
  map(
    eachIntegration => (idMatchesAndTypeIsJira(eachIntegration, orgIntegrationId) ? f(eachIntegration) : eachIntegration),
    stateIntegrations,
  );

/**
 * @function setHasSurnameFirstOnOrgIntegration
 *
 * returns the existing orgIntegrations array with the has_surname_first_on_jira value of a specific orgIntegration
 * changed to the value passed as argument
 *
 * @param  {Array} stateIntegrations
 * @param  {Integer} orgIntegrationId
 * @param  {Boolean} value
 * @returns {Array}
 */
const setHasSurnameFirstOnOrgIntegration = (stateIntegrations, orgIntegrationId, value) =>
  mapIfIdMatches(stateIntegrations, orgIntegrationId, integration => ({ ...integration, has_surname_first_on_jira: value }));

/**
 * @function getHasSurnameFirstFromOrgIntegration
 *
 * returns the has_surname_first_on_jira value of a giver orgIntegration
 *
 * @param  {Array} stateIntegrations
 * @param  {Integer} orgIntegrationId
 * @returns {Boolean}
 */
const getHasSurnameFirstFromOrgIntegration = (stateIntegrations, orgIntegrationId) =>
  find(eachIntegration => idMatchesAndTypeIsJira(eachIntegration, orgIntegrationId), stateIntegrations)
    ?.has_surname_first_on_jira;

/**
 * @function setOrgIntegration
 *
 * returns the existing orgIntegrations array with a specific orgIntegration
 * changed to the value passed as argument
 *
 * @param  {Array} stateIntegrations
 * @param  {Integer} orgIntegrationId
 * @param  {Boolean} newOrgIntegation
 * @returns {Array}
 */
const setOrgIntegration = (stateIntegrations, orgIntegrationId, newOrgIntegation) =>
  mapIfIdMatches(stateIntegrations, orgIntegrationId, () => newOrgIntegation);

export { setHasSurnameFirstOnOrgIntegration, setOrgIntegration, getHasSurnameFirstFromOrgIntegration };
