import React from 'react';
import styled from 'styled-components';

import isPlainObject from 'utils/isPlainObject';

export default ({ data }) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          {data && data.groups && data.groups.map(group => <HeaderCol>{group.title}</HeaderCol>)}
          <HeaderCol>Idea</HeaderCol>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data &&
          data.rows &&
          data.rows.map(row => (
            <TableRow>
              {data &&
                data.groups &&
                data.groups.map((group, index) => (
                  <Cell
                    isTitle={index === 0}
                    empty={!row[index]}
                    open={isPlainObject(row[index]) && row[index].childrenCount > 1}
                  >
                    {isPlainObject(row[index]) ? row[index].title : row[index]}
                  </Cell>
                ))}
              <Cell>{isPlainObject(row[data.groups.length]) ? row[data.groups.length].title : row[data.groups.length]}</Cell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const Table = styled.table`
  border-bottom: 1px solid #e3e3e3;
`;

const TableHeader = styled.thead`
  position: sticky;
  top: 0;
  z-index: 750;
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr``;

const HeaderCol = styled.th`
  text-align: left;
  padding: 10px 10px 10px 15px;
  line-height: 24px;
  color: #131c23;
  background: ${({ theme }) => theme.palette.background.alabaster};
  border: 1px solid #e3e3e3;
  min-width: 300px;
  max-width: 300px;
  font-weight: 500;
  font-size: 16px;
`;

const Cell = styled.td`
  line-height: 24px;
  color: #6b6b6b;
  min-width: 300px;
  max-width: 300px;
  border: 1px solid #e3e3e3;
  padding: 10px 10px 10px 15px;

  ${({ empty }) =>
    empty &&
    `

    border-bottom: none;
    border-top: none;

  `}
  ${({ open }) =>
    open &&
    `

    border-bottom: none;

  `}
  ${({ close }) =>
    close &&
    `

    border-bottom:  1px solid #e3e3e3;

  `}
  ${({ isTitle }) =>
    isTitle &&
    `

    font-weight: 500;
    color: #131c23;
    font-size: 16px;

  `}
`;
