import reduceReducers from 'reduce-reducers';
import { mergeRight as merge } from 'ramda';
import { meta } from 'joi/lib/types/lazy';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import {
  UPDATE_DASHBOARDS_STATE,
  UPDATE_ACTIVE_DASHBOARD,
  FETCH_RELEASE_DASHBOARDS_PROJECTS,
  FETCH_RELEASE_DASHBOARDS_PROJECTS_PENDING,
  FETCH_RELEASE_DASHBOARDS_PROJECTS_FULFILLED,
  SET_DASHBOARD_IN_EDIT_MODE,
} from './types';
import { SAVE_USER_VIEW_FULFILLED, CLONE_USER_VIEW_FULFILLED } from '../userViews';
import { DASHBOARDS_PAGE } from 'src/constants/filters';
import { getDefaultProgressSummaryDates } from 'routes/Dashboard/Dashboards/Charts/utils';

const exist = Boolean;

const { startDate, endDate } = getDefaultProgressSummaryDates();

const dashboardsInitialState = {
  progressStartDate: startDate,
  progressEndDate: endDate,
};

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  FETCH_RELEASE_DASHBOARDS_PROJECTS,
]);

const initialState = {
  ...dashboardsInitialState,
  ...thunksInitialState,
};

const dashboardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_IN_EDIT_MODE: {
      return {
        ...state,
        inEditMode: action.payload,
      };
    }
    case UPDATE_ACTIVE_DASHBOARD:
      return {
        ...state,
        activeDashboard: action.payload,
      };
    case UPDATE_DASHBOARDS_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_RELEASE_DASHBOARDS_PROJECTS_PENDING:
      return merge(state, { releaseDashboardsProjects: [] });
    case FETCH_RELEASE_DASHBOARDS_PROJECTS_FULFILLED:
      const { projectsWithEstimatesData } = action.payload;

      return merge(state, { releaseDashboardsProjects: projectsWithEstimatesData });
    case SAVE_USER_VIEW_FULFILLED:
      const { data } = action.payload;

      if (!exist(data)) return state;

      if (!exist(data.page) || data.page !== DASHBOARDS_PAGE) return state;

      const currentDashboard = meta && meta.id;

      if (!exist(currentDashboard) && exist(data.state?.dashboard)) {
        return merge(state, { activeDashboard: { ...data.state?.dashboard, id: data.id, isInEdit: false } });
      }

      return state;
    case CLONE_USER_VIEW_FULFILLED:
      return {
        ...state,
        inEditMode: true,
      };
    default:
      return state;
  }
};

const reducer = reduceReducers(initialState, dashboardsReducer, ...thunksReducers);

export default reducer;
