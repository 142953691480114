import reduceReducers from 'reduce-reducers';
import { FETCH_PERSONAS, FETCH_PERSONAS_FULFILLED } from './types';
import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';
import adapter from './entityAdapter';
import {
  DELETE_GRID_PERSONA_FULFILLED,
  SAVE_GRID_NEW_PERSONA_FULFILLED,
  SWITCH_PERSONAS_GRID_ROW_ORDER_FULFILLED,
  UPDATE_GRID_PERSONA_FULFILLED,
} from 'features/PersonasGrid/store/types';

const initialState = {
  ...adapter.getInitialState(),
  operations: bulkThunkInitialState([FETCH_PERSONAS]),
};

const personasDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PERSONAS_FULFILLED:
      return adapter.setAll(state, action.payload);
    case UPDATE_GRID_PERSONA_FULFILLED:
    case SWITCH_PERSONAS_GRID_ROW_ORDER_FULFILLED:
      return adapter.upsertOne(state, action.payload);
    case DELETE_GRID_PERSONA_FULFILLED:
      return adapter.removeOne(state, action.payload);
    case SAVE_GRID_NEW_PERSONA_FULFILLED:
      return adapter.addOne(state, action.payload);
    default: {
      return state;
    }
  }
};

const operationsReducer = getThunksReducers([FETCH_PERSONAS]);

const reducer = reduceReducers(initialState, personasDataReducer, ...operationsReducer);

export default reducer;
