import React from 'react';
import moment from 'moment-timezone';

import formatDate from 'utils/dates/formatDate';
// import addWeeks from 'utils/dates/addWeeks';
// import subtractWeeks from 'utils/dates/subtractWeeks';

const setGanttPlannedStartDisplay = plannedStartDate => {
  const plannedStartDateRef = React.useRef(plannedStartDate);

  plannedStartDateRef.current = plannedStartDate;

  return gantt => {
    gantt.addTaskLayer({
      renderer: {
        render: task => {
          let { estimated_start_date: estimatedStartDate } = task;

          if (task.dbType !== 'project') return false;

          if (estimatedStartDate && plannedStartDateRef.current) {
            const el = document.createElement('div');

            estimatedStartDate = moment(estimatedStartDate);

            el.className = 'plannedStartDate';
            const sizes = gantt.getTaskPosition(task, estimatedStartDate);

            el.style.left = `${sizes.left}px`;
            el.style.top = `${sizes.top}px`;

            el.setAttribute('title', `Planned start date: ${formatDate(estimatedStartDate)}`);
            return el;
          }
          return false;
        },
        // define getRectangle in order to hook layer with the smart rendering
        getRectangle: (task, view) => {
          const { estimated_start_date: estimatedStartDate } = task;

          if (task.dbType !== 'project') return null;

          if (estimatedStartDate && plannedStartDateRef.current) {
            return gantt.getTaskPosition(task, moment(estimatedStartDate));
          }
          return null;
        },
      },
    });

    // ================
    // GANTT WINDOW LIMITS
    // if the planned start date is far to the right, the gantt should extend its window to the right
    // gantt.attachEvent('onParse', () => {
    //   if (plannedStartDateRef.current) {
    //     gantt.eachTask(task => {
    //       let { estimated_start_date: estimatedStartDate } = task;

    //       if (estimatedStartDate && task.dbType === 'project') {
    //         estimatedStartDate = moment(estimatedStartDate);
    //         const state = gantt.getState();

    //         // if (state.min_date > estimatedStartDate) {
    //         //   gantt.config.start_date = subtractWeeks(moment(estimatedStartDate), 2);
    //         //   gantt.render();
    //         // }
    //         // if (state.max_date < estimatedStartDate) {
    //         //   gantt.config.end_date = addWeeks(moment(estimatedStartDate), 2);
    //         //   gantt.render();
    //         // }
    //       }
    //     });
    //   }
    // });
  };
};

export default setGanttPlannedStartDisplay;
