import React from 'react';

import { IDEA } from 'constants/projects';
import { ROADMAP_PAGE } from 'constants/filters';

import ColorToolbar from 'components/ColorToolbar';

import useColorLegend from 'hooks/timeline/useColorLegend';

const KEYS_TO_DISABLE_COLOR_PICKER = ['health'];
const KEYS_TO_HIDE_LEGEND = [IDEA];

const ColorLegend = ({ colorBy }) => {
  const [getLegendGroups, updateGroupColor] = useColorLegend(ROADMAP_PAGE);

  if (!colorBy || KEYS_TO_HIDE_LEGEND.includes(colorBy.key)) {
    return null;
  }

  const showPicker = !KEYS_TO_DISABLE_COLOR_PICKER.includes(colorBy.key);

  return (
    <ColorToolbar groups={getLegendGroups(colorBy)} showPicker={showPicker} onUpdateGroupColor={updateGroupColor(colorBy)} />
  );
};

export default ColorLegend;
