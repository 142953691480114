import { getUserName } from 'utils';
import { returnsTrueIfKeyIsNotEscOrTab } from 'utils/agGrid';
import AgGridAutocomplete from 'components/AgGridAutocomplete/new';
import LoadableUsersAutocomplete from 'containers/LoadableUsersAutocomplete';
import { stringComparator } from '../comparators';
import { AutocompleteCellRenderer } from '../cellRenderers';
import { applyCheckIsEditable } from '../helpers';

const getOwnerColumnDef = ({ editable, users = [], field = 'owner_id', ...remainingOptions }) => ({
  field,
  headerName: 'Owner',
  editable: applyCheckIsEditable(editable),
  cellRenderer: AutocompleteCellRenderer,
  cellEditorPopup: true,
  cellEditor: AgGridAutocomplete,
  cellEditorParams: params => {
    return {
      autoFocus: true,
      options: () => {
        const options = users;

        if (params.colDef.filterSuggestions) {
          return params.colDef.filterSuggestions(options, params);
        }

        return options;
      },
      autocompleteComponent: LoadableUsersAutocomplete,
      dataMapper: user => ({ value: getUserName(user), label: getUserName(user) }),
    };
  },
  enableRowGroup: true,
  breakwords: true,
  suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
  comparator: stringComparator,
  width: 100,
  minWidth: 100,
  valueGetter: params => {
    const user = params.data?.owner_id ? getUserName(users.find(user => user.id === params.data.owner_id)) ?? null : null;

    return user || params.data?.ownerName;
  },
  valueSetter: params => {
    const foundUser = users.find(user => user.name === params.newValue);

    params.data.owner_id = foundUser?.id ?? null;

    return true;
  },
  ...remainingOptions,
});

export default getOwnerColumnDef;
