import React from 'react';
import styled from 'styled-components';

import useCustomUserFieldsSettings from './hooks/useCustomUserFieldsSettings';
import useCustomUserFields from 'src/hooks/useCustomUserFields';

import Checkbox from 'design-system/atoms/Checkbox/index';
import FluidTextField from 'design-system/organisms/FluidTextField/index';

import InputWithIcon from 'design-system/molecules/InputWithIcon';

import ProfileIcon from '@material-ui/icons/Person';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import getSystemFieldName from 'utils/getSystemFieldName';
import { getSystemFieldNameFromCustomUserFieldKey } from 'utils/customUserFields/customUserFields';

const CUSTOM_USER_FIELD_NAME = 'Custom User Field Name';
const APPLY_TO_PORTFOLIO = 'Apply to Portfolio';

export default props => {
  const { allowActions, updateCustomUserFieldValue, renameCustomUserField, systemFields } = useCustomUserFieldsSettings();
  const { getAllCustomUserFields } = useCustomUserFields();

  const handleChange = key => event => {
    event.persist();
    updateCustomUserFieldValue({ [key]: event.target.checked });
  };

  const renderSingleCustomUserFields = (key, value) => (
    <StyledTableRow key={key}>
      <TableCell>
        <StyledInputWithIcon icon={<ProfileIcon />}>
          <FluidTextField
            key={getSystemFieldName(key)}
            value={getSystemFieldName(getSystemFieldNameFromCustomUserFieldKey(key), systemFields)}
            onChange={value => renameCustomUserField(key, value)}
            disableEdit={!allowActions}
          />
        </StyledInputWithIcon>
      </TableCell>
      <ApplyCheckbox color="primary" onChange={allowActions && handleChange(key)} checked={value} />
    </StyledTableRow>
  );

  return (
    <Wrapper>
      <Container {...props}>
        {Object.entries(getAllCustomUserFields()).map(([key, value]) => {
          return renderSingleCustomUserFields(key, value);
        })}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  margin: 0 44px;
`;

const HeaderRow = styled.div`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const Container = styled(({ children }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>{CUSTOM_USER_FIELD_NAME}</TableCell>
        <TableCell align="center">
          <HeaderRow>{APPLY_TO_PORTFOLIO}</HeaderRow>
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>{children}</TableBody>
  </Table>
))`
  &&&& {
    margin-top: 50px;
  }
`;

const StyledTableRow = styled.tr`
  td {
    border-bottom: 0;
  }
`;

const StyledInputWithIcon = styled(InputWithIcon)`
  &&&& {
    border: 0;
  }
`;

const ApplyCheckbox = styled(props => (
  <TableCell align="center" {...props}>
    <Checkbox color={props.color} checked={props.checked} />
  </TableCell>
))`
  &&&& {
    width: 150px;
    border: 0;
  }
`;
