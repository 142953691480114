import React from 'react';
import styled from 'styled-components';

import Text from 'design-system/atoms/Text/index';


const Header = () => {
  return (
    <Container>
      <Text variant="h4">Customize Metric types</Text>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
