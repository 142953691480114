import React from 'react';
import styled from 'styled-components';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import Tooltip from '@material-ui/core/Tooltip';

import DragonBackButton from 'design-system/atoms/DragonBackButton/index';
import FluidTextField from 'design-system/organisms/FluidTextField/index';


const exist = Boolean;

export default function HeaderTitle({
  backButtonId,
  disableEdit,
  inputRef,
  onClickBack,
  placeholder,
  title = '',
  updateTitle,
  textFieldProps,
  inputStyles,
  pageIconRenderer,
}) {
  return (
    <Wrapper>
      <IconContainer>
        <DragonBackButton id={backButtonId} type="transparent" icon={<ArrowLeft />} onClick={onClickBack} />
      </IconContainer>
      {exist(pageIconRenderer) && pageIconRenderer()}
      <ViewLinkContainer>
        <Tooltip title={title} placement="bottom-start">
          <Link currentView inputStyles={inputStyles}>
            <FluidTextField
              disableEdit={disableEdit}
              inputRef={inputRef}
              placeholder={placeholder}
              value={title}
              onChange={updateTitle}
              {...textFieldProps}
            />
          </Link>
        </Tooltip>
      </ViewLinkContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.fontSizeMediumSmall}px;
  gap: 14px;
`;

const IconContainer = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizeMedium}px;
  margin-right: 5px;

  svg {
    color: ${({ theme }) => theme.palette.newLayout.background.primary};
  }
`;

const ViewLinkContainer = styled.div`
  margin-right: 10px;
  display: flex;
`;

const Link = styled.div`
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.fontSizeMediumSmall}px;

  input {
    font-size: ${({ theme }) => theme.typography.fontSizeMediumSmall}px;
    min-width: 300px;

    ${({ inputStyles }) => inputStyles};
  }
`;
