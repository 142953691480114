import theme from 'design-system/theme';
import { materialColors } from 'design-system/themes/default';

export const POR_DEFAULT_COLOR = materialColors.mediumPurple;
export const UNCOMITTED_SCENARIO_DEFAULT_COLOR = theme.palette.newLayout.background.dodgerBlue;

export const allColorsAvailable = [
  '#FFBA00',
  '#A4F2A1',
  '#c62828',
  '#2e7632',
  '#03dea3',
  '#ff8f00',
  '#fde000',
  '#3461AC',
  '#00B1F3',
  '#4ee12b',
  '#f36666',
  '#621919',
  '#151dfc',
];
