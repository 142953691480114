/**
 * AgGridColumnJiraIssueType Molecule Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */

import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import isFunction from 'lodash/isFunction';

import IconButton from '@material-ui/core/IconButton';

import TextDeprecated from '../../atoms/TextDeprecated';
import OptionsPopover from 'design-system/organisms/OptionsPopover';
import useOptionsForm, { OPTIONS_FORM_WIDTH } from 'hooks/useOptionsForm';
import { omit } from 'ramda';

export const AgGridColumnCustomFieldDescriptionRenderer = params => {
  // Text
  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();

    params.colDef.onLinkClick(params.data);
  };

  const hasLink = (() => {
    if (params.colDef.hasLink) {
      if (typeof params.colDef.hasLink === 'function') return params.colDef.hasLink(params);
      return params.colDef.hasLink;
    }

    return false;
  })();

  // Dropdown
  const [isOptionEditorOpen, setIsOptionEditorOpen] = useState(false);
  const clickRef = useRef(null);
  const popoverActionRef = useRef(null);
  const { colDef: { editable } = {} } = params;
  const [options, setOptions] = useState(params?.data?.data || {});

  const isDropdown = useMemo(() => {
    if (params.colDef.isDropdown) {
      if (typeof params.colDef.isDropdown === 'function') return params.colDef.isDropdown(params);
      return params.colDef.isDropdown;
    }
  }, [params]);

  const _handleEditClick = e => {
    e.stopPropagation();
    e.preventDefault();
    setIsOptionEditorOpen(true);
  };

  const isEditable = isFunction(editable) ? editable(params) : editable;

  const handleOptionsChange = useCallback((...args) => {
    setOptions(...args);
  });

  const handleOptionsRemove = useCallback(
    item => {
      if (params.data.id) params.colDef.apiUpdateCustomFieldById(params.data.id, omit([item], options));
    },
    [params.data.id, options],
  );

  const handleCloseOptionsEditor = useCallback(() => {
    setIsOptionEditorOpen(false);
    if (params.data.id) params.colDef.updateOptions(params.data.id, options);
  }, [options, params.data.id]);

  const customFieldOptions = useMemo(() => {
    return params?.data?.data || {};
  }, [params?.data]);

  const DropdownOptionsComponent = useOptionsForm({
    popoverActionRef: popoverActionRef.current,
    options: customFieldOptions,
    handleUpdateOptions: handleOptionsChange,
    disableEdit: !isEditable,
    onOptionRemove: handleOptionsRemove,
  });

  return (
    <Wrapper hasLink={hasLink} ref={clickRef}>
      <StyledText
        textColor={params.colDef.cellClass ? '' : '#303030'}
        size="small"
        breakwords={params.colDef.breakwords || params.colDef.autoHeight}
        onClick={isDropdown ? _handleEditClick : undefined}
        isDropdown={isDropdown}
      >
        {params.colDef.formatter ? params.colDef.formatter(params.data) : params.value}
      </StyledText>
      {!!hasLink && <OpenLink onClick={onClick}>{params.colDef.linkIcon}</OpenLink>}
      {!!isDropdown && (
        <StyledPopover
          isOpen={isOptionEditorOpen}
          anchorEl={clickRef.current}
          updateActionRef={ref => (popoverActionRef.current = ref)}
          onClose={handleCloseOptionsEditor}
        >
          {/*
            Apply drag and drop component to edit these options.
            The order should be save.
          */}
          {DropdownOptionsComponent}
        </StyledPopover>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  ${({ hasLink }) =>
    hasLink &&
    `
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    `}
`;

const StyledText = styled(TextDeprecated)`
  width: 100%;
  height: 100%;
  ${({ isDropdown }) =>
    isDropdown &&
    `
  &&&&:hover {
    cursor: pointer
  }
`}
`;

const OpenLink = styled(props => <IconButton {...props}>{props.children}</IconButton>)`
  &&&& {
    position: relative;
    visibility: hidden;
    padding: 3px;
    font-size: 1.125rem;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

// We need to force the transform=none since inside the popover we have a drag and drop component that can't be affected by it
const StyledPopover = styled(OptionsPopover)`
  &&&& {
    div[role='document'] {
      transform: none !important;

      max-width: ${OPTIONS_FORM_WIDTH}px;
    }
  }
`;
