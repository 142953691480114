import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';

import { getIsCreatingCustomerRequest, getCustomerRequestFormData } from 'store/customerRequests/selectors';
import { getCustomerRequestsCustomFields } from 'store/customFields/selectors';
import { getCurrentUser } from 'store/login/selectors';
import useSystemFields from 'hooks/useSystemFields';
import useUploadFileAction from 'hooks/files/useUploadFileAction';
import getFailedFileUploadErrorMessage from 'hooks/files/utils/getFailedFileUploadErrorMessage.js';
import {
  addFileToCustomerRequest,
  removeFileFromCustomerRequest,
  toggleCustomFieldsForCustomerRequest,
  togglePortalSettingsForCustomerRequest,
  watchCustomerRequest,
  unwatchCustomerRequest,
} from 'store/customerRequests/actions';
import useUploadedFileGetLink from 'hooks/files/useUploadedFileGetLink';
import useHiddenFields from 'hooks/useHiddenFields';
import CancelContinueToast from 'components/CancelContinueToast';
import { EXTERNAL_REQUESTOR } from '@dragonboat/permissions';
import useCustomRequestStatusMappingConfiguration from 'hooks/configuration/useCustomRequestStatusMappingConfiguration';
import { REQUESTS_DRAWER_TABS } from './constants';

const AUTOMATIC_UPDATE_MAX_LINKED_PROJECTS = 1;
const emptyObject = {};

export default Component => {
  return props => {
    const dispatch = useDispatch();
    const [currentTab, setCurrentTab] = useState(REQUESTS_DRAWER_TABS.DETAILS);

    const cr = useSelector(getCustomerRequestFormData) || emptyObject;
    const { enableAutomaticUpdate, isLoading, mapping } = useCustomRequestStatusMappingConfiguration();
    const isCreating = useSelector(getIsCreatingCustomerRequest);
    const [getSystemFieldName] = useSystemFields();
    const { checkFieldDisplay } = useHiddenFields();

    const hasAutomaticUpdateConfigured = useMemo(
      () => !isLoading && enableAutomaticUpdate && !isEmpty(mapping),
      [isLoading, mapping, enableAutomaticUpdate],
    );
    const canAutomaticUpdate = useMemo(
      () => hasAutomaticUpdateConfigured && cr?.projects?.length === AUTOMATIC_UPDATE_MAX_LINKED_PROJECTS,
      [hasAutomaticUpdateConfigured, cr?.projects],
    );

    const customFields = useSelector(getCustomerRequestsCustomFields);
    const currentUser = useSelector(getCurrentUser);

    const headerTitle = !isCreating ? `CR-${cr.key} ${cr.title}` : `New Customer ${getSystemFieldName('request')}`;

    const { files } = cr;

    const isWatchingStatusEnabled = useMemo(
      () => cr?.watchers?.some(watcher => watcher.id === currentUser.id),
      [cr.watchers, currentUser.id],
    );

    // TODO: PERMISSION
    const showCustomFieldsButton = ![EXTERNAL_REQUESTOR].includes(currentUser.role_id);

    const _uploadFile = async file => {
      const upload = async () => {
        if (file.size / 1024 / 1024 > 5) {
          toast('Exceeded file size of 5MB');
          return;
        }

        try {
          const result = await useUploadFileAction(file, 'customer-requests', cr);

          if (result) dispatch(addFileToCustomerRequest(cr, result));
        } catch (err) {
          toast(getFailedFileUploadErrorMessage(err));
        }
      };

      if (cr.files && cr.files.length > 0) {
        toast(
          <CancelContinueToast
            title="You may attach 1 file (up to 5MB) per item. 
              If you upload a new file, you’ll lose access to the currently attached file."
            handleContinue={upload}
            handleCancel={() => toast.dismiss()}
          />,
          {
            autoClose: 10000,
          },
        );
      } else {
        upload();
      }
    };

    const _getFileLink = async fileId => {
      const url = await useUploadedFileGetLink(fileId, 'customer-requests', cr.id);

      return url;
    };

    const _handleDeleteLink = async file => {
      dispatch(removeFileFromCustomerRequest(file, cr));
    };

    function _handlePasteEvt(event) {
      event.preventDefault();
      if (!event.clipboardData.files.length) {
        return;
      }
      const file = event.clipboardData.files[0];

      _uploadFile(file);
    }

    const _pasteListener = action => {
      switch (action) {
        case 'add':
          document.addEventListener('paste', _handlePasteEvt);
          break;
        case 'remove':
          document.removeEventListener('paste', _handlePasteEvt);
          break;
        default:
          break;
      }
    };

    const _handleToggleCustomFields = () => {
      dispatch(toggleCustomFieldsForCustomerRequest());
    };

    const _handleTogglePortalSettings = () => {
      dispatch(togglePortalSettingsForCustomerRequest());
    };

    const _handleCustomerRequestWatchStatus = () => {
      if (isWatchingStatusEnabled) {
        return dispatch(unwatchCustomerRequest(cr));
      }

      return dispatch(watchCustomerRequest(cr));
    };

    useEffect(() => {
      setCurrentTab(REQUESTS_DRAWER_TABS.DETAILS);
    }, [cr?.id]);

    useEffect(() => {
      return () => {
        document.removeEventListener('paste', _handlePasteEvt);
      };
    });

    const isMergedRequest = !!cr?.parent_id;

    return (
      <Component
        {...props}
        isMergedRequest={isMergedRequest}
        isCreating={isCreating}
        title={headerTitle}
        getSystemFieldName={getSystemFieldName}
        files={files}
        customFields={customFields}
        handleAttachFile={checkFieldDisplay('files') && _uploadFile}
        getFileLink={_getFileLink}
        handleDeleteLink={_handleDeleteLink}
        pasteListener={_pasteListener}
        onClickCustomFields={_handleToggleCustomFields}
        onClickPortalSettings={_handleTogglePortalSettings}
        showCustomFieldsButton={showCustomFieldsButton}
        onToggleWatchStatusClick={!isCreating && _handleCustomerRequestWatchStatus}
        isWatchingStatusEnabled={isWatchingStatusEnabled}
        canAutomaticUpdate={canAutomaticUpdate}
        hasAutomaticUpdateConfigured={hasAutomaticUpdateConfigured}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        customerRequest={cr}
      />
    );
  };
};
