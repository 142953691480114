import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_PORTFOLIO_OVERVIEW_SUB_ROADMAPS'),
  ...getThunkActionTypes('FETCH_PORTFOLIO_OVERVIEW_OKRS'),
  ...getThunkActionTypes('FETCH_PORTFOLIO_OVERVIEW_METRICS'),

  ...getThunkActionTypes('FETCH_PORTFOLIO_OVERVIEW_BY_ROADMAP'),
  ...getThunkActionTypes('UPDATE_PORTFOLIO_OVERVIEW_LAYOUT'),
  ...getThunkActionTypes('UPDATE_PORTFOLIO_OVERVIEW_DESCRIPTION'),
  ...getThunkActionTypes('UPDATE_PORTFOLIO_OVERVIEW_WIDGET_VISIBILITY'),
  ...getThunkActionTypes('UPDATE_PORTFOLIO_OVERVIEW_COMMITTED_STATE'),
  ...getThunkActionTypes('FETCH_PORTFOLIO_OVERVIEW_HISTORY'),
  ...getThunkActionTypes('FETCH_PORTFOLIO_OVERVIEW_HISTORY_NEXT'),
};

export const {
  FETCH_PORTFOLIO_OVERVIEW_SUB_ROADMAPS,
  FETCH_PORTFOLIO_OVERVIEW_OKRS,
  FETCH_PORTFOLIO_OVERVIEW_OKRS_FULFILLED,
  FETCH_PORTFOLIO_OVERVIEW_METRICS,
  FETCH_PORTFOLIO_OVERVIEW_METRICS_FULFILLED,

  FETCH_PORTFOLIO_OVERVIEW_BY_ROADMAP,
  FETCH_PORTFOLIO_OVERVIEW_BY_ROADMAP_PENDING,
  FETCH_PORTFOLIO_OVERVIEW_BY_ROADMAP_FULFILLED,

  UPDATE_PORTFOLIO_OVERVIEW_LAYOUT,
  UPDATE_PORTFOLIO_OVERVIEW_LAYOUT_PENDING,
  UPDATE_PORTFOLIO_OVERVIEW_LAYOUT_FULFILLED,

  UPDATE_PORTFOLIO_OVERVIEW_DESCRIPTION,
  UPDATE_PORTFOLIO_OVERVIEW_DESCRIPTION_PENDING,
  UPDATE_PORTFOLIO_OVERVIEW_DESCRIPTION_FULFILLED,

  UPDATE_PORTFOLIO_OVERVIEW_WIDGET_VISIBILITY,
  UPDATE_PORTFOLIO_OVERVIEW_WIDGET_VISIBILITY_PENDING,
  UPDATE_PORTFOLIO_OVERVIEW_WIDGET_VISIBILITY_FULFILLED,

  UPDATE_PORTFOLIO_OVERVIEW_COMMITTED_STATE,
  UPDATE_PORTFOLIO_OVERVIEW_COMMITTED_STATE_PENDING,
  UPDATE_PORTFOLIO_OVERVIEW_COMMITTED_STATE_FULFILLED,

  FETCH_PORTFOLIO_OVERVIEW_HISTORY,
  FETCH_PORTFOLIO_OVERVIEW_HISTORY_FULFILLED,
  FETCH_PORTFOLIO_OVERVIEW_HISTORY_NEXT,
  FETCH_PORTFOLIO_OVERVIEW_HISTORY_NEXT_FULFILLED,
} = actionTypes;

export const UPDATE_PORTFOLIO_OVERVIEW_LOCAL_LAYOUT = 'UPDATE_PORTFOLIO_OVERVIEW_LOCAL_LAYOUT';
export const UPDATE_PORTFOLIO_OVERVIEW_LOCAL_EDIT_MODE = 'UPDATE_PORTFOLIO_OVERVIEW_LOCAL_EDIT_MODE';
