import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL, OBJECTIVE_CORP_LEVEL, OBJECTIVE_LEVEL } from 'constants/objectives';

/**
 * Get the permission resource associated with a given Okr type.
 *
 * @param {string} level - The Okr level for which you want to retrieve the permission resource.
 * @returns {string} The permission resource associated with the provided objective type.
 */
const getPermissionResourceByObjectiveLevel = level => {
  switch (level) {
    case OBJECTIVE_CORP_LEVEL:
      return PERMISSION_RESOURCES.objectiveCorp;
    case OBJECTIVE_LEVEL:
      return PERMISSION_RESOURCES.objective;
    case KEY_RESULT_1_LEVEL:
      return PERMISSION_RESOURCES.keyResult;
    case KEY_RESULT_2_LEVEL:
      return PERMISSION_RESOURCES.keyResult2;
    default:
      return '';
  }
};

export default getPermissionResourceByObjectiveLevel;
