import React from 'react';
import { createCustomMessage } from 'react-chatbot-kit';

const ASYNC_MESSAGE_LOADER = 'asyncMessageLoader';

const ActionProvider = ({ children, setState, createChatBotMessage }) => {
  const addMessageToState = message => {
    setState(state => ({ ...state, messages: [...state.messages, message] }));
  };

  // Options.payload includes full message object from openai thread
  const addBotMessageToState = ({ message, options }) => {
    // Remove custom AsyncLoaderMessage component when message is returned
    setState(state => ({
      ...state,
      messages: state.messages.filter(message => message.type !== ASYNC_MESSAGE_LOADER),
    }));

    const botMessage = createChatBotMessage(message, { ...options, withAvatar: false });

    addMessageToState(botMessage);
  };

  const addAsyncMessageLoaderToState = () => {
    const asyncMessageLoader = createCustomMessage('', ASYNC_MESSAGE_LOADER);

    addMessageToState(asyncMessageLoader);
  };

  return (
    <div>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          actions: {
            addMessageToState,
            addBotMessageToState,
            addAsyncMessageLoaderToState,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
