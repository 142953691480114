const SEARCH_KEYS = ['path', 'name', 'page', 'description'];

export default (views, query) => (query ? views.filter(view => viewMatchesQuery(view, query)) : views);

export const viewMatchesQuery = (view, query) => {
  const match = Object.entries(view).some(([key, value]) => {
    if (!SEARCH_KEYS.includes(key) || !value) {
      return false;
    }

    return value?.toLowerCase().includes(query.toLowerCase());
  });

  return match;
};
