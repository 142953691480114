import React from 'react';
import styled from 'styled-components';

import AppliesToAutocomplete from 'shared/components/inputs/Autocomplete/AppliesToAutocomplete';
import { METRIC } from 'constants/common';

const MetricAutocomplete = ({ selectedEntity, options, onSave, onCancel, disabled, autoFocus, getSystemFieldName, width }) => {
  return (
    <Container $width={width}>
      <AppliesToAutocomplete
        name={METRIC}
        options={options}
        disabled={disabled}
        selectedEntity={selectedEntity}
        roadmapsKey={METRIC}
        getSystemFieldName={getSystemFieldName}
        onSelectOptionCallback={item => onSave(item?.metricTitle)}
        onCloseList={onCancel}
        focusOnInit={autoFocus}
        levelOneFieldName={METRIC}
        formatByRoadmap={false}
        renderPopperOnPortal
      />
    </Container>
  );
};

export default MetricAutocomplete;

const Container = styled.div`
  width: ${({ $width }) => ($width ? `${$width}px` : 'auto')};
`;
