import React, { useRef } from 'react';

import BaseLayout from 'design-system/organisms/BaseLayout/index';

import theme from 'design-system/theme';

import CreateDrawer from 'features/Canvas/components/CreateDrawer';

import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { GOALS_CANVAS_PAGE } from 'constants/filters/pages';
import useCreateDrawer from 'features/Canvas/components/CreateDrawer/useCreateDrawer';

import useGoalsCanvas from 'features/GoalsCanvas/hooks/useGoalsCanvas';
import { OBJECTIVE_NODE, KEYRESULT1_NODE, KEYRESULT2_NODE, METRIC_NODE } from 'features/Canvas/constants';
import Canvas from 'features/Canvas/Canvas';
import CanvasControlsBar from 'features/Canvas/components/CanvasControlsBar';
import usePermissions from 'hooks/permissions/usePermissions';

const pageId = GOALS_CANVAS_PAGE;

const availableNodes = {
  [OBJECTIVE_NODE]: true,
  [KEYRESULT1_NODE]: true,
  [KEYRESULT2_NODE]: true,
  [METRIC_NODE]: true,
};

const READ_ONLY_TOOLBAR_HEIGHT = 0;

const fixedNodesToDisplay = ['idea', 'initiative', 'bet'];

const GoalsCanvas = ({ isReadOnlyView = false }) => {
  const ref = useRef();
  const { canView } = usePermissions();
  const { openCreateDrawer, ...createDrawerProps } = useCreateDrawer();
  const {
    dataForCanvas,
    canvasLayoutConfig,
    canvasNodesLayout,
    canvasEdgesLayout,
    userActionsMeta,
    nodeTypeLabels,
    onNodesLayoutUpdate,
    onEdgesLayoutUpdate,
    onToggleOkrRelations,
    onToggleOkrMetricsRelations,
    onToggleBoardRelations,
    onToggleMetricRelations,
    onToggleHighlightHealth,
    onToggleOwnerAvatar,
    onToggleProgress,
    onToggleStatus,
    handleDisplayNodesChange,
  } = useGoalsCanvas();

  const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);
  const onAddTextBoxClick = () => {
    ref.current.contentWindow.postMessage(
      {
        type: 'addTextBox',
      },
      '*',
    );
  };

  return (
    <BaseLayout
      displayBorderOnToolbar
      withoutContentMargin
      background={theme.palette.background.secondary}
      toolbarComponent={
        shouldDisplayControlsBar ? (
          <CanvasControlsBar
            pageId={pageId}
            onAddTextBoxClick={onAddTextBoxClick}
            relationsConfig={canvasLayoutConfig.relationsConfig}
            displayPreferences={canvasLayoutConfig.displayPreferences}
            displayNodes={canvasLayoutConfig.displayNodes}
            onToggleOkrRelations={onToggleOkrRelations}
            onToggleOkrMetricsRelations={onToggleOkrMetricsRelations}
            onToggleBoardRelations={onToggleBoardRelations}
            onToggleMetricRelations={onToggleMetricRelations}
            onToggleHighlightHealth={onToggleHighlightHealth}
            handleDisplayNodesChange={handleDisplayNodesChange}
            onToggleOwnerAvatar={onToggleOwnerAvatar}
            onToggleProgress={onToggleProgress}
            onToggleStatus={onToggleStatus}
            availableNodes={availableNodes}
          />
        ) : null
      }
    >
      <Canvas
        innerRef={ref}
        id="outcome-canvas"
        title="outcome canvas"
        dataForCanvas={dataForCanvas}
        openCreateDrawer={openCreateDrawer}
        canvasLayoutConfig={canvasLayoutConfig}
        canvasNodesLayout={canvasNodesLayout}
        canvasEdgesLayout={canvasEdgesLayout}
        userActionsMeta={userActionsMeta}
        nodeTypeLabels={nodeTypeLabels}
        onNodesLayoutUpdate={onNodesLayoutUpdate}
        onEdgesLayoutUpdate={onEdgesLayoutUpdate}
        toolbarHeight={isReadOnlyView ? READ_ONLY_TOOLBAR_HEIGHT : undefined}
        fixedNodesToDisplay={fixedNodesToDisplay}
      />

      <CreateDrawer {...createDrawerProps} />
    </BaseLayout>
  );
};

export default GoalsCanvas;
