import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loginWithGoogle, getOrganization as getOrganizationAction } from 'store/login';
import { getLoginOrg } from 'store/login/selectors';
import { SIGNUP_MESSAGE_DEMO, SIGNUP_MESSAGE_TRIAL } from './constants';

const getSignupMessage = loginOrg => {
  if (loginOrg?.is_onboarding_demo) return SIGNUP_MESSAGE_DEMO;
  return SIGNUP_MESSAGE_TRIAL;
};

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const googleErrorMessage = useSelector(state => state.login.googleErrorMessage);
    const loginOrg = useSelector(getLoginOrg);

    const _handleGoogleLogin = googleToken => dispatch(loginWithGoogle(googleToken, props));
    const _getOrganization = () => dispatch(getOrganizationAction(props));

    const _receiveMessage = event => {
      let obj;

      try {
        if (typeof event.data === 'string') obj = JSON.parse(event.data);
        else if (typeof event.data === 'object') obj = event.data;
        else return;
      } catch (err) {
        return;
      }

      switch (obj.ssoType) {
        case 'google':
          _handleGoogleLogin(obj.token);
          break;
        default:
          break;
      }
    };

    useEffect(() => {
      _getOrganization();
      window.addEventListener('message', _receiveMessage, false);

      return () => window.removeEventListener('message', _receiveMessage);
    }, []);

    return <Component googleErrorMessage={googleErrorMessage} signupMessage={getSignupMessage(loginOrg)} />;
  };
};

export default componentHOC;
