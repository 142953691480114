export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_FULFILLED = 'FETCH_CATEGORIES_FULFILLED';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_FULFILLED = 'UPDATE_CATEGORY_FULFILLED';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_FULFILLED = 'CREATE_CATEGORY_FULFILLED';

export const UPDATE_CATEGORY_BY_ID = 'UPDATE_CATEGORY_BY_ID';
export const UPDATE_CATEGORY_BY_ID_FULFILLED = 'UPDATE_CATEGORY_BY_ID_FULFILLED';

export const ADD_CATEGORY_WITHOUT_SAVE = 'ADD_CATEGORY_WITHOUT_SAVE';
export const REMOVE_UNSAVED_CATEGORIES = 'REMOVE_UNSAVED_CATEGORIES';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_FULFILLED = 'DELETE_CATEGORY_FULFILLED';

export const MERGE_CATEGORIES = 'MERGE_CATEGORIES';
export const MERGE_CATEGORIES_FULLFILLED = 'MERGE_CATEGORIES_FULFILLED';

export const CREATE_CATEGORIES = 'CREATE_CATEGORIES';
export const CREATE_CATEGORIES_FULFILLED = 'CREATE_CATEGORIES_FULFILLED';
export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
export const UPDATE_CATEGORIES_FULFILLED = 'UPDATE_CATEGORIES_FULFILLED';
export const BULK_DELETE_CATEGORIES = 'BULK_DELETE_CATEGORIES';
export const BULK_DELETE_CATEGORIES_FULFILLED = 'BULK_DELETE_CATEGORIES_FULFILLED';

export const UNDO_ADD_CATEGORIES = 'UNDO_ADD_CATEGORIES';
export const UNDO_ADD_CATEGORIES_FULFILLED = 'UNDO_ADD_CATEGORIES_FULFILLED';
export const UNDO_UPDATE_CATEGORIES = 'UNDO_UPDATE_CATEGORIES';
export const UNDO_UPDATE_CATEGORIES_FULFILLED = 'UNDO_UPDATE_CATEGORIES_FULFILLED';
export const UNDO_BULK_DELETE_CATEGORIES = 'UNDO_BULK_DELETE_CATEGORIES';
export const UNDO_BULK_DELETE_CATEGORIES_FULFILLED = 'UNDO_BULK_DELETE_CATEGORIES_FULFILLED';

export const SWITCH_CATEGORIES_ROW_ORDER = 'SWITCH_CATEGORIES_ROW_ORDER';
export const SWITCH_CATEGORIES_ROW_ORDER_FULFILLED = 'SWITCH_CATEGORIES_ROW_ORDER_FULFILLED';
export const SWITCH_CATEGORIES_ROW_ORDER_PENDING = 'SWITCH_CATEGORIES_ROW_ORDER_PENDING';
export const SWITCH_CATEGORIES_ROW_ORDER_REJECTED = 'SWITCH_CATEGORIES_ROW_ORDER_REJECTED';

export const CREATE_CATEGORY_ROADMAP = 'CREATE_CATEGORY_ROADMAP';
export const CREATE_CATEGORY_ROADMAP_FULFILLED = 'CREATE_CATEGORY_ROADMAP_FULFILLED';
export const DELETE_CATEGORY_ROADMAP = 'DELETE_CATEGORY_ROADMAP';
export const DELETE_CATEGORY_ROADMAP_FULFILLED = 'DELETE_CATEGORY_ROADMAP_FULFILLED';
export const BULK_DELETE_CATEGORY_ROADMAP = 'BULK_DELETE_CATEGORY_ROADMAP';
export const BULK_DELETE_CATEGORY_ROADMAP_FULFILLED = 'BULK_DELETE_CATEGORY_ROADMAP_FULFILLED';
