import React, { useCallback, useMemo, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import DOMPurify from 'dompurify';
import styled from 'styled-components';
import withTheme from '@material-ui/core/styles/withTheme';
import CloseIcon from '@material-ui/icons/Close';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import WidgetSegment from 'design-system/organisms/WidgetSegment/index';
import HomeBackground from 'design-system/atoms/HomeBackground/index';

import theme from 'design-system/theme';
import HomeNavOptions from './components/HomeNavOptions';
import AnnouncementLightbox from './components/AnnouncementLightbox';
import ConfirmDialog from 'components/ConfirmDialog';

import useOrgAnnouncements from './hooks/useOrgAnnouncements';

import formatDate from 'utils/dates/formatDate';
import { getUserName } from 'utils/index';
import { PAGE_HEADER_HEIGHT } from 'constants/common';
import MyFavoriteViews from 'routes/Home/MyFavoriteViews';

const BORDER_COLOR = '#e6e6e6';

function UserHome() {
  const [isAnnouncementLightboxOpen, setIsAnnouncementLightboxOpen] = useState(false);
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState(null);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);

  const {
    addAnnouncementsVisible,
    announcementCardTitle,
    announcements,
    handleArchiveAnnouncement,
    handleDeleteAnnouncement,
    isAdmin,
    isEditor,
    onboardingGuide,
    saveAnnouncement,
    saveOnboardingGuide,
    selectedAnnouncement,
  } = useOrgAnnouncements(selectedAnnouncementId);

  const toggleAnnouncementLightbox = useCallback(() => {
    const currentlyOpen = !!isAnnouncementLightboxOpen;

    setIsAnnouncementLightboxOpen(!isAnnouncementLightboxOpen);

    if (currentlyOpen) setSelectedAnnouncementId(null);
  }, [isAnnouncementLightboxOpen, setIsAnnouncementLightboxOpen]);

  const handleSaveAnnouncement = useCallback(
    value => {
      saveAnnouncement(value);
      toggleAnnouncementLightbox();
    },
    [saveAnnouncement, toggleAnnouncementLightbox],
  );

  const handleUpdateAnnouncement = useCallback(
    announcementId => {
      setSelectedAnnouncementId(announcementId);
      toggleAnnouncementLightbox();
    },
    [setSelectedAnnouncementId, toggleAnnouncementLightbox],
  );

  const toggleDeleteConfirmationDialog = useCallback(() => {
    setIsDeleteConfirmationDialogOpen(!isDeleteConfirmationDialogOpen);
  }, [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen]);

  const handleClickDeleteAnnouncement = useCallback(() => {
    handleDeleteAnnouncement(selectedAnnouncementId);
    toggleDeleteConfirmationDialog();
    toggleAnnouncementLightbox();
  }, [handleDeleteAnnouncement, selectedAnnouncementId, toggleAnnouncementLightbox, toggleDeleteConfirmationDialog]);

  const emptyAnnouncements = useMemo(() => !announcements?.length, [announcements]);

  const listItems = useMemo(() => {
    return announcements.map((announcement, index) => {
      const isLast = index >= announcements.length - 1;

      const updateAnnouncement = () => handleUpdateAnnouncement(announcement.id);
      const parsedAnnouncementMessage = ReactHtmlParser(DOMPurify.sanitize(announcement.message), { decodeEntities: true });

      return (
        <StyledListItem key={`announcement-${announcement.id}`} disableGutters disablePadding isLast={isLast}>
          <LeftListItemContainer>
            <StyledListItemText $isAdmin={isAdmin} onClick={isAdmin && updateAnnouncement} primary={parsedAnnouncementMessage} />
            {isEditor && (
              <EditorContainer>
                <AdminInformationText color={theme.palette.newLayout.background.darkGray} size="small">
                  {`${getUserName(announcement.updatedBy)}${announcement.updatedBy ? ',' : ''} ${formatDate(
                    announcement.updated_at,
                    true,
                  )}`}
                </AdminInformationText>
              </EditorContainer>
            )}
          </LeftListItemContainer>
          <RightListItemContainer>
            {isEditor && (
              <>
                <StyledIconButton onClick={() => handleArchiveAnnouncement(announcement.id)}>
                  <CloseIcon />
                </StyledIconButton>
                <StyledIconButton onClick={updateAnnouncement}>
                  <OpenInNewIcon />
                </StyledIconButton>
              </>
            )}
            {isAdmin && (
              <StyledIconButton onClick={updateAnnouncement}>
                <EditOutlinedIcon />
              </StyledIconButton>
            )}
          </RightListItemContainer>
        </StyledListItem>
      );
    });
  }, [announcements, handleArchiveAnnouncement, handleUpdateAnnouncement, isAdmin, isEditor]);

  const emptyListText = useMemo(() => {
    return (
      <TextContainer>
        <TextDeprecated>No Announcements</TextDeprecated>
      </TextContainer>
    );
  }, []);

  const onboardingGuideDisplay = useMemo(() => {
    return (
      <StyledFluidRemirrorWYSIWYGEditor
        key="onboarding_guide"
        value={onboardingGuide}
        content={onboardingGuide}
        disableEdit={!!isEditor}
        onChange={saveOnboardingGuide}
        onBlur={saveOnboardingGuide}
        style={{ minHeight: '34px', padding: '0' }}
        smallInput
      />
    );
  }, [onboardingGuide]);

  return (
    <HomeBackground>
      {/* tabIndex is needed so that we have a focusable element in order to get out of edit mode on the rich text editor */}
      <Container tabIndex={-1}>
        <LeftContent style={{ flex: 0.15 }}>
          <HomeNavOptions />
        </LeftContent>
        <RightContent style={{ flex: 0.85 }}>
          <WidgetsGrid>
            <StyledWidgetSegment
              title={announcementCardTitle}
              canAddAnnouncement={addAnnouncementsVisible}
              handleAddAnnouncement={toggleAnnouncementLightbox}
              initialContent={emptyAnnouncements ? emptyListText : listItems}
            />
            <MyFavoriteViews />
          </WidgetsGrid>
          <StyledBottomWidgetSegment
            showExpandButton={false}
            title="Get Started Guide"
            customBorderColor={BORDER_COLOR}
            initialContent={onboardingGuideDisplay}
          />
        </RightContent>
      </Container>
      <AnnouncementLightbox
        isOpen={isAnnouncementLightboxOpen}
        onClose={toggleAnnouncementLightbox}
        onDeleteAnnouncement={toggleDeleteConfirmationDialog}
        onSaveAnnouncement={handleSaveAnnouncement}
        selectedAnnouncement={selectedAnnouncement}
        isAdmin={isAdmin}
      />
      <ConfirmDialog
        isOpen={isDeleteConfirmationDialogOpen}
        onCancel={toggleDeleteConfirmationDialog}
        onConfirm={handleClickDeleteAnnouncement}
        title="Delete selected announcement"
        text="Are you sure you want to delete the seleced announcement?"
      />
    </HomeBackground>
  );
}

export default withTheme()(UserHome);

const Container = styled.div`
  display: flex;
  align-items: stretch;
  min-height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
`;

const LeftContent = styled.div`
  overflow: hidden;
`;

const RightContent = styled.div`
  border-left: 1px solid ${BORDER_COLOR};
  padding: 12px 28px 28px 28px;
`;

const StyledBottomWidgetSegment = styled(WidgetSegment)`
  width: 100%;
  background: transparent;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  border-color: transparent;
`;

const StyledWidgetSegment = styled(WidgetSegment)`
  min-height: 200px;
`;

const StyledIconButton = styled(IconButton)`
  && {
    padding: 6px;
    margin-right: 10px;
    opacity: 0;
    transition: opacity 0.2s;
  }

  svg {
    font-size: ${({ theme }) => theme.typography.fontSizeMediumSmall}px;
  }
`;

const StyledListItem = styled(ListItem)`
  && {
    min-height: 50px;
    justify-content: space-between;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: background-color 0.2s;
    }

    &:hover {
      :before {
        background-color: ${({ theme }) => theme.palette.newLayout.background.transparentBlack};
      }

      ${StyledIconButton} {
        opacity: 1;
      }
    }

    ${({ isLast, theme }) =>
      !isLast &&
      `
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: ${theme.palette.newLayout.background.lighterBlack}
        }
    `}
  }
`;

const LeftListItemContainer = styled.div`
  padding-left: ${({ theme }) => theme.spacing.unit * 2}px;
`;

const StyledListItemText = styled(ListItemText)`
  &&& {
    position: relative;

    > span {
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
      line-height: ${({ theme }) => theme.typography.lineHeightSmallRem}rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  ${({ $isAdmin }) => $isAdmin && 'cursor: pointer;'}
`;

const RightListItemContainer = styled.div`
  display: flex;
`;

const TextContainer = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AdminInformationText = styled(TextDeprecated)`
  && {
    font-weight: ${({ theme }) => theme.typography.fontWeightLight};
    margin-right: 5px;
  }
`;

const EditorContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledFluidRemirrorWYSIWYGEditor = styled(FluidRemirrorWYSIWYGEditor)`
  > div > div {
    &:hover {
      background: ${({ theme }) => theme.palette.newLayout.background.transparentBlack};
    }

    > div > div {
      background: transparent;

      button {
        background: ${({ theme }) => theme.palette.white};
      }
    }
  }

  .remirror-editor-wrapper {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const WidgetsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 56px;
  align-items: stretch;

  @media only screen and (max-width: 1250px) {
    grid-template-columns: 1fr;
    grid-gap: 28px;
  }
`;
