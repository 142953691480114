import axios from 'axios';
import { isNil, path } from 'ramda';

import { createThunk } from 'utils/store/thunk';

import {
  ADD_AZURE_INTEGRATION,
  ADD_AZURE_INTEGRATION_FULFILLED,
  ADD_ORG_INTEGRATION,
  ADD_ORG_INTEGRATION_FULFILLED,
  DELETE_ALL_DEMO_DATA,
  DISABLE_INTEGRATION_WEBHOOKS,
  ENABLE_INTEGRATION_WEBHOOKS,
  FETCH_ORG_INTEGRATIONS,
  FETCH_ORGANIZATION,
  FETCH_ORGANIZATION_FULFILLED,
  IMPORT_USING_RECURRING_JQL_QUERY,
  MAKE_PAYMENT,
  REMOVE_ORG_INTEGRATION,
  REMOVE_TEST_SLACK_CONNECTION,
  RESTORE_ORG_INTEGRATION_DATA,
  SET_SELECTED_ORG_INTEGRATION_ID,
  TEST_SLACK_CONNECTION,
  UPDATE_FORWARD_EMAILS_INTEGRATION,
  UPDATE_HAS_SURNAME_FIRST_ON_JIRA,
  UPDATE_JIRA_INTEGRATION,
  UPDATE_ORG_INTEGRATION_BY_REALTIME_FULFILLED,
  UPDATE_ORGANIZATION_DATA,
  UPDATE_ORGANIZATION_DATA_FULFILLED,
  UPDATE_PORTAL_SETTINGS,
  UPDATE_SLACK_INTEGRATION,
  UPDATE_SLACK_INTEGRATION_FULFILLED,
} from './types';
import throwRequestError from '../utils/throwRequestError';

import { addDatadogRUM } from 'utils/addDatadogRUM';
import jiraActionWrapper from 'utils/jiraActionWrapper';
import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';

import { INTEGRATION_GATEWAY_INTEGRATION_TYPES } from 'constants/integrations';

const getNoteKey = path(['key']);
const getNoteValue = path(['note']);

export const fetchOrganization = () => {
  return (dispatch, getState) => {
    return axios
      .get('/api/organization')
      .then(response => {
        addDatadogRUM(response.data, getState()?.login?.currentUser);

        dispatch({
          type: FETCH_ORGANIZATION_FULFILLED,
          payload: response,
        });

        return response.data;
      })
      .catch(err => {
        dispatch({
          type: FETCH_ORGANIZATION,
          payload: err,
          error: err,
        });
      });
  };
};

export const setupAzureIntegration = (code, state, props) => {
  return dispatch =>
    axios
      .post('/api/integrations/azure/authorization', { code, state })
      .then(response => {
        dispatch({
          type: ADD_AZURE_INTEGRATION_FULFILLED,
          payload: response.data,
        });

        return response.data;
      })
      .catch(err => {
        dispatch({
          type: ADD_AZURE_INTEGRATION,
          payload: err,
          error: err,
        });
      });
};

export const setupSlackIntegration = (code, state) => {
  return dispatch =>
    axios
      .post('/api/integrations/slack/verification', { code, state })
      .then(response => {
        dispatch({
          type: ADD_ORG_INTEGRATION_FULFILLED,
          payload: response.data,
        });

        return response.data;
      })
      .catch(err => {
        dispatch({
          type: ADD_ORG_INTEGRATION,
          payload: err,
          error: err,
        });
      });
};

export const updateSlackIntegration = data => {
  return dispatch =>
    axios
      .put('/api/integrations/slack/settings', data)
      .then(response => {
        dispatch({
          type: UPDATE_SLACK_INTEGRATION_FULFILLED,
          payload: response.data,
        });

        return response.data;
      })
      .catch(err => {
        dispatch({
          type: UPDATE_SLACK_INTEGRATION,
          payload: err,
          error: err,
        });
      });
};

export const testSlackConnection = () => async dispatch => {
  return dispatch({
    type: TEST_SLACK_CONNECTION,
    payload: axios.post('/api/integrations/slack/test'),
  });
};

export const removeTestSlackConnection = () => async dispatch => {
  return dispatch({
    type: REMOVE_TEST_SLACK_CONNECTION,
  });
};

export const updateForwardEmailsIntegration = (data, oldData) => {
  return dispatch =>
    dispatch({
      type: UPDATE_FORWARD_EMAILS_INTEGRATION,
      payload: axios.put('/api/integrations/forwardEmails/settings', data),
      meta: {
        newData: data,
        oldData,
        toastErrorMessage: 'Unable to save forward email settings.',
      },
    });
};

export const updatePortalSettings = (data = { hiddenFields: [] }) => {
  return dispatch => {
    const promise = axios.put(`/api/customer-requests/portal/settings`, data).then(({ data }) => data);

    return dispatch({
      type: UPDATE_PORTAL_SETTINGS,
      payload: promise,
    });
  };
};

export const uploadPortalLogoFile = file => dispatch => {
  if (!file) return;

  const formData = new FormData();

  formData.append('file', file);

  const promise = axios.post(`/api/customer-requests/portal/logo`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

  return dispatch({
    type: UPDATE_PORTAL_SETTINGS,
    payload: promise,
  });
};

export const deletePortalLogoFile = () => async dispatch => {
  const promise = axios.delete(`/api/customer-requests/portal/logo`);

  return dispatch({
    type: UPDATE_PORTAL_SETTINGS,
    payload: promise,
  });
};

export const makePayment = (data, token) => {
  return {
    payload: axios.post('/api/billing', { data, tokenId: token.id, isUpgrade: data.isUpgrade }),
    type: MAKE_PAYMENT,
  };
};

export const updateCompletedSteps = completedSteps => {
  return {
    payload: axios.put('/api/organization/', { completed_steps: completedSteps }).then(({ data }) => data),
    type: UPDATE_ORGANIZATION_DATA,
  };
};

export const updateSystemFieldsName = fieldRenamed => {
  return (dispatch, getState) => {
    const { organization } = getState();
    const { system_fields_name } = organization.organization; // eslint-disable-line
    const fieldsToUpdate = system_fields_name ? system_fields_name.filter(f => f.key !== fieldRenamed.key) : []; // eslint-disable-line

    const trimmedTitle = fieldRenamed.title?.trim() || '';
    const newFieldData = { ...fieldRenamed, title: trimmedTitle };

    return axios
      .put('/api/organization/', { system_fields_name: [...fieldsToUpdate, newFieldData] })
      .then(({ data }) => {
        dispatch({
          type: UPDATE_ORGANIZATION_DATA_FULFILLED,
          payload: data,
        });
      })
      .catch(throwRequestError);
  };
};

export const updateCustomUserFieldsSettings = fieldUpdated => updateOrganization({ custom_user_project_fields: fieldUpdated });

export const updateMetadataNotes = newNote => {
  return dispatch => {
    const key = getNoteKey(newNote);
    const note = getNoteValue(newNote);

    if (isNil(key) || isNil(note)) return;

    return dispatch({
      payload: axios
        .put('/api/organizations/metadata-notes', newNote)
        .then(({ data }) => data)
        .catch(throwRequestError),
      type: UPDATE_ORGANIZATION_DATA,
    });
  };
};

export const updateBillingStatus = billingStatus => {
  return dispatch =>
    dispatch({
      payload: axios
        .put('/api/organizations/billing-status', { billingStatus })
        .then(({ data }) => data)
        .catch(throwRequestError),
      type: UPDATE_ORGANIZATION_DATA,
    });
};

export const updateOnboardingGuide = onboardingGuide => updateOrganization({ onboarding_guide: onboardingGuide });

export const updateReadOnlyHasPageConfiguration = hasPageConfigurationReadOnly =>
  updateOrganization({ has_page_configuration_read_only_users: hasPageConfigurationReadOnly });

export const updateHasAdvancedMetricsReporting = hasAdvancedMetricsReporting =>
  updateOrganization({ has_advanced_metric_reporting: hasAdvancedMetricsReporting });

export const updateHasHeadlessShareEnabled = hasHeadlessShareEnabled =>
  updateOrganization({ has_headless_share_enabled: hasHeadlessShareEnabled });

export const updateAllowReadOnlyUsersToUpdateRequestFields = allowReadOnlyUsersToUpdateRequestFields =>
  updateOrganization({ allow_read_only_users_to_update_request_fields: allowReadOnlyUsersToUpdateRequestFields });

export const updateAllowReadOnlyUsersToViewPortfolioOverview = allowReadOnlyUsersToViewPortfolioOverview =>
  updateOrganization({ allow_read_only_users_to_view_portfolio_overview: allowReadOnlyUsersToViewPortfolioOverview });

export const updateHasPersonas = hasPersonas => updateOrganization({ has_personas: hasPersonas });

export const updateHasLifecycles = hasLifecycles => updateOrganization({ has_lifecycles: hasLifecycles });

export const updateIdeaHasAutoHealth = hasAutoHealth => updateOrganization({ default_idea_auto_health: hasAutoHealth });

export const updateInitiativeHasAutoHealth = hasAutoHealth =>
  updateOrganization({ default_initiative_auto_health: hasAutoHealth });

export const updateBetHasAutoHealth = hasAutoHealth => updateOrganization({ default_bet_auto_health: hasAutoHealth });

export const updateOrganization = (update = {}) => {
  return {
    payload: axios
      .put('/api/organization/', update)
      .then(({ data }) => data)
      .catch(throwRequestError),
    type: UPDATE_ORGANIZATION_DATA,
  };
};

export const deleteAllDemoData = (data, token) => {
  return {
    payload: axios.post('/api/organization/deleteAllDemoData'),
    type: DELETE_ALL_DEMO_DATA,
  };
};

const updateJiraIntegationCall = (id, data) => {
  return axios.put(`/api/integrations/jira/${id}/`, data).then(({ data }) => data);
};

export const updateJiraIntegration = (integrationId, data) => {
  return dispatch => {
    return jiraActionWrapper(
      dispatch,
      () => updateJiraIntegationCall(integrationId, data),
      UPDATE_JIRA_INTEGRATION,
      integrationId,
    );
  };
};

/**
 * optimistic behaviour: sends data param so the FE value is processed on the _PENDING action
 * before the response from the backend arrives. for this action in 99.99% of the times the response will 200 OK
 */
export const updateHasSurnameFirstOnJira = (integrationId, value) => {
  return dispatch => {
    return jiraActionWrapper(
      dispatch,
      () => updateJiraIntegationCall(integrationId, { has_surname_first_on_jira: value }),
      UPDATE_HAS_SURNAME_FIRST_ON_JIRA,
      integrationId,
      { id: integrationId },
    );
  };
};

export const registerJiraWebhook = integrationId => {
  return dispatch => {
    return jiraActionWrapper(
      dispatch,
      () => axios.post(`/api/integrations/jira/${integrationId}/webhook`).then(({ data }) => data),
      UPDATE_JIRA_INTEGRATION,
      integrationId,
    );
  };
};

export const unregisterJiraWebhook = integrationId => {
  return dispatch => {
    return jiraActionWrapper(
      dispatch,
      () => axios.delete(`/api/integrations/jira/${integrationId}/webhook`).then(({ data }) => data),
      UPDATE_JIRA_INTEGRATION,
      integrationId,
    );
  };
};

export const disableADOWebhooks = (organizationId, userId, orgIntegrationId) =>
  createThunk(
    DISABLE_INTEGRATION_WEBHOOKS,
    () => axios.delete(`/api/azuredevops/webhooks/${organizationId}/${userId}/${orgIntegrationId}`).then(res => res.data),
    { integrationType: 'azuredevops', organizationId, userId, orgIntegrationId },
    { includeRetryAction: true },
  );

export const enableADOWebhooks = (organizationId, userId, orgIntegrationId) =>
  createThunk(
    ENABLE_INTEGRATION_WEBHOOKS,
    () => axios.put(`/api/azuredevops/webhooks/${organizationId}/${userId}/${orgIntegrationId}`).then(res => res.data),
    { integrationType: 'azuredevops', organizationId, userId, orgIntegrationId },
    { includeRetryAction: true },
  );

export const enableIntegrationWebhooks = (integrationType, organizationId, orgIntegrationId) =>
  createThunk(
    ENABLE_INTEGRATION_WEBHOOKS,
    () => axios.put(`/api/integrations/${integrationType}/webhooks/${orgIntegrationId}`).then(res => res.data),
    { integrationType, organizationId, orgIntegrationId },
    { includeRetryAction: true },
  );

export const disableIntegrationWebhooks = (integrationType, organizationId, orgIntegrationId) =>
  createThunk(
    DISABLE_INTEGRATION_WEBHOOKS,
    () => axios.delete(`/api/integrations/${integrationType}/webhooks/${orgIntegrationId}`).then(res => res.data),
    { integrationType, organizationId, orgIntegrationId },
    { includeRetryAction: true },
  );

export const fetchOrgIntegrations = () => {
  return dispatch => {
    const payload = axios.get('/api/integrations/organization').then(({ data }) => data);

    dispatch({
      type: FETCH_ORG_INTEGRATIONS,
      payload,
    });

    return payload;
  };
};

export const addOrgIntegration = (integrationType, opts) => {
  return dispatch => {
    const payload = axios.post(`/api/org-integrations/${integrationType}`, opts).then(res => res.data);

    dispatch({
      type: ADD_ORG_INTEGRATION,
      payload,
    });

    return payload;
  };
};

export const setSelectedOrgIntegrationId = orgIntegrationId => {
  return {
    type: SET_SELECTED_ORG_INTEGRATION_ID,
    payload: orgIntegrationId,
  };
};

export const restoreOrgIntegrationData = (orgIntegrationId, restoredIntegrationDataToUpdate = {}) => {
  return dispatch => {
    const payload = axios
      .put(`/api/org-integrations/${orgIntegrationId}/restore`, { restoredIntegrationDataToUpdate })
      .then(res => res.data);

    dispatch({
      type: RESTORE_ORG_INTEGRATION_DATA,
      payload,
    });

    return payload;
  };
};

export const removeOrgIntegration = (integrationType, orgIntegrationId) => {
  return dispatch => {
    const requestUrl = INTEGRATION_GATEWAY_INTEGRATION_TYPES.includes(integrationType)
      ? `/api/integrations/${integrationType}/${orgIntegrationId}`
      : `/api/integrations/${integrationType}`;

    const payload = axios.delete(requestUrl);

    dispatch({
      type: REMOVE_ORG_INTEGRATION,
      payload,
      meta: {
        integrationType,
        orgIntegrationId,
      },
    });

    return payload;
  };
};

export const importUsingRecurringJQLQuery = orgIntegrationId => {
  return dispatch => {
    const payload = axios.post(`/api/jira/${orgIntegrationId}/import/recurring-jql`).then(res => res.data);

    dispatch({
      type: IMPORT_USING_RECURRING_JQL_QUERY,
      payload,
      meta: {
        id: orgIntegrationId,
      },
    });

    return payload;
  };
};

export const gotOrgIntegrationsRealtimeUpdate = generateRealtimeUpdateAction(
  undefined,
  undefined,
  UPDATE_ORG_INTEGRATION_BY_REALTIME_FULFILLED,
  undefined,
  undefined,
  undefined,
);

export const organizationActions = {
  fetchOrganization,
  makePayment,
  deleteAllDemoData,
};
