import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pluck, pipe, defaultTo } from 'ramda';

import {
  fetchProjectReaders,
  resetProjectReaders,
  shareProjectToOrganizations,
  unshareProjectWithOrganization,
} from 'store/accessControl';
import {
  isFetchProjectReadersUninitialized,
  isFetchProjectReadersLoading,
  getProjectReadersData,
  isAddProjectReadersLoading as isAddProjectReadersLoadingSelector,
  getAddProjectReadersError,
} from 'store/accessControl/selectors';
import { getOrganization } from 'store/organization';

const ORGANIZATION_ID = 'organization_id';
const defaultToEmptyArray = defaultTo([]);

const getOrganizationIds = pipe(defaultToEmptyArray, pluck(ORGANIZATION_ID));

const useProjectAccessControl = projectId => {
  const dispatch = useDispatch();

  const organization = useSelector(state => getOrganization(state));
  const isUninitialized = useSelector(state => isFetchProjectReadersUninitialized(state));
  const isProjectReadersLoading = useSelector(state => isFetchProjectReadersLoading(state));
  const isAddProjectReadersLoading = useSelector(state => isAddProjectReadersLoadingSelector(state));
  const addProjectReadersError = useSelector(state => getAddProjectReadersError(state));
  const currentProjectReaders = useSelector(state => getProjectReadersData(state, projectId)) ?? [];

  const shareProject = useCallback(
    organizationIds => dispatch(shareProjectToOrganizations(projectId, organizationIds)),
    [projectId],
  );

  const unshareProject = useCallback(
    organizationId => dispatch(unshareProjectWithOrganization(projectId, organizationId)),
    [projectId],
  );

  useEffect(() => {
    return () => {
      if (projectId) dispatch(resetProjectReaders(projectId));
    };
  }, []);

  useEffect(() => {
    if (isUninitialized && !isProjectReadersLoading && projectId) {
      dispatch(fetchProjectReaders(projectId));
    }
  }, [projectId, isUninitialized, isProjectReadersLoading]);

  const currentProjectReadersOrgIds = useMemo(() => getOrganizationIds(currentProjectReaders), [currentProjectReaders]);

  const isCurrentProjectReadOnly = useMemo(
    () => currentProjectReadersOrgIds.includes(organization?.id),
    [currentProjectReadersOrgIds, organization?.id],
  );

  return {
    isProjectReadersLoading,
    isAddProjectReadersLoading,
    isUninitialized,
    currentProjectReaders,
    addProjectReadersError,
    currentProjectReadersOrgIds,
    isCurrentProjectReadOnly,

    // Actions
    shareProject,
    unshareProject,
  };
};

export default useProjectAccessControl;
