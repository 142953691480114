import styled, { css } from 'styled-components';
import SnapshotItemWrapper from 'routes/Dashboard/Snapshot/SnapshotItemWrapper';
import CardHeader from '@material-ui/core/CardHeader';
import materialColorsSnapshot from 'routes/Dashboard/Snapshot/colors';
import LaunchIcon from '@material-ui/icons/Launch';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import React from 'react';

export const StyledSnapshotItemWrapper = styled(SnapshotItemWrapper)`
  ${({ reduceMinHeight }) =>
    reduceMinHeight &&
    css`
      &&&& > div {
        min-height: 240px;
      }
    `}
`;

export const ObjectiveHeader = styled(({ icon, ...props }) => (
  <div {...props}>
    <div>{icon}</div>
    {props.children}
  </div>
))`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;

    > div {
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      svg {
        height: 28px !important;
        width: 28px !important;

        ${({ child }) =>
          child &&
          css`
            height: 16px !important;
            width: 16px !important;
            margin-top: 3px;
          `}
      }
    }
  }
`;

export const Header = styled(CardHeader)`
  &&&& {
    display: block;
    width: calc(100% - 48px);
    height: 28px;
    margin: 24px 24px 40px 24px;
    padding: 0;

    span span,
    div span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;

      padding-top: 1px;

      font-size: ${props => props.theme.typography.fontSizeMedium}px !important;
      line-height: ${props => props.theme.typography.lineHeightRegularLarge}px !important;
      font-weight: ${props => props.theme.typography.fontWeightMedium} !important;
      color: ${materialColorsSnapshot.darkestGray} !important;
    }

    ${({ child }) =>
      child &&
      css`
        margin-bottom: 35px;

        span span {
          font-size: ${props => props.theme.typography.fontSizeMediumSmall}px;
        }
      `}
  }
`;

export const InnerContainer = styled.div`
  &&&& {
    padding: 0 24px 0 24px;
  }
`;

export const ProgressPercentage = styled.div`
  &&&& {
    font-size: ${props => props.theme.typography.fontSizeMedium}px;
    font-weight: ${props => props.theme.typography.fontWeightMedium};
    line-height: ${props => props.theme.typography.lineHeightRegularLarge}px;

    margin-bottom: 4px;

    ${({ color }) => `
      color: ${color};
    `}

    ${({ isBlack }) =>
      isBlack &&
      `
      color: ${materialColorsSnapshot.darkestGray};
    `}

    ${({ child }) =>
      child &&
      css`
        font-size: ${props => props.theme.typography.fontSizeSmallLarge}px;
        line-height: ${props => props.theme.typography.lineHeightRegularLarge}px;
      `}
  }
`;

export const ProgressDetailViewIcon = styled(LaunchIcon)`
  &&&& {
    width: 16px;
    height: 16px;

    color: ${materialColorsSnapshot.darkGray};
    position: absolute;
    top: 10px;
    right: 10px;

    -webkit-animation: fadein 0.5s;
    -moz-animation: fadein 0.5s;
    -ms-animation: fadein 0.5s;
    -o-animation: fadein 0.5s;
    animation: fadein 0.5s;

    ${({ child }) =>
      child &&
      `
      top: 5px;
      right: 5px;
      svg {
        width: 10px;
        height: 10px;
      }
    `}
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const ProgressLabel = styled.span`
  &&&& {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const ProgressStatus = styled.div`
  &&&& {
    position: relative;

    font-size: ${props => props.theme.typography.fontSizeSmallLarge}px;
    line-height: ${props => props.theme.typography.lineHeightRegularLarge}px;

    color: ${materialColorsSnapshot.darkGray};

    transition: all 1s;

    padding-bottom: 5px;

    ${({ child }) =>
      child &&
      css`
        font-size: ${props => props.theme.typography.fontSize}px;
        line-height: ${props => props.theme.typography.lineHeightSmallLarge}px;
        padding-bottom: 28px;
      `}

    ${ProgressDetailViewIcon} {
      display: none;
    }

    &:hover {
      background: ${materialColorsSnapshot.lightGray};
      ${ProgressDetailViewIcon} {
        display: block;
      }
    }
  }
`;

export const ButtonWrapper = styled.div``;

export const MetrictStatus = styled.div`
    &:hover {
      background: ${materialColorsSnapshot.lightGray};

      ${ButtonWrapper} {
        visibility: visible;
      }
    }
  }
`;

export const MetrictStatusHeader = styled.div`
  &&&& {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.lines || 1};
    -webkit-box-orient: vertical;

    text-transform: capitalize;

    font-size: ${props => props.theme.typography.fontSizeSmallLarge}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    line-height: ${props => props.theme.typography.lineHeightRegularLarge}px;
    color: ${materialColorsSnapshot.darkestGray};

    ${({ child, theme }) =>
      child &&
      css`
        margin-bottom: 0.5rem;
        font-size: ${theme.typography.fontSize}px;
        line-height: ${props => props.theme.typography.lineHeightSmallLarge}px;
      `}
  }
`;

export const GridRow = styled.div`
  &&&& {
    width: 100%;
    display: flex;

    ${({ justifyEnd }) =>
      justifyEnd &&
      css`
        justify-content: flex-end;
      `}
  }
`;

export const ProgressRow = styled(GridRow)`
  &&&& {
    height: 6rem;
  }
`;

export const ChartBody = styled.div`
  &&&& {
    bottom: 0;
    left: 0;

    width: 80px;

    ${({ child }) =>
      child &&
      css`
        width: 60px;
      `}
  }
`;

export const OkrBody = styled.div`
  &&&& {
    display: flex;
    flex-direction: column;
    justify-content: ${({ justifyStart }) => (justifyStart ? 'flex-start' : 'center')};
    width: 55%;
    min-height: 80px;
    position: relative;
    ${ChartBody} {
      left: 0;
    }

    border-right: 1px solid ${props => props.theme.palette.newLayout.border.lightGray};

    padding-right: 32px;

    ${({ child }) =>
      child &&
      css`
        padding-right: 22px;
      `}

    ${({ useFullWidth }) =>
      useFullWidth &&
      css`
        width: 100%;
        padding: 0;
        border: unset;
      `}
  }
`;

export const FeatureBody = styled.div`
  &&&& {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 45%;
    position: relative;
    padding-left: 32px;

    ${({ child }) =>
      child &&
      css`
        padding-left: 22px;
      `}
  }
`;

export const PortfolioProgressContainer = styled.div`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const ChartContainer = styled(FeatureBody)`
  &&&& {
    align-items: center;
    flex-direction: unset;
    justify-content: unset;
    min-height: 80px;

    > div {
      display: flex;
      align-items: center;
    }
  }
`;

export const InnerWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

export const StyledText = styled(TextDeprecated)`
  &&&& {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    > span {
      max-width: 125px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
