const topics = {
  USER_FILTERS: 'userFilters',
  USER_VIEWS: 'userViews',
  PRIORITIES: 'priorities',
  TAGS: 'tags',
  PHASES: 'phases',
  THEMES: 'themes',
  CATEGORIES: 'categories',
  CUSTOMERS: 'customers',
  APP: 'app',
  USERS: 'users',
  ROADMAPS: 'roadmaps',
  PRODUCTS: 'products',
  KEY_RESULTS: 'keyResults',
  OBJECTIVES: 'objectives',
  METRICS: 'metrics',
  CUSTOMER_REQUESTS: 'customerRequests',
  STREAM_RELEASE_NOTES: 'streamReleaseNotes',
};

export { topics };
