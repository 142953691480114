import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getUserRoleLabel } from 'utils/UserTypeMapper';
import { selectCreatedUsersIds } from 'store/users-settings/selectors';

import { filterUsers } from '../helpers/listUsers';

const useFilteredUsers = (users, { filtersValues, searchText }) => {
  const createdUsersIds = useSelector(state => selectCreatedUsersIds(state));

  const processedSearchText = useMemo(() => searchText?.toLowerCase(), [searchText]);

  const searchTextFilter = useCallback(
    ({ first_name: firstName, last_name: lastName }) => {
      return !processedSearchText || [firstName, lastName].some(field => field?.toLowerCase().includes(processedSearchText));
    },
    [processedSearchText],
  );

  const statusesFilter = useCallback(
    ({ status }) => {
      return !filtersValues.status?.length || (status && filtersValues.status.includes(status));
    },
    [filtersValues.status],
  );

  const teamsFilter = useCallback(
    ({ team }) => {
      return !filtersValues.teams?.length || (team?.title && filtersValues.teams.includes(team.title));
    },
    [filtersValues.teams],
  );

  const teams2Filter = useCallback(
    ({ team2 }) => {
      return !filtersValues.teams2?.length || (team2?.title && filtersValues.teams2.includes(team2.title));
    },
    [filtersValues.teams2],
  );

  const skillsFilter = useCallback(
    ({ skill }) => {
      return !filtersValues.skills?.length || (skill?.title && filtersValues.skills.includes(skill.title));
    },
    [filtersValues.skills],
  );

  const rolesFilter = useCallback(
    ({ role_id: roleId }) => {
      const roleLabel = getUserRoleLabel(roleId);

      return !filtersValues.role?.length || (roleLabel && filtersValues.role.includes(roleLabel));
    },
    [filtersValues.role],
  );

  const filters = useMemo(() => {
    const activeFilters = [];

    if (searchText) {
      activeFilters.push(searchTextFilter);
    }

    if (filtersValues.status?.length) {
      activeFilters.push(statusesFilter);
    }

    if (filtersValues.teams?.length) {
      activeFilters.push(teamsFilter);
    }

    if (filtersValues.teams2?.length) {
      activeFilters.push(teams2Filter);
    }

    if (filtersValues.skills?.length) {
      activeFilters.push(skillsFilter);
    }

    if (filtersValues.role?.length) {
      activeFilters.push(rolesFilter);
    }

    return activeFilters;
  }, [searchText, searchTextFilter, filtersValues, statusesFilter, teamsFilter, teams2Filter, skillsFilter, rolesFilter]);

  return useMemo(() => {
    return filterUsers({ allUsers: users, filters, createdUsersIds });
  }, [users, filters, createdUsersIds]);
};

export default useFilteredUsers;
