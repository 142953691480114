import { getAllProjectsFromGroups } from 'store/projects/helpers/groupSelectors';
import isEmpty from 'lodash/isEmpty';
import { calculateGroupProgress } from 'utils/index';

export const calculateGroupValue = groupData => {
  const groupProjects = getAllProjectsFromGroups([groupData]);

  if (!isEmpty(groupProjects)) {
    return calculateGroupProgress(groupProjects);
  }

  return 0;
};

const progressValueFormatter = props => {
  const { value, data } = props;
  let progressValue = value;

  if (data && data.progress_type === 'auto' && data.progress_calculated) {
    progressValue = data.progress_calculated;
  }

  const calculatedValue = data?.group ? calculateGroupValue(data) : progressValue;

  return calculatedValue;
};

export default progressValueFormatter;
