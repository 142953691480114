import { useSelector } from 'react-redux';
import { defaultTo } from 'ramda';

import { selectInsightsFilters } from '../store';
import useGroupsData from './useGroupsData';
import { FIELD_KEY_TO_FILTER_KEY_MAPPER } from '../constants';

const defaultAsEmptyArray = defaultTo([]);
const defaultAsEmptyString = defaultTo('');

const getEntityTitle = entity => defaultAsEmptyString(entity?.title ?? entity?.name);

const getFilterEntity = (filter, filterData) => {
  const filterValues = defaultAsEmptyArray(filter?.values);
  const [filteredEntityId] = filterValues;

  return filterData[filteredEntityId];
};

/**
 * Custom hook that generates the title for the insights grid modal.
 *
 *
 * @returns {string} The generated title for the insights grid modal.
 */
const useInsightsGridModalTitle = () => {
  const insightsFilters = useSelector(selectInsightsFilters);
  const groupsData = useGroupsData();

  const [filter1, filter2] = insightsFilters;

  // invert object keys and values
  const FILTER_KEY_TO_FIELD_KEY_MAPPER = Object.keys(FIELD_KEY_TO_FILTER_KEY_MAPPER).reduce((acc, key) => {
    return {
      ...acc,
      [FIELD_KEY_TO_FILTER_KEY_MAPPER[key]]: key,
    };
  }, {});

  const filter1FieldKey = FILTER_KEY_TO_FIELD_KEY_MAPPER[filter1?.key] ?? filter1?.key;
  const filter2FieldKey = FILTER_KEY_TO_FIELD_KEY_MAPPER[filter2?.key] ?? filter2?.key;

  const filter1Data = filter1 && groupsData(filter1FieldKey);
  const filter2Data = filter2 && groupsData(filter2FieldKey);

  const filter1Entity = filter1 && getFilterEntity(filter1, filter1Data);
  const filter2Entity = filter2 && getFilterEntity(filter2, filter2Data);

  const lightboxTitle = `${getEntityTitle(filter1Entity)}${filter2Entity ? ` - ${getEntityTitle(filter2Entity)}` : ''}`;

  return lightboxTitle;
};

export default useInsightsGridModalTitle;
