import { returnsTrueIfKeyIsNotEnterOrTab } from 'utils/agGrid';

/**
 * Options to be added to ag-grid field that uses AgGridAutocomplete component.
 * Allow use keyboard keys to navigate through autocomplete suggestions in ag-grid cell.
 * Focus field on render first time.
 * Apply class to show suggestions visually correct.
 */
export default (field, hierarchyParams, parentObjects, otherOptions = {}) => {
  return {
    suppressKeyboardEvent: returnsTrueIfKeyIsNotEnterOrTab,
    cellEditorParams: {
      autoFocus: true,
      hierarchyParams,
      parentObjects,
      field,
      ...otherOptions,
    },
  };
};
