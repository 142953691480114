// External dependencies
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

// Dragonboat imports
import { updateState } from 'store/ideasBoard';
import {
  getOrganization,
  getIdeasIntegrations,
  selectHasProducts2,
  selectHasProducts,
  selectHasKeyResults2,
  selectHasKeyResults,
  hasMultiLevelPortfolioMetadata as hasMultiLevelPortfolioMetadataSelector,
} from 'store/organization/selectors';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const { canView } = usePermissions();

    const updateIdeasBoardState = state => dispatch(updateState(state));
    const lsState = useSelector(state => state.ideasBoard);
    const organization = useSelector(state => getOrganization(state));
    const [tempState, _setTempState] = useState(cloneDeep(lsState));
    const customFields = useSelector(state => getProjectsCustomFields(state));
    const hasKeyResults = useSelector(selectHasKeyResults);
    const hasKeyResults2 = useSelector(selectHasKeyResults2);
    const hasProducts = useSelector(selectHasProducts);
    const hasProducts2 = useSelector(selectHasProducts2);
    const hasMultiLevelPortfolioMetadata = useSelector(hasMultiLevelPortfolioMetadataSelector);
    const integrations = useSelector(state => getIdeasIntegrations(state));
    const hasMetadataMultiSelect = useFeatureFlags([FeatureFlags.HAS_METADATA_MULTI_SELECT]);
    const shouldShowPreCalculations = useFeatureFlags([FeatureFlags.HAS_OUTCOME_DASHBOARD]);
    const hasTeams2 = useFeatureFlags([FeatureFlags.HAS_TEAMS_2]);
    const hasPersonas = canView(PERMISSION_FEATURES.personas);
    const hasLifecycles = canView(PERMISSION_FEATURES.lifecycles);

    const _onCancel = () => props.closeDialog();
    const setTempState = data => {
      _setTempState({ ...tempState, ...data });
    };
    const _onApply = () => {
      _onCancel();
      setTimeout(() => updateIdeasBoardState(tempState), 500);
    };

    useEffect(() => {
      _setTempState(cloneDeep(lsState));
    }, [props.isOpen]);

    const systemFields = organization.system_fields_name;
    const hasBets = organization.has_bet;
    const hasInitiatives = organization.has_hierarchy;

    return (
      <Component
        {...props}
        isOpen={props.isOpen}
        onCancel={_onCancel}
        selectedTags={tempState.selectedTags}
        updateState={setTempState}
        displayPreferences={tempState.displayPreferences || {}}
        onChangeDisplayPref={(key, value) =>
          setTempState({ displayPreferences: { ...tempState.displayPreferences, [key]: value } })
        }
        systemFields={systemFields}
        onApply={_onApply}
        organizationIntegrations={integrations}
        hasBets={hasBets}
        hasInitiatives={hasInitiatives}
        customFields={customFields}
        hasKeyResults={hasKeyResults}
        hasKeyResults2={hasKeyResults2}
        hasProducts={hasProducts}
        hasProducts2={hasProducts2}
        hasTeams2={hasTeams2}
        shouldShowPreCalculations={shouldShowPreCalculations}
        hasMetadataMultiSelect={hasMetadataMultiSelect}
        hasMultiLevelPortfolioMetadata={hasMultiLevelPortfolioMetadata}
        hasPersonas={hasPersonas}
        hasLifecycles={hasLifecycles}
      />
    );
  };
};

export default componentHOC;
