/* eslint-disable */
/**
 * UnfilterIcon Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function jiraIcon(props) {
  const height = Math.ceil((props?.style?.height || 16) * 0.875);
  return (
    <SvgIcon {...props} style={{ ...props.style, height }} width="256px" height="256px" viewBox="0 0 23 23">
      <path
        class="a"
        d="M22401.5,20545a11.5,11.5,0,1,1,8.133-3.367A11.421,11.421,0,0,1,22401.5,20545Zm-3.488-4.859-1.3,1.3a9.3,9.3,0,0,0,9.59,0l-1.3-1.307a7.51,7.51,0,0,1-6.98,0Zm3.488-12.141a5.5,5.5,0,1,0,5.5,5.5A5.5,5.5,0,0,0,22401.5,20528Zm-7.943.711a9.286,9.286,0,0,0,0,9.584l1.3-1.3a7.517,7.517,0,0,1,0-6.98Zm15.891,0-1.3,1.3a7.5,7.5,0,0,1,0,6.98l1.3,1.3a9.309,9.309,0,0,0,0-9.584Zm-7.947-2.707a7.488,7.488,0,0,1,3.492.861l1.3-1.3a9.273,9.273,0,0,0-9.59,0l1.3,1.3A7.543,7.543,0,0,1,22401.5,20526Z"
        transform="translate(-22390 -20522.002)"
      />
    </SvgIcon>
  );
}
