import { FeatureFlags } from '@dragonboat/config';

import { checkOrganizationFlags } from 'hooks/useFeatureFlags';
import AddOrLinkNewColumnOrRowBox from './AddOrLinkNewColumnOrRowBox';
import AddNewColumnOrRowBox, { cellType } from './AddNewColumnOrRowBox';

const hasSummaryLazyLoading = checkOrganizationFlags({}, [FeatureFlags.HAS_SUMMARY_LAZY_LOADING]);

const Component = hasSummaryLazyLoading ? AddOrLinkNewColumnOrRowBox : AddNewColumnOrRowBox;

export default Component;

export { cellType };
