import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import { textColor } from 'design-system/themes/default';

import CollapsePanel from 'components/CollapsePanel';
import InfoToast from 'components/InfoToast';

import { getJiraIssuesTypeMapping as getJiraIssuesTypeMappingSelector } from 'store/organization/selectors';
import { selectHasSurnameFirstOnJira as selectHasSurnameFirstOnJiraSelector } from 'store/integrations/selectors';
import {
  updateJiraIntegration as updateJiraIntegrationAction,
  updateHasSurnameFirstOnJira as updateHasSurnameFirstOnJiraAction,
} from 'store/organization';

import MapHierarchy from '../MapHierarchy';

const OtherJiraSettings = props => {
  const { integrationSettings, orgIntegrationId, userCanUpdateOrgIntegration } = props;

  const dispatch = useDispatch();

  const hasSurnameFirstOnJira = useSelector(state => selectHasSurnameFirstOnJiraSelector(state, orgIntegrationId));
  const hierarchyData = useSelector(getJiraIssuesTypeMappingSelector(orgIntegrationId));

  const onChangeAddIdeaNumberToJira = useCallback(
    value => {
      dispatch(updateJiraIntegrationAction(orgIntegrationId, { add_idea_number_to_jira: value }));
    },
    [orgIntegrationId],
  );

  const onChangeHasSurnameFirstOnJira = useCallback(
    value => {
      dispatch(updateHasSurnameFirstOnJiraAction(orgIntegrationId, value));
    },
    [orgIntegrationId],
  );

  const onUpdateHierarchyMap = useCallback(
    hierarchyData => {
      return dispatch(updateJiraIntegrationAction(orgIntegrationId, { jiraIssueTypeMapping: hierarchyData })).then(
        updatedIntegration => {
          if (updatedIntegration?.data?.webhooks)
            toast(<InfoToast>Webhooks have been updated to reflect the new hierarchy</InfoToast>, {});
        },
      );
    },
    [orgIntegrationId],
  );

  return (
    <CollapsePanel title={<TextDeprecated size="medium">Hierarchy, name and other settings</TextDeprecated>}>
      <PanelContent>
        <MapHierarchy
          hierarchyData={hierarchyData}
          updateHierarchyMap={onUpdateHierarchyMap}
          orgIntegrationId={orgIntegrationId}
          isReadOnly={!userCanUpdateOrgIntegration}
        />
        <ToggleSwitch
          disabled={!userCanUpdateOrgIntegration}
          label="Exclude dragonboat item ID from the title when creating an item in Jira"
          control={
            <Switch
              checked={!integrationSettings.add_idea_number_to_jira}
              onChange={() => onChangeAddIdeaNumberToJira(!integrationSettings.add_idea_number_to_jira)}
            />
          }
        />
        <ToggleSwitch
          disabled={!userCanUpdateOrgIntegration}
          label={`JIRA user name format: "Surname, First Name"`}
          control={
            <Switch checked={hasSurnameFirstOnJira} onChange={() => onChangeHasSurnameFirstOnJira(!hasSurnameFirstOnJira)} />
          }
        />
      </PanelContent>
    </CollapsePanel>
  );
};

const PanelContent = styled.div`
  width: 100%;
  color: ${textColor};
  padding-bottom: 16px;
`;

const ToggleSwitch = styled(FormControlLabel)`
  &&&& {
    width: 100%;
  }
`;

export default OtherJiraSettings;
