import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { updateRoadmapById } from 'store/roadmaps';

import { URL_COLUMN_FIELD, DEFAULT_VALUE } from '../helpers/constants';
import { INTEGRATIONS_KEYS } from 'constants/integrations';

const useMultiChildDragonsList = childrenDragons => {
  const dispatch = useDispatch();

  const rows = useMemo(() => {
    return childrenDragons.map((childDragon, index) => ({
      ...childDragon,
      id: index + 1,
      code: childDragon.code || DEFAULT_VALUE,
      updatedAt: childDragon.updated_at,
      baseUrl: childDragon.child_organization_url,
      label: childDragon.accountName,
    }));
  }, [childrenDragons]);

  const openRowBaseUrlById = row => {
    window.open(row?.baseUrl, '_blank');
  };

  const updateOrganizationAccessById = useCallback(
    (rowId, field) => {
      const row = rows.find(r => r?.id === rowId);

      if (row?.portfolio_roadmap_id && field?.[URL_COLUMN_FIELD]) {
        dispatch(updateRoadmapById(row.portfolio_roadmap_id, { title: field[URL_COLUMN_FIELD] }));
      }
    },
    [rows],
  );

  const integrationType = INTEGRATIONS_KEYS.dragonboat;

  return { rows, openRowBaseUrlById, updateOrganizationAccessById, integrationType };
};

export default useMultiChildDragonsList;
