import React from 'react';

import { CustomerRequestsCommentsLightboxProvider } from 'hooks/customerRequests/useCustomerRequestsCommentsLightbox';

import Grid from './Grid';

export default ({ ...props }) => {
  return (
    <CustomerRequestsCommentsLightboxProvider>
      <Grid {...props} />
    </CustomerRequestsCommentsLightboxProvider>
  );
};
