import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';

const Header = ({ onClose }) => {
  return (
    <Container>
      <Text as="h4">Create on Dragonboat</Text>
      <StyledButtonIcon onClick={onClose}>
        <CloseIcon />
      </StyledButtonIcon>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledButtonIcon = styled(ButtonIcon)`
  // transform to compensate the MUI hover size
  transform: translateX(${spacing(2)}px);

  svg {
    font-size: ${({ theme }) => theme.sizing.icons.regular}rem;
  }
`;
