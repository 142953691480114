import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { equals, pipe, prop } from 'ramda';
import styled from 'styled-components';

import { READ_ONLY_USER } from '@dragonboat/permissions';
import { ROADMAP_PAGE } from 'constants/filters';
import LoadingOverlay from 'design-system/atoms/LoadingOverlay/index';

import Timeline from 'design-system/organisms/Timeline';
import TitleWithActions from 'design-system/organisms/TitleWithActions';
import { isSnapToPredictedEnd } from 'design-system/organisms/Timeline/Legacy/helpers';

import PlanningStageWarningDialog from 'components/PlanningStageWarningDialog';

import { getCurrentUser } from 'store/login/selectors';
import { openObjectiveDrawer } from 'store/objectives/actions';
import { OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2, OBJECT_OBJECTIVE, OBJECT_OBJECTIVE_CORP_STRING } from 'store/objectives';

import useSystemFields from 'hooks/useSystemFields';
import useTimelines from 'hooks/useTimelines';
import useLoadProjectsForTransactionPages from 'hooks/projects/useLoadProjectsForTransactionPages';

import useSwimlaneProjects from './hooks/useSwimlaneProjects';
import useSwimlaneDataTree from './hooks/useSwimlaneDataTree';
import useSwimlaneHandlers from './hooks/useSwimlaneHandlers';
import { getContentStyle } from './helpers/getContentStyle';
import { mapFields } from './helpers/updateProjectGroupData';

import { CORP_OBJECTIVE_KEY, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY, OBJECTIVE_KEY } from 'constants/objectives';

import ControlsBar from './ControlsBar';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const isReadOnlyUser = pipe(prop('role_id'), equals(READ_ONLY_USER));
const CUSTOM_FIELD_PREFIX = 'custom_fields.';
const isCustomField = type => type && type?.startsWith(CUSTOM_FIELD_PREFIX);
const getGroupTitle = (groupType, getSystemFieldName) =>
  isCustomField(groupType?.key) ? groupType.title : groupType && getSystemFieldName(mapFields[groupType.key] || groupType.key);

const MILESTONE_TOP_LANE_PREFIX = 'milestoneTopLane';

// Gets the type as a string and returns the okr key
const mapLevelIntoObject = level => {
  switch (level) {
    case CORP_OBJECTIVE_KEY:
      return OBJECT_OBJECTIVE_CORP_STRING;
    case OBJECTIVE_KEY:
      return OBJECT_OBJECTIVE;
    case KEY_RESULT_1_KEY:
      return OBJECT_KEY_RESULT;
    case KEY_RESULT_2_KEY:
      return OBJECT_KEY_RESULT_2;
    default:
      return OBJECT_OBJECTIVE;
  }
};

/**
 * This page component should only be responsible for:
 * - Prepare the data to the Timeline;
 * - Pass configuration, options to the Timeline;
 * - Pass the handlers to the Timeline.
 *
 * Should not address any specific UI concern, these should be accounted in the agnostic Timeline component.
 * * */
const Swimlane = ({ className, viewsDropdownTabs, withCustomersGrouping, barRenderer, minBarWidth }) => {
  const dispatch = useDispatch();

  const [getSystemFieldName] = useSystemFields();

  const currentUser = useSelector(getCurrentUser);

  const isReadOnly = isReadOnlyUser(currentUser);
  const isAnonymousUser = currentUser.is_anonymous;

  const { canView } = usePermissions();

  const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

  // Get the timeline configuration for the swimlane
  const {
    lsState: {
      grouplaneType,
      swimlaneType,
      displayMilestone,
      displayMilestoneOn,
      groupbarWidth,
      sidebarWidth,
      slotWidth,
      isDialogVisible,
      showLegend,
      showTooltip,
      showTextOverflow,
      snapToGridOn,
      showMyItemsOnly,
    },
    zoomMode,
    updateState,
    resizeFirstGroupHeader,
    resizeSecondGroupHeader,
  } = useTimelines(ROADMAP_PAGE);

  // Load the projects
  useLoadProjectsForTransactionPages(ROADMAP_PAGE, null, null, { includeMilestones: displayMilestone });

  const groupTypeTitle = getGroupTitle(grouplaneType, getSystemFieldName);
  const dataTypeTitle = getGroupTitle(swimlaneType, getSystemFieldName);

  // Get the projects and all the project's information
  const {
    projects,
    isUninitializedApplyingFilters,
    isFetchingProjects,
    isCreatingProject,
    isUpdatingProject,
    isDeletingProject,
    hasProjectUpdateError,
    handleOpenProjectLightBox,
  } = useSwimlaneProjects(showMyItemsOnly);

  // Generate Swimlane data
  const [treeData, firstGroupData, secondGroupData, filterData, filter] = useSwimlaneDataTree(projects);

  // Get the handlers for the timeline
  const { handleDrag, handleResize, handleClickRow } = useSwimlaneHandlers({
    treeData,
    firstGroupData,
    secondGroupData,
    filterData,
    filter,
    groupTypeTitle,
    dataTypeTitle,
    slotWidth,
    zoomMode,
    updateState,
  });

  const confirmPlanningStageWarning = useCallback(() => updateState({ isDialogVisible: false }), []);

  const hasGlobalLoading = isFetchingProjects || isUninitializedApplyingFilters;

  if (hasGlobalLoading) {
    return <LoadingOverlay />;
  }

  const customRenderTitle = (item, defaultRenderTitle) => {
    const itemType = item.meta?.type || OBJECTIVE_KEY;

    const isOkr = [CORP_OBJECTIVE_KEY, OBJECTIVE_KEY, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY].includes(itemType);
    const type = mapLevelIntoObject(itemType);

    const openButtonProps = { onClick: () => dispatch(openObjectiveDrawer(item.id, type)) };

    if (isOkr && item.id && !item.id?.startsWith?.(MILESTONE_TOP_LANE_PREFIX)) {
      return (
        <TitleWithActions openButtonProps={openButtonProps} hasOpenButton>
          {defaultRenderTitle(item)}
        </TitleWithActions>
      );
    }

    return defaultRenderTitle(item);
  };

  return (
    <Wrapper className={className}>
      {shouldDisplayControlsBar && (
        <ControlsBar viewsDropdownTabs={viewsDropdownTabs} withCustomersGrouping={withCustomersGrouping} />
      )}
      <Timeline
        // data
        isLoading={isCreatingProject || isUpdatingProject || isDeletingProject}
        data={treeData}
        firstGroupTitle={groupTypeTitle}
        secondGroupTitle={dataTypeTitle}
        shouldResetData={hasProjectUpdateError}
        // options
        contentStyle={getContentStyle(isReadOnly, showLegend)}
        zoomMode={zoomMode}
        groupbarWidth={groupbarWidth}
        sidebarWidth={sidebarWidth}
        slotWidth={slotWidth}
        displayMilestone={displayMilestone}
        displayMilestoneOn={displayMilestoneOn}
        showTooltip={showTooltip}
        showTextOverflow={showTextOverflow}
        snapToGridOn={snapToGridOn}
        resizable={!snapToGridOn}
        draggable={!isSnapToPredictedEnd(snapToGridOn) && !isAnonymousUser}
        customRenderTitle={customRenderTitle}
        // handlers
        onResizeFirstGroupHeader={resizeFirstGroupHeader}
        onResizeSecondGroupHeader={resizeSecondGroupHeader}
        onDoubleClick={handleOpenProjectLightBox}
        onRowClick={handleClickRow}
        onDrag={handleDrag}
        onResize={handleResize}
        barRenderer={barRenderer}
        minBarWidth={minBarWidth}
      />
      <PlanningStageWarningDialog isOpen={isDialogVisible} onConfirm={confirmPlanningStageWarning} />
    </Wrapper>
  );
};

export default Swimlane;

const Wrapper = styled.div`
  &&&& {
    padding: 0;
  }
`;
