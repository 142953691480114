import axios from 'axios';
import throwRequestError from 'src/store/utils/throwRequestError';

export const callCreateMetric = async metric => {
  return axios
    .post('/api/metrics', metric)
    .then(res => res.data)
    .catch(throwRequestError);
};

export const callAddMetricToOkr = async (okrId, metricId, type) => {
  return axios
    .post(`/api/${type}/${okrId}/metrics/${metricId}`)
    .then(res => res.data)
    .catch(throwRequestError);
};
