import React from 'react';
import styled from 'styled-components';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Grid from '@material-ui/core/Grid';

import FluidFieldLabel from 'design-system/molecules/FluidFieldLabel';
import useClipboard from './useClipboard';
import FluidAccessMultiSelect from 'src/design-system/organisms/FluidAccessMultiSelect';
import DrawerHeader from 'design-system/molecules/DrawerHeader/index';
import NewDialog from 'design-system/organisms/NewDialog/index';

import { HeaderIconButton } from 'design-system/organisms/NewDialog';

const EmailLabel = ({ email }) => {
  const { ClipboardButton, ClipboardText } = useClipboard(email);

  return (
    <>
      Your unique email address: <ClipboardButton text={email} />
      <ClipboardText text={email} />
    </>
  );
};

export default props => {
  const { forwardEmailsConfig, updateConfig, defaultUserDomainName, organizationSlug, onClose, ...rest } = props;
  const email = `${organizationSlug}@inbox.dragonboat.io`;

  return (
    <StyledDialog
      type="DRAWER"
      withoutBackdrop
      headerComponent={StyledDrawerHeader}
      headerProps={{
        onClose,
        title: 'Configure Email Forwarding',
        helpLink: 'https://dragonboat.zendesk.com/hc/en-us/articles/4413362965911',
        leftIcon: <ChevronLeftIcon />,
      }}
      paddingOverride="0px"
      closeButton
      onClose={props.onClose}
      {...rest}
    >
      <Form autoComplete="off">
        <Grid container xs={12} direction="column" justify="space-around">
          <ForwardEmailsLabel label={<EmailLabel email={email} />} />
          <FluidAccessMultiSelect
            defaultUserDomainName={defaultUserDomainName}
            accessConfig={forwardEmailsConfig}
            allowMultiSelectLabel="Only allow emails from these domains"
            // denyMultiSelectLabel="Allow submission from any domain, except the following"
            updateConfig={updateConfig}
          />
        </Grid>
      </Form>
    </StyledDialog>
  );
};

const StyledDialog = styled(NewDialog)`
  &&&& ${HeaderIconButton} {
    top: 18px;
  }
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  > div > div {
    display: flex;
  }
`;

const Form = styled.form`
  &&&& {
    padding: 20px 56px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const ForwardEmailsLabel = styled(FluidFieldLabel)`
  &&&& {
    margin-bottom: 12px;
  }
`;
