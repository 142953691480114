import axios from 'axios';

import {
  FETCH_CUSTOM_FIELDS,
  UPDATE_CUSTOM_FIELD,
  UPDATE_CUSTOM_FIELD_BY_ID,
  ADD_CUSTOM_FIELD_WITHOUT_SAVE,
  REMOVE_UNSAVED_CUSTOM_FIELDS,
  CREATE_CUSTOM_FIELD,
  CREATE_CUSTOM_FIELD_ROADMAP,
  DELETE_CUSTOM_FIELD_ROADMAP,
  BULK_DELETE_CUSTOM_FIELD_ROADMAP,
  UPDATE_CUSTOM_FIELDS_FULFILLED,
} from './types';
import throwRequestError from '../utils/throwRequestError';
import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';
import fetchMetadataAction from 'store/utils/factory/fetchMetadataAction';

export const fetchCustomFields = fetchMetadataAction(
  FETCH_CUSTOM_FIELDS,
  '/api/customFields',
  state => state.customFields.customFields,
  state => state.customFields.lastCallsDate,
);

export const updateCustomField = customField => {
  return {
    payload: axios
      .post(`/api/customFields/update/${customField.id}`, customField)
      .then(res => res.data)
      .catch(throwRequestError),
    type: UPDATE_CUSTOM_FIELD,
  };
};

export const updateCustomFieldById = (customFieldId, update, enhanced = false) => {
  if (enhanced) {
    update = {
      ...update,
      data_enhanced: update.data,
      data: Object.entries(update.data || {}).reduce((acc, [key, value]) => ({ ...acc, [key]: value.title }), {}),
    };
  }
  return {
    payload: axios
      .put(`/api/customFields/${customFieldId}`, update)
      .then(res => res.data)
      .catch(throwRequestError),
    type: UPDATE_CUSTOM_FIELD_BY_ID,
  };
};

export const createCustomField = customField => {
  return {
    payload: axios
      .post('/api/customFields/', customField)
      .then(res => res.data)
      .catch(throwRequestError),
    type: CREATE_CUSTOM_FIELD,
  };
};

export const createCustomFieldRoadmap = (customFieldId, customFieldRoadmap) => {
  return {
    payload: axios
      .post(`/api/customFields/${customFieldId}/customFieldRoadmaps`, customFieldRoadmap)
      .then(response => response.data),
    type: CREATE_CUSTOM_FIELD_ROADMAP,
  };
};

export const deleteCustomFieldRoadmap = ({
  id,
  roadmapCorpId = null,
  roadmapId = null,
  subRoadmapId = null,
  product2Id = null,
}) => {
  const body = { roadmapCorpId, roadmapId, product1Id: subRoadmapId, product2Id };

  return {
    payload: axios.delete(`/api/customFields/${id}/customFieldRoadmaps/roadmaps`, { data: body }).then(response => response.data),
    type: DELETE_CUSTOM_FIELD_ROADMAP,
  };
};

export const bulkDeleteCustomFieldRoadmaps = customFieldId => {
  return {
    payload: axios.delete(`/api/customFields/${customFieldId}/customFieldRoadmaps`).then(response => response.data),
    type: BULK_DELETE_CUSTOM_FIELD_ROADMAP,
  };
};

export const addCustomFieldWithoutSave = customField => ({
  type: ADD_CUSTOM_FIELD_WITHOUT_SAVE,
  customField,
});

export const removeUnsavedCustomFields = _ => ({
  type: REMOVE_UNSAVED_CUSTOM_FIELDS,
});

export const customFieldActions = {
  fetchCustomFields,
  updateCustomField,
  createCustomField,
};

export const gotCustomFieldsRealtimeUpdate = generateRealtimeUpdateAction(
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  UPDATE_CUSTOM_FIELDS_FULFILLED,
);
