import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

import useProjectsWithDeliverables from 'hooks/projects/useProjectsWithDeliverables';
import { useIdeasGrid } from 'containers/IdeasList/IdeasList/New/hooks';

import { upsertProjectDeliverable, deleteProjectDeliverable } from 'store/cycleDeliverables';
import { isSomeUpdateOnProjectsDeliverablesOcurring } from 'store/cycleDeliverables/selectors';
import { getDeliverableIdFromFieldName } from 'utils/cycleDeliverables/cycleDeliverablesUtils';
import { useGridRowDataWithUpdateDataLoading } from 'design-system/molecules/AgGridReact-New/hooks';

const emptyArray = [];

const useCycleDeliverablesGrid = (
  data = emptyArray,
  {
    parentHandleGridReady,
    displayLayer,
    portfolioMode,
    currentUser,
    hasBet,
    defaultPhase,
    expandedGroups = emptyArray,
    columnsState,
    viewType,
    shouldGridBeUnmounted,

    saveGridConfig,
    getSystemFieldName,
    createUnsavedProject,
    openProjectInfo,
    openOkrInfo,
    setSelectedItems,
    updateProjectById,
    loadParentProjects,
    saveColumnsState,
  },
) => {
  const projectsData = useProjectsWithDeliverables(data);

  const {
    processedData,

    getGridIsReady,
    getGridApi,

    handleGridReady,
    handleRowGroupOpened,
    handleSelectionChanged,
    handleDisplayedColumnsChanged,
    handleColumnResized,
    checkIsGroupOpenByDefault,
    addNewInlineProject,
    openRowInfo,
    updateProjectByIdOnCellValueChange: updateProjectByIdOnCellValueChangeFromIdeasGrid,
    loadParentProjectsOptions,
    handleMetadataCellDoubleClick,
  } = useIdeasGrid(projectsData, {
    parentHandleGridReady,
    displayLayer,
    portfolioMode,
    currentUser,
    hasBet,
    defaultPhase,
    expandedGroups,
    columnsState,
    viewType,
    shouldGridBeUnmounted,

    saveGridConfig,
    getSystemFieldName,
    createUnsavedProject,
    openProjectInfo,
    openOkrInfo,
    setSelectedItems,
    updateProjectById,
    loadParentProjects,
    saveColumnsState,
  });
  const dispatch = useDispatch();

  const isProjectsDeliverablesUpdating = useSelector(isSomeUpdateOnProjectsDeliverablesOcurring);

  const { rowData } = useGridRowDataWithUpdateDataLoading(processedData, isProjectsDeliverablesUpdating, { getGridApi });

  const updateProjectByIdOnCellValueChange = useCallback(
    async (id, updatedData, previousValue, params) => {
      const {
        newValue,
        colDef: { field: updatedField },
      } = params;

      const isUpdatedFieldDeliverableField = updatedField.indexOf('deliverable_') !== -1;

      if (isUpdatedFieldDeliverableField) {
        const deliverableId = getDeliverableIdFromFieldName(updatedField);

        return dispatch(upsertProjectDeliverable(id, deliverableId, { status: newValue }));
      }

      return updateProjectByIdOnCellValueChangeFromIdeasGrid(id, updatedData, previousValue, params);
    },
    [dispatch, deleteProjectDeliverable, upsertProjectDeliverable, updateProjectByIdOnCellValueChangeFromIdeasGrid],
  );

  return {
    processedData: rowData,

    getGridIsReady,
    getGridApi,

    handleGridReady,
    handleRowGroupOpened,
    handleSelectionChanged,
    handleDisplayedColumnsChanged,
    handleColumnResized,
    checkIsGroupOpenByDefault,
    addNewInlineProject,
    openRowInfo,
    updateProjectByIdOnCellValueChange,
    loadParentProjectsOptions,
    handleMetadataCellDoubleClick,
  };
};

export default useCycleDeliverablesGrid;
