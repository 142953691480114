import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  dependencyLinesToggleAction,
  allDependenciesToggleAction,
  itemsWithoutDependenciesToggleAction,
} from 'store/summary';
import {
  addProjectProjectDependency,
  removeProjectProjectDependency,
} from 'store/dependencies/thunks';

import { getSliceProp as getSummarySliceProp } from 'store/summary/selectors';


const useSummaryDependencyLinesManagement = () => {
  const dispatch = useDispatch();

  const showDependencyLines = useSelector(
    getSummarySliceProp('showDependencyLines'),
  );

  const showAllDependencies = useSelector(
    getSummarySliceProp('showAllDependencies'),
  );

  const hideItemsWithoutDependencies = useSelector(
    getSummarySliceProp('hideItemsWithoutDependencies'),
  );

  const dependencyLinesToggle = useCallback(
    () => dispatch(dependencyLinesToggleAction()),
    [dispatch],
  );

  const allDependenciesToggle = useCallback(
    () => dispatch(allDependenciesToggleAction()),
    [dispatch],
  );

  const itemsWithoutDependenciesToggle = useCallback(
    () => dispatch(itemsWithoutDependenciesToggleAction()),
    [dispatch],
  );

  const addProjectDependency = useCallback(
    (sourceId, targetId, dependencyType = null) => dispatch(
      addProjectProjectDependency(sourceId, targetId, dependencyType),
    ),
    [dispatch],
  );

  const deleteProjectDependency = useCallback(
    (sourceId, targetId) => dispatch(
      removeProjectProjectDependency(sourceId, targetId),
    ),
    [dispatch],
  );

  return {
    actions: {
      dependencyLinesToggle,
      allDependenciesToggle,
      itemsWithoutDependenciesToggle,
      addProjectDependency,
      deleteProjectDependency,
    },

    showDependencyLines,
    showAllDependencies,
    hideItemsWithoutDependencies,
  };
};

export default useSummaryDependencyLinesManagement;