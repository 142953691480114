import { defaultTo, prop, propEq, pipe, isNil, isEmpty, head } from 'ramda';

import { NEW_ROW_ID } from 'design-system/molecules/AgGridReact-New/helpers';
import { OBJECT_OBJECTIVE } from 'store/objectives/types';

import { METADATA_LEVELS } from 'constants/common';

const defaultToEmptyObject = defaultTo({});
const isTypeObjective = pipe(defaultToEmptyObject, propEq('type', OBJECT_OBJECTIVE));
const isLevelCorp = pipe(defaultToEmptyObject, propEq('level', METADATA_LEVELS.LEVEL_CORP));
const isLevelNil = pipe(prop('level'), isNil);

const getRowId = ({ data }) => data.uniqueId || NEW_ROW_ID;

const getDataPath = data => data.path;

const checkDeleteVisibility = () => false;

const checkDeleteDisabled = () => true;

const makeCheckAddVisibility =
  (allowedLevel, { hasKeyResults, organization, hasCorpLevel, isParentDragon } = {}) =>
  ({ data }) => {
    if (hasCorpLevel) {
      if (isParentDragon) {
        // Don't allow parent organizations to add inner levels
        return false;
      }

      if (!isLevelCorp(data) && data.organization_id !== organization.id) {
        // Don't allow inner levels to have the create button on items from other accounts (DoD)
        return false;
      }
    }

    if (isTypeObjective(data)) {
      return isLevelCorp(data) || hasKeyResults;
    }

    return isLevelNil(data) || +data.level < allowedLevel;
  };

const makeCheckCheckboxSelection =
  (orgId, orgAccessControl = []) =>
  ({ data, api }) => {
    if (data.organization_id !== orgId) {
      return false;
    }

    const isCorpObjective = orgAccessControl.some(access => access.portfolio_placeholder_objective_id === data.id);

    if (isCorpObjective) {
      return false;
    }

    const currentSelectedItems = api.getSelectedRows();

    return (
      isEmpty(currentSelectedItems) ||
      (head(currentSelectedItems).type === data.type && head(currentSelectedItems).level === data.level)
    );
  };

const checkIsEditable =
  (hasCorpLevel, organizationId) =>
  ({ data }) => {
    if (hasCorpLevel) {
      return data?.organization_id === organizationId || data?.level !== METADATA_LEVELS.LEVEL_CORP;
    }
    return data?.uniqueId.includes('KEY_RESULT');
  };

const getOKRGroupColumnTitle = (hasKeyResults, hasKeyResults2, hasCorpLevel, getSystemFieldName) => {
  const objectiveCorpFieldName = hasCorpLevel ? `${getSystemFieldName('objectiveCorp')} > ` : '';
  const objectiveFieldName = `${objectiveCorpFieldName}${getSystemFieldName('objective')}`;

  if (hasKeyResults) {
    const keyResult1FieldName = getSystemFieldName('keyResult1');

    const keyResult2FieldName = hasKeyResults2 ? ` > ${getSystemFieldName('keyResult2')}` : '';

    return `${objectiveFieldName} > ${keyResult1FieldName}${keyResult2FieldName}`;
  }

  return objectiveFieldName;
};

export {
  getRowId,
  getDataPath,
  checkDeleteVisibility,
  checkDeleteDisabled,
  makeCheckAddVisibility,
  makeCheckCheckboxSelection,
  checkIsEditable,
  getOKRGroupColumnTitle,
};
