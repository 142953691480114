import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { both, pipe, isEmpty, isNil, not, path, defaultTo } from 'ramda';

import {
  fetchADOProjects,
  getADOProjects,
  isFetchADOProjectLoading,
  resetADOProjects,
  isFetchADOUninitialized,
} from 'store/integrations';
import { getOrganization } from 'store/organization/selectors';
import { getCurrentUser } from 'store/login/selectors';
import { enableADOWebhooks } from 'store/organization';
import { getNotificationById } from 'store/app';

import useUserIntegrations from 'hooks/useUserIntegrations';
import useAppNotifications, { notificationTypes } from 'hooks/useAppNotifications';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

const DATA = 'data';
const TYPE = 'type';

const hasNotWebhooksCreated = pipe(path(['data', 'webhooks']), both(Array.isArray, pipe(isEmpty, not)), not);

const isNotNil = pipe(isNil, not);

const defaultToEmptyObject = defaultTo({});

const getAuthType = pipe(defaultToEmptyObject, path([DATA, TYPE]));

const AzureDevopsHOC = Component => {
  return props => {
    const {
      integration,
      userIntegrations,
      linkProjectIntegration,
      unlinkProjectIntegration,
      projectIntegrations,
      createProjectIntegration,
      linkErrorMsg,
      createErrorMsg,
      addUserIntegrationError,
      isLinking,
      isCreating,
    } = props;
    const dispatch = useDispatch();

    const organization = useSelector(getOrganization);
    const currentUser = useSelector(state => getCurrentUser(state));

    const isLoading = useSelector(isFetchADOProjectLoading);
    const adoProjectsData = useSelector(getADOProjects);
    const isAdoProjectUninitialized = useSelector(isFetchADOUninitialized);

    const { fetchUserIntegrations, addUserIntegrationByAuthType } = useUserIntegrations(
      INTEGRATIONS_KEYS.azuredevops,
      integration.id,
    );

    const createWorkItemWarning = useSelector(state =>
      getNotificationById(state, notificationTypes.CREATE_AZURE_DEVOPS_WORK_ITEM_VALIDATION_ERROR),
    );

    const { addNotification, removeNotification } = useAppNotifications();

    const azureProjects = useMemo(() => adoProjectsData?.data ?? [], [adoProjectsData]);

    const [taskLink, setTaskLink] = useState('');
    const [projectId, setProjectId] = useState('');

    const fetchAzureProjects = useCallback(() => {
      if (integration && !isLoading && isAdoProjectUninitialized) {
        dispatch(fetchADOProjects(integration.id));
      }
    }, [integration, isLoading, isAdoProjectUninitialized]);

    useEffect(() => {
      dispatch(resetADOProjects());
    }, [integration?.id]);

    useEffect(() => {
      if (projectIntegrations && projectIntegrations.length && integration) {
        projectIntegrations.forEach(pi => {
          if (pi.org_integration_id === integration.id) {
            setTaskLink(pi.data._links.html.href);
          }
        });
      }
    }, [projectIntegrations, integration]);

    useEffect(() => {
      return () => {
        if (isNotNil(createWorkItemWarning)) {
          removeNotification(createWorkItemWarning.id);
        }
      };
    }, [createWorkItemWarning]);

    const handleLinkTask = () => {
      if (taskLink) {
        try {
          const regexp = /edit\/(\d+)(?!\/$)?|(^\d+)$/g;

          const match = regexp.exec(taskLink);

          const id = match[1] || match[2];

          if (id) linkProjectIntegration(id, { org_integration_id: integration.id });
        } catch (err) {
          console.error(err);
        }
      }
    };

    const handleUnlinkTask = () => {
      if (projectIntegrations && projectIntegrations.length > 0) {
        unlinkProjectIntegration(projectIntegrations[0].id, { org_integration_id: integration.id });
        setTaskLink('');
      }
    };

    const handleCreateTask = () => {
      createProjectIntegration({ azure_project_id: projectId, org_integration_id: integration.id });
    };

    const handleAddUserIntegration = token => {
      const authType = getAuthType(integration);

      return addUserIntegrationByAuthType(authType, token)
        .then(() => {
          addNotification({ id: 'ado-successfully-integrated', message: 'Successfully integrated' });
          fetchUserIntegrations();
          dispatch(fetchADOProjects(integration.id));

          if (hasNotWebhooksCreated(integration)) {
            dispatch(enableADOWebhooks(organization.id, currentUser.id, integration.id)).then(() => {
              addNotification({ id: 'ado-webhooks-enabled-successfully', message: 'Webhooks enabled successfully' });
            });
          }
        })
        .catch(() => {
          addNotification({ id: 'ado-integration-failed', message: 'Integration failed' });
        });
    };

    return (
      <Component
        integration={integration}
        userIntegrations={userIntegrations}
        projectIntegrations={projectIntegrations}
        linkProjectIntegration={linkProjectIntegration}
        handleLinkTask={handleLinkTask}
        handleUnlinkTask={handleUnlinkTask}
        taskLink={taskLink}
        setTaskLink={setTaskLink}
        isLinking={isLinking}
        projectId={projectId}
        setProjectId={setProjectId}
        handleCreateTask={handleCreateTask}
        isCreating={isCreating}
        azureProjects={azureProjects}
        linkErrorMsg={linkErrorMsg}
        createErrorMsg={createErrorMsg}
        addUserIntegration={handleAddUserIntegration}
        addUserIntegrationError={addUserIntegrationError}
        fetchAzureProjects={fetchAzureProjects}
        fetchUserIntegrations={fetchUserIntegrations}
        isLoading={isLoading}
        createWorkItemWarning={createWorkItemWarning}
      />
    );
  };
};

export default AzureDevopsHOC;
