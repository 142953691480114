import React from 'react';
import styled from 'styled-components';

import HelpIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';
import InlineIcon from 'design-system/atoms/InlineIcon/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


export default ({ label }) => {
  return (
    <Tooltip enterDelay={200} title={label}>
      <TextDeprecated inline>
        <StyledIcon>
          <HelpIcon fontSize="small" />
        </StyledIcon>
      </TextDeprecated>
    </Tooltip>
  );
};

const StyledIcon = styled(InlineIcon)`
  &&&&& {
    font-size: ${({ theme }) => theme.typography.fontSizeSmall}px;
    padding: 0px;
  }
`;
