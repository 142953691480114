import axios from "axios";

import { createThunk } from "utils/store/thunk";

import { FETCH_SELF_GUIDED_DEMO_DATA } from "./types";

export const fetchSelfGuidedDemoData = () =>
  createThunk(
    FETCH_SELF_GUIDED_DEMO_DATA,
    axios.get('/api/organizations/self-guided-demo-data').then(res => res.data),
  );
