import AgGridMultiSelectRenderer from 'design-system/atoms/AgGridMultiSelectRenderer/index';

import { defaultTo, last } from 'ramda';

import { applyCheckIsEditable } from '../helpers';
import { MultiLevelSelectCellEditor } from '../cellEditors';
import { stringComparator } from '../comparators';

/**
 * @function metadataRoadmapComparator
 *
 * Specific comparator for metadata roadmap
 *
 * On this field we have different formats in multiple rows, because of that
 * is not possible to use a generic comparator
 *
 * @param  {Array} a
 * @param  {Array} b
 * @param  {type} _1
 * @param  {type} _2
 * @param  {Boolean} isInverted
 * @return {Number}
 */
const metadataRoadmapComparator = (a, b, _1, _2, isInverted) => {
  /*
   * After user change the applies to the value has Portfolio Placeholder as first item of the array
   *
   * But on the grid this item is not shown causing issue on sort items
   * For that reason if value has more than one item is using the second item
   */
  const hasRoadmapSelectedAndFirstItemIsPlaceholderRoadmapOnA = a?.length > 1 && !a?.[0]?.roadmap;
  const hasRoadmapSelectedAndFirstItemIsPlaceholderRoadmapOnB = b?.length > 1 && !b?.[0]?.roadmap;
  const aIndex = hasRoadmapSelectedAndFirstItemIsPlaceholderRoadmapOnA ? 1 : 0;
  const bIndex = hasRoadmapSelectedAndFirstItemIsPlaceholderRoadmapOnB ? 1 : 0;

  const roadmapTitleA = a?.[aIndex]?.roadmap?.title;
  const roadmapTitleB = b?.[bIndex]?.roadmap?.title;

  const valueA = roadmapTitleA ?? a?.[0]?.name ?? a?.[0]?.title;
  const valueB = roadmapTitleB ?? b?.[0]?.name ?? b?.[0]?.title;

  return stringComparator(valueA, valueB, _1, _2, isInverted);
};

const getMetadataRoadmapColumnDef = ({
  roadmapsMetadata = [],
  field,
  editable = true,
  createCustomCellRenderer,
  idKey = 'id',
  ...remainingOptions
}) => {
  const [
    anchorEl,
    formatMetadataRoadmaps,
    formatRoadmaps,
    handleClearAllItems,
    roadmaps,
    updateAnchorEl,
    updateSelectedItems,
    addDefaultMetadataRoadmapName,
    ,
    getSelectedItemsRef,
  ] = roadmapsMetadata;

  const hideMetadataRoadmaps = last(roadmapsMetadata);

  return {
    field,
    editable: applyCheckIsEditable(editable),
    headerName: 'Applies to',
    keyCreator: ({ value }) => {
      return value && Array.isArray(value) ? value.map(c => c.title) : [null];
    },
    formatValue: formatMetadataRoadmaps,
    hide: hideMetadataRoadmaps,
    labelField: 'title',
    onCellClicked: event => {
      const { data } = event;

      if (data && applyCheckIsEditable(editable)(event)) {
        updateAnchorEl(event.event.target);
      }
    },
    overrideCellValueChanged: true,
    cellRenderer: createCustomCellRenderer ? createCustomCellRenderer(AgGridMultiSelectRenderer) : AgGridMultiSelectRenderer,
    cellRendererParams: params => ({
      fullWidth: params.value?.length === 1,
      getNOfTextRowsToDisplay: () => 1,
      showTooltip: true,
    }),
    cellEditorPopup: true,
    comparator: metadataRoadmapComparator,
    cellEditor: MultiLevelSelectCellEditor,
    cellEditorParams: params => {
      return {
        anchorEl: anchorEl.current,
        data: params.data,
        handleClear: id => handleClearAllItems(id),
        options: formatRoadmaps(roadmaps || []),
        getSelectedItems: () => getSelectedItemsRef.current(params.data[idKey]),
        setSelectedItems: (x, data) => updateSelectedItems(x, data),
        width: params.column.getActualWidth(),
        showTooltip: true,
      };
    },
    ...remainingOptions,
    valueGetter: getterParams => {
      const { data } = getterParams;

      const fieldValues = defaultTo([], data?.[field]);

      const values = remainingOptions.valueGetter ? remainingOptions.valueGetter(getterParams) : fieldValues;

      return addDefaultMetadataRoadmapName({ value: values, data });
    },
  };
};

export default getMetadataRoadmapColumnDef;
