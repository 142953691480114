import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  text: {
    margin: theme.spacing.unit,
    lineHeight: 1.5,
  },
});

export default withStyles(styles)((props) => (
  <div>
    <h3 className={props.classes.text}>
      { props.children }
    </h3>
  </div>
));
