import React from 'react';
import styled from 'styled-components';

const ChangeCellRenderer = ({ value }) => {
  const getTextColorByValue = () => {
    if (!value) {
      return 'inherit';
    }

    const numberValue = value.replace('%', '');

    if (Number.isNaN(numberValue) || +numberValue === 0) return 'inherit';

    return +numberValue < 0 ? 'red' : 'green';
  };

  if (!value) {
    return '';
  }

  return <Wrapper $textColor={getTextColorByValue()}>{value}</Wrapper>;
};

export default ChangeCellRenderer;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  &&&& {
    ${({ $textColor }) => `
      color: ${$textColor};
    `};
  }
`;
