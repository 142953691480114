import { not, pipe, prop, propEq } from 'ramda';
import sumBy from 'lodash/sumBy';

import { calcPointsVariance } from 'store/projects/helpers/getProjectPointsValues';
import { IDEA_LAYER } from 'store/projects/constants';

const DISPLAY_LAYER = 'displayLayer';
const GROUP = 'group';
const LAYER = 'layer';
const PLANNED_POINTS = 'plannedPoints';
const REPORTED_POINTS = 'reportedPoints';

const isGroup = propEq(GROUP, true);
const isNotGroup = pipe(isGroup, not);

const getDisplayLayer = prop(DISPLAY_LAYER);
const getLayer = prop(LAYER);
const getPlannedPoints = prop(PLANNED_POINTS);
const getReportedPoints = prop(REPORTED_POINTS);
const isIdea = propEq(LAYER, IDEA_LAYER);
/**
 * AG-Grid Multi column aggregation docs
 *  - https://www.ag-grid.com/angular-data-grid/aggregation-custom-functions/#multi-column-aggregation
 */
const sumPointsVarianceField = params => {
  const {
    data,
    values,
    colDef: { field, cellRendererParams },
  } = params;

  // while initiatives and bet do not have estimation by points, they should be treated as group to sum up all
  if (data && isNotGroup(data) && isIdea(data)) {
    const isSameLayer = getLayer(data) === getDisplayLayer(cellRendererParams);

    return {
      plannedPoints: isSameLayer ? getPlannedPoints(data) : 0,
      reportedPoints: isSameLayer ? getReportedPoints(data) : 0,
      toString: () => data[field],
    };
  }

  const totalPlannedPoints = sumBy(values, PLANNED_POINTS);
  const totalReportedPoints = sumBy(values, REPORTED_POINTS);

  return {
    plannedPoints: totalPlannedPoints,
    reportedPoints: totalReportedPoints,
    toString: () => calcPointsVariance(totalPlannedPoints, totalReportedPoints),
  };
};

export default sumPointsVarianceField;
