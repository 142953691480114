let setTimeoutId;

const cleanupSetTimeout = () => {
  clearTimeout(setTimeoutId);

  setTimeoutId = null;
};

const focusOnCell = (gridApis, column) => {
  if (!column) {
    return;
  }

  const columnToFocus = gridApis.columnApi.getAllGridColumns().find(col => col.colDef.field === column);

  const renderedNodes = gridApis.api.getRenderedNodes();
  const rowIndexToFocus = renderedNodes.find(node => !node.group && !node?.data?.id)?.rowIndex;

  if (rowIndexToFocus >= 0) {
    if (setTimeoutId != null) {
      cleanupSetTimeout();
    }

    // Could not find a solution to deal with the aggrid animations
    setTimeoutId = setTimeout(() => {
      const currentRenderedNodes = gridApis.api.getRenderedNodes();

      // Check if after 300 ms we still want to focus
      const currentRowIndexToFocus = currentRenderedNodes.find(node => !node.group && !node?.data?.id)?.rowIndex;

      if (currentRowIndexToFocus >= 0) {
        gridApis.api.setFocusedCell(rowIndexToFocus, columnToFocus);
        gridApis.api.startEditingCell({
          rowIndex: rowIndexToFocus,
          colKey: columnToFocus?.colId,
        });
      }

      cleanupSetTimeout();
    }, 300);
  }
};

export { focusOnCell };
