/**
 * SelectableItemWithSelect Molecule Component
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import styled from 'styled-components';

import Autocomplete from 'design-system/atoms/Autocomplete/index';

import SelectableItem from '../SelectableItem';

const SelectableItemWithSelect = ({ label, selected, onChange, selectedOption, options, onSelectChange, ...props }) => {
  return (
    <Container {...props}>
      <StyledSelectableItem label={label} selected={selected} onChange={onChange} />
      {options?.length ? (
        <SelectContainer selected={selected}>
          <StyledAutocomplete value={selectedOption} suggestions={options} onValueChange={onSelectChange} hideBottomBorder />
        </SelectContainer>
      ) : null}
    </Container>
  );
};

export default SelectableItemWithSelect;

const Container = styled.div`
  display: flex;
  align-items: stretch;
`;

const StyledSelectableItem = styled(SelectableItem)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 100px;
  padding: 0 ${({ theme }) => theme.spacing.unit * 2}px;
  border: ${({ theme, selected }) =>
    `1px solid ${selected ? theme.palette.newLayout.background.duskBlue : theme.palette.newLayout.background.pastelGrey}`};
  border-left: none;
  border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;

  input {
    font-size: ${({ theme }) => theme.typography.fontSize}px;
    cursor: pointer;
  }

  .autocomplete-input-icon {
    top: 2px;
    justify-content: flex-end;
    cursor: pointer;

    svg {
      font-size: ${({ theme }) => theme.typography.fontSizeMedium}px;
    }
  }
`;
