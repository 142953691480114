import React from 'react';

import FluidTreeDropdownFieldWithShowAll from 'design-system/organisms/FluidTreeDropdownFieldWithShowAll/index';

import { handleProductSelection } from './helpers';

const RoadmapsTreeDropdown = props => {
  const { options = [], okrSelectedArchived } = props;

  const handleSelectOption = (selectedValue, save) => {
    // User selected a Product level 1 or 2 (roadmap_id exists for those cases)
    if ('roadmap_id' in selectedValue) {
      handleProductSelection(options, selectedValue, save);
    } else {
      // User selected roadmap
      save({ roadmapTitle: selectedValue.title, product1Title: null, product2Title: null });
    }
  };

  return (
    <FluidTreeDropdownFieldWithShowAll
      {...props}
      options={options}
      isArchived={okrSelectedArchived}
      handleChange={handleSelectOption}
      disablePortal={false}
    />
  );
};

export default RoadmapsTreeDropdown;
