import React from 'react';

import NewDialog from 'design-system/organisms/NewDialog/index';
import TextAreaEditor from 'design-system/organisms/TextAreaEditor/index';


import Actions from './Actions';

export default props => {
  const { isOpen, message, onConfirm, onCancel, onChange } = props;

  return (
    <NewDialog
      id="email-request-watchers"
      title="Email Request Watchers"
      open={isOpen}
      closeButton
      onClose={onCancel}
      maxWidth="sm"
      fullWidth
      actions={<Actions onConfirm={onConfirm} onCancel={onCancel} />}
    >
      <TextAreaEditor value={message} onChange={onChange} disableToolbox style={{ border: '1px solid #eee', padding: '16px' }} />
    </NewDialog>
  );
};
