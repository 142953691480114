import React from 'react';
import styled from 'styled-components';

import TabButton from 'design-system/atoms/TabButton';

const TreeViewToggle = ({ isTreeView, onChange, className }) => {
  return (
    <Container id="tree_view" className={className}>
      <TabButton active={isTreeView} onClick={onChange} data-testid="button-tree_view">
        Tree View
      </TabButton>
    </Container>
  );
};

export default TreeViewToggle;

const Container = styled.div``;
