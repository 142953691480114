import { DEFAULT_SETTINGS_NAME } from 'config';
import pluralize from 'pluralize';
import { defaultTo } from 'ramda';

const defaultToEmptyString = defaultTo('');

export default (key, systemFields, plural) => {
  const renamedField = systemFields && Array.isArray(systemFields) && systemFields.find(f => f.key === key);
  const defaultName = DEFAULT_SETTINGS_NAME[key];

  const value = !renamedField ? defaultName || key : renamedField.title || defaultName;

  // if value contains a trailing space, the pluralize method will add an 'S' after the space
  const fieldName = defaultToEmptyString(value).trim();

  return plural ? pluralize(fieldName) : fieldName;
};
