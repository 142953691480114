import React from 'react';

import { FILTERS_FUNCTIONS } from 'design-system/constants';

import FieldWrapper from './FieldWrapper';
import FieldSingleSelect from './FieldSingleSelect';

export default ({ value, onChange, fullWidth, width, fieldFunction }) => {
  const isEmptyFunction = () => fieldFunction && fieldFunction.key === FILTERS_FUNCTIONS.isEmpty.key;

  if (isEmptyFunction()) return '';

  const booleanCustomFieldOptions = [
    { id: true, title: 'Yes' },
    { id: false, title: 'No' },
  ];

  const booleanCustomFieldSelectValue = booleanCustomFieldOptions.find(opt => opt.id === value);

  return (
    <FieldWrapper
      input
      text
      style={{ flexBasis: width || 'auto', flexGrow: fullWidth && 1 }}
      data-testid="filters-checkbox-input"
    >
      <FieldSingleSelect
        options={booleanCustomFieldOptions}
        value={booleanCustomFieldSelectValue?.title || 'No'}
        handleChange={() => onChange(!value)}
      />
    </FieldWrapper>
  );
};
