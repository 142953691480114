export const SHOW_DELETING_IDEA_CONFIRMATION = 'SHOW_DELETING_IDEA_CONFIRMATION';
export const HIDE_DELETING_IDEA_CONFIRMATION = 'HIDE_DELETING_IDEA_CONFIRMATION';

export const SHOW_LIGHTBOX = 'SHOW_LIGHTBOX';
export const HIDE_LIGHTBOX = 'HIDE_LIGHTBOX';
export const SELECT_PROJECT_LIGHTBOX_TAB = 'SELECT_PROJECT_LIGHTBOX_TAB';

export const SHOW_IDEAS_COPY_PASTE_LIGHTBOX = 'SHOW_IDEAS_COPY_PASTE_LIGHTBOX';
export const HIDE_IDEAS_COPY_PASTE_LIGHTBOX = 'HIDE_IDEAS_COPY_PASTE_LIGHTBOX';
export const SET_IDEAS_COPY_PASTE_LIGHTBOX_DATA = 'SET_IDEAS_COPY_PASTE_LIGHTBOX_DATA';

export const SHOW_IMPORT_IDEAS_LIGHTBOX = 'SHOW_IMPORT_IDEAS_LIGHTBOX';
export const HIDE_IMPORT_IDEAS_LIGHTBOX = 'HIDE_IMPORT_IDEAS_LIGHTBOX';
export const SHOW_IMPORT_JQL_LIGHTBOX = 'SHOW_IMPORT_JQL_LIGHTBOX';
export const HIDE_IMPORT_JQL_LIGHTBOX = 'HIDE_IMPORT_JQL_LIGHTBOX';

export const SHOW_IMPORT_TIP = 'SHOW_IMPORT_TIP';
export const HIDE_IMPORT_TIP = 'HIDE_IMPORT_TIP';

export const SHOW_IMPORT_ADO_QUERY_LIGHTBOX = 'SHOW_IMPORT_ADO_QUERY_LIGHTBOX';
export const HIDE_IMPORT_ADO_QUERY_LIGHTBOX = 'HIDE_IMPORT_ADO_QUERY_LIGHTBOX';

export const SHOW_IMPORT_DRAGONBOAT_FILTER_LIGHTBOX = 'SHOW_IMPORT_DRAGONBOAT_FILTER_LIGHTBOX';
export const HIDE_IMPORT_DRAGONBOAT_FILTER_LIGHTBOX = 'HIDE_IMPORT_DRAGONBOAT_FILTER_LIGHTBOX';

export const SHOW_IMPORT_RALLY_VIEW_LIGHTBOX = 'SHOW_IMPORT_RALLY_VIEW_LIGHTBOX';
export const HIDE_IMPORT_RALLY_VIEW_LIGHTBOX = 'HIDE_IMPORT_RALLY_VIEW_LIGHTBOX';
