import { propOr, pathOr, defaultTo, path } from 'ramda';

const isAllocationDataset = path(['dataset', 'isAllocationDataset']);
const getTitleLabel = pathOr('', ['dataset', 'label']);
const getTitleValue = propOr('', 'formattedValue');
const getAllocationTitleValue = pathOr('', ['raw', 'formatted']);

const formatTooltipTitle = (tooltipItem, usePercentages) => {
  const shouldUsePercentages = !isAllocationDataset(tooltipItem) && usePercentages;

  const value = isAllocationDataset(tooltipItem) ? getAllocationTitleValue(tooltipItem) : getTitleValue(tooltipItem);
  const label = getTitleLabel(tooltipItem);

  return `${label}: ${value}${shouldUsePercentages ? '%' : ''}`;
};

export const getMetricChartTooltipTitle = usePercentages => tooltipItems => {
  return defaultTo([])(tooltipItems)
    .map(tooltipItem => formatTooltipTitle(tooltipItem, usePercentages))
    .join('\n');
};

const DEFAULT_X_EXTRACTOR = pathOr('', ['raw', 'x']);
const LABEL_EXTRACTOR = pathOr('', ['raw', 'label']);

export const getMetricChartTooltipLabel = (tooltipItem, xExtractor = DEFAULT_X_EXTRACTOR) => {
  const date = xExtractor(tooltipItem);

  if (isAllocationDataset(tooltipItem)) {
    return LABEL_EXTRACTOR(tooltipItem);
  }

  return `${date}`;
};
