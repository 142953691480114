import axios from 'axios';
import { defaultTo, either, isEmpty, isNil } from 'ramda';

import { addQueryParamToUrl, removeQueryParamFromUrl } from 'utils/queryParamsUtils';
import isProjectKey from 'utils/projects/isProjectKey';

import { OPEN_IDEA, PROJECT_LIGHTBOX_TAB_QUERY_PARAM } from 'constants/queryParams';

import { getSelectedProject } from 'store/projects/selectors';
import {
  OPEN_PROJECT_LIGHTBOX,
  OPEN_PROJECT_LIGHTBOX_WITH_SCENARIO,
  OPEN_LIGHTBOX_TO_CREATE_PROJECT,
  OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_OKR,
  OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_REQUEST,
  CLOSE_PROJECT_LIGHTBOX,
  GET_PROJECT_INTEGRATION_PROGRESS,
  SET_DATE_RANGE_MODE,
  SET_VISIBLE_FIELDS,
  SET_CURRENT_PROJECT_PARENT,
  OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_METRIC,
} from './types';
import { loadMetadataForProjectIds } from 'features/MetadataOnDemand/store/network';
import { PLAN_OF_RECORD_ID } from 'constants/common';
import { createThunk } from 'utils/store/thunk';
import { fetchVersionsProjectsById } from 'store/roadmapVersions';
import { getAvailableRoadmapVersions } from 'store/roadmapVersions/selectors';
import { TAB_DETAILS } from 'constants/projectLightbox';

const isNilOrEmpty = either(isNil, isEmpty);

export const setVisibleFields = selectedFields => {
  return {
    type: SET_VISIBLE_FIELDS,
    payload: selectedFields,
  };
};

export const setCurrentProjectParent = parentData => {
  return {
    type: SET_CURRENT_PROJECT_PARENT,
    payload: { parentData },
  };
};

export const setDateRangeMode = value => ({ type: SET_DATE_RANGE_MODE, payload: value });

export const closeProjectLightbox = () => {
  removeQueryParamFromUrl(OPEN_IDEA);
  removeQueryParamFromUrl(PROJECT_LIGHTBOX_TAB_QUERY_PARAM);

  return { type: CLOSE_PROJECT_LIGHTBOX };
};

/**
 * @function fetchProject
 *
 * Fetch a project by Id or by Key.
 *
 * @param {Number|String} projectId
 * @returns {Promise<Object>}
 */
const fetchProject = async projectId => {
  const request = await axios.get(`/api/projects/${projectId}`);

  return request.data;
};

const getOpenLightboxPromise = (id, state, basePayload) =>
  new Promise(async (resolve, reject) => {
    let projectInfo = getSelectedProject(state, basePayload);
    let parentProjectInfo = null;

    try {
      if (!projectInfo) {
        const project = await fetchProject(id);

        projectInfo = { ...project };
      }

      const infoRequests = [
        loadMetadataForProjectIds([projectInfo.id]),
        axios.get(`/api/projects/${projectInfo.id}/integration-progress`),
      ];

      if (isNilOrEmpty(projectInfo.Jiras)) {
        infoRequests.push(axios.get(`/api/projects/${projectInfo.id}/jiras`));
      }

      const [metadataOnDemand, integrationProgressResponse, jirasResponse] = await Promise.all(infoRequests);

      projectInfo = {
        ...projectInfo,
        Jiras: defaultTo(projectInfo.Jiras, jirasResponse?.data),
        integrationProgress: integrationProgressResponse.data,
      };

      if (projectInfo.parent_id) {
        try {
          parentProjectInfo = await fetchProject(projectInfo.parent_id);
        } catch (e) {
          if (window.Rollbar) {
            window.Rollbar.warn(e);
          }
        }
      }

      resolve({ projectData: projectInfo, parentData: parentProjectInfo, metadataOnDemand });
    } catch (error) {
      reject(error);
    }
  });

const getOpenLightboxWithScenarioPromise = async (projectId, scenarioId) => {
  const projectDataResponse = await fetchVersionsProjectsById([scenarioId], [projectId]);

  const projectInfo = (projectDataResponse || []).find(project => project.id === projectId) || {};

  let parentInfo = null;

  if (projectInfo.parent_id) {
    const parentDataResponse = await fetchVersionsProjectsById([scenarioId], [projectInfo.parent_id]);

    parentInfo = (parentDataResponse || []).find(project => project.id === projectInfo.parent_id) || {};
  }

  const metadataOnDemand = await loadMetadataForProjectIds([projectId]);

  return {
    projectData: projectInfo,
    parentData: parentInfo,
    metadataOnDemand,
  };
};

export const openProjectLightbox =
  (id, tab = TAB_DETAILS, defaultData, meta = {}) =>
  async (dispatch, getState) => {
    if (!id) {
      return dispatch({ type: OPEN_LIGHTBOX_TO_CREATE_PROJECT, payload: defaultData, meta });
    }

    const state = getState();
    const basePayload = isProjectKey(id) ? { key: id } : { id };

    addQueryParamToUrl(PROJECT_LIGHTBOX_TAB_QUERY_PARAM, tab);

    dispatch({
      payload: getOpenLightboxPromise(id, state, basePayload),
      type: OPEN_PROJECT_LIGHTBOX,
      meta: { ...basePayload, tab },
    });
  };

export const openProjectLightboxToCreateFromRequest = (requestId, defaultData = {}) => ({
  type: OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_REQUEST,
  payload: defaultData,
  meta: { requestId },
});

export const openProjectLightboxToCreateProjectFromMetric = (metricId, defaultData = {}) => ({
  type: OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_METRIC,
  payload: defaultData,
  meta: { metricId },
});

export const openProjectLightboxToCreateFromOkr = (okrId, okrType, defaultData) => ({
  type: OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_OKR,
  payload: defaultData,
  meta: { okrId, type: okrType },
});

export const openProjectLightboxWithScenario =
  (projectId, scenarioId, disabledFields = [], tab = TAB_DETAILS, defaultData = {}, meta = {}) =>
  async (dispatch, getState) => {
    if (!projectId) {
      return dispatch({ type: OPEN_LIGHTBOX_TO_CREATE_PROJECT, payload: defaultData, meta });
    }

    if (!scenarioId || scenarioId === PLAN_OF_RECORD_ID) {
      return dispatch(openProjectLightbox(projectId, tab, defaultData, meta));
    }

    const basePayload = isProjectKey(projectId) ? { key: projectId } : { id: projectId };

    const state = getState();
    const scenario = getAvailableRoadmapVersions(state).find(scenario => scenario.id === scenarioId);

    return dispatch(
      createThunk(OPEN_PROJECT_LIGHTBOX_WITH_SCENARIO, async () => {
        const projectInfo = await getOpenLightboxWithScenarioPromise(projectId, scenarioId);

        return {
          ...basePayload,
          ...projectInfo,
          scenario,
          tab,
          disabledFields,
        };
      }),
    );
  };

export const getProjectIntegrationProgress = id => async dispatch => {
  return dispatch({
    payload: axios.get(`/api/projects/${id}/integration-progress`),
    type: GET_PROJECT_INTEGRATION_PROGRESS,
    meta: {
      id,
    },
  });
};

export const actions = {
  closeProjectLightbox,
  openProjectLightbox,
};
