import { isCapacityUser } from 'utils';
import moment from 'moment-timezone';

import { INCLUDE_ALL_OPTION } from 'constants/projects';
import getRoundedValue from 'routes/Forecast/helpers/getRoundedValue';

/**
 * Returns the tasks that has resourcs associated
 *  - Task has team and skill
 *  - task has owner that are a capacity user and has team and skill
 *
 * @param {*} gantt
 * @param {*} teamId
 * @param {*} skillId
 */

const getResourceTasks = (gantt, resource, showUncommittedProjects = INCLUDE_ALL_OPTION) => {
  const res = [];
  const { teamId, skillId, userId } = resource;

  const isResourceTask = task => {
    const hasTasks = task.tasks && !!task.tasks.length;
    const hasEstimates = task.estimates && !!task.estimates.length;
    const isProject = task.dbType === gantt.config.types.project;

    if (showUncommittedProjects !== INCLUDE_ALL_OPTION && !task?.$committed) return false;
    if (task.unscheduled) return false;
    if (isProject && (hasTasks || hasEstimates)) return false;
    if (userId) return task.owner && task.owner_id === userId;
    if (task.dbType === 'estimate') return task.team_id === teamId && task.skill_id === skillId;
    if (task.dbType === 'task' && task.subtasks && !!task.subtasks.length) return false;

    return task.owner && isCapacityUser(task.owner) && task.owner.team_id === teamId && task.owner.skill_id === skillId;
  };

  gantt.eachTask(task => {
    if (isResourceTask(task)) {
      res.push(task);
    }
  });
  return res;
};

/**
 * Generates the timetable with resource values
 *
 * @param {*} gantt
 * @param {*} tasks
 * @param {*} scale
 * @param {*} selectedRounding
 */
const calculateResourceLoad = (gantt, tasks, scale, selectedRounding) => {
  const step = scale.unit;
  const timegrid = {};
  let stepLength;

  switch (step) {
    case 'month':
      stepLength = 30;
      break;
    case 'quarter':
      stepLength = 91.25;
      break;
    default:
      stepLength = 7;
      break;
  }

  for (let i = 0; i < tasks.length; i++) {
    const task = tasks[i];

    let currDate = gantt.date[`${step}_start`](new Date(task.start_date));

    while (currDate < new Date(task.end_date)) {
      const date = currDate;

      currDate = gantt.date.add(currDate, 1, step);

      if (!gantt.isWorkTime({ date, task })) {
        continue;
      }
      const startDiff = moment(task.start_date).diffDuration(moment(date), 'days', true);
      const endDiff = moment(currDate).diffDuration(moment(task.end_date), 'days', true);
      let value = task.numStaff || 1;

      if (task.effort_score) {
        value = task.effort_score / stepLength;
        if (value < 1) value = 1;
      }
      if (startDiff > 0) {
        value *= (stepLength - startDiff) / stepLength;
      }
      if (endDiff > 0) {
        value *= (stepLength - endDiff) / stepLength;
      }

      const timestamp = date.valueOf();

      if (!timegrid[timestamp]) {
        timegrid[timestamp] = {};
        timegrid[timestamp].value = 0;
      }

      if (value < 0) value *= -1;
      timegrid[timestamp].value += value;
    }
  }

  const timetable = [];
  let start;
  let end;

  Object.keys(timegrid).forEach(i => {
    start = new Date(i * 1);
    end = gantt.date.add(start, 1, step);
    timetable.push({
      start_date: start,
      end_date: end,
      value: getRoundedValue(timegrid[i].value, selectedRounding),
    });
  });

  return timetable;
};

/**
 * Function that renders on the gantt the HC Resource Line
 *
 * @param {*} options
 */
const renderHCResourceLine = ({
  resource,
  timeline,
  gantt,
  selectedTeam,
  selectedHighlight,
  selectedTimelineInterval,
  selectedRounding,
  onHCTableCellClick,
  showUncommittedProjects = INCLUDE_ALL_OPTION,
}) => {
  if (selectedTeam && resource.teamId !== selectedTeam.id) {
    return;
  }
  const tasks = getResourceTasks(gantt, resource, showUncommittedProjects);

  const scaleUnit = ['month', 'week'].includes(selectedTimelineInterval.key)
    ? { unit: selectedTimelineInterval.key }
    : timeline.getScale();
  const timetable = calculateResourceLoad(gantt, tasks, scaleUnit, selectedRounding);

  const row = document.createElement('div');

  if (resource.skillStaff === 0) {
    document.querySelectorAll(`[resource_id="${resource.id}"]`).forEach(node => {
      node.className += ' zeroStaff';
    });
  }

  for (let i = 0; i < timetable.length; i++) {
    const day = timetable[i];

    let css = '';

    if (selectedHighlight && selectedHighlight.key === 'overUnder') {
      if (!day.value) {
        css = 'workday_null';
      } else if (day.value < resource.skillStaff) {
        css = 'workday_idle';
      } else if (day.value === resource.skillStaff) {
        css = 'workday_ok';
      } else {
        css = 'workday_over';
      }
    } else if (!day.value) {
      css = 'workday_null';
    } else if (day.value <= resource.skillStaff) {
      css = 'workday_ok';
    } else {
      css = 'workday_over';
    }

    const sizes = timeline.getItemPosition(resource, day.start_date, day.end_date);
    const el = document.createElement('div');

    el.className = css;

    if (onHCTableCellClick) {
      el.onclick = e => onHCTableCellClick(gantt, resource);
    }

    el.style.cssText = [
      `left:${sizes.left}px`,
      `width:${sizes.width - 1}px`,
      'position:absolute',
      'cursor:pointer',
      `height:${gantt.config.row_height - 1}px`,
      `line-height:${sizes.height}px`,
      `top:${sizes.top}px`,
    ].join(';');

    el.innerHTML = day.value;
    row.appendChild(el);
  }

  return row;
};

export default renderHCResourceLine;
