import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';

import {
  CREATE_PHASE_FULFILLED,
  FETCH_PHASES_FULFILLED,
  UPDATE_PHASE_FULFILLED,
  ADD_PHASE_WITHOUT_SAVE,
  REMOVE_UNSAVED_PHASES,
  DELETE_PHASE_FULFILLED,
  MERGE_PHASES_FULFILLED,
  CREATE_PHASES_FULFILLED,
  UPDATE_PHASES_FULFILLED,
  UNDO_CREATE_PHASES_FULFILLED,
  UNDO_UPDATE_PHASES_FULFILLED,
  BULK_DELETE_PHASES_FULFILLED,
  UNDO_BULK_DELETE_PHASES_FULFILLED,
  UPDATE_PHASE_BY_ID_FULFILLED,
  UPDATE_PHASE_BY_ID_REJECTED,
  UPDATE_PHASE_ROW_ORDER_PENDING,
  UPDATE_PHASE_ROW_ORDER_FULFILLED,
  UPDATE_PHASE_ROW_ORDER_REJECTED,
  BULK_DELETE_PHASE_ROADMAP_FULFILLED,
  CREATE_PHASE_ROADMAP_FULFILLED,
  DELETE_PHASE_ROADMAP_FULFILLED,
} from './types';
import upsertListItem from '../utils/upsertListItem';
import addRows from 'store/utils/addRows';
import updateRows from 'store/utils/updateRows';

import sortByRank from 'utils/sortByRank';

const initialState = {
  phases: [],
  isLoaded: false,
};

function removeUnsaved(list) {
  return list.filter(el => el.id);
}

function updatePhase(phases, phase) {
  return phases.map(item => (item.id === phase.id ? phase : item));
}

export default function phasesReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_PHASE_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        return state;
      }

      let phases = state.phases || [];

      phases = removeUnsaved(phases);
      phases = upsertListItem(action.payload, phases);

      return {
        ...state,
        phases,
        isLoaded: true,
      };
    }
    case FETCH_PHASES_FULFILLED:
      return Object.assign({}, state, {
        phases: action.payload.data,
        isLoaded: true,
        lastCallsDate: moment().valueOf(),
      });
    case ADD_PHASE_WITHOUT_SAVE:
      const phases = state.phases ? removeUnsaved(state.phases) : [];

      phases.unshift(action.phase || { planningStage: 'Backlog' });

      return {
        ...state,
        phases,
      };
    case UPDATE_PHASE_BY_ID_FULFILLED:
    case UPDATE_PHASE_FULFILLED:
      return {
        ...state,
        phases: updatePhase(state.phases, action.payload),
        isLoaded: true,
      };
    case UPDATE_PHASE_BY_ID_REJECTED: {
      if (!action.meta.prevData) {
        return state;
      }

      return {
        ...state,
        phases: updatePhase(state.phases, action.meta.prevData),
        isLoaded: true,
      };
    }
    case UPDATE_PHASE_ROW_ORDER_REJECTED: {
      if (!action.meta || !action.meta.prev) return state;

      const {
        meta: { prev },
      } = action;

      let phases = state.phases ? [...state.phases] : [];

      phases = upsertListItem(prev, phases);

      return {
        ...state,
        phases: phases.sort(sortByRank),
      };
    }
    case UPDATE_PHASE_ROW_ORDER_PENDING: {
      if (!action.payload) {
        return state;
      }

      const phases = upsertListItem(action.payload, state.phases || []);

      return {
        ...state,
        phases: phases.sort(sortByRank),
      };
    }
    case UPDATE_PHASE_ROW_ORDER_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        console.error('UPDATE_PHASE_ROW_ORDER::Phase passed does not have id');
        return state;
      }

      const phaseToHaveNewRank = state.phases.find(phase => phase.id === action.payload.id);

      if (!phaseToHaveNewRank) {
        return state;
      }

      // Sync rank:

      const phases = upsertListItem(
        {
          ...phaseToHaveNewRank,
          rank: action.payload.rank,
        },
        state.phases || [],
      );

      return {
        ...state,
        phases: phases.sort(sortByRank),
      };
    }
    case REMOVE_UNSAVED_PHASES:
      return {
        ...state,
        phases: state.phases.filter(phase => phase.id),
      };
    case DELETE_PHASE_FULFILLED:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        phases: state.phases.filter(phase => phase.id !== action.payload),
      };
    case MERGE_PHASES_FULFILLED:
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        phases: state.phases.filter(phase => !action.payload.includes(phase.id)),
      };
    case CREATE_PHASES_FULFILLED: {
      const phases = addRows(state.phases, action.payload);

      return {
        ...state,
        phases,
        lastActionIds: action.payload.map(obj => obj.id),
      };
    }
    case UPDATE_PHASES_FULFILLED: {
      const phases = updateRows(state.phases, action.payload);

      return {
        ...state,
        phases,
        lastActionIds: action.payload.map(obj => obj.id),
      };
    }
    case BULK_DELETE_PHASES_FULFILLED: {
      const phases = state.phases.filter(r => !action.payload.includes(String(r.id)));

      return {
        ...state,
        phases,
        lastActionIds: action.payload,
      };
    }
    case UNDO_UPDATE_PHASES_FULFILLED: {
      const phases = updateRows(state.phases, action.payload);

      return {
        ...state,
        phases,
        lastActionIds: null,
      };
    }
    case UNDO_CREATE_PHASES_FULFILLED: {
      return {
        ...state,
        phases: state.phases.filter(c => state.lastActionIds.indexOf(c.id) === -1),
        lastActionIds: null,
      };
    }
    case UNDO_BULK_DELETE_PHASES_FULFILLED: {
      const phases = updateRows(state.phases, action.payload);

      return {
        ...state,
        phases,
        lastActionIds: null,
      };
    }
    case CREATE_PHASE_ROADMAP_FULFILLED:
      const existingPhases = cloneDeep(state.phases);
      const phase = existingPhases.find(obj => obj.id === action.payload.phase_id);

      phase.phase_roadmaps = [...(phase.phase_roadmaps || []), action.payload];
      phase.updated_at = action.payload.updated_at;

      const updatedPhases = upsertListItem(phase, existingPhases);

      return {
        ...state,
        phases: updatedPhases,
      };
    case DELETE_PHASE_ROADMAP_FULFILLED:
      const existingPhses = cloneDeep(state.phases);
      const { id: phaseId, phaseRoadmaps } = action.payload;
      const parent = existingPhses.find(obj => obj.id === parseInt(phaseId));

      parent.phase_roadmaps = phaseRoadmaps;

      const updated = upsertListItem(parent, existingPhses);

      return {
        ...state,
        phases: updated,
      };
    case BULK_DELETE_PHASE_ROADMAP_FULFILLED:
      const phs = cloneDeep(state.phases);
      const updatedPs = phs.map(phase => {
        if (phase.id === action.payload) {
          return {
            ...phase,
            phase_roadmaps: [],
          };
        }

        return phase;
      });

      return {
        ...state,
        phases: updatedPs,
      };
    default:
      return state;
  }
}
