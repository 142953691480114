import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import DialogActions from '@material-ui/core/DialogActions';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import ConfirmDialog from 'components/ConfirmDialog';

const MAX_FIRST_NAME_LENGTH = 10;

const getNameDisplayForButton = name =>
  name?.length > MAX_FIRST_NAME_LENGTH ? `${name.slice(0, MAX_FIRST_NAME_LENGTH)}...` : name;

export default function ChangeOwnerDialog({
  isOpen,
  onCancel,
  onChangeOwnerForAllViews,
  onChangeOwnerForSingleView,
  ownerUserOptions = [],
  selectedViewOwnerId,
  view,
}) {
  const { first_name: viewOwnerFirstName } = view?.user || {};

  const newOwner = ownerUserOptions.find(user => user.id === selectedViewOwnerId);

  return (
    <ConfirmDialog
      isOpen={isOpen}
      onCancel={onCancel}
      maxWidth="lg"
      showActions={false}
      showCloseIcon
      title="Update Owner"
      customActions={
        <StyledDialogActions>
          <Grid container>
            <Grid xs={1}>
              <Button id="cancel-button" onClick={onCancel}>
                Cancel
              </Button>
            </Grid>
            <Grid xs={11}>
              <EndButtonRow>
                <UpdateButton
                  id="change-all-button"
                  disabled={!selectedViewOwnerId}
                  color="primary"
                  onClick={onChangeOwnerForAllViews}
                >
                  Update all views owned by {getNameDisplayForButton(viewOwnerFirstName)}
                </UpdateButton>

                <UpdateButton
                  id="confirm-change-owner-button"
                  disabled={!selectedViewOwnerId}
                  color="primary"
                  onClick={onChangeOwnerForSingleView}
                >
                  Update this view
                </UpdateButton>
              </EndButtonRow>
            </Grid>
          </Grid>
        </StyledDialogActions>
      }
      extraContent={
        <ChangeOwnerWrapper>
          <TextDeprecated>
            Change owner from {viewOwnerFirstName} to <BoldText>{newOwner?.first_name}</BoldText>
          </TextDeprecated>
        </ChangeOwnerWrapper>
      }
    />
  );
}

const ChangeOwnerWrapper = styled.div`
  width: 650px;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 0 8px;
  max-width: 100%;
`;

const UpdateButton = styled(Button)`
  &&&&& {
    text-align: right;
  }
`;

const EndButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BoldText = styled.span`
  ${({ theme }) => `font-weight: ${theme.typography.fontWeightBold};`}
`;
