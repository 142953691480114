import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { uniq } from 'ramda';

import { getOrganization } from 'store/organization/selectors';

import useMetadataForAllocation from 'hooks/useMetadataForAllocation';
import useSystemFields from 'hooks/useSystemFields';

import { groupByOptions, sumByOptions, durationOptions, defaultTableColumns } from 'store/allocationReport/consts';
import { generatePieChartData, getSummaryDataFromPieData } from 'routes/Dashboard/AllocationReport/utils';
import { getDefaultAllocationDates } from 'utils/allocation/calcDefaultDates';
import useTimeframesForAllocationReport from './hooks/useTimeframesForAllocationReport';
import { getPageFilters } from 'store/filters/selectors';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const CHART_LABEL_MAX_WIDTH = 130;

const componentHOC = Component => {
  return props => {
    const { projects, lsState } = props;

    const metadata = useMetadataForAllocation();
    const [getSystemFieldName] = useSystemFields();

    const hasKeyResults = useSelector(state => state.organization.organization.has_key_results);
    const hasProducts = useSelector(state => state.organization.organization.has_products);
    const organization = useSelector(state => getOrganization(state));
    const pageFilters = useSelector(getPageFilters);

    const { canView } = usePermissions();

    const hasTimeframeCorpLevel = canView(PERMISSION_RESOURCES.timeframeCorp);

    const availableTimeframes = useMemo(() => {
      const filterTimeframes = (pageFilters.fields?.timeframes || []).map(t => (t === 'null' ? null : parseInt(t)));
      const projectTimeframes = projects.flatMap(proj => [
        proj.timeframe_id,
        ...(hasTimeframeCorpLevel ? [proj.timeframe_corp_id] : []),
      ]);

      return uniq([...projectTimeframes, ...filterTimeframes]);
    }, [pageFilters, projects, hasTimeframeCorpLevel]);

    const { timeframes, onTimeframesChange, useDndList } = useTimeframesForAllocationReport({
      updateLsState: props.updateLsState,
      availableTimeframes,
    });

    const systemFields = organization.system_fields_name;

    const data = {
      ...metadata,
      product1s: metadata?.products || [],
      product2s: metadata?.products2 || [],
      keyResult1s: metadata?.keyResults || [],
      keyResult2s: metadata?.keyResults2 || [],
      // Mapping for metrics - on getSummaryDataFromPieData we're using lsState.selectedGroupBy for the option
      // We need this here for now otherwise it will mess with saved views
      metricss: metadata?.metrics || [],
      organization,
      systemFields,
    };
    const hasCorpRoadmap = canView(PERMISSION_RESOURCES.roadmapCorp);
    const hasCorpObjective = canView(PERMISSION_RESOURCES.objectiveCorp);
    const hasProjectMetrics = canView(PERMISSION_FEATURES.projectMetrics);

    const groupOptions = groupByOptions({
      systemFields,
      hasCorpRoadmap,
      hasProducts,
      hasCorpObjective,
      hasKeyResults,
      hasProjectMetrics,
    });
    const selectedGroupBy = lsState.selectedGroupBy || groupOptions.find(o => o.key === 'roadmap');
    const selectedDuration = lsState.selectedDuration || durationOptions[0];
    const selectedSumBy = lsState.selectedSumBy || sumByOptions()[0];
    const selectedTimeframes = useMemo(
      () => lsState.selectedTimeframes || timeframes.map(timeframe => timeframe.id?.toString()),
      [lsState.selectedTimeframes, timeframes],
    );

    const getSelectedDate = useCallback((stateDate, key = 'startDate') => {
      const defaultDates = getDefaultAllocationDates();

      const selectedDate = stateDate || defaultDates[key];

      return moment(selectedDate);
    }, []);

    const processedLsState = {
      dataType: selectedGroupBy,
      sumBy: selectedSumBy,
      duration: selectedDuration,
      startDate: getSelectedDate(lsState.startDate),
      endDate: getSelectedDate(lsState.endDate, 'endDate'),
      selectedGroupBy,
      selectedDuration,
      selectedSumBy,
      selectedTimeframes,
      selectedDisplayOptions: lsState.selectedDisplayOptions || defaultTableColumns,
    };

    const chartData = generatePieChartData({
      ...data,
      projects,
      lsState: processedLsState,
      useTimeframes: true,
      labelMaxWidth: CHART_LABEL_MAX_WIDTH,
    });

    const tableData = getSummaryDataFromPieData(chartData, processedLsState.duration, data, selectedGroupBy);

    return (
      <Component
        {...props}
        chartData={chartData}
        tableData={tableData}
        lsState={processedLsState}
        groupOptions={groupOptions}
        timeframes={timeframes}
        onTimeframesChange={onTimeframesChange}
        useDndList={useDndList}
        getSystemFieldName={getSystemFieldName}
      />
    );
  };
};

export default componentHOC;
