import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateForwardEmailsIntegration as updateForwardEmailsIntegrationAction } from 'store/organization';

export default Component => {
  return props => {
    const forwardEmails = useSelector(state =>
      state.organization?.integrations?.find(integration => integration.integrationType === 'forwardEmails'),
    );
    const defaultUserEmail = useSelector( state => state.organization?.organization?.email);
    const organizationSlug = useSelector(state => state.organization?.organization.slug);
    const [settingsState, setSettingsState] = useState();
    const [defaultUserDomainName, setDefaultUserDomainName] = useState(null);

    useEffect( () => {
      if (forwardEmails){
        setSettingsState( () => ({
          allow: forwardEmails?.data?.allow || [],
          deny: forwardEmails?.data?.deny || [],
          listType: forwardEmails?.data?.listType || 'allow',
        }));
      }
    }, [forwardEmails] );

    useEffect( () => {
      setDefaultUserDomainName(defaultUserEmail.substring( defaultUserEmail.indexOf("@") + 1, defaultUserEmail.length ));
    }, [defaultUserEmail] );

    const dispatch = useDispatch();

    return (
      <Component
        {...props}
        defaultUserDomainName={defaultUserDomainName}
        forwardEmailsConfig={settingsState}
        organizationSlug={organizationSlug}
        updateConfig={settings => dispatch(updateForwardEmailsIntegrationAction(settings, settingsState))}
      />
    );
  };
};
