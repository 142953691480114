import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { not } from 'ramda';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import isFunction from 'lodash/isFunction';

import BarChart from 'components/ReactChart/BarChart';
import ChartWrapper from 'src/components/ReactChart/utils/ChartWrapper';
import makeTooltipLabel from 'utils/charts/makeTooltipLabel';
import makeTooltipLabelWithPercentage from 'utils/charts/makeTooltipLabelWithPercentage';
import useBarStackedCharDatalabelsConfig from 'hooks/charts/useBarStackedCharDatalabelsConfig';
import ChartContent from '../ChartContent';

import useIdeasWithGoalsProgressByTimeframeChartData, {
  PROGRESS_DATA_SET_KEY,
} from './useIdeasWithGoalsProgressByTimeframeChartData';

import useSystemFields from 'hooks/useSystemFields';

import useDetailsTableLightboxOnDashboardChart from '../../hooks/useDetailsTableLightboxOnDashboardChart';
import DetailsTableLightbox from '../../DetailsTableLightbox/DetailsTableLightbox';
import { getDetailsTableVisibleColumns } from './helpers';
import getDisplayLayerLabel from 'store/projects/helpers/getDisplayLayerLabel';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { tickSmallStyles, tickStyles } from '../utils/scales';
import { HIDE_LEGEND_OPTION, HIDE_GRID_OPTION } from 'constants/charts';
import ChartLegend from 'routes/Dashboard/Dashboards/Charts/ChartLegend';

const MAX_LEGEND_ITEMS = 6;
const PROGRESS_LINE_DATALABEL_OFFSET = 8;
const PROGRESS_LINE_DATALABEL_ALIGN = 'right';
const PROGRESS_LINE_MIN_Y = 0; // 0%
const PROGRESS_LINE_MAX_Y = 100; // 100%

const TIMEFRAME_KEY = 'timeframe';

const mappedLayers = {
  [IDEA_LAYER]: 'idea',
  [INITIATIVE_LAYER]: 'initiative',
  [BET_LAYER]: 'bet',
};

const tooltipLabelFormatter = tooltipItem => {
  if (tooltipItem.dataset.id === PROGRESS_DATA_SET_KEY) {
    return makeTooltipLabelWithPercentage(tooltipItem);
  }
  return makeTooltipLabel(tooltipItem);
};

const IdeasWithGoalsProgressByTimeframeChart = ({
  title,
  subtitle,
  actionButtons,
  wrapper: Wrapper,
  disableClick,
  minHeight,
}) => {
  const { chartData, hasData, displayLayer, selectedGroup1, hasKeyResults1, getTimeframeId } =
    useIdeasWithGoalsProgressByTimeframeChartData();

  const [getSystemFieldName] = useSystemFields();

  const chartTitle = isFunction(title) && title(mappedLayers[displayLayer] || mappedLayers.IDEA_LAYER);

  const stackedChartData = chartData.datasets.filter(dataset => dataset.id !== PROGRESS_DATA_SET_KEY);
  const datalabelsConfig = useBarStackedCharDatalabelsConfig(stackedChartData);

  const datalabelsDisplay = useCallback(
    ctx => {
      if (ctx.dataset.id === PROGRESS_DATA_SET_KEY) {
        return true;
      }
      return datalabelsConfig.display(ctx);
    },
    [datalabelsConfig],
  );
  const datalabelsAlignment = useCallback(
    ctx => {
      if (ctx.dataset.id === PROGRESS_DATA_SET_KEY) {
        return PROGRESS_LINE_DATALABEL_ALIGN;
      }
      return datalabelsConfig.labels.value.align;
    },
    [datalabelsConfig],
  );
  const datalabelsOffset = useCallback(
    ctx => {
      if (ctx.dataset.id === PROGRESS_DATA_SET_KEY) {
        return PROGRESS_LINE_DATALABEL_OFFSET;
      }
      return datalabelsConfig.labels.value.offset;
    },
    [datalabelsConfig],
  );

  const getFilterForLightboxData = useCallback(
    timeframeIndex => {
      const timeframeId = getTimeframeId(timeframeIndex);

      return {
        timeframe_id: `${timeframeId}`,
      };
    },
    [chartData, getTimeframeId],
  );

  const { chartRef, detailsTableOpen, handleClickOnChartSlice, handleCloseDetailsTableLightbox, projectsSubFilters } =
    useDetailsTableLightboxOnDashboardChart({
      getFilterForLightboxData,
    });

  const visibleColumns = useMemo(
    () => getDetailsTableVisibleColumns(getSystemFieldName, hasKeyResults1),
    [getSystemFieldName, hasKeyResults1],
  );

  const _renderTitleHeaderLabel = () =>
    `${getSystemFieldName(TIMEFRAME_KEY)} > ${getDisplayLayerLabel(displayLayer, getSystemFieldName)}`;

  if (not(hasData)) {
    return '';
  }

  return (
    <Wrapper>
      <ChartContent title={chartTitle} subtitle={subtitle} actionButtons={actionButtons}>
        <ChartWrapper height={minHeight}>
          <BarChart
            setRef={ref => (chartRef.current = ref)}
            data={chartData}
            plugins={[ChartDataLabels]}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  ...HIDE_LEGEND_OPTION,
                },
                datalabels: {
                  ...datalabelsConfig,
                  labels: {
                    value: {
                      align: datalabelsAlignment,
                      offset: datalabelsOffset,
                    },
                  },
                  display: datalabelsDisplay,
                },
                tooltip: {
                  callbacks: {
                    label: tooltipLabelFormatter,
                  },
                },
              },
              animation: {
                duration: 0,
              },
              scales: {
                x: {
                  stacked: true,
                  ticks: {
                    ...tickStyles,
                  },
                  grid: {
                    ...HIDE_GRID_OPTION,
                  },
                },
                y: {
                  stacked: true,
                  ticks: {
                    ...tickSmallStyles,
                    stepSize: 1,
                  },
                },
                yProgress: {
                  display: false,
                  type: 'linear',
                  min: PROGRESS_LINE_MIN_Y,
                  max: PROGRESS_LINE_MAX_Y,
                },
              },
              onClick: disableClick ? null : handleClickOnChartSlice,
            }}
          />
        </ChartWrapper>
        <ChartLegend legends={chartData?.datasets} maxLegendItems={MAX_LEGEND_ITEMS} />
      </ChartContent>
      <DetailsTableLightbox
        open={detailsTableOpen}
        onClose={handleCloseDetailsTableLightbox}
        projectsSubFilters={projectsSubFilters}
        title={chartTitle}
        displayLayer={displayLayer}
        allLevels={false}
        selectedGroup1={selectedGroup1}
        renderTitleHeaderLabel={_renderTitleHeaderLabel}
        visibleColumns={visibleColumns}
      />
    </Wrapper>
  );
};

IdeasWithGoalsProgressByTimeframeChart.propTypes = {
  title: PropTypes.func,
  wrapper: PropTypes.func,
};

export default IdeasWithGoalsProgressByTimeframeChart;
