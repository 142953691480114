import React, { useCallback } from 'react';

import Autocomplete from 'design-system/atoms/Autocomplete/v2';
import useTreeAutocompleteSearch from 'design-system/atoms/Autocomplete/v2/hooks/useTreeAutocompleteSearch';

import useAppliesToAutocompleteOptions from './hooks/useAppliesToAutocompleteOptions';
import { flattenItems } from './helpers/componentHelpers';
import { KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL, OBJECTIVE_CORP_LEVEL, OBJECTIVE_LEVEL } from 'constants/objectives';

const emptyArray = [];

const roadmapsKey = 'objective';
const roadmapKeysByLevel = {
  [OBJECTIVE_CORP_LEVEL]: 'objective',
  [OBJECTIVE_LEVEL]: 'objective',
  [KEY_RESULT_1_LEVEL]: 'key_result',
  [KEY_RESULT_2_LEVEL]: 'key_result',
};

const OkrAutocomplete = ({
  name,
  options = emptyArray,
  selectedEntity,
  getSystemFieldName,
  disabled = false,
  onCloseList,
  placeholder,
  focusOnInit,
  withNullOption,
  onSelectOptionCallback,
  canAddNew,
}) => {
  const metadataOptions = useAppliesToAutocompleteOptions({
    options,
    roadmapsKey,
    roadmapKeysByLevel,
    selectedEntity,
    getSystemFieldName,
    withNullOption,
  });
  const { searchValue, filteredOptions, onSearchChange, registerExpandAllCallback, registerCollapseAllCallback } =
    useTreeAutocompleteSearch(metadataOptions);

  const onSelectOption = useCallback(
    selectedItem => {
      const flatOptions = flattenItems(options);

      if (selectedItem && 'objective_id' in selectedItem) {
        const objective = flatOptions.find(opt => opt.id === selectedItem.objective_id && opt.level === OBJECTIVE_LEVEL);

        if (selectedItem.level === 0) {
          onSelectOptionCallback({
            objectiveTitle: objective.title,
            keyResult1Title: selectedItem.title,
            keyResult2Title: null,
          });
        }

        if (selectedItem.level === 1) {
          const keyResult = objective.keyResults.find(opt => opt.id === selectedItem.parent_id);

          onSelectOptionCallback({
            objectiveTitle: objective.title,
            keyResult1Title: keyResult.title,
            keyResult2Title: selectedItem.title,
          });
        }
      } else {
        onSelectOptionCallback({ objectiveTitle: selectedItem.title, keyResult1Title: null, keyResult2Title: null });
      }
    },
    [options],
  );

  return (
    <Autocomplete
      name={name}
      searchValue={searchValue}
      options={filteredOptions}
      disabled={disabled}
      onSelectOption={onSelectOption}
      onValueChange={onSearchChange}
      onCloseList={onCloseList}
      registerExpandAllCallback={registerExpandAllCallback}
      registerCollapseAllCallback={registerCollapseAllCallback}
      placeholder={placeholder}
      focusOnInit={focusOnInit}
      canAddNew={canAddNew}
    />
  );
};

export default OkrAutocomplete;
