import React from 'react';
import styled from 'styled-components';

import agGridMaterialStylesOverride from './agGridMaterialStylesOverride';

const StyledWrapper = styled.div`
  ${agGridMaterialStylesOverride};
`;

// For AgGrid 28
const AgGridReactMaterialThemeWrapper = ({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>;
};

export default AgGridReactMaterialThemeWrapper;
