import { pluck } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import getStatuses from 'store/customerRequests/helpers/getStatuses';
import priorityValues from 'store/customerRequests/constants/priority';
import useSystemFields from 'hooks/useSystemFields';
import { bulkUpdateCustomerRequests } from 'store/customerRequests/actions';
import { getUsersWithName, getUsers } from 'store/users/selectors';
import useRoadmapAutocompletes from 'hooks/useRoadmapAutocompletes';
import DatePicker from 'components/DatePicker';

import usePreviewListColumnsDefs from './usePreviewListColumnsDefs';
import { AutocompleteEnhanced } from '../components/fieldsEditComponents';
import { parseUpdatedData } from '../helpers/useBulkUpdateFields.helpers';

const mapLabels = pluck('label');
const mapTitles = pluck('title');

/**
 * Custom hook for managing bulk update fields in the RequestsBulkUpdateModal component.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onBulkUpdateFinished - The function to be called after the bulk update is finished.
 * @returns {Object} An object containing the fields and the handleBulkUpdate function.
 */
const useBulkUpdateFields = ({ onBulkUpdateFinished } = {}) => {
  const dispatch = useDispatch();
  const [getSystemFieldName] = useSystemFields();

  const { statusColumnDef, priorityColumnDef, ownerColumnDef, roadmapColumnDef, product1ColumnDef, desiredTimeColumnDef } =
    usePreviewListColumnsDefs();

  const { roadmaps, hasProducts, products } = useRoadmapAutocompletes({ selectedRoadmapTitle: null });

  const users = useSelector(getUsers);
  const owners = useSelector(getUsersWithName);

  const statuses = getStatuses();
  const priorities = Object.entries(priorityValues).map(([key, { label, color }]) => ({ value: key, label, color }));

  const fields = {
    status: {
      editingComponent: AutocompleteEnhanced(mapLabels(statuses)),
      gridColumn: statusColumnDef,
      label: 'Status',
      getFieldId: value => statuses.find(s => s.label === value).value,
    },
    priority: {
      editingComponent: AutocompleteEnhanced(mapLabels(priorities)),
      gridColumn: priorityColumnDef,
      label: getSystemFieldName('priority'),
      getFieldId: value => priorities.find(s => s.label === value).value,
    },
    ownerName: {
      editingComponent: AutocompleteEnhanced(owners),
      gridColumn: ownerColumnDef,
      label: 'Owner',
      getFieldId: value => users.find(u => u.name === value).id,
      getFieldKey: () => 'owner_id',
    },
    roadmap: {
      editingComponent: AutocompleteEnhanced(mapTitles(roadmaps)),
      gridColumn: roadmapColumnDef,
      label: getSystemFieldName('roadmap'),
      getFieldId: value => roadmaps.find(r => r.title === value).id,
      getFieldKey: () => 'roadmap_id',
    },
    ...(hasProducts
      ? {
          product1: {
            editingComponent: AutocompleteEnhanced(mapTitles(products)),
            gridColumn: product1ColumnDef,
            label: getSystemFieldName('product1'),
            parentField: 'roadmap',
            updateParent: product1Title => {
              const product = products.find(p => p.title === product1Title);
              const roadmap = roadmaps.find(r => product && r.id === product.roadmap_id);

              return roadmap ? roadmap.title : null;
            },
            getFieldId: value => products.find(r => r.title === value).id,
            getFieldKey: () => 'product_1_id',
          },
        }
      : {}),

    desired_time: {
      editingComponent: DatePicker,
      gridColumn: desiredTimeColumnDef,
      label: 'Desired time',
    },

    // TODO: missing support on BE
    // tags: {
    //   editingComponent: MultiSelectEnhanced(tags, { label: 'title', value: 'id' }),
    //   gridColumn: tagsColumnDef,
    //   label: getSystemFieldName('tag'),
    //   getFieldId: value => value.map(t => t.id),
    //   getFieldKey: () => 'tag_ids',
    // },

    // TODO: missing support on BE
    // customers: {
    //   editingComponent: MultiSelectEnhanced(customers, { label: 'name', value: 'id' }),
    //   gridColumn: customersColumnDef,
    //   label: getSystemFieldName('customer'),
    //   getFieldId: value => value.map(c => c.id),
    //   getFieldKey: () => 'customer_ids',
    // },

    // TODO: Bulk Update modal not support 3 levels of parent
    // ...(hasProducts2
    //   ? {
    //       product2: {
    //         editingComponent: AutocompleteEnhanced(mapTitles(products2)),
    //         gridColumn: product2ColumnDef,
    //         label: getSystemFieldName('product2', systemFields),
    //         parentField: 'product1',
    //         updateParent: product2Title => {
    //           const product2 = products2.find(p => p.title === product2Title);
    //           const product = products.find(r => product2 && r.id === product2.parent_id);

    //           return product ? product.title : null;
    //         },
    //         getFieldId: value => products2.find(r => r.title === value).id,
    //         getFieldKey: () => 'product_2_id',
    //       },
    //     }
    //   : {}),

    // TODO: missing support on BE
    // personas
    // lifecycles

    // TODO: missing support on BE
    // custom fields
  };

  // TODO: missing support on BE
  // (customFields || []).forEach(cf => {
  //   const shouldNotBeRendered = [TYPES_OF_CUSTOM_FIELDS.FORMULA].includes(cf.field_type);

  //   if (shouldNotBeRendered) return;

  //   fields[cf.key] = {
  //     editingComponent: props => CustomFieldEditor(cf, props),
  //     gridColumn: customFieldsColumnDefs(cf),
  //     label: cf.title,
  //   };
  // });

  const handleBulkUpdate = async changes => {
    const updatedData = parseUpdatedData(fields, changes);

    dispatch(bulkUpdateCustomerRequests(updatedData));

    if (onBulkUpdateFinished) {
      onBulkUpdateFinished();
    }
  };

  return {
    fields,

    handleBulkUpdate,
  };
};

export default useBulkUpdateFields;
