import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { defaultTo } from 'ramda';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CommentIcon from 'design-system/atoms/CommentIcon';
import MuiBadge from '@material-ui/core/Badge';

import theme, { spacing } from 'design-system/theme';
import useSystemFields from 'hooks/useSystemFields';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';

import { getOrganization } from 'store/organization';

import getFavoriteIcon from 'utils/userViews/getFavoriteIcon';

import DragonIconButton from 'design-system/atoms/DragonIconButton/index';
import DragonMenu from 'design-system/atoms/DragonMenu/index';
import ButtonV2 from 'design-system/atoms/ButtonV2/index';

import lightenDarkenColor from 'design-system/utils/lightenDarkenColor';
import MoreHoriz from 'design-system/atoms/DragonIcons/MoreHoriz';
import DuplicateIcon from 'design-system/atoms/DragonIcons/Duplicate';
import CircleChart from 'design-system/atoms/DragonIcons/CircleChart';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import useDashboardComments from 'hooks/dashboards/useDashboardComments';
import DashboardCommentsLightbox from 'containers/CommentLightbox/Dashboards/DashboardCommentsLightbox';

import { TIMEFRAME_DASHBOARD_KEY, PORTFOLIO_OUTCOME_DASHBOARD_KEY } from '../helpers/templates';
import useWidgetLightboxContext from '../WidgetLightbox/hooks/useWidgetLightboxContext';
import UpdateViewDialog from 'containers/ViewDialog/UpdateViewDialog';
import useUpdateViewDialogUI from 'hooks/userViews/useUpdateViewDialogUI';
import usePermissions from 'hooks/permissions/usePermissions';
import { DETAILS_TAB } from 'containers/ViewDialog/constants';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { OPEN_ADVANCED_SEARCH } from 'constants/queryParams';
import { addQueryParamToUrl } from 'utils/queryParamsUtils';

const exist = Boolean;
const defaultToEmptyString = defaultTo('');
const EDIT_DASHBOARD = 'Edit Dashboard';
const DUPLICATE_DASHBOARD = 'Duplicate Dashboard';
const DELETE_DASHBOARD = 'Delete Dashboard';
const SAVE_BUTTON = 'Save';
const ADD_WIDGET_BUTTON = 'Add Widget';
const TRUE_KEY = 'true';

const HeaderOptionsComponent = props => {
  const organization = useSelector(getOrganization);
  const [getSystemFieldName] = useSystemFields();
  const [moreOptionsAncherEl, setMoreOptionsAncherEl] = useState(null);
  const [templatesOptionAnchorEl, setTemplatesOptionsAnchorEl] = useState(null);
  const [isCommentsLightboxOpen, setCommentsLightboxOpen] = useState(false);
  const [shareUrl, setShareUrl] = useState('');

  const activeDashboardView = props.activeDashboard;
  const { dashboard } = props.activeDashboard?.state || {};

  const hasDashboardAddWidget = useFeatureFlags([FeatureFlags.HAS_DASHBOARD_ADD_WIDGET]);
  const hasDashboardComments = useFeatureFlags([FeatureFlags.HAS_DASHBOARD_COMMENTS]);

  const permissions = usePermissions();

  const { commentsCount, onFetchCommmentsHandler } = useDashboardComments({ dashboard: activeDashboardView });

  const {
    updateDialogProps,
    closeUpdateDialog: closeUpdateDialogUI,
    openShareDialog: openShareDialogUI,
  } = useUpdateViewDialogUI(activeDashboardView, permissions, [DETAILS_TAB]);

  useEffect(() => {
    if (!activeDashboardView?.id) {
      return;
    }

    if (!hasDashboardComments) {
      return;
    }

    onFetchCommmentsHandler();
  }, [onFetchCommmentsHandler, activeDashboardView?.id]);

  const _handleSelectTemplate = useCallback(
    template => {
      props.onDashboardTemplateChanged(template.key);

      addQueryParamToUrl(OPEN_ADVANCED_SEARCH, TRUE_KEY);
    },
    [props.onDashboardTemplateChanged],
  );
  const _createOrUpdateDashboard = useCallback(() => {
    props.toggleEditMode(false);
    props.createOrUpdateDashboard(props.activeDashboard);
  }, [props.createOrUpdateDashboard, props.activeDashboard]);

  const { openAddWidgetLightbox } = useWidgetLightboxContext();

  const handleAddNewWidget = () => {
    openAddWidgetLightbox(props.match?.params?.dashboardId);
  };

  const templatesOptions = useMemo(
    () => [
      ...(organization.has_delivery_dashboard
        ? [
            {
              key: TIMEFRAME_DASHBOARD_KEY,
              icon: <CircleChart fill={theme.palette.primary.main} />,
              label: `Portfolio Delivery Template`,
            },
          ]
        : []),
      ...(organization.has_outcome_dashboard
        ? [
            {
              key: PORTFOLIO_OUTCOME_DASHBOARD_KEY,
              icon: <CircleChart fill={theme.palette.primary.main} />,
              label: `Portfolio Outcome Template`,
            },
          ]
        : []),
      // {
      //   key: INITIATIVE_DASHBOARD_KEY,
      //   icon: <CircleChart fill={theme.palette.primary.main} />,
      //   label: `${getSystemFieldName('initiative')} Template`,
      // },
      // {
      //   key: ROADMAP_DASHBOARD_KEY,
      //   icon: <CircleChart fill={theme.palette.primary.main} />,
      //   label: `Portfolio Template`,
      // },
    ],
    [getSystemFieldName, organization.has_delivery_dashboard, organization.has_outcome_dashboard],
  );
  const selectedTemplate = useMemo(
    () => templatesOptions.find(template => template.key === props.selectedTemplateKey),
    [props.selectedTemplateKey, templatesOptions],
  );

  const _handleShareDialogOpen = useCallback(() => {
    setShareUrl(`${window.location.origin}/dashboard/dashboards/${activeDashboardView?.id}`);
    openShareDialogUI();
  }, [activeDashboardView?.id]);

  const hasDashboardId = useMemo(() => exist(props.dashboardId), [props.dashboardId]);

  const isNewAndReadyToBeSaved = useMemo(
    () => !hasDashboardId && dashboard?.template && activeDashboardView?.name,
    [hasDashboardId, dashboard?.template, activeDashboardView?.name],
  );

  const hasSaveButton = useMemo(() => {
    if (props.isReadOnlyUser) {
      return false;
    }

    return isNewAndReadyToBeSaved || props.checkCurrentDashboardChanged() || props.inEditMode;
  }, [props.isReadOnlyUser, props.checkCurrentDashboardChanged, props.inEditMode, isNewAndReadyToBeSaved]);

  const isSaveButtonDisabled = useMemo(
    () => !defaultToEmptyString(activeDashboardView?.name).trim().length,
    [activeDashboardView?.name],
  );

  const showEditButton = hasDashboardId && !props.inEditMode && !hasSaveButton && props.isDashboardOwner && !props.isReadOnlyUser;
  const showSaveButton = !dashboard?.disallowSave && hasSaveButton && props.isDashboardOwner && !props.isReadOnlyUser;
  const showAddWidgetButton = hasDashboardAddWidget && props.inEditMode && props.isDashboardOwner && !props.isReadOnlyUser;
  const showDeleteButton = permissions.canDelete(PERMISSION_RESOURCES.userView, { view: activeDashboardView });

  const editDashboard = showEditButton && <span id="edit-dashboard-button">{EDIT_DASHBOARD}</span>;

  const moreOptions = useMemo(
    () => [
      ...(showEditButton
        ? [
            {
              key: 'edit-dashboard',
              icon: <EditIcon />,
              label: editDashboard,
            },
          ]
        : []),
      {
        key: 'duplicate-dashboard',
        icon: <DuplicateIcon />,
        label: DUPLICATE_DASHBOARD,
      },
      ...(showDeleteButton
        ? [
            {
              key: 'delete-dashboard',
              icon: (
                <DeleteText>
                  <DeleteIcon />
                </DeleteText>
              ),
              label: <DeleteText>{DELETE_DASHBOARD}</DeleteText>,
            },
          ]
        : []),
    ],
    [showEditButton, showDeleteButton],
  );

  const _handleSelectMoreOptions = useCallback(
    option => {
      switch (option.key) {
        case 'edit-dashboard':
          props.toggleEditMode(true);
          break;
        case 'duplicate-dashboard':
          props.duplicateDashboard();
          break;
        case 'delete-dashboard':
          props.deleteDashboard();
          break;
        default:
          break;
      }
    },
    [props.duplicateDashboard, props.deleteDashboard, props.toggleEditMode],
  );

  return (
    <Wrapper>
      {showAddWidgetButton && (
        <StyledButton
          id="add-widget-button"
          color={theme.palette.background.white}
          background={theme.palette.background.primary}
          hover={{ background: lightenDarkenColor(theme.palette.background.primary, -20) }}
          onClick={handleAddNewWidget}
          size="small"
        >
          {ADD_WIDGET_BUTTON}
        </StyledButton>
      )}
      {showSaveButton && (
        <StyledButton
          id="save-dashboard-button"
          color={theme.palette.background.white}
          background={theme.palette.background.primary}
          hover={{ background: lightenDarkenColor(theme.palette.background.primary, -20) }}
          onClick={_createOrUpdateDashboard}
          size="small"
          disabled={isSaveButtonDisabled}
        >
          {SAVE_BUTTON}
        </StyledButton>
      )}
      {hasDashboardId && (
        <>
          <ButtonIconButton
            id="favorite-dashboard-button"
            type="transparent"
            icon={getFavoriteIcon(props.isFavorite)}
            onClick={props.handleFavoriteUserView}
          />
          <ButtonIconButton
            id="share-dashboard-button"
            type="transparent"
            icon={<ShareOutlinedIcon />}
            onClick={_handleShareDialogOpen}
          />
          {hasDashboardComments && (
            <Badge badgeContent={commentsCount || 0}>
              <ButtonIconButton
                id="comments-dashboard-button"
                type="transparent"
                icon={<CommentIcon color={theme.palette.text.primary} />}
                onClick={() => setCommentsLightboxOpen(true)}
              />
            </Badge>
          )}
          {!props.isReadOnlyUser && (
            <ButtonIconButton
              id="more-options-button"
              variant="primary"
              type="fill"
              icon={<MoreHoriz />}
              onClick={e => setMoreOptionsAncherEl(e.currentTarget)}
            />
          )}

          <DragonMenu
            items={moreOptions}
            anchorEl={moreOptionsAncherEl}
            placement="bottom-end"
            onSetAnchorEl={setMoreOptionsAncherEl}
            onSelectItem={_handleSelectMoreOptions}
          />
          <UpdateViewDialog
            key={updateDialogProps?.selectedTab}
            show={updateDialogProps?.open}
            onClose={closeUpdateDialogUI}
            initialViewTab={updateDialogProps?.selectedTab}
            view={activeDashboardView}
            shareUrl={shareUrl}
            tabs={updateDialogProps?.visibleTabs}
          />
        </>
      )}
      {!dashboard?.disallowSave && !hasDashboardId && (
        <>
          <SelecteTemplateButton
            id="choose-from-template-button"
            color={theme.palette.background.primary}
            background="transparent"
            borderColor={theme.palette.background.primary}
            onClick={e => setTemplatesOptionsAnchorEl(e.currentTarget)}
            open={!!templatesOptionAnchorEl}
            rightArrowVisible
          >
            {selectedTemplate?.label}
          </SelecteTemplateButton>

          <DragonMenu
            items={templatesOptions}
            anchorEl={templatesOptionAnchorEl}
            placement="bottom-end"
            onSetAnchorEl={setTemplatesOptionsAnchorEl}
            onSelectItem={_handleSelectTemplate}
          />
        </>
      )}
      <DashboardCommentsLightbox
        isOpen={isCommentsLightboxOpen}
        item={activeDashboardView}
        onClose={() => setCommentsLightboxOpen(false)}
      />
    </Wrapper>
  );
};

export default withRouter(HeaderOptionsComponent);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 320px;
  margin-right: 15px;
`;

const ButtonIconButton = styled(DragonIconButton)`
  &&&& {
    margin-right: 14px;
  }
`;

const DeleteText = styled.span`
  color: ${({ theme }) => theme.palette.newLayout.background.red} !important;
`;

const SelecteTemplateButton = styled(ButtonV2)`
  float: right;
  position: relative;
  padding-left: 10px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledButton = styled(ButtonV2)`
  && {
    padding: ${({ theme }) => `0 ${theme.spacing.unit * 2}px`} !important;
    margin-right: ${({ theme }) => theme.spacing.unit * 2}px;
    white-space: nowrap;

    &:disabled {
      opacity: 0.5;
    }
  }
`;

const Badge = styled(MuiBadge)`
  &&&& {
    span {
      color: ${props => props.theme.palette.text.primary};
      font-weight: ${props => props.theme.typography.fontWeightBold};
      background: transparent;
      top: ${spacing(1)}px;
      right: ${spacing(1.5)}px;
    }
  }
`;
