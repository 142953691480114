import isEmpty from 'lodash/isEmpty';

const POSSIBLE_COLUMNS_KEYS = [
  'status_color',
  'roadmapTitle',
  'progress',
  'integration',
  'integrationIssueType',
  'ownerName',
  'parent',
  'categoryTitle',
  'themeTitle',
  'timeframeTitle',
  'timeframe2Title',
  'objectiveTitle',
  'priorityTitle',
  'product1Title',
  'product2Title',
  'keyResult1Title',
  'keyResult2Title',
  'timeEstimate',
  'storyPoint',
  'issueCounts',
  'updatedByName',
  'tags',
  'customers',
  'planningStage',
  'phaseTitle',
  'status_summary',
  'predictedEndDate',
  'deadline',
  'estimated_start_date',
  'links',
  'details',
  'planned_moar',
  'benefit1',
  'benefit2',
  'benefit3',
  'cost1',
  'priorityScore',
  'riceScore',
  'reach_score',
  'impact_score',
  'confidence_score',
  'effort_score',
  'sum',
  'completed_date',
];

const updateVisibleColumns = (originalColumnDefs, visibleFields, customFields) => {
  const possibleColumnsKeys = POSSIBLE_COLUMNS_KEYS;

  if (!isEmpty(customFields)) {
    customFields.forEach(field => {
      possibleColumnsKeys.push(field.key);
    });
  }

  const originalColumnDefsClone = [...originalColumnDefs];

  originalColumnDefsClone.sort((first, second) => {
    const a = visibleFields.findIndex(item => item.key === first.field);
    const b = visibleFields.findIndex(item => item.key === second.field);

    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });

  return originalColumnDefsClone.map(colDef => {
    if (!possibleColumnsKeys.includes(colDef.field)) {
      return { ...colDef };
    }

    return {
      ...colDef,
      hide: !visibleFields.some(field => field.key === colDef.field),
    };
  });
};

export { updateVisibleColumns };
