import React, { useMemo } from 'react';
import styled from 'styled-components';
import withTheme from '@material-ui/core/styles/withTheme';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import { PAGE_NAVIGATION_DIRECTIONS } from 'constants/pagination';

import { formatPageNumber } from 'design-system/utils/pagination';

function PaginationActions({ currentPage, handleChangePage, lastPage, isDisabled }) {
  const isFirstPage = useMemo(() => currentPage === 0, [currentPage]);
  const isLastPage = useMemo(() => currentPage >= lastPage - 1, [currentPage, lastPage]);

  const pageDescription = useMemo(() => {
    const formattedCurrentPage = formatPageNumber(currentPage + 1);
    const formattedLastPage = formatPageNumber(lastPage);

    return `${formattedCurrentPage} of ${formattedLastPage}`;
  }, [currentPage, lastPage]);

  return (
    <ButtonWrapper>
      <StyledIconButton
        onClick={() => handleChangePage(PAGE_NAVIGATION_DIRECTIONS.FIRST)}
        disabled={isFirstPage || isDisabled}
        aria-label="First Page"
        data-testid="first-page"
      >
        <FirstPageIcon fontSize="inherit" />
      </StyledIconButton>
      <StyledIconButton
        onClick={() => handleChangePage(PAGE_NAVIGATION_DIRECTIONS.PREVIOUS)}
        disabled={isFirstPage || isDisabled}
        aria-label="Previous Page"
        data-testid="previous-page"
      >
        <KeyboardArrowLeft fontSize="inherit" />
      </StyledIconButton>
      <PageDescription>{pageDescription}</PageDescription>
      <StyledIconButton
        onClick={() => handleChangePage(PAGE_NAVIGATION_DIRECTIONS.NEXT)}
        disabled={isLastPage || isDisabled}
        aria-label="Next Page"
        data-testid="next-page"
      >
        <KeyboardArrowRight fontSize="inherit" />
      </StyledIconButton>
      <StyledIconButton
        onClick={() => handleChangePage(PAGE_NAVIGATION_DIRECTIONS.LAST)}
        disabled={isLastPage || isDisabled}
        aria-label="Last Page"
        data-testid="last-page"
      >
        <LastPageIcon fontSize="inherit" />
      </StyledIconButton>
    </ButtonWrapper>
  );
}

export default withTheme()(PaginationActions);

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 32px;
`;

const StyledIconButton = styled(IconButton)`
  &&&&& {
    font-size: ${({ theme }) => theme.typography.fontSizeMediumSmall}px;
    padding: 0;
  }
`;

const PageDescription = styled.div`
  padding: 0 2px;
  white-space: nowrap;
`;
