export const labelMatchesQuery = (label, query) => label?.toLowerCase()?.includes(query.toLowerCase());

export const optionMatchesQuery = (option, query) => {
  if (labelMatchesQuery(option.label, query)) {
    return true;
  }

  return option.children?.some(child => optionMatchesQuery(child, query)) || false;
};

export const expandOptionIfMatchesQuery = (option, query) => ({
  ...option,
  openByDefault: optionMatchesQuery(option, query),
  resetIsOpen: true,
  children: (option.children || []).map(child => expandOptionIfMatchesQuery(child, query)),
});
