import { useMemo, useCallback } from 'react';
import { path, omit } from 'ramda';
import { useSelector } from 'react-redux';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import usePermissions from 'hooks/permissions/usePermissions';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useMetadataForOptions from 'hooks/metadata/useMetadataForOptions';
import { getOrganization } from 'store/organization/selectors';
import makeMetadataRoadmapsOptionsFormatter from 'utils/makeMetadataRoadmapsOptionsFormatter';

import {
  ROADMAPS,
  ROADMAPS_CORP,
  PRODUCTS,
  PRODUCTS_2,
  OBJECTIVES,
  KEY_RESULTS,
  KEY_RESULTS_2,
  TIMEFRAMES,
  TIMEFRAMES_2,
  THEMES,
  CATEGORIES,
  TEAMS,
  TEAMS_2,
} from 'constants/common';

const KEYS = [
  ROADMAPS,
  ROADMAPS_CORP,
  PRODUCTS,
  PRODUCTS_2,
  TIMEFRAMES,
  TIMEFRAMES_2,
  OBJECTIVES,
  KEY_RESULTS,
  KEY_RESULTS_2,
  THEMES,
  CATEGORIES,
  TEAMS,
  TEAMS_2,
];

const FILTERED_OPTIONS = 'filteredOptions';

const CHILDREN = 'children';
const omitChildren = (metadataOptions = []) => metadataOptions?.map(option => omit([CHILDREN], option));

const useAdditionalMetadataDropdownOptions = (formatByRoadmap = false, roadmapId = null) => {
  const organization = useSelector(state => getOrganization(state));
  const organizationsAccessControl = useOrganizationsAccessControl();

  const { canView } = usePermissions();

  const { isChildDragon } = organizationsAccessControl;

  const metadataOptionsObj = useMetadataForOptions({
    metadataKeys: KEYS,
  });

  const {
    filteredOptions: { roadmaps, roadmapCorps: roadmapsCorp },
  } = metadataOptionsObj;

  const formatMetadataOptions = useMemo(
    () => makeMetadataRoadmapsOptionsFormatter(organization, roadmapId, organizationsAccessControl, roadmaps, roadmapsCorp),
    [organization, organizationsAccessControl, roadmaps, roadmapsCorp],
  );

  const getOptions = useCallback(
    (options, roadmapKey, metadataShouldIncludeCorpRoadmap = false) => {
      if (!formatByRoadmap) return options;

      return formatMetadataOptions(roadmapKey, options, {
        needsObject: true,
        includeCorpRoadmap: canView(PERMISSION_RESOURCES.roadmapCorp) || (isChildDragon && metadataShouldIncludeCorpRoadmap),
      });
    },
    [formatByRoadmap, formatMetadataOptions],
  );

  return useMemo(
    () => ({
      additionalRoadmapOptions: path([FILTERED_OPTIONS, ROADMAPS], metadataOptionsObj),
      additionalProducts1Options: path([FILTERED_OPTIONS, PRODUCTS], metadataOptionsObj),
      additionalProducts2Options: path([FILTERED_OPTIONS, PRODUCTS_2], metadataOptionsObj),
      additionalObjectivesOptions: getOptions(
        path([FILTERED_OPTIONS, OBJECTIVES], metadataOptionsObj),
        'objective_roadmaps',
        true,
      ),
      additionalKeyResults1Options: getOptions(
        path([FILTERED_OPTIONS, KEY_RESULTS], metadataOptionsObj),
        'objective_roadmaps',
        true,
      ),
      additionalKeyResults2Options: getOptions(
        path([FILTERED_OPTIONS, KEY_RESULTS_2], metadataOptionsObj),
        'objective_roadmaps',
        true,
      ),
      additionalTimeframesOptions: getOptions(
        path([FILTERED_OPTIONS, TIMEFRAMES], metadataOptionsObj),
        'timeframe_roadmaps',
        true,
      ),
      additionalTimeframes2Options: getOptions(
        path([FILTERED_OPTIONS, TIMEFRAMES_2], metadataOptionsObj),
        'timeframe_roadmaps',
        true,
      ),
      additionalThemesOptions: getOptions(path([FILTERED_OPTIONS, THEMES], metadataOptionsObj), 'theme_roadmaps'),
      additionalCategoriesOptions: getOptions(path([FILTERED_OPTIONS, CATEGORIES], metadataOptionsObj), 'category_roadmaps'),
      additionalTeamsOptions: omitChildren(path([FILTERED_OPTIONS, TEAMS], metadataOptionsObj)),
      additionalTeams2Options: path([FILTERED_OPTIONS, TEAMS_2], metadataOptionsObj),
    }),
    [metadataOptionsObj, getOptions],
  );
};

export default useAdditionalMetadataDropdownOptions;
