/**
 * RadioButton Atom Component
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import theme from 'design-system/theme';

const Checked = ({ size, color }) => (
  <Container size={size} background={color}>
    <StyledCheckIcon size={size} />
  </Container>
);

const Unchecked = ({ size, borderColor }) => <Container size={size} borderColor={borderColor} />;

const RadioButton = props => {
  const { size = '18px', color = theme.palette.newLayout.background.duskBlue, borderColor = theme.palette.text.black } = props;

  return (
    <FormControlLabel
      control={
        <StyledRadio
          icon={<Unchecked size={size} borderColor={borderColor} />}
          checkedIcon={<Checked size={size} color={color} />}
          color={color}
        />
      }
      {...props}
    />
  );
};

export default RadioButton;

const StyledRadio = styled(Radio)`
  &&&& {
    color: ${({ color }) => color};
  }
`;

const Container = styled.div`
  position: relative;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background: ${({ background }) => background || 'unset'};
  border: ${({ borderColor }) => `1px solid ${borderColor || 'transparent'}`};
  border-radius: 50%;
  box-sizing: border-box;
`;

const StyledCheckIcon = styled(CheckIcon)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${({ size }) => `calc(${size} - 4px)`};
    fill: ${({ theme }) => theme.palette.newLayout.background.white};
  }
`;
