import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import Card from 'design-system/molecules/Card/index';
import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';

import buildToolbarActions from '../buildToolbarActions';
import useDescription from './useDescription';
import useSelectedRoadmapOnPortfolioOverview from '../../hooks/useSelectedRoadmapOnPortfolioOverview';
import { getWidgetHeight, MAX_WIDGET_HEIGHT } from './helpers';
import theme from 'design-system/theme';
import NewDialog from 'design-system/organisms/NewDialog';
// eslint-disable-next-line max-len
import PortfolioOverviewHistory from 'features/PortfolioOverview/widgets/DescriptionEditor/DescriptionHistory/DescriptionHistory';

const cardContentStyles = css`
  height: 100%;
`;

const valueOrPlaceholderStyles = css`
  height: 95%;
`;

const getCardTitle = roadmap => {
  const roadmapTitle = roadmap?.entity?.title;

  return `${roadmapTitle || 'Portfolio'} Overview`;
};

const RichTextEditor = ({
  id,
  portfolioOverviewId,
  isVisible,
  onVisibilityChange,
  userCanEdit,
  disableEdit,
  updateHeightOnLayout,
}) => {
  const {
    data,
    otherProps: { handleTextEditorUpdate, onOpenHistory, onCloseHistory, isHistoryOpen },
  } = useDescription();

  const { activeRoadmapEntity } = useSelectedRoadmapOnPortfolioOverview();

  const applyMaxHeightOnEditor = () => updateHeightOnLayout(id, MAX_WIDGET_HEIGHT);

  const applyContentHeightOnEditor = editorManager => {
    const widgetHeight = getWidgetHeight(editorManager?.view?.dom?.offsetHeight);

    updateHeightOnLayout(id, widgetHeight);
  };

  const handleEditorStateChange = (editable, editorManager) => {
    if (!userCanEdit) return;

    if (editable) {
      return applyMaxHeightOnEditor();
    }

    applyContentHeightOnEditor(editorManager);
  };

  const updateDescription = useCallback(
    updatedDescription => {
      handleTextEditorUpdate(updatedDescription);
    },
    [handleTextEditorUpdate],
  );

  const buildActions = () => {
    if (!userCanEdit || disableEdit) {
      return buildToolbarActions({
        history: { onClick: onOpenHistory },
      });
    }

    return buildToolbarActions({
      visibility: { value: isVisible, onChange: onVisibilityChange },
      history: { onClick: onOpenHistory },
    });
  };

  return (
    <>
      <StyledCard title={getCardTitle(activeRoadmapEntity)} actions={buildActions()} contentStyles={cardContentStyles}>
        <StyledFluidRemirrorWYSIWYGEditor
          key="roadmap-overview"
          value={data}
          content={data}
          placeholder="Add an overview for this Roadmap"
          onChange={updateDescription}
          smallInput
          onEditorStateChange={handleEditorStateChange}
          onInit={manager => applyContentHeightOnEditor(manager)}
          valueOrPlaceholderStyles={valueOrPlaceholderStyles}
          disableEdit={!userCanEdit}
        />
      </StyledCard>
      <NewDialog
        id="description-history"
        title="History"
        contentBackgroundColor={theme.palette.white}
        onClose={onCloseHistory}
        open={isHistoryOpen}
        maxWidth="md"
        closeButton
      >
        <PortfolioOverviewHistory portfolioOverviewId={portfolioOverviewId} />
      </NewDialog>
    </>
  );
};

export default RichTextEditor;

const StyledCard = styled(Card)`
  height: 100%;
  overflow: hidden;
`;

const StyledFluidRemirrorWYSIWYGEditor = styled(FluidRemirrorWYSIWYGEditor)`
  // We need to apply 'height: 100%' to multiple elements so that the
  // scroll is working correctly on the .remirror-editor-wrapper element

  &&&& {
    padding: 0;
    margin: 0;
    height: 100%;

    > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }

    .remirror-theme {
      height: 100%;
    }

    .remirror-editor-wrapper {
      max-height: 95%;
      overflow-y: auto;
    }

    .remirror-editor {
      color: ${({ theme }) => theme.palette.text.primary};
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;

      cursor: text;
    }

    .remirror-is-empty:before {
      font-style: normal;
    }
  }
`;
