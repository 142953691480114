import React, { useMemo } from 'react';
import { css } from 'styled-components';

import theme from 'design-system/theme';
import Tabs from 'design-system/organisms/Tabs/index';


const tabStyles = css`
  color: ${({ isSelectedTab, theme }) =>
    isSelectedTab ? theme.palette.text.primary : theme.palette.newLayout.background.disable} !important;
  font-size: ${({ theme }) => theme.typography.fontSize}px;
  min-width: unset;
  min-height: 30px;
  margin-right: 21px;

  &[aria-selected='true'] {
    color: ${({ theme }) => theme.palette.text.darkerGrey};
  }

  span {
    padding: 0;
  }
`;

const selectedTabBorderStyles = {
  background: theme.palette.text.primary,
  height: '2px',
};

export default ({ items, selected, handleNavigationChange, ...props }) => {
  const handleTabChange = (e, val) => {
    const path = items.find(item => item.label === val)?.path;

    if (path) handleNavigationChange(path);
  };

  const selectedTab = useMemo(() => items.find(item => item.path === selected)?.label, [items, selected]);

  // if has only one item should not render
  if (items.length <= 1) {
    return '';
  }

  return (
    <Tabs
      {...props}
      tabs={items}
      selectedTab={selectedTab}
      handleTabChange={handleTabChange}
      tabStyles={tabStyles}
      TabIndicatorProps={{ style: selectedTabBorderStyles }}
    />
  );
};
