import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import last from 'lodash/last';
import { defaultTo, equals, path, pipe } from 'ramda';

import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';

const DATA = 'data';
const GROUP = 'group';

const defaultToEmptyObject = defaultTo({});
const isTypeGroup = pipe(defaultToEmptyObject, path([DATA, GROUP]), equals(true));

/**
 * Get the final project layer based on the parent project or group and feature flag hasBets.
 *
 * @param {Object} parentProjectOrGroup The parent project or group in the hierarchy
 * @param {Boolean} hasBet If the feature flag hasBets is active for this organization
 * @param {Function} getSystemFieldName Get the current name for some dragonboat entities
 * @param {String} displayLayer The selected display level by user
 * @param {Boolean} portfolioMode If portfolio mode is selected
 * @returns The layer constant
 */
const getLayer = (parentProjectOrGroup, hasBet, getSystemFieldName, displayLayer, portfolioMode) => {
  if (parentProjectOrGroup?.data?.layer) {
    return parentProjectOrGroup.data.layer === BET_LAYER ? INITIATIVE_LAYER : IDEA_LAYER;
  }

  if (!portfolioMode || isTypeGroup(parentProjectOrGroup)) {
    return displayLayer;
  }

  const withoutBetLabel = `Without ${getSystemFieldName('bet', false)}`;
  const withoutInitiativeLabel = `Without ${getSystemFieldName('initiative', false)}`;

  // Value may not exist (for non-title columns), but the node key
  // will still hold the title for filler groups (like "Without Bet").
  const parentValueOrKey = parentProjectOrGroup?.value || parentProjectOrGroup?.node?.key;

  if (parentValueOrKey === withoutBetLabel) {
    return INITIATIVE_LAYER;
  }

  if (parentValueOrKey === withoutInitiativeLabel) {
    return IDEA_LAYER;
  }

  return hasBet ? BET_LAYER : INITIATIVE_LAYER;
};

/**
 * Flatten all the project's parents.
 * @param {*} obj The source project to get parent from
 * @param {*} acc The accumulatted result with all the current parents
 * @returns An array containing all the ascending parents
 */
const getParentRecursive = (obj, acc = []) => {
  const result = acc;

  const { parent } = obj;

  if (parent?.data?.groupData) {
    result.push(parent.data.groupData);
  }

  if (parent.uiLevel === 0) {
    return result;
  }

  if (parent?.parent) {
    getParentRecursive(parent, result);
  }

  return result;
};

/**
 * Get the child project based on the hierarchy context.
 *
 * @param {Object} parentProjectOrGroup The parent project or group in the hierarchy
 * @param {Object} currentUser The current logged userId
 * @param {Boolean} hasBet If the feature flag hasBets is active for this organization
 * @param {Function} getSystemFieldName Get the current name for some dragonboat entities
 * @param {String} displayLayer The selected display level by user
 * @param {Boolean} portfolioMode If portfolio mode is selected
 * @returns The body for the new child project
 */
const createChildFromProject = (
  parentProjectOrGroup,
  currentUser,
  hasBet,
  getSystemFieldName,
  displayLayer,
  portfolioMode,
  defaultPhase,
) => {
  const parents = getParentRecursive(parentProjectOrGroup?.node);
  const inheritedData = parents.reduce((acc, parent) => ({ ...acc, ...parent }), {});
  const parentProjectOrGroupData = parentProjectOrGroup?.data;

  const grandParentId = isNumber(parentProjectOrGroup?.node?.parent?.data?.id) ? parentProjectOrGroup.node.parent.data.id : null;

  const parentId =
    (isNumber(parentProjectOrGroupData?.id) && parentProjectOrGroupData.id) ||
    (isNumber(parentProjectOrGroupData?.groupData?.parent_id) && parentProjectOrGroupData.groupData.parent_id) ||
    null;

  const grandParentLayer = isNumber(parentProjectOrGroup?.node?.parent?.data?.layer)
    ? parentProjectOrGroup.node.parent.data.layer
    : null;
  const parentLayer = parentProjectOrGroupData?.layer || parentProjectOrGroupData?.groupData?.layer;

  return {
    owner_id: currentUser?.id,
    ...inheritedData,
    layer: getLayer(parentProjectOrGroup, hasBet, getSystemFieldName, displayLayer, portfolioMode),
    parent_id: parentId ?? grandParentId,
    parentLayer: parentLayer ?? grandParentLayer,
    keyResult1: inheritedData?.keyResult1Title ? { title: inheritedData.keyResult1Title } : null,
    keyResult2: inheritedData?.keyResult2Title ? { title: inheritedData.keyResult2Title } : null,
    parentGroupId:
      isString(parentProjectOrGroupData?.id) && parentProjectOrGroupData?.id.startsWith('group-')
        ? parentProjectOrGroupData?.id
        : null,
    path: parentProjectOrGroupData?.path ?? [],
    phase: defaultPhase,
    ...(getNewGroupData(parentProjectOrGroup) ?? {}),
  };
};

/**
 * Get the placeholder for the title input for the new child project
 * @param {Object} project Base project or group to be used on the label calculation
 * @param {Function} getSystemFieldName Function to get the current name for some metadata properties
 * @returns The input placeholder
 */
const getChildProjectPlaceholder = (project, getSystemFieldName) => {
  switch (project?.layer) {
    case IDEA_LAYER:
      return `new ${getSystemFieldName('idea', false)} name`;
    case INITIATIVE_LAYER:
      return `new ${getSystemFieldName('initiative', false)} name`;
    case BET_LAYER:
      return `new ${getSystemFieldName('bet', false)} name`;
    default:
      return '';
  }
};

const getNewGroupData = parentProjectOrGroup => {
  const groupLevel1 = parentProjectOrGroup?.node?.parent?.parent?.data;
  const groupLevel1Field = groupLevel1?.groupOption?.field;
  const groupLevel1GroupData = groupLevel1?.groupData;

  const groupLevel1Result = groupLevel1?.group ? convertNewGroupData(groupLevel1Field, groupLevel1GroupData) : {};

  const groupLevel2 = parentProjectOrGroup?.node?.parent?.data;
  const groupLevel2Field = groupLevel2?.groupOption?.field;
  const groupLevel2GroupData = groupLevel2?.groupData;

  const groupLevel2Result = groupLevel2?.group ? convertNewGroupData(groupLevel2Field, groupLevel2GroupData) : {};

  const groupLevel3 = parentProjectOrGroup?.data;
  const groupLevel3Field = groupLevel3?.groupOption?.field;
  const groupLevel3Data = groupLevel3?.groupData;

  const groupLevel3Result = groupLevel3?.group ? convertNewGroupData(groupLevel3Field, groupLevel3Data) : {};

  return {
    ...groupLevel1Result,
    ...groupLevel2Result,
    ...groupLevel3Result,
  };
};

const convertNewGroupData = (groupField, groupData) => {
  const isStringOption = ['status_color', 'planningStage'].includes(groupField);
  const isCustomFieldOption = groupField.startsWith('custom_fields.');

  if (isStringOption) {
    return { [groupField]: groupData?.[groupField]?.id };
  }

  if (isCustomFieldOption) {
    const customField = last(groupField?.split('.'));

    return { custom_fields: { [customField]: groupData?.[groupField]?.id }, [customField]: groupData?.[groupField]?.id };
  }

  if (['tags', 'customers'].includes(groupField)) {
    return { [groupField]: [...Object.values(groupData)] };
  }

  return groupData;
};

export { createChildFromProject, getChildProjectPlaceholder, getLayer };
