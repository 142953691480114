import { omit } from 'ramda';

const getColDefId = colDef => colDef.field || colDef.id;

const omitWidth = omit(['width']);

/**
 * @function processColumnsGroup
 *
 * Process columns group and apply initial state of the columns based
 * on previous saved column state
 *
 * @param  {Array} columns
 * @param  {Array} visibleColumnsIds
 * @param  {Array} columnsState
 * @return {Array}
 */
const processColumnsGroup = (columns, visibleColumnsIds = [], columnsState = []) => {
  const result = columns
    .filter(c => c)
    .map(c => {
      const colDefId = getColDefId(c);
      const colState = columnsState.find(col => col.colId === colDefId);
      const hide = !visibleColumnsIds.includes(colDefId);

      return {
        ...omitWidth(c),
        ...(c?.width ? { width: c?.width } : {}),
        ...(colState?.width ? { width: colState?.width } : {}),
        initialHide: hide,
        initialPinned: colState?.pinned,
        initialFlex: colState?.flex,

        initialSort: colState?.sort,
        initialSortIndex: colState?.sortIndex,

        initialRowGroup: colState?.rowGroup,
        initialRowGroupIndex: colState?.rowGroupIndex,

        initialPivot: colState?.pivot,
        initialPivotIndex: colState?.pivotIndex,

        ...(c.children ? { children: processColumnsGroup(c.children, visibleColumnsIds, columnsState) } : {}),
      };
    });

  return result;
};

export default processColumnsGroup;
