import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import TitleWithActions from 'design-system/organisms/TitleWithActions';
import formatDate from 'design-system/utils/formatDate';
import getEditColumnDef from 'design-system/molecules/AgGridReact-New/columns/getEditColumnDef';
import { getUsers } from 'store/users/selectors';
import { getCurrentUser } from 'store/login/selectors';

export default ({ onSelectScenarioClick, onEditScenarioClick }) => {
  const users = useSelector(getUsers);
  const currentUser = useSelector(getCurrentUser);

  const ownerValueFormatter = useCallback(
    ({ data }) => {
      if (!data?.created_by_id) {
        return;
      }

      const user = users.find(user => user?.id === data.created_by_id);

      return user?.name || '';
    },
    [users],
  );

  const timestampValueFormatter = useCallback(({ data }) => {
    if (!data?.created_at) {
      return;
    }

    const date = formatDate(data.created_at);

    return date || '';
  }, []);

  const checkEditVisibility = useCallback(
    ({ data }) => {
      return currentUser?.id === data?.created_by_id;
    },
    [currentUser],
  );

  const editColumnDef = useMemo(() => {
    return getEditColumnDef({
      getVisibility: checkEditVisibility,
      handleClick: params => onEditScenarioClick(params),
      cellClass: 'action-icon-cell',
    });
  }, [checkEditVisibility, onEditScenarioClick]);

  const columnDefs = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Title',
        sortable: true,
        cellRenderer: params => (
          <TitleWithActions openButtonProps={{ onClick: () => onSelectScenarioClick(params) }} hasOpenButton>
            {params.value}
          </TitleWithActions>
        ),
        width: 400,
      },
      {
        field: 'owner',
        headerName: 'Owner',
        sortable: true,
        valueFormatter: ownerValueFormatter,
      },
      {
        field: 'created_at',
        headerName: 'Created at',
        sortable: true,
        valueFormatter: timestampValueFormatter,
      },
      { ...editColumnDef },
    ],
    [ownerValueFormatter, timestampValueFormatter, editColumnDef, onSelectScenarioClick],
  );

  return {
    columnDefs,
  };
};
