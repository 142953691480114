import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { isNil } from 'ramda';

import useMetrics from 'hooks/grid/metrics/useMetrics';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import { addExistingMetricToProject, removeMetricFromProject } from 'store/projects';
import { updateMetricById } from 'store/metrics';

import useMetricOptions from 'src/containers/MetricsForm/hooks/useMetricOptions';

/**
 * @function useProjectsMultipleMetricsForm
 *
 * Use actions to deal with multiple metrics form
 * add, edit, update actions
 *
 * @param  {Object} project
 * @return {Object}
 */
export default function useProjectsMultipleMetricsForm(project) {
  const dispatch = useDispatch();

  const { canView } = usePermissions();
  const hasNewMetadataAutocompleteForMetrics = canView(PERMISSION_FEATURES.newRoadmapMetadataAutocompleteOnMetricsForm);

  const { activeMetrics: metrics } = useMetrics();
  const metricOptions = useMetricOptions(project, metrics);

  // For the new metadata autocomplete we need the whole Metric, while for the 'old' dropdown we only need the name
  const projectMetricOptions = useMemo(
    () => (hasNewMetadataAutocompleteForMetrics ? metricOptions : metricOptions?.map(m => m.name)),
    [metricOptions, hasNewMetadataAutocompleteForMetrics],
  );

  const projectId = project?.id;

  const onAddMetric = async metricId => {
    if (isNil(metricId)) {
      return;
    }

    dispatch(addExistingMetricToProject(projectId, metricId));
  };

  const onRemoveMetric = async id => {
    if (isNil(id)) {
      return;
    }

    dispatch(removeMetricFromProject(projectId, id));
  };

  const onUpdateMetric = (metricId, update) => {
    if (isNil(metricId)) {
      return;
    }

    dispatch(updateMetricById(metricId, update));
  };

  return {
    metrics,
    metricsOptions: projectMetricOptions || [],
    onAddMetric,
    onRemoveMetric,
    onUpdateMetric,
  };
}
