import React, { useMemo } from 'react';

import { ActionsWrapper, ActionButton, StyledAddIcon, StyledOpenInNewIcon, StyledRoadmapIcon } from './styled';

const renderAddButton = (params, addChildVisible, onClick, disabled = false) => (
  <ActionButton hideButton={!addChildVisible} onClick={() => onClick(params.data, params)} disabled={disabled}>
    <StyledAddIcon />
  </ActionButton>
);

const renderOpenButton = (params, onClick) => (
  <ActionButton onClick={() => onClick(params.data, params)}>
    <StyledOpenInNewIcon />
  </ActionButton>
);

const renderOpenProjectsButton = (params, onClick) => (
  <ActionButton onClick={() => onClick(params.data, params)}>
    <StyledRoadmapIcon />
  </ActionButton>
);

const Actions = params => {
  const {
    checkAddVisibility,
    handleAddOKRWithoutSave,
    openObjectiveDrawer,
    openProjectsLightbox,
    disabled = false,
    showActions = () => true,
  } = params;

  const addChildVisible = useMemo(() => checkAddVisibility && checkAddVisibility(params), [checkAddVisibility, params]);

  const showActionButtons = useMemo(() => showActions(params), [params, showActions]);

  const addButton = useMemo(
    () => showActionButtons && renderAddButton(params, addChildVisible, handleAddOKRWithoutSave, disabled),
    [params, addChildVisible, handleAddOKRWithoutSave, disabled, showActionButtons],
  );

  const openButton = useMemo(
    () => showActionButtons && renderOpenButton(params, openObjectiveDrawer),
    [params, openObjectiveDrawer, showActionButtons],
  );

  const openProjectsButton = useMemo(
    () => showActionButtons && openProjectsLightbox && renderOpenProjectsButton(params, openProjectsLightbox),
    [params, openProjectsLightbox, showActionButtons],
  );

  return (
    <>
      {addButton || openButton ? (
        <ActionsWrapper className="actions">
          {addButton}
          {openProjectsButton}
          {openButton}
        </ActionsWrapper>
      ) : null}
    </>
  );
};

export default Actions;
