import { defaultTo, prop, reduceBy, not } from 'ramda';

const defaultToEmptyArray = defaultTo([]);

const upsertProjectDeliverableOnStore = (projectsDeliverables, upsertedProjectDeliverable = {}) => {
  const { project_id: projectId, cycle_deliverable_id: cycleDeliverableId } = upsertedProjectDeliverable;

  if (not(projectId) || not(cycleDeliverableId)) {
    return projectsDeliverables;
  }

  const getProjectDeliverables = prop(projectId);
  const projectDeliverables = defaultToEmptyArray(getProjectDeliverables(projectsDeliverables));
  const projectDeliverablesByCycleDeliverableId = reduceBy(
    (_, o) => ({ ...o }),
    {},
    prop('cycle_deliverable_id'),
    projectDeliverables,
  );

  const projectDoesNotHaveDeliverable = not(projectDeliverablesByCycleDeliverableId[cycleDeliverableId]);

  // It is a new projectDeliverable and should be added to the project
  if (projectDoesNotHaveDeliverable) {
    return [...projectDeliverables, upsertedProjectDeliverable];
  }

  // It is an update should change the existing one
  return projectDeliverables.map(projectDeliverable => {
    if (cycleDeliverableId === projectDeliverable.cycle_deliverable_id) {
      return upsertedProjectDeliverable;
    }

    return projectDeliverable;
  });
};

export default upsertProjectDeliverableOnStore;
