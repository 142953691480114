import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { pick } from 'ramda';

import {
  deleteDashboardComment,
  editDashboardComment,
  fetchDashboardComments,
  fetchNextDashboardComments,
  getDashboardComments,
  saveDashboardComment,
} from 'store/comments/dashboards';

/**
 * Custom hook for managing comments within a dashboard component.
 *
 * @param {object} options - Options for the hook.
 * @param {object} options.dashboard - The dashboard object for which comments are managed.
 *
 * @returns {object} An object with properties and functions for managing comments.
 */
const useDashboardComments = ({ dashboard }) => {
  const dispatch = useDispatch();
  const commentsData = useSelector(state => getDashboardComments(state, dashboard?.id));

  const hasMoreComments = !!commentsData?._meta?.next;
  const comments = commentsData?.data || [];
  const commentsCount = commentsData?._meta?.count;

  const addCommentHandler = useCallback(
    data => {
      const otherFieldsAllowed = ['mentions', 'editorState', 'integrationType', 'channel'];

      // will use plainText to maintain consistency with edit action
      dispatch(saveDashboardComment(dashboard?.id, { plainText: data.text, ...pick(otherFieldsAllowed, data) }));
    },
    [dashboard?.id],
  );

  const onFetchCommmentsHandler = useCallback(() => dispatch(fetchDashboardComments(dashboard?.id)), [dashboard?.id]);

  const onFetchNextPageHandler = useCallback(() => dispatch(fetchNextDashboardComments(dashboard?.id)), [dashboard?.id]);

  const onDeleteCommentHandler = useCallback(
    commentId => {
      dispatch(deleteDashboardComment(dashboard?.id, commentId));
    },
    [dashboard?.id],
  );

  const onEditCommentHandler = useCallback(
    (commentId, data) => {
      dispatch(editDashboardComment(dashboard?.id, commentId, data));
    },
    [dashboard?.id],
  );

  return {
    hasMoreComments,
    comments,
    commentsCount,

    addCommentHandler,
    onFetchCommmentsHandler,
    onFetchNextPageHandler,
    onDeleteCommentHandler,
    onEditCommentHandler,
  };
};

export default useDashboardComments;
