import { ADD_NEW_LINKED_ITEM_ROW, REMOVE_UNSAVED_LINKED_ITEMS_ROWS } from './types';

export const addNewLinkedItemRow = () => {
  return {
    type: ADD_NEW_LINKED_ITEM_ROW,
    payload: true,
  };
};

export const removeUnsavedLinkedItemsRows = () => {
  return {
    type: REMOVE_UNSAVED_LINKED_ITEMS_ROWS,
  };
};
