import {
  UPDATE_WHITEBOARD_EDGES_LAYOUT,
  UPDATE_WHITEBOARD_LAYOUT_CONFIG,
  UPDATE_WHITEBOARD_NODES_LAYOUT,
} from 'features/whiteboard/store/types';

export const updateLayoutConfig = layoutConfig => ({
  type: UPDATE_WHITEBOARD_LAYOUT_CONFIG,
  payload: layoutConfig,
  meta: {
    makesActiveViewDirty: true,
  },
});

export const updateNodesLayout = nodesLayout => ({
  type: UPDATE_WHITEBOARD_NODES_LAYOUT,
  payload: nodesLayout,
  meta: {
    makesActiveViewDirty: true,
  },
});

export const updateEdgesLayout = edgesLayout => ({
  type: UPDATE_WHITEBOARD_EDGES_LAYOUT,
  payload: edgesLayout,
  meta: {
    makesActiveViewDirty: true,
  },
});
