import { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { either, isNil, isEmpty, map, defaultTo, prop } from 'ramda';

import {
  fetchRallyProjects as fetchRallyProjectsAction,
  resetRallyProjects as resetRallyProjectsAction,
  getRallyProjects as getRallyProjectsSelector,
  isFetchRallyProjectsLoading as isFetchRallyProjectsLoadingSelector,
  isFetchRallyProjectsUninitialized as isFetchRallyProjectsUninitializedSelector,
} from 'store/integrations';

const OBJECT_ID = 'ObjectID';
const NAME = 'Name';

const getObjectID = prop(OBJECT_ID);
const getName = prop(NAME);

const isNilOrEmpty = either(isNil, isEmpty);
const defaultToEmptyArray = defaultTo([]);

const useCreateOnRally = (orgIntegration, onCreate) => {
  const dispatch = useDispatch();

  const [selectedProjectId, setSelectedProjectId] = useState('');

  const isLoadingRallyProjects = useSelector(isFetchRallyProjectsLoadingSelector);
  const rallyProjectsData = useSelector(getRallyProjectsSelector);
  const isFetchRallyProjectsUninitialized = useSelector(isFetchRallyProjectsUninitializedSelector);

  const rallyProjects = useMemo(() => {
    const projects = defaultToEmptyArray(rallyProjectsData?.data);

    return map(project => ({
      value: getObjectID(project),
      label: getName(project),
    }), projects);
  }, [rallyProjectsData]);

  useEffect(() => {
    dispatch(resetRallyProjectsAction());
  }, [orgIntegration?.id]);

  const fetchRallyProjects = useCallback(() => {
    if (orgIntegration && !isLoadingRallyProjects && isFetchRallyProjectsUninitialized) {
      dispatch(fetchRallyProjectsAction(orgIntegration.id));
    }
  }, [orgIntegration, isLoadingRallyProjects, isFetchRallyProjectsUninitialized]);

  const onCreateHandler = useCallback(() => {
    if (isNilOrEmpty(selectedProjectId)) {
      return;
    }
    
    onCreate(selectedProjectId);
  }, [onCreate, selectedProjectId]);

  return {
    fetchRallyProjects,
    setSelectedProjectId,
    isLoadingRallyProjects,
    selectedProjectId,
    rallyProjects,
    onCreateHandler,
  };
};

export default useCreateOnRally;
