import React, { useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { not, values } from 'ramda';

import DndMenuList from 'design-system/molecules/DndMenuList/index';

import theme from '../../theme';
import { BottomListWrapper, RowWrapper, StyledHideMessage, StyledLineSeparator, TopListWrapper } from './styles';
import {
  BOTTOM_AREA,
  LEFT_AREA,
  RIGHT_AREA,
  getFieldUpdateOnDragElement,
  getFieldsByGroupArea,
  getRequiredFields,
} from './utils';

const DROPPABLE_TYPE = 'configuration';

const onDraggingStyle = {
  backgroundColor: theme.palette.background.lightBlue,
  border: `1px dashed ${theme.palette.border.darkBlue}`,
  borderRadius: '4px',
  strokeWidth: '2px',
};

const renderList = (parentId, items, draggable, isDragging, itemRightActions, checkable, handleSelectItems, selectedItems) => (
  <DndMenuList
    parentId={parentId}
    items={items}
    type={DROPPABLE_TYPE}
    draggable={draggable}
    isDragging={isDragging}
    onDraggingStyle={onDraggingStyle}
    itemRightActions={itemRightActions}
    checkable={checkable}
    onSelectItem={handleSelectItems}
    selectedItems={selectedItems}
  />
);

const FieldsLayoutConfigurator = ({
  fields = {},
  draggable = true,
  onChange,
  editable,
  enableRequiredFields,
  canFieldBeRequired,
  itemRightActions = [],
}) => {
  const [isDragging, setIsDragging] = useState(false);

  const fieldsByArea = getFieldsByGroupArea(fields);

  const leftFields = fieldsByArea[LEFT_AREA];
  const rightFields = fieldsByArea[RIGHT_AREA];
  const hiddenFields = fieldsByArea[BOTTOM_AREA];
  const requiredFields = getRequiredFields(fields);

  const _onDragStart = () => {
    setIsDragging(true);
  };

  const _onDragEnd = ({ destination, source, draggableId }) => {
    setIsDragging(false);
    const dropedOnSamePosition = destination?.droppableId === source.droppableId && destination.index === source.index;

    if (not(destination) || dropedOnSamePosition) {
      return;
    }

    const updatedFields = getFieldUpdateOnDragElement(fields, destination, source, draggableId);

    if (onChange) onChange(updatedFields);
  };

  const _handleSelectItems = (fieldId, checked) => {
    const fieldsUpdated = values(fields).reduce((acc, field) => {
      if (field.id === fieldId) {
        return {
          ...acc,
          [field.id]: {
            ...field,
            required: checked,
          },
        };
      }

      return {
        ...acc,
        [field.id]: field,
      };
    }, {});

    onChange(fieldsUpdated);
  };

  const checkable = fieldId => enableRequiredFields && editable && canFieldBeRequired(fieldId);
  const selectedRequiredFields = requiredFields.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.id]: true,
    }),
    {},
  );

  return (
    <DragDropContext onDragStart={_onDragStart} onDragEnd={_onDragEnd}>
      <RowWrapper>
        <TopListWrapper>
          {renderList(
            LEFT_AREA,
            leftFields,
            editable && draggable,
            isDragging,
            [...(not(isDragging) ? itemRightActions : [])],
            checkable,
            _handleSelectItems,
            selectedRequiredFields,
          )}
        </TopListWrapper>
        <StyledLineSeparator />
        <TopListWrapper>
          {renderList(
            RIGHT_AREA,
            rightFields,
            editable && draggable,
            isDragging,
            [...(not(isDragging) ? itemRightActions : [])],
            checkable,
            _handleSelectItems,
            selectedRequiredFields,
          )}
        </TopListWrapper>
      </RowWrapper>
      <BottomListWrapper>
        <StyledHideMessage variant="body2" color="textSecondary">
          {editable && draggable && 'Drag fields below to hide'}
        </StyledHideMessage>
        {renderList(BOTTOM_AREA, hiddenFields, editable && draggable, isDragging)}
      </BottomListWrapper>
    </DragDropContext>
  );
};

export default FieldsLayoutConfigurator;
