/* eslint-disable camelcase */
// External dependencies
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';

// Dragonboat dependencies
import subscribeNewData from 'subscribeNewData';
import { STAFFING_PAGE, FORECAST_PAGE } from 'constants/filters';
import { updateState } from 'store/staffing';
import { getUserName } from 'utils';
import { getUsers } from 'store/users/selectors';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import { getDisplayLayer, getPageFilters } from 'store/filters/selectors';
import useSystemFields from 'hooks/useSystemFields';
import getDisplayLayerLabel from 'store/projects/helpers/getDisplayLayerLabel';
import useDeepEffect from 'hooks/useDeepEffect';
import { fetchUsers } from 'store/users';
import { fetchTasks } from 'store/tasks';
import { updateDefaultUserFilter } from 'store/filters';
import useBookADemoLocker from 'hooks/useBookADemoLocker';

import useStaffingDataTree from './hooks/useStaffingDataTree';
import useStaffingTasks from './hooks/useStaffingTasks';
import useUpdateTask from './hooks/useUpdateTask';
import { getStaffingFiltersForApi } from './helpers';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [scheduler, setScheduler] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const lsState = useSelector(state => state.staffing);
    const displayLayer = useSelector(state => getDisplayLayer(state));
    const filters = useSelector(state => getPageFilters(state, STAFFING_PAGE));
    const users = useSelector(state =>
      getStateDataForPage(state, getUsers, 'users', state.staffing.showInactiveUsers, STAFFING_PAGE),
    );
    const dataTypeField = `${lsState.swimlaneType ? lsState.swimlaneType.key : 'owner'}_id`;
    const [tasks] = useStaffingTasks(users);
    const [dataTree, currentPage, setCurrentPage, collapseAll, expandAll, toggleGroup] = useStaffingDataTree(tasks, users);
    const [handleTaskUpdate] = useUpdateTask();
    const [getSystemFieldName] = useSystemFields();

    const displayLayerRef = React.useRef(displayLayer);
    const displayMilestoneOnRef = React.useRef(lsState.displayMilestoneOn);
    const dataTypeFieldRef = React.useRef(dataTypeField);
    const currentPageRef = React.useRef(currentPage);
    const dataTreeRef = React.useRef(dataTree);

    dataTreeRef.current = dataTree;
    currentPageRef.current = currentPage;
    displayLayerRef.current = displayLayer;
    displayMilestoneOnRef.current = lsState.displayMilestoneOn;
    dataTypeFieldRef.current = dataTypeField;

    const _updateLsState = state => dispatch(updateState(state));
    const _showLightbox = selectedTask => _updateLsState({ selectedTask });
    const _onInitScheduler = schedulerApi => {
      setScheduler(schedulerApi);

      const on = schedulerApi.attachEvent.bind(schedulerApi);

      on('onBeforeFolderToggle', (section, isOpen) => {
        toggleGroup(section.key);
      });
      on('onBeforeLightbox', id => {
        const task = schedulerApi.getEvent(id);

        task.$new = !task.title && !task.project_id;
        if (task.$new) {
          const owner = users.find(u => u.id === task.owner_id);

          task.duration = moment(task.end_date).diffDuration(moment(task.start_date), 'days');
          task.ownerName = owner ? getUserName(owner) : '';
        }

        _showLightbox({
          ...task,
          start_date: moment(task.start_date).parseFromGantt(),
          end_date: moment(task.end_date).parseFromGantt(true),
        });
      });
      on('onEventChanged', (id, task) => {
        const startDate = moment(task.start_date).parseFromGantt();
        const endDate = moment(task.end_date).parseFromGantt(true);

        const taskToUpdate = { ...task, start_date: startDate, end_date: endDate };

        taskToUpdate[dataTypeFieldRef.current] = task[dataTypeFieldRef.current] === 'N/A' ? null : task[dataTypeFieldRef.current];

        handleTaskUpdate(id, 'updated', {
          ...taskToUpdate,
          duration: Math.abs(endDate.diffDuration(startDate, 'days', false)),
        });
      });
    };
    const _exportToExcel = () => {
      if (!scheduler) return;

      scheduler.exportToExcel({
        name: 'My document.xls',
        columns: [
          { id: 'text', header: 'Title', width: 50 },
          { id: 'projectName', header: 'Idea', width: 50 },
          { id: 'ownerName', header: 'Owner', width: 30 },
          { id: 'ownerAllocation', header: 'Owner Allocation', width: 20 },
          { id: 'start_date', header: 'Start date', width: 20 },
          { id: 'end_date', header: 'End date', width: 20 },
        ],
      });
    };
    const _toggleLocalMode = () => _updateLsState({ localMode: !lsState.localMode });
    const _renderTooltipText = (task, start, end) => {
      const projectLabel = getDisplayLayerLabel(displayLayerRef.current, getSystemFieldName);
      const endDate = moment(end).parseFromGantt(true).formatAsDate();

      return `${!lsState.hideTasksName ? `<b>Task:</b> ${task.title}<br />` : ''}
        <b>${projectLabel}:</b> ${task.text ? task.text.split(':')[0].trim() : ''}<br />
        <b>Owner Allocation:</b> ${task.ownerAllocation}%<br />
        <b>Start date:</b> ${start}<br />
        <b>End date:</b> ${endDate}<br />`;
    };
    const _nextPage = () => {
      const nextPage = currentPageRef.current + 1;

      setCurrentPage(nextPage);
    };

    useDeepEffect(() => {
      const [usersFilters, taksFilters] = getStaffingFiltersForApi({ ...filters, displayLayer: [displayLayer] });

      const _loadData = async () => {
        setLoadingData(true);
        await dispatch(fetchUsers({ params: usersFilters }, true));
        await dispatch(fetchTasks(taksFilters));
        await dispatch(updateDefaultUserFilter(STAFFING_PAGE, filters, true));
        setLoadingData(false);
      };

      _loadData();
    }, [filters, displayLayer]);

    const BookADemoLockerComponent = useBookADemoLocker(FORECAST_PAGE);

    if (BookADemoLockerComponent) return <BookADemoLockerComponent />;

    return (
      <Component
        {...props}
        loaded
        dataTree={dataTree}
        tasks={tasks}
        onInitScheduler={_onInitScheduler}
        exportToExcel={_exportToExcel}
        localMode={lsState.localMode}
        toggleLocalMode={_toggleLocalMode}
        allCollapsed={dataTree.every(i => !i.open)}
        collapseAll={collapseAll}
        expandAll={expandAll}
        lsState={lsState}
        dataTypeField={dataTypeField}
        renderTooltipText={_renderTooltipText}
        nextPage={_nextPage}
        loadingData={loadingData}
        scheduler={scheduler}
      />
    );
  };
};

export default compose(subscribeNewData(['app', 'tasks', 'customFields', 'timeframes']), withRouter, componentHOC);
