import { useSelector } from 'react-redux';

import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from '@dragonboat/permissions';
import { buildProjectsFiltersForBetChildren, buildProjectsFiltersForInitiativeChildren } from 'utils/filters/projectsFilters';
import { getSelectedProject } from 'store/projects';

import { propOr } from 'ramda';

const filterBuilderMapper = {
  [INITIATIVE_LAYER]: buildProjectsFiltersForInitiativeChildren,
  [BET_LAYER]: buildProjectsFiltersForBetChildren,
};
const displayLayerMapper = {
  [INITIATIVE_LAYER]: IDEA_LAYER,
  [BET_LAYER]: INITIATIVE_LAYER,
};
const defaultFilterBuilder = () => ({});

const useTimelineLightboxInProgressChart = ({ parentProjectId }) => {
  const parentProject = useSelector(state => getSelectedProject(state, { id: parentProjectId }));

  const filterBuilder = propOr(defaultFilterBuilder, parentProject?.layer)(filterBuilderMapper);

  const timelineLightboxFilters = filterBuilder(parentProject?.id);
  const timelineLightboxPortfolioMode = parentProject?.layer === BET_LAYER;
  const timelineLightboxDisplayLayer = displayLayerMapper[parentProject?.layer];

  return {
    timelineLightboxFilters,
    timelineLightboxPortfolioMode,
    timelineLightboxDisplayLayer,
  };
};

export default useTimelineLightboxInProgressChart;
