import React from 'react';
import styled from 'styled-components';
import { TOAST_MESSAGE_TYPES } from 'design-system/constants';

const getColorByType = props => {
  switch (props.type) {
    case TOAST_MESSAGE_TYPES.ERROR:
      return props.theme.palette.text.error;
    case TOAST_MESSAGE_TYPES.INFO:
    default:
  }
};

export default ({ title, description, type }) => {
  return (
    <Wrapper>
      <Title type={type}>{title}</Title>
      {description && <Description>{description}</Description>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-weight: ${props => props.theme.typography.fontWeightMedium};
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.fontSize}px;
  line-height: 18px;
`;

const Title = styled.p`
  color: ${props => getColorByType(props)};
  font-weight: ${props => props.theme.typography.fontWeightRegular};
  margin-bottom: ${props => props.theme.spacing.unit / 2}px;
`;

const Description = styled.p`
  color: ${props => props.theme.palette.text.midnightSun};
  font-weight: ${props => props.theme.typography.fontWeightRegular};
`;
