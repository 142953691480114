/**
 * HelpLink Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import styled from 'styled-components';
import { textColor } from 'design-system/themes/default';

export default styled.a`
  color: ${textColor}!important;
  margin-left: 8px;

  svg{
    width: 20px;
    height: 20px;
  }
`;
