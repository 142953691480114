import { has, pipe, includes, not } from 'ramda';

const hasLayerProperty = has('layer');
const notIncludes = pipe(includes, not);

/**
 * @function filterNotAvailableLayersFromFilters
 *
 * Filters the not available layers from the filters payload.
 * And if required assign the current account bottom layer.
 *
 * IMPORTANT  - currently this function is changing/mutating the param filters on purpose.
 *            - this happens because in some places/pages expecting always the same ref
 *              for the filters object.
 *
 * @param {Object} filters
 * @param {String} bottomLayer
 * @param {Array} availableLayers
 */
export const filterNotAvailableLayersFromFilters = (filters, bottomLayer, availableLayers = []) => {
  if (hasLayerProperty(filters)) {
    if (Array.isArray(filters.layer)) {
      filters.layer = filters.layer.filter(layer => includes(layer, availableLayers));
    } else if (notIncludes(filters.layer, availableLayers)) {
      filters.layer = bottomLayer;

      if (filters.fields?.layer) {
        filters.fields.layer = bottomLayer;
      }
    }
  }

  if (Array.isArray(filters.secondLayer)) {
    filters.secondLayer = filters.secondLayer.filter(layer => includes(layer, availableLayers));
  }

  return filters;
};
