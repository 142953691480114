import axios from 'axios';

import {
  FETCH_SKILLS,
  UPDATE_SKILL,
  UPDATE_SKILL_BY_ID,
  CREATE_SKILL,
  ADD_SKILL_WITHOUT_SAVE,
  REMOVE_UNSAVED_SKILLS,
  CREATE_SKILL_FULFILLED,
  DELETE_SKILL_FULFILLED,
  UPDATE_SKILL_FULFILLED,
  BULK_DELETE_SKILLS_FULFILLED,
} from './types';
import fetchMetadataAction from '../utils/factory/fetchMetadataAction';

import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';

export const fetchSkills = fetchMetadataAction(
  FETCH_SKILLS,
  '/api/skills',
  (state) => state.skills.skills,
  (state) => state.skills.lastCallsDate,
);

export const updateSkill = skill => {
  return {
    payload: axios.post(`/api/skills/update/${skill.id}`, skill).then(res => res.data),
    type: UPDATE_SKILL,
  };
};

export const updateSkillById = (id, skill) => {
  return {
    payload: axios.put(`/api/skills/${id}`, skill).then(res => res.data),
    type: UPDATE_SKILL_BY_ID,
  };
};

export const createSkill = skill => {
  return {
    payload: axios.post('/api/skills/', skill).then(res => res.data),
    type: CREATE_SKILL,
  };
};

export const addSkillWithoutSave = skill => ({
  type: ADD_SKILL_WITHOUT_SAVE,
  skill,
});

export const removeUnsavedSkills = _ => ({
  type: REMOVE_UNSAVED_SKILLS,
});

export const gotSkillsRealtimeUpdate = generateRealtimeUpdateAction(
  CREATE_SKILL_FULFILLED, DELETE_SKILL_FULFILLED, UPDATE_SKILL_FULFILLED, BULK_DELETE_SKILLS_FULFILLED);

export const skillActions = {
  fetchSkills,
  updateSkill,
  updateSkillById,
  createSkill,
  addSkillWithoutSave,
  removeUnsavedSkills,
};
