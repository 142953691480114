import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  addUserIntegration as addUserIntegrationAction,
  removeUserIntegration as removeUserIntegrationAction,
  fetchUserIntegrations as fetchUserIntegrationsAction,
  clearLoadedUserIntegrationsForOrgIntegration as clearLoadedUserIntegrationsAction,
} from 'src/store/login';

import { authenticateWithOAuth2 } from 'src/store/integrations';

import {
  getUserIntegrations as getUserIntegrationsSelector,
  getUserIntegrationByOrgIntegrationId as getUserIntegrationByOrgIntegrationIdSelector,
  getAddUserIntegrationError as getAddUserIntegrationErrorSelector,
} from 'store/login/selectors';

const AUTH_TYPE_OAUTH2 = 'oauth2';

const useUserIntegrations = (integrationType, orgIntegrationId) => {
  const dispatch = useDispatch();

  const userIntegration = useSelector(getUserIntegrationByOrgIntegrationIdSelector(orgIntegrationId));
  const userIntegrations = useSelector(getUserIntegrationsSelector);

  const addUserIntegrationError = useSelector(getAddUserIntegrationErrorSelector);

  const addUserIntegration = useCallback(
    (token, metadata) => dispatch(addUserIntegrationAction(integrationType, orgIntegrationId, token, metadata)),
    [integrationType, orgIntegrationId],
  );

  const removeUserIntegration = useCallback(
    () => dispatch(removeUserIntegrationAction(integrationType, orgIntegrationId)),
    [integrationType, orgIntegrationId],
  );

  const clearLoadedUserIntegrations = useCallback(
    () => dispatch(clearLoadedUserIntegrationsAction(orgIntegrationId)),
    [orgIntegrationId],
  );

  const fetchUserIntegrations = useCallback(() => dispatch(fetchUserIntegrationsAction()), []);

  const addUserIntegrationByAuthType = useCallback(
    (authType, token = null) => {
      if (authType === AUTH_TYPE_OAUTH2) {
        return authenticateWithOAuth2(integrationType, orgIntegrationId);
      }
      return dispatch(addUserIntegrationAction(integrationType, orgIntegrationId, token));
    },
    [integrationType, orgIntegrationId],
  );

  return {
    userIntegration,
    userIntegrations,
    addUserIntegrationError,
    addUserIntegration,
    addUserIntegrationByAuthType,
    removeUserIntegration,
    fetchUserIntegrations,
    clearLoadedUserIntegrations,
  };
};

export default useUserIntegrations;
