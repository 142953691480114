import axios from 'axios';
import isFunction from 'lodash/isFunction';

import throwRequestError from 'store/utils/throwRequestError';

const updateCycleDeliverableApiCall = (cycleDeliverableId, data, onUpdateError) =>
  axios
    .put(`/api/cycle-deliverables/${cycleDeliverableId}`, { ...data })
    .then(({ data }) => data)
    .catch(err => {
      if (isFunction(onUpdateError)) onUpdateError(err, data);
      throwRequestError(err);
    });

export default updateCycleDeliverableApiCall;
