import React from 'react';

import { DragDropContext } from 'react-beautiful-dnd';

import DndAtoms from 'design-system/atoms/DndAtoms/index';


const { MenuList, InnerWrapper } = DndAtoms;

export default ({
  draggable,
  renderSearch,
  renderClear,
  renderShowArchived,
  onDragEnd,
  children,
  color,
  fontSize,
  styleAsAutocomplete,
  renderAddOption,
}) => {
  return (
    <MenuList color={color} fontSize={fontSize} hasFooter={!!renderClear} $styleAsAutocomplete={styleAsAutocomplete}>
      {renderSearch && renderSearch()}
      {draggable ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <InnerWrapper>{children}</InnerWrapper>
        </DragDropContext>
      ) : (
        <InnerWrapper>{children}</InnerWrapper>
      )}
      {renderShowArchived && renderShowArchived()}
      {renderClear && renderClear()}
      {renderAddOption && renderAddOption()}
    </MenuList>
  );
};
