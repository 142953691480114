import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getOrganization } from 'store/organization';
import {
  HEIGHT_OFFSET_FOR_NEW_VERSION_OF_AGGRID,
  ROW_HEIGHT_EXTRA_INCREMENT,
} from 'routes/Dashboard/Snapshot/SnapshotTable/helpers/constants';
import { CORP_OBJECTIVE_KEY, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY, OBJECTIVE_KEY } from 'constants/objectives';

const useSnapshotTableComponent = (selectedSnapshotField, rows, showSnapshotTreeView, pageSize, rowHeight) => {
  const organization = useSelector(getOrganization);

  const displayOkr = useMemo(
    () => [CORP_OBJECTIVE_KEY, OBJECTIVE_KEY, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY].includes(selectedSnapshotField),
    [selectedSnapshotField],
  );

  const heightOffset = displayOkr ? HEIGHT_OFFSET_FOR_NEW_VERSION_OF_AGGRID : 0;

  // If tree view, calculate the length for the top level elements only
  const rowsLength = useMemo(() => {
    // Use parent rows length if tree view, but fallback to all rows.length in case
    // there was an issue accessing the `path` attribute (possible corner case).
    return (showSnapshotTreeView && rows.length) || rows.length;
  }, [showSnapshotTreeView, rows]);

  // prettier-ignore
  const tableHeight = (
    (pageSize > rowsLength ? rowsLength : pageSize) * (rowHeight + ROW_HEIGHT_EXTRA_INCREMENT)
  ) + heightOffset;

  return {
    displayOkr,
    tableHeight,
    systemFields: organization.system_fields_name,
  };
};

export default useSnapshotTableComponent;
