// AzureDevopsCallback.js
import React, { useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

const CODE = 'code';
const STATE = 'state';

const AzureDevopsCallback = props => {
  useEffect(() => {
    const queryParams = new URLSearchParams(props.location.search);

    if (queryParams.has(CODE)) {
      const code = queryParams.get(CODE);
      const state = queryParams.get(STATE);

      if (window.opener) {
        window.opener.postMessage({ code, state, ssoType: 'azuredevops' }, '*');
        window.close();
      }
    }
  }, [props.location.search]);

  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        marginTop: '-1em' /* set to a negative number 1/2 of your height */,
        marginLeft: '-2em' /* set to a negative number 1/2 of your width */,
      }}
    >
      <CircularProgress size={50} />
    </div>
  );
};

export default AzureDevopsCallback;
