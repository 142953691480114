import React, { useRef, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { defaultTo, equals, isNil, not, pipe, prop } from 'ramda';

import CloseIcon from '@material-ui/icons/Close';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import HierarchyBreadcrumbTitles from 'design-system/organisms/HierarchyBreadcrumbTitles/index';

import { draftBackgroundColor } from 'design-system/constants/draft';
import { removeElemProps } from 'design-system/utils';

import theme from 'design-system/theme';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useGenerateProjectBreadcrumbTitles from 'hooks/useGenerateProjectBreadcrumbTitles';

import isOldLightboxActive from '../utils/isOldLightboxActive';
import { generateProjectOwnerOrganizationUrl } from '../utils/generateProjectOwnerOrganizationUrl';

import { PAGE_HEADER_HEIGHT } from 'constants/common';

const ICON_SIZE = `${theme.typography.fontSizeMediumSmallRem}rem`;
const ACTIVE_TAB_COLOR = theme.palette.primary.main;
const DEFAULT_TAB_COLOR = theme.palette.newLayout.text.grey;

const MILESTONE = 'milestone';
const TITLE = 'title';

const defaultToEmptyObject = defaultTo({});

const getId = pipe(defaultToEmptyObject, prop('id'));
const getOrganizationId = pipe(defaultToEmptyObject, prop('organization_id'));
const isNotNil = pipe(isNil, not);

const isTitleDirty = pipe(defaultToEmptyObject, prop(TITLE), equals(true));

const getTabTestId = name => `project-lightbox-tab-${(name || '').toLowerCase()}`;

const generateProjectTitle = (
  isNew,
  selectedProject,
  isMilestone,
  headerTitle,
  creatingNewSelectedLayer,
  formData,
  formDirtyFields,
) =>
  isNew
    ? {
        layer: creatingNewSelectedLayer,
        isMilestone: isMilestone ? MILESTONE : null,
        placeholder: headerTitle,
        readingPlaceholder: headerTitle,
        title: isTitleDirty(formDirtyFields) ? formData.title : undefined,
      }
    : { ...selectedProject, isMilestone: isMilestone ? MILESTONE : null };

export default ({
  headerTitle,
  isNew,
  onClose,
  organization,
  updateFormData,
  onSave,
  selectedProject,
  formData,
  formDirtyFields,
  parent,
  grandParent,
  creatingNewSelectedLayer,
  gettingDataFields,
  selectedRoadmapVersion,
  setRef,
  isMilestone,
  fieldValidation,
  userCanEdit,
}) => {
  const headerRef = useRef(null);
  const {
    isDodActive,
    getAccountNameAndUrlForOrganizationId,
    getReaderTokenByOrganizationId,
    fetchChildOrganizationReaderToken,
  } = useOrganizationsAccessControl();

  const oldLightboxActive = isOldLightboxActive();

  const update = newData =>
    isNew ? updateFormData(newData) : onSave(true, false, { id: selectedProject.id, ...newData }, false);

  const _checkFieldDisabled = field => {
    if (!userCanEdit) return true;
    if (gettingDataFields) return gettingDataFields.includes(field);

    return false;
  };

  const dodReaderToken = useMemo(() => {
    return selectedProject ? getReaderTokenByOrganizationId(getOrganizationId(selectedProject)) : null;
  }, [getReaderTokenByOrganizationId, selectedProject?.id]);

  const openProjectInOwnerOrganization = useCallback(() => {
    if (isNotNil(dodReaderToken)) {
      const { accountUrl } = getAccountNameAndUrlForOrganizationId(getOrganizationId(selectedProject));
      const url = generateProjectOwnerOrganizationUrl(getId(selectedProject), accountUrl, dodReaderToken);

      window.open(url, '_blank');

      fetchChildOrganizationReaderToken(getOrganizationId(selectedProject));
    }
  }, [dodReaderToken, fetchChildOrganizationReaderToken, selectedProject?.id, selectedProject?.organization_id]);

  const wrapperStyle = {};

  if (selectedRoadmapVersion) {
    wrapperStyle.backgroundColor = draftBackgroundColor;
  }

  const projectForBreadcrumbTitle = generateProjectTitle(
    isNew,
    selectedProject,
    isMilestone,
    headerTitle,
    creatingNewSelectedLayer,
    formData,
    formDirtyFields,
  );

  const projectOwnerAccountName = useMemo(() => {
    const { organization_id: projectOrganizationId } = selectedProject || {};
    const shouldRenderLinkToOwnerAccount = isDodActive && projectOrganizationId !== organization?.id;
    const { accountName } = getAccountNameAndUrlForOrganizationId(projectOrganizationId);

    const canRenderAccountNameAndLink = shouldRenderLinkToOwnerAccount && accountName;

    return canRenderAccountNameAndLink ? accountName : null;
  }, [selectedProject?.organization_id, isDodActive, getAccountNameAndUrlForOrganizationId, organization?.id]);

  // creates a list of items with the correct icons, titles, actions and other props for breadcrumb title
  const { item, parentItem, ancestryItems } = useGenerateProjectBreadcrumbTitles(
    projectForBreadcrumbTitle,
    parent,
    grandParent,
    projectOwnerAccountName,
    update,
    _checkFieldDisabled('title') && !isNew,
    fieldValidation,
    dodReaderToken && openProjectInOwnerOrganization,
  );

  const _renderProjectTitle = () => (
    <HierarchyBreadcrumbTitles item={item} parentItem={parentItem} ancestryItems={ancestryItems} showTooltip />
  );

  const _setRef = ref => {
    headerRef.current = ref;
    if (setRef) setRef(ref);
  };

  return (
    <Wrapper ref={_setRef} oldLightboxActive={oldLightboxActive} style={wrapperStyle}>
      {_renderProjectTitle()}
      <CloseIconWrapper onClick={onClose} data-testid={getTabTestId('Close')}>
        <Icon title="Close">
          <CloseIcon />
        </Icon>
      </CloseIconWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  margin: 0 0 12px 0;
  max-height: 48px;
  width: 100%;
  align-items: center;

  ${({ oldLightboxActive }) =>
    !oldLightboxActive &&
    css`
      min-height: ${PAGE_HEADER_HEIGHT}px;
      height: ${PAGE_HEADER_HEIGHT}px;
      padding: 15px 24px;
      border-bottom: 1px solid ${theme.palette.newLayout.background.borderGray};
      max-height: unset;
      margin: 0;
    `}
`;

const Icon = styled(removeElemProps(ButtonIcon, ['active']))`
  &&&&& {
    color: ${DEFAULT_TAB_COLOR};

    ${({ active }) => active && `color: ${ACTIVE_TAB_COLOR};`}
    svg {
      fill: ${DEFAULT_TAB_COLOR};

      ${({ active }) => active && `fill: ${ACTIVE_TAB_COLOR};`}
    }
  }
`;

const CloseIconWrapper = styled.div`
  min-height: 0;
  font-weight: 400;
  font-size: 0.875rem;
  min-width: 0;

  svg {
    font-size: ${ICON_SIZE};
  }
`;
