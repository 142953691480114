import { getThunkActionTypes } from 'utils/store/thunk';

export const OPEN_LIGHTBOX_TO_CREATE_PROJECT = 'OPEN_LIGHTBOX_TO_CREATE_PROJECT';
export const OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_REQUEST = 'OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_REQUEST';
export const OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_METRIC = 'OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_METRIC';
export const OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_OKR = 'OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_OKR';

export const CLOSE_PROJECT_LIGHTBOX = 'CLOSE_PROJECT_LIGHTBOX';

export const GET_PROJECT_INTEGRATION_PROGRESS = 'GET_PROJECT_INTEGRATION_PROGRESS';
export const GET_PROJECT_INTEGRATION_PROGRESS_FULFILLED = 'GET_PROJECT_INTEGRATION_PROGRESS_FULFILLED';

export const SET_DATE_RANGE_MODE = 'SET_DATE_RANGE_MODE';

export const SET_VISIBLE_FIELDS = 'SET_VISIBLE_FIELDS';

export const SET_CURRENT_PROJECT_PARENT = 'SET_CURRENT_PROJECT_PARENT';

export const {
  OPEN_PROJECT_LIGHTBOX,
  OPEN_PROJECT_LIGHTBOX_PENDING,
  OPEN_PROJECT_LIGHTBOX_FULFILLED,
  OPEN_PROJECT_LIGHTBOX_REJECTED,
} = getThunkActionTypes('OPEN_PROJECT_LIGHTBOX');

export const {
  OPEN_PROJECT_LIGHTBOX_WITH_SCENARIO,
  OPEN_PROJECT_LIGHTBOX_WITH_SCENARIO_PENDING,
  OPEN_PROJECT_LIGHTBOX_WITH_SCENARIO_FULFILLED,
  OPEN_PROJECT_LIGHTBOX_WITH_SCENARIO_REJECTED,
} = getThunkActionTypes('OPEN_PROJECT_LIGHTBOX_WITH_SCENARIO');

export const { LOAD_ESTIMATES_TAB, LOAD_ESTIMATES_TAB_PENDING, LOAD_ESTIMATES_TAB_FULFILLED, LOAD_ESTIMATE_TAB_REJECTED } =
  getThunkActionTypes('LOAD_ESTIMATES_TAB');
