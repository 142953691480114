import React, { useState, useMemo, useCallback } from 'react';
import { toUpper, pipe, split, last, path, has } from 'ramda';
import styled from 'styled-components';
import BrightnessLow from '@material-ui/icons/BrightnessLow';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import ImportIcon from '@material-ui/icons/CloudUploadOutlined';
import BuildIcon from '@material-ui/icons/Build';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';
import ViewIcon from '@material-ui/icons/ViewCompactOutlined';
import { FeatureFlags } from '@dragonboat/config';
import CreateIcon from '@material-ui/icons/Create';

import DragonIconButton from 'design-system/atoms/DragonIconButton/index';
import DragonMenu from 'design-system/atoms/DragonMenu/index';
import MultiSelectDropdown2ColumnsPopover from 'design-system/organisms/MultiSelectDropdown2ColumnsPopover/index';

import Add from 'design-system/atoms/DragonIcons/Add';
import MoreHoriz from 'design-system/atoms/DragonIcons/MoreHoriz';
import ShowFieldsIcon from 'design-system/atoms/ShowFieldsIcon';
import useSystemFields from 'hooks/useSystemFields';
import { AVAILABLE_VIEWS } from 'constants/customerRequests';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import ImportCustomerRequests from '../Header/ImportCustomerRequests';

import {
  getFieldOptions,
  getVisibleFieldTitles,
  getVisibleFieldKeys,
  getAllFields,
  getIsPortalRoute,
  HIDE_FIELD_KEY_BY_FEATURE_FLAG,
} from 'routes/CustomerRequests/helpers';
import useDeepEffect from 'hooks/useDeepEffect';
import {
  ADMIN_USER,
  OWNER_USER,
  LEADER_USER,
  REQUESTOR_USER,
  EXTERNAL_REQUESTOR,
  MANAGER_USER,
  EDITOR_USER,
} from '@dragonboat/permissions';
import ShareView from 'containers/ShareView';
import { REQUESTS_INSIGHTS, REQUESTS_DETAIL, REQUESTS_LIST } from 'constants/filters';
import useFeatureFlags, { checkOrganizationFlags } from 'hooks/useFeatureFlags';
import CustomerRequestsAutoTag from 'features/CustomerRequestsAutoTag';
import usePermissions from 'hooks/permissions/usePermissions';
import RequestsGlobalSearch from 'features/RequestsGlobalSearch';

const isNotRequestor = userRole => ![REQUESTOR_USER, EXTERNAL_REQUESTOR].some(role => String(role) === String(userRole));

const DETAIL_URL = '/requests/detail';
const LIST_URL = '/requests/list';
const INSIGHTS_URL = '/requests/insights';

export default props => {
  const {
    currentUser,
    customFields,
    gridColumnsState,
    onChangeVisibleFields,
    onClickNew,
    toggleBulkActions,
    toggleCustomFields,
    togglePortalSettings,
    toggleForwardEmails,
    organization,
    saveGridRowHeight,
    canCreateRequest,
    handleExportToCsv,
  } = props;

  const hasEnabledAutoTag = useFeatureFlags([FeatureFlags.HAS_CUSTOMER_REQUESTS_AUTO_TAG]);

  const { canView } = usePermissions();

  // We're no longer getting `selectedView` from state but instead from url path
  const getSelectedViewFromLocation = pipe(path(['history', 'location', 'pathname']), split('/'), last, toUpper);
  const selectedView = getSelectedViewFromLocation(props);
  const currentlyViewingDetailPage = selectedView === AVAILABLE_VIEWS.DETAIL;

  const canBulkEdit =
    canView(PERMISSION_FEATURES.requestsListBulkUpdate) ||
    canView(PERMISSION_FEATURES.requestsMerge) ||
    canView(PERMISSION_FEATURES.requestsBulkDelete);

  const MORE_OPTIONS_DATA = [
    {
      key: 'showFields',
      icon: <ShowFieldsIcon fill="#6b6b6b" style={{ fontSize: '24px' }} />,
      label: 'Show Fields',
    },
    {
      key: 'customField',
      icon: <BrightnessLow />,
      label: 'Custom Fields',
    },
    {
      key: 'import',
      icon: <ImportIcon />,
      label: 'Import',
      hide: !canCreateRequest,
    },
    {
      key: 'export',
      icon: <DownloadIcon />,
      label: 'Export',
    },
    {
      key: 'portalSettings',
      icon: <BuildIcon />,
      label: 'Portal Settings',
      // TODO: PERMISSION
      hide:
        ![ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER].includes(currentUser.role_id) ||
        !organization.enable_portal_settings,
    },
    // {
    //   key: 'merge',
    //   icon: <MergeTypeIcon />,
    //   label: 'Merge Items',
    //   hide: ![ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER].includes(currentUser.role_id),
    // },
    {
      key: 'bulkUpdate',
      icon: <CreateIcon style={{ verticalAlign: 'bottom' }} />,
      label: 'Bulk Update',
      hide: !canBulkEdit,
    },
    {
      key: 'forwardEmails',
      icon: <EmailIcon />,
      label: 'Forward emails',
      // TODO: PERMISSION
      hide:
        !organization.create_request_from_email ||
        ![ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER].includes(currentUser.role_id),
    },
    {
      key: 'shareView',
      icon: <ViewIcon />,
      label: 'View',
    },
  ];
  const DETAILS_ITEMS = ['customField', 'import', 'portalSettings', 'forwardEmails', 'shareView'];
  const LIST_ITEMS = [
    'customField',
    'import',
    'export',
    'portalSettings',
    'forwardEmails',
    'bulkDelete',
    'bulkUpdate',
    'merge',
    'showFields',
    'shareView',
  ];
  const INSIGHTS_ITEMS = ['showFields', 'shareView'];

  const optionsMenuItemsByView = {
    [AVAILABLE_VIEWS.LIST]: LIST_ITEMS,
    [AVAILABLE_VIEWS.DETAIL]: DETAILS_ITEMS,
    // TODO: PERMISSION
    ...(isNotRequestor(currentUser.role_id) ? { [AVAILABLE_VIEWS.INSIGHTS]: INSIGHTS_ITEMS } : {}),
  };

  const optionsPageIdByView = {
    [AVAILABLE_VIEWS.LIST]: REQUESTS_LIST,
    [AVAILABLE_VIEWS.DETAIL]: REQUESTS_DETAIL,
    [AVAILABLE_VIEWS.INSIGHTS]: REQUESTS_INSIGHTS,
  };

  const optionsPageURLByView = {
    [AVAILABLE_VIEWS.LIST]: LIST_URL,
    [AVAILABLE_VIEWS.DETAIL]: DETAIL_URL,
    [AVAILABLE_VIEWS.INSIGHTS]: INSIGHTS_URL,
  };

  const [isImportLightboxOpen, setIsImportLightboxOpen] = useState(false);
  const [isConfigureFieldsOpen, setIsConfigureFieldsOpen] = useState(false);
  const [moreOptionsAncherEl, setMoreOptionsAnchorEl] = useState(null);
  const [rowHeightGridAnchorEl, setRowHeightGridAnchorEl] = useState(null);
  const [visibleFields, setVisibleFields] = useState(getVisibleFieldTitles(gridColumnsState, customFields));
  const [getSystemFieldName] = useSystemFields();
  const [isShareViewMenuOpen, setIsShareViewMenuOpen] = useState(false);

  useDeepEffect(() => {
    setVisibleFields(getVisibleFieldTitles(gridColumnsState, customFields));
  }, [gridColumnsState, customFields]);

  const _onShowDetail = event => setMoreOptionsAnchorEl(event.currentTarget);
  const _onShowRowHeightOptions = event => setRowHeightGridAnchorEl(event.currentTarget);
  const _handleSelectMoreOptions = item => {
    switch (item.key) {
      case 'customField':
        toggleCustomFields();
        break;
      case 'import':
        setIsImportLightboxOpen(true);
        break;
      case 'export':
        handleExportToCsv();
        break;
      case 'portalSettings':
        togglePortalSettings();
        break;
      case 'forwardEmails':
        toggleForwardEmails();
        break;
      case 'showFields':
        setIsConfigureFieldsOpen(true);
        break;
      case 'bulkDelete':
      case 'bulkUpdate':
        toggleBulkActions();
        break;
      case 'shareView':
        setIsShareViewMenuOpen(true);
        break;
      default:
        break;
    }
  };
  const _closeImportLightbox = () => setIsImportLightboxOpen(false);
  const _closeConfigureFields = () => {
    onChangeVisibleFields(getVisibleFieldKeys(visibleFields, customFields));
    setIsConfigureFieldsOpen(false);
  };

  const _handleChangeVisibleFields = visibleFields => {
    setVisibleFields(visibleFields);
    const keys = getVisibleFieldKeys(visibleFields, customFields);

    onChangeVisibleFields(keys);
  };

  const _handleHideAll = () => setVisibleFields(['']);
  const _handleShowAll = () => setVisibleFields(getAllFields(customFields));

  const isDetailsVisible = !!gridColumnsState.find(c => c.colId === 'details' && !c.hide);
  const isPortal = getIsPortalRoute();

  const _closeShareViewMenuPopover = () => {
    setIsShareViewMenuOpen(false);
  };

  const pageIdAndURL = useMemo(() => {
    const id = optionsPageIdByView[selectedView] || '';
    const url = optionsPageURLByView[selectedView] || '';

    return { id, url };
  }, [selectedView]);

  const filterVisibleOption = useCallback(
    field => {
      const hasPermissionToCheck = has(field?.key)(HIDE_FIELD_KEY_BY_FEATURE_FLAG);

      if (hasPermissionToCheck) {
        const hideConfig = HIDE_FIELD_KEY_BY_FEATURE_FLAG[field?.key];
        const flagsCondition =
          'flags' in hideConfig ? hideConfig.flags.some(flag => checkOrganizationFlags(organization, [flag])) : true;
        const viewPermissionCondition = 'viewPermission' in hideConfig ? canView(hideConfig.viewPermission) : true;

        return flagsCondition && viewPermissionCondition;
      }

      return true;
    },
    [organization, canView],
  );

  const moreOptionsByView = optionsMenuItemsByView[selectedView];
  const hasMoreOptions = !isPortal && moreOptionsByView?.length;

  return (
    <>
      <Wrapper includesAutoTag={hasEnabledAutoTag} hasMoreOptions={hasMoreOptions} showAddButton={canCreateRequest}>
        <CustomerRequestsAutoTag />
        {canCreateRequest && (
          <IconButton id="add-new-request" variant="primary" type="fill" icon={<Add />} onClick={onClickNew} />
        )}

        <RequestsGlobalSearch />
        {!isPortal && isDetailsVisible && currentlyViewingDetailPage && (
          <>
            <IconButton
              id="row-height-options"
              type="transparent"
              icon={<FormatLineSpacingIcon />}
              onClick={_onShowRowHeightOptions}
            />
            <DragonMenu
              items={[
                { id: 40, label: '1x', key: 40 },
                { id: 80, label: '2x', key: 80 },
                { id: 120, label: '3x', key: 120 },
                { id: 160, label: '4x', key: 160 },
              ]}
              anchorEl={rowHeightGridAnchorEl}
              placement="bottom-end"
              onSetAnchorEl={setRowHeightGridAnchorEl}
              onSelectItem={({ id }) => {
                saveGridRowHeight(id);
              }}
            />
          </>
        )}
        {!isPortal && moreOptionsByView?.length && (
          <IconButton id="more-options-button" variant="primary" type="fill" icon={<MoreHoriz />} onClick={_onShowDetail} />
        )}
      </Wrapper>

      <DragonMenu
        items={MORE_OPTIONS_DATA.filter(opt => !!(optionsMenuItemsByView[selectedView] || []).includes(opt.key))}
        anchorEl={moreOptionsAncherEl}
        placement="bottom-end"
        onSetAnchorEl={setMoreOptionsAnchorEl}
        onSelectItem={_handleSelectMoreOptions}
      />
      <ImportCustomerRequests isOpen={isImportLightboxOpen} close={_closeImportLightbox} />
      <MultiSelectDropdown2ColumnsPopover
        open={isConfigureFieldsOpen}
        onClose={_closeConfigureFields}
        onChange={_handleChangeVisibleFields}
        leftItems={getFieldOptions(customFields, 'left', filterVisibleOption)}
        rightItems={getFieldOptions(customFields, 'right', filterVisibleOption)}
        selected={visibleFields}
        handleHideAll={_handleHideAll}
        handleShowAll={_handleShowAll}
        renderLabel={i => getSystemFieldName(i)}
      />
      <ShareView
        pageId={pageIdAndURL.id}
        isShareViewMenuOpen={isShareViewMenuOpen}
        closeShareViewMenuPopover={_closeShareViewMenuPopover}
      />
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ includesAutoTag, showAddButton, hasMoreOptions }) =>
    `${includesAutoTag ? '150px' : ''} ${showAddButton ? '30px' : ''} 140px ${hasMoreOptions ? '30px' : ''} 1fr`};
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
`;

const IconButton = styled(DragonIconButton)``;
