import React, { Component } from 'react';
import styled from 'styled-components';
import withStyles from '@material-ui/core/styles/withStyles';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';

import { brandColor } from 'design-system/themes/default';
import { getRandom5DigitNum } from 'utils';

class DropdownFilter extends Component {
  onChange = e => {
    const key = e.target.value;
    const opt = key !== undefined && key !== null && key !== '' ? this.props.options.find(o => o.key === key) : '';

    this.props.onChange(opt);
  };

  render() {
    const { className, title, allTitle, allEnd, options, inline, hideAll, selectedValue, name, disabled, style } = this.props;
    const menuId = `simple-menu-${getRandom5DigitNum()}`;

    return (
      <FormControl
        className={className}
        margin={title ? 'dense' : 'normal'}
        style={
          style || {
            margin: '0px 8px',
            minWidth: '100px',
            maxWidth: '200px',
            textAlign: 'left',
            ...(inline ? { display: 'inline-block' } : {}),
          }
        }
      >
        {title && (
          <InputLabel
            shrink
            htmlFor={menuId}
            style={{ whiteSpace: 'nowrap', width: '150%', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {title}
          </InputLabel>
        )}
        <Select
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            style: { width: '90%' },
          }}
          value={selectedValue ? selectedValue.key : ''}
          onChange={this.onChange}
          input={<Input id={menuId} margin={title ? 'dense' : 'none'} />}
          classes={title ? { selectMenu: this.props.classes.denseSelect } : {}}
          style={selectedValue && !disabled ? { color: brandColor, maxWidth: '100%' } : {}}
          name={name}
          disabled={disabled}
          displayEmpty
          IconComponent={StyledArrowDownIcon}
        >
          {!hideAll && !allEnd && (
            <StyledMenuItemDropdown value="">
              <span style={{ color: 'rgba(172, 172, 172, 1)' }}>{allTitle || 'All'}</span>
            </StyledMenuItemDropdown>
          )}
          {options
            .filter(option => !!option && !option.hidden)
            .map(option => (
              <StyledMenuItemDropdown key={option.key} disabled={option.disabled} value={option.key}>
                {option.title}
              </StyledMenuItemDropdown>
            ))}
          {!hideAll && allEnd && <StyledMenuItemDropdown value="">{allTitle || 'All'}</StyledMenuItemDropdown>}
        </Select>
      </FormControl>
    );
  }
}

const StyledArrowDownIcon = styled(KeyboardArrowDownIcon)`
  cursor: pointer;

  && {
    fill: ${({ theme }) => theme.palette.newLayout.text.siroccoGrey};
    font-size: 20px;
  }
`;

const styles = theme => ({
  denseSelect: {
    fontSize: '14px',
    paddingTop: 0,
    paddingBottom: 0,
  },
});

export default withStyles(styles, { name: 'DropdownFilter' })(DropdownFilter);
