import axios from 'axios';
import { pluck } from 'ramda';

import { createThunk } from 'utils/store/thunk';

import { GENERATE_AUTO_TAGS, SAVE_AUTO_TAGS, SET_AUTO_TAGS_AS_TAGS } from './types';

const generateAutoTags = (customerRequests, similarityThreshold, canInferNewTags = false) => {
  return createThunk(GENERATE_AUTO_TAGS, async () => {
    const { data } = await axios.post('/api/customer-requests/auto-tags/generate', {
      customerRequestIds: pluck('id', customerRequests),
      canInferNewTags,
      similarityThreshold,
    });

    return data;
  });
};

const saveAutoTags = generatedAutoTags => {
  return createThunk(SAVE_AUTO_TAGS, async () => {
    const requestBody = generatedAutoTags.reduce((carry, { customerRequestId, tags }) => {
      return [...carry, ...tags.map(tag => ({ customerRequestId, tagId: tag.id }))];
    }, []);

    await axios.put('/api/customer-requests/auto-tags', requestBody);

    return generatedAutoTags;
  });
};

const setAutoTagsAsTags = generatedAutoTags => {
  return createThunk(SET_AUTO_TAGS_AS_TAGS, async () => {
    const saveTagsRequestBody = generatedAutoTags.reduce((carry, { customerRequestId, tags }) => {
      return [...carry, ...tags.map(tag => ({ customerRequestId, tagId: tag.id }))];
    }, []);

    // Save the auto tags
    await axios.put('/api/customer-requests/auto-tags', saveTagsRequestBody);

    const affectedRequestIds = generatedAutoTags.map(autoTagPayload => autoTagPayload.customerRequestId);

    await axios.put('/api/customer-requests/auto-tags/tags', { customerRequestIds: affectedRequestIds });

    return generatedAutoTags;
  });
};

export { generateAutoTags, saveAutoTags, setAutoTagsAsTags };
