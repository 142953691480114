import { defaultTo, pipe, prop, isNil, not, propEq, head } from 'ramda';

import { caculateTotalCost, getUserName, getUpdatedByDisplayName } from 'utils';
import { INTEGRATIONS_KEYS } from 'constants/integrations';
import getValidFormula from 'store/customFields/helpers/getValidFormula';
import { getRallyObjectUrl } from 'utils/integrations/getRallyObjectUrl';
import formatPercent from 'design-system/utils/formatPercent';

import { IDEA_LAYER } from '../constants';
import parseStories from './parseStories';
import deserializeProject from './deserializeProject';
import getProjectStatusColor from './getProjectStatusColor';
import { getProjectProgress } from './getProjectProgress';
import { getProjectAllocationValues } from './getProjectAllocationValues';
import getMetricsForEnrichedEntity from 'store/utils/getMetricsForEnrichedEntity';

import getEstimatesMinAndMaxDates from './getEstimatesMinAndMaxDates';
import getStatusColorInHex from './getStatusColorInHex';

import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';
import { parseEntityWithSanitizedKeys } from 'utils/customFields';
import { getProjectPointsValues } from 'store/projects/helpers/getProjectPointsValues';
import getProjectPredictedEndDate from './getProjectPredictedEndDate';

const defaultToEmptyArray = defaultTo([]);
const defaultToEmptyString = defaultTo('');

const isNotNil = pipe(isNil, not);
const dataIsDefined = pipe(prop('data'), isNil, not);

const _getTitleFromObject = obj => {
  if (!obj) return '';
  return obj.title;
};

/**
 * Parse project integration to global format
 *
 * @function _parseProjectIntegration
 * @param  {Object} integration
 * @param  {Array} orgIntegrations
 * @return {Object}
 */
export const _parseProjectIntegration = (integration, orgIntegrations) => {
  const orgIntegration = defaultTo(
    {},
    orgIntegrations.find(i => i.id === integration.org_integration_id),
  );
  let integrationKey;
  let integrationUrl;
  const isClubehouseIntegration = propEq('integrationType', INTEGRATIONS_KEYS.clubhouse);
  const isGithubIntegration = propEq('integrationType', INTEGRATIONS_KEYS.github);
  const isAzureDevopsIntegration = propEq('integrationType', INTEGRATIONS_KEYS.azuredevops);
  const isAsanaIntegration = propEq('integrationType', INTEGRATIONS_KEYS.asana);
  const isRallyIntegration = propEq('integrationType', INTEGRATIONS_KEYS.rally);

  if (isClubehouseIntegration(orgIntegration) && dataIsDefined(integration)) {
    integrationKey = `${orgIntegration.data.workspace}-${integration.data.epic_id}`;
    integrationUrl = `https://app.clubhouse.io/${orgIntegration.data.workspace}/epic/${integration.data.epic_id}`;
  } else if (isGithubIntegration(orgIntegration) && dataIsDefined(integration)) {
    const githubMilestoneTitle =
      integration?.data?.epic_id && integration?.data?.repository
        ? `${integration.data.repository}-${integration.data.epic_id}`
        : null;
    const githubMilestoneURL = integration?.data?.githubData?.html_url;
    const githubProject = integration.data && integration.data.projects.length > 0 && integration.data.projects[0];

    integrationKey = githubMilestoneTitle || githubProject.name;
    integrationUrl = githubMilestoneTitle ? githubMilestoneURL : githubProject.html_url;
  } else if (isAzureDevopsIntegration(orgIntegration) && dataIsDefined(integration)) {
    integrationKey = `${
      integration.data.fields['System.TeamProject'] ? `${integration.data.fields['System.TeamProject']}/` : ''
    }${integration.data.id}`;
    integrationUrl = integration.data._links?.html?.href;
  } else if (isAsanaIntegration(orgIntegration) && dataIsDefined(integration)) {
    integrationKey = integration.data.gid;
    integrationUrl = integration.data.permalink_url;
  } else if (isRallyIntegration(orgIntegration) && dataIsDefined(integration)) {
    const rallyProject = getRallyObjectUrl(orgIntegration.data, integration);

    integrationKey = rallyProject.key;
    integrationUrl = rallyProject.link;
  }

  return {
    key: integrationKey,
    url: integrationUrl,
    type: orgIntegration?.integrationType,
    orgIntegrationId: orgIntegration?.id,
    _integration: integration,
  };
};

/**
 * Parse integration data to global format
 *
 * Similar to _parseProjectIntegration but for Jira.
 *
 * @function parseJiraIntegrationData
 * @param  {Object} integration
 * @param  {Array} orgIntegrations
 * @return {Object}
 */
export const parseJiraIntegrationData = data => {
  return {
    key: data.key,
    url: data.ticket_url,
    issueType: data.issue_type,
    type: INTEGRATIONS_KEYS.JIRA,
  };
};

/**
 * Given a list of selected Ids return a new list with the respective metadata object
 * @param selected
 * @param metadataCollection
 * @return {*}
 */
const parseMetadataMultiSelected = (selected = [], metadataCollection = {}) => {
  return (selected || []).reduce((all, w) => {
    const item = metadataCollection[w.id];

    if (item) {
      return [...all, item];
    }

    return all;
  }, []);
};

/**
 * Given a list of selected Ids return a new list with the respective metadata object based on a non normalized collection
 * @param selected
 * @param metadataCollection
 * @return {unknown[]}
 */
const parseUnnormalizedMetadataMultiSelected = (selected = [], metadataCollection = []) => {
  return (selected || []).map(selected => {
    const item = metadataCollection.find(({ id }) => id === selected.id);

    return item ?? selected;
  });
};

const parseFormulaCustomFields = (project, customFields = []) => {
  // eslint-disable-next-line no-unused-vars
  const entityWithSanitizedKeys = parseEntityWithSanitizedKeys(project, customFields);

  return customFields.reduce((projectCustomFields, customField) => {
    if (!customField) {
      return projectCustomFields;
    }

    const { field_type: fieldType } = customField;

    if (fieldType !== TYPES_OF_CUSTOM_FIELDS.FORMULA) {
      return projectCustomFields;
    }

    let calculatedValue = null;

    try {
      calculatedValue = +parseFloat(
        // eslint-disable-next-line no-eval
        eval(getValidFormula(customField.description, 'entityWithSanitizedKeys', customFields, 'projects')),
      ).toFixed(2);
    } catch (err) {
      if (window.Rollbar) {
        window.Rollbar.warn('Error parsing formula custom field', err);
      }
    }

    return {
      ...projectCustomFields,
      [customField.key]: [Infinity, NaN].includes(calculatedValue) ? '' : calculatedValue,
    };
  }, {});
};

/**
 * Given the custom_fields object on a project, parses the custom field values saved,
 * and adds appropriate default value based on custom field field_type
 * @param {Object} project
 * @param {Array} customFields
 * @returns {Object}
 */
const parseCustomFields = (project, customFields = []) => {
  return customFields.reduce((projectCustomFields, customField) => {
    const { field_type: fieldType, key: customFieldKey } = customField;

    let projectValue;

    switch (fieldType) {
      case TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN:
        projectValue = defaultToEmptyArray(project?.custom_fields?.[customFieldKey]);
        return { ...projectCustomFields, [customFieldKey]: projectValue };
      case TYPES_OF_CUSTOM_FIELDS.DATE:
      case TYPES_OF_CUSTOM_FIELDS.DROPDOWN:
      case TYPES_OF_CUSTOM_FIELDS.NUMBER:
      case TYPES_OF_CUSTOM_FIELDS.SHORT_TEXT:
      case TYPES_OF_CUSTOM_FIELDS.RICH_TEXT:
      case TYPES_OF_CUSTOM_FIELDS.YES_NO:
        projectValue = defaultToEmptyString(project?.custom_fields?.[customFieldKey]);
        return { ...projectCustomFields, [customFieldKey]: projectValue };
      default:
        return projectCustomFields;
    }
  }, {});
};

export const getEnrichedProject = (project, metadata, childrenByParentId) => {
  const {
    orgIntegrations,
    roadmapsCorp,
    roadmaps,
    products,
    productsLevel2,
    objectivesCorp,
    objectives,
    keyResults,
    keyResultsLevel2,
    categories,
    priorities,
    phases,
    themes,
    timeframesCorp,
    timeframes,
    users,
    teams,
    teams2,
    votes,
    customFields,
    stories,
    customers,
    tags,
    metrics,
  } = metadata;

  let p = { ...project };

  const roadmapCorp = p.roadmap_corp_id && roadmapsCorp[p.roadmap_corp_id];
  const roadmap = p.roadmap_id && roadmaps[p.roadmap_id];
  const product1 = p.product_1_id && products[p.product_1_id];
  const product2 = p.product_2_id && productsLevel2[p.product_2_id];
  const objectiveCorp = p.objective_corp_id && objectivesCorp[p.objective_corp_id];
  const objective = p.objective_id && objectives[p.objective_id];
  const keyResult1 = p.key_result_1_id && keyResults[p.key_result_1_id];
  const keyResult2 = p.key_result_2_id && keyResultsLevel2[p.key_result_2_id];
  const category = p.category_id && categories[p.category_id];
  const priority = p.priority_id && priorities[p.priority_id];
  const phase = p.phase_id && phases[p.phase_id];
  const theme = p.theme_id && themes[p.theme_id];
  const timeframeCorp = p.timeframe_corp_id && timeframesCorp[p.timeframe_corp_id];
  const timeframe = p.timeframe_id && timeframes[p.timeframe_id];
  const timeframe2 = p.timeframe_2_id && timeframes[p.timeframe_2_id];
  const owner = p.owner_id && users[p.owner_id];
  const createdBy = p.createdBy || (p.created_by_id && users[p.created_by_id]);
  const updatedBy = p.updatedBy || (p.updated_by_id && users[p.updated_by_id]);
  const projectCustomers = (p.customers || []).map(c => customers[c.id]).filter(isNotNil);
  const projectTags = (p.tags || []).map(t => tags[t.id]).filter(isNotNil);
  const projectMetrics = getMetricsForEnrichedEntity(metrics, p);
  const projectPersonas = defaultToEmptyArray(p.personas);
  const projectLifecycles = defaultToEmptyArray(p.lifecycles);

  // Custom User Fields
  const user1 = p.user1 || (p.user_1_id && users[p.user_1_id]);
  const user2 = p.user2 || (p.user_2_id && users[p.user_2_id]);
  const user3 = p.user3 || (p.user_3_id && users[p.user_3_id]);
  const user4 = p.user4 || (p.user_4_id && users[p.user_4_id]);
  const user5 = p.user5 || (p.user_5_id && users[p.user_5_id]);
  const user6 = p.user6 || (p.user_6_id && users[p.user_6_id]);
  const user7 = p.user7 || (p.user_7_id && users[p.user_7_id]);

  if (p.Jiras && p.Jiras.length) {
    [p.jira] = p.Jiras;
  } else {
    p.jira = undefined;
  }

  const { progress, manualProgress } = getProjectProgress(p);

  p.predictedEndDate = getProjectPredictedEndDate(p);

  p.status_color = getProjectStatusColor(p);
  p.statusColorHex = getStatusColorInHex(p);

  if (p.custom_fields) {
    const nonFormulaCustomFieldValues = parseCustomFields(p, customFields);

    p = { ...p, ...nonFormulaCustomFieldValues };

    // Formula custom fields may include other custom fields, so they must be processed after all the others
    const formulaCustomFieldValues = parseFormulaCustomFields(p, customFields);

    p = { ...p, ...formulaCustomFieldValues };
  }

  if (p.integrations && p.integrations.length) {
    // parse project integrations to a format that is iqual for all integrations
    p.parsedIntegrations = p.integrations.map(i => _parseProjectIntegration(i, orgIntegrations));

    // Should maintain the first project integration as property to not break other integrations
    p.integration = head(p.parsedIntegrations);
  } else if (p.jira) {
    p.integration = parseJiraIntegrationData(p.jira);
  }

  const totalCost = caculateTotalCost(p.estimates, teams);

  const projectVotes = votes[p.id] || { count: 0, '5_users_ids': [], had_vote: false };

  p.children = [];

  if (childrenByParentId && p.layer !== IDEA_LAYER) {
    p.children = childrenByParentId[p.id] || [];
  }

  const watchers = parseMetadataMultiSelected(p.watchers, users);

  const estimates = defaultToEmptyArray(p.estimates);

  const estimatesTimeline = getEstimatesMinAndMaxDates(estimates);

  p.planningStage = phase ? phase.planning_stage : 'Backlog';

  const { plannedAllocation, reportedAllocation, completedAllocation, scopeVariance } = getProjectAllocationValues(p);
  const { plannedPoints, pointsVariance, completedPoints, reportedPoints } = getProjectPointsValues(p);

  const additionalRoadmaps = parseMetadataMultiSelected(p.additionalRoadmaps, roadmaps);
  const additionalProducts = parseMetadataMultiSelected(p.additionalProducts, products);
  const additionalProducts2 = parseMetadataMultiSelected(p.additionalProducts2, productsLevel2);
  const additionalObjectives = parseMetadataMultiSelected(p.additionalObjectives, objectives);
  const additionalKeyResults = parseMetadataMultiSelected(p.additionalKeyResults, keyResults);
  const additionalKeyResults2 = parseMetadataMultiSelected(p.additionalKeyResults2, keyResultsLevel2);
  const additionalTimeframes = parseMetadataMultiSelected(p.additionalTimeframes, timeframes);
  const additionalTimeframes2 = parseMetadataMultiSelected(p.additionalTimeframes2, timeframes);
  const additionalThemes = parseMetadataMultiSelected(p.additionalThemes, themes);
  const additionalCategories = parseMetadataMultiSelected(p.additionalCategories, categories);
  const additionalTeams = parseUnnormalizedMetadataMultiSelected(p.additionalTeams, teams);
  const additionalTeams2 = parseUnnormalizedMetadataMultiSelected(p.additionalTeams2, teams2);
  const resourceTeam =
    p.resource_team_id && (teams.find(t => t.id === p.resource_team_id) ?? teams2.find(t => t.id === p.resource_team_id));

  return deserializeProject({
    ...p,
    roadmapCorp: roadmapCorp || null,
    roadmapCorpTitle: _getTitleFromObject(roadmapCorp),
    roadmap: roadmap || null,
    roadmapTitle: _getTitleFromObject(roadmap),
    product1: product1 || null,
    product1Title: _getTitleFromObject(product1),
    product2: product2 || null,
    product2Title: _getTitleFromObject(product2),
    objectiveCorp: objectiveCorp || null,
    objectiveCorpTitle: _getTitleFromObject(objectiveCorp),
    objective: objective || null,
    objectiveTitle: _getTitleFromObject(objective),
    keyResult1: keyResult1 || null,
    keyResult1Title: _getTitleFromObject(keyResult1),
    keyResult2: keyResult2 || null,
    keyResult2Title: _getTitleFromObject(keyResult2),
    category: category || null,
    categoryTitle: _getTitleFromObject(category),
    priority: priority || null,
    priorityTitle: _getTitleFromObject(priority),
    phase: phase || null,
    phaseTitle: _getTitleFromObject(phase),
    theme: theme || null,
    themeTitle: _getTitleFromObject(theme),
    timeframeCorp: timeframeCorp || null,
    timeframeCorpTitle: _getTitleFromObject(timeframeCorp),
    timeframe: timeframe || null,
    timeframeTitle: _getTitleFromObject(timeframe),
    timeframe2: timeframe2 || null,
    timeframe2Title: _getTitleFromObject(timeframe2),
    owner: owner || null,
    ownerName: owner ? getUserName(owner) : '',
    createdBy: createdBy || null,
    createdByName: createdBy ? getUserName(createdBy) : '',
    updatedBy: updatedBy || null,
    updatedByName: getUpdatedByDisplayName(updatedBy),
    manualProgress,
    progress,
    totalCost,
    displayProgress: formatPercent(progress),
    stories: !p.stories ? parseStories(stories[p.id], orgIntegrations) : p.stories,
    votes: projectVotes,
    watchers,
    customers: projectCustomers,
    tags: projectTags,
    metrics: projectMetrics,
    estimates,
    estimatesTimeline,
    plannedAllocation,
    reportedAllocation,
    completedAllocation,
    scopeVariance,
    // Custom User Fields
    user1Name: user1 ? getUserName(user1) : '',
    user2Name: user2 ? getUserName(user2) : '',
    user3Name: user3 ? getUserName(user3) : '',
    user4Name: user4 ? getUserName(user4) : '',
    user5Name: user5 ? getUserName(user5) : '',
    user6Name: user6 ? getUserName(user6) : '',
    user7Name: user7 ? getUserName(user7) : '',
    // Additional metadata
    additionalRoadmaps,
    additionalProducts,
    additionalProducts2,
    additionalObjectives,
    additionalKeyResults,
    additionalKeyResults2,
    additionalTimeframes,
    additionalTimeframes2,
    additionalThemes,
    additionalCategories,
    additionalTeams,
    additionalTeams2,
    personas: projectPersonas,
    lifecycles: projectLifecycles,
    plannedPoints,
    reportedPoints,
    pointsVariance,
    completedPoints,
    resourceTeam,
    resourceTeamTitle: resourceTeam?.title,
  });
};
