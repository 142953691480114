import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGridCustomerRequest, getMetadata } from 'store/customerRequests/selectors';
import sortByRank from 'utils/sortByRank';

import { fetchRequestsMergedOnRequest } from '../store';
import { selectRequestsMerged, selectRequestsMergedIsError, selectRequestsMergedIsLoading } from '../store/selectors';

/**
 * Custom hook for fetching merged requests based on the provided ID.
 *
 * @param {Object} options - The options object.
 * @param {string} options.id - The ID of the request.
 * @returns {Object} - The merged requests, loading state, and error state.
 */
const useRequestsMerged = ({ id }) => {
  const dispatch = useDispatch();
  const requests = useSelector(selectRequestsMerged);
  const isFetching = useSelector(selectRequestsMergedIsLoading);
  const error = useSelector(selectRequestsMergedIsError);

  const metadata = useSelector(getMetadata);

  const sortedCustomerRequests = useMemo(
    () => requests.map(req => getGridCustomerRequest(metadata, req)).sort(sortByRank),
    [requests, metadata],
  );

  useEffect(() => {
    dispatch(fetchRequestsMergedOnRequest(id));
  }, [id]);

  return { requests: sortedCustomerRequests, isFetching, error };
};

export default useRequestsMerged;
