import { IDEA_LAYER, INITIATIVE_LAYER } from '../store/projects/constants';

/**
 * @function makeProjectForParent returns a function to find parent by id
 * @param  {Array} initiatives       List of selected initiatives
 * @param  {Array} bets           List of selected bets
 * @return {Function} Function to find parent by id
 */
const makeGetParentProject = (initiatives, bets) => {
  const getParentProjectById = (parentId, currentLayer = IDEA_LAYER) => {
    let collection = [];

    switch (currentLayer) {
      case IDEA_LAYER:
        collection = [...initiatives, ...bets];
        break;
      case INITIATIVE_LAYER:
        collection = [...bets];
        break;
      default:
        collection = [];
        break;
    }

    const result = collection.length
      ? collection.filter(p => p.planningStage !== 'Archived').sort((a, b) => (a > b ? 1 : -1))
      : collection;

    return result.find(item => item.id === parentId);
  };

  return getParentProjectById;
};

export default makeGetParentProject;
