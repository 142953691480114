import { fromJS } from 'immutable';
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';

import {
  TIMEFRAME_STATE_CHANGE_ACTION,
  FETCH_TIMEFRAMES_FULFILLED,
  UPDATE_TIMEFRAME_FULFILLED,
  CREATE_TIMEFRAME_FULFILLED,
  DELETE_TIMEFRAMES_FULFILLED,
  ADD_SELECTED_GRID_TIMEFRAME,
  REMOVE_SELECTED_GRID_TIMEFRAME,
  MERGE_TIMEFRAMES_FULFILLED,
  UPDATE_TIMEFRAME_BY_ID_FULFILLED,
  DELETE_TIMEFRAME_BY_ID_FULFILLED,
  REMOVE_UNSAVED_TIMEFRAME,
  ADD_TIMEFRAME_WITHOUT_SAVE,
  FETCH_TIMEFRAMES_PENDING,
  CREATE_TIMEFRAMES_FULFILLED,
  UPDATE_TIMEFRAMES_FULFILLED,
  UNDO_CREATE_TIMEFRAMES_FULFILLED,
  UNDO_UPDATE_TIMEFRAMES_FULFILLED,
  BULK_DELETE_TIMEFRAMES_FULFILLED,
  UNDO_BULK_DELETE_TIMEFRAMES_FULFILLED,
  UPDATE_TIMEFRAME_ROW_ORDER_FULFILLED,
  UPDATE_TIMEFRAME_ROW_ORDER_PENDING,
  UPDATE_TIMEFRAME_ROW_ORDER_REJECTED,
  BULK_DELETE_TIMEFRAME_ROADMAP_FULFILLED,
  CREATE_TIMEFRAME_ROADMAP_FULFILLED,
  DELETE_TIMEFRAME_ROADMAP_FULFILLED,
} from './types';

import addRows from 'store/utils/addRows';
import updateRows from 'store/utils/updateRows';
import upsertListItem from 'store/utils/upsertListItem';
import sortByRank from 'utils/sortByRank';
import getNewMetadataLevel from '../utils/getNewMetadataLevel';

const initialState = fromJS({
  rows: [],
  isLoaded: false,
  selectedGridTimeframes: [],
});

export default function timeframesReducer(state = initialState, action) {
  switch (action.type) {
    case TIMEFRAME_STATE_CHANGE_ACTION:
      return state.set(action.partialStateName, action.partialStateValue);
    case FETCH_TIMEFRAMES_PENDING:
      return state.merge({ isLoaded: false });
    case FETCH_TIMEFRAMES_FULFILLED:
      return state.merge({
        rows: fromJS(action.payload.data),
        isLoaded: true,
        lastCallsDate: moment().valueOf(),
      });
    case UPDATE_TIMEFRAME_BY_ID_FULFILLED:
    case UPDATE_TIMEFRAME_FULFILLED: {
      const rows = state
        .get('rows')
        .toJS()
        .map(row => (row.id === action.payload.id ? action.payload : row));

      return state.merge({ rows: fromJS(rows), isLoaded: true });
    }
    case CREATE_TIMEFRAME_FULFILLED: {
      let rows = state
        .get('rows')
        .toJS()
        .filter(row => row.id);

      rows = upsertListItem(action.payload, rows);

      const parent = rows.find(r => r.id === action.payload.parent_id);

      if (parent) {
        const children = (parent.children || []).filter(child => child.id && child.id !== action.payload.id);

        children.push(action.payload);
        parent.children = children;

        rows = upsertListItem(parent, rows);
      }

      return state.merge({ rows: fromJS(rows), isLoaded: true });
    }
    case DELETE_TIMEFRAMES_FULFILLED: {
      const ids = !Array.isArray(action.payload) ? [action.payload] : action.payload;
      const rows = state
        .get('rows')
        .toJS()
        .filter(row => ids && !ids.includes(row.id));

      return state.set('rows', fromJS(rows)).set('isLoaded', true);
    }
    case ADD_SELECTED_GRID_TIMEFRAME:
      if (!action.timeframe || !action.timeframe.id) {
        console.warn('ADD_SELECTED_GRID_TIMEFRAME::a timeframe must be passed to this action');
        return state;
      }

      if (!state.has('selectedGridTimeframes')) {
        return state.merge({
          selectedGridTimeframes: fromJS([action.timeframe]),
        });
      } else if (state.get('selectedGridTimeframes').find(timeframe => timeframe.get('id') === action.timeframe.id)) {
        return state;
      }
      return state.merge({
        selectedGridTimeframes: state.get('selectedGridTimeframes').push(fromJS(action.timeframe)),
      });
    case REMOVE_SELECTED_GRID_TIMEFRAME:
      if (!action.timeframeId) {
        console.warn('REMOVE_SELECTED_GRID_TIMEFRAME::a timeframeId must be passed to this action');
        return state;
      }

      if (!state.has('selectedGridTimeframes')) {
        return state;
      }

      return state.merge({
        selectedGridTimeframes: state
          .get('selectedGridTimeframes')
          .filter(timeframe => timeframe.get('id') !== action.timeframeId),
      });
    case UPDATE_TIMEFRAME_ROW_ORDER_REJECTED: {
      if (!action.meta || !action.meta.prev) return state;

      let timeframes = state.get('rows') ? state.get('rows').toJS() : [];

      timeframes = upsertListItem(action.meta.prev, timeframes);

      return state.set('rows', fromJS(timeframes.sort(sortByRank)));
    }
    case UPDATE_TIMEFRAME_ROW_ORDER_PENDING:
    case UPDATE_TIMEFRAME_ROW_ORDER_FULFILLED:
      if (!action.payload || !action.payload.id) {
        console.error('UPDATE_TIMEFRAME_ROW_ORDER_FULFILLED::Timeframe passed does not have id');
        return state;
      }

      let timeframes = state.get('rows') ? state.get('rows').toJS() : [];

      timeframes = upsertListItem(action.payload, timeframes);

      return state.set('rows', fromJS(timeframes.sort(sortByRank)));
    case MERGE_TIMEFRAMES_FULFILLED:
      if (!action.payload) {
        return state;
      }

      const {
        payload: removedTimeframes,
        meta: { targetTimeframeId },
      } = action;

      const currentRows = state.get('rows').toJS();

      const updatedRows = currentRows
        .filter(timeframe => !action.payload.includes(timeframe?.id))
        .map(timeframe =>
          removedTimeframes.includes(timeframe.parent_id) ? { ...timeframe, parent_id: +targetTimeframeId } : timeframe,
        );

      return state.update('rows', () => fromJS(updatedRows));
    case DELETE_TIMEFRAME_BY_ID_FULFILLED:
      if (!action.payload) {
        console.trace('A payload must be passed');
        return state;
      }

      return state.update('rows', list => list.filter(item => item.get('id') !== action.payload));
    case REMOVE_UNSAVED_TIMEFRAME:
      return state.update('rows', list => list.filter(item => item.get('id')));
    case ADD_TIMEFRAME_WITHOUT_SAVE:
      /**
       * timeframeLevel
       *
       * When a timeframe is provied in the action payload it means that a child is being added.
       * For this type of use cases the level of the new timeframe should be increased by one.
       * When a timeframe is being added to the root action.timeframe is undefined.
       */
      const timeframeLevel = getNewMetadataLevel(action?.timeframe);
      const newItem = action.timeframe ? fromJS({ parent_id: action.timeframe.id, level: `${timeframeLevel}` }) : fromJS({});

      return state.update('rows', list => list.filter(item => item.get('id')).unshift(newItem));
    case CREATE_TIMEFRAMES_FULFILLED: {
      const rows = addRows(state.get('rows'), action.payload);

      return state.set('rows', rows).set('lastActionIds', fromJS(action.payload.map(obj => obj.id)));
    }
    case UPDATE_TIMEFRAMES_FULFILLED: {
      const rows = updateRows(state.get('rows'), action.payload);

      return state.set('rows', rows).set('lastActionIds', fromJS(action.payload.map(obj => obj.id)));
    }
    case BULK_DELETE_TIMEFRAMES_FULFILLED: {
      const rows = state
        .get('rows')
        .toJS()
        .filter(r => !action.payload.includes(String(r.id)));

      return state.set('rows', fromJS(rows)).set('lastActionIds', fromJS(action.payload));
    }
    case UNDO_UPDATE_TIMEFRAMES_FULFILLED: {
      const rows = updateRows(state.get('rows'), action.payload);

      return state.set('rows', rows).set('lastActionIds', null);
    }
    case UNDO_CREATE_TIMEFRAMES_FULFILLED: {
      return state
        .set(
          'rows',
          fromJS(
            state
              .get('rows')
              .toJS()
              .filter(c => state.get('lastActionIds').indexOf(c.id) === -1),
          ),
        )
        .set('lastActionIds', null);
    }
    case UNDO_BULK_DELETE_TIMEFRAMES_FULFILLED: {
      const rows = updateRows(state.get('rows'), action.payload);

      return state.set('rows', rows).set('lastActionIds', null);
    }
    case CREATE_TIMEFRAME_ROADMAP_FULFILLED:
      const tfs = cloneDeep(state.get('rows').toJS() || []);
      const timeframe = tfs.find(obj => obj.id === action.payload.timeframe_id);

      timeframe.timeframe_roadmaps = [...(timeframe.timeframe_roadmaps || []), action.payload];
      timeframe.updated_at = action.payload.updated_at;

      const updatedTimeframes = upsertListItem(timeframe, tfs);

      return state.set('rows', fromJS(updatedTimeframes));
    case DELETE_TIMEFRAME_ROADMAP_FULFILLED:
      const rows = cloneDeep(state.get('rows').toJS() || []);

      const { id: timeframeId, timeframeRoadmaps } = action.payload;

      const parent = rows.find(obj => obj.id === parseInt(timeframeId));

      parent.timeframe_roadmaps = timeframeRoadmaps;

      const updated = upsertListItem(parent, rows);

      return state.set('rows', fromJS(updated));
    case BULK_DELETE_TIMEFRAME_ROADMAP_FULFILLED:
      const timeframeRows = cloneDeep(state.get('rows').toJS() || []);
      const updatedTfs = timeframeRows.map(timeframe => {
        if (timeframe.id === action.payload) {
          return {
            ...timeframe,
            timeframe_roadmaps: [],
          };
        }

        return timeframe;
      });

      return state.set('rows', fromJS(updatedTfs));
    default:
      return state;
  }
}
