import { useDispatch, useSelector } from 'react-redux';

import { fetchCustomerRequestsForCluster, getClustersDataForAnalyseChart, getCustomerRequestsForCluster } from '../store';

const useLoadCustomersRequestsForCluster = () => {
  const dispatch = useDispatch();

  const clusters = useSelector(getClustersDataForAnalyseChart);
  const customerRequests = useSelector(getCustomerRequestsForCluster);

  const fetchCustomerRequests = selectedClusterId => {
    const selectedCluster = clusters.find(cluster => cluster.id === selectedClusterId);

    dispatch(fetchCustomerRequestsForCluster(selectedCluster.uuid));
  };

  return {
    data: customerRequests || [],
    fetchCustomerRequests,
  };
};

export default useLoadCustomersRequestsForCluster;
