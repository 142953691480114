import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchTemplates, fetchTemplatesCategories } from 'store/templates';

const useFetchTemplatesAndCategories = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTemplates());
    dispatch(fetchTemplatesCategories());
  }, []);
};

export default useFetchTemplatesAndCategories;
