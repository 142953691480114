const receiveMessageFactory = (target, callback) => async event => {
  let obj;

  try {
    if (typeof event.data === 'string') obj = JSON.parse(event.data);
    else if (typeof event.data === 'object') obj = event.data;
    else return;
  } catch (err) {
    return;
  }

  if (obj.target === target) {
    callback(obj.data);
  }
};

export default receiveMessageFactory;
