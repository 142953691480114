import React, { useState, useEffect } from 'react';
import { removeGanttTolltip } from 'components/ganttCommon';

const componentHOC = Component => {
  return props => {
    const [open, setOpen] = useState(false);
    const openTrigger = () => setOpen(true);
    const closeDialog = () => setOpen(false);

    useEffect(() => props.onOpen(openTrigger), []);

    return (
      <Component
        {...props}
        open={!!open}
        closeDialog={() => {
          removeGanttTolltip();
          closeDialog();

          if (props.onClose) props.onClose();
        }}
      />
    );
  };
};

export default componentHOC;
