import React from 'react';
import styled, { css } from 'styled-components';

import FluidTimePeriodField from 'design-system/organisms/FluidTimePeriodField';
import { spacing } from 'design-system/theme';
import formatDate from 'utils/dates/formatDate';

const getStyles = (fontSize, textAlign = 'right') => ({
  textField: css`
    input {
      text-align: ${textAlign};
      padding-right: ${spacing()}px;
      font-size: ${fontSize}px;

      &:hover {
        cursor: pointer;
      }
    }
  `,
  gridContainer: css`
    flex-wrap: nowrap;
  `,
  inlineDatePicker: css`
    > div {
      margin-top: 0;
    }

    label {
      display: none;
    }

    input {
      font-size: ${fontSize}px;
    }

    button {
      opacity: 1;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  `,
});

const defaultWrapperCss = css`
  width: 425px;
`;

const InlineDateRange = ({
  startDate,
  endDate,
  onChange,
  fontSize = 14,
  textAlign = 'right',
  useLocalTimezone = false,
  wrapperCss = defaultWrapperCss,
  includeFormat = true,
  label,
  ...props
}) => {
  const styles = getStyles(fontSize, textAlign);

  return (
    <Wrapper wrapperCss={wrapperCss} withLabel={!!label}>
      <FluidTimePeriodField
        {...props}
        key="inline-date-range"
        format="row"
        value={`${formatDate(startDate, useLocalTimezone)} - ${formatDate(endDate, useLocalTimezone)}`}
        startDate={startDate}
        endDate={endDate}
        onDateChange={onChange}
        customStyles={styles}
        label={label}
      />
    </Wrapper>
  );
};

export default InlineDateRange;

const Wrapper = styled.div`
  ${({ withLabel }) =>
    withLabel &&
    `
      width: auto;
      min-width: 300px;
      max-width: 415px;
  
      &&&& > div {
        padding-left: 0;
      }

      input:not([disabled]) {
        width: 82px;
      }
  `}
  ${({ wrapperCss }) => wrapperCss}
`;
