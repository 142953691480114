import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

const Tabs = props => {
  const { options, selected, onChange } = props;

  return (
    <Wrapper>
      {options.map(({ key, title }) => (
        <Item key={key} isSelected={selected === key}>
          <CustomButton color={selected === key && 'primary'} isSelected={selected === key} onClick={() => onChange(key)}>
            {title}
          </CustomButton>
        </Item>
      ))}
    </Wrapper>
  );
};

Tabs.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const Item = styled.div`
  position: relative;
  border-bottom: 1px solid #3333331a;
  &:after {
    content: '';
    background-color: ${props => props.theme.palette.primary.main};
    position: absolute;
    width: 100%;
    height: 2px;
    top: 100%;
    left: 0;
    display: ${props => (props.isSelected ? 'block' : 'none')};
  }
`;

const CustomButton = styled(Button)`
  &&&& {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
    padding: 6px 16px;
    color: ${props => !props.color && '#6b6b6b'};
  }
`;

export default Tabs;
