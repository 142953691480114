import React from 'react';

import useExploreOpenPendoGuide from './hooks/useExploreOpenPendoGuide';

const ExploreComponent = () => {
  useExploreOpenPendoGuide();

  return <div />;
};

export default ExploreComponent;
