import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTo, equals, isNil, not, pipe, prop } from 'ramda';

import history from 'store/utils/history';
import { openProjectLightbox } from 'store/projectLightbox';

import { getProjectKey } from 'utils/projects/getProjectKey';
import isProjectKey from 'utils/projects/isProjectKey';

import { OPEN_IDEA } from '../../../constants/queryParams';

const KEY = 'key';
const ID = 'id';

const hasValidKey = pipe(defaultTo({}), prop(KEY), isNil, not);
const hasValidId = pipe(defaultTo({}), prop(ID), isNil, not);

const notEquals = pipe(equals, not);

export default function (isOpen, selectedProject) {
  const dispatch = useDispatch();

  const errored = useSelector(state => state.projectLightbox.errored);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (errored) {
      urlParams.delete(OPEN_IDEA);
      history.replace({ search: urlParams.toString() });

      return;
    }

    const openIdeaParam = urlParams.get(OPEN_IDEA);

    if (!isOpen && !selectedProject && openIdeaParam) {
      if (isProjectKey(openIdeaParam)) {
        dispatch(openProjectLightbox(openIdeaParam));
      } else if (!Number.isNaN(Number(openIdeaParam))) {
        dispatch(openProjectLightbox(+openIdeaParam));
      }
    } else if (hasValidKey(selectedProject) || hasValidId(selectedProject)) {
      const projectKey = getProjectKey(selectedProject);
      const shouldSetOpenIdeaParam = isNil(openIdeaParam) || notEquals(openIdeaParam, String(projectKey));

      if (shouldSetOpenIdeaParam) {
        urlParams.set(OPEN_IDEA, projectKey);
        history.replace({ search: urlParams.toString() });
      }
    }
  }, [selectedProject?.id, selectedProject?.key]);
}
