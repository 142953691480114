import axios from 'axios';

import {
  MERGE_TIMEFRAMES,
  DELETE_TIMEFRAME_BY_ID,
  CREATE_TIMEFRAMES,
  UPDATE_TIMEFRAMES,
  UNDO_CREATE_TIMEFRAMES,
  UNDO_UPDATE_TIMEFRAMES,
  BULK_DELETE_TIMEFRAMES,
  UNDO_BULK_DELETE_TIMEFRAMES,
  UPDATE_TIMEFRAME_ROW_ORDER,
  UPDATE_TIMEFRAME_ROW_ORDER_REJECTED,
} from './types';

import { updateTimeframe } from './actions';

import throwRequestError from '../utils/throwRequestError';
import bulkCreateAction from 'store/utils/factory/bulkCreateAction';
import bulkUpdateAction from 'store/utils/factory/bulkUpdateAction';
import bulkDeleteAction from 'store/utils/factory/bulkDeleteAction';
import moveRowToPosition from 'utils/moveRowToPosition';

export function mergeTimeframes(timeframesIdsToMerge, timeframeId) {
  return dispatch => {
    const payload = axios
      .post(`/api/timeframes/merge/${timeframeId}`, {
        timeframesIdsToMerge,
      })
      .then(response => {
        return response.data;
      })
      .catch(throwRequestError);

    dispatch({
      type: MERGE_TIMEFRAMES,
      payload,
      meta: { targetTimeframeId: timeframeId },
    });

    return payload;
  };
}

export function switchTimeframesRowOrder(timeframeId1, timeframeId2, _, position) {
  return async (dispatch, getState) => {
    const state = getState().timeframes;
    const timeframes = state.get('rows') ? state.get('rows').toJS() : [];
    const selectedTimeframe = timeframes.find(({ id }) => id === +timeframeId1);
    const moveToCloseTimeframe = timeframes.find(({ id }) => id === +timeframeId2);
    const movedRow = moveRowToPosition(timeframes, timeframeId1, timeframeId2, position);

    const haveDifferentLevels = selectedTimeframe?.level !== moveToCloseTimeframe?.level;
    const movedToParentWithoutChildren = selectedTimeframe.parent_id && !moveToCloseTimeframe.parent_id;
    const movedToDifferentParent = selectedTimeframe.parent_id !== moveToCloseTimeframe.parent_id;

    if (selectedTimeframe.organization_id !== moveToCloseTimeframe.organization_id) {
      return dispatch({
        type: UPDATE_TIMEFRAME_ROW_ORDER_REJECTED,
        meta: { prev: selectedTimeframe },
      });
    }

    if (movedToParentWithoutChildren || haveDifferentLevels) {
      await dispatch(updateTimeframe({ id: selectedTimeframe.id, parent_id: moveToCloseTimeframe.id }));
    } else if (movedToDifferentParent) {
      await dispatch(updateTimeframe({ id: selectedTimeframe.id, parent_id: moveToCloseTimeframe.parent_id }));
    }

    return dispatch({
      type: UPDATE_TIMEFRAME_ROW_ORDER,
      payload: {
        promise: axios
          .put(`/api/timeframes/rowOrder/${timeframeId1}/${timeframeId2}`, { position })
          .then(response => response.data),
        data: movedRow,
      },
      meta: { prev: selectedTimeframe },
    });
  };
}

export const deleteTimeframeById = timeframeId => {
  return dispatch => {
    const payload = axios
      .delete(`/api/timeframes/${timeframeId}`)
      .then(res => res.data)
      .catch(throwRequestError);

    dispatch({
      payload,
      type: DELETE_TIMEFRAME_BY_ID,
    });

    return payload;
  };
};

export const createTimeframes = bulkCreateAction(CREATE_TIMEFRAMES, '/api/timeframes', {
  toastText: 'Timeframes have been created',
  ACTION_TYPE: UNDO_CREATE_TIMEFRAMES,
  endpoint: '/api/timeframes/versions/last',
  store: 'timeframes',
});
export const updateTimeframes = bulkUpdateAction(UPDATE_TIMEFRAMES, '/api/timeframes', {
  toastText: 'Timeframes have been changed',
  ACTION_TYPE: UNDO_UPDATE_TIMEFRAMES,
  endpoint: '/api/timeframes/versions/last',
  store: 'timeframes',
});
export const bulkDeleteTimeframes = bulkDeleteAction(BULK_DELETE_TIMEFRAMES, '/api/timeframes/', {
  toastText: 'Timeframes have been deleted',
  ACTION_TYPE: UNDO_BULK_DELETE_TIMEFRAMES,
  endpoint: '/api/timeframes/versions/last',
  store: 'timeframes',
});
