import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { getAllObjectives } from 'store/objectives/selectors';
import {
  createObjective,
  createObjectives,
  addObjectiveWithoutSave,
  updateObjectiveById,
  fetchObjectives,
  mergeObjectives,
  requestRemoveObjectiveById,
  bulkDeleteObjectives,
  switchObjectivesRowOrder,
  moveObjectiveToObjective,
} from 'store/objectives';
import usePortfolioOKRs from 'hooks/usePortfolioOKRs';

const useObjectives = (isGoalMode = false) => {
  const dispatch = useDispatch();

  const objectives = useSelector(state => getAllObjectives(state));
  const processedObjectives = usePortfolioOKRs(isGoalMode, objectives);

  const boundActionCreators = useMemo(() => {
    return bindActionCreators(
      {
        createObjective,
        createObjectives,
        addObjectiveWithoutSave,
        updateObjectiveById,
        fetchObjectives,
        mergeObjectives,
        requestRemoveObjectiveById,
        bulkDeleteObjectives,
        switchObjectivesRowOrder,
        moveObjectiveToObjective,
      },
      dispatch,
    );
  }, [dispatch]);

  return {
    objectives: processedObjectives,

    ...boundActionCreators,
  };
};

export default useObjectives;
