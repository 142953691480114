import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';

import theme from 'design-system/theme';

const DEAFULT_ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'left',
};

const DEFAULT_TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'left',
};

const AdvancedSearchPopover = ({ anchorEl, header, footer, children, onClose, onSubmit }) => {
  const _renderHeader = () => <HeaderContainer>{header}</HeaderContainer>;
  const _renderFooter = () => <FooterContainer>{footer}</FooterContainer>;
  const _renderBody = () => <SearchContainer>{children}</SearchContainer>;

  const handleFormSubmit = e => {
    e.preventDefault();

    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      style={{ zIndex: theme.zIndex.filtersPopover }}
      onClose={onClose}
      anchorOrigin={DEAFULT_ANCHOR_ORIGIN}
      transformOrigin={DEFAULT_TRANSFORM_ORIGIN}
    >
      <Wrapper onSubmit={handleFormSubmit}>
        {header && _renderHeader()}
        {_renderBody()}
        {footer && _renderFooter()}

        {/* Required submit input to trigger the form submit on click enter */}
        <input style={{ display: 'none' }} type="submit" value="Submit" />
      </Wrapper>
    </Popover>
  );
};

AdvancedSearchPopover.propTypes = {
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  header: PropTypes.object,
  footer: PropTypes.object,
  children: PropTypes.object,
};

const Wrapper = styled.form``;

const HeaderContainer = styled.div``;

const FooterContainer = styled.div``;

const SearchContainer = styled.div`
  width: 100%;
  min-width: 650px;
`;

export default AdvancedSearchPopover;
