/* eslint-disable */
/**
 * UnfilterIcon Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function unfilterIcon(props) {
  return (
    <SvgIcon {...props} style={{ width: props.width || 20, height: props.height || 20 }} viewBox="0 0 30 30">
      <path
        d="M18.3079426,17.1572631 L18.3079426,26.8410322 C18.3079426,27.1910327 18.1266923,27.5160821 17.8289868,27.7001156 C17.6667795,27.8003599 17.4826972,27.8509459 17.2980289,27.8509459 C17.143683,27.8509459 16.9889464,27.8156431 16.8464169,27.7443539 L12.2503646,25.4463522 C11.9082254,25.2752582 11.6921117,24.9255506 11.6921117,24.5430793 L11.6921117,14.3983413 L2.73877711,3.81101966 C2.48491928,3.51077504 2.42832739,3.09055952 2.59380617,2.7338695 C2.75928495,2.37717947 3.11675623,2.14905411 3.50987209,2.14905411 L26.4901335,2.14905411 C26.8832981,2.14905411 27.2407694,2.3772283 27.4061994,2.7338695 C27.5716293,3.09051069 27.5151351,3.51077504 27.2612773,3.81101966 L21.0488112,11.1572631 L18.4036028,11.1572631 L24.3135675,4.1687839 L5.6864869,4.1687839 L13.4730716,13.3765135 C13.6272223,13.558838 13.7118415,13.7898442 13.7118415,14.0286141 L13.7118415,23.9189572 L16.288164,25.207094 L16.288164,17.1572631 L18.3079426,17.1572631 Z"
        id="Combined-Shape"
        fillRule="nonzero"
      ></path>
      <rect id="Rectangle" fillRule="nonzero" x="14.4125375" y="12.9072631" width="11" height="2.5" rx="1.25"></rect>
    </SvgIcon>
  );
}
