import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getUserTeamViews } from 'store/userViews/selectors';
import { getCurrentUser } from 'store/login/selectors';

/**
 * @function useUserTeamViews
 *
 * Get all views for current user:
 *   - if user is on a team would see views created by users in that team
 *   - otherwise they would see views that are shared within the whole org
 *
 * @return {Object}
 */
const useUserTeamViews = () => {
  const views = useSelector(getUserTeamViews);
  const currentUser = useSelector(getCurrentUser);

  const teamViews = useMemo(() => views.filter(view => view.user_id !== currentUser.id), [views, currentUser]);

  return {
    teamViews,
  };
};

export default useUserTeamViews;
