const updateSelectedItems = ({ gridIsReady, gridApi }, selectedItems) => {
  if (gridIsReady) {
    gridApi.forEachNode(node => {
      try {
        node.setSelected(selectedItems.some(i => i.id === node.data.id));
      } catch {
        // pass
      }
    });
  }
};

export { updateSelectedItems };
