import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentUser } from 'store/login/selectors';
import { checkRolePermission } from 'containers/UserPermission/utils';

import useProjectFieldsConfigurationForConfigurator from './hooks/useProjectFieldsConfigurationForConfigurator';

export default Component => {
  return props => {
    const { layer, oldLightboxActive } = props;

    const currentUser = useSelector(getCurrentUser);
    const canEditFieldsLayout = checkRolePermission('EDIT_PROJECT_FIELDS_LAYOUT', currentUser);

    const {
      configuration,
      handleChangeConfiguration,
      handleSaveConfiguration,
      allowFieldToInherit,
      enableRequiredFields,
      allowFieldToBeRequired,
    } = useProjectFieldsConfigurationForConfigurator(layer, oldLightboxActive);

    return (
      <Component
        {...props}
        currentUser={currentUser}
        fieldsLayout={configuration.fieldsLayout}
        fieldsInherit={configuration.fieldsInherit}
        fieldsRequired={configuration.fieldsRequired}
        handleChangeConfiguration={handleChangeConfiguration}
        handleSaveConfiguration={handleSaveConfiguration}
        allowFieldToInherit={allowFieldToInherit}
        editable={canEditFieldsLayout}
        enableRequiredFields={enableRequiredFields}
        allowFieldToBeRequired={allowFieldToBeRequired}
      />
    );
  };
};
