import React, { createContext, useState, useContext } from 'react';

import ProjectsListLightbox from 'containers/ProjectsListLightbox';

const ProjectsListLightboxContext = createContext({});

const ProjectsListLightboxProvider = ({ children }) => {
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [entityType, setEntityType] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [tableState, setTableState] = useState({ columnState: null, visibleFields: null });

  const openProjectsListLightbox = (selectedEntity, entityType, selectedProjects = []) => {
    setSelectedEntity(selectedEntity);
    setEntityType(entityType);
    setSelectedProjects(selectedProjects);
  };

  const closeProjectsListLightbox = () => {
    setSelectedEntity(null);
    setEntityType(null);
    setSelectedProjects({});
  };

  const updateTableState = (key, newState) => {
    const newTableState = { ...tableState };

    newTableState[key] = newState;
    setTableState(newTableState);
  };

  return (
    <ProjectsListLightboxContext.Provider value={{ openProjectsListLightbox }}>
      {children}
      <ProjectsListLightbox
        isOpen={!!selectedEntity}
        selectedEntity={selectedEntity}
        entityType={entityType}
        selectedProjects={selectedProjects}
        onClose={closeProjectsListLightbox}
        tableState={tableState}
        updateTableState={updateTableState}
      />
    </ProjectsListLightboxContext.Provider>
  );
};

/**
 * @function useProjectsListLightboxContext
 *
 * hook to use the projects list lightbox context
 * needs to be wrapped on ProjectsListLightboxProvider
 *
 * @return {Object}
 */
const useProjectsListLightboxContext = () => useContext(ProjectsListLightboxContext);

export { ProjectsListLightboxProvider };

export default useProjectsListLightboxContext;
