import React from 'react';
import { useSelector } from 'react-redux';

import { getOrganization } from 'store/organization/selectors';
import { getUsers } from 'store/users/selectors';
import { ProjectsListLightboxProvider } from 'hooks/useProjectsListLightbox';

const metricsHOC = Component => {
  return props => {
    const organization = useSelector(getOrganization);

    const users = useSelector(getUsers);

    return (
      <ProjectsListLightboxProvider>
        <Component {...props} users={users} hideMetadataRoadmaps={!organization.enable_metadata_roadmaps} />
      </ProjectsListLightboxProvider>
    );
  };
};

metricsHOC.displayName = 'metricsHOC';

export default metricsHOC;
