import Component from './Home';
import HeaderOptions from './HeaderOptions';
import HeaderFilters from './HeaderFilters';
import NavBarSubMenu from './NavBarSubMenu';
import NavBarItem from './NavBarItem';

export default {
  HeaderOptions,
  HeaderFilters,
  Route: Component,
  NavBarSubMenu,
  NavBarItem,
};
