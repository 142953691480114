import { useCallback, useState } from 'react';
import axios from 'axios';

import { getUserName } from 'utils';

const DEFAULT_PARAMS = {
  status: 'Active',
  from: 0,
  to: 10,
};

/**
 * TODO - this needs to be improved and have a proper thunk operation
 * in the store. I was able to find 6/7 places using axios directly in
 * the component/container to search for users.
 */
const useSearchUsers = () => {
  const [suggestions, setSuggestions] = useState([]);

  const onSearchUsers = useCallback((search = {}) => {
    const { value } = search;

    const params = {
      ...DEFAULT_PARAMS,
      search: value,
    };

    return axios.get('/api/users', { params }).then(({ data: responseData }) => {
      const usersData = responseData.data.map(user => ({
        ...user,
        name: getUserName(user),
      }));

      setSuggestions(usersData);
    });
  }, []);

  return {
    suggestions,
    onSearchUsers,
  };
};

export default useSearchUsers;
