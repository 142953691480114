import { useCallback, useMemo } from 'react';

import { PERMISSION_RESOURCES, REFERER_SETTINGS } from '@dragonboat/permissions';

import useSystemFields from 'hooks/useSystemFields';
import usePermissions from 'hooks/permissions/usePermissions';

import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';
import {
  statusColumnDef,
  updatedColumnDef,
  getMetadataRoadmapColumnDef,
  healthColumnDef,
  dateColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';
import { useSettingsGridColumns } from 'containers/Grids/SettingsGrid/hooks';

import GroupHeaderInnerRenderer from '../components/GroupHeaderInnerRenderer';
import { makeCheckAddVisibility } from '../helpers';
import { useSelector } from 'react-redux';
import { getOrganization } from 'store/organization/selectors';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import { isNewRow } from 'design-system/molecules/AgGridReact-New/helpers';
import { GROUP_COLUMN_INITIAL_WIDTH } from 'constants/grid';

const TIMEFRAME_KEY_CORP = 'timeframeCorp';
const TIMEFRAME_KEY = 'timeframe';
const TIMEFRAME_KEY_LEVEL_2 = 'timeframe2';
const TIMEFRAME_ROADMAPS_FIELD = 'timeframe_roadmaps';
const TITLE_FIELD = 'title';

const startDateColumnDef = {
  ...dateColumnDef,
  field: 'date',
  headerName: 'Start',
};

const endDateColumnDef = {
  ...dateColumnDef,
  field: 'end_date',
  headerName: 'End',
};

const useTimeframesGridColumns = ({
  allowActions,
  selectionMode,
  setItemToDelete,
  addTimeframeWithoutSave,
  groupColumn,
  roadmapColumn,
}) => {
  const [getSystemFieldName] = useSystemFields();
  const organization = useSelector(state => getOrganization(state));

  const { organizationAccessControlData } = useOrganizationsAccessControl();
  const { canView } = usePermissions();

  const canViewCorpLevel = canView(PERMISSION_RESOURCES.timeframeCorp, { referer: REFERER_SETTINGS });
  const canViewTimeframe = canView(PERMISSION_RESOURCES.timeframe);
  const canViewTimeframeLevel2 = canView(PERMISSION_RESOURCES.timeframe2);

  const { hasMultiLevelPortfolioMetadata } = groupColumn;
  const { createTimeframeRoadmap, deleteTimeframeRoadmap, bulkDeleteTimeframeRoadmaps, timeframes, hideMetadataRoadmaps } =
    roadmapColumn;

  const hasCorpLevelOrMultiLevelEnabled = canViewCorpLevel || hasMultiLevelPortfolioMetadata;
  // Specific Timeframes Group column definition

  const levelZeroTitle = canViewCorpLevel && getSystemFieldName(TIMEFRAME_KEY_CORP);
  const levelOneTitle = canViewTimeframe && getSystemFieldName(TIMEFRAME_KEY);
  const levelTwoTitle = canViewTimeframeLevel2 && getSystemFieldName(TIMEFRAME_KEY_LEVEL_2);

  const titleHeaderName = [levelZeroTitle, levelOneTitle, levelTwoTitle].filter(Boolean).join(' > ');

  const handleAddLevelTimeframe = useCallback((data, { node }) => {
    if (!node.expanded) {
      node.setExpanded(true);
    }

    return addTimeframeWithoutSave({ level: data.level, id: data?.id });
  }, []);

  const checkAddVisibility = useMemo(() => {
    return makeCheckAddVisibility(canViewCorpLevel, hasMultiLevelPortfolioMetadata, organization, organizationAccessControlData);
  }, [canViewCorpLevel, hasMultiLevelPortfolioMetadata]);

  const groupColumnDef = useMemo(
    () => ({
      headerName: hasCorpLevelOrMultiLevelEnabled ? titleHeaderName : 'Title',
      field: TITLE_FIELD,
      suppressMovable: true,
      editable: allowActions,
      headerClass: 'first-field',
      cellClass: 'ag-title-cell',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
        innerRenderer: GroupHeaderInnerRenderer,
        handleAddWithoutSave: handleAddLevelTimeframe,
        checkAddVisibility,
      },
      width: GROUP_COLUMN_INITIAL_WIDTH,
    }),
    [titleHeaderName],
  );

  const settingsGridColumns = useSettingsGridColumns({
    allowActions,
    selectionMode,
    setItemToDelete,
    items: timeframes,
    canDragRows: true,
    headerCheckboxSelection: !hasMultiLevelPortfolioMetadata && !canViewCorpLevel,
  });

  const roadmapsMetadata = useRoadmapsMetadata(
    createTimeframeRoadmap,
    deleteTimeframeRoadmap,
    timeframes,
    TIMEFRAME_KEY,
    bulkDeleteTimeframeRoadmaps,
  );

  const remainingTimeframesColumnDefs = useMemo(
    () => [
      getMetadataRoadmapColumnDef({
        roadmapsMetadata: [...roadmapsMetadata, hideMetadataRoadmaps],
        idKey: 'uniqueId',
        field: TIMEFRAME_ROADMAPS_FIELD,
        editableForNewRow: false,
      }),
      startDateColumnDef,
      endDateColumnDef,
      { ...statusColumnDef, enableRowGroup: false },
      healthColumnDef,
      updatedColumnDef,
    ],
    [hideMetadataRoadmaps, hasMultiLevelPortfolioMetadata, canViewCorpLevel],
  );

  // Join settings and timeframes column definitions

  const columnDefs = useMemo(
    () => [
      ...settingsGridColumns.beforeColumnDefs,
      ...[groupColumnDef],
      ...remainingTimeframesColumnDefs,
      ...settingsGridColumns.afterColumnDefs,
    ],
    [settingsGridColumns, remainingTimeframesColumnDefs],
  );

  return {
    columnDefs,
    defaultColDef: {
      ...settingsGridColumns.defaultColDef,
      editable: params => allowActions && !isNewRow(params),
    },
  };
};

export default useTimeframesGridColumns;

export { TIMEFRAME_ROADMAPS_FIELD };
