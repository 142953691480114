import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  errorMessage: {
    marginTop: 30,
    color: theme.palette.error.main,
  },
});

export default withStyles(styles)(props => {
  return (
    <Typography color="error" variant="body" className={props.classes.errorMessage}>
      {props.errorMessage}
    </Typography>
  );
});
