import { indexBy, prop } from 'ramda';

import SubRoadmapsList from '../widgets/SubRoadmapsList';
import DescriptionEditor from '../widgets/DescriptionEditor';
import OkrsList from '../widgets/OkrsList';
import MetricsList from '../widgets/MetricsList';

import {
  PORTFOLIO_GOALS_WIDGET_ID,
  PORTFOLIO_METRICS_WIDGET_ID,
  PORTFOLIO_ROADMAP_OVERVIEW_WIDGET_ID,
  PORTFOLIO_SUB_ROADMAPS_WIDGET_ID,
} from './widgetIds';

export const GRID_MIN_ROWS = 2;
export const GRID_MAX_ROWS = 10;
export const GRID_LAYOUT_ROW_HEIGHT = 240;
export const WIDGET_HEIGHT_OFFSET = 0.5;

const PORTFOLIO_SUB_ROADMAPS_WIDGET = {
  id: PORTFOLIO_SUB_ROADMAPS_WIDGET_ID,
  widgetComponent: SubRoadmapsList,
  dragDisabledElements: ['.ag-root-wrapper', 'button'],
};

const PORTFOLIO_GOALS_WIDGET = {
  id: PORTFOLIO_GOALS_WIDGET_ID,
  widgetComponent: OkrsList,
  dragDisabledElements: ['.ag-root-wrapper', 'button'],
};

const PORTFOLIO_METRICS_WIDGET = {
  id: PORTFOLIO_METRICS_WIDGET_ID,
  widgetComponent: MetricsList,
  dragDisabledElements: ['#metric-values-popover'],
};

const PORTFOLIO_ROADMAP_OVERVIEW_WIDGET = {
  id: PORTFOLIO_ROADMAP_OVERVIEW_WIDGET_ID,
  widgetComponent: DescriptionEditor,
  dragDisabledElements: ['.remirror-editor-wrapper', '#description-history'],
};

const AVAILABLE_WIDGETS = [
  PORTFOLIO_ROADMAP_OVERVIEW_WIDGET,
  PORTFOLIO_SUB_ROADMAPS_WIDGET,
  PORTFOLIO_GOALS_WIDGET,
  PORTFOLIO_METRICS_WIDGET,
];

const WIDGETS_CONFIG_BY_ID = indexBy(prop('id'))(AVAILABLE_WIDGETS);

const INITIAL_LAYOUT = [
  { i: PORTFOLIO_ROADMAP_OVERVIEW_WIDGET_ID, w: 1, h: 1.5, x: 0, y: 0 },
  { i: PORTFOLIO_SUB_ROADMAPS_WIDGET_ID, w: 1, h: 2, x: 1, y: 0 },
  { i: PORTFOLIO_GOALS_WIDGET_ID, w: 1, h: 2, x: 1, y: 3 },
  { i: PORTFOLIO_METRICS_WIDGET_ID, w: 1, h: 1, x: 1, y: 5 },
];

const INITIAL_LAYOUT_BY_ID = indexBy(prop('i'))(INITIAL_LAYOUT);

// eslint-disable-next-line max-len
const DEFAULT_DESCRIPTION = `<p><strong>Vision</strong></p><p></p><p><strong>Strategies</strong></p><p></p><p><strong>Current state</strong></p><p></p><p><strong>Competition</strong></p><p></p>`;

export { AVAILABLE_WIDGETS, WIDGETS_CONFIG_BY_ID, INITIAL_LAYOUT, INITIAL_LAYOUT_BY_ID, DEFAULT_DESCRIPTION };
