import React from 'react';

import Autocomplete from 'design-system/atoms/Autocomplete/v2';
import useTreeAutocompleteSearch from 'design-system/atoms/Autocomplete/v2/hooks/useTreeAutocompleteSearch';

import useByFieldAutocompleteOptions from './hooks/useByFieldAutocompleteOptions';

const emptyArray = [];

const GroupAutocomplete = ({
  name,
  options = emptyArray,
  getSystemFieldName,
  disabled = false,
  onCloseList,
  placeholder,
  focusOnInit,
  onSelectOptionCallback,
  withNullOption,
}) => {
  const groupOptions = useByFieldAutocompleteOptions({
    options,
    getSystemFieldName,
    withNullOption,
  });

  const { searchValue, filteredOptions, onSearchChange, registerExpandAllCallback, registerCollapseAllCallback } =
    useTreeAutocompleteSearch(groupOptions);

  return (
    <Autocomplete
      name={name}
      searchValue={searchValue}
      options={filteredOptions}
      disabled={disabled}
      onSelectOption={(...args) => {
        return onSelectOptionCallback(...args);
      }}
      onValueChange={onSearchChange}
      onCloseList={onCloseList}
      registerExpandAllCallback={registerExpandAllCallback}
      registerCollapseAllCallback={registerCollapseAllCallback}
      placeholder={placeholder}
      focusOnInit={focusOnInit}
    />
  );
};

export default GroupAutocomplete;
