import { useSelector } from 'react-redux';
import { isNil } from 'ramda';

import { getFilters } from 'store/users/selectors';
import { ACTIVE_STATUS } from 'constants/common';
import UserTypeMapper from 'utils/UserTypeMapper';
import { RESOURCE_USER } from '@dragonboat/permissions';
import useRoleAvailability from 'hooks/users/useRoleAvailability';

const DEFAULT_FILTERS = {
  status: [ACTIVE_STATUS],
};

/**
 * Applies default filters to the provided filters object if any value is missing or empty.
 *
 * @param {Object} defaultFilters - The default filters object.
 * @param {Object} filters - The filters object to which default filters will be applied.
 * @return {Object} The filters object with default filters applied.
 */
export const applyDefaultFiltersIfAnyValue = (defaultFilters, filters) => {
  return Object.keys(defaultFilters).reduce((acc, filterKey) => {
    if (isNil(filters[filterKey])) {
      return {
        ...acc,
        [filterKey]: defaultFilters[filterKey],
      };
    }

    return acc;
  }, filters);
};

/**
 * Builds the default filters for user roles based on the availability of roles
 * and merges them with provided default filters.
 *
 * @param {object} defaultFilters - The default filters to be merged.
 * @param {object} options - The options object.
 * @param {function} options.isRoleAvailable - A function that checks if a role is available.
 * @returns {object} - An object containing the merged default filters.
 */
const buildDefaultFiltersWithDefaultRoles = (defaultFilters, { isRoleAvailable }) => {
  // Filter out roles that are not RESOURCE_USER
  const nonResourceRoles = UserTypeMapper.filter(userType => userType.value !== RESOURCE_USER);

  // Filter available user roles based on the provided availability check
  const availableRoles = nonResourceRoles.filter(userType => isRoleAvailable(userType.value)).map(userType => userType.label);

  return {
    ...defaultFilters,
    role: availableRoles,
  };
};

/**
 * Custom hook for using user filters with default values.
 *
 * @returns {Object} An object containing user filters.
 * @property {Object} filters - User filters with default values applied.
 */
const useUsersFilters = () => {
  const filters = useSelector(getFilters);

  const { isRoleAvailable } = useRoleAvailability();

  const defaultFilters = buildDefaultFiltersWithDefaultRoles(DEFAULT_FILTERS, { isRoleAvailable });

  const usersFilters = applyDefaultFiltersIfAnyValue(defaultFilters, filters);

  return {
    filters: usersFilters,
  };
};

export default useUsersFilters;
