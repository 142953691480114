// External dependencies
import { createSelector } from 'reselect';
import { getCustomerCustomFields } from 'store/customFields/selectors';
import { getOrganizationIntegrations } from 'store/organization';

// Dragonboat dependencies
import { filterActiveItens } from 'utils';
import { withFlatCustomFields, withIntegrations } from 'utils/customers';

export const getState = createSelector(
  state => state.customers,
  state => getCustomerCustomFields(state, true, true),
  state => getOrganizationIntegrations(state),
  (state, customFields, orgIntegrations) => {
    const customFieldsWrapper = withFlatCustomFields(customFields);
    const integrationsMapper = withIntegrations(orgIntegrations);
    const rows = state.has('rows') ? state.get('rows').toJS() : [];
    const mapped = rows.map(integrationsMapper);

    return mapped.map(customFieldsWrapper);
  },
);

export const getAllCustomers = getState;
export const getCustomers = getAllCustomers;

export const getActiveCustomers = createSelector(getState, data => data.filter(filterActiveItens));

const selectorByStatusFilter = showArchived => (showArchived ? getAllCustomers : getActiveCustomers);

export const getCustomersFilteredByStatus = (state, showArchived) => selectorByStatusFilter(showArchived)(state);

export const getTotalSalesforceCustomers = createSelector(
  state => state.customers,
  customerState => customerState.get('totalSalesforceCustomers'),
);

export const getSalesforceImportError = createSelector(
  state => state.customers,
  customerState => customerState.get('importSalesforceCustomersError'),
);

export const getSearchText = createSelector(
  state => state.customers,
  customersState => customersState.get('searchText') || '',
);

export const getSettingsGroupByOption = createSelector(
  state => state.customers,
  customersState => customersState.get('settingsGroupByOption'),
);
