import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { upsertReleaseCapacityAllocation, getNormalizedReleaseCapacityAllocation } from 'store/releaseCapacityAllocation';
/**
 * @function useReleaseCapacityAllocation
 *
 * Hook to get the release capacity allocation data and actions
 *
 * @return {type}
 */
export const useReleaseCapacityAllocation = () => {
  const dispatch = useDispatch();

  const releaseCapacityAllocation = useSelector(getNormalizedReleaseCapacityAllocation);

  const upsertResourcing = useCallback((timeframeId, metadataField, metadataId, newResourcing) => {
    dispatch(upsertReleaseCapacityAllocation(timeframeId, newResourcing, metadataField, metadataId));
  }, []);

  return {
    // Data
    releaseCapacityAllocation,

    // Actions
    upsertResourcing,
  };
};
