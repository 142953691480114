import React, { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete/index';

import ChartWidget from 'routes/Dashboard/Dashboards/ChartWidget';
import {
  SCENARIOS_BENEFITS_COMPARISON_CHART,
  SCENARIO_STACKED_BENEFIT_CHART,
  SCENARIOS_ACCUMULATIVE_BENEFITS_COMPARISON_CHART,
} from 'routes/Dashboard/Dashboards/helpers/templates';
import { getAvailableRoadmapVersions } from 'store/roadmapVersions/selectors';
import { PLAN_OF_RECORD_ID, PLAN_OF_RECORD_TITLE_COMMITTED } from 'constants/common';
import { DEFAULT_PROJECTED_OPTION } from 'store/roadmapVersions/constants/defaultChartOptions';
import createLabelsMap from 'routes/RoadmapVersions/utils/createLabelsMap';

const CONTAINER_SPACING_OFFSET = 110;

const createWidgets = (oneClickRoadmapVersion, dateInterval, labelsMap, stackedByField) => {
  const comparisonAccumulativeChart = {
    ...SCENARIOS_ACCUMULATIVE_BENEFITS_COMPARISON_CHART,
    props: {
      roadmapVersionIds: [PLAN_OF_RECORD_ID, ...(oneClickRoadmapVersion ? [oneClickRoadmapVersion.id] : [])],
      projectedField: DEFAULT_PROJECTED_OPTION.key,
      dateInterval,
      labelsMap,
    },
  };

  const comparisonChart = {
    ...SCENARIOS_BENEFITS_COMPARISON_CHART,
    props: {
      roadmapVersionIds: [PLAN_OF_RECORD_ID, ...(oneClickRoadmapVersion ? [oneClickRoadmapVersion.id] : [])],
      projectedField: DEFAULT_PROJECTED_OPTION.key,
      dateInterval,
      labelsMap,
    },
  };

  const planOfRecordStackedChart = {
    ...SCENARIO_STACKED_BENEFIT_CHART,
    props: {
      roadmapVersionId: PLAN_OF_RECORD_ID,
      chartTitle: PLAN_OF_RECORD_TITLE_COMMITTED,
      dateInterval,
      projectedField: DEFAULT_PROJECTED_OPTION.key,
      stackedByField,
    },
  };

  const stackedChart = {
    ...SCENARIO_STACKED_BENEFIT_CHART,
    props: {
      roadmapVersionId: oneClickRoadmapVersion?.id,
      chartTitle: oneClickRoadmapVersion?.name || 'N/A',
      dateInterval,
      projectedField: DEFAULT_PROJECTED_OPTION.key,
      stackedByField,
    },
  };

  return { comparisonAccumulativeChart, comparisonChart, planOfRecordStackedChart, stackedChart };
};

const renderWidget = (widget, chartsHeight) => (
  <ChartWidget widget={widget} handleDelete={() => {}} inEditMode={false} updateWidgetHeight={() => {}} height={chartsHeight} />
);

const BenefitCharts = ({ oneClickRoadmapVersion, dateInterval, stackedByOption, onStackedByOptionChange, stackedByOptions }) => {
  const [chartsHeight, setChartsHeight] = useState(null);

  const containerRef = useRef(null);

  const roadmapVersions = useSelector(state => getAvailableRoadmapVersions(state));

  const labelsMap = useMemo(
    () => createLabelsMap([...roadmapVersions, oneClickRoadmapVersion]),
    [roadmapVersions, oneClickRoadmapVersion],
  );

  const { comparisonAccumulativeChart, comparisonChart, planOfRecordStackedChart, stackedChart } = useMemo(
    () => createWidgets(oneClickRoadmapVersion, dateInterval, labelsMap, stackedByOption?.key),
    [oneClickRoadmapVersion, dateInterval, labelsMap, stackedByOption],
  );

  useEffect(() => {
    if (containerRef.current) {
      const containerHeight = containerRef.current.clientHeight;

      // eslint-disable-next-line no-mixed-operators
      setChartsHeight(containerHeight / 2 - CONTAINER_SPACING_OFFSET);
    }
  }, []);

  return (
    <Container ref={containerRef}>
      <ChartsContainer>
        {renderWidget(comparisonAccumulativeChart, chartsHeight)}
        {renderWidget(comparisonChart, chartsHeight)}
      </ChartsContainer>
      <SpacedGroupByAutocomplete
        style={{ marginTop: '1em', marginBottom: '1em' }}
        label="Stacked by"
        suggestions={stackedByOptions}
        value={stackedByOption}
        onChange={onStackedByOptionChange}
      />
      <ChartsContainer>
        {renderWidget(planOfRecordStackedChart, chartsHeight)}
        {renderWidget(stackedChart, chartsHeight)}
      </ChartsContainer>
    </Container>
  );
};

export default BenefitCharts;

const Container = styled.div`
  height: 100%;
`;

const SpacedGroupByAutocomplete = styled(GroupByAutocomplete)`
  margin-bottom: 1em;
  margin-top: 1em;
`;

const ChartsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.unit * 2}px;

  > div {
    width: 50%;
    box-sizing: border-box;
  }
`;
