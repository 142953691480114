import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { path } from 'ramda';

import { KEY_RESULT_LEVEL } from 'constants/common';
import { OBJECTIVE_KEY } from 'constants/objectives';

import GroupHeaderInnerRenderer from 'containers/Grids/OkrsGrid/components/GroupHeaderInnerRenderer';

import {
  targetAllocationAmountColumnDef,
  targetAllocationPercentageColumnDef,
  getOwnerColumnDef,
  getMetadataRoadmapColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';
import { ProgressCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { ProgressCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import okrHealthColumnDef from 'design-system/molecules/AgGridReact-New/columns/okrHealthColumnDef';
import AgGridIntegrationIconsColumn from 'components/AgGridIntegrationIconsColumn';
import useSystemFields from 'hooks/useSystemFields';
import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';
import { createObjectiveRoadmap, deleteObjectiveRoadmap, bulkDeleteObjectiveRoadmaps } from 'store/objectives/actions';
import { integrationComparator } from 'utils/agGridComparators';
import { GROUP_COLUMN_INITIAL_WIDTH } from 'constants/grid';

import dateColumnDef from 'design-system/molecules/AgGridReact-New/columns/dateColumnDef';

import { getOrganization } from 'store/organization';
import { getCurrentUser } from 'store/login/selectors';
import useNumberOfTextRowsToDisplayOnGridCell from 'hooks/useNumberOfTextRowsToDisplayOnGridCell';
import { READ_ONLY_USER } from '@dragonboat/permissions';
import makeCanObjectiveMetadataRoadmapBeUpdated from 'utils/okrs/makeCanObjectiveMetadataRoadmapBeUpdated';
import { getOKRGroupColumnTitle } from '../helpers';

const KEY_RESULT_KEY = 'key_result';

export const ROADMAP_FIELD_KEY = 'key_result_roadmaps';

export const ROADMAP_OBJECTIVE_FIELD_KEY = 'objective_roadmaps';

// With the removal of the open and add columns we have more space to render the title column
// and keep same look and feel as before.
const TITLE_COLUMN_MIN_WIDTH = 270;
const TITLE_COLUMN_MIN_WIDTH_GOAL_MODE = 440;

// This can be improved by having it to be a function and we could pass override props
const dateColumns = [
  { ...dateColumnDef, headerName: 'Start Date', field: 'start_date', width: 110 },
  { ...dateColumnDef, headerName: 'End Date', field: 'end_date', width: 110 },
];

const useObjectivesGridColumns = (
  canUpdate,
  objectivesAndKeyResultsWithHierarchy,
  {
    openObjectiveDrawer,
    openProjectsLightbox,
    allowActions,
    users,
    orgIntegrations,
    showIntegrationColumn,
    hideMetadataRoadmaps,
    hasKeyResults,
    hasKeyResults2,
    allowAddKeyResult1,
    allowAddKeyResult2,
    hasCorpLevel, // Actually is being passed isDodActive
    isGoalMode,
    checkAddVisibility,
    handleAddOKRWithoutSave,
  },
) => {
  const [getSystemFieldName] = useSystemFields();
  const organization = useSelector(getOrganization);
  const currentUser = useSelector(getCurrentUser);
  const userIsNotReadOnly = currentUser?.role_id !== READ_ONLY_USER;
  const canObjectiveMetadataRoadmapBeUpdated = useMemo(
    () => makeCanObjectiveMetadataRoadmapBeUpdated(hasCorpLevel, organization.id, userIsNotReadOnly, canUpdate),
    [hasCorpLevel, organization.id, userIsNotReadOnly, canUpdate],
  );

  const outcomeProgressColumnDef = useMemo(
    () => ({
      width: 110,
      editable: allowActions,
      field: 'progress',
      headerName: 'Outcome Progress %',
      cellRenderer: ProgressCellRenderer,
      cellEditor: ProgressCellEditor,
    }),
    [allowActions],
  );

  const roadmapsMetadata = useRoadmapsMetadata(
    createObjectiveRoadmap,
    deleteObjectiveRoadmap,
    objectivesAndKeyResultsWithHierarchy,
    OBJECTIVE_KEY,
    bulkDeleteObjectiveRoadmaps,
    KEY_RESULT_KEY,
  );

  const integrationsColumnDef = useMemo(
    () => ({
      editable: false,
      headerName: 'Integration',
      field: 'integrations',
      cellRenderer: AgGridIntegrationIconsColumn,
      comparator: integrationComparator,
      orgIntegrations,
      hide: !showIntegrationColumn,
      filterParams: {
        values: ['(Blanks)', 'Has Integration'],
      },
      keyCreator: ({ value }) => {
        return value?.length ? 'Has Integration' : '(Blanks)';
      },
      width: 75,
    }),
    [orgIntegrations, showIntegrationColumn],
  );

  const columnsToRender = useMemo(
    () =>
      isGoalMode ? [okrHealthColumnDef, ...dateColumns] : [targetAllocationAmountColumnDef, targetAllocationPercentageColumnDef],
    [isGoalMode],
  );

  /**
   * for tree data, the key is defined, so new row requires only checking id
   */
  const isExistingOkrRow = useCallback(params => !!params?.data?.id, []);

  const columnDefs = useMemo(
    () => [
      getMetadataRoadmapColumnDef({
        roadmapsMetadata: [...roadmapsMetadata, hideMetadataRoadmaps],
        field: hasCorpLevel ? ROADMAP_OBJECTIVE_FIELD_KEY : ROADMAP_FIELD_KEY,
        editable: params => isExistingOkrRow(params) && !!canObjectiveMetadataRoadmapBeUpdated(params),
        idKey: 'uniqueId',
        valueGetter: data => [
          ...(path(['data', ROADMAP_FIELD_KEY], data) ?? path(['data', ROADMAP_OBJECTIVE_FIELD_KEY], data) ?? []),
        ],
      }),
      ...columnsToRender,
      outcomeProgressColumnDef,
      { ...getOwnerColumnDef({ editable: params => isExistingOkrRow(params) && allowActions, users, enableRowGroup: false }) },
      integrationsColumnDef,
    ],
    [allowActions, hideMetadataRoadmaps, isGoalMode, roadmapsMetadata],
  );

  const headerName = useMemo(
    () => getOKRGroupColumnTitle(hasKeyResults, hasKeyResults2, hasCorpLevel, getSystemFieldName),
    [getSystemFieldName, hasKeyResults, hasKeyResults2],
  );

  const checkGroupHeaderIsEditable = useCallback(
    ({ data }) => {
      return (
        allowActions ||
        (allowAddKeyResult1 && data?.level === KEY_RESULT_LEVEL.keyResult) ||
        (allowAddKeyResult2 && data?.level === KEY_RESULT_LEVEL.keyResult2)
      );
    },
    [allowActions, allowAddKeyResult1, allowAddKeyResult2],
  );

  const { getNumberOfTextRowsToDisplayOnGridCell } = useNumberOfTextRowsToDisplayOnGridCell();

  const groupColumnDef = useMemo(
    () => ({
      headerName,
      field: 'title',
      suppressMovable: true,
      cellClass: 'ag-react-editable-container--expandable ag-title-cell',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: GroupHeaderInnerRenderer,
        suppressCount: true,
        suppressDoubleClickExpand: true,
        openObjectiveDrawer,
        openProjectsLightbox,
        getNumberOfTextRowsToDisplayOnGridCell,
        checkAddVisibility,
        handleAddOKRWithoutSave,
        disabled: !allowActions,
      },
      editable: checkGroupHeaderIsEditable,
      minWidth: isGoalMode ? TITLE_COLUMN_MIN_WIDTH_GOAL_MODE : TITLE_COLUMN_MIN_WIDTH,
      width: GROUP_COLUMN_INITIAL_WIDTH,
    }),
    [getSystemFieldName, headerName, openObjectiveDrawer, openProjectsLightbox, isGoalMode],
  );

  return {
    columnDefs,
    groupColumnDef,
  };
};

export default useObjectivesGridColumns;
