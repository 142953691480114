import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import HelpLink from 'design-system/atoms/HelpLink';
import TextFieldNoBorder from 'design-system/atoms/TextFieldNoBorder';
import { spacing } from 'design-system/theme';
import { FIELD_MAPPING_DIRECTION_OPTIONS } from 'src/constants/integrations';

const getLinkColor = (theme, direction) => {
  return direction === FIELD_MAPPING_DIRECTION_OPTIONS.outbound ? theme.palette.primary.main : theme.palette.brandDarkBlue;
};

export const IntegrationWrapper = styled.div`
  border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;
  background-color: ${({ theme }) => theme.palette.background.integration};
  padding: ${spacing(2)}px ${spacing(3)}px ${spacing(2)}px ${spacing(2)}px;
  margin-top: -30px;
`;

export const StyledGridItem = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

export const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const InputWrapper = styled.div`
  flex-grow: 1;
  min-width: ${({ minWidth }) => minWidth || 215}px;
`;

export const StyledTextField = styled(TextFieldNoBorder)`
  label {
    color: ${({ theme }) => theme.palette.text.disabled};
  }
`;

export const HintButtonWrapper = styled.div`
  position: relative;
`;

export const LinkWorkItemButton = styled(Button)`
  &&&& {
    font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
    color: ${({ theme, direction, disabled }) => (disabled ? theme.palette.text.disabled : getLinkColor(theme, direction))};
    text-align: center;
    white-space: nowrap;
  }

  svg {
    margin-right: ${spacing(0.5)}px;
  }
`;

export const HelpIconLink = styled(HelpLink)`
  && {
    position: absolute;
    top: 0;
    right: -16px;

    svg {
      width: 1em;
      height: 1em;
      font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`;
