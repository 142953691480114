import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Stepper from '@material-ui/core/Stepper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { has } from 'ramda';

import CloseButton from 'components/DialogTitle/CloseButton';
import useIntegrations from 'hooks/useIntegrations';
import { getOrganization } from 'store/organization';
import { IMPORT_LIGHTBOX_VARIANTS } from 'constants/integrations';

import { Content, CustomLightBox, Wrapper } from './ImportFromIntegrationWizard.styles';

const ImportFromIntegrationWizard = ({
  integrationType,
  steps,
  isOpen,
  onClose,
  parsedImportUrl,
  orgIntegrationId,
  onFinish,
  variant,
  useAsyncImport = false,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const isImportVariant = variant === IMPORT_LIGHTBOX_VARIANTS.import;
  const isRecurringImportVariant = variant === IMPORT_LIGHTBOX_VARIANTS.recurringImport;
  const isLastStep = currentStep === steps.length - 1;

  const { organization } = useSelector(getOrganization);

  const {
    importCountIntegrationItems,
    isImportCountIntegrationItemsLoading,
    importCountIntegrationItemsError,
    importInsertIntegrationItems,
    asyncImportInsertIntegrationItems,
    importInsertIntegrationItemsError,
    isImportInsertIntegrationItemsLoading,
  } = useIntegrations(integrationType, orgIntegrationId);

  const handleStepClick = useCallback(
    index => () => {
      if (completed[index]) {
        setCurrentStep(index);
      }
    },
    [completed, setCurrentStep],
  );
  // Async import (on worker) should have a similar UI experience. The new action will maintain the store in that state
  const importAction = useAsyncImport ? asyncImportInsertIntegrationItems : importInsertIntegrationItems;

  const handlePrev = useCallback(() => setCurrentStep(prevState => prevState - 1), [setCurrentStep]);
  const handleNext = useCallback(() => {
    setCompleted(prevCompleted => ({ ...prevCompleted, [currentStep]: true }));
    setCurrentStep(prevCurrentStep => Math.min(steps.length - 1, prevCurrentStep + 1));

    if (currentStep === 0) {
      if (isImportVariant) importAction(orgIntegrationId, parsedImportUrl);
    } else if (currentStep === 1) {
      onFinish();
    }
  }, [currentStep, setCompleted, setCurrentStep, onClose, parsedImportUrl, orgIntegrationId, organization, onFinish]);
  const handleClose = useCallback(() => {
    if (currentStep === 1 && !importInsertIntegrationItemsError) {
      onFinish();
    }

    onClose();

    setCurrentStep(0);
  }, [onClose, importInsertIntegrationItemsError, currentStep, onFinish]);

  const shouldButtonNextBeDisabled = useMemo(() => {
    const hasIntegrationItems = importCountIntegrationItems[parsedImportUrl] > 0;
    const hasSearch = has(parsedImportUrl);
    const hasAnySearch = !hasSearch;
    const hasImportCountError = Boolean(importCountIntegrationItemsError);

    if (isRecurringImportVariant) return isImportCountIntegrationItemsLoading || hasImportCountError || hasAnySearch;

    return isImportCountIntegrationItemsLoading || hasImportCountError || !hasIntegrationItems;
  }, [isImportCountIntegrationItemsLoading, importCountIntegrationItemsError, importCountIntegrationItems, parsedImportUrl]);

  const shouldShowSaveRecurringImportButton = isRecurringImportVariant && isLastStep;

  return (
    <CustomLightBox
      onClose={handleClose}
      isOpen={isOpen}
      maxWidth="lg"
      big
      content={
        <Wrapper>
          <CloseButton onClick={handleClose} />
          <Stepper alternativeLabel activeStep={currentStep}>
            {steps.map(({ title }, index) => (
              <Step key={title}>
                <StepButton onClick={handleStepClick(index)} completed={completed[index]} disabled={!completed[index]}>
                  {title}
                </StepButton>
              </Step>
            ))}
          </Stepper>
          <Content>{steps[currentStep].component}</Content>
          <Grid container>
            <Grid item xs={6}>
              {currentStep > 0 && currentStep !== steps.length - 1 && <Button onClick={handlePrev}>Back</Button>}
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              {currentStep !== steps.length - 1 && (
                <Button color="primary" onClick={handleNext} disabled={shouldButtonNextBeDisabled}>
                  Next
                </Button>
              )}
              {shouldShowSaveRecurringImportButton && (
                <Button color="primary" onClick={onFinish} disabled={shouldButtonNextBeDisabled}>
                  Import and save for future recurring import
                </Button>
              )}
            </Grid>
          </Grid>
          {(isImportCountIntegrationItemsLoading || isImportInsertIntegrationItemsLoading) && (
            <div style={{ marginBottom: '30px' }}>
              <LinearProgress />
            </div>
          )}
        </Wrapper>
      }
    />
  );
};

ImportFromIntegrationWizard.propTypes = {
  integrationType: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      component: PropTypes.object.isRequired,
    }),
  ).isRequired,
  parsedImportUrl: PropTypes.string,
  orgIntegrationId: PropTypes.number,
  onFinish: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
};

export default ImportFromIntegrationWizard;
