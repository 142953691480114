/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import RoadmapTable from 'design-system/organisms/RoadmapTable/index';


import { BASE_Z_INDEX, DEFAULT_SIDEBAR_WIDTH, generateMultiGroupRowKey, generateSingleGroupRowKey, ROW_HEIGHT } from '../helpers';
import Bar, { DndBoundsName, MilestoneDndBoundsName } from './Bar';
import Today from './Today';

const defaultRenderTitle = lines => item => <RoadmapTable.CellTitle lines={lines}>{item.title}</RoadmapTable.CellTitle>;

const TimelineBody = ({
  // data
  data,
  orders,

  // options
  groupbarWidth,
  sidebarWidth,
  groupbarOffset,
  sidebarOffset,
  mode,
  displayMilestone,
  displayMilestoneOn,
  slotWidth,
  showTooltip,
  showTextOverflow,
  resizable,
  draggable,
  snapToGridOn,
  barRenderer,

  // presentation
  isMultiGroup,
  shouldShowBar,
  fromDate,
  todayLine,
  customRenderTitle,

  // handlers
  onDoubleClick,
  onRowClick,
  onDrag,
  onResize,

  // loading
  isLoading,
}) => {
  return (
    <RoadmapTable.Body>
      {isMultiGroup ? (
        <>
          {data.map((lane, laneIndex) => (
            <RoadmapTable.Row key={`${lane.key}-${laneIndex}`} bordered>
              <RoadmapTable.Cell
                zIndex={BASE_Z_INDEX}
                left={1}
                sticky
                center
                width={`${groupbarWidth || DEFAULT_SIDEBAR_WIDTH}px`}
                background={lane.color}
              >
                <RoadmapTable.CellContainer center color={lane.color}>
                  {customRenderTitle
                    ? customRenderTitle(lane, defaultRenderTitle(!isEmpty(lane.groups) || 1))
                    : defaultRenderTitle(!isEmpty(lane.groups) || 1)}
                </RoadmapTable.CellContainer>
              </RoadmapTable.Cell>
              <RoadmapTable.Cell width={`calc(100% - ${groupbarWidth || DEFAULT_SIDEBAR_WIDTH}px)`} style={{ padding: 0 }}>
                {isEmpty(lane.groups) ? (
                  <RoadmapTable.Row>
                    <RoadmapTable.Cell
                      zIndex={BASE_Z_INDEX}
                      left={(groupbarWidth || DEFAULT_SIDEBAR_WIDTH) + 1}
                      sticky
                      center
                      split
                      width={`${sidebarWidth || DEFAULT_SIDEBAR_WIDTH}px`}
                      background={lane.color}
                    />
                    <RoadmapTable.Cell width={`calc(100% - ${sidebarWidth || DEFAULT_SIDEBAR_WIDTH}px)`}>
                      <RoadmapTable.CellContainer>
                        <RoadmapTable.RowContent onClick={!isLoading && onRowClick(lane)} />
                      </RoadmapTable.CellContainer>
                    </RoadmapTable.Cell>
                  </RoadmapTable.Row>
                ) : (
                  lane.groups.map((group, groupIndex) => (
                    <RoadmapTable.Row key={`group-${(orders || []).length}-${(group.rows || []).length}-${groupIndex}`}>
                      <RoadmapTable.Cell
                        zIndex={BASE_Z_INDEX}
                        left={(groupbarWidth || DEFAULT_SIDEBAR_WIDTH) + 1}
                        sticky
                        center
                        split
                        width={`${sidebarWidth || DEFAULT_SIDEBAR_WIDTH}px`}
                        background={lane.color}
                      >
                        <RoadmapTable.CellContainer center color={group.color} bordered>
                          {customRenderTitle
                            ? customRenderTitle(group, defaultRenderTitle((group.rows && group.rows.length) || 1))
                            : defaultRenderTitle((group.rows && group.rows.length) || 1)}
                        </RoadmapTable.CellContainer>
                      </RoadmapTable.Cell>
                      <RoadmapTable.Cell
                        width={`calc(100% - ${sidebarWidth || DEFAULT_SIDEBAR_WIDTH}px)`}
                        background={lane.color}
                      >
                        <RoadmapTable.CellContainer>
                          {isEmpty(group.rows) ? (
                            <RoadmapTable.RowContent onClick={!isLoading && onRowClick(lane, group)} />
                          ) : (
                            group.rows.map((row, rowIndex) => {
                              const key = generateMultiGroupRowKey(orders, lane, group, rowIndex, groupbarOffset, sidebarOffset);

                              return (
                                <RoadmapTable.RowContent
                                  className={lane.key === 'milestoneTopLane' ? MilestoneDndBoundsName : ''}
                                  key={key}
                                  onClick={!isLoading && onRowClick(lane, group)}
                                >
                                  {row.map(
                                    (task, taskIndex) =>
                                      shouldShowBar(task) && (
                                        <Bar
                                          key={`${task.id}-${taskIndex}`}
                                          {...task}
                                          fromDate={fromDate}
                                          mode={mode}
                                          isMilestoneGroupTopLane={lane.key === 'milestoneTopLane'}
                                          onDoubleClick={onDoubleClick}
                                          onDragStop={onDrag(task, laneIndex, rowIndex, groupIndex)}
                                          onResizeStop={onResize(task, laneIndex, rowIndex, groupIndex)}
                                          isLoading={isLoading}
                                          showTooltip={showTooltip}
                                          showTextOverflow={showTextOverflow}
                                          resizable={resizable}
                                          draggable={draggable}
                                          slotWidth={slotWidth}
                                          snapToGridOn={snapToGridOn}
                                          barRenderer={barRenderer}
                                        />
                                      ),
                                  )}
                                </RoadmapTable.RowContent>
                              );
                            })
                          )}
                        </RoadmapTable.CellContainer>
                      </RoadmapTable.Cell>
                    </RoadmapTable.Row>
                  ))
                )}
              </RoadmapTable.Cell>
            </RoadmapTable.Row>
          ))}
          <DndPlayground
            className={DndBoundsName}
            style={{
              top: displayMilestone && displayMilestoneOn === 'topLane' ? ROW_HEIGHT : 0,
              height: `calc(100% - ${displayMilestone && displayMilestoneOn === 'topLane' ? ROW_HEIGHT : 0}px)`,
              left: (sidebarWidth || DEFAULT_SIDEBAR_WIDTH) + (groupbarWidth || DEFAULT_SIDEBAR_WIDTH),
              width: `calc(100% - ${(sidebarWidth || DEFAULT_SIDEBAR_WIDTH) + (groupbarWidth || DEFAULT_SIDEBAR_WIDTH)}px)`,
            }}
          />
        </>
      ) : (
        <>
          {data.map((lane, laneIndex) => (
            <RoadmapTable.Row key={`${lane.key}-${laneIndex}`}>
              <RoadmapTable.Cell
                zIndex={BASE_Z_INDEX}
                left={1}
                sticky
                center
                width={`${groupbarWidth || DEFAULT_SIDEBAR_WIDTH}px`}
                background={lane.color}
                bordered
                split
              >
                <RoadmapTable.CellContainer center color={lane.color} bold bordered>
                  {customRenderTitle
                    ? customRenderTitle(lane, defaultRenderTitle((lane.rows && lane.rows.length) || 1))
                    : defaultRenderTitle((lane.rows && lane.rows.length) || 1)}
                </RoadmapTable.CellContainer>
              </RoadmapTable.Cell>
              <RoadmapTable.Cell
                width={`calc(100% - ${groupbarWidth || DEFAULT_SIDEBAR_WIDTH}px)`}
                style={{ padding: 0 }}
                background={lane.color}
              >
                <RoadmapTable.CellContainer>
                  {isEmpty(lane.rows) ? (
                    <RoadmapTable.RowContent onClick={!isLoading && onRowClick(lane)} />
                  ) : (
                    lane.rows &&
                    lane.rows.map((row, rowIndex) => {
                      const key = generateSingleGroupRowKey(orders, lane, rowIndex, groupbarOffset, sidebarOffset);

                      return (
                        <RoadmapTable.RowContent
                          className={lane.key === 'milestoneTopLane' ? MilestoneDndBoundsName : ''}
                          key={key}
                          onClick={!isLoading && onRowClick(lane)}
                        >
                          {row.map(
                            (task, taskIndex) =>
                              shouldShowBar(task) && (
                                <Bar
                                  key={`${task.id}-${taskIndex}`}
                                  {...task}
                                  fromDate={fromDate}
                                  mode={mode}
                                  isMilestoneGroupTopLane={lane.key === 'milestoneTopLane'}
                                  onDoubleClick={onDoubleClick}
                                  onDragStop={onDrag(task, laneIndex, rowIndex)}
                                  onResizeStop={onResize(task, laneIndex, rowIndex)}
                                  isLoading={isLoading}
                                  showTooltip={showTooltip}
                                  showTextOverflow={showTextOverflow}
                                  resizable={resizable}
                                  draggable={draggable}
                                  slotWidth={slotWidth}
                                  snapToGridOn={snapToGridOn}
                                  barRenderer={barRenderer}
                                />
                              ),
                          )}
                        </RoadmapTable.RowContent>
                      );
                    })
                  )}
                </RoadmapTable.CellContainer>
              </RoadmapTable.Cell>
            </RoadmapTable.Row>
          ))}
          <DndPlayground
            className={DndBoundsName}
            style={{
              top: displayMilestone && displayMilestoneOn === 'topLane' ? ROW_HEIGHT : 0,
              left: groupbarWidth,
              width: `calc(100% - ${groupbarWidth}px)`,
              height: `calc(100% - ${displayMilestone && displayMilestoneOn === 'topLane' ? ROW_HEIGHT : 0}px)`,
            }}
          />
        </>
      )}

      <Today todayLine={todayLine} />
    </RoadmapTable.Body>
  );
};

export default TimelineBody;

const DndPlayground = styled.div`
  &&&& {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    top: 0;
    z-index: -1;
  }
`;
