import React from 'react';
import styled from 'styled-components';

import TextDeprecated from 'design-system/atoms/TextDeprecated';
import theme from 'design-system/theme';
import formatDate from 'utils/dates/formatDate';

export default ({ item, isVisible, ...props }) => (
  <StyledText color={theme.palette.newLayout.background.darkGray} size="small" isVisible={isVisible} {...props}>
    Updated {formatDate(item.updated_at, true)}
  </StyledText>
);

const StyledText = styled(TextDeprecated)`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.2s;
`;
