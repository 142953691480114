import { useCallback, useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';

import { findParentWithLevel } from 'design-system/molecules/AgGridReact-New/helpers';

import { takeGridSnapshot } from '../helpers';

const direction = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

const moveInArray = (array, fromIndex, toIndex) => {
  const arrayCopy = array.slice();

  const [elementToMove] = arrayCopy.splice(fromIndex, 1);

  arrayCopy.splice(toIndex, 0, elementToMove);

  return arrayCopy;
};

const isUsingTreeData = movingNode => movingNode.data?.path;

const pathIsTheSame = (path1, path2) => path1.every((path1Part, index) => path1Part === path2[index]);

const getPathLastValue = node => {
  return node.data.path[node.data.path.length - 1];
};

const updateNodePath = (node, newPath, api) => {
  node.data.path = newPath;

  const dataToUpdate = [node.data];

  if (node.allChildrenCount) {
    const updatedChildrenData = (node.childrenAfterGroup || []).map(movingNodeChild => {
      movingNodeChild.data.path = [...node.data.path, getPathLastValue(movingNodeChild)];

      return movingNodeChild.data;
    });

    dataToUpdate.push(...updatedChildrenData);
  }

  api.applyTransaction({
    update: dataToUpdate,
  });

  api.clearFocusedCell();
};

/**
 * Updates the nodes' information and returns
 * the index the moving node needs to be moved to.
 */
const updateNodes = (movingNode, overNode, ids, api, idKey) => {
  if (!overNode) {
    return ids.length - 1;
  }

  if (!isUsingTreeData(movingNode)) {
    return ids.indexOf(overNode.data[idKey]);
  }

  if (movingNode?.level > overNode?.level) {
    const movingNodeLastPathItem = getPathLastValue(movingNode);

    const newPath = [...overNode.data.path, movingNodeLastPathItem];

    updateNodePath(movingNode, newPath, api);
  } else if (overNode?.level === movingNode?.level) {
    const movingNodeAllPathsButLast = movingNode.data.path.slice(0, -1);

    const overNodeAllPathsButLast = overNode.data.path.slice(0, -1);

    if (!pathIsTheSame(movingNodeAllPathsButLast, overNodeAllPathsButLast)) {
      const movingNodeLastPathItem = getPathLastValue(movingNode);

      const newPath = [...overNodeAllPathsButLast, movingNodeLastPathItem];

      updateNodePath(movingNode, newPath, api);
    }

    return ids.indexOf(overNode.data[idKey]);
  } else if (movingNode.level < overNode.level) {
    const overNodeParentOfTheSameLevelAsTheMovingNode = findParentWithLevel(overNode, movingNode.level);

    const movingNodeAllPathsButLast = movingNode.data.path.slice(0, -1);

    // eslint-disable-next-line max-len
    const overNodeParentOfTheSameLevelAsTheMovingNodeAllPathsButLast = overNodeParentOfTheSameLevelAsTheMovingNode.data.path.slice(
      0,
      -1,
    );

    if (!pathIsTheSame(movingNodeAllPathsButLast, overNodeParentOfTheSameLevelAsTheMovingNodeAllPathsButLast)) {
      const movingNodeLastPathItem = movingNode.data.path[movingNode.data.path.length - 1];

      const newPath = [...overNodeParentOfTheSameLevelAsTheMovingNodeAllPathsButLast, movingNodeLastPathItem];

      updateNodePath(movingNode, newPath, api);
    }

    return ids.indexOf(overNodeParentOfTheSameLevelAsTheMovingNode.data[idKey]) + 1;
  }
};

const useRowDrag = ({ items, dragAction, idKey = 'id' }) => {
  const rowsBeforeDrag = useRef([]);
  const lastOverNode = useRef(null);
  const positionToMove = useRef(null);

  const rowDataRef = useRef(items);
  const movedToIndexRef = useRef(-1);

  const onRowDragEnter = useCallback(({ api, node }) => {
    rowsBeforeDrag.current = takeGridSnapshot(api);
    lastOverNode.current = null;
    positionToMove.current = null;
    movedToIndexRef.current = -1;

    try {
      node.setExpanded(false);
    } catch (error) {
      console.warn('An error occurred while trying to collapse a node ', error);
    }
  }, []);

  const onRowDragMove = useCallback(
    ({ node: movingNode, overNode, api }) => {
      if (isEmpty(rowsBeforeDrag.current) || !movingNode.data) {
        return;
      }

      const rowNeedsToMove = movingNode !== overNode;

      if (!rowNeedsToMove) {
        return;
      }

      const ids = rowDataRef.current.map(row => row[idKey]);

      const fromIndex = ids.indexOf(movingNode.data[idKey]);

      const toIndex = updateNodes(movingNode, overNode, ids, api, idKey);

      if (toIndex !== null) {
        if (movingNode.data.path) {
          const newRowData = moveInArray(rowDataRef.current, fromIndex, toIndex);

          rowDataRef.current = newRowData;

          api.setRowData(newRowData);
        }

        movedToIndexRef.current = toIndex;

        positionToMove.current = fromIndex > toIndex ? direction.TOP : direction.BOTTOM;
      }

      lastOverNode.current = overNode;
    },
    [rowsBeforeDrag.current, idKey, rowDataRef.current],
  );

  const onRowDragEnd = useCallback(
    event => {
      const movingNodeId = event.node.data[idKey];

      const itemsIds = items.map(row => row[idKey]);

      const indexOnItems = itemsIds.indexOf(movingNodeId);

      const changedPosition = movedToIndexRef.current !== indexOnItems;

      if (dragAction && lastOverNode.current && changedPosition) {
        dragAction(event.node, lastOverNode.current, {
          position: positionToMove.current,
        });
      }
    },
    [dragAction, lastOverNode.current, items, idKey],
  );

  const onRowDragLeave = useCallback(
    ({ api }) => {
      api.setRowData(items);
    },
    [items],
  );

  useEffect(() => {
    rowDataRef.current = items;
  }, [items]);

  return {
    onRowDragEnter,
    onRowDragMove,
    onRowDragEnd,
    onRowDragLeave,
  };
};

export default useRowDrag;
