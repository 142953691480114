import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import { loadScenarioFromQueryParam } from 'store/roadmapVersions';
import { getSelectedRoadmapVersion, selectIsRoadmapVersionsLoaded } from 'store/roadmapVersions/selectors';

import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/userViews';
import addQueryParamToUrl from 'utils/roadmapVersions/addQueryParamToUrl';

const useLoadScenario = () => {
  const dispatch = useDispatch();

  const selectedRoadmapVersion = useSelector(getSelectedRoadmapVersion);

  const isRoadmapVersionsLoaded = useSelector(state => selectIsRoadmapVersionsLoaded(state));

  const path = getCurrentPath();
  const pageId = getPageIdFromPath(path);

  const _getScenarioIdOnQueryParam = useCallback(() => {
    const queryParam = queryString.parse(window.location.search);

    return queryParam?.scenario;
  }, [window.location.search]);

  useEffect(() => {
    if (selectedRoadmapVersion?.id) {
      addQueryParamToUrl(selectedRoadmapVersion);
    }
  }, [selectedRoadmapVersion?.id]);

  useEffect(() => {
    if (isRoadmapVersionsLoaded) {
      const scenarioIdOnQueryParam = _getScenarioIdOnQueryParam();

      dispatch(loadScenarioFromQueryParam(scenarioIdOnQueryParam, pageId));
    }
  }, [_getScenarioIdOnQueryParam, isRoadmapVersionsLoaded]);
};

export default useLoadScenario;
