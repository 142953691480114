import React from 'react';
import { defaultTo } from 'ramda';

import ReactBubbleChart from 'design-system/organisms/ReactBubbleChart';
import useSystemFields from 'hooks/useSystemFields';
import BaseLayout from 'design-system/organisms/BaseLayout/index';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';

import { UNDEFINED_LABEL } from 'constants/common';
import RequestsGridModal from 'features/RequestsGridModal';

import useClustersDataForAnalyzeChart from './hooks/useClustersDataForAnalyzeChart';
import { BUBBLE_SIZE_BY_OPTIONS } from './utils';
import { ChartContainer, Wrapper } from './styles';
import useLoadCustomersRequestsForCluster from './hooks/useLoadCustomersRequestsForCluster';
import useClustersEventsOnAnalyze from './hooks/useClustersEventsOnAnalyze';
import AnalyzeToolbar from './AnalyzeToolbar';
import useLoadCustomerRequests from 'hooks/customerRequests/useLoadCustomerRequests';
import AnalyzeSummaryPanel from './AnalyzeSummaryPanel';
import useClustersByOptions from './hooks/useClustersByOptions';

const defaultAsUndefinedTitle = defaultTo(UNDEFINED_LABEL);

const tooltipProps = [
  {
    css: 'symbol',
    prop: 'data.text',
  },
];

const AnalyzeComponent = () => {
  const [getSystemFieldName] = useSystemFields();

  useLoadCustomerRequests();

  const { data: clustersData, isUninitialized, selectedClusterBy, selectedBubbleSizeBy } = useClustersDataForAnalyzeChart();
  const { data: customerRequests, fetchCustomerRequests } = useLoadCustomersRequestsForCluster(selectedClusterBy?.key);

  const {
    selectedCluster,
    showGridLightbox,

    handleCloseGridLightbox,
    handleClusterByChange,
    handleBubbleSizeByChange,
    handleBubbleClick,
    handleClusterItemClick,
  } = useClustersEventsOnAnalyze({ fetchCustomerRequests });

  const { clusterByOptions } = useClustersByOptions();

  const optionsForBubbleSizeBy = BUBBLE_SIZE_BY_OPTIONS.map(o => ({
    ...o,
    title: getSystemFieldName(o.title),
  }));

  if (isUninitialized) {
    return <PageLoading />;
  }

  return (
    <BaseLayout
      toolbarComponent={
        <AnalyzeToolbar
          clusterByOptions={clusterByOptions}
          clusterByValue={selectedClusterBy}
          handleClusterByChange={handleClusterByChange}
          bubbleSizeByOptions={optionsForBubbleSizeBy}
          bubbleSizeByValue={selectedBubbleSizeBy}
          handleBubbleSizeByChange={handleBubbleSizeByChange}
        />
      }
    >
      <Wrapper>
        <AnalyzeSummaryPanel onClusterClick={handleClusterItemClick} />
        <ChartContainer>
          <ReactBubbleChart
            className="chart__bubble"
            data={clustersData}
            tooltip
            tooltipProps={tooltipProps}
            onClick={handleBubbleClick}
          />
        </ChartContainer>
      </Wrapper>
      <RequestsGridModal
        customerRequests={customerRequests}
        title={selectedCluster && defaultAsUndefinedTitle(selectedCluster.title)}
        isOpen={showGridLightbox}
        handleClose={handleCloseGridLightbox}
      />
    </BaseLayout>
  );
};

export default AnalyzeComponent;
