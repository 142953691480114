import React from 'react';
import styled from 'styled-components';

import { BaseGrid } from 'containers/Grids';
import { BASE_ROW_HEIGHT } from 'constants/grid';
import useCommonGridProps from 'design-system/molecules/AgGridReact-New/hooks/useCommonGridProps';
import { ID_KEY } from 'routes/CustomerRequests/Grid/New/constants';
import { useCommonGridEventHandlers } from 'design-system/molecules/AgGridReact-New/hooks';

import useSnapshotTableComponentGridColumns from '../../hooks/useSnapshotTableComponentGridColumns';
import { getRowId } from 'routes/Dashboard/Snapshot/OkrTable/helpers';

const GeneralTable = ({
  onGridReady,
  rowData,
  tableHeight,
  rowHeight,
  withPagination = true,
  pageSize,
  title,
  displayLayer,
  onUpdateMetadataFieldById,
}) => {
  const commonGridEvents = useCommonGridEventHandlers({
    items: rowData,
    idKey: ID_KEY,
    actions: {
      update: onUpdateMetadataFieldById,
    },
  });

  const commonGridProps = useCommonGridProps({});
  const { columnDefs, defaultColDef } = useSnapshotTableComponentGridColumns(title, displayLayer);

  return (
    <Wrapper>
      <BaseGrid
        {...commonGridEvents}
        {...commonGridProps}
        rowData={rowData}
        columnDefs={columnDefs}
        onGridReady={onGridReady}
        defaultColDef={defaultColDef}
        height={`${tableHeight + 115}px`}
        rowHeight={rowHeight}
        pagination={withPagination}
        paginationPageSize={Number(pageSize)}
        suppressPaginationPanel={!withPagination}
        headerHeight={BASE_ROW_HEIGHT}
        getRowId={getRowId}
        enableSorting
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ag-layout-normal.ag-root {
    margin: 0;
  }
`;

export default GeneralTable;
