import React from 'react';
import { useSelector } from 'react-redux';

import { getOrganization } from 'store/organization/selectors';

export default Component => {
  return props => {
    const organization = useSelector(state => getOrganization(state));

    return <Component {...props} organization={organization} />;
  };
};
