import { useSelector } from 'react-redux';

import { getCustomerRequestsCustomFields } from 'store/customFields/selectors';
import useSystemFields from 'hooks/useSystemFields';

const useHideableOptions = () => {
  const [getSystemFieldName] = useSystemFields();
  const customFields = useSelector(getCustomerRequestsCustomFields);

  const customOptions = customFields.map(customField => {
    return {
      id: customField.key,
      name: customField.title,
    };
  });

  const hideableOptions = [
    ...customOptions,
    {
      id: 'comments',
      name: 'Comments',
    },
    {
      id: 'created_at',
      name: 'Created at',
    },
    {
      id: 'created_by',
      name: 'Created by',
    },
    {
      id: 'customers',
      name: getSystemFieldName('customer'),
    },
    {
      id: 'desired_time',
      name: 'Desired Time',
    },
    {
      id: 'files',
      name: 'Files',
    },
    {
      id: 'ideas',
      name: 'Ideas',
    },
    {
      id: 'links',
      name: 'Links',
    },
    {
      id: 'owner',
      name: 'Owner',
    },
    {
      id: 'parent',
      name: 'Merged Under',
    },
    {
      id: 'priority',
      name: getSystemFieldName('priority'),
    },
    {
      id: 'product1',
      name: getSystemFieldName('product1'),
    },
    {
      id: 'roadmap',
      name: getSystemFieldName('roadmap'),
    },
    {
      id: 'status',
      name: 'Status',
    },
    {
      id: 'tags',
      name: getSystemFieldName('tags'),
    },
    {
      id: 'timeframe',
      name: getSystemFieldName('timeframe'),
    },
    {
      id: 'updated_at',
      name: 'Updated At',
    },
    {
      id: 'votes',
      name: 'Votes',
    },
    {
      id: 'watchers',
      name: 'Watchers',
    },
  ];

  return [hideableOptions];
};

export default useHideableOptions;
