import React from 'react';
import { TextWrapper } from 'design-system/molecules/AgGridReact-New/cellRenderers/TitleCellRenderer';

const defaultGetNumberOfTextRowsToDisplayOnGridCell = () => 1;

const TextCellRenderer = ({ value, getNumberOfTextRowsToDisplayOnGridCell = defaultGetNumberOfTextRowsToDisplayOnGridCell }) => {
  return <TextWrapper $nOfTextRowsToDisplay={getNumberOfTextRowsToDisplayOnGridCell()}>{value}</TextWrapper>;
};

export default TextCellRenderer;
