import React from 'react';
import styled from 'styled-components';
import { path, defaultTo, pipe } from 'ramda';

import Popover from '@material-ui/core/Popover';

import Actions from './Actions';
import Header from './Header';
import { DRAWER_FOOTER_HEIGHT, DRAWER_ACTION_BAR } from './consts/layout';

// this is all the content that must be considered for the body height calculation
const LIGHTBOX_MARGIN_Y = 96;
const DRAWER_HEADER_HEIGHT = 92;
const DRAWER_BODY_PADDING = 48;

const TOTAL_BARS_HEIGHT =
  LIGHTBOX_MARGIN_Y + DRAWER_HEADER_HEIGHT + DRAWER_FOOTER_HEIGHT + DRAWER_ACTION_BAR + DRAWER_BODY_PADDING;

const defaultEmptyObj = defaultTo({});
const defaultToZero = defaultTo(0);

const getOffsetParent = pipe(defaultEmptyObj, path(['current', 'offsetParent']), defaultEmptyObj);
const getOffsetLeft = pipe(defaultEmptyObj, path(['offsetLeft']), defaultToZero);
const getOffsetWidth = pipe(defaultEmptyObj, path(['offsetWidth']), defaultToZero);
const getWindowWidth = pipe(defaultEmptyObj, path(['current', 'ownerDocument', 'body', 'offsetWidth']), defaultToZero);

const MetadataPopoverComponent = ({
  isOpen,
  anchorEl,
  onClose,
  metadataSelected,
  contentComponent,
  onSettingsLinkClicked,
  metadataSelectedNotes,
  updateNote,
  isAllowedToWriteMetadataNotes,
}) => {
  const offsetParent = getOffsetParent(anchorEl);

  const marginLeft = getOffsetLeft(offsetParent);
  const parentWidth = getOffsetWidth(offsetParent);
  const windowWidth = getWindowWidth(anchorEl);

  const marginRight = windowWidth - (parentWidth + marginLeft);

  const okrDrawerWidth = windowWidth * 0.7;

  const metadataPopoverWidth = okrDrawerWidth - marginRight;

  return (
    <StyledPopover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl?.current?.parentElement || null}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      metadataPopoverWidth={metadataPopoverWidth}
    >
      <Header
        onClose={onClose}
        metadataSelected={metadataSelected}
        metadataSelectedNotes={metadataSelectedNotes}
        updateNote={updateNote}
        isAllowedToWriteMetadataNotes={isAllowedToWriteMetadataNotes}
        height={DRAWER_HEADER_HEIGHT}
      />
      <BodyContainer>
        {contentComponent && contentComponent({ height: `calc(100vh - ${TOTAL_BARS_HEIGHT}px`, actionsPadding: '5px 0' })}
      </BodyContainer>
      <FooterContainer>
        <Actions onClose={onClose} onSettingsLinkClicked={onSettingsLinkClicked} />
      </FooterContainer>
    </StyledPopover>
  );
};

export default MetadataPopoverComponent;

// We need to force the transform=none since inside the popover we have a drag and drop component that can't be affected by it
const StyledPopover = styled(Popover)`
  &&&& {
    div[role='document'] {
      width: ${({ metadataPopoverWidth }) => metadataPopoverWidth || 0}px; // this should match the OKR drawer with
      height: calc(100% - 96px);
      transform: none !important;

      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

const BodyContainer = styled.div`
  &&&& {
    height: calc(100% - ${DRAWER_HEADER_HEIGHT + DRAWER_FOOTER_HEIGHT}px);
    padding: 0 24px;
    overflow: hidden;

    div[role='grid'] {
      margin: 0;
    }
  }
`;

const FooterContainer = styled.div`
  &&&& {
    height: ${DRAWER_FOOTER_HEIGHT}px;
    padding: 10px 16px;
  }
`;
