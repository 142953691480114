import React from 'react';
import { useSelector } from 'react-redux';

import { ACTIVE_STATUS } from 'constants/common';

import { getObjectives } from 'store/objectives/selectors';

export default ({ selectedObjectiveTitle, selectedKeyResult1Title, selectedKeyResult2Title }) => {
  const objectives = useSelector(state => getObjectives(state));
  const hasKeyResults = useSelector(state => state.organization.organization.has_key_results);
  const hasKeyResults2 = useSelector(
    state => state.organization.organization.has_key_results && state.organization.organization.has_key_results_2,
  );

  const _getObjectsKeyResults = (parents = []) => {
    const keyResults = parents.reduce((keyResults, parent) => {
      const parentKeyResults = parent?.keyResults || [];

      return [...keyResults, ...parentKeyResults];
    }, []);

    return keyResults.filter(keyResult => keyResult.status !== 'Archived');
  };

  // if a objective is selected, filter the keyResults to just that objective
  const keyResults1 = React.useMemo(() => {
    let relevantObjectives;

    if (selectedObjectiveTitle && objectives) {
      relevantObjectives = objectives.filter(r => r.title === selectedObjectiveTitle) || [];
    } else {
      relevantObjectives = objectives || [];
    }

    return _getObjectsKeyResults(relevantObjectives);
  }, [selectedObjectiveTitle, objectives]);

  // if a objective is selected, filter the keyResults to just that objective
  const keyResults2 = React.useMemo(() => {
    let relevantParents;

    if (selectedKeyResult1Title && keyResults1) {
      relevantParents = keyResults1.filter(r => r.title === selectedKeyResult1Title) || [];
    } else {
      relevantParents = keyResults1 || [];
    }

    return _getObjectsKeyResults(relevantParents);
  }, [selectedKeyResult1Title, objectives, keyResults1]);

  const okrSelectedArchived = React.useMemo(() => {
    const isObjectiveArchived =
      selectedObjectiveTitle && objectives.find(obj => obj.title === selectedObjectiveTitle)?.status !== ACTIVE_STATUS;
    const isKeyResult1Archived =
      selectedKeyResult1Title &&
      keyResults1?.length &&
      keyResults1.find(obj => obj.title === selectedKeyResult1Title)?.status !== ACTIVE_STATUS;
    const isKeyResult2Archived =
      selectedKeyResult2Title &&
      keyResults2?.length &&
      keyResults2.find(obj => obj.title === selectedKeyResult2Title)?.status !== ACTIVE_STATUS;

    return !!(isObjectiveArchived || isKeyResult1Archived || isKeyResult2Archived);
  }, [selectedObjectiveTitle, selectedKeyResult1Title, selectedKeyResult2Title, objectives, keyResults1, keyResults2]);

  return { objectives, hasKeyResults, hasKeyResults2, keyResults1, keyResults2, okrSelectedArchived };
};
