import { flatten } from 'ramda';
import { columnToMetadataMap } from 'features/RoadmapHistory/store/helpers/projects';

const HIGHLIGHT_CLASS = 'cellHighlighted';

const getFieldsFromColumnsToCompare = highlightField => flatten(columnToMetadataMap[highlightField?.key] || highlightField?.key);

const projectHasChangesOnFields = (fieldsChangedByProjectId, projectId, fieldsFromColumnsToCompare) => {
  return fieldsChangedByProjectId?.[projectId]?.some(changedField => fieldsFromColumnsToCompare?.includes(changedField));
};

export const getIsSnapFieldHighlighted = ({
  context: { changedFieldsByProjectBySnapDiff, highlightField } = {},
  data: { id: projectId } = {},
  colDef,
} = {}) => {
  if (!highlightField?.key || !colDef?.field.includes(highlightField?.key)) return '';

  const fieldsFromColumnsToCompare = getFieldsFromColumnsToCompare(highlightField);

  const hasChangeToHighlight = Object.values(changedFieldsByProjectBySnapDiff)?.some(diff => {
    const { changedFieldsByProjectId = {} } = diff;

    const hasChangeToHighlight = projectHasChangesOnFields(changedFieldsByProjectId, projectId, fieldsFromColumnsToCompare);

    return hasChangeToHighlight;
  });

  return hasChangeToHighlight ? HIGHLIGHT_CLASS : '';
};

export const getTitleIsHighlighted = ({
  context: { changedFieldsByProjectBySnapDiff, highlightField } = {},
  data: { id: projectId } = {},
} = {}) => {
  if (!highlightField?.key) return '';

  const fieldsFromColumnsToCompare = getFieldsFromColumnsToCompare(highlightField);

  const hasChangeToHighlight = Object.values(changedFieldsByProjectBySnapDiff)?.some(diff => {
    return projectHasChangesOnFields(diff?.changedFieldsByProjectId, projectId, fieldsFromColumnsToCompare);
  });

  return hasChangeToHighlight ? HIGHLIGHT_CLASS : '';
};

export const getCurrentIsHighlighted = ({
  context: { changedFieldsByProjectBySnapDiff, highlightField } = {},
  data: { id: projectId } = {},
  colDef,
} = {}) => {
  if (!highlightField?.key || colDef?.field !== highlightField?.key) return '';

  const fieldsFromColumnsToCompare = getFieldsFromColumnsToCompare(highlightField);

  const hasChangeToHighlight = Object.values(changedFieldsByProjectBySnapDiff)?.some(diff => {
    return projectHasChangesOnFields(diff?.changedFieldsByProjectId, projectId, fieldsFromColumnsToCompare);
  });

  return hasChangeToHighlight ? HIGHLIGHT_CLASS : '';
};
