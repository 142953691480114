import React from 'react';
import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';

export default styled(props => {
  return (
    <TableCell
      component="div"
      variant="body"
      {...Object.keys(props).reduce((acc, k) => {
        if (!['centered', 'editable', 'isSelected', 'isChild', 'isParent', 'isDragDisabled', 'editing'].includes(k))
          acc[k] = props[k];
        return acc;
      }, {})}
    />
  );
})`
  &&&& {
    position: relative;
    flex: 1;
    cursor: default;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    width: 100%;
    overflow: hidden;

    padding: 4px 8px 4px 12px;

    color: rgb(107, 107, 107);
    font-size: 12px;

    border-bottom: 1px solid #efefef;

    transition: width ease-in-out 0.15s;

    ${props =>
      props.centered &&
      `
      justify-content: center;
      padding: 4px;
    `}

    ${props =>
      props.editable &&
      `
      cursor: text;
    `}

    ${props =>
      props.isSelected &&
      `
      border: 1px solid rgb(0, 157, 255);
      padding: 3px 7px 3px 11px;
    `}

    ${props =>
      props.variant === 'head' &&
      `
      font-weight: bold;
    `}

    ${props =>
      props.isChild &&
      `
      padding-left: 48px;
    `}
  }
`;
