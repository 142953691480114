import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { getTags } from 'store/tags/selectors';
import {
  createTag,
  updateTagById,
  mergeTags,
  fetchTags,
  removeUnsavedTags,
  addTagWithoutSave,
  createTags,
  updateTags,
  bulkDeleteTags,
  deleteTag,
  createTagRoadmap,
  bulkDeleteTagRoadmaps,
  deleteTagRoadmap,
} from 'store/tags';

const useTags = hideArchivedItems => {
  const dispatch = useDispatch();

  const tags = useSelector(state => getTags(state, !hideArchivedItems));

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          fetchTags,
          createTag,
          createTags,
          updateTags,
          bulkDeleteTags,
          addTagWithoutSave,
          removeUnsavedTags,
          updateTagById,
          deleteTag,
          mergeTags,
        },
        dispatch,
      ),
    [dispatch],
  );

  return {
    tags,
    createTagRoadmap,
    deleteTagRoadmap,
    bulkDeleteTagRoadmaps,
    ...boundActionCreators,
  };
};

export default useTags;
