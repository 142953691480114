import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { both, pipe, isEmpty, not, path, either, isNil, prop } from 'ramda';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

import { restoreOrgIntegrationData, enableIntegrationWebhooks } from 'store/organization';
import { getOrganization } from 'store/organization/selectors';

import useIntegrations from 'hooks/useIntegrations';
import useUserIntegrations from 'hooks/useUserIntegrations';
import useAppNotifications from 'hooks/useAppNotifications';

import CreateOrgIntegration from '../CreateOrgIntegration';
import CreateOAuthClientCredentials from '../CreateOAuthClientCredentials';

const isNotNilOrEmpty = pipe(either(isNil, isEmpty), not);
const getOrgIntegrationUri = path(['data', 'uri']);

const hasNotWebhooksCreated = pipe(path(['data', 'webhooks']), both(Array.isArray, pipe(isEmpty, not)), not);
const hasOrgIntegrationCredentials = pipe(prop('org_integration_credentials_id'), isNotNilOrEmpty);

const IntegrationSteps = props => {
  const { orgIntegration, userIntegration, isAddingOrgIntegration } = props;

  const dispatch = useDispatch();

  const organization = useSelector(getOrganization);

  const { addOrgIntegration, storeOrgIntegrationOauthCredentials } = useIntegrations(INTEGRATIONS_KEYS.rally, orgIntegration?.id);

  const { addUserIntegrationByAuthType, fetchUserIntegrations } = useUserIntegrations(
    INTEGRATIONS_KEYS.rally,
    orgIntegration?.id,
  );
  const { addNotification } = useAppNotifications();

  const shouldShowOrganizationIntegrationStep = !orgIntegration && isAddingOrgIntegration;
  const shouldShowOauthClientSetup = orgIntegration && !userIntegration && not(hasOrgIntegrationCredentials(orgIntegration));

  const handleCreateOrganization = useCallback(
    (uri, workspaceRef) => {
      addOrgIntegration({ uri, workspaceRef });
    },
    [addOrgIntegration],
  );

  const handleStoreOrgIntegrationOauthCredentials = useCallback(
    async (clientId, clientSecret) => {
      if (clientId && clientSecret) {
        await storeOrgIntegrationOauthCredentials({ clientId, clientSecret });
      }
    },
    [storeOrgIntegrationOauthCredentials, orgIntegration?.id],
  );

  const OnAddUserIntegration = useCallback(async () => {
    await fetchUserIntegrations();

    if (hasNotWebhooksCreated(orgIntegration)) {
      dispatch(enableIntegrationWebhooks(INTEGRATIONS_KEYS.rally, organization.id, orgIntegration.id)).then(() => {
        addNotification({ id: 'rally-webhooks-enabled-successfully', message: 'Webhooks enabled successfully' });
      });
    }

    if (orgIntegration.id && orgIntegration.needs_restore) {
      dispatch(restoreOrgIntegrationData(orgIntegration.id));
    }
  }, [orgIntegration?.id, organization, orgIntegration?.needs_restore]);

  useEffect(() => {
    const handleOauth2UserIntegration = async () => {
      if (!orgIntegration || not(hasOrgIntegrationCredentials(orgIntegration))) return;

      await addUserIntegrationByAuthType('oauth2')
        .then(() => {
          addNotification({ id: 'rally-successfully-integrated', message: 'Successfully integrated' });

          OnAddUserIntegration();
        })
        .catch(() => {
          addNotification({ id: 'rally-integration-failed', message: 'Integration failed' });
        });
    };

    if (userIntegration) return;

    handleOauth2UserIntegration();
  }, [userIntegration, orgIntegration?.id, organization, orgIntegration?.org_integration_credentials_id]);

  return (
    <React.Fragment>
      {shouldShowOrganizationIntegrationStep && <CreateOrgIntegration onClickNext={handleCreateOrganization} />}
      {shouldShowOauthClientSetup && (
        <CreateOAuthClientCredentials
          onClickConnect={handleStoreOrgIntegrationOauthCredentials}
          uri={getOrgIntegrationUri(orgIntegration)}
        />
      )}
    </React.Fragment>
  );
};

export default IntegrationSteps;
