export const ALLOCATION_PERCENTAGE_COLS = ['showCurrentAllocationPercentage', 'showTargetAllocationPercentage'];

export const TOTAL_ROW_ID = 'TOTAL';

export const TOTAL_ROW_LABEL = 'Total';

export const GRID_HEIGHT_OFFSET = 100;

export const DEFAULT_COL_WIDTH = 165;

export const DEFAULT_DRAWER_COL_WIDTH = 110;

export const TITLE_COL_WIDTH = 250;

export const TITLE_DRAWER_COL_WIDTH = 125;

export const HEALTH_COL_WIDTH = 40;
