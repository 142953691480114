import React, { Component, Fragment } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { bool, func, number, string } from 'prop-types';
import { toast } from 'react-toastify';
import sortBy from 'lodash/sortBy';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import styled from 'styled-components';
import { not } from 'ramda';

import CloseButton from 'components/DialogTitle/CloseButton';
import LightBox from 'components/LightBox';
import ImportJQLStepOne from 'containers/ImportIdeasDialog/ImportFromJQL/ImportJQLStepOne';
import MapImportDataFields from 'containers/MapImportDataFields';
import { DEFAULT_FIELDS_MAPPING } from 'containers/ImportIdeasDialog/ImportFromJQL/utils';
import IdeasCopyPasteLightbox from 'containers/IdeasCopyPasteLightbox';
import onRowSelectedFactory from 'utils/onRowSelectedFactory';
import afterProcessClipboardDataFactory from 'components/DataGrid/afterProcessClipboardDataFactory';
import { getMappingByJiraTicketId, hasIntegrationProjectsMapping } from 'utils/mapping';
import Text from 'design-system/atoms/Text';
import { spacing } from 'design-system/theme';

import { FIELDS_MAPPING_TYPES_FOR_IMPORT, IMPORT_JQL_PREVIEW_MESSAGE } from 'constants/integrations';

import IntegrationPermissionsDialog from 'routes/Settings/Integration/components/IntegrationPermissionsDialog';
import { UPDATE_JIRA_RECURRING_IMPORT_QUERY } from 'routes/Settings/Integration/constants/validationDialogActionTypes';

export default class RecurringJQLReviewModal extends Component {
  propTypes = {
    isOpen: bool,
    onClose: func,
    searchForTasksByJiraUrl: func,
    updateJiraConfigMisc: func,
    updateJiraIntegration: func,
    step: number,
    recurringJQLQuery: string,
  };

  state = {
    currentStep: 0,
    loading: false,
    searched: false,
    totalTasks: 0,
    fields: [],
    mappedFields: [],
    jiraUrl: '',
    jiraErrors: null,
    jiraData: [],
    selectedItems: [],
    selectedFieldsMappingType: '',
    hasSavedMappingForJiraProjects: false,
    selectedOrgIntegration: null,
    validatePermissionsDialogOpen: false,
  };

  constructor(props) {
    super(props);

    this.state.jiraUrl = this.props.recurringJQLQuery || '';

    this.initialState = { ...this.state };

    this.bulkCreateAndUpdateData = afterProcessClipboardDataFactory(this.props.bulkCreateProjects, this.props.bulkUpdateProjects);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.jiraUrl !== this.props.jiraUrl) {
      this.setState({
        jiraUrl: this.props.jiraUrl,
      });
      this.initialState = {
        ...this.initialState,
        jiraUrl: this.props.jiraUrl,
      };
    }
  }

  isSavedFieldMappingSelected = () => {
    const { hasJiraFieldMappingByProject } = this.props;
    const { selectedFieldsMappingType } = this.state;

    return hasJiraFieldMappingByProject || selectedFieldsMappingType === FIELDS_MAPPING_TYPES_FOR_IMPORT.SAVED_MAPPING;
  };

  searchForTasks = jiraUrl => {
    const { searchForTasksByJiraUrl, orgIntegrations } = this.props;
    const orgIntegration = orgIntegrations.find(i => jiraUrl.includes(i.data.base_url));

    if (!orgIntegration) return;

    const jiraConfig = orgIntegration.data;

    this.setState({ loading: true, jiraErrors: null, selectedOrgIntegration: orgIntegration.id });
    return searchForTasksByJiraUrl(orgIntegration.id, jiraUrl)
      .then(resp => {
        // success
        if (resp && resp.data) {
          const { total, fields } = resp.data;
          const mappedFields = getMappingByJiraTicketId(jiraConfig).map(f => ({
            ...f,
            col: fields ? (fields.find(j => j.key === f.jiraKey) || {}).name : null,
            deletedOnJira: fields && !fields.find(j => j.key === f.jiraKey),
          }));

          this.setState({
            loading: false,
            searched: true,
            totalTasks: total,
            fields,
            mappedFields,
            jiraUrl,
            orgIntegration,
            hasSavedMappingForJiraProjects: hasIntegrationProjectsMapping(jiraConfig),
          });
          // error
        }
      })
      .catch(err => {
        const error = err.response.data;
        let jiraErrors = null;

        if (error.error_code === 'INVALID_JIRA_REQUEST') {
          jiraErrors = error._data;
        } else if (error.error_code === 'JIRA_MISSING_JQL_ON_URL') {
          jiraErrors =
            'Enter your Jira URL containing the JQL. Please do not use saved Jira filter, and use Jira issue search instead.';
        }

        toast('Error fetching the jira EPICs');
        this.setState({
          loading: false,
          totalTasks: 0,
          fields: [],
          jiraErrors,
        });
      });
  };

  saveMappedFields() {
    const { fields, mappedFields, jiraUrl, orgIntegration } = this.state;
    const { previewTasksByJiraUrl } = this.props;

    if (!orgIntegration) return;

    const finalFields = this.isSavedFieldMappingSelected()
      ? null
      : mappedFields
          .filter(d => d.col)
          .map(d => ({
            jiraKey: fields.find(f => f.name === d.col)?.key,
            jiraField: fields.find(f => f.name === d.col),
            field: d.field,
            dbAutoSync: d.dbAutoSync,
            jiraAutoSync: d.jiraAutoSync,
          }));

    this.setState({ loading: true });

    return previewTasksByJiraUrl(orgIntegration.id, jiraUrl, finalFields).then(r => {
      const { result, badValues } = r.data;

      this.setState({
        loading: false,
        totalJiraData: result,
        jiraData: result,
        defaultJiraData: result,
        notImported: result.filter(d => d.exists).length,
        jiraDataPreviewedCursor: result.length,
        cursorStart: 0,
        cursorEnd: result.length - 1,
        badValues,
      });
    });
  }

  next = () => {
    this.setState({
      currentStep: this.state.currentStep + 1,
    });
  };

  importJiraForProjects(_data) {
    const { createAllProjectsJiras } = this.props;
    const { orgIntegration } = this.state;

    // NOTE: this should be using the value from the redux store, needs refactoring
    return createAllProjectsJiras(orgIntegration.id, this.state.jiraUrl).then(result => {
      return result?.value?.data?.data?.length;
    });
  }

  import = () => {
    const { jiraData, orgIntegration } = this.state;

    const selectedData = jiraData.filter(d => this.state.selectedItems.includes(d.jiraKey));
    const dataSelectedInTable = selectedData.map(d => ({ ...d, planningStage: d.inProgress ? 'Confirmed' : 'Backlog' }));

    this.setState({
      loading: true,
    });

    return this.importJiraForProjects()
      .then(async ({ value }) => {
        const imported = value?.data?.data?.length ?? 0;

        this.setState({
          imported: this.state.imported + imported,
        });

        const epicsKeys = dataSelectedInTable.map(epic => epic.jiraKey);

        this.props.syncJiraStories(orgIntegration.id, epicsKeys, undefined, true);
      })
      .catch(err => {
        console.log({ err });
      });
  };

  handleNext = () => {
    const { currentStep } = this.state;

    switch (currentStep) {
      case 0: {
        if (this.isSavedFieldMappingSelected()) {
          this.saveMappedFields().then(() => {
            this.setState({
              currentStep: currentStep + 2,
              waitForApi: false,
            });
          });
        } else {
          this.next();
        }
        break;
      }
      case 1: {
        this.saveMappedFields().then(this.next);
        break;
      }
      case 2: {
        this.setState({ validatePermissionsDialogOpen: true });

        break;
      }
      default:
    }
  };

  onConfirmNewJQL = () => {
    const { orgIntegration } = this.state;

    Promise.all([
      this.import(),
      this.props.updateJiraIntegration(orgIntegration.id, { recurring_jql_query: this.state.jiraUrl }),
    ]).then(this.handleClose);
  };

  handlePrev = () => {
    const { currentStep } = this.state;

    if (currentStep === 2) {
      this.setState({
        jiraData: [],
        selectedItems: [],
        currentStep: this.isSavedFieldMappingSelected() ? 0 : 1,
      });
    } else {
      this.setState({
        currentStep: currentStep - 1,
      });
    }
  };

  nextButtonDisabled = () => {
    const { hasJiraFieldMappingByProject } = this.props;
    const { currentStep, jiraErrors, searched, jiraUrl, loading, hasSavedMappingForJiraProjects, selectedFieldsMappingType } =
      this.state;

    const isInvalidFieldMappingSelection =
      not(hasJiraFieldMappingByProject) && hasSavedMappingForJiraProjects && !selectedFieldsMappingType;

    if (currentStep === 0 && isInvalidFieldMappingSelection) {
      return true;
    }

    return !!jiraErrors || !searched || !jiraUrl || loading;
  };

  handleClose = () => {
    this.setState({
      ...omit(this.initialState, ['jiraUrl']),
    });
    this.props.onClose();
  };

  onItemSelected = e => {
    const select = data => {
      data = data.map(i => i.jiraKey);
      this.setState({ selectedItems: [...this.state.selectedItems, ...data] });
    };
    const unselect = data => {
      data = data.map(i => i.jiraKey);
      this.setState({ selectedItems: this.state.selectedItems.filter(i => !data.includes(i)) });
    };
    const selectEvent = onRowSelectedFactory(this, select, unselect);

    selectEvent(e);
  };

  render() {
    const { isOpen, onClose, step, getSystemFieldName, hasJiraFieldMappingByProject } = this.props;
    const {
      loading,
      totalTasks,
      searched,
      jiraUrl,
      jiraErrors,
      fields,
      mappedFields,
      jiraData,
      notImported,
      selectedOrgIntegration,
      hasSavedMappingForJiraProjects,
      selectedFieldsMappingType,
      validatePermissionsDialogOpen,
    } = this.state;

    const currentStep = step || this.state.currentStep;

    return (
      <CustomLightBox
        isOpen={isOpen}
        onClose={onClose}
        maxWidth="lg"
        big
        content={
          <Wrapper>
            <CloseButton onClick={onClose} />
            <Stepper alternativeLabel activeStep={currentStep}>
              <Step>
                <StepButton completed={currentStep > 0}>Search EPICs</StepButton>
              </Step>
              <Step>
                <StepButton completed={currentStep > 1} disabled={currentStep < 1}>
                  Map Data Fields
                </StepButton>
              </Step>
              <Step>
                <StepButton completed={currentStep > 2} disabled={currentStep < 2}>
                  Confirm Recurring Import
                </StepButton>
              </Step>
            </Stepper>
            <Content>
              {currentStep === 0 && (
                <ImportJQLStepOne
                  loading={loading}
                  totalTasks={totalTasks}
                  searched={searched}
                  onSearch={this.searchForTasks}
                  jiraUrl={jiraUrl}
                  jiraErrors={jiraErrors}
                  recurringWarningMessage
                  getSystemFieldName={getSystemFieldName}
                  orgIntegrationId={selectedOrgIntegration}
                  hasSavedMappingForJiraProjects={hasSavedMappingForJiraProjects}
                  selectedFieldsMappingType={selectedFieldsMappingType}
                  onChangeFieldsMappingType={selectedType =>
                    this.setState({
                      selectedFieldsMappingType: selectedType,
                    })
                  }
                  hasJiraFieldMappingByProject={hasJiraFieldMappingByProject}
                />
              )}
              {currentStep === 1 && (
                <MapImportDataFields
                  integrationType="jira"
                  columns={sortBy(fields, ['name'])}
                  defaultMap={DEFAULT_FIELDS_MAPPING}
                  dataMapping={mappedFields}
                  updateDataMapping={data => this.setState({ mappedFields: data })}
                />
              )}
              {currentStep === 2 && (
                <Fragment>
                  <StyledText>{IMPORT_JQL_PREVIEW_MESSAGE}</StyledText>
                  <IdeasCopyPasteLightbox
                    isOpen
                    isLightbox={false}
                    isCopyPaste={false}
                    rowData={jiraData}
                    rowsAutoheight
                    showJiraKey
                    selectMode
                    skipSelectionCheckboxes
                    height="calc(100vh - 400px)"
                    updateRowData={jiraData => this.setState({ jiraData })}
                    onRowSelected={() => {}}
                    {...pick(this.props, [
                      'productOwners',
                      'phaseTitles',
                      'roadmapsTitles',
                      'timeframesTitles',
                      'jiraIntegrated',
                      'objectiveTitles',
                      'themeTitles',
                      'categoryTitles',
                      'priorities',
                      'organization',
                      'roadmaps',
                      'objective',
                      'customFields',
                    ])}
                    hidePlanningStage
                    hideProgress
                    hideDeleteButton
                  />
                  {notImported > 0 && (
                    <InfoTypography>
                      <br />
                      This list excludes {notImported} EPICs that are already in dragonboat.
                    </InfoTypography>
                  )}
                </Fragment>
              )}
            </Content>
            {loading && <LinearProgress />}
            <Grid container>
              <Grid item xs={6}>
                {currentStep > 0 && (
                  <Button disabled={this.state.loading} onClick={this.handlePrev}>
                    Back
                  </Button>
                )}
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                {currentStep !== 2 && (
                  <Button color="primary" onClick={this.handleNext} disabled={this.nextButtonDisabled()}>
                    Next
                  </Button>
                )}
                {currentStep === 2 && (
                  <Fragment>
                    <Button color="primary" onClick={this.handleNext}>
                      Import and save for future recurring import
                    </Button>
                    <Button onClick={this.handleClose}>Cancel</Button>
                  </Fragment>
                )}
              </Grid>
            </Grid>
            <IntegrationPermissionsDialog
              isDialogVisible={validatePermissionsDialogOpen}
              integrationType="Jira"
              messageActionName={UPDATE_JIRA_RECURRING_IMPORT_QUERY}
              onCancel={() => this.handleClose()}
              onConfirm={() => this.onConfirmNewJQL()}
              onClose={() => this.handleClose()}
              orgIntegrationId={selectedOrgIntegration}
            />
          </Wrapper>
        }
      />
    );
  }
}

const CustomLightBox = styled(LightBox)`
  &&&& {
    > div > div {
      height: 60%;
      width: 980px;
      overflow: hidden;
      transition: width 0.6s, height 0.6s;

      ${({ big }) =>
        big &&
        `
        width: 1128px;
        height: 100%;
      `}
    }

    > div > div > div {
      height: 100%;
      overflow: hidden;
    }
  }
`;

const Wrapper = styled.div`
  height: 100%;
  position: relative;

  > button {
    top: -18px;
    right: -16px;
  }
`;

const Content = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 166px);
`;

const InfoTypography = styled(Typography)`
  &&&& {
    font-size: 17px;
    margin-left: 12px;
  }
`;

const StyledText = styled(Text)`
  margin: ${spacing(2)}px 0;
`;
