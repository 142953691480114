import React from 'react';
import { withTheme } from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

function SvgComponent(props) {
  const fill = props.fill || props.theme.palette.text.primary;

  return (
    <SvgIcon fontSize="inherit" width="24.956" height="24.964" viewBox="0 0 24.956 24.964" {...props}>
      <g id="icon-allocation" transform="translate(-31.807 -633.967)" opacity="0.6">
        <path 
          id="Path_15600" 
          data-name="Path 15600"
          d="M44.413,658.931A12.628,12.628,0,0,1,41.586,634c.041-.009.084-.016.126-.021l.1,1-.1-1a2.163,2.163,0,0,1,2.384,
          1.925.918.918,0,0,1,.006.1v9.042a1.585,1.585,0,0,0,1.583,1.584h8.784a2.3,2.3,0,0,1,2.292,2.291,1.01,1.01,0,0,
          1-.026.226,12.655,12.655,0,0,1-12.324,9.779Zm-2.457-22.962a10.626,10.626,0,0,0,4.881,20.683,10.694,10.694,0,0,0,
          7.915-7.8.291.291,0,0,0-.281-.215H45.687a3.588,3.588,0,0,1-3.583-3.584v-8.962A.2.2,0,0,0,41.956,635.969Z"
          fill={fill}
        />
        <path 
          id="Path_15601" 
          data-name="Path 15601"
          d="M55.375,643.468H49.563a2.294,2.294,0,0,1-2.292-2.292v-5.813a1,1,0,0,1,1.332-.943,12.567,12.567,0,0,1,
          7.715,7.716,1,1,0,0,1-.943,1.332Zm-6.1-6.589v4.3a.292.292,0,0,0,.292.292h4.3A10.546,10.546,0,0,0,49.271,636.879Z"
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
}

export default withTheme(SvgComponent);
