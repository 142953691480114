import reduceReducers from 'reduce-reducers';

import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';
import {
  CREATE_PROJECT_FROM_CANVAS,
  CREATE_OBJECTIVE_FROM_CANVAS,
  CREATE_KEY_RESULT_FROM_CANVAS,
  CREATE_METRIC_FROM_CANVAS,
} from 'features/Canvas/store/types';

import {
  APPLY_FILTERS_WHITEBOARD,
  UPDATE_WHITEBOARD_EDGES_LAYOUT,
  UPDATE_WHITEBOARD_LAYOUT_CONFIG,
  UPDATE_WHITEBOARD_NODES_LAYOUT,
} from './types';

import { projectsAdapter, objectivesAdapter, keyResultsAdapter, timeframesAdapter, metricsAdapter } from './helpers/adapters';
import { projectsReducer } from './reducers/projectsReducer';
import { objectivesReducer } from './reducers/objectivesReducer';
import { keyResultsReducer } from './reducers/keyResultsReducer';
import { timeframesReducer } from './reducers/timeframesReducer';
import { metricsReducer } from './reducers/metricsReducer';

export const initialState = {
  nodesLayout: {
    nodes: [],
    labelsValue: {},
  },
  edgesLayout: [],
  layoutConfig: {
    displayNodes: ['idea', 'initiative', 'objective', 'timeframe'],
    relationsConfig: {
      okrs: false,
      projects: false,
      timeframes: false,
    },
  },
  projects: projectsAdapter.getInitialState(),
  objectives: objectivesAdapter.getInitialState(),
  keyResults: keyResultsAdapter.getInitialState(),
  timeframes: timeframesAdapter.getInitialState(),
  metrics: metricsAdapter.getInitialState(),
  totalAllLayers: 0,
  totalByLayer: {},
  operations: bulkThunkInitialState([APPLY_FILTERS_WHITEBOARD, CREATE_PROJECT_FROM_CANVAS]),
};

const whiteboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_WHITEBOARD_LAYOUT_CONFIG:
      return {
        ...state,
        layoutConfig: {
          ...action.payload,
        },
      };
    case UPDATE_WHITEBOARD_NODES_LAYOUT:
      return {
        ...state,
        nodesLayout: {
          ...state.nodesLayout,
          ...action.payload,
        },
      };
    case UPDATE_WHITEBOARD_EDGES_LAYOUT:
      return {
        ...state,
        edgesLayout: action.payload,
      };
    default: {
      return state;
    }
  }
};

const operationsReducer = getThunksReducers([
  APPLY_FILTERS_WHITEBOARD,
  CREATE_PROJECT_FROM_CANVAS,
  CREATE_OBJECTIVE_FROM_CANVAS,
  CREATE_KEY_RESULT_FROM_CANVAS,
  CREATE_METRIC_FROM_CANVAS,
]);

const reducer = reduceReducers(
  initialState,
  whiteboardReducer,
  projectsReducer,
  objectivesReducer,
  keyResultsReducer,
  timeframesReducer,
  metricsReducer,
  ...operationsReducer,
);

export default reducer;
