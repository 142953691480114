import React from 'react';
import styled from 'styled-components';
import Input from '@material-ui/core/Input';

import FilterDropdownWithDndList from 'design-system/molecules/FilterDropdownWithDndList/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import { spacing } from 'design-system/theme';
import ViewsListWithTabs from 'containers/ViewsListWithTabs/ViewsListWithTabs';
import Search from 'design-system/atoms/DragonIcons/Search';
import { PAGE_HEADER_HEIGHT } from 'constants/common';
import useViewsFilters, { OWNER_FILTER, PAGE_FILTER, UPDATED_FILTER } from 'hooks/userViews/useViewsFilters';
import { ALL_VIEWS, FAVORITE_VIEWS } from 'hooks/userViews/useViewsTabs';

export default () => {
  const {
    searchQuery,
    setSearchQuery,
    selectedFilters,
    handleFilterChange,
    ownerOptions,
    pageOptions,
    updatedAtOptions,
    hasOwnerFilterActive,
    hasPageFilterActive,
    hasUpdatedFilterActive,
    applyFiltersOnViews,
  } = useViewsFilters();

  return (
    <PageContent>
      <PageBody>
        <PageOptions>
          <FiltersContainer>
            <TextDeprecated>Filter views by</TextDeprecated>
            <StyledFilterDropdownWithDndList
              items={ownerOptions}
              selectedItems={selectedFilters[OWNER_FILTER]}
              onChange={items => handleFilterChange(OWNER_FILTER, items)}
              label={hasOwnerFilterActive ? '' : 'All Owners'}
              textFieldValue={hasOwnerFilterActive ? 'Owners' : ''}
              withLabelAnimation={false}
            />
            <StyledFilterDropdownWithDndList
              items={pageOptions}
              selectedItems={selectedFilters[PAGE_FILTER] || []}
              onChange={items => handleFilterChange(PAGE_FILTER, items)}
              label={hasPageFilterActive ? '' : 'All Pages'}
              textFieldValue={hasPageFilterActive ? 'Pages' : ''}
              withLabelAnimation={false}
            />
            <StyledFilterDropdownWithDndList
              items={updatedAtOptions}
              selectedItems={selectedFilters[UPDATED_FILTER] || []}
              onChange={items => handleFilterChange(UPDATED_FILTER, items)}
              label={hasUpdatedFilterActive ? '' : 'Updated anytime'}
              textFieldValue={hasUpdatedFilterActive ? 'Updated' : ''}
              withLabelAnimation={false}
            />
          </FiltersContainer>
          <SearchContainer>
            <Icon>
              <Search />
            </Icon>
            <Input
              type="text"
              placeholder="Search for a view..."
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
          </SearchContainer>
        </PageOptions>
        <ViewsListWithTabs
          searchQuery={searchQuery}
          visibleTabs={[FAVORITE_VIEWS, ALL_VIEWS]}
          applyFilters={applyFiltersOnViews}
        />
      </PageBody>
    </PageContent>
  );
};

const PageContent = styled.div`
  padding: 0 22px;
  background-color: ${props => props.theme.palette.background.white};
`;

const PageBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
  padding-top: ${spacing()}px;
`;

const PageOptions = styled.div`
  position: absolute;
  top: ${spacing(2)}px;
  right: 0;
  display: flex;
  align-items: center;
  z-index: 1;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.unit * 2}px;
  margin-right: ${({ theme }) => theme.spacing.unit * 3}px;
`;

const StyledFilterDropdownWithDndList = styled(FilterDropdownWithDndList)`
  max-width: 145px;
`;

const SearchContainer = styled.div`
  position: relative;

  input {
    padding-left: 22px;

    &::placeholder {
      color: ${({ theme }) => theme.palette.text.quickSilver};
      font-size: ${({ theme }) => theme.typography.fontSize}px;
    }
  }
`;

const Icon = styled.div`
  &&&& {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    svg path {
      fill: ${({ theme }) => theme.palette.text.primary};
    }
  }
`;
