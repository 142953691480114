import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import MuiBadge from '@material-ui/core/Badge';

import FilterIcon from 'design-system/atoms/FilterIcon/index';

import theme from 'design-system/theme';

const FilterButtonComponent = React.forwardRef(({ badgeContent, onClick, className }, ref) => (
  <StyledButton id="global-search-icon" aria-haspopup="true" buttonRef={ref} onClick={onClick} className={className}>
    <Badge badgeContent={badgeContent}>
      <FilterIcon width={14} height={14} color={theme.palette.text.primary} stroke={theme.palette.text.primary} />
    </Badge>
  </StyledButton>
));

const StyledButton = styled(Button)`
  &&&& {
    border-radius: 50%;
    height: 38px;
    width: 38px;
    min-width: unset;
    margin-left: 10px;
    button {
      position: absolute;
      transform: translateX(10px);
    }
  }
`;

const Badge = styled(MuiBadge)`
  &&&& {
    span {
      color: ${props => props.theme.palette.text.primary};
      font-weight: ${props => props.theme.typography.fontWeightBold};
      background: transparent;
      top: -1px;
      right: -5px;
    }
  }
`;

FilterButtonComponent.propTypes = {
  badgeContent: PropTypes.string,
  onClick: PropTypes.func,
};

export default FilterButtonComponent;
