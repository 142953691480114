/**
 * IconAndTextButton Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React, { PureComponent } from 'react';

import Button from '@material-ui/core/Button';

export default class IconAndTextButton extends PureComponent {
  render() {
    const { icon, text, onClick, disabled, color, ...otherProps } = this.props;

    const IconComponent = icon;

    return (
      <React.Fragment>
        <Button
          color={color || 'primary'}
          onClick={onClick}
          disabled={disabled}
          {...otherProps}
        >
          <IconComponent style={{ marginRight: 5, width: 18 }} />
          { text }
        </Button>
      </React.Fragment>
    );
  }
}
