import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import theme from 'design-system/theme';

import createActionColumnDefGetter from './createActionColumnDefGetter';

const OPEN_ITEM_COLUMN_ID = 'openItem';

const getOpenItemColumnDef = createActionColumnDefGetter({
  field: OPEN_ITEM_COLUMN_ID,
  icon: OpenInNewIcon,
  otherActionDefs: {
    iconStyles: {
      fontSize: theme.typography.fontSize,
      width: '20px',
    },
  },
});

export { OPEN_ITEM_COLUMN_ID };

export default getOpenItemColumnDef;
