import moment from 'moment-timezone';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';

const convertToCSV = array => {
  let str = '';
  const ignoreKeys = ['details', 'notes', 'summary', 'integrationProgress'];
  let header = '';
  const keys = [
    '#',
    'id',
    'title',
    'planningStage',
    'roadmapTitle',
    'product1Title',
    'objectiveTitle',
    'keyResult1Title',
    'categoryTitle',
    'phaseTitle',
    'themeTitle',
    'timeframeTitle',
    'ownerName',
    'createdBy',
    'createdByName',
    'updatedBy',
    'parent',
    'manualProgress',
    'planned_moar',
    'totalCost',
    'displayProgress',
    'objective_id',
    'priorityTitle',
    'cost1',
    'cost2',
    'reach_score',
    'cost3',
    'costs',
    'owner_id',
    'row_order',
    'created_at',
    'key_result_2_id',
    'key_result_1_id',
    'start_date',
    'rank',
    'created_by_id',
    'sort_order',
    'progress',
    'estimated_start_date',
    'priorityScore',
    'user_id',
    'parent_id',
    'layer',
    'theme_id',
    'business_value',
    'end_date',
    'customColor',
    'duration',
    'status_color',
    'estimated_duration',
    'updated_at',
    'product_2_id',
    'deadline',
    'riceScore',
    'product_1_id',
    'impact_score',
    'benefit1',
    'timeframe_id',
    'type',
    'benefit2',
    'benefit3',
    'benefits',
    'category_id',
    'updatedByName',
    'confidence_score',
    'roadmap_id',
    'jira_progress',
    'effort_score',
    'updated_by_id',
    'phase_id',
  ];

  keys.forEach(key => {
    if (header !== '') header += ',';

    header += key;
  });
  str += `${header}\r\n`;

  for (let i = 0; i < array.length; i++) {
    let line = '';

    keys.forEach(key => {
      if (line !== '') line += ',';
      let item = array[i][key];

      if (isObject(item) || isArray(item) || ignoreKeys.includes(key) || !item) {
        item = '-';
      }

      line += isString(item) ? item.replace(/,/g, '') : item;
    });

    str += `${line}\r\n`;
  }

  return str;
};

export default (items, fileTitle) => {
  // Convert Object to JSON
  const csv = convertToCSV(items);

  const exportedFilenmae = fileTitle ? `${fileTitle}.csv` : `export-${moment().format()}.csv`;

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement('a');

    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);

      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
