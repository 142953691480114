// External dependencies
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

// Dragonboat depencies
import { updateLocalProjectById } from 'store/projects';

const componentHOC = (Component) => {
  return (props) => {
    const dispatch = useDispatch();
    const { setShowImportJsonDialog } = props;
    const [newProjectsState, setNewProjectsState] = useState(null);

    const importNewProjectsState = (newState) => {
      JSON.parse(newState).forEach(project => {
        dispatch(updateLocalProjectById(project.id, project));
      });
      setShowImportJsonDialog(false);
    };

    return (<Component
      {...props}
      newProjectsState={newProjectsState}
      setNewProjectsState={setNewProjectsState}
      importNewProjectsState={importNewProjectsState}
    />);
  };
};

export default componentHOC;
