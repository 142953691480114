import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { getDisplayLayer } from 'store/filters/selectors';
import useSystemFields from 'hooks/useSystemFields';
import { getOrgHasBet } from 'store/organization';
import { getOrganizationIntegrations } from 'store/organization/selectors';
import { getGroupOptionsSelector } from 'store/projects/groupSelectors';
import { getGroupOptionByKey } from 'store/projects/helpers/groupOptions';

import { DEFAULT_COLUMNS, PORTFOLIO_COLUMNS } from '../helpers/columns';

function useTimelineLightboxState({ portfolioMode }) {
  const [search, setSearch] = useState('');
  const [zoom, setZoom] = useState(true);
  const [getSystemFieldName] = useSystemFields();

  const [timelineLightboxState, setTimelineLightboxState] = useState({});

  const displayLayer = useSelector(getDisplayLayer);
  const hasBets = useSelector(getOrgHasBet);
  const orgIntegrations = useSelector(getOrganizationIntegrations);

  const getColumns = useCallback(() => {
    return [...DEFAULT_COLUMNS, ...PORTFOLIO_COLUMNS];
  }, [getSystemFieldName]);

  const updateState = useCallback(
    state => {
      setTimelineLightboxState({
        ...timelineLightboxState,
        ...state,
      });
    },
    [timelineLightboxState, setTimelineLightboxState],
  );

  const groupByOptions = useSelector(state => getGroupOptionsSelector(state, { withNullOption: false, portfolioMode }));
  const selectedGroup = getGroupOptionByKey((timelineLightboxState.selectedGroup || { key: null })?.key, groupByOptions);

  const setSelectedGroup = group => updateState({ selectedGroup: group });

  return {
    displayLayer,
    hasBets,
    lsState: timelineLightboxState,
    orgIntegrations,
    search,
    zoom,
    selectedGroup,
    groupByOptions,

    getColumns,
    setZoom,
    setSearch,
    updateState,
    setSelectedGroup,
  };
}

export default useTimelineLightboxState;
