import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { not } from 'ramda';

import { getUserViewById, getActiveViewForPage } from 'store/userViews/selectors';
import { getCurrentUser } from 'store/login/selectors';
import { getActiveTemplate } from 'store/templates';
import { setPageActiveView } from 'store/userViews/actions';
import { getDefaultTemplate, getTemplateByKey } from '../helpers/templates';
import { DASHBOARDS_PAGE } from 'constants/filters';
import { isDashboardInEditMode } from 'store/dashboards/selectors';
import { getPageFilters } from 'store/filters/selectors';
import { getOrganization } from 'store/organization';
import { getWidgetDefaultCoordinatesBasedOnIndex } from 'utils/reactGridLayout';
import { getDefaultProgressSummaryDates } from '../Charts/utils';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const doGetNewDashboardData = (organization, filters, templateKey) => {
  const urlParams = new URL(window.location.href).searchParams;
  const templateKeyQueryParam = urlParams.get('template');

  const template = getTemplateByKey(templateKey);

  const { startDate, endDate } = getDefaultProgressSummaryDates();

  return {
    id: null,
    page: DASHBOARDS_PAGE,
    name: !templateKeyQueryParam ? '' : templateKeyQueryParam.replace(/-/g, ' '),
    favorites: [],
    state: {
      dashboard: {
        disallowSave: !!templateKeyQueryParam,
        template: templateKeyQueryParam || templateKey || getDefaultTemplate(organization),
        progressStartDate: startDate,
        progressEndDate: endDate,
        widgets:
          template?.charts?.map((chart, i) => ({
            ...chart,
            ...getWidgetDefaultCoordinatesBasedOnIndex(i),
          })) || [],
      },
      filter: filters,
    },
  };
};

export default function (match) {
  const dispatch = useDispatch();

  const { dashboardId } = useMemo(() => match.params, [match]);

  const organization = useSelector(getOrganization);
  // ID of the Template selected on the Templates page
  const storedActiveTemplateId = useSelector(getActiveTemplate);
  const activeDashboardView = useSelector(state => getActiveViewForPage(state, DASHBOARDS_PAGE));
  const selectedTemplate = activeDashboardView?.state?.dashboard?.template;
  const userViewForDashboard = useSelector(state => getUserViewById(state, dashboardId));
  const editModeStoreValue = useSelector(state => isDashboardInEditMode(state));
  const globalFilters = useSelector(state => getPageFilters(state, DASHBOARDS_PAGE));

  // Key of the Template selected on the Dashboards page
  const templateKey = useMemo(() => selectedTemplate || getDefaultTemplate(organization), [selectedTemplate, organization]);

  const getNewDashboardData = useCallback(
    template => doGetNewDashboardData(organization, globalFilters, template),
    [organization, globalFilters],
  );

  const dashboardView = dashboardId ? userViewForDashboard : getNewDashboardData(templateKey);

  useEffect(() => {
    if (activeDashboardView) {
      return;
    }

    /*
     * By default will cretae for selected template key a default active
     * dashboard a new one with default dashboard data (has id null)
     */
    dispatch(setPageActiveView(DASHBOARDS_PAGE, getNewDashboardData(templateKey)));
  }, [activeDashboardView, organization, globalFilters]);

  useEffect(() => {
    /*
     * In the case of:
     *    - active dashboard id is null (it means was created a dafault dashboard data)
     *    - exists some user user view already created (use case when it loads from url id)
     *
     * It will store the active dashboard the current selected user view and not use the
     * default dashboard
     */

    const activeDashboardViewIsNotCreated = not(activeDashboardView?.id);
    const dashboardLoadedFromAPI = userViewForDashboard?.id;

    if (activeDashboardViewIsNotCreated && dashboardLoadedFromAPI) {
      dispatch(setPageActiveView(DASHBOARDS_PAGE, userViewForDashboard));
    }
  }, [activeDashboardView, userViewForDashboard]);

  const currentUser = useSelector(getCurrentUser);

  const { canUpdate } = usePermissions();

  const allowUpdate = canUpdate(PERMISSION_FEATURES.userDashbboard);
  const hasAccessToDashboard = !dashboardId || (dashboardId && activeDashboardView?.id);
  const isInEditMode = (activeDashboardView && !activeDashboardView.id) || editModeStoreValue;

  const activeDashboardViewOrDashboardView = activeDashboardView || dashboardView;

  const isDashboardOwner = dashboardId ? currentUser.id === activeDashboardViewOrDashboardView?.user_id : true;

  return {
    isInEditMode,
    activeDashboardView: activeDashboardViewOrDashboardView,
    hasAccessToDashboard,
    dashboardId: activeDashboardViewOrDashboardView?.id,
    isReadOnlyUser: !allowUpdate,
    templateId: storedActiveTemplateId,
    userViewForDashboard,
    isDashboardOwner,
    getNewDashboardData,
  };
}
