import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import MultiSelectDropdown from 'design-system/atoms/MultiSelectDropdown/index';


const FilterDropdown = ({ items = [], propertyName, label, selected = [], onChange, ...props }) => {
  const transformedItems = items.reduce((acc, i) => {
    if (isString(i)) {
      acc[i] = true;
    } else {
      const value = (isObject(i[propertyName]) ? i[propertyName].title : i[propertyName]) || '';

      acc[value.trim()] = true;
    }

    return acc;
  }, {});

  return (
    <MultiSelectDropdown
      {...props}
      items={Object.keys(transformedItems)}
      label={label}
      selected={selected}
      onChange={selectedLabels => {
        onChange({ [propertyName]: selectedLabels });
      }}
    />
  );
};

FilterDropdown.propsTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  propertyName: PropTypes.string,
  selected: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default styled(FilterDropdown)`
  &&&& {
    width: 140px;
    margin-left: 10px;

    label {
      margin-left: 16px;
      font-size: 14px;
    }
  }
`;
