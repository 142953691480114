import { schema, normalize } from 'normalizr';

import { add, remove, makeDependencyEntityId } from 'utils/store/entities';

import {
  // Project => Task Dependencies:
  ADD_PROJECT_TASK_DEPENDENCY_FULFILLED,
  REMOVE_PROJECT_TASK_DEPENDENCY_FULFILLED,

  // Project => Estimate Dependencies:
  ADD_PROJECT_ESTIMATE_DEPENDENCY_FULFILLED,
  REMOVE_PROJECT_ESTIMATE_DEPENDENCY_FULFILLED,

  // Project => Project Dependencies:
  ADD_PROJECT_PROJECT_DEPENDENCY_FULFILLED,
  REMOVE_PROJECT_PROJECT_DEPENDENCY_FULFILLED,

  // Estimate => Estimate Dependencies:
  ADD_ESTIMATE_ESTIMATE_DEPENDENCY_FULFILLED,
  REMOVE_ESTIMATE_ESTIMATE_DEPENDENCY_FULFILLED,

  // Estimate => Project Dependencies:
  ADD_ESTIMATE_PROJECT_DEPENDENCY_FULFILLED,
  REMOVE_ESTIMATE_PROJECT_DEPENDENCY_FULFILLED,

  // Task => Task Dependencies:
  ADD_TASK_TASK_DEPENDENCY_FULFILLED,
  REMOVE_TASK_TASK_DEPENDENCY_FULFILLED,

  // Task => Project Dependencies:
  ADD_TASK_PROJECT_DEPENDENCY_FULFILLED,
  REMOVE_TASK_PROJECT_DEPENDENCY_FULFILLED,
} from '../types';

const dependenciesEntity = new schema.Entity('dependencies', undefined, {
  idAttribute: (value) => makeDependencyEntityId(
    // The project_id is validated last, because if the source is an Estimate or Task,
    // the target will come as `project_id`. But if the source is not an Estimate or Task,
    // the source will come as `project_id` instead.
    value.info.estimate_id || value.info.task_id || value.info.project_id,
    value.id,
  ),
});

const addDependencyToState = (state, action, dependencyKey) => {
  const { entities } = normalize(action.payload.response.data, dependenciesEntity);

  return {
    ...state,
    dependencies: {
      ...state.dependencies,
      [dependencyKey]: add(state.dependencies[dependencyKey], entities.dependencies),
    },
  };
};

const removeDependencyFromState = (state, action, dependencyKey) => {
  const {
    estimateId,
    taskId,
    projectId,
    dependencyId,
  } = action.payload;

  const idToRemove = makeDependencyEntityId(estimateId || taskId || projectId, dependencyId);

  return {
    ...state,
    dependencies: {
      ...state.dependencies,
      [dependencyKey]: remove(state.dependencies[dependencyKey], [idToRemove]),
    },
  };
};

const reducer = (state = {}, action) => {
  switch (action.type) {
    // Project => Task Dependencies:
    case ADD_PROJECT_TASK_DEPENDENCY_FULFILLED: {
      return addDependencyToState(state, action, 'projectTask');
    }
    case REMOVE_PROJECT_TASK_DEPENDENCY_FULFILLED: {
      return removeDependencyFromState(state, action, 'projectTask');
    }

      // Project => Estimate Dependencies:
    case ADD_PROJECT_ESTIMATE_DEPENDENCY_FULFILLED: {
      return addDependencyToState(state, action, 'projectEstimate');
    }
    case REMOVE_PROJECT_ESTIMATE_DEPENDENCY_FULFILLED: {
      return removeDependencyFromState(state, action, 'projectEstimate');
    }

    // Project => Project Dependencies:
    case ADD_PROJECT_PROJECT_DEPENDENCY_FULFILLED: {
      return addDependencyToState(state, action, 'projectProject');
    }
    case REMOVE_PROJECT_PROJECT_DEPENDENCY_FULFILLED: {
      return removeDependencyFromState(state, action, 'projectProject');
    }

    // Estimate => Estimate Dependencies:
    case ADD_ESTIMATE_ESTIMATE_DEPENDENCY_FULFILLED: {
      return addDependencyToState(state, action, 'estimateEstimate');
    }
    case REMOVE_ESTIMATE_ESTIMATE_DEPENDENCY_FULFILLED: {
      return removeDependencyFromState(state, action, 'estimateEstimate');
    }

    // Estimate => Project Dependencies:
    case ADD_ESTIMATE_PROJECT_DEPENDENCY_FULFILLED: {
      return addDependencyToState(state, action, 'estimateProject');
    }
    case REMOVE_ESTIMATE_PROJECT_DEPENDENCY_FULFILLED: {
      return removeDependencyFromState(state, action, 'estimateProject');
    }

    // Task => Task Dependencies:
    case ADD_TASK_TASK_DEPENDENCY_FULFILLED: {
      return addDependencyToState(state, action, 'taskTask');
    }
    case REMOVE_TASK_TASK_DEPENDENCY_FULFILLED: {
      return removeDependencyFromState(state, action, 'taskTask');
    }

    // Task => Project Dependencies:
    case ADD_TASK_PROJECT_DEPENDENCY_FULFILLED: {
      return addDependencyToState(state, action, 'taskProject');
    }
    case REMOVE_TASK_PROJECT_DEPENDENCY_FULFILLED: {
      return removeDependencyFromState(state, action, 'taskProject');
    }

    default: {
      return state;
    }
  }
};

export default reducer;