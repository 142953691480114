import React from 'react';
import { defaultTo } from 'ramda';

import DndList from 'design-system/organisms/DndList/index';
import MultiFilterSearchRow from 'design-system/atoms/MultiFilterSearchRow/index';

import { searchMenuItems } from 'design-system/utils';

const defaultEmptyObject = defaultTo({});

const MultiFilterSearch = ({
  id,
  menu = [],
  selectedItems = {},
  draggable = false,
  checkable = false,
  colorable = false,
  hideSearch = false,
  renderClearFilter,
  onSelectItem,
  onClickItem,
  onChangeOrder,
  onChangeColor,
  menuListProps,
  dataTestId,
}) => {
  const [items, setItems] = React.useState(menu);
  const [search, setSearch] = React.useState('');

  const _handleChange = event => {
    setSearch(event.target.value);
  };

  const _renderSearch = () => {
    return <MultiFilterSearchRow handleChange={_handleChange} hideSearch={hideSearch} search={search} />;
  };

  const _handleChangeOrder = (startIndex, endIndex) => {
    const startId = menu.findIndex(i => i.id === items[startIndex].id);
    const endId = menu.findIndex(i => i.id === items[endIndex].id);

    setItems(items => {
      const result = Array.from(items);
      const [removed] = result.splice(startIndex, 1);

      result.splice(endIndex, 0, removed);

      return result;
    });

    onChangeOrder(startId, endId);
  };

  React.useEffect(() => {
    setItems(searchMenuItems(search, menu));
  }, [search]);

  return (
    <DndList
      id={`droppable-${id}`}
      data-testid={dataTestId}
      items={items}
      isSubTree
      selectedItems={selectedItems}
      draggable={draggable}
      checkable={checkable}
      colorable={colorable}
      renderSearch={_renderSearch}
      renderClear={renderClearFilter}
      onSelectItem={onSelectItem}
      onClickItem={onClickItem}
      onChangeOrder={_handleChangeOrder}
      onChangeColor={onChangeColor}
      showTooltip
      {...defaultEmptyObject(menuListProps)}
    />
  );
};

export default MultiFilterSearch;
