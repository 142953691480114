import React from 'react';
import styled from 'styled-components';

import OktaCircleIcon from 'design-system/atoms/OktaCircleIcon/index';


import AuthMainButton from 'components/AuthMainButton';

const OktaLoginButton = ({ handleClick }) => {
  return (
    <div style={{ paddingTop: 3, textAlign: 'center' }}>
      <Button variant="contained" color="primary" onClick={handleClick}>
        <OktaCircleIcon /> Login with Okta
      </Button>
    </div>
  );
};

export default OktaLoginButton;

const Button = styled(AuthMainButton)`
  &&&& {
    margin: 0;
    width: 100%;
    height: 58px;
    svg {
      margin-right: 15px;
    }
  }
`;
