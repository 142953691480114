import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

export default withStyles(theme => ({
  lightboxContainer: {
    height: 'calc(100% - 96px)',
  },
}))(props => (
  <Dialog
    open={!!props.isOpen}
    onClose={props.onClose}
    fullWidth={props.fullWidth}
    maxWidth={props.maxWidth || 'md'}
    width={props.width || 'md'}
    className={props.className}
    classes={props.fullHeight ? { paperScrollPaper: props.classes.lightboxContainer } : {}}
    scroll="paper"
    data-cy={props.dataCy}
  >
    {(props.hideTabs || props.showTitle) && (
      <Grid container justify="space-between">
        <Grid item xs={props.headerTabs ? 10 : 12}>
          <DialogTitle>{props.title}</DialogTitle>
        </Grid>
        {props.headerTabs && (
          <Grid item xs={2} style={{ paddingTop: 16 }}>
            {props.headerTabs}
          </Grid>
        )}
      </Grid>
    )}

    {props.children}

    {props.content && <DialogContent style={{ overflowX: 'hidden' }}>{props.content}</DialogContent>}

    {props.actions && <DialogActions>{props.actions}</DialogActions>}
  </Dialog>
));
