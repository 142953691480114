import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

import numberComparator from './numberComparator';

const stringComparator = (a, b, _1, _2, isInverted) => {
  if (isNumber(a) || isNumber(b)) {
    return numberComparator(a, b, _1, _2, isInverted, true);
  }

  const valueA = typeof a === 'string' ? a : '';
  const valueB = typeof b === 'string' ? b : '';

  if (!valueA && !valueB) {
    return 0;
  }

  // escape HTML tags
  const cleanedA = valueA.replace(/<\/?[^>]+(>|$)/g, '');
  const cleanedB = valueB.replace(/<\/?[^>]+(>|$)/g, '');

  if (cleanedA === cleanedB) {
    return 0;
  }

  if (isEmpty(cleanedA) && !isEmpty(cleanedB)) {
    return isInverted ? -1 : 1;
  }

  if (!isEmpty(cleanedA) && isEmpty(cleanedB)) {
    return isInverted ? 1 : -1;
  }

  return cleanedA?.localeCompare ? cleanedA.localeCompare(cleanedB) : cleanedA - cleanedB;
};

export default stringComparator;
