const makeGetMetadataChipCellRendererParams = (metadataCollection = []) => {
  return params => {
    const metadataTitle = params.value;

    const metadata = metadataCollection.find(p1 => p1?.title === metadataTitle);

    return {
      color: metadata?.color,
    };
  };
};

export default makeGetMetadataChipCellRendererParams;
