import moment from 'moment-timezone';
import Color from 'color';

import { defaultGanttBlue, materialColorsAlt } from 'design-system/themes/default';
import { computeEndDate } from 'utils';
import sortRowOrder from 'utils/sortRowOrder';
import { getProjectHealthLabel } from 'utils/projects/healthUtils';

export const statusColorLegend = [
  {
    title: '',
    color: materialColorsAlt.gray,
  },
  {
    title: 'Red',
    color: materialColorsAlt.red,
  },
  {
    title: 'Yellow',
    color: materialColorsAlt.yellow,
  },
  {
    title: 'Green',
    color: materialColorsAlt.green,
  },
];

const addColumnWidth = 24;
const viewColumnWidth = 26;
const columnsWidths = {
  progressPercent: 45,
  totalCost: 50,
  planningStage: 60,
};
const metadataFields = [
  'roadmapCorp',
  'roadmap',
  'product1',
  'objectiveCorp',
  'objective',
  'keyResult1',
  'keyResult2',
  'theme',
  'category',
  'priority',
];

export function generateGanttColumns({ selectedDisplayColumns, savedWidths = {}, showLockColumn = false }) {
  const columns = [
    { name: 'view', width: viewColumnWidth, max_width: viewColumnWidth, resize: false, sort: false },
    { name: 'add', width: addColumnWidth, max_width: addColumnWidth, resize: false, sort: false },
  ];

  (selectedDisplayColumns || [])
    .filter(c => !['integrationKey', 'key'].includes(c.id))
    .reverse()
    .forEach(c => {
      if (c.id === 'title') {
        columns.unshift({
          name: 'text',
          label: 'Title',
          tree: true,
          width: savedWidths.text || 350,
          min_width: 100,
          resize: true,
          editor: c.editor,
        });
      } else {
        columns.unshift({
          name: c.id,
          label: c.gridLabel || c.name,
          width: savedWidths[c.id] || columnsWidths[c.id] || c.width || 70,
          min_width: 45,
          resize: c.resize || true,
          editor: c.editor,
          template: c.template,
        });
      }
    });

  return columns;
}

export function calculateGridWidth(gantt) {
  const selectedDisplayColumns = gantt.config.columns;
  const onTitleFields = ['key', 'integrationKey'];

  const totalWidth = selectedDisplayColumns
    .filter(c => !onTitleFields.includes(c.id))
    .reduce((total, cur) => {
      return total + cur.width;
    }, 0);

  return totalWidth;
}

export function getTooltipText(start, end, task) {
  let tooltip = `<b>Title:</b> ${task.shortText || task.text}<br />`;
  const duration = Math.round(task.duration / 7);

  const isProject = task.dbType === 'project';

  tooltip = task.ownerName ? `${tooltip}<b>Owner:</b> ${task.ownerName}<br />` : tooltip;
  tooltip =
    task.dbType === 'project' && task.ownerAllocation
      ? `${tooltip}<b>Owner allocation:</b> ${task.ownerAllocation}%<br />`
      : tooltip;
  tooltip = task.dbType === 'task' && task.details ? `${tooltip}<b>Details:</b> ${task.details}<br />` : tooltip;
  tooltip =
    task.type === 'milestone'
      ? `${tooltip}<b>Date:</b> ${moment(task.start_date).parseFromGantt().formatAsDate()}<br />`
      : tooltip;
  tooltip =
    task.start_date && task.type !== 'milestone'
      ? `${tooltip}<b>Start date:</b> ${moment(task.start_date).parseFromGantt().formatAsDate()}<br />`
      : tooltip;
  tooltip =
    task.end_date && task.type !== 'milestone'
      ? `${tooltip}<b>End date:</b> ${moment(task.end_date).parseFromGantt(true).formatAsDate()}<br />`
      : tooltip;
  tooltip = task.duration ? `${tooltip}<b>Duration:</b> ${duration} ${duration === 1 ? 'week' : 'weeks'}<br />` : tooltip;

  if (task.status_color) {
    const statusColorLabel = isProject ? getProjectHealthLabel(task.status_color) : task.status_color;

    tooltip = `${tooltip}<b>Health:</b> ${statusColorLabel}<br />`;
  }

  if (task.Jiras && task.Jiras.length && task.Jiras[0] && task.Jiras[0].ticket_url) {
    tooltip += `<b>Jira:</b> <a href="${task.Jiras[0].ticket_url}" target="_blank">${task.Jiras[0].key}</a><br />`;
  }
  if (task.jiraUrl) {
    tooltip += `<b>Jira:</b> <a href="${task.jiraUrl}" target="_blank">${task.key}</a><br />`;
  }
  tooltip = task.status_summary ? `${tooltip}<b>Summary:</b> ${task.status_summary}<br />` : tooltip;

  return tooltip;
}

export function getTaskLabels(projects) {
  const labels = [];

  projects.forEach(project => {
    if (project.tasks) {
      project.tasks.forEach(task => {
        if (task.label) {
          labels.push(task.label);
        }
      });
    }
  });
  return labels;
}

export const getNextDayInFuture = dayINeed => {
  const today = moment().isoWeekday();

  if (today <= dayINeed) {
    return moment().isoWeekday(dayINeed);
  }
  return moment().add(1, 'weeks').isoWeekday(dayINeed);
};

export function autoSchedule({ projects, filteredProjects, teams, skills, updateEstimates, hcTable, showConfirmedTasks = true }) {
  const newHcTable = Object.keys(hcTable).reduce((acc, cur) => {
    const subObj = hcTable[cur];

    acc[`${subObj.resource.teamTitle}-${subObj.resource.skillTitle}`] = new Array(100).fill(0);
    return acc;
  }, {});
  // const nextSunday = getNextDayInFuture(0);
  const rankedProjects = projects.sort(sortRowOrder);
  const filteredRankedProjects = filteredProjects.sort(sortRowOrder);
  const confirmed = rankedProjects.filter(p => p.planningStage === 'Confirmed');
  let estimatesToUpdate = [];
  const getTeam = teamId => teams.find(t => t.id === teamId);
  const getSkill = skillId => skills.find(s => s.id === skillId);
  const updateNewHcTable = (entity, skillObj, teamObj, isEst = false) => {
    const skillTitle = skillObj ? skillObj.title : '';
    const teamTitle = teamObj ? teamObj.title : '';
    const entityEndDate = computeEndDate(entity.start_date, entity.duration);
    const end = Math.ceil(moment(entityEndDate).diffDuration(moment(), 'days') / 7);
    const start = end - Math.ceil(entity.duration / 7) < 0 ? 0 : end - Math.ceil(entity.duration / 7);

    for (let i = start; i < end; i++) {
      newHcTable[`${teamTitle}-${skillTitle}`][i] += isEst ? entity.numStaff : 1;
    }
  };

  confirmed.forEach(p => {
    if (showConfirmedTasks) {
      p.tasks.forEach(task => {
        const skillObj = task.owner && task.owner.skill_id ? getSkill(task.owner.skill_id) : null;
        const teamObj = task.owner && task.owner.team_id ? getTeam(task.owner.team_id) : null;

        updateNewHcTable(task, skillObj, teamObj);
      });
    } else {
      p.estimates.forEach(est => {
        const skillObj = getSkill(est.skill_id);
        const teamObj = getTeam(est.team_id);

        updateNewHcTable(est, skillObj, teamObj, true);
      });
    }
  });
  const planning = filteredRankedProjects.filter(p => p.planningStage === 'Planning');

  planning.forEach(p => {
    const staffedEstimates = p.estimates.filter(est => {
      const estTeam = getTeam(est.team_id);
      const estSkill = getSkill(est.skill_id);

      if (!estTeam || !estSkill) return false;

      return hcTable[`${estTeam.title}-${estSkill.title}`].resource.skillStaff;
    });

    staffedEstimates.forEach(est => {
      const estTeam = getTeam(est.team_id);
      const estSkill = getSkill(est.skill_id);
      const { skillStaff } = hcTable[`${estTeam.title}-${estSkill.title}`].resource;
      const numWeeks = Math.ceil(est.duration / 7);
      let index = newHcTable[`${estTeam.title}-${estSkill.title}`].findIndex((data, i) => {
        for (let j = i; j < i + numWeeks; j++) {
          if (est.numStaff + newHcTable[`${estTeam.title}-${estSkill.title}`][j] > skillStaff) {
            return false;
          }
        }
        return true;
      });

      if (index === -1) {
        index = 0;
      }
      est.start_date = moment().addDuration(7 * index, 'days');

      for (let i = 0; i < numWeeks; i++) {
        newHcTable[`${estTeam.title}-${estSkill.title}`][index + i] += est.numStaff;
      }
    });
    estimatesToUpdate = estimatesToUpdate.concat(staffedEstimates);
  });
  // updateEstimates(estimatesToUpdate, true);
}

export const EST_ID_OFFSET = 10000000;
export const TASK_ID_OFFSET = 20000000;
export const ROADMAP_ID_OFFSET = 30000000;
export const OBJECTIVE_ID_OFFSET = 40000000;
export const TEAM_ID_OFFSET = 50000000;
export const TIMEFRAME_ID_OFFSET = 60000000;
export const THEME_ID_OFFSET = 70000000;
export const PHASE_ID_OFFSET = 80000000;
export const KEY_RESULT_ID_OFFSET = 90000000;
export const INITIATIVE_ID_OFFSET = 100000000;
export const CATEGORY_ID_OFFSET = 110000000;
export const IDEA_INITIATIVE_ID_OFFSET = 120000000;
export const IDEA_BET_ID_OFFSET = 130000000;
export const PRIORITY_ID_OFFSET = 140000000;
export const OWNER_ID_OFFSET = 150000000;
export const HEALTH_OFFSET = 160000000;
export const CUSTOM_FIELD_OFFSET = 170000000;
export const TAG_FIELD_OFFSET = 180000000;
export const TIMEFRAME2_ID_OFFSET = 190000000;
export const ROADMAPCORP_ID_OFFSET = 200000000;
export const OBJECTIVECORP_ID_OFFSET = 210000000;
export const UNASSIGNED_ID_OFFSET = 1000000000;
export const KEY_RESULT_2_ID_OFFSET = 220000000;
export const PRODUCT_2_ID_OFFSET = 230000000;
export const CUSTOMER_USER_1_ID_OFFSET = 240000000;
export const CUSTOMER_USER_2_ID_OFFSET = 250000000;
export const CUSTOMER_USER_3_ID_OFFSET = 260000000;
export const CUSTOMER_USER_4_ID_OFFSET = 270000000;
export const CUSTOMER_USER_5_ID_OFFSET = 280000000;
export const CUSTOMER_USER_6_ID_OFFSET = 290000000;
export const CUSTOMER_USER_7_ID_OFFSET = 300000000;
export const GANTT_OFFSETS = {
  est: EST_ID_OFFSET,
  task: TASK_ID_OFFSET,
  roadmapCorp: ROADMAPCORP_ID_OFFSET,
  roadmap: ROADMAP_ID_OFFSET,
  objectiveCorp: OBJECTIVECORP_ID_OFFSET,
  objective: OBJECTIVE_ID_OFFSET,
  team: TEAM_ID_OFFSET,
  timeframe: TIMEFRAME_ID_OFFSET,
  timeframe2: TIMEFRAME2_ID_OFFSET,
  theme: THEME_ID_OFFSET,
  phase: PHASE_ID_OFFSET,
  category: CATEGORY_ID_OFFSET,
  keyResult1: KEY_RESULT_ID_OFFSET,
  keyResult2: KEY_RESULT_2_ID_OFFSET,
  product1: INITIATIVE_ID_OFFSET,
  product2: PRODUCT_2_ID_OFFSET,
  initiative: IDEA_INITIATIVE_ID_OFFSET,
  bet: IDEA_BET_ID_OFFSET,
  priority: PRIORITY_ID_OFFSET,
  owner: OWNER_ID_OFFSET,
  status_color: HEALTH_OFFSET,
  customField: CUSTOM_FIELD_OFFSET,
  tags: TAG_FIELD_OFFSET,
  user1: CUSTOMER_USER_1_ID_OFFSET,
  user2: CUSTOMER_USER_2_ID_OFFSET,
  user3: CUSTOMER_USER_3_ID_OFFSET,
  user4: CUSTOMER_USER_4_ID_OFFSET,
  user5: CUSTOMER_USER_5_ID_OFFSET,
  user6: CUSTOMER_USER_6_ID_OFFSET,
  user7: CUSTOMER_USER_7_ID_OFFSET,
};

export function getUnmoddedTaskById(id, allTasks) {
  return allTasks.find(t => t.id === id - TASK_ID_OFFSET);
}

export const getGroupProjectField = groupKey => {
  if (groupKey === 'initiative' || groupKey === 'bet') {
    return 'parent_id';
  }
  return `${groupKey}_id`;
};

export function getProjectParent(project, selectedGroup1 = { key: null }, selectedGroup2 = { key: null }, undefinedTop = false) {
  const group1ProjectField = getGroupProjectField(selectedGroup1.key);
  const group2ProjectField = getGroupProjectField(selectedGroup2.key);

  const type2Id = project[group2ProjectField];
  const type1Id = project[group1ProjectField];

  if (!selectedGroup1.key && !selectedGroup2.key) {
    return 0;
  }
  if (selectedGroup2.key) {
    return GANTT_OFFSETS[selectedGroup2.key] + (type2Id || 0) + (type1Id || UNASSIGNED_ID_OFFSET);
  }
  if (selectedGroup1.key) {
    return undefinedTop && !type1Id ? 0 : GANTT_OFFSETS[selectedGroup1.key] + (type1Id || 0);
  }
  return 0;
}

export function getLightenedColor(color) {
  return Color(color).alpha(0.5).lighten(0.5);
}

export function getColor(project, task, colorByMode = 'roadmap', defaultColor = defaultGanttBlue) {
  if (task && task.status_color === 'Red') {
    return materialColorsAlt.red;
  } else if (!task && project && project.status_color === 'Red') {
    return materialColorsAlt.red;
  }

  if (metadataFields.includes(colorByMode)) {
    if (!project?.[colorByMode]) return defaultColor;

    return task ? getLightenedColor(project[colorByMode].color) : project[colorByMode].color;
  } else if (colorByMode === 'idea') {
    return task ? task.customColor || defaultColor : project.customColor || defaultColor;
  } else if (colorByMode === 'health') {
    const entity = task || project;
    const mapHealthToColor = {
      Red: materialColorsAlt.red,
      Yellow: materialColorsAlt.yellow,
      Green: materialColorsAlt.green,
      Blue: materialColorsAlt.blue,
    };

    return mapHealthToColor[entity.status_color] || defaultColor;
  }

  return defaultColor;
}

export function getLegendGroups(projects, colorById) {
  if (metadataFields.includes(colorById)) {
    return projects
      .filter(p => p[colorById])
      .reduce((acc, cur) => {
        if (!acc.map(o => o.id).includes(cur[colorById].id)) {
          acc.push(cur[colorById]);
        }
        return acc;
      }, []);
  }

  return statusColorLegend;
}

export function getTotalCost(estimates = [], teams = []) {
  return estimates.reduce((acc, est) => {
    const team = teams.find(t => est.team_id === t.id);

    acc += team ? Math.round((est.numStaff || 0) * (team.cost || 0) * (est.duration / 7)) : 0;
    return acc;
  }, 0);
}

export const removeGanttTolltip = () => {
  const ganttTooltip = document.getElementsByClassName('gantt_tooltip');

  while (ganttTooltip.length > 0) ganttTooltip[0].remove();
};

export const fixedGanttTimeWindow = gantt => {
  const project = gantt.getSubtaskDates();
  const areaWidth = gantt.$task.offsetWidth;
  // Setting available scales
  const scaleConfigs = [
    // decades
    {
      scales: [
        {
          subscale_unit: 'year',
          unit: 'year',
          step: 10,
          template(date) {
            const dateToStr = gantt.date.date_to_str('%Y');
            const endDate = gantt.date.add(gantt.date.add(date, 10, 'year'), -1, 'day');

            return `${dateToStr(date)} - ${dateToStr(endDate)}`;
          },
        },
        {
          unit: 'year',
          step: 100,
          template(date) {
            const dateToStr = gantt.date.date_to_str('%Y');
            const endDate = gantt.date.add(gantt.date.add(date, 100, 'year'), -1, 'day');

            return `${dateToStr(date)} - ${dateToStr(endDate)}`;
          },
        },
      ],
    },
    // years
    {
      scales: [
        { subscale_unit: 'year', unit: 'year', step: 1, date: '%Y' },
        {
          unit: 'year',
          step: 5,
          template(date) {
            const dateToStr = gantt.date.date_to_str('%Y');
            const endDate = gantt.date.add(gantt.date.add(date, 5, 'year'), -1, 'day');

            return `${dateToStr(date)} - ${dateToStr(endDate)}`;
          },
        },
      ],
    },
    // quarters
    {
      scales: [
        { subscale_unit: 'month', unit: 'year', step: 3, format: '%Y' },
        {
          unit: 'month',
          step: 3,
          template(date) {
            const dateToStr = gantt.date.date_to_str('%M');
            const endDate = gantt.date.add(gantt.date.add(date, 3, 'month'), -1, 'day');

            return `${dateToStr(date)} - ${dateToStr(endDate)}`;
          },
        },
      ],
    },
    // months
    {
      scales: [
        { subscale_unit: 'month', unit: 'year', step: 1, format: '%Y' },
        { unit: 'month', step: 1, format: '%M' },
      ],
    },
    // weeks
    {
      scales: [
        { subscale_unit: 'week', unit: 'month', step: 1, date: '%F' },
        {
          unit: 'week',
          step: 1,
          template(date) {
            const dateToStr = gantt.date.date_to_str('%d %M');
            const endDate = gantt.date.add(gantt.date.add(date, 1, 'week'), -1, 'day');

            return `${dateToStr(date)} - ${dateToStr(endDate)}`;
          },
        },
      ],
    },
    // days
    {
      scales: [
        { subscale_unit: 'day', unit: 'month', step: 1, format: '%F' },
        { unit: 'day', step: 1, format: '%j' },
      ],
    },
    // hours
    {
      scales: [
        { subscale_unit: 'hour', unit: 'day', step: 1, format: '%j %M' },
        { unit: 'hour', step: 1, format: '%H:%i' },
      ],
    },
    // minutes
    {
      scales: [
        { subscale_unit: 'minute', unit: 'hour', step: 1, format: '%H' },
        { unit: 'minute', step: 1, format: '%H:%i' },
      ],
    },
  ];
  // get number of columns in timeline
  const getUnitsBetween = (from, to, unit, step) => {
    let start = new Date(from);
    const end = new Date(to);
    let units = 0;

    while (start.valueOf() < end.valueOf()) {
      units++;
      start = gantt.date.add(start, step, unit);
    }
    return units;
  };

  for (let i = 0; i < scaleConfigs.length; i++) {
    const columnCount = getUnitsBetween(
      project.start_date,
      project.end_date,
      scaleConfigs[i].scales[0].subscale_unit,
      scaleConfigs[i].scales[0].step,
    );

    if ((columnCount + 2) * gantt.config.min_column_width >= areaWidth) {
      --i;
      break;
    }
  }
};
