import React from 'react';

import useAllocationStackedChart from 'hooks/allocation/useAllocationStackedChart';
// eslint-disable-next-line max-len
import { ChartContainer } from 'routes/Dashboard/AllocationReport/AllocationV2/AllocationReportComponent/AllocationReportComponent';
import ChartContent from 'routes/Dashboard/Dashboards/Charts/ChartContent';

import mapAllocationDataToChart from './mapAllocationData';

const ActualAllocationStackedChart = ({ allocationData, lsState, title, dataKey, displayFormatter, tickYProps }) => {
  const { data, entities, stackEntities } = mapAllocationDataToChart(allocationData, dataKey);

  const [chart] = useAllocationStackedChart(data, entities, stackEntities, lsState, displayFormatter, tickYProps);

  return (
    <ChartContainer bordered>
      <ChartContent title={title}>{chart}</ChartContent>
    </ChartContainer>
  );
};

export default ActualAllocationStackedChart;
