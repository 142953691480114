import { css } from 'styled-components';

import { spacing } from 'design-system/theme';

const agGridMaterialStylesOverride = css`
  .ag-theme-material {
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  .ag-theme-material .ag-header {
    border-color: ${({ theme }) => theme.palette.border.secondary};
  }

  .ag-theme-material .ag-cell,
  .ag-group-contracted,
  .ag-group-expanded {
    padding: 6px;
  }

  .ag-theme-material .ag-header-cell,
  .ag-theme-material .ag-header-group-cell {
    padding: 0 6px !important;
    line-height: 38px !important;
  }

  .ag-theme-material .ag-group-expanded .ag-icon.ag-icon-tree-open:before,
  .ag-theme-material .ag-group-contracted .ag-icon.ag-icon-tree-closed:before {
    font-size: 17px;
    top: 8px;
  }

  .ag-theme-material .ag-row-drag .ag-icon-grip:before {
    opacity: 0.7;
    cursor: grab;
    font-size: 22px;
    margin-top: 3px;
  }

  .ag-theme-material .ag-ltr .ag-row-group-indent-1 {
    padding-left: 26px;
  }

  .ag-theme-material .ag-ltr .ag-row-group-indent-2 {
    padding-left: 52px;
  }

  .ag-theme-material .ag-ltr .ag-row-group-indent-3 {
    padding-left: 78px;
  }

  .ag-theme-material .ag-cell-inline-editing,
  .ag-theme-material .ag-popup-editor {
    background: #fff !important;
  }

  .ag-theme-material .ag-header-cell-resize::after,
  .ag-theme-material .ag-row,
  .ag-theme-material .ag-header-row {
    border-color: ${({ theme }) => theme.palette.border.secondary};
  }

  .no-box-shadow,
  .ag-theme-material .ag-cell-inline-editing.no-box-shadow {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }

  .ag-theme-material .ag-cell {
    line-height: 21px !important;
  }

  .ag-theme-material .ag-cell > div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .ag-theme-material .ag-header-select-all {
    margin-left: 2px;
    margin-top: 3px;
    display: block;
  }

  .over-limit.ag-cell-value {
    background-color: #ffcdd2;
  }

  .cell-uneditable {
    text-align: center;
    background-color: #f8f8f8;
    color: rgba(0, 0, 0, 0.6);
  }

  .metadata-parent-row p,
  .metadata-parent-row div {
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  }

  .metadata-child-row p,
  .metadata-child-row div,
  .metadata-child-row span {
    color: #bcbcbc !important;
  }

  .ag-theme-material .ag-paging-panel {
    padding: 0 45px;
    border-top: none;
  }

  .ag-dnd-ghost {
    position: absolute;
    z-index: 1000000 !important;
    display: none;
  }

  .ag-theme-material .ag-overlay-loading-center {
    width: 50px;
    margin: 0 auto;
    padding: 0;
    box-shadow: none;
    background: transparent;
    visibility: visible !important;
  }

  .ag-theme-material .ag-overlay-loading-center img {
    width: 100%;
  }

  .ag-theme-material .ag-ltr .ag-row-group-indent-4 {
    padding-left: 104px;
  }

  .ag-theme-material .ag-ltr .ag-row-group-indent-5 {
    padding-left: 130px;
  }

  .ag-theme-material input[class^='ag-'][type='text'] {
    border-bottom: 1px solid #e0e0e0;
    height: 24px;
  }

  .ag-theme-material .ag-cell-inline-editing {
    height: initial;
  }

  .ag-theme-material .ag-cell-wrapper,
  .ag-theme-material .ag-group-value {
    width: 100%;
    align-items: center;
    gap: ${spacing()}px;
  }

  .ag-theme-material
    .ag-cell-wrapper
    > *:not(.ag-cell-value):not(.ag-group-value):not(.ag-group-contracted):not(.ag-group-expanded) {
    padding-top: 2px;
  }

  .ag-theme-material .ag-cell-wrapper.ag-cell-expandable > .ag-group-child-count {
    color: #9e9e9e;
    font-size: 12px;
    margin-left: 5px;
    padding-top: 0;
    height: auto;
  }

  .ag-theme-material .ag-side-buttons {
    padding-top: 0;
    background-color: #fafafa;
  }

  .ag-theme-material .ag-selected.ag-selected .ag-side-button-button.ag-side-button-button {
    border-left: unset;
  }

  .ag-theme-material .ag-side-button-button.ag-side-button-button {
    padding: 6px 1px;
    min-height: unset;
    border: 0;
    color: rgba(0, 0, 0, 0.54);
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 700;
  }

  .ag-theme-material.ag-theme-material .ag-group-child-count.ag-group-child-count {
    color: rgba(0, 0, 0, 0.87);
  }

  .ag-theme-material .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
    height: unset;
  }

  .ag-theme-material .ag-checkbox .ag-input-wrapper {
    font-size: 18px !important;
  }

  .ag-theme-material .ag-ltr .ag-row-drag,
  .ag-theme-material .ag-ltr .ag-selection-checkbox {
    margin-right: 0;
  }

  .ag-theme-material .ag-ltr .ag-group-expanded,
  .ag-theme-material .ag-ltr .ag-group-contracted {
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .ag-theme-material .ag-title-cell {
    padding-right: 0;
    padding-left: 0;
  }

  .ag-theme-material .ag-title-cell div,
  .ag-theme-material .ag-title-cell p,
  .ag-theme-material .ag-title-cell span {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  }

  .ag-theme-material .ag-cell-range-left {
    padding-right: 0;
  }
`;

export default agGridMaterialStylesOverride;
