import React, { memo, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { array, func, string, object, bool, arrayOf } from 'prop-types';
import { Map } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { CSS_SCOPE_CLASS } from 'design-system/molecules/AgGridReact-New';

import AgGridGroupRowInnerRenderer from 'components/AgGridGroupRowInnerRenderer';
import usePrevious from 'hooks/usePrevious';

import useAgGrid from './hooks/useAgGrid';

import moveInArray from './moveInArray';
import externalProcessDataFromClipboard from './processDataFromClipboard';
import { BaseGrid } from 'containers/Grids';

const sortListByListOrder = (originalList, listOrder) => {
  return originalList.sort((a, b) => {
    return listOrder.indexOf(a.id) - listOrder.indexOf(b.id);
  });
};

const getNodeByIndex = (index, rowData) => {
  return rowData.find(node => node.rowIndex === index);
};

const getNodeByDataId = (id, rowData) => {
  return rowData.find(node => node.data && node.data.id === id);
};

const DataGrid = memo(
  ({
    focusField = 'title',
    gridOptions = {},
    mustNotFocusOnCreatingNewRow = false,
    gridState = {},
    adjustColumnsWidth = true,
    disableFocusOnNewRow = false,
    defaultFilters = {},
    pasteFields = [],
    isGridWithoutIds,
    height,
    remove,
    saveNew,
    updateById,
    columnDefs,
    pasteToUnlimitedCells,
    afterProcessClipboardData,
    labels,
    saveGridState,
    onColumnVisible,
    onGettingGridApis,
    rowData,
    switchRowOrder,
    getRowId,
  }) => {
    const [stateThrottle, setStateThrottle] = useState(0);

    const [stateColumnDefs, setStateColumnDefs] = useState(columnDefs);

    const [stateChangingColumnDefs, setStateChangingColumnDefs] = useState(false);

    const mounted = useRef(false);

    const gridStateBeforeChangeColumnDefsRef = useRef(null);

    const shouldSetFilterModelRef = useRef(false);

    const lastOverNodeRef = useRef(null);

    const rowDataBeforeDragRef = useRef(null);

    const movingNodeIndexRef = useRef(null);

    const actualGridDataOrderRef = useRef(null);

    const previousRowData = usePrevious(cloneDeep(rowData));

    // necessary to avoid a focus problem that happens on adding one row to the grid
    // adding an empty row causes an unexpected saving of grid state, which removes focus from the field
    const prevGridState = usePrevious(gridState);

    const groupRowInnerRenderer = useMemo(() => (labels ? AgGridGroupRowInnerRenderer(labels) : undefined), [labels]);

    const {
      isGridReady,
      getGridApi,
      getGridColumnApi,
      initAgGrid,
      methods: { takeGridSnapshot: agGridTakeGridSnapshot },
    } = useAgGrid();

    const processColumnDefs = useCallback(
      columnDefs => {
        const processClipboardData = data => {
          getGridApi().stopEditing();

          return externalProcessDataFromClipboard(
            data,
            getGridApi(),
            getGridColumnApi(),
            columnDefs,
            isGridWithoutIds,
            pasteToUnlimitedCells,
          ).then(afterProcessClipboardData);
        };

        // Enable call afterProcessClipboardData prop with data processed from the clipboard in some fields
        // The component associated to the column must detect paste event and
        // call function passed by parameter with data from the clipboard.
        if (afterProcessClipboardData && pasteFields?.length) {
          columnDefs = columnDefs.map(column => {
            if (pasteFields.includes(column.field)) {
              return {
                ...column,
                cellEditorParams: {
                  ...(column.cellEditorParams || {}),
                  onPaste: processClipboardData,
                },
              };
            }
            return column;
          });
        }

        return columnDefs;
      },
      [columnDefs, isGridWithoutIds, pasteToUnlimitedCells, afterProcessClipboardData, pasteFields],
    );

    const getSelectedCell = useCallback(() => {
      const gridApi = getGridApi();

      const rangeSelections = gridApi.getCellRanges();

      if (rangeSelections && rangeSelections.length) {
        const nodes = [];

        gridApi.forEachNode(node => nodes.push(node));

        return rangeSelections[0].start;
      }

      return null;
    }, []);

    const processDataFromClipboard = useCallback(
      params => {
        const copyPaste = externalProcessDataFromClipboard(
          params.data,
          getGridApi(),
          getGridColumnApi(),
          columnDefs,
          isGridWithoutIds,
          pasteToUnlimitedCells,
        );

        if (afterProcessClipboardData) {
          copyPaste.then(afterProcessClipboardData);
        }

        copyPaste.then(_ => {
          const selectedCell = getSelectedCell();

          if (selectedCell) {
            getGridApi().setFocusedCell(selectedCell.rowIndex, selectedCell.column.colId);
          }
        });
      },
      [columnDefs, isGridWithoutIds, pasteToUnlimitedCells, afterProcessClipboardData],
    );

    const getSortModel = useCallback(() => {
      return getGridColumnApi()
        .getColumnState()
        .filter(c => !!c.sort);
    }, []);

    const getGridState = useCallback(() => {
      return isGridReady()
        ? {
            columnState: getGridColumnApi().getColumnState(),
            sortState: getSortModel(),
            filterState: getGridApi().getFilterModel(),
          }
        : {};
    }, [getSortModel]);

    const defaultGroupSortComparator = useCallback(
      ({ nodeA, nodeB }) => {
        if (!isGridReady() || !nodeA || !nodeB) {
          return 0;
        } else if (nodeA.key && !nodeB.key) {
          return -1;
        } else if (nodeB.key && !nodeA.key) {
          return 1;
        } else if (!nodeB.key && !nodeA.key) {
          return 0;
        }

        const sortModel = getSortModel();

        const sortValue = sortModel.length && nodeA.field === sortModel[0].colId ? sortModel[0].sort : 'asc';

        const nodeAValue = nodeA.key.trim().toUpperCase();

        const nodeBValue = nodeB.key.trim().toUpperCase();

        if ((sortValue === 'asc' && nodeAValue < nodeBValue) || (sortValue === 'desc' && nodeAValue > nodeBValue)) {
          return -1;
        } else if ((sortValue === 'asc' && nodeAValue > nodeBValue) || (sortValue === 'desc' && nodeAValue < nodeBValue)) {
          return 1;
        }

        return 0;
      },
      [getSortModel],
    );

    /**
     * On change sort model it suppresses drag if grid rows are sorted
     */
    const postSort = useCallback(({ api, columnApi }) => {
      if (columnApi && columnApi.getColumnState().every(columState => columState.sort)) {
        api.setSuppressRowDrag(true);
      } else if (columnApi && !columnApi.getColumnState().some(columState => columState.sort)) {
        api.setSuppressRowDrag(false);
      }
    }, []);

    const clearFiltersAndSort = useCallback(() => {
      return new Promise(resolve => {
        if (isGridReady()) {
          const gridApi = getGridApi();

          gridApi.setFilterModel(defaultFilters);

          gridApi.setSortModel();

          return setTimeout(resolve, 0);
        }

        return resolve();
      });
    }, []);

    const autoResize = useCallback(() => {
      if (isGridReady() && adjustColumnsWidth) {
        const allColumnIds = getGridColumnApi()
          .getColumns()
          .map(column => column.colId);

        getGridColumnApi().autoSizeColumns(allColumnIds);
      }
    }, [adjustColumnsWidth]);

    const handleCellEditingStopped = useCallback(
      e => {
        if (!e.data.id && !e.data[focusField]) {
          return remove();
        }
      },
      [focusField, remove],
    );

    const handleCellValueChanged = useCallback(
      cell => {
        const {
          data,
          colDef: { field },
          newValue,
          oldValue,
          rowIndex,
        } = cell;

        const { id } = data;

        if (isGridWithoutIds) {
          return updateById(rowIndex, {
            [field]: newValue,
          });
        }

        // create new project if it has a value on focus field and does not have an id
        if (!id && field === focusField && data[focusField]) {
          return saveNew(data).catch(remove);
        } else if (!id && field === focusField) {
          // if user did not type a value on focus field then remove row from grid
          return remove();
        } else if (oldValue === newValue || (!id && field !== focusField)) {
          // if cell value did not change or a cell different from value of
          // focus field in a new row is being updated then don't do nothing
          return;
        }

        const result = updateById(id, {
          [field]: newValue,
        });

        if (result) {
          result.catch(_ => {
            try {
              cell.node.setData({
                ...cell.node.data,
                [field]: oldValue,
              });
            } catch {
              // pass
            }
          });
        }
      },
      [saveNew, remove, updateById, focusField, isGridWithoutIds],
    );

    const persistGridState = useCallback(() => {
      const gridState = getGridState();

      if (!stateThrottle && !stateChangingColumnDefs && !isEqual(gridState, prevGridState)) {
        setStateThrottle(1);

        setTimeout(() => {
          setStateThrottle(0);
        }, 3000);

        saveGridState(gridState);

        // this.prevGridState = gridState; // TODO
      }
    }, [getGridState, stateThrottle, stateChangingColumnDefs, prevGridState, saveGridState]);

    const expandAllNodes = useCallback(() => {
      getGridApi().forEachNode(node => {
        if (node.group) {
          try {
            node.setExpanded(true);
          } catch {
            // pass
          }
        }
      });
    }, []);

    const takeGridSnapshot = useCallback(() => {
      expandAllNodes();

      return agGridTakeGridSnapshot();
    }, [expandAllNodes, agGridTakeGridSnapshot]);

    /**
     * Return list of fields by which row data is grouped
     */
    const getGroupModel = useCallback(() => {
      const columnState = getGridColumnApi().getColumnState();

      return columnState.filter(column => column.rowGroupIndex !== null).map(column => column.colId);
    }, []);

    const focusOnRow = useCallback(
      (row, column) => {
        const gridApi = getGridApi();

        const renderedNodes = gridApi.getRenderedNodes();

        const groupModel = getGroupModel();

        if (groupModel.length) {
          expandAllNodes();
        }

        const editCellIndex = renderedNodes.findIndex(node => !node.group && row.id === node.data.id);

        if (editCellIndex >= 0) {
          if (gridOptions?.pagination) {
            gridApi.paginationGoToPage(0);
          }

          gridApi.setFocusedCell(editCellIndex, column);

          gridApi.startEditingCell({
            rowIndex: editCellIndex,
            colKey: column,
          });
        }
      },
      [getGroupModel, expandAllNodes, gridOptions],
    );

    const onGridReady = useCallback(
      params => {
        initAgGrid(params.api, params.columnApi);

        if (gridState) {
          const { columnState, sortState, filterState } = Map.isMap(gridState) ? gridState.toJS() : gridState;

          if (columnState) {
            params.columnApi.applyColumnState({
              state: columnState,
              applyOrder: true,
            });
          }
          if (sortState) {
            params.api.setSortModel(sortState);
          }
          if (filterState) {
            params.api.setFilterModel(filterState);
          }
        }

        if (saveGridState && gridState) {
          const events = [
            'columnResized',
            'sortChanged',
            'filterChanged',
            'gridColumnsChanged',
            'displayedColumnsChanged',
            'floatingRowDataChanged',
          ];

          params.api.addEventListener('columnRowGroupChanged', () => {
            const columnState = params.columnApi.getColumnState().map(column => {
              if (column.rowGroupIndex !== null && column.rowGroupIndex >= 0) {
                return {
                  ...column,
                  hide: true,
                };
              }

              return column;
            });

            params.columnApi.applyColumnState({
              state: columnState,
              applyOrder: true,
            });
          });

          events.forEach(eventName => {
            params.api.addEventListener(eventName, persistGridState);
          });
        }

        if (onColumnVisible) {
          params.api.addEventListener('columnVisible', onColumnVisible);
        }

        autoResize();
        params.api.addEventListener('firstDataRendered', autoResize);

        if (onGettingGridApis) {
          onGettingGridApis(params);
        }

        if (columnDefs) {
          setStateColumnDefs(processColumnDefs(columnDefs));
        }
      },
      [gridState, saveGridState, onColumnVisible, autoResize, onGettingGridApis, columnDefs, processColumnDefs],
    );

    const onRowDragEnter = useCallback(
      node => {
        lastOverNodeRef.current = null;

        rowDataBeforeDragRef.current = takeGridSnapshot();

        movingNodeIndexRef.current = node.overIndex;
      },
      [takeGridSnapshot],
    );

    const onRowDragMove = useCallback(
      event => {
        const gridApi = getGridApi();

        // _actualGridDataOrder saves the current order of rows after changing the order of rows in the grid by ag-grid api
        // it helps to set the correct order of moving row
        const rowsList = actualGridDataOrderRef.current ? sortListByListOrder(rowData, actualGridDataOrderRef.current) : rowData;

        const ids = rowsList.map(row => row.id);

        const movingNode = event.node;

        const { overNode } = event;

        const rowNeedsToMove = movingNode !== overNode;

        if (rowNeedsToMove && overNode && overNode.group && movingNode.data[overNode.field] !== overNode.key) {
          const movingData = movingNode.data;

          movingData[overNode.field] = overNode.key;

          gridApi.updateRowData({ update: [movingData] });

          if (overNode.allChildrenCount > 0) {
            const firstGroupChildren = overNode.childrenAfterFilter[0];

            if (firstGroupChildren && firstGroupChildren.data && firstGroupChildren.data.id) {
              const fromIndex = ids.indexOf(movingData.id);

              const toIndex = ids.indexOf(firstGroupChildren.data.id) - 1;

              const newStore = moveInArray(rowsList.slice(), fromIndex, toIndex);

              gridApi.setRowData(newStore);

              actualGridDataOrderRef.current = newStore.map(row => row.id);
            }
          }

          gridApi.clearFocusedCell();
        } else if (rowNeedsToMove && overNode && overNode.data) {
          const movingData = movingNode.data;

          const overData = overNode.data;

          const groupModel = getGroupModel();

          if (groupModel.length) {
            // assign update to object with grouped fields values of over node
            groupModel.forEach(field => {
              if (overData[field] !== movingData[field]) {
                movingData[field] = overData[field];

                gridApi.updateRowData({ update: [movingData] });
              }
            });
          }

          const fromIndex = ids.indexOf(movingData.id);

          const toIndex = ids.indexOf(overData.id);

          const newStore = moveInArray(rowsList.slice(), fromIndex, toIndex);

          gridApi.setRowData(newStore);

          actualGridDataOrderRef.current = newStore.map(row => row.id);

          gridApi.clearFocusedCell();
        }
      },
      [rowData, getGroupModel],
    );

    const onRowDragEnd = useCallback(
      event => {
        const movingNode = getNodeByDataId(event.node.data.id, rowDataBeforeDragRef.current);

        const overNode = getNodeByIndex(event.overIndex, rowDataBeforeDragRef.current);

        actualGridDataOrderRef.current = null;

        if (!overNode) {
          return getGridApi().setRowData(cloneDeep(rowData));
        }

        // Don't need to switch
        if (movingNode.data === overNode.data) {
          return;
        }

        const groupModel = getGroupModel();

        // if moving node status is different of over node then update moving data status
        if (
          groupModel.length &&
          movingNode.data.id &&
          !overNode.group &&
          groupModel.find(field => overNode.data[field] !== movingNode.data[field])
        ) {
          // assign update to object with grouped fields values of over node
          const update = groupModel.reduce((final, field) => {
            final[field] = overNode.data[field];

            return final;
          }, {});

          // if moving node group is below over node group,
          // then the moving node should be placed below the row where is the cursor
          const positionToMove = movingNode.parentRowIndex > overNode.parentRowIndex ? 'top' : 'bottom';

          return switchRowOrder(movingNode.data.id, overNode.data.id, update, positionToMove);
        } else if (movingNode.data.id && overNode.group) {
          const update = {};

          let actualNode = overNode;

          while (actualNode.level !== -1) {
            update[actualNode.field] = actualNode.key;

            actualNode = actualNode.parent;
          }

          if (overNode.childrenAfterFilter && overNode.childrenAfterFilter.length) {
            // when a row is over the group,
            // the row should be always placed on the top of the group
            return switchRowOrder(movingNode.data.id, overNode.childrenAfterFilter[0], update, 'top');
          }
          return updateById(movingNode.data.id, update);
        } else if (!overNode.group && overNode.data.id) {
          return switchRowOrder(movingNode.data.id, overNode.data.id);
        }
      },
      [switchRowOrder, updateById, rowData, getGroupModel],
    );

    const onRowDragLeave = useCallback(() => {
      actualGridDataOrderRef.current = null;

      return getGridApi().setRowData(cloneDeep(rowData));
    }, [rowData]);

    useEffect(() => {
      if (isGridReady() && !isEqual(columnDefs, stateColumnDefs)) {
        gridStateBeforeChangeColumnDefsRef.current = getGridState();

        setStateChangingColumnDefs(true);

        setStateColumnDefs(processColumnDefs(columnDefs));

        shouldSetFilterModelRef.current = true;
      }
    }, [columnDefs, processColumnDefs, getGridState]);

    useEffect(() => {
      autoResize();
    }, [rowData]);

    useEffect(() => {
      if (!mounted.current) {
        mounted.current = true;
      } else {
        if (!isGridReady()) {
          return;
        }

        const gridApi = getGridApi();

        if (previousRowData !== rowData) {
          if (!disableFocusOnNewRow && previousRowData !== rowData && rowData.length && !rowData[0].id) {
            clearFiltersAndSort().then(() => {
              if (!mustNotFocusOnCreatingNewRow) {
                focusOnRow(rowData[0], focusField);
              }
            });
          } else if (
            previousRowData &&
            previousRowData !== rowData &&
            previousRowData.length &&
            rowData.length &&
            !previousRowData[0].id &&
            rowData[0].id &&
            previousRowData.length <= rowData.length
          ) {
            // fix navigation on tab while creating new row
            const selectedCell = getSelectedCell();

            gridApi.clearRangeSelection();

            if (selectedCell && selectedCell.rowIndex === 0 && selectedCell.column.colId !== focusField) {
              gridApi.startEditingCell({
                rowIndex: selectedCell.rowIndex,
                colKey: selectedCell.column.colId,
              });
            }
          } else if (previousRowData !== rowData && rowData.length && rowData[0].id) {
            setTimeout(() => {
              gridApi.resetRowHeights();
            }, 0);
          }
        }
      }
    });

    useEffect(() => {
      if (gridStateBeforeChangeColumnDefsRef.current && shouldSetFilterModelRef.current) {
        getGridApi().setFilterModel(gridStateBeforeChangeColumnDefsRef.current.filterState);

        shouldSetFilterModelRef.current = false;
      }

      setStateChangingColumnDefs(false);
    }, [stateColumnDefs]);

    useEffect(() => {
      return () => {
        if (isGridReady()) {
          getGridApi().destroy();
        }
      };
    }, []);

    return (
      <div className={CSS_SCOPE_CLASS}>
        <BaseGrid
          columnDefs={stateColumnDefs}
          rowData={rowData}
          onGridReady={onGridReady}
          // stopEditingWhenCellsLoseFocus
          enableSorting
          enableFilter
          enableColResize
          rowSelection="multiple"
          suppressRowClickSelection
          suppressDragLeaveHidesColumns
          {...(isGridWithoutIds ? {} : { deltaRowDataMode: true, getRowNodeId: data => data.id })}
          postSortRows={postSort}
          initialGroupOrderComparator={defaultGroupSortComparator}
          animateRows
          onRowDragEnter={onRowDragEnter}
          onRowDragMove={onRowDragMove}
          onRowDragEnd={onRowDragEnd}
          onRowDragLeave={onRowDragLeave}
          enableRangeSelection
          processDataFromClipboard={processDataFromClipboard}
          suppressCopyRowsToClipboard
          onCellValueChanged={handleCellValueChanged}
          onCellEditingStopped={handleCellEditingStopped}
          localeText={{
            noRowsToShow: ' ',
          }}
          groupRowRendererParams={{
            ...(gridOptions?.groupRowRendererParams || {}),
            innerRenderer: groupRowInnerRenderer,
          }}
          groupDefaultExpanded
          groupDisplayType="groupRows"
          rowHeight={38}
          headerHeight={38}
          height={height}
          getRowId={getRowId}
          {...gridOptions}
        />
      </div>
    );
  },
);

DataGrid.displayName = 'DataGrid';

DataGrid.propTypes = {
  columnDefs: array.isRequired,
  rowData: array.isRequired,
  updateById: func.isRequired,
  switchRowOrder: func,
  remove: func.isRequired,
  saveNew: func.isRequired,
  focusField: string,
  gridOptions: object,
  mustNotFocusOnCreatingNewRow: bool,
  saveGridState: func,
  gridState: object,
  onColumnVisible: func,
  afterProcessClipboardData: func,
  adjustColumnsWidth: bool,
  defaultFilters: object,
  pasteFields: arrayOf(string),
  disableFocusOnNewRow: bool,
  isGridWithoutIds: bool, // used in copy paste feature
};

export default DataGrid;
