import React from 'react';
import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';
import MuiInput from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

import SearchIcon from '@material-ui/icons/Search';

export default ({ search, onChange }) => {
  return (
    <Wrapper>
      <div style={{ display: 'inline-block' }}>
        <Input
          value={search}
          onChange={onChange}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(Paper)`
  &&&& {
    width: 200px;
    padding: 10px 13px;
  }
`;

const Input = styled(MuiInput)`
  &&&& {
    font-size: 16px;
  }
`;
