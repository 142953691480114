/* eslint-disable no-mixed-operators */
import moment from 'moment-timezone';

import theme, { spacing } from 'design-system/theme';

export const DAY = 'day';
export const WEEK = 'week';
export const MONTH = 'month';
export const QUARTER = 'quarter';
export const YEAR = 'year';

const DEFAULT_MAX_Y_TICKS = 10;

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_DISPLAY_FORMATS = {
  day: 'YYYY/MM/DD',
  week: 'YYYY/MM/DD',
  month: 'MMMM YYYY',
  quarter: '\\QQ YYYY',
  year: 'YYYY',
};

export const getTimeUnit = (startDate, endDate) => {
  if (!moment(startDate).isValid() || !moment(endDate).isValid()) return;

  const duration = moment.duration(moment(endDate).diff(moment(startDate)));

  if (duration.asWeeks() < 1) {
    return DAY;
  } else if (duration.asMonths() < 1) {
    return WEEK;
  } else if (duration.asYears() < 1) {
    return MONTH;
  } else if (duration.asYears() < 2) {
    return QUARTER;
  }
  return YEAR;
};

export const getTicksInRange = (startDate, endDate, unit) => {
  if (!moment(startDate).isValid() || !moment(endDate).isValid()) return;

  const start = moment(startDate).startOf(unit);
  const end = moment(endDate).endOf(unit);

  const ticks = [];
  const current = moment(start);

  while (current.isSameOrBefore(end)) {
    ticks.push(current.format(DATE_FORMAT));
    current.add(1, unit).startOf(unit);
  }

  const lastTick = ticks[ticks.length - 1];
  const lastTickIsBeforeEndDate = moment(lastTick).startOf('day').isBefore(end.startOf('day'));

  if (lastTick && lastTickIsBeforeEndDate) {
    ticks.push(end.add(1, unit).startOf(unit).format(DATE_FORMAT));
  }

  return ticks;
};

export const xScaleOptionsForDates = (timeUnit, datesForChart, dateDisplayFormats) => ({
  type: 'time',
  time: {
    unit: timeUnit,
    displayFormats: dateDisplayFormats || DATE_DISPLAY_FORMATS,
  },
  min: datesForChart[0],
  max: datesForChart[datesForChart.length - 1],
  ticks: {
    display: true,
    autoSkip: false,
    source: 'labels',
    padding: spacing(),
    color: theme.palette.charts.ticks,
    font: {
      size: `9px`,
    },
  },
  grid: {
    display: false,
  },
});

const calculateTickSpacing = (minValue, maxValue, maxTicks) => {
  const dataRange = maxValue - minValue;

  return Math.ceil(dataRange / maxTicks);
};

export const yScaleOptionsForNumbers = (minValue, maxValue, usePercentages) => {
  const tickSpacing = calculateTickSpacing(minValue, maxValue, DEFAULT_MAX_Y_TICKS);
  const minTick = Math.floor(minValue / tickSpacing) * tickSpacing - tickSpacing;
  const maxTick = Math.ceil(maxValue / tickSpacing) * tickSpacing + tickSpacing;

  return {
    suggestedMin: Math.max(minTick, 0),
    suggestedMax: maxTick,
    ticks: {
      maxTicksLimit: DEFAULT_MAX_Y_TICKS,
      callback: value => (usePercentages ? `${Math.round(value)} %` : Math.round(value).toLocaleString('en-US')),
      padding: spacing(2),
      color: theme.palette.charts.ticks,
      font: {
        size: `9px`,
      },
    },
    grid: {
      color: theme.palette.charts.gridLines,
      tickLength: 0,
    },
  };
};
