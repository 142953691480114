import styled, { css } from 'styled-components';
import TextField from '@material-ui/core/TextField';

export default styled(TextField)`
  &&&& {
    > div::before {
      border-bottom: 0 !important;
    }

    > div::after {
      border-bottom: 0 !important;
    }

    textarea {
      ${({ $fontSize }) => $fontSize && `font-size: ${$fontSize}px;`}

      ${props =>
        props.multiline &&
        css`
          overflow: hidden;
        `}
    }

    ${({ $width }) =>
      $width &&
      css`
        width: ${$width}px;
      `}

    ${props =>
      props.fullWidth &&
      css`
        width: 100%;
      `}

    ${props =>
      props.useEllipsis &&
      css`
        input {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      `}

    ${props =>
      props.multiline &&
      props.rowsMax &&
      css`
        &&& textarea {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: ${props.rowsMax};
          overflow: hidden;
          word-wrap: break-word;
        }
      `}

    ${props =>
      props.error &&
      css`
        > div::after {
          border-bottom: 2px solid ${props.theme.palette.text.error} !important;
        }
      `}
  }
`;
