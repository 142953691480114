import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import omit from 'lodash/omit';
import { toast } from 'react-toastify';

import getRandomString from 'utils/getRandomString';
import { setAppGlobalConfig } from 'store/app';
import { getTeams } from 'store/teams/selectors';
import { inviteUsers } from 'store/users';
import validateEmail from 'utils/validateEmail';
import validateSharedViewURL, { isDashboardViewUrl } from 'utils/validateSharedViewURL';
import { getCurrentUser } from 'store/login/selectors';
import { getUserViews } from 'store/userViews/selectors';
import { READ_ONLY_USER } from '@dragonboat/permissions';
import ToastMessage from 'design-system/atoms/ToastMessage/index';

import { getOrganization } from 'store/organization/selectors';
import checkIfUserIsAdmin from 'utils/checkIfUserIsAdmin';

const DEAFULT_USERS_TO_INVITE = [{}, {}, {}];

const generateNewUser = () => ({
  rowId: getRandomString(),
  email: '',
  name: '',
  role_id: READ_ONLY_USER,
  team_id: null,
  shared_view: '',
});

/**
 *
 * @param {*} Component
 * @returns
 */
const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();

    const [usersToInvite, setUsersToInvite] = React.useState([]);
    const [showBulkImportDialog, setShowBulkImportDialog] = React.useState(false);

    const isOpen = useSelector(state => state.app.showInviteUsersDialog);
    const teams = useSelector(getTeams);
    const currentUser = useSelector(getCurrentUser);
    const organization = useSelector(getOrganization);
    const userViews = useSelector(getUserViews);
    const isPortalSettingsEnabled = organization?.enable_portal_settings;

    const _userIsAdmin = checkIfUserIsAdmin(currentUser);

    const parseDashboardViewUrl = url => {
      // If a view is from dashboard page, we need to parse the URL so it has the
      // same format as all other views - 'url.com?sharedView=key'
      const urlArr = url.split('/');
      const viewId = urlArr.pop();
      const userViewKey = userViews.find(view => view.id === Number(viewId))?.key;

      urlArr[urlArr.length - 1] = `${urlArr[urlArr.length - 1]}?sharedView=${userViewKey}`;

      return urlArr.join('/');
    };

    const _validateRows = rows => {
      let isValid = true;

      const data = rows
        .map(row => {
          const { email, name, role_id: roleId, shared_view: sharedView } = row;
          const errors = {};
          const _setError = (field, error) => {
            isValid = false;

            errors[field] = error;
          };

          if (!email && !name) return null;

          if (!name) _setError('name', 'Name is required');
          if (!email) _setError('email', 'Email is required');
          if (email && !validateEmail(email)) _setError('email', 'Email is not valid');
          if (sharedView && !validateSharedViewURL(sharedView)) _setError('shared_view', 'Shared View URL is not valid');
          if (!roleId) _setError('role_id', 'Role is required');

          return {
            ...row,
            shared_view: isDashboardViewUrl(sharedView) ? parseDashboardViewUrl(sharedView) : sharedView,
            errors,
          };
        })
        .filter(row => !!row);

      return [data, isValid];
    };
    const _addNewUserToInvite = React.useCallback(() => {
      setUsersToInvite([...usersToInvite, generateNewUser()]);
    }, [usersToInvite]);
    const _removeUserToInvite = React.useCallback(
      rowId => {
        setUsersToInvite(usersToInvite.filter(u => u.rowId !== rowId));
      },
      [usersToInvite],
    );
    const _updateUserToInvite = React.useCallback(
      (rowId, change) => {
        setUsersToInvite(usersToInvite.map(u => (u.rowId === rowId ? { ...u, ...change } : u)));
      },
      [usersToInvite],
    );
    const _onClose = React.useCallback(() => {
      dispatch(setAppGlobalConfig({ showInviteUsersDialog: false }));
    }, []);
    const _onSubmit = React.useCallback(async () => {
      const [data, valid] = _validateRows(usersToInvite);

      if (!valid) {
        setUsersToInvite(usersToInvite.map(u => data.find(d => d.rowId === u.rowId) || omit(u, ['errors'])));
        return;
      }
      if (data.length > 0) {
        await dispatch(inviteUsers(data.map(row => omit(row, ['errors', 'rowId']))));

        toast(<ToastMessage title="Invites Sent" description="Your teammates have been invited." />, {
          autoClose: 30000,
        });
      }

      _onClose();
    }, [usersToInvite]);
    const _onBulkImport = React.useCallback(() => {
      setShowBulkImportDialog(true);
      _onClose();
    }, []);
    const _onCloseBulkImportDialog = React.useCallback(() => {
      setShowBulkImportDialog(false);
    }, []);

    React.useEffect(() => {
      setUsersToInvite(DEAFULT_USERS_TO_INVITE.map(() => generateNewUser()));
    }, [isOpen]);

    return (
      <Component
        {...props}
        isOpen={isOpen}
        onClose={_onClose}
        usersToInvite={usersToInvite}
        addNewUserToInvite={_addNewUserToInvite}
        removeUserToInvite={_removeUserToInvite}
        updateUserToInvite={_updateUserToInvite}
        onSubmit={_onSubmit}
        onBulkImport={_onBulkImport}
        teams={teams}
        showBulkImportDialog={showBulkImportDialog}
        onCloseBulkImportDialog={_onCloseBulkImportDialog}
        currentUser={currentUser}
        isPortalSettingsEnabled={isPortalSettingsEnabled}
        canSelectRole={_userIsAdmin}
      />
    );
  };
};

export default componentHOC;
