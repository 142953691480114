import React from 'react';
import Highlighter from 'react-highlight-words';

import { ACTIVE_STATUS } from 'constants/common';
import {
  KEY_RESULT_1_LEVEL,
  OBJECTIVE_CORP_ICON_TYPE,
  OBJECTIVE_ICON_TYPE,
  KEY_RESULT_1_ICON_TYPE,
  KEY_RESULT_2_ICON_TYPE,
} from 'constants/objectives';

import { OBJECT_OBJECTIVE, OBJECT_OBJECTIVE_CORP_STRING } from 'store/objectives/types';

import { Wrapper, Bold, StyledObjectiveType, TextWrapper } from './styled';
import Actions from './Actions';

const GroupHeaderInnerRenderer = params => {
  const { hideIcon = () => false } = params;

  if (!params.data) {
    return <span />;
  }

  const isActive = params.data.status === ACTIVE_STATUS;

  const { isTreeView = false } = params.context;
  const nodeIsNotTopLevel = Boolean(params.node.level);
  const { getNumberOfTextRowsToDisplayOnGridCell } = params;

  const isTopLevel = !nodeIsNotTopLevel;
  const isLowerLevel = nodeIsNotTopLevel;
  const noChildren = !params.node.allChildrenCount;
  const hasParent = Boolean(params.data.parent_id);
  const shouldIndentTopLevel = isTopLevel && isTreeView && noChildren;

  const textValue = params.searchString ? (
    <Highlighter searchWords={[params.searchString]} autoEscape textToHighlight={params.value} />
  ) : (
    params.value
  );

  if (Number(params.data.type) <= Number(OBJECT_OBJECTIVE)) {
    const shouldIndentLowerLevels = isLowerLevel && hasParent && noChildren;

    return (
      <Wrapper $indent={shouldIndentTopLevel || shouldIndentLowerLevels}>
        {!hideIcon(params?.value) && (
          <StyledObjectiveType
            type={params.data.level === OBJECT_OBJECTIVE_CORP_STRING ? OBJECTIVE_CORP_ICON_TYPE : OBJECTIVE_ICON_TYPE}
            active={isActive}
          />
        )}
        <TextWrapper
          $nOfTextRowsToDisplay={getNumberOfTextRowsToDisplayOnGridCell && getNumberOfTextRowsToDisplayOnGridCell()}
          $active={isActive}
        >
          <Bold>{textValue}</Bold>
        </TextWrapper>
        <Actions {...params} />
      </Wrapper>
    );
  }

  const isKeyResultLvl1 = params.data.level === KEY_RESULT_1_LEVEL;

  const shouldIndentLowerLevels = isLowerLevel && noChildren;

  return (
    <Wrapper $indent={shouldIndentTopLevel || shouldIndentLowerLevels}>
      {!hideIcon(params?.value) && (
        <StyledObjectiveType type={isKeyResultLvl1 ? KEY_RESULT_1_ICON_TYPE : KEY_RESULT_2_ICON_TYPE} active={isActive} />
      )}
      <TextWrapper
        $nOfTextRowsToDisplay={getNumberOfTextRowsToDisplayOnGridCell && getNumberOfTextRowsToDisplayOnGridCell()}
        $active={isActive}
      >
        {textValue}
      </TextWrapper>
      <Actions {...params} />
    </Wrapper>
  );
};

export default GroupHeaderInnerRenderer;
