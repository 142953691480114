import { isEmpty, values, not, keys, pipe, type, equals } from 'ramda';

import { ROADMAPS, TIMEFRAMES, PRIORITIES } from 'constants/common';
import { COMMITTED_KEY, EXCLUDE_UNCOMMITTED_OPTION, INCLUDE_ALL_OPTION } from 'constants/projects';

const OBJECT_TYPE = 'Object';

const isObject = pipe(type, equals(OBJECT_TYPE));

/*
 * Check if is an object and on that case it means that is a parent children structure
 *
 * That structure is something like:
 *    {
 *      [parentId]: { [childId]: true }
 *    }
 */
const isParentChildrenValue = isObject;

/**
 * @function makefilterProjectBasedOnSelectedTeams
 *
 * make filter function to filter projects based on selected teams
 *  - Will check all estimates on the projects and filter only those that
 *    have any esttimates on selected teams
 *
 * @param  {Object} unselectedTeams
 * @return {Function}
 */
const makefilterProjectBasedOnSelectedTeams =
  (unselectedTeams = {}) =>
  project => {
    const { estimates } = project;

    const anySelectedTeam = isEmpty(values(unselectedTeams).filter(Boolean));

    if (anySelectedTeam) {
      return true;
    }

    /*
     * Should show only projects that has any estimate with selected teams
     */
    const hasAnyEstimateInSelectedTeams = estimates.some(estimate => {
      const isIgnored = unselectedTeams[estimate.team_id];

      if (isIgnored) {
        return false;
      }

      return true;
    });

    return hasAnyEstimateInSelectedTeams;
  };

/**
 * @function makeFilterProjectBasedOnPlanOption
 *
 * Make filter function to filter projects based on selected filter on
 * plan based on autocomplete filter options
 *
 * @param  {Object} selectedOptionsOnFilterToPlan
 * @return {Function}
 */
const makeFilterProjectBasedOnPlanOption = selectedOptionsOnFilterToPlan => project => {
  const filterByRoadmapsAndProducts = selectedOptions => {
    if (isParentChildrenValue(selectedOptions[project.roadmap_id])) {
      return not(selectedOptions[project.roadmap_id][project.product_1_id]);
    }

    return not(selectedOptions[project.roadmap_id]);
  };
  const filterByTimeframes = selectedOptions => {
    if (isParentChildrenValue(selectedOptions[project.timeframe_id])) {
      return not(selectedOptions[project.timeframe_id][project.timeframe_2_id]);
    }

    return not(selectedOptions[project.timeframe_id]);
  };
  const filterByPriorities = selectedOptions => {
    return not(selectedOptions[project.priority_id]);
  };

  const filterByCommitted = (includeCommittedOption = INCLUDE_ALL_OPTION) => {
    const includeUncommitted = includeCommittedOption !== EXCLUDE_UNCOMMITTED_OPTION;

    const projectCommitted = project?.committed;

    return projectCommitted || includeUncommitted;
  };

  const filterByPlanOption = {
    [ROADMAPS]: filterByRoadmapsAndProducts,
    [TIMEFRAMES]: filterByTimeframes,
    [PRIORITIES]: filterByPriorities,
    [COMMITTED_KEY]: filterByCommitted,
  };

  return keys(selectedOptionsOnFilterToPlan).every(planKey => {
    const filterFn = filterByPlanOption[planKey];

    if (not(filterFn)) {
      return true;
    }

    return filterFn(selectedOptionsOnFilterToPlan[planKey]);
  });
};

export { makefilterProjectBasedOnSelectedTeams, makeFilterProjectBasedOnPlanOption };
