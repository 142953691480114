import React from 'react';
import { toast } from 'react-toastify';
import { not, propEq, pipe, has, __, path, defaultTo, keys, head } from 'ramda';

import { addNotification, setGlobalMessage } from 'store/app';
import Strong from 'design-system/atoms/Strong/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import { READ_ONLY_USER } from '@dragonboat/permissions';

import Error from './Error';
import PageLink from 'design-system/atoms/PageLink';
import ProjectLink from 'design-system/atoms/ProjectLink';
import { UPDATE_PROJECT_FULFILLED } from 'store/projects';
import { loginActions } from 'store/login';
import getDisplayLayerLabel from 'store/projects/helpers/getDisplayLayerLabel';

import { ucFirst } from 'utils';
import getSystemFieldName from 'utils/getSystemFieldName';
import { isFunction } from 'lodash';
import { VALIDATION_ERROR_CODE } from 'src/config';
import {
  CREATE_KEY_RESULT_REJECTED,
  CREATE_KEY_RESULT_FROM_DRAWER_REJECTED,
  CREATE_OBJECTIVE_REJECTED,
  CREATE_OBJECTIVE_FROM_DRAWER_REJECTED,
  UPDATE_OBJECTIVE_FROM_DRAWER_REJECTED,
  UPDATE_KEY_RESULT_ID_REJECTED,
  UPDATE_KEY_RESULT_FROM_DRAWER_REJECTED,
  UPDATE_OBJECTIVE_REJECTED,
} from 'store/objectives/types';
import theme from 'design-system/theme';

const getSystemFields = path(['organization', 'organization', 'system_fields_name']);
const isJiraAuthConfigFailed = error => error === 'JIRA_AUTH_CONFIG_FAILED';
const isConnectionNotConfiguredCorrectly = error => ['JIRA_AUTH_FAILED', 'TEST_JIRA_CONNECTION_REJECTED'].includes(error);
const isDefault = error => !isJiraAuthConfigFailed(error) && !isConnectionNotConfiguredCorrectly(error);
const isErrorNotDisplayedYet = pipe(propEq('errorAlreadyDisplayed', true), not);

const toastErrors = {
  // CHANGE_PASSWORD_INCORRECT: {
  //   error: (action, data) => <Error>The old password entered is incorrect</Error>,
  // },
  INVALID_JIRA_CONFIGURATION_ERROR: {
    error: (action, data) => {
      return (
        <Error>
          {isJiraAuthConfigFailed(action.type) && (
            <span>
              {`The connect was not established. Please make sure you copy and paste the entire
              content of each cell to the relevant Jira Application Link fields. Please see the
              instruction `}
              <a
                style={{ color: theme.palette.primary, textDecoration: 'underline' }}
                href="https://dragonboat.zendesk.com/hc/en-us/articles/360013260974"
              >
                here
              </a>
              .{' Contact us if you need help.'}
            </span>
          )}
          {isConnectionNotConfiguredCorrectly(action.type) && (
            <span>
              {'JIRA connection is not configured correctly. Click '}
              <a style={{ color: theme.palette.primary, textDecoration: 'underline' }} href="/settings/integrations/jira">
                here
              </a>{' '}
              to check the connection details.
            </span>
          )}
          {isDefault(action.type) && (
            <span>
              {'Unexpected JIRA error. Click '}
              <a style={{ color: theme.palette.primary, textDecoration: 'underline' }} href="/settings/integrations/jira">
                here
              </a>{' '}
              to check the connection details or Contact us if you need help.
            </span>
          )}
        </Error>
      );
    },
    autoClose: false,
  },
  DOD_PERMISSION_ERROR: {
    error: (action, data) => {
      const portfolioName = data.entityBelongsToCorpAccount ? 'parent account' : data.portfolioName;

      return (
        <Error>
          <span>{`You don't have permission to update data from ${portfolioName}`}</span>
        </Error>
      );
    },
  },
  DOD_PERMISSION_ERROR_PARENT_READ_ONLY_PROJECTS: {
    error: (action, data, systemFields) => {
      const { layer } = data;
      const layerLabel = getDisplayLayerLabel(layer, name => getSystemFieldName(name, systemFields));

      return (
        <Error>
          <span>{`Please update ${layerLabel} in its original account`}</span>
        </Error>
      );
    },
  },
  CREATE_OR_DELETE_AZURE_DEVOPS_WEBHOOKS_ERROR: {
    error: (action, data = {}) => {
      const { code } = data;

      return (
        <Error>
          <span>
            {`Unable to create or remove integration webhooks in ${code}.
              Please confirm the user permissions on Azure Devops account.`}
          </span>
        </Error>
      );
    },
  },
  CREATE_OR_DELETE_RALLY_WEBHOOKS_ERROR: {
    error: (action, data = {}) => {
      return (
        <Error>
          <span>
            {`Unable to create or remove integration webhooks.
              Please confirm the user permissions on Rally account.`}
          </span>
        </Error>
      );
    },
  },
  CREATE_JIRA_WEBHOOKS_ERROR: {
    error: (action, data = {}) => {
      const { code } = data;

      return (
        <Error>
          <span>{`Unable to create integration webhooks in ${code}. Please confirm the user permissions on Jira account.`}</span>
        </Error>
      );
    },
  },
  // TODO this action error handling can be done by slice and use this middleware only to wire everything
  // this will allow us to have consistent toasts across all error handlers
  [VALIDATION_ERROR_CODE]: {
    error: (action, data = {}) => {
      const validationErrors = defaultTo({}, data?.errors);

      const fieldMessages = {
        title: 'title too long',
      };

      const firstErrorField = head(keys(validationErrors));

      const defaultMessage = firstErrorField ? `invalid ${firstErrorField}` : 'Unexpected error';

      const errorMessage = fieldMessages[firstErrorField] || defaultMessage;

      const actionsWithErrorToast = {
        [CREATE_KEY_RESULT_REJECTED]: `Creation failed: ${errorMessage}`,
        [CREATE_KEY_RESULT_FROM_DRAWER_REJECTED]: `Creation failed: ${errorMessage}`,
        [CREATE_OBJECTIVE_REJECTED]: `Creation failed: ${errorMessage}`,
        [CREATE_OBJECTIVE_FROM_DRAWER_REJECTED]: `Creation failed: ${errorMessage}`,
        [UPDATE_KEY_RESULT_ID_REJECTED]: `Update failed: ${errorMessage}`,
        [UPDATE_KEY_RESULT_FROM_DRAWER_REJECTED]: `Update failed: ${errorMessage}`,
        [UPDATE_OBJECTIVE_REJECTED]: `Update failed: ${errorMessage}`,
        [UPDATE_OBJECTIVE_FROM_DRAWER_REJECTED]: `Update failed: ${errorMessage}`,
      };

      if (actionsWithErrorToast[action.type]) {
        return <span>{actionsWithErrorToast[action.type]}</span>;
      }

      return null;
    },
  },
};

const hasErrorHandler = has(__, toastErrors);

const lightboxErrors = {
  ERROR_ON_SYNC_JIRA_REQUEST: ({ _data }) => (
    <TextDeprecated size="medium" breakwords>
      The EPIC has been updated, except the <Strong>{_data.invalidFields}</Strong> field(s). See more details{' '}
      <PageLink href="https://dragonboat.zendesk.com/hc/en-us/articles/360025615233-Sync-Field-Values-with-Jira" target="_blank">
        here
      </PageLink>
      .
    </TextDeprecated>
  ),
  ERROR_ON_SYNC_JIRA_FIELD: ({ _data }) => (
    <TextDeprecated size="medium" breakwords>
      The EPIC has been updated, except the <Strong>{ucFirst(_data.field)}</Strong> field. Please add this new{' '}
      {ucFirst(_data.field)} value in Jira for future updates. See more details{' '}
      <PageLink href="https://dragonboat.zendesk.com/hc/en-us/articles/360025615233-Sync-Field-Values-with-Jira" target="_blank">
        here
      </PageLink>
      .
    </TextDeprecated>
  ),
};

/* eslint-disable */
// toast(<InfoToast>The connect was not established.Please make sure you copy and paste the entire content of each cell to the relevant Jira Application Link fields. Please see the instruction <a href="https://dragonboat.zendesk.com/hc/en-us/articles/360013260974">here</a>. Contact us if you need help.</InfoToast>, {
//   toastId: 'JIRA_CONNECTION_ERROR',
// });
/* eslint-enable */

export default store => next => action => {
  if (action.error && action.payload && action.payload.response && action.payload.response.status) {
    const { status, data } = action.payload.response;
    const isJiraAuthError = data && data.error_code && data.error_code === 'INVALID_JIRA_TOKEN_ERROR';
    const isIntegrationAuthError = data && data.error_code && data.error_code === 'INTEGRATION_AUTHORIZATION_ERROR';

    // if (toast.isActive(NETWORK_ERROR) || status === 404) {
    // } else
    // if (status === 403) {
    //   store.dispatch(loginActions.clearAuthTokenAndStore());
    //   // toast('Your session has expired. Please re-login.', { toastId: NETWORK_ERROR });
    // } else
    if (status === 401 && !isJiraAuthError && !isIntegrationAuthError) {
      const { currentUser } = store.getState().login || {};

      if (data.userMessage) {
        toast(data.userMessage);
      } else if (currentUser.role_id === READ_ONLY_USER) {
        toast('You have read only access. Your changes will not be saved. Please contact your admin if you need edit access.');
      }
    } else if (status >= 400 && status <= 530) {
      if (status === 403) {
        const user = store.getState().login.currentUser;

        if (user.isSuperAdmin || user.is_anonymous) return next(action);
        return store.dispatch(loginActions.clearAuthTokenAndStore());
      }

      try {
        if (hasErrorHandler(data.error_code) && isErrorNotDisplayedYet(data)) {
          const errorConf = toastErrors[data.error_code];

          const errorMessage = errorConf.error(action, data._data, getSystemFields(store.getState()));

          if (errorMessage) {
            store.dispatch(addNotification({ id: data.error_code, message: errorMessage, type: 'error' }));

            data.errorAlreadyDisplayed = true;
          }
        } else if (data.error_code && lightboxErrors[data.error_code]) {
          const message = lightboxErrors[data.error_code](data);

          store.dispatch(setGlobalMessage(message));
          store.dispatch({
            type: UPDATE_PROJECT_FULFILLED,
            payload: data._data.updatedProject,
          });
        }
        if (data.error_code && data.error_code === 'JIRA_ALREADY_LINKED_ERROR') {
          const hasProjectData = data._data?.project_id && data._data?.title;

          toast(
            <span>
              Jira {data._data.key} is already linked
              {hasProjectData ? (
                <>
                  <span>&nbsp;to&nbsp;</span>
                  <ProjectLink href={`/ideas/grid?openIdea=${data._data.project_id}`}>
                    {data._data.project_id} {data._data.title}
                  </ProjectLink>
                </>
              ) : null}
            </span>,
          );
        } else if (data.message) {
          // toast(data.message);
        } else if (action?.meta?.toastErrorMessage) {
          const message = action.meta.toastErrorMessage;

          toast(isFunction(message) ? message(data) : message);
        }
      } catch (err) {
        // toast(<ErrorReload />, { toastId: NETWORK_ERROR });
      }
    }
  }

  return next(action);
};
