import { useCallback, useMemo } from 'react';
import { head, isNil, not, pathOr, pipe } from 'ramda';
import moment from 'moment-timezone';

import useGridApiContext from 'hooks/grid/useGridApiContext';

const isNotNil = pipe(isNil, not);

const DRAG_COL_ID = 'drag';

export default function useExportCsvFromGrid(gridId, fileNamePrefix) {
  const { getColumnApi, getGridApi } = useGridApiContext();

  const columnApi = useMemo(() => getColumnApi(gridId), [getColumnApi]);
  const gridApi = useMemo(() => getGridApi(gridId), [getGridApi]);

  const handleExportToCsv = useCallback(() => {
    if (!gridApi || !columnApi) return;

    const columnState = columnApi.getColumnState();
    const firstColumn = head(columnState);
    const columnsOrderKeys = Object.values(columnState).map(column => column.colId);

    let visibleColumns = columnApi
      .getColumns()
      .filter(col => !!col.colDef.exportFn) // filter columns without defined exporter
      .map(col => col.colDef.field)
      .filter(col => columnState.some(colState => colState.colId === col && !colState.hide))
      .sort((colA, colB) => columnsOrderKeys.indexOf(colA) - columnsOrderKeys.indexOf(colB));

    // ensure the title is always present
    if (isNotNil(firstColumn) && !visibleColumns.includes(firstColumn.colId)) {
      visibleColumns = [firstColumn.colId, ...visibleColumns].filter(col => col !== DRAG_COL_ID);
    }

    gridApi.exportDataAsCsv({
      fileName: `${fileNamePrefix}-${moment().format()}`,
      columnGroups: true,
      columnKeys: visibleColumns,
      processCellCallback: params => {
        const exportFn = pathOr(undefined, ['column', 'colDef', 'exportFn'], params);

        if (exportFn) {
          return exportFn(params);
        }

        return params.value;
      },
    });
  }, [gridApi, columnApi]);

  return {
    handleExportToCsv,
  };
}
