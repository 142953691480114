import reduceReducers from 'reduce-reducers';
import { append, pick } from 'ramda';

import {
  UPDATE_GRID_PERSONA,
  UPDATE_GRID_PERSONA_PENDING,
  FETCH_PERSONAS_GRID_DATA,
  FETCH_PERSONAS_GRID_DATA_FULFILLED,
  CREATE_GRID_PERSONA,
  SAVE_GRID_NEW_PERSONA,
  SAVE_GRID_NEW_PERSONA_FULFILLED,
  REMOVE_GRID_UNSAVED_PERSONA,
  DELETE_GRID_PERSONA,
  DELETE_GRID_PERSONA_FULFILLED,
  CREATE_PERSONA_ROADMAP_FULFILLED,
  DELETE_PERSONA_ROADMAP_FULFILLED,
  BULK_DELETE_PERSONA_ROADMAP_FULFILLED,
  SWITCH_PERSONAS_GRID_ROW_ORDER_PENDING,
  SWITCH_PERSONAS_GRID_ROW_ORDER_REJECTED,
  SWITCH_PERSONAS_GRID_ROW_ORDER_FULFILLED,
  CHANGE_ROW_HEIGHT_PERSONA_GRID,
  CHANGE_HIDE_ARCHIVED_PERSONA_GRID,
} from './types';
import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';
import sortByRank from 'utils/sortByRank';

import { PERSONA_ROADMAP_FIELD, BASE_ROW_HEIGHT } from '../helpers/constants';

const initialState = {
  data: [],
  rowHeight: BASE_ROW_HEIGHT,
  hideArchived: false,
  operations: bulkThunkInitialState([UPDATE_GRID_PERSONA, FETCH_PERSONAS_GRID_DATA]),
};

const personasDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PERSONAS_GRID_DATA_FULFILLED:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_GRID_PERSONA_PENDING:
      const updatedPersona = action.payload;

      return {
        ...state,
        data: state.data?.map(persona => {
          if (updatedPersona?.id === persona.id) {
            return { ...persona, ...updatedPersona };
          }
          return persona;
        }),
      };
    case CREATE_GRID_PERSONA:
      const { hasUnsavedItem, data } = state;

      if (hasUnsavedItem) return state;

      return {
        ...state,
        data: [{ isNew: true }, ...data],
        hasUnsavedItem: true,
      };
    case SAVE_GRID_NEW_PERSONA_FULFILLED:
      return {
        ...state,
        data: state.data.map(persona => {
          if (persona?.isNew) {
            const createdPersona = {
              ...action.payload,
              [PERSONA_ROADMAP_FIELD]: [],
            };

            return createdPersona;
          }
          return persona;
        }),
        hasUnsavedItem: false,
      };
    case REMOVE_GRID_UNSAVED_PERSONA:
      return {
        ...state,
        data: state.data.filter(persona => !persona.isNew),
        hasUnsavedItem: false,
      };
    case DELETE_GRID_PERSONA_FULFILLED:
      return {
        ...state,
        data: state.data.filter(persona => persona?.id !== action.payload),
      };

    case CREATE_PERSONA_ROADMAP_FULFILLED: {
      const newPersonaRoadmap = action.payload;
      const updatedData = state.data.map(item => {
        if (item.id === newPersonaRoadmap.persona_id) {
          const existingRoadmaps = item[PERSONA_ROADMAP_FIELD] || [];

          return {
            ...item,
            [PERSONA_ROADMAP_FIELD]: append(newPersonaRoadmap, existingRoadmaps),
            updated_at: newPersonaRoadmap.updated_at,
          };
        }
        return item;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case DELETE_PERSONA_ROADMAP_FULFILLED: {
      const { id, personaRoadmaps } = action.payload;
      const personaId = parseInt(id);
      const updatedData = state.data.map(item => {
        if (item.id === personaId) {
          return {
            ...item,
            [PERSONA_ROADMAP_FIELD]: personaRoadmaps,
          };
        }
        return item;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case BULK_DELETE_PERSONA_ROADMAP_FULFILLED: {
      const personaId = action.payload;
      const updatedData = state.data.map(item => {
        if (item.id === personaId) {
          return {
            ...item,
            [PERSONA_ROADMAP_FIELD]: [],
          };
        }
        return item;
      });

      return {
        ...state,
        data: updatedData,
      };
    }
    case SWITCH_PERSONAS_GRID_ROW_ORDER_REJECTED: {
      if (!action.meta || !action.meta.prev) return state;

      const { id, rank } = action.meta.prev;
      const updatedData = state.data.map(item => {
        if (item.id === id) {
          return { ...item, rank };
        }
        return item;
      });

      return {
        ...state,
        data: updatedData.sort(sortByRank),
      };
    }
    case SWITCH_PERSONAS_GRID_ROW_ORDER_PENDING: {
      if (!action.meta || !action.meta.row) {
        return state;
      }

      const { id, rank } = action.meta.row;
      const updatedData = state.data.map(item => {
        if (item.id === id) {
          return { ...item, rank };
        }
        return item;
      });

      return {
        ...state,
        data: updatedData.sort(sortByRank),
      };
    }
    case SWITCH_PERSONAS_GRID_ROW_ORDER_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        return state;
      }
      const updatedItem = action.payload;
      const updatedData = state.data.map(item => {
        if (item.id === updatedItem?.id) {
          return {
            ...item,
            ...pick(['rank', 'updated_at', 'updated_by_id'])(updatedItem),
          };
        }
        return item;
      });

      return {
        ...state,
        data: updatedData.sort(sortByRank),
      };
    }
    case CHANGE_ROW_HEIGHT_PERSONA_GRID:
      return {
        ...state,
        rowHeight: action?.payload,
      };
    case CHANGE_HIDE_ARCHIVED_PERSONA_GRID:
      return {
        ...state,
        hideArchived: action?.payload,
      };
    default: {
      return state;
    }
  }
};

const operationsReducer = getThunksReducers([UPDATE_GRID_PERSONA, SAVE_GRID_NEW_PERSONA, DELETE_GRID_PERSONA]);

const reducer = reduceReducers(initialState, personasDataReducer, ...operationsReducer);

export default reducer;
