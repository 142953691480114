import { createCachedSelector } from 're-reselect'; // External dependencies
import { createSelectorCreator, defaultMemoize, createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import { isNil } from 'ramda';

export function getState(state) {
  return state.grids;
}

export const getSelectionMode = createSelector(
  [getState, (_, gridType) => gridType],
  (gridsState, gridType) => gridsState && gridsState.getIn([gridType, 'selectionMode']),
);

export const getSelectedItems = createSelector([getState, (_, grid) => grid], (state, grid) =>
  state && state.getIn([grid, 'selectedItems']) ? state.getIn([grid, 'selectedItems']).toJS() : [],
);

export const getGridState = createSelector([getState, (_, grid) => grid], (state, grid) =>
  state && state.getIn([grid, 'gridState']) ? state.getIn([grid, 'gridState']).toJS() : {},
);

export const getUserView = createSelector([getState, (_, grid) => grid], (state, grid) =>
  state && state.getIn([grid, 'userView']) ? state.getIn([grid, 'userView']).toJS() : {},
);

export const getSelectedView = createSelector([getState, (_, grid) => grid], (state, grid) =>
  state && state.getIn([grid, 'selectedView']) ? state.getIn([grid, 'selectedView']).toJS() : {},
);

export const getMergeLightboxVisible = createSelector(
  [getState, (_, gridType) => gridType],
  (gridsState, gridType) => gridsState && gridsState.getIn([gridType, 'mergeLightboxVisible']),
);

export const getGridConfigValue = createCachedSelector(
  (state, grid, key) => {
    const gridState = getState(state);
    const result = gridState && gridState.hasIn([grid, key]) ? gridState.getIn([grid, key]) : null;

    return result !== null && result.toJS ? result.toJS() : result;
  },
  (_, __, ___, defaultVal) => defaultVal,
  (val, defaultVal) => {
    return isNil(val) ? defaultVal : val;
  },
)({
  keySelector: (_, grid, key, defaultVal) => `getGridConfigValue_${grid}_${key}_${defaultVal}`,
  selectorCreator: createSelectorCreator(defaultMemoize, isEqual),
});
