import { createSelector } from 'reselect';

import { getState } from '../selectors';

export const getAllDashboardComments = createSelector(getState, state => state.byDashboard || {});

const makeGetDashboardComments = () => {
  return createSelector(
    getAllDashboardComments,
    (_, dashboardId) => dashboardId,
    (comments, dashboardId) => {
      return comments[dashboardId] || {};
    },
  );
};

export const getDashboardComments = makeGetDashboardComments();
