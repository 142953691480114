import { ROADMAP_PAGE } from 'constants/filters';
import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import useTimelines from 'hooks/useTimelines';

import useSwimlaneProjects from './useSwimlaneProjects';
import makeCreateProject from '../helpers/makeCreateProject';
import { useCallback } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import { updateProjectGroupData } from '../helpers/updateProjectGroupData';

const mapLayers = {
  bet: BET_LAYER,
  initiative: INITIATIVE_LAYER,
};

const useSwimlaneActions = ({ filter, filterData, firstGroupData, secondGroupData }) => {
  // Get the timeline configuration
  const {
    lsState: { grouplaneType: firstGroupType, swimlaneType: secondGroupType, displayTimelineBy },
  } = useTimelines(ROADMAP_PAGE);

  // Get the projects and all the project's information
  const { projects, isUpdatingProject, handleOpenProjectLightBox, displayLayer, updateProject } = useSwimlaneProjects();

  const createProject = makeCreateProject({
    displayLayer,
    filter,
    filterData,
    mapLayers,
    firstGroupData,
    secondGroupData,
    firstGroupType,
    secondGroupType,
    handleOpenProjectLightBox,
  });

  const createMilestone = () => createProject(null, null, true);

  // Update project
  const updateProjectTimeline = useCallback(
    (projectId, fromDiff, toDiff, order = 0, firstGroupId = -1, secondGroupId) => {
      if (isUpdatingProject) return;

      const existing = projects.find(project => project.id === projectId);

      if (!existing) {
        return;
      }

      const newProject = cloneDeep(existing);

      // Start date
      if (!displayTimelineBy || !newProject.integrationProgress || !newProject.integrationProgress.startDate) {
        const startDate = newProject.estimated_start_date ? moment(newProject.estimated_start_date) : moment();
        const newStartDate = startDate.add(fromDiff, 'days').startOf('day');

        const shouldUpdateStartDate = fromDiff || !existing.estimated_start_date;

        newProject.estimated_start_date = shouldUpdateStartDate ? newStartDate : existing.estimated_start_date;
      } else {
        newProject.integrationProgress.startDate = fromDiff
          ? moment(newProject.integrationProgress.startDate).add(fromDiff, 'days').startOf('day')
          : existing.integrationProgress.startDate;
      }

      // End date
      if (!displayTimelineBy || !newProject.integrationProgress || !newProject.integrationProgress.endDate) {
        const deadline = newProject.deadline ? moment(newProject.deadline) : moment().add(2, 'weeks');
        const newEndDate = deadline.add(toDiff, 'days').endOf('day');

        const shouldUpdateEndDate = toDiff || !existing.deadline;

        newProject.deadline = shouldUpdateEndDate ? newEndDate : existing.deadline;
      } else {
        newProject.integrationProgress.endDate = toDiff
          ? moment(newProject.integrationProgress.endDate).add(toDiff, 'days').endOf('day')
          : existing.integrationProgress.endDate;
      }

      if (order) {
        updateProjectGroupData(newProject, firstGroupType, firstGroupData, firstGroupId);

        if (secondGroupId) {
          updateProjectGroupData(newProject, secondGroupType, secondGroupData, secondGroupId);
        }
      }

      updateProject(newProject);
    },
    [
      isUpdatingProject,
      projects,
      displayTimelineBy,
      firstGroupData,
      firstGroupType,
      secondGroupType,
      secondGroupData,
      updateProject,
    ],
  );

  return { createProject, createMilestone, updateProjectTimeline };
};

export default useSwimlaneActions;
