import React from 'react';
import { toast } from 'react-toastify';
import Link from '@material-ui/core/Link';

import sortRowOrder from 'utils/sortRowOrder';
import { getProjectFieldToUpdate, getMetadataKeyByGroupKey, getMetadataObjectByKey } from 'store/projects/helpers/groupMetadata';
import { isGroupFieldADate } from 'utils/grouping';

import generateSummaryData from './generateSummaryData';

const SIMPLE_TYPES = ['status_color', 'planningStage'];

const computeGroupId = (rowId, colId = null, groupId) => `${rowId}*${colId}*${groupId}`;

const makeProjectId = (rowId, colId, groupId, projectId) => {
  return `project*${computeGroupId(rowId, colId, groupId)}*${projectId}`;
};

const generateHeaderColumnId = id => `headerColumn_${id}`;

const createNewProjectToast = (project, onOpenProject) => {
  toast(
    <div>
      <span>{project.title} has been created but may not be visible, </span>
      <Link onClick={() => onOpenProject(project.id)}>click here to view</Link>
    </div>,
  );
};

/**
 * Function similar to the one above (FORMATTED_FIELD_KEY).
 *
 * Allow to attach the "Title" to the field name keeping the
 * same rules used for "_id".
 *
 * This should be a temporary fix in order to allow the jira
 * sync of some fields in the 3D Dynamic view.
 *
 * The proper fix will be implemented in the Jira Sync Interface feature.
 */
const formatFieldTitle = fieldInfo => {
  if (fieldInfo.dataKey) {
    return `${fieldInfo.dataKey}Title`;
  }

  if (SIMPLE_TYPES.includes(fieldInfo.key)) {
    return fieldInfo.key;
  }

  return `${fieldInfo.key}Title`;
};

/**
 * Process the project property value based on the key being used.
 *
 * @param key The project property key
 * @param value The project property value
 *
 * @return {any} The processed value for the project property
 *
 * */
const setDataId = (key, value) => {
  const isCustomField = key?.startsWith('custom_fields.');

  if (isCustomField) {
    return value.replace('custom_fields.', '');
  }

  const simpleTypes = ['status_color', 'planningStage'];
  const isSimpleGrouping = simpleTypes.includes(key);

  if (isSimpleGrouping) {
    return value;
  }

  if (value === 'null') return null;

  return parseInt(value, 10);
};

/**
 * Sort the rows based on the row order property.
 *
 * @param rowA The first row to be compared
 * @param rowB The second row to be compared
 *
 * @return {number} The result of the sort
 * */
const sortTableRows = (rowA, rowB) => {
  if (rowA.rowOrder < 0 || !rowA.id) return 1;
  if (rowB.rowOrder < 0 || !rowB.id) return -1;

  return rowA.rowOrder - rowB.rowOrder;
};

/**
 * Generates the row mapper function to be used in the table rows conversion.
 *
 * @param cols The column set to be used on each Row
 * @param rowsOrder The object containing the row order by id
 *
 * @return {function} The mapper function to be used in the conversion
 * */
const tableRowMapper = (cols, rowsOrder) => row => {
  const headerColumns = cols
    ? cols.reduce(
        (acc, col) => ({
          ...acc,
          [generateHeaderColumnId(col.id)]: {
            row,
            col,
          },
        }),
        {},
      )
    : {};

  return {
    id: row.id,
    rowOrder: rowsOrder.indexOf(row.id),
    headerBanner: { row },
    ...headerColumns,
  };
};

/**
 * Turm rows into table rows to be used in the [ReactTable]{@link /ReactTable/ReactTable.jsx} component.
 *
 * @param rows Rows to be converted into Table rows
 * @param cols Columns to be used in the conversion
 * @param rowsOrder Object with the rows order indexed by row id
 *
 * @return Object
 * */
const convertToTableRows = (rows, cols, rowsOrder) => () => {
  const rowMapper = tableRowMapper(cols, rowsOrder);

  return rows ? rows.map(rowMapper).sort(sortTableRows) : [];
};

/**
 * Turm cols into table columns to be used in the [ReactTable]{@link /ReactTable/ReactTable.jsx} component.
 *
 * @param cols Columns to be converted
 *
 * @return {object} An object containing all cols indexed by headerColumn_{colId}
 * */
const convertToTableColumns = cols => () => {
  const headerColumns = cols
    ? cols.reduce(
        (acc, col) => ({
          ...acc,
          [generateHeaderColumnId(col.id)]: { col: col || undefined },
        }),
        {},
      )
    : {};

  return {
    headerBanner: { col: undefined },
    ...headerColumns,
  };
};

const getColumnIdOrNull = col => col?.id ?? null;

/**
 * Creates an object where the projects are indexed by Cell.
 * For example, result[rowId][colId] will return the array containing all projects that belongs to the row=rowId and col=colId.
 *
 *  @param rows The rows to be used to build the cache with projects by row and col
 *
 *  @return {object} The object with all projects indexed by Row and Col.
 * */
const getCellProjectsByRowAndCol = rows =>
  rows?.reduce((accRows, row) => {
    const colsReduced = row?.values?.reduce(
      (accCols, value) => ({
        ...accCols,
        [getColumnIdOrNull(value.col)]: accCols[getColumnIdOrNull(value.col)]
          ? [...accCols[getColumnIdOrNull(value.col)], value].sort((a, b) => sortRowOrder(a.project, b.project))
          : [value],
      }),
      {},
    );

    return { ...accRows, [row.id]: colsReduced };
  }, {}) ?? {};

const getProjectGroup = project => project?.group?.[0]?.id;

/**
 * Creates an object where the projects are indexed by Group.
 * For example, result[groupId] will return the array containing all projects that belongs to the group=groupId.
 *
 *  @param cellProjects The projects that belong to a given Cell
 *
 *  @return {object} The object with all cell projects indexed by Group.
 * */
const getCellProjectsByGroup = cellProjects =>
  cellProjects?.reduce(
    (acc, cellProject) => ({
      ...acc,
      [getProjectGroup(cellProject)]: acc[getProjectGroup(cellProject)]
        ? [...acc[getProjectGroup(cellProject)], cellProject]
        : [cellProject],
    }),
    {},
  ) ?? {};

/**
 * Updates a project with grouping information.
 *
 * @param project The project to be updated
 * @param groupOption The group option selected
 * @param metadata The metadata information to be used
 * @param inputId The new value of the target project field
 * */
const updateProjectWithGroupData = (project, groupOption, metadata, inputId) => {
  if (isGroupFieldADate(groupOption)) {
    return;
  }

  const isCustomField = groupOption?.field?.startsWith('custom_fields.');

  if (isCustomField) {
    const customGroupField = groupOption?.field?.replace('custom_fields.', '');

    project[customGroupField] = inputId;
    project.custom_fields = { ...project.custom_fields, [customGroupField]: inputId };

    return;
  }

  const metadataKey = getMetadataKeyByGroupKey(groupOption.key);
  const metadataObject = getMetadataObjectByKey(metadata, metadataKey, inputId);

  project[groupOption.field] = metadataObject?.id ?? null;

  const simpleTypes = ['status_color', 'planningStage'];
  const parentTypes = ['bet', 'initiative'];

  const isSimpleGrouping = simpleTypes.includes(groupOption?.key);
  const isBetOrInitiative = parentTypes.includes(groupOption?.key);

  if (isBetOrInitiative) {
    project.parentLayer = metadataObject?.layer;
  }

  if (!isBetOrInitiative && !isSimpleGrouping) {
    project[getProjectFieldToUpdate(groupOption.key)] = metadataObject?.id ? metadataObject : null;
    project[formatFieldTitle(groupOption)] = metadataObject?.title;
  }
};

const calculateHeaderHeight = (tableElement, rowId) => {
  const divCell = tableElement?.querySelector(`tr div[data-row-id="${rowId}"]`);

  if (!divCell) {
    return;
  }

  const computedStyle = getComputedStyle(divCell);
  const paddingY = parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom);

  return divCell.clientHeight - paddingY;
};

const calculateHeadersHeights = (rows, summaryTableRef) =>
  rows.reduce(
    (acc, row) => ({
      ...acc,
      [row.id]: calculateHeaderHeight(summaryTableRef?.current, row.id),
    }),
    {},
  );

const filterProjectsByRow = rowId => row => row.id === rowId;
const filterProjectsByCol = colId => value =>
  Array.isArray(value.col) ? value.col.includes(colId) : (value.col && value.col.id === colId) || value.col === colId;
const filterProjectsByGroup = (groupKey, groupValue) => value => groupKey ? value.project[groupKey] === groupValue : true;

/**
 * Sort the filtered list of projects that belong to a given row, column and group [optional].
 *
 * @param rows the source list of rows
 * @param rowId the rowId to be filtered by
 * @param colId the colId to be filtered by
 * @param groupKey the groupKey to be used on the filter by group
 * @param groupValue the groupValue to be used on the filter by group
 *
 * @return {Array} projects filtered and sorted by the conditions specified on the filter functions above
 * */
const getSortedProjectByRowAndColAndGroup = (rows, rowId, colId, groupKey, groupValue) =>
  rows
    .filter(filterProjectsByRow(rowId))
    .reduce(
      (acc, row) => [
        ...acc,
        ...row.values
          .filter(filterProjectsByCol(colId))
          .filter(filterProjectsByGroup(groupKey, groupValue))
          .map(value => value.project),
      ],
      [],
    )
    .sort(sortRowOrder);

export {
  generateHeaderColumnId,
  generateSummaryData,
  formatFieldTitle,
  setDataId,
  computeGroupId,
  makeProjectId,
  calculateHeaderHeight,
  calculateHeadersHeights,
  createNewProjectToast,
  convertToTableRows,
  convertToTableColumns,
  updateProjectWithGroupData,
  getCellProjectsByRowAndCol,
  getCellProjectsByGroup,
  getSortedProjectByRowAndColAndGroup,
};
