import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import queryString from 'query-string';

import PublicPageTemplateComponent from 'src/design-system/templates/PublicPageTemplate/PublicPageTemplateComponent';

import Button from 'design-system/atoms/Button/index';
import InputTextField from 'design-system/atoms/InputTextField/index';

import { materialColors, materialBackground } from 'design-system/themes/default';

import AuthErrorMessage from 'components/AuthErrorMessage';

import { setNewPassword } from 'store/login';
import { getDefaultRoute, validatePassword } from 'utils';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      newPasswordError: false,
      confirmNewPasswordError: false,
    };

    this.updatePassword = this.updatePassword.bind(this);
    this.isUpdateDisabled = this.isUpdateDisabled.bind(this);
    this.checkNewReqs = this.checkNewReqs.bind(this);
    this.checkConfirmNewReqs = this.checkConfirmNewReqs.bind(this);
  }

  componentDidMount() {
    const { isAuthenticated, currentUser } = this.props;

    if (isAuthenticated) {
      return <Redirect to={getDefaultRoute(currentUser)} />;
    }
  }

  updatePassword() {
    this.props.changePassword(this.state.oldPassword, this.state.newPassword);
  }

  checkNewReqs() {
    this.setState({ newPasswordError: !validatePassword(this.state.newPassword) });
  }

  checkConfirmNewReqs() {
    this.setState({ confirmNewPasswordError: this.state.confirmNewPassword !== this.state.newPassword });
  }

  isUpdateDisabled() {
    return (
      !this.state.newPassword ||
      !this.state.confirmNewPassword ||
      this.state.newPasswordError ||
      this.state.confirmNewPasswordError
    );
  }

  render() {
    const { isAuthenticated, onSubmit, newPasswordSuccess, newPasswordErrorMessage, currentUser } = this.props;

    const handleSubmit = () => {
      onSubmit(this.state.newPassword);
    };

    const renderLogInForm = () => {
      return (
        <PublicPageTemplateComponent>
          <Grid container direction="column" alignItems="center" spacing={0}>
            <Grid item xs={12}>
              <StyledTitle>Set new password</StyledTitle>
            </Grid>
            <Grid item xs={12}>
              {!newPasswordSuccess && (
                <form>
                  <div style={{ marginTop: '30px' }}>
                    <InputTextField
                      variant="outlined"
                      name="newPassword"
                      label="New Password"
                      helperText="8 to 24 characters with at least one letter and one number"
                      value={this.state.newPassword}
                      handleValue={v => this.setState({ newPassword: v })}
                      onBlur={this.checkNewReqs}
                      error={this.state.newPasswordError}
                      // className={this.props.classes.textFields}
                      type="password"
                      required
                    />
                  </div>
                  <div style={{ marginTop: '30px' }}>
                    <InputTextField
                      variant="outlined"
                      name="confirmNewPassword"
                      label="Confirm new password"
                      value={this.state.confirmNewPassword}
                      handleValue={v => this.setState({ confirmNewPassword: v })}
                      onBlur={this.checkConfirmNewReqs}
                      error={this.state.confirmNewPasswordError}
                      // className={this.props.classes.textFields}
                      type="password"
                      required
                    />
                  </div>
                  {newPasswordErrorMessage && <AuthErrorMessage errorMessage={newPasswordErrorMessage} />}
                  <StyledStartButton
                    background={this.isUpdateDisabled() ? materialBackground.gray : materialColors.mediumPurple}
                    variant="contained"
                    disabled={this.isUpdateDisabled()}
                    onClick={() => handleSubmit()}
                  >
                    Update password
                  </StyledStartButton>
                </form>
              )}
              {newPasswordSuccess && (
                <div className={this.props.classes.successText}>
                  <Typography variant="h6">Password updated!</Typography>
                  <Typography className={this.props.classes.successTextItem} variant="body2">
                    Your password was updated with success. Please login with your new credentials.
                  </Typography>
                  <StyledStartButton
                    background={materialColors.mediumPurple}
                    variant="contained"
                    onClick={() => this.props.history.push('/login')}
                  >
                    Go to Login
                  </StyledStartButton>
                </div>
              )}
            </Grid>
          </Grid>
        </PublicPageTemplateComponent>
      );
    };

    if (isAuthenticated) {
      return <Redirect to={getDefaultRoute(currentUser)} />;
    }

    return renderLogInForm(handleSubmit);
  }
}

const mapStateToProps = state => {
  const { login } = state;

  return {
    isAuthenticated: login.isAuthenticated,
    newPasswordSuccess: login.newPasswordSuccess,
    newPasswordErrorMessage: login.newPasswordErrorMessage,
    currentUser: login.currentUser,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onSubmit: newPassword => dispatch(setNewPassword(newPassword, queryString.parse(window.location.search).t)),
});

const styles = theme => ({
  textFields: {
    width: 250,
  },
  googleLoginButton: {
    marginTop: 30,
    width: 250,
  },
  resetPasswordHead: {
    marginTop: 30,
  },
  successText: {
    marginTop: 30,
  },
  successTextItem: {
    marginTop: 30,
    width: 270,
  },
});

const StyledTitle = styled.h1`
  color: #3461ac;
  font-size: 2.25rem; // -> 36px -> with :root font-size of 16px;
  font-weight: bold;
  margin: 0 0 1.813rem 0;
`;

const StyledStartButton = styled(Button)`
  &&&& {
    margin-top: 30px;
    color: #ffffff;
    width: 100%;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
