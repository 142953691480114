import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useIdeasGridColumnsDefinitions } from 'containers/IdeasList/IdeasList/New/hooks';
import { selectSelectedSnapshotsToCompareWithoutProjects } from 'features/RoadmapHistory/store/selectors';
import { generateSnapChildrenColumnDefs, getCurrentColumnAsChild, getSnapParentColumnDef } from '../helpers/snapsColumnDefs';
import { getTitleIsHighlighted } from '../helpers/fieldsHighlights';
import { CURRENT_DATA_ID, ROADMAP_HISTORY_GRID_AVAILABLE_COLUMNS } from 'features/RoadmapHistory/constants';

const useCompareRoadmapHistoryColumnsDefinitions = ({
  portfolioMode,
  hasHierarchy,
  hasBet,
  hasKeyResults,
  hasKeyResults2,
  hasProducts,
  hasProducts2,
  hasMultiLevelMetadata,
  hasTeams2,
  topLayer,
  displayLayer,
  rowHeight,
  visibleColumnsIds,
  columnsToCompareIds,
  titleSearchString,
  customUserFields,
  currentUser,
  integrations,
  columnsState,
  customFields,
  hasMetadataMultiSelect,

  getSystemFieldName,
  addNewInlineProject,
  openProjectInfo,
  openRowInfo,
  loadParentProjectsOptions,
  checkUserCanCreateNewMetadata,
  updateProjectById,
  handleMetadataCellDoubleClick,
  createMetadataMultiSelectOption,
  removeGridRow,
}) => {
  const selectedSnapshotsToCompare = useSelector(selectSelectedSnapshotsToCompareWithoutProjects);

  const {
    columnDefs: ideasGridColumnDefs,
    defaultColDef,
    groupColumnDef,
  } = useIdeasGridColumnsDefinitions({
    portfolioMode,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    topLayer,
    displayLayer,
    rowHeight,
    visibleColumnsIds,
    titleSearchString,
    customUserFields,
    currentUser,
    integrations,
    customFields,
    columnsState,
    hasMetadataMultiSelect,

    getSystemFieldName,
    addNewInlineProject,
    openProjectInfo,
    openRowInfo,
    loadParentProjectsOptions,
    updateProjectById,
    handleMetadataCellDoubleClick,
    checkUserCanCreateNewMetadata,
    createMetadataMultiSelectOption,
    removeGridRow,
  });

  const columnDefs = useMemo(() => {
    const { visibleColumns, comparableColumns } = ideasGridColumnDefs.reduce(
      (acc, column) => {
        if (!ROADMAP_HISTORY_GRID_AVAILABLE_COLUMNS.includes(column.field)) return acc;
        const isComparingField = columnsToCompareIds.includes(column.field);

        if (!isComparingField) {
          return { ...acc, visibleColumns: [...acc.visibleColumns, { ...column, editable: false }] };
        }

        const hasSnapsSelected = !!selectedSnapshotsToCompare?.length;

        const snapsColumns = selectedSnapshotsToCompare.map(snap => generateSnapChildrenColumnDefs(snap, column));
        const fieldColumn = getSnapParentColumnDef(
          `${CURRENT_DATA_ID}_${column.field}`,
          column.headerName,
          [getCurrentColumnAsChild(column), ...snapsColumns],
          hasSnapsSelected,
        );

        return {
          ...acc,
          comparableColumns: [...acc.comparableColumns, fieldColumn],
        };
      },
      { visibleColumns: [], comparableColumns: [] },
    );

    return [...visibleColumns, ...comparableColumns];
  }, [selectedSnapshotsToCompare, ideasGridColumnDefs, columnsToCompareIds]);

  const updatedGroupColumnDef = useMemo(() => {
    return {
      ...groupColumnDef,
      cellClass: params => `${groupColumnDef.cellClass(params)} ${getTitleIsHighlighted(params)}`,
      editable: false,
      rowDrag: false,
    };
  }, [groupColumnDef]);

  return {
    columnDefs,
    defaultColDef,
    groupColumnDef: updatedGroupColumnDef,
  };
};

export default useCompareRoadmapHistoryColumnsDefinitions;
