import React from 'react';
import { ConfirmDialog } from '../gridCommon';

const SlackAuthenticationErrorDialog = props => {
  const { showDialog, handleOnCancel, handleOnConfirm } = props;

  return (
    <ConfirmDialog
      isOpen={showDialog}
      title="Slack Test Failed"
      text="Slack connection test failed due to invalid authorization, would you like to reconnect slack?"
      onCancel={() => handleOnCancel(false)}
      onConfirm={() => {
        handleOnConfirm();
      }}
    />
  );
};

export default SlackAuthenticationErrorDialog;
