import buildPageFiltersWithPlanningStages from 'utils/filters/buildPageFiltersWithPlanningStages';
import { CHILDREN_FILTERS } from './filters';

export const GOAL_MODE_TIMELINE_FILTER = buildPageFiltersWithPlanningStages(
  {
    children: CHILDREN_FILTERS.noChildren,
  },
  null,
  true,
);
