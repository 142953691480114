/**
 * @function getEstimateInfoByGridField
 *
 * get the estimate info based on estomimate ag grid field estimate_1_2
 *
 * @param  {String} fieldName
 * @return {Object}
 */
const getEstimateInfoByGridField = fieldName => {
  const [teamId, skillId] = fieldName.split('_').splice(1, 2);

  return {
    teamId: parseInt(teamId),
    skillId: parseInt(skillId),
  };
};

export default getEstimateInfoByGridField;
