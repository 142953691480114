import { isCapacityUser, getUserName } from 'utils';

/**
 * Function that generate the resources table for the gant HC Table
 *
 * @param {*} options
 */
const generateHCTableResources = ({
  HCTableFilter,
  skills,
  teams,
  tasks: ganttData,
  users,
  showResourceUsers,
  resourceUsersGroup,
}) => {
  const owners = users.filter(u => !!u.team_id && isCapacityUser(u));
  const tasks = ganttData.data
    .filter(t => t.type !== 'milestone') // ignore milestones
    .filter(t => ['project', 'estimate', 'task'].includes(t.dbType));

  const _getFilteredTeamsSkills = () => {
    let ownersToShow = [];
    const sortedTeams = teams
      .filter(t => (HCTableFilter ? HCTableFilter.teamId === t.id : true))
      .sort((a, b) => (a.title?.toLowerCase() > b.title?.toLowerCase() ? 1 : -1));
    const teamsSkillsData = sortedTeams.reduce((acc, team) => {
      const teamData = tasks.filter(t =>
        ['project', 'task'].includes(t.dbType) ? t.owner && t.owner.team_id === team.id : t.team_id === team.id,
      );

      if (!teamData.length) return acc;

      const teamUsers = owners.filter(user => user.team_id === team.id);
      const _normalizeTeamDataBy = (ownerKey, fieldKey) => (acc, item) => {
        const isProject = item.dbType === 'project';
        const isTask = item.dbType === 'task';
        const notHaveEstimates = !tasks.filter(t => t.dbType === 'estimate' && t.project_id === item.id).length;
        const notHaveTasks = !tasks.filter(t => t.dbType === 'task' && t.project_id === item.id).length;

        if ((isProject && notHaveEstimates && notHaveTasks) || isTask) {
          if (!item.owner) return acc;
          acc[item.owner[ownerKey]] = item;
          return acc;
        }

        if (!item[fieldKey]) return acc;

        acc[item[fieldKey]] = item;
        return acc;
      };
      const normalizedTeamDataBySkill = teamData.reduce(_normalizeTeamDataBy('skill_id', 'skill_id'), {});
      const normalizedTeamDataByOwner = teamData.reduce(_normalizeTeamDataBy('id', 'owner_id'), {});

      const filteredSkillData = skills
        .filter(s => !!normalizedTeamDataBySkill[s.id] && (HCTableFilter ? HCTableFilter.skillId === s.id : true))
        .map(skill => {
          const skillUsers = teamUsers.filter(u => u.skill_id === skill.id);

          ownersToShow = [...ownersToShow, ...skillUsers.filter(u => !!normalizedTeamDataByOwner[u.id])];

          return {
            id: skill.id,
            title: skill.title,
            count: skillUsers.length,
          };
        });

      return [
        ...acc,
        ...filteredSkillData.map(skill => ({
          id: Math.floor(Math.random() * 1000000),
          teamId: team.id,
          teamTitle: team.title,
          teamStaff: teamUsers.length,
          skillId: skill.id,
          skillTitle: skill.title ? skill.title : 'Other',
          skillStaff: skill.count,
          resourceRow: true,
        })),
      ];
    }, []);

    return [teamsSkillsData, ownersToShow];
  };
  const [teamsAndSkills, teamsAndSkillsOwners] = _getFilteredTeamsSkills();
  let result = [];

  if (!showResourceUsers || (showResourceUsers && !!resourceUsersGroup)) {
    result = [...result, ...teamsAndSkills];
  }

  if (showResourceUsers) {
    const resources = teamsAndSkillsOwners
      .filter(u =>
        resourceUsersGroup
          ? u.team_id && u.skill_id && teamsAndSkills.find(o => o.teamId === u.team_id && o.skillId === u.skill_id)
          : true,
      )
      .map(u => ({
        id: Math.floor(Math.random() * 1000000),
        teamTitle: !resourceUsersGroup || resourceUsersGroup === 'teamAndSkill' ? getUserName(u) : '',
        ...(resourceUsersGroup
          ? { parent: teamsAndSkills.find(o => o.teamId === u.team_id && o.skillId === u.skill_id).id }
          : {}),
        skillTitle: resourceUsersGroup === 'skillAndTeam' ? getUserName(u) : '',
        skillStaff: 1,
        userId: u.id,
        teamId: u.team_id,
        skillId: u.skill_id,
        resourceRow: !!resourceUsersGroup,
      }));

    result = [...result, ...resources];
  }

  return result;
};

export default generateHCTableResources;
