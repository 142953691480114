import { useCallback, useMemo } from 'react';
import { difference } from 'ramda';

import CustomLoadingOverlay from '../components/CustomLoadingOverlay';
import { afterProcessClipboardDataFactory, getSelectedCellFactory } from '../helpers';
import processDataFromClipboardHelper from '../helpers/processDataFromClipboard';

const ALLOWED_ITEMS_ON_CONTEXT_MENU = ['copy'];
const ITEMS_TO_EXCLUDE_COL_HEADER_GENERAL_TAB = ['resetColumns'];

const useCommonGridProps = ({ isTreeView, bulkCreate, bulkUpdate, aggFuncs = {} }) => {
  const processDataFromClipboard = useCallback(
    ({ api, columnApi, data }) => {
      const columnDefs = api.getColumnDefs();

      const copyPaste = processDataFromClipboardHelper(data, api, columnApi, columnDefs, false, null, false);

      if (bulkCreate && bulkUpdate) {
        const afterProcessClipboardData = afterProcessClipboardDataFactory(bulkCreate, bulkUpdate);

        copyPaste.then(afterProcessClipboardData);
      }

      const getSelectedCell = getSelectedCellFactory(api);

      copyPaste.then(_ => {
        const selectedCell = getSelectedCell();

        if (selectedCell) {
          api.setFocusedCell(selectedCell.rowIndex, selectedCell.column.colId);
        }
      });
    },
    [bulkCreate, bulkUpdate],
  );

  return useMemo(
    () => ({
      animateRows: true,
      maintainColumnOrder: true,
      components: {
        customLoadingOverlay: CustomLoadingOverlay,
        customNoRowsOverlay: CustomLoadingOverlay,
      },
      aggFuncs,
      loadingOverlayComponent: 'customLoadingOverlay',
      noRowsOverlayComponent: 'customNoRowsOverlay',
      enableRangeSelection: true,
      // TODO: currently this option breaks the autocomplete editor save, this should be investigated
      // stopEditingWhenCellsLoseFocus: true,
      treeData: isTreeView,
      rowDragManaged: !isTreeView,
      processDataFromClipboard,

      // Header General tab items and context menu items configuration
      getMainMenuItems: params => difference(params.defaultItems, ITEMS_TO_EXCLUDE_COL_HEADER_GENERAL_TAB),
      getContextMenuItems: () => ALLOWED_ITEMS_ON_CONTEXT_MENU,
    }),
    [processDataFromClipboard, isTreeView, aggFuncs],
  );
};

export default useCommonGridProps;
