import React from 'react';
import styled from 'styled-components';
import withTheme from '@material-ui/core/styles/withTheme';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

import { NONE_PLACEHOLDER } from 'constants/common';
import FluidField from 'design-system/molecules/FluidField/index';
import InlineDatePickerNoBorder from 'design-system/atoms/InlineDatePickerNoBorder/index';
import TextFieldNoBorder from 'design-system/atoms/TextFieldNoBorder/index';


/* Todo: Access a better way to override the default z-index
 * TimePicker opens a MUI modal. We need to override only picker-popover to be above OKR drawer dialog
 */
import './fluidTimePeriodFieldOverride.css';

const DEFAULT_END_DATE_FIELD = 'end_date';
const DEFAULT_START_DATE_FIELD = 'start_date';
const DEFAULT_GRID_ITEM_SIZE = 12;

const FluidTimePeriodField = props => {
  const {
    endDate,
    onDateChange,
    startDate,
    value,
    customStyles = {},
    startDateField = DEFAULT_START_DATE_FIELD,
    endDateField = DEFAULT_END_DATE_FIELD,
    dateGridItemSize = DEFAULT_GRID_ITEM_SIZE,
    views = ['year', 'month', 'day'],
    minDate,
  } = props;

  return (
    <FluidField
      readingPlaceholder={NONE_PLACEHOLDER}
      {...props}
      renderer={(_, { placeholder } = {}) => (
        <StyledTextField
          disabled
          key="time-period-val"
          value={value?.trim()}
          customStyles={customStyles.textField}
          placeholder={placeholder}
        />
      )}
      editorRenderer={({ disableEdit }) => (
        <StyledGridContainer container customStyles={customStyles.gridContainer}>
          <StyledGridItem item md={dateGridItemSize} customStyles={customStyles.gridItem}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <InlineDatePickerNoBorder
                views={views}
                minDate={minDate}
                clearable
                keyboard
                open
                format="YYYY-MM-DD"
                label="Start Date"
                onChange={date => onDateChange({ [startDateField]: date })}
                value={startDate}
                style={{ width: '100%' }}
                disabled={disableEdit}
                customStyles={customStyles.inlineDatePicker}
              />
            </MuiPickersUtilsProvider>
          </StyledGridItem>
          <StyledGridItem item md={dateGridItemSize} customStyles={customStyles.gridItem}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <InlineDatePickerNoBorder
                views={views}
                minDate={minDate}
                clearable
                keyboard
                open
                format="YYYY-MM-DD"
                label="End Date"
                onChange={date => onDateChange({ [endDateField]: date })}
                value={endDate}
                style={{ width: '100%' }}
                disabled={disableEdit}
                customStyles={customStyles.inlineDatePicker}
              />
            </MuiPickersUtilsProvider>
          </StyledGridItem>
        </StyledGridContainer>
      )}
      ignoreBlurOn={() => !!document.querySelector('#picker-popover')}
    />
  );
};

export default withTheme()(FluidTimePeriodField);

const StyledTextField = styled(TextFieldNoBorder)`
  &&&&& {
    input:disabled {
      color: ${({ theme }) => theme.palette.black || 'inherit'};
    }
  }

  && {
    ${({ customStyles }) => customStyles}
  }
`;

const StyledGridContainer = styled(Grid)`
  && {
    ${({ customStyles }) => customStyles}
  }
`;

const StyledGridItem = styled(Grid)`
  && {
    ${({ customStyles }) => customStyles}
  }
`;
