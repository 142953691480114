import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import flatten from 'lodash/flatten';
import { not } from 'ramda';

import { getCurrentUser } from 'store/login/selectors';

import normalizeArray from 'utils/normalizeArray';
import { makeFilterProjectByOwner } from 'utils/projects/projectsUtils';

const emptyArray = [];

/**
 * Creates a filter function if user selects to see only its owned items
 * @param projects
 * @param showMyItemsOnly
 * @param avoidHierarchy
 * @return {function(*): boolean}
 */
const useProjectsLocalFilterByOwner = (projects = emptyArray, showMyItemsOnly = false, avoidHierarchy = false) => {
  const [projectsIdsToShow, setProjectsIdsToShow] = useState([]);
  const currentUser = useSelector(getCurrentUser);
  const filterByOwner = makeFilterProjectByOwner(currentUser.id);

  const projectsById = useMemo(() => normalizeArray(projects, 'id'), [projects]);

  const _filterProjectsByOwner = () => {
    // if not toggle on should not filter
    if (!showMyItemsOnly) {
      return;
    }

    let projectsToShow = [];

    // recursive function to get all project parents on the tree
    const _getProjectParents = project => {
      const parent = projectsById[project.parent_id];

      if (!parent) {
        return [];
      }

      return [parent.id, ..._getProjectParents(parent)];
    };

    // recursive function to get all project children on the current project tree
    const _getProjectChildren = project => {
      const children = projects.filter(p => p.parent_id === project.id);

      return flatten([project.id, ...children.map(c => _getProjectChildren(c))]);
    };

    // iterate over all projects to find the ones that matches
    projects.forEach(project => {
      const { id } = project;

      const isNotMatchedAlready = not(projectsToShow.includes(id));

      if (isNotMatchedAlready) {
        const projectOwnerMatch = filterByOwner(project);

        if (projectOwnerMatch) {
          const parents = not(avoidHierarchy) ? _getProjectParents(project) : [];
          const children = not(avoidHierarchy) ? _getProjectChildren(project) : [];

          projectsToShow = flatten([...projectsToShow, ...parents, ...children, id]);
        }
      }
    });

    // save on the state the visible projects
    setProjectsIdsToShow(projectsToShow);
  };

  useEffect(() => {
    _filterProjectsByOwner();
  }, [showMyItemsOnly, projectsById]);

  const filterFunction = useMemo(() => (showMyItemsOnly ? p => projectsIdsToShow.includes(parseInt(p?.id)) : null), [
    showMyItemsOnly,
    projectsIdsToShow,
  ]);

  return filterFunction;
};

export default useProjectsLocalFilterByOwner;
