import React from 'react';

import Autocomplete from 'design-system/atoms/Autocomplete/index';


// const SORTED_VALID_OPTIONS = 'sortedValidOptions';

// const getSortedValidOptions = propOr([], SORTED_VALID_OPTIONS);

const AutocompleteEnhanced = (suggestions, addNew) => props => {
  return (
    <Autocomplete
      value={props.value}
      onValueChange={props.onChange}
      suggestions={suggestions}
      addNew={addNew}
      disabled={props.disabled}
    />
  );
};

// const CustomFieldEditor = (field, props) => {
//   const fieldType = prop('field_type', field);
//   const enhancedOption = getSortedValidOptions(field).map(({ key, title }) => ({ id: key, title }));

//   return CustomFieldEditorFactory(fieldType, enhancedOption, props.value, props.onChange);
// };

// const MultiSelectEnhanced = (options, optionsMapper, addNew) => props => {
//   return (
//     <MultiSelect
//       options={options}
//       onChange={value => {
//         const newValue = options
//           .filter(row => value.indexOf(row.id?.toString()) >= 0)
//           .sort((row1, row2) => {
//             return value.indexOf(row1.id) - value.indexOf(row2.id);
//           });

//         props.onChange({
//           target: {
//             value: newValue,
//           },
//         });
//       }}
//       optionsMapper={optionsMapper}
//       value={props.value}
//       onCreateOption={addNew}
//     />
//   );
// };

export { AutocompleteEnhanced };
