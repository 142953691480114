import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  loginButton: {
    width: 250,
    marginTop: 30,
    height: 50,
  },
});

export default withStyles(styles)((props) => {
  const { classes, className, ...extraProps } = props;

  return (
    <div>
      <Button
        className={`${classes.loginButton} ${className}`}
        {...extraProps}
      />
    </div>
  );
});
