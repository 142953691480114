import React from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import Autocomplete from 'design-system/atoms/Autocomplete/index';

import Link from 'components/Link';
import SetupIntegrationToken from 'routes/Settings/Integration/components/SetupIntegrationToken/SetupIntegrationToken';

export default props => {
  const {
    projectIntegrations,
    userIntegrations,
    taskLink,
    setTaskLink,
    handleLinkTask,
    handleUnlinkTask,
    setProjectId,
    handleCreateTask,
    asanaProjects,
    linkErrorMsg,
    addUserIntegration,
    addUserIntegrationError,
  } = props;

  const suggestions = (asanaProjects || []).map(pr => ({ value: pr.gid, label: pr.name }));

  return (
    <>
      {userIntegrations && userIntegrations.length > 0 && (
        <>
          {(!projectIntegrations || projectIntegrations.length === 0) && (
            <Grid container>
              <Grid item xs={8}>
                <Autocomplete
                  hideBottomBorder
                  suggestions={suggestions}
                  onValueChange={value => {
                    setProjectId(value);
                  }}
                  placeholder="Select Asana project"
                />
              </Grid>
              <ButtonContainer item xs={4}>
                <Button id="create-asana" color="primary" onClick={handleCreateTask}>
                  Create Task
                </Button>
              </ButtonContainer>
            </Grid>
          )}

          {projectIntegrations && projectIntegrations.length > 0 && (
            <Grid container>
              <StyledLink item>
                <Link href={taskLink} target="_blank">
                  {taskLink && taskLink.replace('/f', '').match(/([^/]+$)/g)}
                </Link>
              </StyledLink>
              <ButtonContainer item>
                <Button color="primary" onClick={handleUnlinkTask}>
                  Unlink
                </Button>
              </ButtonContainer>
            </Grid>
          )}

          {(!projectIntegrations || projectIntegrations.length === 0) && (
            <Grid container>
              <Grid item xs={8}>
                <TextField
                  label="Enter your task link"
                  fullWidth
                  value={taskLink}
                  onChange={event => setTaskLink(event.target.value)}
                  disabled={projectIntegrations && projectIntegrations.length > 0}
                />
              </Grid>
              <ButtonContainer item xs={4}>
                <Button id="link-asana" color="primary" onClick={handleLinkTask}>
                  Link task
                </Button>
              </ButtonContainer>
              {linkErrorMsg && linkErrorMsg.length && (
                <ErrorDiv item xs={12}>
                  <ErrorSpan>{linkErrorMsg}</ErrorSpan>
                </ErrorDiv>
              )}
            </Grid>
          )}
        </>
      )}
      {(!userIntegrations || !userIntegrations.length) && (
        <Grid container>
          <Grid item xs={12}>
            <SetupIntegrationToken
              integrationName="Asana"
              integrationHelpLink="https://app.asana.com/0/developer-console"
              connectButtonLabel="CONNECT"
              saveToken={token => addUserIntegration(token)}
              error={addUserIntegrationError}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const ButtonContainer = styled(Grid)`
  &&&& {
    display: flex;
    align-items: flex-end;
  }
`;

const StyledLink = styled(Grid)`
  &&&& {
    display: flex;
    align-items: center;

    a {
      font-size: 0.875rem;
    }
  }
`;

const ErrorDiv = styled(Grid)`
  &&&& {
    padding-top: 5px;
  }
`;

const ErrorSpan = styled.span`
  &&&& {
    color: red;
  }
`;
