/**
 *
 * @param {String} text The text to be copied.
 */
export default async text => {
  try {
    return navigator.clipboard.writeText(text);
  } catch (error) {
    console.error('Failed to copy text: ', error);
  }
};
