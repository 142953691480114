import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FluidField from 'design-system/molecules/FluidField/index';
import HealthSelect from 'design-system/molecules/HealthSelect/index';


const FluidHealthField = props => (
  <FluidField
    {...props}
    editorRenderer={({ value, save, cancel, disableEdit }) => (
      <HealthContainer>
        <HealthSelect disableEdit={disableEdit} cancel={cancel} save={save} value={value} />
      </HealthContainer>
    )}
  />
);

FluidHealthField.propTypes = {
  ...FluidField.propTypes,
  milestoneDisabled: PropTypes.bool,
  hasHierarchy: PropTypes.bool,
};

export default FluidHealthField;

const HealthContainer = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;
