import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loadAndOpenCustomerRequest } from 'store/customerRequests/actions';
import { getQueryParamFromUrl } from 'utils/queryParamsUtils';
import { OPEN_CUSTOMER_REQUEST_QUERY_PARAM } from 'constants/customerRequests';

export default () => {
  const dispatch = useDispatch();

  const urlOpenCr = getQueryParamFromUrl(OPEN_CUSTOMER_REQUEST_QUERY_PARAM);

  useEffect(() => {
    if (urlOpenCr === null) {
      return;
    }

    if (!Number.isNaN(Number(urlOpenCr))) {
      dispatch(loadAndOpenCustomerRequest(+urlOpenCr));
    }
  }, [urlOpenCr]);
};
