import history from 'store/utils/history';

export const addQueryParamToUrl = (queryParam, value) => {
  const path = window.location.pathname;
  const params = new URLSearchParams(window.location.search);

  params.set(queryParam, value);
  history.push(`${path}?${params.toString()}`);
};

export const removeQueryParamFromUrl = queryParam => {
  const path = window.location.pathname;
  const params = new URLSearchParams(window.location.search);

  params.delete(queryParam);
  history.push(`${path}?${params.toString()}`);
};

export const removeAllQueryParamsFromUrl = () => {
  const path = window.location.pathname;

  history.push(`${path}`);
};

export const getQueryParamFromUrl = queryParam => {
  const params = new URLSearchParams(window.location.search);

  return params.get(queryParam);
};

export const addQueryParamAndOpenOnNewTab = (queryParam, value) => {
  const path = window.location.pathname;
  const params = new URLSearchParams(window.location.search);

  params.set(queryParam, value);
  window.open(`${path}?${params.toString()}`, '_blank');
};

export default {
  addQueryParamToUrl,
  removeQueryParamFromUrl,
  getQueryParamFromUrl,
  addQueryParamAndOpenOnNewTab,
};
