import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Redirect } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import queryString from 'query-string';

import AuthWrapper from 'components/AuthWrapper';
import FormTextField from 'components/FormTextField';
import AuthErrorMessage from 'components/AuthErrorMessage';
import AuthMainButton from 'components/AuthMainButton';

import { superlogin, getOrganization } from 'store/login';
import { getDefaultRoute } from 'utils';

class SuperLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      organization: null,
    };
  }

  async componentDidMount() {
    const { isAuthenticated, currentUser } = this.props;

    const { next } = queryString.parse(this.props.location.search);

    this.props.getOrganization();

    if (isAuthenticated) {
      if (next) {
        return <Redirect to={next} />;
      }

      return <Redirect to={getDefaultRoute(currentUser)} />;
    }
  }

  render() {
    const { handleSubmit, errorMessage, organization } = this.props;

    if (!organization) {
      return <div />;
    }

    const renderLogInForm = handleSubmit => {
      return (
        <div>
          <AuthWrapper>
            <Typography color="textSecondary" variant="h6" className={this.props.classes.orDiv}>
              Super Admin Login to {organization.title && organization.title}
              {!organization.title && organization.slug}
            </Typography>
            <Typography color="textSecondary" variant="body1">
              {organization.host}
            </Typography>
            <form onSubmit={handleSubmit}>
              <div style={{ marginTop: '30px' }}>
                <FormTextField name="email" placeholder="Email" className={this.props.classes.textFields} type="text" required />
              </div>
              <div style={{ marginTop: '30px' }}>
                <FormTextField
                  name="password"
                  placeholder="Password"
                  className={this.props.classes.textFields}
                  type="password"
                  required
                />
              </div>
              <div style={{ marginTop: '30px' }}>
                <FormTextField
                  name="otp"
                  placeholder="OTP Token (6 digits)"
                  className={this.props.classes.textFields}
                  type="password"
                  required
                />
              </div>
              {errorMessage && <AuthErrorMessage errorMessage={errorMessage} />}
              <AuthMainButton variant="contained" color="primary" type="submit">
                Super Admin Login
              </AuthMainButton>
            </form>
          </AuthWrapper>
        </div>
      );
    };

    return renderLogInForm(handleSubmit);
  }
}

const mapStateToProps = state => {
  const { login } = state;

  return {
    isAuthenticated: login.isAuthenticated,
    errorMessage: login.errorMessage,
    organization: login.organization,
    currentUser: login.currentUser,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onSubmit: (values, dispatch, props) => {
    dispatch(superlogin(values, props));
  },
  getOrganization: () => dispatch(getOrganization(props)),
});

const styles = theme => ({
  textFields: {
    width: 250,
  },
  orDiv: {
    marginTop: 20,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'login',
    enableReinitialize: true,
  })(withStyles(styles)(SuperLogin)),
);
