export const PLANNED = 'planned';
export const REPORTED = 'reported';
export const COMPLETED = 'completed';
export const TARGET = 'target';
export const ACTUAL = 'actual';
export const BAR_CHART = 'bar-chart';
export const PIE_CHART = 'pie-chart';
export const STACKED_CHART = 'stacked-chart';
export const STACKED_CHART_PERCENTAGE = 'stacked-chart-%';
export const TABLE = 'table';
export const DISPLAY_LEGEND_INLINE = 'display-legend-inline';
export const STACKED_BY_TIMEFRAME_CHART = 'stacked-by-timeframe-chart';
export const STACKED_BY_TIMEFRAME_PERCENTAGE_CHART = 'stacked-by-timeframe-percentage-chart';
export const PREFERENCES = 'preferences';
export const DOWNLOAD_PNG = 'download-png';
