import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';

import { getObjectives, selectKeyResults1 } from 'store/objectives/selectors';
import { getRoadmaps, getCorpRoadmaps, getProducts } from 'store/roadmaps/selectors';
import { getGroupOptions } from 'store/objectives/helpers/groupOptions';
import { getGroupingObjects } from 'store/objectives/helpers/groupingObjects';

import { getOrganizationSystemFieldsNames } from 'store/organization/selectors';

import getSystemFieldName from 'utils/getSystemFieldName';

import { getNestedProjectGroups } from 'src/store/projects/helpers/groupSelectors';
import { METADATA_LEVELS } from 'constants/common';
import { getIsDodActive } from 'store/accessControl/selectors';
import { hideEmptyBasedOnPrefOrArchived } from 'utils/grouping';

/**
 * Creates a selector that retrieves all the data from the store needed for grouping.
 *
 * @returns A selector with all the data objects needed for grouping.
 */
export const getDefaultGroupingObjects = createSelector(
  // Objectives & Key Results - include archived for Advanced search by status
  state => getObjectives(state, true, METADATA_LEVELS.LEVEL_CORP),
  state => getObjectives(state, true),
  state => selectKeyResults1(state, true),
  state => getCorpRoadmaps(state, true),
  state => getRoadmaps(state, true),
  state => getProducts(state, true),
  (objectivesCorp, objectives, keyResults1, roadmapsCorp, roadmaps, products) => ({
    objectivesCorp,
    objectives,
    keyResults1,
    roadmapsCorp,
    roadmaps,
    products,
  }),
);

const KEY_FIELD_MAP = {
  objectiveCorp: 'objective_corp_id',
  objective: 'objective_id',
  keyResult1: 'parent_id',
  roadmapsCorp: 'roadmapsCorp',
  roadmaps: 'roadmaps',
  products1: 'products',
};

const mapGroupKeyToField = key => KEY_FIELD_MAP[key];

/**
 * Set field value on a given group if it is no present
 *
 * @param  {Object} group
 * @param  {String} group.key key representing the type of the group
 * @param  {String} group.field the project attribute that holds the group value
 */
const _setFieldAttributeOnGroup = group => {
  const groupDoesntExist = !group;
  const groupAlreadyHasField = group?.field;
  const groupHasNoKey = !group?.key;

  if (groupDoesntExist || groupAlreadyHasField || groupHasNoKey) return;

  const field = mapGroupKeyToField(group.key);

  if (!field) return;

  group.field = field;
};

const formatGroupedDate = (date, selectedGroupByDates) => {
  switch (selectedGroupByDates?.groupBy) {
    case 'month': {
      return {
        id: moment(date).format('YYYY-MM'),
        title: moment(date).format('YYYY-MM'),
        start: moment(date).startOf('month'),
        end: moment(date).endOf('month'),
      };
    }
    case 'year': {
      return {
        id: moment(date).format('YYYY'),
        title: moment(date).format('YYYY'),
        start: moment(date).startOf('year'),
        end: moment(date).endOf('year'),
      };
    }
    default: {
      return {
        id: `${moment(date).format('YYYY')}-Q${moment(date).format('Q')}`,
        title: `${moment(date).format('YYYY')}-Q${moment(date).format('Q')}`,
        start: moment(date).startOf('quarter'),
        end: moment(date).endOf('quarter'),
      };
    }
  }
};

/**
 * Get all groups based on the filtered projects and the selected fields for grouping.
 *
 * @param {Object} groupingOptions
 * @param {Object} groupingOptions.selectedGroup1 The info about the field to be used for groups of level 1
 * @param {Object} groupingOptions.selectedGroup2 The info about the field to be used for groups of level 2
 * @param {Object} groupingOptions.selectedGroup3 The info about the field to be used for groups of level 3
 * @param {String} groupingOptions.page The page filter
 * @param {Boolean} groupingOptions.onlyProjects The flag to includes only projects
 * @param {Boolean} groupingOptions.hideEmpty If active should hide groups without elements
 * @param {Boolean} groupingOptions.withHierarchy If the result should consider the project hierarchy, meaning that
 * the projects should not be duplicated as children of other projects and also exists in the specific layer
 * @param {Function} groupingOptions.projectsFilters An array of filter functions to apply to all the projects
 * @param {Function} groupingOptions.projectMapper A mapper function to apply to the projects
 * @param {Function} groupingOptions.customMetadata A custom metadata object to override the store defaults
 * @param {Function} groupingOptions.customAllProjectsByLayer A custom set of projects by layer to be considered for grouping
 * @returns The groups hierarchy structure with the three level groups
 */
const makeSelectObjectivesGroups = ({
  selectedGroup1,
  selectedGroup2,
  selectedGroup3,
  objects = [],
  hideEmptyUserPref = true,
  keyResultLevel = 1,
}) =>
  createSelectorCreator(defaultMemoize, isEqual)(
    defaultGroupingObjects => defaultGroupingObjects,
    defaultGroupingObjects => {
      // Support for legacy views with no field value set on groups
      _setFieldAttributeOnGroup(selectedGroup1);
      _setFieldAttributeOnGroup(selectedGroup2);
      _setFieldAttributeOnGroup(selectedGroup3);

      const hasAtLeastOneSelectedGroup = !!selectedGroup1?.key;

      const filteredObjects = objects.filter(o => o.level === keyResultLevel);

      if (!hasAtLeastOneSelectedGroup || (hideEmptyUserPref && isEmpty(filteredObjects))) {
        return [];
      }

      const selectedGroupByDates = head(
        [selectedGroup1, selectedGroup2, selectedGroup3].filter(group => group?.type === 'dates'),
      );

      const groupedDates = filteredObjects
        .map(p => p[selectedGroupByDates?.field])
        .sort((a, b) => moment(a) - moment(b))
        .filter(d => moment(d).isValid())
        .map(d => formatGroupedDate(d, selectedGroupByDates))
        .reduce((result, d) => {
          result[d.title] = d;
          return result;
        }, {});

      const groupingObjects = getGroupingObjects({
        defaultGroupingObjects,
        groupedDates,
      });

      const hideEmptyWith = (_, groupObject) => hideEmptyBasedOnPrefOrArchived({ groupObject, hideEmptyUserPref });

      return getNestedProjectGroups(filteredObjects, groupingObjects, [selectedGroup1, selectedGroup2, selectedGroup3], {
        hideEmptyWith,
      });
    },
  );

const getGroupOptionsSelector = createSelector(
  getOrganizationSystemFieldsNames,
  getIsDodActive,
  (_, options) => options,
  (systemFieldsNames, isDodActive, options) => {
    const customGetSystemFiedName = name => getSystemFieldName(name, systemFieldsNames, false);

    return getGroupOptions({
      getSystemFieldName: customGetSystemFiedName,
      isDodActive,
      ...options,
    });
  },
);

export { makeSelectObjectivesGroups, getGroupOptionsSelector };
