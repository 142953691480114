import React from 'react';
import styled from 'styled-components';

import History from 'components/History/History';
import RemirrorWYSIWYGEditor from 'design-system/molecules/RemirrorWYSIWYGEditor';

import CenteredLoading from 'features/PortfolioOverview/components/CenteredLoading';

import usePortfolioOverviewHistory from './useDescriptionHistory';
import theme, { spacing } from 'design-system/theme';

const DescriptionHistory = ({ portfolioOverviewId }) => {
  const { portfolioOverviewHistory, hasNext, isLoadingHistory, fetchNextPage, systemFields } = usePortfolioOverviewHistory({
    portfolioOverviewId,
  });

  const customFormat = {
    description: value => <RemirrorWYSIWYGEditor content={value} smallInput disableEdit />,
  };

  const renderHistoryContent = () => {
    return portfolioOverviewHistory?.length ? (
      <History
        objects={portfolioOverviewHistory}
        hasNext={hasNext}
        fetchNextPage={fetchNextPage}
        systemFields={systemFields}
        customFormat={customFormat}
      />
    ) : (
      <EmptyMessage>No records</EmptyMessage>
    );
  };

  return <Wrapper>{isLoadingHistory ? <CenteredLoading /> : renderHistoryContent()}</Wrapper>;
};

const Wrapper = styled.div`
  min-width: 40vw;
`;

const EmptyMessage = styled.div`
  padding: ${spacing(2)}px ${spacing(5)}px;
  font-size: ${theme.typography.fontSizeRem}rem;
  font-style: italic;
  text-align: center;
`;

export default DescriptionHistory;
