import { propOr } from 'ramda';

const getAllTimeViews = propOr(0, 'total_view_count');

export default view => {
  const allTimeViews = getAllTimeViews(view);

  return {
    allTimeViews,
  };
};
