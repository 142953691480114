import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import flatten from 'lodash/flatten';

import useProjectGroups from 'hooks/useProjectGroups';
import useProjectsLocalSearch from 'hooks/projects/useProjectsLocalSearch';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useProjectsFrontendFilters from 'hooks/filters/useProjectsFontendFilters';
import useProjectLocalFilterByDeliverableOwner from 'hooks/projects/useProjectLocalFilterByDeliverableOwner';
import useProjectsLocalFilterByDeliverableStatus from 'hooks/projects/useProjectsLocalFilterByDeliverableStatus';

import { PDLC } from 'store/grids';
import { getDisplayLayer } from 'store/filters/selectors';
import { getAllProjectsFiltered } from 'store/projects/groupSelectors';
import { getGridConfigValue } from 'store/grids/selectors';
import { getSelectedDeliverableStatusPDLCGrid } from 'store/cycleDeliverables/selectors';
import { getOrgHasMetadataRoadmaps as getOrgHasMetadataRoadmapsSelector } from 'store/organization';

import setIdeasHierarchyPath from 'utils/setIdeasHierarchyPath';
import { filterAllEntries, makeItSelectable } from 'utils/grid';

import { GLOBAL_FILTER } from 'constants/filters';

const useIdeasPDLCData = ({
  viewType = PDLC,
  portfolioMode,
  pageId,
  selectedGroup1,
  selectedGroup2,
  selectedGroup3,
  getSystemFieldName,
  showMyItemsOnly = false,
}) => {
  const displayLayer = useSelector(getDisplayLayer);
  const allData = useSelector(state => getAllProjectsFiltered(state, GLOBAL_FILTER, true, true));
  const projectsInAllLayers = useMemo(() => flatten(Object.values(allData)), [allData]);
  const selectedDeliverableStatus = useSelector(getSelectedDeliverableStatusPDLCGrid);
  const searchText = useSelector(state => getGridConfigValue(state, viewType, 'searchText'));
  const bulkDelete = useSelector(state => getGridConfigValue(state, viewType, 'bulkDelete'));

  const isSingleLayerGroupedBy = !portfolioMode && !!selectedGroup1?.key;
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmapsSelector);
  const { getDefaultRoadmapTitleForMetadataItem } = useOrganizationsAccessControl();
  const [filterBySearchString] = useProjectsLocalSearch(projectsInAllLayers, searchText);
  const filterMyItems = useProjectLocalFilterByDeliverableOwner(projectsInAllLayers, showMyItemsOnly);
  const filterByDeliverableStatus = useProjectsLocalFilterByDeliverableStatus(projectsInAllLayers, selectedDeliverableStatus);

  const { applyFrontendFiltersOnProject, applyFrontendFilters } = useProjectsFrontendFilters(
    filterMyItems,
    filterByDeliverableStatus,
    filterBySearchString,
  );

  const projectsFilters = useMemo(() => [applyFrontendFiltersOnProject], [applyFrontendFiltersOnProject]);

  const projectGroups = useProjectGroups({
    projectsFilters,
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    withHierarchy: portfolioMode,
    customAllProjectsByLayer: allData,
    id: pageId,
  });

  const data = useMemo(() => {
    let data;
    const filteredData = filterAllEntries(applyFrontendFilters, allData);

    if (portfolioMode || isSingleLayerGroupedBy) {
      data = setIdeasHierarchyPath(
        filteredData,
        displayLayer,
        getSystemFieldName('initiative', false),
        getSystemFieldName('bet', false),
        isSingleLayerGroupedBy,
        projectGroups,
        hasMetadataRoadmaps,
        getDefaultRoadmapTitleForMetadataItem,
      );
    } else {
      data = applyFrontendFilters(allData[displayLayer]);
    }

    if (bulkDelete) data = makeItSelectable(data);

    return data;
  }, [allData, displayLayer, bulkDelete, portfolioMode, isSingleLayerGroupedBy, projectGroups, applyFrontendFilters]);

  return data;
};

export default useIdeasPDLCData;
