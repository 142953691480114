import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import PageTitle from 'design-system/atoms/PageTitle/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import InfoToast from 'components/InfoToast';

import { getPlan, getNumEditors, getRecurrency, numberWithCommas, getUnpaidEditors, getTotalEditors } from './billingUtils';

class SubscriptionInfo extends PureComponent {
  constructor(props) {
    super(props);

    this.getPlan = getPlan.bind(this);
    this.getNumEditors = getNumEditors.bind(this);
    this.getRecurrency = getRecurrency.bind(this);
    this.numberWithCommas = numberWithCommas.bind(this);
    this.getUnpaidEditors = getUnpaidEditors.bind(this);
    this.getTotalEditors = getTotalEditors.bind(this);

    this.state = {
      nEditorsToAdd: this.getUnpaidEditors(),
    };
  }

  updateEditors = async newValue => {
    this.setState({ loading: true });
    await axios.post('/api/billing/editors', {
      quantity: newValue,
    });

    toast(<InfoToast>Your subscription was successfully update</InfoToast>);
    await this.props.updateBillingInfo();
    this.setState({ loading: false });
  };

  // state = {};
  render() {
    return (
      <Fragment>
        {!this.props.loading && (
          <BillingPaper>
            <Wrapper>
              <PageTitle>Add Editors</PageTitle>
              <TextDeprecated>
                {this.getPlan()} plan (billed {this.getRecurrency()})
              </TextDeprecated>
              <TextDeprecated>Total Editors: {this.getTotalEditors()}</TextDeprecated>
              <TextDeprecated>Number of editors paid: {this.getNumEditors()}</TextDeprecated>

              <AddUsersFormContainer>
                <TextField
                  name="duration"
                  label="Editors to add"
                  type="number"
                  value={this.state.nEditorsToAdd}
                  onChange={e => this.setState({ nEditorsToAdd: e.target.value })}
                  InputProps={{ min: 1 }}
                />
                <SubmitAddUsersButton
                  color="primary"
                  disabled={this.state.loading}
                  onClick={() => this.updateEditors(this.state.nEditorsToAdd)}
                >
                  Submit
                </SubmitAddUsersButton>
              </AddUsersFormContainer>
            </Wrapper>
          </BillingPaper>
        )}
        {this.props.loading && <div />}
      </Fragment>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
`;

const SubmitAddUsersButton = styled(Button)`
  &&&& {
    margin-top: 20px;
    margin-left: 10px;
  }
`;

const AddUsersFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
`;

const BillingPaper = styled.div`
  max-width: 70%;
  // flex-grow: 1;
  padding: 40px 40px 30px 40px;
`;

export default SubscriptionInfo;
