import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addUserViewFavorite, removeUserViewFavorite } from 'store/userViews';
import { getUserFavoriteViews } from 'store/userViews/selectors';
import { getUsers } from 'store/users/selectors';

import formatUserViews from 'utils/userViews/formatUserViews';

/**
 * @function useUserFavoriteViews
 *
 * use all user favorite views data and action attached (for future developments)
 *
 * @return {Object}
 */
const useUserFavoriteViews = () => {
  const dispatch = useDispatch();
  const userFavoriteViews = useSelector(getUserFavoriteViews);
  const users = useSelector(getUsers);

  const handleFavoriteUserView = useCallback(
    view => {
      const notFavorite = !view?.favorites?.length;

      if (notFavorite) {
        dispatch(addUserViewFavorite(view.id));
      } else {
        dispatch(removeUserViewFavorite(view.id));
      }
    },
    [dispatch],
  );

  const isFavoriteView = view => !!view?.favorites?.length;

  const formattedViews = useMemo(() => formatUserViews(userFavoriteViews, users), [userFavoriteViews, users]);
  /*
   * TODO: migrate the favorite actions to here
   *  - add view as favoritre
   *  - remove view as favoritre
   */

  return {
    userViews: formattedViews,
    handleFavoriteUserView,
    isFavoriteView,
  };
};

export default useUserFavoriteViews;
