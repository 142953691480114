import React, { Component } from 'react';
import RootRef from '@material-ui/core/RootRef';

import Autocomplete from 'design-system/atoms/Autocomplete/index';


export default class AgGridAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.getValue = this.getValue.bind(this);

    this.state = {
      value: props.value || '',
    };

    this.domRef = React.createRef();
  }

  getValue() {
    return this.state.value;
  }

  onChange(value) {
    this.setState({ value });
  }

  isPopup() {
    return true;
  }

  render() {
    const { value } = this.state;
    const { autoFocus, options, addNew, charPress, autocompleteComponent } = this.props;

    const AutocompleteComponent = autocompleteComponent || Autocomplete;

    return (
      <RootRef rootRef={this.domRef}>
        <AutocompleteComponent
          suggestions={options}
          value={value}
          autoFocus={autoFocus}
          onValueChange={this.onChange}
          addNew={addNew}
          charPress={charPress}
        />
      </RootRef>
    );
  }
}
