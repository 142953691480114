import styled from 'styled-components';

const TableRowContent = styled.div`
  &&&& {
    height: 34px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

TableRowContent.displayName = 'TableRowContent';

export default TableRowContent;
