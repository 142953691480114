/**
 * InlineIcon Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import styled from 'styled-components';

export default styled.div`
  display: inline-block;
  vertical-align: middle;
`;
