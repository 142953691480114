import pick from 'lodash/pick';

import getSystemFieldName from 'utils/getSystemFieldName';
import { FILTERS_FIELDS } from './filters/fields';

export const COLOR_BY_OPTIONS = (systemFields, hasKeyResults, hasProducts) => [
  // { key: 'health', title: 'Health' },
  { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
  ...(hasProducts ? [{ key: 'product1', title: getSystemFieldName('product1', systemFields) }] : []),
  // { key: 'objective', title: getSystemFieldName('objective', systemFields) },
  // ...(hasKeyResults ? [{ key: 'keyResult1', title: getSystemFieldName('keyResult1', systemFields) }] : []),
  // { key: 'idea', title: getSystemFieldName('idea', systemFields) },
  // { key: 'theme', title: getSystemFieldName('theme', systemFields) },
  // { key: 'category', title: getSystemFieldName('category', systemFields) },
];

export const DEFAULT_COLOR_BY_OPTION = systemFields => COLOR_BY_OPTIONS(systemFields).find(g => g.key === 'roadmap');

export const GROUP_OPTIONS_FOR_TASKS = ({ systemFields }) => [
  { key: null, title: 'No group' },
  { key: 'team', title: getSystemFieldName('team', systemFields) },
  { key: 'skill', title: getSystemFieldName('skill', systemFields) },
];

export const DEFAULT_GROUP_OPTION = opts => ({
  selectedGroup1: opts.find(g => g.key === 'team'),
  selectedGroup2: opts.find(g => g.key === 'skill'),
});

export const ZOOM_OPTIONS = [
  { id: 'day', title: 'Daily' },
  { id: 'week', title: 'Weekly' },
  { id: 'month', title: 'Monthly' },
  { id: 'quarter', title: 'Quarter' },
];

export const DEFAULT_ZOOM_OPTION = ZOOM_OPTIONS.find(g => g.id === 'week');

export const STAFFING_FILTERS_FIELDS = pick(FILTERS_FIELDS, ['teams', 'skills', 'users']);

export const STAFFING_QUICK_FILTER_OPTIONS = getSystemFieldName => [
  { id: 'teams', label: getSystemFieldName('team') },
  { id: 'skills', label: getSystemFieldName('skill') },
];
