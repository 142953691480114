import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import { ThemeProvider } from 'styled-components';

import { getDisplayLayer, usePortfolioMode } from 'store/filters/selectors';
import { getProjectHealthLabel } from 'utils/projects/healthUtils';
import { getOutcomeSnapshotState } from 'store/goalMode/selectors';
import { getSnapshotState } from 'store/snapshot/selectors';

import useSystemFields from 'hooks/useSystemFields';

import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import isNumeric from 'utils/isNumeric';
import { getOrgHasBet } from 'store/organization';
import MicroChip from 'design-system/atoms/MicroChip';
import theme from 'design-system/theme';

const DEFAULT_COLUMNS = [{ name: 'text', label: 'Title', tree: true, width: 350, resize: true }];
const getGoalModeColumns = getSystemFieldName => [
  { name: 'roadmapTitle', label: getSystemFieldName('roadmap'), width: 75, resize: true },
  {
    name: 'progressPercent',
    label: 'Progress',
    width: 75,
    resize: true,
    template: obj => (isNumeric(obj.progressPercent) ? `${obj.progressPercent}%` : null),
  },
];
const PORTFOLIO_MODE_COLUMNS = [
  { name: 'ownerName', label: 'Owner', width: 75, resize: true },
  {
    name: 'status_color',
    label: 'Health',
    width: 90,
    resize: true,
    template: obj => {
      const label = getProjectHealthLabel(obj.status_color);

      return (
        obj.status_color &&
        ReactDOMServer.renderToString(
          <ThemeProvider theme={theme}>
            <MicroChip
              label={label}
              color={theme.palette.healthStatus[label]?.background}
              textColor={theme.palette.healthStatus[label]?.color}
              variant="bold"
              fullWidth
            />
          </ThemeProvider>,
        )
      );
    },
  },
];

const ALLOCATION_COLUMNS = [
  { name: 'plannedAllocation', label: 'Planned Allocation (Weeks)', width: 120, resize: true },
  { name: 'reportedAllocation', label: 'Reported Allocation (Weeks)', width: 120, resize: true },
];

export default function useTimelineLightbox(isGoalMode, showAllocationColumns, openItems) {
  const [getSystemFieldName] = useSystemFields();

  const storedStateSelector = isGoalMode ? getOutcomeSnapshotState : getSnapshotState;
  const storedState = useSelector(storedStateSelector);

  const [timelineLightboxState, setTimelineLightboxState] = useState(storedState);

  const displayLayerState = useSelector(getDisplayLayer);
  const isPortfolioMode = useSelector(usePortfolioMode);
  const hasBets = useSelector(getOrgHasBet);

  const displayLayer = useMemo(() => {
    if (isGoalMode) {
      return hasBets ? BET_LAYER : INITIATIVE_LAYER;
    }

    return displayLayerState;
  }, [hasBets, isGoalMode]);

  const getColumns = useCallback(() => {
    return [
      ...DEFAULT_COLUMNS,
      ...(isGoalMode ? getGoalModeColumns(getSystemFieldName) : PORTFOLIO_MODE_COLUMNS),
      ...(showAllocationColumns ? ALLOCATION_COLUMNS : []),
    ];
  }, [getSystemFieldName, isGoalMode]);

  const updateState = useCallback(
    state => {
      setTimelineLightboxState({
        ...timelineLightboxState,
        ...state,
      });
    },
    [timelineLightboxState, setTimelineLightboxState],
  );

  return {
    displayLayer,
    getColumns,
    hasBets,
    isPortfolioMode,
    lsState: timelineLightboxState,
    portfolioMode: isGoalMode || isPortfolioMode,
    updateState,
  };
}
