import moment from 'moment';
import { pipe, map, filter, isEmpty, pluck } from 'ramda';

const notEmptyValue = val => !isEmpty(val) && !Number.isNaN(val);
const isFinite = val => val && Number.isFinite(val);

/**
 * Returns the min or max date in a given array
 * @param {Function} the function used to extract min or max date
 */
const minMaxDates = minMaxFunc =>
  pipe(
    map(Date.parse), // Try to parse all values as dates
    filter(notEmptyValue), // Remove null, false and NaN values
    values => minMaxFunc(...values),
    timestamp => (isFinite(timestamp) ? new Date(timestamp) : null),
  );

/**
 * Returns the minimum date present in the given array
 * @param {Array of items containing possible dates} array
 * @returns {Date|null}
 */
export const getMinDate = minMaxDates(Math.min);

/**
 * Returns the maximum date present in the given array
 * @param {Array of items containing possible dates} array
 * @returns {Date|null}
 */
export const getMaxDate = minMaxDates(Math.max);

/**
 * Calculates min start date and max end date using each estimate start date and duration.
 * @param {Estimate[] | RoadmapVersionEstimate[]} estimates
 * @returns {{startDate: Date, endDate: Date}} start and end date
 */
export default estimates => {
  const startDates = pluck('start_date')(estimates);
  const endDates = estimates.map(estimate =>
    moment(estimate.start_date)
      .add(estimate.duration - 1, 'days')
      .toDate(),
  );

  const minStartDate = getMinDate(startDates);
  const maxEndDate = getMaxDate(endDates);

  return {
    startDate: minStartDate,
    endDate: maxEndDate,
  };
};
