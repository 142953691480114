import React, { useMemo } from 'react';
import { path, not, propEq, anyPass } from 'ramda';
import { useSelector } from 'react-redux';

import { getOrganization, getOrgHasMetadataRoadmaps, getHasMultipleMetrics, getOrgHasMetricCharts } from 'store/organization';

import useNumberOfTextRowsToDisplayOnGridCell from 'hooks/useNumberOfTextRowsToDisplayOnGridCell';
import useSystemFields from 'hooks/useSystemFields';

import GroupHeaderInnerRenderer from 'containers/Grids/OkrsGrid/components/GroupHeaderInnerRenderer';
import TitleCellRenderer from 'containers/Grids/OkrsGrid/components/TitleCellRenderer';
import InsightsIcon from 'design-system/atoms/InsightsIcon/index';

import { ProgressCellRenderer, WYSIWYGCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { ProgressCellEditor, WYSIWYGCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import {
  getOwnerColumnDef,
  colorColumnDef,
  numericColumnDef,
  okrHealthColumnDef as OkrHealthColumnDef,
  getTitleColumnDef,
  getDragColumnDef,
  getMetadataRoadmapColumnDef,
  targetAllocationAmountColumnDef as TargetAllocationAmountColumnDef,
  targetAllocationPercentageColumnDef as TargetAllocationPercentageColumnDef,
  defaultColumnDefCommonProps,
} from 'design-system/molecules/AgGridReact-New/columns';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import dateColumnDef from 'design-system/molecules/AgGridReact-New/columns/dateColumnDef';

import { returnsTrueIfKeyIsNotEscOrTab } from 'utils/agGrid';
import { GROUP_COLUMN_INITIAL_WIDTH } from 'constants/grid';
import { OBJECTIVE_KEY, KEY_RESULT_1_KEY, KEY_RESULT_2_KEY, CORP_OBJECTIVE_KEY } from 'constants/objectives';

import makeCanObjectiveMetadataRoadmapBeUpdated from 'utils/okrs/makeCanObjectiveMetadataRoadmapBeUpdated';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import usePermissions from 'hooks/permissions/usePermissions';
import { OBJECT_KEY_RESULT } from 'store/objectives';
import { getPermissionResourceByKeyResultLevel } from 'utils/okrs';

import getMetricsColumnDef from './metricsColumn';
import {
  defaultExport,
  exportDate,
  exportHealth,
  exportMetadataRoadmap,
  exportMultiLineText,
  exportMultiSelectMetadata,
  exportValueAsPercentage,
} from 'features/GridExport/helpers/exportUtils';

const isNumberObjective = propEq('type', 0);
const isStringKeyResult = propEq('type', OBJECT_KEY_RESULT);
const isTypeKeyResult = anyPass([isNumberObjective, isStringKeyResult]);

const ROADMAP_OBJECTIVE_FIELD_KEY = 'objective_roadmaps';
const ROADMAP_FIELD_KEY = 'key_result_roadmaps';
const UNDEFINED_ID = 'null';

const objectiveEntityKeys = [CORP_OBJECTIVE_KEY, OBJECTIVE_KEY];

const checkIsNewOkrRow = data => !data.id;

const isTitleColumn = colDef => colDef.field === 'title';

const checkIsEditableOKR =
  (hasCorpLevel, organizationId, canUpdate, canCreate) =>
  ({ colDef, data }) => {
    if (data.id === UNDEFINED_ID) {
      return false;
    }

    if (!isTitleColumn(colDef) && checkIsNewOkrRow(data)) {
      return false;
    }

    const resource = isTypeKeyResult(data) ? getPermissionResourceByKeyResultLevel(data.level) : PERMISSION_RESOURCES.objective;
    const allowUpdate = canUpdate(resource, { data });
    const allowCreate = canCreate(resource);

    // TODO: move this condition to permissions module
    if (hasCorpLevel) {
      return data?.organization_id === organizationId && allowUpdate;
    }

    if (checkIsNewOkrRow(data)) {
      return allowCreate;
    }

    return allowUpdate;
  };

const checkShouldShowActions = ({ data }) => data.id !== UNDEFINED_ID;

const useOkrTableGridColumns = ({
  hasKeyResults,
  hasKeyResults2,

  isTreeView,
  selectedField,
  visibleColumns,
  metadataTitle,
  displayLayerLabel,
  searchString,

  users,

  metrics,
  hasAdvancedMetricReporting,
  openMetricView,
  createMetricOption,

  checkRowDrag,
  disableDrag,
  addVisibility,
  handleAdd,
  handleOpenItem,
  handleOpenProjectsLightbox,
  rowHeight,

  roadmapsMetadata,
  hasCorpLevel,
}) => {
  const [getSystemFieldName] = useSystemFields();
  const organization = useSelector(state => getOrganization(state));
  const hideMetadataRoadmaps = !useSelector(getOrgHasMetadataRoadmaps);
  const hasMultipleMetrics = useSelector(getHasMultipleMetrics);
  const hasMetricCharts = useSelector(getOrgHasMetricCharts);

  const { canUpdate, canCreate } = usePermissions();

  const editable = useMemo(
    () => checkIsEditableOKR(hasCorpLevel, organization?.id, canUpdate, canCreate),
    [canUpdate, canCreate, hasCorpLevel, organization?.id],
  );

  const { getNumberOfTextRowsToDisplayOnGridCell } = useNumberOfTextRowsToDisplayOnGridCell(rowHeight);

  const canObjectiveMetadataRoadmapBeUpdated = useMemo(
    () => makeCanObjectiveMetadataRoadmapBeUpdated(hasCorpLevel, organization?.id, true, canUpdate),
    [hasCorpLevel, organization?.id, canUpdate],
  );

  const multilineTextCommonDefs = useMemo(
    () => ({
      editable,
      suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
      cellRenderer: WYSIWYGCellRenderer,
      cellEditor: WYSIWYGCellEditor,
      cellEditorParams: {
        maxHeight: 150,
        autoFocus: true,
      },
      width: 450,
      minWidth: 200,
      comparator: stringComparator,
      cellEditorPopup: true,
    }),
    [editable],
  );

  const dragColumnDef = useMemo(() => getDragColumnDef({ checkRowDrag, disableDrag }), [checkRowDrag, disableDrag]);

  const ideasProgressColumnDef = useMemo(
    () => ({
      editable,
      field: 'ideasProgress',
      headerName: `${displayLayerLabel} Progress`,
      width: 120,
      minWidth: 100,
      cellRenderer: ProgressCellRenderer,
      cellEditor: ProgressCellEditor,
    }),
    [editable, displayLayerLabel],
  );

  const progressColumnDef = useMemo(
    () => ({
      editable,
      field: 'progress',
      headerName: `${metadataTitle} Progress`,
      width: 120,
      minWidth: 100,
      cellRenderer: ProgressCellRenderer,
      cellEditor: ProgressCellEditor,
      exportFn: exportValueAsPercentage,
    }),
    [editable, metadataTitle],
  );

  const summaryColumnDef = useMemo(
    () => ({
      ...multilineTextCommonDefs,
      field: 'summary',
      headerName: 'Summary',
      cellClass: 'align-items-top',
      exportFn: exportMultiLineText,
    }),
    [multilineTextCommonDefs],
  );

  const okrHealthColumnDef = useMemo(
    () => ({
      ...OkrHealthColumnDef,
      editable,
      exportFn: exportHealth,
    }),
    [editable],
  );

  const linksColumnDef = useMemo(
    () => ({
      ...multilineTextCommonDefs,
      field: 'links',
      headerName: 'Links',
      minWidth: 150,
      cellClass: 'align-items-top',
      exportFn: exportMultiLineText,
    }),
    [multilineTextCommonDefs],
  );

  const descriptionColumnDef = useMemo(
    () => ({
      ...multilineTextCommonDefs,
      field: 'description',
      headerName: 'Description',
      cellClass: 'align-items-top',
      exportFn: exportMultiLineText,
    }),
    [multilineTextCommonDefs],
  );

  const ownerColumnDef = useMemo(
    () => ({
      ...getOwnerColumnDef({ editable, users }),
      cellRendererParams: {
        getNumberOfTextRowsToDisplayOnGridCell,
      },
      exportFn: defaultExport,
      minWidth: 100,
      enableRowGroup: false,
    }),
    [editable, users, getNumberOfTextRowsToDisplayOnGridCell],
  );

  const metricsColumnDef = useMemo(
    () => ({
      ...getMetricsColumnDef({
        editable,
        metrics,
        hasMultipleMetrics,
        createMetricOption,
      }),
      minWidth: 200,
      maxHeight: 150,
      cellRendererParams: {
        expandRenderer: hasAdvancedMetricReporting && not(hasMultipleMetrics),
        onClickLink: entity => openMetricView((entity?.metrics || [])[0]),
        getNumberOfTextRowsToDisplayOnGridCell,
        openLinkIcon: <InsightsIcon />,
        onChipClick: metric => hasMetricCharts && openMetricView(metric),
      },
      exportFn: value => exportMultiSelectMetadata(value, 'name'),
    }),
    [editable, metrics, hasAdvancedMetricReporting, getNumberOfTextRowsToDisplayOnGridCell],
  );

  const okrActualValueColumnDef = useMemo(
    () => ({
      ...numericColumnDef,
      editable,
      field: 'metric_current',
      headerName: 'Actual Value',
      minWidth: 80,
      maxHeight: 150,
      headerClass: '',
    }),
    [editable],
  );

  const okrTargetValueColumnDef = useMemo(
    () => ({
      ...numericColumnDef,
      editable,
      field: 'metric_target',
      headerName: 'Target Value',
      minWidth: 80,
      maxHeight: 150,
      headerClass: '',
    }),
    [editable],
  );

  const okrColorColumnDef = useMemo(
    () => ({
      ...colorColumnDef,
      editable,
      field: 'color',
      headerName: 'Display Color',
      width: 100,
      minWidth: 100,
      exportFn: defaultExport,
    }),
    [editable],
  );

  const headerName = useMemo(() => {
    if (isTreeView) {
      const okrNames = [];

      if (selectedField === CORP_OBJECTIVE_KEY) {
        okrNames.push(getSystemFieldName(CORP_OBJECTIVE_KEY));
      }

      if (objectiveEntityKeys.includes(selectedField)) {
        okrNames.push(getSystemFieldName(OBJECTIVE_KEY));
      }

      if (hasKeyResults && selectedField !== KEY_RESULT_2_KEY) {
        okrNames.push(getSystemFieldName(KEY_RESULT_1_KEY));
      }

      if (hasKeyResults2) {
        okrNames.push(getSystemFieldName(KEY_RESULT_2_KEY));
      }

      return okrNames.join(' > ');
    }

    return metadataTitle;
  }, [getSystemFieldName, isTreeView, selectedField, hasKeyResults, hasKeyResults2]);

  const titleColumnDef = useMemo(
    () => ({
      ...getTitleColumnDef(metadataTitle, 'title'),
      cellRenderer: TitleCellRenderer,
      cellRendererParams: {
        selectedField,
        getNumberOfTextRowsToDisplayOnGridCell,
        checkAddVisibility: addVisibility,
        handleAddOKRWithoutSave: handleAdd,
        openObjectiveDrawer: handleOpenItem,
        openProjectsLightbox: handleOpenProjectsLightbox,
        showActions: checkShouldShowActions,
      },
      editable,
      exportFn: defaultExport,
      minWidth: 250,
      cellClass: 'title-cell ag-title-cell',
      width: GROUP_COLUMN_INITIAL_WIDTH,
    }),
    [editable, selectedField, getNumberOfTextRowsToDisplayOnGridCell],
  );

  const groupColumnDef = useMemo(
    () => ({
      headerName,
      field: 'title',
      suppressMovable: true,
      cellClass: 'ag-react-editable-container--expandable ag-title-cell',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: GroupHeaderInnerRenderer,
        suppressCount: true,
        searchString,
        suppressDoubleClickExpand: true,
        selectedField,
        getNumberOfTextRowsToDisplayOnGridCell,
        checkAddVisibility: addVisibility,
        handleAddOKRWithoutSave: handleAdd,
        openObjectiveDrawer: handleOpenItem,
        openProjectsLightbox: handleOpenProjectsLightbox,
        showActions: checkShouldShowActions,
      },
      rowDrag: checkRowDrag,
      exportFn: defaultExport,
      editable,
      minWidth: GROUP_COLUMN_INITIAL_WIDTH,
      comparator: stringComparator,
    }),
    [headerName, selectedField, checkRowDrag, editable, searchString, getNumberOfTextRowsToDisplayOnGridCell],
  );
  const outcomeProgressColumnDef = useMemo(
    () => ({
      width: 120,
      minWidth: 100,
      editable,
      field: 'progress',
      headerName: 'Goal Progress',
      cellRenderer: ProgressCellRenderer,
      cellEditor: ProgressCellEditor,
      exportFn: value => exportValueAsPercentage(value, true),
    }),
    [editable],
  );

  const metadataRoadmapColumnDef = useMemo(
    () =>
      getMetadataRoadmapColumnDef({
        headerName: 'Applies To',
        roadmapsMetadata: [...roadmapsMetadata, hideMetadataRoadmaps],
        field: hasCorpLevel ? ROADMAP_OBJECTIVE_FIELD_KEY : ROADMAP_FIELD_KEY,
        editable: canObjectiveMetadataRoadmapBeUpdated,
        idKey: 'uniqueId',
        valueGetter: data => [
          ...(path(['data', ROADMAP_FIELD_KEY], data) ?? path(['data', ROADMAP_OBJECTIVE_FIELD_KEY], data) ?? []),
        ],
        exportFn: exportMetadataRoadmap,
      }),
    [hasCorpLevel, roadmapsMetadata, hideMetadataRoadmaps],
  );

  const targetAllocationAmountColumnDef = useMemo(
    () => ({
      ...TargetAllocationAmountColumnDef,
      exportFn: defaultExport,
      editable,
      minWidth: 200,
    }),
    [editable],
  );

  const targetAllocationPercentageColumnDef = useMemo(
    () => ({
      ...TargetAllocationPercentageColumnDef,
      exportFn: exportValueAsPercentage,
      editable,
      minWidth: 200,
    }),
    [editable],
  );

  const dateColumns = [
    { ...dateColumnDef, editable, headerName: 'Start Date', field: 'start_date', width: 110, exportFn: exportDate },
    { ...dateColumnDef, editable, headerName: 'End Date', field: 'end_date', width: 110, exportFn: exportDate },
  ];

  const columnDefs = useMemo(() => {
    const alwaysVisibleColumns = isTreeView ? [groupColumnDef] : [dragColumnDef, titleColumnDef];

    const otherColumns = [
      progressColumnDef,
      outcomeProgressColumnDef,
      ideasProgressColumnDef,
      summaryColumnDef,
      metricsColumnDef,
      ...(not(hasMultipleMetrics) ? [okrActualValueColumnDef, okrTargetValueColumnDef] : []),
      ownerColumnDef,
      metadataRoadmapColumnDef,
      okrHealthColumnDef,
      descriptionColumnDef,
      linksColumnDef,
      ...dateColumns,
      okrColorColumnDef,
      targetAllocationAmountColumnDef,
      targetAllocationPercentageColumnDef,
    ].filter(columnDef => visibleColumns.includes(columnDef.headerName));

    return [...alwaysVisibleColumns, ...otherColumns];
  }, [
    isTreeView,
    visibleColumns,
    groupColumnDef,
    titleColumnDef,
    ideasProgressColumnDef,
    progressColumnDef,
    summaryColumnDef,
    okrHealthColumnDef,
    linksColumnDef,
    descriptionColumnDef,
    ownerColumnDef,
    metricsColumnDef,
    okrActualValueColumnDef,
    okrTargetValueColumnDef,
    okrColorColumnDef,
    targetAllocationAmountColumnDef,
    targetAllocationPercentageColumnDef,
    hasMultipleMetrics,
  ]);

  const defaultColDef = useMemo(
    () => ({
      ...defaultColumnDefCommonProps,
      editable,
      cellClass: params =>
        params.colDef.autoHeight ? 'ag-react-container--expandable' : 'ag-react-editable-container--expandable',
      sortable: true,
      resizable: true,
      filter: true,
    }),
    [editable],
  );

  return {
    columnDefs,
    defaultColDef,
  };
};

export default useOkrTableGridColumns;
