import { TRANSACTION_PAGES_PROJECTS_LIMIT } from 'constants';
import useLoadProjects from './useLoadProjects';

const useLoadProjectsForTransactionPages = (pageId, projectsAssociations, forceAssociations, additionalFilters = {}) => {
  return useLoadProjects(
    pageId,
    projectsAssociations,
    forceAssociations,
    additionalFilters,
    {
      from: 0,
      to: TRANSACTION_PAGES_PROJECTS_LIMIT,
    },
    false,
  );
};

export default useLoadProjectsForTransactionPages;
