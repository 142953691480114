import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getOrganization } from 'store/organization';
import { getProjectsCustomFields } from 'store/customFields/selectors';

import getFields from 'containers/AdvancedSearchPopover/helpers/getFields';

import usePageFilters from 'hooks/filters/usePageFilters';
import useCustomUserFields from 'hooks/useCustomUserFields';
import useSystemFields from 'hooks/useSystemFields';

import { SCENARIO_FILTER } from 'constants/filters/pages';
import { FILTERS_FIELDS } from 'constants/filters/fields';

import { TRANSACTION_PAGES_PROJECTS_LIMIT } from 'constants';
import { getProjectsTotal } from 'store/filters/selectors';
import { isLoadingProjects } from 'store/projects';

const useScenarioFilter = () => {
  const [getSystemFieldName] = useSystemFields();
  const { pageFilters, displayLayer } = usePageFilters(SCENARIO_FILTER);
  const storeTotal = useSelector(getProjectsTotal);
  const totalByLayer = useSelector(state => state.projects.totalByLayer);
  const isLoading = useSelector(isLoadingProjects);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!isLoading) setTotal(storeTotal);
  }, [storeTotal, isLoading]);

  const totalCountForDisplayLayer = totalByLayer[displayLayer];

  const shouldDisplayTotalCount =
    !!total && totalCountForDisplayLayer && totalCountForDisplayLayer > total && total >= TRANSACTION_PAGES_PROJECTS_LIMIT;

  const { customFields, hasKeyResults, hasKeyResults2, hasProducts, hasMultiLevelPortfolioMetadata, hasTeams2 } = useSelector(
    state => ({
      customFields: getProjectsCustomFields(state, true),
      hasKeyResults: getOrganization(state).has_key_results,
      hasKeyResults2: getOrganization(state).has_key_results2,
      hasProducts: getOrganization(state).has_products,
      hasMultiLevelPortfolioMetadata: getOrganization(state).has_multi_level_portfolio_metadata,
      hasTeams2: getOrganization(state).has_teams_2,
    }),
  );

  const { getCustomUserFieldByFieldKey } = useCustomUserFields();

  const fields = useMemo(
    () =>
      getFields(
        getSystemFieldName,
        FILTERS_FIELDS,
        true,
        customFields,
        hasKeyResults,
        hasKeyResults2,
        hasProducts,
        hasMultiLevelPortfolioMetadata,
        hasTeams2,
        false,
        { getCustomUserFieldByFieldKey },
      ),
    [
      FILTERS_FIELDS,
      customFields,
      hasKeyResults,
      hasProducts,
      hasMultiLevelPortfolioMetadata,
      hasTeams2,
      getCustomUserFieldByFieldKey,
    ],
  );

  return {
    pageFilters,
    fields,
    total,
    shouldDisplayTotalCount,
    totalCountForDisplayLayer,
  };
};

export default useScenarioFilter;
