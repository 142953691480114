// External dependencies
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { ADMIN_USER, LEADER_USER, OWNER_USER, PERMISSION_RESOURCES } from '@dragonboat/permissions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// Dragonboat dependencies
import NewDialog from 'design-system/organisms/NewDialog/index';
import Tabs from 'design-system/organisms/Tabs/index';
import theme, { spacing } from 'design-system/theme';

import Details from './Details';
import SharePublicLink from './SharePublicLink';
import ShareWithUsers from './ShareWithUsers';
import Header from './Header';

import { getUsers } from 'store/users/selectors';
import { updateUserViewOwner } from 'store/userViews/actions';
import { updateOwnerForAllUserViews } from 'store/userViews/sharedViews.thunks';
import { getCurrentUser } from 'store/login/selectors';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

// eslint-disable-next-line max-len
import UserViewRecurringNotifications from 'features/UserViewRecurringNotification/components/UserViewRecurringNotifications/UserViewRecurringNotifications';
// eslint-disable-next-line max-len
import { CollaborationIntegrationChannelsProvider } from 'features/UserViewRecurringNotification/components/UserViewRecurringNotifications/useCollaborationIntegrationChannelsContext';

const selectedTabBorderStyles = {
  background: theme.palette.primary.main,
  height: '2px',
};

const isAdminOrEditorUser = roleId => [ADMIN_USER, LEADER_USER, OWNER_USER].includes(roleId);
const isAdminUser = roleId => [LEADER_USER, OWNER_USER].includes(roleId);
const getUsersForDropdown = users =>
  users.reduce((userOptions, user) => {
    if (isAdminOrEditorUser(user.role_id)) {
      const option = { ...user, value: user.id, label: user.name };

      return [...userOptions, option];
    }

    return userOptions;
  }, []);

const ViewDialog = ({
  show,
  onClose,
  onDelete,
  onSave,
  selectedTab,
  handleTabChange,
  tabs,
  isDetailsTab,
  isShareWithUsersTab,
  isSharePublicLinkTab,
  isRecurringNotificationTab,
  onUpdate,
  shareUrl,
  userViews,
  view,
  handleOnPreview,
  handleOnShare,
  shareOption,
  setShareOption,
  editorState,
  setEditorState,
  viewName,
  setViewName,
  viewDescription,
  setViewDescription,
  isSaveDisabled,
  isPreviewDisabled,
  disabledTab,
  userCanEditView,
}) => {
  const dispatch = useDispatch();

  const users = useSelector(getUsers);
  const currentUser = useSelector(getCurrentUser);

  const userCanUpdateOwner = isAdminUser(currentUser?.role_id) || currentUser?.id === view?.user_id;

  const { canView, canDelete } = usePermissions();

  const showDeleteButton = view && onDelete && canDelete(PERMISSION_RESOURCES.userView, { view });

  const canViewUserViewCounts = canView(PERMISSION_FEATURES.userViewCounts);

  useEffect(() => {
    setViewName(view?.name || '');
    setViewDescription(view?.description || '');
  }, [view, show]);

  const renderDetails = (
    <Details
      show={show}
      onClose={onClose}
      onUpdate={onUpdate}
      view={view}
      userViews={userViews}
      ownerUserOptions={getUsersForDropdown(users)}
      viewName={viewName}
      setViewName={setViewName}
      viewDescription={viewDescription}
      setViewDescription={setViewDescription}
      onUpdateViewOwner={(viewId, userId) => dispatch(updateUserViewOwner(viewId, userId))}
      onUpdateViewOwnerAllViews={(userId, newUserId) => dispatch(updateOwnerForAllUserViews(userId, newUserId))}
      showUpdateOwner={userCanUpdateOwner}
      disableEditDetails={!userCanEditView}
    />
  );

  const renderShareWithUsers = (
    <ShareWithUsers
      show={show}
      onClose={onClose}
      view={view}
      shareUrl={shareUrl}
      shareOption={shareOption}
      setShareOption={setShareOption}
      editorState={editorState}
      setEditorState={setEditorState}
    />
  );

  const renderSharePublicLink = <SharePublicLink view={view} shareUrl={shareUrl} onClose={onClose} />;

  const renderUserViewRecurringNotifications = <UserViewRecurringNotifications view={view} shareUrl={shareUrl} />;

  const cancelButton = (
    <Button
      data-test="cancel-view"
      onClick={() => {
        onClose();
        setViewName('');
        setViewDescription('');
      }}
    >
      Cancel
    </Button>
  );
  const renderDetailsActionButtons = (
    <RightActionButtons item xs={9}>
      <Button
        data-test="save-view"
        onClick={() => {
          onSave(viewName, viewDescription);
          setViewName('');
          setViewDescription('');
          onClose();
        }}
        disabled={isSaveDisabled}
        color="primary"
      >
        Save
      </Button>
      {cancelButton}
    </RightActionButtons>
  );

  const renderShareActionButtons = (
    <RightActionButtons item xs={9}>
      <Button data-test="preview-view" onClick={handleOnPreview} disabled={isPreviewDisabled} color="primary">
        Preview
      </Button>
      <Button data-test="share-view" onClick={handleOnShare} color="primary">
        Share
      </Button>
      {cancelButton}
    </RightActionButtons>
  );

  const renderRecurringNotificationButtons = (
    <RightActionButtons item xs={9}>
      {cancelButton}
    </RightActionButtons>
  );

  const ActionButtonsRenderer = () => (
    <Fragment>
      <Grid container>
        <Grid item xs={3}>
          {showDeleteButton && (
            <RedButton data-test="delete-view" onClick={() => onDelete(view.id)} color="secondary">
              Delete
            </RedButton>
          )}
        </Grid>
        {isDetailsTab ? renderDetailsActionButtons : null}
        {isRecurringNotificationTab ? renderRecurringNotificationButtons : null}
        {!isDetailsTab && !isRecurringNotificationTab ? renderShareActionButtons : null}
      </Grid>
    </Fragment>
  );

  return (
    <NewDialog
      id="view-dialog"
      data-test="view-dialog"
      open={show}
      onClose={onClose}
      maxWidth="md"
      scroll="paper"
      closeButton
      actions={ActionButtonsRenderer()}
      header={
        <Header
          view={view}
          viewName={viewName}
          setViewName={setViewName}
          disabled={!userCanEditView}
          showCounter={canViewUserViewCounts}
        />
      }
      fullWidth
      paddingOverride={`${spacing(1)}px ${spacing(3)}px ${spacing(3)}px`}
    >
      <CollaborationIntegrationChannelsProvider>
        <DialogWrapper>
          <StyledTabs
            tabs={tabs}
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
            tabStyles={tabStyles}
            checkIfTabIsDisabled={disabledTab}
            TabIndicatorProps={{ style: selectedTabBorderStyles }}
          />
          {isDetailsTab && renderDetails}
          {isShareWithUsersTab && renderShareWithUsers}
          {isSharePublicLinkTab && renderSharePublicLink}
          {isRecurringNotificationTab && renderUserViewRecurringNotifications}
        </DialogWrapper>
      </CollaborationIntegrationChannelsProvider>
    </NewDialog>
  );
};

const StyledTabs = styled(Tabs)`
  && {
    min-height: unset;
    margin-bottom: ${spacing(3)}px;
  }
`;

const DialogWrapper = styled.div``;

const RedButton = styled(Button)`
  &&&& {
    position: absolute;
    left: ${spacing(2.5)}px;
  }
`;

const RightActionButtons = styled(Grid)`
  display: flex;
  justify-content: flex-end;
`;

const tabStyles = css`
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  min-height: ${spacing(4)}px;

  span {
    padding: 0 0 ${spacing(0.25)}px 0;
  }
`;

export default ViewDialog;
