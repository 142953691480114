import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createTask, deleteTask, updateTask } from 'store/tasks';

export default () => {
  const dispatch = useDispatch();
  const lsState = useSelector(state => state.staffing);
  const lsStateRef = useRef(lsState);

  lsStateRef.current = lsState;

  const _updateTask = (id, action, task, ignoreDraftMode = false) => {
    if (lsStateRef.current.localMode && !ignoreDraftMode) return;

    task.owner_id = task.owner_id === 'N/A' ? null : task.owner_id;
    if (task.owner_id !== null) {
      task.ownerName = task.owner_id || task.owner || task.ownerSelected ? task.ownerSelected : '';
    } else {
      task.ownerName = '';
    }

    task.project_id = +task.project_id;
    task.original_project_id = +task.original_project_id;
    task.duration = +task.duration;

    if (action === 'inserted') {
      dispatch(createTask(task));
    } else if (action === 'updated') {
      dispatch(updateTask(task));
    } else if (action === 'deleted') {
      dispatch(deleteTask(task.id));
    }
  };

  return [_updateTask];
};
