import Axios from 'axios';
import { toast } from 'react-toastify';
import React from 'react';
import pick from 'lodash/pick';

import {
  FETCH_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ,
  FETCH_NEXT_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  RECEIVE_REALTIME_NOTIFICATION,
  MARK_NOTIFICATION_AS_ARCHIVED,
  MARK_ALL_NOTIFICATIONS_AS_ARCHIVED,
} from './types';

import JiraUpdateErrorItem from 'containers/Header/NotificationsList/Components/JiraUpdateErrorItem';

export const fetchNotifications = () => {
  return dispatch => {
    const payload = Axios.get('/api/notifications').then(res => res.data);

    dispatch({
      type: FETCH_NOTIFICATIONS,
      payload,
    });

    return payload;
  };
};

export const fetchNextNotifications = () => {
  return (dispatch, getState) => {
    let payload;

    const notificationsState = getState().notifications;

    if (notificationsState.isFetching) return;

    if (notificationsState && notificationsState._meta) {
      payload = Axios.get(notificationsState._meta.next).then(res => res.data);
    } else {
      payload = Axios.get('/api/notifications').then(res => res.data);
    }

    dispatch({
      type: FETCH_NEXT_NOTIFICATIONS,
      payload,
    });

    return payload;
  };
};

export const markNotificationAsRead = notificationId => {
  return dispatch => {
    const payload = Axios.put(`/api/notifications/${notificationId}`).then(res => res.data);

    dispatch({
      type: MARK_NOTIFICATION_AS_READ,
      payload,
    });

    return payload;
  };
};

export const markAllNotificationsAsRead = () => {
  return dispatch => {
    const payload = Axios.put('/api/notifications').then(res => res.data);

    dispatch({
      type: MARK_ALL_NOTIFICATIONS_AS_READ,
      payload,
    });

    return payload;
  };
};

export const markNotificationAsArchived = notificationId => {
  return dispatch => {
    const payload = Axios.put(`/api/notifications/${notificationId}`, { archived: true }).then(res => res.data);

    dispatch({
      type: MARK_NOTIFICATION_AS_ARCHIVED,
      payload,
    });

    return payload;
  };
};

export const markAllNotificationsAsArchived = () => {
  return dispatch => {
    const payload = Axios.put('/api/notifications', { archived: true }).then(res => res.data);

    dispatch({
      type: MARK_ALL_NOTIFICATIONS_AS_ARCHIVED,
      payload,
    });

    return payload;
  };
};

export const receiveRealtimeNotification = payload => {
  switch (payload.notification_type) {
    case 'JIRA_UPDATE_ERROR':
      toast(<JiraUpdateErrorItem {...pick(payload.notification_payload, ['project', 'field'])} />);
      break;
    case 'JIRA_PROJECT_RENAMED_WARNING':
      toast(<JiraUpdateErrorItem {...pick(payload.notification_payload, ['project', 'field'])} />);
      break;
    default:
      break;
  }

  return dispatch => {
    dispatch({
      type: RECEIVE_REALTIME_NOTIFICATION,
      payload,
    });

    return payload;
  };
};
