import moment from 'moment';

/**
 * Default time scale config with tooltip
 */
export default ({ dateInterval }) => ({
  type: 'time',
  min: moment(dateInterval?.startDate)?.toISOString(),
  max: moment(dateInterval?.endDate)?.toISOString(),
  time: {
    round: dateInterval?.unit,
    unit: dateInterval?.unit,
    displayFormats: {
      month: 'YYYY-MMM',
    },
    tooltipFormat: 'YYYY-MMM',
  },
});
