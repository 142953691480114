import React from 'react';
import styled from 'styled-components';

import ButtonV2 from 'design-system/atoms/ButtonV2/index';

import theme from 'design-system/theme';
import UpsellPageTemplate from 'design-system/templates/UpsellPageTemplate';

import NotFoundImage from 'components/PermissionsDialog/components/permissions_dialog.svg';
import { Container, SideSection, Title, StyledText, ButtonWrapper } from './styledComponents';

const LEARN_MORE_BUTTON_ID = 'learnmore';

const UpsellPage = ({ location }) => {
  const previousUrl = location?.state?.from?.pathname;

  return (
    <UpsellPageTemplate>
      <Container>
        <SideSection>
          <Image src={NotFoundImage} alt="not-found" />
        </SideSection>
        <SideSection withPaddingTop>
          <Title>
            This page{previousUrl ? <span> ({previousUrl})</span> : null} does not exist, or you don’t have access to the
            requested feature.
          </Title>
          <StyledText>Please contact your success manager to upgrade or activate the feature.</StyledText>
          <ButtonWrapper>
            <ButtonV2
              id={LEARN_MORE_BUTTON_ID}
              color={theme.palette.background.white}
              background={theme.palette.background.primary}
              hover={{ background: theme.palette.background.primary }}
            >
              Learn more
            </ButtonV2>
          </ButtonWrapper>
        </SideSection>
      </Container>
    </UpsellPageTemplate>
  );
};

export default UpsellPage;

const Image = styled.img`
  max-height: 375px;
`;
