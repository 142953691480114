import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

import { getOrganization } from 'store/organization/selectors';
import { getCurrentUser } from 'store/login/selectors';

import useIntegrations from 'hooks/useIntegrations';
import useUserIntegrations from 'hooks/useUserIntegrations';
import useAppNotifications from 'hooks/useAppNotifications';

import CreateOrgIntegration from './CreateOrgIntegration';
import CreateUserIntegration from './CreateUserIntegration';

const IntegrationSteps = props => {
  const { orgIntegration, userIntegration, addingOrgIntegration } = props;

  const organization = useSelector(getOrganization);
  const currentUser = useSelector(state => getCurrentUser(state));

  const { addOrgIntegration } = useIntegrations(INTEGRATIONS_KEYS.zendesk);
  const { addUserIntegrationError, addUserIntegration } = useUserIntegrations(INTEGRATIONS_KEYS.zendesk, orgIntegration?.id);
  const { addNotification } = useAppNotifications();

  const handleCreateOrganization = useCallback(
    async uri => {
      addOrgIntegration({ uri });
    },
    [addOrgIntegration],
  );

  const handleAddUserIntegration = useCallback(
    async (token, username) => {
      await addUserIntegration(token, { username }).then(() => {
        addNotification({ id: 'zendesk-successfully-integrated', message: 'Successfully integrated' });
      });
    },
    [addUserIntegration, organization.id, currentUser.id, orgIntegration],
  );

  const shouldShowOrganizationIntegrationStep = !orgIntegration && addingOrgIntegration;
  const shouldShowUserIntegrationStep = orgIntegration && !userIntegration;

  return (
    <Wrapper>
      {shouldShowOrganizationIntegrationStep && <CreateOrgIntegration onClickNext={handleCreateOrganization} />}
      {shouldShowUserIntegrationStep && (
        <CreateUserIntegration
          addUserIntegrationError={addUserIntegrationError}
          uri={orgIntegration.data.uri}
          onClickConnect={handleAddUserIntegration}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &&&& {
    margin-left: 16px;
  }
`;

export default IntegrationSteps;
