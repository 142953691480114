import { not, prop } from 'ramda';

const ID = 'id';
const PARENT_ID = 'parent_id';

const getId = prop(ID);
const getParentId = prop(PARENT_ID);

/**
 * @function filterChildrenObjectives
 *
 * Filter the Level Corp objectives from baby accounts AND the Level 1 objects from
 * baby accounts under Level Corp objectives from baby.
 *
 * @param {Array} objectives
 * @param {Array} childrenObjectivesIds
 * @returns {Array}
 */
const filterChildrenObjectives = (objectives, childrenObjectivesIds) => {
  const isNotChildrenObjective = id => not(childrenObjectivesIds.includes(id));

  return objectives.filter(
    objective => isNotChildrenObjective(getId(objective)) && isNotChildrenObjective(getParentId(objective)),
  );
};

export default filterChildrenObjectives;
