import React from 'react';

import { isOfObjectRoadmapType } from '../../helpers';

import { Row, ActionButton, LockIcon } from './styled';

const OwnerCellWrapper = ({ children, roadmap, entityPermissionsEnabled, openRoadmapLightbox }) => {
  const handleOpenRoadmapLightbox = () => openRoadmapLightbox(roadmap.id);

  return (
    <Row>
      {children}
      {roadmap && isOfObjectRoadmapType(roadmap) && entityPermissionsEnabled && (
        <ActionButton onClick={handleOpenRoadmapLightbox} color="default" size="small" hover>
          <LockIcon hasUserRoles={roadmap.has_user_roles} fontSize="small" />
        </ActionButton>
      )}
    </Row>
  );
};

export default OwnerCellWrapper;
