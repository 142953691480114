import axios from 'axios';

export const loadProjectsByEntity = (entityId, entityType, params, showCompleted = false) =>
  axios.get(`/api/projects/${entityType}/${entityId}/list`, {
    params: {
      ...params,
      showCompleted,
    },
  });

export const addMetricToProject = (projectId, metricId) => axios.post(`/api/projects/${projectId}/metrics/${metricId}`);

export const removeMetricFromProject = (projectId, metricId) => axios.delete(`/api/projects/${projectId}/metrics/${metricId}`);
