import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reject, equals, append } from 'ramda';

import { getLeftNavigationExpandedItems, setLeftNavigationExpandedItems } from 'store/app';

/**
 * @function useLeftNavigationExpandedItems
 *
 * use left navigation expanded items from the store
 *
 * @return {Object}
 */
const useLeftNavigationExpandedItems = () => {
  const dispatch = useDispatch();

  const leftNavigationExpandedItems = useSelector(getLeftNavigationExpandedItems);

  const handleToggleExpandLefNavItem = useCallback(
    item => {
      const isItemExpanded = leftNavigationExpandedItems.includes(item);
      const updatedLeftNavigationExpandedItems = isItemExpanded
        ? reject(equals(item), leftNavigationExpandedItems)
        : append(item, leftNavigationExpandedItems);

      dispatch(setLeftNavigationExpandedItems(updatedLeftNavigationExpandedItems));
    },
    [setLeftNavigationExpandedItems, leftNavigationExpandedItems, dispatch],
  );

  const isItemExpanded = useCallback(
    item => {
      return leftNavigationExpandedItems.includes(item);
    },
    [leftNavigationExpandedItems],
  );

  return {
    leftNavigationExpandedItems,
    isItemExpanded,
    handleToggleExpandLefNavItem,
  };
};

export default useLeftNavigationExpandedItems;
