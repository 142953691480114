export const TAB_DETAILS = {
  id: 'details',
  label: 'Details',
};

export const TAB_ESTIMATES = {
  id: 'estimates',
  label: 'Estimates',
};

export const TAB_MERGE = {
  id: 'merge',
  label: 'Merge Requests',
};

export const TAB_HISTORY = {
  id: 'history',
  label: 'History',
};

export const REQUESTS_DRAWER_TABS = {
  DETAILS: TAB_DETAILS.id,
  ESTIMATES: TAB_ESTIMATES.id,
  MERGE: TAB_MERGE.id,
  HISTORY: TAB_HISTORY.id,
};
