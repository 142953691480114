import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Rename from 'routes/Settings/Base/Components/Rename/Rename';

const RoadmapItems = () => {
  const hasBets = useSelector(state => state.organization.organization.has_bet);
  const hasInitiatives = useSelector(state => state.organization.organization.has_hierarchy);

  const rows = [hasBets ? 'bet' : null, hasInitiatives ? 'initiative' : null, 'idea'].filter(Boolean);

  return (
    <Wrapper>
      <Rename rows={rows} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &&&& {
    padding: 52px 32px 32px;
  }
`;

export default RoadmapItems;
