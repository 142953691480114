import reduceReducers from 'reduce-reducers';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  // Project => Task Dependencies:
  ADD_PROJECT_TASK_DEPENDENCY,
  REMOVE_PROJECT_TASK_DEPENDENCY,

  // Project => Estimate Dependencies:
  ADD_PROJECT_ESTIMATE_DEPENDENCY,
  REMOVE_PROJECT_ESTIMATE_DEPENDENCY,

  // Project => Project Dependencies:
  ADD_PROJECT_PROJECT_DEPENDENCY,
  REMOVE_PROJECT_PROJECT_DEPENDENCY,

  // Estimate => Estimate Dependencies:
  ADD_ESTIMATE_ESTIMATE_DEPENDENCY,
  REMOVE_ESTIMATE_ESTIMATE_DEPENDENCY,

  // Estimate => Project Dependencies:
  ADD_ESTIMATE_PROJECT_DEPENDENCY,
  REMOVE_ESTIMATE_PROJECT_DEPENDENCY,

  // Task => Task Dependencies:
  ADD_TASK_TASK_DEPENDENCY,
  REMOVE_TASK_TASK_DEPENDENCY,

  // Task => Project Dependencies:
  ADD_TASK_PROJECT_DEPENDENCY,
  REMOVE_TASK_PROJECT_DEPENDENCY,

  // Other:
  FETCH_PROJECT_DEPENDENCIES,
  SET_PROJECTS_DEPENDENCIES,
  SYNC_INTEGRATION_PROJECT_DEPENDENCIES,
} from '../types';

const { initialState, reducers } = getThunksInitialStateAndReducers([
  // Project => Task Dependencies:
  ADD_PROJECT_TASK_DEPENDENCY,
  REMOVE_PROJECT_TASK_DEPENDENCY,

  // Project => Estimate Dependencies:
  ADD_PROJECT_ESTIMATE_DEPENDENCY,
  REMOVE_PROJECT_ESTIMATE_DEPENDENCY,

  // Project => Project Dependencies:
  ADD_PROJECT_PROJECT_DEPENDENCY,
  REMOVE_PROJECT_PROJECT_DEPENDENCY,

  // Estimate => Estimate Dependencies:
  ADD_ESTIMATE_ESTIMATE_DEPENDENCY,
  REMOVE_ESTIMATE_ESTIMATE_DEPENDENCY,

  // Estimate => Project Dependencies:
  ADD_ESTIMATE_PROJECT_DEPENDENCY,
  REMOVE_ESTIMATE_PROJECT_DEPENDENCY,

  // Task => Task Dependencies:
  ADD_TASK_TASK_DEPENDENCY,
  REMOVE_TASK_TASK_DEPENDENCY,

  // Task => Project Dependencies:
  ADD_TASK_PROJECT_DEPENDENCY,
  REMOVE_TASK_PROJECT_DEPENDENCY,

  // Other:
  FETCH_PROJECT_DEPENDENCIES,
  SET_PROJECTS_DEPENDENCIES,
  SYNC_INTEGRATION_PROJECT_DEPENDENCIES,
]);

const reducer = reduceReducers(initialState, ...reducers);

export { initialState };

export default reducer;
