import reduceReducers from 'reduce-reducers';
import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import { groupBy, prop, omit } from 'ramda';

import {
  CREATE_ROADMAP_HISTORY_SNAPSHOT,
  FETCH_ROADMAP_HISTORY_PROJECTS_FULFILLED,
  SELECT_VIEW_SNAP_TO_COMPARE,
  SELECT_VIEW_SNAP_TO_COMPARE_FULFILLED,
  REMOVE_SELECTED_SNAP_TO_COMPARE,
  REMOVE_ALL_SELECTED_SNAP_TO_COMPARE,
  OPEN_VIEW_HISTORY_SNAPS_DIALOG,
  CLOSE_VIEW_HISTORY_SNAPS_DIALOG,
  FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW_FULFILLED,
  UPDATE_ROADMAP_HISTORY_SNAPSHOT_FULFILLED,
  FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW,
  UPDATE_ROADMAP_HISTORY_SNAPSHOT,
  UPDATE_ROADMAP_HISTORY_SNAPSHOT_REALTIME,
  CREATE_ROADMAP_HISTORY_SNAPSHOT_FULFILLED,
  DELETE_ROADMAP_HISTORY_SNAPSHOT,
  DELETE_ROADMAP_HISTORY_SNAPSHOT_FULFILLED,
  FETCH_ROADMAP_HISTORY_COMPARE_DATA,
  FETCH_ROADMAP_HISTORY_COMPARE_DATA_FULFILLED,
  FETCH_ROADMAP_HISTORY_COMPARE_DATA_PENDING,
  FETCH_ROADMAP_HISTORY_COMPARE_DATA_REJECTED,
  REMOVE_SELECTED_SNAP_TO_COMPARE_PENDING,
  SELECT_VIEW_SNAP_TO_COMPARE_PENDING,
  REMOVE_SELECTED_SNAP_TO_COMPARE_FULFILLED,
  REMOVE_SELECTED_SNAP_TO_COMPARE_REJECTED,
} from './types';
import { projectsAdapter, snapsAdapter, snapDiffsAdapter } from './helpers/adapters';
import updateRoadmapHistorySnapshotList from './helpers/updateRoadmapHistorySnapshotList';
import {
  selectAllDiffs,
  selectRoadmapHistorySnapsForCurrentView,
  selectSelectedSnapsToCompare,
} from 'features/RoadmapHistory/store/selectors';

const { initialState: operationsInitialState, reducers: operationsReducers } = getThunksInitialStateAndReducers([
  CREATE_ROADMAP_HISTORY_SNAPSHOT,
  SELECT_VIEW_SNAP_TO_COMPARE,
  REMOVE_SELECTED_SNAP_TO_COMPARE,
  FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW,
  UPDATE_ROADMAP_HISTORY_SNAPSHOT,
  DELETE_ROADMAP_HISTORY_SNAPSHOT,
  FETCH_ROADMAP_HISTORY_COMPARE_DATA,
]);

const initialState = {
  ...operationsInitialState,
  projects: projectsAdapter.getInitialState(),
  snapshots: snapsAdapter.getInitialState(),
  snapDiffs: snapDiffsAdapter.getInitialState(),
  viewSnapsDialog: {
    open: false,
    selectedUserViewId: null,
    selectedSnaps: [],
  },
  snapshotsListByUserView: {},
};

const sortByDate = (a, b) => new Date(b) - new Date(a);

const RoadmapHistoryReducer = (state = initialState, action) => {
  let snaps;
  let snapDiffData;

  switch (action.type) {
    case FETCH_ROADMAP_HISTORY_PROJECTS_FULFILLED:
      return {
        ...state,
        projects: projectsAdapter.setAll(state?.projects, action.payload?.data || []),
        currentViewId: action?.meta?.userViewId,
      };
    case REMOVE_SELECTED_SNAP_TO_COMPARE_FULFILLED:
    case SELECT_VIEW_SNAP_TO_COMPARE_FULFILLED:
    case FETCH_ROADMAP_HISTORY_COMPARE_DATA_FULFILLED:
      snaps = action.payload?.diffs?.map(({ srcSnapshot }) => srcSnapshot) || [];
      snapDiffData = action.payload?.diffs?.map(diff => omit(['srcSnapshot'], diff)) || [];

      return {
        ...state,
        projects: projectsAdapter.setAll(state?.projects, action.payload?.currentProjects || []),
        currentViewId: action?.meta?.userViewId,
        compareUuid: action.payload?.compareUuid,
        snapshots: snapsAdapter.setAll(state?.snapshots, snaps),
        snapDiffs: snapDiffsAdapter.setAll(state?.snapDiffs, snapDiffData),
      };
    case FETCH_ROADMAP_HISTORY_COMPARE_DATA_REJECTED:
      return {
        ...state,
        currentViewId: action?.meta?.userViewId,
      };
    case FETCH_ROADMAP_HISTORY_COMPARE_DATA_PENDING:
      return {
        ...state,
        snapshots: snapsAdapter.removeAll(state?.snapshots),
        snapDiffs: snapDiffsAdapter.removeAll(state?.snapDiffs),
        projects: projectsAdapter.removeAll(state?.projects),
        currentViewId: undefined,
        compareUuid: undefined,
      };
    case DELETE_ROADMAP_HISTORY_SNAPSHOT_FULFILLED:
      const { viewId } = action?.meta;
      const snapId = Number(action?.payload);
      const updatedSnaps = snapsAdapter.removeOne(state?.snapshots, +action.payload);

      return {
        ...state,
        snapshots: updatedSnaps,
        snapshotsListByUserView: {
          ...state.snapshotsListByUserView,
          [viewId]: state.snapshotsListByUserView?.[viewId]?.filter(snap => snap?.id !== snapId),
        },
      };
    case REMOVE_SELECTED_SNAP_TO_COMPARE_PENDING: {
      const { snapId } = action.meta;
      const allDiffs = selectAllDiffs({ roadmapHistory: state });
      const snapDiffs = allDiffs.filter(diff => diff?.src === snapId || diff?.target === snapId).map(diff => diff?.id);

      return {
        ...state,
        snapshots: snapsAdapter.removeOne(state?.snapshots, snapId),
        snapDiffs: snapDiffsAdapter.removeMany(state?.snapDiffs, snapDiffs),
      };
    }
    case REMOVE_SELECTED_SNAP_TO_COMPARE_REJECTED: {
      const selectedSnaps = selectSelectedSnapsToCompare({ roadmapHistory: state });

      // Clear list when there are no snaps selected
      if (selectedSnaps.length === 0) {
        return {
          ...state,
          projects: projectsAdapter.removeAll(state?.projects),
        };
      }

      return state;
    }
    case SELECT_VIEW_SNAP_TO_COMPARE_PENDING: {
      const { snapId } = action.meta;
      const allSnaps = selectRoadmapHistorySnapsForCurrentView({ roadmapHistory: state });
      const selectedSnap = allSnaps.find(({ id }) => id === snapId);

      return {
        ...state,
        snapshots: snapsAdapter.addOne(state?.snapshots, selectedSnap),
      };
    }
    case REMOVE_ALL_SELECTED_SNAP_TO_COMPARE:
      return {
        ...state,
        snapshots: snapsAdapter.removeAll(state?.snapshots),
        snapDiffs: snapDiffsAdapter.removeAll(state?.snapDiffs),
      };
    case OPEN_VIEW_HISTORY_SNAPS_DIALOG:
      const { selectedUserViewId } = action.payload;

      return {
        ...state,
        viewSnapsDialog: {
          open: true,
          selectedUserViewId,
        },
        // reset data before compare
        snapshots: snapsAdapter.removeAll(state?.snapshots),
        snapDiffs: snapDiffsAdapter.removeAll(state?.snapDiffs),
        projects: projectsAdapter.removeAll(state?.projects),
        currentViewId: undefined,
        compareUuid: undefined,
      };
    case CLOSE_VIEW_HISTORY_SNAPS_DIALOG:
      return {
        ...state,
        viewSnapsDialog: {
          open: false,
          selectedUserViewId: null,
        },
      };
    case FETCH_ROADMAP_HISTORY_SNAPSHOT_FOR_USER_VIEW_FULFILLED:
      return {
        ...state,
        snapshotsListByUserView: {
          ...state.snapshotsListByUserView,
          ...groupBy(
            prop('user_view_id'),
            action.payload?.sort((a, b) => sortByDate(a.created_at, b.created_at)),
          ),
        },
      };
    case UPDATE_ROADMAP_HISTORY_SNAPSHOT_FULFILLED:
      return updateRoadmapHistorySnapshotList(state, action.payload);

    case UPDATE_ROADMAP_HISTORY_SNAPSHOT_REALTIME: {
      return updateRoadmapHistorySnapshotList(state, action.payload);
    }
    case CREATE_ROADMAP_HISTORY_SNAPSHOT_FULFILLED: {
      return updateRoadmapHistorySnapshotList(state, action.payload);
    }
    default:
      return state;
  }
};

const reducer = reduceReducers(initialState, RoadmapHistoryReducer, ...operationsReducers);

export default reducer;
