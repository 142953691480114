import { concat, pathOr, pipe, propOr } from 'ramda';
import reduceReducers from 'reduce-reducers';
import getStore from 'store/store';

import upsertListItem from 'store/utils/upsertListItem';
import { UPDATE_KEY_RESULT_FROM_DRAWER_FULFILLED, UPDATE_OBJECTIVE_FROM_DRAWER_FULFILLED } from 'store/objectives';
import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import { buildObjectivesWithKeyResults, updateKeyResult } from 'store/objectives/helpers';

import { layoutOrDefaultForAllWidgets } from './helpers';
import {
  FETCH_PORTFOLIO_OVERVIEW_SUB_ROADMAPS,
  FETCH_PORTFOLIO_OVERVIEW_OKRS,
  FETCH_PORTFOLIO_OVERVIEW_METRICS,
  FETCH_PORTFOLIO_OVERVIEW_BY_ROADMAP,
  UPDATE_PORTFOLIO_OVERVIEW_LAYOUT_FULFILLED,
  FETCH_PORTFOLIO_OVERVIEW_BY_ROADMAP_FULFILLED,
  UPDATE_PORTFOLIO_OVERVIEW_DESCRIPTION_FULFILLED,
  UPDATE_PORTFOLIO_OVERVIEW_WIDGET_VISIBILITY_FULFILLED,
  UPDATE_PORTFOLIO_OVERVIEW_LOCAL_LAYOUT,
  FETCH_PORTFOLIO_OVERVIEW_OKRS_FULFILLED,
  UPDATE_PORTFOLIO_OVERVIEW_LOCAL_EDIT_MODE,
  UPDATE_PORTFOLIO_OVERVIEW_COMMITTED_STATE_FULFILLED,
  FETCH_PORTFOLIO_OVERVIEW_METRICS_FULFILLED,
  FETCH_PORTFOLIO_OVERVIEW_HISTORY,
  FETCH_PORTFOLIO_OVERVIEW_HISTORY_FULFILLED,
  FETCH_PORTFOLIO_OVERVIEW_HISTORY_NEXT_FULFILLED,
} from './types';

const getPayloadData = pathOr([], ['payload']);
const getHistoryData = pipe(getPayloadData, propOr({}, 'data'));
const getMeta = pipe(getPayloadData, propOr({}, '_meta'));

const { initialState: operationsInitialState, reducers: operationsReducers } = getThunksInitialStateAndReducers([
  FETCH_PORTFOLIO_OVERVIEW_SUB_ROADMAPS,
  FETCH_PORTFOLIO_OVERVIEW_OKRS,
  FETCH_PORTFOLIO_OVERVIEW_METRICS,
  FETCH_PORTFOLIO_OVERVIEW_BY_ROADMAP,
  FETCH_PORTFOLIO_OVERVIEW_HISTORY,
]);

export const initialState = {
  ...operationsInitialState,
  portfolioOverviewForActiveRoadmap: null,
  objectives: [],
  metrics: [],
  isInEditMode: false,
  portfolioOverviewHistory: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PORTFOLIO_OVERVIEW_LAYOUT_FULFILLED: {
      const portfolioOverview = action.payload;
      const layout = layoutOrDefaultForAllWidgets(portfolioOverview.state.widgets, portfolioOverview.state.layout);

      return {
        ...state,
        portfolioOverviewForActiveRoadmap: {
          ...state.portfolioOverviewForActiveRoadmap,
          state: {
            ...state.portfolioOverviewForActiveRoadmap.state,
            layout,
          },
        },
      };
    }
    case UPDATE_PORTFOLIO_OVERVIEW_WIDGET_VISIBILITY_FULFILLED: {
      const { widgets } = action.payload.state;

      return {
        ...state,
        portfolioOverviewForActiveRoadmap: {
          ...state.portfolioOverviewForActiveRoadmap,
          state: {
            ...state.portfolioOverviewForActiveRoadmap.state,
            widgets,
          },
        },
      };
    }
    case UPDATE_PORTFOLIO_OVERVIEW_DESCRIPTION_FULFILLED: {
      const { description } = action.payload.state;

      return {
        ...state,
        portfolioOverviewForActiveRoadmap: {
          ...state.portfolioOverviewForActiveRoadmap,
          state: {
            ...state.portfolioOverviewForActiveRoadmap.state,
            description,
          },
        },
      };
    }
    case FETCH_PORTFOLIO_OVERVIEW_BY_ROADMAP_FULFILLED: {
      const { portfolioOverview, initialEditModeState } = action.payload;
      const layout = layoutOrDefaultForAllWidgets(portfolioOverview.state.widgets, portfolioOverview.state.layout);

      return {
        ...state,
        isInEditMode: initialEditModeState,
        portfolioOverviewForActiveRoadmap: {
          ...portfolioOverview,
          state: {
            ...portfolioOverview.state,
            layout,
          },
        },
      };
    }

    case UPDATE_PORTFOLIO_OVERVIEW_LOCAL_LAYOUT: {
      return {
        ...state,
        portfolioOverviewForActiveRoadmap: {
          ...state.portfolioOverviewForActiveRoadmap,
          state: {
            ...state.portfolioOverviewForActiveRoadmap.state,
            layout: action.payload,
          },
        },
      };
    }

    case FETCH_PORTFOLIO_OVERVIEW_OKRS_FULFILLED: {
      const objectives = action.payload;

      return {
        ...state,
        objectives,
      };
    }

    case FETCH_PORTFOLIO_OVERVIEW_METRICS_FULFILLED: {
      const metrics = action.payload;

      return {
        ...state,
        metrics,
      };
    }

    case UPDATE_OBJECTIVE_FROM_DRAWER_FULFILLED: {
      return {
        ...state,
        objectives: upsertListItem(action.payload, state.objectives),
      };
    }

    case UPDATE_KEY_RESULT_FROM_DRAWER_FULFILLED: {
      const keyResultsOnStore = getStore().getState().objectives.keyResults || [];
      const keyResults = updateKeyResult(keyResultsOnStore, action.payload);

      return {
        ...state,
        objectives: buildObjectivesWithKeyResults(state.objectives, keyResults),
      };
    }

    case UPDATE_PORTFOLIO_OVERVIEW_LOCAL_EDIT_MODE: {
      return {
        ...state,
        isInEditMode: action.payload.isInEditMode,
      };
    }

    case UPDATE_PORTFOLIO_OVERVIEW_COMMITTED_STATE_FULFILLED: {
      const portfolioOverview = action.payload;

      return {
        ...state,
        portfolioOverviewForActiveRoadmap: {
          ...state.portfolioOverviewForActiveRoadmap,
          state: {
            ...state.portfolioOverviewForActiveRoadmap.state,
            committed: portfolioOverview.state.committed,
          },
        },
      };
    }
    case FETCH_PORTFOLIO_OVERVIEW_HISTORY_FULFILLED: {
      return {
        ...state,
        portfolioOverviewHistory: {
          ...state.portfolioOverviewHistory,
          [action.meta.portfolioOverviewId]: {
            _meta: getMeta(action),
            history: getHistoryData(action),
          },
        },
      };
    }
    case FETCH_PORTFOLIO_OVERVIEW_HISTORY_NEXT_FULFILLED: {
      return {
        ...state,
        portfolioOverviewHistory: {
          ...state.portfolioOverviewHistory,
          [action.meta.portfolioOverviewId]: {
            _meta: getMeta(action),
            history: concat(
              pathOr([], [action.meta.portfolioOverviewId, 'history'])(state.portfolioOverviewHistory),
              getHistoryData(action),
            ),
          },
        },
      };
    }
    default:
      return state;
  }
};

export default reduceReducers(initialState, reducer, ...operationsReducers);
