import { useCallback } from 'react';

import useLightboxesControlContext from 'hooks/lightboxes/useLightboxesControl';

/**
 * @function useOpenMetricFromEntityWithMetrics hook that will control and find the metric that should be open
 *
 * @return {Object} Return the control variables and callback to open/close metric lightbox
 */
const useOpenMetricFromEntityWithMetrics = () => {
  const { openMetricLightbox, isMetricsDialogVisible = false } = useLightboxesControlContext();

  const handleOpenMetricView = useCallback(
    (selectedEntity, tab, extraProps = {}) => {
      if (!selectedEntity) return;
      const { id } = selectedEntity;

      if (openMetricLightbox) {
        openMetricLightbox(id, extraProps, tab);
      }
    },
    [openMetricLightbox],
  );

  return {
    isMetricsDialogVisible,
    handleOpenMetricView,
  };
};

export default useOpenMetricFromEntityWithMetrics;
