import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react';

import GlobalComponent from 'containers/ProjectsDropdown/GlobalComponent';

const ProjectsDropdownContext = createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case 'register': {
      return {
        ...state,
        [action.payload.componentId]: action.payload.hookPayload,
      };
    }
    case 'unregister': {
      const { componentId } = action.payload;

      const { [componentId]: _, ...filteredState } = state;

      return filteredState;
    }
    default:
      throw new Error(`Invalid action type: ${action.type}`);
  }
};

const ProjectsDropdownProvider = ({ children }) => {
  const [contextState, dispatch] = useReducer(reducer, {});

  const register = useCallback(
    (componentId, hookPayload) => {
      dispatch({
        type: 'register',
        payload: {
          componentId,
          hookPayload,
        },
      });
    },
    [dispatch],
  );

  const unregister = useCallback(
    componentId => {
      dispatch({
        type: 'unregister',
        payload: {
          componentId,
        },
      });
    },
    [dispatch],
  );

  const contextPayload = useMemo(
    () => ({
      register,
      unregister,
    }),
    [register, unregister],
  );

  const registeredComponents = useMemo(() => {
    return Object.entries(contextState).map(([componentId, hookPayload]) => (
      <GlobalComponent key={componentId} {...hookPayload} />
    ));
  }, [contextState]);

  return (
    <ProjectsDropdownContext.Provider value={contextPayload}>
      {children}
      {registeredComponents}
    </ProjectsDropdownContext.Provider>
  );
};

const useProjectsDropdownContext = () => useContext(ProjectsDropdownContext);

export { ProjectsDropdownContext, ProjectsDropdownProvider };

export default useProjectsDropdownContext;
