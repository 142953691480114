import React, { useCallback } from 'react';
import { defaultTo, propOr, isEmpty } from 'ramda';

import { UserFilterDropdownWithDndList } from './styled';

const defaultToEmptyArray = defaultTo([]);

const getOptionTitle = i => i.title || i.label;

const getSelectedItems = (options, filter) => options.filter(s => filter && filter.includes(getOptionTitle(s)));

const UsersFilterDropdown = ({ options, filters, updateFilters, field, label, hasClearSelection, hasSearchBar }) => {
  const getFilterValue = propOr([], field);

  const filterValue = getFilterValue(filters);

  const applyFilter = useCallback(
    values => {
      updateFilters({ [field]: defaultToEmptyArray(values).map(getOptionTitle) });
    },
    [updateFilters, field],
  );

  const selectedItems = getSelectedItems(options, filterValue);
  const inputLabel = isEmpty(filterValue) ? label : '';

  return (
    <UserFilterDropdownWithDndList
      items={options}
      selectedItems={selectedItems}
      onChange={applyFilter}
      label={inputLabel}
      textFieldValue={filterValue.join(', ')}
      hasClearSelection={hasClearSelection}
      hasSearchBar={hasSearchBar}
    />
  );
};

export default UsersFilterDropdown;
