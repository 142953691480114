import axios from 'axios';

import {
  REMOVE_UNSAVED_CUSTOMERS,
  ADD_CUSTOMER_WITHOUT_SAVE,
  UPDATE_CUSTOMER_FULFILLED,
  DELETE_CUSTOMER_FULFILLED,
  CREATE_CUSTOMER_FULFILLED,
  BULK_DELETE_CUSTOMERS_FULFILLED,
  CREATE_CUSTOMER_ROADMAP,
  DELETE_CUSTOMER_ROADMAP,
  BULK_DELETE_CUSTOMER_ROADMAP,
  IMPORT_CUSTOMERS,
  UPDATE_CUSTOMERS_SEARCH_TEXT,
  UPDATE_SETTINGS_GROUP_BY_OPTION,
} from './types';

import { SALESFORCE_CUSTOMER_COUNT_RESET, SALESFORCE_IMPORT_CUSTOMERS_ERROR_RESET } from 'store/integrations/types';

import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';

export const createCustomerRoadmap = (customerId, customerRoadmap) => {
  return {
    payload: axios.post(`/api/customers/${customerId}/customerRoadmaps/`, customerRoadmap).then(response => response.data),
    type: CREATE_CUSTOMER_ROADMAP,
  };
};

export const deleteCustomerRoadmap = ({ id, roadmapId, subRoadmapId = null, product2Id = null }) => {
  let route = `/api/customers/${id}/customerRoadmaps/${roadmapId}`;

  route += subRoadmapId ? `/${subRoadmapId}` : '';
  route += subRoadmapId && product2Id ? `/${product2Id}` : '';

  return {
    payload: axios.delete(route).then(response => response.data),
    type: DELETE_CUSTOMER_ROADMAP,
  };
};

export const bulkDeleteCustomerRoadmaps = customerId => {
  return {
    payload: axios.delete(`/api/customers/${customerId}/customerRoadmaps/`).then(response => response.data),
    type: BULK_DELETE_CUSTOMER_ROADMAP,
  };
};

export const addCustomerWithoutSave = customer => ({
  type: ADD_CUSTOMER_WITHOUT_SAVE,
  customer,
});

export const removeUnsavedCustomers = _ => ({
  type: REMOVE_UNSAVED_CUSTOMERS,
});

export const gotCustomersRealtimeUpdate = generateRealtimeUpdateAction(
  CREATE_CUSTOMER_FULFILLED,
  DELETE_CUSTOMER_FULFILLED,
  UPDATE_CUSTOMER_FULFILLED,
  BULK_DELETE_CUSTOMERS_FULFILLED,
);

export const updateSearchText = text => ({
  type: UPDATE_CUSTOMERS_SEARCH_TEXT,
  payload: text,
});

export const updateSettingsGroupByOption = option => ({
  type: UPDATE_SETTINGS_GROUP_BY_OPTION,
  payload: option,
});

export const importCustomers = data => {
  return dispatch => {
    const cleanData = data.map(obj => {
      return Object.entries(obj).reduce((cleanObj, [key, value]) => {
        if (typeof value !== 'undefined') cleanObj[key] = value;

        return cleanObj;
      }, {});
    });

    axios.post('/api/customers/import', cleanData).then(({ data }) => data);

    return dispatch({
      type: IMPORT_CUSTOMERS,
      meta: data,
    });
  };
};

export const resetCustomerCount = () => ({ type: SALESFORCE_CUSTOMER_COUNT_RESET });

export const resetCustomerImportError = () => ({ type: SALESFORCE_IMPORT_CUSTOMERS_ERROR_RESET, payload: '' });
