import { materialBackground } from 'design-system/themes/default';
import AgGridHeaderWithIcon from 'design-system/molecules/AgGridReact-New/components/AgGridHeaderWithIcon';
import getCompareRoadmapsColumnDefByField from './getCompareRoadmapsColumnDefByField';
import makeFieldKeyForRoadmapVersionProjectField from './makeFieldKeyForRoadmapVersionProjectField';
import GavelIcon from '@material-ui/icons/Gavel';

import { PLAN_OF_RECORD_COL_ID } from './contants';
import { PLAN_OF_RECORD_TITLE } from 'constants/common';

const backgroundVersionCellStyle = { backgroundColor: materialBackground.roadmapVersionCellBackground };
const withBackgroundColor = idx => idx % 2 !== 0;

const getHeaderColumnStyleProps = (centerText, idx) => {
  const hasBackground = !!idx && withBackgroundColor(idx);

  return `${centerText ? 'ag-header-cell-text-centered' : ''} ${hasBackground ? 'roadmap-version-with-background-color' : ''}`;
};

const getRoadmapVersionCellCssStyle = roadmapId => params => {
  const { sortedSelectedRoadmapVersionsIds } = params.context;

  // first is plan of record
  const selectedRoadmapVersionIndex = sortedSelectedRoadmapVersionsIds.indexOf(roadmapId) + 1;
  const hasBackground = !!selectedRoadmapVersionIndex && withBackgroundColor(selectedRoadmapVersionIndex);

  return hasBackground ? backgroundVersionCellStyle : {};
};

const getRoadmapVersionHeaderClass = (roadmapId, centerText) => params => {
  const { sortedSelectedRoadmapVersionsIds } = params.context;

  // first is plan of record
  const selectedRoadmapVersionIndex = sortedSelectedRoadmapVersionsIds.indexOf(roadmapId) + 1;

  return getHeaderColumnStyleProps(centerText, selectedRoadmapVersionIndex);
};

const generateRoadmapVersionsFieldsColumns = (
  ideasGridColumnDefs,
  roadmapVersionsFields,
  roadmapVersions,
  onConvertToPlanOfRecordHeaderClicked,
  headerName = PLAN_OF_RECORD_TITLE,
  tooltipText,
) => {
  const roadmapVersionsFieldsColumnDefs = roadmapVersionsFields.map(roadmapVersionsField => {
    const fieldColumnDef = getCompareRoadmapsColumnDefByField(ideasGridColumnDefs, roadmapVersionsField.key);

    return {
      width: 120,
      ...fieldColumnDef,
      headerName: roadmapVersionsField.title,
      field: roadmapVersionsField.key,
      ...getHeaderColumnStyleProps(false),
    };
  });

  return [
    {
      headerName,
      field: PLAN_OF_RECORD_COL_ID,
      enableColResize: true,
      hide: false,
      headerClass: 'ag-header-cell-text-centered',
      children: roadmapVersionsFieldsColumnDefs,
    },
    ...roadmapVersions.map(roadmapVersion => {
      return {
        headerName: roadmapVersion.name,
        headerGroupComponent: onConvertToPlanOfRecordHeaderClicked
          ? AgGridHeaderWithIcon({
              tooltipText,
              IconComponent: GavelIcon,
              onIconClick: () => onConvertToPlanOfRecordHeaderClicked(roadmapVersion.id),
            })
          : null,
        enableColResize: true,
        field: makeFieldKeyForRoadmapVersionProjectField(roadmapVersion.id),
        cellStyle: getRoadmapVersionCellCssStyle(roadmapVersion.id),
        headerClass: getRoadmapVersionHeaderClass(roadmapVersion.id, true),
        children: roadmapVersionsFieldsColumnDefs.map(roadmapVersionsFieldsColumnDef => ({
          ...roadmapVersionsFieldsColumnDef,
          field: makeFieldKeyForRoadmapVersionProjectField(roadmapVersion.id, roadmapVersionsFieldsColumnDef.field),
          cellStyle: getRoadmapVersionCellCssStyle(roadmapVersion.id),
          headerClass: getRoadmapVersionHeaderClass(roadmapVersion.id, false),
          editable: false,
        })),
      };
    }),
  ];
};

export default generateRoadmapVersionsFieldsColumns;
