import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import Dropdown from 'design-system/molecules/Dropdown/index';


import useTimelinesForSwimlaneCompare from '../../hooks/useTimelinesForSwimlaneCompare';

import ShowItemsWithoutDatesButton from 'routes/Swimlane/components/ShowItemsWithoutDatesButton';
import GroupByList from '../GroupByList/GroupByList';
import ZoomButton from 'routes/Swimlane/New/ControlsBar/components/ZoomButton';

import { columnWidths } from 'utils/swimlane/new/columnWidths';
import { COMPARE_SCENARIOS_SWIMLANE_PAGE } from 'constants/filters/pages';
import SwimlaneSelectedVersionsColors from 'routes/RoadmapVersions/components/SwimlaneSelectedVersionsColors';
import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useScenarioVersionColors from 'routes/RoadmapVersions/hooks/useScenarioVersionColors';
import DisplayTimeline from '../DisplayTimeline/DisplayTimeline';

const ControlsBar = ({ displayRoadmapChips = true }) => {
  const {
    lsState: { selectedZoom },
    selectedGroupOptions,
    groupByOptionsList,
    changeSelectedZoom,
    changeSelectedGroupOption,
    changeSlotWidth,
    selectedSnapToGridOn,
    changeSelectedSnapToGridOn,
    snapToGridOnOptions,
  } = useTimelinesForSwimlaneCompare();

  const { roadmapVersions } = useRoadmapVersions();

  const { scenarioColors } = useScenarioVersionColors();

  return (
    <>
      <Wrapper container spacing={8}>
        <GroupByControls>
          {!isEmpty(selectedGroupOptions) && (
            <GroupByList
              selectedGroupOptions={selectedGroupOptions}
              groupByOptionsList={groupByOptionsList}
              changeSelectedGroupOption={changeSelectedGroupOption}
            />
          )}

          <DisplayTimeline
            selectedSnapToGridOn={selectedSnapToGridOn}
            snapToGridOnOptions={snapToGridOnOptions}
            changeSelectedSnapToGridOn={changeSelectedSnapToGridOn}
          />
        </GroupByControls>

        {displayRoadmapChips ? (
          <SwimlaneSelectedVersionsColors scenarioColors={scenarioColors} roadmapVersions={roadmapVersions} />
        ) : null}

        <RightControls>
          {/* Items Without Dates */}
          <IconWrapper>
            <ShowItemsWithoutDatesButton page={COMPARE_SCENARIOS_SWIMLANE_PAGE} />
          </IconWrapper>

          {/* Column width */}
          <IconWrapper>
            <Dropdown
              label="Col width"
              options={columnWidths}
              onChange={changeSlotWidth}
              isButton={false}
              placeholder={
                <RotatedButtonIcon title="Col width" data-cy="grid-col-width">
                  <FormatLineSpacingIcon />
                </RotatedButtonIcon>
              }
            />
          </IconWrapper>

          {/* Zoom */}
          <IconWrapper>
            <ZoomButton selectedZoom={selectedZoom} changeSelectedZoom={changeSelectedZoom} />
          </IconWrapper>
        </RightControls>
      </Wrapper>
    </>
  );
};

export default ControlsBar;

const Wrapper = styled.div`
  &&& {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 8px 0;
  }
`;

const RotatedButtonIcon = styled(ButtonIcon)`
  transform: rotate(90deg);
`;

const GroupByControls = styled.div`
  display: flex;
`;

const RightControls = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  width: 48px;
`;
