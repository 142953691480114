import React from 'react';
import { defaultTo } from 'ramda';

import FluidField from 'design-system/molecules/FluidField/index';

import RequestIntegrationInformation from '../RequestIntegrationInformation/RequestIntegrationInformation';

const defaultToEmptyString = defaultTo('');

const getIntegrationLabel = (integrationType, oneStepAvailableIntegrations) => {
  switch (integrationType) {
    case 'zendesk':
      return 'Zendesk';
    default:
      return defaultToEmptyString(oneStepAvailableIntegrations?.[integrationType]?.title);
  }
};

const renderIntegration = (integration, orgIntegrations, oneStepAvailableIntegrations) => {
  const orgIntegration = orgIntegrations.find(int => +int.id === +integration.org_integration_id);
  const integrationLabel = getIntegrationLabel(orgIntegration?.integrationType, oneStepAvailableIntegrations);

  return <RequestIntegrationInformation integrationLabel={integrationLabel} integrationItemLink={integration?.external_url} />;
};

const FluidRequestsIntegrationField = props => {
  if (!Array.isArray(props.value)) return null;

  return (
    <FluidField
      {...props}
      ignoreMouseEvents
      editorRenderer={({ value }) =>
        value.map(integration => renderIntegration(integration, props.orgIntegrations, props.oneStepAvailableIntegrations))
      }
    />
  );
};

FluidRequestsIntegrationField.propTypes = { ...FluidField.propTypes };

export default FluidRequestsIntegrationField;
