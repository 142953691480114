import { SUPPORTED_FIELDS } from 'utils/roadmapVersions/supportedFieldsUtils';

/**
 * @function makeCellStyleForCreatingOrViewingScenario
 *
 * make function to get style for cell when the grid is
 * creating or viewing some scenario
 *
 * @param  {Object} enhancedValue
 * @return {Object}
 */
const makeCellStyleForCreatingOrViewingScenario = (enhancedValue = {}) => params => {
  const { colDef, context } = params;
  const { isCreatingOrViewingScenario } = context;

  if (!isCreatingOrViewingScenario) {
    return enhancedValue;
  }

  const field = SUPPORTED_FIELDS.find(f => f.key === colDef.field);

  /*
   * if field is not included on roadmap version supported fields
   * will gray out the field value
   */
  if (!field) {
    return {
      ...enhancedValue,
      opacity: '0.6',
    };
  }

  return enhancedValue;
};

export default makeCellStyleForCreatingOrViewingScenario;
