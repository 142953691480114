import React from 'react';
import styled, { css } from 'styled-components';
import { slice } from 'ramda';
import IconButton from '@material-ui/core/IconButton';
import materialColorsSnapshot from 'routes/Dashboard/Snapshot/colors';

import InsightsIcon from 'design-system/atoms/InsightsIcon/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';

import ProgressBarSquaredSmall from 'design-system/atoms/ProgressBarSquaredSmall';
import unitNumberToValue from 'utils/unitNumberToValue';
import theme from '../../theme';

const MAX_METRICS = 3;
const getFirstMetrics = slice(0, MAX_METRICS);

const MultipleMetricsProgressBar = ({ entity, hasAdvancedMetricReporting, toggleMetricInformation }) => {
  const _renderOKRProgressBar = metric => {
    return (
      <ProgressBarSquaredSmall
        color={theme.palette.primary.main}
        formatValues
        minValue={unitNumberToValue(metric?.baseline_value)}
        minValueLabel={metric?.baseline_value}
        actualValue={unitNumberToValue(metric?.actual_value)}
        actualValueLabel={metric?.actual_value}
        maxValue={unitNumberToValue(metric?.target_value)}
        maxValueLabel={`${metric?.target_value}%`}
      />
    );
  };

  const handleMetricClick = (e, metric) => {
    toggleMetricInformation(metric);
    e.stopPropagation();
  };

  return (
    <Wrapper>
      {entity.metrics &&
        getFirstMetrics(entity.metrics).map(metric => (
          <MetricContainer onClick={e => hasAdvancedMetricReporting && handleMetricClick(e, metric)}>
            <MetrictStatusHeader>
              <HeaderWrapper>
                <TooltipOnOverflow text={metric?.name}>
                  <MetricName>{metric?.name || 'No titled'}</MetricName>
                </TooltipOnOverflow>
                {hasAdvancedMetricReporting && (
                  <ButtonWrapper>
                    <StyledIconButton onClick={e => handleMetricClick(e, metric)}>
                      <InsightsIcon />
                    </StyledIconButton>
                  </ButtonWrapper>
                )}
              </HeaderWrapper>
            </MetrictStatusHeader>
            <MetrictStatusContent>{_renderOKRProgressBar(metric)}</MetrictStatusContent>
          </MetricContainer>
        ))}
    </Wrapper>
  );
};

export default MultipleMetricsProgressBar;

const ButtonWrapper = styled.div``;

const Wrapper = styled.div`
  &&&& {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const MetricContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    ${ButtonWrapper} {
      visibility: visible;
    }
  }
`;

const MetrictStatusHeader = styled.div`
  &&&& {
    width: 60%;
    margin-bottom: 0.4rem;
    text-transform: capitalize;

    font-size: ${props => props.theme.typography.fontSizeSmall}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    line-height: ${props => props.theme.typography.lineHeightSmallLarge}px;
    color: ${materialColorsSnapshot.darkestGray};

    ${({ child, theme }) =>
      child &&
      css`
        margin-bottom: 0.4rem;
        font-size: ${theme.typography.fontSize}px;
        line-height: ${props => props.theme.typography.lineHeightSmallLarge}px;
      `}
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${ButtonWrapper} {
    visibility: hidden;
  }
`;

const MetricName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MetrictStatusContent = styled.div`
  &&&& {
    width: 40%;
    margin-bottom: 0.4rem;
    padding-left: 5px;
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    padding: 6px;
  }

  svg {
    font-size: ${({ theme }) => theme.typography.fontSize}px;
  }
`;
