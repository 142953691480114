import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getGridConfigValue } from 'store/grids/selectors';
import useActiveViewChanged from 'hooks/userViews/useActiveViewChanged';

import { isLoadingProjects } from 'store/projects';
import { getObjectIdFromUniqueId } from 'utils/grid';

const EXPANDED_GROUPS_STORE_KEY = 'expandedGroups';

const findAndGetUniqueIdFromContext = (key, { processedData = [] }) =>
  processedData?.find(project => project?.id === key)?.uniqueId;

/**
 * @function useGridExpandedGroups hook to load expanded groups and update grid groups
 * @param  {String} gridId The grid that we are working with
 * @param  {String} gridApi Grid api instance for the grid we are using

 * @return {Object} All data and callbacks generated by the hook
 */
const useIdeasGridExpandedGroups = (gridId, gridApi) => {
  const expandedGroupsRef = useRef([]);
  const { activeViewChanged } = useActiveViewChanged();

  const isLoading = useSelector(isLoadingProjects);

  const expandedGroups = useSelector(state => getGridConfigValue(state, gridId, EXPANDED_GROUPS_STORE_KEY, []));

  const isGroupExpandedByDefault = useCallback(
    ({ key, id, context }) => {
      const uniqueId = id ?? findAndGetUniqueIdFromContext(key, context);

      // to ensure retro-compatibility with saved expanded groups list from old grid
      const parsedIdForOldViews = getObjectIdFromUniqueId(uniqueId);

      return (
        expandedGroupsRef.current.includes(key) ||
        expandedGroupsRef.current.includes(uniqueId) ||
        (parsedIdForOldViews && expandedGroupsRef.current.includes(parsedIdForOldViews))
      );
    },
    [expandedGroupsRef.current],
  );

  useEffect(() => {
    expandedGroupsRef.current = expandedGroups;
  }, [expandedGroups]);

  useEffect(() => {
    if (!gridApi || isLoading || !activeViewChanged) {
      return;
    }

    const expandedNodesBatchUpdateData = [];

    // View was changed, lets force expanded groups are up to date on the grid
    gridApi.forEachNode(node => {
      const newNode = { ...node, expanded: isGroupExpandedByDefault(node) };

      expandedNodesBatchUpdateData.push(newNode);
    });

    // not sure if this is still being used for ideas list but at least is protected
    gridApi.applyTransactionAsync({ update: expandedNodesBatchUpdateData });
  }, [gridApi, activeViewChanged, isLoading, isGroupExpandedByDefault]);

  return {
    isGroupExpandedByDefault,
  };
};

export default useIdeasGridExpandedGroups;
