import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import { BaseGrid } from 'containers/Grids';
import { useCommonGridEventHandlers, useCommonGridProps } from 'design-system/molecules/AgGridReact-New/hooks';
import { HEADER_HEIGHT, ID_KEY } from 'routes/CustomerRequests/Grid/New/constants';
import Autocomplete from 'design-system/atoms/Autocomplete/index';

import Text from 'design-system/atoms/Text';
import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';
import { AUTOCOMPLETE_FROM_SERVER_DELAY } from 'constants';
import { BASE_ROW_HEIGHT } from 'constants/grid';
import useDynamicGridHeight from 'design-system/molecules/AgGridReact-New/hooks/useDynamicGridHeight';
import { OPEN_CUSTOMER_REQUEST_QUERY_PARAM } from 'constants/customerRequests';
import { addQueryParamAndOpenOnNewTab } from 'utils/queryParamsUtils';

import { GridWrapper, StyledButton } from './styles';
import usePotentialDuplicatesGrid from './hooks/usePotentialDuplicatesGrid';
import usePotentialDuplicatesGridColumnsDefs from './hooks/usePotentialDuplicatesGridColumnsDefs';
import useMergePotentialDuplicates from './hooks/useMergePotentialDuplicates';

const GRID_HEIGHT_OFFSET = 250;

const getRowId = ({ data }) => data?.id || null;

const PotentialDuplicatesGrid = ({ customerRequest, data, updateData }) => {
  const handleOpenCustomerRequestInfoInNewTab = row => {
    addQueryParamAndOpenOnNewTab(OPEN_CUSTOMER_REQUEST_QUERY_PARAM, row?.key);
  };

  const {
    handleGridReady,
    setDirtyViewMutex,
    gridContext,
    removeUnsavedRows,
    addPotentialDuplicateRequest,
    removePotentialDuplicateRequest,
    cleanTableData,
  } = usePotentialDuplicatesGrid({ customerRequest, data, updateData });
  const { columnDefs, defaultColDef } = usePotentialDuplicatesGridColumnsDefs({
    addPotentialDuplicateRequest,
    removePotentialDuplicateRequest,
    onOpenCustomerRequestInfo: handleOpenCustomerRequestInfoInNewTab,
  });
  const commonGridProps = useCommonGridProps({});

  const commonGridEvents = useCommonGridEventHandlers({
    items: data,
    idKey: ID_KEY,
    focusField: null,
    actions: {
      remove: removeUnsavedRows,
    },
  });

  const rowHeight = BASE_ROW_HEIGHT;

  const { gridHeight } = useDynamicGridHeight({
    rowHeight,
    headerHeight: HEADER_HEIGHT,
    rowCount: data.length,
    maxRowsHeight: (window.innerHeight - GRID_HEIGHT_OFFSET) / rowHeight,
  });

  const {
    selectedRequestToMerge,
    suggestions,
    requestsListIsEmpty,
    anyRequestToMergeSelected,
    isMerging,

    setSelectedRequestToMerge,
    handleMergeRequests,
  } = useMergePotentialDuplicates({
    customerRequest,
    requestsToMerge: data,
  });

  return (
    <>
      <GridWrapper>
        <BaseGrid
          // Common props:
          {...commonGridProps}
          {...commonGridEvents}
          // General props:
          getRowId={getRowId}
          onGridReady={handleGridReady}
          rowData={data}
          context={gridContext}
          onFirstDataRendered={setDirtyViewMutex}
          suppressRowClickSelection
          // Styling:
          height={gridHeight}
          headerHeight={HEADER_HEIGHT}
          rowHeight={rowHeight}
          // Columns:
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
        />
      </GridWrapper>
      <Grid container alignItems="center">
        <Grid container xs={1} alignItems="center" justify="flex-start">
          <Text variant="h5">Keep</Text>
        </Grid>
        <Grid container xs={9}>
          <FormControl fullWidth>
            <Autocomplete
              style={{ width: '100%' }}
              name="request"
              placeholder={TYPE_TO_SEARCH_PLACEHOLDER}
              onValueChange={value => {
                setSelectedRequestToMerge(suggestions.find(r => r.id === value));
              }}
              loadDelay={AUTOCOMPLETE_FROM_SERVER_DELAY}
              value={selectedRequestToMerge?.title}
              suggestions={suggestions.map(r => ({
                value: r.id,
                label: r.title,
              }))}
              inputProps={{ useEllipsis: true }}
              disabled={requestsListIsEmpty}
            />
          </FormControl>
        </Grid>
        <Grid container xs={2} justify="flex-end">
          <div>
            <StyledButton
              color="primary"
              disabled={requestsListIsEmpty || anyRequestToMergeSelected || isMerging}
              onClick={handleMergeRequests}
            >
              Merge
            </StyledButton>
            <StyledButton onClick={cleanTableData} disabled={requestsListIsEmpty || isMerging}>
              Cancel
            </StyledButton>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default PotentialDuplicatesGrid;
