import isFunction from 'lodash/isFunction';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';

import { isValidActionPrefix } from './common';

/**
 * Creates all the thunk action types constants for pending, fulfilled and rejected states.
 *
 * @param {String} actionPrefix The base type for action types.
 * @returns An object with all action types constants.
 */
const getThunkActionTypes = actionPrefix =>
  isValidActionPrefix(actionPrefix)
    ? {
        [actionPrefix]: actionPrefix,
        [`${actionPrefix}_PENDING`]: `${actionPrefix}_PENDING`,
        [`${actionPrefix}_FULFILLED`]: `${actionPrefix}_FULFILLED`,
        [`${actionPrefix}_REJECTED`]: `${actionPrefix}_REJECTED`,
        [`${actionPrefix}_RESET`]: `${actionPrefix}_RESET`,
      }
    : {};

/**
 * Creates the async action creator.
 *
 * @param {String} type The action type
 * @param {*} payload The payload action property. May be a function or an object.
 * @param {*} metadata The metadata for meta action property. May be a function or an object.
 * @param {Object} config additional configurations for the create thunk builder
 * @param {Boolean} config.includeRetryAction if true will add a retry action to the original action metadata
 *  This may be helpful to trigger different action in the then callback.
 * @returns The payload passed as argument, may be a promise or an object.
 */
const createThunk = (type, payload, metadata, config = {}) => {
  const { includeRetryAction = false } = config;

  const retryActionPayload = includeRetryAction ? { retryAction: () => createThunk(type, payload, metadata) } : {};

  return {
    type,
    ...(payload && { payload: isFunction(payload) ? payload() : payload }),
    ...(metadata && isFunction(metadata) && { meta: { ...metadata(), ...retryActionPayload } }),
    ...(metadata && isObject(metadata) && !isEmpty(metadata) && { meta: { ...metadata, ...retryActionPayload } }),
  };
};

export { createThunk, getThunkActionTypes };
