import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export default ({ title, allowNewRow, renderNewRowButton, newRowButtonTitle, addNew, forwardRef }) => (
  <Wrapper ref={forwardRef}>
    {title && (
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
    )}
    <div style={{ flex: 1 }} />
    {allowNewRow && renderNewRowButton && renderNewRowButton(addNew)}
    {allowNewRow && !renderNewRowButton && (
      <Button onClick={addNew} size="small">
        {newRowButtonTitle}
      </Button>
    )}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: baseline;
`;
