import { defaultTo } from 'ramda';
import { FeatureFlags } from '@dragonboat/config';
import {
  PERMISSION_ACTIONS,
  ADMIN_USER,
  OWNER_USER,
  LEADER_USER,
  MANAGER_USER,
  EDITOR_USER,
  REQUESTOR_USER,
  READ_ONLY_USER,
  COLLABORATOR_USER,
  SUPER_ADMIN_USER,
  CSM_USER,
} from '@dragonboat/permissions';

import { PERMISSION_FEATURES, PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';
import {
  SUMMARY_PAGE,
  PORTFOLIO_CANVAS_PAGE,
  SNAPSHOT_PAGE,
  FORECAST_PAGE,
  SCENARIO_CHART_PAGE,
  FORECAST_CHART_PAGE,
  IDEAS_OVERVIEW_PAGE,
} from 'constants/filters';
import { checkOrganizationFlags } from 'hooks/useFeatureFlags';
import {
  PORTFOLIO_LIFECYCLES_PAGE,
  PORTFOLIO_PERSONAS_PAGE,
  REQUESTS_LIFECYCLES,
  REQUESTS_PERSONAS,
  MISSION_CONTROL_HOME_PAGE,
  GOALS_CANVAS_PAGE,
  METRICS_CANVAS_PAGE,
  GOALS_ALLOCATION_PAGE,
  FORECAST_RESOURCES_PAGE,
  IDEAS_PDLC_PAGE,
  WELCOME_PAGE,
  HOME_PAGE,
  IDEAS_BOARD_PAGE,
  IDEAS_GRID_PAGE,
  ROADMAP_PAGE,
  MANAGE_PAGE,
  REQUESTS_ANALYZE,
  DASHBOARD_HOME_PAGE,
  DASHBOARDS_PAGE,
  PROGRESS_PAGE,
  ALLOCATION_REPORT_PAGE,
  TEMPLATES_PAGE,
  METRICS_CHART_PAGE,
  WELCOME_SELECT_TEMPLATE_PAGE,
  USER_DASHBOARDS_PAGE,
  DASHBOARD_EXPLORE_PAGE,
  METRICS_GRID_PAGE,
  GOALS_SUMMARY_PAGE,
  GOALS_SNAPSHOT_PAGE,
  GOALS_GRID_PAGE,
  IDEAS_ESTIMATES_PAGE,
  IDEAS_FLAT_GRID_PAGE,
} from 'constants/filters/pages';

const defaultToFalse = defaultTo(false);

const generatePagePermissions = ({ checks, organization }) => {
  const canViewSettingsPage = page => {
    switch (true) {
      case checks.userIsReadOnly():
        return checks.orgHasReadOnlyUserAccess(`settings/${page}`);
      default:
        return checks.userIsOneOf(ADMIN_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER);
    }
  };

  const canViewPortfolioOverview = () => {
    switch (true) {
      case checks.userIsReadOnly():
        return defaultToFalse(organization.allow_read_only_users_to_view_portfolio_overview) && !checks.isDoDActive();
      default:
        return checks.userIsOneOf(ADMIN_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER) && !checks.isDoDActive();
    }
  };

  const canViewMissionControlHome = () =>
    checks.userIsOneOf(
      ADMIN_USER,
      OWNER_USER,
      LEADER_USER,
      MANAGER_USER,
      EDITOR_USER,
      REQUESTOR_USER,
      READ_ONLY_USER,
      COLLABORATOR_USER,
    );
  const canViewWelcomePage = () => checks.userIsOneOf(ADMIN_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER);
  const canViewHomePage = () => checks.userIsOneOf(ADMIN_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER);
  const canViewPortfolioModule = (context, permissions) => {
    switch (true) {
      case checks.userIsOneOf(READ_ONLY_USER, CSM_USER, COLLABORATOR_USER):
        return context.isDashboardModule === true;
      default:
        return permissions.canView(PERMISSION_FEATURES.portfolioModule, context);
    }
  };
  const canViewOutcomeModule = (context, permissions) => {
    switch (true) {
      case checks.userIsOneOf(READ_ONLY_USER, CSM_USER, COLLABORATOR_USER):
        return context.isDashboardModule === true;
      default:
        return permissions.canView(PERMISSION_FEATURES.outcomeModule, context);
    }
  };
  const canViewMetricsModule = (context, permissions) => {
    switch (true) {
      case checks.userIsOneOf(READ_ONLY_USER, CSM_USER, COLLABORATOR_USER):
        return context.isDashboardModule === true;
      default:
        return permissions.canView(PERMISSION_FEATURES.metricsModule, context);
    }
  };
  const canViewScenariosModule = (context, permissions) => permissions.canView(PERMISSION_FEATURES.scenariosModule, context);
  const canViewForecastModule = (context, permissions) => {
    switch (true) {
      case checks.userIsOneOf(READ_ONLY_USER, CSM_USER, COLLABORATOR_USER):
        return context.isDashboardModule === true;
      default:
        return permissions.canView(PERMISSION_FEATURES.forecastModule, context);
    }
  };
  const canViewDashboardModule = (context, permissions) => {
    switch (true) {
      case checks.userIsOneOf(READ_ONLY_USER, CSM_USER, COLLABORATOR_USER):
        return context.isDashboardModule === true;
      default:
        return permissions.canView(PERMISSION_FEATURES.dashboardModule, context);
    }
  };
  const canViewRequestsModule = (context, permissions) => permissions.canView(PERMISSION_FEATURES.requestsModule, context);

  // Builds check for `flags`
  const checkFeatureFlagsEnabled = flags => {
    return () => checkOrganizationFlags(organization, flags);
  };

  const canViewIdeasPDLCPage = (context, permissions) => {
    switch (true) {
      case checks.userIsOneOf(COLLABORATOR_USER):
        // collaborator should not have dashboard route for pdlc page
        return permissions.canView(PERMISSION_FEATURES.pdlcModule, context) && !context.isDashboardModule;
      default:
        return permissions.canView(PERMISSION_FEATURES.portfolioModule, context);
    }
  };
  const canViewProgressPage = (context, permissions) => {
    switch (true) {
      case checks.userIsOneOf(READ_ONLY_USER, CSM_USER, COLLABORATOR_USER):
        return context.isDashboardModule === true;
      default:
        return permissions.canView(PERMISSION_FEATURES.dashboardModule, context);
    }
  };
  const canViewAllocationReportPage = (context, permissions) => {
    switch (true) {
      case checks.userIsOneOf(READ_ONLY_USER, CSM_USER, COLLABORATOR_USER):
        return context.isDashboardModule === true;
      default:
        return permissions.canView(PERMISSION_FEATURES.dashboardModule, context);
    }
  };
  const canViewTemplatesPage = () => checks.userIsOneOf(ADMIN_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER);
  const canViewWelcomeSelectTemplatePage = () =>
    checks.userIsOneOf(ADMIN_USER, OWNER_USER, LEADER_USER, MANAGER_USER, EDITOR_USER);

  const orgHasDashboardsEnabled = () =>
    checkOrganizationFlags(organization, FeatureFlags.HAS_DELIVERY_DASHBOARD) ||
    checkOrganizationFlags(organization, FeatureFlags.HAS_OUTCOME_DASHBOARD);

  /* TODO: This is Views Home Page should be renamed */
  const canViewDashboardHome = () =>
    checks.userIsOneOf(
      ADMIN_USER,
      SUPER_ADMIN_USER,
      CSM_USER,
      OWNER_USER,
      READ_ONLY_USER,
      LEADER_USER,
      COLLABORATOR_USER,
      EDITOR_USER,
      MANAGER_USER,
    );
  const canViewDashboardExplore = () =>
    checks.userIsOneOf(
      ADMIN_USER,
      SUPER_ADMIN_USER,
      CSM_USER,
      OWNER_USER,
      READ_ONLY_USER,
      LEADER_USER,
      COLLABORATOR_USER,
      EDITOR_USER,
      MANAGER_USER,
    );
  const canViewDashboardsHomePageOrDashboardPage = (context, permissions) => {
    switch (true) {
      case checks.userIsOneOf(READ_ONLY_USER, CSM_USER, COLLABORATOR_USER):
        return context.isDashboardModule === true;
      default:
        return permissions.canView(PERMISSION_FEATURES.dashboardModule, context);
    }
  };

  return {
    [PERMISSION_PAGES[SUMMARY_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewPortfolioModule, checks.isPortfolioAccount],
    },
    [PERMISSION_PAGES[PORTFOLIO_CANVAS_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewPortfolioModule, () => checkOrganizationFlags(organization, FeatureFlags.HAS_CANVAS)],
    },
    [PERMISSION_PAGES[SNAPSHOT_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewDashboardModule, () => checkOrganizationFlags(organization, FeatureFlags.HAS_SNAPSHOT)],
    },
    [PERMISSION_PAGES[FORECAST_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [
        canViewForecastModule,
        () => checkOrganizationFlags(organization, FeatureFlags.HAS_FORECAST_MODULE),
      ],
    },
    [PERMISSION_PAGES[SCENARIO_CHART_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [
        canViewScenariosModule,
        () => checkOrganizationFlags(organization, FeatureFlags.HAS_ROADMAP_VERSIONS),
      ],
    },
    [PERMISSION_PAGES[FORECAST_CHART_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [
        canViewScenariosModule,
        () => checkOrganizationFlags(organization, FeatureFlags.HAS_ROADMAP_VERSIONS),
      ],
    },
    [PERMISSION_PAGES.settingsRoadmapsPage]: {
      [PERMISSION_ACTIONS.view]: [() => canViewSettingsPage('roadmaps')],
    },
    [PERMISSION_PAGES.settingsOkrsPage]: {
      [PERMISSION_ACTIONS.view]: [() => canViewSettingsPage('objectives')],
    },
    [PERMISSION_PAGES.settingsMetricsPage]: {
      [PERMISSION_ACTIONS.view]: [() => canViewSettingsPage('metrics')],
    },
    [PERMISSION_PAGES[IDEAS_OVERVIEW_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewPortfolioOverview, checkFeatureFlagsEnabled(FeatureFlags.HAS_PORTFOLIO_OVERVIEW)],
    },
    [PERMISSION_PAGES[PORTFOLIO_LIFECYCLES_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [
        canViewPortfolioModule,
        (context, permissions) => permissions.canView(PERMISSION_FEATURES.lifecycles, context),
      ],
    },
    [PERMISSION_PAGES[PORTFOLIO_PERSONAS_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [
        canViewPortfolioModule,
        (context, permissions) => permissions.canView(PERMISSION_FEATURES.personas, context),
      ],
    },
    [PERMISSION_PAGES[REQUESTS_LIFECYCLES]]: {
      [PERMISSION_ACTIONS.view]: [(context, permissions) => permissions.canView(PERMISSION_FEATURES.lifecycles, context)],
    },
    [PERMISSION_PAGES[REQUESTS_PERSONAS]]: {
      [PERMISSION_ACTIONS.view]: [(context, permissions) => permissions.canView(PERMISSION_FEATURES.personas, context)],
    },
    [PERMISSION_PAGES[MISSION_CONTROL_HOME_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [
        checks.isPortfolioAccount,
        canViewMissionControlHome,
        checkFeatureFlagsEnabled(FeatureFlags.HAS_MISSION_CONTROL_HOME),
      ],
    },
    [PERMISSION_PAGES[GOALS_CANVAS_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewOutcomeModule, () => checkOrganizationFlags(organization, FeatureFlags.HAS_CANVAS)],
    },
    [PERMISSION_PAGES[METRICS_CANVAS_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewOutcomeModule, () => checkOrganizationFlags(organization, FeatureFlags.HAS_CANVAS)],
    },
    [PERMISSION_PAGES[GOALS_ALLOCATION_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [
        canViewOutcomeModule,
        () => checkOrganizationFlags(organization, FeatureFlags.HAS_REPORTED_ALLOCATION_REPORT),
        () => checkOrganizationFlags(organization, FeatureFlags.HAS_ACTUAL_ALLOCATION),
      ],
    },
    [PERMISSION_PAGES[IDEAS_BOARD_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewPortfolioModule],
    },
    [PERMISSION_PAGES[IDEAS_GRID_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewPortfolioModule],
    },
    [PERMISSION_PAGES[ROADMAP_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewPortfolioModule],
    },
    [PERMISSION_PAGES[MANAGE_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewPortfolioModule],
    },
    [PERMISSION_PAGES[FORECAST_RESOURCES_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewForecastModule, () => checks.isFeatureFlagEnabled(FeatureFlags.HAS_STAFFING_PAGE)],
    },
    [PERMISSION_PAGES[IDEAS_PDLC_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewIdeasPDLCPage],
    },
    [PERMISSION_PAGES[WELCOME_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewWelcomePage],
    },
    [PERMISSION_PAGES[HOME_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewHomePage],
    },
    [PERMISSION_PAGES[REQUESTS_ANALYZE]]: {
      [PERMISSION_ACTIONS.view]: [
        canViewRequestsModule,
        () => checks.isFeatureFlagEnabled(FeatureFlags.HAS_REQUESTS_ANALYZE_PAGE),
      ],
    },
    [PERMISSION_PAGES[DASHBOARD_HOME_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewDashboardHome],
    },
    [PERMISSION_PAGES[DASHBOARDS_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewDashboardsHomePageOrDashboardPage, orgHasDashboardsEnabled],
    },
    [PERMISSION_PAGES[PROGRESS_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [checks.isNotSelfTrialAccount, canViewProgressPage],
    },
    [PERMISSION_PAGES[ALLOCATION_REPORT_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewAllocationReportPage],
    },
    [PERMISSION_PAGES[TEMPLATES_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewTemplatesPage],
    },
    [PERMISSION_PAGES[METRICS_CHART_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [
        canViewMetricsModule,
        () => checks.isFeatureFlagEnabled(FeatureFlags.HAS_ADVANCED_METRIC_REPORTING),
      ],
    },
    [PERMISSION_PAGES[WELCOME_SELECT_TEMPLATE_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewWelcomeSelectTemplatePage],
    },
    [PERMISSION_PAGES[USER_DASHBOARDS_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewDashboardModule, orgHasDashboardsEnabled],
    },
    [PERMISSION_PAGES[DASHBOARD_EXPLORE_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewDashboardExplore],
    },
    [PERMISSION_PAGES[METRICS_GRID_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewMetricsModule],
    },
    [PERMISSION_PAGES[GOALS_SUMMARY_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewOutcomeModule],
    },
    [PERMISSION_PAGES[GOALS_SNAPSHOT_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewOutcomeModule],
    },
    [PERMISSION_PAGES[GOALS_GRID_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewOutcomeModule],
    },
    [PERMISSION_PAGES[IDEAS_ESTIMATES_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewPortfolioModule],
    },
    [PERMISSION_PAGES[IDEAS_FLAT_GRID_PAGE]]: {
      [PERMISSION_ACTIONS.view]: [canViewPortfolioModule],
    },
  };
};

export default generatePagePermissions;
