import React from 'react';

import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

export default props => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <InlineDatePicker keyboard clearable value={props.value || null} format="YYYY-MM-DD" onChange={props.onChange} />
  </MuiPickersUtilsProvider>
);
