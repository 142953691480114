import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { defaultTo, omit } from 'ramda';

import { getEnrichedProject } from 'store/projects/selectors';
import { getFiltersForProjectsSearch } from 'utils/projects/projectsUtils';

const FILTER_FIELDS_TO_INGORE_ON_APPLY_FILTERS = ['from', 'to', 'order'];

/**
 * @function useProjectsTitleSearch
 *
 * Hook that allows a component to search for projects by title
 *
 * @param  {Array} layers                Layers to include on search request
 * @param  {Boolean} includeMilestones   Flag to filter milestones on the result or not
 * @return {Object}
 */
const useProjectsTitleSearch = layers => {
  const [isLoading, setIsLoading] = useState([]);

  const [projects, setProjects] = useState([]);
  const [filters, setFilters] = useState({});

  // required for getEnrichedProject selector
  const state = useSelector(state => state);

  const searchProjects = useCallback(
    async (
      value,
      { showCompleted = false, overwriteLayers = null, limit = 20, withParents = false, extraFilters = {}, offset = 0 },
    ) => {
      setIsLoading(true);

      const titleSearchParams = {
        withParents,
        layersToSearch: layers,
        showCompleted,
        limit,
        searchText: value,
      };

      const { data } = await axios.post('/api/projects/search/title', titleSearchParams);

      // TODO: maybe we could have here a "light" getEnrichedProject that gets only the metadata we need
      const enrichedProjects = defaultTo([], data).map(sp => getEnrichedProject(state, sp));

      setIsLoading(false);
      setProjects(enrichedProjects);

      const filtersUsed = getFiltersForProjectsSearch({
        layers: overwriteLayers ?? layers,
        limit,
        offset,
        search: value,
        order: '-created_at',
        showCompleted,
      });

      setFilters(omit(FILTER_FIELDS_TO_INGORE_ON_APPLY_FILTERS, filtersUsed));

      return enrichedProjects;
    },
    [state, layers],
  );

  return {
    projects,
    isLoading,
    searchProjects,
    filters,
  };
};

export default useProjectsTitleSearch;
