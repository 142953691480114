import React from 'react';
import styled from 'styled-components';

import NewDialog from 'design-system/organisms/NewDialog/index';

import theme from 'design-system/theme';
import { PAGE_HEADER_HEIGHT } from 'constants/common';

import Actions from './Actions';
import Header from './Header';
import { metadataKeys } from 'utils/metadata/metadataKeys';
import { DRAWER_FOOTER_HEIGHT, DRAWER_ACTION_BAR } from './consts/layout';

const DIALOG_TYPE = 'DRAWER';

// -- just for old ag grids --
// this is all the content that must be considered for the body height calculation
// const DRAWER_HEADER_HEIGHT = 78;
// const TOTAL_HEADERS_HEIGHT = PAGE_HEADER_HEIGHT + DRAWER_HEADER_HEIGHT;
const TOTAL_BARS_HEIGHT = PAGE_HEADER_HEIGHT + DRAWER_FOOTER_HEIGHT + DRAWER_ACTION_BAR;

const PADDING_OVERRIDE = '0 24px';

const DEFAULT_WIDTH = '900px';
const INCREASED_WIDTH = '1100px';
const METADATAS_WITH_INCREASED_WIDTH = [metadataKeys.metric];

const getDrawerWidth = metadata => (METADATAS_WITH_INCREASED_WIDTH.includes(metadata) ? INCREASED_WIDTH : DEFAULT_WIDTH);

const MetadataDrawerComponent = ({
  isOpen,
  onClose,
  metadataSelected,
  contentComponent,
  onSettingsLinkClicked,
  oldLightboxActive,
  metadataSelectedNotes,
  updateNote,
  isAllowedToWriteMetadataNotes,
}) => {
  return (
    <>
      <StyledDialog
        open={!!isOpen}
        type={DIALOG_TYPE}
        paddingOverride={PADDING_OVERRIDE}
        headerComponent={Header}
        headerProps={{
          onClose,
          metadataSelected,
          metadataSelectedNotes,
          updateNote,
          isAllowedToWriteMetadataNotes,
          height: PAGE_HEADER_HEIGHT,
        }}
        actions={
          <Actions onClose={onClose} onSettingsLinkClicked={onSettingsLinkClicked} oldLightboxActive={oldLightboxActive} />
        }
        onClose={onClose}
        fullHeight
        metadataSelected={metadataSelected}
      >
        {contentComponent && contentComponent({ height: `calc(100vh - ${TOTAL_BARS_HEIGHT}px`, actionsPadding: '5px 0' })}
      </StyledDialog>
    </>
  );
};

export default MetadataDrawerComponent;

const StyledDialog = styled(NewDialog)`
  &&&& {
    z-index: ${theme.zIndex.metadataDrawer};

    > div[role='document'] {
      width: 100%;

      @media only screen and (min-width: calc(900px + 200px)) {
        width: ${({ metadataSelected }) => getDrawerWidth(metadataSelected)};
      }

      // popover body div
      > div:nth-child(2) {
        overflow: hidden;
      }

      div[role='grid'] {
        margin: 0;
      }
    }
  }
`;
