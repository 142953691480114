import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { BASE_ROW_HEIGHT, SHOW_UNCOMMITTED_PROJECTS } from 'constants/grid';
import { FORECAST_BY_HEADCOUNT } from 'constants/forecast';

import { BaseGrid } from 'containers/Grids';

import PageLoading from 'design-system/atoms/PageLoading/PageLoading';
import { useCommonGridProps, useCommonGridEventHandlers } from 'design-system/molecules/AgGridReact-New/hooks';
import {
  sumNumericFields,
  sumScopeVarianceField,
  sumPointsVarianceField,
} from 'design-system/molecules/AgGridReact-New/aggFuncs';

import useLoginInfo from 'hooks/useLoginInfo';
import usePageFilters from 'hooks/filters/usePageFilters';
import useOrganizations from 'hooks/useOrganizations';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useForecastGrid from './hooks/useForecastGrid';
import useEstimatesColumnsDefinitions from './hooks/useEstimatesColumnsDefinitions';
import useIdeasGridRowDrag from '../IdeasList/IdeasList/New/hooks/useIdeasGridRowDrag';
import useAdditionalMetadataDropdownOptions from 'hooks/metadata/useAdditionalMetadataDropdownOptions';

import { getDataPath, getRowId, checkRowSelectable } from '../IdeasList/IdeasList/New/helpers';
import { useMetadata, useProjectsOnGrids, useGridState, useSystemFields } from '../IdeasList/IdeasList/New/hooks';

import { INCLUDE_ALL_OPTION } from 'constants/projects';
import { ID_KEY, GRID_HEIGHT } from '../IdeasList/IdeasList/New/constants';

import { sumAggSkills } from './helpers';
import rowClassRules from './helpers/rowClassRules';
import { getGridConfigValue } from 'store/grids/selectors';
import { forecastStyles } from './styled';

const IdeasForecastList = ({ viewType, data, onGridReady: parentHandleGridReady, isCreatingOrViewingScenario }) => {
  const { getSystemFieldName } = useSystemFields();

  const { displayLayer, searchString: pageFiltersSearchString, pageFilters } = usePageFilters();

  /*
   * Forecast List does not support portfolio mode, so will be forced
   * to be always false
   */
  const portfolioMode = false;

  const { currentUser } = useLoginInfo();

  const {
    organization,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    filters: { topLayer },
    customUserFields,
    integrations,
    customFields,
    hasMetadataMultiSelect,
  } = useOrganizations();

  const { isParentDragon } = useOrganizationsAccessControl();
  const showUncommittedProjects = useSelector(state =>
    getGridConfigValue(state, viewType, SHOW_UNCOMMITTED_PROJECTS, INCLUDE_ALL_OPTION),
  );

  const { metadata, filteredMetadata, checkUserCanCreateNewMetadata, createMetadataMultiSelectOption } = useMetadata(data, {
    currentUser,
    organization,
  });

  const additionalMetadataOptions = useAdditionalMetadataDropdownOptions();

  const {
    hasUnsavedProject,
    defaultPhase,

    updateProjectById,
    createUnsavedProject,
    removeUnsavedProjects,
    createNewProject,
    openProjectInfo,
    openOkrInfo,
    loadParentProjects,
    switchProjectRowOrder,
  } = useProjectsOnGrids();

  const {
    rowHeight,
    expandedGroups,
    isGrouping,
    searchString,
    isBulkDeleting,
    visibleColumnsIds,
    columnsState,
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    shouldGridBeUnmounted,

    saveGridConfig,
    setSelectedItems,
    saveColumnsState,
    removeGridRow,
    setDirtyViewMutex,
  } = useGridState(viewType);

  const {
    processedData,
    forecastBy,

    getGridIsReady,
    getGridApi,

    handleGridReady,
    handleRowGroupOpened,
    handleSelectionChanged,
    handleDisplayedColumnsChanged,
    handleColumnResized,
    checkIsGroupOpenByDefault,
    addNewInlineProject,
    openRowInfo,
    updateProjectByIdOnCellValueChange,
    loadParentProjectsOptions,
    handleMetadataCellDoubleClick,
  } = useForecastGrid(data, {
    parentHandleGridReady,
    displayLayer,
    portfolioMode,
    currentUser,
    hasBet,
    defaultPhase,
    expandedGroups,
    viewType,
    metadata,
    selectedGroup2,
    selectedGroup1,
    columnsState,
    shouldGridBeUnmounted,

    saveGridConfig,
    getSystemFieldName,
    createUnsavedProject,
    openProjectInfo,
    openOkrInfo,
    setSelectedItems,
    updateProjectById,
    loadParentProjects,
    saveColumnsState,
  });

  const { columnDefs, defaultColDef, groupColumnDef } = useEstimatesColumnsDefinitions({
    viewType,
    portfolioMode,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    topLayer,
    displayLayer,
    rowHeight,
    metadata,
    filteredMetadata,
    visibleColumnsIds,
    titleSearchString: searchString || pageFiltersSearchString,
    customUserFields,
    columnsState,
    processedData,
    integrations,
    currentUser,
    forecastBy,
    customFields,
    hasMetadataMultiSelect,
    isBulkDeleting,

    getSystemFieldName,
    addNewInlineProject,
    openProjectInfo,
    openRowInfo,
    loadParentProjectsOptions,
    checkUserCanCreateNewMetadata,
    updateProjectById,
    handleMetadataCellDoubleClick,
    removeGridRow,
    createMetadataMultiSelectOption,
  });

  const isForecastByHeadcountSelected = forecastBy === FORECAST_BY_HEADCOUNT;

  const commonGridProps = useCommonGridProps({
    isTreeView: true,
    aggFuncs: { sumNumericFields, sumScopeVarianceField, sumAggSkills, sumPointsVarianceField },
  });

  const { handleRowDrag } = useIdeasGridRowDrag({
    selectedGroup1,
    selectedGroup2,
    selectedGroup3,
    switchProjectRowOrder,
    updateProjectById,
  });

  const commonGridEvents = useCommonGridEventHandlers({
    items: processedData,
    idKey: ID_KEY,
    actions: {
      update: updateProjectByIdOnCellValueChange,
      save: createNewProject,
      remove: removeUnsavedProjects,
      drag: handleRowDrag,
      sortChanged: handleDisplayedColumnsChanged,
    },
  });

  const gridContext = useMemo(
    () => ({
      hasUnsavedProject,
      isParentDragon,
      portfolioMode,
      isGrouping,
      isBulkDeleting,
      processedData,
      forecastBy,
      parents: metadata.parents,
      isCreatingOrViewingScenario,
      showUncommittedProjects,
      isForecastByHeadcountSelected,
      metadata,
      filteredMetadata,
      additionalMetadataOptions,
      selectedGroup1,
      selectedGroup2,
      pageFilters,
    }),
    [
      hasUnsavedProject,
      isParentDragon,
      portfolioMode,
      isGrouping,
      isBulkDeleting,
      processedData,
      forecastBy,
      isCreatingOrViewingScenario,
      showUncommittedProjects,
      isForecastByHeadcountSelected,
      metadata,
      filteredMetadata,
      additionalMetadataOptions,
      selectedGroup1,
      selectedGroup2,
      pageFilters,
    ],
  );

  useEffect(() => {
    if (getGridIsReady()) {
      getGridApi().resetRowHeights();
    }
  }, [rowHeight]);

  useEffect(() => {
    // We need to refresh Header in order to see the checkbox when bulk updating
    if (getGridIsReady()) {
      getGridApi().refreshHeader();
    }

    // Cleanup selected items if stopped bulk deleting.
    if (!isBulkDeleting && getGridIsReady()) {
      getGridApi().deselectAll();
    }
  }, [isBulkDeleting]);

  useEffect(() => {
    /*
     * On change the creating or viewing scenario flag will change the hedaders with
     * a different color, this will force to rerender the header cells to get the new
     * color info
     */
    if (getGridIsReady()) {
      getGridApi().refreshHeader();
    }
  }, [isCreatingOrViewingScenario]);

  useEffect(() => {
    if (getGridIsReady()) {
      getGridApi().redrawRows();
    }
  }, [showUncommittedProjects]);

  if (shouldGridBeUnmounted) {
    return <PageLoading />;
  }

  return (
    <BaseGrid
      // Common props:
      {...commonGridProps}
      {...commonGridEvents}
      // General props:
      onGridReady={handleGridReady}
      onFirstDataRendered={setDirtyViewMutex}
      rowData={processedData}
      context={gridContext}
      isGroupOpenByDefault={checkIsGroupOpenByDefault}
      isRowSelectable={checkRowSelectable}
      rowSelection="multiple"
      suppressRowClickSelection
      // Getters/Accessors:
      getRowId={getRowId}
      getDataPath={getDataPath}
      // Styling:
      cssStyles={forecastStyles}
      height={GRID_HEIGHT}
      rowHeight={rowHeight}
      headerHeight={BASE_ROW_HEIGHT}
      rowClassRules={rowClassRules}
      // Columns:
      defaultColDef={defaultColDef}
      autoGroupColumnDef={groupColumnDef}
      columnDefs={columnDefs}
      // Events:
      onRowGroupOpened={handleRowGroupOpened}
      onSelectionChanged={handleSelectionChanged}
      onDisplayedColumnsChanged={handleDisplayedColumnsChanged}
      onColumnResized={handleColumnResized}
    />
  );
};

export default IdeasForecastList;
