import React from 'react';
import styled, { css } from 'styled-components';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RefreshIcon from '@material-ui/icons/Cached';

import Autocomplete from 'design-system/atoms/Autocomplete/index';
import InlineAutoCompleteInput from 'design-system/atoms/InlineAutoCompleteInput/index';
import InlineDateRange from 'design-system/organisms/InlineDateRange/InlineDateRange';
import Dropdown from 'design-system/molecules/Dropdown/index';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import theme, { spacing } from 'design-system/theme';
import { brandColor } from 'design-system/themes/default';

import ShareView from 'containers/ShareView';

const AUTOCOMPLETE_ID = 'time-unit-selector';

const MONTH_OPTION_KEY = 'month';
const MONTH_OPTION_TITLE = 'Month';
const QUARTER_OPTION_KEY = 'quarter';
const QUARTER_OPTION_TITLE = 'Quarter';

const MONTH_OPTION = { value: MONTH_OPTION_KEY, title: MONTH_OPTION_TITLE };
const QUARTER_OPTION = { value: QUARTER_OPTION_KEY, title: QUARTER_OPTION_TITLE };

const mapTimeUnitKeyToTitle = {
  [MONTH_OPTION_KEY]: MONTH_OPTION_TITLE,
  [QUARTER_OPTION_KEY]: QUARTER_OPTION_TITLE,
};

const TIME_UNIT_SUGGESTIONS = [MONTH_OPTION, QUARTER_OPTION];

const MIN_DATE = '2024-01-01';

const inlineDateRangeCss = css`
  flex: 0 1 auto;
`;

const TimeUnitSelector = ({ onValueChange, selectedValue, disabled }) => {
  return (
    <TimeUnitSelectorWrapper id={AUTOCOMPLETE_ID}>
      <Autocomplete
        suggestions={TIME_UNIT_SUGGESTIONS}
        value={mapTimeUnitKeyToTitle[selectedValue] ?? MONTH_OPTION_TITLE}
        onValueChange={onValueChange}
        inputProps={{
          style: {
            fontSize: theme.typography.fontSize,
            color: brandColor,
          },
          containerStyles: {
            maxWidth: 240,
          },
          'data-testid': AUTOCOMPLETE_ID,
        }}
        icon={<StyledArrowDownIcon />}
        iconStyle={{ padding: 0, width: 'auto' }}
        inputComponent={InlineAutoCompleteInput}
        highlightOnlyFullMatch
        disabled={disabled}
      />
    </TimeUnitSelectorWrapper>
  );
};

const ActionsToolbar = React.memo(
  ({
    pageId,
    dropdownOptions = [],
    startDate,
    endDate,
    handleDateRangeChange,
    timeUnitSelected,
    handleTimeUnitChange,
    canRecalculate,
    alreadyRecalculated,
    onClickRecalculate,
    pageTitleRenderer,
    shouldDisplayControlsBar,
  }) => {
    return (
      <ControlsContainer>
        <Flex justifyContent="space-between">
          <DateContainer flex="1" gap={`${spacing()}px`}>
            <InlineDateRange
              label="Time Period:"
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateRangeChange}
              views={['year', 'month']}
              minDate={MIN_DATE}
              wrapperCss={inlineDateRangeCss}
              disableEdit={!shouldDisplayControlsBar}
            />
            <TimeUnitSelector
              selectedValue={timeUnitSelected}
              onValueChange={handleTimeUnitChange}
              disabled={!shouldDisplayControlsBar}
            />
          </DateContainer>
          {pageTitleRenderer && <MidContainer flex="1">{pageTitleRenderer()}</MidContainer>}
          <Flex justifyContent="flex-end" gap={`${spacing(0.5)}px`} flex="1">
            {shouldDisplayControlsBar && (
              <ActionButtonsContainer>
                {canRecalculate && (
                  <ButtonIcon
                    disabled={alreadyRecalculated}
                    variant="primary"
                    onClick={() => {
                      if (onClickRecalculate) onClickRecalculate();
                    }}
                    title="Recalculate last 3 months"
                  >
                    <RefreshIcon style={{ fontSize: 20 }} />
                  </ButtonIcon>
                )}
                <ShareView pageId={pageId} />

                <MoreDropdownContainer>
                  <Dropdown
                    placeholder={
                      <ButtonIcon>
                        <MoreHorizIcon />
                      </ButtonIcon>
                    }
                    options={dropdownOptions}
                    isButton={false}
                  />
                </MoreDropdownContainer>
              </ActionButtonsContainer>
            )}
          </Flex>
        </Flex>
      </ControlsContainer>
    );
  },
);

export default ActionsToolbar;

const ControlsContainer = styled.div`
  margin: ${spacing(0.5)}px auto;
`;

const FlexStyle = css`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  gap: ${spacing()}px;

  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}

  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `}
`;

const DateContainer = styled.div`
  ${FlexStyle};
  flex-wrap: wrap;
`;

const MidContainer = styled.div`
  ${FlexStyle};
  justify-content: center;

  @media screen and (min-width: 1300px) {
    flex: none;
  }
`;
const Flex = styled.div`
  ${FlexStyle};
`;

const MoreDropdownContainer = styled.div``;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(0.5)}px;
`;

const TimeUnitSelectorWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

const StyledArrowDownIcon = styled(KeyboardArrowDownIcon)`
  cursor: pointer;

  && {
    fill: ${({ theme }) => theme.palette.newLayout.text.siroccoGrey};
    font-size: 20px;
  }
`;
