import { defaultTo } from 'ramda';

const defaultAsEmptyArray = defaultTo([]);

/**
 * Retrieves an array of column IDs from the provided Ag-Grid column state.
 *
 * @param {Array<object>} columnState - An array representing the Ag-Grid column state.
 * @returns {Array<string>} An array of column IDs extracted from the column state.
 */
const getAllColumnsFromAgGridColumnState = columnState => {
  return defaultAsEmptyArray(columnState).map(c => c.colId);
};

export default getAllColumnsFromAgGridColumnState;
