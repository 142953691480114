import axios from 'axios';

export default slug => {
  if (!slug) {
    [slug] = window.location.hostname.split('.');
  }

  if (slug === 'app') {
    if (URLSearchParams) slug = new URLSearchParams(window.location.search).get('workspace');
  }

  if (slug) {
    axios.defaults.headers.common['x-organization-slug'] = slug;
  }

  // if (!slug) {
  //   window.location = 'https://app.lvh.me/login';
  // }
};
