import styled from 'styled-components';

import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';

export const IllustrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${spacing(6)}px;
`;

export const StyledText = styled(Text)`
  font-size: ${({ theme }) => theme.typography.fontSizeLargeRem}rem;
  text-align: center;
  margin: 0 auto;

  b {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }
`;
