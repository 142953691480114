import React, { useState } from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import SearchInput from '../SearchInput';

export default ({ searchQuery = '', searchPlaceholder, handleSearch, closeDialog }) => {
  const [query, setQuery] = useState(searchQuery);

  const handleChange = val => {
    setQuery(val);
    handleSearch(val);
  };

  return (
    <Wrapper>
      <StyledSearchInput query={query} placeholder={searchPlaceholder} handleChange={handleChange} />
      {closeDialog ? (
        <ButtonIcon onClick={closeDialog}>
          <StyledCloseIcon />
        </ButtonIcon>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px 12px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  && {
    font-size: ${({ theme }) => theme.typography.fontSizeMedium}px;
    color: ${({ theme }) => theme.palette.background.dimGray};
  }
`;

const StyledSearchInput = styled(SearchInput)`
  width: 90%;
  margin-left: 22px;
`;
