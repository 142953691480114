import { path, propEq } from 'ramda';

const DATA = 'data';
const MENTION = 'mention';
const TYPE = 'type';

const isTypeMention = propEq(TYPE, MENTION);
const getMention = path([DATA, MENTION]);

/**
 * @function getEditorMentions
 *
 * Extracts user mentions from the editor state.
 * The editor state should be provided in raw format.
 *
 * @param {Object} editorState
 * @param {Object} editorState.entityMap
 * @returns {Array}
 */
export const getEditorMentions = (editorState = {}) => {
  const { entityMap = {} } = editorState;

  const mentions = Object.entries(entityMap)
    .filter(([_, entity]) => isTypeMention(entity))
    .map(([_, entity]) => getMention(entity));

  return mentions;
};
