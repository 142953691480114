import { isNil } from 'ramda';

const getRoundedValue = (val, selectedRounding) => {
  if (isNil(val)) {
    return 0;
  }
  return selectedRounding && selectedRounding.key === 'decimal' ? +parseFloat(val).toFixed(2) : Math.round(val);
};

export default getRoundedValue;
