import { isEmpty } from 'ramda';

/**
 * @function assignJirasToProjects creates a new list of project with the respective Jiras data
 * @param  {Array} projects List of projects
 * @param  {Array} jiras  List of jira integrations
 * @return {Array} List of projects with new Jiras field
 */
const assignJirasToProjects = (projects, jiras = []) => {
  if (isEmpty(jiras)) {
    return projects;
  }
  return projects.map(project => {
    const jirasForProject = jiras.filter(data => project.id === data.project_id);

    return {
      ...project,
      Jiras: jirasForProject,
    };
  });
};

export default assignJirasToProjects;
