import { stringComparator } from '../comparators';
import { WYSIWYGCellRenderer } from '../cellRenderers';
import { returnsTrueIfKeyIsNotEscOrTab } from 'design-system/molecules/AgGridReact-New/helpers';
import { RemirrorWYSIWYGCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';

const remirrorColumnDef = {
  suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
  cellRenderer: WYSIWYGCellRenderer,
  cellEditor: RemirrorWYSIWYGCellEditor,
  cellEditorParams: {
    maxHeight: 150,
    autoFocus: true,
  },
  width: 450,
  minWidth: 200,
  comparator: stringComparator,
  cellEditorPopup: true,
};

export default remirrorColumnDef;
