import { useCallback, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { pipe, propEq, both, defaultTo, not } from 'ramda';

import { KEY_RESULT_1_LEVEL, KEY_RESULT_2_LEVEL } from 'constants/objectives';

import {
  addKeyResultWithoutSave as addKeyResultWithoutSaveAction,
  createKeyResult as createKeyResultAction,
  removeUnsavedObjectivesAndKeyResults,
  openObjectiveDrawer as openObjectiveDrawerAction,
  createObjective,
  addObjectiveWithoutSave,
} from 'store/objectives/actions';
import {
  fetchObjectives,
  switchObjectivesRowOrder,
  switchKeyResultsRowOrder,
  moveKeyResultToObjective,
  moveObjectiveToObjective,
} from 'store/objectives/thunks';
import { OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2, OBJECT_OBJECTIVE } from 'store/objectives/types';

import { getObjectiveTypeByLevel } from 'utils/objectives';
import sortByRank from 'utils/sortByRank';
import { METADATA_LEVELS } from 'constants/common';
import { useInitAgGrid } from 'design-system/molecules/AgGridReact-New/hooks';

const defaultToEmptyObject = defaultTo({});
const isTypeObjective = pipe(defaultToEmptyObject, propEq('type', OBJECT_OBJECTIVE));
const isLevelCorp = pipe(defaultToEmptyObject, propEq('level', METADATA_LEVELS.LEVEL_CORP));
const isBothObjectiveAndLevelCorp = both(isTypeObjective, isLevelCorp);

const useSnapshotOkrTableGrid = (
  items,
  { hasKeyResults2, isTreeView, hasCorpLevel = false, onGridReady: parentHandleGridReady },
) => {
  const dispatch = useDispatch();

  const { initAgGrid } = useInitAgGrid();

  const allowedLevel = hasKeyResults2 ? KEY_RESULT_2_LEVEL : KEY_RESULT_1_LEVEL;

  const processedItems = useMemo(
    // Add path to the items, even if there is no tree view.
    // This is needed because we cannot change Grid modes (tree view vs not tree view)
    // without tearing down the grid. So the solution applied here is to
    // pretend there is always a tree view, even if it's just one level.
    () => {
      return items
        .map(item => ({
          ...item,
          path: isTreeView ? item.path : [item.id],
        }))
        .sort(sortByRank);
    },
    [items, isTreeView],
  );

  const {
    addKeyResultWithoutSaveAction: addKeyResultWithoutSaveBoundAction,
    createKeyResultAction: createKeyResultBoundAction,
    addObjectiveWithoutSave: addObjectiveWithoutSaveBoundAction,
    createObjective: createObjectiveBoundAction,
    ...otherBoundActionCreators
  } = useMemo(() => {
    return bindActionCreators(
      {
        addKeyResultWithoutSaveAction,
        createKeyResultAction,
        fetchObjectives,
        switchObjectivesRowOrder,
        switchKeyResultsRowOrder,
        moveKeyResultToObjective,
        addObjectiveWithoutSave,
        createObjective,
        moveObjectiveToObjective,
      },
      dispatch,
    );
  }, [dispatch]);

  const handleAddKeyResultWithoutSave = useCallback(
    parentData => {
      if (parentData.type === OBJECT_KEY_RESULT) {
        return addKeyResultWithoutSaveBoundAction(parentData, true, KEY_RESULT_2_LEVEL);
      }

      return addKeyResultWithoutSaveBoundAction(parentData);
    },
    [addKeyResultWithoutSaveBoundAction],
  );

  const handleAddObjectiveWithoutSave = useCallback(
    parentData => {
      if (not(hasCorpLevel) || isTypeObjective(parentData)) {
        return addObjectiveWithoutSaveBoundAction(parentData, METADATA_LEVELS.LEVEL_1);
      }

      return addObjectiveWithoutSaveBoundAction(parentData, METADATA_LEVELS.LEVEL_CORP);
    },
    [addObjectiveWithoutSaveBoundAction, hasCorpLevel],
  );

  const handleAddOKRWithoutSave = useCallback(
    (data, { node }) => {
      if (!node.expanded) {
        node.setExpanded(true);
      }

      return isBothObjectiveAndLevelCorp(data) ? handleAddObjectiveWithoutSave(data) : handleAddKeyResultWithoutSave(data);
    },
    [handleAddKeyResultWithoutSave, handleAddObjectiveWithoutSave],
  );

  const create = useCallback(
    data => {
      if ([OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2].includes(data.type)) {
        return createKeyResultBoundAction(data);
      }

      return createObjectiveBoundAction(data);
    },
    [createObjectiveBoundAction, createKeyResultBoundAction],
  );

  const removeUnsaved = useCallback(() => {
    return dispatch(removeUnsavedObjectivesAndKeyResults());
  }, [dispatch]);

  const openObjectiveDrawer = useCallback(
    okr => {
      const okrType = getObjectiveTypeByLevel(okr);

      return dispatch(openObjectiveDrawerAction(okr.id, okrType));
    },
    [dispatch],
  );

  const handleGridReady = useCallback(
    params => {
      initAgGrid(params.api, params.columnApi);

      if (parentHandleGridReady) {
        parentHandleGridReady(params);
      }
    },
    [initAgGrid, parentHandleGridReady],
  );

  return {
    handleGridReady,
    processedItems,
    allowedLevel,
    handleAddKeyResultWithoutSave,
    create,
    removeUnsaved,
    openObjectiveDrawer,
    handleAddOKRWithoutSave,
    ...otherBoundActionCreators,
  };
};

export default useSnapshotOkrTableGrid;
