import React from 'react';

import ToggleButton from 'design-system/molecules/ToggleButton';
import PatientListItem from 'design-system/atoms/PatientListItem';

const ShowMyItemsToggle = ({ showMyItemsOnly, updateShowMyItemsOnly, showMyItemsTitle = 'Show my items only' }) => {
  return (
    <ToggleButton
      id="my_items"
      data-testid="button-filter-my-items"
      on={showMyItemsOnly}
      icon={<PatientListItem />}
      title={showMyItemsTitle}
      onChange={updateShowMyItemsOnly}
    />
  );
};

export default ShowMyItemsToggle;
