import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Dialog from 'design-system/molecules/Dialog/index';
import Checkbox from 'design-system/atoms/Checkbox/index';
import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';
import ProjectType from 'containers/ProjectType';
import { IDEA_LAYER } from 'store/projects/constants';

const getOptions = isIdeaLayer => [
  ...(isIdeaLayer ? [] : [{ key: 'children', label: 'Immediate Sub items' }]),
  { key: 'estimates', label: 'Estimates' },
];

const dialogContentStyles = css`
  padding: 0;
`;

const CloneDialog = ({ isOpen, onClone, onClose, project }) => {
  const [selectedOptions, setSelectedOptions] = useState({});

  const options = getOptions(project?.layer === IDEA_LAYER);

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;

    setSelectedOptions(prevOptions => ({ ...prevOptions, [name]: checked }));
  };

  const handleClickClone = () => {
    onClone(selectedOptions);
    onClose();
  };

  if (!project) return null;

  return (
    <Dialog open={!!isOpen} onClose={onClose} contentCustomStyles={dialogContentStyles} closeButton>
      <StyledDialogTitle>
        Clone <ProjectType layer={project.layer} />
        {project.title}
      </StyledDialogTitle>
      <Content>
        <Text>Also clone</Text>
        <OptionsContainer>
          {options.map(option => (
            <StyledFormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={selectedOptions?.[option.key]}
                  name={option.key}
                  onChange={handleCheckboxChange}
                />
              }
              label={option.label}
            />
          ))}
        </OptionsContainer>
      </Content>
      <StyledDialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleClickClone} color="primary">
          Clone
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default CloneDialog;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    padding-right: ${spacing(7)}px;
  }
`;

const Content = styled.div`
  min-width: 500px;
  padding: ${spacing()}px ${spacing(3)}px;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  > span {
    padding-top: ${spacing()}px;
    padding-bottom: ${spacing()}px;
    line-height: ${({ theme }) => theme.typography.lineHeightSmallRem}rem;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing()}px;
`;

const StyledDialogActions = styled(DialogActions)`
  && {
    margin: ${spacing()}px ${spacing(0.5)}px;
  }
`;
