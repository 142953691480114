import React, { useMemo } from 'react';
import { defaultTo } from 'ramda';

import ProjectType from 'containers/ProjectType';
import { BaseTitle } from 'design-system/molecules/AgGridReact-New/cellRenderers/TitleCellRenderer';

const defaultToEmptyArray = defaultTo([]);

const ParentCellRenderer = ({ data, getNumberOfTextRowsToDisplayOnGridCell, context }) => {
  const { parent: parentName } = data;
  const { parents } = context;

  /*
   * As the field is the parent when the date has the update on the parentLayer will not trigger the
   * render on this cell render, for that reason is getting the parent layer from parents context
   * that contains all the entities that if fetched by the client inluding also the automcomplete
   * projects
   */
  const parentProject = useMemo(() => parentName && defaultToEmptyArray(parents).find(p => p.title === parentName), [
    parentName,
    parents,
  ]);
  const parentLayer = parentName ? parentProject?.layer || data.parentLayer : null;

  const icon = useMemo(() => {
    return <ProjectType layer={parentLayer} />;
  }, [parentLayer]);

  return (
    <BaseTitle icon={icon} getNumberOfTextRowsToDisplayOnGridCell={getNumberOfTextRowsToDisplayOnGridCell}>
      {parentName}
    </BaseTitle>
  );
};

export default ParentCellRenderer;
