import { isLoading } from 'utils/store/thunk';

import { IMPORT_METRIC_VALUES } from './types';

// Slice state
export const getState = state => state.oneStepIntegration;

// Operations
export const selectOperationsState = state => getState(state).operations || {};

export const selectIsImportingMetricValues = state => isLoading(selectOperationsState(state), IMPORT_METRIC_VALUES);
