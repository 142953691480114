import React from 'react';
import styled from 'styled-components';

export default ({ title, handleCancel, handleContinue }) => {
  return (
    /* eslint-disable */
    (<div>
      <h3>
        {title}
        <Buttons>
          <a onClick={() => handleCancel()}>CANCEL</a>
          <a onClick={() => handleContinue()}>CONTINUE</a>
        </Buttons>
      </h3>
    </div>)
    /* eslint-disable */
  );
};

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 8px 0;
`;
