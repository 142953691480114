import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import FluidTextField from 'design-system/organisms/FluidTextField/index';

import useUpdateSystemFields from 'hooks/useUpdateSystemFields';

const RowHeadingStyles = css`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  line-height: ${({ theme }) => theme.typography.lineHeightRegularSmallRem}rem;
`;

const ColumnHeadingStyles = css`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  line-height: ${({ theme }) => theme.typography.lineHeightSmallLargeRem}rem;
`;

const StyledFluidTextField = styled(FluidTextField)`
  &&&& {
    display: flex;
    min-height: unset;
    padding: 0;

    textarea {
      ${({ isColumnHeader }) => (isColumnHeader ? ColumnHeadingStyles : RowHeadingStyles)}
    }
  }
`;

const EditableHeader = ({ title, dataId, dataKey, editingHeight, isColumnHeader, handleFocus, handleBlur }) => {
  const { makeUpdater } = useUpdateSystemFields();

  const update = useCallback(makeUpdater(dataKey), [makeUpdater, dataKey]);

  const saveChange = useCallback(
    newValue => {
      if (title !== newValue) {
        update(dataId, {
          [dataKey === 'customers' ? 'name' : 'title']: newValue,
        });
      }
    },
    [update, dataKey, dataId, title],
  );

  return (
    <StyledFluidTextField
      value={title}
      onChange={saveChange}
      InputProps={{ multiline: true, rowsMax: 3, onFocus: handleFocus, onBlur: handleBlur }}
      displayBorder
      editingHeight={editingHeight}
      isColumnHeader={isColumnHeader}
    />
  );
};

export default EditableHeader;
