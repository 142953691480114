import React from 'react';
import styled from 'styled-components';

import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import { spacing } from 'design-system/theme';

const VisibilityButtonIcon = ({ onClick }) => (
  <StyledButtonIcon onClick={onClick}>
    <VisibilityIcon />
  </StyledButtonIcon>
);

export default VisibilityButtonIcon;

export const StyledButtonIcon = styled(ButtonIcon)`
  &&&& {
    color: ${({ theme }) => theme.palette.text.secondary};
    padding: ${spacing(0.5)}px;

    svg {
      font-size: ${({ theme }) => theme.sizing.icons.small}rem;
    }
  }
`;
