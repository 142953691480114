import axios from 'axios';

import React, { PureComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import { StripeProvider } from 'react-stripe-elements';

import styled from 'styled-components';

import SubscriptionInfo from './SubscriptionInfo';
import AddUsers from './AddUsers';
import Receipts from './Receipts';
import { ADMIN_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER, MANAGER_USER, EDITOR_USER } from '@dragonboat/permissions';
import { getWelcomeRoute } from 'utils';

class Billing extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { firstLoad: true, loading: true, prices: null };
  }

  getPrices = async () => {
    try {
      const res = await axios.get('/api/billing/prices');

      this.setState({ ...(this.state || {}), prices: res.data });
    } catch (err) {
      // pass
    }
  };

  updateBillingInfo = async () => {
    this.setState({ loading: true });
    try {
      const res = await axios.get('/api/billing');

      this.setState({ billingInfo: { ...res.data }, loading: false, firstLoad: false });

      // if (res.data.billing_status === 'paid') {
      //   // this.setState({ hasBillingInfo: true, ...this.getPlanTypeFromServer(res.plan_type), editors: res.num_seats || 1 });
      // }
    } catch (err) {
      this.setState({ loading: false, firstLoad: false });
    }
  };

  componentDidMount = async () => {
    this.getPrices();
    this.updateBillingInfo();
  };

  sendMessage = async message => {
    await axios.post('/api/billing/message', {
      message,
    });

    return new Promise((res, rej) => {
      setTimeout(() => {
        res();
      }, 3000);
    });
  };

  render() {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_FRONTEND_KEY}>
        <Wrapper container>
          <Grid item xs={12}>
            <Route
              path="/settings/billing/info"
              component={props => (
                <SubscriptionInfo
                  toggleMessage={this.toggleMessage}
                  billingInfo={this.state.billingInfo}
                  firstLoad={this.state.firstLoad}
                  handleChangeMessage={this.handleChangeMessage}
                  message={this.state.message}
                  messageOpen={this.state.messageOpen}
                  messageSuccess={this.state.messageSuccess}
                  sendMessage={this.sendMessage}
                  currentUser={this.props.currentUser}
                  updateBillingInfo={this.updateBillingInfo}
                  prices={this.state.prices}
                />
              )}
            />
            {this.state.billingInfo && (
              <React.Fragment>
                <Route
                  path="/settings/billing/addusers"
                  component={props => (
                    <AddUsers
                      billingInfo={this.state.billingInfo}
                      loading={this.state.loading}
                      updateBillingInfo={this.updateBillingInfo}
                    />
                  )}
                />
                <Route
                  path="/settings/billing/receipts"
                  component={props => <Receipts billingInfo={this.state.billingInfo} loading={this.state.loading} />}
                />
              </React.Fragment>
            )}
            <Route
              path="/settings/billing"
              render={() => {
                const { currentUser } = this.props;

                if (
                  [ADMIN_USER, MANAGER_USER, EDITOR_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER].includes(currentUser.role_id)
                ) {
                  return <Redirect to={`/settings/billing/info${window.location.search}`} />;
                }

                return <Redirect to={getWelcomeRoute(currentUser)} />;
              }}
            />
          </Grid>
        </Wrapper>
      </StripeProvider>
    );
  }
}

const Wrapper = styled(Grid)`
  &&&& {
    position: fixed;
    height: 100%;
  }
`;

function mapStateToProps(state) {
  const {
    app: { stripeLoaded },
    login,
  } = state;

  return {
    stripeLoaded,
    currentUser: login.currentUser,
  };
}

export default compose(connect(mapStateToProps, {}))(Billing);
