export default (onConfirmed, updatedRows, fieldsToUpdate) => {
  const confirm = async () => {
    const hasRoleId = fieldsToUpdate.some(currentField => currentField.field === 'role_id');

    if (hasRoleId) fieldsToUpdate.push({ field: 'email' });

    const rows = updatedRows.map(item => {
      const update = fieldsToUpdate.reduce((final, current) => {
        if (current.field && current.field.indexOf('.') >= 0) {
          const parts = current.field.split('.');

          final[parts[0]] = item[parts[0]];
        } else if (current.field) {
          final[current.field] = item[current.field] || null;

          // if we are bulk updating progress it means the progress type is now manual
          const updatingProgress = current.field === 'progress';

          if (updatingProgress) final.progress_type = 'manual';
        }

        return final;
      }, {});

      return {
        id: item.id,
        ...update,
      };
    });

    await onConfirmed(rows);
  };

  return {
    confirm,
  };
};
