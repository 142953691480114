import { path } from 'ramda';

const mapAllocationDataToChart = (allocationData, dataKey) => {
  if (!allocationData || !allocationData.metadata) {
    return { data: {}, entities: [], stackEntities: [] };
  }

  return allocationData?.dates_formatted.reduce(
    (chartData, stack, dataIndex) => {
      const stackEntity = {
        id: allocationData?.dates[dataIndex],
        title: stack,
      };

      const stackData = {
        [stackEntity.id]: allocationData?.metadata.reduce((stackData, entity) => {
          return {
            ...stackData,
            [entity.id]: path(['completed_allocation', entity.id, dataKey, dataIndex])(allocationData),
          };
        }, {}),
      };

      return {
        ...chartData,
        data: {
          ...chartData.data,
          ...stackData,
        },
        stackEntities: [...chartData.stackEntities, stackEntity],
      };
    },
    { entities: allocationData?.metadata, data: {}, stackEntities: [] },
  );
};

export default mapAllocationDataToChart;
