import React from 'react';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useScenarioToolbarControls from 'containers/ScenarioToolbarControls/hooks/useScenarioToolbarControls';

const componentHOC = Component => {
  return props => {
    const { isParentDragon } = useOrganizationsAccessControl();

    const path = window.location.pathname;
    const { isCreatingOrViewingScenario } = useScenarioToolbarControls();

    const _setSelectedView = path => {
      props.history.push(path);
    };

    if (isCreatingOrViewingScenario) return null;

    return <Component selectedView={path} setSelectedView={_setSelectedView} isParentDragon={isParentDragon} {...props} />;
  };
};

export default componentHOC;
