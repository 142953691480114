import { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { getSkills } from 'store/skills/selectors';
import {
  createSkill,
  updateSkillById,
  mergeSkills,
  fetchSkills,
  removeUnsavedSkills,
  addSkillWithoutSave,
  bulkCreateSkills,
  bulkUpdateSkills,
  bulkDeleteSkills,
  switchSkillsRowOrder,
  deleteSkill,
} from 'store/skills';
import { getOrganization } from 'store/organization';

const useSkills = hideArchivedItems => {
  const dispatch = useDispatch();

  const organization = useSelector(getOrganization);
  const skills = useSelector(state => getSkills(state, !hideArchivedItems));

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          fetchSkills,
          createSkill,
          bulkCreateSkills,
          bulkUpdateSkills,
          bulkDeleteSkills,
          addSkillWithoutSave,
          removeUnsavedSkills,
          updateSkillById,
          deleteSkill,
          mergeSkills,
          switchSkillsRowOrder,
        },
        dispatch,
      ),
    [dispatch],
  );

  return {
    skills,
    systemFields: organization.system_fields_name,
    ...boundActionCreators,
  };
};

export default useSkills;
