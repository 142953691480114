import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import { spacing } from 'design-system/theme';
import useMetricsIntegrations from 'hooks/useMetricsIntegrations';
import usePermissions from 'hooks/permissions/usePermissions';
import FluidMetricDataSourceField from 'features/MetricsDialog/components/FluidMetricDataSourceField';

const DEFAULT_LABEL = 'Data Source';

const MetricDataSourcePopoverButton = ({ metric, label = DEFAULT_LABEL }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { canUpdate } = usePermissions();

  const canEditMetric = canUpdate(PERMISSION_RESOURCES.metric);

  const { canViewMetricIntegration, updateMetricIntegration } = useMetricsIntegrations();

  const updateMetricDataSource = updateMetricIntegration(metric?.id);

  if (!canViewMetricIntegration) {
    return null;
  }

  const metricIntegrationData = metric?.metricIntegrations?.[0];

  return (
    <>
      <Button color="primary" onClick={e => setAnchorEl(e.currentTarget)} disabled={false}>
        {label}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Wrapper>
          <FluidMetricDataSourceField
            value={metricIntegrationData?.external_url}
            integrationData={metricIntegrationData}
            onChange={updateMetricDataSource}
            disableEdit={!canEditMetric}
          />
        </Wrapper>
      </Popover>
    </>
  );
};

export default MetricDataSourcePopoverButton;

const Wrapper = styled.div`
  width: 600px;

  .saving-loader {
    top: ${spacing(0.5)}px;
  }
`;
