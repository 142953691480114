import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCycleDeliverables } from 'store/cycleDeliverables';
import {
  getAllCycles,
  isCycleDeliverablesUninitialized,
  isCycleDeliverablesLoading,
  getAllCycleDeliverables,
} from 'store/cycleDeliverables/selectors';

const useOrganizationCycleDeliverables = () => {
  const dispatch = useDispatch();
  const cycles = useSelector(getAllCycles);
  const allCycleDeliverables = useSelector(getAllCycleDeliverables);
  const isUninitialized = useSelector(isCycleDeliverablesUninitialized);
  const isLoading = useSelector(isCycleDeliverablesLoading);

  useEffect(() => {
    if (isUninitialized && !isLoading) {
      dispatch(fetchCycleDeliverables());
    }
  }, []);

  return { cycles, isLoading, allCycleDeliverables };
};

export default useOrganizationCycleDeliverables;
