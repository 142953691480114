import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentUser } from 'store/login/selectors';

import { getQueryParamFromUrl, addQueryParamToUrl, removeQueryParamFromUrl } from 'utils/queryParamsUtils';
import { METRIC_TAB_QUERY_PARAM, OPEN_METRIC } from 'constants/queryParams';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const CREATE_NEW_PARAM_VALUE = 'new';
const DETAILS_TAB = 'details';

const useMetricLightbox = () => {
  const urlOpenMetric = getQueryParamFromUrl(OPEN_METRIC);
  const currentUser = useSelector(getCurrentUser);
  const { canView } = usePermissions();
  const hasMetricLightboxDetails = canView(PERMISSION_FEATURES.metricLightboxDetails);

  const [selectedMetricId, setSelectedMetricId] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [lightboxProps, setLightboxProps] = useState({});

  const isMetricsDialogVisible = !!selectedMetricId || isCreating;

  const openMetricLightbox = (id, extraProps = {}, tab) => {
    setSelectedMetricId(id);
    setLightboxProps(extraProps);

    addQueryParamToUrl(OPEN_METRIC, id);
    hasMetricLightboxDetails && addQueryParamToUrl(METRIC_TAB_QUERY_PARAM, tab || DETAILS_TAB);
  };

  const closeMetricLightbox = () => {
    setSelectedMetricId(null);
    setIsCreating(false);
    setLightboxProps({});

    removeQueryParamFromUrl(OPEN_METRIC);
    hasMetricLightboxDetails && removeQueryParamFromUrl(METRIC_TAB_QUERY_PARAM);
  };

  const createMetricOnLightbox = (extraProps = {}) => {
    setLightboxProps(extraProps);

    addQueryParamToUrl(OPEN_METRIC, CREATE_NEW_PARAM_VALUE);
    addQueryParamToUrl(METRIC_TAB_QUERY_PARAM, DETAILS_TAB);
  };

  useEffect(() => {
    const isNewMetric = urlOpenMetric === CREATE_NEW_PARAM_VALUE;

    if (isNewMetric && hasMetricLightboxDetails) {
      setIsCreating(true);
      setSelectedMetricId(null);
    } else if (urlOpenMetric && !selectedMetricId) {
      setSelectedMetricId(Number(urlOpenMetric));
      setIsCreating(false);
    }
  }, [urlOpenMetric, selectedMetricId]);

  return {
    metricDialogProps: {
      currentUser,
      isOpen: isMetricsDialogVisible,
      onClose: closeMetricLightbox,
      isCreating,
      selectedMetricId,
      ...lightboxProps,
    },
    isMetricsDialogVisible,
    openMetricLightbox,
    createMetricOnLightbox,
  };
};

export default useMetricLightbox;
