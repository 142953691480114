/**
 * Sorts an array of objects alphabetically and numerically by their title property.
 *
 * @param {Array} arr - The array to be sorted. It should be an array of objects with the prop title.
 * @returns {Array} - The sorted array.
 */
const sortAlphaNumericallyByTitle = (arr = []) => {
  return arr.sort((a, b) => {
    return a.title.localeCompare(b.title, undefined, { numeric: true, sensitivity: 'base' });
  });
};

export default sortAlphaNumericallyByTitle;
