import { initialState } from 'features/whiteboard/store/reducer';
import { projectsSelector } from 'features/whiteboard/store/selectors';
import { APPLY_FILTERS_WHITEBOARD_FULFILLED, UPDATE_METADATA_ENTITIES_WHITEBOARD } from 'features/whiteboard/store/types';
import { timeframesAdapter } from 'features/whiteboard/store/helpers/adapters';
import { filterProjectsTimeframes } from 'features/whiteboard/store/helpers/entitiesFilters';

import { pickTimeframeFields } from 'features/Canvas/helpers/fieldPickers';
import {
  DELETE_TIMEFRAME_BY_ID_FULFILLED,
  UPDATE_TIMEFRAME_BY_ID_FULFILLED,
  UPDATE_TIMEFRAME_FULFILLED,
} from 'store/timeframes/types';

const processTimeframes = (timeframes, projects) => filterProjectsTimeframes(timeframes, projects).map(pickTimeframeFields);

const timeframesReducer = (state = initialState, action) => {
  const { payload } = action;
  const storeProjects = projectsSelector.selectAll(state);

  switch (action.type) {
    case APPLY_FILTERS_WHITEBOARD_FULFILLED: {
      const { projects, timeframes } = payload;

      if (!timeframes?.length) return state;

      return {
        ...state,
        timeframes: timeframesAdapter.setAll(state?.timeframes, processTimeframes(timeframes, projects)),
      };
    }
    case UPDATE_METADATA_ENTITIES_WHITEBOARD: {
      const { timeframes } = payload;

      return {
        ...state,
        timeframes: timeframesAdapter.setAll(state?.timeframes, processTimeframes(timeframes, storeProjects)),
      };
    }
    case UPDATE_TIMEFRAME_BY_ID_FULFILLED:
    case UPDATE_TIMEFRAME_FULFILLED: {
      if (!payload?.id) return state;

      const shouldAddToStore = storeProjects.some(project => project?.timeframeId === payload?.id);

      if (!shouldAddToStore) {
        return state;
      }
      return {
        ...state,
        timeframes: timeframesAdapter.upsertOne(state?.timeframes, pickTimeframeFields(payload)),
      };
    }
    case DELETE_TIMEFRAME_BY_ID_FULFILLED: {
      return {
        ...state,
        timeframes: timeframesAdapter.removeOne(state?.timeframes, payload),
      };
    }
    default: {
      return state;
    }
  }
};

export { timeframesReducer };
