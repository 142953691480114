import moment from 'moment-timezone';

/**
 * @function getEarliestAndLatestDates
 * @description Returns the earliest and latest dates from an array of dates
 *
 * @param {*} dates  - array of dates
 * @returns {*} earliestDate and latestDate
 */

export default dates => {
  const parsedDates = dates.map(date => moment(date)).filter(date => date.isValid());

  return {
    earliestDate: moment.min(parsedDates),
    latestDate: moment.max(parsedDates),
  };
};
