const indexTree = (tree, array = []) => {
  // forEach on Maps keeps order
  tree.forEach((node, id) => {
    array.push(id);
    if (node.size > 0) indexTree(node, array);
  });

  return array;
};

const buildTree = (rows, parentPropertyKey) => {
  const tree = new Map();

  const rootsToRemove = [];

  // why a for loop instead of a forEach? to keep row's order!
  for (let i = 0; i < rows.length; i++) {
    const el = rows[i];
    const parentId = el[parentPropertyKey];

    if (parentId === null) {
      tree.set(el.id, new Map());
      continue;
    }
    // discard key later as it's not a root parent
    rootsToRemove.push(el.id);
    // populate if needed
    if (!tree.has(el.id)) tree.set(el.id, new Map());
    if (!tree.has(parentId)) tree.set(parentId, new Map());

    const treeParentEl = tree.get(parentId);

    treeParentEl.set(el.id, tree.get(el.id));
  }
  // clean non-root parents
  rootsToRemove.forEach(key => tree.delete(key));

  return { tree, indexedTree: indexTree(tree) };
};

export default buildTree;
