import { useCallback } from 'react';

import { useReleaseCapacityAllocation } from 'hooks/releaseCapacityAllocation/useReleaseCapacityAllocation';
import { getReleaseCapacityAllocationMetadataByGroupRow } from 'utils/releaseCapacityAllocation';
import usePageFilters from '../filters/usePageFilters';

/**
 * @function useUpdateReleaseCapacityAllocationOnForecastList
 *
 * Hook that returns the update function to handle with
 * release capacity allocation changes on estimates columns
 *
 * @return {Object}
 */
const useUpdateReleaseCapacityAllocationOnForecastList = () => {
  const { upsertResourcing } = useReleaseCapacityAllocation();
  const { pageFilters } = usePageFilters();

  const updateReleaseCapacityAllocation = useCallback(
    (row, { teamId, skillId, value }) => {
      if (!row) {
        return;
      }

      const { timeframeId, metadataField, metadataId } = getReleaseCapacityAllocationMetadataByGroupRow(row, pageFilters);

      const resourcing = [{ teamId: +teamId, skillId: +skillId, value: +value }];

      return upsertResourcing(timeframeId, metadataField, metadataId, resourcing);
    },
    [upsertResourcing],
  );

  return { updateReleaseCapacityAllocation };
};

export default useUpdateReleaseCapacityAllocationOnForecastList;
