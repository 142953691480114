import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { not, isNil, pipe } from 'ramda';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/HelpOutline';

import { spacing } from 'design-system/theme';

import useLinkWithRally from './useLinkWithRally';

import { FIELD_MAPPING_DIRECTION_OPTIONS, INTEGRATIONS_DOCS_LINKS, INTEGRATIONS_KEYS } from 'src/constants/integrations';

import {
  BottomRow,
  InputWrapper,
  StyledTextField,
  ButtonsWrapper,
  HintButtonWrapper,
  LinkWorkItemButton,
  HelpIconLink,
} from '../../../utils/styles';

const isNotNil = pipe(isNil, not);

const LinkWithRally = props => {
  const { onLink, linkErrorMsg, rallyWorkItemType, isLinking, disableActions } = props;

  const { link, invalidUrlMsg, onChangeHandler, onLinkHandler } = useLinkWithRally(onLink);

  const errorMessage = linkErrorMsg || invalidUrlMsg;

  return (
    <BottomRow>
      <InputWrapper minWidth={185}>
        <StyledTextField
          label={`Enter Rally ${rallyWorkItemType} Link`}
          error={isNotNil(errorMessage)}
          value={link}
          onChange={onChangeHandler}
          style={{ marginTop: `${spacing(2)}px` }}
          fullWidth
          useEllipsis
          disabled={isLinking || disableActions}
        />
      </InputWrapper>
      <ButtonsWrapper>
        <LinkWorkItemButton
          id="link-to-rally"
          onClick={() => onLinkHandler(FIELD_MAPPING_DIRECTION_OPTIONS.outbound)}
          disabled={not(link) || isLinking || disableActions}
          direction={FIELD_MAPPING_DIRECTION_OPTIONS.outbound}
        >
          <ArrowForwardIcon color="inherit" fontSize="inherit" />
          Link & update Rally
        </LinkWorkItemButton>
        <HintButtonWrapper>
          <LinkWorkItemButton
            id="link-from-rally"
            onClick={() => onLinkHandler(FIELD_MAPPING_DIRECTION_OPTIONS.inbound)}
            disabled={not(link) || isLinking || disableActions}
            direction={FIELD_MAPPING_DIRECTION_OPTIONS.inbound}
          >
            <ArrowBackIcon color="inherit" fontSize="inherit" />
            Link & update by Rally
          </LinkWorkItemButton>
          <HelpIconLink
            href={INTEGRATIONS_DOCS_LINKS[INTEGRATIONS_KEYS.rally].CREATE_OR_LINK_EPICS}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => e.stopPropagation()}
          >
            <HelpIcon />
          </HelpIconLink>
        </HintButtonWrapper>
      </ButtonsWrapper>
      {errorMessage && (
        <Grid item sm={12} style={{ paddingTop: '5px' }}>
          <ErrorSpan>{errorMessage}</ErrorSpan>
        </Grid>
      )}
    </BottomRow>
  );
};

LinkWithRally.propTypes = {
  onLink: PropTypes.func.isRequired,
  linkErrorMsg: PropTypes.string,
  rallyWorkItemType: PropTypes.string.isRequired,
};

export default LinkWithRally;

const ErrorSpan = styled('span')`
  color: ${({ theme }) => theme.palette.text.error};
`;
