import { append, defaultTo, keys, values, pipe, isEmpty, not } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useCallback } from 'react';

import useSystemFields from 'hooks/useSystemFields';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import { getPriorities } from 'store/priorities/selectors';
import { getProducts, getRoadmaps } from 'store/roadmaps/selectors';
import { getTimeframes } from 'store/timeframes/selectors';
import { ROADMAPS, TIMEFRAMES, PRODUCTS, PRIORITIES } from 'constants/common';
import { getSelectedOptionsOnFilterToPlan } from 'store/forecastHeadcount/selectors';
import { setSelectedOptionsOnFilterToPlan } from 'store/forecastHeadcount';
import {
  COMMITTED_KEY,
  EXCLUDE_AND_DISPLAY_UNCOMMITTED_OPTION,
  EXCLUDE_UNCOMMITTED_OPTION,
  INCLUDE_ALL_OPTION,
} from 'constants/projects';

const UNDEFINED_ITEM = {
  id: null,
  title: 'Undefined',
};
const DEFAULT_MENU_LIST_PROPS = {
  checkedByDefault: true,
};

const committedOptions = [
  {
    label: 'Include all items',
    value: INCLUDE_ALL_OPTION,
  },
  {
    label: 'Exclude Below the line',
    value: EXCLUDE_UNCOMMITTED_OPTION,
  },
  {
    label: 'Exclude Below the line & Display all',
    value: EXCLUDE_AND_DISPLAY_UNCOMMITTED_OPTION,
  },
];

const COMMITED_OPTION_ID = 'above-the-line-filter-option';

const appendUndefinedToOptions = (data = []) => append(UNDEFINED_ITEM, data);
const mapMetadataToDndList = v => ({
  ...v,
  label: v.title,
});
const defaultEmptyObject = defaultTo({});
const defaultEmptyArray = defaultTo([]);
const isNotEmpty = pipe(isEmpty, not);

/**
 * Generates the body to update the changed filter option
 * @param parentKey
 * @param childId
 * @param selected
 * @param selectedOptionsOnFilter
 * @return {{}}
 */
const getUpdatedOption = (parentKey, childId, selected, selectedOptionsOnFilter) => {
  switch (parentKey) {
    case COMMITTED_KEY:
      return { [parentKey]: selected };
    default:
      return {
        [parentKey]: {
          ...defaultEmptyObject(selectedOptionsOnFilter[parentKey]),
          [childId]: selected,
        },
      };
  }
};

/**
 * @function useFilterOptionsForPlanBasedOn
 *
 * Use the suggestions and eventos on autocomplete to select the desired plan
 *  - also returns the filter options base on selected option
 *
 * @param  {String} pageId
 * @return {Object}
 */
const useFilterOptionsForPlanBasedOn = pageId => {
  const dispatch = useDispatch();
  const [getSystemFieldName] = useSystemFields();

  const selectedOptionsOnFilter = useSelector(state => getSelectedOptionsOnFilterToPlan(state, pageId));

  const metadataFromStore = useSelector(state => ({
    [ROADMAPS]: getStateDataForPage(state, getRoadmaps, ROADMAPS),
    [TIMEFRAMES]: getStateDataForPage(state, getTimeframes, TIMEFRAMES),
    [PRODUCTS]: getStateDataForPage(state, getProducts, PRODUCTS),
    [PRIORITIES]: getPriorities(state),
  }));

  const menu = useMemo(() => {
    const getRoadmapChildren = roadmapId => {
      return metadataFromStore[PRODUCTS].filter(opt => opt.roadmap_id === roadmapId).map(mapMetadataToDndList);
    };
    const mapTimeframeWithChildren = timeframe => ({
      ...mapMetadataToDndList(timeframe),
      children: defaultEmptyArray(timeframe.children).map(mapMetadataToDndList),
    });

    const roadmaps = appendUndefinedToOptions(metadataFromStore[ROADMAPS]).map(roadmap => {
      return {
        ...mapMetadataToDndList(roadmap),
        children: getRoadmapChildren(roadmap.id),
      };
    });
    const timeframes = appendUndefinedToOptions(metadataFromStore[TIMEFRAMES]).map(mapTimeframeWithChildren);
    const priorities = appendUndefinedToOptions(metadataFromStore[PRIORITIES]).map(mapMetadataToDndList);

    return [
      {
        key: PRIORITIES,
        value: PRIORITIES,
        label: getSystemFieldName('priority'),
        type: 'check',
        menuListProps: DEFAULT_MENU_LIST_PROPS,
        children: priorities,
      },
      {
        key: TIMEFRAMES,
        value: TIMEFRAMES,
        label: getSystemFieldName('timeframe'),
        type: 'check',
        menuListProps: DEFAULT_MENU_LIST_PROPS,
        children: timeframes,
      },
      {
        key: ROADMAPS,
        value: ROADMAPS,
        label: `${getSystemFieldName('roadmap')} / ${getSystemFieldName('product1')}`,
        type: 'check',
        menuListProps: DEFAULT_MENU_LIST_PROPS,
        children: roadmaps,
      },
      {
        id: COMMITED_OPTION_ID,
        key: COMMITTED_KEY,
        value: COMMITTED_KEY,
        label: 'Above the Line',
        type: 'radio',
        children: committedOptions,
        menuListProps: {
          defaultSelected: INCLUDE_ALL_OPTION,
        },
      },
    ];
  }, [getSystemFieldName, metadataFromStore]);

  const hasFilterActive = useMemo(() => {
    return keys(selectedOptionsOnFilter).some(key => {
      return isNotEmpty(values(selectedOptionsOnFilter[key]).filter(Boolean));
    });
  }, [selectedOptionsOnFilter]);

  const handleSelectOption = useCallback(
    (parentKey, childId, selected) => {
      const newSelectedItems = {
        ...selectedOptionsOnFilter,
        ...getUpdatedOption(parentKey, childId, selected, selectedOptionsOnFilter),
      };

      dispatch(setSelectedOptionsOnFilterToPlan(pageId, newSelectedItems));
    },
    [dispatch, pageId, selectedOptionsOnFilter],
  );

  const handleClearFilter = useCallback(
    item => {
      if (not(item)) {
        return;
      }

      const clearAllOnMenuItem = menuItem => {
        return defaultEmptyArray(menuItem?.children).reduce(
          (acc, child) => ({
            ...acc,
            [child.id]: true,
          }),
          {},
        );
      };

      const newSelectedItems = {
        ...selectedOptionsOnFilter,
        [item.key]: clearAllOnMenuItem(menu.find(o => o.key === item.key)),
      };

      dispatch(setSelectedOptionsOnFilterToPlan(pageId, newSelectedItems));
    },
    [dispatch, pageId, selectedOptionsOnFilter],
  );

  return {
    menu,
    selectedOptions: selectedOptionsOnFilter,
    handleSelectOption,
    hasFilterActive,
    handleClearFilter,
  };
};

export default useFilterOptionsForPlanBasedOn;
