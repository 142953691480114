import { isNil, not } from 'ramda';

import getStore from 'store/store';
import { getIsDodActive } from 'store/accessControl/selectors';
import { checkOrganizationFlags } from 'hooks/useFeatureFlags';
import { getOrganization } from 'store/organization';
import { FeatureFlags } from '@dragonboat/config';

const DEFAULT_PREFIX = 'DB-';

/**
 * @function isProjectKeyUsageDisabled
 *
 * Check if the usage of project key is disabled.
 *
 * @returns {Boolean}
 */
const isProjectKeyUsageDisabled = () => {
  const isDodActive = getIsDodActive(getStore().getState());
  const organization = getOrganization(getStore().getState());
  const hasProjectKeyEnabled = checkOrganizationFlags(organization, [FeatureFlags.HAS_PROJECT_KEY_ENABLED]);

  return isDodActive || not(hasProjectKeyEnabled);
};

/**
 * @function getProjectKey
 *
 * Gets the project id based on the current account config
 *
 * @param {Object} project
 * @returns {String|Number}
 */
export const getProjectKey = project => {
  if (isNil(project)) {
    return null;
  }

  const defaultId = project.id;

  if (isProjectKeyUsageDisabled()) {
    return defaultId;
  }

  return project.key || defaultId;
};

/**
 *
 * @function getProjectKeyLabel
 *
 * gets DB-[id or key] based on account config
 *
 * @param {Object} project
 * @returns {String}
 */
export const getProjectKeyLabel = project => {
  if (isNil(project)) {
    return null;
  }

  if (isProjectKeyUsageDisabled()) {
    return `DB-${project.id}`;
  }

  return project.key;
};

/**
 * @function getIntegrationProjectKey
 *
 * Gets the project id based on the current account config to be used in the Integrations flows
 *
 * @param {Object} project
 * @returns {String}
 */
export const getIntegrationProjectKey = project => {
  if (isNil(project)) {
    return null;
  }

  const defaultId = `${DEFAULT_PREFIX}${project.id}`;

  if (isProjectKeyUsageDisabled()) {
    return defaultId;
  }

  return project.key || defaultId;
};
