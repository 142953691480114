// External dependencies
import { is } from 'immutable';
import { createSelectorCreator, defaultMemoize, createSelector } from 'reselect';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import groupBy from 'lodash/groupBy';

import moment from 'moment-timezone';
import { createCachedSelector } from 're-reselect';

// Dragonboat depencies
import sortRowOrder from 'utils/sortRowOrder';
import { getError, isLoading, isSuccess, isError, isUninitialized } from 'utils/store/thunk';

import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from './constants';
import { getCollectionNameFromLayer } from './helpers/collections';
import { getEnrichedProject as _getEnrichedProject } from './helpers/enrichProjects';
import { selectMetadata } from './metadataSelectors';

import {
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECTS,
  UPDATE_PROJECT_ROW_ORDER,
  APPLY_FILTERS,
  BULK_REMOVE_PROJECT_METRICS,
  BULK_ADD_PROJECT_METRICS,
  UPDATE_PROJECT_PERSONAS,
  UPDATE_PROJECT_LIFECYCLES,
  ADD_PROJECT_METRIC,
} from './types';

// Using immutable we need to create a new selector creator that compares immutable objects
const createImmutableSelector = createSelectorCreator(defaultMemoize, is);
const createShallowSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getIsFetching = createSelector(
  state => state.projects.isFetching,
  isFetching => isFetching,
);

export function getState(state, _, layer = IDEA_LAYER, getAsFilters = false) {
  const collectionName = getCollectionNameFromLayer(layer, getAsFilters);

  return (state.projects || {})[collectionName];
}

export const getJSState = createCachedSelector(
  (state, layer) => getState(state, null, layer),
  projects => {
    return projects.toJS();
  },
)((_, layer) => `projects_${layer}`);

// TODO: REMOVE
export const getIdeas = createImmutableSelector(getState, state => state.toJS());

/* All objects per layer */

export const getAllIdeas = createSelector(
  state => {
    return selectMetadata(state);
  },
  state => getJSState(state, IDEA_LAYER),
  (metadata, ideas) => ideas.map(idea => _getEnrichedProject(idea, metadata)),
);
export const getAllInitiatives = createSelector(
  state => selectMetadata(state),
  state => getJSState(state, INITIATIVE_LAYER),
  (metadata, ideas) => ideas.map(idea => _getEnrichedProject(idea, metadata)),
);

export const getProjectsEntities = createSelector(
  state => state.entities.projects,
  projects => projects,
);

export const getAllBets = createSelector(
  state => selectMetadata(state),
  state => getJSState(state, BET_LAYER),
  (metadata, ideas) => ideas.map(idea => _getEnrichedProject(idea, metadata)),
);

const _defaultLayers = [IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER];

/* todo: this selector and its usages should be revisited. Its cache it's being invalidate several times
        for example on the usage of selectFilteredProjectsForPage
 */
export const getAllFromMultipleLayers = createCachedSelector(
  state => getAllIdeas(state),
  state => getAllInitiatives(state),
  state => getAllBets(state),
  (_, layers) => layers || _defaultLayers,
  (ideas, initiatives, bets, layers) => {
    const mapLayerToArray = {
      [IDEA_LAYER]: ideas,
      [INITIATIVE_LAYER]: initiatives,
      [BET_LAYER]: bets,
    };

    const data = [];

    layers.forEach(layer => {
      data.push(...mapLayerToArray[layer.toString()]);
    });

    return data;
  },
)((_, layers) => `all_from_multiple_${(layers || _defaultLayers).join('_')}`);

const obj = {};
// const arr = [];

/* Selectors for pages */

const getLastCallsIds = createCachedSelector(
  (state, layer = IDEA_LAYER, getAsFilters) =>
    ((state.projects.lastCallsMeta?.[layer] || {})[getAsFilters ? 'filters' : 'notFilters'] || {}).ids,
  ids => {
    return ids;
  },
)({
  keySelector: (_, layer, getAsFilters) => `lastcallsids-${layer}-${getAsFilters}`,
  selectorCreator: createSelectorCreator(defaultMemoize, isEqual),
});

const IDEAS_PARENT_LAYERS = [INITIATIVE_LAYER, BET_LAYER];
const INITIATIVES_PARENT_LAYERS = [BET_LAYER];

const BETS_CHILD_LAYERS = [INITIATIVE_LAYER, IDEA_LAYER];
const INITIATIVES_CHILD_LAYERS = [IDEA_LAYER];

const _getParentLayersFromLayer = (layer = IDEA_LAYER) =>
  layer.toString() === IDEA_LAYER ? IDEAS_PARENT_LAYERS : INITIATIVES_PARENT_LAYERS;
const _getChildLayersFromLayer = (layer = BET_LAYER) =>
  layer.toString() === BET_LAYER ? BETS_CHILD_LAYERS : INITIATIVES_CHILD_LAYERS;

const emptyArray = [];

export const selectFilteredProjectsForPage = createCachedSelector(
  (state, _, __, layer) => {
    return getJSState(state, layer);
  },
  (_, __, onlyProjects) => onlyProjects,
  state => selectMetadata(state),
  (state, __, ___, layer, getAsFilters) => getLastCallsIds(state, layer, getAsFilters) || emptyArray,
  (_, __, ___, ____, _____, showAll) => showAll,
  (state, __, ___, layer = IDEA_LAYER) => {
    return layer === BET_LAYER ? obj : getAllFromMultipleLayers(state, _getParentLayersFromLayer(layer));
  },
  (state, __, ___, layer = BET_LAYER) => {
    return layer === IDEA_LAYER ? obj : getAllFromMultipleLayers(state, _getChildLayersFromLayer(layer));
  },
  (_, __, ___, layer) => layer,
  (state, onlyProjects, metadata, ids = [], showAll, parents, children, layer) => {
    const normalizedParents = groupBy(parents, p => p.id);
    const normalizedChildren = groupBy(children, p => p.parent_id);

    return state
      .map(project => {
        const enrichedProject = _getEnrichedProject(project, metadata);

        if (enrichedProject.parent_id) {
          enrichedProject.parent = normalizedParents[enrichedProject.parent_id]?.[0].title;
          enrichedProject.parentLayer = normalizedParents[enrichedProject.parent_id]?.[0].layer;
        } else {
          enrichedProject.parent = null;
        }

        enrichedProject.children = normalizedChildren[enrichedProject.id] || [];

        return enrichedProject;
      })
      .filter(p => {
        if (onlyProjects && p.type === 'milestone') return false;
        if (showAll) return true;

        return ids.includes(+p.id);
      })
      .sort(sortRowOrder);
  },
)((_, __, onlyProjects, layer, ___, showAll) => `selectFilteredProjectsForPage+${__}+${onlyProjects}+${layer}+${___}+${showAll}`);

const makeGetAllDependencies = () => {
  const _getAllDependencies = createShallowSelector(
    state => state.projects?.loadedAsDependency,
    deps => deps || {},
  );

  return createSelector(_getAllDependencies, selectMetadata, (dependencies, metadata) => {
    return Object.values(dependencies).map(p => {
      return _getEnrichedProject(p, metadata);
    });
  });
};

export const getAllDependencies = makeGetAllDependencies();

export const selectFilteredProjectsForTimelinePage = createCachedSelector(
  (state, page, onlyProjects, layer, asFilters) => selectFilteredProjectsForPage(state, page, onlyProjects, layer, asFilters),
  (state, _, __, layer) => getAllDependencies(state, layer),
  (state, _, __, ___, ____, HCTableFilter) => HCTableFilter,
  (state, _, __, ___, ____, _____, withDependencies) => withDependencies,
  (state, _, __, layer) => layer,
  (projects, dependencies, HCTableFilter, withDependencies, layer) => {
    const _filterbyHCTableFilter = p => {
      if (!HCTableFilter) return [true, p];

      const { teamId, skillId, userId } = HCTableFilter;
      const clonedProject = { ...p };
      const _filterOwnerTeamSkill = (tId, sId, ownerId) => (userId ? userId === ownerId : teamId === tId && skillId === sId);
      const _filterTasks = t => {
        if (t.subtasks && t.subtasks.length) {
          return t.subtasks.some(_filterTasks);
        }
        return t.owner && _filterOwnerTeamSkill(t.owner.team_id, t.owner.skill_id, t.owner_id);
      };

      // filter tasks and estimates of the current project
      clonedProject.tasks = p.tasks && p.tasks.filter(_filterTasks);
      clonedProject.estimates = p.estimates && p.estimates.filter(e => _filterOwnerTeamSkill(e.team_id, e.skill_id));

      // filters
      const hasTasks = clonedProject.tasks && !!clonedProject.tasks.length;
      const hasEstimates = clonedProject.estimates && !!clonedProject.estimates.length;
      const projectOwnerFilter =
        p.owner &&
        _filterOwnerTeamSkill(p.owner.team_id, p.owner.skill_id, p.owner_id) &&
        (!p.tasks || !p.tasks.length) &&
        (!p.estimates || !p.estimates.length);
      const shouldShowProject = projectOwnerFilter || hasTasks || hasEstimates;

      return [shouldShowProject, clonedProject];
    };

    // remove duplicates
    const filteredDependencies = dependencies.filter(
      d => !projects.some(p => String(p.id) === String(d.id)) && d.layer === layer,
    );
    const data = !withDependencies ? projects : projects.concat(filteredDependencies);

    return data
      .reduce((acc, project) => {
        let addProject = true;
        const [showProject, clonedProject] = _filterbyHCTableFilter(project);

        addProject = showProject;

        if (addProject) acc.push(clonedProject);

        return acc;
      }, [])
      .sort(sortRowOrder);
  },
)(
  (_, __, onlyProjects, layer, ___, ____, withDependencies) =>
    `selectFilteredProjectsForTimelinePage+${onlyProjects}+${layer}+${withDependencies}`,
);

export const getSelectedProject = createSelector(
  state => selectMetadata(state),
  (_, selectedProject) => selectedProject,
  getAllBets,
  getAllInitiatives,
  getAllIdeas,
  (state, selectedProject) => {
    return selectedProject.layer === IDEA_LAYER
      ? obj
      : getAllFromMultipleLayers(state, _getChildLayersFromLayer(selectedProject.layer));
  },
  (state, selectedProject, bets, initiatives, ideas, children) => {
    if (!selectedProject || (!selectedProject.id && !selectedProject.key)) {
      return null;
    }
    let project;

    const _find = collection => {
      return collection.find(p => +p.id === +selectedProject.id || (selectedProject.key && p.key === selectedProject.key));
    };

    switch (selectedProject.layer) {
      case BET_LAYER:
        project = _find(bets);
        break;
      case INITIATIVE_LAYER:
        project = _find(initiatives);
        break;
      case IDEA_LAYER:
        project = _find(ideas);
        break;
      default:
        project = _find(bets);
        if (!project) project = _find(initiatives);
        if (!project) project = _find(ideas);
        break;
    }

    if (!project) return;

    const normalizedChildren = groupBy(children, p => p.parent_id);

    const enriched = _getEnrichedProject(project, state, normalizedChildren);

    return enriched;
  },
);

export const getIfModifiedSince = createSelector(
  state => state.projects.lastCallsMeta,
  (state, layers) => layers || [IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER],
  (state, layers, getAsFilters) => (getAsFilters ? 'filters' : 'notFilters'),
  (state, layers, getAsFilters, params) => params,
  (lastCallsMeta, layers, storeAsFiltersLabel, params) => {
    const lastModified = layers.reduce((lastModified, layer) => {
      if (
        !lastCallsMeta[layer] ||
        !lastCallsMeta[layer][storeAsFiltersLabel] ||
        !isEqual(lastCallsMeta[layer][storeAsFiltersLabel].params, omit(params, 'layer')) ||
        !lastCallsMeta[layer][storeAsFiltersLabel].date
      ) {
        return null;
      }

      const lastModifiedForThisLayer = lastCallsMeta[layer][storeAsFiltersLabel].date;

      if (!lastModified || lastModified > lastModifiedForThisLayer) return lastModifiedForThisLayer;

      return lastModified;
    }, null);

    if (lastModified) return `${moment.utc(lastModified).format('ddd, DD MMM YYYY HH:mm:ss')} GMT`;
  },
);

/* Returns the list of associations (withTasks, withEstimates, etc.)
 * used in the last call and present in all the queries layers
 */
export const getLastCallAssociations = createSelector(
  state => state.projects.lastCallsMeta,
  (state, layers) => layers || [IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER],
  (state, layers, getAsFilters) => (getAsFilters ? 'filters' : 'notFilters'),
  (state, layers, getAsFilters, params) => params,
  (lastCallsMeta, layers, storeAsFiltersLabel, params) => {
    const associationsCount = {};

    layers.forEach(layer => {
      if (
        !lastCallsMeta[layer] ||
        !lastCallsMeta[layer][storeAsFiltersLabel] ||
        !isEqual(lastCallsMeta[layer][storeAsFiltersLabel].params, omit(params, 'layer')) ||
        !lastCallsMeta[layer][storeAsFiltersLabel].associations
      ) {
        return;
      }

      lastCallsMeta[layer][storeAsFiltersLabel].associations.forEach(association => {
        associationsCount[association] = (associationsCount[association] || 0) + 1;
      });
    });

    return (
      Object.entries(associationsCount)
        .map(([key, val]) => (val === layers.length ? key : null))
        // we always need to get estimates and tasks when we query for projects if estimates are required
        // since they aren't cached in redux
        .filter(v => v && !['withEstimates', 'withTasks'].includes(v))
    );
  },
);

export const getEnrichedProject = createSelector(
  state => selectMetadata(state),
  (_, projectInfo) => projectInfo,
  (state, projectInfo) => {
    return projectInfo.layer === IDEA_LAYER ? obj : getAllFromMultipleLayers(state, _getChildLayersFromLayer(projectInfo.layer));
  },
  (metadata, projectInfo, children) => {
    if (!projectInfo || !projectInfo.id) {
      return null;
    }

    const normalizedChildren = groupBy(children, p => p.parent_id);

    const enriched = _getEnrichedProject(projectInfo, metadata, normalizedChildren);

    return enriched;
  },
);

export const getBulkDeletePending = state => state.projects.bulkDeletePending;

export const getBulkUpdatePending = state => state.projects.bulkUpdatePending;

export const getProjectCountersByPhase = createSelector(
  state => state.projects.projectCountersByPhase,
  state => selectMetadata(state),
  (_, field) => field,
  (counters, metadata, field) => {
    let collection = `${field}s`;

    if (field === 'keyResult1') collection = 'keyResults';
    if (field === 'product1') collection = 'products';

    return (counters || []).map(counter => ({
      ...counter,
      group: counter.group_id && counter.group_id !== 'null' ? (metadata[collection] || {})[+counter.group_id] : null,
    }));
  },
);

export const getUnsavedProject = state => {
  return state.projects.unsaved;
};

const getProjectOperations = state => state.projects.operations;

export const isCreateProjectLoading = createSelector(getProjectOperations, state => isLoading(state, CREATE_PROJECT));
export const isCreateProjectSuccess = createSelector(getProjectOperations, state => isSuccess(state, CREATE_PROJECT));
export const isCreateProjectError = createSelector(getProjectOperations, state => isError(state, CREATE_PROJECT));

export const isUpdateProjectLoading = createSelector(getProjectOperations, state => isLoading(state, UPDATE_PROJECT));
export const isUpdateProjectSuccess = createSelector(getProjectOperations, state => isSuccess(state, UPDATE_PROJECT));
export const isUpdateProjectError = createSelector(getProjectOperations, state => isError(state, UPDATE_PROJECT));
export const getUpdateProjectError = createSelector(getProjectOperations, state => getError(state, UPDATE_PROJECT));

export const isDeleteProjectLoading = createSelector(getProjectOperations, state => isLoading(state, DELETE_PROJECTS));
export const isDeleteProjectSuccess = createSelector(getProjectOperations, state => isSuccess(state, DELETE_PROJECTS));
export const isDeleteProjectError = createSelector(getProjectOperations, state => isError(state, DELETE_PROJECTS));

export const isApplyFiltersLoading = createSelector(getProjectOperations, state => isLoading(state, APPLY_FILTERS));
export const isApplyFiltersSuccess = createSelector(getProjectOperations, state => isSuccess(state, APPLY_FILTERS));
export const isApplyFiltersError = createSelector(getProjectOperations, state => isError(state, APPLY_FILTERS));
export const isApplyFiltersUninitialized = createSelector(getProjectOperations, state => isUninitialized(state, APPLY_FILTERS));

export const isUpdateRowOrderLoading = createSelector(getProjectOperations, state => isLoading(state, UPDATE_PROJECT_ROW_ORDER));
export const isUpdateRowOrderSuccess = createSelector(getProjectOperations, state => isSuccess(state, UPDATE_PROJECT_ROW_ORDER));
export const isUpdateRowOrderError = createSelector(getProjectOperations, state => isError(state, UPDATE_PROJECT_ROW_ORDER));
export const isUpdateRowOrderUninitialized = createSelector(getProjectOperations, state =>
  isUninitialized(state, UPDATE_PROJECT_ROW_ORDER),
);

export const isBulkRemoveMetricsFromProjectLoading = createSelector(getProjectOperations, state =>
  isLoading(state, BULK_REMOVE_PROJECT_METRICS),
);

export const isBulkAddMetricsToProjectLoading = createSelector(getProjectOperations, state =>
  isLoading(state, BULK_ADD_PROJECT_METRICS),
);

export const isAddMetricsToProjectLoading = createSelector(getProjectOperations, state => isLoading(state, ADD_PROJECT_METRIC));

export const isUpdatePersonasToProjectLoading = createSelector(getProjectOperations, state =>
  isLoading(state, UPDATE_PROJECT_PERSONAS),
);

export const isUpdateLifecyclesToProjectLoading = createSelector(getProjectOperations, state =>
  isLoading(state, UPDATE_PROJECT_LIFECYCLES),
);

export const isSomeUpdateOnProjectsOcurring = createSelector(
  isUpdateProjectLoading,
  isUpdateRowOrderLoading,
  isCreateProjectLoading,
  isBulkRemoveMetricsFromProjectLoading,
  isBulkAddMetricsToProjectLoading,
  isUpdatePersonasToProjectLoading,
  isUpdateLifecyclesToProjectLoading,
  (
    isUpdatingProject,
    isProjectRowOrderChanging,
    isCreatingProject,
    isBulkRemoveMetricsFromProject,
    isBulkAddMetricsToProject,
    isUpdateProjectPersonas,
    isUpdateProjectLifecycles,
  ) => {
    return (
      isUpdatingProject ||
      isProjectRowOrderChanging ||
      isCreatingProject ||
      isBulkRemoveMetricsFromProject ||
      isBulkAddMetricsToProject ||
      isUpdateProjectPersonas ||
      isUpdateProjectLifecycles
    );
  },
);

export const getStoriesData = state => state.projects?.stories;

export const getStoriesByProjectId = createSelector([getStoriesData, (_, id) => id], (state, id) => {
  return state?.[id] ?? [];
});

export const isLoadingProjects = createSelector(
  [getIsFetching, isApplyFiltersLoading],
  (isFetching, isApplyingFilters) => isFetching || isApplyingFilters,
);

export const getTotalProjectResults = createSelector(
  state => state.projects,
  projectsState => projectsState.totalAllLayers || 0,
);
