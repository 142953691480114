const mapDeliverableStatusToAutocompleteOption = (id, status) => ({
  value: id,
  label: status.title,
});

const getDeliverableFieldKey = deliverable => {
  return `deliverable_${deliverable.parent_id}_${deliverable.id}`;
};

const getDeliverableIdFromFieldName = fieldName => {
  const [, deliverableId] = fieldName.split('_').splice(1, 2);

  return deliverableId;
};

export { mapDeliverableStatusToAutocompleteOption, getDeliverableFieldKey, getDeliverableIdFromFieldName };
