import axios from 'axios';

import {
  SAVE_PROJECT_COMMENT,
  EDIT_PROJECT_COMMENT,
  FETCH_PROJECT_COMMENTS,
  FETCH_NEXT_PROJECT_COMMENTS,
  SAVE_OBJECTIVE_COMMENT,
  FETCH_OBJECTIVE_COMMENTS,
  FETCH_NEXT_OBJECTIVE_COMMENTS,
  SAVE_KEY_RESULT_COMMENT,
  FETCH_KEY_RESULT_COMMENTS,
  FETCH_NEXT_KEY_RESULT_COMMENTS,
  DELETE_PROJECT_COMMENT,
  DELETE_OBJECTIVE_COMMENT,
  EDIT_OBJECTIVE_COMMENT,
  DELETE_KEY_RESULT_COMMENT,
  EDIT_KEY_RESULT_COMMENT,
} from './types';

export const saveProjectComment = (projectId, comment) => {
  return dispatch => {
    const payload = axios.post(`/api/projects/${projectId}/comments`, comment).then(res => ({
      data: res.data,
      projectId,
    }));

    dispatch({
      type: SAVE_PROJECT_COMMENT,
      payload,
    });

    return payload;
  };
};

export const fetchProjectComments = (projectId, field) => {
  return dispatch => {
    const payload = axios.get(`/api/projects/${projectId}/comments?field=${field || 'null'}`).then(res => ({
      data: res.data,
      projectId,
    }));

    dispatch({
      type: FETCH_PROJECT_COMMENTS,
      payload,
      meta: { projectId },
    });

    return payload;
  };
};

export const fetchNextProjectComments = (projectId, field) => {
  return (dispatch, getState) => {
    let payload;

    const commentsState = getState().comments;

    const projectCommentsState = commentsState.projects.get(`${projectId}`);

    if (projectCommentsState && projectCommentsState._meta && projectCommentsState._meta.next) {
      payload = axios.get(projectCommentsState._meta.next).then(res => ({
        data: res.data,
        projectId,
      }));
    } else {
      payload = axios.get(`/api/projects/${projectId}/comments?field=${field || 'null'}`).then(res => ({
        data: res.data,
        projectId,
      }));
    }

    dispatch({
      type: FETCH_NEXT_PROJECT_COMMENTS,
      payload,
    });

    return payload;
  };
};

export const saveObjectiveComment = (objectiveId, comment) => {
  return dispatch => {
    const payload = axios.post(`/api/objectives/${objectiveId}/comments`, comment).then(res => ({
      data: res.data,
      objectiveId,
    }));

    dispatch({
      type: SAVE_OBJECTIVE_COMMENT,
      payload,
    });

    return payload;
  };
};

export const fetchObjectiveComments = (objectiveId, field) => {
  return dispatch => {
    const payload = axios.get(`/api/objectives/${objectiveId}/comments?field=${field || 'null'}`).then(res => ({
      data: res.data,
      objectiveId,
    }));

    dispatch({
      type: FETCH_OBJECTIVE_COMMENTS,
      payload,
      meta: { objectiveId },
    });

    return payload;
  };
};

export const fetchNextObjectiveComments = (objectiveId, field) => {
  return (dispatch, getState) => {
    let payload;

    const commentsState = getState().comments;

    const objectiveCommentsState = commentsState.objectives.get(`${objectiveId}`);

    if (objectiveCommentsState && objectiveCommentsState._meta && objectiveCommentsState._meta.next) {
      payload = axios.get(objectiveCommentsState._meta.next).then(res => ({
        data: res.data,
        objectiveId,
      }));
    } else {
      payload = axios.get(`/api/objectives/${objectiveId}/comments?field=${field || 'null'}`).then(res => ({
        data: res.data,
        objectiveId,
      }));
    }

    dispatch({
      type: FETCH_NEXT_OBJECTIVE_COMMENTS,
      payload,
    });

    return payload;
  };
};

export const deleteObjectiveComment = (objectiveId, id) => {
  return {
    payload: axios.delete(`/api/objectives/${objectiveId}/comments/${id}`).then(response => response.data),
    type: DELETE_OBJECTIVE_COMMENT,
    meta: { objectiveId, id },
  };
};

export const editObjectiveComment = (objectiveId, id, data) => {
  return dispatch => {
    const payload = axios.put(`/api/objectives/${objectiveId}/comments/${id}`, data).then(response => response.data);

    dispatch({
      type: EDIT_OBJECTIVE_COMMENT,
      payload,
      meta: { objectiveId, id },
    });

    return payload;
  };
};

export const saveKeyResultComment = (keyResultId, comment) => {
  return dispatch => {
    const payload = axios.post(`/api/keyResults/${keyResultId}/comments`, comment).then(res => ({
      data: res.data,
      keyResultId,
    }));

    dispatch({
      type: SAVE_KEY_RESULT_COMMENT,
      payload,
    });

    return payload;
  };
};

export const fetchKeyResultComments = (keyResultId, field) => {
  return dispatch => {
    const payload = axios.get(`/api/keyResults/${keyResultId}/comments?field=${field || 'null'}`).then(res => ({
      data: res.data,
      keyResultId,
    }));

    dispatch({
      type: FETCH_KEY_RESULT_COMMENTS,
      payload,
      meta: { keyResultId },
    });

    return payload;
  };
};

export const fetchNextKeyResultComments = (keyResultId, field) => {
  return (dispatch, getState) => {
    let payload;

    const commentsState = getState().comments;

    const keyResultCommentsState = commentsState.keyResults.get(`${keyResultId}`);

    if (keyResultCommentsState && keyResultCommentsState._meta && keyResultCommentsState._meta.next) {
      payload = axios.get(keyResultCommentsState._meta.next).then(res => ({
        data: res.data,
        keyResultId,
      }));
    } else {
      payload = axios.get(`/api/keyResults/${keyResultId}/comments?field=${field || 'null'}`).then(res => ({
        data: res.data,
        keyResultId,
      }));
    }

    dispatch({
      type: FETCH_NEXT_KEY_RESULT_COMMENTS,
      payload,
    });

    return payload;
  };
};

export const deleteKeyResultComment = (keyResultId, id) => {
  return {
    payload: axios.delete(`/api/keyResults/${keyResultId}/comments/${id}`).then(response => response.data),
    type: DELETE_KEY_RESULT_COMMENT,
    meta: { keyResultId, id },
  };
};

export const editKeyResultComment = (keyResultId, id, data) => {
  return dispatch => {
    const payload = axios.put(`/api/keyResults/${keyResultId}/comments/${id}`, data).then(response => response.data);

    dispatch({
      type: EDIT_KEY_RESULT_COMMENT,
      payload,
      meta: { keyResultId, id },
    });

    return payload;
  };
};

export const deleteProjectComment = (projectId, id) => {
  return {
    payload: axios.delete(`/api/projects/${projectId}/comments/${id}`).then(response => response.data),
    type: DELETE_PROJECT_COMMENT,
    meta: { projectId, id },
  };
};

export const editProjectComment = (projectId, id, data) => {
  return dispatch => {
    const payload = axios.put(`/api/projects/${projectId}/comments/${id}`, data).then(response => response.data);

    dispatch({
      type: EDIT_PROJECT_COMMENT,
      payload,
      meta: { projectId, id },
    });

    return payload;
  };
};
