import axios from 'axios';

import { createThunk } from 'utils/store/thunk';
import { FETCH_LIFECYCLES } from 'features/Lifecycles/store/types';


const fetchLifecyclesData = () =>
  createThunk(
    FETCH_LIFECYCLES,
    axios.get('/api/v1/lifecycles').then(({ data }) => data),
  );

export { fetchLifecyclesData };
