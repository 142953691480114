import React, { useMemo } from 'react';
import { defaultTo, flatten } from 'ramda';
import styled from 'styled-components';
import MuiIconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArchiveIcon from '@material-ui/icons/Archive';

import normalizeArray from 'utils/normalizeArray';
import Autocomplete from 'design-system/atoms/Autocomplete/index';
import ToggleButton from 'design-system/molecules/ToggleButton/index';

import { materialColors } from 'design-system/themes/default';

import FieldWrapper from './components/FieldWrapper';
import FieldMultiselect from './components/FieldMultiselect';
import FieldText from './components/FieldText';
import FieldDate from './components/FieldDate';
import FieldCheckbox from './components/FieldCheckbox';
import FieldSingleSelect from './components/FieldSingleSelect';

export default ({
  fields,
  options,
  onRemove,
  onChange,
  data,
  functions,
  inputComponent,
  isCustomerRequest,
  labelOverride,
  showUndefined,
}) => {
  const { field: fieldId, op, value, showArchived } = data;

  // TODO: this should be done outside of this component
  const field = isCustomerRequest ? fields?.find(f => f.key === fieldId) : fields?.find(f => f.id === fieldId);

  const fieldFunc = functions?.find(f => f.key === op);
  const _onChange = (f, v) => onChange(fieldId, f, v);
  const normalizedOptions = useMemo(() => {
    if (!options) {
      return {};
    }

    if (!field?.hasGroups) {
      return normalizeArray(options, 'id');
    }

    return normalizeArray(flatten(options.map(option => option.children || [])), 'id');
  }, [options, field?.hasGroups]);

  const isOptionField = () => field && field?.type === 'option';
  const isCheckField = () => field && field?.type === 'check';
  const isTextField = () => field && field?.type === 'text';
  const isDateField = () => field && field?.type === 'date';
  const isNumberField = () => field && field?.type === 'number';
  const isCheckboxField = () => field && field?.type === 'checkbox';
  const isSingleOptionField = () => field && field?.type === 'singleOption';

  const _handleFieldChange = optionLabel => {
    const selectedOption = fields?.find(f => f.label === optionLabel);

    if (selectedOption) {
      // TODO: this should be done outside of this component
      _onChange('field', isCustomerRequest ? selectedOption.key : selectedOption.id);
    }

    if (!selectedOption && !optionLabel) {
      _onChange('field', null);
    }
  };
  const _handleFunctionChange = optionLabel => {
    const selectedFunction = functions.find(f => f.label === optionLabel);

    if (selectedFunction) _onChange('op', selectedFunction.key);
    if (!selectedFunction && !optionLabel) _onChange('op', null);
  };

  const multiSelectValue = useMemo(
    () => (Array.isArray(value) && Object.keys(normalizedOptions).length ? value.map(id => normalizedOptions[id]) : value),
    [normalizedOptions, value],
  );

  const optionsList = defaultTo([], options);

  const handleSingleSelectChange = optionLabel => {
    const selectedOption = optionsList.find(opt => opt.title === optionLabel);

    if (selectedOption) {
      _onChange('value', selectedOption.id);
    }
  };

  const singleSelectValue = optionsList.find(opt => opt.id === value)?.title;

  React.useEffect(() => {
    if (fieldFunc && fieldFunc.key === 'isEmpty') {
      _onChange('value', null);
    }
  }, [(fieldFunc || {}).key]);

  const valueInput = (
    <React.Fragment>
      {isOptionField() && (
        <FieldMultiselect
          showUndefined={showUndefined}
          inputComponent={inputComponent}
          labelOverride={labelOverride}
          options={options}
          field={field}
          fieldFunction={fieldFunc}
          value={multiSelectValue}
          onChange={val => _onChange('value', val)}
          hasGroups={field?.hasGroups}
        />
      )}
      {isSingleOptionField() && (
        <FieldSingleSelect value={singleSelectValue} options={options} handleChange={handleSingleSelectChange} />
      )}
      {isTextField() && (
        <FieldText
          fullWidth
          value={value || ''}
          fieldFunction={fieldFunc}
          onChange={({ target }) => _onChange('value', target.value)}
        />
      )}
      {isDateField() && <FieldDate fieldFunction={fieldFunc} value={value} onChange={val => _onChange('value', val)} />}
      {isNumberField() && (
        <FieldText
          width={150}
          value={value || ''}
          fieldFunction={fieldFunc}
          onChange={({ target }) => _onChange('value', target.value)}
        />
      )}
      {isCheckboxField() && <FieldCheckbox fieldFunction={fieldFunc} value={value} onChange={val => _onChange('value', val)} />}
      {isCheckField() && (
        <FieldMultiselect
          showUndefined={showUndefined}
          inputComponent={inputComponent}
          options={options}
          field={field}
          value={multiSelectValue}
          onChange={val => _onChange('value', val)}
        />
      )}
    </React.Fragment>
  );

  return (
    <Wrapper data-testid={`filter-condition-component-${field?.key || field?.id}`}>
      {fields && (
        <FieldWrapper input autocomplete>
          <Autocomplete value={field && field?.label} suggestions={fields} handleChange={_handleFieldChange} />
        </FieldWrapper>
      )}
      {functions && field && field?.label && (
        <FieldWrapper input autocomplete>
          <Autocomplete value={fieldFunc && fieldFunc.label} suggestions={functions} handleChange={_handleFunctionChange} />
        </FieldWrapper>
      )}
      {valueInput}
      <FieldWrapper style={{ marginLeft: 'auto' }}>
        {isOptionField() && field?.archivedOptions && (
          <ToggleButton
            on={false}
            icon={
              <ArchiveIcon
                style={{ color: showArchived ? materialColors.azure : materialColors.darkGray, width: 20, height: 20 }}
              />
            }
            title="Show Archived"
            onChange={() => _onChange('showArchived', !showArchived)}
          />
        )}
        <IconButton onClick={() => onRemove(data)} hideButton={field?.hideRemoveButton}>
          <CloseIcon />
        </IconButton>
      </FieldWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  min-width: 300px;
  padding: 5px 0;
`;

const IconButton = styled(MuiIconButton)`
  &&&& {
    svg {
      font-size: ${props => props.theme.typography.fontSizeSmallLarge}px;
    }

    visibility: ${({ hideButton }) => (hideButton ? 'hidden' : 'visible')};
  }
`;
