import { defaultTo, prop, path } from 'ramda';
import { REHYDRATE } from 'redux-persist';

import {
  UPDATE_SUMMARY_STATE,
  UPDATE_SUMMARY_COLUMN_STATE,
  DEPENDENCY_LINES_TOGGLE,
  ALL_DEPENDENCIES_TOGGLE,
  ITEMS_WITHOUT_DEPENDENCIES_TOGGLE,
  UPDATE_SUMMARY_EXPANDED_GROUPS,
  UPDATE_SUMMARY_DISPLAY_FIELDS,
} from './types';
import { dateFieldToGroupOptions, groupDateByOptions } from './consts';

import getSystemFieldName from 'utils/getSystemFieldName';
import { getTimeframeOption, getRoadmapOption } from 'store/projects/helpers/groupOptions';
import { getScenarioVersionOption } from 'store/roadmapVersions/helpers/groupOptions';
import { COMPARE_SCENARIOS_SUMMARY, GOAL_SUMMARY, PORTFOLIO_SUMMARY } from 'constants/summary';
import { getRoadmapHistorySnapOption } from 'features/RoadmapHistory/components/CompareRoadmapHistorySnapsSummary';

const pageInitialState = {
  selectedColData: getTimeframeOption(getSystemFieldName),
  selectedRowData: getRoadmapOption(getSystemFieldName),
  groupByData: null,
  headerColor: { column: true, row: true },
  displayPreferences: {
    displayFields: ['title', 'timeframe', 'health'],
    showGroupColor: true,
  },
  showColorPicker: false,
  allCollapsed: false,
  expandedGroups: [],
  scrollTop: 0,
  scrollLeft: 0,
  isReadOnly: undefined,
  hideEmptyLanes: false,
  showDependencyLines: false,
  showAllDependencies: false,
  hideItemsWithoutDependencies: false,
  dateFieldToGroup: dateFieldToGroupOptions[0],
  groupDatesBy: groupDateByOptions[0],
};

const initialState = {
  portfolioSummary: pageInitialState,
  goalSummary: pageInitialState,
  compareScenariosSummary: {
    ...pageInitialState,
    isReadOnly: true,
    selectedRowData: getScenarioVersionOption(getSystemFieldName),
    selectedColData: getTimeframeOption(getSystemFieldName),
  },
  compareRoadmapHistorySummary: {
    ...pageInitialState,
    isReadOnly: true,
    selectedRowData: getRoadmapHistorySnapOption(getSystemFieldName),
    selectedColData: getTimeframeOption(getSystemFieldName),
  },
};

const sliceReducer = (state = pageInitialState, action) => {
  switch (action.type) {
    case UPDATE_SUMMARY_STATE:
      return {
        ...state,
        ...action.payload,
      };

    case UPDATE_SUMMARY_COLUMN_STATE:
      return {
        ...state,
        colsWidth: {
          ...(state.colsWidth || {}),
          ...action.payload,
        },
      };

    case DEPENDENCY_LINES_TOGGLE:
      return {
        ...state,
        showDependencyLines: !state.showDependencyLines,
      };

    case ALL_DEPENDENCIES_TOGGLE:
      return {
        ...state,
        showAllDependencies: !state.showAllDependencies,
      };

    case ITEMS_WITHOUT_DEPENDENCIES_TOGGLE:
      return {
        ...state,
        hideItemsWithoutDependencies: !state.hideItemsWithoutDependencies,
      };

    case UPDATE_SUMMARY_EXPANDED_GROUPS:
      const { groupId, expanded } = action.payload;

      return {
        ...state,
        expandedGroups: expanded
          ? [...(state.expandedGroups || []), groupId]
          : (state.expandedGroups || []).filter(g => g !== groupId),
      };

    case UPDATE_SUMMARY_DISPLAY_FIELDS:
      return {
        ...state,
        displayPreferences: {
          ...state.displayPreferences,
          displayFields: action.payload,
        },
      };

    default:
      return state;
  }
};

const defaultToPortfolioSummary = defaultTo(PORTFOLIO_SUMMARY);
const summaryReducer = (state = initialState, action) => {
  const getSliceFromPayload = slice => path(['payload', 'summary', slice]);

  const slice = defaultToPortfolioSummary(action?.meta?.slice);

  if (action.type === REHYDRATE) {
    if (getSliceFromPayload(PORTFOLIO_SUMMARY)(action)) {
      return { ...state, portfolioSummary: getSliceFromPayload(PORTFOLIO_SUMMARY)(action) };
    }

    if (getSliceFromPayload(GOAL_SUMMARY)(action)) {
      return { ...state, goalSummary: getSliceFromPayload(GOAL_SUMMARY)(action) };
    }

    if (getSliceFromPayload(COMPARE_SCENARIOS_SUMMARY)(action)) {
      return { ...state, compareScenariosSummary: getSliceFromPayload(COMPARE_SCENARIOS_SUMMARY)(action) };
    }
  }

  return {
    ...state,
    [slice]: sliceReducer(prop(slice, state), action),
  };
};

export default summaryReducer;
