import React, { useState } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'ramda';

import UserViewsList from 'containers/UserViewsList';
import SearchBar from 'components/SearchBar';
import WidgetSegmentTitle from 'design-system/atoms/WidgetSegmentTitle';
import useUserViews from 'hooks/userViews/useUserViews';
import useSearchViews from 'hooks/userViews/useSearchViews';
import useUserViewsList from 'hooks/userViews/useUserViewsList';
import { VIEW_ITEM_VARIANTS } from 'constants/userViews';

export default ({ userHasSavedViews }) => {
  const [allViews, setAllViews] = useState([]);

  const { userViewsWithoutDashboardViews: userViews } = useUserViews();
  const { searchText, handleSearchViews } = useSearchViews(userViews, setAllViews);
  const userViewsListProps = useUserViewsList(allViews, !isEmpty(searchText));

  return (
    <ViewsContainer>
      <ViewsHeader>
        <WidgetSegmentTitle>All views</WidgetSegmentTitle>
        {userHasSavedViews && (
          <SearchBar
            onClick={e => e.stopPropagation()}
            searchString={searchText}
            updateSearchString={handleSearchViews}
            inputProps={{ 'data-testid': 'views-search-input' }}
            placeholder="Search by name"
          />
        )}
      </ViewsHeader>
      <ViewsList>
        <UserViewsList {...userViewsListProps} variant={VIEW_ITEM_VARIANTS.homepage} />
      </ViewsList>
    </ViewsContainer>
  );
};

const ViewsContainer = styled.div`
  margin: 0 auto;
`;

const ViewsHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ViewsList = styled.div`
  max-height: 335px;
  overflow-y: scroll;
  margin: 21px 0;
`;
