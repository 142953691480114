import React, { useEffect } from 'react';

import Dialog from 'design-system/molecules/Dialog/index';

import ChartWidgetWizard from '../ChartWidgetWizard/ChartWidgetWizard';
import useChartWidgetWizard from '../ChartWidgetWizard/hooks/useChartWidgetWizard';
import WidgetLightboxActions from './components/WidgetLightboxActions';

const AddWidgetLightbox = ({ isOpen, onClose, dashboardId }) => {
  const {
    title,
    charts,
    selectedChart,
    chartOptions,
    selectedChartOptions,
    currentScreen,
    canSaveWidget,

    handleSelectChart,
    goToPreviousScreen,
    handleSelectChartOption,
    saveWidget,
    resetWizard,
    fetchChartOptions,
  } = useChartWidgetWizard({ dashboardId });

  const onCloseLightbox = () => {
    onClose();
    resetWizard();
  };

  const onSaveWidget = () => {
    saveWidget();
    onCloseLightbox();
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    fetchChartOptions();
  }, [isOpen, fetchChartOptions]);

  return (
    <Dialog
      data-test="widget-wizard-dialog"
      open={!!isOpen}
      onClose={onCloseLightbox}
      maxWidth="lg"
      scroll="paper"
      closeButton
      title=""
      actions={
        <WidgetLightboxActions
          currentScreen={currentScreen}
          goToPreviousScreen={goToPreviousScreen}
          saveLabel="Add Widget"
          onSaveWidget={onSaveWidget}
          canSave={canSaveWidget}
        />
      }
    >
      <ChartWidgetWizard
        title={title}
        charts={charts}
        selectedChart={selectedChart}
        handleSelectChart={handleSelectChart}
        currentScreen={currentScreen}
        chartOptions={chartOptions}
        selectedChartOptions={selectedChartOptions}
        handleSelectChartOption={handleSelectChartOption}
      />
    </Dialog>
  );
};

export default AddWidgetLightbox;
