import { useDispatch, useSelector } from 'react-redux';
import { updateMetricById } from 'store/metrics';
import {
  addExistingMetricToKeyResult,
  addExistingMetricToObjective,
  createMetricAndAddToOkr,
  removeMetricFromKeyResult,
  removeMetricFromObjective,
} from 'store/objectives';
import { isObjective } from 'store/objectives/helpers';
import { getHasAdvancedMetricReporting } from 'store/organization';
import formatDate from 'utils/dates/formatDate';
import { metricActions } from 'store/metrics/actions';

const OBJECTIVES = 'objectives';
const KEY_RESULTS = 'keyResults';

/**
 * @function useSingleMetricsForm
 *
 * hook with actions to deal with add, remove and edit metrics
 * for organizations with single metrics enabled
 *
 * @return {Object}
 */
const useSingleMetricsForm = () => {
  const dispatch = useDispatch();
  const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);

  const setMetricValueDataId = (date, metricValue = {}) => {
    if (hasAdvancedMetricReporting && metricValue.id) {
      const dateChanged = metricValue.date ? formatDate(date) !== formatDate(metricValue.date) : true;

      return dateChanged ? undefined : metricValue.id;
    }

    // if no metric reporting should always update
    if (metricValue.id) {
      return metricValue.id;
    }
  };

  const updateMetricValue = (okr, type, value, field, metricValue) => {
    const selectedMetric = okr?.metrics?.[0];

    if (value && selectedMetric && selectedMetric.id) {
      const metricId = selectedMetric.id;
      // update metric value with proper info - need existing metric value + metric
      const data = {
        metric_id: metricId,
        type,
      };

      if (field === 'value') {
        data.value = value;
        data.id = metricValue?.id || undefined;
        data.date = metricValue?.date || new Date();
      }

      if (field === 'date') {
        data.date = value;
        data.value = metricValue?.value || null;
        data.id = setMetricValueDataId(value, metricValue);
      }

      if (data.id) {
        dispatch(metricActions.updateMetricValue(data.id, data));
      } else {
        dispatch(metricActions.addMetricValue(data));
      }
    }
  };

  const updateMetricBaseline = (metricId, update) => {
    dispatch(updateMetricById(metricId, update));
  };

  const removeMetricFromOkr = (okr, okrType, metricId) => {
    if (isObjective(okrType)) {
      return dispatch(removeMetricFromObjective(okr.id, metricId));
    }

    return dispatch(removeMetricFromKeyResult(okr.id, metricId));
  };

  const addMetricToOkr = async (okr, okrType, metricId) => {
    if (okr.metrics?.length) {
      await removeMetricFromOkr(okr, okrType, okr.metrics[0].id);
    }

    if (isObjective(okrType)) {
      return dispatch(addExistingMetricToObjective(okr.id, metricId));
    }

    return dispatch(addExistingMetricToKeyResult(okr.id, metricId));
  };

  const createMetricLinkToOkr = async (okr, okrType, metric) => {
    if (okr.metrics?.length) {
      await removeMetricFromOkr(okr, okrType, okr.metrics[0].id);
    }

    const type = isObjective(okrType) ? OBJECTIVES : KEY_RESULTS;

    dispatch(createMetricAndAddToOkr(okr, metric, type));
  };

  return {
    updateMetricValue,
    removeMetricFromOkr,
    updateMetricBaseline,
    addMetricToOkr,
    createMetricLinkToOkr,
  };
};

export default useSingleMetricsForm;
