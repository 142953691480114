import { useCallback } from 'react';
import getRoadmapVisibleFieldsIds from '../components/CompareScenariosGrid/helpers/getRoadmapVisibleFieldsIds';
import { useSelector } from 'react-redux';
import { getSelectedRoadmapVersionsToCompare } from 'store/roadmapVersions/selectors';
import useRoadmapVersionsSupportedFields from 'routes/RoadmapVersions/hooks/useRoadmapVersionsSupportedFields';

/**
 * @function useCompareRoadmapVersionsOnChangeVisibleColumns this return a func to enchance
 * the visible columns when the selection on show fields changes. Since we have dynamic fields we need to enrich the
 * _onChangeColumnVisibility logic
 */
const useCompareRoadmapVersionsOnChangeVisibleColumns = () => {
  const selectedRoadmapVersions = useSelector(state => getSelectedRoadmapVersionsToCompare(state));
  const { roadmapVersionsFields } = useRoadmapVersionsSupportedFields();

  const enhanceOnChangeVisibleColumns = useCallback(
    newVisibleColumns => {
      const { roadmapFieldsVisible } = getRoadmapVisibleFieldsIds(
        newVisibleColumns,
        selectedRoadmapVersions,
        roadmapVersionsFields,
      );

      return [...newVisibleColumns, ...roadmapFieldsVisible];
    },
    [selectedRoadmapVersions, roadmapVersionsFields],
  );

  return { enhanceOnChangeVisibleColumns };
};

export default useCompareRoadmapVersionsOnChangeVisibleColumns;
