/**
 * AppLogo Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import styled from 'styled-components';

import Autocomplete from '../Autocomplete';

export default props => {
  return (
    <MyAutocomplete
      {...props}
      suggestionsTopOffset={2}
      inputProps={{
        ...(props.inputProps || {}),
        classes: {
          focused: 'focused',
          error: 'error',
        },
      }}
    />
  );
};

const mapPaddingSizes = {
  small: '8px 14px',
  medium: '12.5px 14px',
  large: '18.5px 14px',
};

const mapInputIconTop = {
  small: '0',
  medium: '6px',
  large: '12px',
};

const MyAutocomplete = styled(Autocomplete)`
  &&&& {
    box-sizing: border-box;
    width: 100%;

    div[aria-autocomplete='list'] {
      &.error:before,
      &.error:hover:before {
        border-color: ${props => props.theme.palette.text.error};
      }

      &.focused:not(.error):before {
        border: 2px solid ${props => props.theme.palette.background.primary};
      }

      &.focused.error:before {
        border: 2px solid ${props => props.theme.palette.text.error};
      }

      &:before {
        border-bottom: none;
        transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, border-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        border: 1px solid ${props => props.theme.palette.text.lilacTime};
        border-radius: 4px;
        width: 100%;
        height: 100%;
      }
      &:hover:not(.focused):not(.error):before {
        border-color: ${props => props.theme.palette.text.primary};
      }

      &:after:hover {
        border-bottom: none;
      }
      &.focused:after {
        border-bottom: none;
      }

      &.error:after {
        border-bottom: none;
      }
    }

    .autocomplete-input-icon {
      top: ${props => mapInputIconTop[props.size || 'large']};
      right: 4px;
      width: 28px;
      background: ${props => props.theme.palette.background.white};
    }

    input {
      padding: ${props => mapPaddingSizes[props.size || 'large']};

      &::placeholder {
        color: ${props => props.theme.palette.text.lilacTime};

        font-weight: ${props => props.theme.typography.fontWeightMedium};
        font-size: ${props => props.theme.typography.fontSize}px;
        font-family: ${props => props.theme.typography.fontFamily};
      }
    }
  }
`;
