import React from 'react';
import styled from 'styled-components';
import TruncateMarkup from 'react-truncate-markup';
import { pipe, split, head, pluck } from 'ramda';

const getKey = pipe(split('-'), head);

export default props => {
  return (
    <TruncateMarkup lines={props.truncateLines}>
      <div>
        {`Jira project `}
        <Bold>{getKey(props.renamedProject.key)}</Bold>
        {` might have been renamed and dragonboat integration needs to be reset.`}
        <br />
        {`As a result, stories ${pluck('id', props.orphanStories).join(
          ',',
        )} won't synchronize and update progress on the corresponding idea.`}
      </div>
    </TruncateMarkup>
  );
};

const Bold = styled.span`
  &&&& {
    font-weight: 700;
  }
`;
