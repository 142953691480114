import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import getSystemFieldName from 'utils/getSystemFieldName';

export const groupByOptions = ({
  systemFields,
  hasCorpRoadmap,
  hasProducts,
  hasCorpObjective,
  hasKeyResults,
  canView,
  hasProjectMetrics,
} = {}) => {
  const corpEntities = [
    ...(hasCorpRoadmap ? [{ key: 'roadmapCorp', title: getSystemFieldName('roadmapCorp', systemFields) }] : []),
    ...(hasCorpObjective ? [{ key: 'objectiveCorp', title: getSystemFieldName('objectiveCorp', systemFields) }] : []),
  ];

  return [
    ...corpEntities,
    { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
    ...(hasProducts ? [{ key: 'product1', title: getSystemFieldName('product1', systemFields) }] : []),
    ...(canView && canView(PERMISSION_RESOURCES.product2)
      ? [{ key: 'product2', title: getSystemFieldName('product2', systemFields) }]
      : []),
    { key: 'objective', title: getSystemFieldName('objective', systemFields) },
    ...(hasKeyResults ? [{ key: 'keyResult1', title: getSystemFieldName('keyResult1', systemFields) }] : []),
    ...(canView && canView(PERMISSION_RESOURCES.keyResult2)
      ? [{ key: 'keyResult2', title: getSystemFieldName('keyResult2', systemFields) }]
      : []),
    { key: 'theme', title: getSystemFieldName('theme', systemFields) },
    { key: 'category', title: getSystemFieldName('category', systemFields) },
    ...(hasProjectMetrics ? [{ key: 'metrics', title: 'Metrics' }] : []),
  ];
};

export const sumByOptions = ideasSystemFieldName => [
  { key: 'duration', shortTitle: 'Planned', title: 'Planned (assignments or estimates)' },
  { key: 'count', shortTitle: `# of ${ideasSystemFieldName}`, title: `# of ${ideasSystemFieldName}` },
];

export const durationOptions = [
  { key: 'day', title: 'In days' },
  { key: 'week', title: 'In weeks' },
];

export const defaultTableColumns = ['showHealth', 'showCurrentAllocationPercentage', 'showReportedAllocationPercentage'];
