import React, { useImperativeHandle, useState } from 'react';

import MetadataAutocomplete from '../AppliesToAutocomplete';
import cellEditorHoc from 'design-system/molecules/AgGridReact-New/helpers/cellEditorHoc';
import { getLevelTitlePropertyName } from '../helpers/componentHelpers';

const MetadataAutocompleteCellEditor = cellEditorHoc(({ onChange, forwardedRef, ...params }) => {
  const {
    value,
    stopEditing,
    node: { data },
    roadmapsKey,
  } = params;

  const [internalValue, setInternalValue] = useState(undefined);

  useImperativeHandle(forwardedRef, () => ({
    getValue() {
      return internalValue !== undefined ? internalValue : value;
    },
  }));

  const handleChange = selectedItem => {
    const titleProp = getLevelTitlePropertyName(roadmapsKey);

    setInternalValue(selectedItem?.[titleProp] || '');

    onChange(selectedItem, { stopEditing, data });
  };

  return <MetadataAutocomplete {...params} onSelectOptionCallback={handleChange} />;
});

export default MetadataAutocompleteCellEditor;
