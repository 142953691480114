import { LinkExtension } from 'remirror/extensions';
import { isElementDomNode } from '@remirror/core-utils';

export default class RemirrorLinkExtension extends LinkExtension {
  createMarkSpec(extra, override) {
    const originalMarkSpec = super.createMarkSpec(extra, override);

    originalMarkSpec.attrs = {
      ...originalMarkSpec.attrs,
      data: { default: {} },
    };

    originalMarkSpec.toDOM = node => {
      const attributes = {
        ...extra.dom(node),
        rel: 'noreferrer nofollow',
        target: '_blank',
        href: node.attrs.href,
      };

      if (node.attrs.data.downloadUrl) {
        attributes.href = node.attrs.data.downloadUrl;
        attributes.target = '_self';
        delete attributes.rel;
      }

      return ['a', attributes, 0];
    };

    originalMarkSpec.parseDOM = [
      {
        tag: 'a[href]',
        getAttrs: node => {
          if (!isElementDomNode(node)) {
            return false;
          }

          const href = node.getAttribute('href');
          const target = node.getAttribute('target');

          const data = {
            ...(node.dataset || {}),
            originalHref: href,
          };

          const extraAttributes = extra.parse(node);
          const { baseURLForAttachments } = extraAttributes;

          const isJiraCloudAttachment = !!data.attachmentType;
          const isJiraServerAttachment = href.includes('/secure/attachment') && !!node.querySelector('img.rendericon');
          const isJiraAttachment = isJiraCloudAttachment || isJiraServerAttachment;

          if (isJiraAttachment) {
            // storybook breaks with
            // data.attachmentType ??= 'file';
            data.attachmentType = data.attachmentType || 'file';

            if (baseURLForAttachments) {
              const baseUrl = baseURLForAttachments.replace(/\/$/, '');

              data.downloadUrl = `${baseUrl}?attachmentUrl=${href}`;
            }
          }

          return {
            ...extra.parse(node),
            href,
            data,
            target: target || '_blank',
          };
        },
      },
      ...(override.parseDOM || []),
    ];

    return originalMarkSpec;
  }
}
