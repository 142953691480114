import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import isPlainObject from 'lodash/isPlainObject';
import { defaultTo, head, identity, ifElse, isEmpty, isNil, map, of, pipe, prop, reject } from 'ramda';

import { TREE_BRANCH_FIELDS, TREE_BRANCHES } from 'constants/branches';

import { getMetrics } from 'store/metrics/selectors';
import { getUserName } from 'utils/index';
import { getOrderForEntities } from 'routes/Dashboard/Snapshot/helpers'; // move to central spot
import { verifyMetadataEntities } from 'utils/metadataRoadmaps';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

const UNDEFINED_ID = 'null';

const defaultToEmptyArray = defaultTo([]);

const extractPropFromTopElement = property =>
  pipe(
    defaultToEmptyArray,
    reject(isNil),
    ifElse(isEmpty, () => null, pipe(head, prop(property))),
  );

export default function useSnapshotEntities(
  selectedSnapshotField,
  hasMetadataRoadmaps,
  hideEmptyCards,
  reportData = [],
  pageFilters = {},
  usableMetadata = {},
) {
  const [treeData, setTreeData] = useState({
    roots: [],
    branches: [],
  });
  const [selectedTreeEntity, setSelectedTreeEntity] = useState(null);

  const metrics = useSelector(getMetrics);

  const { isDodActive } = useOrganizationsAccessControl();

  const getEntityMetrics = pipe(
    defaultToEmptyArray,
    ifElse(isPlainObject, of, identity),
    reject(isNil),
    map(m => metrics.find(formatted => formatted.id === m.id)),
  );

  const usableMetadataFieldName = useMemo(() => `${selectedSnapshotField}s`, [selectedSnapshotField]);

  const selectedUsableMetadata = useMemo(
    () => usableMetadata[usableMetadataFieldName],
    [usableMetadata, usableMetadataFieldName],
  );

  const allReportEntities = useMemo(() => {
    return reportData.map(e => {
      const entity = {
        ...e,
        metrics: getEntityMetrics(e.metrics),
        uniqueId: String(e.id),
        ideasProgress: e.projectsProgress,
        group: selectedSnapshotField,
        metric_name: extractPropFromTopElement('name')(e.metrics),
        metric_current: extractPropFromTopElement('actual_value')(e.metrics),
        metric_target: extractPropFromTopElement('target_value')(e.metrics),
        ownerName: e.owner ? getUserName(e.owner) : '',
      };

      return entity;
    });
  }, [reportData, metrics, selectedSnapshotField]);

  const entities = useMemo(() => {
    let entities = allReportEntities.filter(e => {
      if (e.metadataName && e.metadataName !== selectedSnapshotField) {
        return false;
      }

      const hasIdeas = e.nProjects > 0;

      return hideEmptyCards ? hasIdeas : true;
    });

    if (Object.keys(TREE_BRANCH_FIELDS(isDodActive)).includes(selectedSnapshotField)) {
      entities = getOrderForEntities(selectedSnapshotField, entities, usableMetadata, isDodActive);
    }

    if (hasMetadataRoadmaps && (pageFilters.quickFilters?.roadmaps || pageFilters.quickFilters?.products)) {
      entities = verifyMetadataEntities(entities, usableMetadata);
    }

    entities = entities.map(e => {
      if (e.metadataName === 'objectiveCorp' && usableMetadata?.objectivesCorp) {
        return {
          ...e,
          title: usableMetadata.objectivesCorp.find(m => m.id === e.id)?.title || e.title,
        };
      }

      return e;
    });

    entities = selectedUsableMetadata
      ? entities.map(entity => {
          const entityInUsableMetadata = selectedUsableMetadata.find(metadataEntity => metadataEntity.id === entity.id) || {};

          return {
            ...entity,
            ...entityInUsableMetadata,
            id: entity.id || UNDEFINED_ID,
            metrics: getEntityMetrics(entity.metrics),
          };
        })
      : entities;

    return entities;
  }, [allReportEntities, selectedUsableMetadata, hideEmptyCards, selectedSnapshotField]);

  const _getSelectedEntityFromReport = (entity, entities) =>
    entities.find(e => {
      return e.id === entity.id && entity.metadataName === e.metadataName;
    });

  const _generateBranches = (entity, branchGroup) => {
    return allReportEntities
      .filter(entity => entity.metadataName === branchGroup)
      .map(e => ({
        ...e,
        group: branchGroup,
      }))
      .filter(e => e[TREE_BRANCH_FIELDS(isDodActive)[branchGroup]] === entity.id);
  };

  const updateTreeData = (entity, refresh = false) => {
    const branchGroup = TREE_BRANCHES[entity.group || selectedSnapshotField];

    if (!branchGroup) {
      return;
    }

    let updatedTreeData = {
      roots: [],
      branches: [],
    };

    // if report data changes, we only need refresh the existing tree
    if (refresh) {
      updatedTreeData.roots = treeData.roots.map(root => {
        const updatedEntity = _getSelectedEntityFromReport(root, allReportEntities);

        return {
          ...updatedEntity,
          group: root.group,
        };
      });

      updatedTreeData.branches = treeData.branches.map(branch => {
        const updatedEntity = _getSelectedEntityFromReport(branch, allReportEntities);

        return {
          ...updatedEntity,
          group: branch.group,
        };
      });
    } else {
      setSelectedTreeEntity(entity);

      // Check is branch entity
      const branchIndex = treeData.branches.findIndex(branch => `${branch.id}` === `${entity.id}`);
      // Check is root entity
      const rootIndex = treeData.roots.findIndex(root => `${root.id}` === `${entity.id}`);

      let branches = [];

      if (rootIndex !== 0) {
        branches = _generateBranches(entity, branchGroup);
      }

      if (rootIndex === -1 && branchIndex === -1) {
        // First time to select card
        updatedTreeData = {
          roots: [
            {
              ...entity,
              group: selectedSnapshotField,
            },
          ],
          branches,
        };
      } else if (branchIndex > -1) {
        // Branch Entity selected
        updatedTreeData = {
          roots: [...treeData.roots, { ...entity }],
          branches,
        };
      } else if (rootIndex > -1) {
        // Root Entity selected
        if (rootIndex === 0) {
          // Head root is selected
          updatedTreeData = {
            roots: [],
            branches: [],
          };

          setSelectedTreeEntity(null);
        } else {
          updatedTreeData = {
            roots: [...treeData.roots],
            branches,
          };

          updatedTreeData.roots.splice(rootIndex, 1);

          if (!branches.length) {
            const root = updatedTreeData.roots[rootIndex - 1];
            const newBranches = _generateBranches(root, TREE_BRANCHES[root.group || selectedSnapshotField]);

            updatedTreeData.branches = [...newBranches];
          }
        }
      }
    }

    setTreeData(updatedTreeData);
  };

  // Resets tree data when selected snapshot field changes
  useEffect(() => {
    setTreeData({
      roots: [],
      branches: [],
    });
    setSelectedTreeEntity(null);
  }, [selectedSnapshotField]);

  useEffect(() => {
    if (selectedTreeEntity) {
      updateTreeData(selectedTreeEntity, true);
    }
  }, [allReportEntities]);

  return {
    allReportEntities,
    entities,
    treeData,
    updateTreeData,
  };
}
