import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { uniq } from 'ramda';

import { selectSelectedSnapsDiffs } from 'features/RoadmapHistory/store/selectors';

const useCompareRoadmapHistoryGridSnapsDiffs = () => {
  const selectedSnapDiffs = useSelector(selectSelectedSnapsDiffs);

  const changedFieldsByProjectBySnapDiff = useMemo(
    () =>
      selectedSnapDiffs.reduce((acc, snapDiff) => {
        const changedFieldsByProjectId = snapDiff?.changesByProject?.reduce((acc, change) => {
          return {
            ...acc,
            [change?.projectId]: uniq([...(acc[change?.projectId] || []), ...Object.keys(change?.changes)]),
          };
        }, {});

        return {
          ...acc,
          [snapDiff.id]: {
            src: snapDiff.src,
            target: snapDiff.target,
            changedFieldsByProjectId,
          },
        };
      }, {}),
    [selectedSnapDiffs],
  );

  return changedFieldsByProjectBySnapDiff;
};

export default useCompareRoadmapHistoryGridSnapsDiffs;
