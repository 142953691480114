import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { TextCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { getProductColumnDef } from 'utils/grids/columns';
import { PRODUCT_1_LEVEL, PRODUCT_2_LEVEL } from 'constants/roadmaps';
import useSystemFields from 'hooks/useSystemFields';
import dateColumnDef from 'design-system/molecules/AgGridReact-New/columns/dateColumnDef';
import getTitleColumnDef from 'design-system/molecules/AgGridReact-New/columns/getTitleColumnDef';
import { useMemo } from 'react';

const idColumnDef = {
  field: 'id',
  headerName: 'ID',
  editable: false,
  width: 50,
};

const titleColumnDef = {
  ...getTitleColumnDef(),
  editable: false,
  width: 400,
};

const statusColumnDef = {
  field: 'status',
  headerName: 'Status',
  filter: false,
  enableRowGroup: true,
  comparator: stringComparator,
  width: 100,
};

const desiredTimeColumnDef = {
  ...dateColumnDef,
  field: 'desired_time',
  headerName: 'Desired Time',
  width: 100,
};

/**
 * Custom hook that returns an object containing column definitions for a preview list.
 * The column definitions are used in a data grid component.
 *
 * @returns {Object} An object containing column definitions for the preview list.
 */
function usePreviewListColumnsDefs() {
  const [getSystemFieldName] = useSystemFields();

  const priorityColumnDef = useMemo(
    () => ({
      field: 'priority',
      headerName: getSystemFieldName('priority'),
      comparator: stringComparator,
      width: 120,
    }),
    [],
  );

  const ownerColumnDef = useMemo(
    () => ({
      field: 'ownerName',
      headerName: 'Owner',
      cellRenderer: TextCellRenderer,
      comparator: stringComparator,
      width: 120,
    }),
    [],
  );

  const roadmapColumnDef = useMemo(
    () => ({
      field: 'roadmap',
      headerName: getSystemFieldName('roadmap'),
      cellRenderer: TextCellRenderer,
      comparator: stringComparator,
      width: 120,
    }),
    [],
  );

  const product1ColumnDef = useMemo(
    () =>
      getProductColumnDef({
        field: 'product1',
        headerName: getSystemFieldName('product1'),
        selectedLevel: PRODUCT_1_LEVEL,
        getFilteredRoadmaps: params => params.context.filteredMetadata.roadmaps,
        cellRenderer: TextCellRenderer,
      }),
    [],
  );

  const product2ColumnDef = useMemo(
    () =>
      getProductColumnDef({
        field: 'product2',
        headerName: getSystemFieldName('product1'),
        selectedLevel: PRODUCT_2_LEVEL,
        getFilteredRoadmaps: params => params.context.filteredMetadata.roadmaps,
        cellRenderer: TextCellRenderer,
      }),
    [],
  );

  // TODO: missing support on BE
  // const tagsColumnDef = {
  //   field: 'tags',
  //   headerName: getSystemFieldName('tag'),
  //   width: 120,
  //   labelField: 'title',
  //   cellRenderer: ChipsCellRenderer,
  //   comparator: multiSelectComparator,
  // };

  // TODO: missing support on BE
  // const customersColumnDef = {
  //   field: 'customers',
  //   headerName: getSystemFieldName('customer'),
  //   width: 120,
  //   labelField: 'name',
  //   keyCreator: ({ value }) => (value?.length ? value.map(c => c.name) : [null]).join('-'),
  //   cellRenderer: ChipsCellRenderer,
  //   showTooltip: true,
  //   comparator: multiSelectComparator,
  // };

  // TODO: missing support on BE
  // const customFieldsColumnDefs = cf =>
  //   getCustomFieldColumnDef({
  //     ...cf,
  //     fieldType: cf.field_type,
  //     dataEnhanced: cf.data_enhanced,
  //   });

  return useMemo(
    () => ({
      idColumnDef,
      titleColumnDef,
      statusColumnDef,
      priorityColumnDef,
      ownerColumnDef,
      roadmapColumnDef,
      product1ColumnDef,
      product2ColumnDef,
      desiredTimeColumnDef,
    }),
    [priorityColumnDef, ownerColumnDef, roadmapColumnDef, product1ColumnDef, product2ColumnDef],
  );
}

export default usePreviewListColumnsDefs;
