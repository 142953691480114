import axios from 'axios';

import throwRequestError from 'store/utils/throwRequestError';
import { createThunk } from 'utils/store/thunk';

import { FETCH_FIELDS_LAYOUT, UPSERT_FIELDS_LAYOUT } from './types';

const fetchFieldsLayout = (type, level) =>
  createThunk(FETCH_FIELDS_LAYOUT, axios.get(`/api/fieldsLayout/${type}/${level}`).catch(throwRequestError), {
    type,
    level,
  });

const upsertFieldsLayout = (type, level, data) =>
  createThunk(UPSERT_FIELDS_LAYOUT, axios.put(`/api/fieldsLayout/${type}/${level}`, data).catch(throwRequestError), {
    type,
    level,
  });

export { fetchFieldsLayout, upsertFieldsLayout };
