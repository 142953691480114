import { MetadataTreeEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';

const getMetadataTreeEditorColumnDef = ({ getItems, shouldAcceptSelection, handleUpdate }) => {
  const handleChange = async (selectedOption, { data, stopEditing }) => {
    if (shouldAcceptSelection(selectedOption)) {
      await handleUpdate(data.id, selectedOption);

      stopEditing();
    }
  };

  return {
    cellEditor: MetadataTreeEditor,
    cellEditorParams: params => {
      return {
        items: getItems(params),
        onChange: handleChange,
        hasClearSelection: true,
        hasMultiLevel: true,
        isAgGridCell: true,
        displaySelectedValue: true,
        styleAsAutocomplete: true,
        forceOpen: true,
      };
    },
    overrideCellValueChanged: true,
    cellEditorPopup: true,
  };
};

export default getMetadataTreeEditorColumnDef;
