import React from 'react';
import PropTypes from 'prop-types';

import AdvancedSearchHeader from 'design-system/molecules/AdvancedSearchHeader/index';


/*
 * Footer to be displayed on Goals advanced search popover
 */
const SearchHeader = ({ title }) => {
  return <AdvancedSearchHeader>{title}</AdvancedSearchHeader>;
};

SearchHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SearchHeader;
