import React, { useEffect, useState } from 'react';

import Dialog from 'design-system/molecules/Dialog/index';

import { removeGanttTolltip } from 'components/ganttCommon';
import { PROJECTS_LIGHTBOX_Z_INDEX } from 'constants/projectLightbox';

import Timeline from './components/Timeline';
import useTimelineLightboxData from './hooks/useTimelineLightboxData';

// Should be less than the projects lightbox z-index
const timelineLightboxZIndex = PROJECTS_LIGHTBOX_Z_INDEX - 100;

export default ({
  onOpen,
  onClose,
  groupedBy,
  entityGroup,
  lazyLoadProjects,
  getLazyLoadProjectsSubFilter,
  hideMaxItemDisplayMessage = false,
  isGoalMode,
  groupBySelection,
  filtersForApi,
  portfolioMode,
  displayLayer,
  openItems,
}) => {
  const { loadProjects, clearProjects } = useTimelineLightboxData({ displayLayer });

  const [open, setOpen] = useState(false);
  const openTrigger = (filters = filtersForApi) => {
    setOpen(true);
    loadProjects(filters);
  };

  const closeDialog = () => {
    removeGanttTolltip();
    setOpen(false);
    clearProjects();

    if (onClose) onClose();
  };

  useEffect(() => onOpen(openTrigger), []);

  return (
    <Dialog
      data-test="timeline-dialog"
      open={!!open}
      onClose={closeDialog}
      maxWidth="lg"
      scroll="paper"
      fullWidth
      closeButton
      title=""
      zIndex={timelineLightboxZIndex}
    >
      {open && (
        <Timeline
          openItems={openItems}
          groupedBy={groupedBy}
          entityGroup={entityGroup}
          lazyLoadProjects={lazyLoadProjects}
          getLazyLoadProjectsSubFilter={getLazyLoadProjectsSubFilter}
          hideMaxItemDisplayMessage={hideMaxItemDisplayMessage}
          isGoalMode={isGoalMode}
          groupBySelection={groupBySelection}
          portfolioMode={portfolioMode}
          displayLayer={displayLayer}
        />
      )}
    </Dialog>
  );
};
