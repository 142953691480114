import {
  GET_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION_FULFILLED,
  UPDATE_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION_FULFILLED,
} from '../types';

const initialState = {};

const customerRequestStatusMappingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION_FULFILLED:
    case UPDATE_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION_FULFILLED: {
      return action.payload?.data ?? {};
    }

    default: {
      return state;
    }
  }
};

export { customerRequestStatusMappingReducer, initialState };

export default customerRequestStatusMappingReducer;
