import React from 'react';
import { useSelector } from 'react-redux';
import { defaultTo } from 'ramda';

import { UNDEFINED_LABEL } from 'constants/common';
import Text from 'design-system/atoms/Text';

import { Wrapper, ClusterItem, Counter, ItemTitle } from './styles';
import { getClustersDataForAnalyseChart } from '../store';

const defaultAsUndefinedTitle = defaultTo(UNDEFINED_LABEL);
const defaultAsZero = defaultTo(0);

export default ({ onClusterClick }) => {
  const data = useSelector(getClustersDataForAnalyseChart);

  const sortByClusterSize = (a, b) => defaultAsZero(b.customer_requests?.length) - defaultAsZero(a.customer_requests?.length);

  const _renderRowInfo = entry => {
    return (
      <ClusterItem color={entry.color} key={entry.id} onClick={() => onClusterClick(entry)}>
        <ItemTitle>{defaultAsUndefinedTitle(entry.title)}</ItemTitle>
        <Counter>
          <Text>({defaultAsZero(entry.customer_requests?.length)})</Text>
        </Counter>
      </ClusterItem>
    );
  };

  if (!data || !data?.length) {
    return '';
  }

  return <Wrapper>{data.sort(sortByClusterSize).map(_renderRowInfo)}</Wrapper>;
};
