import anchorme from 'anchorme';

export default (draftjsEditorState, mentions, fallback) => {
  if (draftjsEditorState && draftjsEditorState.blocks && mentions) {
    let commentContent = '';

    const mentionsKeys = Object.entries(draftjsEditorState.entityMap).reduce((final, [key, value]) => {
      const userId = value && value.data && value.data.mention && value.data.mention.id;

      if (userId) {
        const mention = mentions.find(mention => mention.id === userId);

        if (mention) {
          final[key] = mention;
        }
      }

      return final;
    }, {});

    draftjsEditorState.blocks.forEach(block => {
      let blockContent = '';

      let cursor = 0;

      if (block && block.entityRanges && block.entityRanges.length) {
        block.entityRanges.forEach((range) => {
          const mentionUser = mentionsKeys[range.key];

          blockContent += block.text.substring(cursor, range.offset);

          if (mentionUser) {
            blockContent += `<span class="mention">${
              range.length > 10 ?
                `${mentionUser.first_name} ${mentionUser.last_name}` :
                `${mentionUser.first_name}`
            }</span>`;
          } else {
            blockContent += block.text.substring(range.offset, range.offset + range.length);
          }

          cursor = range.offset + range.length;
        });

        // add last piece of text
        blockContent += block.text.substring(cursor);
      } else {
        blockContent = block.text;
      }

      commentContent += `${blockContent}<br />`;
    });

    // TODO we may want to use linkifyHtmlString from utils instead
    return anchorme(commentContent);
  }

  return `<p>${fallback}</p>`;
};
