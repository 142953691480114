import React from 'react';
import styled from 'styled-components';

import withTheme from '@material-ui/core/styles/withTheme';

import AddProjectButton from 'components/AddProjectButton/AddProjectButton';

const HeaderOptions = props => {
  return <StyledAddProjectButton {...props} />;
};

const StyledAddProjectButton = styled(AddProjectButton)`
  &&&& {
    padding-right: 20px;
  }
`;

export default withTheme()(HeaderOptions);
