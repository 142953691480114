import { useSettingsGridEventHandlers } from 'containers/Grids/SettingsGrid/hooks';
import { ERROR_LOGS } from 'store/grids/constants';

const useErrorLogsGridEventHandlers = ({ errorLogs }) => {
  return useSettingsGridEventHandlers({
    settingType: ERROR_LOGS,
    items: errorLogs,
    focusField: 'app_name',
    actions: {},
  });
};

export default useErrorLogsGridEventHandlers;
