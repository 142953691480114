import { defaultTo, has } from 'ramda';
import { PAGES_STATE_TO_SAVE, updatePageActiveView } from 'store/userViews';
import { getPageIdFromPath } from 'utils/userViews';

const defaultToEmptyObject = defaultTo({});

export default ({ getState, dispatch }) =>
  next =>
  action => {
    const meta = defaultToEmptyObject(action.meta);
    const pageId = getPageIdFromPath(window.location.pathname);

    const stateToViewHandler = PAGES_STATE_TO_SAVE[pageId];
    const needsViewUpdateFromStore = has('makesActiveViewDirty', meta) && stateToViewHandler;

    next(action);

    if (!needsViewUpdateFromStore) {
      return;
    }

    dispatch(updatePageActiveView(pageId, { state: stateToViewHandler(getState()) }, meta.makesActiveViewDirty));
  };
