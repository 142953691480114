import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMultiFilters, getSearch } from 'store/customerRequests/selectors';
import {
  voteOnCustomerRequest as voteOnCustomerRequestAction,
  unvoteOnCustomerRequest as unvoteOnCustomerRequestAction,
} from 'store/votes/actions';
import useHiddenFields from 'hooks/useHiddenFields';
import { setCustomerRequestsMultiFilters } from 'store/customerRequests/thunks';
import { addQueryParamToUrl } from 'utils/queryParamsUtils';
import { OPEN_CUSTOMER_REQUEST_QUERY_PARAM } from 'constants/customerRequests';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

export default Component => {
  return props => {
    const dispatch = useDispatch();
    const multiFilters = useSelector(getMultiFilters);
    const { checkFieldDisplay } = useHiddenFields();
    const { canView } = usePermissions();

    const canViewRequestDrawer = canView(PERMISSION_FEATURES.customerRequestsDrawer);

    const startEditingCustomerRequest = React.useCallback(() => {
      if (!canViewRequestDrawer) {
        return;
      }
      addQueryParamToUrl(OPEN_CUSTOMER_REQUEST_QUERY_PARAM, props.item.key);
    }, [props.item.key, canViewRequestDrawer]);

    const addCustomerRequestsFilter = React.useCallback(
      (field, value) => {
        const currentFieldFilter = multiFilters.find(f => f.key === field);
        let values = [];

        if (!currentFieldFilter) values.push(value);
        else if (currentFieldFilter.values.includes(value)) values = currentFieldFilter.values.filter(v => v !== value);
        else if (!currentFieldFilter.values.includes(value)) values = [...currentFieldFilter.values, value];

        dispatch(
          setCustomerRequestsMultiFilters([
            ...multiFilters.filter(f => f.key !== field),
            {
              key: field,
              values,
            },
          ]),
        );
      },
      [props.item.id, multiFilters],
    );
    const search = useSelector(getSearch);

    const voteOnCustomerRequest = React.useCallback(() => dispatch(voteOnCustomerRequestAction(props.item.id)), [props.item.id]);
    const unvoteOnCustomerRequest = React.useCallback(
      () => dispatch(unvoteOnCustomerRequestAction(props.item.id)),
      [props.item.id],
    );

    return (
      <Component
        startEditingCustomerRequest={startEditingCustomerRequest}
        addCustomerRequestsFilter={addCustomerRequestsFilter}
        search={search}
        voteOnCustomerRequest={voteOnCustomerRequest}
        unvoteOnCustomerRequest={unvoteOnCustomerRequest}
        checkFieldDisplay={checkFieldDisplay}
        {...props}
      />
    );
  };
};
