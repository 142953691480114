import { FORECAST_BY_TIME, FORECAST_BY_HEADCOUNT, FORECAST_BY_TIME_CAPACITY } from 'constants/forecast';
import getEstimateWeekDur from 'utils/getEstimateWeekDur';

/**
 * @function getEstimateCellValueBasedOnForecastBy
 *
 * Gets the estimated cell value based on the forecast by option (can be by time/staff number)
 *
 * @param  {String} forecastBy
 * @param  {Number} duration
 * @param  {Number} numStaff
 * @param  {Object} curValue
 * @return {String}
 */
const getEstimateCellValueBasedOnForecastBy = (duration, numStaff, curValue, forecastBy = FORECAST_BY_TIME) => {
  const valueByForecastType = {
    [FORECAST_BY_TIME]: () => getEstimateWeekDur(duration, numStaff, curValue),
    [FORECAST_BY_TIME_CAPACITY]: () => getEstimateWeekDur(duration, numStaff, curValue),
    [FORECAST_BY_HEADCOUNT]: () => numStaff,
  };
  const getValueFn = valueByForecastType[forecastBy];

  if (!getValueFn) {
    return '';
  }

  return getValueFn();
};

export default getEstimateCellValueBasedOnForecastBy;
