import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { filterActiveItens } from 'utils';
import sortByRank from 'utils/sortByRank';

export function getState(state) {
  return state.priorities;
}

export const makeGetPriorities = () =>
  createCachedSelector([getState, (_, showArchived) => showArchived], (state, showArchived) => {
    return (showArchived ? state?.priorities : state?.priorities?.filter(filterActiveItens))?.sort(sortByRank);
  })((_, showArchived) => `${showArchived}`);

export const getPriorities = makeGetPriorities();

export const makeGetNormalizedPriorities = () => {
  const _localGetPriorities = makeGetPriorities();

  return createSelector(
    state => _localGetPriorities(state, true),
    state => {
      return state.reduce((objs, obj) => {
        objs[obj.id] = obj;
        return objs;
      }, {});
    },
  );
};

export const getNormalizedPriorities = makeGetNormalizedPriorities();
