import React from 'react';
import styled, { css } from 'styled-components';
import Grid from '@material-ui/core/Grid';

import FluidContainer from 'design-system/atoms/FluidContainer/index';
import FluidFieldGroup from 'design-system/molecules/FluidFieldGroup/index';
import FluidTextField from 'design-system/organisms/FluidTextField/index';
import FluidSelectField from 'design-system/organisms/FluidSelectField/index';
import FluidHealthField from 'design-system/organisms/FluidHealthField/index';
import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';
import FluidMetadataRoadmapsField from 'design-system/organisms/FluidMetadataRoadmapsField/index';
import FluidColorField from 'design-system/molecules/FluidColorField/index';

import FluidMetadataAutocompleteField from 'containers/FluidMetadataAutocompleteField';
import LoadableUsersAutocomplete from 'containers/LoadableUsersAutocomplete';
import MetricValuesForm from 'containers/MetricsForm/components/MetricValuesForm';
import FluidByTeamAutocompleteField from 'shared/components/inputs/Autocomplete/formFields/FluidByTeamAutocompleteField';

import FluidMetricDataSourceField from 'features/MetricsDialog/components/FluidMetricDataSourceField';
import getMetricLevelsOptions from 'features/MetricLevels/utils/getMetricLevelsOptions';

import formatDateTime from 'utils/dates/formatDateTime';
import { getUserName } from 'utils';
import Comments from './Comments';

import useMetricDetails from '../../hooks/useMetricDetails';

const getLevelIdFromOptions = (levelsOptions, label) => levelsOptions.find(opt => opt.label === label)?.value;

const MetricDetails = ({
  selectedMetric,
  updateMetric,
  updateMetricValue,
  addMetricValue,
  canEditMetric,
  displayConditionalFields,
}) => {
  const {
    metricLevels,
    users,
    usersOptions,
    availableRoadmaps,
    getSelectedRoadmapItems,
    updateSelectedRoadmapItems,
    formatMetadataRoadmaps,
    formatRoadmaps,
    handleClearAllRoadmapItems,
    addDefaultMetadataRoadmapName,
    removeMetadataRoadmap,
    canViewMetricLevels,
    canViewMetricSummary,
    canViewMetadataRoadmaps,
    canViewMetricIntegration,
    showMetricComments,
    updateMetricDataSource,
    getSystemFieldName,
    hasNewMetadataAutocomplete,
  } = useMetricDetails({ selectedMetric });

  const metricLevelsOptions = getMetricLevelsOptions(metricLevels, true);

  const metricIntegrationData = selectedMetric?.metricIntegrations?.[0];

  const ownerName = selectedMetric.owner && getUserName(selectedMetric.owner);

  return (
    <Form key={`metric-details-${selectedMetric?.id}`} autoComplete="off">
      <Grid container xs={12} justify="space-around">
        <FluidContainer size={6}>
          <StyledFluidRemirrorWYSIWYGEditor
            key="details"
            value={selectedMetric.details}
            content={selectedMetric.details}
            label="Metric details"
            onChange={value => updateMetric({ details: value })}
            placeholder="Write something here..."
            disableEdit={!canEditMetric}
          />
          {canViewMetricIntegration ? (
            <FluidMetricDataSourceField
              value={metricIntegrationData?.external_url}
              integrationData={metricIntegrationData}
              label="Data source"
              onChange={updateMetricDataSource}
              disableEdit={!canEditMetric}
            />
          ) : null}
          <StyledFluidRemirrorWYSIWYGEditor
            key="links"
            value={selectedMetric.link}
            content={selectedMetric.link}
            placeholder="Add some links here..."
            label="Links"
            onChange={value => updateMetric({ link: value })}
            disableEdit={!canEditMetric}
            smallInput
          />
          {canViewMetricSummary ? (
            <StyledFluidRemirrorWYSIWYGEditor
              key="summary"
              value={selectedMetric.summary}
              content={selectedMetric.summary}
              label="Status summary"
              onChange={value => updateMetric({ summary: value })}
              placeholder="Write something here..."
              disableEdit={!canEditMetric}
            />
          ) : null}
          <MetricValuesForm
            metric={selectedMetric}
            canAddOrEdit={canEditMetric}
            updateMetric={(_, update) => updateMetric(update)}
            updateMetricValue={updateMetricValue}
            addMetricValue={addMetricValue}
          />
        </FluidContainer>

        <FluidContainer size={5} leftBorder>
          <FluidColorField
            key="color"
            value={selectedMetric.color}
            label="Display Color"
            onChange={value => updateMetric({ color: value.hex })}
            format="row"
            disableEdit={!canEditMetric}
          />
          {canViewMetricLevels ? (
            <FluidSelectField
              key="level"
              label="Type"
              value={metricLevels[selectedMetric?.level]?.title}
              onChange={value => updateMetric({ level: getLevelIdFromOptions(metricLevelsOptions, value) })}
              options={metricLevelsOptions.map(l => l.label)}
              placeholder="Select a level"
              format="row"
              disableEdit={!canEditMetric}
            />
          ) : null}
          {!hasNewMetadataAutocomplete ? (
            <FluidMetadataAutocompleteField
              key="users"
              value={ownerName}
              label="Owner"
              metadata="users"
              onChange={value => updateMetric({ owner_id: value })}
              format="row"
              autocompleteComponent={LoadableUsersAutocomplete}
              autocompleteProps={{
                suggestions: users,
              }}
              disableEdit={!canEditMetric}
            />
          ) : (
            <FluidByTeamAutocompleteField
              key="users"
              name="users"
              label={getSystemFieldName('owner')}
              value={[ownerName]}
              options={usersOptions || []}
              onChange={user => updateMetric({ owner_id: user?.id || null })}
              disableEdit={!canEditMetric}
              getSystemFieldName={getSystemFieldName}
              format="row"
              withNullOption
              focusOnInit
            />
          )}
          {canViewMetadataRoadmaps ? (
            <FluidMetadataRoadmapsField
              key="applies-to"
              label="Applies to"
              format="row"
              roadmaps={availableRoadmaps}
              selected={{ ...selectedMetric, uniqueId: selectedMetric.id }}
              getSelectedItems={getSelectedRoadmapItems}
              data={addDefaultMetadataRoadmapName({
                value: formatMetadataRoadmaps(selectedMetric?.metric_roadmaps || []),
                data: selectedMetric,
              })}
              updateSelectedItems={updateSelectedRoadmapItems}
              handleClear={handleClearAllRoadmapItems}
              objectType=""
              disableEdit={!canEditMetric}
              formatRoadmaps={formatRoadmaps}
              removeMetadataRoadmap={removeMetadataRoadmap}
            />
          ) : null}
          <FluidHealthField
            key="health"
            value={selectedMetric.status_color}
            label="Metric Health"
            onChange={value => updateMetric({ status_color: value })}
            format="row"
            disableEdit={!canEditMetric}
          />
          {displayConditionalFields || showMetricComments ? (
            <FluidFieldGroup topBorder>
              {showMetricComments && <Comments metricId={selectedMetric.id} />}
              {displayConditionalFields ? (
                <>
                  <StyledFluidTextField
                    key="updatedBy"
                    value={getUserName(selectedMetric.updatedBy)}
                    label="Updated by"
                    format="row"
                    disableEdit
                  />
                  <StyledFluidTextField
                    key="updatedAt"
                    value={formatDateTime(selectedMetric.updated_at)}
                    label="Updated at"
                    format="row"
                    disableEdit
                  />
                  <StyledFluidTextField
                    key="createdAt"
                    value={formatDateTime(selectedMetric.created_at)}
                    label="Created at"
                    format="row"
                    disableEdit
                  />
                </>
              ) : null}
            </FluidFieldGroup>
          ) : null}
        </FluidContainer>
      </Grid>
    </Form>
  );
};

export default MetricDetails;

const Form = styled.form`
  width: 100%;
`;

const StyledFluidTextField = styled(FluidTextField)`
  input {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  }
`;

const StyledFluidRemirrorWYSIWYGEditor = styled(FluidRemirrorWYSIWYGEditor)`
  ${({ smallInput }) =>
    !smallInput &&
    css`
      && .ProseMirror {
        min-height: 90px;
      }
    `}
  .remirror-editor-wrapper {
    max-height: 400px;
    overflow-y: auto;
  }
`;
