import React, { useState } from 'react';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import SlackIcon from 'src/routes/Settings/Integration/Slack/slack-logo.png';
import useSystemFields from 'hooks/useSystemFields';
import Checkbox from 'design-system/atoms/Checkbox/index';
import PageTitle from 'design-system/atoms/PageTitle/index';

import SearchableListSelect from 'src/design-system/molecules/SearchableListSelect';

const Notifications = ({
  showSlackColumn,
  notificationsPreferences: receivedNotificationsPreferences = {},
  updateNotifications,
  slackChannels,
}) => {
  const [state, setNotificationsState] = useState(receivedNotificationsPreferences);

  const [getSystemFieldName] = useSystemFields();

  const handleChange = (objectType, name) => type => event => {
    const newState = merge({ ...state }, { [objectType]: { [name]: { [type]: event.target.checked } } });

    setNotificationsState(newState);
    return updateNotifications ? updateNotifications(state) : null;
  };

  const setNotificationChannel = newChannel => {
    const newState = merge({ ...state }, { slackNotificationChannel: newChannel });

    setNotificationsState(newState);
    return updateNotifications ? updateNotifications(newState) : null;
  };

  return (
    <NotificationsSection>
      <PageTitle>Notifications Preferences</PageTitle>
      <NotificationsTypeSection
        type=""
        showSlackColumn={showSlackColumn}
        setNotificationChannel={setNotificationChannel}
        selectedSlackChannel={state.slackNotificationChannel}
        slackChannels={slackChannels}
      >
        <NotificationItem
          name="When I'm added as Owner"
          objectType="projects"
          notificationName="owner_id"
          notificationsPreferences={state}
          onChange={handleChange('projects', 'owner_id')}
          showSlackColumn={showSlackColumn}
        />
        <NotificationItem
          name="When Health is updated"
          objectType="projects"
          notificationName="status_color"
          notificationsPreferences={state}
          onChange={handleChange('projects', 'status_color')}
          showSlackColumn={showSlackColumn}
        />
        <NotificationItem
          name={`When ${getSystemFieldName('phase')} is updated`}
          objectType="projects"
          notificationName="phase_id"
          notificationsPreferences={state}
          onChange={handleChange('projects', 'phase_id')}
          showSlackColumn={showSlackColumn}
        />
        <NotificationItem
          name={`When ${getSystemFieldName('timeframe')} is updated`}
          objectType="projects"
          notificationName="timeframe_id"
          notificationsPreferences={state}
          onChange={handleChange('projects', 'timeframe_id')}
          showSlackColumn={showSlackColumn}
        />
        <NotificationItem
          name="When mentioned"
          objectType="projects"
          notificationName="mention"
          notificationsPreferences={state}
          onChange={handleChange('projects', 'mention')}
          showSlackColumn={showSlackColumn}
        />
      </NotificationsTypeSection>
    </NotificationsSection>
  );
};

Notifications.propTypes = {
  notificationsPreferences: PropTypes.object,
  updateNotifications: PropTypes.func,
};

const NotificationsSection = styled.section`
  /* margin-top: 30px; */
`;

const HeaderRow = styled.div`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const SlackLogo = styled.img`
  &&&& {
    height: 15px;
    width: 15px;
    margin-right: 5px;
  }
`;

const MailIcon = styled(MailOutlineIcon)`
  height: 15px !important;
  width: 15px !important;
  margin-right: 5px;
`;

const NotificationsTypeSection = styled(
  ({ showSlackColumn, slackChannels, setNotificationChannel, selectedSlackChannel, children, ...otherProps }) => (
    <Table {...otherProps}>
      <TableHead>
        <TableRow>
          <TableCell>{otherProps.type}</TableCell>
          <TableCell align="center">
            <HeaderRow>
              <MailIcon height={15} width={15} />
              Email
            </HeaderRow>
          </TableCell>
          {showSlackColumn && (
            <TableCell align="center">
              <HeaderRow>
                <SlackLogo src={SlackIcon} />
                <div>Slack:&nbsp;</div>
                <SearchableListSelect
                  title="Slack Channels"
                  myFilters={slackChannels.map(c => ({ id: c.id, name: c.name, title: c.name }))}
                  value={selectedSlackChannel}
                  onChange={setNotificationChannel}
                />
              </HeaderRow>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  ),
)`
  &&&& {
    margin-top: 15px;
  }
`;

const NotificationItem = styled(props => {
  const notificationData = ((props.notificationsPreferences || {})[props.objectType] || {})[props.notificationName] || {};

  return (
    <TableRow key={props.name}>
      <NotificationName>{props.name}</NotificationName>
      <NotificationCheckbox color="primary" onChange={props.onChange('email')} checked={notificationData.email} />
      {props.showSlackColumn && (
        <NotificationCheckbox color="primary" onChange={props.onChange('slack')} checked={notificationData.slack} />
      )}
    </TableRow>
  );
})`
  &&&& {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`;

const NotificationName = styled(props => (
  <TableCell {...props}>
    <Typography>{props.children}</Typography>
  </TableCell>
))`
  &&&& {
    border: 0px;
  }
`;

const NotificationCheckbox = styled(props => (
  <TableCell align="center" {...props}>
    <Checkbox color={props.color} checked={props.checked} />
  </TableCell>
))`
  &&&& {
    width: 100px;
    border: 0px;
  }
`;

export default Notifications;
