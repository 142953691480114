import React from 'react';
import styled from 'styled-components';

import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';

import DropdownFilter from 'components/DropdownFilter';

import { PLANNED_LABEL, REPORTED_LABEL, COMPLETED_LABEL, TARGET_LABEL, ACTUAL_LABEL } from 'constants/allocation';

const DROPDOWN_MAX_WIDTH = '500px';

const getSelectedAllocationTypeLabel = (lsState = {}) => {
  const { showPlanned, showReported, showCompleted, showTarget, showActual } = lsState;
  const reportTypes = {
    showPlanned: PLANNED_LABEL,
    showReported: REPORTED_LABEL,
    showCompleted: COMPLETED_LABEL,
    showTarget: TARGET_LABEL,
    showActual: ACTUAL_LABEL,
  };

  let selectedType = '';

  const shouldShowPlannedLabel = showPlanned && !showReported && !showCompleted && !showTarget && !showActual;
  const shouldShowReportedLabel = !showPlanned && showReported && !showCompleted && !showTarget && !showActual;
  const shouldShowCompletedLabel = !showPlanned && !showReported && showCompleted && !showTarget && !showActual;
  const shouldShowTargetLabel = !showPlanned && !showReported && !showCompleted && showTarget && !showActual;
  const shouldShowActualLabel = !showPlanned && !showReported && !showCompleted && !showTarget && showActual;

  if (shouldShowPlannedLabel) {
    selectedType = reportTypes.showPlanned;
  } else if (shouldShowReportedLabel) {
    selectedType = reportTypes.showReported;
  } else if (shouldShowCompletedLabel) {
    selectedType = reportTypes.showCompleted;
  } else if (shouldShowTargetLabel) {
    selectedType = reportTypes.showTarget;
  } else if (shouldShowActualLabel) {
    selectedType = reportTypes.showActual;
  }

  return selectedType;
};

const AllocationBySelector = ({ lsState, groupByOptions, onChangeGroupBy, disabled }) => {
  const selectedType = getSelectedAllocationTypeLabel(lsState);

  return (
    <PageTitle>
      <Text variant="h2" style={{ display: 'inline-block' }}>
        {selectedType} Allocation by
      </Text>
      <InlineDropdownFilter
        name="selectField"
        options={groupByOptions}
        selectedValue={lsState.selectedGroupBy}
        onChange={onChangeGroupBy}
        style={{ margin: `0 ${spacing()}px`, minWidth: '100px', maxWidth: DROPDOWN_MAX_WIDTH }}
        hideAll
        disabled={disabled}
      />
      {lsState?.highlightOverInvestedGoals && <p>Highlighting over investment</p>}
    </PageTitle>
  );
};

export default AllocationBySelector;

const PageTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const InlineDropdownFilter = styled(DropdownFilter)`
  &&& {
    #select-selectField {
      font-size: 1.25rem;
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      color: ${({ theme }) => theme.palette.text.primary};
      padding-bottom: 0;
    }
  }
`;
