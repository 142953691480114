import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { bool, arrayOf, array, object, func, string } from 'prop-types';
import { toast } from 'react-toastify';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';

import { spacing } from 'design-system/theme';

import LightBox from 'components/LightBox';
import DataGrid from 'components/DataGrid/DataGrid';
import useCopyPasteLightbox from './useCopyPasteLightbox';

const TRANSFORM_FROM_CLIPBOARD = [{}];

const onRowSelectedDefault = () => {};

const promiseResolve = () => Promise.resolve();

const getRowId = ({ data }) => data?.id || null;

const CopyPasteLightbox = memo(
  ({
    rowsAutoheight,
    rowData,
    updateRowData,
    save,
    mandatoryField = 'title',
    isOpen,
    otherActions,
    cancel,
    isLightbox = true,
    height,
    isCopyPaste = true,
    introText,
    onRowSelected = onRowSelectedDefault,
    modalTitle,
    columnDefs = [],
  }) => {
    const [disableSaveButton, setDisableSaveButton] = useState(false);

    const mounted = useRef(false);
    const stateRowData = useRef(rowData);

    stateRowData.current = rowData;

    const {
      isGridReady,
      getGridApi,
      initAgGrid,
      methods: { processRowDataFromClipboard },
      generateGridOptions,
    } = useCopyPasteLightbox();

    const gridOptions = useMemo(() => {
      return generateGridOptions(onRowSelected, mandatoryField);
    }, [onRowSelected, mandatoryField]);

    const updateByRowIndex = (rowIndex, update) => {
      const newRowData = [...stateRowData.current];

      newRowData[rowIndex] = {
        ...stateRowData.current[rowIndex],
        ...update,
      };

      updateRowData(newRowData);
    };

    const afterProcessClipboardData = data => {
      if (!data || (!data.create && !data.update)) {
        return;
      }

      let newRowData = stateRowData.current.slice(0);

      if (data.create) {
        newRowData = newRowData.concat(data.create);
      }

      if (data.update) {
        data.update.forEach(({ rowIndex, ...row }) => {
          newRowData[rowIndex] = {
            ...newRowData[rowIndex],
            ...row,
          };
        });
      }
      updateRowData(newRowData);
    };

    const onGettingGridApis = useCallback(
      async ({ api, columnApi }) => {
        initAgGrid(api, columnApi);

        if (isEqual(TRANSFORM_FROM_CLIPBOARD, rowData)) {
          const clipboardData = await processRowDataFromClipboard(columnDefs);

          if (clipboardData) {
            updateRowData(clipboardData);
          }
        }
      },
      [rowData, updateRowData, processRowDataFromClipboard, columnDefs],
    );

    const onSave = useCallback(() => {
      setDisableSaveButton(true);

      return save(stateRowData.current.filter(row => row[mandatoryField]))
        .catch(() => {
          toast('Saving failed. Please try again.');
        })
        .finally(() => {
          setDisableSaveButton(false);
        });
    }, [save, mandatoryField]);

    const getMainContent = useCallback(() => {
      return (
        <div>
          {isCopyPaste && !introText && (
            <InfoContainer>
              You may paste up to 100 rows at a time. Here is dragonboat{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://dragonboat.zendesk.com/hc/en-us/articles/360013269194-Dragonboat-Overview-Data-Structure"
              >
                data structure
              </a>
              .
            </InfoContainer>
          )}
          {introText && <InfoContainer>{introText()}</InfoContainer>}
          <DataGrid
            height={height}
            columnDefs={columnDefs}
            rowData={stateRowData.current}
            updateById={updateByRowIndex}
            remove={promiseResolve}
            saveNew={promiseResolve}
            disableFocusOnNewRow
            onGettingGridApis={onGettingGridApis}
            isGridWithoutIds
            afterProcessClipboardData={afterProcessClipboardData}
            pasteToUnlimitedCells
            gridOptions={gridOptions}
            getRowId={getRowId}
          />
        </div>
      );
    }, [
      isCopyPaste,
      introText,
      height,
      rowData,
      columnDefs,
      updateByRowIndex,
      onGettingGridApis,
      afterProcessClipboardData,
      gridOptions,
    ]);

    useEffect(() => {
      if (!mounted.current) {
        mounted.current = true;
      } else if (rowsAutoheight && isGridReady()) {
        getGridApi().resetRowHeights();
      }
    });

    return isLightbox ? (
      <LightBox
        isOpen={isOpen}
        fullWidth
        title={modalTitle}
        maxWidth="lg"
        content={getMainContent()}
        actions={
          <>
            {otherActions}
            <Button aria-label="save" color="primary" onClick={onSave} disabled={disableSaveButton}>
              import
            </Button>
            <Button onClick={cancel}>cancel</Button>
          </>
        }
      />
    ) : (
      getMainContent()
    );
  },
);

const InfoContainer = styled(DialogContentText)`
  &&&& {
    margin: ${spacing(2)} 0 ${spacing(3)} 0;
  }
`;

DataGrid.displayName = 'DataGrid';

DataGrid.propTypes = {
  isOpen: bool,
  isLightbox: bool,
  rowData: arrayOf(object).isRequired,
  height: string,

  isCopyPaste: bool,

  save: func.isRequired,
  cancel: func.isRequired,
  updateRowData: func.isRequired,
  onRowSelected: func,
  columnDefs: array,

  mandatoryField: string,
};

export default CopyPasteLightbox;
