import React from 'react';

import ErrorMessage from 'design-system/atoms/ErrorMessage';

export default ({ createError, linkError, repoName, showProjectLightbox, hideProjectLightbox }) => {
  const error = createError || linkError;

  if (!error) {
    return <span />;
  }

  if (error === 'Milestone already exists') {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  const milestoneAlreadyLinkedMessageRegEx = /This milestone is already linked to Idea DB-(\d+)./;

  const match = error.match(milestoneAlreadyLinkedMessageRegEx);

  if (match) {
    const errorMessage = error.slice(0, error.indexOf(`DB-${match[1]}`));

    return (
      <ErrorMessage>
        {`${errorMessage} `}
        <a
          onClick={() => {
            hideProjectLightbox();

            setTimeout(() => {
              showProjectLightbox(+match[1]);
            }, 400);
          }}
        >
          DB-{match[1]}
        </a>
        .
      </ErrorMessage>
    );
  }

  return (
    <p>
      Unable to connect to <u>{repoName}</u> due to <ErrorMessage>{`"${error}"`}</ErrorMessage> error from Github. Please verify
      repo name or your permission.
    </p>
  );
};
