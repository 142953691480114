import { useCallback, useMemo } from 'react';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

/**
 * Filters a list of metadata items based on the linked roadmap and subroadmap
 *
 * @param {Array} items
 * @param {Object} options
 * @param {Number} options.roadmapId
 * @param {Number} options.subRoadmapId
 * @param {String} options.roadmapsPropName
 * @returns {Array}
 */
const filterItemsByRoadmapAndSubroadmap = (items, { roadmapId, subRoadmapId, product2Id, roadmapsPropName } = {}) => {
  return items.filter(item => {
    const roadmaps = item[roadmapsPropName];
    const roadmapsIsEmpty = !roadmaps || roadmaps.length === 0;

    if (roadmapsIsEmpty) return true;

    return roadmaps.some(entry => {
      const roadmapMatch = entry.roadmap_id === roadmapId;
      const subRoadmapMatch =
        !subRoadmapId ||
        (subRoadmapId && entry.product_1_id === subRoadmapId) ||
        (product2Id && entry.product_2_id === product2Id);

      return roadmapMatch && subRoadmapMatch;
    });
  });
};

/**
 * Gets the linked roadmaps property name for a given metadata entiry
 *
 * @param {String} roadmapsKey
 * @returns {String}
 */
const getItemRoadmapsPropertyName = roadmapsKey => `${roadmapsKey}_roadmaps`;

const useFilterMetadataBasedOnRoadmap = selectedProject => {
  const { getCurrentOrgCorpRoadmap, getDefaultRoadmapForMetadataItem } = useOrganizationsAccessControl();

  const currentOrgCorpRoadmap = useMemo(() => getCurrentOrgCorpRoadmap(), [getCurrentOrgCorpRoadmap]);

  const filterOptionsBasedOnCorpRoadmap = useCallback(
    options => {
      if (!currentOrgCorpRoadmap) {
        return options;
      }

      return options.filter(item => {
        const corpRoadmapForMetadataItem = getDefaultRoadmapForMetadataItem(item);

        return currentOrgCorpRoadmap.id === corpRoadmapForMetadataItem?.id;
      });
    },
    [currentOrgCorpRoadmap, getDefaultRoadmapForMetadataItem],
  );

  const filterOptions = useCallback(
    (options, metadataRoadmapsKey) => {
      const { roadmap_id: roadmapId, product_1_id: subRoadmapId, product_2_id: product2Id } = selectedProject || {};

      let filteredOptions = options;

      // If org has a corp roadmap only use the items that belong to this corp roadmap
      if (currentOrgCorpRoadmap) {
        filteredOptions = filterOptionsBasedOnCorpRoadmap(filteredOptions, currentOrgCorpRoadmap);
      }

      // If roadmap is selected filter items based on metadata roadmaps selection
      if (roadmapId && metadataRoadmapsKey) {
        const roadmapsPropName = getItemRoadmapsPropertyName(metadataRoadmapsKey);

        filteredOptions = filterItemsByRoadmapAndSubroadmap(filteredOptions, {
          roadmapId,
          subRoadmapId,
          product2Id,
          roadmapsPropName,
        });
      }

      return filteredOptions;
    },
    [selectedProject, currentOrgCorpRoadmap, filterOptionsBasedOnCorpRoadmap],
  );

  return {
    filterOptions,
  };
};

export default useFilterMetadataBasedOnRoadmap;
