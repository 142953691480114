import React, { memo, useRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import RootRef from '@material-ui/core/RootRef';

import Autocomplete from 'design-system/atoms/Autocomplete/index';


import cellEditorHoc from 'design-system/molecules/AgGridReact-New/helpers/cellEditorHoc';

const handleOnClickAway = (clickEvent, { stopEditing }) => {
  const clickedEventTargetIsRenderedAsPortal = clickEvent.target.closest('#react-autowhatever-1');

  if (!clickedEventTargetIsRenderedAsPortal) {
    stopEditing();
  }
};

const AgGridAutocomplete = memo(
  cellEditorHoc(
    ({
      value: originalValue = '',
      autoFocus,
      options,
      addNew,
      charPress,
      size,
      dataMapper,
      loadData,
      autocompleteComponent,
      prependProjectIcon,
      stopEditing,
      forwardedRef,
    }) => {
      const domRef = useRef(null);

      const [value, setValue] = useState(undefined);

      const AutocompleteComponent = useMemo(() => autocompleteComponent || Autocomplete, [autocompleteComponent]);

      const handleOnChange = useCallback(newValue => {
        setValue(newValue);
      });

      useImperativeHandle(forwardedRef, () => ({
        getValue() {
          return value === undefined ? originalValue : value;
        },
      }));

      useEffect(() => {
        if (value != null) {
          stopEditing();
        }
      }, [value]);

      return (
        <RootRef rootRef={domRef}>
          <AutocompleteComponent
            suggestions={options}
            value={value ?? originalValue}
            autoFocus={autoFocus}
            onValueChange={handleOnChange}
            addNew={addNew}
            charPress={charPress}
            size={size}
            dataMapper={dataMapper}
            loadData={loadData}
            prependSuggestion={prependProjectIcon}
          />
        </RootRef>
      );
    },
    handleOnClickAway,
  ),
);

AgGridAutocomplete.displayName = 'AgGridAutocomplete';

export default AgGridAutocomplete;
