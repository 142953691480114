import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const useMissionControlHome = () => {
  const { canView } = usePermissions();

  const displayOkrsWidget = canView(PERMISSION_FEATURES.missionControlOkrs);
  const displayMetricsWidget = canView(PERMISSION_FEATURES.missionControlMetrics);
  const displayRequestsWidgets = canView(PERMISSION_FEATURES.missionControlRequests);

  return {
    displayOkrsWidget,
    displayMetricsWidget,
    displayRequestsWidgets,
  };
};

export default useMissionControlHome;
