import { keys } from 'ramda';

import { getRandomColor } from 'utils';
import { CUSTOMER_CUSTOM_FIELD } from '../constants';

const isCustomerCustomField = cf => cf.association_type === CUSTOMER_CUSTOM_FIELD;

const enrichCustomFieldsWithCustomerFields = data => {
  return data.map(cf => {
    if (isCustomerCustomField(cf)) {
      const dataEnanchedWithColors = keys(cf.data_enhanced).reduce((acc, key) => {
        const entry = cf.data_enhanced[key];

        return {
          ...acc,
          [key]: {
            ...entry,
            color: entry.color || getRandomColor(),
          },
        };
      }, {});

      return {
        ...cf,
        data_enhanced: dataEnanchedWithColors,
      };
    }

    return cf;
  });
};

export { isCustomerCustomField, enrichCustomFieldsWithCustomerFields };
