import axios from 'axios';

import {
  FETCH_USERS,
  DELETE_USERS,
  DELETE_ALL_USERS,
  ADD_USER_WITHOUT_SAVE,
  REMOVE_UNSAVED_USERS,
  UPDATE_USERS_SEARCH,
  UPDATE_USERS_FILTERS,
  CREATE_USER_FULFILLED,
  UPDATE_USER_FULFILLED,
  DELETE_USER_BY_ID_FULFILLED,
  BULK_DELETE_USERS_FULFILLED,
  INVITE_USERS,
} from './types';

import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';
import fetchMetadataAction from '../utils/factory/fetchMetadataAction';

export const fetchUsers = fetchMetadataAction(
  FETCH_USERS,
  '/api/users',
  state => ({ data: state.users.rows }),
  state => state.users.lastCallsDate,
);

export const inviteUsers = data => {
  return {
    payload: axios.post('/api/users/invite', {
      data,
    }),
    type: INVITE_USERS,
  };
};

export const deleteUsers = ids => {
  return {
    payload: axios.post('/api/users/delete', ids),
    type: DELETE_USERS,
  };
};

export const deleteAllUsers = () => {
  return {
    payload: axios.post('/api/users/delete/all'),
    type: DELETE_ALL_USERS,
  };
};

export function addUserWithoutSave(user) {
  return {
    type: ADD_USER_WITHOUT_SAVE,
    user,
  };
}

export function removeUnsavedUsers() {
  return {
    type: REMOVE_UNSAVED_USERS,
  };
}

export function updateUsersSearch(payload) {
  return {
    type: UPDATE_USERS_SEARCH,
    payload,
  };
}

export function updateFilters(payload) {
  return {
    type: UPDATE_USERS_FILTERS,
    payload,
  };
}

export const gotUsersRealtimeUpdate = generateRealtimeUpdateAction(
  CREATE_USER_FULFILLED,
  DELETE_USER_BY_ID_FULFILLED,
  UPDATE_USER_FULFILLED,
  BULK_DELETE_USERS_FULFILLED,
);

export const userActions = {
  fetchUsers,
  deleteUsers,
  deleteAllUsers,
};
