import React, { useCallback, useMemo } from 'react';
import { PERMISSION_RESOURCES, SYSTEM_FIELD } from '@dragonboat/permissions';

import { PRIORITIES } from 'store/grids/constants';
import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SettingsDialogs from 'components/SettingsDialogs';
import { BaseGrid } from 'containers/Grids';
import {
  useSettingsActions,
  useSettingsGridSelection,
  useSettingsGridProps,
  useSettingsGridEventHandlers,
  useSettingsGridColumns,
} from 'containers/Grids/SettingsGrid/hooks';
import usePriorities from './hooks/usePriorities';
import usePrioritiesGridColumns from './hooks/usePrioritiesGridColumns';
import useForceVisibleColumns from 'hooks/useForceVisibleColumns';
import usePermissions from 'hooks/permissions/usePermissions';

const PRIORITY_KEY = 'priority';
const TITLE_FIELD = 'title';

const Priorities = ({
  showActionBar = true,
  updateSystemFieldsName,
  systemFields,
  hideMetadataRoadmaps,
  forceVisibleFields,
  customHeight,
  hideArchivedItems,
}) => {
  const {
    priorities,
    createPriority,
    addPriorityWithoutSave,
    removeUnsavedPriorities,
    updatePriorityById,
    deletePriority,
    mergePriorities,
    switchPrioritiesRowOrder,
    createPriorityRoadmap,
    deletePriorityRoadmap,
    bulkDeletePriorityRoadmaps,
  } = usePriorities(hideArchivedItems);

  // Specific column definition for priorities
  const prioritiesGridColumnDefs = usePrioritiesGridColumns({
    roadmapColumn: {
      createPriorityRoadmap,
      deletePriorityRoadmap,
      priorities,
      bulkDeletePriorityRoadmaps,
      hideMetadataRoadmaps,
    },
  });

  const handleRowDrag = useCallback(
    (currentNode, lastOverNode, { position }) =>
      switchPrioritiesRowOrder(currentNode.data.id, lastOverNode.data.id, null, position),
    [switchPrioritiesRowOrder],
  );

  // Settings common agGrid configuration

  const { selectionMode, selectedItems, enableSelectionMode, disableSelectionMode, updateSelectedOnAgGridInit } =
    useSettingsGridSelection(PRIORITIES, {
      actions: { remove: removeUnsavedPriorities },
    });

  const {
    itemToDelete,
    setItemToDelete,
    deleteItem,
    deleteProgress,
    mergeLightboxVisible,
    showMergeLightbox,
    hideMergeLightbox,
  } = useSettingsActions({
    settingType: PRIORITIES,
    selectedItems,
    disableSelectionMode,
    deleteById: deletePriority,
  });

  const settingsGridProps = useSettingsGridProps();

  const { canUpdate, canCreate, canView, canMerge } = usePermissions();
  const canUpdateOrCreate = canUpdate(PERMISSION_RESOURCES.priority) || canCreate(PERMISSION_RESOURCES.priority);

  const permissions = {
    allowActions: canUpdateOrCreate,
    canAdd: canCreate(PERMISSION_RESOURCES.priority),
    rename: {
      canUpdate: showActionBar && canUpdate(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.priority }),
      canView: showActionBar && canView(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.priority }),
    },
    canMerge: showActionBar && canMerge(PERMISSION_RESOURCES.priority),
    canDragRows: showActionBar && canUpdateOrCreate,

    canUpdate: useCallback(data => canUpdate(PERMISSION_RESOURCES.priority, { data }), [canUpdate]),
  };

  const settingsGridColumns = useSettingsGridColumns({
    allowActions: permissions.allowActions,
    canUpdate: permissions.canUpdate,
    canDragRows: permissions.canDragRows,
    selectionMode,
    setItemToDelete,
    items: priorities,
  });

  const settingsGridEventHandlers = useSettingsGridEventHandlers({
    settingType: PRIORITIES,
    items: priorities,
    focusField: TITLE_FIELD,
    actions: {
      update: updatePriorityById,
      save: createPriority,
      remove: removeUnsavedPriorities,
      drag: handleRowDrag,
    },
  });

  const visibleColumns = useForceVisibleColumns(prioritiesGridColumnDefs, forceVisibleFields);

  // Join settings and priorities column definitions
  const columnDefs = useMemo(
    () => [...settingsGridColumns.beforeColumnDefs, ...visibleColumns, ...settingsGridColumns.afterColumnDefs],
    [settingsGridColumns, visibleColumns],
  );

  return (
    <>
      {permissions.allowActions && (
        <SettingsActionsToolbar
          settingKey={PRIORITY_KEY}
          settingType={PRIORITIES}
          systemFields={systemFields}
          updateSystemFieldsName={updateSystemFieldsName}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          onEnableSelection={enableSelectionMode}
          onCancelSelection={disableSelectionMode}
          showMergeLightbox={showMergeLightbox}
          add={addPriorityWithoutSave}
          renamePermissions={permissions.rename}
          canMerge={permissions.canMerge}
          hideAddButton={!permissions.canAdd}
        />
      )}
      <BaseGrid
        onGridReady={updateSelectedOnAgGridInit}
        rowData={priorities}
        columnDefs={columnDefs}
        defaultColDef={settingsGridColumns.defaultColDef}
        {...settingsGridProps}
        {...settingsGridEventHandlers}
        height={customHeight ?? settingsGridProps.height}
      />
      <SettingsDialogs
        settingKey={PRIORITY_KEY}
        settingType={PRIORITIES}
        deleteProgress={deleteProgress}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        deleteItem={deleteItem}
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
        mergeLightboxVisible={mergeLightboxVisible}
        hideMergeLightbox={hideMergeLightbox}
        merge={mergePriorities}
      />
    </>
  );
};

export default Priorities;
