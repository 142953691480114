import React from 'react';
import styled from 'styled-components';
import List from '@material-ui/core/List';

export default (props) => {
  return (
    <ListContainer>
      { props.children }
    </ListContainer>
  );
};

const ListContainer = styled(List)`
  width: 100%;
`;