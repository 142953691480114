import React, { useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import { GOALS_SUMMARY_PAGE } from 'constants/filters';

import { createTheme } from 'store/themes';
import { createPhase } from 'store/phases';
import { showImportIdeasLightbox } from 'store/ideas';
import { createProject, updateProject, switchProjectRowOrder } from 'store/projects';
import { createRoadmap } from 'store/roadmaps';
import { createTag } from 'store/tags';
import { createCategory } from 'store/categories';
import { createObjective, OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2 } from 'store/objectives';
import { createTimeframe } from 'store/timeframes';
import { openObjectiveDrawer } from 'store/objectives/actions';
import { createCustomer } from 'store/customers';
import { getCurrentUser } from 'store/login/selectors';
import { selectOrganization } from 'store/organization/selectors';

import Summary from 'routes/Dashboard/Summary/Summary';
import useMetadata from 'routes/Dashboard/Summary/hooks/useMetadata';
import useSystemFields from 'hooks/useSystemFields';
import useKeyResults2Groups from 'hooks/useKeyResults2Groups';
import { getGroupOptionsSelector } from 'store/objectives/groupSelectors';
import { getGroupOptions, getGroupOptionByKey } from 'store/objectives/helpers/groupOptions';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const withHooksHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [getSystemFieldName] = useSystemFields();

    const currentUser = useSelector(getCurrentUser);
    const organization = useSelector(selectOrganization);
    const metadata = useMetadata(organization, []);

    const { canView } = usePermissions();

    const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

    const hasKeyResults2 = organization.has_key_results_2;

    const activeKeyResultAlias = useMemo(
      () => (hasKeyResults2 ? getSystemFieldName('keyResult2', true) : getSystemFieldName('keyResult1', true)),
      [getSystemFieldName, hasKeyResults2],
    );

    const emptyTableMessage = useMemo(
      () => `0 ${activeKeyResultAlias} matching the search criteria. Please update filter or display option.`,
      [activeKeyResultAlias],
    );

    const handleOpenProjectLightbox = useCallback(
      id => {
        const type = hasKeyResults2 ? OBJECT_KEY_RESULT_2 : OBJECT_KEY_RESULT;

        return dispatch(openObjectiveDrawer(id, type));
      },
      [dispatch, hasKeyResults2],
    );

    return (
      <Component
        {...props}
        isLoading={false}
        projects={[]}
        normalizedProjects={{}}
        metadata={metadata}
        isTrial={false}
        currentUser={currentUser}
        hasEditPermissions={false}
        pageFilters={{}}
        isWorkingWithRoadmapVersion={false}
        showControlsForUser={shouldDisplayControlsBar}
        page={GOALS_SUMMARY_PAGE}
        useGroupsHook={useKeyResults2Groups}
        getGroupOptionsSelector={getGroupOptionsSelector}
        getGroupOptions={getGroupOptions}
        getGroupOptionByKey={getGroupOptionByKey}
        emptyTableMessage={emptyTableMessage}
        openProjectLightbox={handleOpenProjectLightbox}
        isGoalMode
      />
    );
  };
};

export default compose(
  connect(() => ({}), {
    createProject,
    updateProject,
    switchProjectRowOrder,
    createObjective,
    createTimeframe,
    createRoadmap,
    createTheme,
    createPhase,
    createCategory,
    createTag,
    createCustomer,
    showImportIdeasLightbox,
  }),
  withHooksHOC,
)(Summary);
