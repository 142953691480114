import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { not } from 'ramda';
import moment from 'moment-timezone';

import useProjectFieldsConfiguration from 'hooks/configuration/useProjectFieldsConfiguration';
import { getOrgHasProjectInheritFromParent } from 'store/organization';
import { PROJECT_FIELD_TO_LAYOUT_FIELD_NAME_MAPPER, LAYOUT_FIELD_NAME_TO_PROJECT_FIELD_MAPPER } from 'constants/projectLightbox';
import { removeHtmlTags } from 'utils';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

const PROJECT_FIELDS_TO_IGNORE_ON_VALIDATION = ['parent'];

const getLayoutFieldNameByProjectField = projectField => PROJECT_FIELD_TO_LAYOUT_FIELD_NAME_MAPPER[projectField] || projectField;
const getProjectFieldByLayoutFieldName = fieldName => LAYOUT_FIELD_NAME_TO_PROJECT_FIELD_MAPPER[fieldName] || fieldName;

const isValid = value => Boolean(value);
const basicValidationField = (name, value) => {
  if (Array.isArray(value)) {
    return value.filter(v => isValid(v)).length > 0;
  }

  return isValid(value);
};
const validateHtmlField = (name, value) => value && Boolean(removeHtmlTags(value));

const validateTimelineField = (name, value) => {
  if (value instanceof moment) {
    return true;
  }
  if (value && typeof value === 'object') {
    return value?.estimated_start_date || value?.deadline;
  }

  return basicValidationField(name, value);
};

const validationByLayoutField = {
  links: validateHtmlField,
  details: validateHtmlField,
  statusSummary: validateHtmlField,
  timeline: validateTimelineField,
};

const validateLayoutField = (name, value) => {
  const validationFn = validationByLayoutField[name] || basicValidationField;
  const valid = validationFn(name, value);

  return valid;
};

const getProjectInvalidRequiredFields = (selectedProject, requiredFields) => {
  return requiredFields.filter(fieldName => {
    const projectField = getProjectFieldByLayoutFieldName(fieldName);
    const value = selectedProject && selectedProject[projectField];

    const valid = validateLayoutField(fieldName, value);

    return not(valid);
  });
};

/**
 * @function useLightboxRequiredFields
 *
 * Hook that contains all logic for required fields on project lightbox
 *
 * @param  {Object}   selectedProject
 * @param  {String}   layer
 * @param  {Function} saveProject
 * @return {Object}
 */
const useLightboxRequiredFields = (selectedProject, layer, saveProject) => {
  const [isRequiredFieldsLightboxVisible, setIsRequiredFieldsLightboxVisible] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);

  const { canUpdate, canCreate } = usePermissions();

  const isNew = !selectedProject?.id;
  const isCreating = isNew;

  const userCanEditProjectFields = isCreating
    ? canCreate(PERMISSION_RESOURCES.project)
    : canUpdate(PERMISSION_RESOURCES.project, { project: selectedProject });

  const { projectsFieldsRequired } = useProjectFieldsConfiguration(layer);
  const inheritProjectFieldsEnabled = useSelector(getOrgHasProjectInheritFromParent);
  const isProjectLightboxOpen = useSelector(state => state.projectLightbox.lightboxOpen);

  const projectsFieldsRequiredNames = useMemo(
    () =>
      Object.keys(projectsFieldsRequired).reduce((acc, key) => {
        return [...acc, ...(projectsFieldsRequired[key].required ? [key] : [])];
      }, []),
    [projectsFieldsRequired],
  );
  const invalidRequiredFields = useMemo(
    () => getProjectInvalidRequiredFields(selectedProject, projectsFieldsRequiredNames),
    [selectedProject, projectsFieldsRequiredNames],
  );

  // Reset Internal State on close / open the project lightbox
  useEffect(() => {
    setFormIsDirty(false);
    setIsRequiredFieldsLightboxVisible(false);
  }, [isProjectLightboxOpen]);

  const isFieldRequired = name => {
    // for read only users does not show any required field info
    if (!userCanEditProjectFields) {
      return false;
    }

    return projectsFieldsRequiredNames.includes(name);
  };

  const isFieldValid = (name, value) => {
    const valid = not(invalidRequiredFields.includes(name));
    const ingoreValidation = !userCanEditProjectFields || not(formIsDirty);

    if (ingoreValidation) {
      return true;
    }

    return valid;
  };

  const openRequiredFieldsLightbox = () => setIsRequiredFieldsLightboxVisible(true);
  const closeRequiredFieldsLightbox = () => setIsRequiredFieldsLightboxVisible(false);

  const onSaveWithValidation = (...args) => {
    const [, shouldClose = true, newData] = args;

    if (not(inheritProjectFieldsEnabled)) {
      return saveProject(...args);
    }

    const dataToSave = newData || {};
    const fieldsValidated = [];
    const invalidFields = [];

    Object.keys(dataToSave).forEach(projectField => {
      const value = dataToSave[projectField];
      const fieldName = getLayoutFieldNameByProjectField(projectField);
      const required = isFieldRequired(fieldName);

      /*
       * we have duplicated fields names on field layout and project fields
       * should ignore the duplicated fields
       *
       * For example on parent the payload containes the parent_id and parent
       *  - parent_id is the project that should get the value
       *  - parent is the object that comes from form and will have conflit with
       *    field layout on field validation
       */
      if (PROJECT_FIELDS_TO_IGNORE_ON_VALIDATION.includes(projectField)) {
        return;
      }

      if (!required) {
        return;
      }

      const valid = validateLayoutField(fieldName, value);

      if (valid) {
        fieldsValidated.push(fieldName);

        return;
      }

      invalidFields.push(fieldName);
    });

    const invalidRequiredFieldsWithoutValidatedOnCurrentValidation = invalidRequiredFields.filter(
      f => !fieldsValidated.includes(f),
    );
    const hasInvalidFieldsOnCurrentPayload = invalidFields.length > 0;
    const hasInvalidRequiredFieldsOnForm = invalidRequiredFieldsWithoutValidatedOnCurrentValidation.length > 0;
    const hasRequiredFields = projectsFieldsRequiredNames.length > 0;

    const currentValidationIsDirty = hasInvalidRequiredFieldsOnForm || hasInvalidFieldsOnCurrentPayload;

    /*
     * On create should not require the form dirty to show the required
     * fields warning
     *
     * On update only show warning message if form is already dirty, will
     * avoid show warning every time user open the lightbox
     */
    const isCurrentFormInDirtyState = isCreating ? currentValidationIsDirty : formIsDirty && currentValidationIsDirty;

    const shouldTriggerRequiredFieldsWarning = hasRequiredFields && shouldClose && isCurrentFormInDirtyState;

    // update form dirty status
    setFormIsDirty(currentValidationIsDirty);

    if (shouldTriggerRequiredFieldsWarning) {
      return openRequiredFieldsLightbox();
    }

    return saveProject(...args);
  };

  const fieldValidation = inheritProjectFieldsEnabled
    ? {
        isFieldValid,
        isFieldRequired,
      }
    : {};

  return {
    fieldValidation,
    isRequiredFieldsLightboxVisible,
    formIsDirty,

    onSaveWithValidation,
    closeRequiredFieldsLightbox,
  };
};

export default useLightboxRequiredFields;
