import { createSelector } from 'reselect';
import { defaultTo } from 'ramda';

import { getData, isError, isLoading } from 'utils/store/thunk';

import { SEARCH_REQUESTS_BY_TEXT } from './types';

const defaultAsEmptyArray = defaultTo([]);

// Slice state
export const getState = state => state.requestsGlobalSearch;

// Operations
export const selectOperationsState = state => getState(state).operations || {};

export const selectSearchedRequests = createSelector(selectOperationsState, state =>
  defaultAsEmptyArray(getData(state, SEARCH_REQUESTS_BY_TEXT)?.data?.data),
);

export const selectSearchedRequestsIsLoading = createSelector(selectOperationsState, state =>
  isLoading(state, SEARCH_REQUESTS_BY_TEXT),
);

export const selectSearchedRequestsIsError = createSelector(selectOperationsState, state =>
  isError(state, SEARCH_REQUESTS_BY_TEXT),
);
