import getValidFormula from 'store/customFields/helpers/getValidFormula';

import { parseEntityWithSanitizedKeys } from 'utils/customFields';

const getEnrichedWithCustomFields = (customerRequest, customFields) => {
  const customFieldKeys = customerRequest.custom_fields ? Object.keys(customerRequest.custom_fields) : [];

  if (customFieldKeys.length) {
    // todo: duplicated code - move to a util/helper
    Object.keys(customerRequest.custom_fields)
      .filter(crcf => customFields.find(cf => cf.key === crcf)?.field_type !== 'Formula')
      .forEach(key => {
        customerRequest[key] = customerRequest.custom_fields[key];
      });

    // eslint-disable-next-line no-unused-vars
    const entityWithSanitizedKeys = parseEntityWithSanitizedKeys(customerRequest, customFields);

    customFields
      .filter(cf => cf.field_type === 'Formula')
      .forEach(cf => {
        try {
          customerRequest[cf.key] = +parseFloat(
            // eslint-disable-next-line no-eval
            eval(getValidFormula(cf.description, 'entityWithSanitizedKeys', customFields, 'customerRequests')),
          ).toFixed(2);
          if ([Infinity, NaN].includes(customerRequest[cf.key])) customerRequest[cf.key] = '';
        } catch (err) {
          customerRequest[cf.key] = '';
        }
      });
  }

  return customerRequest;
};

export default getEnrichedWithCustomFields;
