import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { returnsTrueIfKeyIsNotEscOrTab } from 'design-system/molecules/AgGridReact-New/helpers';
import { getMetadataTreeEditorColumnDef } from 'design-system/molecules/AgGridReact-New/columns';

import { makeGetItemsForKeyResultsMetadataTreeDropdownCellEditor, makeGetMetadataChipCellRendererParams } from '../helpers';
import { ChipCellRenderer, EmptyValueIfNotProjectRenderer } from '../renderers';

const getKeyResultColumnDef = ({
  field,
  headerName,
  selectedLevel,

  getFilteredObjectives,
  getKeyResults,

  update,

  ...otherProps
}) => {
  const metadataTreeEditorColumnDef = getMetadataTreeEditorColumnDef({
    shouldAcceptSelection: selectedOption => !selectedOption || selectedOption.data?.level === selectedLevel,
    getItems: params =>
      makeGetItemsForKeyResultsMetadataTreeDropdownCellEditor(getFilteredObjectives(params), selectedLevel)(params),
    handleUpdate: (dataId, selectedOption) => {
      const updatedData = {
        [field]: selectedOption?.title ?? null,
      };

      return update(dataId, updatedData);
    },
  });

  return {
    field,
    headerName,
    cellRenderer: EmptyValueIfNotProjectRenderer(ChipCellRenderer),
    cellRendererParams: params => makeGetMetadataChipCellRendererParams(getKeyResults(params))(params),
    ...metadataTreeEditorColumnDef,
    comparator: stringComparator,
    suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
    width: 120,
    ...otherProps,
  };
};

export default getKeyResultColumnDef;
