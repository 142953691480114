import axios from 'axios';
import { createThunk } from 'utils/store/thunk';
import throwRequestError from 'store/utils/throwRequestError';

import {
  ARCHIVE_ANNOUNCEMENT,
  CREATE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  FETCH_ANNOUNCEMENTS,
  FETCH_UNREAD_ANNOUNCEMENTS,
  UPDATE_ANNOUNCEMENT,
} from './types';
import { getAnnouncements } from './selectors';
import upsertListItem from 'store/utils/upsertListItem';

const DEFAULT_ANNOUNCEMENT_LIMIT = 5;

const fetchAnnouncements = () =>
  createThunk(
    FETCH_ANNOUNCEMENTS,
    axios.get('/api/announcements', { params: { limit: DEFAULT_ANNOUNCEMENT_LIMIT } }).catch(throwRequestError),
  );

const fetchUnreadAnnouncements = () =>
  createThunk(FETCH_UNREAD_ANNOUNCEMENTS, axios.get('/api/announcements/unread').catch(throwRequestError));

const createAnnouncement = ({ message, channel, integrationType }) => {
  return (dispatch, getState) => {
    return dispatch(
      createThunk(
        CREATE_ANNOUNCEMENT,
        axios
          .post('/api/announcements', { message, channel, integrationType })
          .then(({ data }) => {
            dispatch(fetchAnnouncements());
            return data;
          })
          .catch(throwRequestError),
      ),
    );
  };
};

const updateAnnouncement = (id, { message, channel, integrationType }) => {
  return (dispatch, getState) => {
    const state = getState();
    const allAnnouncements = getAnnouncements(state);

    return dispatch(
      createThunk(
        UPDATE_ANNOUNCEMENT,
        axios
          .put(`/api/announcements/${id}`, { message, channel, integrationType })
          .then(({ data }) => {
            const updated = upsertListItem(data, allAnnouncements);

            return updated;
          })
          .catch(throwRequestError),
      ),
    );
  };
};

const deleteAnnouncement = id => {
  return (dispatch, getState) => {
    const state = getState();
    const allAnnouncements = getAnnouncements(state);

    return dispatch(
      createThunk(
        DELETE_ANNOUNCEMENT,
        axios
          .delete(`/api/announcements/${id}`)
          .then(() => {
            const updated = allAnnouncements.filter(announcement => announcement.id !== id);

            return updated;
          })
          .catch(throwRequestError),
      ),
    );
  };
};

const archiveAnnouncement = id =>
  createThunk(ARCHIVE_ANNOUNCEMENT, axios.post(`/api/announcements/read/${id}`).catch(throwRequestError));

export {
  archiveAnnouncement,
  createAnnouncement,
  deleteAnnouncement,
  fetchAnnouncements,
  fetchUnreadAnnouncements,
  updateAnnouncement,
};
