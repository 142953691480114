import styled, { css } from 'styled-components';

import theme, { spacing } from 'design-system/theme';
import Text from 'design-system/atoms/Text';

const Wrapper = styled.div`
  ${({ theme }) => `border-right: 1px solid ${theme.palette.border.primary};`}
  box-sizing: border-box;

  width: 250px;
  min-height: 100%;
  overflow-y: auto;
`;

const ClusterItem = styled.div`
  cursor: pointer;

  // ${({ theme }) => `border-bottom: 1px solid ${theme.palette.border.primary};`}

  min-height: 40px;
  display: flex;
  align-items: center;

  padding: 0 ${spacing()}px;

  display: flex;
  gap: ${spacing(0.5)}px;

  ${({ color }) => color && `border-left: 2px solid ${color};`}

  p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const linkStyles = css`
  cursor: pointer;
  text-decoration: none;
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;

  &:visited {
    color: initial;
    text-decoration: none;
  }

  &:hover {
    color: ${theme.palette.text.actionLabel.active};
    text-decoration: none;
  }
`;

const ItemTitle = styled(Text)`
  margin: ${spacing()}px 0;
  margin-left: ${spacing(1.5)}px;
  color: ${({ theme }) => theme.palette.text.actionLabel.primary};
  ${linkStyles};
`;

const Counter = styled.div`
  p {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    color: ${({ theme }) => theme.palette.text.actionLabel.primary};
  }
`;

export { Wrapper, ClusterItem, Counter, ItemTitle };
