import { either, isEmpty, isNil, not, pipe } from 'ramda';

const isNilOrEmpty = either(isNil, isEmpty);
const isNotNilOrEmpty = pipe(isNilOrEmpty, not);

/**
 * @function isEstimateValid
 *
 * Checks if a given estimate is valid or not.
 * Required fields:
 *    - skill
 *    - team
 *    - duration
 *
 * @param {Object} estimate
 * @returns {Boolean}
 */
export const isEstimateValid = estimate => {
  if (isNil(estimate)) {
    return false;
  }

  return (
    isNotNilOrEmpty(estimate.skill) &&
    isNotNilOrEmpty(estimate.team) &&
    isNotNilOrEmpty(estimate.duration) &&
    isNotNilOrEmpty(estimate.numStaff)
  );
};
