import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  addOrgIntegration as addOrgIntegrationAction,
  updateOrgIntegration as updateOrgIntegrationAction,
  removeOrgIntegration as removeOrgIntegrationAction,
  restoreOrgIntegrationData as restoreOrgIntegrationDataAction,
  getOrgIntegrationFieldMapping as getOrgIntegrationFieldMappingAction,
} from '../store/actions';
import {
  getAvailableIntegrations,
  getOrganizationIntegrations,
  getFieldMappingForOrgIntegration,
  getDragonboatFieldsForOrgIntegration,
} from '../store/selectors';
import { fetchIntegrationDragonboatFields } from '../store/thunks';

const useOrgIntegrations = integrationType => {
  const dispatch = useDispatch();

  const allOrgIntegrations = useSelector(getOrganizationIntegrations);
  const oneStepAvailableIntegrations = useSelector(getAvailableIntegrations);

  const orgIntegration = useMemo(() => {
    return allOrgIntegrations.find(orgIntegration => orgIntegration.integrationType === integrationType);
  }, [allOrgIntegrations]);

  const fieldMapping = useSelector(state => getFieldMappingForOrgIntegration(state, orgIntegration?.id));
  const dragonboatFields = useSelector(state => getDragonboatFieldsForOrgIntegration(state, orgIntegration?.id));

  const addOrgIntegration = useCallback(data => dispatch(addOrgIntegrationAction(integrationType, data)), [integrationType]);

  const updateOrgIntegration = useCallback(
    (orgIntegrationId, data) => dispatch(updateOrgIntegrationAction(orgIntegrationId, data)),
    [],
  );

  const removeOrgIntegration = useCallback(
    orgIntegrationId => dispatch(removeOrgIntegrationAction(integrationType, orgIntegrationId)),
    [integrationType],
  );

  const restoreOrgIntegrationData = useCallback(
    (orgIntegrationId, showSuccessToast) =>
      dispatch(restoreOrgIntegrationDataAction(integrationType, orgIntegrationId, showSuccessToast)),
    [integrationType],
  );

  useEffect(() => {
    if (orgIntegration) {
      dispatch(getOrgIntegrationFieldMappingAction(integrationType, orgIntegration.id));
      dispatch(fetchIntegrationDragonboatFields(integrationType, orgIntegration.id));
    }
  }, [orgIntegration]);

  return {
    orgIntegration,
    addOrgIntegration,
    updateOrgIntegration,
    removeOrgIntegration,
    restoreOrgIntegrationData,
    oneStepAvailableIntegrations,
    fieldMapping,
    dragonboatFields,
  };
};

export default useOrgIntegrations;
