import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import DrawerView from 'design-system/templates/DrawerView/index';
import Loading from 'design-system/atoms/Loading/Loading';
import BaseLayout from 'design-system/organisms/BaseLayout/index';

import SearchBar from 'components/SearchBar';
import CategoriesList from './components/CategoriesList';
import TemplateCard from './components/TemplateCard';

const TemplatesPageContent = ({ templates, searchQuery, handleSearch, handleSelectTemplate, isLoading }) => (
  <BaseLayout>
    <TopBar>
      <SearchBar onClick={e => e.stopPropagation()} searchString={searchQuery} updateSearchString={handleSearch} />
    </TopBar>

    {isLoading ? (
      <StyledLoading />
    ) : (
      <TemplatesContainer>
        {templates.map(template => (
          <TemplateCard key={template.id} template={template} handleSelectTemplate={handleSelectTemplate} />
        ))}
      </TemplatesContainer>
    )}
  </BaseLayout>
);

export default ({ categories, ...props }) => {
  return isEmpty(categories) ? (
    <TemplatesPageContent {...props} />
  ) : (
    <DrawerView isDrawerOpen drawerPosition="left" drawerWidth="230px" drawerComponent={CategoriesList}>
      <TemplatesPageContent {...props} />
    </DrawerView>
  );
};

const TopBar = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.unit * 3}px;
  margin-top: ${({ theme }) => theme.spacing.unit * 2}px;
`;

const TemplatesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.unit * 4}px;
  margin-top: ${({ theme }) => theme.spacing.unit * 2}px;
`;

const StyledLoading = styled(Loading)`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.unit * 20}px;
`;
