import { useState } from 'react';

import useCollaborationIntegrationChannels from 'hooks/integrations/useCollaborationIntegrationChannels';
import { RECURRING_OPTIONS, SEND_ONCE } from 'features/EstimateByPoints/constants';

/**
 * A custom React hook that provides functionality to share a view on a collaboration integration.
 *
 * @function useSendToIntegrationChannel
 * @param {string} integrationType - Integration type to share view with
 *
 * @returns {object} - An object containing the following methods:
 *   - selectedChannel: The currently selected channel to share the view on
 *   - channels: An array of channels available for the integration type
 *   - hasIntegrationsOfType: A boolean indicating whether the org has integrations of the specified type
 *   - setSelectedChannel: A function to set the selected channel to share the view on
 *   - shareViewOnIntegration: A function to dispatch the shareViewOnIntegration action
 */
const useSendToIntegrationChannel = integrationType => {
  const COMMENT_LS_STATE = `${integrationType}_comment_channel`;
  const [lsChannel, setLsChannel] = useState(localStorage.getItem(COMMENT_LS_STATE));
  const [periodicity, setPeriodicity] = useState(RECURRING_OPTIONS[SEND_ONCE]);
  const channels = useCollaborationIntegrationChannels(integrationType);

  const selectedChannel = lsChannel && channels.some(c => c.id === lsChannel) ? lsChannel : null;

  /**
   * Sets the selected channel to share the view on.
   *
   * @function setSelectedChannel
   * @param {string} channel - The channel to set as selected
   */
  const setSelectedChannel = channel => {
    setLsChannel(channel);
    localStorage.setItem(COMMENT_LS_STATE, channel);
  };

  const setSelectedPeriodicity = periodicity => {
    setPeriodicity(periodicity ? RECURRING_OPTIONS[periodicity] : null);
  };

  return {
    selectedChannel,
    channels,
    setSelectedChannel,
    periodicity,
    setSelectedPeriodicity,
  };
};

export default useSendToIntegrationChannel;
