import React from 'react';

import { PAGE_HEADER_HEIGHT } from 'constants/common';
import useCanvasDataSync from 'features/Canvas/hooks/useCanvasDataSync';

const PAGE_TOOLBAR_HEIGHT = 62;

const Canvas = ({
  id,
  title,
  innerRef,
  toolbarHeight = PAGE_TOOLBAR_HEIGHT,
  openCreateDrawer,
  dataForCanvas,
  canvasLayoutConfig,
  canvasNodesLayout,
  canvasEdgesLayout,
  userActionsMeta,
  nodeTypeLabels,
  onNodesLayoutUpdate,
  onEdgesLayoutUpdate,
  fixedNodesToDisplay,
}) => {
  useCanvasDataSync({
    innerRef,
    openCreateDrawer,
    dataForCanvas,
    canvasLayoutConfig,
    canvasNodesLayout,
    canvasEdgesLayout,
    userActionsMeta,
    nodeTypeLabels,
    onNodesLayoutUpdate,
    onEdgesLayoutUpdate,
    fixedNodesToDisplay,
  });

  return (
    <iframe
      id={id}
      ref={innerRef}
      title={title}
      style={{ width: '100%', height: `calc(100vh - ${PAGE_HEADER_HEIGHT}px - ${toolbarHeight}px)` }}
      src="/render-whiteboard"
    />
  );
};

export default Canvas;
