import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { isURL } from 'utils';

export default class ConfigStepOne extends Component {
  render() {
    const { baseUrl, baseUrlChange, onNextClick, formData, changeForm, goBack } = this.props;

    return (
      <StepOneContent>
        <Description>
          * You need <em>Jira Admin permission</em> to connect dragonboat with Jira.
        </Description>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              label="Enter your Jira URL"
              inputProps={{
                name: 'base_url',
                onClick: e => e.target.select(),
              }}
              onChange={baseUrlChange}
              value={baseUrl}
              style={{ width: '100%' }}
              required
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Examples>Examples - https://company.atlassian.net or https://jira.company.com</Examples>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              disabled
              label="Email"
              inputProps={{
                name: 'email',
                onClick: e => e.target.select(),
              }}
              onChange={changeForm('email')}
              value={formData.email}
              style={{ width: '100%', marginTop: '5px' }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              label="API Token"
              inputProps={{
                name: 'api_token',
                onClick: e => e.target.select(),
              }}
              onChange={changeForm('api_token')}
              value={formData.token}
              style={{ width: '100%' }}
              required
            />
          </Grid>
        </Grid>
        <ConnectButton
          id="next-button"
          color="primary"
          disabled={!isURL(baseUrl) || !formData.email || !formData.api_token}
          onClick={onNextClick}
        >
          Next
        </ConnectButton>
        <BackButton id="back-button" color="primary" onClick={goBack}>
          Back
        </BackButton>
      </StepOneContent>
    );
  }
}

const StepOneContent = styled.div`
  padding-bottom: 80px;
  padding-top: 40px;
`;

const Description = styled.p`
  margin-bottom: 16px;
`;

const Examples = styled.div`
  font-size: 11px;
  margin-top: 8px;
  color: #b3b3b3;
`;

const ConnectButton = styled(Button)`
  &&&& {
    margin-top: 38px;
    float: right;
  }
`;

const BackButton = styled(Button)`
  &&&& {
    margin-top: 38px;
    float: left;
  }
`;
