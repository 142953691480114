import { KEY_SEPARATOR } from './constants';

const generateSingleGroupRowKey = (internalOrders, lane, rowIndex, groupbarOffset, sidebarOffset) => {
  const globalRowIndex = internalOrders.findIndex(order => order === `${lane.id}${KEY_SEPARATOR}${rowIndex}`);
  const projectsLength = lane.rows.reduce((acc, row) => acc + row.length, 0);

  return [lane.key, (internalOrders || []).length, globalRowIndex, projectsLength, groupbarOffset, sidebarOffset].join('-');
};

const generateMultiGroupRowKey = (internalOrders, lane, group, rowIndex, groupbarOffset, sidebarOffset) => {
  const globalRowIndex = internalOrders.findIndex(
    order => order === `${lane.id}${KEY_SEPARATOR}${group.id}${KEY_SEPARATOR}${rowIndex}`,
  );
  const projectsLength = group.rows.reduce((acc, row) => acc + row.length, 0);

  return [
    lane.key,
    (internalOrders || []).length,
    (group.rows || []).length,
    globalRowIndex,
    projectsLength,
    groupbarOffset,
    sidebarOffset,
  ].join('-');
};

export { generateSingleGroupRowKey, generateMultiGroupRowKey };
