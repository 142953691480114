import isNumeric from 'utils/isNumeric';
import { ENTER_KEY_CODE, UP_ARROW_KEY_CODE, DOWN_ARROW_KEY_CODE, RIGHT_ARROW_KEY_CODE, LEFT_ARROW_KEY_CODE } from './consts';

const itemSelector = '[data-item="true"]';
const parentItemSelector = '[data-parent-item="true"]';

export const eventKeyIsEnter = e => {
  const key = e?.keyCode || e?.charCode;

  return key === ENTER_KEY_CODE;
};

export const eventKeyIsUpArrow = e => {
  const key = e?.keyCode || e?.charCode;

  return key === UP_ARROW_KEY_CODE;
};

export const eventKeyIsDownArrow = e => {
  const key = e?.keyCode || e?.charCode;

  return key === DOWN_ARROW_KEY_CODE;
};

export const eventKeyIsRightArrow = e => {
  const key = e?.keyCode || e?.charCode;

  return key === RIGHT_ARROW_KEY_CODE;
};

export const eventKeyIsLeftArrow = e => {
  const key = e?.keyCode || e?.charCode;

  return key === LEFT_ARROW_KEY_CODE;
};

export const parseItemIdAndPath = idString => {
  const ids = idString.split('-');
  const itemId = ids?.[ids.length - 1];

  return {
    itemId: isNumeric(itemId) ? Number(itemId) : itemId === 'null' ? null : itemId,
    itemPath: ids.filter((id, index) => !(index === 0 && id === 'null')).map(id => (isNumeric(id) ? Number(id) : id)),
  };
};

export const findItem = (options, itemId) => {
  return options.reduce((found, option) => {
    if (found) return found;
    if (option.id === itemId) return option;
    if (option.children) return findItem(option.children, itemId);
    return null;
  }, null);
};

export const getNextElementByLevel = (level = 0) => {
  let selector = `e?.currentTarget?.closest('[data-parent-item="true"]')`;

  for (let i = 0; i < level; i++) {
    selector += `?.parentNode?.closest('[data-parent-item="true"]')`;
  }

  selector += '?.nextSibling';

  return selector;
};

export const getNextElementToBeFocused = (e, itemPath) => {
  const elementToBeFocused =
    e?.currentTarget?.nextSibling?.querySelector(itemSelector)?.closest(parentItemSelector) ||
    e?.currentTarget?.closest(parentItemSelector)?.nextSibling ||
    e?.currentTarget?.closest(parentItemSelector)?.parentNode?.closest(parentItemSelector)?.nextSibling ||
    // eslint-disable-next-line
    eval(getNextElementByLevel(itemPath.length - 1));

  return elementToBeFocused;
};

export const getPreviousElementToBeFocused = e => {
  const previousElements = e?.currentTarget?.closest(parentItemSelector)?.previousSibling?.querySelectorAll(parentItemSelector);

  const elementToBeFocused =
    previousElements?.[previousElements?.length - 1] ||
    e.currentTarget?.closest(parentItemSelector)?.previousSibling?.closest(parentItemSelector) ||
    e.currentTarget?.closest(parentItemSelector)?.parentNode?.closest(parentItemSelector);

  return elementToBeFocused;
};
