export default [
  '#f4ceff',
  '#eaeaea',
  '#f8e0df',
  '#dbeffe',
  '#fae3fa',
  '#b9d4f0',
  '#fbf2f1',
  '#e9fae3',
  '#ffd9e2',
  '#a6f2e9',
  '#e0ecd0',
  '#fff7b9',
  '#f7d9a9',
  '#e9f098',
  '#d3fcff',
  '#f0f4d3',
];
