import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import styled from 'styled-components';

import NewDialog from 'design-system/organisms/NewDialog/index';

import { HeaderIconButton } from 'design-system/organisms/NewDialog';
import theme, { spacing } from 'design-system/theme';
import MetricValueChartComponent, { ChartWrapper } from './components/MetricValueChart/MetricValueChartComponent';
import MetricValueTable from './components/MetricValueTable';
import MetricDetails from './components/MetricDetails';
import { materialBackground } from 'design-system/themes/default';
import useProjectsListLightboxContext from 'hooks/useProjectsListLightbox';
import MetricsDialogHeader from './components/MetricsDialogHeader';
import useMetricsDialogTabs from './hooks/useMetricsDialogTabs';
import { METRIC } from 'constants/common';

import LinkedPortfolioItems from 'features/MetricsDialog/LinkedPortfolioItems';

export default React.memo(
  ({
    addMetricValue,
    addUnsavedMetricValue,
    chartData,
    dates,
    datesForChart,
    chartTimeUnit,
    deleteMetricValue,
    endDate,
    formatDate,
    handleDateChange,
    isOpen,
    isAnonymousUser,
    items,
    metric,
    onClose,
    removeMetricValue,
    startDate,
    updateMetricValue,
    useValuesAsPercentages,
    updateMetric,
    canEditMetric,
    createMetric,
    canCreateMetric,
    isCreating,
  }) => {
    const [showTable, setShowTable] = useState(true);

    const { activeTab, detailsTab, linkedItemsTab, chartTab } = useMetricsDialogTabs();

    const { openProjectsListLightbox: openProjectsListLightboxAction } = useProjectsListLightboxContext();

    const openProjectsListLightboxFromChart = projectsIds => openProjectsListLightboxAction(metric, METRIC, projectsIds);

    return (
      <StyledDialog
        id="metrics-lightbox"
        type="DRAWER"
        closeButton
        contentBackgroundColor={theme.palette.white}
        onClose={onClose}
        open={isOpen}
        maxWidth="lg"
        fullWidth
        fullHeight
        headerComponent={MetricsDialogHeader}
        headerProps={{
          metric,
          updateMetric,
          canEditMetric,
          showTable,
          isAnonymousUser,
          setShowTable,
          onClose,
          isCreating,
          createMetric,
          canCreateMetric,
        }}
      >
        <GridWrapper>
          {activeTab === detailsTab ? (
            <MetricDetails
              selectedMetric={metric}
              canEditMetric={canEditMetric}
              updateMetric={updateMetric}
              updateMetricValue={updateMetricValue}
              addMetricValue={addMetricValue}
              displayConditionalFields={!!metric?.id}
            />
          ) : null}
          {activeTab === chartTab ? (
            <Grid container>
              <Grid item xs={12} m={12}>
                <MetricValueChartComponent
                  metric={metric}
                  chartData={chartData}
                  datesForChart={datesForChart}
                  timeUnit={chartTimeUnit}
                  startDate={startDate}
                  endDate={endDate}
                  handleDateChange={handleDateChange}
                  useValuesAsPercentages={useValuesAsPercentages}
                  openLightbox={openProjectsListLightboxFromChart}
                  isAnonymousUser={isAnonymousUser}
                  displayClusters
                />
              </Grid>
              {metric?.id && showTable && (
                <Grid item xs={12} m={12}>
                  <MetricValueTable
                    addMetricValue={addMetricValue}
                    backgroundColor={materialBackground.whiteSmoke}
                    dates={dates}
                    formatDate={formatDate}
                    isOpen={isOpen}
                    userCannotEditMetric={!canEditMetric}
                    items={items}
                    metric={metric}
                    removeMetricValue={removeMetricValue}
                    updateMetricValue={updateMetricValue}
                    addUnsavedMetricValue={addUnsavedMetricValue}
                    deleteMetricValue={deleteMetricValue}
                  />
                </Grid>
              )}
            </Grid>
          ) : null}
          {activeTab === linkedItemsTab && (
            <LinkedPortfolioItems
              entity={metric}
              openProjectsListLightbox={() => openProjectsListLightboxAction(metric, METRIC)}
            />
          )}
        </GridWrapper>
      </StyledDialog>
    );
  },
);

const StyledDialog = styled(NewDialog)`
  &&&& {
    ${HeaderIconButton} {
      top: 18px;
    }

    > div[role='document'] {
      width: 100%;

      @media only screen and (min-width: 1100px) {
        width: 70%;
      }
    }
  }
`;

const GridWrapper = styled.div`
  min-height: 65vh;
  background-color: ${({ theme }) => theme.palette.white};

  ${ChartWrapper} {
    padding: ${spacing(2)}px;
    background: ${({ theme }) => theme.palette.white};
    border-radius: ${({ theme }) => theme.shape.borderRadiusRegular}px;
  }
`;
