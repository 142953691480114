import { useDispatch, useSelector } from 'react-redux';

import { getCurrentUser } from 'store/login/selectors';
import { updateSystemFieldsName } from 'store/organization';

const useRename = () => {
  const dispatch = useDispatch();

  const systemFields = useSelector(state => state.organization.organization.system_fields_name);
  const user = useSelector(getCurrentUser);

  return {
    systemFields,
    user,
    updateSystemFieldsName: fieldRename => dispatch(updateSystemFieldsName(fieldRename)),
  };
};

export default useRename;
