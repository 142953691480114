import { createCachedSelector } from 're-reselect';

import { filterActiveItens } from 'utils/index';
import sortByRank from 'utils/sortByRank';

import { BASE_ROW_HEIGHT } from '../helpers/constants';

const emptyArray = [];

export const getLifecyclesGridData = state => state?.lifecyclesGrid?.data || emptyArray;

export const selectGridRowHeight = state => state?.lifecyclesGrid?.rowHeight || BASE_ROW_HEIGHT;

export const selectHideArchived = state => state?.lifecyclesGrid?.hideArchived || false;

export const selectLifecyclesGridData = createCachedSelector(
  getLifecyclesGridData,
  selectHideArchived,
  (lifecyclesGridData, hideArchived) => {
    return hideArchived ? lifecyclesGridData.filter(filterActiveItens).sort(sortByRank) : lifecyclesGridData;
  },
)((state, hideArchived) => `lifecyclesGrid-${hideArchived}`);
