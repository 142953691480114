import { useCallback, useEffect, useMemo, useState } from 'react';
import { head, isNil } from 'ramda';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

import useIntegrations from 'hooks/useIntegrations';
import useAppNotifications from 'hooks/useAppNotifications';

const useMicrosoftTeamsIntegrationSettings = () => {
  const [selectedOrgIntegrationId, setSelectedOrgIntegrationId] = useState(null);
  const [isConfirmDialogDisplayed, setIsConfirmDialogDisplayed] = useState(false);
  const [isAddingOrgIntegration, setIsAddingOrgIntegration] = useState(false);

  const { addNotification } = useAppNotifications();

  const { hasOrgIntegrations, organizationIntegrations, removeOrgIntegration, addOrgIntegration } = useIntegrations(
    INTEGRATIONS_KEYS.microsoftTeams,
  );

  const selectedOrgIntegration = useMemo(
    () => organizationIntegrations.find(integration => integration.id === selectedOrgIntegrationId),
    [organizationIntegrations, selectedOrgIntegrationId],
  );

  const onClickConnect = useCallback(
    (e) => {
      e.preventDefault();

      if (!selectedOrgIntegrationId) {
        setIsAddingOrgIntegration(true);
      }
    },
    [selectedOrgIntegrationId],
  );

  const onClickDisconnect = useCallback(() => {
    setIsConfirmDialogDisplayed(true);
  }, []);

  const onAddOrgIntegrationHandler = useCallback(
    async ({ channelName, webhookUrl }) => {
      await addOrgIntegration({ channelName, webhookUrl });

      addNotification({ id: 'microsoft-teams-successfully-integrated', message: 'Successfully integrated' });
    },
    [addOrgIntegration],
  );

  const onRemoveOrgIntegrationHandler = useCallback(() => {
    if (selectedOrgIntegrationId) {
      removeOrgIntegration(selectedOrgIntegrationId).then(() => {
        setSelectedOrgIntegrationId(null);
      });
    }

    setIsConfirmDialogDisplayed(false);
    setIsAddingOrgIntegration(false);
  }, [selectedOrgIntegrationId, removeOrgIntegration]);

  const onCloseRemoveOrgIntegrationConfirmationHandler = useCallback(() => {
    setIsConfirmDialogDisplayed(false);
  }, []);

  useEffect(() => {
    if (hasOrgIntegrations && isNil(selectedOrgIntegrationId)) {
      setSelectedOrgIntegrationId(head(organizationIntegrations)?.id);
    }
  }, [selectedOrgIntegrationId, hasOrgIntegrations, organizationIntegrations]);

  return {
    selectedOrgIntegration,
    selectedOrgIntegrationId,
    isConfirmDialogDisplayed,
    isAddingOrgIntegration,
    onClickDisconnect,
    onClickConnect,
    onAddOrgIntegrationHandler,
    onRemoveOrgIntegrationHandler,
    onCloseRemoveOrgIntegrationConfirmationHandler,
  };
};

export default useMicrosoftTeamsIntegrationSettings;
