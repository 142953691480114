import React from 'react';
import styled from 'styled-components';
import ProjectIntegrationKey from 'containers/ProjectIntegrationKey';

const IntegrationKeyCellRenderer = params => {
  const {
    data,
    colDef: { orgIntegrations, field },
  } = params;

  if (!orgIntegrations || orgIntegrations.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <ProjectIntegrationKey project={data} orgIntegrations={orgIntegrations} field={field} />
    </Wrapper>
  );
};

export default IntegrationKeyCellRenderer;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
