import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLogin } from 'src/store/login/selectors';
import {
  signupWithGoogle,
  getOrganization as getOrganizationAction,
  requestAccess as requestAccessAction,
} from 'src/store/login';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const login = useSelector(getLogin);

    const handleGoogleLogin = googleToken => dispatch(signupWithGoogle(googleToken, props));
    const getOrganization = () => dispatch(getOrganizationAction(props));
    const requestAccess = email => dispatch(requestAccessAction(email));

    const receiveMessage = event => {
      try {
        const obj = JSON.parse(event.data);

        if (obj && obj.ssoType === 'google') handleGoogleLogin(obj.token);
      } catch (err) {
        // pass
      }
    };

    useEffect(() => {
      getOrganization();
      window.addEventListener('message', receiveMessage, false);

      return () => window.removeEventListener('message', receiveMessage);
    }, []);

    useEffect(() => {
      const errorBody = login.requestAccessError;
      const isEmailAlreadyExists = errorBody === 'Email already exists';
      const hasMessage = errorBody?.message;

      if (login.requestAccessError) {
        if (isEmailAlreadyExists) window.location = '/login';
        else if (hasMessage) {
          setError(errorBody?.message);
        } else {
          setError('Please try again later');
        }
      }
    }, [login.requestAccessError]);

    useEffect(() => {
      if (login.requestAccessSuccess && !login.requestAccessUserCreated) {
        window.location = '/signup_pending';
      } else if (login.requestAccessSuccess && login.requestAccessUserCreated) {
        window.location = '/signup_existing_account_confirmation';
      }
    }, [login.requestAccessSuccess]);

    const handleRequest = () => {
      setError('');
      requestAccess(email);
    };

    return (
      <Component
        organization={login.organization}
        error={error}
        email={email}
        handleEmailValue={setEmail}
        handleRequest={handleRequest}
      />
    );
  };
};

export default componentHOC;
