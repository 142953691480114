import React from 'react';

import useSystemFields from 'hooks/useSystemFields';

const componentHOC = Component => {
  return props => {
    const [getSystemFieldName] = useSystemFields();

    return <Component {...props} getSystemFieldName={getSystemFieldName} />;
  };
};

export default componentHOC;
