export const BET_NODE = 'bet';
export const INITIATIVE_NODE = 'initiative';
export const IDEA_NODE = 'idea';

export const OBJECTIVE_NODE = 'objective';
export const KEYRESULT1_NODE = 'keyResult1';
export const KEYRESULT2_NODE = 'keyResult2';

export const TIMEFRAME_NODE = 'timeframe';
export const METRIC_NODE = 'metric';

export const OKRS = 'okrs';
export const PROJECTS = 'projects';
export const TIMEFRAMES = 'timeframes';
export const BOARD = 'board';
export const METRICS = 'metrics';
export const OKRS_METRICS = 'okrsMetrics';
export const OKRS_PROJECTS = 'okrsProjects';
export const HIGHLIGHT_HEALTH = 'highlightHealth';
export const OWNER_AVATAR = 'ownerAvatar';
export const PROGRESS = 'progress';
export const STATUS = 'status';

export const defaultAvailableNodes = {
  [BET_NODE]: true,
  [INITIATIVE_NODE]: true,
  [IDEA_NODE]: true,
  [OBJECTIVE_NODE]: true,
  [KEYRESULT1_NODE]: true,
  [KEYRESULT2_NODE]: true,
  [TIMEFRAME_NODE]: true,
  [METRIC_NODE]: true,
};
