import { getThunkActionTypes } from 'utils/store/thunk';

const fetchAllReleaseCapacityAllocationTypes = {
  ...getThunkActionTypes('FETCH_ALL_RELEASE_CAPACITY_ALLOCATION'),
};

const upsertReleaseCapacityAllocationTypes = {
  ...getThunkActionTypes('UPSERT_RELEASE_CAPACITY_ALLOCATION'),
};

export const {
  FETCH_ALL_RELEASE_CAPACITY_ALLOCATION,
  FETCH_ALL_RELEASE_CAPACITY_ALLOCATION_FULFILLED,
  FETCH_ALL_RELEASE_CAPACITY_ALLOCATION_PENDING,
  FETCH_ALL_RELEASE_CAPACITY_ALLOCATION_REJECTED,
} = fetchAllReleaseCapacityAllocationTypes;

export const {
  UPSERT_RELEASE_CAPACITY_ALLOCATION,
  UPSERT_RELEASE_CAPACITY_ALLOCATION_FULFILLED,
  UPSERT_RELEASE_CAPACITY_ALLOCATION_PENDING,
  UPSERT_RELEASE_CAPACITY_ALLOCATION_REJECTED,
} = upsertReleaseCapacityAllocationTypes;
