import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getJiraProjects as getJiraProjectsAction } from 'store/integrations';
import { getOrgHasJiraFieldMappingByProject as getOrgHasJiraFieldMappingByProjectSelector } from 'store/organization/selectors';
import { updateJiraIntegration as updateJiraIntegrationAction } from 'store/organization';
import { IDEA_LAYER } from 'store/projects/constants';

import { TABS_OPTIONS } from '../MappingTabs';
import useLoadProjectsFieldMapping from './hooks/useLoadProjectsFieldMapping';
import useProjectsFieldMapping from './hooks/useProjectsFieldMapping';

const container = Component => {
  return props => {
    const {
      orgIntegrationId,
      integrationMapping,
      progressTracking = {},
      integrationSettings,
      userCanUpdateOrgIntegration,
    } = props;

    const dispatch = useDispatch();

    const [selectedMappingOperation, setSelectedMappingOperation] = useState(TABS_OPTIONS.FIELD_MAPPING);
    const [jiraProjects, setJiraProjects] = useState([]);
    const [selectedMappingLayer, setSelectedMappingLayer] = useState(IDEA_LAYER);

    const hasMappingByProject = useSelector(getOrgHasJiraFieldMappingByProjectSelector);

    const [mappings] = useLoadProjectsFieldMapping({ integrationMapping, progressTracking, selectedMappingLayer });
    const { isAddMappingVisible, onStartAddFieldMapping, onSaveFieldMapping, onRemoveFieldMapping, onAddFieldMapping } =
      useProjectsFieldMapping({ orgIntegrationId, integrationMapping, selectedMappingLayer });

    const availableJiraProjects = useMemo(() => {
      return jiraProjects.filter(jiraProject => !mappings.find(mapping => mapping.key === jiraProject.key));
    }, [jiraProjects, mappings]);

    const fetchJiraProjects = useCallback(async () => {
      const projects = await dispatch(getJiraProjectsAction(orgIntegrationId));

      setJiraProjects(projects?.data || []);
    }, [orgIntegrationId, dispatch]);

    const onChangeProgressTracking = useCallback(
      (mappingKey, data) => {
        const payload = {
          progress_tracking: {
            ...progressTracking,
            [mappingKey]: data,
          },
        };

        dispatch(updateJiraIntegrationAction(orgIntegrationId, payload));
      },
      [orgIntegrationId, progressTracking, dispatch],
    );

    useEffect(() => {
      // on invalid orgIntegrationId dont do anything
      if (!orgIntegrationId) {
        return;
      }

      fetchJiraProjects();
    }, []);

    return (
      <Component
        orgIntegrationId={orgIntegrationId}
        hasMappingByProject={hasMappingByProject}
        integrationSettings={integrationSettings}
        mappings={mappings}
        selectedMappingOperation={selectedMappingOperation}
        setSelectedMappingOperation={setSelectedMappingOperation}
        onChangeProgressTracking={onChangeProgressTracking}
        onSaveFieldMapping={onSaveFieldMapping}
        onRemoveFieldMapping={onRemoveFieldMapping}
        onAddNewJiraProject={onAddFieldMapping}
        availableJiraProjects={availableJiraProjects}
        isAddMappingVisible={isAddMappingVisible}
        onStartAddMappingFlow={onStartAddFieldMapping}
        selectedMappingLayer={selectedMappingLayer}
        setSelectedMappingLayer={setSelectedMappingLayer}
        userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
      />
    );
  };
};

export default container;
