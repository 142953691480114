/* eslint-disable */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function OktaIcon(props) {
  return (
    <SvgIcon style={{ width: 35, height: 35, ...props.style }} viewBox="5 0 37 30">
      <path d="M10.182 12.903c-2.566 0-4.643 2.077-4.643 4.643s2.077 4.643 4.643 4.643c2.566 0 4.643-2.077 4.643-4.643s-2.077-4.643-4.643-4.643zM10.182 19.872c-1.283 0-2.326-1.043-2.326-2.326s1.043-2.326 2.326-2.326c1.283 0 2.326 1.043 2.326 2.326s-1.043 2.326-2.326 2.326z"></path>
      <path d="M18.342 19.106c0-0.369 0.443-0.545 0.702-0.286 1.163 1.182 3.083 3.212 3.092 3.222 0.028 0.028 0.055 0.074 0.166 0.111 0.046 0.018 0.12 0.018 0.203 0.018h2.095c0.378 0 0.489-0.434 0.314-0.655l-3.471-3.554-0.185-0.185c-0.397-0.471-0.351-0.655 0.102-1.135l2.751-3.074c0.175-0.222 0.065-0.646-0.323-0.646h-1.902c-0.074 0-0.129 0-0.185 0.018-0.111 0.037-0.157 0.074-0.185 0.111-0.009 0.009-1.532 1.652-2.474 2.658-0.258 0.277-0.72 0.092-0.72-0.286v-5.271c0-0.268-0.222-0.369-0.397-0.369h-1.551c-0.268 0-0.397 0.175-0.397 0.332v11.686c0 0.268 0.222 0.342 0.406 0.342h1.551c0.24 0 0.397-0.175 0.397-0.351v-2.686z"></path>
      <path d="M31.006 21.746l-0.166-1.551c-0.018-0.212-0.222-0.36-0.434-0.323-0.12 0.018-0.24 0.028-0.36 0.028-1.237 0-2.243-0.969-2.317-2.197v-2.031c0-0.249 0.185-0.452 0.434-0.452h2.077c0.148 0 0.369-0.129 0.369-0.397v-1.468c0-0.286-0.185-0.434-0.351-0.434h-2.095c-0.24 0-0.434-0.175-0.443-0.415v-2.354c0-0.148-0.111-0.369-0.397-0.369h-1.542c-0.194 0-0.378 0.12-0.378 0.36v7.56c0.065 2.511 2.123 4.514 4.643 4.514 0.212 0 0.415-0.018 0.618-0.046 0.222-0.028 0.369-0.212 0.342-0.425z"></path>
      <path d="M42.194 19.798c-1.311 0-1.514-0.471-1.514-2.234v-4.255c0-0.148-0.111-0.397-0.406-0.397h-1.551c-0.194 0-0.406 0.157-0.406 0.397v0.194c-0.674-0.388-1.458-0.609-2.289-0.609-2.566 0-4.643 2.077-4.643 4.643s2.077 4.643 4.643 4.643c1.154 0 2.206-0.425 3.018-1.117 0.434 0.665 1.135 1.108 2.234 1.117 0.185 0 1.182 0.037 1.182-0.434v-1.652c0-0.138-0.111-0.295-0.268-0.295zM36.037 19.872c-1.283 0-2.326-1.043-2.326-2.326s1.043-2.326 2.326-2.326c1.283 0 2.326 1.043 2.326 2.326-0.009 1.283-1.052 2.326-2.326 2.326z"></path>
    </SvgIcon>
  );
}
