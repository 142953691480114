import { useMemo } from 'react';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';

import { materialColors } from 'design-system/themes/default';
import { ActionsCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import {
  getAddColumnDef,
  getOpenItemColumnDef,
  getDragColumnDef,
  defaultColumnDefCommonProps,
} from 'design-system/molecules/AgGridReact-New/columns';
import { isNewRow } from 'design-system/molecules/AgGridReact-New/helpers';

const SELECT_FIELD = 'select';
const SELECT_WIDTH = 40;

const ACTIONS_FIELD = 'actions';
const ACTIONS_WIDTH = 60;

const defaultDeleteDisabled = () => false;

const defaultDeleteVisibility = () => true;

const defaultOpenItemVisibility = () => true;

const useSettingsGridColumns = ({
  allowActions,
  selectionMode,
  canDragRows,
  canSelectRows = true,
  items = [],
  setItemToDelete,
  deleteDisabled = defaultDeleteDisabled,
  deleteVisibility = defaultDeleteVisibility,
  checkboxSelection = true,
  showDisabledCheckboxes = true,
  headerCheckboxSelection = true,
  handleAdd,
  addVisibility,
  addCellClass,
  openItemVisibility = defaultOpenItemVisibility,
  handleOpenItem,
  checkRowDrag,
}) => {
  // This fake node key is to keep backwards compatibility.
  const hasUnsavedItem = items.some(item => isNewRow({ node: { key: item?.id }, data: item }));

  const selectColumnDef = useMemo(
    () => ({
      field: SELECT_FIELD,
      headerName: '',
      hide: !selectionMode || hasUnsavedItem,
      suppressMovable: true,
      editable: false,
      suppressSorting: true,
      suppressMenu: true,
      headerCheckboxSelection,
      checkboxSelection,
      showDisabledCheckboxes,
      width: SELECT_WIDTH,
      maxWidth: SELECT_WIDTH,
    }),
    [selectionMode, checkboxSelection, showDisabledCheckboxes, headerCheckboxSelection, hasUnsavedItem],
  );

  const dragColumnDef = useMemo(
    () => getDragColumnDef({ checkRowDrag, disableDrag: hasUnsavedItem }),
    [checkRowDrag, hasUnsavedItem],
  );

  const beforeColumnDefs = useMemo(() => {
    if (allowActions) {
      return [...(canSelectRows ? [selectColumnDef] : []), ...(canDragRows ? [dragColumnDef] : [])];
    }

    return [];
  }, [allowActions, selectColumnDef, canDragRows]);

  const afterColumnDefs = useMemo(() => {
    if (allowActions) {
      return [
        {
          field: ACTIONS_FIELD,
          headerName: '',
          editable: false,
          suppressMovable: true,
          filter: false,
          resizable: false,
          cellRenderer: ActionsCellRenderer,
          headerClass: 'centered-header',
          cellRendererParams: {
            actions: [
              {
                icon: DeleteIcon,
                color: materialColors.darkerGray,
                getVisibility: deleteVisibility,
                onClick: setItemToDelete,
                disabled: deleteDisabled,
              },
            ],
          },
          width: ACTIONS_WIDTH,
          suppressMenu: true,
        },
      ];
    }

    return [];
  }, [allowActions, deleteVisibility, setItemToDelete, deleteDisabled]);

  const addColumnDef = useMemo(() => {
    return getAddColumnDef({
      getVisibility: addVisibility,
      handleClick: handleAdd,
      cellClass: addCellClass,
    });
  }, [addVisibility, handleAdd, addCellClass]);

  const openItemColumnDef = useMemo(() => {
    return getOpenItemColumnDef({
      getVisibility: openItemVisibility,
      handleClick: handleOpenItem,
    });
  }, [openItemVisibility, handleOpenItem]);

  const defaultColDef = useMemo(
    () => ({
      ...defaultColumnDefCommonProps,
      cellClass: params =>
        params.colDef.autoHeight ? 'ag-react-container--expandable' : 'ag-react-editable-container--expandable',
      sortable: true,
      resizable: true,
      filter: true,
      editable: params => !isNewRow(params),
    }),
    [],
  );

  return useMemo(
    () => ({
      beforeColumnDefs,
      afterColumnDefs,
      defaultColDef,
      selectColumnDef,
      addColumnDef,
      openItemColumnDef,
    }),
    [beforeColumnDefs, afterColumnDefs, defaultColDef, selectColumnDef, addColumnDef, openItemColumnDef],
  );
};

export default useSettingsGridColumns;
