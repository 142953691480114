import React, { useMemo, useCallback } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import styled, { css } from 'styled-components';

import { spacing } from 'design-system/theme';
import { brandColor } from 'design-system/themes/default';

import Autocomplete from '../Autocomplete';
import InlineAutoCompleteInput from '../InlineAutoCompleteInput';

const GroupByAutocomplete = ({
  className,
  suggestions,
  value,
  onChange,
  label = 'Group by',
  inputStyle = {},
  inputContainerStyle = {},
  iconStyle = {},
  ...autocompleteProps
}) => {
  const mappedSuggestions = useMemo(() => suggestions?.map(suggestion => suggestion.title), [suggestions]);

  // Fallback mechanism to deal with views that load default values to this component with no title attribute
  if (value && value.key && !value.title) {
    value = suggestions.find(suggestion => suggestion.key === value.key);
  }

  const onValueChange = useCallback(
    option => {
      if (option) {
        onChange(suggestions.find(o => o.title === option));
      }
    },
    [onChange, suggestions],
  );

  if (!mappedSuggestions) {
    return null;
  }

  return (
    <Autocomplete
      className={className}
      label={label}
      suggestions={mappedSuggestions}
      value={value?.title}
      onValueChange={onValueChange}
      inputProps={{
        style: {
          fontSize: '14px',
          color: brandColor,
          ...inputStyle,
        },
        containerStyles: inputContainerStyle,
        defaultValue: suggestions.find(item => item.key === null)?.title,
        'data-testid': autocompleteProps?.['data-testid'] ? `autocomplete-${autocompleteProps?.['data-testid']}` : '',
        id: autocompleteProps?.id,
      }}
      icon={<StyledArrowDownIcon />}
      iconStyle={{ padding: 0, width: 'auto', ...iconStyle }}
      inputComponent={InlineAutoCompleteInput}
      {...autocompleteProps}
    />
  );
};

const StyledGroupAutocomplete = styled(GroupByAutocomplete)`
  display: inline-block;

  input {
    padding: 1px 30px 2px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  }

  &&&& p {
    color: ${({ theme }) => theme.palette.text.actionLabel.primary};

    ${({ label }) =>
      label !== '' &&
      css`
        margin-right: ${spacing()}px;
      `}
  }
`;

const StyledArrowDownIcon = styled(KeyboardArrowDownIcon)`
  cursor: pointer;

  && {
    fill: ${({ theme }) => theme.palette.text.actionLabel.primary};
    font-size: 20px;
  }
`;

export default StyledGroupAutocomplete;
