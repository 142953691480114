import styled, { css } from 'styled-components';

import { spacing } from 'design-system/theme';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  padding: ${spacing(8)}px 10vw ${spacing(10)}px;
  gap: ${spacing(3)}px;
`;

export const SideSection = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  ${({ withPaddingTop }) =>
    withPaddingTop &&
    css`
      padding-top: ${spacing(8)}px;
    `}
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeSmallLarge}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  line-height: 1.25;

  span {
    font-size: ${({ theme }) => theme.typography.fontSize}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  }
`;

export const StyledText = styled.p`
  font-size: ${({ theme }) => theme.typography.fontSizeSmallLarge}px;
  color: ${({ theme }) => theme.palette.text.primary};
  line-height: 1.25;
  margin-top: ${spacing(2)}px;
`;

export const ButtonWrapper = styled.div`
  margin-top: ${spacing(4)}px;
`;
