import { useEffect, useCallback } from 'react';
import pluralize from 'pluralize';
import { useDispatch, useSelector } from 'react-redux';

import useSystemFields from 'hooks/useSystemFields';
import usePageQueryParamFilters from 'hooks/usePageQueryParamFilters';
import { REQUESTS_LIST_PATH } from 'constants/filters/pages';
import { addQueryParamToUrl } from 'utils/queryParamsUtils';
import { OPEN_CUSTOMER_REQUEST_QUERY_PARAM } from 'constants/customerRequests';
import { USER_IS_OWNER_FILTER_KEY } from 'routes/CustomerRequests/HeaderFilters/constants';

import {
  fetchMissionControlRequests,
  selectMissionControlHomeRequests,
  selectIsLoadingMissionControlHomeRequests,
} from 'features/MissionControlHome/store';

const getTitle = getSystemFieldName => {
  const titleSuffix = 'My';
  const entityTitle = pluralize(getSystemFieldName('request'));

  return `${titleSuffix} ${entityTitle}`;
};

const useMissionControlMyRequests = () => {
  const dispatch = useDispatch();

  const [getSystemFieldName] = useSystemFields();

  const { navigateToPageWithFilter } = usePageQueryParamFilters();

  const requests = useSelector(selectMissionControlHomeRequests);

  const isLoading = useSelector(selectIsLoadingMissionControlHomeRequests);

  const fetchRequests = () => dispatch(fetchMissionControlRequests());

  const openCustomerRequestsDrawer = useCallback(
    row => row && addQueryParamToUrl(OPEN_CUSTOMER_REQUEST_QUERY_PARAM, row.id),
    [addQueryParamToUrl],
  );

  const getRowId = ({ data }) => data.id;

  const goToRequestsPage = () => navigateToPageWithFilter(`/${REQUESTS_LIST_PATH}`, USER_IS_OWNER_FILTER_KEY);

  useEffect(() => {
    fetchRequests();
  }, []);

  return {
    requests,
    dataLength: requests?.length || 0,
    isLoading,
    title: getTitle(getSystemFieldName),
    entitySystemFieldName: getSystemFieldName('request'),
    openCustomerRequestsDrawer,
    goToRequestsPage,
    getRowId,
  };
};

export default useMissionControlMyRequests;
