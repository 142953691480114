import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';

import { getLogin } from 'store/login/selectors.js';
import { getFunctionRoles } from 'store/functionRoles/selectors.js';
import { signup } from 'store/login';
import { functionRolesActions } from 'store/functionRoles';
import getSignupQuerystring from 'routes/helpers/getSignupQuerystring';

import { getDefaultRoute } from 'utils';

const componentHOC = Component => {
  return ({ location }) => {
    const dispatch = useDispatch();

    const [functionRoles, setFunctionRoles] = useState([]);
    const currentUser = useState();

    const functionRolesWhitelist = ['Product Management', 'Program Management', 'Engineering', 'Other'];

    const loginSelector = useSelector(getLogin);
    const storeFunctionRoles = useSelector(getFunctionRoles);

    useEffect(() => {
      const array = storeFunctionRoles
        .filter(fr => functionRolesWhitelist.includes(fr.title))
        .map(fr => ({ id: fr.id, title: fr.title }));

      setFunctionRoles(array);
    }, [storeFunctionRoles]);

    useEffect(() => {
      const loadFuncs = dispatch(functionRolesActions.fetchFunctionRoles());

      Promise.all([loadFuncs]).then(result => {});
    }, []);

    const { next } = queryString.parse(location.search);

    if (loginSelector.isAuthenticated && localStorage.getItem('user') !== null) {
      if (next) {
        return <Redirect to={next} />;
      }

      return <Redirect to={getDefaultRoute(currentUser)} />;
    }

    const handleSubmit = userSignUpObj => {
      const { ref } = queryString.parse(location.search);

      dispatch(signup({ ...userSignUpObj, ref, querystring: getSignupQuerystring(location.pathname, location.search) }));
    };

    return (
      <Component
        handleSubmit={handleSubmit}
        functionRoles={functionRoles}
        signUpServerError={loginSelector.signupError ? loginSelector.signupError : null}
      />
    );
  };
};

export default componentHOC;
