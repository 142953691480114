const getDetailsTableVisibleColumns = (getSystemFieldName, hasKeyResult1) => {
  return [
    {
      key: 'objectiveTitle',
      label: getSystemFieldName('objective'),
    },
    ...(hasKeyResult1
      ? [
          {
            key: 'keyResult1Title',
            label: getSystemFieldName('keyResult1'),
          },
        ]
      : []),
    {
      key: 'roadmapTitle',
      label: getSystemFieldName('roadmap'),
    },
    {
      key: 'phaseTitle',
      label: getSystemFieldName('phase'),
    },
    {
      key: 'progress',
      label: getSystemFieldName('progress'),
    },
    {
      key: 'ownerName',
      label: getSystemFieldName('owner'),
    },
  ];
};

const getObjectiveId = (index, chartData) => chartData.ids[index];

export { getDetailsTableVisibleColumns, getObjectiveId };
