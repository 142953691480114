import { propEq } from 'ramda';
import head from 'lodash/head';

import { MILESTONE_TYPE } from 'utils/swimlane/new/constants';

const isMilestone = propEq('type', MILESTONE_TYPE);

const isMilestoneTopGroup = groups =>
  Array.isArray(groups) ? head(groups)?.meta?.type === MILESTONE_TYPE : groups?.meta?.type === MILESTONE_TYPE;

export { isMilestone, isMilestoneTopGroup };
