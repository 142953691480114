import { UPDATE_USER_BY_ID_FULFILLED } from 'store/users/types';
import { setCurrentUser } from 'store/login';

export default ({ dispatch, getState }) => next => action => {
  if (action.type === UPDATE_USER_BY_ID_FULFILLED) {
    const { login } = getState();
    const { payload } = action;

    if (login.currentUser && payload && login.currentUser.id === payload.id) {
      dispatch(setCurrentUser({
        ...login.currentUser,
        ...payload,
      }));
    }
  }

  return next(action);
};
