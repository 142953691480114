import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTo, pipe, not } from 'ramda';
import styled from 'styled-components';

import theme, { spacing } from 'design-system/theme';
import { brandColor } from 'design-system/themes/default';
import { getGridConfigValue } from 'store/grids/selectors';
import Autocomplete from 'design-system/atoms/Autocomplete/index';
import InlineAutoCompleteInput from 'design-system/atoms/InlineAutoCompleteInput/index';

import { saveGridConfig } from 'store/grids';
import {
  FORECAST_BY_HEADCOUNT,
  FORECAST_BY_TIME,
  FORECAST_LIST_FORECAST_BY_OPTION_KEY,
  FORECAST_BY_TIME_CAPACITY,
} from 'constants/forecast';
import { cleanPageHeadcountFilter } from 'store/forecastHeadcount';

import useEnrichedSelectedGroupsFromState from 'hooks/grid/useGridSelectedGroups';

const HEADCOUNT_OPTION = { value: FORECAST_BY_HEADCOUNT, title: 'Headcount' };
const TIME_OPTION = { value: FORECAST_BY_TIME, title: 'Estimate' };
const TIME_CAPACITY_OPTION = { value: FORECAST_BY_TIME_CAPACITY, title: 'Estimate and Capacity' };
const SUGGESTIONS = [TIME_OPTION, TIME_CAPACITY_OPTION, HEADCOUNT_OPTION];

const DEFAULT_VALUE = TIME_OPTION.value;
const LABEL = 'Forecast by';
const AUTOCOMPLETE_ID = 'forecast_by';
const AUTOCOMPLETE_TEST_ID = AUTOCOMPLETE_ID;

const defaultValue = defaultTo(DEFAULT_VALUE);
const notExists = pipe(Boolean, not);

const ForecastByAutocomplete = ({ className, viewType, pageId }) => {
  const dispatch = useDispatch();
  const suggestions = SUGGESTIONS;

  const onUpdateGridConfig = (key, value) => dispatch(saveGridConfig(viewType, key, value));
  const selectedForecastBy = useSelector(state => getGridConfigValue(state, viewType, FORECAST_LIST_FORECAST_BY_OPTION_KEY));
  const { selectedGroup2 } = useEnrichedSelectedGroupsFromState(viewType);

  const value = useMemo(() => {
    return suggestions.find(o => o.value === defaultValue(selectedForecastBy));
  }, [selectedForecastBy, suggestions]);

  const onValueChange = useCallback(
    option => {
      if (notExists(option)) {
        return;
      }

      /*
       * Forecast by headcount should not have selected group2
       *
       * Will check if has selectedGroup2 and remove it on that case
       */
      if (option === FORECAST_BY_HEADCOUNT && selectedGroup2) {
        onUpdateGridConfig('selectedGroup2', null);
      }

      onUpdateGridConfig(FORECAST_LIST_FORECAST_BY_OPTION_KEY, option);
      dispatch(cleanPageHeadcountFilter(pageId));
    },
    [suggestions, pageId, selectedGroup2],
  );

  return (
    <Wrapper id={AUTOCOMPLETE_ID}>
      <Autocomplete
        className={className}
        label={LABEL}
        suggestions={suggestions}
        value={value?.title}
        onValueChange={onValueChange}
        inputProps={{
          style: {
            fontSize: theme.typography.fontSize,
            color: brandColor,
          },
          'data-testid': AUTOCOMPLETE_TEST_ID,
        }}
        icon={<StyledArrowDownIcon />}
        iconStyle={{ padding: 0, width: 'auto' }}
        inputComponent={InlineAutoCompleteInput}
      />
    </Wrapper>
  );
};

ForecastByAutocomplete.propTypes = {
  className: PropTypes.string,
  viewType: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
};
const Wrapper = styled.div`
  display: flex;
`;

const StyledForecastByAutocomplete = styled(ForecastByAutocomplete)`
  margin: 0 8px;

  input {
    padding: 1px 30px 2px 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  }

  &&&& p {
    color: ${({ theme }) => theme.palette.text.actionLabel.primary};
    margin-right: ${spacing()}px;
  }
`;

const StyledArrowDownIcon = styled(KeyboardArrowDownIcon)`
  cursor: pointer;

  && {
    fill: ${({ theme }) => theme.palette.newLayout.text.siroccoGrey};
    font-size: 20px;
  }
`;

export default StyledForecastByAutocomplete;
