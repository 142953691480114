import React from 'react';
import styled, { css } from 'styled-components';

import materialColorsSnapshot from 'routes/Dashboard/Snapshot/colors';

import LaunchIconButton from 'design-system/molecules/LaunchIconButton/index';

import ProgressBarSquared from 'design-system/atoms/ProgressBarSquared';
import unitNumberToValue from 'utils/unitNumberToValue';

const MetricProgressBar = ({ child, entity, hasAdvancedMetricReporting, toggleMetricInformation }) => {
  const _renderOKRProgressBar = () => (
    <ProgressBarSquared
      smallVersion={child}
      color={entity.status_color ? entity.status_color.toLowerCase() : 'darkestGray'}
      formatValues={entity.progress_type !== 'manual'}
      showHalfValue={entity.progress_type === 'manual'}
      actualValue={entity.progress_type === 'manual' ? entity.progress * 100 : unitNumberToValue(entity.metrics[0]?.actual_value)}
      actualValueLabel={
        entity.progress_type !== 'manual' ? entity.metrics[0]?.actual_value : `${Math.round(entity.progress * 100)}%`
      }
      minValue={entity.progress_type === 'manual' ? 0 : unitNumberToValue(entity.metrics[0]?.baseline_value)}
      minValueLabel={entity.progress_type !== 'manual' ? entity.metrics[0]?.baseline_value : '0%'}
      maxValue={entity.progress_type === 'manual' ? 100 : unitNumberToValue(entity.metrics[0]?.target_value)}
      maxValueLabel={entity.progress_type !== 'manual' ? entity.metrics[0]?.target_value : '100%'}
    />
  );

  return (
    <Wrapper child={child} onClick={() => hasAdvancedMetricReporting && toggleMetricInformation((entity?.metrics || [])[0])}>
      {entity.metrics && entity.metrics[0]?.name && (
        <>
          <MetrictStatusHeader child={child}>
            <HeaderWrapper>
              <div>{entity.metrics && (entity.metrics[0]?.name || 'No titled')}</div>
              {hasAdvancedMetricReporting && (
                <ButtonWrapper>
                  <LaunchIconButton handleClick={() => toggleMetricInformation(entity.metrics[0])} />
                </ButtonWrapper>
              )}
            </HeaderWrapper>
          </MetrictStatusHeader>
          <MetrictStatusContent child={child}>{_renderOKRProgressBar()}</MetrictStatusContent>
        </>
      )}
    </Wrapper>
  );
};

export default MetricProgressBar;

const ButtonWrapper = styled.div``;

const Wrapper = styled.div`
  &:hover {
    background: ${materialColorsSnapshot.lightGray};

    ${ButtonWrapper} {
      visibility: visible;
    }
  }
}
`;

const MetrictStatusHeader = styled.div`
  &&&& {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${props => props.lines || 1};
    -webkit-box-orient: vertical;

    margin-bottom: 1rem;
    text-transform: capitalize;

    font-size: ${props => props.theme.typography.fontSizeSmallLarge}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    line-height: ${props => props.theme.typography.lineHeightRegularLarge}px;
    color: ${materialColorsSnapshot.darkestGray};

    ${({ child, theme }) =>
      child &&
      css`
        margin-bottom: 0.5rem;
        font-size: ${theme.typography.fontSize}px;
        line-height: ${props => props.theme.typography.lineHeightSmallLarge}px;
      `}
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${ButtonWrapper} {
    visibility: hidden;
  }
`;

const MetrictStatusContent = styled.div`
  &&&& {
  }
`;
