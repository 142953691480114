import React, { useState } from 'react';
import { reject, isNil, pipe, not, isEmpty } from 'ramda';
import Switch from '@material-ui/core/Switch';

const isNotEmpty = pipe(reject(isNil), isEmpty, not);

export default ({ disabled, register, unregister, webhooks, orgIntegrationId }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Switch
      disabled={disabled || loading}
      checked={isNotEmpty(webhooks)}
      onChange={async e => {
        setLoading(true);

        try {
          if (e.target.checked) {
            await register(orgIntegrationId);
          } else {
            await unregister(orgIntegrationId);
          }
        } catch (err) {
          // already being handled in src/store/middlewares/errorHandler/errorHandler.js
        }

        setLoading(false);
      }}
    />
  );
};
