import moment from 'moment-timezone';
import flatten from 'lodash/flatten';
import isObject from 'lodash/isObject';

import { brandColor, materialColorsAlt } from 'design-system/themes/default';
import { getAllTasks, getAllEsts } from 'utils';

/**
 * Function to get Projects links for gantt timeline
 *
 * @param {*} projects projects from the store
 * @param {*} withTasks
 * @param {*} withEsts
 */
const generateGanttLinks = (projects, withTasks = true, withEsts = true, portfolioMode = false) => {
  const allTasks = getAllTasks(projects);
  const allEsts = getAllEsts(projects);

  const getEntity = (id, type = 'project') => {
    if (type === 'project') {
      if (portfolioMode) {
        const allChildren = flatten(projects.map(p => p.children || []));
        const children = allChildren.find(p => p && p.id === id);

        if (children) return children;
      }
      return projects.find(p => p && p.id === id);
    } else if (type === 'estimate') {
      const est = allEsts.find(e => e && e.id === id);

      return est ? { ...est, id: `estimate-${est.id}` } : null;
    } else if (type === 'task') {
      const task = allTasks.find(t => t && t.id === id);

      return task ? { ...task, id: `task-${task.id}` } : null;
    }

    return null;
  };

  const generateDep = (objectId, dependency, types) => {
    const sourceEntity = getEntity(objectId, types[0]);
    const targetEntity = getEntity(isObject(dependency) ? dependency.id : dependency, types[1]);

    if (!sourceEntity || !targetEntity) {
      return null;
    }
    let sourceEnd = sourceEntity.ganttEndDate
      ? moment(sourceEntity.ganttEndDate)
      : moment(sourceEntity.ganttStartDate).addDuration(sourceEntity.duration || 0, 'days');

    if (withEsts && sourceEntity?.estimatesTimeline?.endDate) {
      sourceEnd = moment(sourceEntity?.estimatesTimeline?.endDate);
    }

    let targetStart = moment(targetEntity.ganttStartDate);

    if (withEsts && targetEntity?.estimatesTimeline?.startDate) {
      targetStart = moment(targetEntity?.estimatesTimeline?.startDate);
    }

    const color = moment(targetStart).add(0.5, 'days').isSameOrAfter(sourceEnd) ? brandColor : materialColorsAlt.red;

    const dependencyType = isObject(dependency) && dependency.info && dependency.info.type;

    return {
      id: `${sourceEntity.id}_${targetEntity.id}`,
      source: sourceEntity.id,
      target: targetEntity.id,
      color,
      type: dependencyType || '0',
    };
  };

  const projectLinks = projects.reduce((acc, project) => {
    const pDep = project.projectDependencies
      ? project.projectDependencies.map(p => generateDep(project.id, p, ['project', 'project']))
      : [];
    const tDep = project.taskDependencies
      ? project.taskDependencies.map(t => generateDep(project.id, t, ['project', 'task']))
      : [];
    const eDep = project.estimateDependencies
      ? project.estimateDependencies.map(e => generateDep(project.id, e, ['project', 'estimate']))
      : [];

    if (portfolioMode && project.children) {
      const childrenDep = flatten(
        project.children.map(child =>
          child.projectDependencies ? child.projectDependencies.map(p => generateDep(child.id, p, ['project', 'project'])) : [],
        ),
      );

      acc = acc.concat(childrenDep);
    }

    acc = acc.concat(pDep).concat(tDep).concat(eDep);
    return acc;
  }, []);

  const taskLinks = withTasks
    ? allTasks.reduce((acc, task) => {
        const pDep = task.projectDependencies
          ? task.projectDependencies.map(p => generateDep(task.id, p, ['task', 'project']))
          : [];
        const tDep = task.taskDependencies ? task.taskDependencies.map(t => generateDep(task.id, t, ['task', 'task'])) : [];

        acc = acc.concat(pDep).concat(tDep);
        return acc;
      }, [])
    : [];

  const estLinks = withEsts
    ? allEsts.reduce((acc, est) => {
        if (!est) {
          return [];
        }

        const pDep = est.projectDependencies
          ? est.projectDependencies.map(p => generateDep(est.id, p, ['estimate', 'project']))
          : [];
        const eDep = est.estimateDependencies
          ? est.estimateDependencies.map(e => generateDep(est.id, e, ['estimate', 'estimate']))
          : [];

        acc = acc.concat(pDep).concat(eDep);
        return acc;
      }, [])
    : [];

  return projectLinks.concat(taskLinks).concat(estLinks).filter(Boolean);
};

export default generateGanttLinks;
