import { createSelector } from 'reselect';
import { isLoading, isError, isUninitialized } from 'utils/store/thunk';
import { CREATE_DISCOVERY_BOT_THREAD, PROCESS_USER_CHAT_MESSAGE } from './types';

export const getState = state => {
  return state.discoveryBot;
};

export const getThreadMessages = createSelector([getState], state => state?.threadMessages || []);
export const getLatestBotResponse = createSelector([getState], state => state?.latestBotResponse || null);
export const getDiscoveryBotThreadId = createSelector([getState], state => state?.threadId || null);

// Thunk operations

const getOperations = state => {
  return state.discoveryBot.operations;
};

export const getIsCreateBotThreadLoading = createSelector(getOperations, state => isLoading(state, CREATE_DISCOVERY_BOT_THREAD));
export const getCreateBotThreadError = createSelector(getOperations, state => isError(state, CREATE_DISCOVERY_BOT_THREAD));
export const getIsCreateThreadUnitialized = createSelector(getOperations, state =>
  isUninitialized(state, CREATE_DISCOVERY_BOT_THREAD),
);

export const getIsProcessingUserMessage = createSelector(getOperations, state => isLoading(state, PROCESS_USER_CHAT_MESSAGE));
export const getProcessUserChatMessageError = createSelector(getOperations, state => isError(state, PROCESS_USER_CHAT_MESSAGE));
