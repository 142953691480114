import React from 'react';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const Component = props => {
  const { isTestingConnection, onTestConnectionClick, userCanUpdateOrgIntegration } = props;

  return (
    <Wrapper>
      <Button
        id="test-azure-devops-connection-button"
        color="primary"
        onClick={onTestConnectionClick}
        disabled={isTestingConnection || !userCanUpdateOrgIntegration}
      >
        {isTestingConnection && 'TESTING...'}
        {!isTestingConnection && 'TEST MY AZURE CONNECTION'}
      </Button>
      {isTestingConnection && <CircularProgress size={25} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &&&& {
    display: flex;
    align-items: center;
  }
`;

export default Component;
