import { getUserName } from 'utils/index';

import { RECENTLY_CREATED_FILTER_KEY, RECENTLY_CREATED_META_KEY, USER_IS_OWNER_FILTER_KEY } from './constants';

import { FILTERS_FUNCTIONS } from 'design-system/constants';
import { defaultTo, equals, path } from 'ramda';

const getDefaultFilters = currentUser => [
  {
    key: RECENTLY_CREATED_FILTER_KEY,
    children: [],
    label: 'Recently created',
    metaType: RECENTLY_CREATED_META_KEY,
    type: 'click',
  },
  {
    key: USER_IS_OWNER_FILTER_KEY,
    children: [],
    label: `I'm the owner`,
    metaType: 'owners',
    type: 'click',
    filter: [
      {
        ...currentUser,
        title: getUserName(currentUser),
      },
    ],
    op: FILTERS_FUNCTIONS.in.key,
  },
];

/**
 * For the given filters check if the header filter results counter should be shown
 * If filter is all levels we are omiting because it can cause confusion since
 * we are returning all the parents. So the user can be confused on why it shows 10 items but in pages like
 * snapshot you only see 1 item before drilling down
 *
 * @param {Object} filters
 *
 * @returns {Boolean}
 */
const needsToShowResultsCounterForFilters = filters => {
  const levelFilter = path(['fields', 'level'], defaultTo({}, filters));

  const isAllLevels = equals('all', levelFilter);

  if (!levelFilter || isAllLevels) {
    // When all levels, or level filter is not there do not show the result counts
    return false;
  }

  return true;
};

export { getDefaultFilters, needsToShowResultsCounterForFilters };
