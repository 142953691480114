import React, { Component } from 'react';
import { string } from 'prop-types';
import { prop } from 'ramda';

import MicroChip from 'design-system/atoms/MicroChip/index';

import { materialColors } from 'design-system/themes/default';
import invertedTextColor from 'design-system/utils/invertedTextColor';
import { STATUS_COLOR_PROP_MAP } from 'constants/objectives';
import { ALT_HEALTH_COLORS_LABELS } from 'constants/health';
import { isOkr } from 'utils/getOkrDataForIdeasGrid';

const UNDEFINED_VALUE = 'Undefined';

export default class ShowMaterialColor extends Component {
  static propTypes = {
    value: string,
  };

  static defaultProps = {
    value: '',
  };

  getLabelAndBackground = (data, value, optionsLabel) => {
    let label;
    let bgColor;

    if (isOkr(data)) {
      optionsLabel = ALT_HEALTH_COLORS_LABELS;

      label = ALT_HEALTH_COLORS_LABELS[value] ? ALT_HEALTH_COLORS_LABELS[value] : undefined;

      bgColor = STATUS_COLOR_PROP_MAP[value] ? STATUS_COLOR_PROP_MAP[value] : 'lightGray';
    } else {
      label = optionsLabel && optionsLabel[value] ? optionsLabel[value] : value;

      bgColor = value === 'Undefined' ? 'darkGray' : value.toLowerCase();
    }

    return [label, bgColor];
  };

  render() {
    const { fullWidth } = this.props;

    const { optionsLabel } = this.props.colDef;

    const value = this.props.getValue();

    if (this.props.node.group && 'value' in this.props) {
      if (this.props.reactContainer) {
        this.props.reactContainer.style.display = 'inline-block';
      }

      return this.props.value;
    }

    const valueIsUndefined = value === UNDEFINED_VALUE;

    if (!value || valueIsUndefined) {
      return '';
    }

    const data = prop('data', this.props);

    const [label, bgColor] = this.getLabelAndBackground(data, value, optionsLabel);

    const renderHexChip = () => (
      <span
        style={{
          padding: '5px',
          borderRadius: '5px',
          whiteSpace: 'pre',
          backgroundColor: `${materialColors[bgColor] || bgColor}`,
          color: invertedTextColor(materialColors[bgColor] || bgColor, true),
        }}
      >
        {typeof value === 'string' && materialColors[bgColor] ? label : '    '}
      </span>
    );

    return materialColors[bgColor] ? (
      <MicroChip
        label={label}
        color={materialColors[bgColor] || bgColor}
        textColor={invertedTextColor(materialColors[bgColor] || bgColor, true)}
        fullWidth={fullWidth}
      />
    ) : (
      renderHexChip()
    );
  }
}
