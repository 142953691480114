import React, { memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Draggable from 'design-system/atoms/Draggable/index';

import ProjectInfoCard from 'containers/ProjectInfoCard';
import ObjectiveInfoCard from 'containers/ObjectiveInfoCard';
import useSummaryDependencyLinesManagement from 'hooks/useSummaryDependencyLinesManagement';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import { getIdeasIntegrations } from 'store/organization/selectors';

import ProjectDependenciesLinker from './ProjectDependenciesLinker';

const CellProject = memo(
  ({
    dragId,
    project,
    isReadOnly,
    displayFields,
    selectedColData,
    selectedRowData,
    localSearchStr,
    index,
    onProjectClick,
    col,
    draggingProjectId,
    setDraggingProjectId,
    allProjectsInfo,
    expandedGroups = [],
    isGrouping,
    isGoalMode,
    isAnonymousUser,
  }) => {
    const customFields = useSelector(getProjectsCustomFields);

    const orgIntegrations = useSelector(getIdeasIntegrations);

    const handleDraggingFlag = useCallback((dragging, projectId) => {
      setDraggingProjectId(dragging ? projectId : null);
    }, []);

    const { showDependencyLines, hideItemsWithoutDependencies } = useSummaryDependencyLinesManagement();

    const projectInfo = {
      data: project,
      metadata: {
        col,
      },
    };

    const CardComponent = useMemo(() => (isGoalMode ? ObjectiveInfoCard : ProjectInfoCard), [isGoalMode]);

    const dependencyLinesOnNewSummaryVersionAreDisabled =
      localStorage.getItem('disableDependencyLinesOnNewSummaryVersion') === 'true';

    const disableDrag = isReadOnly || isAnonymousUser;

    if (dependencyLinesOnNewSummaryVersionAreDisabled) {
      return (
        <DraggableWrapper key={project.id}>
          <Draggable
            id={dragId}
            index={index}
            disabled={isReadOnly}
            draggingFlagHandler={handleDraggingFlag}
            metadata={project.id}
          >
            <CardComponent
              isGoalMode={isGoalMode}
              dataTestId={dragId}
              project={project}
              disabled={isReadOnly}
              displayFields={displayFields}
              localSearchStr={localSearchStr}
              fieldsToIgnore={[selectedColData && selectedColData.key, selectedRowData && selectedRowData.key]}
              customFields={customFields}
              onClickProject={onProjectClick}
              orgIntegrations={orgIntegrations}
            />
          </Draggable>
        </DraggableWrapper>
      );
    }

    return (
      <DraggableWrapper key={project.id}>
        <ProjectDependenciesLinker
          projectInfo={projectInfo}
          allProjectsInfo={allProjectsInfo}
          disabled={!showDependencyLines}
          isReadOnly={isReadOnly}
          draggingProjectId={draggingProjectId}
          expandedGroups={expandedGroups}
          isGrouping={isGrouping}
          hideItemsWithoutDependencies={hideItemsWithoutDependencies}
        >
          <Draggable
            id={dragId}
            index={index}
            disabled={disableDrag}
            draggingFlagHandler={handleDraggingFlag}
            metadata={project.id}
          >
            <CardComponent
              isGoalMode={isGoalMode}
              dataTestId={dragId}
              project={project}
              disabled={isReadOnly}
              displayFields={displayFields}
              localSearchStr={localSearchStr}
              fieldsToIgnore={[selectedColData && selectedColData.key, selectedRowData && selectedRowData.key]}
              customFields={customFields}
              onClickProject={onProjectClick}
              orgIntegrations={orgIntegrations}
            />
          </Draggable>
        </ProjectDependenciesLinker>
      </DraggableWrapper>
    );
  },
);

const DraggableWrapper = styled.div`
  z-index: 1;
`;

CellProject.displayName = 'SummaryCellProject';

export default CellProject;
