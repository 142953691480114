import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_MISSION_CONTROL_REQUESTS'),
  ...getThunkActionTypes('FETCH_MISSION_CONTROL_CREATED_REQUESTS'),
  ...getThunkActionTypes('FETCH_MISSION_CONTROL_OKRS'),
  ...getThunkActionTypes('FETCH_MISSION_CONTROL_METRICS'),
  ...getThunkActionTypes('REFRESH_MISSION_CONTROL_OKRS'),
  ...getThunkActionTypes('REFRESH_MISSION_CONTROL_METRICS'),
};

export const {
  FETCH_MISSION_CONTROL_REQUESTS,
  FETCH_MISSION_CONTROL_REQUESTS_PENDING,
  FETCH_MISSION_CONTROL_REQUESTS_FULFILLED,
  FETCH_MISSION_CONTROL_OKRS,
  FETCH_MISSION_CONTROL_OKRS_PENDING,
  FETCH_MISSION_CONTROL_OKRS_FULFILLED,
  FETCH_MISSION_CONTROL_METRICS,
  FETCH_MISSION_CONTROL_METRICS_PENDING,
  FETCH_MISSION_CONTROL_METRICS_FULFILLED,
  REFRESH_MISSION_CONTROL_OKRS,
  REFRESH_MISSION_CONTROL_OKRS_FULFILLED,
  REFRESH_MISSION_CONTROL_METRICS,
  REFRESH_MISSION_CONTROL_METRICS_FULFILLED,
  FETCH_MISSION_CONTROL_CREATED_REQUESTS,
  FETCH_MISSION_CONTROL_CREATED_REQUESTS_FULFILLED,
} = actionTypes;
