import React from 'react';
import styled from 'styled-components';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import ProjectType from 'containers/ProjectType';

const ParentCellRenderer = ({ value }) => {
  if (!value) return '';

  const { layer, title } = value;

  return (
    <TextDeprecated size="small">
      <AlignedVertically>
        {layer && <ProjectType layer={layer} style={{ height: '14', width: '14', marginRight: '4px' }} />}
        {title}
      </AlignedVertically>
    </TextDeprecated>
  );
};

export default ParentCellRenderer;

const AlignedVertically = styled.div`
  display: flex;
  align-items: center;
`;
