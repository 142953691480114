import React, { useMemo } from 'react';
import styled from 'styled-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EditIcon from '@material-ui/icons/Edit';

import Card from 'design-system/molecules/Card/index';

import WidgetGrid from 'design-system/organisms/WidgetGrid';
import Roadmap from 'design-system/atoms/DragonIcons/Roadmap';
import { nameColumnDef, numericColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import MetricChangeValuesPopover from 'containers/MetricsForm/components/MetricChangeValuesPopover/MetricChangeValuesPopover';
import CenteredLoading from 'features/PortfolioOverview/components/CenteredLoading';
import EmptyMessage from 'features/MissionControlHome/components/EmptyMessage';
import useUpdateGridWidgetHeight from 'features/MissionControlHome/widgets/useUpdateGridWidgetHeight';
import { getWidgetHeight } from 'features/PortfolioOverview/widgets/helpers';
import useMissionControlMyMetrics from './useMissionControlMyMetrics';
import MetricNameCellRenderer from './MetricNameCellRenderer';

const GRID_MAX_ROWS = 5;
const GRID_MIN_HEIGHT = 100;

const metricActualValueColumnDef = {
  ...numericColumnDef,
  field: 'actual_value',
  headerName: 'Actual',
  headerClass: 'ag-header-cell-text-centered',
  flex: 0.15,
  resizable: true,
};

const metricTargetValueColumnDef = {
  ...numericColumnDef,
  field: 'target_value',
  headerName: 'Target',
  headerClass: 'ag-header-cell-text-centered',
  flex: 0.15,
  resizable: true,
};

const MetricsList = ({ id, updateHeightOnLayout = () => {} }) => {
  const {
    metrics,
    isLoading,
    dataLength,
    title,
    entitySystemFieldName,
    metricForPopover,
    popoverAnchorEl,
    canUpdateMetric,
    openMetricView,
    openProjectsListLightbox,
    goToMetricsPage,
    getRowId,
    openMetricPopover,
    setPopoverAnchorEl,
  } = useMissionControlMyMetrics();

  const hasPagination = dataLength > GRID_MAX_ROWS;

  const { updateWidgetHeight, gridHeight } = useUpdateGridWidgetHeight({
    widgetId: id,
    getWidgetHeight,
    updateHeightOnLayout,
    hasPagination,
    maxItems: GRID_MAX_ROWS,
  });

  const metricNameColumnDef = useMemo(() => {
    return {
      ...nameColumnDef,
      cellRenderer: MetricNameCellRenderer,
      suppressMenu: true,
      actions: [
        {
          icon: <OpenInNewIcon />,
          onClick: openMetricView,
        },
        {
          icon: <Roadmap />,
          onClick: openProjectsListLightbox,
        },
        {
          icon: <EditIcon />,
          onClick: openMetricPopover,
        },
      ],
      cellClass: 'align-center',
      flex: 0.7,
      resizable: true,
    };
  }, [openMetricView, openProjectsListLightbox]);

  return (
    <StyledCard
      title={title}
      actions={[{ icon: <OpenInNewIcon />, onClick: goToMetricsPage, testId: 'open-my-metrics' }]}
      onClickTitle={goToMetricsPage}
    >
      {isLoading ? <CenteredLoading /> : null}
      {!isLoading && !dataLength ? <EmptyMessage entity={entitySystemFieldName} /> : null}
      {!isLoading && !!dataLength ? (
        <>
          <WidgetGrid
            hasPagination={hasPagination}
            getRowId={getRowId}
            data={metrics}
            columnDefs={[metricNameColumnDef, metricActualValueColumnDef, metricTargetValueColumnDef]}
            pageSize={GRID_MAX_ROWS}
            numberOfRecords={dataLength}
            setVisibleItems={i => updateWidgetHeight(i)}
            gridHeight={gridHeight || GRID_MIN_HEIGHT}
          />
          <MetricChangeValuesPopover
            anchorEl={popoverAnchorEl}
            setAnchorEl={el => setPopoverAnchorEl(el)}
            metric={metricForPopover}
            canAddOrEdit={canUpdateMetric}
          />
        </>
      ) : null}
    </StyledCard>
  );
};

export default MetricsList;

const StyledCard = styled(Card)`
  overflow: hidden;
`;
