import { useCallback, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import head from 'lodash/head';
import { defaultTo, pipe, prop, propOr, pick, isEmpty, not } from 'ramda';

import {
  addProductWithoutSave,
  addRoadmapWithoutSave,
  bulkDeleteProducts,
  bulkDeleteRoadmaps,
  createProduct,
  createRoadmaps,
  deleteProductById,
  deleteRoadmapById,
  fetchRoadmaps,
  mergeProducts,
  mergeRoadmaps,
  moveProductToRoadmap,
  removeUnsavedRoadmapsAndProducts,
  switchProductsRowOrder,
  switchRoadmapsRowOrder,
  updateProductById,
  updateRoadmapById,
  openRoadmapLightbox,
  getRoadmapUserRoles,
  moveRoadmapToRoadmap,
  updateProducts,
} from 'store/roadmaps';
import { getAllRoadmaps as getAllRoadmapsSelector } from 'store/roadmaps/selectors';

import { isOfObjectProductType, isOfObjectRoadmapType, ROADMAP_KEY, PRODUCT_2_KEY, getAllRoadmaps } from '../helpers';
import { METADATA_LEVELS } from 'constants/common';
import { getOrganization } from 'src/store/organization';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { PRODUCT_1_KEY } from 'constants/roadmaps';

const defaultToArray = defaultTo([]);
const findChildren = pipe(prop('products'), defaultToArray);

const useRoadmaps = ({ hasProducts, hasProducts2, hasCorpLevel, hideArchivedItems }) => {
  const dispatch = useDispatch();
  const organization = useSelector(state => getOrganization(state));
  const storeRoadmaps = useSelector(state => getAllRoadmapsSelector(state));
  const { canView } = usePermissions();

  const filteredRoadmapsForSettings = useMemo(() => {
    if (canView(PERMISSION_RESOURCES.roadmapCorp)) {
      return storeRoadmaps;
    }

    return storeRoadmaps.filter(roadmap => !roadmap.organization_id || roadmap.organization_id === organization.id);
  }, [storeRoadmaps, organization.id, canView]);

  const roadmapsWithHierarchy = useMemo(() => {
    return getAllRoadmaps(filteredRoadmapsForSettings, hasProducts, hasProducts2, hideArchivedItems, canView);
  }, [filteredRoadmapsForSettings, hasProducts, hasProducts2, canView]);

  const childrenSettingKeys = useMemo(() => {
    const canViewProduct1SystemFieldName = canView(PERMISSION_RESOURCES.systemFieldName, { field: PRODUCT_1_KEY });

    const roadmapKey = hasCorpLevel ? ROADMAP_KEY : '';
    const subRoadmapKey = hasProducts ? PRODUCT_1_KEY : '';
    const productKey = hasProducts2 && canViewProduct1SystemFieldName ? PRODUCT_2_KEY : '';

    return [roadmapKey, subRoadmapKey, productKey].filter(Boolean);
  }, [hasProducts, hasProducts2, hasCorpLevel, canView]);

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          fetchRoadmaps,
          createRoadmaps,
          createProduct,
          bulkDeleteRoadmaps,
          bulkDeleteProducts,
          addProductWithoutSave,
          addRoadmapWithoutSave,
          removeUnsavedRoadmapsAndProducts,
          updateRoadmapById,
          updateProductById,
          deleteRoadmapById,
          deleteProductById,
          mergeProducts,
          mergeRoadmaps,
          moveProductToRoadmap,
          switchProductsRowOrder,
          switchRoadmapsRowOrder,
          openRoadmapLightbox,
          getRoadmapUserRoles,
          moveRoadmapToRoadmap,
          updateProducts,
        },
        dispatch,
      ),
    [dispatch],
  );

  const {
    fetchRoadmaps: fetchRoadmapsWithProducts,
    createRoadmaps: saveRoadmap,
    createProduct: saveProduct,
    bulkDeleteRoadmaps: deleteRoadmaps,
    bulkDeleteProducts: deleteProducts,
    deleteRoadmapById: deleteRoadmap,
    deleteProductById: deleteProduct,
    mergeProducts: mergeSelectedProducts,
    mergeRoadmaps: mergeSelectedRoadmaps,
    updateRoadmapById: updateRoadmap,
    updateProductById: updateProduct,
    openRoadmapLightbox: openRoadmapLightboxAction,
    getRoadmapUserRoles: getRoadmapUserRolesAction,
    addRoadmapWithoutSave: addRoadmapWithoutSaveAction,
    updateProducts: updateMultipleProducts,
    ...remainingBoundActionCreators
  } = boundActionCreators;

  const save = data => {
    if (isOfObjectProductType(data)) {
      return saveProduct(data);
    }
    return saveRoadmap(data);
  };

  const update = (id, data, oldVal, event) => {
    const originalUpdate = isOfObjectProductType(data) ? () => updateProduct(id, data) : () => updateRoadmap(id, data);

    const itemBeingUpdated = prop('data', event);

    const childrenLvl1 = findChildren(itemBeingUpdated);
    let childrenLvl2 = [];

    childrenLvl1.forEach(child => {
      childrenLvl2 = [...childrenLvl2, ...findChildren(child)];
    });

    // before update we need to check:
    // 1 - if the prop being updated is the Status
    // 2 - if 1, if the roadmap being updated has products
    // if 1 and 2 all products should be also updated with same Status. otherwise update just the roadmap

    // checking 1
    const notUpdatingStatus = pipe(propOr(false, 'status'), not)(data);

    if (notUpdatingStatus) return originalUpdate(id, data);

    // checking 2

    const parentWithoutChildren = pipe(isEmpty)(childrenLvl1);

    if (parentWithoutChildren) return originalUpdate(id, data);

    // updating all products Status

    const update = pick(['status'], data);
    const itemsToUpdate = [];
    const children = [...childrenLvl1, ...childrenLvl2];

    children.forEach(child => {
      itemsToUpdate.push({ id: child.id, ...update });
    });

    return updateMultipleProducts(itemsToUpdate).then(result => {
      originalUpdate(id, data);
    });
  };

  const deleteById = (objectToDeleteId, objectToDelete) => {
    return isOfObjectProductType(objectToDelete) ? deleteProduct(objectToDeleteId) : deleteRoadmap(objectToDeleteId);
  };

  const merge = (ids, idToPersist, selectedItems) => {
    if (selectedItems.filter(obj => isOfObjectProductType(obj)).length > 0) {
      return mergeSelectedProducts(ids, idToPersist);
    }
    return mergeSelectedRoadmaps(ids, idToPersist);
  };

  const bulkDelete = useCallback(
    (ids, roomId, selectedItems) => {
      if (!selectedItems.length) {
        return;
      }

      if (isOfObjectRoadmapType(head(selectedItems))) {
        return deleteRoadmaps(ids, roomId);
      }

      return deleteProducts(ids, roomId);
    },
    [deleteRoadmaps, deleteProducts],
  );

  const handleOpenRoadmapLightbox = roadmapId => {
    getRoadmapUserRoles(roadmapId);
    openRoadmapLightboxAction(roadmapId);
  };

  const handleAddRoadmapWithoutSaveAction = useCallback(
    data => {
      const shouldBeCorpLevel = hasCorpLevel && !data?.level ? METADATA_LEVELS.LEVEL_CORP : METADATA_LEVELS.LEVEL_1;

      return addRoadmapWithoutSaveAction(data, shouldBeCorpLevel);
    },
    [addRoadmapWithoutSaveAction, hasCorpLevel],
  );

  return {
    roadmaps: roadmapsWithHierarchy,
    childrenSettingKeys,
    save,
    merge,
    bulkDelete,
    deleteById,
    update,
    handleOpenRoadmapLightbox,
    addRoadmapWithoutSave: handleAddRoadmapWithoutSaveAction,
    ...remainingBoundActionCreators,
    fetchRoadmapsWithProducts,
  };
};

export default useRoadmaps;
