import { DEFAULT_SIDEBAR_WIDTH, MIN_BAR_WIDTH, SNAP_BAR_WIDTH, VIEW_MODE_RELATIONS } from './constants';
import { getTargetDateForSnap, isSnapToEnd } from './snapToGrid';
import { differenceBetweenDatesByUnit } from './dates';

/**
 * Calculates the width offsets for each changed group.
 *
 * @param {Array} previousGroupWidths The previous widths of the group columns.
 * @param {Array} currentGroupWidths The current widths of the group columns.
 *
 * @return {Array} the list of the groups widths differences
 * */
const generateGroupOffsets = (previousGroupWidths, currentGroupWidths) =>
  previousGroupWidths.map((previousWidth, index) =>
    currentGroupWidths[index] === DEFAULT_SIDEBAR_WIDTH ? 0 : currentGroupWidths[index] - previousWidth,
  );

/**
 * Caculates the position and width information for a given item.
 *
 * @param {Object} item The item used for calculations
 * @param {String} zoomMode The calendar zoom mode (daily, monthly, weekly or quarterly)
 * @param {Number} slotWidth The width for each timeline slot
 * @param {Date} fromDate The initial date of the date range
 * @param {snapToGridOn} snapToGridOn The snap to grid options selected by the user if any
 *
 * @return {Object} information of the position and width
 * * */
const calculateItemPositionAndWidth = (item, zoomMode, slotWidth, fromDate, snapToGridOn = null) => {
  const targetDate = snapToGridOn ? getTargetDateForSnap(item, zoomMode, snapToGridOn) : item.startDate;

  if (!targetDate) {
    return {};
  }

  const distanceToTargetDate = differenceBetweenDatesByUnit(fromDate, targetDate, zoomMode);
  const distanceToTargetDateRelation = Math.floor(
    differenceBetweenDatesByUnit(fromDate, targetDate, VIEW_MODE_RELATIONS[zoomMode]),
  );

  if (snapToGridOn) {
    const leftDistance = Math.round(distanceToTargetDate) * slotWidth;

    // add 1px for each relation slot before this item
    const leftDistanceWithBorders = leftDistance + distanceToTargetDateRelation;
    const left = isSnapToEnd(snapToGridOn) ? leftDistanceWithBorders - slotWidth : leftDistanceWithBorders;

    return {
      left,
      width: SNAP_BAR_WIDTH,
      right: left + SNAP_BAR_WIDTH,
    };
  }

  const leftDistance = distanceToTargetDate * slotWidth;

  // add 1px for each relation slot before this item
  const left = leftDistance + distanceToTargetDateRelation;

  const widthDistance = Math.max(differenceBetweenDatesByUnit(item.startDate, item.endDate, zoomMode) * slotWidth, MIN_BAR_WIDTH);

  return {
    left,
    width: Math.max(widthDistance, MIN_BAR_WIDTH),
    right: left + widthDistance,
  };
};

/**
 * Check if item is inside the area delimited by the coordinates.
 *
 * @param {Object} item The item to check if is inside the area
 * @param {Object} coordinates The client rect of the area
 *
 * @return {Boolean} true if item is inside the area
 * */
const isInsideVisibleArea = (item, coordinates) => {
  if (item.info.left > coordinates.scrollLeft + coordinates.clientWidth) {
    return false;
  }

  return item.info.left + item.info.width >= coordinates.scrollLeft;
};

export { generateGroupOffsets, calculateItemPositionAndWidth, isInsideVisibleArea };
