import styled from 'styled-components';

import { spacing } from 'design-system/theme';

const GridWrapper = styled.div`
  padding: ${spacing(2)}px;

  .ag-layout-normal.ag-root {
    margin: 0;
  }
  .ag-layout-normal .ag-paging-panel {
    padding: 0;
  }
  .ag-layout-normal .ag-paging-page-summary-panel {
    margin-right: 0;
  }
  .ag-layout-normal .ag-paging-page-summary-panel .ag-paging-button:last-child {
    margin-right: 0;
  }
`;

export { GridWrapper };
