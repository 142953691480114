import reduceReducers from 'reduce-reducers';

import {
  FETCH_METRIC_ALLOCATION_CHART_DATA,
  FETCH_METRIC_ALLOCATION_CHART_DATA_FULFILLED,
  FETCH_METRIC_ALLOCATION_CHART_DATA_PENDING,
  FETCH_METRIC_ALLOCATION_CHART_DATA_REJECTED,
  UPDATE_METRIC_ALLOCATION_TIME_PERIOD,
} from './types';
import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import { mergeRight, pipe, defaultTo, path } from 'ramda';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  FETCH_METRIC_ALLOCATION_CHART_DATA,
]);

const initialState = {
  ...thunksInitialState,
  allocationByMetricId: {},
};

const getAllocationByMetricId = (metricId, state) => pipe(path(['allocationByMetricId', metricId]), defaultTo({}))(state);

const metricsAllocationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_METRIC_ALLOCATION_CHART_DATA_REJECTED: {
      const { metricId } = action.meta;

      return {
        ...state,
        allocationByMetricId: {
          ...state.allocationByMetricId,
          [metricId]: mergeRight(getAllocationByMetricId(metricId, state), {
            error: action.payload,
            loading: false,
          }),
        },
      };
    }
    case FETCH_METRIC_ALLOCATION_CHART_DATA_FULFILLED: {
      const { metricId } = action.meta;

      return {
        ...state,
        allocationByMetricId: {
          ...state.allocationByMetricId,
          [metricId]: mergeRight(getAllocationByMetricId(metricId, state), {
            data: action.payload,
            error: false,
            loading: false,
          }),
        },
      };
    }
    case FETCH_METRIC_ALLOCATION_CHART_DATA_PENDING: {
      const { metricId } = action.meta;

      return {
        ...state,
        allocationByMetricId: {
          ...state.allocationByMetricId,
          [metricId]: mergeRight(getAllocationByMetricId(metricId, state), {
            error: false,
            loading: true,
          }),
        },
      };
    }
    case UPDATE_METRIC_ALLOCATION_TIME_PERIOD: {
      return {
        ...state,
        metricAllocationTimePeriod: action.payload,
      };
    }
    default:
      return state;
  }
};

const reducer = reduceReducers(initialState, metricsAllocationReducer, ...thunksReducers);

export default reducer;
