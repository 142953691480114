// only transform nested properties of one level deep
export default obj => {
  if (obj instanceof Object) {
    return Object.keys(obj).reduce((final, key) => {
      if (key.includes('.')) {
        const props = key.split('.');

        final[props[0]] = {
          [props[1]]: obj[key],
        };
      } else {
        final[key] = obj[key];
      }
      return final;
    }, {});
  }

  throw new Error('Parameter must be an object');
};
