import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from '@material-ui/core/Input';

import FluidField from 'design-system/molecules/FluidField/index';
import MetadataFluidField from 'design-system/molecules/MetadataFluidField/index';
import SearchableDndListField from 'design-system/molecules/SearchableDndListField/index';

const FluidTreeDropdownFieldWithShowAll = props => {
  const { options = [], isArchived, toggleIsShowingAll, showAllText, handleChange, hasOtherOptions, disablePortal } = props;

  const setValue = (titles = []) => {
    return titles.filter(Boolean).join(' / \n');
  };

  return (
    <MetadataFluidField
      {...props}
      renderer={(value, { placeholder } = {}) => (
        <StyledInput
          type="text"
          value={setValue(value)}
          minRows={props?.value?.filter(Boolean)?.length || 1}
          archivedValue={isArchived}
          multiline
          disableUnderline
          readOnly={props.disableEdit}
          placeholder={placeholder}
        />
      )}
      editorRenderer={({ save, cancel, disableEdit, placeholder }) => (
        <SearchableDndListField
          options={options}
          onSelectOption={selected => handleChange(selected, save)}
          onCloseList={cancel}
          onShowAllClick={hasOtherOptions ? toggleIsShowingAll : null}
          showAllText={showAllText}
          disabled={disableEdit}
          placeholder={placeholder}
          disablePortal={disablePortal}
          focusOnInit
          usePopper
        />
      )}
    />
  );
};

FluidTreeDropdownFieldWithShowAll.propTypes = {
  ...FluidField.propTypes,
  metadata: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

const StyledInput = styled(Input)`
  &&&& {
    ${({ archivedValue, theme }) =>
      archivedValue
        ? `
    color: ${theme.palette.text.lightGrey}`
        : ''}
  }
`;

export default FluidTreeDropdownFieldWithShowAll;
