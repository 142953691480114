import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

import DragonAvatar from 'design-system/atoms/DragonAvatar/index';

import { Avatar, Label } from 'design-system/atoms/DragonAvatar/DragonAvatar';
import getFavoriteIcon from 'utils/userViews/getFavoriteIcon';
import { getUserName, getUserShortName } from 'utils';

export default ({ item, handleFavoriteUserView, handleActionClick, getActionIcon, isFavorite, shareButtonId, small }) => {
  const addFavorite = !isFavorite;

  return (
    <MenuContainer small={small}>
      <MenuItem small={small}>
        <StyledFavoriteIconButton onClick={() => handleFavoriteUserView(item.id, addFavorite)} small={small}>
          {getFavoriteIcon(isFavorite)}
        </StyledFavoriteIconButton>
      </MenuItem>
      <MenuItem small={small}>
        <StyledDragonAvatar short={getUserShortName(item.user)} showBorder={false} tooltipTitle={getUserName(item.user)} />
      </MenuItem>
      <MenuItem>
        <StyledActionIconButton
          id={`${shareButtonId}-${item.id}`}
          onClick={e => handleActionClick(e, item.isOwner)}
          small={small}
        >
          {getActionIcon(item.isOwner)}
        </StyledActionIconButton>
      </MenuItem>
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  min-width: 150px;

  ${({ small }) =>
    small &&
    `
      gap: 5px;
    `}
`;

const MenuItem = styled.div`
  ${({ small, theme }) =>
    small &&
    `
      ${Avatar} {
        width: 24px;
        height: 24px;
        background: ${theme.palette.newLayout.background.mercuryGrey};
      }

      ${Label} {
        font-size: 12px;
        color: ${theme.palette.newLayout.background.dark};
      }
    `}
`;

const StyledDragonAvatar = styled(DragonAvatar)`
  position: relative;
  padding: 0;
  gap: 0;
  margin: 0;
  width: auto;
`;

const StyledFavoriteIconButton = styled(IconButton)`
  ${({ small }) =>
    small &&
    `
      && {
        font-size: 20px;
        padding: 8px;
      }
    `}
`;

const StyledActionIconButton = styled(IconButton)`
  ${({ small }) =>
    small &&
    `
      && {
        padding: 8px;
      }

      svg {
        font-size: 21px;
      }
    `}
`;
