import React, { useCallback, useMemo } from 'react';

import ConfirmDialog from 'components/ConfirmDialog';
import ConfirmPhaseDeleteModal from 'components/ConfirmPhaseDeleteModal';

const BulkDeleteConfirmDialog = ({
  isPhasesSettings,
  settingNamePlural,
  items,
  selectedItems,
  itemToDelete,
  isOpen,
  onCancel,
  bulkDelete,
  bulkUpdateProjects,
  defaultPhase,
}) => {
  const totalImpactedProjects = useMemo(() => {
    return selectedItems.reduce((total, item) => {
      return total + (item.projects?.length || 0);
    }, 0);
  }, [selectedItems]);

  const bulkDeletePhasesAndUpdateAssociatedProjects = useCallback(
    async (newPhaseId = null) => {
      if (bulkUpdateProjects && newPhaseId) {
        const projectsUpdatePayload = selectedItems.flatMap(({ projects = [] }) => {
          return projects.map(p => ({
            id: p.id,
            phase_id: newPhaseId,
          }));
        });

        if (projectsUpdatePayload.length) {
          await bulkUpdateProjects(projectsUpdatePayload);
        }
      }

      bulkDelete();
    },
    [bulkUpdateProjects, bulkDelete],
  );

  return isPhasesSettings && totalImpactedProjects ? (
    <ConfirmPhaseDeleteModal
      defaultPhase={defaultPhase}
      isBulk
      isOpen={isOpen}
      handleCancel={onCancel}
      handleDelete={bulkDeletePhasesAndUpdateAssociatedProjects}
      items={items}
      objectToDelete={itemToDelete}
      selectedItems={selectedItems}
      title={`Delete selected ${settingNamePlural}`}
      text={`There are ${totalImpactedProjects} items associated with these phases.`}
    />
  ) : (
    <ConfirmDialog
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={bulkDelete}
      title={`Delete selected ${settingNamePlural}`}
      text={`Are you sure you want to delete selected ${settingNamePlural}?`}
    />
  );
};

export default BulkDeleteConfirmDialog;
