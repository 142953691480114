import React, { Component } from 'react';
import styled from 'styled-components';
import { defaultTo } from 'ramda';
import { func, bool, arrayOf, shape, number, string } from 'prop-types';
import { toast } from 'react-toastify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import NewDialog from 'design-system/organisms/NewDialog/index';
import DialogActions from 'design-system/molecules/DialogActions/index';

import ConfirmDialog from 'components/ConfirmDialog';
import sortByRank from 'utils/sortByRank';

const itemType = shape({
  id: number.isRequired,
  label: string,
});

export default class MergeLightbox extends Component {
  static propTypes = {
    dialogVisible: bool,
    items: arrayOf(itemType),
    hideDialog: func.isRequired,
    merge: func.isRequired,
    transformLabel: func,
    shouldDisableOption: func,
    disableSelectionMode: func,
    saveButtonLabel: string,
    title: string,
  };

  static defaultProps = {
    items: [],
    dialogVisible: false,
    shouldDisableOption: () => false,
    saveButtonLabel: 'Save',
    title: 'Select the item to keep',
  };

  constructor(props) {
    super(props);

    this.selectPersistItem = this.selectPersistItem.bind(this);
    this.save = this.save.bind(this);
  }

  state = {
    persistItem: '',
  };

  componentWillReceiveProps({ items }) {
    if (items !== this.props.items) {
      this.setState({ persistItem: null });
    }
  }

  selectPersistItem(event) {
    this.setState({
      persistItem: event.target.value,
    });
  }

  save() {
    if (this.props.shouldConfirm && !this.state.showConfirmDialog) {
      this.setState({ showConfirmDialog: true });
      return;
    }

    this.setState({
      pending: true,
    });

    const { merge, items, hideDialog, disableSelectionMode } = this.props;
    const { persistItem } = this.state;

    // extract items ids that will be merged
    const itemsIdsToMerge = items.map(item => item.id).filter(id => id.toString() !== persistItem);

    return merge(itemsIdsToMerge, persistItem, items)
      .then(() => {
        hideDialog();
        this.setState({ pending: false });
        disableSelectionMode();
      })
      .catch(err => {
        this.setState({
          pending: false,
        });
        toast(err);
      });
  }

  render() {
    const { title, saveButtonLabel, customConfirm, hideDialog, dialogVisible, items, transformLabel, shouldDisableOption } =
      this.props;

    const defaltAsEmptyArray = defaultTo([]);

    // by default will sort items by rank
    const sortedItems = defaltAsEmptyArray(items).sort(sortByRank);

    return (
      <NewDialog
        open={dialogVisible}
        title={title}
        closeButton
        onClose={hideDialog}
        actions={
          <DialogActions
            onSave={this.save}
            onCancel={hideDialog}
            saveLabel={saveButtonLabel}
            saveProps={{ disabled: !this.state.persistItem || this.state.pending }}
          />
        }
      >
        <DialogContent>
          <RadioGroup value={this.state.persistItem} onChange={this.selectPersistItem}>
            {sortedItems.map(item => (
              <FormControlLabel
                key={item.id}
                value={item.id.toString()}
                control={<Radio color="primary" />}
                label={transformLabel ? transformLabel(item, items) : item.label}
                disabled={shouldDisableOption(item)}
              />
            ))}
          </RadioGroup>
          {customConfirm}
        </DialogContent>
        <ConfirmDialog
          isOpen={this.state.showConfirmDialog}
          title="Confirm Merge"
          text="Are you sure you want to merge these items?"
          onCancel={() => this.setState({ showConfirmDialog: false })}
          onConfirm={this.save}
        />
      </NewDialog>
    );
  }
}

const DialogContent = styled.div`
  min-width: 350px;
`;
