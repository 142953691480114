export const IDEAS_BOARD_PAGE = 'ideasBoard';
export const IDEAS_GRID_PAGE = 'ideasGrid:ideas';
export const IDEAS_ESTIMATES_PAGE = 'ideasGrid:estimates';
export const IDEAS_PDLC_PAGE = 'ideasGrid:pdlc';
export const IDEAS_OVERVIEW_PAGE = 'ideasGrid:overview';
export const ALLOCATION_REPORT_PAGE = 'allocationReport';
export const PROGRESS_PAGE = 'progress';
export const FORECAST_PAGE = 'forecast';
export const FORECAST_RESOURCES_PAGE = 'forecast:resources';
export const FORECAST_CHART_PAGE = 'forecast:chart';
export const SUMMARY_PAGE = 'summary';
export const GOALS_SUMMARY_PAGE = 'goals:summary';
export const GOALS_GRID_PAGE = 'goals:grid';
export const GOALS_SNAPSHOT_PAGE = 'goals:snapshot';
export const GOALS_ALLOCATION_PAGE = 'goals:allocation';
export const GOALS_CANVAS_PAGE = 'goals:canvas';
export const SNAPSHOT_PAGE = 'snapshot';
export const MANAGE_PAGE = 'manage';
export const STAFFING_PAGE = 'staffing';
export const ROADMAP_PAGE = 'roadmap';
export const GLOBAL_FILTER = 'global';
export const PORTFOLIO_REPORT_PAGE = 'portfolioReport';
export const REQUESTS_FILTERS = 'requests';
export const REQUESTS_DETAIL = 'requestsDetail';
export const REQUESTS_LIST = 'requestsList';
export const REQUESTS_INSIGHTS = 'requestsInsights';
export const REQUESTS_ANALYZE = 'requestsAnalyze';
export const REQUESTS_PERSONAS = 'requestsPersonas';
export const REQUESTS_LIFECYCLES = 'requestsLifecycles';
export const DASHBOARD_HOME_PAGE = 'viewsList';
export const DASHBOARD_EXPLORE_PAGE = 'explore';
export const DASHBOARDS_PAGE = 'dashboards';
export const OBJECTIVES_FILTER = 'objectives';
export const METRICS_FILTER = 'metrics';
export const METRICS_GRID_PAGE = 'metrics:grid';
export const METRICS_CHART_PAGE = 'metrics:chart';
export const METRICS_CANVAS_PAGE = 'metrics:canvas';
export const COMPARE_SCENARIOS_FILTER = 'compareScenarios';
export const COMPARE_SCENARIOS_LIST_PAGE = 'compareScenarios:list';
export const COMPARE_SCENARIOS_SWIMLANE_PAGE = 'compareScenarios:swimlane';
export const COMPARE_SCENARIOS_CHARTS_PAGE = 'compareScenarios:charts';
export const COMPARE_SCENARIOS_SUMMARY_PAGE = 'compareScenarios:summary';
export const SCENARIO_FILTER = 'scenario';
export const SCENARIO_LIST_PAGE = 'scenario:list';
export const SCENARIO_SWIMLANE_PAGE = 'scenario:swimlane';
export const SCENARIO_GANTT_PAGE = 'scenario:gant';
export const SCENARIO_SUMMARY_PAGE = 'scenario:summary';
export const SCENARIO_CHART_PAGE = 'scenario:chart';
export const SCENARIO_AI_PLANNER_PAGE = 'scenario:aiplanner';
export const IDEAS_FLAT_GRID_PAGE = 'ideasGrid:flat';
export const MULTI_LAYER_FILTER = 'multiLayer';
export const PORTFOLIO_CANVAS_PAGE = 'portfolio:canvas';
export const PORTFOLIO_PERSONAS_PAGE = 'portfolio:personas';
export const PORTFOLIO_LIFECYCLES_PAGE = 'portfolio:lifecycles';
export const MISSION_CONTROL_HOME_PAGE = 'missionControlHome';
export const ALLOCATION_REPORT_FILTERS = 'allocationReport';
export const SNAPSHOT_FILTERS = 'snapshot';
export const PROGRESS_FILTERS = 'progress';
export const WELCOME_PAGE = 'welcome';
export const HOME_PAGE = 'home';
export const TEMPLATES_PAGE = 'templates';
export const WELCOME_SELECT_TEMPLATE_PAGE = 'welcome:select-template';
export const USER_DASHBOARDS_PAGE = 'dashboards:user';
export const METRICS_ALLOCATION_PAGE = 'metrics:allocation';

export const ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PAGE = 'compareSnaps:list';
export const ROADMAP_HISTORY_COMPARE_SNAPS_SWIMLANE_PAGE = 'compareSnaps:swimlane';
export const ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PAGE = 'compareSnaps:summary';

// this map is to decide in which scenario page we should land
// depending on the page we come from...
// e.g. Ideas Grid -> click Create scenario -> Scenario List
export const MAP_SCENARIO_PATH_PAGES = {
  // Porfolio module pages
  [IDEAS_GRID_PAGE]: SCENARIO_LIST_PAGE,
  [MANAGE_PAGE]: SCENARIO_GANTT_PAGE,
  [SUMMARY_PAGE]: SCENARIO_SUMMARY_PAGE,
  // Forecast module pages
  [IDEAS_ESTIMATES_PAGE]: SCENARIO_LIST_PAGE,
  [FORECAST_PAGE]: SCENARIO_GANTT_PAGE,
};

export const PAGES_WITH_MOBILE_SUPPORT = [DASHBOARDS_PAGE, IDEAS_OVERVIEW_PAGE];

// Porfolio module paths
const IDEAS_BOARD_PATH = 'ideas/board';

export const IDEAS_GRID_PATH = 'ideas/grid';
export const IDEAS_GRID_FLAT_PATH = 'ideas/grid-flat';

export const IDEAS_PDLC_PATH = 'ideas/pdlc';
const IDEAS_ROADMAP_PATH = 'ideas/roadmap';
const IDEAS_TIMELINE_PATH = 'ideas/timeline';
const IDEAS_SUMMARY_PATH = 'ideas/summary';

export const IDEAS_CANVAS_PATH = 'ideas/canvas';

export const IDEAS_OVERVIEW_PATH = 'ideas/overview';

export const IDEAS_PERSONAS_PATH = 'ideas/personas';
export const IDEAS_LIFECYCLES_PATH = 'ideas/lifecycles';

export const MANAGE_TIMELINE_PATH = 'manage/timeline';
export const MANAGE_RESOURCES_PATH = 'manage/resources';

// Goals module paths
export const GOALS_GRID_PATH = 'goals/grid';
const GOALS_SUMMARY_PATH = 'goals/summary';
const GOALS_SNAPSHOT_PATH = 'goals/snapshot';
const GOALS_CANVAS_PATH = 'goals/canvas';

// Metrics Module paths
export const METRICS_GRID_PATH = 'metrics/grid';
const METRICS_CHART_PATH = 'metrics/chart';
const METRICS_CANVAS_PATH = 'metrics/canvas';

// Forecast module paths
const FORECAST_TIMELINE_PATH = 'forecast/timeline';
const FORECAST_LIST_PATH = 'forecast/list';
const FORECAST_RESOURCES_PATH = 'forecast/resources';
const FORECAST_CHART_PATH = 'forecast/chart';

// requests
export const REQUESTS_LIST_PATH = 'requests/list';
const REQUESTS_DETAIL_PATH = 'requests/detail';
const REQUESTS_INSIGHTS_PATH = 'requests/insights';
const REQUESTS_ANALYZE_PATH = 'requests/analyze';

export const REQUESTS_PERSONAS_PATH = 'requests/personas';
export const REQUESTS_LIFECYCLES_PATH = 'requests/lifecycles';

// Dashboard module paths
const DASHBOARDS_PATH = 'dashboard/dashboards';

export const DASHBOARDS_HOME_PATH = 'dashboard/home';
const DASHBOARD_PROGRESS_PATH = 'dashboard/progress';
const DASHBOARD_SNAPSHOT_PATH = 'dashboard/snapshot';
const DASHBOARD_BOARD_PATH = 'dashboard/board';
const DASHBOARD_LIST_PATH = 'dashboard/list';
const DASHBOARD_GRID_PATH = 'dashboard/grid';
const DASHBOARD_GRID_FLAT_PATH = 'dashboard/grid-flat';
const DASHBOARD_SUMMARY_PATH = 'dashboard/summary';
const DASHBOARD_ALLOCATION_PATH = 'dashboard/allocation-report';
const DASHBOARD_IDEAS_PDLC_PATH = 'dashboard/ideas-pdlc';
const DASHBOARD_ROADMAP_PATH = 'dashboard/roadmap';
const DASHBOARD_TIMELINE_PATH = 'dashboard/timeline';
const DASHBOARD_FORECAST_PATH = 'dashboard/forecast';
const DASHBOARD_FORECAST_TIMELINE_PATH = 'dashboard/forecast-timeline';
const DASHBOARD_GOALS_GRID_PATH = 'dashboard/goals-grid';
const DASHBOARD_GOALS_SUMMARY_PATH = 'dashboard/goals-summary';
const DASHBOARD_GOALS_SNAPSHOT_PATH = 'dashboard/goals-snapshot';
const DASHBOARD_RESOURCES_PATH = 'dashboard/resources';
const DASHBOARD_CANVAS_PATH = 'dashboard/canvas';
const DASHBOARD_OVERVIEW_PATH = 'dashboard/overview';
const DASHBOARD_PERSONAS_PATH = 'dashboard/personas';
const DASHBOARD_LIFECYCLES_PATH = 'dashboard/lifecycles';
const DASHBOARD_GOALS_CANVAS_PATH = 'dashboard/goals-canvas';
const DASHBOARD_METRICS_CANVAS_PATH = 'dashboard/metrics-canvas';
const DASHBOARD_METRICS_GRID_PATH = 'dashboard/metrics-grid';
const DASHBOARD_METRICS_CHART_PATH = 'dashboard/metrics-chart';

// scenario
const SCENARIO_LIST_PATH = 'scenario/list';
const SCENARIO_SWIMLANE_PATH = 'scenario/swimlane';
const SCENARIO_GANTT_PATH = 'scenario/gantt';
const SCENARIO_SUMMARY_PATH = 'scenario/summary';
const SCENARIO_CHART_PATH = 'scenario/chart';
const SCENARIO_AI_PLANNER_PATH = 'scenario/aiplanner';

// compare scenario
export const COMPARE_SCENARIOS_LIST_PATH = 'compare-scenarios/list';
export const COMPARE_SCENARIOS_SWIMLANE_PATH = 'compare-scenarios/swimlane';
export const COMPARE_SCENARIOS_CHARTS_PATH = 'compare-scenarios/charts';
export const COMPARE_SCENARIOS_SUMMARY_PATH = 'compare-scenarios/summary';

// Compare roadmap history
export const ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PATH = 'compare-snaps/list';
export const ROADMAP_HISTORY_COMPARE_SNAPS_SWIMLANE_PATH = 'compare-snaps/swimlane';
export const ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PATH = 'compare-snaps/summary';

/*
  main - main path for pageId
  readOnly - path for pageId in read only mode
  alternatives - alternative paths for pageId (not matched pageId -> path but can be matched path -> pageId)
  pathToPageId - if false, pageId will not be matched to path (path -> pageId will not work)
*/
export const PAGEID_TO_PATH = {
  // ideas
  [IDEAS_BOARD_PAGE]: {
    main: IDEAS_BOARD_PATH,
    readOnly: DASHBOARD_BOARD_PATH,
  },
  [IDEAS_GRID_PAGE]: {
    main: IDEAS_GRID_PATH,
    readOnly: DASHBOARD_GRID_PATH,
  },
  [IDEAS_FLAT_GRID_PAGE]: {
    main: IDEAS_GRID_FLAT_PATH,
    readOnly: DASHBOARD_GRID_FLAT_PATH,
  },
  [IDEAS_PDLC_PAGE]: {
    main: IDEAS_PDLC_PATH,
    readOnly: DASHBOARD_IDEAS_PDLC_PATH,
  },
  [ROADMAP_PAGE]: {
    main: IDEAS_ROADMAP_PATH,
    readOnly: DASHBOARD_ROADMAP_PATH,
  },
  [MANAGE_PAGE]: {
    main: IDEAS_TIMELINE_PATH,
    readOnly: DASHBOARD_TIMELINE_PATH,
    alternatives: [MANAGE_TIMELINE_PATH],
  },
  [SUMMARY_PAGE]: {
    main: IDEAS_SUMMARY_PATH,
    readOnly: DASHBOARD_SUMMARY_PATH,
  },
  [PORTFOLIO_CANVAS_PAGE]: {
    main: IDEAS_CANVAS_PATH,
    readOnly: DASHBOARD_CANVAS_PATH,
  },
  [IDEAS_OVERVIEW_PAGE]: {
    main: IDEAS_OVERVIEW_PATH,
    readOnly: DASHBOARD_OVERVIEW_PATH,
  },
  [PORTFOLIO_PERSONAS_PAGE]: {
    main: IDEAS_PERSONAS_PATH,
    readOnly: DASHBOARD_PERSONAS_PATH,
  },
  [PORTFOLIO_LIFECYCLES_PAGE]: {
    main: IDEAS_LIFECYCLES_PATH,
    readOnly: DASHBOARD_LIFECYCLES_PATH,
  },

  // goals
  [GOALS_GRID_PAGE]: {
    main: GOALS_GRID_PATH,
    readOnly: DASHBOARD_GOALS_GRID_PATH,
  },
  [GOALS_SUMMARY_PAGE]: {
    main: GOALS_SUMMARY_PATH,
    readOnly: DASHBOARD_GOALS_SUMMARY_PATH,
  },
  [GOALS_SNAPSHOT_PAGE]: {
    main: GOALS_SNAPSHOT_PATH,
    readOnly: DASHBOARD_GOALS_SNAPSHOT_PATH,
  },
  [GOALS_CANVAS_PAGE]: {
    main: GOALS_CANVAS_PATH,
    readOnly: DASHBOARD_GOALS_CANVAS_PATH,
  },

  // metrics
  [METRICS_GRID_PAGE]: {
    main: METRICS_GRID_PATH,
    readOnly: DASHBOARD_METRICS_GRID_PATH,
  },
  [METRICS_CHART_PAGE]: {
    main: METRICS_CHART_PATH,
    readOnly: DASHBOARD_METRICS_CHART_PATH,
  },
  [METRICS_CANVAS_PAGE]: {
    main: METRICS_CANVAS_PATH,
    readOnly: DASHBOARD_METRICS_CANVAS_PATH,
  },
  // Forecast
  [FORECAST_PAGE]: {
    main: FORECAST_TIMELINE_PATH,
    readOnly: DASHBOARD_FORECAST_TIMELINE_PATH,
    alternatives: [DASHBOARD_FORECAST_PATH],
  },
  [IDEAS_ESTIMATES_PAGE]: {
    main: FORECAST_LIST_PATH,
    readOnly: DASHBOARD_LIST_PATH,
  },
  [FORECAST_RESOURCES_PAGE]: {
    main: FORECAST_RESOURCES_PATH,
    readOnly: DASHBOARD_RESOURCES_PATH,
    alternatives: [MANAGE_RESOURCES_PATH],
  },
  [FORECAST_CHART_PAGE]: {
    main: FORECAST_CHART_PATH,
  },

  // dashboard
  [DASHBOARD_HOME_PAGE]: {
    main: DASHBOARDS_HOME_PATH,
  },
  [DASHBOARDS_PAGE]: {
    main: DASHBOARDS_PATH,
  },
  [SNAPSHOT_PAGE]: {
    main: DASHBOARD_SNAPSHOT_PATH,
  },
  [PROGRESS_PAGE]: {
    main: DASHBOARD_PROGRESS_PATH,
  },
  [ALLOCATION_REPORT_PAGE]: {
    main: DASHBOARD_ALLOCATION_PATH,
  },

  // requests
  [REQUESTS_LIST]: {
    main: REQUESTS_LIST_PATH,
  },
  [REQUESTS_DETAIL]: {
    main: REQUESTS_DETAIL_PATH,
  },
  [REQUESTS_INSIGHTS]: {
    main: REQUESTS_INSIGHTS_PATH,
  },
  [REQUESTS_ANALYZE]: {
    main: REQUESTS_ANALYZE_PATH,
  },
  [REQUESTS_PERSONAS]: {
    main: REQUESTS_PERSONAS_PATH,
  },
  [REQUESTS_LIFECYCLES]: {
    main: REQUESTS_LIFECYCLES_PATH,
  },

  // scenario
  [SCENARIO_LIST_PAGE]: {
    main: SCENARIO_LIST_PATH,
  },
  [SCENARIO_SWIMLANE_PAGE]: {
    main: SCENARIO_SWIMLANE_PATH,
  },
  [SCENARIO_GANTT_PAGE]: {
    main: SCENARIO_GANTT_PATH,
  },
  [SCENARIO_SUMMARY_PAGE]: {
    main: SCENARIO_SUMMARY_PATH,
  },
  [SCENARIO_CHART_PAGE]: {
    main: SCENARIO_CHART_PATH,
  },
  [SCENARIO_AI_PLANNER_PAGE]: {
    main: SCENARIO_AI_PLANNER_PATH,
  },

  // compare scenario
  [COMPARE_SCENARIOS_LIST_PAGE]: {
    main: COMPARE_SCENARIOS_LIST_PATH,
  },
  [COMPARE_SCENARIOS_SWIMLANE_PAGE]: {
    main: COMPARE_SCENARIOS_SWIMLANE_PATH,
  },
  [COMPARE_SCENARIOS_CHARTS_PAGE]: {
    main: COMPARE_SCENARIOS_CHARTS_PATH,
  },
  [COMPARE_SCENARIOS_SUMMARY_PAGE]: {
    main: COMPARE_SCENARIOS_SUMMARY_PATH,
  },

  [ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PAGE]: {
    main: ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PATH,
  },
  [ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PAGE]: {
    main: ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PATH,
  },
  [ROADMAP_HISTORY_COMPARE_SNAPS_SWIMLANE_PAGE]: {
    main: ROADMAP_HISTORY_COMPARE_SNAPS_SWIMLANE_PATH,
  },
};
