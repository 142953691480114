import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, pipe, not, defaultTo, keys, pluck } from 'ramda';

import { getPageFilters } from 'store/filters/selectors';
import { getOrgHasMetadataRoadmaps } from 'store/organization';
import { RECENTLY_CREATED_META_KEY, TITLE_KEY } from 'routes/Goals/HeaderFilters/constants';
import { FILTERS_FUNCTIONS } from 'design-system/constants';

import makeFilterOKRHierarchyByRoadmap from 'utils/makeFilterOKRHierarchyByRoadmap';
import { makeFilterByDate } from 'utils/filters/dateFilterUtils';
import { hasMatchOnFilterOptionByOperation } from 'utils/filters/optionsFilterUtils';
import { sortByCreatedAt } from 'utils/sortEntities';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

const isNotEmpty = pipe(isEmpty, not);
const defaultToEmptyArray = defaultTo([]);
const mapToIds = pluck('id');

const hasFilterField = (filters, field) => {
  return keys(filters).includes(field);
};

const START_DATE_FILTER_KEY = 'startDate';
const END_DATE_FILTER_KEY = 'endDate';
const OWNER_FILTER_KEY = 'owners';
const STATUS_FILTER_KEY = 'status';
const START_DATE_OKR_FIELD = 'start_date';
const END_DATE_OKR_FIELD = 'end_date';
const OWNER_OKR_FIELD = 'owner_id';
const STATUS_OKR_FIELD = 'status';

const matchIgnoreCase = (value, filterValue) => value.toUpperCase().includes(filterValue.toUpperCase());
const notMatchIgnoreCase = (value, filterValue) => !value.toUpperCase().includes(filterValue.toUpperCase());

const getOkrChildren = okr => {
  return isEmpty(defaultToEmptyArray(okr?.children)) ? defaultToEmptyArray(okr?.keyResults) : defaultToEmptyArray(okr?.children);
};

/**
 * @function makeFilterOkrByOwner
 *
 * Make filter function to filter okrs by owner field
 *
 * @param  {Array} filterValue  - value of the owner filter
 * @param  {String} ownerField  - owner field on okr object
 * @param  {String} operation   - filter operation (in, not in, is empty, ...)
 * @return {Function}
 */
const makeFilterOkrByOwner =
  (filterValue, ownerField, operation, considerChildrenOnFilter = true) =>
  okr => {
    const owners = mapToIds(defaultToEmptyArray(filterValue));
    const value = okr[ownerField];

    const hasMatch = hasMatchOnFilterOptionByOperation(owners, value, operation);

    if (hasMatch) return true;

    const children = getOkrChildren(okr);

    if (isEmpty(children) || !considerChildrenOnFilter) return false;

    const childrenWithAppliedFilter = children.filter(makeFilterOkrByOwner(filterValue, ownerField, operation));

    return isNotEmpty(childrenWithAppliedFilter);
  };

/**
 * @function makeFilterOkrByDate
 *
 * makes filter function to filter okrs by date field
 *
 * @param  {String} filterValue
 * @param  {String} okrFieldKey
 * @param  {Boolean} considerChildrenOnFilter
 * @return {Function}
 */
const makeFilterOkrByDate =
  (filterValue, okrFieldKey, considerChildrenOnFilter = true) =>
  okr => {
    const filterFn = makeFilterByDate(filterValue, okrFieldKey);
    const hasMatch = filterFn(okr);

    if (hasMatch) return true;

    const children = getOkrChildren(okr);

    if (isEmpty(children) || !considerChildrenOnFilter) return false;

    const childrenWithAppliedFilter = children.filter(makeFilterOkrByDate(filterValue, okrFieldKey));

    return isNotEmpty(childrenWithAppliedFilter);
  };

/**
 * @function makeFilterOkrByStatus
 *
 * Defines a filtering function that can be used to filter a list of okr objects
 * based on the value of a specified property in the okr object, and whether
 * or not that value matches the filterValue argument.
 *
 * It also applies the filter recursively to any child objects of the okr object,
 * allowing the filter to be applied to nested okr structures.
 *
 * @param  {Array} filterValue  - value of the owner filter
 * @param  {String} ownerField  - owner field on okr object
 * @param  {String} operation   - filter operation (in, not in, is empty, ...)
 * @param  {Boolean} considerChildrenOnFilter   - if should consider children matches
 * @return {Function}
 */
const makeFilterOkrByStatus =
  (filterValue, statusField, operation, considerChildrenOnFilter = true) =>
  okr => {
    const statusFilter = filterValue;
    const value = okr[statusField];

    const hasMatch = statusFilter?.includes(value);

    if (hasMatch) return true;

    const children = getOkrChildren(okr);

    if (isEmpty(children) || !considerChildrenOnFilter) return false;

    const childrenWithAppliedFilter = children.filter(makeFilterOkrByStatus(filterValue, statusField, operation));

    return isNotEmpty(childrenWithAppliedFilter);
  };

/**
 * Defines a filtering function that can be used to filter a list of okr objects
 * based on the value of a specified property in the okr object, and whether
 * or not that value matches the filterValue argument.
 *
 * @param filterValue
 * @param titleField
 * @param operation
 * @return {(function(*): (boolean|*))|*}
 */
const makeFilterOkrByTitle =
  (filterValue, titleField, operation, considerChildrenOnFilter = true) =>
  okr => {
    const titleFilter = filterValue || '';
    const value = okr[titleField] || '';

    const isInOperator = FILTERS_FUNCTIONS.in.key === operation;

    const hasMatch = isInOperator ? matchIgnoreCase(value, titleFilter) : notMatchIgnoreCase(value, titleFilter);

    if (hasMatch) return true;

    if (!isInOperator) return false;

    const children = getOkrChildren(okr);

    if (isEmpty(children) || !considerChildrenOnFilter) return false;

    const childrenWithAppliedFilter = children.filter(makeFilterOkrByTitle(filterValue, titleField, operation));

    return isNotEmpty(childrenWithAppliedFilter);
  };

/**
 * @function useGoalModeOKRs
 * @param  {Boolean} isGoalMode If it is being rendering in goal mode context/pages
 * @param  {Array} okrs       List of OKRs to be processed
 * @return {Array}          List of preocessed OKRs
 */
const useGoalModeOKRs = (isGoalMode = false, okrs = [], considerChildrenOnFilter = true) => {
  const pageFilters = useSelector(getPageFilters);
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);

  const hasRecentlyCreatedFilter = useMemo(() => {
    return !!pageFilters?.fields?.[RECENTLY_CREATED_META_KEY];
  }, [pageFilters]);

  const filterRoadmaps = useMemo(() => {
    return [...defaultToEmptyArray(pageFilters?.fields?.roadmaps), ...defaultToEmptyArray(pageFilters?.fields?.roadmapsCorp)];
  }, [pageFilters]);

  const filterProducts = useMemo(() => {
    return defaultToEmptyArray(pageFilters?.fields?.products);
  }, [pageFilters]);

  const filterProducts2 = useMemo(() => {
    return defaultToEmptyArray(pageFilters?.fields?.products2);
  }, [pageFilters]);

  const { isDodActive, getDefaultRoadmapForMetadataItem } = useOrganizationsAccessControl();

  const filterByRoadmap = useMemo(
    () =>
      makeFilterOKRHierarchyByRoadmap(
        filterRoadmaps,
        filterProducts,
        filterProducts2,
        {
          isDodActive,
          getDefaultRoadmapForMetadataItem,
        },
        considerChildrenOnFilter,
      ),
    [filterRoadmaps, filterProducts, filterProducts2, considerChildrenOnFilter],
  );

  const handleFilterRecentlyCreatedOkrs = (okrs = []) => {
    if (okrs.length) {
      okrs = okrs.sort(sortByCreatedAt);

      return okrs.length >= 100 ? okrs : okrs.slice(0, 100);
    }

    return okrs;
  };

  const processedOkrs = useMemo(() => {
    let filteredOkrs = okrs;

    if (!isGoalMode) {
      return filteredOkrs;
    }

    if (hasRecentlyCreatedFilter) {
      filteredOkrs = handleFilterRecentlyCreatedOkrs(filteredOkrs);
    }

    if (hasMetadataRoadmaps) {
      // Roadmap Filter
      const hasRoadmapSelected = isNotEmpty(filterRoadmaps) || isNotEmpty(filterProducts) || isNotEmpty(filterProducts2);

      filteredOkrs = hasRoadmapSelected ? filteredOkrs.filter(filterByRoadmap) : filteredOkrs;
    }

    // Title Filter
    filteredOkrs = hasFilterField(pageFilters?.fields, TITLE_KEY)
      ? filteredOkrs.filter(
          makeFilterOkrByTitle(
            pageFilters.fields?.[TITLE_KEY],
            TITLE_KEY,
            pageFilters?.op?.[TITLE_KEY],
            considerChildrenOnFilter,
          ),
        )
      : filteredOkrs;

    // Start Date Filter
    filteredOkrs = hasFilterField(pageFilters?.fields, START_DATE_FILTER_KEY)
      ? filteredOkrs.filter(
          makeFilterOkrByDate(pageFilters.fields?.[START_DATE_FILTER_KEY], START_DATE_OKR_FIELD, considerChildrenOnFilter),
        )
      : filteredOkrs;

    // End Date Filter
    filteredOkrs = hasFilterField(pageFilters?.fields, END_DATE_FILTER_KEY)
      ? filteredOkrs.filter(
          makeFilterOkrByDate(pageFilters.fields?.[END_DATE_FILTER_KEY], END_DATE_OKR_FIELD, considerChildrenOnFilter),
        )
      : filteredOkrs;

    // Owner Filter
    filteredOkrs = hasFilterField(pageFilters?.fields, OWNER_FILTER_KEY)
      ? filteredOkrs.filter(
          makeFilterOkrByOwner(
            pageFilters.fields?.[OWNER_FILTER_KEY],
            OWNER_OKR_FIELD,
            pageFilters?.op?.[OWNER_FILTER_KEY],
            considerChildrenOnFilter,
          ),
        )
      : filteredOkrs;

    // Status Filter
    filteredOkrs = hasFilterField(pageFilters?.fields, STATUS_FILTER_KEY)
      ? filteredOkrs.filter(
          makeFilterOkrByStatus(
            pageFilters.fields?.[STATUS_FILTER_KEY],
            STATUS_OKR_FIELD,
            pageFilters?.op?.[STATUS_FILTER_KEY],
            considerChildrenOnFilter,
          ),
        )
      : filteredOkrs;

    return filteredOkrs;
  }, [okrs, isGoalMode, filterByRoadmap, filterRoadmaps, filterProducts, filterProducts2, pageFilters, considerChildrenOnFilter]);

  return processedOkrs;
};

export default useGoalModeOKRs;
