import React from 'react';
import styled from 'styled-components';

import Link from 'components/Link';

const ZENDESK_LINK = 'https://dragonboat.zendesk.com/hc/en-us/articles/8582736563991';

const CreateWorkItemWarning = props => {
  const { warning } = props;

  const message = `Create ADO completed but some fields did not get updated. Message from ADO: "${warning.errorMessage}".`;
  const contact = `Please contact your admin to update ADO configuration according to the set up guide `;

  return (
    <Wrapper>
      <WarningDetail>{message}</WarningDetail>
      <WarningDetail>
        <span>{contact}</span>
        <Link href={ZENDESK_LINK} target="_blank">
          {ZENDESK_LINK}
        </Link>
      </WarningDetail>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &&&& {
    padding-top: 16px;
  }
`;

const WarningDetail = styled.p`
  &&&& {
    color: red;
    margin: 0 0 8px 0;
  }
`;

export default CreateWorkItemWarning;
