import React from 'react';
import styled from 'styled-components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Switch } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getCorpAccountRoadmap } from 'store/roadmaps/selectors';

export default props => {
  const corpAccountName = useSelector(state => getCorpAccountRoadmap(state)?.title);

  return (
    <FooterSwitchContainer rowHeight={40}>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={props.checked}
            onChange={e => {
              const { checked } = e.target;

              props.onChange(checked);
            }}
          />
        }
        onClick={e => e.stopPropagation()}
        label={`Show all items across ${corpAccountName}`}
      />
    </FooterSwitchContainer>
  );
};

const FooterSwitchContainer = styled.div`
  height: ${({ rowHeight }) => rowHeight}px;

  display: flex;
  align-items: center;

  padding-left: 16px;

  font-size: ${({ theme }) => theme.typography?.fontSize}px;
  color: ${({ theme }) => theme.palette?.text.lightGrey};

  > label {
    flex: 1;
  }
`;
