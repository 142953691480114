import React from 'react';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import CloseIcon from '@material-ui/icons/Close';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import { removeElemProps } from 'design-system/utils';

export default ({ headerTitle, onClose }) => {
  return (
    <Wrapper>
      <ProjectTitle>
        <AlignCenter>
          <Title>{headerTitle}</Title>
        </AlignCenter>
      </ProjectTitle>
      <ProjectTabs>
        <Tabs
          value={1000}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          textColor="primary"
          centered
        >
          <HeaderTab
            component="div"
            disableTouchRipple
            disableRipple
            onClick={onClose}
            value={1000}
            label={
              <TabIcon active={false} title='Close'>
                <CloseIcon />
              </TabIcon>
            }
          />
        </Tabs>
      </ProjectTabs>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex: 0 0 auto;
  margin: 0 0 12px 0;
  display: flex;
  width: 100%;
  align-items: center;
`;

const AlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

const ProjectTitle = styled.div`
  color: #6b6b6b;
  font-size: 16px;

  display: flex;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
`;

const Title = styled.p`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.2;
`;

const ProjectTabs = styled.div`
  flex: auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
`;

const TabIcon = styled(removeElemProps(ButtonIcon, ['active']))`
  &&&&& {
    color: #6b6b6b;

    ${({ active }) => active && 'color: #009dff;'}

    svg {
      fill: #6b6b6b;

      ${({ active }) => active && 'fill: #009dff;'}
    }
  }
`;

const HeaderTab = styled(Tab)`
  &&&& {
    min-height: 0;
    font-weight: 400;
    font-size: 0.875rem;
    min-width: 0;

    > span > span {
      padding: 0 2px;
    }

    svg {
      font-size: 20px;
    }
  }
`;
