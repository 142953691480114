import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

export default withTheme()(styled.div`
  th {
    position: sticky;
    top: 0;
    z-index: 3;
    background: ${({ theme }) => theme.palette.background.alabaster};
    /* max-width: 360px;
    min-width: 150px; */
    border-left: 1px solid ${({ theme }) => theme.palette.border.mercury};
  }
  th:first-child {
    left: 0;
    z-index: 3;
    border-left: 0px;
  }
`);
