import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { materialColors } from 'design-system/themes/default';

const FluidFieldGroup = styled(props => <Grid container direction="column" justify="space-between" {...props} />)`
  &&&& {
    position: relative;
    width: 100%;
    margin: 32px 0;

    > :not(:first-child) {
      margin-bottom: 0;
      height: 28px;
      color: #ccc;
    }

    ${props =>
      props.topBorder &&
      `
      :after{
        position: absolute;
        content: '';
        border-top: 2px solid ${materialColors.lightGray};
        width: 100%;
        transform: translate(0, -16px);
        top: 0;
        left: 0;
      }
    `}
  }
`;

export default FluidFieldGroup;
