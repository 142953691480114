export default text => {
  const clipboardData = text
    .split('\n')
    .map(row => row.split('\t'));


  // copying ag-grid cells generate an extra entry in clipboard
  // this is a hack to remove this extra entry
  if (
    (
      clipboardData &&
      clipboardData[0] &&
      clipboardData[0].length !== 1 &&
      clipboardData[clipboardData.length - 1].length === 1
    ) ||
    (
      clipboardData &&
      clipboardData[0] &&
      clipboardData[0].length === 1 &&
      clipboardData[clipboardData.length - 1].length === 1 &&
      !clipboardData[clipboardData.length - 1][0]
    )
  ) {
    clipboardData.pop();
  }

  return clipboardData;
};
