import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import LinkOffOutlined from '@material-ui/icons/LinkOffOutlined';

import useOrganizations from 'hooks/useOrganizations';
import usePageFilters from 'hooks/filters/usePageFilters';
import useNumberOfTextRowsToDisplayOnGridCell from 'hooks/useNumberOfTextRowsToDisplayOnGridCell';

import { useSystemFields, useIdeasGridColumnsDefinitions } from 'containers/IdeasList/IdeasList/New/hooks';
import { TitleCellInnerRenderer } from 'containers/IdeasList/IdeasList/New/hooks/useIdeasGridColumnsDefinitions/renderers';

import theme from 'design-system/theme';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { isNewRow } from 'design-system/molecules/AgGridReact-New/helpers';
import { ActionsCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';

import { getProjectLayers } from 'store/filters/selectors';

import LinkProjectCellEditor from '../components/LinkProjectCellEditor';

const VISIBLE_COLUMNS = ['roadmapTitle', 'product1Title', 'phaseTitle', 'integration', 'ownerName'];

const useLinkedPortfolioItemsColumns = ({
  openProjectLightbox,
  updateProject,
  linkProjectToEntity,
  unlinkProjectFromEntity,
  currentUser,
  checkUserCanCreateNewMetadata,
}) => {
  const { hasIdeas } = useSelector(getProjectLayers);

  const { getSystemFieldName } = useSystemFields();
  const { displayLayer, portfolioMode } = usePageFilters();
  const { getNumberOfTextRowsToDisplayOnGridCell } = useNumberOfTextRowsToDisplayOnGridCell();

  const {
    integrations,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    filters: { topLayer, availableLayers },
    hasMetadataMultiSelect,
  } = useOrganizations();

  const titleColumnDef = useMemo(
    () => ({
      field: 'title',
      headerName: 'Title',
      suppressMovable: true,
      comparator: stringComparator,
      headerClass: 'first-field',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: params => ({
        innerRenderer: TitleCellInnerRenderer,
        suppressCount: true,
        suppressDoubleClickExpand: true,
        getNumberOfTextRowsToDisplayOnGridCell,
        displayChildrenCount: false,
        openButtonProps: {
          hasOpenButton: true,
          onClick: () => openProjectLightbox(params.data),
        },
      }),
      cellEditorSelector: params => {
        return {
          component: LinkProjectCellEditor,
          params: {
            onSave: linkProjectToEntity,
            topLayer,
            availableLayers,
          },
        };
      },
      rowDrag: false,
      pinned: 'left',
      lockPinned: true,
      lockPosition: 'left',
      initialHide: false,
      editable: isNewRow,
      width: 430,
    }),
    [getNumberOfTextRowsToDisplayOnGridCell, openProjectLightbox, linkProjectToEntity],
  );

  const unlinkColumnDef = useMemo(
    () => ({
      headerName: '',
      field: 'unlink',
      editable: false,
      suppressMovable: true,
      suppressMenu: true,
      filter: false,
      resizable: false,
      cellRenderer: ActionsCellRenderer,
      headerClass: 'centered-header',
      cellRendererParams: {
        actions: [
          {
            icon: LinkOffOutlined,
            color: theme.palette.icons.primary,
            onClick: unlinkProjectFromEntity,
            getVisibility: () => true,
          },
        ],
      },
      width: 38,
      initialHide: false,
    }),
    [unlinkProjectFromEntity],
  );

  const { columnDefs, defaultColDef } = useIdeasGridColumnsDefinitions({
    portfolioMode,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    hasIdeas,
    topLayer,
    displayLayer,
    customUserFields: [],
    currentUser,
    integrations,
    hasMetadataMultiSelect,
    getSystemFieldName,
    openProjectInfo: openProjectLightbox,
    visibleColumnsIds: VISIBLE_COLUMNS,
    checkUserCanCreateNewMetadata,
    updateProjectById: updateProject,
  });

  return useMemo(
    () => ({
      columnDefs: [titleColumnDef, ...columnDefs, unlinkColumnDef],
      defaultColumnDef: {
        ...defaultColDef,
        // Temporary - should be editable
        editable: false,
      },
    }),
    [titleColumnDef, columnDefs, defaultColDef],
  );
};

export default useLinkedPortfolioItemsColumns;
