// TODO remove these consts when organization 'custom_user_project_fields' json changes
import invert from 'lodash/invert';

const USER1 = 'user1';
const USER2 = 'user2';
const USER3 = 'user3';
const USER4 = 'user4';
const USER5 = 'user5';
const USER6 = 'user6';
const USER7 = 'user7';

// TODO remove these 2 helpers when organization 'custom_user_project_fields' json changes
// after deleting, replace all 'getSystemFieldNameFromCustomUserFieldKey[key]' with 'key'
export const customerUserFieldKeys = [USER1, USER2, USER3, USER4, USER5, USER6, USER7];
const mapSystemFieldNameToCustomUserFieldKey = {
  user_1_id: USER1,
  user_2_id: USER2,
  user_3_id: USER3,
  user_4_id: USER4,
  user_5_id: USER5,
  user_6_id: USER6,
  user_7_id: USER7,
};

export const customUserFieldIds = ['user_1_id', 'user_2_id', 'user_3_id', 'user_4_id', 'user_5_id', 'user_6_id', 'user_7_id'];

export const getSystemFieldNameFromCustomUserFieldKey = key => mapSystemFieldNameToCustomUserFieldKey[key];
export const getCustomUserFieldKeyFromSystemFieldName = fieldName => invert(mapSystemFieldNameToCustomUserFieldKey)[fieldName];

export const getActiveCustomUserFields = customUserFields =>
  customUserFields ? Object.keys(customUserFields)?.filter(key => !!customUserFields[key]) : [];
