import { FORECAST_BY_TIME, FORECAST_BY_HEADCOUNT, FORECAST_BY_TIME_CAPACITY } from 'constants/forecast';
import { DAYS_IN_WEEK, DEFAULT_DURATION_ON_CREATE_ESTIMATE, DEFAULT_STAFF_ON_CREATE_ESTIMATE } from 'constants/estimates';

/**
 * @function getEstimateDurationAndStaffBasedOnForecastBy
 *
 * get the duration and staff based on forecast by option
 *
 * @param  {Number} value
 * @param  {String} forecastBy
 * @param  {Object} estimate
 * @return {Object}
 */
const getEstimateDurationAndStaffBasedOnForecastBy = (value, forecastBy = FORECAST_BY_TIME, estimate = {}) => {
  const updateObj = {};

  switch (forecastBy) {
    case FORECAST_BY_TIME:
    case FORECAST_BY_TIME_CAPACITY:
      updateObj.duration = +value * DAYS_IN_WEEK;
      updateObj.numStaff = estimate?.numStaff || DEFAULT_STAFF_ON_CREATE_ESTIMATE;

      break;
    case FORECAST_BY_HEADCOUNT:
      updateObj.duration = estimate?.duration || DEFAULT_DURATION_ON_CREATE_ESTIMATE;
      updateObj.numStaff = +value;

      break;
    default:
      break;
  }

  return updateObj;
};

export default getEstimateDurationAndStaffBasedOnForecastBy;
