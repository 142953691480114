import React from 'react';
import styled from 'styled-components';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import StrategicBoardImg from './images/strategic-board.png';
import AllocateAndPrioritize from './images/allocate-prioritize.png';
import ProductTour from './images/product-tour.png';
import Forecast from './images/forecast.png';
import AutomagicTracking from './images/automagic-tracking.png';
import OrganizeIdeas from './images/organize-ideas.png';

export default ({ getSystemFieldName }) => {
  const features = [
    {
      title: 'Product Tour',
      image: ProductTour,
      id: 'product-tour',
    },
    {
      title: `Organize ${getSystemFieldName('idea', true)}`,
      image: OrganizeIdeas,
      id: 'organize',
    },
    {
      title: 'Create a Strategic Board (Portfolio Kanban)',
      image: StrategicBoardImg,
      id: 'strategic-board',
    },
    {
      title: 'Allocate and Prioritize',
      image: AllocateAndPrioritize,
      id: 'allocate-prioritize',
    },
    {
      title: 'Resource Forecast and Scenario',
      image: Forecast,
      id: 'resource-forecast',
    },
    {
      title: 'Automatic tracking & stakeholder reports',
      image: AutomagicTracking,
      id: 'automatic-tracking',
    },
  ];

  return (
    <Wrapper>
      {features.map(f => (
        <AppFeature key={f.title}>
          <FeatureIcon id={f.id} onClick={() => {}}>
            <img src={f.image} alt={f.title} />
          </FeatureIcon>
          <TextDeprecated breakwords>{f.title}</TextDeprecated>
        </AppFeature>
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px 90px;
`;

const AppFeature = styled.div`
  width: calc(100% / 3);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 0 50px;

  p {
    color: #131c23;
    font-size: 18px;
    max-width: 220px;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
  }
`;

const FeatureIcon = styled.div`
  box-shadow: 1px 1px 7px 2px #e8e8e8;
  border-radius: 8px;
  cursor: pointer;
  margin: 0 6px;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #58ccc6;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 328px;
  }
`;
