import React, { useMemo } from 'react';
import styled from 'styled-components';

import UserViewsList from 'containers/UserViewsList';
import WidgetSegment from 'design-system/organisms/WidgetSegment';
import useUserTeamViews from 'hooks/userViews/useUserTeamViews';
import useUserViewsList from 'hooks/userViews/useUserViewsList';
import { VIEW_ITEM_VARIANTS } from 'constants/userViews';

const VISIBLE_VIEWS_LIMIT = 6;
const WIDGET_CONTENT_MAX_HEIGHT = 285;

export default () => {
  const { teamViews } = useUserTeamViews();
  const { viewsList, ...userViewsListProps } = useUserViewsList(teamViews, false);

  const initialViews = useMemo(() => viewsList.slice(0, VISIBLE_VIEWS_LIMIT), [viewsList]);
  const seeMoreVisible = useMemo(() => viewsList.length > VISIBLE_VIEWS_LIMIT, [viewsList.length]);

  return (
    <StyledWidgetSegment
      title="Views by my team"
      initialContent={<UserViewsList {...userViewsListProps} viewsList={initialViews} variant={VIEW_ITEM_VARIANTS.widgetTile} />}
      expandedContent={<UserViewsList {...userViewsListProps} viewsList={viewsList} variant={VIEW_ITEM_VARIANTS.widgetTile} />}
      contentMaxHeight={WIDGET_CONTENT_MAX_HEIGHT}
      showExpandButton={seeMoreVisible}
    />
  );
};

const StyledWidgetSegment = styled(WidgetSegment)`
  min-height: 340px;
`;
