/* eslint-disable */
import React from 'react';
import { withTheme } from 'styled-components';

export default withTheme(({ fill, height = 14, theme, width = 14, ...props }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.82981 0C2.617 0 0 2.617 0 5.82981C0 9.04262 2.617 11.6596 5.82981 11.6596C7.22693 11.6596 8.51 11.1635 9.51558 10.3399L12.9964 13.8208C13.0501 13.8767 13.1144 13.9214 13.1856 13.9522C13.2568 13.9829 13.3335 13.9992 13.411 14C13.4886 14.0008 13.5655 13.9861 13.6373 13.9567C13.7091 13.9274 13.7744 13.8841 13.8292 13.8292C13.8841 13.7744 13.9274 13.7091 13.9567 13.6373C13.9861 13.5655 14.0008 13.4886 14 13.411C13.9992 13.3335 13.9829 13.2568 13.9522 13.1856C13.9214 13.1144 13.8767 13.0501 13.8208 12.9964L10.3399 9.51558C11.1635 8.51 11.6596 7.22693 11.6596 5.82981C11.6596 2.617 9.04262 0 5.82981 0ZM5.82981 1.16596C8.41249 1.16596 10.4937 3.24713 10.4937 5.82981C10.4937 8.41249 8.41249 10.4937 5.82981 10.4937C3.24713 10.4937 1.16596 8.41249 1.16596 5.82981C1.16596 3.24713 3.24713 1.16596 5.82981 1.16596Z"
        fill={fill || theme.palette.newLayout.background.primary}
      />
    </svg>
  );
});
