import { getThunkActionTypes } from 'utils/store/thunk';

export const CREATE_GRID_LIFECYCLE = 'CREATE_GRID_LIFECYCLE';
export const REMOVE_GRID_UNSAVED_LIFECYCLE = 'REMOVE_GRID_UNSAVED_LIFECYCLE';
export const CHANGE_ROW_HEIGHT_LIFECYCLE_GRID = 'CHANGE_ROW_HEIGHT_LIFECYCLE_GRID';
export const CHANGE_HIDE_ARCHIVED_LIFECYCLE_GRID = 'CHANGE_HIDE_ARCHIVED_LIFECYCLE_GRID';

const actionTypes = {
  ...getThunkActionTypes('UPDATE_GRID_LIFECYCLE'),
  ...getThunkActionTypes('SAVE_GRID_NEW_LIFECYCLE'),
  ...getThunkActionTypes('DELETE_GRID_LIFECYCLE'),
  ...getThunkActionTypes('FETCH_LIFECYCLES_GRID_DATA'),
  ...getThunkActionTypes('CREATE_LIFECYCLE_ROADMAP'),
  ...getThunkActionTypes('DELETE_LIFECYCLE_ROADMAP'),
  ...getThunkActionTypes('BULK_DELETE_LIFECYCLE_ROADMAP'),
  ...getThunkActionTypes('SWITCH_LIFECYCLES_GRID_ROW_ORDER'),
};

export const {
  UPDATE_GRID_LIFECYCLE,
  UPDATE_GRID_LIFECYCLE_FULFILLED,
  UPDATE_GRID_LIFECYCLE_PENDING,
  FETCH_LIFECYCLES_GRID_DATA,
  FETCH_LIFECYCLES_GRID_DATA_FULFILLED,
  SAVE_GRID_NEW_LIFECYCLE,
  SAVE_GRID_NEW_LIFECYCLE_FULFILLED,
  DELETE_GRID_LIFECYCLE,
  DELETE_GRID_LIFECYCLE_FULFILLED,
  CREATE_LIFECYCLE_ROADMAP,
  CREATE_LIFECYCLE_ROADMAP_FULFILLED,
  DELETE_LIFECYCLE_ROADMAP,
  DELETE_LIFECYCLE_ROADMAP_FULFILLED,
  BULK_DELETE_LIFECYCLE_ROADMAP,
  BULK_DELETE_LIFECYCLE_ROADMAP_FULFILLED,
  SWITCH_LIFECYCLES_GRID_ROW_ORDER,
  SWITCH_LIFECYCLES_GRID_ROW_ORDER_PENDING,
  SWITCH_LIFECYCLES_GRID_ROW_ORDER_REJECTED,
  SWITCH_LIFECYCLES_GRID_ROW_ORDER_FULFILLED,
} = actionTypes;
