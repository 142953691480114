/* eslint-disable max-len */
import React, { createContext, useContext } from 'react';

import useMetricLightbox from 'hooks/lightboxes/useMetricLightbox';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import MetricsDialog from 'features/MetricsDialog';

import MetricLevelsLightbox from 'features/MetricLevels/components/MetricLevelsLightbox';
import useMetricLevelsLightbox from 'features/MetricLevels/components/MetricLevelsLightbox/useMetricLevelsLightbox';
import GenerateAiReleaseNotesDialog from 'features/AIReleaseNotes/components/GenerateAiReleaseNotesDialog';
import useGenerateAiReleaseNotesDialog from 'features/AIReleaseNotes/components/GenerateAiReleaseNotesDialog/hooks/useGenerateAiReleaseNotesDialog';
import AIAnswerBotDialog from 'features/AIAnswerBot/components/AIAnswerBotDialog';
import useAIAnswerBotDialog from 'features/AIAnswerBot/components/AIAnswerBotDialog/hooks/useAIAnswerBotDialog';

const LightboxesControlContext = createContext({});

const LightboxesControlProvider = ({ children }) => {
  const { canView } = usePermissions();

  const canViewMetricLevels = canView(PERMISSION_FEATURES.metricLevels);

  const canViewAIAnswerBot = canView(PERMISSION_FEATURES.aiAnswerBot);

  const { openMetricLightbox, createMetricOnLightbox, isMetricsDialogVisible, metricDialogProps = {} } = useMetricLightbox();

  const { openMetricLevelsLightbox, ...metricLevelsLightboxProps } = useMetricLevelsLightbox();

  const { openDialog: openAIAnswerBotDialog, ...aiAnswerBotProps } = useAIAnswerBotDialog();

  const {
    openDialog: openReleaseNotesDialog,
    hasReleaseNotesFeature,
    ...releaseNotesDialogProps
  } = useGenerateAiReleaseNotesDialog();

  return (
    <LightboxesControlContext.Provider
      value={{
        openMetricLightbox,
        createMetricOnLightbox,
        isMetricsDialogVisible,
        openMetricLevelsLightbox,
        openReleaseNotesDialog,
        openAIAnswerBotDialog,
      }}
    >
      {children}
      <MetricsDialog {...metricDialogProps} />
      {canViewMetricLevels ? <MetricLevelsLightbox {...metricLevelsLightboxProps} /> : null}
      {hasReleaseNotesFeature ? <GenerateAiReleaseNotesDialog {...releaseNotesDialogProps} /> : null}
      {canViewAIAnswerBot ? <AIAnswerBotDialog {...aiAnswerBotProps} /> : null}
    </LightboxesControlContext.Provider>
  );
};

/**
 * @function useLightboxesControlContext
 *
 * hook to use the multiple lightbox context
 * needs to be wrapped on MetricsLightboxProvider
 *
 * @return {Object}
 */
const useLightboxesControlContext = () => useContext(LightboxesControlContext);

export { LightboxesControlProvider };

export default useLightboxesControlContext;
