import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import { materialColors } from 'design-system/themes/default';

export default styled(Button)`
  &&&& {
    margin-left: -8px;
    color: ${materialColors.darkerGray};
  }
`;
