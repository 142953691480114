import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import { materialColors } from 'design-system/themes/default';

export default function OutlinedPurpleButton({ buttonText = '', extraStyles, id, onClick }) {
  return (
    <PurpleOutlinedButton id={id} variant="outlined" color="secondary" onClick={onClick} $extraStyles={extraStyles}>
      {buttonText}
    </PurpleOutlinedButton>
  );
}

const PurpleOutlinedButton = styled(Button)`
  &&&& {
    color: ${materialColors.mediumPurple};
    border-color: ${materialColors.mediumPurple};

    :hover {
      background-color: ${materialColors.mediumPurple + 14};
      border-color: ${materialColors.mediumPurple};
    }
    
    ${({ $extraStyles }) => $extraStyles && $extraStyles}
  }

`;
