import compact from 'lodash/compact';

import { prop, reduce, keys, defaultTo, assoc, pipe, uniqBy } from 'ramda';

const defaultToEmptyArray = defaultTo([]);

const getCompactUniqById = pipe(defaultToEmptyArray, uniqBy(prop('id')), compact);

const UNDEFINED_GROUP = { id: null };

const _addUndefinedGroup = groupingObjects => {
  const isDatesGroup = groupingObjects.some(obj => obj.start && obj.end);
  const undefinedGroup = { ...UNDEFINED_GROUP, isDatesGroup };

  return [...groupingObjects, undefinedGroup];
};

/**
 * @function addUndefinedObjectsToUniqueGroupingCollections
 *
 * Adds the Undefined group to all collections and enforces uniqBy ID
 *
 * @param  {Object} groupingObjects - collection of grouping objects grouped by key
 */
const addUndefinedObjectsToUniqueGroupingCollections = groupingObjects => {
  return reduce(
    (acc, key) => assoc(key, _addUndefinedGroup(getCompactUniqById(groupingObjects[key])), acc),
    {},
    keys(groupingObjects),
  );
};

/**
 * @function getGroupingObjects
 *
 * Returns an Object with arrays of metadata objects grouped by entity type (roadmaps, objectives, etc.)
 *
 * @param  {Object} options
 * @param  {Object} options.defaultGroupingObjects - default grouping objects collected from store
 * @param  {Array} options.groupedDates - date groups collected from projects' timelines
 * @returns {Object}
 */
const getGroupingObjects = ({ defaultGroupingObjects, groupedDates }) => {
  const result = {
    ...defaultGroupingObjects,
    ...(groupedDates ? { dates: Object.keys(groupedDates).map(key => groupedDates[key]) } : {}),
  };

  return addUndefinedObjectsToUniqueGroupingCollections(result);
};

export { getGroupingObjects };
