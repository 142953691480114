import styled from 'styled-components';

import { spacing } from 'design-system/theme';
import DragonSelectButton from 'design-system/atoms/DragonSelectButton/DragonSelectButton';

const PortfolioTreeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Link = styled.div`
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.fontSizeMedium}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  ${({ isLink, theme }) => isLink && `color: ${theme.palette.primary.main};`}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  max-width: 200px;
`;

const Separator = styled.span`
  margin: 0 ${spacing()}px;
`;

const Selector = styled(DragonSelectButton)`
  &&&& {
    width: auto;
  }
`;

export { PortfolioTreeContainer, Link, Separator, Selector };
