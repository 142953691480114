import axios from 'axios';
import { defaultTo, isEmpty, isNil, path } from 'ramda';

import { createThunk } from 'utils/store/thunk';

import {
  FETCH_OUTCOME_ALLOCATION_DATA,
  FETCH_OUTCOME_SNAPSHOT_DATA,
  PAGE_LOAD_OUTCOME_MODULE_GOALS,
  UPDATE_OUTCOME_MODULE_FILTERS,
} from './types';
import { getCurrentUser } from 'store/login/selectors';
import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/pages';
import { getDefaultUserViewForPage } from 'store/userViews/selectors';
import { OBJECTIVES_FILTER } from 'constants/filters';
import { requestUserFilterCreate, requestUserFilterUpdate } from 'store/filters/network';
import { requestFetchOKRs } from './network';
import { apiUpdateView } from 'store/userViews/network';
import { selectOutcomeModuleFilter } from 'store/filters/selectors';

const fetchOutcomeSnapshotDataAction = requestBody => {
  const request = axios.post('/api/v1/reports/outcome/snapshot', requestBody);

  return createThunk(FETCH_OUTCOME_SNAPSHOT_DATA, request);
};

const fetchOutcomeAllocationDataAction = requestBody => {
  const request = axios.post(`/api/projects/allocation-report`, requestBody);

  return createThunk(FETCH_OUTCOME_ALLOCATION_DATA, request);
};

const updateOutcomeModuleFilters = newFilters => (dispatch, getState) => {
  const state = getState();
  const currentUser = getCurrentUser(state);
  const path = getCurrentPath();
  const pageId = getPageIdFromPath(path);
  const defaultView = getDefaultUserViewForPage(state, pageId);

  const userFilters = defaultTo([], state._filters?.userFilters);

  const isGoalsFilter = filter => !!filter.default_filter && filter.page === OBJECTIVES_FILTER;
  const currentDefaultUserFilter = userFilters.find(isGoalsFilter);

  return dispatch(
    createThunk(
      UPDATE_OUTCOME_MODULE_FILTERS,
      async () => {
        const userFilterUpdateRequest = currentDefaultUserFilter
          ? requestUserFilterUpdate(currentDefaultUserFilter.id, { ...currentDefaultUserFilter, state: newFilters })
          : requestUserFilterCreate(OBJECTIVES_FILTER, newFilters, currentUser);

        const requests = [requestFetchOKRs(newFilters), userFilterUpdateRequest];

        if (defaultView) {
          const newViewState = { ...defaultTo({}, defaultView.state), filter: newFilters };

          requests.push(apiUpdateView(defaultView.id, { ...defaultView, state: newViewState }));
        }

        const [fetchOkrsResult, userFilter, updatedUserView] = await Promise.all(requests);

        return {
          filters: newFilters,
          fetchOkrsResult,
          userFilter,
          updatedUserView,
        };
      },
      {},
    ),
  );
};

const applySavedFilterOnOutcomeModule = filterId => (dispatch, getState) => {
  const state = getState();
  const currentUser = getCurrentUser(state);
  const path = getCurrentPath();
  const pageId = getPageIdFromPath(path);
  const defaultView = getDefaultUserViewForPage(state, pageId);
  const userFilters = defaultTo([], state._filters?.userFilters);

  const isGoalsFilter = filter => !!filter.default_filter && filter.page === OBJECTIVES_FILTER;
  const currentDefaultUserFilter = userFilters.find(isGoalsFilter);
  const userFilterToApply = userFilters.find(userFilter => userFilter.id === filterId);

  const newFilters = defaultTo({}, userFilterToApply.state);

  return dispatch(
    createThunk(
      UPDATE_OUTCOME_MODULE_FILTERS,
      async () => {
        const userFilterUpdateRequest = currentDefaultUserFilter
          ? requestUserFilterUpdate(currentDefaultUserFilter.id, { ...currentDefaultUserFilter, state: newFilters })
          : requestUserFilterCreate(OBJECTIVES_FILTER, newFilters, currentUser);

        const requests = [userFilterUpdateRequest, requestFetchOKRs(newFilters)];

        if (defaultView) {
          const newViewState = { ...defaultTo({}, defaultView.state), filter: newFilters };

          requests.push(apiUpdateView(defaultView.id, { ...defaultView, state: newViewState }));
        }

        const [fetchOkrsResult, userFilter, userViewUpdate] = await Promise.all(requests);

        return {
          fetchOkrsResult,
          userFilter,
          userViewUpdate,
        };
      },
      {},
    ),
  );
};

const loadOKRsOnOutcomeModulePageLoad = () => (dispatch, getState) => {
  const state = getState();
  const outcomeFilters = selectOutcomeModuleFilter(state);

  return dispatch(createThunk(PAGE_LOAD_OUTCOME_MODULE_GOALS, () => requestFetchOKRs(outcomeFilters), {}));
};

const loadOutcomeGoalsFromUserViewApplied = appliedView => dispatch => {
  const viewFilter = path(['state', 'filter'], appliedView);

  if (isNil(viewFilter) || isEmpty(viewFilter)) {
    return;
  }

  return dispatch(createThunk(PAGE_LOAD_OUTCOME_MODULE_GOALS, () => requestFetchOKRs(viewFilter), {}));
};

export {
  fetchOutcomeAllocationDataAction,
  fetchOutcomeSnapshotDataAction,
  updateOutcomeModuleFilters,
  applySavedFilterOnOutcomeModule,
  loadOKRsOnOutcomeModulePageLoad,
  loadOutcomeGoalsFromUserViewApplied,
};
