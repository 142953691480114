const SLOT_WIDTH = 25;
const DEFAULT_SIDEBAR_WIDTH = 250;

const VIEW_MODE_RELATIONS = {
  day: 'month',
  week: 'month',
  month: 'year',
  quarter: 'year',
};

const VIEW_MODE_PARENT_FORMATS = {
  day: 'DD',
  week: 'DD',
  month: 'MMMM, YYYY',
  quarter: 'Q, YYYY',
  year: 'YYYY',
};

const VIEW_MODE_CHILD_FORMATS = {
  day: 'DD',
  week: 'DD',
  month: 'MM',
  quarter: 'Q',
  year: 'YYYY',
};

const DEFAULT_VIEW_MODE = {
  id: 'week',
  title: 'Weekly',
};

const DEFAULT_VIEWPORTS = {
  day: 3,
  week: 20,
  month: 15,
  quarter: 30,
};

const ROW_HEIGHT = 35;

const ZOOM_OPTIONS = [
  { id: 'day', title: 'Daily' },
  { id: 'week', title: 'Weekly' },
  { id: 'month', title: 'Monthly' },
  { id: 'quarter', title: 'Quarter' },
];

const DEFAULT_ZOOM_OPTION = ZOOM_OPTIONS.find(g => g.id === 'week');

const MAX_HEIGHT = 70;
const BASE_Z_INDEX = 100;
const LEFT_DIRECTION = 'left';
const MIN_DELTA_TO_SNAP = 0.5;
const DAY = 'day';
const KEY_SEPARATOR = '*';
const SCROLL_LEFT_TIMEOUT = 100;

export {
  VIEW_MODE_RELATIONS,
  VIEW_MODE_PARENT_FORMATS,
  VIEW_MODE_CHILD_FORMATS,
  DEFAULT_VIEW_MODE,
  DEFAULT_SIDEBAR_WIDTH,
  ROW_HEIGHT,
  ZOOM_OPTIONS,
  DEFAULT_ZOOM_OPTION,
  SLOT_WIDTH,
  DEFAULT_VIEWPORTS,
  MAX_HEIGHT,
  BASE_Z_INDEX,
  LEFT_DIRECTION,
  MIN_DELTA_TO_SNAP,
  DAY,
  KEY_SEPARATOR,
  SCROLL_LEFT_TIMEOUT,
};
