import { authenticateOnJira } from 'store/integrations/actions';
import promiseTimeout from 'utils/promiseTimeout';

const POPUP_WINDOW_TIMEOUT = 30000;

export default async (dispatch, action, storeKey, integrationId, meta = {}) => {
  let payload;

  try {
    if (storeKey) {
      dispatch({
        type: `${storeKey}_PENDING`,
        meta,
      });
    }

    payload = await action();
  } catch (err) {
    if (err.response && err.response.data.error_code === 'INVALID_JIRA_TOKEN_ERROR') {
      try {
        await promiseTimeout(POPUP_WINDOW_TIMEOUT, authenticateOnJira(integrationId));
      } catch (err) {
        let action = 'JIRA_AUTH_FAILED';

        if (storeKey === 'TEST_JIRA_CONNECTION') {
          action = 'JIRA_AUTH_CONFIG_FAILED';
        }
        dispatch({
          type: action,
          payload: err,
          error: true,
        });

        return { error: err };
      }

      try {
        payload = await action();
      } catch (err) {
        if (storeKey) {
          dispatch({
            type: `${storeKey}_FAILED`,
            payload: err,
            meta,
            error: true,
          });

          // Just to be caught by the operations reducers
          dispatch({
            type: `${storeKey}_REJECTED`,
            payload: err,
            meta,
          });

          return { error: err };
        }

        throw err;
      }
    } else if (storeKey) {
      dispatch({
        type: `${storeKey}_FAILED`,
        payload: err,
        meta,
        error: true,
      });

      // Just to be caught by the operations reducers
      dispatch({
        type: `${storeKey}_REJECTED`,
        payload: err,
        meta,
      });

      return { error: err };
    } else {
      throw err;
    }
  }

  if (storeKey) {
    dispatch({
      type: `${storeKey}_FULFILLED`,
      payload,
      meta,
    });
  }

  return payload;
};
