import React from 'react';
import { toast } from 'react-toastify';
import { defaultTo, path, pipe, equals, not } from 'ramda';

import Error from '../middlewares/errorHandler/Error';

const getErrorData = pipe(path(['response', 'data', '_data']), defaultTo({}));
const isErrorCodeDoDPermission = pipe(path(['response', 'data', 'error_code']), equals('DOD_PERMISSION_ERROR'));
const isErrorNotDisplayedYet = pipe(path(['response', 'data', 'errorAlreadyDisplayed']), equals(true), not);

/**
 * @function showDoDPermissionErrorToast
 *
 * This code is required because some of the store slices are not using redux middlewares.
 *    - src/store/middlewares/errorHandler/errorHandler.js
 *
 * In order to avoid refactor the actions + reducers the error condition can be duplicated here.
 *
 * @param {Object} data
 */
const showDoDPermissionErrorToast = data => {
  const { portfolioName, entityBelongsToCorpAccount } = getErrorData(data);

  /**
   * This flag will avoid duplicated toasts when the store is using both error handler
   * middleware and throwRequestError
   */
  data.response.data.errorAlreadyDisplayed = true;
  const portfolioNameLabel = entityBelongsToCorpAccount ? 'parent account' : portfolioName;

  toast.error(
    <Error>
      <span>{`You don't have permission to update data from ${portfolioNameLabel}`}</span>
    </Error>,
    {
      toastId: data.error_code,
      autoClose: 5000,
    },
  );
};

export const DEFAULT_REQUEST_FAILED_ERROR_MESSAGE = 'Request failed';

/**
 * @function showDefaultErrorToast
 *
 * Default error toast.
 *
 * @param {Object} data
 */
const showDefaultErrorToast = data => {
  const { data: errorData } = data?.response;

  toast(errorData?.error_code || DEFAULT_REQUEST_FAILED_ERROR_MESSAGE);
};

export default function throwRequestError(data, raiseToast = false) {
  switch (true) {
    case isErrorCodeDoDPermission(data) && isErrorNotDisplayedYet(data): {
      showDoDPermissionErrorToast(data);
      break;
    }
    case raiseToast: {
      showDefaultErrorToast(data);
      break;
    }
    default:
  }

  throw data;
}
