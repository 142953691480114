import { useSelector } from 'react-redux';
import { prop } from 'ramda';

import { getOrganizationMetadataNotes } from 'store/organization/selectors';

/**
 * @function useMetadataTab - Hook that manage metadata tab data or actions
 * @returns {Object} - Returns an object with a method to get metadata notes
 */
const useMetadataTab = () => {
  const metadataNotes = useSelector(getOrganizationMetadataNotes);
  
  const getMetadataNote = (metadataKey) => {
      if (!metadataKey) return null;
  
      const metadataNote = metadataNotes.find(meta => meta.key === metadataKey);
  
       const note = metadataNote && prop('note', metadataNote);
  
       if (!note) return null;
  
       return note;
  };
  
return { getMetadataNote };
};

export default useMetadataTab;