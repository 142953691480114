import axios from 'axios';
import throwRequestError from '../utils/throwRequestError';
import {
  MERGE_THEMES,
  DELETE_THEME,
  CREATE_THEMES,
  UPDATE_THEMES,
  UNDO_CREATE_THEMES,
  UNDO_UPDATE_THEMES,
  BULK_DELETE_THEMES,
  UNDO_BULK_DELETE_THEMES,
  SWITCH_THEMES_ROW_ORDER,
} from './types';
import bulkCreateAction from 'store/utils/factory/bulkCreateAction';
import bulkUpdateAction from 'store/utils/factory/bulkUpdateAction';
import bulkDeleteAction from 'store/utils/factory/bulkDeleteAction';
import moveRowToPosition from 'utils/moveRowToPosition';

export function switchThemesRowOrder(id1, id2, update, position) {
  return (dispatch, getState) => {
    const state = getState().themes.themes;
    const prevData = state.find(({ id }) => id === +id1);
    const movedRow = moveRowToPosition(state, id1, id2, position);

    const payload = axios.put(`/api/themes/rowOrder/${id1}/${id2}`, { position }).then(res => res.data);

    dispatch({
      type: SWITCH_THEMES_ROW_ORDER,
      payload,
      meta: { movedRow, prev: prevData, update },
    });

    return payload;
  };
}

export function mergeThemes(themesIdsToMerge, themeId) {
  return dispatch => {
    const payload = axios
      .post(`/api/themes/merge/${themeId}`, {
        themesIdsToMerge,
      })
      .then(response => {
        return response.data;
      })
      .catch(throwRequestError);

    dispatch({
      type: MERGE_THEMES,
      payload,
    });

    return payload;
  };
}

export const deleteTheme = themeId => {
  return dispatch => {
    const payload = axios
      .delete(`/api/themes/${themeId}`)
      .then(res => res.data)
      .catch(throwRequestError);

    dispatch({
      payload,
      type: DELETE_THEME,
    });

    return payload;
  };
};

export const createThemes = bulkCreateAction(CREATE_THEMES, '/api/themes', {
  toastText: 'Themes have been created',
  ACTION_TYPE: UNDO_CREATE_THEMES,
  endpoint: '/api/themes/versions/last',
  store: 'themes',
});
export const updateThemes = bulkUpdateAction(UPDATE_THEMES, '/api/themes', {
  toastText: 'Themes have been changed',
  ACTION_TYPE: UNDO_UPDATE_THEMES,
  endpoint: '/api/themes/versions/last',
  store: 'themes',
});
export const bulkDeleteThemes = bulkDeleteAction(BULK_DELETE_THEMES, '/api/themes/', {
  toastText: 'Themes have been deleted',
  ACTION_TYPE: UNDO_BULK_DELETE_THEMES,
  endpoint: '/api/themes/versions/last',
  store: 'themes',
});
