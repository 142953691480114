import { FILTERS_FUNCTIONS } from 'design-system/constants';

import { GOAL_MODE, METRICS_MODE, PORTFOLIO_MODE } from '../common';
import {
  IDEAS_BOARD_PAGE,
  IDEAS_GRID_PAGE,
  IDEAS_ESTIMATES_PAGE,
  IDEAS_PDLC_PAGE,
  ALLOCATION_REPORT_PAGE,
  PROGRESS_PAGE,
  FORECAST_PAGE,
  SUMMARY_PAGE,
  GOALS_SUMMARY_PAGE,
  GOALS_GRID_PAGE,
  GOALS_SNAPSHOT_PAGE,
  GOALS_ALLOCATION_PAGE,
  SNAPSHOT_PAGE,
  MANAGE_PAGE,
  STAFFING_PAGE,
  ROADMAP_PAGE,
  GLOBAL_FILTER,
  MULTI_LAYER_FILTER,
  PORTFOLIO_REPORT_PAGE,
  REQUESTS_FILTERS,
  REQUESTS_DETAIL,
  REQUESTS_LIST,
  REQUESTS_INSIGHTS,
  DASHBOARD_HOME_PAGE,
  DASHBOARDS_PAGE,
  OBJECTIVES_FILTER,
  METRICS_FILTER,
  METRICS_GRID_PAGE,
  METRICS_CHART_PAGE,
  COMPARE_SCENARIOS_LIST_PAGE,
  COMPARE_SCENARIOS_SWIMLANE_PAGE,
  COMPARE_SCENARIOS_CHARTS_PAGE,
  COMPARE_SCENARIOS_FILTER,
  COMPARE_SCENARIOS_SUMMARY_PAGE,
  SCENARIO_FILTER,
  SCENARIO_LIST_PAGE,
  SCENARIO_SWIMLANE_PAGE,
  SCENARIO_GANTT_PAGE,
  SCENARIO_SUMMARY_PAGE,
  SCENARIO_AI_PLANNER_PAGE,
  IDEAS_FLAT_GRID_PAGE,
  PORTFOLIO_CANVAS_PAGE,
  SCENARIO_CHART_PAGE,
  FORECAST_CHART_PAGE,
  IDEAS_OVERVIEW_PAGE,
  PORTFOLIO_LIFECYCLES_PAGE,
  PORTFOLIO_PERSONAS_PAGE,
  REQUESTS_PERSONAS,
  REQUESTS_LIFECYCLES,
  MISSION_CONTROL_HOME_PAGE,
  GOALS_CANVAS_PAGE,
  METRICS_CANVAS_PAGE,
  FORECAST_RESOURCES_PAGE,
  HOME_PAGE,
  WELCOME_PAGE,
  REQUESTS_ANALYZE,
  METRICS_ALLOCATION_PAGE,
  TEMPLATES_PAGE,
  USER_DASHBOARDS_PAGE,
  WELCOME_SELECT_TEMPLATE_PAGE,
  DASHBOARD_EXPLORE_PAGE,
} from './pages';

export const PLANNING_STAGE_FILTERS = {
  [IDEAS_BOARD_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [IDEAS_GRID_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [IDEAS_ESTIMATES_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [IDEAS_PDLC_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [ALLOCATION_REPORT_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [PROGRESS_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [FORECAST_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [SUMMARY_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [SNAPSHOT_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [GOALS_SNAPSHOT_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed', 'Completed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [GOALS_ALLOCATION_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed', 'Completed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [PORTFOLIO_REPORT_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [MANAGE_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [STAFFING_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [ROADMAP_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [DASHBOARDS_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [IDEAS_FLAT_GRID_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [GLOBAL_FILTER]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [COMPARE_SCENARIOS_FILTER]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [SCENARIO_FILTER]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
  [PORTFOLIO_CANVAS_PAGE]: {
    default: ['Backlog', 'Planning', 'Confirmed'],
    available: ['Backlog', 'Planning', 'Confirmed', 'Archived', 'Completed'],
  },
};

export const DEFAULT_PLANNING_STAGES = page => (PLANNING_STAGE_FILTERS[page] ? PLANNING_STAGE_FILTERS[page].default : []);
export const AVAILABLE_PLANNING_STAGES = page => (PLANNING_STAGE_FILTERS[page] ? PLANNING_STAGE_FILTERS[page].available : []);

export const DEFAULT_PAGE_FILTERS = {
  [GLOBAL_FILTER]: {
    planningStages: DEFAULT_PLANNING_STAGES(GLOBAL_FILTER),
  },
  [MULTI_LAYER_FILTER]: {},
  [METRICS_FILTER]: {
    status: ['Active'],
  },
  [OBJECTIVES_FILTER]: {
    status: ['Active'],
  },
  [COMPARE_SCENARIOS_FILTER]: {
    planningStages: DEFAULT_PLANNING_STAGES(COMPARE_SCENARIOS_FILTER),
  },
  [PROGRESS_PAGE]: {
    planningStages: DEFAULT_PLANNING_STAGES(PROGRESS_PAGE),
  },
};

export const DEFAULT_VISIBLE_FILTERS = [
  'objectives',
  'keyResults',
  'roadmaps',
  'products',
  'themes',
  'timeframes',
  'planningStages',
  'phases',
  'categories',
  'tags',
  'customers',
  'users',
  'teams',
  'skills',
  'priorities',
];

export const MAP_FILTERS_WITH_PROJECT = {
  timeframes: 'timeframe_id',
  timeframes2: 'timeframe_2_id',
  roadmapsCorp: 'roadmap_corp_id',
  roadmaps: 'roadmap_id',
  objectivesCorp: 'objective_corp_id',
  objectives: 'objective_id',
  themes: 'theme_id',
  categories: 'category_id',
  phases: 'phase_id',
  products: 'product_1_id',
  products2: 'product_2_id',
  keyResults: 'key_result_1_id',
  keyResults1: 'key_result_1_id',
  keyResults2: 'key_result_2_id',
  users: 'owner_id',
  planningStages: 'planningStage',
  tags: 'tags',
  customers: 'customers',
  teams: 'teams',
  teams2: 'teams2',
  skills: 'skills',
  priorities: 'priority_id',
  created_by: 'created_by_id',
  status_colors: 'status_color',
  metrics: 'metrics',
  personas: 'personas',
  lifecycles: 'lifecycles',
};

export const INGORE_FILTERS_ON_PAGE = {};

export const CURRENT_USER_ID_AS_ME = 'me';

export const QUICK_FILTER_OPTIONS = getSystemFieldName => [
  { id: 'roadmapsCorp', label: getSystemFieldName('roadmapCorp') },
  { id: 'roadmaps', label: getSystemFieldName('roadmap') },
  { id: 'products', label: getSystemFieldName('product1') },
  { id: 'objectivesCorp', label: getSystemFieldName('objectiveCorp') },
  { id: 'objectives', label: getSystemFieldName('objective') },
  { id: 'keyResults', label: getSystemFieldName('keyResult1') },
  { id: 'themes', label: getSystemFieldName('theme') },
  { id: 'timeframes', label: getSystemFieldName('timeframe') },
  { id: 'timeframes2', label: getSystemFieldName('timeframe2') },
  { id: 'planningStages', label: 'Planning Stage' },
  { id: 'phases', label: getSystemFieldName('phase') },
  { id: 'categories', label: getSystemFieldName('category') },
  { id: 'priorities', label: getSystemFieldName('priority') },
  { id: 'tags', label: getSystemFieldName('tag') },
  { id: 'customers', label: getSystemFieldName('customer') },
  { id: 'teams', label: getSystemFieldName('team') },
  { id: 'teams2', label: getSystemFieldName('team2') },
  { id: 'skills', label: getSystemFieldName('skill') },
  { id: 'metrics', label: getSystemFieldName('metric') },
];

export const FILTER_CHILDREN_FIELDS_PROPERTY = 'childrenFields';

export const CHILDREN_FILTERS = {
  allChildren: 'ALL_CHILDREN',
  filteredChildren: 'FILTERED_CHILDREN',
  noChildren: 'NO_CHILDREN',
};

const MAP_PAGE_MODE_TO_FILTER = {
  [GOAL_MODE]: OBJECTIVES_FILTER,
  [METRICS_MODE]: METRICS_FILTER,
  [PORTFOLIO_MODE]: GLOBAL_FILTER,
};

const PAGES_WITH_PORTFOLIO_MODE = [MANAGE_PAGE, IDEAS_GRID_PAGE, SNAPSHOT_PAGE, IDEAS_PDLC_PAGE, PORTFOLIO_CANVAS_PAGE];

/**
 * Structure that has a list of filters that are dependent on another filter.
 * (For example SCENARIO_FILTER depends on GLOBAL_FILTER)
 */
const FILTERS_DEPENDENCIES = { [GLOBAL_FILTER]: [SCENARIO_FILTER] };

export {
  IDEAS_BOARD_PAGE,
  IDEAS_GRID_PAGE,
  IDEAS_ESTIMATES_PAGE,
  ALLOCATION_REPORT_PAGE,
  PROGRESS_PAGE,
  FORECAST_PAGE,
  SUMMARY_PAGE,
  GOALS_SUMMARY_PAGE,
  GOALS_GRID_PAGE,
  GOALS_SNAPSHOT_PAGE,
  GOALS_ALLOCATION_PAGE,
  SNAPSHOT_PAGE,
  MANAGE_PAGE,
  STAFFING_PAGE,
  ROADMAP_PAGE,
  GLOBAL_FILTER,
  MULTI_LAYER_FILTER,
  PORTFOLIO_REPORT_PAGE,
  REQUESTS_FILTERS,
  REQUESTS_DETAIL,
  REQUESTS_LIST,
  REQUESTS_INSIGHTS,
  DASHBOARD_HOME_PAGE,
  DASHBOARDS_PAGE,
  OBJECTIVES_FILTER,
  MAP_PAGE_MODE_TO_FILTER,
  FILTERS_FUNCTIONS,
  PAGES_WITH_PORTFOLIO_MODE,
  METRICS_FILTER,
  IDEAS_PDLC_PAGE,
  METRICS_GRID_PAGE,
  METRICS_CHART_PAGE,
  COMPARE_SCENARIOS_LIST_PAGE,
  COMPARE_SCENARIOS_SWIMLANE_PAGE,
  COMPARE_SCENARIOS_CHARTS_PAGE,
  COMPARE_SCENARIOS_SUMMARY_PAGE,
  SCENARIO_LIST_PAGE,
  SCENARIO_SWIMLANE_PAGE,
  SCENARIO_GANTT_PAGE,
  SCENARIO_SUMMARY_PAGE,
  SCENARIO_AI_PLANNER_PAGE,
  FILTERS_DEPENDENCIES,
  IDEAS_FLAT_GRID_PAGE,
  PORTFOLIO_CANVAS_PAGE,
  SCENARIO_CHART_PAGE,
  FORECAST_CHART_PAGE,
  IDEAS_OVERVIEW_PAGE,
  PORTFOLIO_LIFECYCLES_PAGE,
  PORTFOLIO_PERSONAS_PAGE,
  REQUESTS_PERSONAS,
  REQUESTS_LIFECYCLES,
  MISSION_CONTROL_HOME_PAGE,
  GOALS_CANVAS_PAGE,
  METRICS_CANVAS_PAGE,
  FORECAST_RESOURCES_PAGE,
  HOME_PAGE,
  WELCOME_PAGE,
  REQUESTS_ANALYZE,
  METRICS_ALLOCATION_PAGE,
  TEMPLATES_PAGE,
  USER_DASHBOARDS_PAGE,
  WELCOME_SELECT_TEMPLATE_PAGE,
  DASHBOARD_EXPLORE_PAGE,
};
