import React, { Fragment, useRef } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import copyToClipboard from 'utils/copyToClipboard';

export default ({ id }) => {
  const shareUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?openIdea=${id}`;
  const refInput = useRef();
  const _selectAndCopy = async () => {
    const { current } = refInput;

    current.select();
    await copyToClipboard(shareUrl);
    current.select();
  };

  return (
    <Fragment>
      <DialogContentText>Copy the URL below to share with others on your organization</DialogContentText>
      <TextField
        inputProps={{
          ref: refInput,
          onClick: _selectAndCopy,
        }}
        autoFocus
        margin="dense"
        type="text"
        readOnly
        value={shareUrl}
        fullWidth
      />
      <Grid style={{ marginTop: '15px', textAlign: 'right' }}>
        <Button onClick={_selectAndCopy} color="primary">
          Copy to clipboard
        </Button>
      </Grid>
    </Fragment>
  );
};
