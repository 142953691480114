import { ALT_HEALTH_COLORS_LABELS } from 'constants/health';

/**
 * @function getOkrHealthLabel
 *
 * returns the label for project status_color value
 *
 * @param  {string} healthValue
 * @return {string}
 */
const getOkrHealthLabel = healthValue => {
  const label = ALT_HEALTH_COLORS_LABELS[healthValue];

  if (!label) {
    return healthValue;
  }

  return label;
};

export { getOkrHealthLabel };
