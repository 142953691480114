import axios from 'axios';
import throwRequestError from '../utils/throwRequestError';
import {
  DELETE_CUSTOM_FIELD,
  CREATE_CUSTOM_FIELDS,
  UPDATE_CUSTOM_FIELDS,
  CREATE_CUSTOM_FIELD_ASSOCIATION,
  DELETE_CUSTOM_FIELD_ASSOCIATION,
} from './types';
import bulkCreateAction from 'store/utils/factory/bulkCreateAction';
import bulkUpdateAction from 'store/utils/factory/bulkUpdateAction';

export const deleteCustomField = customFieldId => {
  return dispatch => {
    const payload = axios
      .delete(`/api/customFields/${customFieldId}`)
      .then(res => res.data)
      .catch(throwRequestError);

    dispatch({
      payload,
      type: DELETE_CUSTOM_FIELD,
    });

    return payload;
  };
};

export const bulkCreateCustomFields = bulkCreateAction(CREATE_CUSTOM_FIELDS, '/api/customFields');
export const bulkUpdateCustomFields = bulkUpdateAction(UPDATE_CUSTOM_FIELDS, '/api/customFields');

export const createCustomFieldAssociation = (customFieldId, associationType) => {
  return {
    payload: axios
      .put(`/api/customFields/${customFieldId}/associations/${associationType}`)
      .then(res => res.data)
      .catch(throwRequestError),
    type: CREATE_CUSTOM_FIELD_ASSOCIATION,
    meta: { customFieldId, associationType },
  };
};

export const deleteCustomFieldAssociation = (customFieldId, associationType) => {
  return {
    payload: axios
      .delete(`/api/customFields/${customFieldId}/associations/${associationType}`)
      .then(res => res.data)
      .catch(throwRequestError),
    type: DELETE_CUSTOM_FIELD_ASSOCIATION,
    meta: { customFieldId, associationType },
  };
};
