import React from 'react';
import styled from 'styled-components';
import withTheme from '@material-ui/core/styles/withTheme';

import Pagination from 'design-system/organisms/Pagination/index';

import useCustomerRequestsPagination from 'hooks/customerRequests/useCustomerRequestsPagination';

import { CUSTOMER_REQUESTS_DEFAULT_PAGINATION_LIMIT } from 'constants/customerRequests';

function RequestsPagination({ isDisabled, hideIfNoResult, customContentComponent }) {
  const {
    handleChangePage,
    handleChangePageLimit,
    indexedCurrentPage: currentPage,
    lastPage,
    pageLimit,
    resultsCount,
  } = useCustomerRequestsPagination();

  return (
    <Wrapper>
      <Pagination
        currentPage={currentPage}
        handleChangePage={handleChangePage}
        handleChangePageLimit={handleChangePageLimit}
        includePagination={hideIfNoResult ? resultsCount > 0 : true}
        lastPage={lastPage}
        pageLimit={pageLimit}
        resultsCount={resultsCount}
        rowsPerPageOptions={[CUSTOMER_REQUESTS_DEFAULT_PAGINATION_LIMIT]}
        isDisabled={isDisabled}
        customContentComponent={customContentComponent}
      />
    </Wrapper>
  );
}

export default withTheme()(RequestsPagination);

const Wrapper = styled.div``;
