import { omit } from 'ramda';
import { DASHBOARDS_PAGE } from 'constants/filters';
import { PAGES_STATE_TO_SAVE } from 'store/userViews/consts';
import { ensureViewStateCompatibility, getPageIdFromPath } from 'utils/userViews';

const FORBIDDEN_STATE_KEYS = ['filter', 'id', 'title'];
const removeForbiddenKeysFromTemplate = omit(FORBIDDEN_STATE_KEYS);

const getCleanTemplateState = (storeState, template) => {
  const pageId = getPageIdFromPath(template.path);
  const state = ensureViewStateCompatibility(template.state, storeState);

  switch (pageId) {
    case DASHBOARDS_PAGE:
      state.dashboard = removeForbiddenKeysFromTemplate(state.dashboard);

      return state;
    default:
      return removeForbiddenKeysFromTemplate(state);
  }
};

export const generateViewFromTemplate = (state, template) => {
  const pageId = getPageIdFromPath(template.path);
  const getViewInitialStateHandler = PAGES_STATE_TO_SAVE[pageId];

  if (!getViewInitialStateHandler) {
    return null;
  }

  const templateState = getCleanTemplateState(state, template);
  const initialViewState = getViewInitialStateHandler(state);

  return {
    id: null,
    page: pageId,
    name: '',
    template_id: template.id,
    state: {
      ...initialViewState,
      ...templateState,
    },
  };
};
