import moment from 'moment-timezone';

import { getColor } from 'components/ganttCommon';

import { convertedDate, getUserName } from 'utils';
import lightenDarkenColor from 'design-system/utils/lightenDarkenColor';
import itemsTimespan from 'utils/itemsTimespan';

const DEFAULT_SELECT_COLOR_BY = 'roadmap';
const AMOUNT_TO_LIGHTHEN = -40;
const WHITE = '#ffffff';
const DAY_UNIT_TIME = 'day';
const WEEKS_UNIT_TIME = 'weeks';

const planningStages = {
  Backlog: {
    color: true,
    border: true,
  },
  Planning: {
    color: false,
    border: true,
  },
  Confirmed: {
    color: false,
    border: false,
  },
  Completed: {
    color: false,
    border: false,
  },
};

const getStartDate = (project, displayTimelineBy) => {
  if (!displayTimelineBy || !project.integrationProgress || !project.integrationProgress.startDate) {
    return project.estimated_start_date;
  }

  return project.integrationProgress.startDate;
};

const getEndDate = (project, displayTimelineBy) => {
  if (project.type === 'milestone') {
    const startDate = project.estimated_start_date ? moment(project.estimated_start_date) : moment();

    return convertedDate(startDate.add(1, DAY_UNIT_TIME));
  }

  if (!displayTimelineBy || !project.integrationProgress || !project.integrationProgress.endDate) {
    return project.deadline;
  }

  return project.integrationProgress.endDate;
};

const getIntegrationTimeSpan = integrationProgress => {
  if (!integrationProgress) return null;

  const hasStories = integrationProgress && integrationProgress.issuesTotal > 0;

  return hasStories ? itemsTimespan([moment(integrationProgress.startDate), moment(integrationProgress.endDate)]) : null;
};

/**
 * Generates the function to convert a project into a Roadmap task.
 * @param selectedColorBy The display preference related with field selected for task color
 * @param displayTimelineBy
 * @param firstGroupBy The first selected groupBy field
 * @param secondGroupBy The second selected groupBy field
 * @returns {function} A function to convert the project with the new structure
 */
const getProjectMapper = (selectedColorBy, displayTimelineBy, firstGroupBy, secondGroupBy) => project => {
  const colorBy = selectedColorBy ? selectedColorBy.key : DEFAULT_SELECT_COLOR_BY;
  const color = getColor(project, null, colorBy);

  const borderColor = lightenDarkenColor(color, AMOUNT_TO_LIGHTHEN);

  const integrationTimespan = project?.integrationProgress ? getIntegrationTimeSpan(project.integrationProgress) : null;

  const startDate = getStartDate(project, displayTimelineBy) ? moment(getStartDate(project, displayTimelineBy)) : moment();
  const endDate = getEndDate(project, displayTimelineBy)
    ? moment(getEndDate(project, displayTimelineBy))
    : moment().add(2, WEEKS_UNIT_TIME);

  return {
    id: project.id,
    type: project.type,
    title: project.title,
    startDate: startDate.clone().startOf(DAY_UNIT_TIME),
    endDate: endDate.clone().endOf(DAY_UNIT_TIME),
    predictedEndDate: integrationTimespan ? moment(integrationTimespan.endDate).endOf(DAY_UNIT_TIME) : null,
    progress: project.progress,
    ownerName: getUserName(project.owner),
    owner: project.owner,
    color: planningStages[project.planningStage] && planningStages[project.planningStage].color ? WHITE : color,
    borderColor: planningStages[project.planningStage] && planningStages[project.planningStage].border ? borderColor : null,
    ...(firstGroupBy?.field
      ? { [firstGroupBy.field]: project[firstGroupBy.field] === undefined ? null : project[firstGroupBy.field] }
      : {}),
    ...(secondGroupBy?.field
      ? { [secondGroupBy?.field]: project[secondGroupBy?.field] === undefined ? null : project[secondGroupBy?.field] }
      : {}),
    parentLayer: project.parentLayer,
    parentId: project.parent_id,
  };
};

export { getProjectMapper, planningStages, getIntegrationTimeSpan };
