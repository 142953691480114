import React from 'react';
import { useSelector } from 'react-redux';
import { getOrgHasMultipleIntegrations } from 'store/organization/selectors';


const miroLightboxIntegrationContainer = Component => {
  return props => {
    const orgHasMultipleIntegrations = useSelector(getOrgHasMultipleIntegrations);

    const {
      integration,
      userIntegrations,
      linkProjectIntegration,
      projectIntegrations,
      linkErrorMsg,
    } = props;

    return (
      <Component
        integration={integration}
        hasMultipleIntegrations={orgHasMultipleIntegrations}
        userIntegrations={userIntegrations}
        projectIntegrations={projectIntegrations}
        linkProjectIntegration={linkProjectIntegration}
        linkErrorMsg={linkErrorMsg}
      />
    );
  };
};

export default miroLightboxIntegrationContainer;
