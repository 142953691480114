import axios from 'axios';
import throwRequestError from '../utils/throwRequestError';
import {
  MERGE_CATEGORIES,
  DELETE_CATEGORY,
  CREATE_CATEGORIES,
  UPDATE_CATEGORIES,
  UNDO_ADD_CATEGORIES,
  UNDO_UPDATE_CATEGORIES,
  BULK_DELETE_CATEGORIES,
  UNDO_BULK_DELETE_CATEGORIES,
  SWITCH_CATEGORIES_ROW_ORDER,
} from './types';
import bulkCreateAction from 'store/utils/factory/bulkCreateAction';
import bulkUpdateAction from 'store/utils/factory/bulkUpdateAction';
import bulkDeleteAction from 'store/utils/factory/bulkDeleteAction';
import moveRowToPosition from 'utils/moveRowToPosition';

export function switchCategoriesRowOrder(id1, id2, update, position) {
  return (dispatch, getState) => {
    const state = getState().categories.categories;
    const prevData = state.find(({ id }) => id === +id1);
    const movedRow = moveRowToPosition(state, id1, id2, position);

    const payload = axios.put(`/api/categories/rowOrder/${id1}/${id2}`, { position }).then(res => res.data);

    dispatch({
      type: SWITCH_CATEGORIES_ROW_ORDER,
      payload,
      meta: { prev: prevData, row: movedRow, update },
    });

    return payload;
  };
}

export function mergeCategories(categoriesIdsToMerge, categoryId) {
  return dispatch => {
    const payload = axios
      .post(`/api/categories/merge/${categoryId}`, {
        categoriesIdsToMerge,
      })
      .then(response => {
        return response.data;
      })
      .catch(throwRequestError);

    dispatch({
      type: MERGE_CATEGORIES,
      payload,
    });

    return payload;
  };
}

export const deleteCategory = categoryId => {
  return dispatch => {
    const payload = axios
      .delete(`/api/categories/${categoryId}`)
      .then(res => res.data)
      .catch(throwRequestError);

    dispatch({
      payload,
      type: DELETE_CATEGORY,
    });

    return payload;
  };
};

export const createCategories = bulkCreateAction(CREATE_CATEGORIES, '/api/categories', {
  toastText: 'Categories have been created',
  ACTION_TYPE: UNDO_ADD_CATEGORIES,
  endpoint: '/api/categories/versions/last',
  store: 'categories',
});
export const updateCategories = bulkUpdateAction(UPDATE_CATEGORIES, '/api/categories', {
  toastText: 'Categories have been changed',
  ACTION_TYPE: UNDO_UPDATE_CATEGORIES,
  endpoint: '/api/categories/versions/last',
  store: 'categories',
});
export const bulkDeleteCategories = bulkDeleteAction(BULK_DELETE_CATEGORIES, '/api/categories/', {
  toastText: 'Categories have been deleted',
  ACTION_TYPE: UNDO_BULK_DELETE_CATEGORIES,
  endpoint: '/api/categories/versions/last',
  store: 'categories',
});
