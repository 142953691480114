/**
 * Given an object that can have nested children returns a new object with an
 * updated property name across all levels
 * @param {Object} obj
 * @param {string} oldName
 * @param {string} newName
 */

const renameObjectProperty = (obj, oldName, newName) => {
  if (Array.isArray(obj)) {
    return obj.map(item => renameObjectProperty(item, oldName, newName));
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj = {};

    Object.keys(obj).forEach(key => {
      if (Object.hasOwn(obj, key)) {
        const newKey = key === oldName ? newName : key;

        newObj[newKey] = renameObjectProperty(obj[key], oldName, newName);
      }
    });
    return newObj;
  }
  return obj;
};

export default renameObjectProperty;
