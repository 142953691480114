import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { login } from 'store/login';

export default () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return [email, password, props => dispatch(login({ email, password }, props)), setEmail, setPassword];
};
