import React from 'react';
import isFunction from 'lodash/isFunction';

import withTheme from '@material-ui/core/styles/withTheme';
import styled from 'styled-components';

import Checkbox from 'design-system/atoms/Checkbox/index';

import { isGroupRow } from '../helpers';

const CheckboxCellRenderer = params => {
  const { column, node, colDef, value } = params;
  const checkedHandler = e => {
    const { checked } = e.target;
    const { colId } = column;

    try {
      node.setDataValue(colId, checked);
    } catch {
      // pass
    }
  };

  let disabled = false;

  const isEditable = isFunction(colDef.editable) ? colDef.editable(params) : colDef.editable;

  if (isEditable === false) disabled = true;

  if (isGroupRow(params)) return null;

  return <StyledCheckbox disableTouchRipple fontSize="small" onChange={checkedHandler} checked={value} disabled={disabled} />;
};

export default CheckboxCellRenderer;

const StyledCheckbox = withTheme()(styled(Checkbox)`
  &&&&&& {
    svg {
      color: ${({ theme }) => theme.palette.text.info};
    }
    padding: 0 !important;

    ${({ disabled }) => disabled && `opacity: .5;`}
  }
`);
