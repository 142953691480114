import React from 'react';
import isEqual from 'react-fast-compare';

const useDeepCompareMemoize = value => {
  const ref = React.useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
};

export default (callback, dependencies) => {
  return React.useMemo(callback, useDeepCompareMemoize(dependencies));
};
