import React from 'react';

import { getOrganization } from 'store/organization/selectors';

import AllocationV2 from './AllocationV2';
import AllocationV1 from './AllocationV1';
import { useSelector } from 'react-redux';

export default () => {
  const organization = useSelector(getOrganization);

  if (organization.has_reported_allocation_report) {
    return <AllocationV2 />;
  }

  return <AllocationV1 />;
};
