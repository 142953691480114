import moment from 'moment-timezone';
import ReactDOMServer from 'react-dom/server';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { defaultTo, isNil, path, pipe } from 'ramda';

import formatPercent from 'design-system/utils/formatPercent';
import MicroChip from 'design-system/atoms/MicroChip/index';

import theme from 'design-system/theme';
import { ScopeVarianceRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';

import { DEFAULT_GROUP_OPTION, getObjectiveGroupOption } from 'store/projects/helpers/groupOptions';

import getSystemFieldName from 'utils/getSystemFieldName';

import normalizeArray from 'utils/normalizeArray';
import { convertHoursToString } from 'utils/converTimeToString';

import { getActiveCustomUserFields } from 'utils/customUserFields/customUserFields';

import { INTEGRATIONS_NAMES, INTEGRATIONS_KEYS } from 'constants/integrations';
import { PROGRESS_PERCENTAGE_DECIMAL_PLACES } from 'constants/common';
import { HEALTH_LABEL_OPTIONS, ALL_CUSTOM_USER_FIELDS_AVAILABLE } from 'constants/projects';
import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';

import { getProjectHealthLabel } from 'utils/projects/healthUtils';
import { COMMITTED_PROJECTS, UNCOMMITTED_PROJECTS } from 'routes/Forecast/Timeline/helpers/getLockedProjectsIds';
import { GANTT_FIELD_TYPE } from 'components/GanttWrapper/fieldType';
import formatDate from 'utils/dates/formatDate';

const HIDDEN_CUSTOM_FIELDS = [TYPES_OF_CUSTOM_FIELDS.RICH_TEXT];

// TODO: refactor into reactEditor components (and move to central Fields declaration)
const CUSTOM_FIELDS_FIELD_FACTORY = {
  [TYPES_OF_CUSTOM_FIELDS.FORMULA]: () => ({ field: GANTT_FIELD_TYPE.TEXT }),
  [TYPES_OF_CUSTOM_FIELDS.YES_NO]: () => ({
    field: GANTT_FIELD_TYPE.SELECT,
    hideEmptyOption: true,
    options: [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ],
    mapValue: value => {
      switch (value) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return null;
      }
    },
  }),
  [TYPES_OF_CUSTOM_FIELDS.SHORT_TEXT]: () => ({ field: GANTT_FIELD_TYPE.TEXT }),
  [TYPES_OF_CUSTOM_FIELDS.NUMBER]: () => ({ field: GANTT_FIELD_TYPE.NUMBER }),
  [TYPES_OF_CUSTOM_FIELDS.DATE]: () => ({ field: GANTT_FIELD_TYPE.DATE }),
  [TYPES_OF_CUSTOM_FIELDS.DROPDOWN]: cf => ({
    field: GANTT_FIELD_TYPE.SELECT,
    ...(cf.data
      ? {
          options: Object.keys(cf.data).reduce((acc, key) => [...acc, { value: key, label: cf.data[key] }], []),
        }
      : {}),
  }),
  [TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN]: cf => ({
    field: GANTT_FIELD_TYPE.MULTI_SELECT,
    multiple: true,
    ...(cf.data
      ? {
          options: Object.keys(cf.data).reduce((acc, key) => [...acc, { value: key, label: cf.data[key] }], []),
        }
      : {}),
  }),
};

const CUSTOM_FIELDS_TEMPLATE_FACTORY = {
  [TYPES_OF_CUSTOM_FIELDS.YES_NO]: cf => {
    return obj => {
      if (isNil(obj) || isNil(obj.custom_fields)) return null;

      const yesOrNo = obj.custom_fields[cf?.key];
      const isTrue = yesOrNo === true || yesOrNo === 'true';

      if (isTrue) {
        return 'Yes';
      }
      const isFalse = yesOrNo === false || yesOrNo === 'false';

      if (isFalse) {
        return 'No';
      }
      return null;
    };
  },
  [TYPES_OF_CUSTOM_FIELDS.DROPDOWN]: cf => {
    return obj => {
      if (isNil(obj) || isNil(obj.custom_fields)) return null;

      const selection = path(['custom_fields', cf.key])(obj);

      return cf.data_enhanced[selection]?.title;
    };
  },
  [TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN]: cf => {
    return obj => {
      if (isNil(obj) || isNil(obj.custom_fields)) return null;

      const selections = pipe(path(['custom_fields', cf.key]), defaultTo([]))(obj);
      const selectionsOrDefault = Array.isArray(selections) ? selections : [];
      const labels = selectionsOrDefault.map(optionId => cf.data_enhanced[optionId]?.title);

      return labels.join(', ');
    };
  },
};

export const COMMITTED_COLUMN = {
  id: 'committed',
  name: 'Above the Line',
  gridLabel: 'Above the Line',
  width: 80,
  resize: false,
  template: obj => {
    if (obj?.committed === true) {
      return `<p style="color: ${theme.palette.newLayout.text.grey}">${COMMITTED_PROJECTS}</p>`;
    }

    if (obj?.committed === false) {
      return UNCOMMITTED_PROJECTS;
    }

    return '';
  },
  editor: {
    type: 'inlineEditor',
    field: GANTT_FIELD_TYPE.SELECT,
    map_to: 'committed',
    hideEmptyOption: true,
    options: [
      { label: COMMITTED_PROJECTS, value: true },
      { label: UNCOMMITTED_PROJECTS, value: false },
    ],
  },
};

const mapCustomFieldToColumn = cf => {
  const fieldFactory = CUSTOM_FIELDS_FIELD_FACTORY[cf.field_type];

  if (isNil(fieldFactory)) {
    throw new Error(`Editor field factory not available for custom field type: ${cf.field_type}`);
  }

  const editorFieldParams = fieldFactory(cf);

  const templateFactory = CUSTOM_FIELDS_TEMPLATE_FACTORY[cf.field_type];
  const template = templateFactory ? templateFactory(cf) : undefined;

  return {
    id: cf.key,
    name: `(cf) ${cf.title}`,
    gridLabel: cf.title,
    template,
    editor: {
      type: 'inlineEditor',
      map_to: cf.key,
      ...editorFieldParams,
    },
  };
};

export const DISPLAY_COLUMNS_OPTIONS = ({
  systemFields,
  hasKeyResults,
  hasProducts,
  customFields,
  customUserFields,
  hasHierarchy,
  canView,
  hasIntegration,
  orgIntegrations,
  hasKeyResults2,
  shouldShowPreCalculations,
  hasCompletedAllocation,
}) => {
  const availableIntegrationKeys = ['JIRA', 'github', 'clubhouse', 'azuredevops', 'asana'];

  const integrations = orgIntegrations
    ? normalizeArray(
        orgIntegrations.filter(oi => availableIntegrationKeys.includes(oi.integrationType)),
        'integrationType',
      )
    : [];

  const integrationsAvailableToShowKey = Object.keys(integrations).length;
  const showIntegrationKey = hasIntegration && integrationsAvailableToShowKey > 0;
  const integrationName = integrationsAvailableToShowKey === 1 ? INTEGRATIONS_NAMES[Object.keys(integrations)[0]] : 'Integration';

  const activeCustomUserFields = getActiveCustomUserFields(customUserFields);

  return [
    { id: 'key', name: 'Key' },
    ...(showIntegrationKey ? [{ id: 'integrationKey', name: `${integrationName} Key` }] : []),
    {
      id: 'title',
      name: 'Title',
      editor: { type: 'inlineEditor', map_to: 'title', field: GANTT_FIELD_TYPE.TEXT },
    },
    {
      id: 'timeframeTitle',
      name: getSystemFieldName('timeframe', systemFields),
      editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.SELECT, map_to: 'timeframeTitle' },
    },
    {
      id: 'timeframe2Title',
      name: getSystemFieldName('timeframe2', systemFields),
      editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.SELECT, map_to: 'timeframe2Title' },
    },
    ...(canView && canView(PERMISSION_RESOURCES.roadmapCorp)
      ? [
          {
            id: 'roadmapCorpTitle',
            name: getSystemFieldName('roadmapCorp', systemFields),
            editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.SELECT, map_to: 'roadmapCorpTitle' },
          },
        ]
      : []),
    {
      id: 'roadmapTitle',
      name: getSystemFieldName('roadmap', systemFields),
      editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.SELECT, map_to: 'roadmapTitle' },
    },
    ...(hasProducts
      ? [
          {
            id: 'product1Title',
            name: getSystemFieldName('product1', systemFields),
            editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.SELECT, map_to: 'product1Title' },
          },
        ]
      : []),
    ...(canView && canView(PERMISSION_RESOURCES.objectiveCorp)
      ? [
          {
            id: 'objectiveCorpTitle',
            name: getSystemFieldName('objectiveCorp', systemFields),
            editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.SELECT, map_to: 'objectiveCorpTitle' },
          },
        ]
      : []),
    {
      id: 'objectiveTitle',
      name: getSystemFieldName('objective', systemFields),
      editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.SELECT, map_to: 'objectiveTitle' },
    },
    ...(hasKeyResults
      ? [
          {
            id: 'keyResult1Title',
            name: getSystemFieldName('keyResult1', systemFields),
            editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.SELECT, map_to: 'keyResult1Title' },
          },
        ]
      : []),
    ...(hasKeyResults2
      ? [
          {
            id: 'keyResult2Title',
            name: getSystemFieldName('keyResult2', systemFields),
            editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.SELECT, map_to: 'keyResult2Title' },
          },
        ]
      : []),
    {
      id: 'phaseTitle',
      name: getSystemFieldName('phase', systemFields),
      editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.SELECT, map_to: 'phaseTitle' },
    },
    {
      id: 'planningStage',
      name: 'Planning Stage',
    },
    {
      id: 'ownerName',
      name: 'Owner',
      // editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.SELECT, map_to: 'ownerName' },
    },
    {
      id: 'progressPercent',
      name: 'Progress',
      gridLabel: '%',
      template: obj => formatPercent(obj.progressOriginal, PROGRESS_PERCENTAGE_DECIMAL_PLACES),
      editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.TEXT, map_to: 'progressGridValue' },
    },
    {
      id: 'scopeVariance',
      name: 'Scope Variance',
      template: obj => {
        return (
          obj.scopeVariance &&
          ReactDOMServer.renderToString(
            <ThemeProvider theme={theme}>
              <ScopeVarianceRenderer value={obj.scopeVariance} />
            </ThemeProvider>,
          )
        );
      },
    },
    {
      id: 'plannedAllocation',
      name: 'Planned Scope (weeks)',
      gridLabel: 'Planned Scope',
    },
    {
      id: 'reportedAllocation',
      name: 'Reported Scope (weeks)',
      gridLabel: 'Reported Scope',
    },
    ...(hasCompletedAllocation
      ? [
          {
            id: 'completedAllocation',
            name: 'Completed Scope (weeks)',
            gridLabel: 'Completed Scope',
          },
        ]
      : []),
    {
      id: 'status_color',
      name: 'Health',
      template: obj => {
        const label = getProjectHealthLabel(obj.status_color);

        return (
          obj.status_color &&
          ReactDOMServer.renderToString(
            <ThemeProvider theme={theme}>
              <MicroChip
                label={label}
                color={theme.palette.healthStatus[label]?.background}
                textColor={theme.palette.healthStatus[label]?.color}
                variant="bold"
                fullWidth
              />
            </ThemeProvider>,
          )
        );
      },
      editor: {
        type: 'inlineEditor',
        field: GANTT_FIELD_TYPE.SELECT,
        map_to: 'status_color',
        options: HEALTH_LABEL_OPTIONS,
      },
    },
    {
      id: 'themeTitle',
      name: getSystemFieldName('theme', systemFields),
      editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.SELECT, map_to: 'themeTitle' },
    },
    {
      id: 'effort_score',
      name: 'Effort',
      editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.TEXT, map_to: 'effort_score' },
    },
    {
      id: 'categoryTitle',
      name: getSystemFieldName('category', systemFields),
      editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.SELECT, map_to: 'categoryTitle' },
    },
    {
      id: 'priorityTitle',
      name: getSystemFieldName('priority', systemFields),
      editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.SELECT, map_to: 'priorityTitle' },
    },
    {
      id: 'estimatedStartDate',
      name: 'Target start date',
      template: obj => (obj.group ? '' : formatDate(obj.estimated_start_date)),
      editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.DATE, map_to: 'estimated_start_date' },
    },
    {
      id: 'estimatedEndDate',
      name: 'Target end date',
      template: obj => (obj.dbType === 'story' || obj.group ? '' : formatDate(obj.deadline)),
      editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.DATE, map_to: 'deadline' },
    },
    {
      id: 'business_value',
      name: 'Benefit',
      editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.NUMBER, map_to: 'business_value' },
    },
    {
      id: 'totalCost',
      name: 'Cost',
      editor: { type: 'inlineEditor', field: GANTT_FIELD_TYPE.NUMBER, map_to: 'totalCost' },
    },
    { id: 'storyPoints', name: 'Reported Points', template: obj => (obj.storyPoints ? obj.storyPoints : '') },
    {
      id: 'timeEstimated',
      name: 'Time estimate',
      template: obj => {
        if (Number(obj.timeEstimated) && obj?.integration?.type === INTEGRATIONS_KEYS.azuredevops) {
          return convertHoursToString(obj.timeEstimated);
        }

        return obj.timeEstimated ? obj.timeEstimated : '';
      },
    },
    { id: 'issuesTotal', name: 'Issue count', template: obj => (obj.issuesTotal ? obj.issuesTotal : '') },
    ...(hasHierarchy ? [{ id: 'parentTitle', name: 'Parent' }] : []),
    { id: 'predictedEndDate', name: 'Predicted end date' },
    {
      id: 'planned_moar',
      name: 'Planned MoAR',
      template: obj => obj.planned_moar && `${parseFloat(obj.planned_moar)}%`,
    },
    { id: 'riceScore', name: 'RICE' },
    ...(customFields || []).filter(cf => !HIDDEN_CUSTOM_FIELDS.includes(cf.field_type)).map(cf => mapCustomFieldToColumn(cf)),
    ...(activeCustomUserFields || []).map(cf => ({
      id: ALL_CUSTOM_USER_FIELDS_AVAILABLE[cf]?.field,
      name: getSystemFieldName(ALL_CUSTOM_USER_FIELDS_AVAILABLE[cf]?.systemFieldKey, systemFields),
    })),
    COMMITTED_COLUMN,
    { id: 'customersCount', name: '# of Customers' },
    { id: 'customerRequestsCount', name: 'Total requests' },
    ...(shouldShowPreCalculations
      ? [
          { id: 'activeRevenue', name: 'Active value' },
          { id: 'inactiveRevenue', name: 'Inactive value' },
          { id: 'totalRevenue', name: 'Total value' },
        ]
      : []),
    {
      id: 'resourceTeamTitle',
      name: getSystemFieldName('team', systemFields),
    },
  ];
};
export const DEFAULT_DISPLAY_COLUMNS = ['title', 'progressPercent', 'status_color', 'committed'];

export const GROUP_OPTIONS = ({ systemFields, hasKeyResults, hasKeyResults2, hasProducts, hasHierarchy }) => [
  { key: null, title: 'No group' },
  { key: 'objective', title: getSystemFieldName('objective', systemFields) },
  ...(hasKeyResults
    ? [
        {
          key: 'key_result_1',
          field: 'keyResult1',
          title: getSystemFieldName('keyResult1', systemFields),
        },
      ]
    : []),
  ...(hasKeyResults2
    ? [{ key: 'key_result_2', field: 'keyResult2', title: getSystemFieldName('keyResult2', systemFields) }]
    : []),
  { key: 'timeframe', title: getSystemFieldName('timeframe', systemFields) },
  { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
  ...(hasProducts
    ? [
        {
          key: 'product_1',
          field: 'product1',
          title: getSystemFieldName('product1', systemFields),
        },
      ]
    : []),
  { key: 'theme', title: getSystemFieldName('theme', systemFields) },
  { key: 'phase', title: getSystemFieldName('phase', systemFields) },
  { key: 'category', title: getSystemFieldName('category', systemFields) },
  { key: 'priority', title: getSystemFieldName('priority', systemFields) },
  ...(hasHierarchy
    ? [
        { key: 'initiative', title: getSystemFieldName('initiative', systemFields) },
        { key: 'bet', title: getSystemFieldName('bet', systemFields) },
      ]
    : []),
];

export const getDefaultGroupOptions = getSystemFieldName => [getObjectiveGroupOption(getSystemFieldName), DEFAULT_GROUP_OPTION];

export const ZOOM_OPTIONS = [
  { id: 'day', title: 'Daily' },
  { id: 'week', title: 'Weekly' },
  { id: 'month', title: 'Monthly' },
  { id: 'quarter', title: 'Quarterly' },
];
export const DEFAULT_ZOOM_OPTION = ZOOM_OPTIONS.find(g => g.id === 'week');

export const COLOR_BY_OPTIONS = (systemFields, hasKeyResults, hasProducts, hasHierarchy, canView, hasKeyResults2) => [
  { key: 'health', title: 'Health' },
  ...(canView && canView(PERMISSION_RESOURCES.roadmapCorp)
    ? [{ key: 'roadmapCorp', title: getSystemFieldName('roadmapCorp', systemFields) }]
    : []),
  { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
  ...(hasProducts ? [{ key: 'product1', title: getSystemFieldName('product1', systemFields) }] : []),
  ...(canView && canView(PERMISSION_RESOURCES.objectiveCorp)
    ? [{ key: 'objectiveCorp', title: getSystemFieldName('objectiveCorp', systemFields) }]
    : []),
  { key: 'objective', title: getSystemFieldName('objective', systemFields) },
  ...(hasKeyResults ? [{ key: 'keyResult1', title: getSystemFieldName('keyResult1', systemFields) }] : []),
  ...(hasKeyResults2 ? [{ key: 'keyResult2', title: getSystemFieldName('keyResult2', systemFields) }] : []),
  { key: 'idea', title: getSystemFieldName('idea', systemFields) },
  // ...(
  //   hasHierarchy
  //     ? [
  //       { key: 'initiative', title: getSystemFieldName('initiative', systemFields) },
  //       { key: 'bet', title: getSystemFieldName('bet', systemFields) },
  //     ]
  //     : []
  // ),
  { key: 'theme', title: getSystemFieldName('theme', systemFields) },
  { key: 'category', title: getSystemFieldName('category', systemFields) },
  { key: 'priority', title: getSystemFieldName('priority', systemFields) },
];
export const DEFAULT_COLOR_BY_OPTION = systemFields => COLOR_BY_OPTIONS(systemFields).find(g => g.id === 'roadmap');

export const DEFAULT_TIME_WINDOW = { key: 'auto', from: moment().subtract(30, 'days'), to: moment().add(30, 'days') };

export const ROUNDING_OPTIONS = [
  { key: 'whole', title: 'No decimal' },
  { key: 'decimal', title: '2 decimal' },
];
export const DEFAULT_ROUNDING_OPTION = ROUNDING_OPTIONS[0];

export const DEFAULT_HIGHLIHT_OPTION = { key: 'overOnly' };
