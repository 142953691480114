import { isEmpty, keys } from 'ramda';
import { ADMIN_USER, EDITOR_USER, MANAGER_USER } from '@dragonboat/permissions';
import { FeatureFlags } from '@dragonboat/config';

import {
  INTEGRATIONS_KEYS,
  NON_AGILE_INTEGRATIONS,
  USER_INTEGRATIONS_TYPES,
  COLLABORATION_TOOL_TYPE_INTEGRATIONS,
  INTEGRATIONS_NAMES,
} from 'constants/integrations';

import Jira from './Jira';
import Github from './Github';
import Clubhouse from './Clubhouse';
import Okta from './Okta';
import Asana from './Asana';
import Azure from './Azure';
import Slack from './Slack';
import MicrosoftTeams from './MicrosoftTeams';
import AzureDevops from './AzureDevops';
import Miro from './Miro';
import DragonboatV2 from './DragonboatV2';
import Zendesk from './Zendesk';
import Rally from './Rally';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useFeatureFlags from 'hooks/useFeatureFlags';
import OneStepIntegration from 'features/OneStepIntegration';

const jira = integrationsCount => ({
  name: 'jira',
  label: `Jira ${integrationsCount ? `(${integrationsCount})` : ''}`,
  component: Jira,
  integrationType: 'JIRA',
  userIntegrationType: 0,
});

const github = {
  name: 'github',
  label: 'GitHub',
  component: Github,
  integrationType: 'github',
  userIntegrationType: 1,
};

const clubhouse = {
  name: 'clubhouse',
  label: 'Shortcut',
  component: Clubhouse,
  integrationType: 'clubhouse',
  userIntegrationType: 2,
};

const okta = {
  name: 'okta',
  label: 'Okta',
  component: Okta,
  integrationType: 'okta',
  userIntegrationType: 4,
};

const asana = {
  name: 'asana',
  label: 'Asana',
  component: Asana,
  integrationType: 'asana',
  userIntegrationType: 5,
};

const azure = {
  name: 'azuresso',
  label: 'Azure Active Directory',
  component: Azure,
  integrationType: 'azure',
  userIntegrationType: 6,
};

const slack = {
  name: 'slack',
  label: 'Slack',
  component: Slack,
  integrationType: 'slack',
  userIntegrationType: 7,
};

const microsoftTeams = {
  name: INTEGRATIONS_KEYS.microsoftTeams,
  label: INTEGRATIONS_NAMES[INTEGRATIONS_KEYS.microsoftTeams],
  component: MicrosoftTeams,
  integrationType: INTEGRATIONS_KEYS.microsoftTeams,
  userIntegrationType: USER_INTEGRATIONS_TYPES[INTEGRATIONS_KEYS.microsoftTeams],
};

const azuredevops = integrationsCount => ({
  name: 'azuredevops',
  label: `Azure Devops ${integrationsCount ? `(${integrationsCount})` : ''}`,
  component: AzureDevops,
  integrationType: 'azuredevops',
  userIntegrationType: 8,
});

const miro = {
  name: 'miro',
  label: 'Miro',
  component: Miro,
  integrationType: 'miro',
  userIntegrationType: 9,
};

const zendesk = {
  name: 'zendesk',
  label: 'Zendesk',
  component: Zendesk,
  integrationType: 'zendesk',
  userIntegrationType: 13,
};

const rally = integrationsCount => ({
  name: 'rally',
  label: `Rally ${integrationsCount ? `(${integrationsCount})` : ''}`,
  component: Rally,
  integrationType: INTEGRATIONS_KEYS.rally,
  userIntegrationType: USER_INTEGRATIONS_TYPES[INTEGRATIONS_KEYS.rally],
});

const dragonboatV2 = integrationsCount => ({
  name: 'dragonboat',
  label: `Dragonboat ${integrationsCount ? `(${integrationsCount})` : ''}`,
  component: DragonboatV2,
});

// Show if it has feature flag on, is currently a parent or there is no access configured (as parent or child) yet.
const showDoDV2Settings = (organization, isParentDragon, organizationAccessControlData) =>
  organization?.has_dod_v2 && (isParentDragon || isEmpty(organizationAccessControlData));

const defaultIntegration = (allowedIntegration, oneStepAvailableIntegrations) => ({
  name: allowedIntegration,
  label: oneStepAvailableIntegrations[allowedIntegration]?.title,
  component: OneStepIntegration,
  integrationType: allowedIntegration,
  userIntegrationType: oneStepAvailableIntegrations[allowedIntegration]?.userIntegrationType,
});

export default (activeOrgIntegrations, allowedOrgIntegrationsTypes, organization, currentUser, oneStepAvailableIntegrations) => {
  const { isParentDragon, organizationAccessControlData, childrenDragons } = useOrganizationsAccessControl();

  const hasZendeskIntegration = useFeatureFlags([FeatureFlags.HAS_ZENDESK_REQUEST_INTEGRATION]);
  const hasRallyIntegration = useFeatureFlags([FeatureFlags.HAS_RALLY_INTEGRATION]);
  const hasMicrosoftTeamsIntegration = useFeatureFlags([FeatureFlags.HAS_MICROSOFT_TEAMS_INTEGRATION]);

  const _shouldShowIntegration = integration => {
    // PROD-5328
    const isEditorTypeUser = [ADMIN_USER, MANAGER_USER, EDITOR_USER].includes(currentUser.role_id);

    if (COLLABORATION_TOOL_TYPE_INTEGRATIONS.includes(integration) && isEditorTypeUser) {
      return false;
    }

    const nonAgileIntegrations = [...NON_AGILE_INTEGRATIONS, ...keys(oneStepAvailableIntegrations)];

    if (nonAgileIntegrations.includes(integration)) return true;
    if (isParentDragon && integration !== INTEGRATIONS_KEYS.dragonboat) return false;
    if (organization.has_multiple_integrations) return true;
    if ((activeOrgIntegrations || []).filter(i => !nonAgileIntegrations.includes(i.integrationType)).length === 0) return true;

    return !!activeOrgIntegrations.find(i => i.integrationType === integration);
  };

  return allowedOrgIntegrationsTypes.reduce((acc, allowedIntegration) => {
    if (!_shouldShowIntegration(allowedIntegration)) return acc;

    switch (allowedIntegration) {
      case 'JIRA':
        acc.push(
          jira(
            organization.has_multiple_integrations ? activeOrgIntegrations.filter(i => i.integrationType === 'JIRA').length : '',
          ),
        );
        break;
      case 'clubhouse':
        acc.push(clubhouse);
        break;
      case 'github':
        acc.push(github);
        break;
      case 'okta':
        acc.push(okta);
        break;
      case 'asana':
        acc.push(asana);
        break;
      case 'azure':
        acc.push(azure);
        break;
      case 'azuredevops':
        acc.push(
          azuredevops(
            organization.has_multiple_integrations
              ? activeOrgIntegrations.filter(i => i.integrationType === INTEGRATIONS_KEYS.azuredevops).length
              : '',
          ),
        );
        break;
      case 'slack':
        acc.push(slack);
        break;
      case INTEGRATIONS_KEYS.microsoftTeams:
        hasMicrosoftTeamsIntegration && acc.push(microsoftTeams);
        break;
      case 'miro':
        organization?.has_miro && acc.push(miro);
        break;
      case INTEGRATIONS_KEYS.dragonboat:
        showDoDV2Settings(organization, isParentDragon, organizationAccessControlData) &&
          acc.push(dragonboatV2(childrenDragons.length));
        break;
      case 'zendesk':
        if (hasZendeskIntegration) {
          acc.push(zendesk);
        }
        break;
      case INTEGRATIONS_KEYS.rally:
        if (hasRallyIntegration) {
          acc.push(
            rally(
              organization.has_multiple_integrations
                ? activeOrgIntegrations.filter(i => i.integrationType === INTEGRATIONS_KEYS.rally).length
                : '',
            ),
          );
        }
        break;

      default:
        if (keys(oneStepAvailableIntegrations).includes(allowedIntegration)) {
          acc.push(defaultIntegration(allowedIntegration, oneStepAvailableIntegrations));
        }
        break;
    }

    return acc;
  }, []);
};
