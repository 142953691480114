import { prosemirrorNodeToHtml, htmlToProsemirrorNode, RemirrorManager } from '@remirror/core';
import { Node as PMNode } from '@remirror/pm/model';
import DOMPurify from 'dompurify';

import EditorExtensions from './extensions';
import { F, tryCatch, isNil, isEmpty, either, forEach } from 'ramda';

const isNilOrEmpty = either(isNil, isEmpty);

const safeJsonParse = tryCatch(JSON.parse, F);

export const convertEditorJsonToHtml = editorJson => {
  const onError = ({ json, invalidContent, transformers }) => {
    // Automatically remove all invalid nodes and marks.
    return transformers.remove(json, invalidContent);
  };

  const extensions = EditorExtensions({ onBlur: () => {}, onClick: () => {} });
  const manager = RemirrorManager.create(extensions, { onError });

  const proseMirrorNode = PMNode.fromJSON(manager.schema, editorJson);

  return prosemirrorNodeToHtml(proseMirrorNode);
};

export const convertHtmlStringToEditorJson = htmlString => {
  const validJson = safeJsonParse(htmlString);

  if (validJson) {
    return validJson;
  }

  const onError = ({ json, invalidContent, transformers }) => {
    // Automatically remove all invalid nodes and marks.
    return transformers.remove(json, invalidContent);
  };

  const extensions = EditorExtensions();

  const manager = RemirrorManager.create(extensions, { onError });

  const docNode = htmlToProsemirrorNode({
    content: htmlString,
    schema: manager.schema,
  }).toJSON();

  return docNode;
};

/**
 * Insert link at the head of the given document content
 * @param {Object} document - ProseMirror document object
 * @param {String} href - The href for the link to be added
 * @param {String} text - The text for the link
 * @returns {Object} - The updated document
 */
export const unshiftLinkToDocument = (document, textBeforeLink, href, linkText) => {
  if (!Array.isArray(document?.content)) {
    return;
  }

  const linkMark = {
    type: 'link',
    attrs: {
      href,
      target: null,
      auto: true,
      data: {},
    },
  };

  const linkNode = {
    type: 'paragraph',
    content: [
      {
        type: 'text',
        text: textBeforeLink,
      },
      {
        type: 'text',
        marks: [linkMark],
        text: linkText,
      },
    ],
  };

  return {
    ...document,
    content: [linkNode, ...document.content],
  };
};

/**
 * @function fillInEmptyTableRows
 * Adds an empty table data cell element to empty table rows in HTML content
 * Prevents internal error from Remirror causing a crash for <tr> elements without <td> children
 * @param {*} htmlString String of HTML content
 * @returns
 */
 export const fillInEmptyTableRows = htmlString => {
   // Parse the HTML into a DOM tree
   const dom = new DOMParser().parseFromString(htmlString, 'text/html');

   if (isNilOrEmpty(dom)) {
     return htmlString;
   }

   // Find all <tr> tags
   const trElements = dom.querySelectorAll('tr');

   // Iterate over each <tr> element
   forEach(tr => {
     // Check if the <tr> is empty
     if (isEmpty(tr.innerHTML.trim())) {
       // Add an empty <td> inside the empty <tr>
       const td = dom.createElement('td');

       tr.appendChild(td);
     }
   }, trElements);

   // Serialize the DOM back to a string
   const updatedHtml = dom.documentElement.outerHTML;

   // Sanitize the HTML
   const sanitizedHtml = DOMPurify.sanitize(updatedHtml);

   return sanitizedHtml;
 };
