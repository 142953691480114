import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';

export default (pageFilters, hasBet) => {
  let { layer = IDEA_LAYER, secondLayer = [] } = pageFilters || {};

  if (pageFilters.layer === 'TOP_LAYER') {
    layer = hasBet ? BET_LAYER : INITIATIVE_LAYER;
  }
  if (pageFilters.secondLayer === 'ALL_OTHERS') {
    secondLayer = hasBet ? [INITIATIVE_LAYER, IDEA_LAYER] : [IDEA_LAYER];
  }
  return { layer, secondLayer, children: pageFilters.children };
};
