import { useState, useEffect, useCallback } from 'react';

/**
 * @function getShowMoreOptionsForRow
 *
 * Check if the row is inside of more optons array or not
 *
 * @param  {Strinn} selectedOption
 * @param  {Array} options
 * @return {Boolean} }
 */
const getShowMoreOptionsForRow = (selectedOption, options = []) => {
  if (!selectedOption) return false;

  const indexOfValue = options.reduce((i, option, currentIndex) => {
    if (option.children.includes(selectedOption)) {
      return currentIndex;
    }

    return i;
  }, 0);

  return indexOfValue > 0;
};

/**
 * @function useRoadmapMetadataShowMoreOptions
 *
 * Use option to check if shoudl show more options for roadmap metadata
 *
 * @param  {String} props.value
 * @param  {Array} props.options
 * @param  {Number} props.hoveredRowIndex
 * @param  {Array} props.optionsByTitle
 * @return {Object}
 */
const useRoadmapMetadataShowMoreOptions = ({ value, options, hoveredRowIndex, optionsByTitle }) => {
  const [showMoreOptions, setShowMoreOptions] = useState(getShowMoreOptionsForRow(value, options));

  // when user changes the the row (up and down keys) will check if should show more options or not
  useEffect(() => {
    if (!optionsByTitle[hoveredRowIndex]) {
      return;
    }

    const newMoreOptions = getShowMoreOptionsForRow(optionsByTitle[hoveredRowIndex], options);

    setShowMoreOptions(newMoreOptions);
  }, [hoveredRowIndex, optionsByTitle, options, setShowMoreOptions]);

  // toggle show more options handler
  const toggleShowMoreOptions = useCallback(() => {
    setShowMoreOptions(currentValue => !currentValue);
  }, [setShowMoreOptions]);

  return {
    showMoreOptions,
    toggleShowMoreOptions,
  };
};

export default useRoadmapMetadataShowMoreOptions;
