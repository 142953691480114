const CYCLE_LEVEL = 0;

const getDataPath = data => data.path || [data.uniqueId];

const isParentItem = data => CYCLE_LEVEL === data?.level;

const makeCheckAddVisibility =
  () =>
  ({ data }) => {
    return isParentItem(data);
  };

const NEW_ROW_ID = 'new';

const getRowId = ({ data }) => {
  if (!data.uniqueId) {
    return NEW_ROW_ID;
  }

  return data.uniqueId;
};

export { getDataPath, isParentItem, makeCheckAddVisibility, getRowId };
