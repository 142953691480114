import React from 'react';
import styled from 'styled-components';

// Components
import HelpLink from 'design-system/atoms/HelpLink/index';
import InlineIcon from 'design-system/atoms/InlineIcon/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import FieldMapping from './FieldMapping';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Rename from 'routes/Settings/Base/Components/Rename/Rename';

export default props => {
  const { enableAutomaticUpdate, ...rest } = props;

  const rows = ['request'].filter(Boolean);

  return (
    <Wrapper>
      <Rename rows={rows} />
      {enableAutomaticUpdate && (
        <>
          <div>
            <TextDeprecated variant="h6">
              Automation Rules{' '}
              <HelpLink
                href="https://dragonboat.zendesk.com/hc/en-us/articles/6504323566359-Automate-Request-Status"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InlineIcon>
                  <HelpIcon />
                </InlineIcon>
              </HelpLink>
            </TextDeprecated>
          </div>
          <FieldMapping {...rest} />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &&&& {
    padding: 52px 32px 32px;
  }
`;
