import Axios from 'axios';
import isFunction from 'lodash/isFunction';
import throwRequestError from '../throwRequestError';

export default (ACTION_TYPE, page, stateKey, viewUrl, viewState) => {
  return () => {
    return async (dispatch, getState) => {
      let stateToSave = viewState;

      if (!stateToSave) {
        const state = getState();

        if (isFunction(stateKey)) {
          stateToSave = { ...stateKey(state) };
        } else {
          stateToSave = { [stateKey]: state[stateKey] };
        }
      }
      const data = {
        state: stateToSave,
        page,
        path: window.location.pathname.substring(1),
      };

      const payload = Axios.post('/api/userViewsOld/', data)
        .then(response => {
          const { id } = response.data;
          const host = window.location.origin;
          const shareUrl = `${host}${viewUrl}?sharedView=${id}`; // &public=true`;

          return shareUrl;
        })
        .catch(throwRequestError);

      dispatch({
        type: ACTION_TYPE,
        payload,
      });

      return payload;
    };
  };
};
