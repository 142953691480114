import {
  UPDATE_GOALS_ALLOCATION_GRID_PREFERENCES,
  UPDATE_GOALS_ALLOCATION_TIME_PERIOD,
  UPDATE_GOALS_ALLOCATION_TIME_UNIT,
} from './types';

export const updateGoalsAlocationTimePeriod = state => {
  return {
    payload: state,
    type: UPDATE_GOALS_ALLOCATION_TIME_PERIOD,
    meta: {
      makesActiveViewDirty: true,
    },
  };
};

export const updateGoalsAlocationTimeUnit = state => {
  return {
    payload: state,
    type: UPDATE_GOALS_ALLOCATION_TIME_UNIT,
    meta: {
      makesActiveViewDirty: true,
    },
  };
};

export const updateGoalsAllocationGridPreferences = state => {
  return {
    payload: state,
    type: UPDATE_GOALS_ALLOCATION_GRID_PREFERENCES,
    meta: {
      makesActiveViewDirty: true,
    },
  };
};
