import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import PageLink from 'design-system/atoms/PageLink/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import { FIELDS_MAPPING_TYPES_FOR_IMPORT } from 'constants/integrations';

const DOCS_LINKS = {
  MAPPING_FIELDS: 'https://dragonboat.zendesk.com/hc/en-us/articles/360025615233-Jira-Mapping-Fields-Field-Sync',
};

const FieldsMappingTypeSelection = props => {
  const { mappingLink, selectedType, onChangeHandler } = props;

  return (
    <Wrapper>
      <RadioGroup value={selectedType} onChange={onChangeHandler}>
        <FormControlLabel
          value={FIELDS_MAPPING_TYPES_FOR_IMPORT.SAVED_MAPPING}
          control={<Radio />}
          label={
            <InfoIconContainer>
              <RadioButtonLabel>Apply saved mapping</RadioButtonLabel>
              <ButtonIcon href={DOCS_LINKS.MAPPING_FIELDS} target="_blank">
                <InfoIcon />
              </ButtonIcon>
              <PageLink href={mappingLink} target="_blank">
                (see mapping)
              </PageLink>
            </InfoIconContainer>
          }
        />
        <FormControlLabel
          value={FIELDS_MAPPING_TYPES_FOR_IMPORT.CUSTOM_MAPPING}
          control={<Radio />}
          label={
            <InfoIconContainer>
              <RadioButtonLabel>Apply a one time mapping for just this import</RadioButtonLabel>
            </InfoIconContainer>
          }
        />
      </RadioGroup>
    </Wrapper>
  );
};

FieldsMappingTypeSelection.propTypes = {
  mappingLink: PropTypes.string,
  selectedType: PropTypes.string,
  onChangeHandler: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  margin-top: 35px;
`;

const InfoIconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const InfoIcon = styled(InfoOutlinedIcon)`
  font-size: 16px !important;
`;

const RadioButtonLabel = styled(TextDeprecated)`
  font-size: 14px;
`;

export default FieldsMappingTypeSelection;
