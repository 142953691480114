import { defaultTo } from 'ramda';

import {
  FETCH_OUTCOME_ALLOCATION_DATA_FULFILLED,
  FETCH_OUTCOME_SNAPSHOT_DATA_FULFILLED,
  RESET_ALLOCATION_DATA,
  UPDATE_GOAL_MODE_ALLOCATION_STATE,
  UPDATE_GOAL_MODE_STATE,
  UPDATE_GOAL_MODE_SNAPSHOT_STATE,
  PAGE_LOAD_OUTCOME_MODULE_GOALS_FULFILLED,
} from '../types';

import { DEFAULT_OUTCOME_ALLOCATION_STATE } from '../consts';
import { UPDATE_OUTCOME_MODULE_FILTERS_FULFILLED } from 'store/goalMode/types';
import {
  CREATE_KEY_RESULT_FROM_DRAWER_FULFILLED,
  CREATE_KEY_RESULT_FULFILLED,
  CREATE_OBJECTIVE,
  CREATE_OBJECTIVE_FROM_DRAWER_FULFILLED,
} from 'store/objectives/types';
import { keyResultsAdapter, objectivesAdapter } from '../adapters';

export const initialState = {
  showTreeView: true,
  tableVisibleFields: ['Health', 'Goal Progress', 'Owner', 'Summary', 'Metric', 'Applies to'],
  expandedItems: false,
  pageSize: 50,
  searchText: '',
  outcomeSnapshotData: [],
  outcomeSnapshotState: {
    hideEmptyCards: false,
    metricViewModeOn: false,
    metricClustersVisible: false,
    metricViewModeTimePeriod: {},
    openTasks: [],
    cardsPerRow: 2,
    visibleCardElements: {
      metrics: true,
      portfolioProgress: true,
      portfolioItems: true,
      ownerAvatar: true,
    },
  },
  outcomeAllocationData: [],
  outcomeAllocationState: DEFAULT_OUTCOME_ALLOCATION_STATE,
  goalsCountByLevel: {
    objectives: {
      totalsByLevel: {},
    },
    keyResults: {
      totalsByLevel: {},
    },
  },
  loadedObjectives: objectivesAdapter.getInitialState(),
  loadedKeyResults: keyResultsAdapter.getInitialState(),
};

export default function goalModeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_OUTCOME_ALLOCATION_DATA_FULFILLED:
      return {
        ...state,
        outcomeAllocationData: action.payload.data,
      };
    case FETCH_OUTCOME_SNAPSHOT_DATA_FULFILLED:
      return {
        ...state,
        outcomeSnapshotData: action.payload.data,
      };
    case RESET_ALLOCATION_DATA:
      return {
        ...state,
        outcomeAllocationData: [],
      };
    case UPDATE_GOAL_MODE_ALLOCATION_STATE:
      return {
        ...state,
        outcomeAllocationState: {
          ...state.outcomeAllocationState,
          ...action.payload,
        },
      };
    case UPDATE_GOAL_MODE_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_GOAL_MODE_SNAPSHOT_STATE: {
      return {
        ...state,
        outcomeSnapshotState: {
          ...state.outcomeSnapshotState,
          ...action.payload,
        },
      };
    }
    case UPDATE_OUTCOME_MODULE_FILTERS_FULFILLED:
      const { fetchOkrsResult: okrsDataFromFilterUpdate } = action.payload;

      const numberOfObjectivesLoadedFromFilterUpdate = defaultTo(0, okrsDataFromFilterUpdate?.objectives?.data?.length);
      const numberOfKeyResultsLoadedFromFilterUpdate = defaultTo(0, okrsDataFromFilterUpdate?.keyResults?.data?.length);

      const objectivesCountByLevelFromFilterUpdate = defaultTo([], okrsDataFromFilterUpdate?.objectives?.countsByLevel);
      const keyResultsCountByLevelFromFilterUpdate = defaultTo([], okrsDataFromFilterUpdate?.keyResults?.countsByLevel);

      return {
        ...state,
        loadedObjectives: objectivesAdapter.setAll(
          state.loadedObjectives,
          defaultTo([], okrsDataFromFilterUpdate?.objectives?.data),
        ),
        loadedKeyResults: keyResultsAdapter.setAll(
          state.loadedKeyResults,
          defaultTo([], okrsDataFromFilterUpdate?.keyResults?.data),
        ),
        goalsCountByLevel: {
          objectives: {
            loadedCount: numberOfObjectivesLoadedFromFilterUpdate,
            totalsByLevel: objectivesCountByLevelFromFilterUpdate,
          },
          keyResults: {
            loadedCount: numberOfKeyResultsLoadedFromFilterUpdate,
            totalsByLevel: keyResultsCountByLevelFromFilterUpdate,
          },
        },
      };
    case PAGE_LOAD_OUTCOME_MODULE_GOALS_FULFILLED:
      const { objectives, keyResults } = action.payload;

      const numberOfObjectivesLoadedFromLoad = defaultTo(0, objectives?.data?.length);
      const numberOfKeyResultsLoadedFromLoad = defaultTo(0, keyResults?.data?.length);

      const objectivesCountByLevelFromLoad = defaultTo([], objectives?.countsByLevel);
      const keyResultsCountByLevelFromLoad = defaultTo([], keyResults?.countsByLevel);

      return {
        ...state,
        loadedObjectives: objectivesAdapter.setAll(state.loadedObjectives, defaultTo([], objectives?.data)),
        loadedKeyResults: objectivesAdapter.setAll(state.loadedKeyResults, defaultTo([], keyResults?.data)),
        goalsCountByLevel: {
          objectives: {
            loadedCount: numberOfObjectivesLoadedFromLoad,
            totalsByLevel: objectivesCountByLevelFromLoad,
          },
          keyResults: {
            loadedCount: numberOfKeyResultsLoadedFromLoad,
            totalsByLevel: keyResultsCountByLevelFromLoad,
          },
        },
      };
    case CREATE_KEY_RESULT_FROM_DRAWER_FULFILLED:
    case CREATE_KEY_RESULT_FULFILLED: {
      if (!action.payload || !action.payload.id) {
        return state;
      }

      const levelCurrentCount = state.goalsCountByLevel.keyResults.totalsByLevel[action.payload.level] || 0;

      return {
        ...state,
        goalsCountByLevel: {
          ...state.goalsCountByLevel,
          keyResults: {
            loadedCount: state.goalsCountByLevel.keyResults.loadedCount + 1,
            totalsByLevel: {
              ...state.goalsCountByLevel.keyResults.totalsByLevel,
              [action.payload.level]: levelCurrentCount + 1,
            },
          },
        },
      };
    }
    case CREATE_OBJECTIVE_FROM_DRAWER_FULFILLED:
    case CREATE_OBJECTIVE: {
      if (!action.payload || !action.payload.id) {
        return state;
      }

      const levelCurrentCount = state.goalsCountByLevel.objectives.totalsByLevel[action.payload.level] || 0;

      return {
        ...state,
        goalsCountByLevel: {
          ...state.goalsCountByLevel,
          objectives: {
            loadedCount: state.goalsCountByLevel.objectives.loadedCount + 1,
            totalsByLevel: {
              ...state.goalsCountByLevel.objectives.totalsByLevel,
              [action.payload.level]: levelCurrentCount + 1,
            },
          },
        },
      };
    }

    default:
      return state;
  }
}
