import React, { memo } from 'react';
import RoadmapTable from 'design-system/organisms/RoadmapTable/index';

import theme from 'design-system/theme';
import invertedTextColor from 'design-system/utils/invertedTextColor';

import { ROW_HEIGHT, snapToGridOptions } from '../helpers';

const DndBoundsName = 'roadmap-timeline-playground';
const MilestoneDndBoundsName = 'roadmap-timeline-milestone-playground';

const DEFAULT_SNAP = 1;
const DEFAULT_Y_SNAP = 35;
const OVERFLOW_TEXT_COLOR = '#0000009a';

const Bar = memo(
  ({
    id,
    title,
    color,
    borderColor,
    type,
    ownerName,
    startDate,
    endDate,
    predictedEndDate,
    progress,
    info,
    isMilestoneGroupTopLane,
    openBody,
    onDoubleClick,
    onDragStop,
    onResizeStop,
    isLoading,
    showTooltip,
    showTextOverflow,
    resizable,
    draggable,
    mode,
    slotWidth,
    snapToGridOn,
    barRenderer,
  }) => {
    const props = {
      id,
      name: ownerName,
      title,
      color,
      borderColor,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      predictedEndDate:
        snapToGridOn === snapToGridOptions.PREDICTED_END_DATE && predictedEndDate ? predictedEndDate.format('YYYY-MM-DD') : null,
      progress,
      textColor: showTextOverflow ? OVERFLOW_TEXT_COLOR : invertedTextColor(color, true, undefined, theme.palette.text.primary),
      top: 0,
      left: info.left,
      width: info.width,
      height: ROW_HEIGHT,
      boundsname: isMilestoneGroupTopLane ? MilestoneDndBoundsName : DndBoundsName,
      openBody,
      onDoubleClick,
      onDragStop,
      onResizeStop,
      isLoading,
      showTooltip,
      showTextOverflow,
      resizable,
      disableDragX: !draggable,
      disableDragY: !draggable,
      resizeGrid: mode === 'day' ? [slotWidth, DEFAULT_SNAP] : [DEFAULT_SNAP, DEFAULT_SNAP],
      dragGrid: snapToGridOn || mode === 'day' ? [slotWidth, DEFAULT_Y_SNAP] : [DEFAULT_SNAP, DEFAULT_Y_SNAP],
      slotWidth,
    };

    if (barRenderer) {
      return barRenderer(props);
    }

    return type === 'milestone' ? <RoadmapTable.Milestone {...props} /> : <RoadmapTable.Bar {...props} />;
  },
);

Bar.displayName = 'Bar';

export default Bar;

export { DndBoundsName, MilestoneDndBoundsName };
