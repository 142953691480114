import React from 'react';
import { withTheme } from 'styled-components';
import SvgIcon from '@material-ui/core/SvgIcon';

function SvgComponent(props) {
  const fill = props.fill || props.theme.palette.text.primary;

  return (
    <SvgIcon fontSize="inherit" width="24.988" height="24.989" viewBox="0 0 24.988 24.989" {...props}>
      <g id="icon-list" transform="translate(-81.514 -689.202)" opacity="0.6">
        <path 
          id="Path_15576" 
          data-name="Path 15576"
          d="M102.629,714.191H85.387a3.878,3.878,0,0,1-3.873-3.874V693.075a3.877,3.877,0,0,1,3.873-3.873h17.242a3.877,
          3.877,0,0,1,3.873,3.873v17.242A3.878,3.878,0,0,1,102.629,714.191ZM85.387,691.2a1.875,1.875,0,0,0-1.873,
          1.873v17.242a1.875,1.875,0,0,0,1.873,1.874h17.242a1.875,1.875,0,0,0,1.873-1.874V693.075a1.875,1.875,0,0,0-1.873-1.873Z"
          fill={fill}
        />
        <path 
          id="Path_15577" 
          data-name="Path 15577"
          d="M90.176,714.191a1,1,0,0,1-1-1V690.2a1,1,0,0,1,2,0v22.989A1,1,0,0,1,90.176,714.191Z"
          fill={fill}
        />
        <path 
          id="Path_15578" 
          data-name="Path 15578"
          d="M97.839,714.191a1,1,0,0,1-1-1V690.2a1,1,0,0,1,2,0v22.989A1,1,0,0,1,97.839,714.191Z"
          fill={fill}
        />
        <path 
          id="Path_15579"
          data-name="Path 15579"
          d="M105.5,707.461H82.514a1,1,0,0,1,0-2H105.5a1,1,0,0,1,0,2Z"
          fill={fill}
        />
        <path 
          id="Path_15580"
          data-name="Path 15580"
          d="M105.5,700.713H82.514a1,1,0,0,1,0-2H105.5a1,1,0,0,1,0,2Z"
          fill={fill}
        />
        <path 
          id="Path_15581" 
          data-name="Path 15581"
          d="M102.629,714.191H85.387a3.72,3.72,0,0,1-3.873-3.535V695.45a3.719,3.719,0,0,1,3.873-3.534h17.242a3.719,
          3.719,0,0,1,3.873,3.534v15.206A3.72,3.72,0,0,1,102.629,714.191ZM85.387,693.916a1.731,1.731,0,0,0-1.873,
          1.534v15.206a1.732,1.732,0,0,0,1.873,1.535h17.242a1.732,1.732,0,0,0,1.873-1.535V695.45a1.731,1.731,0,0,0-1.873-1.534Z"
          fill={fill}
        />
      </g>
    </SvgIcon>
  );
}

export default withTheme(SvgComponent);
