import { useCallback, useState } from 'react';

const useHeatmapLightbox = () => {
  const [isHeatmapLightboxOpen, setIsHeatmapLightboxOpen] = useState(false);

  const openHeatmapLightbox = useCallback(() => setIsHeatmapLightboxOpen(true), [setIsHeatmapLightboxOpen]);
  const closeHeatmapLightbox = useCallback(() => setIsHeatmapLightboxOpen(false), [setIsHeatmapLightboxOpen]);

  return {
    isHeatmapLightboxOpen,

    openHeatmapLightbox,
    closeHeatmapLightbox,
  };
};

export default useHeatmapLightbox;
