import flatten from 'lodash/flatten';

/**
 * Recursive function to get project children
 * @param project
 * @param projects
 * @return {*}
 */
export const getProjectChildren = (project, projects) => {
  const children = projects.filter(p => p.parent_id === project.id);

  return flatten([project.id, ...children.map(c => getProjectChildren(c, projects))]);
};

/**
 * Recursive function to get project parents
 *
 * @param project
 * @param projectsById
 * @return {[undefined,...[]|*]|*[]}
 */
export const getProjectParents = (project, projectsById = {}) => {
  const parent = projectsById[project.parent_id];

  if (!parent) {
    return [];
  }

  return [parent.id, ...getProjectParents(parent)];
};
