import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import OpenInNew from '@material-ui/icons/OpenInNew';

const LinkCellRenderer = ({ data, link, isVisible }) => {
  if (!link || !data?.id) {
    return null;
  }

  return isVisible ? (
    <LinkIconButton color="primary" onClick={() => link(data.id, data)}>
      <StyledOpenInNew />
    </LinkIconButton>
  ) : null;
};

export default LinkCellRenderer;

const LinkIconButton = styled(props => <IconButton {...props}>{props.children}</IconButton>)`
  &&&& {
    position: relative;
    visibility: hidden;
    padding: 3px;
    font-size: 1.125rem;
  }
`;

const StyledOpenInNew = styled(OpenInNew)`
  &&&& {
    font-size: 14px;
    width: 20px;
  }
`;
