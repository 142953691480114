import { useSelector } from 'react-redux';

import { getOrgCustomUserProjectFields, getOrgHasCustomUserProjectFields } from 'store/organization/selectors';

const useCustomUserFields = () => {
  const hasCustomUserFields = useSelector(getOrgHasCustomUserProjectFields);
  const customUserFields = useSelector(getOrgCustomUserProjectFields);

  // might need to change the way we're doing this
  // customUserFields.field.type ?
  const getCustomUserFieldByFieldKey = fieldKey => {
    if (hasCustomUserFields) return customUserFields[fieldKey];

    return false;
  };

  const getAllCustomUserFields = () => {
    if (hasCustomUserFields) return customUserFields;

    return null;
  };

  return {
    hasCustomUserFields,
    getCustomUserFieldByFieldKey,
    getAllCustomUserFields,
  };
};

export default useCustomUserFields;
