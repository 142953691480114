import React from 'react';
import styled from 'styled-components';

import NewDialog from 'design-system/organisms/NewDialog/index';

import { HeaderIconButton } from 'design-system/organisms/NewDialog';
import history from 'store/utils/history';

import Form from './Form';
import Footer from './Footer';
import Header from './Header';
import EstimatesAndAssignments from './EstimatesAndAssignments';
import { REQUESTS_DRAWER_TABS } from './constants';
import MergedRequests from './MergedRequests/MergedRequests';
import RequestsHistory from 'features/RequestsHistory';

export default props => {
  const {
    pasteListener,
    title,
    currentTab = REQUESTS_DRAWER_TABS.DETAILS,
    setCurrentTab,
    formData,
    isMergedRequest,
    customerRequest,
    ...rest
  } = props;

  const _onDetailsFocus = () => {
    if (pasteListener) pasteListener('add');
  };

  const _onDetailsBlur = () => {
    if (pasteListener) pasteListener('remove');
  };

  const _onTabChange = tab => {
    setCurrentTab(tab);
  };

  const navigateToUrlAndCloseDrawer = url => {
    if (url) {
      history.push(url);
      props.onClose();
    }
  };

  return (
    <StyledDialog
      type="DRAWER"
      withoutBackdrop
      headerComponent={Header}
      headerProps={{
        title: title || `New Customer ${props.getSystemFieldName('request')}`,
        onClickCustomFields: props.onClickCustomFields,
        showCustomFieldsButton: props.showCustomFieldsButton,
        files: props.files,
        attachFileButtonClicked: props.handleAttachFile,
        onToggleWatchStatusClick: props.onToggleWatchStatusClick,
        isWatchingStatusEnabled: props.isWatchingStatusEnabled,
        currentTab,
        onTabChange: _onTabChange,
      }}
      actions={<Footer resourceLabel={props.getSystemFieldName('request')} />}
      closeButton
      onClose={props.onClose}
      headerHeight={props.headerHeight}
      fullHeight
      {...rest}
    >
      {currentTab === REQUESTS_DRAWER_TABS.DETAILS && (
        <Form
          isMergedRequest={isMergedRequest}
          onClose={props.onClose}
          isCreating={props.isCreating}
          getFileLink={props.getFileLink}
          files={props.files}
          customFields={props.customFields}
          handleDeleteLink={props.handleDeleteLink}
          onDetailsFocus={_onDetailsFocus}
          onDetailsBlur={_onDetailsBlur}
          canAutomaticUpdate={props.canAutomaticUpdate}
          hasAutomaticUpdateConfigured={props.hasAutomaticUpdateConfigured}
          navigateToUrlAndCloseDrawer={navigateToUrlAndCloseDrawer}
        />
      )}
      {currentTab === REQUESTS_DRAWER_TABS.ESTIMATES && <EstimatesAndAssignments formData={formData} />}
      {currentTab === REQUESTS_DRAWER_TABS.MERGE && <MergedRequests customerRequest={customerRequest} />}
      {currentTab === REQUESTS_DRAWER_TABS.HISTORY && <RequestsHistory customerRequest={customerRequest} />}
    </StyledDialog>
  );
};

const StyledDialog = styled(NewDialog)`
  &&&& ${HeaderIconButton} {
    top: 18px;
  }
`;
