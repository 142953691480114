import React, { useMemo } from 'react';
import omit from 'lodash/omit';

import isEqual from 'lodash/isEqual';
import { head } from 'ramda';

import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import MuiButton from '@material-ui/core/Button';
import MuiSaveIcon from '@material-ui/icons/Save';
import Radio from '@material-ui/core/Radio';
import MenuListItem from '@material-ui/core/MenuItem';
import MuiListItemText from '@material-ui/core/ListItemText';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';

import { CHILDREN_FILTERS, DEFAULT_PLANNING_STAGES, FILTERS_FUNCTIONS } from 'constants/filters';
import AddLayerToSearch from 'containers/AddLayerToSearch';
import SavedFiltersPopper from 'design-system/organisms/SavedFiltersPopper/index';
import SaveFilterDialog from 'design-system/organisms/SaveFilterDialog/index';
import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import useDeepEffect from 'hooks/useDeepEffect';
import copyToClipboard from 'utils/copyToClipboard';

import { buildFilterLink } from 'utils/filters/buildFilterLink';

import { removeQueryParamFromUrl } from 'utils/queryParamsUtils';
import { ADD_FILTER_TITLE, OPEN_ADVANCED_SEARCH } from 'constants/queryParams';

import AdvancedQueryBuilder from 'features/DBQL/AdvancedQueryBuilder';
import getDbqlFromPortfolioFilters from 'features/DBQL/helpers/getDbqlFromPortfolioFilters';
import { AND } from 'features/DBQL/AdvancedQueryBuilder/helpers/constants';

import { FILTERS_FIELDS } from 'constants/filters/fields';
import { spacing } from 'design-system/theme';

const DISPLAY_ALL_SUBITEMS = 'Display all sub-items';
// const DISPLAY_DOD_CORP_SUBITEMS = 'Display all sub-items across {corpName}';
// const DISPLAY_ONLY_SUBITEMS_IN_THESE_CONDITIONS = 'Display only sub-items in these conditions';
const DONT_DISPLAY_SUBITEMS = "Don't display sub-items";

const addTitleFiltersFrom = (titleField, filters = {}, value, groupOperator = AND) => {
  filters.dbql = {
    ...(filters.dbql || {}),
    conditions: [
      {
        field: titleField,
        value,
        op: FILTERS_FUNCTIONS.contains.key,
        groupOperator,
      },
      ...(filters.dbql?.conditions || []),
    ],
  };
};

const getDefaultInitialConditions = ({ planningStages }) => {
  return [
    {
      field: { id: 'planningStages', ...FILTERS_FIELDS.planningStages },
      groupOperator: AND,
      op: 'in',
      value: planningStages,
    },
  ];
};

const appendInitialConditions = (pageFilters, defaultInitialConditions) => {
  return {
    ...pageFilters,
    dbql: {
      ...(pageFilters?.dbql || {}),
      conditions: pageFilters?.dbql?.conditions ?? defaultInitialConditions,
    },
  };
};

export default ({
  anchorEl,
  fields,
  setAnchorEl,
  getFieldOptions,
  pageFilters,
  applyFilters,
  userFilters,
  staticFilters,
  onSaveFilter,
  onDeleteFilter,
  onCreateFilter,
  loadUsers,
  filterToEdit,
  onSavedFilterClick,
  layerOptions,
  bottomLayer,
  topLayer,
  globalSearch,
  doSearch,
  setFilterToEdit,
  anchorOrigin,
  transformOrigin,
  hasCopyFilterLink,
  selectLayerProps = {},
  userCanEditFilter = true,
  setIsUsingDbqlAdvancedSearch,
  page,
}) => {
  const defaultInitialConditions = getDefaultInitialConditions({ planningStages: DEFAULT_PLANNING_STAGES(page) });

  const [addFilterAnchorEl, setAddFilterAnchorEl] = React.useState(null);
  const [savedFiltersAnchorEl, setSavedFiltersAnchorEl] = React.useState(null);

  const [editableItem, setEditableItem] = React.useState(null);

  const [filters, setFilters] = React.useState(appendInitialConditions(pageFilters, defaultInitialConditions));

  // this allows to wait for filters to load before displaying them
  const [filtersLoaded, setFiltersLoaded] = React.useState(false);

  const [showSaveFilterDialog, setShowSaveFilterDialog] = React.useState(false);
  const [saveCurrentFilter, setSaveCurrentFilter] = React.useState(false);
  const savedFiltersPopperRef = React.useRef(null);
  const [searchValue, setSearchValue] = React.useState(globalSearch);
  const dbqlConditions = filters?.dbql?.conditions;

  const onUpdate = changes => {
    setFilters({
      ...filters,
      dbql: {
        ...filters?.dbql,
        ...changes,
      },
    });
  };

  const actionRef = React.useRef();

  const currentLayer = useMemo(() => {
    // carry over the selection from advanced search
    return filters?.dbql?.layer || filters?.layer || topLayer;
  }, [filters?.dbql?.layer, topLayer]);

  const _onShowSavedFilters = e => setSavedFiltersAnchorEl(savedFiltersAnchorEl ? null : e.currentTarget);

  const handleClosePopover = () => {
    setAnchorEl(null);
    removeQueryParamFromUrl(OPEN_ADVANCED_SEARCH);
    removeQueryParamFromUrl(ADD_FILTER_TITLE);
  };

  const _onCancel = () => {
    appendInitialConditions(pageFilters, defaultInitialConditions);
    handleClosePopover();
  };
  const _onApply = () => {
    const filterState = filterToEdit ? filterToEdit.state : {};
    const filterId = filterToEdit && isEqual(filterState, filters) ? filterToEdit.id : null;

    // build string
    const mainQueryString = getDbqlFromPortfolioFilters(filters);

    applyFilters({ ...filters, dbql: { ...filters?.dbql, mainQueryString } }, filterId);
    doSearch(searchValue || '');
    handleClosePopover();
  };
  const _onClickAway = e => {
    _onCancel();
  };

  const _onClickAwaySavedFiltersPopper = e => {
    if (e.composedPath().includes(savedFiltersAnchorEl)) return;

    setSavedFiltersAnchorEl(null);
  };
  const _onClickSaveEditedFilter = () => {
    setSaveCurrentFilter(true);
    setShowSaveFilterDialog(true);
  };

  const _onDeleteCurrentFilter = () => {
    onDeleteFilter(filterToEdit.id);
    _onCancel();
  };

  const _updateUsersOptions = async (filter, field) => {
    if (!pageFilters[filter][field] || !anchorEl || !loadUsers) return null;

    const users = await loadUsers(pageFilters[filter][field].map(u => (u.id ? u.id : u)));

    return users;
  };

  const _onSelectLayer = layer => {
    setFilters({ ...filters, layer, dbql: { ...filters?.dbql, layer } });
  };

  const _onSelectSecondLayer = layer => {
    setFilters({ ...filters, secondLayer: layer, dbql: { ...filters?.dbql, secondLayer: layer } });
  };

  const _onSelectChildren = (childrenValue, otherOpts = {}) => {
    setFilters({
      ...filters,
      children: childrenValue,
      dbql: {
        ...filters.dbql,
        ...otherOpts,
        loadChildrenType: childrenValue,
      },
    });
  };

  React.useEffect(() => {
    if (addFilterAnchorEl) setAddFilterAnchorEl(null);
    if (savedFiltersAnchorEl) setSavedFiltersAnchorEl(null);

    // closed the popup, set filters as not loaded
    if (!anchorEl) setFiltersLoaded(false);
  }, [!anchorEl]);

  const urlParams = new URLSearchParams(window.location.search);
  const openAdvancedSearchParam = urlParams.get(OPEN_ADVANCED_SEARCH);
  const addFilterTitle = urlParams.get(ADD_FILTER_TITLE);

  useDeepEffect(() => {
    const _updateFilters = async () => {
      const newFilters = appendInitialConditions(pageFilters, defaultInitialConditions);
      const usersFilter = await _updateUsersOptions('fields', 'users');
      const createdByFilter = await _updateUsersOptions('fields', 'created_by');

      if (usersFilter) newFilters.fields.users = usersFilter;
      if (createdByFilter) newFilters.fields.created_by = createdByFilter;

      const childrenUsersFilter = await _updateUsersOptions('childrenFields', 'users');
      const childrenCreatedByFilter = await _updateUsersOptions('childrenFields', 'created_by');

      if (childrenUsersFilter) newFilters.childrenFields.users = childrenUsersFilter;
      if (childrenCreatedByFilter) newFilters.childrenFields.created_by = childrenCreatedByFilter;

      if (openAdvancedSearchParam && addFilterTitle) {
        const titleField = fields.find(field => field?.id === 'title');
        const groupOperator = head(dbqlConditions)?.groupOperator;

        addTitleFiltersFrom(titleField, newFilters, addFilterTitle, groupOperator);
      }

      setFilters(newFilters);
    };

    _updateFilters();

    const isVisible = !!anchorEl;

    if (isVisible) {
      // if is visible and went through this, filter is ready to be loaded
      // make sure it runs after when the filter is actually loaded to ensure
      // any transformation applied afterwards is not overwritten
      setTimeout(() => setFiltersLoaded(true), 0);
    }
  }, [pageFilters, !anchorEl, openAdvancedSearchParam]);

  React.useEffect(() => {
    setSearchValue(globalSearch);
  }, [globalSearch]);

  if (!anchorEl) return '';

  const _onEditFilter = filter => {
    setFilterToEdit(filter);
    setSavedFiltersAnchorEl(null);
  };

  const _onCopyFilterLink = filter => {
    _onCancel();

    /**
     * The setTimeout is required because the hidden textarea used to select and
     * copy the text to the clipboard (check copyToClipboard function) seems to
     * have a conflit with the Popover used to show the saved filters sub-menu.
     * For that reason it is required to:
     *  - call popover close (_onCancel)
     *  - wrap copyToClipboard with setTimeout to use the next render cycle
     */
    setTimeout(async () => {
      const link = buildFilterLink(filter.key);

      await copyToClipboard(link);
    }, 1);
  };

  const renderSavedFiltersSubMenu = item => {
    return (
      <>
        <ListItem onClick={() => setEditableItem(item)}>
          <ListItemText>Rename</ListItemText>
        </ListItem>
        <ListItem onClick={() => _onEditFilter(item)}>
          <ListItemText>Edit</ListItemText>
        </ListItem>
        <ListItem onClick={() => onDeleteFilter(item.id)}>
          <ListItemText>Delete</ListItemText>
        </ListItem>
        {hasCopyFilterLink && (
          <ListItem onClick={() => _onCopyFilterLink(item)}>
            <ListItemText>Copy filter link</ListItemText>
            <MuiListItemIcon>
              <ShareIcon fontSize="small" />
            </MuiListItemIcon>
          </ListItem>
        )}
      </>
    );
  };

  const renderLayerSelect = () => (
    <AddFirstLayer
      disabled={selectLayerProps.disabled}
      layer={currentLayer}
      bottomLayer={bottomLayer}
      secondLayer={filters?.dbql?.secondLayer}
      options={layerOptions}
      onSelectLayer={_onSelectLayer}
      onSelectSecondLayer={_onSelectSecondLayer}
    />
  );

  const renderChildrenSelect = () => {
    const shouldNotRenderChildrenSelect = filters?.dbql?.layer === bottomLayer;

    if (shouldNotRenderChildrenSelect) {
      return;
    }

    const selectedValue = filters?.dbql?.loadChildrenType || CHILDREN_FILTERS.noChildren;

    return (
      <Line>
        <Radio
          color="primary"
          checked={selectedValue === CHILDREN_FILTERS.allChildren}
          onChange={() => _onSelectChildren(CHILDREN_FILTERS.allChildren)}
        />
        <TextDeprecated>{DISPLAY_ALL_SUBITEMS}</TextDeprecated>
        <Radio
          color="primary"
          checked={selectedValue === CHILDREN_FILTERS.noChildren}
          onChange={() => _onSelectChildren(CHILDREN_FILTERS.noChildren)}
        />
        <TextDeprecated>{DONT_DISPLAY_SUBITEMS}</TextDeprecated>
      </Line>
    );
  };

  const renderSavedFiltersPopper = () => (
    <SavedFiltersPopper
      anchorEl={savedFiltersAnchorEl}
      onClickAway={_onClickAwaySavedFiltersPopper}
      staticFilters={staticFilters}
      myFilters={userFilters}
      setRef={r => (savedFiltersPopperRef.current = r)}
      editableItem={editableItem}
      onSetEditableItem={setEditableItem}
      onSaveFilter={(id, name) => onSaveFilter(id, name)}
      onFilterClick={f => {
        onSavedFilterClick(f);
        setSavedFiltersAnchorEl(null);
        handleClosePopover();
      }}
      renderSubMenu={renderSavedFiltersSubMenu}
    />
  );

  const renderSaveFilterDialog = () => (
    <SaveFilterDialog
      filterName={filterToEdit && filterToEdit.name}
      showDialog={showSaveFilterDialog}
      onClose={() => setShowSaveFilterDialog(false)}
      onSave={name => {
        if (filterToEdit && (name === filterToEdit.name || saveCurrentFilter)) {
          onSaveFilter(filterToEdit.id, name, filters);
          setSaveCurrentFilter(false);
        } else {
          onCreateFilter(name, filters);
        }
      }}
    />
  );

  const renderFooter = () => {
    const shouldShowSaveFilterButton = !!filters?.dbql && userCanEditFilter;
    const shouldShowSavedFiltersButton =
      userCanEditFilter && ((userFilters && !!userFilters.length) || (staticFilters && !!staticFilters.length));

    return (
      <ButtonsContainer>
        {filterToEdit && (
          <Button color="secondary" onClick={_onDeleteCurrentFilter}>
            Delete this filter
          </Button>
        )}
        {shouldShowSavedFiltersButton && <Button onClick={_onShowSavedFilters}>Saved Filters</Button>}
        <ActionsButtonsContainer>
          {shouldShowSaveFilterButton && (
            <Button color="primary" onClick={() => setShowSaveFilterDialog(true)}>
              <SaveIcon />
              {filterToEdit ? 'Save' : 'Save active filter'}
            </Button>
          )}
          {userCanEditFilter && (
            <Button onClick={_onApply} color="primary">
              Apply
            </Button>
          )}
          <Button onClick={_onCancel}>Cancel</Button>
        </ActionsButtonsContainer>
      </ButtonsContainer>
    );
  };

  return (
    <React.Fragment>
      {filtersLoaded && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          action={r => (actionRef.current = r)}
          style={{ zIndex: 1000 }}
          onClose={_onClickAway}
          anchorOrigin={
            anchorOrigin || {
              vertical: 'bottom',
              horizontal: 'left',
            }
          }
          transformOrigin={
            transformOrigin || {
              vertical: 'top',
              horizontal: 'left',
            }
          }
        >
          <FiltersWrapper>
            <HeaderWrapper>
              <FiltersHeader
                handleClickFilterName={() => (filterToEdit ? _onClickSaveEditedFilter() : null)}
                savedFilter={filterToEdit}
              >
                {filterToEdit ? `${filterToEdit.name}` : 'New search (Beta)'}
              </FiltersHeader>
              <ButtonWrapper>
                <ProvideFeedbackButton id="search_feedback">Provide feedback</ProvideFeedbackButton>
              </ButtonWrapper>
              <ButtonWrapper>
                <DBQLButton color="primary" onClick={() => setIsUsingDbqlAdvancedSearch(false)}>
                  Back to current search experience
                </DBQLButton>
              </ButtonWrapper>
            </HeaderWrapper>
            {renderLayerSelect()}
            <StyledAdvancedQueryBuilder
              allFields={fields}
              getFieldOptions={getFieldOptions}
              onUpdateConditions={onUpdate}
              conditions={dbqlConditions}
            />
            {renderChildrenSelect()}
            {renderFooter()}
            {renderSavedFiltersPopper()}
            {renderSaveFilterDialog()}
          </FiltersWrapper>
        </Popover>
      )}
    </React.Fragment>
  );
};

const FiltersWrapper = styled(Paper)`
  &&&& {
    position: relative;
    width: 100%;
    min-width: 650px;
  }
`;

const AddFirstLayer = styled(AddLayerToSearch)`
  &&&&&& {
    width: 600px;
    padding: 0 ${spacing(4)}px;
  }
`;

const Button = styled(MuiButton)``;

const SaveIcon = styled(MuiSaveIcon)`
  &&& {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px 10px;
  border-top: 1px solid #fafafa;
  box-shadow: 0px -2px 12px 0px rgb(204 204 204 / 30%);
`;

const ActionsButtonsContainer = styled.div`
  margin-left: auto;
  padding-left: 50px;
`;

const FiltersHeader = styled(props => (
  <div {...omit(props, ['children'])}>
    {props.savedFilter && <Typography variant="filtersTitle">Saved filter / &nbsp;</Typography>}
    <StyledTypography onClick={props.handleClickFilterName} savedFilter={props.savedFilter} variant="filtersTitle">
      {props.children}
    </StyledTypography>
  </div>
))`
  &&&& {
    display: flex;
    justify-content: left;
    flex-shrink: 0;
    padding: 20px 18px 10px;
    // text-transform: uppercase;
    white-space: nowrap;

    span {
      font-size: 1.1rem;
      font-weight: ${({ theme }) => theme.typography.fontWeightBold};
    }

    span:first-child {
      color: ${({ theme }) => theme.palette.newLayout.text.labelDisabled};
    }
  }
`;

const StyledTypography = styled(Typography)`
  &&&& {
    ${props =>
      props.savedFilter &&
      `
        &:hover {
          cursor: pointer;
        }
     `}
  }
`;

const Line = styled.div`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 ${spacing(2)}px;
  }
`;

const ListItemText = styled(MuiListItemText)`
  &&&& {
    margin: 0;

    ${({ uppercase }) =>
      uppercase &&
      `
      text-transform: uppercase;
    `}
    span {
      font-size: ${props => props.theme.typography.fontSize}px;
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      color: ${props => props.theme.palette.text.lightGrey};
    }
  }
`;

const ListItem = styled(MenuListItem)`
  &&&& {
    height: 18px;

    &:hover {
      background-color: #00000014;

      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      ${ListItemText} {
        color: #2ea8e1;
      }
    }
  }
`;

const DBQLButton = styled(Button)`
  &&&& {
    margin-right: ${spacing(1)}px;
  }
`;

const StyledAdvancedQueryBuilder = styled(AdvancedQueryBuilder)`
  padding: 0 ${spacing(3)}px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ButtonWrapper = styled.div`
  padding: ${spacing(1.5)}px 0;
`;
const ProvideFeedbackButton = styled(Button)`
  &&&& {
    color: ${({ theme }) => theme.palette.text.accent};
  }
`;
