import reduceReducers from 'reduce-reducers';
import { FETCH_LIFECYCLES, FETCH_LIFECYCLES_FULFILLED } from './types';
import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';
import adapter from './entityAdapter';
import {
  DELETE_GRID_LIFECYCLE_FULFILLED,
  SAVE_GRID_NEW_LIFECYCLE_FULFILLED,
  SWITCH_LIFECYCLES_GRID_ROW_ORDER_FULFILLED,
  UPDATE_GRID_LIFECYCLE_FULFILLED,
} from 'features/LifecyclesGrid/store/types';

const initialState = {
  ...adapter.getInitialState(),
  operations: bulkThunkInitialState([FETCH_LIFECYCLES]),
};

const lifecyclesDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIFECYCLES_FULFILLED:
      return adapter.setAll(state, action.payload);
    case UPDATE_GRID_LIFECYCLE_FULFILLED:
    case SWITCH_LIFECYCLES_GRID_ROW_ORDER_FULFILLED:
      return adapter.upsertOne(state, action.payload);
    case DELETE_GRID_LIFECYCLE_FULFILLED:
      return adapter.removeOne(state, action.payload);
    case SAVE_GRID_NEW_LIFECYCLE_FULFILLED:
      return adapter.addOne(state, action.payload);
    default: {
      return state;
    }
  }
};

const operationsReducer = getThunksReducers([FETCH_LIFECYCLES]);

const reducer = reduceReducers(initialState, lifecyclesDataReducer, ...operationsReducer);

export default reducer;
