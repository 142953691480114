import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import MetadataTreeDropdown from 'containers/MetadataTreeDropdown';
import useSystemFields from 'hooks/useSystemFields';
import { getOrgHasTeamsLevelTwo as getOrgHasTeamsLevelTwoSelector } from 'store/organization/selectors';

const TeamField = props => {
  const { teams, estimate, index, userCanEdit, onUpdateEstimate } = props;

  const [inputRef, setInputRef] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const hasTeamsLevelTwo = useSelector(getOrgHasTeamsLevelTwoSelector);
  const [getSystemFieldName] = useSystemFields();

  const selectedItems = useMemo(() => teams.filter(team => team.title === estimate?.team?.title), [teams, estimate?.team?.title]);

  const selectedTeamName = useMemo(() => {
    if (estimate?.team2?.title) return estimate.team2.title;
    if (estimate?.team?.title) return estimate.team.title;

    return '';
  }, [estimate?.team?.title, estimate?.team2?.title]);

  const onOpenDropdownHandler = useCallback(() => {
    if (userCanEdit) setIsEditing(true);
  }, [userCanEdit]);

  const onUpdateTeamHandler = useCallback(
    value => {
      if (onUpdateEstimate) onUpdateEstimate(index, 'team', value ? { id: value.id, title: value.title } : undefined);

      setIsEditing(false);
    },
    [index, onUpdateEstimate],
  );

  return (
    <>
      <TextField
        inputRef={node => setInputRef(node)}
        label={getSystemFieldName('team')}
        value={selectedTeamName}
        margin="normal"
        required
        disabled={!userCanEdit}
        InputLabelProps={{ shrink: true }}
        onClick={onOpenDropdownHandler}
      />
      <MetadataTreeDropdown
        inputRef={inputRef}
        isOpen={isEditing}
        items={teams}
        selectedItems={selectedItems}
        onChange={onUpdateTeamHandler}
        onClose={() => setIsEditing(false)}
        hasClearSelection
        hasMultiLevel={hasTeamsLevelTwo}
        hasShowArchived
      />
    </>
  );
};

export default TeamField;
