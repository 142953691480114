import { useCallback } from 'react';
import { pluck } from 'ramda';
import { useDispatch } from 'react-redux';

import { updateProjectPersonas as updateProjectPersonasAction } from 'store/projects/thunks';

const ID_KEY = 'id';
const mapObjectsToIds = pluck(ID_KEY);

/**
 * @function useUpdateProjectPersonas
 *
 * hook that returns actions to update project Personas
 *
 * @return {Object}
 */
const useUpdateProjectPersonas = () => {
  const dispatch = useDispatch();

  const updateProjectPersonas = useCallback(
    (id, newValue, oldValue) => {
      const oldValueIds = mapObjectsToIds(oldValue);
      const newValueIds = mapObjectsToIds(newValue);

      dispatch(updateProjectPersonasAction(id, newValueIds, oldValueIds));
    },
    [dispatch, updateProjectPersonasAction],
  );

  return { updateProjectPersonas };
};

export default useUpdateProjectPersonas;
