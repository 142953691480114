import { useMemo } from 'react';

import { FORECAST_BY_HEADCOUNT, FORECAST_BY_TIME, FORECAST_BY_TIME_AND_CAPACITY_OPTIONS } from 'constants/forecast';
import { getEstimateColIdByTeamSkill } from 'utils/estimates';

import { getEstimateCellValueBasedOnForecastBy } from '../helpers';
import { allPass, defaultTo, equals, filter, isNil, pipe, prop, uniq } from 'ramda';
import isBoolean from 'lodash/isBoolean';
import { INCLUDE_ALL_OPTION } from 'constants/projects';
import { estimateRounder } from 'utils/estimates/unitConversion';

const HAVE_MULTIPLE_ESTIMATES_ALERT = 'You have multiple entries for this estimate, please edit all estimates on Forecast page.';

const checkIsForecastByHeadCount = equals(FORECAST_BY_HEADCOUNT);

// if the prop was saved with true/false the default should be 'Include All' option
const getShowUncommittedProjectsWithRetroCompatibility = showUncommittedProjects =>
  isBoolean(showUncommittedProjects) ? INCLUDE_ALL_OPTION : showUncommittedProjects;

/**
 *
 * @param {*} projects
 */
export const getEstimatesData = (projects, forecastBy = FORECAST_BY_TIME) => {
  if (!projects) {
    return;
  }

  // map all projects to get estimates for each one
  const projectsWithEst = projects.map(cur => {
    const currClone = { ...cur };

    // if dont have estimates returns de current data
    if (!cur || !cur.estimates) {
      return currClone;
    }
    const estimates = cur.estimates.filter(est => est.team && est.skill);

    // convert project estimates to each field on data row
    estimates.forEach(est => {
      if (!est.team || !est.skill) {
        return;
      }
      const colId = getEstimateColIdByTeamSkill(est.team.id, est.skill.id);

      currClone[colId] = {
        value: getEstimateCellValueBasedOnForecastBy(est.duration, est.numStaff, currClone[colId], forecastBy),
        showLink: true,
        hideInput: !!currClone[colId],
        alert: currClone[colId] ? HAVE_MULTIPLE_ESTIMATES_ALERT : '',
        ...(FORECAST_BY_TIME_AND_CAPACITY_OPTIONS.includes(forecastBy) ? { showLinkOnMount: est.numStaff > 1 } : {}),
        teamId: est.team.id,
        skillId: est.skill.id,
      };
    });

    // add addictional data to the row
    currClone.estimates = estimates.map(est => ({
      ...est,
      ...{
        teamName: est.team ? est.team.title : '',
        skillName: est.skill ? est.skill.title : '',
        projectId: cur.id,
      },
    }));

    return currClone;
  });

  return projectsWithEst;
};

const isProject = pipe(prop('group'), isNil);

const excludeEstimatesWithNoTeamSkill = filter(allPass([prop('team'), prop('skill')]));

const defaultToEmptyString = defaultTo('');
const defaultToEmptyArray = defaultTo([]);

const getEstimateWithNames = estimate => ({
  ...estimate,
  teamName: defaultToEmptyString(estimate?.team?.title),
  skillName: defaultToEmptyString(estimate?.skill?.title),
});

const getEstimates = pipe(prop('estimates'), defaultToEmptyArray);
const getEstimatesColIds = pipe(prop('estimatesColIds'), defaultToEmptyArray);
const defaultToZero = defaultTo(0);

const groomProjectWithTotalsFromEstimate = (estimate, project, forecastBy) => {
  const colId = getEstimateColIdByTeamSkill(estimate.team.id, estimate.skill.id);

  const sum = getEstimateCellValueBasedOnForecastBy(estimate.duration, estimate.numStaff, project[colId], forecastBy);
  const estimatePoints = estimate.story_points;
  const sumEstimatePoints = estimatePoints + defaultToZero(project[colId]?.sumEstimatePoints);

  return {
    ...project,
    estimates: [...getEstimates(project), getEstimateWithNames(estimate)],
    estimatesColIds: uniq([...getEstimatesColIds(project), colId]),
    [colId]: {
      value: sum,
      sum,
      showLink: true,
      hideInput: !!project[colId],
      alert: project[colId] ? HAVE_MULTIPLE_ESTIMATES_ALERT : '',
      ...(FORECAST_BY_TIME_AND_CAPACITY_OPTIONS.includes(forecastBy) ? { showLinkOnMount: estimate.numStaff > 1 } : {}),
      teamId: estimate.team.id,
      skillId: estimate.skill.id,
      estimatePoints,
      sumEstimatePoints,
    },
  };
};

const groomGroupWithTotalsFromChild = (child, group, forecastBy, includeCommittedSelectionOption) => {
  const colIds = getEstimatesColIds(child);

  const selectedOption = getShowUncommittedProjectsWithRetroCompatibility(includeCommittedSelectionOption);
  const isForecastByHeadCount = checkIsForecastByHeadCount(forecastBy);
  const timeAndCapacityProjectIncluded = !isForecastByHeadCount && (selectedOption === INCLUDE_ALL_OPTION || child?.committed);
  const headcountProjectIncluded = isForecastByHeadCount && child?.includeInSums;
  const considerValueForSum = timeAndCapacityProjectIncluded || headcountProjectIncluded;

  return colIds.reduce(
    (group, eachColId) => ({
      ...group,
      estimatesColIds: uniq([...getEstimatesColIds(group), eachColId]),
      [eachColId]: {
        sum: estimateRounder.rounder(
          considerValueForSum
            ? defaultToZero(group[eachColId]?.sum) + defaultToZero(child?.[eachColId]?.sum)
            : defaultToZero(group[eachColId]?.sum),
        ),
        sumEstimatePoints: considerValueForSum
          ? defaultToZero(group[eachColId]?.sumEstimatePoints) + defaultToZero(child?.[eachColId]?.sumEstimatePoints)
          : defaultToZero(group[eachColId]?.sumEstimatePoints),
      },
    }),
    group,
  );
};

const getEstimateTotal = (
  groupOrProject,
  forecastBy = FORECAST_BY_TIME,
  includeCommittedSelectionOption = INCLUDE_ALL_OPTION,
) => {
  if (!groupOrProject) return {};

  if (isProject(groupOrProject)) {
    const project = groupOrProject;

    if (!project || !prop('estimates', project)) return project;

    return excludeEstimatesWithNoTeamSkill(project.estimates).reduce(
      (projectWithTotals, eachEstimate) => groomProjectWithTotalsFromEstimate(eachEstimate, projectWithTotals, forecastBy),
      {
        ...project,
        estimates: [],
      },
    );
  }

  const group = groupOrProject;

  const elements = group.elements.map(eachElement => getEstimateTotal(eachElement, forecastBy));

  return elements.reduce(
    (group, eachElement) => groomGroupWithTotalsFromChild(eachElement, group, forecastBy, includeCommittedSelectionOption),
    { ...group, elements },
  );
};

/**
 * @function useProjectsWithEstimates
 *
 * hook that merges the current projects data will all estimates and parsed for the ag grid
 *
 * @param  {Array} projects
 * @param  {Object} options
 * @param  {Object} options.forecastBy
 * @return {Array}
 */
const useProjectsWithEstimates = (projectsAndGroups, { forecastBy, includeCommittedSelectionOption }) => {
  const data = useMemo(
    () => projectsAndGroups.map(eachProject => getEstimateTotal(eachProject, forecastBy, includeCommittedSelectionOption)),
    [projectsAndGroups, forecastBy],
  );

  return data;
};

export default useProjectsWithEstimates;
