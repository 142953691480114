import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  formStyles: {
    paddingTop: 40,
    paddingBottom: 40,
    width: 400,
    flexDirection: 'column',
    marginTop: -100,
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    filter: `drop-shadow(2px 2px 5px ${theme.palette.border.mercury})`,
  },
});

export default withStyles(styles)(props => {
  return <div className={props.classes.formStyles}>{props.children}</div>;
});
