import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Rnd } from 'react-rnd';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import theme, { spacing } from 'design-system/theme';

import isClickEventNoDragEnd from 'utils/timeline/isClickEventNoDragEnd';

import TooltipContent from './TooltipContent';
import { MIN_BAR_WIDTH } from './constants';
import useTableBarActionHandlers from 'design-system/organisms/RoadmapTable/hooks/useTableBarActionHandlers';

const TEXT_COLOR = theme.palette.text.primary;

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    background: theme.palette.white,
    maxWidth: spacing(43.75),
    maxHeight: '100%',
    borderRadius: 0,
    border: `1px solid ${theme.palette.border.mercury}`,
    padding: spacing(1.75),
    opacity: 1,
  },
}))(Tooltip);

const SwimlaneVerticalBar = ({
  id,
  title,
  name,
  color,
  borderColor,
  startDate,
  endDate,
  predictedEndDate,
  progress,
  top,
  left,
  width,
  height,
  noMargin,
  disableDragX = false,
  disableDragY = false,
  boundsname,
  openBody,
  openLightBox,
  onDoubleClick,
  onDrag,
  onDragStop,
  onResizeStop,
  onResize,
  isLoading,
  showTooltip,
  showTextOverflow,
  resizeGrid,
  dragGrid,
  slotWidth,
}) => {
  const [open, setOpen] = useState(false);

  const style = {
    zIndex: '50',
  };

  const dragAxis = useMemo(() => {
    if (isLoading) {
      return 'none';
    }

    if (!disableDragX && !disableDragY) return 'both';
    if (!disableDragX) return 'x';
    if (!disableDragY) return 'y';

    return 'none';
  }, [disableDragX, disableDragY, isLoading]);

  const {
    onOpenTooltip,
    onCloseTooltip,
    handleDragStop,
    handleDrag,
    handleResizeStop,
    handleResize,
    handleClick,
    handleDoubleClick,
  } = useTableBarActionHandlers(
    id,
    left,
    top,
    setOpen,
    isClickEventNoDragEnd,
    onDragStop,
    onDrag,
    onResizeStop,
    onResize,
    openBody,
    openLightBox,
    onDoubleClick,
  );

  const _width = slotWidth < MIN_BAR_WIDTH ? MIN_BAR_WIDTH : slotWidth;

  return (
    <Rnd
      style={style}
      default={{
        x: left,
        y: top,
        width: _width,
        height,
      }}
      position={{
        x: left,
        y: top,
      }}
      size={{
        width: _width,
        height,
      }}
      enableResizing={{
        left: false,
        right: false,
      }}
      disableDragging={isLoading || (disableDragX && disableDragY)}
      dragGrid={dragGrid}
      resizeGrid={resizeGrid}
      bounds={boundsname ? `.${boundsname}` : ''}
      dragAxis={dragAxis}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
      onDragStart={onCloseTooltip}
      onDragStop={handleDragStop}
      onDrag={handleDrag}
      onResizeStart={onCloseTooltip}
      onResizeStop={handleResizeStop}
      onResize={handleResize}
    >
      <HtmlTooltip
        open={showTooltip && open}
        onOpen={onOpenTooltip}
        onClose={onCloseTooltip}
        title={
          <TooltipContent
            name={name}
            title={title}
            startDate={startDate}
            endDate={endDate}
            predictedEndDate={predictedEndDate}
            progress={progress}
          />
        }
      >
        <BarWrapper style={{ marginTop: noMargin ? 0 : 6 }} width={_width}>
          <BarProgress borderColor={borderColor} />
          <BarContent showTextOverflow={showTextOverflow}>{title}</BarContent>
        </BarWrapper>
      </HtmlTooltip>
    </Rnd>
  );
};

export default SwimlaneVerticalBar;

const BarWrapper = styled.div`
  &&&& {
    height: ${spacing(3.5)}px;
    width: ${({ width }) => width}px;
    box-sizing: border-box;

    margin-top: ${spacing(1)}px;

    display: flex;
    align-items: center;
    justify-content: start;

    cursor: pointer;

    color: ${TEXT_COLOR};
  }
`;

const BarProgress = styled.div`
  position: absolute;
  left: 0;

  width: ${spacing(0.65)}px;
  height: ${spacing(3.5)}px;

  background-color: ${({ borderColor }) => borderColor};
`;

const BarContent = styled.p`
  &&&& {
    max-width: 100%;

    margin-left: ${spacing(1)}px;

    ${({ showTextOverflow }) =>
      !showTextOverflow &&
      `
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;      
    `}

    ${({ showTextOverflow }) =>
      showTextOverflow &&
      `
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-height: ${spacing(1.75)}px; /* fallback */
      max-height: ${spacing(3.5)}px; /* fallback */
    `}

    user-select: none;

    font-size: ${spacing(1.5)}px;
    font-stretch: 100%;
    font-weight: normal;

    color: ${TEXT_COLOR};
  }
`;
