// Code copied from useProgressBarValues
import { defaultTo } from 'ramda';

const defaultToZero = defaultTo(0);

const convertStringToNumber = number => {
  number = number.replaceAll(',', '').replaceAll('%', '');

  return Number(number);
};

const formatNumberWithCommas = x => {
  if (!x && x !== 0) return '';

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const getFormattedMetricProgressValues = (actualValue, targetValue, baselineValue) => {
  let percentageValue = 0;
  let formattedActualValue = defaultToZero(actualValue);
  let formattedBaselineValue = defaultToZero(baselineValue);
  let formattedTargetValue = defaultToZero(targetValue);

  if (typeof formattedActualValue === 'string') formattedActualValue = convertStringToNumber(formattedActualValue);
  if (typeof formattedBaselineValue === 'string') formattedBaselineValue = convertStringToNumber(formattedBaselineValue);
  if (typeof formattedTargetValue === 'string') formattedTargetValue = convertStringToNumber(formattedTargetValue);

  const rangePercentage = formattedTargetValue - formattedBaselineValue;
  const value = Math.max(formattedActualValue - formattedBaselineValue, 0);

  if (value && rangePercentage) {
    percentageValue = (value * 100) / rangePercentage;
  }

  return {
    percentageValue,
    formattedTargetValue: formatNumberWithCommas(formattedTargetValue),
    formattedActualValue: formatNumberWithCommas(formattedActualValue),
    formattedBaselineValue: formatNumberWithCommas(formattedBaselineValue),
  };
};

export default getFormattedMetricProgressValues;
