import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import DragonSelectButton from 'design-system/atoms/DragonSelectButton/index';
import Autocomplete from 'design-system/atoms/Autocomplete/index';
import InlineAutoCompleteInput from 'design-system/atoms/InlineAutoCompleteInput/index';

import SubNavigationTabs from 'design-system/organisms/SubNavigationTabs';
import theme, { spacing } from 'design-system/theme';

import { setAppGlobalConfig } from 'store/app';
import history from 'store/utils/history';
import { FILTERS_FIELDS } from 'constants/filters/fields';
import { getPathWithoutLeadingSlash } from 'utils/index';

import SelectRoadmapVersionsPopover from 'containers/SelectRoadmapVersionsPopover/SelectRoadmapVersionsPopover';
import FilterButtonComponent from 'containers/PageHeaderFilters/components/FilterButtonComponent';
import TotalCountComponent from 'containers/PageHeaderFilters/components/TotalCountComponent';
import AdvancedSearchPopover from 'containers/AdvancedSearchPopover';

import useSystemFields from 'hooks/useSystemFields';
import useApplicationRoutes from 'hooks/useApplicationRoutes';
import useSelectCompareScenariosBaseVersion from './hooks/useSelectCompareScenariosBaseVersion';
import useLoadProjectsForScenarioVersionComparison from '../../hooks/useLoadProjectsForScenarioVersionComparison';
import usePreSelectedVersions from 'routes/RoadmapVersions/hooks/usePreSelectedVersions';
import useCompareScenariosFilters from './hooks/useCompareScenariosFilters';
import useRoadmapVersions from 'hooks/useRoadmapVersions';

import { COMPARE_SCENARIOS_FILTER } from 'constants/filters/pages';
import { isLoadingProjects } from 'store/projects';
import getFiltersCounter from 'utils/filters/getFiltersCounter';
import ModulesMenu from 'components/ModulesMenu';

import CompareScenariosToolbarControls from './components/CompareScenariosToolbarControls';

const COMPARE_SCENARIOS_VIEWS = [
  {
    path: 'compare-scenarios/list',
    label: 'List',
  },
  {
    path: 'compare-scenarios/swimlane',
    label: 'Swimlane',
  },
  {
    path: 'compare-scenarios/summary',
    label: '3D Dynamic',
  },
  {
    path: 'compare-scenarios/charts',
    label: 'Charts',
  },
];

const selectBaseVersionIconStyle = { padding: 0, width: 'auto', top: -3 };
const selectBaseVersionInputProps = {
  useEllipsis: true,
  style: {
    fontSize: '14px ',
  },
  containerStyles: {
    maxWidth: 350,
  },
};

const ICON_MODULE_PURPLE = theme.palette.text.purple;
const ICON_MODULE_BLUE = theme.palette.newLayout.background.lightPrimary;
const ICON_MODULE_BACKGROUND = `linear-gradient(to bottom right, ${ICON_MODULE_PURPLE}, ${ICON_MODULE_BLUE});`;

const HeaderVersionsSelection = () => {
  const dispatch = useDispatch();

  const [showSelectScenariosList, setShowSelectScenariosList] = useState(false);

  const selectScenarioButton = useRef(null);

  const [getSystemFieldName] = useSystemFields();

  const [pages] = useApplicationRoutes();

  usePreSelectedVersions();
  useLoadProjectsForScenarioVersionComparison();
  const { isRoadmapVersionsLoading, isFetchMultipleRoadmapVersionsProjectsLoading } = useRoadmapVersions();
  const isLoadingProjectsStore = useSelector(isLoadingProjects);

  const isLoading = isRoadmapVersionsLoading || isFetchMultipleRoadmapVersionsProjectsLoading || isLoadingProjectsStore;

  const lastView = useSelector(state => state.app?.lastPagesView['compare-scenarios']);

  const path = window.location.pathname;
  const myLastView = path === '/compare-scenarios' && lastView ? `/compare-scenarios/${lastView}` : path;

  const { availableScenarioOptions, selectedBaseVersion, handleBaseVersionChange } = useSelectCompareScenariosBaseVersion();

  const _onOpenSelectScenariosList = () => setShowSelectScenariosList(true);
  const _onCloseSelectScenariosList = () => setShowSelectScenariosList(false);

  const subNavigationTabs = useMemo(() => COMPARE_SCENARIOS_VIEWS.filter(o => !!pages.find(p => p.path === o.path)), [pages]);

  const selectedSubNavigationTab = useMemo(() => getPathWithoutLeadingSlash(myLastView), [myLastView]);

  const setSelectedPage = useCallback(path => {
    const params = new URLSearchParams(window.location.search);

    history.push(`/${path}?${params.toString()}`);
    const pathParts = path.split('/');

    const selectedPage = pathParts.length > 1 ? pathParts[1] : '';

    dispatch(setAppGlobalConfig({ lastPagesView: { 'compare-scenarios': selectedPage } }));
  }, []);

  const renderSubNavigation = () => (
    <>
      {subNavigationTabs && (
        <StyledSubNavigationTabs
          items={subNavigationTabs}
          selected={selectedSubNavigationTab}
          handleNavigationChange={setSelectedPage}
        />
      )}
    </>
  );

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  const { pageFilters, fields, total } = useCompareScenariosFilters();

  const renderFilters = () => (
    <SpaceContainer>
      <FilterButtonComponent
        badgeContent={getFiltersCounter(pageFilters, fields)}
        onClick={event => {
          if (filterAnchorEl === null) setFilterAnchorEl(event.currentTarget);
        }}
      />
      <TotalCountComponent total={total} hasLoaded={!isLoading} />
    </SpaceContainer>
  );

  const renderAdvancedSearchPopover = () => {
    return (
      <AdvancedSearchPopover
        anchorEl={filterAnchorEl}
        setAnchorEl={el => setFilterAnchorEl(el)}
        page={COMPARE_SCENARIOS_FILTER}
        availableFields={FILTERS_FIELDS}
        includeCustomFields
      />
    );
  };

  return (
    <>
      <HeaderContainer>
        <ModulesMenuWrapper>
          <ModulesMenu pageIconBackground={ICON_MODULE_BACKGROUND} moduleTitle="Compare Scenarios" />
        </ModulesMenuWrapper>
        <FlexColumnContainer>
          <Header>
            Compare
            <SelectButtonContainer ref={selectScenarioButton}>
              <DragonSelectButton
                id="filters-list-dropdown"
                variant="secondary"
                noUnderline
                onClick={_onOpenSelectScenariosList}
                placeholder={`Select ${getSystemFieldName('scenario', true)}`}
                arrowIcon={<StyledKeyboardArrowDownIcon />}
              />
            </SelectButtonContainer>
            with
            <StyledAutocomplete
              value={selectedBaseVersion}
              suggestions={availableScenarioOptions}
              onValueChange={handleBaseVersionChange}
              iconStyle={selectBaseVersionIconStyle}
              inputProps={selectBaseVersionInputProps}
              inputComponent={InlineAutoCompleteInput}
              hideBottomBorder
            />
            {renderFilters()}
          </Header>
          {renderSubNavigation()}
        </FlexColumnContainer>
      </HeaderContainer>
      <SelectRoadmapVersionsPopover
        anchorEl={selectScenarioButton?.current}
        open={showSelectScenariosList}
        onClose={_onCloseSelectScenariosList}
      />
      <CompareScenariosToolbarControls />
      {renderAdvancedSearchPopover()}
    </>
  );
};

export default HeaderVersionsSelection;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2.5)}px;
  height: 100%;
`;

const ModulesMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing(2.5)}px;
`;

const Header = styled.div`
  &&&& {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: ${({ theme }) => theme.typography.fontSize}px;
  }
`;

const SelectButtonContainer = styled.div`
  &&&& {
    margin: 0 10px;

    button {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
`;

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
  &&&&& {
    width: 24px;
    height: 24px;

    path {
      fill: none;
    }
  }
`;

const StyledSubNavigationTabs = styled(SubNavigationTabs)`
  &&&& {
    min-height: unset;
  }
`;

const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
`;

const StyledAutocomplete = styled(Autocomplete)`
  margin-left: 3px;
  display: inline-block;

  input {
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const SpaceContainer = styled.div`
  display: flex;
  align-items: baseline;
`;
