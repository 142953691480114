import React from 'react';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';

import Button from 'design-system/atoms/Button/index';
import InputTextField from 'design-system/atoms/InputTextField/index';

import { materialColors } from 'design-system/themes/default';

const ForgotPasswordForm = ({ handleEmailValue, handleSubmit, email }) => {
  return (
    <Wrapper>
      <Grid container direction="column" alignItems="center" spacing={0}>
        <Grid item xs={12}>
          <StyledTitle>Reset password</StyledTitle>
        </Grid>
        <Grid item xs={12}>
          <StyledSubTitle>We suggest using the email address you use at work.</StyledSubTitle>
        </Grid>
        <Grid item xs={12} container spacing={16}>
          <Grid item xs={12}>
            <InputTextField
              multiline
              variant="outlined"
              label="Work email"
              type="email"
              value={email}
              handleValue={handleEmailValue}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledStartButton background={materialColors.mediumPurple} variant="contained" onClick={handleSubmit}>
              Send me Instructions
            </StyledStartButton>
          </Grid>
          <StyledFooter item xs={12} container spacing={8}>
            <Grid item xs={12}>
              <StyledFooterSpan>Need help? Contact us at help@dragonboat.io</StyledFooterSpan>
            </Grid>
          </StyledFooter>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default ForgotPasswordForm;

const Wrapper = styled.div`
  max-width: 360px;
  @media screen and (min-height: 700px) {
    margin-top: -5rem;
  }
`;

const StyledTitle = styled.h1`
  color: #3461ac;
  font-size: 2.25rem; // -> 36px -> with :root font-size of 16px;
  font-weight: bold;
  margin: 0 0 1.813rem 0;
`;

const StyledSubTitle = styled.h5`
  color: #131c23;
  font-size: 1rem;
  font-weight: 400;
  margin: 0 0 1.813rem 0;
  text-align: center;
  white-space: nowrap;
`;

const StyledStartButton = styled(Button)`
  &&&& {
    color: #ffffff;
    width: 100%;
  }
`;

const StyledFooter = styled(Grid)`
  &&&& {
    margin-top: 0.438rem;
    text-align: center;
  }
`;

const StyledFooterSpan = styled.span`
  &&&& {
    color: #777777;
    font-weight: 400;
    font-size: 0.875rem;

    a {
      color: #3c4348;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
