import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import RequestsParentComponent from './CustomerRequestsComponent';

import {
  getIsBulkUpdate,
  getIsCreatingCustomerRequest,
  getIsCustomFieldsVisible,
  getIsPortalSettingsVisible,
  getIsForwardEmailsVisible,
  getIsEditingCustomerRequest,
  getSelectedView,
} from 'store/customerRequests/selectors';
import { getGridConfigValue } from 'store/grids/selectors';
import {
  bulkDeleteCustomerRequests as bulkDeleteCustomerRequestsAction,
  bulkUpdateCustomerRequests as bulkUpdateCustomerRequestsAction,
  stopCreatingCustomerRequest as stopCreatingCustomerRequestAction,
  stopEditingCustomerRequest as stopEditingCustomerRequestAction,
  toggleBulkUpdateCustomerRequests as toggleBulkUpdateCustomerRequestsAction,
  toggleCustomFieldsForCustomerRequest as toggleCustomFieldsForCustomerRequestAction,
  toggleForwardEmailsForCustomerRequest as toggleForwardEmailsForCustomerRequestAction,
  togglePortalSettingsForCustomerRequest as togglePortalSettingsForCustomerRequestAction,
} from 'store/customerRequests/actions';
import { removeSelectedItem } from 'store/grids/actions';

import { CUSTOMER_REQUESTS } from 'store/grids/constants';
import { CUSTOMER_REQUEST_LAYERS } from 'store/customerRequests/constants/layers';
import useSubscribeNewData from 'hooks/useSubscribeNewData';
import { FIELDS_LAYOUT_TYPES } from 'features/FieldsLayout/constants';
import useFormLayoutDirtyWarning from 'features/FieldsLayout/hooks/useFormLayoutDirtyWarning';
import { resetRequestHistory } from 'features/RequestsHistory/store/actions';
import { FeatureFlags } from '@dragonboat/config';
import useFeatureFlags from 'hooks/useFeatureFlags';

export default Component => {
  return props => {
    const dispatch = useDispatch();

    const isBulkUpdate = useSelector(getIsBulkUpdate);
    const isCreating = useSelector(getIsCreatingCustomerRequest);
    const isCustomFieldsVisible = useSelector(getIsCustomFieldsVisible);
    const isPortalSettingsVisible = useSelector(getIsPortalSettingsVisible);
    const isForwardEmailsVisible = useSelector(getIsForwardEmailsVisible);
    const isEditing = useSelector(getIsEditingCustomerRequest);
    const selectedItems = useSelector(state => getGridConfigValue(state, CUSTOMER_REQUESTS, 'selectedItems'));
    const selectedView = useSelector(state => getSelectedView(state));

    const closeRequestsForm = () =>
      dispatch(isEditing ? stopEditingCustomerRequestAction() : stopCreatingCustomerRequestAction());

    const { onCloseFormWithValidation: closeRequestsFormWithValidation } = useFormLayoutDirtyWarning({
      type: FIELDS_LAYOUT_TYPES.requests,
      onCloseForm: closeRequestsForm,
    });

    const hasConfigurableRequestDrawer = useFeatureFlags([FeatureFlags.HAS_CONFIGURABLE_REQUEST_DRAWER]);
    const onCloseRequestsForm = hasConfigurableRequestDrawer ? closeRequestsFormWithValidation : closeRequestsForm;

    const stopCreatingOrEditingCustomerRequest = (...params) => {
      if (isForwardEmailsVisible) {
        return dispatch(toggleForwardEmailsForCustomerRequestAction());
      }

      if (isCustomFieldsVisible) {
        return dispatch(toggleCustomFieldsForCustomerRequestAction());
      }

      if (isPortalSettingsVisible) {
        return dispatch(togglePortalSettingsForCustomerRequestAction());
      }

      if (!isEditing && !isCreating) return;

      dispatch(resetRequestHistory());

      return onCloseRequestsForm(...params);
    };

    const toggleBulkUpdate = () => {
      dispatch(toggleBulkUpdateCustomerRequestsAction());
    };

    // TODO: This is legacy, not used anymore
    const bulkMergeRequests = selectedParentId => {
      const updates = selectedItems.map(request => {
        const isNewParent = parseInt(selectedParentId) === request.id;

        return {
          id: request.id,
          parent_id: isNewParent ? null : selectedParentId,
          layer: isNewParent ? CUSTOMER_REQUEST_LAYERS.REQUEST : CUSTOMER_REQUEST_LAYERS.SUB_REQUEST,
        };
      });

      dispatch(bulkUpdateCustomerRequestsAction(updates));
    };

    const _bulkDeleteRequests = itemsToDelete => {
      const ids = itemsToDelete.map(item => parseInt(item.id));

      dispatch(bulkDeleteCustomerRequestsAction(ids));
    };

    useEffect(() => {
      // Torn off bulk update mode if it was on when component mounts
      if (isBulkUpdate) {
        toggleBulkUpdate();
      }

      return () => {
        // Clear selected items when component unmounts if some are selected
        if (selectedItems) {
          dispatch(removeSelectedItem(CUSTOMER_REQUESTS, selectedItems));
        }
      };
    }, []);

    useSubscribeNewData(['app']);

    return (
      <RequestsParentComponent
        bulkDeleteRequests={_bulkDeleteRequests}
        isCreatingOrEditingCustomerRequest={isCreating || isEditing}
        isCustomFieldsVisible={isCustomFieldsVisible}
        isPortalSettingsVisible={isPortalSettingsVisible}
        isForwardEmailsVisible={isForwardEmailsVisible}
        isBulkActionsVisible={isBulkUpdate}
        mergeRequests={bulkMergeRequests}
        selectedItems={selectedItems || []}
        selectedView={selectedView}
        stopCreatingOrEditingCustomerRequest={stopCreatingOrEditingCustomerRequest}
        toggleBulkUpdate={toggleBulkUpdate}
        {...props}
      >
        <Component />
      </RequestsParentComponent>
    );
  };
};
