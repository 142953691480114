import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';

import { CUSTOM_FIELD_PREFIX, PARENT_GROUP_TYPES, SIMPLE_GROUP_TYPES } from 'utils/swimlane/new/constants';

const mapLayers = {
  bet: BET_LAYER,
  initiative: INITIATIVE_LAYER,
};

/**
 * Updates a given project with the selected group data.
 *
 * @param {Object} project The target project to be updated
 * @param {Object} groupType The source group key and field to be used on the update
 * @param {Array} groupData The list of group objects to retrieve the information to update the project
 * @param {Number | null} targetId The group id
 * */
const updateProjectWithGroupData = (project, groupType, groupData, targetId) => {
  const isCustomFieldGroup = groupType?.field?.startsWith(CUSTOM_FIELD_PREFIX);
  const isParentGroup = PARENT_GROUP_TYPES.includes(groupType?.key);
  const isStringGroup = SIMPLE_GROUP_TYPES.includes(groupType?.key) || isCustomFieldGroup;

  const initialTargetId = isStringGroup ? targetId : +targetId;
  const id = targetId === 'N/A' || targetId === 'null' ? null : initialTargetId;

  if (isParentGroup) {
    project.parent_id = id;
    project.parentLayer = mapLayers[groupType?.key];

    return;
  }

  if (isCustomFieldGroup) {
    const customGroupField = groupType?.field?.replace(CUSTOM_FIELD_PREFIX, '');

    project[customGroupField] = id;

    return;
  }

  const groupDataObject = isStringGroup ? groupData.find(l => l.id === id)?.id ?? null : groupData.find(l => l.id === id);

  project[groupType?.key] = groupDataObject;

  if (!isStringGroup) {
    project[groupType?.field] = id;
    project[`${groupType?.key}Title`] = groupDataObject?.title;
  }
};

export { updateProjectWithGroupData };
