import { createThunk } from '../../utils/store/thunk';

import {
  GET_DRAGONBOT_CORPUS_VERSIONS,
  MAKE_DRAGONBOT_CORPUS_VERSION_CURRENT,
  REQUEST_DRAGONBOT_PROMPT_RESULT,
  UPDATE_DRAGONBOT_CORPUS_VERSION,
} from './types';

import axios from 'axios';

export const requestDragonbotPromptResult = promptText => createThunk(
  REQUEST_DRAGONBOT_PROMPT_RESULT,
  axios.post('/api/v1/dragonbot/prompt', { text: promptText }).then(res => res.data),
);

export const loadDragonbotCorpusVersions = () => createThunk(
  GET_DRAGONBOT_CORPUS_VERSIONS,
  axios.get('/api/v1/dragonbot/corpus').then(res => res.data),
);

export const updateDragonbotCorpusVersion = (corpusVersionId, data) => createThunk(
  UPDATE_DRAGONBOT_CORPUS_VERSION,
  axios.put(`/api/v1/dragonbot/corpus/${corpusVersionId}`, data).then(res => res.data),
);

export const makeDragonbotCorpusVersionTheCurrent = (corpusVersionId) => createThunk(
  MAKE_DRAGONBOT_CORPUS_VERSION_CURRENT,
  axios.put(`/api/v1/dragonbot/corpus/${corpusVersionId}/current`).then(res => res.data),
);
