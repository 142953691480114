import { isCapacityUser } from 'utils';
import moment from 'moment-timezone';

import getRoundedValue from 'routes/Forecast/helpers/getRoundedValue';

/**
 * Returns the tasks that has resourcs associated
 *  - Task has team and skill
 *  - task has owner that are a capacity user and has team and skill
 *
 * @param {*} gantt
 * @param {*} teamId
 * @param {*} skillId
 */
const getResourceTasks = (gantt, tasks, resource) => {
  const res = [];
  const { teamId, skillId, userId } = resource;

  const isResourceTask = task => {
    const hasTasks = task.tasks && !!task.tasks.length;
    const hasEstimates = task.estimates && !!task.estimates.length;
    const isProject = task.dbType === gantt.config.types.project;

    if (isProject && (hasTasks || hasEstimates)) return false;
    if (userId) return task.owner && task.owner_id === userId;
    if (task.dbType === 'estimate') return task.team_id === teamId && task.skill_id === skillId;
    if (task.dbType === 'task' && task.subtasks && !!task.subtasks.length) return false;

    return task.owner && isCapacityUser(task.owner) && task.owner.team_id === teamId && task.owner.skill_id === skillId;
  };

  tasks.forEach(task => {
    if (isResourceTask(task)) {
      res.push(task);
    }
  });
  return res;
};

/**
 * Generates the timetable with resource values
 *
 * @param {*} gantt
 * @param {*} tasks
 * @param {*} scale
 * @param {*} selectedRounding
 */
const calculateResourceLoad = (gantt, tasks, scale, selectedRounding) => {
  const step = scale.unit;

  const timegrid = {};
  let stepLength;

  switch (step) {
    case 'month':
      stepLength = 30;
      break;
    case 'quarter':
      stepLength = 91.25;
      break;
    default:
      stepLength = 7;
      break;
  }

  for (let i = 0; i < tasks.length; i++) {
    const task = tasks[i];

    let currDate = gantt.date[`${step}_start`](new Date(task.start_date));

    while (currDate < new Date(task.end_date)) {
      const date = currDate;

      currDate = gantt.date.add(currDate, 1, step);

      if (!gantt.isWorkTime({ date, task })) {
        continue;
      }
      const startDiff = moment(task.start_date).diffDuration(moment(date), 'days', true);
      const endDiff = moment(currDate).diffDuration(moment(task.end_date), 'days', true);
      let value = task.numStaff || 1;

      if (task.effort_score) {
        value = task.effort_score / stepLength;
        if (value < 1) value = 1;
      }
      if (startDiff > 0) {
        value *= (stepLength - startDiff) / stepLength;
      }
      if (endDiff > 0) {
        value *= (stepLength - endDiff) / stepLength;
      }

      const timestamp = date.valueOf();

      if (!timegrid[timestamp]) {
        timegrid[timestamp] = {};
        timegrid[timestamp].value = 0;
      }

      if (value < 0) value *= -1;
      timegrid[timestamp].value += value;
    }
  }

  const timetable = [];
  let start;
  let end;

  Object.keys(timegrid).forEach(i => {
    start = new Date(i * 1);
    end = gantt.date.add(start, 1, step);
    timetable.push({
      start_date: start,
      end_date: end,
      value: getRoundedValue(timegrid[i].value, selectedRounding),
    });
  });

  return timetable;
};

/**
 * Function that renders on the gantt the HC Resource Line
 *
 * @param {*} options
 */
const checkIsGreen = ({ resources, tasks: ganttTasks, gantt, selectedRounding, selectedTimelineInterval }) => {
  for (let i = 0; i < resources.length; i++) {
    const resource = resources[i];

    if (!resource) continue;

    const tasks = getResourceTasks(gantt, ganttTasks, resource);

    const scaleUnit = { unit: selectedTimelineInterval.id };
    const timetable = calculateResourceLoad(gantt, tasks, scaleUnit, selectedRounding);

    for (let i = 0; i < timetable.length; i++) {
      const day = timetable[i];

      if (day.value && day.value > resource.skillStaff) {
        return false;
      }
    }
  }

  return true;
};

export default checkIsGreen;
