import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { isNil, keys } from 'ramda';

import useGetStackedByData from '../hooks/useGetStackedByData';

import usePageFilters from 'hooks/filters/usePageFilters';

import { COMPARE_SCENARIOS_FILTER } from 'constants/filters/pages';
import { getBaseCompareVersionSelected } from 'store/roadmapVersions/selectors';
import { PLAN_OF_RECORD_ID } from 'constants/common';
import { materialColors } from 'design-system/themes/default';
import { TIME_BUCKET_TIMEFRAME_KEY } from 'store/roadmapVersions/constants/defaultChartOptions';
import { SCALE_TYPE_LABELS, SCALE_TYPE_TIME } from '../utils/scales';

const DEFAULT_CHART_DATA = {
  datasets: [],
};

const CHART_API_URL = '/api/v1/reports/scenarios/compare';

const checkHasChartData = (chartData = {}) => Boolean(keys(chartData.datasets).length);

const requestChartData = async requestPayload => {
  return axios.post(CHART_API_URL, requestPayload);
};

/**
 * @function useScenarioStackedBenefitChartData
 *
 * Use chart data for stacked benefits.
 *
 * @return {Object}
 */
const useScenarioStackedBenefitChartData = (roadmapVersionId, projectedField, timeBucketMode, stackByField, dateInterval) => {
  const [state, setState] = useState({
    dataKeyedByScenarioId: null,
    loading: false,
  });

  const _getStackedByData = useGetStackedByData(stackByField);

  const chartData = useMemo(() => {
    if (isNil(state.dataKeyedByScenarioId)) {
      return DEFAULT_CHART_DATA;
    }

    const scenarioIds = keys(state.dataKeyedByScenarioId);

    const datasets = scenarioIds.reduce((datasets, scenarioId) => {
      const datasetsByGroup = state.dataKeyedByScenarioId[scenarioId].reduce((dataByGroup, entry) => {
        const groups = keys(entry.stacks);

        groups.forEach(group => {
          if (!(group in dataByGroup)) {
            dataByGroup[group] = [];
          }

          dataByGroup[group].push({
            x: entry.t,
            y: entry.stacks[group],
          });
        });

        return dataByGroup;
      }, {});

      const groupsDatasets = keys(datasetsByGroup).map(groupName => {
        return {
          label: _getStackedByData(groupName)?.title || 'Undefined',
          yAxisID: 'y',
          data: datasetsByGroup[groupName],
          borderColor: _getStackedByData(groupName)?.color || materialColors.darkGray,
          backgroundColor: _getStackedByData(groupName)?.color || materialColors.darkGray,
        };
      });

      return [...datasets, ...groupsDatasets];
    }, []);

    return {
      datasets,
    };
  }, [state.dataKeyedByScenarioId, _getStackedByData]);

  const { filtersForApiByLevels } = usePageFilters(COMPARE_SCENARIOS_FILTER);

  const baseCompareVersionSelected = useSelector(getBaseCompareVersionSelected);

  const makeChartData = useCallback(async () => {
    if (isNil(projectedField) || isNil(dateInterval)) {
      return;
    }

    setState(state => ({ ...state, loading: true }));

    try {
      const requestPayload = {
        scenarioIds: [roadmapVersionId],
        interval: dateInterval,
        projectedField,
        timeBucketMode,
        stackByField,
        filters: filtersForApiByLevels,
        baseCompareVersionId: baseCompareVersionSelected?.id || PLAN_OF_RECORD_ID,
      };
      const { data } = await requestChartData(requestPayload);

      setState({ dataKeyedByScenarioId: data, loading: false });
    } catch {
      setState({ dataKeyedByScenarioId: {}, loading: false });
    }
  }, [
    roadmapVersionId,
    projectedField,
    timeBucketMode,
    stackByField,
    dateInterval,
    filtersForApiByLevels,
    baseCompareVersionSelected?.id,
  ]);

  useEffect(() => {
    makeChartData();
  }, [makeChartData]);

  const hasData = useMemo(() => checkHasChartData(chartData), [chartData]);
  const chartScaleType = timeBucketMode === TIME_BUCKET_TIMEFRAME_KEY ? SCALE_TYPE_LABELS : SCALE_TYPE_TIME;

  return {
    chartData,
    chartScaleType,
    hasData,
    loading: state.loading,
  };
};

export default useScenarioStackedBenefitChartData;
