import { propOr } from 'ramda';

import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { KEY_RESULT_LEVEL } from 'constants/common';

const keyResultLevelToResource = {
  [KEY_RESULT_LEVEL.keyResult]: PERMISSION_RESOURCES.keyResult,
  [KEY_RESULT_LEVEL.keyResult2]: PERMISSION_RESOURCES.keyResult2,
};

/**
 * Get the permission resource associated with a given key result level.
 *
 * @param {string} level - The key result level for which you want to retrieve the permission resource.
 * @returns {string} The permission resource associated with the provided key result level.
 */
const getPermissionResourceByKeyResultLevel = level => {
  const getResource = propOr(PERMISSION_RESOURCES.keyResult, level);

  return getResource(keyResultLevelToResource);
};

export default getPermissionResourceByKeyResultLevel;
