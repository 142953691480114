import { getUserName, getMetadataFilteredByActiveItems } from 'utils';

import getProcessedProductsList from './getProcessedProductsList';
import { OBJECT_ROADMAP, OBJECT_PRODUCT } from './constants';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

const emptyArray = [];

const buildPathFromAncestry = (items, parentId) => {
  const parent = items.find(parentItem => parentItem.id === parentId);

  let previousPath = [];

  if (!parent) return previousPath;

  if (parent?.parent_id) {
    previousPath = buildPathFromAncestry(items, parent?.parent_id).flat();
  }
  return [...previousPath, parent?.title];
};

const getAllRoadmaps = (roadmaps, hasProducts, hasProducts2, hideArchivedItems, canView) => {
  const titles = [];

  const roadmapsProcessed = roadmaps.reduce((acc, roadmap) => {
    const result = titles.filter(title => title === roadmap.title);
    let parentPath = [];

    titles.push(roadmap.title);

    let { title } = roadmap;

    if (result.length > 0) {
      title = `${roadmap.title}${Array(result.length).fill('\u00A0').join('')}`;
    }

    if (roadmap.parent_id && canView(PERMISSION_RESOURCES.roadmapCorp)) {
      parentPath = buildPathFromAncestry(roadmaps, roadmap.parent_id);
    }
    const path = [...parentPath, title];
    const enhancedRoadmap = {
      ...roadmap,
      title,
      ownerName: getUserName(roadmap.owner),
      updatedBy: getUserName(roadmap.updatedBy),
      path,
      type: OBJECT_ROADMAP,
      uniqueId: `ROADMAP${roadmap.id ? roadmap.id : '-PENDING'}`,
    };

    const products = getProcessedProductsList(roadmap, path, { hasProducts, hasProducts2, OBJECT_PRODUCT });

    return [...acc, enhancedRoadmap, ...products];
  }, []);

  return getMetadataFilteredByActiveItems(roadmapsProcessed, hideArchivedItems) || emptyArray;
};

export default getAllRoadmaps;
