import React from 'react';
import styled from 'styled-components';

import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';

const EmptyMessage = ({ entity }) => (
  <Container>
    <Text>Currently you are not an owner of any active {entity}.</Text>
  </Container>
);

const Container = styled.div`
  margin: ${spacing(3)}px 0 ${spacing(2)}px;
`;

export default EmptyMessage;
