import React, { useState } from 'react';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';

import SearchableDndList from 'design-system/molecules/SearchableDndList/index';

import MultiSelectChips from './MultiSelectChips';

export default ({ label, placeholder, items, selectedItems, onSelectItems, searchMenuItems, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const _openDndList = e => setAnchorEl(e.currentTarget);

  const _handleSelectItem = (itemId, selected) => {
    const newCheckedItems = cloneDeep(selectedItems);

    newCheckedItems[itemId] = selected;
    onSelectItems(newCheckedItems);
  };

  const _handleClearItems = () => onSelectItems({});

  return (
    <Container {...props}>
      <MultiSelectChips
        label={label}
        placeholder={placeholder}
        items={items}
        selectedItems={selectedItems}
        onOpen={_openDndList}
        onChange={updatedItems => onSelectItems(updatedItems)}
      />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper>
          <SearchableDndList
            items={items}
            selectedItems={selectedItems}
            onSelectItem={_handleSelectItem}
            handleClear={_handleClearItems}
            searchMenuItems={searchMenuItems}
            checkable
          />
        </Paper>
      </Popover>
    </Container>
  );
};

const Container = styled.div``;
