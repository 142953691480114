import axios from 'axios';

import {
  FETCH_VOTES_PER_PROJECT,
  VOTE_ON_PROJECT,
  UNVOTE_ON_PROJECT,
  FETCH_VOTES_PER_CUSTOMER_REQUEST,
  VOTE_ON_CUSTOMER_REQUEST,
  UNVOTE_ON_CUSTOMER_REQUEST,
} from './types';

export const fetchVotesPerProjectApiCall = filters => axios.post('/api/projects/search/votes', filters).then(res => res.data);

export const fetchVotesPerProject = filters => {
  return dispatch => {
    return dispatch({
      type: FETCH_VOTES_PER_PROJECT,
      payload: axios.post('/api/projects/search/votes', filters).then(res => res.data),
    });
  };
};

export const voteOnProject = projectId => {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.login.currentUser.id;

    return dispatch({
      type: VOTE_ON_PROJECT,
      payload: axios.post(`/api/projects/${projectId}/votes`).then(res => res.data),
      meta: {
        user_id: userId,
        project_id: projectId,
      },
    });
  };
};

export const unvoteOnProject = projectId => {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.login.currentUser.id;

    return dispatch({
      type: UNVOTE_ON_PROJECT,
      payload: axios.delete(`/api/projects/${projectId}/votes/my-vote`).then(res => res.data),
      meta: {
        user_id: userId,
        project_id: projectId,
      },
    });
  };
};

export const fetchVotesPerCustomerRequest = filters => {
  return dispatch => {
    return dispatch({
      type: FETCH_VOTES_PER_CUSTOMER_REQUEST,
      payload: axios.get('/api/customer-requests/votes', { params: filters }).then(res => res.data),
    });
  };
};

export const voteOnCustomerRequest = customerRequestId => {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.login.currentUser.id;

    return dispatch({
      type: VOTE_ON_CUSTOMER_REQUEST,
      payload: axios.post(`/api/customer-requests/${customerRequestId}/votes`).then(res => res.data),
      meta: {
        user_id: userId,
        customer_request_id: customerRequestId,
      },
    });
  };
};

export const unvoteOnCustomerRequest = customerRequestId => {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.login.currentUser.id;

    return dispatch({
      type: UNVOTE_ON_CUSTOMER_REQUEST,
      payload: axios.delete(`/api/customer-requests/${customerRequestId}/votes/my-vote`).then(res => res.data),
      meta: {
        user_id: userId,
        customer_request_id: customerRequestId,
      },
    });
  };
};
