import React from 'react';
import NewDialog from 'design-system/organisms/NewDialog/index';
import DialogActions from 'design-system/molecules/DialogActions/index';
import FluidSelectField from 'design-system/organisms/FluidSelectField/index';

import Header from './Header';
import ProjectForm from './Forms/ProjectForm';
import OkrForm from './Forms/OkrForm';
import MetricForm from './Forms/MetricForm';
import useCreateProjectForm from './Forms/ProjectForm/useCreateProjectForm';
import useCreateOkrForm from './Forms/OkrForm/useCreateOkrForm';
import useCreateMetricForm from './Forms/MetricForm/useCreateMetricForm';

const DIALOG_WIDTH = 600;

const CreateDrawer = ({
  isOpen,
  onClose,
  objectTitle = '',
  textLabelId,
  type,
  typeOptions,
  selectedTypeIsOkr,
  selectedTypeIsProject,
  selectedTypeIsMetric,
  handleTypeChange,
}) => {
  const {
    handleSave: handleProjectSave,
    onCloseCallback: onCloseProjectCallback,
    ...projectFormProps
  } = useCreateProjectForm(type, objectTitle, textLabelId, onClose);

  const {
    handleSave: handleOkrSave,
    onCloseCallback: onCloseOkrCallback,
    disableCreate: disableCreateOkr,
    ...okrFormProps
  } = useCreateOkrForm(type, objectTitle, textLabelId, onClose);

  const {
    handleSave: handleMetricSave,
    onCloseCallback: onCloseMetricCallback,
    ...metricFormProps
  } = useCreateMetricForm(type, objectTitle, textLabelId, onClose);

  const getHandlersAndPropsForType = () => {
    switch (true) {
      case selectedTypeIsProject:
        return { handleSave: handleProjectSave, handleClose: onCloseProjectCallback, disableCreate: false };
      case selectedTypeIsOkr:
        return { handleSave: handleOkrSave, handleClose: onCloseOkrCallback, disableCreate: disableCreateOkr };
      case selectedTypeIsMetric:
        return { handleSave: handleMetricSave, handleClose: onCloseMetricCallback, disableCreate: false };
      default:
        return { handleSave: handleProjectSave, handleClose: onCloseProjectCallback, disableCreate: false };
    }
  };

  const { handleSave, handleClose, disableCreate } = getHandlersAndPropsForType();

  const onCloseDrawer = () => onClose(handleClose);

  return (
    <NewDialog
      id="whiteboard-create-drawer"
      type="DRAWER"
      scroll="paper"
      fullHeight
      open={!!isOpen}
      onClose={onCloseDrawer}
      header={<Header onClose={onCloseDrawer} />}
      width={`${DIALOG_WIDTH}px`}
      actions={<DialogActions onSave={handleSave} saveLabel="Create" saveProps={{ disabled: disableCreate }} />}
    >
      <FluidSelectField
        key="type"
        label="Type"
        value={typeOptions[type]}
        onChange={handleTypeChange}
        options={Object.values(typeOptions)}
        placeholder="Select a type"
        format="row"
      />
      {selectedTypeIsOkr ? <OkrForm {...okrFormProps} /> : null}
      {selectedTypeIsProject ? <ProjectForm {...projectFormProps} /> : null}
      {selectedTypeIsMetric ? <MetricForm {...metricFormProps} /> : null}
    </NewDialog>
  );
};

export default CreateDrawer;
