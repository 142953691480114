import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';
import Autosuggest from 'react-autosuggest';
import TextField from '@material-ui/core/TextField';

import useInputForDropdown from 'design-system/atoms/Autocomplete/hooks/useInputForDropdown';
import useRenderDropdownContainer from 'design-system/atoms/Autocomplete/hooks/useRenderDropdownContainer';
import useRoadmapMetadataOptionsForDropdown from './hooks/useRoadmapMetadataOptionsForDropdown';
import RoadmapMetadataOptions from './RoadmapMetadataOptions';

export default props => {
  const { disableEdit, handleClose, handleSave, label, options, value, addNew, withEllipsis } = props;

  const dropdownRef = useRef();
  const [optionsByTitle, setOptionsByTitle] = useState([]);

  const onClose = (target = {}) => {
    handleClose({ target });
  };
  const onSuggestionSelected = suggestion => {
    handleSave({ target: { value: suggestion } });
  };
  const handleEnterKey = selectedIndex => {
    if (!optionsByTitle[selectedIndex]) {
      return onClose();
    }

    onSuggestionSelected(optionsByTitle[selectedIndex]);
  };

  const { inputRef, inputValue, inputProps, isDropdownOpen, hoveredRowIndex } = useInputForDropdown({
    value,
    onClose,
    disableEdit,
    onSuggestionSelected,
    handleEnterKey,
    dropdownRef,
    totalOptions: optionsByTitle.length,
  });

  const { allOptions, filteredOptions } = useRoadmapMetadataOptionsForDropdown({ inputValue, value, options });

  useEffect(() => setOptionsByTitle(allOptions), [allOptions]);

  const renderRoadmapMetadataOptions = () => {
    const renderProps = {
      options,
      filteredOptions,
      optionsByTitle,
      hoveredRowIndex,
      inputValue,
      addNew,
      onSuggestionSelected,
      value,
      label,
      withEllipsis,
    };

    return <RoadmapMetadataOptions {...renderProps} />;
  };
  const renderDropdownOptionsComponent = props => <div {...props}>{renderRoadmapMetadataOptions()}</div>;

  const renderOptions = useRenderDropdownContainer({
    renderOptions: renderDropdownOptionsComponent,
    inputRef,
    dropdownRef,
    isOpen: isDropdownOpen,
  });

  const renderInputComponent = props => {
    return <AutocompleteTextField withEllipsis={withEllipsis} {...props} />;
  };

  return (
    <SelectContainer>
      <Autosuggest
        suggestions={[]}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        renderInputComponent={renderInputComponent}
        inputProps={inputProps}
        renderSuggestionsContainer={renderOptions}
      />
    </SelectContainer>
  );
};

const SelectContainer = styled(FormControl)`
  margin: 0 10px;
`;

const AutocompleteTextField = styled(TextField)`
  width: 100%;

  ${({ withEllipsis }) =>
    withEllipsis &&
    `
    input {
      max-width: 85%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}

  > div::before {
    border-bottom: 0 !important;
  }
  > div::after {
    border-bottom: 0 !important;
  }
  > div > div::focus {
    background-color: transparent;
  }
`;
