import React from 'react';
import FluidField from 'design-system/molecules/FluidField/index';
import TextFieldNoBorder from 'design-system/atoms/TextFieldNoBorder/index';

import NumberFormat from 'react-number-format';

const FluidNumberField = props => (
  <FluidField
    {...props}
    editorRenderer={({ value, onChange, save, cancel, disableEdit }) => (
      <NumberFormat
        value={value}
        customInput={TextFieldNoBorder}
        thousandSeparator
        onValueChange={e => onChange(e.value)}
        disabled={disableEdit}
        {...saveOnTabOrBlur(save, cancel)}
      />
    )}
  />
);

const saveOnTabOrBlur = (save, cancelEdit) => ({
  onKeyDown: e => {
    e.stopPropagation();

    if (e.which === 9 || e.which === 13) save();
    if (e.which === 27) cancelEdit();
  },
  onBlur: _ => save(),
});

FluidNumberField.propTypes = { ...FluidField.propTypes };

export default FluidNumberField;
