import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import MicroChip from 'design-system/atoms/MicroChip/index';

import Stars from 'design-system/atoms/DragonIcons/Stars';
import { getBaseCompareVersionSelected, getSelectedRoadmapVersionsIdsToCompare } from 'store/roadmapVersions/selectors';

import { PLAN_OF_RECORD_ID, COMPARE_SCENARIOS_PLAN_OF_RECORD_LIST } from 'constants/common';

import getSelectedVersionsForBadges from 'routes/RoadmapVersions/utils/getSelectedVersionsForBadges';
import useScenariosListDialog from 'hooks/useScenariosListDialog/useScenariosListDialog';

const exist = Boolean;

const SwimlaneSelectedVersionsColors = props => {
  const { scenarioColors, roadmapVersions, oneClickRoadmapVersion } = props;

  const selectedRoadmapVersionsIds = useSelector(state => getSelectedRoadmapVersionsIdsToCompare(state));
  const baseCompareVersionSelected = useSelector(getBaseCompareVersionSelected);
  const { openSaveScenarioDialog } = useScenariosListDialog();

  const selectedVersionsForBadges = useMemo(
    () =>
      getSelectedVersionsForBadges(
        [...(oneClickRoadmapVersion ? [oneClickRoadmapVersion?.id] : []), ...selectedRoadmapVersionsIds],
        baseCompareVersionSelected,
      ) || [],
    [selectedRoadmapVersionsIds, baseCompareVersionSelected, oneClickRoadmapVersion],
  );

  const getPorVersionFromId = selectedVersionId =>
    COMPARE_SCENARIOS_PLAN_OF_RECORD_LIST.find(por => por.id === selectedVersionId);

  const getScenarioVersionFromId = selectedVersionId => roadmapVersions.find(version => version?.id === +selectedVersionId);

  const selectRoadmapVersionOrPlanOrRecord = selectedVersionId => {
    const planOfRecordObject = getPorVersionFromId(selectedVersionId);
    const isPlanOfRecord = exist(planOfRecordObject);

    return isPlanOfRecord ? planOfRecordObject : getScenarioVersionFromId(selectedVersionId);
  };

  const _handleOpenSaveScenarioDialog = (selectedVersionId, currentVersion) => {
    if (selectedVersionId === PLAN_OF_RECORD_ID) return;

    openSaveScenarioDialog(currentVersion);
  };

  return (
    <Wrapper {...props}>
      {selectedVersionsForBadges?.map(selectedVersionId => {
        const currentVersion = selectRoadmapVersionOrPlanOrRecord(selectedVersionId);

        const versionColor = scenarioColors[selectedVersionId];

        const isAiPlanVersion = currentVersion?.id === oneClickRoadmapVersion?.id;

        const label = isAiPlanVersion ? (
          <ChipWithIconWrapper>
            <StyledStarsIcon /> {currentVersion?.name}
          </ChipWithIconWrapper>
        ) : (
          currentVersion?.name
        );

        return (
          <SytledMicroChip
            key={selectedVersionId}
            label={label}
            color={versionColor}
            onClick={() => _handleOpenSaveScenarioDialog(selectedVersionId, currentVersion)}
          />
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const SytledMicroChip = styled(MicroChip)`
  &&&& {
    max-width: 20ch;
  }
`;

const ChipWithIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.unit}px;
`;

const StyledStarsIcon = styled(Stars)`
  width: 15px;
  height: 15px;

  path {
    fill: ${({ theme }) => theme.palette.newLayout.background.white};
  }
`;

export default SwimlaneSelectedVersionsColors;
