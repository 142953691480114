import React from 'react';
import styled from 'styled-components';
import InputAdornment from '@material-ui/core/InputAdornment';

import FluidTextField from 'design-system/organisms/FluidTextField/index';
import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';
import ObjectiveType from 'design-system/atoms/ObjectiveType/index';

import { spacing } from 'design-system/theme';
import FluidMetadataAutocompleteField from 'containers/FluidMetadataAutocompleteField';
import LoadableUsersAutocomplete from 'containers/LoadableUsersAutocomplete';
import useSystemFields from 'hooks/useSystemFields';
import { ENTER_TITLE_PLACEHOLDER } from 'constants/common';
import { getTypeParent, getAlignedToErrorMessage } from './helpers';

const OkrForm = ({
  formValues,
  objectTitle,
  ownerOptions,
  parentOptions,
  type,
  displayAlignedToField,
  handleFormValueChange,
}) => {
  const [getSystemFieldName] = useSystemFields();

  const parentType = getTypeParent(type);

  const alignedToErrorMessage = getAlignedToErrorMessage(type, getSystemFieldName);

  const selectedOwnerName = ownerOptions?.find(opt => opt.id === formValues?.owner)?.name;

  return (
    <>
      <FluidTextField
        key="title"
        label="Title"
        value={formValues?.title || objectTitle}
        onChange={val => handleFormValueChange('title', val)}
        placeholder={ENTER_TITLE_PLACEHOLDER}
        readingPlaceholder={ENTER_TITLE_PLACEHOLDER}
        format="row"
      />
      {displayAlignedToField && (
        <>
          <FluidMetadataAutocompleteField
            key="parent"
            value={formValues?.parent}
            label="Aligned to"
            onChange={val => handleFormValueChange('parent', val)}
            options={parentOptions}
            format="row"
            addNew={false}
            autocompleteProps={{
              startAdornment: parentType && (
                <InputAdornment position="start">
                  <ObjectiveTypeIcon type={parentType} active />
                </InputAdornment>
              ),
            }}
          />
          {!formValues?.parent && <ErrorMessage>{alignedToErrorMessage}</ErrorMessage>}
        </>
      )}
      <FluidMetadataAutocompleteField
        name="Owner"
        key="users"
        value={selectedOwnerName}
        label={getSystemFieldName('owner')}
        metadata="users"
        onChange={val => handleFormValueChange('owner', val)}
        autocompleteComponent={LoadableUsersAutocomplete}
        autocompleteProps={{
          suggestions: ownerOptions,
          placeholder: `Select an ${getSystemFieldName('owner')}`,
        }}
        format="row"
      />
      <StyledFluidRemirrorWYSIWYGEditor
        name="description"
        key="description"
        label="Description"
        value={formValues?.description}
        content={formValues?.description}
        onChange={val => handleFormValueChange('description', val)}
        placeholder="Write a description..."
        disableToolbar
        smallInput
        format="row"
      />
    </>
  );
};

export default OkrForm;

const StyledFluidRemirrorWYSIWYGEditor = styled(FluidRemirrorWYSIWYGEditor)`
  && .remirror-is-empty:before {
    font-style: normal;
  }
`;

const ObjectiveTypeIcon = styled(ObjectiveType)`
  &&&& {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  }
`;

const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.palette.text.error};
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
  padding: ${spacing(0.5)}px ${spacing()}px;
`;
