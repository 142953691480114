import React from 'react';
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const ConfirmDialog = props => {
  const { isOpen, className, onCancel, onConfirm, onDelete, onArchive, text, title, disableActions, zIndex } = props;

  let { row } = props;

  row = row || {
    id: '',
    title: '',
  };

  return (
    <StyledDialog open={!!isOpen} classes={{ paper: className }} id="confirm-dialog" zIndex={zIndex}>
      <DialogTitle>{title || (row.title ? `Delete DB-${row.id}: ${row.title}` : 'Are you sure you want to delete?')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <StyledDialogActions>
        <Grid container>
          <Grid item xs={3}>
            <Button
              data-test="confirm-dialog-confirm-button"
              disabled={disableActions}
              onClick={onConfirm || onDelete}
              color="secondary"
            >
              {onDelete ? 'Delete' : 'Confirm'}
            </Button>
          </Grid>
          <Grid item xs={9}>
            <div style={{ justifyContent: 'flex-end', textAlign: 'right' }}>
              {onArchive && (
                <Button onClick={onArchive} color="primary">
                  Archive
                </Button>
              )}
              <Button disabled={disableActions} onClick={onCancel} data-test="confirm-dialog-cancel-button">
                Cancel
              </Button>
            </div>
          </Grid>
        </Grid>
      </StyledDialogActions>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  &&&& {
    ${({ zIndex }) =>
      zIndex &&
      css`
        z-index: ${zIndex};
      `}
  }
`;

const StyledDialogActions = styled(DialogActions)`
  padding: 0 8px;
`;
