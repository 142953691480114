import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { isNil, keys } from 'ramda';

import useGetStackedByData from '../hooks/useGetStackedByData';

import usePageFilters from 'hooks/filters/usePageFilters';

import { COMPARE_SCENARIOS_FILTER } from 'constants/filters/pages';
import { getBaseCompareVersionSelected } from 'store/roadmapVersions/selectors';
import { PLAN_OF_RECORD_ID } from 'constants/common';
import { materialColors } from 'design-system/themes/default';

const DEFAULT_CHART_DATA = {
  datasets: [],
};

const CHART_API_URL = '/api/v1/reports/scenarios/capacity-utilization';

const checkHasChartData = (chartData = {}) => Boolean(keys(chartData.datasets).length);

const requestChartData = async requestPayload => {
  return axios.post(CHART_API_URL, requestPayload);
};

/**
 * @function useScenarioUnplannedResourcesChartData
 *
 * Use chart data for stacked unplanned resources.
 *
 * @return {Object}
 */
const useScenarioUnplannedResourcesChartData = (roadmapVersionId, stackByField, dateInterval) => {
  const [state, setState] = useState({
    data: null,
    loading: false,
  });

  const _getStackedByData = useGetStackedByData(stackByField);

  const chartData = useMemo(() => {
    if (isNil(state.data)) {
      return DEFAULT_CHART_DATA;
    }

    const datasetsByGroup = state.data.reduce((dataByGroup, entry) => {
      const groups = keys(entry.stacks);

      groups.forEach(group => {
        if (!(group in dataByGroup)) {
          dataByGroup[group] = [];
        }
        dataByGroup[group].push({
          x: entry.t,
          y: entry.stacks[group],
        });
      });

      return dataByGroup;
    }, {});

    const datasets = keys(datasetsByGroup).map(groupName => {
      return {
        label: _getStackedByData(groupName)?.title || 'Undefined',
        yAxisID: 'y',
        data: datasetsByGroup[groupName],
        borderColor: _getStackedByData(groupName)?.color || materialColors.darkGray,
        backgroundColor: _getStackedByData(groupName)?.color || materialColors.darkGray,
      };
    });

    return {
      datasets,
    };
  }, [state.data, _getStackedByData]);

  const { filtersForApiByLevels } = usePageFilters(COMPARE_SCENARIOS_FILTER);

  const baseCompareVersionSelected = useSelector(getBaseCompareVersionSelected);

  const makeChartData = useCallback(async () => {
    if (isNil(dateInterval)) {
      return;
    }

    setState(state => ({ ...state, loading: true }));

    try {
      const requestPayload = {
        scenarioId: roadmapVersionId,
        interval: dateInterval,
        stackByField,
        filters: filtersForApiByLevels,
        baseCompareVersionId: baseCompareVersionSelected?.id || PLAN_OF_RECORD_ID,
      };
      const { data } = await requestChartData(requestPayload);

      setState({ data, loading: false });
    } catch {
      setState({ data: [], loading: false });
    }
  }, [roadmapVersionId, stackByField, dateInterval, filtersForApiByLevels, baseCompareVersionSelected?.id]);

  useEffect(() => {
    makeChartData();
  }, [makeChartData]);

  const hasData = useMemo(() => checkHasChartData(chartData), [chartData]);

  return {
    chartData,
    hasData,
    loading: state.loading,
  };
};

export default useScenarioUnplannedResourcesChartData;
