import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { PERMISSION_RESOURCES, SYSTEM_FIELD } from '@dragonboat/permissions';

import { SKILLS } from 'store/grids/constants';
import { updateSystemFieldsName as updateSystemFieldsNameAction } from 'store/organization';

import usePermissions from 'hooks/permissions/usePermissions';
import useForceVisibleColumns from 'hooks/useForceVisibleColumns';
import useSkills from './hooks/useSkills';
import useSkillsGridColumns from './hooks/useSkillsGridColumns';

import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SettingsDialogs from 'components/SettingsDialogs';
import { BaseGrid } from 'containers/Grids';
import {
  useSettingsActions,
  useSettingsGridSelection,
  useSettingsGridProps,
  useSettingsGridEventHandlers,
  useSettingsGridColumns,
} from 'containers/Grids/SettingsGrid/hooks';
import { SKILL_KEY, TITLE_FIELD } from './constants';

const Skills = ({ showActionBar = true, forceVisibleFields, customHeight, hideArchivedItems }) => {
  const {
    skills,
    createSkill,
    addSkillWithoutSave,
    removeUnsavedSkills,
    updateSkillById,
    deleteSkill,
    mergeSkills,
    switchSkillsRowOrder,
    bulkCreateSkills,
    bulkUpdateSkills,
    bulkDeleteSkills,
    systemFields,
  } = useSkills(hideArchivedItems);

  const dispatch = useDispatch();

  const updateSystemFieldsName = useCallback(
    fieldRename => dispatch(updateSystemFieldsNameAction(fieldRename)),
    [updateSystemFieldsNameAction],
  );

  // Specific column definition for Skills
  const skillsGridColumnDefs = useSkillsGridColumns();

  const handleRowDrag = useCallback(
    (currentNode, lastOverNode, { position }) => switchSkillsRowOrder(currentNode.data.id, lastOverNode.data.id, null, position),
    [switchSkillsRowOrder],
  );

  // Settings common agGrid configuration
  const { selectionMode, selectedItems, enableSelectionMode, disableSelectionMode, updateSelectedOnAgGridInit } =
    useSettingsGridSelection(SKILLS, {
      actions: { remove: removeUnsavedSkills },
    });

  const {
    itemToDelete,
    setItemToDelete,
    deleteItem,
    deleteProgress,
    mergeLightboxVisible,
    showMergeLightbox,
    hideMergeLightbox,
    bulkDeleteItems,
    showBulkDeleteDialog,
    setShowBulkDeleteDialog,
  } = useSettingsActions({
    settingType: SKILLS,
    selectedItems,
    disableSelectionMode,
    deleteById: deleteSkill,
    bulkDelete: bulkDeleteSkills,
  });

  const settingsGridProps = useSettingsGridProps({
    bulkCreate: bulkCreateSkills,
    bulkUpdate: bulkUpdateSkills,
  });

  const { canUpdate, canCreate, canView, canMerge } = usePermissions();
  const canUpdateOrCreate = canUpdate(PERMISSION_RESOURCES.skill) || canCreate(PERMISSION_RESOURCES.skill);

  const permissions = {
    allowActions: canUpdateOrCreate,
    canAdd: canCreate(PERMISSION_RESOURCES.skill),
    rename: {
      canUpdate: showActionBar && canUpdate(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.skill }),
      canView: showActionBar && canView(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.skill }),
    },
    canMerge: showActionBar && canMerge(PERMISSION_RESOURCES.skill),
    canBulkDelete: showActionBar && canUpdateOrCreate,
    canDragRows: showActionBar && canUpdateOrCreate,

    canUpdate: useCallback(data => canUpdate(PERMISSION_RESOURCES.skill, { data }), [canUpdate]),
  };

  const settingsGridColumns = useSettingsGridColumns({
    allowActions: permissions.allowActions,
    canUpdate: permissions.canUpdate,
    canDragRows: permissions.canDragRows,
    selectionMode,
    setItemToDelete,
    items: skills,
  });

  const settingsGridEventHandlers = useSettingsGridEventHandlers({
    settingType: SKILLS,
    items: skills,
    focusField: TITLE_FIELD,
    actions: {
      update: updateSkillById,
      save: createSkill,
      remove: removeUnsavedSkills,
      drag: handleRowDrag,
    },
  });

  const visibleColumns = useForceVisibleColumns(skillsGridColumnDefs, forceVisibleFields);

  // Join settings and skills column definitions
  const columnDefs = useMemo(
    () => [...settingsGridColumns.beforeColumnDefs, ...visibleColumns, ...settingsGridColumns.afterColumnDefs],
    [settingsGridColumns, visibleColumns],
  );

  return (
    <>
      {permissions.allowActions && (
        <SettingsActionsToolbar
          settingKey={SKILL_KEY}
          settingType={SKILLS}
          systemFields={systemFields}
          updateSystemFieldsName={updateSystemFieldsName}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          onEnableSelection={enableSelectionMode}
          onCancelSelection={disableSelectionMode}
          add={addSkillWithoutSave}
          renamePermissions={permissions.rename}
          hideAddButton={!permissions.canAdd}
          // Merge
          canMerge={permissions.canMerge}
          showMergeLightbox={showMergeLightbox}
          // Bulk
          canBulkDelete={permissions.canBulkDelete}
          setShowBulkDeleteDialog={setShowBulkDeleteDialog}
        />
      )}
      <BaseGrid
        onGridReady={updateSelectedOnAgGridInit}
        rowData={skills}
        columnDefs={columnDefs}
        defaultColDef={settingsGridColumns.defaultColDef}
        {...settingsGridProps}
        {...settingsGridEventHandlers}
        height={customHeight ?? settingsGridProps.height}
      />
      <SettingsDialogs
        settingKey={SKILL_KEY}
        settingType={SKILLS}
        deleteProgress={deleteProgress}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        deleteItem={deleteItem}
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
        // Merge
        mergeLightboxVisible={mergeLightboxVisible}
        hideMergeLightbox={hideMergeLightbox}
        merge={mergeSkills}
        // Bulk
        bulkDelete={bulkDeleteItems}
        showBulkDeleteDialog={showBulkDeleteDialog}
        setShowBulkDeleteDialog={setShowBulkDeleteDialog}
      />
    </>
  );
};

export default Skills;
