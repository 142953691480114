import moment from 'moment';
import compact from 'lodash/compact';
import { prop, map, pick, reduce, keys, defaultTo, assoc, pipe, uniqBy } from 'ramda';
import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';
import tagMetadataOptionsIfExcludedByPageFilter from 'src/utils/filters/tagFilterMetadataOptionsIfExcludedByPageFilter';

import { materialColors } from 'design-system/themes/default';

const defaultToEmptyArray = defaultTo([]);
const defaultToEmptyObject = defaultTo({});

const _groomMonthGroup = d => ({
  id: moment(d).format('YYYY-MM'),
  title: moment(d).format('YYYY-MM'),
  start: moment(d).startOf('month'),
  end: moment(d).endOf('month'),
  isDatesGroup: true,
});

const _groomQuarterGroup = d => ({
  id: `${moment(d).format('YYYY')}-Q${moment(d).format('Q')}`,
  title: `${moment(d).format('YYYY')}-Q${moment(d).format('Q')}`,
  start: moment(d).startOf('quarter'),
  end: moment(d).endOf('quarter'),
  isDatesGroup: true,
});

const _getGroomFactory = dateGrouping => (prop('groupBy', dateGrouping) === 'month' ? _groomMonthGroup : _groomQuarterGroup);
const groomDatesGroupArray = d => map(_getGroomFactory(d));

const GROUPS_WITH_NO_UNDEFINED_VALUE = ['planningStages'];

const GROUPS_WITH_UNDEFINED_COLOR = ['statusColors'];

const UNDEFINED_GROUP = { id: null };

const _addUndefinedGroup = (key, groupingObjects) => {
  if (GROUPS_WITH_NO_UNDEFINED_VALUE.includes(key)) {
    return groupingObjects;
  }

  const isDatesGroup = groupingObjects.some(obj => obj.start && obj.end);

  const undefinedGroup = { ...UNDEFINED_GROUP, isDatesGroup };

  if (GROUPS_WITH_UNDEFINED_COLOR.includes(key)) {
    undefinedGroup.color = materialColors.lightGray;
  }

  return [...groupingObjects, undefinedGroup];
};

const getCustomFieldsGroupOptions = groupObjects => {
  return groupObjects.reduce((acc, eachCf) => {
    const isDropdownCustomField = groupObjects?.some(groupObject => groupObject?.field_type === TYPES_OF_CUSTOM_FIELDS.DROPDOWN);

    if (isDropdownCustomField) {
      return assoc(
        `custom_fields.${eachCf.key}`,
        eachCf?.data
          ? Object.entries(eachCf.data_enhanced).map(([entry, data]) => {
              const { color, title } = data;

              return {
                id: entry,
                title,
                field_type: eachCf?.field_type,
                color,
              };
            })
          : [],
        acc,
      );
    }

    return acc;
  }, {});
};

const addCustomFieldsOptionsToGroupingObjects = groupingObjects => {
  return {
    ...defaultToEmptyObject(getCustomFieldsGroupOptions(groupingObjects?.customFields || [])),
    ...groupingObjects,
  };
};

const getCompactUniqById = pipe(defaultToEmptyArray, uniqBy(prop('id')), compact);

/**
 * @function addUndefinedObjectsToUniqueGroupingCollections
 *
 * Adds the Undefined group to all collections and enforces uniqBy ID
 *
 * @param  {Object} groupingObjects - collection of grouping objects grouped by key
 */
const addUndefinedObjectsToUniqueGroupingCollections = groupingObjects => {
  return reduce(
    (acc, key) => assoc(key, _addUndefinedGroup(key, getCompactUniqById(groupingObjects[key])), acc),
    {},
    keys(groupingObjects),
  );
};

/**
 * @function getDefaultGroupingObjectsWithCustomFieldsAndDates
 *
 * Given a set of custom metadata or metadata coming from the store,
 * it parses that data as groups, adding Dates and Custom Fields Groups
 * Adds the undefined Group per collection and removes duplicates
 *
 * @param  {Object} customMetadata
 * @param  {Object} defaultGroupingObjects
 * @param  {Array} groupedDates
 */
const getDefaultGroupingObjectsWithCustomFieldsAndDates = ({ customMetadata, defaultGroupingObjects, groupedDates }) => {
  const shouldOverwriteStoreDefaults = !!customMetadata;

  let groupingObjects = null;

  if (shouldOverwriteStoreDefaults)
    groupingObjects = {
      ...customMetadata,
      ...pick(['statusColors', 'planningStages', 'customFields'], defaultGroupingObjects),
      dates: groupedDates,
    };
  else {
    groupingObjects = { ...defaultGroupingObjects, dates: groupedDates };
  }

  return pipe(addCustomFieldsOptionsToGroupingObjects, addUndefinedObjectsToUniqueGroupingCollections)(groupingObjects);
};

const getTaggedGroupingObjectsIfExcludedOnFilters = (groupingObjects, pageFilters, groupFilterFunctions) =>
  reduce(
    (enrichedGroupingObjects, metadataKey) => ({
      ...enrichedGroupingObjects,
      [metadataKey]: tagMetadataOptionsIfExcludedByPageFilter(groupingObjects, pageFilters, metadataKey, groupFilterFunctions),
    }),
    {},
    keys(groupingObjects),
  );

export { groomDatesGroupArray, getDefaultGroupingObjectsWithCustomFieldsAndDates, getTaggedGroupingObjectsIfExcludedOnFilters };
