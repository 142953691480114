import React from 'react';
import { useSelector } from 'react-redux';

import usePermissionsDialog from 'hooks/usePermissionsDialog';
import usePageNavigation from 'hooks/usePagesNavigation/usePageNavigation';
import theme from 'design-system/theme';
import PageHeaderFilters from 'containers/PageHeaderFilters';
import { getTotalProjectResults } from 'store/projects';

export default props => {
  const { isDialogVisible: isAccessDenied } = usePermissionsDialog();
  const totalResults = useSelector(getTotalProjectResults);

  const { pages, selectedPage, setSelectedPage } = usePageNavigation('/dashboard', props.history);

  if (isAccessDenied) return '';

  const { showQuickFilter, showFilterIcon, showResultsCounter, showDisplayLayer } = props;

  return (
    <PageHeaderFilters
      history={props.history}
      subNavigationTabs={pages}
      showQuickFilter={showQuickFilter}
      showFilterIcon={showFilterIcon}
      showResultsCounter={showResultsCounter}
      showDisplayLayer={showDisplayLayer}
      selectedSubNavigationTab={selectedPage}
      handleSubNavigationChange={setSelectedPage}
      pageIconBackground={theme.palette.newLayout.background.fountainBlue}
      customNumberOfResults={totalResults}
    />
  );
};
