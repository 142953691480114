import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { defaultTo, isEmpty } from 'ramda';

import { getPageFilters } from 'store/filters/selectors';
import { getRoadmaps, getCorpRoadmaps } from 'store/roadmaps/selectors';
import { getOrganization } from 'store/organization/selectors';

import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';

import { filterMetadataOptions } from 'utils/metadataRoadmaps';

const makeGetTaggedMetadataIfExcludedFromQuickfilter = filteredMetadata => (key, objects) => {
  const existsInFilteredMetadata = obj => filteredMetadata[key].find(eachObj => eachObj.id === obj.id);

  return objects.map(eachObj => {
    if (existsInFilteredMetadata(eachObj)) return eachObj;

    return { ...eachObj, excludedFromFilter: true };
  });
};

export default () => {
  const organization = useSelector(getOrganization);
  const pageFilters = useSelector(getPageFilters);
  const { quickFilters } = pageFilters;
  const { enable_metadata_roadmaps: hasMetadataRoadmaps } = organization;

  const { isDodActive, getDefaultRoadmapForMetadataItem } = useOrganizationsAccessControl();

  const filterValidRoadmaps = useCallback(
    () => roadmap => {
      const selectedRoadmaps = defaultTo([], quickFilters?.roadmaps);

      return isEmpty(selectedRoadmaps) || selectedRoadmaps.includes(roadmap);
    },
    [quickFilters?.roadmaps],
  );
  const filterValidRoadmapsCorp = useCallback(
    corpRoadmap => {
      const selectedRoadmapsCorp = defaultTo([], quickFilters?.roadmapsCorp);

      return isEmpty(selectedRoadmapsCorp) || selectedRoadmapsCorp.includes(corpRoadmap);
    },
    [quickFilters?.roadmapsCorp],
  );

  const roadmapsCorp = useSelector(state => getCorpRoadmaps(state, false));
  const roadmaps = useSelector(getRoadmaps) ?? [];

  const selectableRoadmapCorps = roadmapsCorp;

  return useCallback(
    (metadata, projects) => {
      if (!Object.keys(quickFilters || {}).length || !hasMetadataRoadmaps) {
        return metadata;
      }

      const metadataWithSelectedRoadmaps = {
        ...metadata,
        roadmapsCorp: roadmapsCorp.filter(filterValidRoadmapsCorp),
        roadmaps: roadmaps.filter(filterValidRoadmaps),
      };

      const filteredMetadataOptions = filterMetadataOptions(
        hasMetadataRoadmaps,
        projects,
        metadataWithSelectedRoadmaps,
        quickFilters,
        {
          isDodActive,
          getDefaultRoadmapForMetadataItem,
        },
      );

      const getTaggedMetadataIfExcludedFromQuickfilter = makeGetTaggedMetadataIfExcludedFromQuickfilter(filteredMetadataOptions);

      return Object.entries(metadata).reduce(
        (metadata, [eachKey, metadataObjects]) => ({
          ...metadata,
          [eachKey]: getTaggedMetadataIfExcludedFromQuickfilter(eachKey, metadataObjects),
        }),
        {},
      );
    },
    [
      selectableRoadmapCorps,
      filterValidRoadmapsCorp,
      roadmaps,
      filterValidRoadmaps,
      hasMetadataRoadmaps,
      quickFilters,
      isDodActive,
      getDefaultRoadmapForMetadataItem,
    ],
  );
};
