import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchReleaseDashboardsProjects } from 'store/dashboards';
import { getPageFilters, getUserFilters } from 'store/filters/selectors';
import { getProjectLightboxOpen } from 'store/projectLightbox/selectors';
import { getOrgHasBet } from 'store/organization';

import { DASHBOARDS_PAGE, CHILDREN_FILTERS } from 'constants/filters';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import useOrganizations from 'hooks/useOrganizations';

/**
 * hook to fetch the projects for the release dashboard
 *
 */
export default ({ projectsSubFilters = {}, displayLayer, allLevels = true }) => {
  const dispatch = useDispatch();

  const filters = useSelector(state => getPageFilters(state, DASHBOARDS_PAGE));
  const userFilters = useSelector(state => getUserFilters(state));
  const isProjectLightboxOpen = useSelector(getProjectLightboxOpen);
  const hasBet = useSelector(getOrgHasBet);

  const {
    filters: { availableLayers, topLayer },
  } = useOrganizations();

  const layer = displayLayer || topLayer;

  const filtersForApiByLevels = useMemo(
    () => compileFiltersBody(filters, userFilters, hasBet, DASHBOARDS_PAGE, layer, null, projectsSubFilters),
    [filters, userFilters, hasBet, layer, projectsSubFilters],
  );

  useEffect(() => {
    const params = {
      ...filtersForApiByLevels,
      layer$in: allLevels ? Object.values(availableLayers) : layer,
      children: CHILDREN_FILTERS.noChildren,
      withParents: allLevels,
    };

    if (!isProjectLightboxOpen) dispatch(fetchReleaseDashboardsProjects(params));
  }, [filtersForApiByLevels, isProjectLightboxOpen]);
};
