import { SUPPORTED_FIELDS } from 'utils/roadmapVersions/supportedFieldsUtils';

/**
 * @function applyCheckIsCreatingOrViewingScenario
 *
 * apply function to check edition ability cell when the grid is
 * creating or viewing some scenario
 *
 * @param  {Function} checkIsEditable
 * @return {Function}
 */
const applyCheckIsCreatingOrViewingScenario = checkIsEditable => {
  return params => {
    const { colDef, context } = params;
    const { isCreatingOrViewingScenario } = context;
    const isEditable = typeof checkIsEditable === 'function' ? checkIsEditable(params) : checkIsEditable;

    if (isCreatingOrViewingScenario && isEditable) {
      const field = SUPPORTED_FIELDS.find(f => f.key === colDef.field);

      return Boolean(field);
    }

    return isEditable;
  };
};

export default applyCheckIsCreatingOrViewingScenario;
