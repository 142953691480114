import React from 'react';
import styled from 'styled-components';

import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';
import formatPercent from 'design-system/utils/formatPercent';
import { PROGRESS_PERCENTAGE_DECIMAL_PLACES } from 'constants/common';

const LargeProgressBar = ({ value, color, ...props }) => (
  <Container {...props}>
    <StyledText variant="label">{formatPercent(value, PROGRESS_PERCENTAGE_DECIMAL_PLACES)}</StyledText>
    <Bar>
      <Progress value={formatPercent(value, PROGRESS_PERCENTAGE_DECIMAL_PLACES)} color={color} />
    </Bar>
  </Container>
);

export default LargeProgressBar;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${spacing()}px;
`;

const Bar = styled.div`
  width: 100%;
  height: 14px;
  background-color: ${({ theme }) => theme.palette.progressBar.background};
  border-radius: ${({ theme }) => theme.shape.borderRadiusExtraLarge}px;
  overflow: hidden;
`;

const Progress = styled.div`
  width: ${({ value }) => value};
  height: 100%;
  background-color: ${({ color, theme }) => color || theme.palette.primary.main};
`;

const StyledText = styled(Text)`
  min-width: 40px;
`;
