import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

import { SLOT_WIDTH, zoomModes } from './helpers';

import TimelineBody from './components/TimelineBody';
import TimelineHeader from './components/TimelineHeader';
import LanesLoadingOverlay from './components/LanesLoadingOverlay';
import { TimelineDisplayTable, TimelineScrollContainer } from './components/styled';

import useTimelineScroll from './hooks/useTimelineScroll';
import useTimelineDateRange from './hooks/useTimelineDateRange';
import useTimelineConverter from './hooks/useTimelineConverter';
import useTimelineToday from './hooks/useTimelineToday';
import useTimelineDecorateHandlers from './hooks/useTimelineDecorateHandlers';
import useTimelineGroups from './hooks/useTimelineGroups';

/**
 * This component should be independent from the page where is being used. So when refering to the enities
 * displayed inside the timeline we're calling items instead of projects or bars.
 *
 * */
const Timeline = ({
  className,
  // data
  data,
  isLoading,
  groupTitles,
  shouldResetData,
  isMilestoneItemChecker,

  // options
  groupWidths,
  zoomMode,
  slotWidth,
  displayMilestone,
  displayMilestoneOn,
  showTooltip,
  tooltipLabels,
  showTextOverflow,
  snapToGridOn,
  resizable,
  draggable,
  hideEmptyLane,
  groupTitleRenderer,

  // handlers
  onResizeGroupHeader,
  onDoubleClick,
  onRowClick,
  onDrag,
  onResize,
}) => {
  const contentRef = useRef(null);

  const groupsCount = compact(groupTitles).length;

  // Prevent scroll to today when the user performs the horizontal scroll
  const shouldScrollToToday = useRef(true);

  // Generate date range and slots for Timeline
  const [fromDate, toDate, updateDateRange, slots] = useTimelineDateRange(zoomMode);

  // Get open/collapse state functions for inside groups
  const { isGroupOpen, toogleGroup, groupOffsets } = useTimelineGroups(groupWidths);

  // Calculate the left coordinate for the today line
  const todayLineLeft = useTimelineToday({ contentRef, shouldScrollToToday, fromDate, zoomMode, groupWidths, slotWidth });

  // Convert data into Timeline items
  const { internalData, internalOrders, updateItemAfterDrag, updateItemAfterResize } = useTimelineConverter({
    data,
    zoomMode,
    slotWidth,
    fromDate,
    snapToGridOn,
    isGroupOpen,
    hideEmptyLane,
    groupsCount,
    isLoading,
    shouldResetData,
    groupOffsets,
    displayMilestone,
    displayMilestoneOn,
    isMilestoneItemChecker,
  });

  const { hasVerticalScroll, shouldShowItem, handleScroll } = useTimelineScroll({
    contentRef,
    fromDate,
    toDate,
    zoomMode,
    updateDateRange,
    shouldScrollToToday,
    internalOrders,
  });

  const { handleRowClick, handleDrag, handleResize } = useTimelineDecorateHandlers({
    handlers: { onRowClick, onDrag, onResize },
    options: {
      isLoading,
      snapToGridOn,
      fromDate,
      zoomMode,
      slotWidth,
      internalData,
      hasVerticalScroll,
      internalOrders,
      updateItemAfterDrag,
      updateItemAfterResize,
    },
  });

  if (isEmpty(internalData)) {
    return null;
  }

  return (
    <TimelineScrollContainer ref={contentRef} className={className} onScroll={handleScroll}>
      <TimelineDisplayTable>
        <TimelineHeader
          slots={slots}
          groupTitles={groupTitles}
          groupsWidths={groupWidths}
          fromDate={fromDate}
          toDate={toDate}
          zoomMode={zoomMode}
          slotWidth={slotWidth}
          onResizeGroupHeader={onResizeGroupHeader}
        />
        <TimelineBody
          isLoading={isLoading}
          selectedGroupsCount={groupsCount}
          data={internalData}
          orders={internalOrders}
          fromDate={fromDate}
          groupsWidths={groupWidths}
          groupOffsets={groupOffsets}
          mode={zoomMode}
          displayMilestone={displayMilestone}
          displayMilestoneOn={displayMilestoneOn}
          slotWidth={slotWidth}
          showTooltip={showTooltip}
          tooltipLabels={tooltipLabels}
          showTextOverflow={showTextOverflow}
          todayLine={todayLineLeft}
          snapToGridOn={snapToGridOn}
          shouldShowItem={shouldShowItem}
          hideEmptyLane={hideEmptyLane}
          resizable={resizable}
          draggable={draggable}
          groupTitleRenderer={groupTitleRenderer}
          isMilestoneItemChecker={isMilestoneItemChecker}
          isGroupOpen={isGroupOpen}
          toogleGroup={toogleGroup}
          onDoubleClick={onDoubleClick}
          onRowClick={handleRowClick}
          onDrag={handleDrag}
          onResize={handleResize}
        />
        {isLoading && <LanesLoadingOverlay hasScroll={hasVerticalScroll} contentRef={contentRef} groupWidths={groupWidths} />}
      </TimelineDisplayTable>
    </TimelineScrollContainer>
  );
};

export default Timeline;

Timeline.propTypes = {
  isLoading: PropTypes.bool,
  groupTitles: PropTypes.arrayOf(PropTypes.string),
  groupWidths: PropTypes.arrayOf(PropTypes.number),
  shouldResetData: PropTypes.bool,
  isMilestoneItemChecker: PropTypes.func,
  zoomMode: PropTypes.oneOf(Object.values(zoomModes)),
  slotWidth: PropTypes.number,
  displayMilestone: PropTypes.bool,
  showTooltip: PropTypes.bool,
  showTextOverflow: PropTypes.bool,
  snapToGridOn: PropTypes.string,
  resizable: PropTypes.bool,
  draggable: PropTypes.bool,
  hideEmptyLane: PropTypes.bool,
  groupTitleRenderer: PropTypes.func,
  onResizeGroupHeader: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onRowClick: PropTypes.func,
  onDrag: PropTypes.func,
  onResize: PropTypes.func,
};

Timeline.defaultProps = {
  isLoading: false,
  groupTitles: [],
  groupWidths: [0, 0, 0],
  shouldResetData: false,
  isMilestoneItemChecker: null,
  zoomMode: zoomModes.WEEK,
  slotWidth: SLOT_WIDTH,
  displayMilestone: false,
  showTooltip: true,
  showTextOverflow: false,
  snapToGridOn: null,
  resizable: true,
  draggable: true,
  hideEmptyLane: true,
  groupTitleRenderer: null,
  onResizeGroupHeader: null,
  onDoubleClick: null,
  onRowClick: null,
  onDrag: null,
  onResize: null,
};
