import { SET_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED } from 'store/customerRequests/types';
import {
  SET_PAGE_FILTERS,
  CLEAR_PAGE_FILTERS,
  FETCH_USER_FILTERS_FULFILLED,
  CREATE_USER_FILTER_FULFILLED,
  DELETE_USER_FILTER_FULFILLED,
  UPDATE_USER_FILTER_FULFILLED,
  SET_GROUP_DATA_TYPE,
  SET_FILTERS,
  UPDATE_USER_FILTER_BY_REALTIME,
  SET_SAW_NO_ITEMS_DIALOG,
} from './types';
import { DEFAULT_PAGE_FILTERS, GLOBAL_FILTER, OBJECTIVES_FILTER } from 'constants/filters';
import { IDEA_LAYER } from 'store/projects/constants';
import updateRows from 'store/utils/updateRows';
import getDependantFiltersForUpdate from 'utils/filters/filtersDependencies';
import { UPDATE_OUTCOME_MODULE_FILTERS_FULFILLED } from 'store/goalMode/types';

const initialState = {
  ...DEFAULT_PAGE_FILTERS,
  userFilters: [],
  displayLayer: IDEA_LAYER,
  groupDataType: 'objective',
  sawNoItemsDialog: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_FILTERS: {
      const filters = action.payload;

      return {
        ...state,
        ...filters,
      };
    }
    case SET_PAGE_FILTERS: {
      const { filters } = action.payload;
      const page = action.payload.page || GLOBAL_FILTER;

      return {
        ...state,
        [page]: {
          ...filters,
        },
        ...getDependantFiltersForUpdate(page, filters),
      };
    }
    case CLEAR_PAGE_FILTERS: {
      const page = action.payload.page || GLOBAL_FILTER;

      return {
        ...state,
        ...{ [page]: DEFAULT_PAGE_FILTERS[page] },
      };
    }
    case FETCH_USER_FILTERS_FULFILLED: {
      const { data } = action.payload;

      return {
        ...state,
        userFilters: data,
      };
    }
    case CREATE_USER_FILTER_FULFILLED: {
      const { data } = action.payload;

      return {
        ...state,
        userFilters: [...state.userFilters, data],
      };
    }
    case DELETE_USER_FILTER_FULFILLED: {
      const { data: id } = action.payload;

      return {
        ...state,
        userFilters: state.userFilters.filter(f => f.id !== id),
      };
    }
    case UPDATE_USER_FILTER_FULFILLED: {
      const userFilters = updateRows(state.userFilters, action.payload);

      return {
        ...state,
        userFilters,
      };
    }
    case UPDATE_USER_FILTER_BY_REALTIME: {
      const userFilters = updateRows(state.userFilters, action.payload);
      // should aplly the default filter to each page
      const defaultFilters = action.payload instanceof Array ? action.payload.filter(f => f.default_filter) : [];

      return {
        ...state,
        ...defaultFilters.reduce((acc, f) => ({ ...acc, [f.page]: f.state }), {}),
        userFilters,
      };
    }
    case SET_GROUP_DATA_TYPE: {
      const { dataType } = action.payload;

      if (!dataType) return state;

      return {
        ...state,
        groupDataType: dataType,
      };
    }
    case SET_SAW_NO_ITEMS_DIALOG: {
      const { sawNoItemsDialog } = action.payload;

      return {
        ...state,
        sawNoItemsDialog,
      };
    }
    case SET_CUSTOMER_REQUESTS_MULTI_FILTERS_FULFILLED: {
      return {
        ...state,
        userFilters: updateRows(state.userFilters, action.payload),
      };
    }
    case UPDATE_OUTCOME_MODULE_FILTERS_FULFILLED: {
      const { userFilter, filters } = action.payload;

      return {
        ...state,
        userFilters: updateRows(state.userFilters, userFilter),
        [OBJECTIVES_FILTER]: {
          ...filters,
        },
      };
    }
    default:
      return state;
  }
}
