import { isEmpty, not, isNil, both, head, defaultTo, pipe, prop, last, filter, propEq, sort } from 'ramda';

import isNumeric from 'utils/isNumeric';
import isPercentage from 'utils/isPercentage';
import formatDate from 'utils/dates/formatDate';
import isTruthyOrZero from 'utils/isTruthyOrZero';
import formatNumber from 'utils/formatNumber';

export const METRIC_VALUES = 'metricValues';
export const ACTUAL = 'actual';
export const TARGET = 'target';
export const TYPE = 'type';
export const DATE = 'date';

const getMetrics = prop('metrics');
const defaultToEmptyString = defaultTo('');
const defaultToEmptyArray = defaultTo([]);
const isNotEmpty = pipe(isEmpty, not);
const isNotNil = pipe(isNil, not);
const getFirstMetric = pipe(getMetrics, defaultToEmptyArray, head);
const getMetricId = pipe(getFirstMetric, prop('id'));

export const aintNilNorEmpty = both(isNotNil, isNotEmpty);
export const getActualValue = pipe(getFirstMetric, prop('actual_value'), defaultToEmptyString);

export const getActualDate = pipe(getFirstMetric, prop('actual_date'), defaultToEmptyString, formatDate);
export const getTargetValue = pipe(getFirstMetric, prop('target_value'), defaultToEmptyString);
export const getTargetDate = pipe(getFirstMetric, prop('target_date'), defaultToEmptyString, formatDate);

// Casting metric value to Number to add leading zero on decimal numbers from 0 to 1
// and using 'toLocaleString' on it to add the thousands separator
export const formatMetricInputValue = value =>
  isTruthyOrZero(value) ? (isNumeric(value) ? Number(value).toLocaleString('en-US') : value) : null;

export const areAllValuesInPercentages = values => values.every(isPercentage);

const sortByDateAsc = (a, b) => new Date(a[DATE]) - new Date(b[DATE]);

const getActualMetrics = pipe(prop(METRIC_VALUES), defaultToEmptyArray, sort(sortByDateAsc), filter(propEq(TYPE, ACTUAL)));
const getTargetMetrics = pipe(prop(METRIC_VALUES), defaultToEmptyArray, sort(sortByDateAsc), filter(propEq(TYPE, TARGET)));

export const getActualMetricValue = pipe(getActualMetrics, last);
const getTargetMetricValue = pipe(getTargetMetrics, last);

/**
 * gets the metricValue that should be displayed based on most recent date by type
 * based on metrics from selector
 * @param {*} selected
 * @param {*} metrics
 * @param {*} type
 * @returns
 */
export const getMetricValue = (selected = {}, metrics = [], type = ACTUAL) => {
  const metricId = getMetricId(selected);
  const storedMetric = metrics.find(m => m.id === metricId);

  if (type === ACTUAL && storedMetric?.metricValues) {
    return getActualMetricValue(storedMetric) || {};
  }

  // for some reason was not able to find the selected metric
  if (!storedMetric) {
    return {};
  }

  return getTargetMetricValue(storedMetric) || {};
};

/**
 * Gets metric values from metrics
 * @param {String} storedMetric
 * @param {String} type
 * @returns {Object} metric values object
 */
export const getMetricValueFromMetricObject = (storedMetric, type = ACTUAL) => {
  if (type === ACTUAL && storedMetric?.metricValues) {
    return getActualMetricValue(storedMetric) || {};
  }

  // for some reason was not able to find the selected metric
  if (!storedMetric) {
    return {};
  }

  return getTargetMetricValue(storedMetric) || {};
};

/**
 * formats metric value numbers - if % display as is, empty should be null, invalid returns as is
 * if it meets numeric condition, formats to locale
 * @param {String} value
 * @returns
 */
export const formatMetricValueNumber = (value = null) => {
  if (!value) return null;

  if (isPercentage(value)) return value;

  if (!isNumeric(value)) return value;

  return formatNumber(value);
};
