import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useCommands } from '@remirror/react-core';
import React, { useState } from 'react';
import styled from 'styled-components';

const MenuPopup = ({ anchorEl, handleClose }) => {
  const commands = useCommands();

  // close the popup after clicking
  const handleClick = command => {
    return () => {
      command();
      handleClose();
    };
  };

  return (
    <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
      <MenuItem button onClick={handleClick(commands.addTableRowBefore)}>
        Insert row above
      </MenuItem>
      <MenuItem button onClick={handleClick(commands.addTableRowAfter)}>
        Insert row below
      </MenuItem>
      <MenuItem button onClick={handleClick(commands.addTableColumnBefore)}>
        Insert column before
      </MenuItem>
      <MenuItem button onClick={handleClick(commands.addTableColumnAfter)}>
        Insert column after
      </MenuItem>

      <MenuItem button onClick={handleClick(commands.deleteTableColumn)}>
        Remove column
      </MenuItem>
      <MenuItem button onClick={handleClick(commands.deleteTableRow)}>
        Remove row
      </MenuItem>
    </Menu>
  );
};

export default ({ popupOpen, setPopupOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setPopupOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopupOpen(false);
  };

  return (
    <div>
      <TableCellMenuButton aria-controls="simple-menu" aria-haspopup="true" size="small" onClick={handleClick}>
        <ExpandMoreIcon />
      </TableCellMenuButton>
      <MenuPopup anchorEl={popupOpen ? anchorEl : null} handleClose={handleClose} />
    </div>
  );
};

const TableCellMenuButton = styled(IconButton)`
  width: 20px;
  height: 20px;
  position: absolute;
  right: -5px;
`;
