import React from 'react';
import axios from 'axios';
import isFunction from 'lodash/isFunction';

import { getUserName } from 'utils';

const componentHOC = Component => {
  return props => {
    const _dataMapper =
      props.dataMapper ||
      (user => ({
        entity: user,
        label: getUserName(user),
        value: user.id,
      }));

    const _dataFilter = user => user.status !== 'Inactive';
    const [data, setData] = React.useState([]);

    const _loadData = value => {
      return new Promise(resolve => {
        const params = {
          name: value,
          from: 0,
          to: 20,
        };

        axios.get('/api/users', { params }).then(({ data: resData }) => {
          const _mappedData = resData.data.filter(_dataFilter).map(_dataMapper);

          setData(_mappedData);
          resolve(_mappedData);
        });
      });
    };
    const _onValueChange = (value, text) => {
      const option = data.find(p => p.id === value || p.value === value);

      if (props.onValueChange) props.onValueChange(value, text, option);
    };

    const _options = React.useMemo(() => {
      const suggestions = isFunction(props.suggestions) ? props.suggestions() : props.suggestions;
      const users = (suggestions || []).filter(_dataFilter).map(_dataMapper);

      const optionsWithCurrValue =
        props.value && data.find(o => o.label === props.value)
          ? [...data.filter(o => o.label === props.value), ...users.filter(o => o.label !== props.value)]
          : users;

      return props.value ? optionsWithCurrValue : data;
    }, [props.value, data, props.suggestions]);

    return (
      <Component
        {...props}
        loadData={_loadData}
        options={_options.filter(user => user.status !== 'Inactive')}
        onValueChange={_onValueChange}
      />
    );
  };
};

export default componentHOC;
