import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import groupBy from 'lodash/groupBy';
import { pick } from 'ramda';

import { INITIATIVE, BET } from 'constants/projects';
import { makeSelectFilteredProjectsByRoadmapForPage } from 'store/filters/selectors';
import { BET_LAYER, INITIATIVE_LAYER, IDEA_LAYER } from 'store/projects/constants';
import getRelevantStateKeysForProjects from 'store/utils/getRelevantStateKeysForProjects';
import convertToNumberOrUndefined from 'utils/convertToNumberOrUndefined';
import { isLoading, isUninitialized } from 'utils/store/thunk';
import { getOrgHasBet } from 'store/organization';

import { selectMetadata } from '../projects/metadataSelectors';
import { getEnrichedProject } from '../projects/selectors';

import { FETCH_RELEASE_DASHBOARDS_PROJECTS } from './types';

const isInitiativeOrBet = value => [INITIATIVE, BET].includes(value);
const projectsLayerMapping = {
  initiative: INITIATIVE_LAYER,
  bet: BET_LAYER,
};

const DATA_TYPE_MAPPER = {
  product1: 'product',
  keyResult1: 'keyResult',
  category: 'categorie',
};

/**
 *
 * @param {*} state - the redux state
 * @returns - the release dashboard state
 */
function getDashboardState(state) {
  return state?.dashboards || {};
}

const getAllLayersParents = projects => projects.filter(project => [INITIATIVE_LAYER, BET_LAYER].includes(project.layer));

/**
 * creates a selector for the release dashboard projects
 */
const defaultArrayPlaceholder = [];

export const getReleaseDashboardsProjects = createCachedSelector(
  state => state?.dashboards?.releaseDashboardsProjects || defaultArrayPlaceholder,
  getRelevantStateKeysForProjects,
  getOrgHasBet,
  (releaseProjects, stateForProjects, hasBets) => {
    const parents = getAllLayersParents(releaseProjects);
    const normalizedParents = groupBy(parents, p => p.id);

    // we need this verification because before we stored the projects in an object
    if (!Array.isArray(releaseProjects)) return [];

    const enrichedProjects = (releaseProjects || []).map(project => getEnrichedProject(stateForProjects, project));

    const reducedProjectsByLayer = enrichedProjects.reduce(
      (acc, enrichedProject) => {
        const projectLayer = enrichedProject.layer;

        if (enrichedProject.parent_id) {
          const normalizedParent = normalizedParents[enrichedProject.parent_id]?.[0] || {};

          enrichedProject.parent = normalizedParent;
          enrichedProject.parentLayer = normalizedParent.layer;
        } else {
          enrichedProject.parent = null;
        }

        // if (!hasBets && projectLayer === BET_LAYER) {
        //   return acc;
        // }

        acc[projectLayer].push(enrichedProject);

        return acc;
      },
      {
        [IDEA_LAYER]: [],
        [INITIATIVE_LAYER]: [],
        [BET_LAYER]: [],
      },
    );

    return reducedProjectsByLayer;
  },
)(() => 'all_layers_release_dashboards_projects');

export const getActiveDashboard = createSelector(getDashboardState, state => state.activeDashboard);

export const makeSelectGetProjectsGroupedBy = () => {
  const _selectProjects = makeSelectFilteredProjectsByRoadmapForPage();

  return createSelector(
    (state, pageId, _, layer = IDEA_LAYER) => {
      return _selectProjects(state, pageId, false, layer);
    },
    state => selectMetadata(state),
    (_, __, preferences) => preferences,
    (projects, metadata, preferences) => {
      const { selectedGroupByLvl1, selectedGroupByLvl2 } = preferences;

      // 1. create an object thats groups projects by selected group like
      // { timeframe_1: { roadmap_1: [project1, project2, project3], roadmap_2: [project4, project5, project6] },
      //   timeframe_2: {roadmap_3: [project7, project8]} }

      const _getEntities = key => {
        const _entityKey = DATA_TYPE_MAPPER[key] || key;

        return metadata[`${_entityKey}s`];
      };
      const _getGroupData = groupByKey => {
        const _groupData = {};

        if (!isInitiativeOrBet(groupByKey)) {
          Object.keys(_getEntities(groupByKey)).forEach(md => {
            _groupData[md] = {};
          });
        } else {
          // add an entry for initiative id or bet id
          // when showing we filter by initiative or bet - now it doesn't matter
          projects.forEach(p => {
            if (p.parent_id && p.parentLayer === projectsLayerMapping[groupByKey]) {
              _groupData[p.parent_id] = {};
            }
          });
        }

        _groupData.undefined = {};

        return _groupData;
      };
      const _getProjectsByGroups = (groupBy1Id, groupBy2Id) => {
        groupBy1Id = convertToNumberOrUndefined(groupBy1Id);

        if (groupBy2Id) groupBy2Id = convertToNumberOrUndefined(groupBy2Id);

        const _filterProjectByGroup = (p, groupByKey, groupById) => {
          if (!groupByKey) return true;

          return p[groupByKey]?.id === groupById;
        };

        return projects.filter(p => {
          if (!isInitiativeOrBet(selectedGroupByLvl1.key)) {
            return (
              _filterProjectByGroup(p, selectedGroupByLvl1.key, groupBy1Id) &&
              _filterProjectByGroup(p, selectedGroupByLvl2?.key, groupBy2Id)
            );
          }

          return (
            p.parent_id === groupBy1Id &&
            p.parentLayer === projectsLayerMapping[selectedGroupByLvl1.key] &&
            _filterProjectByGroup(p, selectedGroupByLvl2?.key, groupBy2Id)
          );
        });
      };

      const _getDataLevel1 = groupByKey => {
        const _groupData = _getGroupData(groupByKey);

        return Object.keys(_groupData).reduce((acc, firstGroupId) => {
          return {
            ...acc,
            [firstGroupId]: _getProjectsByGroups(firstGroupId),
          };
        }, {});
      };
      const _getDataLevel2 = (currentData, groupByKey) => {
        const _groupData = _getGroupData(groupByKey);
        const _result = {};

        Object.keys(currentData).forEach(firstGroupId => {
          _result[firstGroupId] = Object.keys(_groupData).reduce((acc, secondGroupId) => {
            return { ...acc, [secondGroupId]: _getProjectsByGroups(firstGroupId, secondGroupId) };
          }, {});
        });

        return _result;
      };

      let result = {};

      if (selectedGroupByLvl1?.key) result = _getDataLevel1(selectedGroupByLvl1.key);
      if (selectedGroupByLvl2?.key) result = _getDataLevel2(result, selectedGroupByLvl2.key);

      return result;
    },
  );
};

export const getProjectsGroupedBy = makeSelectGetProjectsGroupedBy();

const getOperationsState = createSelector([getDashboardState], dashboardState => dashboardState?.operations ?? {});

export const isDashboardProjectsLoading = createSelector(getOperationsState, state =>
  isLoading(state, FETCH_RELEASE_DASHBOARDS_PROJECTS),
);

export const isDashboardProjectsUninitialized = createSelector(getOperationsState, state =>
  isUninitialized(state, FETCH_RELEASE_DASHBOARDS_PROJECTS),
);

export const isDashboardInEditMode = createSelector([getDashboardState], dashboardState => dashboardState?.inEditMode ?? false);

export const getDashboardDateRangeValues = createSelector([getDashboardState], state =>
  pick(['progressStartDate', 'progressEndDate'], state),
);
