import { includes, prop } from 'ramda';
import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';

const getEnhancedData = obj => prop('data_enhanced', obj);
const getData = obj => prop('data', obj);

const DROPDOWN_FIELD_TYPES = [TYPES_OF_CUSTOM_FIELDS.DROPDOWN, TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN];

const isFieldDropdownType = fieldType => includes(fieldType, DROPDOWN_FIELD_TYPES);

export default customFields => {
  return customFields
    .filter(f => isFieldDropdownType(f.field_type))
    .reduce((acc, cf) => {
      const enhancedOptionsData = getEnhancedData(cf);
      const optionsData = getData(cf);

      const fieldData = enhancedOptionsData || optionsData || {};

      return {
        ...acc,
        [cf.key]: Object.entries(fieldData).map(([key, value]) => ({
          id: key,
          title: value.title || value,
          archived: value.archived,
        })),
      };
    }, {});
};
