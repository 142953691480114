import React, { useMemo, useCallback } from 'react';
import { css } from 'styled-components';
import { defaultTo } from 'ramda';

import PDLCSettings from 'routes/Settings/pdlc';
import { CYCLE_DELIVERABLE_LEVEL } from 'constants/common';

const defaultToZero = defaultTo(0);

const visibleFields = ['drag', 'title', 'color', 'actions'];

const BASE_GRID_HEIGHT = 40;
// Give space for Cycle and 2 more new child
const BASE_CYCLE_DELIVERABLE_ROW_HEIGHT = 100;

const getChildrenHeight = children => Math.min(defaultToZero(children?.length) * 40, 300);

const isDeliverable = cycleDeliverable => cycleDeliverable?.level === CYCLE_DELIVERABLE_LEVEL.deliverable;

// lightbox container will set the spacing
const customCssStyles = css`
  .ag-layout-normal.ag-root {
    margin: 0 10px;
  }
`;

const calcGridheightBasedOnChildren = cycle =>
  `${BASE_GRID_HEIGHT + BASE_CYCLE_DELIVERABLE_ROW_HEIGHT + getChildrenHeight(cycle?.children)}px`;

const CycleSettingsContent = ({ filterByCycle, handleOnOpenDeliverable }) => {
  const customHeightBasedOnChild = useMemo(() => calcGridheightBasedOnChildren(filterByCycle), [filterByCycle?.children?.length]);

  const onTitleClick = useCallback(
    params => {
      const idClicked = params?.data?.id;

      if (idClicked && isDeliverable(params?.data)) {
        handleOnOpenDeliverable(idClicked);
      }
    },
    [handleOnOpenDeliverable],
  );

  return (
    <PDLCSettings
      showActionBar={false}
      forceVisibleFields={visibleFields}
      filterByCycleDeliverableId={filterByCycle?.id}
      customHeight={customHeightBasedOnChild}
      cssStyles={customCssStyles}
      onTitleClick={onTitleClick}
    />
  );
};

export default CycleSettingsContent;
