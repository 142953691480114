import styled, { css } from 'styled-components';

import Button from 'design-system/atoms/Button/index';
import NewDialog from 'design-system/organisms/NewDialog/index';

import { spacing } from 'design-system/theme';

const AiAnalyzerButton = styled(Button)`
  &&&& {
    height: 37px;
    margin-right: ${spacing()}px;

    svg {
      width: 22px;
      height: 22px;
      margin-right: ${spacing(1.5)}px;

      path {
        fill: ${({ theme }) => theme.palette.white};
      }
    }
  }
`;

const StyledDialog = styled(NewDialog)`
  &&&& {
    div[role='document'] > div {
      height: 100%;

      //When on the Preview screen, we need a bigger top margin to display some content
      ${({ increaseTopMargin }) =>
        increaseTopMargin &&
        css`
          margin-top: ${spacing(10)}px;
          min-height: calc(100% - 128px);
          max-height: calc(100% - 128px);
        `}
    }
  }
`;

export { AiAnalyzerButton, StyledDialog };
