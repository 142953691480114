import Button from '@material-ui/core/Button';
import styled from 'styled-components';

import { spacing } from 'design-system/theme';
import FilterDropdown from 'design-system/molecules/FilterDropdown';
import FilterDropdownWithDndList from 'design-system/molecules/FilterDropdownWithDndList';

const Wrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ actionsPadding }) => actionsPadding || '5px 26px 5px 36px'};
`;

const RightButtons = styled.div`
  display: inline-flex;
  margin-left: auto;
`;

const RightButtonsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: ${spacing(1.25)}px;

  flex-flow: ${({ $allowActions, $isSelectionMode }) => ($allowActions && $isSelectionMode ? 'row wrap' : null)};

  height: 48px;
`;

const AddNewButton = styled(Button)`
  &&&& {
    margin-top: ${spacing(0.5)}px;
  }
`;

const CancelButton = styled(Button)`
  && {
    margin-left: 30px;
  }
`;

const StatusFilterDropdown = styled(FilterDropdown)`
  &&& {
    margin-left: 0 !important;
  }
`;

const UserFilterDropdownWithDndList = styled(FilterDropdownWithDndList)`
  &&&& {
    width: 140px;

    label {
      margin-left: ${spacing(0.625)}px;
    }
  }
`;

export {
  AddNewButton,
  CancelButton,
  RightButtons,
  RightButtonsContainer,
  Wrapper,
  StatusFilterDropdown,
  UserFilterDropdownWithDndList,
};
