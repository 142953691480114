import React from 'react';

import { CustomerRequestsCommentsLightboxProvider } from 'hooks/customerRequests/useCustomerRequestsCommentsLightbox';

import RequestsGrid from './RequestsGrid';

export default ({ ...props }) => {
  return (
    <CustomerRequestsCommentsLightboxProvider>
      <RequestsGrid {...props} />
    </CustomerRequestsCommentsLightboxProvider>
  );
};
