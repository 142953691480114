import { useCallback } from 'react';

import history from 'store/utils/history';
import { isProjectsFlatSearchActive } from 'utils/grid';
import { IDEAS_GRID_FLAT_PATH, IDEAS_GRID_PATH } from 'constants/filters/pages';

/**
 * @function useProjectsFlatSearchPage
 *
 * This hoook has the methods to apply or remove project flat search page
 *
 * @return {Object}
 */
const useProjectsFlatSearchPage = () => {
  const closeProjectsFlatSearchPage = useCallback(() => {
    history.push(`/${IDEAS_GRID_PATH}`);
  }, []);

  const openProjectsFlatSearchPage = useCallback(() => {
    return history.push(`/${IDEAS_GRID_FLAT_PATH}`);
  }, []);

  return {
    closeProjectsFlatSearchPage,
    openProjectsFlatSearchPage,
    isProjectsFlatSearchPageActive: isProjectsFlatSearchActive(),
  };
};

export default useProjectsFlatSearchPage;
