/* eslint-disable */
/**
 * UnfilterIcon Atom Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function jiraIcon(props) {
  return (
    <SvgIcon { ...props } style={{ ...props.style}} width="100%" height="100%" viewBox="0 0 40 40">
      <g transform="matrix(0.991287,0,0,0.991287,-288.795,-252.674)">
        <g id="surface1">
            <g>
                <path d="M328.984,269.094L303.203,257.258L303.203,255.887C303.203,255.754 303.176,255.625 303.125,255.5C303.074,255.379 303,255.27 302.906,255.172C302.809,255.078 302.699,255.008 302.578,254.953C302.453,254.902 302.324,254.879 302.188,254.879C302.055,254.879 301.926,254.902 301.801,254.953C301.676,255.008 301.566,255.078 301.473,255.172C301.375,255.27 301.301,255.379 301.25,255.5C301.199,255.625 301.176,255.754 301.176,255.887L301.176,294.219C301.176,294.355 301.199,294.484 301.25,294.605C301.301,294.73 301.375,294.84 301.473,294.934C301.566,295.027 301.676,295.102 301.801,295.152C301.926,295.203 302.055,295.23 302.188,295.23C302.324,295.23 302.453,295.203 302.578,295.152C302.699,295.102 302.809,295.027 302.906,294.934C303,294.84 303.074,294.73 303.125,294.605C303.176,294.484 303.203,294.355 303.203,294.219L303.203,278.824L328.859,270.973C329.059,270.914 329.227,270.801 329.355,270.637C329.488,270.473 329.559,270.285 329.574,270.078C329.586,269.867 329.539,269.676 329.434,269.496C329.324,269.316 329.176,269.18 328.984,269.094ZM303.203,276.715L303.203,259.48L325.73,269.82L303.203,276.715Z" />
            </g>
        </g>
      </g>
    </SvgIcon>
  );
}
