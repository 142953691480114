import moment from 'moment-timezone';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import head from 'lodash/head';
import compact from 'lodash/compact';
import isFunction from 'lodash/isFunction';
import { isEmpty, pipe, all, map, sort, pluck, filter, isNil, not } from 'ramda';

import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';

import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import {
  groomDatesGroupArray,
  getDefaultGroupingObjectsWithCustomFieldsAndDates,
  getTaggedGroupingObjectsIfExcludedOnFilters,
} from './helpers/groupingObjects';
import { sortByRankAndTitle, sortGroups } from './helpers/sorting';
import { getNestedProjectGroups } from './helpers/nestedStructure';

import { mapGroupKeyToField } from '../groupOptions';

const getProjectsForGrouping = (onlyDisplayLayer, displayLayer, allProjectsByLayer, projectsFilters) => {
  const projectsForGrouping = onlyDisplayLayer
    ? allProjectsByLayer?.[displayLayer] ?? []
    : flatten(Object.values(allProjectsByLayer));
  const validProjectsFilters = projectsFilters.filter(filter => isFunction(filter));

  if (!isEmpty(projectsForGrouping) && !isEmpty(validProjectsFilters)) {
    return validProjectsFilters.reduce((acc, filter) => {
      const filteredProjects = projectsForGrouping.filter(filter);

      return [...acc, ...filteredProjects];
    }, []);
  }

  return projectsForGrouping;
};

const getProjectsWithHierarchyForGrouping = (allProjectsByLayer, hasBet) =>
  flatten(
    Object.values({
      ...allProjectsByLayer,
      [BET_LAYER]: hasBet ? allProjectsByLayer[BET_LAYER] : [],
    }),
  );

const isProjectInGroup = (project, groupObject, groupLevel) => {
  const groupLevelField = groupLevel?.field;
  const fieldValue = get(project, groupLevelField, null);

  const isDropdownCustomFieldGroup = groupObject?.field_type === TYPES_OF_CUSTOM_FIELDS.DROPDOWN;

  if (isDropdownCustomFieldGroup) {
    return groupObject?.id === fieldValue || groupObject?.id === fieldValue;
  }

  const isUndefinedGroup = !groupObject.id;

  if (isUndefinedGroup) {
    if (!project.id) {
      const isCustomFieldGroup = groupLevel?.key?.startsWith('custom_fields.');

      if (isCustomFieldGroup) {
        return groupObject?.id === fieldValue?.id || groupObject?.id === fieldValue;
      }
    }

    if (groupObject?.isDatesGroup || ['status_color'].includes(groupLevelField)) {
      return isEmpty(fieldValue);
    }

    if (groupLevel?.field === 'parent_id') {
      if (groupLevel?.key === 'bet') {
        return project?.parentLayer !== BET_LAYER;
      }
      if (groupLevel?.key === 'initiative') {
        return project?.parentLayer !== INITIATIVE_LAYER;
      }
    }

    if (Array.isArray(fieldValue)) {
      return isEmpty(fieldValue) || head(fieldValue)?.id == null;
    }

    return fieldValue === null || fieldValue?.id === null;
  }

  const isDatesFieldGroup = groupObject?.start && groupObject?.end;

  if (isDatesFieldGroup) {
    const momentFieldValue = moment.isMoment(fieldValue) ? fieldValue : moment(fieldValue);

    return momentFieldValue > groupObject.start && momentFieldValue < groupObject.end;
  }

  // For tags, customers and so on
  if (!isEmpty(fieldValue) && Array.isArray(fieldValue)) {
    return fieldValue.map(field => field.id).includes(groupObject?.id);
  }

  return groupObject?.id === fieldValue || groupObject?.id === fieldValue?.id;
};

const addGroupElements = groupLevelElements => {
  if (!isEmpty(groupLevelElements) && groupLevelElements?.some(element => !!element?.group)) {
    const nextGroupLevelElements = groupLevelElements.flatMap(group =>
      group?.elements?.map(element => ({
        ...element,
      })),
    );

    return addGroupElements(nextGroupLevelElements);
  }

  return groupLevelElements;
};

const getAllProjectsFromGroups = groups => {
  if (isEmpty(groups)) {
    return [];
  }

  return groups.reduce((acc, group) => [...acc, ...addGroupElements(group?.elements)], []);
};

const isNotNil = pipe(isNil, not);
const sortByDate = sort((a, b) => moment(a) - moment(b));

/**
 * Set field value on a given group if it is no present
 *
 * @param  {Object} group
 * @param  {String} group.key key representing the type of the group
 * @param  {String} group.field the project attribute that holds the group value
 */
const _setFieldAttributeOnGroup = group => {
  const groupDoesntExist = !group;
  const groupAlreadyHasField = group?.field;
  const groupHasNoKey = !group?.key;

  if (groupDoesntExist || groupAlreadyHasField || groupHasNoKey) return;

  const field = mapGroupKeyToField(group.key);

  if (!field) return;

  return { ...group, field };
};

const setFieldAttributeOnGroups = map(_setFieldAttributeOnGroup);

/**
 * @function getProjects
 *
 * Given a set of options decides if should return all or a single layer of projects
 *
 * @param  {Object} allProjectsByLayer - projects grouped by layer
 * @param  {Object} options
 * @param  {Boolean} options.withHierarchy
 * @param  {Boolean} options.hasBets
 * @param  {Boolean} options.onlyConsiderDisplayLayer
 * @param  {String}  options.displayLayer
 * @param  {Array}  options.projectsFilters - extra layer of local filters to be applied to projects
 * @returns { Array }
 */
const getProjects = (
  allProjectsByLayer,
  { withHierarchy = false, hasBets = false, onlyConsiderDisplayLayer, displayLayer = IDEA_LAYER, projectsFilters },
) => {
  const projects = withHierarchy
    ? getProjectsWithHierarchyForGrouping(allProjectsByLayer, hasBets)
    : getProjectsForGrouping(onlyConsiderDisplayLayer, displayLayer, allProjectsByLayer, projectsFilters);

  return compact(projects);
};

/**
 * @function areAllLayersEmpty
 *
 * Given an Objects of projects grouped by layer checks if all layers are empty of projects
 *
 * @param  {Object} projectsByLayer - Arrays of projects grouped by layer
 * @returns {Boolean}
 */
const areAllLayersEmpty = projectsByLayer =>
  all(eachLayer => isEmpty(projectsByLayer[eachLayer]), [IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER]);

/**
 * @function getDateGroup
 *
 * Returns the first (and theoretically the only) selected group that is a Date group
 *
 * @param  {Array} groups - Array with 1st, 2nd and 3rd level grouping objects
 * @returns {Object}
 */
const getDateGroup = groups => head((groups || []).filter(group => group?.key === 'dates'));

/**
 * @function getGroupingDates
 *
 * Returns a list of objects representing timespans to be used as groups
 * These timespans are collected from projects' timelines
 *
 * @param  {Array} projects - Array of projects to be used
 * @param  {Object} dateGrouping - an object that represents the date grouping object on grouping dropdown
 * @returns {Array}
 */
const getGroupingDates = (projects, dateGrouping) => {
  const getProjectDates = pipe(pluck(dateGrouping?.field), filter(isNotNil));

  const extractAndGroomGroupingDates = pipe(getProjectDates, sortByDate, groomDatesGroupArray(dateGrouping));

  return extractAndGroomGroupingDates(projects);
};

/**
 * @function getGroupingObjects
 *
 * Returns an Object with arrays of metadata objects grouped by entity type (roadmaps, objectives, etc.)
 *
 * @param  {Object} options
 * @param  {Object} options.customMetadata - metadata that overrides the store
 * @param  {Object} options.defaultGroupingObjects - default grouping objects collected from store
 * @param  {Array} options.groupedDates - date groups collected from projects' timelines
 * @param  {Boolean} options.applyPageFiltersToMetadataGroup - boolean that determines if should always
 * display groups that exist on advanced search filter conditions (regardless empty or not)
 * @param  {Object} options.pageFilters
 * @returns {Object}
 */
const getGroupingObjects = ({
  customMetadata,
  defaultGroupingObjects,
  groupedDates,
  applyPageFiltersToMetadataGroup,
  tagMetadataBasedOnRoadmapQuickfilter,
  pageFilters,
  projects,
  groupFilterFunctions,
}) => {
  const defaults = getDefaultGroupingObjectsWithCustomFieldsAndDates({ customMetadata, defaultGroupingObjects, groupedDates });
  const filteredFields = applyPageFiltersToMetadataGroup
    ? getTaggedGroupingObjectsIfExcludedOnFilters(defaults, pageFilters, groupFilterFunctions)
    : {};

  const metadata = {
    ...defaults,
    ...filteredFields,
  };

  if (tagMetadataBasedOnRoadmapQuickfilter) {
    return tagMetadataBasedOnRoadmapQuickfilter(metadata, projects);
  }

  return metadata;
};

export {
  getNestedProjectGroups,
  getProjectsForGrouping,
  getProjectsWithHierarchyForGrouping,
  isProjectInGroup,
  getAllProjectsFromGroups,
  sortByRankAndTitle,
  sortGroups,
  getGroupingObjects,
  getGroupingDates,
  setFieldAttributeOnGroups,
  getProjects,
  areAllLayersEmpty,
  getDateGroup,
};
