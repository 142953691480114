export const SET_PAGE_FILTERS = 'SET_PAGE_FILTERS';
export const CLEAR_PAGE_FILTERS = 'CLEAR_PAGE_FILTERS';

export const CREATE_USER_FILTER = 'CREATE_USER_FILTER';
export const CREATE_USER_FILTER_FULFILLED = 'CREATE_USER_FILTER_FULFILLED';

export const FETCH_USER_FILTERS = 'FETCH_USER_FILTERS';
export const FETCH_USER_FILTERS_FULFILLED = 'FETCH_USER_FILTERS_FULFILLED';

export const UPDATE_USER_FILTER = 'UPDATE_USER_FILTER';
export const UPDATE_USER_FILTER_FULFILLED = 'UPDATE_USER_FILTER_FULFILLED';

export const DELETE_USER_FILTER = 'DELETE_USER_FILTER';
export const DELETE_USER_FILTER_FULFILLED = 'DELETE_USER_FILTER_FULFILLED';

export const SET_GROUP_DATA_TYPE = 'SET_GROUP_DATA_TYPE';
export const SET_FILTERS = 'SET_FILTERS';

export const UPDATE_USER_FILTER_BY_REALTIME = 'UPDATE_USER_FILTER_BY_REALTIME';

export const SET_SAW_NO_ITEMS_DIALOG = 'SET_SAW_NO_ITEMS_DIALOG';
