import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';

import { getGridCustomerRequests } from 'store/customerRequests/selectors';
import RequestsGridModal from 'features/RequestsGridModal';
import BaseLayout from 'design-system/organisms/BaseLayout';

import CounterPieChart from './CounterPieChart';
import CounterStackedChart from './CounterStackedChart';
import useLoadCustomerRequestsOnInsights from './hooks/useLoadCustomerRequestsOnInsights';
import { selectShowInsightsGrid, setInsightsFilters } from './store';
import useInsightsGridModalTitle from './hooks/useInsightsGridModalTitle';
import ControlsBar from '../ControlsBar';

export default React.memo(() => {
  const dispatch = useDispatch();
  const showInsightsGrid = useSelector(selectShowInsightsGrid);
  const customerRequests = useSelector(getGridCustomerRequests);

  useLoadCustomerRequestsOnInsights();

  const handleCloseGridLightbox = () => dispatch(setInsightsFilters([]));

  const gridModalTitle = useInsightsGridModalTitle();

  return (
    <BaseLayout toolbarComponent={<ControlsBar />}>
      <Wrapper>
        <Charts>
          <Grid container spacing={32}>
            <Grid item xs={12} md={4}>
              <CounterPieChart />
            </Grid>
            <Grid item xs={12} md={8}>
              <CounterStackedChart />
            </Grid>
          </Grid>
        </Charts>
        <RequestsGridModal
          customerRequests={customerRequests}
          title={gridModalTitle}
          isOpen={showInsightsGrid}
          handleClose={handleCloseGridLightbox}
        />
      </Wrapper>
    </BaseLayout>
  );
});

const Wrapper = styled.div``;

const Charts = styled.div`
  &&&& {
    width: 100%;
  }
`;
