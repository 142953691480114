import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { getCurrentUser } from 'store/login/selectors';
import { setAppGlobalConfig } from 'store/app';
import { REQUESTOR_USER } from '@dragonboat/permissions';

import lightenDarkenColor from 'design-system/utils/lightenDarkenColor';
import ButtonV2 from 'design-system/atoms/ButtonV2/index';


const INVITE_YOUR_TEAM = 'Invite your team';

export default props => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const _onInviteUsersClick = () => {
    dispatch(setAppGlobalConfig({ showInviteUsersDialog: true }));
  };

  if (currentUser?.role_id === REQUESTOR_USER) return null;

  return (
    <Wrapper>
      <ButtonV2
        id="invite-users-button"
        color={props.theme.palette.background.white}
        background={props.theme.palette.primary.main}
        hover={{ background: lightenDarkenColor(props.theme.palette.background.primary, -20) }}
        onClick={_onInviteUsersClick}
      >
        {INVITE_YOUR_TEAM}
      </ButtonV2>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-right: 20px;
  min-width: 166px;
`;
