import { useMemo } from 'react';

import { PERMISSION_RESOURCES, SYSTEM_FIELD } from '@dragonboat/permissions';
import usePermissions from 'hooks/permissions/usePermissions';

import { makeCheckAddVisibility } from '../helpers';
import { getPermissionResourceByOkrLevel } from 'utils/okrs';

const useObjectivesSettingsPermissions = ({
  organization,
  hasKeyResults,
  hasCorpLevel,
  isGoalMode,
  isChildDragon,
  isParentDragon,
  showActionBar,
  allowedLevel,
}) => {
  const { canUpdate, canCreate, canView } = usePermissions();
  const permissionResource = hasCorpLevel && !isChildDragon ? PERMISSION_RESOURCES.objectiveCorp : PERMISSION_RESOURCES.objective;
  const systemFieldKey = hasCorpLevel ? SYSTEM_FIELD.objectiveCorp : SYSTEM_FIELD.objective;
  const canUpdateOrCreate = canUpdate(permissionResource) || canCreate(permissionResource);

  const permissions = {
    allowActions: canUpdateOrCreate,
    canAddTopLevel: hasCorpLevel ? canCreate(PERMISSION_RESOURCES.objectiveCorp) : canCreate(PERMISSION_RESOURCES.objective),
    rename: {
      canUpdate: showActionBar && canUpdate(PERMISSION_RESOURCES.systemFieldName, { field: systemFieldKey }),
      canView: showActionBar && canView(PERMISSION_RESOURCES.systemFieldName, { field: systemFieldKey }),
    },
    canMerge: showActionBar && !isGoalMode && canUpdateOrCreate,
    canBulkDelete: showActionBar && !isGoalMode && canUpdateOrCreate,
    canDragRows: showActionBar && !isGoalMode && canUpdateOrCreate,
    checkRowDrag: params => canUpdate(getPermissionResourceByOkrLevel(params?.data?.level)),
  };

  const checkAddVisibility = useMemo(
    () => makeCheckAddVisibility(allowedLevel, { hasKeyResults, organization, hasCorpLevel, isParentDragon }),
    [allowedLevel],
  );

  return {
    permissions,
    checkAddVisibility,
    systemFieldKey,
    canUpdate,
    permissionResource,
  };
};

export default useObjectivesSettingsPermissions;
