import React from 'react';
import theme from 'design-system/theme';

import styled from 'styled-components';

const FONT_SIZE = theme.typography.fontSize;
const ICON_SIZE = theme.typography.fontSizeSmallLarge;

const InputWithIcon = ({ icon, children }) => {
  return (
    <Container>
      {icon && <StyledIcon>{icon}</StyledIcon>}
      {children && <StyledInput>{children}</StyledInput>}
    </Container>
  );
};

export default InputWithIcon;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled.div`
  svg {
    font-size: ${ICON_SIZE}px;
  }
`;

const StyledInput = styled.div`
  font-size: ${FONT_SIZE}px;

  input {
    font-size: ${FONT_SIZE}px;
  }
`;
