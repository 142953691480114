import React from 'react';

const GRID_REDRAW_ERROR = 'AG Grid: cannot get grid to draw rows when it is in the middle of drawing rows.';

class ErrorBoundaryWithRetry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      retryCount: 0,
    };
  }

  static getDerivedStateFromError(error) {
    if (error && error.message.includes(GRID_REDRAW_ERROR)) {
      return { hasError: false };
    }

    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    if (window.Rollbar) {
      window.Rollbar.error(error); // Send it to Rollbar!
    }
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      throw error;
    }

    // Render children normally if no error or retrying
    return this.props.children;
  }
}

export default ErrorBoundaryWithRetry;
