import { isEmpty, pipe, not, defaultTo } from 'ramda';
import noop from 'lodash/noop';

const isNotEmpty = pipe(isEmpty, not);
const defaultToEmptyArray = defaultTo([]);

const UNDEFINED_ROADMAP_SELECTED = 'null';

const mapArrayValuesToInt = array => {
  return array.map(v => +v);
};

/**
 * @function isChildOkrFromRoadmapSelected verifies if the roadmap selected on the quick filter matches the
 * default corp roadmap for a OKR item
 * @param  {Object} okr                              OKR object
 * @param  {Array} filterRoadmaps               List of roadmaps on the filter
 * @param  {Function} getDefaultRoadmapForMetadataItem Function to get the default roadmap of a OKR
 * @return {Boolean} The roadmap selected matched the corp roadmap of owner organization of OKR
 */
const isChildOkrFromRoadmapSelected = (okr, filterRoadmaps, getDefaultRoadmapForMetadataItem) => {
  const orgCorpRoadmap = getDefaultRoadmapForMetadataItem(okr);

  return mapArrayValuesToInt(filterRoadmaps).includes(orgCorpRoadmap?.id);
};

const hasMatchingRoadmaps = (okrRoadmaps, filterRoadmaps, filterProducts, filterProducts2) =>
  okrRoadmaps.some(
    roadmap =>
      mapArrayValuesToInt(filterRoadmaps).includes(roadmap?.roadmap_id) ||
      mapArrayValuesToInt(filterProducts).includes(roadmap?.product_1_id) ||
      mapArrayValuesToInt(filterProducts2).includes(roadmap?.product_2_id),
  );

/**
 * @function makeFilterOKRHierarchyByRoadmap returns a function to filter OKRs by roadmaps
 * @param  {Array} filterRoadmaps              List of selected roadmaps on filters
 * @param  {Array} filterProducts              List of selected products on filters
 * @param  {Array} filterProducts2             List of selected products2 on filters
 * @param  {Object} organizationAccessControlOptions Organization access control for DoD context
 * @param  {Boolean} considerChildrenOnFilter If consider children filter match while filtering
 * @return {Function} Function to filter OKRs by roadmaps
 */
const makeFilterOKRHierarchyByRoadmap = (
  filterRoadmaps,
  filterProducts,
  filterProducts2,
  organizationAccessControlOptions = {},
  considerChildrenOnFilter = true,
) => {
  const hasSelectedUndefined = filterRoadmaps.includes(UNDEFINED_ROADMAP_SELECTED);
  const { isDodActive = false, getDefaultRoadmapForMetadataItem = noop } = organizationAccessControlOptions;

  const filterByRoadmaps = okr => {
    const okrRoadmaps = okr?.objective_roadmaps ?? okr?.key_result_roadmaps ?? [];
    const undefinedSelectedAndNoRoadmapOnOkr = hasSelectedUndefined && isEmpty(okrRoadmaps);

    if (undefinedSelectedAndNoRoadmapOnOkr) return true;

    const isChildOKROfCorpRoadmapSelected =
      isDodActive && isChildOkrFromRoadmapSelected(okr, filterRoadmaps, getDefaultRoadmapForMetadataItem);

    const hasMatch =
      isChildOKROfCorpRoadmapSelected || hasMatchingRoadmaps(okrRoadmaps, filterRoadmaps, filterProducts, filterProducts2);

    if (hasMatch) return true;

    const children = isEmpty(defaultToEmptyArray(okr?.children))
      ? defaultToEmptyArray(okr?.keyResults)
      : defaultToEmptyArray(okr?.children);

    if (isEmpty(children) || !considerChildrenOnFilter) return false;

    const childrenWithAppliedRoadmap = children.filter(filterByRoadmaps);

    return isNotEmpty(childrenWithAppliedRoadmap);
  };

  return filterByRoadmaps;
};

export default makeFilterOKRHierarchyByRoadmap;
