import React from 'react';
import BarChart from 'components/ReactChart/BarChart';

import { uniq, defaultTo } from 'ramda';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import normalizeArray from 'utils/normalizeArray';
import calculateTextLines from 'utils/calculateTextLines';
import theme from 'design-system/theme';
import { STACKED_CHART_ROW_HEIGHT } from 'constants/charts';
import { UNDEFINED_LABEL } from 'constants/common';
import { COMPLETED_LABEL, PLANNED_LABEL, REPORTED_LABEL, TARGET_LABEL } from 'constants/allocation';
import { materialColorsAlt } from 'design-system/themes/default';
import ChartLegend from 'routes/Dashboard/Dashboards/Charts/ChartLegend';
import ticksFontStyles from 'utils/charts/ticksFontStyles';

const MAX_NUM_LINES = 1;
const MAX_NUM_CHARS = 25;
const FONT_SIZE = 11;
const MAX_WIDTH_FOR_LABEL = 120;
const FLEX_KEY = 'flex';
const MAX_ITEMS_FOR_STATIC_HEIGHT = 3;
const BAR_HEIGHT_FOR_STATIC_HEIGHT = 35;
const CHART_MIN_HEIGHT = 200;
const COMPLETED_COLOR = '#2e7d32';

const defaultZero = defaultTo(0);

export default (planned, reported, completed, target, entities, lsState) => {
  const normalizedEntities = normalizeArray(entities, 'id');
  const { displayLegendInline } = lsState;
  // should be present in both planned and reported
  const allIds = uniq([...Object.keys(planned), ...Object.keys(reported)]);
  const labels = allIds.map(id => normalizedEntities[id]?.title || UNDEFINED_LABEL);

  const truncateLabel = label => {
    const numLines = calculateTextLines(label, MAX_WIDTH_FOR_LABEL, FONT_SIZE);

    if (numLines > MAX_NUM_LINES && label.length > MAX_NUM_CHARS) return `${label.slice(0, MAX_NUM_CHARS)}...`;

    return label;
  };

  const formattedNumber = value => parseFloat(defaultZero(value).toFixed(2)).toLocaleString('en-US');

  const plannedBarColor = theme.palette.background.lemonTint;
  const reportedBarColor = theme.palette.background.maldives;
  const completedBarColor = COMPLETED_COLOR;
  const targetBarColor = materialColorsAlt.lightGray;
  const maximumBarThickness = 35;

  const barThickness = allIds.length < MAX_ITEMS_FOR_STATIC_HEIGHT ? BAR_HEIGHT_FOR_STATIC_HEIGHT : FLEX_KEY;

  const chartData = {
    labels,
    ids: allIds,
    datasets: [
      ...(lsState.showPlanned
        ? [
            {
              label: PLANNED_LABEL,
              data: allIds.map(id => planned[id]),
              backgroundColor: plannedBarColor,
              hoverBackgroundColor: plannedBarColor,
              maxBarThickness: maximumBarThickness,
              barThickness,
              datalabels: {
                color: theme.palette.black,
                formatter(value) {
                  return formattedNumber(value);
                },
              },
            },
          ]
        : []),
      ...(lsState.showReported
        ? [
            {
              label: REPORTED_LABEL,
              data: allIds.map(id => reported[id]),
              backgroundColor: reportedBarColor,
              hoverBackgroundColor: reportedBarColor,
              maxBarThickness: maximumBarThickness,
              barThickness,
              datalabels: {
                color: theme.palette.black,
                formatter(value) {
                  return formattedNumber(value);
                },
              },
            },
          ]
        : []),
      ...(lsState.showTarget
        ? [
            {
              label: TARGET_LABEL,
              data: allIds.map(id => target[id]),
              backgroundColor: targetBarColor,
              hoverBackgroundColor: targetBarColor,
              maxBarThickness: maximumBarThickness,
              barThickness,
              datalabels: {
                color: theme.palette.black,
                formatter(value) {
                  return formattedNumber(value);
                },
              },
            },
          ]
        : []),
      ...(lsState.showCompleted
        ? [
            {
              label: COMPLETED_LABEL,
              data: allIds.map(id => completed[id]),
              backgroundColor: completedBarColor,
              hoverBackgroundColor: completedBarColor,
              maxBarThickness: maximumBarThickness,
              barThickness,
              datalabels: {
                color: theme.palette.black,
                formatter(value) {
                  return formattedNumber(value);
                },
              },
            },
          ]
        : []),
    ],
  };

  const barOptions = {
    hover: {
      animationDuration: 0,
    },
    maintainAspectRatio: true,
    animation: {
      duration: 0,
    },
    indexAxis: 'y',
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          drawTicks: true,
          offset: true,
        },
        ticks: {
          beginAtZero: true,
          callback(value) {
            const label = this.getLabelForValue(value);

            return truncateLabel(label);
          },
          crossAlign: 'far',
          display: true,
          font: ticksFontStyles,
        },
      },
      x: {
        grid: {
          display: false,
        },
        display: false,
        ticks: {
          font: ticksFontStyles,
        },
      },
    },
    plugins: {
      datalabels: {
        labels: {
          value: {
            align: 'right',
            anchor: 'end',
            clamp: true,
            offset: 10,
            font: {
              size: theme.typography.fontSize,
            },
          },
        },
      },
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label(context) {
            const { dataset } = context || {};
            const { label: dataType } = dataset || {};

            const label = `${dataType}: ${formattedNumber(context.raw)}`;

            return label;
          },
        },
      },
    },
  };

  const height = defaultZero(chartData?.labels?.length) * STACKED_CHART_ROW_HEIGHT;

  if (height === 0) return '';

  const chart = (
    <>
      <BarChart
        id="allocation-bar-chart"
        height={height > CHART_MIN_HEIGHT ? height : CHART_MIN_HEIGHT}
        plugins={[ChartDataLabels]}
        data={chartData}
        options={barOptions}
      />
      {!displayLegendInline && <ChartLegend legends={chartData?.datasets} maxLegendItems={6} />}
    </>
  );

  return [chart];
};
