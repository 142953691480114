/**
 * @function orderDescByPopularityThenId  order DESC by popularity then by Id
 * @param {Object} templateA  to compared
 * @param {Object} templateB  to be compared with
 * @return {number} value of the comparison (-1, 0 or 1)
 */
const orderDescByPopularityThenId = (templateA, templateB) => {
  if (templateA.popularity > templateB.popularity) return -1;
  if (templateA.popularity < templateB.popularity) return 1;
  if (templateA.popularity === templateB.popularity) {
    if (templateA.id > templateB.id) return -1;
    if (templateA.id <= templateB.id) return 1;
  }
  return 0;
};

export default orderDescByPopularityThenId;
