import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { pipe, equals, path, isEmpty, not, isNil, either } from 'ramda';

import LinkIcon from '@material-ui/icons/Link';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import HelpIcon from '@material-ui/icons/HelpOutline';

import { spacing } from 'design-system/theme';
import Autocomplete from 'design-system/atoms/Autocomplete/index';

import { IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import TextFieldNoBorder from 'design-system/atoms/TextFieldNoBorder';
import { BET_LAYER } from 'src/store/projects/constants.js';
import { FIELD_MAPPING_DIRECTION_OPTIONS, INTEGRATIONS_DOCS_LINKS, INTEGRATIONS_KEYS } from 'src/constants/integrations';

import useJiraIssueTypeMapping from 'hooks/integrations/useJiraIssueTypeMapping';
import { checkOrganizationFlags } from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';

import {
  StyledGridItem,
  BottomRow,
  InputWrapper,
  StyledTextField,
  ButtonsWrapper,
  HintButtonWrapper,
  LinkWorkItemButton,
  HelpIconLink,
} from '../../utils/styles';

const isNotNilOrEmpty = pipe(either(isNil, isEmpty), not);
const getLayer = path(['formData', 'layer']);
const _viewingIdeas = pipe(getLayer, equals(IDEA_LAYER));
const _viewingInitiatives = pipe(getLayer, equals(INITIATIVE_LAYER));
const _viewingBets = pipe(getLayer, equals(BET_LAYER));

export default props => {
  const { isCreating, disableActions, clearJiraCreateErrors, clearJiraLinkErrors, errors } = props;

  const enableLinkEpicOutbound = checkOrganizationFlags({}, [FeatureFlags.HAS_LINK_EPIC_OUTBOUND_OPTION]);

  const [jiraTicket, setJiraKey] = useState('');
  const [issueKey, setIssueKey] = useState('');
  const [issueType, setIssueType] = useState(null);

  const { issueTypeMappingByLayer, hasMultipleIssueTypesByLayer } = useJiraIssueTypeMapping(
    props.integration.id,
    getLayer(props),
  );

  const linkingIssueType = (() => {
    switch (getLayer(props)) {
      case INITIATIVE_LAYER:
        return 'initiative';
      case BET_LAYER:
        return 'bet';
      case IDEA_LAYER:
      default:
        return 'issue';
    }
  })();
  const currentlyOnAllowedLayers = () => _viewingBets(props) || _viewingIdeas(props) || _viewingInitiatives(props);

  const hasCreateErrors = isNotNilOrEmpty(errors.create);
  const hasLinkErrors = isNotNilOrEmpty(errors.link);

  useEffect(() => {
    setJiraKey('');
    setIssueKey('');
  }, [props.integration]);

  const handleCreateEpicKeyChange = e => {
    if (hasCreateErrors && e.target.value !== jiraTicket) {
      clearJiraCreateErrors();
    }
    setJiraKey(e.target.value);
  };

  const handleLinkEpicKeyChange = e => {
    if (hasLinkErrors && e.target.value !== issueKey) {
      clearJiraLinkErrors();
    }
    setIssueKey(e.target.value);
  };

  const isCreateJiraButtonDisabled =
    disableActions || isCreating || !jiraTicket || (hasMultipleIssueTypesByLayer && !issueType) || hasCreateErrors;

  return (
    <>
      {currentlyOnAllowedLayers(props) && (
        <Grid container>
          <Grid item xs>
            <StyledTextField
              name="jiraProjectCode"
              label="Project Code"
              style={{ width: '100%', marginTop: '15px' }}
              value={jiraTicket || ''}
              onChange={handleCreateEpicKeyChange}
              placeholder="PROD"
              disabled={disableActions}
              autocomplete="off"
              required
            />
          </Grid>
          {hasMultipleIssueTypesByLayer && (
            <DropdownContainer item xs={4}>
              <Autocomplete
                hideBottomBorder
                suggestions={issueTypeMappingByLayer}
                onValueChange={setIssueType}
                placeholder="Issue Type"
              />
            </DropdownContainer>
          )}
          <StyledGridItem item xs={4}>
            <Button
              id="create-jira"
              color="primary"
              onClick={() => props.createJiraEpic(jiraTicket, issueType)}
              style={{ marginTop: 27 }}
              disabled={isCreateJiraButtonDisabled}
            >
              <AddIcon style={{ marginRight: 6, width: 16 }} />
              {props.isCreating ? 'Creating...' : 'Create Jira'}
            </Button>
          </StyledGridItem>
          {hasCreateErrors && (
            <Grid item xs={12}>
              {errors.create.map(err => (
                <Error>{err}</Error>
              ))}
              <Info>Check Jira key or ask your admin to review hierarchy type mapping.</Info>
            </Grid>
          )}
        </Grid>
      )}

      {enableLinkEpicOutbound ? (
        <BottomRow>
          {currentlyOnAllowedLayers(props) && (
            <>
              <InputWrapper>
                <StyledTextField
                  name="jiraEpicKey"
                  label={`Jira ${linkingIssueType} key (not URL)`}
                  style={{ marginTop: `${spacing(2)}px` }}
                  value={issueKey}
                  onChange={handleLinkEpicKeyChange}
                  placeholder="PROD-17"
                  autocomplete="off"
                  disabled={disableActions}
                  required
                  fullWidth
                  useEllipsis
                />
                {hasLinkErrors && errors.link.map(err => <Error>{err}</Error>)}
              </InputWrapper>
              <ButtonsWrapper>
                <LinkWorkItemButton
                  id="link-jira-outbound"
                  onClick={() => props.linkJiraEpicOutbound(issueKey, linkingIssueType)}
                  disabled={disableActions || props.isLinking || !issueKey}
                  direction={FIELD_MAPPING_DIRECTION_OPTIONS.outbound}
                >
                  <ArrowForwardIcon color="inherit" fontSize="inherit" />
                  {props.isLinking ? 'Linking...' : 'Link & update Jira'}
                </LinkWorkItemButton>
                <HintButtonWrapper>
                  <LinkWorkItemButton
                    id="link-jira-inbound"
                    onClick={() => props.linkJiraEpic(issueKey, linkingIssueType)}
                    disabled={disableActions || props.isLinking || !issueKey}
                    direction={FIELD_MAPPING_DIRECTION_OPTIONS.inbound}
                  >
                    <ArrowBackIcon color="inherit" fontSize="inherit" />
                    {props.isLinking ? 'Linking...' : 'Link & update by Jira'}
                  </LinkWorkItemButton>
                  <HelpIconLink
                    href={INTEGRATIONS_DOCS_LINKS[INTEGRATIONS_KEYS.JIRA].CREATE_OR_LINK_EPICS}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => e.stopPropagation()}
                  >
                    <HelpIcon />
                  </HelpIconLink>
                </HintButtonWrapper>
              </ButtonsWrapper>
            </>
          )}
        </BottomRow>
      ) : (
        <Grid container>
          {currentlyOnAllowedLayers(props) && (
            <>
              <Grid item xs={8}>
                <TextFieldNoBorder
                  name="jiraEpicKey"
                  label={`Jira ${linkingIssueType} key (not URL)`}
                  style={{ width: '100%', marginTop: '15px' }}
                  value={issueKey}
                  onChange={handleLinkEpicKeyChange}
                  placeholder="PROD-17"
                  autocomplete="off"
                  disabled={disableActions}
                  required
                />
                {hasLinkErrors && errors.link.map(err => <Error>{err}</Error>)}
              </Grid>
              <StyledGridItem item xs={4}>
                <Button
                  id="link-jira"
                  color="primary"
                  onClick={() => props.linkJiraEpic(issueKey, linkingIssueType)}
                  style={{ marginTop: 27 }}
                  disabled={disableActions || props.isLinking || !issueKey}
                >
                  <LinkIcon style={{ marginRight: 6, width: 16 }} />
                  {props.isLinking ? 'Linking...' : 'Link Jira'}
                </Button>
              </StyledGridItem>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

const Error = styled.span`
  color: red;
  margin: 10px 0 0;
  display: inline-block;
`;

const Info = styled.span`
  color: ${({ theme }) => theme.palette.text.primary};
  margin: 10px 0 0;
  display: inline-block;
`;

const DropdownContainer = styled(Grid)`
  display: flex;
  align-items: end;
`;
