import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADMIN_USER, EDITOR_USER, MANAGER_USER } from '@dragonboat/permissions';

import { getCurrentUser } from 'store/login';
import { updateOnboardingGuide, getOrgOnboardingGuide } from 'store/organization';
import { DEFAULT_ONBOARDING_GUIDE } from 'constants/organizations';

export default function useOnboardingGuide() {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const orgOnboardingGuide = useSelector(getOrgOnboardingGuide);

  const userIsEditor = useMemo(() => [ADMIN_USER, MANAGER_USER, EDITOR_USER].includes(currentUser?.role_id), [currentUser]);

  const onboardingGuide = useMemo(() => {
    if (!orgOnboardingGuide || orgOnboardingGuide === '<p></p>') {
      return DEFAULT_ONBOARDING_GUIDE;
    }

    return orgOnboardingGuide;
  }, [orgOnboardingGuide]);

  const saveOnboardingGuide = useCallback(
    onboardingGuide => {
      if (onboardingGuide) {
        dispatch(updateOnboardingGuide(onboardingGuide));
      }
    },
    [dispatch],
  );

  return {
    isEditor: userIsEditor,
    onboardingGuide,
    saveOnboardingGuide,
  };
}
