const DIRECT_URL_PAGES = ['dashboards'];

export default (origin, view) => {
  let viewPath = view?.path;
  let url = '';

  if (viewPath?.startsWith('/')) {
    viewPath = viewPath.substring(1);
  }

  if (view) {
    const baseUrl = `${origin}/${viewPath}`;

    url = DIRECT_URL_PAGES.includes(view.page) ? `${baseUrl}/${view.id}` : `${baseUrl}?sharedView=${view.key || view.id}`;
  }

  return url;
};
