import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_RALLY_WORKSPACES'),
  ...getThunkActionTypes('UPDATE_RALLY_INTEGRATION_WORKSPACE'),
  ...getThunkActionTypes('FETCH_RALLY_PROJECTS'),
};

export const {
  FETCH_RALLY_WORKSPACES,
  UPDATE_RALLY_INTEGRATION_WORKSPACE,
  UPDATE_RALLY_INTEGRATION_WORKSPACE_FULFILLED,
  FETCH_RALLY_PROJECTS,
  FETCH_RALLY_PROJECTS_RESET,
} = actionTypes;
