import { defaultTo } from 'ramda';
import isFunction from 'lodash/isFunction';
import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';

const defaultToEmptyString = defaultTo('');

const sanitizeCustomFieldKey = key => {
  const regexPattern = /[^\w\s]/gi; // This pattern matches anything that is not a word character or whitespace

  return defaultToEmptyString(key).replace(regexPattern, '-');
};

const parseEntityWithSanitizedKeys = (entity, customFields) => {
  // On creating a new field its adding to the store without key
  const validCustomfields = customFields.filter(cf => cf.key !== undefined);

  return {
    ...entity,
    ...validCustomfields.reduce(
      (acc, cf) => ({
        ...acc,
        [sanitizeCustomFieldKey(cf.key)]: entity[cf.key],
      }),
      {},
    ),
  };
};

const isNewCustomField = customField => customField?.field_type && customField?.isNew;

const checkEditablePermission = (params, canUpdate) => {
  const customField = params.data;

  const userCanUpdate = isFunction(canUpdate) ? canUpdate(customField) : false;

  return isNewCustomField(customField) || userCanUpdate;
};

const getDescriptionValue = (params, canUpdate) => {
  const isInvalid = !params?.data;

  if (isInvalid) return '';

  switch (params.data.field_type) {
    case TYPES_OF_CUSTOM_FIELDS.FORMULA:
      return params.data.description_text;
    case TYPES_OF_CUSTOM_FIELDS.DROPDOWN:
    case TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN:
      return checkEditablePermission(params, canUpdate) ? 'EDIT OPTIONS' : 'VIEW OPTIONS';
    default:
      return params.data.description;
  }
};

export { sanitizeCustomFieldKey, parseEntityWithSanitizedKeys, getDescriptionValue, checkEditablePermission };
