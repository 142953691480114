// External dependencies
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';

// Dragonboat imports
import { getSkills } from 'store/skills/selectors';
import { getTeams } from 'store/teams/selectors';
import { updateProjectEstimate, createProjectEstimate, deleteProjectEstimate } from 'store/estimates';
import useSystemFields from 'hooks/useSystemFields';
import { getSelectedProject } from 'store/projects/selectors';

const withHooksHOC = Component => {
  return props => {
    const [getSystemFieldName] = useSystemFields();

    const project = useSelector(state => getSelectedProject(state, { id: props.projectId }));

    return <Component {...props} project={project} getSystemFieldName={getSystemFieldName} />;
  };
};

// Redux connect function state to props configuration
const mapStateToProps = (state, props) => ({
  teams: getTeams(state),
  skills: getSkills(state),
});

// Redux connect function dispatch configuration
const mapDispatchToProps = (dispatch, props) => ({
  updateEstimate: estimate => dispatch(updateProjectEstimate(estimate)),
  createEstimate: (projectId, estimate) => dispatch(createProjectEstimate(projectId, estimate)),
  deleteEstimate: (projectId, estimate) => dispatch(deleteProjectEstimate(projectId, estimate)),
});

// Apply HOCs
export default compose(connect(mapStateToProps, mapDispatchToProps), withHooksHOC);
