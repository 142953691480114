import axios from 'axios';

import jiraActionWrapper from 'utils/jiraActionWrapper';

export const searchForTasksByJiraUrl = (integrationId, jiraUrl) => {
  return dispatch => {
    return jiraActionWrapper(
      dispatch,
      async () => {
        return axios.post(`/api/jira/${integrationId}/import/count`, { jiraUrl }).then(r => r);
      },
      null,
      integrationId,
    );
  };
};

export const previewTasksByJiraUrl = (integrationId, jiraUrl, fields) => {
  return dispatch => {
    return jiraActionWrapper(
      dispatch,
      async () => {
        return axios.post(`/api/jira/${integrationId}/import/preview`, { jiraUrl, fields }).then(r => r);
      },
      null,
      integrationId,
    );
  };
};
