import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

const DELETE_LABEL = 'Delete';
const SAVE_LABEL = 'Save';
const CANCEL_LABEL = 'Cancel';

const DialogActions = props => {
  const {
    onCancel,
    onDelete,
    onSave,
    deleteLabel = DELETE_LABEL,
    saveLabel = SAVE_LABEL,
    cancelLabel = CANCEL_LABEL,
    deleteProps = {},
    saveProps = {},
    cancelProps = {},
  } = props;

  return (
    <Grid container direction="row" alignItems="center" justify="space-between">
      <Grid item>
        {onDelete && (
          <Button item color="secondary" onClick={onDelete} {...deleteProps}>
            {deleteLabel}
          </Button>
        )}
      </Grid>
      <Grid item>
        {onSave && (
          <Button color="primary" onClick={onSave} {...saveProps}>
            {saveLabel}
          </Button>
        )}
        {onCancel && (
          <Button onClick={onCancel} {...cancelProps}>
            {cancelLabel}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

DialogActions.propTypes = {
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
};

export default DialogActions;
