import React, { createContext, useContext, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { markNotificationAsRead, processNotification } from 'store/notifications';

const DeepLink = createContext();

export const DeepLinkProvider = ({ children }) => {
  const dispatch = useDispatch();

  const didProcessUrlRef = useRef(false);

  // This could be refactored into an useEffect which would run only once given it's inside a Context Provider
  // however we do have some Startup logic that runs prior to mounting the main <App/> component.
  // Calling this from useAppNotifications and leveraging useRef still guarantees one time execution.
  // Not pretty though.
  const processNotificationFromUrl = () => {
    // Run only once
    if (didProcessUrlRef.current) {
      return;
    }
    didProcessUrlRef.current = true;

    const params = new URLSearchParams(window.location.search);
    const notificationId = params.get('notification_id');
    const notificationType = params.get('notification_type');
    const notificationRawPayload = params.get('notification_payload');
    let notificationPayload;

    try {
      notificationPayload = JSON.parse(notificationRawPayload);
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        console.error(`notification payload in url parameter is not a valid JSON (${notificationRawPayload})`);
      }
    }

    if (notificationType && notificationId && notificationPayload) {
      const notification = {
        id: +notificationId,
        notification_type: notificationType,
        notification_payload: notificationPayload,
      };

      dispatch(markNotificationAsRead(notification.id));
      dispatch(processNotification(notification.notification_type, notification.notification_payload));
    }
  };

  return <DeepLink.Provider value={{ processNotificationFromUrl }}>{children}</DeepLink.Provider>;
};

export const useDeepLinkContext = () => {
  return useContext(DeepLink);
};
