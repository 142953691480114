import { createSelector } from 'reselect';

import { getEnrichedProject } from 'store/projects/helpers/enrichProjects';
import { selectMetadata } from 'store/projects/metadataSelectors';
import { isLoading } from 'utils/store/thunk';

import { LINK_PROJECT_TO_OKR, UNLINK_PROJECT_FROM_OKR, FETCH_OBJECTIVE_LINKED_ITEMS, CREATE_PROJECT_FROM_OKR } from './types';

const getState = state => state?.okrDrawer;

const getOperationsState = state => getState(state)?.operations;

export const selectObjectiveLinkedItems = createSelector(
  state => selectMetadata(state),
  state => getState(state)?.linkedItems,
  (metadata, linkedItems) => (linkedItems || []).map(item => getEnrichedProject(item, metadata)),
);

export const isFetchObjectiveLinkedItemsLoading = state => isLoading(getOperationsState(state), FETCH_OBJECTIVE_LINKED_ITEMS);

export const isLinkProjectToOkrLoading = state => isLoading(getOperationsState(state), LINK_PROJECT_TO_OKR);

export const isUnlinkProjectToOkrLoading = state => isLoading(getOperationsState(state), UNLINK_PROJECT_FROM_OKR);

export const isCreateProjectFromOkrLoading = state => isLoading(getOperationsState(state), CREATE_PROJECT_FROM_OKR);
