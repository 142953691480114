import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export default styled(Button)`
  &&&& {
    color: #1f8f9e;
    border-radius: 4px;
    padding-left: 14px;
    padding-right: 14px;
    letter-spacing: 1.25px;
    font-size: 14px;
    margin: 0 7px;

    &:hover {
      background-color: #1f8f9e1a;
    }

    ${({ iconpos }) =>
      iconpos === 'right' &&
      `
    padding-right: 7px;
  `}

    ${({ iconpos }) =>
      iconpos === 'left' &&
      `
    padding-left: 7px;
  `}

  ${({ align }) =>
      align === 'right' &&
      `
    float: right;
  `}

  ${({ bgcolor }) =>
      bgcolor &&
      `
    background-color: ${bgcolor};

    &:hover{
      background-color: ${bgcolor};
      opacity: .8;
    }
  `}

  ${({ textcolor }) =>
      textcolor &&
      `
    color: ${textcolor};
  `}
  }
`;
