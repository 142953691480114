import theme from 'design-system/theme';

import { ESTIMATE_BY_POINTS, ESTIMATE_BY_TIME } from 'constants/teams';

const estimateByLabels = {
  [ESTIMATE_BY_POINTS]: 'In points',
  [ESTIMATE_BY_TIME]: 'In weeks',
};

const getTeamHeaderArguments = ({ team, canWorkWithEstimationByPoints = false, openTeamLightbox }) => {
  const isEstimatingByPoints = team?.estimate_by === ESTIMATE_BY_POINTS;
  const onClick = () => openTeamLightbox(team);
  const baseArgs = [team.color, onClick];

  if (!canWorkWithEstimationByPoints) return [team.color];
  if (!isEstimatingByPoints) return baseArgs;

  return [
    ...baseArgs,
    {
      subTitle: estimateByLabels[team?.estimate_by || ESTIMATE_BY_TIME],
      subTitleColor: theme.palette.text.accent,
    },
  ];
};

export default getTeamHeaderArguments;
