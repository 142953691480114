import converTimeToString from 'utils/converTimeToString';

const getIntegrationTimeEstimate = ({ data }) =>
  data?.integrationProgress?.timeEstimated ? converTimeToString(data.integrationProgress.timeEstimated) : '';

const textFields = {
  timeEstimate: {
    label: 'Time Estimate',
    valueGetter: getIntegrationTimeEstimate,
    width: 80,
  },
  updatedByName: {
    label: 'Updated By',
    width: 100,
  },
};

export default textFields;
