import { useEffect } from 'react';
import useQuery from './useQuery';

const useQueryParam = (paramKey, cb, options = { defaultTo: undefined }) => {
  const query = useQuery();
  const param = query.get(paramKey) ?? options?.defaultTo;

  useEffect(() => {
    cb(param);
  }, [param]);
};

export default useQueryParam;
