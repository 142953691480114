import React from 'react';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';

import Autocomplete from 'design-system/atoms/Autocomplete/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';


import { OverflowTextContainer, ButtonsContainer, TextWrapper } from '../styled';
import DragonTableCell from '../DragonTableCell';
import { _isEditable } from '../../state';
import DragonTableIconButton from '../DragonTableIconButton';

const TextColumnInput = styled(TextField)`
  width: 100%;

  &&& input {
    font-size: 0.8125rem;
    padding: 0.8125rem 0.4rem;
    width: ${props => `${props.width}px` || '100%'};
  }
`;

const TextColumnEditor = ({
  component: Component,
  options,
  onChange,
  onSave,
  onCancel,
  value,
  isLoading,
  metadata,
  width,
  ...props
}) => {
  const cancelCallback = event => {
    if (event.which === 27) {
      onCancel();
      return;
    }
    event.stopPropagation();
    if (event.which === 13) {
      onSave();
    }
  };

  if (Component) {
    return (
      <Component
        {...props}
        value={value}
        onSave={onSave}
        onValueChange={onSave}
        onKeyDown={cancelCallback}
        onCancel={onCancel}
        hideIcon
        isOnGrid
        disabled={isLoading}
        metadata={metadata}
        options={options}
        width={width}
      />
    );
  }

  return options ? (
    <Autocomplete
      {...props}
      suggestions={options}
      value={value}
      onValueChange={onSave}
      onKeyDown={cancelCallback}
      hideIcon
      isOnGrid
    />
  ) : (
    <TextColumnInput
      {...props}
      value={value}
      onChange={event => onChange(event.target.value)}
      onBlur={() => onSave()}
      onKeyDown={cancelCallback}
    />
  );
};

const getTextColor = (row, color) => {
  if (!color) return;

  return isFunction(color) ? color(row) : color;
};

const TextColumn = ({
  header,
  map,
  displayAs,
  row,
  width,
  flexGrow,
  main,
  editable,
  forceEdit,
  onChange,
  onEdit = () => {},
  onEditCancel = () => {},
  options,
  wasSaved,
  cellProps,
  editorComponent,
  editorWidth,
  metadata,
  buttons,
  color,
  ...props
}) => {
  const isNew = !row;
  const [editingValue, setEditingValue] = React.useState(isNew && main ? '' : null);
  const [isLoading, setIsLoading] = React.useState(false);

  const result = row && (map(row) || map(row) === 0 ? map(row) : '');
  const save = data => {
    const value = data === null ? data : data || editingValue;

    // avoid onBlur excessive saves
    if (isLoading) {
      return;
    }

    if (onChange) {
      if (
        (!row && value !== '' && value) || // if it's a creation and the value is valid
        (row && !isEqual(value, map(row)))
      ) {
        // or it is an update
        const result = onChange(row || {}, value);

        if (result instanceof Promise) {
          setIsLoading(true);
          result.finally(() => {
            setEditingValue(null);
            setIsLoading(false);
          });
        } else {
          setEditingValue(null);
        }
      } else {
        onEditCancel();
        setEditingValue(null);
      }
    } else {
      setEditingValue(null);
    }
  };

  const displayResult = row ? (displayAs ? displayAs(result) : result) : '';
  const isEditable = _isEditable(row, editable);

  const _onBlur = e => {
    /*
     * On the case that has some react-autowhatever-1 elemenr closed to clicked element
     * it means that was clicked on autocomplete option and should continue with
     * selected option to be saved
     *
     * otherwise will cancel the edit to remove the new empty row created on the table
     */
    const clickedEventTargetIsRenderedAsPortal = e.relatedTarget && e.relatedTarget.closest('#react-autowhatever-1');

    if (isNew && !clickedEventTargetIsRenderedAsPortal) {
      setEditingValue(null);
      onEditCancel();
    }
  };
  const _makeOnButtonClick = onClick => e => {
    e.preventDefault();

    return onClick(row, e);
  };

  const Value =
    editingValue !== null ? (
      <TextColumnEditor
        {...props}
        component={editorComponent}
        autoFocus
        onChange={setEditingValue}
        onSave={save}
        onBlur={_onBlur}
        value={editingValue}
        options={options}
        onCancel={() => {
          setEditingValue(null);
          onEditCancel();
        }}
        isLoading={isLoading}
        metadata={metadata}
        width={editorWidth}
      />
    ) : (
      <TextWrapper>
        <TooltipOnOverflow text={displayResult}>
          <OverflowTextContainer>
            {props.isParent && props.hasChildren && (
              <IconButton onClick={props.toggleParent}>
                <ExpandIcon />
              </IconButton>
            )}
            {displayResult}
          </OverflowTextContainer>
        </TooltipOnOverflow>
        {buttons && buttons.length > 0 && (
          <ButtonsContainer>
            {buttons.map(({ icon, onClick }) => (
              <a href="#" target="_blank" rel="noreferrer noopener">
                <DragonTableIconButton icon={icon} onClick={_makeOnButtonClick(onClick)} />
              </a>
            ))}
          </ButtonsContainer>
        )}
      </TextWrapper>
    );
  // update state to know if group is collapsed and make rows 0 height if so

  React.useEffect(() => {
    if (!forceEdit) return;

    if (!editingValue && isEditable) {
      onEdit();
      setEditingValue(result);
    }
  }, [forceEdit]);
  React.useEffect(() => {
    if (row) return;

    setEditingValue(main ? '' : null);
  }, [row]);

  return (
    <DragonTableCell
      {...props}
      editable={isEditable}
      editing={editingValue !== null}
      isSelected={props.isSelected}
      style={{
        width,
        flexGrow,
        overflow: editingValue !== null ? 'visible' : null,
        padding: editingValue !== null ? 0 : null,
        height: cellProps.rowHeight,
        color: getTextColor(row, color),
      }}
      onDoubleClick={() => {
        if (isEditable && editingValue === null) {
          onEdit();
          setEditingValue(result);
        }
      }}
    >
      {Value}
      {isLoading && (
        <CircularProgress
          variant="indeterminate"
          disableShrink
          size={12}
          thickness={4}
          style={{
            position: 'absolute',
            right: 8,
            top: 'calc(50% - 4px)',
          }}
        />
      )}
    </DragonTableCell>
  );
};

export default TextColumn;
