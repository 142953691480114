import React, { useCallback } from 'react';
import { pluck } from 'ramda';

import RequestsMergeModal from 'features/RequestsMergeModal';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';
import RequestsBulkUpdateModal from 'features/RequestsBulkUpdateModal';
import useGridApiContext from 'hooks/grid/useGridApiContext';

import useAIAnalyzer from '../../hooks/useAIAnalyzer';
import Header from './components/Header';
import AIAnalyzerGrid from './components/AIAnalyzerGrid';
import { AI_ANALYZER_GRID } from '../../constants';

const PreviewScreen = ({ onClose }) => {
  const [showMergeModal, setShowMergeModal] = React.useState(false);
  const [showBulkUpdateModal, setShowBulkUpdateModal] = React.useState(false);

  const { canUpdate, canView } = usePermissions();

  const { getGridApi } = useGridApiContext();

  const {
    selectedRequests,
    customerRequests: dataForAIAnalizerGrid,
    canSaveAutoTags,

    handleResetAutoTags,
    handleSetAutoTagsAsRequestTags,
    setSelectedRequests,
    handleUpdateWithAutoTags,
  } = useAIAnalyzer();

  const handleSelectionChanged = useCallback(
    ({ api }) => {
      const currentlySelectedItems = api.getSelectedRows();
      const selecteedCustomerRequestsIds = pluck('id', currentlySelectedItems);

      setSelectedRequests(selecteedCustomerRequestsIds);
    },
    [setSelectedRequests],
  );

  const hadleCloseAndUpdateWithAutoTags = () => {
    handleUpdateWithAutoTags();
    onClose();
  };

  const resetSelection = () => {
    setSelectedRequests([]);
    const gridApi = getGridApi(AI_ANALYZER_GRID);

    if (gridApi) {
      gridApi.deselectAll();
    }
  };

  const hideMergeDialog = () => {
    setShowMergeModal(false);
    resetSelection();
  };

  const userCanViewMerge = canView(PERMISSION_FEATURES.requestsMerge);
  const userCanViewBulkUpdate = canView(PERMISSION_FEATURES.requestsListBulkUpdate);

  const selectedItemsAllowedForMerge = selectedRequests
    ? selectedRequests.filter(i => canUpdate(PERMISSION_FEATURES.requestsMerge, i))
    : [];
  const selectedItemsAllowedForUpdate = selectedRequests
    ? selectedRequests.filter(i => canUpdate(PERMISSION_FEATURES.requestsListBulkUpdate, i))
    : [];

  return (
    <>
      <Header
        onClose={hadleCloseAndUpdateWithAutoTags}
        autoTagsCount={dataForAIAnalizerGrid.length}
        setAutoTagsAsRequestTags={handleSetAutoTagsAsRequestTags}
        rerunAutoTag={handleResetAutoTags}
        canSaveAutoTags={canSaveAutoTags}
        selectedRequests={selectedRequests}
        showMerge={userCanViewMerge}
        canMergeRequests={selectedItemsAllowedForMerge.length >= 2}
        onClickMergeRequests={() => setShowMergeModal(true)}
        showBulkUpdate={userCanViewBulkUpdate}
        canBulkUpdate={selectedItemsAllowedForUpdate.length !== 0}
        onClickBulkUpdateRequests={() => setShowBulkUpdateModal(true)}
      />
      <AIAnalyzerGrid customerRequests={dataForAIAnalizerGrid} handleSelectionChanged={handleSelectionChanged} />
      {showMergeModal && (
        <RequestsMergeModal
          selectedItems={selectedItemsAllowedForMerge}
          isVisible={showMergeModal}
          hideDialog={hideMergeDialog}
          disable={() => setShowMergeModal(false)}
        />
      )}
      {showBulkUpdateModal && (
        <RequestsBulkUpdateModal
          selectedItems={selectedItemsAllowedForUpdate}
          onClose={() => setShowBulkUpdateModal(false)}
          onBulkUpdateFinished={resetSelection}
        />
      )}
    </>
  );
};

export default PreviewScreen;
