import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

const Title = styled.a`
  display: contents;
  position: relative;
  ${({ $fontSize }) => $fontSize && `font-size: ${$fontSize}px;`}
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 1.2;

  ${props =>
    props.multiline &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: normal;
    `}

  cursor: pointer;
`;

const TitleContainer = styled.span`
  max-width: ${props => props.maxPercentageWidth && props.maxPercentageWidth}%;
  color: rgb(0, 157, 255);

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Separator = styled.span`
  color: #009dff;
`;

export default ({
  title,
  onClickTitle,
  multiline,
  maxPercentageWidth,
  showSeparator = false,
  fontSize = '',
  className,
  showTooltip,
  tooltipText,
}) => {
  const WrapperComponent = showTooltip ? Tooltip : Fragment;

  return (
    <React.Fragment>
      <WrapperComponent title={showTooltip && tooltipText} placement="bottom-start">
        <TitleContainer className={className} maxPercentageWidth={maxPercentageWidth}>
          <Title $fontSize={fontSize} onClick={onClickTitle} multiline={multiline}>
            {title}
          </Title>
        </TitleContainer>
      </WrapperComponent>
      {showSeparator && <Separator>&nbsp;&#92;&nbsp;</Separator>}
    </React.Fragment>
  );
};
