import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { not } from 'ramda';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

const MultiOrganizationAccessDropdown = props => {
  const { childrenDragons, selectedOrgAccess, onChange, disabled } = props;

  const [labelRef, setLabelRef] = useState(null);

  const isSelectNotDisabled = not(disabled);
  const allowToSelectOrganizationAccess = isSelectNotDisabled;

  const onLabelClick = useCallback(
    ({ currentTarget }) => {
      if (!allowToSelectOrganizationAccess) return;

      setLabelRef(currentTarget);
    },
    [allowToSelectOrganizationAccess],
  );

  const onResetLabelRef = useCallback(() => setLabelRef(null), []);

  const onMenuItemClickHandler = useCallback(childAccess => {
    onChange(childAccess);
    setLabelRef(null);
  }, []);

  return (
    <>
      <Label onClick={onLabelClick}>
        {selectedOrgAccess && `(${selectedOrgAccess.accountName || selectedOrgAccess.code})`}
        {allowToSelectOrganizationAccess && (
          <ExpandIcon>
            <ExpandMoreIcon />
          </ExpandIcon>
        )}
      </Label>
      <Popper placement="bottom-start" anchorEl={labelRef} open={!!labelRef} onClose={onResetLabelRef} style={{ zIndex: 100000 }}>
        <Paper>
          <ClickAwayListener onClickAway={onResetLabelRef}>
            <MenuList>
              {childrenDragons.map(childDragon => (
                <MenuItem key={childDragon.child_organization_id} onClick={() => onMenuItemClickHandler(childDragon)}>
                  {childDragon.accountName || childDragon.code}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

MultiOrganizationAccessDropdown.propTypes = {
  childrenDragons: PropTypes.array,
  selectedOrgAccess: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const Label = styled.span`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const ExpandIcon = styled.span`
  display: flex;
  margin-left: 2px;
`;

export default MultiOrganizationAccessDropdown;
