import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import head from 'lodash/head';

import { KEY_SEPARATOR } from './constants';
import { createRowOrderKey } from './basic';

/**
 * Generate a unique key using the arguments.
 *
 * @param {Array} orders The list of orders used in the drag and drop into different rows
 * @param {Object} group The group for this key
 * @param {Number} rowIndex The index of the row
 * @param {Array} groupOffsets The list of group widths differences
 * @param {Boolean} hideEmptyLane is this option active
 *
 * @return {String} an unique key the the group
 * */
const generateSingleGroupRowKey = (orders, group, rowIndex, groupOffsets, hideEmptyLane) => {
  const globalRowIndex = orders.findIndex(order => order === `${group.id}${KEY_SEPARATOR}${rowIndex}`);

  const projectsLength = group.rows.reduce((acc, row) => acc + row.length, 0);

  return createRowOrderKey(group.key, (orders || []).length, globalRowIndex, projectsLength, ...groupOffsets, hideEmptyLane);
};

/**
 * Generate a unique key using the arguments.
 *
 * @param {Array} orders The list of orders used in the drag and drop into different rows
 * @param {Object} groups The groups for this key
 * @param {Number} rowIndex The index of the row
 * @param {Array} groupOffsets The list of group widths differences
 * @param {Array} openGroups The list of open groups
 * @param {Boolean} hideEmptyLane is this option active
 *
 * @return {String} an unique key the groups
 * */
const generateMultiGroupRowKey = (orders, groups, rowIndex, groupOffsets, openGroups, hideEmptyLane) => {
  const groupIds = groups.map(group => group.id);

  const globalRowIndex = orders.findIndex(order => order === createRowOrderKey(...groupIds, rowIndex));

  const projectsLength = last(groups).rows.reduce((acc, row) => acc + row.length, 0);

  return createRowOrderKey(
    head(groups).key,
    (orders || []).length,
    (last(groups).rows || []).length,
    globalRowIndex,
    projectsLength,
    ...groupOffsets,
    JSON.stringify(openGroups),
    hideEmptyLane,
  );
};

/**
 * Generate an unique for the row order.
 *
 * @param {Array} data The timeline data structure
 * @param {Array} groupIndexes The index of the groups to search the right row [group1, group2, grouping]
 * @param {Number} rowIndex The index of the row
 *
 * @return {String} an unique key the row
 *
 * */
const getRowOrderKey = (data, groupIndexes, rowIndex) => {
  if (isEmpty(groupIndexes)) {
    return null;
  }

  const firstGroupId = data?.[groupIndexes[0]]?.id;

  if (groupIndexes.length === 1) {
    return createRowOrderKey(firstGroupId, rowIndex);
  }

  const secondGroupId = data?.[groupIndexes[0]]?.groups?.[groupIndexes[1]]?.id;

  if (groupIndexes.length === 2) {
    return createRowOrderKey(firstGroupId, secondGroupId, rowIndex);
  }

  if (groupIndexes.length === 3) {
    // return createRowOrderKey(firstGroupId, secondGroupId, GROUP_HEADERS_ROW, groupIndexes[2], rowIndex);
    return createRowOrderKey(firstGroupId, secondGroupId, rowIndex);
  }
};

export { generateSingleGroupRowKey, generateMultiGroupRowKey, getRowOrderKey };
