import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import pick from 'lodash/pick';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';

import { READ_ONLY_USER } from '@dragonboat/permissions';
import subscribeNewData from 'subscribeNewData';
import { MANAGE_PAGE } from 'constants/filters';
import { DEFAULT_TIME_WINDOW } from 'constants/timeline';
import { generateGanttColumns } from 'components/ganttCommon';
import { usePortfolioMode } from 'store/filters/selectors';
import useProjectsForGantt from 'hooks/timeline/useProjectsForGantt';
import useTodayMarker from 'hooks/timeline/useTodayMarker';
import useCollapsedTasks from 'hooks/timeline/useCollapsedTasks';
import useDisplayTimeframeMarkers from 'hooks/timeline/useDisplayTimeframeMarkers';
import useGanttSort from 'hooks/timeline/useGanttSort';
import setGanttColumnWidths from 'hooks/timeline/setGanttColumnWidths';
import useTimelineLsState from 'hooks/timeline/useTimelineLsState';
import { updateState } from 'store/manage';
import useTimelineProjects from 'hooks/timeline/useTimelineProjects';
import useTimelineGanttEffects from 'hooks/timeline/useTimelineGanttEffects';
import useLazyLoadStories from 'hooks/timeline/useLazyLoadStories';
import setTimelineGanttConfigs from 'hooks/timeline/setTimelineGanttConfigs';
import setTimelineGanttTimeWindowAndZoom from 'hooks/timeline/setTimelineGanttTimeWindowAndZoom';
import useInitTimelineGantt from 'hooks/timeline/useInitTimelineGantt';
import useLoadProjectsForTransactionPages from 'hooks/projects/useLoadProjectsForTransactionPages';
import useTimelineProjectsGantt from 'containers/GanttTimeline/useTimelineProjects';
import setGanttPlannedEndDateDisplay from 'containers/GanttTimeline/setGanttPlannedEndDateDisplay';
import setGanttPlannedStartDisplay from 'containers/GanttTimeline/setGanttPlannedStartDisplay';
import setGanttPredictedEndDateDisplay from 'containers/GanttTimeline/setGanttPredictedEndDateDisplay';
import useTimelineHCTable from 'containers/GanttTimeline/TimelineHCTable';
import setGanttTimelineBusinessValue from 'containers/GanttTimeline/TimelineBusinessValue';
import { getIdeasIntegrations } from 'store/organization/selectors';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const optionsForGanttData = [
  'searchStr',
  'showStories',
  'coloredGroups',
  'selectedColorBy',
  'showEstimates',
  'showTasks',
  'displayAlerts',
  'showOwnerAllocation',
  'selectedTimeWindow',
  'showGroupedTimeline',
  'hideItemsWithoutDependencies',
];

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [compState, _setCompState] = useState({
      selectedTimeWindow: DEFAULT_TIME_WINDOW,
      collapseGanttBars: false,
    });
    const portfolioMode = useSelector(usePortfolioMode);

    const setCompState = data => _setCompState({ ...compState, ...data });

    const manageState = useSelector(state => state.manage.manageState) || {};
    const currentUser = useSelector(state => state.login.currentUser);

    const premissions = usePermissions();

    const shouldDisplayControlsBar = premissions.canView(PERMISSION_FEATURES.controlsBar);

    // TODO: PERMISSION
    const isReadOnlyUser = currentUser.role_id === READ_ONLY_USER;
    const isAnonymousUser = currentUser.is_anonymous;
    const updateLsState = (state, makesActiveViewDirty = true) =>
      dispatch(updateState('manageState', state, makesActiveViewDirty));
    const defaultLsState = {
      showTasks: isBoolean(manageState.showTasks) ? manageState.showTasks : false,
      displayAlerts: isBoolean(manageState.displayAlerts) ? manageState.displayAlerts : true,
      showHCTable: false,
      showBusinessValue: false,
    };
    const lsState = {
      ...useTimelineLsState(manageState, updateLsState, MANAGE_PAGE, defaultLsState),
      selectedTimeWindow: compState.selectedTimeWindow,
    };
    const orgIntegrations = useSelector(getIdeasIntegrations);
    const showStoriesRef = useRef(lsState.showStories);
    const searchStringRef = useRef(lsState.searchStr);
    const portfolioModeRef = useRef(portfolioMode);
    const lsStateRef = useRef(lsState);

    lsStateRef.current = lsState;
    showStoriesRef.current = lsState.showStories;
    searchStringRef.current = lsState.searchStr;
    portfolioModeRef.current = portfolioMode;

    const [projects, ideas, initiatives, bets] = useTimelineProjects(lsState, MANAGE_PAGE, true);

    const updateTodayMaker = useTodayMarker();

    useLoadProjectsForTransactionPages(
      MANAGE_PAGE,
      {
        withDependencies: lsState.showAllDependencies,
        withChildren: portfolioMode,
      },
      { withIntegrations: !!showStoriesRef.current && !isEmpty(orgIntegrations) },
      { includeMilestones: true },
    );

    const [updateTimeframeMarkers] = useDisplayTimeframeMarkers();

    const groupBy = [lsState.selectedGroup1, ...(lsState.selectedGroup2?.key ? [lsState.selectedGroup2] : [])];

    const [tasks] = useProjectsForGantt(MANAGE_PAGE, {
      ...pick(lsState, optionsForGanttData),
      collapseGanttBars: compState.collapseGanttBars,
      portfolioMode,
      showEstimates: !portfolioMode && lsState.showEstimates,
      showTasks: !portfolioMode && lsState.showTasks,
      groupBy,
      projects,
      ideas,
      initiatives,
      bets,
      showId: lsState.selectedDisplayColumns && lsState.selectedDisplayColumns.some(c => c.id === 'key'),
      showIntegrationKey: lsState.selectedDisplayColumns && lsState.selectedDisplayColumns.some(c => c.id === 'integrationKey'),
    });

    // const [initGanttColumnsWidth] = useGanttColumnsWidth(updateLsState);

    const onInitGantt = ganttInstance => {
      setTimelineGanttConfigs(ganttInstance, lsState, columns, isReadOnlyUser, tasks, isAnonymousUser);
      setTimelineGanttTimeWindowAndZoom(ganttInstance, lsState);
      ganttInstance.attachEvent('onTaskOpened', id => {
        const task = ganttInstance.getTask(id);

        // Load stories on stories mode for projects with integraton progress
        if (lsState.showStories && task.dbType === 'project' && task.integrationProgress) {
          _loadProjectStories(id);
          // Load children for initiatives and bets when the page is on portfolio mode
        }
      });
      initGanttSort(ganttInstance);
    };
    const onRefreshGantt = (ganttInstance, data) => {
      setTimelineGanttConfigs(ganttInstance, lsState, columns, isReadOnlyUser, tasks, isAnonymousUser);
      setTimelineGanttTimeWindowAndZoom(ganttInstance, lsState);
      if (data) {
        sortGantt(lsState);
      }

      updateTodayMaker(ganttInstance);
      updateTimeframeMarkers(ganttInstance, lsState.showTimeframes);
    };

    const columns = generateGanttColumns({
      selectedDisplayColumns: lsState.selectedDisplayColumns,
      savedWidths: lsState.columnsWidth,
      orgIntegrations,
    });

    const _onHCTableCellClick = (_, { teamId, skillId, userId }) => {
      if (lsStateRef.current.HCTableFilter) {
        updateLsState({ HCTableFilter: null });
      } else {
        updateLsState({
          HCTableFilter: {
            teamId,
            skillId,
            userId,
          },
        });
      }
    };

    const mapGanttData = opens => task => {
      const open = opens && opens.includes(String(task.id));

      return {
        ...task,
        open,
        $open: open,
      };
    };

    const ganttTasks = {
      data: tasks.data.map(mapGanttData(lsState.openTasks)),
      links: tasks.links,
    };

    const setGanttTimelineHCTable = useTimelineHCTable({
      tasks: ganttTasks,
      HCTableFilter: lsState.HCTableFilter,
      selectedTimelineInterval: lsState.selectedZoom,
      selectedRounding: lsState.selectedRounding,
      selectedHighlight: lsState.selectedHighlight,
      selectedTeam: lsState.selectedTeam,
      onHCTableCellClick: _onHCTableCellClick,
      showResourceUsers: lsState.showResourceUsers,
      resourceUsersGroup: lsState.resourceUsersGroup,
    });

    const { gantt, refreshGantt, ganttRef, reloadGantt } = useInitTimelineGantt({
      data: tasks,
      lsState,
      onRefresh: onRefreshGantt,
      columns,
      columnSort: lsState.sort,
      ganttInitFuncs: [
        onInitGantt,
        setGanttPlannedStartDisplay(lsState.plannedStartDate),
        setGanttPlannedEndDateDisplay(lsState.plannedEndDate),
        setGanttPredictedEndDateDisplay(lsState.displayStoryProgEndDate, lsState.showStories, orgIntegrations),
        lsState.showBusinessValue &&
          setGanttTimelineBusinessValue({
            selectedTimelineInterval: lsState.selectedZoom,
            selectedRounding: lsState.selectedRounding,
          }),
        lsState.showHCTable && setGanttTimelineHCTable,
        setGanttColumnWidths(updateLsState),
      ],
    });

    const { collapseExpandAll } = useCollapsedTasks(gantt, updateLsState, lsState);
    const _loadProjectStories = useLazyLoadStories(gantt, ganttTasks, lsState);
    const [initGanttSort, sortGantt] = useGanttSort(gantt, updateLsState, ganttTasks, lsState.sort);

    useTimelineGanttEffects({
      gantt,
      refreshGantt,
      lsState,
      columns,
      tasks,
      ganttTasks,
      reloadGantt,
    });
    const _afterAddInlineProject = () => {
      if (lsState.searchStr) updateLsState({ searchStr: '' }, false);
    };

    useEffect(() => {
      const openTasks = collapseExpandAll(lsState.allCollapsed);
      const updatedTasks = {
        data: tasks.data.map(mapGanttData(openTasks)),
        links: tasks.links,
      };

      refreshGantt(updatedTasks);
    }, [lsState.allCollapsed]);

    useTimelineProjectsGantt({
      gantt,
      selectedGroup: groupBy,
      localMode: lsState.localMode,
      afterAddInlineProject: _afterAddInlineProject,
      portfolioMode,
      editAllTasks: true,
      showStories: lsState.showStories,
    });

    return (
      <Component
        {...props}
        currentUser={currentUser}
        gantt={gantt}
        tasks={ganttTasks}
        projects={projects}
        allProjects={[...ideas, ...initiatives, ...bets]}
        lsState={lsState}
        compState={compState}
        setCompState={setCompState}
        updateLsState={updateLsState}
        portfolioMode={portfolioMode}
        ganttRef={ganttRef}
        showPageConfigForReadOnly={shouldDisplayControlsBar}
      />
    );
  };
};

export default compose(
  subscribeNewData(['app', 'projects', 'tasks', 'estimates', 'customFields', 'timeframes']),
  withRouter,
  componentHOC,
);
