import reduceReducers from 'reduce-reducers';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import { IMPORT_METRIC_VALUES } from './types';

const { initialState: operationsInitialState, reducers: operationsReducers } = getThunksInitialStateAndReducers([
  IMPORT_METRIC_VALUES,
]);

export const initialState = {
  ...operationsInitialState,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reduceReducers(initialState, reducer, ...operationsReducers);
