import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { not } from 'ramda';
import axios from 'axios';

import { fetchProjectDeliverableDetails } from 'store/cycleDeliverables';
import { getProjectDeliverableDetails, getCycleDeliverableById } from 'store/cycleDeliverables/selectors';
import { getSelectedProject } from 'store/projects';

const fetchProject = async id => {
  try {
    const request = await axios.get(`/api/projects/${id}`);

    return request.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * @function useProjectDeliverableDetails
 *
 * hook to use project deliverable details and fetch the data from server
 *
 * @param  {Number} projectId
 * @param  {Number} cycleDeliverableId
 * @return {Object}
 */
const useProjectDeliverableDetails = (projectId, cycleDeliverableId) => {
  const [tempProject, setTempProject] = useState(null);
  const dispatch = useDispatch();
  const projectDeliverable = useSelector(state => getProjectDeliverableDetails(state, projectId, cycleDeliverableId));
  const cycleDeliverable = useSelector(state => getCycleDeliverableById(state, cycleDeliverableId));
  const projectFromStore = useSelector(state => getSelectedProject(state, { id: projectId }));

  useEffect(() => {
    if (not(projectId) || not(cycleDeliverableId)) {
      return;
    }

    dispatch(fetchProjectDeliverableDetails(projectId, cycleDeliverableId));
  }, [projectId, cycleDeliverableId]);

  useEffect(() => {
    if (!projectFromStore && projectId) {
      fetchProject(projectId).then(p => setTempProject(p));

      return;
    }

    setTempProject(null);
  }, [projectFromStore, projectId]);

  return {
    cycleDeliverable,
    project: projectFromStore || tempProject,
    projectDeliverable,
  };
};

export default useProjectDeliverableDetails;
