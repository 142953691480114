import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_ORGANIZATION_ACCESS_CONTROL'),
  ...getThunkActionTypes('ADD_ORGANIZATION_ACCESS_CONTROL'),
  ...getThunkActionTypes('REMOVE_ORGANIZATION_ACCESS_CONTROL'),
  ...getThunkActionTypes('CREATE_ORGANIZATION_READER_TOKEN'),
  ...getThunkActionTypes('FETCH_PROJECT_READERS'),
  ...getThunkActionTypes('ADD_PROJECT_READERS'),
  ...getThunkActionTypes('REMOVE_PROJECT_READER'),
};

export const {
  FETCH_ORGANIZATION_ACCESS_CONTROL,
  FETCH_ORGANIZATION_ACCESS_CONTROL_PENDING,
  FETCH_ORGANIZATION_ACCESS_CONTROL_FULFILLED,
  FETCH_ORGANIZATION_ACCESS_CONTROL_REJECTED,

  ADD_ORGANIZATION_ACCESS_CONTROL,
  ADD_ORGANIZATION_ACCESS_CONTROL_PENDING,
  ADD_ORGANIZATION_ACCESS_CONTROL_FULFILLED,
  ADD_ORGANIZATION_ACCESS_CONTROL_REJECTED,

  REMOVE_ORGANIZATION_ACCESS_CONTROL,
  REMOVE_ORGANIZATION_ACCESS_CONTROL_PENDING,
  REMOVE_ORGANIZATION_ACCESS_CONTROL_FULFILLED,
  REMOVE_ORGANIZATION_ACCESS_CONTROL_REJECTED,

  CREATE_ORGANIZATION_READER_TOKEN,
  CREATE_ORGANIZATION_READER_TOKEN_PENDING,
  CREATE_ORGANIZATION_READER_TOKEN_FULFILLED,
  CREATE_ORGANIZATION_READER_TOKEN_REJECTED,

  FETCH_PROJECT_READERS,
  FETCH_PROJECT_READERS_PENDING,
  FETCH_PROJECT_READERS_FULFILLED,
  FETCH_PROJECT_READERS_REJECTED,
  FETCH_PROJECT_READERS_RESET,

  ADD_PROJECT_READERS,
  ADD_PROJECT_READERS_PENDING,
  ADD_PROJECT_READERS_FULFILLED,
  ADD_PROJECT_READERS_REJECTED,
  ADD_PROJECT_READERS_RESET,

  REMOVE_PROJECT_READER,
  REMOVE_PROJECT_READER_PENDING,
  REMOVE_PROJECT_READER_FULFILLED,
  REMOVE_PROJECT_READER_REJECTED,
  REMOVE_PROJECT_READER_RESET,
} = actionTypes;

export default actionTypes;
