import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { selectSelectedSnapshotsIdsToCompare, selectSelectedSnapsToCompare } from 'features/RoadmapHistory/store/selectors';
import { prop, uniqBy } from 'ramda';

const metadatasToCheck = [
  {
    metadataKey: 'roadmaps',
    projectMetadataKey: 'roadmap',
    multipleValues: false,
  },
  {
    metadataKey: 'products',
    projectMetadataKey: 'product1',
  },
  {
    metadataKey: 'products',
    projectMetadataKey: 'product2',
  },
  {
    metadataKey: 'objectives',
    projectMetadataKey: 'objective',
  },
  {
    metadataKey: 'keyResults1',
    projectMetadataKey: 'keyResult1',
  },
  {
    metadataKey: 'keyResults2',
    projectMetadataKey: 'keyResult2',
  },
  {
    metadataKey: 'categories',
    projectMetadataKey: 'category',
  },
  {
    metadataKey: 'phases',
    projectMetadataKey: 'phase',
  },
  {
    metadataKey: 'priorities',
    projectMetadataKey: 'priority',
  },
  {
    metadataKey: 'themes',
    projectMetadataKey: 'theme',
  },
  {
    metadataKey: 'timeframes',
    projectMetadataKey: 'timeframe',
  },
  {
    metadataKey: 'timeframes2',
    projectMetadataKey: 'timeframe2',
  },
  {
    metadataKey: 'users',
    projectMetadataKey: 'owner',
  },
  {
    metadataKey: 'lifecycles',
    projectMetadataKey: 'lifecycles',
    multipleValues: true,
  },
  {
    metadataKey: 'personas',
    projectMetadataKey: 'personas',
    multipleValues: true,
  },
  {
    metadataKey: 'tags',
    projectMetadataKey: 'tags',
    multipleValues: true,
  },
  {
    metadataKey: 'metrics',
    projectMetadataKey: 'metrics',
    multipleValues: true,
  },
  {
    metadataKey: 'customers',
    projectMetadataKey: 'customers',
    multipleValues: true,
  },
  {
    metadataKey: 'skills',
    projectMetadataKey: 'skills',
    multipleValues: true,
  },
  {
    metadataKey: 'teams',
    projectMetadataKey: 'teams',
    multipleValues: true,
  },
  {
    metadataKey: 'users',
    projectMetadataKey: 'watchers',
    multipleValues: true,
  },
];

const getMissingMetadatasFromSingleValue = (project, metadataToCheck, updatedMetadata) => {
  const projectMetadata = project?.[metadataToCheck.projectMetadataKey];
  const metadataExists = updatedMetadata?.[metadataToCheck.metadataKey]?.find(metadata => metadata?.id === projectMetadata?.id);

  if (metadataExists || !projectMetadata) {
    return [];
  }
  return [projectMetadata];
};

const getMissingMetadatasFromMultipleValue = (project, metadataToCheck, updatedMetadata) => {
  const projectMetadatas = project?.[metadataToCheck.projectMetadataKey] || [];

  return projectMetadatas.filter(projectMetadata => {
    return updatedMetadata?.[metadataToCheck.metadataKey]?.find(metadata => metadata?.id !== projectMetadata?.id);
  });
};

const useGetMetadataFromSelectedSnapsToCompare = ({ metadata = {} }) => {
  const currentSelectedSnapsIds = useSelector(selectSelectedSnapshotsIdsToCompare);
  const selectedSnapsToCompare = useSelector(selectSelectedSnapsToCompare);
  const selectedSnapsToCompareRef = useRef(selectedSnapsToCompare);
  const metadataRef = useRef(metadata);

  selectedSnapsToCompareRef.current = selectedSnapsToCompare;
  metadataRef.current = metadata;

  const metadataWithSnapMetadata = useMemo(() => {
    const updatedMetadata = { ...metadataRef.current };

    Object.values(selectedSnapsToCompareRef.current).forEach(snapshot => {
      snapshot.projects?.forEach(({ project_data: project }) => {
        metadatasToCheck.forEach(metadataToCheck => {
          let missingMetadatas;

          if (metadataToCheck.multipleValues) {
            missingMetadatas = getMissingMetadatasFromMultipleValue(project, metadataToCheck, updatedMetadata);
          } else {
            missingMetadatas = getMissingMetadatasFromSingleValue(project, metadataToCheck, updatedMetadata);
          }

          if (missingMetadatas.length) {
            updatedMetadata[metadataToCheck.metadataKey] = uniqBy(prop('id'), [
              ...(updatedMetadata?.[metadataToCheck.metadataKey] || []),
              ...missingMetadatas,
            ]);
          }
        });
      });
    });

    return updatedMetadata;
  }, [currentSelectedSnapsIds]);

  return metadataWithSnapMetadata;
};

export default useGetMetadataFromSelectedSnapsToCompare;
