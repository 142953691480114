import axios from 'axios';

import { createThunk } from 'utils/store/thunk';

import {
  GET_PROJECT_FIELDS_CONFIGURATION,
  UPDATE_PROJECT_FIELDS_CONFIGURATION,
  GET_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION,
  UPDATE_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION,
} from './types';
import throwRequestError from '../utils/throwRequestError';

import { cleanConfigurationData } from './helpers';

// Project Field Layout Configuration
const getProjectFieldsConfiguration = layer =>
  createThunk(GET_PROJECT_FIELDS_CONFIGURATION, axios.get(`/api/projectFieldsLayout/${layer}`).catch(throwRequestError), {
    layer,
  });

const updateProjectFieldsConfiguration = (layer, data) =>
  createThunk(
    UPDATE_PROJECT_FIELDS_CONFIGURATION,
    axios.put(`/api/projectFieldsLayout/${layer}`, cleanConfigurationData(data)).catch(throwRequestError),
    { layer },
  );

// Customer Request Mapping
const getCustomerRequestStatusMappingConfiguration = () =>
  createThunk(
    GET_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION,
    axios.get(`/api/customerRequestStatusMapping`).catch(throwRequestError),
  );

const updateCustomerRequestStatusMappingConfiguration = data =>
  createThunk(
    UPDATE_CUSTOMER_REQUEST_STATUS_MAPPING_CONFIGURATION,
    axios.put(`/api/customerRequestStatusMapping`, data).catch(throwRequestError),
  );

export {
  getProjectFieldsConfiguration,
  updateProjectFieldsConfiguration,
  getCustomerRequestStatusMappingConfiguration,
  updateCustomerRequestStatusMappingConfiguration,
};
