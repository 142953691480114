import loading from './loading.gif';

export default class CustomLoadingOverlay {
  init(params) {
    if (this.hideOverlay) clearTimeout(this.hideOverlay);

    this.hideOverlay = setTimeout(() => {
      try {
        params.api.hideOverlay();
      } catch (e) {
        // do nothing
      }
    }, 2000); // hide loading overlay after 2s

    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `<div class="ag-overlay-loading-center" style="visibility: hidden;"><img src="${loading}" /></div>`;
  }

  getGui() {
    return this.eGui;
  }
}
