import React, { useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { not } from 'ramda';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import BarChart from 'components/ReactChart/BarChart';
import makeTooltipLabel from 'utils/charts/makeTooltipLabel';
import makeTooltipLabelWithPercentage from 'utils/charts/makeTooltipLabelWithPercentage';
import useBarStackedCharDatalabelsConfig from 'hooks/charts/useBarStackedCharDatalabelsConfig';
import ChartContent from '../ChartContent';

import useGoalsProgressChartData, { PROGRESS_DATA_SET_KEY } from './useGoalsProgressChartData';
import { HIDE_LEGEND_OPTION, HIDE_GRID_OPTION } from 'constants/charts';
import { tickSmallStyles, tickStyles } from '../utils/scales';
import ChartLegend from '../ChartLegend';

const MAX_LEGEND_ITEMS = 6;
const PROGRESS_LINE_DATALABEL_OFFSET = 8;
const PROGRESS_LINE_DATALABEL_ALIGN = 'right';
const PROGRESS_LINE_MIN_Y = 0; // 0%
const PROGRESS_LINE_MAX_Y = 100; // 100%

const tooltipLabelFormatter = tooltipItem => {
  if (tooltipItem.dataset.id === PROGRESS_DATA_SET_KEY) {
    return makeTooltipLabelWithPercentage(tooltipItem);
  }
  return makeTooltipLabel(tooltipItem);
};

const GoalsProgressStackedChart = ({ wrapper: Wrapper, title, subtitle, actionButtons }) => {
  const { chartData, hasData } = useGoalsProgressChartData();
  const stackedChartData = chartData.datasets.filter(dataset => dataset.id !== PROGRESS_DATA_SET_KEY);
  const datalabelsConfig = useBarStackedCharDatalabelsConfig(stackedChartData);

  const datalabelsDisplay = useCallback(
    ctx => {
      if (ctx.dataset.id === PROGRESS_DATA_SET_KEY) {
        return true;
      }
      return datalabelsConfig.display(ctx);
    },
    [datalabelsConfig],
  );
  const datalabelsAlignment = useCallback(
    ctx => {
      if (ctx.dataset.id === PROGRESS_DATA_SET_KEY) {
        return PROGRESS_LINE_DATALABEL_ALIGN;
      }
      return datalabelsConfig.labels.value.align;
    },
    [datalabelsConfig],
  );
  const datalabelsOffset = useCallback(
    ctx => {
      if (ctx.dataset.id === PROGRESS_DATA_SET_KEY) {
        return PROGRESS_LINE_DATALABEL_OFFSET;
      }
      return datalabelsConfig.labels.value.offset;
    },
    [datalabelsConfig],
  );

  if (not(hasData)) {
    return '';
  }

  return (
    <Wrapper>
      <ChartContent title={title} subtitle={subtitle} actionButtons={actionButtons}>
        <BarChartContainer>
          <BarChart
            data={chartData}
            plugins={[ChartDataLabels]}
            options={{
              plugins: {
                legend: {
                  ...HIDE_LEGEND_OPTION,
                },
                datalabels: {
                  ...datalabelsConfig,
                  labels: {
                    value: {
                      align: datalabelsAlignment,
                      offset: datalabelsOffset,
                    },
                  },
                  display: datalabelsDisplay,
                },
                tooltip: {
                  callbacks: {
                    label: tooltipLabelFormatter,
                  },
                },
              },
              animation: {
                duration: 0,
              },
              scales: {
                x: {
                  stacked: true,
                  ticks: {
                    ...tickStyles,
                  },
                  grid: {
                    ...HIDE_GRID_OPTION,
                  },
                },
                y: {
                  stacked: true,
                  ticks: {
                    ...tickSmallStyles,
                    stepSize: 1,
                  },
                },
                yProgress: {
                  display: false,
                  type: 'linear',
                  min: PROGRESS_LINE_MIN_Y,
                  max: PROGRESS_LINE_MAX_Y,
                },
              },
            }}
          />
        </BarChartContainer>
        <ChartLegend legends={chartData?.datasets} maxLegendItems={MAX_LEGEND_ITEMS} />
      </ChartContent>
    </Wrapper>
  );
};

GoalsProgressStackedChart.propTypes = {
  wrapper: PropTypes.func,
};

export default GoalsProgressStackedChart;

const BarChartContainer = styled.div`
  padding: 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
