import React from 'react';
import styled, { css } from 'styled-components';

import Grid from '@material-ui/core/Grid';

import BreadcrumbTitle from 'design-system/molecules/BreadcrumbTitle/index';


const minWidth = 30;

const HeaderBreadcrumbTitles = ({ items, showTooltip, minWidthTitle = minWidth, className }) => {
  if (!items) return null;

  return (
    <BreadcrumbWrapper itemsSize={items.length} item $minWidthTitle={minWidthTitle} className={className}>
      {items.map(item => (
        <BreadcrumbTitle {...item} showTooltip={showTooltip} />
      ))}
    </BreadcrumbWrapper>
  );
};

export default HeaderBreadcrumbTitles;

const BreadcrumbWrapper = styled(Grid)`
  display: flex;
  flex: auto;
  align-items: center;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  > * {
    display: inline-flex;

    :last-child {
      flex-grow: 1;
      // set the maximum from the available space so it does not go beyond the limits of the parent component
      max-width: calc(
        100% - ${props => props.itemsSize - 1} *
          ${({ $minWidthTitle }) => css`min(${$minWidthTitle}%, calc(100% / ${props => props.itemsSize}))`}
      );
    }
    :not(:last-child) {
      max-width: ${({ $minWidthTitle }) => css`min(${$minWidthTitle}%, calc(100% / ${props => props.itemsSize}))`};
    }
  }
`;
