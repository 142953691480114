/**
 * AgGridHierarchyTitle Organism Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React, { PureComponent } from 'react';
import isFunction from 'lodash/isFunction';
import { path, prop } from 'ramda';

import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import OpenInNew from '@material-ui/icons/OpenInNew';

import TextContent from './TextContent';
import TextDeprecated from '../../atoms/TextDeprecated';

const DEFAULT_INDENTATION = 25;

const getLinkProp = prop('link');
const getData = path(['params', 'data']);
const defaultGetNOfTextRowsToDisplay = () => 0;

export default class AgGridHierarchyTitle extends PureComponent {
  state = {
    hover: false,
  };

  addChildOnHover = () => {
    const { node } = this.props.params;

    if (!node.expanded) {
      setTimeout(() => node.setExpanded(true));
    }

    if (node?.childrenAfterGroup?.length) {
      node.childrenAfterGroup.forEach(child => {
        if (child.key !== 'Undefined' && child.expanded) {
          setTimeout(() => child.setExpanded(false));
        }

        if (child.key === 'Undefined' && !child.expanded) {
          setTimeout(() => child.setExpanded(true));
        }
      });
    }

    this.props.addChild();
  };

  decoratedOnClick = event => {
    if (this.props.onClick) {
      this.props.onClick(this.props.params, event);
    }
  };

  renderTitle = () => {
    if (this.props.renderTitle) {
      return this.props.renderTitle(this.props.params.data);
    }

    return this.props.params.value;
  };

  hasLinkAction = () => {
    const linkProp = getLinkProp(this.props);

    if (!linkProp) return false;

    const data = getData(this.props);

    if (!data) return false;

    const dataId = prop('id', data);

    if (!dataId) return false;

    const linkResultIsFunction = isFunction(linkProp(dataId, data));

    return linkResultIsFunction;
  };

  render() {
    const { node } = this.props.params;
    const { wrapText, textStyles, indentation = DEFAULT_INDENTATION } = this.props;

    if (!this.props?.params?.value) {
      return null;
    }

    const { getMaxLineNumber = defaultGetNOfTextRowsToDisplay } = this.props;

    const actionAddWidth = this.props.showAddOnHover || this.props.showAdd ? 26 : 0;
    const actionOpenWidth = this.hasLinkAction() ? 26 : 0;

    return (
      <Wrapper
        onMouseLeave={() => this.setState({ hover: false })}
        onMouseEnter={() => this.setState({ hover: true })}
        isChild={this.props.isChild}
        level={node.level}
        padChild={this.props.padChild}
        selectable={node.data && node.data.selectable}
        onClick={this.decoratedOnClick}
        $indentation={indentation}
      >
        <div
          style={{
            width: `calc(100% - ${actionAddWidth + actionOpenWidth}px)`,
            overflow: 'hidden',
            display: 'flex',
            height: '100%',
            ...(!this.props.icon ? { marginTop: '3px' } : {}),
          }}
        >
          {this.props.icon ? <React.Fragment>{this.props.icon}&nbsp;&nbsp;</React.Fragment> : null}
          {wrapText && (
            <TextWrapper $nOfTextRowsToDisplay={getMaxLineNumber()}>
              <TextDeprecated textStyles={textStyles}>
                <TextContent showTooltip={this.props.showTooltip} text={this.renderTitle()} />
                {this.props.showChildrenCount && (
                  <ChildrenCount>
                    {/* commented because childrenCount from backend is different from frontend results */}(
                    {/* this.props.childrenCount ? this.props.childrenCount : */ this.props.params.node.allChildrenCount || 0})
                  </ChildrenCount>
                )}
              </TextDeprecated>
            </TextWrapper>
          )}
          {!wrapText && (
            <TextDeprecated textStyles={textStyles}>
              <TextContent showTooltip={this.props.showTooltip} text={this.renderTitle()} />
              {this.props.showChildrenCount && (
                <ChildrenCount>
                  {/* commented because childrenCount from backend is different from frontend results */}(
                  {/* this.props.childrenCount ? this.props.childrenCount : */ this.props.params.node.allChildrenCount || 0})
                </ChildrenCount>
              )}
            </TextDeprecated>
          )}
        </div>
        <ActionWrapper isChild={this.props.isChild}>
          {this.props.showAddOnHover && (
            <LinkIconButton id="port-list-add-tree" onClick={this.addChildOnHover} color="primary" size="small">
              <AddIcon fontSize="small" />
            </LinkIconButton>
          )}
          {this.hasLinkAction() && (
            <LinkIconButton color="primary" onClick={this.props.link(this.props.params.data.id, this.props.params.data)}>
              <OpenInNew style={{ fontSize: 14, width: '20px' }} />
            </LinkIconButton>
          )}
          {this.props.showAdd && (
            <ActionButton id="add-child-button" onClick={this.props.addChild} color="primary" size="small" hover>
              <AddIcon fontSize="small" />
            </ActionButton>
          )}
        </ActionWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  margin-top: ${props => (props.isChild ? '-19px' : '-21px')};
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 2px;
  height: 100%;

  ${props => {
    if (props.level !== undefined) {
      const left = props.selectable ? 80 : 30;
      const levelMargin = props.level * props.$indentation;
      const margin = left + levelMargin;

      return `padding-left: ${margin}px`;
    }

    return `padding-left: ${props.isChild ? '100px' : '80px'}`;
  }}
`;

const TextWrapper = styled.div`
  width: 100%;
  height: calc(1.5em * ${({ $nOfTextRowsToDisplay = 0 }) => $nOfTextRowsToDisplay});

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ $nOfTextRowsToDisplay = 0 }) => $nOfTextRowsToDisplay};
  -webkit-box-orient: vertical;
  flex: 1;

  p {
    word-break: break-word;
    white-space: break-spaces !important;
    overflow: visible !important;
    width: calc(100% - 14px) !important;
  }
`;

const ActionWrapper = styled.div`
  width: 14px;
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => (props.isChild ? '-3px' : 0)};
`;

const ActionButton = styled(IconButton)`
  &&&& {
    width: 25px;
    height: 25px !important;
    padding: 0;
    opacity: ${props => (props.hover === true ? 1 : 0)};
  }
`;

const LinkIconButton = styled(props => <IconButton {...props}>{props.children}</IconButton>)`
  &&&& {
    position: relative;
    visibility: hidden;
    padding: 3px;
    font-size: 1.125rem;
  }
`;

const ChildrenCount = styled.span`
  margin-left: 5px;
  font-size: 12px;
  color: #b1b1b1;
`;
