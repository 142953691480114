import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrgHasJiraIntegrated, getOrgHasMultipleIntegrations } from 'store/organization/selectors';
import usePagination from 'hooks/usePagination';
import usePageFilters from 'hooks/filters/usePageFilters';
import { getUserFilters } from 'store/filters/selectors';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import { fetchProjects, getTotalProjectResults, isLoadingProjects } from 'store/projects';
import { DEFAULT_PAGINATION_LIMIT } from 'constants/projects';

/**
 * Custom hook for paginating and fetching data for the IdeasGridFlat component.
 *
 * @param {Object} options - The options for the hook.
 * @param {string} options.pageId - The ID of the page.
 * @returns {Object} - An object containing functions and state variables for pagination and data fetching.
 */
const useIdeasGridFlatPagination = ({ pageId }) => {
  const [pageLimit, setPageLimit] = useState(DEFAULT_PAGINATION_LIMIT);
  const [currentPage, setCurrentPage] = useState(1);

  // In the ideas grid the withIntegrations must be forced in order to display the integration key in the grid
  const hasJiraIntegration = useSelector(getOrgHasJiraIntegrated);
  const orgHasMultipleIntegrations = useSelector(getOrgHasMultipleIntegrations);

  const dispatch = useDispatch();
  const { pageFilters: filters, displayLayer } = usePageFilters(pageId);
  const hasBet = useSelector(state => state.organization.organization.has_bet);
  const userFilters = useSelector(state => getUserFilters(state));
  const totalProjects = useSelector(getTotalProjectResults);
  const isFetchingProjects = useSelector(isLoadingProjects);

  const handleFetchData = useCallback(options => {
    setCurrentPage(options.page);

    const filtersForApiByLevels = compileFiltersBody(filters, userFilters, hasBet, pageId, displayLayer, null);

    const associations = {
      withTasks: true,
      withEstimates: true,
      withIntegrations: !hasJiraIntegration || orgHasMultipleIntegrations,
      withParents: true,
      withVotes: true,
    };

    const limit = options.limit || pageLimit;
    const to = options.page * limit;

    dispatch(fetchProjects(filtersForApiByLevels, associations, {}, false, true, {}, {}, { from: to - limit, to }));
  }, []);

  const { handleChangePage, handleChangePageLimit } = usePagination(
    handleFetchData,
    currentPage,
    currentPage + 1,
    currentPage - 1,
    totalProjects,
    pageLimit,
    limit => {
      setPageLimit(limit);
      handleFetchData({ page: 1, limit });
    },
  );

  const lastPage = Math.ceil(totalProjects / pageLimit);

  return {
    handleFetchData,
    handleChangePage,
    handleChangePageLimit,

    isFetchingProjects,
    totalProjects,
    currentPage,
    pageLimit,
    lastPage,
  };
};

export default useIdeasGridFlatPagination;
