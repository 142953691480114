import React from 'react';
import PropTypes from 'prop-types';

import ForecastByHeadcountTeamsFilter from './ForecastByHeadcountTeamsFilter';
import ForecastPlanBasedOn from './ForecastPlanBasedOn';

const ForecastByHeadcount = ({ pageId, teamsFilterProps = {} }) => {
  return (
    <>
      <ForecastByHeadcountTeamsFilter {...teamsFilterProps} />
      <ForecastPlanBasedOn pageId={pageId} />
    </>
  );
};

ForecastByHeadcount.propTypes = {
  pageId: PropTypes.string,
  teamsFilterProps: PropTypes.object,
};

export default ForecastByHeadcount;
