import { useMemo, useCallback } from 'react';
import { defaultTo } from 'ramda';
import { useSelector } from 'react-redux';

import { defaultColumnDefCommonProps, getCustomFieldColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { TitleCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { processGroomedColumnDefs, processColumnsGroup as processColumnsGroupUtil } from 'utils/grids/helpers';
import { applyCheckIsEditable } from 'design-system/molecules/AgGridReact-New/helpers';

import useCustomerRequestsGridMetadata from '../useCustomerRequestsGridMetadata';
import useCustomerRequestsGeneralFieldsColumnDefs from './useCustomerRequestsGeneralFieldsColumnDefs';
import useCustomerRequestsScoringFieldsColumnDefs from './useCustomerRequestsScoringFieldsColumnDefs';
import useCustomerRequestsPreCalculatedFieldsColumnDefs from './useCustomerRequestsPreCalculatedFieldsColumnDefs';
import { checkIsEditable } from './helpers';
import { getCustomerRequestIntegrations } from 'store/organization';
import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';
import { defaultExport, exportDate, exportMultiSelectMetadata, exportYesNo } from 'features/GridExport/helpers/exportUtils';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

const defaultToEmptyArray = defaultTo([]);

const TITLE_COLUMN_WIDTH = 400;
const GROUP_COLUMN_ID = 'title';

const exportFnByFieldType = fieldType => {
  switch (fieldType) {
    case TYPES_OF_CUSTOM_FIELDS.DATE:
      return exportDate;
    case TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN:
      return exportMultiSelectMetadata;
    case TYPES_OF_CUSTOM_FIELDS.YES_NO:
      return exportYesNo;
    default:
      return defaultExport;
  }
};

/**
 * @function useCustomerRequestsColumnsDefs
 *
 * Hook to collect all column defs for the customer requests grid
 *
 * @return {Object}
 */
const useCustomerRequestsColumnsDefs = ({
  searchString,
  isBulkUpdate,
  visibleColumnsIds,
  columnsState,
  rowHeight,

  updateCustomerRequestById,
  onOpenCustomerRequestInfo,
  createMultiSelectOption,
}) => {
  const { customFields } = useCustomerRequestsGridMetadata();

  const integrations = useSelector(getCustomerRequestIntegrations);

  const { canView } = usePermissions();

  const canViewRequestsDrawer = canView(PERMISSION_FEATURES.customerRequestsDrawer);

  const generalFieldsColumnDefs = useCustomerRequestsGeneralFieldsColumnDefs({
    rowHeight,
    onOpenCustomerRequestInfo: canViewRequestsDrawer ? onOpenCustomerRequestInfo : null,
    createMultiSelectOption,
    updateCustomerRequestById,
    integrations,
  });

  const scoringFieldsColumnDefs = useCustomerRequestsScoringFieldsColumnDefs();

  const preCalculatedFieldsColumnDefs = useCustomerRequestsPreCalculatedFieldsColumnDefs();

  const customFieldsColumnDefs = useMemo(() => {
    return defaultToEmptyArray(customFields).map(cf => {
      const exportFn = exportFnByFieldType(cf.field_type);

      return getCustomFieldColumnDef({
        ...cf,
        ...(exportFn ? { exportFn } : {}),
        fieldType: cf.field_type,
        dataEnhanced: cf.data_enhanced,
      });
    });
  }, [customFields]);

  const processColumnsGroup = useCallback(
    columns => processColumnsGroupUtil(columns, visibleColumnsIds, columnsState),
    [visibleColumnsIds, columnsState],
  );

  const groupColumnState = useMemo(() => columnsState && columnsState.find(c => c.colId === GROUP_COLUMN_ID), [columnsState]);

  /*
   * isBulkUpdate is included on depencies because the grid is not
   * changing the select on rows if the title column def does not change
   */
  const groupColumnDef = useMemo(
    () => ({
      field: 'title',
      headerName: 'Title',
      suppressMovable: true,
      comparator: stringComparator,
      headerClass: 'first-field',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: params => ({
        innerRenderer: TitleCellRenderer,
        suppressCount: true,
        suppressDoubleClickExpand: true,
        titleSearchString: searchString,
        icon: OpenInNewIcon,
        openButtonProps: {
          hasOpenButton: canViewRequestsDrawer && onOpenCustomerRequestInfo && !!params.data,
          onClick: () => onOpenCustomerRequestInfo(params.data),
        },
      }),
      rowDrag: true,
      initialHide: false,
      pinned: 'left',
      lockPinned: true,
      lockPosition: 'left',
      width: groupColumnState?.width || TITLE_COLUMN_WIDTH,
      headerCheckboxSelection: () => isBulkUpdate,
      checkboxSelection: () => isBulkUpdate,
      exportFn: defaultExport,
    }),
    [groupColumnState, searchString, onOpenCustomerRequestInfo, isBulkUpdate, canViewRequestsDrawer],
  );

  const columnDefs = useMemo(
    () =>
      processGroomedColumnDefs(
        [
          groupColumnDef,

          // general fields
          ...processColumnsGroup(generalFieldsColumnDefs),

          // custom fields
          ...processColumnsGroup(customFieldsColumnDefs),

          // scoring fields
          ...processColumnsGroup(scoringFieldsColumnDefs),

          // pre calculated fields
          ...processColumnsGroup(preCalculatedFieldsColumnDefs),
        ],
        columnsState,
      ),
    [
      processColumnsGroup,
      groupColumnDef,
      columnsState,
      generalFieldsColumnDefs,
      customFieldsColumnDefs,
      scoringFieldsColumnDefs,
      preCalculatedFieldsColumnDefs,
    ],
  );

  const defaultColDef = useMemo(
    () => ({
      ...defaultColumnDefCommonProps,
      editable: applyCheckIsEditable(checkIsEditable),
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: false,
      initialHide: true,
    }),
    [],
  );

  return {
    columnDefs,
    defaultColDef,
    groupColumnDef,
  };
};

export default useCustomerRequestsColumnsDefs;
