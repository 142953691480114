import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTo } from 'ramda';

import { getPageMode } from 'store/app/selectors';
import { setPageMode } from 'store/app';
import { PORTFOLIO_MODE } from 'constants/common';

const defaultToPortfolioMode = defaultTo(PORTFOLIO_MODE);

const PUBLIC_VIEW_URL = '/share/';

const isMatchedUrl = (pathname, routePath) => pathname.includes(routePath);
/**
 * @function usePageMode
 *
 * hook to control the current page mode based on current route
 *
 * @param  {Array} routes
 * @return {Object}
 */
const usePageMode = routes => {
  const dispatch = useDispatch();
  const pageMode = useSelector(getPageMode);

  const currentRoute = useMemo(
    () => routes.find(r => isMatchedUrl(window.location.pathname, r?.path)),
    [routes, window.location.pathname],
  );

  // if shared view no route will match. For goal mode views this would reset the store state and mess up the filters
  const isSharedViewUrl = window.location.pathname.includes(PUBLIC_VIEW_URL);

  useEffect(() => {
    if (!currentRoute || isSharedViewUrl) {
      return;
    }

    // if its diffrent than the current mode will change on the store
    if (currentRoute?.mode !== pageMode) {
      dispatch(setPageMode(defaultToPortfolioMode(currentRoute?.mode)));
    }
  }, [currentRoute?.mode, isSharedViewUrl]);

  return {
    pageMode,
  };
};

export default usePageMode;
