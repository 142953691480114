import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import theme from 'design-system/theme';

import { getCurrentUser } from 'store/login/selectors';
import { createRoadmapHistorySnapshot } from '../../../store';
import { getUserName } from 'utils/index';
import formatDateTime from 'utils/dates/formatDateTime';

// same as color picker
const DEFAULT_COLOR = theme.palette.newLayout.background.primary;

const useCreateRoadmapSnapshotDialog = ({ viewId }) => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const [snapshotName, setSnapshotName] = useState(`${getUserName(user)} - ${formatDateTime(new Date())}`);
  const [snapshotDescription, setSnapshotDescription] = useState();
  const [snapshotColor, setSnapshotColor] = useState(DEFAULT_COLOR);

  const onSaveSnapshot = () => {
    dispatch(
      createRoadmapHistorySnapshot({
        userViewId: viewId,
        name: snapshotName,
        description: snapshotDescription,
        color: snapshotColor,
      }),
    );
  };

  return {
    snapshotDescription,
    snapshotName,
    snapshotColor,
    setSnapshotDescription,
    setSnapshotColor,
    setSnapshotName,
    onSaveSnapshot,
  };
};

export default useCreateRoadmapSnapshotDialog;
