// External dependencies
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import moment from 'moment-timezone';
import { defaultTo } from 'ramda';

import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER, layers as ALL_LAYERS } from 'store/projects/constants';
import { GLOBAL_FILTER, OBJECTIVES_FILTER } from 'constants/filters';

import convertStateFromOldFiltersToNewFilters from 'utils/filters/convertStateFromOldFiltersToNewFilters';
import { getFilterByRoadmap, hasRoadmapQuickFilter } from 'utils/filterByRoadmap';
import getDisplayLayerAndPortfolioModeFromFilters from 'utils/filters/getDisplayLayerAndPortfolioModeFromFilters';

import { selectFilteredProjectsForPage } from 'store/projects/selectors';

import { getPageFilters } from './selectors/getPageFilters';
import { getProjectLayers } from './selectors/getProjectLayers';
import { getActiveViewForPage } from 'store/userViews/selectors';
import { getPageIdFromPath } from 'utils/pages';

const defaultEmptyString = defaultTo('');

export function getState(state) {
  return state._filters;
}

export { getPageFilters };
export { getProjectLayers };

/**
 * @function getSearchOnPageFilters
 *
 * util function to get the search on current page filters
 *
 * @param  {Object} state
 * @param  {String} page
 * @return {String}
 */
export const getSearchOnPageFilters = createCachedSelector([getState, getPageFilters], (_, filters) => {
  const { fields } = filters;

  return defaultEmptyString(fields?.search);
})((_, page) => `getSearchOnPageFilters_${String(page)}`);

const EMPTY_ARRAY = [];

export const getUserFilters = createCachedSelector([getState, (_, page) => page], (state, page) => {
  if (!state.userFilters) return EMPTY_ARRAY;

  const filterByPage = f => (page ? f.page === page : true);

  const userFilters = state.userFilters.filter(filterByPage);
  const staticFilters = userFilters.filter(f => f.is_static);
  const otherFilters = userFilters
    .filter(f => !f.is_static && !f.default_filter)
    .map(f => {
      if (!f.state.fields) {
        return {
          ...f,
          state: convertStateFromOldFiltersToNewFilters(f.state),
        };
      }

      return f;
    });

  if (otherFilters.length > 0 || staticFilters.length > 0) {
    return [
      ...otherFilters.sort((a, b) => moment(b.updated_at) - moment(a.updated_at)),
      ...staticFilters.sort((a, b) => a.sort_order - b.sort_order),
    ];
  }

  return EMPTY_ARRAY;
})((_, page) => `getUserFilters_${String(page)}`);

export const getPageActiveFilter = createSelector([getState, getPageFilters], (state, pageFilters) => {
  const activeFilterId = pageFilters && pageFilters.activeFilter;

  let filters = state?.userFilters?.find(f => f.id === activeFilterId) || {};

  if (!filters?.fields) {
    filters = convertStateFromOldFiltersToNewFilters(filters);
  }

  return filters;
});

/*
 * get the layer present on global filters
 */
export const getDisplayLayer = createSelector(
  [getState, getPageFilters, state => state.organization.organization.has_bet],
  (state, activeFilter, hasBet) => {
    const { displayLayer } = getDisplayLayerAndPortfolioModeFromFilters(activeFilter, hasBet);

    return displayLayer;
  },
);

// Returns the available display layers based on filters
// eg. if filtering one layer with no children if returns only the selected layer
export const getAvailableDisplayLayers = createSelector(
  state => getPageFilters(state),
  state => state.organization.organization.has_bet,
  (activeFilter, hasBet) => {
    return [IDEA_LAYER, INITIATIVE_LAYER, ...(hasBet ? [BET_LAYER] : [])];
  },
);

/*
 * gets the portfolioMode based on global filters children selection and
 * also on (filters.secondLayer)
 *
 *   - Display all children - has portfolioMode
 *   - Display only children in these conditions - has portfolioMode
 *   - include items without parent (filters.secondLayer) - has portfolioMode
 *   - Dont display children - not has portfolioMode
 */
export const usePortfolioMode = createSelector(
  [getState, getPageFilters, state => state.organization.organization.has_bet],
  (state, activeFilter, hasBet) => {
    const { portfolioMode } = getDisplayLayerAndPortfolioModeFromFilters(activeFilter, hasBet);

    return portfolioMode;
  },
);

const _hasFiltersOnMultipleLayers = Array.isArray;
const _getLayersAsArray = layers => (_hasFiltersOnMultipleLayers(layers) ? layers : [layers]);
const _getHighestLayer = pageFilters => _getLayersAsArray(pageFilters.layer).reduce((a, b) => Math.max(a, b));
const _getLayerAndItsChildren = layer => ALL_LAYERS.filter(l => +l <= +layer);

/**
 * Returns valid data layers based on filter and if portfolio mode is on
 * If portfolio mode is on all layers are valid
 * If portfolio mode is not on only the highest filtered layer and the ones below are valid
 * This is related with the fact that we first filter by the top layer defined in the filter
 * and then user can add filters that apply to the ones below - never to the ones above
 * @param  {} pageFilters
 * @param  {} isPortfolioModeActive
 */
const _getValidLayersBasedOnFilters = (pageFilters, isPortfolioModeActive) => {
  const highestLayerOnFilter = _getHighestLayer(pageFilters);

  return isPortfolioModeActive ? ALL_LAYERS : _getLayerAndItsChildren(highestLayerOnFilter);
};
const _exists = (layers, layer) => layers.find(l => +l === +layer);

const _isIdInArray = (p, ids) => (ids || []).some(id => String(id) === String(p.id));
const _filterProjectsByIdAndRoadmap = (projects, pageFilters, ids, dependenciesIds) => {
  const filterByRoadmap = getFilterByRoadmap(pageFilters);

  return projects.filter(p => (_isIdInArray(p, ids) && filterByRoadmap(p)) || _isIdInArray(p, dependenciesIds));
};

const _filterProjectsById = (projects, ids, dependenciesIds) => {
  return projects.filter(p => _isIdInArray(p, ids) || _isIdInArray(p, dependenciesIds));
};

const obj = {};

/**
 * Creates a selector that returns the projects returned by the filters
 * If portfolio mode is active it will show the all projects - some of them don't
 * respect the filters but are parents of some that do
 * If portfolio mode is not active it will show the projects that respect the filters
 * @param  {function} projectsSelectorFactory - Factory that creates the filtered projects selector
 * @param  {Array} forceValidLayers - List of the valid layers that should be considered
 * @returns {function}
 */
export const makeSelectFilteredProjectsByRoadmapForPage = (
  projectsSelector = selectFilteredProjectsForPage,
  forceValidLayers = null,
  hasPortfolioOption = false,
) => {
  const _getIdsIfLayerExists = (layer, layerMeta, validLayers) =>
    _exists(validLayers, layer) ? layerMeta?.notFilters?.ids || [] : [];

  const _getValidLayersProjectsIds = (lastCallsMeta, validLayers) => {
    return Object.entries(lastCallsMeta).reduce(
      (projectIds, [layer, layerMeta]) => [...projectIds, ..._getIdsIfLayerExists(layer, layerMeta, validLayers)],
      [],
    );
  };

  const _getValidLayersDependenciesIds = (dependencies, validLayers) => {
    const ids = [];

    Object.entries(dependencies).forEach(([id, dep]) => {
      if (validLayers.includes(String(dep.layer))) {
        ids.push(id);
      }
    });
    return ids;
  };

  return createSelector(
    projectsSelector,
    state => getPageFilters(state),
    state => usePortfolioMode(state) && hasPortfolioOption,
    state => state.projects?.lastCallsMeta || obj,
    state => state.projects?.loadedAsDependency || obj,
    (projects, pageFilters, isPortfolioModeActive, lastCallsMeta, dependencies) => {
      const hasRoadmapFilter = hasRoadmapQuickFilter(pageFilters);

      const validLayers = forceValidLayers ?? _getValidLayersBasedOnFilters(pageFilters, isPortfolioModeActive);
      const validLayersProjectsIds = _getValidLayersProjectsIds(lastCallsMeta, validLayers);
      const validLayersDependenciesIds = _getValidLayersDependenciesIds(dependencies, validLayers);

      if (hasRoadmapFilter && !isPortfolioModeActive) {
        return _filterProjectsByIdAndRoadmap(projects, pageFilters, validLayersProjectsIds, validLayersDependenciesIds);
      }

      return _filterProjectsById(projects, validLayersProjectsIds, validLayersDependenciesIds);
    },
  );
};

/**
 * A selector that returns total number of projects respecting the result of makeSelectFilteredProjectsByRoadmapForPage
 * If portfolio mode is active it only count projects that respect the filters
 */
export const getProjectsTotal = createSelector(
  (state, hasPortfolioOption, onlyProjects, __, getAsFilters, ___) => {
    const _getFilteredIdeas = makeSelectFilteredProjectsByRoadmapForPage(selectFilteredProjectsForPage, null, hasPortfolioOption);

    return _getFilteredIdeas(state, GLOBAL_FILTER, onlyProjects, IDEA_LAYER, getAsFilters, __);
  },
  (state, hasPortfolioOption, onlyProjects, __, getAsFilters, ___) => {
    const _getFilteredInitiatives = makeSelectFilteredProjectsByRoadmapForPage(
      selectFilteredProjectsForPage,
      null,
      hasPortfolioOption,
    );

    return _getFilteredInitiatives(state, GLOBAL_FILTER, onlyProjects, INITIATIVE_LAYER, getAsFilters, __);
  },
  (state, hasPortfolioOption, onlyProjects, __, getAsFilters, ___) => {
    const _getFilteredBets = makeSelectFilteredProjectsByRoadmapForPage(selectFilteredProjectsForPage, null, hasPortfolioOption);

    return _getFilteredBets(state, GLOBAL_FILTER, onlyProjects, BET_LAYER, getAsFilters, __);
  },
  state => getPageFilters(state),
  (state, hasPortfolioOption) => usePortfolioMode(state) && hasPortfolioOption,
  state => getDisplayLayer(state),
  (state, hasPortfolioOption, onlyProjects, __, getAsFilters, ___, isflatSearchActive = false) => isflatSearchActive,
  (ideas, initiatives, bets, pageFilters, isPortfolioModeActive, displayLayer, isflatSearchActive) => {
    const _getLayerProjects = layer =>
      ({
        [IDEA_LAYER]: ideas,
        [INITIATIVE_LAYER]: initiatives,
        [BET_LAYER]: bets,
      }[layer] || []);
    const _getLayerTotalProjects = layer => {
      const projects = _getLayerProjects(layer);

      return projects.length;
    };

    const validLayers = _getValidLayersBasedOnFilters(pageFilters, isPortfolioModeActive);

    if (!isPortfolioModeActive && !isflatSearchActive) {
      return _exists(validLayers, displayLayer) ? _getLayerTotalProjects(displayLayer) : 0;
    }

    return validLayers.reduce((total, layer) => _getLayerTotalProjects(layer) + total, 0);
  },
);

export const getGlobalFilter = createSelector(getState, state => state[GLOBAL_FILTER]);

export const selectOutcomeModuleFilter = createSelector(
  state => getActiveViewForPage(state, getPageIdFromPath(window.location.pathname)),
  state => getState(state)?.[OBJECTIVES_FILTER],
  (activeView, objectivesFilter) => {
    if (activeView?.isPublic) {
      return activeView.state?.filter;
    }

    return objectivesFilter;
  },
);
