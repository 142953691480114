import React from 'react';

import styled, { css } from 'styled-components';

import Text from 'design-system/atoms/Text/index';

import { spacing } from 'design-system/theme';
import invertedTextColor from 'design-system/utils/invertedTextColor';

import useHandleSortCustomHeader from './useHandleSortCustomHeader';

const clickableHeaderWithBackgroundHOC =
  (color, onClick, { subTitle, subTitleColor } = {}) =>
  ({ displayName, ...params }) => {
    const { renderSortIcon, handleChangeSort, handleOnClick } = useHandleSortCustomHeader(params, onClick);
    const fontColor = color ? invertedTextColor(color, true) : '';
    const hasClick = !!onClick;

    return (
      <HeaderGroup $backgroundColor={color} onClick={handleChangeSort}>
        <NameWrapper onClick={handleOnClick} $hasClick={hasClick} $fontColor={fontColor}>
          {displayName}
        </NameWrapper>
        {subTitle ? (
          <StyledText variant="small" $fontColor={subTitleColor || fontColor} onClick={handleOnClick} $hasClick={hasClick}>
            {subTitle}
          </StyledText>
        ) : null}
        {renderSortIcon(fontColor)}
      </HeaderGroup>
    );
  };

const HeaderGroup = styled.div`
  width: 100%;
  padding: 0 ${spacing(1.25)}px;
  border-left: 3px solid ${({ theme }) => theme.palette.background.white};
  border-right: 3px solid ${({ theme }) => theme.palette.background.white};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  display: flex;
  align-items: center;
  gap: ${spacing()}px;

  &:hover {
    cursor: pointer;
  }
`;

const NameWrapper = styled.div`
  width: fit-content;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ $fontColor }) => $fontColor};
  ${({ $hasClick, $fontColor }) =>
    $hasClick
      ? css`
          &:hover {
            cursor: pointer;
            text-decoration: underline;
            text-decoration-color: ${$fontColor};
          }
        `
      : ''};
`;

const StyledText = styled(Text)`
  color: ${({ $fontColor }) => $fontColor};
  // to be aligned with title since they have different font and line heights
  margin-top: 2px;

  ${({ $hasClick, $fontColor }) =>
    $hasClick
      ? css`
          &:hover {
            cursor: pointer;
            text-decoration: underline;
            text-decoration-color: ${$fontColor};
          }
        `
      : ''};
`;

export default clickableHeaderWithBackgroundHOC;
