import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

const useDebounceSelector = (fn, equalFn, time = 300) => {
  // eslint-disable-next-line no-unused-vars
  const [_, setSate] = useState();
  const refData = useRef();
  const refTimeout = useRef();

  useSelector(state => {
    const nowState = fn(state);

    if (nowState === refData.current) {
      return;
    }
    if (equalFn?.(refData.current, nowState)) {
      return;
    }

    refData.current = nowState;

    clearTimeout(refTimeout.current);

    refTimeout.current = setTimeout(() => {
      setSate(refData.current);
    }, time);
  });

  useEffect(() => {
    return () => clearTimeout(refTimeout.current);
  }, []);

  return refData.current;
};

export default useDebounceSelector;
