import React from 'react';
import styled from 'styled-components';

import TopBar from 'containers/LinkedPortfolioItems/components/TopBar';
import Grid from 'containers/LinkedPortfolioItems/components/Grid';

import useOkrLinkedPortfolioItems from './hooks/useOkrLinkedPortfolioItems';

const LinkedPortfolioItems = ({ entity, openProjectsListLightbox }) => {
  const {
    linkedItems,
    isLoadingLinkedItems,
    isLoadingLinkProjectToOkr,
    isLoadingUnLinkProjectToOkr,
    isLoadingCreateProjectFromOkr,
    createProjectFromOkr,
    linkProjectToOkr,
    unlinkProjectFromOkr,
    addNewLinkedItemRow,
    removeUnsavedLinkedItemsRows,
  } = useOkrLinkedPortfolioItems(entity);

  const isLoadingGrid = isLoadingLinkProjectToOkr || isLoadingUnLinkProjectToOkr || isLoadingCreateProjectFromOkr;

  return (
    <Container>
      <TopBar
        items={linkedItems}
        onClickCreate={createProjectFromOkr}
        onClickLink={() => addNewLinkedItemRow(true)}
        onClickExpand={() => openProjectsListLightbox(entity)}
        isLoading={isLoadingLinkedItems || isLoadingGrid}
      />
      <Grid
        items={linkedItems}
        isLoadingItems={isLoadingLinkedItems}
        isLoadingGrid={isLoadingGrid}
        linkProjectToEntity={linkProjectToOkr}
        unlinkProjectFromEntity={unlinkProjectFromOkr}
        removeUnsavedRows={removeUnsavedLinkedItemsRows}
      />
    </Container>
  );
};

export default LinkedPortfolioItems;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
