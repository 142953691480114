import React from 'react';
import { useSelector } from 'react-redux';
import useSystemFields from 'hooks/useSystemFields';
import { getCurrentUser } from 'store/login/selectors';
import { getAllowedOrgIntegrationsTypes, getOrganization, getOrganizationIntegrations } from 'store/organization/selectors';
import getVisibleIntegrations from 'routes/Settings/Integration/getVisibleIntegrations';
import { getAvailableIntegrations } from 'features/OneStepIntegration/store';

const componentHOC = Component => {
  return props => {
    const [getSystemFieldNames] = useSystemFields();
    const user = useSelector(getCurrentUser);
    const organization = useSelector(getOrganization);
    const activeOrgIntegrations = useSelector(getOrganizationIntegrations);
    const allowedOrgIntegrationsTypes = useSelector(getAllowedOrgIntegrationsTypes);
    const oneStepAvailableIntegrations = useSelector(getAvailableIntegrations);

    const visibleIntegrations = getVisibleIntegrations(
      activeOrgIntegrations,
      allowedOrgIntegrationsTypes,
      organization,
      user,
      oneStepAvailableIntegrations,
    );

    return (
      <Component
        {...props}
        getSystemFieldNames={getSystemFieldNames}
        user={user}
        organization={organization}
        visibleIntegrations={visibleIntegrations}
      />
    );
  };
};

export default componentHOC;
