import React from 'react';
import styled from 'styled-components';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';

import LightBox from 'components/LightBox';
import CloseButton from 'components/DialogTitle/CloseButton';
import { IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import MultiSelect from 'design-system/atoms/MultiSelect/index';


export default ({
  onClose,
  getSystemFieldName,
  selectedLayer,
  onChangeLayer,
  fieldsOptions,
  selectedFields,
  onClickNext,
  onChangeSelectedFields,
}) => {
  return (
    <LightBox
      isOpen
      onClose={onClose}
      maxWidth="lg"
      dataCy="copy-paste-field-selection-dialog"
      content={
        <Wrapper>
          <CloseButton onClick={onClose} />
          <DialogContent>
            <DialogTitle>Start building your {getSystemFieldName('roadmap')}</DialogTitle>
            <RadioGroup
              aria-label="Type"
              name="layer"
              value={selectedLayer}
              onChange={({ target }) => onChangeLayer(target.value)}
            >
              <RadioControlLabel value={IDEA_LAYER} control={<Radio />} label={`Import ${getSystemFieldName('idea')}`} />
              <RadioControlLabel
                value={INITIATIVE_LAYER}
                control={<Radio />}
                label={`Import ${getSystemFieldName('initiative')}`}
              />
            </RadioGroup>
            <InfoContainer>
              <p>
                Import the following fields.&nbsp; (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://dragonboat.zendesk.com/hc/en-us/articles/360013269194-Dragonboat-Overview-Data-Structure"
                >
                  Data structure here
                </a>
                ).
              </p>
            </InfoContainer>
            <MultiSelectContainer>
              <MultiSelect
                options={fieldsOptions}
                optionsMapper={{ label: 'title', value: 'key' }}
                onChange={onChangeSelectedFields}
                value={selectedFields}
                draggable
                hideCreateOption
              />
            </MultiSelectContainer>
            <InfoContainer>You may paste up to 100 rows at a time.</InfoContainer>
            <ActionsContainer>
              <Button onClick={onClose}>Cancel</Button>
              <Button color="primary" disabled={selectedFields.length === 0} onClick={onClickNext}>
                Next
              </Button>
            </ActionsContainer>
          </DialogContent>
        </Wrapper>
      }
    />
  );
};

const Wrapper = styled.div`
  width: 600px;
  min-height: 200px;
`;

const DialogContent = styled.div``;

const DialogTitle = styled.h4`
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 1rem;
`;

const RadioControlLabel = styled(FormControlLabel)`
  &&&& {
  }
`;

const InfoContainer = styled(DialogContentText)`
  &&&& {
    margin: 20px 0px 20px 0px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row-reverse;
`;

const MultiSelectContainer = styled.div``;
