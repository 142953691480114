import theme from 'design-system/theme';

const DEFAULT_BACKGROUND_COLOR = theme.palette.background.white;

const COLORS_BY_LEVEL = {
  0: theme.palette.background.gallery,
  1: theme.palette.background.lightGallery,
};

/**
 * Defines the background color of the rows in the grid.
 * Used to apply different shades of gray according to the level
 * of the group row.
 * Copied and adapted from src/utils/projectsGrid.js
 * This function is passed directly to AgGrid. For more information
 * please check the official docs: https://www.ag-grid.com/react-data-grid/row-styles/#row-style
 */
const getRowStyle = params => {
  if (params.node?.group || params.data?.group) {
    const levelColor = COLORS_BY_LEVEL[params.node.level];

    return {
      backgroundColor: levelColor || DEFAULT_BACKGROUND_COLOR,
    };
  }

  return {
    backgroundColor: DEFAULT_BACKGROUND_COLOR,
  };
};

export default getRowStyle;
