import React, { useState } from 'react';
import styled from 'styled-components';

const DiscoveryBotOptions = ({ payload, actions }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const { options } = payload;

  const selectOption = ({ value, metadata }) => {
    setSelectedOption(value);

    const { createNextMessage, handleOnClick = null } = metadata;

    if (handleOnClick) {
      handleOnClick();
    }

    const nextMessage = createNextMessage();

    actions.addMessageToState(nextMessage);
  };

  return (
    <Options>
      {options.map((option) => {
        return (
          <Option key={option.value}>
            <OptionElement
              onClick={() => selectOption(option)}
              style={{
                backgroundColor: selectedOption === option.value ? '#1291bd' : '#fff',
                color: selectedOption === option.value ? '#fff' : '#191919',
              }}
            >
              {option.label}
            </OptionElement>
          </Option>
        );
      })}
    </Options>
  );
};

const Options = styled.ul`
  margin: 2px 0 12px 0;
  padding: 0 6px;
`;

const Option = styled.li`
  animation: Lmuha 0.3s ease forwards;
  cursor: pointer;
  display: inline-block;
  margin: 2px;
`;

const OptionElement = styled.button`
  background: #fff;
  border: 0;
  border-radius: 22px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: #191919;
  display: inline-block;
  font-size: 14px;
  padding: 8px 10px;
  margin: 2px;
  opacity: 0.7;
  cursor: pointer;
  ${({ active }) =>
    active &&
    `
    opacity: 0.8;
    outline: none;
    background-color: #1291bd;
  `}
`;

export default DiscoveryBotOptions;
