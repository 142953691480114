import React from 'react';
import styled from 'styled-components';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import MultiSelect from 'design-system/atoms/MultiSelect/index';

import { ucFirst } from 'utils';
import { sumByOptions, durationOptions } from 'store/allocationReport/consts';
import useSystemFields from 'hooks/useSystemFields';
import { SUMMARY_TABLE_COLUMNS_PREFERENCES_OPTIONS_PLANNED } from 'constants/allocation';

export default ({
  lsState,
  updateLsState,
  groupOptions,
  showDuration,
  showSumBy,
  columnsOptions = SUMMARY_TABLE_COLUMNS_PREFERENCES_OPTIONS_PLANNED,
}) => {
  const { selectedSumBy, selectedGroupBy, selectedDuration, selectedDisplayOptions } = lsState;
  const [getSystemFieldName] = useSystemFields();

  const onChangeGroupBy = React.useCallback(o => updateLsState({ selectedGroupBy: o }), [updateLsState]);
  const onChangeSumBy = React.useCallback(
    o => updateLsState({ selectedSumBy: o, selectedDuration: durationOptions[0] }),
    [updateLsState],
  );
  const onChangeDuration = React.useCallback(o => updateLsState({ selectedDuration: o }), [updateLsState]);
  const onChangeDisplayOptions = React.useCallback(
    options => updateLsState({ selectedDisplayOptions: options }),
    [updateLsState],
  );

  const selectedColumns = React.useMemo(
    () => selectedDisplayOptions && selectedDisplayOptions.map(oKey => columnsOptions.find(o => o.key === oKey)),
    [selectedDisplayOptions],
  );

  return (
    <React.Fragment>
      <PreferenceGroup>
        <FormLabel control="legend">Group By</FormLabel>
        <Preference row>
          {groupOptions.map(groupBy => (
            <RadioLabel
              control={
                <Radio checked={selectedGroupBy.key === groupBy.key} color="primary" onChange={() => onChangeGroupBy(groupBy)} />
              }
              label={ucFirst(groupBy.title)}
              key={groupBy.key}
            />
          ))}
        </Preference>
      </PreferenceGroup>
      {showSumBy && (
        <PreferenceGroup>
          <FormLabel control="legend">Sum By</FormLabel>
          <Preference row>
            {sumByOptions(getSystemFieldName('ideas', true)).map(sumBy => (
              <RadioLabel
                control={
                  <Radio checked={selectedSumBy.key === sumBy.key} color="primary" onChange={() => onChangeSumBy(sumBy)} />
                }
                label={ucFirst(sumBy.title)}
                key={sumBy.key}
              />
            ))}
          </Preference>
        </PreferenceGroup>
      )}
      {showDuration && (
        <PreferenceGroup>
          <FormLabel control="legend">Effort</FormLabel>
          <Preference row>
            {durationOptions.map(duration => (
              <RadioLabel
                control={
                  <Radio
                    checked={selectedDuration.key === duration.key}
                    color="primary"
                    onChange={() => onChangeDuration(duration)}
                  />
                }
                label={ucFirst(duration.title)}
                key={duration.key}
                disabled={selectedSumBy.key !== 'duration'}
              />
            ))}
          </Preference>
        </PreferenceGroup>
      )}
      <PreferenceGroup>
        <FormLabel control="legend">Display</FormLabel>
        <StyledMultiSelect
          options={columnsOptions}
          optionsMapper={{ label: 'name', value: 'key' }}
          placeholder="Select options to display"
          onChange={onChangeDisplayOptions}
          value={selectedColumns}
          hideCreateOption
          draggable
        />
      </PreferenceGroup>
    </React.Fragment>
  );
};

const PreferenceGroup = styled.div`
  margin: 12px 0;
`;

const Preference = styled(FormGroup)`
  &&&& {
    margin: 0 6px;
  }
`;

const RadioLabel = styled(FormControlLabel)`
  &&&& {
    flex: 1 1 auto;
  }
`;

const StyledMultiSelect = styled(MultiSelect)`
  margin-top: 8px;
`;
