import React, { Component } from 'react';
import { arrayOf, string, bool, oneOfType, object, func } from 'prop-types';
import Select from '@material-ui/core/Select';
import RootRef from '@material-ui/core/RootRef';

import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';


export default class AgGridDropdown extends Component {
  static propTypes = {
    options: oneOfType([arrayOf(string), arrayOf(object), func]),
    showEmptyOption: bool,
    sort: bool,
  };

  static defaultProps = {
    options: [],
    showEmptyOption: false,
    sort: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.onChange = this.onChange.bind(this);
    this.getValue = this.getValue.bind(this);

    this.domRef = React.createRef();
  }

  componentDidMount() {
    // setTimeout ensures that the focus is done after render material ui select component
    setTimeout(() => {
      if (this.domRef.current && this.domRef.current.childNodes.length) {
        this.domRef.current.childNodes[0].childNodes[0].focus();
        this.domRef.current.childNodes[0].childNodes[0].click();
      }
    }, 0);
  }

  onChange(event) {
    this.setState({
      value:
        event.target.value instanceof Object && 'value' in event.target.value ? event.target.value.value : event.target.value,
    });
  }

  getValue() {
    return this.state.value;
  }

  isPopup() {
    return true;
  }

  sortOptions(opt) {
    const options = opt.sort((a, b) => {
      const labelA = a.label ? a.label : a;
      const labelB = b.label ? b.label : b;

      return String(labelA).trim() > String(labelB).trim() ? 1 : -1;
    });

    return options;
  }

  render() {
    const { options, showEmptyOption, sort = true } = this.props;
    let { value } = this.state;
    const isNotStringOrNull = val => typeof val !== 'string' && val !== null && val !== undefined;

    if (isNotStringOrNull(value)) {
      value = value.toString();
    }

    let myOptions = typeof options === 'function' ? options() : options;

    if (sort) {
      myOptions = this.sortOptions(options);
    }

    return (
      <RootRef rootRef={this.domRef}>
        <Select
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          style={{
            padding: '15px',
            minWidth: '180px',
            fontSize: 14,
          }}
          inputRef={node => {
            this.input = node;
          }}
          value={value}
          onChange={this.onChange}
        >
          {showEmptyOption && <StyledMenuItemDropdown value="" />}
          {myOptions.map(item => {
            const label = item.label ? item.label : item;
            const value = item.value ? item.value : item;

            return (
              <StyledMenuItemDropdown key={value} value={value}>
                {label}
              </StyledMenuItemDropdown>
            );
          })}
        </Select>
      </RootRef>
    );
  }
}
