import { getThunkActionTypes } from 'utils/store/thunk';

const actionTypes = {
  ...getThunkActionTypes('FETCH_SELF_GUIDED_DEMO_DATA'),

  SET_DEMO_SPLASH_PAGE_SHOWN: 'SET_DEMO_SPLASH_PAGE_SHOWN',
  TOGGLE_DEMO_SPLASH_PAGE_VISIBILIY: 'TOGGLE_DEMO_SPLASH_PAGE_VISIBILIY',
};

export const {
  FETCH_SELF_GUIDED_DEMO_DATA,
  FETCH_SELF_GUIDED_DEMO_DATA_PENDING,
  FETCH_SELF_GUIDED_DEMO_DATA_FULFILLED,
  FETCH_SELF_GUIDED_DEMO_DATA_REJECTED,
  SET_DEMO_SPLASH_PAGE_SHOWN,
  TOGGLE_DEMO_SPLASH_PAGE_VISIBILIY,
} = actionTypes;
