import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const SlackIcon = props => {
  const { size = 20, marginRight } = props;

  return (
    <SvgIcon style={{ width: size, height: size, marginRight }} viewBox="0 0 1200 1200">
      <path
        fill="#36C5F0"
        opacity="1.000000"
        stroke="none"
        d="M245.000000,569.000000
          C205.505219,569.000000 166.508667,569.226196 127.516258,568.922058
          C100.575821,568.711975 76.820686,559.249390 55.382702,543.042847
          C39.777702,531.245789 28.245337,516.333740 19.661640,499.070404
          C12.973683,485.619781 9.466943,471.257324 7.805153,456.205963
          C5.767384,437.749207 8.149646,420.036499 14.053072,402.725372
          C21.770668,380.094391 35.420013,361.606293 53.928314,346.416992
          C68.248550,334.664673 84.384422,326.811584 102.190887,322.549133
          C110.709587,320.509949 119.623665,319.165161 128.362106,319.146851
          C235.847260,318.921448 343.333679,318.809174 450.818115,319.165863
          C471.555054,319.234711 491.005249,325.484253 508.960907,336.307739
          C519.128723,342.436768 528.581238,349.371429 536.498474,358.129791
          C552.830994,376.197449 564.500610,396.845581 567.854126,421.272675
          C569.417725,432.662079 570.723145,444.412323 569.619934,455.753326
          C568.458435,467.693176 565.216370,479.669830 561.201355,491.036224
          C555.329407,507.659637 545.136292,521.792664 532.430481,534.147705
          C515.944092,550.178894 496.567200,560.907288 474.218170,565.838318
          C465.336304,567.797974 456.069397,568.833313 446.972565,568.866699
          C379.815796,569.113098 312.657684,569.000000 245.000000,569.000000
        z"
      />
      <path
        fill="#ECB22E"
        opacity="1.000000"
        stroke="none"
        d="M910.000000,632.000000
          C966.315430,632.000061 1022.132263,631.773865 1077.945557,632.120300
          C1098.017822,632.244934 1116.822998,638.438965 1133.954834,648.690979
          C1142.702393,653.925659 1151.189453,660.128540 1158.356201,667.325806
          C1165.890991,674.892822 1172.129272,683.883606 1178.179077,692.766418
          C1187.638672,706.656311 1192.177246,722.640381 1194.839478,738.962585
          C1197.643066,756.151611 1195.811646,773.282715 1191.445068,790.156311
          C1186.948486,807.531799 1178.758179,823.188599 1167.427856,836.776917
          C1158.421387,847.578430 1147.830933,857.048889 1135.430420,864.340637
          C1123.286133,871.481628 1110.373779,876.433411 1096.785034,879.589844
          C1090.451904,881.060913 1083.818848,881.893860 1077.320312,881.902100
          C968.855225,882.039368 860.389832,882.074707 751.924805,881.915710
          C736.500610,881.893005 721.663940,877.997009 707.564392,871.799988
          C691.677673,864.817444 677.562195,855.090088 665.843079,842.254456
          C650.090149,825.000671 639.126282,804.971313 635.246399,781.871887
          C633.212708,769.763916 632.496338,757.162537 633.281738,744.918396
          C634.399658,727.490417 639.864990,711.043579 648.574158,695.640625
          C654.607666,684.969788 661.892517,675.488708 670.554260,666.913391
          C686.930603,650.700500 706.496704,640.190063 728.824524,635.166992
          C737.688293,633.172913 746.957764,632.185059 756.050110,632.135254
          C807.198792,631.854919 858.349792,632.000000 910.000000,632.000000
        z"
      />
      <path
        fill="#2EB67D"
        opacity="1.000000"
        stroke="none"
        d="M633.000061,453.822784
          C633.000000,343.709412 632.906433,234.076248 633.083618,124.443535
          C633.116699,103.978264 639.420898,84.951157 650.102905,67.642181
          C655.494263,58.905926 661.660828,50.396137 668.764954,43.025436
          C680.597839,30.748476 694.805847,21.454733 710.740784,15.127974
          C727.120178,8.624789 744.225952,5.288707 761.811340,6.108541
          C789.395081,7.394493 814.418579,16.080196 836.155579,33.593494
          C849.712952,44.516525 860.836548,57.469189 868.818970,72.868805
          C875.861938,86.456024 880.928894,100.870522 881.921509,116.298317
          C882.666626,127.878845 882.947205,139.507034 882.956177,151.114365
          C883.032776,250.250534 883.189026,349.387390 882.871765,448.522614
          C882.788635,474.516754 873.785400,497.939972 858.536560,518.974060
          C846.784546,535.184631 831.585693,547.401306 813.910767,556.360657
          C801.781006,562.509216 788.811401,566.487976 775.129272,568.078247
          C753.836731,570.552856 733.161621,568.766785 713.005920,561.106812
          C692.528809,553.324646 675.627991,540.896484 661.471191,524.188782
          C649.424866,509.971863 641.485291,493.820435 636.674927,476.072693
          C634.758850,469.003510 634.184998,461.570526 633.000061,453.822784
        z"
      />
      <path
        fill="#E01E5A"
        opacity="1.000000"
        stroke="none"
        d="M518.338989,1170.288208
          C506.879883,1179.072144 494.334839,1184.959961 480.923248,1188.869263
          C467.439758,1192.799316 453.556671,1194.802979 439.514313,1193.920776
          C412.951263,1192.251953 388.683624,1184.153076 367.670319,1167.234619
          C352.222076,1154.796875 339.834290,1139.941162 331.539520,1121.929077
          C326.063171,1110.037109 321.967072,1097.612549 321.029175,1084.409912
          C320.502197,1076.990845 320.047089,1069.546753 320.042572,1062.113770
          C319.979462,958.481995 319.780548,854.849426 320.155975,751.218994
          C320.248566,725.663879 329.422211,702.661560 344.464539,682.004883
          C356.204468,665.883240 371.289856,653.669128 388.933624,644.779114
          C401.185822,638.605713 414.175812,634.079895 428.043213,633.044983
          C435.955170,632.454407 443.907166,631.969604 451.832336,632.078308
          C466.715179,632.282410 480.826172,636.085449 494.553558,641.787720
          C511.058716,648.643616 525.166077,658.832703 537.231995,671.816162
          C553.140991,688.934937 563.494507,709.106750 567.844971,732.056580
          C569.427551,740.405212 569.902466,749.071411 569.913147,757.593994
          C570.045959,863.558105 570.130005,969.522827 569.886475,1075.486572
          C569.839722,1095.838745 563.724609,1114.958496 553.011414,1132.197144
          C547.253052,1141.463257 540.170837,1149.999023 532.972290,1158.240967
          C528.949707,1162.846680 523.466980,1166.177002 518.338989,1170.288208
        z"
      />
      <path
        fill="#36C5F0"
        opacity="1.000000"
        stroke="none"
        d="M463.514832,7.185849
          C477.311188,9.911250 490.643524,12.981180 502.410736,20.007261
          C511.715820,25.563213 520.616943,31.948639 529.066467,38.749649
          C542.750061,49.763618 552.769836,63.935772 559.290588,80.084450
          C563.608582,90.777985 567.836487,102.182274 568.590515,113.502480
          C570.244934,138.337555 569.784302,163.320511 569.964966,188.244705
          C570.129272,210.905609 569.999756,233.568665 569.999756,256.999298
          C568.312988,256.999298 566.736389,256.999298 565.159790,256.999298
          C525.329895,256.999329 485.494049,257.420746 445.671997,256.860901
          C415.900818,256.442413 388.858704,247.558945 365.520142,228.201324
          C352.453400,217.363373 341.847900,204.654556 334.082520,189.774094
          C327.015350,176.231552 322.630127,161.744583 320.856964,146.356613
          C318.483521,125.759117 320.773407,105.923767 328.012421,86.602142
          C333.543427,71.839333 341.689362,58.701160 352.372681,47.039989
          C363.233093,35.185558 375.705383,25.409502 390.124176,18.536491
          C401.040619,13.332971 412.539703,9.193851 424.780212,8.021813
          C433.116577,7.223599 441.466583,6.439504 449.828888,6.092190
          C454.230957,5.909359 458.672455,6.677314 463.514832,7.185849
        z"
      />
      <path
        fill="#2EB67D"
        opacity="1.000000"
        stroke="none"
        d="M944.004517,533.000000
          C944.004517,507.170898 944.022095,481.841797 943.998413,456.512726
          C943.982483,439.507050 944.951111,422.594604 950.106140,406.257416
          C954.681335,391.757721 961.595764,378.368256 971.100952,366.464294
          C983.418396,351.038330 998.507629,339.124146 1016.487488,330.747070
          C1028.954102,324.938690 1041.906982,321.599426 1055.526245,319.881348
          C1075.753906,317.329651 1095.262939,319.890991 1114.143188,327.031769
          C1142.955078,337.928772 1164.618286,357.190033 1179.632324,384.105896
          C1186.993286,397.302124 1191.680542,411.420349 1193.774414,426.213013
          C1196.992798,448.951172 1194.037720,471.059601 1185.306763,492.452148
          C1179.315308,507.132141 1170.728882,520.116028 1159.880859,531.378113
          C1143.123291,548.775330 1122.749268,560.573486 1099.087402,565.829285
          C1090.006958,567.846313 1080.518555,568.788757 1071.205200,568.863037
          C1031.047852,569.183350 990.886414,569.000000 950.726440,569.000000
          C948.739868,569.000000 946.753235,569.000000 944.004517,569.000000
          C944.004517,557.004517 944.004517,545.252258 944.004517,533.000000
        z"
      />
      <path
        fill="#E01E5A"
        opacity="1.000000"
        stroke="none"
        d="M258.055542,757.068970
          C258.768585,778.036438 253.751740,797.739990 243.884048,816.053528
          C229.511154,842.728394 208.020279,861.600464 179.894745,873.152771
          C162.015640,880.496521 143.455521,883.217285 124.448013,881.858887
          C98.943909,880.036194 75.778252,871.481323 55.679703,855.229004
          C40.951748,843.319519 28.809965,829.320129 20.685080,812.144653
          C14.295543,798.637573 10.473786,784.461670 8.820920,769.459839
          C6.069135,744.483704 10.579631,721.011658 22.105806,699.053589
          C38.569607,667.689209 63.927795,646.794861 98.188873,636.835876
          C109.989594,633.405640 121.876762,631.896790 134.131516,631.944031
          C175.117996,632.101685 216.105392,632.019897 257.552612,632.554932
          C258.027039,674.409668 258.041290,715.739319 258.055542,757.068970
        z"
      />
      <path
        fill="#ECB22E"
        opacity="1.000000"
        stroke="none"
        d="M636.957520,1101.108887
          C635.616394,1094.192261 633.221924,1087.703857 633.174683,1081.198486
          C632.844666,1035.721069 633.000061,990.240356 633.000061,944.000000
          C634.714783,944.000000 636.293152,943.999939 637.871521,943.999939
          C681.690308,944.000000 725.512146,943.717041 769.325989,944.181580
          C781.654419,944.312317 793.627258,947.935364 805.169617,952.577881
          C822.926575,959.720093 838.353760,970.330933 850.931396,984.613464
          C866.797241,1002.629944 877.931274,1023.252258 880.909790,1047.450439
          C882.194702,1057.889282 883.565552,1068.566162 882.743042,1078.964478
          C880.950745,1101.622803 873.700928,1122.730469 860.153442,1141.255615
          C849.293152,1156.106201 836.013000,1168.310547 819.982666,1177.638428
          C806.156799,1185.683472 791.280884,1190.730591 775.692444,1192.780396
          C745.603943,1196.736694 717.297302,1191.126099 691.226807,1174.846680
          C669.277588,1161.140747 653.396912,1142.430176 643.060059,1118.909790
          C640.598572,1113.308960 638.990845,1107.332764 636.957520,1101.108887
        z"
      />
      <path
        fill="#FF0080"
        opacity="1.000000"
        stroke="none"
        d="M258.423706,756.835938
          C258.041290,715.739319 258.027039,674.409668 258.018555,632.621948
          C258.349426,633.140869 258.957733,634.117615 258.958801,635.094971
          C259.002747,675.105713 258.992767,715.116516 258.980133,755.127319
          C258.979980,755.619141 258.857300,756.111023 258.423706,756.835938
        z"
      />
    </SvgIcon>
  );
};

export default SlackIcon;
