import { createSelector } from 'reselect';

import { isLoading, isSuccess, isError, isUninitialized } from 'utils/store/thunk';
import { FETCH_PROJECT_HISTORY_NEXT_PAGE } from './types';

export const selectProjectsHistory = createSelector(
  state => state?.history?.project?.history,
  history => history || [],
);

export const selectProjectsHistoryNextPageLink = createSelector(
  state => state?.history?.project?._meta,
  meta => meta?.next,
);

const selectHistoryOperations = state => state?.history?.operations;

export const isFetchProjectNextPageLoading = createSelector(selectHistoryOperations, state =>
  isLoading(state, FETCH_PROJECT_HISTORY_NEXT_PAGE),
);
export const isFetchProjectNextPageSuccess = createSelector(selectHistoryOperations, state =>
  isSuccess(state, FETCH_PROJECT_HISTORY_NEXT_PAGE),
);
export const isFetchProjectNextPageError = createSelector(selectHistoryOperations, state =>
  isError(state, FETCH_PROJECT_HISTORY_NEXT_PAGE),
);
export const isFetchProjectNextPageUninitialized = createSelector(selectHistoryOperations, state =>
  isUninitialized(state, FETCH_PROJECT_HISTORY_NEXT_PAGE),
);
