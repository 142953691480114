import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import DependenciesTable from './DependenciesTable';

export default ({ isOpen, onClose, projects, applyTableState, tableState }) => {
  if (!isOpen) return '';

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth>
      <DialogTitle>Dependency details</DialogTitle>
      <DialogContent style={{ minHeight: 450 }}>
        <DependenciesTable projects={projects} tableState={tableState} applyTableState={applyTableState} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
