import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';

// In some components the 'theme' is being injected/overwritten with other values
import theme from '../../theme';

export default styled(MenuItem)`
  &&&&& {
    padding: 5px 15px;
    font-size: ${theme.typography.fontSize}px;
    color: ${theme.palette.text.lightGrey};
    background-color: ${({ selected }) => (selected ? theme.palette.newLayout.background.lighterBlack : '')};
    font-weight: ${({ selected }) => (selected ? 'bold' : 'inherit')};

    ${({ hidden }) => hidden && 'display: none;'}
    &:hover {
      background-color: ${theme.palette.newLayout.background.lighterBlack};
    }
    ${({ hovered }) => hovered && `background-color: ${theme.palette.newLayout.background.lighterBlack};`}

    ${({ customStyles }) => customStyles}
  }
`;
