import { createSelector } from 'reselect';

import { isUninitialized, isLoading, getError } from 'utils/store/thunk';
import { FETCH_TEMPLATES, FETCH_TEMPLATES_CATEGORIES } from './types';

import orderDescByPopularityThenId from './utils/orderDescByPopularityThenId';

export function getState(state) {
  return state.templates;
}

const getOperations = state => {
  return state.templates.operations;
};

const isFetchTemplatesUninitialized = createSelector(getOperations, state => isUninitialized(state, FETCH_TEMPLATES));
const isFetchTemplatesLoading = createSelector(getOperations, state => isLoading(state, FETCH_TEMPLATES));
const hasFetchTemplatesError = createSelector(getOperations, state => getError(state, FETCH_TEMPLATES));

const isFetchCategoriesUninitialized = createSelector(getOperations, state => isUninitialized(state, FETCH_TEMPLATES_CATEGORIES));
const isFetchCategoriesLoading = createSelector(getOperations, state => isLoading(state, FETCH_TEMPLATES_CATEGORIES));
const hasFetchCategoriesError = createSelector(getOperations, state => getError(state, FETCH_TEMPLATES_CATEGORIES));

const getTemplates = state => getState(state)?.templates;

const getTemplatesOrderedByMostPopular = createSelector(getTemplates, templates => templates.sort(orderDescByPopularityThenId));

const getTemplatesCategories = state => getState(state)?.templatesCategories;

const getActiveTemplate = state => getState(state)?.activeTemplate;

const getActiveTemplateCategory = state => getState(state)?.activeTemplateCategory;

export {
  isFetchTemplatesUninitialized,
  isFetchTemplatesLoading,
  hasFetchTemplatesError,
  isFetchCategoriesUninitialized,
  isFetchCategoriesLoading,
  hasFetchCategoriesError,
  getTemplates,
  getTemplatesCategories,
  getActiveTemplate,
  getActiveTemplateCategory,
  getTemplatesOrderedByMostPopular,
};
