import { useRef, useState } from 'react';
import { getOrganization, getOrgHasBet } from 'store/organization';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProjectCustomerRequests as fetchProjectCustomerRequestsAction,
  unwatchProject as unwatchProjectAction,
  watchProject as watchProjectAction,
} from 'store/projects';
import { getSelectedProjectOnLigthbox } from 'store/projectLightbox/selectors';
import useLoadEstimatesTab from 'containers/ProjectLightBox/hooks/useLoadEstimatesTab';
import useSystemFields from 'hooks/useSystemFields';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';
import { openProjectLightbox as openProjectLightboxAction } from 'store/projectLightbox';
import { INITIATIVE_LAYER } from 'store/projects/constants';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { getCurrentUser } from 'store/login/selectors';
import { unvoteOnProject as unvoteOnProjectAction, voteOnProject as voteOnProjectAction } from 'store/votes';
import isOldLightboxActive from 'containers/ProjectLightBox/utils/isOldLightboxActive';

const useProjectNavigationItems = ({ isNew, userCanEdit, uploadFile, type }) => {
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const [showChangeTypeDialog, setShowChangeTypeDialog] = useState(false);
  const [showCloneDialog, setShowCloneDialog] = useState(false);
  const [showConfigureFieldsDialog, setShowConfigureFieldsDialog] = useState(false);

  const permissions = usePermissions();
  const [getSystemFieldName] = useSystemFields();
  const { loadEstimatesTab } = useLoadEstimatesTab();
  const oldLightboxActive = isOldLightboxActive();

  const organization = useSelector(getOrganization);
  const hasBet = useSelector(getOrgHasBet);
  const selectedProject = useSelector(getSelectedProjectOnLigthbox);
  const currentUser = useSelector(getCurrentUser);

  const fetchProjectCustomerRequests = () => dispatch(fetchProjectCustomerRequestsAction(selectedProject.id));

  const hasProgressReportEnabled = permissions.canView(PERMISSION_FEATURES.projectProgressReport, {
    project: { layer: selectedProject?.layer },
  });
  const shouldShowProgressTab = hasProgressReportEnabled && !isNew;
  const userCanCreate = permissions.canCreate(PERMISSION_RESOURCES.project, { project: selectedProject });
  const orgHasHierarchy = organization.has_hierarchy;
  const orgHasRequests = organization.has_customer_requests;
  const isMilestone = type === 'milestone';

  const userCanChangeType = userCanEdit && orgHasHierarchy && !isNew;
  const canViewChildren = selectedProject?.layer && selectedProject?.layer >= INITIATIVE_LAYER && !isNew && orgHasHierarchy;
  const userIsWatching = selectedProject?.watchers?.some(w => w.id === currentUser.id);
  const hadVote = Boolean(selectedProject?.votes?.had_vote);
  const userCanClone = !isNew && userCanCreate;
  const showDetailsTab = !isNew;
  const showRequestsTab = !isNew && !isMilestone && orgHasRequests;
  const showEstimatesTab = !isNew && !isMilestone;
  const showDependenciesTab = !isNew;
  const showShareTab = !isNew;
  const showCommentsTab = !isNew;
  const showHistoryTab = !isNew;
  const showChangeLayoutTab = !isNew;

  const handleFileInputClick = event => {
    hiddenFileInput.current.click();
  };

  const handleFileInputChange = event => {
    const { files } = event.target;

    for (let i = 0; i < files.length; i++) {
      uploadFile(files[i]);
    }

    event.target.value = null;
  };

  const openProjectLightbox = () => dispatch(openProjectLightboxAction(selectedProject.id));

  const openChangeTypeDialog = () => setShowChangeTypeDialog(true);
  const closeChangeTypeDialog = () => setShowChangeTypeDialog(false);
  const openCloneDialog = () => setShowCloneDialog(true);
  const closeCloneDialog = () => setShowCloneDialog(false);
  const watchProject = () => dispatch(watchProjectAction(selectedProject));
  const unwatchProject = () => dispatch(unwatchProjectAction(selectedProject));
  const voteOnProject = () => dispatch(voteOnProjectAction(selectedProject?.id));
  const unvoteOnProject = () => dispatch(unvoteOnProjectAction(selectedProject?.id));
  const openConfigureFieldsDialog = () => setShowConfigureFieldsDialog(true);
  const closeConfigureFieldsDialog = () => setShowConfigureFieldsDialog(false);

  return {
    hiddenFileInput,
    userCanAttachFiles: userCanEdit,
    userCanChangeType,
    shouldShowProgressTab,
    showChangeTypeDialog,
    selectedProject,
    hasBet,
    canViewChildren,
    showCloneDialog,
    userCanClone,
    userIsWatching,
    hadVote,
    showConfigureFieldsDialog,
    oldLightboxActive,
    showDetailsTab,
    showRequestsTab,
    showEstimatesTab,
    showDependenciesTab,
    showShareTab,
    showCommentsTab,
    showHistoryTab,
    showChangeLayoutTab,

    // callbacks
    getSystemFieldName,
    handleFileInputClick,
    handleFileInputChange,
    fetchProjectCustomerRequests,
    loadEstimatesTab,
    openChangeTypeDialog,
    closeChangeTypeDialog,
    reload: openProjectLightbox,
    openCloneDialog,
    closeCloneDialog,
    watchProject,
    unwatchProject,
    voteOnProject,
    unvoteOnProject,
    openConfigureFieldsDialog,
    closeConfigureFieldsDialog,
  };
};

export default useProjectNavigationItems;
