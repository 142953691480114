import React from 'react';
import styled from 'styled-components';

import Button from 'design-system/atoms/Button/index';

import theme, { spacing } from 'design-system/theme';
import Stars from 'design-system/atoms/DragonIcons/Stars';

const AIAnswerBotButton = ({ onClick }) => {
  return (
    <StyledButton
      background={theme.palette.text.brand}
      color={theme.palette.white}
      hover={{ background: theme.palette.newLayout.background.duskBlue }}
      fontSize={theme.typography.fontSizeRem}
      width="175px"
      onClick={onClick}
    >
      <Stars />
      AI Answer Bot
    </StyledButton>
  );
};

export default AIAnswerBotButton;

const StyledButton = styled(Button)`
  &&&& {
    height: 37px;

    svg {
      width: 22px;
      height: 22px;
      margin-right: ${spacing(1.5)}px;

      path {
        fill: ${({ theme }) => theme.palette.white};
      }
    }
  }
`;
