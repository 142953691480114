import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useSystemFields from 'hooks/useSystemFields';
import {
  selectMetricsCanvasEdgesLayout,
  selectMetricsCanvasLayoutConfig,
  selectMetricsCanvasNodesLayout,
  selectMetricsCanvasUserActionsMeta,
} from 'features/MetricsCanvas/store/selectors';
import useMetricsCanvasHandleConfigChanges from 'features/MetricsCanvas/hooks/useMetricsCanvasHandleConfigChanges';
import { updateEdgesLayout, updateNodesLayout } from 'features/MetricsCanvas/store';

import useMetricsCanvasData from 'features/MetricsCanvas/hooks/useMetricsCanvasData';

const useMetricsCanvas = () => {
  const dispatch = useDispatch();
  const [getSystemFieldName] = useSystemFields();
  const canvasLayoutConfig = useSelector(selectMetricsCanvasLayoutConfig);
  const canvasNodesLayout = useSelector(selectMetricsCanvasNodesLayout);
  const canvasEdgesLayout = useSelector(selectMetricsCanvasEdgesLayout);
  const userActionsMeta = useSelector(selectMetricsCanvasUserActionsMeta);

  const nodeTypeLabels = useMemo(
    () => ({
      objective: getSystemFieldName('objective'),
      keyResult1: getSystemFieldName('keyResult1'),
      keyResult2: getSystemFieldName('keyResult2'),
      metric: getSystemFieldName('metric'),
    }),
    [],
  );

  const {
    onToggleOkrRelations,
    onToggleBoardRelations,
    onToggleMetricRelations,
    onToggleOkrMetricsRelations,
    onToggleHighlightHealth,
    onToggleOwnerAvatar,
    onToggleProgress,
    onToggleStatus,
    handleDisplayNodesChange,
  } = useMetricsCanvasHandleConfigChanges({ canvasLayoutConfig });

  const { dataForCanvas } = useMetricsCanvasData();

  const onNodesLayoutUpdate = nodesLayout => dispatch(updateNodesLayout(nodesLayout));
  const onEdgesLayoutUpdate = edgesLayout => dispatch(updateEdgesLayout(edgesLayout));

  return {
    dataForCanvas,
    canvasLayoutConfig,
    canvasNodesLayout,
    canvasEdgesLayout,
    userActionsMeta,
    nodeTypeLabels,
    onNodesLayoutUpdate,
    onEdgesLayoutUpdate,
    onToggleOkrRelations,
    onToggleBoardRelations,
    onToggleMetricRelations,
    onToggleHighlightHealth,
    onToggleOkrMetricsRelations,
    onToggleOwnerAvatar,
    onToggleProgress,
    onToggleStatus,
    handleDisplayNodesChange,
  };
};

export default useMetricsCanvas;
