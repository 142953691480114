export const FETCH_CUSTOM_FIELDS = 'FETCH_CUSTOM_FIELDS';
export const FETCH_CUSTOM_FIELDS_FULFILLED = 'FETCH_CUSTOM_FIELDS_FULFILLED';
export const FETCH_CUSTOM_FIELDS_PENDING = 'FETCH_CUSTOM_FIELDS_PENDING';

export const UPDATE_CUSTOM_FIELD = 'UPDATE_CUSTOM_FIELD';
export const UPDATE_CUSTOM_FIELD_FULFILLED = 'UPDATE_CUSTOM_FIELD_FULFILLED';

export const CREATE_CUSTOM_FIELD = 'CREATE_CUSTOM_FIELD';
export const CREATE_CUSTOM_FIELD_FULFILLED = 'CREATE_CUSTOM_FIELD_FULFILLED';
export const CREATE_CUSTOM_FIELD_REJECTED = 'CREATE_CUSTOM_FIELD_REJECTED';

export const DELETE_CUSTOM_FIELD = 'DELETE_CUSTOM_FIELD';
export const DELETE_CUSTOM_FIELD_FULFILLED = 'DELETE_CUSTOM_FIELD_FULFILLED';

export const UPDATE_CUSTOM_FIELD_BY_ID = 'UPDATE_CUSTOM_FIELD_BY_ID';
export const UPDATE_CUSTOM_FIELD_BY_ID_FULFILLED = 'UPDATE_CUSTOM_FIELD_BY_ID_FULFILLED';

export const ADD_CUSTOM_FIELD_WITHOUT_SAVE = 'ADD_CUSTOM_FIELD_WITHOUT_SAVE';
export const REMOVE_UNSAVED_CUSTOM_FIELDS = 'REMOVE_UNSAVED_CUSTOM_FIELDS';

export const CREATE_CUSTOM_FIELDS = 'CREATE_CUSTOM_FIELDS';
export const CREATE_CUSTOM_FIELDS_FULFILLED = 'CREATE_CUSTOM_FIELDS_FULFILLED';
export const UPDATE_CUSTOM_FIELDS = 'UPDATE_CUSTOM_FIELDS';
export const UPDATE_CUSTOM_FIELDS_FULFILLED = 'UPDATE_CUSTOM_FIELDS_FULFILLED';

export const CREATE_CUSTOM_FIELD_ROADMAP = 'CREATE_CUSTOM_FIELD_ROADMAP';
export const CREATE_CUSTOM_FIELD_ROADMAP_FULFILLED = 'CREATE_CUSTOM_FIELD_ROADMAP_FULFILLED';
export const DELETE_CUSTOM_FIELD_ROADMAP = 'DELETE_CUSTOM_FIELD_ROADMAP';
export const DELETE_CUSTOM_FIELD_ROADMAP_FULFILLED = 'DELETE_CUSTOM_FIELD_ROADMAP_FULFILLED';
export const BULK_DELETE_CUSTOM_FIELD_ROADMAP = 'BULK_DELETE_CUSTOM_FIELD_ROADMAP';
export const BULK_DELETE_CUSTOM_FIELD_ROADMAP_FULFILLED = 'BULK_DELETE_CUSTOM_FIELD_ROADMAP_FULFILLED';

export const CREATE_CUSTOM_FIELD_ASSOCIATION = 'CREATE_CUSTOM_FIELD_ASSOCIATION';
export const CREATE_CUSTOM_FIELD_ASSOCIATION_FULFILLED = 'CREATE_CUSTOM_FIELD_ASSOCIATION_FULFILLED';

export const DELETE_CUSTOM_FIELD_ASSOCIATION = 'DELETE_CUSTOM_FIELD_ASSOCIATION';
export const DELETE_CUSTOM_FIELD_ASSOCIATION_FULFILLED = 'DELETE_CUSTOM_FIELD_ASSOCIATION_FULFILLED';
