import React, { useEffect, useMemo, useCallback } from 'react';
import { PERMISSION_RESOURCES, SYSTEM_FIELD } from '@dragonboat/permissions';

import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SettingsDialogs from 'components/SettingsDialogs';
import { BaseGrid } from 'containers/Grids';
import {
  useSettingsActions,
  useSettingsGridSelection,
  useSettingsGridProps,
  useSettingsGridEventHandlers,
  useSettingsGridColumns,
} from 'containers/Grids/SettingsGrid/hooks';

import { PHASES } from 'store/grids/constants';

import usePhases from './hooks/usePhases';
import usePhasesSettingsGrid from './hooks/usePhasesSettingsGrid';
import usePhasesGridColumns from './hooks/usePhasesGridColumns';
import useForceVisibleColumns from 'hooks/useForceVisibleColumns';
import usePermissions from 'hooks/permissions/usePermissions';
import {
  checkShouldDisableDelete,
  checkDeleteVisibility,
  checkCheckboxSelection,
  checkShouldDisableBulkDelete,
  checkShouldDisableMergeOption,
  checkRowDrag,
  getDataPath,
} from './helpers';

const PHASE_KEY = 'phase';

const TITLE_FIELD = 'title';

const PhasesSettings = ({
  showActionBar = true,
  systemFields,
  updateSystemFieldsName,
  hideMetadataRoadmaps,
  forceVisibleFields,
  customHeight,
  hideArchivedItems,
}) => {
  const {
    phases,
    phasesWithHierarchy,
    defaultPhase,
    planningStages,

    createPhase,
    createPhases,
    updatePhaseById,
    updatePhases,
    fetchPhases,
    mergePhases,
    addPhaseWithoutSave,
    removeUnsavedPhases,
    deletePhaseById,
    bulkDeletePhases,
    switchPhasesRowOrder,
    bulkUpdateProjects,
  } = usePhases(hideArchivedItems);

  const { addNewPhaseToPlanningStage, handleRowDrag } = usePhasesSettingsGrid({
    addPhaseWithoutSave,
    updatePhaseById,
    switchPhasesRowOrder,
  });

  const { selectionMode, selectedItems, enableSelectionMode, disableSelectionMode, updateSelectedOnAgGridInit } =
    useSettingsGridSelection(PHASES, { actions: { remove: removeUnsavedPhases } });

  const { columnDefs: phasesGridColumnDefs, groupColumnDef } = usePhasesGridColumns(phases, {
    hideMetadataRoadmaps,
    planningStages,
    addNewPhaseToPlanningStage,
  });

  const {
    itemToDelete,
    setItemToDelete,
    deleteItem,
    showBulkDeleteDialog,
    setShowBulkDeleteDialog,
    deleteProgress,
    bulkDeleteItems,
    mergeLightboxVisible,
    showMergeLightbox,
    hideMergeLightbox,
  } = useSettingsActions({
    settingType: PHASES,
    selectedItems,
    disableSelectionMode,
    deleteById: deletePhaseById,
    bulkDelete: bulkDeletePhases,
  });

  const settingsGridProps = useSettingsGridProps({
    bulkCreate: createPhases,
    bulkUpdate: updatePhases,
    treeData: true,
  });

  const { canUpdate, canCreate, canMerge, canView } = usePermissions();
  const canUpdateOrCreate = canUpdate(PERMISSION_RESOURCES.phase) || canCreate(PERMISSION_RESOURCES.phase);

  const permissions = {
    allowActions: canUpdateOrCreate,
    canAdd: false,
    rename: {
      canUpdate: showActionBar && canUpdate(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.phase }),
      canView: showActionBar && canView(PERMISSION_RESOURCES.systemFieldName, { field: SYSTEM_FIELD.phase }),
    },
    canMerge: showActionBar && canMerge(PERMISSION_RESOURCES.phase),
    canBulkDelete: showActionBar && canUpdateOrCreate,
    canDragRows: showActionBar && canUpdateOrCreate,

    canUpdate: useCallback(data => canUpdate(PERMISSION_RESOURCES.phase, { data }), [canUpdate]),
    deleteDisabled: checkShouldDisableDelete,
    deleteVisibility: checkDeleteVisibility,
    checkboxSelection: checkCheckboxSelection,
    showDisabledCheckboxes: false,
    rowDrag: checkRowDrag,
  };

  const settingsGridColumns = useSettingsGridColumns({
    allowActions: permissions.allowActions,
    canUpdate: permissions.canUpdate,
    deleteDisabled: permissions.deleteDisabled,
    deleteVisibility: permissions.deleteVisibility,
    showDisabledCheckboxes: permissions.showDisabledCheckboxes,
    canDragRows: permissions.canDragRows,
    rowDrag: permissions.rowDrag,
    checkboxSelection: permissions.checkboxSelection,
    selectionMode,
    setItemToDelete,
    items: phasesWithHierarchy,
  });

  const settingsGridEventHandlers = useSettingsGridEventHandlers({
    settingType: PHASES,
    items: phasesWithHierarchy,
    focusField: TITLE_FIELD,
    actions: {
      update: updatePhaseById,
      save: createPhase,
      remove: removeUnsavedPhases,
      drag: handleRowDrag,
    },
  });

  const visibleColumns = useForceVisibleColumns(phasesGridColumnDefs, forceVisibleFields);

  const columnDefs = useMemo(
    () => [...settingsGridColumns.beforeColumnDefs, groupColumnDef, ...visibleColumns, ...settingsGridColumns.afterColumnDefs],
    [settingsGridColumns, visibleColumns],
  );

  useEffect(() => {
    fetchPhases();
  }, []);

  return (
    <>
      {permissions.allowActions && (
        <SettingsActionsToolbar
          settingKey={PHASE_KEY}
          settingType={PHASES}
          systemFields={systemFields}
          updateSystemFieldsName={updateSystemFieldsName}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          onEnableSelection={enableSelectionMode}
          onCancelSelection={disableSelectionMode}
          setShowBulkDeleteDialog={setShowBulkDeleteDialog}
          showMergeLightbox={showMergeLightbox}
          renamePermissions={permissions.rename}
          canMerge={permissions.canMerge}
          canBulkDelete={permissions.canBulkDelete}
          hideAddButton={!permissions.canAdd}
          checkShouldDisableBulkDelete={checkShouldDisableBulkDelete}
        />
      )}
      <BaseGrid
        onGridReady={updateSelectedOnAgGridInit}
        rowData={phasesWithHierarchy}
        getDataPath={getDataPath}
        columnDefs={columnDefs}
        defaultColDef={settingsGridColumns.defaultColDef}
        {...settingsGridProps}
        {...settingsGridEventHandlers}
        groupDisplayType="custom"
        isRowSelectable={checkCheckboxSelection}
        height={customHeight ?? settingsGridProps.height}
      />
      <SettingsDialogs
        settingKey={PHASE_KEY}
        settingType={PHASES}
        showBulkDeleteDialog={showBulkDeleteDialog}
        setShowBulkDeleteDialog={setShowBulkDeleteDialog}
        deleteProgress={deleteProgress}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        deleteItem={deleteItem}
        bulkDelete={bulkDeleteItems}
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
        mergeLightboxVisible={mergeLightboxVisible}
        hideMergeLightbox={hideMergeLightbox}
        merge={mergePhases}
        bulkUpdateProjects={bulkUpdateProjects}
        defaultPhase={defaultPhase}
        items={phases}
        shouldDisableMergeOption={checkShouldDisableMergeOption}
      />
    </>
  );
};

export default PhasesSettings;
