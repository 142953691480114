import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { head, length, path } from 'ramda';

import { getUserFilters } from 'store/filters/selectors';
import { fetchProgressChartData } from 'store/progress';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import usePageFilters from 'hooks/filters/usePageFilters';
import { isLoadingProgressReportData, getProgressReportData } from 'store/progress/selectors';
import { getOrgJiraIntegrations } from 'store/organization/selectors';
import formatDate from 'utils/dates/formatDate';
import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import TitleType from '../titleType';
import { PROGRESS_PAGE } from 'constants/filters';

const MANUAL_INTEGRATION_TYPE = 'MANUAL';

const getParentProjectLayer = path(['parentProject', 'layer']);
const getScopeTitleType = metadata => {
  const layer = getParentProjectLayer(metadata);

  if (layer === INITIATIVE_LAYER) {
    return TitleType.INITIATIVE;
  }
  if (layer === BET_LAYER) {
    return TitleType.BET;
  }
  return TitleType.PORTFOLIO;
};

// TODO - this needs to be revisited
const useIntegrationType = () => {
  const jiraOrgIntegrations = useSelector(state => getOrgJiraIntegrations(state));
  const integrationType = length(jiraOrgIntegrations) ? head(jiraOrgIntegrations).integrationType : MANUAL_INTEGRATION_TYPE;

  return integrationType;
};

/**
 * Load chart data for
 * @param {Object} query
 * @param {String} query.integrationType
 * @param {String} query.startDate
 * @param {String} query.endDate
 * @param {Number|undefined} query.parentProjectId - optionally scope data to child/grandchild projects
 * @returns
 */
export default function useProgressBurnChartData({ startDate, endDate, parentProjectId }) {
  const dispatch = useDispatch();
  const pageId = PROGRESS_PAGE;
  const { pageFilters, displayLayer } = usePageFilters(pageId);

  const userFilters = useSelector(state => getUserFilters(state));
  const hasBet = useSelector(state => state.organization.organization.has_bet);
  const integrationType = useIntegrationType();

  const isLoadingProgressChartData = useSelector(isLoadingProgressReportData);
  const loadedProgressReportData = useSelector(getProgressReportData);

  const loadChartData = useCallback(
    (start, end, parentProjectId) => {
      const filtersForApiByLevels = compileFiltersBody(pageFilters, userFilters, hasBet, pageId, displayLayer, null);

      dispatch(
        fetchProgressChartData({
          type: integrationType,
          parentProjectId,
          start,
          end,
          filters: filtersForApiByLevels,
        }),
      );
    },
    [dispatch, pageFilters, userFilters, hasBet, pageId, displayLayer, integrationType],
  );

  useEffect(() => {
    loadChartData(startDate, endDate, parentProjectId);
  }, [loadChartData, startDate, endDate, parentProjectId]);

  const { data: loadedReportData, maxDeadlines } = loadedProgressReportData;

  const data = loadedReportData.map(d => ({
    ...d,
    issuesInProgressAndCompleted: d.issuesInProgress + d.issuesClosed,
  }));

  const maxEndDate = moment(maxDeadlines?.maxEndDate);
  const maxPredictedEndDate = moment(maxDeadlines?.maxPredictedEndDate);

  const formattedEndDate = formatDate(maxEndDate);
  const formattedPredictedDate = formatDate(maxPredictedEndDate);
  const titleType = getScopeTitleType(loadedProgressReportData.metadata);

  return {
    data,
    titleType,
    maxEndDate,
    maxPredictedEndDate,
    formattedEndDate,
    formattedPredictedDate,
    isLoading: isLoadingProgressChartData,
  };
}
