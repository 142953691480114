import React from 'react';
import styled from 'styled-components';
import theme from 'design-system/theme';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import Text from 'design-system/atoms/Text/index';

import createdByText from 'utils/userViews/createdByText';
import getFavoriteIcon from 'utils/userViews/getFavoriteIcon';

const ViewName = ({ selectedView, viewOwner, isFavoriteView, handleFavoriteUserView, maxWidth }) => {
  if (!selectedView) {
    return;
  }

  const notFavoriteColor = theme.palette.text.quickSilver;
  const tooltipText = `${selectedView?.name} ${createdByText(viewOwner?.name)}`;

  return (
    <>
      <Tooltip title={tooltipText} placement="bottom">
        <Container data-testid="view-name">
          <Text variant="h4" maxWidth={maxWidth} withEllipsis>
            {selectedView?.name}
          </Text>
          <SubTitle>{createdByText(viewOwner?.first_name)}</SubTitle>
        </Container>
      </Tooltip>
      {handleFavoriteUserView && selectedView?.id && (
        <FavoriteButton
          onClick={e => {
            e.stopPropagation();
            handleFavoriteUserView(selectedView);
          }}
        >
          {selectedView?.name && getFavoriteIcon(isFavoriteView(selectedView), notFavoriteColor)}
        </FavoriteButton>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
`;

const SubTitle = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  color: ${({ theme }) => theme.palette.newLayout.text.grey};
  padding: 0;
  margin: 0 8px;
  align-self: center;
  white-space: nowrap;
`;

export const FavoriteButton = styled(IconButton)`
  &&&&& {
    font-size: ${({ theme }) => theme.typography.fontSizeMediumRem}rem;
  }
`;

export default ViewName;
