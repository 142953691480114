import React from 'react';
import styled from 'styled-components';
import { path } from 'ramda';

import IconButton from '@material-ui/core/IconButton';
import OpenInNew from '@material-ui/icons/OpenInNew';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';

import theme from 'design-system/theme';

const MetricNameRenderer = params => {
  const metricId = path(['data', 'metric_id'], params);
  const hasAdvancedMetricReporting = path(['colDef', 'cellRendererParams', 'hasAdvancedMetricReporting'], params);
  const setSelectedMetricId = path(['colDef', 'cellRendererParams', 'setSelectedMetricId'], params);

  return (
    <MetricTitle color={theme.palette.gridColors.mineShaft} size="small">
      <span>{params.value && `${params.value}`}</span>
      {hasAdvancedMetricReporting && metricId && (
        <LinkIconButton color="primary" onClick={() => setSelectedMetricId(metricId)}>
          <OpenInNew style={{ fontSize: theme.typography.fontSize }} />
        </LinkIconButton>
      )}
    </MetricTitle>
  );
};

const LinkIconButton = styled(IconButton)`
  &&&& {
    position: relative;
    padding: 3px;
    font-size: 1.125rem;
  }
`;

const MetricTitle = styled(TextDeprecated)`
  &&&& {
    display: flex;
    justify-content: space-between;
    width: 100%;

    > span {
      width: calc(100% - 23px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ${LinkIconButton} {
      visibility: hidden;
    }

    &:hover ${LinkIconButton} {
      visibility: visible;
    }
  }
`;

export default MetricNameRenderer;
