import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useUpdateSystemFields from 'hooks/useUpdateSystemFields';
import formatDate from 'utils/dates/formatDate';

import { selectHeaderColor, selectDisplayPreferences } from 'store/summary/selectors';

const TIMEFRAME_KEY = 'timeframe';

const checkIfHeaderIsEditable = (isReadOnly, id, dataKey, supportedFields) =>
  !isReadOnly && id != null && supportedFields.includes(dataKey);

const isTimeframe = selectedRowData => selectedRowData?.key === TIMEFRAME_KEY;

export default ({ rowId, rowDate, isReadOnly, selectedRowData, slice }) => {
  const { supportedFields } = useUpdateSystemFields();

  const headerColor = useSelector(state => selectHeaderColor(state, slice));
  const { verticalRowHeader } = useSelector(state => selectDisplayPreferences(state, slice));

  const isEditable = useMemo(
    () => checkIfHeaderIsEditable(isReadOnly, rowId, selectedRowData.key, supportedFields),
    [isReadOnly, rowId, selectedRowData.key, supportedFields],
  );

  const date = useMemo(() => (isTimeframe(selectedRowData) && rowDate ? formatDate(rowDate) : null), [rowDate]);

  return { isEditable, verticalRowHeader, headerColor, date };
};
