import { getUsers } from 'store/users/selectors';
import { getTimeframes, getTimeframesLevel2 } from 'store/timeframes/selectors';
import { omit } from 'ramda';
import { getPriorities } from 'store/priorities/selectors';

const DATA_TYPES = {
  DATE: 'Date',
  STRING: 'String',
};

const SUPPORTED_FIELDS = [
  { field: 'timeframe_id', key: 'timeframeTitle', title: 'timeframe', type: DATA_TYPES.STRING },
  { field: 'timeframe_2_id', key: 'timeframe2Title', title: 'timeframe2', type: DATA_TYPES.STRING },
  { field: 'owner_id', key: 'ownerName', title: 'Owner', type: DATA_TYPES.STRING },
  { field: 'estimated_start_date', key: 'estimated_start_date', title: 'Target Start Date', type: DATA_TYPES.DATE },
  { field: 'deadline', key: 'deadline', title: 'Target End Date', type: DATA_TYPES.DATE },
  { field: 'committed', key: 'committed', title: 'Above the Line' },
  { field: 'start_date_estimates', key: 'start_date_estimates', title: 'Estimated Start Date', type: DATA_TYPES.DATE },
  { field: 'end_date_estimates', key: 'end_date_estimates', title: 'Estimated End Date', type: DATA_TYPES.DATE },
  { field: 'priority_id', key: 'priorityTitle', title: 'priority', type: DATA_TYPES.STRING },
];

const handleTimeframeEnrichment = (state, projectData) => {
  const timeframe = getTimeframes(state).find(t => t.title === projectData.timeframe?.title);

  let data = projectData;

  if (!timeframe) {
    data = omit(['timeframe'])(projectData);
  }

  return { ...data, timeframe_id: timeframe?.id || null };
};

const handleTimeframe2Enrichment = (state, projectData) => {
  const timeframe2 = getTimeframesLevel2(state).find(t => t.title === projectData.timeframe2?.title);

  let data = projectData;

  if (!timeframe2) {
    data = omit(['timeframe2'])(projectData);
  }

  return { ...data, timeframe_2_id: timeframe2?.id || null };
};

const handleOwnerEnrichment = (state, projectData) => {
  const ownerNameDeleted = projectData.ownerName === null;
  const hasOwnerNameOnUpdate = projectData.ownerName || ownerNameDeleted;
  const user = getUsers(state).find(t => t.name === projectData.ownerName);

  if (!hasOwnerNameOnUpdate) {
    return projectData;
  }

  return {
    ...projectData,
    owner_id: user?.id,
    ownerName: user?.name,
    owner: user,
  };
};

const handlePriorityEnrichment = (state, projectData) => {
  const priority = getPriorities(state).find(t => t.title === projectData.priority?.title);

  let data = projectData;

  if (!priority) {
    data = omit(['priority'])(projectData);
  }

  return { ...data, priority_id: priority?.id || null };
};

const fieldEnrichment = {
  timeframe: handleTimeframeEnrichment,
  timeframe2: handleTimeframe2Enrichment,
  ownerName: handleOwnerEnrichment,
  priority: handlePriorityEnrichment,
};

/**
 * @function populateProjectMetadataWithIdsForRoadmapVersionUpdate
 *
 * Populate project object with metadata ids for roadmap version update
 *
 * @param  {Object} state
 * @param  {Object} dataToSave
 * @return {Object}
 */
const populateProjectMetadataWithIdsForRoadmapVersionUpdate = (state, dataToSave) => {
  let projectWithMetadataIds = { ...dataToSave };

  /*
   * On update project action currently just send the metadata titles but for roadmap
   * version to simulate the update fullfiled from the server is required to have the
   * metadata ids to enrich the projects with metadata objects
   */
  Object.keys(projectWithMetadataIds).forEach(fieldKey => {
    const enrich = fieldEnrichment[fieldKey];

    if (enrich) {
      projectWithMetadataIds = enrich(state, projectWithMetadataIds);
    }
  });

  return projectWithMetadataIds;
};

export { DATA_TYPES, populateProjectMetadataWithIdsForRoadmapVersionUpdate, SUPPORTED_FIELDS };
