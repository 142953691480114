import React from 'react';
import styled, { css } from 'styled-components';

import ToggleButton from 'design-system/molecules/ToggleButton/index';


const ControlsToggle = ({ id, icon, on, onChange, title, disabled = false, ...props }) => {
  return (
    <MetricModeIconContainer id={id} {...props}>
      <StyledToggleButton icon={icon} on={on} onChange={onChange} title={title} disabled={disabled} />
    </MetricModeIconContainer>
  );
};

const MetricModeIconContainer = styled.div`
  &&&& {
    display: flex;
    justify-content: flex-end;
  }
`;

const StyledToggleButton = styled(ToggleButton)`
  &&&&& {
    max-height: unset;
    max-width: unset;

    ${({ on }) =>
      on
        ? css`
            padding: 11px;
          `
        : css`
            border: none;
          `}
  }
`;

export default ControlsToggle;
