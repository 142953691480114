import { useSettingsGridProps } from 'containers/Grids/SettingsGrid/hooks';

import { isOfObjectRoadmapType, getDataPath, getRowId, isNotActive } from '../helpers';

const useRoadmapsGridProps = () => {
  const settingsGridProps = useSettingsGridProps({
    treeData: true,
  });

  return {
    ...settingsGridProps,
    getRowId,
    getDataPath,
    groupDisplayType: 'custom',
    suppressRowClickSelection: true,
    rowClassRules: {
      'metadata-parent-row': params => {
        if (!params.data) {
          return false;
        }

        return isOfObjectRoadmapType(params.data);
      },
      'metadata-child-row': params => {
        if (!params.data) {
          return false;
        }

        return isNotActive(params.data);
      },
    },
  };
};

export default useRoadmapsGridProps;
