import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_PAGES } from 'constants/defaultPages';
import usePermissions from 'hooks/permissions/usePermissions';

const useLastPageForMenuPath = () => {
  const lastPagesView = useSelector(state => state.app.lastPagesView || {});
  const lastGoalsView = useSelector(state => state.app.lastGoalsView);

  const { canView } = usePermissions();

  /**
   * This object is used to handle special cases where we need to save and retrieve a view/page
   * that does not belong to another page. For example, we can save the 'metrics' page as the
   * last viewed page of the 'goals' page. To define such exceptions, add a new entry to this object.
   * (key - parent page, lastView - last view for parent, redirect - child page/view)
   */
  const routingExceptions = {
    goals: {
      lastView: lastGoalsView,
      redirect: 'metrics',
    },
  };

  const checkForExceptions = url => {
    const exception = Object.keys(routingExceptions).find(page => url.includes(page));
    const exceptionPage = exception && routingExceptions[exception];

    if (exceptionPage && exceptionPage.lastView === exceptionPage.redirect) {
      return `/${exceptionPage.redirect}`;
    }

    return url;
  };

  const determineSavedChild = (lastPagesView, parent) => {
    if (parent === 'dashboard') {
      return 'home';
    }

    return lastPagesView[parent] || DEFAULT_PAGES(canView)[parent];
  };

  const _buildChildUrl = (parent, someChild) => {
    return `/${parent}/${someChild}`;
  };

  const getLastPage = useCallback(parent => {
    const savedChild = determineSavedChild(lastPagesView, parent);

    const newUrl = checkForExceptions(_buildChildUrl(parent, savedChild));

    return newUrl;
  }, []);

  return getLastPage;
};

export default useLastPageForMenuPath;
