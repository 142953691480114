import { useSelector } from 'react-redux';

import { getCustomerRequestsCustomFields } from 'store/customFields/selectors';

import useSystemFields from 'hooks/useSystemFields';
import usePermissions from 'hooks/permissions/usePermissions';

import { GROUP_BY_OPTIONS, GROUP_BY_CUSTOM_FIELDS_TYPES_ALLOWED } from '../constants';

const customFieldsGroupByVisible = () => true;

const useGroupByOptions = () => {
  const [getSystemFieldName] = useSystemFields();
  const { canView } = usePermissions();

  const customFields = useSelector(getCustomerRequestsCustomFields);

  const optionsCustomFields = customFields
    .filter(cf => GROUP_BY_CUSTOM_FIELDS_TYPES_ALLOWED.includes(cf.field_type))
    .map(cf => ({
      ...cf,
      title: cf.titleWithSuffix,
      visible: customFieldsGroupByVisible,
      filterCustomKey: 'custom_fields',
    }));

  const groupOptions = [...GROUP_BY_OPTIONS, ...optionsCustomFields]
    .map(o => ({
      ...o,
      title: getSystemFieldName(o.title),
    }))
    .filter(g => g.visible(canView));

  return groupOptions;
};

export default useGroupByOptions;
