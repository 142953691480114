import Button from './Button';
import Chips from './Chips';
import Circle from './Circle';
import Link from './Link';
import Text from './Text';

export default {
  ButtonColumn: Button,
  ChipsColumn: Chips,
  CircleColumn: Circle,
  LinkColumn: Link,
  TextColumn: Text,
};
