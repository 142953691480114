import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import { spacing } from 'design-system/theme';

const StyledButton = styled(Button)`
  span {
    gap: ${spacing()}px;
  }
`;

const GridWrapper = styled.div`
  padding: 0;
  margin-bottom: ${spacing(2)}px;

  .ag-layout-normal.ag-root {
    margin: 0;
  }
`;

export { StyledButton, GridWrapper };
