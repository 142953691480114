import React from 'react';
import { css } from 'styled-components';
import { useSelector } from 'react-redux';

import PageHeaderFilters from 'containers/PageHeaderFilters';
import { getCurrentUser } from 'store/login/selectors';

const MORNING_STARTING_HOUR = 5;
const MORNING_ENDING_HOUR = 12;
const AFTERNOON_STARTING_HOUR = 12;
const AFTERNOON_ENDING_HOUR = 18;

const getWelcomeMessage = user => {
  const currentHour = new Date().getHours();
  let timeOfDay = '';

  if (currentHour >= MORNING_STARTING_HOUR && currentHour < MORNING_ENDING_HOUR) {
    timeOfDay = 'morning';
  } else if (currentHour >= AFTERNOON_STARTING_HOUR && currentHour < AFTERNOON_ENDING_HOUR) {
    timeOfDay = 'afternoon';
  } else {
    timeOfDay = 'evening';
  }

  return `Good ${timeOfDay} ${user.first_name}!`;
};

const welcomeMessageStyles = css`
  font-size: ${({ theme }) => theme.typography.fontSizeMediumRem}rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: ${({ theme }) => theme.palette.brandDarkBlue};
`;

export default function HeaderFilters(props) {
  const currentUser = useSelector(getCurrentUser);
  const welcomeMessage = getWelcomeMessage(currentUser);

  return (
    <PageHeaderFilters
      history={props.history}
      pageIconBackground="transparent"
      showFilterIcon={false}
      showPageIcon
      showQuickFilter={false}
      showResultsCounter={false}
      showDisplayLayer={false}
      customWelcomeMessage={welcomeMessage}
      customWelcomeMessageStyles={welcomeMessageStyles}
      showWelcomeHeader
    />
  );
}
