import React from 'react';
import styled from 'styled-components';

import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete/index';


import { STACKED_CHART } from '../hooks/useChartWidgetWizard';

import ConfigurableChart from '../../Charts/ConfigurableChart';

const PREVIEW_CHART_HEIGHT = 275;

const ChartConfigurations = ({ selectedChart, chartOptions, selectedChartOptions, handleSelectChartOption }) => {
  if (!selectedChart) return null;

  const { groupBy, stackBy } = selectedChartOptions;

  const isStackedChart = selectedChart?.type === STACKED_CHART;

  return (
    <>
      <Flex>
        <GroupByAutocomplete
          name="group-by"
          suggestions={chartOptions.groupBy}
          value={groupBy}
          onChange={val => handleSelectChartOption('groupBy', val)}
        />
        {isStackedChart ? (
          <StyledGroupByAutocomplete
            label="Stack by"
            name="stack-by"
            suggestions={chartOptions.stackBy}
            value={stackBy}
            onChange={val => handleSelectChartOption('stackBy', val)}
          />
        ) : null}
      </Flex>
      <ConfigurableChart
        chartType={selectedChart.chartType}
        groupBy={groupBy?.key}
        stackBy={isStackedChart ? stackBy?.key : null}
        wrapper={ChartPreview}
        horizontal={selectedChart.horizontal}
        minHeight={PREVIEW_CHART_HEIGHT}
      />
    </>
  );
};

export default ChartConfigurations;

const Flex = styled.div`
  display: flex;
  margin-left: 22px;
`;

const StyledGroupByAutocomplete = styled(GroupByAutocomplete)`
  margin-left: ${({ theme }) => theme.spacing.unit * 2}px;
`;

const ChartPreview = styled.div`
  max-width: 100%;
  margin-top: ${({ theme }) => theme.spacing.unit * 3}px;
`;
