import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

import { isNewRow } from '../helpers';

const ActionsCellRenderer = params => {
  if (isNewRow(params)) {
    return null;
  }

  return (
    <Wrapper>
      {params.actions.map(action => {
        const IconComponent = action.icon;
        const disabled = action.disabled ? action.disabled(params.data) : false;
        const iconColor = disabled ? '#00000042' : action.color;
        const { iconStyles = {} } = action;

        return (
          <ActionButton
            key={action.color}
            style={{ display: action.getVisibility(params) ? 'inherit' : 'none' }}
            onClick={() => action.onClick(params.data, params)}
            fontSize="small"
            disabled={disabled}
          >
            <IconComponent style={{ color: iconColor, ...iconStyles }} fontSize="small" />
          </ActionButton>
        );
      })}
    </Wrapper>
  );
};

export default ActionsCellRenderer;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const ActionButton = styled(IconButton)`
  &&&& {
    width: 25px;
    height: 25px;
    padding: 0;
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;
