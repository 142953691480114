import { CHILDREN_FILTERS } from 'src/constants/filters';
import { IDEA_LAYER } from 'store/projects/constants';

import getLayersFromFilters from './getLayersFromFilters';

/**
 * Contains to business logic to get the display layer and portfolio mode from the filters
 * @param  {Object} pageFilters        Object with the filters structure (fields, childrenFields, layer, secondLayer, children)
 * @param  {Boolean} hasBet            Flag to indicate if the organization has a bet
 * @return {Object}                    Object with the display layer and portfolio mode
 */

const isUserFilteringByIdeas = displayLayer => +displayLayer === +IDEA_LAYER;
const isPorfolioModeAcceptable = displayLayer => !isUserFilteringByIdeas(displayLayer);

const isUserFilteringByMultipleLayers = (pageFilters, secondLayer) =>
  [CHILDREN_FILTERS.allChildren, CHILDREN_FILTERS.filteredChildren].includes(pageFilters.children) || secondLayer.length;

export default (pageFilters, hasBet) => {
  const { layer, secondLayer = [] } = getLayersFromFilters(pageFilters, hasBet);

  let displayLayer = layer;
  let portfolioMode = false;

  // If layer is an array return the highest level on that array
  if (Array.isArray(layer)) [displayLayer] = layer.sort().reverse();

  if (isPorfolioModeAcceptable(displayLayer) && isUserFilteringByMultipleLayers(pageFilters, secondLayer)) {
    portfolioMode = true;
  }

  return { displayLayer, portfolioMode };
};
