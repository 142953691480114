import { getAllTasks, getParentTaskOnGantt } from 'utils';
import parseGanttTask from '../parsers/parseGanttTask';

const SHOW_SUBTASKS = true;

export default (teams, collapseGanttBars, sortOrder, options) => p => {
  const tasks = (p.tasks || [])
    .map(task => {
      if (task.owner && task.owner.team_id) teams.push(task.owner.team_id);
      return parseGanttTask(p, task, p.id, { ...options, collapseGanttBars }, teams);
    })
    .sort(sortOrder);

  let subTasks = [];

  if (SHOW_SUBTASKS) {
    subTasks = getAllTasks(p.tasks).map(subtask =>
      parseGanttTask(p, subtask, getParentTaskOnGantt(subtask, tasks).id, { ...options, collapseGanttBars }, teams),
    );
  }

  return [...tasks, ...subTasks];
};
