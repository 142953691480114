import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { getRowId } from 'design-system/molecules/AgGridReact-New/helpers';
import useCommonGridProps from 'design-system/molecules/AgGridReact-New/hooks/useCommonGridProps';

const MULTIPLE_ROW_SELECTION = 'multiple';
const UNDEFINED_TITLE = 'Undefined';

const useSettingsGridProps = ({ rowHeight, bulkCreate, bulkUpdate, treeData } = {}) => {
  const commonGridProps = useCommonGridProps({ isTreeView: treeData, bulkCreate, bulkUpdate });

  return useMemo(
    () => ({
      ...commonGridProps,
      height: 'calc(100vh - 128px)',
      autoGroupColumnDef: {
        cellRendererParams: {
          innerRenderer: ({ value }) => {
            return isEmpty(value) ? UNDEFINED_TITLE : value;
          },
          suppressDoubleClickExpand: true,
        },
      },
      getRowId,
      groupDefaultExpanded: -1,
      headerHeight: 38,
      rowHeight,
      rowSelection: MULTIPLE_ROW_SELECTION,
      suppressPaginationPanel: true,
      suppressRowClickSelection: true,
    }),
    [commonGridProps, rowHeight],
  );
};

export default useSettingsGridProps;
