import { dateColumnDef, checkboxColumnDef } from 'design-system/molecules/AgGridReact-New/columns';

const getCompareRoadmapsColumnDefByField = (ideasGridColumnDefs, selectedFieldKey) => {
  switch (selectedFieldKey) {
    case 'timeframeTitle':
    case 'ownerName':
      return ideasGridColumnDefs.find(colDef => colDef.field === selectedFieldKey);
    case 'estimated_start_date':
    case 'deadline':
      return {
        ...dateColumnDef,
      };
    case 'start_date_estimates':
    case 'end_date_estimates':
      return {
        ...dateColumnDef,
        width: 160,
      };
    case 'committed':
      return {
        ...checkboxColumnDef,
      };
    default:
      return {};
  }
};

export default getCompareRoadmapsColumnDefByField;
