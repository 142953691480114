import AppliesToAutocompleteCellEditor from '../gridColumns/AppliesToAutocompleteCellEditor';
import AppliesToMultiSelectAutocompleteCellEditor from '../gridColumns/AppliesToMultiSelectAutocompleteCellEditor';

const getMetadataAutocompleteEditorColumnDef = ({
  getItems = () => [],
  shouldAcceptSelection = () => true,
  handleUpdate = () => {},
  autocompleteParams = {},
  isMultiSelect = false,
}) => {
  const makeHandleChange =
    params =>
    async (selectedOption, { data, stopEditing }) => {
      if (shouldAcceptSelection(selectedOption)) {
        await handleUpdate(data.id, selectedOption, params);

        stopEditing && stopEditing();
      }
    };

  return {
    cellEditor: isMultiSelect ? AppliesToMultiSelectAutocompleteCellEditor : AppliesToAutocompleteCellEditor,
    cellEditorParams: params => {
      return {
        options: getItems(params),
        onChange: makeHandleChange(params),
        selectedEntity: params?.data,
        focusOnInit: true,
        ...autocompleteParams,
      };
    },
    overrideCellValueChanged: !isMultiSelect,
    cellEditorPopup: true,
  };
};

export default getMetadataAutocompleteEditorColumnDef;
