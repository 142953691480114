import invert from 'lodash/invert';

import getDisplayLayerLabel from 'store/projects/helpers/getDisplayLayerLabel';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { materialColors } from 'design-system/themes/default';

import {
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_PRODUCTS_2,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
  PERSONAS,
  LIFECYCLES,
  RESOURCE_TEAM,
} from 'constants/common';

export const TAB_DETAILS = 'details';
export const TAB_ESTIMATES = 'estimates';
export const TAB_CREATE_TASKS = 'createTasks';
export const TAB_DEPENDENCIES = 'dependencies';
export const TAB_HISTORY = 'history';
export const TAB_COMMENTS = 'comments';
export const TAB_SHARE = 'share';
export const TAB_IDEAS = 'children';
export const TAB_CUSTOMER_REQUESTS = 'customerRequests';
export const TAB_PROGRESS = 'progress';

// export const IDEA_DURATION_DEFAULT = 14;
// export const INITIATIVE_DURATION_DEFAULT = 8 * 7;
// export const BET_DURATION_DEFAULT = 16 * 7;

// const getLayerDuration = layer => {
//   switch (layer) {
//     case IDEA_LAYER:
//       return IDEA_DURATION_DEFAULT;
//     case INITIATIVE_LAYER:
//       return INITIATIVE_DURATION_DEFAULT;
//     case BET_LAYER:
//       return BET_DURATION_DEFAULT;
//     default:
//       return IDEA_DURATION_DEFAULT;
//   }
// };

export const getTypeNameBasedOnLayer = layer => {
  switch (layer) {
    case INITIATIVE_LAYER:
      return 'initiative';
    case BET_LAYER:
      return 'bet';
    case IDEA_LAYER:
    default:
      return 'idea';
  }
};

export const getDefaultFormData = (getSystemFieldName, layer = IDEA_LAYER, isMilestone) => ({
  id: null,
  title: `New ${isMilestone ? 'Milestone' : getSystemFieldName(getTypeNameBasedOnLayer(layer))}`,
  type: 'project',
  customColor: materialColors.gray,
  details: '',
  notes: '',
  roadmapCorpTitle: '',
  roadmapTitle: '',
  [ADDITIONAL_ROADMAPS]: [],
  [ADDITIONAL_PRODUCTS]: [],
  [ADDITIONAL_PRODUCTS_2]: [],
  ownerName: '',
  priorityTitle: '',
  objectiveCorpTitle: '',
  objectiveTitle: '',
  keyResult1Title: '',
  keyResult2Title: '',
  product1Title: '',
  product2Title: '',
  timeframeCorpTitle: '',
  timeframeTitle: '',
  timeframe2Title: '',
  phaseTitle: '',
  status_color: '',
  status_summary: '',
  progress: 0,
  themeTitle: '',
  links: '',
  categoryTitle: '',
  business_value: 0,
  reach_score: 0,
  impact_score: 0,
  confidence_score: 0,
  effort_score: 0,
  totalCost: 0,
  watchers: [],
  layer,
  estimated_start_date: null,
  deadline: null,
  estimated_duration: null,
  duration: null,
  // duration: getLayerDuration(layer),
  // estimated_start_date: formatDate(moment()),
  // deadline: formatDate(moment().addDuration(getLayerDuration(layer), 'days')),
  // estimated_duration: getLayerDuration(layer),
  newTasks: '',
  jira_progress: false,
  jira_auto_sync: false,
  parent_id: null,
  customers: [],
  tags: [],
  metrics: [],
  estimates: [],
  tasks: [],
  files: [],
  user1Name: '',
  user2Name: '',
  user3Name: '',
  user4Name: '',
  user5Name: '',
  user6Name: '',
  user7Name: '',
  customersCount: 0,
  customerRequestsCount: 0,
  totalRevenue: 0,
  activeRevenue: 0,
  inactiveRevenue: 0,
  [ADDITIONAL_OBJECTIVES]: [],
  [ADDITIONAL_KEY_RESULTS]: [],
  [ADDITIONAL_KEY_RESULTS_2]: [],
  [ADDITIONAL_TIMEFRAMES]: [],
  [ADDITIONAL_TIMEFRAMES_2]: [],
  [ADDITIONAL_THEMES]: [],
  [ADDITIONAL_CATEGORIES]: [],
  [ADDITIONAL_TEAMS]: [],
  [ADDITIONAL_TEAMS_2]: [],
  [PERSONAS]: [],
  [LIFECYCLES]: [],
  completed_date: null,
  [RESOURCE_TEAM]: null,
});

export const DEFAULT_PLANNING_STAGE = 'Backlog';
export const ARCHIVED_PLANNING_STAGE = 'Archived';
export const UPDATE_PROJECT_ERROR = 'It was not possible to update project';
export const CLONING_PROJECT_MESSAGE = 'Cloning in progress';

export const getCloneProjectError = (layer, getSystemFieldName) =>
  `Cloning the ${getDisplayLayerLabel(layer, getSystemFieldName)} was not possible`;

export const LEFT_FIELDS = 'leftFields';
export const RIGHT_FIELDS = 'rightFields';
export const HIDDEN_FIELDS = 'hiddenFields';

const ALLOWED_PROJECT_FIELDS_TO_INHERIT = [
  'priority',
  'categories',
  'owner',
  'phases',
  'themes',
  'user1',
  'user2',
  'user3',
  'user4',
  'user5',
  'user6',
  'user7',
  'roadmaps',
  'allObjectives',
  'timeframes',

  // 'customers',
  // 'tags',
  // 'watchers',
  // 'metrics',
];

const HTML_FIELDS = ['links', 'statusSummary', 'details'];

const USERS_FIELDS = ['owner', 'user1', 'user2', 'user3', 'user4', 'user5', 'user6', 'user7'];

const METADATA_FIELDS = ['priority', 'categories', 'themes', 'roadmaps', 'allObjectives', 'timeframes'];

const RELATIONAL_FIELDS = ['customers', 'tags', 'watchers', PERSONAS, LIFECYCLES];

const ADDITIONAL_METADATA_FIELDS = [
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_PRODUCTS_2,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_THEMES,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
];

const ALLOWED_PROJECT_FIELDS_TO_BE_REQUIRED = [
  'title',
  'parent',
  'timeline',
  ...HTML_FIELDS,
  ...USERS_FIELDS,
  ...METADATA_FIELDS,
  ...RELATIONAL_FIELDS,
  ...ADDITIONAL_METADATA_FIELDS,
];

const PROJECT_FIELD_TO_LAYOUT_FIELD_NAME_MAPPER = {
  status_summary: 'statusSummary',
  owner_id: 'owner',
  user_1_id: 'user1',
  user_2_id: 'user2',
  user_3_id: 'user3',
  user_4_id: 'user4',
  user_5_id: 'user5',
  user_6_id: 'user6',
  user_7_id: 'user7',
  priorityTitle: 'priority',
  themeTitle: 'themes',
  phaseTitle: 'phases',
  categoryTitle: 'categories',
  timeframeTitle: 'timeframes',
  roadmapTitle: 'roadmaps',
  objectiveTitle: 'allObjectives',
  parent_id: 'parent',
  estimated_start_date: 'timeline',
  deadline: 'timeline',
};

const LAYOUT_FIELD_NAME_TO_PROJECT_FIELD_MAPPER = invert(PROJECT_FIELD_TO_LAYOUT_FIELD_NAME_MAPPER);

const PROJECTS_LIGHTBOX_Z_INDEX = 1300;

export {
  ADDITIONAL_METADATA_FIELDS,
  ALLOWED_PROJECT_FIELDS_TO_INHERIT,
  ALLOWED_PROJECT_FIELDS_TO_BE_REQUIRED,
  PROJECT_FIELD_TO_LAYOUT_FIELD_NAME_MAPPER,
  LAYOUT_FIELD_NAME_TO_PROJECT_FIELD_MAPPER,
  PROJECTS_LIGHTBOX_Z_INDEX,
};
