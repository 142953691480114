import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { METADATA_LEVELS } from 'constants/common';

import { createMetric } from 'store/metrics';

import useSystemFields from './useSystemFields';
import useMetadataRoadmapQuickFilter from 'hooks/useMetadataRoadmapQuickFilter';

import { getCategories } from 'store/categories/selectors';
import { getPriorities } from 'store/priorities/selectors';
import { getThemes } from 'store/themes/selectors';
import { getObjectives, selectKeyResults1, selectKeyResults2 } from 'store/objectives/selectors';
import { getRoadmaps, getCorpRoadmaps, getProducts, getProductsLevelTwo } from 'store/roadmaps/selectors';
import { getTimeframesLevelCorp } from 'store/timeframes/selectors';
import { getPhases } from 'store/phases/selectors';
import { getTeams } from 'store/teams/selectors';
import { getSkills } from 'store/skills/selectors';
import { getUsers } from 'store/users/selectors';

import shouldAllowAddingNewMetadata from 'utils/shouldAllowAddingNewMetadata';

import { createCustomer } from 'store/customers';
import { createTag } from 'store/tags';
import useGetInitiativesAndBetsFromEntities from 'hooks/useGetInitiativesAndBetsFromEntities';
import { selectPersonasData } from 'features/Personas/store/selectors';
import { selectLifecyclesData } from 'features/Lifecycles/store/selectors';
import { filterActiveMetadata } from 'utils/metadata';
import { groupByTeamHierarchy } from 'shared/helpers/entities/users';

const useMetadata = (data, { currentUser, organization }) => {
  const dispatch = useDispatch();
  const { getSystemFieldName } = useSystemFields();
  const filteredMetadata = useMetadataRoadmapQuickFilter(data, organization);
  const { has_teams_2: hasTeams2 } = organization;

  const checkUserCanCreateNewMetadata = useMemo(() => {
    // TODO: PERMISSION
    return shouldAllowAddingNewMetadata(currentUser, organization);
  }, [currentUser, organization]);

  const categories = useSelector(state => getCategories(state, true));

  const priorities = useSelector(state => getPriorities(state, true));

  const themes = useSelector(state => getThemes(state, true));

  const objectives = useSelector(state => getObjectives(state, true));

  const keyResults1 = useSelector(state => selectKeyResults1(state, true));

  const keyResults2 = useSelector(state => selectKeyResults2(state, true));

  const objectivesCorp = useSelector(state => getObjectives(state, true, METADATA_LEVELS.LEVEL_CORP));

  const roadmaps = useSelector(state => getRoadmaps(state, true));

  const products1 = useSelector(state => getProducts(state, true));

  const products2 = useSelector(state => getProductsLevelTwo(state, true));

  const roadmapsCorp = useSelector(state => getCorpRoadmaps(state, false));

  const timeframesCorp = useSelector(state => getTimeframesLevelCorp(state, true));

  const phases = useSelector(getPhases);

  const teams = useSelector(state => getTeams(state));

  const skills = useSelector(state => getSkills(state));

  const users = useSelector(state => getUsers(state));

  const [initiatives, bets] = useGetInitiativesAndBetsFromEntities();

  const parents = useMemo(() => [...initiatives, ...bets], [initiatives, bets]);

  const personas = useSelector(state => selectPersonasData(state, true));
  const lifecycles = useSelector(state => selectLifecyclesData(state, true));

  const usersGroupedByTeam = useMemo(
    () => groupByTeamHierarchy(users, { hasTeams2, getSystemFieldName }),
    [users, hasTeams2, getSystemFieldName],
  );

  const metadata = useMemo(
    () => ({
      categories,
      priorities,
      themes,
      objectives,
      keyResults1,
      keyResults2,
      objectivesCorp,
      roadmaps,
      products1,
      products2,
      roadmapsCorp,
      timeframesCorp,
      phases,
      teams,
      skills,
      users,
      parents,
      personas,
      lifecycles,
      usersGroupedByTeam,
    }),
    [
      categories,
      priorities,
      themes,
      objectives,
      keyResults1,
      keyResults2,
      objectivesCorp,
      roadmaps,
      products1,
      products2,
      roadmapsCorp,
      timeframesCorp,
      phases,
      teams,
      skills,
      users,
      parents,
      personas,
      lifecycles,
      usersGroupedByTeam,
    ],
  );

  const createMetadataMultiSelectOption = useCallback(
    field => title => {
      if (field === 'tags') return dispatch(createTag({ title }));
      if (field === 'customers') return dispatch(createCustomer({ name: title }));
      if (field === 'metrics') return dispatch(createMetric({ name: title }));
    },
    [],
  );

  const filteredWithoutArchivedItems = useMemo(() => {
    return filterActiveMetadata(filteredMetadata);
  }, [filteredMetadata]);

  return {
    metadata,
    filteredMetadata: filteredWithoutArchivedItems,
    checkUserCanCreateNewMetadata,
    createMetadataMultiSelectOption,
  };
};

export default useMetadata;
