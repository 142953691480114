import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { not } from 'ramda';

import { setRoadmapVersionSelected, updateRoadmapVersionDialogProperties } from 'store/roadmapVersions';

import useRoadmapVersions from 'hooks/useRoadmapVersions';

import history from 'store/utils/history';
import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/userViews';

import {
  FORECAST_PAGE,
  IDEAS_ESTIMATES_PAGE,
  ROADMAP_PAGE,
  SCENARIO_GANTT_PAGE,
  SCENARIO_LIST_PAGE,
  SCENARIO_SWIMLANE_PAGE,
  SCENARIO_SUMMARY_PAGE,
  SUMMARY_PAGE,
  SCENARIO_AI_PLANNER_PAGE,
  SCENARIO_CHART_PAGE,
} from 'constants/filters';

const SCENARIO_PAGES = [
  SCENARIO_LIST_PAGE,
  SCENARIO_GANTT_PAGE,
  SCENARIO_SWIMLANE_PAGE,
  SCENARIO_AI_PLANNER_PAGE,
  SCENARIO_SUMMARY_PAGE,
  SCENARIO_CHART_PAGE,
];
const SCENARIO_PAGES_ALLOWED = [IDEAS_ESTIMATES_PAGE, FORECAST_PAGE, ROADMAP_PAGE, SUMMARY_PAGE, ...SCENARIO_PAGES];

const useScenarioToolbarControls = () => {
  const dispatch = useDispatch();

  const { isRoadmapVersionSelected, isRoadmapVersionCommittedWithGanttFlow } = useRoadmapVersions();

  const path = getCurrentPath();
  const pageId = getPageIdFromPath(path);
  const scenarioPagesAllowed = SCENARIO_PAGES_ALLOWED.includes(pageId);
  const isNewDraftScenario = isRoadmapVersionSelected && not(isRoadmapVersionCommittedWithGanttFlow) && scenarioPagesAllowed;
  const isCreatingOrViewingScenario = isRoadmapVersionSelected && scenarioPagesAllowed;
  const isViewingScenario = isRoadmapVersionSelected && isRoadmapVersionCommittedWithGanttFlow && scenarioPagesAllowed;

  const onClickExitDraftMode = useCallback(() => {
    dispatch(setRoadmapVersionSelected(null));

    history.push(`/${path}`);
  }, []);

  const onClickSaveScenario = useCallback(() => {
    dispatch(updateRoadmapVersionDialogProperties({ showSaveDialog: true }));
  }, []);

  return {
    isCreatingOrViewingScenario,
    isNewDraftScenario,
    onClickExitDraftMode,
    onClickSaveScenario,
    isViewingScenario,
  };
};

export default useScenarioToolbarControls;
