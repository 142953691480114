import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSelectedRoadmapVersion } from 'store/roadmapVersions/selectors';
import { cloneVersion } from 'store/roadmapVersions';

// todo: to be revisited. Dye to the nature of gantt update of multiple taks (project, estimates)
//  we need to clone it first into an uncommitted version
const usePreCloneScenario = () => {
  const selectedRoadmapVersion = useSelector(getSelectedRoadmapVersion);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedRoadmapVersion?.committed) {
      dispatch(cloneVersion(selectedRoadmapVersion));
    }
  }, [selectedRoadmapVersion]);
};

export default usePreCloneScenario;
