import React, { useEffect } from 'react';

export const OPTIONS = {
  FIELD_MAPPING: 'field-mapping',
  PROGRESS_TRACKING: 'progress-tracking',
};

const OPTIONS_LIST = [
  { key: OPTIONS.FIELD_MAPPING, title: 'Field Mapping Setting' },
];

const container = Component => {
  return props => {
    const { selectedOption, onChange } = props;

    useEffect(() => {
      if (!selectedOption) {
        onChange(OPTIONS.FIELD_MAPPING);
      }
    }, [selectedOption, onChange]);

    return <Component options={OPTIONS_LIST} selected={selectedOption} onChange={onChange} />;
  };
};

export default container;
