import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { keys, defaultTo } from 'ramda';

import { UNDEFINED_LABEL } from 'constants/common';
import { getUserFilters } from 'store/filters/selectors';
import { DASHBOARDS_PAGE } from 'constants/filters/pages';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import { getNormalizedObjectives } from 'store/objectives/selectors';
import calculateTextLines from 'utils/calculateTextLines';
import theme from 'design-system/theme';
import invertedTextColor from 'design-system/utils/invertedTextColor';
import { HEALTH_COLORS, HEALTH_LABEL_OPTIONS_LABELS, HEALTH_STATUS } from 'constants/projects';
import usePageFilters from 'hooks/filters/usePageFilters';

const MAX_NUM_LINES = 1;
const MAX_NUM_CHARS_OBJECTIVE = 25;
const FONT_SIZE = 11;
const MAX_WIDTH_FOR_LABEL = 120;
const PROGRESS_DATA_SET_KEY = 'progress';
const DEFAULT_CHART_DATA = {
  labels: [],
  datasets: [],
};
const CHART_API_URL = '/api/v1/reports/dashboard/goal-progress';

const checkHasChartData = (chartData = {}) => Boolean(keys(chartData.datasets).length);
const defaultUndefinedLabel = defaultTo(UNDEFINED_LABEL);
const defaultZero = defaultTo(0);

/**
 * @function useGoalsProgressChartData
 *
 * Use chart data for goals progress stacked chart
 *
 * @return {Object}
 */
const useGoalsProgressChartData = () => {
  const [state, setState] = useState({
    chartData: DEFAULT_CHART_DATA,
    loading: false,
  });
  const userFilters = useSelector(getUserFilters);
  const hasBet = useSelector(state => state.organization.organization.has_bet);

  const { pageFilters: filters, displayLayer } = usePageFilters(DASHBOARDS_PAGE);

  const objectives = useSelector(getNormalizedObjectives);

  const makeChartData = async () => {
    setState(state => ({ ...state, loading: true }));

    const projectsFilter = compileFiltersBody(filters, userFilters, hasBet, DASHBOARDS_PAGE, displayLayer, null);

    const { data: countersAndProgressByGoal } = await axios.post(CHART_API_URL, projectsFilter);

    const getLabelForObjective = objectiveId => {
      const label = defaultUndefinedLabel(objectives[objectiveId]?.title);
      const numLines = calculateTextLines(label, MAX_WIDTH_FOR_LABEL, FONT_SIZE);

      if (numLines > MAX_NUM_LINES) {
        return `${label.slice(0, MAX_NUM_CHARS_OBJECTIVE)}...`;
      }

      return label;
    };

    const ids = countersAndProgressByGoal.map(record => record.objective_id);
    const labels = ids.map(getLabelForObjective);

    const healthStatus = [
      HEALTH_STATUS.UNDEFINED,
      HEALTH_STATUS.CLOSED_OR_PAUSED,
      HEALTH_STATUS.ON_TRACK,
      HEALTH_STATUS.AT_RISK,
      HEALTH_STATUS.OFF_TRACK,
    ];
    const emptyKeyedDataSets = {
      [HEALTH_STATUS.UNDEFINED]: [],
      [HEALTH_STATUS.CLOSED_OR_PAUSED]: [],
      [HEALTH_STATUS.ON_TRACK]: [],
      [HEALTH_STATUS.AT_RISK]: [],
      [HEALTH_STATUS.OFF_TRACK]: [],
      [PROGRESS_DATA_SET_KEY]: [],
    };
    const keyedDataSets = countersAndProgressByGoal.reduce((keyedDataSets, goalCountersAndProgress) => {
      healthStatus.forEach(status => {
        const value = defaultZero(goalCountersAndProgress.stacks[status]);

        keyedDataSets[status].push(value);
      });

      const roundedPercentage = Math.round(defaultZero(goalCountersAndProgress.progress) * 100);

      keyedDataSets[PROGRESS_DATA_SET_KEY].push(roundedPercentage);

      return keyedDataSets;
    }, emptyKeyedDataSets);

    const percentageFormatter = value => `${value}%`;

    const datasets = keys(keyedDataSets).map(datasetKey => {
      if (datasetKey === PROGRESS_DATA_SET_KEY) {
        return {
          id: PROGRESS_DATA_SET_KEY,
          label: 'Progress',
          data: keyedDataSets[datasetKey],
          backgroundColor: theme.palette.black,
          hoverBackgroundColor: theme.palette.black,
          datalabels: {
            color: theme.palette.black,
            font: {
              size: theme.typography.fontSizeSmall,
              weight: 'bold',
            },
            formatter: percentageFormatter,
          },
          type: 'line',
          order: 0,
          yAxisID: 'yProgress',
        };
      }
      const statusColor = datasetKey;

      return {
        id: statusColor,
        label: HEALTH_LABEL_OPTIONS_LABELS[statusColor] || HEALTH_LABEL_OPTIONS_LABELS.Undefined,
        data: keyedDataSets[datasetKey],
        backgroundColor: HEALTH_COLORS[statusColor] || HEALTH_COLORS[HEALTH_STATUS.UNDEFINED],
        hoverBackgroundColor: HEALTH_COLORS[statusColor] || HEALTH_COLORS[HEALTH_STATUS.UNDEFINED],
        datalabels: {
          labels: {
            value: {
              color: invertedTextColor(HEALTH_COLORS[statusColor], true),
            },
          },
        },
        order: 1,
        yAxisID: 'y',
      };
    });
    const data = {
      ids,
      labels,
      datasets,
    };

    setState({ loading: false, chartData: data });
  };

  useEffect(() => {
    makeChartData();
  }, [filters, userFilters, hasBet, displayLayer]);

  const hasData = useMemo(() => checkHasChartData(state.chartData), [state.chartData]);

  return {
    chartData: state.chartData,
    displayLayer,
    hasData,
    loading: state.loading,
  };
};

export default useGoalsProgressChartData;
export { PROGRESS_DATA_SET_KEY };
