import React from 'react';
import styled from 'styled-components';

import DndFooterAction from 'design-system/atoms/DndFooterAction/index';


const DndFooterActions = ({ actions = [] }) => {
  return (
    <FooterWrapper>
      {actions?.map(action => (
        <DndFooterAction {...action} />
      ))}
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div`
  border-top: 1px solid #fafafa; // to change the color when addressing to all dnd/dropdown footers
  box-shadow: 0px -2px 12px 0px rgb(204 204 204 / 30%);
`;

export default DndFooterActions;
