import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UserIcon from '@material-ui/icons/Person';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SettingsIcon from '@material-ui/icons/Settings';
import FilterListIcon from '@material-ui/icons/FilterList';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

// Dragonboat dependencies
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import Dropdown from 'design-system/molecules/Dropdown/index';
import ZoomIcon from 'design-system/atoms/ZoomIcon/index';
import GroupByAutocomplete from 'design-system/atoms/GroupByAutocomplete/index';

import FormGroup from '@material-ui/core/FormGroup';
import ColorToolbar from 'components/ColorToolbar';
import { STAFFING_PAGE } from 'constants/filters';
import AdvancedSearchPopover from 'containers/AdvancedSearchPopover';
import { STAFFING_FILTERS_FIELDS, STAFFING_QUICK_FILTER_OPTIONS } from 'constants/staffing';
import QuickFilterOnPage from 'containers/QuickFilterOnPage';
import downloadPNG from 'utils/downloadPNG';

import PreferencesDialog from './PreferencesDialog';

export default ({
  exportToExcel,
  lsState,
  allCollapsed,
  expandAll,
  collapseAll,
  groupByOptions,
  groupByGroup2Options,
  onGroupLevelChange,
  onCreateNewAssignment,
  getLegendGroups,
  updateGroupColor,
  setOpenPreferences,
  openPreferences,
  changeSelectedZoom,
  getSystemFieldName,
  updateState,
  filtersActive,
  onUpdateSearchString,
  warning,
  onWarningClick,
}) => {
  const [filtersAnchorEl, setFiltersAnchorEl] = React.useState();
  const [filterButtonRef, setFilterButtonRef] = React.useState();
  const { selectedGroup1, selectedGroup2, selectedColorBy, showLegend, selectedZoom, hideTasksName } = lsState;

  const showColorLegend = colorBy => {
    return colorBy && colorBy.title !== 'Idea' ? (
      <div style={{ display: 'inline-block' }}>
        <ColorToolbar
          groups={getLegendGroups(colorBy)}
          showPicker={colorBy.title !== 'Health'}
          onUpdateGroupColor={updateGroupColor(colorBy)}
        />
      </div>
    ) : (
      ''
    );
  };
  const moreDropdownOptions = [
    {
      id: 'preferences',
      key: 'preferences',
      onClick: openPreferences,
      title: (
        <span>
          <SettingsIcon style={{ verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Display preferences</span>
        </span>
      ),
    },
    {
      id: 'show-task-name',
      key: 'show-task-name',
      onClick: () => updateState({ hideTasksName: !hideTasksName }),
      title: (
        <span>
          {!hideTasksName ? (
            <VisibilityIcon style={{ verticalAlign: 'bottom' }} />
          ) : (
            <VisibilityOffIcon style={{ verticalAlign: 'bottom' }} />
          )}
          <span style={{ paddingLeft: 13 }}>Hide Assignment</span>
        </span>
      ),
    },
    {
      id: 'export',
      key: 'export',
      onClick: exportToExcel,
      title: (
        <span>
          <DownloadIcon style={{ verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Export</span>
        </span>
      ),
    },
    {
      id: 'manageResources',
      key: 'manageResources',
      title: (
        <Link to="/settings/users" href="/settings/users" style={{ textDecoration: 'none', color: 'inherit' }}>
          <UserIcon style={{ verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Manage Users</span>
        </Link>
      ),
    },
    {
      id: 'download-png',
      key: 'download-png',
      onClick: downloadPNG,
      title: (
        <span>
          <PhotoCamera style={{ verticalAlign: 'bottom' }} />
          <span style={{ paddingLeft: 13 }}>Download PNG</span>
        </span>
      ),
    },
  ];

  const _onOpenFilters = () => {
    setFiltersAnchorEl(filterButtonRef);
  };

  return (
    <GridWrapper container spacing={8}>
      <Grid item>
        <StyledFormGroup row>
          <GroupByAutocomplete
            name="selectedGroup1"
            suggestions={groupByOptions.filter(o => !o.key || o.key !== (selectedGroup2 || {}).key)}
            value={selectedGroup1}
            onChange={onGroupLevelChange('selectedGroup1')}
          />
          {selectedGroup1 && selectedGroup1.key && (
            <>
              <Spacing />
              <GroupByAutocomplete
                name="selectedGroup2"
                suggestions={groupByGroup2Options.filter(o => !o.key || o.key !== selectedGroup1.key)}
                value={selectedGroup2}
                onChange={onGroupLevelChange('selectedGroup2')}
                label="then by"
              />
            </>
          )}
        </StyledFormGroup>
      </Grid>
      {warning && (
        <WarningMsg>
          <a role="link" tabIndex={0} onClick={() => onWarningClick()}>
            {warning.message}
          </a>
        </WarningMsg>
      )}
      <Grid item style={{ textAlign: 'right' }}>
        <FormGroup row style={{ justifyContent: 'flex-end' }}>
          <AdvancedSearchPopover
            anchorEl={filtersAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            page={STAFFING_PAGE}
            setAnchorEl={el => setFiltersAnchorEl(el)}
            availableFields={STAFFING_FILTERS_FIELDS}
            quickFilterOptions={STAFFING_QUICK_FILTER_OPTIONS(getSystemFieldName)}
            hideSearchByKey
            enableQuickFilters
          />
          <QuickFilterOnPage page={STAFFING_PAGE} quickFilterOptions={STAFFING_QUICK_FILTER_OPTIONS(getSystemFieldName)} />
          <div ref={r => setFilterButtonRef(r)}>
            <ButtonIcon onClick={_onOpenFilters} title="Filter Users" id="staffing-filter">
              {/* <FilterListIcon color={filtersActive ? 'primary' : 'default'} /> */}
              <FilterListIcon style={{ color: filtersActive ? '#37a5da' : '#333' }} />
            </ButtonIcon>
          </div>
          {selectedGroup1 && selectedGroup1.key && (
            <ButtonIcon
              onClick={allCollapsed ? expandAll : collapseAll}
              title={allCollapsed ? 'Expand all' : 'Collapse all'}
              id="expand-collapse"
            >
              {allCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </ButtonIcon>
          )}
          <ButtonIcon title={selectedZoom && selectedZoom.title} onClick={changeSelectedZoom} id="zoom-icon">
            {selectedZoom && selectedZoom.id === 'day' && (
              <ZoomIcon size="small">
                <span />
              </ZoomIcon>
            )}
            {(!selectedZoom || selectedZoom.id === 'week') && (
              <ZoomIcon size="medium">
                <span />
              </ZoomIcon>
            )}
            {selectedZoom && selectedZoom.id === 'month' && (
              <ZoomIcon>
                <span />
              </ZoomIcon>
            )}
            {selectedZoom && selectedZoom.id === 'quarter' && (
              <ZoomIcon size="big">
                <span />
              </ZoomIcon>
            )}
          </ButtonIcon>
          <PreferencesDialog triggerOpenEvent={open => setOpenPreferences(open)} showColorLegend={showColorLegend} />
          <Dropdown
            placeholder={
              <ButtonIcon>
                <MoreHorizIcon />
              </ButtonIcon>
            }
            options={moreDropdownOptions}
            isButton={false}
            inline
          />
        </FormGroup>
      </Grid>
      {showLegend && <ColorLegend>{showColorLegend(selectedColorBy)}</ColorLegend>}
    </GridWrapper>
  );
};

const GridWrapper = styled(Grid)`
  &&& {
    padding: 8px 18px 8px 44px;
    max-width: 100vw;
    position: relative;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledFormGroup = styled(FormGroup)`
  padding-top: 10px;
`;

const ColorLegend = styled.div`
  border: 1px solid #efefef;
  border-radius: 5px;
  margin: 0 22px 4px 8px;
  overflow-y: auto;
  width: 100%;
  max-height: 66px;
  padding: 8px;
`;

const WarningMsg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: red;
  }
`;

const Spacing = styled.div`
  padding: 5px;
`;
