import axios from 'axios';
import isEqual from 'lodash/isEqual';
import { __, includes, isNil, not } from 'ramda';

import cleanUserFilterState from 'utils/filters/cleanUserFilterState';
import buildPageFiltersWithPlanningStages from 'utils/filters/buildPageFiltersWithPlanningStages';
import { getCurrentUser } from 'store/login/selectors';
import { DEFAULT_PAGE_FILTERS, GLOBAL_FILTER, CHILDREN_FILTERS } from 'constants/filters';
import { IDEA_LAYER } from 'src/store/projects/constants';
import {
  SET_PAGE_FILTERS,
  CLEAR_PAGE_FILTERS,
  CREATE_USER_FILTER,
  FETCH_USER_FILTERS,
  UPDATE_USER_FILTER,
  DELETE_USER_FILTER,
  SET_GROUP_DATA_TYPE,
  SET_FILTERS,
  CREATE_USER_FILTER_FULFILLED,
  DELETE_USER_FILTER_FULFILLED,
  UPDATE_USER_FILTER_BY_REALTIME,
} from './types';
import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';

import { getPageFilters, getProjectLayers } from './selectors';
import { SHARED_FILTERS_BY_PAGE } from 'store/userViews';
import { getPageIdFromPath } from 'utils/userViews';
import { excludeLayersFromChildren } from 'utils/filters/excludeLayersFromChildren';

import { getSharedFilterIdFromPageId } from './selectors/getPageFilters';
import initializeDefaultPageFilterState from './utils/initializeDefaultPageFilterState';

const includesChildren = includes(__, [CHILDREN_FILTERS.allChildren, CHILDREN_FILTERS.filteredChildren]);
const isGlobalFiltersPage = includes(__, SHARED_FILTERS_BY_PAGE[GLOBAL_FILTER]);

/**
 * Sets the page filters.
 *
 * @param {string} page - The page identifier.
 * @param {Object} filters - The filters to be applied.
 * @param {boolean} [onLoad=false] - Flag indicating if the filters have been applied reactively or by user action
 * @param {string} [filterId=null] - The filter identifier.
 * @param {boolean} [updateActiveView=true] - Flag indicating if the active view should be updated.
 * @return {Promise} A promise that resolves when the filters are set.
 */
export const setPageFilters =
  (page, filters, onLoad = false, filterId = null, updateActiveView = true) =>
  (dispatch, getState) => {
    const filterPageId = getPageIdFromPath(window.location.pathname) || GLOBAL_FILTER;

    const state = getState();
    const { bottomLayer, hasIdea } = getProjectLayers(state);

    const pageFilters = page === GLOBAL_FILTER ? buildPageFiltersWithPlanningStages(filters, filterId) : filters;

    /*
     * if was applied a static filter should use from current filters
     * - layer, secondLayer and children
     *
     * TODO: we could refator all setPageFilters actions to be
     * moved to usePageFilters hook and apply this logic there
     */
    const filter = state._filters.userFilters && state._filters.userFilters.find(f => f.id === filterId);
    const aplliedFilterIsStaticFilter = filter && !!filter.is_static;

    if (pageFilters.layer === bottomLayer) {
      pageFilters.children = CHILDREN_FILTERS.noChildren;
      pageFilters.allCorpSubItems = false;
    }

    if (not(hasIdea) && isGlobalFiltersPage(filterPageId) && includesChildren(pageFilters.children)) {
      pageFilters.childrenFields = excludeLayersFromChildren(pageFilters.childrenFields, [IDEA_LAYER]);
    }

    if (aplliedFilterIsStaticFilter || isNil(pageFilters.layer)) {
      const currentFilters = getPageFilters(state);

      pageFilters.layer = currentFilters.layer;
      pageFilters.secondLayer = currentFilters.secondLayer;
      pageFilters.children = currentFilters.children;
    }

    const sharedFilter = getSharedFilterIdFromPageId(filterPageId);

    const payload = {
      page: sharedFilter || filterPageId,
      filters: pageFilters,
    };

    dispatch({
      type: SET_PAGE_FILTERS,
      payload,
      meta: {
        ...(updateActiveView ? { makesActiveViewDirty: true } : {}),
        onLoad,
      },
    });

    dispatch(updateDefaultUserFilter(sharedFilter || filterPageId, pageFilters));
  };

export const updateDefaultUserFilter = (page, pageFilters) => (dispatch, getState) => {
  const state = getState();
  const currentUser = getCurrentUser(state);
  const userDefaultFilter =
    state._filters.userFilters && state._filters.userFilters.find(f => !!f.default_filter && f.page === page);

  if (currentUser.is_anonymous) {
    return Promise.resolve({});
  }

  const filterData = {
    state: pageFilters,
    page,
    default_filter: true,
    name: `Default Filter (${currentUser.id})`,
    user_id: currentUser.id,
  };

  if (!userDefaultFilter) {
    return dispatch(createUserFilter(filterData));
  }

  if (isEqual(userDefaultFilter.state, pageFilters)) return Promise.resolve({});
  return dispatch(updateUserFilter(userDefaultFilter.id, filterData));
};

export const clearPageFilters = page => (dispatch, getState) => {
  dispatch({
    type: CLEAR_PAGE_FILTERS,
    payload: { page },
  });

  dispatch(setPageFilters(page, DEFAULT_PAGE_FILTERS[page]));
};

export const createUserFilter = data => (dispatch, getState) => {
  const state = getState();
  const currentUser = getCurrentUser(state);

  if (currentUser.isSuperAdmin) return;

  const payload = axios.post('/api/userFilters/', data);

  dispatch({
    payload,
    type: CREATE_USER_FILTER,
  });

  return payload;
};

export const updateUserFilter = (id, data) => {
  return {
    payload: axios.put(`/api/userFilters/${id}`, data).then(res => res.data),
    type: UPDATE_USER_FILTER,
  };
};

export const fetchUserFilters = () => async (dispatch, getState) => {
  const payload = new Promise(async (resolve, reject) => {
    const response = await axios.get('/api/userFilters/');

    const hasToBeClean = filter => !filter.is_static;

    const _cleanUserFilter = filter => ({ ...filter, state: cleanUserFilterState(filter?.state || {}) });

    response.data = (response?.data || []).map(filter => (hasToBeClean(filter) ? _cleanUserFilter(filter) : filter));

    const { data } = response;

    const userDefaultFilters = data.filter(f => !!f.default_filter);

    if (!userDefaultFilters.length) {
      // Should find for a default static filter and apply
      const defaultStaticFilter = data.find(f => f.is_static && f.is_default);

      if (defaultStaticFilter)
        // third parameter is true because it happens on load, without a user action to manually apply the filter
        dispatch(setPageFilters(defaultStaticFilter.page, defaultStaticFilter.state, true, defaultStaticFilter.id));
    } else {
      const filters = userDefaultFilters.reduce((acc, filter) => {
        acc[filter.page] = initializeDefaultPageFilterState(filter);

        return acc;
      }, {});

      dispatch({
        payload: filters,
        type: SET_FILTERS,
      });
    }

    resolve(response);
  });

  return dispatch({
    payload,
    type: FETCH_USER_FILTERS,
  });
};

export const deleteUserFilter = id => {
  return {
    payload: axios.delete(`/api/userFilters/${id}`),
    type: DELETE_USER_FILTER,
  };
};

/*
 * This action apply the layer inside of current global
 * filters on the store (_filters.global.layer)
 *
 * With this change every time user changes the display layer will execute
 * a backend search and not only filter the layer on frontend side
 */
export const setDisplayLayer = (layer, portfolioMode) => (dispatch, getState) => {
  const state = getState();
  const currentFilters = getPageFilters(state, GLOBAL_FILTER);

  const currentFiltersWithNewLayer = {
    ...currentFilters,
    layer,
    children: portfolioMode ? CHILDREN_FILTERS.allChildren : CHILDREN_FILTERS.noChildren,
  };

  dispatch(setPageFilters(GLOBAL_FILTER, currentFiltersWithNewLayer));
};

export const setGroupDataType = dataType => {
  return {
    type: SET_GROUP_DATA_TYPE,
    payload: {
      dataType,
    },
  };
};

export const gotUserFiltersRealtimeUpdate = generateRealtimeUpdateAction(
  CREATE_USER_FILTER_FULFILLED,
  DELETE_USER_FILTER_FULFILLED,
  UPDATE_USER_FILTER_BY_REALTIME,
  DELETE_USER_FILTER_FULFILLED,
  CREATE_USER_FILTER_FULFILLED,
  UPDATE_USER_FILTER_BY_REALTIME,
);
