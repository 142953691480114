import React, { Component } from 'react';
import anchorme from 'anchorme';
import DOMPurify from 'dompurify';

export default class AgGridParseLinks extends Component {
  render() {
    const { value } = this.props;

    if (!value || !(typeof (value) === 'string')) {
      return '';
    }

    // TODO we may want to use linkifyHtmlString from utils instead
    const textWithUrlsParsed = anchorme(value);

    return (
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(textWithUrlsParsed) }} />
    );
  }
}
