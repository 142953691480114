import React from 'react';
import ShowDependenciesIcon from '@material-ui/icons/LowPriority';
import Switch from '@material-ui/core/Switch';

import Dropdown from 'design-system/molecules/Dropdown/index';
import ToggleButton from 'design-system/molecules/ToggleButton/index';


const Component = ({
  withoutDependencies,
  showDependencyLines,
  onDependencyLinesToggle,
  showAllDependencies,
  onAllDependenciesToggle,
  hideItemsWithoutDependencies,
  onItemsWithoutDependenciesToggle,
  extraRows = [],
  hideShowAllDependencies, // TODO: remove once implementation is complete
}) => {
  const options = [
    ...(withoutDependencies
      ? []
      : [
          {
            id: 'dependency-line',
            key: 'dependency-line',
            onClick: () => onDependencyLinesToggle(),
            title: (
              <div>
                <Switch color="primary" checked={showDependencyLines} />
                Dependency lines
              </div>
            ),
          },
        ]),
    ...(hideShowAllDependencies
      ? []
      : [
          {
            id: 'show-all-dependencies',
            key: 'show-all-dependencies',
            onClick: () => onAllDependenciesToggle(),
            title: (
              <div>
                <Switch color="primary" checked={showAllDependencies} />
                Show all dependencies
              </div>
            ),
          },
        ]),
    {
      id: 'hide-items-without-dependencies',
      key: 'hide-items-without-dependencies',
      onClick: () => onItemsWithoutDependenciesToggle(),
      title: (
        <div>
          <Switch color="primary" checked={hideItemsWithoutDependencies} />
          Hide items without dependencies
        </div>
      ),
    },
    ...extraRows,
  ];

  return (
    <Dropdown
      placeholder={
        <ToggleButton
          on={showAllDependencies || hideItemsWithoutDependencies || showDependencyLines}
          icon={<ShowDependenciesIcon id="dependencies-icon" />}
        />
      }
      placement="bottom-start"
      options={options}
      isButton={false}
    />
  );
};

export default Component;
