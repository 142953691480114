import React, { useState, useCallback, useImperativeHandle, useRef, useEffect } from 'react';
import { defaultTo, prop } from 'ramda';
import RootRef from '@material-ui/core/RootRef';
import isFunction from 'lodash/isFunction';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MultiSelect from 'design-system/atoms/MultiSelect';
import cellEditorHoc from 'design-system/molecules/AgGridReact-New/helpers/cellEditorHoc';

const customHandleOnClickAway = () => {};

const defaultToEmptyObject = defaultTo({});
const getId = (idField, object) => prop(idField, defaultToEmptyObject(object))?.toString();
const isIdInValues = (values, id) => Boolean(values.find(v => String(v) === String(id)));

const MultiSelectCellEditor = cellEditorHoc(
  ({
    options,
    optionsMapper,
    value: originalValue = [],
    createOption,
    forwardedRef,
    stopEditing,
    hideCreateOption = false,
    idField = 'id',
  }) => {
    const domRef = useRef(null);
    const [internalValue, setInternalValue] = useState(originalValue);
    const optionsFromProps = isFunction(options) ? options() : options;

    useImperativeHandle(forwardedRef, () => ({
      getValue() {
        return internalValue;
      },
    }));

    const onChange = useCallback(
      (value, optionsWithCreatedOption) => {
        const changeFromCreateNew = Boolean(optionsWithCreatedOption);

        const rows = (optionsWithCreatedOption ?? optionsFromProps)
          .filter(row => isIdInValues(value, getId(idField, row)))
          .sort((row1, row2) => {
            const row1Id = getId(idField, row1);
            const row2Id = getId(idField, row2);

            return value.indexOf(row1Id) - value.indexOf(row2Id);
          });

        setInternalValue(rows);

        /* If the change is triggered by the create new option it should close and update the project.
         * It was the old grid behaviour and it helps to manage the options and selected state in the cell.
         * This will force an update of the column def to refresh the new options (ag grid prevents it if we are editing)
         */
        if (changeFromCreateNew) {
          stopEditing();
        }
      },
      [optionsFromProps, stopEditing, options],
    );

    const onCreateOption = useCallback(
      value => {
        return createOption(value);
      },
      [createOption],
    );

    useEffect(() => {
      if (originalValue) {
        setInternalValue(originalValue);
      }
    }, [originalValue]);

    const value = internalValue ?? originalValue;

    return (
      <RootRef rootRef={domRef}>
        <MultiSelectContainer>
          <MultiSelect
            options={optionsFromProps}
            optionsMapper={optionsMapper}
            onChange={onChange}
            value={value}
            onCreateOption={onCreateOption}
            hideCreateOption={hideCreateOption}
            autoFocus
          />
        </MultiSelectContainer>
      </RootRef>
    );
  },
  customHandleOnClickAway,
);

const MultiSelectContainer = styled.div`
  width: 200px;
`;

MultiSelectCellEditor.propTypes = {
  value: PropTypes.array.isRequired,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  optionsMapper: PropTypes.object,
  createOption: PropTypes.func,
};

MultiSelectCellEditor.defaultProps = {
  createOption: () => Promise.resolve,
  options: [],
  optionsMapper: {
    label: 'title',
    value: 'id',
  },
};

export default MultiSelectCellEditor;
