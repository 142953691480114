import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchOutcomeSnapshotDataAction } from 'store/goalMode/thunks';
import { getFetchOutcomeSnapshotDataIsLoading, getOutcomeSnapshotReportData } from 'store/goalMode/selectors';

import { getProjectLayers } from 'store/filters/selectors';
import { equals, pick } from 'ramda';

export default function useLoadOutcomeSnapshotReport(filteredOkrs = [], shouldFetchReport = true) {
  const dispatch = useDispatch();

  const isLoadingReportData = useSelector(getFetchOutcomeSnapshotDataIsLoading);
  const rawReportData = useSelector(getOutcomeSnapshotReportData);
  const { layers } = useSelector(state => getProjectLayers(state));

  const reportData = useMemo(() => {
    const reportDataRows = rawReportData && Array.isArray(rawReportData) ? rawReportData : [];

    return filteredOkrs.map(okr => {
      const propsThatNeedToMatch = ['id', 'objective_id', 'parent_id'];
      const reportDataForOkr = reportDataRows.find(row =>
        propsThatNeedToMatch.every(propName => equals(row[propName], okr[propName])),
      );

      return {
        ...reportDataForOkr,
        ...okr,
      };
    });
  }, [filteredOkrs, rawReportData]);

  const fetchOutcomeSnapshotData = useCallback(requestBody => dispatch(fetchOutcomeSnapshotDataAction(requestBody)), [dispatch]);

  const fetchReportData = useCallback(() => {
    const body = {
      filteredOkrs: filteredOkrs.map(pick(['id', 'parent_id', 'objective_id', 'level', 'title'])),
      displayLayer: layers,
    };

    return fetchOutcomeSnapshotData(body);
  }, [filteredOkrs, layers, fetchOutcomeSnapshotData]);

  useEffect(() => {
    if (shouldFetchReport) {
      fetchReportData();
    }
  }, [shouldFetchReport, fetchReportData]);

  return {
    fetchReportData,
    isLoadingReportData,
    projectDisplayLayer: layers,
    reportData,
  };
}
