import { useMemo, useCallback } from 'react';

import { getResourcesCountForTeamSkill } from 'utils/estimates';
import { processColumnsGroup as processColumnsGroupUtil, processGroomedColumnDefs } from 'utils/grids/helpers';

import { useIdeasGridColumnsDefinitions } from 'containers/IdeasList/IdeasList/New/hooks';

import useEstimatesLightboxContext from '../useEstimatesLightbox';
import { getEstimatesColsVisibility } from '../../helpers';
import { getEstimateColumnDef, checkAddChildrenButtonVisibility } from './helpers';
import { DateCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { checkboxColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import clickableHeaderWithBackgroundHOC from 'design-system/molecules/AgGridReact-New/helpers/clickableHeaderWithBackgroundHOC';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import { IDEA_LAYER } from 'store/projects/constants';
import { FORECAST_BY_HEADCOUNT } from 'constants/forecast';

import useTeamLightboxContext from 'containers/IdeasForecastList/hooks/useTeamLightbox';
import getTeamHeaderArguments from './helpers/getTeamHeaderArguments';

const useEstimatesColumnsDefinitions = ({
  viewType,
  portfolioMode,
  hasHierarchy,
  hasBet,
  hasKeyResults,
  hasKeyResults2,
  hasProducts,
  hasProducts2,
  hasMultiLevelMetadata,
  hasTeams2,
  topLayer,
  displayLayer,
  rowHeight,
  metadata,
  visibleColumnsIds,
  titleSearchString,
  customUserFields,
  columnsState,
  processedData,
  integrations,
  currentUser,
  forecastBy,
  customFields,
  hasMetadataMultiSelect,
  isBulkDeleting,

  getSystemFieldName,
  addNewInlineProject,
  openProjectInfo,
  openRowInfo,
  loadParentProjectsOptions,
  checkUserCanCreateNewMetadata,
  updateProjectById,
  handleMetadataCellDoubleClick,
  removeGridRow,
  createMetadataMultiSelectOption,
}) => {
  const { canView } = usePermissions();

  const canWorkWithEstimationByPoints =
    canView(PERMISSION_FEATURES.usePointsToEstimate) && displayLayer === IDEA_LAYER && forecastBy !== FORECAST_BY_HEADCOUNT;

  const {
    columnDefs: ideasGridColumnDefs,
    defaultColDef,
    groupColumnDef,
  } = useIdeasGridColumnsDefinitions({
    portfolioMode,
    hasHierarchy,
    hasBet,
    hasKeyResults,
    hasKeyResults2,
    hasProducts,
    hasProducts2,
    hasMultiLevelMetadata,
    hasTeams2,
    topLayer,
    displayLayer,
    rowHeight,
    visibleColumnsIds,
    titleSearchString,
    customUserFields,
    integrations,
    currentUser,
    customFields,
    columnsState,
    hasMetadataMultiSelect,
    isBulkDeleting,

    getSystemFieldName,
    addNewInlineProject,
    openProjectInfo,
    openRowInfo,
    loadParentProjectsOptions,
    checkUserCanCreateNewMetadata,
    updateProjectById,
    handleMetadataCellDoubleClick,
    removeGridRow,
    createMetadataMultiSelectOption,
    checkAddChildrenButtonVisibility,
  });

  const { openEstimatesLightbox } = useEstimatesLightboxContext();
  const { openTeamLightbox } = useTeamLightboxContext();

  const { teams, skills, users } = metadata;

  const { visibleColumnsIds: visibleEstimatesColumnsIds } = useMemo(
    () => getEstimatesColsVisibility(teams, skills, users, processedData),
    [teams, skills, users, processedData],
  );

  /*
   * forecastBy is included on dependencies because every time some of them is
   * changed should update estimates columns on the grid
   */
  const estimatesColumnsDefs = useMemo(() => {
    return teams.map(team => ({
      headerName: team.title,
      field: `estimate_team_${team.id}`,
      headerClass: 'ag-header-cell-small-padding',
      headerGroupComponent: clickableHeaderWithBackgroundHOC(
        ...getTeamHeaderArguments({
          team,
          canWorkWithEstimationByPoints,
          openTeamLightbox,
        }),
      ),
      children: skills.map(skill => {
        const resourcesCount = getResourcesCountForTeamSkill(team.id, skill.id, users);

        return getEstimateColumnDef(team, skill, resourcesCount, {
          openEstimatesLightbox,
          canWorkWithEstimationByPoints,
        });
      }),
    }));
  }, [teams, skills, users, openEstimatesLightbox, forecastBy, canWorkWithEstimationByPoints, openTeamLightbox]);

  const committedColumnDef = useMemo(
    () => ({
      ...checkboxColumnDef,
      key: 'committed',
      field: 'committed',
      headerName: 'Above the Line',
      width: 120,
      maxWidth: 120,
    }),
    [],
  );

  const estimatedDatesColumnDef = useMemo(
    () => [
      {
        field: 'start_date_estimates',
        key: 'start_date_estimates',
        headerName: 'Estimated Start Date',
        width: 160,
        editable: false,
        cellRenderer: DateCellRenderer,
      },
      {
        field: 'end_date_estimates',
        key: 'end_date_estimates',
        headerName: 'Estimated End Date',
        width: 160,
        editable: false,
        cellRenderer: DateCellRenderer,
      },
    ],
    [],
  );

  const hasColumnsState = Boolean(columnsState);

  const visibleColumnsIdsWithEstimatesCols = useMemo(() => {
    if (hasColumnsState) {
      return visibleColumnsIds;
    }

    return [...visibleColumnsIds, ...visibleEstimatesColumnsIds];
  }, [hasColumnsState, visibleColumnsIds, visibleEstimatesColumnsIds]);

  const processColumnsGroup = useCallback(
    columns => processColumnsGroupUtil(columns, visibleColumnsIdsWithEstimatesCols, columnsState),
    [visibleColumnsIdsWithEstimatesCols, columnsState],
  );

  const columnDefs = useMemo(
    () =>
      processGroomedColumnDefs(
        [
          ...ideasGridColumnDefs,

          // Estimates columns
          ...processColumnsGroup([...estimatesColumnsDefs, committedColumnDef, ...estimatedDatesColumnDef]),
        ],
        columnsState,
      ),
    [ideasGridColumnDefs, processColumnsGroup, estimatesColumnsDefs, columnsState, committedColumnDef, estimatedDatesColumnDef],
  );

  return {
    columnDefs,
    defaultColDef,
    groupColumnDef,
  };
};

export default useEstimatesColumnsDefinitions;
