import axios from 'axios';
import throwRequestError from '../utils/throwRequestError';

import { SWITCH_PRIORITIES_ROW_ORDER, MERGE_PRIORITIES, DELETE_PRIORITY } from './types';

import moveRowToPosition from 'utils/moveRowToPosition';

export function switchPrioritiesRowOrder(id1, id2, update, position) {
  return (dispatch, getState) => {
    const state = getState().priorities.priorities;
    const prevData = state.find(({ id }) => id === +id1);
    const movedRow = moveRowToPosition(state, id1, id2, position);

    const payload = axios.put(`/api/priorities/rowOrder/${id1}/${id2}`, { position }).then(res => res.data);

    dispatch({
      type: SWITCH_PRIORITIES_ROW_ORDER,
      payload,
      meta: { prev: prevData, row: movedRow, update },
    });

    return payload;
  };
}

export function mergePriorities(prioritiesIdsToMerge, priorityId) {
  return dispatch => {
    const payload = axios
      .post(`/api/priorities/merge/${priorityId}`, {
        prioritiesIdsToMerge,
      })
      .then(response => {
        return response.data;
      })
      .catch(throwRequestError);

    dispatch({
      type: MERGE_PRIORITIES,
      payload,
    });

    return payload;
  };
}

export const deletePriority = priorityId => {
  return dispatch => {
    const payload = axios
      .delete(`/api/priorities/${priorityId}`)
      .then(res => res.data)
      .catch(throwRequestError);

    dispatch({
      payload,
      type: DELETE_PRIORITY,
    });

    return payload;
  };
};
