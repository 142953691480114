import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MuiButton from '@material-ui/core/Button';
import styled from 'styled-components';

import PageLoading from 'design-system/atoms/PageLoading/PageLoading';
import PublicPageTemplate from 'design-system/templates/PublicPageTemplate/index';
import Text from 'design-system/atoms/Text/index';

import { getActiveViewForPage, getLoadedPublicSharedViewData } from 'store/userViews/selectors';
import { loadPublicSharedView, setPageActiveView } from 'store/userViews/actions';
import { path } from 'ramda';
import { setAuthorizationToken } from 'utils/setAuthorizationToken';
import { setTokenValue } from 'store/login/actions';
import ErrorImage from 'components/PermissionsDialog/components/permissions_dialog.svg';
import { ToastContainer } from 'react-toastify';
import { getOrganization } from 'store/organization/selectors';
import { SHOW_TOOLBOX } from 'config';
import ConfigToolBox from 'containers/ConfigToolBox';
import NotificationsWrapper from 'containers/NotificationsWrapper';
import { AVAILABLE_VIEWS } from '../constants/routes';
import { getViewName } from 'utils/userViews';
import { setAppError, getIsMetadataFetched } from 'src/store/app';

import useApplicationRoutes from 'hooks/useApplicationRoutes';

import { getAppState } from 'store/app/selectors';

import ErrorBoundary from 'containers/Main/ErrorBoundary';
import { DASHBOARDS_PAGE } from 'constants/filters';
import { PAGEID_TO_PATH } from 'constants/filters/pages';

const ViewNotFound = () => (
  <PublicPageTemplate>
    <PageNotFoundContainer>
      <ImageContainer>
        <StyledImg src={ErrorImage} />
      </ImageContainer>
      <TextContainer>The page you are looking for is no longer available</TextContainer>
      <Link to="/login" style={{ marginTop: 30 }}>
        Log in to Dragonboat
      </Link>
    </PageNotFoundContainer>
  </PublicPageTemplate>
);

const getSelectedTab = (pageId, routes) => {
  const pageIdPathInfo = PAGEID_TO_PATH[pageId];

  if (!pageIdPathInfo) {
    return null;
  }

  return routes.find(route => route.path === pageIdPathInfo.main) || routes[0];
};

export default props => {
  const dispatch = useDispatch();
  const shareLinkHash = path(['match', 'params', 'shareLinkHash'], props);
  const isAuthenticated = useSelector(state => state.login.isAuthenticated);
  const publicSharedViewData = useSelector(state => getLoadedPublicSharedViewData(state));
  const organization = useSelector(getOrganization);
  const isMetadataFetched = useSelector(getIsMetadataFetched);
  const [ready, setReady] = useState(false);
  const appState = useSelector(getAppState);
  const [routes] = isAuthenticated ? useApplicationRoutes() : [[]];
  const pageId = publicSharedViewData?.loadedView?.page;
  const activeView = useSelector(state => getActiveViewForPage(state, pageId));

  useEffect(() => {
    if (publicSharedViewData.error) {
      return;
    }

    if (!publicSharedViewData.loadedView.id) {
      dispatch(loadPublicSharedView(shareLinkHash));
      return;
    }

    if (!isAuthenticated) {
      const { token, user } = publicSharedViewData;

      setAuthorizationToken(token);
      dispatch(setTokenValue(token, user));
      return;
    }

    if (publicSharedViewData.loadedView.id === activeView?.id) {
      setReady(true);
      return;
    }

    dispatch(setPageActiveView(pageId, publicSharedViewData.loadedView));
  }, [shareLinkHash, isAuthenticated, publicSharedViewData, activeView]);

  const noRouteAllowed = isAuthenticated && routes.length === 0;

  if (publicSharedViewData.error || noRouteAllowed) {
    return <ViewNotFound />;
  }

  if (ready && isMetadataFetched) {
    const view = publicSharedViewData.loadedView;
    const viewUrl = `/${view.path}?sharedView=${view.key}`;
    const viewPathParts = publicSharedViewData.loadedView.path.split('/');
    const ViewComponent =
      pageId === DASHBOARDS_PAGE ? AVAILABLE_VIEWS.dashboard.dashboards : path(viewPathParts, AVAILABLE_VIEWS);

    const selectedTab = getSelectedTab(pageId, routes);

    if (!ViewComponent || !selectedTab) {
      return <ViewNotFound />;
    }

    const layoutProps = {
      ...props,
      ...selectedTab?.props,
      organization,
      routes,
      selectedTab,
      disableNav: true,
      user: publicSharedViewData.user,
    };

    return (
      <MainWrapper>
        <ToastContainer progressClassName="dragonboat-react-toastify-progress-bar" />
        {(SHOW_TOOLBOX || localStorage.getItem('showConfigTollbox')) && (
          <ConfigToolBox systemFields={organization.system_fields_name} />
        )}
        <ErrorBoundary appError={appState.appError} setAppError={msg => dispatch(setAppError(msg))}>
          <MainContent id="outer-container">
            <NotificationsWrapper>
              <TopBar>
                <Button>
                  <Link to={viewUrl} target="_blank">
                    Go to Dragonboat
                  </Link>
                </Button>
                <Text variant="h4" maxWidth="80vw" withEllipsis>
                  {getViewName(view)}
                </Text>
              </TopBar>
              <ViewComponent {...layoutProps} />
            </NotificationsWrapper>
          </MainContent>
        </ErrorBoundary>
      </MainWrapper>
    );
  }

  return <PageLoading />;
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 1128px;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  position: relative;
`;

const TopBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 22px;
  margin-top: 40px;
`;

const Button = styled(MuiButton)`
  && {
    color: ${({ theme }) => theme.palette.newLayout.text.primary};
    font-size: ${({ theme }) => theme.typography.fontSizeSmallLargeRem}rem;
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
    text-transform: none;
  }

  a {
    text-decoration: none;
  }
`;

const PageNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled.div`
  height: 100%;
`;

const TextContainer = styled.div`
  margin-top: 2em;
  font-size: 18px;
`;

const StyledImg = styled.img`
  height: 100%;
`;
