import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCategories } from 'store/categories/selectors';
import {
  createCategory,
  createCategoryRoadmap,
  updateCategoryById,
  mergeCategories,
  fetchCategories,
  removeUnsavedCategories,
  addCategoryWithoutSave,
  createCategories,
  updateCategories,
  bulkDeleteCategories,
  bulkDeleteCategoryRoadmaps,
  switchCategoriesRowOrder,
  deleteCategory,
  deleteCategoryRoadmap,
} from 'store/categories';

const useCategories = hideArchivedItems => {
  const dispatch = useDispatch();

  const categories = useSelector(state => getCategories(state, !hideArchivedItems));

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          fetchCategories,
          createCategory,
          updateCategoryById,
          mergeCategories,
          addCategoryWithoutSave,
          removeUnsavedCategories,
          createCategories,
          updateCategories,
          bulkDeleteCategories,
          switchCategoriesRowOrder,
          deleteCategory,
        },
        dispatch,
      ),
    [dispatch],
  );

  return {
    categories,
    createCategoryRoadmap,
    deleteCategoryRoadmap,
    bulkDeleteCategoryRoadmaps,
    ...boundActionCreators,
  };
};

export default useCategories;
