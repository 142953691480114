import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { defaultTo } from 'ramda';

import { CUSTOMER_REQUESTS } from 'store/grids/constants';
import { getGridConfigValue } from 'store/grids/selectors';
import { getSearch, getVisibleFields, getIsLoadingCustomerRequests, getIsBulkUpdate } from 'store/customerRequests/selectors';
import { saveGridState as saveGridStateAction } from 'store/grids/actions';
import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/userViews';
import { getActiveViewForPage } from 'store/userViews/selectors';
import { fetchCustomerRequests } from 'store/customerRequests/actions';
import usePageViewOnGrid from 'hooks/grid/usePageViewOnGrid';

const defaultAsEmptyArray = defaultTo([]);

/**
 * @function useCustomerRequestsGridState
 *
 * Hook that resolves all state data that are reuired on the grid
 *
 * @return {Object}
 */
const useCustomerRequestsGridState = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingCustomerRequests);
  const search = useSelector(getSearch);
  const visibleFields = useSelector(getVisibleFields);

  const isBulkUpdate = useSelector(getIsBulkUpdate);
  const selectedItems = useSelector(state => getGridConfigValue(state, CUSTOMER_REQUESTS, 'selectedItems'));
  const gridStateFromStore = useSelector(state => getGridConfigValue(state, CUSTOMER_REQUESTS, 'gridState', {}));
  const rowHeight = useSelector(state => getGridConfigValue(state, CUSTOMER_REQUESTS, 'rowHeight', 40));

  const path = getCurrentPath();
  const pageId = getPageIdFromPath(path);
  const activeView = useSelector(state => getActiveViewForPage(state, pageId));

  useEffect(() => {
    /*
     * should ignore when change to default view
     * it means some change after apply some view
     */
    if (activeView?.default_view) {
      return;
    }

    /*
     * Each time user change the view will get requests from the server to
     * mount the grid and reset loading sate
     */
    dispatch(fetchCustomerRequests());
  }, [activeView?.id]);

  const { shouldConsiderViewDirty, shouldGridBeUnmounted, setDirtyViewMutex } = usePageViewOnGrid({
    activeView,
    isLoading,
  });

  /*
   * For now will only update the grid state when active view changes
   */
  const gridState = useMemo(() => gridStateFromStore, [activeView?.id]);
  const columnsState = useMemo(() => gridState?.columnState, [gridState?.columnState]);

  const saveColumnsState = useCallback(
    newColumnsState => {
      dispatch(
        saveGridStateAction(
          CUSTOMER_REQUESTS,
          {
            ...gridState,
            columnState: newColumnsState,
          },
          shouldConsiderViewDirty,
        ),
      );
    },
    [dispatch, gridState, shouldConsiderViewDirty],
  );

  const visibleColumnsFromGridState = useMemo(
    () =>
      defaultAsEmptyArray(columnsState)
        .filter(column => !column.hide)
        .map(column => column.colId),
    [columnsState],
  );

  const visibleColumnsIds = useMemo(() => {
    if (visibleColumnsFromGridState.length > 0) {
      return visibleColumnsFromGridState;
    }

    return visibleFields;
  }, [visibleColumnsFromGridState, visibleFields]);

  return {
    isLoading,
    searchString: search,
    visibleColumnsIds,
    selectedItems,
    columnsState,
    rowHeight,
    isBulkUpdate,
    shouldGridBeUnmounted,

    setDirtyViewMutex,
    saveColumnsState,
  };
};

export default useCustomerRequestsGridState;
