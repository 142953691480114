import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import DOMPurify from 'dompurify';
import { defaultTo, equals, indexBy, not, prop } from 'ramda';

import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import ProjectLink from 'design-system/atoms/ProjectLink/index';
import Text from 'design-system/atoms/Text/index';

import ProjectProgressCounters from 'containers/ProjectProgressCounters';
import ProjectIntegrationKey from 'containers/ProjectIntegrationKey';
import ProjectType from 'containers/ProjectType';

import usePermissions from 'hooks/permissions/usePermissions';
import useSystemFields from 'hooks/useSystemFields';
import useDeepMemo from 'hooks/useDeepMemo';
import formatDate from 'utils/dates/formatDate';
import { planningStageColors } from 'utils';
import useFeatureFlags from 'hooks/useFeatureFlags';
import { FeatureFlags } from '@dragonboat/config';
import ProjectInfoCardDetailsLine from 'containers/ProjectInfoCardDetailsLine';
import {
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_PRODUCTS_2,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
  PERSONAS,
  LIFECYCLES,
} from 'constants/common';
import { ALL_CUSTOM_USER_FIELDS_AVAILABLE } from 'constants/projects';
import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';

import { selectPersonasData } from 'features/Personas/store/selectors';
import { selectLifecyclesData } from 'features/Lifecycles/store/selectors';
import { getScopeVarianceColor } from 'utils/allocation/getScopeVarianceColor';
import { customerUserFieldKeys, getCustomUserFieldKeyFromSystemFieldName } from 'utils/customUserFields/customUserFields';
import formatPercent from 'design-system/utils/formatPercent';

const byId = indexBy(prop('id'));

const makeGetAdditionalMetadataDetails = (project, displayFields) => (field, label) => {
  return displayFields.includes(field) && project?.[field]
    ? project?.[field].map(c => ({
        value: c.title,
        field: label,
      }))
    : [];
};

const htmlFields = ['status_summary', 'details'];
const FIELDS_TO_IGNORE_NEXT_TO_TITLE = ['integrationKey'];

const defaultToZero = defaultTo(0);
const defaultToEmptyArray = defaultTo([]);

const isMultiSelectCustomField = fieldType => equals(fieldType, TYPES_OF_CUSTOM_FIELDS.MULTI_SELECT_DROPDOWN);
const isRichTextCustomField = fieldType => equals(fieldType, TYPES_OF_CUSTOM_FIELDS.RICH_TEXT);

const ProjectInfoCard = React.memo(
  ({
    displayFields: fieldsToDisplay,
    fieldsToIgnore,
    project,
    disabled,
    customFields,
    onClickProject,
    hideIcon,
    orgIntegrations,
    titleComponent: TitleComponent,
    localSearchStr,
    dataTestId,
  }) => {
    const _handleClickProject = useCallback(() => onClickProject(project.id), [project.id]);
    const displayFields = useMemo(() => fieldsToDisplay, [fieldsToDisplay]);

    const { canView } = usePermissions();
    const [getSystemFieldName] = useSystemFields();
    const systemFields = useSelector(state => state.organization.organization.system_fields_name);
    const personasData = useSelector(state => selectPersonasData(state, true));
    const personasById = byId(personasData);
    const lifecyclesData = useSelector(state => selectLifecyclesData(state, true));
    const lifecyclesById = byId(lifecyclesData);
    const additionalRoadmapsLabel = `Additional ${getSystemFieldName('roadmap', systemFields, true)}`;
    const additionalProducts1Label = `Additional ${getSystemFieldName('product1', systemFields, true)}`;
    const additionalProducts2Label = `Additional ${getSystemFieldName('product2', systemFields, true)}`;
    const additionalObjectivesLabel = `Additional ${getSystemFieldName('objective', systemFields, true)}`;
    const additionalKeyResults1Label = `Additional ${getSystemFieldName('keyResult1', systemFields, true)}`;
    const additionalKeyResults2Label = `Additional ${getSystemFieldName('keyResult2', systemFields, true)}`;
    const additionalTimeframesLabel = `Additional ${getSystemFieldName('timeframe', systemFields, true)}`;
    const additionalTimeframes2Label = `Additional ${getSystemFieldName('timeframe2', systemFields, true)}`;
    const additionalThemesLabel = `Additional ${getSystemFieldName('theme', systemFields, true)}`;
    const additionalCategoriesLabel = `Additional ${getSystemFieldName('category', systemFields, true)}`;
    const additionalTeamsLabel = `All ${getSystemFieldName('team', systemFields, true)}`;
    const additionalTeams2Label = `All ${getSystemFieldName('team2', systemFields, true)}`;
    const shouldShowPreCalculations = useFeatureFlags([FeatureFlags.HAS_OUTCOME_DASHBOARD]);
    const canViewTimeframeLevel2 = canView(PERMISSION_RESOURCES.timeframe2);

    const _renderField = useDeepMemo(
      () =>
        ({ value, field, color, label }) => {
          if (!value || (fieldsToIgnore || []).includes(field)) return null;

          const cfObject = customFields.find(f => f.key === field);

          if (cfObject) return { label: cfObject.title, value };

          return { label: label || getSystemFieldName(field), value };
        },
      [fieldsToIgnore],
    );
    const _renderSection = useMemo(
      () => items => {
        const filteredItems = items.map(i => _renderField(i)).filter(Boolean);

        return filteredItems.map((item, index) => {
          if (typeof item.value === 'object') return item.value;

          return <ProjectInfoCardDetailsLine value={item.value || index} label={item.label} />;
        });
      },
      [],
    );

    if (!project) return '';

    const _getCustomFieldValue = f => {
      switch (f.field_type) {
        case TYPES_OF_CUSTOM_FIELDS.DATE:
          return formatDate(project[f.key]);
        case TYPES_OF_CUSTOM_FIELDS.DROPDOWN:
          return f.data?.[project[f.key]];
        case TYPES_OF_CUSTOM_FIELDS.YES_NO:
          return project[f.key] ? 'Yes' : 'No';
        default:
          return project[f.key];
      }
    };

    const singleValueCustomFields = useMemo(() => {
      return [...customFields].reduce((cfs, field) => {
        const isSingleValueCustomField =
          not(isMultiSelectCustomField(field.field_type)) && not(isRichTextCustomField(field.field_type));

        if (isSingleValueCustomField && displayFields.includes(field.key)) {
          return [...cfs, { value: _getCustomFieldValue(field), field: field.key }];
        }

        return cfs;
      }, []);
    }, [_getCustomFieldValue, customFields, displayFields, project]);

    const multiValueCustomFields = useMemo(() => {
      return [...customFields].reduce((cfs, field) => {
        if (isMultiSelectCustomField(field.field_type) && displayFields.includes(field.key) && project[field.key]) {
          const values = defaultToEmptyArray(project[field.key]);
          const mappedValues = values.map(value => ({ value: field.data_enhanced[value]?.title, field: field.key }));

          return [...cfs, ...mappedValues];
        }

        return cfs;
      }, []);
    }, [customFields, displayFields, project]);

    const customUserFields = useMemo(() => {
      return customerUserFieldKeys
        .map(key => {
          const userFieldKey = getCustomUserFieldKeyFromSystemFieldName(key);
          const userFieldProjectKey = ALL_CUSTOM_USER_FIELDS_AVAILABLE[userFieldKey]?.field;

          if (displayFields.includes(userFieldProjectKey) && project?.[userFieldProjectKey]) {
            return {
              value: project[userFieldProjectKey],
              field: key,
            };
          }

          return null;
        })
        .filter(field => !!field);
    }, [displayFields, project]);

    const getAdditionalMetadataDetails = useMemo(
      () => makeGetAdditionalMetadataDetails(project, displayFields),
      [project, displayFields],
    );

    const details = useMemo(() => {
      return [
        ...(displayFields.includes('roadmap') && project.roadmap
          ? [{ value: project.roadmap.title, field: 'roadmap', color: project.roadmap.color }]
          : []),
        ...(displayFields.includes('product1') && project.product1
          ? [{ value: project.product1.title, field: 'product1', color: project.product1.color }]
          : []),
        ...getAdditionalMetadataDetails(ADDITIONAL_ROADMAPS, additionalRoadmapsLabel),
        ...getAdditionalMetadataDetails(ADDITIONAL_PRODUCTS, additionalProducts1Label),
        ...getAdditionalMetadataDetails(ADDITIONAL_PRODUCTS_2, additionalProducts2Label),
        ...(displayFields.includes('timeframe') && project.timeframe
          ? [{ value: project.timeframe.title, field: 'timeframe' }]
          : []),
        ...(displayFields.includes('timeframe2') && project.timeframe2 && canViewTimeframeLevel2
          ? [{ value: project.timeframe2.title, field: 'timeframe2' }]
          : []),
        ...getAdditionalMetadataDetails(ADDITIONAL_TIMEFRAMES, additionalTimeframesLabel),
        ...getAdditionalMetadataDetails(ADDITIONAL_TIMEFRAMES_2, additionalTimeframes2Label),
        ...(displayFields.includes('owner') && project.ownerName ? [{ value: project.ownerName, field: 'owner' }] : []),
        ...(displayFields.includes('objective') && project.objective
          ? [{ value: project.objective.title, field: 'objective', color: project.objective.color }]
          : []),
        ...(displayFields.includes('keyResult1') && project.keyResult1
          ? [{ value: project.keyResult1.title, field: 'keyResult1', color: project.keyResult1.color }]
          : []),
        ...(displayFields.includes('keyResult2') && project.keyResult2
          ? [{ value: project.keyResult2.title, field: 'keyResult2', color: project.keyResult2.color }]
          : []),
        ...getAdditionalMetadataDetails(ADDITIONAL_OBJECTIVES, additionalObjectivesLabel),
        ...getAdditionalMetadataDetails(ADDITIONAL_KEY_RESULTS, additionalKeyResults1Label),
        ...getAdditionalMetadataDetails(ADDITIONAL_KEY_RESULTS_2, additionalKeyResults2Label),
        ...(displayFields.includes(PERSONAS) && project.personas
          ? project.personas.map(p => {
              const personaData = personasById[p?.id];

              return { value: personaData?.title, field: 'persona' };
            })
          : []),
        ...(displayFields.includes(LIFECYCLES) && project.personas
          ? project.lifecycles.map(l => {
              const lifecycleData = lifecyclesById[l?.id];

              return { value: lifecycleData?.title, field: 'lifecycle' };
            })
          : []),
        ...(displayFields.includes('customers') && project.customers
          ? project.customers.map(c => ({ value: c.name, field: 'customer', color: c.color }))
          : []),
        ...(displayFields.includes('tags') && project.tags
          ? project.tags.map(c => ({ value: c.title, field: 'tags', color: c.color }))
          : []),
        ...(displayFields.includes('progress') && project.displayProgress
          ? [{ value: project.displayProgress, field: 'progress' }]
          : []),
        ...(displayFields.includes('scopeVariance') && project.scopeVariance
          ? [
              {
                value: formatPercent(project.scopeVariance),
                field: 'scopeVariance',
                label: 'Scope Variance',
                color: getScopeVarianceColor(project.scopeVariance),
              },
            ]
          : []),
        ...(displayFields.includes('plannedAllocation') && project.plannedAllocation
          ? [{ value: project.plannedAllocation, field: 'plannedAllocation', label: 'Planned Scope (weeks)' }]
          : []),
        ...(displayFields.includes('reportedAllocation') && project.reportedAllocation
          ? [{ value: project.reportedAllocation, field: 'reportedAllocation', label: 'Reported Scope (weeks)' }]
          : []),
        ...(displayFields.includes('completedAllocation') && project.completedAllocation
          ? [{ value: project.completedAllocation, field: 'completedAllocation', label: 'Completed Scope (weeks)' }]
          : []),
        ...(displayFields.includes('totalRevenue') && shouldShowPreCalculations
          ? [{ value: defaultToZero(project?.totalRevenue), field: 'totalRevenue', label: 'Total value' }]
          : []),
        ...(displayFields.includes('activeRevenue') && shouldShowPreCalculations
          ? [{ value: defaultToZero(project?.activeRevenue), field: 'activeRevenue', label: 'Active value' }]
          : []),
        ...(displayFields.includes('inactiveRevenue') && shouldShowPreCalculations
          ? [{ value: defaultToZero(project?.inactiveRevenue), field: 'inactiveRevenue', label: 'Inactive value' }]
          : []),
        ...(displayFields.includes('customersCount')
          ? [{ value: defaultToZero(project?.customersCount), field: 'customersCount', label: '# of Customers' }]
          : []),
        ...(displayFields.includes('customerRequestsCount')
          ? [
              {
                value: defaultToZero(project?.customerRequestsCount),
                field: 'customerRequestsCount',
                label: 'Total requests',
              },
            ]
          : []),
        ...(displayFields.includes('planningStage') && project.planningStage
          ? [
              {
                value: project.planningStage,
                field: 'planningStage',
                color: planningStageColors[project.planningStage],
                label: 'Planning Stage',
              },
            ]
          : []),
        ...(displayFields.includes('phase') && project.phase
          ? [{ value: project.phase.title, field: 'phase', color: project.phase.color }]
          : []),
        ...(displayFields.includes('theme') && project.theme
          ? [{ value: project.theme.title, field: 'theme', color: project.theme.color }]
          : []),
        ...getAdditionalMetadataDetails(ADDITIONAL_THEMES, additionalThemesLabel),
        ...(displayFields.includes('category') && project.category
          ? [{ value: project.category.title, field: 'category', color: project.category.color }]
          : []),
        ...getAdditionalMetadataDetails(ADDITIONAL_CATEGORIES, additionalCategoriesLabel),
        ...(displayFields.includes('bet') ? [{ value: project.parent, field: 'bet' }] : []),
        ...(displayFields.includes('initiative') ? [{ value: project.parent, field: 'initiative' }] : []),
        ...(displayFields.includes('priority') && project.priority
          ? [{ value: project.priority.title, field: 'priority', color: project.priority.color }]
          : []),
        ...(displayFields.includes('estimated_start_date') && project.estimated_start_date
          ? [{ value: formatDate(project.estimated_start_date), field: 'estimated_start_date', label: 'Target start date' }]
          : []),
        ...(displayFields.includes('deadline') && project.deadline
          ? [{ value: formatDate(project.deadline), field: 'deadline', label: 'Target end date' }]
          : []),
        ...(displayFields.includes('predictedEndDate') && project.predictedEndDate
          ? [{ value: formatDate(project.predictedEndDate), field: 'predictedEndDate', label: 'Predicted end date' }]
          : []),
        ...(displayFields.includes('team') && project.resourceTeam
          ? [{ value: project.resourceTeam.title, field: 'team', color: project.resourceTeam.color }]
          : []),
        ...(displayFields.includes('integrationKey') && project.integration
          ? [
              {
                value: (
                  <ProjectIntegrationWrap>
                    <ProjectIntegrationKey project={project} orgIntegrations={orgIntegrations} />
                  </ProjectIntegrationWrap>
                ),
                field: 'integrationKey',
              },
            ]
          : []),
        ...getAdditionalMetadataDetails(ADDITIONAL_TEAMS, additionalTeamsLabel),
        ...getAdditionalMetadataDetails(ADDITIONAL_TEAMS_2, additionalTeams2Label),
        ...singleValueCustomFields,
        ...multiValueCustomFields,
        ...customUserFields,
      ].sort((a, b) => displayFields.indexOf(a.field) - displayFields.indexOf(b.field));
    }, [
      additionalCategoriesLabel,
      additionalKeyResults1Label,
      additionalKeyResults2Label,
      additionalObjectivesLabel,
      additionalProducts1Label,
      additionalProducts2Label,
      additionalRoadmapsLabel,
      additionalTeamsLabel,
      additionalTeams2Label,
      additionalThemesLabel,
      additionalTimeframesLabel,
      additionalTimeframes2Label,
      displayFields,
      getAdditionalMetadataDetails,
      multiValueCustomFields,
      orgIntegrations,
      project,
      shouldShowPreCalculations,
      singleValueCustomFields,
    ]);

    const stringDisplayFields = displayFields.filter(f => !['health', 'key'].includes(f));
    const titleIndex = stringDisplayFields.findIndex(f => f === 'title');
    let fieldNextToTitleId = stringDisplayFields.length > titleIndex + 1 ? stringDisplayFields[titleIndex + 1] : null;

    if (FIELDS_TO_IGNORE_NEXT_TO_TITLE.includes(fieldNextToTitleId)) fieldNextToTitleId = null;
    const fieldNextToTitle = fieldNextToTitleId && details.find(f => f.field === fieldNextToTitleId);

    const _renderTitle = title => {
      if (!title) return '';
      if (!localSearchStr) return title;

      return (
        <HighlighterWrapper>
          <Highlighter highlightClassName="hightlightText" searchWords={[localSearchStr]} autoEscape textToHighlight={title} />
        </HighlighterWrapper>
      );
    };

    return (
      <Wrapper data-testid={dataTestId} onClick={_handleClickProject} disabled={disabled} clickable={!!onClickProject}>
        <TitleLine>
          {!hideIcon && (
            <>
              <ProjectType layer={project.layer} color={displayFields.includes('health') && project.statusColorHex} />{' '}
            </>
          )}
          {displayFields.includes('key') && !fieldsToIgnore.includes('key') && (
            <ProjectIdLink role="button" tabIndex="0" style={{ marginLeft: '5px' }} onClick={_handleClickProject}>
              {project.id}
            </ProjectIdLink>
          )}{' '}
          {displayFields.includes('title') && (
            <>
              <Title>
                {TitleComponent ? <TitleComponent>{project.title}</TitleComponent> : _renderTitle(project.title)}
                {fieldNextToTitle && <span style={{ minWidth: 50 }}> ({fieldNextToTitle.value})</span>}
              </Title>
            </>
          )}
        </TitleLine>
        <DetailsLine addMargin={!!displayFields.includes('health')}>
          {_renderSection(details.filter(f => f.field !== fieldNextToTitleId))}
        </DetailsLine>
        {displayFields.includes('progressCount') && (
          <DetailsLine addMargin={!!displayFields.includes('health')}>
            <ProjectProgressCounters project={project} noMargin noColor />
          </DetailsLine>
        )}
        {htmlFields
          .filter(field => displayFields.includes(field) && project[field] && project[field] !== '<p></p>')
          .map(field => (
            <HtmlContainer>
              <HtmlContainerLabel addMargin={!!displayFields.includes('health')}>
                {getSystemFieldName(field, systemFields)}:
              </HtmlContainerLabel>
              <HtmlContainerValue
                addMargin={!!displayFields.includes('health')}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(project[field]) }}
              />
            </HtmlContainer>
          ))}
        {customFields
          ?.filter(
            cf =>
              isRichTextCustomField(cf.field_type) &&
              displayFields.includes(cf.key) &&
              project[cf.key] &&
              project[cf.key] !== '<p></p>',
          )
          .map(cf => (
            <HtmlContainer>
              <HtmlContainerLabel addMargin={!!displayFields.includes('health')}>{cf.title}:</HtmlContainerLabel>
              <HtmlContainerValue
                addMargin={!!displayFields.includes('health')}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(project[cf.key]) }}
              />
            </HtmlContainer>
          ))}
      </Wrapper>
    );
  },
);

ProjectInfoCard.propTypes = {
  customFields: PropTypes.array,
  disabled: PropTypes.bool,
  displayFields: PropTypes.array,
  fieldsToIgnore: PropTypes.array,
  hideIcon: PropTypes.bool,
  onClickProject: PropTypes.func.isRequired,
  project: PropTypes.shape({
    category: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    customers: PropTypes.array,
    deadline: PropTypes.any,
    displayProgress: PropTypes.any,
    estimated_start_date: PropTypes.any,
    id: PropTypes.number,
    keyResult1: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    layer: PropTypes.any,
    objective: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    ownerName: PropTypes.any,
    parent: PropTypes.any,
    phase: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    planningStage: PropTypes.any,
    predictedEndDate: PropTypes.any,
    priority: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    product1: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    roadmap: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    status_color: PropTypes.shape({
      toLowerCase: PropTypes.func,
    }),
    tags: PropTypes.shape({
      map: PropTypes.func,
    }),
    theme: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    timeframe: PropTypes.shape({
      title: PropTypes.any,
    }),
    title: PropTypes.string,
  }).isRequired,
  titleComponent: PropTypes.any,
};

ProjectInfoCard.defaultProps = {
  displayFields: ['title', 'timeframe', 'key'],
  fieldsToIgnore: [],
  disabled: false,
  customFields: [],
};

export default ProjectInfoCard;

const Wrapper = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  line-height: 20px;
  margin: 2.5px 0;
  overflow: hidden;
  border-radius: 4px;
  padding: 0;
  background: white;

  ${({ disabled }) =>
    !disabled &&
    `
    cursor: pointer;
    box-shadow: 0px 1px 2px #6f77821a;
    border: 1px solid #e8ecee;
    padding: 5px 5px;
  `}

  ${({ clickable }) =>
    clickable &&
    `
    cursor: pointer;
  `}
`;

const ProjectIdLink = styled(ProjectLink)`
  &&&& {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  }
`;

const DetailsLine = styled.div`
  font-size: 13px;
  line-height: 14px;
  color: #a7a7a7;
  ${({ addMargin }) => (addMargin ? 'margin-left: 25px;' : 'margin-left: 5px;')}
  margin-top: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  word-break: break-word;
`;

const TitleLine = styled.div`
  &&&& {
    display: flex;
    flex-direction: row;
  }
`;

const Title = styled(Typography)`
  &&&& {
    display: block;
    margin-left: 5px;
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    white-space: break-spaces;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    word-break: break-word;

    span {
      font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
      line-height: 14px;
      color: #a7a7a7;
      font-weight: 400;
      word-break: break-word;
    }
  }
`;

const HtmlContainer = styled.div`
  margin-bottom: 4px;
`;

const HtmlContainerLabel = styled.div`
  ${({ addMargin }) => (addMargin ? 'margin-left: 30px;' : 'margin-left: 10px;')}
  font-size: 13px;
  font-weight: 400;
  color: ${props => props.theme.palette.text.primary};
  text-transform: capitalize;
`;

const HtmlContainerValue = styled(Text)`
  ${({ addMargin }) => (addMargin ? 'margin-left: 30px;' : 'margin-left: 10px;')}
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
  color: #a7a7a7;
  line-height: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ProjectIntegrationWrap = styled.div`
  display: inline-flex;
  margin: 5px;
`;

const HighlighterWrapper = styled.div`
  span {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem !important;
    color: ${props => props.theme.palette.text.primary} !important;
  }
`;
