import React, { useCallback, useState } from 'react';

import ConfirmDialog from 'components/ConfirmDialog';

import DeleteModalContent from './DeleteModalContent';

const ConfirmPhaseDeleteModal = ({
  defaultPhase = {},
  handleCancel,
  handleDelete,
  isBulk,
  isOpen,
  items,
  objectToDelete,
  selectedItems,
  title,
  text,
}) => {
  const [selectedPhaseId, setSelectedPhaseId] = useState(defaultPhase.id);

  const handleConfirm = useCallback(() => handleDelete(selectedPhaseId), [handleDelete, selectedPhaseId]);

  const handleSetSelectedPhaseId = useCallback(val => setSelectedPhaseId(val), []);

  return (
    <ConfirmDialog
      disableConfirm={!selectedPhaseId}
      isOpen={isOpen}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      title={title}
      text={text}
      extraContent={
        <DeleteModalContent
          isBulk={isBulk}
          items={items}
          objectToDelete={objectToDelete}
          selectedItems={selectedItems}
          selectedPhaseId={selectedPhaseId}
          setSelectedPhaseId={handleSetSelectedPhaseId}
        />
      }
    />
  );
};

export default ConfirmPhaseDeleteModal;
