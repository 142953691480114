import React from 'react';
import DragonTableCell from '../DragonTableCell';
import DragonTableIconButton from '../DragonTableIconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

export default ({ row, icon = OpenInNewIcon, onClickButton, ...props }) => {
  const _onClick = e => {
    e.preventDefault();
    return onClickButton(row, e);
  };

  return (
    <DragonTableCell {...props} style={{ height: props.cellProps.rowHeight, justifyContent: 'center' }}>
      {!row ? null : (
        <a href="#" target="_blank" rel="noreferrer noopener" onClick={props.disabled ? e => e.preventDefault() : undefined}>
          <DragonTableIconButton disabled={props.disabled} onClick={_onClick} icon={icon} />
        </a>
      )}
    </DragonTableCell>
  );
};
