import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { either, equals, isEmpty, isNil, not } from 'ramda';

import { FeatureFlags } from '@dragonboat/config';
import { DEFAULT_MAPPING_KEY } from 'constants/integrations';

import useFeatureFlags from 'hooks/useFeatureFlags';

import { getCurrentUser as getCurrentUserSelector } from 'store/login/selectors';
import { updateJiraIntegration as updateJiraIntegrationAction } from 'store/organization';

import formatDateTime from 'utils/dates/formatDateTime';

const PROJECTS = 'PROJECTS';

const isDefaultMapping = equals(DEFAULT_MAPPING_KEY);
const isEntityProjects = equals(PROJECTS);
const isNilOrEmpty = either(isNil, isEmpty);

const useProjectsFieldMapping = props => {
  const { orgIntegrationId, integrationMapping, selectedMappingLayer } = props;

  const dispatch = useDispatch();

  const [isAddMappingVisible, setIsAddMappingVisible] = useState(false);

  const currentUser = useSelector(getCurrentUserSelector);

  const hasMappingByProject = useFeatureFlags([FeatureFlags.HAS_JIRA_FIELD_MAPPING_BY_PROJECT]);

  const isMappingAlreadyCreated = useCallback(
    projectKey => {
      return integrationMapping.some(
        ({ entity, project, layer }) => isEntityProjects(entity) && project === projectKey && layer === selectedMappingLayer,
      );
    },
    [integrationMapping, selectedMappingLayer],
  );

  const onStartAddFieldMapping = useCallback(() => setIsAddMappingVisible(true), []);

  const onSaveFieldMapping = useCallback(
    (projectKey, data) => {
      if (isNilOrEmpty(data)) {
        return;
      }

      let fieldMappingPayload = [];

      if (isMappingAlreadyCreated(projectKey)) {
        fieldMappingPayload = integrationMapping.map(mappingEntry => {
          const isCurrentMappingBeingUpdated =
            isEntityProjects(mappingEntry.entity) &&
            mappingEntry.project === projectKey &&
            mappingEntry.layer === selectedMappingLayer;

          return isCurrentMappingBeingUpdated ? { ...mappingEntry, mapping: data } : mappingEntry;
        });
      } else {
        fieldMappingPayload = [
          ...integrationMapping,
          { entity: PROJECTS, layer: selectedMappingLayer, project: projectKey, mapping: data },
        ];
      }

      const payload = {
        last_edit_mapping_day: formatDateTime(moment()),
        last_edit_mapping_by: currentUser.id,
        field_mapping: fieldMappingPayload,
      };

      dispatch(updateJiraIntegrationAction(orgIntegrationId, payload));
    },
    [orgIntegrationId, currentUser, integrationMapping, selectedMappingLayer],
  );

  const onRemoveFieldMapping = useCallback(
    mappingKey => {
      if (isDefaultMapping(mappingKey)) {
        return;
      }

      const payload = {
        field_mapping: integrationMapping.filter(({ entity, project, layer }) =>
          not(isEntityProjects(entity) && project === mappingKey && layer === selectedMappingLayer),
        ),
      };

      dispatch(updateJiraIntegrationAction(orgIntegrationId, payload));
    },
    [orgIntegrationId, integrationMapping, selectedMappingLayer],
  );

  const onAddFieldMapping = useCallback(
    projectKey => {
      if (not(hasMappingByProject) || isNil(projectKey)) {
        setIsAddMappingVisible(false);

        return;
      }

      if (isMappingAlreadyCreated(projectKey)) {
        setIsAddMappingVisible(false);

        return;
      }

      const payload = {
        field_mapping: [
          ...integrationMapping,
          { entity: PROJECTS, layer: selectedMappingLayer, project: projectKey, mapping: [] },
        ],
      };

      dispatch(updateJiraIntegrationAction(orgIntegrationId, payload));

      setIsAddMappingVisible(false);
    },
    [orgIntegrationId, hasMappingByProject, integrationMapping, selectedMappingLayer, setIsAddMappingVisible],
  );

  return {
    isAddMappingVisible,
    onStartAddFieldMapping,
    onSaveFieldMapping,
    onRemoveFieldMapping,
    onAddFieldMapping,
  };
};

export default useProjectsFieldMapping;
