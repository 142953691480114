import { defaultTo } from 'ramda';

export const TriggerTypes = {
  cron: 'cron',
  manual: 'manual',
  webhook: 'webhook',
};

/**
 * Checks if integration has at least one feature supporting all required triggerTypes.
 *
 * @param {IntegrationFeatures[]} integrationFeatures
 * @param {TriggerTypes|TriggerTypes[]} triggerTypeOrTypes
 */
export const integrationSupportsTriggerTypes = (integrationFeatures, triggerTypeOrTypes) => {
  const triggerTypes = [].concat(triggerTypeOrTypes);

  const hasFeatureWithRequiredTriggerTypes = defaultTo([])(integrationFeatures).some(feature => {
    const hasUnsupportedTriggerType = triggerTypes.some(triggerType => !feature.triggers.includes(triggerType));

    return !hasUnsupportedTriggerType;
  });

  return hasFeatureWithRequiredTriggerTypes;
};

/**
 * Checks if integration has at least one feature supporting webhook trigger
 *
 * @param {IntegrationFeatures[]} integrationFeatures
 */
export const integrationSupportsWebhookTrigger = integrationFeatures => {
  return integrationSupportsTriggerTypes(integrationFeatures, TriggerTypes.webhook);
};
