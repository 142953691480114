import axios from 'axios';

import transformObjectWithNestedKeys from 'utils/transformObjectWithNestedKeys';
import isEmail from 'utils/isEmail';

const DATA_MAPPING = [
  {
    field: 'first_name',
    displayTitle: 'First Name',
    displayHelpText: 'required',
    required: true,
    col: null,
  },
  {
    field: 'last_name',
    displayTitle: 'Last Name',
    col: null,
  },
  {
    field: 'email',
    displayTitle: 'Email',
    col: null,
  },
  {
    field: 'team',
    displayTitle: 'Team',
    displayHelpText: 'for capacity',
    col: null,
    parseToCorrectType: team => ({ title: team }),
  },
  {
    field: 'skill',
    displayTitle: 'Skill',
    displayHelpText: 'for capacity',
    col: null,
    parseToCorrectType: skill => ({ title: skill }),
  },
  {
    field: 'jira_username',
    displayTitle: 'Jira User Name',
    col: null,
  },
];

const getDefaultUsersRows = () => {
  const users = [];
  let nUsers = 100;

  while (nUsers--) {
    users.push({ team: {}, skill: {}, id: nUsers });
  }
  return users;
};

const getWarningMessage = (users, data) => {
  return `${users.length - data.length} users will be imported. \n${
    data.length
  } users are already in Dragonboat and will not be imported.`;
};

const correctUser = user => {
  return {
    ...transformObjectWithNestedKeys(user),
    email: isEmail(user.email) ? user.email : '',
  };
};

const callApiUsersExisting = users => {
  return axios.post('/api/users/existing', {
    users,
  });
};

const noop = () => {};

export { DATA_MAPPING, getWarningMessage, getDefaultUsersRows, correctUser, callApiUsersExisting, noop };
