import React, { Component } from 'react';
import { func, bool, string } from 'prop-types';
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { defaultTo } from 'ramda';

import validateEmail from 'utils/validateEmail';
import UserTypeMapper from 'utils/UserTypeMapper';

const defaultToEmptyString = defaultTo('');

export default class UserAccessEmailLightbox extends Component {
  static propTypes = {
    dialogVisible: bool,
    hideDialog: func.isRequired,
    onSave: func.isRequired,
    name: string,
    currentEmail: string,
  };

  static defaultProps = {
    dialogVisible: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentEmail: props.currentEmail,
    };
  }

  componentDidUpdate({ currentEmail: prevEmail }) {
    if (this.props.currentEmail !== prevEmail) {
      this.setState({ currentEmail: this.props.currentEmail });
    }
  }

  save = () => {
    const { hideDialog, onSave } = this.props;

    const result = onSave(this.state.currentEmail);

    if (result) {
      return result
        .then(() => {
          hideDialog();
        })
        .catch(err => {
          hideDialog();
          toast(err);
        });
    }
  };

  render() {
    const { dialogVisible, errorMessage, name, onCancel, roleId } = this.props;
    const { currentEmail } = this.state;

    const role = UserTypeMapper.find(r => r.value === roleId);

    return (
      <Dialog open={dialogVisible} id="merge-lightbox" maxWidth="sm" fullWidth>
        <DialogTitle>
          Enable {role ? role.label.toLowerCase() : 'editor'} access to {name}
        </DialogTitle>
        <DialogContent>
          <div className="container">
            <FormControl fullWidth margin="dense">
              <TextField
                name="email"
                label="Email"
                value={defaultToEmptyString(currentEmail)}
                error={errorMessage}
                onChange={e => this.setState({ currentEmail: e.target.value })}
              />
              <FormHelperText id="component-error-text" style={{ color: '#f44336' }}>
                {errorMessage}
              </FormHelperText>
              {errorMessage && (
                <div style={{ lineHeight: '1.3rem' }}>
                  {
                    'Please cancel email and search for the name or email via Search on \
                  this page, and merge users if they are duplicates. See more details '
                  }
                  <a
                    href="https://dragonboat.zendesk.com/hc/en-us/articles/360013274554"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </div>
              )}
            </FormControl>
          </div>
          <div className="actions-container">
            <Button onClick={onCancel}>Cancel</Button>
            <Button disabled={!validateEmail(this.state.currentEmail)} onClick={this.save} color="secondary">
              Send email invitation
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
