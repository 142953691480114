import { ACTIVE_STATUS } from 'constants/common';

export default defaultFilter => ({
  fields: {
    [defaultFilter.metaType]: defaultFilter.filter ? defaultFilter.filter : [defaultFilter.key || defaultFilter.id],
    status: [ACTIVE_STATUS],
  },
  quickFilters: {
    [defaultFilter.metaType]: [defaultFilter.key || defaultFilter.id],
  },
  op: {
    ...(defaultFilter.op ? { [defaultFilter.metaType]: defaultFilter.op } : {}),
  },
});
