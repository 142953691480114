export const WELCOME_MESSAGE_TEXT = `Welcome! I’m the Dragonboat demo bot and I’m trained to
        provide you with quick access to a customized, self-guided demo.
        If you’d prefer to connect with our expert sales team for your demo,
        feel free to book a session anytime right here.`;
export const TARGET_TEAM_QUESTION_TEXT = `Before we get started, I'll need to know a little bit about you.
        Are you evaluating a tool for your own product area or for (almost)
        all product teams across the entire company?`;
export const BUSINESS_DRIVER_QUESTION_TEXT = 'What is the business driver for evaluating a new roadmap tool?';

export const DEFAULT_BUSINESS_DRIVER_RESPONSE =
  'Please share a bit of what you are looking to solve with Dragonboat so I can customize your demo.';

export const TARGET_TEAM_OPTIONS = [
  {
    label: 'My product area',
    value: 'productArea',
  },
  {
    label: 'Across the company',
    value: 'company',
  },
];
