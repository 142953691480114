import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';

import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';

import theme from 'design-system/theme';
import MicroChip from '../MicroChip';

const defaultGetNOfTextRowsToDisplay = () => 0;

const Wrapper = styled.div`
  &&&& {
    text-overflow: ellipsis;
    white-space: initial;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${({ $nOfTextRowsToDisplay = 0 }) => ($nOfTextRowsToDisplay > 0 ? $nOfTextRowsToDisplay - 1 || 1 : 0)};
    -webkit-box-orient: vertical;
    height: auto;
  }
`;

export default class AgGridMultiMicroChipRenderer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parentColumnWidth: null,
    };
  }

  componentDidMount() {
    if (this.props.showTooltip && this.props.api) {
      this.props.api.addEventListener('columnResized', this.onColumnResize);
    }
  }

  componentWillUnmount() {
    if (this.props.showTooltip && this.props.api) {
      this.props.api.removeEventListener('columnResized', this.onColumnResize);
    }
  }

  onColumnResize = e => {
    const resizedCol = e.column;
    const parentColName = this.props.colDef.field;
    const isParentColumn = resizedCol && parentColName === resizedCol.colDef.field;

    if (isParentColumn) {
      this.setState({
        parentColumnWidth: resizedCol.actualWidth,
      });
    }
  };

  render() {
    const {
      colDef,
      value,
      getNOfTextRowsToDisplay = defaultGetNOfTextRowsToDisplay,
      fullWidth = false,
      showTooltip = false,
    } = this.props;

    if (!value) {
      return null;
    }

    const { formatValue, labelField } = colDef;
    const colorField = 'color';
    const formattedValue = formatValue ? formatValue(value) : value;

    return (
      <ThemeProvider theme={theme}>
        <Wrapper $nOfTextRowsToDisplay={getNOfTextRowsToDisplay()}>
          {(() => {
            if (typeof formattedValue === 'string') {
              if (this.props.reactContainer) {
                this.props.reactContainer.style.display = 'inline-block';
              }
              return <span>{formattedValue}</span>;
            } else if (formattedValue instanceof Array) {
              return formattedValue.map(valueFormatted => {
                return showTooltip ? (
                  <TooltipOnOverflow
                    text={valueFormatted[labelField]}
                    containerWidth={this.state.parentColumnWidth}
                    elementWithOverflow="span > span"
                  >
                    <MicroChip
                      key={valueFormatted[labelField]}
                      label={valueFormatted[labelField]}
                      color={valueFormatted[colorField]}
                      fullWidth={fullWidth}
                    />
                  </TooltipOnOverflow>
                ) : (
                  <MicroChip
                    key={valueFormatted[labelField]}
                    label={valueFormatted[labelField]}
                    color={valueFormatted[colorField]}
                    fullWidth={fullWidth}
                  />
                );
              });
            }

            return '';
          })()}
        </Wrapper>
      </ThemeProvider>
    );
  }
}
