import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { defaultTo, prop, pipe, isNil, not } from 'ramda';
import isObject from 'lodash/isObject';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

import FluidField from 'design-system/molecules/FluidField/index';
import MultiSelect from 'design-system/atoms/MultiSelect/index';

import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';

const defaultToEmptyArray = defaultTo([]);
const isNotNil = pipe(isNil, not);
const getValue = (idField, value) => prop(idField, value);

// This components requires some refactor as it is receiving options in 2 different ways now
const FluidMultiSelectField = props => (
  <FluidField
    placeholder={TYPE_TO_SEARCH_PLACEHOLDER}
    {...props}
    editorRenderer={({ value = [], onChange, save, cancel, disableEdit, placeholder }) => {
      const { withEllipsis = true } = props;

      const allValues = useMemo(() => props.allValues || props.options, [props.allValues, props.options]);
      const idField = props.idField || 'id';
      const InputComponent = useMemo(() => props.inputComponent || MultiSelect, [props.inputComponent]);
      const rendererValue = useMemo(() => {
        let newValue = value;

        if (!Array.isArray(value) && isNotNil(value)) {
          newValue = [value];
        }

        return newValue;
      }, [value]);

      const inputValue = useMemo(() => {
        if (allValues) {
          return allValues.filter(option => {
            const optionValue = getValue(idField, option);
            const values = defaultToEmptyArray(rendererValue);

            return values.some(v => {
              if (isObject(v)) {
                const valueToCheck = getValue(idField, v);

                return optionValue === valueToCheck;
              }

              return v === optionValue;
            });
          });
        }

        return rendererValue;
      }, [allValues, rendererValue]);

      const _mapValueToSave = useMemo(
        () => props.mapValueToSave || (v => (isObject(v) ? getValue(idField, v) : v)),
        [idField, props.mapValueToSave],
      );

      const mapValues = useCallback(
        values => {
          return values.map(value => {
            if (isObject(value)) {
              return value;
            }

            return defaultToEmptyArray(allValues).find(option => {
              const optionValue = getValue(idField, option);
              const stringifiedValue = String(optionValue);

              return value === stringifiedValue || value === optionValue;
            });
          });
        },
        [allValues, idField],
      );

      return (
        <InputComponent
          hasGroups={props.hasGroups}
          hideCreateOption={props.hideCreateOption}
          options={props.options}
          groupOptions={props.groupOptions}
          optionsMapper={props.optionsMapper}
          withEllipsis={withEllipsis}
          onChange={values => {
            const newValue = mapValues(values);

            onChange(newValue);
            save(newValue.filter(Boolean).map(_mapValueToSave));
          }}
          value={inputValue}
          onCreateOption={option => {
            if (!props.onCreateOption) return;

            props.onCreateOption(option).then(newOption => {
              const newVal = [...rendererValue, newOption];

              onChange(newVal);
              save(newVal.filter(Boolean).map(_mapValueToSave));
            });
          }}
          disabled={disableEdit}
          isDisabled={disableEdit}
          onBlur={cancel}
          hideIcon
          components={{
            ControlComponent: TextFieldNoBorder,
          }}
          {...(props.inputProps || {})}
          placeholder={placeholder}
        />
      );
    }}
  />
);

FluidMultiSelectField.propTypes = {
  ...FluidField.propTypes,
  hasGroups: PropTypes.bool,
  options: PropTypes.array,
  onCreateOption: PropTypes.func,
};

export default FluidMultiSelectField;

const TextFieldNoBorder = styled(TextField)`
  &&&& {
    > div::before {
      border-bottom: 0 !important;
    }

    > div::after {
      border-bottom: 0 !important;
    }
  }
`;
