export default (time) => {
  const weeks = time.match(/(\d+)[w/]/g);
  const days = time.match(/(\d+)[d/]/g);
  const hours = time.match(/(\d+)[h/]/g);
  const minutes = time.match(/(\d+)[m/]/g);
  const seconds = time.match(/(\d+)[s/]/g);
  const getValue = (val) => val && val.join([]).match(/\d+/g).map(Number)[0];
  const values = [
    getValue(weeks),
    getValue(days),
    getValue(hours),
    getValue(minutes),
    getValue(seconds),
  ];
  const times = [52, 5, 8, 60, 60];

  const result = times
    .reduceRight((a, x, index) => {
      const intSum = a.sum;
      const indexVal = values[index] || 0;
      let currentVal = times[times.length - 1];

      for (let i = times.length - 2; i > index; i--) {
        currentVal *= times[i];
      }

      return {
        sum: intSum + (indexVal * currentVal),
      };
    }, {
      sum: 0,
    });

  return result.sum;
};
