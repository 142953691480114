import { BET_LAYER, INITIATIVE_LAYER, IDEA_LAYER } from 'store/projects/constants';

import checkIfGroupShouldOpenForNewItemCreation from './checkIfGroupShouldOpenForNewItemCreation';
import getRowStyle from './getRowStyle';
import loadGridViewColumns from './loadGridViewColumns';
import updateGroupFieldOnDragAndDrop from './updateGroupFieldOnDragAndDrop';

const getRowId = ({ data }) => data.uniqueId;

const getDataPath = data => data.path;

const checkDataIsProject = data => [BET_LAYER, INITIATIVE_LAYER, IDEA_LAYER].includes(data?.layer);

const checkDataIsLayer = (data, layer) => layer === data?.layer;

const checkDataIsOkr = data => data?.okrType;

const checkOpenItemColumnVisibility = ({ data }) => {
  const isProject = checkDataIsProject(data);

  const isOkr = checkDataIsOkr(data);

  return isProject || isOkr;
};

const checkRowSelectable = ({ data }) => {
  return checkDataIsProject(data);
};

const checkCheckboxSelection = ({ data, context }) => {
  return context.isBulkDeleting && checkDataIsProject(data) && !context.hasUnsavedProject;
};

const mapRoadmapsForCellEditor = (roadmaps, mapChildren = false) =>
  roadmaps?.map(roadmap => ({
    ...roadmap,
    label: roadmap.title,
    children: mapChildren ? mapRoadmapsForCellEditor(roadmap.children || []) : [],
  }));

export {
  getRowId,
  getDataPath,
  checkOpenItemColumnVisibility,
  checkRowSelectable,
  checkCheckboxSelection,
  getRowStyle,
  checkDataIsProject,
  checkDataIsOkr,
  checkIfGroupShouldOpenForNewItemCreation,
  checkDataIsLayer,
  loadGridViewColumns,
  updateGroupFieldOnDragAndDrop,
  mapRoadmapsForCellEditor,
};
