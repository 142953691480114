export const FETCH_PRIORITIES = 'FETCH_PRIORITIES';
export const FETCH_PRIORITIES_FULFILLED = 'FETCH_PRIORITIES_FULFILLED';

export const UPDATE_PRIORITY = 'UPDATE_PRIORITY';
export const UPDATE_PRIORITY_FULFILLED = 'UPDATE_PRIORITY_FULFILLED';

export const CREATE_PRIORITY = 'CREATE_PRIORITY';
export const CREATE_PRIORITY_FULFILLED = 'CREATE_PRIORITY_FULFILLED';

export const UPDATE_PRIORITY_BY_ID = 'UPDATE_PRIORITY_BY_ID';
export const UPDATE_PRIORITY_BY_ID_FULFILLED = 'UPDATE_PRIORITY_BY_ID_FULFILLED';

export const ADD_PRIORITY_WITHOUT_SAVE = 'ADD_PRIORITY_WITHOUT_SAVE';
export const REMOVE_UNSAVED_PRIORITIES = 'REMOVE_UNSAVED_PRIORITIES';

export const DELETE_PRIORITY = 'DELETE_PRIORITY';
export const DELETE_PRIORITY_FULFILLED = 'DELETE_PRIORITY_FULFILLED';

export const MERGE_PRIORITIES = 'MERGE_PRIORITIES';
export const MERGE_PRIORITIES_FULFILLED = 'MERGE_PRIORITIES_FULFILLED';

export const CREATE_PRIORITIES = 'CREATE_PRIORITIES';
export const CREATE_PRIORITIES_FULFILLED = 'CREATE_PRIORITIES_FULFILLED';
export const UPDATE_PRIORITIES = 'UPDATE_PRIORITIES';
export const UPDATE_PRIORITIES_FULFILLED = 'UPDATE_PRIORITIES_FULFILLED';

export const SWITCH_PRIORITIES_ROW_ORDER = 'SWITCH_PRIORITIES_ROW_ORDER';
export const SWITCH_PRIORITIES_ROW_ORDER_FULFILLED = 'SWITCH_PRIORITIES_ROW_ORDER_FULFILLED';
export const SWITCH_PRIORITIES_ROW_ORDER_PENDING = 'SWITCH_PRIORITIES_ROW_ORDER_PENDING';
export const SWITCH_PRIORITIES_ROW_ORDER_REJECTED = 'SWITCH_PRIORITIES_ROW_ORDER_REJECTED';

export const CREATE_PRIORITY_ROADMAP = 'CREATE_PRIORITY_ROADMAP';
export const CREATE_PRIORITY_ROADMAP_FULFILLED = 'CREATE_PRIORITY_ROADMAP_FULFILLED';
export const DELETE_PRIORITY_ROADMAP = 'DELETE_PRIORITY_ROADMAP';
export const DELETE_PRIORITY_ROADMAP_FULFILLED = 'DELETE_PRIORITY_ROADMAP_FULFILLED';
export const BULK_DELETE_PRIORITY_ROADMAP = 'BULK_DELETE_PRIORITY_ROADMAP';
export const BULK_DELETE_PRIORITY_ROADMAP_FULFILLED = 'BULK_DELETE_PRIORITY_ROADMAP_FULFILLED';
