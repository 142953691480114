import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import UserPermission from 'containers/UserPermission';
import DeleteAndConfirm from './DeleteAndConfirm';

const Actions = ({ resourceLabel, deleteCustomerRequest, isEditing, deletePermissionChecker, stopEditingCustomerRequest }) => {
  return (
    <Grid container xs={12}>
      {isEditing && (
        <Footer item alignItems="center" xs={12}>
          <UserPermission permissionChecker={deletePermissionChecker}>
            <DeleteAndConfirm label={resourceLabel} onDelete={deleteCustomerRequest} />
          </UserPermission>
          <CloseButton onClick={stopEditingCustomerRequest}>close</CloseButton>
        </Footer>
      )}
    </Grid>
  );
};

export default Actions;

const Footer = styled(Grid)`
  position: sticky;
  width: 100%;
  height: 36px;
  margin-left: 27px !important;
`;

const CloseButton = styled(Button)`
  float: right;
  margin-right: 60px !important;
  color: ${props => props.theme.palette.background.primary} !important;
`;
