import React from 'react';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarTwoToneIcon from '@material-ui/icons/StarTwoTone';
import styled from 'styled-components';

export default (isFavorite, notFavoriteColor = 'inherit') => {
  if (isFavorite) {
    return <StarTwoToneIcon fontSize="inherit" color="primary" />;
  }

  return <StyledStarBorderIcon fontSize="inherit" notFavoriteColor={notFavoriteColor} />;
};

const StyledStarBorderIcon = styled(StarBorderIcon)`
  &&&& {
    color: ${props => props.notFavoriteColor};
  }
`;
