import React from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import startCase from 'lodash/startCase';
import ArrowForward from '@material-ui/icons/ArrowForward';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import TextAreaEditorRender from 'design-system/organisms/TextAreaEditorRender/index';

import getSystemFieldName from 'utils/getSystemFieldName';
import formatDate from 'utils/dates/formatDate';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'src/store/projects/constants';
import { tryCatch, pipe } from 'ramda';
import { convertEditorJsonToHtml } from 'design-system/molecules/RemirrorWYSIWYGEditor/helpers';
import getMetadataTitle, { isCorpPlaceholderTitle } from 'src/store/utils/getMetadataCorpTitleBasedOnRoadmap';

const HistoryItem = props => {
  const getFieldName = () => {
    const exceptions = {
      // objectives: 'Objective',
      planningStage: 'Planning Stage',
    };

    if (exceptions[props.changeField]) return exceptions[props.changeField];
    const fieldName = props.changeField.replace(/[0-9]/g, '');

    return startCase(getSystemFieldName(fieldName, props.systemFields));
  };

  const getMainText = () => {
    let actionString = '';
    let suffixString = '';

    switch (props.changeType) {
      case 'changed':
        actionString = ' changed the ';
        break;
      case 'added':
        actionString = ' added an item to ';
        break;
      case 'removed':
        actionString = ' removed an item from ';
        break;
      case 'created':
        let layer = '';

        switch (props.layer) {
          case IDEA_LAYER:
            if (props.type === 'project') layer = getSystemFieldName('idea', props.systemFields);
            else if (props.type === 'milestone') layer = 'Milestone';
            break;
          case BET_LAYER:
            layer = getSystemFieldName('bet', props.systemFields);
            break;
          case INITIATIVE_LAYER:
            layer = getSystemFieldName('initiative', props.systemFields);
            break;
          default:
            layer = '';
            break;
        }

        actionString = ` created this ${layer}`;
        break;
      default:
        break;
    }

    if (props.changeField === 'rank') {
      if (props.prevValue > props.newValue) {
        suffixString = ' to be lower';
      } else {
        suffixString = ' to be higher';
      }
    }

    let nameToShow;

    if (props.user && props.user.first_name) nameToShow = props.user.first_name;
    if (props.user && props.user.last_name) nameToShow = `${nameToShow} ${props.user.last_name}`;

    if (!nameToShow) nameToShow = 'System';

    return (
      <span>
        <span className={props.classes.relevant}>{nameToShow}</span>
        {actionString}
        {!!props.changeField && <span className={props.classes.relevant}>{getFieldName()}</span>}
        {suffixString}
      </span>
    );
  };

  const formatValue = (field, value, valueObj) => {
    if (props.customFormat && props.customFormat[field]) return props.customFormat[field](value);
    if (!value && value !== false) return null;

    if (typeof value === 'string' || value instanceof String) {
      const testValue = value.replace(/\s/g, '').replace('<p>', '').replace('</p>', '');

      if (!testValue) return null;

      value = value.replace('<p>', '').replace('</p>', '');

      if (valueObj && isCorpPlaceholderTitle(value)) {
        value = getMetadataTitle(valueObj, field, props.orgMetadataForCorpLevels);
      }
    }

    switch (field) {
      case 'jira_progress':
        return value ? 'Yes' : 'No';
      case 'progress':
      case 'activity_progress':
        return `${Math.round(value * 100)}%`;
      case 'details':
      case 'description':
      case 'summary':
      case 'links':
      case 'link':
        const valueToHtml = tryCatch(pipe(JSON.parse, convertEditorJsonToHtml), () => value);

        return <TextAreaEditorRender value={valueToHtml(value)} />;
      case 'notes':
      case 'status_summary':
        return <TextAreaEditorRender value={value} />;
      case 'status_color_auto':
        return value ? 'Auto' : 'Manual';
      default:
        break;
    }

    const momentValue = moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true);

    return momentValue.isValid() ? formatDate(momentValue) : value;
  };

  const getSecondaryText = () => {
    const prevValue = formatValue(props.changeField, props.prevValue, props.prevValueObj);
    const newValue = formatValue(props.changeField, props.newValue, props.newValueObj);
    const value = formatValue(props.changeField, props.value);

    if (props.changeField === 'rank') {
      return null;
    }

    if (props.changeType === 'changed') {
      return (
        <span>
          {prevValue || <span className={props.classes.none}>None</span>}
          <ArrowForward className={props.classes.arrow} />
          {newValue || <span className={props.classes.none}>None</span>}
        </span>
      );
    }

    if (props.changeType === 'error') {
      return <ErrorHistoryEntry> {props.message}</ErrorHistoryEntry>;
    }

    return <span>{value}</span>;
  };

  return (
    <li className={props.classes.item} key={props.key}>
      <Typography>
        <span>{getMainText()}</span>
        <span className={[props.classes.date]}>{moment(props.date).fromNow()}</span>
      </Typography>
      {props.changeType !== 'created' && <Typography className={props.classes.secondaryText}>{getSecondaryText()}</Typography>}
    </li>
  );
};

const styles = theme => ({
  item: {
    paddingBottom: 20,
  },
  secondaryText: {
    fontSize: 13,
    paddingTop: 5,
  },
  date: {
    marginLeft: 5,
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  none: {
    color: theme.palette.text.secondary,
  },
  relevant: {
    fontWeight: 500,
  },
  arrow: {
    height: '0.5em',
    width: '0.5em',
    marginLeft: 5,
    marginRight: 5,
  },
});

const ErrorHistoryEntry = styled.span`
  &&&& {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export default withStyles(styles)(HistoryItem);
