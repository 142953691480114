import reduceReducers from 'reduce-reducers';
import { defaultTo } from 'ramda';
import omit from 'lodash/omit';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import { upsertProjectDeliverableOnStore, deleteProjectDeliverableFromStore } from './helpers';
import { CYCLE_DELIVERABLE_LEVEL } from 'constants/common';
import upsertListItem from 'store/utils/upsertListItem';
import {
  FETCH_CYCLE_DELIVERABLES,
  FETCH_CYCLE_DELIVERABLES_FULFILLED,
  FETCH_PROJECTS_DELIVERABLES,
  FETCH_PROJECTS_DELIVERABLES_FULFILLED,
  UPSERT_PROJECT_DELIVERABLE,
  UPSERT_PROJECT_DELIVERABLE_FULFILLED,
  DELETE_PROJECT_DELIVERABLE,
  DELETE_PROJECT_DELIVERABLE_FULFILLED,
  FETCH_PROJECT_DELIVERABLE,
  FETCH_PROJECT_DELIVERABLE_FULFILLED,
  CREATE_CYCLE,
  CREATE_CYCLE_FULFILLED,
  ADD_CYCLE_WITHOUT_SAVE,
  REMOVE_UNSAVED_CYCLE_DELIVERABLE,
  ADD_DELIVERABLE_WITHOUT_SAVE,
  CREATE_DELIVERABLE,
  CREATE_DELIVERABLE_FULFILLED,
  DELETE_CYCLE_DELIVERABLE,
  DELETE_CYCLE_DELIVERABLE_FULFILLED,
  UPDATE_CYCLE,
  UPDATE_CYCLE_FULFILLED,
  UPDATE_DELIVERABLE,
  UPDATE_DELIVERABLE_FULFILLED,
  UPDATE_CYCLE_DELIVERABLE_ROW_ORDER,
  UPDATE_CYCLE_DELIVERABLE_ROW_ORDER_FULFILLED,
} from './types';
import sortByRank from 'utils/sortByRank';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  FETCH_CYCLE_DELIVERABLES,
  FETCH_PROJECTS_DELIVERABLES,
  UPSERT_PROJECT_DELIVERABLE,
  DELETE_PROJECT_DELIVERABLE,
  FETCH_PROJECT_DELIVERABLE,
  CREATE_CYCLE,
  CREATE_DELIVERABLE,
  DELETE_CYCLE_DELIVERABLE,
  UPDATE_CYCLE,
  UPDATE_DELIVERABLE,
  UPDATE_CYCLE_DELIVERABLE_ROW_ORDER,
]);

const initialState = {
  cycleDeliverables: [],
  projectsDeliverables: {},
  projectDeliverableDetails: {},
  ...thunksInitialState,
};

const defaultToEmptyArray = defaultTo([]);
const defaultToEmptyObject = defaultTo({});

/**
 * @function filterOutUnsavedCycleDeliverables filters out the cycleDeliverable that does not have id (unsaved item)
 * @param  {Array} cycleDeliverables List of cycle deliverables
 * @return {Array} List of cycle deliverables filtered
 */
const filterOutUnsavedCycleDeliverables = cycleDeliverables => cycleDeliverables.filter(cd => !!cd.id);

/**
 * @function mapCyclesWithNewDeliverableChild adds a new deliverable to the children list of the respective cycle parent
 * @param  {Array} cycleDeliverables List of cycle deliverables
 * @param  {Object} newDeliverable   New deliverable created/added
 * @return {Array} List of cycleDeliverables updated with the new deliverable
 */
const mapCyclesWithNewDeliverableChild = (cycleDeliverables, newDeliverable) =>
  cycleDeliverables.map(cycle => {
    if (cycle.id === newDeliverable.parent_id) {
      return {
        ...cycle,
        children: [newDeliverable, ...(cycle.children ?? [])],
      };
    }
    return cycle;
  });

/**
 * @function removeCycleDeliverable
 * @param  {Array} cycleDeliverables List of cycle deliverables
 * @param  {Integer} deletedCycleDeliverableId  Id of the cycle deliverable deleted
 * @param  {Integer} parentIdOfDeletedDeliverable  Parent id if the deleted cycle/deliverable had a parent
 * @param  {Boolean} isDeliverableDeleted  If it was a deliverable that was deleted
 * @return {Array} Updated list of cycle deliverables without the deleted data
 * and the respective parent with its children list also updated
 */
const removeCycleDeliverable = (
  cycleDeliverables,
  deletedCycleDeliverableId,
  parentIdOfDeletedDeliverable,
  isDeliverableDeleted,
) =>
  cycleDeliverables.reduce((acc, cycleDeliverable) => {
    const isDeletedOne = cycleDeliverable.id === deletedCycleDeliverableId;
    const isDeletedOneParent = cycleDeliverable.parent_id === deletedCycleDeliverableId;

    if (isDeletedOne || isDeletedOneParent) {
      return acc;
    }

    let children = cycleDeliverable.children ?? [];

    if (isDeliverableDeleted && cycleDeliverable.id === parentIdOfDeletedDeliverable) {
      // if current cycle is the parent of deleted deliverable, remove child from children array
      children = children.filter(child => child.id !== deletedCycleDeliverableId);
    }

    return [...acc, { ...cycleDeliverable, children }];
  }, []);

const cycleDeliverablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CYCLE_DELIVERABLES_FULFILLED: {
      return {
        ...state,
        cycleDeliverables: action.payload?.data || [],
      };
    }

    case FETCH_PROJECTS_DELIVERABLES_FULFILLED: {
      const projectsDeliverables = action.payload?.data.reduce((acc, item) => {
        return {
          ...acc,
          [item.project_id]: [...(acc[item.project_id] || []), item],
        };
      }, {});

      return {
        ...state,
        projectsDeliverables,
      };
    }

    case UPSERT_PROJECT_DELIVERABLE_FULFILLED: {
      const { project_id: projectId, cycle_deliverable_id: cycleDeliverableId } = action.payload?.data;

      return {
        ...state,
        projectsDeliverables: {
          ...state.projectsDeliverables,
          [projectId]: upsertProjectDeliverableOnStore(state.projectsDeliverables, action.payload?.data),
        },
        projectDeliverableDetails: {
          ...state.projectDeliverableDetails,
          [projectId]: {
            ...defaultToEmptyObject(state.projectDeliverableDetails[projectId]),
            [cycleDeliverableId]: action.payload?.data,
          },
        },
      };
    }

    case DELETE_PROJECT_DELIVERABLE_FULFILLED: {
      const { projectId, cycleDeliverableId } = action.meta;

      return {
        ...state,
        projectsDeliverables: {
          ...state.projectsDeliverables,
          [projectId]: deleteProjectDeliverableFromStore(state.projectsDeliverables, action.meta),
        },
        projectDeliverableDetails: {
          ...state.projectDeliverableDetails,
          [projectId]: omit(defaultToEmptyObject(state.projectDeliverableDetails[projectId]), [cycleDeliverableId]),
        },
      };
    }
    case FETCH_PROJECT_DELIVERABLE_FULFILLED: {
      const { projectId, cycleDeliverableId } = action.meta;
      const { projectDeliverable } = action.payload;

      return {
        ...state,
        projectDeliverableDetails: {
          ...state.projectDeliverableDetails,
          [projectId]: {
            ...defaultToEmptyObject(state.projectDeliverableDetails[projectId]),
            ...(projectDeliverable ? { [cycleDeliverableId]: projectDeliverable } : {}),
          },
        },
      };
    }
    case CREATE_CYCLE_FULFILLED: {
      const cleanedCycleDeliverables = filterOutUnsavedCycleDeliverables(state.cycleDeliverables);

      return {
        ...state,
        cycleDeliverables: [action.payload, ...cleanedCycleDeliverables],
      };
    }
    case CREATE_DELIVERABLE_FULFILLED: {
      const cleanedCycleDeliverables = filterOutUnsavedCycleDeliverables(state.cycleDeliverables);

      return {
        ...state,
        cycleDeliverables: [action.payload, ...mapCyclesWithNewDeliverableChild(cleanedCycleDeliverables, action.payload)],
      };
    }
    case ADD_CYCLE_WITHOUT_SAVE: {
      return {
        ...state,
        cycleDeliverables: [
          {
            level: CYCLE_DELIVERABLE_LEVEL.cycle,
          },
          ...state.cycleDeliverables,
        ],
      };
    }
    case ADD_DELIVERABLE_WITHOUT_SAVE: {
      return {
        ...state,
        cycleDeliverables: [
          {
            ...action.payload,
            level: CYCLE_DELIVERABLE_LEVEL.deliverable,
          },
          ...state.cycleDeliverables,
        ],
      };
    }
    case REMOVE_UNSAVED_CYCLE_DELIVERABLE: {
      return {
        ...state,
        cycleDeliverables: filterOutUnsavedCycleDeliverables(state.cycleDeliverables),
      };
    }

    case DELETE_CYCLE_DELIVERABLE_FULFILLED: {
      const { id: deletedCycleDeliverableId, level, parent_id: parentIdOfDeletedDeliverable } = action.meta;
      const isDeliverableDeleted = level === CYCLE_DELIVERABLE_LEVEL.deliverable;

      const withoutRemovedCycleDeliverable = removeCycleDeliverable(
        state.cycleDeliverables,
        deletedCycleDeliverableId,
        parentIdOfDeletedDeliverable,
        isDeliverableDeleted,
      );

      return {
        ...state,
        cycleDeliverables: withoutRemovedCycleDeliverable,
      };
    }

    case UPDATE_CYCLE_FULFILLED:
    case UPDATE_DELIVERABLE_FULFILLED: {
      const cycleDeliverables = upsertListItem(action.payload, state.cycleDeliverables);
      const parentCycle = state.cycleDeliverables.find(c => c.id === action.payload.parent_id);

      // Its a cycle object should not update children
      if (!parentCycle) {
        return {
          ...state,
          cycleDeliverables,
        };
      }

      const cycleDeliverablesWithParentUpdate = upsertListItem(
        { ...parentCycle, children: upsertListItem(action.payload, defaultToEmptyArray(parentCycle.children)) },
        cycleDeliverables,
      );

      return {
        ...state,
        cycleDeliverables: cycleDeliverablesWithParentUpdate,
      };
    }
    case UPDATE_CYCLE_DELIVERABLE_ROW_ORDER_FULFILLED: {
      const cycleDeliverables = upsertListItem(action.payload, defaultToEmptyArray(state.cycleDeliverables)).sort(sortByRank);
      const newParent = action.payload.parent_id;
      const parentCycle = state.cycleDeliverables.find(c => c.id === newParent);

      // It's a cycle object should not update children
      if (!parentCycle) {
        return {
          ...state,
          cycleDeliverables,
        };
      }

      // add as child of new parent
      const cycleDeliverablesWithParentUpdate = upsertListItem(
        { ...parentCycle, children: upsertListItem(action.payload, defaultToEmptyArray(parentCycle.children)).sort(sortByRank) },
        cycleDeliverables,
      );

      const oldParentId = action?.meta?.prev?.parent_id;
      const oldParentCycle = state.cycleDeliverables.find(c => c.id === oldParentId);

      if (oldParentId === newParent || !oldParentId) {
        return {
          ...state,
          cycleDeliverables: cycleDeliverablesWithParentUpdate,
        };
      }

      // remove itself from old parent
      const cycleDeliverablesWithOldParentUpdate = upsertListItem(
        {
          ...oldParentCycle,
          children: defaultToEmptyArray(oldParentCycle.children).filter(deliverable => deliverable?.id !== action.payload?.id),
        },
        cycleDeliverablesWithParentUpdate,
      );

      return {
        ...state,
        cycleDeliverables: cycleDeliverablesWithOldParentUpdate,
      };
    }

    default: {
      return state;
    }
  }
};

export { initialState };

const reducer = reduceReducers(initialState, cycleDeliverablesReducer, ...thunksReducers);

export default reducer;
