import { loadProjectsByEntity, addMetricToProject, removeMetricFromProject } from 'store/projects/network';
import throwRequestError from 'store/utils/throwRequestError';
import { createThunk } from 'utils/store/thunk';
import { METRIC } from 'constants/common';

import {
  CREATE_PROJECT_FROM_METRIC,
  FETCH_METRIC_LINKED_ITEMS,
  LINK_PROJECT_TO_METRIC,
  UNLINK_PROJECT_FROM_METRIC,
} from './types';
import { getCreateProjectPromise } from 'store/projects';

const isMilestone = type => type === 'milestone';

export const fetchLinkedItems = (metric, params, showCompleted = false) => {
  return createThunk(
    FETCH_METRIC_LINKED_ITEMS,
    loadProjectsByEntity(metric?.id, METRIC, params, showCompleted)
      .then(res => res?.data?.data)
      .catch(throwRequestError),
    {},
  );
};

export const linkProjectToMetric = (project, metric) => {
  return createThunk(
    LINK_PROJECT_TO_METRIC,
    addMetricToProject(project?.id, metric?.id)
      .then(res => res?.data)
      .catch(throwRequestError),
    {},
  );
};

export const unlinkProjectFromMetric = (project, metric) => {
  return createThunk(
    UNLINK_PROJECT_FROM_METRIC,
    removeMetricFromProject(project?.id, metric?.id)
      .then(res => res?.data)
      .catch(throwRequestError),
    {},
  );
};

export const createProjectFromMetric = (project, metricId) => {
  return async dispatch => {
    const createAndLinkPromise = async () => {
      delete project.id;
      project.isNew = true;
      const createdProject = await getCreateProjectPromise(dispatch, project);

      if (isMilestone(createdProject.type)) {
        return {};
      }
      return addMetricToProject(createdProject.id, metricId).then(({ data }) => data);
    };

    return dispatch(createThunk(CREATE_PROJECT_FROM_METRIC, createAndLinkPromise));
  };
};
