import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import withTheme from '@material-ui/core/styles/withTheme';

import DragonHeader from 'containers/NewDragonHeader';
import { setAppGlobalConfig } from 'store/app';
import { PAGE_HEADER_HEIGHT } from 'constants/common';
import {
  COMPARE_SCENARIOS_LIST_PATH,
  COMPARE_SCENARIOS_SWIMLANE_PATH,
  COMPARE_SCENARIOS_CHARTS_PATH,
  COMPARE_SCENARIOS_SUMMARY_PATH,
  ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PATH,
  ROADMAP_HISTORY_COMPARE_SNAPS_SWIMLANE_PATH,
  ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PATH,
} from 'constants/filters/pages';

import Notifications from './Notifications';

const ROUTES_WITH_GO_BACK_HEADER = [
  COMPARE_SCENARIOS_LIST_PATH,
  COMPARE_SCENARIOS_SWIMLANE_PATH,
  COMPARE_SCENARIOS_CHARTS_PATH,
  COMPARE_SCENARIOS_SUMMARY_PATH,
  ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PATH,
  ROADMAP_HISTORY_COMPARE_SNAPS_SWIMLANE_PATH,
  ROADMAP_HISTORY_COMPARE_SNAPS_SUMMARY_PATH,
];

const exist = Boolean;

const NewLayoutComponent = ({
  disableNav,
  user,
  organization,
  selectedTab,
  tabs,
  headerOptions,
  headerFilters,
  headerFiltersProps,
  headerLeftContent,
  history,
  children,
  onLogout,
  theme,
}) => {
  const dispatch = useDispatch();

  const [open, onShow] = useState(!(localStorage.getItem('closeLeftMenu') === 'true'));
  const [notificationsAnchor, setNotificationsAnchor] = useState(null);

  const _onInviteUsers = () => {
    dispatch(setAppGlobalConfig({ showInviteUsersDialog: true }));
  };

  const _onShowHelp = () => {
    const iframe = document.getElementById('launcher');
    const innerDocument = iframe?.contentDocument || iframe?.contentWindow?.document;

    if (!innerDocument) return;

    const button = innerDocument.getElementsByTagName('button');

    if (button.length) button[0].click();
  };

  const _setShowNavMenu = _open => {
    localStorage.setItem('closeLeftMenu', !_open);
    dispatch(setAppGlobalConfig({ leftMenuClosed: !_open }));

    onShow(_open);
  };

  useEffect(() => {
    dispatch(setAppGlobalConfig({ leftMenuClosed: localStorage.getItem('closeLeftMenu') === 'true' }));
  }, []);

  const headerWithGoBack = useMemo(
    () => ROUTES_WITH_GO_BACK_HEADER.includes(history.location.pathname?.replace('/', '')),
    [history.location.pathname],
  );

  return (
    <NewLayoutWrapper id="layout-wrapper">
      <Notifications anchorElem={notificationsAnchor} open={!!notificationsAnchor} onClose={() => setNotificationsAnchor(null)} />

      <InnerWrapper>
        <DragonHeader
          onShow={_setShowNavMenu}
          onShowHelp={_onShowHelp}
          onInviteUsers={_onInviteUsers}
          pageFilters={headerFilters}
          headerFiltersProps={headerFiltersProps}
          pageOptions={headerOptions}
          headerLeftContent={headerLeftContent}
          selectedTab={selectedTab}
          onLogout={onLogout}
          leftMenuOpen={open}
          headerWithGoBack={headerWithGoBack}
          showHideRowsWithSameDataToggle={!!children?.props?.showHideRowsWithSameDataToggle}
        />
        <Content disableNav={disableNav}>{children}</Content>
      </InnerWrapper>
    </NewLayoutWrapper>
  );
};

NewLayoutComponent.propTypes = {
  user: PropTypes.object,
  selectedTab: PropTypes.object,
  tabs: PropTypes.array,
  headerOptions: PropTypes.element,
  headerFilters: PropTypes.element,
  history: PropTypes.object,
  children: PropTypes.element,
  onLogout: PropTypes.func,
};

export default withTheme()(NewLayoutComponent);

const NewLayoutWrapper = styled.div`
  display: flex;
  flex: 1 auto;
`;

const InnerWrapper = styled.div`
  position: relative;
  overflow: hidden;
  flex-flow: column;
  flex: 100%;
`;

const Content = styled.div`
  margin-top: ${PAGE_HEADER_HEIGHT}px;
  height: 100%;
  max-height: calc(100vh - ${PAGE_HEADER_HEIGHT}px);
  overflow-y: auto;

  ${({ disableNav }) =>
    exist(disableNav) &&
    `
    margin-top: 0;
    height: 100vh;
    max-height: 100vh;
  `}
`;
