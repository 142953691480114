import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import GanttContainer from 'containers/GanttTimeline/GanttContainer';
import TimelineTasks from 'containers/GanttTimeline/TimelineTasks';
import TimelineEstimates from 'containers/GanttTimeline/TimelineEstimates';
import { MANAGE_PAGE } from 'constants/filters';
import { draftBackgroundColor } from 'design-system/constants/draft';
import ControlsBar from 'containers/GanttControlsBar';
import ContentDrawer from 'components/ContentDrawer';
import GanttAllocationReport from 'containers/GanttAllocationReport';
import TimelineDependencies from 'containers/GanttTimeline/TimelineDependencies';
import PlanningStageWarningDialog from 'components/PlanningStageWarningDialog';
import getHasPlanningStageGroup from 'utils/planningStageGroups/getHasPlanningStageGroup';

const drawerContentStyles = css`
  margin-top: 0;
`;

const Manage = ({
  gantt,
  tasks,
  projects,
  allProjects,
  lsState,
  compState,
  setCompState,
  updateLsState,
  ganttRef,
  portfolioMode,
  showPageConfigForReadOnly,
  viewsDropdownTabs,
}) => {
  const {
    localMode,
    checkedColorLegend,
    showTasks,
    showEstimates,
    showAllocationReport,
    hideDependencyLines,
    isDialogVisible = false,
    selectedGroup1,
    selectedGroup2,
  } = lsState;
  const path = window.location.pathname;
  const hideFilters = path.includes('/ideas');
  const hasPlanningStageGroup = useMemo(() => {
    return getHasPlanningStageGroup(selectedGroup1, selectedGroup2);
  }, [selectedGroup1, selectedGroup2]);

  const controls = (
    <ControlsBar
      localState={compState}
      updateLocalState={setCompState}
      pageId={MANAGE_PAGE}
      lsState={lsState}
      updateLsState={updateLsState}
      projects={portfolioMode ? allProjects : projects}
      showGroupBy2
      showPortfolioMode
      showSyncIntegration
      showDependenciesButton
      disableLocalSearch={hideFilters}
      viewsDropdownTabs={viewsDropdownTabs}
      hasShowMyItemFilter
    />
  );

  return (
    <Wrapper className="forecastGantt" useDraftStyle={localMode}>
      {showPageConfigForReadOnly && controls}
      <GanttContainer
        height={`calc(100vh - 150px ${checkedColorLegend ? '- 60px' : ''})`}
        ganttRef={ganttRef}
        gantt={gantt}
        pageControlsVisible={showPageConfigForReadOnly}
      />
      <TimelineTasks
        gantt={gantt}
        localMode={localMode}
        addOnlyTasks={showTasks && !showEstimates}
        addBasedOnPlanningStage={showTasks && showEstimates}
        hasPlanningStageGroup={hasPlanningStageGroup}
        showPhaseWarningDialog={() => updateLsState({ isDialogVisible: true })}
      />
      <TimelineEstimates
        gantt={gantt}
        localMode={localMode}
        addOnlyEstimates={showEstimates && !showTasks}
        addBasedOnPlanningStage={showTasks && showEstimates}
      />

      {showAllocationReport && (
        <ContentDrawer
          open={showAllocationReport}
          onClose={() => updateLsState({ showAllocationReport: false })}
          contentStyles={drawerContentStyles}
          content={
            <GanttAllocationReport
              projects={projects}
              updateLsState={state => updateLsState({ allocationReport: { ...(lsState.allocationReport || {}), ...state } })}
              lsState={lsState.allocationReport || {}}
            />
          }
        />
      )}
      {hideDependencyLines && <TimelineDependencies gantt={gantt} data={tasks} />}
      <PlanningStageWarningDialog isOpen={isDialogVisible} onConfirm={() => updateLsState({ isDialogVisible: false })} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  ${({ useDraftStyle }) => (useDraftStyle ? `background-color: ${draftBackgroundColor}` : '')};
`;

export default Manage;
