import React from 'react';

import History from 'components/History/History';
import { getOkrHealthLabel } from 'utils/okrs/healthUtils';

import useProjectDeliverableHistory from './useProjectDeliverableHistory';

const ProjectCycleDeliverableHistory = ({ projectDeliverableId, cycleDeliverable }) => {
  const { projectDeliverableHistory, hasNext, systemFields, fetchNextPage } = useProjectDeliverableHistory({
    projectDeliverableId,
  });

  const customFormat = {
    status: value => cycleDeliverable?.status?.[value]?.title ?? value,
    health: value => getOkrHealthLabel(value) ?? value,
  };

  return (
    <History
      objects={projectDeliverableHistory}
      hasNext={hasNext}
      systemFields={systemFields}
      customFormat={customFormat}
      fetchNextPage={fetchNextPage}
    />
  );
};

export default ProjectCycleDeliverableHistory;
