import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import Grid from '@material-ui/core/Grid';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FormGroup from '@material-ui/core/FormGroup';
import FormatLineSpacingIcon from '@material-ui/icons/FormatLineSpacing';

import { ROADMAP_PAGE } from 'constants/filters';
import { JIRA_PROJECT_RENAMED_WARNING } from 'constants/integrations';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import Dropdown from 'design-system/molecules/Dropdown/index';
import ReadMoreIcon from 'design-system/atoms/ReadMoreIcon/index';
import ToggleButton from 'design-system/molecules/ToggleButton/index';

import ShowItemsWithoutDatesButton from '../../components/ShowItemsWithoutDatesButton';

import ShareView from 'containers/ShareView';

import { setAppGlobalConfig } from 'store/app';

import useOrganizations from 'hooks/useOrganizations';
import useTimelines from 'hooks/useTimelines';

import getJiraIntegrationWarnings from 'utils/getJiraIntegrationWarnings';

import { columnWidths } from 'utils/swimlane/new/columnWidths';

import useMoreDropdownOptions from './hooks/useMoreDropdownOptions';

import PreferencesDialog from './components/PreferencesDialog';
import ShowColorLegend from './components/PreferencesDialog/ColorLegend';
import GroupByList from './components/GroupByList';
import ZoomButton from './components/ZoomButton';

import useScenarioToolbarControls from 'containers/ScenarioToolbarControls/hooks/useScenarioToolbarControls';
import useRoadmapVersions from 'hooks/useRoadmapVersions';
import useScenariosNavigation from 'hooks/useScenariosNavigation';
import { CreateRoadmapSnapshotButton } from 'features/RoadmapHistory/components';

const ControlsBar = ({ viewsDropdownTabs, hasRoadmapHistory }) => {
  const dispatch = useDispatch();

  const [openPreferences, setOpenPreferences] = useState(false);

  const handleOpenPreferences = useCallback(() => setOpenPreferences(true), []);
  const handleClosePreferences = useCallback(() => setOpenPreferences(false), []);

  const { jiraIntegrations, hasRenameJiraProject } = useOrganizations();
  const warning = hasRenameJiraProject && getJiraIntegrationWarnings(jiraIntegrations, JIRA_PROJECT_RENAMED_WARNING);

  const handleWarningClick = () => {
    dispatch(setAppGlobalConfig({ showUpdateJiraKeyDialog: true }));
  };

  const {
    lsState: { selectedColorBy, showLegend, selectedZoom, showTextOverflow },
    selectedGroupOptions,
    groupByOptions,
    changeSelectedZoom,
    changeSelectedGroupOption,
    toggleShowTextOverflow,
    changeSlotWidth,
  } = useTimelines(ROADMAP_PAGE);

  const currentUser = useSelector(state => state.login.currentUser);

  const { isCreatingOrViewingScenario, onClickExitDraftMode } = useScenarioToolbarControls();
  const { isRoadmapVersionCommitted } = useRoadmapVersions();

  const { navigateToScenarioModule } = useScenariosNavigation();

  const toggleDraftMode = useCallback(() => {
    const localMode = isCreatingOrViewingScenario;

    if (!localMode) {
      navigateToScenarioModule();
    } else {
      onClickExitDraftMode();
    }
  }, [isCreatingOrViewingScenario, currentUser]);

  const moreDropdownOptions = useMoreDropdownOptions({ handleOpenPreferences, isCreatingOrViewingScenario, toggleDraftMode });

  return (
    <>
      {openPreferences && <PreferencesDialog onClose={handleClosePreferences} />}

      <GridWrapper container spacing={8}>
        <Grid item>
          <GroupingFormGroup row>
            {!isEmpty(selectedGroupOptions) && (
              <GroupByList
                selectedGroupOptions={selectedGroupOptions}
                groupByOptions={groupByOptions}
                changeSelectedGroupOption={changeSelectedGroupOption}
              />
            )}
          </GroupingFormGroup>
        </Grid>

        {/* Jira integration warning */}
        {warning && (
          <WarningMsg>
            <a role="link" tabIndex={0} onClick={handleWarningClick}>
              {warning.message}
            </a>
          </WarningMsg>
        )}

        <RightGrid>
          <RightFormGroup row>
            {hasRoadmapHistory && <CreateRoadmapSnapshotButton WrapperComponent={IconWrapper} />}
            {/* View */}
            {!isCreatingOrViewingScenario && (
              <ShareView pageId={ROADMAP_PAGE} viewsDropdownTabs={viewsDropdownTabs} id="view-icon" />
            )}
            {/* Items Without Dates */}
            <IconWrapper>
              <ShowItemsWithoutDatesButton />
            </IconWrapper>

            {/* Text overflow */}
            <IconWrapper>
              <ToggleButton
                title="Show Text Overflow"
                on={showTextOverflow}
                onChange={toggleShowTextOverflow}
                icon={<ReadMoreIcon id="text-overflow-icon" />}
              />
            </IconWrapper>

            {/* Column width */}
            <IconWrapper>
              <Dropdown
                label="Col width"
                options={columnWidths}
                onChange={changeSlotWidth}
                isButton={false}
                placeholder={
                  <RotatedButtonIcon title="Col width" data-cy="grid-col-width">
                    <FormatLineSpacingIcon />
                  </RotatedButtonIcon>
                }
              />
            </IconWrapper>

            {/* Zoom */}
            <IconWrapper>
              <ZoomButton selectedZoom={selectedZoom} changeSelectedZoom={changeSelectedZoom} />
            </IconWrapper>

            {/* More options */}
            {!isRoadmapVersionCommitted && (
              <IconWrapper>
                <Dropdown
                  placeholder={
                    <ButtonIcon>
                      <MoreHorizIcon />
                    </ButtonIcon>
                  }
                  options={moreDropdownOptions}
                  isButton={false}
                  inline
                />
              </IconWrapper>
            )}
          </RightFormGroup>
        </RightGrid>

        {showLegend && (
          <ColorLegend>
            <ShowColorLegend colorBy={selectedColorBy} />
          </ColorLegend>
        )}
      </GridWrapper>
    </>
  );
};

export default ControlsBar;

const GridWrapper = styled(Grid)`
  &&& {
    padding: 8px 18px 8px 40px;
    max-width: 100vw;
    position: relative;
    z-index: 1;
    justify-content: space-between;
  }
`;

const ColorLegend = styled.div`
  border: 1px solid #efefef;
  border-radius: 5px;
  margin: 0 22px 4px 8px;
  overflow-y: auto;
  width: 100%;
  max-height: 66px;
  padding: 8px;
`;

const WarningMsg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: red;
    cursor: pointer;
  }
`;

const RotatedButtonIcon = styled(ButtonIcon)`
  transform: rotate(90deg);
`;

const GroupingFormGroup = styled(FormGroup)`
  margin-top: 6px;
`;

const RightGrid = styled(Grid)`
  text-align: right;
`;

const RightFormGroup = styled(FormGroup)`
  justify-content: flex-end;
`;

const IconWrapper = styled.div`
  width: 48px;
`;
