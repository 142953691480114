import { useMemo, useState, useEffect } from 'react';
import useSystemFields from 'hooks/useSystemFields';
import { useSelector } from 'react-redux';
import { pipe, equals, not, keys } from 'ramda';

import { getProjectsCustomFields } from 'store/customFields/selectors';

import useCustomUserFields from 'hooks/useCustomUserFields';

import { customerUserFieldKeys, getCustomUserFieldKeyFromSystemFieldName } from 'utils/customUserFields/customUserFields';
import useProjectFieldsConfiguration from 'hooks/configuration/useProjectFieldsConfiguration';
import { ALLOWED_PROJECT_FIELDS_TO_INHERIT, ALLOWED_PROJECT_FIELDS_TO_BE_REQUIRED } from 'constants/projectLightbox';
import { getOrgHasProjectInheritFromParent } from 'store/organization';
import { BET_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';

import {
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_PRODUCTS_2,
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
  PERSONAS,
  LIFECYCLES,
  RESOURCE_TEAM,
} from 'constants/common';
import { cleanRequiredInvalidFields } from './helpers';

const isNotEquals = pipe(equals, not);
const isBetOrInitiativeLayer = layer => [BET_LAYER, INITIATIVE_LAYER].includes(layer);

const getFieldsToLabelsMapper = (getSystemFieldName, customFields) => ({
  title: 'Title',
  watchers: 'Watchers',
  links: 'Links',
  details: 'Details',
  integrations: 'Integrations',
  files: 'Files',
  timeline: 'Target Timeline',
  customers: getSystemFieldName('customer'),
  tags: getSystemFieldName('tag'),
  customFields: 'Custom Fields',
  statusSummary: 'Status summary',
  owner: 'Owner',
  priority: getSystemFieldName('priority'),
  parent: 'Parent',
  roadmaps: getSystemFieldName('roadmap'),
  [ADDITIONAL_ROADMAPS]: `Additional ${getSystemFieldName('roadmap', true)}`,
  [ADDITIONAL_PRODUCTS]: `Additional ${getSystemFieldName('product1', true)}`,
  [ADDITIONAL_PRODUCTS_2]: `Additional ${getSystemFieldName('product2', true)}`,
  products: getSystemFieldName('product1'),
  products2: getSystemFieldName('product2'),
  themes: getSystemFieldName('theme'),
  categories: getSystemFieldName('category'),
  timeframes: getSystemFieldName('timeframe'),
  [ADDITIONAL_TIMEFRAMES]: `Additional ${getSystemFieldName('timeframe', true)}`,
  [ADDITIONAL_TIMEFRAMES_2]: `Additional ${getSystemFieldName('timeframe2', true)}`,
  phases: getSystemFieldName('phase'),
  health: 'Health',
  progress: 'Progress',
  allObjectives: getSystemFieldName('objective'),
  [ADDITIONAL_OBJECTIVES]: `Additional ${getSystemFieldName('objective', true)}`,
  [ADDITIONAL_KEY_RESULTS]: `Additional ${getSystemFieldName('keyResult1', true)}`,
  [ADDITIONAL_KEY_RESULTS_2]: `Additional ${getSystemFieldName('keyResult2', true)}`,
  ...customFields.reduce((items, field) => ({ ...items, [field.key]: field.title }), {}),
  // Custom User Fields
  user1: getSystemFieldName('user1'),
  user2: getSystemFieldName('user2'),
  user3: getSystemFieldName('user3'),
  user4: getSystemFieldName('user4'),
  user5: getSystemFieldName('user5'),
  user6: getSystemFieldName('user6'),
  user7: getSystemFieldName('user7'),
  metrics: getSystemFieldName('metric', true),
  customersCount: '# of Customers',
  totalRevenue: 'Total value',
  activeRevenue: 'Active value',
  inactiveRevenue: 'Inactive value',
  externalKey: 'External Key',
  externalParentKey: 'External Parent Key',
  [ADDITIONAL_THEMES]: `Additional ${getSystemFieldName('theme', true)}`,
  [ADDITIONAL_CATEGORIES]: `Additional ${getSystemFieldName('category', true)}`,
  [ADDITIONAL_TEAMS]: `All ${getSystemFieldName('team', true)}`,
  [ADDITIONAL_TEAMS_2]: `All ${getSystemFieldName('team2', true)}`,
  [PERSONAS]: getSystemFieldName('persona', true),
  [LIFECYCLES]: getSystemFieldName('lifecycle', true),
  completed_date: 'Completed Date',
  [RESOURCE_TEAM]: getSystemFieldName('team'),
});

const parseFieldLayoutProperty = (key, fieldsLayout, mapKeysToLabels, isVisible) => {
  if (fieldsLayout[key]) {
    return {
      [key]: {
        id: key,
        items: [
          ...fieldsLayout[key].map(key => ({
            id: key,
            label: mapKeysToLabels[key],
            avoidRender: isVisible(key),
          })),
        ],
      },
    };
  }

  return {};
};

const parseFieldsLayoutToDnDListFormat = (fieldsLayout, mapKeysToLabels, isVisible) => ({
  ...parseFieldLayoutProperty('leftFields', fieldsLayout, mapKeysToLabels, isVisible),
  ...parseFieldLayoutProperty('rightFields', fieldsLayout, mapKeysToLabels, isVisible),
  ...parseFieldLayoutProperty('hiddenFields', fieldsLayout, mapKeysToLabels, isVisible),
});

const parseFieldsLayoutFromDndListFormat = fieldsLayout => ({
  ...Object.assign({}, ...Object.keys(fieldsLayout).map(key => ({ [key]: fieldsLayout[key]?.items?.map(item => item.id) }))),
});

const cleanHiddenFieldsOnObject = (hiddenFields, dataObj) => {
  return Object.keys(dataObj).reduce((acc, objKey) => {
    if (hiddenFields.includes(objKey)) {
      return acc;
    }

    return {
      ...acc,
      [objKey]: dataObj[objKey],
    };
  }, {});
};

const filterYesOrNoCustomFields = cf => cf?.field_type !== TYPES_OF_CUSTOM_FIELDS.YES_NO;

/**
 * @function useProjectFieldsConfigurationForConfigurator
 *
 * Use project fields configuration with adpated data and
 * methods for Project Layout Configurator component
 *
 * @param  {String} layer
 * @return {Object}
 */
const useProjectFieldsConfigurationForConfigurator = (layer, oldLightboxActive) => {
  const [updatedConfiguration, setUpdatedConfiguration] = useState({ fieldsLayout: {}, fieldsInherit: {}, fieldsRequired: {} });

  const [getSystemFieldName] = useSystemFields();
  const customFields = useSelector(getProjectsCustomFields);
  const inheritProjectFieldsEnabled = useSelector(getOrgHasProjectInheritFromParent);
  const { getCustomUserFieldByFieldKey } = useCustomUserFields();
  const { projectsFieldsLayout, projectsFieldsInherit, projectsFieldsRequired, updateConfiguration } =
    useProjectFieldsConfiguration(layer, oldLightboxActive);

  useEffect(() => {
    setUpdatedConfiguration(cur => ({ ...cur, fieldsLayout: projectsFieldsLayout }));
  }, [projectsFieldsLayout]);

  useEffect(() => {
    setUpdatedConfiguration(cur => ({ ...cur, fieldsInherit: projectsFieldsInherit }));
  }, [projectsFieldsInherit]);

  useEffect(() => {
    setUpdatedConfiguration(cur => ({ ...cur, fieldsRequired: projectsFieldsRequired }));
  }, [projectsFieldsRequired]);

  const validateIfVisible = key => {
    if (customerUserFieldKeys.includes(key)) return !getCustomUserFieldByFieldKey(getCustomUserFieldKeyFromSystemFieldName(key));
    return false;
  };
  const mapKeysToLabels = useMemo(
    () => getFieldsToLabelsMapper(getSystemFieldName, customFields),
    [getSystemFieldName, customFields],
  );

  const handleSaveConfiguration = () => {
    const updateObject = {
      ...updatedConfiguration.fieldsLayout,
    };
    const updatedConfigurationFieldsInherit = updatedConfiguration.fieldsInherit;
    const updatedConfigurationFieldsRequired = cleanHiddenFieldsOnObject(
      updateObject.hiddenFields,
      updatedConfiguration.fieldsRequired,
    );

    if (isNotEquals(updatedConfigurationFieldsInherit, projectsFieldsInherit)) {
      updateObject.inherit = updatedConfigurationFieldsInherit;
    }

    if (isNotEquals(updatedConfigurationFieldsRequired, projectsFieldsRequired)) {
      const validFieldsKeys = keys(mapKeysToLabels);

      updateObject.required = cleanRequiredInvalidFields(updatedConfigurationFieldsRequired, validFieldsKeys);
    }

    updateConfiguration(layer, updateObject);
  };

  const handleChangeConfiguration = data => {
    setUpdatedConfiguration(cur => ({
      ...cur,
      ...data,
      ...(data.fieldsLayout ? { fieldsLayout: parseFieldsLayoutFromDndListFormat(data.fieldsLayout) } : {}),
    }));
  };

  const projectsFieldsLayoutForDndList = useMemo(
    () => parseFieldsLayoutToDnDListFormat(updatedConfiguration.fieldsLayout, mapKeysToLabels, validateIfVisible),
    [updatedConfiguration.fieldsLayout, mapKeysToLabels, validateIfVisible],
  );

  const allAllowedFieldsToInherit = useMemo(
    () => [...ALLOWED_PROJECT_FIELDS_TO_INHERIT, ...customFields.map(cf => cf.key)],
    [customFields],
  );
  const allAllowedFieldsToRequired = useMemo(
    () => [...ALLOWED_PROJECT_FIELDS_TO_BE_REQUIRED, ...customFields.filter(filterYesOrNoCustomFields).map(cf => cf.key)],
    [customFields],
  );

  const allowFieldToInherit = fieldId =>
    inheritProjectFieldsEnabled && isBetOrInitiativeLayer(layer) && allAllowedFieldsToInherit.includes(fieldId);
  const enableRequiredFields = inheritProjectFieldsEnabled;

  const allowFieldToBeRequired = fieldId => allAllowedFieldsToRequired.includes(fieldId);

  return {
    configuration: {
      fieldsLayout: projectsFieldsLayoutForDndList,
      fieldsInherit: updatedConfiguration.fieldsInherit,
      fieldsRequired: updatedConfiguration.fieldsRequired,
    },
    enableRequiredFields,

    handleChangeConfiguration,
    handleSaveConfiguration,
    allowFieldToInherit,
    allowFieldToBeRequired,
  };
};

export default useProjectFieldsConfigurationForConfigurator;
