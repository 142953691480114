/* eslint-disable */
import React from 'react';

export default ({ fill = '#88929B', width = '22', height = '24', ...props }) => (
  <svg width={width} height={height} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.0833 5.18347L11.9166 5.18347V2.43347L10.0833 2.43347V5.18347Z" fill="#" />
    <path d="M17.4166 12.5167H20.1666V10.6834H17.4166V12.5167Z" fill={fill} />
    <path d="M1.83337 12.5167H4.58337L4.58337 10.6834H1.83337L1.83337 12.5167Z" fill={fill} />
    <path d="M6.14078 7.57235L7.43066 6.28247L5.48612 4.33793L4.19624 5.62781L6.14078 7.57235Z" fill={fill} />
    <path d="M15.8534 7.57821L17.798 5.63367L16.5081 4.34379L14.5635 6.28833L15.8534 7.57821Z" fill={fill} />
    <path
      d="M8.25004 15.2484V19.8501L13.75 19.8501L13.75 15.2484C14.5196 14.6713 15.0881 13.8666 15.3749 12.9484C15.6617 12.0302 15.6524 11.045 15.3482 10.1324C15.044 9.21979 14.4603 8.42604 13.68 7.86357C12.8996 7.3011 11.962 6.99843 11 6.99843C10.0381 6.99843 9.1005 7.3011 8.32012 7.86357C7.53973 8.42604 6.95611 9.21979 6.65191 10.1324C6.34771 11.045 6.33836 12.0302 6.62519 12.9484C6.91201 13.8666 7.48047 14.6713 8.25004 15.2484V15.2484ZM11.9167 18.0168H10.0834V16.0918C10.6884 16.214 11.3117 16.214 11.9167 16.0918V18.0168ZM11 8.8501C11.5439 8.8501 12.0756 9.01138 12.5279 9.31356C12.9801 9.61573 13.3326 10.0452 13.5407 10.5477C13.7489 11.0502 13.8033 11.6031 13.6972 12.1366C13.5911 12.67 13.3292 13.16 12.9446 13.5446C12.56 13.9292 12.07 14.1911 11.5365 14.2973C11.0031 14.4034 10.4502 14.3489 9.94766 14.1408C9.44516 13.9326 9.01567 13.5802 8.7135 13.1279C8.41133 12.6757 8.25004 12.144 8.25004 11.6001C8.25222 10.8714 8.54265 10.1732 9.0579 9.65796C9.57315 9.1427 10.2714 8.85227 11 8.8501V8.8501Z"
      fill={fill}
    />
  </svg>
);
