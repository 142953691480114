import React from 'react';
import styled, { css } from 'styled-components';

import theme from 'design-system/theme';
import Tabs from 'design-system/organisms/Tabs/index';
import InlineDateRange from 'design-system/organisms/InlineDateRange/InlineDateRange';

import SwimlaneSelectedVersionsColors from 'routes/RoadmapVersions/components/SwimlaneSelectedVersionsColors';
import useScenarioVersionColors from 'routes/RoadmapVersions/hooks/useScenarioVersionColors';
import useRoadmapVersions from 'hooks/useRoadmapVersions';

const tabStyles = css`
  && {
    color: ${({ isSelectedTab, theme }) =>
      isSelectedTab ? theme.palette.newLayout.background.dodgerBlue : theme.palette.text.primary};
    font-weight: ${({ isSelectedTab, theme }) =>
      isSelectedTab ? theme.typography.fontWeightBold : theme.typography.fontWeightMedium};
    font-size: ${({ theme }) => theme.typography.fontSizeSmall}px;
    min-height: 30px;
  }
`;

const selectedTabBorderStyles = {
  background: theme.palette.newLayout.background.dodgerBlue,
  height: '2px',
};

const Toolbar = ({ pageTabs, currentPage, onChangePage, oneClickRoadmapVersion, dateInterval, onDateIntervalChange }) => {
  const { allRoadmapVersions } = useRoadmapVersions();
  const { scenarioColors } = useScenarioVersionColors();

  return (
    <Container>
      <StyledTabs
        tabs={pageTabs}
        selectedTab={currentPage}
        handleTabChange={(e, val) => onChangePage(val)}
        tabStyles={tabStyles}
        TabIndicatorProps={{ style: selectedTabBorderStyles }}
      />
      <StyledSwimlaneSelectedVersionsColors
        scenarioColors={scenarioColors}
        roadmapVersions={allRoadmapVersions}
        oneClickRoadmapVersion={oneClickRoadmapVersion}
      />
      <DateRangeContainer>
        {dateInterval ? (
          <InlineDateRange
            startDate={dateInterval.startDate}
            endDate={dateInterval.endDate}
            useLocalTimezone
            onChange={onDateIntervalChange}
          />
        ) : null}
      </DateRangeContainer>
    </Container>
  );
};

export default Toolbar;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.unit}px 0 ${theme.spacing.unit}px ${theme.spacing.unit * 3}px`};
  background: ${({ theme }) => theme.palette.newLayout.background.white};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.newLayout.background.iron}`};
  z-index: ${({ theme }) => theme.zIndex.highest};
`;

const StyledTabs = styled(Tabs)`
  && {
    min-height: 30px;
  }
`;

const StyledSwimlaneSelectedVersionsColors = styled(SwimlaneSelectedVersionsColors)`
  margin: 0 auto;
`;

const DateRangeContainer = styled.div`
  justify-self: flex-end;
  min-height: 44px;
`;
