import makeFieldKeyForRoadmapVersionProjectField from './makeFieldKeyForRoadmapVersionProjectField';

const isFieldSelected = (visibleColumnsIds, field) => visibleColumnsIds.some(visibleId => field?.includes(visibleId));

/**
 * @function getRoadmapVisibleFieldsIds
 * @return {type} {description}
 */
const getRoadmapVisibleFieldsIds = (
  visibleColumnsIds = [],
  selectedRoadmapVersionsToCompare = [],
  roadmapVersionsFields = [],
) => {
  let roadmapFieldsVisible = [];
  let roadmapFieldsHidden = [];

  selectedRoadmapVersionsToCompare.forEach(roadmapVersion => {
    roadmapVersionsFields.forEach(roadmapVersionField => {
      const roadmapFieldColumnId = makeFieldKeyForRoadmapVersionProjectField(roadmapVersion.id, roadmapVersionField.key);

      if (isFieldSelected(visibleColumnsIds, roadmapVersionField.key)) {
        roadmapFieldsVisible = [...roadmapFieldsVisible, roadmapFieldColumnId];
      } else {
        roadmapFieldsHidden = [...roadmapFieldsHidden, roadmapFieldColumnId];
      }
    });
  });

  return {
    roadmapFieldsVisible,
    roadmapFieldsHidden,
  };
};

export default getRoadmapVisibleFieldsIds;
