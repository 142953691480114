import forEach from 'lodash/forEach';
import { defaultTo, uniqBy } from 'ramda';

import { statusColors, orderedPlanningStages } from 'utils';
import {
  KEY_RESULTS_1,
  KEY_RESULTS_2,
  KEY_RESULT_LEVEL,
  PRODUCTS,
  PRODUCTS_2,
  PRODUCT_LEVEL,
  TIMEFRAMES,
  TIMEFRAMES_2,
  TIMEFRAME_LEVEL,
  INITIATIVES,
  BETS,
  STATUS_COLORS,
  PLANNING_STAGES,
  METADATA_LEVELS,
  UNDEFINED,
  KEY_RESULTS,
  OBJECTIVES,
  ROADMAPS,
} from 'constants/common';
import { INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';

const defaultToEmptyArray = defaultTo([]);

const customFieldPrefix = 'custom_fields';
const uniqById = uniqBy(p => p.id);

const mergeArraysWithoutDuplicates = (arr1, arr2) => [...new Set([...arr1, ...arr2])];

const mapStatusColors = statusColors => statusColors.map(sc => ({ id: sc.value }));

const filterStatusColorsByProjects = (statusColors, projects) => {
  return statusColors.filter(sc => projects.some(p => p?.status_color === sc.value || p?.health_calculated === sc.value));
};

export const mergeMetadata = (metadata, userAddedMetadata = {}) => {
  if (!metadata && !userAddedMetadata) return {};
  if (!metadata) return userAddedMetadata;
  if (!userAddedMetadata) return metadata;

  const baseObj = { ...metadata };
  const allKeys = mergeArraysWithoutDuplicates(Object.keys(metadata), Object.keys(userAddedMetadata));

  const newObj = allKeys.reduce(
    (newMetadataByKey, key) => {
      return {
        ...newMetadataByKey,
        [key]: defaultToEmptyArray(userAddedMetadata[key]).reduce((acc, item) => {
          if (!acc.find(i => i.id === item.id)) {
            return [...acc, item];
          }

          return acc;
        }, uniqById(defaultToEmptyArray(baseObj[key]))),
      };
    },
    allKeys.reduce((newMetadataByKey, key) => ({ ...newMetadataByKey, [key]: [] }), {}),
  );

  return newObj;
};

export const removeUserRemovedMetadata = (metadata, userRemovedMetadata) => {
  const baseObj = { ...metadata };

  Object.keys(baseObj).forEach(key => {
    baseObj[key] = baseObj[key]?.filter(object => !userRemovedMetadata[key]?.includes(object?.id));
  });

  return baseObj;
};

export const getMetadataIds = metadata =>
  Object.keys(metadata || {}).reduce((result, key) => {
    result[key] = metadata[key]?.filter(item => !!item.id).map(item => item.id);
    return result;
  }, {});

/**
 * Retrieves custom field options based on the provided projects and user-added metadata IDs.
 *
 * @param {Array} projects - The array of projects.
 * @param {Object} userAddedMetadataIds - The object containing user-added metadata IDs.
 * @param {Array} customFields - The array of custom fields.
 * @returns {Object} - The object containing custom field options.
 */
export const getCustomFieldOptions = (projects, userAddedMetadataIds, customFields = []) => {
  const getCustomField = key => customFields?.find(cf => cf?.key === key);

  const getCustomFieldOption = (customFieldKey, optionKey) => {
    return getCustomField(customFieldKey)?.sortedValidOptions.find(option => option.key === optionKey);
  };

  const formatCustomFieldOptionAsRegularMetadataObject = option => ({ id: option.key, title: option.title, color: option.color });

  const userAddedMetadataObjects = Object.entries(userAddedMetadataIds).reduce((acc, [metadataType, metadataArray = []]) => {
    const [beforeDot, afterDot] = metadataType.split('.');

    if (beforeDot === customFieldPrefix) {
      const customField = getCustomField(afterDot);

      if (!customField) return acc;

      return {
        ...acc,
        [metadataType]: metadataArray
          .map(optionKey => {
            if (optionKey === UNDEFINED) return null;

            const customFieldOption = getCustomFieldOption(afterDot, optionKey);

            return customFieldOption
              ? formatCustomFieldOptionAsRegularMetadataObject(getCustomFieldOption(afterDot, optionKey))
              : null;
          })
          .filter(Boolean),
      };
    }

    return acc;
  }, {});

  return projects?.reduce((acc, project) => {
    forEach(project?.custom_fields, (value, key) => {
      if (value && getCustomField(key)) {
        const prefixedKey = `${customFieldPrefix}.${key}`;

        acc[prefixedKey] = acc[prefixedKey] || [];

        const customFieldOption = getCustomFieldOption(key, value);

        if (!acc[prefixedKey].includes(value) && customFieldOption) {
          acc[prefixedKey].push(formatCustomFieldOptionAsRegularMetadataObject(customFieldOption));
        }
      }
    });
    return acc;
  }, userAddedMetadataObjects);
};

export const filterPlanningStagesByAvailablePhases = (planningStages = [], phases = []) =>
  planningStages.filter(stage => phases.some(phase => phase.planning_stage === stage.id));

/**
 * Moves user-added metadata to the end of each metadata array.
 *
 * @param {Object} metadata - The metadata object containing arrays of metadata.
 * @param {Object} userAddedMetadataIds - The object containing user-added metadata IDs for each metadata type.
 * @returns {Object} - The updated metadata object with user-added metadata moved to the end of each array.
 */
export const moveUserAddedMetadataToTheEnd = (metadata, userAddedMetadataIds) => {
  const moveEach = (metadataArray, metadataType) => {
    const otherMetadata = metadataArray.filter(
      metadata => !defaultToEmptyArray(userAddedMetadataIds[metadataType]).includes(metadata.id),
    );

    const userAddedMetadata = defaultToEmptyArray(userAddedMetadataIds[metadataType])
      .map(id => metadataArray.find(metadata => metadata.id === id))
      .filter(Boolean);

    return [...otherMetadata, ...userAddedMetadata];
  };

  return Object.entries(metadata).reduce((acc, [metadataType, metadataArray]) => {
    acc[metadataType] = moveEach(metadataArray, metadataType);
    return acc;
  }, {});
};

/**
 * Parses grouping data from metadata.
 *
 * @param {Object} metadataOnDemand - The metadata on demand object.
 * @param {Array} projects - The projects array.
 * @param {Array} parentsProjects - The parent projects array.
 * @returns {Object} - The parsed grouping data.
 */
export const parseGroupingDataFromMetadata = (metadataOnDemand = {}, projects = [], parentsProjects = []) => {
  return {
    ...metadataOnDemand,
    [KEY_RESULTS_1]: defaultToEmptyArray(metadataOnDemand?.keyResults)?.filter(kr => kr.level === KEY_RESULT_LEVEL.keyResult),
    [KEY_RESULTS_2]: defaultToEmptyArray(metadataOnDemand?.keyResults)?.filter(kr => kr.level === KEY_RESULT_LEVEL.keyResult2),
    objectivesCorp: defaultToEmptyArray(metadataOnDemand?.objectives)?.filter(o => o.level === METADATA_LEVELS.LEVEL_CORP),
    [OBJECTIVES]: defaultToEmptyArray(metadataOnDemand?.objectives)?.filter(o => o.level === METADATA_LEVELS.LEVEL_1),
    [PRODUCTS]: defaultToEmptyArray(metadataOnDemand?.products)?.filter(p => p.level === PRODUCT_LEVEL.product),
    [PRODUCTS_2]: defaultToEmptyArray(metadataOnDemand?.products)?.filter(p => p.level === PRODUCT_LEVEL.product2),
    roadmapsCorp: defaultToEmptyArray(metadataOnDemand?.roadmaps)?.filter(r => r.level === METADATA_LEVELS.LEVEL_CORP),
    [ROADMAPS]: defaultToEmptyArray(metadataOnDemand?.roadmaps)?.filter(r => r.level === METADATA_LEVELS.LEVEL_1),
    [TIMEFRAMES]: defaultToEmptyArray(metadataOnDemand?.timeframes)?.filter(p => p.level === TIMEFRAME_LEVEL.timeframe),
    [TIMEFRAMES_2]: defaultToEmptyArray(metadataOnDemand?.timeframes)?.filter(p => p.level === TIMEFRAME_LEVEL.timeframe2),
    timeframesCorp: defaultToEmptyArray(metadataOnDemand?.timeframes)?.filter(t => t.level === METADATA_LEVELS.LEVEL_CORP),
    [INITIATIVES]: defaultToEmptyArray(parentsProjects)?.filter(p => p.layer === INITIATIVE_LAYER),
    [BETS]: defaultToEmptyArray(parentsProjects)?.filter(p => p.layer === BET_LAYER),
    [STATUS_COLORS]: mapStatusColors(filterStatusColorsByProjects(statusColors, projects)),
    [PLANNING_STAGES]: filterPlanningStagesByAvailablePhases(orderedPlanningStages, metadataOnDemand?.phases),
  };
};

export const compileRequiredMetadataIdsForMetadataService = metadataIdsByKey => {
  const exceptions = {
    objectivesCorp: OBJECTIVES,
    [KEY_RESULTS_1]: KEY_RESULTS,
    [KEY_RESULTS_2]: KEY_RESULTS,
    [PRODUCTS]: PRODUCTS,
    [PRODUCTS_2]: PRODUCTS,
    [TIMEFRAMES]: TIMEFRAMES,
    [TIMEFRAMES_2]: TIMEFRAMES,
  };

  return Object.keys(metadataIdsByKey).reduce((acc, key) => {
    if (exceptions[key]) {
      acc[exceptions[key]] = [...(acc[exceptions[key]] || []), ...metadataIdsByKey[key]];
    } else {
      acc[key] = [...(acc[key] || []), ...metadataIdsByKey[key]];
    }

    return acc;
  }, {});
};
