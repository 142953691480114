import React from 'react';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Input from '@material-ui/core/Input';
import MuiSearchIcon from '@material-ui/icons/Search';
import MuiMenuItem from '@material-ui/core/MenuItem';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import MuiMenuList from '@material-ui/core/MenuList';
import Portal from '@material-ui/core/Portal';
import EditableTextNewLayoutComponent from 'design-system/organisms/EditableTextNewLayout/EditableTextNewLayoutComponent';
import TooltipOnOverflow from '../TooltipOnOverflow/TooltipOnOverflow';
import TextDeprecated from 'design-system/atoms/TextDeprecated';

export default ({
  anchorEl,
  myFilters,
  staticFilters,
  onFilterClick,
  onClickAway,
  setRef,
  onSaveFilter,
  anchorOrigin,
  transformOrigin,
  marginLeft = 0,
  renderSubMenu,
  editableItem,
  onSetEditableItem,
  title,
}) => {
  const [search, setSearch] = React.useState('');
  const [showSubMenu, setShowSubMenu] = React.useState();
  const parentRef = React.useRef();
  const menuItemsRefs = React.useRef({});
  const searchRef = React.useRef({});
  const titleRef = React.useRef({});
  const portalRef = React.useRef({});
  const [subMenuPosition, setSubMenuPosition] = React.useState({ top: 0, left: 0 });
  const [scrollTop, setScrollTop] = React.useState(0);

  React.useEffect(() => {
    setSearch('');
  }, [!anchorEl]);

  const searchedFilters = [...(myFilters || []), ...(staticFilters || [])].filter(i =>
    i.title.toLowerCase().includes(search.toLowerCase()),
  );

  const handleOnFilterClick = React.useCallback(
    item => () => {
      if (onFilterClick) {
        onFilterClick(item);
      }
    },
    [onFilterClick],
  );

  const _handleChangeItem = (item, newLabel) => {
    onSetEditableItem(null);
    if (!newLabel) return;
    onSaveFilter(item.id, newLabel);
  };

  const _onMouseEnter = i => {
    const isStaticFilter = staticFilters && !!staticFilters.find(f => f.id === i.id);

    if (isStaticFilter) {
      if (showSubMenu) setShowSubMenu(null);

      return;
    }

    setShowSubMenu(i);

    const left =
      parentRef.current?.offsetParent.offsetLeft + menuItemsRefs.current[i?.id]?.offsetParent.clientWidth + Number(marginLeft) ||
      0;
    let top =
      parentRef.current?.offsetParent.offsetTop +
        menuItemsRefs.current[i?.id]?.offsetParent.offsetParent.offsetTop +
        menuItemsRefs.current[i?.id]?.offsetTop || 0;

    top -= menuItemsRefs.current[i?.id]?.clientHeight || 0;
    top -= scrollTop;

    if (staticFilters && !!staticFilters.length) top += titleRef.current?.clientHeight;

    setSubMenuPosition({ top, left });
  };

  const _onMouseLeave = () => {
    setShowSubMenu(null);
  };

  const _onClickAway = event => {
    if (!event?.path?.includes(portalRef.current)) onClickAway(event);
  };

  const isEditable = item => {
    return !item.is_static && editableItem && editableItem.id === item.id;
  };

  const _renderFiltersList = filters => (
    <MenuList>
      {filters.map(i => (
        <MenuItem
          key={i.id}
          buttonRef={r => {
            menuItemsRefs.current[i.id] = r;
          }}
          editable={!i.is_static}
          onMouseEnter={() => _onMouseEnter(i)}
          onClick={handleOnFilterClick(i)}
        >
          <MenuItemContainer onClick={handleOnFilterClick(i)}>
            {!isEditable(i) && (
              <TooltipOnOverflow text={i.name}>
                <TextDeprecated>{i.name}</TextDeprecated>
              </TooltipOnOverflow>
            )}
            {isEditable(i) && (
              <EditableTextNewLayoutComponent
                value={i.name}
                editable
                forbidenValues={filters.map(m => m.name)}
                onChange={label => _handleChangeItem(i, label)}
              />
            )}
            {renderSubMenu && (
              <ListItemIcon hover>
                <MoreHoriz fontSize="small" />
              </ListItemIcon>
            )}
          </MenuItemContainer>
        </MenuItem>
      ))}

      {renderSubMenu && showSubMenu && (
        <Portal>
          <MenuChildContainer style={subMenuPosition} ref={portalRef}>
            <Paper>
              <ChildrenContainer>{renderSubMenu(showSubMenu)}</ChildrenContainer>
            </Paper>
          </MenuChildContainer>
        </Portal>
      )}
    </MenuList>
  );

  return (
    <StyledPopover
      ref={r => {
        if (setRef) setRef(r);
      }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      style={{ zIndex: 1300 }}
      anchorOrigin={
        anchorOrigin || {
          vertical: 'bottom',
          horizontal: 'center',
        }
      }
      transformOrigin={
        transformOrigin || {
          vertical: 'top',
          horizontal: 'center',
        }
      }
      marginLeft={marginLeft}
    >
      <ClickAwayListener onClickAway={_onClickAway}>
        <Wrapper
          onMouseLeave={event => _onMouseLeave(event)}
          onScroll={event => {
            setScrollTop(event.target.scrollTop);
          }}
        >
          <Search ref={searchRef}>
            <SearchIcon />
            <Input placeholder="Search..." value={search} onChange={e => setSearch(e.target.value)} fullWidth disableUnderline />
          </Search>
          <ListContainer
            ref={r => {
              parentRef.current = r;
            }}
          >
            {search !== '' && !searchedFilters.length && <FiltersListHeader>No Results</FiltersListHeader>}
            {search !== '' && <FiltersList>{_renderFiltersList(searchedFilters)}</FiltersList>}
            {search === '' && (
              <>
                {myFilters && !!myFilters.length && (
                  <FiltersList filtersLength={myFilters.length}>
                    <FiltersListHeader ref={titleRef}>{title}</FiltersListHeader>
                    <FiltersListBody>{_renderFiltersList(myFilters)}</FiltersListBody>
                  </FiltersList>
                )}
                {staticFilters && !!staticFilters.length && (
                  <FiltersList>
                    <FiltersListHeader>Default Filters</FiltersListHeader>
                    <FiltersListBody>{_renderFiltersList(staticFilters)}</FiltersListBody>
                  </FiltersList>
                )}
              </>
            )}
          </ListContainer>
        </Wrapper>
      </ClickAwayListener>
    </StyledPopover>
  );
};

const StyledPopover = styled(Popover)`
  &&&& {
    margin-left: ${({ marginLeft }) => marginLeft || '0'}px;
  }
`;

const MenuList = styled(MuiMenuList)`
  &&&& {
    border-radius: 8px;
    overflow: unset;
    max-width: 300px;
  }
`;

const Wrapper = styled(Paper)`
  &&& {
    display: flex;
    flex-direction: column;
    width: 300px;
    min-height: 150px;
    overflow: inherit;
    max-height: 390px;
  }
`;

const Search = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  background-color: #fafbfc;
  border-bottom: 1px solid #e9ebf0;
  padding: 0 13px;
`;

const ListContainer = styled.div`
  display: block;
`;

const FiltersList = styled.div`
  flex: 0 1 auto;
`;

const FiltersListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 12px 12px 10px;
  text-transform: uppercase;
  color: rgba(34, 34, 34, 0.3);
  white-space: nowrap;
  font-size: 14px;
`;

const FiltersListBody = styled.div`
  flex: 0 1 auto;
  position: relative;
  padding: 4px 0 0;
  /* border-bottom: 1px solid #e9ebf0; */
`;

const SearchIcon = styled(MuiSearchIcon)`
  &&&& {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    color: ${props => props.theme.palette.primary.main};
  }
`;

const MenuItemContainer = styled.div`
  &&&& {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr 24px;
  }
`;

const MenuChildContainer = styled.div`
  &&&& {
    position: absolute;
    z-index: 100000;

    padding-left: 8px;
  }
`;

const ListItemIcon = styled(MuiListItemIcon)`
  &&&& {
    display: flex;
    justify-content: space-between;

    ${({ hover }) =>
      hover &&
      `
      display: none;
    `}

    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
`;

const MenuItem = styled(MuiMenuItem)`
  &&&& {
    position: relative;
    overflow: unset;

    height: 18px;

    padding: 10px 5px 10px 16px;

    ${({ editable }) =>
      editable &&
      `
        &:hover {
          ${MenuItemContainer} {
            ${ListItemIcon} {
              display: block;

              svg {
                fill: ${props => props.theme.palette.newLayout.background.primary};
              }
            }
          }
        }
    `}
  }
`;

const ChildrenContainer = styled.div`
  &&&& {
    min-width: 200px;
    max-width: 250px;
  }
`;
