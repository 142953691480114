import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


const TotalCountComponent = ({ hasLoaded, total, totalCountForDisplayLayer, shouldDisplayTotalCount, className }) => (
  <CounterDiv id="total-projects-counter" data-testid="total-projects-counter" className={className}>
    {hasLoaded ? (
      <Total noResults={total === 0}>
        {shouldDisplayTotalCount
          ? `Showing ${total} out of ${totalCountForDisplayLayer}`
          : `${total} Total${total === 0 ? '. Please update filter or display option' : ''}`}
      </Total>
    ) : (
      ''
    )}
  </CounterDiv>
);

const CounterDiv = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-left: 10px;
    ${TextDeprecated} {
      font-size: ${({ theme }) => theme.typography.fontSizeSmall}px;
    }
  }
`;

const Total = styled(TextDeprecated)`
  &&&& {
    font-size: ${props => props.theme.typography.fontSizeSmall}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};
    ${props => props.noResults && `color: ${props.theme.palette.text.error}`};
  }
`;

TotalCountComponent.propTypes = {
  hasLoaded: PropTypes.bool,
  total: PropTypes.number,
  totalCountForDisplayLayer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shouldDisplayTotalCount: PropTypes.bool,
};

export default TotalCountComponent;
