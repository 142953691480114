import { useMemo } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';

import { materialColors } from 'design-system/themes/default';

import { nameColumnDef, updatedColumnDef, defaultColumnDefCommonProps } from 'design-system/molecules/AgGridReact-New/columns';
import { ActionsCellRenderer, TextLinkCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { ACTIONS_FIELD, ACTIONS_WIDTH } from '../helpers/constants';

const accountNameColumn = {
  ...nameColumnDef,
  field: 'label',
  headerName: 'Account Name',
  suppressMovable: true,
  width: 400,
};

const updatedColumn = {
  ...updatedColumnDef,
  field: 'updatedAt',
  headerName: 'Updated At',
  suppressMovable: true,
  width: 200,
};

const defaultColDef = {
  ...defaultColumnDefCommonProps,
  cellClass: params => (params.colDef.autoHeight ? 'ag-react-container--expandable' : 'ag-react-editable-container--expandable'),
  sortable: true,
  resizable: true,
  filter: true,
};

const useMultiChildDragonsListGridColumns = (openRowBaseUrlById, onSelectChildDragon, readOnly) => {
  const codeColumn = useMemo(
    () => ({
      field: 'code',
      headerName: 'Code',
      cellClass: 'cell-link',
      editable: false,
      cellRenderer: TextLinkCellRenderer,
      cellRendererParams: {
        handleClick: openRowBaseUrlById,
      },
      suppressMovable: true,
      width: 200,
    }),
    [openRowBaseUrlById],
  );

  const settingsActionColumn = useMemo(
    () => ({
      field: ACTIONS_FIELD,
      headerName: '',
      editable: false,
      suppressMovable: true,
      filter: false,
      resizable: false,
      cellRenderer: ActionsCellRenderer,
      headerClass: 'centered-header',
      cellRendererParams: {
        actions: [
          {
            icon: SettingsIcon,
            color: materialColors.darkerGray,
            getVisibility: () => true,
            onClick: onSelectChildDragon,
            disabled: () => readOnly,
          },
        ],
      },
      width: ACTIONS_WIDTH,
      suppressMenu: true,
    }),
    [onSelectChildDragon, readOnly],
  );

  const columnDefs = useMemo(
    () => [accountNameColumn, codeColumn, updatedColumn, settingsActionColumn],
    [codeColumn, settingsActionColumn],
  );

  return {
    columnDefs,
    defaultColDef,
  };
};

export default useMultiChildDragonsListGridColumns;
