import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { has } from 'ramda';

import { getPageFilters } from 'store/filters/selectors';
import { getCurrentUser } from 'store/login/selectors';
import {
  DETAILS_META_KEY,
  HEALTH_META_KEY,
  LEVEL_META_KEY,
  NAME_META_KEY,
  OWNER_META_KEY,
  PRODUCT_2_META_KEY,
  PRODUCT_META_KEY,
  RECENTLY_CREATED_BY_ME_FILTER_KEY,
  RECENTLY_CREATED_META_KEY,
  ROADMAP_META_KEY,
  USER_META_KEY,
} from '../constants';
import {
  STATUS_FIELD_KEY,
  makeFilterObjectByHealth,
  makeFilterObjectById,
  makeFilterObjectByOwner,
  makeFilterObjectByRoadmap,
  makeFilterObjectByText,
  makeFilterObjectByLevel,
} from 'utils/filters/fieldFilters';
import { cleanMetricRoadmaps, defaultToEmptyArray } from '../utils';
import { sortByCreatedAt } from 'utils/sortEntities';
import { getActiveViewForPage } from 'store/userViews/selectors';

const hasFilter = (key, fields) => has(key, fields);

export default function useMetricModeMetrics(metrics = [], pageId) {
  const pageFilters = useSelector(getPageFilters);
  const currentUser = useSelector(getCurrentUser);
  const activeView = useSelector(state => getActiveViewForPage(state, pageId));

  const isNewRow = metric => !metric.id;

  const filterRoadmaps = useMemo(() => {
    return [...defaultToEmptyArray(pageFilters?.fields?.roadmaps), ...defaultToEmptyArray(pageFilters?.fields?.roadmapsCorp)];
  }, [pageFilters]);

  const filterProducts = useMemo(() => {
    return defaultToEmptyArray(pageFilters?.fields?.products);
  }, [pageFilters]);

  const filterProducts2 = useMemo(() => {
    return defaultToEmptyArray(pageFilters?.fields?.products2);
  }, [pageFilters]);

  const hasRecentlyCreatedFilter = useMemo(() => {
    return !!pageFilters?.fields?.[RECENTLY_CREATED_META_KEY];
  }, [pageFilters]);

  const hasRecentlyCreatedByMeFilters = useMemo(() => {
    return !!pageFilters?.fields?.[USER_META_KEY]?.includes(RECENTLY_CREATED_BY_ME_FILTER_KEY);
  }, [pageFilters]);

  const filterOwners = useMemo(() => {
    return defaultToEmptyArray(pageFilters?.fields?.[OWNER_META_KEY]);
  }, [pageFilters]);

  const filterDetails = useMemo(() => {
    return pageFilters?.fields?.[DETAILS_META_KEY];
  }, [pageFilters]);

  const filterNames = useMemo(() => {
    return defaultToEmptyArray(pageFilters?.fields?.[NAME_META_KEY]);
  }, [pageFilters]);

  const filterStatusColors = useMemo(() => {
    return defaultToEmptyArray(pageFilters?.fields?.[HEALTH_META_KEY]);
  }, [pageFilters]);

  const filterLevels = useMemo(() => {
    return defaultToEmptyArray(pageFilters?.fields?.[LEVEL_META_KEY]);
  }, [pageFilters]);

  const handleFilterMetricByRoadmap = (metric, type = ROADMAP_META_KEY) => {
    const filterOperation = pageFilters?.op?.[type];
    const filterValuesMapper = {
      [ROADMAP_META_KEY]: filterRoadmaps,
      [PRODUCT_META_KEY]: filterProducts,
      [PRODUCT_2_META_KEY]: filterProducts2,
    };
    const filterValues = filterValuesMapper[type];
    const objValue = cleanMetricRoadmaps(metric.metric_roadmaps ?? []);

    return makeFilterObjectByRoadmap(filterOperation, filterValues, objValue, type);
  };

  const handleFilterRecentlyCreatedMetrics = (metrics = []) => {
    if (hasRecentlyCreatedByMeFilters) {
      metrics = metrics.filter(m => handleFilterMetricByCurrentUser(m, 'created_by_id'));
    }

    if (metrics.length) {
      metrics = metrics.sort(sortByCreatedAt);

      return metrics.length >= 100 ? metrics : metrics.slice(0, 100);
    }

    return metrics;
  };

  const handleFilterMetricByCurrentUser = (metric, field) => {
    const userIdToCompareAgainst = activeView?.user_id || currentUser?.id;

    return metric[field] === userIdToCompareAgainst;
  };

  const handleFilterMetricByStatus = metric => {
    const statusFilter = pageFilters?.fields?.[STATUS_FIELD_KEY];

    return statusFilter?.includes(metric[STATUS_FIELD_KEY]);
  };

  const processedMetrics = useMemo(() => {
    const emptyRow = metrics.find(metric => isNewRow(metric));

    // exclude empty row so it isn't eliminated by filters
    let filteredMetrics = [...metrics].filter(m => !isNewRow(m));

    const filterFields = pageFilters?.fields;

    if (hasRecentlyCreatedByMeFilters || hasRecentlyCreatedFilter) {
      filteredMetrics = handleFilterRecentlyCreatedMetrics(metrics);
    }

    if (hasFilter(STATUS_FIELD_KEY, filterFields)) {
      filteredMetrics = filteredMetrics.filter(handleFilterMetricByStatus);
    }

    if (hasFilter(OWNER_META_KEY, filterFields)) {
      filteredMetrics = filteredMetrics.filter(
        makeFilterObjectByOwner(filterOwners, 'owner_id', pageFilters?.op?.[OWNER_META_KEY], false),
      );
    }

    if (hasFilter(PRODUCT_META_KEY, filterFields)) {
      filteredMetrics = filteredMetrics.filter(m => handleFilterMetricByRoadmap(m, PRODUCT_META_KEY));
    }

    if (hasFilter(PRODUCT_2_META_KEY, filterFields)) {
      filteredMetrics = filteredMetrics.filter(m => handleFilterMetricByRoadmap(m, PRODUCT_2_META_KEY));
    }

    if (hasFilter(ROADMAP_META_KEY, filterFields)) {
      filteredMetrics = filteredMetrics.filter(m => handleFilterMetricByRoadmap(m, ROADMAP_META_KEY));
    }

    if (hasFilter(DETAILS_META_KEY, filterFields)) {
      filteredMetrics = filteredMetrics.filter(
        makeFilterObjectByText(filterDetails, DETAILS_META_KEY, pageFilters?.op?.[DETAILS_META_KEY]),
      );
    }

    if (hasFilter(NAME_META_KEY, filterFields)) {
      filteredMetrics = filteredMetrics.filter(makeFilterObjectById(filterNames, 'id', pageFilters?.op?.[NAME_META_KEY]));
    }

    if (hasFilter(HEALTH_META_KEY, filterFields)) {
      filteredMetrics = filteredMetrics.filter(
        makeFilterObjectByHealth(filterStatusColors, 'status_color', pageFilters?.op?.[HEALTH_META_KEY]),
      );
    }

    if (hasFilter(LEVEL_META_KEY, filterFields)) {
      filteredMetrics = filteredMetrics.filter(makeFilterObjectByLevel(filterLevels, 'level', pageFilters?.op?.[LEVEL_META_KEY]));
    }

    return [...(emptyRow ? [emptyRow] : []), ...filteredMetrics];
  }, [
    activeView,
    filterDetails,
    filterNames,
    filterOwners,
    filterStatusColors,
    filterLevels,
    hasRecentlyCreatedByMeFilters,
    hasRecentlyCreatedFilter,
    metrics,
    pageFilters,
  ]);

  return processedMetrics;
}
