export const NAME_COLUMN_TITLE = 'Name';
export const APPLIES_TO_COLUMN_TITLE = 'Applies to';
export const STATUS_COLUMN_TITLE = 'Status';
export const LEVEL_COLUMN_TITLE = 'Type';
export const DETAILS_COLUMN_TITLE = 'Details';
export const OWNER_COLUMN_TITLE = 'Owner';
export const HEALTH_COLUMN_TITLE = 'Health';
export const ACTUAL_VALUE_COLUMN_TITLE = 'Actual Value';
export const TARGET_VALUE_COLUMN_TITLE = 'Target Value';
export const BASELINE_VALUE_COLUMN_TITLE = 'Baseline Value';
export const DATA_SOURCE_COLUMN_TITLE = 'Data source';
export const SUMMARY_COLUMN_TITLE = 'Summary';
export const COLOR_COLUMN_TITLE = 'Color';
