import { getThunkActionTypes } from 'utils/store/thunk';

const UPDATE_SNAPSHOT_STATE = 'UPDATE_SNAPSHOT_STATE';
const UPDATE_SNAPSHOT_VIEW_MODE = 'UPDATE_SNAPSHOT_VIEW_MODE';
const UPDATE_SNAPSHOT_CLUSTERS_VISIBILITY = 'UPDATE_SNAPSHOT_CLUSTERS_VISIBILITY';
const UPDATE_SNAPSHOT_VIEW_MODE_TIME_PERIOD = 'UPDATE_SNAPSHOT_VIEW_MODE_TIME_PERIOD';

const { FETCH_SNAPSHOT_DATA, FETCH_SNAPSHOT_DATA_PENDING, FETCH_SNAPSHOT_DATA_FULFILLED } =
  getThunkActionTypes('FETCH_SNAPSHOT_DATA');

export {
  UPDATE_SNAPSHOT_STATE,
  FETCH_SNAPSHOT_DATA,
  FETCH_SNAPSHOT_DATA_PENDING,
  FETCH_SNAPSHOT_DATA_FULFILLED,
  UPDATE_SNAPSHOT_VIEW_MODE,
  UPDATE_SNAPSHOT_CLUSTERS_VISIBILITY,
  UPDATE_SNAPSHOT_VIEW_MODE_TIME_PERIOD,
};
