export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_FULFILLED = 'FETCH_CUSTOMERS_FULFILLED';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMER_FULFILLED = 'CREATE_CUSTOMER_FULFILLED';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_FULFILLED = 'UPDATE_CUSTOMER_FULFILLED';

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_FULFILLED = 'DELETE_CUSTOMER_FULFILLED';

export const UPDATE_CUSTOMER_BY_ID = 'UPDATE_CUSTOMER_BY_ID';
export const UPDATE_CUSTOMER_BY_ID_FULFILLED = 'UPDATE_CUSTOMER_BY_ID_FULFILLED';

export const ADD_CUSTOMER_WITHOUT_SAVE = 'ADD_CUSTOMER_WITHOUT_SAVE';
export const REMOVE_UNSAVED_CUSTOMERS = 'REMOVE_UNSAVED_CUSTOMERS';

export const MERGE_CUSTOMERS = 'MERGE_CUSTOMERS';
export const MERGE_CUSTOMERS_FULFILLED = 'MERGE_CUSTOMERS_FULFILLED';

export const CREATE_CUSTOMERS = 'CREATE_CUSTOMERS';
export const CREATE_CUSTOMERS_FULFILLED = 'CREATE_CUSTOMERS_FULFILLED';
export const UPDATE_CUSTOMERS = 'UPDATE_CUSTOMERS';
export const UPDATE_CUSTOMERS_FULFILLED = 'UPDATE_CUSTOMERS_FULFILLED';
export const BULK_DELETE_CUSTOMERS = 'BULK_DELETE_CUSTOMERS';
export const BULK_DELETE_CUSTOMERS_FULFILLED = 'BULK_DELETE_CUSTOMERS_FULFILLED';

export const IMPORT_CUSTOMERS = 'IMPORT_CUSTOMERS';
export const IMPORT_CUSTOMERS_PENDING = 'IMPORT_CUSTOMERS_PENDING';
export const IMPORT_CUSTOMERS_REJECTED = 'IMPORT_CUSTOMERS_REJECTED';
export const IMPORT_CUSTOMERS_FULFILLED = 'IMPORT_CUSTOMERS_FULFILLED';

export const UNDO_CREATE_CUSTOMERS = 'UNDO_CREATE_CUSTOMERS';
export const UNDO_CREATE_CUSTOMERS_FULFILLED = 'UNDO_CREATE_CUSTOMERS_FULFILLED';
export const UNDO_UPDATE_CUSTOMERS = 'UNDO_UPDATE_CUSTOMERS';
export const UNDO_UPDATE_CUSTOMERS_FULFILLED = 'UNDO_UPDATE_CUSTOMERS_FULFILLED';
export const UNDO_BULK_DELETE_CUSTOMERS = 'UNDO_BULK_DELETE_CUSTOMERS';
export const UNDO_BULK_DELETE_CUSTOMERS_FULFILLED = 'UNDO_BULK_DELETE_CUSTOMERS_FULFILLED';

export const CREATE_CUSTOMER_ROADMAP = 'CREATE_CUSTOMER_ROADMAP';
export const CREATE_CUSTOMER_ROADMAP_FULFILLED = 'CREATE_CUSTOMER_ROADMAP_FULFILLED';
export const DELETE_CUSTOMER_ROADMAP = 'DELETE_CUSTOMER_ROADMAP';
export const DELETE_CUSTOMER_ROADMAP_FULFILLED = 'DELETE_CUSTOMER_ROADMAP_FULFILLED';
export const BULK_DELETE_CUSTOMER_ROADMAP = 'BULK_DELETE_CUSTOMER_ROADMAP';
export const BULK_DELETE_CUSTOMER_ROADMAP_FULFILLED = 'BULK_DELETE_CUSTOMER_ROADMAP_FULFILLED';

export const UPDATE_CUSTOMERS_SEARCH_TEXT = 'UPDATE_CUSTOMERS_SEARCH_TEXT';

export const UPDATE_SETTINGS_GROUP_BY_OPTION = 'UPDATE_SETTINGS_GROUP_BY_OPTION';
