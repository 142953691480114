import React, { useState } from 'react';
import styled from 'styled-components';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import SmallText from 'design-system/atoms/SmallText/index';

import CollapsePanel from 'components/CollapsePanel';

import useImportConfiguration from 'hooks/integrations/useImportConfiguration';
import formatDateTime from 'utils/dates/formatDateTime';
import getDateForFormatting from 'routes/Settings/Integration/Jira/utils/isoDateFormat';
import { IMPORT_LIGHTBOX_VARIANTS, INTEGRATIONS_KEYS } from 'constants/integrations';
import ImportFromIntegration from 'containers/ImportIdeasDialog/ImportFromIntegration/ImportFromIntegration';
import { spacing } from 'design-system/theme';

const exist = Boolean;

const RecurringImport = ({ orgIntegrationId, userCanUpdateOrgIntegration }) => {
  const [importIntegrationData, updateImportConfiguration, runIntegrationRecurringImport] = useImportConfiguration(
    'rally',
    orgIntegrationId,
  );

  const [reviewingRecurringImportQuery, setReviewingRecurringImportQuery] = useState(false);

  const handleSetRecurringImportEnabled = e => {
    if (!e.target.checked) {
      updateImportConfiguration({
        recurring_import_enabled: false,
      });
      return;
    }

    updateImportConfiguration({
      recurring_import_enabled: true,
      ...(exist(importIntegrationData?.recurring_import_link)
        ? { recurring_import_link: importIntegrationData.recurring_import_link }
        : {}),
    });
  };

  return (
    <Wrapper>
      <CollapsePanel title={<TextDeprecated size="medium">One time and Recurring Import setting</TextDeprecated>}>
        <PanelContent>
          <ButtonContainer>
            <Button
              id="import-rally-button"
              color="primary"
              onClick={() => setReviewingRecurringImportQuery(IMPORT_LIGHTBOX_VARIANTS.import)}
              disabled={!userCanUpdateOrgIntegration}
            >
              One time import
            </Button>
          </ButtonContainer>
          <br />
          <br />
          <br />

          <FormControlLabelContainer
            control={
              <Switch checked={importIntegrationData.recurring_import_enabled} onChange={handleSetRecurringImportEnabled} />
            }
            label="Recurring auto import Rally portfolio items"
            disabled={!userCanUpdateOrgIntegration}
          />
          {importIntegrationData.recurring_import_enabled && (
            <>
              <ButtonContainer>
                <Button
                  id="recurring-import-query-button"
                  color="primary"
                  onClick={() => setReviewingRecurringImportQuery(IMPORT_LIGHTBOX_VARIANTS.recurringImport)}
                  disabled={!userCanUpdateOrgIntegration}
                >
                  {exist(importIntegrationData?.recurring_import_link) ? 'Review' : 'Create'} Recurring Import Query
                </Button>
              </ButtonContainer>
              <ButtonContainer>
                <Button
                  id="run-recurring-import-button"
                  color="primary"
                  disabled={!userCanUpdateOrgIntegration || !exist(importIntegrationData?.recurring_import_link)}
                  onClick={() => runIntegrationRecurringImport()}
                >
                  Run the recurring import now
                </Button>
                {exist(importIntegrationData?.last_recurring_import) && (
                  <SmallText>
                    (Last Import: {formatDateTime(getDateForFormatting(importIntegrationData.last_recurring_import))})
                  </SmallText>
                )}
              </ButtonContainer>
            </>
          )}

          <ImportFromIntegration
            integrationType={INTEGRATIONS_KEYS.rally}
            isOpen={exist(reviewingRecurringImportQuery)}
            onClose={() => setReviewingRecurringImportQuery(null)}
            variant={reviewingRecurringImportQuery}
            orgIntegrationId={orgIntegrationId}
          />
        </PanelContent>
      </CollapsePanel>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ButtonContainer = styled.div`
  padding-bottom: ${spacing(0.625)}px;
`;

const FormControlLabelContainer = styled(FormControlLabel)`
  margin-top: -${spacing(1.25)}px;
  padding-left: ${spacing(0.625)}px;
`;

const PanelContent = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.palette.text.lightGrey};
  padding-bottom: ${spacing(2)}px;
`;

export default RecurringImport;
