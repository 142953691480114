import React, { useImperativeHandle, useState } from 'react';
import styled from 'styled-components';

import AppliesToMultiSelectAutocomplete from '../AppliesToMultiSelectAutocomplete';
import cellEditorHoc from 'design-system/molecules/AgGridReact-New/helpers/cellEditorHoc';

const AppliesToMultiSelectAutocompleteCellEditor = cellEditorHoc(({ onChange, forwardedRef, ...params }) => {
  const { value } = params;

  const [internalValue, setInternalValue] = useState(value);

  useImperativeHandle(forwardedRef, () => ({
    getValue() {
      return internalValue || [];
    },
  }));

  const handleChange = selectedItems => {
    setInternalValue(selectedItems);
  };

  return (
    <Container>
      <AppliesToMultiSelectAutocomplete {...params} onChange={handleChange} selectedOptions={internalValue} />
    </Container>
  );
});

export default AppliesToMultiSelectAutocompleteCellEditor;

const Container = styled.div`
  width: 250px;
`;
