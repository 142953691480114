import React from 'react';
import styled from 'styled-components';

import ButtonV2 from 'design-system/atoms/ButtonV2/index';

import theme from 'design-system/theme';
import UpsellPageTemplate from 'design-system/templates/UpsellPageTemplate';

import { Container, SideSection, Title, StyledText, ButtonWrapper } from './styledComponents';
import upsellScenario1 from './upsellScenario1.png';
import upsellScenario2 from './upsellScenario2.png';

const LEARN_MORE_BUTTON_ID = 'scenario-chart-upsell-button';

const UpsellScenarioChart = () => {
  return (
    <UpsellPageTemplate>
      <Container>
        <SideSection>
          <Image src={upsellScenario1} alt="upsell scenario chart" />
          <Image src={upsellScenario2} alt="upsell scenario chart" />
        </SideSection>
        <SideSection>
          <Title>Enable Responsive Planner</Title>
          <StyledText>
            Plan, assess and pivot product investment at the speed of your business using real time resource planning &
            allocation.
          </StyledText>
          <StyledText>Please contact your success manager to upgrade or activate.</StyledText>
          <ButtonWrapper>
            <ButtonV2
              id={LEARN_MORE_BUTTON_ID}
              color={theme.palette.background.white}
              background={theme.palette.background.primary}
              hover={{ background: theme.palette.background.primary }}
            >
              Learn more
            </ButtonV2>
          </ButtonWrapper>
        </SideSection>
      </Container>
    </UpsellPageTemplate>
  );
};

const Image = styled.img`
  width: 80%;
  margin-bottom: 30px;
`;

export default UpsellScenarioChart;
