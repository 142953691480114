const migrations = {
  0: state => {
    // migration clear out device state
    return {
      ...state,
      history: undefined,
    };
  },
};

export default migrations;
