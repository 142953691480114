import React, { useEffect } from 'react';

import useGetOktaQueryString from 'hooks/integrations/okta/useGetOktaQueryString';

export default props => {
  // eslint-disable-next-line no-unused-vars
  const [queryString, error, setQueryString, setError] = useGetOktaQueryString(props.location);

  useEffect(() => {
    if (!queryString) return;
    let obj = { ssoType: 'okta' };

    if (error) obj.error_description = error;
    else if (queryString) {
      obj = { ...obj, ...queryString };
    }

    window.opener?.postMessage(JSON.stringify(obj), '*');
    window.close();
  }, [queryString]);

  return <div />;
};
