import React, { useState, useRef, useImperativeHandle, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import RootRef from '@material-ui/core/RootRef';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';

import cellEditorHoc from 'design-system/molecules/AgGridReact-New/helpers/cellEditorHoc';

const handleOnClickAway = (clickEvent, { stopEditing }) => {
  const clickedEventTargetIsRenderedAsPortal = clickEvent.target.closest('#picker-popover');

  if (!clickedEventTargetIsRenderedAsPortal) {
    stopEditing();
  }
};

const DatePickerCellEditor = cellEditorHoc(({ value: originalValue = '', forwardedRef }) => {
  const [stateValue, setStateValue] = useState(originalValue);
  const domRef = useRef(null);

  useImperativeHandle(forwardedRef, () => ({
    getValue() {
      return stateValue ?? originalValue;
    },
  }));

  const handleChange = value => {
    setStateValue(value);
  };

  const handleInputChange = e => {
    if (moment(e.target.value, 'YYYY-MM-DD', true).isValid() || e.target.value === '') {
      setStateValue(e.target.value);
    }
  };

  useEffect(() => {
    domRef?.current?.childNodes?.[0]?.childNodes?.[0]?.focus();
  }, []);

  return (
    <div role="presentation" className="ag-grid-datepicker-container">
      <RootRef rootRef={domRef}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <InlineDatePicker
            keyboard
            clearable
            value={stateValue || null}
            format="YYYY-MM-DD"
            onInputChange={handleInputChange}
            onChange={handleChange}
          />
        </MuiPickersUtilsProvider>
      </RootRef>
    </div>
  );
}, handleOnClickAway);

DatePickerCellEditor.propTypes = {
  value: PropTypes.string,
};

DatePickerCellEditor.defaultProps = {
  value: '',
};

export default DatePickerCellEditor;
