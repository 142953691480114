import flatten from 'lodash/flatten';
import { defaultTo } from 'ramda';

import { getEstimateColIdByTeamSkill } from 'utils/estimates';

const defaultToEmptyArray = defaultTo([]);

/**
 * @function getEstimatesColsVisibility
 *
 * get visible columns based on teams / skills and grid data
 *
 * @param {Array} teams
 * @param {Array} skills
 * @param {Array} users
 * @param {Array} data
 */
const getEstimatesColsVisibility = (teams, skills, users, data) => {
  // filter users with team and skills
  const usersWithTeam = users ? users.filter(u => u.team && u.team.id) : [];
  const usersWithSkill = users ? users.filter(u => u.skill && u.skill.id) : [];

  // get all estimates on row data
  const allEstimates = flatten(defaultToEmptyArray(data).map(d => d.estimates)).filter(e => e && e.skill && e.team);

  // validation func to show team column
  const checkShowTeam = team => {
    // check if team has users related
    const hasUsers = usersWithTeam.filter(u => u.team?.id === team.id).length;
    const hasEstimates = allEstimates.filter(e => e.team?.id === team.id).length;

    return !!hasUsers || !!hasEstimates;
  };

  // valudation fun to show skill column
  const checkShowSkill = (team, skill) => {
    const hasUser = !!usersWithSkill.filter(u => u.team?.id === team.id && u.skill?.id === skill.id).length;
    const hasEstimate = !!allEstimates.filter(e => e.team?.id === team.id && e.skill?.id === skill.id).length;

    return !!hasUser || !!hasEstimate;
  };

  const allEstimatesColumnsIds = [];
  const visibleColumnsIds = [];

  teams.forEach(team => {
    const showTeam = checkShowTeam(team);

    skills.forEach(skill => {
      const colId = getEstimateColIdByTeamSkill(team.id, skill.id);
      const showSkill = checkShowSkill(team, skill);

      if (showTeam && showSkill) {
        visibleColumnsIds.push(colId);
      }

      allEstimatesColumnsIds.push(colId);
    });
  });

  return { allEstimatesColumnsIds, visibleColumnsIds };
};

export default getEstimatesColsVisibility;
