import { removeDuplicates as uniq } from 'utils';
import {
  COLLAPSE_ALL,
  EXPAND_ALL,
  TOGGLE_SECTION,
  SET_SEARCH_STRING,
  UPDATE_STAFFING_STATE,
} from './types';

const initialState = {
  collapsedSections: [],
  searchString: '',
  showLegend: false,
  showAllocation: false,
  showInactiveUsers: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_STAFFING_STATE:
      const { payload } = action;

      return {
        ...state,
        ...payload,
      };
    case COLLAPSE_ALL:
      return Object.assign({}, state, { collapsedSections: uniq(action.payload) });
    case EXPAND_ALL:
      return Object.assign({}, state, { collapsedSections: [] });
    case TOGGLE_SECTION: {
      let { collapsedSections } = state;
      const section = collapsedSections.find(s => s === action.payload);

      if (section) {
        collapsedSections = collapsedSections.filter(s => s !== action.payload);
      } else {
        collapsedSections.push(action.payload);
      }
      return Object.assign({}, state, { collapsedSections: uniq(collapsedSections) });
    }
    case SET_SEARCH_STRING: {
      const { searchString, emptyRows } = action.payload;

      return Object.assign({}, state, { searchString, collapsedSections: emptyRows || [] });
    }
    default:
      return state;
  }
};
