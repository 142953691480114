import axios from 'axios';

import throwRequestError from 'store/utils/throwRequestError';
import { createThunk } from 'utils/store/thunk';

import { SEARCH_REQUESTS_BY_TEXT } from './types';

const searchRequestsByText = (search, limit = 5) =>
  createThunk(
    SEARCH_REQUESTS_BY_TEXT,
    axios.get(`/api/customer-requests/search-by-title?search=${search}&limit=${limit}`).catch(throwRequestError),
  );

export { searchRequestsByText };
