import React from 'react';

import { CommentsLightboxProvider } from '../IdeasList/IdeasList/New/hooks/useCommentsLightbox';
import { CycleDeliverableLightboxProvider } from './hooks/useCycleDeliverableLightbox';
import { ProjectCycleDeliverableLightboxProvider } from './hooks/useProjectCycleDeliverableLightbox';

import IdeasCycleDeliverableList from './IdeasCycleDeliverableList';

export default ({ ...props }) => {
  return (
    <CommentsLightboxProvider>
      <CycleDeliverableLightboxProvider>
        <ProjectCycleDeliverableLightboxProvider>
          <IdeasCycleDeliverableList {...props} />
        </ProjectCycleDeliverableLightboxProvider>
      </CycleDeliverableLightboxProvider>
    </CommentsLightboxProvider>
  );
};
