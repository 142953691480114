import { useCallback, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { has } from 'ramda';

import {
  createCustomField,
  updateCustomFieldById,
  fetchCustomFields,
  removeUnsavedCustomFields,
  addCustomFieldWithoutSave,
  deleteCustomField,
  createCustomFieldRoadmap,
  deleteCustomFieldRoadmap,
  bulkDeleteCustomFieldRoadmaps,
  bulkCreateCustomFields,
  bulkUpdateCustomFields,
  createCustomFieldAssociation,
  deleteCustomFieldAssociation,
} from 'store/customFields';
import { getOrganization } from 'store/organization';

import { getProjectAndRequestsCustomFields, getProjectsCustomFieldsNewSchema } from 'store/customFields/selectors';
import { CUSTOM_FIELD_TYPES, CUSTOMER_REQUEST_CUSTOM_FIELD, PROJECT_CUSTOM_FIELD } from 'store/customFields/constants';
import getFormattedFormulaDescription from 'store/customFields/helpers/getFormattedFormulaDescription';
import useFormulaOptions from 'hooks/useFormulaOptions';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import filterCustomFieldsByQueryAndKey from '../helpers/filterCustomFieldsByQueryAndKey';

const useCustomFields = () => {
  const [openFormula, setOpenFormula] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [hideArchived, setHideArchived] = useState(false);

  const dispatch = useDispatch();

  const { canView } = usePermissions();

  const organization = useSelector(getOrganization);
  const hasProjectsAndRequestsCustomFields = canView(PERMISSION_FEATURES.requestDetailsFieldsLayout);
  const getCustomFieldsSelector = hasProjectsAndRequestsCustomFields
    ? getProjectAndRequestsCustomFields
    : getProjectsCustomFieldsNewSchema;
  const customFields = useSelector(state => getCustomFieldsSelector(state, !hideArchived));

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          fetchCustomFields,
          createCustomField,
          updateCustomFieldById,
          deleteCustomField,
          removeUnsavedCustomFields,
          addCustomFieldWithoutSave,
          bulkCreateCustomFields,
          bulkUpdateCustomFields,
        },
        dispatch,
      ),
    [dispatch],
  );

  const formulaOptions = useFormulaOptions(CUSTOM_FIELD_TYPES.PROJECTS);

  const updateFormula = useCallback(
    formula => {
      dispatch(updateCustomFieldById(openFormula.id, { description: getFormattedFormulaDescription(formula, formulaOptions) }));
    },
    [openFormula],
  );

  const onLinkClickFormula = data => {
    setOpenFormula({ id: data.id, value: data.description_text, title: data.title, organization_id: data.organization_id });
  };

  const handleAddRow = useCallback(() => {
    const newRow = { association_type: PROJECT_CUSTOM_FIELD };

    dispatch(addCustomFieldWithoutSave(newRow));
  }, [addCustomFieldWithoutSave]);

  const updateSearchText = val => setSearchText(val);

  const toggleHideArchived = () => setHideArchived(prevVal => !prevVal);

  const toggleCustomFieldAssociation = useCallback(
    (id, enabled, associationType) => {
      if (enabled) {
        dispatch(createCustomFieldAssociation(id, associationType));
      } else {
        dispatch(deleteCustomFieldAssociation(id, associationType));
      }
    },
    [deleteCustomFieldAssociation],
  );

  const updateCustomField = useCallback(
    (id, update, isUpdate = false) => {
      if (has(PROJECT_CUSTOM_FIELD, update?.associations)) {
        toggleCustomFieldAssociation(id, update.associations?.[PROJECT_CUSTOM_FIELD], PROJECT_CUSTOM_FIELD);
        return;
      }

      if (has(CUSTOMER_REQUEST_CUSTOM_FIELD, update?.associations)) {
        toggleCustomFieldAssociation(id, update.associations?.[CUSTOMER_REQUEST_CUSTOM_FIELD], CUSTOMER_REQUEST_CUSTOM_FIELD);
        return;
      }

      dispatch(updateCustomFieldById(id, update, isUpdate));
    },
    [updateCustomFieldById],
  );

  const filteredCustomFields = useMemo(
    () => filterCustomFieldsByQueryAndKey(customFields, searchText),
    [customFields, searchText],
  );

  return {
    customFields: filteredCustomFields,
    systemFields: organization.system_fields_name,
    hideMetadataRoadmaps: !organization.enable_metadata_roadmaps,
    createCustomFieldRoadmap,
    deleteCustomFieldRoadmap,
    bulkDeleteCustomFieldRoadmaps,
    handleAddRow,
    ...boundActionCreators,
    updateCustomFieldById: updateCustomField,
    openFormula,
    setOpenFormula,
    updateFormula,
    formulaOptions,
    onLinkClickFormula,
    searchText,
    updateSearchText,
    hideArchived,
    toggleHideArchived,
  };
};

export default useCustomFields;
