import { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';

import useOrganizations from 'hooks/useOrganizations';
import useSystemFields from 'hooks/useSystemFields';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import usePermissions from 'hooks/permissions/usePermissions';
import usePageQueryParamFilters from 'hooks/usePageQueryParamFilters';
import { PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';
import getActiveOKRWithHierarchy from 'utils/getActiveOKRWithHierarchy';
import { getObjectiveTypeByLevel } from 'utils/objectives';
import { openObjectiveDrawer as openObjectiveDrawerAction } from 'store/objectives/actions';
import { getOKRGroupColumnTitle } from 'routes/Settings/Objectives/New/helpers';
import { USER_IS_OWNER_FILTER_KEY } from 'routes/Goals/HeaderFilters/constants';
import { OBJECTIVE } from 'constants/common';
import { GOALS_GRID_PATH } from 'constants/filters/pages';
import {
  fetchMissionControlOkrs,
  selectMissionControlHomeOkrs,
  selectIsLoadingMissionControlHomeOkrs,
} from 'features/MissionControlHome/store';
import { subscribeToRefreshMissionControlOrksChanged } from 'features/MissionControlHome/store/listenerMiddleware';

const getTitle = getSystemFieldName => {
  const titleSuffix = 'My';
  const entityTitle = pluralize(getSystemFieldName(OBJECTIVE));

  return `${titleSuffix} ${entityTitle}`;
};

const extractKeyResults = items => {
  return items.flatMap(item => [
    ...(item.keyResults || []),
    ...extractKeyResults(item.keyResults?.filter(kr => kr.keyResults) || []),
  ]);
};

const useMissionControlMyOkrs = () => {
  const dispatch = useDispatch();

  const { canView } = usePermissions();

  const [getSystemFieldName] = useSystemFields();

  const { hasKeyResults, hasKeyResults2 } = useOrganizations();

  const { isDodActive: hasCorpLevel } = useOrganizationsAccessControl();

  const { navigateToPageWithFilter } = usePageQueryParamFilters();

  const okrs = useSelector(selectMissionControlHomeOkrs);

  const isLoading = useSelector(selectIsLoadingMissionControlHomeOkrs);

  const objectivesAndKeyResultsWithHierarchy = useMemo(() => {
    const keyResults = extractKeyResults(okrs);

    return getActiveOKRWithHierarchy(okrs, hasKeyResults, hasKeyResults2, keyResults, true);
  }, [okrs, hasKeyResults, hasKeyResults2]);

  const fetchOkrs = () => dispatch(fetchMissionControlOkrs());

  const goToOkrsPage = () => navigateToPageWithFilter(`/${GOALS_GRID_PATH}`, USER_IS_OWNER_FILTER_KEY);

  const canGoToSettings = canView(PERMISSION_PAGES.settingsOkrsPage);

  const headerName = getOKRGroupColumnTitle(hasKeyResults, hasKeyResults2, hasCorpLevel, getSystemFieldName);

  const openObjectiveDrawer = useCallback(okr => {
    const okrType = getObjectiveTypeByLevel(okr);

    dispatch(openObjectiveDrawerAction(okr.id, okrType));
  }, []);

  const title = getTitle(getSystemFieldName);

  const getRowId = ({ data }) => data.uniqueId;

  useEffect(() => {
    const unsubscribeRefreshData = dispatch(subscribeToRefreshMissionControlOrksChanged());

    fetchOkrs();

    return unsubscribeRefreshData;
  }, []);

  return {
    // data
    objectivesAndKeyResultsWithHierarchy,
    dataLength: okrs?.length || 0,
    isLoading,

    // actions
    openObjectiveDrawer,
    goToOkrsPage,

    // labels
    title,
    headerName,
    entitySystemFieldName: getSystemFieldName(OBJECTIVE),

    // permissions
    canGoToSettings, // todo: will be go to goal mode page with filter

    // utils
    getRowId,
  };
};

export default useMissionControlMyOkrs;
