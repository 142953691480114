import { useSelector } from 'react-redux';
import { useState, useMemo, useCallback, useLayoutEffect } from 'react';
import { getDisplayLayer, makeSelectFilteredProjectsByRoadmapForPage } from 'store/filters/selectors';
import { selectFilteredProjectsForTimelinePage } from 'store/projects/selectors';
import useProjectsLocalSearch from 'hooks/projects/useProjectsLocalSearch';
import { isSomeUpdateOnProjectsOcurring } from 'store/projects';
import { isSomeUpdateOnProjectsEstimatesOcurring } from 'store/estimates/selectors';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import useProjectsLocalFilterByOwner from 'hooks/projects/useProjectsLocalFilterByOwner';
import useProjectsFrontendFilters from 'hooks/filters/useProjectsFontendFilters';

/**
 * Hook to get projects from the store and apply specific filter for page
 *  - filter By Search String
 *  - filter By Time
 *
 * @param {*} lsState
 * @returns [projects]
 */
const useTimelineProjects = (lsState, pageId, hasPortfolioOption, isOneClickPlanModeActive) => {
  const { searchStr, HCTableFilter, showAllDependencies, showMyItemsOnly = false } = lsState;
  const displayLayer = useSelector(state => getDisplayLayer(state));

  const areProjectsUpdating = useSelector(isSomeUpdateOnProjectsOcurring);
  const areEstimatesUpdating = useSelector(isSomeUpdateOnProjectsEstimatesOcurring);
  const storeUpdating = areProjectsUpdating || areEstimatesUpdating;

  const [getFilteredIdeasForTimelinePage, getFilteredInitiativesForTimelinePage, getFilteredBetsForTimelinePage] = useMemo(() => {
    return [
      makeSelectFilteredProjectsByRoadmapForPage(selectFilteredProjectsForTimelinePage, null, hasPortfolioOption),
      makeSelectFilteredProjectsByRoadmapForPage(selectFilteredProjectsForTimelinePage, null, hasPortfolioOption),
      makeSelectFilteredProjectsByRoadmapForPage(selectFilteredProjectsForTimelinePage, null, hasPortfolioOption),
    ];
  }, [hasPortfolioOption]);

  const [ideasOnStore, initiativesOnStore, betsOnStore] = useSelector(state => {
    return [
      getFilteredIdeasForTimelinePage(state, pageId, false, IDEA_LAYER, false, HCTableFilter, showAllDependencies),
      getFilteredInitiativesForTimelinePage(state, pageId, false, INITIATIVE_LAYER, false, HCTableFilter, showAllDependencies),
      getFilteredBetsForTimelinePage(state, pageId, false, BET_LAYER, false, HCTableFilter, showAllDependencies),
    ];
  });

  const [ideas, setIdeas] = useState(ideasOnStore);
  const [initiatives, setInitiatives] = useState(initiativesOnStore);
  const [bets, setBets] = useState(betsOnStore);

  const projectsInAllLayers = useMemo(
    () => [...ideasOnStore, ...initiativesOnStore, ...betsOnStore],
    [ideasOnStore, initiativesOnStore, betsOnStore],
  );
  const [filterProjects] = useProjectsLocalSearch(projectsInAllLayers, searchStr);

  const filterMyItems = useProjectsLocalFilterByOwner(projectsInAllLayers, showMyItemsOnly);

  const { applyFrontendFilters } = useProjectsFrontendFilters(filterMyItems, filterProjects);

  const updateProjectsState = useCallback(() => {
    setIdeas(applyFrontendFilters ? applyFrontendFilters(ideasOnStore) : ideasOnStore);
    setInitiatives(applyFrontendFilters ? applyFrontendFilters(initiativesOnStore) : initiativesOnStore);
    setBets(applyFrontendFilters ? applyFrontendFilters(betsOnStore) : betsOnStore);
  }, [applyFrontendFilters, ideasOnStore, initiativesOnStore, betsOnStore]);

  useLayoutEffect(() => {
    if (!storeUpdating) {
      updateProjectsState();
    }
  }, [updateProjectsState, storeUpdating]);

  const projects = useMemo(() => {
    let result = [];

    switch (displayLayer) {
      case '0':
        result = ideas;
        break;
      case '1':
        result = initiatives;
        break;
      case '2':
        result = bets;
        break;
      default:
        result = ideas;
        break;
    }

    return result;
  }, [ideas, initiatives, bets, displayLayer, isOneClickPlanModeActive]);

  return [projects, ideas, initiatives, bets];
};

export default useTimelineProjects;
