import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { selectGoalsAllocationData } from '../store/selectors';
import { fetchGoalsAllocationData, recalculateCompletedAllocationData } from '../store/thunks';
import { getUserFilters } from 'store/filters/selectors';

import usePageFilters from 'hooks/filters/usePageFilters';

import formatDate from 'utils/dates/formatDate';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import { isNotProductionEnv } from 'src/config';
import getAllocationDataFormatted from '../helpers/getAllocationDataFormatted';

import { OBJECTIVE_KEY } from 'constants/objectives';
import { ALLOCATION_REPORT_PAGE } from 'constants/filters';

const ALLOCATION_BY_API_OPTIONS = {
  objectiveCorp: 'objective_corp_id',
  objective: 'objective_id',
  keyResult1: 'key_result_1_id',
  keyResult2: 'key_result_2_id',
  theme: 'theme_id',
  category: 'category_id',
};

const useGoalsAllocationData = ({
  startDate,
  endDate,
  timeUnit,
  showAllocationAsPercentage,
  showAllocationInWeeks,
  allocationBy = OBJECTIVE_KEY,
  showTwoDecimals,
}) => {
  const dispatch = useDispatch();

  const allocationData = useSelector(selectGoalsAllocationData);

  const { pageFilters: filters, displayLayer } = usePageFilters(ALLOCATION_REPORT_PAGE);
  const userFilters = useSelector(state => getUserFilters(state));
  const hasBet = useSelector(state => state.organization.organization.has_bet);
  const filtersForApiSearch = useMemo(
    () => compileFiltersBody(filters, userFilters, hasBet, ALLOCATION_REPORT_PAGE, displayLayer),
    [filters, userFilters, hasBet, displayLayer],
  );

  const allocationDataFormatted = useMemo(() => {
    return getAllocationDataFormatted(allocationData, showAllocationAsPercentage, showAllocationInWeeks, showTwoDecimals);
  }, [allocationData, showAllocationAsPercentage, showAllocationInWeeks, showTwoDecimals]);

  useEffect(() => {
    dispatch(
      fetchGoalsAllocationData({
        timeUnit,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        projectsFilter: filtersForApiSearch,
        allocationBy: ALLOCATION_BY_API_OPTIONS[allocationBy],
      }),
    );
  }, [dispatch, startDate, endDate, timeUnit, allocationBy, filtersForApiSearch]);

  const [alreadyRecalculated, setAlreadyRecalculated] = useState(false);
  const canRecalculate = isNotProductionEnv();
  const recalculate = useCallback(() => {
    dispatch(recalculateCompletedAllocationData());
    setAlreadyRecalculated(true);
    toast('Please check back in a few seconds');
  }, [dispatch]);

  return {
    allocationData,
    allocationDataFormatted,
    recalculate,
    canRecalculate,
    alreadyRecalculated,
  };
};

export default useGoalsAllocationData;
