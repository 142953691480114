import { useState, useEffect } from 'react';

const DIRECTION_RIGTH = 'right';
const DIRECTION_LEFT = 'left';

const POPPER_WIDTH_DEFAULT = 200;
const CHILD_CONTAINER_WIDTH_DEFAULT = 200;

/**
 * @function useChildItemDirection
 *
 * gets the direaction of the child container based on anchor element
 * and the size of the window
 *
 * @param  {Object} anchorEl
 * @param  {Number} childContainerWidth
 * @param  {Number} popperWidth
 * @return {Object}
 */
const useChildItemDirection = (
  anchorEl,
  childContainerWidth = CHILD_CONTAINER_WIDTH_DEFAULT,
  popperWidth = POPPER_WIDTH_DEFAULT,
) => {
  const [direction, setDirection] = useState(DIRECTION_RIGTH);

  useEffect(() => {
    if (!anchorEl) {
      return;
    }

    const { left } = anchorEl.getBoundingClientRect();
    const requiredSpace = left + childContainerWidth + popperWidth;
    let nextDirection = DIRECTION_RIGTH;

    if (requiredSpace > window.innerWidth) {
      nextDirection = DIRECTION_LEFT;
    }

    setDirection(nextDirection);
  }, [anchorEl, childContainerWidth, popperWidth]);

  return {
    direction,
  };
};

export { useChildItemDirection, DIRECTION_RIGTH, DIRECTION_LEFT };
