import React from 'react';
import styled from 'styled-components';

import RemoveIcon from '@material-ui/icons/Remove';
import TextDeprecated from 'design-system/atoms/TextDeprecated';
import IconAndTextButton from 'design-system/molecules/IconAndTextButton';

import Link from 'components/Link';
import StoriesStatusCounters from 'components/StoriesStatusCounters/StoriesStatusCounters';

export default props => {
  const { stories, epicUri, epicLinkName, unlinkEpic, unlinking } = props;

  return (
    <ClubhouseWrapper>
      <ClubhouseTitle>Clubhouse</ClubhouseTitle>
      <ClubhouseFieldsWrapper>
        <TextDeprecated>
          {'Epic: '}
          <Link href={epicUri} target="_blank">
            {epicLinkName}
          </Link>
        </TextDeprecated>
        <UnlinkButton text="Unlink Epic" icon={RemoveIcon} onClick={() => unlinkEpic()} disabled={unlinking} />
      </ClubhouseFieldsWrapper>
      {stories && stories.length > 0 && <StoriesStatusCounters stories={stories} />}
    </ClubhouseWrapper>
  );
};

const ClubhouseWrapper = styled.div`
  padding: 15px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  height: 150px;
  flex: 1;
`;

const ClubhouseTitle = styled(TextDeprecated)`
  &&&& {
    font-size: 16px !important;
  }
`;

const ClubhouseFieldsWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UnlinkButton = styled(IconAndTextButton)`
  &&&& {
    margin-left: 50px;
  }
`;
