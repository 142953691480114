const MONTH = 'month';
const MONTHLY = 'monthly';
const YEAR = 'year';
const ANNUALLY = 'annually';

const MAP_INTERVAL_TO_RECURRENCY = {
  [MONTH]: MONTHLY,
  [YEAR]: ANNUALLY,
};

export const ROADMAP_PLAN = 'roadmap';
export const PORTFOLIO_PLAN = 'portfolio';
export const OUTCOME_ACCELERATOR_PLAN = 'outcome';
export const COMPLETE_PLAN = 'complete';
export const CUSTOM_PLAN = 'custom';

export const PLANS_BY_TYPE = {
  [OUTCOME_ACCELERATOR_PLAN]: 'Outcome Accelerator',
  [PORTFOLIO_PLAN]: 'Portfolio',
  [ROADMAP_PLAN]: 'Roadmap',
  [COMPLETE_PLAN]: 'Complete',
  [CUSTOM_PLAN]: 'Custom',
};

export function getPlan() {
  if (this.props.billingInfo?.plan_type) {
    if (this.props.billingInfo.plan_type.includes(OUTCOME_ACCELERATOR_PLAN)) return PLANS_BY_TYPE[OUTCOME_ACCELERATOR_PLAN];
    if (this.props.billingInfo.plan_type.includes(PORTFOLIO_PLAN)) return PLANS_BY_TYPE[PORTFOLIO_PLAN];
    if (this.props.billingInfo.plan_type.includes(ROADMAP_PLAN)) return PLANS_BY_TYPE[ROADMAP_PLAN];
    if (this.props.billingInfo.plan_type.includes(COMPLETE_PLAN)) return PLANS_BY_TYPE[COMPLETE_PLAN];
    if (this.props.billingInfo.plan_type.includes(CUSTOM_PLAN)) return PLANS_BY_TYPE[CUSTOM_PLAN];
  }

  return this.props.billingInfo?.product_name;
}

export function getNumEditors() {
  if (this.props.billingInfo) {
    if (this.props.billingInfo.num_seats) return `${this.props.billingInfo.num_seats} Editors`;
  }

  return '';
}

export function getUnpaidEditors() {
  if (this.props.billingInfo) {
    if (this.props.billingInfo.unpaid_seats) return this.props.billingInfo.unpaid_seats;
  }

  return 1;
}

export function getTotalEditors() {
  if (this.props.billingInfo) {
    if (this.props.billingInfo.total_editors) {
      return `${this.props.billingInfo.total_editors} Editors`;
    }
  }

  return '';
}

export function getRecurrency() {
  if (this.props.billingInfo?.recurrency) {
    return MAP_INTERVAL_TO_RECURRENCY[this.props.billingInfo.recurrency];
  }

  // legacy plans
  if (
    this.props.billingInfo &&
    ['starting-monthly', 'scaling-monthly', 'enterprise-monthly'].indexOf(this.props.billingInfo?.plan_type) !== -1
  ) {
    return MONTHLY;
  }

  return ANNUALLY;
}

export function numberWithCommas(x) {
  const parts = x.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}
