import { useCallback, useMemo, useRef, useEffect } from 'react';

import callAgGridApiMethod from 'utils/callAgGridApiMethod';

export const ROW_HEIGHT = 40;
export const MAX_ROWS_NUMBER = 10;
export const TABLE_HEADER_HEIGHT = 53;

const useGridTableApi = rows => {
  const gridTableApi = useRef();
  const gridTableColumnApi = useRef();

  const tableHeightInPx = useMemo(() => {
    const bodyHeight = rows.length <= MAX_ROWS_NUMBER ? rows.length * ROW_HEIGHT : ROW_HEIGHT * MAX_ROWS_NUMBER;

    return `${bodyHeight + TABLE_HEADER_HEIGHT}px`;
  }, []);

  const onTableGridReady = useCallback(
    params => {
      gridTableApi.current = params.api;
      gridTableColumnApi.current = params.columnApi;

      callAgGridApiMethod(gridTableApi.current, _api => _api.showLoadingOverlay());
      callAgGridApiMethod(gridTableApi.current, _api => _api.setRowData(rows));
      callAgGridApiMethod(gridTableApi.current, _api => _api.redrawRows());

      gridTableApi.current.addEventListener('rowDataUpdated', () =>
        callAgGridApiMethod(gridTableApi.current, _api => _api.redrawRows()),
      );

      setTimeout(() => {
        gridTableApi.current.sizeColumnsToFit();
      }, 250);
    },
    [rows],
  );

  useEffect(() => {
    callAgGridApiMethod(gridTableApi.current, _api => _api.setRowData(rows));
  }, [rows]);

  return { tableHeightInPx, gridTableApi, gridTableColumnApi, onTableGridReady };
};

export default useGridTableApi;
