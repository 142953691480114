import React, { useMemo } from 'react';
import { propOr } from 'ramda';
import styled from 'styled-components';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpIcon from '@material-ui/icons/Help';
import { truncateText } from 'utils';

import { LEAD_LINES_PLUGIN } from 'components/ReactChart/utils/plugins';

import DoughnutChart from 'components/ReactChart/DoughnutChart';
import AllocationReportSummaryTable from 'containers/AllocationReportSummaryTable';
import HelpLink from 'design-system/atoms/HelpLink/index';
import InlineIcon from 'design-system/atoms/InlineIcon/index';
import SearchableDndListFieldWithChips from 'design-system/molecules/SearchableDndListFieldWithChips/index';
import MultiSelect from 'design-system/atoms/MultiSelect/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';

import AllocattionReportPreferences from 'containers/AllocattionReportPreferences';
import flattenItemsWithChildren from 'utils/flattenItemsWithChildren';
import renameObjectProperty from 'utils/renameObjectProperty';
import { formatTimeframesForDndList } from './utils';
import getTooltipOptions from 'utils/charts/getTooltipOptions';

const CHART_LABEL_MAX_WIDTH = 60;

const getMetricChartTooltipTitle = tooltipItem => propOr('', 'formattedValue')(tooltipItem?.[0]);
const getMetricChartTooltipLabel = tooltipItem => truncateText(propOr('', 'label')(tooltipItem), CHART_LABEL_MAX_WIDTH);

export default ({
  updateLsState,
  lsState,
  chartData,
  tableData,
  groupOptions,
  projects,
  timeframes,
  onTimeframesChange,
  useDndList,
  getSystemFieldName,
}) => {
  const { selectedGroupBy, selectedTimeframes } = lsState;

  const chartHasData = useMemo(() => chartData && chartData.datasets?.[0]?.data?.length > 0, [chartData]);
  const chartTitle = useMemo(
    () => (selectedGroupBy?.key ? `Allocation by ${getSystemFieldName(selectedGroupBy.key)}` : ''),
    [selectedGroupBy, getSystemFieldName],
  );

  const renderSubTitle = () => {
    const timeframesList = flattenItemsWithChildren(timeframes)
      .filter(timeframe => selectedTimeframes.includes(timeframe.id?.toString()))
      .map(timeframe => timeframe.title)
      .join(', ');

    return timeframesList ? <b>in {timeframesList}</b> : null;
  };

  const renderCurrentFilter = () => (
    <StyledFormControl fullWidth margin="dense">
      {useDndList ? (
        <SearchableDndListFieldWithChips
          label="Timeframes"
          placeholder="Select timeframes to display"
          items={renameObjectProperty(timeframes, 'title', 'label')}
          selectedItems={formatTimeframesForDndList(selectedTimeframes, timeframes)}
          onSelectItems={onTimeframesChange}
        />
      ) : (
        <MultiSelect
          options={timeframes}
          optionsMapper={{ label: 'title', value: 'id' }}
          label="Timeframes"
          placeholder="Select timeframes to display"
          onChange={onTimeframesChange}
          value={selectedTimeframes}
          hideCreateOption
        />
      )}
    </StyledFormControl>
  );

  return (
    <Wrapper>
      <PreferencesPanel>
        <PreferencesPanelSummary expandIcon={<ExpandMoreIcon />}>
          <TooltipOnOverflow text={renderSubTitle()} vertical>
            <PanelTitle>
              Planned Allocation (<span>{renderSubTitle()}</span>)
            </PanelTitle>
          </TooltipOnOverflow>
          <StyledHelpLink
            href="https://dragonboat.zendesk.com/hc/en-us/articles/360016293834"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InlineIcon>
              <HelpIcon />
            </InlineIcon>
          </StyledHelpLink>
        </PreferencesPanelSummary>
        <ExpansionPanelDetails>
          <Preferences>
            {renderCurrentFilter()}
            <AllocattionReportPreferences
              lsState={lsState}
              updateLsState={updateLsState}
              groupOptions={groupOptions}
              showDuration
              showSumBy
            />
          </Preferences>
        </ExpansionPanelDetails>
      </PreferencesPanel>
      <ChartContainer>
        {chartHasData ? <ChartTitle>{chartTitle}</ChartTitle> : null}
        <DoughnutChart
          data={chartData}
          options={{
            plugins: {
              legend: {
                // position: 'bottom',
                display: false,
              },
              tooltip: getTooltipOptions(getMetricChartTooltipTitle, getMetricChartTooltipLabel),
            },
            radius: '50%',
            aspectRatio: 1.4,
          }}
          plugins={[LEAD_LINES_PLUGIN]}
        />
        <SummaryContainer>
          <AllocationReportSummaryTable
            {...tableData}
            data={tableData.tableData}
            lsState={lsState}
            projects={projects}
            isDrawerView
          />
        </SummaryContainer>
      </ChartContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 540px;
  padding: 10px;
`;

const PreferencesPanel = styled(ExpansionPanel)`
  &&&& {
    box-shadow: none;
    border-bottom: 1px solid #ddd;
    padding-bottom: 13px;
  }
`;

const PreferencesPanelSummary = styled(ExpansionPanelSummary)`
  &&&& {
    min-height: unset;

    > div:nth-child(1) {
      padding-left: 30px;
      line-height: 22px;
      margin: 0;
      align-items: center;
    }

    > div:nth-child(2) {
      left: 8px;
      right: auto;

      span {
        color: #333;
      }
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  && {
    margin: 12px 0;
  }
`;

const PanelTitle = styled(Typography)`
  &&&& {
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: ${({ theme }) => theme.typography.fontSizeMedium}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;

    :last-child {
      padding-right: 0;
    }
  }
`;

const StyledHelpLink = styled(HelpLink)`
  > div {
    display: flex;
  }
`;

const Preferences = styled.div``;

const ChartContainer = styled.div`
  margin-top: 20px;
`;

const ChartTitle = styled.h3`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSizeMedium}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  text-align: center;
  margin-top: 45px;
`;

const SummaryContainer = styled.div`
  &&&& {
    thead th {
      padding-bottom: 5px;
    }

    tbody .allocation-title-cell {
      padding-right: 24px;
    }
  }
`;
