import { useCallback } from 'react';

const phaseNodesBelongToDifferentPlanningStage = (node1, node2) => {
  return node1.data.planning_stage !== node2.data.planning_stage;
};

const usePhasesSettingsGrid = ({ addPhaseWithoutSave, updatePhaseById, switchPhasesRowOrder }) => {
  const addNewPhaseToPlanningStage = useCallback(
    (data, { node }) => {
      if (!node.expanded) {
        node.setExpanded(true);
      }

      return addPhaseWithoutSave(data.id);
    },
    [addPhaseWithoutSave],
  );

  const handleRowDrag = useCallback(
    async (currentNode, lastOverNode, { position }) => {
      const currentNodeDataId = currentNode.data.id;

      const lastOverNodeDataId = lastOverNode.data.id;

      if (lastOverNode.data.isGroupRow) {
        // If dropping over a Planning Stage
        const firstPhaseNode = lastOverNode.childrenAfterGroup.find(childNode => childNode.data.id !== currentNodeDataId);

        if (firstPhaseNode) {
          // That Planning Stage contains other Phases,
          // so we place our dragging Phase on top of the other Phases.
          return switchPhasesRowOrder(
            currentNodeDataId,
            firstPhaseNode.data.id,
            {
              planning_stage: lastOverNodeDataId,
            },
            'top',
          );
        } else if (currentNode.data.planning_stage !== lastOverNodeDataId) {
          // That Planning Stage is empty and is different from the Phase's Planning Stage,
          // so we simply insert the Phase in there.
          return updatePhaseById(currentNodeDataId, {
            planning_stage: lastOverNodeDataId,
          });
        }
      } else if (phaseNodesBelongToDifferentPlanningStage(currentNode, lastOverNode)) {
        return switchPhasesRowOrder(
          currentNodeDataId,
          lastOverNodeDataId,
          {
            planning_stage: lastOverNode.data.planning_stage,
          },
          position,
        );
      } else {
        return switchPhasesRowOrder(currentNodeDataId, lastOverNodeDataId, null, position);
      }
    },
    [updatePhaseById, switchPhasesRowOrder],
  );

  return { addNewPhaseToPlanningStage, handleRowDrag };
};

export default usePhasesSettingsGrid;
