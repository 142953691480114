import React from 'react';
import PublicPageTemplateComponent from 'src/design-system/templates/PublicPageTemplate/PublicPageTemplateComponent';
import LoginFormComponent from './LoginForm/LoginFormComponent';

export default ({
  email,
  password,
  useOkta,
  useAzure,
  useGoogle,
  useEmailPassword,
  organization,
  autologin,
  handleEmailValue,
  handlePasswordValue,
  handleLogin,
  oktaErrorMessage,
  errorMessage,
  googleErrorMessage,
  azureButtonClicked,
  oktaButtonClicked,
}) => {
  return (
    <PublicPageTemplateComponent>
      <LoginFormComponent
        autologin={autologin}
        email={email}
        password={password}
        useOkta={useOkta}
        useAzure={useAzure}
        useGoogle={useGoogle}
        useEmailPassword={useEmailPassword}
        organization={organization}
        handleEmailValue={handleEmailValue}
        handlePasswordValue={handlePasswordValue}
        handleLogin={handleLogin}
        oktaErrorMessage={oktaErrorMessage}
        errorMessage={errorMessage}
        googleErrorMessage={googleErrorMessage}
        azureButtonClicked={azureButtonClicked}
        oktaButtonClicked={oktaButtonClicked}
      />
    </PublicPageTemplateComponent>
  );
};
