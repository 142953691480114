import axios from 'axios';

import { createThunk } from 'utils/store/thunk';
import { FETCH_TEMPLATES, FETCH_TEMPLATES_CATEGORIES } from './types';

const fetchTemplates = params => createThunk(FETCH_TEMPLATES, axios.get('/api/templates', { params }));

const fetchTemplatesCategories = params =>
  createThunk(FETCH_TEMPLATES_CATEGORIES, axios.get('/api/template-categories', { params }));

export { fetchTemplates, fetchTemplatesCategories };
