import reduceReducers from 'reduce-reducers';

import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';

import { FETCH_REQUESTS_MERGED } from './types';

export const initialState = {
  forms: {},
  operations: bulkThunkInitialState([FETCH_REQUESTS_MERGED]),
};

const featureReducer = (state = initialState, action) => {
  switch (action.type) {
    default: {
      return state;
    }
  }
};

const operationsReducer = getThunksReducers([FETCH_REQUESTS_MERGED]);

const reducer = reduceReducers(initialState, featureReducer, ...operationsReducer);

export default reducer;
