const getPage = (options, next, current) => {
  if (options.page) {
    return options.page;
  }

  if (next) {
    return next;
  }

  return current;
};

export default getPage;
