import { __, defaultTo, divide, equals, head, isNil, pipe, prop, sort, subtract } from 'ramda';
import { IDEA_LAYER } from 'store/projects/constants';

const PROJECT_PLANNED_POINTS = 'planned_points';
const REPORTED_POINTS = 'total_points';
const CLOSED_POINTS = 'points_closed';

const defaultToEmptyArray = defaultTo([]);
const isEqualsZero = equals(0);
const subtractOne = subtract(__, 1);
const defaultToZero = defaultTo(0);

const sortArrayByLatestUpdatedAtValue = pipe(
  defaultToEmptyArray,
  sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)),
);

export const calcPointsVariance = (plannedPoints, reportedPoints) => {
  const areParamsInvalid = isEqualsZero(plannedPoints) || isNil(plannedPoints) || isNil(reportedPoints);

  if (areParamsInvalid) {
    return 0;
  }

  // 4 decimal since it is a percentage, *100 it will become 2 decimal
  return +parseFloat(subtractOne(divide(reportedPoints, plannedPoints))).toFixed(4);
};

export const getProjectPointsValues = project => {
  // this won't be needed once we do the improvements and checks on the BE
  if (project?.layer !== IDEA_LAYER)
    return {
      pointsVariance: 0,
      plannedPoints: 0,
      reportedPoints: 0,
      completedPoints: 0,
    };

  const sortedProgressValues = sortArrayByLatestUpdatedAtValue(project.currentProgresses);

  const progressValuesForIntegration = sortedProgressValues.filter(value => value.org_integration_id);

  const progressValuesForProject = progressValuesForIntegration.length
    ? head(progressValuesForIntegration)
    : head(sortedProgressValues);

  const reportedPoints = defaultToZero(prop(REPORTED_POINTS, progressValuesForProject));
  const completedPoints = defaultToZero(prop(CLOSED_POINTS, progressValuesForProject));
  const plannedPoints = defaultToZero(prop(PROJECT_PLANNED_POINTS, project));

  const pointsVariance = calcPointsVariance(plannedPoints, reportedPoints);

  return {
    plannedPoints,
    pointsVariance,
    reportedPoints,
    completedPoints,
  };
};
