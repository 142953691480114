import React from 'react';
import styled from 'styled-components';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import NewLabelIcon from 'design-system/atoms/NewLabelIcon/index';

import { spacing } from 'design-system/theme';

import ShareView from 'containers/ShareView';

import RelationsConfigDropdown from './RelationsConfigDropdown';
import DisplayNodesMultiSelect from './DisplayNodesMultiSelect';

const CanvasControlsBar = ({
  pageId,
  onAddTextBoxClick,
  relationsConfig = {},
  displayPreferences = {},
  displayNodes,
  onToggleOkrRelations,
  onToggleOkrMetricsRelations,
  onToggleOkrProjectsRelations,
  onTogglePortfolioItemsRelations,
  onToggleTimeframeRelations,
  onToggleBoardRelations,
  onToggleMetricRelations,
  handleDisplayNodesChange,
  onToggleHighlightHealth,
  onToggleOwnerAvatar,
  onToggleProgress,
  onToggleStatus,
  hasBet = false,
  availableNodes,
  viewsDropdownTabs,
}) => {
  return (
    <Wrapper>
      <ControlsContainer>
        <LeftSection>
          <DisplayNodesMultiSelect
            displayNodes={displayNodes}
            onChange={handleDisplayNodesChange}
            availableNodes={availableNodes}
            hasBet={hasBet}
          />
        </LeftSection>
        <RightSection>
          <ShareView pageId={pageId} viewsDropdownTabs={viewsDropdownTabs} id="view-icon" />
          <ButtonIcon title="Add text box" onClick={onAddTextBoxClick}>
            <NewLabelIcon />
          </ButtonIcon>
          <RelationsConfigDropdown
            relationsConfig={relationsConfig}
            displayPreferences={displayPreferences}
            onToggleOkrRelations={onToggleOkrRelations}
            onToggleOkrMetricsRelations={onToggleOkrMetricsRelations}
            onToggleOkrProjectsRelations={onToggleOkrProjectsRelations}
            onTogglePortfolioItemsRelations={onTogglePortfolioItemsRelations}
            onToggleTimeframeRelations={onToggleTimeframeRelations}
            onToggleBoardRelations={onToggleBoardRelations}
            onToggleMetricRelations={onToggleMetricRelations}
            onToggleHighlightHealth={onToggleHighlightHealth}
            onToggleOwnerAvatar={onToggleOwnerAvatar}
            onToggleProgress={onToggleProgress}
            onToggleStatus={onToggleStatus}
            availableNodes={availableNodes}
          />
        </RightSection>
      </ControlsContainer>
    </Wrapper>
  );
};

export default CanvasControlsBar;

const Wrapper = styled.div``;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing(1.25)}px;
`;

const LeftSection = styled.div`
  display: flex;
  flex: 1 1 33%;
  align-items: center;
  justify-content: flex-start;
`;

const RightSection = styled.div`
  display: flex;
  flex: 1 1 33%;
  align-items: center;
  justify-content: flex-end;
  gap: ${spacing(0.5)}px;
`;
