import React, { useState, useMemo, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import CommentIcon from 'design-system/atoms/CommentIcon';
import MuiBadge from '@material-ui/core/Badge';
import { FeatureFlags } from '@dragonboat/config';

import theme, { spacing } from 'design-system/theme';
import useFeatureFlags from 'hooks/useFeatureFlags';

import DragonIconButton from 'design-system/atoms/DragonIconButton/index';


import useDashboardComments from 'hooks/dashboards/useDashboardComments';
import DashboardCommentsLightbox from 'containers/CommentLightbox/Dashboards/DashboardCommentsLightbox';

const exist = Boolean;

const HeaderOptionsComponent = props => {
  const [isCommentsLightboxOpen, setCommentsLightboxOpen] = useState(false);

  const activeDashboardView = props.activeDashboard;

  const hasDashboardComments = useFeatureFlags([FeatureFlags.HAS_DASHBOARD_COMMENTS]);

  const { commentsCount, onFetchCommmentsHandler } = useDashboardComments({ dashboard: activeDashboardView });

  useEffect(() => {
    if (!activeDashboardView?.id) {
      return;
    }

    if (!hasDashboardComments) {
      return;
    }

    onFetchCommmentsHandler();
  }, [onFetchCommmentsHandler, activeDashboardView?.id]);

  const hasDashboardId = useMemo(() => exist(props.dashboardId), [props.dashboardId]);

  return (
    <Wrapper>
      {hasDashboardId && hasDashboardComments && (
        <Badge badgeContent={commentsCount || 0}>
          <ButtonIconButton
            id="comments-dashboard-button"
            type="transparent"
            icon={<CommentIcon color={theme.palette.text.primary} />}
            onClick={() => setCommentsLightboxOpen(true)}
          />
        </Badge>
      )}
      <DashboardCommentsLightbox
        isOpen={isCommentsLightboxOpen}
        item={activeDashboardView}
        onClose={() => setCommentsLightboxOpen(false)}
      />
    </Wrapper>
  );
};

export default withRouter(HeaderOptionsComponent);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ButtonIconButton = styled(DragonIconButton)`
  &&&& {
    margin-right: 14px;
  }
`;

const Badge = styled(MuiBadge)`
  &&&& {
    span {
      color: ${props => props.theme.palette.text.primary};
      font-weight: ${props => props.theme.typography.fontWeightBold};
      background: transparent;
      top: ${spacing(1)}px;
      right: ${spacing(1.5)}px;
    }
  }
`;
