import React, { memo } from 'react';
import styled from 'styled-components';

import RoadmapTable from 'design-system/organisms/RoadmapTable/index';


import { BASE_Z_INDEX, MAX_HEIGHT, SLOT_WIDTH } from '../helpers';

const TimeBar = memo(({ slots, slotWidth = SLOT_WIDTH }) => (
  <RoadmapTable.Header sticky top maxHeight={MAX_HEIGHT} zIndex={BASE_Z_INDEX}>
    <Content>
      {slots.map(slot => (
        <Slot key={slot.parent}>
          <SlotParent>{slot.parent}</SlotParent>
          <SlotChildren>
            {slot.children.map(child => (
              <SlotChildItem key={child} slotWidth={slotWidth}>
                {child}
              </SlotChildItem>
            ))}
          </SlotChildren>
        </Slot>
      ))}
    </Content>
  </RoadmapTable.Header>
));

TimeBar.displayName = 'TimeBar';

export default TimeBar;

const Content = styled.div`
  &&&& {
    display: flex;
  }
`;

const Slot = styled.div`
  &&&& {
    border-right: 1px solid ${({ theme }) => theme.palette.border.mercury};
    font-size: ${({ theme }) => `${theme.typography.fontSize}px`};
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.primary};
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
  }
`;

const SlotParent = styled.div`
  &&&& {
    height: 35px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.border.mercury};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ theme }) => `${theme.typography.fontSizeSmall}px`};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-transform: capitalize;
    color: ${({ theme }) => theme.palette.gridColors.mineShaft};
  }
`;

const SlotChildren = styled.div`
  &&&& {
    display: flex;
  }
`;

const SlotChildItem = styled.div`
  &&&& {
    width: ${({ slotWidth }) => `${slotWidth}px`};
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${({ theme }) => theme.palette.border.mercury};
    font-size: ${({ theme }) => `${theme.typography.fontSizeSmall}px`};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-transform: capitalize;
    color: #b1b1b1;

    &:last-child {
      border-right: unset;
    }
  }
`;
