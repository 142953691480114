import React from 'react';
import styled from 'styled-components';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import LinkIcon from '@material-ui/icons/Link';

import Text from 'design-system/atoms/Text';
import ButtonIcon from 'design-system/molecules/ButtonIcon';
import OpenInFull from 'design-system/atoms/DragonIcons/OpenInFull';
import { spacing } from 'design-system/theme';

import usePermissions from 'hooks/permissions/usePermissions';

const Topbar = ({ items, onClickCreate, onClickLink, onClickExpand, isLoading }) => {
  const { canUpdate, canCreate } = usePermissions();
  const canCreateProject = canCreate(PERMISSION_RESOURCES.project);
  const canUpdateProject = canUpdate(PERMISSION_RESOURCES.project);

  const hasEmptyRow = items.some(item => !item.id);

  const disableCreateButton = !canCreateProject || hasEmptyRow || isLoading;
  const disableLinkButton = !canUpdateProject || hasEmptyRow || isLoading;

  return (
    <Container>
      <Text variant="h3">Linked Portfolio Items</Text>
      <FlexWrapper>
        {onClickCreate ? (
          <StyledButton onClick={onClickCreate} disabled={disableCreateButton} size="small">
            <AddIcon />
            Create a Portfolio Item
          </StyledButton>
        ) : null}
        <StyledButton onClick={onClickLink} disabled={disableLinkButton} size="small">
          <LinkIcon />
          Link a Portfolio Item
        </StyledButton>
        {onClickExpand ? (
          <StyledButtonIcon small onClick={onClickExpand}>
            <OpenInFull />
          </StyledButtonIcon>
        ) : null}
      </FlexWrapper>
    </Container>
  );
};

export default Topbar;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${spacing()}px 0 ${spacing()}px ${spacing(0.75)}px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${spacing(2)}px;
`;

const StyledButton = styled(Button)`
  && {
    color: ${({ theme }) => theme.palette.primary.main};

    span {
      gap: ${spacing()}px;
    }
  }
`;

const StyledButtonIcon = styled(ButtonIcon)`
  svg {
    fill: ${({ theme }) => theme.palette.text.primary};
    width: ${({ theme }) => theme.typography.fontSizeMediumSmallRem}rem;
    height: ${({ theme }) => theme.typography.fontSizeMediumSmallRem}rem;
  }
`;
