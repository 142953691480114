import axios from 'axios';

import throwRequestError from '../utils/throwRequestError';
import { FETCH_ALL_RELEASE_CAPACITY_ALLOCATION, UPSERT_RELEASE_CAPACITY_ALLOCATION } from './types';
import { createThunk } from 'utils/store/thunk';

const fetchAllReleaseCapacityAllocation = (metadataField, projectFilters) =>
  createThunk(
    FETCH_ALL_RELEASE_CAPACITY_ALLOCATION,
    axios
      .post('/api/v1/release-capacity-allocation', {
        metadataField,
        projectFilters,
      })
      .catch(throwRequestError),
  );

const upsertReleaseCapacityAllocation = (timeframeId, resourcing, metadataField, metadataId) =>
  createThunk(
    UPSERT_RELEASE_CAPACITY_ALLOCATION,
    axios
      .patch('/api/v1/release-capacity-allocation', {
        timeframeId,
        resourcing,
        metadataField,
        metadataId,
      })
      .catch(throwRequestError),
    { timeframeId, metadataField, metadataId },
  );

export { fetchAllReleaseCapacityAllocation, upsertReleaseCapacityAllocation };
