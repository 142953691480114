import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { sendResetPasswordInstructions, clearResetPassword } from 'src/store/login';

import { getLogin } from 'src/store/login/selectors';
import { getDefaultRoute } from 'utils';
import queryString from 'query-string';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();

    const login = useSelector(getLogin);

    const [email, setEmail] = useState('');

    useEffect(() => {
      dispatch(clearResetPassword());

      const { email: urlEmail } = queryString.parse(window.location.search);

      setEmail(urlEmail);

      if (login.isAuthenticated) {
        return <Redirect to={getDefaultRoute(login.currentUser)} />;
      }
    }, []);

    const handleEmailValue = value => {
      setEmail(value);
    };

    const handleSubmit = () => {
      dispatch(sendResetPasswordInstructions(email, props));
    };

    return <Component resetPasswordEmail={email} handleEmailValue={handleEmailValue} handleSubmit={handleSubmit} />;
  };
};

export default componentHOC;
