import uniq from 'lodash/uniq';
import { ADMIN_USER, OWNER_USER, LEADER_USER, READ_ONLY_USER, RESOURCE_USER } from '@dragonboat/permissions';
import UserTypeMapper from 'utils/UserTypeMapper';

export const isAdmin = user => [ADMIN_USER, OWNER_USER, LEADER_USER].includes(user.role_id);
export const isReadOnly = user => [READ_ONLY_USER].includes(user.role_id);
export const isResourceUser = user => [RESOURCE_USER].includes(user.role_id);

const TEAMS_LEVEL_ONE = 0;
const TEAMS_LEVEL_TWO = 1;
const TEAMS_TITLE_PATH = 'team.title';
const TEAMS_2_TITLE_PATH = 'team2.title';

/**
 * User type
 * @typedef {Object} User
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} teamName
 * @property {string} skillName
 * @property {boolean} capacity
 * @property {string} status
 */

// fields order is important, and must follow the same one that is in the columnDefs file
const USERS_FIELDS = ['first_name', 'last_name', 'teamName', 'skillName', 'capacity', 'status'];

/**
 * @param {Array[Array]} array
 * @param {string} field - the first field that will be filled.
 * I.e., if field is "teamName" the first element of the array will be the value of this field
 * @returns {Array[User]}
 */
export const transformArrayIntoUsers = (array, startField) => {
  if (!(array instanceof Array) || !array.length) {
    return [];
  }

  return array
    .map(userArray => {
      if (!userArray || !(userArray instanceof Array) || !userArray.length || userArray.every(el => !el)) {
        return null;
      }

      const userObject = {};
      let fields = USERS_FIELDS;

      if (startField && USERS_FIELDS.includes(startField)) {
        const startFieldIndex = USERS_FIELDS.indexOf(startField);

        fields = USERS_FIELDS.slice(startFieldIndex);
      }

      userArray.forEach((data, index) => {
        const key = fields[index];

        userObject[key] = data;
      });

      return userObject;
    })
    .filter(user => user);
};

export const transformMergeItemLabel = (item, allItems) => {
  const isMergingUsersFromDifferentTeams =
    allItems && uniq(allItems.map(item => (item.team ? item.team.title : null))).length > 1;

  const team = isMergingUsersFromDifferentTeams && item.team && item.team.title ? ` (${item.team.title})` : '';
  const lastName = item.last_name || '';

  const role = UserTypeMapper.find(type => String(type.value) === String(item.role_id))?.label;

  return `${item.first_name} ${lastName}${team}${role ? ` - ${role}` : ''}`;
};

export const getToolTipPerUserIntegrationType = orgIntegrations => userIntegration => {
  switch (userIntegration.integrationType) {
    default:
      return null;
  }
};

/**
 * Gets the payload to update the user team
 *
 * @param {Object} selectedTeam
 * @returns {Object}
 */
export const getTeamsUpdatePayload = (selectedTeam = {}) => {
  const { level, title } = selectedTeam;

  switch (level) {
    case TEAMS_LEVEL_ONE:
      return { [TEAMS_TITLE_PATH]: title };
    case TEAMS_LEVEL_TWO:
      return { [TEAMS_2_TITLE_PATH]: title };
    default:
      return { [TEAMS_TITLE_PATH]: null, [TEAMS_2_TITLE_PATH]: null };
  }
};
