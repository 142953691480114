export default (multioptions, filter) => {
  if (
    !multioptions || !(multioptions instanceof Array) ||
    !filter || !(filter instanceof Array) || !filter.length
  ) {
    return false;
  }

  const multioptionsIds = multioptions.map(option => option.id);
  const filterSelected = filter.filter(f => f.selected).map(f => f.id);

  if (!multioptionsIds.length) {
    multioptionsIds.push(null);
  }
  return filterSelected.some(filter => multioptionsIds.includes(filter));
};
