// External dependencies
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

import { defaultTo, either, isEmpty, isNil, pipe, prop } from 'ramda';

import { getData, isError, isLoading, isUninitialized } from 'utils/store/thunk';

import { CLONE_USER_VIEW, FETCH_USER_VIEWS, SHARE_VIEW_ON_INTEGRATION, SUBSCRIBE_USER_TO_USER_VIEW } from './types';
import { isDashboardView, isNotDashboardView, sortByUpdatedDate } from 'utils/userViews';
import { checkCurrentStateIsSameAsActiveView, isViewADefaultViewFromUser } from './utils';
import { initSaveDialogState } from './reducer';
import { PAGES_STATE_TO_SAVE } from './consts';

import { getPathFromPageId } from 'utils/pages';
import { getNormalizedUsers } from 'store/users/selectors';
import { isCreatingViewRecurringNotification } from 'features/UserViewRecurringNotification/store/selectors';

const defaultEmptyObject = defaultTo({});
const expirationDateNotSet = pipe(prop('expiration_date'), either(isNil, isEmpty));

const getPath = view => view && getPathFromPageId(view.page);

const withPath = view => {
  if (!view || isEmpty(view)) return view;
  return { ...view, path: getPath(view) };
};

export function getState(state) {
  return state.userViews;
}

const getUserViewsOperations = state => {
  return defaultEmptyObject(state.userViews.operations);
};

export const getUserViews = createSelector(
  [state => state.login?.currentUser, state => getState(state).views, state => getNormalizedUsers(state)],
  (currentUser, views = [], users = {}) => {
    views = views || [];
    const _mapUserView = view => ({
      ...view,
      isOwner: view.user_id === currentUser.id,
      user: users[view.user_id],
    });

    return views.filter(expirationDateNotSet).map(_mapUserView).map(withPath).sort(sortByUpdatedDate);
  },
);

/**
 * @function getUserViewsWithoutDashboardViews
 *
 * selectot to get all userViews that are not dashboard views
 *
 * @param  {Object} state
 * @return {Array}
 */
export const getUserViewsWithoutDashboardViews = createCachedSelector([state => getState(state), getUserViews], (_, userViews) =>
  userViews.filter(isNotDashboardView),
)(() => `getUserViewsWithoutDashboardViews`);

export const getUserFavoriteViews = createCachedSelector([state => getState(state), getUserViews], (_, userViews) =>
  userViews.filter(view => view.favorites?.length),
)(() => `getUserFavoriteViews`);

export const getUserDashboards = createCachedSelector([state => getState(state), getUserViews], (_, userViews) =>
  userViews.filter(isDashboardView),
)(() => `getUserDashboards`);

export const getAllPageUserViews = createSelector([getState, getUserViews, (_, page) => page], (_, state, page) =>
  state.filter(v => v.page === page),
);

export const getPageUserViews = createSelector([getState, getUserViews, (_, page) => page], (_, state, page) =>
  state.filter(v => v.page === page && !v.default_view),
);

export const getUserViewById = createSelector([getState, getUserViews, (_, id) => id], (_, state, id) => {
  return state.find(v => v.id === +id);
});

export const getUserViewsDialogProperties = createSelector(getState, state => state.saveDialog ?? initSaveDialogState);

export const getDefaultUserViewForPage = createSelector([getUserViews, (_, page) => page], (state, page) => {
  const defaultViewsForPage = state
    .filter(v => v.page === page && isViewADefaultViewFromUser(v))
    .map(withPath)
    .sort(sortByUpdatedDate);

  if (defaultViewsForPage.length) return defaultViewsForPage[0];

  return null;
});

export const getLoadedPublicSharedViewData = createSelector([getState], state => {
  const publicSharedView = state.publicSharedView || {
    error: false,
    loadedView: {},
    user: null,
    token: '',
  };

  return {
    ...state.publicSharedView,
    loadedView: withPath(publicSharedView.loadedView),
  };
});

export const getUserViewsIsLoading = createSelector(getUserViewsOperations, state => isLoading(state, FETCH_USER_VIEWS));

export const getUserViewsIsUninitialized = createSelector(getUserViewsOperations, state =>
  isUninitialized(state, FETCH_USER_VIEWS),
);

export const getFetchUserViewsDataFulfilled = createSelector(
  getUserViewsOperations,
  userViewsOperations => getData(userViewsOperations, FETCH_USER_VIEWS)?.data,
);

export const getUserViewSubscribedFulfilled = createSelector(
  getUserViewsOperations,
  userViewsOperations => getData(userViewsOperations, SUBSCRIBE_USER_TO_USER_VIEW)?.data,
);

export const getUserViewSubscribeFailed = createSelector(getUserViewsOperations, userViewsOperations =>
  isError(userViewsOperations, SUBSCRIBE_USER_TO_USER_VIEW),
);

export const getClonedUserViewOperationResult = createSelector(
  getUserViewsOperations,
  state => getData(state, CLONE_USER_VIEW)?.data,
);

export const getUserTeamViews = createSelector([getState], state => {
  const userTeamViews = state.userTeamViews ? state.userTeamViews.map(withPath).sort(sortByUpdatedDate) : [];

  return userTeamViews;
});

export const doesViewHasDifferentState = createSelector(
  [state => state, (_, page) => page, (_, __, view) => view],
  (state, page, view) => {
    if (view && page) {
      const hasSameState = checkCurrentStateIsSameAsActiveView(state, page, view);

      return !hasSameState;
    }
    return false;
  },
);

export const getActiveViewForPage = createSelector([getState, (_, page) => page], (state, page) => {
  const activeViewsByPage = defaultEmptyObject(state?.activeViewsByPage);
  const publicViewPage = state?.publicSharedView?.loadedView?.page;

  if (publicViewPage && activeViewsByPage[publicViewPage]) {
    const activeView = activeViewsByPage[publicViewPage];

    activeView.isPublic = true;

    return withPath(activeView);
  }

  return withPath(activeViewsByPage[page]);
});

export const getActiveViewState = createSelector(
  [state => state, (_, page) => page, getActiveViewForPage],
  (state, page, activeViewForPage) => {
    if (!activeViewForPage && PAGES_STATE_TO_SAVE[page]) {
      return PAGES_STATE_TO_SAVE[page](state);
    }

    return defaultEmptyObject(activeViewForPage?.state);
  },
);

export const getViewsDialogOpen = createSelector(getState, state => state.viewsDialogOpen);

export const isSharingViewOnIntegration = state =>
  isLoading(getUserViewsOperations(state), SHARE_VIEW_ON_INTEGRATION) || isCreatingViewRecurringNotification(state);
