import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { prop, defaultTo } from 'ramda';

import getSystemFieldName from 'utils/getSystemFieldName';
import ProjectLink from 'design-system/atoms/ProjectLink/index';
import ObjectiveType from 'design-system/atoms/ObjectiveType/index';
import Text from 'design-system/atoms/Text/index';

import useDeepMemo from 'hooks/useDeepMemo';
import { materialColorsAlt, materialColors } from 'design-system/themes/default';
import Highlighter from 'react-highlight-words';
import DOMPurify from 'dompurify';
import { STATUS_COLOR_PROP_MAP } from 'constants/objectives';
import { selectHasKeyResults2 } from 'store/organization';

const htmlFields = ['status_summary', 'details'];
const FIELDS_TO_IGNORE_NEXT_TO_TITLE = ['integrationKey'];
const defaultToUndefined = defaultTo('Undefined');

const ProjectInfoCard = React.memo(
  ({
    displayFields: fieldsToDisplay,
    fieldsToIgnore,
    project,
    disabled,
    customFields,
    onClickProject,
    hideIcon,
    orgIntegrations,
    titleComponent: TitleComponent,
    localSearchStr,
    dataTestId,
  }) => {
    const _handleClickProject = React.useCallback(() => onClickProject(project.id), [project.id]);
    const displayFields = React.useMemo(() => fieldsToDisplay, [fieldsToDisplay]);
    const systemFields = useSelector(state => state.organization.organization.system_fields_name);
    const hasKeyResults2 = useSelector(selectHasKeyResults2);

    const _renderField = useDeepMemo(
      () =>
        ({ value, field, color, label }) => {
          if (!value || (fieldsToIgnore || []).includes(field)) return null;

          const cfObject = customFields.find(f => f.key === field);

          if (cfObject) return { label: cfObject.title, value };

          return { label: label || getSystemFieldName(field, systemFields), value };
        },
      [fieldsToIgnore],
    );
    const _renderSection = React.useMemo(
      () => items => {
        const filteredItens = items.map(i => _renderField(i)).filter(Boolean);

        return filteredItens.map((item, index) => {
          if (typeof item.value === 'object') return item.value;

          return (
            <DetailsLineItem key={item.value || index} breakLine>
              <span>{item.label}: </span>
              {item.value}
            </DetailsLineItem>
          );
        });
      },
      [],
    );

    if (!project) return '';

    const details = [
      ...(displayFields.includes('progress') && project.displayProgress
        ? [{ value: project.displayProgress, field: 'progress' }]
        : []),
    ].sort((a, b) => displayFields.indexOf(a.field) - displayFields.indexOf(b.field));

    const stringDisplayFields = displayFields.filter(f => !['health', 'key'].includes(f));
    const titleIndex = stringDisplayFields.findIndex(f => f === 'title');
    let fieldNextToTitleId = stringDisplayFields.length > titleIndex + 1 ? stringDisplayFields[titleIndex + 1] : null;

    if (FIELDS_TO_IGNORE_NEXT_TO_TITLE.includes(fieldNextToTitleId)) fieldNextToTitleId = null;
    const fieldNextToTitle = fieldNextToTitleId && details.find(f => f.field === fieldNextToTitleId);

    const _renderTitle = title => {
      if (!title) return '';
      if (!localSearchStr) return title;

      return (
        <HighlighterWrapper>
          <Highlighter highlightClassName="hightlightText" searchWords={[localSearchStr]} autoEscape textToHighlight={title} />
        </HighlighterWrapper>
      );
    };

    const iconStyle = useMemo(() => {
      return displayFields.includes('status_color')
        ? { color: prop(STATUS_COLOR_PROP_MAP[defaultToUndefined(project.status_color)])(materialColorsAlt) }
        : {};
    }, [project.status_color, displayFields]);

    const iconType = useMemo(() => (hasKeyResults2 ? 'keyResult2' : 'keyResult'), [hasKeyResults2]);

    return (
      <Wrapper data-testid={dataTestId} onClick={_handleClickProject} disabled={disabled} clickable={!!onClickProject}>
        <TitleLine>
          {!hideIcon && (
            <>
              <ObjectiveType
                style={{
                  width: '12px',
                  marginRight: '5px',
                  ...iconStyle,
                }}
                type={iconType}
                active={project.status === 'Active'}
              />{' '}
            </>
          )}
          {displayFields.includes('key') && !fieldsToIgnore.includes('key') && (
            <ProjectIdLink role="button" tabIndex="0" style={{ marginLeft: '5px' }} onClick={_handleClickProject}>
              {project.id}
            </ProjectIdLink>
          )}{' '}
          {displayFields.includes('title') && (
            <>
              <Title>
                {TitleComponent ? <TitleComponent>{project.title}</TitleComponent> : _renderTitle(project.title)}
                {fieldNextToTitle && <span style={{ minWidth: 50 }}> ({fieldNextToTitle.value})</span>}
              </Title>
            </>
          )}
        </TitleLine>
        <DetailsLine addMargin={!!displayFields.includes('health')}>
          {_renderSection(details.filter(f => f.field !== fieldNextToTitleId))}
        </DetailsLine>
        {htmlFields
          .filter(field => displayFields.includes(field) && project[field] && project[field] !== '<p></p>')
          .map(field => (
            <HtmlContainer>
              <HtmlContainerLabel addMargin={!!displayFields.includes('health')}>
                {getSystemFieldName(field, systemFields)}:
              </HtmlContainerLabel>
              <HtmlContainerValue
                addMargin={!!displayFields.includes('health')}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(project[field]) }}
              />
            </HtmlContainer>
          ))}
      </Wrapper>
    );
  },
);

ProjectInfoCard.propTypes = {
  customFields: PropTypes.array,
  disabled: PropTypes.bool,
  displayFields: PropTypes.array,
  fieldsToIgnore: PropTypes.array,
  hideIcon: PropTypes.bool,
  onClickProject: PropTypes.func.isRequired,
  project: PropTypes.shape({
    category: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    customers: PropTypes.array,
    deadline: PropTypes.any,
    displayProgress: PropTypes.any,
    estimated_start_date: PropTypes.any,
    id: PropTypes.number,
    keyResult1: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    layer: PropTypes.any,
    objective: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    ownerName: PropTypes.any,
    parent: PropTypes.any,
    phase: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    planningStage: PropTypes.any,
    predictedEndDate: PropTypes.any,
    priority: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    product1: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    roadmap: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    status_color: PropTypes.shape({
      toLowerCase: PropTypes.func,
    }),
    tags: PropTypes.shape({
      map: PropTypes.func,
    }),
    theme: PropTypes.shape({
      color: PropTypes.any,
      title: PropTypes.any,
    }),
    timeframe: PropTypes.shape({
      title: PropTypes.any,
    }),
    title: PropTypes.string,
  }).isRequired,
  titleComponent: PropTypes.any,
};

ProjectInfoCard.defaultProps = {
  displayFields: ['title', 'timeframe', 'key'],
  fieldsToIgnore: [],
  disabled: false,
  customFields: [],
};

export default ProjectInfoCard;

const Wrapper = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  line-height: 20px;
  margin: 2.5px 0 2.5px 5px;
  overflow: hidden;
  border-radius: 4px;
  padding: 0;
  background: white;

  ${({ disabled }) =>
    !disabled &&
    `
    cursor: pointer;
    box-shadow: 0px 1px 2px #6f77821a;
    border: 1px solid #e8ecee;
    padding: 5px 5px;
  `}

  ${({ clickable }) =>
    clickable &&
    `
    cursor: pointer;
  `}
`;

const ProjectIdLink = styled(ProjectLink)`
  &&&& {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  }
`;

const DetailsLine = styled.div`
  font-size: 13px;
  line-height: 14px;
  color: #a7a7a7;
  ${({ addMargin }) => (addMargin ? 'margin-left: 25px;' : 'margin-left: 5px;')}
  margin-top: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  word-break: break-word;
`;

const DetailsLineItem = styled.span`
  ${({ breakLine }) =>
    breakLine &&
    `
    display: block;
    margin-bottom: 4px;
  `}

  &&&& {
    margin-left: 5px;
    font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
    font-weight: 400;

    > span {
      font-size: 13px;
      color: ${materialColors.azure};
      text-transform: capitalize;
      font-style: italic;
    }
  }
`;

const TitleLine = styled.div`
  &&&& {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const Title = styled(Typography)`
  &&&& {
    display: block;
    margin-left: 5px;
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
    white-space: break-spaces;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    word-break: break-word;

    span {
      font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
      line-height: 14px;
      color: #a7a7a7;
      font-weight: 400;
      word-break: break-word;
    }
  }
`;

const HtmlContainer = styled.div`
  margin-bottom: 4px;
`;

const HtmlContainerLabel = styled.div`
  ${({ addMargin }) => (addMargin ? 'margin-left: 30px;' : 'margin-left: 10px;')}
  font-size: 13px;
  font-weight: 400;
  color: ${props => props.theme.palette.text.primary};
  text-transform: capitalize;
`;

const HtmlContainerValue = styled(Text)`
  ${({ addMargin }) => (addMargin ? 'margin-left: 30px;' : 'margin-left: 10px;')}
  font-size: ${({ theme }) => theme.typography.fontSizeSmallRem}rem;
  color: #a7a7a7;
  line-height: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const HighlighterWrapper = styled.div`
  span {
    font-size: ${({ theme }) => theme.typography.fontSizeRem}rem !important;
    color: ${props => props.theme.palette.text.primary}!important;
  }
`;
