import React, { memo, useCallback, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import styled from 'styled-components';

import { spacing } from 'design-system/theme';

const MENU_ID = 'simple-menu';

const ANCHOR_ORIGIN = { vertical: 'bottom', horizontal: 'right' };

const TRANSFORM_ORIGIN = { vertical: 'top', horizontal: 'right' };

const MoreOptionsMenu = memo(({ options }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const openMenu = useCallback(e => setMenuAnchorEl(e.currentTarget), []);

  const closeMenu = useCallback(() => setMenuAnchorEl(null), []);

  return (
    <>
      <IconButton aria-owns={menuAnchorEl ? MENU_ID : undefined} aria-haspopup="true" onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id={MENU_ID}
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={ANCHOR_ORIGIN}
        transformOrigin={TRANSFORM_ORIGIN}
      >
        {options.map(({ label, action, icon }) => (
          <MenuItem
            key={label}
            disableTouchRipple
            disableRipple
            onClick={() => {
              action();

              closeMenu();
            }}
          >
            <StyledListItemIcon>{icon}</StyledListItemIcon>
            <StyledListItemText primary={label} />
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
});

const StyledMenu = styled(Menu)`
  &&&&& {
    min-width: 120px;

    ul li {
      height: 14px;
    }
  }
`;

const StyledListItemText = styled(ListItemText)`
  &&& {
    position: relative;
    padding: 0 0 0 ${spacing(2)}px;

    > span {
      font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
      line-height: ${({ theme }) => theme.typography.lineHeightSmallRem}rem;
    }
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  &&& {
    margin-right: 0;
  }
`;

MoreOptionsMenu.displayName = 'MoreOptionsMenu';

export default MoreOptionsMenu;
