import React from 'react';
import styled from 'styled-components';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import BrightnessLow from '@material-ui/icons/BrightnessLow';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import IconButton from '@material-ui/core/IconButton';
import NotesIcon from '@material-ui/icons/Assignment';
import ScheduleIcon from '@material-ui/icons/Straighten';
import MergeIcon from '@material-ui/icons/CallMerge';
import HistoryIcon from '@material-ui/icons/History';
import Tooltip from '@material-ui/core/Tooltip';

import { FeatureFlags } from '@dragonboat/config';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';
import DrawerHeader from 'design-system/molecules/DrawerHeader/index';
import FluidTextField from 'design-system/organisms/FluidTextField/index';
import Text from 'design-system/atoms/Text/index';

import theme, { spacing } from 'design-system/theme';
import ShowFieldsIcon from 'design-system/atoms/ShowFieldsIcon';

import Link from 'components/Link';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import useSystemFields from 'hooks/useSystemFields';
import useFeatureFlags from 'hooks/useFeatureFlags';

import RequestsDetailFieldsLayout from 'features/RequestsDetailFieldsLayout';
import useRequestsDetailRequiredFields from 'features/RequestsDetailFieldsLayout/hooks/useRequestsDetailRequiredFields';
import { OPEN_CUSTOMER_REQUEST_QUERY_PARAM } from 'constants/customerRequests';
import { TAB_DETAILS, TAB_ESTIMATES, TAB_MERGE, TAB_HISTORY } from '../constants';
import { addQueryParamToUrl } from 'utils/queryParamsUtils';

const BORDER_BOTTOM = `1px solid ${theme.palette.newLayout.background.borderGray}`;
const TITLE_WRAPPER_WIDTH = 100;

export default props => {
  const {
    files,
    attachFileButtonClicked,
    onClickCustomFields,
    showCustomFieldsButton,
    onToggleWatchStatusClick,
    isWatchingStatusEnabled,
    currentTab,
    onTabChange,
  } = props;

  const permissions = usePermissions();
  const [getSystemFieldName] = useSystemFields();

  const inputRef = React.useRef();
  const hiddenFileInput = React.createRef();

  const [showFieldsLayoutRef, setShowFieldsLayoutRef] = React.useState();
  const [showError, setShowError] = React.useState();

  const onCreateCustomerRequest = (_, value) => props.createCustomerRequest({ title: value });

  const { onSaveWithValidation: onCreateWithValidation } = useRequestsDetailRequiredFields({
    formData: props.data,
    onSave: onCreateCustomerRequest,
  });

  const hasConfigurableRequestDrawer = useFeatureFlags([FeatureFlags.HAS_CONFIGURABLE_REQUEST_DRAWER]);
  const createCustomerRequestFn = hasConfigurableRequestDrawer ? onCreateWithValidation : onCreateCustomerRequest;

  const _onChangeTitle = value => {
    if (props.isCreating && value) createCustomerRequestFn('title', value);
    else {
      if (!value) return;
      else if (!props.updateValue) return;
      return props.updateValue('title', value);
    }
  };
  const _onBlurTitle = e => setShowError(!e.target.value);
  const _onKeyDownTitle = e => setShowError(!e.target.value);
  const _handleFileInputClick = event => hiddenFileInput.current.click();
  const _handleFileInputChange = event => {
    const { files } = event.target;

    for (let i = 0; i < files.length; i++) {
      attachFileButtonClicked(files[i]);
    }

    event.target.value = null;
  };

  React.useEffect(() => {
    if (inputRef.current && props.isCreating) inputRef.current.focus();
  }, [!inputRef.current]);

  const showConfigureFieldsLayout = permissions.canView(PERMISSION_FEATURES.requestDetailsFieldsLayout);
  const showRequestsMerged = permissions.canView(PERMISSION_FEATURES.requestsMerge);
  const showRequestsHistory = !props.isCreating;

  const isMergedRequest = !!props.data.parent;

  const onClickConfigureLayout = e => {
    setShowFieldsLayoutRef(e.target);
  };

  const actions = [
    [
      <HeaderIconButton
        title={TAB_DETAILS.label}
        onClick={() => onTabChange(TAB_DETAILS.id)}
        data-cy="request-details-button"
        data-test="request-details-button"
        disabled={isMergedRequest}
      >
        <NotesIcon fontSize="small" color={currentTab === TAB_DETAILS.id ? 'primary' : 'default'} />
      </HeaderIconButton>,
    ],
    [
      <HeaderIconButton
        title={TAB_ESTIMATES.label}
        onClick={() => onTabChange(TAB_ESTIMATES.id)}
        data-cy="request-estimates-button"
        data-test="request-estimates-button"
        disabled={isMergedRequest}
      >
        <ScheduleIcon fontSize="small" color={currentTab === TAB_ESTIMATES.id ? 'primary' : 'default'} />
      </HeaderIconButton>,
    ],
    ...(showRequestsMerged
      ? [
        <HeaderIconButton
          title={TAB_MERGE.label}
          onClick={() => onTabChange(TAB_MERGE.id)}
          data-cy="request-merged-items-button"
          data-test="request-merged-items-button"
          disabled={isMergedRequest}
        >
          <MergeIcon fontSize="small" color={currentTab === TAB_MERGE.id ? 'primary' : 'default'} />
        </HeaderIconButton>,
        ]
      : []),
    ...(attachFileButtonClicked
      ? [
        <ImgUpload {...props}>
          <Tooltip title="Attach File">
            <StyledIconButton disabled={isMergedRequest} onClick={_handleFileInputClick}>
              <AttachFileIcon fontSize="small" color={files && !!files.length ? 'primary' : ''} />
            </StyledIconButton>
          </Tooltip>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={_handleFileInputChange}
            accept=".pdf, .png, .jpg, .jpeg, .doc, .docx, .xls, .xlsx, .ppt, .csv, .mov, .txt, .rtf"
          />
        </ImgUpload>,
        ]
      : []),
    ...(onToggleWatchStatusClick
      ? [
        <HeaderIconButton
          title="Watch"
          disabled={isMergedRequest}
          onClick={onToggleWatchStatusClick}
          data-cy="watch-button"
          data-test="watch-button"
        >
          <VisibilityIcon fontSize="small" color={isWatchingStatusEnabled ? 'primary' : 'default'} />
        </HeaderIconButton>,
        ]
      : []),
    ...(showCustomFieldsButton
      ? [
        <HeaderIconButton
          title="Custom Fields"
          disabled={isMergedRequest}
          onClick={onClickCustomFields}
          data-cy="custom-fields-button"
          data-test="custom-fields-button"
        >
          <BrightnessLow fontSize="small" />
        </HeaderIconButton>,
        ]
      : []),
    ...(showRequestsHistory
      ? [
        <HeaderIconButton
          title={TAB_HISTORY.label}
          onClick={() => onTabChange(TAB_HISTORY.id)}
          data-cy="request-history-button"
          data-test="request-history-button"
        >
          <HistoryIcon fontSize="small" color={currentTab === TAB_HISTORY.id ? 'primary' : 'default'} />
        </HeaderIconButton>,
        ]
      : []),
    ...(showConfigureFieldsLayout
      ? [
        <HeaderIconButton
          title="Configure layout"
          onClick={onClickConfigureLayout}
          data-cy="configure-fields-layout-button"
          data-test="configure-fields-layout-button"
          disabled={isMergedRequest}
        >
          <ShowFieldsIcon style={{ fontSize: 20, flexShrink: 0 }} />
        </HeaderIconButton>,
        ]
      : []),
  ];

  const handleClickParentLink = () => {
    addQueryParamToUrl(OPEN_CUSTOMER_REQUEST_QUERY_PARAM, props.data.parent.key);
  };

  const placeholder = `New ${getSystemFieldName('request')}`;

  return (
    <>
      <DrawerHeader
        onClose={props.onClose}
        titleWidth={props.data.parent ? TITLE_WRAPPER_WIDTH : null} // only set width if parent is present
        title={
          <TitleWrapper>
            <FluidTextField
              value={props.data.title}
              inputRef={inputRef}
              error={showError}
              width="100%"
              placeholder={placeholder}
              readingPlaceholder={placeholder}
              disableEdit={!props.canSimpleEditRequest && !props.isCreating}
              onChange={_onChangeTitle}
              onBlur={_onBlurTitle}
              onKeyDown={_onKeyDownTitle}
              rowsMax={2}
              multiline
            />
            {props.data.parent && (
              <MergedWithWrapper>
                <ParentRequestTitle>Merged with </ParentRequestTitle>
                <Text>
                  <Link onClick={handleClickParentLink}>CR-{props.data.parent.key}</Link>
                </Text>
              </MergedWithWrapper>
            )}
          </TitleWrapper>
        }
        tooltipTitle={props?.data?.title}
        leftIcon={<ChevronLeftIcon />}
        actions={actions}
        customBorderBottom={BORDER_BOTTOM}
        hideBoxShadow
      />
      {showConfigureFieldsLayout && (
        <RequestsDetailFieldsLayout
          anchorEl={showFieldsLayoutRef}
          isOpen={Boolean(showFieldsLayoutRef)}
          onClose={() => setShowFieldsLayoutRef(null)}
        />
      )}
    </>
  );
};

const MergedWithWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 150px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ParentRequestTitle = styled(Text)`
  color: ${({ theme }) => theme.palette.text.error};
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
  padding-right: ${spacing(0.6)}px;
`;

const HeaderIconButton = styled(ButtonIcon)`
  &&&& {
    max-height: 35px;
    max-width: 35px;
    z-index: 10000;
    margin-left: ${spacing(0.5)}px;

    ${({ color }) =>
      color &&
      `
      svg {
        color: ${color};
      }
    `}
  }
`;

const StyledIconButton = styled(IconButton)`
  && {
    max-height: 35px;
    max-width: 35px;
    margin-left: ${spacing(0.5)}px;
  }
`;

const ImgUpload = styled.div`
  &&&& {
    z-index: 10000;

    > input {
      display: none;
    }
  }
`;
