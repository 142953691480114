import { createSelector } from 'reselect';

import { isLoading } from 'utils/store/thunk';

import { CREATE_USER_VIEW_RECURRING_NOTIFICATION, FETCH_EXISTING_RECURRING_NOTIFICATIONS_FOR_USER_VIEW } from './types';
import { viewRecurringNotificationsAdapter } from './helpers/adapters';
import { defaultTo } from 'ramda';

const emptyObject = {};

const getOperations = state => {
  return getState(state).operations;
};

export const getState = state => state?.userViewRecurringNotifications || emptyObject;

const defaultToViewRecurringNotificationsInitialState = defaultTo(viewRecurringNotificationsAdapter.getInitialState());

export const selectViewRecurringNotifications = (state, viewId) => {
  const viewRecurringNotificationsSelector = viewRecurringNotificationsAdapter.getSelectors(state => {
    return defaultToViewRecurringNotificationsInitialState(getState(state).viewRecurringNotificationsByUserView?.[viewId]);
  });

  const result = viewRecurringNotificationsSelector.selectAll(state);

  return result;
};

export const isFetchingViewRecurringNotificationsLoading = createSelector(getOperations, state =>
  isLoading(state, FETCH_EXISTING_RECURRING_NOTIFICATIONS_FOR_USER_VIEW),
);

export const isCreatingViewRecurringNotification = state =>
  isLoading(getOperations(state), CREATE_USER_VIEW_RECURRING_NOTIFICATION);
