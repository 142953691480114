import { selectPortfolioOverviewForActiveRoadmap } from './selectors';
import { WIDGETS_DATA_LOADER_THUNK_BY_ID } from './thunks';
import { FETCH_PORTFOLIO_OVERVIEW_BY_ROADMAP_FULFILLED } from './types';

export default ({ getState, dispatch }) =>
  next =>
  action => {
    next(action);

    // Dispatch all fetches required after loading a Portfolio Overview
    if (action?.type === FETCH_PORTFOLIO_OVERVIEW_BY_ROADMAP_FULFILLED) {
      const portfolioOverview = selectPortfolioOverviewForActiveRoadmap(getState());

      const thunks = portfolioOverview.state.widgets
        .map(widget => {
          if (widget.id in WIDGETS_DATA_LOADER_THUNK_BY_ID) {
            const thunk = WIDGETS_DATA_LOADER_THUNK_BY_ID[widget.id];

            return thunk(portfolioOverview);
          }
          return null;
        })
        .filter(Boolean);

      thunks.forEach(dispatch);
    }
  };
