import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import MuiBadge from '@material-ui/core/Badge';

import theme from 'design-system/theme';
import FilterIcon from '../FilterIcon';

export default ({ onClick, badgeContent, ...props }) => (
  <FilterButton aria-haspopup="true" onClick={onClick} {...props}>
    <Badge badgeContent={badgeContent}>
      <FilterIcon width={14} height={14} color={theme.palette.text.primary} stroke={theme.palette.text.primary} />
    </Badge>
  </FilterButton>
);

const FilterButton = styled(Button)`
  &&&& {
    margin: 0;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    min-width: unset;
  }
`;

const Badge = styled(MuiBadge)`
  &&&& {
    span {
      color: ${props => props.theme.palette.text.primary};
      font-weight: ${props => props.theme.typography.fontWeightBold};
      background: transparent;
      top: -1px;
      right: -5px;
    }
  }
`;
