export const stripGitHubOrganizationUrlPart = (orgIntegration, url) => {
  const organizationProjectUrlRegex = /https:\/\/github.com\/orgs\/(.*)\/projects\/(.*)/;
  const repoProjectUrlRegex = /https:\/\/github.com\/(.*)\/(.*)\/projects\/(.*)/;

  const paramsIndex = url.indexOf('?');

  if (paramsIndex >= 0) {
    url = url.substring(0, paramsIndex);
  }

  if (organizationProjectUrlRegex.exec(url)) {
    return url.replace(`https://github.com/orgs/${orgIntegration?.data?.githubOrganization}`, '');
  } else if (repoProjectUrlRegex.exec(url)) {
    return url.replace(`https://github.com/${orgIntegration?.data?.githubOrganization}`, '');
  }

  return url;
};

export const addMilestoneFilterToUrl = (projectIntegration, url) =>
  projectIntegration && projectIntegration[0] && projectIntegration[0].data.title
    ? `${url}?card_filter_query=milestone:"${escape(projectIntegration[0].data.title)}"`
    : url;
