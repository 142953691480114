import React, { useMemo } from 'react';
import styled from 'styled-components';

import theme from 'design-system/theme';
import HeaderBreadcrumbTitles from 'design-system/organisms/HeaderBreadcrumbTitles/index';
import ToggleButton from 'design-system/molecules/ToggleButton/index';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';

import CloseIcon from '@material-ui/icons/Close';
import { historyTab, detailsTab } from 'features/Pdlc/constants';
import HistoryIcon from '@material-ui/icons/History';
import NotesIcon from '@material-ui/icons/Assignment';

const SEPARATOR = '\\';

const ProjectCycleDeliverableLightboxHeader = ({
  project,
  cycleDeliverable,
  onClose,
  selectedTab,
  onClickTabButton,
  hasPdlcGovernance = false,
}) => {
  const titleItems = useMemo(
    () => [
      {
        title: project?.title,
        fontSize: theme.typography.fontSizeSmallLarge,
        showTooltip: true,
      },
      {
        title: cycleDeliverable?.title,
        icon: SEPARATOR,
        fontSize: theme.typography.fontSizeSmallLarge,
        showTooltip: true,
      },
    ],
    [cycleDeliverable, project],
  );

  return (
    <HeaderWrapper>
      <LeftWrapper>
        <HeaderBreadcrumbTitles items={titleItems} minWidthTitle={50} showTooltip />
      </LeftWrapper>
      <RightWrapper>
        {hasPdlcGovernance ? (
          <>
            <ToggleButton
              onChange={() => onClickTabButton(detailsTab)}
              on={selectedTab === detailsTab}
              title="Details"
              icon={<NotesIcon fontSize="small" />}
            />
            <ToggleButton
              onChange={() => onClickTabButton(historyTab)}
              on={selectedTab === historyTab}
              title="History"
              icon={<HistoryIcon fontSize="small" />}
            />
          </>
        ) : null}
        <ButtonIcon onClick={onClose}>
          <CloseIcon fontSize="small" />
        </ButtonIcon>
      </RightWrapper>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LeftWrapper = styled.div`
  width: 81%;
`;

const RightWrapper = styled.div`
  flex: 0 0 auto;
`;

export default ProjectCycleDeliverableLightboxHeader;
