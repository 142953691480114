import { ESTIMATES, PDLC, COMPARE_ROADMAP_VERSIONS } from 'store/grids';

export const GRID_VIEWS = [
  { key: 'emptyView', title: '' },
  { key: 'myView', title: 'My View' },
  { key: 'progress', title: 'Progress View' },
  { key: 'score', title: 'Score View' },
  { key: 'estimates', title: 'Estimates View' },
  { key: 'pdlc', title: 'PDLC View' },
  { key: 'compareRoadmapVersions', title: 'Compare Roadmap Versions view' },
];

export const IDEAS_GRID_VIEWS = GRID_VIEWS;
export const ESTIMATES_GRID_VIEWS = GRID_VIEWS.filter(v => v.key === 'estimates');

export const DEFAULT_SELECTED_VIEW = viewType => {
  // Todo: why it does not try to find based on viewType and use score as fallback?
  if (viewType === ESTIMATES) return GRID_VIEWS.find(v => v.key === 'estimates');
  if (viewType === PDLC) return GRID_VIEWS.find(v => v.key === 'pdlc');
  if (viewType === COMPARE_ROADMAP_VERSIONS) return GRID_VIEWS.find(v => v.key === 'compareRoadmapVersions');

  return GRID_VIEWS.find(v => v.key === 'score');
};

export const DEFAULT_GLOBAL_FILTERS = {
  stage: ['Backlog', 'Confirmed', 'Planning'],
};

export const GRID_FILTER_TYPE_OPTIONS = ['roadmap', 'stage', 'timeframe', 'tag', 'customer', 'phase'];

export const PLANNING_STAGES = ['Backlog', 'Planning', 'Confirmed', 'Completed', 'Archived'];

export const isEstimatesView = view => view.key === 'estimates';
export const isMyView = view => view.key === 'myView';
export const isScoreView = view => view.key === 'score';
