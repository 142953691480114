// External dependencies
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Dragonboat dependencies
import ShowColumnsDialog from './ShowColumnsDialog';

const GridShowColumns = ({
  isOpen,
  onChangeColumnVisibility,
  onClose,
  gridColumns,
  allowAddTeamsAndSkills,
  visibleColumnsOnGrid,
  DialogComponent = ShowColumnsDialog,
}) => {
  if (!isOpen) return '';
  const customFieldsIndex = gridColumns.findIndex(group => group.key && group.key === 'customFields');
  const estIndex = gridColumns.findIndex(group => group.key && group.key === 'estimates');
  const pdlcIndex = gridColumns.findIndex(group => group.key && group.key === 'pdlc');

  if (estIndex !== -1 && allowAddTeamsAndSkills !== false) {
    gridColumns[estIndex].links = (
      <FieldsDialogLinks>
        <Link to="/settings/teams">Add/Edit Teams</Link>
        <Link to="/settings/skills">Add/Edit Skills</Link>
      </FieldsDialogLinks>
    );
  }

  if (customFieldsIndex !== -1) {
    gridColumns[customFieldsIndex].links = (
      <FieldsDialogLinks>
        <Link to="/settings/customfields">Add/Edit Custom Fields</Link>
      </FieldsDialogLinks>
    );
  }

  if (pdlcIndex !== -1) {
    gridColumns[pdlcIndex].titleRenderer = title => {
      return <Link to="/settings/pdlc">{title}</Link>;
    };
  }

  return (
    <Fragment>
      <DialogComponent
        isOpen={isOpen}
        gridColumns={gridColumns}
        visibleColumnsOnGrid={visibleColumnsOnGrid}
        changeColumnVisible={onChangeColumnVisibility}
        onClose={onClose}
      />
    </Fragment>
  );
};

export default GridShowColumns;

const FieldsDialogLinks = styled.div`
  display: inline-flex;
  float: right;
  width: 67%;

  a {
    flex: 0.5 auto;
    font-weight: 300;
    font-size: 1rem;
  }
`;
