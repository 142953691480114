import React, { useState } from 'react';
import theme from 'design-system/theme';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import dragonbotTheme from '../dragonbot.theme';
import IconButton from '@material-ui/core/IconButton';
import { MoreVert, Minimize, InfoOutlined } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/login/selectors';
import onlyVisibleForUsers from 'utils/onlyVisibleForUsers';
import { SUPER_ADMIN_USER, CSM_USER } from '@dragonboat/permissions';

const Header = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.headerBgColor};
  color: ${({ theme }) => theme.headerFontColor};
  display: flex;
  fill: ${({ theme }) => theme.headerFontColor};
  height: 56px;
  justify-content: space-between;
  padding: 0 10px;
`;

Header.defaultProps = {
  theme: dragonbotTheme,
};

const HeaderTitle = styled.h2`
  margin: 0;
  font-size: ${({ theme }) => theme.headerFontSize};
`;

HeaderTitle.defaultProps = {
  theme: dragonbotTheme,
};

export default (props) => {
  const {
    handleCloseDragonbotClick,
    handleOptionClicked,
    handleToggleHelpWindow,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const currentUser = useSelector(getCurrentUser);
  const hasOptionsMenu = currentUser.isSuperAdmin || onlyVisibleForUsers(CSM_USER, SUPER_ADMIN_USER)(currentUser.role_id);

  const handleClickOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onOptionClicked = (opt) => {
    setAnchorEl(null);
    handleOptionClicked(opt);
  };

  return (
    <BotHeader className="rsc-header">
      <HeaderBlock style={{ flex: 1 }}>
        <IconButton style={{ color: theme.palette.white }} onClick={handleCloseDragonbotClick}>
          <Minimize />
        </IconButton>
      </HeaderBlock>
      <HeaderBlock style={{ flex: 1, flexGrow: 5 }}>
        <HeaderTitle className="rsc-header-title">Dragon Bot</HeaderTitle>
      </HeaderBlock>
      <HeaderBlock style={{ flex: 1 }}>
        <IconButton style={{ color: theme.palette.white }} onClick={handleToggleHelpWindow}>
          <InfoOutlined />
        </IconButton>
        {hasOptionsMenu &&
          <IconButton style={{ color: theme.palette.white }} onClick={handleClickOpenMenu}>
            <MoreVert />
          </IconButton>}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => onOptionClicked('training-data')}>Manage bot training data</MenuItem>
        </Menu>
      </HeaderBlock>
    </BotHeader>
  );
};

const HeaderBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BotHeader = styled(Header)`
  background: transparent linear-gradient(117deg, rgb(107, 226, 220) 0%, rgb(67, 190, 223) 100%) 0% 0% no-repeat padding-box;
  height: 50px;
`;
