import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import SetupIntegrationToken from '../../components/SetupIntegrationToken';

const CreateUserIntegration = ({ onClickConnect, uri, addUserIntegrationError }) => {
  return (
    <Wrapper>
      <SetupIntegrationToken
        integrationName="Azure DevOps"
        integrationHelpLink={`${uri}/_usersSettings/tokens`}
        connectButtonLabel="CONNECT"
        saveToken={token => onClickConnect(token)}
        error={addUserIntegrationError}
      />
    </Wrapper>
  );
};

CreateUserIntegration.propTypes = {
  onClickConnect: PropTypes.func,
};

const TokenTextField = styled(TextField)``;

const ConnectButton = styled(Button)`
  &&&& {
    margin-left: 30px;
  }
`;

const Wrapper = styled.form`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;

  ${ConnectButton} {
    width: 100px;
    height: 40px;
  }

  ${TokenTextField} {
    flex-grow: 1;
    margin-top: 0px;
  }
`;

export default CreateUserIntegration;
