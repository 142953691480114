import labelScaleConfig from './labelScaleConfig';
import timeScaleConfig from './timeScaleConfig';
import theme, { spacing } from 'design-system/theme';
import ticksFontStyles from 'utils/charts/ticksFontStyles';

export const SCALE_TYPE_TIME = 'time';
export const SCALE_TYPE_LABELS = 'labels';

export const horizontalStackedTickScales = {
  scales: {
    x: {
      stacked: true,
      ticks: {
        callback: value => `${value}%`,
        padding: spacing(),
        color: theme.palette.charts.ticks,
        font: {
          ...ticksFontStyles,
          size: `9px`,
        },
      },
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      ticks: {
        padding: spacing(),
        font: {
          ...ticksFontStyles,
          size: `12px`,
        },
      },
      grid: {
        display: false,
      },
    },
  },
};

export const tickSmallStyles = {
  padding: spacing(),
  color: theme.palette.charts.ticks,
  font: {
    ...ticksFontStyles,
    size: `9px`,
  },
};

export const tickWithPercentageSmallStyles = {
  callback: value => `${value}%`,
  ...tickSmallStyles,
};

export const tickStyles = {
  padding: spacing(),
  font: {
    ...ticksFontStyles,
    size: `12px`,
  },
};

/**
 * Scale factory
 */
const scaleForType = type => {
  if (type === SCALE_TYPE_TIME) {
    return timeScaleConfig;
  }
  return labelScaleConfig;
};

export { scaleForType };
