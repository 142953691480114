import React from 'react';

import ChipCellRenderer from './ChipCellRenderer';
import EmptyValueIfNotProjectRenderer from './EmptyValueIfNotProjectRenderer';
import { checkDataIsOkr } from '../../../helpers';
import MultiChipCellRenderer from './MultiChipCellRenderer';

const RoadmapCellRenderer = params => {
  if (checkDataIsOkr(params.data)) {
    return <MultiChipCellRenderer {...params} />;
  }

  const EmptyValueIfNotProjectOrElseChipCellRenderer = EmptyValueIfNotProjectRenderer(ChipCellRenderer);

  return <EmptyValueIfNotProjectOrElseChipCellRenderer {...params} />;
};

export default RoadmapCellRenderer;
