import React from 'react';

import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import AuthWrapper from '../AuthWrapper';
import AuthMainButton from '../AuthMainButton';

const GoogleSignupApprovedDeclined = (props) => {
  const { isApproved, approvedUserEmail, approvedUserName, goToDragonboat } = props;

  return (
    <AuthWrapper>
      <div className={props.classes.successText}>
        <Typography variant="h6">{ isApproved && 'Approved' }{ !isApproved && 'Declined' }</Typography>
        <Typography className={props.classes.successTextItem} variant="body2">
          { isApproved &&
            `Access to user 
              ${approvedUserName} (${approvedUserEmail}) has been confirmed.`
          }
          { !isApproved &&
            `Access to user 
              ${approvedUserName} (${approvedUserEmail}) has been declined.`
          }
        </Typography>
      </div>
      <AuthMainButton
        onClick={goToDragonboat}
        variant="contained"
        color="primary"
      >Go to dragonboat
      </AuthMainButton>
    </AuthWrapper>
  );
};

const styles = theme => ({
  textFields: {
    width: 250,
  },
  googleLoginButton: {
    marginTop: 30,
    width: 250,
  },
  resetPasswordHead: {
    marginTop: 30,
  },
  successText: {
    marginTop: 30,
  },
  successTextItem: {
    marginTop: 30,
    width: 270,
  },
});

export default withStyles(styles)(GoogleSignupApprovedDeclined);
