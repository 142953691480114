export default (dataSource, orgIntegrations) => {
  try {
    const dataSourceUrl = new URL(dataSource);

    // TODO - Refactor this to take in account not only the origin.
    // It should use the regex in the integration config the get the entire uri
    // including the part with the organization name (eg. https://app.amplitude.com/analytics/dragonboat)
    // This way it will compare the https://app.amplitude.com and every amplitude integration has that uri
    return orgIntegrations.find(orgIntegration => {
      const orgIntegrationUrl = new URL(orgIntegration.data.uri);

      return orgIntegrationUrl?.origin === dataSourceUrl.origin;
    });
  } catch (err) {
    return null;
  }
};
