import { useEffect, useMemo } from 'react';
import moment from 'moment-timezone';

import { differenceBetweenDatesByUnit, VIEW_MODE_RELATIONS } from '../helpers';

/**
 * Custom hooks that calculates the position of the today line.
 * */
const useTimelineToday = ({ contentRef, shouldScrollToToday, fromDate, zoomMode, groupWidths, slotWidth }) => {
  const todayLineLeft = useMemo(() => {
    const today = moment();

    const distance = differenceBetweenDatesByUnit(fromDate, today, zoomMode) * slotWidth;
    const distanceRelation = differenceBetweenDatesByUnit(fromDate, today.endOf('day'), VIEW_MODE_RELATIONS[zoomMode]);

    const totalGroupsWidth = groupWidths.reduce((acc, groupWidth) => acc + groupWidth, 0);

    return Math.round(distance + totalGroupsWidth + distanceRelation);
  }, [fromDate, zoomMode, groupWidths, slotWidth]);

  useEffect(() => {
    if (todayLineLeft && contentRef?.current && shouldScrollToToday.current) {
      const totalGroupsWidth = groupWidths.reduce((acc, groupWidth) => acc + groupWidth, 0) / 2;
      const halfVisibleArea = contentRef.current.clientWidth / 2;
      const halfVisibleAreaWithGroupsWidth = halfVisibleArea + totalGroupsWidth;

      // try to place the today line in the middle of the visible area
      contentRef.current.scrollLeft = Math.round(todayLineLeft) - halfVisibleAreaWithGroupsWidth;
    }

    shouldScrollToToday.current = true;
  }, [todayLineLeft, contentRef?.current]);

  return todayLineLeft;
};

export default useTimelineToday;
