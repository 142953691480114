import React, { useCallback } from 'react';
import styled from 'styled-components';
import isNumber from 'lodash/isNumber';

import Grid from '@material-ui/core/Grid';

import BaseLayout from 'design-system/organisms/BaseLayout';
import { spacing } from 'design-system/theme';

import GoalsAllocationGrid from './components/GoalsAllocationGrid';
import ActionsToolbar from './components/ActionsToolbar';

import useGoalsAllocationData from './hooks/useGoalsAllocationData';
import useGoalsAllocationPreferences from './hooks/useGoalsAllocationPreferences';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

import { ALLOCATION_REPORT_PAGE } from 'constants/filters';
import ActualAllocationPieChart from './components/pieChart/ActualAllocationPieChart';
import ActualAllocationStackedChart from './components/stackedChart/ActualAllocationStackedChart';
import { roundToDecimals } from 'utils/index';
import { getNumber, getPercentage, roundNumber } from './helpers/getAllocationDataFormatted';

const GoalsAllocation = ({ lsState, pageTitleRenderer }) => {
  const allocationBy = lsState.selectedGroupBy.key;

  const {
    startDate,
    endDate,
    handleDateRangeChange,
    timeUnit,
    handleTimeUnitChange,
    dropdownOptions,
    showAllocationAsPercentage,
    showAllocationInWeeks,
    showColumnTotal,
    showTargetAllocationPercentage,
    showTargetAllocationAmount,
    showHealth,
    showTwoDecimals,
  } = useGoalsAllocationPreferences();

  const { allocationData, allocationDataFormatted, canRecalculate, alreadyRecalculated, recalculate } = useGoalsAllocationData({
    startDate,
    endDate,
    timeUnit,
    showAllocationAsPercentage,
    showAllocationInWeeks,
    allocationBy,
    showTwoDecimals,
  });

  const { canView } = usePermissions();
  const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

  const stackedChartPercentageFormatter = useCallback(
    value => {
      const roundedValue = roundToDecimals(value, showTwoDecimals ? 4 : 2);

      return getPercentage(roundedValue, showTwoDecimals);
    },
    [showTwoDecimals],
  );
  const stackedChartTimespanFormatter = useCallback(
    value => {
      const roundedValue = roundNumber(value, showTwoDecimals);

      return getNumber(roundedValue, showTwoDecimals);
    },
    [showTwoDecimals],
  );
  const chartYTickOptions = {
    stepSize: 0.1,
    callback(value) {
      if (isNumber(value)) {
        return `${(value * 100).toFixed(0)}%`;
      }

      return value;
    },
  };

  return (
    <BaseLayout wrapperAttributes={{ id: 'allocation-page-container' }}>
      <ActionsToolbar
        pageId={ALLOCATION_REPORT_PAGE}
        dropdownOptions={dropdownOptions}
        startDate={startDate}
        endDate={endDate}
        handleDateRangeChange={handleDateRangeChange}
        timeUnitSelected={timeUnit}
        handleTimeUnitChange={handleTimeUnitChange}
        canRecalculate={canRecalculate}
        alreadyRecalculated={alreadyRecalculated}
        onClickRecalculate={recalculate}
        pageTitleRenderer={pageTitleRenderer}
        shouldDisplayControlsBar={shouldDisplayControlsBar}
      />
      <GridWrapper>
        <Grid container spacing={spacing(2)}>
          {lsState.showPieChart && (
            <Grid item xs={6}>
              <ActualAllocationPieChart
                allocationData={allocationData}
                displayLegendInline={lsState.displayLegendInline}
                showAllocationInWeeks={showAllocationInWeeks}
              />
            </Grid>
          )}
          {lsState.showStackedChartPercentage && (
            <Grid item xs={6}>
              <ActualAllocationStackedChart
                allocationData={allocationData}
                lsState={lsState}
                title="Stacked Actual Allocation %"
                dataKey="dataPercentage"
                displayFormatter={stackedChartPercentageFormatter}
                tickYProps={chartYTickOptions}
              />
            </Grid>
          )}
          {lsState.showStackedChart && (
            <Grid item xs={6}>
              <ActualAllocationStackedChart
                allocationData={allocationData}
                lsState={lsState}
                title={`Stacked Actual Allocation (${showAllocationInWeeks ? 'Weeks' : 'Days'})`}
                dataKey={showAllocationInWeeks ? 'dataWeeks' : 'data'}
                displayFormatter={stackedChartTimespanFormatter}
              />
            </Grid>
          )}
          {lsState.showTable && (
            <Grid item xs={12}>
              <AllocationGridWrapper>
                <GoalsAllocationGrid
                  preferencesOptions={{
                    showColumnTotal,
                    showTargetAllocationPercentage,
                    showTargetAllocationAmount,
                    showHealth,
                    showTwoDecimals,
                  }}
                  allocationData={allocationDataFormatted}
                  allocationBy={allocationBy}
                />
              </AllocationGridWrapper>
            </Grid>
          )}
        </Grid>
      </GridWrapper>
    </BaseLayout>
  );
};

export default GoalsAllocation;

const GridWrapper = styled.div`
  .ag-layout-normal.ag-root {
    margin: 0;
  }

  margin-top: ${spacing(3)}px;

  display: flex;
  flex-direction: column;
  min-height: 500px;
`;

const AllocationGridWrapper = styled.div`
  margin-top: ${spacing(4)}px;
`;
