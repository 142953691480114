import React from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withTheme from '@material-ui/core/styles/withTheme';

import usePermissionsDialog from 'hooks/usePermissionsDialog';

import Image from './components/permissions_dialog.svg';

const PERMISSIONS_TEXT = 'You do not have permission to view this page. Please contact your admin.';

const PermissionsDialogComponent = props => {
  const { isDialogVisible, closeDialog } = usePermissionsDialog();

  return (
    <Dialog open={isDialogVisible} disableBackdropClick id="permissions-dialog">
      <StyledTitle>
        <TitleWrapper>
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </TitleWrapper>
      </StyledTitle>
      <StyledContent>
        <ImageContainer>
          <StyledImg src={Image} />
        </ImageContainer>
        <TextContainer>{PERMISSIONS_TEXT}</TextContainer>
      </StyledContent>
    </Dialog>
  );
};

const StyledTitle = styled(DialogTitle)`
  &&&&& {
    padding: 0;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const StyledContent = styled(DialogContent)`
  ${({ theme }) => {
    return `
      font-size: ${theme.typography.fontSizeMediumSmall}px;
      font-weight: ${theme.typography.fontWeightRegular};
    `;
  }}
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.6;
`;

const ImageContainer = styled.div`
  height: 125px;
`;

const TextContainer = styled.div`
  margin-left: 10px;
`;

const StyledImg = styled.img`
  height: 100%;
`;

export default withTheme()(PermissionsDialogComponent);
