// External dependencies
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import { defaultTo, indexBy, isNil, keys, path, pathOr, pipe, propEq, reject } from 'ramda';

import {
  CUSTOMER_REQUEST_INTEGRATIONS,
  DEFAULT_MAPPING_KEY,
  FEATURE_FLAGS_BY_INTEGRATION,
  INTEGRATIONS_KEYS,
  MAPPING_TYPES,
  METRIC_INTEGRATION_TYPES,
  NON_AGILE_INTEGRATIONS,
} from 'constants/integrations';
import { BILLING_STATUSES } from 'constants/organizations';
import { getAvailableIntegrations } from 'features/OneStepIntegration/store/selectors';
import FeatureTypes from 'routes/Settings/Integration/constants/featureTypes';
import { isLoading, isUninitialized, getError } from 'utils/store/thunk';

import { GET_SLACK_INTEGRATION_CHANNELS } from 'store/organization/types';

const EMPTY_ARRAY = [];

export function getState(state) {
  return state.organization;
}

export const selectOperationsState = state => getState(state).operations || {};

export const getOrganizationIntegrations = createSelector(
  state => (state.organization || {}).integrations,
  integrations => integrations || [],
);

export const organizationHasSlackIntegration = state =>
  getOrganizationIntegrations(state).some(o => o.integrationType === 'slack');

export const getIdeasIntegrations = createSelectorCreator(defaultMemoize, isEqual)(
  state => getState(state),
  state => getAvailableIntegrations(state),
  (organizationState, oneStepAvailableIntegrations) => {
    const orgIntegrations = organizationState.integrations || [];

    return orgIntegrations.filter(({ integrationType }) => {
      const featureFlaggedIntegration = FEATURE_FLAGS_BY_INTEGRATION[integrationType];

      if (featureFlaggedIntegration) {
        return !!organizationState?.organization?.[featureFlaggedIntegration];
      }

      const nonAgileIntegrations = [...NON_AGILE_INTEGRATIONS, ...keys(oneStepAvailableIntegrations)];

      return !nonAgileIntegrations.includes(integrationType);
    });
  },
);

export const getCustomerRequestIntegrations = createSelectorCreator(defaultMemoize, isEqual)(
  state => getState(state),
  state => getAvailableIntegrations(state),
  (orgState, oneStepAvailableIntegrations) => {
    const allIntegrations = orgState.integrations || [];

    const integrationsWithCustomerRequestFeatures = keys(oneStepAvailableIntegrations).reduce(
      (integrationKeys, integrationKey) => {
        const integrationObj = oneStepAvailableIntegrations[integrationKey];

        if (integrationObj.features.some(feature => keys(FeatureTypes.customerRequests).includes(feature.key))) {
          return [...integrationKeys, integrationKey];
        }

        return integrationKeys;
      },
      [],
    );

    const customerRequestsIntegrations = [...CUSTOMER_REQUEST_INTEGRATIONS, ...integrationsWithCustomerRequestFeatures];

    return allIntegrations.filter(({ integrationType }) => customerRequestsIntegrations.includes(integrationType));
  },
);

// Deprecated: don't use this
export const getOrgIntegration = createSelectorCreator(defaultMemoize, isEqual)(getIdeasIntegrations, integrations => {
  return integrations.length ? integrations[0] : null;
});

export const getOrgJiraIntegrations = createSelector(getOrganizationIntegrations, integrations =>
  integrations.filter(integration => integration.integrationType === 'JIRA'),
);

export const getOrgTableauIntegrations = createSelector(getOrganizationIntegrations, integrations =>
  integrations.find(integration => integration.integrationType === 'tableau'),
);

export const getMetricOrgIntegrations = createSelector(getOrganizationIntegrations, integrations =>
  integrations.filter(integration => METRIC_INTEGRATION_TYPES.includes(integration.integrationType)),
);

export const getSalesforceOrgIntegration = createSelector(getOrganizationIntegrations, integrations =>
  integrations.filter(integration => integration.integrationType === 'salesforce'),
);

export const getOrganization = ({ organization: { organization } }) => organization;

// this should be used in the future to get allowed integrations from organization config
export const getAllowedOrgIntegrationsTypes = createSelector(
  getOrganization,
  getAvailableIntegrations,
  (_, oneStepAvailableIntegrations) => [
    'JIRA',
    'clubhouse',
    'github',
    'okta',
    'asana',
    'azure',
    'slack',
    'azuredevops',
    'miro',
    'zendesk',
    INTEGRATIONS_KEYS.dragonboat,
    INTEGRATIONS_KEYS.rally,
    INTEGRATIONS_KEYS.microsoftTeams,
    ...keys(oneStepAvailableIntegrations),
  ],
);

export const getOrgHasMetricCharts = createSelector(getOrganization, org => !!org.has_metric_charts);
export const getOrgHasBet = createSelector(getOrganization, org => !!org.has_bet);
export const getOrgHasMultipleIntegrations = createSelector(getOrganization, org => !!org.has_multiple_integrations);
export const getOrgHasJiraTokenOAuth = createSelector(getOrganization, org => !!org.has_jira_token_oauth);
export const getOrgHasJiraOAuth2 = createSelector(getOrganization, org => !!org.has_jira_oauth2);
export const getOrgHasExternalApiKeysEnabled = createSelector(getOrganization, org => Boolean(org.has_external_api_keys));
export const getPortalSettings = createSelector(getOrganization, org => org.portal_settings || {});

// TODO: update when org.has_project_inherit_from_parent is removed from db
export const getOrgHasProjectInheritFromParent = createSelector(getOrganization, org => true);

export const getOrgHasJiraIntegrated = createSelector(getState, state => {
  const { integrations } = state;

  if (!integrations) return false;

  const jiraIntegrations = integrations.filter(i => i.integrationType === 'JIRA' && !!i.data.jira_tested);

  return jiraIntegrations.length > 0;
});
export const getOrgHasMiro = createSelector(getOrganization, org => !!org.has_miro);

export const getOrgHasJiraFieldMappingByProject = createSelector(getOrganization, org => !!org.has_jira_field_mapping_by_project);

export const getOrgHasMetadataRoadmaps = createSelector(getOrganization, org => !!org.enable_metadata_roadmaps);

export const getOrganizationSystemFieldsNames = createSelector(getOrganization, org => org.system_fields_name || {});

export const getOrganizationMetadataNotes = createSelector(getOrganization, org => org.metadata_notes || []);

export const hasMultiLevelPortfolioMetadata = createSelector(
  getOrganization,
  organization => !!organization.has_multi_level_portfolio_metadata,
);

export const showPageConfigurationForReadOnlyUsers = createSelector(
  getOrganization,
  organization => organization.has_page_configuration_read_only_users,
);

export const getOrgHasCustomUserProjectFields = createSelector(
  getOrganization,
  organization => !!organization.has_custom_user_project_fields,
);

export const getOrgCustomUserProjectFields = createSelector(
  getOrganization,
  organization => organization.custom_user_project_fields || {},
);

export const getHasAdvancedMetricReporting = createSelector(
  getOrganization,
  getOrgHasMetricCharts,
  (organization, hasMetricCharts = false) => !!organization.has_advanced_metric_reporting && hasMetricCharts,
);

export const getAllowReadOnlyUsersToUpdateRequestFields = createSelector(
  getOrganization,
  organization => organization.allow_read_only_users_to_update_request_fields,
);

export const selectAllowReadOnlyUsersToViewPortfolioOverview = state =>
  getOrganization(state).allow_read_only_users_to_view_portfolio_overview;

export const getOrgHasTeamsLevelTwo = createSelector(getOrganization, organization => !!organization.has_teams_2);

export const getOrgHasOneClickPlan = createSelector(getOrganization, org => !!org.has_one_click_plan);

export const hasEnterpriseEnabled = createSelector(getOrganization, organization => !!organization.has_enterprise_enabled);

export const getHasMultipleMetrics = createSelector(getOrganization, organization => !!organization.has_multiple_metrics);

export const getHasProjectMetrics = createSelector(getOrganization, organization => !!organization.has_project_metrics);

export const getHasIntegrationLog = createSelector(getOrganization, organization => !!organization.has_integration_log);

export const getHasPDLC = createSelector(
  getOrganization,
  organization => !!organization.has_pdlc && organization?.billing_status !== BILLING_STATUSES.SELFSERVE_TRIAL,
);

export const getOrganizationHasDodShareProjects = createSelector(
  getOrganization,
  organization => !!organization.has_dod_share_projects,
);

export const getOrgOnboardingGuide = createSelector(getOrganization, organization => organization.onboarding_guide);

export const getIntegrationsByType = integrationType =>
  createSelector(getOrganizationIntegrations, integrations =>
    integrations.filter(integration => integration.integrationType === integrationType),
  );

export const hasIntegrationsOfType = integrationType =>
  createSelector(getOrganizationIntegrations, integrations =>
    integrations.some(integration => integration.integrationType === integrationType),
  );

export const getIntegrationById = integrationId =>
  createSelector(getOrganizationIntegrations, integrations => integrations.find(integration => integration.id === integrationId));

export const isIntegrationProjectsLoading = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => integration?.projects?.isLoading);

export const getIntegrationProjects = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => integration?.projects?.data ?? []);

export const getIntegrationProjectsError = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => integration?.projects?.error);

export const isImportCountIntegrationItemsLoading = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => integration?.queries?.isLoading);

export const getImportCountIntegrationItems = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => integration?.queries?.data ?? {});

export const getImportCountIntegrationItemsError = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => integration?.queries?.error);

export const isImportInsertIntegrationItemsLoading = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => integration?.importQuery?.isLoading);

export const getImportInsertIntegrationItemsData = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => integration?.importQuery?.data);

export const getImportInsertIntegrationItemsError = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => integration?.importQuery?.error);

export const isIntegrationFieldsLoading = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => integration?.fields?.isLoading);

export const getIntegrationFields = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => integration?.fields?.data ?? []);

export const getIntegrationFieldsError = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => integration?.fields?.error);

export const isIntegrationDragonboatFieldsLoading = createSelector(
  getState,
  organization => organization.dragonboatFields?.isLoading || false,
);

export const getIntegrationDragonboatFields = createSelector(getState, organization => organization.dragonboatFields?.data || []);

export const getIntegrationDragonboatFieldsError = createSelector(
  getState,
  organization => organization.dragonboatFields?.error || null,
);

/**
 * Get projects field mapping. Supports both mapping (no layer) and field_mapping (with layer) data structures depending
 * if the `layer` parameter has value.
 * */
export const getIntegrationProjectsMapping = (integrationId, mappingKey = DEFAULT_MAPPING_KEY, layer) =>
  createSelector(getIntegrationById(integrationId), integration => {
    if (!isNil(layer)) {
      const generateMappingId = mapping =>
        [mapping.entity.toLowerCase(), mapping.project.toLowerCase(), mapping.layer.toLowerCase()].join('-');

      const fieldMapping = pathOr([], ['data', 'field_mapping'])(integration);
      const mappingsById = indexBy(generateMappingId)(fieldMapping);
      const projectsMapping =
        mappingsById[
          generateMappingId({
            entity: MAPPING_TYPES.PROJECTS,
            project: mappingKey,
            layer,
          })
        ];

      return projectsMapping.mapping;
    }

    const projectsMapping = get(integration, `data.mapping.${MAPPING_TYPES.PROJECTS}`, {});

    return projectsMapping[mappingKey] || [];
  });

export const getIntegrationWebhooksProjectRestrictions = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => {
    return get(integration, 'data.webhooksProjectRestrictions', {});
  });

export const getIntegrationProgressTracking = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => {
    return get(integration, `data.progress_tracking`, {});
  });

export const getOrgBillingStatus = createSelector(getOrganization, organization => organization.billing_status);
export const getOrgHasSalesforceIntegration = createSelector(
  getOrganization,
  organization => organization.has_salesforce_integration,
);

export const selectOrganization = createSelector(getState, organization => organization.organization);
export const selectHasHierarchy = createSelector(getState, organization => !!organization.organization.has_hierarchy);
export const selectHasKeyResults = createSelector(getState, organization => !!organization.organization.has_key_results);
export const selectHasKeyResults2 = createSelector(getState, organization => !!organization.organization.has_key_results_2);
export const selectHasProducts = createSelector(getState, organization => !!organization.organization.has_products);
export const selectHasProducts2 = createSelector(getState, organization => !!organization.organization.has_products_2);
export const getOrganizationSystemFields = createSelector(
  getState,
  organization => organization.organization.system_fields_name || EMPTY_ARRAY,
);
export const getOrganizationHasForecastByHeadcount = createSelector(
  getState,
  organization => !!organization.organization.has_forecast_by_headcount,
);

export const getJiraIssuesTypeMapping = integrationId =>
  createSelector(getIntegrationById(integrationId), integration => {
    const getIssueTypeMapping = path(['data', 'jiraIssueTypeMapping']);
    const jiraIssueTypeMapping = getIssueTypeMapping(integration);

    return jiraIssueTypeMapping;
  });

export const hasRenameJiraProjectEnabled = createSelector(
  getOrganization,
  organization => !!organization.has_rename_jira_project_enabled,
);

export const getOrgHasTargetedOnboarding = createSelector(
  getOrganization,
  organization => organization.billing_status === BILLING_STATUSES.TRIAL,
);

export const selectDefaultIdeaAutoHealth = createSelector(getOrganization, organization => organization.default_idea_auto_health);
export const selectDefaultInitiativeAutoHealth = createSelector(
  getOrganization,
  organization => organization.default_initiative_auto_health,
);
export const selectDefaultBetAutoHealth = createSelector(getOrganization, organization => organization.default_bet_auto_health);

export const getIsOnboardingDemo = createSelector(getOrganization, organization => organization.is_onboarding_demo);

const filterOutArchived = pipe(defaultTo(EMPTY_ARRAY), reject(propEq('is_archived', true)));

export const selectSlackChannels = state => filterOutArchived(state.organization.slackChannels);

export const selectSelectedOrgIntegrationId = state => state?.organization?.selectedOrgIntegrationId || null;

export const selectHasMultipleIntegrationsByIntegrationType = integrationType =>
  createSelector(
    getOrganizationIntegrations,
    getOrgHasMultipleIntegrations,
    (integrations, hasMultipleIntegrations) =>
      hasMultipleIntegrations && integrations.filter(integration => integration.integrationType === integrationType).length > 1,
  );

export const selectGetSlackChannelsIsLoading = createSelector(selectOperationsState, state =>
  isLoading(state, GET_SLACK_INTEGRATION_CHANNELS),
);

export const selectGetSlackChannelsError = createSelector(selectOperationsState, state =>
  getError(state, GET_SLACK_INTEGRATION_CHANNELS),
);

export const selectGetSlackChannelsIsUndefined = createSelector(selectOperationsState, state =>
  isUninitialized(state, GET_SLACK_INTEGRATION_CHANNELS),
);
