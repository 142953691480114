import React from 'react';
import Grid from '@material-ui/core/Grid';

import BusinessValue from './BusinessValue';
import RequestRicePanel from './RequestRicePanel';

import useRequestScoring from './hooks/useRequestScoring';

export default () => {
  const {
    requestData,
    userCanEdit,
    handleRequestUpdate,
    onBusinessValueChange,
    onReachScoreChange,
    onImpactScoreChange,
    onConfidenceScoreChange,
    onEffortScoreChange,
    businessValue,
    reachScore,
    impactScore,
    confidenceScore,
    effortScore,
    plannedMoar,
  } = useRequestScoring();

  return (
    <Grid container spacing={24}>
      <Grid item xs={10} style={{ maxWidth: '73%' }}>
        <BusinessValue
          businessValue={businessValue}
          onBusinessValueChange={onBusinessValueChange}
          effortScore={effortScore}
          onEffortScoreChange={onEffortScoreChange}
          calculatedMoar={plannedMoar}
          userCanEdit={userCanEdit}
          saveRequest={handleRequestUpdate}
        />
      </Grid>
      <Grid item xs={3} style={{ maxWidth: '26%' }}>
        <RequestRicePanel
          formData={requestData}
          reachScore={reachScore}
          impactScore={impactScore}
          confidenceScore={confidenceScore}
          effortScore={effortScore}
          onReachScoreChange={onReachScoreChange}
          onImpactScoreChange={onImpactScoreChange}
          onConfidenceScoreChange={onConfidenceScoreChange}
          onEffortScoreChange={onEffortScoreChange}
          saveRequest={handleRequestUpdate}
          userCanEdit={userCanEdit}
        />
      </Grid>
    </Grid>
  );
};
