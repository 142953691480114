import React from 'react';

import { TimelineCell, TimelineCellContent, TimelineRow } from '../../styled';

const ItemsRow = ({ items = [], itemRenderer, groupIndexes, rowIndex, isTopMilestone = false }) => (
  <TimelineRow bottomBorder>
    <TimelineCell rowsCount={1}>
      <TimelineCellContent>{items.map(item => itemRenderer(item, groupIndexes, rowIndex, isTopMilestone))}</TimelineCellContent>
    </TimelineCell>
  </TimelineRow>
);

export default ItemsRow;
