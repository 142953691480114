import React from 'react';

import DoughnutChart from 'components/ReactChart/DoughnutChart';
import { LEAD_LINES_PLUGIN } from 'components/ReactChart/utils/plugins';
import ChartWrapper from 'components/ReactChart/utils/ChartWrapper';
import ConfigurableChartWrapper from '../components/ConfigurableChartWrapper';

import useConfigurableChartDataForPieChart from '../hooks/useConfigurableChartDataForPieChart';
import { getPieChartOptions } from '../helpers';
import { DASHBOARDS_PAGE } from 'constants/filters';
import TimelineLightbox from 'features/TimelineLightbox';
import useChartTimelineLightbox from 'routes/Dashboard/Dashboards/hooks/useChartTimelineLightbox';

const MIN_HEIGHT = 230;

export default ({ minHeight = MIN_HEIGHT, groupBy, label, actionButtons }) => {
  const { data, title, subtitle } = useConfigurableChartDataForPieChart({ groupBy, label });

  const {
    handleOnChartClick,
    registerOpenTimelineCallback,
    timelineDisplayLayer,
    timelineProjectsGrouping,
    groupByEntity,
    timelineLightboxOpenItems,
  } = useChartTimelineLightbox(data, groupBy);

  return (
    <ConfigurableChartWrapper title={title} subtitle={subtitle} actionButtons={actionButtons}>
      <ChartWrapper height={minHeight}>
        <DoughnutChart data={data} options={getPieChartOptions(handleOnChartClick)} plugins={[LEAD_LINES_PLUGIN]} />
        <TimelineLightbox
          onOpen={registerOpenTimelineCallback}
          groupedBy={timelineProjectsGrouping}
          groupBySelection={groupByEntity}
          pageId={DASHBOARDS_PAGE}
          openItems={timelineLightboxOpenItems}
          entityGroup={groupByEntity}
          lazyLoadProjects
          displayLayer={timelineDisplayLayer}
        />
      </ChartWrapper>
    </ConfigurableChartWrapper>
  );
};
