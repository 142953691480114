import React from 'react';
import styled from 'styled-components';
import Autocomplete from 'design-system/atoms/Autocomplete/index';


export default props => {
  const { value, options, handleChange } = props;

  return (
    <div style={{ flexBasis: 'auto', flexGrow: 1 }}>
      <FilterFieldAutocomplete value={value} suggestions={options} handleChange={handleChange} />
    </div>
  );
};

const FilterFieldAutocomplete = styled(Autocomplete)`
  margin: 0 3px;

  ${props => `
    background: ${props.theme.palette.white};
    
    input {
      font-size: ${props.theme.typography.fontSize}px;
      padding-left: 4px;
      padding-right: 32px;
    }
  `}
`;
