import { connect } from 'react-redux';
import { compose } from 'redux';
import React from 'react';

import useSinglePurposeSocket from 'hooks/useSinglePurposeSocket';
import IdeasCopyPasteLightbox from './IdeasCopyPasteLightbox';

const withHooksHOC = Component => {
  return React.forwardRef((props, ref) => {
    const socket = useSinglePurposeSocket();

    return <Component {...props} ref={ref} socket={socket} />;
  });
};

const mapStateToProps = state => {
  const {
    organization: { organization },
  } = state;

  return {
    systemFields: organization.system_fields_name,
  };
};

export default compose(connect(mapStateToProps, {}), withHooksHOC)(IdeasCopyPasteLightbox);
