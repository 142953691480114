import { useRef, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { isGridStateDirty } from 'utils/grid';
import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/userViews';
import { getActiveViewForPage } from 'store/userViews/selectors';

const updateGridData = (gridState, gridApi, gridColumnApi) => {
  const { columnState, filterState } = gridState;

  if (columnState) {
    gridColumnApi.current.applyColumnState({
      state: columnState,
      applyOrder: true,
    });
  }
  if (filterState) {
    gridApi.current.setFilterModel(filterState);
  }
};
/**
 * @function useUpdateGridStateFromViewData on grid ready applies the column and filter settings saved from
 * the view on the grid state. Also adds listeners to grid events that change the gridState so we can persit them on the store
 * @param  {Object} gridState     gridState with data that we want to apply
 * @param  {Function} saveGridState callback to save new grid state changes
 * @return {Object} All data and callbacks generated by the hook
 */
const useUpdateGridStateFromViewData = (gridState, saveGridState) => {
  let savingState = false;

  const shouldResetView = useRef(true);
  const gridStateRef = useRef(gridState);

  gridStateRef.current = gridState;

  const path = getCurrentPath();
  const pageId = getPageIdFromPath(path);
  const activeView = useSelector(state => getActiveViewForPage(state, pageId));

  const onGridReadySetData = useCallback(
    (gridApi, gridColumnApi) => {
      if (!gridColumnApi?.current || !gridApi?.current || !gridStateRef.current) return;
      updateGridData(gridStateRef.current, gridApi, gridColumnApi);

      const events = [
        'columnResized',
        'sortChanged',
        'filterChanged',
        'gridColumnsChanged',
        'displayedColumnsChanged',
        'floatingRowDataChanged',
      ];

      const _getGridState = () => ({
        columnState: gridColumnApi.current.getColumnState(),
        filterState: gridApi.current.getFilterModel(),
      });

      const _persistGridState = e => {
        const newGridState = _getGridState();

        if (isEqual(newGridState, gridStateRef.current)) {
          return;
        }

        if (savingState) clearTimeout(savingState);

        savingState = setTimeout(() => {
          const isDirty = isGridStateDirty(gridStateRef.current, newGridState);

          saveGridState(newGridState, shouldResetView.current && isDirty);

          shouldResetView.current = true;
          savingState = null;
        }, 1000);
      };

      events.forEach(eventName => gridApi.current.addEventListener(eventName, _persistGridState));
    },
    [gridStateRef.current, saveGridState],
  );

  useEffect(() => {
    shouldResetView.current = false;
  }, [activeView?.id]);

  const updateDataOnStateGridChange = useCallback(
    (gridApi, gridColumnApi) => {
      if (!gridColumnApi?.current || !gridApi?.current || isEmpty(gridStateRef.current)) return;
      updateGridData(gridStateRef.current, gridApi, gridColumnApi);
    },
    [gridStateRef.current],
  );

  return { onGridReadySetData, updateDataOnStateGridChange };
};

export default useUpdateGridStateFromViewData;
