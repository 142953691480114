import React, { Fragment, useRef } from 'react';

import ImportWidget from 'components/ImportWidget';

import ImportWizardlightbox from './ImportWizardLightbox';

export default ({
  close,
  importCustomers,
  dataMapping,
  getSystemFieldName,
  isOpen,
}) => {
  const importWidget = useRef();

  const onClickBrowseCSVFile = () => {
    close();
    if (importWidget) {
      importWidget.current.fileInput.click();
    }
  };

  return (
    <Fragment>
      <ImportWidget
        hideButton
        ref={ref => {
          importWidget.current = ref;
        }}
        // pass dispatch function to importWidget
        import={importCustomers}
        dataMapping={dataMapping}
        title={`Map ${getSystemFieldName('customer')} data`}
        accept=".csv"
        isAsyncImport
      />
      <ImportWizardlightbox onClickBrowseCSVFile={onClickBrowseCSVFile} isOpen={isOpen} close={close} />
    </Fragment>
  );
};