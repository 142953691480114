import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';

const MicrosoftTeamsIcon = props => {
  const { size = 20, marginRight } = props;

  return (
    <SvgIcon style={{ width: size, height: size, marginRight }} viewBox="0 0 1101 1024">
      <path
        fill="#7B83EB"
        opacity="1.000000"
        stroke="none"
        d="M618.000000,1025.000000
            C599.645752,1025.000000 581.291565,1025.000000 562.790100,1024.651855
            C561.798889,1023.872437 560.985229,1023.143921 560.106262,1023.054016
            C542.760620,1021.279846 525.885132,1017.307983 509.310822,1012.046387
            C490.901306,1006.202271 473.262756,998.651184 456.334534,989.202759
            C435.861542,977.775757 417.005005,964.210144 399.968292,948.245667
            C390.595062,939.462280 382.033722,929.751587 373.634247,920.004028
            C360.763824,905.067810 349.832916,888.756592 341.563904,871.001465
            C372.470154,871.006653 402.914825,871.056458 433.359497,871.064148
            C470.958374,871.073730 508.557556,871.134766 546.156006,870.998413
            C562.727905,870.938293 580.976990,857.726257 585.457520,842.986328
            C586.158203,842.226868 586.639221,841.645691 587.484619,841.028259
            C589.540466,840.321228 591.420349,839.930176 592.893860,838.936340
            C606.721619,829.609802 615.015259,817.075745 615.016296,799.902039
            C615.024719,661.662720 615.022766,523.423462 615.483276,385.110352
            C672.490295,385.024384 729.038025,385.000885 785.585754,385.007660
            C800.385864,385.009460 815.186035,385.105316 830.313110,385.333130
            C837.380859,388.206207 844.832825,389.848602 850.718689,393.815063
            C864.242065,402.928375 871.137634,416.120697 871.129028,432.763824
            C871.079102,529.685791 871.152832,626.607849 871.069641,723.529724
            C871.057922,737.129456 870.896912,750.762268 869.954285,764.320618
            C868.353577,787.345703 863.074646,809.668152 855.434753,831.436462
            C852.580261,839.569580 849.776062,847.720276 846.636475,856.042725
            C838.560120,870.083191 831.517334,884.417847 822.814392,897.661499
            C815.231140,909.201233 806.458435,920.155945 796.972534,930.199097
            C785.153809,942.711975 772.763916,954.898254 759.350586,965.639465
            C741.624634,979.834167 722.104187,991.559143 701.057190,1000.424561
            C692.272217,1004.124939 683.498352,1007.967163 674.441956,1010.882996
            C656.517639,1016.654053 638.294617,1021.334534 619.448120,1023.110718
            C618.910828,1023.161377 618.480286,1024.344727 618.000000,1025.000000
          z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="M829.986145,385.157959
            C815.186035,385.105316 800.385864,385.009460 785.585754,385.007660
            C729.038025,385.000885 672.490295,385.024384 615.018677,385.033112
            C606.087891,385.065338 598.080933,385.101013 589.603149,385.083954
            C581.112488,385.011566 573.092773,384.991943 565.041138,384.506317
            C565.036682,366.070557 565.064148,348.100769 565.487183,330.130615
            C573.943420,331.380371 582.004028,332.630463 590.526855,333.931458
            C599.332581,333.938202 607.676270,333.894073 616.438721,333.913269
            C618.437683,333.651550 620.009827,333.279999 621.599243,333.009399
            C628.812988,331.781494 636.151978,331.060608 643.225708,329.281738
            C663.691528,324.135223 682.562805,315.570923 699.813416,303.152130
            C715.167603,292.098602 728.211975,279.055420 739.125061,263.647125
            C752.169250,245.229904 761.288269,225.056473 765.755920,203.043381
            C768.153015,191.232498 769.415833,179.056656 769.750061,167.005539
            C770.006165,157.770859 768.236206,148.441772 766.962280,139.210602
            C764.032288,117.980423 756.008850,98.609322 745.076721,80.305054
            C734.163513,62.032410 720.172363,46.428680 703.158875,33.849712
            C681.884338,18.120384 658.176819,7.460853 631.908508,2.942069
            C630.858704,2.761480 629.965942,1.668096 629.000000,1.000006
            C786.590271,1.000000 944.180481,1.000000 1102.000000,1.000000
            C1102.000000,140.355896 1102.000000,279.711914 1101.664185,419.783875
            C1100.583984,419.089172 1099.609131,417.752350 1099.130005,416.256714
            C1093.343262,398.194763 1073.754883,384.896729 1055.703369,384.935303
            C982.790405,385.091156 909.876892,384.998169 836.963623,385.006104
            C834.637817,385.006348 832.311951,385.105103 829.986145,385.157959
          M853.077698,176.655670
            C844.553345,199.187347 842.713867,222.388184 848.501648,245.640289
            C855.096741,272.135864 868.960999,294.237427 891.236023,310.878876
            C919.904175,332.296600 952.016174,338.676910 986.346802,331.624573
            C1011.820984,326.391632 1033.221313,313.010162 1050.055298,292.577698
            C1071.760376,266.232788 1079.820801,236.031143 1075.789673,202.640900
            C1073.504761,183.714966 1066.154663,166.352310 1054.940918,150.805771
            C1041.108276,131.628540 1023.094910,117.939964 1000.808838,109.913292
            C983.353149,103.626350 965.520203,101.661217 947.021179,103.857605
            C933.863464,105.419815 921.436157,109.020798 909.676331,114.814369
            C894.627625,122.228264 881.556213,132.364716 871.062317,145.535278
            C863.698059,154.778030 857.629944,164.845490 853.077698,176.655670
          z"
      />
      <path
        fill="#5059C9"
        opacity="1.000000"
        stroke="none"
        d="M830.313110,385.333099
            C832.311951,385.105103 834.637817,385.006348 836.963623,385.006104
            C909.876892,384.998169 982.790405,385.091156 1055.703369,384.935303
            C1073.754883,384.896729 1093.343262,398.194763 1099.130005,416.256714
            C1099.609131,417.752350 1100.583984,419.089172 1101.664185,420.249878
            C1102.000000,519.354248 1102.000000,618.708435 1101.596191,718.417969
            C1100.817261,719.279053 1100.228149,719.735962 1100.099854,720.297974
            C1098.395508,727.769775 1097.001221,735.320862 1095.056152,742.727844
            C1087.553101,771.299072 1072.523926,795.657593 1052.439087,816.974792
            C1036.479736,833.913086 1017.509460,846.681274 996.333313,856.345337
            C974.699707,866.218079 951.893860,870.768860 928.338318,871.898376
            C921.434509,872.229370 914.417236,871.767029 907.548706,870.901306
            C896.846436,869.552246 886.062500,868.211792 875.619995,865.627930
            C865.855408,863.211853 856.489990,859.182434 846.948975,855.862976
            C849.776062,847.720276 852.580261,839.569580 855.434753,831.436462
            C863.074646,809.668152 868.353577,787.345703 869.954285,764.320618
            C870.896912,750.762268 871.057922,737.129456 871.069641,723.529724
            C871.152832,626.607849 871.079102,529.685791 871.129028,432.763824
            C871.137634,416.120697 864.242065,402.928375 850.718689,393.815063
            C844.832825,389.848602 837.380859,388.206207 830.313110,385.333099
          z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="M1.000000,265.000000
            C1.000000,177.045090 1.000000,89.090164 1.000000,1.000000
            C192.689957,1.000000 384.379944,1.000000 576.874878,1.322852
            C577.014526,2.083538 576.405701,2.729787 575.675293,2.927975
            C564.457825,5.971968 552.944702,8.182363 542.051941,12.114662
            C517.738037,20.891979 497.194550,35.421104 479.732239,54.597759
            C466.977692,68.604530 456.681671,84.043587 449.269043,101.402458
            C441.345520,119.957809 436.564484,139.321198 436.095367,159.536728
            C435.873260,169.108429 436.268127,178.740265 437.141418,188.275436
            C437.721954,194.614319 439.643707,200.830383 440.977844,207.510925
            C443.299469,215.601242 445.608429,223.282059 447.454132,230.979492
            C313.552490,230.993271 180.114090,230.973816 46.675701,231.028397
            C38.562996,231.031708 30.885672,233.122177 23.921249,237.485397
            C13.829913,243.807602 6.456378,252.270584 2.873519,263.809723
            C2.706244,264.348419 1.644932,264.609589 1.000000,265.000000
          z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="M341.102356,871.027344
            C349.832916,888.756592 360.763824,905.067810 373.634247,920.004028
            C382.033722,929.751587 390.595062,939.462280 399.968292,948.245667
            C417.005005,964.210144 435.861542,977.775757 456.334534,989.202759
            C473.262756,998.651184 490.901306,1006.202271 509.310822,1012.046387
            C525.885132,1017.307983 542.760620,1021.279846 560.106262,1023.054016
            C560.985229,1023.143921 561.798889,1023.872437 562.321411,1024.651855
            C375.099091,1025.000000 188.198151,1025.000000 1.000000,1025.000000
            C1.000000,936.979309 1.000000,848.958313 1.287175,760.199097
            C2.252820,760.603760 3.093252,761.682983 3.573539,762.903870
            C4.386372,764.970154 4.631331,767.318909 5.705779,769.213928
            C15.129737,785.834351 29.109306,795.006348 48.572079,795.002930
            C70.228592,794.999084 91.885132,795.017578 113.541618,794.991821
            C144.024689,794.955505 174.507721,794.887390 205.455566,794.901794
            C241.916214,794.990295 277.912048,795.009827 313.947205,795.446228
            C315.326324,801.686157 316.426575,807.577026 318.051636,813.319275
            C322.623444,829.474121 327.763702,845.432861 335.944061,860.429199
            C335.941193,860.639648 336.102203,861.028564 336.064545,861.394897
            C337.666992,864.579956 339.307098,867.398621 340.944366,870.427795
            C340.941559,870.638245 341.102356,871.027344 341.102356,871.027344
          z"
      />
      <path
        fill="#454DB6"
        opacity="1.000000"
        stroke="none"
        d="M204.990753,794.832825
            C174.507721,794.887390 144.024689,794.955505 113.541618,794.991821
            C91.885132,795.017578 70.228592,794.999084 48.572079,795.002930
            C29.109306,795.006348 15.129737,785.834351 5.705779,769.213928
            C4.631331,767.318909 4.386372,764.970154 3.573539,762.903870
            C3.093252,761.682983 2.252820,760.603760 1.287175,759.730469
            C1.000000,756.638733 1.000000,753.277405 1.341059,749.378296
            C2.140128,748.645386 2.624774,748.496399 3.052367,748.248840
            C37.723961,728.175598 72.374634,708.066162 107.062790,688.021606
            C150.386490,662.987122 193.728302,637.984009 237.098785,613.030762
            C242.105530,610.150085 247.345154,607.674133 252.547928,605.473511
            C252.619049,625.676147 252.619049,645.415771 252.619049,665.136292
            C272.875549,665.136292 292.392822,665.136292 312.306274,665.136292
            C312.306274,662.752075 312.302307,660.776917 312.306854,658.801819
            C312.378052,627.871399 312.450348,596.941040 312.863281,565.980103
            C315.185791,565.104309 317.277100,564.450012 319.131714,563.383911
            C334.986053,554.270569 350.797455,545.082581 366.621735,535.916992
            C392.804016,520.751953 418.971619,505.561310 445.172577,490.428436
            C475.115448,473.134308 505.053802,455.831757 535.067749,438.661621
            C544.573853,433.223358 554.316101,428.197968 563.972290,423.453522
            C563.981873,478.278198 563.969360,532.632996 563.588745,587.017639
            C560.958191,588.046875 558.580933,588.847107 556.450989,590.075928
            C530.230103,605.203552 504.060608,620.420044 477.847534,635.561157
            C447.890747,652.864685 417.895081,670.100891 387.931732,687.393127
            C359.551880,703.771484 331.202240,720.202209 302.820648,736.577637
            C271.702179,754.532166 240.548965,772.426758 209.466797,790.443909
            C207.715302,791.459167 206.471069,793.349670 204.990753,794.832825
          z"
      />
      <path
        fill="#555DC1"
        opacity="1.000000"
        stroke="none"
        d="M1.000000,265.468658
            C1.644932,264.609589 2.706244,264.348419 2.873519,263.809723
            C6.456378,252.270584 13.829913,243.807602 23.921249,237.485397
            C30.885672,233.122177 38.562996,231.031708 46.675701,231.028397
            C180.114090,230.973816 313.552490,230.993271 447.749329,231.004944
            C448.704620,231.031219 448.901459,231.048676 449.440247,231.274994
            C451.751801,231.655884 453.721130,231.972885 455.691193,231.977417
            C474.124939,232.019913 492.558838,232.008865 510.909332,232.346649
            C509.920898,233.598892 509.156311,234.749313 508.087250,235.397980
            C502.397430,238.850464 496.654358,242.217209 490.887878,245.541046
            C457.299805,264.901581 423.689972,284.224457 390.107147,303.594147
            C360.704681,320.552734 331.307098,337.520142 301.980713,354.609589
            C299.688690,355.945190 297.974213,358.271820 295.536316,360.258667
            C249.873795,360.379456 204.666229,360.379456 159.438812,360.379456
            C159.438812,377.344543 159.438812,393.425232 159.438812,410.004089
            C161.514893,410.004089 163.303146,410.004150 165.091400,410.004089
            C192.724625,410.003174 220.357971,409.970917 247.990707,410.087036
            C249.490051,410.093323 250.984192,411.341003 252.044632,412.011230
            C238.780075,412.007782 225.945877,412.227936 213.125900,411.896606
            C207.505600,411.751343 202.691986,413.308777 197.995987,416.034943
            C172.953400,430.572937 147.899826,445.092102 122.831009,459.584839
            C90.558601,478.242126 58.294689,496.914368 25.967102,515.475647
            C17.761438,520.187073 9.328509,524.502563 1.000002,529.000000
            C1.000000,441.312439 1.000000,353.624878 1.000000,265.468658
          z"
      />
      <path
        fill="#7B83EB"
        opacity="1.000000"
        stroke="none"
        d="M440.965149,207.101410
            C439.643707,200.830383 437.721954,194.614319 437.141418,188.275436
            C436.268127,178.740265 435.873260,169.108429 436.095367,159.536728
            C436.564484,139.321198 441.345520,119.957809 449.269043,101.402458
            C456.681671,84.043587 466.977692,68.604530 479.732239,54.597759
            C497.194550,35.421104 517.738037,20.891979 542.051941,12.114662
            C552.944702,8.182363 564.457825,5.971968 575.675293,2.927975
            C576.405701,2.729787 577.014526,2.083538 577.339966,1.322852
            C594.020874,1.000000 611.041809,1.000000 628.531372,1.000006
            C629.965942,1.668096 630.858704,2.761480 631.908508,2.942069
            C658.176819,7.460853 681.884338,18.120384 703.158875,33.849712
            C720.172363,46.428680 734.163513,62.032410 745.076721,80.305054
            C756.008850,98.609322 764.032288,117.980423 766.962280,139.210602
            C768.236206,148.441772 770.006165,157.770859 769.750061,167.005539
            C769.415833,179.056656 768.153015,191.232498 765.755920,203.043381
            C761.288269,225.056473 752.169250,245.229904 739.125061,263.647125
            C728.211975,279.055420 715.167603,292.098602 699.813416,303.152130
            C682.562805,315.570923 663.691528,324.135223 643.225708,329.281738
            C636.151978,331.060608 628.812988,331.781494 621.599243,333.009399
            C620.009827,333.279999 618.437683,333.651550 616.427124,333.454254
            C615.670105,305.311188 615.429626,277.689087 614.990845,250.070129
            C614.615173,226.423187 593.865784,206.062851 570.120361,206.019653
            C528.984863,205.944824 487.848999,205.982834 446.713409,206.043823
            C444.796814,206.046692 442.881226,206.733261 440.965149,207.101410
          z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="M846.636475,856.042725
            C856.489990,859.182434 865.855408,863.211853 875.619995,865.627930
            C886.062500,868.211792 896.846436,869.552246 907.548706,870.901306
            C914.417236,871.767029 921.434509,872.229370 928.338318,871.898376
            C951.893860,870.768860 974.699707,866.218079 996.333313,856.345337
            C1017.509460,846.681274 1036.479736,833.913086 1052.439087,816.974792
            C1072.523926,795.657593 1087.553101,771.299072 1095.056152,742.727844
            C1097.001221,735.320862 1098.395508,727.769775 1100.099854,720.297974
            C1100.228149,719.735962 1100.817261,719.279053 1101.596191,718.886658
            C1102.000000,820.943481 1102.000000,922.886963 1102.000000,1025.000000
            C940.979126,1025.000000 779.958252,1025.000000 618.468628,1025.000000
            C618.480286,1024.344727 618.910828,1023.161377 619.448120,1023.110718
            C638.294617,1021.334534 656.517639,1016.654053 674.441956,1010.882996
            C683.498352,1007.967163 692.272217,1004.124939 701.057190,1000.424561
            C722.104187,991.559143 741.624634,979.834167 759.350586,965.639465
            C772.763916,954.898254 785.153809,942.711975 796.972534,930.199097
            C806.458435,920.155945 815.231140,909.201233 822.814392,897.661499
            C831.517334,884.417847 838.560120,870.083191 846.636475,856.042725
          z"
      />
      <path
        fill="#4E56BD"
        opacity="1.000000"
        stroke="none"
        d="M1.000002,529.468628
            C9.328509,524.502563 17.761438,520.187073 25.967102,515.475647
            C58.294689,496.914368 90.558601,478.242126 122.831009,459.584839
            C147.899826,445.092102 172.953400,430.572937 197.995987,416.034943
            C202.691986,413.308777 207.505600,411.751343 213.125900,411.896606
            C225.945877,412.227936 238.780075,412.007782 252.113388,412.474365
            C252.644699,475.969208 252.672119,539.001343 252.688293,602.033508
            C252.688553,603.025818 252.550385,604.018188 252.476807,605.010498
            C247.345154,607.674133 242.105530,610.150085 237.098785,613.030762
            C193.728302,637.984009 150.386490,662.987122 107.062790,688.021606
            C72.374634,708.066162 37.723961,728.175598 3.052367,748.248840
            C2.624774,748.496399 2.140128,748.645386 1.341059,748.920166
            C1.000000,675.979126 1.000000,602.958191 1.000002,529.468628
          z"
      />
      <path
        fill="#6E76D3"
        opacity="1.000000"
        stroke="none"
        d="M590.073975,385.136719
            C598.080933,385.101013 606.087891,385.065338 614.559387,385.106873
            C615.022766,523.423462 615.024719,661.662720 615.016296,799.902039
            C615.015259,817.075745 606.721619,829.609802 592.893860,838.936340
            C591.420349,839.930176 589.540466,840.321228 587.520081,840.640076
            C587.809448,837.713257 588.910522,835.148926 588.967468,832.561646
            C589.324951,816.330505 589.449829,800.094299 589.697021,783.403564
            C589.819580,777.784363 589.963501,772.621277 589.964050,767.458191
            C589.976624,642.515808 589.973328,517.573364 589.978149,392.630981
            C589.978210,390.132904 590.040649,387.634796 590.073975,385.136719
          z"
      />
      <path
        fill="#585EA9"
        opacity="1.000000"
        stroke="none"
        d="M589.651611,783.859741
            C589.449829,800.094299 589.324951,816.330505 588.967468,832.561646
            C588.910522,835.148926 587.809448,837.713257 587.155640,840.676392
            C586.639221,841.645691 586.158203,842.226868 585.047363,843.009644
            C579.025085,844.139648 573.634399,845.865417 568.240112,845.876587
            C489.184296,846.040222 410.128021,845.975220 330.903015,845.645752
            C327.779816,836.937683 324.825439,828.548340 322.348236,820.134155
            C341.454651,820.091187 360.083893,820.081238 378.713165,820.053467
            C429.111481,819.978210 479.509766,819.895325 530.380493,819.861572
            C535.326050,819.940002 539.799377,820.013855 544.272400,819.997192
            C561.806580,819.931946 579.341125,808.015198 585.919678,791.805237
            C587.018494,789.097839 588.400513,786.505310 589.651611,783.859741
          z"
      />
      <path
        fill="#6269BC"
        opacity="1.000000"
        stroke="none"
        d="M331.071838,845.964600
            C410.128021,845.975220 489.184296,846.040222 568.240112,845.876587
            C573.634399,845.865417 579.025085,844.139648 584.827698,843.187988
            C580.976990,857.726257 562.727905,870.938293 546.156006,870.998413
            C508.557556,871.134766 470.958374,871.073730 433.359497,871.064148
            C402.914825,871.056458 372.470154,871.006653 341.563904,871.001465
            C341.102356,871.027344 340.941559,870.638245 340.971558,870.040771
            C339.368439,866.638367 337.735321,863.833496 336.102203,861.028564
            C336.102203,861.028564 335.941193,860.639648 335.984436,860.026123
            C334.375732,854.929993 332.723785,850.447266 331.071838,845.964600
          z"
      />
      <path
        fill="#4E56BD"
        opacity="1.000000"
        stroke="none"
        d="M295.991302,360.137878
            C297.974213,358.271820 299.688690,355.945190 301.980713,354.609589
            C331.307098,337.520142 360.704681,320.552734 390.107147,303.594147
            C423.689972,284.224457 457.299805,264.901581 490.887878,245.541046
            C496.654358,242.217209 502.397430,238.850464 508.087250,235.397980
            C509.156311,234.749313 509.920898,233.598892 511.374451,232.341644
            C516.985718,232.307159 522.048523,232.611038 527.501831,232.932587
            C547.468262,236.508957 563.723083,255.682205 563.966736,275.856079
            C564.157715,291.671539 563.972290,307.491272 564.067871,323.308472
            C564.081665,325.584625 564.734497,327.856903 565.091553,330.130981
            C565.064148,348.100769 565.036682,366.070557 564.796387,384.844299
            C564.393311,387.450134 564.050049,389.250977 564.037903,391.054047
            C563.966003,401.696930 563.971069,412.340363 563.950195,422.983643
            C554.316101,428.197968 544.573853,433.223358 535.067749,438.661621
            C505.053802,455.831757 475.115448,473.134308 445.172577,490.428436
            C418.971619,505.561310 392.804016,520.751953 366.621735,535.916992
            C350.797455,545.082581 334.986053,554.270569 319.131714,563.383911
            C317.277100,564.450012 315.185791,565.104309 312.774597,565.518433
            C312.344971,513.511169 312.344971,461.935059 312.344971,409.887634
            C343.911591,409.887634 374.816284,409.887634 405.922760,409.887634
            C405.922760,393.311035 405.922760,377.208466 405.922760,360.432404
            C403.799225,360.432404 401.850189,360.432495 399.901154,360.432404
            C371.418427,360.431427 342.935699,360.447052 314.453003,360.416138
            C308.299011,360.409485 302.145203,360.235046 295.991302,360.137878
          z"
      />
      <path
        fill="#5059C9"
        opacity="1.000000"
        stroke="none"
        d="M853.290039,176.324112
            C857.629944,164.845490 863.698059,154.778030 871.062317,145.535278
            C881.556213,132.364716 894.627625,122.228264 909.676331,114.814369
            C921.436157,109.020798 933.863464,105.419815 947.021179,103.857605
            C965.520203,101.661217 983.353149,103.626350 1000.808838,109.913292
            C1023.094910,117.939964 1041.108276,131.628540 1054.940918,150.805771
            C1066.154663,166.352310 1073.504761,183.714966 1075.789673,202.640900
            C1079.820801,236.031143 1071.760376,266.232788 1050.055298,292.577698
            C1033.221313,313.010162 1011.820984,326.391632 986.346802,331.624573
            C952.016174,338.676910 919.904175,332.296600 891.236023,310.878876
            C868.960999,294.237427 855.096741,272.135864 848.501648,245.640289
            C842.713867,222.388184 844.553345,199.187347 853.290039,176.324112
          z"
      />
      <path
        fill="#464B87"
        opacity="1.000000"
        stroke="none"
        d="M563.972290,423.453491
            C563.971069,412.340363 563.966003,401.696930 564.037903,391.054047
            C564.050049,389.250977 564.393311,387.450134 564.828308,385.310303
            C573.092773,384.991943 581.112488,385.011566 589.603149,385.083984
            C590.040649,387.634796 589.978210,390.132904 589.978149,392.630981
            C589.973328,517.573364 589.976624,642.515808 589.964050,767.458191
            C589.963501,772.621277 589.819580,777.784363 589.697021,783.403564
            C588.400513,786.505310 587.018494,789.097839 585.919678,791.805237
            C579.341125,808.015198 561.806580,819.931946 544.272400,819.997192
            C539.799377,820.013855 535.326050,819.940002 530.534546,819.567139
            C531.790588,818.350037 533.315063,817.365479 534.946716,816.613342
            C562.054993,804.117615 566.833191,781.521179 563.159912,756.686829
            C563.476013,754.127991 563.961182,751.987915 563.962341,749.847656
            C563.991455,695.561035 563.969727,641.274414 563.956909,586.987793
            C563.969360,532.632996 563.981873,478.278198 563.972290,423.453491
          z"
      />
      <path
        fill="#6E76D3"
        opacity="1.000000"
        stroke="none"
        d="M440.977844,207.510925
            C442.881226,206.733261 444.796814,206.046692 446.713409,206.043823
            C487.848999,205.982834 528.984863,205.944824 570.120361,206.019653
            C593.865784,206.062851 614.615173,226.423187 614.990845,250.070129
            C615.429626,277.689087 615.670105,305.311188 616.008301,333.390930
            C607.676270,333.894073 599.332581,333.938202 590.494995,333.471436
            C589.987915,314.671753 590.326538,296.374084 589.864075,278.096680
            C589.387207,259.248932 580.418518,245.207932 563.725159,236.347443
            C552.191956,230.225845 539.598267,231.025314 526.653442,231.180969
            C500.484406,231.094208 474.791351,231.080170 449.098328,231.066132
            C448.901459,231.048676 448.704620,231.031219 448.212585,230.988327
            C445.608429,223.282059 443.299469,215.601242 440.977844,207.510925
          z"
      />
      <path
        fill="#464B88"
        opacity="1.000000"
        stroke="none"
        d="M527.129517,231.253693
            C539.598267,231.025314 552.191956,230.225845 563.725159,236.347443
            C580.418518,245.207932 589.387207,259.248932 589.864075,278.096680
            C590.326538,296.374084 589.987915,314.671753 590.032898,333.420563
            C582.004028,332.630463 573.943420,331.380371 565.487183,330.130615
            C564.734497,327.856903 564.081665,325.584625 564.067871,323.308472
            C563.972290,307.491272 564.157715,291.671539 563.966736,275.856079
            C563.723083,255.682205 547.468262,236.508957 527.489685,232.655640
            C527.101196,231.991943 527.115356,231.622818 527.129517,231.253693
          z"
      />
      <path
        fill="#3E45B0"
        opacity="1.000000"
        stroke="none"
        d="M563.588745,587.017639
            C563.969727,641.274414 563.991455,695.561035 563.962341,749.847656
            C563.961182,751.987915 563.476013,754.127991 563.106567,757.059937
            C558.364624,779.196167 539.822998,793.584473 520.708191,794.100220
            C508.571075,794.427734 496.431152,794.835205 484.291779,794.861206
            C428.000854,794.981812 371.709717,794.981628 315.166626,795.030029
            C314.579071,795.033325 314.243469,795.031372 313.907898,795.029297
            C277.912048,795.009827 241.916214,794.990295 205.455551,794.901794
            C206.471069,793.349670 207.715302,791.459167 209.466797,790.443909
            C240.548965,772.426758 271.702179,754.532166 302.820648,736.577637
            C331.202240,720.202209 359.551880,703.771484 387.931732,687.393127
            C417.895081,670.100891 447.890747,652.864685 477.847534,635.561157
            C504.060608,620.420044 530.230103,605.203552 556.450989,590.075928
            C558.580933,588.847107 560.958191,588.046875 563.588745,587.017639
          z"
      />
      <path
        fill="#7B83EB"
        opacity="1.000000"
        stroke="none"
        d="M313.947205,795.446289
            C314.243469,795.031372 314.579071,795.033325 315.242615,795.452881
            C317.670776,803.966675 319.770905,812.062866 321.871063,820.159058
            C324.825439,828.548340 327.779816,836.937683 330.903015,845.645752
            C332.723785,850.447266 334.375732,854.929993 335.987305,859.815735
            C327.763702,845.432861 322.623444,829.474121 318.051636,813.319275
            C316.426575,807.577026 315.326324,801.686157 313.947205,795.446289
          z"
      />
      <path
        fill="#7B83EB"
        opacity="1.000000"
        stroke="none"
        d="M336.064545,861.394897
            C337.735321,863.833496 339.368439,866.638367 340.974365,869.830322
            C339.307098,867.398621 337.666992,864.579956 336.064545,861.394897
          z"
      />
      <path
        fill="#FFFFFF"
        opacity="1.000000"
        stroke="none"
        d="M295.536316,360.258667
            C302.145203,360.235046 308.299011,360.409485 314.453003,360.416138
            C342.935699,360.447052 371.418427,360.431427 399.901154,360.432404
            C401.850189,360.432495 403.799225,360.432404 405.922760,360.432404
            C405.922760,377.208466 405.922760,393.311035 405.922760,409.887634
            C374.816284,409.887634 343.911591,409.887634 312.344971,409.887634
            C312.344971,461.935059 312.344971,513.511169 312.433655,565.548950
            C312.450348,596.941040 312.378052,627.871399 312.306854,658.801819
            C312.302307,660.776917 312.306274,662.752075 312.306274,665.136292
            C292.392822,665.136292 272.875549,665.136292 252.619049,665.136292
            C252.619049,645.415771 252.619049,625.676147 252.547943,605.473511
            C252.550385,604.018188 252.688553,603.025818 252.688293,602.033508
            C252.672119,539.001343 252.644699,475.969208 252.549515,412.473938
            C250.984192,411.341003 249.490051,410.093323 247.990707,410.087036
            C220.357971,409.970917 192.724625,410.003174 165.091400,410.004089
            C163.303146,410.004150 161.514893,410.004089 159.438812,410.004089
            C159.438812,393.425232 159.438812,377.344543 159.438812,360.379456
            C204.666229,360.379456 249.873795,360.379456 295.536316,360.258667
          z"
      />
      <path
        fill="#5158AA"
        opacity="1.000000"
        stroke="none"
        d="M526.653442,231.180969
            C527.115356,231.622818 527.101196,231.991943 527.099182,232.638000
            C522.048523,232.611038 516.985718,232.307159 511.457825,232.008286
            C492.558838,232.008865 474.124939,232.019913 455.691193,231.977417
            C453.721130,231.972885 451.751801,231.655884 449.440247,231.274994
            C474.791351,231.080170 500.484406,231.094208 526.653442,231.180969
          z"
      />
      <path
        fill="#383C6C"
        opacity="1.000000"
        stroke="none"
        d="M322.348206,820.134155
            C319.770905,812.062866 317.670776,803.966675 315.494629,795.447632
            C371.709717,794.981628 428.000854,794.981812 484.291779,794.861206
            C496.431152,794.835205 508.571075,794.427734 520.708191,794.100220
            C539.822998,793.584473 558.364624,779.196167 563.050110,757.478638
            C566.833191,781.521179 562.054993,804.117615 534.946716,816.613342
            C533.315063,817.365479 531.790588,818.350037 530.062134,819.520935
            C479.509766,819.895325 429.111481,819.978210 378.713165,820.053467
            C360.083893,820.081238 341.454651,820.091187 322.348206,820.134155
          z"
      />
    </SvgIcon>
  );
};

export default MicrosoftTeamsIcon;
