import { INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';

const fieldsThatHaveToBeTransformed = [
  'roadmapTitle',
  'phaseTitle',
  'themeTitle',
  'categoryTitle',
  'priorityTitle',
  'timeframeTitle',
  'objectiveTitle',
  'roadmap1Title',
  'roadmap2Title',
  'teamTitle',
  'tasks',
  'estimates',
  'deadline',
  'created_by',
  'owner',
  'owner_name',
  'parent',
];

export default project => {
  const newProject = {};

  if (!project) {
    return newProject;
  }

  Object.keys(project)
    .filter(key => fieldsThatHaveToBeTransformed.indexOf(key) < 0)
    .forEach(key => {
      newProject[key] = project[key];
    });

  if ('priority' in project) {
    newProject.priority = project.priority || '';
  }

  if ('roadmapCorpTitle' in project) {
    newProject.roadmapCorp = project.roadmapCorpTitle ? { title: project.roadmapCorpTitle } : null;
    newProject.roadmapCorpTitle = project.roadmapCorpTitle;
  }

  if ('roadmapTitle' in project) {
    newProject.roadmap = project.roadmapTitle ? { title: project.roadmapTitle } : null;
    newProject.roadmapTitle = project.roadmapTitle;
  }

  if ('product1Title' in project) {
    newProject.product1 = project.product1Title ? { title: project.product1Title } : null;
  }

  if ('product2Title' in project) {
    newProject.product2 = project.product2Title ? { title: project.product2Title } : null;
  }

  if ('resourceTeamTitle' in project) {
    newProject.resourceTeam = project.resourceTeamTitle ? { ...project.resourceTeam, title: project.resourceTeamTitle } : null;
    newProject.resourceTeamTitle = project.resourceTeamTitle;
  }

  if ('phaseTitle' in project) {
    newProject.phase = project.phaseTitle ? { ...project.phase, title: project.phaseTitle } : null;
    newProject.phaseTitle = project.phaseTitle;
  }

  if ('themeTitle' in project) {
    newProject.theme = project.themeTitle ? { title: project.themeTitle } : null;
    newProject.themeTitle = project.themeTitle;
  }

  if ('categoryTitle' in project) {
    newProject.category = project.categoryTitle ? { title: project.categoryTitle } : null;
    newProject.categoryTitle = project.categoryTitle;
  }

  if ('priorityTitle' in project) {
    newProject.priority = project.priorityTitle ? { title: project.priorityTitle } : null;
    newProject.priorityTitle = project.priorityTitle;
  }

  if ('timeframeTitle' in project) {
    newProject.timeframe = project.timeframeTitle ? { title: project.timeframeTitle } : null;
    newProject.timeframeTitle = project.timeframeTitle;
  }

  if ('timeframe2Title' in project) {
    newProject.timeframe2 = project.timeframe2Title ? { title: project.timeframe2Title } : null;
    newProject.timeframe2Title = project.timeframe2Title;
  }

  if ('objectiveTitle' in project) {
    newProject.objective = project.objectiveTitle ? { title: project.objectiveTitle } : null;
    newProject.objectiveTitle = project.objectiveTitle;
  }

  if ('keyResult1Title' in project) {
    newProject.keyResult1 = project.keyResult1Title ? { title: project.keyResult1Title } : null;
  }

  if ('keyResult2Title' in project) {
    newProject.keyResult2 = project.keyResult2Title ? { title: project.keyResult2Title } : null;
  }

  if ('tasks' in project) {
    newProject.tasks = project.tasks instanceof Array ? project.tasks : [];
  }

  if ('owner' in project || 'ownerName' in project) {
    const fallback = project.owner ? project.owner.id : project.owner_id;

    if (project.owner && project.owner.name && project.ownerName) {
      // if owner name does not match owner.name, clear value to prevent override
      newProject.ownerName = project.owner.name === project.ownerName ? project.ownerName : project.owner.name;
    }

    newProject.owner_id = project.owner && !project.ownerName && project.ownerName in project ? null : fallback;
  }
  if ('owner' in project && !project.owner_id && project.owner && !project.owner.id) {
    newProject.owner = project.owner;
  }

  if ('createdBy' in project) {
    newProject.createdBy = {
      email: project.createdBy && project.createdBy.email ? project.createdBy.email : project.createdBy,
    };
  }

  if ('deadline' in project) {
    newProject.deadline =
      project.deadline && new Date(project.deadline) !== 'Invalid Date'
        ? project.deadline
        : null;
  }

  if ('estimated_start_date' in project) {
    newProject.estimated_start_date =
      project.estimated_start_date && new Date(project.estimated_start_date) !== 'Invalid Date'
        ? project.estimated_start_date
        : null;
  }

  if ('estimates' in project) {
    newProject.estimates =
      project.estimates instanceof Array
        ? project.estimates
            .filter(est => est.skill && est.skill.title && est.team && est.team.title)
            .map(est => {
              return Object.assign({}, est, {
                id: est.id,
                start_date: est.start_date,
                duration: +est.duration,
                numStaff: est.numStaff,
                skill: est.skill && { title: est.skill.title },
                team: est.team && { title: est.team.title },
                sort_order: est.sort_order,
              });
            })
        : [];
  }

  if ('initiativeTitle' in project && project.initiativeTitle) {
    newProject.parent = {
      title: project.initiativeTitle,
      layer: INITIATIVE_LAYER,
      planningStage: 'Planning',
    };
  }

  if ('betTitle' in project && project.betTitle) {
    newProject.parent = {
      title: project.betTitle,
      layer: BET_LAYER,
      planningStage: 'Planning',
    };
  }

  if (project.parent_id === '') newProject.parent_id = null;
  else if ('project_id' in project) newProject.parent_id = project.parent_id;

  return newProject;
};
