import React from 'react';
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import theme from 'design-system/theme';
import formatDate from 'utils/dates/formatDate';
import { getViewName } from 'utils/userViews';
import ViewIconWithAvatar from './ViewIconWithAvatar';
import ActionMenu from './ActionMenu';

export default ({ item, handleFavoriteUserView, handleActionClick, getActionIcon, isFavorite, onSelectView, isLast }) => {
  return (
    <StyledListItem divider={!isLast}>
      <ListItemContainer onClick={() => onSelectView(item)}>
        <ViewIconWithAvatar item={item} />
        <StyledListItemText
          primary={getViewName(item)}
          primaryTypographyProps={{
            style: {
              color: theme.palette.black,
              cursor: 'pointer',
              fontSize: theme.typography.fontSizeSmallLarge,
              fontWeight: theme.typography.fontWeightRegular,
            },
          }}
          secondary={`Last Updated - ${formatDate(item.updated_at, true, true)}`}
          secondaryTypographyProps={{
            style: {
              color: theme.palette.newLayout.text.doveGrey,
              cursor: 'pointer',
              fontSize: theme.typography.fontSizeSmall,
            },
          }}
        />
      </ListItemContainer>
      {!item.is_static && (
        <ActionMenu
          item={item}
          handleFavoriteUserView={handleFavoriteUserView}
          handleActionClick={handleActionClick}
          getActionIcon={getActionIcon}
          isFavorite={isFavorite}
          shareButtonId="dashboard-home-share-view-button"
        />
      )}
    </StyledListItem>
  );
};

const StyledListItem = styled(ListItem)`
  && {
    justify-content: space-between;
  }
`;

const StyledListItemText = styled(ListItemText)`
  justify-content: space-between;

  &&&&& {
    width: 400px;
  }

  & :hover {
    color: ${theme.palette.newLayout.background.primary} !important;
  }
`;

const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
`;
