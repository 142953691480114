import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import reduceReducers from 'reduce-reducers';
import { defaultTo } from 'ramda';
import sortByRank from 'utils/sortByRank';
import { APPLY_FILTERS, APPLY_FILTERS_FULFILLED } from 'store/projects';
import { OPEN_PROJECT_LIGHTBOX, OPEN_PROJECT_LIGHTBOX_FULFILLED } from 'store/projectLightbox';
import { buildMetadataProxy } from './helpers';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  APPLY_FILTERS,
  OPEN_PROJECT_LIGHTBOX,
]);

const initialState = {
  ...thunksInitialState,
  metadata: buildMetadataProxy({}),
};

const metadataOnDemandReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_FILTERS_FULFILLED: {
      const { payload } = action;
      const loadedMetadata = defaultTo({}, payload?.metadataOnDemand);
      const metadata = Object.entries(loadedMetadata).reduce((acc, [metadataKey, metadataValues]) => {
        const values = defaultTo([], metadataValues);

        return {
          ...acc,
          [metadataKey]: values.sort(sortByRank),
        };
      }, state.metadata);

      return {
        ...state,
        metadata: buildMetadataProxy(metadata),
      };
    }
    case OPEN_PROJECT_LIGHTBOX_FULFILLED: {
      const { payload } = action;
      const loadedMetadata = defaultTo({}, payload?.metadataOnDemand);

      const metadata = Object.entries(loadedMetadata).reduce((acc, [metadataKey, metadataValues]) => {
        const values = defaultTo([], metadataValues);

        return {
          ...acc,
          [metadataKey]: [...defaultTo([], state?.metadata?.[metadataKey]), ...values].sort(sortByRank),
        };
      }, state.metadata);

      return {
        ...state,
        metadata: buildMetadataProxy(metadata),
      };
    }
    default:
      return state;
  }
};

const reducer = reduceReducers(initialState, metadataOnDemandReducer, ...thunksReducers);

export default reducer;
