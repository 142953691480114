import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { prop } from 'ramda';

import NewDialog from 'design-system/organisms/NewDialog/index';
import Text from 'design-system/atoms/Text/index';
import DialogActions from 'design-system/molecules/DialogActions';

import useSystemFields from 'hooks/useSystemFields';
import useScenariosNavigation from 'hooks/useScenariosNavigation';
import useDeleteScenario from './hooks/useDeleteScenario';

import { closeDeleteScenarioDialog, createOrLoadNewRoadmapVersionAfterDelete } from 'store/roadmapVersions';
import {
  selectDeleteScenarioDialogStep,
  selectDeleteScenarioDialogVisible,
  selectScenarioToDelete,
} from 'store/roadmapVersions/selectors';
import { CONFIRM_DELETE } from 'store/roadmapVersions/constants/deleteScenarioDialogSteps';
import { selectIsScenarioRoute } from 'store/app';

const deleteScenarioConfirmationMessage = getSystemFieldName =>
  `Are you sure you want to delete this ${getSystemFieldName('scenario')}?`;
const headerTitle = getSystemFieldName => `Delete ${getSystemFieldName('scenario')}`;
const CHOOSE_NEXT_ACTION_MESSAGE = 'Choose your next action.';
const GO_BACK_MESSAGE = 'Go back to previous page';
const createNewScenarioMessage = getSystemFieldName => `Create a new ${getSystemFieldName('scenario')}`;

const getId = prop('id');

const DeleteScenarioDialog = ({ pageId }) => {
  const [getSystemFieldName] = useSystemFields();

  const dispatch = useDispatch();

  const step = useSelector(selectDeleteScenarioDialogStep);
  const dialogVisible = useSelector(selectDeleteScenarioDialogVisible);
  const isScenarioRoute = useSelector(selectIsScenarioRoute);
  const scenarioToDelete = useSelector(selectScenarioToDelete);
  const scenarioToDeleteId = getId(scenarioToDelete);

  const { navigateToScenarioModule, exitScenarioModule } = useScenariosNavigation();
  const { onDeleteScenario } = useDeleteScenario();

  const handleDeleteScenario = () => {
    onDeleteScenario(scenarioToDeleteId);
  };

  const handleCreateNewScenario = () => {
    if (!isScenarioRoute) navigateToScenarioModule();

    dispatch(createOrLoadNewRoadmapVersionAfterDelete(pageId));
  };

  const _hideDialog = () => {
    dispatch(closeDeleteScenarioDialog());
  };

  const handleExitScenarioModule = () => {
    if (isScenarioRoute) exitScenarioModule();

    _hideDialog();
  };

  const _renderDialogAction = () => {
    if (step === CONFIRM_DELETE) {
      return <DialogActions onDelete={handleDeleteScenario} onCancel={_hideDialog} />;
    }

    return (
      <DialogActions
        onSave={handleCreateNewScenario}
        onCancel={handleExitScenarioModule}
        saveLabel={createNewScenarioMessage(getSystemFieldName)}
        cancelLabel={GO_BACK_MESSAGE}
      />
    );
  };

  const _renderDialogContent = () => {
    if (step === CONFIRM_DELETE) {
      return <Text>{deleteScenarioConfirmationMessage(getSystemFieldName)}</Text>;
    }

    return <Text>{CHOOSE_NEXT_ACTION_MESSAGE}</Text>;
  };

  return (
    <NewDialog
      open={dialogVisible}
      header={
        <Text inline as="h3">
          {headerTitle(getSystemFieldName)}
        </Text>
      }
      actions={_renderDialogAction()}
    >
      {_renderDialogContent()}
    </NewDialog>
  );
};

export default DeleteScenarioDialog;
