import React, { useCallback } from 'react';
import styled from 'styled-components';
import dragonbotTheme from '../dragonbot.theme';
import { isEmpty } from 'ramda';
import theme from 'design-system/theme';

export default (props) => {
  const {
    triggerNextStep,
    options = [],
    previousStep,
    chatbotRef,
  } = props;
  const optionsFromPreviousStep = Array.isArray(previousStep?.value?.options) ? previousStep.value.options : [];
  const optionsList = !isEmpty(optionsFromPreviousStep) ? optionsFromPreviousStep : options;

  const bubbleOptionStyle = {
    cursor: 'pointer',
    border: `1px solid ${theme.palette.primary.main}`,
    marginBottom: '10px',
  };

  const onOptionSelected = useCallback(option => () => {
    chatbotRef.current.state.currentStep.options = optionsList;

    return triggerNextStep(option);
  }, [chatbotRef.current, optionsList]);

  return (
    <div className='rsc-os' style={{ width: '100%' }}>
      <Options className='rsc-os-options'>
        {optionsList.map(option => (
          <Option
            onClick={onOptionSelected(option)}
            className='rsc-os-option'
          >
            <OptionElement
              theme={dragonbotTheme}
              className='rsc-os-option-element'
              style={bubbleOptionStyle}
            >
              {option.label}
            </OptionElement>
          </Option>
        ))}
      </Options>
    </div>

  );
};

const Options = styled.ul`
  margin: 2px 0 12px 0;
  padding: 0 6px;
`;

const Option = styled.li`
  animation: Lmuha 0.3s ease forwards;
  cursor: pointer;
  display: inline-block;
  margin: 2px;
  transform: scale(0);
`;

const OptionElement = styled.button`
  background: ${({ theme }) => theme.botBubbleColor};
  border: 0;
  border-radius: 22px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.botFontColor};
  display: inline-block;
  font-size: 14px;
  padding: 12px;
  &:hover {
    opacity: 0.7;
  }
  &:active,
  &:hover:focus {
    outline:none;
  }
`;
