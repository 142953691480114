import formatDateForGantt from 'utils/dates/formatDateForGantt';

import checkProjectTasksOverlap from '../helpers/checkProjectTasksOverlap';

const getEstimateText = (est, collapseGanttBars) => {
  if (collapseGanttBars) return est.team.title;

  let text = `${est.numStaff}`;

  if (est.team && est.team.title) {
    text += ` ${est.team.title}`;
  }
  if (est.skill && est.skill.title) {
    text += ` ${est.skill.title}`;
  }
  return text;
};

/**
 * Function that parse estimate to the gantt format
 *
 * @param {*} est estimate object
 * @param {*} project project object
 * @param {*} collapseGanttBars collapse gantt bars setting
 */
const parseGanttEstimate = (est, project, collapseGanttBars) => {
  const projectStartDate = project.start_date ? formatDateForGantt(project.start_date) : new Date();

  return {
    ...est,
    id: `estimate-${est.id}`,
    entityId: est.id,
    dbType: 'estimate',
    type: 'task',
    parent: project.id,
    text: `* ${getEstimateText(est, collapseGanttBars)}`,
    shortText: getEstimateText(est, collapseGanttBars),
    color: collapseGanttBars && est.team ? est.team.color : 'rgba(176, 190, 197, 0.8)',
    textColor: '#fff',
    start_date: est.start_date ? formatDateForGantt(est.start_date) : projectStartDate,
    ...(est.end_date ? { end_date: formatDateForGantt(est.end_date) } : {}),
    duration: est.duration,
    roadmap_id: project.roadmap_id,
    roadmapTitle: '',
    objective_id: project.objective_id,
    phase_id: project.phase_id,
    teamName: est.team ? est.team.title : '',
    skillName: est.skill ? est.skill.title : '',
    planningStage: '',
    sort_order: est.sort_order,
    open: false,
    $overlap: checkProjectTasksOverlap(collapseGanttBars, est, project.estimates),
    $committed: project?.committed,
  };
};

export default parseGanttEstimate;
