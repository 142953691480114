import { useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { ROADMAP_PAGE } from 'constants/filters';
import {
  isCreateProjectLoading,
  isDeleteProjectLoading,
  isLoadingProjects,
  isApplyFiltersUninitialized,
  isUpdateProjectError,
  isUpdateProjectLoading,
  resetProjectUpdate,
  updateProject,
} from 'store/projects';
import { openProjectLightbox } from 'store/projectLightbox';
import { getDisplayLayer, makeSelectFilteredProjectsByRoadmapForPage } from 'store/filters/selectors';
import useAppNotifications from 'hooks/useAppNotifications';
import useDebounceSelector from 'hooks/useDebounceSelector';
import useProjectsLocalFilterByOwner from 'hooks/projects/useProjectsLocalFilterByOwner';
import useProjectsFrontendFilters from 'hooks/filters/useProjectsFontendFilters';

const getFilteredProjectsForPage = makeSelectFilteredProjectsByRoadmapForPage();

const useSwimlaneProjects = (showMyItemsOnly = false) => {
  const dispatch = useDispatch();

  const { addError } = useAppNotifications();

  const displayLayer = useSelector(getDisplayLayer);

  const isUninitializedApplyingFilters = useSelector(isApplyFiltersUninitialized);
  const isFetchingProjects = useSelector(isLoadingProjects);

  const isCreatingProject = useSelector(isCreateProjectLoading);
  const isUpdatingProject = useSelector(isUpdateProjectLoading);
  const isDeletingProject = useSelector(isDeleteProjectLoading);
  const hasProjectUpdateError = useSelector(isUpdateProjectError);

  const storeProjects = useDebounceSelector(
    state => getFilteredProjectsForPage(state, ROADMAP_PAGE, false, displayLayer),
    isEqual,
    100,
  );

  const filterMyItems = useProjectsLocalFilterByOwner(storeProjects, showMyItemsOnly);
  const { applyFrontendFilters } = useProjectsFrontendFilters(filterMyItems);

  const projects = useMemo(() => {
    return applyFrontendFilters(storeProjects);
  }, [storeProjects, applyFrontendFilters]);

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          updateProject,
          resetProjectUpdate,
        },
        dispatch,
      ),
    [dispatch],
  );

  const handleOpenProjectLightBox = (projectId = null, tab = null, defaultData = {}) => {
    dispatch(openProjectLightbox(projectId, tab, defaultData));
  };

  useEffect(() => {
    if (hasProjectUpdateError) {
      addError({ id: 'project-update-error', message: 'Update failed' });

      boundActionCreators.resetProjectUpdate();
    }
  }, [dispatch, hasProjectUpdateError, addError]);

  return {
    projects,
    isUninitializedApplyingFilters,
    isFetchingProjects,
    isCreatingProject,
    isUpdatingProject,
    isDeletingProject,
    handleOpenProjectLightBox,
    displayLayer,
    ...boundActionCreators,
  };
};

export default useSwimlaneProjects;
