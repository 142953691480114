import axios from 'axios';

import {
  REMOVE_UNSAVED_TAGS,
  ADD_TAG_WITHOUT_SAVE,
  CREATE_TAG_FULFILLED,
  DELETE_TAG_FULFILLED,
  UPDATE_TAG_FULFILLED,
  BULK_DELETE_TAGS_FULFILLED,
  BULK_DELETE_TAG_ROADMAP,
  CREATE_TAG_ROADMAP,
  DELETE_TAG_ROADMAP,
} from './types';

import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';

export const createTagRoadmap = (tagId, tagRoadmap) => {
  return {
    payload: axios.post(`/api/tags/${tagId}/tagRoadmaps/`, tagRoadmap).then(response => response.data),
    type: CREATE_TAG_ROADMAP,
  };
};

export const deleteTagRoadmap = ({ id, roadmapId, subRoadmapId = null, product2Id = null }) => {
  let route = `/api/tags/${id}/tagRoadmaps/${roadmapId}`;

  route += subRoadmapId ? `/${subRoadmapId}` : '';
  route += subRoadmapId && product2Id ? `/${product2Id}` : '';

  return {
    payload: axios.delete(route).then(response => response.data),
    type: DELETE_TAG_ROADMAP,
  };
};

export const bulkDeleteTagRoadmaps = tagId => {
  return {
    payload: axios.delete(`/api/tags/${tagId}/tagRoadmaps/`).then(response => response.data),
    type: BULK_DELETE_TAG_ROADMAP,
  };
};

export const addTagWithoutSave = tag => ({
  type: ADD_TAG_WITHOUT_SAVE,
  tag,
});

export const removeUnsavedTags = _ => ({
  type: REMOVE_UNSAVED_TAGS,
});

export const gotTagsRealtimeUpdate = generateRealtimeUpdateAction(
  CREATE_TAG_FULFILLED,
  DELETE_TAG_FULFILLED,
  UPDATE_TAG_FULFILLED,
  BULK_DELETE_TAGS_FULFILLED,
);
