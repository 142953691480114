import {
  SUPER_ADMIN_USER,
  CSM_USER,
  ADMIN_USER,
  OWNER_USER,
  LEADER_USER,
  READ_ONLY_USER,
  RESOURCE_USER,
  REQUESTOR_USER,
  EXTERNAL_REQUESTOR,
  EDITOR_USER,
  MANAGER_USER,
  COLLABORATOR_USER,
  PLANNER_USER,
} from '@dragonboat/permissions';

const ROLE_LABEL_MAPPER = {
  [ADMIN_USER]: 'Editor',
  [SUPER_ADMIN_USER]: 'Super Admin',
  [OWNER_USER]: 'Owner',
  [READ_ONLY_USER]: 'Read Only',
  [LEADER_USER]: 'Admin',
  [REQUESTOR_USER]: 'Requestor',
  [CSM_USER]: 'CSM',
  [EXTERNAL_REQUESTOR]: 'External Requestor',
  [RESOURCE_USER]: 'No Access',
  [PLANNER_USER]: 'Planner',
  [COLLABORATOR_USER]: 'Collaborator',
  [EDITOR_USER]: 'Lite Editor',
  [MANAGER_USER]: 'Editor',
};

export const getUserRoleLabel = role => {
  return ROLE_LABEL_MAPPER[role] || '';
};

export default [
  // { value: 2, label: 'Editor' },
  { value: RESOURCE_USER, label: getUserRoleLabel(RESOURCE_USER) },
  { value: READ_ONLY_USER, label: getUserRoleLabel(READ_ONLY_USER) },
  { value: ADMIN_USER, label: getUserRoleLabel(ADMIN_USER) },
  { value: LEADER_USER, label: getUserRoleLabel(LEADER_USER) },
  { value: OWNER_USER, label: getUserRoleLabel(OWNER_USER) },
  { value: REQUESTOR_USER, label: getUserRoleLabel(REQUESTOR_USER) },
  { value: EXTERNAL_REQUESTOR, label: getUserRoleLabel(EXTERNAL_REQUESTOR) },
  { value: PLANNER_USER, label: getUserRoleLabel(PLANNER_USER) },
  { value: EDITOR_USER, label: getUserRoleLabel(EDITOR_USER) },
  { value: COLLABORATOR_USER, label: getUserRoleLabel(COLLABORATOR_USER) },
];
