import reduceReducers from 'reduce-reducers';
import { propOr } from 'ramda';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  FETCH_ROADMAP_VERSIONS,
  FETCH_ROADMAP_VERSION_PROJECTS_FULFILLED,
  CREATE_NEW_ROADMAP_VERSION,
  SET_ROADMAP_VERSION_SELECTED,
  FETCH_ROADMAP_VERSIONS_FULFILLED,
  CREATE_NEW_ROADMAP_VERSION_FULFILLED,
  UPDATE_ROADMAP_VERSION_DIALOG_PROPERTIES,
  UPDATE_ROADMAP_VERSION_FULFILLED,
  DISCARD_ROADMAP_VERSION_UPDATED_PROJECTS,
  SET_SELECTED_ROADMAP_VERSIONS_TO_COMPARE,
  SET_SELECTED_ROADMAP_VERSIONS_FIELD,
  RUN_ONE_CLICK_PLAN,
  RUN_ONE_CLICK_PLAN_FULFILLED,
  FETCH_MULTIPLE_ROADMAP_VERSION_PROJECTS,
  FETCH_MULTIPLE_ROADMAP_VERSION_PROJECTS_FULFILLED,
  SET_VERSION_AS_PLAN_OF_RECORD,
  SET_VERSION_AS_PLAN_OF_RECORD_FULFILLED,
  DELETE_ROADMAP_VERSION_FULFILLED,
  SET_STACKED_BY_CHART_OPTION_SELECTED,
  SET_PROJECTED_CHART_OPTION_SELECTED,
  SET_BASE_COMPARE_VERSION_SELECTED,
  SET_COMPARE_SCENARIOS_VERSIONS_COLORS,
  SET_CHARTS_DATE_INTERVAL,
  SET_PENDING_CHANGES,
  CLONE_ROADMAP_VERSION_FULFILLED,
  SET_TIME_BUCKET_CHART_OPTION_SELECTED,
  TOGGLE_HIDE_ROWS_WITH_SAME_DATA,
  UPDATE_SCENARIO_PROJECT,
  SET_COMPARE_SCENARIOS_EDIT_SCENARIO,
  UNSET_SELECTED_SCENARIO,
  OPEN_DELETE_SCENARIO_DIALOG,
  CLOSE_DELETE_SCENARIO_DIALOG,
  LOAD_SCENARIO_FROM_QUERY_PARAM_FULFILLED,
  CREATE_OR_LOAD_NEW_ROADMAP_VERSION_AFTER_DELETE_FULFILLED,
  SET_SCENARIOS_DIALOG_OPEN,
} from './types';
import { DISABLE_ONE_CLICK_PLAN_MODE } from 'store/app/types';
import { COMPARE_SCENARIOS_PLAN_OF_RECORD_VERSION } from 'constants/common';
import { colorsReducer, initialState as colorsInitialState } from './colorsReducer';
import updateRows from 'store/utils/updateRows';
import { CONFIRM_DELETE, GO_BACK_OR_CREATE_NEW } from './constants/deleteScenarioDialogSteps';

const { initialState: thunksInitialState, reducers: thunksReducers } = getThunksInitialStateAndReducers([
  FETCH_ROADMAP_VERSIONS,
  CREATE_NEW_ROADMAP_VERSION,
  FETCH_MULTIPLE_ROADMAP_VERSION_PROJECTS,
  SET_VERSION_AS_PLAN_OF_RECORD,
  RUN_ONE_CLICK_PLAN,
]);

const initialState = {
  ...thunksInitialState,
  selectedRoadmapVersion: null,
  availableRoadmapVersions: [],
  hasPendingChanges: false,
  saveDialog: {
    showSavePopup: false,
    showSaveDialog: false,
    nextRoute: null,
  },
  deleteScenarioDialog: {
    visible: false,
    step: CONFIRM_DELETE,
    scenarioToDelete: null,
  },
  roadmapVersionsProjectsById: {},
  compareVersions: {
    selectedRoadmapVersions: [],
    selectedField: null,
    baseCompareVersionSelected: COMPARE_SCENARIOS_PLAN_OF_RECORD_VERSION,
    hideRowsWithSameData: false,
    compareVersionEditScenario: null,
    ...colorsInitialState,
  },
  scenariosDialogOpen: false,
};

const getScenario = propOr(null, 'scenarioToDelete');

const roadmapVersionsReducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case FETCH_ROADMAP_VERSIONS_FULFILLED:
      return {
        ...state,
        availableRoadmapVersions: payload,
        selectedRoadmapVersion: state.selectedRoadmapVersion && !payload.length ? null : state.selectedRoadmapVersion,
      };
    case FETCH_ROADMAP_VERSION_PROJECTS_FULFILLED:
      return {
        ...state,
        roadmapVersionsProjectsById: {
          ...state.roadmapVersionsProjectsById,
          [meta.roadmapVersionId]: payload.data,
        },
      };
    case FETCH_MULTIPLE_ROADMAP_VERSION_PROJECTS_FULFILLED:
      return {
        ...state,
        roadmapVersionsProjectsById: {
          ...(meta?.resetData ? {} : state.roadmapVersionsProjectsById),
          ...payload.data,
        },
      };
    case CLONE_ROADMAP_VERSION_FULFILLED: {
      const availableRoadmapVersions =
        payload?.committed || Boolean(meta?.clonedFrom)
          ? state.availableRoadmapVersions.concat(payload)
          : state.availableRoadmapVersions;

      const prevSelectedRoadmapVersion = state.selectedRoadmapVersion;
      const prevCompareVersionEditScenario = state.compareVersions.compareVersionEditScenario;

      const clonedFromSelectedRoadmapVersion = prevSelectedRoadmapVersion?.id === meta?.clonedFrom;
      const clonedFromCompareVersionEditScenario = prevCompareVersionEditScenario?.id === meta?.clonedFrom;

      return {
        ...state,
        availableRoadmapVersions,
        hasPendingChanges: false,
        recentlyCloned: Boolean(meta?.clonedFrom),
        clonedFrom: meta?.clonedFrom,
        ...(clonedFromSelectedRoadmapVersion ? { selectedRoadmapVersion: payload } : {}),
        ...(clonedFromCompareVersionEditScenario
          ? { compareVersions: { ...state.compareVersions, compareVersionEditScenario: payload } }
          : {}),
      };
    }
    case CREATE_NEW_ROADMAP_VERSION_FULFILLED: {
      const availableRoadmapVersions = payload?.committed
        ? state.availableRoadmapVersions.concat(payload)
        : state.availableRoadmapVersions;

      return {
        ...state,
        selectedRoadmapVersion: payload,
        availableRoadmapVersions,
        hasPendingChanges: false,
      };
    }
    case CREATE_OR_LOAD_NEW_ROADMAP_VERSION_AFTER_DELETE_FULFILLED: {
      const availableRoadmapVersions = payload?.committed
        ? state.availableRoadmapVersions.concat(payload)
        : state.availableRoadmapVersions;

      return {
        ...state,
        selectedRoadmapVersion: payload,
        availableRoadmapVersions,
        hasPendingChanges: false,
        deleteScenarioDialog: {
          visible: false,
          step: CONFIRM_DELETE,
          scenarioToDelete: null,
        },
        scenariosDialogOpen: false,
      };
    }
    case LOAD_SCENARIO_FROM_QUERY_PARAM_FULFILLED: {
      const { scenario } = payload;

      const availableRoadmapVersions = updateRows(state.availableRoadmapVersions, scenario);

      return {
        ...state,
        selectedRoadmapVersion: scenario,
        availableRoadmapVersions,
      };
    }
    case SET_ROADMAP_VERSION_SELECTED: {
      return {
        ...state,
        selectedRoadmapVersion: payload,
        hasPendingChanges: false,
        recentlyCloned: false,
      };
    }
    case UPDATE_ROADMAP_VERSION_DIALOG_PROPERTIES: {
      return {
        ...state,
        saveDialog: {
          ...state.saveDialog,
          ...payload,
        },
      };
    }
    case UPDATE_ROADMAP_VERSION_FULFILLED: {
      const currentRoadmapVersionsLoaded = state.availableRoadmapVersions;
      const listIndex = currentRoadmapVersionsLoaded.findIndex(version => version.id === payload.id);
      const versionExists = listIndex >= 0;

      if (!versionExists) {
        currentRoadmapVersionsLoaded.push(payload);
      } else {
        currentRoadmapVersionsLoaded[listIndex] = payload;
      }

      const updatingSelectedVersion = state.selectedRoadmapVersion?.id === payload.id;

      const selectedRoadmapVersion = !versionExists || updatingSelectedVersion ? payload : state.selectedRoadmapVersion;

      return {
        ...state,
        availableRoadmapVersions: [...currentRoadmapVersionsLoaded],
        selectedRoadmapVersion,
        hasPendingChanges: false,
      };
    }
    case DISCARD_ROADMAP_VERSION_UPDATED_PROJECTS: {
      return {
        ...state,
        hasPendingChanges: false,
        recentlyCloned: false,
      };
    }
    case SET_SELECTED_ROADMAP_VERSIONS_TO_COMPARE: {
      return {
        ...state,
        compareVersions: {
          ...state.compareVersions,
          selectedRoadmapVersions: payload,
        },
      };
    }
    case SET_SELECTED_ROADMAP_VERSIONS_FIELD: {
      return {
        ...state,
        compareVersions: {
          ...state.compareVersions,
          selectedField: payload,
        },
      };
    }
    case RUN_ONE_CLICK_PLAN_FULFILLED: {
      const oneClickPlanRoadmapVersion = payload.data;

      return {
        ...state,
        selectedRoadmapVersion: oneClickPlanRoadmapVersion,
        availableRoadmapVersions: state.availableRoadmapVersions.concat(oneClickPlanRoadmapVersion),
      };
    }
    case DISABLE_ONE_CLICK_PLAN_MODE: {
      const { shouldReturnToVersion } = payload;

      return {
        ...state,
        selectedRoadmapVersion: shouldReturnToVersion || state.selectedRoadmapVersion,
      };
    }
    case SET_VERSION_AS_PLAN_OF_RECORD_FULFILLED: {
      const backupVersionCreated = payload.data;
      const availableVersions = state.availableRoadmapVersions || [];

      return {
        ...state,
        availableRoadmapVersions: availableVersions.concat(backupVersionCreated),
      };
    }
    case DELETE_ROADMAP_VERSION_FULFILLED: {
      return {
        ...state,
        selectedRoadmapVersion: null,
        availableRoadmapVersions: state.availableRoadmapVersions.filter(version => version.id !== payload),
        compareVersions: {
          ...state.compareVersions,
          selectedRoadmapVersions: state.compareVersions.selectedRoadmapVersions.filter(id => id !== String(payload)),
        },
        deleteScenarioDialog: {
          visible: true,
          step: GO_BACK_OR_CREATE_NEW,
          scenarioToDelete: null,
        },
        scenariosDialogOpen: false,
      };
    }
    case SET_STACKED_BY_CHART_OPTION_SELECTED: {
      return {
        ...state,
        compareVersions: {
          ...state.compareVersions,
          stackedByChartOptionSelected: payload,
        },
      };
    }
    case SET_PROJECTED_CHART_OPTION_SELECTED: {
      return {
        ...state,
        compareVersions: {
          ...state.compareVersions,
          projectedChartOptionSelected: payload,
        },
      };
    }
    case SET_TIME_BUCKET_CHART_OPTION_SELECTED: {
      return {
        ...state,
        compareVersions: {
          ...state.compareVersions,
          timeBucketChartOptionSelected: payload,
        },
      };
    }
    case SET_BASE_COMPARE_VERSION_SELECTED:
      return {
        ...state,
        compareVersions: {
          ...state.compareVersions,
          baseCompareVersionSelected: payload,
        },
      };
    case SET_COMPARE_SCENARIOS_VERSIONS_COLORS:
      return {
        ...state,
        compareVersions: {
          ...state.compareVersions,
          versionsColor: payload,
        },
      };
    case SET_CHARTS_DATE_INTERVAL:
      return {
        ...state,
        compareVersions: {
          ...state.compareVersions,
          chartsDateInterval: payload,
        },
      };
    case SET_PENDING_CHANGES:
      return { ...state, hasPendingChanges: payload };
    case TOGGLE_HIDE_ROWS_WITH_SAME_DATA:
      return {
        ...state,
        compareVersions: {
          ...state.compareVersions,
          hideRowsWithSameData: !state.compareVersions?.hideRowsWithSameData,
        },
      };

    // if updates are made to the plan of record fields we need to refresh the data on the version project for those fields
    case UPDATE_SCENARIO_PROJECT: {
      const { projectData, roadmapVersionId } = payload;

      const versionProjectList = state.roadmapVersionsProjectsById[roadmapVersionId] ?? [];

      return {
        ...state,
        roadmapVersionsProjectsById: {
          ...state.roadmapVersionsProjectsById,
          [roadmapVersionId]: versionProjectList.map(project => {
            if (project.id === projectData.id) {
              return projectData;
            }
            return project;
          }),
        },
      };
    }
    case SET_COMPARE_SCENARIOS_EDIT_SCENARIO: {
      return {
        ...state,
        compareVersions: {
          ...state.compareVersions,
          compareVersionEditScenario: payload,
        },
      };
    }
    case UNSET_SELECTED_SCENARIO: {
      return {
        ...state,
        selectedRoadmapVersion: null,
        compareVersions: {
          ...state.compareVersions,
          compareVersionEditScenario: null,
        },
        hasPendingChanges: false,
      };
    }
    case OPEN_DELETE_SCENARIO_DIALOG: {
      const scenarioToDelete = getScenario(payload);

      return {
        ...state,
        deleteScenarioDialog: {
          visible: true,
          step: CONFIRM_DELETE,
          scenarioToDelete,
        },
      };
    }
    case CLOSE_DELETE_SCENARIO_DIALOG: {
      return {
        ...state,
        deleteScenarioDialog: {
          visible: false,
          step: CONFIRM_DELETE,
          scenarioToDelete: null,
        },
      };
    }
    case SET_SCENARIOS_DIALOG_OPEN: {
      const { open } = payload;

      return {
        ...state,
        scenariosDialogOpen: open,
      };
    }

    default:
      return state;
  }
};

export { initialState };

const reducer = reduceReducers(initialState, roadmapVersionsReducer, colorsReducer, ...thunksReducers);

export default reducer;
