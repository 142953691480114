/**
 * Generate Groupbar width
 * @param {array} tasks
 */
export const generateGroupBarWidth = infos => {
  const value = Object.keys(infos).reduce(
    (val, key) => {
      if (val.min > infos[key].left) {
        val.min = infos[key].left;
      }

      if (val.max < infos[key].left + infos[key].width) {
        val.max = infos[key].left + infos[key].width;
      }

      return val;
    },
    { min: 99999, max: 0 },
  );

  return {
    left: value.min,
    width: value.max - value.min,
    pLeft: 0,
  };
};

export const generateInfos = tasks => {
  return (
    (tasks &&
      tasks.reduce(
        (value, task) => ({
          ...value,
          [task.id]: { ...task.info, pLeft: task.info.left, pWidth: task.info.width },
        }),
        {},
      )) ||
    []
  );
};
