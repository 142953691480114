const OPEN_OBJECTIVE_QUERY_PARAM = 'openObjective';
const OBJECTIVE_TYPE_QUERY_PARAM = 'objectiveType';

const CORP_OBJECTIVE_KEY = 'objectiveCorp';
const OBJECTIVE_KEY = 'objective';
const KEY_RESULT_1_KEY = 'keyResult1';
const KEY_RESULT_2_KEY = 'keyResult2';

const OBJECTIVE_CORP_LEVEL = 'CORP';
const OBJECTIVE_LEVEL = '0';
const KEY_RESULT_1_LEVEL = 0;
const KEY_RESULT_2_LEVEL = 1;

const OBJECTIVE_CORP_ICON_TYPE = 'objectiveCorp';
const OBJECTIVE_ICON_TYPE = 'objective';
const KEY_RESULT_1_ICON_TYPE = 'keyResult';
const KEY_RESULT_2_ICON_TYPE = 'keyResult2';

const PARENT_ID = 'parent_id';
const OBJECTIVE_ID = 'objective_id';

const STATUS_COLOR_PROP_MAP = {
  Green: 'green',
  Yellow: 'yellow',
  Red: 'red',
  Blue: 'blue',
  Undefined: 'darkGray',
};

const OKR_HEALTH_COLORS_OPTIONS = [
  { label: 'Undefined', value: 'Undefined' },
  { label: 'On track', value: 'Green' },
  { label: 'At risk', value: 'Yellow' },
  { label: 'Off track', value: 'Red' },
  { label: 'Completed', value: 'Blue' },
];

const OKR_PROGRESS_TYPE_MANUAL = 'manual';
const OKR_PROGRESS_TYPE_AUTO = 'auto';
const OKR_PROGRESS_TYPE_AUTO_ROLLUP_CHILDREN = 'auto-rollup-children';

export {
  OPEN_OBJECTIVE_QUERY_PARAM,
  OBJECTIVE_TYPE_QUERY_PARAM,
  CORP_OBJECTIVE_KEY,
  OBJECTIVE_KEY,
  KEY_RESULT_1_KEY,
  KEY_RESULT_2_KEY,
  OBJECTIVE_CORP_LEVEL,
  OBJECTIVE_LEVEL,
  KEY_RESULT_1_LEVEL,
  KEY_RESULT_2_LEVEL,
  OBJECTIVE_CORP_ICON_TYPE,
  OBJECTIVE_ICON_TYPE,
  KEY_RESULT_1_ICON_TYPE,
  KEY_RESULT_2_ICON_TYPE,
  STATUS_COLOR_PROP_MAP,
  OKR_HEALTH_COLORS_OPTIONS,
  OKR_PROGRESS_TYPE_MANUAL,
  OKR_PROGRESS_TYPE_AUTO,
  OKR_PROGRESS_TYPE_AUTO_ROLLUP_CHILDREN,
  OBJECTIVE_ID,
  PARENT_ID,
};
