import React, { useState } from 'react';
import { EditorState } from 'draft-js';

const componentHOC = Component => {
  return props => {
    const { view } = props;
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [viewName, setViewName] = useState('');
    const [viewDescription, setViewDescription] = useState('');

    const nameHasNotChanged = view && viewName === view.name;
    const descriptionHasNotChanged = view && viewDescription === view.description;
    const isSaveDisabled = !viewName || (nameHasNotChanged && descriptionHasNotChanged);

    return (
      <Component
        {...props}
        editorState={editorState}
        setEditorState={setEditorState}
        viewName={viewName}
        setViewName={setViewName}
        viewDescription={viewDescription}
        setViewDescription={setViewDescription}
        isSaveDisabled={isSaveDisabled}
      />
    );
  };
};

export default componentHOC;
