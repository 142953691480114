import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getIsCreatingCustomerRequest, getCustomerRequestFormData } from 'store/customerRequests/selectors';
import {
  updateCustomerRequest as updateCustomerRequestAction,
  createCustomerRequest as createCustomerRequestAction,
} from 'store/customerRequests/actions';

import useEditRequestPermissions from '../hooks/useEditRequestPermissions';

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const isCreating = useSelector(getIsCreatingCustomerRequest);
    const updateCustomerRequest = (id, data) => dispatch(updateCustomerRequestAction(id, data));
    const createCustomerRequest = data => dispatch(createCustomerRequestAction(data));
    const formData = useSelector(getCustomerRequestFormData);

    const originalData = React.useRef(formData);

    originalData.current = formData;

    const _updateValue = async (fieldName, value) => {
      if (fieldName === 'title' && !value) return;

      if (value !== originalData.current[fieldName]) {
        return updateCustomerRequest(originalData.current.id, { [fieldName]: value });
      }
    };
    const _createCustomerRequest = data => {
      createCustomerRequest({ ...originalData.current, ...data });
    };

    const [, , canSimpleEditRequest] = useEditRequestPermissions(formData);

    return (
      <Component
        {...props}
        data={originalData.current}
        isCreating={isCreating}
        updateValue={_updateValue}
        canSimpleEditRequest={canSimpleEditRequest}
        createCustomerRequest={_createCustomerRequest}
      />
    );
  };
};

export default componentHOC;
