import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { defaultTo } from 'ramda';

import useAppNotifications from 'hooks/useAppNotifications';
import { getProjectFieldsConfiguration, updateProjectFieldsConfiguration } from 'store/configuration';
import {
  projectFieldsInheritConfiguration,
  projectFieldsRequiredConfiguration,
  updateProjectFieldsConfigurationError,
} from 'store/configuration/selectors';
import { getProjectsCustomFields } from 'store/customFields/selectors';

import useProjectFieldsLayoutConfiguration from './useProjectFieldsLayoutConfiguration';

const defaultAsEmptyObject = defaultTo({});

/**
 * @function useProjectFieldsConfiguration
 *
 * Use project fields configuration
 *
 * Includes project fields layout and project fields inherit
 *
 * @param  {String} layer
 * @param  {Boolean} oldLightboxActive
 * @return {Object}
 */
const useProjectFieldsConfiguration = (layer, oldLightboxActive) => {
  const dispatch = useDispatch();

  const { addError } = useAppNotifications();

  const customFields = useSelector(getProjectsCustomFields);

  const projectsFieldsInherit = useSelector(state => projectFieldsInheritConfiguration(state, layer));
  const projectsFieldsRequired = useSelector(state => projectFieldsRequiredConfiguration(state, layer));
  const { projectsFieldsLayout, isProjectsFieldsLayoutUninitializedOnLayer } = useProjectFieldsLayoutConfiguration(
    layer,
    customFields,
    oldLightboxActive,
  );

  const updateError = useSelector(state => updateProjectFieldsConfigurationError(state));

  const isProjectsFieldsInheritUninitializedOnLayer = projectsFieldsInherit === undefined;
  const isProjectsFieldsRequiredUninitializedOnLayer = projectsFieldsRequired === undefined;
  const isProjectsFieldsConfigurationUninitializedOnLayer =
    isProjectsFieldsLayoutUninitializedOnLayer ||
    isProjectsFieldsInheritUninitializedOnLayer ||
    isProjectsFieldsRequiredUninitializedOnLayer;

  useEffect(() => {
    if (layer && isProjectsFieldsConfigurationUninitializedOnLayer) {
      dispatch(getProjectFieldsConfiguration(layer));
    }
  }, [layer, isProjectsFieldsConfigurationUninitializedOnLayer]);

  useEffect(() => {
    if (updateError) {
      addError({ id: 'error-saving-project-fields-layout', message: 'Failed to save configuration. Please try again.' });
    }
  }, [updateError]);

  const updateConfiguration = (layer, data) => dispatch(updateProjectFieldsConfiguration(layer, data));

  return {
    projectsFieldsLayout: defaultAsEmptyObject(projectsFieldsLayout),
    projectsFieldsInherit: defaultAsEmptyObject(projectsFieldsInherit),
    projectsFieldsRequired: defaultAsEmptyObject(projectsFieldsRequired),
    updateConfiguration,
  };
};

export default useProjectFieldsConfiguration;
