import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { shareViewOnIntegration as shareViewOnIntegrationAction } from 'store/userViews/sharedViews.thunks';
import { isSharingViewOnIntegration } from 'store/userViews/selectors';
import { createUserViewRecurringNotification } from 'features/UserViewRecurringNotification/store';
import { SEND_ONCE } from 'features/EstimateByPoints/constants';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

export default Component => {
  return props => {
    const { integrationType, handleCloseDialog, view, viewUrl } = props;
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();
    const { canCreate } = usePermissions();
    const canCreateRecurringNotifications = canCreate(PERMISSION_FEATURES.userViewRecurringNotifications, {
      view,
      integrationType,
    });

    const isSharingOperationInProgress = useSelector(isSharingViewOnIntegration);

    const shareViewHandler = ({ channel, periodicity }) => {
      if (!channel) {
        return;
      }
      if (canCreateRecurringNotifications && periodicity.value !== SEND_ONCE) {
        dispatch(createUserViewRecurringNotification(integrationType, view, viewUrl, channel, message, periodicity.value));
        handleCloseDialog();
        return;
      }
      dispatch(shareViewOnIntegrationAction(integrationType, view, viewUrl, channel, message));
      handleCloseDialog();
    };

    return (
      <Component
        {...props}
        integrationType={integrationType}
        message={message}
        setMessage={setMessage}
        onSend={shareViewHandler}
        canCreateRecurringNotifications={canCreateRecurringNotifications}
        isLoading={isSharingOperationInProgress}
      />
    );
  };
};
