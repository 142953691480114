import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { KEY_RESULT_LEVEL_METADATA } from 'constants/common';
import { topics } from 'constants/topics';

import { checkRolePermission } from 'containers/UserPermission/utils';

import useSubscribeNewData from 'hooks/useSubscribeNewData';
import { ProjectsListLightboxProvider } from 'hooks/useProjectsListLightbox';

import { OBJECTIVES } from 'store/grids/constants';
import { getCurrentUser } from 'store/login/selectors';
import { getOrganization, getOrganizationIntegrations } from 'store/organization/selectors';
import { updateSystemFieldsName as updateSystemFieldsNameAction } from 'store/organization';
import { getUsers } from 'store/users/selectors';

const TOPICS_TO_SUBSCRIBE = [topics.KEY_RESULTS, topics.OBJECTIVES, topics.METRICS, topics.USER_FILTERS, topics.USER_VIEWS];

const UPDATE_METADATA_PERMISSION = 'UPDATE_METADATA';

export default Component => {
  return ({ hideArchivedItems, showActionBar, isGoalMode, customHeight, forceVisibleFields }) => {
    const dispatch = useDispatch();

    const currentUser = useSelector(getCurrentUser);

    const organization = useSelector(getOrganization);

    const orgIntegrations = useSelector(getOrganizationIntegrations);

    const allowActions = checkRolePermission(UPDATE_METADATA_PERMISSION, currentUser, organization, OBJECTIVES);

    const users = useSelector(getUsers);

    const hasKeyResults = useMemo(() => organization.has_key_results);

    const hasKeyResults2 = useMemo(() => organization.has_key_results_2);

    const updateSystemFieldsName = useCallback(fieldRename => dispatch(updateSystemFieldsNameAction(fieldRename)), [dispatch]);

    useSubscribeNewData(TOPICS_TO_SUBSCRIBE);

    const allowAddKeyResult1 = useSelector(state => {
      return checkRolePermission(
        UPDATE_METADATA_PERMISSION,
        state.login.currentUser,
        organization,
        KEY_RESULT_LEVEL_METADATA.keyResult,
      );
    });

    const allowAddKeyResult2 = useSelector(state => {
      return checkRolePermission(
        UPDATE_METADATA_PERMISSION,
        state.login.currentUser,
        organization,
        KEY_RESULT_LEVEL_METADATA.keyResult2,
      );
    });

    return (
      <ProjectsListLightboxProvider>
        <Component
          allowActions={allowActions}
          organization={organization}
          systemFields={organization.system_fields_name}
          updateSystemFieldsName={updateSystemFieldsName}
          users={users}
          orgIntegrations={orgIntegrations}
          showIntegrationColumn={organization.has_miro && !isGoalMode}
          hideMetadataRoadmaps={!organization.enable_metadata_roadmaps}
          hasKeyResults={hasKeyResults}
          hasKeyResults2={hasKeyResults2}
          allowAddKeyResult1={allowAddKeyResult1}
          allowAddKeyResult2={allowAddKeyResult2}
          hideArchivedItems={hideArchivedItems}
          showActionBar={showActionBar}
          isGoalMode={isGoalMode}
          customHeight={customHeight}
          forceVisibleFields={forceVisibleFields}
        />
      </ProjectsListLightboxProvider>
    );
  };
};
