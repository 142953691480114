import reduceReducers from 'reduce-reducers';
import camelCase from 'lodash/camelCase';
import { defaultTo } from 'ramda';

import { BULK_UPDATE_CUSTOMER_REQUESTS_FULFILLED, MERGE_CUSTOMER_REQUESTS_FULFILLED } from 'store/customerRequests';
import { getThunksInitialStateAndReducers } from 'utils/store/thunk';
import updateRows from 'store/utils/updateRows';
import { LOCATION_CHANGE } from 'store/app/types';

import {
  FETCH_CUSTOMER_REQUESTS_FOR_CLUSTER,
  FETCH_CUSTOMER_REQUEST_CLUSTERS_FOR_ANALYSE_CHART,
  SET_CUSTOMER_REQUESTS_ANALYSE_BUBBLE_SIZE_BY,
  SET_CUSTOMER_REQUESTS_ANALYSE_CLUSTER_BY,
} from './types';
import { BUBBLE_SIZE_BY_OPTIONS, CLUSTER_BY_TAGS_OPTION } from '../utils';

const defaultAsEmptyArray = defaultTo([]);

const { initialState: operationsInitialState, reducers: operationsReducers } = getThunksInitialStateAndReducers([
  FETCH_CUSTOMER_REQUEST_CLUSTERS_FOR_ANALYSE_CHART,
  FETCH_CUSTOMER_REQUESTS_FOR_CLUSTER,
]);

export const initialState = {
  ...operationsInitialState,
  selectedAnalyseClusterBy: CLUSTER_BY_TAGS_OPTION,
  selectedAnalyzeBubbleSizeBy: BUBBLE_SIZE_BY_OPTIONS[0],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      const operationKeyForFetchClustersThunk = camelCase(FETCH_CUSTOMER_REQUEST_CLUSTERS_FOR_ANALYSE_CHART);

      return {
        ...state,
        operations: {
          ...state.operations,
          [operationKeyForFetchClustersThunk]: {
            ...state.operations[operationKeyForFetchClustersThunk],
            data: [],
          },
        },
      };
    case SET_CUSTOMER_REQUESTS_ANALYSE_CLUSTER_BY:
      return {
        ...state,
        selectedAnalyseClusterBy: action.clusterBy,
      };
    case SET_CUSTOMER_REQUESTS_ANALYSE_BUBBLE_SIZE_BY:
      return {
        ...state,
        selectedAnalyzeBubbleSizeBy: action.bubbleSizeBy,
      };
    case MERGE_CUSTOMER_REQUESTS_FULFILLED: {
      const { payload } = action;

      if (!state.operations[camelCase(FETCH_CUSTOMER_REQUESTS_FOR_CLUSTER)]) {
        return state;
      }

      const operationData = defaultAsEmptyArray(state.operations[camelCase(FETCH_CUSTOMER_REQUESTS_FOR_CLUSTER)]?.data);
      const dataWithoutMergedItems = operationData.filter(
        request => !defaultAsEmptyArray(payload).some(item => +item === +request.id),
      );
      const operationKey = camelCase(FETCH_CUSTOMER_REQUESTS_FOR_CLUSTER);

      return {
        ...state,
        operations: {
          ...state.operations,
          [operationKey]: {
            ...state.operations[operationKey],
            data: dataWithoutMergedItems,
          },
        },
      };
    }
    case BULK_UPDATE_CUSTOMER_REQUESTS_FULFILLED: {
      if (!state.operations[camelCase(FETCH_CUSTOMER_REQUESTS_FOR_CLUSTER)]) {
        return state;
      }

      const operationData = defaultAsEmptyArray(state.operations[camelCase(FETCH_CUSTOMER_REQUESTS_FOR_CLUSTER)]?.data);

      const dataWithUpdatedItems = updateRows(operationData, action.payload);
      const operationKey = camelCase(FETCH_CUSTOMER_REQUESTS_FOR_CLUSTER);

      return {
        ...state,
        operations: {
          ...state.operations,
          [operationKey]: {
            ...state.operations[operationKey],
            data: dataWithUpdatedItems,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default reduceReducers(initialState, reducer, ...operationsReducers);
