import reduceReducers from 'reduce-reducers';

import { makeInitialState } from 'utils/store/entities';

import { estimatesEntitiesReducer } from '../estimates';
import { tasksEntitiesReducer } from '../tasks';
import { integrationsEntitiesReducer } from '../integrations';
import { dependenciesEntitiesReducer } from '../dependencies';
import { customerRequestsEntitiesReducer } from '../customerRequests';
import { projectsEntitiesReducer } from '../projects';
import { releaseCapacityAllocationEntitiesReducer } from '../releaseCapacityAllocation';

const initialState = {
  estimates: makeInitialState(),
  teams: makeInitialState(),
  skills: makeInitialState(),
  tasks: makeInitialState(),
  integrations: makeInitialState(),
  stories: makeInitialState(),
  dependencies: {
    // Project => Task Dependencies:
    projectTask: makeInitialState(),

    // Project => Estimate Dependencies:
    projectEstimate: makeInitialState(),

    // Project => Project Dependencies:
    projectProject: makeInitialState(),

    // Estimate => Estimate Dependencies:
    estimateEstimate: makeInitialState(),

    // Estimate => Project Dependencies:
    estimateProject: makeInitialState(),

    // Task => Task Dependencies:
    taskTask: makeInitialState(),

    // Task => Project Dependencies:
    taskProject: makeInitialState(),
  },
  customerRequests: makeInitialState(),
  projects: makeInitialState(),
  roadmaps: makeInitialState(),
  users: makeInitialState(),
  files: makeInitialState(),
  customers: makeInitialState(),
  tags: makeInitialState(),
  metrics: makeInitialState(),
  releaseCapacityAllocation: makeInitialState(),
};

const reducer = reduceReducers(
  initialState,
  estimatesEntitiesReducer,
  tasksEntitiesReducer,
  integrationsEntitiesReducer,
  dependenciesEntitiesReducer,
  customerRequestsEntitiesReducer,
  projectsEntitiesReducer,
  releaseCapacityAllocationEntitiesReducer,
);

export default reducer;
