import reduceReducers from 'reduce-reducers';

import { getThunksInitialStateAndReducers } from 'utils/store/thunk';

import {
  ADD_ORG_INTEGRATION,
  UPDATE_ORG_INTEGRATION,
  ADD_USER_INTEGRATION,
  DISABLE_INTEGRATION_WEBHOOKS,
  ENABLE_INTEGRATION_WEBHOOKS,
  REMOVE_ONE_STEP_ORG_INTEGRATION,
  FETCH_AVAILABLE_INTEGRATIONS,
  FETCH_AVAILABLE_INTEGRATIONS_FULFILLED,
  FETCH_ORG_INTEGRATION_FIELD_MAPPING_FULFILLED,
  UPDATE_ORG_INTEGRATION_FIELD_MAPPING_FULFILLED,
  FETCH_ORG_INTEGRATION_DRAGONBOAT_FIELDS,
  FETCH_ORG_INTEGRATION_DRAGONBOAT_FIELDS_FULFILLED,
} from './types';

const { initialState: operationsInitialState, reducers: operationsReducers } = getThunksInitialStateAndReducers([
  ADD_USER_INTEGRATION,
  ADD_ORG_INTEGRATION,
  UPDATE_ORG_INTEGRATION,
  REMOVE_ONE_STEP_ORG_INTEGRATION,
  ENABLE_INTEGRATION_WEBHOOKS,
  DISABLE_INTEGRATION_WEBHOOKS,
  FETCH_AVAILABLE_INTEGRATIONS,
  FETCH_ORG_INTEGRATION_DRAGONBOAT_FIELDS,
]);

export const initialState = {
  ...operationsInitialState,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AVAILABLE_INTEGRATIONS_FULFILLED:
      return {
        ...state,
        availableIntegrations: action?.payload || {},
      };
    case FETCH_ORG_INTEGRATION_FIELD_MAPPING_FULFILLED:
    case UPDATE_ORG_INTEGRATION_FIELD_MAPPING_FULFILLED:
      return {
        ...state,
        integrationFieldMappings: {
          ...(state.integrationFieldMappings || {}),
          [action.payload.orgIntegrationId]: action.payload.data,
        },
      };
    case FETCH_ORG_INTEGRATION_DRAGONBOAT_FIELDS_FULFILLED:
      return {
        ...state,
        dragonboatFields: {
          ...(state.dragonboatFields || {}),
          [action.payload.orgIntegrationId]: action.payload.data,
        },
      };
    default:
      return state;
  }
};

export default reduceReducers(initialState, reducer, ...operationsReducers);
