import localActionTypes from './actionTypes';

const resetLocalProjects = data => ({ type: localActionTypes.RESET_LOCAL_PROJECTS, payload: data });
const addLocalProject = newProject => ({ type: localActionTypes.ADD_LOCAL_PROJECT, payload: newProject });
const updateLocalProject = updatedProject => ({ type: localActionTypes.UPDATE_LOCAL_PROJECT, payload: updatedProject });
const updateRowOrderLocalProject = (sourceProject, prev, next) => ({
  type: localActionTypes.UPDATE_ROW_ORDER_LOCAL_PROJECT,
  payload: { sourceProject, prev, next },
});

export { resetLocalProjects, addLocalProject, updateRowOrderLocalProject, updateLocalProject };
