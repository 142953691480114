import isEmpty from 'lodash/isEmpty';
import max from 'lodash/max';

import { isItemIncludedInAnother } from './basic';

/**
 * Check if 2 items overlaps based on each visual information.
 * This may be used in projects, milestones or groups.
 *
 * @param {Object} item1 The first item to compare
 * @param {Object} item2 The second item to compare
 * @param {Number} delta An offset to increase the chance of overlapping (used for the group margin)
 *
 * @return {Boolean} If the 2 items are overlapping
 * */
const areItemsOverlapping = (item1, item2, delta = 0) => {
  const obj1Info = item1.info;
  const obj2Info = item2.info;

  const isIncluded = isItemIncludedInAnother(obj1Info, obj2Info, delta);

  const rightSideOverlaps =
    obj1Info.left - delta > obj2Info.left && obj1Info.left - delta < obj2Info.right && obj1Info.right + delta >= obj2Info.right;

  const leftSideOverlaps = obj1Info.right + delta > obj2Info.left && obj1Info.left - delta <= obj2Info.left;

  return isIncluded || rightSideOverlaps || leftSideOverlaps;
};

/**
 * Calculates the number of rows based on the open groups.
 *
 * @param {Array} groupRows The list of rows from the inside grouping
 * @param {Function} isGroupOpen The helper to check if the group is open
 *
 * @return {Number} number of rows to take into account based in the open groups
 * */
const getOpenedGroupsRowsCount = (groupRows, isGroupOpen) => {
  const openedGroupsRowsCount = groupRows.reduce((acc, rowGroup) => {
    const openedGroups = rowGroup.filter(group => isGroupOpen(group.key));

    if (isEmpty(openedGroups)) {
      return acc;
    }

    const openedGroupsRows = max(openedGroups.map(group => group.rows.length));

    return acc + openedGroupsRows;
  }, 0);

  return groupRows.length + openedGroupsRowsCount || 1;
};

/**
 * Generate a list of rows avoid any item overlapping.
 *
 * @param {Array} items The source list of items to be used for the rows generation
 * @param {Number} delta An offset to increase the chance of overlapping (used for the group margin)
 *
 * @return {Array} The resulting rows from the items list
 * */
const generateRows = (items = [], delta = 0) => {
  const rows = [];
  const itemIds = [];

  items.forEach((item, itemIndex) => {
    if (!itemIds.includes(item.id)) {
      const row = [];

      row.push(item);
      itemIds.push(item.id);

      for (let i = itemIndex + 1; i < items.length; i++) {
        if (!itemIds.includes(items[i].id)) {
          const isOverlapped = row.some(obj2 => {
            const obj1 = items[i];

            return areItemsOverlapping(obj1, obj2, delta);
          });

          if (!isOverlapped) {
            row.push(items[i]);
            itemIds.push(items[i].id);
          }
        }
      }

      if (row.length) {
        rows.push(row);
        itemIds.concat(row.map(item => item.id));
      }
    }
  });

  return rows;
};

export { generateRows, getOpenedGroupsRowsCount };
