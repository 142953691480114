import {
  UPDATE_SNAPSHOT_STATE,
  UPDATE_SNAPSHOT_VIEW_MODE,
  UPDATE_SNAPSHOT_CLUSTERS_VISIBILITY,
  UPDATE_SNAPSHOT_VIEW_MODE_TIME_PERIOD,
} from './types';

export const updateState = (state, makesActiveViewDirty = true) => {
  return {
    payload: state,
    type: UPDATE_SNAPSHOT_STATE,
    meta: { makesActiveViewDirty },
  };
};

export const updateViewMode = state => {
  return {
    payload: state,
    type: UPDATE_SNAPSHOT_VIEW_MODE,
    meta: { makesActiveViewDirty: true },
  };
};

export const updateClustersVisibility = state => {
  return {
    payload: state,
    type: UPDATE_SNAPSHOT_CLUSTERS_VISIBILITY,
  };
};

export const updateMetricViewTimePeriod = state => {
  return {
    payload: state,
    type: UPDATE_SNAPSHOT_VIEW_MODE_TIME_PERIOD,
    meta: { makesActiveViewDirty: true },
  };
};

export const snapshotActions = {
  updateState,
};
