import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { ROADMAP_PAGE } from 'constants/filters';

import LoadingOverlay from 'design-system/atoms/LoadingOverlay/index';

import Timeline from 'design-system/organisms/Timeline';
import { isSnapToPredictedEnd } from 'design-system/organisms/Timeline/New/helpers';

import PlanningStageWarningDialog from 'components/PlanningStageWarningDialog';

import useTimelines from 'hooks/useTimelines';
import useLoadProjectsForTransactionPages from 'hooks/projects/useLoadProjectsForTransactionPages';

import { makeCustomGroupTitleRenderer, isMilestone } from './helpers';

import useSwimlaneAuth from './hooks/useSwimlaneAuth';
import useSwimlaneProjects from './hooks/useSwimlaneProjects';
import useSwimlaneDataTree from './hooks/useSwimlaneDataTree';
import useSwimlaneHandlers from './hooks/useSwimlaneHandlers';

import ControlsBar from './ControlsBar';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
/**
 * This page component should only be responsible for:
 * - Preparing the data to the Timeline;
 * - Passing configuration, options to the Timeline;
 * - Passing the handlers to the Timeline.
 *
 * Should not address any specific UI concern, these should be accounted in the agnostic Timeline component.
 * * */
const Swimlane = ({ className, viewsDropdownTabs }) => {
  const dispatch = useDispatch();

  // Get the timeline configuration for the swimlane
  const {
    lsState: {
      displayMilestone,
      displayMilestoneOn,
      slotWidth,
      showLegend,
      showTooltip,
      showTextOverflow,
      snapToGridOn,
      hideEmptyLane,
      isDialogVisible,
    },
    groupWidths,
    groupTitles,
    zoomMode,
    confirmPlanningStageWarning,
  } = useTimelines(ROADMAP_PAGE);

  // Load the projects
  useLoadProjectsForTransactionPages(ROADMAP_PAGE, null, null, { includeMilestones: displayMilestone });

  // Get user's information
  const { isReadOnly, isAnonymousUser } = useSwimlaneAuth();

  const { canView } = usePermissions();

  const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

  // Get the projects and all related information
  const { projects, hasGlobalLoading, isProjectLoading, hasProjectUpdateError } = useSwimlaneProjects();

  // Generate Timeline expected format data from projects
  const { timelineData, groupsData } = useSwimlaneDataTree(projects);

  // Generate the handlers for the timeline
  const { handleDrag, handleResize, handleRowClick, handleItemDoubleClick, handleGroupHeaderResize } = useSwimlaneHandlers({
    timelineData,
    groupsData,
  });

  const isResizable = !snapToGridOn;
  const isDraggable = !isSnapToPredictedEnd(snapToGridOn) && !isAnonymousUser;
  const groupTitleRenderer = makeCustomGroupTitleRenderer(dispatch);

  return (
    <Wrapper className={className}>
      {hasGlobalLoading && <LoadingOverlay />}
      <PlanningStageWarningDialog isOpen={isDialogVisible} onConfirm={confirmPlanningStageWarning} />
      {shouldDisplayControlsBar && <ControlsBar viewsDropdownTabs={viewsDropdownTabs} />}
      <StyledTimeline
        // for style purposes
        isReadOnly={isReadOnly}
        showLegend={showLegend}
        // data
        isLoading={isProjectLoading}
        data={timelineData}
        groupTitles={groupTitles}
        shouldResetData={hasProjectUpdateError}
        isMilestoneItemChecker={isMilestone}
        // options
        groupWidths={groupWidths}
        zoomMode={zoomMode}
        slotWidth={slotWidth}
        displayMilestone={displayMilestone}
        displayMilestoneOn={displayMilestoneOn}
        showTooltip={showTooltip}
        showTextOverflow={showTextOverflow}
        snapToGridOn={snapToGridOn}
        resizable={isResizable}
        draggable={isDraggable}
        hideEmptyLane={hideEmptyLane}
        groupTitleRenderer={groupTitleRenderer}
        // handlers
        onResizeGroupHeader={handleGroupHeaderResize}
        onDoubleClick={handleItemDoubleClick}
        onRowClick={handleRowClick}
        onDrag={handleDrag}
        onResize={handleResize}
      />
    </Wrapper>
  );
};

export default Swimlane;

const StyledTimeline = styled(Timeline)`
  margin: ${({ isReadOnly }) => (isReadOnly ? '116px 44px 0' : '0 44px')};
  height: ${({ showLegend }) => ` calc(100vh - (140px + ${showLegend ? '54px' : '0px'}))`};
`;

const Wrapper = styled.div``;
