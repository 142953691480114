import isString from 'lodash/isString';

import { ESTIMATES, IDEAS } from 'store/grids/constants';
import { PAGEID_TO_PATH } from 'constants/filters/pages';

const isForecastPage = viewType => viewType === ESTIMATES;
const isIdeasPage = viewType => viewType === IDEAS;

const urlMatch = new RegExp(/^([^/]+\/[^/]+)/);

const parsePathname = pathname => urlMatch.exec(pathname)?.[1] ?? pathname;

const getPageIdFromPath = path => {
  const pathWithoutLeadingSlash = path.startsWith('/') ? path.slice(1) : path;
  const pathname = parsePathname(pathWithoutLeadingSlash);

  const pageId = Object.entries(PAGEID_TO_PATH || {}).reduce((found, [pageId, path]) => {
    if (isString(path)) return path === pathname;

    if (
      (path.main === pathname || path.readOnly === pathname || (path.alternatives || []).includes(pathname)) &&
      path.pathToPageId !== false
    ) {
      return pageId;
    }

    return found;
  }, null);

  return pageId || '';
};

const getPathFromPageId = (pageId, readOnly = false) => {
  const path = PAGEID_TO_PATH[pageId] || {};

  if (isString(path)) return path;

  return readOnly && path.readOnly ? path.readOnly : path.main;
};

export { isForecastPage, isIdeasPage, getPageIdFromPath, getPathFromPageId };
