import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  setupSlackIntegration as setupSlackIntegrationAction,
  updateSlackIntegration as updateSlackIntegrationAction,
  getSlackIntegrationChannels,
  testSlackConnection as testSlackConnectionReduxAction,
  removeTestSlackConnection as removeTestSlackConnectionReduxAction,
} from 'store/organization';

export default Component => {
  return props => {
    const { userCanUpdateOrgIntegration, userCanViewOrgIntegration, orgIntegration } = props;

    const [error, setError] = useState('');
    const testSlackConnectionAction = () => dispatch(testSlackConnectionReduxAction());
    const removeTestSlackConnection = () => dispatch(removeTestSlackConnectionReduxAction());

    const testSlackConnection = async () => {
      await testSlackConnectionAction();
    };

    const slackTestStatus = useSelector(state => state.organization.slackTest);
    const systemFields = useSelector(state => state.organization.organization.system_fields_name);

    const clickNext = async () => {
      try {
        const response = await axios.post('/api/integrations/slack/request');

        window.open(response.data.uri, 'Slack_Authentication', 'height=600,width=600');
      } catch (err) {
        setError(err.response.data.message || 'Invalid credentials');
      }
    };

    const dispatch = useDispatch();
    const setupSlackIntegration = (token, user, props) => dispatch(setupSlackIntegrationAction(token, user, props));
    const updateSlackIntegration = data => dispatch(updateSlackIntegrationAction(data));

    useEffect(() => {
      if (orgIntegration) {
        dispatch(getSlackIntegrationChannels());
      }
    }, [orgIntegration, dispatch]);

    useEffect(() => {
      if (slackTestStatus) {
        if (slackTestStatus.success) {
          toast('Slack test successful');
          removeTestSlackConnection();
        }
      }
    }, [slackTestStatus]);

    const receiveMessage = event => {
      let obj;

      try {
        if (typeof event.data === 'string') obj = JSON.parse(event.data);
        else if (typeof event.data === 'object') obj = event.data;
        else return;
      } catch (err) {
        return;
      }

      if (obj.integrationType === 'slack') {
        setupSlackIntegration(obj.code, obj.state);
      }
    };

    useEffect(() => {
      window.addEventListener('message', receiveMessage, false);
      return () => window.removeEventListener('message', receiveMessage);
    }, []);

    return (
      <Component
        error={error}
        clickNext={clickNext}
        orgIntegration={props.orgIntegration}
        removeIntegration={props.removeIntegration}
        updateSlackIntegration={updateSlackIntegration}
        systemFields={systemFields}
        testSlackConnection={testSlackConnection}
        userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
        userCanViewOrgIntegration={userCanViewOrgIntegration}
      />
    );
  };
};
