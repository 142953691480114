import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import Dialog from 'design-system/molecules/Dialog/index';
import FluidContainer from 'design-system/atoms/FluidContainer/index';
import FluidMultiSelectField from 'design-system/organisms/FluidMultiSelectField/index';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';


import Header from './Header';
import Actions from './Actions';
import { getUserName } from 'utils';
import userTypes from 'utils/UserTypeMapper';

import LoadableUsersMultiSelect from 'containers/LoadableMultiSelect/LoadableUsersMultiSelect';

const ROLES = ['Editor', 'Read Only'].map(label => userTypes.find(role => role.label === label));

export default React.memo(({ loading, isOpen, onClose, getSystemFieldName, formData, updateFieldData }) => {
  if (!isOpen) return '';
  const headerTitle = `Set ${getSystemFieldName('roadmap')} Permissions`;

  if (loading) {
    return (
      <LoadingOverlay>
        <PageLoading />
      </LoadingOverlay>
    );
  }

  return (
    <Dialog
      id="project-lightbox"
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      scroll="paper"
      fullWidth
      fullHeight
      header={<Header headerTitle={headerTitle} onClose={onClose} />}
      actions={<Actions visible={!loading} onSave={onClose} />}
    >
      <form autoComplete="off">
        <Grid container xs={12} justify="space-around">
          <FluidContainer size={12}>
            {ROLES.map(role => (
              <FluidMultiSelectField
                key={role.value}
                value={formData[role.value].map(u => ({ name: getUserName(u.user), ...u.user }))}
                label={role.label}
                placeholder={`Select multiple ${role.label}`}
                optionsMapper={{ label: 'name', value: 'id' }}
                onChange={updateFieldData(role.value)}
                inputComponent={LoadableUsersMultiSelect}
                mapValueToSave={v => ({ user: v, role_id: role.value, user_id: v.id })}
                inputProps={{
                  excludeAdminUsers: true,
                  isOptionSelected: (item, value) => {
                    return value.some(v => parseInt(v.value, 10) === item.value);
                  },
                }}
              />
            ))}
          </FluidContainer>
        </Grid>
      </form>
    </Dialog>
  );
});

const LoadingOverlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #3333338c;
  z-index: 10000;

  > div {
    color: #fff;
  }
`;
