import { schema, normalize } from 'normalizr';

import { add, remove } from 'utils/store/entities';

import {
  CREATE_PROJECT_ESTIMATE_FULFILLED,
  UPDATE_PROJECT_ESTIMATE_FULFILLED,
  DELETE_PROJECT_ESTIMATE_FULFILLED,
  UPDATE_ESTIMATES_FULFILLED,
} from '../types';

const teamsEntity = new schema.Entity('teams');
const skillsEntity = new schema.Entity('skills');
const estimatesEntity = new schema.Entity('estimates', {
  team: teamsEntity,
  skill: skillsEntity,
});

const mapEstimateEntitiesToState = (state, entities) => ({
  ...state,
  estimates: add(state.estimates, entities.estimates),
});

const addEstimateToState = (state, estimate) => {
  const { entities } = normalize(estimate, estimatesEntity);

  return mapEstimateEntitiesToState(state, entities);
};

const bulkAddEstimatesToState = (state, estimates) => {
  const { entities } = normalize(estimates, [estimatesEntity]);

  return mapEstimateEntitiesToState(state, entities);
};

const removeEstimateFromState = (state, estimateId) => {
  return {
    ...state,
    estimates: remove(state.estimates, [estimateId]),
  };
};

const estimatesEntitiesReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PROJECT_ESTIMATE_FULFILLED: {
      if (!action.payload?.data?.estimate) {
        return state;
      }

      return addEstimateToState(state, action.payload.data.estimate);
    }

    case UPDATE_PROJECT_ESTIMATE_FULFILLED: {
      if (Array.isArray(action.payload)) {
        // Currently the realtime event is returning an array but the projects reducer
        // is not doing anything with that information so the entities will not be updated
        return state;
      }

      return addEstimateToState(state, action.payload?.data);
    }

    case DELETE_PROJECT_ESTIMATE_FULFILLED: {
      return removeEstimateFromState(state, action.payload.estimate.id);
    }

    case UPDATE_ESTIMATES_FULFILLED: {
      return bulkAddEstimatesToState(state, action.payload.estimates);
    }

    default: {
      return state;
    }
  }
};

export { estimatesEntitiesReducer };
