import React from 'react';
import styled from 'styled-components';
import { defaultTo } from 'ramda';

import Checkbox from 'design-system/atoms/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Segment from 'design-system/atoms/Segment';

import { DELIVERY_LINKED_ISSUES_SUMMARY, DELIVERY_PROGRESS_SUMMARY } from '../../helpers/templates';
import ChartWidget from '../../ChartWidget';

const defaultToEmptyObject = defaultTo({});

const ReadOnlyWidget = ({ widget }) => {
  return (
    <Segment bordered>
      <ChartWidget widget={widget} inEditMode={false} checkAllowEdit={() => false} />
    </Segment>
  );
};

const SelectableWidget = ({ label, widget, isSelected, onSelected }) => {
  return (
    <div>
      <FormControlLabel
        control={<Checkbox color="primary" checked={isSelected} onChange={evt => onSelected(evt.target.checked)} />}
        label={label}
      />

      <ReadOnlyWidget widget={widget} />
    </div>
  );
};

const SummaryChartsConfiguration = ({ selectedChartOptions, handleSelectChartOption }) => {
  const selectedOptions = defaultToEmptyObject(selectedChartOptions);
  const isProgressSummarySelected = selectedOptions[DELIVERY_PROGRESS_SUMMARY.id] || false;
  const isLinkedIssuesSummarySelected = selectedOptions[DELIVERY_LINKED_ISSUES_SUMMARY.id] || false;

  return (
    <Container>
      <SelectableWidget
        label="Delivery Summary"
        widget={DELIVERY_PROGRESS_SUMMARY}
        isSelected={isProgressSummarySelected}
        onSelected={checked => handleSelectChartOption(DELIVERY_PROGRESS_SUMMARY.id, checked)}
      />
      <SelectableWidget
        label="Summary of Integrated Items"
        widget={DELIVERY_LINKED_ISSUES_SUMMARY}
        isSelected={isLinkedIssuesSummarySelected}
        onSelected={checked => handleSelectChartOption(DELIVERY_LINKED_ISSUES_SUMMARY.id, checked)}
      />
    </Container>
  );
};

const Container = styled.div`
  min-width: 950px;
  display: flex;
  flex-direction: column;
`;

export default SummaryChartsConfiguration;
