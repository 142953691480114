import styled from 'styled-components';

export default styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100%;
  width: ${props => (props.isResizable ? 16 : 1)}px;

  :after {
    content: '';
    position: absolute;
    left: 100%;
    top: 12px;
    transform: translateX(-100%);

    width: 1px;
    height: 24px;
  }
`;
