import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export default styled(Typography)`
  &&&& {
    font-weight: 500;
    font-size: ${({ theme }) => `${theme.typography.fontSizeRem}rem`};
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;
  }
`;
