// Operations
import { createSelector } from 'reselect';
import { defaultTo } from 'ramda';

import { getData, getError, isLoading, isUninitialized } from 'utils/store/thunk';
import {
  FETCH_CYCLE_DELIVERABLES,
  UPSERT_PROJECT_DELIVERABLE,
  DELETE_PROJECT_DELIVERABLE,
  CREATE_CYCLE,
  FETCH_PROJECT_DELIVERABLE,
} from './types';

import { CYCLE_DELIVERABLE_LEVEL } from 'constants/common';
import { makeFilterByLevel } from 'utils/index';
import { getGridConfigValue } from 'store/grids/selectors';
import { PDLC } from 'store/grids';

const EMPTY_OBJECT = {};
const defaultToEmptyObject = defaultTo(EMPTY_OBJECT);

const getAllCycleDeliverables = state => {
  return state.cycleDeliverables.cycleDeliverables;
};

const getCycleDeliverablesOperations = state => {
  return state.cycleDeliverables.operations;
};

const getProjectsDeliverablesState = state => {
  return state.cycleDeliverables.projectsDeliverables;
};

const getAllProjectDeliverableDetails = state => {
  return state.cycleDeliverables.projectDeliverableDetails;
};

// Cycle Deliverables operations
const isCycleDeliverablesLoading = createSelector(getCycleDeliverablesOperations, state =>
  isLoading(state, FETCH_CYCLE_DELIVERABLES),
);

const getCycleDeliverablesData = createSelector(getCycleDeliverablesOperations, state =>
  getData(state, FETCH_CYCLE_DELIVERABLES),
);

const getCycleDeliverablesError = createSelector(getCycleDeliverablesOperations, state =>
  getError(state, FETCH_CYCLE_DELIVERABLES),
);

const isCycleDeliverablesUninitialized = createSelector(getCycleDeliverablesOperations, state =>
  isUninitialized(state, FETCH_CYCLE_DELIVERABLES),
);

const isUpsertProjectDeliverableLoading = createSelector(getCycleDeliverablesOperations, state =>
  isLoading(state, UPSERT_PROJECT_DELIVERABLE),
);

const isDeleteProjectDeliverableLoading = createSelector(getCycleDeliverablesOperations, state =>
  isLoading(state, DELETE_PROJECT_DELIVERABLE),
);

const isSomeUpdateOnProjectsDeliverablesOcurring = createSelector(
  isUpsertProjectDeliverableLoading,
  isDeleteProjectDeliverableLoading,
  (upsertLoading, deleteLoading) => {
    return upsertLoading || deleteLoading;
  },
);

const getAllCycles = createSelector(getAllCycleDeliverables, state =>
  state.filter(makeFilterByLevel(CYCLE_DELIVERABLE_LEVEL.cycle)),
);

const getAllDeliverables = createSelector(getAllCycleDeliverables, state =>
  state.filter(makeFilterByLevel(CYCLE_DELIVERABLE_LEVEL.deliverable)),
);

const getProjectsDeliverables = createSelector(getProjectsDeliverablesState, projectsDeliverables => projectsDeliverables);

// Create Cycle Deliverables operations
const isCreateCycleLoading = createSelector(getCycleDeliverablesOperations, state => isLoading(state, CREATE_CYCLE));

const getCreateCycleData = createSelector(getCycleDeliverablesOperations, state => getData(state, CREATE_CYCLE));

const getCreateCycleError = createSelector(getCycleDeliverablesOperations, state => getError(state, CREATE_CYCLE));

const isCreateCycleUninitialized = createSelector(getCycleDeliverablesOperations, state => isUninitialized(state, CREATE_CYCLE));

const getCycleDeliverableById = createSelector(
  [state => getAllCycleDeliverables(state), (state, id) => id],
  (cycleDeliverables, id) => cycleDeliverables.find(cd => cd.id === id),
);

const isfetchProjectDeliverableDetailsLoading = createSelector(getCycleDeliverablesOperations, state =>
  isLoading(state, FETCH_PROJECT_DELIVERABLE),
);

const getProjectDeliverableDetails = createSelector(
  [
    state => getAllProjectDeliverableDetails(state),
    (_, projectId) => projectId,
    (_, __, cycleDeliverableId) => cycleDeliverableId,
    state => isfetchProjectDeliverableDetailsLoading(state),
  ],
  (projectDeliverableDetails, projectId, cycleDeliverableId, isLoadingProjectDeliverable) => {
    if (isLoadingProjectDeliverable) {
      return;
    }

    const allProjectDeliverableDetails = defaultToEmptyObject(projectDeliverableDetails[projectId]);

    return allProjectDeliverableDetails[cycleDeliverableId];
  },
);

/**
 * From a saved status selection only return those that still exists on a deliverable. Deleted status will be discarded
 */
const getSelectedDeliverableStatusPDLCGrid = createSelector(
  [state => getGridConfigValue(state, PDLC, 'selectedDeliverableStatus', {}), getAllDeliverables],
  (selectedDeliverableStatus, deliverables) => {
    return Object.entries(selectedDeliverableStatus).reduce((acc, [selectedDeliverableId, selectedStatuses]) => {
      const currentDeliverable = deliverables.find(deliverable => deliverable?.id === +selectedDeliverableId);

      if (!currentDeliverable) return acc;

      const existingStatus = Object.keys(currentDeliverable?.status || {});
      const selectedExistingStatus = existingStatus.filter(status => selectedStatuses.includes(status));

      if (selectedExistingStatus.length) {
        return {
          ...acc,
          [selectedDeliverableId]: selectedExistingStatus,
        };
      }
      return acc;
    }, EMPTY_OBJECT);
  },
);

export {
  getCycleDeliverableById,
  isCycleDeliverablesLoading,
  getCycleDeliverablesData,
  getCycleDeliverablesError,
  isCycleDeliverablesUninitialized,
  getAllCycles,
  getAllDeliverables,
  getAllCycleDeliverables,
  getProjectsDeliverables,
  isUpsertProjectDeliverableLoading,
  isDeleteProjectDeliverableLoading,
  isSomeUpdateOnProjectsDeliverablesOcurring,
  // Create cycle
  isCreateCycleLoading,
  getCreateCycleData,
  getCreateCycleError,
  isCreateCycleUninitialized,
  // get project deliverable details
  getProjectDeliverableDetails,
  isfetchProjectDeliverableDetailsLoading,
  // Local filter PDLC
  getSelectedDeliverableStatusPDLCGrid,
};
