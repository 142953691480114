import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import { makeSelectFilteredProjectsByRoadmapForPage } from 'store/filters/selectors';
import { selectFilteredProjectsForPage } from 'store/projects/selectors';

import useLoadProjects from 'hooks/projects/useLoadProjects';

import { GLOBAL_FILTER } from 'constants/filters';
import { GANTT_TIMELINE_PROJECTS_LIMIT } from 'constants/snapshot';

export default (pageId, groupedByData, lazyLoad = false, lazyLoadProjectsFilter = {}, isGoalMode = false) => {
  const validLayers = [IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER];

  const projectsSelector = makeSelectFilteredProjectsByRoadmapForPage(selectFilteredProjectsForPage, validLayers);
  const getFilteredProjects = useRef(projectsSelector);

  const [ideas, initiatives, bets] = useSelector(state => [
    getFilteredProjects.current(state, GLOBAL_FILTER, true, IDEA_LAYER),
    getFilteredProjects.current(state, GLOBAL_FILTER, true, INITIATIVE_LAYER),
    getFilteredProjects.current(state, GLOBAL_FILTER, true, BET_LAYER),
  ]);

  if (!lazyLoad) {
    return {
      ideas,
      initiatives,
      bets,
      projectsForGroup: groupedByData.ideas,
    };
  }

  const [loadedProjects] = useLoadProjects(
    pageId,
    null,
    null,
    { ...lazyLoadProjectsFilter, includeMilestones: true },
    {
      from: 0,
      to: GANTT_TIMELINE_PROJECTS_LIMIT,
    },
    true,
    isGoalMode,
  );

  return {
    ideas: loadedProjects ? ideas : [],
    initiatives: loadedProjects ? initiatives : [],
    bets: loadedProjects ? bets : [],
    projectsForGroup: loadedProjects ? [...ideas, ...initiatives, ...bets] : [],
    isLoaded: loadedProjects,
  };
};
