import React, { Component } from 'react';
import Select from 'react-select/creatable';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import ListSubheader from '@material-ui/core/ListSubheader';
import styled, { css } from 'styled-components';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { pluck } from 'ramda';

import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';


import OrderableMultiValue from './OrderableMultiValue';

const getLabels = pluck('label');

const MAX_ROWS_NUM = 3;

const menuItemStyles = css`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const orderDragged = (inputArray, dragIndex, hoverIndex) => {
  const dragedValue = inputArray[dragIndex];
  const ordered = [...inputArray];

  ordered.splice(dragIndex, 1);
  ordered.splice(hoverIndex, 0, dragedValue);
  return ordered;
};

const NoOptionsMessage = props => {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const InputComponent = ({ inputRef, ...props }) => {
  return <div ref={inputRef} {...props} />;
};

const Control = props => {
  const Component = (props.selectProps.components || {}).ControlComponent || TextField;

  const _onFocus = () => props.selectProps.setIsFocused(true);
  const _onBlur = () => props.selectProps.setIsFocused(false);

  return (
    <Component
      fullWidth
      onFocus={_onFocus}
      onBlur={_onBlur}
      InputProps={{
        inputComponent: InputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          // inputRef: props.innerRef,
          inputRef: props.selectProps.textFieldProps.inputRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
};

const Option = props => {
  const _isSelected = props.isFocused || props.isSelected;

  return (
    <TooltipOnOverflow text={props.children}>
      <StyledMenuItemDropdown
        buttonRef={props.innerRef}
        selected={_isSelected}
        component="div"
        customStyles={menuItemStyles}
        {...props.innerProps}
      >
        {props.children}
      </StyledMenuItemDropdown>
    </TooltipOnOverflow>
  );
};

const Placeholder = props => {
  // if this flag inlinePlaceholder is active will use placeholder on inline input
  if (props.selectProps.inlinePlaceholder) {
    return '';
  }

  return (
    <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const SingleValue = props => {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const ValueContainer = props => {
  const { value = [], classes, isFocused, withEllipsis = false } = props.selectProps;

  const valueToString = getLabels(value).join(', ');

  const shouldApplyEllipsis = withEllipsis && !isFocused && value.length >= MAX_ROWS_NUM;

  return shouldApplyEllipsis ? (
    <TooltipOnOverflow text={valueToString} vertical>
      <div className={classes.valueContainerWithEllipsis}>{props.children}</div>
    </TooltipOnOverflow>
  ) : (
    <div className={classes.valueContainer}>{props.children}</div>
  );
};

const Menu = props => {
  let toRender = null;

  if (props.selectProps.selectRef) {
    // this.input is the input ref as received from Autosuggest
    const inputCoords = props.selectProps.selectRef.getBoundingClientRect();

    const { theme } = props;

    const menuHeight = Math.min(300, props.options.length * 48);
    const shouldExpandBottom = inputCoords.bottom + menuHeight < document.documentElement.clientHeight;
    const style = {
      position: 'absolute',
      left: inputCoords.left + window.scrollX, // adding scrollX and scrollY to get the coords wrt document instead of viewport
      top: shouldExpandBottom ? inputCoords.top + 42 + window.scrollY : 'unset',
      // eslint-disable-next-line no-mixed-operators
      bottom: shouldExpandBottom ? 'unset' : document.documentElement.clientHeight - inputCoords.bottom + 42 + window.scrollY,
      maxHeight: '300px',
      overflow: 'auto',
      zIndex: theme.zIndex.gridPopover,
      backgroundColor: '#ffffff',
      borderRadius: '4px',
      boxShadow: '0 4px 16px 0 rgba(0,0,0,.15), 0 1px 2px 0 rgba(0,0,0,.07), 0 0 1px 0 rgba(0,0,0,.2)',
      width: inputCoords.width,
      marginTop: inputCoords.height - 38,
    };

    toRender = (
      <StyledOptionsList {...props.innerProps} className="multiselect-portal" style={style}>
        {props.children}
      </StyledOptionsList>
    );
    return ReactDOM.createPortal(toRender, document.body);
  }

  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
};

const _serializeOptions = (options, optionsMapper) => {
  if (!options || !(options instanceof Array)) {
    return [];
  }

  return options.filter(Boolean).map(option => {
    if (typeof option === 'string') {
      return {
        label: option,
        value: option,
      };
    }

    if (optionsMapper) {
      return {
        label: option[optionsMapper.label],
        value: String(option[optionsMapper.value]),
      };
    }

    return option;
  });
};

const Input = props => {
  const { selectProps, cx, value, innerRef, isDisabled, inputClassName, ...innerProps } = props;

  return (
    <InputWrapper data-value={value || ''}>
      <ValueInput
        className={cx({ input: true }, inputClassName)}
        ref={innerRef}
        placeholder={selectProps.inlinePlaceholder ? selectProps.placeholder : ''}
        disabled={isDisabled}
        value={value}
        {...innerProps}
      />
    </InputWrapper>
  );
};

const defaultComponents = {
  Control,
  Menu,
  MultiValue: OrderableMultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  Input,
};

class MultiSelect extends Component {
  static defaultProps = {
    options: [],
    autoFocus: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      options: _serializeOptions(props.options, this.props.optionsMapper),
      value: _serializeOptions(props.value, this.props.optionsMapper),
      isFocused: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.setState({
        options: _serializeOptions(this.props.options, this.props.optionsMapper),
        value: _serializeOptions(this.props.value, this.props.optionsMapper),
      });
    }

    if (prevProps.value !== this.props.value) {
      this.setState({
        value: _serializeOptions(this.props.value, this.props.optionsMapper),
      });
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.autoFocus && this.ref) {
        this.ref.focus();
      }
    }, 0);
  }

  createOption = async inputValue => {
    const { onCreateOption, value } = this.props;

    if (onCreateOption) {
      const newOption = await onCreateOption(inputValue, value);

      if (newOption && newOption.id) {
        const newValue = [...this.state.value, ..._serializeOptions([newOption], this.props.optionsMapper)];
        const optionsWithCreatedOption = [...this.props.options, newOption];

        this.setState({
          options: _serializeOptions(optionsWithCreatedOption, this.props.optionsMapper),
          value: newValue,
        });

        this.props.onChange(
          newValue.map(option => option.value),
          optionsWithCreatedOption,
        );
      }
    }
  };

  getRef = ref => {
    this.ref = ref;
  };

  render() {
    const {
      className,
      classes,
      theme,
      placeholder,
      label,
      onChange,
      autoFocus,
      hideCreateOption,
      chipColor,
      error,
      defaultMenuIsOpen,
      components,
      draggable,
      hasGroups,
      groupOptions,
      optionsMapper = {},
      keepOpenedAfterSelect = true,
    } = this.props;

    const { value, options } = this.state;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
      indicatorSeparator: base => ({
        ...base,
        display: 'none',
      }),
      multiValue: (styles, { isDragging }) => ({
        ...styles,
        opacity: isDragging ? 0.5 : null,
      }),
    };

    const _setIsFocused = isFocused => this.setState({ isFocused });
    const SelectComponent = (components && components.selectComponent) || Select;

    const internalValue = value
      .map((v, i) => ({
        ...(options.find(opt => opt.value === v.value) || v),
        index: i,
      }))
      .filter(v => !!v);
    const _moveDraggedOption = ({ destination, source }) => {
      if (!destination || !source) return;

      const oderDraggedInternalValue = orderDragged(internalValue, source.index, destination.index);

      onChange(oderDraggedInternalValue.map(option => option.value));
    };

    const formatChildren = (children = [], labelField, valueField) => {
      return children.reduce((filtered, child) => {
        const selected = internalValue.find(val => val.value === String(child[valueField]));

        if (!selected) {
          filtered.push({
            value: String(child[valueField]),
            label: child[labelField],
          });
        }

        return filtered;
      }, []);
    };

    const formatOptions = (options = []) => {
      const labelField = optionsMapper.label || 'title';
      const valueField = optionsMapper.value || 'id';

      if (hasGroups && options.length > 1) {
        return options.map(option => {
          return {
            ...option,
            label: option[labelField],
            value: String(option[valueField]),
            options: formatChildren(option.children, labelField, valueField),
          };
        });
      } else if (hasGroups && options.length) {
        return options.reduce((opts, opt) => {
          const childOptions = formatChildren(opt.children, labelField, valueField);

          return [...opts, ...childOptions];
        }, []);
      }
      return options;
    };

    const groupLabel = data => {
      if (hasGroups && data?.children?.length > 1) {
        return <StyledListSubheader>{data.label}</StyledListSubheader>;
      }
    };

    const behaviourProps = keepOpenedAfterSelect ? { blurInputOnSelect: false, closeMenuOnSelect: false } : {};

    const multiSelectComp = (
      <SelectComponent
        {...this.props}
        classes={classes}
        styles={selectStyles}
        textFieldProps={{
          label,
          InputLabelProps: {
            shrink: true,
          },
          autoFocus,
          error,
          inputRef: this.getRef,
          ...this.props.textFieldProps,
        }}
        options={formatOptions(groupOptions || options)}
        components={{
          ...defaultComponents,
          ...(this.props.hideIcon ? { DropdownIndicator: null } : {}),
          ...(components || {}),
        }}
        value={internalValue}
        isValidNewOption={hideCreateOption ? () => false : undefined}
        onChange={value => {
          return onChange(value ? value.map(option => option.value) : []);
        }}
        onInputChange={this.props.onInputChange}
        onFocus={this.props.onFocus}
        placeholder={placeholder}
        onCreateOption={this.createOption}
        isMulti
        selectRef={this.ref}
        chipColor={chipColor}
        defaultMenuIsOpen={defaultMenuIsOpen}
        hideControlsOnBlur={this.props.hideControlsOnBlur}
        setIsFocused={_setIsFocused}
        isFocused={this.state.isFocused}
        formatGroupLabel={data => groupLabel(data)}
        {...behaviourProps}
      />
    );

    return (
      <SelectWrapper className={className}>
        {!draggable && multiSelectComp}
        {draggable && (
          <DragDropContext onDragEnd={_moveDraggedOption}>
            <Droppable droppableId="droppable" type="app" direction="horizontal">
              {(provided, snapshot) => (
                <div ref={provided.innerRef}>
                  {multiSelectComp}
                  {/* {provided.placeholder} */}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </SelectWrapper>
    );
  }
}

const SelectWrapper = styled.div`
  .multiselect-chip {
    background-color: ${({ chipColor }) => chipColor || '#bffffc'};
  }
`;

const StyledListSubheader = styled(ListSubheader)`
  &&&&& {
    font-size: 12px;
  }
`;

const StyledOptionsList = styled.div`
  &&&&& {
    & > div {
      padding: 8px 0;
    }
  }
`;

const InputWrapper = styled.div`
  flex: 1;
  padding: 2.5px 0;
`;

const ValueInput = styled.input`
  color: inherit;
  background: 0;
  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  width: 99%;
  min-width: ${({ placeholder }) => (!placeholder ? 0 : '150px;')};
  border: none;
  font-size: 16px;
  outline: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: initial;
  padding-left: 0;
`;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    // padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  valueContainerWithEllipsis: {
    display: '-webkit-box',
    '-webkit-line-clamp': MAX_ROWS_NUM,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    flex: 1,
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    backgroundColor: `${theme.palette.newLayout.background.lightGray}`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    fontSize: 16,
    color: `${theme.palette.text.disabled}`,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

export default withStyles(styles, { withTheme: true })(MultiSelect);
