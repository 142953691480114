import React from 'react';
import styled from 'styled-components';

import TitleWithActions from 'design-system/organisms/TitleWithActions';

const TextWithOpenLinkCellRenderer = params => {
  const { value, valueFormatted, data, colDef } = params;
  const { onClickFactory } = colDef.cellEditorParams;
  const onClickOpenButton = onClickFactory(data, value, params);

  const openButtonProps = {
    onClick: onClickOpenButton,
  };

  const { cellStyle } = params.colDef;
  const { color } = cellStyle(params);

  const extraParams = {};

  if (color) {
    extraParams.color = color;
  }

  return (
    <TitleWithActions openButtonProps={openButtonProps} hasOpenButton={Boolean(onClickOpenButton)}>
      <CellText {...extraParams}>{valueFormatted}</CellText>
    </TitleWithActions>
  );
};

const CellText = styled.div`
  &&&& {
    width: 100%;
    ${({ color }) => color && `color: ${color}`}
  }
`;

export default TextWithOpenLinkCellRenderer;
