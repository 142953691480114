import React from 'react';
import styled, { css } from 'styled-components';
import useProgressBarValues from 'src/design-system/hooks/useProgressBarValues';

const ProgressContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 24px;
  border-radius: 4px;

  background: ${props => props.theme.palette.newLayout.progressBarSquared.background.lightGray};

  ${({ smallVersion }) =>
    smallVersion &&
    css`
      height: 16px;
    `}
`;
const ProgressBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  height: 24px;
  width: ${props => props.value}%;
  max-width: 100%;

  background: ${props =>
    props.theme.palette.newLayout.progressBarSquared.background[props.color] ||
    props.theme.palette.newLayout.progressBarSquared.background.gray};

  ${({ smallVersion }) =>
    smallVersion &&
    css`
      border-radius: 10px;
      height: 16px;
    `}
`;

const LabelBefore = styled.span`
  ${({ value }) =>
    value < 40 &&
    css`
      display: none;
    `}

  position: absolute;
  right: 10px;
  font-size: ${props => (props.smallVersion ? props.theme.typography.fontSizeSmall : props.theme.typography.fontSize)}px;
  color: ${props => props.color && ['undefined', 'red', 'green'].includes(props.color) && 'white'};
`;

const LabelAfter = styled.span`
  ${({ value }) =>
    value >= 40 &&
    css`
      display: none;
    `}

  margin-left: 10px;
  font-size: ${props => (props.smallVersion ? props.theme.typography.fontSizeSmall : props.theme.typography.fontSize)}px;
`;

const ProgressLabel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 9px;

  span {
    font-size: ${props => props.theme.typography.fontSize}px;
    line-height: ${props => props.theme.typography.lineHeightSmallLarge}px;
  }
`;

export default ({
  actualValue,
  actualValueLabel,
  minValue,
  minValueLabel,
  maxValue,
  maxValueLabel,
  formatValues,
  smallVersion,
  ...props
}) => {
  const { percentageValue, formattedActualValue, formattedMinValue, formattedMaxValue, color } = useProgressBarValues({
    actualValue,
    actualValueLabel,
    minValue,
    minValueLabel,
    maxValue,
    maxValueLabel,
    formatValues,
    color: props.color,
  });

  return (
    <ProgressContainer className={props.className}>
      <ProgressBarContainer smallVersion={smallVersion}>
        {actualValue > 0 && (
          <ProgressBar value={percentageValue} color={color} smallVersion={smallVersion}>
            <LabelBefore smallVersion={smallVersion} value={percentageValue} color={color}>
              {formattedActualValue}
            </LabelBefore>
          </ProgressBar>
        )}
        <LabelAfter smallVersion={smallVersion} value={percentageValue}>
          {formattedActualValue}
        </LabelAfter>
      </ProgressBarContainer>
      <ProgressLabel>
        <span>{formattedMinValue}</span>
        <span>{formattedMaxValue}</span>
      </ProgressLabel>
    </ProgressContainer>
  );
};
