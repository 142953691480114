import React from 'react';

function buildActions(actions, dispatch) {
  return Object.keys(actions).reduce((acc, k) => {
    acc[k] = payload => dispatch({ type: k, payload });
    return acc;
  }, {});
}

function buildReducer(actions) {
  return (state, action) => {
    const handler = actions[action.type];

    if (handler) return handler(state, action.payload);
    return state;
  };
}

export default function (actions, initialState) {
  const [state, dispatch] = React.useReducer(buildReducer(actions), initialState);

  return [state, buildActions(actions, dispatch)];
}
