import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import DragonAvatar from 'design-system/atoms/DragonAvatar/index';
import ObjectiveType from 'design-system/atoms/ObjectiveType/index';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow/index';

import theme from 'design-system/theme';
import formatPercent from 'design-system/utils/formatPercent';
import ProgressBar from 'design-system/molecules/ProgressBar';
import MultipleMetricsProgressBar from 'design-system/molecules/MultipleMetricsProgressBar';

import SnapshotItemPie from './SnapshotItemPie';
import TimelineLightbox from 'containers/TimelineLightbox';

import { SNAPSHOT_PAGE } from 'constants/filters';
import { PROGRESS_PERCENTAGE_DECIMAL_PLACES } from 'constants/common';
import { HAS_METRIC_DATA } from 'constants/branches';

import { OBJECT_KEY_RESULT, OBJECT_KEY_RESULT_2 } from 'store/objectives';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import { getCurrentUser } from 'store/login/selectors';
import { getHasMultipleMetrics, getOrgHasMetadataRoadmaps } from 'store/organization';

import { getUserName, getUserShortName } from 'utils';

import materialColorsSnapshot from './colors';

import {
  StyledSnapshotItemWrapper,
  ObjectiveHeader,
  Header,
  InnerContainer,
  ProgressPercentage,
  ProgressDetailViewIcon,
  ProgressLabel,
  ProgressStatus,
  MetrictStatus,
  MetrictStatusHeader,
  GridRow,
  ProgressRow,
  ChartBody,
  OkrBody,
  FeatureBody,
  PortfolioProgressContainer,
  ChartContainer,
  InnerWrapper,
  StyledText,
} from './SnapshotItemStyledComponents';

export default function SnapshotItem({
  entity,
  groupedBy,
  displayLayer,
  displayLayerLabel,
  selectEntity,
  isChild,
  openObjectiveDrawer,
  selectedFieldSystemName,
  selectedSnapshotField,
  toggleMetricInformation,
  hasAdvancedMetricReporting,
  visibleCardElements,
}) {
  const [openTimeline, setOpenTimeline] = useState();

  const hasMultipleMetrics = useSelector(getHasMultipleMetrics);
  const hasMetadataRoadmaps = useSelector(getOrgHasMetadataRoadmaps);
  const currentUser = useSelector(getCurrentUser);
  const isAnonymousUser = currentUser.is_anonymous;

  const isOKRCard = HAS_METRIC_DATA[entity.group];

  const entityRoadmaps = useMemo(() => {
    if (!hasMetadataRoadmaps || !entity.roadmaps) return null;

    const roadmapsTitles = entity.roadmaps?.map(roadmap => roadmap.title);

    if (!roadmapsTitles.length) return null;

    return roadmapsTitles.join(', ');
  }, [entity, hasMetadataRoadmaps]);

  const displayMetrics = visibleCardElements?.metrics;
  const displayPortfolioProgress = visibleCardElements?.portfolioProgress;
  const displayPortfolioItems = visibleCardElements?.portfolioItems;
  const displayOwnerAvatar = visibleCardElements?.ownerAvatar;

  const shouldRenderRoadmapName = useMemo(
    () => entityRoadmaps && !displayMetrics && !displayPortfolioProgress && !displayPortfolioItems,
    [entityRoadmaps, displayMetrics, displayPortfolioProgress, displayPortfolioItems],
  );

  const shouldRenderRightPanel = useMemo(
    () => displayPortfolioProgress || displayPortfolioItems,
    [displayPortfolioProgress, displayPortfolioItems],
  );

  const shouldRenderBottomPanel = useMemo(() => displayMetrics || displayPortfolioItems, [displayMetrics, displayPortfolioItems]);

  const shouldRenderOwnerAvatar = useMemo(() => displayOwnerAvatar && entity.owner?.id, [displayOwnerAvatar, entity]);

  const handleSelectEntity = e => {
    selectEntity(entity);
  };

  const handleOpenStatus = e => {
    e.stopPropagation();

    if (entity.id !== null) {
      switch (entity?.group) {
        case 'keyResult1':
          openObjectiveDrawer(entity.id, OBJECT_KEY_RESULT);
          break;
        case 'keyResult2':
          openObjectiveDrawer(entity.id, OBJECT_KEY_RESULT_2);
          break;
        case 'objective':
        case 'objectiveCorp':
        default:
          openObjectiveDrawer(entity.id);
          break;
      }
    } else {
      openTimeline();
    }
  };

  const handleOpenTimeline = e => {
    e.stopPropagation();
    if (isAnonymousUser) return;
    openTimeline();
  };

  const _renderHeader = () => {
    const headerIcon = shouldRenderOwnerAvatar ? (
      <DragonAvatar
        short={getUserShortName(entity.owner)}
        showBorder={false}
        styledWrapper={false}
        tooltipTitle={getUserName(entity.owner)}
      />
    ) : (
      <ObjectiveType
        type={
          {
            objectiveCorp: 'objectiveCorp',
            objective: 'objective',
            keyResult1: 'keyResult',
            keyResult2: 'keyResult2',
          }[entity.group]
        }
        active
      />
    );

    return (
      <Tooltip title={entity.title} placement="bottom-start">
        <Header
          child={isChild}
          title={
            ['objectiveCorp', 'objective', 'keyResult1', 'keyResult2'].includes(entity.group) ? (
              <ObjectiveHeader child={isChild} icon={headerIcon}>
                <span>{entity.title}</span>
              </ObjectiveHeader>
            ) : (
              entity.title
            )
          }
          onClick={() => selectEntity(entity)}
        />
      </Tooltip>
    );
  };

  const _renderProgressStatus = (value, label, onClick, color) => (
    <ProgressStatus child={isChild} onClick={onClick}>
      <ProgressPercentage child={isChild} color={color} isBlack={!color}>
        {value}
      </ProgressPercentage>
      <ProgressLabel>{label} Progress</ProgressLabel>
      {!isAnonymousUser && <ProgressDetailViewIcon />}
    </ProgressStatus>
  );

  const _renderOkrProgress = () => {
    return _renderProgressStatus(
      `${parseFloat((entity.progress || 0) * 100).toFixed(1)}%`,
      selectedFieldSystemName,
      handleOpenStatus,
      materialColorsSnapshot[entity.status_color ? entity.status_color.toLowerCase() : 'darkestGray'],
    );
  };

  const _renderFeatureProgress = () => {
    return _renderProgressStatus(
      formatPercent(entity.ideasProgress, PROGRESS_PERCENTAGE_DECIMAL_PLACES),
      displayLayerLabel,
      handleOpenTimeline,
    );
  };

  const _renderOKRContent = () => (
    <>
      <ProgressRow>
        <OkrBody top justifyStart child={isChild} useFullWidth={!shouldRenderRightPanel}>
          {_renderOkrProgress()}
        </OkrBody>
        {shouldRenderRightPanel ? (
          <FeatureBody child={isChild}>
            <PortfolioProgressContainer visible={displayPortfolioProgress}>{_renderFeatureProgress()}</PortfolioProgressContainer>
            {displayPortfolioItems ? (
              <MetrictStatus child={isChild}>
                <MetrictStatusHeader lines="2" child={isChild} className="chart-title">
                  {entity.nProjects || 0} {displayLayerLabel}s
                </MetrictStatusHeader>
              </MetrictStatus>
            ) : null}
          </FeatureBody>
        ) : null}
      </ProgressRow>
      <GridRow justifyEnd={!displayMetrics}>
        {displayMetrics ? (
          <OkrBody child={isChild} useFullWidth={!shouldRenderRightPanel}>
            {hasMultipleMetrics ? (
              <MultipleMetricsProgressBar
                child={isChild}
                hasAdvancedMetricReporting={hasAdvancedMetricReporting}
                toggleMetricInformation={toggleMetricInformation}
                entity={entity}
              />
            ) : (
              <ProgressBar
                child={isChild}
                hasAdvancedMetricReporting={hasAdvancedMetricReporting}
                toggleMetricInformation={toggleMetricInformation}
                entity={entity}
              />
            )}
          </OkrBody>
        ) : null}
        {displayPortfolioItems ? (
          <ChartContainer child={isChild}>
            {entity.nProjects > 0 && (
              <ChartBody onClick={handleOpenTimeline} child={isChild}>
                <SnapshotItemPie width={null} height={null} entity={entity} noTitle />
              </ChartBody>
            )}
          </ChartContainer>
        ) : null}
        {shouldRenderRoadmapName ? (
          <TooltipOnOverflow text={entityRoadmaps} elementWithOverflow="span">
            <StyledText size="medium" color={theme.palette.newLayout.background.darkestGray}>
              (Applied to &nbsp;<span>{entityRoadmaps}</span>)
            </StyledText>
          </TooltipOnOverflow>
        ) : null}
      </GridRow>
    </>
  );

  const _renderOtherContent = () => (
    <Grid container>
      <Grid item xs={8}>
        {_renderFeatureProgress()}
        <MetrictStatus child={isChild}>
          <MetrictStatusHeader child={isChild}>
            {entity.nProjects || 0} {displayLayerLabel}s
          </MetrictStatusHeader>
        </MetrictStatus>
      </Grid>
      <Grid item xs={4} onClick={handleOpenTimeline}>
        {entity.nProjects > 0 && <SnapshotItemPie width={null} height={null} entity={entity} noTitle />}
      </Grid>
    </Grid>
  );

  const getTimelineProjectsSubFilter = () => {
    const mapSelectedSnapshotField = {
      keyResult2: 'key_result_2_id',
      keyResult1: 'key_result_1_id',
      product1: 'product_1_id',
      roadmapCorp: 'roadmap_corp_id',
      objectiveCorp: 'objective_corp_id',
      initiative: 'initiative__id',
      bet: 'bet__id',
    };

    const selectedItemKey = entity.metadataName || selectedSnapshotField;

    const filterField = mapSelectedSnapshotField[selectedItemKey] || `${selectedSnapshotField}_id`;
    const needsAllLayers = ['initiative', 'bet'].includes(selectedSnapshotField);

    return {
      fields: {
        layer: needsAllLayers ? [BET_LAYER, INITIATIVE_LAYER, IDEA_LAYER] : displayLayer,
        [filterField]: entity.id,
      },
    };
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const openTimelineEntityId = urlParams.get('openTimeline');

    if (openTimeline && openTimelineEntityId && entity?.id === Number(openTimelineEntityId)) {
      openTimeline();
    }
  }, [openTimeline]);

  return (
    <StyledSnapshotItemWrapper child={isChild} reduceMinHeight={!shouldRenderBottomPanel}>
      <InnerWrapper onClick={handleSelectEntity}>
        {_renderHeader()}
        <InnerContainer child={isChild}>{isOKRCard ? _renderOKRContent() : _renderOtherContent()}</InnerContainer>
      </InnerWrapper>
      <TimelineLightbox
        onOpen={f => setOpenTimeline(() => f)}
        entityGroup={groupedBy}
        groupedBy={entity}
        lazyLoadProjects
        getLazyLoadProjectsSubFilter={getTimelineProjectsSubFilter}
        pageId={SNAPSHOT_PAGE}
      />
    </StyledSnapshotItemWrapper>
  );
}
