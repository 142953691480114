const TOAST_MESSAGE_TYPES = {
  ERROR: 'error',
  INFO: 'info',
};

export const IS_EMPTY_KEY = 'isEmpty';
export const IS_NOT_EMPTY_KEY = 'isNotEmpty';

const FILTERS_FUNCTIONS = [
  { key: 'in', label: 'Contains' },
  { key: 'contains', label: 'Contains' },
  { key: 'notContains', label: 'Does Not Contain' },
  { key: 'notIn', label: 'Does Not Contain' },
  { key: IS_EMPTY_KEY, label: 'Is Empty' },
  { key: IS_NOT_EMPTY_KEY, label: 'Is Not Empty' },
  { key: 'equals', label: 'Equals' },
  { key: 'gte', label: 'Bigger than or equals' },
  { key: 'lte', label: 'Smaller than or equals' },
  { key: 'before', label: 'Before' },
  { key: 'after', label: 'After' },
  { key: 'between', label: 'Between' },
  { key: 'thisWeek', label: 'This Week' },
  { key: 'thisMonth', label: 'This Month' },
  { key: 'thisQuarter', label: 'This Quarter' },
  { key: 'thisYear', label: 'This Year' },
  { key: 'lastWeek', label: 'Last Week' },
  { key: 'lastMonth', label: 'Last Month' },
  { key: 'lastQuarter', label: 'Last Quarter' },
  { key: 'lastYear', label: 'Last Year' },
  { key: 'nextWeek', label: 'Next Week' },
  { key: 'nextMonth', label: 'Next Month' },
  { key: 'nextQuarter', label: 'Next Quarter' },
  { key: 'nextYear', label: 'Next Year' },
  { key: 'today', label: 'Today' },
  { key: 'containsAnyOf', label: 'Contains any of' },
  { key: 'isEmptyText', label: 'Is Empty' },
  { key: 'isNotEmptyText', label: 'Is Not Empty' },
].reduce((acc, func) => {
  acc[func.key] = func;
  return acc;
}, {});

export { TOAST_MESSAGE_TYPES, FILTERS_FUNCTIONS };
