import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import { string } from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { defaultTo } from 'ramda';

import cellEditorHoc from '../helpers/cellEditorHoc';
import { focusAndSelectText } from 'containers/Grids/utils/handleFieldFocus';

const defaultTo2Decimals = defaultTo(2);
const defaultToEmptyString = defaultTo('');

const NumericCellEditor = cellEditorHoc(
  ({ column, value: originalValue = '', forwardedRef, allowNegative = true, handleInputFocus = focusAndSelectText }) => {
    const inputRef = useRef();
    const [internalValue, setInternalValue] = useState(defaultToEmptyString(originalValue));

    useImperativeHandle(forwardedRef, () => ({
      getValue() {
        return internalValue;
      },
    }));

    const handleChange = event => {
      setInternalValue(event.value ? event.value : null);
    };

    useEffect(() => {
      handleInputFocus(inputRef.current);
    }, []);

    return (
      <NumberFormat
        data-testid="numeric-cell-editor"
        type="text"
        className="ag-cell-edit-input"
        thousandSeparator
        decimalScale={defaultTo2Decimals(column?.colDef?.decimalScale)}
        customInput={TextField}
        inputRef={el => {
          inputRef.current = el;
        }}
        onValueChange={handleChange}
        value={internalValue}
        allowNegative={allowNegative}
        format={column?.colDef?.format}
      />
    );
  },
);

NumericCellEditor.propTypes = {
  value: string,
};

export default NumericCellEditor;
