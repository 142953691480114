import React, { Component } from 'react';
import { string } from 'prop-types';
import { connect } from 'react-redux';

import { getOrganization } from 'store/organization/selectors';

import GoalsGrid from './GoalsGrid';
import { setLastIdeasView } from 'store/app';

class Goals extends Component {
  static propTypes = {
    displayView: string,
  };

  static defaultProps = {
    displayView: 'grid',
  };

  availableViews = {
    grid: GoalsGrid,
    summary: <div>summary</div>,
  };

  componentDidMount() {
    this.props.setLastIdeasView(this.getViewKey());
  }

  getViewKey() {
    const { location } = this.props;

    const page = location.pathname.split('/')[2];
    const queryString = location.search || '';

    if (!page) {
      this.props.history.push(`/goals/${this.props.lastGoalsView || 'grid'}${queryString}`);
    }

    return !page ? this.props.lastGoalsView || 'grid' : page;
  }

  render() {
    const { props } = this;

    const View = this.availableViews[this.getViewKey()];

    return <View {...props} />;
  }
}

export default connect(
  state => {
    return {
      organization: getOrganization(state),
      lastGoalsView: state.app.lastGoalsView,
    };
  },
  {
    setLastIdeasView,
  },
)(Goals);
