import { HIDE_LEGEND_OPTION } from 'constants/charts';

/**
 * Generates chart options for a pie chart.
 * @param {Function} onChartClickHandler - Callback handler for chart click events
 * @returns {Object} An object containing configuration options for the pie chart.
 */
const getPieChartOptions = onChartClickHandler => ({
  onClick: onChartClickHandler,
  animation: {
    duration: 0,
  },
  responsive: true,
  maintainAspectRatio: false,
  aspectRatio: 2,
  layout: {
    padding: 10,
  },
  plugins: {
    legend: HIDE_LEGEND_OPTION,
  },
  radius: '80%',
});

export default getPieChartOptions;
