import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SNAPSHOT_PAGE } from 'constants/filters';

import { getUserFilters } from 'store/filters/selectors';
import { fetchSnapshotDataAction } from 'store/snapshot/thunks';
import { getSnapshotData, getFetchSnapshotDataIsLoading } from 'store/snapshot/selectors';

import compileFiltersBody from 'utils/filters/compileFiltersBody';
import usePageFilters from 'hooks/filters/usePageFilters';

const useLoadSnapshotReport = selectedSnapshotEntity => {
  const dispatch = useDispatch();

  const pageId = SNAPSHOT_PAGE;
  const { pageFilters: filters, displayLayer } = usePageFilters(SNAPSHOT_PAGE);
  const hasBet = useSelector(state => state.organization.organization.has_bet);
  const userFilters = useSelector(state => getUserFilters(state));

  const reportData = useSelector(getSnapshotData);

  const isLoadingReportData = useSelector(getFetchSnapshotDataIsLoading);

  const fetchSnapshotData = useCallback(requestBody => dispatch(fetchSnapshotDataAction(requestBody)), [dispatch]);

  const fetchReportData = useCallback(async () => {
    const projectsFilter = compileFiltersBody(filters, userFilters, hasBet, pageId, displayLayer);

    const requestBody = {
      projectsFilter,
      displayLayer,
      selectedSnapshotEntity: selectedSnapshotEntity || 'objective',
    };

    return fetchSnapshotData(requestBody);
  }, [fetchSnapshotData, filters, userFilters, displayLayer, selectedSnapshotEntity]);

  useEffect(() => {
    fetchReportData();
  }, [filters, displayLayer, selectedSnapshotEntity]);

  return {
    isLoadingReportData,
    reportData,
    fetchReportData,
  };
};

export default useLoadSnapshotReport;
