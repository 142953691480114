/* eslint-disable max-len */
import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48.659}
      height={43.564}
      viewBox="0 0 48.659 43.564"
      {...props}
    >
      <defs>
        <style>
          {
            '.prefix__a,.prefix__b{fill:#5accc6;stroke:#5accc6;stroke-width:.8px}.prefix__b{stroke-miterlimit:10}'
          }
        </style>
      </defs>
      <path
        className="prefix__a"
        d="M.4 3.187v39.977h47.858v-1.916H2.323V3.187z"
      />
      <path
        className="prefix__b"
        d="M.401 3.187h1.923v38.061h45.935v1.916H.401z"
      />
      <path
        className="prefix__a"
        d="M42.74 31.042c0-1.85.007-3.7 0-5.551a1.1 1.1 0 01.753-1.151c.189-.073.374-.157.556-.247a1.4 1.4 0 00.9-1.609c-.327-2.709-.564-5.428-.843-8.143a1.137 1.137 0 00-1.219-1.182 131.295 131.295 0 00-5.213 0c-.836.016-1.194.416-1.294 1.353-.206 1.929-.384 3.86-.575 5.79-.069.7-.132 1.392-.219 2.086-.122.975-.011 1.376.919 1.7 1.094.378 1.31 1.091 1.322 2.12.04 3.431.148 6.86.228 10.29.017.735-.366 1.2-.984 1.2-.6 0-.956-.392-.976-1.125-.09-3.317-.164-6.634-.268-9.951a1.109 1.109 0 00-.317-.8 3.741 3.741 0 01-1.863-4.123c.391-2.515.54-5.066.826-7.6a3.34 3.34 0 012.993-2.858 76.235 76.235 0 015.617 0 3.412 3.412 0 012.994 2.879c.3 2.735.525 5.478.843 8.211a3.236 3.236 0 01-1.68 3.281 1.063 1.063 0 00-.553.976c-.058 3.137-.143 6.273-.22 9.41-.006.248.005.5-.023.744a.972.972 0 01-1.027.947.923.923 0 01-.933-1.023c.022-1.58.076-3.159.114-4.739.007-.293 0-.587 0-.88h.148"
      />
      <path
        className="prefix__b"
        d="M42.74 31.042c0-1.85.007-3.7 0-5.551a1.1 1.1 0 01.753-1.151c.189-.073.374-.157.556-.247a1.4 1.4 0 00.9-1.609c-.327-2.709-.564-5.428-.843-8.143a1.137 1.137 0 00-1.219-1.182 131.295 131.295 0 00-5.213 0c-.836.016-1.194.416-1.294 1.353-.206 1.929-.384 3.86-.575 5.79-.069.7-.132 1.392-.219 2.086-.122.975-.011 1.376.919 1.7 1.094.378 1.31 1.091 1.322 2.12.04 3.431.148 6.86.228 10.29.017.735-.366 1.2-.984 1.2-.6 0-.956-.392-.976-1.125-.09-3.317-.164-6.634-.268-9.951a1.109 1.109 0 00-.317-.8 3.741 3.741 0 01-1.863-4.123c.391-2.515.54-5.066.826-7.6a3.34 3.34 0 012.993-2.858 76.235 76.235 0 015.617 0 3.412 3.412 0 012.994 2.879c.3 2.735.525 5.478.843 8.211a3.236 3.236 0 01-1.68 3.281 1.063 1.063 0 00-.553.976c-.058 3.137-.143 6.273-.22 9.41-.006.248.005.5-.023.744a.972.972 0 01-1.027.947.923.923 0 01-.933-1.023c.022-1.58.076-3.159.114-4.739.007-.293 0-.587 0-.88z"
      />
      <path
        className="prefix__a"
        d="M29.095 37.387h-1.933v-.731-16.6c0-1.3-.44-1.749-1.741-1.763q-.779-.008-1.558.005c-1.2.022-1.633.447-1.634 1.642q-.007 8.027 0 16.054v1.4h-1.943c-.012-.255-.032-.493-.032-.731V19.932a3.586 3.586 0 013.495-3.574 52.323 52.323 0 011.9 0 3.59 3.59 0 013.491 3.453c.023 5.712.009 11.425.007 17.138 0 .13-.027.261-.046.438"
      />
      <path
        className="prefix__b"
        d="M29.095 37.387h-1.933v-.731-16.6c0-1.3-.44-1.749-1.741-1.763q-.779-.008-1.558.005c-1.2.022-1.633.447-1.634 1.642q-.007 8.027 0 16.054v1.4h-1.943c-.012-.255-.032-.493-.032-.731V19.932a3.586 3.586 0 013.495-3.574 52.323 52.323 0 011.9 0 3.59 3.59 0 013.491 3.453c.023 5.712.009 11.425.007 17.138-.006.13-.033.26-.052.438z"
      />
      <path
        className="prefix__a"
        d="M16.012 37.405h-1.986v-.966-8.259c0-1.184-.449-1.638-1.627-1.652q-.846-.01-1.692 0c-1.156.017-1.6.45-1.608 1.6-.009 2.843-.024 5.687.012 8.53.008.633-.18.853-.794.79-.373-.039-.754-.007-1.2-.007v-1.753c0-2.527-.006-5.055 0-7.582a3.642 3.642 0 013.47-3.55c.564-.016 1.128-.007 1.692-.006a3.661 3.661 0 013.729 3.72c.007 3.022 0 6.044 0 9.138"
      />
      <path
        className="prefix__b"
        d="M16.012 37.405h-1.986v-.966-8.259c0-1.184-.449-1.638-1.627-1.652q-.846-.01-1.692 0c-1.156.017-1.6.45-1.608 1.6-.009 2.843-.024 5.687.012 8.53.008.633-.18.853-.794.79-.373-.039-.754-.007-1.2-.007v-1.753c0-2.527-.006-5.055 0-7.582a3.642 3.642 0 013.47-3.55c.564-.016 1.128-.007 1.692-.006a3.661 3.661 0 013.729 3.72c.009 3.022.004 6.044.004 9.135z"
      />
      <path
        className="prefix__a"
        d="M40.247 8.928a4.436 4.436 0 01-4.28-4.285 4.275 4.275 0 018.55.009 4.406 4.406 0 01-4.275 4.277m-2.291-4.29a2.114 2.114 0 002.236 2.323 2.17 2.17 0 002.385-2.272 2.166 2.166 0 00-2.264-2.322 2.12 2.12 0 00-2.357 2.271"
      />
      <path
        className="prefix__b"
        d="M40.247 8.928a4.436 4.436 0 01-4.28-4.285 4.275 4.275 0 018.55.009 4.406 4.406 0 01-4.27 4.276zm-2.291-4.29a2.114 2.114 0 002.236 2.323 2.17 2.17 0 002.385-2.272 2.166 2.166 0 00-2.264-2.322 2.12 2.12 0 00-2.357 2.271z"
      />
    </svg>
  );
}

export default SvgComponent;
