import { not } from 'ramda';
import { filterActiveItens } from 'utils';

export const shouldHideEmptyBasedOnUserPref = ({ groupObject, hasAFilterByGroupedKey, hideEmptyUserPref }) => {
  if (hideEmptyUserPref) return true;

  if (hasAFilterByGroupedKey) {
    return groupObject?.excludedFromFilter;
  }

  const isActive = filterActiveItens(groupObject);

  return not(isActive);
};

export const shouldHideEmptyBasedOnFilter = ({ groupObject, hasAFilterByGroupedKey }) => {
  if (hasAFilterByGroupedKey) {
    return groupObject?.excludedFromFilter;
  }

  return true;
};

export const hideAllEmpty = () => true;
