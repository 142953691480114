import React from 'react';
import styled from 'styled-components';
import theme, { spacing } from 'design-system/theme';

import useUserFavoriteViews from 'hooks/userViews/useUserFavoriteViews';

import getFavoriteIcon from 'utils/userViews/getFavoriteIcon';

import ButtonIcon from 'design-system/molecules/ButtonIcon/index';


const ViewStarredTitle = ({ children, view }) => {
  const { isFavoriteView, handleFavoriteUserView } = useUserFavoriteViews();
  const notFavoriteColor = theme.palette.text.quickSilver;

  return (
    <>
      <StyledFavoriteIconButton
        onClick={e => {
          e.stopPropagation();
          handleFavoriteUserView(view);
        }}
      >
        {getFavoriteIcon(isFavoriteView(view), notFavoriteColor)}
      </StyledFavoriteIconButton>
      {children || <span>{view?.name}</span>}
    </>
  );
};

const StyledFavoriteIconButton = styled(ButtonIcon)`
  &&&& {
    padding: 2px;
    margin-right: ${spacing(0.75)}px;
    margin-left: -2px;
    float: left;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export default ViewStarredTitle;
