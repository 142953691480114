import { useDispatch, useSelector } from 'react-redux';

import { CUSTOM_USER_FIELDS } from 'store/grids/constants';
import { getCurrentUser } from 'store/login/selectors';
import { updateSystemFieldsName, updateCustomUserFieldsSettings, getOrganization } from 'store/organization';

import { checkRolePermission } from 'containers/UserPermission/utils';
import { DEFAULT_SETTINGS_NAME } from 'config';

import { getSystemFieldNameFromCustomUserFieldKey } from 'utils/customUserFields/customUserFields';

const UPDATE_METADATA_PERMISSION = 'UPDATE_METADATA';

const useCustomUserFieldsSettings = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const organization = useSelector(getOrganization);
  const allowActions = checkRolePermission(UPDATE_METADATA_PERMISSION, currentUser, organization, CUSTOM_USER_FIELDS);

  const updateCustomUserFieldValue = field => {
    return dispatch(updateCustomUserFieldsSettings(field));
  };

  const renameCustomUserField = (key, title) => {
    return dispatch(
      updateSystemFieldsName({
        key: getSystemFieldNameFromCustomUserFieldKey(key),
        defaultTitle: DEFAULT_SETTINGS_NAME[getSystemFieldNameFromCustomUserFieldKey(key)],
        title,
      }),
    );
  };

  return {
    allowActions,
    updateCustomUserFieldValue,
    renameCustomUserField,
    systemFields: organization.system_fields_name,
  };
};

export default useCustomUserFieldsSettings;
