/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unescaped-entities */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { defaultTo, path, pipe, prop, isNil } from 'ramda';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import AddIcon from '@material-ui/icons/Add';
import HelpIcon from '@material-ui/icons/HelpOutline';
import DeleteIcon from '@material-ui/icons/Delete';

import merge from 'lodash/merge';

import { ISSUE_COUNT_PROGRESS, STORY_POINTS_PROGRESS, TIME_ESTIMATES_PROGRESS } from 'constants/integrations';
import {
  ENABLE_JIRA_WEBHOOKS,
  DISABLE_JIRA_WEBHOOKS,
  ENABLE_JIRA_RECURRING_IMPORT,
  DISABLE_JIRA_RECURRING_IMPORT,
} from '../../constants/validationDialogActionTypes';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import HelpLink from 'design-system/atoms/HelpLink/index';
import InlineIcon from 'design-system/atoms/InlineIcon/index';
import SmallText from 'design-system/atoms/SmallText/index';

import { textColor } from 'design-system/themes/default';
import CollapsePanel from 'components/CollapsePanel';
import converStringTimeToSeconds from 'utils/converStringTimeToSeconds';
import converTimeToString from 'utils/converTimeToString';
import formatDateTime from 'utils/dates/formatDateTime';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import getDateForFormatting from '../utils/isoDateFormat';

import { showImportIdeasLightbox, showImportJQLLightbox, searchForTasksByJiraUrl, previewTasksByJiraUrl } from 'store/ideas';
import { testJira, disconnectJira, updateJiraConfigMisc, fetchJiraFields, syncOrgProjectsFromJira } from 'store/integrations';
import ImportIdeasDialog from 'containers/ImportIdeasDialog';
import { ConfirmDialog } from 'components/gridCommon';
import InfoToast from 'components/InfoToast';
import {
  updateJiraIntegration,
  importUsingRecurringJQLQuery,
  registerJiraWebhook,
  unregisterJiraWebhook,
} from 'store/organization';
import RecurringJQLReviewModal from './RecurringJQLReviewLightbox';

import { SUPER_ADMIN_USER } from '@dragonboat/permissions';

import IntegrationPermissionsDialog from 'routes/Settings/Integration/components/IntegrationPermissionsDialog';

import JiraSettings from '../components/JiraSettings';
import JiraWebhooks from '../components/JiraWebhooks';
import OtherJiraSettings from '../components/OtherJiraSettings';

const getJiraTestedFlag = pipe(path(['data', 'jira_tested']), defaultTo(false));
const hasNoError = pipe(prop('error'), isNil);

class ConfigDetails extends React.Component {
  constructor(props) {
    super(props);
    const defaultPanels = [];
    const integrationData = (props.orgIntegration && props.orgIntegration.data) || {};

    this.state = {
      defaultPanels,
      waitForAPI: false,
      confirmDialogOpened: false,
      messageActionName: '',
      onConfirm: null,
      triggerImportJQLLightbox: true,
      defaultTimeEstimate: converTimeToString(integrationData.default_time_estimate) || '1d',
      newMapping: null,
      confirmDeleteMappingOpened: false,
      projectKey: 'DEFAULT',
      validatePermissionsDialogOpen: false,
      jiraIntegrationUpdateData: {},
      confirmationMessage: null,
    };
    this.testJiraConnection = this.testJiraConnection.bind(this);
    this.registerJiraWebhookHandler = this.registerJiraWebhookHandler.bind(this);
    this.unregisterJiraWebhookHandler = this.unregisterJiraWebhookHandler.bind(this);
    this.updateJiraIntegrationHandler = this.updateJiraIntegrationHandler.bind(this);
  }

  testJiraConnection() {
    this.setState({ waitForAPI: true });

    this.props.testJira(this.props.orgIntegration.id, getJiraTestedFlag(this.props.orgIntegration)).then(r => {
      this.setState({ waitForAPI: false });
    });
  }

  clearPermissionsValidationDialogData() {
    this.setState({
      validatePermissionsDialogOpen: false,
      messageActionName: '',
      onConfirm: null,
      jiraIntegrationUpdateData: {},
    });
  }

  validatePermissions(action, actionName, payload = {}, confirmationMessage = null) {
    this.setState({
      onConfirm: action,
      messageActionName: actionName,
      validatePermissionsDialogOpen: true,
      jiraIntegrationUpdateData: payload,
      confirmationMessage,
    });
  }

  registerJiraWebhookHandler() {
    this.setState({ waitForAPI: true });

    this.props
      .registerJiraWebhook(this.props.orgIntegration.id)
      .then(data => {
        if (hasNoError(data)) {
          toast(<InfoToast>Webhooks enabled successfully.</InfoToast>, {
            toastId: 'JIRA_WEBHOOKS_ENABLED',
          });
        }
      })
      .finally(() => {
        this.setState({ waitForAPI: false });
      });
  }

  updateJiraIntegrationHandler() {
    this.props.updateJiraIntegration(this.props.orgIntegration.id, this.state.jiraIntegrationUpdateData);
  }

  unregisterJiraWebhookHandler() {
    this.setState({ waitForAPI: true });

    this.props
      .unregisterJiraWebhook(this.props.orgIntegration.id)
      .then(data => {
        if (hasNoError(data)) {
          toast(<InfoToast>Webhooks disabled successfully.</InfoToast>, {
            toastId: 'JIRA_WEBHOOKS_DISABLED',
          });
        }
      })
      .finally(() => {
        this.setState({ waitForAPI: false });
      });
  }

  isTestJiraConnectionDisabled = () => {
    const { currentUser } = this.props;

    return [SUPER_ADMIN_USER].includes(currentUser.role_id) || currentUser.isSuperAdmin;
  };

  mergeProgressTrackingMapping = (currentProgressTracking, progressTracking) => {
    const mergeResult = merge(currentProgressTracking, progressTracking);

    const update = Object.entries(mergeResult).reduce((acc, [key, value]) => {
      if (value === null) {
        return acc;
      }

      acc[key] = value;
      return acc;
    }, {});

    return { progress_tracking: update };
  };

  render() {
    const { orgIntegration, jiraConfig, userCanUpdateOrgIntegration } = this.props;

    const { waitForAPI, defaultPanels, triggerImportJQLLightbox, projectKey, newMapping } = this.state;
    const disconnect = this.props.disconnet || this.props.disconnectJira;
    const integrationData = (orgIntegration && orgIntegration.data) || {};
    const progressTracking = integrationData.progress_tracking || {};
    const progressTrackingMapping = progressTracking[projectKey] || {};

    if (this.props.justIntegrated && triggerImportJQLLightbox) {
      this.props.showImportJQLLightbox();
      this.setState({ triggerImportJQLLightbox: false });
    }

    // TODO - avoid proving new array ref as fallback every re-render
    const integrationMapping = integrationData?.field_mapping || [];
    const integrationSettings = {
      last_edit_mapping_day: jiraConfig.last_edit_mapping_day,
      last_edit_mapping_by: jiraConfig.last_edit_mapping_by,
      last_sync_day: jiraConfig.last_sync_day,
      add_idea_number_to_jira: jiraConfig.add_idea_number_to_jira,
    };

    return (
      <Wrapper>
        <Progress>{waitForAPI && <LinearProgress />}</Progress>
        <JiraSettings
          orgIntegrationId={orgIntegration.id}
          integrationMapping={integrationMapping}
          integrationSettings={integrationSettings}
          userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
        />
        <OtherJiraSettings
          orgIntegrationId={orgIntegration.id}
          integrationSettings={integrationSettings}
          userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
        />
        <CollapsePanel
          title={
            <Tooltip enterDelay={200} title="Create custom progress tracking by entering 1 JIRA project at a time.">
              <TextDeprecated size="medium">
                Progress tracking setting
                <HelpLink
                  href="https://dragonboat.zendesk.com/hc/en-us/articles/360041999874"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InlineIcon>
                    <HelpIcon />
                  </InlineIcon>
                </HelpLink>
              </TextDeprecated>
            </Tooltip>
          }
          defaultExpanded={defaultPanels.includes('progress')}
        >
          <PanelContent style={{ display: 'flex' }}>
            <VerticalTabs>
              <div style={{ display: 'flex', width: '100%' }}>
                <LeftAlignedButton
                  color={projectKey === 'DEFAULT' && 'primary'}
                  onClick={() => this.setState({ projectKey: 'DEFAULT' })}
                >
                  Default
                </LeftAlignedButton>
              </div>
              {progressTracking &&
                Object.keys(progressTracking)
                  .filter(key => key !== 'DEFAULT')
                  .map(key => (
                    <div style={{ display: 'flex', width: '100%' }}>
                      <Tooltip title={key} enterDelay={250}>
                        <LeftAlignedButton
                          color={projectKey === key && 'primary'}
                          onClick={() => this.setState({ projectKey: key })}
                        >
                          {key}
                        </LeftAlignedButton>
                      </Tooltip>
                      {projectKey === key && (
                        <IconButton
                          id="delete-mapping-button"
                          size="small"
                          onClick={() => this.setState({ confirmDeleteMappingOpened: true })}
                          style={{ width: 36, height: 36 }}
                          disabled={!userCanUpdateOrgIntegration}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                    </div>
                  ))}
              {newMapping !== null ? (
                <TextField
                  label="Project key"
                  autoFocus
                  onChange={e => this.setState({ newMapping: e.target.value })}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      this.props.updateJiraIntegration(
                        orgIntegration.id,
                        this.mergeProgressTrackingMapping(progressTracking, { [newMapping.toUpperCase()]: {} }),
                      );
                      this.setState({ newMapping: null, projectKey: newMapping.toUpperCase() });
                    }
                  }}
                  onBlur={() => this.setState({ newMapping: null })}
                />
              ) : (
                <Button
                  id="add-mapping-button"
                  color="primary"
                  disabled={!userCanUpdateOrgIntegration}
                  onClick={() => this.setState({ newMapping: '' })}
                >
                  <AddIcon />
                </Button>
              )}
            </VerticalTabs>
            <ConfirmDialog
              title="Delete mapping configuration"
              isOpen={this.state.confirmDeleteMappingOpened}
              onCancel={() => this.setState({ confirmDeleteMappingOpened: false })}
              onConfirm={() => {
                this.props.updateJiraIntegration(
                  orgIntegration.id,
                  this.mergeProgressTrackingMapping(progressTracking, { [projectKey]: null }),
                );
                this.setState({ confirmDeleteMappingOpened: false, projectKey: 'DEFAULT' });
              }}
            />
            <div>
              <PreferenceGroup>
                <FormLabel control="legend">Track progress by</FormLabel>
                <Preference row>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          progressTrackingMapping.track_progress_by === ISSUE_COUNT_PROGRESS ||
                          !progressTrackingMapping.track_progress_by
                        }
                        color="primary"
                        onChange={() =>
                          this.props.updateJiraIntegration(
                            orgIntegration.id,
                            this.mergeProgressTrackingMapping(progressTracking, {
                              [projectKey]: { track_progress_by: ISSUE_COUNT_PROGRESS },
                            }),
                          )
                        }
                      />
                    }
                    disabled={!userCanUpdateOrgIntegration}
                    label="Issue count"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={progressTrackingMapping.track_progress_by === STORY_POINTS_PROGRESS}
                        color="primary"
                        onChange={() =>
                          this.props.updateJiraIntegration(
                            orgIntegration.id,
                            this.mergeProgressTrackingMapping(progressTracking, {
                              [projectKey]: { track_progress_by: STORY_POINTS_PROGRESS },
                            }),
                          )
                        }
                      />
                    }
                    disabled={!userCanUpdateOrgIntegration}
                    label="Story Points"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={progressTrackingMapping.track_progress_by === TIME_ESTIMATES_PROGRESS}
                        color="primary"
                        onChange={() =>
                          this.props.updateJiraIntegration(
                            orgIntegration.id,
                            this.mergeProgressTrackingMapping(progressTracking, {
                              [projectKey]: { track_progress_by: TIME_ESTIMATES_PROGRESS },
                            }),
                          )
                        }
                      />
                    }
                    disabled={!userCanUpdateOrgIntegration}
                    label="Time Tracking"
                  />
                </Preference>
              </PreferenceGroup>
              {(progressTrackingMapping.track_progress_by === ISSUE_COUNT_PROGRESS ||
                !progressTrackingMapping.track_progress_by) && (
                <PreferenceGroup>
                  <InlineInfo>
                    <span>Display</span>&nbsp;
                    <InlineTextField
                      value={
                        progressTrackingMapping.display_days_per_issue === undefined
                          ? 1
                          : progressTrackingMapping.display_days_per_issue
                      }
                      onChange={({ target }) =>
                        this.props.updateJiraIntegration(
                          orgIntegration.id,
                          this.mergeProgressTrackingMapping(progressTracking, {
                            [projectKey]: { display_days_per_issue: target.value },
                          }),
                        )
                      }
                      disabled={!userCanUpdateOrgIntegration}
                    />
                    <span>day per issue on the Timeline page</span>
                  </InlineInfo>
                </PreferenceGroup>
              )}
              {progressTrackingMapping.track_progress_by === STORY_POINTS_PROGRESS && (
                <PreferenceGroup>
                  <InlineInfo>
                    <span>Default Story Points</span>&nbsp;
                    <InlineTextField
                      value={
                        progressTrackingMapping.default_story_points === undefined
                          ? 2
                          : progressTrackingMapping.default_story_points
                      }
                      onChange={({ target }) =>
                        this.props.updateJiraIntegration(
                          orgIntegration.id,
                          this.mergeProgressTrackingMapping(progressTracking, {
                            [projectKey]: { default_story_points: target.value },
                          }),
                        )
                      }
                      disabled={!userCanUpdateOrgIntegration}
                    />
                  </InlineInfo>
                  <InlineInfo>
                    <span>Display</span>&nbsp;
                    <InlineTextField
                      value={
                        progressTrackingMapping.display_storypoint_per_day === undefined
                          ? 1
                          : progressTrackingMapping.display_storypoint_per_day
                      }
                      onChange={({ target }) =>
                        this.props.updateJiraIntegration(
                          orgIntegration.id,
                          this.mergeProgressTrackingMapping(progressTracking, {
                            [projectKey]: { display_storypoint_per_day: target.value },
                          }),
                        )
                      }
                      disabled={!userCanUpdateOrgIntegration}
                    />
                    <span>day per Story Point on the Timeline page </span>
                  </InlineInfo>
                </PreferenceGroup>
              )}
              {progressTrackingMapping.track_progress_by === TIME_ESTIMATES_PROGRESS && (
                <PreferenceGroup>
                  <InlineInfo>
                    <span>Default Time Estimates</span>&nbsp;
                    <InlineTextField
                      value={this.state.defaultTimeEstimate}
                      width="100px"
                      placeholder="1w 1d 1h 1m"
                      onChange={({ target }) => {
                        this.setState({ defaultTimeEstimate: target.value });

                        this.props.updateJiraIntegration(
                          orgIntegration.id,
                          this.mergeProgressTrackingMapping(progressTracking, {
                            [projectKey]: { default_time_estimate: converStringTimeToSeconds(target.value) },
                          }),
                        );
                      }}
                      disabled={!userCanUpdateOrgIntegration}
                    />
                  </InlineInfo>
                </PreferenceGroup>
              )}
              <br />
              <br />
              <FormControlLabelContainer
                control={
                  <Switch
                    checked={progressTrackingMapping.exclude_bugs_progress}
                    onChange={() =>
                      this.props.updateJiraIntegration(
                        orgIntegration.id,
                        this.mergeProgressTrackingMapping(progressTracking, {
                          [projectKey]: { exclude_bugs_progress: !progressTrackingMapping.exclude_bugs_progress },
                        }),
                      )
                    }
                  />
                }
                disabled={!userCanUpdateOrgIntegration}
                label="Exclude bugs from progress calculation"
              />
            </div>
          </PanelContent>
        </CollapsePanel>
        <CollapsePanel
          title={<TextDeprecated size="medium">One time and Recurring Import setting</TextDeprecated>}
          defaultExpanded={defaultPanels.includes('import')}
        >
          <PanelContent>
            <ButtonRow>
              <Button
                id="import-jira-jql-button"
                color="primary"
                onClick={() => this.props.showImportJQLLightbox()}
                disabled={!userCanUpdateOrgIntegration}
              >
                One time import using Jira search (JQL)
              </Button>
            </ButtonRow>
            <br />
            <br />
            <br />
            <FormControlLabelContainer
              control={
                <Switch
                  checked={orgIntegration && orgIntegration.data ? orgIntegration.data.auto_import_jql : false}
                  onChange={e => {
                    this.validatePermissions(
                      this.updateJiraIntegrationHandler,
                      !e.target.checked ? ENABLE_JIRA_RECURRING_IMPORT : DISABLE_JIRA_RECURRING_IMPORT,
                      { auto_import_jql: e.target.checked },
                    );
                  }}
                />
              }
              disabled={!userCanUpdateOrgIntegration}
              label={
                <span>
                  Recurring auto import Jira EPICs
                  <HelpLink
                    href="https://dragonboat.zendesk.com/hc/en-us/articles/360034737694"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InlineIcon>
                      <HelpIcon />
                    </InlineIcon>
                  </HelpLink>
                </span>
              }
            />
            {orgIntegration && orgIntegration.data && orgIntegration.data.auto_import_jql && (
              <Fragment>
                <ButtonRow>
                  <Button
                    id="recurring-import-query-button"
                    color="primary"
                    onClick={() => this.setState({ reviewingRecurringImportQuery: true })}
                    disabled={!userCanUpdateOrgIntegration}
                  >
                    {orgIntegration.data && orgIntegration.data.recurring_jql_query ? 'Review' : 'Create'} Recurring Import Query
                  </Button>
                  <RecurringJQLReviewModal
                    isOpen={this.state.reviewingRecurringImportQuery}
                    onClose={() => this.setState({ reviewingRecurringImportQuery: false })}
                    jiraConfig={this.props.jiraConfig}
                    recurringJQLQuery={orgIntegration.data && orgIntegration.data.recurring_jql_query}
                    orgIntegration={orgIntegration}
                  />
                </ButtonRow>
                <ButtonRow>
                  <Button
                    id="run-recurring-import-button"
                    color="primary"
                    disabled={
                      !userCanUpdateOrgIntegration ||
                      !orgIntegration.data ||
                      !orgIntegration.data.recurring_jql_query ||
                      this.state.runningRecurringJQLQueryImport
                    }
                    onClick={() => {
                      this.setState({
                        runningRecurringJQLQueryImport: true,
                      });

                      this.props
                        .importUsingRecurringJQLQuery(orgIntegration.id)
                        .then(() => {
                          this.setState({
                            runningRecurringJQLQueryImport: false,
                          });
                        })
                        .catch(err => {
                          console.log({ err });
                          this.setState({
                            runningRecurringJQLQueryImport: false,
                          });
                        });
                    }}
                  >
                    Run the recurring import now
                  </Button>
                  {orgIntegration.data && orgIntegration.data.last_recurring_import && (
                    <SmallText>
                      (Last Import: {formatDateTime(getDateForFormatting(orgIntegration.data.last_recurring_import))})
                    </SmallText>
                  )}
                </ButtonRow>
              </Fragment>
            )}
          </PanelContent>
        </CollapsePanel>
        <CollapsePanel
          title={<TextDeprecated size="medium">Jira Connection</TextDeprecated>}
          defaultExpanded={defaultPanels.includes('connection')}
        >
          <PanelContent>
            <Info>Your dragonboat workspace has been linked to your Jira instance.</Info>
            <ButtonRow style={{ marginTop: '30px' }}>
              <Button color="primary" disabled={this.isTestJiraConnectionDisabled()} onClick={this.testJiraConnection}>
                Test Jira Connection
              </Button>
            </ButtonRow>
            <ButtonRow>
              <Button
                color="secondary"
                onClick={() => this.setState({ confirmDialogOpened: true })}
                disabled={!userCanUpdateOrgIntegration}
              >
                Disconnect From Jira
              </Button>
              <Note>(This will disconnect the connection between Jira and your entire workspace)</Note>
            </ButtonRow>
          </PanelContent>
        </CollapsePanel>
        {this.props.organization.has_webhooks && (
          <CollapsePanel
            title={<TextDeprecated size="medium">Configure Webhook</TextDeprecated>}
            defaultExpanded={defaultPanels.includes('webhook')}
          >
            <PanelContent>
              <JiraWebhooks
                orgIntegration={orgIntegration}
                registerWebhook={() => this.validatePermissions(this.registerJiraWebhookHandler, ENABLE_JIRA_WEBHOOKS)}
                userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
                unregisterWebhook={() =>
                  this.validatePermissions(
                    this.unregisterJiraWebhookHandler,
                    DISABLE_JIRA_WEBHOOKS,
                    {},
                    'Turn off webhooks and use daily update instead?',
                  )
                }
              />
            </PanelContent>
          </CollapsePanel>
        )}
        <ImportIdeasDialog />
        <ConfirmDialog
          title="Disconnect From Jira"
          text={
            <div>
              <p>Disconnecting Jira will disable Jira linking and update for your entire Workspace. Do you want to continue?</p>
              <br />
              <p>You may reconnect following the Jira integration step in the future.</p>
            </div>
          }
          isOpen={this.state.confirmDialogOpened}
          onCancel={() => this.setState({ confirmDialogOpened: false })}
          onConfirm={() => disconnect(orgIntegration.id)}
        />
        <IntegrationPermissionsDialog
          isDialogVisible={this.state.validatePermissionsDialogOpen}
          integrationType="Jira"
          messageActionName={this.state.messageActionName}
          onCancel={() => this.clearPermissionsValidationDialogData()}
          onConfirm={() => this.state.onConfirm()}
          onClose={() => this.clearPermissionsValidationDialogData()}
          orgIntegrationId={orgIntegration.id}
          confirmationMessage={this.state.confirmationMessage}
        />

        {this.props.goBack && (
          <Button color="primary" onClick={this.props.goBack}>
            Back
          </Button>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state, props) => {
  const {
    organization: { organization },
  } = state;

  return {
    organization,
    customFields: getProjectsCustomFields(state),
    systemFields: organization.system_fields_name,
    currentUser: state.login.currentUser,
    users: state.users.rows || [],
  };
};

export default compose(
  connect(mapStateToProps, {
    disconnectJira,
    updateJiraConfigMisc,
    testJira,
    showImportIdeasLightbox,
    showImportJQLLightbox,
    fetchJiraFields,
    syncOrgProjectsFromJira,
    updateJiraIntegration,
    searchForTasksByJiraUrl,
    previewTasksByJiraUrl,
    importUsingRecurringJQLQuery,
    registerJiraWebhook,
    unregisterJiraWebhook,
  }),
  withRouter,
)(ConfigDetails);

const Wrapper = styled.div``;

const Info = styled.div`
  margin: 0 8px 32px;
`;

const InlineInfo = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
`;

const ButtonRow = styled.div`
  padding-bottom: 5px;
`;

const Note = styled.p`
  font-size: 12px;
  margin-left: 8px;
  margin-top: 3px;
  color: #b3b3b3;
`;

const Progress = styled.div`
  height: 6px;
  width: 100%;
`;

const FormControlLabelContainer = styled(FormControlLabel)`
  margin-top: -10px;
  padding-left: 5px;
`;

const PanelContent = styled.div`
  width: 100%;
  color: ${textColor};
  padding-bottom: 16px;
`;

const PreferenceGroup = styled.div`
  margin: 12px 0;
`;

const Preference = styled(FormGroup)`
  &&&& {
    margin: 0 6px;
  }
`;

const InlineTextField = styled(TextField)`
  input {
    width: ${({ width }) => width || '50px'};
    text-align: center;
    padding: 4px 0;
    font-size: 14px;
  }
`;

const VerticalTabs = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid #ccc;
`;

const LeftAlignedButton = styled(Button)`
  flex-grow: 1;
  && {
    color: ${props => !props.color && '#6b6b6b'};
  }
  & > span {
    text-align: left;
    justify-content: normal;
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
