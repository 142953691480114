import moment from 'moment-timezone';

import { getTooltipText, calculateGridWidth } from 'components/ganttCommon';
import { INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import { EXCLUDE_UNCOMMITTED_OPTION, INCLUDE_ALL_OPTION } from 'constants/projects';
import { materialColorsAlt } from 'design-system/themes/default';

const manageEvents = {};

const _shoulHidePlusButton = (task, options) => {
  const { disableGantt, showStories, showTasks, showEstimates, portfolioMode } = options;
  const conditions = [
    task.dbType === 'estimate',
    task.dbType === 'task',
    disableGantt,
    showStories,
    !showTasks && !showEstimates && !portfolioMode,
    portfolioMode && ![INITIATIVE_LAYER, BET_LAYER].includes(task.layer),
    task.dbType === 'project' && ['Completed'].includes(task.planningStage),
    ['bet', 'initiative'].includes(task.dbType),
  ];

  return conditions.includes(true);
};

/**
 * Hook to set the Timeline configurations for gantt
 *
 * @returns update configs function
 */
const setTimelineGanttConfigs = (gantt, lsState, columns, disableGantt = false, tasks = undefined, disableLinks = false) => {
  const {
    showTooltip,
    showGroupedTimeline,
    showStories,
    zoom,
    localMode,
    hideDependencyLines,
    sort: columnSort,
    portfolioMode,
    showUncommittedProjects = INCLUDE_ALL_OPTION,
  } = lsState;

  const displayDragLinks = !hideDependencyLines && !disableLinks;

  Object.keys(manageEvents).forEach(event => {
    gantt.detachEvent(manageEvents[event]);
  });

  manageEvents.onBeforeTaskMove = gantt.attachEvent('onBeforeTaskMove', (id, parent, tindex) => {
    return !disableGantt && !showStories;
  });
  manageEvents.onBeforeTaskDrag = gantt.attachEvent('onBeforeTaskDrag', (id, parent, tindex) => {
    const ganttTask = gantt.getTask(id);
    const { integrationProgress } = ganttTask;
    const isInitiativeOrBet = [INITIATIVE_LAYER, BET_LAYER].includes(ganttTask.layer);

    // On grouped timeline should not allow to drag bets and initiatives
    if (showGroupedTimeline && isInitiativeOrBet && portfolioMode && ganttTask.type === 'project') return false;

    if (showStories && integrationProgress && integrationProgress.issuesTotal > 0) return false;

    return true;
  });

  gantt.config.min_column_width = !zoom ? 25 : 70;
  gantt.config.show_errors = false;
  gantt.config.show_progress = true;
  gantt.config.round_dnd_dates = false;
  gantt.config.order_branch = true;
  gantt.config.order_branch_free = true;
  gantt.config.sort = true;
  gantt.config.undo = true;
  gantt.config.undo_steps = 5;
  gantt.config.branch_loading = true;
  gantt.config.smart_scales = true;
  gantt.config.drag_links = displayDragLinks;

  gantt.config.columns = columns;
  gantt.config.grid_width = columns.map(c => c.width).reduce((a, b) => a + b, 0);

  if (columnSort) {
    for (let i = 0; i < gantt.config.columns.length; i++) {
      if (gantt.config.columns[i].name === columnSort.field) {
        const direction = columnSort.direction ? 'gantt_desc' : 'gantt_asc';

        // eslint-disable-next-line max-len
        gantt.config.columns[i].label = `${gantt.config.columns[i].label} <div class="${
          gantt.config.columns[i].sort !== false ? 'gantt_sort' : ''
        } ${direction}"></div>`;
      }
    }
  }

  gantt.config.drag_project = !disableGantt;
  gantt.config.drag_progress = !disableGantt;
  gantt.config.drag_resize = !disableGantt;
  gantt.config.drag_move = !disableGantt;
  gantt.config.layout = {
    css: 'gantt_container',
    rows: [
      {
        cols: [
          {
            view: 'grid',
            id: 'grid',
            scrollY: 'scrollVer',
            width: calculateGridWidth(gantt),
          },
          { resizer: true, width: 1 },
          {
            view: 'timeline',
            id: 'timeline',
            scrollX: 'scrollHor',
            scrollY: 'scrollVer',
          },
          { view: 'scrollbar', scroll: 'y', id: 'scrollVer' },
        ],
        gravity: 2,
      },
      { resizer: true, width: 1, mode: 'y' },
      { view: 'scrollbar', scroll: 'x', id: 'scrollHor' },
    ],
  };

  gantt.templates.grid_header_class = (columnName, column) => {
    if (localMode) {
      return 'noPlus';
    }
  };
  gantt.templates.rightside_text = (start, end, task) => (task.dbType === 'story' ? task.text : '');
  gantt.templates.drag_link_class = () => 'gantt_tooltip_hidden';
  gantt.templates.task_text = (start, end, task) => task.shortText || task.text;
  gantt.templates.tooltip_text = showTooltip ? getTooltipText : () => '';
  gantt.templates.task_row_class = (start, end, task) => {
    if (task.group && task.$level === 0 && !showGroupedTimeline) {
      return 'ganttGroupRow';
    }
  };
  gantt.templates.grid_row_class = (start, end, task) => {
    const isGrouping = true;
    let classes = `dbType--${task.dbType} stage--${task.planningStage} `;
    const children = gantt.getChildren(task.id);

    if (task.$has_child > 0 || (children.length && !task.group)) {
      classes += 'boldedParent ';
    }
    if (_shoulHidePlusButton(task, { ...lsState, disableGantt })) {
      classes += 'noPlus ';
    }
    if (task.$level > (isGrouping ? 4 : 3)) {
      classes += 'subtask ';
    }
    if (task.type === 'milestone') {
      classes += 'milestone ';
    }
    if (task.status_color) {
      classes += `ganttStatus-${task.status_color} `;
    }
    if (task.group && task.$level === 0) {
      classes += 'ganttGroupGridRow--timeline ';
    }
    if (task.group && task.$level > 0) {
      classes += 'ganttGroup2GridRow ';
    }
    if (task.group && task.$level === 0 && !showGroupedTimeline) {
      classes += 'ganttGroupGridRow--timeline--bordered ';
    }
    if (task.resourceRow && task.parent === 0) {
      classes += 'ganttResourceParentRow ';
    }
    const includeUncommitted = showUncommittedProjects !== EXCLUDE_UNCOMMITTED_OPTION;

    if (includeUncommitted && !task.$committed) {
      classes += 'notCommittedIncluded';
    }

    return classes;
  };
  gantt.templates.task_class = (start, end, task) => {
    let classes = `gantt_task_${task.dbType} `;
    const links = tasks && tasks.links;

    if (task.dbType === 'project') classes += `stage${task.planningStage}`;
    if (task.group) classes += 'gantt_group';
    if (task.format === 'circle') classes += 'gantt_circle_task_format';
    if (task.$overlap) return 'overlap';
    if (task.group && task.type === 'project' && !showGroupedTimeline) {
      return 'hideGroupTask';
    }

    if (hideDependencyLines && links && links.filter(l => l.target === task.id).length) {
      const color = links.filter(l => l.target === task.id && l.color === materialColorsAlt.red).length > 0 ? 'red' : 'green';

      classes += ` dependenciesBefore dep-before-${color}`;
    }
    if (hideDependencyLines && links && links.filter(l => l.source === task.id).length) {
      const color = links.filter(l => l.source === task.id && l.color === materialColorsAlt.red).length > 0 ? 'red' : 'green';

      classes += ` dependenciesAfter dep-after-${color}`;
    }

    return classes;
  };
  gantt.templates.link_class = link => {
    const source = gantt.getTask(link.source);
    const target = gantt.getTask(link.target);

    const sourceEnd = source.end_date
      ? moment(source.end_date)
      : moment(source.start_date).addDuration(source.duration || 0, 'days');

    return moment(target.start_date).add(0.5, 'days').isSameOrAfter(sourceEnd) ? 'link_green' : 'link_red';
  };
};

export default setTimelineGanttConfigs;
