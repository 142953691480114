import { useCallback, useEffect, useState } from 'react';

import { differenceBetweenDatesByUnit, isInsideVisibleArea, VIEW_MODE_RELATIONS } from '../helpers';

/**
 * Custom hook that deals with the scroll behavior of the timeline.
 * */
const useTimelineScroll = ({ contentRef, fromDate, toDate, zoomMode, updateDateRange, shouldScrollToToday, internalOrders }) => {
  const hasVerticalScroll = contentRef?.current && contentRef.current.scrollHeight > contentRef.current.clientHeight;

  const [scrollCoordinates, setScrollCoordinates] = useState({
    clientWidth: 0,
    scrollWidth: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });

  const shouldShowItem = useCallback(item => isInsideVisibleArea(item, scrollCoordinates), [scrollCoordinates]);

  const handleScroll = event => {
    const relationMode = VIEW_MODE_RELATIONS[zoomMode];

    const left = event.target.scrollLeft;
    const { scrollWidth, clientWidth, scrollTop } = contentRef.current;

    setScrollCoordinates({
      clientWidth,
      scrollWidth,
      scrollTop,
      scrollLeft: left,
    });

    // Update only fromDate or toDate

    const isLeftScroll = left === 0;

    if (isLeftScroll) {
      const newDate = fromDate.clone().subtract(1, relationMode).startOf(relationMode).startOf(zoomMode);

      if (newDate.date() !== 1) {
        newDate.add(1, zoomMode);
      }

      const distance = differenceBetweenDatesByUnit(newDate, fromDate);

      updateDateRange(newDate, undefined);

      contentRef.current.scrollLeft = distance;

      // should not automatically scroll to today line under these cirscunstances
      shouldScrollToToday.current = false;

      return;
    }

    const isRightScroll = left + clientWidth === scrollWidth;

    if (isRightScroll) {
      const newDate = toDate.clone().add(1, relationMode).endOf(relationMode);

      updateDateRange(undefined, newDate);
    }
  };

  useEffect(() => {
    if (contentRef?.current) {
      setScrollCoordinates({
        clientWidth: contentRef.current.clientWidth,
        scrollLeft: contentRef.current.scrollLeft,
        scrollWidth: contentRef.current.scrollWidth,
        scrollTop: contentRef.current.scrollTop,
      });
    }
  }, [contentRef?.current]);

  useEffect(() => {
    if (contentRef?.current) {
      // Preserve the previous vertical scroll
      contentRef.current.scrollTop = scrollCoordinates.scrollTop;
    }
  }, [contentRef?.current, internalOrders?.length]);

  return { hasVerticalScroll, scrollCoordinates, shouldShowItem, handleScroll };
};

export default useTimelineScroll;
