/* eslint-disable */
import React from 'react';

export default () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.2317 7.62756L13.2317 6.23173L7.69791 6.23173L7.69791 0.697914H6.30209L6.30209 6.23173L0.768268 6.23173L0.768268 7.62756L6.30209 7.62756L6.30209 13.1614H7.69791L7.69791 7.62756L13.2317 7.62756Z"
      fill="#2EA8E1"
    />
  </svg>
);
