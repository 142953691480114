import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import { CUSTOM_FIELDS } from 'store/grids/constants';
import { updateSystemFieldsName as updateSystemFieldsNameAction } from 'store/organization';

import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import useForceVisibleColumns from 'hooks/useForceVisibleColumns';
import useCustomFields from './hooks/useCustomFields';
import useCustomFieldsGridColumns from './hooks/useCustomFieldsGridColumns';
import useCustomFieldsGridEventHandlers from './hooks/useCustomFieldsGridEventHandlers';

import {
  useSettingsActions,
  useSettingsGridSelection,
  useSettingsGridColumns,
  useSettingsGridProps,
} from 'containers/Grids/SettingsGrid/hooks';
import SettingsActionsToolbar from 'components/SettingsActionsToolbar';
import SettingsDialogs from 'components/SettingsDialogs';
import { BaseGrid } from 'containers/Grids';
import FormulaLightbox from 'containers/FormulaLightbox';
import SettingsToggle from 'design-system/atoms/SettingsToggle';

import { CUSTOM_FIELD_KEY } from './helpers/constants';

const CustomFields = ({ showActionBar = true, forceVisibleFields, customHeight }) => {
  const {
    customFields,
    createCustomField,
    handleAddRow,
    removeUnsavedCustomFields,
    updateCustomFieldById,
    deleteCustomField,
    systemFields,
    createCustomFieldRoadmap,
    deleteCustomFieldRoadmap,
    bulkDeleteCustomFieldRoadmaps,
    hideMetadataRoadmaps,
    openFormula,
    setOpenFormula,
    updateFormula,
    formulaOptions,
    onLinkClickFormula,
    bulkCreateCustomFields,
    bulkUpdateCustomFields,
    searchText,
    updateSearchText,
    hideArchived,
    toggleHideArchived,
  } = useCustomFields();
  const dispatch = useDispatch();

  const updateSystemFieldsName = useCallback(
    fieldRename => dispatch(updateSystemFieldsNameAction(fieldRename)),
    [updateSystemFieldsNameAction],
  );

  const { canView, canUpdate, canCreate } = usePermissions();
  const canUpdateOrCreate = canCreate(PERMISSION_RESOURCES.customField);

  const permissions = {
    allowActions: canUpdateOrCreate,
    canAdd: canCreate(PERMISSION_RESOURCES.customField),
    canUpdate: customField => canUpdate(PERMISSION_RESOURCES.customField, { customField }),
  };

  // Specific column definition for Custom Fields
  const { columnDefs: customFieldsGridColumnDefs } = useCustomFieldsGridColumns({
    roadmapColumn: {
      customFields,
      createCustomFieldRoadmap,
      deleteCustomFieldRoadmap,
      bulkDeleteCustomFieldRoadmaps,
      hideMetadataRoadmaps,
    },
    canCreate: permissions.canAdd,
    canUpdate: permissions.canUpdate,
    onLinkClickFormula,
    removeUnsavedCustomFields,
    updateCustomFieldById,
    hasAvailableInColumn: canView(PERMISSION_FEATURES.requestDetailsFieldsLayout),
    hasAppliesToColumn: canView(PERMISSION_FEATURES.customFieldAppliesTo),
    hasColorColumn: canView(PERMISSION_FEATURES.customFieldColor),
  });

  const settingsGridProps = useSettingsGridProps({
    bulkCreate: bulkCreateCustomFields,
    bulkUpdate: bulkUpdateCustomFields,
  });

  // Settings common agGrid configuration
  const { selectionMode, selectedItems, enableSelectionMode, disableSelectionMode, updateSelectedOnAgGridInit } =
    useSettingsGridSelection(CUSTOM_FIELDS, {
      actions: { remove: removeUnsavedCustomFields },
    });

  const { itemToDelete, setItemToDelete, deleteItem, deleteProgress } = useSettingsActions({
    settingType: CUSTOM_FIELDS,
    selectedItems,
    disableSelectionMode,
    deleteById: deleteCustomField,
  });

  const settingsGridColumns = useSettingsGridColumns({
    allowActions: permissions.allowActions,
    canUpdate: permissions.canUpdate,
    selectionMode,
    setItemToDelete,
    items: customFields,
  });

  const gridEventHandlers = useCustomFieldsGridEventHandlers({
    customFields,
    updateCustomFieldById,
    createCustomField,
    removeUnsavedCustomFields,
  });

  const visibleColumns = useForceVisibleColumns(customFieldsGridColumnDefs, forceVisibleFields);

  // Join settings and customFields column definitions
  const columnDefs = useMemo(
    () => [...settingsGridColumns.beforeColumnDefs, ...visibleColumns, ...settingsGridColumns.afterColumnDefs],
    [settingsGridColumns, visibleColumns],
  );

  const renderArchivedToggle = () => (
    <SettingsToggle isChecked={hideArchived} onToggle={toggleHideArchived} label="Hide archived" />
  );

  return (
    <>
      {permissions.allowActions && (
        <SettingsActionsToolbar
          settingKey={CUSTOM_FIELD_KEY}
          settingType={CUSTOM_FIELDS}
          systemFields={systemFields}
          updateSystemFieldsName={updateSystemFieldsName}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          onEnableSelection={enableSelectionMode}
          onCancelSelection={disableSelectionMode}
          add={handleAddRow}
          hideAddButton={!permissions.canAdd}
          searchText={searchText}
          updateSearchText={updateSearchText}
          additionalActions={renderArchivedToggle()}
        />
      )}
      <BaseGrid
        rowData={customFields}
        columnDefs={columnDefs}
        {...settingsGridProps}
        {...gridEventHandlers}
        onGridReady={updateSelectedOnAgGridInit}
        defaultColDef={settingsGridColumns.defaultColDef}
        height={customHeight ?? settingsGridProps.height}
      />
      <SettingsDialogs
        settingKey={CUSTOM_FIELD_KEY}
        settingType={CUSTOM_FIELDS}
        deleteProgress={deleteProgress}
        itemToDelete={itemToDelete}
        setItemToDelete={setItemToDelete}
        deleteItem={deleteItem}
        selectedItems={selectedItems}
        disableSelectionMode={disableSelectionMode}
      />
      <FormulaLightbox
        fieldTitle={openFormula?.title}
        isOpen={!!openFormula}
        onClose={() => setOpenFormula(null)}
        onSave={updateFormula}
        currentValue={openFormula?.value || ''}
        options={formulaOptions?.map(f => f.title) || []}
        editable={openFormula && permissions.canUpdate(openFormula)}
      />
    </>
  );
};

export default CustomFields;
