import React from 'react';
import styled from 'styled-components';
import { not } from 'ramda';
import { useSelector } from 'react-redux';

import { INTEGRATIONS_ICON_MAPPER } from 'constants/integrations';
import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/userViews';
import { IDEAS_BOARD_PAGE, IDEAS_ESTIMATES_PAGE, IDEAS_GRID_PAGE, IDEAS_PDLC_PAGE, SUMMARY_PAGE } from 'constants/filters';
import { getLoadedPublicSharedViewData } from 'store/userViews/selectors';
import { ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PAGE } from 'constants/filters/pages';

const PAGES_WITH_INTEGRATION_KEY = [
  IDEAS_BOARD_PAGE,
  IDEAS_ESTIMATES_PAGE,
  IDEAS_GRID_PAGE,
  IDEAS_PDLC_PAGE,
  SUMMARY_PAGE,
  ROADMAP_HISTORY_COMPARE_SNAPS_LIST_PAGE,
];

/**
 * Check if the organization has multiple integrations
 *
 * @function _hasOrgMultipleIntegrations
 * @param  {Array} orgIntegrations
 * @return {Boolean}
 */
const _hasOrgMultipleIntegrations = orgIntegrations => {
  return orgIntegrations.length > 1;
};

const _pageHasIntegrationKey = publicPageId => {
  const currentPath = getCurrentPath();
  const pageId = publicPageId || getPageIdFromPath(currentPath);

  return PAGES_WITH_INTEGRATION_KEY.includes(pageId);
};

/**
 * Component to show the integration of a project
 *  - if organization only has one integration, show the integration key
 *  - if organization has multiple integrations, show the integration icon
 *  - if organization has no integration, show empty
 *
 * @param {Object} props
 * @param {Object} props.project
 * @param {Array} props.orgIntegrations
 * @param {Object} props.renderOptions
 */
export default ({ project, orgIntegrations = [], renderOptions = {}, field = 'integration' }) => {
  if (!project?.[field]) return <span />;

  const IconIntegration = INTEGRATIONS_ICON_MAPPER[project?.[field].type];
  const hasMultipleIntegrations = _hasOrgMultipleIntegrations(orgIntegrations);
  const publicSharedViewData = useSelector(getLoadedPublicSharedViewData);
  const pageShouldShowIntegrationIcon = not(_pageHasIntegrationKey(publicSharedViewData?.loadedView?.page));

  return (
    <Wrapper>
      <Link
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          if (project?.[field].url) {
            window.open(project?.[field].url, '_blank');
          }
        }}
        target="_blank"
        rel="noreferrer"
      >
        {hasMultipleIntegrations && pageShouldShowIntegrationIcon ? <IconIntegration /> : project?.[field].key}
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;

  svg {
    width: 16px !important;
    height: 16px !important;
  }
`;

const Link = styled.a`
  opacity: 1;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.5s ease-in-out;
  }
`;
