import React from 'react';
import styled from 'styled-components';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from 'design-system/molecules/SecondaryNavListItem/index';

import SettingGroup from './SettingGroup';

export default props => {
  const { routes, goTo, isActive, subMenuArgs, organization, user } = props;

  return (
    <Wrapper>
      <List subheader={<ListHeader component="div">&nbsp;</ListHeader>} className="list">
        {routes.errorLogs.visible(user.role_id, user, organization) && (
          <ListItem
            label="Integration Alerts"
            onClick={goTo(routes.errorLogs.path)}
            isActive={isActive(routes.errorLogs.path)}
            showArrow={false}
          />
        )}
        {routes.account.visible(user.role_id, user, organization) && (
          <ListItem
            label="Account Settings"
            onClick={goTo(routes.account.path)}
            isActive={isActive(routes.account.path)}
            showArrow={false}
          />
        )}
        <SettingGroup
          baseNodeText="Operating Model Data"
          childNodes={[
            routes.objectives,
            routes.metrics,
            routes.roadmaps,
            ...(routes.customerRequests.visible(user.role_id, user, organization) ? [routes.customerRequests] : []),
            routes.roadmapItems,
            routes.customers,
          ]}
          goTo={goTo}
          isActive={isActive}
          organization={organization}
          user={user}
        />
        <SettingGroup
          baseNodeText="Roadmap Attributes"
          childNodes={[
            routes.timeframes,
            routes.themes,
            routes.categories,
            routes.priorities,
            routes.phases,
            routes.pdlc,
            routes.tags,
            routes.customFields,
            routes.customUserFields,
          ]}
          goTo={goTo}
          isActive={isActive}
          organization={organization}
          user={user}
        />
        <SettingGroup
          baseNodeText="Resource setting"
          childNodes={[routes.teams, routes.skills]}
          goTo={goTo}
          isActive={isActive}
          organization={organization}
          user={user}
        />
        {routes.users.visible(user.role_id, user, organization) && (
          <ListItem label="Users" onClick={goTo(routes.users.path)} isActive={isActive(routes.users.path)} showArrow={false} />
        )}
        {routes.integrations.visible(user.role_id, user, organization) && (
          <SettingGroup
            baseNodeText={routes.integrations.label}
            childNodes={routes.integrations.subMenu(subMenuArgs)}
            goTo={goTo}
            isActive={isActive}
            subMenuArgs={subMenuArgs}
            user={user}
          />
        )}
        {routes.billing.visible(user.role_id, user, organization) && (
          <SettingGroup
            baseNodeText={routes.billing.label}
            childNodes={routes.billing.subMenu}
            goTo={goTo}
            isActive={isActive}
            subMenuArgs={subMenuArgs}
            user={user}
            organization={organization}
          />
          // <ListItem label="Billing" onClick={goTo(routes.billing.path)} isActive={isActive(routes.billing.path)} />
        )}
        {routes.dragonbot.visible(user.role_id, user, organization) && (
          <SettingGroup
            baseNodeText={routes.dragonbot.label}
            childNodes={routes.dragonbot.subMenu}
            goTo={goTo}
            isActive={isActive}
            subMenuArgs={subMenuArgs}
          />
        )}
      </List>
    </Wrapper>
  );
};

const ListHeader = styled(ListSubheader)`
  &&&& {
    padding-left: 26px;
    line-height: 32px;
  }
`;

const Wrapper = styled.div`
  width: 230px;

  position: relative;
  border-right: 1px solid ${({ theme }) => theme.palette.newLayout.background.lightBlack};

  &&&& {
    span {
      font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
      font-size: ${({ theme }) => theme.typography.fontSize}px;
      color: ${({ theme }) => theme.palette.newLayout.background.disable};
    }

    > .list {
      margin-left: 0;
      height: 100%;
      overflow-y: auto;
    }
  }
`;
