import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  isLoadingCreateProjectIntegration,
  getCreateProjectIntegrationError,
  createProjectIntegration,
} from 'store/integrations';

export default (projectId, orgIntegrationId, updateProject) => {
  const dispatch = useDispatch();

  const creating = useSelector(isLoadingCreateProjectIntegration);
  const error = useSelector(getCreateProjectIntegrationError);

  const _createProjectIntegration = useCallback(
    data => {
      if (creating) {
        return;
      }

      dispatch(createProjectIntegration(projectId, { ...data, org_integration_id: orgIntegrationId }, updateProject));
    },
    [projectId, orgIntegrationId, updateProject, creating],
  );

  return [_createProjectIntegration, creating, error];
};
