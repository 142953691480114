import stringComparator from './stringComparator';

const integrationComparator = (integrationA, integrationB, node1, node2, isInverted) => {
  const keyA = integrationA?.key;
  const keyB = integrationB?.key;

  return stringComparator(keyA, keyB, node1, node2, isInverted);
};

export default integrationComparator;
