import { schema, normalize } from 'normalizr';

import { add, remove } from 'utils/store/entities';

import {
  FETCH_PROJECT_INTEGRATIONS_FULFILLED,
  ADD_PROJECT_INTEGRATION_FULFILLED,
  REMOVE_PROJECT_INTEGRATION_FULFILLED,
  CREATE_PROJECT_INTEGRATION_FULFILLED,
  GET_PROJECT_STORIES_FULFILLED,
  UPDATE_PROJECT_STORIES_FULFILLED,
  SYNC_STORIES_FULFILLED,
  CREATE_PROJECTS_JIRAS_FULFILLED,
  JIRA_TICKET_DETAIL_FULFILLED,
  JIRA_UNLINK_FULFILLED,
} from '../types';

const integrationsEntity = new schema.Entity('integrations');
const storiesEntity = new schema.Entity('stories');

const mapIntegrationEntitiesToState = (state, entities) => ({
  ...state,
  integrations: add(state.integrations, entities.integrations),
});

const mapStoriesEntitiesToState = (state, entities) => ({
  ...state,
  stories: add(state.stories, entities.stories),
});

const addIntegrationToState = (state, integration) => {
  const { entities } = normalize(integration, integrationsEntity);

  return mapIntegrationEntitiesToState(state, entities);
};

const bulkAddIntegrationsToState = (state, integrations) => {
  const { entities } = normalize(integrations, [integrationsEntity]);

  return mapIntegrationEntitiesToState(state, entities);
};

const bulkAddStoriesToState = (state, stories) => {
  const { entities } = normalize(stories, [storiesEntity]);

  return mapStoriesEntitiesToState(state, entities);
};

const removeIntegrationFromState = (state, integrationId) => {
  return {
    ...state,
    integrations: remove(state.integrations, [integrationId]),
  };
};

const integrationsEntitiesReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROJECT_INTEGRATIONS_FULFILLED: {
      return bulkAddIntegrationsToState(state, action.payload?.data);
    }

    case CREATE_PROJECT_INTEGRATION_FULFILLED:
    case ADD_PROJECT_INTEGRATION_FULFILLED: {
      return addIntegrationToState(state, action.payload?.projectIntegration);
    }

    case REMOVE_PROJECT_INTEGRATION_FULFILLED: {
      const integrationId = action.payload?.projectIntegrationId;

      return removeIntegrationFromState(state, integrationId);
    }

    case GET_PROJECT_STORIES_FULFILLED: {
      return bulkAddStoriesToState(state, action.payload?.stories);
    }

    case UPDATE_PROJECT_STORIES_FULFILLED: {
      return bulkAddStoriesToState(state, action.payload?.data);
    }

    case SYNC_STORIES_FULFILLED: {
      return bulkAddStoriesToState(state, action.payload);
    }

    case CREATE_PROJECTS_JIRAS_FULFILLED: {
      const integrations = action.payload?.data?.data;

      if (!Array.isArray(integrations)) {
        return state;
      }

      return bulkAddIntegrationsToState(state, integrations);
    }

    case JIRA_TICKET_DETAIL_FULFILLED: {
      return addIntegrationToState(state, action.payload?.data);
    }

    case JIRA_UNLINK_FULFILLED: {
      const integrationId = Object.values(state.integrations)?.find(integration => integration.key === action.meta?.issueKey)?.id;

      return removeIntegrationFromState(state, integrationId);
    }

    default: {
      return state;
    }
  }
};

export { integrationsEntitiesReducer };
