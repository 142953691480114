import React, { useState } from 'react';
import styled from 'styled-components';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import HelpIcon from '@material-ui/icons/HelpOutline';

import IconAndTextButton from 'design-system/molecules/IconAndTextButton';
import TextDeprecated from 'design-system/atoms/TextDeprecated';
import Link from 'components/Link';
import ButtonIcon from 'design-system/molecules/ButtonIcon/index';


import { IntegrationWrapper, IntegrationTitle, IntegrationFieldsWrapper } from '../components/LightboxIntegrationScaffold';

import GitHubErrorMessage from './GitHubErrorMessage';
import ErrorMessage from 'design-system/atoms/ErrorMessage';

import { addMilestoneFilterToUrl, stripGitHubOrganizationUrlPart } from '../utils/github';

export default props => {
  const { selectedProject } = props;
  const [repoName, setRepoName] = useState('');
  const [milestoneUrl, setMilestoneUrl] = useState('');
  const [milestoneName, setMilestoneName] = useState(selectedProject?.title ? selectedProject.title.slice(0, 20) : '');
  const [projectUrl, setProjectUrl] = useState('');
  const [addProjectMode, setAddProjectMode] = useState(false);
  const [addMilestoneMode, setAddMilestoneMode] = useState(false);

  const {
    creating,
    createError,
    createEpic,
    loadingProjectIntegrations,
    projectIntegration,
    unlinking,
    unlinkEpic,
    linking,
    linkEpic,
    linkError,
    linkingProject,
    projectsUrls,
    linkProject,
    unlinkProject,
    showProjectLightbox,
    hideProjectLightbox,
    integration,
    setProjectError,
  } = props;

  return (
    <IntegrationWrapper>
      <IntegrationTitle>
        GitHub
        <ButtonIcon
          size="small"
          title="Help"
          // eslint-disable-next-line no-script-url
          href="https://dragonboat.zendesk.com/hc/en-us/articles/360038958174#link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <HelpIcon style={{ fontSize: 18, color: 'gray', marginTop: -2 }} />
        </ButtonIcon>
      </IntegrationTitle>
      <IntegrationFieldsWrapper>
        {projectsUrls &&
          projectsUrls.length > 0 &&
          projectsUrls.map(projectUrl => {
            return (
              <Grid container>
                <Grid item xs={7} style={{ marginTop: '16px' }}>
                  <TextDeprecated>
                    <LinkNotUnderlined href={addMilestoneFilterToUrl(integration, projectUrl)} target="_blank">
                      View {stripGitHubOrganizationUrlPart(projectIntegration, projectUrl)}
                    </LinkNotUnderlined>
                  </TextDeprecated>
                </Grid>
                <Grid item xs={5}>
                  <UnlinkButton
                    style={{ marginTop: '8px' }}
                    text="Unlink Project"
                    icon={RemoveIcon}
                    // disabled={unlinking}
                    onClick={() => unlinkProject(projectUrl)}
                  />
                </Grid>
              </Grid>
            );
          })}
        {(!projectsUrls || !projectsUrls.length || addProjectMode) && (
          <Grid container>
            <Grid item xs={7}>
              <TextField
                style={{
                  width: '90%',
                  marginTop: '5px',
                }}
                inputProps={{
                  style: {
                    overflow: 'hidden',
                    'text-overflow': 'ellipsis',
                    'white-space': 'nowrap',
                  },
                }}
                value={projectUrl}
                onChange={e => (e.target.value ? setProjectUrl(e.target.value.trim()) : setProjectUrl(''))}
                label="Project URL"
                name="projectUrl"
                placeholder="Enter Project URL"
              />
              <p style={{ marginTop: 10 }}>
                <ErrorMessage>{setProjectError}</ErrorMessage>
              </p>
            </Grid>
            <Grid item xs={5}>
              <IconAndTextButton
                id="link-git"
                style={{ marginTop: 17 }}
                text="Link Project"
                disabled={!projectUrl || linkingProject}
                icon={AddIcon}
                onClick={async () => {
                  try {
                    await linkProject(projectUrl);
                    setAddProjectMode(false);
                    setProjectUrl('');
                  } catch (err) {
                    // handle error
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
        {!loadingProjectIntegrations &&
          projectIntegration &&
          projectIntegration.length > 0 &&
          !projectIntegration[0].data.slug &&
          addMilestoneMode && (
            <Grid container>
              <Grid item xs={7}>
                <TextField
                  style={{ width: '100%', marginTop: '5px' }}
                  value={repoName}
                  onChange={e => (e.target.value ? setRepoName(e.target.value.trim()) : setRepoName(''))}
                  label="Repo Name"
                  name="repoName"
                  placeholder="Enter repo name, separated by ,"
                />
                <TextField
                  style={{ width: '100%', marginTop: '5px' }}
                  value={milestoneName}
                  onChange={e => (e.target.value ? setMilestoneName(e.target.value.slice(0, 20)) : setMilestoneName(''))}
                  label="Milestone Name"
                  name="milestoneName"
                  placeholder="Milestone short name"
                />
              </Grid>
              <Grid item xs={5}>
                <IconAndTextButton
                  id="create-git-milestone"
                  style={{ marginTop: 70 }}
                  text="Create Milestone"
                  disabled={!repoName || creating}
                  icon={AddIcon}
                  onClick={() => createEpic({ repoName, milestoneName })}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  style={{ width: '100%', marginTop: '15px' }}
                  value={milestoneUrl}
                  onChange={e => (e.target.value ? setMilestoneUrl(e.target.value.trim()) : setMilestoneUrl(''))}
                  label="Milestone URL"
                  name="milestoneUrl"
                  placeholder="Milestone URL"
                />
              </Grid>
              <Grid item xs={5}>
                <IconAndTextButton
                  id="link-git-milestone"
                  style={{ marginTop: 27 }}
                  text="Link Milestone"
                  disabled={!milestoneUrl || linking}
                  icon={AddIcon}
                  onClick={() => {
                    linkEpic('github', { milestoneUrl: milestoneUrl.trim() });
                  }}
                />
              </Grid>
              <div style={{ marginTop: 15 }}>
                <GitHubErrorMessage
                  createError={createError}
                  linkError={linkError}
                  repoName={repoName}
                  showProjectLightbox={showProjectLightbox}
                  hideProjectLightbox={hideProjectLightbox}
                />
              </div>
            </Grid>
          )}
      </IntegrationFieldsWrapper>
      {!loadingProjectIntegrations && projectIntegration && projectIntegration.length > 0 && projectIntegration[0].data.slug && (
        <Grid container style={{ marginTop: '16px' }}>
          <Grid item xs={7}>
            <TextDeprecated style={{ width: '100%', marginTop: '15px', marginBottom: '10px' }}>
              {projectIntegration.map(projectIntegration => {
                if (projectIntegration && projectIntegration.data) {
                  return (
                    <p>
                      <Link href={projectIntegration.data.url} target="_blank">
                        {projectIntegration.data.repository}:{projectIntegration.data.slug}
                      </Link>
                    </p>
                  );
                }

                return <span />;
              })}
            </TextDeprecated>
          </Grid>
          <Grid item xs={5}>
            <UnlinkButton
              style={{ marginTop: '8px' }}
              text="Unlink Milestone(s)"
              icon={RemoveIcon}
              disabled={unlinking}
              onClick={() => unlinkEpic()}
            />
          </Grid>
          {/* {
            (() => {
              if (!addProjectMode && projectsUrls && projectsUrls.length) {
                return (
                  <Grid item xs={5}>
                    <IconAndTextButton
                      style={{ marginTop: 4 }}
                      text="Link another project"
                      icon={AddIcon}
                      onClick={() => {
                        setAddProjectMode(true);
                      }}
                    />
                  </Grid>
                );
              }
            })()
          } */}
          {/* <Grid item xs={5}>
            <LinkNotUnderlined target="_blank" rel="noopener noreferrer" href={projectUrl}>
              <Button
                style={{ marginTop: '18px' }}
                color="primary"
              >
                View Project Board
              </Button>
            </LinkNotUnderlined>
          </Grid> */}
        </Grid>
      )}
      {props.stories && (
        <Grid container>
          {/* <Grid item xs={7}>
            {
              projectsUrls && projectsUrls.length > 0 &&
              <div
                style={{ width: '100%', marginTop: '15px' }}
              >
                <StoriesStatusCounters stories={props.stories} />
              </div>
            }
          </Grid> */}
          {!addMilestoneMode && projectIntegration && projectIntegration.length > 0 && !projectIntegration[0].data.slug && (
            <Grid item xs={5}>
              <IconAndTextButton
                style={{ marginTop: 3 }}
                text="Add Milestone"
                icon={AddIcon}
                onClick={() => setAddMilestoneMode(true)}
              />
            </Grid>
          )}
        </Grid>
      )}
    </IntegrationWrapper>
  );
};

const LinkNotUnderlined = styled('a')`
  &&&:hover {
    text-decoration: none;
  }
`;

const UnlinkButton = styled(IconAndTextButton)``;
