import { not, pipe, prop, propEq } from 'ramda';

import shouldConsiderValueForAggregation from '../helpers/shouldConsiderValueForAggregation';

const DISPLAY_LAYER = 'displayLayer';
const GROUP = 'group';
const LAYER = 'layer';
const PLANNED_ALLOCATION_IN_WEEKS = 'plannedAllocation';
const REPORTED_ALLOCATION_IN_WEEKS = 'reportedAllocation';

const getDisplayLayer = prop(DISPLAY_LAYER);
const getLayer = prop(LAYER);
const getPlannedAllocation = prop(PLANNED_ALLOCATION_IN_WEEKS);
const getReportedAllocation = prop(REPORTED_ALLOCATION_IN_WEEKS);

const isGroup = propEq(GROUP, true);
const isNotGroup = pipe(isGroup, not);

const sumScopeVarianceFieldAggColumnDef = {
  valueGetter: params => {
    const {
      data,
      colDef: { field, cellRendererParams },
    } = params;

    if (data && isNotGroup(data)) {
      const considerValueForSum = shouldConsiderValueForAggregation(params);
      const isSameLayer = getLayer(data) === getDisplayLayer(cellRendererParams);

      return {
        plannedAllocation: considerValueForSum && isSameLayer ? getPlannedAllocation(data) : 0,
        reportedAllocation: considerValueForSum && isSameLayer ? getReportedAllocation(data) : 0,
        toString: () => data[field],
      };
    }
  },
  aggFunc: 'sumScopeVarianceField',
};

export default sumScopeVarianceFieldAggColumnDef;
