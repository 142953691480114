import theme from 'design-system/theme';
import ticksFontStyles from 'utils/charts/ticksFontStyles';

/**
 * Generates chart options for a bar chart, with the option to specify whether it should be a stacked chart.
 *
 * @param {boolean} isStackedChart - A flag indicating whether the chart should be stacked.
 * @param {Function} onChartClickHandler - Callback handler for chart click events
 * @param {Boolean} isHorizontal - If the chart is horizontal or not
 * @param {Object} pluginConfigs - Plugin config object with extra plugin setup for the chart
 * @returns {Object} An object containing configuration options for the bar chart.
 */
const getBarChartOptions = (isStackedChart, onChartClickHandler, isHorizontal = false, pluginConfigs = {}) => {
  const displayXAxis = !isStackedChart || !isHorizontal;
  const displayYAxis = !isStackedChart || isHorizontal;

  return {
    onClick: onChartClickHandler,
    hover: {
      animationDuration: 0,
    },
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        display: displayXAxis,
        min: 0,
        ...(isStackedChart ? { max: 100 } : {}),
        ticks: {
          beginAtZero: true,
          stepSize: 1,
          font: ticksFontStyles,
        },
        scaleLabel: {
          display: false,
        },
        gridLines: {},
        stacked: isStackedChart,
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        display: displayYAxis,
        gridLines: {
          display: false,
          color: theme.palette.background.white,
          zeroLineColor: theme.palette.background.white,
          zeroLineWidth: 0,
        },
        ticks: {
          beginAtZero: true,
          font: ticksFontStyles,
        },
        stacked: isStackedChart,
        maxBarThickness: 30,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: context => {
            const { dataset, label, formattedValue, dataIndex } = context || {};

            if (!isStackedChart) {
              return `${label}: ${formattedValue}`;
            }

            const { label: stackLabel } = dataset || {};

            if (!dataset.rawData) {
              return `${stackLabel}: ${formattedValue}`;
            }

            return `${stackLabel}: ${dataset.rawData[dataIndex]}`;
          },
        },
      },
      ...pluginConfigs,
    },
    responsive: true,
    maintainAspectRatio: false,
  };
};

export default getBarChartOptions;
