import React from 'react';
import TextField from '@material-ui/core/TextField';

import FieldWrapper from './FieldWrapper';

import { FILTERS_FUNCTIONS } from 'design-system/constants';

const emptyFunctions = [
  FILTERS_FUNCTIONS.isEmpty.key,
  FILTERS_FUNCTIONS.isNotEmpty.key,
  FILTERS_FUNCTIONS.isEmptyText.key,
  FILTERS_FUNCTIONS.isNotEmptyText.key,
];

export default ({ value, onChange, fullWidth, width, fieldFunction }) => {
  const isEmptyFunction = () => fieldFunction && emptyFunctions.includes(fieldFunction.key);

  if (isEmptyFunction()) return '';

  return (
    <FieldWrapper input text style={{ flexBasis: width || 'auto', flexGrow: fullWidth && 1 }}>
      <TextField data-testid="filter-input-text" data-test="filter-input-text" value={value} onChange={onChange} />
    </FieldWrapper>
  );
};
