import { not } from 'ramda';

/**
 * @function getMaxValueOnBarStackedChart
 *
 * get the max value on a stacked chart datasets data
 *
 * @param  {Array} datasets
 * @return {Number}
 */
const getMaxValueOnBarStackedChart = datasets => {
  let maxValue = 0;

  if (not(datasets)) {
    return maxValue;
  }

  datasets.forEach(dataset => {
    const valuesArr = dataset.data.filter(v => v);

    const maxOnDataset = Math.max(...valuesArr);

    if (maxOnDataset > maxValue) {
      maxValue = maxOnDataset;
    }
  });

  return maxValue;
};

export default getMaxValueOnBarStackedChart;
