import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { openObjectiveDrawer } from 'store/objectives';
import useLightboxesControlContext from 'hooks/lightboxes/useLightboxesControl';

const useCanvasDataSync = ({
  innerRef,
  openCreateDrawer,
  isLoadingData,
  dataForCanvas,
  canvasLayoutConfig,
  canvasNodesLayout,
  canvasEdgesLayout,
  userActionsMeta,
  nodeTypeLabels,
  onNodesLayoutUpdate,
  onEdgesLayoutUpdate,
  fixedNodesToDisplay,
}) => {
  const dispatch = useDispatch();
  const [canvasInitialized, setCanvasInitialized] = useState(false);
  const { displayNodes, displayPreferences, relationsConfig } = canvasLayoutConfig;

  const { openMetricLightbox } = useLightboxesControlContext();

  useEffect(() => {
    if (canvasInitialized && !isLoadingData) {
      innerRef.current.contentWindow.postMessage(
        {
          type: 'layout',
          displayNodes,
        },
        '*',
      );
    }
  }, [displayNodes, canvasInitialized, isLoadingData]);

  useEffect(() => {
    if (canvasInitialized && !isLoadingData) {
      innerRef.current.contentWindow.postMessage(
        {
          type: 'layout',
          displayPreferences,
        },
        '*',
      );
    }
  }, [displayPreferences, canvasInitialized, isLoadingData]);

  useEffect(() => {
    if (canvasInitialized && !isLoadingData) {
      innerRef.current.contentWindow.postMessage(
        {
          type: 'layout',
          relationsConfig,
        },
        '*',
      );
    }
  }, [relationsConfig, canvasInitialized, isLoadingData]);

  useEffect(() => {
    if (canvasInitialized && !isLoadingData) {
      innerRef.current.contentWindow.postMessage(
        {
          type: 'layout',
          fixedNodesToDisplay,
        },
        '*',
      );
    }
  }, [fixedNodesToDisplay, canvasInitialized, isLoadingData]);

  useEffect(() => {
    if (canvasInitialized) {
      innerRef.current.contentWindow.postMessage(
        {
          type: 'layout',
          nodesLayout: canvasNodesLayout,
        },
        '*',
      );
    }
  }, [canvasNodesLayout, canvasInitialized]);

  useEffect(() => {
    if (canvasInitialized) {
      innerRef.current.contentWindow.postMessage(
        {
          type: 'layout',
          edgesLayout: canvasEdgesLayout,
        },
        '*',
      );
    }
  }, [canvasEdgesLayout, canvasInitialized]);

  useEffect(() => {
    if (canvasInitialized) {
      innerRef.current.contentWindow.postMessage(
        {
          type: 'nodeTypeLabels',
          nodeTypeLabels,
        },
        '*',
      );
    }
  }, [nodeTypeLabels, canvasInitialized]);

  useEffect(() => {
    if (canvasInitialized && !isLoadingData) {
      innerRef.current.contentWindow.postMessage(
        {
          type: 'data',
          ...dataForCanvas,
        },
        '*',
      );
    }
  }, [dataForCanvas, canvasInitialized, isLoadingData]);

  useEffect(() => {
    if (canvasInitialized) {
      innerRef.current.contentWindow.postMessage(
        {
          type: 'userActionsMeta',
          userActionsMeta,
        },
        '*',
      );
    }
  }, [userActionsMeta, canvasInitialized]);

  useEffect(() => {
    const handleMessage = event => {
      const data = event.data || {};

      switch (data.type) {
        case 'whiteboardInitialized':
          setCanvasInitialized(true);
          break;
        case 'openOkr':
          dispatch(openObjectiveDrawer(data?.okrId, data?.okrType));
          break;
        case 'openMetric':
          openMetricLightbox(data?.metricId);
          break;
        case 'createDragonboatObject':
          openCreateDrawer(data?.id, data?.title);
          break;
        case 'nodesLayout':
          onNodesLayoutUpdate(data.nodesLayout);
          break;
        case 'edgesLayout':
          onEdgesLayoutUpdate(data.edgesLayout);
          break;
        default:
          break;
      }
    };

    window.addEventListener('message', handleMessage, false);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
};

export default useCanvasDataSync;
