import React, { useState, Fragment } from 'react';
import styled from 'styled-components';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';
import ErrorMessage from 'design-system/atoms/ErrorMessage/index';
import OktaCircleIcon from 'design-system/atoms/OktaCircleIcon/index';


import useContactForm from 'hooks/useContactForm';

import Content from '../components/Content';
import Title from '../components/Title';
import Body from '../components/Body';

export default props => {
  const { orgIntegration, clickNext, hasOkta, userCanUpdateOrgIntegration, userCanViewOrgIntegration } = props;

  const [oktaURI, setOktaURI] = useState(orgIntegration ? orgIntegration.data.uri : '');
  const [oktaClientId, setOktaClientId] = useState(orgIntegration ? orgIntegration.data.client_id : '');
  const [oktaClientSecret, setOktaClientSecret] = useState(orgIntegration ? orgIntegration.data.client_secret : '');
  const [ContactForm, toggleContactForm] = useContactForm('Okta contact message');

  const shouldShowContactSupportMessage = !userCanUpdateOrgIntegration || !userCanViewOrgIntegration;

  return (
    <Content>
      <Title
        title="Okta Integration"
        logo={<OktaCircleIcon size={30} marginRight={20} style={{ fill: '#0D2875' }} />}
        helpLink="https://dragonboat.zendesk.com/hc/en-us/articles/360047055153-Okta-Single-Sign-on-Integration"
      />
      <Body>
        <TextDeprecated size="medium">Okta Connection</TextDeprecated>
        {((!hasOkta && !orgIntegration) || shouldShowContactSupportMessage) && (
          <Fragment>
            <ContactSupportTeam size="medium" breakwords>
              <a href="#" onClick={toggleContactForm}>
                Click here
              </a>
              {' to contact our support team to enable Okta for your account.'}
            </ContactSupportTeam>
            {ContactForm}
          </Fragment>
        )}
        {hasOkta && !orgIntegration && userCanUpdateOrgIntegration && (
          <OktaFieldsContainer>
            <OktaNote breakwords>
              {`* Once a dragonboat account is set to use Okta, 
                  existing dragonboat users will no longer able to log in with their previous email and password.`}
            </OktaNote>
            <OktaTextField
              label="Okta Organization URL"
              placeholder="https://myorg.okta.com"
              value={oktaURI}
              onChange={e => setOktaURI(e.target.value)}
              required
            />
            <OktaTextField
              label="Client ID"
              placeholder="ID"
              value={oktaClientId}
              onChange={e => setOktaClientId(e.target.value)}
              required
            />
            <OktaTextField
              label="Client Secret"
              placeholder="Secret"
              value={oktaClientSecret}
              onChange={e => setOktaClientSecret(e.target.value)}
              required
            />
            {props.error && (
              <OktaErrorMessage>
                <ErrorMessage>{props.error}</ErrorMessage>
              </OktaErrorMessage>
            )}
            <OktaNextButton
              id="next-button"
              color="primary"
              disabled={!oktaURI || !oktaClientSecret || !oktaClientId}
              onClick={() => clickNext(oktaURI, oktaClientId, oktaClientSecret)}
            >
              Next
            </OktaNextButton>
          </OktaFieldsContainer>
        )}
        {orgIntegration && userCanViewOrgIntegration && (
          <OktaConnected size="medium">
            Your dragonboat workspace has been linked to your Okta instance: <a href={oktaURI}>{oktaURI}</a>.
          </OktaConnected>
        )}
      </Body>
    </Content>
  );
};

const OktaTextField = styled(TextField)`
  &&&& {
    width: 500px;
    margin-bottom: 10px;
  }
`;

const OktaFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
`;

const OktaNextButton = styled(Button)`
  &&&& {
    margin-top: 12px;
    align-self: flex-end;
  }
`;

const OktaConnected = styled(TextDeprecated)`
  &&&& {
    margin-top: 20px;
  }
`;

const OktaNote = styled(TextDeprecated)`
  &&&& {
    font-size: 14px;
    margin-bottom: 10px;
  }
`;

const OktaErrorMessage = styled.div`
  &&&& {
    margin-top: 20px;
  }
`;

const ContactSupportTeam = styled(TextDeprecated)`
  &&&& {
    margin-top: 20px;
  }
`;
