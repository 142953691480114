import axios from 'axios';
import throwRequestError from '../utils/throwRequestError';

import {
  FETCH_CUSTOMERS,
  CREATE_CUSTOMER,
  MERGE_CUSTOMERS,
  DELETE_CUSTOMER,
  CREATE_CUSTOMERS,
  UNDO_CREATE_CUSTOMERS,
  UPDATE_CUSTOMERS,
  UNDO_UPDATE_CUSTOMERS,
  BULK_DELETE_CUSTOMERS,
  UNDO_BULK_DELETE_CUSTOMERS,
  UPDATE_CUSTOMER_BY_ID,
} from './types';
import bulkCreateAction from 'store/utils/factory/bulkCreateAction';
import bulkUpdateAction from 'store/utils/factory/bulkUpdateAction';
import bulkDeleteAction from 'store/utils/factory/bulkDeleteAction';
import fetchMetadataAction from 'store/utils/factory/fetchMetadataAction';
import { fetchCustomFields } from 'store/customFields';

export function mergeCustomers(customersIdsToMerge, customerId) {
  return dispatch => {
    const payload = axios.post(`/api/customers/merge/${customerId}`, {
      customersIdsToMerge,
    })
      .then(response => {
        return response.data;
      })
      .catch(throwRequestError);

    dispatch({
      type: MERGE_CUSTOMERS,
      payload,
    });

    return payload;
  };
}

export const deleteCustomer = customerId => {
  return dispatch => {
    const payload = axios.delete(`/api/customers/${customerId}`).then(res => res.data).catch(throwRequestError);

    dispatch({
      payload,
      type: DELETE_CUSTOMER,
    });

    return payload;
  };
};

export const fetchCustomers = fetchMetadataAction(
  FETCH_CUSTOMERS,
  '/api/customers',
  (state) => state.customers.toJS().rows,
  (state) => state.customers.toJS().lastCallsDate,
);

export const createCustomer = (customer) => {
  return dispatch => {
    const payload = axios.post('/api/customers', customer).then(res => res.data);

    dispatch({
      payload,
      type: CREATE_CUSTOMER,
    });

    return payload;
  };
};

export const updateCustomerById = (customerId, update) => {
  return dispatch => {
    const payload = axios.put(`/api/customers/${customerId}`, update).then(res => {
      dispatch(fetchCustomFields());
      return res.data;
    }).catch(throwRequestError);

    dispatch({
      payload,
      type: UPDATE_CUSTOMER_BY_ID,
    });

    return payload;
  };
};

export const createCustomers = bulkCreateAction(CREATE_CUSTOMERS, '/api/customers', {
  toastText: 'Customers have been created',
  ACTION_TYPE: UNDO_CREATE_CUSTOMERS,
  endpoint: '/api/customers/versions/last',
  store: 'customers',
});
export const updateCustomers = bulkUpdateAction(UPDATE_CUSTOMERS, '/api/customers', {
  toastText: 'Customers have been changed',
  ACTION_TYPE: UNDO_UPDATE_CUSTOMERS,
  endpoint: '/api/customers/versions/last',
  store: 'customers',
});
export const bulkDeleteCustomers = bulkDeleteAction(BULK_DELETE_CUSTOMERS, '/api/customers/', {
  toastText: 'Customers have been deleted',
  ACTION_TYPE: UNDO_BULK_DELETE_CUSTOMERS,
  endpoint: '/api/customers/versions/last',
  store: 'customers',
});
