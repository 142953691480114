import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import getHasPlanningStageGroup from 'utils/planningStageGroups/getHasPlanningStageGroup';
import GanttContainer from 'containers/GanttTimeline/GanttContainer';
import TimelineEstimates from 'containers/GanttTimeline/TimelineEstimates';
import TimelineTasks from 'containers/GanttTimeline/TimelineTasks';
import ControlsBar from 'containers/GanttControlsBar';
import ContentDrawer from 'components/ContentDrawer';
import GanttAllocationReport from 'containers/GanttAllocationReport';
import PlanningStageWarningDialog from 'components/PlanningStageWarningDialog';

const drawerContentStyles = css`
  margin-top: 0;
`;

const TimelineComponent = React.memo(
  ({
    clickAutoSchedule,
    compState,
    gantt,
    ganttRef,
    lsState,
    projects,
    setCompState,
    updateLsState,
    showPageConfigForReadOnly,
    getBackgroundColor,
    handleRunOneClickPlanClicked,
    showDependenciesButton,
    hideAllocationReportButton,
    hideMoreOptionsMenu,
    ganttHeight,
    pageId,
    showUncommittedProjectsToggle,
  }) => {
    const { localMode, showAllocationReport, useTaskOnConfirmedIdeas, isDialogVisible, selectedGroup1, selectedGroup2 } = lsState;
    const hasPlanningStageGroup = useMemo(() => {
      return getHasPlanningStageGroup(selectedGroup1, selectedGroup2);
    }, [selectedGroup1, selectedGroup2]);

    const controls = (
      <ControlsBar
        pageId={pageId}
        localState={compState}
        updateLocalState={setCompState}
        lsState={lsState}
        updateLsState={updateLsState}
        clickAutoSchedule={clickAutoSchedule}
        handleRunOneClickPlanClicked={handleRunOneClickPlanClicked}
        showResourceDisplayOpt
        hideAllocationReportButton={hideAllocationReportButton}
        hideMoreOptionsMenu={hideMoreOptionsMenu}
        showDependenciesButton={showDependenciesButton}
        showUncommittedProjectsToggle={showUncommittedProjectsToggle}
      />
    );

    return (
      <Wrapper className="forecastGantt" backgroundColor={getBackgroundColor}>
        {showPageConfigForReadOnly && controls}
        <GanttContainer
          gantt={gantt}
          ganttRef={ganttRef}
          height={ganttHeight || 'calc(100vh - 150px)'}
          pageControlsVisible={showPageConfigForReadOnly}
        />
        <TimelineEstimates
          gantt={gantt}
          localMode={localMode}
          addOnlyEstimates={(_, parent) => {
            if (!useTaskOnConfirmedIdeas) return true;

            return parent.planningStage !== 'Confirmed';
          }}
        />
        <TimelineTasks
          gantt={gantt}
          localMode={localMode}
          addOnlyTasks={(_, parent) => {
            return useTaskOnConfirmedIdeas && parent.planningStage === 'Confirmed';
          }}
          hasPlanningStageGroup={hasPlanningStageGroup}
          showPhaseWarningDialog={() => updateLsState({ isDialogVisible: true }, false)}
        />
        {showAllocationReport && (
          <ContentDrawer
            open={showAllocationReport}
            onClose={() => updateLsState({ showAllocationReport: false }, false)}
            contentStyles={drawerContentStyles}
            content={
              <GanttAllocationReport
                projects={projects}
                updateLsState={state => updateLsState({ allocationReport: { ...(lsState.allocationReport || {}), ...state } })}
                lsState={lsState.allocationReport || {}}
              />
            }
          />
        )}
        <PlanningStageWarningDialog isOpen={isDialogVisible} onConfirm={() => updateLsState({ isDialogVisible: false }, false)} />
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  height: 100%;
  ${({ backgroundColor }) => backgroundColor && `background: ${backgroundColor}`};
`;

export default TimelineComponent;
