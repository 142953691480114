import React from 'react';

import IdeasGrid from './IdeasGrid';
import { CommentsLightboxProvider } from './hooks/useCommentsLightbox';

export default ({ ...props }) => {
  return (
    <CommentsLightboxProvider>
      <IdeasGrid {...props} />
    </CommentsLightboxProvider>
  );
};
