import { KEY_FIELD_MAP } from 'store/projects/helpers/groupOptions';

const FORECAST_BY_HEADCOUNT = 'headcount';
const FORECAST_BY_TIME = 'time';
const FORECAST_BY_TIME_CAPACITY = 'time-capacity';

const FORECAST_LIST_FORECAST_BY_OPTION_KEY = 'forecastBy';

const FORECAST_BY_TIME_AND_CAPACITY_OPTIONS = [FORECAST_BY_TIME, FORECAST_BY_TIME_CAPACITY];

const NO_GROUP_OPTION_KEY = undefined;

export const ALLOWED_GROUP_BY_2_OPTIONS_ON_FORECAST_BY_TIME_CAPACITY = [
  NO_GROUP_OPTION_KEY,
  KEY_FIELD_MAP.objective,
  KEY_FIELD_MAP.keyResult1,
  KEY_FIELD_MAP.keyResult2,
  KEY_FIELD_MAP.roadmap,
  KEY_FIELD_MAP.product1,
  KEY_FIELD_MAP.product2,
  KEY_FIELD_MAP.theme,
  KEY_FIELD_MAP.category,
  KEY_FIELD_MAP.resourceTeam,
];

export {
  FORECAST_BY_HEADCOUNT,
  FORECAST_BY_TIME,
  FORECAST_BY_TIME_CAPACITY,
  FORECAST_BY_TIME_AND_CAPACITY_OPTIONS,
  FORECAST_LIST_FORECAST_BY_OPTION_KEY,
};
