import React, { memo } from 'react';
import styled from 'styled-components';

import Loading from 'design-system/atoms/Loading';

const LanesLoadingOverlay = memo(
  ({ hasScroll, clientWidth = 0, clientHeight = 0, scrollTop = 0, scrollLeft = 0, groupbarWidth = 0, sidebarWidth = 0 }) => (
    <StyledLoadingOverlay
      hasScroll={hasScroll}
      clientWidth={clientWidth}
      clientHeight={clientHeight}
      scrollTop={scrollTop}
      scrollLeft={scrollLeft}
      groupbarWidth={groupbarWidth}
      sidebarWidth={sidebarWidth}
    >
      <LoadingWrapper>
        <StyledLoading />
      </LoadingWrapper>
    </StyledLoadingOverlay>
  ),
);

LanesLoadingOverlay.displayName = 'LanesLoadingOverlay';

export default LanesLoadingOverlay;

const StyledLoadingOverlay = styled.div`
  &&&& {
    background-color: ${({ theme }) => theme.palette.background.lightestGray};
    height: ${({ hasScroll, clientHeight }) => (hasScroll ? `calc(${clientHeight}px - 70px)` : '100%')};
    left: ${({ scrollLeft, groupbarWidth, sidebarWidth }) => `calc(${sidebarWidth}px + ${groupbarWidth}px + ${scrollLeft}px)`};
    opacity: 0.5 !important;
    position: absolute;
    top: ${({ hasScroll, scrollTop }) => (hasScroll ? `${scrollTop}px}` : 0)};
    width: ${({ clientWidth, groupbarWidth, sidebarWidth }) => `calc(${clientWidth}px - ${groupbarWidth}px - ${sidebarWidth}px)`};
    z-index: 110;
  }
`;

const LoadingWrapper = styled.div`
  &&&& {
    height: 100%;
    position: relative;
    width: 100%;
  }
`;

const StyledLoading = styled(Loading)`
  &&&& {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
  }
`;
