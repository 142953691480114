const getDragColumnDef = ({ checkRowDrag = true, disableDrag, ...rest }) => ({
  field: 'drag',
  headerName: '',
  editable: false,
  rowDrag: params => {
    if (disableDrag) {
      return false;
    }

    return typeof checkRowDrag === 'function' ? checkRowDrag(params) : checkRowDrag;
  },
  suppressMovable: true,
  maxWidth: 28,
  suppressMenu: true,
  ...rest,
});

export default getDragColumnDef;
