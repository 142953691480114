import { useDispatch, useSelector } from 'react-redux';

import { getViewsDialogOpen } from 'store/userViews/selectors';
import { toggleViewsDialog } from 'store/userViews/actions';

const useViewsDialog = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(getViewsDialogOpen);

  const openDialog = () => dispatch(toggleViewsDialog(true));
  const closeDialog = () => dispatch(toggleViewsDialog(false));

  return {
    isOpen,
    openDialog,
    closeDialog,
  };
};

export default useViewsDialog;
