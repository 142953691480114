import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';

import FormControl from '@material-ui/core/FormControl';
import PageLink from 'design-system/atoms/PageLink/index';

import { validatePassword } from 'utils';

const ChangePassword = ({ updatePassword, goToForgotMyPassword }) => {
  const defaultState = { oldPassword: '', newPassword: '', confirmNewPassword: '' };
  const [state, setState] = useState(defaultState);

  const defaultErrorState = { oldPassword: '', newPassword: '', confirmNewPassword: '' };
  const [errors, setErrorState] = useState(defaultErrorState);

  const [updating, setUpdating] = useState(false);
  const [updatedWithSuccess, setUpdatedWithSuccess] = useState(false);

  const setErrors = data => setErrorState({ ...errors, ...data });

  const handleChange = name => e => setState({ ...state, [name]: e.target.value });

  const isButtonDisabled =
    errors.oldPassword || errors.newPassword || errors.confirmNewPassword || updating || updatedWithSuccess;

  const validateOldPassword = () => {
    if (!state.oldPassword) {
      setErrors({ oldPassword: 'Old password is required' });
      return false;
    }
    setErrors({ oldPassword: '' });
    return true;
  };

  const validateNewPassword = () => {
    let newPasswordError = '';
    let confirmNewPasswordError = '';

    if (!state.newPassword) newPasswordError = 'New password is required';
    else if (!validatePassword(state.newPassword))
      newPasswordError = 'New password must have from 8 to 24 characters with at least one letter and one number';
    if (state.newPassword !== state.confirmNewPassword) {
      confirmNewPasswordError = "New password doesn't match Confirm new password";
    }

    setErrors({ newPassword: newPasswordError, confirmNewPassword: confirmNewPasswordError });

    return !(newPasswordError || confirmNewPasswordError);
  };

  const validateConfirmNewPassword = () => {
    if (state.newPassword !== state.confirmNewPassword) {
      setErrors({ confirmNewPassword: "New password doesn't match Confirm new password" });
      return false;
    }
    setErrors({ confirmNewPassword: '' });
    return true;
  };

  const handleBlur = name => () => {
    switch (name) {
      case 'oldPassword': {
        validateOldPassword();
        break;
      }
      case 'newPassword': {
        validateNewPassword();
        break;
      }
      case 'confirmNewPassword': {
        validateConfirmNewPassword();
        break;
      }
      default: {
        // pass
      }
    }
  };

  const handleUpdatePassword = async () => {
    if (validateOldPassword() && validateNewPassword() && validateConfirmNewPassword()) {
      setUpdating(true);
      try {
        await updatePassword(state.oldPassword, state.newPassword);
        setUpdatedWithSuccess(true);
        setTimeout(() => setUpdatedWithSuccess(false), 3000);
        setState(defaultState);
      } catch (err) {
        setUpdatedWithSuccess(false);
        setErrors({ ...errors, oldPassword: 'The inserted password is not correct' });
      } finally {
        setUpdating(false);
      }
    }
  };

  return (
    <ChangePasswordSection>
      <Typography variant="h7">Change Password</Typography>
      <ChangePasswordForm>
        <TextField
          label="Old password"
          type="password"
          value={state.oldPassword}
          error={errors.oldPassword}
          onChange={handleChange('oldPassword')}
          onBlur={handleBlur('oldPassword')}
          margin="normal"
          helperText={errors.oldPassword}
          fullWidth
        />

        <TextField
          label="New password"
          type="password"
          value={state.newPassword}
          onChange={handleChange('newPassword')}
          onBlur={handleBlur('newPassword')}
          error={errors.newPassword}
          margin="normal"
          fullWidth
          helperText={errors.newPassword || '8 to 24 characters with at least one letter and one number'}
        />

        <TextField
          label="Confirm new password"
          type="password"
          value={state.confirmNewPassword}
          onChange={handleChange('confirmNewPassword')}
          onBlur={handleBlur('confirmNewPassword')}
          error={errors.confirmNewPassword}
          helperText={errors.confirmNewPassword}
          margin="normal"
          fullWidth
        />
        <FormControl margin="dense">
          <ForgotMyPasswordButton href="#" onClick={goToForgotMyPassword}>
            I forgot my password
          </ForgotMyPasswordButton>
        </FormControl>
        <UpdatePasswordButton success={updatedWithSuccess} disabled={isButtonDisabled} onClick={handleUpdatePassword}>
          {updatedWithSuccess ? 'Password Updated' : 'Update Password'}
        </UpdatePasswordButton>
      </ChangePasswordForm>
    </ChangePasswordSection>
  );
};

ChangePassword.propTypes = {
  updatePassword: PropTypes.func,
  goToForgotMyPassword: PropTypes.func,
};

const ChangePasswordForm = styled.form`
  &&&& {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const ChangePasswordSection = styled.section`
  margin-top: 30px;
`;

const ForgotMyPasswordButton = styled(PageLink)`
  &&&& {
    width: 200px;
  }
`;

const UpdatePasswordButton = styled(props => <Button color="primary" {...props} />)`
  &&&& {
    width: auto;
    align-self: flex-end;

    ${props => props.success && `color: ${props.theme.palette.text.jungleGreen};`}
  }
`;

export default ChangePassword;
