import axios from 'axios';
import { REHYDRATE } from 'redux-persist';

import { CREATE } from 'store/constants/realtimeUpdateTypes';
import history from 'store/utils/history';

import {
  SET_GLOBAL_SEARCH,
  SET_SEARCH_BAR_OPEN,
  SHOW_ADD_NEW_PROJECT,
  CLOSE_ADD_NEW_PROJECT,
  SET_APP_ERROR,
  STRIPE_LOADED,
  ZENDESK_LOADED,
  SHOW_GITHUB_IMPORT_PROJECTS_DIALOG,
  HIDE_GITHUB_IMPORT_PROJECTS_DIALOG,
  SHOW_WELCOME_DIALOG,
  CLOSE_WELCOME_DIALOG,
  SET_GLOBAL_MESSAGE,
  SET_APP_GLOBAL_CONFIG,
  SAVE_LAST_IDEAS_VIEW,
  SAVE_LAST_GOALS_VIEW,
  ADD_NOTIFICATIONS,
  ADD_NOTIFICATION,
  DELETE_NOTIFICATION,
  DISMISS_NOTIFICATION,
  RESET_NOTIFICATIONS,
  SHOW_PERMISSIONS_DIALOG,
  HIDE_PERMISSIONS_DIALOG,
  SET_LEFT_NAV_EXPANDED_ITEM,
  SET_PAGE_MODE,
  SET_IS_METADATA_FETCHED,
  SET_GO_BACK_URL,
  ENABLE_ONE_CLICK_PLAN_MODE,
  DISABLE_ONE_CLICK_PLAN_MODE,
  UPDATE_IS_SCENARIO_ROUTE,
} from './types';
import { getOneClickPlanModeReturnVersion } from './selectors';
import { getSelectedRoadmapVersion } from 'store/roadmapVersions/selectors';

export const setGlobalSearch = search => ({ type: SET_GLOBAL_SEARCH, search });
export const setSearchBarOpen = open => ({ type: SET_SEARCH_BAR_OPEN, open });

export const showAddNewProject = lightboxIdeaId => ({ type: SHOW_ADD_NEW_PROJECT, lightboxIdeaId });
export const closeAddNewProject = () => ({ type: CLOSE_ADD_NEW_PROJECT });

export const showWelcomeDialog = () => ({ type: SHOW_WELCOME_DIALOG });
export const closeWelcomeDialog = () => ({ type: CLOSE_WELCOME_DIALOG });

export const setAppGlobalConfig = config => ({ type: SET_APP_GLOBAL_CONFIG, config });

export const setAppError = error => ({ type: SET_APP_ERROR, error });
export const setGlobalMessage = message => ({ type: SET_GLOBAL_MESSAGE, message });

export const fetchStateFromAPI = token => {
  return dispatch => {
    axios.get(`/api/screenshot/state/${token}`).then(res => {
      dispatch({ type: REHYDRATE, key: 'root', payload: res.data });
    });
  };
};

export const setLastIdeasView = view => ({ type: SAVE_LAST_IDEAS_VIEW, view });
export const setLastGoalsView = view => ({ type: SAVE_LAST_GOALS_VIEW, view });

export const stripeLoaded = () => ({ type: STRIPE_LOADED });
export const zendeskLoaded = () => ({ type: ZENDESK_LOADED });

export const showGithubImportProjectsDialog = () => ({ type: SHOW_GITHUB_IMPORT_PROJECTS_DIALOG });
export const hideGithubImportProjectsDialog = () => ({ type: HIDE_GITHUB_IMPORT_PROJECTS_DIALOG });

export const showPermissionsDialog = () => ({ type: SHOW_PERMISSIONS_DIALOG });
export const hidePermissionsDialog = (redirectHome = true) => {
  return dispatch => {
    if (redirectHome) {
      history.push('/');
    }

    return dispatch({ type: HIDE_PERMISSIONS_DIALOG });
  };
};

export const addNotifications = notifications => ({ type: ADD_NOTIFICATIONS, notifications });
export const addNotification = notification => ({ type: ADD_NOTIFICATION, notification });
export const removeNotification = id => ({ type: DELETE_NOTIFICATION, id });
export const dismissNotification = id => ({ type: DISMISS_NOTIFICATION, id });
export const resetNotifications = () => ({ type: RESET_NOTIFICATIONS });

export const gotAppRealtimeUpdate = (type, { _data }) => {
  return dispatch => {
    switch (type) {
      case CREATE:
        return dispatch(Array.isArray(_data) ? addNotifications(_data) : addNotification(_data));
      default:
    }
  };
};

export const setLeftNavigationExpandedItems = item => ({ type: SET_LEFT_NAV_EXPANDED_ITEM, payload: item });

export const setPageMode = mode => ({ type: SET_PAGE_MODE, payload: mode });

export const setIsMetadataFetched = isFetched => ({ type: SET_IS_METADATA_FETCHED, payload: isFetched });

export const setGoBackUrls = goBackUrls => ({ type: SET_GO_BACK_URL, goBackUrls });

export const enableOneClickPlanMode = () => {
  return (dispatch, getState) => {
    const selectedVersion = getSelectedRoadmapVersion(getState());

    dispatch({
      type: ENABLE_ONE_CLICK_PLAN_MODE,
      payload: { shouldReturnToVersion: selectedVersion },
    });
  };
};

export const disableOneClickPlanMode = () => {
  return (dispatch, getState) => {
    const shouldReturnToVersion = getOneClickPlanModeReturnVersion(getState());

    dispatch({
      type: DISABLE_ONE_CLICK_PLAN_MODE,
      payload: { shouldReturnToVersion },
    });
  };
};

export const setIsScenarioRoute = isScenarioRoute => ({
  type: UPDATE_IS_SCENARIO_ROUTE,
  payload: isScenarioRoute,
});

export const appActions = {
  setGlobalSearch,
  setSearchBarOpen,
  showAddNewProject,
  closeAddNewProject,
  setAppError,
  fetchStateFromAPI,
  showWelcomeDialog,
  closeWelcomeDialog,
  setGlobalMessage,
  setLeftNavigationExpandedItems,
  setPageMode,
  setIsMetadataFetched,
  setGoBackUrls,
  enableOneClickPlanMode,
  disableOneClickPlanMode,
};
