import React from 'react';
import styled from 'styled-components';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


export default props => {
  return (
    <CounterDiv id="total-projects-counter">
      <TextDeprecated>{props.total} Total</TextDeprecated>
    </CounterDiv>
  );
};

const CounterDiv = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;

    ${TextDeprecated} {
      font-size: ${({ theme }) => theme.typography.fontSizeSmall}px;
    }
  }
`;
