import React, { Component } from 'react';
import { string } from 'prop-types';
import { materialColorsAlt } from 'design-system/themes/default';
import invertedTextColor from 'design-system/utils/invertedTextColor';

export default class ShowMaterialColor extends Component {
  static propTypes = {
    value: string,
  };

  static defaultProps = {
    value: '',
  };

  render() {
    const { value } = this.props;

    if (this.props.node.group && 'value' in this.props) {
      this.props.reactContainer.style.display = 'inline-block';
      return this.props.value;
    }

    return (
      <span
        style={
          value
            ? {
                padding: '5px',
                borderRadius: '5px',
                whiteSpace: 'pre',
                backgroundColor: `${materialColorsAlt[value.toLowerCase()] || value}`,
                color: invertedTextColor(materialColorsAlt[value.toLowerCase()] || value, true),
              }
            : {}
        }
      >
        {typeof value === 'string' && materialColorsAlt[value.toLowerCase()] ? value : '     '}
      </span>
    );
  }
}
