import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import GoogleAnalytics from 'react-ga';
import ReactGA from 'react-ga4';

import queryString from 'query-string';

import { getUserName } from 'utils';
import { getCurrentUser } from 'store/login/selectors';
import { getOrganization } from 'store/organization/selectors';

if (process.env.NODE_ENV !== 'test') {
  GoogleAnalytics.initialize(process.env.REACT_APP_GA_ID, { testMode: process.env.NODE_ENV === 'test' });
}

const hasRA4 = !!process.env.REACT_APP_GA_4_ID;

if (hasRA4) {
  ReactGA.initialize(process.env.REACT_APP_GA_4_ID, { testMode: process.env.NODE_ENV === 'test' });
}

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (location, user, organization) => {
    const page = location.pathname + location.search;

    const userId = user ? user.id : null;
    const roleId = user ? user.role_id : null;
    const persona = user ? user.persona : null;
    const userEmail = user ? user.email : null;
    const organizationId = user ? user.organization_id : null;
    // const organization = user ? user.organization : null;
    const isOwner = user && user.organization ? +user.organization.owner_id === +user.id : null;
    const createdAt = user ? user.created_at : null;

    const slug = organization ? organization.slug : null;
    const billingStatus = organization ? organization.billing_status : null;
    const signupQuerystring = organization ? organization.signup_querystring : null;

    const query = queryString.parse(location.search);

    // GA3
    GoogleAnalytics.set({
      page,
      userId,
      dimension1: userId,
      dimension2: organizationId,
      dimension3: slug,
      dimension4: billingStatus,
      dimension5: userEmail,
      dimension6: roleId,
      dimension7: persona,
      ...options,
    });
    GoogleAnalytics.pageview(page);

    if (hasRA4) {
      // GA4
      ReactGA.set({
        page,
        userId,
        user_properties: {
          userId,
          organizationId,
          slug,
          billingStatus,
          userEmail,
          roleId,
          persona,
        },
        ...options,
      });

      ReactGA.send({ hitType: 'pageview', page });
    }

    // eslint-disable-next-line no-unused-expressions
    userId &&
      userEmail &&
      window.pendo &&
      window.pendo.identify({
        visitor: {
          id: userId,
          email: userEmail,
          full_name: getUserName(user),
          role: roleId,
          isOwner,
          createdAt,

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: organizationId,
          name: slug,
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
          billingStatus,
          signupQuerystring,
        },
      });

    const sharedView = query?.sharedView;

    if (sharedView && window.pendo) {
      window.pendo.track('Load View', {
        viewId: sharedView,
        url: page,
      });
    }
  };

  // eslint-disable-next-line
  const HOC = props => {
    const currentUser = useSelector(getCurrentUser);
    const organization = useSelector(getOrganization);

    const fullPath = useMemo(() => {
      return props.location ? props.location.pathname + props.location.search : null;
    }, [props.location]);

    useEffect(() => {
      if (fullPath) {
        trackPage(props.location, currentUser, organization);
      }
    }, []);

    useEffect(() => {
      trackPage(props.location, currentUser, organization);
    }, [fullPath]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export default withTracker;
