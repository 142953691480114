import { createSelector } from 'reselect';

export const getState = (state) => {
  return state.votes;
};

export const getVotesPerProject = createSelector(
  getState,
  (state) => state.votesPerProject,
);

export const getVotesPerCustomerRequest = createSelector(
  getState,
  (state) => state.votesPerCustomerRequest,
);
