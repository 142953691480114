import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import TrendingUp from '@material-ui/icons/TrendingUp';
import HistoryIcon from '@material-ui/icons/History';

import ObjectiveIcon from 'design-system/atoms/ObjectiveIcon/index';
import CopyAllIcon from 'design-system/atoms/CopyAllIcon/index';
import DragonLogo from 'design-system/atoms/DragonLogo/index';

import PortfolioModeChange from 'containers/PortfolioModeChange';
import Cog from 'design-system/atoms/DragonIcons/Cog';
import Roadmap from 'design-system/atoms/DragonIcons/Roadmap';
import Gallery from 'design-system/atoms/DragonIcons/Gallery';
import CircleChart from 'design-system/atoms/DragonIcons/CircleChart';
import history from 'store/utils/history';

const ALLOW_CHANGE_MODE_PAGES = [
  'ideas',
  'goals',
  'forecast',
  'dashboard',
  'metrics',
  'scenario',
  'home',
  'welcome',
  'settings',
  'not-found',
];

const PAGES_ICONS = [
  {
    path: 'home',
    exception: 'dashboard',
    icon: <DragonLogo />,
  },
  {
    path: 'ideas',
    icon: <Roadmap fill="#2EA8E1" />,
    useColor: true,
  },
  {
    path: 'forecast',
    icon: <Gallery fill="#2EA8E1" />,
  },
  {
    path: 'dashboard',
    icon: <CircleChart fill="#2EA8E1" />,
  },
  {
    path: 'goals',
    icon: <ObjectiveIcon />,
  },
  {
    path: 'metrics',
    icon: <TrendingUp fill="#2EA8E1" />,
    useColor: true,
  },
  {
    path: 'scenario',
    icon: <CopyAllIcon fill="#2EA8E1" />,
  },
  {
    path: 'settings',
    icon: <Cog fill="#2EA8E1" />,
  },
  {
    path: 'compare-snaps',
    icon: <HistoryIcon fill="#2EA8E1" />,
    useColor: true,
  },
];

export default ({ pageIconBackground, moduleTitle }) => {
  const [moduleMenuAnchorEl, setModuleMenuAnchorEl] = useState(null);

  const path = window.location.pathname;

  const viewOptionSelected = useMemo(
    // Adding this 'exception' temporarily until we change the dashboard routes
    () => PAGES_ICONS.find(page => path && path.includes(page.path) && !path.includes(page.exception)) || PAGES_ICONS[0],
    [path],
  );

  const canChangeMode = ALLOW_CHANGE_MODE_PAGES.some(page => path && path.includes(page));

  const moduleIconClicked = useCallback(
    event => {
      if (canChangeMode) {
        setModuleMenuAnchorEl(event.currentTarget);
      }
    },
    [canChangeMode],
  );

  const _moduleMenuClosed = useCallback(() => {
    setModuleMenuAnchorEl(null);
  }, []);

  return (
    <>
      <IconContainer
        onClick={moduleIconClicked}
        canChangeMode={canChangeMode}
        $useColor={viewOptionSelected.useColor}
        background={pageIconBackground}
        id="module_icon"
      >
        {viewOptionSelected && viewOptionSelected.icon}
      </IconContainer>
      {moduleTitle && <ModuleTitle>{moduleTitle}</ModuleTitle>}
      {canChangeMode ? (
        <PortfolioModeChange history={history} anchorEl={moduleMenuAnchorEl} handleClose={_moduleMenuClosed} currentPath={path} />
      ) : null}
    </>
  );
};

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background: ${({ theme, background }) => background || theme.palette.background.primary};
  border-radius: ${({ theme }) => theme.shape.borderRadiusLarge}px;
  svg {
    height: 18px;
    width: 18px;

    ${({ theme, $useColor }) => $useColor && `color: ${theme.palette.white};`}
  }
  svg path {
    ${({ theme, $useColor }) => {
      if ($useColor) {
        return `
        color: ${theme.palette.white};
      `;
      }
      return `fill: ${theme.palette.white};`;
    }}
  }
  &:hover {
    ${({ canChangeMode }) => (canChangeMode ? 'cursor: pointer;' : '')}
  }
`;

const ModuleTitle = styled.div`
  font-size: ${({ theme }) => theme.typography.fontSizeMediumSmall}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: ${({ theme }) => theme.palette.text.primary};
  line-height: 1;
`;
