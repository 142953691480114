import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import FluidField from 'design-system/molecules/FluidField/index';
import StyledMenuItemDropdown from 'design-system/atoms/StyledMenuItemDropdown/index';


const FluidSelectField = props => (
  <FluidField
    {...props}
    editorRenderer={({ value, save, cancel, isEditing, disableEdit, placeholder }) => (
      <FormControl disabled={disableEdit}>
        <CustomSelect
          showEmptyOption={props.showEmptyOption}
          color={props.color}
          onClose={cancel}
          onBlur={cancel}
          value={value}
          onChange={e => save(e.target.value)}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: { vertical: 'top', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
            style: {
              ...(props.zIndex && { zIndex: props.zIndex }),
            },
          }}
          renderValue={currentValue => {
            return currentValue || placeholder;
          }}
          style={{ width: '100%' }}
          displayEmpty
        >
          {props.options.map(value => (
            <StyledMenuItemDropdown key={value} value={value}>
              {value}
            </StyledMenuItemDropdown>
          ))}
        </CustomSelect>
      </FormControl>
    )}
  />
);

const CustomSelect = styled(Select)`
  svg {
    display: none;
  }

  ::before {
    border-bottom: 0 !important;
  }

  ::after {
    border-bottom: 0 !important;
  }

  ${({ color }) =>
    !!color &&
    `
      color: ${color}!important;
   `}

  ${({ value, theme }) =>
    !value &&
    `
      color: ${theme.palette.text.disabled}!important;
   `}
`;

FluidSelectField.propTypes = {
  ...FluidField.propTypes,
  options: PropTypes.array,
};

export default FluidSelectField;
