const UNDEFINED_COL = 'headerColumn_null';
const HEADER_BANNER = 'headerBanner';

export const generateColumnsOrder = (columns, colsOrder) => {
  if (!columns) return [];

  let newOrder;

  const noSavedOrder = !colsOrder || !colsOrder.length;

  if (noSavedOrder) {
    newOrder = Object.keys(columns).map(key => key);
  } else {
    newOrder = [...colsOrder];
  }

  if (!newOrder.includes(HEADER_BANNER)) {
    newOrder = [HEADER_BANNER, ...newOrder];
  }

  const savedOrderHasUndefined = colsOrder?.includes(UNDEFINED_COL);
  const columnsHasUndefined = Object.keys(columns)?.includes(UNDEFINED_COL);

  if (!savedOrderHasUndefined && !columnsHasUndefined) {
    newOrder = [...newOrder, UNDEFINED_COL];
  }

  return Object.keys(columns).sort((keyA, keyB) => {
    const indexA = newOrder.indexOf(keyA);
    const indexB = newOrder.indexOf(keyB);

    if (indexA < 0) return 1;
    if (indexB < 0) return -1;

    return indexA - indexB;
  });
};
