import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

import PageLink from 'design-system/atoms/PageLink/index';


import { INITIATIVE_LAYER } from 'store/projects/constants';

import ShowJiraTicket from './ShowJiraTicket';
import CreateJiraEpic from './CreateJiraEpic';
import getSystemFieldName from 'utils/getSystemFieldName';
import { IntegrationWrapper } from '../utils/styles';

export default props => {
  const { systemFields, selectedProject, integration, formData, disableActions } = props;

  const integrationJiras =
    selectedProject && selectedProject.Jiras ? selectedProject.Jiras.filter(j => j.orgIntegration_id === integration.id) : [];
  const hasJiraEpic = integrationJiras.length > 0;
  const webhooksEnabled = !!(
    props.integration &&
    (props.integration.data.webhook || props.integration.data.webhooks) &&
    props.organizationHasWebhooks
  );

  return (
    <IntegrationWrapper>
      <FormControl fullWidth margin="dense">
        <FormLabel component="legend" id="jira-parent-link">
          Jira
          {`${formData.layer === INITIATIVE_LAYER ? ` ${getSystemFieldName('initiative', systemFields)} (Parent link)` : ' '}`}
          {formData.layer !== INITIATIVE_LAYER && (
            <small style={{ fontSize: '0.8rem' }}>
              (
              <PageLink
                onClick={() => {
                  props.closeAddNewProject();
                }}
                href={`/settings/integrations/jira/${integration.id}`}
                target="_blank"
              >
                see mapping
              </PageLink>
              )
            </small>
          )}
        </FormLabel>
        {hasJiraEpic && (
          <ShowJiraTicket
            formData={formData}
            selectedProject={selectedProject}
            disableActions={disableActions}
            epic={integrationJiras[0]}
            updateProject={props.updateProject}
            webhookEnabled={webhooksEnabled}
            integration={integration}
          />
        )}
        {!hasJiraEpic && (
          <CreateJiraEpic
            formData={formData}
            selectedProject={selectedProject}
            disableActions={disableActions}
            updateProject={props.updateProject}
            systemFields={systemFields}
            integration={integration}
          />
        )}
      </FormControl>
    </IntegrationWrapper>
  );
};
