import { isEmpty, map, not, pipe } from 'ramda';
import React, { createContext, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { INTEGRATIONS_KEYS } from 'constants/integrations';
import { getIntegrationsByType, getSlackIntegrationChannels, selectSlackChannels } from 'store/organization';

const CollaborationIntegrationChannels = createContext();

const isNotEmpty = pipe(isEmpty, not);

// TODO: Temporary workaround; replace channel id/name with Teams channel data when we can authenticate with Teams API
// Will fetch channels from Teams similar to Slack
const getMsTeamsChannels = pipe(
  map(orgIntegration => ({ id: orgIntegration.id, name: orgIntegration.data.channelName, ...orgIntegration.data })),
);

export const CollaborationIntegrationChannelsProvider = ({ children }) => {
  const dispatch = useDispatch();
  const requestedChannelsState = useRef({});

  const slackChannels = useSelector(selectSlackChannels);

  const microsoftTeamsIntegrations = useSelector(getIntegrationsByType(INTEGRATIONS_KEYS.microsoftTeams));
  const microsoftTeamsChannels = isNotEmpty(microsoftTeamsIntegrations) ? getMsTeamsChannels(microsoftTeamsIntegrations) : [];

  const getChannels = integrationType => {
    if (!(integrationType in requestedChannelsState.current)) {
      requestedChannelsState.current[integrationType] = true;

      if (integrationType === INTEGRATIONS_KEYS.slack) {
        dispatch(getSlackIntegrationChannels());
      }
    }

    switch (integrationType) {
      case INTEGRATIONS_KEYS.slack:
        return slackChannels;
      case INTEGRATIONS_KEYS.microsoftTeams:
        return microsoftTeamsChannels;
      default:
        return [];
    }
  };

  return (
    <CollaborationIntegrationChannels.Provider value={{ getChannels }}>{children}</CollaborationIntegrationChannels.Provider>
  );
};

export const useCollaborationIntegrationChannelsContext = () => {
  return useContext(CollaborationIntegrationChannels);
};
