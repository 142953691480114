// External dependencies
import React, { Component, Fragment } from 'react';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FeatureFlags } from '@dragonboat/config';
import { checkOrganizationFlags } from 'hooks/useFeatureFlags';

// Dragonboat dependcies
import afterProcessClipboardDataFactory from 'components/DataGrid/afterProcessClipboardDataFactory';
import ImportIdeasWizardLightbox from './ImportIdeasWizardLightbox';
import ImportWidget from 'components/ImportWidget';
import IdeasCopyPasteLightbox from 'containers/IdeasCopyPasteLightbox';

import ImportFromJQL from './ImportFromJQL';
import { generateDataMapping } from './utils';
import ImportFromIntegration from './ImportFromIntegration/ImportFromIntegration';
import { INTEGRATIONS_KEYS } from '../../constants/integrations';
import { spacing } from 'design-system/theme';

import ProjectLayerRadioButton from 'components/ProjectLayerRadioButton/ProjectLayerRadioButton';
import { IDEA_LAYER } from 'store/projects/constants';

const DEFAULT_CSV_IMPORT_LAYER = IDEA_LAYER;

class ImportIdeasDialog extends Component {
  constructor(props) {
    super(props);

    const { systemFields, organization, hasExternalKeyFieldsOnImport, customFields } = props;

    this.state = {
      selectedCSVImportLayer: DEFAULT_CSV_IMPORT_LAYER,
      dataMapping: generateDataMapping(
        organization,
        systemFields,
        hasExternalKeyFieldsOnImport,
        customFields,
        DEFAULT_CSV_IMPORT_LAYER,
      ),
    };

    this.saveCopyPasteData = this.saveCopyPasteData.bind(this);
    this.onCloseJqlImport = this.onCloseJqlImport.bind(this);
    this.onImportCsvData = this.onImportCsvData.bind(this);
    this.onUserWithoutJira = this.onUserWithoutJira.bind(this);
    this.copyPastBulkCreateBulkUpdate = afterProcessClipboardDataFactory(
      this.props.bulkCreateProjects,
      this.props.bulkUpdateProjects,
    );
  }

  componentDidUpdate = (prevProps, prevState) => {
    const customFieldsChanged = prevProps.customFields !== this.props.customFields;
    const selectedImportLayerChanged = prevState.selectedCSVImportLayer !== this.state.selectedCSVImportLayer;

    const needsToRegenerateDataMapping = customFieldsChanged || selectedImportLayerChanged;

    if (needsToRegenerateDataMapping) {
      const { systemFields, organization, hasExternalKeyFieldsOnImport, customFields } = this.props;

      this.setState({
        ...this.state,
        dataMapping: generateDataMapping(
          organization,
          systemFields,
          hasExternalKeyFieldsOnImport,
          customFields,
          this.state.selectedCSVImportLayer,
        ),
      });
    }
  };

  saveCopyPasteData = async (rowData, socketRoom) => {
    const { hideIdeasCopyPasteLightbox } = this.props;

    if (rowData instanceof Array) {
      try {
        await this.copyPastBulkCreateBulkUpdate({ create: rowData }, socketRoom);
      } catch (e) {
        console.log({ e });
      }

      hideIdeasCopyPasteLightbox();
      this.afterImport();

      return Promise.resolve();
    }
  };

  onCloseJqlImport = () => {
    this.props.hideImportJQLLightbox();
  };

  onImportCsvData(projects) {
    this.props.importProjects(projects, this.state.selectedCSVImportLayer);
  }

  // TODO I think this is duplicated the ImportWizardLightboxContainer is already defining this prop
  onUserWithoutJira() {
    this.props.history.push('/settings/integrations/jira/');
  }

  handleChangeCSVLayer = layer => {
    this.setState({ selectedCSVImportLayer: layer });
  };

  render() {
    const { organization, showRallyWorkspaceList } = this.props;
    const hasRallyIntegration = checkOrganizationFlags(organization, [FeatureFlags.HAS_RALLY_INTEGRATION]);

    return (
      <Fragment>
        <ImportWidget
          hideButton
          ref={ref => {
            this.importWidget = ref;
          }}
          import={this.onImportCsvData}
          dataMapping={this.state.dataMapping}
          selectedImportLayer={this.state.selectedCSVImportLayer}
          title={
            <div>
              <p>
                Map data to dragonboat data field.&nbsp;(
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://dragonboat.zendesk.com/hc/en-us/articles/360013269194-Dragonboat-Overview-Data-Structure"
                >
                  Data structure here
                </a>
                ).
              </p>
            </div>
          }
          instructions={
            <>
              <div style={{ marginBottom: spacing(2) }}>
                Don&apos;t worry, if you don&apos;t like how the data is mapped, you may use Bulk Delete to remove and start over.
              </div>
              <StyledProjectLayerRadioButton
                selectedLayer={this.state.selectedCSVImportLayer}
                onChangeLayer={layer => this.handleChangeCSVLayer(layer)}
              />
            </>
          }
          isAsyncImport
        />
        {this.props.importLightboxVisible && (
          <ImportIdeasWizardLightbox
            isOpen
            showJiraOption
            showAzureDevopsOption
            showRallyOption={hasRallyIntegration}
            onUserWithoutJira={this.onUserWithoutJira}
            jiraIntegrated={this.props.jiraIntegrated}
            azureDevopsIntegrated={this.props.azureDevopsIntegrated}
            dragonboatOnDragonboatIntegrated={this.props.dragonboatOnDragonboatIntegrated}
            rallyIntegrated={this.props.rallyIntegrated}
            onClose={this.props.hideImportIdeasLightbox}
            setCopyPasteData={this.props.setIdeasCopyPasteLightboxData}
            showCopyPasteLightbox={this.props.showIdeasCopyPasteLightbox}
            importClick={() => (this.importWidget ? this.importWidget.fileInput.click() : null)}
            showImportJqlLightbox={this.props.showImportJQLLightbox}
            showImportADOQueryLightbox={this.props.showImportADOQueryLightbox}
            showImportDragonboatFilterLightbox={this.props.showImportDragonboatFilterLightbox}
            showImportRallyViewLightbox={this.props.showImportRallyViewLightbox}
            onStartFresh={() => this.props.history.push('/ideas/board')}
          />
        )}
        {this.props.showImportJqlModal && !this.props.showImportDragonboatFilterModal && (
          <ImportFromJQL isOpen onClose={this.props.hideImportJQLLightbox} onCloseSuccess={this.onCloseJqlImport} />
        )}
        {this.props.showImportADOQueryModal && !this.props.showImportDragonboatFilterModal && (
          <ImportFromIntegration
            integrationType={INTEGRATIONS_KEYS.azuredevops}
            isOpen
            onClose={this.props.hideImportADOQueryLightbox}
          />
        )}
        {this.props.showImportRallyViewModal && !this.props.showImportDragonboatFilterModal && (
          <ImportFromIntegration
            integrationType={INTEGRATIONS_KEYS.rally}
            isOpen
            onClose={this.props.hideImportRallyViewLightbox}
            showWorkspaceList={showRallyWorkspaceList}
          />
        )}
        {this.props.copyPasteLightboxVisible && (
          <IdeasCopyPasteLightbox
            isOpen
            rowData={this.props.copyPasteData}
            cancel={this.props.hideIdeasCopyPasteLightbox}
            save={this.saveCopyPasteData}
            updateRowData={this.props.setIdeasCopyPasteLightboxData}
            {...pick(this.props, [
              'productOwners',
              'phaseTitles',
              'roadmapsTitles',
              'timeframesTitles',
              'jiraIntegrated',
              'objectiveTitles',
              'themeTitles',
              'categoryTitles',
              'priorities',
              'roadmaps',
              'objectives',
              'organization',
              'customFields',
            ])}
          />
        )}
      </Fragment>
    );
  }
}

const StyledProjectLayerRadioButton = styled(ProjectLayerRadioButton)`
  margin-bottom: ${spacing(2)}px;
`;

ImportIdeasDialog.propTypes = {
  bulkCreateProjects: PropTypes.func,
  bulkUpdateProjects: PropTypes.func,
  systemFields: PropTypes.array,
  organization: PropTypes.object,
  showImportTip: PropTypes.func,
  history: PropTypes.object,
  hideImportJQLLightbox: PropTypes.func,
  hideImportADOQueryLightbox: PropTypes.func,
  hideImportRallyViewLightbox: PropTypes.func,
  importLightboxVisible: PropTypes.bool,
  showImportJqlModal: PropTypes.bool,
  showImportADOQueryModal: PropTypes.bool,
  showImportRallyViewModal: PropTypes.bool,
  showImportDragonboatFilterModal: PropTypes.bool,
  importDragonboatFilterModalVariant: PropTypes.string,
  importDragonboatFilterOrgIntegrationId: PropTypes.number,
  jiraIntegrated: PropTypes.bool,
  azureDevopsIntegrated: PropTypes.bool,
  rallyIntegrated: PropTypes.bool,
  setIdeasCopyPasteLightboxData: PropTypes.func,
  copyPasteData: PropTypes.array,
};

export default ImportIdeasDialog;
