import { useMemo } from 'react';

import {
  getTitleColumnDef,
  statusColumnDef,
  colorColumnDef,
  updatedColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';

import useSkills from './useSkills';

import getSystemFieldName from 'utils/getSystemFieldName';
import { SKILL_KEY } from '../constants';

const useSkillsGridColumns = () => {
  const { systemFields } = useSkills();

  const skillFieldTitle = `${getSystemFieldName(SKILL_KEY, systemFields)} Title`;

  return useMemo(() => [getTitleColumnDef(skillFieldTitle), statusColumnDef, colorColumnDef, updatedColumnDef], []);
};

export default useSkillsGridColumns;
