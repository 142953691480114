import React from 'react';
import styled from 'styled-components';
import { throwWithProductionFallback } from 'utils/errorHandling';
import noop from 'lodash/noop';

import AgGridReact, { CSS_SCOPE_CLASS } from 'design-system/molecules/AgGridReact-New';
import AgGridReactMaterialThemeWrapper from 'design-system/molecules/AgGridReactMaterialThemeWrapper';

import agGridStylesOverride from './styles/agGridStylesOverride';
import './styles/baseGrid.css';
import BaseGridErrorBoundary from './BaseGridErrorBoundary';

const MISSING_GET_ROW_ID_MESSAGE = 'Grid: getRowId is not defined. make sure it is implemented';
const DEFAULT_ROW_HEIGHT = 35;

const extendContext = (context = {}, treeData = false) => ({
  ...context,
  isTreeView: context.isTreeView || !!treeData,
});

const BaseGrid = ({ height, rowHeight, cssStyles = '', context = {}, getRowId, ...remainingProps }) => {
  if (!getRowId) {
    throwWithProductionFallback(new Error(MISSING_GET_ROW_ID_MESSAGE), noop);
  }
  return (
    <div className={CSS_SCOPE_CLASS}>
      <AgGridReactMaterialThemeWrapper>
        <AgGridBaseWrapper
          $cssStyles={cssStyles}
          className="ag-theme-material"
          style={{
            width: '100%',
            height: height || '100vh',
          }}
        >
          <BaseGridErrorBoundary>
            <AgGridReact
              {...remainingProps}
              getRowId={getRowId}
              rowHeight={rowHeight || DEFAULT_ROW_HEIGHT}
              context={extendContext(context, remainingProps?.treeData)}
            />
          </BaseGridErrorBoundary>
        </AgGridBaseWrapper>
      </AgGridReactMaterialThemeWrapper>
    </div>
  );
};

export default BaseGrid;

const AgGridBaseWrapper = styled.div`
  width: 100%;

  ${agGridStylesOverride};
  ${({ $cssStyles }) => $cssStyles};
`;
