import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  deleteCustomerRequest as deleteCustomerRequestAction,
  stopEditingCustomerRequest as stopEditingCustomerRequestAction,
} from 'store/customerRequests/actions';

import { getCustomerRequestFormData, getIsEditingCustomerRequest } from 'store/customerRequests/selectors';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { FIELDS_LAYOUT_TYPES } from 'features/FieldsLayout/constants';
import useFormLayoutDirtyWarning from 'features/FieldsLayout/hooks/useFormLayoutDirtyWarning';
import { FeatureFlags } from '@dragonboat/config';
import useFeatureFlags from 'hooks/useFeatureFlags';

export default Component => {
  return props => {
    const dispatch = useDispatch();

    const formData = useSelector(getCustomerRequestFormData);
    const isEditing = useSelector(getIsEditingCustomerRequest);

    const deleteCustomerRequest = () => dispatch(deleteCustomerRequestAction(formData.id));
    const stopEditing = () => dispatch(stopEditingCustomerRequestAction());

    const { onCloseFormWithValidation: stopEditingWithValidation } = useFormLayoutDirtyWarning({
      type: FIELDS_LAYOUT_TYPES.requests,
      onCloseForm: stopEditing,
    });

    const hasConfigurableRequestDrawer = useFeatureFlags([FeatureFlags.HAS_CONFIGURABLE_REQUEST_DRAWER]);
    const stopEditingCustomerRequest = hasConfigurableRequestDrawer ? stopEditingWithValidation : stopEditing;

    const { canDelete } = usePermissions();

    const deletePermissionChecker = () => {
      const canDeleteRequest = canDelete(PERMISSION_RESOURCES.request, { data: formData });

      return canDeleteRequest;
    };

    return (
      <Component
        {...props}
        deleteCustomerRequest={deleteCustomerRequest}
        isEditing={isEditing}
        deletePermissionChecker={deletePermissionChecker}
        stopEditingCustomerRequest={stopEditingCustomerRequest}
      />
    );
  };
};
