/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import queryString from 'query-string';

export const isDashboardViewUrl = url => {
  const dashboardViewRegex = new RegExp(/\/dashboard\/dashboards\/\d+/);

  return dashboardViewRegex.test(url);
};

export default url => {
  if (!url || typeof url !== 'string') {
    return false;
  }

  const { _, query } = queryString.parseUrl(url);

  const urlRegex = new RegExp(
    /^(http:\/\/www\.|https:\/\/www\.|www\.|https:\/\/|http:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/,
  );

  const isValidUrl = urlRegex.test(url);
  const isDashboardView = isDashboardViewUrl(url);
  const isSharedView = !!query.sharedView;
  const isGuidedDemo = !!query.demo;


  return isValidUrl && (isSharedView || isDashboardView || isGuidedDemo);
};
