import React, { useMemo, useRef } from 'react';
import { pipe, not, includes } from 'ramda';

import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';
import {
  getTitleColumnDef,
  statusColumnDef,
  colorColumnDef,
  updatedColumnDef,
  getMetadataRoadmapColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';
import { AgGridColumnCustomFieldTypeSelectRenderer } from 'design-system/molecules/AgGridColumnCustomFieldTypeSelect';
import { AgGridColumnCustomFieldDescriptionRenderer } from 'design-system/molecules/AgGridColumnCustomFieldDescription';
import { MultiLevelSelectCellEditor } from 'design-system/molecules/AgGridReact-New/cellEditors';
import AssociationsCellRenderer from '../components/AssociationsCellRenderer';

import useSystemFields from 'hooks/useSystemFields';

import availableInColumnComparator from '../helpers/availableInColumnComparator';
import { CUSTOM_FIELD_ROADMAP_FIELD, CUSTOM_FIELD_TITLE, CUSTOM_FIELD_TYPE, FORMULA } from '../helpers/constants';
import { getDescriptionValue, checkEditablePermission } from 'utils/customFields';
import {
  AVAILABLE_PROJECT_CUSTOM_FIELDS,
  PROJECT_CUSTOM_FIELD,
  CUSTOMER_REQUEST_CUSTOM_FIELD,
} from 'store/customFields/constants';
import { checkIfCustomFieldIsDropdown } from 'utils/customFields/customFieldsUtils';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { apiUpdateCustomFieldById } from 'store/customFields/network';
import { REQUEST } from 'constants/common';
import { PORTFOLIO_LABEL } from 'constants/roadmaps';

const TYPES_NOT_ALLOWED_TO_BE_SHARED = [FORMULA];

const isTypeAllowedToBeShared = t => pipe(includes(t), not)(TYPES_NOT_ALLOWED_TO_BE_SHARED);

const typeColumnDef = {
  field: 'field_type',
  headerName: 'Type',
  openByDefault: true,
  enableRowGroup: false, // TODO: should have support for grouping
  cellRenderer: AgGridColumnCustomFieldTypeSelectRenderer,
  options: AVAILABLE_PROJECT_CUSTOM_FIELDS,
  cellEditorParams: {
    options: AVAILABLE_PROJECT_CUSTOM_FIELDS,
    showEmptyOption: false,
    sort: false,
  },
  editable: false,
  width: 150,
  maxWidth: 150,
  pinned: 'left',
  lockPinned: true,
  lockPosition: 'left',
};

const getAvailableInColumnOptions = getSystemFieldName => [
  { id: PROJECT_CUSTOM_FIELD, label: PORTFOLIO_LABEL },
  { id: CUSTOMER_REQUEST_CUSTOM_FIELD, label: getSystemFieldName(REQUEST) },
];

const getSelectedAssociations = associations =>
  associations?.reduce((acc, association) => {
    acc[association?.association_type] = true;
    return acc;
  }, {});

const getCustomFieldsColorColumnDef = canUpdate => ({
  ...colorColumnDef,
  editable: ({ data }) => canUpdate(data),
  width: 80,
  minWidth: 80,
});

const getCustomFieldsStatusColumnDef = canUpdate => ({
  ...statusColumnDef,
  editable: ({ data }) => canUpdate(data),
});

const useCustomFieldsGridColumns = ({
  roadmapColumn,
  canCreate,
  canUpdate,
  onLinkClickFormula,
  removeUnsavedCustomFields,
  updateCustomFieldById,
  hasAvailableInColumn,
  hasAppliesToColumn,
  hasColorColumn,
}) => {
  const [getSystemFieldName] = useSystemFields();

  const availbleInColumnAnchorEl = useRef(null);

  const availabeInColumnOptions = getAvailableInColumnOptions(getSystemFieldName);

  const {
    createCustomFieldRoadmap,
    deleteCustomFieldRoadmap,
    customFields,
    bulkDeleteCustomFieldRoadmaps,
    hideMetadataRoadmaps,
  } = roadmapColumn;

  const roadmapsMetadata = useRoadmapsMetadata(
    createCustomFieldRoadmap,
    deleteCustomFieldRoadmap,
    customFields,
    CUSTOM_FIELD_TYPE,
    bulkDeleteCustomFieldRoadmaps,
  );

  const typeActionsColumnDef = {
    ...typeColumnDef,
    onRemove: removeUnsavedCustomFields,
  };

  const titleColumnDef = {
    ...getTitleColumnDef(CUSTOM_FIELD_TITLE, 'title', ({ data }) => (data.id ? canUpdate(data) : canCreate)),
    pinned: 'left',
    lockPinned: true,
    lockPosition: 'left',
  };

  const descriptionColumnDefs = useMemo(
    () => ({
      field: 'description',
      headerName: 'Description',
      openByDefault: true,
      enableRowGroup: true,
      suppressMovable: true,
      editable: params => {
        return params.data.field_type !== FORMULA && checkEditablePermission(params, canUpdate);
      },
      valueGetter: params => getDescriptionValue(params, canUpdate),
      cellRenderer: AgGridColumnCustomFieldDescriptionRenderer,
      updateOptions: (id, options) => updateCustomFieldById(id, { data: options }, true),
      apiUpdateCustomFieldById: (id, options) => apiUpdateCustomFieldById(id, { data: options }, true),
      updateFormula: (id, formula) => updateCustomFieldById(id, { description: formula }),
      isDropdown: params => checkIfCustomFieldIsDropdown(params.data.field_type),
      hasLink: params => params.data.field_type === FORMULA,
      linkIcon: <OpenInNewIcon />,
      onLinkClick: onLinkClickFormula,
      width: 400,
    }),
    [canUpdate],
  );

  const availabeInColumnDef = useMemo(
    () => ({
      field: 'associations',
      headerName: 'Available In',
      cellRenderer: AssociationsCellRenderer,
      cellRendererParams: params => ({
        labels: params?.data?.associations?.reduce((acc, association) => {
          acc[association?.association_type] = availabeInColumnOptions.find(
            opt => opt.id === association?.association_type,
          )?.label;
          return acc;
        }, {}),
      }),
      onCellClicked: params => {
        const { data, event } = params;

        if (data && canUpdate(data)) {
          availbleInColumnAnchorEl.current = event?.target;
        }
      },
      editable: params => {
        return isTypeAllowedToBeShared(params.data.field_type) && checkEditablePermission(params, canUpdate);
      },
      cellEditorPopup: true,
      cellEditor: MultiLevelSelectCellEditor,
      cellEditorParams: params => {
        const { data } = params || {};
        const { associations = [] } = data || {};

        return {
          anchorEl: availbleInColumnAnchorEl.current,
          data: params.data,
          options: availabeInColumnOptions,
          selectedItems: getSelectedAssociations(associations),
          setSelectedItems: ([selectedOption, value], data) =>
            updateCustomFieldById(data?.id, { associations: { [selectedOption]: value } }),
          width: params.column.getActualWidth(),
          showTooltip: true,
          displaySearch: false,
          displayClearAll: false,
        };
      },
      comparator: availableInColumnComparator,
      cellClass: 'no-vertical-padding',
      width: 150,
    }),
    [updateCustomFieldById, availbleInColumnAnchorEl, availabeInColumnOptions, canUpdate],
  );

  const columnDefs = useMemo(
    () => [
      typeActionsColumnDef,
      titleColumnDef,
      ...(hasAvailableInColumn ? [availabeInColumnDef] : []),
      ...(hasAppliesToColumn
        ? [
            getMetadataRoadmapColumnDef({
              roadmapsMetadata: [...roadmapsMetadata, hideMetadataRoadmaps],
              editableForNewRow: false,
              field: CUSTOM_FIELD_ROADMAP_FIELD,
              editable: ({ data }) => canUpdate(data),
            }),
          ]
        : []),
      descriptionColumnDefs,
      getCustomFieldsStatusColumnDef(canUpdate),
      ...(hasColorColumn ? [getCustomFieldsColorColumnDef(canUpdate)] : []),
      updatedColumnDef,
    ],
    [
      canUpdate,
      availabeInColumnDef,
      hasAvailableInColumn,
      roadmapsMetadata,
      hideMetadataRoadmaps,
      hasAppliesToColumn,
      hasColorColumn,
    ],
  );

  return {
    columnDefs,
  };
};

export default useCustomFieldsGridColumns;
