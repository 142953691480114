/**
 * Configtoolbox Container
 * Please write a description
 *
 * @author Nuno Silva <nuno.silva@dragonboat.io>
 */
import React, { Component } from 'react';
import { groupBy, isNil, keys } from 'ramda';
import styled from 'styled-components';
import Fab from '@material-ui/core/Fab';
import TuneIcon from '@material-ui/icons/Tune';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import pluralize from 'pluralize';

import getSystemFieldName from 'utils/getSystemFieldName';
import Checkbox from 'design-system/atoms/Checkbox/index';

import { Configuration } from 'utils/configuration';
import Text from 'design-system/atoms/Text';

import { getOrganization } from 'store/organization/selectors';
import { connect } from 'react-redux';
import { spacing } from 'design-system/theme';

class ConfigToolBox extends Component {
  state = {
    open: false,
    applyed: false,
  };

  renderConfigurableFlags = () => {
    const { configurator } = Configuration;

    if (isNil(configurator)) {
      return <Text>Configurator not available</Text>;
    }

    const flagsMap = configurator.getFlags();

    const groupedFlags = groupBy(flag => Object.keys(this.props.organization).includes(flag))(keys(flagsMap));
    const orgFlags = groupedFlags.true;
    const gates = groupedFlags.false;

    const renderFlagsGroup = flags => {
      return (flags || []).map(flag => {
        const { title } = flagsMap[flag];
        const isEnabled = Configuration.isEnabled(flag, { organization: this.props.organization });
        const isConfigurable = configurator.isConfigurable(flag);
        const isConfigured = !isNil(configurator.isEnabled(flag));

        const onChange = () => {
          if (isEnabled) {
            configurator.disable(flag, false);
          } else {
            configurator.enable(flag);
          }

          this.setState({ applyed: !this.state.applyed });
        };
        const onDefaultClick = () => {
          configurator.disable(flag, true);

          this.setState({ applyed: !this.state.applyed });
        };

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControlLabel
              style={{ flex: 1 }}
              label={title}
              control={
                <Checkbox
                  checked={isEnabled}
                  disabled={!isConfigurable}
                  indeterminate={isEnabled && !isConfigured && isConfigurable}
                  onChange={onChange}
                  color="primary"
                />
              }
            />
            {isConfigurable && (
              <Button size="small" variant="contained" onClick={onDefaultClick}>
                Default
              </Button>
            )}
          </div>
        );
      });
    };

    return {
      orgFlags: renderFlagsGroup(orgFlags),
      gates: renderFlagsGroup(gates),
    };
  };

  changeConfig = (configName, value) => {
    return () => {
      if (value) {
        localStorage.setItem(configName, value);
      } else {
        localStorage.removeItem(configName);
      }

      this.setState({ applyed: !this.state.applyed });
    };
  };

  render() {
    const { open } = this.state;
    const { systemFields } = this.props;

    const { orgFlags, gates } = this.renderConfigurableFlags();

    const formContainer = (
      <div>
        {/*
        <FormControl fullWidth>
          <FormHelperText>Allow to define the user type of the application</FormHelperText>
        </FormControl>
        */}
        <br />
        <br />
        <Divider />
        <FormControl component="fieldset">
          <FormGroup>
            {/* <FormControlLabel
              label="Show jira stories in timeline"
              control={
                <Checkbox
                  checked={localStorage.getItem('showJiraStoriesInTimeline') === 'true'}
                  onChange={this.changeConfig(
                    'showJiraStoriesInTimeline',
                    !(localStorage.getItem('showJiraStoriesInTimeline') === 'true')
                  )}
                  color="primary"
                />
              }
            /> */}
            {/* <FormControlLabel
              label="Integrations Webhooks"
              control={
                <Checkbox
                  checked={localStorage.getItem('integrationsWebhooks') === 'true'}
                  onChange={this.changeConfig(
                    'integrationsWebhooks',
                    !(localStorage.getItem('integrationsWebhooks') === 'true')
                  )}
                  color="primary"
                />
              }
            /> */}
            {/* <FormControlLabel
              label="Allow integrate GitHub"
              control={
                <Checkbox
                  checked={localStorage.getItem('allowIntegrateGithub') === 'true'}
                  onChange={this.changeConfig(
                    'allowIntegrateGithub',
                    !(localStorage.getItem('allowIntegrateGithub') === 'true')
                  )}
                  color="primary"
                />
              }
            /> */}
            <FormControlLabel
              label={`Show Rank on ${pluralize(getSystemFieldName('idea', systemFields))} grid`}
              control={
                <Checkbox
                  checked={localStorage.getItem('showRowOrderInNewGrid') === 'true'}
                  onChange={this.changeConfig(
                    'showRowOrderInNewGrid',
                    !(localStorage.getItem('showRowOrderInNewGrid') === 'true'),
                  )}
                  color="primary"
                />
              }
            />
            <FormControlLabel
              label="Forecast - autoSchedule"
              control={
                <Checkbox
                  checked={localStorage.getItem('autoSchedule') === 'true'}
                  onChange={this.changeConfig('autoSchedule', !(localStorage.getItem('autoSchedule') === 'true'))}
                  color="primary"
                />
              }
            />
          </FormGroup>
          <FormControlLabel
            label="3 grouping levels swimlane"
            control={
              <Checkbox
                checked={localStorage.getItem('threeGroupingLevelsSwimlane') === 'true'}
                onChange={this.changeConfig(
                  'threeGroupingLevelsSwimlane',
                  !(localStorage.getItem('threeGroupingLevelsSwimlane') === 'true'),
                )}
                color="primary"
              />
            }
          />
          <FormControlLabel
            label="1-Click AI Planner - Plan by Estimates"
            control={
              <Checkbox
                checked={localStorage.getItem('oneClickPlanByEstimates') === 'true'}
                onChange={this.changeConfig(
                  'oneClickPlanByEstimates',
                  !(localStorage.getItem('oneClickPlanByEstimates') === 'true'),
                )}
                color="primary"
              />
            }
          />
          <FormControlLabel
            label="Show unplanned capacity charts (compare scenarios page)"
            control={
              <Checkbox
                checked={localStorage.getItem('unplannedCapacityChart') === 'true'}
                onChange={this.changeConfig(
                  'unplannedCapacityChart',
                  !(localStorage.getItem('unplannedCapacityChart') === 'true'),
                )}
                color="primary"
              />
            }
          />
        </FormControl>
        <br />
        <br />
        <Divider />
        <FlagsContainer>
          <Text>Organization FF:</Text>
          <FormControl>{orgFlags}</FormControl>
        </FlagsContainer>
        <Divider />
        <FlagsContainer>
          <Text>App gates:</Text>
          <FormControl>{gates}</FormControl>
        </FlagsContainer>
        <Divider />

        <br />
        <Button color="primary" variant="contained" onClick={() => window.location.reload()}>
          Apply
        </Button>
      </div>
    );

    return (
      <Wrapper>
        <ToolBoxButton
          data-html2canvas-ignore
          color="primary"
          aria-label="Edit"
          open={!!open}
          onClick={() => this.setState({ open: !open })}
        >
          <TuneIcon />
        </ToolBoxButton>
        {open && (
          <Drawer anchor="right" open={!!open} onClose={() => this.setState({ open: false })}>
            <ConfigContainer>
              <Typography variant="h4" gutterBottom>
                Configurations
              </Typography>
              {formContainer}
            </ConfigContainer>
          </Drawer>
        )}
      </Wrapper>
    );
  }
}

export default connect(state => {
  return {
    organization: getOrganization(state),
  };
})(ConfigToolBox);

const Wrapper = styled.div``;

const ToolBoxButton = styled(Fab)`
  &&&& {
    position: fixed;
    right: -40px;
    top: 45%;
    border-radius: 10px 0 0 10px;
    z-index: 100000;

    ${({ open }) =>
      open &&
      `
      right: 415px;
    `}

    ${({ open }) =>
      !open &&
      `
      &:hover{
        right: 0;
      }
    `}
  }
`;

const ConfigContainer = styled.div`
  width: 400px;
  padding: 26px;
`;

const FlagsContainer = styled.div`
  margin-top: ${spacing(2)}px;
  margin-bottom: ${spacing(2)}px;
`;
