/**
 * ProgressLightbox Molecule Component
 * Please write a description
 *
 * @author Your Name <youremail@dragonboat.io>
 */
import React, { PureComponent } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '../../molecules/Dialog';

export default class ProgressLightbox extends PureComponent {
  render() {
    const { progress } = this.props;

    return (
      <Dialog
        open={(progress > 0 && progress < 100) || !('progress' in this.props)}
        dataCy="progress-dialog"
      >
        {
          ('progress' in this.props) ?
            `${progress}%` :
            <CircularProgress />
        }
      </Dialog>
    );
  }
}
