export const FETCH_ROADMAPS = 'FETCH_ROADMAPS';
export const FETCH_ROADMAPS_FULFILLED = 'FETCH_ROADMAPS_FULFILLED';
export const FETCH_ROADMAPS_PENDING = 'FETCH_ROADMAPS_PENDING';

export const UPDATE_ROADMAP = 'UPDATE_ROADMAP';
export const UPDATE_ROADMAP_FULFILLED = 'UPDATE_ROADMAP_FULFILLED';

export const CREATE_ROADMAP = 'CREATE_ROADMAP';
export const CREATE_ROADMAP_FULFILLED = 'CREATE_ROADMAP_FULFILLED';

export const UPDATE_ROADMAP_ID = 'UPDATE_ROADMAP_ID';
export const UPDATE_ROADMAP_ID_FULFILLED = 'UPDATE_ROADMAP_ID_FULFILLED';

export const REMOVE_UNSAVED_ROADMAPS = 'REMOVE_UNSAVED_ROADMAPS';
export const ADD_ROADMAP_WITHOUT_SAVE = 'ADD_ROADMAP_WITHOUT_SAVE';

export const DELETE_ROADMAP = 'DELETE_ROADMAP';
export const DELETE_ROADMAP_FULFILLED = 'DELETE_ROADMAP_FULFILLED';

export const MERGE_ROADMAPS = 'MERGE_ROADMAPS';
export const MERGE_ROADMAPS_FULFILLED = 'MERGE_ROADMAPS_FULFILLED';

export const CREATE_ROADMAPS = 'CREATE_ROADMAPS';
export const CREATE_ROADMAPS_FULFILLED = 'CREATE_ROADMAPS_FULFILLED';
export const UPDATE_ROADMAPS = 'UPDATE_ROADMAPS';
export const UPDATE_ROADMAPS_FULFILLED = 'UPDATE_ROADMAPS_FULFILLED';
export const BULK_DELETE_ROADMAPS = 'BULK_DELETE_ROADMAPS';
export const BULK_DELETE_ROADMAPS_FULFILLED = 'BULK_DELETE_ROADMAPS_FULFILLED';

export const UNDO_CREATE_ROADMAPS = 'UNDO_CREATE_ROADMAPS';
export const UNDO_CREATE_ROADMAPS_FULFILLED = 'UNDO_CREATE_ROADMAPS_FULFILLED';
export const UNDO_UPDATE_ROADMAPS = 'UNDO_UPDATE_ROADMAPS';
export const UNDO_UPDATE_ROADMAPS_FULFILLED = 'UNDO_UPDATE_ROADMAPS_FULFILLED';
export const UNDO_BULK_DELETE_ROADMAPS = 'UNDO_BULK_DELETE_ROADMAPS';
export const UNDO_BULK_DELETE_ROADMAPS_FULFILLED = 'UNDO_BULK_DELETE_ROADMAPS_FULFILLED';

export const UPDATE_ROADMAP_ROW_ORDER = 'UPDATE_ROADMAP_ROW_ORDER';

export const UPDATE_PRODUCT_ID = 'UPDATE_PRODUCT_ID';
export const UPDATE_PRODUCT_ID_FULFILLED = 'UPDATE_PRODUCT_ID_FULFILLED';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const UPDATE_PRODUCTS_FULFILLED = 'UPDATE_PRODUCTS_FULFILLED';

export const UPDATE_PRODUCT_ROW_ORDER = 'roadmaps/UPDATE_PRODUCT_ROW_ORDER';
export const MOVE_PRODUCT_TO_ROADMAP = 'roadmaps/MOVE_PRODUCT_TO_ROADMAP';
export const MOVE_PRODUCT_TO_PRODUCT = 'roadmaps/MOVE_PRODUCT_TO_PRODUCT';
export const ADD_PRODUCT_WITHOUT_SAVE = 'roadmaps/ADD_PRODUCT_WITHOUT_SAVE';
export const REMOVE_UNSAVED_ROADMAPS_AND_PRODUCTS = 'roadmaps/REMOVE_UNSAVED_ROADMAPS_AND_PRODUCTS';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_FULFILLED = 'CREATE_PRODUCT_FULFILLED';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_FULFILLED = 'DELETE_PRODUCT_FULFILLED';
export const BULK_DELETE_PRODUCTS = 'BULK_DELETE_PRODUCTS';
export const BULK_DELETE_PRODUCTS_FULFILLED = 'BULK_DELETE_PRODUCTS_FULFILLED';
export const UNDO_BULK_DELETE_PRODUCTS = 'UNDO_BULK_DELETE_PRODUCTS';
export const UNDO_BULK_DELETE_PRODUCTS_FULFILLED = 'UNDO_BULK_DELETE_PRODUCTS_FULFILLED';

export const MERGE_PRODUCTS = 'MERGE_PRODUCTS';
export const MERGE_PRODUCTS_FULFILLED = 'MERGE_PRODUCTS_FULFILLED';

export const OPEN_ROADMAP_LIGHTBOX = 'OPEN_ROADMAP_LIGHTBOX';
export const CLOSE_ROADMAP_LIGHTBOX = 'CLOSE_ROADMAP_LIGHTBOX';

export const UPDATE_ROADMAP_USER_ROLES = 'UPDATE_ROADMAP_USER_ROLES';
export const UPDATE_ROADMAP_USER_ROLES_FULFILLED = 'UPDATE_ROADMAP_USER_ROLES_FULFILLED';
export const GET_ROADMAP_USER_ROLES = 'GET_ROADMAP_USER_ROLES';
export const GET_ROADMAP_USER_ROLES_FULFILLED = 'GET_ROADMAP_USER_ROLES_FULFILLED';
