import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTo } from 'ramda';

import GlobalSearchInput from 'containers/GlobalSearchInput';
import { OPEN_CUSTOMER_REQUEST_QUERY_PARAM } from 'constants/customerRequests';
import { addQueryParamToUrl } from 'utils/queryParamsUtils';

import { resetRequestsSearch, searchRequestsByText, selectSearchedRequests, selectSearchedRequestsIsLoading } from './store';

const DEFAULT_LIMIT = 5;
const SEARCH_LIMIT = 20;

const defaultAsEmptyArray = defaultTo([]);

const RequestsGlobalSearch = () => {
  const data = useSelector(selectSearchedRequests);
  const isLoadingData = useSelector(selectSearchedRequestsIsLoading);

  const dispatch = useDispatch();

  const fetchData = async search => {
    const limit = search ? SEARCH_LIMIT : DEFAULT_LIMIT;

    dispatch(searchRequestsByText(search, limit));
  };

  const onClickItem = item => {
    addQueryParamToUrl(OPEN_CUSTOMER_REQUEST_QUERY_PARAM, item.key);
  };

  const onCloseList = () => {
    dispatch(resetRequestsSearch());
  };

  const dataFormatted = defaultAsEmptyArray(data).map(item => ({ key: item.key, id: item.id, label: item.title }));

  return (
    <div>
      <GlobalSearchInput
        fetchData={fetchData}
        data={dataFormatted}
        isLoadingData={isLoadingData}
        onClickItem={onClickItem}
        onCloseList={onCloseList}
      />
    </div>
  );
};

export default RequestsGlobalSearch;
