import axios from 'axios';
import { defaultTo } from 'ramda';
import { useCallback } from 'react';

/**
 * 
 * @param {Object} data - Data that can be sent along the request
 * @returns {Object} - Object with the exposed funcs from the hook
 */
export default function useUploadAttachmentImage(data) {
  const uploadImage = useCallback(
    async (file, progressHandler) => {
      const path = `/api/files/attachment/image`;

      const formData = new FormData();

      formData.append('file', file);
      formData.append('data', defaultTo({}, data));

      const config = {
        onUploadProgress: ({ total, loaded }) => progressHandler(loaded / total),
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      const response = await axios.post(path, formData, config);

      return response.data;
    },
    [data],
  );

  const imageUploadHandler = useCallback(
    (
      files,
    ) =>
      files.map(({ file, progress }) => async () => {
        const uploadResult = await uploadImage(file, progress);

        return {
          alt: file.name,
          src: uploadResult.url,
          title: file.name,
          fileName: file.name,
          uploadedFileId: uploadResult.file.id,
        };
      }),
    [uploadImage],
  );

  /**
   * Return a link to proxy jira attachment URLs
   * Maybe this can be removed if we start converting the Jira links to dragonboat links
   * upon jira issue data arrival on dragonboat
   */
  const getBaseURLForAttachments = useCallback(project => {
    const jiraOrgIntegrationId = project?.jira?.orgIntegration_id;

    return jiraOrgIntegrationId ? `/api/jira/${jiraOrgIntegrationId}/attachment/preview` : '';
  }, []);

  return {
    imageUploadHandler,
    getBaseURLForAttachments,
  };
}
