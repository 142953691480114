const isDraggable = (api, gridColumnApi, dragAction, withSort = false, withGroup = false) => {
  const columnState = gridColumnApi.getColumnState();

  const hasSort = !!columnState.filter(colState => {
    return colState.sort != null;
  }).length;

  const hasGroup = !!columnState.filter(colState => colState.rowGroup).length;

  const hasFilter = api.isAnyFilterPresent();

  return (withSort || !hasSort) && (withGroup || !hasGroup) && !hasFilter && !!dragAction;
};

export { isDraggable };
