import React from 'react';
import { prop, propOr } from 'ramda';
import NumberFormat from 'react-number-format';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

import Autocomplete from 'design-system/atoms/Autocomplete/index';
import MultiSelect from 'design-system/atoms/MultiSelect/index';

import BulkUpdateModal from 'components/BulkUpdateModal';
import getSystemFieldName from 'utils/getSystemFieldName';
import { useSelector, useDispatch } from 'react-redux';
import { getPriorities } from 'store/priorities/selectors';
import { getThemes } from 'store/themes/selectors';
import { getObjectives, selectKeyResults1, selectKeyResults2 } from 'store/objectives/selectors';
import { getPhases } from 'store/phases/selectors';
import { getTimeframes, getTimeframesLevel2 } from 'store/timeframes/selectors';
import { getCategories } from 'store/categories/selectors';
import { getUsersWithName } from 'store/users/selectors';
import { createSelector } from 'reselect';
import DatePicker from 'components/DatePicker';
import { getCurrentUser } from 'store/login/selectors';
import { getOrganization, getOrgCustomUserProjectFields } from 'store/organization/selectors';
import { getTags } from 'store/tags/selectors';
import { createTag } from 'store/tags/thunks';
import { getActiveCustomers } from 'store/customers/selectors';
import { createCustomer } from 'store/customers/thunks';
import shouldAllowAddingNewMetadata from 'utils/shouldAllowAddingNewMetadata';
import useRoadmapAutocompletes from 'hooks/useRoadmapAutocompletes';
import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import { HEALTH_LABEL_OPTIONS_LABELS } from 'constants/projects';
import { getProjectsCustomFieldsNewSchema } from 'store/customFields/selectors';
import CustomFieldEditorFactory from 'components/CustomFieldEditorFactory';
import { TYPES_OF_CUSTOM_FIELDS } from 'store/customFields/constants';
import useGetInitiativesAndBetsFromEntities from 'hooks/useGetInitiativesAndBetsFromEntities';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import ProjectsDropdown from 'containers/ProjectsDropdown';
import { METADATA_LEVELS, TEAMS } from 'constants/common';
import useBulkUpdateIdeasColumnsDefs from './hooks/useBulkUpdateIdeasColumnsDefs';
import useMetadataForOptions from 'hooks/metadata/useMetadataForOptions';

const SORTED_VALID_OPTIONS = 'sortedValidOptions';

const exist = Boolean;

const getSortedValidOptions = propOr([], SORTED_VALID_OPTIONS);

const METADATA_KEYS = [TEAMS];

const AutocompleteEnhanced = (suggestions, addNew) => props => {
  return (
    <Autocomplete
      disabled={props.disabled}
      value={props.value}
      onValueChange={props.onChange}
      suggestions={suggestions}
      addNew={addNew}
    />
  );
};

const ParentFieldEnhanced = () => props => {
  const handleProjectSelection = (newParent, { closeTree }) => {
    props.onChange(newParent?.id);

    closeTree();
  };

  return (
    <ProjectsDropdown
      componentId="lightbox-parent-field"
      displayLayer={IDEA_LAYER}
      searchLayers={[INITIATIVE_LAYER, BET_LAYER]}
      onProjectSelect={handleProjectSelection}
      nullOption
    />
  );
};

const MultiSelectEnhanced = (options, optionsMapper, addNew) => props => {
  return (
    <MultiSelect
      options={options}
      onChange={value => {
        const newValue = options
          .filter(row => value.indexOf(row.id?.toString()) >= 0)
          .sort((row1, row2) => {
            return value.indexOf(row1.id) - value.indexOf(row2.id);
          });

        props.onChange({
          target: {
            value: newValue,
          },
        });
      }}
      optionsMapper={optionsMapper}
      value={props.value}
      onCreateOption={addNew}
    />
  );
};

const CustomFieldEditor = (field, props) => {
  const fieldType = prop('field_type', field);
  const enhancedOption = getSortedValidOptions(field).map(({ key, title }) => ({ id: key, title }));

  return CustomFieldEditorFactory(fieldType, enhancedOption, props.value, props.onChange);
};

const getHighestLayer = items => {
  const layers = items.map(i => i.layer);

  if (layers.includes(BET_LAYER)) {
    return BET_LAYER;
  } else if (layers.includes(INITIATIVE_LAYER)) {
    return INITIATIVE_LAYER;
  }

  return IDEA_LAYER;
};

const mapTitles = resources => resources.map(r => r.title);

const getThemesTitles = createSelector([getThemes], mapTitles);
const getPhasesTitles = createSelector([getPhases], mapTitles);
const getCategoriesTitles = createSelector([getCategories], mapTitles);
const getPrioritiesTitles = createSelector([getPriorities], mapTitles);

export default ({ selectedItems, onClose, bulkUpdate }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const organization = useSelector(getOrganization);

  // TODO: PERMISSION
  const allowedToCreateNewMetadata = shouldAllowAddingNewMetadata(currentUser, organization);
  const hasMultiLevelPortfolioMetadata = organization.has_multi_level_portfolio_metadata;

  const { isDodActive } = useOrganizationsAccessControl();

  const systemFields = useSelector(state => state.organization.organization.system_fields_name);
  const themes = useSelector(getThemesTitles);
  const objectives = useSelector(getObjectives);
  const corpObjectives = useSelector(state => getObjectives(state, METADATA_LEVELS.LEVEL_CORP));
  const hasKeyResults = organization.has_key_results;
  const keyResults1 = useSelector(selectKeyResults1);
  const hasKeyResults2 = organization.has_key_results_2;
  const keyResults2 = useSelector(state => selectKeyResults2(state, false));
  const phases = useSelector(getPhasesTitles);
  const timeframes = useSelector(getTimeframes);
  const timeframes2 = useSelector(getTimeframesLevel2);

  const categories = useSelector(getCategoriesTitles);
  const priorities = useSelector(getPrioritiesTitles);
  const owners = useSelector(getUsersWithName);
  const tags = useSelector(getTags);
  const customers = useSelector(getActiveCustomers);
  const customFields = useSelector(state => getProjectsCustomFieldsNewSchema(state));
  const customUserFields = useSelector(getOrgCustomUserProjectFields);

  const {
    filteredOptions: { teams },
  } = useMetadataForOptions({
    metadataKeys: METADATA_KEYS,
  });

  const {
    customersColumnDef,
    idColumnDef,
    roadmapColumnDef,
    tagsColumnDef,
    themeColumnDef,
    titleColumnDef,
    product1ColumnDef,
    objectiveColumnDef,
    keyResult1ColumnDef,
    keyResult2ColumnDef,
    priorityColumnDef,
    ownerColumnDef,
    parentColumnDef,
    categoryColumnDef,
    timeframeColumnDef,
    timeframe2ColumnDef,
    phaseColumnDef,
    projectHealthColumnDef,
    deadlineColumnDef,
    estimatedStartDateColumnDef,
    completedDateColumnDef,
    progressColumnDef,
    customFieldsColumnDefs,
    customUserFieldsColumnDefs,
    resourceTeamColumnDef,
  } = useBulkUpdateIdeasColumnsDefs({
    teams,
  });

  const [initiatives, bets, hasHierarchy, hasBet] = useGetInitiativesAndBetsFromEntities();

  const { roadmaps, hasProducts, products } = useRoadmapAutocompletes({ selectedRoadmapTitle: null });

  const highestLayer = getHighestLayer(selectedItems);

  const mapCustomUsersFields = {
    user_1_id: 'user1Name',
    user_2_id: 'user2Name',
    user_3_id: 'user3Name',
    user_4_id: 'user4Name',
    user_5_id: 'user5Name',
    user_6_id: 'user6Name',
    user_7_id: 'user7Name',
  };

  const mapCustomUsersHeader = {
    user_1_id: 'user1',
    user_2_id: 'user2',
    user_3_id: 'user3',
    user_4_id: 'user4',
    user_5_id: 'user5',
    user_6_id: 'user6',
    user_7_id: 'user7',
  };

  const fields = {
    tags: {
      editingComponent: MultiSelectEnhanced(
        tags,
        {
          label: 'title',
          value: 'id',
        },
        title => dispatch(createTag({ title })),
      ),
      gridColumn: tagsColumnDef,
      label: getSystemFieldName('tag', systemFields),
    },
    customers: {
      editingComponent: MultiSelectEnhanced(customers, { label: 'name', value: 'id' }, name =>
        dispatch(createCustomer({ name })),
      ),
      gridColumn: customersColumnDef,
      label: getSystemFieldName('customer', systemFields),
    },
    roadmapTitle: {
      editingComponent: AutocompleteEnhanced(mapTitles(roadmaps), allowedToCreateNewMetadata('roadmaps')),
      gridColumn: roadmapColumnDef,
      label: getSystemFieldName('roadmap', systemFields),
    },
    ...(hasProducts
      ? {
          product1Title: {
            editingComponent: AutocompleteEnhanced(mapTitles(products), false),
            gridColumn: product1ColumnDef,
            label: getSystemFieldName('product1', systemFields),
            parentField: 'roadmapTitle',
            updateParent: product1Title => {
              const product = products.find(p => p.title === product1Title);
              const roadmap = roadmaps.find(r => product && r.id === product.roadmap_id);

              return roadmap ? roadmap.title : null;
            },
          },
        }
      : {}),
    themeTitle: {
      editingComponent: AutocompleteEnhanced(themes, allowedToCreateNewMetadata('themes')),
      gridColumn: themeColumnDef,
      label: getSystemFieldName('theme', systemFields),
    },
    objectiveTitle: {
      editingComponent: AutocompleteEnhanced(mapTitles(objectives), allowedToCreateNewMetadata('objectives')),
      gridColumn: objectiveColumnDef,
      label: getSystemFieldName('objective', systemFields),
      ...(isDodActive
        ? {
            parentField: 'objective_corp_id',
            updateParent: newObjectiveTitle => {
              const objective = objectives.find(obj => obj.title === newObjectiveTitle);

              if (!objective) return null;

              const corpObjective = corpObjectives.find(obj => obj.id === objective.parent_id);

              return corpObjective ? corpObjective.id : null;
            },
          }
        : {}),
    },
    ...(hasKeyResults && keyResults1 && !!keyResults1.length
      ? {
          keyResult1Title: {
            editingComponent: AutocompleteEnhanced(mapTitles(keyResults1), false),
            gridColumn: keyResult1ColumnDef,
            label: getSystemFieldName('keyResult1', systemFields),
            parentField: 'objectiveTitle',
            updateParent: newKeyResultTitle => {
              const keyResult = keyResults1.find(kr => kr.title === newKeyResultTitle);

              if (!keyResult) return null;

              const objective = objectives.find(o => keyResult && o.id === keyResult.objective_id);

              return objective ? objective.title : null;
            },
            ...(isDodActive
              ? {
                  grandParentField: 'objective_corp_id',
                  updateGrandParent: newKeyResultTitle => {
                    const keyResult = keyResults1.find(kr => kr.title === newKeyResultTitle);

                    if (!keyResult) return null;

                    const objective = objectives.find(obj => obj.id === keyResult.objective_id);

                    if (!objective) return null;

                    const corpObjective = corpObjectives.find(obj => obj.id === objective.parent_id);

                    return corpObjective ? corpObjective.id : null;
                  },
                }
              : {}),
          },
        }
      : {}),
    ...(hasKeyResults2 && keyResults2 && !!keyResults2.length
      ? {
          keyResult2Title: {
            editingComponent: AutocompleteEnhanced(mapTitles(keyResults2), false),
            gridColumn: keyResult2ColumnDef,
            label: getSystemFieldName('keyResult2', systemFields),
            parentField: 'keyResult1Title',
            updateParent: newKeyResult2Title => {
              const keyResult2 = keyResults2.find(kr => kr.title === newKeyResult2Title);

              if (!keyResult2) return null;

              const keyResult1 = keyResults1.find(kr => kr.id === keyResult2.parent_id);

              return keyResult1 ? keyResult1.title : null;
            },
            grandParentField: 'objectiveTitle',
            updateGrandParent: newKeyResult2Title => {
              const keyResult2 = keyResults2.find(kr => kr.title === newKeyResult2Title);

              if (!keyResult2) return null;

              const keyResult1 = keyResults1.find(kr => kr.id === keyResult2.parent_id);

              if (!keyResult1) return null;

              const objective = objectives.find(o => keyResult1 && o.id === keyResult1.objective_id);

              return objective ? objective.title : null;
            },
            ...(isDodActive
              ? {
                  greatGrandParentField: 'objective_corp_id',
                  updateGreatGrandParent: newKeyResult2Title => {
                    const keyResult2 = keyResults2.find(kr => kr.title === newKeyResult2Title);

                    if (!keyResult2) return null;

                    const keyResult1 = keyResults1.find(kr => kr.id === keyResult2.parent_id);

                    if (!keyResult1) return null;

                    const objective = objectives.find(o => keyResult1 && o.id === keyResult1.objective_id);

                    if (!objective) return null;

                    const corpObjective = corpObjectives.find(obj => obj.id === objective.parent_id);

                    return corpObjective ? corpObjective.id : null;
                  },
                }
              : {}),
          },
        }
      : {}),
    priorityTitle: {
      editingComponent: AutocompleteEnhanced(priorities, allowedToCreateNewMetadata('priorities')),
      gridColumn: priorityColumnDef,
      label: getSystemFieldName('priority', systemFields),
    },
    ownerName: {
      editingComponent: AutocompleteEnhanced(owners, allowedToCreateNewMetadata('users')),
      gridColumn: ownerColumnDef,
      label: 'Owner',
    },
    ...([IDEA_LAYER, ...(hasBet ? [INITIATIVE_LAYER] : [])].includes(highestLayer) && hasHierarchy
      ? {
          parent_id: {
            editingComponent: ParentFieldEnhanced({ layer: highestLayer, initiatives, bets }),
            gridColumn: parentColumnDef,
            label: 'Parent',
          },
        }
      : {}),
    categoryTitle: {
      editingComponent: AutocompleteEnhanced(categories, allowedToCreateNewMetadata('categorys')),
      gridColumn: categoryColumnDef,
      label: getSystemFieldName('category', systemFields),
    },
    timeframeTitle: {
      editingComponent: AutocompleteEnhanced(mapTitles(timeframes), allowedToCreateNewMetadata('timeframes')),
      gridColumn: timeframeColumnDef,
      label: getSystemFieldName('timeframe', systemFields),
    },
    ...(hasMultiLevelPortfolioMetadata && timeframes2?.length
      ? {
          timeframe2Title: {
            editingComponent: AutocompleteEnhanced(mapTitles(timeframes2), allowedToCreateNewMetadata('timeframes')),
            gridColumn: timeframe2ColumnDef,
            label: getSystemFieldName('timeframe2', systemFields),
            parentField: 'timeframeTitle',
            updateParent: newTimeframe2Title => {
              const timeframe2 = timeframes2.find(tf => tf.title === newTimeframe2Title);
              const timeframe = timeframes.find(tf => tf.id === timeframe2?.parent_id);

              return timeframe ? timeframe.title : null;
            },
          },
        }
      : {}),
    phaseTitle: {
      editingComponent: AutocompleteEnhanced(phases, allowedToCreateNewMetadata('phases')),
      gridColumn: phaseColumnDef,
      label: getSystemFieldName('phase', systemFields),
    },
    status_color: {
      editingComponent: props => {
        return (
          <Select {...props} style={{ width: '100%' }}>
            <MenuItem value="" />
            {Object.keys(HEALTH_LABEL_OPTIONS_LABELS).map(option => (
              <MenuItem value={option}>{HEALTH_LABEL_OPTIONS_LABELS[option]}</MenuItem>
            ))}
          </Select>
        );
      },
      gridColumn: projectHealthColumnDef,
      label: 'Health',
    },
    deadline: {
      editingComponent: DatePicker,
      gridColumn: deadlineColumnDef,
      label: 'End date',
    },
    estimated_start_date: {
      editingComponent: DatePicker,
      gridColumn: estimatedStartDateColumnDef,
      label: 'Start date',
    },
    completed_date: {
      editingComponent: DatePicker,
      gridColumn: completedDateColumnDef,
      label: 'Completed Date',
    },
    progress: {
      editingComponent: props => (
        <NumberFormat
          suffix="%"
          customInput={TextField}
          thousandSeparator
          onValueChange={values => props.onChange(+values.value / 100)}
          className="ag-cell-edit-input"
          type="text"
          style={{ textAlign: 'center' }}
        />
      ),
      gridColumn: progressColumnDef,
      label: 'Progress',
    },
    resource_team_id: {
      editingComponent: props => {
        const Component = AutocompleteEnhanced(mapTitles(teams), allowedToCreateNewMetadata('teams'));
        const currentTeam = teams.find(team => team.id === props.value);

        return (
          <Component
            {...props}
            value={currentTeam?.title}
            onChange={change => {
              const team = teams.find(team => team.title === change);

              props.onChange(team?.id);
            }}
          />
        );
      },
      gridColumn: resourceTeamColumnDef,
      label: getSystemFieldName('team', systemFields),
    },
  };

  (customFields || []).forEach(cf => {
    const shouldNotBeRendered = [TYPES_OF_CUSTOM_FIELDS.FORMULA, TYPES_OF_CUSTOM_FIELDS.RICH_TEXT].includes(cf.field_type);

    if (shouldNotBeRendered) return;

    fields[cf.key] = {
      editingComponent: props => CustomFieldEditor(cf, props),
      gridColumn: customFieldsColumnDefs(cf),
      label: cf.title,
    };
  });

  Object.entries(customUserFields || {})
    .filter(([, value]) => exist(value))
    .forEach(([key, value]) => {
      const customUserField = mapCustomUsersFields[key];
      const customUserHeader = getSystemFieldName(mapCustomUsersHeader[key], systemFields);

      fields[customUserField] = {
        editingComponent: AutocompleteEnhanced(owners, allowedToCreateNewMetadata('users')),
        gridColumn: customUserFieldsColumnDefs(customUserField, customUserHeader),
        label: customUserHeader,
      };
    });

  return (
    <BulkUpdateModal
      selectedItems={selectedItems}
      resource="item"
      onClose={onClose}
      onConfirmed={bulkUpdate}
      fields={fields}
      mandatoryGridColumns={[idColumnDef, titleColumnDef]}
    />
  );
};
