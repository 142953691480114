import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';

import { getSelected, isDrawerOpen, getAllKeyResults } from 'store/objectives/selectors';
import {
  closeObjectiveDrawer,
  openObjectiveDrawer,
  createObjectiveRoadmap,
  deleteObjectiveRoadmap,
  bulkDeleteObjectiveRoadmaps,
  updateObjectiveFromDrawer,
  createObjectiveFromDrawer,
  updateKeyResultFromDrawer,
  createKeyResultFromDrawer,
} from 'store/objectives/actions';
import {
  deleteKeyResultById,
  OBJECT_OBJECTIVE,
  OBJECT_KEY_RESULT,
  OBJECT_KEY_RESULT_2,
  OBJECT_OBJECTIVE_CORP_STRING,
  requestRemoveObjectiveById,
} from 'store/objectives';
import { getMetrics } from 'store/metrics/selectors';
import { getOrganization, getHasAdvancedMetricReporting, getHasMultipleMetrics } from 'store/organization/selectors';
import { getCurrentUser } from 'store/login/selectors';

import useDirtyState from 'hooks/useDirtyState';
import useSystemFields from 'hooks/useSystemFields';
import { getUsers } from 'store/users/selectors';
import useSubscribeNewData from '../../hooks/useSubscribeNewData';
import {
  OPEN_OBJECTIVE_QUERY_PARAM,
  OBJECTIVE_TYPE_QUERY_PARAM,
  KEY_RESULT_1_LEVEL,
  KEY_RESULT_2_LEVEL,
  OBJECTIVE_CORP_LEVEL,
  OBJECTIVE_LEVEL,
} from 'src/constants/objectives';
import useOpenObjectiveFromUrl from './hooks/useOpenObjectiveFromUrl';
import useOrganizationsAccessControl from 'hooks/useOrganizationsAccessControl';
import useObjectivesSettings from 'routes/Settings/Objectives/New/hooks/useObjectivesSettings';
import { ProjectsListLightboxProvider } from 'hooks/useProjectsListLightbox';

import isOldLightboxActive from 'containers/ProjectLightBox/utils/isOldLightboxActive';
import { getProjectLightboxOpen } from 'store/projectLightbox/selectors';
import { openMetadataPopover, OBJECTIVE_METADATA_POPOVER } from 'store/metadataPopover';
import useSingleMetricsForm from 'hooks/objectives/useSingleMetricsForm';
import useLightboxesControlContext from 'hooks/lightboxes/useLightboxesControl';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { getPermissionResourceByObjectiveType } from 'utils/okrs';
import { defaultTo, pipe } from 'ramda';
import { groupByTeamHierarchy } from 'shared/helpers/entities/users';
import { OBJECTIVES_DRAWER_TABS } from './constants';

const OBJECTIVE_TYPES = [OBJECT_OBJECTIVE, OBJECT_OBJECTIVE_CORP_STRING];

const checkIfChildProgressOptionIsHidden = (isObjectKeyResult, isObjectKeyResult2, shouldDisplayKeyResult2) => {
  if (isObjectKeyResult2) {
    return true;
  }
  if (isObjectKeyResult) {
    return !shouldDisplayKeyResult2;
  }
  return false;
};

const toString = pipe(defaultTo(''), String);

const componentHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const [activeTab, changeTab] = useState(OBJECTIVES_DRAWER_TABS.DETAILS);
    const [isTitleDialogOpen, setIsTitleDialogOpen] = useState(false);
    const [getSystemFieldName] = useSystemFields();

    const { selected, selectedType, parent, grandParent } = useSelector(getSelected);
    const metrics = useSelector(getMetrics);
    const users = useSelector(getUsers);
    const hasTeams2 = useSelector(state => getOrganization(state).has_teams_2);
    const isOpen = useSelector(isDrawerOpen);
    const keyResults = useSelector(state => getAllKeyResults(state));
    const currentUser = useSelector(getCurrentUser);
    const organization = useSelector(getOrganization);
    const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);
    const hasMultipleMetrics = useSelector(getHasMultipleMetrics);

    const usersOptions = useMemo(
      () => groupByTeamHierarchy(users, { hasTeams2, getSystemFieldName }),
      [users, hasTeams2, getSystemFieldName],
    );

    const isObjectObjective = toString(selectedType) === toString(OBJECT_OBJECTIVE);
    const isObjectObjectiveCorp = toString(selectedType) === toString(OBJECT_OBJECTIVE_CORP_STRING);
    const isObjectKeyResult = toString(selectedType) === toString(OBJECT_KEY_RESULT);
    const isObjectKeyResult2 = toString(selectedType) === toString(OBJECT_KEY_RESULT_2);

    const isNew = useMemo(() => !selected?.id, [selected]);

    const oldLightboxActive = isOldLightboxActive();
    const isProjectLightboxOpen = useSelector(getProjectLightboxOpen);

    const _matchOldLightboxHeight = useMemo(
      () => oldLightboxActive && isProjectLightboxOpen,
      [oldLightboxActive, isProjectLightboxOpen],
    );

    const [fullState, updateState] = useDirtyState(selected, isOpen);

    const [keyResultsSelected, setKeyResultsSelected] = useState([]);

    const { has_key_results: hasKeyResults, has_key_results_2: hasKeyResults2 } = organization || {};
    const { isDodActive } = useOrganizationsAccessControl();

    const okrMetricId = useMemo(() => {
      const { metrics } = selected || {};

      if (metrics?.length) {
        return metrics[0]?.id || null;
      }
      return null;
    }, [selected]);

    const { openMetricLightbox } = useLightboxesControlContext();

    const toggleMetricsDialog = (_, metricId) => {
      const extraProps = {
        parentStartDate: selected?.start_date,
        parentEndDate: selected?.end_date,
      };

      if (!metricId) {
        return openMetricLightbox(okrMetricId, extraProps);
      }

      openMetricLightbox(metricId, extraProps);
    };

    useSubscribeNewData(['app']);
    useOpenObjectiveFromUrl(isOpen, selected, selectedType);

    const {
      keyResults: keyResultOptions,
      objectivesAndKeyResultsWithHierarchy,
      objectives,
    } = useObjectivesSettings({
      hasKeyResults,
      hasKeyResults2,
      hasCorpLevel: isDodActive,
      hideArchivedItems: true,
    });

    const parentOptions = useMemo(() => {
      if (isObjectObjectiveCorp) {
        // Corp OKRs have no possible parents
        return [];
      }

      if (isObjectObjective) {
        if (isDodActive) {
          // If DoD objectives can have corp OKRs as parents
          return objectives.filter(o => o.level === OBJECT_OBJECTIVE_CORP_STRING);
        }

        return [];
      }

      if (isObjectKeyResult) {
        if (isDodActive) {
          // When is a new entity use current organization id
          const orgIdFromSelectedEntity = isNew ? organization.id : selected.organization_id;

          // If DoD is active key results can have objectives as parent that belong to the same account
          return objectives.filter(o => o.level === OBJECT_OBJECTIVE && o.organization_id === orgIdFromSelectedEntity);
        }

        return objectives;
      }

      if (isObjectKeyResult2) {
        if (isNew) {
          return keyResultOptions.filter(kr => kr.level === KEY_RESULT_1_LEVEL);
        }

        return keyResultOptions.filter(kr => kr.level === KEY_RESULT_1_LEVEL && kr.objective_id === selected.objective_id);
      }
    }, [isDodActive, isNew, selected, selectedType, organization?.id]);

    const { canUpdate, canCreate, canView } = usePermissions();

    const shouldDisplayKeyResult2 = canView(PERMISSION_RESOURCES.keyResult2);

    const allowAddOrUpdateObjective = useCallback(
      type => {
        // TODO: move this condition to permissions resources
        const isOrgMatch = isNew || organization.id === selected.organization_id;

        const check = isNew ? canCreate : canUpdate;

        return isOrgMatch && check(getPermissionResourceByObjectiveType(type), { data: selected });
      },
      [currentUser, isNew, organization, selected],
    );

    const shouldShowAddNewRowOnTable = useCallback(
      type => {
        return canCreate(getPermissionResourceByObjectiveType(type));
      },
      [organization, selected],
    );

    const update = async data => {
      updateState(data);

      if (isNew && !data.id) return;

      if (isObjectObjective || isObjectObjectiveCorp) {
        return dispatch(updateObjectiveFromDrawer(selected.id, data));
      }

      return dispatch(updateKeyResultFromDrawer(selected.id, data));
    };

    const updateTitle = title => {
      if (title) {
        return update({ title: title.trim() });
      }

      setIsTitleDialogOpen(true);
    };

    const {
      updateMetricBaseline,
      removeMetricFromOkr: removeMetricFromOkrAction,
      addMetricToOkr: addMetricToOkrAction,
      createMetricLinkToOkr: createMetricLinkToOkrAction,
      updateMetricValue: updateMetricValueAction,
    } = useSingleMetricsForm();

    const removeMetricFromOkr = metricId => removeMetricFromOkrAction(selected, selectedType, metricId);
    const addMetricToOkr = metricId => addMetricToOkrAction(selected, selectedType, metricId);
    const createMetricLinkToOkr = metric => createMetricLinkToOkrAction(selected, selectedType, metric);
    const updateMetricValue = (type, value, field, metricValue) =>
      updateMetricValueAction(selected, type, value, field, metricValue);

    const isFormValid = useMemo(() => {
      if (isNew && !fullState?.title) {
        return false;
      }

      if (isObjectKeyResult || isObjectKeyResult2) {
        const hasParentSet = fullState?.objective_id || fullState?.parent_id;

        if (isNew && (!fullState?.title || !hasParentSet)) {
          return false;
        }
      }

      if (isObjectObjective && isDodActive) {
        if (isNew && !fullState?.parent_id) {
          return false;
        }
      }

      return true;
    }, [isNew, fullState, isObjectKeyResult, isObjectKeyResult2]);

    const onClose = () => {
      setTimeout(() => changeTab(OBJECTIVES_DRAWER_TABS.DETAILS), 250);
      dispatch(closeObjectiveDrawer());

      const url = new URL(window.location.href);

      const params = queryString.parse(window.location.search);

      delete params[OPEN_OBJECTIVE_QUERY_PARAM];
      delete params[OBJECTIVE_TYPE_QUERY_PARAM];

      url.search = queryString.stringify(params);

      window.history.pushState('', '', url.toString());
    };

    const handleSelectChild = id => {
      const keyResultType = isObjectKeyResult ? OBJECT_KEY_RESULT_2 : OBJECT_KEY_RESULT;
      const type = isObjectObjectiveCorp ? OBJECT_OBJECTIVE : keyResultType;

      dispatch(openObjectiveDrawer(id, type));
    };

    const onSave = async () => {
      let newObject;

      if (isObjectObjectiveCorp) {
        newObject = await dispatch(createObjectiveFromDrawer({ ...fullState, level: OBJECTIVE_CORP_LEVEL }));
      }

      if (isObjectObjective) {
        newObject = await dispatch(createObjectiveFromDrawer({ ...fullState, level: OBJECTIVE_LEVEL }));
      }

      if (isObjectKeyResult) {
        newObject = await dispatch(createKeyResultFromDrawer({ ...fullState, level: KEY_RESULT_1_LEVEL }));
      }

      if (isObjectKeyResult2) {
        const parentKeyResult = keyResults.find(kr => kr.id === fullState.parent_id);

        newObject = await dispatch(
          createKeyResultFromDrawer({ ...fullState, objective_id: parentKeyResult?.objective_id, level: KEY_RESULT_2_LEVEL }),
        );
      }

      return newObject;
    };

    const onSaveAndOpen = async () => {
      const newObject = await onSave();

      if (newObject) {
        dispatch(openObjectiveDrawer(newObject.id, selectedType));
      }
    };

    const _openMetadataDrawer = useCallback(metadataSelected => {
      dispatch(openMetadataPopover(OBJECTIVE_METADATA_POPOVER, metadataSelected));
    }, []);

    useEffect(() => {
      const normalizedKeyResultsByObjective =
        keyResults.reduce((acc, kr) => {
          if (!acc[kr.objective_id]) acc[kr.objective_id] = [];
          acc[kr.objective_id].push(kr);
          return acc;
        }, {}) || [];

      let keyResultsSelected = [];
      const objectiveKeyResults = normalizedKeyResultsByObjective[selected.objective_id] || [];
      const allowedLevel = hasKeyResults2 ? 1 : 0;
      let higherLevel = 0;

      const objectiveKeyResultsByLevel = objectiveKeyResults.reduce((accObjKr, kr) => {
        if (kr.level > allowedLevel) return accObjKr;
        if (kr.level > higherLevel) higherLevel = kr.level;
        if (!accObjKr[kr.level]) accObjKr[kr.level] = [];
        accObjKr[kr.level].push(kr);
        return accObjKr;
      }, {});

      let idToSelect = selected.id;

      const _generateKeyResultsOnLevel = krs => {
        return krs.filter(kr => {
          if (kr.id === idToSelect) {
            idToSelect = kr.parent_id;
            return true;
          }

          return false;
        });
      };

      for (let level = higherLevel; level >= 0; level--) {
        const keyResultsOnLevel = objectiveKeyResultsByLevel[level] || [];

        keyResultsSelected = keyResultsSelected.concat(_generateKeyResultsOnLevel(keyResultsOnLevel));
      }

      keyResultsSelected.reverse();

      setKeyResultsSelected(keyResultsSelected);
    }, [keyResults, selected]);

    return (
      <ProjectsListLightboxProvider>
        <Component
          isOpen={isOpen}
          onClose={onClose}
          onSave={onSave}
          onSaveAndOpen={onSaveAndOpen}
          selectedType={selectedType}
          selected={fullState}
          parent={parent}
          grandParent={grandParent}
          onClickTitle={(id, objType) => dispatch(openObjectiveDrawer(id, objType))}
          selectChild={handleSelectChild}
          update={update}
          updateTitle={updateTitle}
          onDelete={() => {
            if (OBJECTIVE_TYPES.includes(selectedType.toString())) {
              dispatch(requestRemoveObjectiveById(fullState.id));
            } else dispatch(deleteKeyResultById(fullState.id));
            onClose();
          }}
          upsertKR={(id, kr) => {
            const type = selectedType.toString();

            if (id) {
              if (type === OBJECT_OBJECTIVE_CORP_STRING) {
                return dispatch(updateObjectiveFromDrawer(id, kr));
              }
              return dispatch(updateKeyResultFromDrawer(id, kr));
            }

            let updateKr = {
              ...kr,
            };

            if (type === OBJECT_OBJECTIVE) {
              updateKr = {
                ...updateKr,
                objective_id: fullState.id,
                level: KEY_RESULT_1_LEVEL,
              };
            } else if (type === OBJECT_OBJECTIVE_CORP_STRING) {
              updateKr = {
                ...updateKr,
                parent_id: fullState.id,
              };
            } else {
              updateKr = {
                ...updateKr,
                objective_id: fullState.objective_id,
                parent_id: fullState.id,
                level: KEY_RESULT_2_LEVEL,
              };
            }

            if (type === OBJECT_OBJECTIVE_CORP_STRING) {
              return dispatch(createObjectiveFromDrawer(updateKr));
            }

            return dispatch(createKeyResultFromDrawer(updateKr));
          }}
          getSystemFieldName={getSystemFieldName}
          activeTab={activeTab}
          changeTab={changeTab}
          metrics={metrics}
          users={users}
          usersOptions={usersOptions}
          hasKeyResults2={hasKeyResults2}
          keyResultsSelected={keyResultsSelected}
          allowAddOrUpdateObjective={allowAddOrUpdateObjective}
          isTitleDialogOpen={isTitleDialogOpen}
          toggleTitleDialog={() => setIsTitleDialogOpen(!isTitleDialogOpen)}
          updateMetricValue={updateMetricValue}
          hasAdvancedMetricReporting={hasAdvancedMetricReporting}
          toggleMetricsDialog={toggleMetricsDialog}
          currentUser={currentUser}
          matchOldLightboxHeight={_matchOldLightboxHeight}
          createObjectiveRoadmap={createObjectiveRoadmap}
          deleteObjectiveRoadmap={deleteObjectiveRoadmap}
          bulkDeleteObjectiveRoadmaps={bulkDeleteObjectiveRoadmaps}
          objectivesAndKeyResultsWithHierarchy={objectivesAndKeyResultsWithHierarchy}
          isDodActive={isDodActive}
          parentOptions={parentOptions}
          isNew={isNew}
          openMetadataDrawer={_openMetadataDrawer}
          organizationId={organization.id}
          hasMultipleMetrics={hasMultipleMetrics}
          updateMetricBaseline={updateMetricBaseline}
          createMetricLinkToOkr={createMetricLinkToOkr}
          addMetricToOkr={addMetricToOkr}
          removeMetricFromOkr={removeMetricFromOkr}
          shouldShowAddNewRowOnTable={shouldShowAddNewRowOnTable}
          canUpdate={canUpdate}
          hideChildProgressOption={checkIfChildProgressOptionIsHidden(
            isObjectKeyResult,
            isObjectKeyResult2,
            shouldDisplayKeyResult2,
          )}
          isObjectObjective={isObjectObjective}
          isObjectObjectiveCorp={isObjectObjectiveCorp}
          isObjectKeyResult={isObjectKeyResult}
          isFormValid={isFormValid}
        />
      </ProjectsListLightboxProvider>
    );
  };
};

export default componentHOC;
