import isObject from 'lodash/isObject';
import { defaultTo, head, isNil, path, pathOr, pipe, prop } from 'ramda';
import reduceReducers from 'reduce-reducers';

import { getThunksReducers } from 'utils/store/thunk';

import { UPDATE_PROJECT_FULFILLED, CREATE_PROJECT_FULFILLED, REALTIME_BULK_UPDATE_PROGRESS } from 'store/projects';
import {
  FETCH_PROJECT_CUSTOMER_REQUESTS_FULFILLED,
  WATCH_PROJECT_FULFILLED,
  UNWATCH_PROJECT_FULFILLED,
  ADD_PROJECT_METRIC_FULFILLED,
  REMOVE_PROJECT_METRIC_FULFILLED,
  CREATE_AND_ADD_METRIC_TO_PROJECT_FULFILLED,
  BULK_REMOVE_PROJECT_METRICS_FULFILLED,
  BULK_ADD_PROJECT_METRICS_FULFILLED,
  BULK_UPDATE_PROJECTS_FULFILLED,
  TRANSFER_PROJECT_TO_OTHER_ACCOUNT_FULFILLED,
  UPDATE_PROJECT_PERSONAS_FULFILLED,
  UPDATE_PROJECT_LIFECYCLES_FULFILLED,
} from 'store/projects/types';
import { UPDATE_PROJECT_TASKS_FULFILLED } from 'store/tasks';
import {
  UPDATE_ESTIMATES_FULFILLED,
  CREATE_PROJECT_ESTIMATE_FULFILLED,
  UPDATE_PROJECT_ESTIMATE_FULFILLED,
  DELETE_PROJECT_ESTIMATE_FULFILLED,
} from 'store/estimates';
import {
  CREATE_PROJECT_INTEGRATION_FULFILLED,
  FETCH_PROJECT_INTEGRATIONS_FULFILLED,
  REMOVE_PROJECT_INTEGRATION_FULFILLED,
  JIRA_TICKET_DETAIL_FULFILLED,
  JIRA_UNLINK_FULFILLED,
  UPDATE_PROJECT_INTEGRATION_FULFILLED,
  ADD_PROJECT_INTEGRATION_FULFILLED,
} from 'store/integrations';

import {
  CREATE_PROJECT_FROM_CUSTOMER_REQUEST_FULFILLED,
  REMOVE_PROJECT_FROM_CUSTOMER_REQUEST_FULFILLED,
  CREATE_CUSTOMER_REQUEST_FROM_PROJECT_FULFILLED,
  ADD_CUSTOMER_REQUEST_FROM_PROJECT_FULFILLED,
} from 'store/customerRequests/types';

import {
  OPEN_PROJECT_LIGHTBOX_PENDING,
  OPEN_PROJECT_LIGHTBOX_FULFILLED,
  OPEN_PROJECT_LIGHTBOX_WITH_SCENARIO_FULFILLED,
  OPEN_PROJECT_LIGHTBOX_WITH_SCENARIO_PENDING,
  OPEN_PROJECT_LIGHTBOX_WITH_SCENARIO_REJECTED,
  OPEN_PROJECT_LIGHTBOX_REJECTED,
  OPEN_LIGHTBOX_TO_CREATE_PROJECT,
  OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_OKR,
  OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_REQUEST,
  CLOSE_PROJECT_LIGHTBOX,
  GET_PROJECT_INTEGRATION_PROGRESS_FULFILLED,
  SET_DATE_RANGE_MODE,
  SET_VISIBLE_FIELDS,
  SET_CURRENT_PROJECT_PARENT,
  LOAD_ESTIMATES_TAB_FULFILLED,
  LOAD_ESTIMATES_TAB_PENDING,
  LOAD_ESTIMATES_TAB,
  OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_METRIC,
} from './types';
import { TAB_DETAILS } from 'constants/projectLightbox';
import { METRIC, REQUEST } from 'constants/common';

const initialState = {
  lightboxOpen: false,
  loadingData: false,
  projectData: null,
  activeTabOnOpen: null,
  dateRangeMode: 'duration',
  visibleFields: null,
  errored: false,
  parentProjectData: null,
  scenarioSelected: null,
  disabledFields: [],
};

const ESTIMATES = 'estimates';
const ID = 'id';
const PROJECT_ID = 'project_id';

const getProjectData = path(['payload', 'projectData']);
const getParentData = path(['payload', 'parentData']);
const getScenario = path(['payload', 'scenario']);
const getDisabledFields = pathOr([], ['payload', 'disabledFields']);

const getProjectIdFromMetadata = action => action?.meta?.projectId;
const getProjectIdFromPayload = action => action?.payload?.projectId;
const isSameProject = (projectId, state) => +projectId === +state?.projectData?.id;
const removeProjectIntegrationById = (projectIntegrations, idToRemove) =>
  projectIntegrations.filter(projectIntegration => projectIntegration.id !== idToRemove);
const addProjectIntegrationToList = (projectIntegrations, projectIntegration) => [
  ...(projectIntegrations ?? []).filter(({ id } = {}) => id !== projectIntegration?.id),
  projectIntegration,
];
const defaultToEmptyArray = defaultTo([]);
const defaultToEmptyObject = defaultTo({});
const defaultToNull = defaultTo(null);

const getEstimates = pipe(defaultToEmptyObject, prop(ESTIMATES), defaultToEmptyArray);
const getId = prop(ID);
const getProjectId = prop(PROJECT_ID);

function projectLightboxReducer(state = initialState, action) {
  switch (action.type) {
    case SET_VISIBLE_FIELDS:
      const listOfFields = action.payload || [];

      return {
        ...state,
        visibleFields: [...listOfFields, ...['type', 'title', 'integrations', 'layerMetadata']],
      };
    case OPEN_PROJECT_LIGHTBOX_PENDING:
      const { tab, id, key } = action.meta;

      if (state.lightboxOpen) {
        return {
          ...state,
          projectData: { id, key },
          activeTabOnOpen: tab,
          errored: false,
        };
      }

      return {
        ...state,
        lightboxOpen: true,
        loadingData: true,
        activeTabOnOpen: tab,
        projectData: { id, key },
        errored: false,
        parentProjectData: null,
      };
    case OPEN_PROJECT_LIGHTBOX_WITH_SCENARIO_PENDING:
      if (state.lightboxOpen) {
        return {
          ...state,
          errored: false,
        };
      }

      return {
        ...state,
        lightboxOpen: true,
        loadingData: true,
        activeTabOnOpen: TAB_DETAILS,
        errored: false,
        parentProjectData: null,
      };
    case OPEN_PROJECT_LIGHTBOX_FULFILLED: {
      const { projectData, parentData } = action.payload;

      if (!state.projectData) return { ...state, loadingData: false };

      return {
        ...state,
        projectData: { ...state.projectData, ...projectData },
        parentProjectData: parentData,
        loadingData: false,
      };
    }
    case OPEN_PROJECT_LIGHTBOX_WITH_SCENARIO_FULFILLED: {
      const projectData = getProjectData(action);
      const parentData = getParentData(action);
      const scenario = getScenario(action);
      const disabledFields = getDisabledFields(action);

      return {
        ...state,
        projectData: { ...state?.projectData, ...projectData },
        parentProjectData: parentData,
        loadingData: false,
        scenarioSelected: scenario,
        disabledFields,
      };
    }
    case OPEN_PROJECT_LIGHTBOX_REJECTED:
    case OPEN_PROJECT_LIGHTBOX_WITH_SCENARIO_REJECTED:
      return {
        ...state,
        lightboxOpen: false,
        loadingData: false,
        activeTabOnOpen: null,
        projectData: null,
        parentProjectData: null,
        errored: true,
      };
    case SET_DATE_RANGE_MODE:
      return { ...state, dateRangeMode: action.payload };
    case OPEN_LIGHTBOX_TO_CREATE_PROJECT:
      return {
        ...state,
        lightboxOpen: true,
        activeTabOnOpen: null,
        defaultData: action.payload,
      };
    case OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_REQUEST:
      return {
        ...state,
        lightboxOpen: true,
        activeTabOnOpen: null,
        defaultData: action.payload,
        openFromEntity: {
          type: REQUEST,
          id: action.meta.requestId,
        },
      };
    case OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_METRIC:
      return {
        ...state,
        lightboxOpen: true,
        activeTabOnOpen: null,
        defaultData: action.payload,
        openFromEntity: {
          type: METRIC,
          id: action.meta.metricId,
        },
      };
    case OPEN_LIGHTBOX_TO_CREATE_PROJECT_FROM_OKR:
      return {
        ...state,
        lightboxOpen: true,
        activeTabOnOpen: null,
        defaultData: action.payload,
        openFromEntity: {
          type: action.meta.type,
          id: action.meta.okrId,
        },
      };
    case CLOSE_PROJECT_LIGHTBOX:
      return initialState;
    case ADD_PROJECT_METRIC_FULFILLED:
    case BULK_ADD_PROJECT_METRICS_FULFILLED:
    case BULK_REMOVE_PROJECT_METRICS_FULFILLED:
    case CREATE_AND_ADD_METRIC_TO_PROJECT_FULFILLED:
    case REMOVE_PROJECT_METRIC_FULFILLED:
    case TRANSFER_PROJECT_TO_OTHER_ACCOUNT_FULFILLED:
    case UPDATE_PROJECT_LIFECYCLES_FULFILLED:
    case UPDATE_PROJECT_PERSONAS_FULFILLED:
    case UPDATE_PROJECT_FULFILLED: {
      const { payload } = action;

      const project = payload.projectData || payload;
      const parent = payload?.parentData;

      if (!state.lightboxOpen || !state.projectData || state.projectData.id !== project.id) return state;

      const projectData = project || state.projectData;

      const parentProjectData = parent || parent === null ? parent : state.parentProjectData;

      return {
        ...state,
        projectData: { ...state.projectData, ...projectData },
        parentProjectData,
      };
    }
    case CREATE_PROJECT_FULFILLED: {
      const { payload, realtime } = action;

      if (realtime || !state.lightboxOpen || state.projectData) return state;

      return {
        ...state,
        projectData: payload,
      };
    }
    case JIRA_TICKET_DETAIL_FULFILLED: {
      const { data } = action.payload;
      let projectData = state.projectData || null;
      const createdNewProject = !state.projectData && state.lightboxOpen;

      if (createdNewProject || (state.projectData && state.projectData.id === data.project_id)) {
        projectData = {
          ...(state.projectData || {}),
          Jiras: [...(state.projectData.Jiras || []), data],
        };
      }
      return {
        ...state,
        projectData,
      };
    }
    case JIRA_UNLINK_FULFILLED: {
      const { data } = action.payload;
      let projectData = null;

      if (state.projectData && state.projectData.id === data.project_id) {
        projectData = {
          ...state.projectData,
          Jiras: state.projectData.Jiras.filter(j => j.key !== action.meta.issueKey),
          integrationProgress: null,
        };
      }
      return {
        ...state,
        projectData,
      };
    }
    case UPDATE_ESTIMATES_FULFILLED: {
      const { estimates = [] } = defaultToEmptyObject(action.payload);

      const updatedEstimatesRelatedWithCurrentProject = estimates.filter(
        estimate => getId(state.projectData) === getProjectId(estimate),
      );

      const allProjectEstimates = getEstimates(state.projectData).map(estimate => {
        const updatedEstimate = updatedEstimatesRelatedWithCurrentProject.find(({ id }) => id === estimate.id);

        return updatedEstimate || estimate;
      });

      return {
        ...state,
        projectData: {
          ...state.projectData,
          estimates: allProjectEstimates,
        },
      };
    }
    case CREATE_PROJECT_ESTIMATE_FULFILLED: {
      const { data } = defaultToEmptyObject(action.payload);
      const newEstimate = defaultToNull(data?.estimate);

      const isEstimateNotRelatedWithCurrentProject = isNil(newEstimate) || getId(state.projectData) !== getProjectId(newEstimate);

      if (isEstimateNotRelatedWithCurrentProject) {
        return state;
      }

      return {
        ...state,
        projectData: {
          ...state.projectData,
          estimates: [...getEstimates(state.projectData), { ...newEstimate }],
        },
      };
    }
    case UPDATE_PROJECT_ESTIMATE_FULFILLED: {
      const { data = [] } = defaultToEmptyObject(action.payload);
      const updatedEstimate = head(data);

      const isEstimateNotRelatedWithCurrentProject =
        isNil(updatedEstimate) || getId(state.projectData) !== getProjectId(updatedEstimate);

      if (isEstimateNotRelatedWithCurrentProject) {
        return state;
      }

      return {
        ...state,
        projectData: {
          ...state.projectData,
          estimates: getEstimates(state.projectData).map(estimate => {
            return estimate.id === updatedEstimate.id ? updatedEstimate : estimate;
          }),
        },
      };
    }
    case DELETE_PROJECT_ESTIMATE_FULFILLED: {
      const { projectId, estimate } = defaultToEmptyObject(action.payload);

      const isEstimateNotRelatedWithCurrentProject = isNil(estimate) || getId(state.projectData) !== projectId;

      if (isEstimateNotRelatedWithCurrentProject) {
        return state;
      }

      return {
        ...state,
        projectData: {
          ...state.projectData,
          estimates: getEstimates(state.projectData).filter(({ id }) => estimate.id !== id),
        },
      };
    }
    case UPDATE_PROJECT_TASKS_FULFILLED: {
      const { projectId } = action.meta || {};
      let { projectData } = state;

      if (projectData && projectData.id === projectId) {
        const { data } = action.payload;

        projectData = {
          ...projectData,
          tasks: data,
        };
      }
      return {
        ...state,
        projectData,
      };
    }
    case GET_PROJECT_INTEGRATION_PROGRESS_FULFILLED: {
      const { data: integrationProgress } = action.payload;
      const { id } = action.meta;

      let { projectData } = state;

      if (projectData && projectData.id === id) {
        const { data } = action.payload;

        projectData = {
          ...projectData,
          tasks: data,
        };
      }

      return {
        ...state,
        projectData: {
          ...state.projectData,
          integrationProgress,
        },
        loadingData: false,
      };
    }
    case FETCH_PROJECT_CUSTOMER_REQUESTS_FULFILLED: {
      if (
        !action.meta ||
        !action.meta.projectId ||
        !state.projectData ||
        !state.projectData.id ||
        +action.meta.projectId !== +state.projectData.id
      )
        return state;

      return {
        ...state,
        projectData: {
          ...state.projectData,
          customer_requests_ids: action.payload.map(({ id }) => id),
        },
      };
    }
    case REMOVE_PROJECT_FROM_CUSTOMER_REQUEST_FULFILLED: {
      if (
        !action.meta ||
        !action.meta.projectId ||
        !state.projectData ||
        !state.projectData.id ||
        +action.meta.projectId !== +state.projectData.id ||
        !action.meta.id
      )
        return state;

      return {
        ...state,
        projectData: {
          ...state.projectData,
          customer_requests_ids: state.projectData.customer_requests_ids.filter(id => +id !== +action.meta.id),
        },
      };
    }
    case ADD_CUSTOMER_REQUEST_FROM_PROJECT_FULFILLED:
    case CREATE_CUSTOMER_REQUEST_FROM_PROJECT_FULFILLED: {
      const { data } = action.payload;

      if (!data || !action.meta || !action.meta.id || !state.projectData.id || +action.meta.id !== +state.projectData.id) {
        return state;
      }

      return {
        ...state,
        projectData: {
          ...state.projectData,
          customer_requests_ids: [data.id, ...(state.projectData.customer_requests_ids || [])],
        },
      };
    }
    case CREATE_PROJECT_FROM_CUSTOMER_REQUEST_FULFILLED: {
      if (
        !action.payload ||
        !action.meta ||
        !action.meta.id ||
        !state.projectData ||
        !state.projectData.id ||
        +action.payload.id !== +state.projectData.id
      )
        return state;

      return {
        ...state,
        projectData: {
          ...state.projectData,
          customer_requests_ids: [action.meta.id, ...(state.projectData.customer_requests_ids || [])],
        },
      };
    }
    case REALTIME_BULK_UPDATE_PROGRESS: {
      if (!action.payload) return state;

      if (!action.payload || !state.projectData || !state.projectData.id) return state;

      const payloadProject = action.payload.find(({ id }) => +id === +state.projectData.id);

      if (!payloadProject) return state;

      return {
        ...state,
        projectData: {
          ...state.projectData,
          progress_calculated: payloadProject.progress_calculated,
        },
      };
    }
    case WATCH_PROJECT_FULFILLED: {
      return {
        ...state,
        projectData: {
          ...state.projectData,
          watchers: state.projectData.watchers?.some(w => w.user_id === action.payload.user_id)
            ? state.projectData.watchers
            : [...(state.projectData?.watchers ?? []), action.payload.user],
        },
      };
    }
    case UNWATCH_PROJECT_FULFILLED: {
      return {
        ...state,
        projectData: {
          ...state.projectData,
          watchers: state.projectData.watchers.filter(w => w.id !== action.meta.user_id),
        },
      };
    }
    case FETCH_PROJECT_INTEGRATIONS_FULFILLED: {
      const isCurrentProject = isSameProject(getProjectIdFromMetadata(action), state);

      return {
        ...state,
        projectData: {
          ...state.projectData,
          integrations: isCurrentProject ? action.payload?.data : [],
        },
      };
    }
    case CREATE_PROJECT_INTEGRATION_FULFILLED:
    case ADD_PROJECT_INTEGRATION_FULFILLED: {
      const isCurrentProject = isSameProject(getProjectIdFromMetadata(action) ?? getProjectIdFromPayload(action), state);

      const { projectData: payloadProjectData } = action.payload;

      const projectData = isCurrentProject && payloadProjectData ? payloadProjectData : state.projectData;

      const integrations = isCurrentProject
        ? addProjectIntegrationToList(state.projectData.integrations ?? [], action?.payload?.projectIntegration)
        : state.integrations;

      const updatedProjectData = {
        ...projectData,
        integrations,
      };

      return {
        ...state,
        projectData: updatedProjectData,
      };
    }
    case REMOVE_PROJECT_INTEGRATION_FULFILLED: {
      const isCurrentProject = isSameProject(getProjectIdFromMetadata(action) ?? getProjectIdFromPayload(action), state);

      return {
        ...state,
        projectData: {
          ...state.projectData,
          integrations: isCurrentProject
            ? removeProjectIntegrationById(state.projectData.integrations, +action?.payload?.projectIntegrationId)
            : state.projectData.integrations,
        },
      };
    }
    case UPDATE_PROJECT_INTEGRATION_FULFILLED: {
      const isCurrentProject = isSameProject(getProjectIdFromMetadata(action), state);

      return {
        ...state,
        projectData: {
          ...state.projectData,
          integrations: isCurrentProject && isObject(action.payload?.data) ? [action.payload?.data] : [],
        },
      };
    }
    case SET_CURRENT_PROJECT_PARENT: {
      const { parentData } = action.payload;

      return {
        ...state,
        parentProjectData: defaultToNull(parentData),
      };
    }

    case BULK_UPDATE_PROJECTS_FULFILLED: {
      const currentProjectUpdates = (action?.payload || []).find(project => isSameProject(project.id, state));

      if (!currentProjectUpdates) return state;

      return {
        ...state,
        projectData: {
          ...state.projectData,
          ...currentProjectUpdates,
        },
      };
    }
    case LOAD_ESTIMATES_TAB_PENDING: {
      return {
        ...state,
        projectData: {
          ...state.projectData,
          estimates: [],
        },
      };
    }
    case LOAD_ESTIMATES_TAB_FULFILLED: {
      const payloadEstimates = prop('estimates', action.payload || []);
      const existingProjectData = prop('projectData', state || {});

      return {
        ...state,
        projectData: {
          ...existingProjectData,
          estimates: payloadEstimates,
        },
      };
    }

    default:
      return state;
  }
}

const operationsReducer = getThunksReducers([LOAD_ESTIMATES_TAB]);

const reducer = reduceReducers(initialState, projectLightboxReducer, ...operationsReducer);

export default reducer;
