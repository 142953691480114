import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import {
  getIdeasIntegrations,
  getOrganization,
  hasMultiLevelPortfolioMetadata as hasMultiLevelPortfolioMetadataSelector,
} from 'store/organization/selectors';
import { getRoadmaps, getProducts, getCorpRoadmaps, getProductsLevelTwo } from 'store/roadmaps/selectors';
import { getThemes } from 'store/themes/selectors';
import { getObjectives, selectKeyResults1, selectKeyResults2 } from 'store/objectives/selectors';
import { getTimeframes, getTimeframesLevel2, getTimeframesLevelCorp } from 'store/timeframes/selectors';
import { getCategories, getCategoriesLevelCorp } from 'store/categories/selectors';
import { getPhases } from 'store/phases/selectors';
import { getPriorities } from 'store/priorities/selectors';
import { getTags } from 'store/tags/selectors';
import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import { getAllProjectsFromGroups } from 'store/projects/helpers/groupSelectors';
import { getPageFilters, getDisplayLayer, usePortfolioMode } from 'store/filters/selectors';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import { getIsFetching, getAllInitiatives, getAllBets } from 'store/projects/selectors';
import useProjectGroups from 'hooks/useProjectGroups';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import { getAllTeams } from 'store/teams/selectors';
import { getUsers } from 'store/users/selectors';
import { FORECAST_PAGE } from 'constants/filters';
import useDeepEffect from 'hooks/useDeepEffect';
import useSystemFields from 'hooks/useSystemFields';
import { filterMetadataOptions } from 'utils/metadataRoadmaps';
import { METADATA_LEVELS } from 'constants/common';

import parseProjectsToGantt from './parseProjectsToGantt';
import useOrganizationsAccessControl from '../../useOrganizationsAccessControl';

const emptyObject = {};

/**
 * Custom hook to use the projects on gantt timeline based on multiple options
 *
 * @param {*} pageId used page id
 * @param {*} options object with multiple options
 */
const useProjectsForGantt = (pageId, options) => {
  const [data, setData] = useState({ data: [], links: [] });
  const isFetching = useSelector(getIsFetching);
  const orgIntegrations = useSelector(getIdeasIntegrations);
  const displayLayer = useSelector(state => options.layer || getDisplayLayer(state));
  const pageFilters = useSelector(getPageFilters);
  const initiativesForGrouping = useSelector(getAllInitiatives);
  const betsForGrouping = useSelector(getAllBets);
  const portfolioModeState = useSelector(usePortfolioMode);
  const {
    projects,
    ideas,
    initiatives,
    bets,
    selectedTimeWindow,
    groupBy,
    portfolioMode: isPortfolioMode,
    layer,
    groupingOptions = emptyObject,
  } = useMemo(() => options, [options]);
  const portfolioMode = useMemo(() => {
    if (pageId === FORECAST_PAGE) {
      return false;
    }

    if (typeof isPortfolioMode === 'boolean') {
      return isPortfolioMode;
    }

    return portfolioModeState;
  }, [pageId, isPortfolioMode, portfolioModeState]);
  const products = useSelector(state => getStateDataForPage(state, getProducts, 'products'));
  const products2 = useSelector(state => getStateDataForPage(state, getProductsLevelTwo, 'products2'));
  const customFields = useSelector(state => getProjectsCustomFields(state, false));
  const users = useSelector(state => getUsers(state));
  const { has_bet: hasBet, enable_metadata_roadmaps: hasMetadataRoadmaps } = useSelector(getOrganization);
  // const [initiatives, bets] = useGetInitiativesAndBets();
  const [getSystemFieldName] = useSystemFields();
  const lastSet = useRef(null);
  const { isDodActive, getDefaultRoadmapForMetadataItem } = useOrganizationsAccessControl();

  const hasMultiLevelPortfolioMetadata = useSelector(hasMultiLevelPortfolioMetadataSelector);

  const metadataToFilter = useSelector(
    state => ({
      categoriesCorp: getStateDataForPage(state, getCategoriesLevelCorp, 'categories'),
      categories: getStateDataForPage(state, getCategories, 'categories'),
      keyResults1: getStateDataForPage(state, selectKeyResults1, 'keyResults'),
      keyResults2: getStateDataForPage(state, (state, showArchived) => selectKeyResults2(state, showArchived), 'keyResult2s'),
      objectivesCorp: getStateDataForPage(
        state,
        (state, showArchived) => getObjectives(state, showArchived, METADATA_LEVELS.LEVEL_CORP),
        'objectives',
      ),
      objectives: getStateDataForPage(state, getObjectives, 'objectives'),
      phases: getStateDataForPage(state, getPhases, 'phases'),
      priorities: getStateDataForPage(state, getPriorities, 'priorities'),
      roadmapsCorp: getStateDataForPage(state, (state, showArchived) => getCorpRoadmaps(state, showArchived), 'roadmaps'),
      roadmaps: getStateDataForPage(state, getRoadmaps, 'roadmaps'),
      teams: getStateDataForPage(state, getAllTeams, 'teams'),
      themes: getStateDataForPage(state, getThemes, 'themes'),
      timeframesCorp: getStateDataForPage(state, getTimeframesLevelCorp, 'timeframes'),
      timeframes: getStateDataForPage(state, getTimeframes, 'timeframes'),
      ...(hasMultiLevelPortfolioMetadata
        ? {
            timeframes2: getStateDataForPage(state, getTimeframesLevel2, 'timeframes'),
          }
        : {}),
      tags: getTags(state),
    }),
    isEqual,
  );

  const filteredMetadata = useMemo(
    () =>
      filterMetadataOptions(hasMetadataRoadmaps, projects, metadataToFilter, pageFilters.quickFilters, {
        isDodActive,
        getDefaultRoadmapForMetadataItem,
      }),
    [hasMetadataRoadmaps, projects, metadataToFilter, pageFilters.quickFilters, isDodActive, getDefaultRoadmapForMetadataItem],
  );

  const groupsConfig = {
    selectedGroup1: groupBy?.[0],
    selectedGroup2: groupBy?.[1],
    withHierarchy: portfolioMode,
    customMetadata: {
      ...filteredMetadata,
      products,
      products2,
      users,
      initiatives: initiativesForGrouping,
      bets: betsForGrouping,
    },
    customAllProjectsByLayer: {
      [IDEA_LAYER]: ideas,
      [INITIATIVE_LAYER]: initiatives,
      [BET_LAYER]: bets,
    },
    id: pageId,
    layer,
    ...groupingOptions,
  };

  const projectGroups = useProjectGroups(groupsConfig);

  const allProjectsFromGroups = useMemo(() => getAllProjectsFromGroups(projectGroups), [projectGroups]);

  const parseOptions = useMemo(
    () => ({
      ...options,
      getSystemFieldName,
      customFields,
      hasBet,
      projectGroups,
      allProjectsFromGroups,
    }),
    [options, getSystemFieldName, customFields, hasBet, projectGroups, allProjectsFromGroups],
  );

  const dataFromStore = {
    ...filteredMetadata,
    bets: bets || [],
    ideas: ideas || [],
    initiatives: initiatives || [],
    products,
    projects,
    initiativesForGrouping,
    betsForGrouping,
  };

  const [finishedFetch, setFinishedFetch] = useState(false);

  useEffect(() => setData({ data: [], links: [] }), [portfolioMode, displayLayer]);

  useEffect(() => {
    if (!finishedFetch && !isFetching) setFinishedFetch(true);
  }, [isFetching]);

  const [debouncedParser] = useDebouncedCallback(() => {
    let changedIds = null;
    const _shouldIgnoreChangesAsEverythingChanged = (all, changed) => (changed || []).length === all.length;

    const all = !options.portfolioMode
      ? projects
      : uniq([...(projects || []), ...(ideas || []), ...(initiatives || []), ...(bets || [])]);

    if (lastSet.current && all.length === Object.keys(lastSet.current).length) {
      changedIds = all.filter(o => !lastSet.current[o.id] || o.changed !== lastSet.current[o.id].changed).map(o => o.id);

      if (isEmpty(changedIds) || _shouldIgnoreChangesAsEverythingChanged(all, changedIds)) changedIds = null;
    }

    lastSet.current = {};
    all.forEach(o => (lastSet.current[o.id] = o));

    const parsedDataById = {};
    /*
     * Required to send the current display layer to show only the top
     * level of the current layer if we are showing initiatives should
     * show only initiatives and ideas as children
     */
    const parsedData = parseProjectsToGantt(dataFromStore, parseOptions, orgIntegrations, changedIds, displayLayer);

    parsedData.data.forEach(o => (parsedDataById[o.id] = o));

    let filteredData;

    if (changedIds) {
      filteredData = data.data; //= filterDataByTimeWindow(data.data, selectedTimeWindow);

      filteredData = filteredData.map(o => {
        if (Object.keys(parsedDataById).includes(o.id.toString())) {
          const result = parsedDataById[o.id];

          delete parsedDataById[o.id];
          return result;
        }

        return o;
      });

      filteredData = filteredData.concat(Object.values(parsedDataById));
    } else {
      filteredData = parsedData.data; // filterDataByTimeWindow(parsedData.data, selectedTimeWindow);
    }

    setData({
      data: filteredData,
      links: parsedData.links,
      changedIds,
    });
  }, 250);

  useDeepEffect(() => {
    debouncedParser();
  }, [projects, ideas, initiatives, bets, projectGroups, selectedTimeWindow]);

  useDeepEffect(
    changes => {
      const parsedData = parseProjectsToGantt(dataFromStore, parseOptions, orgIntegrations, null, displayLayer);

      setData({
        data: parsedData.data,
        links: parsedData.links,
      });
    },
    [
      filteredMetadata.roadmaps,
      filteredMetadata.roadmapsCorp,
      filteredMetadata.timeframes,
      filteredMetadata.timeframes2,
      filteredMetadata.themes,
      filteredMetadata.categorys,
      filteredMetadata.phases,
      filteredMetadata.objectives,
      filteredMetadata.objectivesCorp,
      filteredMetadata.teams,
      displayLayer,
      omit(options, ['ideas', 'initiatives', 'bets', 'projects', 'portfolioMode']),
    ],
  );

  return [data];
};

export default useProjectsForGantt;
