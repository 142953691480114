import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTo, equals } from 'ramda';

import {
  getIntegrationWebhooksProjectRestrictions as getIntegrationWebhooksProjectRestrictionsSelector,
  updateIntegrationWebhooksProjectRestrictions as updateIntegrationWebhooksProjectRestrictionsAction,
} from 'store/organization';

import { WEBHOOKS_PROJECT_RESTRICTIONS_TYPES } from 'constants/integrations';

const defaultToEmptyArray = defaultTo([]);

const isEqualsInclude = equals(WEBHOOKS_PROJECT_RESTRICTIONS_TYPES.INCLUDE);

const useIntegrationWebhooksProjectRestrictions = (integrationType, orgIntegrationId) => {
  const dispatch = useDispatch();

  /**
   * Temp workaround because it is being updated a part of the organization store slice.
   * Not possible to use getThunkActionTypes of utils/store/thunk
   */
  const [isUpdating, setIsUpdating] = useState(false);

  const webhooksProjectRestrictions = useSelector(getIntegrationWebhooksProjectRestrictionsSelector(orgIntegrationId));

  const includedProjects = useMemo(() => {
    if (isEqualsInclude(webhooksProjectRestrictions.type)) {
      return defaultToEmptyArray(webhooksProjectRestrictions.projects);
    }

    return [];
  }, [webhooksProjectRestrictions.type, webhooksProjectRestrictions.projects]);

  const updateWebhooksProjectRestrictions = useCallback(
    async data => {
      setIsUpdating(true);

      return dispatch(updateIntegrationWebhooksProjectRestrictionsAction(integrationType, orgIntegrationId, data)).finally(() => {
        setIsUpdating(false);
      });
    },
    [integrationType, orgIntegrationId],
  );

  return { webhooksProjectRestrictions, isUpdating, includedProjects, updateWebhooksProjectRestrictions };
};

export default useIntegrationWebhooksProjectRestrictions;
