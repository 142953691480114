import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import invertedTextColor from 'design-system/utils/invertedTextColor';

import {
  GROUP_PADDING,
  ROW_HEIGHT,
  withBottomBorder,
  withColor,
  withHeight,
  withIndent,
  withLeftAlignment,
  withLeftCoordinate,
  withRightBorder,
  withSplit,
  withStickyPosition,
  withTopAlignment,
  withWidth,
  withZIndex,
} from '../helpers';

const StyledIconButton = styled(({ bgColor, iconColor, ...rest }) => <IconButton {...rest} />)`
  &&&& {
    padding: 0;
    color: ${({ bgColor, iconColor }) => iconColor ?? invertedTextColor(bgColor, true)};
  }
`;

const TimelineScrollContainer = styled.div`
  &&&& {
    box-sizing: border-box;
    width: calc(100% - 88px);
    overflow: scroll;
    position: relative;
    border: 1px solid ${({ theme }) => theme.palette.border.mercury};
  }
`;

const TimelineDisplayTable = styled.div`
  display: table;
  position: relative;
  height: 100%;
  border-collapse: collapse;
`;

const TimelineHead = styled.div`
  &&&& {
    border-bottom: 3px solid;
    border-image: linear-gradient(0deg, #0e275200 0%, #0e27522c 100%) 30;

    ${withStickyPosition}
    ${withZIndex}
    ${withTopAlignment}
    ${withLeftAlignment}
  }
`;

const TimelineHeaderContainer = styled.div`
  &&&& {
    justify-content: center;
    align-items: center;
    line-height: 24px;
    background-color: ${({ theme }) => theme.palette.background.white};

    ${withStickyPosition}
    ${withTopAlignment}
    ${withLeftAlignment}
    ${withZIndex}
    ${withWidth}
    ${withHeight}
    ${withSplit}
    ${withRightBorder}
  }
`;

const TimelineHeaderTitle = styled(Typography)`
  &&&& {
    max-width: calc(100% - 40px);
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.lightestGrey};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 20px;
  }
`;

const TimelineBodyContainer = styled.div`
  position: relative;
  overflow: clip;
`;

const TimelineDndPlayground = styled.div`
  &&&& {
    position: absolute;
    top: ${({ isTopRowReserved }) => (isTopRowReserved ? `${ROW_HEIGHT}px` : 0)};
    left: ${({ groupColumnsWidth }) => `${groupColumnsWidth}px`};
    width: ${({ groupColumnsWidth }) => `calc(100% - ${groupColumnsWidth}px)`};
    height: ${({ isTopRowReserved }) => `calc(100% - ${isTopRowReserved ? ROW_HEIGHT : 0}px)`};
    opacity: 0.3;
    z-index: -1;
  }
`;

const TimelineRow = styled.div`
  &&&& {
    display: flex;
    width: 100%;
    min-height: ${ROW_HEIGHT}px;
  }
`;

const TimelineCell = styled.div`
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.background.white};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.border.white}`};

  ${({ center }) =>
    center &&
    `
      display: flex;
      align-items: center;
    `}

  ${({ rowsCount }) =>
    rowsCount &&
    `
      height: ${rowsCount * ROW_HEIGHT}px;
      max-height: ${rowsCount * ROW_HEIGHT}px;
    `}

  ${withRightBorder}
  ${withBottomBorder}
  ${withStickyPosition}
  ${withLeftCoordinate}
  ${withWidth}
  ${withZIndex}
  ${withSplit}
  ${withColor}
  ${withIndent}
`;

const TimelineCellContent = styled.div`
  &&&& {
    box-sizing: border-box;
    height: ${ROW_HEIGHT}px;
  }
`;

const TimelineCellTitle = styled.p`
  &&&& {
    max-width: 100%;
    box-sizing: border-box;
    margin: 5px 10px;
    color: ${({ theme }) => theme.palette.text.primary};
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    font-weight: ${({ bold }) => (bold ? 500 : 'normal')}
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    display: -webkit-box;
    -webkit-line-clamp: ${({ lines }) => lines};
    -webkit-box-orient: vertical;
    user-select: none;
  }
`;

const TimelineGroupWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  padding: ${GROUP_PADDING}px;
  overflow: hidden;
  display: flex;
  align-items: center;
  font-size: 14px;
  height: ${ROW_HEIGHT - GROUP_PADDING}px;
  cursor: pointer;
  z-index: 1;
  margin-top: ${GROUP_PADDING}px;

  background-color: ${({ bgColor }) => bgColor};
  color: ${({ bgColor, color }) => color ?? invertedTextColor(bgColor, true)};
  left: ${({ left }) => `${left - 5}px`};
  width: ${({ width }) => `${width + 10}px`};

  span {
    flex: 1 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;
  }
`;

const TimelineGroupContent = styled.div`
  box-sizing: border-box;
  left: ${({ left }) => `${left - 5}px`};
  width: ${({ width }) => `${width + 10}px`};
  height: ${({ height }) => `${height}px`}
  border: ${({ isDashed, color, theme }) =>
    isDashed ? `1px dashed ${color ?? theme.pallete.border.platinum}` : `1px solid ${color ?? theme.pallete.border.platinum}`};

  ${({ isOpen }) => (isOpen ? 'display: block;' : 'display: none;')}
  ${({ isTop, isOpen }) => {
    if (isTop && !isOpen) {
      return 'display: block;';
    }

    return isTop ? 'border-bottom: none;' : 'border-top: none;';
  }}

  background: transparent;
  position: absolute;
  padding: 5px;
`;

const TimelineGroupBorder = styled.div`
  box-sizing: border-box;

  left: ${({ left }) => `${left - GROUP_PADDING}px`};
  width: ${({ width }) => `${width + 10}px`};
  height: ${({ height }) => `${height - 5}px`}
  border: ${({ isDashed, color, theme }) =>
    isDashed ? `1px dashed ${color ?? theme.pallete.border.platinum}` : `1px solid ${color ?? theme.pallete.border.platinum}`};

  ${({ hasBottom }) => (!hasBottom ? 'border-bottom: none;' : '')}
  ${({ hasTop }) => (!hasTop ? 'border-top: none;' : '')}

  background: transparent;
  position: absolute;
  padding: ${GROUP_PADDING}px;
  margin-top: ${GROUP_PADDING}px;
`;

TimelineScrollContainer.displayName = 'TimelineScrollContainer';
TimelineDisplayTable.displayName = 'TimelineDisplayTable';
TimelineHead.displayName = 'TimelineHead';
TimelineHeaderContainer.displayName = 'TimelineHeaderContainer';
TimelineHeaderTitle.displayName = 'TimelineHeaderTitle';

TimelineBodyContainer.displayName = 'TimelineBodyContainer';
TimelineDndPlayground.displayName = 'TimelineDndPlayground';

TimelineRow.displayName = 'TimelineRow';

TimelineCell.displayName = 'TimelineCell';
TimelineCellContent.displayName = 'TimelineCellContent';
TimelineCellTitle.displayName = 'TimelineCellTitle';
TimelineGroupWrapper.displayName = 'TimelineGroupWrapper';
TimelineGroupContent.displayName = 'TimelineGroupContent';
TimelineGroupBorder.displayName = 'TimelineGroupBorder';

export {
  StyledIconButton,
  TimelineScrollContainer,
  TimelineDisplayTable,
  TimelineHead,
  TimelineHeaderContainer,
  TimelineHeaderTitle,
  TimelineBodyContainer,
  TimelineDndPlayground,
  TimelineRow,
  TimelineCell,
  TimelineCellContent,
  TimelineCellTitle,
  TimelineGroupWrapper,
  TimelineGroupContent,
  TimelineGroupBorder,
};
