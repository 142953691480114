import React from 'react';
import { EditOutlined, SaveOutlined, Settings, VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { isNil } from 'ramda';
import HistoryIcon from '@material-ui/icons/History';

const buildToolbarActions = ({ visibility, edit, settings, history }) => {
  const actions = [];

  if (!isNil(visibility)) {
    const actionVisibilityOff = { icon: <VisibilityOffOutlined id="visible_icon" />, onClick: visibility.onChange };
    const actionVisibilityOn = { icon: <VisibilityOutlined id="visible_icon" />, onClick: visibility.onChange };

    actions.push(visibility.value ? actionVisibilityOn : actionVisibilityOff);
  }

  if (!isNil(edit)) {
    const actionBeginEdit = { icon: <EditOutlined />, onClick: edit.onChange };
    const actionEndEdit = { icon: <SaveOutlined />, onClick: edit.onChange };

    actions.push(edit.value ? actionEndEdit : actionBeginEdit);
  }

  if (!isNil(settings)) {
    const actionSettings = { icon: <Settings />, onClick: settings.onClick };

    actions.push(actionSettings);
  }

  if (!isNil(history)) {
    const actionSettings = { icon: <HistoryIcon />, onClick: history.onClick };

    actions.push(actionSettings);
  }

  return actions;
};

export default buildToolbarActions;
