import { useMemo } from 'react';

import { useSettingsGridProps } from 'containers/Grids/SettingsGrid/hooks';

import { PERMISSION_RESOURCES, REFERER_SETTINGS } from '@dragonboat/permissions';

import usePermissions from 'hooks/permissions/usePermissions';

import { isParentItem, isActive, getDataPath, getRowId } from '../helpers';

const useTimeframesGridProps = ({ createTimeframes, updateTimeframes, hasMultiLevelPortfolioMetadata, hasCorpLevel }) => {
  const { canView } = usePermissions();
  const treeData = hasMultiLevelPortfolioMetadata || hasCorpLevel;
  const settingsGridProps = useSettingsGridProps({
    bulkCreate: createTimeframes,
    bulkUpdate: updateTimeframes,
    treeData,
  });

  const isRowSelectable = params => params.data && params.data.isSelectable;

  return useMemo(
    () => ({
      ...settingsGridProps,
      rowClassRules: {
        'metadata-parent-row': params => {
          if (!params.data) {
            return false;
          }

          return isParentItem(
            params.data,
            canView(PERMISSION_RESOURCES.timeframeCorp, { referer: REFERER_SETTINGS }),
            hasMultiLevelPortfolioMetadata,
          );
        },
        'metadata-child-row': params => {
          if (!params.data) {
            return false;
          }

          return !isActive(params.data);
        },
      },
      groupDisplayType: 'custom',
      isRowSelectable,
      suppressRowClickSelection: true,
      getDataPath,
      getRowId,
      context: {
        treeData,
      },
    }),
    [settingsGridProps, hasMultiLevelPortfolioMetadata, canView, treeData],
  );
};

export default useTimeframesGridProps;
