import { LexoRank } from 'lexorank';

const DEFAULT_RANK_FIELD = 'rank';

const movingBetweenTwo = (previons, next) => previons && next;
const movingToFirst = (previons, next) => previons && !next;
const movingToLast = (previons, next) => !previons && next;

export const getNewLexorank = (previousLex, nextLex) => {
  let projectLexorank = null;

  if (movingBetweenTwo(previousLex, nextLex)) {
    projectLexorank = previousLex.between(nextLex);
  } else if (movingToFirst(previousLex, nextLex)) {
    projectLexorank = previousLex.genNext();
  } else if (movingToLast(previousLex, nextLex)) {
    projectLexorank = nextLex.genPrev();
  } else {
    projectLexorank = LexoRank.middle();
  }

  return projectLexorank.toString();
};

export const moveRowToPositionUsingLexorank = (data, id1, id2, position, rankField = DEFAULT_RANK_FIELD) => {
  const closeRow = data.find(({ id }) => id === +id2);
  const movingRow = data.find(({ id }) => id === +id1);

  const closeRowIndex = data.findIndex(r => r.id === closeRow.id);

  const prevRow = position === 'bottom' ? data[closeRowIndex + 1] : closeRow;
  const nextRow = position === 'bottom' ? closeRow : data[closeRowIndex - 1];

  const prevLex = prevRow?.[rankField] ? LexoRank.parse(prevRow[rankField]) : null;
  const nextLex = nextRow?.[rankField] ? LexoRank.parse(nextRow[rankField]) : null;

  const newLex = getNewLexorank(prevLex, nextLex);

  return {
    ...movingRow,
    [rankField]: newLex,
  };
};
