// External dependencies
import pick from 'lodash/pick';
import moment from 'moment-timezone';

// Dragonboat dependencies
import getEstimateWeekDur from 'utils/getEstimateWeekDur';
import { mapToAutocompleteSuggestion } from 'utils/mapToAutocompleteSuggestion';
import formatTwoDecimalPlaces from 'utils/estimates/formatToTwoDecimals';

export const estimateDefaults = {
  id: null,
  startDate: moment(),
  endDate: moment().add(6, 'days'),
  duration: 14,
  durationWeeks: 1,
  team: null,
  skill: null,
  numStaff: 1,
  createAnother: false,
};

export const parseEstimateToForm = estimate => {
  return {
    ...pick(estimate, Object.keys(estimateDefaults)),
    startDate: moment(estimate.start_date).parseFromGantt(),
    endDate: moment(estimate.start_date).parseFromGantt(),
    numStaff: estimate.numStaff || 1,
    durationWeeks: getEstimateWeekDur(formatTwoDecimalPlaces(estimate.duration)),
    team: estimate.team ? mapToAutocompleteSuggestion(estimate.team) : null,
    skill: estimate.skill ? mapToAutocompleteSuggestion(estimate.skill) : null,
    storyPoints: estimate?.story_points,
  };
};

export const parseForm = formData => {
  return {
    ...formData,
    start_date: formData.startDate,
    end_date: formData.endDate,
    durationWeeks: +formData.durationWeeks,
  };
};
