export default (taskId, projects) => {
  if (!projects) return {};
  let taskIndex;
  const projectIndex = projects.findIndex(project => {
    if (!project.has('tasks')) return false;

    taskIndex = project.get('tasks').findIndex(estimate => {
      return estimate.get('id') === taskId;
    });
    return taskIndex >= 0;
  });

  return { taskIndex, projectIndex };
};
