import axios from 'axios';
import { useCallback, useEffect, useState, useMemo } from 'react';

import useSystemFields from 'hooks/useSystemFields';
import usePageFilters from 'hooks/filters/usePageFilters';
import getDisplayLayerLabel from 'store/projects/helpers/getDisplayLayerLabel';

import { DASHBOARDS_PAGE } from 'constants/filters';
import { defaultTo, isNil } from 'ramda';

const exists = Boolean;
const CHART_API_URL = '/api/v1/reports/data';

const requestChartData = async requestPayload => {
  return axios.post(CHART_API_URL, requestPayload);
};

const getStackedChartLabel = groupByLabel => groupByLabel;
const getStackedChartSubtitle = stackByLabel => `by ${stackByLabel}`;

const getGroupChartLabel = displayLayerLabel => displayLayerLabel;
const getGroupChartSubtitle = groupByLabel => `by ${groupByLabel}`;

/**
 * A custom hook for fetching and managing configurable chart data.
 *
 * @param {Object} options - Configuration options for fetching chart data.
 * @param {string} options.groupBy - The grouping parameter for the chart data.
 * @param {string} options.stackBy - The stacking parameter for the chart data.
 * @returns {Object} An object containing chart data and loading state.
 */
const useConfigurableChartData = ({ groupBy, stackBy, label }) => {
  const [state, setState] = useState({
    data: null,
    loading: false,
  });

  const { filtersForApiByLevels } = usePageFilters(DASHBOARDS_PAGE);

  const makeChartData = useCallback(async () => {
    setState(state => ({ ...state, loading: true }));

    try {
      const requestPayload = {
        groupBy,
        stackBy,
        filters: filtersForApiByLevels,
      };
      const { data } = await requestChartData(requestPayload);

      setState({
        data: data.dataset,
        groupsMetadata: data.groupsMetadata,
        stacksMetadata: data.stacksMetadata,
        groupedBy: data.groupedBy,
        stackedBy: data.stackedBy,
        bucketInformation: defaultTo({}, data.bucketInformation),
        loading: false,
      });
    } catch {
      setState({ loading: false });
    }
  }, [groupBy, stackBy, filtersForApiByLevels]);

  useEffect(() => {
    makeChartData();
  }, [makeChartData]);

  const { displayLayer } = usePageFilters();
  const [getSystemFieldName] = useSystemFields();
  const displayLayerLabel = useMemo(() => getDisplayLayerLabel(displayLayer, getSystemFieldName), [displayLayer]);

  const title = useMemo(() => {
    if (state.loading) {
      return 'Loading...';
    }

    /*
     * Will support static label when user change the label of the
     * chart and save it on the widget
     */
    if (exists(label)) {
      return label;
    }

    const hasStackedBy = !isNil(state.stackedBy);

    return hasStackedBy ? getStackedChartLabel(state.groupedBy?.title) : getGroupChartLabel(displayLayerLabel);
  }, [label, state.groupedBy, state.stackedBy, state.loading]);

  const subtitle = useMemo(() => {
    if (state.loading) {
      return 'Loading...';
    }

    const hasStackedBy = !isNil(state.stackedBy);

    return hasStackedBy ? getStackedChartSubtitle(state.stackedBy?.title) : getGroupChartSubtitle(state.groupedBy?.title);
  }, [label, state.groupedBy, state.stackedBy, state.loading]);

  return {
    chartData: state.data,
    loading: state.loading,
    groupsMetadata: state.groupsMetadata,
    stacksMetadata: state.stacksMetadata,
    bucketInformation: state.bucketInformation,
    title,
    subtitle,
  };
};

export default useConfigurableChartData;
