import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import {
  getHasAdvancedMetricReporting,
  getHasMultipleMetrics,
  selectHasKeyResults,
  selectHasKeyResults2,
} from 'store/organization';
import { updateState } from 'store/goalMode';
import { getGoalModeSearchText, selectIsLoadingOutcomeGoals } from 'store/goalMode/selectors';
import { GOAL_MODE, saveGridConfig, saveGridState as saveGridStateAction } from 'store/grids';
import { getGridConfigValue } from 'store/grids/selectors';
import { getCurrentUser } from 'store/login/selectors';

import useUpdateMetadataByFieldId from 'hooks/useUpdateMetadataByFieldId';

import { CORP_OBJECTIVE_KEY, OBJECTIVE_CORP_LEVEL, OBJECTIVE_KEY, OBJECTIVE_LEVEL } from 'constants/objectives';
import useSingleMetricsForm from 'hooks/objectives/useSingleMetricsForm';
import useMultipleMetricsGridActions from 'hooks/objectives/useMultipleMetricsGridActions';
import { getMetrics } from 'store/metrics/selectors';
import { OBJECT_KEY_RESULT, OBJECT_OBJECTIVE } from 'store/objectives';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import filterOkrsBySearchText from '../helpers/filterOkrsBySearchText';
import { selectGoalsGridKeyResults, selectGoalsGridObjectives } from '../store/selectors';
import buildGoalsGridRows from '../helpers/buildGoalsGridRows';
import { loadOutcomeGoalsFromUserViewApplied } from 'store/goalMode/thunks';

const objectiveLevels = [OBJECTIVE_CORP_LEVEL, OBJECTIVE_LEVEL];
const OBJECTIVE = 'objective';
// for update the action is the same for keyResult1 and keyResult2
const KEY_RESULT = 'keyResult1';

/**
 * @function useGoalModeGrid hook that will prepare the data to be rendered on the table.
 * It take into consideration the page settings and filters
 * @return {type} {description}
 */
const useGoalModeGrid = (isTreeView = true) => {
  const dispatch = useDispatch();

  const { canView } = usePermissions();

  const hasOkrCorpLevel = canView(PERMISSION_RESOURCES.objectiveCorp);
  const objectiveTopLevelKey = hasOkrCorpLevel ? CORP_OBJECTIVE_KEY : OBJECTIVE_KEY;

  const metrics = useSelector(getMetrics);
  // Include archived objectives for advanced search by status
  const objectives = useSelector(selectGoalsGridObjectives);
  const keyResults = useSelector(selectGoalsGridKeyResults);
  const isLoading = useSelector(selectIsLoadingOutcomeGoals);

  const hasKeyResults = useSelector(selectHasKeyResults);
  const hasKeyResults2 = useSelector(selectHasKeyResults2);
  const currentUser = useSelector(getCurrentUser);
  const isAnonymousUser = currentUser.is_anonymous;
  const hasAdvancedMetricReporting = useSelector(getHasAdvancedMetricReporting);
  const hasMultipleMetrics = useSelector(getHasMultipleMetrics);

  const isNewRow = okr => !okr.id;
  const hasNewRow = useMemo(() => objectives.some(isNewRow), [objectives]);
  const emptyKeyResultRows = useMemo(() => keyResults.filter(isNewRow), [keyResults]);

  const rowHeight = useSelector(state => getGridConfigValue(state, GOAL_MODE, 'rowHeight'));
  const gridState = useSelector(state => getGridConfigValue(state, GOAL_MODE, 'gridState', {}));
  const saveGridState = useCallback(
    (state, makesActiveViewDirty = true) => dispatch(saveGridStateAction(GOAL_MODE, state, makesActiveViewDirty)),
    [dispatch],
  );

  const searchString = useSelector(getGoalModeSearchText);
  const updateSearchString = val => dispatch(updateState({ searchText: val }));
  const [debouncedUpdateSearchString] = useDebouncedCallback(updateSearchString, 500);

  const onUpdateGridConfig = useCallback((key, value) => dispatch(saveGridConfig(GOAL_MODE, key, value)), [dispatch]);

  const { updateMetadataByFieldId } = useUpdateMetadataByFieldId();
  const { addMetricToOkr } = useSingleMetricsForm();
  const { createNewMetric, updateOkrMetrics } = useMultipleMetricsGridActions();

  const updateMetricOnOkr = useCallback(
    (okr, originalValue, field, metadataType) => {
      const okrType = metadataType === OBJECTIVE ? OBJECT_OBJECTIVE : OBJECT_KEY_RESULT;

      if (hasMultipleMetrics) {
        return updateOkrMetrics(okr.id, originalValue, okrType, field);
      }
      const metric = metrics.find(m => m.name === field.metric_name);

      if (!metric) {
        return;
      }

      return addMetricToOkr(okr, okrType, metric.id);
    },
    [addMetricToOkr, hasMultipleMetrics, metrics, updateOkrMetrics],
  );

  const handleUpdateMetadataFieldById = useCallback(
    (id, field, oldValue, cellData) => {
      const metadataType = objectiveLevels.includes(cellData?.data?.level) ? OBJECTIVE : KEY_RESULT;
      const shouldUpdateMetric = Boolean(field.metric_name) || Boolean(field.metrics);

      if (shouldUpdateMetric) {
        return updateMetricOnOkr(cellData?.data, oldValue, field, metadataType);
      }

      return updateMetadataByFieldId(metadataType, id, field, oldValue, cellData);
    },
    [updateMetadataByFieldId, updateMetricOnOkr],
  );

  const createMetricOption = useCallback(
    name => {
      return createNewMetric(name);
    },
    [createNewMetric],
  );

  const goalsRowsData = useMemo(() => {
    const validKeyResults = !hasKeyResults
      ? []
      : keyResults.filter(kr => {
          return !kr.parent_id || (kr.parent_id && hasKeyResults2);
        });

    const goalsGridRows = buildGoalsGridRows(objectives, validKeyResults);

    if (hasNewRow) {
      const newRow = objectives.find(isNewRow);

      return [newRow, ...goalsGridRows.filter(okr => okr.id)];
    }

    return searchString ? filterOkrsBySearchText(goalsGridRows, searchString) : goalsGridRows;
  }, [emptyKeyResultRows, hasKeyResults, hasKeyResults2, searchString]);

  const onSetPageUserView = useCallback(appliedView => {
    dispatch(loadOutcomeGoalsFromUserViewApplied(appliedView));
  }, []);

  return {
    isLoading,
    rowHeight,
    gridState,
    goalsRowsData,
    objectiveTopLevelKey,
    hasKeyResults,
    hasKeyResults2,
    currentUser,
    hasAdvancedMetricReporting,
    isAnonymousUser,
    searchString,

    onUpdateGridConfig,
    onSetPageUserView,
    handleUpdateMetadataFieldById,
    saveGridState,
    createMetricOption,
    updateSearchString: debouncedUpdateSearchString,
  };
};

export default useGoalModeGrid;
