import { defaultTo } from 'ramda';

const emptyArray = [];
const defaultToEmptyArray = defaultTo(emptyArray);

const metadataProxyHandler = {
  get(target, property) {
    return defaultToEmptyArray(target[property]);
  },
};

export const buildMetadataProxy = (data = {}) => {
  return new Proxy(data, metadataProxyHandler);
};
