import { useCallback, useMemo } from 'react';

import {
  getTitleColumnDef,
  getMetadataRoadmapColumnDef,
  statusColumnDef,
  colorColumnDef,
  numericColumnDef,
  updatedByColumnDef,
  updatedColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';

import useRoadmapsMetadata from 'routes/Settings/hooks/useRoadmapsMetadata';
import useTeams from './useTeams';

import GroupHeaderInnerRenderer from '../components/GroupHeaderInnerRenderer';
import { TITLE_FIELD, TEAM_KEY, TEAM2_KEY, TEAM_ROADMAP_FIELD, TEAM_LEVEL } from '../helpers/constants';
import { makeFormatIntegerLimit } from '../helpers';
import getSystemFieldName from 'utils/getSystemFieldName';

import { GROUP_COLUMN_INITIAL_WIDTH } from 'constants/grid';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';

const getTeamCostColumnDef = allowActions => ({
  ...numericColumnDef,
  field: 'cost',
  headerName: 'Cost per Staff Week',
  width: 150,
  suppressMovable: true,
  cellEditorParams: {
    allowNegative: false,
  },
  decimalScale: 0,
  format: makeFormatIntegerLimit(9),
  editable: allowActions,
});

const getColorColumnDef = allowActions => ({
  ...colorColumnDef,
  editable: allowActions,
});

const getStatusColumnDef = allowActions => ({
  ...statusColumnDef,
  editable: allowActions,
});

const teamUpdatedByColumnDef = {
  ...updatedByColumnDef,
  field: 'updatedByName',
  editable: false,
  suppressMovable: true,
};

const useTeamsGridColumns = ({ roadmapColumn, canAdd, allowActions, addTeamWithoutSave }) => {
  const { canView } = usePermissions();
  const { systemFields, hasTeamsLevelTwo } = useTeams();
  const estimationByPointsEnabled = canView(PERMISSION_FEATURES.usePointsToEstimate);

  const levelOneTitle = getSystemFieldName(TEAM_KEY, systemFields);
  const levelTwoTitle = getSystemFieldName(TEAM2_KEY, systemFields);

  const { createTeamRoadmap, deleteTeamRoadmap, teams, bulkDeleteTeamRoadmaps, hideMetadataRoadmaps } = roadmapColumn;

  const titleHeaderName = useMemo(
    () => [levelOneTitle, levelTwoTitle].filter(Boolean).join(' > '),
    [levelOneTitle, levelTwoTitle],
  );

  const checkAddVisibility = useCallback(
    ({ data }) => {
      return canAdd && data?.level === TEAM_LEVEL;
    },
    [canAdd],
  );

  const checkIsDisabledWorkdaysPerPoint = useCallback(
    ({ data }) => {
      return allowActions && data?.level === TEAM_LEVEL;
    },
    [allowActions],
  );

  const groupColumnDef = useMemo(
    () => ({
      headerName: titleHeaderName,
      field: TITLE_FIELD,
      suppressMovable: true,
      editable: allowActions,
      headerClass: 'first-field',
      cellClass: 'ag-title-cell',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: () => ({
        suppressCount: true,
        suppressDoubleClickExpand: true,
        innerRenderer: GroupHeaderInnerRenderer,
        handleAddWithoutSave: addTeamWithoutSave,
        checkAddVisibility,
      }),
      width: GROUP_COLUMN_INITIAL_WIDTH,
    }),
    [titleHeaderName, allowActions, checkAddVisibility, addTeamWithoutSave],
  );

  const workdaysPerPointColumnDef = useMemo(
    () => ({
      ...numericColumnDef,
      field: 'workdays_per_point',
      headerName: 'Work day per Story Point',
      width: 150,
      suppressMovable: true,
      cellRendererParams: {
        withoutZeros: true,
      },
      cellEditorParams: {
        allowNegative: false,
      },
      valueGetter: ({ data }) => {
        if (data?.level === TEAM_LEVEL) {
          return data?.workdays_per_point;
        }
        return 0;
      },
      decimalScale: 2,
      editable: checkIsDisabledWorkdaysPerPoint,
    }),
    [checkIsDisabledWorkdaysPerPoint],
  );

  const titleColumnDef = useMemo(() => {
    if (hasTeamsLevelTwo) return groupColumnDef;

    return {
      ...getTitleColumnDef('Title'),
      pinned: 'left',
      editable: allowActions,
    };
  }, [hasTeamsLevelTwo, allowActions]);

  const roadmapsMetadata = useRoadmapsMetadata(createTeamRoadmap, deleteTeamRoadmap, teams, TEAM_KEY, bulkDeleteTeamRoadmaps);

  return useMemo(
    () => [
      titleColumnDef,
      getMetadataRoadmapColumnDef({
        roadmapsMetadata: [...roadmapsMetadata, hideMetadataRoadmaps],
        editableForNewRow: false,
        editable: allowActions,
        field: TEAM_ROADMAP_FIELD,
        idKey: 'uniqueId',
      }),
      getStatusColumnDef(allowActions),
      getColorColumnDef(allowActions),
      getTeamCostColumnDef(allowActions),
      ...(estimationByPointsEnabled ? [workdaysPerPointColumnDef] : []),
      teamUpdatedByColumnDef,
      updatedColumnDef,
    ],
    [roadmapsMetadata, hideMetadataRoadmaps, workdaysPerPointColumnDef, estimationByPointsEnabled],
  );
};

export default useTeamsGridColumns;
