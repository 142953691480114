// External dependencies
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import flatten from 'lodash/flatten';
import { allPass, defaultTo, filter, prop, reduceBy } from 'ramda';

// Dragonboat depencies
import { filterActiveItens, makeFilterByLevel } from 'utils';

import { METADATA_LEVELS } from 'constants/common';
import { getPermissionsSelector } from 'hooks/permissions/getPermissionsSelector';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';
import { hideArchived } from './helpers';

const defaultToEmptyArray = defaultTo([]);
const defaultEmptyArray = [];
const reduceById = reduceBy((_, r) => r, {}, prop('id'));

export function getState(state) {
  return state.roadmaps;
}

export const getAllRoadmaps = state => defaultTo(defaultEmptyArray, getState(state)?.roadmaps);

export const getCorpAccountRoadmap = createSelector(getAllRoadmaps, roadmaps => roadmaps.find(r => r.is_corp_roadmap));

export const getCrossAccountRoadmaps = createSelector(getAllRoadmaps, roadmaps => {
  const filters = allPass([r => !r.is_corp_roadmap, makeFilterByLevel(METADATA_LEVELS.LEVEL_CORP)]);

  return filter(filters, roadmaps);
});

const getAllCorpRoadmaps = createSelector(getAllRoadmaps, getPermissionsSelector, (roadmaps, permissions) => {
  const filters = allPass([
    roadmap => permissions.canView(PERMISSION_RESOURCES.roadmapCorp, { roadmap }),
    makeFilterByLevel(METADATA_LEVELS.LEVEL_CORP),
  ]);

  return filter(filters, roadmaps);
});

const getActiveCorpRoadmaps = createSelector(getAllCorpRoadmaps, corpRoadmaps => hideArchived(corpRoadmaps));

export const getCorpRoadmaps = (state, showArchived) => (showArchived ? getAllCorpRoadmaps(state) : getActiveCorpRoadmaps(state));

export const getL1Roadmaps = createSelector([getAllRoadmaps, getPermissionsSelector], (roadmaps, permissions) => {
  const filterPermissions = roadmap => permissions.canView(PERMISSION_RESOURCES.roadmap, { roadmap });

  return filter(allPass([makeFilterByLevel(METADATA_LEVELS.LEVEL_1), filterPermissions]), roadmaps);
});

export const getActiveL1Roadmaps = createSelector(getL1Roadmaps, roadmaps => hideArchived(roadmaps));

export const getRoadmaps = (state, showArchived) => (showArchived ? getL1Roadmaps(state) : getActiveL1Roadmaps(state));

export const makeGetProducts = () => {
  return createCachedSelector(
    (state, showArchived) => getRoadmaps(state, showArchived),
    (_, showArchived) => showArchived,
    (roadmaps, showArchived) => {
      const result = flatten(roadmaps.map(o => o.products)).filter(data => data);

      if (showArchived) return result;
      return result.filter(filterActiveItens);
    },
  )((_, showArchived) => `products-${showArchived}`);
};

export const getProducts = makeGetProducts();

export const getProductsLevelTwo = createCachedSelector(getRoadmaps, (roadmaps, showArchived) => {
  const parentProducts = flatten(roadmaps.map(roadmap => roadmap.products || []));
  const products = flatten(parentProducts.map(product => product.products || []));

  if (showArchived) {
    return products;
  }

  return products.filter(filterActiveItens);
})((_, showArchived = false) => `products-level-2-${String(showArchived)}`);

export const getNormalizedRoadmaps = createSelector(getAllRoadmaps, roadmaps => reduceById(roadmaps));

export const getNormalizedCorpRoadmaps = createSelector(getAllCorpRoadmaps, roadmaps => reduceById(roadmaps));

export const makeGetNormalizedProducts = () => {
  const _localGetProducts = makeGetProducts();

  return createSelector(
    state => _localGetProducts(state, true, false),
    state =>
      state.reduce((objs, obj) => {
        objs[obj.id] = obj;
        return objs;
      }, {}),
  );
};

export const getNormalizedProducts = makeGetNormalizedProducts();

export const getNormalizedProductsLevel2 = createCachedSelector(
  state => getProducts(state, true, false),
  products => {
    const normalizeLevelTwoProduct = (childsResult, childProduct) => ({
      ...childsResult,
      [childProduct.id]: childProduct,
    });

    const levelTwoProductsMap = products.reduce((result, product) => {
      const childProductsList = defaultToEmptyArray(product.products);
      const childProducts = childProductsList.reduce(normalizeLevelTwoProduct, {});

      return { ...result, ...childProducts };
    }, {});

    return levelTwoProductsMap;
  },
)(() => `normalized-products-level-2`);

export const getRoadmapLightboxData = createSelector(
  getState,
  state => (state.roadmapUserRoles || {})[state.lightboxRoadmapId] || [],
);
