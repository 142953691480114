import theme from 'design-system/theme';

export default {
  background: '#f5f8fb',
  fontFamily: theme.typography.fontFamily,
  headerBgColor: theme.palette.newLayout.background.secondary,
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#fff',
  botFontColor: theme.typography.color,
  userBubbleColor: '#fff',
  userFontColor: theme.typography.color,
};
