import { defaultTo, prop, pipe, isEmpty, not, head } from 'ramda';

import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import { isGroupRow, returnsTrueIfKeyIsNotEscOrTab } from 'design-system/molecules/AgGridReact-New/helpers';
import AgGridAutocomplete from 'components/AgGridAutocomplete/new';
import { mapDeliverableStatusToAutocompleteOption, getDeliverableFieldKey } from 'utils/cycleDeliverables/cycleDeliverablesUtils';

import clickableHeaderWithBackgroundHOC from '../helpers/clickableHeaderWithBackgroundHOC';
import { TextWithOpenLinkCellRenderer } from '../cellRenderers';

const defaultToEmptyObject = defaultTo({});
const defaultToEmptyString = defaultTo('');

const findSelectedStatusOption = (statuses, value) => statuses.find(status => status.title === value);

const getDeliverableColumnDef = (cycle, deliverable, onClickCallback, onClickDeliverableStatusCallback, allowActions = false) => {
  const onClickDeliverable = onClickCallback
    ? () => {
        /*
         * Before execute the call will unfocus the grid header
         *
         * This will avoid console errors on changing the route if
         * the ag grid header is focused before execute the action
         *
         * On this specific case its openning a lightbox and if change
         * the route when lightbox is opened will trigger a console
         * error because the header still focused before change the route
         */
        const agHeaderFocusedElemts = document.getElementsByClassName('ag-header-active');

        if (agHeaderFocusedElemts) {
          head(agHeaderFocusedElemts).blur();
        }

        onClickCallback(deliverable.id);
      }
    : undefined;
  const field = getDeliverableFieldKey(deliverable);

  return {
    headerName: deliverable.title,
    field,
    headerClass: 'ag-header-cell-text-centered ag-header-cell-small-padding',
    headerComponent: clickableHeaderWithBackgroundHOC(deliverable.color, onClickDeliverable),
    cellEditor: AgGridAutocomplete,
    cellRenderer: TextWithOpenLinkCellRenderer,
    ...(not(allowActions) ? { editable: false } : {}),
    ...(allowActions ? { editable: allowActions } : {}),
    cellEditorParams: {
      autoFocus: true,
      options: Object.entries(defaultToEmptyObject(deliverable.status)).map(([id, status]) =>
        mapDeliverableStatusToAutocompleteOption(id, status),
      ),
      onClickFactory: (data, value, params) => {
        if (isGroupRow(params)) {
          return;
        }

        return () => {
          onClickDeliverableStatusCallback({
            projectId: data.id,
            cycleDeliverableId: deliverable.id,
          });
        };
      },
    },
    valueFormatter: ({ value }) => {
      const getStatusOption = prop(value);
      const deliverableStatusOption = getStatusOption(defaultToEmptyObject(deliverable.status));
      const isCurrentValueDeliverableId = typeof value === 'string' && deliverableStatusOption;

      /*
       * This is a workaround to not have options on autocomplete with titles and work only
       * with object that contains id and title
       *
       * if `deliverableStatusOption` exists it means the value to format is an id and we
       * should get the title for the option that are tied to that id. This happens because
       * the current Autocomplete component set to ag grid data the selected id of the item
       * instead of the selected object, and if we dont show the correspondent title when is
       * updating will show the id instead of label
       */
      if (isCurrentValueDeliverableId) {
        return deliverableStatusOption.title;
      }

      return value || '';
    },
    valueGetter: ({ colDef, data }) => {
      const { field } = colDef;

      /* When we have aggrid rows created by path (example: Without Bet, Without Initiative)
       * those rows do not have 'data', as they it should be.
       * Return empty string for those cases that deliverable status do not apply
       */
      const getValueFromField = pipe(defaultToEmptyObject, prop(field), defaultToEmptyString);

      const value = getValueFromField(data);

      /* This logic is needed due the fact the Autocomplete is working.
       * We are passing to the autocomplete the following structure: [{id: 1, label: 'aaa'}, {id: 2, label: 'bbb'}]
       * Once the autocomplete gets an update it performs a setValue on the grid but only with the 'id' instead the full object.
       * Both valueGetter and ValueFormatter need logic to properly handle the conversion of data.
       * Ideal solution would be to adapt Autocomplete
       * */
      const isCurrentValueObject = typeof value === 'object';

      if (isCurrentValueObject) {
        return value?.label;
      }

      return value;
    },
    cellStyle: params => {
      if (isEmpty(deliverable?.status) || isEmpty(params.value)) return {};

      const option = findSelectedStatusOption(Object.values(deliverable?.status), params.value);

      return { color: defaultToEmptyString(option?.color) };
    },
    cellEditorPopup: true,
    comparator: stringComparator,
    suppressKeyboardEvent: returnsTrueIfKeyIsNotEscOrTab,
    width: 120,
    enableValue: true,
    enableColResize: true,
    parent: {
      headerName: cycle.title,
      field: `cycle_${cycle.id}`,
    },
  };
};

export default getDeliverableColumnDef;
