import { useCallback } from 'react';

import { ROADMAP_PAGE } from 'constants/filters';

import useTimelines from 'hooks/useTimelines/New/useTimelines';

import { GROUP_EXCLUDE_DRAG, isMilestoneTopGroup } from '../helpers';

import useSwimlaneActions from './useSwimlaneActions';

/**
 * Custome hook responsible to generate the Timeline handlers based on the Swimlane data.
 */
const useSwimlaneHandlers = ({ timelineData, groupsData }, page = ROADMAP_PAGE) => {
  // Get Timeline configuration
  const {
    lsState: { slotWidth },
    groupTitles,
    zoomMode,
    updateState,
    resizeGroupHeader,
  } = useTimelines(page);

  // Get actions to deal with projects/milestones changes
  const { openNewProjectLightbox, openNewMilestoneLightbox, openProjectLightbox, updateProjectTimeline } =
    useSwimlaneActions(groupsData);

  // Drag handler
  const handleDrag = useCallback(
    (task, dateDiff, newGroupIds) => {
      if (groupTitles.includes(GROUP_EXCLUDE_DRAG)) {
        updateState({ isDialogVisible: true });
        return false;
      }

      updateProjectTimeline(task.id, dateDiff, dateDiff, newGroupIds);

      return true;
    },
    [timelineData, groupTitles, slotWidth, zoomMode],
  );

  // Item Resize handler
  const handleResize = useCallback(
    (task, fromDiff, toDiff) => updateProjectTimeline(task.id, fromDiff, toDiff),
    [timelineData, updateProjectTimeline, slotWidth, zoomMode],
  );

  // Row click handler
  const handleRowClick = useCallback(
    groups => {
      const isMilestoneGroup = isMilestoneTopGroup(groups);

      if (isMilestoneGroup) {
        openNewMilestoneLightbox();
      } else {
        const groupIds = groups.map(group => group.id);

        openNewProjectLightbox(groupIds);
      }
    },
    [openNewProjectLightbox, openNewMilestoneLightbox],
  );

  return {
    handleDrag,
    handleResize,
    handleRowClick,
    handleItemDoubleClick: openProjectLightbox,
    handleGroupHeaderResize: resizeGroupHeader,
  };
};

export default useSwimlaneHandlers;
