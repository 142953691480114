import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { uniq, difference, isEmpty, pipe, values, map, prop, flatten, reduce, mergeWith, concat } from 'ramda';
import { selectCurrentProjectsIds, selectProjectsOfSelectedSnapsToCompare } from 'features/RoadmapHistory/store/selectors';
import makeFieldKeyForSnapProjectField from '../helpers/makeFieldKeyForSnapProjectField';
import { getGridConfigValue } from 'store/grids/selectors';
import { columnToMetadataMap } from 'features/RoadmapHistory/store/helpers/projects';

const getProjectIdsFromSnapProjects = snaps =>
  uniq(Object.values(snaps).reduce((acc, snap) => [...acc, ...Object.keys(snap?.projects).map(Number)], []));

const getProjectSnapFields = (projectFromSnap, snapshotId) =>
  projectFromSnap
    ? Object.entries(projectFromSnap).reduce(
        (acc, [fieldName, value]) => ({
          ...acc,
          [makeFieldKeyForSnapProjectField(snapshotId, fieldName)]: value,
        }),
        {},
      )
    : {};

const mergedChangedFields = pipe(values, map(prop('changedFieldsByProjectId')), reduce(mergeWith(concat), {}));

const uniqueFieldsByProjectId = pipe(mergedChangedFields, map(uniq));

const emptyArray = [];
const emptyObject = {};

const filterProjectsBasedOnDifferences = (
  projects,
  changedFieldsByProjectBySnapDiff = emptyObject,
  columnsToCompareIds = emptyArray,
  onlyWithDifferences = true,
  highlightField,
) => {
  if (!onlyWithDifferences) return projects;

  const columnsToConsider = highlightField?.key ? [highlightField?.key] : columnsToCompareIds;
  const allSnapProjectFieldChanges = uniqueFieldsByProjectId(changedFieldsByProjectBySnapDiff);

  const fieldsFromColumnsToCompare = flatten(columnsToConsider.map(fieldId => columnToMetadataMap[fieldId] || fieldId));

  return projects.filter(p => {
    const projectChangedFields = allSnapProjectFieldChanges[p?.id];

    return p.notInCurrent || projectChangedFields?.some(fieldChanged => fieldsFromColumnsToCompare.includes(fieldChanged));
  });
};

const useProjectsWithSnapDataForGrid = ({
  data,
  viewType,
  columnsToCompareIds = emptyArray,
  showItemsWithDifferences = false,
  changedFieldsByProjectBySnapDiff = emptyObject,
  highlightField,
}) => {
  const searchText = useSelector(state => getGridConfigValue(state, viewType, 'searchText'));
  const selectedProjectsOfSnapsToCompare = useSelector(selectProjectsOfSelectedSnapsToCompare);
  const currentProjectsIds = useSelector(selectCurrentProjectsIds);

  const projectsWithSnaps = useMemo(() => {
    const localSearchFilter = ({ title }) => (searchText ? title.toLowerCase().includes(searchText.toLowerCase()) : true);
    const projectIdsOnSnaps = getProjectIdsFromSnapProjects(selectedProjectsOfSnapsToCompare);
    const missingProjectsIds = difference(projectIdsOnSnaps, currentProjectsIds);

    // handle projects from current data
    const projectsWithSnapsFields = data.filter(localSearchFilter).map(project => {
      const projectSnapsFields = Object.values(selectedProjectsOfSnapsToCompare).reduce((acc, snapshot) => {
        const projectFromSnap = snapshot?.projects?.[project?.id];

        return {
          ...acc,
          ...getProjectSnapFields(projectFromSnap, snapshot?.id),
        };
      }, {});

      return {
        ...project,
        ...projectSnapsFields,
      };
    });

    // handle projects that do not match current filter but were a match when a snap was created
    const projectsFromSnaps = missingProjectsIds.reduce((accProjects, projectId) => {
      const projectSnapsFields = Object.values(selectedProjectsOfSnapsToCompare).reduce((acc, snapshot) => {
        const projectFromSnap = snapshot?.projects?.[projectId];

        if (!projectFromSnap || !localSearchFilter(projectFromSnap)) return acc;

        return {
          ...acc,
          id: projectId,
          title: projectFromSnap?.title,
          layer: projectFromSnap?.layer,
          ...getProjectSnapFields(projectFromSnap, snapshot?.id),
          notInCurrent: true, // temp. probably not needed since the BE will assume this as a changes project also
        };
      }, {});

      if (isEmpty(projectSnapsFields)) return accProjects;
      return [...accProjects, projectSnapsFields];
    }, []);

    return [...projectsWithSnapsFields, ...projectsFromSnaps];
  }, [data, selectedProjectsOfSnapsToCompare, searchText, currentProjectsIds]);

  const filtered = useMemo(() => {
    return filterProjectsBasedOnDifferences(
      projectsWithSnaps,
      changedFieldsByProjectBySnapDiff,
      columnsToCompareIds,
      showItemsWithDifferences,
      highlightField,
    );
  }, [projectsWithSnaps, changedFieldsByProjectBySnapDiff, columnsToCompareIds, showItemsWithDifferences, highlightField]);

  return filtered;
};

export default useProjectsWithSnapDataForGrid;
