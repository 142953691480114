const renderFormattedNumberText = v => {
  const [number, decimals] = String(v).split('.');

  // Will remove all trailing zeros
  // 1,000 -> 1,000
  // 1,000.00 -> 1,000
  // 1.00 -> 1
  // 1.23 -> 1.23
  if (decimals && parseFloat(decimals) === 0) return number;

  return v;
};

export default renderFormattedNumberText;
