import { useDispatch } from 'react-redux';

import { addMetricValueWithoutSave, removeUnsavedMetricValues } from 'features/MetricsDialog/store/actions';
import {
  updateMetricById,
  addMetricValue,
  updateMetricValue,
  deleteMetricValue,
  createMetric as createMetricThunk,
} from 'features/MetricsDialog/store/thunks';

const useMetricDialogActions = metricId => {
  const dispatch = useDispatch();

  const updateMetric = updateData => dispatch(updateMetricById(metricId, updateData));
  const createMetric = updateData => dispatch(createMetricThunk(updateData));
  const handleAddMetricValue = metricValue => dispatch(addMetricValue(metricId, metricValue));
  const handleUpdateMetricValue = (id, metricValue) => dispatch(updateMetricValue(metricId, id, metricValue));
  const handleDeleteMetricValue = metricValueId => dispatch(deleteMetricValue(metricId, metricValueId));
  const handleAddUnsavedMetricValue = (metricValue, metricId) => dispatch(addMetricValueWithoutSave(metricValue, metricId));
  const handleRemoveMetricValue = () => dispatch(removeUnsavedMetricValues());

  return {
    updateMetric,
    createMetric,
    handleAddMetricValue,
    handleUpdateMetricValue,
    handleDeleteMetricValue,
    handleAddUnsavedMetricValue,
    handleRemoveMetricValue,
  };
};

export default useMetricDialogActions;
