import isMobileScreen from 'utils/isMobileScreen';

import container from './container';
import DesktopComponent from './DashboardsComponent';
import MobileComponent from './MobileDashboardsComponent';
import HeaderOptions from './HeaderOptions';
import HeaderFilters from './HeaderFilters';

const Component = isMobileScreen() ? MobileComponent : DesktopComponent;

export default {
  HeaderOptions,
  HeaderFilters,
  Route: container(Component),
};
