import { useState, useEffect, useMemo, useCallback } from 'react';
import { defaultTo, not, omit } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';

import { deleteProjectDeliverable, upsertProjectDeliverable } from 'store/cycleDeliverables';
import {
  isfetchProjectDeliverableDetailsLoading,
  isSomeUpdateOnProjectsDeliverablesOcurring,
} from 'store/cycleDeliverables/selectors';
import { getUsers } from 'store/users/selectors';
import usePermissions from 'hooks/permissions/usePermissions';
import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

import useProjectDeliverableDetails from './useProjectDeliverableDetails';

const defaultToEmptyString = defaultTo('');
const OWNER_ID_KEY = 'ownerId';
const START_DATE_KEY = 'startDate';
const END_DATE_KEY = 'endDate';
const KEYS_TO_IGNORE_ON_CLEAN_DATA_TO_SAVE = [OWNER_ID_KEY];

const DEFAULT_FORM_DATA = {
  link: '',
  summary: '',
  startDate: null,
  endDate: null,
  ownerId: null,
  health: null,
  status: null,
};

const cleanDataToSave = data => {
  return {
    ...omit(KEYS_TO_IGNORE_ON_CLEAN_DATA_TO_SAVE, data),
    ...(Object.keys(data).includes(START_DATE_KEY) ? { startDate: not(data.startDate) ? null : data.startDate } : {}),
    ...(Object.keys(data).includes(END_DATE_KEY) ? { endDate: not(data.endDate) ? null : data.endDate } : {}),
    owner_id: data.ownerId,
  };
};

/**
 * @function useProjectDeliverableDetailsForm
 *
 * hook to handle with project deliverable details form
 * include actions and data
 *
 * @param  {Number} projectId
 * @param  {Number} cycleDeliverableId
 * @return {Object}
 */
const useProjectDeliverableDetailsForm = (projectId, cycleDeliverableId) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(DEFAULT_FORM_DATA);

  const isUpdatingOrDeleting = useSelector(isSomeUpdateOnProjectsDeliverablesOcurring);
  const isFetching = useSelector(isfetchProjectDeliverableDetailsLoading);
  const users = useSelector(getUsers);

  const isLoading = isFetching;

  const { projectDeliverable, cycleDeliverable, project } = useProjectDeliverableDetails(projectId, cycleDeliverableId);

  const { canUpdate, canDelete, canCreate } = usePermissions();

  const allowEdit = projectDeliverable
    ? canUpdate(PERMISSION_RESOURCES.projectDeliverable, { data: projectDeliverable })
    : canCreate(PERMISSION_RESOURCES.projectDeliverable, {});
  const allowDelete = canDelete(PERMISSION_RESOURCES.projectDeliverable, { data: projectDeliverable });

  const projectDeliverableParsedData = useMemo(
    () => ({
      link: defaultToEmptyString(projectDeliverable?.link),
      summary: defaultToEmptyString(projectDeliverable?.summary),
      startDate: projectDeliverable?.startDate ? moment(projectDeliverable?.startDate).format() : null,
      endDate: projectDeliverable?.endDate ? moment(projectDeliverable?.endDate).format() : null,
      ownerId: projectDeliverable?.owner_id,
      health: projectDeliverable?.health,
      status: projectDeliverable?.status,
    }),
    [projectDeliverable],
  );

  const owner = useMemo(() => {
    if (!projectDeliverableParsedData?.ownerId) {
      return null;
    }

    return users.find(u => u.id === projectDeliverableParsedData?.ownerId);
  }, [projectDeliverableParsedData?.ownerId, users]);

  const onChangeForm = useCallback(entry => {
    setFormData(cur => ({
      ...cur,
      ...entry,
    }));
  }, []);

  const onSave = useCallback(
    newData => {
      const dataToSave = newData || formData;

      // if data is the same will not trigger the update action
      if (isEqual(dataToSave, projectDeliverableParsedData)) {
        return;
      }

      if (isLoading || isUpdatingOrDeleting) {
        return;
      }

      if (not(allowEdit)) {
        return;
      }

      const dataToSend = cleanDataToSave(dataToSave);

      return dispatch(upsertProjectDeliverable(projectId, cycleDeliverableId, dataToSend));
    },
    [
      allowEdit,
      dispatch,
      upsertProjectDeliverable,
      projectId,
      cycleDeliverableId,
      projectDeliverableParsedData,
      formData,
      isLoading,
      isUpdatingOrDeleting,
    ],
  );

  const cleanForm = useCallback(() => setFormData(DEFAULT_FORM_DATA), []);

  const onDelete = useCallback(() => {
    if (!allowDelete) {
      return;
    }

    return dispatch(deleteProjectDeliverable(projectId, cycleDeliverableId));
  }, [allowDelete, dispatch, deleteProjectDeliverable, projectId, cycleDeliverableId]);

  useEffect(() => {
    setFormData(projectDeliverableParsedData);
  }, [projectDeliverableParsedData]);

  return {
    projectDeliverable,
    allowEdit,
    allowDelete,
    formData,
    onChangeForm,
    onSave,
    isLoading,
    cleanForm,
    onDelete,
    users,
    owner,
    cycleDeliverable,
    project,
  };
};

export default useProjectDeliverableDetailsForm;
