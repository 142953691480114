import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getSelectedRoadmapVersion, getStackedByChartOptions } from 'store/roadmapVersions/selectors';
import { DEFAULT_DATE_INTERVAL, getDefaultStackedByOption } from 'store/roadmapVersions/constants/defaultChartOptions';

export const BENEFIT_CHARTS = 'Benefit Charts';
export const SWIMLANE = 'Swimlane';
export const GANTT = 'Gantt';

const PREVIEW_PAGES = [BENEFIT_CHARTS, SWIMLANE, GANTT];

const useOneClickPreview = () => {
  const [currentPage, setCurrentPage] = useState(BENEFIT_CHARTS);
  const [dateInterval, setDateInterval] = useState(DEFAULT_DATE_INTERVAL);
  const [stackedByOption, setStackedByOption] = useState();

  const selectedRoadmapVersion = useSelector(getSelectedRoadmapVersion);
  const stackedByOptions = useSelector(getStackedByChartOptions);
  const defaultStackedByOption = getDefaultStackedByOption(stackedByOptions);

  const onChangePage = page => setCurrentPage(page || BENEFIT_CHARTS);

  const onDateIntervalChange = useCallback(
    value => {
      const { start_date: startDate, end_date: endDate } = value;

      let newDateInterval = { ...dateInterval };

      if (startDate) newDateInterval = { ...newDateInterval, startDate };
      if (endDate) newDateInterval = { ...newDateInterval, endDate };

      setDateInterval(newDateInterval);
    },
    [dateInterval, setDateInterval],
  );

  const onStackedByOptionChange = option => setStackedByOption(option || defaultStackedByOption);

  return {
    pageTabs: PREVIEW_PAGES,
    currentPage,
    onChangePage,
    oneClickRoadmapVersion: selectedRoadmapVersion,
    dateInterval,
    onDateIntervalChange,
    stackedByOption: stackedByOption || defaultStackedByOption,
    onStackedByOptionChange,
    stackedByOptions,
  };
};

export default useOneClickPreview;
