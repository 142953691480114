import moment from 'moment-timezone';
import getSystemFieldName from 'utils/getSystemFieldName';

import formatDate from 'utils/dates/formatDate';

export const searchByOptions = systemFields => [
  { key: 'timeframe', title: getSystemFieldName('timeframe', systemFields) },
  { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
  { key: 'objective', title: getSystemFieldName('objective', systemFields) },
  { key: 'phase', title: getSystemFieldName('phase', systemFields) },
  { key: 'theme', title: getSystemFieldName('theme', systemFields) },
  { key: 'category', title: getSystemFieldName('category', systemFields) },
  { key: 'owner', title: 'Owner' },
];

export const groupByOptions = (systemFields, hasKeyResults, hasProducts) => [
  { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
  ...(hasProducts ? [{ key: 'product_1', title: getSystemFieldName('product1', systemFields) }] : []),
  { key: 'timeframe', title: getSystemFieldName('timeframe', systemFields) },
  { key: 'objective', title: getSystemFieldName('objective', systemFields) },
  ...(hasKeyResults ? [{ key: 'key_result_1', title: getSystemFieldName('keyResult1', systemFields) }] : []),
  { key: 'theme', title: getSystemFieldName('theme', systemFields) },
  { key: 'category', title: getSystemFieldName('category', systemFields) },
  { key: 'phase', title: getSystemFieldName('phase', systemFields) },
  { key: 'owner', title: 'Owner' },
];

export const DEFAULT_STAGES = ['Planning', 'Confirmed', 'Completed'].reduce((acc, cur) => {
  acc[cur] = true;
  return acc;
}, {});

export const initStartDate = () => formatDate(moment().subtract(30, 'day'));
export const initEndDate = () => formatDate(moment().endOf('month'));

export const groupByLvl1Options = (systemFields, hasHierarchy, hasBet) => [
  { key: 'timeframe', title: getSystemFieldName('timeframe', systemFields) },
  ...(hasHierarchy ? [{ key: 'initiative', title: getSystemFieldName('initiative', systemFields) }] : []),
  ...(hasBet ? [{ key: 'bet', title: getSystemFieldName('bet', systemFields) }] : []),
];

export const groupByLvl2Options = (systemFields, hasProducts) => [
  { key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) },
  ...(hasProducts ? [{ key: 'product1', dataKey: 'product_1', title: getSystemFieldName('product1', systemFields) }] : []),
];

export const TABLE_SELECTED_LEVELS = {
  first: 1,
  second: 2,
  third: 3,
};
