import React, { useMemo } from 'react';
import styled from 'styled-components';
import pluralize from 'pluralize';
import { propOr } from 'ramda';

import Card from 'design-system/molecules/Card/index';

import WidgetGrid from 'design-system/organisms/WidgetGrid';
import usePermissions from 'hooks/permissions/usePermissions';
import { getOwnerColumnDef } from 'design-system/molecules/AgGridReact-New/columns';
import CenteredLoading from 'features/PortfolioOverview/components/CenteredLoading';

import useOrganizations from 'hooks/useOrganizations';
import { getUserName } from 'utils';
import history from 'store/utils/history';
import { PERMISSION_PAGES } from 'hooks/permissions/usePermissions/constants';
import useSystemFields from 'hooks/useSystemFields';
import { ROADMAP, PRODUCT_2, METADATA_LEVELS } from 'constants/common';
import { PRODUCT_1_KEY, PRODUCT_1_LEVEL, ROADMAP_LEVEL } from 'constants/roadmaps';

// TODO: move this to a generic util / module
import { getAllRoadmaps } from 'routes/Settings/Roadmaps/New/helpers';
import { TitleLinkCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import buildToolbarActions from '../buildToolbarActions';
import useSelectedRoadmapOnPortfolioOverview from '../../hooks/useSelectedRoadmapOnPortfolioOverview';
import { getWidgetHeight } from '../helpers';
import { GRID_MAX_ROWS } from '../../constants/widgetsConfig';
import usePortfolioOverviewSubRoadmaps from './usePortfolioOverviewSubRoadmaps';
import useUpdateGridWidgetHeight from '../useUpdateGridWidgetHeight';

const SETTINGS_ROADMAPS_URL = '/settings/roadmaps';

const ROADMAP_ENTITY_PARENT_TYPE_LABEL_MAPPER = {
  [METADATA_LEVELS.LEVEL_CORP]: ROADMAP,
  [METADATA_LEVELS.LEVEL_1]: PRODUCT_1_KEY,
  [METADATA_LEVELS.LEVEL_2]: PRODUCT_2,
  [METADATA_LEVELS.LEVEL_3]: PRODUCT_2,
};

const getRowId = ({ data }) => data.uniqueId;

const SubRoadmapsList = ({ id, isVisible, onVisibilityChange, userCanEdit, disableEdit, updateHeightOnLayout }) => {
  const [getSystemFieldName] = useSystemFields();
  const { hasProducts, hasProducts2 } = useOrganizations();
  const { canView } = usePermissions();
  const { handleSelectRoadmap, activeRoadmapEntity, handleSelectProduct } = useSelectedRoadmapOnPortfolioOverview();
  const { data, isLoading } = usePortfolioOverviewSubRoadmaps();
  const hasPagination = data?.length > GRID_MAX_ROWS;

  const { updateWidgetHeight, gridHeight } = useUpdateGridWidgetHeight({
    widgetId: id,
    getWidgetHeight,
    updateHeightOnLayout,
    hasPagination,
    maxItems: GRID_MAX_ROWS,
  });

  const roadmapsWithHierarchy = useMemo(() => {
    return getAllRoadmaps(data, hasProducts, hasProducts2, true, canView);
  }, [data, hasProducts, hasProducts2, canView]);

  const goToRoadmapsSettings = () => history.push(SETTINGS_ROADMAPS_URL);

  const isTitleClickable = roadmap => [ROADMAP_LEVEL, PRODUCT_1_LEVEL].includes(roadmap?.level);

  const handleTitleClick = roadmap => {
    switch (roadmap?.level) {
      case ROADMAP_LEVEL:
        handleSelectRoadmap(roadmap);
        break;
      case PRODUCT_1_LEVEL:
        handleSelectProduct(roadmap);
        break;
      default:
        break;
    }
  };

  const groupColumnDef = useMemo(
    () => ({
      headerName: 'Title',
      field: 'title',
      suppressMovable: true,
      cellClass: 'ag-title-cell',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: params => ({
        innerRenderer: TitleLinkCellRenderer,
        suppressCount: true,
        suppressDoubleClickExpand: true,
        hasProducts2,
        titleIsClickable: isTitleClickable(params.data),
        onTitleClick: handleTitleClick,
        openButtonProps: {
          hasOpenButton: false,
        },
      }),
      flex: 0.8,
    }),
    [hasProducts2],
  );

  const ownerColumnDef = useMemo(
    () => ({
      ...getOwnerColumnDef({ editable: false, users: [] }),
      headerName: 'Owner',
      flex: 0.2,
      enableRowGroup: false,
      valueGetter: params => getUserName(params.data?.owner),
    }),
    [],
  );

  const buildActions = () => {
    const showVisibilityAction = userCanEdit && !disableEdit;
    const visibilityAction = showVisibilityAction ? { value: isVisible, onChange: onVisibilityChange } : null;

    const settingsAction = canView(PERMISSION_PAGES.settingsRoadmapsPage) ? { onClick: goToRoadmapsSettings } : null;

    return buildToolbarActions({
      visibility: visibilityAction,
      settings: settingsAction,
    });
  };

  const labelKey = propOr(ROADMAP, activeRoadmapEntity?.type)(ROADMAP_ENTITY_PARENT_TYPE_LABEL_MAPPER);
  const title = getSystemFieldName(labelKey);

  return (
    <StyledCard title={pluralize(title)} actions={buildActions()}>
      {isLoading ? (
        <CenteredLoading />
      ) : (
        <WidgetGrid
          hasPagination={data?.length > GRID_MAX_ROWS}
          gridHeight={gridHeight}
          isTreeView
          getRowId={getRowId}
          data={roadmapsWithHierarchy}
          columnDefs={[groupColumnDef, ownerColumnDef]}
          pageSize={GRID_MAX_ROWS}
          numberOfRecords={data?.length || 0}
          setVisibleItems={nItems => updateWidgetHeight(nItems)}
        />
      )}
    </StyledCard>
  );
};

export default SubRoadmapsList;

const StyledCard = styled(Card)`
  height: 100%;
  overflow: hidden;
`;
