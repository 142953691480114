import { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ascend, pipe, pluck, propOr, sort } from 'ramda';

import { getBaseCompareVersionSelected } from 'store/roadmapVersions/selectors';
import { setBaseCompareVersionSelected } from 'store/roadmapVersions';
import history from 'store/utils/history';

import getPoROptionsByPage from 'routes/RoadmapVersions/utils/getPoROptionsByPage';

import useRoadmapVersions from 'hooks/useRoadmapVersions';

import getCurrentPath from 'utils/getCurrentPath';
import { getPageIdFromPath } from 'utils/userViews';
import { selectedScenarioVersion } from 'constants/queryParams';
import { COMPARE_SCENARIOS_PLAN_OF_RECORD_LIST } from 'constants/common';

const exist = Boolean;

const getName = propOr('', 'name');
const byName = ascend(getName);
const sortByName = sort(byName);
const getSortedNames = pipe(sortByName, pluck('name'));

const updateQueryParams = selectedVersion => {
  const path = window.location.pathname;
  const params = new URLSearchParams(window.location.search);

  if (selectedVersion) {
    params.set(selectedScenarioVersion, selectedVersion?.id);
  } else {
    params.delete(selectedScenarioVersion);
  }

  history.push(`${path}?${params.toString()}`);
};

const useSelectCompareScenariosBaseVersion = () => {
  const dispatch = useDispatch();
  const { roadmapVersions } = useRoadmapVersions();
  const baseCompareVersionSelected = useSelector(getBaseCompareVersionSelected);

  const path = getCurrentPath();
  const pageId = getPageIdFromPath(path);

  const selectedBaseVersion = getName(baseCompareVersionSelected);

  const availableScenarioOptions = useMemo(() => {
    const planOfRecordOptions = getPoROptionsByPage(pageId).map(option => option.name);
    const roadmapVersionsNames = getSortedNames(roadmapVersions);

    return [...planOfRecordOptions, ...roadmapVersionsNames];
  }, [roadmapVersions]);

  const setBaseVersion = useCallback((version, needsGlobalFilter = true) => {
    dispatch(setBaseCompareVersionSelected(version, needsGlobalFilter));
  }, []);

  const getPorVersionFromName = name => COMPARE_SCENARIOS_PLAN_OF_RECORD_LIST.find(por => por.name === name);

  const getScenarioVersionFromName = name => roadmapVersions.find(version => version?.name === name);

  const selectRoadmapVersionOrPlanOrRecord = selectedName => {
    const planOfRecordObject = getPorVersionFromName(selectedName);
    const isPlanOfRecord = exist(planOfRecordObject);

    return isPlanOfRecord ? planOfRecordObject : getScenarioVersionFromName(selectedName);
  };

  const handleBaseVersionChange = selectedName => {
    const selectedVersion = selectRoadmapVersionOrPlanOrRecord(selectedName);

    // only when loading the page without version we should get the filters from global
    // if we change base versions, apply new filters and then change the base to PoR we should not reset
    const needsGlobalFilter = !baseCompareVersionSelected && !selectedVersion;

    setBaseVersion(selectedVersion, needsGlobalFilter);
    updateQueryParams(selectedVersion);
  };

  return { availableScenarioOptions, selectedBaseVersion, handleBaseVersionChange, setBaseVersion };
};

export default useSelectCompareScenariosBaseVersion;
