import React from 'react';
import styled from 'styled-components';
import TruncateMarkup from 'react-truncate-markup';

import useSystemFields from 'hooks/useSystemFields';

export default props => {
  const [getSystemFieldName] = useSystemFields();

  return (
    <TruncateMarkup lines="2">
      <div>
        {'Unable to update Jira field '}
        <Bold>{`"${getSystemFieldName(props.field.replace('Title', ''))}"`}</Bold>
        {` for ${getSystemFieldName('idea')} `}
        <Bold>{`DB-${props.project.id}`}</Bold>. {props.message}
      </div>
    </TruncateMarkup>
  );
};

const Bold = styled.span`
  &&&& {
    font-weight: 700;
  }
`;
