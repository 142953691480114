import ReactDOMServer from 'react-dom/server';

import { ucFirst, calculateGroupProgress, getProgressNumber } from 'utils';
import { GANTT_OFFSETS } from 'components/ganttCommon';
import formatNumber from 'utils/formatNumber';
import generateOkrIcon from 'utils/okrs/generateOkrIcon';
import { convertHoursToString } from 'utils/converTimeToString';

const OBJECTIVE_CORP = 'objective_corp_id';
const OBJECTIVE = 'objective_id';
const KEY_RESULT_1 = 'key_result_1_id';
const KEY_RESULT_2 = 'key_result_2_id';
const OKR_KEYS = [OBJECTIVE_CORP, OBJECTIVE, KEY_RESULT_1, KEY_RESULT_2];

const mapOkrType = {
  [OBJECTIVE_CORP]: 'objectiveCorp',
  [OBJECTIVE]: 'objective',
  [KEY_RESULT_1]: 'keyResult',
  [KEY_RESULT_2]: 'keyResult2',
};

const isOkr = val => !!val && OKR_KEYS.includes(val);

/**
 * Function that generate the group rows based on groupBy setting
 *
 * @param {*} projects parsed projects in gantt format
 * @param {*} groupsData data for groups (objectives, roadmaps, timeframes, ...)
 * @param {*} projectGroups  project groups by setting
 * @param {*} opts object with other options
 * @returns parsed projects array with groups based on group setting
 */
const generateGroups = (projects, groupsData, projectGroups, opts = {}) => {
  const { getSystemFieldName, customFields, coloredGroups } = opts;

  const numericCustomFields = customFields.filter(f => f.field_type === 'Number').map(f => f.key);

  const getUnassignedGroupTitle = groupKey => {
    if (groupKey === 'status_color') {
      return `Health Undefined`;
    }

    const systemName = getSystemFieldName(groupKey) || groupKey;

    return `${ucFirst(systemName)} Undefined`;
  };

  const parseGroup = (group, parent) => {
    const groupKey = group?.groupOption?.key;
    const entity = group?.groupData?.[groupKey];
    const entityId = entity?.id ? entity.id : 'null';

    const key = groupKey?.startsWith('custom_fields.') ? 'customField' : groupKey;

    const id = `${GANTT_OFFSETS[key] + entityId}${parent !== 0 ? parent : ''}`;

    const groupProjs = projects.filter(p => typeof p.parent === 'string' && p.parent.includes(id));

    const progress = calculateGroupProgress(groupProjs);

    const calcAttributeAgg = (atttr, formatFn) => {
      const result = groupProjs.reduce((acc, cur) => {
        let attrValue = 0;

        switch (typeof cur[atttr]) {
          case 'object':
            attrValue = 0;
            break;
          case 'string':
            attrValue = parseInt(cur[atttr]);
            break;
          default:
            attrValue = cur[atttr];
        }

        acc += attrValue || 0;

        return acc;
      }, 0);

      return formatFn ? formatFn(result) : formatNumber(result);
    };

    const isUndefinedCustomField = group?.title === 'Undefined' && group?.groupOption?.key?.startsWith('custom_fields.');

    const groupTitle = group?.title === 'Undefined' ? getUnassignedGroupTitle(groupKey) : group?.title;

    return {
      id,
      entityId,
      type: 'project',
      group: true,
      dbType: group?.groupOption?.field || groupKey,
      parent,
      open: false,
      text: isUndefinedCustomField ? `${group?.groupOption?.title} Undefined` : groupTitle,
      title: isUndefinedCustomField ? `${group?.groupOption?.title} Undefined` : groupTitle,
      textColor: 'rgba(0, 0, 0, 0.8)',
      progress,
      color: coloredGroups ? entity?.color : 'rgb(199, 199, 199)',
      progressOriginal: progress,
      progressPercent: getProgressNumber(progress),
      business_value: calcAttributeAgg('business_value'),
      totalCost: calcAttributeAgg('totalCost'),
      storyPoints: calcAttributeAgg('storyPoints'),
      timeEstimated: calcAttributeAgg('timeEstimated', convertHoursToString),
      issuesTotal: calcAttributeAgg('issuesTotal'),
      effort_score: calcAttributeAgg('effort_score'),
      ...numericCustomFields.reduce((acc, key) => ({ ...acc, [key]: calcAttributeAgg(key) }), {}),
      rank: entity?.rank,
      row_order: entity?.row_order,
      elements: group?.elements,
    };
  };

  // Generate groups level that call itself for multilevel groups
  let result =
    projectGroups
      ?.map(group => parseGroup(group, 0))
      .reduce((acc, group) => {
        if (group?.elements?.some(element => element?.group)) {
          const childGroups = group?.elements?.map(childGroup => parseGroup(childGroup, group.id));

          return [...acc, group, ...childGroups];
        }

        return [...acc, group];
      }, []) ?? [];

  // method to genererate OKR icon by level and to add to the text
  const getOkrIcon = groupData => {
    const result = groupData.map(data => {
      if (isOkr(data.dbType)) {
        return {
          ...data,
          text: `${ReactDOMServer.renderToString(generateOkrIcon(mapOkrType[data.dbType], true, { height: '16px' }))} ${
            data.text
          }`,
        };
      }

      return data;
    });

    return result;
  };

  // add okr icon
  result = getOkrIcon(result);

  return result;
};

export default generateGroups;
