import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';
import MuiListItemText from '@material-ui/core/ListItemText';

import { spacing } from 'design-system/theme';

const MultiFilterClearRow = ({ handleClear, icon, item, text = 'Clear All' }) => {
  return (
    <FooterWrapper>
      <ClearFilterRow onClick={() => handleClear(item)}>
        <ListItemIcon>{icon || <CloseIcon />}</ListItemIcon>
        <ListItemText uppercase="true" small="true">
          {text}
        </ListItemText>
      </ClearFilterRow>
    </FooterWrapper>
  );
};

export default MultiFilterClearRow;

const ListItemIcon = styled(MuiListItemIcon)`
  &&&& {
    margin: auto;

    display: flex;
    justify-content: space-between;

    svg {
      width: 12px !important;
      height: 12px !important;
    }
  }
`;

const ListItemText = styled(MuiListItemText)`
  &&&& {
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;

    ${({ uppercase }) =>
      uppercase &&
      `
      text-transform: uppercase;
    `}

    span {
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      font-size: ${props => props.theme.typography.fontSize}px;
      line-height: ${props => props.theme.typography.lineHeightRegular}px;
      color: ${props => props.theme.palette.text.lightGrey};
    }
  }
`;

const ClearFilterRow = styled.div`
  &&&& {
    cursor: pointer;

    width: 100%;
    /* height: 100%; */

    display: grid;
    grid-template-columns: 14px 1fr;
    gap: ${spacing(1)}px;

    padding: 5px 15px;
    box-sizing: border-box;
    height: 30px;
    margin: 0.5px 0;

    svg {
      width: 16px !important;
      height: 16px !important;
    }

    span {
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      font-size: ${props => props.theme.typography.fontSize}px;
      line-height: ${props => props.theme.typography.lineHeightRegular}px;
      color: ${props => props.theme.palette.text.lightGrey};
    }
  }
`;

const FooterWrapper = styled.div`
  &&&& {
    border-top: 1px solid #fafafa;
    box-shadow: 0px -2px 12px 0px rgb(204 204 204 / 30%);
  }
`;
