import React, { useCallback, useMemo, useRef } from 'react';

import useSystemFields from 'hooks/useSystemFields';
import {
  statusColumnDef,
  targetAllocationAmountColumnDef,
  targetAllocationPercentageColumnDef,
  colorColumnDef,
  getProgressColumnDef,
  healthColumnDef,
  getOwnerColumnDef,
  updatedByColumnDef,
} from 'design-system/molecules/AgGridReact-New/columns';
import { useSettingsGridColumns } from 'containers/Grids/SettingsGrid/hooks';

import { makeGroupHeaderInnerRenderer } from '../components/GroupHeaderInnerRenderer';
import OwnerCellWrapper from '../components/OwnerCellWrapper';
import { isNewRow } from 'design-system/molecules/AgGridReact-New/helpers';

import {
  PRODUCT_1_KEY,
  PRODUCT_2_KEY,
  ROADMAP_KEY,
  checkAddVisibility,
  isOfObjectProductType,
  isOfObjectRoadmapType,
  ROADMAP_CORP_KEY,
  hasNotParentArchived,
  checkCheckboxSelection,
  checkDefaultColEditable,
  checkDeleteDisabled,
  checkRowDrag,
} from '../helpers';
import { METADATA_LEVELS } from 'constants/common';
import { GROUP_COLUMN_INITIAL_WIDTH } from 'constants/grid';
import usePermissions from 'hooks/permissions/usePermissions';

const useRoadmapsGridColumns = ({
  allowActions,
  users,
  hasProducts,
  hasProducts2,
  hasCorpLevel,
  systemFields,
  selectionMode,
  setItemToDelete,
  entityPermissionsEnabled,
  actions: { addProductWithoutSave, handleOpenRoadmapLightbox, addRoadmapWithoutSave },
  items,
  handleAddWithoutSave,
}) => {
  const [getSystemFieldName] = useSystemFields();
  const { canUpdate, canCreate, canDelete } = usePermissions();

  const itemsRef = useRef(null);

  itemsRef.current = items;

  const titleHeaderName = useMemo(() => {
    const roadmapCorpTitle = hasCorpLevel ? getSystemFieldName(ROADMAP_CORP_KEY, systemFields) : '';
    const roadmapTitle = getSystemFieldName(ROADMAP_KEY, systemFields);
    const subRoadmapTitle = hasProducts ? getSystemFieldName(PRODUCT_1_KEY, systemFields) : '';
    const productTitle = hasProducts2 ? getSystemFieldName(PRODUCT_2_KEY, systemFields) : '';

    return [roadmapCorpTitle, roadmapTitle, subRoadmapTitle, productTitle].filter(Boolean).join(' > ');
  }, [hasProducts, hasProducts2, systemFields, hasCorpLevel]);

  const handleAdd = useCallback((data, { node }) => {
    if (!node.expanded) {
      node.setExpanded(true);
    }

    if (isOfObjectProductType(data)) {
      return addProductWithoutSave(data, true, 1);
    } else if (isOfObjectRoadmapType(data) && data?.level === METADATA_LEVELS.LEVEL_CORP) {
      return addRoadmapWithoutSave(data);
    }

    return addProductWithoutSave(data);
  }, []);

  const addVisibility = useMemo(
    () => checkAddVisibility(allowActions, hasProducts, hasProducts2, selectionMode, canCreate),
    [allowActions, hasProducts, hasProducts2, selectionMode, canCreate],
  );

  const groupColumnDef = useMemo(
    () => ({
      headerName: titleHeaderName,
      field: 'title',
      suppressMovable: true,
      editable: params => checkDefaultColEditable(params, canUpdate, allowActions),
      headerClass: 'first-field',
      cellClass: 'ag-title-cell',
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        suppressCount: true,
        suppressDoubleClickExpand: true,
        innerRenderer: makeGroupHeaderInnerRenderer(hasCorpLevel),
        hasProducts2,
        checkAddVisibility: addVisibility,
        handleAddWithoutSave: handleAdd,
      },
      width: GROUP_COLUMN_INITIAL_WIDTH,
    }),
    [titleHeaderName, canUpdate, hasCorpLevel],
  );

  const statusColumnEditable = params => {
    const { data } = params;

    return checkDefaultColEditable(params, canUpdate, !!data?.id && hasNotParentArchived(itemsRef.current, data));
  };

  const remainingRoadmapsColumnDefs = useMemo(
    () => [
      {
        ...statusColumnDef,
        enableRowGroup: false,
        editable: statusColumnEditable,
      },
      targetAllocationAmountColumnDef,
      targetAllocationPercentageColumnDef,
      colorColumnDef,
      getProgressColumnDef(params => checkDefaultColEditable(params, canUpdate, allowActions)),
      healthColumnDef,
      {
        ...getOwnerColumnDef({
          editable: params => checkDefaultColEditable(params, canUpdate, allowActions),
          editableForNewRow: false,
          users,
        }),
        enableRowGroup: false,
        cellWrapper: (data, children) => (
          <OwnerCellWrapper
            roadmap={data}
            entityPermissionsEnabled={entityPermissionsEnabled}
            openRoadmapLightbox={handleOpenRoadmapLightbox}
          >
            {children}
          </OwnerCellWrapper>
        ),
      },
      updatedByColumnDef,
    ],
    [users, allowActions, canUpdate],
  );

  const settingsGridColumns = useSettingsGridColumns({
    allowActions,
    selectionMode,
    setItemToDelete,
    canDragRows: true,
    rowDrag: params => checkRowDrag(params, canUpdate),
    checkboxSelection: checkCheckboxSelection,
    deleteDisabled: row => checkDeleteDisabled(row, canDelete),
    items,
  });

  // Join common and roadmaps column definitions

  return useMemo(
    () => ({
      columnDefs: [
        ...settingsGridColumns.beforeColumnDefs,
        groupColumnDef,
        ...remainingRoadmapsColumnDefs,
        ...settingsGridColumns.afterColumnDefs,
      ],
      defaultColDef: {
        ...settingsGridColumns.defaultColDef,
        editable: params => checkDefaultColEditable(params, canUpdate, allowActions && !isNewRow(params)),
      },
    }),
    [canUpdate, allowActions, settingsGridColumns, groupColumnDef, remainingRoadmapsColumnDefs, hasProducts],
  );
};

export default useRoadmapsGridColumns;
