import React, { Component } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import queryString from 'query-string';

import PlanningStageWarningDialog from 'components/PlanningStageWarningDialog';

import subscribeNewData from 'subscribeNewData';
import { projectActions, importProjects } from 'store/projects';
import { secureUpdateProjectEstimates } from 'store/estimates';
import {
  deleteKeyResultById,
  requestRemoveObjectiveById,
  updateObjectiveById,
  objectiveActions,
  mergeObjectives,
  mergeKeyResults,
  updateKeyResultById,
} from 'store/objectives';
import {
  mergeRoadmaps,
  mergeProducts,
  deleteRoadmapById,
  updateRoadmapById,
  roadmapActions,
  deleteProductById,
  updateProductById,
} from 'store/roadmaps';
import { mergePhases, deletePhaseById, updatePhaseById, phaseActions } from 'store/phases';
import { mergeThemes, deleteTheme, themeActions } from 'store/themes';
import { mergeCategories, deleteCategory, categoryActions } from 'store/categories';
import { mergeTimeframes, deleteTimeframeById, timeframeActions } from 'store/timeframes';
import { teamActions } from 'store/teams';
import { ideasBoardActions } from 'store/ideasBoard';
import { showImportIdeasLightbox } from 'store/ideas';
import { fetchTags } from 'store/tags';
import { fetchCustomers } from 'store/customers';
import { userActions } from 'store/users';
import { IDEAS_BOARD_PAGE } from 'constants/filters';
import { makeSelectFilteredProjectsByRoadmapForPage, getSearchOnPageFilters } from 'store/filters/selectors';
import { setDisplayLayer } from 'store/filters/actions';
import { getPriorities } from 'store/priorities/selectors';
import { getObjectives, selectKeyResults1, selectKeyResults2 } from 'store/objectives/selectors';
import { getTimeframes, getTimeframesLevel2, getTimeframesLevelCorp } from 'store/timeframes/selectors';
import { getRoadmaps, getProducts } from 'store/roadmaps/selectors';
import { getCategories, getCategoriesLevelCorp } from 'store/categories/selectors';
import { getPhases } from 'store/phases/selectors';
import { getThemes } from 'store/themes/selectors';
import { getCustomersFilteredByStatus } from 'store/customers/selectors';
import { getTags } from 'store/tags/selectors';
import { getOrgHasJiraIntegrated, getOrgJiraIntegrations } from 'store/organization/selectors';
import { getAllTeams } from 'store/teams/selectors';
import { getOrganization } from 'src/store/organization/selectors';
import IdeasBoard from './IdeasBoard';
import getStateDataForPage from 'store/utils/getStateDataForPage';
import useGetInitiativesAndBets from 'hooks/useGetInitiativesAndBets';
import useAnnotateProjectsWithChildrenCount from 'hooks/useAnnotateProjectsWithChildrenCount';
import { IDEA_LAYER, INITIATIVE_LAYER, BET_LAYER } from 'store/projects/constants';
import { openProjectLightbox } from 'store/projectLightbox';
import { voteOnProject as voteOnProjectAction, unvoteOnProject as unvoteOnProjectAction } from 'store/votes/actions';
import useLoadProjectsForTransactionPages from 'hooks/projects/useLoadProjectsForTransactionPages';
import useOrganizationsAccessControl from 'src/hooks/useOrganizationsAccessControl';
import { getProjectsCustomFields } from 'store/customFields/selectors';
import filterInitiativeOrBetsForGroup from 'utils/filterInitiativeOrBetsForGroup';
import { planningStageOptions } from 'utils';
import PageLoading from 'design-system/atoms/PageLoading/PageLoading';

import { filterMetadataOptions } from 'utils/metadataRoadmaps';
import getJiraIntegrationWarnings from 'utils/getJiraIntegrationWarnings';
import { JIRA_PROJECT_RENAMED_WARNING } from 'constants/integrations';
import { setAppGlobalConfig } from 'store/app';
import { getIsDodActive } from 'src/store/accessControl/selectors';
import useProjectsLocalSearch from 'hooks/projects/useProjectsLocalSearch';
import usePageFilters from 'hooks/filters/usePageFilters';
import usePermissions from 'hooks/permissions/usePermissions';
import useProjectsLocalFilterByOwner from 'hooks/projects/useProjectsLocalFilterByOwner';
import useProjectsFrontendFilters from 'hooks/filters/useProjectsFontendFilters';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';

class IdeasBoardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogVisible: false,
      isLoaded: false,
      autoFillData: {},
    };
  }

  setStateAutoFillData(value, callBack) {
    this.setState({ autoFillData: value });

    if (callBack) callBack();
  }

  async componentDidMount() {
    // batch(() => {
    //   this.props.fetchTimeframes();
    //   this.props.fetchObjectives();
    //   this.props.fetchRoadmaps();
    //   this.props.fetchPhases();
    //   this.props.fetchThemes();
    //   this.props.fetchCategories();
    //   this.props.fetchTeams();
    //   this.props.fetchTags();
    //   this.props.fetchCustomers();
    // });
    const { tags, lsState, displayLayer } = this.props;
    const { display } = queryString.parse(this.props.location.search);

    if (display) {
      let displayFilter;
      let newDataType = lsState.dataType;

      switch (display) {
        case 'initiatives':
          displayFilter = INITIATIVE_LAYER;
          if (lsState.dataType === 'initiative') newDataType = 'objective';
          break;
        case 'bets':
          displayFilter = BET_LAYER;
          if (lsState.dataType === 'bet') newDataType = 'objective';
          break;
        default:
          displayFilter = IDEA_LAYER;
          break;
      }

      this.props.updateState({ dataType: newDataType }, false);
      this.props.setDisplayLayer(displayFilter);
    } else if (lsState.dataType === 'initiative' && [INITIATIVE_LAYER, BET_LAYER].includes(displayLayer)) {
      this.props.setDisplayLayer(IDEA_LAYER);
    } else if (lsState.dataType === 'bet' && displayLayer === BET_LAYER) {
      this.props.setDisplayLayer(INITIATIVE_LAYER);
    }

    const empty = (selected = true) => ({
      id: null,
      selected,
    });
    const mapFn = defaultSelect => t => ({ id: t.id, selected: defaultSelect });
    const overrideFn = (items, overrides) =>
      items.map(item1 => {
        overrides = overrides || [];
        return Object.assign(
          item1,
          overrides.find(item2 => item2 && item1.id === item2.id),
        );
      });
    const selectedFilterDialogTags = overrideFn([{ ...empty() }].concat(tags.map(mapFn(true))), lsState.selectedFilterDialogTags);

    this.props.updateState({ selectedFilterDialogTags }, false);
    this.setState({
      isLoaded: true,
    });

    let fieldsConcat = {
      roadmaps: this.props.roadmaps,
      products: this.props.products,
      objectives: this.props.objectives,
      keyResults: this.props.keyResults,
      themes: this.props.themes,
      timeframes: this.props.timeframes,
      phases: this.props.phases,
      categories: this.props.categories,
      priorities: this.props.priorities,
      tags: this.props.tags,
      customers: this.props.customers,
      timeframes2: this.props.timeframes2,
    };

    const myAutoFillData = {};

    if (this.props.filterFieldName === 'planningStages') {
      fieldsConcat = { ...fieldsConcat, planningStages: this.props.filterValue };
    }

    if (fieldsConcat[this.props.filterFieldName] && this.props.filterValue.length) {
      let value;

      if (this.props.filterFieldName === 'planningStages') {
        [value] = this.props.filterValue;
      } else {
        value = fieldsConcat[this.props.filterFieldName].find(f => f.id === +this.props.filterValue[0]);
      }

      if (value) {
        switch (this.props.filterFieldName) {
          case 'roadmaps':
            myAutoFillData.roadmapTitle = value.title;
            break;
          case 'products':
            const roadmap = this.props.roadmaps.find(roadmap => {
              return roadmap.products.findIndex(p => p.title === value.title) > -1;
            });

            myAutoFillData.roadmapTitle = roadmap.title;
            myAutoFillData.product1Title = value.title;
            break;
          case 'objectives':
            myAutoFillData.objectiveTitle = value.title;
            break;
          case 'keyResults':
            myAutoFillData.objectiveTitle = this.props.objectives.find(o => o.id === value.objective_id).title || '';
            myAutoFillData.keyResult1Title = value.title;
            break;
          case 'themes':
            myAutoFillData.themeTitle = value.title;
            break;
          case 'timeframes':
            myAutoFillData.timeframeTitle = value.title;
            break;
          case 'planningStages':
            myAutoFillData.planningStage = value;
            break;
          case 'phases':
            myAutoFillData.phaseTitle = value.title;
            break;
          case 'categories':
            myAutoFillData.categoryTitle = value.title;
            break;
          case 'priorities':
            myAutoFillData.priorityTitle = value.title;
            break;
          case 'tags':
            myAutoFillData.tags = [value];
            break;
          case 'customers':
            myAutoFillData.customers = [value];
            break;
          default:
            break;
        }
      }
    }

    this.setStateAutoFillData(myAutoFillData);
  }

  render() {
    const { autoFillData, isDialogVisible, isLoaded } = this.state;

    if (!isLoaded) {
      return <PageLoading />;
    }

    return (
      <>
        <IdeasBoard
          {...this.props}
          recentlyUsedData={this.props.recentlyUsedData}
          projects={this.props.projects}
          timeframes={this.props.timeframes}
          objectives={this.props.objectives}
          roadmaps={this.props.roadmaps}
          themes={this.props.themes}
          categorys={this.props.categories}
          phases={this.props.phases}
          teams={this.props.teams}
          priorities={this.props.priorities}
          planningStages={planningStageOptions}
          createProject={this.props.createProject}
          updateProject={this.props.updateProject}
          updateProjects={this.props.updateProjects}
          deleteProjects={this.props.deleteProjects}
          switchProjectRowOrder={this.props.switchProjectRowOrder}
          importProjects={this.props.importProjects}
          customers={this.props.customers}
          tags={this.props.tags}
          jiraIntegrated={this.props.jiraIntegrated}
          systemFields={this.props.systemFields}
          pageFilters={this.props.pageFilters}
          integrations={this.props.integrations}
          autoFillData={autoFillData}
          toggleDialog={visible => this.setState({ isDialogVisible: visible })}
        />
        <PlanningStageWarningDialog isOpen={isDialogVisible} onConfirm={() => this.setState({ isDialogVisible: false })} />
      </>
    );
  }
}

const selectFilteredProjectsForPage = makeSelectFilteredProjectsByRoadmapForPage();

const withHooksHOC = Component => {
  return props => {
    const dispatch = useDispatch();
    const { pageFilters: filters, displayLayer } = usePageFilters(IDEAS_BOARD_PAGE);
    const projects = useSelector(state => selectFilteredProjectsForPage(state, IDEAS_BOARD_PAGE, true, displayLayer));
    const [initiatives, bets, hasHierarchy] = useGetInitiativesAndBets();
    const annProjects = useAnnotateProjectsWithChildrenCount(projects, props.displayLayer);
    const voteOnProject = projectId => dispatch(voteOnProjectAction(projectId));
    const unvoteOnProject = projectId => dispatch(unvoteOnProjectAction(projectId));
    const [loadedProjects, loadProjects] = useLoadProjectsForTransactionPages(IDEAS_BOARD_PAGE);
    const customFields = useSelector(state => getProjectsCustomFields(state));
    const organization = useSelector(state => getOrganization(state));
    const { searchString: localSearchString } = props.lsState;
    const globalSearchString = useSelector(getSearchOnPageFilters);
    const searchString = localSearchString || globalSearchString;

    const showMyItemsOnly = useSelector(state => state.ideasBoard.showMyItemsOnly);

    const [filterBySearchString] = useProjectsLocalSearch(projects, searchString);
    const filterMyItems = useProjectsLocalFilterByOwner(projects, showMyItemsOnly);

    const { applyFrontendFiltersOnProject } = useProjectsFrontendFilters(filterMyItems, filterBySearchString);

    const orgJiraIntegrations = useSelector(getOrgJiraIntegrations);

    const { canView, canUpdate, canCreate } = usePermissions();

    const { isParentDragon, isDodActive, getDefaultRoadmapForMetadataItem } = useOrganizationsAccessControl();

    const { has_rename_jira_project_enabled: hasRenameJiraProjectEnabled } = props.organization;
    const warning = hasRenameJiraProjectEnabled && getJiraIntegrationWarnings(orgJiraIntegrations, JIRA_PROJECT_RENAMED_WARNING);

    const filterFieldName = Object.keys(filters.quickFilters || [''])[0];
    const filterValue = filters.quickFilters && filters.quickFilters[filterFieldName];

    const hasMetadataRoadmaps = organization.enable_metadata_roadmaps;

    const shouldDisplayControlsBar = canView(PERMISSION_FEATURES.controlsBar);

    const metadataToBeFiltered = {
      priorities: useSelector(state => getPriorities(state)),
      timeframes: useSelector(state => getStateDataForPage(state, getTimeframes, 'timeframes')),
      roadmaps: useSelector(state => getStateDataForPage(state, getRoadmaps, 'roadmaps')),
      themes: useSelector(state => getStateDataForPage(state, getThemes, 'themes')),
      categories: useSelector(state => getStateDataForPage(state, getCategories, 'categories')),
      phases: useSelector(state => getStateDataForPage(state, getPhases, 'phases')),
      objectives: useSelector(state => getStateDataForPage(state, getObjectives, 'objectives')),
      teams: useSelector(state => getStateDataForPage(state, getAllTeams, 'teams')),
      customers: useSelector(state => getStateDataForPage(state, getCustomersFilteredByStatus, 'customers')),
      tags: useSelector(state => getStateDataForPage(state, getTags, 'tags')),
    };

    const metadataItems = filterMetadataOptions(hasMetadataRoadmaps, projects, metadataToBeFiltered, filters.quickFilters, {
      isDodActive,
      getDefaultRoadmapForMetadataItem,
    });

    const _onWarningClick = () => {
      dispatch(setAppGlobalConfig({ showUpdateJiraKeyDialog: true }));
    };

    if (!loadedProjects) return <PageLoading />;

    return (
      <Component
        {...props}
        {...metadataItems}
        lsState={{
          ...props.lsState,
          searchString,
        }}
        applyFrontendFiltersOnProject={applyFrontendFiltersOnProject}
        pageFilters={filters}
        hasMetadataRoadmaps={hasMetadataRoadmaps}
        initiativesForGrouping={
          initiatives
            ? filterInitiativeOrBetsForGroup(
                initiatives.filter(p => p.planningStage !== 'Archived'),
                props.lsState.dataType,
                filters,
              )
            : []
        }
        betsForGrouping={
          bets
            ? filterInitiativeOrBetsForGroup(
                bets.filter(p => p.planningStage !== 'Archived'),
                props.lsState.dataType,
                filters,
              )
            : []
        }
        displayLayer={displayLayer}
        projects={annProjects || projects}
        hasHierarchy={hasHierarchy}
        hasBet={props.hasBet}
        loadProjects={loadProjects}
        voteOnProject={voteOnProject}
        unvoteOnProject={unvoteOnProject}
        customFields={customFields}
        filterFieldName={filterFieldName}
        filterValue={filterValue}
        warning={warning}
        onWarningClick={_onWarningClick}
        showPageConfigReadOnly={shouldDisplayControlsBar}
        isParentDragon={isParentDragon}
        canView={canView}
        canUpdate={canUpdate}
        canCreate={canCreate}
      />
    );
  };
};

const makeMapStateToProps = () => {
  return state => {
    const { ideasBoard, objectives, organization, roadmaps } = state;
    const hasMultiLevelPortfolioMetadata = !!organization.organization.has_multi_level_portfolio_metadata;
    const isDodActive = getIsDodActive(state);

    const objectivesCorp = getStateDataForPage(
      state,
      (state, showArchived) => getObjectives(state, showArchived, 1),
      'objectivesCorp',
    );
    const roadmapsCorp = getStateDataForPage(
      state,
      (state, showArchived) => getRoadmaps(state, showArchived, 'CORP'),
      'roadmapsCorp',
    );
    const timeframesCorp = getStateDataForPage(state, getTimeframesLevelCorp, 'timeframesCorp');
    const categoriesCorp = getStateDataForPage(state, getCategoriesLevelCorp, 'categoriesCorp');

    let keyResults = getStateDataForPage(state, selectKeyResults1, 'keyResults');
    let keyResult2s = getStateDataForPage(state, (state, showArchived) => selectKeyResults2(state, showArchived), 'keyResults');
    let timeframes2 = hasMultiLevelPortfolioMetadata ? getStateDataForPage(state, getTimeframesLevel2, 'timeframes2') : [];
    const allProducts = getStateDataForPage(state, getProducts, 'products');
    let products = allProducts;

    if (ideasBoard.secondLayerFilterId) {
      keyResults = objectives.objectives.find(obj => obj.id === ideasBoard.secondLayerFilterId)
        ? objectives.objectives.find(obj => obj.id === ideasBoard.secondLayerFilterId).keyResults || []
        : [];
      keyResult2s = keyResult2s.filter(
        kr2 => kr2.parent_id === ideasBoard.secondLayerFilterId || kr2.objective_id === ideasBoard.secondLayerFilterId,
      );
      products = roadmaps.roadmaps.find(obj => obj.id === ideasBoard.secondLayerFilterId)
        ? roadmaps.roadmaps.find(obj => obj.id === ideasBoard.secondLayerFilterId).products || []
        : [];
      if (hasMultiLevelPortfolioMetadata) {
        timeframes2 = timeframes2.filter(tf2 => tf2.parent_id === ideasBoard.secondLayerFilterId);
      }
    }

    return {
      integrations: organization.integrations,
      objectivesCorp,
      keyResults,
      keyResult2s,
      roadmapsCorp,
      allProducts,
      products,
      timeframesCorp,
      timeframes2,
      categoriesCorp,
      jiraIntegrated: getOrgHasJiraIntegrated(state),
      isAuthenticated: state.login.isAuthenticated,
      currentUser: state.login.currentUser,
      lsState: ideasBoard,
      emptyLaneVisibility: state.ideasBoard.emptyLaneVisibility,
      showMyItemsOnly: state.ideasBoard.showMyItemsOnly,
      systemFields: state.organization.organization.system_fields_name,
      hasKeyResults: state.organization.organization.has_key_results,
      hasKeyResults2: state.organization.organization.has_key_results_2,
      hasProducts: state.organization.organization.has_products,
      hasBet: state.organization.organization.has_bet,
      filterPageId: IDEAS_BOARD_PAGE,
      recentlyUsedData: state.projects.recentlyUsedData,
      hasMultiLevelPortfolioMetadata,
      isDodActive,
    };
  };
};

export default subscribeNewData([
  'app',
  'projects',
  'estimates',
  'teams',
  'themes',
  'categories',
  'phases',
  'objectives',
  'roadmaps',
  'timeframes',
  'teams',
  'skills',
  'tags',
  'customers',
  'products',
  'keyResults',
  'customFields',
])(
  compose(
    connect(makeMapStateToProps, {
      ...projectActions,
      ...objectiveActions,
      ...roadmapActions,
      ...themeActions,
      ...categoryActions,
      ...phaseActions,
      ...teamActions,
      ...timeframeActions,
      ...ideasBoardActions,
      ...userActions,
      deleteKeyResultById,
      deleteProductById,
      deleteTimeframeById,
      updateKeyResultById,
      updateProductById,
      mergeTimeframes,
      deleteTheme,
      mergeThemes,
      deleteCategory,
      mergeObjectives,
      mergeKeyResults,
      requestRemoveObjectiveById,
      updateObjectiveById,
      updatePhaseById,
      deletePhaseById,
      mergePhases,
      updateRoadmapById,
      deleteRoadmapById,
      mergeRoadmaps,
      mergeProducts,
      mergeCategories,
      importProjects,
      secureUpdateProjectEstimates,
      showImportIdeasLightbox,
      fetchTags,
      fetchCustomers,
      setDisplayLayer,
      openProjectLightbox,
    }),
    withHooksHOC,
  )(IdeasBoardContainer),
);
