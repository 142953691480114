import { useCallback, useMemo } from 'react';

import useInitAgGrid from 'design-system/molecules/AgGridReact-New/hooks/useInitAgGrid';

import processRowDataFromClipboard from './helpers/processRowDataFromClipboard';

const methodsToDecorate = {
  processRowDataFromClipboard,
};

const useCopyPasteLightbox = () => {
  const { isGridReady, getGridApi, getGridColumnApi, initAgGrid, methods } = useInitAgGrid(methodsToDecorate);

  const generateGridOptions = useCallback(
    (onRowSelected, mandatoryField) => ({
      onRowSelected,
      enableSorting: false,
      enableFilter: false,
      sideBar: {
        toolPanels: ['columns'],
        defaultToolPanel: '',
      },
      getRowStyle: params => {
        if (params.data && Object.keys(params.data).length !== 0 && !params.data[mandatoryField]) {
          return {
            backgroundColor: 'rgb(249, 244, 255)',
          };
        }
      },
    }),
    [],
  );

  // TODO: add more common logic when IdeasCopyPaste is converted

  return useMemo(
    () => ({
      isGridReady,
      getGridApi,
      getGridColumnApi,

      initAgGrid,

      methods,
      generateGridOptions,
    }),
    [methods, generateGridOptions],
  );
};

export default useCopyPasteLightbox;
