import { useCallback, useState } from 'react';

import { INTEGRATIONS_KEYS } from 'constants/integrations';

import useUserIntegrations from 'hooks/useUserIntegrations';
import useAppNotifications from 'hooks/useAppNotifications';

const useTestRallyConnection = (orgIntegrationId, handleTestConnection) => {
  const [isTestingRallyConnection, setIsTestingRallyConnection] = useState(false);

  const { addNotification } = useAppNotifications();
  const { removeUserIntegration } = useUserIntegrations(INTEGRATIONS_KEYS.rally, orgIntegrationId);

  const handleTestRallyConnection = useCallback(() => {
    setIsTestingRallyConnection(true);

    handleTestConnection(INTEGRATIONS_KEYS.rally, orgIntegrationId)
      .then(({ value }) => {
        if (!value?.data) {
          removeUserIntegration();
        } else {
          addNotification({ message: 'Rally is configured correctly.' });
        }
      })
      .catch(() => {
        removeUserIntegration();
      })
      .finally(() => {
        setIsTestingRallyConnection(false);
      });
  }, [orgIntegrationId, handleTestConnection, removeUserIntegration]);

  return { isTestingRallyConnection, handleTestRallyConnection };
};

export default useTestRallyConnection;
