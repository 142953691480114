import React from 'react';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';
import MenuListItem from '@material-ui/core/MenuItem';
import MenuListItemIcon from '@material-ui/core/ListItemIcon';
import MenuListItemText from '@material-ui/core/ListItemText';

const DefaultMenuItem = ({ item, handleSelectItem }) => {
  if (item.hide) {
    return null;
  }

  return (
    <>
      <StyledMenuListItem key={item.key} data-testid={item.testId} onClick={() => handleSelectItem(item)}>
        {item.icon && <StyledMenuListItemIcon>{item.icon}</StyledMenuListItemIcon>}
        <StyledMenuListItemText>{item.label}</StyledMenuListItemText>
      </StyledMenuListItem>
      {item.showBorder && <StyledDivider />}
    </>
  );
};

export default DefaultMenuItem;

const StyledMenuListItem = styled(MenuListItem)`
  &&&& {
    height: 18px;
  }
`;

const StyledMenuListItemIcon = styled(MenuListItemIcon)`
  &&&& {
    margin-right: 0;
  }
`;

const StyledMenuListItemText = styled(MenuListItemText)`
  &&&& {
    span {
      font-size: ${props => props.theme.typography.fontSize}px;
      font-weight: ${props => props.theme.typography.fontWeightRegular};
      color: ${props => props.theme.palette.text.lightGrey};
    }
  }
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 4px 10px;
  }
`;
