import React from 'react';
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';

import theme from 'design-system/theme';

export default ({ baseNodeText, childNodes, goTo, isActive, organization, user, open = false }) => {
  const [isOpen, setIsOpen] = React.useState(open);

  const filteredChildNodes = childNodes.filter(child => !child.visible || child.visible(user.role_id, user, organization));

  if (filteredChildNodes.length === 0) return null;

  const hasChildActive = filteredChildNodes.find(c => isActive(c.path));

  React.useEffect(() => {
    if (hasChildActive && !open) setIsOpen(true);
  }, []);

  return (
    <React.Fragment>
      <ListItem button key={baseNodeText} onClick={() => setIsOpen(!isOpen)} style={{ paddingLeft: 26 }}>
        <ListItemText
          primary={baseNodeText}
          primaryTypographyProps={{
            style: {
              color: hasChildActive && theme.palette.newLayout.background.primary,
            },
          }}
          style={{ paddingRight: 4 }}
        />
        <ListItemSecondaryAction style={{ right: 10, pointerEvents: 'none' }}>
          <ExpandIcon isOpen={isOpen} />
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {filteredChildNodes.map(child => (
            <ListItem button onClick={goTo(child.path)} style={{ paddingLeft: 40 }}>
              <ListItemText
                primary={child.label}
                primaryTypographyProps={{
                  style: {
                    color: isActive(child.path) && theme.palette.newLayout.background.primary,
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

const ExpandIcon = styled(ExpandLessIcon)`
  &&&& {
    color: ${({ theme }) => theme.palette.primary.main};
    transition: transform 0.25s linear;
    transform: rotate(${props => (props.isOpen ? 180 : 90)}deg);
  }
`;
