import { useState, useEffect } from 'react';

import useSaveViewDialogUI from 'hooks/userViews/useSaveViewDialogUI';
import useViewsForReleaseNotes from 'features/AIReleaseNotes/hooks/useViewsForReleaseNotes';
import useGenerateAiReleaseNotes from 'features/AIReleaseNotes/hooks/useGenerateAIRelaseNotes';

const useGenerateAiReleaseNotesDialog = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [releaseNotes, setReleaseNotes] = useState(null);

  const { activeView, editableUserViews, cloneView, updateView } = useViewsForReleaseNotes();

  const {
    releaseNotes: generatedReleaseNotes,
    isGeneratingReleaseNotes,
    generateReleaseNotes,
    hasReleaseNotesFeature,
  } = useGenerateAiReleaseNotes();

  const {
    saveDialogProps: saveViewDialogProps,
    openSaveDialog: openSaveViewDialog,
    closeSaveDialog: closeSaveViewDialog,
  } = useSaveViewDialogUI();

  const openDialog = () => setShowDialog(true);

  const closeDialog = () => {
    setShowDialog(false);
    setReleaseNotes(null);
  };

  const updateReleaseNotes = updatedReleaseNotes => setReleaseNotes(updatedReleaseNotes);

  const onGenerateReleaseNotes = (summaryFormat, summarizeBy) => generateReleaseNotes(summaryFormat, summarizeBy);

  const onSaveReleaseNotes = () => {
    const isDefaultView = activeView?.default_view;

    if (isDefaultView) {
      openSaveViewDialog();
    } else {
      updateView(releaseNotes);
      closeDialog();
    }
  };

  const onSaveUserView = async (name, description) => {
    cloneView(name, description, releaseNotes);
    closeDialog();
  };

  const onUpdateUserView = async current => {
    updateView(releaseNotes, current);
    closeSaveViewDialog();
    closeDialog();
  };

  useEffect(() => setReleaseNotes(generatedReleaseNotes), [generatedReleaseNotes]);

  return {
    isOpen: showDialog,
    isLoading: isGeneratingReleaseNotes,
    releaseNotes,
    hasReleaseNotesFeature,
    saveViewDialogProps,
    editableUserViews,
    onSaveReleaseNotes,
    onGenerateReleaseNotes,
    onSaveUserView,
    onUpdateUserView,
    updateReleaseNotes,
    openDialog,
    closeDialog,
    closeSaveViewDialog,
  };
};

export default useGenerateAiReleaseNotesDialog;
