import React from 'react';
import useAIAnswerBotDialog from '../../hooks/useAIAnswerBotDialog';

// Parses User messages
const MessageParser = ({ children, actions }) => {
  const { processUserMessage } = useAIAnswerBotDialog();

  const parse = async message => {
    actions.addAsyncMessageLoaderToState();

    const promptResponse = await processUserMessage(message);

    actions.addBotMessageToState(promptResponse);
  };

  return (
    <div>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
