import { defaultTo, divide } from 'ramda';
import moment from 'moment';
import calculateDuration from 'utils/calculateDuration';

const defaultToZero = defaultTo(0);

/**
 *
 * Method to show counter values instead of percentage
 *
 */
const formatterToShowEpicCounter = (_, ctx) => {
  const { dataset, dataIndex } = ctx;

  return defaultToZero(dataset.counters[dataIndex]);
};

const getDefaultProgressSummaryDates = () => {
  const startDate = moment().subtract(3, 'months').startOf('quarter');
  const endDate = moment().endOf('quarter');

  return {
    endDate,
    startDate,
  };
};

const setToMidnight = date => moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

const getExpectedProgressForDateRange = (startDate, endDate) => {
  const today = setToMidnight(moment());
  const start = setToMidnight(startDate);
  const end = setToMidnight(endDate);

  const todayAndStartDuration = calculateDuration(start, today);
  const dateRangeDuration = calculateDuration(start, end);

  const expectedProgress = divide(todayAndStartDuration, dateRangeDuration);

  return expectedProgress > 1 ? 1 : expectedProgress;
};

export { formatterToShowEpicCounter, getDefaultProgressSummaryDates, getExpectedProgressForDateRange };
