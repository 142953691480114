import isEmpty from 'lodash/isEmpty';
import { indexBy, prop } from 'ramda';
import { LEADER_USER, OWNER_USER, MANAGER_USER, ADMIN_USER } from '@dragonboat/permissions';

import { METADATA_LEVELS } from 'constants/common';
import { DEFAULT_DESCRIPTION, INITIAL_LAYOUT_BY_ID } from '../constants/widgetsConfig';
import { filterActiveItens } from 'utils/index';

/**
 * Filters the OKRs based on the provided filter function.
 * Recursively filters the children and key results of each OKR.
 *
 * @param {Array} okrs - The array of OKRs to filter.
 * @param {Function} filterByRoadmap - The filter function to apply.
 * @returns {Array} - The filtered OKRs.
 */
export const getFilteredOKRs = (okrs, filterByRoadmap) => {
  const filteredOkrs = (okrs || [])
    .filter(filterByRoadmap)
    .filter(filterActiveItens)
    .map(eachOkr => ({
      ...eachOkr,
      children: getFilteredOKRs(eachOkr.children, filterByRoadmap),
      keyResults: getFilteredOKRs(eachOkr.keyResults, filterByRoadmap),
    }));

  return filteredOkrs;
};

export const getFilteredMetrics = (metrics, roadmapId, roadmapType) => {
  const roadmapFieldByType = {
    [METADATA_LEVELS.LEVEL_1]: 'roadmap_id',
    [METADATA_LEVELS.LEVEL_2]: 'product_1_id',
    [METADATA_LEVELS.LEVEL_3]: 'product_2_id',
  };

  const filteredMetrics = metrics.filter(m => {
    // When no roadmap is selected we only want to return metrics that aren't associated with any roadmap
    if (!roadmapId) return isEmpty(m?.metric_roadmaps);

    return m.metric_roadmaps.some(r => r[roadmapFieldByType[roadmapType]] === roadmapId);
  });

  return filteredMetrics;
};

export const layoutOrDefaultForAllWidgets = (widgets, layout) => {
  const layoutIndexedById = indexBy(prop('i'))(layout);

  return widgets.map(widget => layoutIndexedById[widget.id] || INITIAL_LAYOUT_BY_ID[widget.id]);
};

export const defaultDescriptionBuilder = () => {
  return DEFAULT_DESCRIPTION;
};

export const defaultEditMode = (userRole, portfolioOverviewIsCommitted) => {
  const userRoleDefaultsToEditMode = [OWNER_USER, LEADER_USER, MANAGER_USER, ADMIN_USER].includes(userRole);

  return userRoleDefaultsToEditMode && !portfolioOverviewIsCommitted;
};
