import reduceReducers from 'reduce-reducers';
import camelCase from 'lodash/camelCase';

import { bulkThunkInitialState, getThunksReducers } from 'utils/store/thunk';

import { RESET_REQUESTS_SEARCH, SEARCH_REQUESTS_BY_TEXT } from './types';

export const initialState = {
  forms: {},
  operations: bulkThunkInitialState([SEARCH_REQUESTS_BY_TEXT]),
};

const featureReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_REQUESTS_SEARCH: {
      const operationKey = camelCase(SEARCH_REQUESTS_BY_TEXT);

      return {
        ...state,
        operations: {
          ...state.operations,
          [operationKey]: {
            ...state.operations[operationKey],
            data: [],
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

const operationsReducer = getThunksReducers([SEARCH_REQUESTS_BY_TEXT]);

const reducer = reduceReducers(initialState, featureReducer, ...operationsReducer);

export default reducer;
