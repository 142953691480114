import moment from 'moment-timezone';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import { divide } from 'ramda';

import converTimeToString from 'utils/converTimeToString';
import formatDateForGantt from 'utils/dates/formatDateForGantt';
import itemsTimespan from 'utils/itemsTimespan';
import { PROJECT_INTEGRATION_STATUSES } from 'constants/integrations';
import theme from 'design-system/theme';
import getDateTimeFormat from 'utils/dates/getDateTimeFormat';

const BUG = 'BUG';
const DONE = +PROJECT_INTEGRATION_STATUSES.DONE;
const IN_PROGRESS = +PROJECT_INTEGRATION_STATUSES.IN_PROGRESS;
const OPEN = +PROJECT_INTEGRATION_STATUSES.OPEN;
const TASK_GANTT_TYPE = 'task';
const MILESTONE_GANTT_TYPE = 'milestone';
const STORY_DB_TYPE = 'story';

/**
 * Prepare story key for sorting purposes
 * for example PROD-123 to 123
 *
 * @function _prepareStoryKey
 * @param  {String} key to prepare
 * @return {String} prepared key for sorting purposes
 */
const _prepareStoryKey = key => key.toString().replace(/[0-9]+/g, v => v.padStart(10, '0'));

/**
 * Sort stories by integration key
 *
 * @function _sortStoriesByKey
 * @param  {String} a
 * @param  {String} b
 * @return {Number}
 */
const _sortStoriesByKey = (a, b) => {
  const v0 = a && a.key ? _prepareStoryKey(a.key) : 0;
  const v1 = b && b.key ? _prepareStoryKey(b.key) : 0;

  if (v0 && v1) return v0.localeCompare(v1);

  return 0;
};

/**
 * Sort stories by Status Category number 0 - OPEN, 1 - IN PROGRESS, 2 - DONE
 *
 * @function _sortStoriesByStatusCategory
 * @param  {String} a
 * @param  {String} b
 * @return {Number}
 */
const _sortStoriesByStatusCategory = (a, b) => {
  return get(b, 'status_category', OPEN) - get(a, 'status_category', OPEN);
};

/**
 * Sort stories by start date
 *
 * @function _sortByStartDate
 * @param  {String} a
 * @param  {String} b
 * @return {Number}
 */
const _sortByStartDate = (a, b) => {
  if (a?.start_date && b?.start_date) {
    return moment(a.start_date, getDateTimeFormat()).unix() - moment(b.start_date, getDateTimeFormat()).unix();
  }

  return 0;
};

/**
 * Sort stories by start date if Status Category DONE
 *
 * @function _sortStoriesByStatusCategoryOrStartDate
 * @param  {type} a
 * @param  {type} b
 * @return {type}
 */
const _sortStoriesByStartDateIfDone = (a, b) => {
  const isStoryDone = s => s?.status_category === DONE;

  if (isStoryDone(a) && isStoryDone(b)) {
    return _sortByStartDate(a, b);
  }

  // maintain original order
  return 0;
};

/**
 * Calculation of story start date of each story
 *
 * If has start date will it
 *
 * If DONE will use the dateClosed
 *
 * @function _calcStoryStartDate
 * @param  {Object} story
 * @return {Date}
 */
const _calcStoryStartDate = story => {
  if (story?.startDate) {
    return moment(story.startDate);
  }
  if (story?.status_category === DONE) {
    return moment(story.dateClosed);
  }

  return new Date();
};

/**
 * Calc story progress based on Status Category
 *
 * DONE - 100%
 * IN PROGRESS - 50%
 * OPEN - 0%
 *
 * @function _calcStoryProgress
 * @param  {Number} statusCategory
 * @return {Number}
 */
const _calcStoryProgress = story => {
  if (story?.progress || story?.progress === 0) return story.progress;
  if (story.status_category === DONE) return 100;
  if (story.status_category === IN_PROGRESS) return 50;
  return 0;
};

/**
 * Get story color base on Status Category
 *
 * @function _getStoryColor
 * @param  {Number} statusCategory
 * @return {String}
 */
const _getStoryColor = statusCategory => {
  if (statusCategory === DONE) return theme.palette.background.bermuda;
  if (statusCategory === IN_PROGRESS) return theme.palette.background.anakiwa;
  return theme.palette.background.alto;
};

/**
 * Check if story is BUG
 *
 * @function _storyIsBug
 * @param  {Object} story
 * @return {Boolean}
 */
const _storyIsBug = story => {
  return story?.issueType && story.issueType.toUpperCase() === BUG;
};

/**
 * Check if shouyld exclude bugs progress based org integration configs
 *
 * @function _souldExcludeBugsProgress
 * @param  {Object} orgIntegration
 * @return {Boolean}
 */
const _souldExcludeBugsProgress = orgIntegration => {
  return orgIntegration?.data?.exclude_bugs_progress;
};

/**
 * Check if should hide story points based on org integration configs
 *
 * @function _shouldHideStoryPoints
 * @param  {Object} story
 * @param  {Object} orgIntegration
 * @return {Boolean}
 */
const _shouldHideStoryPoints = (story, orgIntegration) => {
  return _storyIsBug(story) && _souldExcludeBugsProgress(orgIntegration);
};

/**
 * Check if should hide time estimated based on org integration configs
 *
 * @function _shouldHideTimeEstimated
 * @param  {Object} story
 * @param  {Object} orgIntegration
 * @return {Boolean}
 */
const _shouldHideTimeEstimated = (story, orgIntegration) => {
  return _storyIsBug(story) && _souldExcludeBugsProgress(orgIntegration);
};

/**
 * Check if story object has start date and end date
 *
 * @function _hasStartAndEndDate
 * @param  {Object} story
 * @return {Boolean}
 */
const _hasStartAndEndDate = story => {
  return story?.startDate && story?.endDate;
};

/**
 * Get the story type based on story data
 *  - if has start and end date is task bar
 *  - if has any dates is milestone diamond
 *
 * @function _getStoryGanttType
 * @param  {Object} story
 * @return {String}
 */
const _getStoryGanttType = story => {
  if (_hasStartAndEndDate(story)) {
    return TASK_GANTT_TYPE;
  }

  return MILESTONE_GANTT_TYPE;
};

/**
 * Parse the story duration based on start and end date
 *  - if has start and end date has duration and end date
 *  - if has any dates dos not have duration
 * @function _parseStoryDuration
 * @param  {Object} story
 * @return {Object}
 */
const _parseStoryDuration = story => {
  if (_hasStartAndEndDate(story)) {
    return {
      end_date: moment(story.endDate),
      duration: moment(story.endDate).diffDuration(moment(story.startDate), 'days'),
    };
  }

  return {};
};

/**
 * Parse story data to be used in Gantt
 *
 * @function _parseStory
 * @param  {Object} story
 * @param  {Object} project
 * @return {Object}
 */
const _parseStory = (story, project) => {
  const type = _getStoryGanttType(story);
  const progress = _calcStoryProgress(story);

  return {
    ...story,
    ..._parseStoryDuration(story),
    id: `story-${story.id}`,
    dbType: STORY_DB_TYPE,
    format: type === MILESTONE_GANTT_TYPE ? 'circle' : 'bar',
    textColor: theme.palette.text.lightBlack,
    parent: project.id,
    roadmap_id: project.roadmap_id,
    objective_id: project.objective_id,
    type,
    start_date: _calcStoryStartDate(story),
    progressOriginal: divide(progress, 100),
    progressPercent: progress,
    color: _getStoryColor(story.status_category),
    storyPoints: _shouldHideStoryPoints(story) ? '' : story.storyPoints,
    timeEstimated: _shouldHideTimeEstimated(story) ? '' : converTimeToString(story.timeEstimated),
  };
};

/**
 * Parse Predicted End Date diamond to be shown in Gantt
 *
 * @function _parsePredictedEndDate
 * @param  {Object} project
 * @param  {Date} predictedEndDate
 * @return {Object}
 */
const _parsePredictedEndDate = (project, predictedEndDate) => {
  return {
    id: `${project.id}end_date`,
    type: MILESTONE_GANTT_TYPE,
    dbType: STORY_DB_TYPE,
    format: 'diamond',
    text: 'Predicted end date',
    ownerName: '',
    textColor: theme.palette.text.lightBlack,
    color: theme.palette.secondary.main,
    parent: project.id,
    start_date: predictedEndDate,
  };
};

/**
 * Calc the project integration timeline based on project integrationProgress
 *
 * @function _calcProjectIntegrationTimespan
 * @param  {Object} project
 * @return {Object}
 */
const _calcProjectIntegrationTimespan = project => {
  // Calculates project timespan based on project dates and integration dates
  const dates = [];

  if (project.integrationProgress) {
    if (project.integrationProgress.startDate) {
      dates.push(moment(project.integrationProgress.startDate));
    }
    if (project.integrationProgress.endDate) {
      dates.push(moment(project.integrationProgress.endDate));
    }
  }

  return itemsTimespan(dates);
};

/**
 * Calc target timeline of the project
 *
 * @function _calcProjectTimeline
 * @param  {Object} project
 * @param  {Array} stories
 * @return {Object}
 */
const _calcProjectTimeline = (project, stories) => {
  const storiesDates = flatten(
    stories.map(story => {
      const storyDates = [];

      if (story.start_date) {
        storyDates.push(moment(story.start_date, getDateTimeFormat()));
      }
      if (story?.end_date) {
        storyDates.push(moment(story.end_date, getDateTimeFormat()));
      }

      return storyDates;
    }),
  );
  const storiesTimespan = itemsTimespan(storiesDates);
  const integrationTimespan = _calcProjectIntegrationTimespan(project);
  let targetEndDate = storiesTimespan ? storiesTimespan.endDate : new Date();
  let startDate = storiesTimespan ? storiesTimespan.startDate : new Date();

  targetEndDate = integrationTimespan ? new Date(integrationTimespan.endDate) : targetEndDate;
  startDate = integrationTimespan ? new Date(integrationTimespan.startDate) : startDate;
  // Check if the targetEndDate is under stories timespan end date
  const isTargetEndDateUnderStoriesEndDate = moment(targetEndDate) < moment(storiesTimespan.endDate);

  if (storiesTimespan && isTargetEndDateUnderStoriesEndDate) {
    targetEndDate = storiesTimespan.endDate;
  }

  return {
    startDate,
    endDate: targetEndDate,
  };
};

/**
 * Format the dates to be used on Gantt
 *
 * @function _formatStoriesDatesToGantt
 * @param  {type} stories
 * @return {type}
 */
const _formatStoriesDatesToGantt = stories => {
  return stories.map(story => ({
    ...story,
    ...(story.end_date ? { end_date: formatDateForGantt(story.end_date) } : {}),
    start_date: formatDateForGantt(story.start_date),
  }));
};

/**
 * Function that parses the organization stories to the gantt format
 *
 *
 * @param {*} project project object
 */
const parseProjectStories = project => {
  if (!project.stories) return;

  const result = project.stories
    .map(story => _parseStory(story, project))
    .sort(_sortStoriesByKey)
    .sort(_sortStoriesByStatusCategory)
    .sort(_sortStoriesByStartDateIfDone);

  if (!result.length) return;

  const projectTimeline = _calcProjectTimeline(project, result);

  result.push(_parsePredictedEndDate(project, projectTimeline.endDate));

  return {
    result: _formatStoriesDatesToGantt(result),
    startDate: formatDateForGantt(projectTimeline.startDate),
    endDate: formatDateForGantt(projectTimeline.endDate),
  };
};

export default parseProjectStories;
