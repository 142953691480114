export const FETCH_PHASES = 'FETCH_PHASES';
export const FETCH_PHASES_FULFILLED = 'FETCH_PHASES_FULFILLED';

export const UPDATE_PHASE = 'UPDATE_PHASE';
export const UPDATE_PHASE_FULFILLED = 'UPDATE_PHASE_FULFILLED';

export const CREATE_PHASE = 'CREATE_PHASE';
export const CREATE_PHASE_FULFILLED = 'CREATE_PHASE_FULFILLED';

export const UPDATE_PHASE_BY_ID = 'UPDATE_PHASE_BY_ID';
export const UPDATE_PHASE_BY_ID_FULFILLED = 'UPDATE_PHASE_BY_ID_FULFILLED';
export const UPDATE_PHASE_BY_ID_REJECTED = 'UPDATE_PHASE_BY_ID_REJECTED';

export const DELETE_PHASE = 'DELETE_PHASE';
export const DELETE_PHASE_FULFILLED = 'DELETE_PHASE_FULFILLED';

export const MERGE_PHASES = 'MERGE_PHASES';
export const MERGE_PHASES_FULFILLED = 'MERGE_PHASES_FULFILLED';

export const REMOVE_UNSAVED_PHASES = 'REMOVE_UNSAVED_PHASES';
export const ADD_PHASE_WITHOUT_SAVE = 'ADD_PHASE_WITHOUT_SAVE';

export const CREATE_PHASES = 'CREATE_PHASES';
export const CREATE_PHASES_FULFILLED = 'CREATE_PHASES_FULFILLED';
export const UPDATE_PHASES = 'UPDATE_PHASES';
export const UPDATE_PHASES_FULFILLED = 'UPDATE_PHASES_FULFILLED';
export const BULK_DELETE_PHASES = 'BULK_DELETE_PHASES';
export const BULK_DELETE_PHASES_FULFILLED = 'BULK_DELETE_PHASES_FULFILLED';

export const UNDO_CREATE_PHASES = 'UNDO_CREATE_PHASES';
export const UNDO_CREATE_PHASES_FULFILLED = 'UNDO_CREATE_PHASES_FULFILLED';
export const UNDO_UPDATE_PHASES = 'UNDO_UPDATE_PHASES';
export const UNDO_UPDATE_PHASES_FULFILLED = 'UNDO_UPDATE_PHASES_FULFILLED';
export const UNDO_BULK_DELETE_PHASES = 'UNDO_BULK_DELETE_PHASES';
export const UNDO_BULK_DELETE_PHASES_FULFILLED = 'UNDO_BULK_DELETE_PHASES_FULFILLED';

export const UPDATE_PHASE_ROW_ORDER = 'UPDATE_PHASE_ROW_ORDER';
export const UPDATE_PHASE_ROW_ORDER_PENDING = 'UPDATE_PHASE_ROW_ORDER_PENDING';
export const UPDATE_PHASE_ROW_ORDER_FULFILLED = 'UPDATE_PHASE_ROW_ORDER_FULFILLED';
export const UPDATE_PHASE_ROW_ORDER_REJECTED = 'UPDATE_PHASE_ROW_ORDER_REJECTED';

export const CREATE_PHASE_ROADMAP = 'CREATE_PHASE_ROADMAP';
export const CREATE_PHASE_ROADMAP_FULFILLED = 'CREATE_PHASE_ROADMAP_FULFILLED';
export const DELETE_PHASE_ROADMAP = 'DELETE_PHASE_ROADMAP';
export const DELETE_PHASE_ROADMAP_FULFILLED = 'DELETE_PHASE_ROADMAP_FULFILLED';
export const BULK_DELETE_PHASE_ROADMAP = 'BULK_DELETE_PHASE_ROADMAP';
export const BULK_DELETE_PHASE_ROADMAP_FULFILLED = 'BULK_DELETE_PHASE_ROADMAP_FULFILLED';
