import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export default styled(Typography)`
  &&&& {
    font-weight: 500;
    font-size: 16px;
    text-align: left;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
