import { spacing } from 'design-system/theme';
import { css } from 'styled-components';

const stylesOverride = css`
  .ag-layout-normal.ag-root {
    margin: 0;
  }

  .ag-header {
    height: 60px !important;

    .ag-header-cell-resize {
      top: 0;
    }
    .ag-header-cell-resize::after {
      margin-top: 0;
      height: 100%;
    }
  }

  .ag-header-row {
    background-color: #f8fafb;
    height: 60px !important;
  }

  .ag-header-row .ag-header-cell {
    color: #161616;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px !important;
    padding: ${spacing(2.625)}px 0 ${spacing(2.625)}px ${spacing(3)}px !important;
  }

  .ag-row .ag-cell {
    & > div {
      display: flex !important;
      align-items: center !important;

      p {
        font-size: 12px !important;
      }
    }

    &.align-items-top > div {
      align-items: unset !important;
      justify-content: start;
      height: auto;
      max-height: 100%;
    }
  }

  .ag-row .ag-cell:not(.action-icon-cell) {
    padding-left: ${spacing(3)}px !important;
  }

  .ag-row .title-cell.ag-cell-not-inline-editing {
    padding-left: ${spacing(3)}px !important;
    padding-top: 2px !important;

    div {
      font-size: 16px !important;
      color: #161616 !important;
      font-weight: 400 !important;
      width: 100%;
      display: flex !important;
      align-items: center !important;

      & div:first-child {
        width: 100%;
      }

      & div:not(:first-child) {
        width: 26px !important;
      }

      svg {
        top: 19px !important;
        right: 0 !important;
      }
    }

    p {
      font-size: 16px !important;
      color: #161616 !important;
      font-weight: 400 !important;
    }
  }

  .ag-row-hover {
    background-color: #f9f9f9 !important;
  }

  .ag-paging-panel {
    height: 60px !important;
  }

  && {
    .ag-checkbox-input-wrapper:focus-within {
      box-shadow: none;
    }
  }
`;

export { stylesOverride };
