import moment from 'moment-timezone';
import { gt } from 'ramda';

/**
 * Hook to set the Timeline configurations for gantt
 *
 * @returns update configs function
 */
const MONTH_IN_DAYS = 30;
const WEEK_IN_DAYS = 7;
const QUARTER_IN_DAYS = 30 * 3;
const YEAR_IN_DAYS = 365;

const MAP_SCALE_TO_DIFFERENCE = {
  day: 1,
  week: WEEK_IN_DAYS,
  month: MONTH_IN_DAYS,
  quarter: QUARTER_IN_DAYS,
  year: YEAR_IN_DAYS,
};

const gtZeroOrOne = value => (gt(value, 0) ? value : 1);

const setTimelineGanttConfigs = (gantt, lsState) => {
  const { selectedZoom, selectedTimeWindow } = lsState;

  let minColumnWidth = 70;

  if (selectedTimeWindow.key === 'manual') {
    // gantt.config.start_date = moment(selectedTimeWindow.from).subtract(2, gantt.config.duration_unit);
    // gantt.config.end_date = moment(selectedTimeWindow.to).add(2, gantt.config.duration_unit);

    // // TODO: review this method and the way we are fixing the timeline interval
    // setTimeout(() => fixedGanttTimeWindow(gantt), 100);
    const { from, to } = selectedTimeWindow;

    const differenceInDays = moment(to).diff(moment(from), 'days');

    const nUnits = differenceInDays / MAP_SCALE_TO_DIFFERENCE[selectedZoom?.id];

    const columnWidth = gantt.config.grid_width / gtZeroOrOne(nUnits);

    minColumnWidth = columnWidth;

    const startPos = gantt.posFromDate(from);

    setTimeout(() => gantt.scrollTo(startPos, null), 500);
  }
  // gantt.config.start_date = HCTableFilter && HCTableFilter.startDate ? moment(HCTableFilter.startDate) : null;
  // gantt.config.end_date = HCTableFilter && HCTableFilter.endDate ? moment(HCTableFilter.endDate) : null;

  switch (selectedZoom?.id) {
    case 'day':
      gantt.config.scale_unit = 'month';
      gantt.config.min_column_width = minColumnWidth;
      gantt.config.date_scale = '%M %Y';
      gantt.config.subscales = [{ unit: 'day', step: 1, date: '%d' }];
      break;
    case 'week':
      gantt.config.min_column_width = minColumnWidth;
      gantt.config.scale_unit = 'week';
      gantt.config.date_scale = '%d';
      gantt.config.scale_height = 60;
      gantt.config.subscales = [{ unit: 'month', step: 1, date: '%F, %Y' }];
      break;
    case 'month':
      gantt.config.min_column_width = minColumnWidth;
      gantt.config.scale_height = 40;
      gantt.config.scale_unit = 'month';
      gantt.config.date_scale = '%M %Y';
      gantt.config.subscales = [];
      break;
    case 'quarter':
      gantt.config.min_column_width = minColumnWidth;
      gantt.config.scale_height = 40;
      gantt.config.scale_unit = 'quarter';
      gantt.config.date_scale = '%M %Y';
      gantt.config.subscales = [];
      break;
    default:
      break;
  }
};

export default setTimelineGanttConfigs;
