import { defaultTo } from 'ramda';
import { createSelector } from 'reselect';

import getOwnerUsersById from 'features/Canvas/helpers/getOwnerUsers';
import { getUsers } from 'store/users/selectors';

import { keyResultsAdapter, metricsAdapter, objectivesAdapter, projectsAdapter, timeframesAdapter } from './helpers/adapters';
import { selectMetricLevels } from 'features/MetricLevels/store';

const emptyObject = {};

const defaultToProjectsInitialState = defaultTo(projectsAdapter.getInitialState());
const defaultToObjectivesInitialState = defaultTo(objectivesAdapter.getInitialState());
const defaultToKeyResultsInitialState = defaultTo(keyResultsAdapter.getInitialState());
const defaultToTimeframesInitialState = defaultTo(timeframesAdapter.getInitialState());
const defaultToMetricsInitialState = defaultTo(metricsAdapter.getInitialState());

const getState = state => state?.goalsCanvas;

export const selectGoalsCanvasLayoutConfig = state => state.goalsCanvas.layoutConfig || emptyObject;
export const selectGoalsCanvasNodesLayout = state => state.goalsCanvas.nodesLayout || emptyObject;
export const selectGoalsCanvasEdgesLayout = state => state.goalsCanvas.edgesLayout || emptyObject;
export const selectGoalsCanvasUserActionsMeta = state => state.goalsCanvas.userActionsMeta || emptyObject;

export const projectsSelector = projectsAdapter.getSelectors(state => {
  return defaultToProjectsInitialState(state.projects);
});

export const objectivesSelector = objectivesAdapter.getSelectors(state => {
  return defaultToObjectivesInitialState(state.objectives);
});

const keyResultsSelector = keyResultsAdapter.getSelectors(state => {
  return defaultToKeyResultsInitialState(state.keyResults);
});

const timeframesSelector = timeframesAdapter.getSelectors(state => {
  return defaultToTimeframesInitialState(state.timeframes);
});

export const metricsSelector = metricsAdapter.getSelectors(state => {
  return defaultToMetricsInitialState(state.metrics);
});

export const selectDataForGoalsCanvas = createSelector(
  state => projectsSelector.selectAll(getState(state)),
  state => objectivesSelector.selectAll(getState(state)),
  state => keyResultsSelector.selectAll(getState(state)),
  state => timeframesSelector.selectAll(getState(state)),
  state => metricsSelector.selectAll(getState(state)),
  getUsers,
  selectMetricLevels,
  (projects, objectives, keyResults, timeframes, metrics, users, metricLevels) => {
    return {
      projectsList: projects,
      objectivesList: objectives,
      keyResultsList: keyResults,
      timeframesList: timeframes,
      metricsList: metrics,
      usersList: getOwnerUsersById(users, [...projects, ...objectives, ...keyResults, ...metrics]),
      metricLevels,
    };
  },
);
