import { IMPORT_LIGHTBOX_VARIANTS } from 'constants/integrations';
import {
  SHOW_DELETING_IDEA_CONFIRMATION,
  HIDE_DELETING_IDEA_CONFIRMATION,
  SHOW_LIGHTBOX,
  HIDE_LIGHTBOX,
  SHOW_IDEAS_COPY_PASTE_LIGHTBOX,
  HIDE_IDEAS_COPY_PASTE_LIGHTBOX,
  SET_IDEAS_COPY_PASTE_LIGHTBOX_DATA,
  SHOW_IMPORT_IDEAS_LIGHTBOX,
  HIDE_IMPORT_IDEAS_LIGHTBOX,
  SHOW_IMPORT_JQL_LIGHTBOX,
  HIDE_IMPORT_JQL_LIGHTBOX,
  SHOW_IMPORT_TIP,
  HIDE_IMPORT_TIP,
  SELECT_PROJECT_LIGHTBOX_TAB,
  SHOW_IMPORT_ADO_QUERY_LIGHTBOX,
  HIDE_IMPORT_ADO_QUERY_LIGHTBOX,
  SHOW_IMPORT_DRAGONBOAT_FILTER_LIGHTBOX,
  HIDE_IMPORT_DRAGONBOAT_FILTER_LIGHTBOX,
  SHOW_IMPORT_RALLY_VIEW_LIGHTBOX,
  HIDE_IMPORT_RALLY_VIEW_LIGHTBOX,
} from './types';

export const showDeletingIdeaConfirmation = id => ({
  type: SHOW_DELETING_IDEA_CONFIRMATION,
  id,
});

export const hideDeletingIdeaConfirmation = () => ({
  type: HIDE_DELETING_IDEA_CONFIRMATION,
});

export const showLightbox = ideaId => ({
  type: SHOW_LIGHTBOX,
  ideaId,
});

export const hideLightbox = () => ({
  type: HIDE_LIGHTBOX,
});

export const showIdeasCopyPasteLightbox = () => ({
  type: SHOW_IDEAS_COPY_PASTE_LIGHTBOX,
});

export const hideIdeasCopyPasteLightbox = () => ({
  type: HIDE_IDEAS_COPY_PASTE_LIGHTBOX,
});

export const setIdeasCopyPasteLightboxData = data => ({
  type: SET_IDEAS_COPY_PASTE_LIGHTBOX_DATA,
  data,
});

export const showImportIdeasLightbox = () => ({
  type: SHOW_IMPORT_IDEAS_LIGHTBOX,
});

export const hideImportIdeasLightbox = () => ({
  type: HIDE_IMPORT_IDEAS_LIGHTBOX,
});

export const showImportJQLLightbox = () => ({
  type: SHOW_IMPORT_JQL_LIGHTBOX,
});

export const hideImportJQLLightbox = () => ({
  type: HIDE_IMPORT_JQL_LIGHTBOX,
});

export const showImportTip = () => ({ type: SHOW_IMPORT_TIP });
export const hideImportTip = () => ({ type: HIDE_IMPORT_TIP });

export const selectProjectLightboxTab = tab => ({
  type: SELECT_PROJECT_LIGHTBOX_TAB,
  tab,
});

export const showImportADOQueryLightbox = () => ({
  type: SHOW_IMPORT_ADO_QUERY_LIGHTBOX,
});

export const hideImportADOQueryLightbox = () => ({
  type: HIDE_IMPORT_ADO_QUERY_LIGHTBOX,
});

export const showImportRallyViewLightbox = () => ({
  type: SHOW_IMPORT_RALLY_VIEW_LIGHTBOX,
});

export const hideImportRallyViewLightbox = () => ({
  type: HIDE_IMPORT_RALLY_VIEW_LIGHTBOX,
});

export const showImportDragonboatFilterLightbox = (orgIntegrationId, variant = IMPORT_LIGHTBOX_VARIANTS.import) => ({
  type: SHOW_IMPORT_DRAGONBOAT_FILTER_LIGHTBOX,
  payload: { variant, orgIntegrationId },
});

export const hideImportDragonboatFilterLightbox = () => ({
  type: HIDE_IMPORT_DRAGONBOAT_FILTER_LIGHTBOX,
});
