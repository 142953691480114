import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InView } from 'react-intersection-observer';
import styled from 'styled-components';
import GridLayout, { WidthProvider } from 'react-grid-layout';

import BaseLayout from 'design-system/organisms/BaseLayout/index';

import { spacing } from 'design-system/theme';

import { getCurrentUser } from 'store/login/selectors';
import { updateMoarVisibility } from 'store/metrics/actions';
import { mapToObject as mapChartLayersToObject } from 'store/metrics/chartLayers';

import SnapshotMetricItem from 'routes/Dashboard/Snapshot/SnapshotMetricItem/SnapshotMetricItem';
import ActionsToolbar from '../MetricsGrid/components/ActionsToolbar';
import useMetricsChartLayout from 'routes/Metrics/MetricsChart/hooks/useMetricsChartLayout';

import { METRICS_CHART_PAGE } from 'constants/filters';
import { PAGE_SIDE_MARGIN, PAGE_HEADER_HEIGHT } from 'constants/common';
import useMetrics from 'hooks/grid/metrics/useMetrics';
import useFilteredMetrics from 'hooks/useFilteredMetrics';
import useOpenMetricFromEntityWithMetrics from 'hooks/useOpenMetricFromEntityWithMetrics';
import { ProjectsListLightboxProvider } from 'hooks/useProjectsListLightbox';
import useMetricModeMetrics from '../hooks/useMetricModeMetrics';
import useMetricsChartView from '../hooks/useMetricsChartView';
import useQueryParam from 'hooks/useQueryParam';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';
import { getMoreDropdownOptions } from './helpers';

const TOOLBAR_HEIGHT = 56;
const PAGE_HEIGHT_OFFSET = PAGE_HEADER_HEIGHT + TOOLBAR_HEIGHT;

const FluidGridLayout = WidthProvider(GridLayout);

export default function MetricsChart() {
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);

  const isAnonymousUser = currentUser?.is_anonymous;

  const { metrics, searchText, updateSearchText } = useMetrics();

  const quickFilteredMetrics = useMetricModeMetrics(metrics, METRICS_CHART_PAGE);
  const filteredMetrics = useFilteredMetrics(quickFilteredMetrics, { searchText });

  const {
    areMetricClustersVisible,
    startDate,
    endDate,
    handleDateRangeChange,
    hasProjectMetrics,
    userCanEditChartView,
    userCanViewChartToggle,
    handleChartClustersToggle,
    chartVisibleLayers,
    updateChartVisibleLayers,
    isMetricMoarVisible,
    toggleShowMoar,
  } = useMetricsChartView();

  const { handleOpenMetricView } = useOpenMetricFromEntityWithMetrics();

  const { layout, handleLayoutChange, handleDelete, toggleEditMode, inEditMode } = useMetricsChartLayout({
    filteredMetrics,
    searchText,
  });

  const visibleMetrics = useMemo(
    () => filteredMetrics.filter(metric => layout.some(chart => chart.i === metric.id.toString())),
    [layout, filteredMetrics],
  );

  const hasMetricsToRender = visibleMetrics?.length;

  const { canView } = usePermissions();
  const hasMetricsAllocation = canView(PERMISSION_FEATURES.metricMoar);

  useQueryParam(
    'showMoar',
    val => {
      dispatch(updateMoarVisibility(['true', ''].includes(val)));
    },
    { defaultTo: false },
  );

  const handleUpdateChartVisibleLayers = useCallback(
    layer => {
      const isLayerVisible = chartVisibleLayers.includes(layer);

      const chartVisibleLayersPayload = isLayerVisible
        ? chartVisibleLayers.filter(visibleLayer => visibleLayer !== layer)
        : [...chartVisibleLayers, layer];

      updateChartVisibleLayers(chartVisibleLayersPayload);
    },
    [chartVisibleLayers, updateChartVisibleLayers],
  );

  const dropdownOptions = useMemo(
    () => getMoreDropdownOptions(chartVisibleLayers, handleUpdateChartVisibleLayers),
    [chartVisibleLayers, handleUpdateChartVisibleLayers],
  );

  const displayLayers = useMemo(() => mapChartLayersToObject(chartVisibleLayers), [chartVisibleLayers]);
  const moarPreferences = useMemo(() => {
    return {
      show: isMetricMoarVisible,
      showPlanned: displayLayers.showPlanned,
      showCompleted: displayLayers.showCompleted,
      showReported: displayLayers.showReported,
    };
  }, [isMetricMoarVisible, displayLayers]);

  const renderLayoutContent = () =>
    hasMetricsToRender ? (
      <FluidGridLayout
        layout={layout}
        cols={2}
        isResizable={false}
        isDraggable={!!inEditMode}
        onLayoutChange={handleLayoutChange}
        rowHeight={360}
        margin={[spacing(2), spacing(2)]}
        containerPadding={[0, 0]}
        // Disabling css transforms allows InterceptionObserver / <InView/> to properly work
        // and let's us load each chart data as it becomes visible on screen
        useCSSTransforms={false}
      >
        {visibleMetrics.map(metric => (
          <MetricContainer key={metric.id.toString()}>
            <InView threshold={0.2} triggerOnce>
              {({ inView, ref }) => {
                return (
                  <SnapshotMetricItem
                    ref={ref}
                    key={metric.id}
                    inView={inView}
                    metric={metric}
                    startDate={startDate}
                    endDate={endDate}
                    handleOpenMetricView={handleOpenMetricView}
                    displayClusters={areMetricClustersVisible}
                    isAnonymousUser={isAnonymousUser}
                    handleDelete={handleDelete}
                    inEditMode={inEditMode}
                    moarPreferences={moarPreferences}
                    displayActual={displayLayers.showActual || !isMetricMoarVisible}
                    displayTarget={displayLayers.showTarget || !isMetricMoarVisible}
                  />
                );
              }}
            </InView>
          </MetricContainer>
        ))}
      </FluidGridLayout>
    ) : null;

  const renderActions = () =>
    isAnonymousUser ? null : (
      <>
        <ActionsToolbar
          searchText={searchText}
          updateSearchText={updateSearchText}
          showArchivedToggle={false}
          showDateField
          showClusters
          pageId={METRICS_CHART_PAGE}
          inEditMode={inEditMode}
          toggleEditMode={toggleEditMode}
          showMoarToggle={hasMetricsAllocation}
          showMoreOptions={isMetricMoarVisible}
          dropdownOptions={dropdownOptions}
          startDate={startDate}
          endDate={endDate}
          handleDateRangeChange={handleDateRangeChange}
          areMetricClustersVisible={areMetricClustersVisible}
          hasProjectMetrics={hasProjectMetrics}
          userCanEditChartView={userCanEditChartView}
          userCanViewChartToggle={userCanViewChartToggle}
          handleChartClustersToggle={handleChartClustersToggle}
          isMetricMoarVisible={isMetricMoarVisible}
          toggleShowMoar={toggleShowMoar}
        />
      </>
    );

  return (
    <ProjectsListLightboxProvider>
      <BaseLayout>
        {renderActions()}
        <PageBody>{renderLayoutContent()}</PageBody>
      </BaseLayout>
    </ProjectsListLightboxProvider>
  );
}

const PageBody = styled.div`
  background: ${({ theme }) => theme.palette.background.secondary};
  height: calc(100vh - ${PAGE_HEIGHT_OFFSET}px);
  overflow: auto;
  padding: ${spacing(2)}px ${PAGE_SIDE_MARGIN}px;
  margin: 0 -${PAGE_SIDE_MARGIN}px;
  border-top: ${({ theme }) => `1px solid ${theme.palette.border.secondary}`};

  .react-grid-item.react-grid-placeholder {
    background: ${({ theme }) => theme.palette.newLayout.background.header};
    opacity: 1;
  }
`;

const MetricContainer = styled.div`
  &&&& {
    > div {
      width: 100%;
    }
  }
`;
