import { useState, useCallback } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const useAIAnswerBotDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [threadId] = useState(uuidv4());

  const openDialog = () => setIsOpen(true);

  const closeDialog = () => setIsOpen(false);

  const processUserMessage = useCallback(
    async text => {
      const message = await axios.post(`/api/v1/dragonbot/${threadId}/messages`, { text }).then(res => res.data);

      const promptResponse = {
        message: message.output,
        options: {
          withAvatar: false,
        },
      };

      return promptResponse;
    },
    [isOpen],
  );

  return {
    isOpen,
    processUserMessage,
    openDialog,
    closeDialog,
  };
};

export default useAIAnswerBotDialog;
