import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';

import ConfirmDialog from 'components/ConfirmDialog';
import RequestsBulkUpdateModal from 'features/RequestsBulkUpdateModal';
import { PERMISSION_FEATURES } from 'hooks/permissions/usePermissions/constants';
import usePermissions from 'hooks/permissions/usePermissions';
import RequestsMergeModal from 'features/RequestsMergeModal';
import Text from 'design-system/atoms/Text';
import { EDITOR_USER, MANAGER_USER, READ_ONLY_USER, REQUESTOR_USER, COLLABORATOR_USER } from '@dragonboat/permissions';
import { getCurrentUser } from 'store/login/selectors';

const ActionRow = styled.div`
  &&&& {
    margin-right: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding-bottom: 18px;
    margin-left: 44px;
  }
`;

export default props => {
  const [showBulkUpdateModal, setShowBulkUpdateModal] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const { bulkDeleteRequests, selectedItems = [], toggleBulkUpdate } = props;
  const currentUser = useSelector(getCurrentUser);

  const { canView, canUpdate, canDelete } = usePermissions();

  const selectedItemsAllowedForUpdate = selectedItems.filter(i => canUpdate(PERMISSION_FEATURES.requestsListBulkUpdate, i));
  const selectedItemsAllowedForMerge = selectedItems.filter(i => canUpdate(PERMISSION_FEATURES.requestsMerge, i));
  const selectedItemsAllowedForDelete = selectedItems.filter(i => canDelete(PERMISSION_FEATURES.requestsBulkDelete, i));

  const _handleDelete = () => {
    setIsConfirmVisible(false);
    bulkDeleteRequests(selectedItemsAllowedForDelete);
  };

  const _getDeleteConfirmationText = () => (
    <p>Deleting a Customer Request permanently removes all of its data. Are you sure you want to delete?</p>
  );

  const buttonRow = () => {
    return (
      <Fragment>
        {canView(PERMISSION_FEATURES.requestsBulkDelete) && (
          <Button color="secondary" onClick={() => setIsConfirmVisible(true)} disabled={!selectedItemsAllowedForDelete.length}>
            Delete
          </Button>
        )}
        {canView(PERMISSION_FEATURES.requestsListBulkUpdate) && (
          <Button color="primary" onClick={() => setShowBulkUpdateModal(true)} disabled={!selectedItemsAllowedForUpdate.length}>
            Update
          </Button>
        )}
        {canView(PERMISSION_FEATURES.requestsMerge) && (
          <Button onClick={() => setShowMergeModal(true)} disabled={selectedItemsAllowedForMerge.length < 2}>
            Merge
          </Button>
        )}
        <Button onClick={toggleBulkUpdate}>Cancel</Button>
      </Fragment>
    );
  };

  const readOnlyUserAndRequestorMessage = `Can only update and merge Customer Requests you own or are the creator.
     Can only delete Customer Requests you are the creator.`;
  const editorManagerMessage = 'You can only update created / owned items';

  const messageUserMapper = {
    [READ_ONLY_USER]: readOnlyUserAndRequestorMessage,
    [COLLABORATOR_USER]: readOnlyUserAndRequestorMessage,
    [REQUESTOR_USER]: readOnlyUserAndRequestorMessage,
    [EDITOR_USER]: editorManagerMessage,
    [MANAGER_USER]: editorManagerMessage,
  };

  const message = messageUserMapper[currentUser.role_id] || '';

  return (
    <Fragment>
      <ActionRow>
        <Text>{message}</Text>
        <div>{buttonRow()}</div>
      </ActionRow>
      <ConfirmDialog
        isOpen={isConfirmVisible}
        onCancel={() => setIsConfirmVisible(false)}
        onConfirm={_handleDelete}
        text={_getDeleteConfirmationText()}
        title={`You are about to delete ${selectedItemsAllowedForDelete.length} items`}
        confirmText="Delete"
      />
      {showBulkUpdateModal && (
        <RequestsBulkUpdateModal selectedItems={selectedItemsAllowedForUpdate} onClose={() => setShowBulkUpdateModal(false)} />
      )}
      {showMergeModal && (
        <RequestsMergeModal
          selectedItems={selectedItemsAllowedForMerge}
          isVisible={showMergeModal}
          hideDialog={() => setShowMergeModal(false)}
          disable={() => setShowMergeModal(false)}
        />
      )}
    </Fragment>
  );
};
