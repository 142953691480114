import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isCustomRequestStatusMappingLoading,
  customerRequestStatusMapping,
  isCustomRequestStatusMappingUninitialized,
  updateCustomRequestStatusMappingError,
} from 'store/configuration/selectors';
import {
  getCustomerRequestStatusMappingConfiguration,
  updateCustomerRequestStatusMappingConfiguration,
} from 'store/configuration/thunks';

import { getOrganization } from '../../store/organization';
import useAppNotifications from '../useAppNotifications';

export default () => {
  const dispatch = useDispatch();
  const { has_automatic_request_status_update: enableAutomaticUpdate } = useSelector(getOrganization);

  const configuration = useSelector(state => customerRequestStatusMapping(state)) || {};
  const isLoading = useSelector(state => isCustomRequestStatusMappingLoading(state));
  const updateError = useSelector(state => updateCustomRequestStatusMappingError(state));
  const isUninitialized = useSelector(state => isCustomRequestStatusMappingUninitialized(state));

  const { addError } = useAppNotifications();

  useEffect(() => {
    if (!isLoading && enableAutomaticUpdate && (isUninitialized || configuration.fieldsConfiguration === undefined)) {
      dispatch(getCustomerRequestStatusMappingConfiguration());
    }
  }, [enableAutomaticUpdate]);

  useEffect(() => {
    if (updateError) {
      addError({ id: 'error-saving-customer-request-status-mapping', message: 'Failed to update rules. Please try again.' });
    }
  }, [updateError]);

  return {
    enableAutomaticUpdate,
    mapping: configuration?.mapping,
    updateConfiguration: data => dispatch(updateCustomerRequestStatusMappingConfiguration(data)),
    isLoading,
  };
};
