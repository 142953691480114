import React, { Fragment } from 'react';

import { isEmpty, not, pipe, both, isNil } from 'ramda';

import { TextWrapper, Wrapper } from './styled';

const defaultGetNumberOfTextRowsToDisplayOnGridCell = () => 1;

import TitleWithActions from 'design-system/organisms/TitleWithActions/index';


const isNotNil = pipe(isNil, not);
const isNotEmpty = pipe(isEmpty, not);
const isNotNilOrEmpty = both(isNotNil, isNotEmpty);

const BaseTitle = ({
  children,
  icon = '',
  bold = false,
  indent = false,
  textColor = null,
  fontSize = null,
  getNumberOfTextRowsToDisplayOnGridCell = defaultGetNumberOfTextRowsToDisplayOnGridCell,
  addButtonProps = {},
  openButtonProps = {},
}) => {
  const TitleWrapper = isNotNilOrEmpty(addButtonProps) || isNotNilOrEmpty(openButtonProps) ? TitleWithActions : Fragment;

  return (
    <TitleWrapper
      addButtonProps={addButtonProps}
      openButtonProps={openButtonProps}
      hasAddButton
      hasOpenButton={openButtonProps.hasOpenButton}
      inLineRender
    >
      <Wrapper data-test="grid-row" $indent={indent}>
        {icon}
        <TextWrapper
          $nOfTextRowsToDisplay={getNumberOfTextRowsToDisplayOnGridCell()}
          $bold={bold}
          $fontSize={fontSize}
          $textColor={textColor}
        >
          {children}
        </TextWrapper>
      </Wrapper>
    </TitleWrapper>
  );
};

export default BaseTitle;
