import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import isFunction from 'lodash/isFunction';

const useHandleSortCustomHeader = (params, onClick) => {
  const [sortDirection, setSortDirection] = useState(null);

  const handleChangeSort = e => {
    if (isFunction(params?.progressSort)) {
      params.progressSort(e.shiftKey);
    }
  };

  const handleOnClick = e => {
    if (onClick) {
      e.stopPropagation();
      onClick();
    }
  };

  const handleSortChanged = () => setSortDirection(params?.column?.sort);

  const renderSortIcon = color => {
    if (sortDirection) {
      return <StyledSpan $color={color} className={`ag-icon ag-sort-indicator-icon ag-icon-${sortDirection}`} />;
    }
    return null;
  };

  useEffect(() => {
    params.api.addEventListener('sortChanged', handleSortChanged);

    return () => {
      params.api.removeEventListener('sortChanged', handleSortChanged);
    };
  }, []);

  return {
    handleSortChanged,
    handleChangeSort,
    renderSortIcon,
    // for unit test purposes
    sortDirection,
    handleOnClick,
  };
};

export default useHandleSortCustomHeader;

const StyledSpan = styled.span`
  color: ${({ $color }) => $color};
`;
