export const DEFAULT_TYPE = 'roadmap';
export const PLANNED_LABEL = 'Planned';
export const REPORTED_LABEL = 'Reported';
export const COMPLETED_LABEL = 'Completed';
export const TARGET_LABEL = 'Target';
export const ACTUAL_LABEL = 'Actual';
export const PLANNED_STACKED_LABEL = 'Planned Allocation by';
export const REPORTED_STACKED_LABEL = 'Reported Allocation by';
export const COMPLETED_STACKED_LABEL = 'Completed Allocation by';

export const SUMMARY_TABLE_COLUMNS_PREFERENCES_OPTIONS = [
  { key: 'showHealth', name: 'Health' },
  { key: 'showTargetAllocationPercentage', name: 'Target Allocation %' },
  { key: 'showTargetAllocationAmount', name: 'Target Allocation Amount' },
  { key: 'showCurrentAllocationPercentage', name: 'Planned Allocation %' },
  { key: 'currentAllocationAmount', name: 'Planned Allocation Amount' },
  { key: 'showReportedAllocationPercentage', name: 'Reported Allocation %' },
  { key: 'reportedAllocationAmount', name: 'Reported Allocation Amount' },
  { key: 'showCompletedAllocationPercentage', name: 'Completed Allocation %' },
  { key: 'completedAllocationAmount', name: 'Completed Allocation Amount' },
  { key: 'showDifferencePlannedReported', name: 'Difference' },
  { key: 'showScopeVariance', name: 'Scope Variance' },
];

const TARGET_COLUMNS = ['showTargetAllocationPercentage', 'showTargetAllocationAmount'];
const DEFAULT_COLUMNS = ['showHealth', ...TARGET_COLUMNS];
const PLANNED_COLUMNS = ['showCurrentAllocationPercentage', 'currentAllocationAmount'];
const REPORTED_COLUMNS = ['showReportedAllocationPercentage', 'reportedAllocationAmount'];
const COMPLETED_COLUMNS = ['showCompletedAllocationPercentage', 'completedAllocationAmount'];

export const PLANNED_REPORTED_COLUMNS = [
  ...PLANNED_COLUMNS,
  ...REPORTED_COLUMNS,
  'showDifferencePlannedReported',
  'showScopeVariance',
];

const PLANNED_AND_REPORTED_COLUMNS = ['showDifferencePlannedReported', 'showScopeVariance'];

export const PLANNED_REPORTED_COMPLETED_COLUMNS = [...PLANNED_REPORTED_COLUMNS, ...COMPLETED_COLUMNS];

export const SUMMARY_TABLE_COLUMNS_PREFERENCES_OPTIONS_PLANNED = [...DEFAULT_COLUMNS, ...PLANNED_COLUMNS].map(key =>
  SUMMARY_TABLE_COLUMNS_PREFERENCES_OPTIONS.find(o => o.key === key),
);

export const SUMMARY_TABLE_COLUMNS_PREFERENCES_OPTIONS_REPORTED = [...DEFAULT_COLUMNS, ...REPORTED_COLUMNS].map(key =>
  SUMMARY_TABLE_COLUMNS_PREFERENCES_OPTIONS.find(o => o.key === key),
);

export const SUMMARY_TABLE_COLUMNS_PREFERENCES_OPTIONS_PLANNED_REPORTED = [...DEFAULT_COLUMNS, ...PLANNED_REPORTED_COLUMNS].map(
  key => SUMMARY_TABLE_COLUMNS_PREFERENCES_OPTIONS.find(o => o.key === key),
);

export const SUMMARY_TABLE_COLUMNS_PREFERENCES_OPTIONS_PLANNED_REPORTED_COMPLETED = [
  ...DEFAULT_COLUMNS,
  ...PLANNED_REPORTED_COMPLETED_COLUMNS,
].map(key => SUMMARY_TABLE_COLUMNS_PREFERENCES_OPTIONS.find(o => o.key === key));

export const getAllocationSummaryTableColumnPreferencesOptions = hasCompletedAllocation => {
  return [
    ...DEFAULT_COLUMNS,
    ...PLANNED_COLUMNS,
    ...REPORTED_COLUMNS,
    ...PLANNED_AND_REPORTED_COLUMNS,
    ...(hasCompletedAllocation ? COMPLETED_COLUMNS : []),
  ].map(key => SUMMARY_TABLE_COLUMNS_PREFERENCES_OPTIONS.find(o => o.key === key));
};
