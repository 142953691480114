const FIRST = 'FIRST';
const NEXT = 'NEXT';
const PREVIOUS = 'PREVIOUS';
const LAST = 'LAST';

const PAGE_NAVIGATION_DIRECTIONS = {
  FIRST,
  NEXT,
  PREVIOUS,
  LAST,
};

const DEFAULT_PAGINATION_INFORMATION = { current: 1, next: null, previous: null };

const DEFAULT_PAGINATION_LIMIT = 100;

export { DEFAULT_PAGINATION_INFORMATION, DEFAULT_PAGINATION_LIMIT, PAGE_NAVIGATION_DIRECTIONS };
