import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getChartsDateInterval,
  getProjectedChartOptionSelected,
  getStackedByChartOptionSelected,
  getStackedByChartOptions,
  getTimeBucketChartOptionSelected,
  getTimeBucketChartOptions,
} from 'store/roadmapVersions/selectors';
import {
  setChartsDateInterval,
  setProjectedChartOptionSelected,
  setStackedByChartOptionSelected,
  setTimeBucketChartOptionSelected,
} from 'store/roadmapVersions';
import {
  getDefaultBucketByOption,
  getDefaultStackedByOption,
  PROJECTED_OPTIONS,
} from 'store/roadmapVersions/constants/defaultChartOptions';

export const STACKED_BY_DROPDOWN = 'stackedByDropdown';
export const PROJECTED_DROPDOWN = 'projectedDropdown';
export const TIME_BUCKET_DROPDOWN = 'timeBucketDropdown';

const useScenariosChartsOptions = () => {
  const dispatch = useDispatch();

  const stackedByOptionSelected = useSelector(getStackedByChartOptionSelected);
  const projectedOptionSelected = useSelector(getProjectedChartOptionSelected);
  const timeBucketOptionSelected = useSelector(getTimeBucketChartOptionSelected);
  const dateInterval = useSelector(getChartsDateInterval);
  const stackedByOptions = useSelector(getStackedByChartOptions);
  const timeBucketOptions = useSelector(getTimeBucketChartOptions);

  const defaultStackedByOption = getDefaultStackedByOption(stackedByOptions);
  const defaultBucketOption = getDefaultBucketByOption(timeBucketOptions);

  const onDropdownChange = useCallback(
    dropdown => option => {
      switch (dropdown) {
        case STACKED_BY_DROPDOWN:
          return dispatch(setStackedByChartOptionSelected(option || defaultStackedByOption));
        case PROJECTED_DROPDOWN:
          return dispatch(setProjectedChartOptionSelected(option));
        case TIME_BUCKET_DROPDOWN:
          return dispatch(setTimeBucketChartOptionSelected(option || defaultBucketOption));
        default:
          break;
      }
    },
    [],
  );

  const setDateInterval = useCallback(newDateInterval => {
    dispatch(setChartsDateInterval(newDateInterval));
  }, []);

  return {
    onDropdownChange,
    projectedOptionSelected,
    stackedByOptionSelected,
    timeBucketOptionSelected,
    dateInterval,
    setDateInterval,
    projectedOptions: PROJECTED_OPTIONS,
    stackedByOptions,
    timeBucketOptions,
  };
};

export default useScenariosChartsOptions;
