import { __, defaultTo, divide, equals, head, isNil, pipe, prop, sort, subtract } from 'ramda';

const REPORTED_DURATION_WEEKS = 'reported_duration_in_weeks';
const COMPLETED_DURATION_WEEKS = 'completed_duration_in_weeks';

const defaultToEmptyArray = defaultTo([]);
const isEqualsZero = equals(0);
const subtractOne = subtract(__, 1);
const defaultToZero = defaultTo(0);

const sortArrayByLatestUpdatedAtValue = pipe(
  defaultToEmptyArray,
  sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)),
);

/**
 * @function calcScopeVariance
 *
 * Calculates the scope variance based on a provided pair of planned and reported values.
 *
 * @param {Number} planned
 * @param {Number} reported
 * @returns {Number}
 */
export const calcScopeVariance = (planned, reported) => {
  const areParamsInvalid = isEqualsZero(planned) || isNil(planned) || isNil(reported);

  if (areParamsInvalid) {
    return 0;
  }

  // 4 decimal since it is a percentage, *100 it will become 2 decimal
  return +parseFloat(subtractOne(divide(reported, planned))).toFixed(4);
};

/**
 * @function getProjectAllocationValues
 *
 * Gets the allocation values for a given projects.
 * - Planned Allocation in weeks
 * - Reported Allocation in weeks
 *
 * If project has progress values for an integration we use that if not use only the latest value
 *
 * @param {Object} project
 * @returns {Object}
 */
export const getProjectAllocationValues = project => {
  const sortedProgressValues = sortArrayByLatestUpdatedAtValue(project.currentProgresses);

  const progressValuesForIntegration = sortedProgressValues.filter(value => value.org_integration_id);

  const progressValuesForProject = progressValuesForIntegration.length
    ? head(progressValuesForIntegration)
    : head(sortedProgressValues);

  const reportedAllocationInWeeks = defaultToZero(prop(REPORTED_DURATION_WEEKS, progressValuesForProject));
  const completedAllocationInWeeks = defaultToZero(prop(COMPLETED_DURATION_WEEKS, progressValuesForProject));

  const scopeVariance = calcScopeVariance(project.planned_scope_in_weeks, reportedAllocationInWeeks);

  return {
    plannedAllocation: project.planned_scope_in_weeks,
    reportedAllocation: reportedAllocationInWeeks,
    completedAllocation: completedAllocationInWeeks,
    scopeVariance,
  };
};
