import React from 'react';
import styled from 'styled-components';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Autocomplete from 'design-system/atoms/Autocomplete/index';
import InlineAutoCompleteInput from 'design-system/atoms/InlineAutoCompleteInput/index';

import theme from 'design-system/theme';
import { brandColor } from 'design-system/themes/default';

import { INCLUDE_ALL_OPTION, EXCLUDE_UNCOMMITTED_OPTION, EXCLUDE_AND_DISPLAY_UNCOMMITTED_OPTION } from 'constants/projects';

const AUTOCOMPLETE_ID = 'exclude-below-the-line-toggle';
const AUTOCOMPLETE_TEST_ID = AUTOCOMPLETE_ID;

const INCLUDE_ALL_TITLE = 'Include all items';
const EXCLUDE_UNCOMMITTED_TITLE = 'Exclude Below the line';
const EXCLUDE_AND_DISPLAY_UNCOMMITTED_TITLE = 'Exclude Below the line & Display All';

const showAllOption = { value: INCLUDE_ALL_OPTION, title: INCLUDE_ALL_TITLE };
const excludeBelowOption = { value: EXCLUDE_UNCOMMITTED_OPTION, title: EXCLUDE_UNCOMMITTED_TITLE };
const excludeBelowAndDisplayOption = {
  value: EXCLUDE_AND_DISPLAY_UNCOMMITTED_OPTION,
  title: EXCLUDE_AND_DISPLAY_UNCOMMITTED_TITLE,
};
const SUGGESTIONS = [showAllOption, excludeBelowOption, excludeBelowAndDisplayOption];

const optionTitleMap = {
  [INCLUDE_ALL_OPTION]: INCLUDE_ALL_TITLE,
  [EXCLUDE_UNCOMMITTED_OPTION]: EXCLUDE_UNCOMMITTED_TITLE,
  [EXCLUDE_AND_DISPLAY_UNCOMMITTED_OPTION]: EXCLUDE_AND_DISPLAY_UNCOMMITTED_TITLE,
};

const AboveTheLineViewingSelector = ({ onValueChange, selectedValue }) => {
  return (
    <Wrapper id={AUTOCOMPLETE_ID}>
      <Autocomplete
        suggestions={SUGGESTIONS}
        value={optionTitleMap[selectedValue] ?? INCLUDE_ALL_TITLE}
        onValueChange={onValueChange}
        inputProps={{
          style: {
            fontSize: theme.typography.fontSize,
            color: brandColor,
          },
          containerStyles: {
            maxWidth: 240,
          },
          'data-testid': AUTOCOMPLETE_TEST_ID,
        }}
        icon={<StyledArrowDownIcon />}
        iconStyle={{ padding: 0, width: 'auto' }}
        inputComponent={InlineAutoCompleteInput}
        highlightOnlyFullMatch
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;

  input {
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  }
`;

const StyledArrowDownIcon = styled(KeyboardArrowDownIcon)`
  cursor: pointer;

  && {
    fill: ${({ theme }) => theme.palette.newLayout.text.siroccoGrey};
    font-size: 20px;
  }
`;

export default AboveTheLineViewingSelector;
