import parseClipboardDataToArray from './parseClipboardDataToArray';

function getSelectionText() {
  let text = '';

  if (window.getSelection) {
    text = window.getSelection().toString();
  }
  // else if (document.selection && document.selection.type != 'Control') {
  //   text = document.selection.createRange().text;
  // }
  return text;
}

export default function (e) {
  e.preventDefault();

  const { onPaste } = this.props;

  const clipboardData = e.clipboardData.getData('Text');

  const clipboardDataHandled = parseClipboardDataToArray(clipboardData);

  if ((clipboardDataHandled.length === 1 && clipboardDataHandled[0].length === 1) || this.props.node.data.id) {
    const selectedText = getSelectionText();

    const clipboardDataWithoutWhiteSpaces = clipboardData.replace('\n', ' ').replace('\t', ' ').trim();

    this.state.value = this.state.value || '';
    const value = selectedText
      ? this.state.value.replace(selectedText, clipboardDataWithoutWhiteSpaces)
      : this.state.value.slice(0, e.target.selectionStart) +
        clipboardDataWithoutWhiteSpaces +
        this.state.value.slice(e.target.selectionStart);

    this.setState({
      value,
    });
  } else if (onPaste) {
    onPaste(clipboardDataHandled);
  } else if (process.env.NODE_ENV === 'development') {
    console.trace('onPaste prop is undefined');
  }
}
