import React from 'react';
import styled from 'styled-components';

import FluidTextField from 'design-system/organisms/FluidTextField/index';
import FluidRemirrorWYSIWYGEditor from 'design-system/organisms/FluidRemirrorWYSIWYGEditor/index';

import LoadableUsersAutocomplete from 'containers/LoadableUsersAutocomplete';
import FluidMetadataAutocompleteField from 'containers/FluidMetadataAutocompleteField';
import useSystemFields from 'hooks/useSystemFields';

const placeholder = 'Write a name';
const MetricForm = ({ formValues, objectTitle, ownerOptions, handleFormValueChange }) => {
  const [getSystemFieldName] = useSystemFields();

  const selectedOwnerName = ownerOptions?.find(opt => opt.id === formValues?.owner)?.name;

  return (
    <>
      <FluidTextField
        key="name"
        label="Name"
        value={formValues?.name || objectTitle}
        onChange={val => handleFormValueChange('name', val)}
        placeholder={placeholder}
        readingPlaceholder={placeholder}
        format="row"
      />
      <FluidMetadataAutocompleteField
        name="Owner"
        key="users"
        value={selectedOwnerName}
        label={getSystemFieldName('owner')}
        metadata="users"
        onChange={val => handleFormValueChange('owner', val)}
        autocompleteComponent={LoadableUsersAutocomplete}
        autocompleteProps={{
          suggestions: ownerOptions,
          placeholder: `Select an ${getSystemFieldName('owner')}`,
        }}
        format="row"
      />
      <StyledFluidRemirrorWYSIWYGEditor
        name="details"
        key="details"
        label="Details"
        value={formValues?.details}
        content={formValues?.details}
        onChange={val => handleFormValueChange('details', val)}
        placeholder="Write some details..."
        disableToolbar
        smallInput
        format="row"
      />
    </>
  );
};

export default MetricForm;

const StyledFluidRemirrorWYSIWYGEditor = styled(FluidRemirrorWYSIWYGEditor)`
  && .remirror-is-empty:before {
    font-style: normal;
  }
`;
