import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import withTheme from '@material-ui/core/styles/withTheme';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

import DragonIconButton from 'design-system/atoms/DragonIconButton/index';
import ViewName from 'design-system/molecules/ViewName/index';


import history from 'store/utils/history';

const DASHBOARD_HOME_PATH = '/dashboard/home';
const ALL_VIEWS = 'All Views';

const DragonViewHeader = ({ currentPath, selectedView, viewOwner, isFavoriteView, handleFavoriteUserView }) => {
  const showBackButton = useMemo(() => !DASHBOARD_HOME_PATH.includes(currentPath), [currentPath]);

  return (
    <Wrapper>
      {showBackButton && (
        <>
          <IconContainer>
            <DragonIconButton
              id="go-to-dashboard-home-button"
              type="transparent"
              icon={<ArrowLeft />}
              onClick={() => history.push(DASHBOARD_HOME_PATH)}
            />
          </IconContainer>

          <ViewLinkContainer>
            <Link isLink={showBackButton} onClick={() => history.push(DASHBOARD_HOME_PATH)}>
              {ALL_VIEWS}
            </Link>
            {selectedView?.name && (
              <>
                <StyledSpan>/</StyledSpan>
                <ViewName
                  selectedView={selectedView}
                  viewOwner={viewOwner}
                  isFavoriteView={isFavoriteView}
                  handleFavoriteUserView={handleFavoriteUserView}
                  maxWidth="35vw"
                />
              </>
            )}
          </ViewLinkContainer>
        </>
      )}
    </Wrapper>
  );
};

DragonViewHeader.propTypes = {
  selectedView: PropTypes.object,
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.fontSizeMediumSmall}px;
`;

const IconContainer = styled.div`
  margin-right: 10px;
  font-size: ${({ theme }) => theme.typography.fontSizeSmallLarge}px;

  svg {
    color: ${({ theme }) => theme.palette.newLayout.background.primary};
  }
`;

const ViewLinkContainer = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const Link = styled.div`
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.fontSizeSmallLarge}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  min-width: 65px;
  ${({ isLink, theme }) => isLink && `color: ${theme.palette.primary.main};`}
`;

const StyledSpan = styled.span`
  margin: 0 8px;
`;

export default withTheme()(DragonViewHeader);
