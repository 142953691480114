import getSystemFieldName from 'utils/getSystemFieldName';
import { DEFAULT_UNDEFINED_COLOR } from 'constants/charts';
import { UNDEFINED_LABEL } from 'constants/common';
import { INTEGRATIONS_KEYS } from 'constants/integrations';
import isEmpty from 'lodash/isEmpty';
import compileFiltersBody from 'utils/filters/compileFiltersBody';
import { defaultTo, isNil } from 'ramda';
import { CHILDREN_FILTERS, DASHBOARDS_PAGE } from 'constants/filters';

const exist = Boolean;

const _formatIntegrationIssueTypeHeader = integrations => {
  const hasJira = integrations.find(integration => integration.integrationType === INTEGRATIONS_KEYS.JIRA);

  if (hasJira) {
    return 'Jira Issue Type';
  }

  return 'Integration Issue Type';
};

export const getDisplayColumnsOptions = (systemFields, integrations, organization, customFields, isDodActive) => {
  const hasIntegrations = exist(integrations) && integrations.length > 0;
  const hasHierarchy = organization.has_hierarchy;
  const hasKeyResults2 = organization.has_key_results_2;
  const hasProducts2 = organization.has_products_2;

  const COLUMNS_KEYS = [
    'status_color',
    isDodActive ? 'roadmapCorpTitle' : '',
    'roadmapTitle',
    'progress',
    hasIntegrations ? 'integration' : '',
    hasIntegrations ? 'integrationIssueType' : '',
    'ownerName',
    hasHierarchy ? 'parent' : '',
    'categoryTitle',
    'themeTitle',
    'timeframeTitle',
    isDodActive ? 'objectiveCorpTitle' : '',
    'objectiveTitle',
    'priorityTitle',
    'product1Title',
    hasProducts2 ? 'product2Title' : '',
    'keyResult1Title',
    hasKeyResults2 ? 'keyResult2Title' : '',
    'timeEstimate',
    'storyPoint',
    'issueCounts',
    'updatedByName',
    'tags',
    'customers',
    'planningStage',
    'phaseTitle',
    'status_summary',
    'predictedEndDate',
    'deadline',
    'estimated_start_date',
    'links',
    'details',
    'planned_moar',
    'benefit1',
    'benefit2',
    'benefit3',
    'cost1',
    'priorityScore',
    'riceScore',
    'reach_score',
    'impact_score',
    'confidence_score',
    'effort_score',
    'sum',
  ].filter(key => exist(key));

  const COLUMNS_LABELS = {
    status_color: getSystemFieldName('status_color', systemFields),
    roadmapCorpTitle: getSystemFieldName('roadmapCorp', systemFields),
    roadmapTitle: getSystemFieldName('roadmap', systemFields),
    progress: getSystemFieldName('progress', systemFields),
    integration: 'Integration Key',
    ownerName: getSystemFieldName('owner', systemFields),
    parent: 'Parent',
    categoryTitle: getSystemFieldName('category', systemFields),
    themeTitle: getSystemFieldName('theme', systemFields),
    timeframeTitle: getSystemFieldName('timeframe', systemFields),
    objectiveCorpTitle: getSystemFieldName('objectiveCorp', systemFields),
    objectiveTitle: getSystemFieldName('objective', systemFields),
    priorityTitle: getSystemFieldName('priority', systemFields),
    product1Title: getSystemFieldName('product1', systemFields),
    product2Title: getSystemFieldName('product2', systemFields),
    keyResult1Title: getSystemFieldName('keyResult1', systemFields),
    keyResult2Title: getSystemFieldName('keyResult2', systemFields),
    integrationIssueType: _formatIntegrationIssueTypeHeader(integrations),
    timeEstimate: 'Time Estimate',
    storyPoint: 'Reported Points',
    issueCounts: 'Issue Count',
    updatedByName: 'Updated By',
    tags: getSystemFieldName('tag'),
    customers: getSystemFieldName('customer'),
    planningStage: 'Planning Stage',
    phaseTitle: getSystemFieldName('phase', systemFields),
    status_summary: 'Status Summary',
    predictedEndDate: 'Predicted end date',
    deadline: 'Target end date',
    estimated_start_date: 'Target start date',
    links: 'Links',
    details: 'Details',
    planned_moar: 'Planned MoAR',
    benefit1: 'Strategic Benefit (0-5)',
    benefit2: 'Urgency (0-5)',
    benefit3: 'Frequency (0-5)',
    cost1: 'Cost (0-5)',
    priorityScore: 'Total Score',
    riceScore: 'RICE Score',
    reach_score: 'Reach Score',
    impact_score: 'Impact Score',
    confidence_score: 'Confidence Score',
    effort_score: 'Effort',
    sum: 'Total Estimate',
  };

  const result = COLUMNS_KEYS.map(key => ({ key, label: COLUMNS_LABELS[key] }));

  if (!isEmpty(customFields)) {
    customFields.forEach(field => {
      result.push({ key: field.key, label: field.title });
    });
  }

  return result;
};

export const getDisplayColumnsDefaultOptions = systemFields => {
  return [
    {
      key: 'status_color',
      label: getSystemFieldName('status_color', systemFields),
    },
    {
      key: 'roadmapTitle',
      label: getSystemFieldName('roadmap', systemFields),
    },
    {
      key: 'progress',
      label: getSystemFieldName('progress', systemFields),
    },
    {
      key: 'ownerName',
      label: getSystemFieldName('owner', systemFields),
    },
  ];
};

export const sortChartDataDescending = data => data.sort((a, b) => b.value - a.value);

export const formatDataByMetadataForPieChart = (data = {}, metadata = {}) => {
  let formatted = Object.entries(data).reduce((formatted, next) => {
    const [id, value] = next;

    if (value) {
      formatted.push({
        id,
        value: Array.isArray(value) ? value.length : value,
        color: metadata[id]?.color || DEFAULT_UNDEFINED_COLOR,
        label: metadata[id]?.title || UNDEFINED_LABEL,
      });
    }

    return formatted;
  }, []);

  formatted = sortChartDataDescending(formatted);

  return {
    ids: formatted.map(val => val.id),
    values: formatted.map(val => val.value),
    colors: formatted.map(val => val.color),
    labels: formatted.map(val => val.label),
  };
};

/**
 * Build projects filter for selected field in dashboard chart
 * @param {Object} baseFilters
 * @param {String} selectedField
 * @param {String} selectedFieldValue
 * @param {String} displayLayer
 * @returns {Object}
 */
export const buildFilterForTimelineProjects = (baseFilters, selectedField, selectedFieldValue, displayLayer, searchUuid) => {
  const hasValidSearchUuid = !isNil(searchUuid) && !isEmpty(searchUuid);

  const baseFilter = {
    ...defaultTo({}, baseFilters),
    children: CHILDREN_FILTERS.allChildren,
    fields: {
      ...defaultTo({}, baseFilters?.fields),
      op: {
        ...defaultTo({}, baseFilters?.fields?.op),
      },
    },
    ...(hasValidSearchUuid ? { searchUuid } : {}),
  };

  const hasValidField = !isNil(selectedField) && !isEmpty(selectedField);

  if (hasValidField) {
    baseFilter.fields[selectedField] = selectedFieldValue ? [selectedFieldValue] : null;
    baseFilter.fields.op[selectedField] = selectedFieldValue ? 'in' : 'isEmpty';
  }

  return compileFiltersBody(baseFilter, {}, true, DASHBOARDS_PAGE, displayLayer);
};
