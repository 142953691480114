import { PERMISSION_RESOURCES } from '@dragonboat/permissions';

const GROUP_BY_CUSTOMER_SEGMENT = 'customerSegment';
const GROUP_BY_CUSTOMER_TYPE = 'customerType';

const visibleDefault = () => true;

const GROUP_BY_OPTIONS = [
  { key: 'customer_id', title: 'customer', visible: visibleDefault },
  { key: GROUP_BY_CUSTOMER_SEGMENT, title: 'Customer segment', visible: visibleDefault },
  { key: GROUP_BY_CUSTOMER_TYPE, title: 'Customer type', visible: visibleDefault },
  { key: 'status', title: 'Status', visible: visibleDefault },
  { key: 'priority', title: 'priority', visible: visibleDefault },
  { key: 'tag_id', title: 'tag', visible: visibleDefault },
  { key: 'roadmap_id', title: 'roadmap', visible: visibleDefault },
  { key: 'product_1_id', title: 'product1', visible: visibleDefault },
  { key: 'product_2_id', title: 'product2', visible: canView => canView(PERMISSION_RESOURCES.product2) },
  { key: 'owner_id', title: 'Owner', visible: visibleDefault },
];

const FIELD_KEY_TO_FILTER_KEY_MAPPER = {
  customer_id: 'customer_ids',
  tag_id: 'tag_ids',
  customerSegment: 'customer_segment',
  customerType: 'customer_type',
};

const GROUP_BY_CUSTOM_FIELDS_TYPES_ALLOWED = ['Dropdown', 'Multi-select Dropdown'];

export {
  GROUP_BY_CUSTOMER_SEGMENT,
  GROUP_BY_CUSTOMER_TYPE,
  GROUP_BY_OPTIONS,
  FIELD_KEY_TO_FILTER_KEY_MAPPER,
  GROUP_BY_CUSTOM_FIELDS_TYPES_ALLOWED,
};
