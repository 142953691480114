import React from 'react';
import { INTEGRATIONS_ICON_MAPPER } from 'src/constants/integrations';

export default props => {
  if (!props.hasMultipleIntegrations) {
    // When org does not allow multiple integrations just show miro icon in the integration tab
    const MiroIcon = INTEGRATIONS_ICON_MAPPER.miro;

    return <MiroIcon />;
  }


  // TODO We can add something here like link to linked miro widget or something similar
  return (<></>);
};
