import React from 'react';
import { Map } from 'immutable';
import axios from 'axios';
import withStyles from '@material-ui/core/styles/withStyles';
import { toast } from 'react-toastify';

const toastStyles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  text: {
    margin: theme.spacing.unit,
    lineHeight: 1.5,
  },
  link: {
    paddingLeft: 50,
    paddingRight: 50,
  },
});

const ToastUndo = withStyles(toastStyles)(props => (
  /* eslint-disable */
  (<div>
    <h3 className={props.classes.text}>
      {props.text}
      <br />
      <a onClick={props.onClick}>Click to undo</a>
    </h3>
  </div>)
  /* eslint-enable */
));

export default (toastText, ACTION_TYPE, store, endpoint, dispatchFn) => {
  return async (dispatch, getState) => {
    const callUndo = () => {
      let ids;

      if (Map.isMap(getState()[store])) {
        ids = getState()[store].toJS().lastActionIds;
      } else {
        ids = getState()[store].lastActionIds;
      }

      const payload = axios
        .delete(endpoint, {
          data: { ids },
        })
        .then(res => res.data);

      if (!dispatchFn) {
        return dispatch({
          type: ACTION_TYPE,
          payload,
        });
      }
      return dispatchFn(payload);
    };

    toast(
      () => {
        return <ToastUndo text={toastText} onClick={callUndo} />;
      },
      {
        autoClose: 10000,
        toastId: 'UNDO_TOAST',
      },
    );
  };
};
