import { defaultTo, has, pathSatisfies, propEq } from 'ramda';

import theme from 'design-system/theme';

import { KEY_SEPARATOR, SELF_TIMELINE_GROUPS } from './constants';

const defaultToNullString = defaultTo('null');

const defaultToMercuyColor = defaultTo(theme.palette.border.mercury);

const hasGroups = has('groups');

const hasItems = has('items');

const isLevel = level => propEq('level', level);

const isSelfTimelineGroup = pathSatisfies(type => SELF_TIMELINE_GROUPS.includes(type), ['meta', 'type']);

const createRowOrderKey = (...args) => args.map(defaultToNullString).join(KEY_SEPARATOR);

const convertKeyIntoArray = key => key?.split(KEY_SEPARATOR) || [];

const isUndefinedGroup = orderSegment => ['null', 'undefined'].includes(orderSegment);

const findGroupsLevel1ByGroupIds = (internalData, groupIds) =>
  internalData?.find(level1 => `${defaultToNullString(level1.id)}` === groupIds[0]);

const findGroupsLevel2ByGroupIds = (internalData, groupIds) =>
  findGroupsLevel1ByGroupIds(internalData, groupIds)?.groups?.find(level2 => `${defaultToNullString(level2.id)}` === groupIds[1]);

const findGroupsLevel3ByGroupIdsAndGroupingIndex = (internalData, groupIds) =>
  findGroupsLevel2ByGroupIds(internalData, groupIds)?.groupings?.[groupIds[2]]?.groupRow;

const findGroupsLevel3ByGroupIds = (internalData, groupIds) =>
  findGroupsLevel2ByGroupIds(internalData, groupIds)?.groups?.find(level3 => `${defaultToNullString(level3.id)}` === groupIds[2]);

const findGroupsLevel2ByGroupIndexes = (internalData, indexes) => internalData?.[indexes[0]]?.groups?.[indexes[1]];

const isItemCenterInsideGroup = itemCenter => group => itemCenter >= group.info.left && itemCenter <= group.info.right;

const isItemIncludedInAnother = (obj1Info, obj2Info, delta = 0) =>
  obj1Info.left - delta >= obj2Info.left && obj1Info.left - delta <= obj2Info.right && obj1Info.right + delta <= obj2Info.right;

export {
  defaultToNullString,
  defaultToMercuyColor,
  hasGroups,
  hasItems,
  isLevel,
  isSelfTimelineGroup,
  createRowOrderKey,
  convertKeyIntoArray,
  isUndefinedGroup,
  findGroupsLevel1ByGroupIds,
  findGroupsLevel2ByGroupIds,
  findGroupsLevel3ByGroupIds,
  findGroupsLevel3ByGroupIdsAndGroupingIndex,
  findGroupsLevel2ByGroupIndexes,
  isItemCenterInsideGroup,
  isItemIncludedInAnother,
};
