import { useCallback } from 'react';
import { pluck, pipe, defaultTo } from 'ramda';

import { PROGRESS_MANUAL } from 'store/projects/constants';
import {
  ADDITIONAL_ROADMAPS,
  ADDITIONAL_PRODUCTS,
  ADDITIONAL_PRODUCTS_2,
  ADDITIONAL_OBJECTIVES,
  ADDITIONAL_KEY_RESULTS,
  ADDITIONAL_KEY_RESULTS_2,
  ADDITIONAL_TIMEFRAMES,
  ADDITIONAL_TIMEFRAMES_2,
  ADDITIONAL_THEMES,
  ADDITIONAL_CATEGORIES,
  ADDITIONAL_TEAMS,
  ADDITIONAL_TEAMS_2,
} from 'constants/common';

const PARENT = 'parent';
const OWNER_NAME = 'ownerName';
const PROGRESS = 'progress';
const TIMEFRAME_1 = 'timeframeTitle';
const HEALTH = 'status_color';

const defaultToEmptyArray = defaultTo([]);
const getIds = pipe(defaultToEmptyArray, pluck('id'));

/**
 * @function useProcessUpdateProjectData
 *
 * Hook to abstract some data process logic before calling the update project action
 *
 * @param  {Function} loadParentProjects callback to load parents
 * @return {Object}
 */
const useProcessUpdateProjectData = (parents, hasMultiLevelMetadata) => {
  const processUpdateData = useCallback(
    (updatedData, updatedField) => {
      switch (updatedField) {
        case PARENT:
          if (updatedData.parent) {
            // Find the parent Project with the exact same name
            const parentProject = parents.find(pp => pp.title === updatedData.parent) || parents[0];

            return {
              ...updatedData,
              parent_id: parentProject?.id,
            };
          }

          return {
            ...updatedData,
            parent_id: null,
          };
        case OWNER_NAME:
          return {
            ...updatedData,
            ...(!updatedData.ownerName ? { owner_id: null } : {}),
          };
        case PROGRESS:
          return {
            ...updatedData,
            progress_type: PROGRESS_MANUAL,
          };
        case TIMEFRAME_1:
          return {
            ...updatedData,
            ...(hasMultiLevelMetadata && !updatedData.timeframe2Title ? { timeframe2Title: null } : {}),
          };
        case HEALTH:
          return {
            ...updatedData,
            status_color_auto: false,
          };
        case ADDITIONAL_ROADMAPS:
        case ADDITIONAL_PRODUCTS:
        case ADDITIONAL_PRODUCTS_2:
        case ADDITIONAL_OBJECTIVES:
        case ADDITIONAL_KEY_RESULTS:
        case ADDITIONAL_KEY_RESULTS_2:
        case ADDITIONAL_TIMEFRAMES:
        case ADDITIONAL_TIMEFRAMES_2:
        case ADDITIONAL_THEMES:
        case ADDITIONAL_CATEGORIES:
        case ADDITIONAL_TEAMS:
        case ADDITIONAL_TEAMS_2:
          return {
            ...updatedData,
            [updatedField]: getIds(updatedData?.[updatedField]),
          };
        default:
          return updatedData;
      }
    },
    [parents],
  );

  return { processUpdateData };
};

export default useProcessUpdateProjectData;
