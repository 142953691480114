import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Question from 'design-system/atoms/DragonIcons/Question';

const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

/*
   Help Icon that sits behind Resource Center icon (bottom right)
   This is a failsafe if the Resource Center icon doesn't render
*/
const DragonIconHelpButton = ({ variant, type, onClick, ...otherProps }) => {
  const props = {
    fill: type === 'fill',
    transparent: type === 'transparent',
    variant,
    ...otherProps,
  };

  const onShowHelp = () => {
    const iframe = document.getElementById('launcher');
    const innerDocument = iframe?.contentDocument || iframe?.contentWindow?.document;

    if (!innerDocument) return;

    const button = innerDocument.getElementsByTagName('button');

    if (button.length) button[0].click();
  };

  return (
    <ButtonWrapper {...props}>
      <Button onClick={onShowHelp} data-testid="dragon-help-icon-button">
        <Question />
      </Button>
    </ButtonWrapper>
  );
};

const Button = styled.button`
  & {
    width: 100%;
    height: 100%;

    border: none;
    border-radius: 50%;

    padding: 8px;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;

      margin-top: -2px !important; // we need this because <Question/> overrides it
      margin-left: -4px;
    }
  }
`;

const ButtonWrapper = styled.div`
  & {
    position: fixed;
    z-index: ${({ theme }) => theme.zIndex.high};
    width: 50px;
    height: 50px;
    right: 10px;
    bottom: 9px;

    border: none;
    border-radius: 50%;

    padding: 0;
    box-sizing: border-box;

    &:hover {
      background: ${({ theme }) => theme.palette.newLayout.background.lightestDisable};
    }

    ${({ fill, theme, variant }) =>
      fill &&
      `
      ${Button} {
        background: ${theme.palette.newLayout.background[variant]};
      }

      svg path {
        fill: ${theme.palette.newLayout.background.white};
      }

      &:hover {
        background: ${theme.palette.newLayout.background[`lightest${capitalize(variant)}`]};
      }
    `}

    ${({ transparent, theme, variant }) =>
      transparent &&
      `
      width: 50px;
      height: 50px;
      background: transparent;

      padding: 0;

      ${Button} {
        background: transparent;
      }

      ${
        variant &&
        `
        svg path {
          fill: ${theme.palette.newLayout.background[variant]};
        }

        &:hover {
          ${Button} {
            background: ${theme.palette.newLayout.background[`lightest${capitalize(variant)}`]};
          }
        }
      `
      }
    `}
  }
`;

DragonIconHelpButton.propTypes = {
  variant: PropTypes.oneOf(['primary', 'disable', 'secondary']),
  type: PropTypes.oneOf(['fill', 'transparent', 'default']).isRequired,
  onClick: PropTypes.func,
};

export default DragonIconHelpButton;
