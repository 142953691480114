import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import Tabs from 'design-system/organisms/Tabs';
import { SearchRow } from 'design-system/atoms/MultiFilterSearchRow/styled';
import theme, { spacing } from 'design-system/theme';
import SearchableDndList from 'design-system/molecules/SearchableDndList';

const tabStyles = css`
  && {
    color: ${({ isSelectedTab, theme }) =>
      isSelectedTab ? theme.palette.text.primary : theme.palette.newLayout.background.disable};
    font-size: ${({ theme }) => theme.typography.fontSize}px;
    min-height: 30px;
    margin-right: ${spacing(2)}px;
  }
`;

const selectedTabBorderStyles = {
  background: theme.palette.text.primary,
  height: '2px',
};

const SearchableDndListWithTabs = props => {
  const { tabs, items, renderCustomHeaderOptions, checkIfTabIsDisabled } = props;
  const [selectedTab, setSelectedTab] = useState(tabs?.[0]?.label || '');

  const _renderHeaderOptions = () => {
    return (
      <HeaderContainer>
        {renderCustomHeaderOptions && renderCustomHeaderOptions()}
        {tabs.length && (
          <HeaderTabs
            tabs={tabs}
            selectedTab={selectedTab}
            handleTabChange={(e, val) => setSelectedTab(val)}
            tabStyles={tabStyles}
            TabIndicatorProps={{ style: selectedTabBorderStyles }}
            checkIfTabIsDisabled={checkIfTabIsDisabled}
          />
        )}
      </HeaderContainer>
    );
  };

  const itemsForSelectedTab = useMemo(() => {
    if (!tabs || !tabs.length) {
      return items;
    }

    const tabSpec = tabs.find(t => t.label === selectedTab);

    if (!tabSpec || !tabSpec.filterFn) {
      return items;
    }

    return tabSpec.filterFn(items);
  }, [items, tabs, selectedTab]);

  return <SearchableDndList {...props} items={itemsForSelectedTab} renderCustomHeaderOptions={_renderHeaderOptions} />;
};

export default SearchableDndListWithTabs;

const HeaderContainer = styled.div`
  ${SearchRow} {
    border: none;
    padding: 0 ${spacing(1.875)}px;

    input {
      font-size: ${({ theme }) => theme.typography.fontSize}px;

      &::placeholder {
        color: ${({ theme }) => theme.palette.text.quickSilver};
      }
    }
  }
`;

const HeaderTabs = styled(Tabs)`
  padding: 0 ${spacing(2)}px;

  && {
    min-height: unset;
  }
`;
