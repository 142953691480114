import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { omit, over, lensProp } from 'ramda';
import TextField from '@material-ui/core/TextField';
import TooltipOnOverflow from 'design-system/molecules/TooltipOnOverflow';
import theme from 'design-system/theme';
import TextDeprecated from '../TextDeprecated';

const TEXT_WIDTH_OFFSET = 10;

const textStyles = css`
  font-weight: ${theme.typography.fontWeightMedium};
`;

export default ({ value, label, inputRef, defaultValue, ...props }) => {
  const [width, setWidth] = useState(`${value?.length}ch`);

  const textRef = useRef(null);

  const inputProps = over(lensProp('InputProps'), omit(['style']), omit(['style'], props));

  useEffect(() => {
    if (textRef.current) {
      const textWidth = textRef.current?.getBoundingClientRect()?.width;

      if (textWidth) setWidth(`${textWidth + TEXT_WIDTH_OFFSET}px`);
    }
  }, [value]);

  return (
    <Wrapper id={props.id}>
      <TextDeprecated size="small" textStyles={textStyles} color={theme.palette.newLayout.text.grey}>
        {label}
      </TextDeprecated>
      <HiddenText ref={textRef} style={props.style}>
        {value}
      </HiddenText>
      <TextFieldContainer width={width} style={props.containerStyles}>
        <TooltipOnOverflow text={value} elementWithOverflow="input" containerWidth={width}>
          <StyledTextField
            type="text"
            value={value}
            inputRef={inputRef}
            isDefaultValue={value === defaultValue}
            autoSize
            style={props.style}
            {...inputProps}
          />
        </TooltipOnOverflow>
      </TextFieldContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-right: 25px;
`;

const TextFieldContainer = styled.div`
  width: ${({ width }) => width};
  min-width: 95px;
  max-width: 190px;
`;

const StyledTextField = styled(TextField)`
  font-size: ${({ theme }) => theme.typography.fontSize}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  width: 100%;

  > div {
    font-size: inherit;
    font-weight: inherit;

    ${({ isDefaultValue, theme }) =>
      isDefaultValue &&
      css`
        color: ${theme.palette.newLayout.background.lightDisable};
      `}

    &:before,
    &:after {
      bottom: -3px;
      right: -20px;
    }
  }

  && input {
    padding: 0;
    cursor: pointer;
  }
`;

const HiddenText = styled.p`
  position: absolute;
  top: -999px;
  left: -999px;
  visibility: hidden;
`;
