import axios from 'axios';

import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { injectStripe } from 'react-stripe-elements';

import TextDeprecated from 'design-system/atoms/TextDeprecated/index';


import formatDate from 'utils/dates/formatDate';

import { getPlan, getNumEditors, getRecurrency, numberWithCommas } from './billingUtils';

class SubscriptionDetails extends Component {
  constructor(props) {
    super(props);

    this.getPlan = getPlan.bind(this);
    this.getNumEditors = getNumEditors.bind(this);
    this.getRecurrency = getRecurrency.bind(this);
    this.numberWithCommas = numberWithCommas.bind(this);
  }

  checkout = async () => {
    const res = await axios.put('/api/billing/session/payment-method');

    await this.props.stripe.redirectToCheckout({
      sessionId: res.data.id,
    });
  };

  render() {
    return (
      <Wrapper>
        <TextDeprecated>
          {this.getPlan()} plan (billed {this.getRecurrency()})
        </TextDeprecated>
        <TextDeprecated>{this.getNumEditors()}</TextDeprecated>
        {this.props.billingInfo ? (
          <>
            {this.props.billingInfo.last_payment_amount && (
              <TextDeprecated>
                Last payment: $ {this.numberWithCommas(this.props.billingInfo.last_payment_amount.toFixed(2))}
              </TextDeprecated>
            )}
            {this.props.billingInfo.last_payment_date && (
              <TextDeprecated>Last payment date: {formatDate(this.props.billingInfo.last_payment_date)}</TextDeprecated>
            )}
            {this.props.billingInfo.paymentMethod && this.props.billingInfo.paymentMethod.last4 && (
              <Fragment>
                <br />

                <TextDeprecated>Payment card number: **** {this.props.billingInfo.paymentMethod.last4}</TextDeprecated>
                <TextDeprecated>
                  {'Payment card expiration: '}
                  {this.props.billingInfo.paymentMethod.expYear}
                  {' / '}
                  {this.props.billingInfo.paymentMethod.expMonth.toString().padStart(2, '0')}
                </TextDeprecated>
              </Fragment>
            )}
            {this.props.billingInfo.last_payment_amount && (
              <ContactInfo>
                {'Update your payment card '}
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    this.checkout();
                  }}
                >
                  here
                </a>
                .{' (You will be redirected to Stripe to complete this update.)'}
              </ContactInfo>
            )}
          </>
        ) : null}
        <ContactInfo breakwords>
          {'Contact us for other billing related questions '}
          <a href="https://dragonboat.zendesk.com/hc/en-us/requests/new" target="_blank" rel="noreferrer">
            clicking here
          </a>
          .
        </ContactInfo>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
`;

const ContactInfo = styled(TextDeprecated).attrs(props => ({
  ...props,
}))`
  &&&& {
    margin-top: 30px;
  }
`;

export default injectStripe(SubscriptionDetails);
