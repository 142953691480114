import React from 'react';
import styled from 'styled-components';

import { withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

export const Lower = styled(props => <Typography color="textSecondary" {...props} />)`
  &&&& {
    font-size: 14px;
  }
`;

export const DateLower = styled(Lower)`
  &&&& {
    text-align: left;
    display: block;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ProgressLower = withTheme()(styled(Lower)`
  &&&& {
    display: inline;
    font-weight: 500;
  }
`);

export const TotalLower = withTheme()(styled(ProgressLower)`
  &&&& {
    display: inline;
    color: ${({ theme }) => theme.palette.text.green};
  }
`);
