import React, { useCallback, useEffect, useState } from 'react';
import { omit, either, isEmpty, isNil, pipe, not } from 'ramda';
import { useDebouncedCallback } from 'use-debounce';
import TextField from '@material-ui/core/TextField';
import styled, { css } from 'styled-components';

import { AUTOCOMPLETE_FROM_SERVER_DELAY } from 'constants';
import { BET_LAYER, IDEA_LAYER, INITIATIVE_LAYER } from 'store/projects/constants';
import useProjectsSearch from 'hooks/useProjectsSearch';

import useProjectsDropdown from './useProjectsDropdown';
import { TYPE_TO_SEARCH_PLACEHOLDER } from 'constants/common';

// Fields that are added by the grid and should be removed
const GRID_FIELDS = ['parentGroups', 'pathTitle', 'path', 'groupData'];

const isNilOrEmpty = either(isNil, isEmpty);
const isNotNilOrEmpty = pipe(isNilOrEmpty, not);

const Container = styled.div`
  position: relative;
`;

const smallInputStyles = css`
  font-size: 0.8125rem;
  padding: 0.8125rem 0.4rem;
`;

const StyledTextField = styled(TextField)`
  width: 100%;

  &&& input {
    ${({ $small }) => $small && smallInputStyles};
  }
`;

const initialEmptyData = {
  [IDEA_LAYER]: [],
  [INITIATIVE_LAYER]: [],
  [BET_LAYER]: [],
};
const emptyObject = {};
const ProjectsDropdown = ({
  displayLayer,
  searchLayers,
  placeholder = TYPE_TO_SEARCH_PLACEHOLDER,
  customDataLoader,
  componentId,
  onProjectSelect,
  onProjectCreation,
  onClickOutside,
  nullOption,
  smallInput,
  disabled,
  searchFilterOptions = emptyObject,
  dropdownFooterComponent,
  className,
  openOnInit,
  initialValue = '',
}) => {
  const [value, setValue] = useState(initialValue);

  const decoratedOnProjectSelect = useCallback(
    (project, ...args) => {
      setValue(project?.title || '');

      if (onProjectSelect) {
        const selectedProject = project?.id ? omit(GRID_FIELDS, project) : project;

        onProjectSelect(selectedProject, ...args);
      }
    },
    [onProjectSelect],
  );

  const { setData, setDisplayLayer, isLoading, setIsLoading, treeIsOpen, setTreeIsOpen, shouldShowCompleted, inputRef } =
    useProjectsDropdown(componentId, {
      onProjectSelect: decoratedOnProjectSelect,
      onProjectCreation,
      onClickOutside,
      nullOption,
      footerComponent: dropdownFooterComponent,
      openOnInit,
    });

  const { searchProjectsByTitle } = useProjectsSearch(searchLayers);

  const defaultDataLoader = useCallback(
    async searchValue => {
      return searchProjectsByTitle(searchValue, {
        ...searchFilterOptions,
        showCompleted: shouldShowCompleted,
      });
    },
    [searchProjectsByTitle, searchFilterOptions, shouldShowCompleted],
  );

  const dataLoader = useCallback(
    async searchValue => {
      if (customDataLoader) {
        return customDataLoader(searchValue, { defaultDataLoader });
      }
      return defaultDataLoader(searchValue);
    },
    [customDataLoader, defaultDataLoader],
  );

  const loadData = useCallback(
    async (searchValue = '') => {
      if (isNilOrEmpty(searchValue) || isLoading) {
        return;
      }
      setTreeIsOpen(false);

      setIsLoading(true);

      const searchResults = await dataLoader(searchValue);

      const processedResults = searchResults.reduce((allLayers, searchedProject) => {
        const projectLayer = searchedProject.layer;

        return {
          ...allLayers,
          [projectLayer]: [...allLayers[projectLayer], searchedProject],
        };
      }, initialEmptyData);

      setData(processedResults);

      setIsLoading(false);

      setTreeIsOpen(true);
    },
    [dataLoader, isLoading],
  );

  const [debouncedLoadData] = useDebouncedCallback(loadData, AUTOCOMPLETE_FROM_SERVER_DELAY);

  const handleOnChange = useCallback(
    event => {
      setValue(event.target.value);

      debouncedLoadData(event.target.value);
    },
    [debouncedLoadData],
  );

  const handleOnFocus = useCallback(() => {
    if (!treeIsOpen && isNotNilOrEmpty(value)) {
      debouncedLoadData(value);
    }
  }, [treeIsOpen, debouncedLoadData, value]);

  useEffect(() => {
    setDisplayLayer(displayLayer);
  }, [displayLayer]);

  useEffect(() => {
    if (treeIsOpen && isNotNilOrEmpty(value)) {
      debouncedLoadData(value);
    }
  }, [shouldShowCompleted, searchFilterOptions]);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <Container className={className}>
      <StyledTextField
        disabled={disabled}
        inputRef={inputRef}
        value={value}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        placeholder={placeholder}
        $small={smallInput}
      />
    </Container>
  );
};

export default ProjectsDropdown;
