import React from 'react';
import { connect } from 'react-redux';
import GoogleLogin from 'react-google-login';
import styled from 'styled-components';

import GoogleIcon from 'design-system/atoms/DragonIcons/Google';

class GoogleLoginButton extends React.Component {
  render() {
    if (this.props.zendeskLoaded && window.zE) {
      /* eslint-disable */
      zE(() => {
        zE.hide();
      });
      /* eslint-enable */
    }

    const responseGoogle = response => {
      if (response.tokenId) {
        const json = JSON.stringify({
          ssoType: 'google',
          token: response.tokenId,
        });

        window.parent.postMessage(json, '*');
      }
    };

    return (
      <Wrapper>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}
          render={renderProps => (
            <GoogleButton onClick={renderProps.onClick} disabled={renderProps.disabled}>
              <GoogleIcon />
              <span>Sign in with Google</span>
            </GoogleButton>
          )}
          buttonText="Sign in with Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  const {
    app: { zendeskLoaded },
  } = state;

  return {
    zendeskLoaded,
  };
};

const mapDispatchToProps = (dispatch, props) => ({});

const Wrapper = styled.div`
  height: 44px;
`;

const GoogleButton = styled.button`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-weight: ${props => props.theme.typography.fontWeightRegular}!important;
  font-size: ${props => props.theme.typography.fontSize}px;
  line-height: ${props => props.theme.typography.lineHeightSmallLarge}px;
  color: ${props => props.theme.palette.text.black};
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  align-items: center;
  background: white;
  padding: 0 15px;
  cursor: pointer;

  span {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(GoogleLoginButton);
