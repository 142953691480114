import React, { useCallback, useState } from 'react';

import useAppNotifications from 'hooks/useAppNotifications';

const INTEGRATION_TYPE = 'azuredevops';

export default Component => {
  return props => {
    const { orgIntegrationId, handleTestConnection, removeUserIntegration, userCanUpdateOrgIntegration } = props;

    const [isTestingAzureConnection, setIsTestingAzureConnection] = useState(false);

    const { addNotification } = useAppNotifications();

    const handleTestAzureConnection = useCallback(() => {
      setIsTestingAzureConnection(true);

      handleTestConnection(INTEGRATION_TYPE, orgIntegrationId)
        .then(({ value }) => {
          if (!value?.data) {
            removeUserIntegration();
          } else {
            addNotification({ message: 'Azure Devops is configured correctly.' });
          }
        })
        .catch(() => {
          removeUserIntegration();
        })
        .finally(() => {
          setIsTestingAzureConnection(false);
        });
    }, [orgIntegrationId, handleTestConnection, removeUserIntegration]);

    return (
      <Component
        isTestingConnection={isTestingAzureConnection}
        onTestConnectionClick={handleTestAzureConnection}
        userCanUpdateOrgIntegration={userCanUpdateOrgIntegration}
      />
    );
  };
};
