import { isEmpty, keys } from 'ramda';

const DATE_FIELDS = ['start_date', 'end_date'];

export default function (updateObject) {
  if ('title' in updateObject) {
    updateObject.title = updateObject.title.trim();

    if (!updateObject.title) {
      delete updateObject.title;
    }
  }

  if ('progress' in updateObject) {
    updateObject.progress_type = updateObject.progress_type || 'manual';
  }

  if (keys(updateObject).some(key => DATE_FIELDS.includes(key))) {
    DATE_FIELDS.forEach(field => {
      if (field in updateObject) {
        updateObject[field] = isEmpty(updateObject[field]) ? null : updateObject[field];
      }
    });
  }

  return updateObject;
}