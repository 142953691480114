import React from 'react';
import styled from 'styled-components';

import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import ButtonIcon from 'design-system/molecules/ButtonIcon';
import { spacing } from 'design-system/theme';
import theme from 'design-system/dragonboatTheme';
import Text from 'design-system/atoms/Text';

import useGroupConditionQueryBuilder from 'features/DBQL/AdvancedQueryBuilder/hooks/useGroupConditionQueryBuilder';
import Condition from 'features/DBQL/AdvancedQueryBuilder/components/Condition';
import OperatorSelector from 'features/DBQL/AdvancedQueryBuilder/components/OperatorSelector';

import { AND } from 'features/DBQL/AdvancedQueryBuilder/helpers/constants';

const TITLE_WITH_CONDITIONS = 'of the following are true...';
const TITLE_WITHOUT_CONDITIONS = 'Add new conditions to the group';

const emptyArray = [];

const getHeaderTitle = (conditions = [], groupOperator = AND) => {
  if (conditions?.length) {
    return `${groupOperator === AND ? 'All' : 'Any'} ${TITLE_WITH_CONDITIONS}`;
  }
  return TITLE_WITHOUT_CONDITIONS;
};

const ConditionGroup = ({ index, condition, allFields, getFieldOptions, onRemove, onChange, onUpdateGroupOperator }) => {
  const { groupOperator, conditions = emptyArray } = condition;
  const {
    onChangeCondition,
    addNewCondition,
    onRemoveCondition,
    onUpdateGroupOperator: onUpdateChildGroupOperator,
    groupOperator: childGroupOperator,
  } = useGroupConditionQueryBuilder({
    index,
    allFields,
    onChange,
    conditions,
  });

  return (
    <Wrapper>
      <Operator>
        <OperatorSelector
          index={index}
          operator={groupOperator}
          canChangeOperator={index === 1}
          onChangeOperator={onUpdateGroupOperator}
        />
      </Operator>
      <ConditionsWrapper>
        <GroupHeader>
          <StyledHeaderText as="h5">{getHeaderTitle(conditions, childGroupOperator)}</StyledHeaderText>
          <Actions>
            <ButtonIcon onClick={addNewCondition} title="Add condition" small>
              <AddIcon fontSize="small" />
            </ButtonIcon>
            <ButtonIcon onClick={() => onRemove(index)} title="Remove group" small>
              <CloseIcon fontSize="small" />
            </ButtonIcon>
          </Actions>
        </GroupHeader>
        {conditions.map((condition, index) => (
          <Condition
            index={index}
            condition={condition}
            allFields={allFields}
            getFieldOptions={getFieldOptions}
            onChange={onChangeCondition}
            onRemove={onRemoveCondition}
            onUpdateGroupOperator={onUpdateChildGroupOperator}
          />
        ))}
      </ConditionsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
`;
const Operator = styled.div``;

const ConditionsWrapper = styled.div`
  background: ${theme.palette.background.secondary};
  padding: ${spacing()}px 0 ${spacing()}px ${spacing()}px;
  width: 100%;
`;
const GroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${spacing()}px;
  padding-right: ${spacing()}px;
`;
const Actions = styled.div`
  &&&& svg {
    fill: ${({ theme }) => theme.palette.newLayout.text.grey};
  }
`;
const StyledHeaderText = styled(Text)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.fontSizeRem}rem;
`;

export default ConditionGroup;
