import React, { useMemo } from 'react';
import styled from 'styled-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import Card from 'design-system/molecules/Card/index';

import WidgetGrid from 'design-system/organisms/WidgetGrid';
import { TitleCellRenderer } from 'design-system/molecules/AgGridReact-New/cellRenderers';
import { stringComparator } from 'design-system/molecules/AgGridReact-New/comparators';
import CenteredLoading from 'features/PortfolioOverview/components/CenteredLoading';
import EmptyMessage from 'features/MissionControlHome/components/EmptyMessage';
import useUpdateGridWidgetHeight from 'features/MissionControlHome/widgets/useUpdateGridWidgetHeight';
import { getWidgetHeight } from 'features/PortfolioOverview/widgets/helpers';
import useMissionControlCreatedRequests from './useMissionControlCreatedRequests';
import { requestStatusColumnDef } from 'features/MissionControlHome/widgets/common/commonColumns';

const GRID_MAX_ROWS = 5;
const GRID_MIN_HEIGHT = 100;

const RequestsList = ({ id, updateHeightOnLayout = () => {} }) => {
  const {
    requests,
    dataLength,
    isLoading,
    title,
    entitySystemFieldName,
    openCustomerRequestsDrawer,
    goToRequestsPage,
    getRowId,
  } = useMissionControlCreatedRequests();

  const hasPagination = dataLength > GRID_MAX_ROWS;

  const { updateWidgetHeight, gridHeight } = useUpdateGridWidgetHeight({
    widgetId: id,
    getWidgetHeight,
    updateHeightOnLayout,
    hasPagination,
    maxItems: GRID_MAX_ROWS,
  });

  const titleColumnDef = useMemo(
    () => ({
      field: 'title',
      headerName: 'Title',
      suppressMovable: true,
      suppressMenu: true,
      comparator: stringComparator,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: params => ({
        innerRenderer: TitleCellRenderer,
        suppressCount: true,
        suppressDoubleClickExpand: true,
      }),
      rowDrag: false,
      editable: false,
      flex: 1,
      resizable: true,
    }),
    [openCustomerRequestsDrawer],
  );

  return (
    <StyledCard
      title={title}
      actions={[{ icon: <OpenInNewIcon />, onClick: goToRequestsPage, testId: 'open-created-requests' }]}
      onClickTitle={goToRequestsPage}
    >
      {isLoading ? <CenteredLoading /> : null}
      {!isLoading && !dataLength ? <EmptyMessage entity={entitySystemFieldName} /> : null}
      {!isLoading && !!dataLength ? (
        <WidgetGrid
          hasPagination={hasPagination}
          getRowId={getRowId}
          data={requests}
          columnDefs={[titleColumnDef, requestStatusColumnDef]}
          pageSize={GRID_MAX_ROWS}
          numberOfRecords={dataLength}
          setVisibleItems={i => updateWidgetHeight(i)}
          gridHeight={gridHeight || GRID_MIN_HEIGHT}
        />
      ) : null}
    </StyledCard>
  );
};

export default RequestsList;

const StyledCard = styled(Card)`
  overflow: hidden;
`;
