import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import getSystemFieldName from 'utils/getSystemFieldName';
import { materialColorsAlt } from 'design-system/themes/default';
import moment from 'moment';
import theme from 'design-system/theme';
import { getChartTitle } from './utils';

const BurnDownChartHOC = Component => {
  return props => {
    const { data, loading, startDate, endDate, titleType } = props;

    const systemFields = useSelector(state => state.organization.organization.system_fields_name);
    const [openTimeline, setOpenTimeline] = useState();

    const registerOpenTimelineCallback = useCallback(
      openTimeline => {
        setOpenTimeline(() => openTimeline);
      },
      [setOpenTimeline],
    );

    const dates = useMemo(() => {
      return data.map(d => moment(d.date).toDate());
    }, [data]);

    const labels = useMemo(
      () => [moment(startDate).toDate(), ...dates, moment(endDate).toDate(), moment(endDate).add(1, 'week').toDate()],
      [dates, startDate, endDate],
    );

    const remainingProgresses = useMemo(
      () =>
        data.map(ideaLog => {
          const completedPercentage = Number((ideaLog.issuesClosed + (0.5 * ideaLog.issuesInProgress)) / ideaLog.issuesTotal);

          if (Number.isNaN(completedPercentage)) {
            return null;
          }

          const remainingProgress = 1 - completedPercentage;

          return remainingProgress < 0 ? 0 : (remainingProgress * 100).toFixed(2);
        }),
      [data],
    );

    const chartData = useMemo(() => {
      if (!remainingProgresses.length) {
        return { labels, datasets: [] };
      }

      return {
        labels,
        datasets: [
          {
            label: 'Remaining progress',
            data: [null, ...remainingProgresses, null],
            borderColor: materialColorsAlt.lightBlue,
            backgroundColor: [materialColorsAlt.lightBlue],
            fill: false,
            borderWidth: 1,
            tension: 0.1,
            format: '%',
          },
        ],
        annotations: {
          closedIssuesTrendline: {
            type: 'line',
            borderColor: theme.palette.newLayout.text.grey,
            borderWidth: 1,
            xMin: labels[0],
            xMax: moment(endDate).toDate(),
            yMin: 100,
            yMax: 0,
          },
        },
      };
    }, [remainingProgresses, labels, endDate]);

    const chartTitle = loading ? 'Loading...' : getChartTitle(titleType, systemFields);

    return (
      <Component
        {...props}
        title={chartTitle}
        data={chartData}
        loading={loading}
        onChartClick={openTimeline}
        registerOpenTimelineCallback={registerOpenTimelineCallback}
        timelineLightboxTitleEntity={{ key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) }}
        timelineLightboxGroupBy={{ key: 'roadmap', title: getSystemFieldName('roadmap', systemFields) }}
      />
    );
  };
};

export default BurnDownChartHOC;
