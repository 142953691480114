import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import AdvancedSearchFooter from 'design-system/molecules/AdvancedSearchFooter/index';


const APPLY = 'Apply';
const CANCEL = 'Cancel';

const CustomAdvancedSearchFooter = ({ onApply, onCancel }) => {
  const _renderFooterButtons = () => (
    <>
      <Button onClick={onApply} color="primary">
        {APPLY}
      </Button>
      <Button onClick={onCancel}>{CANCEL}</Button>
    </>
  );

  return <AdvancedSearchFooter rightContent={_renderFooterButtons()} />;
};

CustomAdvancedSearchFooter.propTypes = {
  onApply: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CustomAdvancedSearchFooter;
