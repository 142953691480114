import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addUserIntegration as addUserIntegrationAction } from '../store/actions';
import { getAllUserIntegrationsForOrgIntegration, getUserIntegrations, selectIsAddingUserIntegration } from '../store/selectors';

const useUserIntegrations = (integrationType, orgIntegrationId) => {
  const dispatch = useDispatch();

  const isAddingUserIntegration = useSelector(selectIsAddingUserIntegration);

  const allUserIntegrations = useSelector(getUserIntegrations);

  const allOrgUserIntegrations = useSelector(getAllUserIntegrationsForOrgIntegration(orgIntegrationId));

  const userIntegration = useMemo(() => {
    return allUserIntegrations.find(userIntegration => userIntegration.org_integration_id === orgIntegrationId);
  }, [allUserIntegrations]);

  const addUserIntegration = useCallback(
    (data, showSuccessToast) => dispatch(addUserIntegrationAction(integrationType, orgIntegrationId, data, showSuccessToast)),
    [integrationType, orgIntegrationId],
  );

  return {
    allOrgUserIntegrations,
    userIntegration,
    addUserIntegration,
    isAddingUserIntegration,
  };
};

export default useUserIntegrations;
