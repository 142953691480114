import theme from 'design-system/theme';

export const STACKED_CHART_ROW_HEIGHT = 32;
export const STACKED_CHART_MIN_HEIGHT = 260;

export const DASHBOARD_CHART_MIN_HEIGHT = 320;
export const DASHBOARD_CHART_MAX_HEIGHT = 522;

export const DEFAULT_UNDEFINED_COLOR = theme.palette.background.lightGrey;
export const DEFAULT_PIE_LABEL_COLOR = theme.palette.text.darkerGrey;

const MIN_PERCENTAGE_PIE_SLICE = 4.5;

export const BAR_CHART_LABEL_MAX_NUM_CHARS = 15;

export const calculatePiePercentage = data => {
  const { dataset, dataIndex } = data;

  const sum = dataset.data.reduce((a, b) => a + b, 0);

  const dataPoint = dataset.data[dataIndex];

  return ((dataPoint / sum) * 100).toFixed(1);
};

export const calculateShowValueOnPie = percentage => {
  return percentage >= MIN_PERCENTAGE_PIE_SLICE;
};

export const DEFAULT_PIE_CHART_DATALABEL_OPTIONS = {
  color: 'white',
  display: data => {
    const percentage = calculatePiePercentage(data);

    return calculateShowValueOnPie(percentage);
  },
  formatter: value => (typeof value === 'number' ? value.toLocaleString() : value),
};

export const DEFAULT_PIE_CHART_LAYOUT_OPTIONS = {
  layout: { padding: { top: 30, bottom: 30, left: 20, right: 20 } },
};

export const HIDE_LEGEND_OPTION = { display: false };
export const HIDE_GRID_OPTION = { display: false };

export const DEFAULT_LIMIT_TO_HIDE_DATALABEL = 0.15;

export const LIMIT_TO_HIDE_DATALABEL = {
  horizontalStackedChart: 0.1,
  verticalStackedChart: 0.15,
};
