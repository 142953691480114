import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import AuthWrapper from 'components/AuthWrapper';
import AuthMainButton from 'components/AuthMainButton';

import { getDefaultRoute } from 'utils';
import { signupWithGoogle } from 'store/login';

class Login extends React.Component {
  render() {
    const { isAuthenticated, googleToken, currentUser, organization } = this.props;

    if (!googleToken) {
      return <Redirect to="/" />;
    }

    const renderPage = () => {
      return (
        <AuthWrapper>
          <div className={this.props.classes.successText}>
            <Typography variant="h6">User not found</Typography>
            <Typography className={this.props.classes.successTextItem} variant="body2">
              {`You do not have an account with dragonboat.
                Want to send an email to`}
              <br />
              the owner of {organization.title && organization.title}
              {!organization.title && organization.slug}?
            </Typography>
          </div>
          <AuthMainButton
            className={this.props.classes.signupButton}
            variant="contained"
            color="secondary"
            onClick={() => this.props.signupWithGoogle(this.props)}
          >
            Yes
          </AuthMainButton>
          <AuthMainButton
            className={this.props.classes.signupButton}
            variant="contained"
            color="primary"
            onClick={() => this.props.history.push('/signup')}
          >
            Create new workspace
          </AuthMainButton>
          <CancelButton href="/">Cancel</CancelButton>
        </AuthWrapper>
      );
    };

    if (isAuthenticated) {
      return <Redirect to={getDefaultRoute(currentUser)} />;
    }

    return renderPage();
  }
}

const mapStateToProps = state => {
  return {
    googleToken: state.login.googleToken,
    currentUser: state.login.currentUser,
    organization: state.login.organization,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  signupWithGoogle: props => {
    return dispatch(signupWithGoogle(props.googleToken, props));
  },
});

const styles = theme => ({
  successText: {
    marginTop: 30,
  },
  successTextItem: {
    width: 270,
    marginTop: 30,
  },
  signupButton: {
    marginTop: 10,
    width: 250,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));

const CancelButton = styled.a`
  margin-top: 14px;
  cursor: pointer;
  color: #999999 !important;
  font-size: 18px;

  &:hover {
    text-decoration: none;
  }
`;
