/* eslint-disable no-nested-ternary */
import omit from 'lodash/omit';

import { getColor } from 'components/ganttCommon';
import { getUserName, getProgressNumber } from 'utils';
import formatDateForGantt from 'utils/dates/formatDateForGantt';

import checkProjectTasksOverlap from '../helpers/checkProjectTasksOverlap';

/**
 * Function that parse task to the gantt format
 *
 * @param {*} project project object
 * @param {*} task task object
 * @param {*} parentId id of the parent
 * @param {*} collapseGanttBars collapse gantt bars setting
 * @param {*} teams all teams from the store
 */
const parseGanttTask = (project, task, parentId, { selectedColorBy, collapseGanttBars, showOwnerAllocation }, teams = []) => {
  let color = 'rgba(176, 190, 197, 0.8)';

  if (selectedColorBy && selectedColorBy.key === 'health') {
    color = getColor(task, null, 'health', color);
  }

  const _getType = () => {
    if (task.duration === 0) return 'milestone';

    return task.subtasks && task.subtasks.length ? 'project' : 'task';
  };

  return {
    ...omit(task, ['end_date']), // should remove the end_date to calculated on gantt by given duration
    id: `task-${task.id}`,
    entityId: task.id,
    dbType: 'task',
    type: _getType(),
    parent: parentId,
    text: `- ${showOwnerAllocation ? `[${task.ownerAllocation}%]` : ''} ${task.title}`,
    shortText: `${showOwnerAllocation ? `[${task.ownerAllocation}%]` : ''} ${task.title}`,
    color:
      collapseGanttBars && task.owner && teams.some(t => t.id === task.owner.team_id)
        ? teams.find(t => t.id === task.owner.team_id).color
        : color,
    textColor: '#fff',
    ownerName: getUserName(task.owner),
    start_date: formatDateForGantt(task.start_date),
    duration: task.duration,
    progressOriginal: task.progress,
    progressGridValue: getProgressNumber(task.progress),
    progressPercent: getProgressNumber(task.progress),
    roadmap_id: project.roadmap_id,
    roadmapTitle: '',
    objective_id: project.objective_id,
    phase_id: project.phase_id,
    team_id: task.owner && task.owner.team_id,
    numStaff: task.ownerAllocation / 100,
    status_color: task.status_color || '',
    planningStage: '',
    open: false,
    $overlap: checkProjectTasksOverlap(collapseGanttBars, task, project.tasks),
    $committed: project.committed,
  };
};

export default parseGanttTask;
