import { keys, not } from 'ramda';

const mergeFieldsLayoutWithDefaultFields = (savedFieldsLayout, defaultFieldsLayout) => {
  if (not(savedFieldsLayout)) {
    return defaultFieldsLayout;
  }

  return keys(defaultFieldsLayout).reduce((carry, fieldKey) => {
    const savedField = savedFieldsLayout[fieldKey];
    const defaultField = defaultFieldsLayout[fieldKey];

    if (!savedField) {
      return {
        ...carry,
        // TODO: will have duplicate index, not break the order but should be evaluated
        [fieldKey]: defaultField,
      };
    }

    return {
      ...carry,
      [fieldKey]: savedField,
    };
  }, {});
};

export default mergeFieldsLayoutWithDefaultFields;
