import { defaultTo } from 'ramda';
import calculateTextLines from '../calculateTextLines';

const defaultEmptyString = defaultTo('');

const MAX_NUM_LINES = 1;
const FONT_SIZE = 11;
const MAX_WIDTH_FOR_LABEL = 120;

export default (
  labelValue,
  maxNumChars,
  fontSize = FONT_SIZE,
  maxWidthForLabel = MAX_WIDTH_FOR_LABEL,
  maxNumLines = MAX_NUM_LINES,
) => {
  const label = defaultEmptyString(labelValue);
  const numLines = calculateTextLines(label, maxWidthForLabel, fontSize);

  if (numLines > maxNumLines) {
    return `${label.slice(0, maxNumChars)}...`;
  }

  return label;
};
