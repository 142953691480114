// External dependencies
import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';

// Dragonboat depencies
import { removeDuplicates as uniq, getUserName } from 'utils';

import { getNormalizedSkills } from 'store/skills/selectors';
import { getNormalizedTeams } from 'store/teams/selectors';
import { defaultTo } from 'ramda';

export const _getMetadata = createSelector(
  state => getNormalizedSkills(state),
  state => getNormalizedTeams(state),
  (skills, teams) => {
    return { skills, teams };
  },
);

const emptyArray = [];
const defaultToEmptyArray = defaultTo(emptyArray);

const emptyObject = [];
const defaultToEmptyObject = defaultTo(emptyObject);

export const getState = createSelector(
  state => state.users,
  state => uniq(defaultToEmptyArray(state?.rows)),
);

export const makeGetUsers = () =>
  createCachedSelector(
    getState,
    (_, showInactive) => showInactive,
    _getMetadata,
    (state, showInactive, metadata) => {
      const _getTeam = u => (metadata.teams ? metadata.teams[u.team.id] : null);
      const _getSkill = u => (metadata.skills ? metadata.skills[u.skill.id] : null);

      const users = state.map(u => ({
        ...u,
        name: getUserName(u),
        ...(u.skill ? { skill: _getSkill(u) } : {}),
        ...(u.team ? { team: _getTeam(u) } : {}),
        teamTitle: u.team && _getTeam(u) && _getTeam(u).title,
        skillTitle: u.skill && _getSkill(u) && _getSkill(u).title,
      }));

      return showInactive ? users : users.filter(user => user.status !== 'Inactive');
    },
  )((_, showInactive) => `users:${showInactive}`);

export const getUsers = makeGetUsers();

export const getUserById = createSelector([getState, getUsers, (_, id) => id], (_, state, id) => {
  return state.find(u => u.id === +id);
});

export const getUsersWithName = createSelector(getState, getUsers, (state, users) => users.map(getUserName));

export const makeGetNormalizedUsers = () => {
  return createSelector(
    state => getState(state),
    state =>
      state.reduce((objs, obj) => {
        objs[obj.id] = { ...obj, name: getUserName(obj) };
        return objs;
      }, {}),
  );
};

export const getNormalizedUsers = makeGetNormalizedUsers();

export const getSearchText = createSelector(
  state => state.users,
  state => state.searchText,
);

export const getFilters = createSelector(
  state => state.users,
  state => defaultToEmptyObject(state.filters),
);
