import { useCallback, useMemo, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import {
  createCustomField,
  updateCustomFieldById,
  fetchCustomFields,
  removeUnsavedCustomFields,
  addCustomFieldWithoutSave,
  deleteCustomField,
} from 'store/customFields';
import { getOrganization } from 'store/organization';
import { getCustomerRequestsCustomFieldsForCustomerRequestsTable } from 'store/customFields/selectors';
import { CUSTOM_FIELD_TYPES, CUSTOMER_REQUEST_CUSTOM_FIELD } from 'store/customFields/constants';
import getFormattedFormulaDescription from 'store/customFields/helpers/getFormattedFormulaDescription';

import useFormulaOptions from 'hooks/useFormulaOptions';

import getSystemFieldName from 'utils/getSystemFieldName';
import { CUSTOM_FIELD_KEY } from '../helpers/constants';

const useCustomFields = () => {
  const [openFormula, setOpenFormula] = useState(null);
  const [showArchivedItems, setShowArchivedItems] = useState(true);

  const dispatch = useDispatch();

  const organization = useSelector(getOrganization);

  const customFields = useSelector(state => getCustomerRequestsCustomFieldsForCustomerRequestsTable(state, showArchivedItems));

  const systemFields = organization.system_fields_name;
  const customFieldName = `${getSystemFieldName(CUSTOM_FIELD_KEY, systemFields)}`;

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          fetchCustomFields,
          createCustomField,
          updateCustomFieldById,
          deleteCustomField,
          removeUnsavedCustomFields,
          addCustomFieldWithoutSave,
        },
        dispatch,
      ),
    [dispatch],
  );

  const formulaOptions = useFormulaOptions(CUSTOM_FIELD_TYPES.CUSTOMER_REQUESTS);

  const updateFormula = useCallback(
    formula => {
      dispatch(updateCustomFieldById(openFormula.id, { description: getFormattedFormulaDescription(formula, formulaOptions) }));
    },
    [openFormula],
  );

  const onLinkClickFormula = data => {
    setOpenFormula({ id: data.id, value: data.description_text, title: data.title, organization_id: data.organization_id });
  };

  const handleAddRow = useCallback(() => {
    const newRow = { association_type: CUSTOMER_REQUEST_CUSTOM_FIELD };

    dispatch(addCustomFieldWithoutSave(newRow));
  }, [addCustomFieldWithoutSave]);

  const toggleArchivedItems = () => {
    setShowArchivedItems(!showArchivedItems);
  };

  return {
    customFields: customFields.filter(({ key }) => !(key || '').includes('hidden#')),
    customFieldName,
    handleAddRow,
    ...boundActionCreators,
    openFormula,
    setOpenFormula,
    updateFormula,
    formulaOptions,
    onLinkClickFormula,
    showArchivedItems,
    toggleArchivedItems,
  };
};

export default useCustomFields;
