import axios from 'axios';
import getStore from 'store/store';

import {
  FETCH_ROADMAPS,
  UPDATE_ROADMAP,
  CREATE_ROADMAP,
  CREATE_ROADMAP_FULFILLED,
  UPDATE_ROADMAP_FULFILLED,
  DELETE_ROADMAP_FULFILLED,
  BULK_DELETE_ROADMAPS_FULFILLED,
  REMOVE_UNSAVED_ROADMAPS,
  ADD_ROADMAP_WITHOUT_SAVE,
  UPDATE_ROADMAP_ROW_ORDER,
  UPDATE_PRODUCT_ROW_ORDER,
  ADD_PRODUCT_WITHOUT_SAVE,
  REMOVE_UNSAVED_ROADMAPS_AND_PRODUCTS,
  CREATE_PRODUCT,
  CREATE_PRODUCT_FULFILLED,
  DELETE_PRODUCT_FULFILLED,
  UPDATE_PRODUCT_ID_FULFILLED,
  OPEN_ROADMAP_LIGHTBOX,
  CLOSE_ROADMAP_LIGHTBOX,
  UPDATE_ROADMAP_USER_ROLES,
  GET_ROADMAP_USER_ROLES,
} from './types';
import fetchMetadataAction from '../utils/factory/fetchMetadataAction';

import generateRealtimeUpdateAction from 'utils/generateRealtimeUpdateAction';
import { CREATE, UPDATE, DELETE } from 'store/constants/realtimeUpdateTypes';
import { METADATA_LEVELS } from 'constants/common';

export const fetchRoadmaps = fetchMetadataAction(
  FETCH_ROADMAPS,
  '/api/roadmaps',
  state => state.roadmaps.roadmaps,
  state => null, // state.roadmaps.lastCallsDate,
);

export const updateRoadmap = roadmap => {
  return dispatch => {
    const payload = axios.post(`/api/roadmaps/update/${roadmap.id}`, roadmap).then(res => res.data);

    dispatch({
      payload,
      type: UPDATE_ROADMAP,
    });

    return payload;
  };
};

export const createRoadmap = roadmap => {
  return dispatch => {
    const payload = axios.post('/api/roadmaps/', roadmap).then(response => response.data);

    dispatch({
      payload,
      type: CREATE_ROADMAP,
    });

    return payload;
  };
};

export const removeUnsavedRoadmaps = () => ({
  type: REMOVE_UNSAVED_ROADMAPS,
});

export const addRoadmapWithoutSave = (roadmap, defaultLevel = METADATA_LEVELS.LEVEL_1) => {
  const state = getStore().getState();

  const orgId = state?.organization?.organization?.id;
  const roadmaps = state?.roadmaps?.roadmaps || [];
  const accessControlList = state?.accessControl?.organizationAccessControl || [];

  const childAccessControl = accessControlList.find(entry => entry.child_organization_id === orgId);

  if (!roadmap && childAccessControl && defaultLevel === METADATA_LEVELS.LEVEL_1) {
    roadmap = roadmaps.find(r => r.id === childAccessControl.portfolio_roadmap_id);
  }

  return {
    type: ADD_ROADMAP_WITHOUT_SAVE,
    roadmap,
    defaultLevel,
  };
};

export const addProductWithoutSave = (parent, isProduct = false, level = 0) => ({
  type: ADD_PRODUCT_WITHOUT_SAVE,
  parent,
  isProduct,
  level,
});

export const removeUnsavedRoadmapsAndProducts = () => ({
  type: REMOVE_UNSAVED_ROADMAPS_AND_PRODUCTS,
});

export function updateRoadmapRowOrder(roadmap) {
  return {
    type: UPDATE_ROADMAP_ROW_ORDER,
    roadmap,
  };
}

export function updateProductRowOrder(product) {
  return {
    type: UPDATE_PRODUCT_ROW_ORDER,
    product,
  };
}

export const createProduct = product => {
  return {
    payload: axios.post('/api/products/', product).then(response => response.data),
    type: CREATE_PRODUCT,
  };
};

export const updateRoadmapUserRoles = (roadmapId, roles) => {
  return {
    payload: axios.put(`/api/roadmaps/${roadmapId}/userRoles`, roles).then(response => ({ roles: response.data, roadmapId })),
    type: UPDATE_ROADMAP_USER_ROLES,
  };
};

export const getRoadmapUserRoles = roadmapId => {
  return {
    payload: axios.get(`/api/roadmaps/${roadmapId}/userRoles`).then(response => ({ roles: response.data, roadmapId })),
    type: GET_ROADMAP_USER_ROLES,
  };
};

export const gotRoadmapsRealtimeUpdate = generateRealtimeUpdateAction(
  CREATE_ROADMAP_FULFILLED,
  DELETE_ROADMAP_FULFILLED,
  UPDATE_ROADMAP_FULFILLED,
  BULK_DELETE_ROADMAPS_FULFILLED,
);

export const gotProductsRealtimeUpdate = (type, data) => {
  return dispatch => {
    switch (type) {
      case CREATE:
        return dispatch({
          type: CREATE_PRODUCT_FULFILLED,
          payload: data,
        });
      case DELETE:
        return dispatch({
          type: DELETE_PRODUCT_FULFILLED,
          payload: data.id,
        });
      case UPDATE:
        return dispatch({
          type: UPDATE_PRODUCT_ID_FULFILLED,
          payload: data,
        });
      default:
    }
  };
};

export const roadmapActions = {
  fetchRoadmaps,
  updateRoadmap,
  createRoadmap,
  updateRoadmapRowOrder,
  createProduct,
};

export function openRoadmapLightbox(roadmapId) {
  return {
    type: OPEN_ROADMAP_LIGHTBOX,
    roadmapId,
  };
}
export function closeRoadmapLightbox() {
  return {
    type: CLOSE_ROADMAP_LIGHTBOX,
  };
}
