import container from './customerRequestsContainer';
import Component from './CustomerRequestsComponent';
import HeaderOptions from './HeaderOptions';
import HeaderFilters from './HeaderFilters';

import Grid from './Grid';
import List from './List';
import Insights from './Insights';
import Analyze from './Analyze';
import PersonasGrid from 'features/PersonasGrid';
import LifecyclesGrid from 'features/LifecyclesGrid';

const Route = container(Component);

const GridRoute = container(Grid);
const ListRoute = container(List);
const InsightsRoute = container(Insights);
const AnalyzeRoute = container(Analyze);
const PersonasRoute = container(PersonasGrid);
const LifecyclesRoute = container(LifecyclesGrid);

export default {
  GridRoute,
  ListRoute,
  InsightsRoute,
  Route,
  HeaderOptions,
  HeaderFilters,
  AnalyzeRoute,
  PersonasRoute,
  LifecyclesRoute,
};
