import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

export default React.memo(({ 
  children, 
  colId, 
  height,
  minHeight,
  rowId, 
  verticalAlign,
  verticalRowHeader, 
}) => {
  const id = `col-${rowId}-${colId}-null`;

  return (
    <TableCol key={id} height={height} minHeight={minHeight} verticalRowHeader={verticalRowHeader} verticalAlign={verticalAlign}>
      {children}
    </TableCol>
  );
});

const TableCol = withTheme()(styled.div`
  padding: 14px 16px;
  color: #6b6b6b;
  border: null;
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  max-width: 100%;
  display: inline-flex;
  width: 100%;
  ${({ verticalAlign }) => (verticalAlign ? `vertical-align: ${verticalAlign};` : '')}

  > div {
    /* height: 100%; */
  }
`);
