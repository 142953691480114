import React, { useState } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import ErrorMessage from 'design-system/atoms/ErrorMessage/index';


import Description from '../../components/Description';
import ConnectButton from '../../components/ConnectButton';

export default props => {
  const [token, setToken] = useState('');
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <Grid container>
      <Grid item xs={10} container>
        <Grid item xs={12}>
          <Description>
            You need a <em>token</em> to connect dragonboat with {props.integrationName}.&nbsp; Get the token&nbsp;
            <a id="get-token-link" href={props.integrationHelpLink} target="_blank" rel="noreferrer noopener">
              here
            </a>
            .
          </Description>
        </Grid>
        <TokenContainer item xs={10}>
          <TextField
            id="username-input"
            label={`Enter your ${props.integrationName} username`}
            value={username}
            onChange={e => setUsername(e.target.value)}
            required
            fullWidth
          />
        </TokenContainer>
        <TokenContainer item xs={10}>
          <TextField
            id="integration-token-input"
            label={`Enter your ${props.integrationName} token`}
            value={token}
            onChange={e => setToken(e.target.value)}
            required
            fullWidth
          />
          {!!props.error && <TokenError>Couldn&apos;t validate inserted token. Please try again.</TokenError>}
        </TokenContainer>
      </Grid>

      <ConnectContainer item xs={2}>
        <ConnectButton
          id="connect-integration-button"
          color="primary"
          disabled={!token || loading}
          onClick={() => {
            setLoading(true);
            props
              .saveToken(token, username)
              .then(() => setLoading(false))
              .catch(() => setLoading(false));
          }}
        >
          {props.connectButtonLabel ? props.connectButtonLabel : 'Next'}
        </ConnectButton>
      </ConnectContainer>
    </Grid>
  );
};

const ConnectContainer = styled(Grid)`
  &&&& {
    padding-right: 15px;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
  }
`;

const TokenContainer = styled(Grid)`
  &&&& {
    min-width: 400px;
    padding-top: 10px;
  }
`;

const TokenError = styled(({ className, ...props }) => {
  return (
    <div className={className}>
      <ErrorMessage {...props} />
    </div>
  );
})`
  &&&& {
    margin-top: 10px;
  }
`;
