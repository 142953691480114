import axios from 'axios';

import throwRequestError from '../utils/throwRequestError';

import {
  UPDATE_USER,
  DELETE_USER_BY_ID,
  UPDATE_USER_BY_ID,
  MERGE_USERS,
  CREATE_USER,
  CREATE_USERS,
  UPDATE_USERS,
  UNDO_CREATE_USERS,
  UNDO_UPDATE_USERS,
  BULK_DELETE_USERS,
  UNDO_BULK_DELETE_USERS,
  IMPORT_USERS,
} from './types';

import { fetchUsers } from './actions';

import transformObjectWithNestedKeys from 'utils/transformObjectWithNestedKeys';
import bulkCreateAction from 'store/utils/factory/bulkCreateAction';
import bulkUpdateAction from 'store/utils/factory/bulkUpdateAction';
import bulkDeleteAction from 'store/utils/factory/bulkDeleteAction';
import { getCurrentUser } from 'store/login';

const processUserData = data => {
  if (data instanceof Array) {
    data = data.map(user => transformObjectWithNestedKeys(user));
  } else if (typeof data === 'object') {
    data = transformObjectWithNestedKeys(data);
  }

  return data;
};

const buildCreateUserPromise = data =>
  axios
    .post('/api/users', data)
    .then(res => res.data)
    .catch(throwRequestError);

export const makeCreateUser = action => data => {
  const processedData = processUserData(data);
  const promise = buildCreateUserPromise(processedData);

  return dispatch => {
    dispatch({
      payload: promise,
      type: action,
    });

    return promise;
  };
};

export const createUser = makeCreateUser(CREATE_USER);

export const updateUser = update => {
  return dispatch => {
    if (update instanceof Array) {
      update = update.map(user => transformObjectWithNestedKeys(user));
    } else if (typeof update === 'object') {
      update = transformObjectWithNestedKeys(update);
    }

    const payload = axios
      .put('/api/users', update)
      .then(res => res.data)
      .catch(throwRequestError);

    dispatch({
      payload,
      type: UPDATE_USER,
    });

    return payload;
  };
};

export const deleteUserById = userId => {
  return dispatch => {
    const payload = axios
      .delete(`/api/users/${userId}`)
      .then(res => res.data)
      .catch(throwRequestError);

    dispatch({
      payload,
      type: DELETE_USER_BY_ID,
    });

    return payload;
  };
};

export const updateUserById = (userId, update) => {
  update = transformObjectWithNestedKeys(update);
  return dispatch => {
    const payload = axios
      .put(`/api/users/${userId}`, update)
      .then(res => res.data)
      .catch(throwRequestError)
      .finally(() => dispatch(getCurrentUser()));

    dispatch({
      payload,
      type: UPDATE_USER_BY_ID,
    });

    return payload;
  };
};

export function mergeUsers(usersIdsToMerge, teamId) {
  return async dispatch => {
    const payload = axios
      .post(`/api/users/merge/${teamId}`, {
        usersIdsToMerge,
      })
      .then(response => {
        return response.data;
      })
      .catch(throwRequestError);

    await dispatch({
      type: MERGE_USERS,
      payload,
    });

    await dispatch(fetchUsers());
  };
}

export const createUsers = bulkCreateAction(
  CREATE_USERS,
  '/api/users',
  {
    toastText: 'Users have been created',
    ACTION_TYPE: UNDO_CREATE_USERS,
    endpoint: '/api/users/versions/last',
    store: 'users',
  },
  transformObjectWithNestedKeys,
);
export const updateUsers = bulkUpdateAction(
  UPDATE_USERS,
  '/api/users',
  {
    toastText: 'Users have been changed',
    ACTION_TYPE: UNDO_UPDATE_USERS,
    endpoint: '/api/users/versions/last',
    store: 'users',
  },
  transformObjectWithNestedKeys,
);
export const bulkDeleteUsers = bulkDeleteAction(BULK_DELETE_USERS, '/api/users/', {
  toastText: 'Users have been deleted',
  ACTION_TYPE: UNDO_BULK_DELETE_USERS,
  endpoint: '/api/users/versions/last',
  store: 'users',
});

export const importUsers = users => {
  return dispatch => {
    axios.post(`/api/users/import`, users).catch(throwRequestError);

    dispatch({
      type: IMPORT_USERS,
    });
  };
};
